import React, { Component } from "react";

class FeelingProud extends Component {
  render() {
    // eslint-disable-next-line
    const theme = this.props.theme;
    return (
      // <svg
      //   id="b52d7e2d-d80f-4111-b6ed-d15502ee1edd"
      //   data-name="Layer 1"
      //   xmlns="http://www.w3.org/2000/svg"
      //   width="711.1879"
      //   height="669.68268"
      //   viewBox="0 0 711.1879 669.68268"
      // >
      //   <title>feeling_proud</title>
      //   <polygon
      //     points="516.326 380.018 516.326 565.013 547.27 615.443 549.625 619.279 671.722 619.279 674.189 380.018 516.326 380.018"
      //     fill="#e6e6e6"
      //   />
      //   <polygon
      //     points="516.326 380.018 516.326 565.013 547.27 615.443 549.169 380.018 516.326 380.018"
      //     opacity="0.1"
      //   />
      //   <polygon
      //     points="221.566 375.084 221.566 560.08 190.622 610.51 188.267 614.345 66.17 614.345 63.703 375.084 221.566 375.084"
      //     fill="#e6e6e6"
      //   />
      //   <polygon
      //     points="221.566 375.084 221.566 560.08 190.622 610.51 188.723 375.084 221.566 375.084"
      //     opacity="0.1"
      //   />
      //   <polygon
      //     points="711.188 371.385 711.188 382.484 47.67 382.484 47.67 366.451 109.335 334.385 656.923 334.385 711.188 371.385"
      //     fill="#e6e6e6"
      //   />
      //   <polygon
      //     points="711.188 371.385 711.188 382.484 47.67 382.484 47.67 366.451 711.188 371.385"
      //     opacity="0.1"
      //   />
      //   <polygon
      //     points="142.635 426.883 113.035 426.883 100.702 418.25 156.201 418.25 142.635 426.883"
      //     fill={theme.text}
      //   />
      //   <polygon
      //     points="142.635 462.649 113.035 462.649 100.702 454.016 156.201 454.016 142.635 462.649"
      //     fill={theme.text}
      //   />
      //   <polygon
      //     points="142.635 513.215 113.035 513.215 100.702 504.581 156.201 504.581 142.635 513.215"
      //     fill={theme.text}
      //   />
      //   <polygon
      //     points="142.635 563.78 113.035 563.78 100.702 555.147 156.201 555.147 142.635 563.78"
      //     fill={theme.text}
      //   />
      //   <polygon
      //     points="595.257 431.816 624.857 431.816 637.19 423.183 581.691 423.183 595.257 431.816"
      //     fill={theme.text}
      //   />
      //   <polygon
      //     points="595.257 467.582 624.857 467.582 637.19 458.949 581.691 458.949 595.257 467.582"
      //     fill={theme.text}
      //   />
      //   <polygon
      //     points="595.257 518.148 624.857 518.148 637.19 509.515 581.691 509.515 595.257 518.148"
      //     fill={theme.text}
      //   />
      //   <polygon
      //     points="595.257 568.713 624.857 568.713 637.19 560.08 581.691 560.08 595.257 568.713"
      //     fill={theme.text}
      //   />
      //   <path
      //     d="M859.81989,235.61493H610.63446a9.4227,9.4227,0,0,0-9.42389,9.42389V412.89655a9.4227,9.4227,0,0,0,9.42389,9.42388h94.5929l-3.54389,22.62623s-20.25281,10.75927-6.01251,11.07574,81.32764,0,81.32764,0,12.97448,0-7.59479-11.39221l-3.33362-22.30976h93.7497a9.42266,9.42266,0,0,0,9.42388-9.42388V245.03882A9.42266,9.42266,0,0,0,859.81989,235.61493Z"
      //     transform="translate(-244.40605 -115.15866)"
      //     fill={theme.text}
      //   />
      //   <rect
      //     x="365.82336"
      //     y="130.42445"
      //     width="249.99557"
      //     height="139.87094"
      //     fill="#f2f2f2"
      //   />
      //   <circle cx="490.82114" cy="125.99415" r="1.58225" fill="#f2f2f2" />
      //   <circle cx="490.82114" cy="294.34559" r="6.01255" fill="#f2f2f2" />
      //   <polygon
      //     points="481.452 357.952 481.452 361.117 311.202 361.117 311.202 358.585 311.436 357.952 315.632 346.56 477.971 346.56 481.452 357.952"
      //     fill={theme.text}
      //   />
      //   <path
      //     d="M791.37558,470.01266c-.31011,1.3259-1.481,2.72467-4.1265,4.04741-9.4935,4.74675-28.797-1.2658-28.797-1.2658s-14.87315-2.53161-14.87315-9.17706a11.86592,11.86592,0,0,1,1.30377-.77528c3.99133-2.11163,17.2253-7.32222,40.69051.22062a9.78618,9.78618,0,0,1,4.46035,2.923A4.667,4.667,0,0,1,791.37558,470.01266Z"
      //     transform="translate(-244.40605 -115.15866)"
      //     fill={theme.text}
      //   />
      //   <path
      //     d="M791.37558,470.01266c-11.62,4.45247-21.97744,4.78472-32.607-2.59805a23.34328,23.34328,0,0,0-13.88583-4.57268c3.99133-2.11163,17.2253-7.32222,40.69051.22062a9.78618,9.78618,0,0,1,4.46035,2.923A4.667,4.667,0,0,1,791.37558,470.01266Z"
      //     transform="translate(-244.40605 -115.15866)"
      //     opacity="0.1"
      //   />
      //   <ellipse
      //     cx="532.40017"
      //     cy="350.99016"
      //     rx="4.11385"
      //     ry="1.2658"
      //     fill="#f2f2f2"
      //   />
      //   <polygon
      //     points="481.452 357.952 481.452 361.117 311.202 361.117 311.202 358.585 311.436 357.952 481.452 357.952"
      //     opacity="0.1"
      //   />
      //   <path
      //     d="M339.06751,115.15881a8.55394,8.55394,0,0,0-4.96126,1.1083c-1.476,1.0108-2.40629,2.78162-3.35588,4.42947a52.98576,52.98576,0,0,1-14.43428,16.30464c-4.28894,3.142-9.74169,7.05966-9.00789,12.85269a17.37163,17.37163,0,0,0,2.09058,5.4255c3.9566,7.987,14.75357,14.15926,13.69156,23.27309,3.931-6.58092-1.327-9.835,2.604-16.41586,1.87205-3.134,5.12412-6.67168,7.95054-4.70353.94637.659,1.56777,1.84095,2.55314,2.41917,2.35122,1.37972,4.85259-1.258,6.93387-3.12633,7.17685-6.44263,17.383-4.7548,26.31387-2.74743,4.21624.94767,8.8473,2.191,11.33971,6.2207,3.277,5.29821-3.11344,11.02034-4.72807,17.01049a3.264,3.264,0,0,0,3.50293,4.06214c2.67975-.26434,5.8542-.481,6.01278-1.65694,3.37154.12,7.50269-.26181,8.94728-3.7725a14.76216,14.76216,0,0,0,.69252-4.29319c.5302-5.89718,3.0331-11.27719,4.69119-16.88993s2.37906-12.1905-.41909-17.1668a19.86362,19.86362,0,0,0-3.666-4.45557C379.94,117.67017,359.06259,115.136,339.06751,115.15881Z"
      //     transform="translate(-244.40605 -115.15866)"
      //     fill={theme.dark}
      //   />
      //   <path
      //     d="M326.54375,190.06386s3.4534,28.7784-9.20909,31.08068,11.51136,41.4409,11.51136,41.4409l57.55681,6.90682-13.81364-46.04545s-9.20909-3.45341-3.4534-26.47613S326.54375,190.06386,326.54375,190.06386Z"
      //     transform="translate(-244.40605 -115.15866)"
      //     fill="#ffb8b8"
      //   />
      //   <polygon
      //     points="47.028 569.318 51.633 632.631 75.806 632.631 68.9 569.318 47.028 569.318"
      //     fill="#ffb8b8"
      //   />
      //   <polygon
      //     points="224.303 449.6 227.756 502.552 254.233 491.041 243.872 447.298 224.303 449.6"
      //     fill="#ffb8b8"
      //   />
      //   <path
      //     d="M274.16705,442.73826s1.15113,66.7659,8.058,88.63749,5.75568,23.02272,4.60455,26.47613-2.30228,1.15114-2.30228,6.90682-2.30227,96.69544,0,104.7534-6.90681,21.87159,0,23.02272,39.13863,0,40.28977-6.90682-9.20909-9.20909-4.60454-13.81363,11.51136-98.99772,11.51136-98.99772l16.11591-65.61476,29.92954-34.53409H441.0818l19.56932,75.975s-8.058,21.87159-2.30228,21.87159,40.28977,6.90682,40.28977-18.41818S487.12725,451.94735,484.825,449.64508s1.15113-10.36023-2.30228-13.81364-43.74317-27.62727-58.70794-32.23181S387.857,393.51476,387.857,393.51476Z"
      //     transform="translate(-244.40605 -115.15866)"
      //     fill={theme.dark}
      //   />
      //   <path
      //     d="M498.63861,596.99051s-17.267-5.75568-25.325,11.51137-4.60454,21.87159-4.60454,21.87159,26.47613,9.20909,31.08068,4.60454c2.00333-2.00334,8.36471-2.69926,14.91273-2.84621,9.97289-.22381,12.62625-14.33323,3.20748-17.61885q-.42145-.147-.85317-.25539C507.8477,611.95529,498.63861,596.99051,498.63861,596.99051Z"
      //     transform="translate(-244.40605 -115.15866)"
      //     fill={theme.dark}
      //   />
      //   <circle cx="108.03826" cy="59.36486" r="34.53409" fill="#ffb8b8" />
      //   <path
      //     d="M307.55,235.53374s56.40567,11.51136,70.21931-6.90682,19.56931,51.80113,19.56931,51.80113l6.90682,73.67272-10.36023,40.28977s-54.1034,43.74318-71.37044,47.19658-43.74318,5.75569-43.74318,5.75569,8.058-127.77613,8.058-130.0784S307.55,235.53374,307.55,235.53374Z"
      //     transform="translate(-244.40605 -115.15866)"
      //     fill={theme.imageHighlight}
      //   />
      //   <path
      //     d="M324.70433,213.94456s-12.54979-7.18923-16.0032-.28241S273.01591,237.836,269.5625,237.836s6.90682,95.54431,2.30227,107.05567S245.38864,440.436,258.05114,447.34281s3.45341-6.90682,16.11591,10.36022,74.82385,17.267,78.27726,10.36023-27.62727-58.708-21.87159-107.05567,14.96477-115.11362,6.90682-124.32271S324.70433,213.94456,324.70433,213.94456Z"
      //     transform="translate(-244.40605 -115.15866)"
      //     fill={theme.dark}
      //   />
      //   <path
      //     d="M366.258,221.7201l1.60341-5.518s47.89544,15.87824,50.19772,26.23847,1.15113,82.88181-6.90682,88.63749-19.56932,14.96477-11.51136,28.7784,17.267,28.77841,24.17386,29.92954,19.56931,9.20909,16.1159,17.267-44.89431-6.90682-44.89431-6.90682-27.62727-20.72045-26.47613-52.95227S366.258,221.7201,366.258,221.7201Z"
      //     transform="translate(-244.40605 -115.15866)"
      //     fill={theme.dark}
      //   />
      //   <path
      //     d="M406.54771,357.55418l-27.62727,51.80113s-40.28976,41.4409-17.267,46.04545,35.68522-37.9875,35.68522-37.9875l29.92955-42.592Z"
      //     transform="translate(-244.40605 -115.15866)"
      //     fill="#ffb8b8"
      //   />
      //   <path
      //     d="M340.74981,124.19457a7.83806,7.83806,0,0,0-4.03868.78334,9.41322,9.41322,0,0,0-2.73182,3.13072,39.77267,39.77267,0,0,1-11.7501,11.524c-3.49138,2.22077-7.93014,4.98972-7.3328,9.0842a11.35949,11.35949,0,0,0,1.70182,3.8347,30.16344,30.16344,0,0,1,3.66519,18.80068l9.60011-13.954c1.52393-2.21507,4.17125-4.7155,6.47207-3.32442.77039.46577,1.27623,1.30117,2.07836,1.70985,1.914.97518,3.95021-.88912,5.64447-2.20967,5.84225-4.55361,14.15049-3.36066,21.42059-1.94187,3.43219.66981,7.20207,1.54859,9.231,4.39676,3.37169,4.73311-.149,11.5721,1.81882,17.04a5.02339,5.02339,0,0,0,2.07852-3.31717c2.74457.08484,6.1075-.185,7.28345-2.66638a9.18757,9.18757,0,0,0,.56374-3.03439c.43161-4.16809,2.46907-7.97065,3.81883-11.93769s1.93666-8.61616-.34116-12.13338a14.68107,14.68107,0,0,0-2.98426-3.14917C374.02174,125.96958,357.02664,124.17845,340.74981,124.19457Z"
      //     transform="translate(-244.40605 -115.15866)"
      //     fill={theme.dark}
      //   />
      //   <path
      //     d="M406.54771,237.836l10.72528,2.84686s24.95995,63.919,19.20427,107.66222-9.20909,34.53409-9.20909,34.53409-9.20909-20.72046-29.92955-16.11591Z"
      //     transform="translate(-244.40605 -115.15866)"
      //     fill={theme.dark}
      //   />
      //   <path
      //     d="M293.02894,739.422c-2.77592,3.77228-2.21935,9.16459-4.67828,13.15075-2.13144,3.45526-6.14837,5.26695-8.81439,8.32869a22.32616,22.32616,0,0,0-2.26617,3.25987c-2.47311,4.14255-4.4833,9.45174-2.04761,13.61641,1.95936,3.35022,6.07786,4.65961,9.855,5.552,4.77407,1.128,9.73436,2.03071,14.56354,1.16876s9.54882-3.819,11.27067-8.41239a32.50011,32.50011,0,0,1,1.2051-3.4007c2.61747-5.15294,10.82749-5.20864,13.50628-10.33,1.87466-3.584.15138-7.91623-1.57118-11.57578l-5.26109-11.1771c-1.74592-3.70919-8.82362-1.57608-12.51339-2.46492C301.46518,735.97844,296.49084,734.70126,293.02894,739.422Z"
      //     transform="translate(-244.40605 -115.15866)"
      //     fill={theme.dark}
      //   />
      //   <path
      //     d="M254.59773,380.5769l48.34772,74.82386s29.92954,37.98749,39.13863,20.72045-32.23181-46.04545-32.23181-46.04545L278.77159,372.519Z"
      //     transform="translate(-244.40605 -115.15866)"
      //     fill="#ffb8b8"
      //   />
      //   <path
      //     d="M278.77159,240.13828,269.5625,237.836s-18.41818,5.75568-23.02272,29.92954-1.15114,120.86931,4.60454,122.02044,28.42725-16.83663,32.05624-13.02286-7.88238-15.75554-4.429-26.11577S278.77159,240.13828,278.77159,240.13828Z"
      //     transform="translate(-244.40605 -115.15866)"
      //     fill={theme.dark}
      //   />
      //   <g id="f3c2397c-d780-4cc6-97d8-1503d8277a2c" data-name="Group 13">
      //     <rect
      //       id="ad41612f-86f7-46b2-a964-3a5da7bcf3cf"
      //       data-name="Rectangle 55"
      //       x="437.40692"
      //       y="176.36275"
      //       width="29.75235"
      //       height="7.1626"
      //       fill={theme.imageHighlight}
      //     />
      //     <rect
      //       id="b9a0b375-cc2a-4c59-8850-661af54e4f62"
      //       data-name="Rectangle 56"
      //       x="559.72215"
      //       y="176.36275"
      //       width="10.46842"
      //       height="7.1626"
      //       fill={theme.imageHighlight}
      //     />
      //     <rect
      //       id="e174c2ab-9bc5-41ec-b37c-b06fbf8a9878"
      //       data-name="Rectangle 57"
      //       x="580.65899"
      //       y="176.36275"
      //       width="10.46842"
      //       height="7.1626"
      //       fill={theme.imageHighlight}
      //     />
      //     <rect
      //       id="ba2479ac-4c7d-43b4-a028-73b1e20e4002"
      //       data-name="Rectangle 58"
      //       x="477.62768"
      //       y="176.36275"
      //       width="71.07506"
      //       height="7.1626"
      //       fill={theme.imageHighlight}
      //     />
      //     <rect
      //       id="ac60ef86-00f2-480b-8171-28b6fdfc1958"
      //       data-name="Rectangle 59"
      //       x="399.94099"
      //       y="222.6442"
      //       width="29.75235"
      //       height="7.1626"
      //       fill={theme.imageHighlight}
      //     />
      //     <rect
      //       id="b3d8298d-cd65-487a-8b19-4cee946356e7"
      //       data-name="Rectangle 60"
      //       x="522.25621"
      //       y="222.6442"
      //       width="10.46842"
      //       height="7.1626"
      //       fill={theme.imageHighlight}
      //     />
      //     <rect
      //       id="ed27f15f-f0c4-4569-8330-a6688f8e356c"
      //       data-name="Rectangle 61"
      //       x="543.19305"
      //       y="222.6442"
      //       width="10.46842"
      //       height="7.1626"
      //       fill={theme.imageHighlight}
      //     />
      //     <rect
      //       id="f685c005-adaf-4d60-acda-005b037d82a6"
      //       data-name="Rectangle 62"
      //       x="440.16176"
      //       y="222.6442"
      //       width="71.07506"
      //       height="7.1626"
      //       fill={theme.imageHighlight}
      //     />
      //     <rect
      //       id="eca7c7fc-83f8-48ca-a588-6606db47af3f"
      //       data-name="Rectangle 63"
      //       x="522.80718"
      //       y="192.34088"
      //       width="29.75235"
      //       height="7.1626"
      //       fill={theme.imageHighlight}
      //     />
      //     <rect
      //       id="a71b5b2f-f8b9-481d-a301-5e3357e5fe42"
      //       data-name="Rectangle 64"
      //       x="563.02795"
      //       y="192.34088"
      //       width="29.75235"
      //       height="7.1626"
      //       fill={theme.imageHighlight}
      //     />
      //     <rect
      //       id="abb3a25a-f854-41fc-8391-e6ad9fbe2417"
      //       data-name="Rectangle 66"
      //       x="399.94099"
      //       y="192.34088"
      //       width="10.46842"
      //       height="7.1626"
      //       fill={theme.imageHighlight}
      //     />
      //     <rect
      //       id="add2b425-b775-4eb5-9c25-8c4c14f957c9"
      //       data-name="Rectangle 67"
      //       x="420.87784"
      //       y="192.34088"
      //       width="10.46842"
      //       height="7.1626"
      //       fill={theme.imageHighlight}
      //     />
      //     <rect
      //       id="b7159612-3b84-4b0b-a885-612eaab04c06"
      //       data-name="Rectangle 68"
      //       x="441.81466"
      //       y="192.34088"
      //       width="71.07506"
      //       height="7.1626"
      //       fill={theme.imageHighlight}
      //     />
      //     <rect
      //       id="edfc7301-4de5-47dc-80e2-1ae2da982d6d"
      //       data-name="Rectangle 69"
      //       x="461.09859"
      //       y="207.76802"
      //       width="29.75235"
      //       height="7.1626"
      //       fill={theme.imageHighlight}
      //     />
      //     <rect
      //       id="e1009f91-6275-4375-80fa-0d778e331fdc"
      //       data-name="Rectangle 70"
      //       x="420.87783"
      //       y="207.76802"
      //       width="29.75235"
      //       height="7.1626"
      //       fill={theme.imageHighlight}
      //     />
      //     <rect
      //       id="a060fb9f-1f1a-4862-9a69-16dd49199e18"
      //       data-name="Rectangle 71"
      //       x="399.941"
      //       y="207.76802"
      //       width="10.46842"
      //       height="7.1626"
      //       fill={theme.imageHighlight}
      //     />
      //     <rect
      //       id="b6754d5f-104f-449c-b9a9-cf9ac82bf5fe"
      //       data-name="Rectangle 73"
      //       x="582.31189"
      //       y="207.76802"
      //       width="10.46842"
      //       height="7.1626"
      //       fill={theme.imageHighlight}
      //     />
      //     <rect
      //       id="e6ed1ccd-2cfd-450f-a339-07df3a1ee6bb"
      //       data-name="Rectangle 74"
      //       x="500.76841"
      //       y="207.76802"
      //       width="71.07506"
      //       height="7.1626"
      //       fill={theme.imageHighlight}
      //     />
      //   </g>
      // </svg>
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="100%"
        viewBox="0 0 3024 4032"
        enable-background="new 0 0 3024 4032"
      >
        <path
          fill="#8AB7E0"
          opacity="1.000000"
          stroke="none"
          d="
M2.678146,1370.542725 
	C2.000000,1281.958252 2.000000,1193.916382 2.000000,1104.937256 
	C16.993694,1088.505859 42.025894,1086.540283 58.663124,1099.849487 
	C63.096039,1103.395752 66.477768,1103.887451 70.631897,1099.549683 
	C75.582436,1094.380493 80.774223,1095.697266 85.516296,1100.197144 
	C94.473671,1108.697144 105.072762,1109.585327 116.097847,1106.032715 
	C123.243210,1103.730347 130.220947,1103.320557 137.651398,1103.472656 
	C148.886017,1103.702759 159.906998,1100.373047 171.222046,1100.151855 
	C175.686203,1100.064697 177.417572,1095.639648 179.913559,1092.565186 
	C188.379364,1082.136963 205.308533,1081.086548 213.258530,1090.321167 
	C218.508286,1096.419434 219.272369,1102.588135 214.370224,1109.336182 
	C212.664658,1111.684082 210.603912,1114.876831 214.036179,1117.197510 
	C216.921494,1119.148193 219.991211,1117.640381 221.895279,1115.015625 
	C226.200974,1109.080566 230.821564,1106.109131 237.594437,1111.680664 
	C239.507431,1113.254150 241.467957,1111.673706 242.851044,1110.274658 
	C250.358673,1102.680786 260.043854,1100.582642 270.824036,1099.244873 
	C275.192780,1099.165039 278.611023,1099.990112 282.533508,1101.750244 
	C286.981506,1103.797363 287.773071,1111.512817 295.023132,1107.418457 
	C302.902161,1102.744873 310.778625,1102.248291 318.888519,1103.261475 
	C322.852661,1103.756958 326.809601,1104.784668 330.810760,1104.141357 
	C337.542450,1103.059082 342.528503,1105.091675 345.652588,1111.400269 
	C348.285278,1116.716431 350.949036,1116.737915 355.304565,1112.329102 
	C365.268066,1102.244019 376.347900,1095.556763 391.811798,1100.753174 
	C397.519653,1102.671143 404.357697,1101.351440 410.564911,1102.856567 
	C414.080078,1103.708740 415.995575,1101.141479 418.183075,1099.082886 
	C421.330414,1096.121094 425.578613,1095.006714 427.065399,1099.666748 
	C429.559662,1107.484741 437.458344,1109.663940 441.567474,1115.686279 
	C444.434174,1119.887817 449.308838,1118.445312 453.592346,1117.592773 
	C470.886841,1114.150391 487.894775,1110.537109 505.555023,1117.532104 
	C514.295044,1120.993774 524.101624,1119.447632 532.142334,1113.186035 
	C538.613953,1108.146118 544.181519,1107.589600 551.966919,1112.313965 
	C561.330261,1117.995972 572.879333,1116.285278 582.619629,1112.633179 
	C593.331787,1108.616943 602.591675,1108.286865 611.807983,1115.099487 
	C615.729797,1117.998291 619.711060,1118.132690 623.983398,1115.987061 
	C626.964050,1114.490112 630.414368,1113.893066 633.231384,1112.245117 
	C643.322205,1106.341797 651.625610,1106.823975 659.385986,1116.663818 
	C663.753784,1122.202026 669.713013,1122.171875 676.307190,1118.089478 
	C688.906128,1110.289795 702.068787,1106.935791 715.199646,1117.666992 
	C719.083069,1120.840698 723.885620,1120.841431 728.590454,1120.182739 
	C737.430542,1118.944824 745.937988,1119.479858 753.518616,1124.747925 
	C757.503418,1127.516968 759.625366,1125.315552 761.486755,1122.165894 
	C765.480530,1115.407715 770.820679,1115.633545 777.828491,1117.431519 
	C800.905884,1123.352783 824.515869,1124.146118 848.053711,1121.272339 
	C860.117554,1119.799561 865.880493,1121.152222 872.402283,1131.350952 
	C874.125732,1134.046021 875.325134,1136.929443 878.969910,1133.546387 
	C890.591492,1122.758911 905.521484,1123.224121 919.814697,1121.945435 
	C924.024536,1121.568848 928.415771,1122.875854 932.752625,1122.753052 
	C941.331055,1122.510132 947.880188,1124.949707 949.596924,1134.534668 
	C950.377014,1138.890137 953.528870,1140.451660 957.592773,1140.563110 
	C965.246460,1140.773071 972.867371,1141.134399 980.437622,1139.129272 
	C993.212158,1135.745483 1006.086792,1135.168457 1018.806824,1139.432617 
	C1022.281067,1140.597168 1024.976685,1140.037476 1028.190552,1138.319824 
	C1036.427124,1133.918213 1040.832031,1134.679077 1044.656372,1142.480103 
	C1048.090088,1149.484497 1053.147827,1150.998901 1059.973022,1150.795044 
	C1066.632324,1150.596069 1073.305420,1150.837646 1079.969727,1150.749268 
	C1087.324829,1150.651611 1093.993408,1151.816040 1097.932251,1159.025635 
	C1100.117554,1163.025635 1103.514771,1163.151367 1107.392822,1162.120483 
	C1112.952026,1160.642334 1118.619507,1160.529419 1124.262085,1161.447632 
	C1129.033813,1162.224365 1133.280884,1161.219238 1137.821899,1159.532227 
	C1149.581299,1155.163086 1161.626709,1153.127563 1173.814331,1158.414551 
	C1179.142334,1160.725830 1184.845459,1161.000122 1190.523682,1160.720215 
	C1215.305176,1159.499023 1238.625610,1165.249268 1261.065186,1175.284302 
	C1262.940186,1176.122681 1264.795166,1176.644043 1266.852661,1176.382568 
	C1268.176270,1176.214478 1269.509155,1175.732422 1270.801514,1175.817139 
	C1284.030762,1176.685303 1297.333008,1179.725342 1310.314453,1174.271118 
	C1322.015869,1181.893799 1330.676147,1173.966309 1339.853271,1168.531250 
	C1349.342651,1162.911255 1359.004761,1161.750244 1369.414307,1166.472290 
	C1379.301270,1170.957397 1389.841553,1166.767822 1400.108643,1167.574097 
	C1407.672485,1168.168335 1415.395752,1168.156616 1422.997314,1168.894165 
	C1430.619751,1169.633667 1437.964233,1170.410889 1445.544678,1167.638306 
	C1450.596313,1165.790527 1456.633911,1166.342163 1460.427490,1170.820435 
	C1468.744263,1180.638672 1479.141846,1179.715820 1489.792603,1177.218750 
	C1504.664917,1173.731323 1518.689209,1174.872559 1532.034302,1182.834839 
	C1539.500854,1187.289551 1548.579468,1185.835083 1553.816772,1181.227661 
	C1566.942505,1169.680298 1582.727173,1173.545654 1597.326172,1170.919678 
	C1611.306641,1168.404907 1624.515747,1171.267578 1636.493042,1178.953613 
	C1641.214478,1181.983276 1644.608521,1180.171631 1647.956543,1176.871704 
	C1654.366455,1170.554077 1660.728027,1170.966431 1668.769043,1174.874390 
	C1682.633667,1181.612915 1697.514771,1183.433350 1712.136841,1176.846436 
	C1719.593384,1173.487305 1726.046875,1172.283813 1731.779297,1179.484375 
	C1733.572144,1181.736328 1735.735596,1182.995117 1738.736572,1181.644287 
	C1747.821045,1177.555420 1756.476074,1180.667847 1764.939819,1183.661011 
	C1781.651245,1189.571045 1798.172729,1189.040283 1814.653809,1183.050049 
	C1818.324341,1181.716064 1821.957397,1181.347046 1825.213257,1183.521729 
	C1831.178223,1187.506104 1836.756348,1186.890991 1843.085815,1184.004272 
	C1850.273560,1180.725952 1858.435547,1180.251221 1865.748901,1176.755615 
	C1870.762329,1174.359375 1875.554443,1177.579956 1879.492676,1180.719238 
	C1886.190674,1186.058472 1892.694336,1185.656616 1900.894165,1183.562988 
	C1914.590332,1180.065918 1928.460938,1176.555664 1943.113037,1177.838745 
	C1952.639526,1178.672974 1962.331421,1177.331665 1971.960571,1178.839233 
	C1984.401123,1180.786987 1997.190918,1180.823975 2008.485718,1187.797119 
	C2012.115356,1190.038086 2016.763672,1190.709351 2021.089844,1190.492188 
	C2038.438843,1189.621460 2055.589355,1193.639526 2072.968262,1192.277954 
	C2082.212158,1191.553833 2090.191895,1196.699097 2096.646240,1201.758179 
	C2108.655518,1211.171631 2121.786377,1211.708862 2135.490234,1210.406616 
	C2155.767822,1208.479492 2175.994629,1209.250854 2196.286865,1209.685791 
	C2208.472900,1209.947021 2220.612305,1206.173096 2232.985352,1208.729248 
	C2236.901123,1209.538208 2241.010498,1209.502686 2244.250977,1212.219116 
	C2257.789551,1223.567505 2273.427246,1222.149170 2288.844482,1218.654907 
	C2303.289795,1215.380737 2303.238525,1214.633301 2312.033691,1226.468018 
	C2315.038086,1230.510620 2318.605469,1231.898560 2323.294678,1230.582642 
	C2330.799072,1228.476318 2338.477051,1228.785645 2346.138672,1228.753052 
	C2382.037354,1228.600586 2417.931641,1226.967896 2453.838867,1228.672363 
	C2460.751709,1229.000610 2467.035400,1229.939697 2471.878418,1235.494141 
	C2474.196777,1238.153076 2477.155273,1239.654541 2480.789795,1237.973633 
	C2486.948242,1235.125610 2493.029785,1236.396851 2499.355469,1237.628540 
	C2509.593262,1239.621826 2519.969482,1239.678345 2529.975830,1236.192383 
	C2538.321777,1233.284790 2546.891357,1232.029663 2555.569092,1232.856812 
	C2577.919678,1234.986816 2600.375000,1234.036621 2622.256348,1230.594604 
	C2648.197021,1226.513794 2674.911865,1228.701904 2700.264404,1220.487183 
	C2703.415771,1219.466187 2706.868652,1218.630981 2710.119629,1219.062866 
	C2731.377930,1221.886963 2751.235840,1212.153687 2772.195557,1212.334595 
	C2777.010742,1212.376221 2781.320801,1210.298218 2784.220459,1206.171021 
	C2788.652100,1199.862427 2794.722412,1198.619751 2801.871094,1199.963623 
	C2807.826660,1201.083008 2813.781494,1200.340820 2819.784180,1199.801025 
	C2839.454590,1198.032104 2858.729980,1197.479126 2876.910645,1208.279541 
	C2888.838867,1215.365601 2889.497803,1214.396973 2898.674805,1203.871460 
	C2901.571533,1200.549316 2905.117676,1199.422974 2909.318359,1199.422729 
	C2926.645508,1199.421387 2943.756592,1196.187988 2961.129883,1196.171265 
	C2968.803711,1196.163818 2976.410889,1196.658081 2984.049561,1196.874023 
	C2992.766846,1197.120361 2997.312256,1201.760254 2998.831299,1210.183350 
	C3001.026855,1222.358521 3001.166748,1222.317993 3013.182617,1222.768799 
	C3017.145508,1222.917358 3021.244385,1222.443726 3025.376465,1225.496094 
	C3026.000000,1338.041748 3026.000000,1450.083618 3026.000000,1563.062744 
	C3015.319336,1569.079468 3004.601318,1568.550537 2994.891602,1562.298950 
	C2990.186768,1559.269775 2986.520020,1557.530884 2981.324951,1560.303833 
	C2974.988770,1563.685425 2968.773926,1562.617554 2962.082275,1560.281372 
	C2949.561523,1555.910034 2935.826172,1556.244995 2923.418213,1551.011841 
	C2921.564697,1550.229858 2919.506348,1551.417603 2917.699219,1552.296143 
	C2911.498291,1555.311523 2904.848389,1555.711060 2898.153076,1555.463013 
	C2870.596191,1554.442261 2843.102783,1556.674683 2815.572998,1556.994019 
	C2812.204346,1557.033081 2808.737305,1557.116821 2805.772217,1555.551147 
	C2795.400635,1550.074585 2784.390381,1550.859375 2773.508057,1552.436035 
	C2755.131104,1555.098755 2736.509521,1555.936279 2718.635010,1562.318237 
	C2709.984619,1565.406860 2700.132812,1564.424805 2691.168701,1560.735107 
	C2680.456543,1556.325928 2669.855957,1556.099731 2659.286621,1561.189575 
	C2657.177246,1562.205444 2654.885742,1562.986694 2652.558350,1562.985718 
	C2627.609131,1562.975220 2602.688232,1564.901978 2577.735107,1564.136108 
	C2573.083984,1563.993408 2568.425537,1563.356689 2563.765137,1563.515015 
	C2555.454834,1563.797119 2547.865234,1561.943115 2541.442627,1556.452515 
	C2535.981689,1551.784546 2529.747314,1550.342407 2522.789795,1551.638916 
	C2508.380127,1554.324341 2493.433838,1552.389648 2479.207520,1556.720947 
	C2477.631104,1557.200928 2475.857910,1557.316895 2474.239746,1557.012573 
	C2441.431396,1550.844849 2408.128174,1559.412842 2375.087891,1552.973877 
	C2358.389893,1549.719727 2340.652832,1551.057617 2323.546387,1554.481689 
	C2322.239502,1554.743164 2320.910889,1555.075562 2319.590332,1555.030640 
	C2296.001221,1554.226440 2272.523682,1556.668701 2248.988281,1557.390381 
	C2244.287109,1557.534546 2239.436768,1557.117188 2235.533691,1554.319336 
	C2228.791748,1549.486938 2221.248291,1548.758667 2213.572754,1549.814209 
	C2198.259277,1551.919922 2182.964600,1549.655884 2167.772217,1550.053833 
	C2113.127441,1551.484863 2058.815674,1543.233521 2004.200806,1545.466187 
	C1999.210693,1545.670288 1994.201294,1545.596558 1989.205322,1545.410522 
	C1985.218750,1545.262329 1981.140625,1545.295410 1977.586304,1541.421387 
	C1980.114502,1530.474854 1989.052368,1529.927246 1997.078613,1528.896118 
	C2004.365723,1527.959717 2010.782104,1526.208862 2015.267090,1520.032593 
	C2018.052368,1516.197144 2019.254761,1512.610840 2012.808228,1511.519775 
	C2007.284668,1510.584717 2002.703369,1508.139038 1999.330078,1503.693481 
	C1996.822754,1500.389526 1993.341919,1499.818970 1989.604614,1499.560669 
	C1959.720215,1497.495728 1929.842651,1495.627441 1899.858276,1495.288330 
	C1878.958008,1495.052124 1857.867310,1497.952881 1837.166016,1492.540039 
	C1833.779175,1491.654541 1830.671143,1493.665283 1827.409546,1494.108154 
	C1813.764648,1495.960693 1799.840088,1498.671387 1791.039062,1483.558350 
	C1788.659424,1479.471924 1784.321289,1479.766113 1780.351318,1479.520752 
	C1731.626709,1476.510498 1683.081299,1470.306519 1634.129150,1471.336426 
	C1628.250854,1471.460205 1622.881470,1469.849976 1618.558960,1465.703369 
	C1615.510010,1462.778320 1611.821045,1461.332886 1607.796143,1460.960815 
	C1591.269531,1459.432983 1574.903198,1456.335815 1558.075562,1458.576050 
	C1544.891846,1460.331055 1531.519897,1460.569824 1518.417114,1456.705811 
	C1511.948486,1454.798218 1505.133789,1454.899292 1498.555908,1455.811157 
	C1479.295532,1458.481445 1459.594971,1459.536377 1440.759766,1456.759521 
	C1419.058228,1453.559814 1397.033936,1455.484253 1375.592407,1450.538208 
	C1366.623901,1448.469360 1357.534790,1450.193604 1349.175049,1453.622559 
	C1342.496338,1456.361938 1335.835449,1457.479126 1328.762085,1457.455933 
	C1312.102783,1457.401367 1295.442993,1457.498291 1278.783813,1457.416870 
	C1269.426880,1457.371094 1260.162964,1457.369019 1251.319580,1461.285645 
	C1246.848145,1463.265991 1241.743530,1463.442261 1238.043701,1460.018555 
	C1229.364746,1451.987915 1219.063721,1453.508545 1208.752930,1453.833496 
	C1184.217163,1454.607056 1159.661011,1455.900513 1135.639282,1448.599976 
	C1128.254883,1446.355713 1120.355225,1446.887207 1112.789185,1447.981812 
	C1100.169067,1449.807007 1087.527100,1449.307007 1074.878174,1449.418945 
	C1068.364624,1449.476685 1062.324585,1448.608032 1057.743408,1443.120850 
	C1054.471436,1439.201904 1050.003662,1439.813599 1045.714600,1441.128418 
	C1039.691040,1442.975098 1033.905884,1442.489990 1028.172607,1439.989380 
	C1025.107178,1438.652222 1021.859009,1437.521118 1018.505798,1437.716431 
	C998.334778,1438.892578 978.737488,1435.578979 959.328186,1430.537476 
	C956.075012,1429.692505 952.660339,1429.597290 949.414612,1430.483765 
	C934.359680,1434.594604 918.977905,1433.258423 903.684875,1433.393799 
	C899.005798,1433.435181 894.315979,1433.159668 889.855103,1431.648193 
	C870.482483,1425.083740 850.110901,1424.311768 830.223633,1420.791626 
	C811.021667,1417.392578 791.706604,1413.509644 772.898499,1407.911987 
	C768.064209,1406.473022 763.212158,1405.247803 758.181946,1405.520508 
	C734.698120,1406.794189 711.954224,1400.697266 688.837952,1398.832886 
	C666.100586,1396.999146 644.827454,1388.430908 622.446838,1386.415283 
	C589.126160,1383.414429 556.019653,1375.386841 522.161926,1381.055176 
	C511.521454,1382.836670 502.282013,1380.230835 494.144897,1371.745972 
	C487.184021,1364.487549 477.802826,1361.686401 466.860504,1362.432129 
	C442.628143,1364.083374 418.359985,1364.118530 394.040802,1361.901123 
	C369.312561,1359.646484 344.885010,1356.165527 320.787506,1350.243042 
	C318.194275,1349.605591 315.019073,1348.856201 312.908936,1350.078979 
	C302.682220,1356.004639 290.489502,1355.213623 280.088104,1360.016113 
	C272.390411,1363.570190 266.302887,1363.000732 260.268524,1356.816650 
	C250.162827,1346.460083 229.343399,1345.929688 217.897049,1355.207397 
	C212.755661,1359.374512 207.927933,1364.161743 200.807098,1364.957642 
	C194.854401,1365.623047 189.536697,1364.180664 184.973557,1360.324585 
	C178.437073,1354.801270 171.553726,1353.563599 163.783890,1357.775757 
	C159.301559,1360.205688 154.370193,1360.949463 149.238174,1360.286255 
	C137.579849,1358.779419 125.961098,1359.222046 114.405739,1361.298950 
	C94.225098,1364.925903 73.853065,1363.083496 53.562813,1363.361572 
	C45.910507,1363.466431 39.062012,1363.975830 34.923473,1371.849609 
	C32.901398,1375.696899 28.691721,1377.429199 24.582024,1376.195557 
	C17.582031,1374.094482 10.010480,1374.480591 2.678146,1370.542725 
z"
        />
        <path
          fill="#78B0E5"
          opacity="1.000000"
          stroke="none"
          d="
M284.477631,1104.453613 
	C280.305939,1104.497192 277.146881,1102.080322 272.776001,1102.006714 
	C266.435516,1092.387573 267.292328,1090.387573 276.532196,1086.368164 
	C287.324036,1081.673706 298.347382,1078.510010 310.122833,1077.690308 
	C313.572815,1077.450195 318.024078,1077.489868 319.554230,1073.368286 
	C321.199890,1068.935669 318.064392,1065.862305 315.195282,1062.984863 
	C313.785919,1061.571167 312.181488,1060.344360 310.977112,1058.736206 
	C309.541473,1056.819336 308.206238,1054.713501 309.832916,1052.384033 
	C311.647552,1049.785522 315.082947,1049.214844 316.925201,1050.894775 
	C323.515686,1056.904663 330.769287,1053.394409 337.831299,1053.315308 
	C342.077942,1053.267822 345.670074,1052.336914 348.323761,1048.755249 
	C352.463348,1043.168335 357.660278,1043.039185 363.339417,1046.131836 
	C367.767426,1048.543213 372.421387,1049.195557 376.582642,1046.185791 
	C384.038361,1040.793457 392.288727,1040.371338 401.125122,1039.895142 
	C415.678162,1039.111084 429.983307,1035.088013 444.734070,1035.800659 
	C451.705139,1036.137451 454.838318,1034.131470 450.906281,1026.696411 
	C448.792328,1022.699097 448.828400,1018.162720 452.222778,1014.568176 
	C455.687775,1010.898743 460.978760,1010.386230 464.168823,1013.026611 
	C475.058167,1022.039612 484.939697,1015.406799 494.483978,1011.471802 
	C501.866913,1008.427795 507.354034,1008.698303 513.820007,1013.352478 
	C521.070374,1018.571167 530.079590,1020.802551 537.507324,1026.098999 
	C542.159058,1029.415894 546.074768,1025.517822 551.399353,1022.322327 
	C545.603638,1021.026672 541.300781,1023.141907 537.227905,1021.801941 
	C533.921326,1020.714172 530.837341,1019.198181 530.799927,1015.169312 
	C530.759338,1010.806641 534.157532,1009.770630 537.584045,1009.331299 
	C545.195190,1008.355469 552.891785,1009.345581 560.527283,1008.431152 
	C568.365662,1007.492493 574.535706,1005.228882 575.891052,995.885071 
	C576.770508,989.821716 580.790039,985.720947 587.516907,985.506287 
	C594.002197,985.299194 597.200928,989.263550 598.687866,994.910339 
	C600.641235,1002.328308 605.906494,1003.866211 611.720337,1000.928650 
	C628.527100,992.436462 646.030945,991.525146 664.181274,994.206238 
	C668.750916,994.881287 671.731812,992.461975 672.792297,987.779785 
	C673.936218,982.729370 670.856873,981.357849 666.888000,980.513733 
	C662.492371,979.578735 658.439392,977.654297 656.365112,973.580505 
	C652.800781,966.580383 646.975830,964.745605 639.790405,965.023865 
	C630.565186,965.381104 624.395386,961.616333 622.677429,951.997437 
	C622.323669,950.016602 621.218140,948.314514 620.213196,946.572998 
	C613.136047,934.308899 614.914001,928.380005 627.293518,921.690735 
	C632.024353,919.134460 635.742798,921.031677 639.095520,923.984009 
	C646.307556,930.334778 654.420044,929.670715 661.254761,924.944641 
	C669.192078,919.456238 678.411743,920.075806 686.772522,917.078552 
	C689.992554,915.924255 692.818115,917.316284 694.222351,920.446167 
	C698.111328,929.114441 706.747681,933.139587 712.914673,939.566406 
	C717.665588,944.517456 723.823059,943.976990 728.917053,939.024658 
	C731.786682,936.234802 734.423340,933.214600 738.085999,931.409668 
	C745.553894,927.729431 751.581177,929.305176 757.872253,937.568726 
	C760.680481,941.257507 762.455139,942.042053 767.454285,940.707703 
	C780.287964,937.282349 792.816040,932.962769 805.519592,929.182678 
	C806.802246,928.801086 808.111023,928.740356 809.428772,928.591003 
	C813.258118,928.157166 814.894592,936.173950 818.238892,932.210754 
	C822.090759,927.645874 825.429749,921.780334 824.103394,914.190186 
	C825.008728,902.938538 831.430298,898.677979 840.831299,903.111633 
	C847.507690,906.260315 853.896484,909.612976 861.480164,909.930664 
	C866.261047,910.130981 868.060120,913.839600 869.554932,917.715027 
	C871.271118,922.164185 873.707581,923.933655 877.169006,919.229553 
	C880.938599,914.106689 886.436279,913.630676 892.123169,912.571899 
	C908.991577,909.431152 925.128479,911.204895 941.213257,917.070435 
	C949.648926,920.146545 958.682739,921.362671 967.733032,918.608154 
	C970.643677,917.722290 973.946960,917.265259 976.497131,919.009033 
	C982.878296,923.372375 989.028870,924.716553 995.677673,919.644409 
	C997.010864,918.627441 999.412842,918.736023 1000.295349,920.113647 
	C1004.633423,926.885376 1011.211243,925.041687 1017.371704,924.978271 
	C1019.039673,924.961121 1020.874695,924.791992 1022.312866,925.513000 
	C1039.990723,934.375305 1058.133911,926.521606 1076.030884,927.035034 
	C1081.897583,927.203369 1086.705933,924.459106 1091.094360,920.308105 
	C1099.587646,912.274597 1102.741577,912.155701 1113.026611,918.031738 
	C1115.092651,919.212036 1117.081177,920.723267 1119.500244,919.719299 
	C1131.711548,914.651123 1142.530518,920.538940 1153.163818,924.681885 
	C1162.140137,928.179199 1170.498779,927.994812 1179.484863,925.849365 
	C1189.697754,923.410889 1200.177856,922.605225 1209.827881,917.486816 
	C1225.285278,909.288086 1241.141235,914.760681 1256.579956,918.247375 
	C1264.722656,920.086304 1272.077881,919.959351 1279.996948,917.257202 
	C1294.763428,912.218689 1309.646362,917.542419 1325.212646,918.219360 
	C1329.182983,921.569824 1332.177490,922.980713 1337.331421,921.590576 
	C1346.389771,919.147339 1355.254150,920.558167 1364.220337,924.862000 
	C1377.703735,931.334106 1392.852173,931.866333 1407.103394,929.126953 
	C1420.722900,926.509033 1434.336182,927.819092 1447.829590,925.978271 
	C1449.126709,925.801392 1450.772705,925.919556 1451.701782,926.716064 
	C1466.885254,939.732727 1482.451904,927.234375 1497.792725,927.229553 
	C1502.611206,927.228027 1507.685913,926.940063 1512.409912,926.421326 
	C1529.162964,924.581665 1545.431641,929.562439 1561.980103,928.709778 
	C1570.636597,928.263733 1579.159546,926.744751 1587.207642,923.111511 
	C1597.284424,918.562500 1607.672119,917.197632 1618.265869,921.289612 
	C1621.956787,922.715271 1625.432251,922.698181 1628.811401,920.704590 
	C1638.703979,914.868347 1649.203003,912.945984 1660.509888,915.349854 
	C1664.240234,916.142822 1667.742065,914.763184 1671.152588,913.330078 
	C1675.339111,911.571106 1679.761841,910.900330 1683.658447,913.467529 
	C1693.617798,920.029175 1703.040649,916.592896 1711.916748,911.722107 
	C1719.314697,907.662537 1724.499390,906.373352 1730.595459,914.478149 
	C1735.374634,920.832336 1743.335571,918.251770 1748.598755,914.034485 
	C1760.270508,904.682129 1772.848389,903.931213 1786.135498,908.444031 
	C1807.594971,915.732544 1828.450195,915.588379 1849.327271,906.004333 
	C1856.391113,902.761475 1864.786499,903.401428 1872.067749,899.696533 
	C1875.543823,897.927734 1880.423218,898.817261 1883.435669,901.622131 
	C1891.070801,908.731445 1900.659180,907.609802 1909.751099,908.964844 
	C1917.398315,910.104614 1924.985474,908.613708 1932.596924,908.682739 
	C1945.206299,908.797119 1956.553711,913.151123 1968.418457,915.796997 
	C1971.638916,916.515198 1974.862305,917.762085 1977.878174,915.075073 
	C1982.943481,910.562195 1988.247314,907.449402 1994.655884,912.863953 
	C1995.864014,913.884521 1997.106934,913.756287 1998.130981,912.612305 
	C2006.883545,902.833130 2015.231445,909.853333 2023.363281,913.523682 
	C2028.318970,915.760498 2031.923950,919.753113 2038.271851,914.964478 
	C2042.262207,911.954346 2048.012207,915.456726 2052.102539,918.324036 
	C2057.040283,921.785706 2061.735352,922.081177 2067.061523,919.543091 
	C2073.808594,916.328125 2078.837402,918.562622 2085.160889,922.782104 
	C2097.305420,930.885925 2110.235107,937.762573 2126.492188,932.927673 
	C2144.045166,927.707336 2163.013428,922.726807 2180.490479,927.253845 
	C2193.465820,930.614929 2201.453369,926.146423 2211.650146,921.881470 
	C2219.990967,918.392578 2229.462158,916.497375 2238.374268,915.501709 
	C2262.106445,912.850281 2285.856934,910.061890 2310.009033,912.795044 
	C2330.783447,915.145935 2351.761963,912.996521 2372.333252,909.155701 
	C2378.914795,907.926819 2384.644775,908.072693 2390.734863,910.643005 
	C2397.482910,913.490784 2404.749756,914.890503 2411.774902,916.947815 
	C2420.786865,919.586853 2428.126953,914.555542 2434.602539,909.472412 
	C2445.062988,901.261108 2455.477295,896.253967 2468.304688,904.072144 
	C2470.002441,905.107056 2472.312500,905.875305 2474.066650,904.874390 
	C2483.304932,899.603455 2493.637939,901.797119 2503.394775,900.188293 
	C2512.254639,898.727356 2518.588379,901.069824 2521.333252,911.290344 
	C2530.481201,901.582397 2540.246826,896.959656 2552.350830,904.081665 
	C2553.833008,904.953796 2555.664795,905.154175 2557.124023,904.138611 
	C2568.296631,896.364075 2583.245361,895.691589 2592.859619,884.894592 
	C2597.024658,880.216736 2602.122070,880.514099 2608.327881,881.442017 
	C2624.528809,883.864441 2640.756592,889.159363 2657.415527,883.283142 
	C2663.364014,881.184937 2669.814697,880.763611 2676.068115,879.816711 
	C2684.876465,878.483032 2691.350830,874.273071 2696.114014,866.561646 
	C2701.704346,857.511353 2710.252686,857.617493 2715.423584,866.893921 
	C2717.934326,871.397827 2719.607178,873.808533 2724.980957,870.933533 
	C2728.967041,868.801086 2733.534912,871.723572 2737.794678,870.828491 
	C2746.781494,868.940125 2756.213867,868.867310 2763.299561,861.055481 
	C2767.564697,856.353455 2772.667969,857.512634 2774.622559,864.111084 
	C2776.711670,871.162903 2780.427734,871.197205 2785.725830,867.756531 
	C2788.587158,865.898254 2792.016113,864.581421 2795.238525,865.364929 
	C2807.667969,868.386780 2818.630615,862.777405 2830.000244,859.829285 
	C2834.317871,858.709839 2838.254883,858.212402 2842.690918,859.155212 
	C2853.006836,861.347839 2862.916504,859.252380 2873.203369,855.914612 
	C2870.563965,852.072388 2867.157959,853.467346 2864.519775,852.546265 
	C2861.941650,851.646179 2859.112793,850.954651 2859.211914,847.566223 
	C2859.300781,844.529114 2861.562256,842.217163 2864.222168,842.135864 
	C2873.167236,841.862427 2881.021729,839.258423 2887.681152,833.201843 
	C2888.145752,832.779236 2889.039062,832.492004 2889.573242,832.722290 
	C2901.228760,837.745483 2910.472656,828.590515 2921.059082,827.436523 
	C2923.488770,827.171631 2925.519531,826.277283 2927.304199,824.645874 
	C2928.775391,823.300903 2930.649658,820.850525 2932.118408,821.415588 
	C2941.476807,825.016235 2948.070068,817.299805 2956.098633,816.178589 
	C2961.400879,815.438171 2966.339844,814.673828 2971.343750,812.487244 
	C2979.191650,809.057434 2985.857422,811.503479 2988.275635,819.188354 
	C2990.821777,827.279236 2995.987549,827.578674 3002.238037,825.709961 
	C3009.784424,823.453857 3017.214844,822.827637 3025.405029,825.535278 
	C3026.000000,958.708435 3026.000000,1091.416870 3026.000000,1225.062744 
	C3019.333496,1225.995239 3012.659180,1226.187988 3006.002197,1225.935303 
	C2997.821289,1225.624878 2996.310547,1223.910156 2996.003174,1215.731567 
	C2995.915527,1213.402588 2996.120361,1211.022095 2995.707031,1208.753540 
	C2994.577881,1202.554199 2991.927979,1200.435913 2985.583496,1200.001709 
	C2969.718018,1198.916016 2953.856201,1200.138062 2937.992676,1200.129761 
	C2932.066895,1200.126587 2926.224854,1201.914062 2920.274170,1202.550903 
	C2916.598389,1202.944336 2913.290283,1203.370728 2909.610596,1202.409790 
	C2904.953369,1201.193726 2901.072998,1202.287109 2899.686279,1207.884033 
	C2899.141846,1210.081055 2897.881348,1212.196655 2896.568359,1214.086426 
	C2894.195068,1217.502441 2890.974609,1218.991455 2887.128174,1216.482910 
	C2880.519287,1212.172974 2872.410889,1211.505249 2865.956543,1205.952148 
	C2860.971191,1201.662842 2852.618164,1202.734985 2845.834229,1202.292114 
	C2836.305664,1201.669922 2826.661377,1201.272339 2817.021973,1203.534790 
	C2811.010498,1204.945923 2804.511230,1204.573120 2798.268066,1202.542480 
	C2793.770264,1201.079590 2789.459717,1201.917236 2787.562744,1207.038086 
	C2785.053955,1213.810181 2779.285645,1215.953247 2773.252197,1215.749023 
	C2763.840088,1215.430176 2754.840332,1218.648926 2745.481689,1218.058838 
	C2744.188965,1217.977417 2742.448242,1217.934326 2741.619385,1218.668457 
	C2734.790771,1224.716919 2727.118652,1223.497437 2719.096924,1222.117920 
	C2711.449463,1220.802856 2703.541992,1222.032471 2696.460205,1224.514526 
	C2682.755615,1229.317505 2668.685791,1229.298584 2654.580078,1230.070435 
	C2648.056396,1230.427490 2641.638184,1233.142944 2635.008545,1230.818481 
	C2628.909668,1236.620605 2621.148438,1232.897827 2614.403809,1234.518066 
	C2608.089355,1236.034790 2601.871582,1235.830566 2595.577393,1235.998047 
	C2589.612549,1236.156860 2583.625488,1235.503662 2577.755371,1237.536377 
	C2575.669922,1238.258545 2573.055176,1238.161865 2570.841797,1237.689697 
	C2555.843262,1234.490356 2541.373535,1235.635742 2526.692383,1240.429199 
	C2516.553711,1243.739502 2505.614990,1243.397095 2495.269775,1239.966187 
	C2490.500977,1238.384521 2486.518311,1238.220825 2482.093018,1241.033447 
	C2476.168213,1244.799316 2474.175293,1244.879150 2470.640869,1239.215942 
	C2466.605225,1232.749023 2461.490234,1231.653931 2454.679199,1231.879395 
	C2444.831055,1232.205200 2434.988525,1230.855347 2425.079834,1231.880859 
	C2419.168701,1232.492554 2413.193848,1232.087036 2407.268799,1230.433838 
	C2403.849365,1229.479614 2399.882812,1229.495728 2396.345703,1230.117310 
	C2375.822754,1233.723755 2355.149902,1231.280151 2334.562500,1231.960815 
	C2330.864014,1232.083130 2327.393799,1232.502808 2323.885986,1233.951416 
	C2316.658203,1236.936157 2310.941650,1234.232544 2308.156494,1227.016479 
	C2304.955566,1218.722900 2302.448242,1216.821899 2295.132812,1220.147705 
	C2280.499268,1226.799805 2265.823242,1223.563477 2251.065430,1221.784790 
	C2247.772705,1221.387817 2245.840820,1219.021973 2244.242920,1216.421143 
	C2242.978027,1214.361938 2241.516357,1212.051514 2239.011963,1212.313599 
	C2228.486572,1213.415405 2218.077393,1207.767090 2207.496582,1212.018921 
	C2202.464355,1214.041138 2197.215576,1214.903809 2191.980713,1212.377197 
	C2191.694092,1212.238892 2191.323242,1212.206787 2190.997803,1212.227051 
	C2174.215820,1213.272461 2157.370117,1210.865601 2140.595459,1213.383667 
	C2131.346436,1214.772095 2121.923584,1214.534302 2112.686768,1213.545044 
	C2107.615479,1213.002075 2103.128662,1210.295288 2098.720459,1206.719727 
	C2092.435791,1201.622559 2084.438721,1198.589966 2077.027588,1194.974609 
	C2075.814697,1194.382812 2073.852295,1195.532104 2072.235840,1195.533569 
	C2051.680176,1195.551880 2031.175903,1194.582520 2010.758789,1192.107300 
	C2009.445679,1191.948242 2007.867188,1191.945557 2006.906616,1191.217529 
	C1992.315918,1180.159424 1974.197144,1184.781006 1958.163574,1180.186279 
	C1954.815186,1179.226807 1951.500854,1182.390869 1948.456665,1181.714478 
	C1937.073853,1179.184448 1926.280640,1181.919922 1915.197388,1183.720093 
	C1909.279419,1184.681519 1903.387817,1184.758911 1898.039185,1187.936768 
	C1892.118896,1191.454224 1886.536255,1190.233521 1881.343872,1186.190063 
	C1880.031982,1185.168335 1878.883667,1183.930176 1877.541626,1182.954468 
	C1869.048462,1176.779907 1868.646973,1176.799072 1860.154785,1182.844360 
	C1858.815796,1183.797363 1856.903076,1185.761597 1856.122437,1185.397827 
	C1849.027588,1182.092773 1844.459839,1187.541870 1838.931641,1189.801514 
	C1834.039551,1191.801514 1829.186646,1191.676636 1825.351440,1187.343384 
	C1821.750977,1183.275269 1818.115356,1183.144409 1814.179688,1186.804688 
	C1813.212646,1187.704224 1812.065430,1189.095703 1810.998901,1189.097534 
	C1799.502808,1189.117676 1788.118042,1192.941162 1776.580200,1190.402832 
	C1767.853394,1188.482910 1759.629761,1184.794312 1750.782349,1183.087769 
	C1746.521362,1182.265869 1743.357300,1182.222168 1739.733643,1185.049805 
	C1734.353882,1189.248169 1733.227173,1188.729492 1730.005859,1183.007446 
	C1725.580444,1175.146118 1723.443726,1174.324707 1716.079712,1178.783203 
	C1707.967896,1183.694580 1699.170410,1184.903687 1690.115601,1183.762817 
	C1684.861450,1183.100830 1679.715576,1181.685913 1674.334717,1181.920898 
	C1670.569946,1182.085571 1668.525879,1179.140381 1665.962036,1177.054932 
	C1660.404419,1172.534424 1656.223999,1172.819702 1651.136475,1177.941162 
	C1649.738037,1179.348999 1648.764526,1181.182983 1647.349731,1182.569946 
	C1643.367432,1186.473633 1640.676270,1186.302368 1636.045410,1183.098389 
	C1627.063965,1176.884033 1617.749268,1171.861450 1606.028076,1173.881104 
	C1598.992920,1175.093506 1591.334106,1172.110596 1584.810303,1177.238892 
	C1583.495850,1178.272095 1581.553589,1177.742676 1580.100952,1176.747192 
	C1572.521851,1171.552979 1566.759155,1175.905518 1561.721191,1180.822388 
	C1553.722656,1188.628906 1543.140747,1186.829102 1533.689575,1189.157959 
	C1532.187012,1189.528076 1530.856934,1187.616577 1530.066650,1186.097412 
	C1528.753418,1183.572998 1526.600830,1181.392700 1524.029053,1181.258057 
	C1513.000977,1180.680786 1502.299927,1175.463379 1490.816650,1180.296753 
	C1482.712402,1183.707886 1473.878784,1182.830200 1465.411621,1180.122681 
	C1463.449097,1179.495117 1461.519897,1178.774902 1460.723755,1176.758789 
	C1459.480835,1173.611450 1458.509521,1170.158447 1454.304077,1170.158936 
	C1449.750244,1170.159546 1444.722656,1168.546143 1440.936279,1172.305420 
	C1437.788574,1175.430420 1435.031738,1175.965210 1431.424927,1173.109985 
	C1429.230713,1171.372803 1426.259521,1172.329834 1423.655762,1171.961548 
	C1413.264038,1170.491699 1402.503662,1169.834473 1392.397095,1170.709351 
	C1380.365967,1171.750732 1369.490234,1171.026489 1358.570557,1166.260864 
	C1356.662842,1165.428345 1353.473877,1165.523926 1351.764038,1166.600342 
	C1345.106445,1170.792114 1336.830566,1171.620972 1331.017456,1177.757080 
	C1324.250610,1184.899780 1323.675659,1184.553101 1315.601318,1178.663330 
	C1312.362549,1176.301025 1301.695679,1176.731323 1298.212402,1180.359619 
	C1294.061768,1184.682861 1290.552368,1184.633301 1286.050171,1181.347778 
	C1280.471069,1177.276245 1274.365234,1176.697388 1268.169434,1179.958130 
	C1265.188354,1181.526978 1262.886963,1181.518555 1260.057617,1179.206665 
	C1255.028809,1175.097534 1250.155151,1169.736084 1242.410278,1173.240234 
	C1241.929565,1173.457886 1241.053711,1173.182739 1240.527344,1172.863770 
	C1225.662720,1163.854736 1209.162842,1163.702881 1192.592041,1164.015381 
	C1184.575928,1164.166504 1176.971436,1163.371826 1169.231689,1160.330933 
	C1158.300903,1156.036377 1147.177368,1157.930298 1137.088501,1163.939331 
	C1133.925171,1165.823486 1130.904175,1166.401611 1127.604980,1165.492432 
	C1119.669556,1163.305664 1111.937988,1162.884155 1104.313354,1166.750854 
	C1100.108521,1168.883301 1097.057739,1167.379028 1096.027832,1162.858521 
	C1094.228516,1154.960693 1088.906860,1153.584717 1081.776489,1153.894897 
	C1072.128174,1154.314819 1062.444702,1153.848267 1052.782471,1154.053467 
	C1047.712891,1154.161011 1044.453369,1152.492920 1043.446289,1147.242432 
	C1043.136230,1145.625488 1042.503662,1144.034546 1041.798218,1142.535645 
	C1039.293213,1137.213623 1033.896484,1136.380005 1029.847778,1140.624878 
	C1024.447632,1146.286621 1024.305176,1146.517334 1018.022278,1142.639771 
	C1015.010498,1140.781006 1012.085205,1139.809692 1008.653870,1140.001221 
	C998.467041,1140.570190 988.436096,1139.509033 978.133423,1143.015869 
	C970.340942,1145.668457 960.996521,1143.982544 952.350525,1143.818115 
	C947.699890,1143.729614 946.756653,1139.841431 946.087280,1136.083252 
	C944.309082,1126.099976 944.298950,1126.150879 934.356689,1125.980469 
	C929.061340,1125.889648 923.814880,1126.215942 918.515808,1124.658447 
	C913.272339,1123.117065 907.930176,1125.563843 902.991333,1126.986328 
	C897.859192,1128.464355 893.008057,1130.406982 887.533813,1130.061523 
	C884.539978,1129.872803 882.525085,1130.525635 883.055115,1134.587402 
	C883.789124,1140.212524 879.316711,1140.226196 875.687683,1139.778931 
	C872.241699,1139.354248 867.757385,1138.907593 869.327087,1133.325439 
	C870.266663,1129.984375 868.684814,1128.244019 866.008606,1127.053223 
	C860.493042,1124.598877 855.364258,1119.343140 848.730530,1125.146118 
	C847.494446,1126.227295 844.882202,1126.045898 842.930420,1125.946899 
	C831.673950,1125.375610 820.402161,1127.066162 809.103943,1124.464478 
	C803.535950,1123.182251 797.416260,1124.748413 791.451050,1124.221558 
	C785.739441,1123.717163 780.552917,1123.187134 775.454773,1119.984009 
	C769.690857,1116.362549 767.698303,1117.377075 764.360046,1123.274292 
	C763.704163,1124.432861 763.177856,1125.664185 762.557129,1126.843628 
	C758.613708,1134.337036 758.895203,1134.038208 752.473999,1127.884888 
	C746.873657,1122.518311 739.501648,1121.696777 732.179077,1123.074463 
	C728.518188,1123.763306 724.936890,1123.940552 721.287354,1123.966919 
	C717.792969,1123.992310 714.477722,1123.065430 712.427795,1120.183228 
	C708.122375,1114.130005 701.971008,1113.497681 695.488342,1114.075562 
	C689.175232,1114.638550 683.356934,1117.203003 678.101074,1120.498047 
	C675.454773,1122.157349 674.055664,1126.406250 670.165771,1125.487671 
	C665.121216,1124.296021 659.408569,1124.128418 656.091003,1119.009521 
	C655.005920,1117.335449 654.002808,1115.584961 652.737427,1114.055298 
	C648.848389,1109.354126 645.757935,1108.714966 640.779297,1112.420654 
	C634.559570,1117.049927 626.488525,1117.079834 620.110779,1121.425903 
	C617.876282,1122.948730 614.463623,1122.372437 611.963318,1119.726440 
	C603.392944,1110.656860 593.741150,1109.650024 583.224792,1116.357666 
	C579.115051,1118.979126 574.929871,1120.462646 570.293701,1117.276611 
	C568.026428,1115.718384 565.818970,1117.135498 563.718079,1118.136353 
	C557.263123,1121.210938 551.975769,1119.091431 548.049988,1113.852783 
	C544.846619,1109.578125 541.933716,1109.266968 538.216370,1112.801392 
	C530.244507,1120.380615 520.801147,1123.301025 509.878021,1122.072266 
	C506.558838,1121.698853 503.477020,1121.510986 500.393158,1119.707764 
	C493.831177,1115.870483 486.719177,1114.705566 479.271881,1117.221924 
	C475.385223,1118.535278 471.337006,1117.861450 467.357758,1117.989502 
	C463.720856,1118.106567 460.075470,1117.699707 456.798462,1120.074707 
	C452.346161,1123.301636 447.144501,1121.772705 442.231323,1121.851074 
	C439.329010,1121.897461 438.413269,1119.813599 437.897064,1117.302246 
	C437.079895,1113.327148 434.647858,1110.460938 430.565582,1110.210083 
	C425.272858,1109.884766 423.063293,1108.197754 425.366608,1102.759155 
	C426.015106,1101.227905 425.836700,1099.319580 423.747406,1098.706421 
	C421.709839,1098.108398 421.013550,1099.582642 420.138580,1101.147461 
	C415.999542,1108.549561 415.351440,1108.845093 407.998016,1105.930908 
	C405.162506,1104.807129 402.384888,1103.621216 399.332977,1104.065674 
	C393.919037,1104.853760 388.232239,1107.468750 384.360840,1100.847778 
	C384.078918,1100.365723 382.261353,1100.218872 381.571808,1100.622925 
	C372.240234,1106.090698 359.015411,1105.781616 354.922150,1119.162842 
	C353.870850,1122.599609 350.258179,1122.464844 347.419708,1120.187256 
	C344.428894,1117.787476 343.024261,1114.401245 341.937469,1110.856445 
	C340.735321,1106.935547 338.220123,1105.475220 334.298737,1106.895264 
	C329.681854,1108.567139 325.264801,1107.795532 320.606781,1106.661865 
	C311.957275,1104.556641 303.567932,1106.109985 294.794220,1109.965332 
	C290.620575,1108.937134 287.844147,1106.812012 284.477631,1104.453613 
z"
        />
        <path
          fill="#5486C5"
          opacity="1.000000"
          stroke="none"
          d="
M2.571032,280.464355 
	C2.000000,187.360077 2.000000,94.720146 2.000000,2.000000 
	C919.375183,2.000000 1836.750366,2.000000 2755.062744,2.000000 
	C2761.881836,14.002806 2758.206055,18.850504 2746.727783,24.599279 
	C2737.094238,29.424324 2726.294189,35.303684 2722.751709,47.568539 
	C2720.169678,56.508167 2709.211914,62.307186 2700.648438,59.533024 
	C2697.640137,58.558552 2696.756836,56.270634 2695.864746,53.683628 
	C2694.034912,48.376637 2691.289795,49.029781 2689.550781,53.433994 
	C2684.515625,66.186874 2671.525391,67.220230 2661.640625,72.663895 
	C2656.963623,75.239365 2653.275391,71.978630 2651.515137,67.426132 
	C2649.488037,62.183094 2646.104248,61.523987 2641.169434,63.047115 
	C2636.784668,64.400467 2631.158447,68.599014 2628.434570,61.366302 
	C2625.864014,54.539925 2630.587402,50.951302 2636.397217,48.680691 
	C2637.052734,48.424488 2637.576660,48.061123 2638.325928,46.990376 
	C2626.223145,46.594677 2615.104492,46.934559 2607.787842,58.529953 
	C2604.639404,63.518997 2599.467285,62.193104 2595.103027,60.499321 
	C2587.469482,57.536808 2580.381104,58.408909 2572.789062,61.056381 
	C2565.199707,63.702953 2557.367676,68.321144 2548.967529,62.666409 
	C2546.617188,61.084206 2544.535889,62.619316 2542.787598,64.343735 
	C2540.889160,66.216286 2538.887207,68.045982 2537.316162,70.166267 
	C2528.455566,82.123642 2517.449219,85.602028 2503.101562,80.643517 
	C2494.017578,77.504227 2484.133789,80.443054 2475.715332,83.021904 
	C2465.205322,86.241356 2455.436523,85.085022 2445.235840,84.399841 
	C2442.339844,84.205330 2437.937012,81.280945 2437.159180,86.662399 
	C2436.428955,91.715828 2438.431396,95.894455 2444.459229,96.560127 
	C2449.443115,97.110481 2454.460693,96.599892 2459.436523,97.038116 
	C2463.348145,97.382637 2468.107422,97.129074 2469.040283,102.255356 
	C2469.968994,107.356705 2465.700439,108.923157 2461.927002,110.430954 
	C2453.670410,113.730240 2445.562012,118.544258 2436.499756,112.373802 
	C2435.197754,111.487282 2433.081787,111.702499 2431.620605,112.432007 
	C2420.366943,118.050621 2407.640625,117.639435 2395.755127,120.241844 
	C2384.918457,122.614662 2372.190186,126.266960 2361.051758,118.345062 
	C2356.900391,115.392326 2352.945801,117.344818 2350.482422,120.638947 
	C2341.135986,133.138184 2326.194580,134.861298 2313.314209,140.509979 
	C2309.573975,142.150253 2305.330811,142.486176 2301.758057,140.022675 
	C2293.537598,134.353745 2285.070801,136.422195 2276.892090,139.377640 
	C2270.736084,141.602005 2264.950928,142.255859 2258.401611,141.326157 
	C2247.826416,139.824966 2236.714355,141.559845 2226.888672,143.978989 
	C2219.926270,145.693268 2213.554443,152.459000 2205.207520,154.558533 
	C2197.385742,156.525909 2189.424561,157.472122 2182.722900,154.101654 
	C2171.768066,148.592209 2161.278320,152.172836 2153.302246,157.050568 
	C2140.328369,164.984482 2129.604004,159.209274 2118.493896,155.436890 
	C2114.480957,154.074356 2113.242676,154.714890 2109.694824,156.191269 
	C2099.710205,160.346176 2089.817871,165.456970 2078.645752,166.199066 
	C2072.266357,166.622803 2068.397217,163.665741 2067.233154,157.528885 
	C2066.225830,152.218933 2063.352295,149.279892 2057.992920,148.660843 
	C2056.677490,148.508881 2055.298584,148.175949 2054.204834,147.443298 
	C2035.092041,134.639893 2019.523438,143.856857 2003.951416,155.082596 
	C1995.049683,161.499802 1984.547363,162.961136 1974.522827,158.862289 
	C1961.520020,153.545807 1948.895874,152.081863 1935.728394,157.422272 
	C1933.878906,158.172379 1931.770142,158.829834 1929.863159,158.442062 
	C1907.450928,153.884323 1889.461792,165.689240 1870.982056,174.812637 
	C1863.207031,178.651138 1857.582520,178.152100 1850.992188,172.937210 
	C1840.923462,164.969910 1820.998291,166.209717 1811.763916,174.830521 
	C1809.306763,177.124573 1806.842651,179.335770 1803.789917,180.760147 
	C1800.661865,182.219788 1799.077881,184.293228 1800.301514,187.887177 
	C1801.063843,190.125839 1801.315674,192.357452 1800.193481,194.667267 
	C1796.128784,203.035233 1770.993774,213.781464 1762.093872,210.432938 
	C1753.914185,207.355377 1746.138306,206.351028 1738.012085,210.602219 
	C1731.011841,214.264282 1723.187378,211.201859 1715.605957,212.377823 
	C1703.689697,214.226151 1691.774170,217.599686 1679.554321,217.393036 
	C1674.305298,217.304260 1669.665894,218.163879 1665.661255,221.698090 
	C1661.764526,225.137146 1657.159790,227.633972 1651.992798,226.671371 
	C1639.197510,224.287689 1627.633179,228.920242 1615.902344,232.397034 
	C1607.998901,234.739456 1600.337402,235.737991 1592.410645,232.846359 
	C1586.024414,230.516785 1579.996216,231.165009 1574.485840,235.462814 
	C1571.419312,237.854462 1567.641602,239.203964 1564.185547,237.307251 
	C1550.973999,230.056580 1536.965088,232.326111 1523.095215,233.483002 
	C1515.934692,234.080261 1509.039673,233.751907 1502.649170,230.424103 
	C1496.972046,227.467728 1492.508911,228.634064 1488.453857,233.237259 
	C1483.926758,238.376328 1478.516846,239.955017 1472.416260,236.241409 
	C1468.196167,233.672501 1464.136230,233.985458 1460.100342,236.156189 
	C1446.451416,243.497284 1431.373047,244.511154 1416.513428,244.852951 
	C1402.561523,245.173904 1389.377441,246.948074 1377.033325,253.720932 
	C1371.338013,256.845825 1365.682129,257.371918 1360.551514,252.204941 
	C1356.134399,247.756592 1351.890381,247.983765 1347.898682,252.986542 
	C1345.490967,256.004089 1342.639771,258.209320 1338.427368,256.282318 
	C1322.934082,249.194992 1307.282837,252.810287 1291.760742,255.929886 
	C1284.894043,257.309906 1278.152466,259.059631 1271.083496,259.098358 
	C1265.272583,259.130188 1259.859253,258.190399 1255.070435,254.566223 
	C1249.412354,250.284058 1243.823853,249.619919 1237.802612,254.443878 
	C1230.983643,259.907074 1222.752808,260.116211 1214.275391,260.916840 
	C1196.944702,262.553528 1179.875488,260.102814 1162.793945,260.068756 
	C1151.830322,260.046875 1141.377808,262.676575 1131.419312,256.279846 
	C1130.862061,255.921951 1129.879395,255.742264 1129.502808,256.120605 
	C1118.550293,267.122406 1102.625000,256.375244 1091.098022,264.899597 
	C1090.081177,265.651520 1091.182861,265.587311 1090.228882,265.163788 
	C1076.712036,259.162628 1062.440552,270.068268 1049.020874,263.240265 
	C1046.161011,261.785126 1043.130737,261.976929 1040.227051,262.784363 
	C1030.101074,265.600128 1019.756165,265.034149 1009.434814,265.122803 
	C1001.844299,265.188019 994.780090,263.914917 989.319519,258.104889 
	C985.525085,254.067612 981.657288,254.746811 977.850098,257.820343 
	C972.561646,262.089661 966.659241,263.424683 960.041260,262.243103 
	C955.797913,261.485504 951.771729,261.943054 950.013428,266.552429 
	C946.703064,275.230377 939.825867,277.354858 931.571045,277.068817 
	C923.917053,276.803650 918.381470,278.318604 916.025146,287.307922 
	C913.779785,295.874359 904.473694,299.258453 895.549194,295.055115 
	C886.786499,290.928101 877.433655,289.049561 868.397583,289.590027 
	C851.470703,290.602539 834.702881,288.464142 817.839050,288.900543 
	C803.497375,289.271667 789.164612,288.006653 775.638123,282.568237 
	C770.561096,280.527008 766.385071,280.070740 762.041016,284.371582 
	C756.864990,289.496094 751.835327,288.465057 748.063782,282.304382 
	C744.680237,276.777557 741.186890,276.415649 737.513672,281.851837 
	C734.152039,286.826965 729.627991,288.676208 723.745361,287.767212 
	C719.372375,287.091461 715.119324,288.022156 710.940735,289.278076 
	C702.907715,291.692444 695.415710,291.059296 687.834900,286.907349 
	C677.286011,281.129730 665.529358,285.771912 660.392944,297.020599 
	C657.237305,303.931183 654.261230,304.869629 648.157471,300.213013 
	C637.734436,292.261230 626.805969,289.758881 614.719421,296.133942 
	C610.857788,298.170715 607.175964,297.954498 603.308777,295.885468 
	C595.304199,291.602936 587.166016,292.109497 579.472290,296.525085 
	C574.711853,299.257233 570.379761,299.950989 565.380066,297.308716 
	C558.902222,293.885254 552.238342,297.535675 545.800903,297.942749 
	C534.681396,298.645935 522.442200,295.242584 512.833252,305.133881 
	C509.637848,308.423218 503.990540,307.251495 498.652679,305.773071 
	C494.889801,303.440033 491.675964,301.700806 487.951996,301.216370 
	C482.521088,300.509888 477.057190,298.773254 478.912384,308.447876 
	C480.235474,315.347382 474.140015,318.505524 468.424286,319.500244 
	C462.558746,320.520996 456.589600,320.628296 450.965240,323.064819 
	C448.486694,324.138550 445.497833,324.155945 443.790955,321.466248 
	C442.242523,319.026306 444.077271,317.111816 445.566833,315.283691 
	C447.604492,312.782990 450.996155,310.391998 448.262329,306.735962 
	C445.402863,302.911896 441.067963,301.425781 436.688599,303.524841 
	C429.443481,306.997406 422.560364,308.494507 414.763519,304.832520 
	C411.494324,303.296997 407.683685,305.648193 404.259491,307.012604 
	C393.392517,311.342529 390.025024,310.091675 384.136383,299.707336 
	C383.315308,298.259430 382.516998,296.798126 381.702026,295.346161 
	C378.213501,289.130981 373.391144,286.638733 366.861237,290.633698 
	C360.573181,294.480713 356.202332,293.182709 353.413116,286.475555 
	C351.339111,281.488281 347.015991,280.618866 342.638000,281.284149 
	C331.858002,282.922241 320.646088,281.798615 310.345123,286.691193 
	C303.472229,289.955505 297.225952,286.588104 291.450958,283.306061 
	C286.173035,280.306580 281.113220,280.917236 275.317474,281.077209 
	C260.221741,281.493896 245.919800,289.547028 230.456131,285.426758 
	C228.502136,284.906128 226.395996,285.768250 224.814804,287.006409 
	C217.041550,293.093292 208.448929,293.031647 199.180405,291.558807 
	C188.633972,289.882904 177.993835,290.384155 167.355789,292.134613 
	C155.663940,294.058441 144.300293,294.368134 134.757431,284.780579 
	C129.500870,279.499420 122.330864,280.257538 116.004585,283.339233 
	C108.576027,286.957886 103.006020,286.043732 97.734665,279.274261 
	C92.396660,272.419220 84.123413,272.621857 76.522530,273.429718 
	C68.664612,274.264923 61.843502,273.871338 54.702858,269.359619 
	C45.871063,263.779327 36.716183,267.873779 33.211414,277.458130 
	C32.209293,280.198639 32.191597,283.890381 28.601095,284.515289 
	C24.548553,285.220581 23.935772,281.511292 22.984655,278.794159 
	C21.575380,274.768127 19.559200,275.818665 16.984320,277.596436 
	C12.839822,280.457886 8.557480,283.165497 2.571032,280.464355 
z"
        />
        <path
          fill="#6AA6E3"
          opacity="1.000000"
          stroke="none"
          d="
M3026.000000,825.062683 
	C3022.162109,826.500610 3018.569092,828.744080 3014.388672,826.570435 
	C3011.546387,825.092651 3008.500244,826.591309 3005.855225,828.092285 
	C3002.172852,830.182007 2998.184814,829.933228 2994.184814,830.015686 
	C2988.937256,830.123718 2986.459473,828.015076 2985.882324,822.482422 
	C2984.990479,813.931519 2976.952393,810.460815 2970.507568,816.140930 
	C2966.639648,819.549805 2963.584473,820.026672 2958.915771,818.812805 
	C2954.651611,817.704041 2950.687988,819.002991 2947.850830,823.311523 
	C2944.156006,828.921326 2943.653564,829.015442 2937.998535,824.822876 
	C2934.500488,822.229553 2931.988037,821.856323 2930.118896,825.981873 
	C2928.030029,830.592224 2925.506836,832.934143 2920.131348,830.486328 
	C2917.149902,829.128662 2914.382568,830.743469 2912.023438,832.947205 
	C2906.648682,837.967712 2900.584229,839.861328 2893.834473,835.877563 
	C2888.914795,832.973999 2885.876465,836.057739 2883.965088,839.416504 
	C2880.967773,844.683716 2877.414062,846.463806 2871.787598,844.107178 
	C2870.931396,843.748596 2869.806641,843.972290 2868.810303,844.017761 
	C2865.878906,844.151489 2861.511719,843.537292 2861.399902,847.169922 
	C2861.262207,851.635925 2865.869629,849.496155 2868.452393,849.943787 
	C2871.050049,850.394104 2873.761475,850.197266 2876.352783,850.669983 
	C2878.039795,850.977661 2879.182129,852.363098 2879.467285,854.178772 
	C2879.777832,856.154663 2878.718506,857.329956 2876.971680,857.888245 
	C2872.581055,859.291504 2868.750977,862.385620 2863.762939,862.021484 
	C2859.754639,861.728882 2855.854492,862.324158 2851.956055,863.511719 
	C2849.815918,864.163513 2847.276611,864.129395 2845.040039,863.725525 
	C2837.793945,862.416931 2833.484131,862.324158 2826.936279,864.685486 
	C2823.687256,865.857178 2820.395508,866.348755 2817.129150,865.956421 
	C2812.039062,865.345093 2811.129395,872.845276 2806.158447,871.441956 
	C2802.422852,870.387268 2799.365967,867.602234 2794.981689,867.878357 
	C2791.116699,868.121704 2788.093506,869.134277 2785.118896,871.739807 
	C2778.755127,877.313904 2773.518555,875.370605 2772.054688,867.232361 
	C2771.879395,866.257751 2771.959229,865.238220 2771.907959,864.240112 
	C2771.815918,862.447266 2771.191895,860.863831 2769.294678,860.501831 
	C2767.535400,860.166260 2765.932129,860.997314 2765.041992,862.517334 
	C2762.787842,866.365417 2759.979492,870.825134 2755.655762,870.791199 
	C2746.654297,870.720581 2738.265869,878.191589 2728.902588,872.744263 
	C2726.941650,871.603333 2724.979736,872.603088 2724.083008,874.817627 
	C2722.800781,877.984985 2720.219727,878.219727 2717.457275,877.737549 
	C2713.857422,877.109192 2713.936768,874.129211 2714.037109,871.491516 
	C2714.245361,866.019592 2710.705322,863.819153 2706.330322,862.486206 
	C2703.564209,861.643433 2701.074707,862.032898 2699.957031,865.399353 
	C2695.471680,878.906738 2684.297119,882.383728 2671.901123,883.901123 
	C2664.327148,884.828247 2656.856934,885.899048 2649.712402,888.952698 
	C2642.087402,892.211609 2634.764404,887.329407 2627.190186,887.274963 
	C2624.984863,887.259094 2622.600586,885.392029 2620.420898,886.389038 
	C2615.524658,888.628296 2611.391602,887.037903 2607.247070,884.615112 
	C2603.176758,882.235779 2600.349609,882.067383 2596.103760,886.008545 
	C2588.722656,892.859802 2581.154053,900.278137 2569.685059,900.608765 
	C2565.701416,900.723511 2562.651611,904.135559 2559.875000,907.085754 
	C2556.824951,910.326477 2553.695312,910.644653 2550.393066,907.375854 
	C2548.000977,905.008240 2545.225098,902.302551 2541.860352,902.851868 
	C2537.356201,903.587219 2532.954590,905.349365 2528.713135,907.151123 
	C2525.846680,908.368835 2526.169189,911.540894 2525.844727,914.157471 
	C2525.556396,916.486389 2524.236572,917.735168 2521.862793,917.667969 
	C2519.437256,917.599365 2518.363770,916.096985 2518.143066,913.852783 
	C2517.980225,912.197144 2518.121338,910.474792 2517.717041,908.886597 
	C2516.471436,903.996216 2512.900635,901.130310 2508.165039,902.525452 
	C2501.556641,904.472351 2494.960449,903.866882 2488.345703,903.946106 
	C2484.239990,903.995361 2480.405273,904.189758 2477.383545,907.526306 
	C2474.208008,911.033020 2470.709229,909.710815 2467.461670,907.652893 
	C2462.744873,904.663879 2458.774414,901.166138 2452.031738,903.355469 
	C2446.460938,905.164307 2441.520752,907.090393 2437.537842,911.419617 
	C2432.359375,917.048157 2424.629395,917.982788 2418.290283,921.502625 
	C2417.263428,922.072815 2415.407227,922.172302 2414.415527,921.622070 
	C2405.869629,916.881592 2395.677002,917.158691 2387.127686,912.539795 
	C2382.800049,910.201660 2378.537109,908.713135 2374.209717,912.519714 
	C2373.724121,912.946960 2372.997803,913.502747 2372.471436,913.422058 
	C2361.273193,911.705566 2350.614258,918.667053 2339.333008,915.466064 
	C2338.065186,915.106445 2336.501709,914.374084 2335.476807,914.794067 
	C2325.803467,918.757446 2315.968750,916.591797 2306.110840,916.149231 
	C2301.063965,915.922607 2296.258789,914.222290 2291.360596,914.210022 
	C2282.263916,914.187317 2273.200684,915.835815 2264.173828,916.414185 
	C2256.367676,916.914368 2248.569580,918.327148 2240.691895,918.021606 
	C2236.686035,917.866211 2232.659668,917.674744 2229.952881,921.690491 
	C2228.160889,924.349243 2225.341064,924.378357 2222.786133,923.211304 
	C2218.150391,921.093750 2214.591553,922.768738 2210.810547,925.433289 
	C2206.757324,928.289673 2201.569580,929.365295 2197.988525,933.040771 
	C2194.405029,936.718628 2191.013428,936.449219 2187.783447,932.733276 
	C2184.913818,929.431824 2181.838623,927.634644 2177.326660,929.697021 
	C2175.924316,930.337891 2174.034912,930.061829 2172.379883,929.977234 
	C2154.357666,929.056702 2137.003418,932.501282 2120.109863,938.362488 
	C2115.886230,939.827881 2112.338867,939.797974 2108.846436,937.278564 
	C2104.110107,933.861877 2098.896729,932.050354 2093.056396,931.968567 
	C2088.308838,931.902161 2085.154541,929.639526 2082.629883,925.519592 
	C2078.797607,919.265747 2075.830811,918.486084 2069.588379,921.739014 
	C2067.203613,922.981628 2064.769043,923.920593 2062.300537,924.880737 
	C2058.939453,926.188171 2055.912354,926.517517 2053.456543,923.057251 
	C2051.203613,919.883301 2047.635742,918.814148 2044.096191,917.735596 
	C2040.298462,916.578369 2036.307373,913.941162 2036.881592,921.717041 
	C2037.092163,924.567566 2032.270264,924.935852 2029.050537,921.390686 
	C2024.074829,915.912354 2017.578125,913.600281 2010.962036,911.445251 
	C2003.240845,908.930237 2002.215820,909.583191 1999.978027,917.501831 
	C1999.524536,919.106812 1999.548340,921.014404 1997.491333,921.312439 
	C1994.734375,921.711792 1994.706909,919.384216 1993.895386,917.574707 
	C1990.770996,910.607910 1986.012695,909.791016 1981.410889,916.148987 
	C1977.870483,921.040710 1975.080322,923.516357 1969.501099,919.159119 
	C1966.749634,917.010376 1962.359131,919.308228 1959.873047,917.718628 
	C1951.898926,912.619751 1943.780029,910.770935 1934.704834,913.727356 
	C1926.702148,907.239136 1917.943848,914.209595 1909.393311,912.428406 
	C1903.940552,911.292664 1897.969971,909.073303 1891.780151,911.134338 
	C1889.424072,911.918884 1887.292358,909.091187 1885.409668,907.057556 
	C1878.764404,899.879517 1872.447754,899.843872 1865.469360,907.022156 
	C1863.110229,909.448914 1861.230103,909.812561 1858.664551,907.777954 
	C1856.190674,905.816284 1854.218994,906.606628 1851.550659,908.197144 
	C1840.505493,914.781006 1827.834351,916.293762 1815.465698,917.582214 
	C1809.161255,918.239014 1802.343384,916.021301 1796.049194,914.131714 
	C1790.087036,912.341919 1783.416138,912.610657 1778.208130,908.458252 
	C1769.366577,911.562866 1757.872803,905.735291 1751.325806,916.859497 
	C1748.058105,922.411743 1741.494507,919.721008 1736.578979,921.141602 
	C1731.381104,922.643738 1727.188232,919.650635 1725.789185,913.603210 
	C1724.674072,908.783386 1720.613037,909.091553 1718.078003,911.534851 
	C1712.126831,917.270386 1704.503174,918.006165 1697.246826,920.239502 
	C1692.118530,921.817932 1688.099243,920.975464 1684.509155,917.420593 
	C1681.314819,914.257446 1677.377075,912.451111 1673.819946,915.355347 
	C1667.871826,920.211487 1661.535278,919.523804 1655.151978,917.915955 
	C1644.386719,915.204468 1635.856934,919.994812 1627.583740,925.535889 
	C1624.975952,927.282593 1622.856079,927.813599 1620.354736,926.104980 
	C1610.406006,919.309387 1599.808472,920.618774 1590.011719,925.297363 
	C1576.221069,931.883362 1561.502319,933.940063 1547.317383,931.355896 
	C1537.012451,929.478516 1527.120361,930.840820 1517.169922,929.759949 
	C1509.134644,928.887207 1501.761230,928.785583 1493.989380,931.654053 
	C1489.690430,933.240662 1484.663818,934.628784 1479.452148,932.437378 
	C1475.464600,930.760803 1471.976318,932.954102 1468.940063,936.224915 
	C1464.245483,941.282288 1462.297607,941.296692 1459.191772,936.045471 
	C1455.148926,929.209778 1449.785645,927.753479 1442.392700,929.347900 
	C1437.915161,930.313538 1433.126831,929.902405 1428.475952,929.983704 
	C1421.194336,930.110962 1413.823975,929.178284 1407.036255,933.157593 
	C1405.462891,934.080017 1403.070435,934.234314 1401.197388,933.912354 
	C1390.423096,932.060547 1379.337524,932.910339 1368.689819,930.097351 
	C1367.102295,929.678040 1365.279541,929.479675 1364.031738,928.552002 
	C1355.294067,922.056091 1345.910034,921.475952 1335.938232,925.000122 
	C1331.777466,926.470581 1327.992676,926.665833 1326.121338,920.728394 
	C1338.027588,912.354736 1350.542847,913.063904 1363.024414,918.308472 
	C1366.990479,919.974915 1370.576660,922.300598 1375.036621,922.841248 
	C1385.806763,924.146912 1391.821899,917.894409 1390.222168,907.111816 
	C1389.387085,901.482666 1385.239502,901.494446 1381.284302,901.347595 
	C1369.329956,900.903931 1357.432983,902.798096 1345.381470,901.446899 
	C1337.378662,900.549622 1330.932251,899.109863 1328.827026,890.249817 
	C1328.144043,887.375122 1326.063477,885.781677 1323.388672,884.906189 
	C1316.848145,882.765259 1314.220459,877.963379 1313.325806,871.412903 
	C1311.894531,860.930298 1315.276733,857.240601 1325.314819,860.552795 
	C1333.638062,863.299133 1342.004028,864.973450 1350.640015,865.801331 
	C1352.968506,866.024536 1355.289551,866.328430 1357.586304,865.708191 
	C1358.922607,865.347351 1360.107300,864.545593 1360.459473,863.192444 
	C1360.956787,861.282532 1359.748901,860.131409 1358.195801,859.262146 
	C1356.414795,858.265381 1354.465942,857.575195 1352.483521,857.627625 
	C1342.602295,857.889038 1334.242676,856.178467 1329.856079,845.478638 
	C1327.824707,840.523682 1321.919434,841.654724 1317.329834,841.181702 
	C1312.329224,840.666321 1304.002075,842.622314 1304.000610,836.284424 
	C1303.998779,828.717773 1312.638672,831.223999 1317.774536,830.923645 
	C1324.297974,830.542114 1330.018555,829.021362 1335.530640,825.306702 
	C1345.108154,818.852173 1363.897705,820.470520 1371.544678,828.785767 
	C1376.253296,833.905823 1381.054688,834.379822 1386.761841,832.257690 
	C1399.044678,827.690674 1411.671875,829.822571 1424.263306,829.388367 
	C1442.882446,828.746094 1460.456421,833.105652 1478.305298,836.695679 
	C1483.999146,837.840820 1489.464844,836.801453 1495.014404,835.130615 
	C1507.669434,831.320557 1512.569946,835.320251 1511.644775,848.456848 
	C1511.424072,851.589478 1510.942383,854.335144 1514.415894,856.110352 
	C1523.369019,860.685730 1532.495239,864.399231 1542.849976,864.003540 
	C1547.331177,863.832397 1550.222290,862.586853 1548.558960,857.451843 
	C1547.443848,854.008972 1545.600952,851.831970 1542.473755,855.931824 
	C1539.744263,859.510315 1536.519531,863.637512 1531.888794,860.235474 
	C1526.285645,856.118896 1521.293335,850.668091 1520.578857,843.280273 
	C1520.046143,837.773010 1524.724487,835.401978 1529.002441,833.292542 
	C1530.199707,832.702087 1531.589233,831.798157 1532.754517,832.055603 
	C1545.564941,834.885498 1558.166260,828.159424 1570.997803,831.354492 
	C1575.150391,832.388550 1580.212524,833.760132 1582.865723,829.142639 
	C1590.078247,816.589722 1602.280640,816.495972 1612.727905,819.993164 
	C1623.585571,823.627625 1631.403564,821.231995 1641.402344,817.618835 
	C1659.591431,811.045898 1679.096191,809.085083 1698.575073,811.600464 
	C1707.971680,812.813904 1717.140747,813.317871 1726.191772,810.009155 
	C1726.815918,809.780884 1727.487061,809.551453 1728.137207,809.574646 
	C1748.768066,810.312134 1768.928223,805.219421 1789.369385,804.558655 
	C1798.107666,804.276245 1806.856079,806.411865 1815.132690,802.112976 
	C1811.975952,798.301331 1809.061401,796.379944 1803.974121,800.668518 
	C1800.242065,803.814758 1786.010742,799.845520 1782.742676,795.911316 
	C1779.527222,792.040100 1781.417725,789.020325 1784.615845,786.608643 
	C1788.834839,783.426941 1791.451660,779.404236 1792.310303,774.224304 
	C1792.714722,771.784790 1792.511841,769.601013 1789.911865,768.299500 
	C1783.496582,765.088318 1771.051025,771.189453 1769.258179,778.468140 
	C1766.496460,789.680054 1760.878540,793.015991 1750.673096,789.593689 
	C1744.987427,787.687012 1741.718628,784.172119 1741.939941,777.825012 
	C1742.107666,773.014709 1742.989258,767.997986 1740.105591,763.721313 
	C1737.467285,764.134766 1736.995605,765.854187 1736.130859,767.199219 
	C1729.888550,776.909912 1721.862427,777.240295 1715.000244,768.073059 
	C1709.837524,761.176086 1711.390869,753.922607 1719.585571,751.533508 
	C1730.044189,748.484375 1733.773315,737.856445 1742.462891,732.924255 
	C1744.750000,731.626160 1743.343994,727.941223 1743.009766,725.332397 
	C1742.629883,722.366821 1741.144897,718.710999 1743.173462,716.587036 
	C1749.898926,709.545837 1758.225464,704.267517 1764.987183,697.096130 
	C1767.560791,694.366516 1793.798828,705.060913 1795.644653,708.820251 
	C1797.727173,713.062073 1798.087524,717.790344 1799.075195,722.342102 
	C1801.305298,732.620728 1809.052246,736.088013 1817.251221,729.350281 
	C1824.689575,723.237671 1831.424072,720.204407 1840.732300,724.904114 
	C1845.823853,727.474670 1850.555054,724.813782 1853.934570,720.600037 
	C1859.390869,713.797180 1866.347046,712.625610 1874.551270,713.722717 
	C1891.128662,715.939514 1907.828491,714.506653 1924.475342,714.636963 
	C1926.991821,714.656677 1930.519897,714.962891 1931.022949,711.556824 
	C1931.553345,707.964966 1928.011108,707.554138 1925.535400,707.063904 
	C1918.241455,705.619568 1912.694458,701.923706 1908.801392,695.537476 
	C1905.193848,689.619873 1899.481201,687.711060 1892.822754,686.800842 
	C1880.363892,685.097839 1875.609375,680.250061 1873.079834,668.946411 
	C1871.743164,662.974060 1873.729980,659.356140 1879.379272,657.287842 
	C1889.892578,653.438538 1900.754028,650.935852 1911.711792,653.831970 
	C1930.243774,658.729797 1947.077148,650.285095 1964.703979,648.221680 
	C1965.364380,648.144409 1966.157349,647.993835 1966.553101,647.531006 
	C1980.126099,631.650818 2001.788940,636.776550 2018.227905,627.679626 
	C2027.627563,622.478088 2039.213623,627.585754 2049.827148,626.904724 
	C2056.530518,626.474548 2063.170410,626.672791 2068.887939,622.378784 
	C2069.951416,621.580200 2071.259033,620.904785 2072.554443,620.865906 
	C2096.777588,620.138916 2119.683838,610.091797 2144.105225,610.796509 
	C2168.065430,611.487976 2192.015381,610.418823 2216.024658,611.863098 
	C2231.317627,612.783020 2246.765869,610.995300 2262.238770,607.011047 
	C2281.441406,602.066345 2301.892822,601.081116 2321.742432,603.328186 
	C2337.897705,605.157043 2353.664307,603.873901 2369.523193,602.238403 
	C2377.219482,601.444702 2383.666260,602.289856 2390.019775,607.326355 
	C2398.150391,613.771606 2402.079346,612.310669 2405.570312,602.622192 
	C2407.775635,596.501831 2412.061523,593.454163 2418.278320,593.112549 
	C2428.928955,592.527161 2439.594727,592.555481 2450.250000,593.117493 
	C2457.182373,593.483093 2461.535156,598.289001 2466.265625,602.497192 
	C2468.981934,604.913574 2472.019775,608.044189 2475.908203,605.709229 
	C2480.069336,603.210327 2476.689209,599.804749 2475.843262,596.880981 
	C2474.981934,593.904175 2473.859619,590.836548 2477.201172,588.869812 
	C2480.994873,586.637329 2484.809082,587.140320 2488.401123,589.783386 
	C2497.701660,596.626709 2497.836426,596.645874 2501.957275,586.345032 
	C2503.884766,581.526306 2507.335205,579.264282 2512.095947,580.484192 
	C2519.072021,582.271851 2524.419678,580.031616 2529.397461,575.466125 
	C2532.159180,572.932922 2535.609863,571.561279 2539.297607,571.001282 
	C2543.799072,570.317871 2547.184326,568.106812 2550.268311,564.729065 
	C2556.819824,557.554260 2566.156738,554.086487 2574.709961,556.423889 
	C2587.997559,560.055176 2599.748047,556.182373 2611.909912,553.180359 
	C2617.456543,551.811218 2622.944092,550.603577 2628.653320,550.815491 
	C2635.476807,551.068848 2641.870361,550.107300 2647.557617,545.846497 
	C2650.866699,543.367126 2654.948730,542.894043 2658.997314,542.878357 
	C2665.680908,542.852417 2672.300293,542.530029 2678.786133,540.624756 
	C2682.195801,539.623169 2685.658203,540.150879 2687.943604,543.016235 
	C2692.507812,548.739014 2696.578369,546.855896 2701.575928,543.368530 
	C2710.701904,537.000549 2718.517578,537.144470 2727.209717,544.068237 
	C2732.843750,548.556335 2737.161621,548.855835 2744.193359,545.882568 
	C2756.978271,540.477051 2770.980469,540.504272 2783.925293,546.104736 
	C2789.368408,548.459473 2793.503174,549.671753 2798.653076,546.263428 
	C2804.825439,542.178040 2811.208496,542.322144 2817.321533,546.706238 
	C2824.069336,551.545776 2830.830078,551.729858 2837.694580,546.928711 
	C2840.755615,544.787720 2844.171143,543.000305 2847.933838,543.551941 
	C2866.677734,546.300293 2885.849365,538.949341 2904.439941,546.095093 
	C2907.503174,547.272644 2911.363281,546.939453 2914.105713,544.904175 
	C2920.927734,539.841431 2928.002441,539.471558 2935.827393,542.043701 
	C2939.612549,543.287842 2944.479004,544.013489 2947.590088,541.965271 
	C2956.404053,536.162781 2964.838379,539.298401 2973.965576,540.487000 
	C2990.755371,542.673462 3007.793701,540.572144 3025.361816,541.546692 
	C3026.000000,636.041809 3026.000000,730.083557 3026.000000,825.062683 
z"
        />
        <path
          fill="#568CCD"
          opacity="1.000000"
          stroke="none"
          d="
M497.420074,304.428986 
	C501.004089,304.004120 504.003357,303.984070 507.002441,303.996765 
	C510.072540,304.009796 512.712952,303.875702 514.284241,300.265656 
	C516.670776,294.782379 522.136230,294.008453 526.969971,294.877899 
	C531.396606,295.674133 535.357300,296.002716 539.636230,294.528015 
	C542.465088,293.553101 545.599121,293.646606 548.516113,294.539856 
	C550.855835,295.256409 552.876587,295.508850 555.107056,293.981781 
	C559.934937,290.676392 564.493774,291.379395 568.568359,295.524109 
	C570.879883,297.875366 573.240234,297.876617 575.463989,295.472656 
	C582.380554,287.995667 591.407654,290.287415 599.909058,290.105286 
	C604.294250,290.011353 605.020630,296.662689 609.902649,295.540710 
	C613.820496,294.640320 615.746033,290.444336 620.216797,289.917358 
	C632.053528,288.522156 642.960938,289.026978 651.095642,299.500244 
	C653.396790,302.462921 656.624939,301.442078 657.641357,297.660217 
	C658.065247,296.082947 657.931458,294.354889 658.043152,292.694855 
	C658.382019,287.658325 661.013733,284.532990 666.050354,284.148346 
	C669.457703,283.888123 672.653748,282.979553 675.685242,281.727264 
	C680.825317,279.604034 684.954956,279.815338 689.293579,283.964325 
	C694.603149,289.041870 701.403625,289.053589 708.077271,286.897430 
	C714.460693,284.834961 720.673828,282.564941 727.466919,285.404572 
	C729.926880,286.432892 732.034729,285.095337 733.386780,282.913055 
	C734.436951,281.217957 735.158325,279.315857 736.238403,277.643066 
	C740.582764,270.915192 746.016968,271.156525 749.729797,278.239014 
	C750.495483,279.699615 750.817017,281.392151 751.575317,282.857697 
	C753.455688,286.491821 757.054810,286.864594 758.697632,283.871002 
	C764.055969,274.106506 770.306763,277.081635 778.459839,280.096710 
	C793.809082,285.772980 810.001221,287.772736 826.366516,284.744446 
	C829.894836,284.091553 832.934448,284.311035 835.954468,285.989105 
	C839.962280,288.216003 844.281738,288.685120 848.546570,287.442444 
	C862.180542,283.469757 875.588440,287.906189 889.097534,288.267395 
	C891.578918,288.333710 893.097839,290.455658 894.973328,291.808960 
	C899.927063,295.383575 904.975220,294.400330 910.025024,291.909149 
	C913.620361,290.135437 913.694641,286.896057 914.094971,283.474792 
	C914.944458,276.214203 917.073303,274.367645 924.298157,274.041077 
	C928.289307,273.860687 932.297180,274.080994 936.292786,273.961700 
	C941.288818,273.812531 944.822876,272.466583 946.422974,266.584656 
	C948.837952,257.707733 950.851807,256.955292 960.416687,259.213196 
	C966.383423,260.621704 971.546021,260.361725 975.814270,255.303299 
	C977.058105,253.829224 978.904724,252.807220 980.597778,251.780487 
	C984.267273,249.555222 987.520142,249.636520 989.874268,253.793228 
	C993.621399,260.409698 999.605225,262.235962 1006.795654,262.046265 
	C1019.057190,261.722839 1031.445190,263.144226 1043.322266,258.565186 
	C1046.353027,257.396729 1049.242432,258.262115 1051.566406,260.621857 
	C1056.496582,265.627899 1062.095825,264.935638 1066.937988,261.245697 
	C1072.508545,257.000671 1077.400635,257.613342 1082.306763,261.553589 
	C1085.851562,264.400452 1088.751709,263.961731 1092.068481,261.239868 
	C1097.505249,256.778259 1102.668091,254.488144 1109.965576,259.231323 
	C1114.670654,262.289612 1120.044434,258.486572 1124.262451,254.779877 
	C1129.849731,249.869904 1129.938843,249.971390 1135.429077,254.414337 
	C1142.680786,260.282776 1150.144043,261.042908 1158.246948,256.514404 
	C1160.291992,255.371521 1162.171875,253.464127 1164.588501,254.912399 
	C1174.552368,260.883453 1186.054321,256.771240 1196.461304,259.894775 
	C1198.663574,260.555695 1201.085449,259.539093 1203.156128,258.365967 
	C1206.517700,256.461578 1209.740967,256.440430 1213.625366,257.354828 
	C1222.171265,259.366547 1230.191406,257.217865 1236.937134,251.542816 
	C1242.819702,246.593948 1249.516846,244.851288 1254.257690,249.946899 
	C1261.441650,257.668549 1269.997314,256.521484 1278.317017,255.597610 
	C1283.679810,255.002121 1289.615845,254.864792 1294.451050,251.186188 
	C1296.957275,249.279419 1300.248657,250.167892 1303.215454,249.992783 
	C1311.476562,249.505203 1319.845947,251.202103 1328.007080,248.433838 
	C1332.145508,247.030090 1334.923706,249.777390 1337.500122,252.498978 
	C1340.250732,255.404541 1342.313965,256.411346 1345.119995,252.009521 
	C1351.682251,241.714951 1355.301880,241.586700 1363.594116,251.187225 
	C1366.437012,254.478790 1369.180664,254.254272 1372.398682,252.712463 
	C1376.870483,250.569885 1381.117310,247.881775 1385.723755,246.112625 
	C1394.818359,242.619873 1404.309448,241.363144 1414.095337,241.942947 
	C1421.413086,242.376495 1428.796631,242.442932 1435.650024,239.118591 
	C1438.320312,237.823364 1440.855347,239.243591 1443.387573,239.157883 
	C1448.430664,238.987122 1452.768555,236.427200 1457.225342,234.046127 
	C1462.852295,231.039993 1468.742432,226.785614 1474.866821,234.386169 
	C1477.282959,237.384766 1481.516235,236.843552 1484.343628,233.237366 
	C1485.573364,231.668777 1486.886353,230.148804 1488.294678,228.739853 
	C1493.996460,223.035553 1495.086182,222.863998 1501.985474,226.768661 
	C1507.925537,230.130508 1513.963501,230.983337 1520.970703,230.481812 
	C1532.067627,229.687576 1543.234741,227.418579 1554.495483,229.880142 
	C1557.540527,230.545776 1560.158813,231.281586 1562.486694,233.382797 
	C1565.948486,236.507507 1569.145264,236.929596 1572.728271,232.941315 
	C1578.396729,226.631516 1585.749634,226.520645 1592.902100,229.645584 
	C1599.458984,232.510284 1605.782227,232.900345 1612.218384,230.174789 
	C1620.207642,226.791626 1629.098999,226.578781 1636.973877,222.777191 
	C1640.110352,221.262985 1643.425659,222.264771 1646.716431,223.174835 
	C1653.553833,225.065720 1659.879028,224.595520 1664.682617,218.358444 
	C1669.385620,212.251862 1676.078613,213.528290 1682.184326,214.605606 
	C1685.802002,215.243942 1688.851440,215.201401 1691.531860,212.994202 
	C1696.203369,209.147232 1701.408813,209.624496 1706.711792,210.616302 
	C1708.646362,210.978134 1710.691406,212.483124 1712.483765,211.383820 
	C1718.941528,207.423080 1725.296387,204.891541 1732.590332,209.553085 
	C1733.933838,210.411697 1735.733887,208.969025 1737.067261,207.932907 
	C1744.270142,202.336441 1752.265381,202.761139 1759.726440,206.382904 
	C1768.123657,210.459045 1774.665405,211.026886 1782.126831,203.770645 
	C1786.065796,199.940231 1792.715698,198.721207 1797.178467,194.483109 
	C1799.458740,192.317673 1799.275391,191.084793 1797.437378,188.774826 
	C1793.514893,183.844986 1794.121338,180.023773 1799.751465,178.689453 
	C1805.182129,177.402374 1808.580811,174.797562 1811.225586,170.195694 
	C1813.511108,166.219223 1817.565308,165.942764 1821.744507,165.944855 
	C1826.646729,165.947327 1831.714111,166.914597 1836.110596,163.319199 
	C1838.943848,161.002228 1841.951050,162.701599 1844.434692,164.326477 
	C1848.594849,167.048065 1852.925659,169.554840 1856.393311,173.269073 
	C1858.902344,175.956375 1862.301758,177.277756 1865.248413,174.518814 
	C1870.766846,169.352310 1878.786011,168.990524 1884.332764,163.975662 
	C1885.473022,162.944855 1887.424072,162.184875 1888.931030,162.292084 
	C1896.315674,162.817352 1901.974976,161.219666 1903.843262,152.870438 
	C1904.365234,150.537674 1906.513794,150.440857 1908.637939,151.811234 
	C1917.546631,157.559067 1927.643921,157.244644 1936.526733,153.768570 
	C1949.936646,148.520920 1962.280029,149.836472 1974.473755,155.841766 
	C1978.717651,157.931885 1982.877319,158.684357 1986.952515,157.642014 
	C1993.869629,155.872818 2002.382935,157.219818 2005.924805,147.834137 
	C2007.131104,144.637512 2012.116699,144.413254 2015.549438,143.171219 
	C2023.346680,140.349945 2031.905640,140.461136 2039.481323,136.593384 
	C2042.796143,134.901016 2046.029785,136.648132 2048.349365,139.409241 
	C2052.203613,143.997574 2056.667969,146.623779 2063.033691,146.174881 
	C2068.279541,145.804962 2069.268799,150.103958 2069.941406,154.255707 
	C2071.574463,164.334976 2071.582031,164.242874 2081.750000,162.447159 
	C2086.546387,161.600098 2091.928467,162.601913 2095.054199,157.315948 
	C2095.452148,156.643158 2096.847412,156.159042 2097.725098,156.234100 
	C2105.028809,156.858536 2110.640137,155.632629 2111.358398,146.571365 
	C2111.507324,144.693954 2114.954346,143.953629 2115.532959,146.840897 
	C2117.192871,155.126160 2123.184082,155.886765 2129.857666,156.029022 
	C2132.164551,156.078171 2135.072021,155.659378 2136.657227,156.849747 
	C2142.342041,161.118042 2146.311523,160.114853 2149.480469,154.022766 
	C2149.755127,153.494644 2150.498535,152.894547 2151.046387,152.872055 
	C2158.438232,152.568756 2165.158691,150.172043 2171.653320,146.783905 
	C2172.498291,146.343094 2174.524170,146.996735 2175.314209,147.811478 
	C2180.271240,152.924744 2187.268799,151.203857 2193.080566,153.363724 
	C2195.729004,154.348007 2198.283691,153.743347 2200.811279,152.583618 
	C2202.914307,151.618790 2205.130127,150.308929 2207.326904,150.246674 
	C2215.009277,150.028931 2220.934570,148.033905 2222.801758,139.385727 
	C2223.354980,136.823349 2225.908691,135.628586 2228.015625,138.454330 
	C2230.204346,141.389740 2232.419189,140.837646 2235.737549,140.361221 
	C2244.802002,139.059860 2253.865723,134.929214 2263.250488,139.351700 
	C2266.998535,141.117813 2270.583984,139.150757 2273.571533,137.120071 
	C2280.078369,132.697586 2287.906738,134.911606 2294.787109,132.438873 
	C2296.747314,131.734406 2298.372314,133.704895 2299.950439,134.948837 
	C2305.637451,139.431107 2311.110596,140.173386 2316.607178,135.959335 
	C2320.323486,133.110489 2323.916260,131.726685 2328.457031,131.956207 
	C2337.083984,132.392258 2340.180664,124.055237 2346.266113,120.418533 
	C2347.886230,119.450256 2348.777100,117.339729 2350.260742,116.050331 
	C2356.060059,111.010201 2358.571533,110.678535 2363.775146,116.233749 
	C2367.389160,120.091621 2370.942139,120.377342 2375.410156,119.247673 
	C2377.647705,118.681992 2379.948486,118.184364 2382.242676,118.057770 
	C2388.897705,117.690575 2396.049561,120.497429 2401.799072,114.559494 
	C2402.514160,113.821007 2404.367432,113.957108 2405.667725,114.049873 
	C2414.173828,114.656845 2422.163574,113.052940 2429.824951,109.230080 
	C2433.983643,107.154823 2438.286865,107.252670 2441.802490,110.860550 
	C2445.265137,114.414047 2448.731445,114.731911 2452.038574,110.912819 
	C2455.077393,107.403473 2459.500488,108.626778 2463.121826,107.219208 
	C2465.251465,106.391342 2467.866455,105.477051 2467.286865,102.489502 
	C2466.809814,100.029579 2464.418213,100.064079 2462.421875,100.045784 
	C2456.424072,99.990768 2450.420166,100.158699 2444.428467,99.951958 
	C2436.076172,99.663750 2434.427002,98.313080 2434.140381,90.169098 
	C2434.014160,86.586479 2431.474365,83.287239 2433.159912,78.857895 
	C2442.193604,80.577095 2451.223145,82.024261 2460.366211,82.744972 
	C2464.209229,83.047905 2467.253174,83.518997 2470.504395,81.008682 
	C2474.058594,78.264542 2478.266602,77.182762 2482.771240,78.573471 
	C2484.723633,79.176277 2486.542236,79.659805 2488.475098,78.380424 
	C2494.421387,74.444633 2500.365479,75.722656 2506.519287,78.167229 
	C2517.038330,82.345688 2523.052002,80.833290 2530.362549,72.697586 
	C2534.577148,68.007172 2539.597412,64.096046 2543.027588,58.620365 
	C2544.979980,55.503658 2548.042969,55.151360 2550.209717,58.667236 
	C2553.334473,63.737549 2556.838135,63.488785 2561.969727,61.328083 
	C2568.033936,58.774750 2574.758789,57.844784 2580.750977,54.811268 
	C2583.296143,53.522919 2585.906982,54.228470 2588.496338,55.169159 
	C2592.248047,56.532124 2596.093262,57.636402 2599.864258,58.948959 
	C2602.765625,59.958683 2604.722656,59.915092 2605.991699,56.328968 
	C2609.041016,47.711983 2616.699707,45.354752 2624.604980,44.115246 
	C2629.475098,43.351616 2634.567383,44.543892 2639.403320,42.583263 
	C2641.211670,41.850079 2643.596924,41.502033 2644.765625,43.580578 
	C2645.877686,45.558784 2644.263916,47.380337 2643.267822,49.053928 
	C2641.695312,51.696812 2639.004639,51.946434 2636.281494,52.065681 
	C2633.357910,52.193714 2631.072754,53.330551 2630.364990,56.405544 
	C2629.918213,58.346390 2629.524414,60.503742 2630.884033,62.162163 
	C2632.617920,64.277283 2634.514404,62.551754 2636.231201,61.842243 
	C2638.690674,60.825844 2640.982666,59.314354 2643.510010,58.570354 
	C2649.659424,56.759933 2652.414795,58.594280 2654.001709,65.103485 
	C2655.723877,72.167847 2657.413330,73.199821 2663.286865,69.036118 
	C2668.042725,65.664711 2673.468262,64.195526 2678.537109,61.762543 
	C2682.548584,59.836994 2685.880615,57.896439 2686.217041,52.460361 
	C2686.514648,47.645836 2691.487305,43.627304 2694.675293,44.852329 
	C2698.974609,46.504272 2700.748779,49.810150 2699.002930,54.483845 
	C2698.648193,55.433155 2698.821289,56.434917 2699.937012,57.211151 
	C2702.201416,58.493431 2704.226074,56.618721 2706.434082,56.258373 
	C2718.522705,54.285454 2718.730957,54.293858 2719.934814,42.821152 
	C2720.398682,38.401646 2722.733398,36.098186 2726.406006,34.954498 
	C2732.173096,33.158642 2736.488281,30.118774 2738.356201,24.076021 
	C2739.428711,20.606888 2742.216797,20.025913 2745.545654,20.220718 
	C2754.443604,20.741449 2755.676025,19.559536 2755.985596,10.740610 
	C2756.067383,8.410436 2755.999268,6.074994 2756.000000,2.870986 
	C2761.400146,2.000000 2766.800049,2.000000 2773.100098,2.000000 
	C2775.144043,3.159080 2776.352539,4.259117 2777.458496,5.449355 
	C2781.731934,10.048024 2782.418701,6.522257 2783.565186,2.495165 
	C2864.479492,2.000000 2944.959229,2.000000 3026.000000,2.000000 
	C3026.000000,46.706890 3026.000000,91.416130 3026.000000,137.062683 
	C3022.476318,140.643784 3019.791016,137.821869 3016.840088,136.617538 
	C3006.297119,132.314377 3000.966553,135.497208 3000.752686,146.470093 
	C3000.584229,155.120239 2997.634033,161.526749 2988.286621,162.991501 
	C2983.485107,163.743896 2980.703857,166.656631 2978.371826,170.523483 
	C2976.364746,173.851944 2972.899170,177.230957 2969.459717,175.259232 
	C2958.663086,169.070114 2949.030029,179.231262 2938.480469,176.442184 
	C2928.300293,173.750824 2918.169434,176.576294 2907.898193,177.856201 
	C2896.865967,179.230957 2886.213867,180.182190 2876.648926,186.352707 
	C2874.419922,187.790741 2871.429443,188.508972 2870.014648,190.693192 
	C2860.847900,204.846085 2848.327148,200.368561 2836.140625,196.851364 
	C2831.677002,195.563141 2827.452881,193.354050 2822.693115,193.321808 
	C2814.858398,193.268723 2807.483887,192.960312 2803.061523,184.572754 
	C2801.144287,180.936096 2796.735596,180.952560 2793.224854,182.846390 
	C2790.352295,184.395859 2789.091309,187.119003 2790.623291,190.481384 
	C2792.519043,194.642059 2796.039307,199.062317 2791.444580,203.206329 
	C2786.461670,207.700546 2779.373291,209.818665 2773.706787,207.208344 
	C2759.815430,200.809555 2746.289062,205.951157 2732.663330,206.672089 
	C2726.694092,206.987930 2720.817383,208.469772 2714.860352,206.674530 
	C2708.716309,204.822891 2703.092773,205.875595 2697.745117,209.457275 
	C2691.616455,213.562012 2684.965820,214.691513 2677.718506,212.596252 
	C2670.260986,210.440277 2665.982422,212.782928 2662.080078,220.371201 
	C2657.178711,229.901901 2645.582764,229.918137 2636.381592,232.815414 
	C2632.974854,233.888123 2629.110107,232.868317 2625.458740,232.454865 
	C2606.853516,230.348251 2589.783691,233.943604 2575.179932,246.322250 
	C2570.661377,250.152283 2565.968750,250.373001 2561.642334,246.550201 
	C2556.110840,241.662582 2550.635498,241.870605 2544.143799,244.572556 
	C2533.700684,248.919250 2522.357666,250.761154 2512.015381,255.523743 
	C2510.193848,256.362610 2508.023682,257.074463 2506.174072,256.538910 
	C2486.838623,250.940323 2471.375732,264.473999 2453.727051,267.434113 
	C2444.103027,269.048309 2435.447266,271.482941 2425.570801,268.502808 
	C2414.600342,265.192627 2402.993408,261.231293 2392.384277,270.408752 
	C2390.641846,271.916016 2387.898438,272.543915 2385.703613,271.867737 
	C2373.954346,268.248413 2364.989990,274.207397 2356.244141,280.377960 
	C2349.782715,284.936981 2343.372070,287.319427 2335.835938,283.341644 
	C2335.251953,283.033356 2334.510742,282.616852 2333.949951,282.755646 
	C2314.223633,287.637146 2293.497070,275.619720 2274.125977,286.291168 
	C2261.667236,283.299500 2251.163818,294.631317 2237.941895,290.540741 
	C2231.517090,288.553040 2223.565186,291.088013 2216.551758,293.853363 
	C2206.143311,297.957397 2195.388184,299.763672 2184.533691,295.152222 
	C2179.697998,293.097839 2175.523438,294.221832 2171.436768,297.169922 
	C2165.044189,301.781769 2157.911621,303.934082 2149.931885,304.048187 
	C2135.899414,304.248840 2122.774902,310.969025 2108.549316,310.150391 
	C2107.891357,310.112579 2107.180420,310.424652 2106.563477,310.274780 
	C2089.590332,306.152313 2077.488525,313.134033 2066.745605,325.935791 
	C2060.735352,333.098145 2051.072266,331.438202 2043.399902,328.418091 
	C2035.945312,325.483582 2029.564331,326.137970 2023.561157,330.217743 
	C2005.250366,342.661926 1983.453491,343.744354 1963.052856,349.350708 
	C1958.924072,350.485321 1955.270142,348.682251 1951.832642,346.346313 
	C1938.583862,337.342743 1929.379150,337.583893 1917.258179,347.472473 
	C1913.739868,350.342804 1910.614380,349.718933 1907.215332,347.674194 
	C1904.683716,346.151245 1901.967651,343.303986 1899.076172,344.969879 
	C1890.235840,350.062988 1879.673096,349.294464 1870.764648,353.750336 
	C1854.200317,362.035370 1837.696655,358.943207 1825.716064,345.040680 
	C1824.629517,343.779724 1823.569214,342.483063 1821.736816,342.312378 
	C1812.622314,358.078461 1808.927490,359.319366 1790.029053,354.810333 
	C1779.566772,352.314087 1768.935181,350.668396 1759.990234,359.477783 
	C1757.148560,362.276581 1753.436646,362.481110 1749.671143,361.480377 
	C1737.062378,358.129425 1725.307251,359.080292 1715.119507,368.221039 
	C1713.877075,369.335846 1712.289185,370.094391 1710.649414,370.365753 
	C1691.012695,373.615112 1671.688965,379.419159 1651.330444,376.219788 
	C1638.425781,374.191833 1625.432495,373.827728 1612.968506,379.162598 
	C1610.828247,380.078735 1608.233643,381.204712 1606.196411,380.546295 
	C1594.491943,376.763489 1585.025391,380.111176 1574.773438,386.575226 
	C1564.511597,393.045380 1552.150024,388.996399 1540.554443,387.967682 
	C1522.953247,386.406281 1505.650391,388.507477 1489.858154,396.959259 
	C1480.581421,401.924072 1471.329956,402.239777 1461.883545,399.098083 
	C1451.677612,395.703827 1441.853760,391.729614 1431.123779,397.577179 
	C1427.787476,399.395325 1424.012817,397.396515 1420.513672,396.188263 
	C1413.868164,393.893585 1406.687378,393.677460 1401.478271,398.305603 
	C1391.880981,406.832581 1381.270020,405.964691 1370.404785,403.343628 
	C1361.819214,401.272491 1354.248291,399.434814 1346.497192,406.666290 
	C1341.743652,411.101135 1334.427368,409.089935 1328.177612,407.671417 
	C1326.225586,407.228333 1323.885742,407.002777 1322.580444,405.674774 
	C1315.893921,398.871704 1305.619263,411.430176 1300.660156,402.348846 
	C1295.816528,393.478729 1287.396973,395.508270 1280.691528,393.811432 
	C1270.780029,391.303253 1260.401001,395.699005 1250.026733,394.769104 
	C1246.722290,394.472931 1244.985840,398.724762 1243.125854,401.452087 
	C1240.545898,405.234894 1237.279785,408.306885 1232.518677,407.825653 
	C1219.443726,406.504242 1206.742188,409.689758 1193.857300,410.562622 
	C1189.825073,410.835785 1185.899414,410.815948 1181.966431,409.801819 
	C1173.133545,407.524231 1164.840698,407.868073 1157.817383,414.748535 
	C1155.098755,417.411865 1151.293457,419.684784 1147.879517,418.429962 
	C1133.006836,412.963440 1118.642944,421.367462 1103.897461,419.458466 
	C1098.396729,418.746368 1092.679688,420.125275 1087.207520,417.775543 
	C1082.794678,415.880615 1078.762451,417.824860 1074.839111,419.841217 
	C1062.436890,426.215088 1049.763794,430.638763 1035.611816,425.766388 
	C1032.072876,424.548004 1028.442505,426.170166 1026.591675,428.739136 
	C1018.052185,440.591949 1006.987061,436.997345 997.113770,432.750946 
	C986.937439,428.374115 980.026367,429.043518 972.757568,437.998840 
	C966.396057,445.836365 952.133850,444.712402 946.111938,437.347290 
	C939.167358,428.853699 935.085938,427.637543 924.738464,430.955902 
	C920.911865,432.183105 917.457703,434.252289 913.762146,435.775330 
	C907.431641,438.384216 901.385864,438.522858 896.118103,433.454376 
	C891.464478,428.976929 886.327148,428.286102 880.516418,430.866760 
	C878.691040,431.677429 876.484253,432.436462 874.671692,431.754913 
	C857.991943,425.482941 841.798157,432.391052 825.398499,433.375824 
	C823.734253,433.475769 821.785400,433.395844 820.532654,434.315857 
	C803.771301,446.625763 785.348022,439.385895 767.550720,438.812988 
	C760.465027,438.584900 753.574951,438.868713 746.829285,441.062500 
	C741.009705,442.955109 735.130920,443.618073 728.362183,441.967529 
	C719.771362,437.318207 711.532715,435.578766 702.594604,437.714935 
	C698.521973,438.688232 694.538757,438.515137 691.894653,434.513672 
	C689.885498,431.473236 689.509644,428.215607 691.758301,425.181213 
	C694.495117,421.488190 698.717346,420.615295 702.562195,422.025757 
	C711.176941,425.186005 717.864502,423.700287 723.687683,416.351288 
	C726.257568,413.107971 730.814941,413.013580 734.859497,412.929626 
	C743.856323,412.742950 752.842773,412.617493 761.807556,413.860565 
	C766.442932,414.503357 771.087036,414.104919 776.367737,411.862518 
	C770.275269,406.907501 764.252197,404.598969 757.259888,407.828064 
	C750.174255,411.100250 743.222595,411.545135 735.981201,408.586731 
	C733.150146,407.430145 730.090759,406.852966 727.333862,408.738525 
	C720.048157,413.721405 712.511169,412.556580 704.328674,411.162079 
	C695.117065,409.592163 685.662109,410.681732 677.353638,415.975830 
	C670.814636,420.142517 665.058655,419.148529 657.758118,416.258881 
	C642.267395,410.127441 627.380005,401.308685 609.594910,402.403076 
	C603.322754,402.789032 596.693726,401.334320 591.450195,397.628632 
	C583.738770,392.178894 575.651855,391.480560 566.758118,393.093964 
	C552.013062,395.768921 537.687378,393.939728 523.494568,389.128601 
	C513.512207,385.744720 503.050446,386.796906 492.775787,388.178040 
	C485.798401,389.115967 478.830353,389.669830 471.864044,388.603729 
	C467.441223,387.926788 463.051025,386.409943 462.616730,381.070923 
	C462.216064,376.145203 465.331207,372.080261 469.603455,371.242676 
	C478.121948,369.572632 477.578522,364.296021 478.326324,357.512848 
	C479.343048,348.290863 480.950348,339.133087 481.160645,329.797974 
	C481.296509,323.768524 485.531097,320.359802 490.448730,316.650269 
	C492.955505,313.456665 494.829437,310.725647 495.751221,306.700195 
	C496.292389,305.639313 496.563812,305.243744 497.420074,304.428986 
z"
        />
        <path
          fill="#99BAD6"
          opacity="1.000000"
          stroke="none"
          d="
M2.000000,1370.937256 
	C9.147470,1370.575073 16.407339,1368.644409 23.331047,1373.085449 
	C27.755142,1375.923218 31.638950,1374.081787 33.329494,1368.604736 
	C35.569363,1361.348145 40.218475,1359.780273 46.957588,1359.919189 
	C60.284096,1360.193726 73.624527,1359.790527 86.951134,1360.062866 
	C98.650360,1360.301880 110.063187,1358.546753 121.533089,1356.611572 
	C130.089508,1355.167847 139.002609,1354.961792 147.393127,1356.602295 
	C153.513779,1357.798950 158.281540,1357.011230 163.502808,1354.312012 
	C173.852097,1348.961792 178.890366,1349.880981 187.206894,1357.936890 
	C192.694412,1363.252441 202.014221,1363.720459 207.973572,1358.974976 
	C208.233231,1358.768066 208.423996,1358.458252 208.704605,1358.296509 
	C215.775497,1354.218018 220.101151,1346.730469 227.549805,1343.124634 
	C234.750031,1348.441162 243.182251,1344.584106 250.815933,1346.299072 
	C255.106476,1347.263062 259.643127,1347.661743 262.197327,1352.985718 
	C266.551270,1362.061401 275.674622,1362.347656 281.589050,1354.160522 
	C282.328888,1353.136353 282.430878,1351.657715 284.211517,1350.750610 
	C291.529907,1355.405029 298.135071,1355.122559 305.214264,1348.395996 
	C310.340759,1343.524658 317.910645,1345.215820 324.236389,1347.319580 
	C336.558594,1351.417480 348.873871,1354.748169 362.013153,1352.417114 
	C365.478973,1351.802246 367.429657,1354.610352 369.971344,1355.999634 
	C375.071014,1358.786865 380.037567,1361.315063 385.703003,1357.291382 
	C387.136047,1356.273804 388.862091,1356.635132 390.507751,1357.198608 
	C401.811310,1361.069214 413.533783,1359.899048 425.143341,1359.984009 
	C435.475220,1360.059692 445.808075,1360.001099 456.140503,1359.999268 
	C458.140320,1359.998901 460.367798,1360.567383 462.102661,1359.886230 
	C472.788757,1355.690552 482.017029,1360.182983 491.476135,1364.162476 
	C494.834778,1365.575562 496.750916,1367.966431 498.514832,1370.851318 
	C501.747925,1376.138794 506.323639,1378.428833 512.726990,1378.087891 
	C528.636597,1377.240356 544.476135,1375.213257 560.487854,1375.887085 
	C570.445374,1376.306030 580.849548,1376.541626 590.067322,1378.820923 
	C600.314758,1381.354736 610.264526,1381.129639 620.357666,1382.185913 
	C628.171509,1383.003418 635.556702,1386.293335 643.561584,1387.036133 
	C650.615417,1387.690796 658.301880,1387.469971 663.847290,1393.451538 
	C664.213806,1393.846802 665.133606,1393.938477 665.755127,1393.857422 
	C681.229797,1391.839722 695.958008,1397.957642 711.239197,1398.010986 
	C713.235046,1398.017944 715.529419,1397.545898 717.178589,1398.343628 
	C730.215332,1404.649414 744.066711,1401.196655 757.545349,1401.934204 
	C763.194458,1402.243408 768.511414,1402.910156 774.074768,1404.646362 
	C785.733582,1408.285034 797.697693,1411.303711 810.035522,1411.985840 
	C812.394531,1412.116211 814.933411,1412.019409 816.747070,1413.526123 
	C822.984070,1418.707886 830.326477,1418.152832 837.635071,1417.999878 
	C843.314819,1417.881104 848.925049,1417.809448 854.118225,1420.883789 
	C857.099182,1422.648682 860.632751,1422.058960 863.937561,1421.965210 
	C871.065857,1421.762817 877.832947,1423.292969 884.264221,1426.171143 
	C890.461853,1428.944580 896.795715,1430.287598 903.626709,1430.070435 
	C912.616821,1429.784302 921.622925,1430.006104 930.622131,1429.996582 
	C933.948608,1429.992920 937.243958,1430.038086 940.479492,1428.849854 
	C954.883545,1423.559448 968.481445,1427.934937 981.942505,1432.707886 
	C984.555786,1433.634521 987.064087,1433.980713 989.716064,1433.990112 
	C996.715393,1434.015137 1003.719299,1434.156860 1010.713013,1433.958008 
	C1018.451477,1433.738037 1026.010986,1433.853516 1032.835327,1438.329956 
	C1036.187866,1440.529419 1039.611328,1439.879395 1043.294678,1438.418579 
	C1054.016235,1434.166382 1055.260986,1434.523804 1062.248779,1443.478760 
	C1063.987061,1445.706299 1065.986816,1446.082642 1068.316406,1445.961548 
	C1082.567993,1445.220947 1096.787476,1446.667358 1111.144043,1444.591675 
	C1122.369873,1442.968750 1134.464966,1441.581909 1145.164429,1448.483276 
	C1148.110840,1450.383789 1151.580566,1449.906494 1154.904663,1450.014648 
	C1166.838379,1450.402710 1178.817871,1449.007812 1190.704468,1451.771851 
	C1194.783081,1452.720215 1199.298828,1452.381958 1203.577393,1451.171021 
	C1211.757080,1448.855713 1220.138306,1450.079712 1228.419800,1450.239868 
	C1232.517700,1450.319092 1235.938110,1452.520630 1238.213135,1456.274536 
	C1241.042725,1460.943481 1245.684570,1461.265747 1249.520630,1458.769043 
	C1256.735718,1454.073242 1264.499390,1453.928955 1272.545776,1453.967163 
	C1293.210083,1454.065430 1313.875366,1454.007202 1334.540161,1453.993652 
	C1338.507446,1453.991089 1342.044556,1453.427002 1345.890747,1451.413696 
	C1355.707764,1446.274414 1366.938354,1443.784302 1377.391357,1447.239014 
	C1389.350586,1451.191528 1401.033813,1450.092163 1412.833374,1449.107910 
	C1416.718506,1448.783936 1420.016724,1448.675903 1423.227539,1450.636597 
	C1427.980591,1453.539062 1433.107666,1454.124512 1438.562500,1454.058472 
	C1452.892456,1453.884888 1467.225952,1453.985474 1481.557983,1454.004883 
	C1491.194702,1454.017944 1500.594727,1450.779053 1510.323975,1451.974854 
	C1512.956299,1452.298340 1515.874634,1451.671143 1518.237915,1452.581055 
	C1532.810547,1458.192139 1547.559937,1456.429443 1562.418823,1454.404541 
	C1567.008057,1453.779053 1571.766113,1453.677612 1576.380127,1454.087158 
	C1588.907593,1455.199463 1601.395142,1456.753662 1613.908203,1458.039551 
	C1616.869385,1458.343994 1618.823120,1459.797729 1620.171143,1462.328369 
	C1622.705200,1467.085693 1626.877075,1468.422119 1631.946655,1468.024536 
	C1633.934326,1467.868774 1635.950562,1468.103394 1637.943237,1467.984253 
	C1662.590454,1466.509521 1687.076538,1470.320190 1711.703857,1470.266357 
	C1720.535767,1470.247070 1729.354858,1473.350464 1738.327393,1470.750610 
	C1739.140381,1470.515137 1740.310303,1471.062378 1741.172974,1471.515747 
	C1753.992798,1478.251099 1767.863037,1475.896240 1781.362671,1475.834595 
	C1788.906372,1475.800049 1794.378174,1477.023071 1796.249146,1485.401489 
	C1797.266968,1489.959961 1801.542847,1491.744507 1805.498901,1491.702026 
	C1813.962036,1491.611328 1822.749878,1494.201050 1830.802368,1489.011353 
	C1833.750977,1487.111206 1837.378784,1487.594482 1840.388306,1489.323853 
	C1845.294556,1492.143555 1850.559570,1492.046387 1855.909912,1492.020020 
	C1868.575073,1491.957520 1881.261841,1492.467407 1893.902100,1491.901367 
	C1919.226929,1490.767212 1944.378052,1494.230957 1969.665039,1494.263794 
	C1977.847046,1494.274414 1986.077759,1496.476685 1994.429199,1496.039062 
	C1998.114136,1495.845947 2000.877686,1497.129883 2002.023560,1501.033081 
	C2003.746704,1506.902710 2008.194214,1508.360229 2013.720093,1508.024048 
	C2015.374146,1507.923340 2017.088257,1507.899292 2018.701782,1508.214355 
	C2023.352905,1509.122314 2024.960083,1512.090698 2022.499023,1516.125000 
	C2016.977417,1525.176025 2010.739014,1533.396484 1998.249512,1532.018555 
	C1990.713501,1531.187134 1984.956177,1535.571899 1978.424561,1539.457031 
	C1972.623047,1544.094604 1968.960327,1541.193237 1965.222046,1536.341309 
	C1963.164307,1533.550781 1964.354004,1529.065674 1960.122437,1529.180298 
	C1957.407471,1529.253784 1956.780518,1531.936401 1956.097900,1534.944946 
	C1951.406250,1549.711060 1951.393677,1549.776855 1936.364990,1546.900879 
	C1928.849243,1545.462769 1925.049805,1550.209351 1922.424316,1555.714600 
	C1916.838135,1567.427246 1907.423584,1571.291016 1895.078857,1572.299805 
	C1888.168457,1572.864380 1884.000610,1565.870117 1878.828491,1567.280884 
	C1873.929810,1568.617310 1870.687134,1574.775635 1864.348389,1574.956055 
	C1850.707886,1575.344116 1837.033203,1574.892944 1823.440796,1576.701538 
	C1820.486694,1577.094727 1817.231079,1576.612305 1814.646118,1575.206299 
	C1805.088013,1570.008301 1795.584351,1565.923950 1784.720703,1571.649536 
	C1783.341309,1572.376465 1781.166504,1572.018799 1779.904907,1570.997437 
	C1767.367310,1560.848389 1751.025146,1568.334595 1737.626465,1561.841431 
	C1735.143555,1560.638306 1731.915161,1563.081055 1729.069824,1563.996582 
	C1723.176392,1565.892456 1717.700195,1566.153198 1711.815674,1562.838013 
	C1703.525635,1558.167969 1693.698730,1560.363770 1687.210938,1567.375122 
	C1685.058838,1569.700928 1682.419067,1571.259033 1679.505493,1571.713623 
	C1666.580933,1573.730225 1654.094482,1579.506348 1640.536255,1576.369263 
	C1638.577271,1575.916016 1636.527466,1576.123291 1634.631958,1578.578979 
	C1637.928467,1582.167725 1643.274414,1582.980347 1646.539062,1586.597778 
	C1650.910400,1591.441772 1650.260742,1595.972534 1644.919434,1597.287598 
	C1627.623291,1601.546021 1609.939575,1603.336182 1592.322144,1605.546387 
	C1589.679321,1605.877930 1586.792847,1606.791382 1584.389404,1605.791748 
	C1564.635010,1597.574341 1545.177734,1603.694336 1525.569702,1606.455078 
	C1514.672607,1607.989502 1503.445801,1608.520264 1492.724609,1606.067383 
	C1482.295044,1603.681396 1472.496460,1604.793213 1462.154541,1606.253418 
	C1444.690063,1608.719360 1427.009521,1609.597900 1409.899536,1614.416626 
	C1406.679565,1615.323364 1403.299683,1614.919189 1399.968994,1614.403809 
	C1375.215332,1610.574951 1350.432129,1614.881348 1325.654907,1613.670044 
	C1333.218750,1615.110840 1340.911987,1614.641846 1348.368164,1616.609863 
	C1354.272827,1618.168457 1360.302734,1619.544189 1361.749268,1626.780151 
	C1362.961792,1632.845703 1357.912109,1645.688354 1352.407715,1647.582031 
	C1344.816406,1650.193237 1341.466919,1654.902832 1339.703247,1662.422485 
	C1338.386841,1668.034302 1332.043335,1670.522583 1328.169189,1675.614014 
	C1327.119385,1676.313599 1326.666992,1676.531250 1325.475586,1676.811279 
	C1321.423218,1675.951172 1319.510376,1672.858276 1316.451538,1671.534058 
	C1314.023560,1670.482788 1310.967651,1668.228516 1309.006592,1669.549194 
	C1298.491577,1676.629883 1282.980347,1670.969849 1274.622803,1683.196899 
	C1274.483398,1683.400879 1274.002808,1683.371704 1272.952148,1683.498413 
	C1270.727905,1683.471558 1269.319458,1682.922729 1267.088623,1682.640869 
	C1263.076782,1681.807373 1259.795166,1681.164917 1256.446777,1681.245239 
	C1238.142944,1681.683838 1220.819702,1677.324951 1204.152344,1670.210449 
	C1198.534546,1667.812500 1193.344238,1667.258667 1187.916626,1670.250122 
	C1186.462280,1671.051880 1184.872803,1671.869507 1183.249146,1671.971436 
	C1170.300171,1672.785034 1157.736328,1675.312256 1145.184692,1678.686523 
	C1138.135254,1680.581665 1130.568115,1679.381348 1124.838135,1673.350830 
	C1122.143799,1670.514893 1118.408569,1668.480469 1115.113892,1671.508057 
	C1107.886108,1678.149414 1100.265137,1676.942017 1093.068726,1672.737305 
	C1074.487671,1661.880981 1053.804565,1664.393433 1033.929443,1663.636475 
	C1018.864502,1663.062744 1004.462952,1658.876587 989.426941,1658.400391 
	C980.039612,1658.103271 974.263977,1649.270630 968.097046,1642.995117 
	C963.294006,1638.107178 958.422241,1635.340698 951.647278,1635.121216 
	C939.456360,1634.726074 927.403198,1632.093872 915.150024,1636.593262 
	C909.337585,1638.727417 906.182983,1632.932739 905.228027,1627.842529 
	C903.456848,1618.401367 898.156250,1610.690430 894.115723,1602.328003 
	C892.369446,1598.713989 890.685669,1597.514038 886.532043,1598.339722 
	C878.177979,1600.000610 871.181152,1598.100342 868.635925,1587.445312 
	C865.189697,1594.445557 859.715149,1594.810181 853.937805,1595.061768 
	C849.136475,1595.270874 843.935364,1593.131836 839.213989,1597.632324 
	C838.157104,1599.716797 837.234985,1600.968994 835.703979,1602.758057 
	C833.520691,1604.696899 831.947937,1606.145752 829.425659,1607.681396 
	C815.327209,1609.225952 802.483398,1612.157715 790.475586,1618.475342 
	C785.585205,1621.048462 781.047485,1619.927368 779.336731,1614.181885 
	C777.475464,1607.931519 772.816528,1606.761719 767.657898,1607.441406 
	C751.775940,1609.534058 735.149841,1608.276733 720.958252,1618.247681 
	C715.361633,1622.179932 708.658386,1621.717407 702.788635,1618.652832 
	C693.460510,1613.782471 684.008972,1613.697998 673.743896,1614.620361 
	C660.924683,1615.772217 647.977966,1613.431152 634.966187,1614.597900 
	C627.562439,1615.261963 621.036316,1610.424561 614.756042,1606.713135 
	C608.693115,1603.130005 602.928589,1601.962891 595.821655,1603.875488 
	C583.995789,1607.058228 571.521423,1607.226318 559.919800,1612.054810 
	C553.874512,1614.570801 548.397095,1611.405273 543.831726,1606.762451 
	C535.298706,1598.084473 528.219055,1596.528198 518.263611,1602.861328 
	C507.919128,1609.441895 497.084839,1612.421021 485.095367,1611.233765 
	C478.257538,1610.556641 473.245514,1614.778809 467.358398,1616.613159 
	C460.482697,1618.755493 458.318420,1608.381348 450.984009,1609.071411 
	C442.772217,1609.843872 434.820587,1609.796021 428.081573,1602.779907 
	C425.093994,1599.669556 419.797821,1601.994873 416.346832,1605.160400 
	C409.511536,1611.430664 401.151062,1613.205322 392.297974,1613.199951 
	C372.959381,1613.188232 353.915497,1615.226318 334.746552,1618.116089 
	C322.091064,1620.023804 308.465576,1618.703247 297.821350,1608.063965 
	C290.936218,1601.182251 283.218353,1598.212280 271.845337,1601.198364 
	C257.798096,1604.886475 242.687241,1600.595825 228.428024,1597.826294 
	C219.019714,1595.998779 209.585083,1593.066895 200.112900,1592.603027 
	C176.501801,1591.447021 153.651917,1584.467896 129.766281,1584.964844 
	C113.920906,1585.294434 98.232216,1580.776855 82.410889,1579.159302 
	C67.325844,1577.617065 52.007133,1574.367554 37.213753,1575.195435 
	C25.124170,1575.871826 14.392517,1573.990601 2.639393,1570.513184 
	C2.000000,1503.958252 2.000000,1437.916382 2.000000,1370.937256 
z"
        />
        <path
          fill="#5F98D9"
          opacity="1.000000"
          stroke="none"
          d="
M3025.367676,277.523132 
	C3026.000000,356.041779 3026.000000,434.083588 3026.000000,513.062683 
	C3024.178955,515.633423 3021.897217,515.438965 3020.560547,513.988525 
	C3013.298584,506.109131 3002.291748,504.560150 2994.218262,498.028137 
	C2987.877197,492.897827 2980.966064,492.162567 2973.996094,497.256866 
	C2970.977051,499.463440 2967.500244,500.577087 2963.818359,501.135529 
	C2953.946045,502.632904 2944.022949,503.729828 2936.557129,511.778137 
	C2933.968750,514.568787 2929.833984,514.854980 2926.174805,513.703552 
	C2915.729980,510.417084 2905.776367,512.335083 2895.935303,516.085449 
	C2892.712646,517.313660 2889.069336,518.447327 2886.456787,515.859619 
	C2880.212158,509.674957 2873.746338,511.931946 2866.247803,512.926270 
	C2856.160889,514.263794 2845.792725,511.428009 2835.780762,509.113922 
	C2822.325195,506.003906 2819.420166,507.031708 2812.631836,519.321655 
	C2808.245361,527.263184 2801.649414,528.425598 2796.212158,520.896118 
	C2789.994141,512.285278 2782.636963,510.008545 2772.828125,512.269775 
	C2766.810547,513.656982 2760.793945,512.369324 2755.160889,510.238525 
	C2748.995850,507.906525 2744.447510,508.189972 2741.212646,514.894775 
	C2739.147949,519.174133 2735.292725,520.831055 2731.739014,516.381470 
	C2724.413818,507.209198 2714.735596,508.388580 2705.161133,510.186493 
	C2700.230957,511.112335 2695.469238,512.235291 2690.392578,511.841583 
	C2685.491943,511.461517 2681.012695,512.996155 2677.930664,516.939392 
	C2674.005859,521.960205 2669.361572,522.374878 2663.691406,520.551392 
	C2652.014893,516.796265 2640.723145,517.396912 2630.159668,524.314575 
	C2627.577881,526.005493 2624.548828,526.656433 2621.525391,526.227234 
	C2615.797852,525.414124 2611.923096,527.681091 2609.230469,532.634521 
	C2608.120605,534.676758 2606.476318,537.393066 2604.126465,536.868286 
	C2596.483154,535.161194 2588.488037,538.748718 2581.531494,536.676575 
	C2565.008057,531.754822 2553.403320,540.076599 2541.521729,548.783386 
	C2540.466797,549.556641 2539.763184,550.784729 2538.784668,551.688538 
	C2532.093018,557.868774 2528.039062,557.673462 2522.921387,549.859009 
	C2519.790039,545.077759 2516.778564,544.821167 2513.586914,548.780273 
	C2505.997070,558.194580 2493.273926,556.505981 2484.266357,562.786743 
	C2482.630859,563.927185 2480.058838,563.510803 2478.564697,562.183533 
	C2472.487305,556.785706 2466.257324,559.170288 2459.816162,561.189636 
	C2452.511719,563.479736 2445.302246,566.039368 2437.896484,568.071594 
	C2428.931152,570.531799 2420.899902,569.705017 2411.705811,567.518921 
	C2399.977295,564.730103 2387.024414,566.434937 2377.855713,576.421875 
	C2372.908447,581.810669 2367.691162,582.489929 2360.629150,583.423218 
	C2343.537842,585.681641 2327.144287,578.374939 2310.111816,581.827148 
	C2303.475342,583.172180 2296.034424,581.747742 2289.715088,578.328430 
	C2284.954346,575.752502 2281.228516,576.855774 2276.710449,579.221802 
	C2263.428467,586.177124 2250.079346,593.555359 2234.075684,588.149658 
	C2231.553467,587.297607 2228.759033,588.039001 2226.237549,589.017517 
	C2219.145508,591.769409 2212.698242,590.346191 2206.140625,586.847473 
	C2196.031494,581.453369 2186.295898,581.500549 2178.058594,590.711304 
	C2174.272949,594.943970 2169.638916,594.142517 2165.088379,592.902161 
	C2157.967773,590.961365 2152.043945,592.507690 2145.173340,595.665527 
	C2131.110596,602.128784 2115.608398,600.558105 2101.097900,597.223206 
	C2089.343506,594.521729 2079.358643,595.947449 2069.813232,602.439880 
	C2064.174561,606.275330 2057.520020,607.491577 2051.556641,610.429565 
	C2048.942871,611.717285 2046.421509,611.043579 2044.051636,609.368103 
	C2034.978394,602.953186 2025.587158,602.746155 2015.927490,608.038452 
	C2009.910034,611.335388 2004.259399,609.898804 1999.016968,606.275208 
	C1989.446167,599.659729 1980.046631,596.341309 1970.282715,606.042847 
	C1968.138062,608.173828 1964.709473,609.321472 1961.879028,608.519653 
	C1946.659546,604.208618 1932.643799,609.432495 1918.437134,613.413940 
	C1917.795044,613.593933 1916.946533,613.990723 1916.499268,613.716858 
	C1904.756958,606.526855 1893.754272,615.837646 1882.268311,615.235474 
	C1870.556641,614.621399 1859.220459,618.489441 1847.580688,619.127319 
	C1843.077271,619.374084 1839.048950,620.623840 1835.729492,623.708130 
	C1829.840698,629.179626 1823.336060,631.277771 1815.593750,627.879150 
	C1813.784790,627.085022 1811.515991,626.048279 1809.801270,627.022278 
	C1801.081665,631.974976 1790.530640,629.837097 1782.404541,632.759460 
	C1772.185913,636.434265 1766.044922,632.317322 1757.826782,628.763611 
	C1756.435791,626.719910 1756.286865,625.087219 1756.209229,623.404846 
	C1755.999268,618.856506 1758.855957,611.316101 1755.310303,610.413269 
	C1749.515869,608.937622 1742.244385,608.845276 1736.254272,612.325745 
	C1733.065552,614.178467 1730.006104,616.561646 1726.266968,616.581482 
	C1715.691284,616.637390 1706.029541,618.299561 1697.843750,626.140320 
	C1693.816162,629.997986 1688.177490,628.623169 1684.105347,625.002930 
	C1678.474487,619.997070 1672.494385,621.021118 1665.586914,621.264099 
	C1648.487793,621.865662 1631.235840,626.168823 1614.149048,620.330627 
	C1604.607422,617.070557 1595.483887,620.779785 1587.090210,624.576477 
	C1571.378296,631.683289 1555.794922,629.315979 1540.135132,625.564026 
	C1526.713623,622.348328 1512.094482,623.415039 1500.767822,612.436279 
	C1497.188721,608.966919 1489.765747,610.904907 1484.279785,612.517151 
	C1475.609375,615.065369 1466.535156,615.318359 1457.819702,617.648254 
	C1452.558960,619.054688 1446.682495,618.550354 1442.187378,615.954407 
	C1430.477173,609.191956 1420.524658,614.375000 1410.601685,619.539795 
	C1406.174561,621.844116 1402.056641,623.012756 1397.285645,621.570129 
	C1378.704346,615.951965 1359.872437,617.790710 1341.165405,620.305603 
	C1335.471191,621.071106 1330.313354,621.098572 1324.586304,620.308838 
	C1314.178589,618.873718 1303.452026,618.600464 1293.035645,621.087769 
	C1287.853882,622.325134 1282.713745,623.702026 1277.456421,624.611877 
	C1267.996582,626.249084 1260.440308,623.336243 1254.757446,615.504089 
	C1252.417725,612.279419 1250.445435,608.466431 1246.965088,606.486816 
	C1239.362427,602.162476 1234.409912,597.237427 1237.008179,587.316895 
	C1238.285156,582.440918 1233.492554,580.718567 1229.459839,579.810913 
	C1224.879761,578.780151 1220.165039,579.308960 1215.513794,579.063110 
	C1209.960449,578.769470 1204.803467,577.472412 1201.761719,572.359863 
	C1197.896118,565.862671 1191.791626,564.860718 1185.285400,565.627686 
	C1178.235596,566.458740 1171.338501,565.821472 1164.385986,565.016968 
	C1159.849976,564.492004 1155.615967,563.001160 1153.560791,558.827759 
	C1150.146973,551.894653 1144.354492,551.188477 1137.681274,551.138245 
	C1130.108765,551.081299 1123.357910,553.838135 1116.418823,556.147400 
	C1110.304199,558.182373 1104.188354,560.181152 1099.159790,553.713074 
	C1096.872070,550.770447 1093.649658,552.178528 1090.774414,552.586365 
	C1083.215210,553.658508 1076.075562,553.151123 1070.357300,547.304504 
	C1066.723022,543.588562 1062.577148,544.358459 1058.279297,545.584595 
	C1053.811401,546.859192 1048.863159,549.369446 1044.723633,546.743347 
	C1037.595459,542.221191 1033.233521,546.266724 1027.041260,549.212158 
	C1011.379700,556.661987 994.713074,560.113708 976.928711,557.214783 
	C956.671631,553.912720 936.186523,550.485229 916.194397,559.117065 
	C913.736450,560.178223 911.006836,561.208801 908.437256,560.713440 
	C899.379456,558.967407 891.590881,557.700134 884.292664,566.892273 
	C880.831665,571.251404 871.689880,569.051147 865.154907,567.162964 
	C862.626343,566.432434 860.076782,564.811768 857.477051,566.340149 
	C844.764648,573.814148 830.898926,570.676697 817.396973,570.854370 
	C806.840271,570.993347 796.008667,569.175903 786.350586,575.429504 
	C785.801270,575.785217 785.057983,576.105896 784.454895,575.987244 
	C766.785461,572.510376 747.653748,576.242615 731.501465,565.277527 
	C729.287964,563.774841 726.916565,562.405945 727.281128,558.455444 
	C731.293030,554.333679 735.653564,555.701172 739.923706,556.044312 
	C761.281860,557.760742 782.546143,558.619507 802.749023,549.330811 
	C807.209839,547.279846 811.852539,547.203735 816.270081,549.537842 
	C824.601257,553.939941 833.023682,553.411621 841.361389,549.901062 
	C848.874634,546.737610 856.402222,544.971802 864.681763,546.102905 
	C870.340210,546.876038 875.918091,545.027283 881.360718,543.432861 
	C885.554199,542.204224 889.771729,541.030029 894.136841,541.890625 
	C901.672424,543.376221 907.847656,541.684875 912.772400,535.427307 
	C915.404846,532.082458 919.639832,530.387024 923.568970,531.553650 
	C934.536377,534.809937 946.115417,532.221130 957.078491,535.874268 
	C963.980347,538.174072 971.392029,538.988892 978.800171,538.697510 
	C982.883972,538.536926 987.069580,538.087402 989.929626,535.029785 
	C996.893738,527.584595 1006.325317,526.720886 1015.397705,524.767944 
	C1018.790588,524.037598 1022.181274,524.206726 1025.030151,526.201416 
	C1031.044067,530.412170 1037.563354,530.234741 1044.217896,528.775635 
	C1054.300781,526.564819 1064.319702,524.169861 1074.778564,524.494507 
	C1079.361694,524.636841 1082.986206,522.006226 1086.304810,519.120544 
	C1092.660645,513.593689 1099.681641,512.190979 1107.687012,515.092712 
	C1113.764160,517.295532 1120.184570,516.698975 1126.509155,516.785583 
	C1131.474487,516.853455 1135.687744,515.983521 1137.820923,510.629913 
	C1139.246704,507.051727 1142.592529,505.267090 1145.981689,506.920898 
	C1158.376709,512.969421 1171.529663,510.126556 1184.372681,510.777191 
	C1189.404541,511.032135 1194.470459,511.089752 1198.837524,513.912476 
	C1203.876221,517.169189 1208.237183,516.549744 1212.185425,512.239014 
	C1217.826416,506.079987 1224.394165,504.978333 1232.156372,507.818176 
	C1239.574951,510.532288 1246.904907,508.538605 1254.566162,507.725769 
	C1273.326416,505.735352 1291.592896,500.239502 1310.536011,500.093597 
	C1315.943237,500.051971 1321.109131,498.933289 1326.356567,498.058807 
	C1333.936157,496.795654 1341.516846,495.808655 1349.541382,498.574829 
	C1347.195923,492.883972 1350.113159,490.052826 1355.211426,489.285797 
	C1368.679321,487.259521 1382.099487,484.199585 1395.868042,486.047150 
	C1403.560791,487.079407 1411.222778,487.245148 1418.621948,484.720825 
	C1428.141968,481.473022 1437.760986,485.431549 1447.418213,483.715668 
	C1458.827026,481.688721 1470.308716,479.359924 1482.083252,480.011627 
	C1485.416260,480.196106 1488.707031,480.006195 1491.892212,481.289459 
	C1499.623657,484.404419 1505.834106,484.570770 1511.787354,476.500305 
	C1516.376953,470.278656 1524.565430,472.049500 1529.781128,476.635376 
	C1536.859009,482.858551 1543.047607,481.736267 1550.749878,478.368103 
	C1561.521362,473.657715 1573.585938,475.173615 1584.970215,472.977997 
	C1594.108765,471.215424 1603.489502,470.687164 1612.283203,467.289764 
	C1617.118164,465.421783 1622.025513,465.311066 1627.033447,466.285370 
	C1642.552124,469.304535 1658.120239,469.800781 1673.754272,467.223663 
	C1675.396973,466.952911 1677.119995,466.687042 1678.733765,466.977966 
	C1689.119995,468.850311 1697.761963,467.153473 1705.640259,458.806549 
	C1710.907959,453.225220 1719.616455,452.827118 1727.352417,452.930573 
	C1737.090210,453.060791 1745.408691,450.860565 1752.791260,443.748199 
	C1759.427002,437.355164 1764.705444,438.520325 1768.271484,446.425323 
	C1770.162109,450.616425 1772.390869,450.813873 1776.030273,449.970123 
	C1782.001709,448.585632 1787.526733,446.719116 1791.082642,441.140228 
	C1795.957153,433.492523 1802.857178,432.050232 1811.116455,434.950409 
	C1816.151978,436.718567 1821.457275,437.547455 1826.564087,439.087128 
	C1836.792114,442.170776 1845.729858,444.470245 1855.926514,435.729614 
	C1864.346558,428.511719 1877.020264,428.764801 1888.587646,429.754730 
	C1904.581299,431.123383 1920.295288,429.205811 1935.491455,424.003296 
	C1939.796387,422.529541 1943.829224,422.041229 1948.150879,423.420624 
	C1952.598267,424.840149 1957.189575,425.653595 1961.759399,426.605011 
	C1975.642456,429.495422 1988.568970,427.186615 2002.554688,424.307434 
	C2031.954956,418.254944 2061.436035,409.473541 2092.187012,413.336182 
	C2096.774902,413.912445 2100.817627,412.921753 2104.133789,409.646912 
	C2108.345703,405.487732 2113.788818,404.352753 2119.163086,402.446167 
	C2130.880859,398.289185 2142.004883,396.892548 2153.921143,401.896210 
	C2159.535645,404.253784 2165.748291,401.735229 2171.465088,399.765594 
	C2187.926270,394.094055 2204.275146,389.633667 2222.185791,393.879944 
	C2228.563477,395.391998 2236.237305,394.246887 2242.877930,391.834595 
	C2260.610107,385.393250 2278.787842,384.970032 2297.101318,387.697632 
	C2304.708252,388.830597 2310.228027,387.020782 2313.852539,380.242950 
	C2319.488525,369.703003 2328.723145,366.978088 2339.325684,369.836334 
	C2351.620361,373.150757 2363.822754,370.975372 2376.072998,371.044800 
	C2380.057617,371.067383 2383.107178,369.183319 2385.829102,366.639374 
	C2392.009277,360.863525 2398.622314,358.341675 2406.590576,363.177643 
	C2408.617676,364.408051 2410.824463,364.855865 2413.206787,363.526642 
	C2425.499023,356.667633 2438.705078,357.323975 2451.990723,359.087250 
	C2454.698242,359.446564 2457.900635,360.137939 2459.764404,358.470001 
	C2468.271729,350.856781 2479.823242,349.192749 2488.931885,343.012695 
	C2493.308105,340.043762 2497.291748,339.190063 2501.941650,341.390076 
	C2505.564941,343.104279 2509.390137,344.277557 2513.087891,345.762512 
	C2522.814697,349.668610 2531.986084,349.166412 2540.629639,343.106689 
	C2549.212402,337.089874 2557.752197,336.810852 2566.729980,342.378387 
	C2574.021240,346.900208 2581.994385,347.099213 2589.842529,344.100433 
	C2602.245117,339.361298 2615.046387,338.170349 2628.157715,338.762848 
	C2634.195557,339.035706 2640.029541,337.725189 2645.939453,336.544800 
	C2657.898438,334.156189 2669.553711,329.438507 2682.273682,332.259430 
	C2687.197266,333.351471 2692.190430,331.189362 2696.693604,328.954468 
	C2715.145752,319.796906 2735.059082,318.325287 2755.098389,318.102325 
	C2761.632568,318.029602 2767.511963,315.887207 2773.674316,314.652161 
	C2779.205811,313.543610 2784.387939,311.929321 2790.221436,314.387482 
	C2795.865723,316.766022 2800.363525,312.051239 2804.803223,309.572083 
	C2810.375488,306.460510 2815.622314,305.228180 2821.928955,306.761719 
	C2831.633545,309.121521 2839.610596,303.978485 2846.554199,298.564392 
	C2852.973145,293.559540 2858.917480,292.951782 2866.016602,295.598511 
	C2880.685791,301.067505 2894.675781,300.825348 2907.417236,290.435333 
	C2911.653076,286.981110 2916.615234,285.161499 2922.022949,284.415375 
	C2924.991455,284.005798 2928.265137,283.884888 2930.737549,282.345154 
	C2951.511230,269.408081 2972.354492,276.721680 2993.245117,281.742004 
	C2999.869873,283.334137 3006.419434,283.404907 3012.643555,279.864258 
	C3016.353516,277.753723 3020.012939,274.996368 3025.367676,277.523132 
z"
        />
        <path
          fill="#6DA3DE"
          opacity="1.000000"
          stroke="none"
          d="
M1757.234619,627.916748 
	C1763.286133,627.484131 1767.622070,629.548462 1771.888794,632.506226 
	C1774.960449,634.635498 1777.924561,634.895142 1776.897217,629.418274 
	C1776.446411,627.014648 1777.866211,626.552124 1779.835693,626.992859 
	C1782.427612,627.572815 1785.052490,628.068237 1787.578125,628.868591 
	C1793.338257,630.694153 1798.459839,630.654480 1803.251465,626.032349 
	C1807.070312,622.348511 1811.295410,621.023499 1816.588379,624.851135 
	C1822.110962,628.844910 1828.095703,627.913513 1832.490234,622.399780 
	C1836.922607,616.838440 1842.543823,615.304871 1849.273315,615.969238 
	C1857.282959,616.760132 1864.428467,611.980713 1872.632446,612.474182 
	C1877.107910,612.743408 1881.894043,611.966309 1886.523560,612.073120 
	C1891.743408,612.193542 1896.427734,611.643494 1899.935181,607.144714 
	C1902.601685,603.724731 1905.179932,604.470764 1908.308105,607.174683 
	C1914.851685,612.830994 1915.037109,612.695923 1921.977539,608.744812 
	C1923.701172,607.763550 1925.267700,605.799072 1927.257935,606.257629 
	C1932.948364,607.569031 1938.042358,608.193237 1942.276123,602.648865 
	C1943.438354,601.126770 1946.711304,601.905701 1948.986084,602.654480 
	C1951.832275,603.591370 1954.615723,604.781616 1957.519409,605.467407 
	C1962.625488,606.673279 1967.084839,606.005676 1971.226074,601.948669 
	C1978.392700,594.927551 1983.822876,594.337708 1992.677856,598.629028 
	C1994.758667,599.637451 1996.831665,600.847290 1998.561768,602.360657 
	C2005.249634,608.211365 2011.749634,609.328125 2018.843262,602.830200 
	C2021.257080,600.619080 2024.582397,599.405518 2028.138916,601.021057 
	C2030.969849,602.307129 2034.025391,601.881836 2037.008057,602.026184 
	C2038.652344,602.105774 2040.892090,601.780273 2041.819336,602.695374 
	C2045.615234,606.441406 2047.955078,612.180115 2054.706787,605.329956 
	C2057.965820,602.023438 2064.158447,603.009155 2068.829102,599.359314 
	C2076.943115,593.018616 2087.208984,589.352234 2097.611572,593.208618 
	C2103.821777,595.510925 2109.810303,596.028992 2116.125977,596.004883 
	C2122.457275,595.980713 2128.789307,595.936401 2135.119629,596.016602 
	C2138.917725,596.064636 2142.933350,595.764038 2145.144287,592.598633 
	C2149.273926,586.686157 2154.835693,587.770752 2160.427246,588.527893 
	C2163.045898,588.882385 2165.664307,589.545776 2168.161621,590.417053 
	C2171.962402,591.743042 2174.348877,592.370483 2176.501465,587.205139 
	C2179.088623,580.997192 2193.602295,578.108887 2201.780273,581.026245 
	C2203.340576,581.582825 2204.922119,582.255981 2206.273193,583.193420 
	C2212.987549,587.851990 2219.611328,590.327576 2227.316406,584.832458 
	C2230.094482,582.851379 2233.722656,584.225830 2236.960693,585.313110 
	C2246.305420,588.450745 2254.909912,587.304382 2263.464355,581.945618 
	C2268.623535,578.713745 2275.270508,578.430237 2279.714111,573.065308 
	C2281.775879,570.575745 2286.055664,571.639404 2288.842529,574.021545 
	C2295.802734,579.971375 2304.268066,579.079468 2312.312012,578.743713 
	C2322.568604,578.315796 2332.770508,576.266663 2342.636475,581.105103 
	C2346.500488,582.999939 2350.477783,581.964722 2354.125977,579.930969 
	C2357.274170,578.176147 2360.217773,577.628235 2363.342041,580.190552 
	C2367.182617,583.340637 2370.139648,581.210754 2372.642090,578.073059 
	C2374.088379,576.259644 2375.149414,574.116516 2376.708984,572.421265 
	C2378.939941,569.996826 2381.242920,565.982178 2384.372803,566.510315 
	C2393.962158,568.128113 2402.042236,559.948853 2411.554688,562.947449 
	C2413.416260,563.534180 2415.197021,564.651001 2416.760742,565.853882 
	C2420.319580,568.591431 2424.385986,568.595215 2428.217041,567.507812 
	C2440.638672,563.982056 2453.084717,560.449524 2465.245605,556.141418 
	C2470.551270,554.261902 2474.462646,553.099304 2478.010742,558.738647 
	C2480.333496,562.430420 2483.351562,561.748840 2486.274414,558.809082 
	C2489.719971,555.343445 2494.009033,553.938293 2498.950439,553.911987 
	C2503.896973,553.885620 2507.871826,551.814514 2510.479004,547.293091 
	C2511.444092,545.619995 2512.955566,544.124146 2514.525391,542.963013 
	C2519.481689,539.297302 2522.027832,539.974915 2524.622314,545.400696 
	C2525.051025,546.297363 2525.365723,547.249695 2525.715820,548.182739 
	C2528.312256,555.098083 2532.352539,556.053162 2536.601807,549.945679 
	C2545.182861,537.611633 2559.054932,535.400330 2571.575684,530.507874 
	C2572.162354,530.278625 2573.108154,530.102234 2573.513428,530.403320 
	C2580.183350,535.358948 2587.193359,534.448486 2594.504883,532.098206 
	C2597.987793,530.978516 2600.551025,534.324097 2603.576416,535.125732 
	C2606.639160,534.308899 2606.190186,531.385925 2607.239990,529.487549 
	C2611.086182,522.532043 2612.500244,521.669495 2620.704834,523.331726 
	C2623.853271,523.969543 2626.521240,523.963989 2629.107422,521.818176 
	C2640.025146,512.758057 2652.400879,513.476501 2664.666748,517.638550 
	C2669.863770,519.402039 2672.432129,520.273376 2675.474365,514.322449 
	C2678.420410,508.559326 2685.239502,507.419434 2691.342529,508.435822 
	C2695.432617,509.117004 2698.558838,508.875031 2702.604004,507.375549 
	C2710.094482,504.598846 2718.316895,505.097198 2726.112549,507.246582 
	C2730.888184,508.563293 2733.694824,512.336731 2735.108154,517.545715 
	C2739.259277,515.626831 2739.337158,512.043701 2740.604736,509.160980 
	C2742.890869,503.961548 2744.725830,502.990601 2749.932617,505.116119 
	C2759.014893,508.823578 2767.855957,511.167175 2777.929443,508.311676 
	C2786.593750,505.855591 2793.503662,509.795624 2797.664307,517.911804 
	C2798.417480,519.381165 2798.850342,520.917480 2800.314697,521.990601 
	C2804.336426,524.938232 2807.960449,523.985046 2809.752930,519.366394 
	C2815.905518,503.513184 2821.326416,501.027802 2837.728516,506.843506 
	C2840.664062,507.884430 2843.578369,507.905914 2846.565186,508.015778 
	C2849.217041,508.113312 2852.130859,507.698120 2854.462646,508.650757 
	C2861.038086,511.337158 2867.001221,511.675262 2873.281006,507.547546 
	C2878.154785,504.344055 2882.751709,507.054413 2885.268555,511.182983 
	C2888.560059,516.582886 2891.513428,514.826721 2895.961914,513.003601 
	C2905.385986,509.141052 2915.176758,505.880859 2925.401855,510.671082 
	C2929.072998,512.390808 2933.029053,512.836243 2935.389893,509.353699 
	C2942.129150,499.412842 2952.853760,499.652740 2962.776123,498.216797 
	C2966.936279,497.614685 2970.175537,496.404938 2973.501221,493.849762 
	C2980.608398,488.388733 2988.516113,488.512665 2995.359131,494.682922 
	C2999.990723,498.859100 3004.977295,501.758209 3010.841553,503.294586 
	C3014.929932,504.365784 3018.175049,506.302948 3020.120605,510.180756 
	C3021.050293,512.034241 3022.476318,513.390442 3025.304199,513.908569 
	C3026.000000,522.713806 3026.000000,531.427612 3026.000000,541.070679 
	C3020.600342,544.521179 3015.436279,545.635376 3009.063477,544.396179 
	C3002.409180,543.102295 2995.137207,544.581238 2988.244629,544.551575 
	C2979.451904,544.513794 2969.730957,547.040161 2961.906250,540.184082 
	C2960.318604,538.793152 2959.154541,539.187439 2957.739014,540.622742 
	C2949.710938,548.763794 2940.037842,546.992920 2930.778809,544.110474 
	C2926.191406,542.682434 2922.758789,541.648193 2919.216553,545.720764 
	C2913.686523,552.079163 2906.360596,551.211792 2899.865723,548.627625 
	C2889.392334,544.460327 2878.912354,545.673279 2868.437500,547.027588 
	C2862.750977,547.762756 2857.250488,548.637512 2851.710205,546.859863 
	C2846.798584,545.283875 2842.922363,546.305725 2838.960693,549.853882 
	C2831.943604,556.138794 2822.966309,555.704163 2815.784912,549.467712 
	C2810.683838,545.037842 2806.091309,544.710083 2800.645264,548.465088 
	C2799.280029,549.406494 2798.096680,550.612732 2796.727051,551.547363 
	C2792.660645,554.323059 2788.085693,554.877808 2785.106445,550.777771 
	C2781.495361,545.807983 2776.855469,545.914856 2771.818115,545.984863 
	C2766.819824,546.054321 2761.811035,546.189331 2756.823242,545.950745 
	C2750.948975,545.669800 2745.594238,546.192627 2741.568115,551.294373 
	C2739.087891,554.437134 2735.929932,554.074219 2732.589111,552.308777 
	C2729.860840,550.867126 2727.844238,548.692932 2725.645752,546.665833 
	C2718.518311,540.093872 2709.693848,540.001404 2702.624023,546.390747 
	C2701.883057,547.060547 2701.234131,547.833984 2700.480225,548.487732 
	C2694.640625,553.552979 2690.814697,553.264282 2686.738037,546.717651 
	C2685.026855,543.969421 2683.983643,540.866943 2680.244629,543.061707 
	C2673.740967,546.879272 2666.709229,546.012390 2659.777588,545.945190 
	C2654.629883,545.895325 2649.807129,546.096252 2646.272705,550.647339 
	C2643.750244,553.895142 2640.054199,554.285400 2636.287109,553.984009 
	C2622.099609,552.848816 2608.817627,556.654907 2595.553467,561.007568 
	C2589.773193,562.904541 2583.665283,562.976501 2578.070312,559.974670 
	C2573.837158,557.703430 2569.884766,557.729553 2565.749512,560.065857 
	C2564.325195,560.870667 2562.640137,561.761658 2561.101562,561.714905 
	C2556.412354,561.572388 2553.772217,563.830444 2551.942871,567.908264 
	C2549.975586,572.294189 2546.404053,574.492615 2541.500977,573.974731 
	C2536.748779,573.472717 2533.197998,575.377991 2530.100098,578.845154 
	C2525.080566,584.463257 2519.774170,587.655457 2511.803467,583.224548 
	C2506.979248,580.542847 2503.870117,583.708618 2504.312744,589.660095 
	C2504.857666,596.989929 2504.756348,596.558533 2497.544678,598.253357 
	C2492.028076,599.549866 2488.530029,600.684326 2487.184814,592.827148 
	C2486.369141,588.063477 2480.935547,590.057678 2477.159668,590.928528 
	C2476.650879,594.858948 2479.548828,596.726990 2480.846924,599.254944 
	C2482.191650,601.874451 2484.756836,603.928345 2481.823975,607.125793 
	C2478.547607,610.697449 2474.180420,611.941467 2470.317383,610.129517 
	C2467.513672,608.814392 2465.245605,606.742737 2463.492188,604.378357 
	C2458.163086,597.192505 2451.128418,595.262085 2442.513428,595.874329 
	C2434.222900,596.463623 2425.852295,595.821716 2417.528809,596.089539 
	C2411.498047,596.283630 2408.943115,598.775574 2408.076416,604.244202 
	C2407.442139,608.246155 2409.699463,614.679688 2403.994141,615.057251 
	C2398.547852,615.417664 2391.865723,615.579956 2387.491699,610.255554 
	C2386.647705,609.228149 2385.980713,608.024902 2385.020752,607.129272 
	C2383.019775,605.262451 2380.368652,603.386169 2377.869385,604.309998 
	C2370.118896,607.175293 2361.966309,605.055908 2354.154297,606.881470 
	C2345.639893,608.871155 2336.971924,608.576721 2328.403076,607.093201 
	C2316.476807,605.028320 2304.405518,605.291138 2292.595947,606.641479 
	C2285.290527,607.476746 2278.059814,607.721985 2270.778076,607.994934 
	C2267.481445,608.118530 2263.778320,607.293518 2261.291260,610.010437 
	C2257.383057,614.280212 2252.484131,614.043457 2247.450928,614.025635 
	C2240.481934,614.001038 2233.548828,613.744690 2226.639404,615.671814 
	C2223.237061,616.620789 2219.287598,616.334473 2215.706299,615.800354 
	C2189.513916,611.893860 2163.185059,614.333618 2136.941650,614.357056 
	C2125.824463,614.367004 2114.686035,617.399963 2103.437012,618.267639 
	C2098.847656,618.621582 2094.186523,620.201538 2090.443359,623.057434 
	C2086.514404,626.054688 2082.693604,626.840271 2078.500488,624.195374 
	C2074.638672,621.759521 2071.629639,622.699768 2068.959229,626.028381 
	C2066.178467,629.495117 2062.421143,630.292236 2058.209717,630.012573 
	C2047.621338,629.309204 2037.046387,630.367188 2026.354614,628.975586 
	C2020.377319,628.197571 2012.899414,630.631470 2007.723145,634.066956 
	C2000.837158,638.637085 1993.972046,640.330811 1986.166504,639.984253 
	C1980.274292,639.722595 1974.846191,639.665283 1972.945801,647.218750 
	C1972.213135,650.130859 1969.192749,652.012085 1965.884399,651.882263 
	C1960.155396,651.657471 1954.909912,654.613586 1949.124756,654.122437 
	C1947.234985,653.962036 1945.011963,654.437378 1943.355103,655.362732 
	C1932.593750,661.372803 1921.687744,658.510193 1910.577148,656.698181 
	C1902.437988,655.370667 1893.952271,654.423340 1886.204590,658.870972 
	C1882.758911,660.849121 1877.341553,658.261597 1875.381470,662.605164 
	C1873.282349,667.256897 1877.676025,671.232239 1878.591675,675.715942 
	C1879.961548,682.424744 1886.864502,683.949219 1892.146118,683.632141 
	C1903.342529,682.960083 1909.407837,688.460632 1913.909668,697.573547 
	C1916.674561,703.170654 1922.436646,703.315247 1927.671997,703.942566 
	C1930.679932,704.302979 1933.738281,704.121521 1935.311035,707.470947 
	C1938.028687,713.258545 1935.230713,717.886475 1928.578979,717.945679 
	C1912.582764,718.088135 1896.584351,717.951965 1880.587158,718.020752 
	C1877.512573,718.033997 1874.725830,716.915955 1871.796265,716.409302 
	C1865.489380,715.318726 1860.240234,716.275879 1856.482056,722.665649 
	C1852.012451,730.265015 1844.749268,731.589905 1836.852173,727.311829 
	C1829.308350,723.225098 1827.881348,723.499268 1821.879517,730.188416 
	C1817.186157,735.419312 1807.897583,738.000793 1801.929810,735.487549 
	C1797.726074,733.717041 1796.175659,729.803894 1796.191650,725.735474 
	C1796.210449,720.955750 1794.382690,716.628784 1793.806274,712.009155 
	C1793.175537,706.955078 1788.707886,703.444519 1783.904175,704.629822 
	C1777.919189,706.106689 1773.199829,707.166443 1770.066284,699.893188 
	C1768.817627,696.994873 1763.416626,697.867554 1763.025024,701.107727 
	C1761.953735,709.972168 1755.697876,712.176331 1748.759277,714.102417 
	C1745.192017,715.092712 1743.968384,717.258484 1745.291748,720.902222 
	C1745.965210,722.757080 1746.094727,724.803589 1746.547729,726.745483 
	C1748.500854,735.119995 1748.580566,735.277405 1740.938354,738.579041 
	C1737.016479,740.273438 1734.298462,742.769104 1732.616577,746.660461 
	C1730.617432,751.285767 1727.397217,753.773743 1721.930054,754.156128 
	C1714.079102,754.705200 1712.364746,758.654236 1716.266357,765.447083 
	C1716.748169,766.286072 1717.359375,767.098572 1718.068359,767.752136 
	C1726.860962,775.856323 1730.851807,774.750549 1733.896118,762.776123 
	C1734.872070,758.937012 1736.679932,757.783752 1740.092163,758.118408 
	C1743.470093,758.449646 1745.723633,759.709412 1745.084839,763.850830 
	C1744.291382,768.996033 1747.418579,774.236206 1744.612427,779.464294 
	C1743.612305,781.327332 1744.452026,783.700195 1746.360229,784.698181 
	C1751.132568,787.193787 1756.195679,788.912964 1761.703369,787.572449 
	C1764.761719,786.828125 1765.621948,784.416382 1765.962402,781.541992 
	C1766.486694,777.115662 1764.416748,772.079102 1769.112671,768.583740 
	C1772.839600,765.809570 1777.439819,766.531555 1781.477661,765.136353 
	C1784.395752,764.128052 1787.344482,764.165344 1790.334961,763.977356 
	C1794.759766,763.699341 1796.098877,766.589539 1796.891846,770.001099 
	C1797.751587,773.699219 1792.158936,785.574219 1788.708130,787.643982 
	C1787.567383,788.328247 1786.313599,788.826843 1785.186523,789.530579 
	C1784.088257,790.216370 1782.755371,790.851746 1783.004395,792.517334 
	C1783.291138,794.434692 1784.724365,795.394104 1786.390869,795.662964 
	C1789.656372,796.189941 1792.721191,796.715942 1795.828613,798.358398 
	C1801.901733,801.568420 1803.989502,800.193726 1805.990356,792.873352 
	C1806.412231,791.329224 1806.074341,789.246033 1808.320190,789.112244 
	C1810.243530,788.997742 1811.446289,790.556335 1811.590332,792.225098 
	C1812.114502,798.296326 1814.817261,800.654846 1820.873291,798.453430 
	C1821.438599,798.247864 1822.831543,799.151611 1823.214722,799.872681 
	C1823.865967,801.098145 1823.582764,802.643921 1822.323120,803.387512 
	C1820.636108,804.383301 1818.759399,805.657654 1816.935425,805.705383 
	C1812.099487,805.831787 1807.777832,806.593506 1804.279785,810.375244 
	C1802.635864,812.152588 1800.610840,811.273254 1798.921631,809.919128 
	C1795.302124,807.017578 1791.533081,806.123535 1786.865845,807.676086 
	C1784.534912,808.451355 1781.546509,807.885071 1779.020630,807.259094 
	C1776.522217,806.639832 1774.365723,806.494873 1772.622803,808.479980 
	C1769.338989,812.219971 1765.028076,812.061401 1760.650757,812.019226 
	C1753.319336,811.948547 1745.986450,812.021790 1738.654419,811.992859 
	C1732.991943,811.970520 1727.555054,812.147583 1722.103271,814.653870 
	C1715.909790,817.501038 1708.776001,816.880493 1702.416992,815.472900 
	C1687.028198,812.066223 1672.020508,814.835693 1656.915405,816.756592 
	C1652.988281,817.255981 1649.171997,818.572632 1645.258423,819.244751 
	C1640.880981,819.996277 1637.286743,821.703003 1633.932861,824.852112 
	C1627.886963,830.528992 1627.172607,830.329590 1620.583862,825.722839 
	C1611.764771,819.556641 1602.070923,821.907349 1592.587646,822.971558 
	C1589.777466,823.286865 1588.679321,825.996155 1587.461426,828.539612 
	C1583.816406,836.151733 1581.448242,837.151611 1573.334473,835.441650 
	C1571.061523,834.962585 1568.675659,834.652222 1566.594360,833.707642 
	C1562.186279,831.706848 1558.256958,832.413635 1554.293823,834.818726 
	C1550.170288,837.321106 1546.388062,838.649170 1541.477173,835.281616 
	C1537.222168,832.363831 1526.445312,835.928833 1523.372559,840.084473 
	C1521.755249,842.271729 1521.908813,844.542603 1523.713745,846.450806 
	C1526.931519,849.852112 1529.737427,853.478760 1532.078369,857.575378 
	C1533.917725,860.794678 1536.418335,860.171021 1538.218384,857.232605 
	C1539.431641,855.251831 1540.376099,853.101746 1541.378418,850.997681 
	C1542.200806,849.271606 1542.187988,846.768127 1544.708496,846.538330 
	C1547.701782,846.265503 1548.346680,848.988281 1549.440430,850.938477 
	C1550.579834,852.969788 1551.653198,855.058411 1552.526367,857.214783 
	C1555.079102,863.519531 1553.244507,867.318665 1546.789917,867.717590 
	C1540.303711,868.118347 1533.891724,864.739685 1527.201050,867.025146 
	C1526.449463,867.281799 1524.883911,865.853333 1524.006592,864.915710 
	C1521.172363,861.886047 1517.969238,860.361877 1513.669556,859.716919 
	C1506.695923,858.670837 1505.212036,855.192078 1508.482788,848.487793 
	C1510.347412,844.665466 1510.239624,841.374084 1507.780396,838.268494 
	C1504.944458,834.687256 1501.032471,835.537598 1497.785889,837.274292 
	C1484.288574,844.494263 1471.453613,838.743958 1458.485962,835.626770 
	C1451.167603,833.867493 1444.183716,830.507324 1436.225952,833.396301 
	C1433.285034,834.463928 1429.436768,834.386597 1426.387817,833.502197 
	C1421.119629,831.974182 1415.809692,832.552246 1410.573120,831.891968 
	C1400.935425,830.676758 1392.283203,834.209412 1383.329956,836.501587 
	C1374.861572,838.669556 1374.239014,838.594482 1369.668579,831.013672 
	C1367.455322,827.342834 1365.457031,825.345215 1360.995239,827.149719 
	C1358.630859,828.105896 1356.624756,826.009277 1354.449707,825.306702 
	C1347.760498,823.146301 1341.486450,822.726685 1336.186646,828.712830 
	C1332.406250,832.982910 1327.335449,834.081482 1321.704834,834.101562 
	C1316.325439,834.120667 1310.716309,832.934448 1303.317871,835.923706 
	C1310.156738,839.045532 1315.390747,837.818420 1320.355103,837.946045 
	C1330.719482,838.212463 1332.624268,838.916626 1335.592896,848.638489 
	C1337.036255,853.365173 1339.488037,854.053284 1343.496582,854.040161 
	C1349.122437,854.021729 1354.753662,853.545471 1360.272339,855.692688 
	C1363.805664,857.067322 1366.258789,858.783691 1365.764771,862.802979 
	C1365.312744,866.480591 1362.936646,868.844604 1359.406616,869.661926 
	C1356.473389,870.341064 1353.407715,870.269775 1350.537109,869.162170 
	C1347.655396,868.050293 1344.686035,867.950134 1341.681763,868.007996 
	C1337.322510,868.092102 1333.378784,867.526550 1329.311646,865.188232 
	C1325.227539,862.840271 1320.390869,860.407776 1314.862793,863.269714 
	C1314.953247,867.779785 1317.757812,871.685303 1317.154541,876.636169 
	C1316.713379,880.256470 1321.395874,882.144836 1325.429565,881.967163 
	C1330.030396,881.764526 1331.631226,883.749756 1332.153809,888.389099 
	C1333.185669,897.552246 1334.046631,897.191833 1343.647339,898.161499 
	C1357.223022,899.532593 1370.687378,897.714233 1384.197632,898.016724 
	C1392.882080,898.211121 1394.954834,900.213684 1393.825439,908.915222 
	C1393.106201,914.457947 1391.101807,919.842468 1389.561401,925.266052 
	C1388.877197,927.674255 1386.670410,928.267334 1384.758789,927.648865 
	C1379.902710,926.077698 1374.958130,926.034058 1369.966187,925.845825 
	C1368.417114,925.787415 1366.434692,925.185852 1365.448730,924.104858 
	C1356.475220,914.266235 1345.205688,918.417908 1334.571167,918.857971 
	C1332.277588,918.953003 1330.006958,919.598267 1326.872559,919.993774 
	C1316.100342,919.566406 1306.168823,920.798401 1296.255005,918.297058 
	C1289.688354,916.640259 1282.996704,918.492676 1276.942383,921.609863 
	C1271.562500,924.379944 1266.247803,924.726196 1260.607300,922.496338 
	C1255.944824,920.653076 1251.093506,919.500916 1245.995728,919.979309 
	C1245.666870,920.010132 1245.256958,920.089050 1245.008911,919.939636 
	C1233.631348,913.085815 1222.333984,917.498962 1210.922363,919.906311 
	C1207.794189,920.566223 1205.307007,921.632751 1203.155640,923.839417 
	C1201.044556,926.004944 1198.618286,928.827820 1195.550781,927.793091 
	C1186.283081,924.667053 1178.381836,930.306580 1169.788208,931.291138 
	C1165.261841,931.809692 1161.317383,931.789246 1157.311035,929.690735 
	C1149.623901,925.664246 1141.122192,923.968262 1132.973022,921.252380 
	C1128.791016,919.858582 1125.388794,919.980408 1121.831909,922.791565 
	C1117.992798,925.825745 1114.780640,926.445251 1111.277466,921.190430 
	C1106.747559,914.395325 1099.819336,913.917969 1094.803589,920.663757 
	C1085.171509,933.617859 1071.763306,931.551331 1058.850342,931.002563 
	C1054.773926,930.829346 1051.257080,930.693237 1047.374146,932.155762 
	C1040.367065,934.795044 1033.432983,935.815796 1026.841064,930.173462 
	C1022.097961,926.113708 1015.949280,927.784363 1010.475342,928.409058 
	C1001.366028,929.448608 1001.402649,929.769714 998.420410,921.257202 
	C995.343201,921.347778 994.686279,924.657837 992.362305,925.856934 
	C987.783325,928.219543 983.434814,929.191284 979.788208,924.682556 
	C975.987305,919.983154 971.814636,919.794128 966.549194,922.041687 
	C957.352478,925.967224 948.243896,922.638550 939.543762,920.129639 
	C928.763428,917.020813 918.353271,912.698486 906.788330,914.003784 
	C899.887695,914.782654 893.114929,916.522156 886.054199,916.152710 
	C881.559998,915.917603 879.953125,919.658203 878.618774,923.115540 
	C877.606873,925.737671 876.678955,928.155579 873.203308,927.793335 
	C870.141418,927.474243 867.798767,925.411438 867.645020,922.826843 
	C867.124084,914.071594 862.472046,911.731628 854.423279,912.270386 
	C849.323242,912.611694 845.182190,909.504578 841.068665,906.379578 
	C834.254333,901.202942 828.147034,903.902344 826.004150,913.218506 
	C811.415649,917.939575 796.706909,923.779297 782.976135,911.832214 
	C781.312805,910.385010 779.519775,909.735840 777.561096,909.742981 
	C763.598328,909.793823 749.722412,906.863770 735.706482,908.596619 
	C732.336548,909.013367 729.154236,909.550171 726.537415,911.976440 
	C719.950256,918.084106 713.557190,916.414001 708.136780,910.611572 
	C703.810120,905.979980 699.475769,903.997375 693.146240,905.932312 
	C688.260803,907.425720 682.937561,906.470581 678.373718,904.745239 
	C668.094482,900.859436 656.221375,899.333496 647.102478,901.648926 
	C636.429871,904.358948 626.183594,903.670593 615.944519,904.121277 
	C597.447754,904.935425 579.887939,912.137207 561.251953,911.072083 
	C557.903320,910.880737 554.484802,910.870422 551.491882,909.305542 
	C547.012451,906.963562 542.807068,906.728210 538.210510,909.115417 
	C534.164490,911.216736 529.728516,911.204590 525.692139,908.978943 
	C521.660828,906.756226 517.876892,905.666870 513.475952,908.110596 
	C510.409088,909.813599 508.029694,908.624512 505.862915,905.727234 
	C499.135376,896.731995 493.976349,894.610657 484.126953,898.942017 
	C476.320618,902.375000 469.722626,901.530701 463.594604,896.717834 
	C451.969208,887.587402 439.152252,885.428589 424.972717,888.327881 
	C417.655212,889.824158 411.000397,887.937561 405.771149,882.403687 
	C402.457733,878.897156 398.907715,876.280945 393.852783,875.931885 
	C391.014099,875.735779 388.817841,874.103210 388.404144,870.980713 
	C387.948608,867.542297 389.515167,865.194092 392.667572,863.912415 
	C397.130768,862.097900 403.141693,861.731445 401.993317,854.514282 
	C400.860901,847.397400 395.523285,844.684753 389.286804,843.677673 
	C377.022308,841.697449 366.762848,835.829102 357.456573,828.003418 
	C346.837677,819.073914 345.619110,818.984375 334.178497,826.544495 
	C328.981201,829.979004 323.274628,829.491943 317.809296,827.603455 
	C308.928131,824.534485 300.193024,824.544067 291.348022,827.504456 
	C284.885925,829.667297 278.146240,829.464661 271.518158,829.115540 
	C257.027618,828.352234 242.890121,830.003784 229.201645,834.727356 
	C221.386566,837.424133 215.408859,835.840454 211.437836,828.471130 
	C208.828003,823.627930 204.849976,823.213013 200.367447,824.748108 
	C188.666290,828.755127 177.563797,826.939392 166.268906,822.609253 
	C151.540085,816.962708 136.493744,813.349976 120.345589,816.753357 
	C112.432777,818.421021 104.367805,817.298218 97.823868,811.323669 
	C95.840538,809.512878 93.315224,808.229370 90.600098,808.169373 
	C68.834846,807.688538 48.905800,797.042847 27.092453,796.770325 
	C23.335659,796.723328 20.020458,794.949097 17.252613,791.140625 
	C15.403187,786.436218 16.647369,782.551331 17.611816,778.694885 
	C21.003515,765.133057 25.726404,763.339417 38.314045,769.706604 
	C46.823563,774.010925 55.813801,776.263672 64.773399,770.331848 
	C70.011360,766.863953 75.456909,766.873474 80.898331,769.664734 
	C85.959503,772.260925 90.903114,771.605103 96.371536,770.779480 
	C114.009155,768.116638 131.590424,763.195801 149.682144,768.319092 
	C156.478882,770.243835 162.984207,768.997681 170.042480,766.947266 
	C180.968079,763.773438 192.497620,764.600769 202.138535,772.343506 
	C213.030487,781.091003 213.752335,781.079041 221.627136,770.360046 
	C226.885300,763.202759 236.080658,761.763184 244.738586,767.908020 
	C249.084778,770.992676 251.210373,771.193848 254.023621,766.607361 
	C257.209778,761.412781 262.743439,760.737793 268.177216,760.954712 
	C284.467834,761.605347 300.735840,762.760803 317.006622,763.763306 
	C322.796082,764.120056 328.222809,764.236877 333.581543,761.782837 
	C341.477356,758.166809 349.961273,757.685852 358.075623,759.814453 
	C374.387085,764.093384 390.560791,762.550903 406.560364,759.332764 
	C420.502380,756.528442 434.323120,756.195374 448.280334,757.878296 
	C453.350037,758.489685 458.210999,758.191589 463.126221,756.996643 
	C470.247772,755.265076 477.371582,753.342102 484.726868,753.046814 
	C491.021820,752.794189 495.850281,752.625732 493.244751,743.576904 
	C491.670593,738.109863 499.509583,733.085693 508.020203,734.798340 
	C532.927429,739.810547 558.199280,733.929321 583.090149,737.923157 
	C586.606323,738.487305 589.428833,736.362793 592.410217,734.873352 
	C607.051819,727.558777 621.390503,722.167053 637.781677,731.681396 
	C645.450317,736.132751 655.240112,736.641113 663.955566,732.565491 
	C671.055664,729.245300 677.345703,729.600708 683.630737,734.218140 
	C688.329895,737.670471 692.810791,739.312622 697.313171,733.286804 
	C700.572205,728.924927 705.398010,729.178284 710.009644,731.476440 
	C715.674988,734.299683 721.433716,736.928406 727.083313,739.785339 
	C729.258118,740.885010 731.870422,741.464233 733.307617,738.914368 
	C735.346985,735.296021 731.658752,735.396423 729.856812,734.602051 
	C726.556335,733.147095 723.511597,731.400513 725.201843,727.244446 
	C726.790039,723.339172 730.454407,723.751160 733.810791,724.462219 
	C736.423462,725.015747 739.040710,725.889282 741.248535,727.328552 
	C751.280334,733.868347 761.471985,733.051392 771.698486,728.141724 
	C777.317444,725.444031 781.407593,725.936279 785.426575,731.395569 
	C790.478271,738.257507 794.606934,738.047424 799.571716,731.915283 
	C805.433777,724.674805 811.059448,723.390198 819.482971,728.383179 
	C822.877075,730.394897 826.262146,728.991577 828.716431,729.550598 
	C839.726135,732.058289 852.694580,728.398743 861.175354,732.492188 
	C875.892822,739.595947 887.159912,736.779297 899.623230,730.564575 
	C900.166321,727.922363 897.750366,727.101135 896.985718,725.387512 
	C895.526062,722.116455 895.135925,718.829163 897.730103,716.060730 
	C900.401489,713.209961 904.121765,713.252502 906.918213,715.223572 
	C913.224670,719.668518 920.213501,721.020081 927.633545,721.417542 
	C929.989258,721.543701 932.055969,722.725952 934.052307,724.006592 
	C952.409790,735.783569 967.245972,733.594116 981.100586,717.266968 
	C986.068909,711.411926 992.701050,708.345337 1000.209412,707.482544 
	C1005.649414,706.857483 1010.073425,708.723145 1012.134460,714.343384 
	C1013.686829,718.576416 1012.769226,725.032776 1018.065063,726.157898 
	C1024.056519,727.430786 1030.699707,726.774841 1036.604004,724.564941 
	C1040.907471,722.954285 1044.708374,720.009277 1048.818848,717.799561 
	C1051.171387,716.534790 1053.619141,714.725464 1052.783936,711.582642 
	C1051.991699,708.601746 1049.085815,707.901123 1046.422119,707.597900 
	C1038.122681,706.653137 1029.771973,707.446289 1021.453125,707.057007 
	C1015.490417,706.777954 1009.695984,706.049072 1009.835999,698.014709 
	C1009.942932,691.880432 1015.045105,687.117065 1022.162659,687.927856 
	C1030.944214,688.928223 1039.415894,686.768555 1048.064087,686.807068 
	C1056.133545,686.843018 1064.044189,687.006592 1071.229614,691.475037 
	C1073.281494,692.751099 1076.149658,695.124573 1077.762329,690.997192 
	C1081.546631,681.311279 1090.072998,682.178345 1097.933228,681.778687 
	C1101.229614,681.611023 1104.666748,682.091858 1107.837646,682.917847 
	C1119.684937,686.003784 1130.546997,684.600586 1141.200806,678.200195 
	C1148.152710,674.023743 1156.254150,677.653687 1163.821899,677.304077 
	C1171.414185,676.953369 1178.970825,677.672241 1186.580811,675.952637 
	C1196.729980,673.659241 1206.727661,680.608398 1215.717773,679.331360 
	C1225.544067,677.935669 1235.530518,674.405640 1244.857056,677.602051 
	C1255.246948,681.162964 1263.622925,678.741760 1273.340942,675.647949 
	C1291.322388,669.923401 1310.289673,670.222473 1328.391724,674.832214 
	C1340.124390,677.820068 1351.499146,677.816406 1362.972046,676.589905 
	C1378.325195,674.948669 1393.388672,673.885315 1408.478027,678.513550 
	C1420.865601,682.313171 1433.253052,677.178772 1445.754395,677.152771 
	C1460.988647,677.121155 1476.195190,676.980225 1491.463013,677.242615 
	C1504.137695,677.460510 1517.390381,679.400879 1529.713013,672.175964 
	C1537.353760,667.696289 1547.116333,668.569519 1555.828735,666.780212 
	C1574.899292,662.863586 1594.559448,659.869568 1614.097046,663.523743 
	C1630.008423,666.499817 1640.733276,658.354553 1650.338135,648.136780 
	C1653.858765,644.391418 1657.062866,643.324036 1661.749512,642.048340 
	C1674.011353,638.710510 1687.955200,643.999084 1698.942383,634.681763 
	C1699.906494,633.864136 1701.511963,633.351685 1702.727051,633.595154 
	C1717.100464,636.475098 1730.772583,628.771667 1745.048706,630.857605 
	C1749.374756,631.489746 1752.453247,628.011414 1757.234619,627.916748 
z"
        />
        <path
          fill="#5B92D2"
          opacity="1.000000"
          stroke="none"
          d="
M3026.000000,277.062683 
	C3022.504150,277.746521 3019.312012,278.827057 3016.641113,280.892914 
	C3006.765381,288.532135 2996.762939,288.533051 2986.550049,281.741852 
	C2985.114502,280.787079 2983.526123,279.733307 2981.953369,280.234131 
	C2976.952393,281.826599 2972.370850,281.964020 2967.922119,278.541321 
	C2966.631836,277.548523 2964.666016,278.616730 2963.222412,279.456177 
	C2958.690674,282.091522 2955.025635,282.594360 2951.173584,277.623535 
	C2948.532959,274.216095 2944.326416,277.013672 2942.432129,279.189941 
	C2936.330078,286.199463 2928.145264,287.160187 2919.932861,287.857117 
	C2915.899902,288.199341 2913.011963,289.828583 2910.357910,292.459961 
	C2899.800293,302.926239 2886.569824,302.752716 2873.113770,301.991089 
	C2868.580078,301.734497 2865.722412,298.039764 2861.286865,297.181824 
	C2856.198975,296.197723 2852.277344,295.454987 2849.434082,300.851227 
	C2847.821289,303.912598 2844.741455,304.864441 2841.524658,306.188843 
	C2832.657227,309.839508 2824.189941,313.763184 2814.661133,308.306091 
	C2813.873291,307.854980 2811.538818,308.519135 2811.086914,309.320984 
	C2807.773926,315.200165 2800.158203,313.581726 2796.026611,318.370697 
	C2793.606934,321.175262 2789.950684,320.499603 2787.509521,317.094513 
	C2785.979980,314.960571 2784.292236,314.805817 2781.534668,315.443298 
	C2771.514893,317.759491 2762.340088,322.705444 2750.987549,320.990631 
	C2744.836182,320.061462 2737.271240,321.943359 2730.379883,322.685272 
	C2717.684814,324.051971 2706.026123,328.066040 2694.584717,333.417603 
	C2688.459961,336.282349 2681.908447,337.061096 2675.143066,334.789978 
	C2670.966797,333.388000 2666.996338,334.557861 2662.656494,335.905396 
	C2653.586182,338.721741 2644.076172,341.536621 2634.708984,341.667816 
	C2618.320557,341.897308 2602.111816,342.044495 2586.696289,348.277710 
	C2578.647217,351.532410 2571.630859,349.006348 2564.867432,345.180084 
	C2561.951172,343.530304 2559.050049,342.071472 2555.916504,340.938019 
	C2553.511963,340.068115 2551.523438,340.364532 2549.331299,341.706726 
	C2545.364258,344.135498 2540.829834,345.774689 2537.220703,348.617432 
	C2532.510742,352.327423 2527.016357,353.255096 2522.148926,351.653290 
	C2515.062744,349.321167 2507.191162,349.332611 2500.881104,344.212891 
	C2496.078369,340.316162 2493.065918,341.201630 2490.109863,347.155396 
	C2488.062012,351.280243 2485.295410,352.925171 2481.414307,351.367004 
	C2476.333496,349.327057 2473.431396,351.229492 2470.411377,355.105316 
	C2464.315430,362.929413 2457.802734,364.696198 2448.838135,361.430634 
	C2446.939941,360.739197 2444.984131,359.601288 2443.082520,360.280060 
	C2436.605469,362.591797 2429.924316,361.959503 2423.299805,361.981140 
	C2419.468262,361.993652 2417.263184,363.916351 2415.322754,366.951904 
	C2413.043457,370.517090 2410.231445,370.182007 2406.859375,367.866241 
	C2396.541260,360.780212 2396.132812,360.629578 2387.844482,369.375366 
	C2383.523926,373.934418 2379.372559,376.165405 2373.136475,374.145721 
	C2371.031494,373.463989 2368.270996,373.434143 2366.217773,374.199646 
	C2357.157959,377.577332 2348.499023,374.834045 2339.827637,372.854004 
	C2336.170166,372.018860 2332.570801,371.995178 2328.924316,372.062286 
	C2324.643799,372.141022 2321.181885,373.767761 2319.131836,377.781250 
	C2318.375732,379.261475 2317.561035,380.720398 2316.660156,382.116302 
	C2309.675293,392.942291 2309.611328,392.212097 2296.349121,390.666107 
	C2279.941895,388.753510 2263.375977,387.869507 2247.296875,393.824677 
	C2242.151123,395.730682 2236.990234,398.746948 2231.010254,396.247955 
	C2229.518799,395.624786 2227.537354,396.036224 2226.278564,397.193878 
	C2222.626465,400.552094 2219.763916,399.127380 2216.902588,396.087708 
	C2215.466064,394.561554 2213.457275,393.722595 2211.438965,394.233582 
	C2199.928711,397.147644 2187.557129,395.717804 2176.566650,401.437103 
	C2173.071045,403.256165 2169.084229,404.279144 2165.210205,405.189148 
	C2158.937256,406.662598 2152.771973,406.588806 2146.763916,403.489716 
	C2140.310791,400.160950 2133.789795,398.455780 2127.390381,404.146210 
	C2125.094971,406.187408 2121.807373,406.127930 2118.770752,405.997925 
	C2113.753906,405.783112 2109.949707,407.536743 2106.804443,411.733521 
	C2103.179199,416.570160 2098.561035,418.444000 2091.938721,416.647797 
	C2086.639160,415.210449 2080.692139,415.887878 2075.047119,416.074158 
	C2069.472900,416.258057 2063.997803,416.806732 2058.330566,416.225800 
	C2051.322510,415.507355 2045.222412,420.318451 2038.197021,421.075134 
	C2031.025513,421.847565 2023.957764,423.765289 2016.910767,425.305237 
	C2011.203979,426.552307 2004.834961,425.917236 1998.966797,427.975220 
	C1997.733398,428.407776 1995.971802,428.057220 1995.146606,428.818634 
	C1987.471802,435.899017 1979.354980,432.536163 1970.808716,430.855682 
	C1961.749268,429.074280 1952.293701,429.348114 1943.748901,425.045288 
	C1941.294800,423.809509 1939.124146,425.273834 1937.230835,426.503815 
	C1930.892456,430.621338 1924.469238,433.698486 1916.686401,430.584351 
	C1914.718018,429.796753 1912.759277,430.642761 1910.943970,431.567047 
	C1903.683716,435.263916 1896.049683,434.521118 1888.500610,433.104614 
	C1877.252808,430.993988 1866.824097,433.800140 1856.552246,437.813507 
	C1853.677124,438.936859 1851.306885,440.969421 1850.331177,443.961365 
	C1848.894653,448.366425 1845.908081,448.587860 1842.458252,447.075348 
	C1830.857300,441.989227 1817.971069,441.451508 1806.268555,436.567566 
	C1800.978027,434.359558 1797.030029,436.272919 1794.086548,442.257477 
	C1790.936279,448.662231 1786.535522,451.937744 1779.392578,452.241699 
	C1776.880249,452.348602 1774.470581,454.118225 1771.949463,454.927307 
	C1766.638794,456.631714 1764.734253,455.104431 1765.330200,449.354156 
	C1765.572021,447.020752 1766.642700,444.485199 1764.321411,442.953766 
	C1761.398804,441.025513 1758.526367,442.578949 1755.998901,444.276459 
	C1754.937378,444.989471 1754.118042,446.141602 1753.359985,447.215088 
	C1748.682739,453.839630 1741.986328,456.012207 1734.269409,455.999207 
	C1731.603638,455.994720 1728.919800,455.800415 1726.275146,456.037476 
	C1716.293945,456.932281 1706.247559,457.516022 1700.568359,468.035065 
	C1698.412720,472.027740 1694.155884,472.808228 1690.007324,471.556732 
	C1682.415894,469.266663 1675.086182,470.511871 1667.404541,471.454102 
	C1653.200073,473.196442 1638.856934,472.365570 1624.804688,469.187103 
	C1621.816895,468.511322 1619.015625,467.625763 1616.085815,469.042542 
	C1605.069946,474.369598 1593.275024,475.829254 1581.238525,476.005646 
	C1578.467651,476.046234 1576.023926,476.980286 1573.631226,478.176575 
	C1571.200806,479.391724 1568.497437,480.766327 1566.002808,479.529358 
	C1559.510864,476.310425 1554.437988,479.824402 1549.105591,482.433472 
	C1538.279663,487.730469 1538.224487,487.801483 1529.461426,479.495728 
	C1525.335205,475.584869 1520.441040,475.675293 1515.422607,476.272858 
	C1512.662476,476.601562 1512.689697,478.618317 1513.279541,480.769562 
	C1513.738892,482.445129 1513.934692,484.471619 1512.096313,485.123932 
	C1507.408203,486.787476 1502.234497,485.741577 1497.459351,487.566193 
	C1495.520752,488.307007 1493.662842,486.651123 1492.251221,485.194672 
	C1489.663818,482.525116 1486.812744,481.517700 1483.260132,483.218201 
	C1482.384399,483.637329 1481.291748,483.981873 1480.365356,483.859161 
	C1467.328613,482.131470 1454.846191,487.936859 1442.273682,487.390594 
	C1430.240479,486.867828 1418.767456,488.374512 1407.066895,489.879547 
	C1402.186035,490.507355 1397.236328,489.569458 1392.256714,488.658478 
	C1383.382324,487.034882 1374.575195,490.418518 1365.620117,490.742188 
	C1361.799438,490.880280 1357.794556,491.855103 1353.826538,492.175018 
	C1352.070801,492.316559 1350.604858,493.093475 1350.656494,495.221558 
	C1350.696411,496.872406 1351.984985,497.341248 1353.259766,497.791901 
	C1355.375854,498.539948 1359.806396,497.175171 1358.531128,501.277771 
	C1357.360718,505.043274 1353.971924,503.703125 1351.050659,502.129303 
	C1343.843506,498.246399 1336.180908,499.914490 1328.649658,500.819824 
	C1320.887329,501.752899 1313.351318,506.022919 1305.282227,502.267365 
	C1304.729858,502.010284 1303.748413,501.913513 1303.337646,502.227570 
	C1296.973511,507.093414 1288.602661,503.587494 1281.860474,507.594086 
	C1277.937866,509.925140 1273.290161,510.635468 1268.429321,508.590637 
	C1263.460938,506.500488 1257.682617,508.366821 1254.031006,510.747009 
	C1245.208008,516.497925 1236.994385,513.097900 1229.321045,510.198303 
	C1223.040039,507.824799 1219.289185,508.829773 1215.500977,513.479248 
	C1214.872803,514.250244 1214.102051,514.905762 1213.390381,515.607788 
	C1206.176392,522.724243 1206.015625,522.934692 1197.953979,516.805420 
	C1194.694458,514.327026 1191.387939,513.976807 1187.693604,513.989380 
	C1178.030151,514.022156 1168.366333,513.957275 1158.703125,514.019592 
	C1154.648438,514.045715 1151.068970,513.593628 1147.562256,510.821747 
	C1142.476562,506.801849 1140.870972,507.432617 1140.162720,513.664429 
	C1139.632324,518.330688 1137.880615,520.302795 1133.327026,519.985413 
	C1123.163330,519.276917 1112.787476,521.852600 1102.899414,516.798340 
	C1096.837280,513.699707 1091.418457,517.758362 1087.903076,521.895203 
	C1083.377075,527.221375 1078.168579,528.782532 1071.980591,527.923096 
	C1060.379272,526.311890 1049.963013,531.890137 1038.795776,533.070557 
	C1033.286499,533.652771 1028.668579,533.517395 1025.070068,529.756287 
	C1021.538574,526.065063 1018.160583,526.706116 1014.256165,528.199402 
	C1011.452393,529.271667 1008.610535,530.044861 1005.527710,530.054016 
	C1000.586365,530.068604 996.541687,531.903442 993.771790,536.302368 
	C991.012634,540.684265 986.622070,541.940430 981.709045,542.000000 
	C971.035828,542.129395 960.789429,540.207092 950.384705,537.629028 
	C940.792725,535.252441 930.403992,537.015198 920.578308,534.185669 
	C917.392944,533.268372 914.961914,535.746338 913.666321,538.769104 
	C911.200623,544.521729 906.266907,547.400879 900.799500,545.764038 
	C890.405334,542.652161 881.399475,547.495483 871.816650,549.049316 
	C869.533447,549.419556 866.878052,550.546387 864.993896,549.803467 
	C856.219788,546.343994 848.873657,550.505127 841.190002,553.409668 
	C831.892456,556.924316 822.743164,558.686584 814.044495,551.487671 
	C810.296692,548.386108 806.337158,550.579956 802.887817,552.599792 
	C792.810059,558.500793 781.075439,560.687378 770.341797,560.585632 
	C758.635315,560.474487 746.357910,562.124634 734.714905,558.220276 
	C733.203552,557.713440 731.407898,558.054810 728.876709,558.004272 
	C721.167786,563.631287 712.605286,560.191711 704.315308,562.487915 
	C698.137939,562.964294 693.234924,565.429871 687.982239,566.241577 
	C680.419556,567.410278 673.756958,566.123840 671.315979,557.552795 
	C670.170044,553.528809 667.726013,551.443481 663.902771,550.351135 
	C657.471741,548.513855 655.179932,543.767273 654.981812,537.477722 
	C654.761169,530.471130 655.380493,523.490906 655.515442,516.497253 
	C655.634644,510.317596 657.530334,504.716827 650.287903,500.038208 
	C646.001343,497.269104 649.444397,491.849152 654.267639,492.165100 
	C665.314209,492.888702 674.030212,487.151764 683.638062,483.633453 
	C693.003174,480.204132 702.536621,480.054962 711.569214,485.428284 
	C714.004395,486.876953 716.232727,489.347351 719.639282,488.052887 
	C716.257019,479.998657 709.562378,476.802673 698.814880,478.139771 
	C695.520569,478.549561 692.376526,480.065399 688.986572,479.731354 
	C683.086914,479.150085 678.805908,473.618164 679.740417,467.672180 
	C680.722107,461.426514 684.886108,459.218018 690.614380,460.436768 
	C697.153015,461.827881 703.781738,462.421051 710.335327,463.516449 
	C716.490540,464.545258 718.695679,462.083252 719.771667,456.502655 
	C720.881104,450.747955 720.938782,444.351257 727.275146,440.207947 
	C738.285339,442.211273 747.375671,436.313049 757.271667,435.604614 
	C760.590271,435.367065 763.701111,435.371185 767.005127,435.672821 
	C772.853943,436.206726 778.614136,437.531555 784.724976,436.286316 
	C788.819519,435.451965 793.870972,434.379333 796.869629,440.159760 
	C798.547668,443.394531 801.635437,441.293030 803.497681,439.300781 
	C810.015686,432.327789 818.605469,430.770020 827.446777,430.057709 
	C832.691345,429.635162 837.616028,429.012909 842.841919,427.378448 
	C851.573181,424.647705 861.414856,424.077484 870.144348,427.809937 
	C874.527161,429.683929 877.784546,429.696289 881.196655,427.334381 
	C887.451050,423.005096 892.170227,425.187073 897.003540,429.901154 
	C903.615112,436.349396 906.843994,436.646729 915.191956,431.759430 
	C920.757629,428.501007 926.485352,426.376251 932.987244,425.930573 
	C940.341370,425.426483 946.334167,426.443909 948.081726,435.056000 
	C948.779480,438.494629 951.301270,439.935577 954.687744,439.943329 
	C962.218140,439.960541 969.692139,441.381012 973.911316,431.756042 
	C976.796387,425.174652 987.987610,423.361725 994.153870,427.743256 
	C998.531128,430.853638 1002.449524,433.174927 1007.801147,431.120331 
	C1009.163269,430.597351 1010.716858,430.563416 1011.317444,431.874756 
	C1014.271240,438.324219 1017.071228,434.111359 1019.233093,431.564484 
	C1022.226807,428.037689 1024.910400,424.769592 1029.491821,422.683350 
	C1033.711670,420.761749 1036.500977,419.269714 1039.445312,423.951324 
	C1040.343384,425.379456 1042.133423,425.915741 1043.906372,425.575745 
	C1054.593872,423.526062 1065.708008,423.128601 1075.000366,416.136993 
	C1079.745728,412.566559 1085.129028,411.006226 1090.485962,415.554626 
	C1091.751953,416.629578 1093.178345,417.673248 1094.990601,417.199341 
	C1104.191162,414.793274 1113.559326,416.471802 1122.839233,416.035065 
	C1125.924561,415.889862 1128.664917,415.699188 1130.735352,412.837982 
	C1133.205933,409.423737 1135.993286,410.032745 1139.327515,412.269623 
	C1148.272827,418.270691 1150.859741,417.765747 1158.369263,409.359802 
	C1159.500732,408.093140 1160.647583,406.259735 1162.201172,406.449249 
	C1169.197266,407.302673 1176.440430,401.398804 1183.257935,407.230347 
	C1183.924438,407.800415 1185.181030,407.976471 1186.120483,407.886108 
	C1196.971069,406.842438 1207.886963,406.152039 1218.617188,404.360291 
	C1222.257568,403.752411 1225.196777,402.671143 1228.977417,404.283661 
	C1234.972412,406.840637 1236.822388,405.828125 1240.193726,400.249146 
	C1241.394775,398.261505 1242.743408,396.354614 1243.826538,394.306122 
	C1245.891724,390.399536 1249.097900,389.248077 1253.040161,390.697357 
	C1257.275391,392.254333 1261.685425,392.719879 1265.814941,391.543701 
	C1273.312134,389.408264 1280.529907,387.432831 1287.584595,392.647125 
	C1288.305054,393.179535 1289.618774,393.515167 1290.416992,393.241394 
	C1297.706055,390.741699 1302.092896,392.305847 1302.356812,400.909515 
	C1302.432739,403.382599 1304.683350,404.161011 1306.809814,403.276459 
	C1309.242554,402.264465 1311.505249,400.815491 1313.771851,399.440735 
	C1316.061035,398.052216 1317.901367,398.063507 1319.963501,400.118317 
	C1326.214844,406.347717 1334.245483,406.192810 1342.249634,405.886139 
	C1345.174683,405.774078 1347.043213,403.902283 1348.851196,401.852417 
	C1354.635010,395.294830 1354.595947,395.501678 1362.860352,398.325867 
	C1370.424683,400.910797 1378.239136,402.187683 1386.318115,402.085876 
	C1390.971924,402.027222 1394.424561,400.782074 1397.590942,397.083160 
	C1402.709229,391.104095 1416.346558,389.366119 1422.612549,393.674164 
	C1426.080688,396.058502 1429.367554,396.943024 1432.600708,394.174103 
	C1438.155884,389.416840 1444.049927,389.680725 1450.365967,392.026672 
	C1454.411133,393.529144 1458.457153,395.090790 1462.612549,396.223633 
	C1472.842773,399.012695 1483.006348,400.506012 1491.352295,391.611908 
	C1494.120728,388.661682 1497.434204,387.524689 1501.257202,388.042267 
	C1506.874756,388.802765 1512.228394,384.829681 1517.923706,387.241180 
	C1518.399536,387.442719 1519.359131,387.148163 1519.774292,386.750305 
	C1526.805420,380.011627 1534.528564,382.793793 1542.203125,385.069611 
	C1548.955688,387.071991 1556.236206,384.757141 1562.873535,387.638458 
	C1569.621826,383.047668 1580.396484,386.025116 1584.285645,375.785889 
	C1585.961426,371.373840 1590.250244,371.085449 1593.349731,373.997681 
	C1599.924316,380.175171 1607.067383,378.744781 1613.761719,375.623749 
	C1625.972046,369.931030 1638.573853,371.617157 1651.022583,372.875122 
	C1661.674805,373.951538 1672.077026,374.783051 1682.657959,372.323456 
	C1690.706543,370.452515 1698.918091,369.144073 1707.126221,368.161865 
	C1710.707031,367.733398 1713.396362,366.925964 1715.476318,363.824280 
	C1718.497803,359.318390 1722.822632,357.551544 1728.363892,357.844269 
	C1731.244995,357.996490 1734.175293,356.511658 1737.125732,356.256317 
	C1740.079712,356.000610 1743.950806,355.190582 1745.916260,356.649323 
	C1753.315063,362.141144 1757.910522,358.977142 1762.309937,352.847809 
	C1763.467285,351.235260 1765.372192,350.097198 1767.482544,350.177521 
	C1776.033813,350.503113 1784.845337,348.144287 1793.024170,352.471924 
	C1797.098022,354.627563 1801.494263,354.065460 1805.776367,353.935760 
	C1812.608643,353.728760 1816.990845,348.905640 1816.804199,341.775177 
	C1816.751953,339.772430 1815.797485,337.628113 1818.168213,336.628235 
	C1820.925171,335.465515 1823.396851,336.342529 1825.193237,338.791870 
	C1827.548584,342.003479 1830.311157,345.015106 1832.095215,348.521515 
	C1834.204834,352.668243 1837.015259,354.246277 1841.595337,354.087830 
	C1846.874023,353.905182 1852.115234,353.937866 1857.439575,355.258240 
	C1862.640869,356.548004 1866.839233,352.703491 1870.892212,349.908417 
	C1873.342773,348.218475 1875.141968,345.770416 1878.758057,347.228729 
	C1883.192749,349.017242 1887.184937,347.747162 1890.820068,344.765625 
	C1892.082275,343.730225 1893.755371,343.208588 1895.198242,342.378235 
	C1900.082520,339.567169 1903.965088,339.096069 1908.039551,344.551086 
	C1911.692139,349.441101 1913.794067,348.848450 1916.031616,343.215698 
	C1919.209473,335.216400 1926.635986,337.796722 1932.236206,336.795044 
	C1935.087036,336.285095 1938.475830,337.442169 1941.832397,336.572845 
	C1945.403809,335.647919 1947.917358,338.026794 1950.320801,340.611420 
	C1957.647095,348.489685 1958.931519,349.128174 1968.312988,344.613708 
	C1976.171753,340.832031 1984.917114,343.308777 1992.587280,339.976746 
	C2000.968384,336.335938 2010.302734,335.233307 2017.962891,329.519073 
	C2022.136841,326.405457 2027.234497,324.378601 2032.148804,322.458771 
	C2035.791748,321.035492 2039.393433,322.285828 2042.300537,324.976196 
	C2048.455322,330.672394 2055.895508,324.015442 2062.327881,327.071930 
	C2062.484863,327.146576 2063.046143,326.795258 2063.123779,326.549438 
	C2065.178711,320.036804 2072.944580,318.525299 2075.477783,312.979889 
	C2077.179199,309.254730 2079.347900,308.332031 2082.648193,307.375793 
	C2085.909668,306.430756 2089.281982,305.281677 2092.437988,306.125580 
	C2100.558594,308.297211 2108.762695,305.908813 2116.867188,307.126495 
	C2118.400146,307.356873 2120.105469,306.732666 2121.684326,306.322479 
	C2132.837158,303.424316 2143.791992,300.387177 2155.693359,300.746368 
	C2159.814209,300.870728 2165.736816,298.771423 2169.796875,294.735016 
	C2175.573975,288.991943 2179.176270,288.618073 2185.817139,291.981262 
	C2195.771729,297.022797 2205.685059,295.035522 2215.125977,291.127716 
	C2224.537354,287.232056 2234.085938,285.619385 2243.814209,287.870941 
	C2249.996826,289.301849 2254.739746,289.620941 2258.355469,283.509399 
	C2260.730469,279.494904 2264.016846,278.910522 2267.177002,282.738373 
	C2268.895020,284.818939 2270.171143,285.722382 2272.920654,284.043671 
	C2282.634521,278.113068 2293.164551,277.309174 2304.293457,278.972626 
	C2312.548828,280.206604 2320.932617,284.130341 2329.348389,278.670471 
	C2332.022461,276.935608 2335.186035,278.327271 2337.601562,280.486603 
	C2342.663330,285.011810 2347.713623,283.265411 2351.948975,279.644867 
	C2355.824463,276.332184 2360.357666,274.358063 2364.486572,271.637329 
	C2370.953857,267.375671 2377.177734,265.094543 2384.675781,269.126404 
	C2388.086426,270.960419 2391.019531,268.620819 2393.636475,266.411133 
	C2399.897217,261.124420 2406.119873,259.302063 2414.578125,262.750061 
	C2420.727051,265.256653 2427.886719,265.632660 2434.510742,267.692810 
	C2439.135742,269.131256 2444.354492,268.188507 2448.997070,265.571106 
	C2455.466553,261.923645 2462.549561,260.178497 2469.976562,260.005615 
	C2476.329590,259.857727 2481.011963,255.839676 2486.164062,253.021347 
	C2490.816162,250.476624 2494.982910,248.801468 2499.991211,252.503860 
	C2503.401367,255.024658 2508.175293,255.205643 2511.370117,252.827072 
	C2518.654785,247.403290 2527.725098,247.720947 2535.572754,244.219666 
	C2539.435303,242.496414 2543.837646,242.007599 2547.595703,239.661224 
	C2552.123779,236.834015 2556.448730,237.407852 2560.247803,241.271744 
	C2561.416016,242.459824 2562.697510,243.545578 2563.788086,244.799316 
	C2566.972656,248.460693 2571.029297,248.235214 2572.992188,244.648453 
	C2576.491455,238.255356 2581.749268,236.845947 2588.187744,235.110245 
	C2593.287354,233.735458 2597.845459,230.768250 2603.550537,229.540848 
	C2614.388184,227.209137 2624.657471,230.004333 2635.163086,230.003693 
	C2638.831299,230.003464 2642.599854,230.389282 2644.953125,226.582840 
	C2645.288818,226.039688 2645.899658,225.308853 2646.411377,225.285507 
	C2654.939209,224.896255 2660.828857,222.359650 2661.831543,212.192337 
	C2662.214355,208.308563 2671.477051,206.387161 2677.134521,208.900208 
	C2684.765625,212.289963 2691.073242,211.261475 2697.239502,205.951859 
	C2702.183594,201.694824 2708.102539,200.585144 2714.066406,203.166748 
	C2719.300781,205.432587 2724.474365,205.324844 2729.572998,203.854218 
	C2736.439453,201.873795 2743.374512,202.012466 2750.353271,201.988434 
	C2752.016602,201.982712 2754.000977,202.413956 2755.289062,201.680817 
	C2762.028320,197.844574 2767.532471,198.908798 2772.847412,204.545746 
	C2774.900879,206.723663 2788.418457,203.649963 2790.593018,201.209732 
	C2791.875977,199.769699 2791.903564,198.168793 2791.006104,196.631195 
	C2790.345459,195.498779 2789.348389,194.564499 2788.517822,193.528809 
	C2786.922852,191.539719 2783.672363,190.012756 2784.991943,186.988327 
	C2786.286865,184.020874 2787.891113,180.838837 2790.772461,179.012497 
	C2796.094482,175.639130 2805.693359,178.271317 2807.444824,184.417145 
	C2808.980713,189.806732 2812.038818,190.166962 2816.261230,190.001175 
	C2823.997070,189.697388 2831.116943,191.017548 2838.392334,194.458435 
	C2844.114990,197.164917 2851.244873,196.979950 2857.781006,197.840698 
	C2860.503906,198.199265 2862.075928,196.134430 2862.973389,193.906555 
	C2865.317871,188.086624 2870.321533,185.518066 2875.592529,183.273926 
	C2878.939941,181.848907 2882.536133,180.999405 2885.105713,178.075745 
	C2886.758545,176.195648 2889.270020,175.632126 2891.536133,175.834259 
	C2908.217041,177.322464 2924.356445,167.938492 2941.350830,173.660446 
	C2945.578369,175.083847 2950.562988,174.668243 2954.368408,170.749069 
	C2958.024170,166.984024 2961.705566,167.637268 2965.867676,170.726608 
	C2972.220703,175.442093 2973.143066,175.080490 2976.338867,167.540741 
	C2978.332520,162.836227 2980.795166,159.969269 2986.552734,159.850601 
	C2994.990723,159.676727 2998.316162,155.619888 2997.917236,147.324860 
	C2997.807129,145.042114 2997.030029,142.787979 2996.523438,140.528748 
	C2995.836182,137.463516 2997.402100,135.393677 2999.619629,133.701294 
	C3003.624023,130.645325 3008.704590,132.624939 3013.118652,130.892288 
	C3016.019775,129.753586 3017.702637,132.945145 3019.636719,134.654419 
	C3021.137207,135.980560 3022.514648,137.449600 3025.303711,137.914017 
	C3026.000000,139.500000 3026.000000,141.000000 3026.000000,143.250000 
	C3025.465088,154.339859 3016.827148,164.431396 3025.524414,175.519806 
	C3026.000000,209.375122 3026.000000,242.750244 3026.000000,277.062683 
z"
        />
        <path
          fill="#699BD5"
          opacity="1.000000"
          stroke="none"
          d="
M701.524536,560.598450 
	C709.797729,556.278320 718.114136,558.678772 727.093262,558.003662 
	C735.260315,565.448120 744.032288,569.608459 754.451477,569.961670 
	C762.382080,570.230469 770.270142,569.460999 777.867371,573.383179 
	C781.826782,575.427246 786.656982,573.982544 789.553406,570.154846 
	C791.985779,566.940369 794.459839,567.310181 798.089417,567.450012 
	C813.954224,568.060913 829.850708,567.938232 845.734985,567.881897 
	C849.505981,567.868530 852.992920,566.270691 855.254700,563.131897 
	C857.937927,559.408081 860.764038,559.619873 863.480530,562.604187 
	C869.296509,568.993530 876.721558,563.454285 883.235596,565.390198 
	C885.768921,566.143127 886.865906,562.141663 887.741211,559.620056 
	C890.625916,551.309814 890.794189,551.049072 898.359985,555.605347 
	C904.153625,559.094421 909.834717,558.859680 915.431946,556.141479 
	C920.924866,553.473938 926.772766,552.955139 932.487366,551.550842 
	C940.010376,549.702148 946.598816,549.388489 953.942200,551.763794 
	C957.864136,553.032532 962.533203,552.068054 966.862366,551.979736 
	C970.906189,551.897278 974.735535,552.345398 978.494507,554.147644 
	C984.493408,557.023987 990.845947,557.024658 996.864685,554.395752 
	C1000.973328,552.601135 1005.050354,551.969299 1009.445435,551.951965 
	C1018.544739,551.916077 1026.342163,548.988892 1031.593750,541.027039 
	C1033.960815,537.438416 1037.130371,536.817444 1039.722046,540.171997 
	C1044.319458,546.122620 1049.590698,545.787292 1055.637695,543.195190 
	C1058.378174,542.020386 1061.268555,541.140259 1064.158203,540.383972 
	C1066.947388,539.653931 1069.505981,540.300476 1071.198486,542.837585 
	C1073.236938,545.893311 1075.013428,549.531555 1079.163330,549.724426 
	C1084.385376,549.967224 1089.861816,551.344360 1094.734619,548.145081 
	C1098.847290,545.444885 1101.587402,545.585693 1102.032959,551.264221 
	C1102.413574,556.115723 1105.436523,556.389343 1109.122803,555.273499 
	C1112.306274,554.309814 1115.684082,553.617004 1118.544678,552.027832 
	C1126.870239,547.402710 1135.839111,548.008972 1144.814453,547.949097 
	C1149.650513,547.916748 1153.161011,548.938232 1154.457275,554.359375 
	C1155.790039,559.933838 1159.467041,562.372253 1165.508667,562.120605 
	C1172.344116,561.835938 1179.045044,563.358582 1186.122437,562.335388 
	C1193.844116,561.218994 1202.365356,561.335022 1204.917114,572.197571 
	C1205.596802,575.090881 1209.453247,575.850098 1212.561279,575.921204 
	C1217.223389,576.027893 1221.891602,576.091003 1226.552002,575.968628 
	C1230.332642,575.869324 1233.838989,576.554749 1237.016235,578.638062 
	C1241.925049,581.856812 1242.760376,584.813416 1240.390137,590.425903 
	C1237.562988,597.120056 1237.626953,598.675842 1244.194336,600.777222 
	C1249.608154,602.509460 1252.213623,606.334412 1254.995728,610.372375 
	C1263.420898,622.600525 1266.422363,623.815613 1280.793945,620.742004 
	C1285.325073,619.772949 1289.991455,619.505188 1294.165283,617.075684 
	C1296.857178,615.508789 1300.047974,615.721680 1302.982178,616.107361 
	C1308.181396,616.790710 1313.225952,618.490417 1318.295532,615.117615 
	C1320.865845,613.407532 1323.415894,615.683472 1325.683472,616.895874 
	C1329.959961,619.182373 1333.466064,619.075012 1338.562866,617.598450 
	C1346.833374,615.202576 1355.803101,612.928894 1364.820801,615.254761 
	C1369.410522,616.438538 1374.253784,616.832886 1378.628906,615.287170 
	C1384.610474,613.174072 1389.666504,614.460449 1394.805908,617.384399 
	C1400.536255,620.644592 1405.970093,621.831543 1410.301270,614.976196 
	C1411.091919,613.724854 1412.977661,612.465149 1414.391235,612.426453 
	C1421.175903,612.240417 1427.584473,611.326050 1433.100464,606.835388 
	C1434.501099,605.695129 1436.479614,606.294861 1437.426880,607.910889 
	C1443.253906,617.852295 1452.070679,614.902405 1460.746826,613.748413 
	C1472.194458,612.225830 1483.273926,608.866882 1494.844116,607.785706 
	C1502.587646,607.062134 1507.509155,611.109131 1512.068970,615.580322 
	C1516.875244,620.293213 1521.363647,622.684021 1527.704224,619.146973 
	C1531.156128,617.221313 1533.962891,619.440674 1537.088623,620.556152 
	C1552.224365,625.957581 1567.386108,629.035950 1583.234253,622.523621 
	C1591.720215,619.036621 1600.280518,614.905884 1609.990723,615.994019 
	C1611.639526,616.178833 1613.608276,615.695312 1614.911133,616.434204 
	C1625.579956,622.483948 1636.663086,620.313660 1647.761108,618.439941 
	C1648.416626,618.329163 1649.150269,617.994812 1649.721436,618.171692 
	C1658.075806,620.759277 1665.942139,618.393250 1673.932739,616.188354 
	C1678.406494,614.953979 1681.223267,618.346375 1684.182129,621.121765 
	C1691.214233,627.718201 1693.363525,627.996582 1699.756104,621.007812 
	C1704.709961,615.592102 1710.039062,613.397034 1717.327148,613.852051 
	C1724.791748,614.318054 1733.008057,615.462280 1736.758911,605.883972 
	C1737.518433,603.944458 1739.558838,605.104797 1741.016846,606.002075 
	C1745.901367,609.008240 1750.052490,608.769653 1754.267700,604.226379 
	C1757.168823,601.099304 1759.691895,602.249512 1759.837646,606.622620 
	C1760.057617,613.226379 1760.962769,619.964111 1758.116089,627.179077 
	C1755.962891,629.695374 1753.828369,631.284058 1751.922485,633.111328 
	C1749.173950,635.746948 1746.410522,636.733704 1743.107300,634.100342 
	C1739.809204,631.471191 1736.724609,632.104736 1732.969238,633.826599 
	C1726.374878,636.850220 1719.536377,638.647461 1711.916382,636.520447 
	C1705.628174,634.765259 1699.301880,635.835388 1694.265381,640.761780 
	C1690.091064,644.844849 1685.696167,646.790344 1680.064941,643.177979 
	C1676.556641,640.927368 1672.816040,642.094666 1669.719971,644.260681 
	C1666.202148,646.721802 1663.156616,648.520264 1659.146118,644.988892 
	C1657.553833,643.586731 1654.803345,644.118652 1654.468018,646.635010 
	C1653.111938,656.811218 1644.614624,659.390869 1637.168823,661.755859 
	C1631.769409,663.471008 1629.217896,669.359680 1623.847168,669.603821 
	C1619.023926,669.823120 1615.237549,665.388794 1609.719482,665.788757 
	C1602.505859,666.311646 1595.147461,666.722961 1587.890503,664.627747 
	C1585.636597,663.977051 1582.708252,665.268188 1580.188599,666.010132 
	C1573.931763,667.852600 1567.868042,671.131836 1561.096313,668.052124 
	C1551.205200,673.037720 1540.252441,672.849487 1529.676758,674.498779 
	C1524.547241,682.063660 1518.274536,683.607239 1510.597900,678.204834 
	C1510.173584,677.906189 1509.136719,677.997131 1508.664429,678.318054 
	C1497.868286,685.653381 1486.780151,679.440247 1475.915039,678.705750 
	C1471.711548,678.421631 1468.187134,680.359497 1464.187622,680.023987 
	C1457.692261,679.479126 1451.358521,680.381165 1444.876099,681.209534 
	C1438.105225,682.074707 1430.749023,678.479309 1424.335815,683.853577 
	C1417.038086,681.153687 1408.846680,683.682312 1401.826050,680.642456 
	C1392.393799,676.558533 1382.687134,677.079102 1373.391846,678.868713 
	C1363.513916,680.770569 1353.582153,679.570740 1343.667480,681.290222 
	C1337.465820,682.365784 1330.216309,678.631897 1323.727661,676.260376 
	C1320.812744,675.195068 1318.501709,674.116394 1315.302490,675.142029 
	C1309.814209,676.901428 1303.970337,676.480591 1298.437622,675.797180 
	C1285.736816,674.228394 1274.130737,676.795715 1263.280029,683.267700 
	C1259.226562,685.685547 1255.441284,687.016235 1251.709717,682.776489 
	C1249.247559,679.979004 1245.873657,679.955139 1242.483643,679.980042 
	C1239.822754,679.999634 1236.884766,680.523071 1234.553101,679.600952 
	C1228.719727,677.293823 1224.138672,678.913574 1220.060791,682.991211 
	C1217.520142,685.531616 1215.414673,685.634583 1212.259521,683.574097 
	C1202.189941,676.997925 1191.333008,676.776489 1180.267578,680.838196 
	C1177.994995,681.672424 1175.762695,682.257019 1173.466553,681.473267 
	C1167.603394,679.472168 1161.725708,679.377258 1155.806396,681.059998 
	C1154.072754,681.552795 1152.410400,681.348206 1151.019531,680.404114 
	C1146.870728,677.587830 1143.996338,679.375244 1140.444092,682.085327 
	C1136.627563,684.997131 1130.995972,684.796204 1127.457520,688.876953 
	C1126.090698,690.453247 1123.640259,689.866882 1121.811157,688.892029 
	C1114.163940,684.816345 1105.486450,686.494568 1097.398193,684.597595 
	C1093.235474,683.621338 1088.245117,685.412781 1083.806885,686.612427 
	C1080.244385,687.575378 1079.708496,690.570251 1080.949341,693.883972 
	C1081.661011,695.784851 1082.391602,697.887939 1080.165649,699.181335 
	C1078.491821,700.153870 1077.156982,698.909180 1075.788574,697.832275 
	C1063.424438,688.102234 1049.378662,688.592163 1035.028442,691.372070 
	C1031.006226,692.151306 1027.147949,692.201660 1023.250916,690.751587 
	C1019.338318,689.295776 1016.070618,690.401428 1013.582275,693.765320 
	C1011.850403,696.106628 1011.657898,698.724731 1012.545410,701.357239 
	C1013.508301,704.213379 1016.133789,703.942261 1018.420959,703.960693 
	C1026.085449,704.022644 1033.750488,703.984741 1041.415405,704.005615 
	C1043.746826,704.011963 1046.150391,703.736450 1048.393921,704.201782 
	C1052.633179,705.080994 1057.333130,706.036255 1057.742676,711.540771 
	C1058.119019,716.597717 1054.493652,719.958069 1050.455811,721.205750 
	C1046.153320,722.535278 1042.466919,724.118713 1039.451172,727.431702 
	C1037.210205,729.893616 1034.313721,730.484070 1031.219116,729.406250 
	C1028.637451,728.507202 1026.411621,728.152344 1023.617676,729.431458 
	C1016.893433,732.509949 1011.440491,729.448120 1010.123535,722.329712 
	C1009.943726,721.358032 1010.082520,720.331970 1009.994385,719.338135 
	C1009.236267,710.779724 1005.561218,707.604553 997.771179,710.688293 
	C991.425354,713.200256 984.490906,714.653809 981.077881,723.218689 
	C978.604309,729.426025 970.654114,733.208313 963.047852,733.861084 
	C957.093323,734.372131 951.058411,734.044983 945.062012,733.937439 
	C940.882812,733.862549 936.913635,732.552185 934.715149,728.900574 
	C932.285278,724.864807 928.992188,723.683777 924.670593,723.993652 
	C917.856201,724.482300 911.516785,723.990540 906.637695,718.007690 
	C904.670837,715.595947 900.884888,714.713013 898.911194,717.948303 
	C897.137024,720.856628 897.173828,724.468933 901.514526,726.067627 
	C902.449951,726.412170 903.391235,726.775208 904.261963,727.253357 
	C906.010986,728.213989 907.968079,729.165527 907.283386,731.706726 
	C906.744690,733.705811 904.917603,734.024780 903.279907,733.890503 
	C898.974609,733.537170 895.882019,736.355408 892.216919,737.617615 
	C881.917419,741.164368 871.219421,745.097961 861.910522,734.980713 
	C858.978455,731.793945 856.190002,731.485107 852.541138,734.756531 
	C847.187805,739.555969 843.373779,739.216736 837.765076,734.716980 
	C836.988342,734.093750 836.369202,733.271484 835.582764,732.663330 
	C831.102844,729.198425 829.916016,729.445068 827.018555,734.042908 
	C826.009033,735.644836 826.557800,738.197632 824.090088,738.926086 
	C821.538269,731.657349 817.508728,728.546143 810.054016,728.095032 
	C805.958984,727.847229 802.550110,728.880249 802.162537,733.630798 
	C801.853516,737.419189 800.920532,740.051575 796.408997,740.002319 
	C791.072388,739.944031 785.635315,740.706238 782.559509,734.620300 
	C778.948425,727.475281 777.478577,726.598999 772.526123,731.175964 
	C766.261414,736.965820 760.464844,737.364746 754.382080,732.590515 
	C751.713074,733.968445 752.090454,736.486877 750.657410,737.884521 
	C748.480347,740.007629 745.395752,739.993225 745.512878,737.054871 
	C745.738770,731.384338 742.795532,730.407898 738.417603,729.590820 
	C736.245056,729.185364 734.401733,727.235413 732.231628,726.535889 
	C730.033875,725.827393 727.213867,725.526917 726.713135,728.751099 
	C726.295959,731.437317 728.690369,731.817078 730.730408,731.915588 
	C734.383484,732.091919 738.096436,731.982300 740.603027,735.136108 
	C741.067078,736.407532 740.459839,737.157043 739.754089,737.893921 
	C730.289307,747.775940 733.851440,748.249268 721.639465,740.499817 
	C716.939758,737.517456 711.253723,736.726440 706.757019,732.946167 
	C704.055115,730.674866 700.235291,731.949463 698.548828,735.724182 
	C697.521240,738.024475 698.050659,741.241943 694.457642,741.813965 
	C690.939880,742.373962 687.419373,741.605652 685.079163,739.294373 
	C677.923035,732.226807 671.720703,732.250610 662.207092,736.769897 
	C653.232056,741.033386 641.804382,738.655151 632.597351,733.306152 
	C625.570862,729.224060 619.374207,727.547546 611.262390,730.913940 
	C603.972656,733.939026 595.827209,734.789368 589.776550,740.684937 
	C588.006042,742.410034 585.267090,742.448669 583.095581,741.741638 
	C573.951843,738.764343 564.575928,740.463074 555.314575,740.005615 
	C549.696167,739.728027 544.009949,740.689026 538.503906,738.551636 
	C534.741089,737.091003 531.311340,739.281067 527.896667,740.375183 
	C521.949829,742.280762 516.260254,743.592896 511.241638,738.323547 
	C509.357605,736.345398 507.242950,736.119629 505.063477,737.952881 
	C504.809265,738.166626 504.494293,738.311707 504.254456,738.538330 
	C501.613770,741.034058 497.294769,736.327942 495.251190,740.429932 
	C493.208923,744.529419 498.031006,745.909668 499.147095,748.816406 
	C500.604248,752.611389 498.589722,755.587158 493.565918,755.859863 
	C486.935822,756.219727 480.301239,755.869263 473.797272,757.860168 
	C469.689240,759.117737 465.273438,759.379089 461.178894,760.668884 
	C456.543457,762.129150 452.194977,761.656677 447.472778,761.004089 
	C435.953033,759.412109 424.373016,759.133057 412.778595,761.360962 
	C400.829620,763.656982 388.998047,766.054565 376.483704,765.185730 
	C370.243286,764.752441 364.415253,764.265747 358.250702,762.833130 
	C350.329773,760.992554 341.701782,760.388062 333.969818,765.012451 
	C328.834290,768.083923 323.448059,768.962158 317.688629,766.926331 
	C316.448212,766.487915 315.076996,766.042847 313.803375,766.132263 
	C297.798187,767.255859 282.001526,763.804016 266.047424,764.017334 
	C257.999451,764.124939 256.979034,764.864441 254.347534,772.104614 
	C253.594498,774.176331 254.210983,777.192932 251.043289,777.528809 
	C248.333206,777.816101 246.670303,775.843323 245.886871,773.569580 
	C243.424545,766.423096 236.767105,767.649780 231.648666,766.900391 
	C226.961700,766.214233 223.076630,771.356140 222.001236,777.620361 
	C220.932968,783.843262 220.979660,783.537170 214.543961,782.555420 
	C208.273682,781.599060 202.986053,779.192322 199.353073,774.002197 
	C197.175781,770.891785 194.525116,769.719055 190.892548,769.981812 
	C187.203415,770.248657 183.677536,769.423401 180.086807,768.509033 
	C175.835800,767.426636 171.448975,767.369019 167.988998,771.003540 
	C165.122086,774.015076 161.869370,773.988586 157.923508,773.109436 
	C149.209671,771.167908 140.350266,769.881653 131.554581,768.303955 
	C129.138138,767.870544 127.000130,768.823425 124.743294,769.395813 
	C118.300888,771.029663 111.848953,772.677551 105.075836,772.030396 
	C102.038528,771.740173 99.083237,772.289062 96.394714,773.829651 
	C90.471130,777.223999 85.108986,776.033997 79.540710,772.542297 
	C73.226089,768.582703 70.807129,768.743530 65.434280,773.760742 
	C61.577690,777.362122 57.948399,778.798462 52.874653,776.747009 
	C50.814171,775.913757 48.292049,776.012451 45.984989,776.044678 
	C41.838345,776.102539 38.695343,775.126831 35.445786,771.852905 
	C28.915184,765.273376 24.105961,767.019775 21.657482,775.977112 
	C20.519735,780.139404 19.362431,784.296265 18.114197,789.222900 
	C14.089576,795.372864 9.097701,795.796387 2.720070,792.563110 
	C2.000000,719.958191 2.000000,647.916443 2.000000,574.937317 
	C8.854923,569.745300 17.092237,569.419800 24.348721,566.109924 
	C28.209850,564.348816 32.297047,566.377136 34.801502,570.021057 
	C40.041920,577.645752 47.619690,579.145874 55.962490,578.002075 
	C67.281868,576.450195 78.460526,577.008545 89.790489,578.220947 
	C105.233963,579.873474 120.307556,578.312622 133.382523,568.611023 
	C137.037445,565.899048 140.906616,565.333435 144.411057,568.175964 
	C149.283035,572.127686 154.528244,572.642883 160.367081,571.537659 
	C163.333435,570.976196 166.946701,570.681824 169.024078,572.796997 
	C174.789597,578.667480 179.922897,576.641541 185.872284,573.123352 
	C192.660492,569.109070 200.383957,567.972168 208.112915,569.593018 
	C216.029724,571.253235 222.160309,569.166443 228.078094,563.650146 
	C239.901550,552.628784 254.531509,556.286255 268.009979,558.357056 
	C277.564209,559.824829 287.455109,562.574646 296.613831,561.255249 
	C308.360199,559.562988 318.907745,555.701904 331.633057,559.698425 
	C337.352814,561.494812 346.254639,553.284973 352.910950,548.401306 
	C362.550629,541.328796 372.102509,539.986267 382.413300,546.598999 
	C385.795197,548.767944 389.926727,551.666992 393.529724,549.506226 
	C404.040283,543.202942 412.665344,548.929626 421.737366,552.684875 
	C425.431274,554.213867 429.130005,555.756836 432.602478,557.733887 
	C435.372314,559.310974 437.169800,559.261353 439.107971,556.213928 
	C442.993134,550.105286 448.548706,550.764832 453.994049,553.782471 
	C462.317596,558.395203 470.861298,559.303101 480.032654,556.805664 
	C486.821167,554.957153 493.761261,553.526062 500.025452,558.690796 
	C502.737488,560.926819 504.891510,558.814575 507.235443,557.584900 
	C519.958679,550.910095 522.468872,551.444702 531.345886,562.410034 
	C536.314392,568.547363 550.258606,570.631348 561.162109,566.686890 
	C566.164185,564.877319 570.922363,562.308289 576.253113,561.546265 
	C584.738342,560.333435 592.981995,560.283752 599.166992,567.722107 
	C601.575134,570.618408 603.466492,570.075745 606.648865,568.019531 
	C617.042908,561.303833 628.325867,559.550659 639.065063,566.888306 
	C648.481567,573.322205 657.192383,576.848938 668.523254,570.741211 
	C675.018677,567.239929 682.956787,570.756592 690.199219,571.094299 
	C697.997498,571.457947 702.173401,569.734924 701.524536,560.598450 
z"
        />
        <path
          fill="#96B6D1"
          opacity="1.000000"
          stroke="none"
          d="
M1325.932129,1676.833496 
	C1328.025269,1671.700317 1328.621582,1666.526123 1334.834106,1665.096069 
	C1338.217041,1664.317261 1337.797363,1660.418945 1338.031128,1657.405640 
	C1338.638916,1649.573975 1339.804810,1647.229858 1346.742065,1646.470825 
	C1353.058716,1645.779785 1354.913940,1642.406372 1356.603638,1637.263184 
	C1361.272095,1623.053955 1361.059570,1622.928467 1346.702026,1619.594849 
	C1340.400391,1618.131714 1334.207886,1618.146484 1327.915161,1617.984741 
	C1325.265747,1617.916748 1322.591187,1617.867188 1319.984253,1617.453979 
	C1318.295898,1617.186523 1316.991211,1615.974365 1316.960327,1614.032593 
	C1316.929199,1612.067993 1318.256470,1610.854370 1319.925781,1610.574341 
	C1322.526367,1610.138062 1325.204590,1610.031494 1327.850830,1610.022583 
	C1342.169800,1609.974731 1356.489380,1610.028931 1370.808472,1609.984131 
	C1375.775635,1609.968628 1380.741699,1610.346680 1385.614746,1608.549072 
	C1389.230591,1607.215088 1392.800903,1608.333740 1396.186279,1610.060303 
	C1401.544556,1612.793091 1407.057739,1612.557373 1412.653320,1610.742188 
	C1426.644287,1606.203491 1441.172852,1605.004395 1455.718872,1604.049072 
	C1459.054688,1603.830078 1462.308838,1603.676270 1465.508057,1602.511475 
	C1473.582764,1599.571899 1481.538818,1600.309082 1489.917236,1602.146606 
	C1504.275635,1605.295776 1518.865356,1605.678711 1533.219971,1601.322388 
	C1534.490112,1600.937012 1535.794678,1600.186157 1537.038818,1600.269287 
	C1548.059448,1601.006470 1558.805298,1597.961792 1569.764160,1598.008057 
	C1571.426758,1598.015015 1573.520996,1597.480225 1574.685303,1598.271118 
	C1588.258057,1607.489502 1601.825684,1600.385132 1615.402344,1598.558228 
	C1618.013428,1598.206909 1620.917847,1597.625488 1623.286255,1598.390625 
	C1627.997803,1599.912231 1631.458252,1600.034302 1633.839355,1594.775146 
	C1635.582886,1590.924316 1638.649658,1592.569336 1641.532104,1593.712891 
	C1643.404419,1594.455566 1645.855835,1597.093750 1647.368896,1593.636841 
	C1648.511841,1591.025757 1646.090576,1589.626587 1644.253174,1588.094727 
	C1639.267700,1583.937988 1632.919678,1582.987305 1626.991943,1580.901001 
	C1632.291748,1572.134766 1637.040283,1569.936523 1644.544556,1573.943237 
	C1648.491699,1576.050781 1651.365967,1575.430908 1654.731567,1573.663330 
	C1658.841309,1571.505127 1662.592896,1569.744629 1667.869019,1571.348999 
	C1673.765991,1573.142212 1678.647827,1567.282959 1684.572998,1566.739746 
	C1685.067749,1566.694214 1685.780762,1565.102661 1685.782593,1564.228149 
	C1685.797485,1557.154541 1689.245972,1555.853882 1695.468506,1557.747681 
	C1697.889648,1558.484741 1700.968628,1558.437622 1703.358521,1557.615234 
	C1708.422852,1555.872681 1711.463135,1556.662964 1715.195312,1561.166016 
	C1719.125122,1565.907471 1724.926270,1561.580688 1729.835693,1560.662598 
	C1731.344971,1560.380493 1732.616455,1558.908936 1734.036011,1558.046631 
	C1736.491821,1556.554565 1739.049561,1555.824463 1741.242310,1558.346802 
	C1744.493042,1562.086060 1748.743530,1562.117920 1753.146851,1562.022583 
	C1757.806763,1561.921631 1762.471802,1562.065430 1767.132324,1561.981201 
	C1771.218994,1561.907471 1774.958740,1561.980713 1777.535034,1566.174072 
	C1780.075439,1570.309204 1783.831543,1570.452515 1787.928589,1568.069336 
	C1790.221436,1566.735718 1792.642456,1565.518188 1795.150757,1564.683960 
	C1798.782715,1563.476196 1802.351562,1563.598145 1805.392822,1566.472290 
	C1813.074341,1573.731689 1821.786011,1574.592163 1832.050171,1572.680298 
	C1840.755005,1571.058838 1849.960083,1572.075806 1858.944458,1572.015381 
	C1865.949341,1571.968262 1873.119141,1572.960327 1875.032837,1563.051147 
	C1875.685547,1559.672485 1881.828491,1559.494873 1883.840576,1563.668945 
	C1887.259399,1570.761108 1891.866455,1568.281616 1897.505249,1568.536865 
	C1910.332153,1569.118042 1915.874146,1562.184082 1920.432373,1552.012085 
	C1924.485474,1542.967163 1932.299805,1540.680542 1940.537476,1545.121948 
	C1946.770752,1548.482666 1948.515747,1547.958496 1951.055542,1541.641846 
	C1951.911743,1539.512085 1952.335938,1537.208618 1953.468994,1534.494629 
	C1957.225830,1531.203491 1960.722900,1530.682739 1965.267700,1533.423462 
	C1968.782104,1537.277588 1971.310547,1540.959595 1977.240479,1539.998535 
	C1980.990967,1543.098999 1984.870972,1541.940674 1988.403198,1541.952026 
	C2015.713379,1542.039185 2043.024048,1541.989136 2070.334473,1542.009888 
	C2075.284912,1542.013672 2080.243896,1541.492676 2084.673584,1544.904663 
	C2087.111816,1546.782959 2090.474854,1545.979370 2093.448975,1545.984863 
	C2110.434814,1546.015869 2127.441162,1546.523804 2144.400391,1545.851562 
	C2161.082520,1545.190186 2177.528320,1547.900879 2194.129395,1548.049683 
	C2204.114258,1548.139282 2213.799805,1544.894897 2223.881104,1545.889160 
	C2227.951416,1546.290771 2231.888672,1546.225952 2234.711914,1549.731934 
	C2237.742188,1553.494995 2241.786621,1554.199585 2246.346436,1554.051025 
	C2252.667725,1553.844971 2259.000732,1554.002075 2265.328857,1553.996704 
	C2266.660889,1553.995605 2268.241699,1554.431396 2269.286621,1553.874756 
	C2280.385742,1547.962891 2292.009033,1552.314331 2303.362061,1552.102417 
	C2313.354248,1551.915771 2323.305664,1552.191650 2332.910645,1548.746094 
	C2334.434814,1548.199341 2336.250977,1547.828125 2337.804688,1548.100952 
	C2352.498535,1550.680542 2367.763184,1545.202759 2381.808350,1549.208984 
	C2395.336426,1553.067749 2409.037842,1549.823486 2422.244873,1553.549438 
	C2426.045410,1554.621704 2430.254150,1554.653687 2434.014160,1552.863525 
	C2443.286377,1548.448975 2452.761963,1549.118652 2462.222656,1551.733765 
	C2470.767822,1554.095947 2479.113525,1555.032593 2487.550293,1551.287231 
	C2489.600830,1550.376953 2492.089600,1549.984375 2494.344727,1550.083008 
	C2506.361084,1550.609131 2518.192139,1549.145142 2529.944824,1546.959839 
	C2532.526123,1546.479736 2534.567871,1547.175049 2536.535645,1548.377930 
	C2538.220703,1549.408081 2539.725098,1550.769409 2541.195801,1552.108765 
	C2547.888672,1558.203735 2555.409912,1561.226807 2564.742432,1560.143677 
	C2570.620361,1559.461548 2576.654785,1559.638672 2582.530518,1561.576416 
	C2584.955811,1562.376099 2587.908691,1562.388672 2590.451416,1561.890381 
	C2609.607910,1558.135620 2628.946777,1560.747314 2648.184082,1560.052979 
	C2652.894043,1559.882935 2656.799316,1558.657837 2661.177734,1556.452026 
	C2670.333984,1551.838867 2680.684570,1552.315796 2690.020752,1556.271118 
	C2702.271240,1561.460938 2713.965576,1562.819458 2725.695312,1555.567505 
	C2727.822510,1554.252563 2730.061768,1554.265869 2732.366455,1553.969971 
	C2746.137207,1552.201416 2759.860596,1550.002197 2773.666016,1548.592285 
	C2781.578613,1547.783936 2789.615967,1548.181030 2797.597656,1548.065674 
	C2799.682861,1548.035522 2801.622803,1548.718384 2803.028076,1550.219360 
	C2807.579590,1555.081299 2813.280029,1554.712524 2818.820557,1553.290161 
	C2825.145752,1551.666504 2831.167969,1551.718384 2837.474854,1553.380615 
	C2843.368408,1554.934204 2849.288574,1553.897461 2855.166016,1552.207153 
	C2860.198242,1550.759888 2865.222412,1550.820801 2870.689209,1551.355591 
	C2883.557129,1552.614258 2896.620850,1551.881104 2909.605225,1551.943237 
	C2912.088379,1551.955200 2914.136963,1550.868408 2915.803955,1549.140381 
	C2919.395264,1545.418335 2923.315918,1545.465332 2927.593262,1547.807739 
	C2935.949463,1552.383423 2944.898193,1554.489502 2954.426025,1553.992310 
	C2958.231445,1553.793579 2961.465820,1554.671631 2964.499023,1557.307739 
	C2969.069092,1561.279053 2974.095215,1561.016235 2979.160645,1557.764038 
	C2988.306885,1551.891602 2989.656494,1552.138672 2996.712402,1560.412476 
	C2998.298584,1562.272095 3000.530273,1564.146851 3002.647949,1563.502441 
	C3009.997803,1561.266235 3017.051758,1565.132690 3025.130859,1564.000000 
	C3026.000000,1587.375122 3026.000000,1610.750244 3026.000000,1635.062744 
	C3024.965332,1636.833252 3023.965332,1638.191650 3022.687500,1638.109375 
	C3007.910156,1637.159302 2995.182373,1645.083862 2981.210693,1647.576782 
	C2976.554199,1648.407593 2971.775635,1649.110352 2967.405518,1647.207642 
	C2956.658447,1642.528198 2945.531738,1642.323120 2934.125244,1643.076782 
	C2923.061768,1643.807861 2911.842773,1638.297485 2900.856445,1643.828857 
	C2900.601074,1643.957397 2900.141357,1643.956665 2899.878174,1643.797485 
	C2887.434570,1636.268066 2873.714844,1640.781372 2860.906982,1641.015503 
	C2845.999268,1641.288208 2830.837646,1639.905640 2815.841309,1644.166992 
	C2795.485596,1649.951538 2774.336426,1645.939697 2753.544189,1646.279907 
	C2745.362061,1646.413818 2736.165527,1648.478394 2730.597412,1638.499512 
	C2728.664062,1635.034912 2726.460449,1638.565308 2725.296875,1641.158936 
	C2720.676270,1651.456299 2718.432861,1652.375366 2707.954834,1648.158203 
	C2704.551758,1646.788452 2700.976318,1645.821167 2697.686279,1644.278931 
	C2691.950928,1641.590942 2687.048340,1639.459839 2682.563232,1646.730957 
	C2680.030029,1650.837402 2675.388672,1649.424194 2671.807861,1647.109985 
	C2666.578369,1643.730591 2661.448242,1644.079712 2655.614990,1645.720093 
	C2646.038818,1648.413330 2636.449951,1651.217407 2626.327637,1651.697632 
	C2620.041016,1651.995728 2613.865967,1653.513062 2608.098877,1656.495239 
	C2601.389404,1659.964722 2595.129639,1658.900879 2589.953369,1653.299561 
	C2586.972900,1650.074463 2583.227051,1647.871338 2579.923340,1645.096436 
	C2574.636230,1640.655884 2569.564209,1640.256226 2563.561768,1644.091797 
	C2557.274902,1648.108887 2549.592041,1648.216553 2542.500244,1649.849243 
	C2538.088867,1650.864746 2534.349854,1649.005615 2534.432617,1643.663086 
	C2534.505615,1638.937988 2532.128418,1637.805420 2528.158936,1638.631836 
	C2513.458252,1641.693237 2499.032227,1638.171143 2484.554199,1636.703369 
	C2477.472900,1635.985596 2470.800781,1635.524292 2464.119141,1638.848877 
	C2458.864258,1641.463135 2452.921631,1639.348999 2448.474121,1640.020386 
	C2451.038818,1640.083130 2454.778320,1639.707520 2458.459961,1640.265381 
	C2461.596436,1640.740723 2464.415283,1642.185181 2464.692383,1645.705200 
	C2464.980957,1649.377319 2461.979492,1650.763672 2459.125977,1651.840698 
	C2455.943115,1653.041870 2452.565186,1653.156860 2449.257568,1652.620850 
	C2441.995605,1651.444092 2434.696045,1651.036133 2427.356445,1651.084229 
	C2420.400635,1651.129761 2415.509766,1648.767700 2412.737061,1641.584961 
	C2409.294922,1632.668701 2406.139404,1632.441772 2399.070068,1639.294556 
	C2388.157227,1649.873047 2381.420654,1651.195923 2367.725586,1644.714600 
	C2359.062012,1640.614380 2350.000488,1639.296753 2340.573242,1639.123657 
	C2333.616699,1638.996094 2327.938477,1639.623901 2324.098145,1647.184570 
	C2320.817139,1653.643433 2310.229980,1654.187012 2301.699219,1648.247925 
	C2295.787598,1644.132202 2291.602051,1643.231934 2286.281006,1648.578491 
	C2281.990723,1652.889282 2276.701416,1652.604980 2271.495605,1649.812622 
	C2267.678955,1647.765625 2263.826660,1645.797852 2259.828369,1644.112305 
	C2248.481934,1639.328857 2236.047119,1642.522949 2229.379883,1652.968506 
	C2225.670166,1658.780151 2220.998291,1661.157715 2214.525879,1661.083740 
	C2205.233887,1660.977661 2195.839600,1662.135864 2186.816895,1658.761353 
	C2183.572510,1657.547974 2180.258789,1658.109985 2176.934082,1658.410400 
	C2165.631348,1659.431885 2154.455566,1659.776733 2143.235352,1656.712036 
	C2135.047119,1654.475098 2126.612549,1656.662109 2118.593018,1658.674683 
	C2105.168701,1662.043823 2092.326172,1663.284790 2080.657471,1653.767700 
	C2077.186035,1650.936157 2072.952148,1651.381958 2069.170166,1652.599976 
	C2060.746826,1655.312866 2052.217041,1655.585938 2043.542236,1654.880005 
	C2034.584595,1654.151001 2025.722534,1653.524414 2017.203735,1657.800049 
	C2013.901489,1659.457642 2009.868286,1658.816895 2006.463135,1657.301392 
	C1997.576050,1653.346191 1988.565430,1653.739868 1979.080444,1654.670532 
	C1962.854248,1656.262817 1946.252686,1656.644653 1930.236328,1655.246216 
	C1918.382812,1654.211426 1906.056763,1651.908081 1894.142822,1649.121826 
	C1891.000244,1648.386963 1887.935791,1646.725342 1884.707153,1648.514771 
	C1884.492676,1649.335327 1884.700928,1649.927612 1885.344604,1650.351807 
	C1890.179565,1653.537476 1898.145142,1655.203491 1896.758789,1662.486328 
	C1895.173950,1670.812134 1886.531982,1676.216553 1879.739380,1675.655273 
	C1863.280518,1674.295288 1848.587524,1681.490723 1832.841675,1682.336060 
	C1827.608032,1682.617188 1823.995850,1684.371216 1821.138550,1688.514526 
	C1814.691772,1697.862427 1805.977783,1700.009521 1795.762329,1695.765869 
	C1790.703979,1693.664795 1787.215942,1694.687012 1783.350952,1698.502075 
	C1777.512573,1704.265015 1771.966431,1710.507935 1762.973145,1712.083374 
	C1757.647705,1713.016479 1758.631470,1715.995728 1762.523071,1718.531982 
	C1766.250000,1720.960938 1771.665405,1721.859497 1771.264160,1728.750977 
	C1770.526489,1730.394897 1769.796753,1731.080078 1768.150391,1731.786133 
	C1764.068359,1732.981079 1760.813965,1730.818359 1756.734375,1731.757935 
	C1755.063232,1731.949707 1754.101196,1731.964111 1752.416260,1731.845093 
	C1749.808838,1731.380981 1747.956665,1730.760376 1745.245483,1730.974243 
	C1737.893799,1731.293335 1731.309326,1731.294556 1724.211670,1728.990723 
	C1722.124023,1728.343140 1720.792114,1727.790039 1718.799805,1727.039917 
	C1717.222412,1726.722290 1716.327026,1726.527710 1714.779053,1726.163818 
	C1712.762085,1725.807739 1711.413940,1725.817017 1709.360596,1726.367676 
	C1701.245483,1727.725464 1693.833862,1727.454346 1685.636719,1726.659302 
	C1672.795410,1722.064575 1661.893555,1727.610352 1650.146362,1731.018311 
	C1643.975220,1739.114258 1643.848389,1748.434692 1643.793213,1756.885254 
	C1643.746216,1764.090942 1642.114990,1770.355469 1639.574707,1777.516602 
	C1630.842529,1782.301270 1625.366089,1791.821899 1613.829346,1790.734131 
	C1611.530029,1788.563965 1610.881958,1786.342896 1609.665039,1783.451660 
	C1607.417969,1769.947388 1609.224121,1757.150757 1608.036621,1743.648560 
	C1606.049438,1737.086182 1600.667236,1737.376099 1596.449463,1737.167480 
	C1587.560303,1736.727905 1578.595215,1736.197632 1568.903320,1737.742554 
	C1567.000122,1737.979614 1565.906006,1737.910522 1564.022827,1737.460815 
	C1551.996216,1733.060181 1540.564941,1734.766846 1528.349365,1736.304932 
	C1520.303955,1737.729248 1513.045288,1737.715576 1505.022095,1736.618164 
	C1496.219727,1732.653198 1488.195923,1729.507935 1480.433350,1737.950439 
	C1480.126221,1739.471680 1479.902710,1740.328247 1479.407959,1741.792969 
	C1476.665405,1745.794434 1476.264648,1749.443726 1477.975830,1753.987915 
	C1478.646729,1756.920410 1478.638672,1759.223877 1478.390747,1762.234619 
	C1478.071045,1764.794189 1477.854980,1766.654419 1478.424561,1769.246460 
	C1478.951416,1774.053345 1479.367920,1778.131348 1478.987793,1783.028809 
	C1478.940674,1784.887695 1478.934814,1785.956299 1478.961182,1787.842651 
	C1479.378174,1792.011841 1479.096313,1795.311646 1477.962891,1799.358398 
	C1477.264404,1801.037964 1476.603149,1801.830322 1476.242920,1802.717407 
	C1471.107422,1815.371704 1471.122437,1815.375610 1456.934448,1813.300781 
	C1455.283936,1813.059448 1453.654175,1813.195068 1451.280640,1813.933350 
	C1447.825195,1814.808105 1445.141846,1814.815430 1441.693359,1813.986206 
	C1437.936401,1812.427490 1436.503906,1809.327026 1433.341064,1807.010986 
	C1431.801025,1805.206665 1431.003540,1803.851440 1430.064453,1801.683594 
	C1427.462646,1787.371094 1427.380859,1787.175171 1413.772339,1785.719849 
	C1405.856567,1784.873291 1397.896484,1784.960571 1389.214355,1785.899658 
	C1386.390625,1786.645142 1384.283325,1786.632446 1381.378784,1786.349854 
	C1378.076294,1785.517334 1375.570312,1784.758545 1372.249268,1784.051270 
	C1364.598877,1778.446045 1368.053833,1770.364136 1366.083130,1762.947754 
	C1365.952637,1761.191040 1366.003052,1760.193481 1366.271973,1758.480835 
	C1367.681763,1738.883911 1370.568726,1719.969482 1364.441650,1700.916504 
	C1362.959106,1700.057251 1362.186157,1699.444458 1361.007568,1698.151733 
	C1359.644897,1687.885620 1353.888184,1684.911987 1344.749023,1684.376099 
	C1338.155884,1683.989746 1330.904907,1683.302124 1325.932129,1676.833496 
z"
        />
        <path
          fill="#010611"
          opacity="1.000000"
          stroke="none"
          d="
M214.434265,4033.335938 
	C171.958206,4034.000000 129.916428,4034.000000 86.937317,4034.000000 
	C81.933128,4029.573242 82.358528,4024.166504 83.213768,4018.960449 
	C88.083740,3989.312744 90.120384,3959.244141 96.478180,3929.823975 
	C97.076195,3927.056885 96.648689,3924.605713 95.016151,3921.394775 
	C94.064110,3918.880127 94.018707,3917.142090 94.873138,3914.598877 
	C96.466774,3912.477783 98.030258,3911.812988 100.558517,3911.356445 
	C117.912964,3911.299805 134.058990,3915.243164 150.518463,3916.233887 
	C159.533371,3916.776611 168.447922,3917.694824 176.706421,3912.958008 
	C180.155441,3910.979248 184.086105,3909.034668 183.359940,3904.100098 
	C182.641678,3899.219482 180.247803,3894.889160 175.085510,3893.989990 
	C157.769852,3890.974121 140.236145,3889.556641 122.785744,3887.537109 
	C119.149300,3887.116455 116.459633,3888.839600 115.012123,3892.422852 
	C113.886261,3895.209961 112.426117,3897.844482 108.504013,3899.055420 
	C107.161720,3899.000732 106.627907,3898.854736 105.502541,3898.108887 
	C101.601456,3884.118652 106.412521,3871.320801 108.143547,3857.500000 
	C108.873421,3856.301758 109.233887,3855.874512 110.326828,3855.069336 
	C111.714813,3854.544922 112.609367,3854.172852 112.999321,3854.489990 
	C124.718620,3864.015625 136.605408,3858.546875 148.957123,3855.820557 
	C158.242691,3853.770996 168.083389,3853.211426 177.780121,3854.375488 
	C182.424164,3854.933105 187.093979,3854.996826 191.753174,3854.546631 
	C193.384949,3854.388916 195.335342,3854.370361 196.136932,3852.687744 
	C197.223480,3850.407227 195.285095,3849.003174 193.950607,3847.658691 
	C189.615662,3843.291504 184.865829,3839.987549 178.155029,3840.530518 
	C171.136826,3841.098633 164.248093,3839.232178 157.272995,3838.730957 
	C153.839340,3838.483887 150.578949,3837.226562 149.352005,3833.604248 
	C147.941940,3829.441650 150.633560,3826.697266 153.326721,3824.105469 
	C154.045670,3823.413818 154.810364,3822.616455 155.738541,3822.350830 
	C170.068115,3818.252686 184.839874,3812.014160 199.445770,3817.048584 
	C212.845886,3821.667480 223.933151,3818.592285 236.148788,3813.705811 
	C232.581696,3809.638916 228.320099,3807.596924 224.595398,3804.909668 
	C219.575058,3801.287598 214.534927,3797.953613 216.586060,3790.497070 
	C218.552979,3783.346436 223.256866,3778.623047 230.436783,3777.743652 
	C239.470245,3776.637207 247.966736,3773.797363 256.563080,3771.238770 
	C259.205078,3770.452637 264.683624,3771.530273 262.893799,3766.119385 
	C261.675476,3762.436279 258.704773,3759.111572 253.643875,3760.659424 
	C250.460617,3761.633057 247.311310,3762.840820 243.893433,3762.523193 
	C241.192505,3762.272217 238.399948,3761.874268 237.361252,3758.868164 
	C236.340897,3755.915283 237.893204,3753.470947 240.122177,3751.937988 
	C248.189041,3746.389893 257.411560,3745.390137 266.849152,3746.100586 
	C270.165344,3746.350098 271.792633,3749.230713 272.995728,3752.081299 
	C276.696442,3760.848877 283.784027,3763.190186 292.536133,3762.522705 
	C316.070312,3760.727295 339.741608,3760.089111 362.535095,3752.750977 
	C367.634888,3751.108887 372.757233,3749.710449 377.297424,3746.821289 
	C388.074585,3739.964355 399.798920,3735.309570 411.944550,3731.553711 
	C425.266571,3727.434814 439.008087,3724.779785 451.646118,3718.323975 
	C462.250641,3712.906494 474.546509,3715.199463 485.946655,3712.416748 
	C501.894409,3708.523926 517.865479,3705.663818 529.380066,3692.432373 
	C531.072510,3690.487305 534.105652,3690.206787 536.073181,3687.909912 
	C531.314636,3687.514648 527.689331,3689.860107 524.008240,3691.790527 
	C519.390503,3694.211670 514.563599,3695.470703 509.543823,3693.956299 
	C495.221008,3689.634277 482.505707,3694.582275 470.359863,3701.067139 
	C460.688385,3706.230957 450.700592,3710.070557 439.848389,3708.987793 
	C418.630402,3706.871094 400.730652,3714.873047 385.891479,3728.248779 
	C365.678314,3746.468750 341.452789,3745.822998 317.271393,3746.154297 
	C309.950348,3746.254150 302.716614,3745.317383 295.570648,3747.608398 
	C293.027283,3748.423828 290.363922,3748.776855 287.671021,3748.574219 
	C284.192444,3748.312988 281.021912,3747.331787 279.420654,3743.900146 
	C277.806488,3740.440430 279.264954,3737.384766 281.237732,3734.582031 
	C283.620941,3731.197266 287.002594,3729.047119 290.815613,3727.646729 
	C294.254547,3726.384033 297.779510,3725.360107 301.432678,3722.680420 
	C315.551147,3717.179443 329.794495,3718.312256 344.494202,3716.797119 
	C356.704773,3718.673096 365.861786,3715.186035 375.678497,3708.534180 
	C395.139709,3695.346436 416.123444,3684.460938 440.836670,3684.776855 
	C446.934418,3684.854980 453.013794,3683.598389 458.024536,3680.405273 
	C472.515411,3671.170410 489.394531,3670.758789 505.992920,3666.410645 
	C507.671967,3665.833740 508.652252,3665.597900 510.379578,3665.319824 
	C513.682922,3665.248047 515.736511,3663.934814 518.488159,3662.333252 
	C522.680481,3660.847656 526.261658,3660.959473 530.615723,3661.477539 
	C542.910706,3662.054688 554.255371,3662.280518 561.195679,3650.280762 
	C564.350647,3644.825684 569.835022,3643.343262 575.721680,3644.053711 
	C580.585632,3644.640625 584.829834,3643.689453 589.427490,3640.499512 
	C592.130920,3639.393799 594.209167,3639.044678 597.142090,3639.043457 
	C605.166687,3639.421875 611.429688,3637.741211 617.264893,3632.818848 
	C624.239075,3626.935547 632.909790,3624.246338 641.978760,3622.784424 
	C652.090759,3621.154785 652.319458,3620.462646 648.442749,3610.984375 
	C645.780823,3604.476074 648.120972,3600.884766 655.028259,3602.384766 
	C670.095276,3605.657227 685.385315,3606.065186 700.632202,3607.357178 
	C718.938293,3608.908447 734.242981,3617.495605 747.949646,3629.237061 
	C752.196777,3632.875488 755.466858,3637.187988 753.393555,3643.155518 
	C751.354858,3649.023438 746.282715,3650.572510 740.648254,3651.053711 
	C725.723938,3652.328125 710.716736,3650.980713 695.799072,3652.534180 
	C693.120972,3652.812988 690.411865,3652.189209 688.000122,3651.056885 
	C673.732849,3644.358154 659.225708,3647.968018 644.770264,3649.960938 
	C642.553833,3650.266602 639.674316,3650.491943 639.096741,3653.209473 
	C638.637451,3655.370361 640.930237,3656.495850 642.384399,3657.804688 
	C644.567749,3659.769043 647.790405,3661.512939 646.361206,3665.052734 
	C644.862610,3668.763672 641.052979,3668.463379 637.742493,3668.670410 
	C633.304932,3668.947754 626.046814,3666.812500 625.713257,3672.138672 
	C625.324524,3678.345703 633.222351,3676.704346 637.630188,3677.862793 
	C642.767334,3679.213623 648.091797,3679.877686 653.161621,3681.593750 
	C673.697693,3688.545654 693.862000,3686.574951 713.697266,3678.832764 
	C726.556152,3673.813721 740.104736,3673.046631 753.074951,3675.799316 
	C765.688904,3678.476318 778.129028,3678.721680 791.518555,3678.785400 
	C798.315613,3678.508301 803.800171,3676.616943 809.358154,3672.655273 
	C810.475342,3671.992676 810.947632,3671.760742 812.176941,3671.280273 
	C820.457642,3664.276123 829.262573,3662.019531 839.339417,3661.750488 
	C847.227905,3661.539795 852.583191,3663.660156 854.541748,3671.243164 
	C856.132996,3677.404053 859.758545,3678.118896 864.972839,3676.950195 
	C878.526550,3673.912842 891.864807,3674.830322 905.429443,3677.842041 
	C919.434937,3680.951660 933.109253,3677.315918 946.628784,3673.344971 
	C966.345154,3667.553955 985.868408,3661.258057 1007.071167,3662.631592 
	C1014.738831,3663.128174 1022.369019,3658.688477 1029.894043,3656.060059 
	C1045.988281,3650.438721 1061.879395,3651.844727 1077.518433,3657.978271 
	C1080.027710,3658.962402 1081.996948,3660.824951 1084.135498,3663.640137 
	C1085.126953,3665.291748 1085.424805,3666.332764 1085.448975,3668.234375 
	C1084.718018,3671.613770 1082.678345,3673.459717 1081.246460,3676.498047 
	C1076.905762,3680.921875 1072.180786,3679.383057 1066.917725,3679.173828 
	C1057.475952,3680.229248 1049.775146,3683.217773 1043.281616,3689.461426 
	C1038.237061,3694.312012 1031.748047,3695.829102 1024.925171,3694.163330 
	C1019.771912,3692.905029 1014.547180,3692.677979 1008.569336,3692.122803 
	C1006.760803,3692.119873 1005.737671,3691.981201 1003.984253,3691.532715 
	C995.201111,3689.409912 987.364624,3690.762695 979.937744,3693.688965 
	C973.897156,3696.069336 967.839233,3697.118652 960.666992,3696.908203 
	C958.289917,3697.024902 956.702637,3697.016113 954.330688,3696.646729 
	C950.235535,3695.915771 947.213196,3694.465332 944.076477,3693.352051 
	C942.187622,3692.681885 939.575806,3691.408691 938.356628,3692.756592 
	C932.054321,3699.725342 921.223511,3700.894531 916.454895,3709.330078 
	C912.521301,3716.288818 907.040955,3718.902588 899.451111,3719.463379 
	C891.830444,3720.026855 884.183960,3720.833496 876.650940,3722.225098 
	C874.514771,3722.620117 871.549683,3723.281982 871.817383,3726.294922 
	C872.067993,3729.114258 874.917297,3729.738281 877.157104,3729.806641 
	C885.944519,3730.075195 895.491089,3729.815430 901.785339,3723.637207 
	C912.272644,3713.342285 923.995178,3715.280273 935.235107,3718.922852 
	C944.499451,3721.924805 952.235474,3720.949219 961.250244,3718.385742 
	C983.641541,3712.018066 1006.366760,3712.606201 1029.209351,3717.492188 
	C1043.439209,3720.535889 1058.238159,3720.706787 1070.616821,3730.225098 
	C1075.913574,3734.298096 1077.120728,3737.332764 1075.319824,3744.249023 
	C1072.339844,3755.692383 1066.662476,3760.685547 1054.978149,3759.318359 
	C1050.032593,3758.739502 1047.881470,3760.270752 1049.376953,3766.254150 
	C1051.608643,3775.182617 1049.571045,3777.360596 1040.595703,3779.248535 
	C1029.730835,3781.533447 1018.716858,3780.894043 1007.736694,3781.288330 
	C985.244263,3782.095947 963.610901,3785.876709 944.793396,3799.778320 
	C937.141357,3805.431396 927.756592,3807.835449 918.365295,3807.710205 
	C904.591736,3807.526855 891.090088,3807.979736 878.204285,3813.467285 
	C873.276184,3815.565918 867.661621,3813.021240 861.462097,3815.549072 
	C866.515808,3819.347900 870.469604,3822.734131 874.376343,3826.175293 
	C877.167175,3828.633545 880.019592,3831.325928 878.691956,3835.521240 
	C877.330872,3839.823242 873.617737,3841.829834 869.509949,3842.203613 
	C861.560303,3842.926758 853.575012,3842.992920 845.570129,3842.549316 
	C808.342407,3840.485840 771.390503,3839.894043 734.264893,3846.990723 
	C707.888245,3852.032959 680.440979,3853.191650 654.937561,3840.566406 
	C641.633606,3833.980957 627.714539,3832.095215 613.171326,3833.443604 
	C611.360779,3833.611572 609.032043,3833.292236 608.165100,3836.639893 
	C613.908936,3840.072754 620.791931,3839.415771 627.006897,3841.099365 
	C633.625488,3842.892822 639.722778,3845.233887 643.508667,3851.374756 
	C647.866699,3858.443604 645.895752,3864.136230 638.104187,3867.204590 
	C636.240173,3867.938965 634.317505,3868.785400 632.319214,3868.651123 
	C616.896484,3867.614990 605.782043,3878.494629 592.419495,3883.145752 
	C588.415588,3884.539551 585.789551,3888.482910 583.522400,3892.316895 
	C575.230164,3906.340088 562.654846,3911.124023 547.224854,3907.668701 
	C528.120483,3903.391113 508.985687,3902.963379 490.400574,3910.682861 
	C488.268890,3911.568359 484.989410,3911.894531 484.727600,3914.248779 
	C483.491638,3925.363281 473.711090,3931.441162 469.637543,3940.819824 
	C467.191772,3946.450928 462.845856,3949.385742 456.747925,3950.636719 
	C448.521027,3952.323975 440.218140,3952.992188 431.917389,3953.017334 
	C423.500732,3953.043213 416.560364,3955.421875 410.029694,3960.952148 
	C400.689819,3968.860352 388.477203,3969.197754 378.407257,3962.987793 
	C365.121552,3954.794189 356.499115,3954.754150 345.464783,3965.638184 
	C336.012024,3974.962646 325.504852,3980.413818 312.608765,3983.061768 
	C301.832306,3985.274658 294.356659,3992.281250 292.093414,4003.767334 
	C290.446747,4012.123779 285.111847,4017.293213 277.172943,4019.656982 
	C263.484131,4023.732422 249.687851,4025.009277 235.940933,4019.852783 
	C229.955765,4017.607910 223.770187,4016.383057 217.451492,4015.770996 
	C214.081497,4015.444580 210.248260,4015.319336 208.306076,4018.426270 
	C205.934357,4022.220947 209.658752,4024.349854 211.876053,4026.672363 
	C213.503006,4028.376465 215.179489,4030.017578 214.434265,4033.335938 
z"
        />
        <path
          fill="#6091CA"
          opacity="1.000000"
          stroke="none"
          d="
M701.177673,559.999634 
	C700.909119,565.148010 705.929749,567.967224 708.225037,573.481934 
	C696.671387,574.087158 686.512573,575.022400 676.473572,572.982056 
	C672.123535,572.098022 669.036560,572.559570 665.474365,575.262146 
	C659.874573,579.510681 651.521790,579.365417 646.178223,574.981018 
	C642.026428,571.574463 636.591370,570.239258 632.957397,566.119751 
	C631.824402,564.835388 630.200256,564.245605 628.423157,564.914978 
	C623.716797,566.687988 619.096741,567.567749 613.922302,566.334167 
	C610.539490,565.527832 609.113831,568.514893 607.887268,571.636719 
	C605.780334,576.999146 601.127014,577.744995 598.715454,572.690674 
	C593.182617,561.094543 583.709290,563.596375 574.625916,564.954834 
	C570.789185,565.528625 567.060730,567.497375 563.536011,569.320923 
	C558.886047,571.726685 554.187500,572.821045 549.110107,571.359192 
	C547.123657,570.787231 545.316223,570.731689 543.274658,571.238220 
	C535.923767,573.062256 531.319885,569.265442 527.703125,563.397034 
	C526.081360,560.765564 526.483276,556.555725 521.941895,556.083069 
	C516.239380,555.489563 511.352905,557.446289 507.242126,561.045105 
	C501.506653,566.066223 501.457306,566.352661 497.678070,560.670654 
	C496.061340,558.239929 494.038635,558.220520 491.729828,558.007568 
	C486.590179,557.533447 481.825439,559.397339 477.100586,560.745789 
	C468.038818,563.331787 460.103333,560.975708 452.478485,556.455566 
	C451.077515,555.625000 449.968201,554.406433 448.218140,554.118347 
	C443.415100,553.327637 441.110352,555.271484 441.280426,560.490662 
	C441.334351,562.145386 442.093689,563.868591 440.477203,565.026550 
	C438.939819,566.127869 437.216736,565.720215 435.955322,564.461121 
	C430.124512,558.641296 422.683411,556.083008 415.044617,553.831421 
	C411.501678,552.787109 398.215851,552.289917 395.062775,553.303284 
	C390.942566,554.627502 387.465790,553.683716 383.824036,551.255493 
	C378.513580,547.714600 373.503448,543.582214 365.871521,545.721497 
	C361.681305,546.896118 357.299469,546.423950 355.132721,551.242676 
	C354.755768,552.080994 353.847809,553.052368 353.013367,553.254944 
	C345.459595,555.088684 339.804352,560.236816 333.486206,564.222534 
	C329.565887,566.695618 326.537750,564.428467 323.494354,562.324402 
	C315.278564,556.644470 314.023712,556.439453 306.544189,563.254089 
	C303.580566,565.954224 300.696899,566.533691 297.511536,565.406433 
	C292.945038,563.790527 288.330231,564.116150 283.689758,563.985840 
	C281.034210,563.911255 278.067688,564.419495 275.783081,563.422913 
	C265.247589,558.827454 254.203400,560.202332 243.279007,560.001404 
	C237.642288,559.897827 233.232254,561.613708 229.550659,566.444031 
	C224.845734,572.617004 218.507812,575.572571 210.268799,573.176880 
	C200.013550,570.194946 190.618011,572.440552 182.082443,578.867859 
	C176.096176,583.375549 174.069687,583.396790 170.216843,577.328918 
	C167.188461,572.559448 163.180984,573.593750 159.726898,574.611694 
	C153.081467,576.570190 147.736191,576.842102 143.066040,570.446045 
	C140.302414,566.661133 136.436218,567.628845 134.237152,571.725220 
	C131.480835,576.859741 127.017860,577.264221 121.854118,578.292175 
	C112.114227,580.231140 102.593269,583.171692 92.514809,581.956543 
	C85.608978,581.123962 78.811707,579.493164 71.752472,579.947510 
	C66.462601,580.288025 61.214443,579.956543 55.940094,581.485596 
	C49.567421,583.333130 43.535660,580.378235 37.523739,578.596497 
	C34.549252,577.714905 34.296604,574.661072 33.030941,572.379822 
	C30.490160,567.800293 26.067154,565.971741 22.436796,569.381226 
	C16.739573,574.731812 10.310752,573.912842 2.873800,574.000000 
	C2.000000,476.624878 2.000000,379.249756 2.000000,280.937317 
	C10.503148,279.745361 17.112350,275.946136 21.350159,268.210754 
	C26.167053,272.799377 25.404114,278.221161 26.789562,282.722900 
	C30.997377,282.210938 29.687181,279.283783 29.987776,277.279510 
	C31.268112,268.742523 33.286629,266.093231 41.532902,264.605316 
	C48.070839,263.425598 54.829304,262.524719 59.906342,268.810608 
	C62.507545,272.031158 65.931366,272.312286 69.870987,271.138092 
	C76.369934,269.201080 83.004807,269.588745 89.612259,270.866547 
	C94.977287,271.904083 99.377106,273.599609 101.969475,279.373138 
	C104.419205,284.829010 106.871727,285.348450 112.354195,281.538147 
	C117.195412,278.173523 122.150452,277.999969 127.496590,277.958557 
	C131.037964,277.931152 134.389267,278.528992 136.388931,281.511017 
	C143.763779,292.509003 154.992798,291.233185 165.255676,289.452698 
	C179.198349,287.033783 192.787201,286.855957 206.557877,289.689484 
	C212.111771,290.832306 217.180054,289.466095 221.366302,285.545685 
	C225.862610,281.334900 230.266922,278.609497 235.998215,283.864014 
	C238.909409,286.533020 242.262512,285.290039 245.180573,283.283966 
	C247.666840,281.574707 250.063400,279.992828 253.327026,279.978882 
	C262.580383,279.939392 271.915283,280.882538 280.706909,276.387970 
	C283.301208,275.061676 286.784882,275.766113 289.032684,278.226257 
	C291.664398,281.106628 295.492523,281.553986 298.548981,283.429871 
	C303.557098,286.503540 307.966522,285.890472 312.363922,282.511719 
	C313.967651,281.279480 315.668671,279.948486 317.740173,280.155914 
	C327.136047,281.096802 336.162354,277.677216 345.503052,277.975677 
	C353.812378,278.241241 353.780823,278.154877 356.399658,286.128845 
	C356.906464,287.672089 357.801605,289.087860 358.758514,291.058563 
	C363.369080,289.725677 366.734558,286.389374 371.147797,285.129730 
	C375.413940,283.912079 378.434937,284.566925 380.666626,288.023468 
	C383.180908,291.917694 386.021393,295.588470 387.816803,299.991638 
	C390.963165,307.708069 394.818817,309.260071 401.465271,304.891998 
	C405.020874,302.555267 408.935394,302.290253 412.575378,300.796967 
	C414.561249,299.982269 416.864075,299.885529 418.152283,301.562195 
	C422.801147,307.612976 427.679504,304.791779 432.113434,301.832275 
	C438.202454,297.768097 443.451111,297.965790 449.277893,302.491455 
	C454.523499,306.565796 455.257172,310.694977 450.504181,315.443146 
	C448.754791,317.190765 445.227478,317.120605 445.122223,320.789948 
	C448.839264,322.960876 451.542328,320.309845 454.093719,318.573242 
	C457.067841,316.548889 460.010437,315.190063 463.361969,316.836090 
	C467.525604,318.880981 470.181030,316.286743 473.342377,314.443176 
	C477.354767,312.103363 478.427948,310.021973 475.106201,305.889984 
	C471.585663,301.510681 472.652618,297.736511 477.694153,297.558899 
	C484.549255,297.317322 492.390656,295.650024 497.854065,303.285400 
	C498.004913,304.009766 498.002533,305.003662 498.003113,305.500610 
	C495.552795,308.349762 494.124725,311.174347 494.016113,315.309235 
	C493.798553,318.494293 492.940735,320.878357 490.387543,321.453949 
	C484.343964,322.816376 484.101837,327.098633 483.897369,332.105408 
	C483.578674,339.908936 485.819550,347.971313 480.844025,355.409515 
	C478.752106,358.536835 482.183746,362.316193 482.083679,366.041870 
	C481.903381,372.756226 481.746307,373.474396 475.138336,372.528015 
	C471.050171,371.942535 468.848877,373.995117 466.637665,376.478485 
	C462.999969,380.563965 463.775818,384.033356 469.120209,385.211273 
	C476.298004,386.793335 483.673859,386.399170 490.945648,385.325836 
	C504.538177,383.319519 517.659912,383.122467 531.122620,388.056885 
	C539.776428,391.228760 549.506287,393.105560 559.316162,390.694977 
	C565.388794,389.202759 571.861267,390.031769 578.008606,388.190247 
	C581.530945,387.135101 584.318115,389.510223 587.065430,391.452820 
	C596.676270,398.248535 607.011230,401.631226 618.850342,398.269287 
	C620.375305,397.836212 622.807983,397.580139 623.698975,398.424805 
	C629.438904,403.866364 637.031372,404.412781 643.929810,406.859894 
	C647.019348,407.955902 649.747803,410.086914 653.243713,410.009186 
	C656.130066,409.945038 658.169250,411.482513 660.288269,413.239471 
	C666.071594,418.034607 669.028625,418.440674 674.933838,413.774658 
	C680.239990,409.582001 685.967285,408.099579 692.471497,407.943848 
	C698.888611,407.790161 705.016541,406.106812 711.576904,409.528625 
	C715.695496,411.676849 721.550171,410.624481 725.072021,405.898163 
	C728.198120,401.703003 731.704407,402.414001 735.530640,404.738647 
	C742.785828,409.146576 749.976440,409.444885 756.915833,404.061188 
	C759.893494,401.751038 762.964233,401.756805 766.305237,403.108856 
	C770.617554,404.853973 774.968750,406.507965 779.337769,408.106415 
	C781.702209,408.971436 783.751099,410.216888 783.439392,412.990753 
	C783.079834,416.189911 780.207397,415.466248 778.036865,416.042786 
	C765.504517,419.371948 753.020508,414.964569 740.512268,415.965729 
	C737.531982,416.204254 734.515625,416.085052 731.519165,416.005096 
	C727.412415,415.895477 724.736572,417.878143 722.551697,421.234711 
	C717.802612,428.530518 714.942932,429.386414 707.014648,426.465820 
	C704.840515,425.664917 702.706665,424.596588 700.457458,424.199310 
	C697.819214,423.733307 695.016724,423.868439 693.243774,426.426758 
	C691.398132,429.090057 692.609558,431.532776 694.194580,433.782806 
	C696.097290,436.483887 699.069946,436.613831 701.300781,435.088104 
	C707.393127,430.921448 713.585327,434.243164 719.671936,434.411957 
	C722.537903,434.491425 725.370789,436.197510 727.647949,439.370148 
	C723.820068,446.684692 723.446228,454.423523 721.955444,461.851288 
	C720.545654,468.875244 719.985657,469.158783 713.299561,466.985718 
	C711.446716,466.383545 709.399719,465.964600 707.476074,466.072723 
	C701.718750,466.396210 696.220947,465.464508 690.905823,463.339539 
	C687.927795,462.148956 684.898254,461.430969 683.043457,464.809967 
	C681.350952,467.893433 681.452515,471.268158 683.595825,474.229736 
	C685.456909,476.801422 688.241821,479.136444 691.175476,477.103760 
	C698.187866,472.244965 705.246399,475.128784 712.343506,476.013031 
	C716.302002,476.506226 724.707214,484.127869 725.555725,487.913422 
	C726.341064,491.417664 723.192688,494.534546 719.491638,493.422943 
	C716.018982,492.379944 712.961548,490.371460 709.964600,488.166290 
	C701.750732,482.122559 692.801636,483.482300 684.072998,486.806854 
	C677.889282,489.162079 671.947083,492.162842 665.942749,494.972870 
	C662.584595,496.544434 659.519409,495.452637 656.291748,494.501984 
	C654.078064,493.850006 651.273315,493.542175 650.708557,496.698395 
	C650.232300,499.359741 652.702698,499.417694 654.626648,500.011902 
	C661.746948,502.210999 661.927917,502.789978 659.152405,510.306396 
	C658.699158,511.533813 657.900513,512.956299 658.165466,514.074463 
	C660.042908,521.998352 657.479614,529.839050 657.949036,537.734985 
	C658.421753,545.686523 658.987549,546.877197 666.970215,547.854492 
	C671.046204,548.353455 673.673279,548.839661 674.110229,553.907715 
	C674.895203,563.013428 680.052673,566.067261 689.013184,563.243225 
	C692.775635,562.057556 696.138672,559.547607 701.177673,559.999634 
z"
        />
        <path
          fill="#79A9DA"
          opacity="1.000000"
          stroke="none"
          d="
M2.000000,792.937317 
	C6.886499,792.017517 11.810378,792.337402 17.223421,790.103027 
	C21.113609,791.830139 23.830036,794.117737 27.925135,794.083374 
	C41.951443,793.965576 55.314777,798.617432 69.109512,800.301819 
	C70.626091,800.487000 72.277519,801.485718 73.404205,802.582092 
	C76.618347,805.710022 79.971062,806.387817 84.323868,804.991638 
	C90.874313,802.890442 96.797028,804.275024 101.198174,809.961121 
	C103.689598,813.180054 106.949409,814.212646 110.861877,814.014465 
	C116.480225,813.729675 122.031349,814.058350 127.683929,812.616699 
	C138.453812,809.870056 148.698761,813.856323 158.908798,816.677246 
	C163.705551,818.002625 168.423462,819.710632 173.031799,821.599609 
	C181.434250,825.044067 189.805099,825.242920 198.196228,821.863953 
	C200.698792,820.856262 203.130829,820.003418 205.875275,820.000549 
	C209.895950,819.996338 212.563980,821.027954 214.054474,825.492493 
	C217.086349,834.573853 218.909164,834.264404 227.742249,831.840332 
	C236.528366,829.429077 245.127075,825.606689 254.628571,825.939392 
	C262.945740,826.230591 271.281921,825.931396 279.608154,826.026794 
	C284.314117,826.080688 288.667755,825.194702 293.204010,823.671387 
	C301.132080,821.009094 309.518555,820.296021 317.328491,824.342896 
	C320.865906,826.175903 324.391632,825.847229 328.005371,825.981018 
	C330.875916,826.087219 332.581207,825.032471 334.288849,822.313599 
	C339.854126,813.452454 340.082550,813.610718 348.672729,818.298096 
	C353.587646,820.979980 357.734589,824.788269 362.141693,827.951477 
	C369.921997,833.535828 378.112610,839.302429 388.765106,840.260742 
	C402.572113,841.502869 406.380249,848.867554 406.029785,856.858765 
	C405.766174,862.869385 404.622406,864.606018 398.896545,865.785034 
	C396.956818,866.184387 394.891541,865.988464 392.961304,866.415649 
	C390.563171,866.946350 390.018280,868.854187 390.404785,871.038879 
	C390.758759,873.039795 392.384003,874.141602 394.011230,873.441101 
	C400.536194,870.632629 403.632324,874.214783 406.831360,879.163574 
	C410.917023,885.483948 417.417694,887.411804 424.377899,885.392395 
	C431.657806,883.280334 438.930328,884.445618 446.194458,884.009338 
	C449.395721,883.817200 451.746918,885.376343 454.309723,887.149231 
	C459.759064,890.918823 465.561005,894.179932 471.231293,897.628296 
	C474.345947,899.522461 476.917664,899.604004 480.205536,897.044678 
	C487.179077,891.616394 495.463440,893.438599 503.104828,895.079346 
	C508.582642,896.255432 507.474762,902.570801 509.175171,907.453857 
	C515.170166,902.183228 520.598694,898.876587 527.066284,906.455627 
	C529.601746,909.426758 534.546875,908.983826 537.944763,905.884827 
	C542.277893,901.932800 546.090393,901.201599 550.939880,905.593323 
	C555.897827,910.083374 562.464661,908.350830 568.175598,907.372681 
	C573.179199,906.515686 577.882690,905.329956 582.809387,907.295593 
	C586.189087,908.643982 588.281799,905.884216 590.947876,904.730652 
	C597.139526,902.051392 603.168884,897.274902 610.383240,902.761414 
	C612.799561,904.599060 614.677551,902.181396 616.359314,900.655396 
	C620.272949,897.104187 624.100098,897.229553 627.816467,900.856689 
	C631.813171,904.757446 635.221375,904.820435 638.228394,899.682495 
	C640.804565,895.280701 644.631226,895.341919 648.599609,897.398865 
	C652.315918,899.325195 655.790955,900.121521 660.004517,898.493774 
	C663.049622,897.317383 666.853333,897.245117 669.732788,899.077209 
	C675.390076,902.676697 682.102417,901.276978 688.000183,903.559265 
	C690.656372,904.587219 693.145508,903.177490 695.658142,902.203552 
	C702.981140,899.365417 703.146240,899.342102 707.857971,905.906250 
	C709.857361,908.691589 712.007019,910.992737 715.095764,912.537964 
	C718.606812,914.294434 721.585876,914.178833 723.634583,910.543518 
	C726.197266,905.996216 729.733887,905.983154 734.777710,905.214844 
	C748.213867,903.168152 761.159241,907.569153 774.357605,906.081238 
	C779.310120,905.522888 783.270935,906.215698 786.927124,911.532166 
	C790.085876,916.125366 797.448669,917.217529 803.368408,915.559692 
	C810.310852,913.615417 817.226074,914.241272 825.086853,914.003052 
	C829.190613,915.976868 831.743103,918.523376 827.900452,921.735962 
	C824.997009,924.163208 823.998047,926.832520 823.874146,930.342590 
	C823.742981,934.057678 821.043396,936.311218 818.380005,938.283691 
	C815.259094,940.594910 813.177734,940.109436 811.977600,935.843079 
	C810.298889,929.875793 810.012695,929.963379 804.822754,933.174988 
	C803.694336,933.873352 802.451843,935.189270 801.390625,935.070557 
	C790.473022,933.848267 782.115784,943.467651 771.189209,942.065002 
	C768.116516,941.670532 766.613159,943.814819 764.866943,946.031738 
	C761.354919,950.490540 760.096375,950.109375 758.085449,944.516052 
	C757.639954,943.276917 757.642456,941.837646 757.042542,940.699219 
	C751.440063,930.069397 739.476379,928.625366 733.095886,939.116760 
	C728.730713,946.294434 723.525696,946.004639 717.249023,945.994629 
	C713.951477,945.989380 711.558716,944.778076 709.892334,941.821655 
	C707.901367,938.289490 705.162598,934.927795 701.688660,933.249023 
	C695.444275,930.231506 691.697021,925.855530 691.133362,918.510986 
	C684.290405,920.114746 678.758789,924.849060 671.687622,922.632263 
	C670.314697,922.201904 667.496582,923.737671 666.523010,925.168213 
	C663.502747,929.605713 658.811768,929.623169 654.435547,930.953979 
	C647.662537,933.013672 642.361816,931.901978 637.358704,926.559631 
	C632.093079,920.936768 631.754272,921.283508 626.242737,927.183899 
	C625.366150,928.122375 624.353943,928.985596 623.269592,929.669983 
	C617.250000,933.469543 616.078125,937.766235 621.112000,942.686279 
	C624.555908,946.052307 626.013306,949.298340 626.190613,953.913635 
	C626.384766,958.966125 629.661011,961.677246 634.684326,961.915283 
	C638.675293,962.104309 642.680664,961.971863 646.678711,962.035156 
	C651.369385,962.109375 655.627258,963.659851 656.958923,968.401917 
	C659.082642,975.964478 664.258728,977.836243 671.240173,978.093872 
	C677.770264,978.334778 677.758850,978.655273 676.602783,985.119751 
	C676.427429,986.100281 676.249451,987.080444 676.068359,988.059937 
	C674.512695,996.475830 671.177002,999.562622 663.252441,997.642151 
	C655.621216,995.792786 648.064941,996.220581 640.447205,995.999512 
	C634.434265,995.824951 629.111328,996.427917 623.663208,1000.382935 
	C619.103882,1003.692749 612.558289,1004.588074 606.731750,1005.743469 
	C600.458801,1006.987427 597.747559,1004.187744 595.926819,996.027588 
	C594.326904,988.857239 589.865662,986.011902 583.854736,988.507996 
	C580.021118,990.100037 578.871948,993.680054 578.155701,997.332275 
	C576.020081,1008.221619 575.972473,1007.897339 565.035583,1010.769897 
	C559.374573,1012.256714 553.806152,1011.742371 548.187561,1011.977966 
	C545.193481,1012.103577 542.183838,1011.886414 539.192627,1012.042480 
	C536.708801,1012.172119 532.844604,1011.199280 532.798096,1014.847717 
	C532.744141,1019.071594 536.826416,1019.695862 540.171997,1019.743896 
	C543.804810,1019.796021 547.147400,1017.490173 550.929993,1018.002563 
	C553.133057,1018.300964 555.994690,1017.272705 557.098999,1020.017151 
	C558.452332,1023.380554 555.161865,1023.446411 553.336975,1024.593628 
	C550.819641,1026.176270 548.637573,1028.428589 545.963379,1029.594971 
	C541.382263,1031.593018 536.928467,1033.518311 533.198486,1027.146606 
	C530.423828,1022.406921 524.119873,1021.787170 519.316895,1019.997009 
	C513.505371,1017.830872 509.663879,1012.207520 504.171387,1011.074585 
	C499.046753,1010.017517 495.281952,1017.393311 488.821533,1016.391968 
	C486.029144,1015.959106 482.863312,1017.867859 481.122589,1020.418396 
	C477.748322,1025.362549 474.559692,1023.956848 471.510498,1020.424438 
	C468.723663,1017.195862 465.021301,1015.923706 461.199005,1014.723877 
	C458.530029,1013.885925 455.879150,1013.631348 453.766998,1015.861755 
	C451.424316,1018.335327 451.520569,1021.498779 452.606323,1024.308838 
	C453.791016,1027.375122 456.108826,1030.008301 457.256348,1033.082764 
	C459.038696,1037.858154 457.273254,1039.236938 452.115509,1039.137207 
	C443.381165,1038.968262 434.593018,1039.001831 425.904419,1039.794312 
	C417.685272,1040.543945 409.375885,1044.146973 401.448120,1043.298950 
	C390.984070,1042.179565 383.080841,1045.146240 374.989960,1050.957886 
	C371.588715,1053.401123 366.842468,1052.536133 362.794678,1049.795654 
	C355.060486,1044.559326 354.787811,1044.618896 349.995331,1052.108643 
	C347.316742,1056.294800 344.539154,1058.237549 339.326019,1056.375488 
	C336.316650,1055.300537 332.575623,1055.296387 329.739990,1057.573730 
	C325.144745,1061.264282 321.832031,1059.494629 318.842255,1055.472412 
	C318.444672,1054.937622 318.087891,1054.362549 317.631073,1053.883423 
	C315.770569,1051.931885 313.253174,1051.227051 311.548767,1053.278564 
	C309.844879,1055.329468 312.085480,1056.844604 313.795990,1057.829102 
	C317.043030,1059.697876 319.728760,1062.248291 321.901947,1065.163818 
	C326.992615,1071.993286 323.997284,1078.827881 315.677948,1080.028931 
	C309.448242,1080.928223 303.327179,1082.609863 296.919861,1082.077148 
	C293.432953,1081.787231 290.541748,1083.752808 287.571930,1085.232178 
	C282.531036,1087.743286 277.645050,1090.668579 271.780792,1090.353882 
	C269.881897,1094.105103 272.776184,1097.220703 272.003235,1101.202026 
	C267.445465,1102.750732 262.741760,1103.055420 258.354523,1104.395996 
	C252.653000,1106.138062 247.394577,1108.651855 243.758377,1114.095093 
	C240.377380,1119.156372 238.028030,1118.956177 234.582626,1114.668091 
	C229.957382,1108.911621 229.560181,1108.948608 225.834290,1115.555786 
	C225.510910,1116.129150 225.388901,1116.824219 225.030151,1117.368286 
	C221.955551,1122.030762 213.907700,1123.710205 210.126328,1120.505859 
	C207.055618,1117.903687 207.102814,1112.247192 211.119690,1108.107788 
	C215.031494,1104.076782 217.191833,1100.526245 213.567490,1095.120972 
	C209.140076,1088.518066 205.230972,1086.349121 198.336868,1087.534912 
	C196.708252,1087.815063 195.041824,1087.932861 193.387787,1087.997437 
	C187.080872,1088.243774 182.474289,1091.034668 180.607803,1097.193604 
	C179.077927,1102.242065 175.506287,1103.837891 170.822403,1103.945801 
	C166.167877,1104.053101 161.229492,1102.995239 156.909225,1104.207397 
	C146.916840,1107.011353 136.995956,1107.338379 126.837746,1106.117065 
	C125.290749,1105.931030 123.392776,1106.353638 122.050400,1107.154175 
	C113.816322,1112.064697 105.094147,1111.937012 96.194878,1109.898804 
	C91.151588,1108.744019 86.198021,1108.288940 83.302055,1102.225464 
	C80.605659,1096.579712 75.635635,1097.183838 71.794487,1102.494019 
	C66.979889,1109.149780 64.859985,1109.440186 58.525143,1104.140381 
	C57.272957,1103.092896 56.177238,1101.820435 55.166836,1100.528687 
	C51.371140,1095.675903 46.369293,1094.017334 40.311314,1093.921509 
	C28.431328,1093.733398 17.232862,1095.718018 7.128384,1102.351440 
	C6.045363,1103.062500 4.679635,1103.342773 2.722538,1103.911377 
	C2.000000,1000.624878 2.000000,897.249756 2.000000,792.937317 
z"
        />
        <path
          fill="#91B1CA"
          opacity="1.000000"
          stroke="none"
          d="
M1769.513672,1730.577881 
	C1770.272949,1726.539673 1768.868408,1724.633667 1765.403198,1723.422363 
	C1761.368530,1722.011963 1757.248901,1720.345459 1754.214844,1717.054688 
	C1751.864624,1714.505615 1752.296021,1712.262573 1755.350952,1710.635010 
	C1756.218628,1710.172974 1757.231323,1709.709595 1758.177002,1709.710571 
	C1766.923828,1709.720459 1772.494507,1703.517944 1779.051270,1699.219360 
	C1780.831787,1698.052124 1781.600464,1696.237671 1782.730591,1694.610718 
	C1786.422363,1689.296509 1788.505737,1688.582031 1794.258545,1691.634033 
	C1797.544189,1693.377197 1800.877075,1695.533325 1804.437500,1695.144775 
	C1810.357056,1694.498779 1815.801636,1692.971069 1818.969482,1686.464478 
	C1822.858154,1678.477051 1824.467651,1678.319458 1834.062378,1679.623413 
	C1836.007080,1679.887695 1838.645752,1680.428345 1839.902100,1679.461426 
	C1845.939697,1674.814331 1854.428101,1677.027710 1860.147705,1671.873657 
	C1862.442261,1669.806030 1864.440430,1671.526245 1866.577637,1672.249268 
	C1876.020874,1675.444092 1888.636963,1671.743896 1894.351440,1664.205811 
	C1896.288818,1661.650269 1894.783081,1659.904785 1893.057861,1658.195312 
	C1889.832642,1654.999634 1885.317993,1654.490601 1881.452148,1652.629639 
	C1879.442627,1651.662231 1876.619019,1650.930908 1877.136963,1647.944336 
	C1877.607544,1645.230957 1880.368896,1644.699463 1882.566772,1644.314697 
	C1885.834351,1643.742676 1889.728271,1642.916992 1892.364502,1644.746948 
	C1898.143066,1648.757935 1904.407349,1648.063354 1910.654053,1647.943237 
	C1915.805298,1647.844360 1920.613281,1648.467773 1924.764526,1651.801392 
	C1926.366333,1653.088013 1928.135010,1654.049316 1930.266724,1653.497437 
	C1942.092896,1650.436279 1954.131226,1652.629395 1966.056396,1652.029297 
	C1973.004272,1651.679565 1979.929199,1652.306763 1986.861694,1650.384888 
	C1993.201904,1648.626953 1999.480835,1650.768921 2005.157227,1653.705688 
	C2011.141479,1656.801758 2016.621094,1656.588257 2022.035278,1652.729614 
	C2023.666260,1651.567139 2025.381836,1649.759399 2027.320923,1650.249512 
	C2035.950928,1652.430908 2044.889526,1650.549072 2053.454346,1652.479492 
	C2055.567627,1652.955688 2057.115967,1653.287842 2058.958008,1652.072998 
	C2063.572021,1649.029541 2069.152588,1649.525757 2074.243408,1648.221069 
	C2077.788330,1647.312378 2081.538818,1647.841919 2083.844971,1651.704712 
	C2086.988281,1656.968994 2092.480225,1657.677856 2097.768311,1657.885864 
	C2104.687744,1658.157959 2111.390869,1656.948730 2118.299316,1655.507446 
	C2128.219482,1653.438110 2138.322754,1650.582275 2149.372314,1654.852539 
	C2158.029053,1658.198120 2168.437988,1656.915405 2177.960449,1654.911133 
	C2182.052734,1654.049805 2185.808350,1653.500000 2189.353271,1656.220825 
	C2191.938721,1658.205566 2194.984863,1657.990112 2197.977539,1657.995728 
	C2203.309082,1658.005859 2208.650391,1657.797119 2213.969727,1658.051880 
	C2220.485840,1658.363770 2226.074219,1657.902466 2226.698975,1649.439697 
	C2226.843262,1647.485596 2228.716064,1645.920166 2230.529297,1645.054199 
	C2236.228516,1642.331787 2242.032227,1639.829102 2247.763916,1637.173462 
	C2249.835938,1636.213623 2251.691895,1636.738770 2253.452393,1637.781250 
	C2259.743652,1641.507202 2267.260498,1642.623901 2273.109375,1647.390747 
	C2277.441162,1650.921021 2281.664551,1650.266479 2285.248779,1645.796631 
	C2286.491455,1644.246582 2287.994873,1642.886475 2289.482666,1641.555176 
	C2294.625000,1636.954590 2295.200439,1636.732666 2299.261719,1642.673706 
	C2304.076660,1649.716309 2311.660889,1648.081177 2318.170898,1649.271729 
	C2320.651855,1649.725586 2323.962891,1646.552124 2323.171387,1643.059326 
	C2321.888916,1637.400146 2325.839844,1636.377808 2329.298096,1636.292358 
	C2343.157959,1635.949341 2357.101807,1634.694214 2369.944824,1642.489014 
	C2380.456787,1648.868896 2389.226318,1646.574585 2396.742920,1636.751831 
	C2402.106689,1629.742310 2406.570068,1628.357422 2411.737793,1632.084106 
	C2414.688721,1634.212280 2415.894287,1637.253906 2415.955322,1640.716309 
	C2416.055908,1646.419067 2419.310791,1648.048218 2424.422607,1648.028687 
	C2433.379639,1647.994385 2442.310059,1647.756470 2451.215576,1649.781128 
	C2455.155273,1650.676880 2461.975098,1650.962524 2462.161133,1646.227173 
	C2462.354980,1641.294189 2455.807617,1642.160522 2451.665039,1642.020874 
	C2447.671143,1641.886230 2443.665039,1642.043213 2439.677002,1641.830933 
	C2437.247314,1641.701538 2435.049072,1640.887817 2435.102783,1637.361816 
	C2439.971680,1637.575073 2444.823242,1637.769897 2449.672852,1638.004272 
	C2456.086670,1638.314209 2462.356934,1638.151733 2467.616455,1633.637451 
	C2469.511230,1632.011108 2472.052490,1631.491333 2474.237793,1632.119751 
	C2484.194092,1634.982666 2494.686768,1633.091309 2504.722900,1636.260010 
	C2512.643066,1638.760620 2520.991455,1635.559204 2529.167236,1635.234985 
	C2530.130371,1635.196899 2531.059082,1634.607300 2532.030273,1634.396240 
	C2534.428467,1633.875488 2536.777100,1634.028687 2538.464844,1636.014038 
	C2540.288574,1638.158936 2539.864258,1640.268555 2537.855713,1642.159180 
	C2536.515869,1643.420166 2535.927246,1645.029907 2537.085693,1646.573486 
	C2538.188721,1648.043579 2540.392822,1648.497803 2541.609131,1647.590820 
	C2548.605225,1642.373413 2558.612061,1646.455566 2565.289062,1639.000732 
	C2569.109131,1634.735596 2574.443604,1635.462891 2578.627197,1640.076050 
	C2583.242432,1645.165039 2590.796875,1646.208618 2594.115723,1653.391235 
	C2595.772217,1656.976440 2602.170654,1657.620728 2605.829102,1654.531860 
	C2613.114014,1648.380249 2621.905273,1648.737305 2630.263916,1648.212280 
	C2639.333496,1647.642578 2647.916504,1644.957397 2656.085205,1642.059937 
	C2663.185303,1639.541504 2668.036133,1640.370972 2673.482422,1644.466309 
	C2679.009521,1648.622681 2679.851318,1648.327393 2682.868896,1642.089722 
	C2683.588379,1640.602417 2683.842773,1638.883911 2684.603271,1637.423950 
	C2686.177002,1634.402710 2688.621094,1633.585205 2690.865967,1636.408691 
	C2696.958740,1644.072266 2707.113525,1642.780029 2714.664062,1647.467773 
	C2718.529541,1649.867676 2721.317871,1647.258179 2721.943115,1642.545532 
	C2722.117188,1641.231934 2722.011719,1639.883057 2722.063965,1638.551392 
	C2722.177002,1635.674194 2723.103760,1633.299316 2726.175781,1632.445312 
	C2729.479736,1631.526855 2732.133057,1632.779907 2734.050049,1635.378540 
	C2734.997803,1636.663330 2735.676025,1638.302246 2735.978027,1639.876465 
	C2736.632812,1643.292480 2738.711426,1644.294067 2741.764893,1643.625610 
	C2750.871582,1641.631958 2759.928467,1643.991455 2769.009766,1643.811768 
	C2772.664307,1643.739624 2776.533936,1644.437500 2779.927246,1643.465210 
	C2786.003174,1641.724121 2791.630615,1641.378174 2797.300049,1644.518677 
	C2798.367188,1645.109619 2800.192871,1645.590698 2801.060059,1645.083130 
	C2808.090332,1640.967285 2816.934570,1643.049072 2823.760498,1638.198242 
	C2826.553467,1636.213501 2829.746094,1635.324707 2832.968750,1637.930908 
	C2836.481445,1640.771973 2840.334473,1640.094482 2844.346680,1638.929565 
	C2850.690186,1637.087891 2856.842529,1636.112183 2863.800049,1637.539917 
	C2870.368896,1638.887939 2877.797119,1639.516479 2884.315674,1635.819458 
	C2887.500488,1634.013428 2889.854736,1634.829468 2892.294434,1637.217651 
	C2897.209717,1642.028931 2902.322266,1643.129150 2908.049072,1638.129395 
	C2910.346680,1636.123413 2913.385742,1635.654297 2916.475342,1637.250977 
	C2925.934814,1642.139893 2936.117432,1640.275269 2945.884521,1639.318726 
	C2950.474365,1638.869263 2954.168701,1639.070679 2958.194336,1640.979858 
	C2972.550537,1647.788452 2986.356445,1646.060425 2999.506592,1637.680908 
	C3003.235352,1635.304932 3007.233887,1636.165649 3011.155762,1636.033447 
	C3015.482422,1635.887451 3019.817871,1636.000000 3025.074707,1636.000000 
	C3026.000000,1670.708496 3026.000000,1705.416870 3026.000000,1741.062744 
	C3024.369873,1743.474854 3022.529541,1744.518433 3019.515137,1744.815674 
	C3017.691406,1744.876953 3016.657959,1744.888916 3014.874512,1744.906860 
	C3009.407471,1745.334717 3004.417236,1744.393066 2999.731445,1748.200928 
	C2997.236572,1758.702271 2992.536621,1767.688232 2994.291748,1779.096436 
	C2995.386230,1786.209839 2992.853027,1794.949463 2993.012695,1803.096069 
	C2993.241699,1814.778687 2990.664551,1826.396606 2992.311768,1838.150513 
	C2993.210205,1844.562012 2990.112061,1848.406006 2983.538574,1848.828979 
	C2974.251953,1849.426514 2964.952393,1849.623169 2954.884033,1848.500244 
	C2946.992676,1844.337891 2938.380371,1846.247681 2930.518066,1842.065918 
	C2927.697998,1839.783203 2926.277588,1837.400879 2924.465820,1834.281494 
	C2923.434570,1831.299072 2923.354980,1829.040161 2923.617676,1825.918457 
	C2925.354492,1813.450195 2924.104492,1801.729858 2924.898193,1789.293579 
	C2925.675049,1784.675537 2926.014160,1780.850952 2924.318115,1776.425049 
	C2924.101562,1774.419312 2923.632080,1776.052979 2923.967041,1774.801758 
	C2926.577637,1765.048340 2923.140869,1759.732300 2912.847900,1759.092773 
	C2911.613281,1759.016235 2910.562500,1757.890137 2909.129883,1756.448730 
	C2907.430176,1753.648560 2907.144775,1751.193970 2907.066406,1747.963989 
	C2907.117188,1745.798584 2907.122559,1744.367432 2907.127930,1742.257446 
	C2907.172852,1740.706421 2907.195312,1739.833984 2907.203613,1738.349365 
	C2907.175049,1737.342896 2907.157715,1736.948486 2907.078613,1735.986450 
	C2906.801758,1734.706787 2906.486328,1734.043945 2905.608398,1733.098877 
	C2903.032959,1731.405029 2900.488770,1731.946289 2897.589844,1731.044678 
	C2892.809082,1728.039673 2888.461914,1728.581299 2883.364258,1730.296265 
	C2872.882080,1732.048462 2862.898438,1730.095337 2852.514160,1732.371582 
	C2850.358887,1733.195923 2848.816162,1733.755371 2847.096191,1735.132568 
	C2846.499268,1735.808838 2846.285889,1736.094238 2845.813965,1736.834473 
	C2845.270264,1737.935425 2845.093506,1738.611206 2845.083984,1739.859375 
	C2845.292480,1741.176392 2845.396973,1741.948608 2845.484375,1743.355957 
	C2845.369629,1744.865112 2845.090576,1745.692627 2844.270508,1747.031738 
	C2839.215332,1752.815796 2836.796875,1760.422363 2827.875488,1762.042969 
	C2823.709961,1763.073975 2820.222656,1763.048340 2816.006836,1762.542969 
	C2810.918945,1761.262695 2806.553467,1759.617188 2801.538574,1762.578125 
	C2800.129639,1763.268066 2799.277344,1763.530762 2797.748535,1763.802979 
	C2793.211182,1763.310303 2792.297363,1766.808350 2789.729492,1769.248657 
	C2788.696045,1769.863525 2788.247803,1770.066528 2787.072021,1770.471924 
	C2774.744385,1771.075439 2763.325195,1770.921387 2753.692383,1779.809937 
	C2748.504395,1784.597168 2741.420654,1782.391846 2736.037354,1778.456787 
	C2730.635742,1774.508545 2725.398193,1775.490845 2719.219238,1775.806396 
	C2693.870117,1777.100464 2667.907471,1782.398193 2644.046631,1768.212158 
	C2641.452881,1766.670044 2638.396729,1765.315430 2635.460693,1766.130005 
	C2621.984619,1769.868530 2607.703857,1767.533325 2594.235352,1772.510620 
	C2586.243164,1775.464233 2577.224365,1771.952759 2568.602539,1772.487427 
	C2564.148438,1772.763672 2560.110352,1772.704468 2556.675537,1776.067871 
	C2551.871094,1780.772583 2546.404541,1780.727051 2541.235596,1776.953125 
	C2526.720459,1766.355713 2511.415283,1764.616455 2494.979492,1771.928955 
	C2492.265381,1773.136719 2489.086670,1774.092529 2486.207275,1772.907837 
	C2480.346436,1770.495605 2475.198486,1772.413330 2469.868896,1774.584839 
	C2461.586182,1777.959351 2454.666504,1776.326294 2446.488037,1771.724365 
	C2433.981201,1764.687256 2420.220459,1760.199219 2404.885254,1764.952637 
	C2390.032227,1769.556396 2374.522217,1767.415649 2359.278809,1766.287842 
	C2355.332764,1765.995850 2352.799805,1762.942383 2351.336670,1759.390869 
	C2349.065186,1753.877197 2345.440430,1753.703735 2340.983643,1756.483398 
	C2333.545410,1761.122314 2326.535645,1761.385376 2320.361816,1754.395752 
	C2317.024170,1750.617065 2313.939697,1751.878662 2310.911865,1754.561035 
	C2304.762939,1760.008545 2297.439697,1761.267090 2289.526611,1761.279419 
	C2278.190918,1761.296875 2267.172607,1764.507812 2255.827637,1764.921509 
	C2247.767090,1765.215454 2241.645020,1763.799927 2238.529785,1755.041626 
	C2236.208252,1748.514893 2231.055420,1747.822144 2226.386230,1753.038208 
	C2222.297852,1757.605225 2218.516846,1762.751709 2211.800049,1762.236206 
	C2200.904053,1761.400024 2189.686768,1761.633301 2179.546631,1756.691162 
	C2175.735107,1754.833496 2175.034668,1750.290039 2173.316162,1746.715576 
	C2171.524170,1742.988037 2169.220703,1738.802124 2164.464600,1740.447510 
	C2153.060547,1744.392456 2141.229980,1740.206299 2129.798828,1743.104370 
	C2111.132324,1747.836426 2092.358887,1747.986084 2073.603271,1743.302856 
	C2070.620605,1742.557861 2067.638672,1743.150391 2064.703613,1743.863770 
	C2056.408203,1745.880127 2048.192383,1745.269043 2039.958252,1743.334229 
	C2031.193970,1741.274658 2021.708374,1742.018433 2013.420410,1739.925537 
	C1998.780884,1736.229004 1983.727539,1734.290161 1969.336426,1729.763062 
	C1950.664917,1723.889404 1932.717407,1724.302979 1915.824585,1734.901001 
	C1911.818359,1737.414429 1908.535156,1740.955566 1903.296631,1738.451416 
	C1899.971313,1736.862061 1898.427124,1739.128174 1896.870850,1741.383301 
	C1892.817871,1747.255859 1887.053955,1750.175171 1880.137451,1751.187256 
	C1877.834229,1751.524414 1875.287231,1751.631836 1873.431885,1753.019531 
	C1858.394043,1764.267822 1840.997681,1760.844849 1824.203491,1761.064697 
	C1820.130859,1761.118164 1816.246582,1760.187866 1813.328125,1756.930054 
	C1810.306152,1753.556519 1808.653687,1748.055176 1803.141602,1748.221924 
	C1795.561523,1748.451172 1787.819702,1748.368286 1780.901855,1752.620361 
	C1775.885132,1755.703735 1770.445068,1759.046509 1767.964600,1749.073242 
	C1768.619629,1745.437866 1770.758545,1742.605103 1768.163818,1738.891357 
	C1767.347290,1735.719604 1767.454468,1733.322510 1769.513672,1730.577881 
z"
        />
        <path
          fill="#051523"
          opacity="1.000000"
          stroke="none"
          d="
M3025.425293,3899.513672 
	C3026.000000,3931.375244 3026.000000,3962.750244 3026.000000,3995.062744 
	C3022.734131,3999.150391 3018.717529,3999.218506 3014.753906,3998.251221 
	C2988.906738,3991.943604 2963.313232,3984.724365 2937.979980,3976.583740 
	C2922.838379,3971.717773 2908.077148,3965.610107 2891.831299,3964.672119 
	C2883.250488,3964.176514 2874.592773,3962.978027 2866.589600,3959.176270 
	C2863.554932,3957.734619 2860.147461,3957.014404 2856.842041,3957.417725 
	C2839.379395,3959.546875 2822.461914,3956.772949 2805.704834,3952.191162 
	C2804.777588,3951.937744 2803.706299,3951.824463 2802.935547,3953.075928 
	C2804.649414,3956.466064 2808.704834,3956.898682 2811.405518,3959.145264 
	C2814.546875,3961.758301 2817.832031,3964.451416 2816.773926,3969.046143 
	C2815.741211,3973.531250 2811.750244,3974.942139 2807.805420,3976.001465 
	C2802.803955,3977.344727 2797.799805,3976.602539 2793.008545,3975.195312 
	C2783.677734,3972.455566 2774.279053,3970.718018 2764.500977,3971.077637 
	C2759.675049,3971.254639 2755.386475,3969.674561 2751.916016,3966.037598 
	C2744.537109,3958.305664 2734.974365,3955.894043 2724.760742,3955.317871 
	C2712.445068,3954.623047 2700.235107,3953.131836 2688.140137,3950.719971 
	C2684.216064,3949.937744 2680.105713,3949.289551 2676.750977,3947.138672 
	C2661.121094,3937.117188 2643.046875,3933.159912 2626.204834,3926.175537 
	C2607.736084,3918.516357 2589.286377,3911.431152 2569.454590,3908.061523 
	C2562.291992,3906.844727 2556.123779,3902.156982 2549.418457,3899.230225 
	C2541.727295,3895.874023 2539.445312,3897.075439 2539.713623,3905.659180 
	C2539.931641,3912.627930 2541.026855,3919.543213 2541.348877,3926.542480 
	C2542.356934,3948.464844 2545.289551,3970.265869 2545.595947,3992.246094 
	C2545.695068,3999.366943 2549.044434,4003.974854 2556.435791,4005.721436 
	C2561.048096,4006.811279 2565.564209,4009.282471 2565.223389,4015.040771 
	C2564.910889,4020.322266 2560.095459,4020.858154 2556.265381,4021.183838 
	C2548.779785,4021.820801 2550.021484,4028.882080 2546.503418,4033.381592 
	C2541.258789,4034.000000 2536.517822,4034.000000 2530.888184,4034.000000 
	C2525.042480,4028.353271 2526.680176,4021.430176 2526.495605,4014.937744 
	C2526.335449,4009.307617 2525.308350,4003.707764 2526.089355,3997.171387 
	C2530.340576,3990.196045 2530.980225,3983.617432 2530.690430,3976.333252 
	C2529.251953,3940.130859 2528.147217,3903.915283 2526.408203,3867.723145 
	C2526.260742,3864.658936 2526.511963,3861.717773 2523.071533,3859.020020 
	C2519.012207,3838.276611 2516.545166,3818.166016 2517.352539,3796.926758 
	C2518.125977,3793.837402 2519.408936,3792.001465 2521.444336,3789.715332 
	C2527.719482,3785.403809 2529.170166,3778.728027 2533.210449,3772.914551 
	C2540.433594,3765.157959 2547.758057,3761.581055 2557.525391,3767.034180 
	C2562.551758,3769.840332 2568.021729,3768.033936 2572.984863,3766.035156 
	C2582.239258,3762.308594 2589.574707,3764.565918 2597.963623,3770.058594 
	C2611.971924,3779.230469 2625.445557,3789.822510 2642.572754,3793.535400 
	C2648.063965,3794.726074 2654.719727,3795.409180 2657.433594,3802.858887 
	C2652.995605,3812.428711 2643.798584,3811.869141 2636.128662,3814.318604 
	C2632.498779,3815.477539 2628.034424,3813.984863 2624.277832,3818.666260 
	C2632.232178,3823.516602 2640.943115,3825.326904 2649.311523,3827.562012 
	C2656.471680,3829.474121 2663.065674,3830.914062 2662.626221,3840.439697 
	C2662.504395,3843.072998 2664.692627,3844.734375 2667.024414,3845.296631 
	C2678.618896,3848.091553 2689.618896,3853.322266 2701.969727,3853.098145 
	C2708.555420,3852.979004 2713.791748,3851.168213 2717.040771,3845.306152 
	C2721.467041,3837.320068 2727.541992,3836.756348 2735.679932,3839.518555 
	C2746.703125,3843.260010 2757.053223,3848.343750 2767.987305,3853.631592 
	C2762.781494,3845.974365 2755.828857,3841.719971 2747.837158,3838.878662 
	C2740.906982,3836.414307 2734.291504,3833.764648 2737.370850,3824.127930 
	C2738.485840,3820.637939 2735.758545,3819.626709 2733.362305,3818.306641 
	C2728.007812,3815.356689 2721.650879,3815.760498 2715.765625,3812.459229 
	C2714.689941,3810.211182 2714.948486,3808.671143 2716.007324,3807.217529 
	C2721.067627,3800.270752 2720.440186,3798.565430 2712.001709,3796.220947 
	C2709.416016,3795.502441 2706.738770,3794.989258 2704.106934,3795.299072 
	C2674.492188,3798.782471 2647.616211,3791.145264 2622.640381,3775.528809 
	C2619.501221,3773.565918 2615.600098,3773.510254 2612.283936,3771.994141 
	C2607.352539,3769.739746 2603.625488,3766.030273 2605.345703,3760.321533 
	C2607.178223,3754.239502 2612.217041,3753.899414 2617.592773,3755.706787 
	C2622.638428,3757.403809 2627.918701,3758.681396 2632.607178,3761.097412 
	C2648.871582,3769.479736 2666.163086,3770.681152 2683.919189,3769.343506 
	C2688.673096,3768.985107 2693.253418,3769.869141 2697.562500,3771.725098 
	C2709.700439,3776.951904 2723.081299,3776.108643 2735.550049,3779.686279 
	C2738.285156,3780.470947 2740.877686,3779.542725 2743.091064,3777.932861 
	C2751.011230,3772.171631 2757.358887,3774.731445 2763.417725,3782.137451 
	C2766.373779,3789.446289 2772.478271,3791.479492 2778.293701,3793.899170 
	C2782.941162,3795.833008 2788.473145,3797.668457 2787.266357,3804.018066 
	C2785.977783,3810.796387 2779.784424,3812.553223 2774.096680,3812.195801 
	C2765.560059,3811.658203 2756.962402,3810.668945 2748.260986,3808.177246 
	C2755.592529,3812.047607 2763.270264,3813.717285 2770.795654,3815.760254 
	C2800.168457,3823.734619 2830.374268,3828.406250 2859.140869,3838.910889 
	C2869.535156,3842.706787 2879.203125,3839.252441 2886.817383,3829.691406 
	C2891.063721,3828.498047 2894.588623,3827.523193 2899.035645,3828.360107 
	C2911.377197,3832.916748 2923.473633,3834.802979 2935.435059,3831.630615 
	C2943.853271,3829.397705 2951.568604,3828.522461 2959.555908,3832.286865 
	C2963.634277,3834.208984 2967.957031,3833.936279 2972.243408,3833.031982 
	C2974.821289,3832.488281 2978.089600,3832.202881 2978.643311,3829.198486 
	C2979.255859,3825.876465 2975.904053,3825.014893 2973.571533,3824.033691 
	C2966.537842,3821.072998 2959.162354,3819.127441 2951.042480,3817.166260 
	C2944.269287,3817.576172 2938.490723,3815.662354 2932.547607,3815.454346 
	C2921.803467,3815.078369 2911.327637,3814.667480 2900.338135,3816.326416 
	C2883.076904,3818.932617 2865.627441,3813.740234 2848.312500,3811.383545 
	C2845.628174,3811.018066 2843.560303,3809.145752 2842.620117,3805.535645 
	C2844.397949,3797.213135 2852.119141,3800.357666 2857.832275,3796.126709 
	C2845.580811,3793.700928 2836.424072,3787.270752 2826.857178,3781.596680 
	C2823.947510,3779.870850 2820.562012,3779.142578 2817.220947,3779.353271 
	C2806.582275,3780.023193 2796.932373,3776.937500 2787.360107,3772.658447 
	C2764.575439,3762.473145 2739.960938,3759.934082 2715.646973,3756.357422 
	C2706.867432,3755.065918 2699.040039,3751.621094 2691.462402,3747.456299 
	C2687.930664,3745.515137 2684.662842,3742.478271 2685.204102,3737.978760 
	C2685.782471,3733.171875 2690.193848,3732.338379 2694.060303,3731.183105 
	C2707.124512,3727.280029 2720.444336,3728.438721 2732.247559,3733.776123 
	C2755.706299,3744.383789 2781.173340,3746.069092 2805.512695,3752.578857 
	C2810.966553,3754.037354 2816.535156,3755.121826 2822.635010,3757.081787 
	C2832.865967,3760.123047 2842.343750,3763.396729 2852.815430,3760.318848 
	C2852.449951,3756.655029 2849.064209,3755.960693 2847.080566,3754.097168 
	C2844.619141,3751.784912 2841.774658,3749.594238 2842.901123,3745.745605 
	C2844.103027,3741.638916 2847.739746,3741.770996 2851.116943,3741.876221 
	C2854.111084,3741.969727 2857.166504,3742.454346 2859.306396,3739.559082 
	C2862.290527,3735.520752 2860.291016,3729.245117 2854.944092,3727.573486 
	C2850.196533,3726.088867 2845.343994,3724.936279 2840.017090,3722.908691 
	C2838.354492,3722.574707 2837.431885,3722.268311 2835.876953,3721.533203 
	C2832.157959,3716.659424 2824.350830,3718.209473 2823.089355,3710.880371 
	C2829.807617,3703.460693 2837.825439,3711.197998 2845.197021,3708.526855 
	C2833.734375,3695.327637 2819.103027,3692.024902 2803.220703,3691.811523 
	C2800.569336,3691.775635 2799.926025,3693.524170 2798.692627,3695.928223 
	C2793.941406,3698.837402 2789.585449,3696.830078 2784.510986,3696.439941 
	C2783.312256,3696.138916 2782.842041,3695.986572 2781.701904,3695.522217 
	C2775.504395,3689.602051 2766.204590,3690.423096 2760.914551,3683.053711 
	C2759.468750,3680.601807 2758.946533,3678.662598 2759.669434,3675.863037 
	C2762.485596,3672.933350 2765.537842,3672.140869 2769.449219,3671.412598 
	C2775.801270,3670.794434 2780.666016,3672.920898 2786.147705,3675.693848 
	C2794.838623,3683.081055 2805.430176,3679.844238 2815.149414,3683.070312 
	C2823.081299,3684.643311 2830.812988,3683.367676 2838.114746,3687.754395 
	C2842.324707,3692.693115 2846.355957,3696.385010 2853.530029,3695.253418 
	C2855.203125,3695.328613 2856.154541,3695.438965 2857.803955,3695.762695 
	C2874.762451,3697.774414 2887.969727,3705.155518 2900.835693,3715.374512 
	C2912.139648,3724.352295 2926.694824,3728.138184 2942.442139,3727.324219 
	C2932.937500,3722.667236 2922.870605,3721.166748 2914.053711,3716.568848 
	C2903.455322,3711.042236 2901.822510,3705.215088 2908.534668,3695.792480 
	C2909.999756,3694.912109 2910.933594,3694.648926 2912.650146,3694.644043 
	C2915.258301,3695.152344 2916.739502,3696.616943 2919.456299,3696.726318 
	C2924.510498,3696.799072 2927.824951,3695.072754 2930.430664,3691.576904 
	C2934.642334,3685.927979 2939.954590,3684.928467 2946.290039,3688.019287 
	C2962.848389,3696.097656 2981.679932,3697.095947 2998.441650,3704.677002 
	C3002.754150,3706.627441 3007.591064,3705.769287 3012.228516,3704.743652 
	C3016.407471,3703.819092 3020.676025,3702.363525 3025.403809,3705.511719 
	C3026.000000,3721.375244 3026.000000,3736.750244 3026.000000,3753.062744 
	C3022.102783,3756.866455 3019.000488,3753.839111 3015.905518,3752.376465 
	C3001.197021,3745.426025 2985.447266,3747.576416 2970.030029,3746.913574 
	C2967.751953,3746.815430 2965.296875,3747.927490 2963.099609,3747.280518 
	C2944.347900,3741.755859 2924.708740,3749.514893 2911.773193,3744.678467 
	C2923.790039,3746.420898 2942.031006,3748.238281 2958.601562,3755.458252 
	C2975.703369,3762.909912 2994.578125,3760.651855 3011.641602,3767.250488 
	C3016.531006,3769.141113 3022.214600,3769.489990 3025.577148,3775.408203 
	C3026.000000,3806.708496 3026.000000,3837.416992 3026.000000,3869.062744 
	C3014.683838,3870.808594 3003.776123,3867.608398 2996.269287,3860.014648 
	C2988.679199,3852.337158 2980.327881,3848.339844 2970.398682,3846.590820 
	C2955.098633,3843.895752 2939.934570,3845.383057 2925.070801,3849.553955 
	C2921.810303,3850.468506 2916.999756,3851.483398 2917.560791,3856.056641 
	C2918.015625,3859.766602 2922.470215,3859.814453 2925.602783,3860.820068 
	C2938.561768,3864.981445 2952.264648,3865.430420 2965.301270,3869.982666 
	C2980.941650,3875.444580 2994.977295,3883.282471 3008.220215,3892.770508 
	C3013.377930,3896.465576 3019.743652,3895.845215 3025.425293,3899.513672 
z"
        />
        <path
          fill="#A4BED0"
          opacity="1.000000"
          stroke="none"
          d="
M2.000000,1570.937256 
	C8.937206,1570.112915 15.939970,1569.202759 22.450069,1572.993530 
	C25.396093,1574.709106 29.084225,1574.722534 31.974802,1572.594238 
	C36.255390,1569.442505 40.499123,1569.708618 45.186096,1571.361572 
	C48.931870,1572.682617 52.860821,1573.489990 56.719593,1574.482178 
	C58.633499,1574.974121 60.846806,1576.200439 62.454964,1575.670044 
	C71.872948,1572.563843 80.609070,1576.766479 89.582214,1577.301147 
	C108.747055,1578.442749 127.493958,1584.609009 146.930359,1582.056885 
	C151.921158,1581.401611 156.770233,1582.591187 161.629242,1584.078613 
	C169.881027,1586.604980 178.503876,1587.346924 187.079437,1587.943848 
	C193.329987,1588.378784 199.776337,1587.084595 205.572632,1590.677979 
	C207.057693,1591.598755 208.754120,1591.455811 210.383804,1590.827515 
	C212.299896,1590.088989 214.475723,1589.544312 216.231918,1590.477905 
	C231.799942,1598.753174 249.442322,1595.521240 265.752228,1599.570435 
	C270.116425,1600.653931 273.559174,1598.360962 276.979858,1596.425171 
	C282.038452,1593.562256 286.455963,1593.531494 290.129333,1598.469849 
	C291.602478,1600.450317 293.424225,1601.669067 295.796143,1602.239136 
	C296.752045,1602.468872 297.618896,1603.045410 298.559418,1603.366943 
	C303.416443,1605.028076 302.733826,1612.678345 307.787079,1613.008179 
	C311.938507,1613.279175 315.998962,1611.792847 320.281616,1613.992188 
	C326.498413,1617.184814 333.123566,1616.906128 339.562988,1614.174927 
	C342.020966,1613.132568 344.404938,1612.041138 347.174774,1612.014893 
	C361.108826,1611.883179 374.879883,1609.092773 388.908813,1609.886841 
	C399.287170,1610.474243 409.581116,1610.000122 416.510071,1599.965332 
	C418.929230,1596.461670 423.323883,1598.385254 426.844025,1598.017822 
	C431.092926,1597.574463 433.696747,1598.960449 434.423065,1603.714478 
	C434.871735,1606.651489 437.090088,1608.376465 440.343658,1606.432983 
	C446.437988,1602.792480 452.552063,1606.993774 458.700134,1606.420044 
	C461.653198,1606.144409 461.402466,1609.546265 462.341949,1611.576660 
	C464.462036,1616.158691 467.090790,1616.741943 469.172852,1612.590210 
	C471.289124,1608.370361 474.138641,1607.822876 477.977692,1607.986694 
	C479.972290,1608.071777 481.991791,1607.824463 483.968903,1608.028564 
	C496.726379,1609.345459 508.227814,1606.794067 518.535034,1598.569702 
	C524.818848,1593.555786 532.597351,1596.122803 539.741638,1596.661621 
	C542.133728,1596.841919 542.719727,1599.973389 543.993164,1601.884766 
	C550.237122,1611.256592 554.689697,1612.396729 564.722534,1606.719238 
	C572.007996,1602.596436 580.286743,1604.139648 587.965454,1602.200439 
	C591.425537,1601.326660 595.250916,1601.717285 598.502380,1599.760132 
	C603.178223,1596.945435 607.399719,1598.308960 611.593079,1600.930298 
	C614.688049,1602.864990 617.912048,1604.592651 621.021179,1606.505615 
	C629.016052,1611.424316 637.157227,1611.517822 646.633240,1610.860840 
	C661.302917,1609.843750 676.206726,1611.622925 691.073425,1610.476685 
	C694.575867,1610.206787 698.389465,1613.302612 701.982910,1615.011963 
	C712.607971,1620.066650 716.178589,1619.573608 724.647705,1610.865356 
	C727.055481,1608.389771 729.709900,1608.001099 732.753723,1608.002441 
	C739.721069,1608.005615 746.365662,1607.524536 753.236938,1605.006592 
	C760.645630,1602.291748 769.046997,1603.855347 777.000916,1604.337646 
	C780.604675,1604.556152 781.474915,1607.476929 782.020996,1610.733398 
	C783.357239,1618.701904 783.402527,1618.477539 790.902344,1614.715942 
	C802.596313,1608.850708 814.676392,1604.296265 829.083374,1605.996948 
	C830.808838,1605.256958 831.765930,1604.933716 833.580688,1605.334473 
	C836.636169,1609.185059 835.246704,1611.827881 832.222473,1614.049561 
	C830.890564,1615.027710 829.504150,1616.076050 827.959961,1616.528442 
	C813.970581,1620.628052 807.360229,1629.251465 810.664490,1644.892822 
	C810.106812,1648.580933 809.452637,1651.491211 809.001221,1655.189331 
	C809.096375,1658.444214 809.038757,1660.976318 810.125488,1664.146851 
	C810.941650,1670.322266 809.432495,1675.557861 808.996155,1681.711182 
	C808.974609,1684.812134 809.023804,1687.083008 808.993164,1690.240234 
	C809.758484,1700.235352 809.933350,1709.348022 809.143555,1719.395508 
	C809.297791,1722.979980 809.742859,1725.608643 809.633850,1728.293701 
	C809.026245,1743.260376 807.297913,1744.956055 790.879883,1745.602783 
	C788.671082,1745.705078 790.345093,1745.313477 789.022461,1745.482178 
	C771.369751,1747.732788 771.391846,1747.732910 771.328735,1765.020874 
	C771.312073,1769.591309 771.640015,1774.172241 771.019592,1779.584961 
	C770.752869,1785.150391 771.291260,1789.907959 769.814087,1795.416016 
	C759.614258,1806.875122 746.840332,1803.188354 734.069153,1802.821777 
	C730.155579,1798.532715 730.983521,1794.062500 731.084839,1788.814819 
	C733.748718,1758.784912 732.026611,1729.603027 732.270386,1700.449219 
	C732.294922,1697.517090 732.268921,1694.431519 729.537720,1691.529663 
	C722.310669,1689.017944 715.356445,1688.042358 711.051697,1681.335938 
	C709.272827,1678.564819 704.945007,1679.302124 701.047119,1678.487061 
	C698.270813,1677.791016 696.496033,1676.359009 693.657043,1676.067505 
	C692.172668,1675.979004 691.322327,1675.900269 689.830505,1675.692139 
	C686.664001,1674.752808 683.981689,1674.760376 680.771729,1673.950439 
	C678.848083,1673.337036 677.661804,1672.712158 675.916992,1671.728394 
	C673.120972,1669.321533 670.788330,1669.414551 667.712280,1671.257568 
	C664.050903,1671.988525 661.335388,1670.974487 658.006348,1669.562744 
	C653.015015,1665.545654 652.606445,1660.403931 653.255920,1655.506470 
	C654.260376,1647.931885 651.068115,1644.319092 643.275879,1643.223877 
	C640.385010,1642.971191 638.176697,1643.166382 635.324524,1643.670166 
	C634.228455,1643.880615 633.787415,1643.951660 632.677490,1644.069214 
	C631.118408,1644.091553 630.236633,1643.994385 628.731812,1643.640137 
	C625.623596,1642.015015 623.070923,1642.897705 619.818481,1643.225830 
	C596.955200,1642.048096 574.811890,1640.829590 551.854187,1640.384521 
	C533.239929,1640.263184 515.635315,1637.166748 497.843048,1636.328735 
	C492.572357,1636.080566 487.351624,1635.083252 481.403320,1636.734375 
	C478.502960,1638.079102 476.033417,1638.511597 473.480103,1640.442383 
	C472.053101,1641.201294 471.186157,1641.477051 469.636963,1641.684814 
	C467.806976,1641.795166 466.597137,1641.692139 465.163849,1642.751709 
	C464.504120,1643.975952 464.181671,1644.725220 463.619995,1646.094116 
	C462.781708,1647.987427 462.016998,1649.151978 460.534271,1650.719727 
	C459.510529,1651.464600 459.055939,1651.710449 457.855652,1652.211670 
	C452.271515,1653.403442 447.415497,1653.266846 441.735718,1652.577881 
	C425.912140,1649.084595 411.337250,1652.396240 396.782928,1655.456909 
	C391.339478,1656.601807 391.059387,1661.266724 390.966339,1665.671265 
	C390.777435,1674.612427 391.086578,1683.559326 390.682678,1693.355469 
	C388.359283,1704.478760 390.750946,1715.083496 388.077881,1726.117310 
	C385.823853,1730.164429 382.477509,1731.151367 379.237518,1730.765991 
	C367.445435,1729.362793 354.750824,1731.534424 345.288635,1720.998901 
	C340.180481,1715.311035 335.923889,1720.595337 331.263123,1724.286743 
	C329.633514,1725.219360 328.597137,1725.455933 326.713501,1725.368896 
	C322.432953,1722.808472 318.450348,1722.875610 314.450714,1722.954712 
	C309.275330,1723.057129 304.574524,1721.754517 300.137299,1717.563965 
	C298.596771,1715.717163 298.019165,1714.255615 297.568481,1711.920410 
	C297.418884,1710.121582 297.435394,1709.094971 297.567780,1707.315430 
	C297.812378,1704.559082 297.852448,1702.560913 297.308655,1699.797607 
	C296.684784,1689.151245 297.167236,1679.277588 297.025574,1668.620117 
	C297.057007,1666.327637 297.073517,1664.822021 297.070557,1662.629272 
	C297.113678,1660.660278 297.160492,1659.378418 297.033081,1657.536133 
	C296.789581,1656.281860 296.514130,1655.629883 295.682953,1654.743652 
	C294.952545,1654.285034 294.648956,1654.118042 293.867615,1653.740723 
	C292.720520,1653.291504 292.043060,1653.078125 290.804413,1652.828369 
	C289.108704,1652.662598 287.964752,1652.579712 286.204498,1652.633057 
	C285.178436,1652.661987 284.763580,1652.665894 283.718811,1652.650635 
	C281.814789,1652.547363 280.562317,1652.322510 278.699768,1651.890137 
	C275.328735,1650.981812 272.949646,1648.900757 269.229309,1649.536621 
	C268.202698,1649.710693 267.788452,1649.761841 266.752136,1649.832764 
	C265.720490,1649.826294 265.310883,1649.799927 264.299133,1649.684082 
	C261.792267,1649.173706 259.892700,1648.543091 257.406067,1649.698486 
	C255.720444,1650.433228 254.546021,1650.801880 252.699066,1651.105225 
	C250.354538,1651.083496 248.677322,1650.702759 246.313995,1650.548828 
	C245.221069,1650.420898 244.783127,1650.353516 243.692703,1650.134277 
	C241.297882,1649.471313 239.688126,1648.660645 237.514359,1647.452148 
	C233.937088,1644.203125 231.365540,1644.968262 228.477753,1648.415771 
	C227.049011,1649.231201 226.158707,1649.519043 224.529556,1649.713257 
	C219.840744,1649.447754 215.870209,1648.714233 211.252014,1649.229004 
	C209.385208,1649.805176 209.116592,1650.755249 209.553406,1652.620605 
	C209.942673,1654.679321 209.833160,1656.063354 209.118484,1658.098389 
	C204.760635,1663.903320 199.665527,1667.141846 193.004578,1667.655029 
	C185.323257,1668.247070 178.560837,1671.227417 171.924133,1675.053589 
	C168.638596,1676.947632 164.519699,1677.820435 159.811859,1676.440430 
	C156.875839,1669.412109 152.531281,1666.593018 145.632401,1663.817749 
	C136.576889,1660.174805 127.078613,1657.909668 117.646011,1653.474365 
	C114.797295,1649.518677 115.279884,1645.388794 114.014511,1641.007690 
	C112.876549,1636.337891 109.376839,1636.953369 105.884216,1636.186768 
	C104.763039,1635.877441 104.317451,1635.722900 103.226517,1635.258545 
	C97.939072,1631.898438 93.160553,1632.808594 88.214897,1634.176636 
	C78.328827,1636.911377 68.452934,1640.407959 58.760441,1633.053589 
	C56.096802,1631.032349 52.327068,1631.861816 49.003799,1632.327515 
	C41.636597,1633.360107 34.419449,1633.431641 27.643263,1629.592041 
	C23.838564,1627.436157 20.041721,1628.022095 16.197905,1629.895142 
	C11.169070,1632.345459 6.521722,1632.173950 2.516332,1626.530273 
	C2.000000,1607.958252 2.000000,1589.916382 2.000000,1570.937256 
z"
        />
        <path
          fill="#6A8297"
          opacity="1.000000"
          stroke="none"
          d="
M2.617740,2302.501465 
	C2.000000,2300.500000 2.000000,2299.000000 2.000000,2296.750000 
	C11.040714,2290.665039 21.267759,2291.683594 31.076223,2290.870605 
	C38.360916,2290.266602 45.729412,2290.611816 52.996040,2289.231445 
	C39.529140,2288.961914 25.983591,2290.302979 12.598249,2287.787354 
	C9.347899,2287.176270 5.917717,2287.166016 2.571494,2284.481201 
	C2.000000,2284.000000 2.000000,2283.000000 2.000000,2282.500000 
	C5.721318,2278.434082 10.448839,2278.560547 15.098222,2278.468750 
	C25.376226,2278.266113 35.671360,2278.799072 46.713409,2277.580078 
	C52.577545,2276.300781 57.262657,2278.891846 62.262482,2278.942383 
	C73.898964,2279.061035 85.291679,2279.409424 95.931503,2272.803711 
	C102.182983,2268.922607 102.711342,2269.773682 109.438652,2274.213135 
	C107.069099,2268.914551 102.086182,2270.752930 98.306648,2269.540771 
	C94.866577,2268.437744 89.997963,2267.021240 90.338127,2263.192627 
	C90.761444,2258.427979 95.643715,2259.581299 99.262863,2259.888916 
	C102.155914,2260.135254 105.067734,2260.062500 108.346817,2259.612061 
	C96.618973,2262.207520 91.150040,2251.479980 82.398079,2247.833984 
	C78.451965,2246.190186 82.526314,2240.285889 86.064117,2240.256592 
	C97.780235,2240.160889 110.094933,2233.542480 121.190788,2242.841064 
	C122.979210,2244.339844 125.741966,2243.408447 128.649429,2244.275879 
	C131.470123,2245.565430 132.859619,2247.551025 135.368835,2249.249756 
	C139.402725,2251.713867 143.587204,2250.666504 147.762833,2252.624023 
	C151.051834,2256.895508 154.976959,2256.587158 158.968536,2256.245850 
	C163.663330,2255.844482 167.652420,2256.603516 167.970825,2263.208984 
	C167.751511,2264.350342 167.605759,2264.787354 167.120819,2265.768555 
	C166.273087,2266.973633 165.250443,2267.127686 165.036316,2268.079834 
	C165.921005,2267.908447 166.819809,2267.809326 168.341415,2267.399414 
	C171.323425,2266.946777 173.666092,2266.969482 176.740356,2266.951172 
	C179.515747,2266.823730 181.327438,2265.864014 184.028351,2265.142334 
	C197.175583,2262.681396 209.498703,2259.525146 223.010269,2262.687988 
	C240.990860,2262.545410 256.901733,2268.110107 272.840546,2273.537109 
	C273.776703,2273.855957 274.948578,2274.152832 275.422760,2274.935547 
	C281.247101,2284.547852 290.604675,2283.723877 300.440857,2283.919434 
	C303.394287,2290.941406 300.419037,2293.889648 294.312042,2294.690674 
	C288.574982,2295.443359 283.080170,2294.574707 277.173157,2291.615234 
	C266.216675,2283.917236 254.603119,2287.066406 243.625137,2287.704102 
	C235.035278,2288.202637 226.439621,2286.939697 217.862122,2288.388184 
	C214.986237,2288.874023 211.365128,2287.747803 211.222672,2283.943115 
	C211.071854,2279.914551 214.068207,2278.306641 217.833572,2278.063721 
	C223.077530,2277.725586 228.407532,2278.680176 233.568542,2276.954346 
	C222.755936,2280.660889 212.487762,2275.745605 201.087708,2273.845215 
	C202.873840,2278.309814 205.187759,2281.846680 201.519562,2284.685791 
	C198.311905,2287.168213 194.414719,2286.876221 191.139923,2284.935791 
	C185.295578,2281.472656 179.736771,2277.728027 172.943253,2282.815918 
	C172.180542,2283.387207 170.721786,2283.254639 170.076462,2282.651611 
	C161.901321,2275.009766 154.629883,2283.672852 146.916306,2283.794189 
	C144.796600,2283.827393 144.564041,2284.634766 146.101212,2285.483887 
	C149.561813,2287.395020 143.479538,2294.289795 150.953522,2294.034180 
	C158.200027,2293.786621 161.661514,2297.061035 158.643173,2305.301514 
	C154.113022,2310.864746 148.817902,2312.825928 142.426941,2313.088867 
	C129.845230,2313.606689 117.309860,2315.088623 106.646347,2315.046631 
	C140.274506,2312.931152 175.750671,2314.244629 211.240677,2317.381104 
	C223.114624,2318.430664 235.116135,2315.804688 246.980957,2314.645996 
	C271.470062,2312.254395 296.043335,2312.961182 320.524719,2311.100830 
	C337.708527,2309.794922 354.883911,2307.059814 372.416595,2309.506348 
	C373.142731,2310.242432 373.127960,2307.477051 372.566040,2308.686279 
	C372.263397,2309.337646 372.059082,2309.275879 371.615417,2308.901367 
	C366.449341,2304.541016 370.378754,2302.820557 373.979828,2301.660400 
	C378.406067,2300.234375 383.322662,2301.925537 388.373077,2299.571289 
	C393.516083,2294.768799 399.301392,2295.439941 404.910217,2294.915771 
	C408.041901,2294.623535 412.357025,2297.424316 414.218872,2291.607422 
	C414.337036,2289.932373 414.612183,2288.998535 415.484680,2287.470947 
	C420.164520,2283.731201 424.809692,2282.769043 430.621582,2284.282715 
	C431.895844,2284.963867 432.541962,2285.337158 432.767609,2285.912109 
	C437.234161,2297.290771 437.225800,2297.293945 450.565735,2296.166016 
	C453.577423,2296.868652 455.009796,2298.231201 455.317871,2301.373047 
	C446.750092,2310.869629 435.515869,2307.642334 425.412476,2308.359375 
	C418.944153,2308.818115 412.296448,2307.736084 405.735107,2308.274902 
	C445.482330,2309.429932 485.256012,2311.629639 524.830017,2305.479492 
	C517.335938,2306.401855 509.881622,2305.904541 502.392517,2305.297119 
	C494.756317,2304.677734 487.879364,2309.574219 479.930420,2308.721191 
	C473.607544,2308.043457 467.297607,2308.126221 461.448975,2303.409180 
	C456.910156,2292.903320 450.544159,2288.045898 439.062439,2285.604004 
	C428.577576,2283.373779 418.387878,2279.257324 407.340729,2278.653809 
	C401.906830,2278.356445 400.522034,2274.892334 401.028534,2269.557861 
	C402.157501,2257.667969 400.024841,2255.714844 388.059692,2255.217041 
	C378.030334,2254.799805 368.148132,2256.501465 358.195435,2256.864502 
	C352.917603,2257.057129 345.919525,2257.756104 347.379822,2247.892090 
	C354.802612,2242.868652 362.686890,2245.099854 370.125092,2244.249023 
	C383.180389,2242.755859 396.174469,2240.749023 410.029114,2239.749268 
	C418.808167,2243.666504 427.368469,2244.465820 436.057312,2244.575928 
	C439.917572,2244.624756 445.056305,2243.296875 445.255035,2249.219482 
	C445.444916,2254.878418 440.870605,2257.008789 435.931763,2257.798584 
	C431.556152,2258.498291 427.545074,2255.802734 422.873383,2255.983887 
	C437.339264,2262.729004 445.621185,2261.029785 456.390411,2249.887451 
	C459.125458,2247.057617 457.020905,2245.031982 456.342255,2242.702881 
	C452.040833,2227.938965 454.519562,2224.336182 470.068665,2223.135010 
	C482.160095,2222.200684 493.902740,2222.961426 505.001343,2228.837646 
	C508.881866,2230.892334 513.649841,2231.717529 513.445496,2237.194092 
	C513.219299,2243.256348 508.452179,2244.023438 504.298798,2244.190430 
	C508.858002,2244.709717 514.873840,2243.436279 518.231628,2248.591553 
	C520.864929,2252.634277 514.775513,2253.407471 513.878418,2256.575439 
	C519.169128,2258.817627 528.979614,2254.984863 527.332886,2266.581787 
	C528.407349,2266.451416 528.369934,2265.490723 528.686584,2264.922607 
	C529.351929,2263.729248 529.665283,2262.465820 528.798828,2261.197266 
	C528.226624,2260.358887 527.216125,2259.894531 526.698486,2259.088379 
	C522.947937,2253.248047 525.183899,2248.858154 532.076355,2248.420898 
	C532.738647,2248.378662 533.432983,2248.372314 534.065369,2248.548096 
	C554.567505,2254.254395 575.443909,2250.983643 596.174377,2251.131348 
	C603.919312,2251.186279 611.038147,2252.050537 613.147400,2261.391602 
	C613.766296,2264.132568 615.946960,2264.715820 618.416199,2265.099121 
	C626.239197,2266.313721 628.517334,2271.380615 627.085327,2279.263916 
	C637.781555,2272.042480 649.413330,2274.135010 660.186523,2273.462646 
	C652.083679,2272.384033 642.367126,2275.868652 635.764343,2267.114990 
	C635.178284,2266.338135 633.837646,2265.915283 633.615479,2265.089111 
	C631.714417,2258.023193 627.302063,2255.043213 619.934509,2255.242188 
	C617.521240,2255.307373 614.972046,2252.971924 615.168640,2249.745117 
	C615.359314,2246.616455 617.837585,2245.096680 620.412170,2244.386719 
	C631.106628,2241.436279 641.914246,2243.502441 651.803467,2246.740479 
	C659.753662,2249.343506 650.479797,2253.809082 650.511047,2257.918945 
	C661.109985,2262.364746 671.060852,2253.463379 681.230774,2256.297363 
	C677.646301,2253.593506 673.437195,2255.655029 669.392517,2255.430420 
	C665.883118,2255.235596 662.845093,2253.595215 661.519165,2250.165771 
	C659.990173,2246.211670 662.815552,2244.857666 666.180664,2243.821777 
	C634.969727,2239.094971 603.925537,2242.666260 572.989685,2243.607666 
	C564.577332,2243.863770 555.464417,2246.243652 546.751343,2247.989746 
	C538.174866,2249.708496 529.126770,2245.831787 521.166260,2242.603027 
	C516.010986,2240.512207 520.254822,2235.954346 523.753784,2233.716064 
	C527.923035,2231.048584 534.407837,2230.224121 530.439819,2222.381348 
	C529.199280,2219.929688 531.424805,2217.176025 534.481506,2216.572021 
	C538.395569,2215.798828 542.325317,2215.046631 546.271484,2214.466553 
	C550.674988,2213.818848 553.764954,2215.698730 553.440247,2220.379150 
	C552.903442,2228.117188 557.365417,2229.132812 563.455872,2228.961670 
	C573.425903,2228.681396 583.412720,2229.255371 595.025024,2228.551514 
	C590.773132,2225.737549 585.053162,2224.838379 587.119751,2219.367188 
	C588.853821,2214.776367 593.537781,2215.290527 597.453918,2215.036865 
	C601.439758,2214.778564 605.447083,2214.840088 609.440002,2214.985596 
	C621.490234,2215.425049 622.797852,2217.572510 617.546265,2228.098389 
	C630.417297,2220.967041 643.631592,2219.520752 657.913086,2226.080078 
	C661.205872,2229.506836 664.231323,2229.777832 668.062073,2228.447510 
	C672.389832,2226.944092 676.953735,2226.541504 681.190063,2230.700928 
	C682.966675,2237.452637 685.980225,2241.717041 693.819214,2241.152588 
	C700.753174,2241.955566 706.934448,2242.494385 713.300049,2241.580322 
	C722.050598,2240.324463 729.321960,2245.441406 736.654541,2248.914307 
	C741.389954,2251.156982 740.400146,2255.618164 735.595398,2257.899658 
	C731.678528,2259.759521 727.200684,2259.709473 723.205933,2260.677734 
	C731.976013,2261.620850 741.123291,2265.120605 749.153748,2257.073486 
	C751.297607,2254.925049 754.854431,2256.722900 757.501099,2258.315186 
	C759.191833,2259.332275 760.718079,2260.813721 762.784607,2260.905762 
	C764.932922,2261.001465 767.011780,2260.021484 767.356262,2257.904785 
	C767.811646,2255.107178 765.184082,2254.671875 763.338257,2254.871582 
	C758.081421,2255.439453 752.873413,2256.609619 747.547668,2255.894287 
	C745.467773,2255.614746 743.732544,2254.657959 743.361328,2252.331055 
	C743.062195,2250.456055 744.270203,2249.283691 745.539490,2248.160889 
	C756.110596,2238.811279 776.894226,2243.692139 782.740234,2257.635498 
	C783.017334,2260.470703 782.708740,2262.488770 781.622253,2265.141846 
	C770.688416,2269.937256 766.734924,2278.444824 765.505981,2289.694824 
	C762.868042,2293.191406 760.123657,2294.020020 756.177612,2291.933105 
	C751.707581,2285.009277 747.352295,2281.251709 739.800171,2287.203125 
	C737.041565,2289.376953 732.825256,2289.271729 729.189941,2291.882568 
	C723.228149,2293.457275 718.042725,2292.807373 712.705933,2292.051758 
	C705.898376,2291.087891 698.775085,2290.042969 692.836121,2295.918457 
	C705.781555,2291.929688 719.001343,2293.156494 732.152832,2292.975830 
	C748.736511,2292.748291 765.341309,2294.223633 781.906616,2292.316162 
	C786.046997,2291.839355 789.432312,2293.052002 789.563416,2297.882568 
	C789.698853,2302.874756 786.085510,2303.809326 782.074280,2303.686523 
	C776.083008,2303.503662 770.100159,2303.180420 764.099060,2303.430176 
	C759.913818,2303.604736 756.184082,2304.429688 753.551575,2309.189453 
	C741.786987,2312.184082 730.581482,2310.813232 718.605347,2310.633545 
	C711.036316,2307.418701 704.082581,2305.929443 696.001099,2308.004395 
	C693.399658,2309.201904 691.497131,2310.063721 688.652588,2310.592285 
	C679.599182,2310.949463 671.266602,2311.694092 662.833862,2307.648438 
	C665.437073,2299.836182 659.315125,2300.354004 655.374634,2299.291504 
	C656.160400,2301.375000 658.069519,2303.511475 656.406128,2306.908691 
	C650.564209,2313.208252 643.786682,2315.477051 636.101685,2314.890625 
	C630.101501,2314.432617 624.132080,2314.786865 617.396729,2314.903809 
	C611.890686,2315.027100 606.951538,2312.429443 602.458496,2316.549805 
	C600.750000,2318.116943 597.220398,2318.244873 596.177795,2315.983154 
	C592.177856,2307.306396 584.762268,2308.942627 578.690247,2309.084229 
	C582.401184,2310.111328 590.817566,2307.116943 590.079956,2314.584717 
	C589.458374,2320.877441 581.257019,2319.468750 576.510254,2317.967773 
	C569.880737,2315.871338 564.060547,2319.628174 557.815125,2318.956055 
	C536.539612,2316.666748 515.373169,2318.867676 494.161652,2320.184326 
	C490.130768,2320.434326 486.502716,2322.517334 482.853699,2321.903320 
	C487.521027,2333.321533 496.726837,2328.586182 504.981781,2327.678711 
	C506.977325,2327.459473 509.038574,2327.623779 510.881012,2328.430908 
	C519.872192,2332.370117 528.890259,2334.745850 538.851501,2331.801514 
	C542.361389,2330.764160 546.479004,2331.887207 547.528503,2336.455322 
	C548.673157,2341.437988 545.483215,2343.863525 541.286987,2345.102295 
	C533.799927,2347.311768 526.391479,2347.992188 518.843384,2344.727051 
	C515.301208,2343.194580 510.577271,2337.808350 509.173645,2347.996094 
	C508.362061,2353.886963 495.033875,2351.810303 489.506622,2344.848633 
	C489.219879,2343.546387 488.283661,2346.056641 489.360107,2345.269775 
	C489.888153,2344.884277 489.921234,2345.147705 490.137146,2345.852295 
	C492.095612,2352.243652 487.085938,2352.381104 483.431396,2352.734619 
	C465.984192,2354.422607 448.453430,2358.316895 431.135559,2351.928955 
	C428.487854,2350.952393 426.552826,2352.224365 424.739716,2353.626465 
	C421.329956,2356.263428 417.408661,2355.992920 414.051849,2355.997559 
	C419.204041,2356.729492 424.713348,2354.797607 430.394592,2354.856445 
	C438.119568,2354.936768 445.736725,2354.837891 452.033173,2360.488525 
	C454.351562,2362.569092 457.549835,2362.603760 460.518890,2363.046143 
	C463.783600,2363.532715 467.730103,2363.557129 468.739716,2367.454834 
	C469.810150,2371.587646 466.686707,2374.160889 463.776062,2376.504150 
	C462.204590,2377.769775 460.260468,2378.363770 458.550201,2379.373291 
	C457.405823,2380.049072 458.850861,2379.149658 458.065399,2380.184326 
	C457.663239,2380.713867 458.430298,2381.319580 459.955566,2381.195312 
	C471.010498,2380.659424 481.621185,2382.182617 491.085419,2378.266357 
	C485.392731,2379.892334 479.157684,2382.315674 471.988586,2378.964355 
	C468.235657,2370.394531 469.749176,2362.055664 475.523956,2356.733154 
	C482.114929,2350.657959 491.772888,2352.251221 500.229736,2353.192627 
	C503.663422,2353.574951 503.569336,2356.983887 500.784393,2359.036621 
	C498.622192,2360.630615 495.584808,2359.737305 493.164581,2361.076172 
	C504.669403,2355.707031 514.027771,2367.267090 525.911011,2365.027832 
	C530.469543,2365.176758 534.283081,2364.893555 538.816528,2366.005859 
	C542.871582,2368.535400 546.156189,2370.736328 549.962952,2372.040527 
	C557.026001,2374.460449 559.573792,2378.744141 554.898193,2386.256348 
	C548.106567,2390.468506 541.579407,2388.820801 535.081604,2388.594727 
	C553.784119,2389.114258 572.519836,2388.975342 592.052002,2389.004395 
	C598.465271,2388.841309 605.955383,2388.177979 606.293152,2395.193115 
	C606.663513,2402.886475 598.428101,2401.181396 593.366150,2401.979736 
	C589.176880,2402.640381 584.863037,2401.739014 579.744263,2401.204590 
	C577.121216,2400.964355 575.367981,2400.991455 572.744385,2400.971680 
	C568.977051,2400.963867 566.076477,2400.796631 562.336548,2401.371582 
	C533.161316,2403.531494 504.832458,2406.014404 476.477386,2402.269287 
	C470.793243,2401.518555 465.282227,2403.861084 458.868774,2402.464355 
	C452.405243,2398.837646 447.751007,2393.976318 441.999084,2390.624268 
	C438.778412,2388.747314 436.171417,2387.696533 434.344635,2392.605469 
	C431.999603,2398.906250 426.117584,2398.876465 423.504150,2394.519775 
	C416.587738,2382.989502 405.493774,2385.912842 395.879028,2385.568604 
	C381.931885,2385.069580 368.127625,2388.575439 354.164062,2389.468018 
	C346.563171,2389.953857 341.222229,2396.223633 333.259399,2398.069824 
	C328.850586,2398.410645 325.799835,2397.467773 323.820587,2393.077148 
	C323.104736,2389.874512 323.239868,2387.559570 324.624084,2384.569824 
	C329.242767,2380.005127 334.629211,2381.273926 340.262512,2380.141357 
	C344.495514,2378.999268 348.085175,2378.730225 352.283234,2377.765625 
	C357.076508,2376.659180 361.468445,2377.997314 366.207458,2375.688965 
	C362.658722,2372.136230 358.932800,2373.692383 354.720093,2374.218262 
	C348.404541,2374.209229 342.588257,2373.214844 337.377625,2378.338867 
	C332.471375,2381.182129 327.648804,2381.827148 323.210297,2380.831543 
	C304.470551,2376.627441 285.850800,2379.451416 267.198456,2381.082520 
	C261.898956,2381.545898 256.630402,2381.426025 250.498383,2380.987305 
	C243.094147,2379.749512 236.267319,2381.575195 228.977325,2379.074219 
	C211.242172,2379.985107 194.306412,2378.365479 177.423889,2381.079102 
	C162.761154,2383.436279 148.749878,2375.967529 133.227112,2376.647461 
	C126.995583,2374.262695 125.985672,2370.320557 127.728546,2365.127197 
	C129.396530,2360.156982 131.590652,2356.385986 137.744766,2355.637207 
	C142.903442,2355.010010 147.452591,2357.171631 152.459763,2357.156006 
	C163.682312,2357.120850 174.686890,2356.242676 185.787918,2354.288818 
	C193.793579,2352.879639 202.252304,2353.703125 210.476852,2355.437256 
	C194.227554,2350.709473 178.423050,2356.312012 162.409180,2357.105957 
	C151.266708,2357.658691 140.173965,2353.433594 129.005875,2357.111328 
	C126.984314,2357.777100 125.345573,2356.014404 124.716896,2353.100098 
	C127.081924,2347.566162 131.328690,2346.429932 135.923355,2346.391602 
	C132.165665,2345.507568 129.222946,2347.828857 125.681793,2350.291748 
	C121.008598,2353.876465 115.851448,2355.157227 112.119659,2359.432617 
	C108.078033,2364.062744 101.835999,2363.247314 96.181755,2362.380615 
	C93.411835,2361.956055 90.995422,2360.656982 90.659927,2357.518066 
	C90.240585,2353.594482 92.538498,2351.840332 95.732582,2351.001465 
	C91.126610,2352.210449 85.777733,2352.634033 86.035225,2360.540039 
	C81.556053,2363.092041 77.932320,2362.129639 73.781311,2359.337402 
	C70.517189,2355.276367 69.406570,2351.443115 70.817055,2346.331299 
	C74.639259,2341.563232 79.193489,2341.635742 83.878548,2342.022949 
	C90.395462,2342.560791 96.903900,2343.847412 104.188103,2342.129395 
	C119.709763,2337.337158 135.046906,2336.498047 147.672943,2335.593262 
	C131.158157,2335.343994 112.523026,2340.902344 92.572533,2340.578125 
	C88.761383,2340.018555 85.793777,2339.224854 82.401260,2337.389648 
	C77.803177,2330.590820 79.122551,2325.362793 84.221550,2321.573730 
	C77.901680,2321.131348 69.535927,2324.672607 65.177757,2314.570801 
	C68.030853,2309.461670 74.585190,2313.481445 77.031189,2307.876465 
	C76.084839,2303.987305 73.306976,2304.923340 71.242645,2304.996094 
	C54.394810,2305.590332 37.563545,2303.973145 20.701275,2304.825928 
	C14.905572,2305.118896 8.738062,2306.530029 2.617740,2302.501465 
z"
        />
        <path
          fill="#05121F"
          opacity="1.000000"
          stroke="none"
          d="
M2770.438965,3673.359375 
	C2767.164795,3674.651367 2764.319824,3675.312012 2760.737305,3675.986328 
	C2755.894531,3679.526123 2751.375000,3677.980713 2747.946777,3675.672119 
	C2732.306641,3665.139648 2713.400146,3662.521729 2696.940918,3654.070801 
	C2694.488037,3652.811523 2691.813965,3652.639160 2689.152100,3653.265137 
	C2675.121582,3656.563232 2660.618408,3654.244629 2646.517822,3656.912354 
	C2641.630615,3657.836670 2636.688721,3655.656494 2631.672607,3656.273438 
	C2630.406982,3658.988281 2633.137451,3659.660889 2634.043945,3661.138916 
	C2635.750244,3663.920410 2636.618408,3666.777100 2634.395996,3669.433594 
	C2631.945068,3672.363037 2628.810059,3670.971191 2626.188965,3669.610840 
	C2617.636963,3665.172607 2608.381104,3661.565186 2599.511230,3658.947510 
	C2584.396240,3654.487061 2568.681396,3647.807373 2552.139648,3654.122314 
	C2551.169922,3654.492920 2550.209229,3654.039551 2548.866211,3652.858154 
	C2546.902588,3648.278564 2545.740967,3644.251953 2546.503662,3639.229004 
	C2551.031738,3633.489990 2557.705566,3635.110352 2563.531982,3632.319092 
	C2558.343018,3628.660889 2551.323242,3630.407471 2546.585693,3625.199707 
	C2546.677490,3612.033691 2547.471436,3599.563232 2547.043945,3586.211670 
	C2546.887207,3582.516846 2546.145508,3579.762695 2545.636230,3576.104980 
	C2544.988525,3567.125244 2544.023193,3558.938721 2548.529053,3550.573730 
	C2556.487061,3545.636719 2563.360596,3549.410156 2571.070801,3551.748535 
	C2577.368164,3557.858154 2584.686279,3556.457031 2591.434326,3556.374756 
	C2596.949707,3556.307617 2601.620361,3557.401367 2606.599365,3559.942871 
	C2619.938477,3566.750732 2634.517578,3566.498291 2648.513184,3563.409668 
	C2658.083008,3561.298096 2666.879883,3561.353760 2675.921143,3564.242920 
	C2679.802246,3565.483398 2683.661377,3566.513428 2687.593994,3564.792725 
	C2704.863037,3557.238770 2720.051758,3564.132324 2735.124023,3571.625000 
	C2738.425781,3573.266602 2742.089355,3575.329102 2741.107178,3579.776855 
	C2739.953369,3585.002930 2735.408447,3584.444580 2731.387451,3584.250977 
	C2730.396484,3584.203369 2729.414795,3583.949219 2728.428955,3583.820801 
	C2736.767822,3588.004150 2746.193115,3587.239746 2754.993164,3589.332275 
	C2756.640869,3589.723877 2758.621338,3589.431396 2759.332275,3587.704346 
	C2762.389648,3580.278320 2768.701660,3581.587646 2774.838623,3581.324219 
	C2768.839600,3577.667480 2762.126465,3576.104248 2755.379639,3574.549316 
	C2751.786865,3573.721191 2747.689453,3572.650391 2747.353760,3568.269287 
	C2747.018311,3563.891113 2751.434326,3563.737305 2754.999023,3562.180176 
	C2778.582275,3566.175537 2801.997070,3560.883057 2826.041504,3566.203125 
	C2823.999756,3563.201660 2821.371338,3562.646484 2819.264648,3560.388672 
	C2817.750488,3557.733398 2817.699219,3555.611572 2818.697754,3552.664551 
	C2820.544434,3549.923340 2822.871826,3549.323242 2825.722656,3547.959229 
	C2838.595947,3546.936035 2850.438965,3548.329346 2862.979248,3551.232422 
	C2872.501221,3552.429932 2880.897217,3555.535400 2890.628662,3555.148438 
	C2892.550049,3555.212402 2893.638916,3555.324463 2895.507812,3555.714844 
	C2916.236572,3563.288818 2936.312988,3570.258301 2956.622314,3576.557373 
	C2961.792969,3578.161377 2966.323486,3578.381348 2969.922852,3573.382080 
	C2971.813232,3570.756592 2974.769043,3569.671143 2977.909424,3570.447754 
	C2991.519043,3573.812500 3005.602051,3573.997314 3019.293701,3576.763428 
	C3021.258057,3577.160156 3023.182373,3577.676758 3025.387939,3579.497314 
	C3026.000000,3614.041748 3026.000000,3648.083740 3026.000000,3683.062744 
	C3013.902100,3688.370361 3002.162109,3685.650635 2991.636230,3680.063232 
	C2976.708984,3672.139404 2962.111572,3663.502197 2946.995117,3655.959717 
	C2939.771484,3652.355225 2931.101807,3651.930176 2923.126953,3650.113525 
	C2906.645752,3646.359863 2889.811279,3645.666504 2872.719238,3645.977783 
	C2854.786865,3646.304688 2839.141357,3656.499756 2820.473145,3654.314697 
	C2821.522949,3660.739258 2825.932861,3661.254395 2829.426758,3661.936768 
	C2843.123291,3664.611084 2856.124268,3670.278320 2870.194580,3671.307617 
	C2872.857422,3671.502197 2875.408936,3672.322021 2877.762451,3673.663818 
	C2880.639648,3675.304199 2882.113525,3677.729004 2881.776367,3681.062012 
	C2881.441406,3684.370850 2879.498535,3686.552002 2876.476562,3687.693848 
	C2872.891357,3689.048584 2869.257080,3688.406738 2865.660156,3687.606201 
	C2847.769531,3683.622559 2830.580322,3677.280518 2813.304688,3671.369385 
	C2805.022705,3668.535400 2796.772217,3666.187500 2788.167480,3665.545166 
	C2778.641602,3664.833252 2769.038818,3665.070312 2758.966064,3658.328857 
	C2763.073730,3665.176270 2770.677246,3665.744873 2770.438965,3673.359375 
z"
        />
        <path
          fill="#05131D"
          opacity="1.000000"
          stroke="none"
          d="
M504.639954,4033.503418 
	C497.938812,4034.000000 491.877625,4034.000000 484.908203,4034.000000 
	C481.602264,4029.930176 484.753021,4028.877441 487.490021,4027.672363 
	C490.416504,4026.383545 494.101959,4026.594238 496.129486,4023.258789 
	C474.855896,4013.965088 456.620789,4021.722412 438.495087,4033.513672 
	C432.599976,4034.000000 427.199951,4034.000000 420.899963,4034.000000 
	C417.351440,4027.068359 423.693695,4026.458984 427.192413,4024.379150 
	C437.957306,4017.979736 438.124664,4016.594238 427.808746,4010.297607 
	C421.984131,4006.742676 414.229584,4004.447998 416.892120,3994.963623 
	C418.974030,3987.546875 425.286163,3984.551270 436.501923,3985.531982 
	C440.172302,3985.853027 443.781799,3986.119873 447.418976,3985.255371 
	C457.067261,3982.962646 458.489105,3980.025635 454.012939,3971.420898 
	C450.708496,3965.068604 453.237671,3960.584717 461.165131,3960.380615 
	C477.141907,3959.969727 493.004456,3958.504639 508.890594,3956.923828 
	C518.415222,3955.976318 527.037537,3953.462402 535.761658,3946.732422 
	C530.105591,3944.537109 525.169739,3945.440674 520.482666,3944.927979 
	C515.208740,3944.350830 510.526123,3942.708252 508.719482,3937.213135 
	C507.032196,3932.080811 510.125763,3928.477539 513.269958,3924.799072 
	C522.067383,3914.506836 532.565369,3909.265869 546.570190,3910.656738 
	C556.126831,3911.605713 565.810608,3910.715088 575.449219,3909.410889 
	C583.345032,3908.342773 588.870972,3905.116211 593.290588,3898.394775 
	C603.546692,3882.798340 612.609192,3880.864014 630.751099,3887.144287 
	C641.763855,3890.956787 653.558594,3891.799316 665.294678,3890.179199 
	C669.939270,3889.538330 674.537170,3889.000488 679.174255,3890.212646 
	C683.597107,3891.368896 687.539124,3893.170410 688.156982,3898.310059 
	C688.916321,3904.625977 692.904724,3905.578125 698.151733,3904.938721 
	C708.696106,3903.653076 719.503662,3903.445801 728.711243,3896.977051 
	C732.235535,3894.501221 734.922546,3892.100342 732.161926,3887.191162 
	C728.320007,3880.358643 732.603516,3876.789062 738.467773,3875.831787 
	C746.130615,3874.581299 751.818665,3871.125732 757.195007,3865.618408 
	C764.213379,3858.428711 773.830200,3857.638184 783.310974,3858.296387 
	C789.716125,3858.741455 795.932800,3858.370850 802.137512,3856.887451 
	C805.490967,3856.085938 808.607849,3854.971924 809.723328,3851.250000 
	C811.796265,3844.333740 817.515564,3842.726318 823.589478,3841.594482 
	C833.488708,3839.749512 842.783325,3842.101807 851.850098,3845.780518 
	C855.214661,3847.145508 859.203430,3848.371094 859.109741,3852.947266 
	C859.006165,3858.007324 854.602417,3857.891602 851.110352,3858.685059 
	C850.171570,3858.898193 849.193848,3859.068115 848.093567,3860.803955 
	C854.573364,3864.057373 860.836121,3863.143555 866.861145,3860.515625 
	C871.589050,3858.453613 876.318604,3857.655029 881.411804,3857.844238 
	C911.256470,3858.952637 941.258606,3855.940674 970.946655,3860.818359 
	C984.680420,3863.074951 998.261292,3866.355713 1011.581909,3870.387451 
	C1018.478027,3872.474609 1025.224609,3873.318848 1032.232178,3872.205078 
	C1037.405640,3871.382568 1041.888794,3869.347900 1043.490723,3863.723877 
	C1045.590210,3856.352539 1050.785645,3854.363770 1057.424316,3856.470215 
	C1068.910278,3860.115234 1077.187378,3856.088135 1084.425903,3846.584229 
	C1088.155396,3846.474609 1090.087158,3848.343750 1092.497070,3850.842285 
	C1093.671509,3852.906738 1093.910400,3854.418945 1093.655273,3856.716797 
	C1089.631348,3867.037842 1083.843628,3876.574463 1092.953003,3887.199707 
	C1093.578369,3888.254883 1093.791016,3888.705078 1094.229858,3889.877930 
	C1094.834717,3892.623535 1095.088623,3894.655762 1094.934326,3897.495605 
	C1095.198730,3901.388672 1095.082886,3904.484619 1094.191650,3908.287598 
	C1091.437744,3913.327393 1092.498657,3919.072998 1087.846924,3923.215576 
	C1082.478882,3925.169922 1077.579346,3925.775146 1074.922241,3920.664307 
	C1072.372070,3915.759277 1075.591064,3911.828857 1078.772583,3908.219238 
	C1081.343018,3905.303223 1085.050781,3902.764404 1083.155396,3897.971680 
	C1081.088867,3892.745850 1076.171997,3894.064941 1072.108521,3893.112305 
	C1057.328979,3889.647949 1042.718384,3888.494141 1028.064331,3893.908936 
	C1024.993774,3895.043945 1021.414734,3893.923096 1018.236145,3892.956055 
	C994.537109,3885.748291 971.226501,3887.661865 947.897278,3895.063721 
	C937.404968,3898.392822 927.434753,3893.740234 918.043884,3889.975342 
	C904.393982,3884.502686 892.520203,3884.621094 879.846680,3893.413818 
	C868.318298,3901.412109 854.836731,3902.016357 842.008362,3895.129883 
	C837.766602,3892.852783 833.670898,3891.408691 828.839539,3891.354004 
	C809.703796,3891.138184 791.341370,3895.240234 773.337891,3901.180664 
	C768.082458,3902.915283 762.934753,3904.968506 757.379211,3906.061523 
	C767.820129,3908.672363 778.107239,3911.008301 788.813416,3909.338623 
	C792.105286,3908.824951 795.445984,3908.767334 798.765198,3909.220703 
	C803.690735,3909.893066 807.873047,3911.835449 809.308167,3916.999756 
	C810.686401,3921.958008 807.881775,3925.476562 804.005920,3927.803711 
	C799.345886,3930.602295 796.687317,3934.000000 796.655090,3939.741943 
	C796.600342,3949.517090 788.013367,3951.277832 780.791138,3955.259277 
	C766.976807,3957.298340 753.728333,3956.762207 740.673706,3960.179688 
	C729.347046,3963.144775 718.490601,3966.964600 707.772766,3971.484619 
	C702.650696,3973.644775 699.519714,3976.778564 700.041321,3982.768311 
	C700.783142,3991.286133 695.875854,3994.907227 688.379700,3997.590088 
	C675.118103,4002.336426 662.016052,4004.032471 647.703308,4003.734131 
	C616.815796,4003.090332 586.193359,4008.362549 555.561646,4012.201660 
	C550.214233,4012.871826 544.857361,4014.801758 541.101868,4018.496826 
	C531.052673,4028.384033 519.063904,4032.724854 504.639954,4033.503418 
z"
        />
        <path
          fill="#010A16"
          opacity="1.000000"
          stroke="none"
          d="
M1086.588623,3847.550293 
	C1083.159912,3848.337158 1082.022339,3850.089844 1082.019775,3852.786865 
	C1082.015137,3857.764404 1079.387207,3859.526611 1074.567871,3860.123535 
	C1067.950684,3860.943359 1061.735474,3861.557861 1055.177979,3858.917236 
	C1049.483032,3856.623779 1047.314819,3858.340332 1047.234131,3864.365723 
	C1047.160889,3869.824707 1043.620728,3872.291992 1039.678467,3873.660400 
	C1030.058228,3876.999268 1020.154480,3877.908691 1010.608032,3873.233154 
	C1006.324280,3871.134766 1002.085632,3869.593750 997.243225,3869.966553 
	C994.576538,3870.172119 991.946533,3869.833496 989.418884,3868.762695 
	C974.721497,3862.536865 959.184448,3861.953857 943.554504,3861.990723 
	C939.239014,3862.000732 935.005676,3861.886475 930.711487,3860.717285 
	C925.564758,3859.315674 920.136414,3859.497070 914.887024,3860.735352 
	C906.656799,3862.677002 898.462280,3861.588135 890.168030,3861.321533 
	C882.726624,3861.082275 875.056641,3858.990479 867.573914,3863.875244 
	C859.843079,3868.922119 848.321777,3866.584473 840.733582,3859.896729 
	C843.909607,3856.278809 846.981812,3853.441406 852.438049,3855.632568 
	C854.208679,3856.343506 856.656067,3856.157959 857.302124,3853.619629 
	C857.983948,3850.940918 855.714478,3850.435303 853.854553,3849.707031 
	C842.261047,3845.167725 830.649658,3841.543701 818.020142,3845.881104 
	C814.383301,3847.130371 811.704651,3848.167969 813.248169,3852.662109 
	C814.419739,3856.073242 812.649902,3857.627197 809.618958,3858.366455 
	C800.936035,3860.484619 792.341309,3863.251709 783.184998,3861.429443 
	C777.527527,3860.303223 771.972900,3861.781494 766.591858,3863.009277 
	C762.438232,3863.957275 758.250977,3866.517822 756.443115,3870.495850 
	C753.778137,3876.360596 749.262878,3877.790283 743.623657,3878.022217 
	C741.317078,3878.117188 738.954041,3878.273193 736.745300,3878.874023 
	C734.877991,3879.382324 732.780762,3880.322998 732.574890,3882.653809 
	C732.335999,3885.357910 734.565247,3885.661377 736.539490,3886.110596 
	C739.072632,3886.687012 739.624817,3888.225098 738.638123,3890.540039 
	C737.048706,3894.270020 734.838135,3897.142822 731.260254,3899.558838 
	C722.035339,3905.789062 711.572571,3906.742920 701.093201,3907.970459 
	C698.787659,3908.240723 696.431824,3908.081543 694.126160,3908.351074 
	C686.895508,3909.196777 684.331299,3907.394287 685.267578,3900.010742 
	C685.890442,3895.098633 683.838257,3894.536133 680.209351,3893.584717 
	C675.946167,3892.466797 671.991760,3891.727051 667.533325,3893.034424 
	C660.732117,3895.029297 653.601196,3894.384033 646.710449,3893.553467 
	C642.459961,3893.041016 638.291931,3891.838867 633.919922,3891.935303 
	C632.301331,3891.970947 629.931091,3892.015869 629.169006,3891.053711 
	C625.262207,3886.124756 620.592163,3887.432129 615.173096,3887.206055 
	C605.897095,3886.818359 600.886292,3891.352051 597.166321,3898.028564 
	C592.313782,3906.737549 586.752930,3911.622559 575.680481,3912.681641 
	C562.685852,3913.924072 549.803650,3913.565186 536.885010,3913.868408 
	C531.527893,3913.994385 527.226440,3915.079834 523.635010,3919.251953 
	C521.044678,3922.260498 518.013000,3924.936279 514.935059,3927.464355 
	C511.913605,3929.946289 509.305908,3932.739746 510.780334,3936.734863 
	C512.352112,3940.993408 516.209656,3942.199463 520.587952,3942.020996 
	C523.913513,3941.885498 527.250244,3942.053711 530.580200,3941.988770 
	C534.970398,3941.903320 539.071350,3942.737549 543.467651,3945.778320 
	C532.000427,3954.590088 520.266479,3960.758057 505.588409,3960.143311 
	C500.708923,3959.938965 495.728973,3961.183105 490.851624,3962.075195 
	C480.498352,3963.968506 470.090698,3963.152100 459.702759,3962.867676 
	C457.428925,3962.805420 455.727386,3963.090088 454.793243,3965.171631 
	C453.651733,3967.715576 454.737366,3969.549561 456.852875,3971.137695 
	C458.893738,3972.670166 463.766296,3970.764160 462.894806,3975.788086 
	C462.129883,3980.197998 460.384491,3984.331299 455.824585,3985.960938 
	C449.673187,3988.159912 443.654083,3991.212158 436.625977,3988.689941 
	C433.937561,3987.725342 430.675415,3987.883057 427.742493,3988.186035 
	C423.175659,3988.657715 418.534180,3994.075928 418.348999,3998.504883 
	C418.213470,4001.745850 420.290344,4003.611328 422.913452,4004.300781 
	C428.593262,4005.794678 432.653870,4009.867432 437.424591,4012.777100 
	C443.362732,4016.398926 443.272186,4018.855469 436.922089,4022.412354 
	C432.896118,4024.667236 429.293945,4027.744873 424.612549,4028.871338 
	C422.728180,4029.324951 420.913025,4030.446777 420.130859,4033.310059 
	C403.958191,4034.000000 387.916382,4034.000000 370.937286,4034.000000 
	C367.632202,4028.650635 372.138184,4027.843018 375.234009,4026.631348 
	C381.497040,4024.179688 388.059784,4023.741699 394.784729,4024.354248 
	C399.905670,4024.820312 405.225647,4025.483643 410.520203,4022.008789 
	C407.717316,4017.718750 403.511200,4017.340576 399.940887,4017.000977 
	C381.217194,4015.219971 362.633759,4012.518799 343.588165,4014.068115 
	C330.444763,4015.137207 321.477905,4019.015869 318.495789,4033.402832 
	C283.958221,4034.000000 249.916428,4034.000000 214.937317,4034.000000 
	C213.793961,4030.237793 210.573334,4029.439453 208.048798,4027.977539 
	C204.250824,4025.778320 201.842499,4022.315186 202.642197,4018.157959 
	C203.522980,4013.580322 208.036362,4012.479980 212.025497,4012.156738 
	C219.710403,4011.533691 227.513657,4012.790527 234.330841,4015.834473 
	C249.797806,4022.740479 264.946503,4020.477539 279.903503,4015.477051 
	C285.455750,4013.620361 290.154083,4008.934082 288.851837,4001.675293 
	C288.241882,3998.275391 289.878510,3995.840576 291.700897,3993.520508 
	C298.529785,3984.826904 307.002045,3978.907715 318.429749,3978.041016 
	C327.545227,3977.349854 334.448212,3972.709961 340.155457,3965.792480 
	C347.283600,3957.152344 357.034729,3953.645264 367.801941,3952.779297 
	C371.252319,3952.501465 373.823792,3955.135742 376.379700,3957.344971 
	C387.703369,3967.133301 399.488434,3967.105713 410.003571,3956.858643 
	C414.849579,3952.136475 420.045532,3949.530762 426.898895,3949.952637 
	C437.997955,3950.636475 448.898132,3948.299805 459.874908,3947.228271 
	C464.536194,3946.773193 466.414978,3942.510986 467.262543,3938.729736 
	C468.275024,3934.213135 469.975891,3930.938477 474.095978,3928.493408 
	C478.317627,3925.988770 480.564453,3921.986084 480.041290,3916.706055 
	C479.478363,3911.024658 481.894592,3907.982178 487.964996,3907.826660 
	C490.537079,3907.760742 493.250244,3906.857178 495.606140,3905.724609 
	C511.210602,3898.222656 527.654480,3903.356934 543.678284,3902.333496 
	C551.308838,3908.135010 559.481995,3906.000000 567.512329,3903.705322 
	C573.487976,3901.997803 577.916138,3898.639893 580.131348,3892.179932 
	C582.673584,3884.766846 587.770569,3879.500977 596.023193,3877.864502 
	C600.915833,3876.894775 605.433228,3874.446777 609.272766,3871.452881 
	C614.617004,3867.285400 620.462402,3865.743652 626.933655,3866.048096 
	C631.728088,3866.273926 636.205750,3865.240234 640.515564,3863.394287 
	C643.388123,3862.163574 644.408997,3859.773682 643.221863,3856.828857 
	C641.160522,3851.715332 638.072144,3847.523926 632.452026,3845.848877 
	C626.084717,3843.950684 619.615784,3842.575195 612.988098,3842.003906 
	C611.332581,3841.861084 609.589600,3842.038574 608.034607,3841.573975 
	C605.139832,3840.709473 602.138000,3839.600586 602.270264,3835.739502 
	C602.416443,3831.471680 605.266785,3830.374512 608.989807,3830.011475 
	C627.291382,3828.225342 644.633179,3831.280029 661.130127,3839.538330 
	C671.102112,3844.530518 681.693237,3847.021973 692.981506,3846.049316 
	C696.618958,3845.735840 700.333374,3845.709473 703.966248,3846.048584 
	C722.132141,3847.744629 739.560120,3843.236328 757.068054,3839.702393 
	C762.001770,3838.706299 766.824707,3837.954102 771.843140,3837.971436 
	C791.831238,3838.040283 811.822632,3837.791992 831.806824,3838.081299 
	C842.958557,3838.242676 854.042603,3840.227539 865.242493,3839.870361 
	C866.570618,3839.828125 867.970398,3840.075195 869.214417,3839.737305 
	C872.394043,3838.873535 876.274841,3838.211914 877.025879,3834.499512 
	C877.722778,3831.054443 874.527344,3829.207275 871.824768,3828.028809 
	C866.580750,3825.741455 864.477722,3818.401123 857.289551,3819.204834 
	C856.130493,3819.334229 855.059326,3817.824707 854.883118,3816.395264 
	C854.641785,3814.437012 855.833191,3813.149170 857.466248,3812.612305 
	C861.498108,3811.286133 865.416199,3808.904541 869.945251,3810.959717 
	C872.475525,3812.107910 875.098755,3812.306152 877.640869,3810.909912 
	C890.038940,3804.101562 903.408447,3803.839355 917.078430,3804.569092 
	C926.487000,3805.071289 936.782593,3803.472412 943.031921,3797.095215 
	C950.693115,3789.276855 959.985291,3787.075928 969.222595,3784.292725 
	C984.179382,3779.787109 999.555664,3776.989502 1015.356873,3777.997559 
	C1024.031860,3778.551025 1032.560181,3777.356445 1041.113281,3776.060547 
	C1047.801270,3775.046875 1049.741333,3771.991211 1046.735107,3766.174561 
	C1041.494751,3756.035645 1041.494751,3756.034912 1053.423706,3756.000977 
	C1055.422485,3755.995117 1057.421509,3756.012939 1059.420166,3755.998047 
	C1068.573853,3755.929199 1068.604492,3755.930664 1070.020386,3746.556396 
	C1070.272339,3744.887695 1070.632568,3743.367920 1071.549438,3741.898438 
	C1075.148438,3736.130127 1075.104614,3736.111572 1069.616333,3733.618408 
	C1068.101807,3732.930420 1066.216187,3732.551270 1065.173828,3731.414062 
	C1057.375244,3722.903320 1046.619751,3723.633301 1036.616455,3721.994141 
	C1027.799072,3720.549316 1018.974670,3719.146240 1010.167358,3717.642578 
	C1008.211975,3717.308838 1006.162781,3715.876953 1004.415466,3716.229980 
	C989.231934,3719.299561 973.066650,3715.131104 958.477173,3722.655273 
	C951.326172,3726.343262 944.116272,3727.325195 936.740540,3722.455811 
	C929.310486,3717.550537 920.306946,3721.575195 912.351807,3718.928955 
	C911.103394,3718.513916 910.189087,3720.135010 909.446167,3721.257080 
	C904.643494,3728.509766 897.354919,3730.971191 889.186157,3732.086914 
	C882.991211,3732.932617 876.959595,3735.243164 870.699158,3731.980225 
	C868.081177,3730.616211 866.238220,3729.195068 866.185974,3726.125000 
	C866.134033,3723.069336 867.612732,3721.067139 870.512817,3720.191162 
	C873.052551,3719.423828 875.679321,3718.031006 878.177307,3718.206055 
	C886.917053,3718.817139 895.232300,3715.323486 903.933777,3715.964844 
	C908.002014,3716.264648 910.982056,3714.914551 912.167053,3710.487793 
	C913.917725,3703.947998 917.475037,3699.270020 924.941223,3698.525879 
	C927.886047,3698.232178 930.250366,3696.776611 931.929260,3693.673096 
	C935.438477,3687.185547 938.179138,3686.381348 944.496765,3689.828613 
	C947.165588,3691.284668 950.101868,3691.896240 953.342773,3693.711426 
	C954.732361,3694.739258 955.274841,3695.653076 955.142151,3696.641357 
	C953.658264,3707.690918 955.881165,3710.243408 967.132935,3709.923340 
	C970.438293,3709.829346 973.716980,3709.454834 977.044800,3709.789551 
	C979.180115,3710.004639 982.320007,3711.129395 982.868591,3708.004150 
	C983.447632,3704.705322 980.362244,3703.695312 977.716736,3703.112549 
	C973.511902,3702.186279 969.310547,3701.242188 965.117432,3700.268555 
	C962.278503,3699.609375 958.929260,3699.375977 959.564392,3694.561523 
	C967.289246,3694.484375 974.030579,3693.239502 980.776550,3689.888428 
	C987.451111,3686.572266 995.245911,3687.646484 1002.580200,3687.084473 
	C1002.908997,3687.059326 1003.315857,3688.052979 1003.844421,3689.286133 
	C1004.402527,3691.696045 997.234924,3693.873779 1004.057312,3694.995850 
	C1013.957275,3696.624023 1022.207703,3700.731689 1028.870361,3708.312988 
	C1031.579956,3711.395996 1035.516479,3712.884521 1039.616089,3712.787354 
	C1055.906250,3712.401855 1069.987061,3718.501953 1083.891602,3727.520508 
	C1085.364380,3728.389404 1086.095703,3729.041016 1087.177246,3730.396484 
	C1088.759033,3733.256348 1088.962280,3735.799805 1090.010986,3738.851562 
	C1090.278076,3740.550781 1090.307251,3741.524658 1090.154419,3743.205811 
	C1087.098755,3749.643066 1084.517456,3755.364014 1090.950439,3761.423340 
	C1091.938232,3763.357910 1092.209229,3764.765869 1092.192627,3766.955078 
	C1092.026611,3768.157471 1091.913452,3768.630127 1091.520996,3769.772705 
	C1087.417847,3776.726318 1083.161621,3782.414795 1076.382568,3786.098633 
	C1071.715698,3788.635010 1067.284302,3789.862793 1061.463135,3788.336182 
	C1059.757812,3788.250000 1058.780029,3788.149170 1057.067139,3787.848145 
	C1050.078857,3786.532959 1043.962769,3786.946777 1037.651855,3787.993408 
	C1027.177002,3789.730957 1016.512085,3789.699463 1005.999756,3787.980713 
	C995.405212,3786.248291 985.633606,3787.022705 977.167786,3794.551025 
	C972.946960,3798.304688 967.528320,3800.372070 963.064148,3803.820068 
	C961.788208,3804.805420 960.371399,3805.985596 960.790222,3807.894775 
	C961.230896,3809.903564 963.118652,3810.083008 964.693420,3810.329590 
	C969.301453,3811.051025 974.145081,3811.571777 978.549316,3810.474365 
	C994.048462,3806.612549 1010.190308,3808.195557 1026.372192,3804.162109 
	C1030.976929,3802.609619 1034.906860,3801.541016 1039.795288,3801.703613 
	C1043.329224,3802.153564 1046.031494,3803.643311 1049.686279,3802.358643 
	C1051.419189,3802.238281 1052.407104,3802.353516 1054.076050,3802.910645 
	C1055.599365,3803.835938 1056.267334,3804.587158 1056.973877,3806.205566 
	C1056.459717,3813.107666 1051.472168,3812.487549 1047.343506,3812.939697 
	C1032.483521,3814.566406 1018.303101,3819.688721 1003.442932,3821.367920 
	C1000.622864,3821.686768 996.682190,3822.709961 996.681580,3826.154541 
	C996.680847,3829.951660 1000.962219,3830.263672 1003.601868,3830.208740 
	C1015.881287,3829.953369 1025.584717,3835.122070 1035.538696,3841.909668 
	C1044.112793,3847.756348 1055.151123,3847.541504 1065.549805,3846.506836 
	C1070.341064,3846.030273 1072.675171,3843.269531 1071.736694,3838.275635 
	C1071.369263,3836.320068 1069.841553,3833.890869 1070.769897,3832.494629 
	C1077.380371,3822.552734 1072.621338,3815.623291 1065.244385,3809.106201 
	C1064.633423,3808.566650 1065.045166,3807.193604 1065.792725,3805.599609 
	C1074.282837,3801.365234 1081.389893,3804.976318 1089.259033,3808.244629 
	C1091.071533,3809.900879 1091.610107,3811.344238 1091.725098,3813.684326 
	C1089.803833,3819.267334 1086.681030,3823.933350 1089.623169,3830.136963 
	C1090.666382,3833.059814 1090.743164,3835.429932 1090.740234,3838.538574 
	C1090.662842,3840.251465 1090.522217,3841.222656 1090.084961,3842.893799 
	C1089.276611,3845.006836 1088.399292,3846.186279 1086.588623,3847.550293 
z"
        />
        <path
          fill="#748B9F"
          opacity="1.000000"
          stroke="none"
          d="
M412.692108,2242.166504 
	C407.680237,2241.881104 403.261322,2241.653564 399.561157,2244.259033 
	C395.990540,2246.773193 392.551819,2243.771484 388.997559,2244.561768 
	C376.120880,2247.425537 363.061432,2248.581055 348.937622,2248.009277 
	C345.236450,2249.780273 342.295746,2247.046631 338.838867,2248.513428 
	C335.704742,2249.136475 333.465576,2250.421143 330.115204,2250.734619 
	C311.576752,2250.933838 293.865723,2251.346436 275.365906,2250.721680 
	C266.571808,2248.875488 264.243866,2241.600586 262.054108,2235.657715 
	C259.425751,2228.524658 266.536194,2230.905029 269.366577,2229.969727 
	C263.920776,2229.384033 257.869476,2231.829346 251.029327,2230.040527 
	C234.723770,2227.258789 219.287720,2230.988037 203.743759,2230.928223 
	C190.606964,2230.877686 177.443283,2233.447266 164.273117,2232.270020 
	C154.595657,2231.405273 145.307892,2234.834961 135.506989,2233.339111 
	C120.077744,2230.984619 104.454430,2230.018555 94.994530,2230.806152 
	C105.278221,2230.137695 121.599205,2232.690918 137.974686,2233.267578 
	C152.103302,2233.764648 166.397827,2232.796875 180.614944,2232.372559 
	C201.027832,2231.763672 221.348770,2229.023926 242.528198,2230.138916 
	C246.129730,2233.882080 249.549316,2235.699707 254.288101,2235.534180 
	C257.631989,2235.417236 261.155945,2236.627197 261.172455,2240.991455 
	C261.188812,2245.311768 257.692719,2246.288086 254.354385,2246.739990 
	C246.182510,2247.846436 238.052353,2249.261230 229.740799,2249.284668 
	C224.648041,2249.299072 219.284286,2249.213135 215.124405,2253.053711 
	C217.765137,2258.147949 232.047546,2252.506348 224.494232,2265.464355 
	C211.018906,2262.464844 198.339661,2265.417480 184.821014,2267.856934 
	C181.800980,2266.052490 180.300674,2267.396973 178.431763,2269.528564 
	C175.214813,2269.975098 172.441376,2269.979736 168.835907,2269.977539 
	C166.984116,2269.186768 165.877319,2268.482178 165.716003,2266.577637 
	C165.990387,2265.990234 166.000000,2266.000000 165.996918,2265.996094 
	C172.923874,2249.972900 185.952850,2257.458496 196.214645,2257.558105 
	C194.324127,2256.799072 191.246368,2257.165527 188.342117,2256.412842 
	C185.407745,2255.653076 182.364914,2255.932617 179.379959,2256.019043 
	C175.792740,2256.122803 172.936600,2254.914795 170.492447,2252.314697 
	C162.553818,2243.869141 152.977142,2242.145752 142.123764,2245.404785 
	C138.610138,2246.459717 134.933182,2246.786133 130.616425,2246.318359 
	C127.339432,2246.039551 124.750244,2246.200439 122.116150,2246.861572 
	C118.762543,2247.703613 112.979103,2250.725830 116.739578,2242.394531 
	C117.307144,2241.137207 115.477608,2240.019287 114.114204,2240.484131 
	C106.325317,2243.138428 98.296013,2241.528809 90.392174,2242.027832 
	C88.750771,2242.131592 87.076088,2242.272461 85.502319,2242.706299 
	C84.222916,2243.059082 83.189804,2244.012695 83.131279,2245.524170 
	C83.061943,2247.314941 84.494064,2247.682373 85.769272,2247.850098 
	C91.371651,2248.587402 97.107506,2248.770752 98.876221,2255.985352 
	C99.409920,2258.162598 102.100441,2257.822754 104.062286,2258.009033 
	C107.564621,2258.341797 111.254013,2257.257812 116.020935,2259.545410 
	C107.404861,2262.062012 99.907921,2262.042969 90.864113,2262.082520 
	C96.094261,2265.564941 99.852028,2268.101562 105.045517,2268.337402 
	C107.894051,2268.467041 110.875290,2269.783691 113.426018,2271.222412 
	C116.061035,2272.708252 116.524635,2275.542236 115.136993,2278.203857 
	C113.980186,2280.422607 108.078903,2280.869873 107.299309,2278.533203 
	C104.633644,2270.542969 102.485832,2272.869629 96.483002,2276.309082 
	C90.589058,2279.686279 83.731567,2283.826904 75.266594,2282.284668 
	C67.573471,2280.883057 59.378792,2282.640137 50.803040,2280.004883 
	C46.568295,2278.338867 43.467316,2276.545898 49.233650,2273.968994 
	C52.661606,2272.437256 56.360561,2272.914062 57.833366,2273.134766 
	C45.498234,2272.862793 31.009754,2273.509277 16.528030,2273.205811 
	C11.962690,2273.110352 7.207402,2273.860352 2.549058,2270.471680 
	C2.000000,2266.571289 2.000000,2263.142578 2.000000,2258.856934 
	C5.685263,2254.931885 8.709030,2257.793457 12.056835,2258.941162 
	C18.083830,2261.007812 24.259951,2262.958252 29.132818,2256.437744 
	C30.409695,2254.729248 32.330811,2253.941406 34.381332,2254.110352 
	C45.306774,2255.009521 55.605011,2249.843262 63.868420,2250.763184 
	C49.495098,2251.755127 32.713497,2255.641113 15.569556,2255.184082 
	C11.314463,2255.070801 6.892167,2255.695068 2.548087,2252.472168 
	C2.000000,2250.500000 2.000000,2249.000000 2.000000,2246.750000 
	C5.951649,2242.184326 11.287619,2241.672852 15.979549,2242.133057 
	C31.026924,2243.608398 45.266087,2239.394531 57.739101,2237.137451 
	C44.867352,2240.572266 29.707012,2238.949707 14.638334,2239.189941 
	C10.709899,2239.252686 6.636861,2239.533203 2.584221,2236.487305 
	C2.000000,2234.500000 2.000000,2233.000000 2.000000,2230.750000 
	C7.325855,2226.078613 13.890947,2223.894043 19.814587,2225.197998 
	C35.489685,2228.648682 49.842079,2222.873779 64.643440,2220.408203 
	C70.446320,2219.441650 76.357941,2219.445068 81.264084,2218.171631 
	C76.547020,2217.798584 69.188316,2222.588135 67.443199,2212.210449 
	C67.013344,2209.654297 64.091171,2211.251465 62.434040,2211.311279 
	C48.831688,2211.802979 35.220055,2212.034912 21.626635,2212.841553 
	C15.403221,2213.211182 8.985377,2214.517090 2.561606,2210.475586 
	C2.000000,2209.111084 2.000000,2208.222168 2.000000,2206.666504 
	C12.780439,2200.556396 24.715643,2202.811523 36.047573,2201.012939 
	C41.585751,2200.133545 47.335358,2200.982910 52.989189,2200.995361 
	C59.777260,2201.010254 66.421753,2201.068359 68.264053,2192.077881 
	C68.923714,2188.858643 72.273415,2187.336670 75.461235,2188.168213 
	C82.372849,2189.971924 89.134537,2187.616699 95.086876,2187.951904 
	C89.707993,2187.116211 83.477737,2190.422119 77.185524,2187.992188 
	C73.183754,2186.446777 71.631500,2184.422363 74.952446,2180.705811 
	C77.428963,2177.934326 85.186554,2153.206543 85.127594,2148.572021 
	C85.570305,2147.512451 85.788506,2147.112549 86.420807,2146.193848 
	C86.747437,2144.549805 91.258720,2144.584229 86.995232,2142.394043 
	C85.509567,2141.907959 84.712860,2141.469238 83.479446,2140.388672 
	C82.317581,2138.434814 82.189095,2136.974854 82.838547,2134.776855 
	C83.853149,2133.371338 84.662338,2132.825684 86.313972,2132.369629 
	C91.424286,2132.777344 95.669685,2132.089355 100.260857,2130.651611 
	C97.927681,2130.057373 96.048233,2130.128906 93.477036,2130.606934 
	C88.856537,2131.351318 84.945877,2132.695312 80.934097,2131.759766 
	C78.262016,2131.136719 75.805244,2129.735352 75.354576,2126.716309 
	C74.845284,2123.304443 77.602928,2122.157959 80.076378,2121.186523 
	C87.866776,2118.127441 96.277504,2118.681885 104.606163,2116.575684 
	C105.627487,2111.219482 104.670082,2105.416992 111.332390,2104.042969 
	C117.648308,2102.740479 121.079163,2106.813965 124.055946,2111.492188 
	C126.687836,2115.628418 128.645493,2120.563965 135.851395,2120.836182 
	C133.510178,2117.802002 131.529465,2114.628174 135.860641,2112.437256 
	C138.916763,2110.891357 142.280823,2111.034180 146.316696,2111.025635 
	C148.034409,2111.115479 149.024750,2111.165283 150.780090,2111.282227 
	C152.577362,2111.483398 153.569931,2111.761963 155.191116,2112.576172 
	C156.202896,2113.352051 156.547745,2113.729492 157.210220,2114.795410 
	C158.021835,2126.892578 156.998184,2127.962646 146.074707,2129.379150 
	C136.136749,2130.667236 125.854965,2130.979980 115.915131,2137.938965 
	C122.748825,2139.426758 127.110100,2136.012451 130.946426,2133.945312 
	C141.972366,2128.004150 152.843521,2130.574951 163.835358,2132.702637 
	C167.060486,2133.326660 170.298523,2135.397949 173.496490,2134.362061 
	C185.861252,2130.356689 197.544922,2132.477295 209.336685,2136.892090 
	C215.490372,2139.195801 221.687439,2138.925049 226.583633,2133.149902 
	C228.841461,2130.486572 232.356140,2130.209473 235.747772,2129.984131 
	C240.398270,2129.675293 245.031540,2129.117676 249.291321,2125.278076 
	C250.906952,2124.674561 251.881653,2124.582031 253.572571,2124.895264 
	C264.646973,2136.705811 262.364685,2145.923096 247.929092,2150.299561 
	C242.156693,2152.050049 236.280975,2152.815186 230.181763,2151.848877 
	C221.259369,2150.435547 212.238297,2151.172852 203.257217,2151.164062 
	C200.590317,2151.161377 197.879745,2151.398438 195.738556,2153.296143 
	C186.439438,2161.537354 176.729309,2158.592041 167.004807,2154.338867 
	C164.802750,2153.375488 162.642029,2151.895264 163.453873,2149.097412 
	C164.214676,2146.475098 166.587006,2145.929443 169.108246,2146.146484 
	C164.360870,2145.770752 159.795303,2147.174561 155.145279,2147.717529 
	C148.657410,2148.474609 148.752975,2154.555908 146.650864,2158.838867 
	C144.287460,2163.654053 149.028870,2163.245605 151.045547,2164.743652 
	C153.143661,2166.302002 154.109421,2168.362793 153.776917,2170.789307 
	C153.156052,2175.319824 156.064758,2173.653076 158.094711,2173.375732 
	C160.154816,2173.094482 161.830826,2173.717041 161.556625,2169.650146 
	C160.961411,2160.821289 165.355804,2157.611572 174.414246,2157.758545 
	C181.659470,2157.876221 188.878922,2158.878906 196.109100,2156.224609 
	C200.917145,2154.459229 207.118866,2154.609131 208.549500,2160.765381 
	C210.145111,2167.631836 204.442383,2169.614014 198.794266,2170.516602 
	C196.511032,2170.881592 194.147049,2170.849365 191.658478,2172.124023 
	C202.966919,2171.856201 213.899796,2171.312500 224.139908,2170.937744 
	C222.282410,2170.678711 219.683350,2170.385742 217.446487,2168.955078 
	C214.818558,2167.274414 212.569748,2165.159668 213.175919,2161.709961 
	C213.751694,2158.433350 216.771011,2158.332520 219.306702,2157.550781 
	C229.065903,2154.541748 239.337448,2155.680420 248.932556,2156.359863 
	C260.122467,2157.152100 271.211334,2158.877197 282.092621,2157.164551 
	C280.133301,2155.338135 277.120667,2152.635986 280.198914,2149.184814 
	C284.165375,2144.737793 288.456573,2139.946533 295.984619,2144.432861 
	C296.932068,2145.705566 297.259247,2146.540283 297.421936,2148.088379 
	C297.125793,2152.398682 301.099274,2151.695312 302.783997,2154.309082 
	C302.345276,2164.740723 294.875549,2166.243896 287.489471,2166.832275 
	C270.138580,2168.214111 253.850555,2173.351562 238.005264,2180.070068 
	C233.163406,2182.122803 228.303894,2182.531494 223.374161,2182.030029 
	C216.718475,2181.352783 222.815720,2186.020996 220.612778,2187.872070 
	C217.993134,2190.450439 215.226212,2189.826416 210.656067,2190.145020 
	C220.058502,2189.875488 227.443237,2188.920898 234.858521,2188.388428 
	C238.577545,2188.121582 244.129730,2186.487061 244.366989,2192.191650 
	C244.580841,2197.332520 239.034912,2195.359375 235.859833,2196.367432 
	C259.086975,2191.773682 283.075043,2199.218262 306.529877,2191.130615 
	C310.018219,2185.581299 316.329285,2181.185547 308.150024,2174.825195 
	C305.287415,2172.599365 307.372345,2169.348145 310.801666,2168.027832 
	C311.734436,2167.668457 312.845001,2167.149414 313.701050,2167.458496 
	C326.377197,2172.032227 337.288879,2163.424316 349.278046,2162.666992 
	C354.263000,2162.352051 359.784454,2161.946045 362.564941,2155.816895 
	C363.866638,2152.947266 367.714813,2153.356445 371.430145,2153.964844 
	C379.945282,2162.039062 387.654907,2170.111572 398.973206,2172.961426 
	C394.465912,2172.513428 392.911774,2168.437988 390.087463,2165.811523 
	C386.938141,2162.883057 380.885162,2162.630615 382.023407,2155.482666 
	C384.321747,2146.827393 375.750977,2149.434326 372.864197,2145.482910 
	C368.880219,2139.345947 372.676971,2137.317139 377.122955,2135.793945 
	C380.878754,2134.507080 384.744019,2136.016357 389.807709,2135.806885 
	C381.940735,2133.746582 373.758026,2137.209229 368.426117,2129.176270 
	C367.792908,2127.549805 367.609406,2126.565918 367.569946,2124.790527 
	C367.805878,2122.489258 368.356079,2121.084961 369.694702,2119.248779 
	C375.544037,2114.752441 380.451996,2114.922607 384.921631,2120.207520 
	C387.060791,2122.736572 389.428894,2125.044189 393.897614,2125.539062 
	C393.892700,2122.156494 389.914459,2120.028076 391.355804,2116.124512 
	C395.113159,2112.834961 398.725006,2115.068359 402.269012,2115.069580 
	C407.630066,2115.071289 411.819000,2113.886719 409.335846,2106.869385 
	C407.602997,2101.972412 409.052307,2098.142578 414.229309,2096.686035 
	C419.970337,2095.070801 422.933411,2098.368896 424.493835,2103.451416 
	C424.787079,2104.406738 424.911865,2105.418945 425.259186,2106.347656 
	C426.021545,2108.386963 425.595215,2111.360840 428.608490,2111.775391 
	C429.968414,2111.962646 430.773132,2110.468262 431.266388,2109.184326 
	C432.441467,2106.126465 430.983154,2104.262207 428.604675,2102.466797 
	C420.874420,2096.630371 412.601288,2093.210693 402.710175,2095.680664 
	C400.439301,2096.247803 398.092285,2096.301514 394.999084,2095.866455 
	C392.359802,2094.981445 390.319550,2095.218994 388.393005,2094.782959 
	C385.036499,2094.023438 381.603851,2092.744629 381.322357,2088.900391 
	C380.943939,2083.733154 385.328491,2083.775635 389.653595,2083.228271 
	C393.147949,2083.180176 395.856567,2085.602295 398.133728,2084.771240 
	C420.724121,2076.525635 442.508881,2089.533203 464.859253,2087.055908 
	C471.942413,2086.270996 479.094238,2089.207520 486.603729,2087.090088 
	C481.779816,2086.561523 475.206512,2090.922852 473.219238,2084.584717 
	C470.387390,2075.552979 480.004303,2072.892578 485.003174,2066.828369 
	C474.743896,2062.812256 463.961853,2066.035889 454.047485,2063.117676 
	C451.565338,2062.387207 448.710876,2062.918701 446.249939,2061.779541 
	C442.482208,2060.035400 441.144440,2056.772461 443.879211,2053.776855 
	C447.877716,2049.396973 452.594147,2044.975098 459.419708,2047.742920 
	C468.948151,2051.606689 478.865906,2050.902832 488.584259,2050.004395 
	C500.114197,2048.938232 505.432648,2053.990479 503.354218,2065.591064 
	C502.588287,2069.866455 499.801666,2073.476318 499.408966,2078.473145 
	C499.995270,2081.085205 500.625305,2083.017822 502.221619,2085.149902 
	C506.365479,2087.808350 509.815857,2087.888916 513.460144,2084.092285 
	C521.576599,2084.415771 519.332458,2091.072510 521.036255,2095.960449 
	C514.694031,2102.998535 506.741302,2098.136230 499.639343,2099.465332 
	C494.923950,2100.347900 489.320587,2099.409912 484.343903,2099.743408 
	C487.702240,2100.311768 491.373169,2098.580566 495.663361,2100.116211 
	C497.808228,2100.912598 499.067169,2101.761475 500.684235,2103.415283 
	C502.163147,2105.932129 502.328888,2107.935547 501.630249,2110.694336 
	C500.623749,2112.781982 499.215729,2113.341553 497.927643,2114.055420 
	C500.219879,2113.959717 502.317139,2113.423096 504.297363,2110.974609 
	C506.714691,2108.917236 508.800781,2107.659668 511.859711,2106.613281 
	C515.296997,2106.111328 517.667908,2106.952881 520.412964,2108.973877 
	C523.281616,2116.897217 529.626221,2119.099854 536.569824,2121.761719 
	C537.739746,2123.861572 537.797302,2125.407715 536.974792,2127.699219 
	C530.722229,2132.741699 523.915833,2130.839111 517.476501,2131.222412 
	C511.292969,2131.590576 504.845367,2130.591064 499.119263,2135.923828 
	C497.706696,2137.191650 496.832306,2137.832520 495.165192,2138.752197 
	C493.372406,2139.401855 492.302277,2139.471924 490.467773,2139.038574 
	C487.022552,2136.689941 486.118500,2133.812256 486.092285,2129.846680 
	C488.635925,2122.425293 482.977966,2121.293945 478.568481,2118.640381 
	C477.198242,2118.037109 476.401917,2117.734619 474.961273,2117.352295 
	C473.047791,2117.048828 471.759735,2116.903809 469.792908,2116.831299 
	C461.036346,2116.688721 452.672363,2118.101562 445.130402,2112.620605 
	C453.115631,2116.253662 458.369904,2122.074463 462.694885,2129.256592 
	C467.186188,2136.715332 466.343353,2138.774658 457.070648,2139.675293 
	C460.631287,2145.145020 465.957001,2148.058105 472.835266,2149.834473 
	C475.435760,2154.656982 479.839081,2154.379639 483.567780,2155.449463 
	C485.831207,2156.098877 488.228058,2156.762695 488.737518,2159.500244 
	C489.305389,2162.551514 487.291718,2164.479004 485.219360,2166.207275 
	C483.394440,2167.729004 481.213287,2168.562256 478.852661,2168.894043 
	C478.852661,2168.894043 444.870758,2169.539551 444.870758,2169.539551 
	C440.507965,2167.529053 438.475555,2169.039551 436.924774,2173.566406 
	C435.314758,2178.265625 430.740814,2181.566406 426.169769,2181.411377 
	C415.487488,2181.048584 405.200714,2181.809326 396.512909,2186.689697 
	C403.523834,2190.076172 411.308960,2188.409424 419.182831,2184.764648 
	C429.217957,2180.119629 439.121857,2180.073242 448.438141,2186.883301 
	C449.782745,2187.866211 451.241730,2188.680908 452.831573,2189.230713 
	C454.807465,2189.914062 456.856903,2190.275879 458.255280,2188.344238 
	C459.592133,2186.497559 457.943604,2185.263428 456.797729,2184.145508 
	C454.362946,2181.769531 451.637054,2179.414307 453.652649,2175.601807 
	C455.627014,2171.867676 459.377197,2171.330811 463.145081,2171.293945 
	C467.128143,2171.255127 471.091339,2171.565918 475.073395,2170.754639 
	C478.248840,2170.107666 481.824127,2170.978760 482.478058,2174.274170 
	C484.002594,2181.955811 489.568390,2180.828369 494.796906,2180.864502 
	C501.460815,2180.910645 508.140045,2180.576416 514.783569,2181.037354 
	C518.856689,2181.320068 524.239319,2180.584229 524.613586,2186.800293 
	C524.868225,2191.028809 515.146667,2198.629395 511.627808,2196.612793 
	C503.966003,2192.221924 498.811981,2194.255127 492.370544,2199.569092 
	C487.177002,2203.853271 479.901764,2205.712891 472.508148,2202.993652 
	C464.498474,2200.047607 457.493713,2203.013428 451.132080,2207.720215 
	C448.076202,2209.980957 445.953735,2214.945068 441.236328,2212.062500 
	C436.462311,2209.145020 431.923645,2205.513428 431.123260,2199.250732 
	C430.929443,2197.734375 432.256470,2196.064697 430.208771,2194.231445 
	C422.277435,2199.900635 412.914581,2199.274902 403.649017,2199.115967 
	C386.325104,2198.818848 369.001038,2199.069092 351.206696,2199.066650 
	C354.034851,2202.391602 355.822968,2203.733887 360.615601,2201.115967 
	C371.434998,2195.205566 400.462280,2198.871582 406.901917,2205.862305 
	C408.876465,2208.006104 412.010651,2209.959473 410.347717,2213.406738 
	C408.865021,2216.480469 405.495636,2216.754639 402.461334,2216.557617 
	C395.514465,2216.107666 388.707062,2214.733643 382.357880,2212.623535 
	C381.449982,2213.354248 381.244843,2213.943115 381.429535,2214.672852 
	C385.090179,2229.136719 384.926056,2228.787354 370.510986,2230.156738 
	C343.770844,2232.697266 317.113617,2236.129883 290.245483,2237.171387 
	C288.902771,2237.096680 290.145996,2238.646484 289.835754,2237.349854 
	C289.795410,2237.181396 290.098541,2237.053467 290.741241,2237.075928 
	C317.011627,2237.985596 343.268768,2234.849121 369.544220,2236.835938 
	C378.590088,2237.520020 387.463257,2235.615234 396.418610,2234.927246 
	C400.653137,2234.601562 402.288696,2233.233643 401.246338,2228.641602 
	C399.929840,2222.842529 403.867981,2221.439697 408.393646,2222.456787 
	C413.227997,2223.542969 418.834808,2225.025635 420.388855,2230.296143 
	C422.042908,2235.906250 417.278992,2239.169678 412.692108,2242.166504 
z"
        />
        <path
          fill="#010A16"
          opacity="1.000000"
          stroke="none"
          d="
M2546.937256,4034.000000 
	C2546.000000,4032.335205 2545.973145,4030.669922 2546.004395,4029.005615 
	C2546.198242,4018.681152 2546.202637,4018.680420 2556.695557,4017.996582 
	C2558.021729,4017.910400 2559.395752,4018.028564 2560.666016,4017.718506 
	C2563.822021,4016.947754 2564.589111,4014.055420 2562.182861,4011.730957 
	C2559.431885,4009.073242 2555.969727,4008.121582 2552.173340,4007.901611 
	C2547.286377,4007.618652 2544.101074,4005.343750 2543.592041,4000.031982 
	C2543.373047,3997.745605 2542.001221,3995.473633 2542.152832,3993.263428 
	C2543.158447,3978.589355 2541.059570,3964.030273 2539.642578,3949.572021 
	C2538.319580,3936.072021 2539.365479,3922.382324 2536.307861,3909.021973 
	C2535.502930,3905.504395 2535.823975,3901.794922 2536.917969,3898.169922 
	C2539.644043,3889.136963 2541.027344,3888.686523 2548.173828,3895.081543 
	C2549.690186,3896.438477 2551.205811,3897.728027 2553.313965,3897.901367 
	C2557.489746,3898.244629 2560.763184,3900.447754 2564.092529,3902.728516 
	C2565.979492,3904.021484 2568.366943,3905.762451 2570.340332,3905.549805 
	C2579.612549,3904.551270 2587.386719,3910.944092 2596.620361,3910.352295 
	C2599.627441,3910.159912 2603.020264,3912.113770 2605.855225,3913.748291 
	C2622.923584,3923.588135 2641.849854,3928.382812 2660.469971,3933.942627 
	C2665.154541,3935.341064 2667.645508,3939.353760 2671.911865,3941.406494 
	C2678.896973,3944.768311 2685.822754,3947.207764 2693.460938,3948.367920 
	C2704.260986,3950.008301 2714.991455,3952.317627 2725.969482,3951.936768 
	C2734.642090,3951.635986 2741.833252,3956.134033 2749.668945,3958.527100 
	C2750.771729,3958.864014 2752.007812,3960.093018 2752.406494,3961.190674 
	C2754.872314,3967.980225 2760.305664,3968.786621 2766.230225,3967.940186 
	C2777.167236,3966.377686 2787.166260,3970.453613 2797.366699,3972.956299 
	C2802.252197,3974.155273 2806.537598,3973.759033 2810.935547,3971.992920 
	C2816.363525,3969.812744 2816.525635,3967.771240 2812.395264,3963.317871 
	C2809.692871,3960.404053 2805.882812,3960.138916 2802.762451,3958.325439 
	C2799.339355,3956.336670 2794.213135,3953.876709 2795.504150,3950.091553 
	C2796.918213,3945.946045 2802.488525,3948.004883 2806.187256,3948.529541 
	C2809.422607,3948.988770 2812.466797,3950.671387 2815.660156,3951.586914 
	C2819.404297,3952.660889 2823.061279,3954.889160 2827.186768,3952.651123 
	C2827.968506,3952.226807 2829.409912,3952.076660 2830.071045,3952.516113 
	C2837.522217,3957.468750 2845.357666,3954.914307 2853.187012,3954.070801 
	C2859.549561,3953.384766 2865.472656,3954.739990 2871.491943,3957.518311 
	C2879.645508,3961.281494 2888.807617,3961.659912 2897.717285,3961.893066 
	C2903.829102,3962.052979 2909.610840,3962.749268 2914.930908,3965.926025 
	C2916.060059,3966.600586 2917.250732,3967.533447 2918.472656,3967.635498 
	C2932.220703,3968.781250 2944.027100,3976.165771 2957.103271,3979.521973 
	C2976.594727,3984.525146 2995.916748,3990.185791 3015.362061,3995.374756 
	C3018.189697,3996.129395 3021.301025,3995.821045 3025.140625,3996.002441 
	C3026.000000,4002.722656 3026.000000,4009.445068 3026.000000,4017.083984 
	C3013.736572,4024.552490 3002.420410,4018.638916 2991.831299,4014.049316 
	C2974.984375,4006.747803 2958.641602,3999.377686 2939.695068,3998.491699 
	C2928.588135,3997.972168 2917.697021,3993.525635 2906.836914,3990.499512 
	C2892.682617,3986.554932 2881.278564,3993.239746 2869.907471,3998.990479 
	C2864.205811,4001.873779 2870.057617,4004.478760 2872.646484,4005.149658 
	C2882.301270,4007.651855 2891.863525,4010.811279 2901.676025,4012.351074 
	C2910.616699,4013.754150 2919.260010,4015.979980 2927.897949,4018.427734 
	C2934.821777,4020.390137 2941.068115,4023.468018 2948.468994,4017.652588 
	C2953.888672,4013.393555 2969.651611,4023.890137 2972.440186,4033.371094 
	C2830.624756,4034.000000 2689.249756,4034.000000 2546.937256,4034.000000 
z"
        />
        <path
          fill="#091825"
          opacity="1.000000"
          stroke="none"
          d="
M2.660181,3368.484863 
	C2.000000,3355.957764 2.000000,3343.915283 2.000000,3330.936523 
	C12.148292,3324.647705 22.692101,3325.303223 32.669659,3329.583984 
	C38.554832,3332.109131 44.216560,3333.628906 49.977234,3331.533447 
	C65.198380,3325.995850 80.698555,3326.745117 96.290421,3328.519531 
	C99.230942,3328.854004 101.884354,3328.323975 104.093254,3327.891113 
	C93.668121,3327.698242 81.772964,3331.387451 73.752686,3319.749023 
	C72.782906,3318.341797 70.711067,3317.907959 69.160538,3318.429688 
	C58.608250,3321.981445 47.345833,3319.888672 36.629082,3323.002441 
	C25.811640,3326.145752 14.506840,3326.905518 2.608455,3324.483398 
	C2.000000,3306.624756 2.000000,3289.249756 2.000000,3270.937256 
	C19.604500,3270.183105 35.907215,3259.301270 54.371990,3264.757568 
	C63.318027,3267.400635 72.849937,3266.324707 81.922562,3271.300293 
	C89.166893,3275.272705 99.455666,3274.632080 107.351448,3271.634766 
	C119.136276,3267.161133 130.964523,3269.224609 143.537338,3267.968506 
	C141.337601,3261.243652 144.130600,3257.692871 151.231155,3256.940674 
	C168.434326,3255.117676 185.645142,3253.350098 202.788605,3250.980957 
	C207.061157,3250.390381 211.537888,3250.195801 215.737579,3251.058594 
	C232.098618,3254.419922 247.752045,3252.328857 263.965088,3248.469727 
	C277.352417,3245.282959 291.743744,3247.704590 305.639160,3248.841553 
	C312.763458,3249.424072 317.138977,3247.516602 318.048676,3239.172363 
	C320.405640,3237.523193 322.326019,3236.898926 324.327698,3236.819336 
	C340.924774,3236.158447 357.569519,3236.747314 374.119446,3234.733154 
	C377.069458,3234.374268 380.068665,3235.227295 383.765717,3236.319824 
	C389.348846,3239.294189 394.423309,3239.708984 400.416260,3238.272949 
	C402.077820,3237.760010 403.039856,3237.563477 404.713867,3237.350830 
	C407.658478,3237.102295 409.905670,3236.989990 414.278656,3236.988281 
	C410.345490,3236.080078 408.391052,3236.055420 405.715881,3236.027344 
	C404.003479,3235.891602 403.033630,3235.666504 401.370483,3235.015137 
	C395.060455,3230.715088 390.943756,3226.554199 399.505127,3220.395508 
	C403.471649,3218.951904 406.776794,3218.693848 410.957458,3218.987061 
	C415.020844,3219.155518 418.253784,3218.765869 422.286957,3218.893311 
	C425.694641,3219.082520 428.312775,3219.196777 431.454041,3217.563965 
	C439.575195,3213.992432 446.965332,3215.280273 454.372253,3217.227051 
	C458.523285,3218.317871 462.769470,3218.732422 467.855530,3218.780273 
	C470.274750,3218.886963 471.822601,3218.537109 474.119110,3217.771240 
	C478.687958,3216.879150 482.498871,3216.651855 487.161469,3216.904297 
	C506.400726,3212.474609 523.166443,3216.765381 539.182190,3227.292969 
	C539.932861,3234.028564 535.408447,3236.085449 530.643066,3238.804199 
	C529.530762,3239.318115 529.083130,3239.501709 527.959839,3239.909912 
	C525.572632,3240.703613 523.662476,3240.710449 521.626709,3242.342285 
	C520.667908,3242.982910 520.264648,3243.217041 519.209106,3243.724365 
	C516.248779,3244.748779 513.861206,3244.906738 510.729187,3245.025391 
	C480.502777,3254.473389 452.303101,3267.302002 423.209229,3277.768799 
	C414.532532,3280.890381 405.362701,3283.215820 396.946320,3289.038330 
	C388.000336,3291.733154 379.381348,3293.015137 371.044189,3297.654785 
	C344.410339,3306.518311 318.433624,3314.952881 293.011902,3327.070312 
	C289.428009,3328.932129 286.275024,3329.606201 282.316406,3330.452393 
	C279.252930,3330.718994 277.038788,3330.578125 274.138672,3329.462402 
	C269.726929,3325.475586 265.014404,3325.194580 260.349182,3325.359375 
	C244.707611,3325.913086 229.030655,3326.428467 214.719894,3334.049316 
	C212.634460,3335.159912 210.355545,3336.034180 208.017822,3335.714355 
	C193.338745,3333.707520 178.844299,3338.573730 164.200806,3336.825684 
	C158.832001,3336.184570 155.153809,3338.458252 152.671997,3342.892822 
	C149.636429,3348.317139 144.997787,3349.968018 139.004990,3351.329590 
	C109.759415,3357.972656 80.310143,3363.215332 50.328789,3364.113525 
	C34.474083,3364.588623 18.944462,3366.938965 2.660181,3368.484863 
z"
        />
        <path
          fill="#69849B"
          opacity="1.000000"
          stroke="none"
          d="
M3025.452637,2321.523438 
	C3026.000000,2326.083252 3026.000000,2330.166748 3026.000000,2335.125000 
	C3025.533447,2336.477051 3025.026855,2336.905273 3024.493164,2337.302246 
	C3023.126465,2338.318848 3021.458740,2338.413330 3019.891602,2338.816650 
	C3021.470459,2339.897949 3023.648193,2339.630127 3025.525391,2341.553711 
	C3026.000000,2348.061279 3026.000000,2354.122314 3026.000000,2361.091797 
	C3013.872314,2367.651367 3000.795166,2366.407227 2988.118896,2366.382080 
	C2967.187500,2366.340576 2946.310547,2364.033691 2925.362549,2363.423340 
	C2914.901123,2363.118408 2905.303711,2368.105713 2894.788574,2367.062988 
	C2871.927490,2364.795654 2848.960449,2365.622559 2825.236816,2365.125000 
	C2818.425293,2362.821777 2812.373779,2362.707764 2805.950195,2363.228760 
	C2798.336426,2363.846680 2790.497803,2362.762939 2785.984375,2355.148193 
	C2783.416992,2350.815430 2780.467529,2352.229492 2777.675781,2354.184082 
	C2769.187500,2360.127197 2761.458252,2358.723389 2754.332764,2351.993164 
	C2751.639160,2349.449219 2748.875977,2348.447754 2745.324707,2349.477295 
	C2743.088867,2350.125977 2740.494141,2351.186279 2738.500732,2350.280518 
	C2729.209961,2346.060303 2725.644775,2352.051514 2721.694580,2359.228027 
	C2713.913818,2362.108643 2707.218750,2358.283203 2699.570801,2357.858643 
	C2696.811035,2352.611572 2695.342773,2347.302734 2688.401611,2346.934570 
	C2686.554688,2346.836426 2685.211182,2344.577881 2685.187744,2342.388184 
	C2685.156982,2339.530518 2687.225586,2338.378418 2689.577148,2337.765625 
	C2694.135010,2336.578369 2698.806885,2336.963135 2703.443604,2337.018799 
	C2707.765137,2337.070801 2712.088135,2337.243652 2715.616455,2335.297119 
	C2694.044678,2336.969971 2672.078369,2334.536377 2650.519775,2334.678223 
	C2653.717773,2337.071533 2657.509521,2338.905273 2662.547852,2337.922119 
	C2679.380859,2334.638184 2684.865479,2340.451660 2679.985840,2357.210449 
	C2676.818848,2360.010742 2673.683350,2361.148438 2670.333008,2360.892822 
	C2656.941162,2359.870605 2643.413574,2360.346924 2629.358887,2357.267578 
	C2618.317627,2357.510498 2608.108887,2357.182129 2597.901855,2357.292480 
	C2594.938232,2357.324707 2591.447754,2356.429199 2589.127930,2357.889648 
	C2582.392090,2362.129883 2575.410645,2361.727539 2567.332520,2360.643311 
	C2547.531982,2363.518555 2528.479492,2364.126221 2508.511230,2363.249023 
	C2505.419434,2362.872803 2503.164551,2363.017334 2500.137695,2362.659912 
	C2496.857910,2362.026611 2494.533936,2360.733887 2491.134766,2360.979004 
	C2487.644775,2360.957520 2485.660400,2362.783691 2482.584717,2364.116943 
	C2472.872314,2366.722900 2464.055420,2364.022461 2455.279541,2363.296631 
	C2439.156738,2361.962646 2423.086670,2363.058350 2406.169922,2362.632324 
	C2398.959229,2359.210693 2392.335693,2357.679688 2385.576660,2360.943359 
	C2379.239014,2364.003662 2372.651855,2361.068848 2365.454590,2362.469727 
	C2356.290283,2363.257812 2348.349121,2361.254395 2339.875977,2358.309570 
	C2339.184082,2350.532227 2344.818115,2351.377686 2349.103027,2350.594238 
	C2392.857666,2342.597168 2436.692871,2349.261230 2480.490723,2350.063965 
	C2487.175049,2350.186279 2493.653809,2348.420410 2501.227539,2348.941650 
	C2523.241211,2348.730225 2544.338379,2349.639160 2566.298340,2348.458496 
	C2571.099121,2349.694824 2573.460693,2347.213379 2576.622070,2344.409668 
	C2591.330811,2342.018066 2605.359131,2343.474365 2620.129639,2343.009766 
	C2624.684814,2342.720947 2628.724121,2343.861084 2632.553711,2340.711426 
	C2628.256836,2337.840088 2624.962402,2340.180908 2620.895508,2342.039062 
	C2614.860352,2343.302002 2609.541504,2343.434814 2603.537109,2341.883789 
	C2600.680908,2335.595947 2596.767822,2333.734131 2590.397949,2334.997803 
	C2570.878418,2338.869141 2550.642334,2334.814941 2531.190674,2340.094971 
	C2528.315430,2340.875488 2525.284668,2340.579346 2522.631836,2338.694336 
	C2520.080322,2336.880859 2522.152832,2330.858643 2515.972900,2332.129150 
	C2509.708496,2333.416748 2503.520508,2334.267822 2499.560303,2340.100342 
	C2498.818604,2341.192383 2497.776123,2342.052979 2496.089355,2343.313965 
	C2492.820068,2344.898926 2490.065674,2344.805664 2486.473389,2344.571289 
	C2478.481201,2332.509766 2466.714844,2335.629639 2455.994629,2335.422607 
	C2440.367676,2335.121094 2424.740479,2335.355957 2409.103760,2334.901123 
	C2400.025879,2334.636963 2391.120361,2339.392822 2381.650635,2337.450928 
	C2379.717529,2337.054443 2377.652832,2337.304688 2375.706055,2336.841309 
	C2372.795898,2336.148438 2369.270996,2335.606445 2369.431885,2331.736084 
	C2369.562500,2328.583740 2372.639160,2327.623779 2375.287842,2327.287109 
	C2389.485107,2325.483887 2402.880859,2321.483887 2418.213379,2323.941650 
	C2432.655518,2326.255859 2448.465332,2326.609375 2463.584961,2323.880859 
	C2470.184326,2322.690186 2476.796143,2322.438232 2483.454346,2323.343994 
	C2485.750000,2323.656494 2488.124268,2324.115479 2490.337891,2323.063477 
	C2506.304443,2315.474854 2522.305176,2318.998047 2538.690918,2321.795166 
	C2547.551758,2323.307861 2556.977051,2322.619141 2566.144043,2322.857422 
	C2580.080811,2323.219727 2594.068604,2323.742676 2606.159424,2321.886963 
	C2601.821777,2321.548096 2595.760742,2322.840820 2589.633057,2323.123535 
	C2561.653564,2324.414795 2534.151855,2317.631104 2506.164551,2318.595215 
	C2498.038574,2318.875244 2489.607666,2321.013184 2480.716553,2317.506836 
	C2468.580811,2313.248047 2456.310303,2319.590576 2444.345703,2313.725342 
	C2441.707031,2311.915039 2440.197754,2310.124268 2438.737793,2308.332764 
	C2437.472168,2306.779541 2436.506104,2304.907471 2437.601562,2302.847412 
	C2438.969971,2300.273438 2441.499268,2300.303955 2443.702148,2300.991455 
	C2449.498291,2302.800537 2455.389160,2303.293945 2462.270264,2303.050293 
	C2497.005127,2305.399414 2530.908936,2304.402100 2564.799561,2304.457275 
	C2583.402344,2304.487549 2601.978516,2302.162354 2620.621582,2304.318604 
	C2627.544189,2305.119385 2634.409668,2303.660156 2641.319824,2302.016113 
	C2653.679443,2299.075684 2666.238037,2299.512939 2678.809326,2301.993896 
	C2686.138428,2303.440186 2693.807129,2304.974854 2701.396484,2299.978027 
	C2708.412354,2292.779785 2717.165771,2293.798096 2725.176514,2291.981934 
	C2719.095215,2291.033691 2713.134521,2290.562500 2707.643311,2294.760498 
	C2703.308838,2298.074219 2698.615967,2298.701660 2693.704590,2295.804199 
	C2688.958984,2293.004150 2683.369385,2293.495605 2678.312988,2291.951416 
	C2674.872070,2290.900879 2670.607666,2289.854980 2671.308838,2285.028320 
	C2671.950439,2280.614746 2676.068848,2278.831299 2679.641602,2279.719482 
	C2691.245361,2282.605469 2701.827393,2280.232178 2712.521484,2275.747559 
	C2717.080322,2273.836182 2722.554443,2274.287598 2727.262939,2276.037109 
	C2736.737061,2279.557617 2745.388184,2279.803223 2753.012695,2272.052734 
	C2754.345215,2270.698242 2756.722656,2271.036377 2758.036377,2269.440186 
	C2751.397461,2267.450684 2745.874756,2272.077881 2739.815430,2272.451904 
	C2725.947998,2273.307861 2711.996094,2274.789551 2698.240234,2270.633789 
	C2693.763916,2269.281494 2688.225830,2265.946533 2685.620605,2274.198730 
	C2684.735107,2277.003906 2680.451660,2277.188965 2677.525146,2276.699951 
	C2666.745117,2274.898193 2655.741211,2274.819824 2645.054932,2272.417480 
	C2640.893799,2271.481934 2637.896729,2269.070557 2636.567871,2264.839111 
	C2635.714355,2262.123047 2637.786865,2257.279053 2632.041992,2257.982666 
	C2627.110840,2258.586670 2621.609131,2255.620605 2617.492188,2261.515625 
	C2613.377686,2267.407227 2604.731934,2268.966309 2599.118896,2265.328125 
	C2593.218018,2261.503662 2592.087158,2256.033447 2595.825439,2249.176514 
	C2602.684570,2245.626465 2609.492432,2246.016846 2616.005127,2246.454102 
	C2627.227051,2247.207275 2638.285889,2244.580322 2650.215332,2245.842529 
	C2651.560547,2246.445068 2652.303467,2246.733887 2652.557373,2247.285400 
	C2657.448486,2257.908936 2659.327148,2258.535400 2671.250488,2257.922607 
	C2686.079346,2257.160156 2700.967773,2256.650391 2713.839355,2258.680908 
	C2700.458984,2256.843994 2685.100342,2256.873535 2669.750977,2257.960693 
	C2657.757812,2258.810303 2654.725098,2256.830566 2655.837891,2246.947266 
	C2666.834473,2240.119385 2678.352783,2242.748291 2689.184082,2243.699951 
	C2701.280029,2244.762695 2713.363770,2246.086670 2726.354492,2246.793457 
	C2743.314453,2246.321045 2759.409668,2245.274902 2775.533447,2244.961914 
	C2770.578369,2245.105713 2765.630859,2244.847168 2760.677246,2245.718750 
	C2757.023682,2246.361572 2753.384521,2245.519287 2750.257568,2243.370850 
	C2748.275146,2242.008545 2746.828369,2240.147949 2747.332520,2237.519775 
	C2747.759033,2235.295654 2749.582275,2234.413818 2751.485352,2233.701660 
	C2752.422363,2233.351318 2753.403564,2233.045654 2754.382568,2233.040771 
	C2771.887451,2232.951416 2789.452148,2230.847900 2807.656738,2234.673340 
	C2812.329346,2235.030762 2816.295654,2234.224854 2820.707031,2236.335449 
	C2824.717285,2240.593750 2822.211914,2242.743652 2818.956055,2244.404297 
	C2815.339111,2246.249268 2811.341797,2246.911133 2807.333740,2246.943848 
	C2802.159912,2246.985840 2798.260254,2248.664062 2794.008301,2252.127930 
	C2786.606445,2258.157715 2777.093262,2258.522949 2769.010010,2257.925293 
	C2775.746094,2259.297607 2783.607910,2256.022217 2791.611816,2257.184814 
	C2802.933105,2258.829102 2804.732422,2262.349609 2798.935303,2271.894287 
	C2797.559570,2274.159424 2796.061035,2276.330811 2795.434326,2279.005127 
	C2792.955322,2289.583496 2791.958252,2290.081543 2780.518555,2286.884277 
	C2779.238281,2286.511963 2780.720947,2288.768311 2780.532227,2287.448486 
	C2780.494141,2287.181641 2780.800049,2287.101562 2781.423340,2287.295166 
	C2788.390869,2289.459717 2795.743408,2289.953857 2802.657227,2292.505371 
	C2807.713867,2294.371338 2812.880371,2293.615723 2816.059082,2288.833984 
	C2823.296143,2277.948242 2833.489502,2278.750000 2843.576904,2282.304932 
	C2851.395020,2285.060059 2859.477783,2287.063477 2867.092285,2290.508545 
	C2876.832275,2294.915527 2883.284180,2293.150879 2890.494141,2284.983643 
	C2896.336182,2278.366943 2912.600586,2276.060303 2918.725586,2281.932861 
	C2923.860352,2286.856201 2929.188721,2287.298096 2935.200195,2285.665771 
	C2940.930176,2284.109375 2946.132080,2285.489014 2951.770996,2286.582764 
	C2969.082520,2289.940918 2986.706055,2290.635254 3004.340820,2290.907959 
	C3011.209473,2291.014160 3018.362061,2289.276367 3025.441162,2293.520996 
	C3026.000000,2296.133301 3026.000000,2298.266846 3026.000000,2301.200195 
	C3024.168701,2303.742676 3021.891113,2304.323242 3019.529785,2303.893555 
	C3004.376465,2301.138184 2988.944336,2304.080322 2973.769775,2301.503418 
	C2962.117432,2299.524658 2950.768311,2303.865967 2941.823730,2303.642090 
	C2959.371582,2298.357910 2978.720947,2305.635254 2998.403564,2307.778809 
	C3002.214600,2308.193848 3007.303955,2309.564453 3006.657227,2314.640625 
	C3005.910645,2320.501709 3000.923096,2319.708740 2996.394531,2319.060791 
	C2987.052979,2317.723633 2978.269775,2319.386475 2969.089844,2322.139648 
	C2954.808594,2326.422852 2940.404785,2332.893311 2924.717285,2324.984619 
	C2917.070557,2321.129395 2908.388916,2325.939209 2900.326172,2326.782471 
	C2880.858887,2328.819092 2861.427490,2329.878418 2841.904297,2330.093018 
	C2845.053223,2330.082031 2848.220947,2329.840088 2852.099609,2330.603027 
	C2855.239014,2333.308838 2860.265381,2334.618164 2858.215332,2339.172363 
	C2856.702881,2342.531494 2852.401611,2341.936523 2849.225830,2341.900391 
	C2822.375000,2341.594482 2795.470947,2342.675781 2768.849854,2337.358887 
	C2761.443604,2335.879639 2753.616211,2336.627197 2748.244873,2337.723877 
	C2762.332031,2333.727051 2777.739502,2341.386963 2793.704834,2341.549316 
	C2814.259033,2341.758545 2834.825928,2342.644531 2855.355469,2341.412354 
	C2861.168213,2341.063477 2866.897217,2338.874268 2871.968018,2334.111572 
	C2885.264404,2331.428223 2897.252441,2325.985352 2911.073730,2328.727051 
	C2927.336670,2331.953369 2944.252197,2330.274658 2960.761230,2332.580811 
	C2964.100586,2333.047363 2967.185791,2332.026123 2970.164062,2330.080811 
	C2979.209229,2324.172852 2985.666992,2323.995850 2993.569580,2330.812256 
	C2998.731445,2335.264404 3002.483887,2338.050293 3007.194580,2330.815918 
	C3008.042725,2329.513428 3010.346436,2330.192871 3011.322510,2329.847900 
	C3007.782959,2328.819824 3002.389404,2328.702148 3003.577393,2322.624023 
	C3004.541504,2317.691406 3009.335693,2317.773438 3013.422119,2318.266846 
	C3017.338379,2318.739502 3021.468750,2318.522705 3025.452637,2321.523438 
z"
        />
        <path
          fill="#01050E"
          opacity="1.000000"
          stroke="none"
          d="
M504.937317,4034.000000 
	C504.391510,4031.204102 506.113281,4030.095459 508.814484,4030.045166 
	C519.206360,4029.851562 527.552002,4026.162598 535.174988,4018.469482 
	C541.616943,4011.968506 550.632324,4008.662598 560.304138,4007.921631 
	C571.730103,4007.046387 583.090027,4005.341553 594.506836,4004.308105 
	C613.275818,4002.609863 632.012573,3998.249268 650.843140,4000.327881 
	C662.489502,4001.613281 673.018127,4000.853516 683.240540,3995.335693 
	C684.105530,3994.868652 685.092407,3994.577637 686.057251,3994.353271 
	C697.011108,3991.807861 697.012939,3991.815674 696.111755,3980.481689 
	C695.675293,3974.992432 697.719360,3970.846680 703.902161,3969.356689 
	C711.716248,3967.473145 718.170410,3962.799561 725.915649,3960.631592 
	C739.319214,3956.879883 752.883728,3954.422363 766.576660,3952.542725 
	C770.351868,3952.024414 774.246277,3954.525879 779.148010,3953.982910 
	C785.012695,3951.519287 790.383423,3952.840332 795.596863,3952.666748 
	C804.249878,3952.378418 812.987305,3953.404053 821.548767,3951.871582 
	C831.490173,3950.092285 840.190796,3950.846924 845.985718,3960.486328 
	C846.848633,3961.921631 848.254639,3963.311035 849.877930,3963.444092 
	C868.461304,3964.967529 886.220459,3975.718018 905.609497,3968.426025 
	C908.409241,3967.372803 911.458862,3966.805176 914.449219,3966.922363 
	C921.567322,3967.201660 925.863708,3964.959473 928.321167,3957.349365 
	C931.170837,3948.524658 940.127441,3945.877441 948.181335,3943.939453 
	C956.606323,3941.912109 962.682129,3939.105469 964.757080,3929.398926 
	C966.471741,3921.377686 973.877258,3919.866943 980.868530,3921.892578 
	C993.077942,3925.429932 1005.209290,3925.070801 1017.499146,3923.128662 
	C1019.474670,3922.816895 1021.481873,3922.697021 1023.481140,3922.724365 
	C1041.117310,3922.965332 1058.528076,3926.016113 1076.760010,3927.413086 
	C1081.705078,3933.301270 1077.684448,3936.409668 1073.943237,3939.230957 
	C1068.648193,3943.224854 1062.724121,3946.292480 1057.203491,3949.977051 
	C1019.964417,3974.829346 983.095886,4000.226807 946.086914,4025.417725 
	C941.412781,4028.598877 936.756226,4031.734131 930.588318,4033.563477 
	C788.624878,4034.000000 647.249756,4034.000000 504.937317,4034.000000 
z"
        />
        <path
          fill="#728BA1"
          opacity="1.000000"
          stroke="none"
          d="
M2.617447,1912.496338 
	C2.000000,1907.258911 2.000000,1902.517700 2.000000,1896.888306 
	C6.161286,1892.112793 11.376036,1893.142212 16.278135,1893.098877 
	C55.596249,1892.751099 94.918922,1893.588257 135.127502,1892.436890 
	C137.745728,1891.684082 139.506134,1891.571289 142.102234,1891.939209 
	C148.882233,1895.722168 155.320709,1895.702515 162.617966,1894.311035 
	C164.782501,1893.780640 166.252777,1893.734131 168.446808,1893.875366 
	C171.879791,1894.584839 174.582230,1895.473145 178.111145,1894.342285 
	C179.794998,1894.252930 180.753418,1894.365356 182.393433,1894.814941 
	C191.077698,1898.167236 199.049545,1898.381104 207.704559,1894.573730 
	C210.491302,1893.586792 212.597183,1893.564209 215.503998,1894.211792 
	C224.411163,1898.761597 232.854431,1897.448486 242.127747,1895.608398 
	C265.722107,1893.135864 288.500336,1890.599243 312.227356,1894.920532 
	C353.205475,1894.619141 393.211639,1897.767456 433.281677,1897.522339 
	C442.222321,1897.467651 451.178314,1897.115479 460.130219,1897.042114 
	C468.726746,1896.971558 477.329620,1896.814575 486.664948,1898.570801 
	C488.621735,1898.157471 489.732758,1898.022461 491.650116,1898.166748 
	C494.430481,1898.785767 495.951904,1900.077271 498.012268,1901.737549 
	C499.817627,1903.125610 501.124023,1904.031372 503.435059,1902.999634 
	C504.825592,1902.595581 505.650543,1902.523926 507.107544,1902.662720 
	C509.311432,1903.299683 510.870819,1903.951538 513.271606,1903.531006 
	C516.401733,1903.249023 518.699341,1902.354248 521.886597,1901.689453 
	C548.357300,1898.311646 574.104004,1897.645630 600.815186,1899.000977 
	C604.128418,1898.897949 606.536743,1898.304321 609.883667,1898.867676 
	C638.723022,1898.441040 666.637024,1898.193726 695.361389,1901.087402 
	C703.585938,1902.333008 710.932190,1902.947266 719.182556,1901.594238 
	C735.008667,1900.304199 749.988708,1900.173462 765.832458,1901.006714 
	C768.369507,1901.134521 770.064819,1900.987305 772.558533,1901.398193 
	C775.953308,1902.303345 778.500732,1903.234985 782.068176,1903.062988 
	C789.539612,1903.149902 796.217773,1903.321899 802.868286,1902.980591 
	C815.072449,1902.354248 826.088684,1904.777710 837.392456,1910.297729 
	C848.683655,1915.811523 861.086792,1919.680420 874.823120,1915.552124 
	C884.557373,1912.626709 895.126587,1912.227051 905.985962,1910.252686 
	C913.207886,1915.002075 920.048157,1916.864868 928.167603,1912.682617 
	C931.736145,1910.246582 933.535278,1907.063965 936.903198,1904.338867 
	C944.967651,1901.893555 952.340027,1902.118286 960.518066,1903.972168 
	C963.854065,1909.748169 973.409302,1907.876831 973.299927,1916.662720 
	C967.072388,1922.867432 959.512573,1921.461670 952.388672,1921.968872 
	C938.705200,1922.942993 924.408936,1921.850586 914.167419,1933.932739 
	C912.887817,1935.442261 910.775879,1935.957764 908.976685,1936.819580 
	C898.942993,1941.626709 897.364502,1945.456543 897.338257,1956.309448 
	C897.304688,1970.164429 902.884094,1981.636230 910.079346,1992.793945 
	C913.057617,1997.412231 913.010925,2001.591919 906.961365,2003.933472 
	C899.406006,2006.857788 898.473083,2010.476685 903.706421,2016.807251 
	C906.048035,2019.640137 909.391968,2021.412231 911.901794,2024.067749 
	C916.034790,2028.440796 916.659363,2032.186768 909.643494,2034.092041 
	C906.057617,2035.065796 902.797729,2036.767700 899.860718,2038.927734 
	C892.854919,2044.079712 893.411682,2051.610596 900.600098,2056.317627 
	C905.996399,2059.851074 911.792786,2061.996582 917.997925,2063.405518 
	C920.291077,2063.926270 922.731934,2065.011719 922.752686,2067.782227 
	C922.778137,2071.166748 919.922241,2071.988525 917.258911,2072.470215 
	C912.292480,2073.368408 907.289734,2073.185303 902.421753,2071.921143 
	C898.048340,2070.785400 896.088196,2072.230713 896.247803,2076.717773 
	C896.456848,2082.595459 895.165833,2087.934082 890.168823,2092.617432 
	C888.454468,2094.713379 887.010132,2096.096680 885.365173,2097.237061 
	C882.517578,2099.210938 879.344116,2100.883057 876.263672,2098.234131 
	C873.094482,2095.509033 874.422241,2092.065674 876.217407,2089.160400 
	C880.731934,2081.854248 879.551758,2073.518799 879.165649,2065.945557 
	C878.447510,2051.860596 877.085510,2037.614380 871.327820,2024.322876 
	C870.288391,2021.923462 870.275818,2019.047729 867.583191,2017.595947 
	C865.715454,2016.589111 863.675964,2016.125854 862.001465,2017.601807 
	C859.800720,2019.541382 861.452454,2021.107422 862.975403,2022.488403 
	C867.124146,2026.250244 867.208008,2030.647949 864.535889,2035.098755 
	C861.900208,2039.488525 857.765320,2039.991577 853.165649,2038.566772 
	C847.525818,2036.819580 844.253113,2039.186890 842.704590,2044.613892 
	C841.876587,2047.515869 840.906921,2050.616211 838.543335,2052.455078 
	C831.435425,2057.984619 834.334534,2064.934326 836.460388,2070.904785 
	C839.472961,2079.365479 838.259277,2082.255859 829.171692,2082.368408 
	C823.943604,2082.433350 818.548645,2081.077393 812.830139,2085.489746 
	C820.955139,2087.523682 828.074707,2090.009521 832.499084,2096.750488 
	C833.956848,2098.971924 836.066101,2101.105469 834.074341,2103.893555 
	C832.318420,2106.351562 829.503540,2106.625244 826.843628,2105.973389 
	C823.112122,2105.058105 819.313477,2102.203125 816.276001,2107.419434 
	C820.783386,2108.133301 825.365601,2108.364014 829.879639,2109.064209 
	C834.019104,2109.705811 839.669250,2109.366211 839.057861,2115.589355 
	C838.518066,2121.085449 833.421143,2120.310791 829.513733,2119.942139 
	C821.273315,2119.164795 814.482544,2122.033203 808.745178,2127.694580 
	C804.905640,2131.483154 804.379272,2134.479736 810.999207,2135.321777 
	C814.023682,2135.706299 816.856628,2136.692383 819.077576,2138.888428 
	C822.288940,2142.063477 824.795288,2145.749512 823.161621,2150.421631 
	C821.688293,2154.635742 817.531860,2154.811768 813.904480,2154.795410 
	C805.026428,2154.755371 796.117554,2154.053223 786.943665,2157.213135 
	C784.135376,2155.277832 783.550842,2152.683105 784.824524,2150.763428 
	C791.172546,2141.196289 792.040710,2130.460205 792.738037,2119.080566 
	C794.460815,2117.447998 799.371033,2117.184570 795.249878,2114.523193 
	C790.953308,2111.748535 786.704529,2109.970947 790.906311,2104.078857 
	C782.588501,2090.655029 798.493408,2087.220703 801.714844,2078.521729 
	C802.607422,2076.111572 804.405640,2075.338135 801.100159,2073.616211 
	C795.922852,2070.918945 794.710999,2064.466553 789.997803,2061.100586 
	C788.350464,2059.923828 788.187805,2057.536865 788.975464,2055.552490 
	C790.039856,2052.870361 792.465698,2052.383545 794.797302,2053.114014 
	C802.193604,2055.430420 805.842896,2050.655273 809.931152,2045.211182 
	C808.866211,2034.865479 812.248901,2027.132202 820.034729,2021.024780 
	C821.792847,2019.645752 822.462341,2017.074341 822.968750,2014.834351 
	C823.858398,2010.899658 828.123718,2005.665283 823.562317,2003.240112 
	C819.115112,2000.875610 812.090149,1999.047119 807.715088,2003.311768 
	C803.081055,2007.828491 799.150696,2006.993164 793.677673,2004.822632 
	C791.920776,2004.548950 790.928467,2004.272339 789.222534,2003.545654 
	C784.089600,2000.323853 782.778259,1996.358521 784.710388,1990.649536 
	C786.353455,1988.630127 787.921631,1988.075195 790.406372,1987.673096 
	C794.212524,1987.597778 797.235657,1987.695435 800.135925,1988.449341 
	C805.199158,1989.765625 808.696350,1988.091919 810.366394,1983.207642 
	C812.435303,1977.156372 816.120667,1973.712769 822.870361,1974.024414 
	C826.125854,1974.174805 829.018860,1972.981079 829.276794,1969.207764 
	C829.726990,1962.620605 830.591309,1956.162598 829.552368,1949.379272 
	C827.438049,1935.575562 815.827332,1931.333374 806.089783,1927.524414 
	C792.569641,1922.236206 778.460938,1916.570190 762.982971,1919.222412 
	C749.958130,1921.454346 736.865173,1922.888672 723.855530,1925.694946 
	C707.644592,1929.191772 690.726990,1927.379761 674.125916,1926.527832 
	C667.717163,1926.199097 660.565857,1927.504395 655.850647,1920.902222 
	C654.937744,1919.624023 652.665039,1919.484985 651.129517,1919.885864 
	C635.814331,1923.884888 620.036743,1915.263794 604.822510,1921.623779 
	C603.928833,1921.997437 602.735535,1922.067749 601.864807,1921.709595 
	C587.837585,1915.938843 575.042236,1921.432617 562.605896,1926.687866 
	C552.112061,1931.122192 540.957642,1929.850098 530.548889,1930.117676 
	C516.597412,1930.476318 502.034210,1931.732056 488.198608,1927.783081 
	C482.917053,1926.275635 479.171448,1927.687378 474.741364,1931.211670 
	C446.316803,1932.383911 418.405212,1936.919800 391.037323,1928.381714 
	C379.832245,1924.885864 368.423798,1923.761719 356.713104,1922.991333 
	C331.839294,1921.355347 306.833160,1924.639648 282.217072,1921.824463 
	C266.274353,1920.001221 249.713272,1926.170776 234.328201,1917.887329 
	C231.816177,1916.534668 228.409302,1917.421631 225.474396,1918.131104 
	C211.745575,1921.449219 198.066208,1921.651367 184.418793,1917.670532 
	C180.850159,1916.629517 177.072495,1916.123901 173.568695,1917.220215 
	C165.718216,1919.676636 158.157349,1919.806641 150.150436,1917.442017 
	C142.648804,1915.226685 138.324142,1919.313232 136.412766,1926.634277 
	C133.898941,1936.262939 122.771507,1940.747314 114.117737,1935.678223 
	C109.346565,1932.883423 109.569679,1929.364014 113.809280,1926.313354 
	C117.206902,1923.868774 121.808655,1922.897461 123.438545,1917.483887 
	C113.153145,1915.092773 103.029541,1916.201294 92.107971,1916.923950 
	C80.144943,1915.994019 68.988304,1915.172607 56.978416,1914.950439 
	C55.095604,1914.873169 54.030216,1914.804199 52.193459,1914.643921 
	C49.428886,1914.227661 47.436531,1913.891968 44.640617,1914.358521 
	C33.471199,1913.124756 23.087557,1915.329102 12.682108,1914.604858 
	C9.462222,1914.380737 6.131092,1915.034912 2.617447,1912.496338 
z"
        />
        <path
          fill="#EAE6D7"
          opacity="1.000000"
          stroke="none"
          d="
M1760.477905,4033.488281 
	C1714.624878,4034.000000 1669.249756,4034.000000 1622.937256,4034.000000 
	C1618.808838,4021.317871 1622.029175,4017.731689 1634.033936,4020.437500 
	C1637.129028,4021.135742 1640.314941,4022.770508 1644.189209,4020.145508 
	C1640.307251,4017.371338 1636.187134,4019.394775 1632.608276,4018.461426 
	C1627.745483,4017.193848 1625.275269,4014.962646 1628.905518,4010.084717 
	C1633.058350,4004.504150 1636.782104,3998.621826 1645.213013,3998.656982 
	C1650.788208,3998.680176 1655.294067,3995.609375 1658.455688,3990.853516 
	C1660.507690,3987.766846 1663.001831,3985.118652 1666.336548,3983.376709 
	C1674.365845,3979.183350 1680.440308,3981.515869 1683.583496,3990.037842 
	C1684.504150,3992.534180 1685.018921,3995.177002 1686.038696,3997.632568 
	C1688.657959,4003.937988 1693.870850,3997.844971 1697.398071,3999.932129 
	C1697.523193,3998.087891 1694.336914,3997.709717 1695.157959,3996.007080 
	C1699.131958,3987.765869 1695.137207,3983.695557 1688.110229,3980.096191 
	C1685.548706,3978.784180 1687.019653,3975.125977 1688.904175,3973.055908 
	C1693.320679,3968.204102 1698.444580,3964.741699 1705.566162,3965.937988 
	C1701.569092,3962.344971 1699.866699,3957.162842 1705.207764,3955.043213 
	C1713.593994,3951.714844 1720.060059,3943.387451 1730.413208,3945.130371 
	C1723.561157,3942.850830 1715.859985,3942.823730 1711.833496,3935.189941 
	C1709.809692,3931.353271 1706.702393,3933.204834 1704.247192,3935.069336 
	C1701.391724,3937.238281 1699.139648,3940.591064 1694.163086,3938.695068 
	C1691.612915,3931.911133 1697.099609,3927.265869 1697.733154,3920.754395 
	C1699.969360,3916.243652 1703.175049,3916.489258 1706.581909,3917.525391 
	C1710.084106,3918.590576 1713.656250,3919.410889 1717.147583,3920.501709 
	C1721.138916,3921.749268 1724.401001,3920.925293 1726.680664,3917.328369 
	C1728.788818,3914.001221 1726.340820,3911.735840 1724.588745,3909.357422 
	C1723.997192,3908.554688 1723.306030,3907.827881 1722.689331,3907.043945 
	C1720.455200,3904.206055 1717.104004,3901.325684 1720.028687,3897.459229 
	C1723.242920,3893.209961 1727.950195,3891.318115 1733.300171,3892.563232 
	C1737.741333,3893.596924 1740.180176,3896.400879 1739.196655,3901.339355 
	C1738.018311,3907.255371 1741.851929,3910.178955 1746.470947,3911.435791 
	C1749.221802,3912.184082 1753.838257,3912.036377 1751.404663,3906.399414 
	C1751.141724,3905.790771 1750.801147,3905.197998 1750.709839,3904.538330 
	C1750.474609,3902.839111 1750.620850,3900.150879 1751.998291,3900.136475 
	C1762.720581,3900.022461 1755.881348,3882.364014 1768.446899,3884.030273 
	C1772.641968,3884.586670 1777.044800,3883.370361 1781.330933,3882.841797 
	C1791.310547,3881.611328 1792.177124,3879.953857 1792.264893,3869.625000 
	C1792.331055,3861.855713 1796.942871,3855.052490 1797.767700,3847.039551 
	C1798.321533,3841.661377 1806.420654,3842.293701 1811.654663,3842.329590 
	C1816.511230,3842.363037 1821.295532,3844.310303 1823.403442,3836.961182 
	C1824.582397,3832.850830 1829.692139,3833.098145 1832.786377,3835.585938 
	C1842.381226,3843.300537 1850.477051,3840.391846 1858.352417,3832.931885 
	C1860.749390,3830.660889 1863.244995,3828.290527 1867.683472,3829.178711 
	C1871.328857,3835.591309 1870.546753,3842.382812 1871.105103,3848.795898 
	C1871.719116,3855.850098 1867.841919,3857.768066 1859.940063,3855.731201 
	C1858.611816,3857.936523 1860.953857,3859.146240 1861.444092,3860.775391 
	C1864.194458,3869.912354 1861.564697,3873.993408 1852.015747,3874.842773 
	C1848.373169,3875.166992 1844.687744,3874.966064 1841.104614,3875.950195 
	C1839.145874,3876.488525 1837.533691,3877.646729 1835.820557,3878.635010 
	C1849.968628,3874.739258 1864.128662,3881.294922 1878.422607,3878.698486 
	C1877.790527,3874.574463 1870.133057,3872.485107 1874.224609,3867.758057 
	C1878.955933,3862.291504 1884.617188,3857.169434 1893.000977,3858.212402 
	C1885.583374,3853.351318 1885.477295,3843.350098 1894.068726,3838.285156 
	C1899.511230,3835.076660 1902.058960,3830.711670 1904.757690,3825.825439 
	C1906.527954,3822.620117 1909.582520,3820.224609 1904.764771,3816.145508 
	C1900.624390,3812.640381 1903.225708,3808.994873 1908.111206,3807.527100 
	C1910.348633,3806.854736 1912.758789,3807.050537 1915.658325,3806.005371 
	C1921.538696,3805.064941 1925.729126,3806.578613 1927.805298,3811.613281 
	C1930.120361,3817.227539 1934.177612,3817.187744 1939.255737,3816.376953 
	C1947.345459,3815.085205 1955.668823,3812.632324 1963.925537,3818.465820 
	C1968.813232,3832.328613 1972.786133,3845.790527 1966.423218,3859.644287 
	C1965.010742,3862.719482 1964.156128,3865.403564 1964.829712,3868.982910 
	C1966.512207,3877.923584 1962.111816,3886.252197 1960.987305,3895.579102 
	C1949.583008,3900.012939 1937.040283,3899.953369 1928.218262,3909.363037 
	C1923.802734,3914.073242 1919.334839,3908.533447 1914.626221,3908.075928 
	C1915.204834,3912.100586 1918.316406,3911.153320 1920.104004,3912.212402 
	C1926.793335,3916.174561 1928.453491,3921.500000 1925.853760,3928.872559 
	C1924.362549,3933.101074 1925.907715,3937.962891 1922.898438,3942.136719 
	C1920.916504,3944.885498 1919.417358,3947.772461 1915.911377,3948.796631 
	C1912.337158,3949.840820 1908.681763,3950.863525 1905.450073,3948.167236 
	C1902.202759,3945.458008 1904.418579,3941.987061 1904.507568,3938.802490 
	C1904.574585,3936.396240 1906.815918,3934.845459 1906.862427,3932.466064 
	C1898.440186,3931.035889 1892.031616,3931.602539 1887.882446,3941.323486 
	C1885.047852,3947.964111 1875.279541,3946.054688 1869.608032,3949.913818 
	C1875.471191,3947.483154 1881.813110,3948.328613 1888.057129,3948.284180 
	C1890.980713,3948.263184 1893.136108,3949.812012 1893.641479,3952.955811 
	C1894.116089,3955.907227 1893.116577,3958.421387 1890.432739,3959.648193 
	C1884.046631,3962.568604 1877.563354,3965.482666 1871.896362,3962.363770 
	C1873.780762,3962.795166 1877.343384,3964.012207 1876.684692,3968.185547 
	C1875.996216,3972.546631 1871.885986,3972.489258 1868.646729,3971.907227 
	C1859.944702,3970.343750 1851.374268,3972.246338 1842.745850,3972.335693 
	C1839.171265,3972.372803 1834.304565,3973.200195 1833.581177,3968.597168 
	C1832.747559,3963.291992 1838.099243,3962.967773 1841.746704,3961.770264 
	C1844.259888,3960.945312 1847.029053,3961.362061 1849.548096,3960.420654 
	C1846.275635,3949.149902 1847.654785,3946.165283 1859.000488,3941.050537 
	C1855.353149,3939.392090 1851.086914,3937.949707 1854.011353,3933.080322 
	C1858.375732,3925.813721 1853.463989,3924.521729 1848.052979,3923.446777 
	C1836.642090,3921.180176 1836.640869,3921.186279 1831.195190,3913.277344 
	C1828.130859,3919.131592 1830.672607,3922.034912 1835.489136,3924.274170 
	C1840.743408,3926.716553 1848.812866,3928.061768 1846.990112,3935.693115 
	C1845.070312,3943.729736 1836.631226,3942.526855 1830.147095,3942.847900 
	C1825.869263,3943.060059 1821.398438,3943.042480 1817.836060,3938.700684 
	C1816.980835,3944.781250 1815.651123,3950.377441 1808.075073,3950.515869 
	C1811.324707,3955.846436 1813.014893,3962.281006 1821.319336,3959.228516 
	C1823.878540,3958.287842 1825.453857,3960.922607 1825.488647,3963.565918 
	C1825.536865,3967.233154 1823.863037,3969.984375 1820.388916,3971.377197 
	C1816.682495,3972.862793 1812.456421,3971.649414 1807.897461,3973.551514 
	C1814.175293,3982.006348 1808.403809,3990.371338 1807.721313,3998.618408 
	C1807.200317,4004.915039 1799.655273,4002.520996 1795.044800,4001.833008 
	C1788.306519,4000.827637 1782.631226,4000.900635 1780.980103,4009.431885 
	C1783.596436,4002.347412 1788.419922,4001.769775 1794.655273,4004.041748 
	C1809.800293,4009.560303 1825.985107,4009.977051 1841.578369,4013.319580 
	C1845.043823,4014.062256 1849.583496,4014.252930 1849.115845,4019.436523 
	C1848.669678,4024.380615 1844.059326,4023.880859 1840.652466,4024.728271 
	C1837.299805,4025.562012 1834.192261,4025.126953 1830.998657,4023.573242 
	C1821.707031,4019.051758 1811.944702,4018.528320 1801.767578,4019.974854 
	C1791.704346,4021.405518 1781.399292,4023.805664 1773.506958,4018.728027 
	C1775.031982,4019.298340 1779.753418,4019.640869 1778.544800,4024.758301 
	C1777.607300,4028.728516 1773.472778,4028.765381 1770.195801,4028.464111 
	C1766.010010,4028.079346 1763.144775,4029.648438 1760.477905,4033.488281 
z"
        />
        <path
          fill="#808489"
          opacity="1.000000"
          stroke="none"
          d="
M2.485741,3638.451904 
	C2.000000,3635.866699 2.000000,3633.733154 2.000000,3630.799805 
	C12.394009,3618.349609 26.964140,3613.545166 40.504158,3607.480225 
	C65.582275,3596.247803 91.705124,3588.353516 119.639542,3588.866943 
	C132.585632,3589.104980 144.611374,3592.624023 156.011688,3600.467773 
	C161.152725,3611.371582 156.142090,3620.124512 151.583801,3629.614014 
	C150.738190,3631.851562 150.368454,3633.493896 151.065643,3635.796875 
	C151.744812,3639.121338 150.220657,3641.160156 148.702103,3643.846680 
	C147.884583,3646.032471 147.966629,3647.670410 148.434265,3649.996094 
	C149.158508,3654.608154 149.013016,3658.553223 148.679962,3663.265137 
	C147.414612,3670.107910 146.757523,3676.294922 144.756897,3682.979492 
	C141.172470,3697.700439 134.842651,3711.049805 135.945053,3726.025146 
	C136.352234,3731.556641 129.428375,3738.426025 130.153183,3739.580811 
	C133.809692,3745.404785 131.014740,3749.611572 128.713928,3754.961914 
	C120.228279,3766.127197 111.314682,3775.972900 101.215195,3785.670410 
	C100.457146,3786.524170 100.152336,3786.864014 99.372314,3787.695801 
	C98.574593,3788.509766 98.252083,3788.831543 97.440048,3789.628906 
	C90.649017,3795.984863 84.832047,3802.418945 76.949356,3807.540039 
	C64.237518,3816.529541 52.630802,3825.809082 41.241669,3835.371826 
	C36.744492,3839.147705 30.700449,3838.876953 25.518000,3841.065674 
	C17.996302,3844.242432 11.561757,3849.959229 2.543106,3850.444336 
	C2.000000,3844.599854 2.000000,3839.199951 2.000000,3832.899902 
	C5.127957,3828.660400 8.048386,3824.372070 13.341047,3828.619385 
	C15.609563,3830.439697 16.849663,3828.979004 18.210539,3827.559814 
	C18.907707,3826.832520 19.439054,3825.870850 18.784208,3824.930420 
	C17.491827,3823.074707 15.533113,3823.008789 13.534738,3823.340088 
	C10.053354,3823.916992 6.825530,3826.185059 2.532949,3824.449463 
	C2.000000,3807.958252 2.000000,3791.916260 2.000000,3774.937256 
	C8.030268,3768.878174 11.403664,3763.990723 8.216845,3754.756592 
	C4.867636,3745.052734 13.464445,3737.765137 24.480061,3738.933105 
	C22.796860,3737.805908 20.844048,3737.850586 19.016548,3738.050049 
	C11.913721,3738.824463 9.596992,3734.848877 9.102594,3728.640381 
	C8.546031,3721.650879 7.563804,3714.742676 2.493627,3708.490234 
	C2.000000,3689.958252 2.000000,3671.916260 2.000000,3652.937256 
	C6.786084,3647.965576 10.080519,3643.820557 2.485741,3638.451904 
z"
        />
        <path
          fill="#020914"
          opacity="1.000000"
          stroke="none"
          d="
M2314.561768,4033.420410 
	C2311.222168,4034.000000 2308.444336,4034.000000 2304.833252,4034.000000 
	C2297.595459,4029.773193 2290.258057,4027.523193 2283.354980,4024.362061 
	C2279.205566,4022.462158 2274.949463,4023.451172 2270.738525,4024.970459 
	C2256.468018,4030.118896 2235.363037,4024.761230 2225.150146,4013.647461 
	C2222.394043,4010.647949 2220.473633,4006.167236 2214.362549,4007.181152 
	C2216.191895,4011.052490 2220.281250,4011.635498 2222.570557,4014.159180 
	C2225.415527,4017.295166 2229.459961,4020.177246 2224.565918,4025.390137 
	C2220.310791,4026.807129 2216.978027,4025.759277 2213.019043,4024.102295 
	C2207.149170,4019.616943 2201.539551,4016.227539 2194.243652,4014.548828 
	C2193.069580,4014.227783 2192.604980,4014.070068 2191.465820,4013.607178 
	C2163.820557,3999.515625 2136.010010,3987.465576 2109.013916,3973.734863 
	C2104.619141,3971.499512 2099.662842,3969.968262 2096.666016,3964.637451 
	C2096.923828,3955.239990 2103.214600,3954.842041 2109.330078,3955.716309 
	C2117.834473,3956.932373 2126.337158,3958.546631 2134.238281,3962.319336 
	C2137.773193,3964.006836 2141.347900,3966.031006 2146.566895,3964.387207 
	C2143.068115,3957.686279 2135.683105,3957.038330 2130.817871,3953.155029 
	C2117.351562,3942.406738 2102.332275,3939.839600 2085.901611,3945.116943 
	C2079.261475,3947.249756 2072.047119,3946.747070 2064.739258,3949.483643 
	C2050.879150,3948.672363 2040.332520,3941.238770 2029.192139,3935.451172 
	C2017.100708,3929.169678 2004.781494,3923.297607 1992.640503,3915.553467 
	C1988.886108,3905.550537 1992.627441,3896.445312 1993.121948,3887.270020 
	C1993.744507,3875.720215 1995.557007,3864.254150 1997.028564,3852.772461 
	C1997.416138,3849.749512 1998.624390,3847.026611 2001.239380,3844.134521 
	C2008.310059,3839.050049 2014.339722,3839.691650 2021.312744,3843.493408 
	C2033.087891,3849.913330 2045.532349,3853.513672 2059.583740,3851.930908 
	C2070.377930,3850.714600 2081.461182,3851.222168 2092.416992,3851.102295 
	C2096.854736,3851.053711 2101.357178,3851.938721 2103.117676,3856.807129 
	C2104.854736,3861.610596 2101.823975,3865.053955 2098.831543,3868.283203 
	C2094.890381,3872.536377 2090.179443,3875.532471 2084.460938,3876.827393 
	C2080.549072,3877.713379 2076.803955,3879.059326 2073.189697,3880.818604 
	C2068.932617,3882.890381 2066.479980,3885.388184 2070.509766,3889.872803 
	C2070.953125,3890.366455 2071.534180,3890.890869 2071.658936,3891.488770 
	C2073.877686,3902.122070 2079.634521,3903.580078 2088.531494,3898.455566 
	C2091.440186,3896.780273 2095.075684,3897.239990 2098.327393,3898.167725 
	C2104.160400,3899.831787 2109.966797,3900.401611 2116.072754,3899.766602 
	C2122.334473,3899.115723 2127.250977,3900.914551 2124.041260,3908.792969 
	C2121.812988,3914.262939 2124.405029,3916.876221 2128.847900,3918.907715 
	C2130.058838,3919.461426 2128.500488,3918.989990 2129.769043,3919.275391 
	C2148.681152,3923.530762 2165.438965,3933.359619 2183.443115,3939.954590 
	C2188.962158,3941.976074 2194.428223,3942.184814 2199.778076,3939.850098 
	C2200.481201,3938.072998 2199.340820,3937.172363 2198.449707,3936.242920 
	C2196.081299,3933.772217 2193.579834,3931.188721 2195.705322,3927.535645 
	C2197.927246,3923.716064 2201.685791,3923.705566 2205.429932,3924.739990 
	C2209.611816,3925.895020 2213.876953,3926.601562 2218.107666,3927.500000 
	C2222.389160,3928.409180 2225.944824,3930.737305 2225.351562,3935.536865 
	C2224.508545,3942.358643 2229.074951,3943.881836 2233.721191,3944.147217 
	C2251.932861,3945.187744 2268.752441,3951.911133 2286.141602,3956.293701 
	C2290.814209,3957.471436 2304.069824,3951.909912 2305.562256,3947.574707 
	C2307.220703,3942.755371 2302.974121,3942.072266 2299.855957,3941.603027 
	C2292.591553,3940.510254 2285.156738,3940.507080 2277.930664,3941.236816 
	C2260.373535,3943.009521 2250.074707,3930.551758 2238.296143,3921.438965 
	C2233.945312,3918.072998 2232.186279,3911.710449 2234.951660,3905.839600 
	C2237.799805,3899.792480 2243.529541,3898.612061 2249.381348,3899.211426 
	C2261.279541,3900.429932 2273.027100,3902.788330 2284.979980,3903.833008 
	C2291.470947,3904.399902 2296.050293,3909.654053 2300.890381,3913.684082 
	C2306.358154,3918.236328 2312.222656,3921.830566 2319.372070,3923.007568 
	C2322.386719,3923.504150 2324.319824,3923.551758 2324.209961,3919.299805 
	C2323.981689,3910.459961 2329.921631,3908.719971 2337.031006,3909.904053 
	C2346.888428,3911.546143 2356.035645,3914.516846 2358.237793,3926.313721 
	C2359.218750,3931.566895 2364.427734,3930.510010 2368.355469,3932.800049 
	C2375.811523,3942.132080 2371.597900,3952.583984 2373.025391,3963.071777 
	C2372.895264,3966.119141 2372.897705,3968.343994 2371.584229,3971.129395 
	C2369.331787,3974.064209 2366.716553,3974.785400 2364.043213,3974.814941 
	C2357.712891,3974.885010 2352.416992,3975.582520 2350.756592,3983.484375 
	C2349.871582,3987.695801 2345.481934,3988.007080 2341.587646,3987.163086 
	C2337.027832,3986.175293 2332.478760,3985.052490 2328.169189,3983.275635 
	C2321.613281,3980.572998 2315.453125,3977.239258 2311.683838,3987.364746 
	C2310.384033,3990.855469 2305.531494,3990.880371 2301.837646,3990.980957 
	C2288.144775,3991.354248 2274.529053,3991.056885 2261.584717,3985.678955 
	C2259.100342,3984.646729 2256.402588,3983.512939 2253.885254,3984.951904 
	C2242.357910,3991.541016 2232.276123,3985.053955 2222.740723,3980.542480 
	C2214.119385,3976.463379 2205.740967,3973.202393 2196.210938,3972.928467 
	C2190.141846,3972.754395 2184.480713,3970.649170 2178.776611,3968.796631 
	C2176.525146,3968.065430 2173.707031,3967.358398 2172.429199,3969.774414 
	C2170.897949,3972.669434 2173.863037,3973.842529 2175.833740,3974.769531 
	C2184.254639,3978.731201 2192.541016,3983.207764 2202.292236,3982.918457 
	C2204.285156,3982.859131 2206.526123,3982.569336 2208.231689,3983.400146 
	C2235.737793,3996.796387 2269.086426,3990.169678 2295.804688,4008.940186 
	C2303.127197,4010.709473 2309.042725,4013.615479 2313.414062,4018.823730 
	C2316.880615,4022.954102 2319.889404,4027.528320 2314.561768,4033.420410 
z"
        />
        <path
          fill="#738EA6"
          opacity="1.000000"
          stroke="none"
          d="
M2822.569336,2238.519287 
	C2817.898926,2238.023926 2813.827148,2238.008545 2808.882568,2237.986816 
	C2806.843506,2226.157959 2800.543701,2220.391602 2788.448730,2220.781250 
	C2785.227539,2220.885254 2780.994385,2219.961426 2781.248291,2215.628174 
	C2781.544434,2210.565430 2785.971680,2209.245605 2790.121338,2209.856689 
	C2800.551270,2211.392822 2811.003418,2210.832520 2819.013672,2210.994873 
	C2816.135742,2211.003906 2810.819824,2210.979980 2805.503662,2210.979004 
	C2801.481201,2210.978271 2796.438965,2211.443848 2793.903809,2209.030762 
	C2783.827393,2199.438965 2774.076660,2206.945557 2764.443848,2208.866211 
	C2743.040527,2213.134033 2721.621826,2212.549561 2700.124023,2210.453857 
	C2698.125488,2210.259033 2696.360352,2209.184326 2694.659668,2206.858887 
	C2692.383057,2201.760986 2695.142090,2194.712646 2689.522705,2192.868408 
	C2680.632324,2189.950439 2671.078613,2189.942383 2662.314697,2193.975342 
	C2655.548340,2197.089355 2648.838379,2199.568848 2641.291992,2198.909668 
	C2634.494873,2198.315918 2627.998047,2197.083008 2624.639648,2190.047607 
	C2622.291748,2185.128418 2617.677979,2185.409424 2613.454590,2185.410400 
	C2605.380615,2185.412354 2597.623047,2183.903076 2589.987793,2181.474854 
	C2587.446045,2180.666504 2584.849854,2179.882568 2583.015625,2177.829834 
	C2580.297119,2174.787598 2577.837891,2171.319336 2579.442871,2167.100586 
	C2581.039062,2162.903564 2585.237305,2163.529541 2588.846191,2163.171143 
	C2595.323242,2162.527344 2599.761719,2167.684814 2606.440918,2168.807373 
	C2617.305176,2168.039062 2627.299561,2169.684570 2638.183594,2169.056641 
	C2643.614502,2169.083008 2648.172852,2169.041992 2653.543457,2168.750977 
	C2657.108887,2169.041504 2659.570801,2167.732666 2662.968262,2167.276367 
	C2672.632812,2164.869141 2681.757812,2160.651123 2689.197998,2170.382568 
	C2689.712402,2171.055176 2691.063721,2171.193359 2692.036133,2171.126465 
	C2711.264160,2169.808594 2730.833008,2177.170166 2751.301025,2168.256348 
	C2738.499268,2166.300293 2726.848877,2167.818604 2715.500000,2165.815186 
	C2712.510986,2165.287354 2710.942383,2167.925537 2708.866211,2169.568359 
	C2703.996338,2173.421631 2693.390869,2172.964600 2689.417969,2168.899414 
	C2684.899658,2164.276123 2686.437012,2159.913818 2690.961670,2155.354004 
	C2692.535400,2154.483887 2693.524658,2154.246826 2695.321777,2154.233643 
	C2717.926025,2153.820801 2739.968506,2155.983398 2761.383545,2149.865723 
	C2754.182861,2149.268799 2746.997070,2149.688965 2739.895508,2149.113770 
	C2726.175537,2148.003174 2713.119141,2150.653809 2699.481201,2154.754639 
	C2697.781738,2154.444336 2696.819824,2154.237061 2695.133301,2153.964355 
	C2692.784180,2153.514160 2691.174805,2153.056396 2689.701172,2152.273682 
	C2687.253906,2150.974121 2685.033447,2149.211914 2685.218018,2146.211670 
	C2685.447021,2142.485352 2688.573486,2141.650879 2691.568359,2140.957520 
	C2693.511475,2140.508057 2695.445801,2139.963867 2697.476562,2140.108154 
	C2697.961426,2139.994629 2697.680420,2140.010498 2696.631348,2140.026123 
	C2692.225342,2140.090820 2685.653809,2142.366455 2685.476807,2135.687988 
	C2685.303467,2129.161133 2691.944092,2129.165527 2696.977539,2129.395264 
	C2702.705811,2129.656982 2707.856445,2131.704102 2712.830322,2134.764404 
	C2718.574707,2138.298828 2724.706543,2139.407715 2729.926514,2133.372314 
	C2732.750732,2130.107178 2736.234619,2129.667725 2739.864258,2131.416992 
	C2746.538818,2134.634033 2753.658691,2132.968994 2760.555176,2133.570312 
	C2764.849365,2133.944824 2767.252686,2132.328125 2763.931396,2127.756592 
	C2761.210938,2124.012207 2759.226318,2119.712646 2755.002197,2117.171387 
	C2752.729248,2115.803711 2750.360596,2114.042969 2751.314453,2110.821045 
	C2752.165039,2107.947754 2754.707764,2106.387207 2757.376953,2106.310791 
	C2763.861572,2106.125000 2768.078613,2103.144531 2772.471436,2098.336182 
	C2779.565430,2090.571045 2791.156250,2094.392334 2793.992920,2104.655762 
	C2795.807129,2111.220215 2799.813477,2117.869629 2792.844238,2123.784912 
	C2796.278809,2127.580811 2799.503906,2124.260742 2802.572021,2124.574951 
	C2806.361572,2124.963135 2810.279297,2125.575928 2811.110840,2129.961914 
	C2812.066162,2134.997803 2808.069824,2135.867676 2803.811768,2136.716553 
	C2809.925293,2137.501221 2815.378174,2138.836182 2820.762207,2142.452637 
	C2825.721191,2146.763916 2831.054443,2148.248535 2836.688721,2149.319336 
	C2840.251221,2149.996582 2843.048096,2150.856201 2842.290771,2156.475830 
	C2841.024170,2165.874023 2841.788330,2165.977051 2831.544189,2165.996582 
	C2839.893555,2166.135010 2851.780518,2166.693359 2861.766357,2167.942627 
	C2862.681885,2167.972656 2861.729248,2168.037109 2860.796631,2167.913818 
	C2853.871094,2167.000244 2849.213867,2163.106934 2847.284668,2156.527344 
	C2846.512939,2153.895264 2849.135742,2151.840088 2851.406494,2151.945312 
	C2857.232178,2152.214600 2865.353271,2146.261963 2867.671143,2157.458252 
	C2868.218018,2160.099365 2870.980225,2158.675537 2872.712158,2158.820068 
	C2880.241699,2159.447510 2887.971924,2157.548584 2895.854492,2161.123047 
	C2897.764648,2169.941650 2890.589844,2171.983398 2885.658936,2173.394287 
	C2877.869873,2175.622803 2869.707031,2176.877441 2861.208984,2176.435303 
	C2848.036621,2175.749756 2834.786133,2175.913086 2823.280273,2175.995361 
	C2831.686523,2174.847168 2841.773438,2177.538086 2851.917725,2176.178467 
	C2855.930420,2175.640625 2859.467041,2176.802979 2859.646240,2181.696289 
	C2859.829346,2186.690918 2856.361084,2187.667969 2852.275635,2187.563721 
	C2843.320068,2187.335449 2834.560303,2184.746094 2825.443359,2185.890381 
	C2819.710449,2186.609619 2814.713379,2188.139893 2810.451416,2192.670166 
	C2828.316406,2189.070312 2845.903809,2192.688477 2863.416016,2191.695312 
	C2866.031006,2191.546875 2868.701416,2192.289307 2871.368164,2191.912354 
	C2879.156494,2190.812744 2880.207275,2188.478271 2875.557373,2182.265625 
	C2874.359619,2180.665283 2872.613037,2179.310059 2873.467285,2177.083496 
	C2874.600098,2174.131104 2877.372803,2175.202637 2879.539062,2175.019043 
	C2890.458740,2174.094238 2901.312744,2172.273193 2912.339844,2172.766357 
	C2915.281982,2172.897705 2918.459717,2173.058594 2920.164062,2169.707520 
	C2922.169922,2165.764160 2918.830811,2164.892090 2916.721924,2162.818115 
	C2912.286865,2158.456787 2913.503906,2154.288574 2918.915527,2151.817139 
	C2923.829834,2149.572754 2929.204346,2148.764648 2934.530273,2150.199219 
	C2941.003418,2151.943115 2948.165283,2151.241211 2954.098389,2153.429932 
	C2966.933350,2158.165283 2981.760010,2157.155029 2993.629883,2166.777100 
	C3005.885986,2164.437988 3016.276611,2166.224121 3025.410156,2175.477051 
	C3026.000000,2190.041992 3026.000000,2204.083740 3026.000000,2219.062988 
	C3022.363037,2223.721680 3017.177246,2220.737305 3012.890137,2223.186768 
	C3017.138672,2222.722412 3021.101807,2220.833252 3025.510010,2223.543213 
	C3026.000000,2227.428711 3026.000000,2230.857422 3026.000000,2235.143066 
	C3017.515381,2239.268555 3008.329346,2240.519287 2999.872803,2238.494873 
	C2987.899902,2235.628662 2976.029053,2239.327881 2964.312988,2237.035156 
	C2960.385254,2236.266602 2953.812500,2237.498535 2956.398193,2229.586426 
	C2956.644287,2228.832275 2957.174805,2228.352539 2956.817383,2228.323486 
	C2955.816406,2228.240723 2954.860107,2228.872803 2953.908447,2228.845215 
	C2946.015137,2228.617676 2937.869385,2229.292969 2931.708496,2222.682129 
	C2929.916748,2220.759521 2927.469971,2220.075439 2925.537842,2222.127930 
	C2915.452393,2232.841797 2902.307861,2227.946533 2890.548584,2228.467773 
	C2882.725098,2228.814209 2874.657227,2228.334717 2866.703613,2227.932861 
	C2891.371338,2228.593506 2916.040283,2229.615234 2940.713867,2228.308838 
	C2942.064697,2228.237305 2944.021240,2228.021484 2944.490723,2228.982422 
	C2950.427002,2241.127930 2962.285889,2238.208496 2971.338379,2237.613037 
	C2985.592041,2236.675293 2999.563477,2240.692139 3013.682373,2238.439453 
	C3017.774414,2237.786377 3021.651367,2237.966553 3025.443848,2241.501709 
	C3026.000000,2243.500000 3026.000000,2245.000000 3026.000000,2247.250000 
	C3021.019775,2252.586670 3015.304199,2249.975342 3009.791260,2249.735840 
	C3001.723877,2249.385010 2993.668701,2247.191162 2988.414307,2257.389404 
	C2985.012207,2263.992676 2963.667969,2260.975342 2960.552979,2253.794922 
	C2958.385986,2248.799805 2954.921875,2248.169434 2950.530762,2247.250000 
	C2928.412842,2242.619873 2906.818604,2248.177002 2884.993164,2249.941650 
	C2869.800049,2251.169678 2854.601318,2253.864258 2839.326660,2252.567139 
	C2834.175537,2252.129883 2825.324951,2253.467041 2828.897217,2242.426025 
	C2829.631592,2240.155518 2824.643799,2241.052490 2822.569336,2238.519287 
z"
        />
        <path
          fill="#6886A3"
          opacity="1.000000"
          stroke="none"
          d="
M3025.455322,1939.684937 
	C3026.000000,1958.041748 3026.000000,1976.083618 3026.000000,1995.062744 
	C3022.180908,1999.589111 3017.410889,1999.326416 3011.838867,1999.177002 
	C3009.848389,1998.996582 3008.713135,1998.953979 3006.740479,1998.938965 
	C2996.067871,1999.468018 2986.243164,1998.269287 2975.590332,1998.780151 
	C2973.692871,1998.854980 2972.611572,1998.877563 2970.729980,1998.907227 
	C2963.626709,1999.218872 2957.320068,1999.565186 2950.457520,1997.464600 
	C2947.056885,1995.998779 2944.439697,1996.198120 2941.210693,1997.922852 
	C2937.608154,1998.953491 2934.607910,1998.768311 2930.866211,1998.887451 
	C2929.072998,1998.908447 2928.045166,1998.915039 2926.237305,1998.941040 
	C2921.780762,1999.329956 2918.120850,1999.094849 2913.691406,1998.544922 
	C2907.404541,1996.904907 2901.726807,1997.230713 2895.308594,1996.968994 
	C2869.964355,1996.606812 2845.416260,1996.288818 2820.057129,1996.595703 
	C2815.086426,1996.221313 2811.023926,1995.173096 2806.111328,1994.643066 
	C2803.368652,1994.223755 2801.383057,1993.956055 2798.618652,1994.564941 
	C2791.495117,1995.456665 2785.122803,1995.380615 2777.952393,1994.879150 
	C2774.088867,1994.301025 2771.076416,1992.653564 2767.095459,1994.501709 
	C2743.433838,1995.214722 2720.597412,1993.759888 2697.731201,1994.165161 
	C2680.494873,1994.470703 2663.229980,1994.283569 2645.141113,1993.164673 
	C2643.123779,1992.997070 2641.962891,1992.977905 2639.920898,1993.062134 
	C2619.464600,1994.905518 2599.872070,1993.573120 2580.286621,1993.427246 
	C2577.284424,1993.404907 2574.309570,1993.035645 2570.916016,1991.139160 
	C2569.537598,1983.232422 2565.319824,1980.284546 2558.009033,1979.874268 
	C2554.017578,1979.650269 2549.181641,1979.366577 2547.234863,1973.639282 
	C2560.287598,1955.267334 2579.542969,1957.887573 2597.711670,1957.506714 
	C2610.926270,1957.229614 2623.859131,1954.399292 2637.832031,1953.648926 
	C2645.446533,1952.280762 2652.262451,1952.293945 2659.857910,1953.383789 
	C2675.105957,1957.541626 2689.985107,1956.250977 2704.644287,1956.100708 
	C2717.496094,1955.968872 2730.300537,1958.124146 2741.979980,1955.736816 
	C2744.224121,1957.346558 2744.008301,1954.738892 2743.455322,1955.952148 
	C2743.376465,1956.125488 2743.100830,1955.910034 2742.494141,1955.701538 
	C2740.823242,1955.126953 2739.619141,1954.144897 2739.168457,1951.704834 
	C2739.465332,1950.378906 2739.838135,1949.432983 2740.304688,1949.442261 
	C2752.633545,1949.688965 2756.972656,1940.638672 2761.911865,1931.138184 
	C2764.060791,1930.209106 2765.583740,1930.215942 2767.820801,1931.088013 
	C2770.864502,1934.083740 2771.217285,1937.438965 2772.229492,1941.403809 
	C2772.954102,1946.423462 2773.028809,1950.653198 2772.272949,1955.612549 
	C2771.753174,1958.338013 2768.663330,1959.557129 2770.919922,1962.599365 
	C2773.367188,1964.797852 2775.097412,1966.919678 2777.750732,1967.648682 
	C2783.805664,1969.312378 2789.303467,1964.654419 2788.917969,1958.371094 
	C2788.633789,1953.734253 2787.374756,1949.295166 2786.430908,1944.790527 
	C2785.665527,1941.138428 2785.234863,1937.575562 2789.088867,1934.326172 
	C2798.466309,1931.502075 2807.350342,1932.863892 2816.965820,1932.819946 
	C2818.800781,1933.059326 2819.833740,1933.200439 2821.593750,1933.499756 
	C2824.957275,1934.351685 2827.166016,1936.629272 2830.915039,1935.672607 
	C2833.526855,1935.263916 2835.447998,1935.069458 2838.131836,1935.086182 
	C2842.378662,1934.641724 2845.892334,1934.837524 2850.127930,1934.928223 
	C2856.264648,1935.318481 2861.327393,1934.283203 2866.264404,1931.886353 
	C2871.670166,1929.261841 2877.244385,1924.223511 2883.057617,1931.346802 
	C2892.087646,1923.070068 2896.225098,1939.345703 2905.064697,1935.700439 
	C2908.644287,1936.914673 2911.492676,1936.462036 2915.091797,1935.525635 
	C2922.230469,1934.644653 2928.616699,1933.891235 2935.443848,1936.820068 
	C2939.543945,1941.764526 2943.265625,1945.049072 2949.237305,1939.270752 
	C2951.034912,1938.155518 2952.382568,1937.772705 2954.494141,1937.495361 
	C2958.452637,1937.475220 2961.692871,1938.274292 2965.647461,1937.410645 
	C2975.556396,1935.771240 2984.407227,1929.163452 2994.812012,1935.566406 
	C2998.644043,1937.553345 3001.857422,1938.981812 3006.091797,1936.645020 
	C3011.024658,1935.371582 3015.077393,1936.668945 3019.783447,1937.890869 
	C3021.724854,1939.208130 3023.209229,1939.684814 3025.455322,1939.684937 
z"
        />
        <path
          fill="#122B42"
          opacity="1.000000"
          stroke="none"
          d="
M2.598794,3508.447754 
	C2.000000,3490.624756 2.000000,3473.249756 2.000000,3454.937256 
	C5.736197,3449.381592 10.578394,3446.579834 16.483147,3446.376221 
	C20.972918,3446.221436 24.947821,3445.359863 28.387003,3442.334961 
	C29.360365,3441.479004 28.191301,3441.572021 29.149319,3441.860596 
	C43.321907,3446.125244 47.607922,3430.878418 58.048473,3427.008057 
	C69.024681,3422.938721 80.865166,3420.345215 90.978699,3414.001465 
	C95.298378,3411.291748 99.646812,3413.629639 103.329399,3414.226318 
	C116.469818,3416.355957 126.028618,3406.852783 138.480255,3405.029297 
	C143.824341,3404.621826 148.931168,3407.445557 153.535400,3402.489746 
	C155.437637,3401.108398 156.836105,3399.828369 158.380142,3399.633545 
	C180.033752,3396.897705 196.173111,3381.872070 215.553009,3374.101318 
	C217.331940,3373.387939 219.438690,3372.891602 219.668198,3370.239258 
	C216.302200,3367.849854 211.745102,3369.587891 207.867065,3366.608643 
	C206.897690,3365.143311 206.753021,3364.156006 207.181732,3362.406006 
	C208.585159,3360.530518 209.977951,3359.897217 212.232147,3359.369629 
	C225.807312,3355.135986 235.924728,3362.922852 245.817307,3368.284424 
	C251.995667,3371.632568 256.692047,3374.302246 263.533295,3371.653076 
	C266.527740,3370.493408 270.075104,3371.942383 273.817505,3370.412598 
	C281.382843,3361.740234 287.623749,3352.809814 294.822357,3344.759521 
	C299.678864,3339.328613 293.287964,3334.693359 296.024292,3329.406494 
	C300.575195,3327.223877 303.552643,3329.220459 306.973511,3332.146973 
	C314.690521,3338.089355 332.836334,3338.300537 341.425201,3332.831787 
	C351.876709,3326.176758 361.777863,3318.374023 375.090759,3318.332031 
	C375.908936,3318.329590 376.554413,3317.072021 377.446838,3315.546875 
	C378.146576,3314.484375 378.473877,3314.087402 379.408905,3313.205078 
	C386.119751,3311.212646 391.945343,3308.762939 398.017700,3307.209961 
	C415.121765,3302.836914 418.728516,3304.356201 429.139709,3319.500732 
	C429.897919,3320.603760 430.592712,3321.722412 431.159576,3323.734131 
	C431.117950,3327.745605 428.390320,3329.554688 426.734070,3332.790527 
	C425.554443,3335.257080 424.304474,3336.685059 421.618896,3337.444824 
	C418.136749,3337.599609 415.463806,3336.868164 412.115601,3336.276855 
	C410.679901,3335.908203 409.893005,3335.641113 407.576630,3334.819580 
	C405.334595,3334.250244 404.663513,3334.364990 403.576111,3334.983154 
	C401.574615,3336.884521 400.569641,3338.806396 399.064972,3341.229004 
	C394.419250,3347.267090 389.496124,3351.155273 381.283447,3348.767578 
	C378.334900,3348.195312 376.205566,3348.162598 374.477570,3351.071045 
	C372.715759,3353.923096 370.932495,3355.959717 369.418640,3358.975830 
	C366.123779,3363.081787 363.460358,3366.856689 358.839020,3368.543701 
	C352.404053,3370.892334 346.605408,3370.962402 341.607788,3364.588135 
	C336.275452,3365.064697 331.315186,3365.084473 328.316345,3370.682617 
	C321.602386,3382.826660 320.899078,3382.921387 309.359314,3378.318604 
	C305.088470,3376.614990 300.192200,3374.576416 296.245544,3380.331787 
	C295.983032,3381.926025 295.763397,3382.831787 295.214661,3384.392090 
	C292.391388,3391.547363 288.370544,3396.373779 281.200073,3398.126953 
	C278.627136,3398.755859 276.554688,3400.712158 274.765717,3402.670654 
	C264.221649,3414.212891 249.364777,3415.805908 235.518845,3419.586182 
	C230.895554,3420.848145 226.543594,3419.538330 223.991486,3414.833008 
	C221.542801,3431.062256 218.387970,3433.411621 202.130737,3437.557129 
	C192.698776,3439.962402 183.638306,3444.377441 174.607590,3450.000977 
	C170.720520,3451.414307 166.427994,3449.887207 164.219788,3454.680664 
	C163.758606,3456.174561 163.390884,3456.988525 162.459702,3458.249023 
	C158.176788,3460.447754 155.093948,3457.628906 151.645844,3457.134766 
	C149.346298,3456.805420 146.125641,3455.152344 146.059235,3460.477051 
	C146.027374,3462.134277 145.872833,3463.074707 145.306030,3464.667969 
	C139.425385,3470.163330 133.246048,3464.242432 126.966766,3466.594482 
	C120.982170,3468.836182 114.974518,3470.227539 110.566193,3475.215088 
	C108.790413,3477.224609 106.599487,3480.187012 104.252441,3479.726074 
	C90.709785,3477.067139 80.725349,3486.727051 68.800552,3489.625244 
	C55.019096,3492.974854 42.564297,3500.713623 28.461124,3503.758301 
	C20.056652,3505.572510 11.913036,3508.600098 2.598794,3508.447754 
z"
        />
        <path
          fill="#2B383B"
          opacity="1.000000"
          stroke="none"
          d="
M2.505596,1862.472900 
	C2.000000,1859.866577 2.000000,1857.733276 2.000000,1854.799927 
	C9.832644,1850.021851 11.534438,1843.512695 10.992013,1835.173706 
	C10.395227,1825.999146 17.784458,1817.098877 27.589638,1814.360107 
	C32.146107,1816.281006 35.454926,1819.426392 40.107269,1819.315186 
	C46.153053,1819.170288 47.907578,1824.443604 50.484455,1828.267700 
	C57.971035,1813.635986 66.928032,1800.138062 69.999535,1783.377441 
	C70.686180,1782.407837 71.014679,1782.059204 71.995102,1781.405518 
	C73.587273,1780.991821 74.517944,1781.036133 76.071655,1781.505859 
	C77.079399,1781.930298 77.451912,1782.124146 78.298203,1782.634033 
	C82.044472,1784.066406 80.030426,1780.728638 81.610313,1779.522461 
	C84.376686,1777.143433 87.056496,1776.375000 90.716560,1776.687012 
	C92.268211,1777.880737 91.296730,1776.520508 92.303757,1777.289795 
	C93.464096,1778.176025 94.627144,1785.212036 97.036423,1777.123901 
	C99.207932,1775.500244 101.038223,1774.908203 103.721069,1774.682373 
	C109.883842,1775.809082 114.920692,1778.022705 120.993973,1776.695190 
	C127.176933,1775.343628 131.711731,1780.512939 134.599106,1784.916504 
	C137.812241,1789.817139 132.101761,1792.148804 129.068787,1794.994141 
	C120.445702,1803.083984 121.570061,1812.099487 132.991394,1823.637451 
	C136.651688,1819.102783 138.036224,1813.771851 139.466049,1808.443604 
	C140.072479,1806.183716 140.765121,1803.739868 143.533432,1803.331421 
	C146.431595,1802.903687 147.528610,1805.222900 148.575180,1807.324219 
	C149.612991,1809.407959 150.263672,1811.784546 151.927780,1813.423462 
	C157.938217,1819.343628 157.619827,1824.014404 149.830658,1828.087891 
	C144.000565,1831.136841 142.684509,1835.558472 147.059875,1841.120239 
	C151.597336,1846.887573 150.426147,1855.511230 156.519150,1860.556030 
	C165.419250,1856.446777 168.051178,1848.441040 169.613876,1839.857178 
	C170.255600,1836.331909 172.867538,1835.894165 175.502228,1835.715332 
	C180.111099,1835.402588 184.766617,1835.678467 189.197586,1834.515259 
	C189.609146,1833.785156 189.490677,1833.182251 188.793625,1832.796997 
	C185.194824,1830.807739 178.760056,1830.329224 180.056870,1824.988281 
	C181.287476,1819.919922 187.421280,1819.678345 192.016891,1820.331543 
	C198.666092,1821.276733 200.140884,1819.165405 197.323898,1813.390869 
	C191.907410,1802.287354 191.887390,1802.297119 200.728622,1792.815796 
	C189.847549,1789.645264 191.727112,1778.605835 187.089127,1771.412231 
	C186.011795,1769.741455 186.269272,1767.155640 188.221024,1766.054321 
	C191.865036,1763.997925 195.275955,1765.883423 199.141388,1767.861938 
	C200.010056,1768.536011 200.324814,1768.831421 201.020340,1769.616211 
	C202.050186,1771.007324 202.634033,1771.982666 204.396820,1772.232178 
	C206.143097,1772.064453 207.327133,1772.068237 209.124771,1772.351562 
	C211.369644,1772.998535 212.756302,1774.013794 214.930191,1774.855225 
	C216.424942,1775.137329 217.294846,1775.256348 218.834015,1775.491211 
	C229.503876,1778.080688 229.488022,1785.373657 226.764465,1793.648682 
	C231.584854,1792.945190 234.632690,1791.361572 235.086472,1787.705322 
	C235.785522,1782.073120 239.498917,1781.953857 243.633621,1782.129517 
	C247.874374,1782.309570 251.539017,1782.842041 250.808319,1788.798462 
	C250.361145,1792.443481 254.075943,1792.199829 257.016510,1793.351074 
	C258.134735,1793.709595 258.571075,1793.889404 259.617432,1794.432617 
	C262.163177,1796.214600 263.129211,1798.475098 265.281616,1800.636230 
	C267.160553,1803.041504 268.579102,1804.913696 269.833038,1807.717651 
	C270.391602,1818.982422 267.194489,1829.327148 268.181244,1840.058960 
	C268.662201,1845.289551 269.636719,1846.885254 275.215027,1846.192017 
	C285.298004,1844.939453 295.291779,1842.500854 305.714294,1844.183594 
	C310.850708,1845.012939 313.471649,1839.530884 316.776917,1836.542847 
	C327.700378,1826.668213 329.068665,1826.239380 342.697754,1832.497192 
	C345.273743,1832.887573 347.086609,1833.409790 349.444458,1834.541260 
	C352.686859,1837.256714 353.395752,1840.412231 353.436707,1843.920776 
	C361.821503,1838.286499 362.642578,1838.632324 368.487274,1850.188843 
	C369.061279,1840.837769 381.775085,1841.345215 382.965790,1831.800049 
	C384.002258,1831.269531 384.461975,1831.154541 385.626221,1831.133789 
	C388.862549,1832.285889 390.621246,1834.326172 392.326202,1836.302490 
	C397.384949,1842.166016 403.063538,1843.548096 409.796173,1839.253174 
	C410.903961,1838.546509 412.143219,1838.040894 413.379913,1837.577026 
	C416.702972,1836.330566 420.037750,1835.970459 422.216187,1839.385254 
	C424.520691,1842.997559 422.270752,1845.556274 419.570129,1847.814819 
	C417.818878,1849.279419 415.375305,1849.789673 414.027161,1851.830566 
	C422.653748,1854.320190 423.561707,1854.049683 424.618774,1846.175293 
	C425.170074,1842.068359 426.719177,1840.155640 431.478699,1840.386719 
	C445.041473,1850.415527 444.161224,1863.334473 430.235718,1869.995361 
	C425.817749,1872.108521 421.769928,1874.968262 416.411224,1876.458496 
	C413.707611,1875.800659 414.683868,1868.431396 409.408844,1873.862061 
	C408.502136,1874.795532 400.902710,1874.551880 396.560455,1871.751831 
	C392.654510,1869.233398 387.524597,1865.814575 385.772003,1874.889282 
	C384.813019,1879.854614 381.402313,1878.811279 378.778137,1875.376343 
	C378.015961,1874.378540 378.175476,1872.924194 377.132446,1872.067383 
	C374.592499,1875.535278 371.587219,1880.626831 368.326508,1873.382568 
	C365.925964,1868.049683 360.304565,1874.127563 357.962891,1869.229980 
	C356.963745,1868.965210 356.562897,1868.875610 355.591858,1868.622070 
	C350.741821,1869.460571 350.707916,1879.619873 342.921936,1873.339355 
	C341.974243,1870.333008 341.291809,1867.901611 340.884430,1865.403442 
	C341.125183,1867.193115 341.703339,1868.943115 341.588379,1871.420532 
	C340.476654,1874.218506 339.130096,1876.667236 336.574890,1875.911743 
	C333.506378,1875.004517 334.261353,1871.661987 333.884491,1869.754028 
	C332.669769,1873.937622 336.565979,1877.841431 335.911804,1883.210693 
	C334.259918,1887.452515 331.496735,1888.343628 327.266602,1887.392334 
	C323.945099,1884.531250 325.451904,1881.253052 324.798767,1877.645508 
	C323.938019,1875.816406 324.688232,1877.310913 324.041962,1876.145996 
	C323.561523,1875.279785 323.051270,1874.448120 323.147430,1873.404419 
	C323.039398,1874.396973 323.382233,1875.244019 324.373962,1876.581299 
	C325.154480,1879.567871 324.945435,1881.994629 324.814392,1885.171875 
	C324.322021,1886.287354 323.953918,1886.647461 322.869659,1887.313965 
	C321.109222,1887.786743 320.079651,1887.721313 318.373352,1887.058716 
	C316.958710,1885.960449 316.405304,1885.118042 315.841278,1883.456299 
	C315.786743,1878.558228 315.068390,1874.475464 313.946198,1870.454346 
	C313.910400,1870.326050 314.289459,1869.287231 313.545044,1869.956421 
	C313.202576,1870.264282 313.291412,1871.156860 312.596558,1872.295166 
	C309.555328,1874.711548 306.521576,1875.115479 303.647339,1874.167236 
	C300.147797,1873.012695 298.134674,1872.615601 294.620605,1875.855225 
	C288.831604,1881.192261 286.701904,1873.635864 283.041382,1869.676880 
	C278.154266,1880.366821 269.397949,1878.942627 261.138519,1876.214966 
	C255.450119,1874.336304 250.578903,1874.066406 244.916504,1876.070801 
	C237.887558,1878.558960 230.366501,1876.581177 223.240082,1875.977295 
	C210.784607,1874.921631 198.159485,1878.642944 185.270386,1874.132324 
	C182.525452,1872.423584 180.747086,1870.139038 177.086349,1871.301514 
	C176.059357,1871.606567 175.646988,1871.717651 174.608826,1871.939941 
	C170.992783,1872.024902 168.361618,1872.938599 165.608887,1875.370483 
	C163.337128,1876.474976 161.528687,1876.745483 158.989075,1876.874023 
	C151.620972,1877.319458 145.347122,1873.636841 138.523666,1875.160034 
	C134.865494,1875.976562 131.776398,1874.591675 130.788483,1870.690552 
	C129.738297,1866.543701 132.383484,1864.611450 135.900009,1863.243408 
	C130.223099,1864.377808 123.852859,1859.895874 118.839615,1866.428223 
	C118.737083,1867.651978 118.597359,1868.143677 117.993454,1869.276001 
	C111.178612,1876.473511 102.959366,1874.766846 94.303772,1874.990967 
	C86.585083,1875.239258 79.790398,1875.390381 80.131775,1864.693604 
	C80.311035,1862.743408 80.645691,1861.711548 81.666611,1860.139526 
	C85.435684,1856.723022 89.003944,1854.029297 93.202805,1853.149048 
	C102.388321,1851.223389 105.088470,1843.475586 109.005409,1836.717529 
	C110.135277,1834.768066 110.368011,1832.947876 108.028358,1831.729004 
	C103.687798,1829.467773 104.289124,1826.202515 105.799194,1822.453613 
	C107.275688,1818.788208 105.667778,1815.567749 102.477188,1814.101196 
	C98.825302,1812.422607 98.517357,1816.551025 97.250542,1818.488647 
	C94.915207,1822.060669 92.319611,1825.446655 90.953949,1829.670654 
	C88.928459,1835.935181 88.685005,1835.856445 81.352715,1836.317017 
	C82.081383,1842.085205 88.033859,1846.572754 85.229630,1853.676514 
	C83.557327,1856.977661 77.750603,1855.910034 79.418579,1861.632080 
	C79.462616,1872.257324 67.628876,1878.093872 57.757591,1873.547974 
	C47.789993,1868.957886 37.479355,1865.745361 26.814680,1871.970947 
	C23.634220,1873.827515 20.220451,1874.029663 17.594839,1871.331299 
	C13.476612,1867.099121 7.675011,1866.064819 2.505596,1862.472900 
z"
        />
        <path
          fill="#1B2B3A"
          opacity="1.000000"
          stroke="none"
          d="
M2.471684,3144.447998 
	C2.000000,3143.111084 2.000000,3142.222168 2.000000,3140.666504 
	C4.464489,3136.293457 8.692990,3136.414307 12.689327,3135.321777 
	C9.918584,3132.283936 5.557351,3133.566162 2.498809,3130.470459 
	C2.000000,3127.866699 2.000000,3125.733154 2.000000,3122.799805 
	C9.613076,3118.251221 18.102600,3118.888428 26.150921,3117.341064 
	C30.092722,3116.583008 34.370705,3117.664307 36.323360,3121.961670 
	C38.134995,3125.948242 34.633781,3127.914062 32.463364,3130.298340 
	C31.365707,3131.504639 30.135904,3132.660400 30.791142,3135.280762 
	C37.062702,3136.388184 42.950863,3134.789062 48.753773,3132.297607 
	C64.652351,3125.471924 80.641983,3119.007568 98.603592,3121.006104 
	C93.380234,3120.292725 86.083313,3121.038818 85.661636,3114.643555 
	C85.206970,3107.748291 92.892082,3108.020020 97.820251,3107.355225 
	C110.695129,3105.618408 123.723640,3103.785400 136.037994,3100.258057 
	C149.313431,3096.455078 157.119308,3102.858154 164.524185,3110.906494 
	C167.444336,3114.080322 169.849167,3114.911133 173.670670,3115.117920 
	C184.429916,3115.700684 194.888947,3113.631836 205.423615,3112.156738 
	C208.720963,3111.695068 212.053864,3110.635010 215.325119,3111.035156 
	C229.083405,3112.718262 241.043808,3110.013672 252.164917,3100.753662 
	C258.905731,3095.140869 268.953339,3093.846924 277.302338,3092.137451 
	C299.896393,3087.511230 320.075226,3076.278809 342.760040,3071.395996 
	C363.381561,3066.957031 383.378937,3066.787842 403.847717,3067.087158 
	C406.530975,3067.126221 409.116241,3067.847412 412.024597,3069.748291 
	C419.024139,3082.682617 419.024261,3082.683838 434.428986,3081.276855 
	C439.389130,3080.823730 444.357178,3081.062988 449.012634,3081.067627 
	C440.045959,3080.140869 431.352295,3076.730713 422.344818,3074.639160 
	C419.353027,3073.944824 416.649292,3072.498047 417.394592,3067.888184 
	C420.449158,3064.168213 424.099182,3063.373291 428.748169,3063.048340 
	C430.636261,3063.073242 431.708710,3063.072021 433.567444,3063.032471 
	C447.933990,3062.534424 460.987579,3057.289062 474.788635,3058.597412 
	C476.999084,3058.807129 479.870819,3058.464355 479.402740,3054.998047 
	C479.015045,3052.127441 477.495026,3049.949219 474.118805,3050.326416 
	C454.249420,3052.544922 434.366119,3050.371094 414.492767,3050.617676 
	C410.854919,3050.662598 407.218811,3051.115723 403.561920,3050.887451 
	C394.960327,3050.349609 386.331360,3049.294434 378.663879,3054.991211 
	C376.509766,3056.591553 373.617004,3056.447021 370.252686,3055.235840 
	C367.076233,3051.489502 364.169800,3050.413574 359.591125,3050.218994 
	C344.640137,3049.584229 329.870880,3049.500244 315.146118,3052.794189 
	C309.954315,3053.955811 304.557861,3052.861084 299.555573,3050.758301 
	C297.021912,3049.693115 294.796539,3048.218018 294.862915,3045.025879 
	C294.932861,3041.663818 297.308289,3040.662354 300.097015,3040.110107 
	C302.018463,3039.729492 304.812439,3040.357422 305.695068,3038.670410 
	C311.934937,3026.742188 324.226440,3028.983154 335.054688,3025.747070 
	C345.863800,3022.210205 356.309479,3021.117676 367.527496,3019.629395 
	C373.272034,3018.664795 378.221771,3018.414795 383.990234,3019.009521 
	C390.104858,3018.450195 396.088531,3021.118408 401.086975,3015.370605 
	C406.512512,3012.518555 411.204132,3014.152100 416.688660,3015.491211 
	C420.562439,3016.680420 423.250793,3018.600342 427.160950,3019.725098 
	C436.099518,3023.170166 444.409607,3023.574463 453.204254,3023.208496 
	C468.943085,3022.554199 484.746521,3025.349365 500.433777,3020.891602 
	C506.742096,3019.099365 513.284851,3017.918701 519.644348,3016.101318 
	C526.172729,3014.235596 532.724365,3014.504639 539.994690,3016.724121 
	C545.417236,3017.261230 550.117126,3016.508057 554.671692,3017.645752 
	C557.354736,3018.315918 559.845093,3019.697266 559.852966,3022.841309 
	C559.859802,3025.570068 557.355652,3026.672363 555.237671,3027.415527 
	C548.619751,3029.737305 541.615417,3030.058350 534.758240,3031.414551 
	C562.158142,3025.550293 589.879150,3026.406250 617.605835,3026.931885 
	C626.877869,3027.107422 636.132751,3026.859131 645.965149,3029.539551 
	C647.732300,3030.240479 648.614502,3030.846680 649.838623,3032.264648 
	C651.439087,3039.169434 646.703430,3040.769287 642.434692,3042.044678 
	C638.019897,3043.364014 633.695129,3044.503906 629.959412,3048.593018 
	C621.121765,3054.510986 614.585815,3062.418457 604.457764,3066.403809 
	C598.663269,3066.677246 594.061462,3064.812256 588.680786,3063.197021 
	C578.908691,3060.550537 580.207703,3068.555664 577.315674,3073.384277 
	C573.874329,3075.104248 570.795959,3075.695557 567.895508,3075.125732 
	C553.525085,3072.303223 539.418762,3074.843262 524.455200,3076.836914 
	C516.530701,3077.222656 510.607635,3079.110840 509.198273,3087.395996 
	C508.630707,3090.732422 506.112244,3091.254395 503.141479,3091.029053 
	C479.496368,3089.233887 456.149078,3093.599121 432.633728,3094.573242 
	C425.307648,3094.876465 418.026276,3095.599121 410.756989,3094.278320 
	C406.086243,3093.429199 401.454071,3092.065430 398.932922,3087.670410 
	C395.277405,3081.297852 389.643372,3080.599365 383.423157,3081.828125 
	C370.666199,3084.348633 357.741180,3085.449463 344.833771,3086.653076 
	C340.153503,3087.089844 336.995605,3090.025146 333.348480,3092.250000 
	C326.420349,3096.476074 317.991882,3096.463867 310.157166,3100.392090 
	C305.123627,3101.268555 300.922852,3102.551758 295.876587,3102.840820 
	C293.588409,3103.073975 291.855682,3102.732666 290.008179,3104.353027 
	C289.266113,3105.073242 288.973022,3105.375000 288.237976,3106.145264 
	C286.289307,3108.343262 285.328979,3110.519775 282.808502,3112.378418 
	C279.811127,3114.159424 277.205109,3115.045898 274.556610,3114.953369 
	C262.654755,3114.538086 251.998932,3117.586670 241.221436,3123.041260 
	C231.305511,3128.059814 219.595535,3125.444824 208.684738,3124.917236 
	C198.234940,3124.411621 188.095032,3124.672363 178.447449,3129.301514 
	C174.431946,3131.228516 170.016632,3131.629395 165.729477,3130.815186 
	C157.058426,3129.167969 148.511887,3129.787842 139.185699,3131.374512 
	C135.043625,3131.514648 133.574554,3127.996582 130.703079,3127.297852 
	C127.826485,3126.598145 125.978546,3127.271729 127.714790,3131.501953 
	C128.284149,3137.339844 126.049210,3140.071533 120.008820,3139.830078 
	C99.504387,3131.484863 82.677116,3126.169678 65.859604,3141.975586 
	C62.054211,3145.552002 57.277832,3147.602295 52.206440,3147.219482 
	C42.291649,3146.470947 35.896118,3151.457520 29.685213,3160.015625 
	C34.229427,3158.561035 37.825005,3156.907471 41.999092,3159.343262 
	C43.681091,3168.974365 37.013721,3172.416504 30.388607,3174.103516 
	C21.540260,3176.356689 12.435485,3177.992188 2.638784,3178.460938 
	C2.000000,3172.599854 2.000000,3167.199951 2.000000,3160.899902 
	C4.123235,3156.616211 10.266445,3157.803711 10.751710,3151.771973 
	C12.348653,3143.812744 4.530319,3148.319824 2.471684,3144.447998 
z"
        />
        <path
          fill="#F0EBDD"
          opacity="1.000000"
          stroke="none"
          d="
M1292.469971,4033.441895 
	C1249.291504,4034.000000 1206.583130,4034.000000 1162.937256,4034.000000 
	C1156.069458,4030.823242 1151.062622,4026.847168 1148.500122,4020.248535 
	C1146.207520,4014.344971 1141.248169,4011.179932 1135.765747,4008.424561 
	C1130.922119,4005.990234 1124.991211,4004.183838 1125.246826,3996.378662 
	C1129.095581,3993.208984 1132.580811,3995.188477 1136.082275,3995.847412 
	C1143.000488,3997.149902 1149.860107,3998.924072 1154.633301,3991.055176 
	C1156.852905,3987.395508 1160.497803,3988.637695 1163.481323,3990.453125 
	C1168.301514,3993.386719 1174.200073,3995.608887 1177.349731,3999.996826 
	C1184.700806,4010.238281 1196.086670,4013.232422 1206.927368,4017.808594 
	C1197.199341,4008.306396 1190.655762,3995.578857 1177.252197,3989.842529 
	C1176.027344,3989.318604 1174.566650,3988.970947 1173.761719,3987.974609 
	C1162.499023,3974.031494 1147.673828,3962.557861 1143.066162,3943.267334 
	C1142.666870,3938.381348 1140.843872,3933.524170 1147.418823,3932.376465 
	C1153.159546,3931.374756 1161.697998,3937.078125 1162.949707,3942.637695 
	C1165.341919,3953.263428 1169.401001,3956.949951 1181.853271,3958.371582 
	C1170.954346,3953.378906 1171.400146,3941.274658 1164.963013,3933.479248 
	C1164.117554,3932.455811 1164.321167,3933.889404 1164.367188,3932.765381 
	C1164.644287,3925.975098 1165.070923,3919.535889 1159.328491,3914.014160 
	C1157.748291,3912.494873 1170.396240,3903.691162 1175.334717,3905.035156 
	C1182.484009,3906.980713 1188.244873,3905.297119 1194.231079,3901.778564 
	C1197.588257,3899.805176 1201.528076,3899.905762 1204.323120,3903.106934 
	C1209.137695,3908.621094 1215.348877,3911.420410 1222.114380,3913.611084 
	C1225.479004,3914.700439 1229.952637,3916.266846 1228.393677,3920.973633 
	C1226.996216,3925.193115 1222.710571,3922.916748 1219.587891,3922.977539 
	C1218.922852,3922.990479 1218.257812,3922.912598 1217.593140,3922.873779 
	C1212.414917,3922.569336 1206.395752,3920.424316 1203.478882,3926.769775 
	C1201.763184,3930.502197 1211.153687,3939.637207 1216.536499,3940.358154 
	C1213.106934,3939.659912 1207.872437,3940.161621 1209.146606,3934.006104 
	C1210.263672,3928.608887 1212.861328,3922.930420 1219.591797,3924.343506 
	C1227.557861,3926.016113 1229.791626,3922.470459 1232.226318,3915.813721 
	C1235.217896,3907.634521 1242.463867,3904.292236 1251.807617,3905.185547 
	C1255.018677,3910.597656 1249.447266,3912.366699 1247.686523,3917.611572 
	C1260.293579,3913.707275 1268.737305,3923.473145 1279.745361,3926.071045 
	C1279.371338,3920.653564 1271.044678,3917.251465 1277.044312,3912.301025 
	C1282.053223,3908.168701 1286.242676,3913.658691 1290.528442,3915.578369 
	C1294.199707,3917.222656 1297.143433,3919.441406 1297.343140,3924.432129 
	C1297.673340,3932.687256 1304.514404,3935.885254 1312.313721,3932.107178 
	C1321.141724,3927.830322 1330.025635,3927.188965 1339.135742,3932.982910 
	C1340.549927,3938.182373 1338.942871,3942.280029 1336.548462,3945.873047 
	C1332.271851,3952.290039 1329.729858,3959.540039 1326.054077,3966.212891 
	C1324.213989,3969.553223 1321.668213,3974.198486 1317.057739,3972.384521 
	C1312.125854,3970.444336 1313.300171,3965.307129 1313.932739,3960.987549 
	C1314.172485,3959.350586 1315.749756,3958.269287 1315.710205,3956.548828 
	C1309.733154,3962.001953 1303.165527,3963.606445 1295.492188,3960.345459 
	C1290.595215,3958.264404 1285.324829,3957.244141 1280.150146,3956.057861 
	C1277.725952,3955.502441 1275.422607,3954.840088 1274.651245,3952.394531 
	C1270.523804,3939.306885 1259.160767,3939.405762 1248.720703,3938.404541 
	C1244.521240,3938.001953 1240.135864,3938.050537 1235.795166,3938.254639 
	C1239.236328,3948.677490 1242.703125,3951.243896 1252.333008,3949.943848 
	C1263.914429,3948.380859 1273.709839,3954.581787 1283.713135,3958.175049 
	C1292.523926,3961.340088 1301.336792,3965.651855 1309.855713,3970.110596 
	C1316.328125,3973.498535 1320.694458,3978.569580 1317.840332,3985.176758 
	C1313.846802,3994.422119 1318.557251,4000.965820 1322.153564,4008.092041 
	C1323.483398,4010.727051 1326.238037,4012.898926 1324.307861,4016.116211 
	C1322.430176,4019.245117 1319.102173,4020.486084 1315.681885,4020.120850 
	C1308.781006,4019.383789 1301.652954,4019.262939 1300.251221,4009.524170 
	C1299.248413,4002.556641 1296.362793,4001.937256 1291.515747,4007.199219 
	C1286.777222,4012.342285 1282.121338,4012.894775 1276.420410,4008.335938 
	C1276.108032,4008.041992 1275.697021,4007.889160 1276.035522,4008.135010 
	C1280.014160,4012.296143 1286.091919,4012.788818 1289.183838,4017.441650 
	C1292.292725,4022.118652 1295.810181,4026.735352 1292.469971,4033.441895 
z"
        />
        <path
          fill="#0D1C2A"
          opacity="1.000000"
          stroke="none"
          d="
M521.274536,3074.306396 
	C526.775269,3074.749268 531.366150,3073.058594 535.658508,3071.803955 
	C549.293762,3067.819336 562.678284,3072.744141 577.062500,3071.956543 
	C581.783020,3068.933350 584.783630,3065.238770 587.554993,3060.494629 
	C592.936707,3061.356201 597.856323,3062.692871 603.559814,3064.062988 
	C606.874390,3068.994873 603.871643,3072.578125 600.973511,3075.634766 
	C595.949524,3080.934082 591.331055,3086.659668 584.923950,3091.605469 
	C582.361084,3092.620605 580.393677,3092.795898 577.712524,3092.907715 
	C571.788025,3092.569336 568.907104,3093.511475 573.508240,3098.950684 
	C574.583191,3100.221436 574.791382,3101.948975 574.308594,3104.308594 
	C572.563965,3107.237549 570.316406,3108.060059 567.037964,3108.336426 
	C560.112183,3107.535156 553.924316,3106.049316 547.034424,3108.287598 
	C553.368286,3110.725342 561.239746,3108.399414 564.798706,3116.445312 
	C558.961792,3124.800781 551.265137,3122.766846 542.920898,3121.265625 
	C527.568787,3120.667236 512.978516,3119.912354 498.611206,3117.643799 
	C490.852203,3116.418457 485.568542,3120.452148 480.549622,3124.159668 
	C471.709412,3130.689941 461.443573,3133.734375 451.398499,3135.777588 
	C439.833710,3138.129395 428.019043,3140.161133 416.273041,3141.416016 
	C409.431793,3142.146729 404.965851,3143.427246 402.347229,3150.684082 
	C395.897949,3154.268311 389.765778,3153.541748 382.861847,3152.373535 
	C376.054382,3147.561768 369.899597,3147.438965 362.918701,3152.011719 
	C352.395874,3153.978516 342.475037,3153.491211 331.841705,3153.774170 
	C326.175110,3148.529053 320.075562,3147.780029 313.284332,3146.750977 
	C297.351715,3144.336426 281.238983,3143.974609 265.314636,3140.053955 
	C253.728928,3137.201416 240.409134,3139.172119 228.560257,3143.625977 
	C223.161972,3145.655029 217.780945,3148.037109 212.234863,3146.824219 
	C203.321274,3144.874512 195.252289,3146.987793 187.204834,3150.061768 
	C177.668442,3153.704590 167.476532,3153.885010 157.311066,3155.562012 
	C166.811462,3158.623535 180.414917,3150.402100 183.883301,3167.499756 
	C183.541061,3168.778320 183.324448,3169.272949 182.561600,3170.381836 
	C179.565720,3172.635010 176.814575,3172.774902 173.198944,3172.512451 
	C155.836517,3169.681396 139.801025,3173.433350 124.893486,3179.337646 
	C106.056877,3186.797852 87.691910,3190.548340 67.996010,3184.284180 
	C65.061668,3183.350830 62.096451,3184.645752 59.192886,3185.306396 
	C48.844540,3187.661865 38.497082,3190.803467 27.979454,3185.146973 
	C30.525534,3188.689697 34.743847,3188.395264 37.837154,3190.293213 
	C41.765888,3192.703613 41.734631,3195.337158 37.924999,3197.890137 
	C31.460407,3202.222656 22.720505,3201.746582 17.329992,3195.586914 
	C13.332911,3191.019775 7.391468,3192.172363 2.589901,3188.491211 
	C2.000000,3185.222168 2.000000,3182.444336 1.999980,3178.833252 
	C4.165259,3177.255615 6.402423,3176.670654 8.480701,3175.733154 
	C12.632047,3173.860840 16.371670,3172.535400 21.489168,3172.927002 
	C29.973982,3173.576416 37.421215,3169.595703 41.985962,3160.877686 
	C46.669037,3155.246582 52.735184,3157.126709 59.054638,3156.582520 
	C68.283829,3155.623047 77.022491,3155.759521 84.571091,3149.205078 
	C94.972878,3143.203613 104.410568,3136.584473 117.308174,3137.892090 
	C122.621422,3138.261230 126.558571,3137.692627 126.018845,3130.817871 
	C128.528397,3127.400635 131.382599,3127.342285 135.203018,3129.393066 
	C145.133789,3126.672363 154.235809,3124.558594 164.176636,3127.413330 
	C169.058517,3128.815430 174.238632,3128.633057 179.346786,3125.195068 
	C186.419006,3120.434570 195.421768,3120.424561 203.364273,3121.155029 
	C214.033829,3122.136230 224.617065,3121.591309 235.228607,3121.910400 
	C239.447571,3122.037354 242.818161,3119.724365 246.002670,3117.222900 
	C251.178680,3113.156494 256.911346,3111.480469 263.605927,3111.872314 
	C269.857178,3112.238037 276.285187,3112.831055 283.179810,3110.149902 
	C290.914673,3112.049805 285.986603,3106.647217 287.561279,3104.525146 
	C287.995117,3104.000000 288.000000,3104.000000 287.998169,3104.000000 
	C289.061096,3102.935303 290.136200,3101.880859 291.634949,3100.452637 
	C296.990540,3100.602295 301.637177,3099.017578 307.173676,3098.055176 
	C313.285645,3101.783447 318.766663,3095.880615 324.850647,3098.583496 
	C338.846924,3102.374756 352.402496,3102.598145 365.977631,3102.648193 
	C370.311493,3102.664062 374.615356,3102.041504 379.745453,3102.203125 
	C383.927063,3103.291748 386.913208,3105.756104 391.525574,3105.055664 
	C395.883362,3104.558838 399.505707,3104.592285 403.877686,3104.458496 
	C410.485779,3110.563721 409.603058,3114.923340 401.342987,3116.230469 
	C381.106659,3119.432373 361.198975,3126.015381 340.238342,3122.805908 
	C335.632660,3122.101074 330.954529,3122.542480 326.246185,3123.579102 
	C326.992493,3126.115479 329.338409,3126.105957 330.952820,3126.083496 
	C350.346008,3125.815674 369.974182,3131.348877 389.098022,3123.704346 
	C392.175781,3122.474365 395.695648,3122.609619 398.992157,3123.006348 
	C405.177612,3123.750732 410.793884,3123.044434 415.821075,3118.855713 
	C418.110382,3116.947998 421.467133,3115.483398 424.194397,3116.080566 
	C443.604370,3120.333008 459.011292,3109.172119 475.768066,3103.211182 
	C483.806824,3100.351562 492.401886,3100.206543 500.424530,3101.365234 
	C513.491211,3103.252197 525.397583,3104.285645 533.685547,3090.385742 
	C538.212524,3088.421631 542.907227,3090.215088 546.959534,3086.974854 
	C539.028381,3082.673340 530.665161,3084.533203 522.067139,3083.168457 
	C518.669922,3080.358887 517.795898,3077.691406 521.274536,3074.306396 
z"
        />
        <path
          fill="#7995AC"
          opacity="1.000000"
          stroke="none"
          d="
M2994.484375,2169.458496 
	C2990.872559,2170.115723 2987.882324,2169.661377 2986.332764,2166.516113 
	C2983.898438,2161.574951 2979.411865,2161.177979 2975.223145,2162.328613 
	C2970.824951,2163.536621 2967.738770,2162.981445 2964.485840,2159.794922 
	C2960.969727,2156.350586 2956.279053,2155.698730 2951.454102,2155.980957 
	C2946.434082,2156.274414 2941.309570,2156.266357 2936.807373,2153.857910 
	C2930.762207,2150.624023 2925.121094,2153.686035 2919.328369,2154.298340 
	C2916.924805,2154.552246 2916.077637,2156.439941 2916.332275,2158.661377 
	C2916.616455,2161.140625 2918.446533,2161.721680 2920.631836,2161.838379 
	C2922.833252,2161.955566 2924.978271,2159.618896 2927.214111,2161.685791 
	C2925.433838,2166.164795 2923.790527,2173.282959 2924.319092,2176.492188 
	C2907.885986,2176.492188 2891.635742,2176.492188 2874.377686,2176.492188 
	C2876.227051,2180.570068 2879.445557,2180.261963 2881.326660,2181.814697 
	C2885.657471,2185.389893 2884.752441,2191.714600 2879.468018,2193.663086 
	C2876.989990,2194.577148 2874.216797,2195.876709 2871.787109,2195.530518 
	C2861.604492,2194.080566 2851.412842,2195.682373 2841.354004,2195.097656 
	C2832.395264,2194.576904 2823.619385,2193.208984 2814.757080,2195.445312 
	C2809.657715,2196.731934 2805.243408,2195.394531 2799.597900,2191.390137 
	C2806.400146,2190.181641 2810.663818,2187.185059 2815.151367,2184.633789 
	C2821.453857,2181.050537 2829.896240,2181.075195 2837.293457,2182.509277 
	C2842.543213,2183.526855 2847.875244,2183.410156 2853.022461,2184.667480 
	C2855.757324,2185.335938 2857.772461,2185.202148 2857.672363,2181.772949 
	C2857.581055,2178.651123 2855.369385,2177.991211 2852.797363,2178.272217 
	C2838.943848,2179.786133 2825.151123,2177.128418 2811.311523,2177.682861 
	C2810.757812,2177.704834 2810.178223,2177.089600 2809.599609,2176.765137 
	C2809.474854,2173.532471 2811.986084,2174.089844 2813.765869,2174.071045 
	C2823.087646,2173.971680 2832.412354,2173.897217 2841.732910,2174.032959 
	C2851.254883,2174.171387 2860.716309,2172.267090 2870.311035,2173.880371 
	C2877.489990,2175.087646 2884.248779,2173.507812 2889.430908,2167.746582 
	C2891.187744,2165.793457 2894.789551,2166.485596 2895.875977,2162.682617 
	C2896.987793,2160.815186 2898.302979,2160.401855 2900.443848,2160.440674 
	C2902.619873,2160.890625 2903.462402,2162.202637 2904.660400,2162.988770 
	C2905.755127,2163.707520 2906.683350,2162.605225 2905.356934,2162.741699 
	C2904.030518,2162.878174 2905.677002,2160.969482 2904.558350,2161.553711 
	C2902.490479,2155.910889 2898.154297,2154.570557 2893.481689,2153.365723 
	C2880.724854,2150.076416 2870.747314,2142.857178 2864.950439,2130.920898 
	C2862.518066,2125.912109 2859.782959,2122.848877 2853.942627,2122.628174 
	C2850.209229,2122.487305 2846.156006,2121.208252 2846.883545,2116.227295 
	C2847.573975,2111.501221 2851.844727,2111.511719 2855.500488,2111.291260 
	C2858.505615,2111.109863 2861.452393,2111.826660 2864.406738,2112.301514 
	C2875.144775,2114.026611 2884.130127,2111.486084 2890.188232,2101.758301 
	C2878.789062,2092.036621 2879.455566,2081.308105 2891.772949,2075.572021 
	C2884.752197,2066.931885 2884.912842,2066.333740 2896.999512,2058.290527 
	C2902.012207,2056.417725 2906.822998,2057.984863 2913.549805,2055.995361 
	C2903.498047,2052.158447 2897.500488,2044.825195 2887.500977,2046.104126 
	C2882.651611,2046.724365 2878.998291,2043.820068 2878.818115,2038.627930 
	C2878.637207,2033.417969 2882.258057,2030.611450 2886.990967,2030.363281 
	C2897.750244,2029.799316 2907.868896,2027.235718 2917.717041,2022.901733 
	C2923.680664,2020.277344 2929.537109,2021.892090 2934.541016,2025.839233 
	C2938.812500,2029.208252 2943.114990,2028.907593 2948.395264,2028.514160 
	C2964.125000,2027.342651 2980.021484,2027.089355 2995.718506,2028.469116 
	C3002.327148,2029.050049 3010.607910,2030.957886 3011.240234,2041.315552 
	C3009.692383,2044.360840 3007.247559,2045.691284 3005.805664,2048.010742 
	C3000.497070,2056.549561 3000.321289,2056.610352 2992.858398,2049.226318 
	C2990.566406,2046.958374 2988.479980,2047.316650 2986.840088,2049.077393 
	C2980.044922,2056.373535 2971.209717,2056.926270 2962.119629,2057.610107 
	C2958.324219,2057.895752 2951.327637,2057.520996 2952.271973,2062.826904 
	C2953.174561,2067.897949 2959.319092,2065.909424 2963.442627,2064.556152 
	C2971.114014,2062.038818 2977.884277,2063.221436 2983.585205,2069.175293 
	C2987.300781,2073.055664 2989.032959,2076.932373 2983.508301,2080.748047 
	C2980.536377,2082.800537 2979.503906,2085.864990 2978.776367,2089.217529 
	C2977.935303,2093.093506 2975.857178,2096.010254 2982.351318,2098.302979 
	C2989.681885,2100.890869 2989.992432,2107.258057 2984.697754,2114.695312 
	C2998.187256,2117.184570 3012.237061,2112.686523 3025.482910,2119.528809 
	C3026.000000,2121.500000 3026.000000,2123.000000 3026.000000,2125.250000 
	C3018.286377,2130.075439 3010.354248,2126.512451 3002.589600,2126.330566 
	C2993.973145,2126.128906 2988.441650,2128.625732 2986.592529,2137.510010 
	C2989.387207,2138.987793 2992.629639,2137.651367 2995.226807,2139.439209 
	C2999.278809,2142.228516 3004.141602,2146.746582 3000.714600,2150.572754 
	C2995.567139,2156.319092 2997.885986,2163.229492 2994.484375,2169.458496 
z"
        />
        <path
          fill="#0D171F"
          opacity="1.000000"
          stroke="none"
          d="
M2.554059,3574.548340 
	C2.000000,3559.958008 2.000000,3545.916260 2.000000,3530.937012 
	C12.400186,3524.029053 23.569674,3521.150879 35.535393,3522.367188 
	C37.638573,3522.581299 39.234909,3523.739502 40.145142,3520.938232 
	C40.554630,3519.678467 41.047913,3517.915283 42.319759,3517.767334 
	C56.238461,3516.149658 65.570778,3504.059570 79.450027,3501.952637 
	C89.592918,3500.412598 99.485222,3497.215088 107.552414,3490.107422 
	C109.823669,3488.106445 112.871559,3486.731689 115.806709,3486.866211 
	C135.303146,3487.757080 150.725830,3471.948730 170.387894,3473.695312 
	C171.349915,3473.780762 172.332687,3473.176514 173.074875,3472.492432 
	C191.384674,3455.606689 215.802856,3451.117432 237.952454,3440.452881 
	C241.248642,3439.061768 242.831238,3436.529541 245.888336,3434.836914 
	C248.909241,3434.004639 251.015030,3434.360107 252.826172,3436.925293 
	C253.276047,3438.005615 252.729401,3439.737549 254.769699,3437.829102 
	C264.569763,3429.441895 275.050751,3424.025146 287.410736,3422.700439 
	C290.486755,3422.370850 293.110016,3421.066162 295.367126,3418.912598 
	C297.926300,3416.470459 301.055115,3415.229004 304.529510,3416.116211 
	C316.227295,3419.103516 325.865997,3414.480469 335.223602,3408.302002 
	C345.845947,3401.288574 356.943359,3395.337646 370.092865,3394.901611 
	C374.718353,3394.748291 378.227478,3392.488281 381.719238,3389.784180 
	C386.251343,3386.274170 391.146667,3383.356445 397.263275,3384.255371 
	C405.760651,3385.504395 408.917084,3392.888184 403.648376,3399.614502 
	C400.550537,3403.569336 396.612518,3406.653564 391.656403,3408.234619 
	C367.988678,3415.785889 345.331512,3426.034668 322.115173,3434.608643 
	C307.178528,3440.125000 295.863617,3452.783936 279.355499,3454.300537 
	C277.623718,3454.459473 275.632477,3455.658447 274.622162,3458.163086 
	C284.460785,3460.590088 292.985474,3457.567871 300.734436,3452.202148 
	C306.657104,3448.100586 312.817047,3445.810303 320.057587,3445.096924 
	C327.294983,3444.384277 334.414001,3442.320312 341.173431,3439.305908 
	C344.879364,3437.653076 348.772736,3436.748291 353.384949,3439.013672 
	C354.965973,3446.531006 349.269775,3448.112549 344.710693,3449.990723 
	C339.000305,3452.343750 333.019287,3454.087158 326.438843,3456.308838 
	C321.678650,3456.568604 318.311340,3458.068359 315.130737,3461.645020 
	C306.795013,3466.572266 298.399811,3469.533447 289.230286,3472.495117 
	C279.364471,3472.063965 272.546112,3477.107666 265.129211,3482.536621 
	C263.248932,3483.662354 261.845123,3483.963623 259.676514,3484.054199 
	C251.065994,3482.792969 246.907532,3489.286133 240.650818,3493.031494 
	C237.563293,3494.881348 234.832779,3495.655029 231.292114,3496.145020 
	C222.037064,3495.815186 214.786209,3498.715332 208.226486,3505.232910 
	C203.662918,3507.440430 199.395065,3508.139404 194.436157,3509.011719 
	C191.664551,3509.058838 190.152298,3510.286133 187.911285,3511.709473 
	C151.920181,3523.794678 117.391960,3537.766357 82.133171,3549.729004 
	C67.998199,3554.524902 54.472939,3561.068115 39.761391,3564.303467 
	C31.790747,3566.056152 25.644329,3571.634033 18.597025,3575.337646 
	C13.407551,3578.064941 8.190267,3580.300049 2.554059,3574.548340 
z"
        />
        <path
          fill="#8DA0AE"
          opacity="1.000000"
          stroke="none"
          d="
M53.515701,1912.642822 
	C66.098366,1911.979248 78.280991,1911.225708 91.134888,1914.003662 
	C98.914215,1917.370239 99.160065,1926.637695 106.155853,1930.036255 
	C107.194145,1930.540649 107.461678,1932.560547 106.753036,1933.702637 
	C103.267204,1939.320068 104.364235,1945.932251 102.579819,1951.940063 
	C97.591263,1968.735352 97.738708,1968.779175 79.644447,1970.503906 
	C81.240929,1973.568237 85.046196,1975.948120 82.798332,1979.815186 
	C80.998718,1982.911011 78.155830,1983.211304 74.536011,1983.091797 
	C73.346321,1982.893921 72.850647,1983.102539 72.681763,1983.733398 
	C72.598701,1984.043945 72.914169,1984.242310 73.794029,1984.547852 
	C78.906044,1988.495728 79.837410,1994.751465 85.222786,1998.412476 
	C90.167328,2001.773682 87.806267,2007.243408 82.360718,2009.022339 
	C78.180748,2010.387939 75.212219,2012.058105 73.572426,2016.822998 
	C70.640381,2018.770508 67.732033,2018.493530 64.581635,2019.044800 
	C66.970879,2020.851807 68.764130,2022.231689 71.906097,2021.357300 
	C75.066673,2020.461182 77.539017,2019.553223 80.198318,2019.432861 
	C88.951698,2019.036621 91.691238,2022.505249 89.367142,2031.056885 
	C95.026352,2031.954468 103.485893,2024.396606 105.250717,2037.047607 
	C101.413338,2041.748901 95.869186,2042.049072 91.758202,2044.902588 
	C93.077873,2045.880127 95.144905,2045.337402 97.470673,2046.456299 
	C99.814995,2047.707886 101.140587,2049.079834 102.725372,2051.192383 
	C104.860985,2058.891846 101.442879,2061.562012 95.011009,2061.655518 
	C106.019569,2071.091553 103.036865,2096.907227 88.217789,2112.966064 
	C95.226540,2112.876953 103.260040,2106.990967 107.517624,2117.496094 
	C110.655548,2122.814209 106.289833,2122.358398 104.453888,2121.790039 
	C94.843430,2118.813232 86.298141,2123.044189 76.565880,2124.884766 
	C81.100403,2132.932861 87.179932,2128.358887 93.206635,2128.059570 
	C96.286049,2132.823486 92.867699,2133.337646 88.788635,2133.977051 
	C87.125305,2134.024902 86.251335,2134.043457 84.721802,2134.071777 
	C70.321678,2140.381592 64.504219,2141.253418 41.575577,2140.448730 
	C40.242275,2140.458008 41.409653,2142.000000 41.118378,2140.698975 
	C41.075325,2140.506592 41.395958,2140.390137 42.073410,2140.440674 
	C49.332817,2140.981445 56.611382,2140.905029 63.878468,2141.160156 
	C68.054176,2141.306641 71.868874,2142.744629 73.242905,2147.202637 
	C74.487595,2151.241455 71.925606,2153.895020 69.432228,2156.768555 
	C58.072548,2169.862061 44.694324,2173.569580 28.444792,2166.745361 
	C20.426884,2163.378174 11.178075,2167.140137 2.587052,2162.488770 
	C2.000000,2138.624756 2.000000,2115.249756 2.000000,2090.937256 
	C4.795391,2086.958008 8.534445,2087.114502 12.174157,2087.073730 
	C19.554539,2086.990967 26.762415,2086.343750 33.017361,2081.798096 
	C34.667587,2080.598633 36.622601,2079.841064 38.635761,2080.253662 
	C42.550709,2081.056152 46.737068,2084.135254 48.987717,2077.638672 
	C50.125397,2074.354980 53.205917,2072.720703 53.737431,2068.057617 
	C54.683426,2059.758057 59.645729,2052.030029 63.357727,2044.282471 
	C65.388527,2040.044067 70.060677,2039.456299 74.612350,2039.526489 
	C70.760582,2035.495483 67.305191,2034.119385 61.830910,2037.707642 
	C52.831509,2043.606934 48.734806,2033.648071 43.906105,2029.772217 
	C34.171032,2021.958008 34.773300,2022.086670 26.321964,2030.977051 
	C20.708061,2036.882446 12.226007,2040.404785 2.529756,2038.460205 
	C2.000000,2013.291504 2.000000,1988.583130 2.000000,1962.937256 
	C12.550719,1964.647827 23.415073,1960.875732 33.992603,1963.656738 
	C43.747551,1966.221436 53.019150,1961.567261 60.291534,1961.805664 
	C51.761887,1963.971191 41.622375,1959.543701 31.189297,1958.123657 
	C28.784353,1957.796265 26.911291,1956.115845 26.733622,1953.402832 
	C26.521749,1950.167603 28.559870,1948.517090 31.365917,1947.794678 
	C37.856846,1946.123535 44.600784,1945.202026 51.168018,1945.656616 
	C60.744789,1946.319702 64.183502,1939.068848 69.127335,1933.573486 
	C70.967072,1931.528564 69.705856,1929.966797 67.864876,1928.687988 
	C64.318832,1926.224609 61.536388,1922.785889 57.544006,1920.862671 
	C54.426224,1919.360840 51.631191,1917.450928 53.515701,1912.642822 
z"
        />
        <path
          fill="#3E5871"
          opacity="1.000000"
          stroke="none"
          d="
M3025.488525,2805.528809 
	C3026.000000,2811.400146 3026.000000,2816.800049 3026.000000,2823.100098 
	C3025.246338,2824.656006 3024.304199,2825.772949 3023.560303,2825.596191 
	C3007.955078,2821.884033 2991.617676,2823.189941 2976.173096,2818.884521 
	C2970.720703,2817.364746 2965.402832,2815.678467 2959.742432,2815.325684 
	C2945.895752,2814.462891 2945.895264,2814.470703 2937.735352,2805.677979 
	C2942.729736,2813.699707 2942.763672,2813.539795 2951.750732,2815.442627 
	C2970.746826,2819.465088 2990.060547,2821.126953 3009.253662,2823.718262 
	C3014.562988,2824.435547 3020.502197,2824.840332 3025.460938,2829.468994 
	C3026.000000,2830.888916 3026.000000,2831.777832 3026.000000,2833.333496 
	C3023.398926,2836.616455 3020.068359,2837.297852 3016.754395,2836.483154 
	C2995.032959,2831.142578 2972.560303,2831.389160 2950.695801,2827.549561 
	C2953.046631,2828.459229 2956.562256,2828.221191 2956.579590,2832.043945 
	C2956.592773,2834.932373 2954.190918,2835.813477 2951.903809,2836.416504 
	C2948.609619,2837.285156 2945.316162,2837.160889 2941.501953,2835.494629 
	C2939.760254,2835.289062 2941.058105,2832.199219 2938.482910,2834.238770 
	C2927.647705,2842.863525 2917.991455,2839.962646 2913.211426,2826.577637 
	C2914.884033,2824.656006 2916.479492,2824.719727 2918.136719,2825.215820 
	C2919.434326,2825.526611 2917.725830,2823.236816 2918.406250,2824.383301 
	C2918.734131,2824.935791 2918.484863,2825.218750 2917.772217,2824.940430 
	C2908.904541,2821.479248 2899.236084,2823.994629 2890.244629,2821.239258 
	C2888.323486,2820.650635 2887.361572,2822.339844 2887.393066,2825.032959 
	C2886.990479,2832.847168 2881.558350,2833.055176 2876.618408,2833.175049 
	C2865.993652,2833.433105 2855.539062,2831.322021 2845.007324,2830.273438 
	C2836.464844,2829.422852 2828.050537,2827.260254 2819.339355,2828.263672 
	C2814.625488,2828.806885 2811.036377,2826.930176 2808.737305,2822.564209 
	C2805.119385,2815.693848 2807.251953,2811.901123 2815.098145,2811.482666 
	C2820.522461,2811.193359 2826.266113,2814.239990 2831.863770,2809.344482 
	C2838.759277,2803.467285 2846.175293,2802.316895 2854.034180,2803.409668 
	C2860.948975,2804.371582 2867.897461,2805.008057 2872.946289,2805.681396 
	C2876.143311,2806.040771 2875.337646,2804.169434 2875.415283,2805.500488 
	C2875.429199,2805.737549 2875.110840,2805.761475 2874.454834,2805.742188 
	C2873.791748,2805.722412 2873.105713,2805.820557 2872.468506,2805.635986 
	C2868.841064,2804.587158 2863.097168,2805.137695 2863.688965,2799.715088 
	C2864.266357,2794.425293 2869.435791,2795.518066 2873.402832,2795.894287 
	C2875.391602,2796.083008 2877.394287,2795.986816 2879.391113,2796.004883 
	C2873.647705,2796.236084 2867.873047,2795.592773 2862.335938,2794.579590 
	C2848.246338,2792.001465 2834.289795,2789.356201 2819.817871,2790.904297 
	C2815.256104,2791.392090 2810.553223,2790.023682 2806.102783,2788.585449 
	C2803.540771,2787.757324 2800.804688,2786.623047 2801.491943,2783.153564 
	C2802.040771,2780.383789 2804.589111,2779.805664 2806.927246,2779.670654 
	C2819.183594,2778.964111 2831.455566,2778.304688 2843.727539,2778.410400 
	C2851.237549,2778.474854 2858.820312,2778.146973 2865.858643,2782.385010 
	C2869.429688,2784.535645 2873.528809,2781.538574 2877.372559,2780.682373 
	C2884.263672,2779.147705 2891.315674,2780.568848 2898.259766,2779.646484 
	C2880.033936,2781.565430 2861.929199,2779.118408 2843.765137,2778.501221 
	C2830.218994,2778.040527 2815.961914,2776.572021 2803.186279,2780.563477 
	C2785.540039,2786.076660 2768.558838,2783.885742 2751.025146,2782.436035 
	C2731.848633,2780.850830 2712.766846,2778.362305 2693.578125,2777.025391 
	C2688.780762,2776.691406 2684.422119,2775.526123 2679.812012,2772.388916 
	C2669.050537,2765.168945 2657.462891,2762.298828 2645.886475,2759.438721 
	C2643.964844,2758.963867 2641.908936,2759.031250 2639.960693,2758.567627 
	C2636.524902,2757.749512 2633.399902,2756.221924 2633.191406,2752.287598 
	C2632.978760,2748.272461 2635.999512,2746.422852 2639.420166,2745.579346 
	C2652.938477,2742.246582 2666.670410,2741.246582 2679.756592,2746.499756 
	C2694.118408,2752.265381 2709.070801,2752.750977 2723.859131,2751.916748 
	C2734.979980,2751.289795 2745.741455,2751.168701 2756.537354,2753.755859 
	C2770.363525,2757.069092 2783.867920,2762.306396 2798.355225,2759.963623 
	C2794.942627,2758.056641 2790.536865,2756.131348 2788.770996,2750.677734 
	C2788.375244,2747.053467 2791.521729,2745.931152 2792.128662,2743.400635 
	C2788.674561,2741.830566 2784.389893,2741.676758 2782.525391,2736.738037 
	C2782.463623,2730.888916 2786.140381,2729.763428 2790.144043,2728.993408 
	C2793.962891,2728.259277 2797.897949,2728.749512 2802.521973,2727.691162 
	C2810.799072,2726.541504 2817.694092,2729.307373 2825.619629,2730.785156 
	C2830.735840,2730.523193 2834.581543,2728.347900 2838.728760,2727.734863 
	C2846.151611,2726.637695 2853.828369,2725.480469 2858.951660,2734.057617 
	C2856.453125,2738.787842 2855.588867,2742.042236 2861.956787,2742.708984 
	C2870.802490,2743.634766 2879.436523,2745.842285 2888.040527,2747.887207 
	C2893.291504,2749.135254 2900.327637,2749.426758 2900.262695,2758.199707 
	C2882.595215,2761.503418 2866.729492,2771.019531 2850.009277,2769.251465 
	C2864.109863,2768.294678 2879.711426,2770.053711 2895.037109,2764.496338 
	C2903.590576,2761.395020 2913.290527,2761.501953 2922.427246,2759.789307 
	C2927.472900,2758.843262 2932.262451,2760.859619 2936.926514,2764.603027 
	C2940.252441,2772.622314 2947.045898,2773.984375 2953.525391,2774.758545 
	C2965.784180,2776.222900 2977.618408,2780.012695 2989.959473,2780.936523 
	C2993.169189,2781.176514 2998.039307,2781.407715 2997.818848,2785.327881 
	C2997.550781,2790.093262 2992.908447,2790.865967 2988.549561,2790.389160 
	C2971.747559,2788.551270 2954.739746,2788.179932 2939.738525,2783.513672 
	C2937.050537,2783.092773 2937.819336,2785.066650 2937.794189,2783.733643 
	C2937.790039,2783.507568 2938.104980,2783.384277 2938.695312,2783.537109 
	C2962.964111,2789.804688 2988.052490,2789.647461 3012.695068,2792.978027 
	C3016.989746,2793.558350 3021.350098,2793.907715 3025.407959,2797.491211 
	C3026.000000,2798.888916 3026.000000,2799.777832 3026.000000,2801.333496 
	C3024.145752,2804.625488 3021.286865,2803.087891 3018.093506,2803.753418 
	C3021.132812,2803.372559 3023.087158,2804.072021 3025.488525,2805.528809 
z"
        />
        <path
          fill="#243748"
          opacity="1.000000"
          stroke="none"
          d="
M2.639275,2834.490234 
	C2.000000,2830.571289 2.000000,2827.142578 2.000000,2822.856934 
	C4.643761,2818.551270 9.462212,2819.644775 13.171948,2816.677734 
	C15.325383,2815.923828 16.827606,2815.795654 19.101330,2815.895996 
	C24.219934,2817.388916 28.563856,2817.519531 33.616570,2815.570557 
	C35.869690,2815.220459 37.389473,2815.178467 39.660103,2815.084961 
	C59.612083,2818.131348 79.341980,2816.818359 98.262604,2822.250000 
	C96.111588,2821.644531 93.834183,2821.278320 93.353035,2818.610840 
	C92.841324,2815.773682 94.921219,2814.468750 96.968109,2813.306396 
	C98.710228,2812.317383 100.635651,2811.580322 102.612885,2811.495850 
	C115.493820,2810.945801 128.775177,2809.443848 141.134628,2812.126465 
	C151.078217,2814.284424 157.494873,2813.337891 163.630661,2804.764160 
	C175.312836,2801.279541 186.390244,2800.020508 197.852707,2801.741455 
	C205.652206,2802.912598 213.640854,2803.848389 221.968384,2800.254883 
	C223.465027,2799.151367 224.427856,2798.770508 226.215485,2798.583984 
	C237.371536,2799.387695 247.866302,2801.794678 258.445618,2796.450684 
	C259.625275,2795.313477 260.438690,2794.862305 262.029297,2794.419922 
	C264.589264,2794.086182 266.495178,2794.126221 268.959991,2793.075928 
	C285.176361,2787.808105 300.293976,2781.190186 317.818481,2781.011475 
	C341.905121,2775.933838 365.707428,2772.824219 388.106445,2764.360107 
	C401.815948,2759.179932 415.552917,2758.278320 427.150726,2760.148438 
	C418.816528,2759.686768 407.901154,2758.839844 396.884277,2759.263184 
	C383.325714,2759.784668 370.411743,2762.080078 358.817352,2769.509766 
	C355.047394,2771.925293 350.994110,2773.350342 346.566101,2773.209961 
	C321.001495,2772.400146 295.385376,2773.083740 269.875854,2770.786133 
	C264.003906,2770.257080 259.436340,2767.862305 261.341614,2759.828125 
	C267.792206,2754.613281 274.860046,2754.693115 281.809723,2754.554932 
	C294.075958,2754.311035 306.346893,2754.805908 318.571838,2752.957520 
	C324.166901,2752.111572 329.649200,2751.649902 334.663025,2748.534912 
	C338.076569,2746.414307 342.311096,2745.736572 346.216614,2745.973633 
	C361.359924,2746.893311 376.393829,2744.863525 391.749847,2747.119629 
	C409.965393,2749.795166 428.629150,2749.845703 447.759277,2752.977051 
	C460.069061,2749.349121 471.810303,2748.237305 483.789978,2748.596436 
	C490.658234,2748.802490 496.632080,2745.977051 502.628418,2741.283447 
	C506.288300,2739.217041 509.507202,2739.069336 513.556641,2739.433105 
	C515.781067,2739.783691 517.192627,2740.208984 519.250366,2740.838379 
	C525.364197,2742.428955 525.813660,2736.960205 529.252686,2734.379395 
	C540.327759,2734.314453 550.738281,2732.194092 561.933167,2734.408691 
	C571.828979,2740.490723 582.218201,2740.725830 592.268860,2740.348633 
	C609.962524,2739.684082 627.115784,2743.015625 644.337952,2745.862061 
	C649.120972,2746.652588 657.012146,2746.570801 652.514771,2756.460938 
	C649.507202,2758.492920 646.879211,2758.841553 644.257141,2758.434326 
	C627.921997,2755.896484 611.471558,2757.414307 594.288208,2756.837402 
	C590.410034,2756.329346 587.730408,2753.182129 583.600220,2755.630859 
	C580.621033,2756.625977 578.259460,2756.672363 575.187805,2756.120117 
	C569.164124,2753.797607 563.832947,2751.603516 558.713562,2751.801270 
	C564.319153,2752.612061 566.775879,2754.814453 559.942139,2760.232422 
	C544.849243,2766.858398 529.293823,2766.606201 514.175232,2766.535400 
	C501.886230,2766.477783 490.849426,2771.549805 478.850250,2772.028076 
	C472.636444,2772.275879 474.630432,2778.494873 473.198242,2783.055908 
	C471.898834,2785.145020 470.504181,2785.897217 468.193298,2786.625000 
	C464.672424,2786.770996 462.419037,2788.060303 459.491333,2789.843750 
	C441.211823,2793.558594 424.039185,2796.582031 405.601776,2796.017822 
	C388.158844,2795.483643 369.858337,2797.495605 352.352051,2800.236816 
	C338.028717,2802.479248 324.000092,2803.589111 309.729828,2802.836182 
	C302.642914,2802.462158 297.961914,2804.424561 292.301849,2809.458496 
	C281.072449,2819.445068 269.308319,2830.596436 251.254745,2822.448730 
	C244.158035,2819.246582 235.664413,2819.052490 228.059464,2823.501709 
	C225.765274,2824.843994 222.950134,2825.214355 220.288437,2825.106689 
	C197.849823,2824.197266 175.772690,2826.230713 153.423431,2832.925049 
	C140.620163,2832.084473 129.286819,2834.617676 117.707932,2839.905762 
	C112.137550,2842.419678 107.052986,2843.194336 101.042999,2842.660400 
	C94.951683,2840.374268 92.236107,2845.110840 87.822159,2847.347656 
	C86.239136,2847.751953 85.308006,2847.762939 83.701454,2847.419922 
	C81.129913,2846.136230 80.339561,2844.368896 80.133736,2842.340088 
	C79.739281,2838.451416 80.179535,2833.877930 74.681755,2833.391357 
	C69.594131,2832.941162 64.075867,2831.538086 60.415180,2837.100342 
	C58.489964,2840.025635 56.954681,2844.105713 52.298176,2842.517578 
	C47.979950,2841.044189 48.401222,2836.969727 48.304878,2833.357178 
	C48.259899,2831.671143 46.964439,2831.360352 44.932632,2831.068115 
	C35.825989,2833.243652 27.294102,2833.571045 17.910460,2833.250000 
	C12.358471,2832.696289 8.368995,2838.069092 2.639275,2834.490234 
z"
        />
        <path
          fill="#F0EBDD"
          opacity="1.000000"
          stroke="none"
          d="
M1760.937256,4034.000000 
	C1760.015747,4032.335205 1760.005859,4030.669434 1760.052124,4029.005615 
	C1760.163208,4025.006348 1762.111206,4023.564941 1765.899292,4024.994873 
	C1768.466187,4025.963867 1771.100342,4026.115479 1773.767456,4025.877686 
	C1775.044800,4025.763916 1776.547729,4025.632324 1776.789551,4023.928955 
	C1777.086548,4021.836914 1775.545776,4020.830811 1773.957397,4020.485352 
	C1771.703857,4019.995361 1769.333252,4020.072021 1767.028442,4019.781738 
	C1764.909668,4019.514648 1763.244751,4018.458740 1762.604004,4016.317139 
	C1762.041504,4014.436768 1762.619385,4012.961182 1764.771240,4012.862793 
	C1765.687256,4012.820557 1766.752930,4013.306885 1767.551636,4013.847168 
	C1778.884521,4021.517090 1791.029297,4018.317627 1803.104736,4016.667236 
	C1813.688354,4015.220947 1823.694092,4016.245605 1833.521606,4021.491455 
	C1836.884766,4023.286621 1842.474976,4021.422607 1846.875977,4020.451172 
	C1848.223389,4020.154053 1847.529419,4016.493652 1845.945801,4016.786865 
	C1840.657959,4017.766357 1836.639282,4013.885498 1831.618652,4013.925293 
	C1817.222656,4014.039062 1803.556641,4010.200195 1790.150391,4005.371094 
	C1786.763672,4004.151367 1784.276001,4005.287598 1784.831299,4008.552246 
	C1785.964355,4015.215332 1782.358765,4014.278809 1778.532471,4013.106689 
	C1776.727783,4012.554199 1774.571289,4012.384033 1772.975586,4010.010254 
	C1774.157349,4007.099365 1781.645752,4008.289307 1778.976196,4002.235352 
	C1778.453979,4001.050781 1780.440063,4000.315430 1781.770996,4000.053467 
	C1786.586426,3999.105225 1791.257568,3996.737305 1796.362305,3999.078369 
	C1799.145508,4000.354736 1802.199219,4000.173828 1805.649536,3999.718506 
	C1806.253662,3996.979004 1801.638428,3995.996582 1803.523682,3992.378174 
	C1809.837769,3980.258057 1809.598999,3980.133789 1800.700562,3971.179932 
	C1806.113892,3969.877930 1811.133545,3968.506592 1816.246826,3969.234619 
	C1822.735596,3970.158691 1823.224609,3966.420166 1823.523682,3962.120605 
	C1821.629639,3960.600342 1820.800415,3961.745850 1819.641357,3962.350586 
	C1812.355103,3966.151855 1811.745361,3966.242920 1809.369507,3958.396729 
	C1807.702026,3952.889648 1803.798218,3951.739258 1799.209595,3951.107910 
	C1800.020508,3947.050781 1802.853027,3948.197754 1804.908203,3948.050293 
	C1813.384766,3947.440918 1814.422974,3946.585693 1815.296509,3938.362305 
	C1815.465088,3936.775879 1814.729248,3935.024658 1815.138184,3933.551270 
	C1815.568237,3932.001953 1818.136963,3932.056885 1818.307129,3932.921875 
	C1820.718506,3945.175049 1830.214355,3938.432617 1836.581909,3939.719727 
	C1838.305908,3940.068359 1840.445679,3938.900391 1842.265381,3938.125488 
	C1846.375122,3936.375488 1846.906006,3933.172119 1843.129028,3930.906250 
	C1839.757446,3928.883545 1835.828613,3927.786133 1832.137207,3926.301270 
	C1826.631592,3924.086914 1823.859009,3916.653076 1826.809326,3911.735352 
	C1828.093262,3909.595703 1830.265991,3908.486572 1832.654907,3908.433105 
	C1835.521606,3908.369141 1837.393921,3910.980225 1837.029785,3912.967773 
	C1835.674805,3920.360596 1840.079834,3920.291504 1845.072266,3920.019531 
	C1847.757202,3919.873047 1850.308594,3920.516602 1852.900513,3921.275391 
	C1861.717529,3923.856445 1862.613037,3927.829834 1855.846558,3934.867676 
	C1855.492676,3935.235596 1855.576782,3936.024658 1855.439941,3936.702393 
	C1858.275635,3939.111084 1861.840698,3937.387695 1864.949951,3938.293457 
	C1866.414429,3938.719971 1867.370117,3939.636963 1867.356934,3941.028809 
	C1867.343750,3942.425537 1866.390015,3943.514404 1864.882690,3943.674805 
	C1862.902588,3943.885742 1860.905396,3943.937012 1858.916016,3944.062256 
	C1854.292603,3944.353271 1851.725342,3947.422363 1850.324951,3951.259033 
	C1848.639160,3955.877197 1852.159302,3957.657227 1855.577881,3959.306152 
	C1856.010986,3959.515137 1856.103516,3960.429932 1856.368408,3961.052002 
	C1853.333008,3964.537598 1849.185547,3963.898438 1845.249146,3964.023926 
	C1841.549561,3964.141357 1838.254395,3965.350830 1834.385620,3967.656006 
	C1837.272827,3970.743652 1840.114624,3969.533203 1842.891235,3969.474854 
	C1852.019653,3969.281738 1861.151489,3966.143311 1870.315308,3969.590820 
	C1872.015991,3970.230957 1874.638916,3970.334229 1874.820312,3967.439209 
	C1874.937134,3965.572754 1873.169678,3964.573242 1871.501099,3964.339355 
	C1868.546631,3963.924805 1865.523804,3963.980957 1862.578735,3963.523926 
	C1861.244263,3963.316895 1860.387573,3962.113525 1860.657471,3960.542725 
	C1860.933716,3958.936523 1862.142456,3958.348633 1863.521484,3958.470459 
	C1864.818848,3958.585449 1866.148804,3958.977539 1867.342041,3959.518066 
	C1874.749634,3962.873047 1881.608643,3959.695801 1888.584229,3957.841064 
	C1891.422485,3957.086426 1892.316895,3955.155762 1891.450684,3952.532715 
	C1890.621094,3950.020996 1888.559082,3950.179932 1886.535889,3950.844727 
	C1880.184326,3952.931641 1872.645386,3948.730469 1866.884277,3954.979980 
	C1865.400024,3956.590332 1862.606445,3954.477051 1860.897461,3952.379150 
	C1864.761719,3946.331299 1874.083618,3948.247559 1877.283691,3941.314453 
	C1877.365356,3941.137451 1877.977783,3941.017822 1878.223145,3941.130615 
	C1888.014648,3945.639648 1887.525757,3938.432373 1888.100220,3932.468018 
	C1888.309937,3930.290771 1889.323364,3927.788574 1891.464600,3928.427490 
	C1898.925293,3930.654541 1907.163208,3926.288574 1914.223389,3931.313721 
	C1913.833618,3934.022461 1911.300171,3933.557373 1909.669678,3934.430176 
	C1907.390015,3935.649902 1905.746582,3937.097168 1907.855835,3939.876465 
	C1908.408691,3940.605225 1908.846313,3941.626465 1908.855713,3942.518799 
	C1908.874023,3944.272217 1902.934448,3944.197510 1907.106689,3947.126953 
	C1909.750244,3948.983398 1918.437500,3945.895508 1919.117065,3943.257080 
	C1919.489258,3941.812012 1917.911133,3939.609131 1919.788940,3938.739990 
	C1926.892334,3935.451660 1922.881470,3932.358887 1919.786255,3929.211182 
	C1921.121582,3925.981934 1925.205688,3926.948975 1927.071777,3924.200684 
	C1925.765991,3921.407471 1922.152222,3920.586914 1921.367676,3916.782227 
	C1920.486938,3912.512207 1915.837646,3914.465332 1912.766479,3913.966064 
	C1911.104248,3913.695557 1909.329956,3913.649170 1908.721191,3911.733154 
	C1908.128418,3909.868408 1909.355103,3908.640869 1910.608521,3907.494385 
	C1911.830933,3906.376465 1912.908081,3905.055664 1914.258667,3904.129395 
	C1919.015381,3900.868652 1920.623657,3901.938965 1921.300659,3909.055908 
	C1925.874146,3909.386230 1928.291504,3906.396973 1930.788452,3903.266357 
	C1933.583740,3899.761475 1936.990601,3897.367188 1941.906250,3897.954590 
	C1945.285645,3898.358398 1948.347534,3897.641113 1951.122437,3895.193115 
	C1953.355469,3893.222900 1956.148438,3894.113281 1959.308105,3895.708496 
	C1959.952759,3914.271973 1954.056763,3931.518066 1950.854858,3949.233643 
	C1949.607422,3956.134521 1942.174194,3956.307373 1938.921509,3961.556641 
	C1935.134033,3967.668457 1930.477173,3973.514648 1927.027222,3979.836914 
	C1923.791016,3985.767578 1921.127930,3991.437012 1914.521851,3994.485596 
	C1912.125977,3995.591309 1909.861084,3997.838867 1910.930908,4001.764404 
	C1912.333862,4006.912842 1908.243774,4009.883057 1904.385498,4009.839844 
	C1889.554321,4009.674805 1880.328369,4021.002441 1868.371704,4026.599609 
	C1863.924927,4028.681152 1859.594971,4031.144775 1854.508301,4033.522217 
	C1823.291504,4034.000000 1792.583130,4034.000000 1760.937256,4034.000000 
z"
        />
        <path
          fill="#0D1C2A"
          opacity="1.000000"
          stroke="none"
          d="
M430.413818,3065.563477 
	C426.334167,3065.649414 422.886871,3066.502930 418.762024,3067.904053 
	C416.692871,3069.641602 415.811737,3071.790283 412.641876,3071.994141 
	C408.697601,3070.062744 405.071747,3069.968018 401.371185,3069.994629 
	C387.858643,3070.091553 374.377625,3068.695312 360.814941,3070.925293 
	C356.188416,3071.686035 351.244659,3069.155762 348.144867,3075.010498 
	C347.285217,3076.634033 344.440765,3075.939453 342.470459,3075.975342 
	C332.035461,3076.165039 321.291748,3078.017822 312.663055,3082.808594 
	C301.350494,3089.089111 289.763733,3092.390381 277.365234,3094.238037 
	C271.868713,3100.703125 263.514130,3099.070557 256.651978,3101.633545 
	C250.487396,3103.936279 245.338013,3108.170654 240.812973,3112.828369 
	C238.372589,3115.341064 236.196564,3116.279053 232.972488,3115.387939 
	C219.664093,3111.710205 206.539398,3114.815674 193.659622,3117.406006 
	C184.082855,3119.332031 174.516083,3116.614502 165.039322,3118.947754 
	C163.032272,3119.441895 159.611008,3117.544189 162.166275,3113.748291 
	C163.979630,3111.054688 161.857224,3110.399902 159.876480,3109.720459 
	C156.136154,3108.437744 152.418411,3107.447266 150.958023,3102.806885 
	C150.113678,3100.124268 147.438309,3100.398438 145.029022,3101.510498 
	C137.655151,3104.914307 129.410400,3104.192383 121.815765,3106.192627 
	C110.426697,3109.192139 98.460007,3109.382324 86.257034,3113.255127 
	C89.505928,3117.807617 93.197922,3118.299805 97.216194,3117.745850 
	C99.170891,3117.476562 101.071175,3116.466553 103.001419,3116.450928 
	C105.599731,3116.429688 107.477432,3117.944092 107.428726,3120.766846 
	C107.383698,3123.376709 105.301575,3123.843262 103.223671,3123.924072 
	C93.961647,3124.284180 84.412697,3122.614990 75.558769,3126.074707 
	C67.062302,3129.395020 57.739185,3130.297119 49.904388,3135.669922 
	C44.280510,3139.526611 37.074928,3138.235596 30.687611,3139.823242 
	C28.139071,3140.456299 26.410206,3138.608643 25.251181,3136.338623 
	C23.924517,3133.740723 25.079603,3131.839600 26.890499,3130.175537 
	C28.348473,3128.835938 29.894205,3127.562988 31.535952,3126.461182 
	C32.812466,3125.604980 35.120846,3125.787842 34.933632,3123.538086 
	C34.732239,3121.117432 32.705128,3120.293701 30.663885,3120.211914 
	C27.691595,3120.092529 24.101952,3119.322998 21.839302,3120.647705 
	C15.772438,3124.199219 9.766815,3122.899170 2.803133,3122.009766 
	C2.000000,3117.258789 2.000000,3112.517822 2.000000,3106.888184 
	C6.726114,3101.653076 12.628291,3102.650391 18.180187,3102.724854 
	C31.593897,3102.904541 44.630680,3102.519775 56.794186,3095.230469 
	C63.307438,3091.327393 71.712173,3092.459229 79.608826,3089.492676 
	C83.503181,3087.333984 87.041260,3086.700195 91.331024,3086.023438 
	C94.543633,3085.833496 96.829147,3085.027344 99.803230,3083.795166 
	C107.022118,3082.804199 113.517662,3082.406250 120.872246,3082.921875 
	C125.055428,3082.752930 128.409225,3082.528320 132.611755,3082.728027 
	C135.069168,3083.065674 136.708969,3083.211670 139.120544,3083.542480 
	C159.527328,3092.397705 179.534134,3088.860352 201.766312,3087.010254 
	C196.428543,3081.363770 191.608047,3081.902344 187.295288,3081.051514 
	C182.666107,3080.137939 177.652847,3079.617432 179.263092,3072.076172 
	C181.390808,3069.882812 183.485107,3069.340820 186.521393,3069.100586 
	C189.530960,3069.123291 191.717117,3068.981689 194.669464,3069.437012 
	C201.332153,3071.332275 207.193253,3072.519287 213.567139,3068.599609 
	C218.669098,3066.598389 223.241577,3066.823975 228.543365,3067.218262 
	C231.570602,3067.675537 233.844467,3067.730957 236.703735,3066.409668 
	C238.904602,3065.853027 240.420486,3065.836426 242.729202,3066.200684 
	C260.808594,3072.025635 277.576813,3067.005371 293.995361,3062.802246 
	C315.464722,3057.305908 336.323242,3049.241455 359.086029,3050.896240 
	C362.407562,3051.137695 365.717957,3050.865479 369.287994,3053.374512 
	C374.540924,3054.484863 378.405548,3053.276123 381.474579,3049.593506 
	C383.748932,3046.864502 386.526642,3046.747559 390.217072,3046.774414 
	C399.292572,3046.840332 408.444916,3049.945312 417.515747,3046.535645 
	C421.552490,3045.018555 425.214386,3048.001465 429.200378,3047.981934 
	C442.143982,3047.918945 455.088226,3047.979980 468.032227,3047.988281 
	C468.695282,3047.988770 469.374115,3047.839111 470.018768,3047.936523 
	C473.687714,3048.492432 476.248627,3042.568359 479.935730,3045.671631 
	C483.523743,3048.691162 481.377533,3054.629639 485.188507,3058.120361 
	C486.305298,3059.143066 484.898712,3061.396240 482.973053,3061.643311 
	C480.354645,3061.979248 477.684296,3061.981445 475.035461,3061.996582 
	C465.446381,3062.052246 455.876770,3061.442871 446.598572,3065.249023 
	C443.423828,3066.551270 439.403717,3065.792725 434.897247,3065.973145 
	C433.155792,3064.955566 432.100677,3064.654053 430.413818,3065.563477 
z"
        />
        <path
          fill="#637E95"
          opacity="1.000000"
          stroke="none"
          d="
M3025.356934,2481.404297 
	C3026.000000,2490.049072 3026.000000,2498.098145 3026.000000,2507.073730 
	C3021.201660,2512.333984 3015.462158,2511.401611 3009.841309,2510.778320 
	C2975.075195,2506.924316 2940.389160,2504.372314 2905.339355,2508.582520 
	C2884.557129,2511.078857 2863.586182,2506.360352 2842.634033,2507.069824 
	C2848.146729,2506.805664 2853.632812,2507.042236 2858.977051,2508.479248 
	C2862.106934,2509.320801 2866.229004,2509.672363 2866.445312,2513.825195 
	C2866.668701,2518.111816 2862.544678,2518.759033 2859.441650,2519.605469 
	C2850.626465,2522.009521 2841.642334,2521.636719 2832.630127,2520.931885 
	C2810.060059,2519.166748 2787.572998,2518.875488 2764.863037,2520.271484 
	C2742.032471,2521.674316 2718.987793,2519.068848 2696.497559,2514.366699 
	C2685.594971,2512.087158 2674.736084,2513.235107 2663.061035,2512.365723 
	C2659.285400,2513.064697 2656.388916,2512.978760 2652.771973,2511.741943 
	C2648.034912,2504.168945 2648.814209,2501.634766 2655.819336,2502.249756 
	C2668.372314,2503.351807 2680.935791,2502.602539 2693.514160,2503.501221 
	C2708.689453,2504.585693 2724.045410,2503.761475 2739.294434,2504.711670 
	C2762.268555,2506.142822 2785.195801,2508.559082 2805.053223,2507.095215 
	C2795.360840,2506.115723 2782.393311,2508.347168 2769.481445,2506.993652 
	C2765.303467,2506.555664 2759.577148,2507.398438 2759.514404,2501.502686 
	C2759.457031,2496.105957 2764.729004,2495.318115 2769.200439,2495.085205 
	C2774.548828,2494.806641 2779.889160,2494.886719 2784.784912,2497.724365 
	C2777.542725,2493.215576 2769.404541,2495.946289 2761.770752,2494.630127 
	C2749.570068,2492.527100 2737.181396,2493.076660 2724.901855,2492.256836 
	C2716.588867,2491.702148 2708.012695,2492.318848 2700.284180,2498.104004 
	C2694.515625,2502.422119 2686.310791,2502.184082 2679.133545,2502.285645 
	C2664.963867,2502.485840 2650.766846,2502.538818 2636.591797,2501.862793 
	C2634.542236,2501.764893 2632.647461,2502.376465 2630.150879,2503.842529 
	C2617.575684,2505.595215 2605.866699,2508.159912 2592.974609,2506.706299 
	C2583.210449,2505.086426 2574.254395,2505.542236 2565.325928,2505.556885 
	C2556.041016,2505.572510 2546.808350,2505.682861 2537.947021,2502.093262 
	C2534.890869,2500.855225 2531.475342,2500.949463 2528.133057,2501.085205 
	C2510.289062,2501.810303 2492.447266,2502.342285 2473.873779,2498.981934 
	C2469.441895,2499.271484 2465.899170,2499.529297 2461.515625,2498.615967 
	C2460.126953,2498.159912 2459.591797,2497.925049 2458.378174,2497.143555 
	C2457.095215,2495.596436 2456.828857,2494.515381 2457.204102,2492.576660 
	C2464.830322,2487.822021 2472.857910,2489.242920 2480.593018,2489.270020 
	C2486.768555,2489.291748 2491.553223,2487.860840 2495.091797,2481.628418 
	C2500.299805,2478.495605 2505.023926,2480.005615 2510.469238,2480.856445 
	C2516.492920,2481.100830 2521.767334,2481.019775 2525.333740,2486.145020 
	C2527.378174,2489.083252 2530.828125,2487.478760 2532.527100,2487.902832 
	C2532.299072,2487.882080 2533.972656,2488.192627 2533.449219,2488.151855 
	C2525.605713,2487.547852 2520.574463,2485.971436 2529.788086,2477.949707 
	C2532.567383,2477.046143 2534.680664,2476.907227 2537.648193,2477.158691 
	C2552.921631,2479.252441 2567.105957,2482.298340 2580.609863,2488.073730 
	C2586.574219,2490.624512 2593.175293,2491.818848 2599.231689,2490.041016 
	C2615.281250,2485.330566 2631.608398,2486.906250 2647.864502,2486.658691 
	C2653.692139,2486.570068 2659.040283,2486.047363 2663.123047,2481.043457 
	C2665.535889,2478.086426 2669.192627,2477.000244 2673.022461,2477.055908 
	C2680.687012,2477.166992 2688.340332,2476.747803 2696.000732,2476.659424 
	C2701.116455,2476.600586 2706.949219,2479.723389 2710.511719,2472.583984 
	C2711.520020,2470.562988 2715.472412,2470.990723 2718.091797,2471.504150 
	C2755.089600,2478.755859 2792.444580,2472.907715 2829.596191,2474.516846 
	C2835.837646,2474.787354 2842.235107,2476.156982 2849.222656,2473.800781 
	C2854.780762,2473.635010 2859.997559,2475.726318 2864.497559,2470.481934 
	C2870.118652,2468.127686 2875.195801,2468.844238 2881.111816,2469.282471 
	C2884.275146,2469.729492 2886.564209,2469.776123 2888.900635,2469.495850 
	C2901.518799,2467.982910 2913.681885,2469.324707 2926.239990,2472.172363 
	C2948.652588,2477.254883 2971.637451,2476.282959 2994.447754,2473.775391 
	C3005.391113,2472.572754 3015.466797,2474.842285 3025.356934,2481.404297 
z"
        />
        <path
          fill="#69849B"
          opacity="1.000000"
          stroke="none"
          d="
M3025.357178,2369.482422 
	C3026.000000,2388.041748 3026.000000,2406.083740 3026.000000,2425.062744 
	C3021.422119,2430.737793 3015.396484,2431.127686 3009.399902,2431.369141 
	C2992.431885,2432.052490 2975.559570,2430.089355 2958.782959,2428.261230 
	C2949.448730,2427.244385 2940.501953,2425.929688 2931.280273,2428.479004 
	C2925.776611,2430.000488 2920.043701,2428.806885 2913.630127,2428.758301 
	C2909.047607,2427.731934 2907.062012,2424.749512 2904.968506,2421.856445 
	C2903.588135,2419.948730 2901.848633,2418.372803 2899.288818,2418.995850 
	C2895.779541,2419.850342 2897.386963,2422.403564 2897.721924,2424.477539 
	C2898.098633,2426.813232 2897.833740,2428.981689 2895.564209,2431.246582 
	C2889.727783,2433.757568 2884.665039,2433.212158 2879.087402,2430.353271 
	C2877.112549,2428.997559 2875.942139,2426.427002 2874.833252,2426.854980 
	C2865.267090,2430.550537 2854.187744,2425.424072 2844.457275,2432.270020 
	C2831.782959,2432.557129 2819.903809,2431.889893 2807.389160,2429.707031 
	C2802.432617,2422.590576 2808.087646,2421.903076 2812.098389,2421.330078 
	C2818.328857,2420.440430 2824.691895,2421.058350 2827.865723,2421.051270 
	C2814.552246,2423.472168 2798.255615,2417.353027 2781.751221,2420.299316 
	C2777.140137,2421.122314 2772.992676,2418.718994 2770.904785,2414.638184 
	C2768.146240,2409.247314 2763.760986,2408.633057 2758.531250,2408.368896 
	C2749.446045,2407.910400 2740.643066,2408.261719 2732.463379,2412.893311 
	C2729.140381,2414.775146 2725.433594,2414.914551 2721.798584,2413.976318 
	C2715.668213,2412.394287 2709.484375,2411.362793 2703.156494,2410.932129 
	C2699.922852,2410.711914 2695.533203,2410.369873 2695.544434,2406.189697 
	C2695.557861,2401.346924 2700.281494,2401.426025 2703.823242,2401.097900 
	C2706.449707,2400.854736 2709.130615,2401.097900 2711.787354,2401.100586 
	C2715.108398,2401.104248 2718.420898,2401.048096 2721.735596,2400.720703 
	C2735.007568,2399.409668 2736.915283,2397.050293 2733.992188,2385.413330 
	C2739.834961,2381.696045 2745.635742,2382.618652 2752.142578,2382.880859 
	C2774.131348,2391.171387 2796.244873,2383.599609 2817.728027,2386.805176 
	C2818.916016,2386.982422 2821.128174,2387.715088 2821.288086,2386.214844 
	C2822.363281,2376.109131 2829.260498,2380.164795 2834.727539,2380.342773 
	C2851.625244,2380.892578 2868.555664,2382.066162 2885.308105,2378.560547 
	C2852.268555,2381.648926 2819.205566,2379.581055 2785.317383,2378.989746 
	C2762.632080,2378.385742 2740.897217,2379.762207 2719.244385,2382.713379 
	C2715.651123,2383.203369 2711.989258,2383.300293 2707.591064,2382.015137 
	C2697.479248,2371.003418 2684.069092,2376.308105 2672.024658,2372.962891 
	C2666.993164,2372.620605 2661.304932,2373.496338 2661.451172,2367.386963 
	C2661.577393,2362.115234 2666.806396,2361.922852 2671.142822,2360.863037 
	C2682.963379,2357.973877 2694.914795,2359.633301 2706.782471,2358.941406 
	C2715.382324,2358.439941 2720.767578,2366.556641 2729.858887,2366.681396 
	C2736.611084,2366.746582 2742.543945,2366.659180 2748.475098,2366.754150 
	C2752.400146,2366.816895 2755.364746,2365.952637 2756.998779,2361.713135 
	C2758.631592,2357.477051 2763.257568,2356.591309 2766.131348,2358.664307 
	C2780.110352,2368.745850 2796.073975,2363.776611 2811.921875,2365.615234 
	C2827.514648,2366.330322 2842.269287,2370.471680 2857.143066,2366.179932 
	C2862.406006,2364.661377 2867.739258,2366.461426 2873.044189,2366.437012 
	C2913.658447,2366.249756 2954.150146,2368.458252 2994.547852,2372.564453 
	C2997.187012,2372.832764 3000.346924,2374.310059 2999.813232,2368.941650 
	C2999.579102,2366.588135 3002.917236,2365.773438 3005.323242,2365.826172 
	C3011.922363,2365.970215 3018.707764,2365.115479 3025.357178,2369.482422 
z"
        />
        <path
          fill="#687D90"
          opacity="1.000000"
          stroke="none"
          d="
M2.712340,2546.574463 
	C2.000000,2542.571289 2.000000,2539.142578 2.000000,2534.856934 
	C5.130318,2530.704590 9.247176,2531.012207 13.196565,2531.139893 
	C33.404900,2531.792725 53.590717,2530.629883 73.788422,2530.511719 
	C75.787788,2530.500000 77.960968,2530.737305 79.703659,2529.925049 
	C89.460938,2525.376221 99.587639,2525.862793 109.913162,2526.935059 
	C120.763062,2528.062256 130.981003,2520.053711 140.443283,2524.173096 
	C134.299164,2523.105957 126.717415,2523.306152 118.928093,2525.832764 
	C111.510475,2528.238037 103.802513,2527.372803 96.092621,2523.014160 
	C94.885048,2521.523438 94.552002,2520.490479 94.580078,2518.553711 
	C103.151962,2510.189941 113.654892,2508.240723 124.123550,2509.540283 
	C138.419937,2511.314941 152.474426,2511.828125 160.344238,2510.175049 
	C147.384995,2513.501953 128.411835,2510.563721 109.497849,2506.939697 
	C103.043053,2505.702637 97.354332,2506.064697 91.515396,2509.895264 
	C85.096428,2514.105713 77.330132,2516.216064 69.809067,2516.638184 
	C52.509693,2517.609619 35.789791,2521.811279 18.778360,2524.319824 
	C13.599895,2525.083740 8.207501,2526.047363 2.629509,2522.507080 
	C2.000000,2520.500000 2.000000,2519.000000 2.000000,2516.750000 
	C5.046884,2511.789307 9.624621,2510.965820 14.330945,2510.428711 
	C34.691227,2508.104492 54.672039,2502.649170 73.691330,2503.449707 
	C76.653870,2502.708252 74.943260,2500.859619 75.545227,2502.049316 
	C75.641251,2502.239258 75.458229,2502.398193 74.993118,2502.513672 
	C66.632530,2504.588623 57.925041,2504.185059 49.536869,2504.450439 
	C38.226494,2504.807861 27.036724,2504.991211 15.861465,2507.007812 
	C11.662209,2507.765869 7.177810,2507.733154 2.658597,2504.526123 
	C2.000000,2499.916748 2.000000,2495.833252 2.000000,2490.875000 
	C8.548754,2485.098389 16.217310,2485.156006 24.709040,2485.045410 
	C46.970860,2486.862061 68.580284,2485.844482 89.842712,2489.819336 
	C96.023544,2490.974854 102.390167,2491.970215 108.657928,2491.011230 
	C114.854538,2490.062988 119.873734,2490.245117 125.008461,2495.147949 
	C129.103058,2499.057373 135.808563,2497.785400 139.804749,2494.337646 
	C148.389420,2486.931152 157.538239,2488.329346 167.925903,2490.668457 
	C186.223068,2492.743408 203.557281,2490.165527 221.713562,2489.197754 
	C235.179062,2490.585205 247.369919,2485.854980 259.997925,2486.113281 
	C263.227509,2486.179688 266.474274,2485.001953 270.075775,2485.578857 
	C265.414307,2484.433105 260.581604,2485.832764 256.018829,2482.481445 
	C255.475357,2481.410645 255.435791,2480.899902 255.763000,2479.701172 
	C264.894043,2471.814941 275.389465,2471.619385 285.555145,2473.252441 
	C300.028778,2475.577393 314.362061,2474.472900 328.027191,2470.816650 
	C341.223053,2467.285889 354.331390,2468.549805 367.458344,2467.639404 
	C372.626434,2467.280762 381.101471,2467.625488 381.096619,2472.552490 
	C381.090332,2478.933105 372.919769,2476.941162 368.088715,2476.843262 
	C362.327362,2476.726318 357.051086,2479.308350 351.904694,2479.034668 
	C357.386780,2479.744385 363.320404,2475.019043 370.032288,2479.238770 
	C373.208923,2488.171875 367.191315,2488.862549 361.815826,2488.764404 
	C348.834106,2488.528320 336.100159,2490.959473 323.218079,2491.632324 
	C319.209412,2491.841797 315.400574,2492.973389 310.723267,2494.233398 
	C299.620178,2495.360596 289.340210,2494.770508 278.319763,2494.822998 
	C274.215515,2493.605713 271.119537,2494.040283 268.855804,2496.390137 
	C269.830719,2496.521973 270.829987,2495.335938 272.797974,2494.723145 
	C277.750580,2495.676514 285.856934,2493.986328 278.970978,2503.258301 
	C271.830475,2507.328857 263.270599,2504.434326 256.870544,2510.781250 
	C254.458389,2512.567627 252.424759,2513.351318 249.510376,2514.211914 
	C227.734787,2516.558350 206.632050,2513.509277 184.933929,2516.363525 
	C178.171936,2517.119629 172.056122,2516.838623 165.919342,2518.831543 
	C171.071289,2519.269531 175.930801,2518.711914 181.507233,2518.902344 
	C186.709564,2517.512451 191.244919,2516.745850 196.694946,2517.536133 
	C208.541595,2521.398193 219.646713,2524.497559 231.802200,2518.523682 
	C234.706421,2517.474365 236.925690,2517.372559 240.024292,2517.791016 
	C261.062439,2523.115967 281.207062,2518.891602 301.416779,2518.031006 
	C319.723206,2517.251953 337.458771,2512.239990 355.767944,2511.884766 
	C360.496674,2511.793213 365.104034,2511.411865 369.590363,2515.234863 
	C369.708313,2525.316162 361.144531,2521.276367 356.161133,2524.387451 
	C361.222778,2522.843994 365.953827,2523.065918 370.576691,2522.268311 
	C373.261169,2521.804932 376.094116,2522.484863 377.021149,2525.653076 
	C378.072144,2529.244385 375.964874,2532.301025 372.962463,2532.496338 
	C361.353668,2533.251709 351.780396,2540.122559 340.782776,2542.456543 
	C336.834747,2543.294189 332.683380,2543.031738 329.125610,2540.870850 
	C325.310822,2538.554199 321.576630,2538.718262 316.935852,2540.388184 
	C311.244202,2541.155273 306.185150,2541.331543 300.860443,2543.938965 
	C297.592041,2544.818848 295.040741,2544.788574 291.770996,2544.139648 
	C283.931946,2539.001465 275.818146,2537.451660 267.427338,2535.819824 
	C261.113495,2534.592285 259.328644,2536.428955 258.295715,2542.351562 
	C256.860565,2543.288574 255.894211,2543.502930 254.123444,2543.461914 
	C240.155701,2534.498291 225.257050,2539.092041 211.089691,2538.323486 
	C205.928375,2538.043457 200.563065,2539.572266 195.304626,2539.115723 
	C184.388443,2538.168213 174.378189,2540.256836 164.703125,2547.086182 
	C152.218658,2547.464355 140.502335,2547.516113 128.960037,2550.494385 
	C123.139313,2551.996338 117.251030,2550.125000 110.633743,2549.108154 
	C97.794777,2551.998535 85.820374,2549.185791 72.957504,2548.734863 
	C52.698051,2548.004150 33.310608,2549.112793 13.919425,2548.979980 
	C10.337045,2548.955566 6.610765,2549.537354 2.712340,2546.574463 
z"
        />
        <path
          fill="#E6E1D0"
          opacity="1.000000"
          stroke="none"
          d="
M1364.524414,4033.519531 
	C1361.222168,4034.000000 1358.444458,4034.000000 1354.833252,4034.000000 
	C1351.240845,4030.144043 1353.951050,4027.644531 1356.501099,4025.612793 
	C1368.401001,4016.129395 1382.172241,4009.100830 1392.697388,3997.770996 
	C1394.797363,3995.510742 1398.112305,3995.588135 1401.197754,3995.737305 
	C1411.818115,3996.251221 1415.053711,3994.194336 1418.708618,3984.245361 
	C1420.135010,3980.363281 1422.944946,3980.271973 1425.952148,3981.170410 
	C1432.354614,3983.083008 1437.089111,3982.367432 1438.802002,3974.792236 
	C1439.318848,3972.506592 1441.107910,3970.490723 1443.220581,3969.568115 
	C1449.844971,3966.676025 1455.193481,3962.551025 1458.526123,3955.979980 
	C1459.273438,3954.506348 1460.748413,3953.124756 1462.337524,3952.921631 
	C1469.732666,3951.976807 1472.309937,3947.668701 1472.134521,3940.594238 
	C1472.023315,3936.111328 1474.806641,3934.246826 1479.354248,3934.482910 
	C1486.728516,3934.866211 1490.240967,3931.931641 1487.306030,3924.197998 
	C1486.110718,3921.048340 1486.106445,3918.310791 1490.390503,3916.580078 
	C1494.009888,3916.541016 1496.788574,3917.372803 1499.561768,3918.236084 
	C1497.333862,3917.289795 1494.872925,3917.010254 1492.099243,3915.528320 
	C1490.765015,3914.572510 1490.173462,3913.852783 1489.529907,3912.354980 
	C1500.628052,3906.932129 1499.726685,3895.687500 1502.659790,3886.550781 
	C1503.659302,3883.437988 1504.199219,3880.129883 1504.462891,3876.828369 
	C1495.914307,3886.423584 1488.503662,3896.534668 1488.978027,3910.993652 
	C1488.574585,3912.056396 1488.330200,3912.446045 1487.549438,3913.266602 
	C1482.358154,3914.955078 1479.763062,3918.630371 1476.517822,3922.627441 
	C1475.131470,3923.518066 1474.240112,3923.829834 1472.595337,3924.109863 
	C1462.878784,3923.928467 1454.065430,3925.439697 1445.225342,3926.866943 
	C1440.025757,3927.706299 1437.030518,3930.871094 1434.629395,3935.951660 
	C1433.154175,3938.795654 1431.463623,3940.419922 1428.421875,3941.433594 
	C1425.167603,3941.609863 1422.512817,3941.293457 1419.734985,3943.474854 
	C1417.442749,3944.415771 1415.667969,3944.658447 1413.206543,3944.842773 
	C1406.362061,3944.850586 1401.713989,3947.774902 1397.587891,3953.010498 
	C1394.842285,3956.429199 1393.877808,3960.648438 1388.743408,3961.458984 
	C1382.171631,3956.171875 1376.750366,3955.658936 1369.753418,3960.566162 
	C1363.701904,3964.810059 1354.177124,3961.681152 1350.555786,3955.218262 
	C1346.529175,3948.032227 1352.325195,3943.856934 1357.125610,3940.843018 
	C1361.145752,3938.318115 1363.623291,3936.706299 1363.101074,3931.195801 
	C1362.545532,3925.334229 1368.145142,3924.921631 1372.369141,3924.646973 
	C1376.043945,3924.407471 1378.792725,3923.996826 1379.576050,3919.664795 
	C1380.579468,3914.115234 1384.739624,3911.620605 1389.634521,3909.642090 
	C1395.989014,3907.073486 1404.821655,3904.504639 1403.286743,3896.429199 
	C1401.847656,3888.857666 1393.129761,3886.925049 1385.681885,3885.689209 
	C1369.541504,3883.010742 1354.788818,3876.521973 1340.475220,3868.903564 
	C1339.277832,3868.266357 1338.794067,3866.896729 1338.423828,3864.875244 
	C1341.244751,3857.017578 1345.651489,3854.062012 1352.345581,3859.744141 
	C1355.767212,3862.648193 1359.082642,3860.849121 1363.201904,3860.679932 
	C1368.333252,3861.259277 1367.693115,3869.510254 1372.299561,3867.690186 
	C1380.360474,3864.505859 1388.441772,3870.918457 1396.469849,3865.978027 
	C1406.955811,3859.524658 1408.393921,3860.705322 1409.035034,3873.522705 
	C1409.087402,3874.565674 1409.517456,3875.427002 1411.129150,3876.152588 
	C1417.468872,3870.678711 1418.346924,3860.907959 1425.606079,3855.885986 
	C1427.195923,3854.785889 1425.667358,3853.847412 1424.934937,3852.769531 
	C1419.711060,3845.082520 1420.257324,3844.112305 1431.303345,3842.214355 
	C1432.520020,3842.718018 1433.035034,3843.116943 1433.491577,3843.586182 
	C1433.948608,3844.056152 1433.825195,3843.874268 1432.821777,3842.445557 
	C1431.835815,3837.652588 1434.043823,3834.468994 1437.118286,3832.530762 
	C1443.329956,3828.615723 1441.155640,3818.877930 1448.905518,3816.297852 
	C1443.391846,3805.891846 1453.190918,3803.262939 1458.182129,3798.374268 
	C1467.774658,3788.978516 1474.393066,3776.966309 1485.192383,3767.745850 
	C1488.295044,3764.140137 1491.216431,3764.168945 1493.971191,3766.972168 
	C1496.331177,3769.373779 1498.413452,3772.471436 1496.342651,3775.665283 
	C1493.982666,3779.305176 1494.214233,3780.371338 1498.693237,3780.652100 
	C1503.345825,3780.944092 1504.395752,3784.242188 1503.424072,3789.012207 
	C1502.837402,3790.031006 1502.528564,3790.393799 1501.595215,3791.106201 
	C1497.764282,3792.844971 1494.457153,3790.680420 1490.555420,3791.879395 
	C1488.487305,3792.260986 1487.089722,3792.334717 1484.999023,3792.454590 
	C1476.597046,3794.905273 1472.126587,3800.249023 1471.876587,3808.185547 
	C1471.727417,3812.920654 1470.069092,3815.775635 1466.838989,3819.252441 
	C1458.904785,3827.793945 1451.459839,3836.669678 1448.486206,3848.788086 
	C1446.257812,3857.869385 1438.512329,3864.343750 1433.124756,3871.884277 
	C1430.703247,3875.273682 1428.945435,3878.562988 1428.257202,3883.283203 
	C1424.245117,3895.646240 1418.526855,3905.846680 1407.322388,3912.231689 
	C1399.037231,3916.952637 1396.827026,3926.353027 1391.787842,3934.059326 
	C1403.237915,3929.170654 1415.722778,3928.656494 1427.018921,3924.000488 
	C1431.747192,3922.051758 1435.116821,3918.117676 1437.237061,3913.421875 
	C1439.716553,3907.930420 1443.145630,3902.987305 1445.934814,3897.679443 
	C1447.750122,3894.225098 1449.014526,3889.529297 1454.329468,3890.950195 
	C1459.602783,3892.359375 1457.683350,3897.364502 1458.333252,3901.302734 
	C1461.022583,3890.677002 1464.075195,3888.266113 1473.346069,3890.835205 
	C1477.494385,3891.984619 1477.776611,3889.968018 1480.352173,3887.718750 
	C1488.500000,3880.602783 1487.250122,3867.426025 1498.430054,3862.390381 
	C1503.568848,3860.075684 1500.699097,3855.789795 1497.303711,3852.824951 
	C1494.718750,3850.567627 1489.771118,3849.264648 1491.674683,3844.634521 
	C1493.413330,3840.404785 1498.291016,3841.242432 1502.028198,3840.080566 
	C1512.083618,3836.954834 1523.784424,3836.895752 1530.096191,3826.008789 
	C1532.504639,3821.854248 1536.210815,3822.906006 1540.279297,3825.151855 
	C1543.707886,3830.218994 1542.838257,3835.209961 1542.340210,3840.164062 
	C1540.242676,3861.028076 1534.499023,3881.247803 1531.304565,3901.944092 
	C1529.815308,3911.592773 1524.746582,3920.959961 1513.538940,3924.056641 
	C1511.620605,3924.586914 1510.032715,3925.063477 1508.955566,3927.243652 
	C1499.336182,3946.713867 1487.835083,3964.658691 1467.897827,3975.312256 
	C1465.478149,3976.605225 1463.660278,3978.424316 1461.758789,3980.289307 
	C1448.032104,3993.750977 1433.562988,4005.638428 1415.976440,4014.594482 
	C1406.557861,4019.391113 1398.122070,4021.638672 1388.199097,4022.206543 
	C1379.244873,4022.718994 1373.664185,4031.289307 1364.524414,4033.519531 
z"
        />
        <path
          fill="#6E8AA1"
          opacity="1.000000"
          stroke="none"
          d="
M2821.802979,2238.769531 
	C2827.679443,2238.505859 2833.944824,2236.327393 2839.144775,2241.529053 
	C2836.771729,2245.731201 2831.902344,2242.562500 2829.273926,2245.459961 
	C2830.066650,2249.292236 2833.966797,2249.393799 2836.246826,2249.467773 
	C2844.050293,2249.720459 2852.143311,2252.052002 2859.624756,2247.635254 
	C2860.438965,2247.154785 2861.540283,2246.686035 2862.403320,2246.832275 
	C2881.494629,2250.057373 2899.773682,2243.911621 2918.437012,2242.204590 
	C2934.009277,2240.780029 2949.371582,2243.354736 2964.149658,2246.389893 
	C2965.373779,2249.145752 2963.244629,2249.999512 2962.864502,2251.462646 
	C2961.873047,2255.279053 2963.719971,2258.104980 2967.087158,2257.026123 
	C2972.292725,2255.359131 2976.860352,2256.988281 2981.725098,2257.794678 
	C2984.809082,2258.305664 2987.567627,2258.224854 2986.956299,2253.242676 
	C2986.349121,2248.295166 2990.162842,2246.150635 2994.550781,2246.133301 
	C3001.157227,2246.107422 3007.870605,2244.987549 3014.357666,2247.148926 
	C3017.609375,2248.232666 3020.933105,2247.903076 3025.121582,2248.001465 
	C3026.000000,2262.708496 3026.000000,2277.416992 3026.000000,2293.062744 
	C3014.355713,2293.996094 3002.692871,2294.403809 2991.072266,2293.876709 
	C2978.537598,2293.308350 2965.972900,2292.363770 2953.554932,2290.627441 
	C2945.384033,2289.485107 2940.963867,2288.573242 2932.985107,2289.760742 
	C2930.700195,2290.100586 2928.339844,2289.943848 2926.013672,2289.994141 
	C2922.264893,2290.075195 2919.445557,2289.861084 2917.516357,2285.231201 
	C2915.221436,2279.724121 2908.476074,2278.961182 2903.682617,2282.902344 
	C2901.737305,2284.501465 2900.261963,2285.669922 2897.590088,2284.949951 
	C2894.833740,2284.207275 2891.773682,2284.882812 2891.446777,2287.993164 
	C2891.136963,2290.937256 2895.701904,2289.127686 2897.132568,2293.074951 
	C2885.613281,2293.104736 2874.708740,2300.937744 2863.551514,2292.012207 
	C2861.394287,2290.286377 2858.697998,2289.981445 2855.977051,2289.989502 
	C2853.981201,2289.995605 2851.711426,2290.198486 2850.250488,2288.740723 
	C2844.144287,2282.646240 2835.700195,2285.094482 2828.626709,2282.605957 
	C2826.932617,2282.009766 2825.112061,2282.419922 2824.475586,2284.260742 
	C2823.395508,2287.384033 2820.522949,2288.652100 2818.395752,2290.669922 
	C2816.054688,2292.891113 2815.532715,2297.487793 2811.638428,2297.419434 
	C2807.065674,2297.339355 2801.958740,2297.179932 2798.070312,2295.157471 
	C2790.500244,2291.220703 2781.707520,2291.195068 2773.422607,2286.058594 
	C2780.211914,2286.058594 2786.034180,2286.058594 2792.801025,2286.058594 
	C2791.804932,2281.354492 2787.996094,2277.135254 2793.805420,2273.622803 
	C2797.142334,2271.604980 2796.495850,2265.779541 2802.005859,2264.251953 
	C2797.030273,2260.530518 2792.581299,2259.960693 2787.923828,2259.989502 
	C2780.937500,2260.032715 2773.950195,2260.042236 2766.964355,2259.973633 
	C2763.765869,2259.942383 2760.204834,2260.421143 2758.916992,2256.319580 
	C2760.708252,2253.594971 2762.659180,2255.376953 2764.352051,2255.775146 
	C2774.004395,2258.045166 2782.492676,2253.016113 2791.533936,2251.513184 
	C2792.280273,2251.388916 2793.472412,2250.129639 2793.405762,2249.510986 
	C2792.574951,2241.806641 2798.292725,2244.203369 2801.982422,2244.061523 
	C2808.861572,2243.796875 2815.877686,2244.472412 2821.802979,2238.769531 
z"
        />
        <path
          fill="#DBD5C8"
          opacity="1.000000"
          stroke="none"
          d="
M1854.888306,4034.000000 
	C1857.120972,4027.171875 1864.228027,4025.227295 1869.985474,4022.410400 
	C1874.718140,4020.095459 1878.350708,4016.097656 1883.636963,4014.640869 
	C1886.990356,4013.716797 1884.224609,4009.925293 1885.322754,4007.052246 
	C1892.166016,4011.288818 1898.949097,4007.774170 1905.771851,4006.871826 
	C1907.591064,4006.630859 1909.700317,4005.397217 1909.566650,4002.907471 
	C1909.413330,4000.050537 1905.723389,4002.524658 1905.006104,3999.823486 
	C1906.589111,3997.451660 1910.627319,3996.611084 1910.507568,3992.625977 
	C1910.486938,3991.941650 1911.747437,3990.578125 1912.356079,3990.611572 
	C1920.518677,3991.063721 1920.150879,3984.061768 1923.478027,3979.607422 
	C1928.319580,3973.126465 1929.897461,3964.161377 1937.731812,3959.854248 
	C1941.402710,3957.836182 1936.837769,3954.207275 1939.027710,3951.664795 
	C1940.327271,3950.482910 1941.275757,3951.556396 1942.336182,3952.320557 
	C1945.354736,3954.495850 1947.229004,3953.746582 1947.888550,3950.062500 
	C1948.587524,3946.159424 1949.601196,3942.281250 1949.907349,3938.347656 
	C1950.277222,3933.597412 1951.418457,3929.157227 1953.934448,3925.178223 
	C1956.794800,3920.656006 1956.983032,3917.206055 1951.109985,3915.228760 
	C1949.837891,3914.800537 1948.912109,3913.689941 1949.053223,3912.186035 
	C1949.214844,3910.461670 1950.690674,3910.329834 1951.973511,3910.108643 
	C1952.952515,3909.939941 1953.975708,3910.010010 1954.945679,3909.811035 
	C1956.959961,3909.396973 1959.033813,3908.704346 1958.866943,3906.192871 
	C1958.723999,3904.036865 1956.517212,3904.308350 1955.048828,3903.792969 
	C1954.086792,3903.454834 1953.169312,3903.084229 1953.172729,3901.680908 
	C1954.204224,3899.009033 1957.368286,3898.874023 1959.596924,3896.534180 
	C1959.978027,3893.367920 1960.142700,3890.708008 1959.076782,3888.186279 
	C1958.096802,3885.866943 1958.390869,3884.073730 1960.180176,3881.935547 
	C1963.466919,3878.007812 1962.924927,3871.914307 1959.456787,3867.203857 
	C1957.986572,3865.207031 1958.580688,3863.996094 1960.252319,3862.249023 
	C1966.095947,3856.142090 1970.368164,3849.472412 1965.028564,3840.786133 
	C1964.108276,3839.288818 1963.691772,3837.484131 1964.458008,3836.047852 
	C1967.109375,3831.079102 1964.895996,3826.388672 1963.999878,3820.807617 
	C1961.589600,3815.522705 1963.765869,3811.116699 1964.164429,3806.664795 
	C1964.646973,3801.274170 1966.578247,3795.723389 1962.322388,3790.142090 
	C1961.599854,3789.185791 1961.359863,3788.769287 1960.955078,3787.653564 
	C1963.396606,3776.594238 1966.811401,3766.363281 1966.476685,3755.491699 
	C1966.208740,3746.794189 1964.240356,3745.389160 1955.426880,3746.072266 
	C1949.466431,3746.534668 1942.683228,3749.388428 1938.681274,3741.067871 
	C1938.884033,3738.870361 1939.517334,3737.577881 1940.957153,3735.917480 
	C1945.631836,3733.947754 1950.144165,3733.729980 1954.256226,3734.057861 
	C1968.419434,3735.187256 1977.685303,3721.035889 1976.477173,3710.248291 
	C1975.645264,3702.819092 1975.347778,3696.144043 1978.787598,3689.379883 
	C1974.011597,3697.799316 1971.848267,3707.094482 1968.938599,3716.124756 
	C1967.734009,3719.863525 1969.608887,3726.262939 1963.660034,3726.396484 
	C1958.639893,3726.509033 1959.799927,3720.263916 1957.635254,3716.350098 
	C1955.415649,3710.969482 1954.711060,3706.090820 1955.453857,3700.319092 
	C1956.610840,3696.020264 1956.326660,3692.569336 1954.239014,3688.629883 
	C1953.789795,3687.078369 1953.658936,3686.166504 1953.664673,3684.552490 
	C1954.149536,3681.481201 1955.364014,3678.631348 1957.663452,3678.963135 
	C1967.200806,3680.338135 1963.091797,3674.482910 1962.369873,3669.822510 
	C1961.933472,3666.262207 1960.604858,3664.889893 1956.875366,3666.216309 
	C1953.678955,3666.691895 1951.220093,3666.556641 1948.255249,3664.944580 
	C1947.044678,3663.524902 1946.790771,3662.507080 1947.105713,3660.637939 
	C1949.136963,3657.527100 1951.967041,3657.267334 1955.189941,3656.056152 
	C1958.463623,3655.232422 1961.026855,3655.074951 1964.335693,3655.087402 
	C1969.985596,3655.026123 1975.807251,3657.596191 1978.559448,3650.804688 
	C1974.550659,3647.932861 1970.130249,3655.632568 1966.820557,3650.322266 
	C1964.399414,3646.437500 1965.972656,3642.657471 1970.719604,3639.293701 
	C1966.394775,3638.170898 1962.463501,3637.882568 1959.340332,3635.648193 
	C1955.356812,3632.799316 1956.224365,3628.580811 1956.555908,3624.733643 
	C1956.924683,3620.454834 1960.353882,3617.887695 1963.152954,3615.119141 
	C1966.144043,3612.161621 1968.526611,3609.160889 1966.215332,3603.976074 
	C1965.569336,3601.294678 1965.615723,3599.326660 1966.487915,3596.683350 
	C1972.544556,3589.248535 1974.132202,3581.035400 1973.068604,3572.773682 
	C1972.236572,3566.310547 1973.582031,3561.084961 1977.664551,3555.496582 
	C1980.160156,3553.811035 1982.247070,3553.522461 1985.230469,3553.897949 
	C1992.985352,3557.519287 1993.688354,3564.368408 1994.964600,3570.803955 
	C1996.695679,3579.531250 1997.604614,3588.480957 2003.056763,3596.894043 
	C2004.450806,3599.562012 2004.697144,3601.695312 2004.870117,3604.641113 
	C2004.623413,3609.040527 2006.746704,3612.486328 2005.362427,3616.832520 
	C1994.106079,3622.372070 1995.339966,3611.505371 1991.297974,3606.974365 
	C1989.450073,3606.912842 1989.276733,3608.991943 1988.512085,3610.123779 
	C1986.805420,3612.649658 1980.762695,3612.375977 1982.730469,3616.594727 
	C1984.440552,3620.260986 1988.424438,3623.149170 1993.154419,3622.805908 
	C1997.962402,3622.456787 2001.693848,3623.214111 2002.813599,3629.485840 
	C2003.693115,3637.088623 2003.714844,3643.913330 2002.529419,3651.601562 
	C2001.446045,3654.653809 2000.992920,3656.903320 2001.009277,3659.275635 
	C2001.324829,3705.156250 1997.508423,3750.915527 1996.963379,3797.674316 
	C1996.965454,3799.770020 1996.972778,3800.962646 1996.971802,3803.035645 
	C1996.916870,3807.968750 1996.887939,3812.020996 1996.953735,3816.926514 
	C1997.312744,3821.162109 1997.350586,3824.520996 1995.875244,3828.520020 
	C1988.645630,3832.665283 1987.889282,3838.987061 1985.636475,3845.732178 
	C1978.435059,3867.294678 1981.638794,3891.534912 1968.416382,3911.412842 
	C1967.320679,3913.059814 1966.803833,3915.112793 1966.778076,3917.125732 
	C1966.524414,3936.985352 1957.033203,3953.922607 1949.487793,3971.441162 
	C1947.237793,3976.665283 1942.353882,3980.177979 1939.214478,3984.889648 
	C1936.088745,3989.581299 1932.005371,3993.789307 1930.629150,3999.416748 
	C1928.366089,4008.670654 1921.520630,4013.597168 1913.373901,4015.735840 
	C1898.450195,4019.654053 1884.214966,4024.837158 1870.557251,4033.508301 
	C1865.258911,4034.000000 1860.517700,4034.000000 1854.888306,4034.000000 
z"
        />
        <path
          fill="#EEE9DC"
          opacity="1.000000"
          stroke="none"
          d="
M1699.435425,3919.442383 
	C1700.455078,3926.355957 1698.078369,3931.723145 1694.128296,3937.291992 
	C1684.598145,3942.619629 1677.405273,3951.098145 1666.756958,3953.541016 
	C1665.490234,3953.831543 1663.555786,3954.327881 1663.776489,3955.651123 
	C1666.428589,3971.549805 1651.484009,3967.865967 1644.739380,3973.546143 
	C1648.043091,3982.830078 1647.171631,3984.402344 1636.727661,3984.866943 
	C1626.893799,3985.304688 1618.400146,3986.211182 1616.688477,3998.983398 
	C1615.878784,4005.024170 1608.934326,4007.524414 1603.638184,4008.629150 
	C1592.840088,4010.881104 1588.299194,4020.903564 1579.951050,4026.079590 
	C1578.162231,4027.188721 1583.234131,4030.167236 1580.450684,4033.466797 
	C1536.624878,4034.000000 1493.249756,4034.000000 1448.937256,4034.000000 
	C1443.102173,4027.585938 1446.013794,4021.921143 1451.325684,4018.638916 
	C1459.917236,4013.329834 1466.434814,4005.228027 1476.984253,4002.307373 
	C1486.945679,3999.549316 1491.790039,3999.899170 1496.140991,4009.350586 
	C1497.199707,4011.650391 1497.602417,4012.922119 1499.923462,4011.697021 
	C1501.941162,4010.632324 1501.006714,4009.031250 1500.185059,4007.461670 
	C1491.376099,3990.636719 1491.840698,3989.880615 1510.696289,3989.965820 
	C1532.910156,3990.066406 1537.593018,3986.548828 1546.565552,3962.557861 
	C1552.102417,3956.776123 1559.904785,3955.150635 1564.040405,3947.948975 
	C1566.058716,3944.434570 1572.030884,3944.359375 1575.766235,3947.582764 
	C1579.262207,3950.599121 1577.221191,3954.278564 1575.490967,3957.579102 
	C1573.639404,3961.111572 1571.876709,3964.676758 1570.552124,3970.184326 
	C1577.202271,3963.424805 1577.695801,3954.352295 1584.722778,3948.905029 
	C1587.374023,3947.109131 1589.678955,3946.339600 1592.801025,3945.728516 
	C1604.711060,3938.532227 1618.099243,3937.014160 1630.179443,3931.996582 
	C1639.382202,3928.174316 1649.111450,3920.354980 1660.877930,3923.535645 
	C1662.097046,3923.864990 1661.188721,3924.262939 1661.760498,3923.502686 
	C1669.203857,3913.604736 1679.598145,3920.261475 1689.154419,3918.311523 
	C1690.822266,3917.779785 1691.813354,3917.592041 1693.605469,3917.467285 
	C1696.010986,3917.519531 1697.507935,3917.987793 1699.435425,3919.442383 
z"
        />
        <path
          fill="#F5F1E5"
          opacity="1.000000"
          stroke="none"
          d="
M1411.504028,3942.465820 
	C1414.120483,3941.986084 1416.249390,3941.981934 1419.176514,3941.974121 
	C1421.353516,3940.794189 1423.166992,3940.854004 1425.379150,3940.123535 
	C1428.266724,3939.904297 1430.125244,3939.071777 1431.683594,3936.546387 
	C1443.316528,3938.342529 1451.626831,3932.961182 1459.452271,3925.684570 
	C1462.456055,3922.891602 1467.065430,3923.901611 1471.420532,3922.379395 
	C1472.894897,3922.013916 1473.781982,3922.008301 1475.334595,3922.001465 
	C1480.447021,3920.402832 1481.715820,3915.621094 1485.544678,3912.479004 
	C1486.009766,3912.019531 1486.997559,3912.002930 1487.491455,3911.996582 
	C1488.324951,3911.638672 1488.664551,3911.287109 1489.513672,3911.462891 
	C1490.640137,3912.229004 1491.149414,3912.611328 1491.785156,3913.558838 
	C1492.766846,3914.760986 1493.035889,3915.681396 1492.413208,3917.375488 
	C1488.704834,3918.707520 1486.447754,3921.236572 1489.818726,3923.129150 
	C1495.763550,3926.467041 1492.453857,3929.916748 1490.754028,3932.935791 
	C1487.793945,3938.193359 1484.048706,3939.759277 1479.774902,3937.622803 
	C1475.761353,3935.616211 1473.558838,3936.117432 1474.706177,3940.707520 
	C1475.511963,3943.931641 1474.458740,3946.551270 1474.084351,3949.444092 
	C1473.521973,3953.789795 1471.974976,3956.344482 1466.891113,3954.629150 
	C1462.743530,3953.229248 1460.751709,3956.123779 1459.379761,3959.356445 
	C1456.923340,3965.144531 1453.650146,3969.509766 1446.692505,3970.583740 
	C1443.625244,3971.057129 1440.895264,3973.794922 1440.441528,3977.525635 
	C1440.190063,3979.593506 1442.706421,3982.257080 1439.481323,3983.633057 
	C1436.171143,3985.045410 1432.789795,3986.881348 1428.961914,3985.318115 
	C1427.423218,3984.689697 1425.937256,3983.932617 1424.402832,3983.292969 
	C1423.147949,3982.770020 1421.713501,3982.375000 1420.845215,3983.736084 
	C1420.072876,3984.947754 1420.402100,3986.309326 1421.568481,3987.250000 
	C1422.953491,3988.367676 1425.923584,3988.330566 1424.846069,3991.227783 
	C1423.988770,3993.532959 1421.981323,3993.666992 1419.860962,3992.966797 
	C1416.462280,3991.844727 1414.794312,3993.986816 1413.803223,3996.585205 
	C1411.849365,4001.706787 1408.900757,4001.656250 1404.498291,3999.438965 
	C1399.669556,3997.006836 1395.924805,3997.965820 1391.400269,4002.264160 
	C1385.717285,4007.663086 1381.888428,4016.158936 1372.464844,4016.879883 
	C1367.595703,4023.544434 1358.181152,4024.916504 1354.088013,4033.293457 
	C1333.958252,4034.000000 1313.916382,4034.000000 1292.937256,4034.000000 
	C1291.678711,4024.885010 1289.228149,4017.099365 1279.358032,4014.063232 
	C1276.040283,4013.042969 1275.047852,4010.317871 1274.090088,4006.720459 
	C1275.283325,4006.325439 1276.618042,4006.519043 1277.841553,4006.997803 
	C1286.525513,4010.393555 1286.609131,4010.447266 1290.113525,4001.382080 
	C1291.080078,3998.881836 1292.407959,3998.496826 1294.645996,3999.310059 
	C1296.821289,4000.100830 1299.121704,4000.478027 1301.035400,4002.005859 
	C1303.606567,4004.059082 1306.992188,4005.449219 1303.629395,4009.716064 
	C1301.286011,4012.689453 1303.290527,4015.154297 1306.237549,4015.562012 
	C1310.719849,4016.182129 1314.924438,4019.340820 1319.811157,4017.030762 
	C1323.642456,4015.219482 1324.218384,4013.053223 1320.657715,4010.729980 
	C1318.372803,4009.239258 1315.648193,4008.419189 1316.085693,4005.079346 
	C1316.823120,3999.449463 1314.873901,3995.598145 1306.552124,3994.307129 
	C1310.414673,3991.294189 1313.025635,3990.067627 1314.423584,3988.014893 
	C1318.675659,3981.771729 1315.547241,3974.289062 1308.106201,3973.163086 
	C1305.914917,3972.831299 1304.446899,3972.503662 1303.213989,3970.738770 
	C1301.604980,3968.436035 1299.092041,3966.818359 1296.560059,3966.659668 
	C1290.873657,3966.302979 1286.361328,3964.160156 1282.692383,3959.925049 
	C1282.274170,3959.442139 1281.593018,3958.854004 1281.045288,3958.865479 
	C1272.514771,3959.042480 1265.447876,3954.037354 1257.473877,3952.304688 
	C1255.186279,3951.807373 1252.586182,3951.554443 1250.825195,3953.373779 
	C1246.614380,3957.724121 1243.946289,3954.590576 1240.798462,3951.879639 
	C1236.437134,3948.123535 1233.802734,3943.016602 1230.012329,3938.832031 
	C1229.108032,3937.833984 1228.412109,3936.518066 1229.349487,3935.249756 
	C1230.187988,3934.115479 1231.878174,3933.581543 1232.928589,3934.146973 
	C1240.788208,3938.376953 1250.121704,3931.369629 1257.678955,3937.477051 
	C1257.903564,3937.658691 1258.339355,3937.688965 1258.650757,3937.636475 
	C1263.067383,3936.892090 1267.905762,3934.388428 1270.461548,3940.782959 
	C1272.063721,3944.791504 1278.792480,3945.630615 1277.072388,3951.951172 
	C1276.628174,3953.583496 1279.361694,3953.750732 1280.998047,3953.981689 
	C1287.493408,3954.899414 1293.919800,3955.442627 1299.885864,3959.113525 
	C1303.865234,3961.562500 1308.197998,3958.284424 1312.040039,3956.510498 
	C1313.767578,3955.712891 1315.022827,3953.898926 1316.503540,3952.558594 
	C1318.362305,3950.876709 1320.436523,3949.481445 1322.436401,3952.081055 
	C1324.448486,3954.697266 1322.715698,3956.505127 1320.377930,3957.519043 
	C1313.184937,3960.638184 1316.287842,3965.784668 1316.962891,3971.562744 
	C1320.931030,3969.510254 1323.790283,3966.627197 1324.300171,3964.066650 
	C1326.444580,3953.299316 1335.619873,3946.042480 1337.910522,3934.736816 
	C1338.821899,3933.229736 1339.794800,3932.899902 1341.653931,3933.335938 
	C1342.215454,3946.564209 1346.813965,3959.806885 1339.238037,3972.772461 
	C1335.653809,3976.284912 1331.963379,3978.559814 1329.695068,3982.461182 
	C1326.405151,3988.119385 1324.490112,3992.823730 1331.528809,3997.605957 
	C1337.864136,4001.910400 1337.173584,4008.731201 1333.997925,4016.302246 
	C1341.220703,4012.148193 1345.390259,4007.194824 1349.639038,4001.438721 
	C1344.893921,3998.448730 1340.272461,3996.652588 1335.603394,3995.244629 
	C1328.634155,3993.143066 1327.292603,3989.447754 1331.194214,3983.453857 
	C1333.173218,3980.413330 1334.351929,3976.822266 1337.927979,3974.061523 
	C1347.954956,3973.997803 1354.119385,3966.157715 1362.592041,3963.695312 
	C1370.585327,3961.372314 1378.282837,3958.635254 1387.363525,3960.303711 
	C1390.541138,3959.754395 1391.832642,3958.314453 1392.058838,3955.797607 
	C1392.207031,3954.147949 1392.975342,3952.885498 1395.267944,3952.101074 
	C1400.626343,3948.370117 1406.114624,3946.146729 1411.504028,3942.465820 
z"
        />
        <path
          fill="#1B2B3A"
          opacity="1.000000"
          stroke="none"
          d="
M2.673041,3012.538086 
	C2.000000,3008.571289 2.000000,3005.142578 2.000000,3000.856934 
	C16.419094,2992.893555 31.577003,2990.031006 47.349506,2993.628174 
	C54.934101,2995.358154 62.428974,2995.614014 70.079369,2995.711426 
	C79.009705,2995.825195 88.179665,2995.304443 94.788826,3003.465332 
	C96.783257,3005.927979 100.408272,3005.736816 103.266525,3005.219482 
	C124.820412,3001.320557 146.395004,2997.583008 168.138351,2994.873291 
	C169.369446,2994.719727 170.769226,2994.284424 171.444504,2992.271484 
	C166.649063,2990.687744 162.401367,2992.465332 158.216248,2994.183838 
	C143.792603,3000.104980 129.306992,3001.027100 114.474930,2995.544678 
	C103.835709,2991.611816 92.786407,2993.150146 81.854385,2994.033936 
	C78.544441,2994.301270 75.273331,2995.123047 71.922462,2994.721436 
	C69.580307,2994.440430 67.159325,2994.023682 65.856125,2991.809814 
	C61.867119,2985.034668 61.735435,2984.248779 53.655003,2986.209961 
	C40.316357,2989.447266 30.930662,2980.898438 20.169146,2976.367920 
	C18.943710,2975.852051 18.788406,2974.189209 18.780754,2972.037842 
	C21.783636,2966.386475 26.431841,2967.010742 31.773418,2967.462891 
	C43.113510,2973.192871 53.042122,2971.176025 63.344559,2964.822754 
	C66.053619,2963.631836 68.163841,2963.468018 71.103378,2963.607910 
	C73.828079,2964.054688 75.909142,2964.844727 77.720192,2964.179932 
	C89.186447,2959.971191 100.985443,2958.914062 113.828949,2959.750977 
	C118.899780,2958.695312 123.246780,2958.698486 128.403275,2958.861816 
	C134.726105,2959.528320 139.759918,2957.503906 145.676346,2955.747559 
	C151.952362,2954.123291 157.418472,2952.578125 164.033737,2952.980469 
	C181.122772,2953.291504 197.221786,2950.308594 214.265442,2951.203613 
	C218.013260,2951.880615 220.267654,2954.606201 224.324463,2953.997070 
	C227.256592,2953.989746 229.237717,2954.537109 231.359344,2956.704834 
	C232.620682,2958.858398 232.918350,2960.424316 231.892120,2962.667236 
	C221.343765,2966.077637 211.269409,2967.469482 200.855469,2965.996582 
	C186.962921,2964.032227 173.025665,2965.432129 159.108871,2965.537354 
	C162.647247,2964.893555 166.273560,2964.754150 169.879807,2965.196289 
	C172.876678,2965.563721 176.458145,2966.176270 176.575729,2969.879639 
	C176.696198,2973.673584 173.089020,2974.569336 170.124481,2974.729248 
	C162.818695,2975.124512 155.414444,2976.040527 148.189468,2975.126953 
	C135.722763,2973.550293 123.682732,2975.175293 111.641243,2977.992676 
	C107.742447,2978.905273 103.769142,2979.322754 100.425194,2978.869873 
	C108.249329,2979.495605 116.547829,2978.426514 124.732910,2976.145264 
	C127.954247,2975.247559 131.348160,2974.426514 134.604385,2975.017578 
	C151.442978,2978.073975 168.389816,2973.700195 185.350937,2976.284912 
	C195.392242,2977.815186 205.894699,2977.377441 216.120834,2974.884766 
	C222.393692,2973.355469 228.509598,2976.788574 234.889648,2976.695068 
	C240.970306,2976.605713 246.728989,2974.962646 253.410034,2974.021484 
	C260.003235,2974.437012 266.189026,2977.308594 271.761047,2971.063965 
	C281.842102,2970.557373 290.897522,2971.916748 299.834595,2977.000977 
	C300.923950,2979.442627 300.266785,2980.922363 298.562927,2982.818848 
	C287.657928,2986.214355 277.031372,2986.402588 266.505554,2987.254395 
	C257.610321,2987.973877 248.895645,2989.260498 240.508560,2993.175537 
	C258.977417,2995.411377 277.950256,2993.448242 296.397156,3000.847656 
	C302.295685,3000.572998 306.989044,3002.627686 311.891785,3003.417725 
	C314.233093,3003.794922 316.476349,3005.011719 316.665100,3007.769775 
	C316.859436,3010.609375 314.582123,3011.805664 312.378296,3012.583252 
	C304.144104,3015.489014 295.451385,3016.044922 286.885559,3017.013428 
	C280.234863,3017.764893 273.752472,3018.712158 267.508698,3021.286621 
	C261.511200,3023.760010 255.030914,3024.135742 248.835510,3022.857422 
	C235.759628,3020.159180 222.471909,3020.329590 208.534027,3019.005371 
	C205.517975,3018.661133 203.261063,3018.016113 200.402985,3019.218750 
	C198.532547,3019.846191 197.240234,3019.994873 195.288300,3019.969238 
	C192.545914,3019.501709 190.524323,3019.592529 188.064194,3021.091797 
	C186.560074,3021.503418 185.668198,3021.542725 184.104095,3021.305664 
	C177.556854,3017.733154 171.669907,3014.343262 163.734085,3016.167480 
	C160.206360,3016.837646 157.363968,3016.894287 153.845367,3016.114502 
	C150.165558,3014.031494 147.227005,3014.474609 143.588623,3016.409668 
	C123.675552,3016.943115 104.641533,3020.692139 84.628319,3018.955566 
	C81.271103,3018.341309 78.900673,3016.548096 75.392731,3017.578613 
	C73.351974,3017.945068 71.986877,3017.843506 69.984856,3017.182373 
	C66.105049,3013.375488 61.819725,3013.059570 56.670830,3013.052246 
	C43.196419,3014.813477 30.396275,3014.537598 17.653011,3015.252930 
	C12.798767,3015.525391 7.769872,3016.038818 2.673041,3012.538086 
z"
        />
        <path
          fill="#020D19"
          opacity="1.000000"
          stroke="none"
          d="
M2770.785889,3673.994629 
	C2768.916260,3668.025635 2762.208252,3668.372803 2759.209473,3664.425049 
	C2757.864258,3662.654053 2755.461426,3661.721924 2753.698730,3660.221191 
	C2752.326904,3659.052979 2751.868408,3657.331543 2752.836426,3655.744629 
	C2753.659912,3654.395020 2755.182129,3654.650391 2756.404785,3655.234131 
	C2768.267334,3660.896484 2781.009521,3661.917236 2793.871582,3663.113281 
	C2810.162598,3664.627930 2824.721924,3672.587158 2840.203369,3677.204590 
	C2850.076172,3680.149414 2859.992188,3682.901611 2869.940430,3685.545410 
	C2871.790527,3686.037109 2873.955078,3685.927734 2875.850830,3685.520508 
	C2878.377197,3684.977783 2879.681885,3683.036621 2879.764648,3680.402832 
	C2879.836182,3678.117432 2878.758789,3676.613770 2876.568115,3675.967285 
	C2874.343018,3675.310059 2872.117920,3674.233887 2869.864746,3674.156250 
	C2853.714355,3673.600342 2839.094727,3666.218750 2823.355225,3663.891846 
	C2822.388184,3663.749023 2821.226807,3663.556396 2820.566406,3662.944092 
	C2818.041504,3660.604492 2813.451416,3659.012207 2815.267578,3654.512939 
	C2816.789795,3650.741943 2820.228760,3650.858154 2824.360352,3650.786133 
	C2833.354980,3650.629395 2842.864258,3651.688232 2850.977295,3645.659668 
	C2854.127197,3643.319336 2857.838623,3641.573242 2862.177002,3643.596924 
	C2863.578857,3644.250488 2865.597656,3644.363525 2867.091553,3643.915283 
	C2880.633301,3639.851074 2894.119385,3643.750977 2907.621094,3644.010986 
	C2914.644531,3644.146484 2921.527588,3645.534180 2928.060059,3647.879639 
	C2933.304199,3649.762207 2938.018555,3652.880127 2943.973633,3650.211182 
	C2945.649170,3649.460205 2948.521729,3649.777344 2949.218506,3651.895264 
	C2950.893311,3656.986572 2954.542480,3658.173584 2959.246094,3658.303223 
	C2960.452637,3658.336670 2961.761719,3659.165283 2962.804932,3659.928223 
	C2972.397949,3666.941650 2983.781006,3670.930420 2993.302246,3678.078369 
	C2996.870850,3680.757324 3001.481934,3681.468994 3005.574951,3681.641846 
	C3011.934326,3681.909668 3018.033447,3683.511719 3025.152832,3683.994141 
	C3026.000000,3690.722656 3026.000000,3697.445068 3026.000000,3705.083984 
	C3022.694824,3706.102295 3019.319092,3705.608154 3016.150146,3706.960449 
	C3005.767334,3711.390869 2995.968994,3708.522705 2986.544434,3704.135010 
	C2979.539795,3700.874023 2972.394043,3698.826660 2964.737549,3697.839844 
	C2958.185791,3696.995361 2951.911621,3694.842529 2946.115479,3691.378906 
	C2938.063477,3686.567383 2935.839844,3686.782471 2932.250732,3694.873047 
	C2929.353760,3701.403809 2924.358643,3699.573486 2918.844971,3699.992188 
	C2916.951172,3698.916016 2915.867432,3697.867432 2914.391113,3696.411621 
	C2913.106445,3696.009521 2912.219727,3696.009277 2910.667969,3696.009277 
	C2898.031494,3700.625977 2886.069336,3704.823242 2873.180908,3699.066162 
	C2868.866943,3697.139404 2864.127441,3698.204590 2858.787598,3697.999512 
	C2857.111084,3698.000000 2856.222168,3698.000000 2854.666504,3698.000000 
	C2849.481934,3694.683105 2843.668945,3694.059326 2838.552490,3690.418945 
	C2831.518799,3686.719727 2824.339355,3687.040527 2816.715820,3686.000732 
	C2807.291260,3680.206055 2796.713135,3681.912109 2786.657227,3678.433594 
	C2780.938965,3677.562744 2776.973389,3673.172119 2770.785889,3673.994629 
z"
        />
        <path
          fill="#627A90"
          opacity="1.000000"
          stroke="none"
          d="
M3025.441406,2617.437012 
	C3026.000000,2618.888916 3026.000000,2619.777832 3026.000000,2621.333496 
	C3022.328369,2625.370361 3018.114990,2625.055176 3013.901855,2623.514648 
	C3009.796631,2622.013184 3005.572998,2621.462891 3000.423340,2621.077637 
	C2993.499023,2618.987061 2987.164795,2618.767090 2980.883789,2618.379883 
	C2954.659424,2616.760986 2929.193115,2610.252930 2903.313232,2606.416504 
	C2892.033936,2604.744629 2880.782227,2604.967529 2869.497559,2605.475586 
	C2851.471191,2606.286865 2833.845459,2602.820312 2816.109131,2600.601074 
	C2812.082764,2600.096924 2808.212646,2599.896729 2803.231201,2601.222412 
	C2816.085938,2599.184326 2827.551025,2602.295166 2839.066895,2603.652344 
	C2852.252197,2605.206055 2865.268799,2607.407471 2878.618164,2605.487793 
	C2891.375732,2603.652832 2903.660889,2607.852783 2916.185791,2609.068115 
	C2922.126465,2609.644531 2927.981201,2610.881104 2933.980225,2611.143066 
	C2938.572754,2611.343262 2942.512939,2612.947510 2942.786133,2619.289551 
	C2932.962891,2623.909668 2923.186768,2620.434570 2912.675781,2620.861816 
	C2909.107422,2620.152100 2906.404297,2619.347168 2902.735840,2620.316650 
	C2869.855225,2616.068359 2837.534912,2621.952637 2805.490479,2617.564941 
	C2792.319336,2615.761475 2779.137451,2615.566162 2765.784668,2617.091797 
	C2755.457275,2618.271973 2744.925049,2613.481934 2733.612061,2616.970703 
	C2716.329834,2615.511719 2699.798096,2615.000732 2683.297852,2613.966797 
	C2698.918701,2615.332520 2714.653564,2614.923096 2731.013916,2617.022461 
	C2735.102051,2617.076660 2738.412598,2616.845459 2742.545410,2617.508301 
	C2745.619873,2618.391357 2747.703369,2619.400879 2750.177490,2621.436523 
	C2751.105713,2623.293945 2751.097656,2624.457031 2750.125000,2626.292969 
	C2743.894287,2629.861084 2737.889404,2629.393311 2731.997070,2628.433350 
	C2714.552734,2625.591553 2697.111084,2624.135986 2679.514160,2626.998535 
	C2676.229736,2627.533203 2672.732666,2627.581055 2669.656982,2626.335938 
	C2645.254639,2616.458008 2619.386963,2619.318359 2594.067871,2617.517578 
	C2586.785400,2616.999268 2579.372803,2617.524414 2572.716309,2611.663574 
	C2570.569824,2607.971191 2572.736328,2606.534180 2575.446289,2604.586670 
	C2589.663818,2603.666016 2602.984863,2604.736328 2617.114746,2606.363770 
	C2635.640625,2602.966064 2653.660156,2604.702637 2671.505859,2603.001465 
	C2687.604492,2601.466553 2703.948486,2602.025391 2720.153564,2600.973145 
	C2727.856201,2600.473389 2735.258301,2602.624756 2742.847412,2602.863770 
	C2724.049072,2599.121338 2705.073975,2601.611084 2686.182617,2601.524414 
	C2676.911865,2601.481689 2668.507568,2599.163818 2660.380615,2595.150635 
	C2658.028564,2593.989258 2655.115723,2592.949707 2655.444580,2589.680664 
	C2655.771484,2586.430908 2658.658936,2585.846680 2662.171875,2585.297363 
	C2664.131104,2585.222900 2665.251953,2585.253662 2667.200195,2585.479736 
	C2679.810059,2589.805176 2691.630371,2590.093018 2704.319580,2585.717773 
	C2728.320312,2584.096924 2751.390381,2584.854248 2775.106445,2588.886719 
	C2794.227783,2584.979492 2812.590088,2581.756348 2831.924316,2583.638672 
	C2827.101074,2582.765625 2822.663330,2582.020752 2818.187744,2581.543213 
	C2813.257568,2581.017090 2808.261230,2581.603760 2803.356689,2581.035156 
	C2799.098633,2580.541748 2793.445312,2581.639648 2793.592041,2573.899902 
	C2797.800781,2570.159668 2802.319336,2571.224609 2807.431885,2571.053223 
	C2812.476318,2571.034424 2816.650635,2570.838135 2820.205078,2566.562988 
	C2824.228516,2564.944824 2827.517822,2564.937500 2831.523193,2566.677734 
	C2834.597656,2570.452881 2837.425293,2573.040771 2841.762207,2573.482666 
	C2854.137939,2574.743164 2866.571777,2579.098877 2878.750244,2572.339355 
	C2881.060547,2571.056885 2883.897461,2570.839111 2886.542236,2571.161377 
	C2904.573486,2573.358643 2922.937744,2572.419434 2941.567627,2576.879883 
	C2967.017578,2578.018066 2991.627686,2579.157227 3016.053467,2582.606445 
	C3019.331055,2583.069336 3022.547607,2583.934326 3025.385254,2587.374023 
	C3026.000000,2590.133301 3026.000000,2592.266846 3026.000000,2595.200195 
	C3019.285156,2602.046143 3011.326416,2601.495117 3003.312012,2600.093750 
	C2996.758545,2598.947754 2990.366455,2596.717285 2983.665527,2596.613525 
	C2978.230713,2596.529541 2972.318604,2595.141357 2968.598633,2601.320312 
	C2967.047363,2603.896729 2963.995117,2603.661377 2961.299561,2603.368408 
	C2936.551025,2600.678711 2911.833008,2597.676270 2892.449951,2596.594971 
	C2919.635742,2596.431396 2951.500732,2604.347656 2984.079834,2606.401367 
	C2997.393311,2607.240479 3012.520020,2608.706055 3025.441406,2617.437012 
z"
        />
        <path
          fill="#708497"
          opacity="1.000000"
          stroke="none"
          d="
M325.392090,2391.496582 
	C326.461823,2396.120361 329.538513,2395.986084 333.278412,2395.989746 
	C337.423096,2394.207031 340.293488,2394.581055 342.550323,2398.848633 
	C342.418457,2400.740234 341.904694,2401.686035 340.497192,2402.978271 
	C320.451660,2404.834961 303.182861,2413.874268 284.791138,2418.884766 
	C278.476776,2420.604980 273.001648,2418.252197 266.422180,2417.045898 
	C263.518341,2416.871094 261.391418,2416.913086 258.467468,2416.659668 
	C245.532532,2414.243652 233.500870,2413.468750 221.934021,2419.063477 
	C218.323135,2420.810059 214.274414,2421.013916 209.774658,2422.583496 
	C207.871155,2422.807617 206.777374,2422.872314 204.860001,2422.909180 
	C202.951309,2422.920410 201.864243,2422.930176 199.971954,2422.983398 
	C194.186096,2423.620850 189.183090,2423.100586 184.196075,2423.323242 
	C180.312973,2423.496338 176.196732,2421.860107 175.970367,2429.119141 
	C175.836639,2433.407471 169.645416,2433.015625 165.738068,2432.274902 
	C140.593826,2427.509277 115.567062,2430.657959 90.526649,2433.165039 
	C88.544937,2433.363525 86.566216,2434.089600 84.591049,2433.891357 
	C63.390686,2431.760742 42.316833,2435.230469 21.171013,2435.220703 
	C14.915237,2435.217773 8.375774,2435.886719 2.588999,2430.531006 
	C2.000000,2425.258789 2.000000,2420.517822 2.000000,2414.888184 
	C6.205797,2410.170654 11.011430,2411.622803 16.505560,2412.422363 
	C22.295502,2411.874512 28.091379,2409.124268 30.705488,2416.302734 
	C31.500748,2418.486572 32.653008,2417.218018 33.642384,2417.253906 
	C58.809059,2418.171631 83.875893,2414.738037 109.042381,2415.487793 
	C109.992279,2415.516113 111.030487,2415.354004 112.730530,2412.879150 
	C101.101952,2412.966064 90.492256,2409.111328 80.020691,2414.835205 
	C78.848541,2415.476074 77.660484,2416.509033 76.414154,2416.440674 
	C60.984306,2415.594727 45.074013,2420.724121 30.293562,2411.312012 
	C24.889906,2407.870850 17.741169,2407.426270 10.312168,2409.577148 
	C7.384743,2410.316650 5.279401,2410.196045 2.659050,2408.498291 
	C2.000000,2405.222168 2.000000,2402.444336 2.000000,2398.833252 
	C8.629598,2391.935303 17.265688,2388.836670 25.412838,2388.514648 
	C35.484261,2388.117188 42.653965,2381.814209 51.586342,2379.650879 
	C43.315910,2381.315186 35.155125,2381.626709 27.208923,2386.563965 
	C20.358677,2390.820068 10.664042,2391.582031 2.494770,2384.489258 
	C2.000000,2380.571289 2.000000,2377.142578 2.000000,2372.856934 
	C9.258770,2366.791992 17.964600,2369.179932 25.925680,2367.759033 
	C34.641029,2366.204102 43.544464,2365.666992 52.151234,2363.239502 
	C63.914188,2359.921631 75.716896,2362.561768 88.374435,2363.377930 
	C96.603256,2366.487549 104.269234,2364.334229 111.771324,2364.877441 
	C119.348251,2365.426025 125.510254,2367.658691 124.741814,2377.773193 
	C114.384491,2384.425537 103.259163,2383.564209 96.981041,2383.874512 
	C111.197243,2381.372070 129.975082,2384.748291 148.794724,2384.636230 
	C169.724609,2384.511719 190.705795,2385.805176 211.306641,2380.170410 
	C218.327759,2378.250000 225.791397,2377.960938 233.649963,2380.841309 
	C237.585220,2388.564453 232.612366,2390.601562 227.347092,2390.846924 
	C220.442123,2391.168457 217.208023,2394.645264 215.274231,2401.433350 
	C201.001785,2401.589844 186.754852,2406.161865 173.606873,2398.273926 
	C170.421890,2396.362793 166.732132,2395.344727 163.056519,2396.077393 
	C153.118896,2398.057861 143.225571,2396.568359 132.496826,2396.286133 
	C111.386238,2395.594482 91.305878,2397.407227 70.654060,2401.485596 
	C66.757645,2402.602295 63.648312,2401.020752 60.503723,2401.387207 
	C63.315617,2401.158447 66.059639,2402.424072 69.653076,2401.721680 
	C86.161674,2398.500732 101.932663,2395.770752 118.889565,2397.632568 
	C124.335747,2403.504883 130.294235,2403.216553 137.145401,2403.901611 
	C158.256165,2406.012695 179.357147,2400.211426 200.518539,2405.378174 
	C209.588135,2407.592529 219.634949,2404.493164 230.058701,2404.953613 
	C248.210464,2402.927246 265.383270,2399.055176 283.092285,2400.663818 
	C289.819916,2401.275146 296.717194,2400.676270 298.879852,2392.040527 
	C299.539429,2389.406738 301.783966,2388.299561 304.264191,2388.504639 
	C311.132996,2389.072266 318.287964,2387.426514 325.392090,2391.496582 
z"
        />
        <path
          fill="#CBC5B5"
          opacity="1.000000"
          stroke="none"
          d="
M1364.937256,4034.000000 
	C1364.099243,4031.235840 1366.074585,4030.040527 1368.196045,4028.945557 
	C1370.557007,4027.726562 1373.610596,4027.038330 1375.126465,4025.134766 
	C1381.143555,4017.578613 1389.269775,4018.417725 1397.354248,4018.756348 
	C1403.000732,4018.992676 1408.735596,4019.698242 1410.705444,4012.251953 
	C1411.188477,4010.425537 1413.163696,4009.984619 1414.817505,4010.151855 
	C1421.075195,4010.784912 1425.556396,4008.343018 1429.176880,4003.227051 
	C1430.598022,4001.218994 1433.727783,4000.860596 1435.921509,3999.259521 
	C1445.816040,3992.038574 1454.930542,3984.057861 1462.679565,3974.518311 
	C1463.906006,3973.008789 1465.154907,3970.045410 1468.240967,3972.141602 
	C1469.155518,3972.762939 1470.502197,3972.660889 1470.758911,3971.724121 
	C1472.470215,3965.483154 1477.525513,3964.270020 1482.839111,3963.105225 
	C1483.873657,3962.878418 1485.170776,3961.405762 1485.395996,3960.311768 
	C1487.440674,3950.377441 1496.757568,3945.053223 1500.479736,3936.220459 
	C1501.238647,3934.419678 1502.977539,3934.238037 1504.592651,3933.888428 
	C1505.984497,3933.587646 1506.998291,3933.005371 1506.683594,3931.297363 
	C1505.527832,3925.022217 1506.054565,3924.133301 1512.250732,3921.779053 
	C1522.936279,3917.718506 1528.540405,3907.811523 1528.970337,3895.095947 
	C1529.189087,3888.627441 1531.815674,3882.268799 1533.034180,3875.800293 
	C1536.029541,3859.901855 1540.213013,3844.167236 1539.996460,3826.905762 
	C1539.143921,3824.787842 1538.955200,3823.421387 1539.140381,3821.276367 
	C1539.356323,3819.156982 1539.728027,3817.788818 1540.318115,3815.745605 
	C1540.221802,3801.576660 1544.770630,3788.667480 1545.307983,3775.271240 
	C1545.373535,3773.638672 1546.218628,3771.831787 1545.624878,3770.378174 
	C1541.193848,3759.530273 1544.508789,3748.950195 1546.649536,3737.645264 
	C1549.679810,3733.654053 1552.084229,3730.097168 1552.808594,3724.975098 
	C1552.813477,3723.421143 1552.817139,3722.524902 1552.990845,3720.949219 
	C1554.694092,3716.896973 1556.090942,3714.071533 1551.959106,3710.823975 
	C1546.228516,3706.319580 1546.203125,3701.373779 1553.345947,3697.732422 
	C1554.906738,3696.936768 1556.312378,3695.847168 1556.479370,3694.128662 
	C1557.754028,3681.020752 1562.481445,3668.458008 1562.456543,3654.468750 
	C1562.027222,3651.245117 1562.713745,3648.927002 1564.096558,3646.041504 
	C1567.649902,3641.339111 1568.938965,3635.373779 1574.976685,3634.072754 
	C1579.812622,3633.030518 1585.303955,3629.431152 1587.675171,3638.050781 
	C1588.868042,3642.387695 1593.440063,3639.825684 1596.450317,3640.002930 
	C1601.203857,3640.282959 1606.791626,3639.739502 1606.876831,3646.254639 
	C1606.951172,3651.917480 1602.238770,3653.830811 1597.114624,3654.085693 
	C1593.782715,3654.251465 1590.453979,3654.336670 1586.210205,3655.415283 
	C1588.740356,3658.765869 1590.720337,3661.923584 1586.852783,3665.888428 
	C1580.069946,3672.842285 1580.580322,3676.052246 1587.846191,3682.058350 
	C1590.419434,3684.185547 1595.984131,3684.147461 1594.512451,3689.287354 
	C1593.230347,3693.765381 1588.984619,3696.629395 1584.696167,3696.778076 
	C1577.164185,3697.039062 1576.957275,3701.268799 1577.736084,3706.712402 
	C1579.245850,3717.266357 1576.980469,3727.256348 1573.205688,3737.009766 
	C1571.122925,3742.391602 1569.521118,3747.108154 1574.349365,3752.601807 
	C1578.635132,3757.478516 1574.509033,3762.760742 1571.460938,3767.067139 
	C1560.620605,3782.382568 1560.539917,3782.325439 1576.778076,3793.963379 
	C1580.755371,3801.747803 1576.082642,3808.007080 1574.938477,3815.470215 
	C1571.354126,3831.767578 1564.649414,3846.298096 1561.202515,3861.868896 
	C1559.706909,3868.625000 1559.634399,3875.765625 1556.150146,3882.813477 
	C1550.430176,3888.782471 1545.997070,3894.983887 1539.258911,3898.937744 
	C1535.233887,3901.299316 1534.543213,3906.072021 1533.963501,3910.304199 
	C1531.865601,3925.620117 1524.434326,3937.521973 1511.853516,3946.389893 
	C1507.742188,3949.287598 1504.026978,3953.113770 1502.054077,3957.467285 
	C1496.537354,3969.641357 1486.192871,3977.455811 1477.280762,3986.531006 
	C1473.609253,3990.269775 1474.279175,3991.606445 1479.370361,3993.073242 
	C1482.702026,3998.046875 1479.666626,4000.332764 1476.243408,4002.021729 
	C1470.058350,4005.073730 1464.219238,4008.317139 1458.606201,4012.618164 
	C1448.229858,4020.569092 1435.526367,4025.085205 1424.491943,4033.517090 
	C1404.624878,4034.000000 1385.249756,4034.000000 1364.937256,4034.000000 
z"
        />
        <path
          fill="#698196"
          opacity="1.000000"
          stroke="none"
          d="
M256.604828,2479.244629 
	C256.053528,2480.229736 256.040619,2480.585693 256.014465,2481.475586 
	C253.321259,2487.715820 248.344803,2485.447754 243.954865,2485.623047 
	C234.998749,2485.980225 225.715057,2481.476562 216.359909,2487.685303 
	C211.724472,2489.299561 207.774307,2489.228516 202.967865,2488.500488 
	C194.071838,2484.686523 186.031601,2482.462158 177.121735,2486.024414 
	C172.471680,2487.883545 167.170654,2487.001465 162.238831,2486.737549 
	C145.900955,2485.863281 129.977814,2489.831787 113.780655,2490.450684 
	C111.453476,2490.539551 108.795898,2491.500977 106.930206,2490.241211 
	C94.500710,2481.848877 81.028091,2490.606201 68.060555,2487.555420 
	C55.178951,2484.525146 41.647163,2484.785889 28.366434,2484.040771 
	C27.025511,2483.965332 25.861206,2484.855957 23.797398,2485.579590 
	C16.464054,2484.957520 9.824656,2488.704834 2.713174,2484.571289 
	C2.000000,2481.222168 2.000000,2478.444336 2.000000,2474.833252 
	C13.692062,2467.285889 26.771093,2466.770264 39.593319,2466.715576 
	C64.482376,2466.609375 89.394951,2468.075195 114.111740,2463.636230 
	C96.900459,2464.461182 79.833977,2467.752441 62.532253,2466.995850 
	C58.285225,2466.810059 53.691879,2467.577881 51.098190,2461.927734 
	C53.964397,2454.239746 60.886227,2455.219971 66.325836,2453.583740 
	C69.758469,2452.551025 73.599754,2452.886719 77.552795,2450.428711 
	C97.563820,2449.249512 116.695244,2448.480225 135.866989,2445.517822 
	C149.433411,2443.421875 163.710922,2444.835449 177.397812,2450.274902 
	C184.878891,2453.248047 193.923477,2451.591064 202.044678,2449.293945 
	C218.434128,2444.657715 235.608276,2444.025635 251.987366,2439.272217 
	C256.431122,2437.982422 261.277832,2438.378418 265.907288,2439.148193 
	C268.837402,2439.635498 272.502960,2440.138672 272.532104,2443.927490 
	C272.564514,2448.134033 268.757812,2447.698242 265.828217,2447.995117 
	C255.584961,2449.034180 245.410370,2450.477783 239.226624,2452.226562 
	C260.272461,2448.990723 284.971680,2444.089111 310.801208,2441.151367 
	C333.132599,2444.376953 354.725037,2442.290039 376.269989,2441.757812 
	C382.085266,2441.614258 387.732849,2438.710449 393.438507,2437.020020 
	C396.990906,2435.967529 400.447174,2434.945068 404.943359,2436.095459 
	C413.090424,2440.425781 419.688110,2445.511963 429.110748,2445.563965 
	C436.994507,2445.607422 440.785645,2452.000732 441.371643,2460.468750 
	C440.655273,2462.141846 439.914001,2462.777832 438.198059,2463.174805 
	C433.778809,2461.294434 430.142548,2460.540771 426.056152,2464.171875 
	C400.922821,2469.814941 376.315857,2466.863525 351.755646,2465.902832 
	C329.896271,2465.048096 308.076385,2462.973877 286.225494,2466.918701 
	C278.097412,2468.386475 269.600952,2467.819092 261.334625,2466.601562 
	C254.999802,2465.668701 248.772064,2465.331787 242.555664,2467.253662 
	C240.324768,2467.943359 237.997864,2468.187500 235.641922,2467.902588 
	C207.495193,2464.499512 179.381454,2467.560791 151.257111,2468.273682 
	C176.860413,2467.565430 202.469788,2468.983154 228.099701,2467.861816 
	C235.840134,2467.523438 243.976471,2467.956787 251.833725,2469.150391 
	C257.355530,2469.989258 260.753174,2472.447266 256.604828,2479.244629 
z"
        />
        <path
          fill="#1B2B3A"
          opacity="1.000000"
          stroke="none"
          d="
M400.017395,3221.178711 
	C395.514709,3227.096436 395.514709,3227.096436 401.966980,3233.239502 
	C396.822266,3237.286133 390.992767,3235.170410 385.709961,3234.609619 
	C375.076996,3233.480713 364.480682,3233.287598 353.843201,3233.304932 
	C345.197327,3233.318848 336.900757,3233.082031 328.683563,3228.878174 
	C322.165741,3225.543457 314.261505,3226.696289 307.099274,3228.389160 
	C301.392822,3229.737793 295.849091,3231.831055 288.656769,3232.735596 
	C298.939453,3236.053955 308.950714,3233.092285 317.622620,3239.434082 
	C317.097168,3244.212402 320.675995,3245.573730 323.052460,3247.749756 
	C321.550812,3251.124756 318.604675,3251.910156 315.655457,3251.868652 
	C307.703918,3251.756592 299.664978,3253.035645 291.823059,3250.875732 
	C289.144958,3250.137939 286.661865,3250.103271 284.008362,3250.929688 
	C282.138916,3251.511475 279.776672,3252.395996 278.209717,3251.750000 
	C269.646851,3248.219238 261.848663,3251.555176 254.035553,3254.081299 
	C244.706100,3257.097656 235.153168,3258.187988 225.800964,3255.731445 
	C211.768372,3252.045410 198.039368,3254.712891 184.302765,3256.496338 
	C173.139252,3257.946045 161.954575,3258.964600 150.762024,3260.016846 
	C148.850891,3260.196533 146.399628,3259.415771 145.351654,3261.514160 
	C144.108200,3264.004395 146.002182,3265.850830 147.631180,3267.528564 
	C148.089340,3268.000244 148.715759,3268.303467 149.214798,3268.741455 
	C150.478455,3269.850342 151.846451,3271.082520 150.490356,3272.854736 
	C149.557312,3274.074463 147.930695,3273.852051 146.806686,3273.234619 
	C137.725571,3268.249023 127.711182,3274.458008 118.509674,3270.840576 
	C117.798813,3270.560791 116.499702,3271.084717 115.775902,3271.633301 
	C104.675880,3280.047119 92.046303,3274.586914 80.141541,3275.596924 
	C79.537872,3275.648193 78.563362,3275.377930 78.312599,3274.941162 
	C73.780205,3267.043945 66.401878,3269.831299 59.542046,3269.236572 
	C52.368198,3268.614746 45.184669,3267.068115 38.005402,3265.918457 
	C31.796865,3264.923584 26.884892,3268.604248 22.148647,3270.803955 
	C16.192005,3273.570801 11.681346,3273.556152 6.459960,3270.495605 
	C5.665332,3270.029785 4.512236,3270.175537 2.762465,3270.019043 
	C2.000000,3268.500000 2.000000,3267.000000 2.000000,3264.750000 
	C3.149028,3263.323486 1.595966,3264.057617 2.675678,3263.304443 
	C3.890894,3262.456299 5.211931,3262.169189 6.597547,3261.947266 
	C5.303684,3261.878662 4.055227,3261.652344 2.479364,3260.449219 
	C2.000000,3256.571289 2.000000,3253.142578 2.000000,3248.856934 
	C3.890216,3245.983398 6.461178,3245.211670 9.682814,3243.972412 
	C12.580719,3243.210205 14.735296,3242.970947 17.745720,3243.083008 
	C29.348089,3243.006348 40.006828,3244.172119 50.745781,3240.264648 
	C57.409908,3237.839844 65.111938,3239.751465 72.185776,3244.030273 
	C73.771751,3250.180908 68.338486,3250.593018 64.943199,3254.296875 
	C76.516159,3255.777832 88.561104,3252.554199 97.392815,3262.364990 
	C94.765411,3259.849365 92.276299,3258.122070 92.745010,3254.404541 
	C93.146767,3251.218262 95.633537,3249.687256 98.286057,3249.501709 
	C114.341034,3248.379883 127.658150,3239.554688 142.247711,3234.319336 
	C153.886505,3230.143066 155.847549,3230.990479 163.133789,3240.254150 
	C167.781723,3246.164062 179.182266,3246.723877 185.273575,3240.373047 
	C189.188644,3236.291260 193.356506,3234.961426 198.510590,3234.956299 
	C214.219528,3234.940674 229.316940,3229.991211 244.912399,3229.180664 
	C249.800171,3228.926514 251.057236,3226.225098 251.557709,3221.461914 
	C255.890656,3216.989746 260.928467,3216.890625 265.908630,3216.751953 
	C273.531769,3216.540283 281.161560,3216.822998 288.784760,3216.572266 
	C293.457520,3216.418701 298.642059,3216.438232 301.912567,3212.949219 
	C308.245636,3206.193359 314.398254,3207.702393 322.501984,3209.787354 
	C340.320801,3214.371338 358.133453,3220.712891 377.093048,3218.130615 
	C382.774628,3217.357178 388.254730,3220.013916 394.723572,3218.482910 
	C397.332703,3218.362305 398.909149,3218.827881 400.017395,3221.178711 
z"
        />
        <path
          fill="#131C25"
          opacity="1.000000"
          stroke="none"
          d="
M95.955917,3920.065430 
	C101.558647,3921.715332 100.416420,3926.033447 99.628059,3929.216553 
	C94.770096,3948.833496 93.661079,3969.035156 90.760277,3988.913330 
	C88.679184,4003.173828 84.783531,4017.481201 85.985382,4033.094482 
	C80.599976,4034.000000 75.199944,4034.000000 68.899963,4034.000000 
	C65.967468,4026.072754 62.066788,4024.157471 55.108582,4029.445557 
	C51.722294,4032.019287 47.218330,4032.280029 42.544132,4033.573242 
	C28.742878,4034.000000 15.485754,4034.000000 2.000000,4034.000000 
	C2.000000,3996.625244 2.000000,3959.249756 2.000000,3920.937256 
	C4.232552,3917.359619 6.989584,3915.136963 10.478538,3915.403564 
	C22.531809,3916.325439 30.046803,3909.187500 37.282585,3901.156250 
	C40.807343,3897.244141 44.245888,3892.949707 50.920677,3892.741211 
	C58.104832,3896.216064 58.870392,3901.666016 57.428951,3908.098633 
	C54.742149,3920.088623 48.174335,3930.180420 40.753735,3939.447510 
	C34.042088,3947.830078 29.944481,3957.205566 25.959610,3967.618652 
	C20.948479,3976.658447 23.247591,3981.131348 32.567810,3981.887695 
	C36.395790,3982.198486 39.724586,3980.889648 43.035454,3979.291992 
	C65.328903,3968.533691 83.046371,3953.508057 90.464142,3928.760742 
	C91.407509,3925.613770 92.464272,3922.393799 95.955917,3920.065430 
z"
        />
        <path
          fill="#49647C"
          opacity="1.000000"
          stroke="none"
          d="
M3025.368164,2703.477783 
	C3026.000000,2709.400146 3026.000000,2714.800049 3026.000000,2721.100098 
	C3014.011475,2728.156250 2998.910645,2729.027100 2989.798584,2723.049316 
	C2980.123779,2716.702393 2971.041016,2716.436523 2961.302979,2714.530518 
	C2950.809326,2712.476318 2940.315674,2711.602783 2928.895996,2713.025391 
	C2923.059570,2713.314453 2918.269287,2711.627197 2913.350830,2711.111572 
	C2900.274170,2709.741211 2887.573975,2706.881348 2873.923340,2710.503662 
	C2860.953613,2713.945557 2847.691406,2707.998291 2834.596924,2705.939941 
	C2831.014648,2705.376953 2827.548340,2704.034668 2823.851074,2703.994141 
	C2827.303223,2704.206055 2831.397217,2704.524658 2831.664551,2708.657715 
	C2831.908936,2712.438232 2827.743408,2712.409180 2824.975586,2713.090576 
	C2804.239502,2718.195801 2783.981689,2712.797363 2763.620605,2710.103516 
	C2760.347900,2709.670654 2758.191895,2708.763184 2755.354492,2712.321533 
	C2751.751221,2716.840088 2745.121338,2715.724609 2740.251953,2714.820557 
	C2728.044922,2712.553711 2715.724365,2713.614258 2702.751465,2711.961914 
	C2693.799805,2703.102783 2694.540771,2701.645752 2709.711426,2697.450684 
	C2728.373535,2693.048584 2746.000732,2688.250732 2764.931641,2689.666016 
	C2778.082275,2690.648682 2791.055908,2685.919678 2803.588379,2681.749268 
	C2808.762939,2680.027100 2813.621338,2678.856445 2819.044678,2679.203369 
	C2833.025391,2680.097656 2847.087646,2684.934082 2860.982422,2678.390869 
	C2862.697021,2677.583496 2864.842529,2679.118408 2866.621582,2678.986084 
	C2863.135986,2678.151855 2859.290771,2678.611328 2855.612305,2678.126709 
	C2851.015137,2677.521484 2845.058594,2677.569580 2845.947266,2669.518555 
	C2849.455811,2666.889404 2852.764160,2667.020752 2856.125977,2667.200928 
	C2878.714355,2668.410645 2901.277832,2670.128662 2923.906494,2670.515381 
	C2927.918701,2670.583984 2932.135742,2670.468750 2935.607666,2672.542969 
	C2941.137695,2675.846191 2946.799316,2676.195557 2952.912109,2675.122070 
	C2956.894287,2674.422607 2960.889404,2674.902344 2965.409668,2677.042480 
	C2976.259033,2683.629883 2987.430420,2686.051758 2999.038330,2686.667480 
	C3008.029785,2687.144287 3016.700195,2688.632812 3025.360352,2693.470215 
	C3026.000000,2694.888916 3026.000000,2695.777832 3026.000000,2697.333496 
	C3020.300781,2703.179443 3013.999268,2699.492676 3007.938232,2699.198242 
	C3005.270020,2699.068359 3002.386719,2696.309082 3000.337891,2697.475830 
	C2988.967285,2703.952393 2979.305664,2696.566895 2968.943848,2694.418213 
	C2958.880127,2692.331543 2948.392090,2694.695068 2938.298828,2692.025635 
	C2935.627441,2691.319336 2932.642822,2691.811523 2931.579102,2691.014404 
	C2945.487305,2693.559326 2961.157227,2694.636230 2976.366211,2698.393066 
	C2987.167725,2701.061279 2997.923096,2703.999756 3009.426758,2700.367188 
	C3014.210938,2698.856445 3019.963379,2700.072998 3025.368164,2703.477783 
z"
        />
        <path
          fill="#21364A"
          opacity="1.000000"
          stroke="none"
          d="
M3025.358643,3181.485352 
	C3026.000000,3200.041748 3026.000000,3218.083740 3026.000000,3237.062744 
	C3022.762939,3241.090332 3019.316406,3239.368896 3015.938477,3238.389893 
	C3009.042725,3236.390869 3002.041016,3235.038086 2994.854980,3234.738281 
	C2992.608887,3234.644531 2990.121582,3234.129883 2989.500000,3238.146973 
	C2988.916992,3240.309082 2988.106445,3241.536621 2986.293457,3242.948242 
	C2981.271973,3244.801758 2977.961670,3242.399170 2974.509277,3240.414062 
	C2966.665039,3235.903320 2958.608398,3234.201172 2949.786133,3237.807129 
	C2940.027832,3241.795898 2930.299072,3239.696289 2920.559326,3237.140869 
	C2916.423096,3236.055420 2912.288574,3234.765869 2907.259033,3234.048340 
	C2905.151367,3232.983887 2904.124023,3231.874756 2903.155762,3229.800781 
	C2905.545654,3224.647705 2902.701172,3221.314209 2900.573242,3217.116455 
	C2901.287598,3212.817139 2903.968262,3211.819580 2908.121094,3211.331787 
	C2893.308105,3207.326660 2879.113037,3210.359375 2865.104980,3209.508789 
	C2850.716064,3208.634766 2837.258545,3204.372559 2823.545410,3200.903564 
	C2821.014160,3200.263184 2818.717285,3198.306885 2819.302002,3195.011475 
	C2819.828613,3192.043701 2822.067627,3190.524170 2824.742920,3189.798340 
	C2830.341797,3188.279297 2835.939209,3189.057861 2841.524658,3190.092529 
	C2846.006104,3190.922363 2850.694824,3192.787109 2854.835938,3187.520996 
	C2867.177979,3185.835449 2878.874268,3187.901367 2890.627930,3187.984375 
	C2896.048828,3188.022461 2901.545898,3190.769775 2904.506836,3182.957764 
	C2905.877930,3179.340576 2910.589600,3180.165527 2913.396240,3181.987305 
	C2932.400146,3194.321533 2954.247559,3188.953125 2974.726562,3191.582520 
	C2982.501465,3192.581055 2990.938477,3188.322510 2996.019287,3191.199219 
	C2991.335449,3189.088867 2983.035645,3190.026855 2975.069092,3188.927490 
	C2957.926270,3186.562012 2940.858398,3183.930664 2923.887207,3180.438965 
	C2913.176025,3178.235107 2902.402832,3177.727539 2891.739258,3183.056885 
	C2884.461914,3186.693848 2876.792480,3184.122559 2869.954102,3178.453613 
	C2871.302246,3169.086670 2877.618652,3170.028320 2884.421631,3170.932373 
	C2883.788818,3167.688477 2878.402832,3165.271973 2882.941895,3160.922607 
	C2901.325684,3166.282471 2920.028809,3166.565186 2937.979492,3170.833740 
	C2946.123291,3172.770264 2954.235840,3174.080078 2962.406982,3170.985352 
	C2963.967773,3170.394043 2965.650879,3170.191162 2967.304443,3170.401123 
	C2986.637451,3172.858398 3005.580566,3177.441162 3025.358643,3181.485352 
z"
        />
        <path
          fill="#0D171F"
          opacity="1.000000"
          stroke="none"
          d="
M156.029846,3602.875488 
	C138.465530,3592.017578 119.779602,3590.170654 100.557343,3593.104980 
	C68.630890,3597.978760 38.937355,3609.260742 11.348152,3626.073242 
	C8.815980,3627.616211 6.503214,3629.630615 2.725317,3630.098145 
	C2.000000,3615.958008 2.000000,3601.916260 2.000000,3586.937012 
	C7.071133,3580.653076 14.300006,3579.589844 20.647484,3576.952148 
	C48.529289,3565.365479 77.236786,3555.867432 104.971245,3543.886230 
	C112.950768,3540.439209 121.765144,3540.774658 130.076004,3538.177246 
	C138.875534,3535.427002 147.985474,3533.718994 156.624100,3530.103271 
	C161.319778,3528.137695 167.064041,3526.042969 170.467987,3533.865479 
	C172.246277,3545.549805 166.817566,3555.630371 166.948730,3567.208252 
	C164.845016,3579.932617 163.134384,3591.898193 156.029846,3602.875488 
z"
        />
        <path
          fill="#020914"
          opacity="1.000000"
          stroke="none"
          d="
M168.588806,3534.591797 
	C167.640137,3532.362061 167.265518,3530.322021 165.069748,3530.885010 
	C160.936111,3531.944580 156.318207,3531.877441 152.873413,3534.754150 
	C148.800735,3538.155273 143.746109,3539.038330 139.000626,3539.574219 
	C133.317551,3540.215820 127.898666,3540.627441 123.178001,3544.264404 
	C122.227539,3544.996826 120.429970,3545.606689 119.475014,3545.203125 
	C111.456863,3541.815674 105.283569,3547.251221 98.832672,3549.828857 
	C84.080582,3555.723389 69.179901,3561.141846 54.291142,3566.644531 
	C43.230137,3570.732178 32.307186,3575.467773 21.330826,3579.939453 
	C15.494715,3582.317139 9.734703,3584.693604 2.778056,3585.981201 
	C2.000000,3582.571289 2.000000,3579.142578 2.000000,3574.856934 
	C7.938275,3576.446777 13.129028,3575.969971 18.617310,3571.896729 
	C29.351059,3563.930908 41.593960,3559.542969 54.878906,3557.132812 
	C65.604248,3555.187012 74.845932,3548.530273 85.241570,3544.987549 
	C94.246040,3541.918457 103.512512,3539.706055 112.410889,3536.175781 
	C120.028214,3533.154053 127.674713,3530.265381 134.995270,3526.580811 
	C141.792892,3523.159424 149.388336,3521.245850 156.443634,3519.517578 
	C166.732574,3516.997559 175.915573,3511.416748 187.201584,3510.001221 
	C189.047836,3508.917236 190.137970,3507.875977 191.605362,3506.412354 
	C196.989014,3506.796387 201.423904,3503.679443 207.154602,3503.976074 
	C212.937225,3502.759277 216.849838,3494.853516 223.192459,3502.329590 
	C223.170761,3506.917725 220.001648,3508.418213 216.566971,3511.163574 
	C229.883926,3510.078369 242.231918,3512.919678 254.521530,3509.795898 
	C259.126221,3508.625244 263.638184,3510.565430 268.936768,3511.173340 
	C283.606537,3514.900879 297.687683,3515.391113 312.632629,3513.379639 
	C316.300598,3513.528564 319.431427,3516.081543 322.600220,3512.256836 
	C324.677063,3510.565918 326.490051,3510.085449 329.150574,3510.007324 
	C343.190521,3512.296631 345.713226,3511.125488 351.444946,3499.735107 
	C352.947021,3498.736816 353.956787,3498.499023 355.825073,3498.585693 
	C366.591797,3500.686035 375.237732,3505.040527 382.602448,3512.190918 
	C389.729248,3519.110352 389.328125,3523.657227 380.578979,3528.140381 
	C378.801117,3529.051758 377.016174,3530.164551 375.108948,3530.569824 
	C348.649353,3536.195068 323.299774,3545.720703 295.103973,3544.734863 
	C276.850922,3544.096436 261.184784,3535.859619 243.668411,3534.391602 
	C236.085297,3533.755615 229.404129,3534.219238 222.506821,3537.782227 
	C208.859131,3544.832520 178.815002,3543.014893 168.588806,3534.591797 
z"
        />
        <path
          fill="#546D83"
          opacity="1.000000"
          stroke="none"
          d="
M312.446533,2443.405518 
	C300.549896,2445.700439 289.126007,2447.570068 277.650391,2449.034180 
	C264.508057,2450.710693 251.432190,2452.532715 238.526062,2455.721191 
	C233.913391,2456.860596 228.757904,2456.603271 221.828690,2454.954834 
	C238.552277,2447.526855 254.603622,2446.387695 270.582428,2444.333496 
	C263.405853,2440.916016 255.737885,2440.739746 248.909409,2443.034668 
	C237.147308,2446.987793 224.931503,2447.653320 212.964493,2449.986572 
	C211.343842,2450.302490 209.296600,2449.727295 208.062347,2450.521240 
	C198.842056,2456.454346 188.577103,2452.806396 178.888016,2454.118896 
	C173.669739,2454.825439 172.685883,2449.613281 169.235107,2448.665039 
	C165.417526,2447.616699 162.173248,2454.161377 158.316681,2451.264893 
	C152.558685,2446.940186 146.334442,2448.290283 140.174103,2448.069580 
	C135.862808,2447.915283 131.530792,2447.707520 127.781219,2450.684570 
	C125.873085,2452.199951 123.372177,2451.953857 121.041862,2452.002197 
	C111.766273,2452.194336 102.452538,2451.331787 93.287155,2453.842529 
	C88.623177,2455.119873 84.150314,2452.724121 78.746162,2452.067627 
	C73.449059,2455.229248 68.190422,2453.863525 63.331860,2454.375488 
	C49.512058,2455.831543 35.578819,2454.437012 21.671867,2456.149658 
	C15.187484,2456.948242 7.364913,2456.700928 2.547606,2448.644531 
	C2.000000,2442.599854 2.000000,2437.199951 2.000000,2430.899902 
	C13.796817,2432.111328 25.572996,2433.128662 37.443012,2430.275879 
	C40.675983,2429.498779 43.956646,2430.532471 47.235668,2431.285156 
	C51.707840,2432.311279 56.340164,2433.079346 60.481873,2429.629883 
	C63.340370,2427.249023 66.653770,2428.138428 69.965828,2428.954346 
	C78.042824,2430.944336 86.126350,2431.774902 94.283783,2428.978271 
	C99.740662,2427.107666 105.491501,2428.329102 111.103371,2428.029541 
	C115.345795,2427.802979 119.780235,2428.789307 123.528198,2425.705566 
	C125.327560,2424.225098 127.185165,2424.736084 128.989960,2425.984375 
	C133.250244,2428.931396 137.735489,2427.970703 142.372498,2426.939453 
	C149.246689,2425.410400 156.137512,2424.982666 162.772873,2428.289062 
	C166.690582,2430.241211 170.689911,2430.622070 175.023010,2428.711670 
	C173.293991,2424.597656 169.002792,2427.250977 166.900665,2423.930420 
	C170.012665,2420.813477 174.044678,2420.120850 178.256683,2420.062256 
	C184.896179,2419.969727 191.537613,2420.015381 199.110504,2420.037354 
	C200.897263,2421.055908 201.927856,2421.345703 203.568481,2420.444824 
	C204.875305,2420.008057 205.768402,2419.987793 207.331360,2419.983887 
	C212.702835,2423.002686 217.451111,2421.010742 222.071945,2419.912109 
	C229.948853,2418.039062 237.622559,2411.301514 245.719193,2419.590332 
	C246.880066,2420.778809 247.995087,2418.920166 248.825104,2418.089111 
	C251.067734,2415.844482 253.928162,2415.237549 257.441345,2414.352539 
	C260.138397,2413.984863 262.255707,2413.999268 265.166992,2414.025879 
	C267.908325,2417.336914 270.643341,2419.087158 274.595673,2419.562012 
	C278.210205,2419.996582 282.000427,2421.072998 280.343872,2426.935547 
	C277.863861,2429.896484 275.043762,2432.000244 272.137665,2431.227783 
	C261.824951,2428.486084 251.572708,2432.250732 243.884537,2431.145020 
	C262.489838,2428.776367 283.239838,2433.077393 304.132721,2434.996582 
	C308.622742,2435.408936 314.139984,2435.549561 312.446533,2443.405518 
z"
        />
        <path
          fill="#091A29"
          opacity="1.000000"
          stroke="none"
          d="
M3025.444824,3487.438965 
	C3026.000000,3499.375977 3026.000000,3510.752197 3026.000000,3523.063965 
	C3022.145752,3527.177002 3018.536865,3525.227539 3014.862549,3523.532715 
	C3006.970703,3519.892334 3000.208008,3514.212891 2992.665039,3510.275146 
	C2975.742188,3501.439453 2958.540771,3492.504883 2937.975830,3498.760254 
	C2927.971924,3501.802979 2917.428955,3499.632080 2907.346191,3497.759521 
	C2890.773682,3494.681396 2874.743164,3488.096436 2857.290283,3491.481934 
	C2853.281006,3492.259766 2849.718994,3489.580078 2846.355713,3487.281982 
	C2836.668945,3480.662842 2826.829590,3476.168213 2814.866211,3482.498047 
	C2809.009766,3485.596924 2802.356445,3483.572266 2796.245361,3482.038330 
	C2778.121582,3477.489014 2759.604736,3475.846680 2741.090088,3474.179688 
	C2733.311035,3473.479492 2726.289307,3470.236328 2719.119141,3467.727539 
	C2709.676270,3464.423096 2699.930176,3462.321777 2689.806152,3458.765137 
	C2687.874512,3456.198730 2687.671631,3454.019775 2688.617188,3450.954590 
	C2690.521729,3448.268311 2692.676270,3447.724121 2695.789795,3447.511230 
	C2697.130859,3447.607666 2697.657227,3447.695557 2698.932129,3448.029053 
	C2702.282715,3449.743164 2704.779541,3451.520508 2708.783203,3451.114258 
	C2720.521484,3451.745117 2730.724121,3456.397949 2742.388672,3457.409180 
	C2753.251953,3460.555420 2763.847656,3461.447266 2773.999023,3464.364502 
	C2775.911377,3464.914307 2778.519287,3465.449707 2779.626709,3463.780762 
	C2785.436523,3455.023926 2793.552979,3458.158203 2801.129150,3459.477539 
	C2806.195557,3460.359863 2809.470947,3459.137695 2811.565674,3453.589355 
	C2817.120117,3450.212158 2822.130615,3452.538330 2827.877930,3453.277344 
	C2832.965820,3455.094971 2836.727539,3454.158447 2840.602051,3450.444824 
	C2845.444824,3448.540527 2849.678223,3448.667236 2854.711670,3449.886719 
	C2869.484863,3456.061768 2883.391357,3462.580566 2898.127441,3466.161377 
	C2911.187256,3469.334961 2925.115234,3467.662109 2938.295410,3471.274902 
	C2939.857910,3471.703369 2941.658691,3471.310059 2943.112061,3470.432617 
	C2955.373535,3463.032227 2967.033203,3468.274170 2978.114502,3473.023682 
	C2985.895508,3476.358643 2993.401123,3478.831299 3001.907715,3479.063965 
	C3010.213379,3479.291260 3018.434326,3480.746582 3025.444824,3487.438965 
z"
        />
        <path
          fill="#374549"
          opacity="1.000000"
          stroke="none"
          d="
M2.000000,1862.932251 
	C8.021901,1862.179932 13.745080,1862.773926 18.073082,1867.960449 
	C21.840422,1872.474976 23.567654,1872.760132 27.458904,1868.190918 
	C30.679029,1864.409790 33.514603,1863.676392 37.741535,1865.591675 
	C41.412064,1867.254883 45.369419,1866.805542 49.288490,1867.024048 
	C52.874584,1867.224121 57.903709,1865.181396 59.438377,1871.659668 
	C60.056835,1874.270264 63.263439,1873.708496 65.038094,1871.480713 
	C66.569695,1869.558350 67.651222,1868.379395 70.715767,1869.603027 
	C76.208389,1871.796021 76.373924,1871.381470 77.928864,1863.389893 
	C78.447037,1861.622681 79.301102,1861.127075 81.211044,1861.507324 
	C81.564278,1871.161865 82.153160,1872.517456 93.159470,1872.008179 
	C98.553848,1877.244507 104.673393,1874.043579 110.002502,1875.052368 
	C118.644470,1876.688477 128.883484,1870.774048 135.828995,1880.466064 
	C136.291626,1881.111572 137.023254,1881.465576 137.453613,1881.001099 
	C142.812836,1875.216187 151.055801,1878.865112 157.472870,1874.510254 
	C160.063248,1874.000732 162.155960,1873.965210 165.032883,1873.898682 
	C168.788589,1874.426514 171.067505,1873.214355 173.460419,1870.584106 
	C174.287628,1870.088013 174.634827,1870.074097 175.502716,1870.036255 
	C178.234390,1871.500122 180.035721,1874.417725 183.964081,1871.997070 
	C193.160507,1872.672607 201.788406,1875.953369 210.221756,1871.239868 
	C211.564850,1870.489380 212.811203,1870.912720 213.817169,1871.883545 
	C215.060608,1873.083618 216.571106,1874.339600 218.164658,1873.844604 
	C226.042740,1871.396851 233.820862,1874.578247 241.638977,1873.993286 
	C242.954758,1873.894897 244.953522,1874.221069 245.470810,1873.506592 
	C250.407394,1866.689697 255.944489,1870.543823 260.661591,1873.080933 
	C265.057739,1875.445190 269.752258,1873.160034 274.041351,1875.364380 
	C276.073517,1876.408813 277.589722,1874.414673 277.912201,1872.114990 
	C278.181458,1870.194580 277.972382,1868.170654 279.779053,1866.698120 
	C283.499512,1863.665649 287.064209,1864.869263 287.956116,1869.588257 
	C288.139221,1870.557129 288.009857,1871.583862 288.182343,1872.556030 
	C288.599548,1874.907715 290.032227,1876.126953 292.380096,1875.365601 
	C294.447388,1874.695312 295.795563,1873.272949 294.645294,1870.850708 
	C294.015900,1869.525146 291.838684,1868.297363 293.997070,1866.792725 
	C295.541443,1865.716064 297.514069,1866.442017 298.354401,1867.875488 
	C301.346466,1872.978760 306.192200,1871.930176 311.597839,1871.993652 
	C317.596710,1873.175171 314.817596,1878.409790 317.563293,1881.587769 
	C318.078674,1882.741089 318.102539,1883.376099 318.068726,1884.486572 
	C318.965302,1889.564697 310.306702,1887.541138 312.716614,1893.617188 
	C313.107605,1895.140015 313.012634,1896.017944 312.139648,1897.245605 
	C288.996246,1894.650269 266.427002,1897.053589 242.938080,1897.664429 
	C233.801773,1898.033081 225.582001,1898.545044 216.580551,1896.990234 
	C213.763641,1896.568115 211.749161,1896.514160 208.982574,1896.553467 
	C200.057938,1899.036621 191.796387,1898.106812 182.678482,1896.881226 
	C180.955811,1896.411865 180.093018,1896.369873 178.582306,1896.346436 
	C175.754288,1895.961548 173.558548,1895.967041 170.701447,1896.186890 
	C168.523697,1896.229004 167.001907,1896.198120 164.719421,1896.200195 
	C157.767807,1895.455688 151.466171,1896.820557 144.559204,1894.877686 
	C142.335922,1894.405640 140.868683,1894.326050 138.667542,1894.305054 
	C113.366447,1893.524536 88.790810,1894.284546 64.221924,1893.947998 
	C60.064671,1893.890991 55.450695,1895.175903 52.141384,1891.091675 
	C51.922619,1890.821533 52.033821,1889.647217 50.578823,1890.372803 
	C35.316723,1897.984009 19.268341,1892.953613 2.763946,1892.529785 
	C2.000000,1882.621460 2.000000,1873.243042 2.000000,1862.932251 
z"
        />
        <path
          fill="#465869"
          opacity="1.000000"
          stroke="none"
          d="
M2528.573242,3995.169434 
	C2529.856689,4007.980225 2530.291992,4020.042480 2529.914551,4033.068359 
	C2491.958252,4034.000000 2453.916260,4034.000000 2414.937256,4034.000000 
	C2407.230957,4030.341553 2398.832031,4029.762207 2393.287598,4022.549316 
	C2394.817139,4011.208984 2401.729736,4018.015625 2407.375977,4017.942871 
	C2408.566406,4008.479736 2401.174805,4004.455078 2395.101074,4001.585449 
	C2389.376465,3998.881104 2388.133545,3995.050781 2387.697266,3990.109863 
	C2387.208008,3984.574707 2390.104736,3981.060791 2394.977783,3978.328125 
	C2402.934326,3973.866943 2410.570557,3974.026855 2418.489502,3978.048096 
	C2422.576416,3980.123779 2426.727051,3982.828857 2431.947754,3978.631836 
	C2450.991699,3979.264648 2468.418457,3985.195557 2486.328857,3988.540039 
	C2495.778564,3990.304443 2505.303223,3992.117676 2514.511963,3994.956787 
	C2518.917236,3996.314941 2522.229492,3994.329590 2526.683350,3993.200684 
	C2527.831299,3993.681396 2528.211182,3994.035889 2528.573242,3995.169434 
z"
        />
        <path
          fill="#626669"
          opacity="1.000000"
          stroke="none"
          d="
M96.536072,3919.182129 
	C95.704002,3922.373047 94.573425,3924.798584 93.872383,3927.342285 
	C86.854683,3952.803223 69.779823,3969.249268 46.675377,3980.417725 
	C40.717430,3983.297607 35.270077,3986.415771 27.938292,3984.295166 
	C18.377569,3981.530029 16.369183,3976.057617 23.568281,3967.506348 
	C25.771759,3967.515625 26.730833,3968.938477 28.364666,3970.471680 
	C29.031950,3971.222656 29.286705,3971.529053 29.914307,3972.284912 
	C31.323143,3974.220703 32.791546,3974.214600 34.972488,3973.764160 
	C60.221397,3968.286377 76.441475,3953.799072 83.552437,3929.472900 
	C85.269638,3923.598633 85.623543,3917.678955 82.886818,3911.203125 
	C81.455589,3904.278809 86.753380,3901.228271 89.834892,3896.223389 
	C86.618340,3897.792480 85.004395,3900.292725 82.667046,3901.794434 
	C79.394150,3903.896484 75.668762,3907.425049 72.132927,3903.748047 
	C68.817307,3900.300293 72.844200,3897.225830 75.041313,3893.776367 
	C68.808952,3897.543701 64.609222,3890.663330 58.368111,3891.488281 
	C53.405140,3888.177490 48.141022,3888.670166 43.549103,3888.964844 
	C35.482140,3889.482666 30.504118,3886.234619 27.017550,3878.763428 
	C35.363899,3866.748779 45.959251,3857.478027 55.876564,3847.397705 
	C38.181026,3852.374756 23.321053,3860.763672 16.251305,3879.945312 
	C14.035167,3886.887207 11.743527,3892.584473 2.664845,3888.505859 
	C2.000000,3875.957764 2.000000,3863.915283 2.000000,3850.936523 
	C9.781804,3845.824951 17.372993,3841.264893 25.694382,3838.175537 
	C29.289726,3836.840820 32.322758,3833.487061 36.771496,3835.110596 
	C37.305660,3835.305420 38.532448,3835.114014 38.586769,3834.892822 
	C40.205238,3828.300293 46.883389,3827.634521 50.953976,3823.994629 
	C58.363853,3817.369385 66.410484,3811.737793 75.229485,3806.041504 
	C78.095802,3806.351807 79.524834,3807.873779 80.208687,3809.738770 
	C82.706230,3816.549072 88.145897,3819.129639 95.281631,3820.666992 
	C98.945160,3824.896729 96.180290,3827.839844 94.249298,3830.820557 
	C87.943161,3840.554199 84.402679,3850.210938 93.187393,3860.612305 
	C97.364967,3865.558350 95.582878,3871.112061 93.168571,3876.518555 
	C97.419350,3873.154785 98.343498,3868.429199 99.551392,3863.763428 
	C100.755646,3859.111572 102.585243,3854.842285 109.192841,3855.521484 
	C109.978355,3869.816650 107.485710,3883.369385 104.346130,3897.760742 
	C108.360535,3900.432373 104.708000,3902.320557 103.882599,3903.123535 
	C100.975777,3905.950684 98.995323,3909.212402 97.344261,3913.603271 
	C96.824921,3915.653076 96.630920,3917.062256 96.536072,3919.182129 
z"
        />
        <path
          fill="#172837"
          opacity="1.000000"
          stroke="none"
          d="
M2689.524902,3458.656006 
	C2698.103760,3457.633301 2705.710938,3459.502441 2713.237061,3462.454102 
	C2720.932861,3465.472168 2728.889893,3467.822754 2736.730957,3470.471191 
	C2737.360596,3470.683594 2738.093262,3471.283691 2738.582031,3471.119873 
	C2748.076416,3467.932861 2756.582520,3473.937256 2765.699463,3473.975098 
	C2775.721191,3474.016602 2785.406250,3475.870361 2795.032471,3478.540771 
	C2803.310059,3480.836426 2811.537842,3482.731201 2819.771973,3477.336670 
	C2824.673828,3474.125244 2829.832520,3474.127441 2835.452393,3477.939209 
	C2840.099609,3481.091797 2846.518311,3481.196289 2850.310547,3486.604736 
	C2851.533691,3488.349121 2855.547119,3489.751953 2857.543701,3489.087158 
	C2875.567871,3483.087646 2891.651855,3492.042236 2908.460205,3495.178711 
	C2916.941162,3496.761719 2925.684082,3495.201904 2934.085449,3497.499512 
	C2939.149414,3493.311768 2945.575439,3495.096191 2951.137695,3493.541748 
	C2959.881592,3491.097656 2966.301270,3496.049316 2973.515869,3498.641846 
	C2975.009521,3499.178711 2976.491699,3501.399170 2977.584473,3501.145264 
	C2988.767090,3498.544678 2994.107910,3507.909180 3001.552490,3512.709229 
	C3008.799561,3517.381592 3016.247070,3521.438232 3025.247070,3523.988281 
	C3026.000000,3526.133301 3026.000000,3528.266846 3026.000000,3531.200195 
	C3023.159424,3534.455566 3020.249023,3533.172852 3017.672607,3531.764160 
	C3008.795654,3526.909668 2998.681396,3525.873291 2989.377197,3522.356201 
	C2987.308350,3521.574219 2984.699951,3520.338135 2982.805176,3522.321045 
	C2976.755127,3528.652832 2970.471924,3525.986816 2964.690674,3522.540039 
	C2955.500977,3517.061279 2946.238037,3514.307373 2935.339355,3516.531250 
	C2927.729980,3518.083496 2920.292236,3514.680420 2912.911133,3512.857666 
	C2904.230713,3510.714600 2896.012695,3511.562500 2886.538574,3515.753174 
	C2893.493896,3520.283691 2899.918457,3522.320557 2906.854492,3522.746826 
	C2912.837158,3523.114502 2918.754395,3523.841553 2924.604004,3525.088867 
	C2928.574463,3525.935791 2933.232910,3526.990234 2932.655029,3532.101074 
	C2932.113770,3536.888184 2927.463623,3536.122803 2923.861328,3536.402100 
	C2922.841797,3536.480957 2917.502441,3534.151611 2922.363770,3539.473145 
	C2923.961670,3542.888184 2923.124268,3545.374756 2920.940430,3548.337402 
	C2917.821777,3550.562256 2915.073242,3550.722656 2911.434570,3549.936768 
	C2902.656006,3544.618408 2893.696045,3541.428467 2885.414551,3536.885742 
	C2882.787598,3535.444824 2880.172607,3534.246094 2878.052246,3531.031250 
	C2876.804199,3527.379639 2875.353027,3524.726318 2872.607910,3523.191162 
	C2857.555420,3514.774170 2842.329102,3506.845703 2826.204102,3500.457275 
	C2805.646729,3492.312500 2783.983887,3489.173584 2762.771973,3483.932373 
	C2754.407959,3481.865479 2746.229492,3483.427002 2737.983887,3482.621826 
	C2721.776367,3481.038330 2708.548340,3470.662354 2692.126953,3467.450195 
	C2689.415771,3464.825928 2688.319824,3462.376709 2689.524902,3458.656006 
z"
        />
        <path
          fill="#294159"
          opacity="1.000000"
          stroke="none"
          d="
M3025.427979,2905.501465 
	C3026.000000,2913.385742 3026.000000,2920.771729 3026.000000,2929.078613 
	C3017.285156,2933.736328 3008.664795,2928.783447 2999.837158,2929.632568 
	C2994.836670,2930.113770 2989.378418,2929.330322 2983.724365,2928.897217 
	C2988.744873,2931.248779 2993.696533,2929.406006 2998.429932,2930.164307 
	C3003.552246,2930.984863 3007.410400,2934.219971 3011.291504,2937.236328 
	C3015.508301,2940.514404 3020.764404,2941.859375 3025.494141,2945.526367 
	C3026.000000,2954.713867 3026.000000,2963.427490 3026.000000,2973.070801 
	C3024.490967,2975.319580 3022.885498,2976.596436 3020.797607,2976.591309 
	C3003.842529,2976.548584 2987.297607,2975.038818 2972.782471,2964.761230 
	C2968.261719,2961.560547 2966.791748,2958.143799 2969.444824,2952.436768 
	C2971.053223,2946.660156 2974.645752,2945.055664 2979.511719,2945.673584 
	C2984.426514,2946.297607 2989.355225,2945.829834 2994.302246,2945.773682 
	C2966.849854,2946.616943 2939.058838,2950.304199 2912.992432,2935.043457 
	C2913.436035,2939.247070 2917.349121,2940.708252 2915.779785,2944.599365 
	C2910.100586,2947.719971 2904.854248,2945.991943 2899.822021,2945.088379 
	C2889.227783,2943.187256 2878.827881,2940.354004 2868.066650,2936.365234 
	C2867.389404,2928.424072 2863.245605,2925.493896 2856.293457,2925.192871 
	C2849.611084,2924.903564 2843.326660,2922.847656 2837.285156,2920.020752 
	C2835.189209,2919.040039 2832.908447,2917.617188 2830.718750,2918.020020 
	C2818.198242,2920.321777 2807.540527,2914.468262 2795.667480,2910.391602 
	C2785.752686,2907.231689 2774.987793,2909.529053 2766.993164,2900.966797 
	C2766.254639,2895.267578 2769.583252,2893.900635 2773.442627,2893.251709 
	C2778.093018,2892.470215 2783.045410,2892.090332 2787.288574,2893.911621 
	C2798.157959,2898.576660 2809.858154,2899.325684 2821.842773,2902.344727 
	C2837.024414,2909.553955 2852.220215,2914.090820 2868.014160,2916.721680 
	C2875.269287,2917.930420 2882.303467,2919.664551 2888.257324,2924.450684 
	C2891.197021,2926.813477 2894.481201,2927.638672 2898.487793,2926.405518 
	C2928.867188,2917.054199 2929.583984,2917.163086 2955.953613,2930.353027 
	C2949.437744,2920.646484 2937.963135,2922.423340 2928.363770,2918.112793 
	C2925.539551,2914.826416 2921.786377,2914.987549 2918.999023,2913.243164 
	C2916.976807,2911.977783 2914.952637,2910.518799 2915.298584,2907.844727 
	C2915.722656,2904.566406 2918.515869,2903.949707 2921.194824,2903.521973 
	C2923.858154,2903.096436 2926.470947,2903.702881 2929.109375,2904.011963 
	C2930.312500,2904.152832 2932.684570,2906.254150 2932.795410,2903.074463 
	C2933.126221,2893.586914 2938.263184,2897.557861 2942.904297,2899.144043 
	C2958.961670,2904.633057 2975.425537,2903.525635 2991.906250,2901.889160 
	C3003.088379,2900.778564 3014.372314,2898.682373 3025.427979,2905.501465 
z"
        />
        <path
          fill="#E6E1D0"
          opacity="1.000000"
          stroke="none"
          d="
M1580.799927,4034.000000 
	C1580.769409,4025.422607 1572.712036,4029.532959 1569.450928,4026.853516 
	C1569.634155,4026.097168 1569.671631,4025.039795 1569.936401,4024.979492 
	C1578.128662,4023.113037 1578.391479,4023.377441 1586.798950,4014.635498 
	C1592.598755,4008.604736 1600.541016,4007.411133 1607.463135,4003.962402 
	C1614.914185,4000.249512 1615.508057,3999.910889 1614.644409,3991.175293 
	C1614.364868,3988.347900 1614.928467,3987.018066 1617.692383,3986.005859 
	C1623.890991,3983.734131 1629.859497,3980.978271 1636.802490,3981.902100 
	C1639.282715,3982.231934 1642.106567,3982.701416 1645.025146,3980.402344 
	C1643.909912,3977.401123 1639.683228,3976.985107 1638.811401,3973.594482 
	C1637.925903,3970.150146 1638.976807,3967.730957 1642.271362,3968.608643 
	C1651.473145,3971.059814 1656.699829,3964.773682 1662.976440,3960.792725 
	C1662.034546,3956.742188 1656.161011,3959.055908 1656.636963,3954.928467 
	C1657.115356,3950.778320 1661.378906,3951.780273 1663.916626,3951.202393 
	C1670.130615,3949.788086 1675.689941,3948.602539 1679.595581,3942.304443 
	C1681.935425,3938.531494 1688.003296,3939.228516 1693.211182,3938.012207 
	C1696.307251,3937.885254 1698.922241,3938.152588 1699.780029,3935.256836 
	C1700.903809,3931.462158 1703.649170,3930.458984 1707.195801,3929.727295 
	C1713.686401,3928.389160 1713.749878,3928.131592 1714.800781,3934.880615 
	C1715.117798,3936.916992 1716.169678,3937.663086 1717.837280,3938.191650 
	C1722.881104,3939.790527 1727.689087,3942.177246 1733.211060,3942.069580 
	C1735.646729,3942.021729 1739.472534,3941.320801 1739.151489,3945.259521 
	C1738.854980,3948.896240 1735.104370,3947.765869 1732.627808,3947.964600 
	C1730.310791,3948.151123 1727.949463,3947.882324 1725.649292,3948.159668 
	C1722.675781,3948.518066 1718.820435,3948.933105 1717.510742,3951.264404 
	C1715.084961,3955.581787 1711.641113,3956.041748 1707.557495,3956.257324 
	C1705.754639,3956.352295 1704.523438,3957.555908 1704.347656,3959.466797 
	C1704.137451,3961.752686 1705.082642,3963.255859 1707.435669,3963.800293 
	C1709.803345,3964.347900 1712.885864,3963.321777 1714.400146,3967.027832 
	C1711.151123,3968.730957 1707.825928,3968.050049 1704.714111,3967.940430 
	C1698.387207,3967.717773 1694.216187,3971.564941 1690.039429,3975.306885 
	C1688.315918,3976.850830 1688.209106,3979.109131 1691.086426,3979.707520 
	C1695.410767,3980.606934 1699.309204,3982.211914 1702.872314,3985.127930 
	C1702.258057,3989.548340 1697.461792,3991.685791 1696.677002,3995.806152 
	C1698.664307,3998.826416 1701.148315,3995.715576 1703.164062,3996.790771 
	C1704.867432,3997.699463 1707.370605,3998.124756 1706.975342,4000.652344 
	C1706.610352,4002.985596 1704.564453,4003.863037 1702.406738,4003.064697 
	C1696.130127,4000.743164 1690.849365,4002.080322 1685.933105,4008.495117 
	C1683.795532,4001.393311 1682.093994,3995.548584 1680.271606,3989.741943 
	C1678.621582,3984.484131 1676.020874,3982.834717 1670.895508,3984.281494 
	C1666.646118,3985.481201 1662.800049,3987.859863 1661.113647,3992.105957 
	C1658.227661,3999.372803 1652.180054,4001.647217 1645.448853,4001.816650 
	C1639.845459,4001.958008 1635.647095,4002.812988 1634.373901,4008.992920 
	C1633.798584,4011.785400 1629.624878,4011.310791 1629.187500,4014.917480 
	C1633.350342,4016.701904 1637.486450,4016.290771 1641.618164,4015.108643 
	C1643.917725,4014.450928 1646.339478,4013.457031 1647.556274,4016.455322 
	C1648.381226,4018.488281 1650.260498,4020.730713 1648.544800,4022.958008 
	C1646.844238,4025.165771 1644.242432,4026.281494 1641.435913,4025.427246 
	C1636.140869,4023.815186 1630.420532,4023.822998 1625.338379,4020.298096 
	C1624.157837,4024.892090 1623.182251,4028.687988 1622.103271,4033.242188 
	C1616.599976,4034.000000 1611.199951,4034.000000 1604.899902,4034.000000 
	C1601.052612,4030.373291 1605.531738,4029.071289 1606.748535,4026.377686 
	C1598.247925,4022.602539 1593.943848,4028.739990 1588.479980,4033.521729 
	C1585.866577,4034.000000 1583.733276,4034.000000 1580.799927,4034.000000 
z"
        />
        <path
          fill="#F4EEDC"
          opacity="1.000000"
          stroke="none"
          d="
M2.493857,1728.494385 
	C2.000000,1700.624878 2.000000,1673.249756 2.000000,1644.937256 
	C6.527843,1639.991943 14.760986,1641.266968 16.773218,1633.544800 
	C17.067450,1632.415649 19.533306,1631.282959 19.867096,1632.444214 
	C22.111053,1640.251343 28.271397,1632.140259 31.435545,1635.511353 
	C31.672998,1634.361450 33.125973,1633.629639 33.439915,1634.111816 
	C41.314648,1646.208374 54.626991,1639.545654 65.609657,1643.432129 
	C73.998489,1646.074097 81.167488,1649.164062 83.066849,1658.879883 
	C80.356186,1663.055542 76.494041,1663.360962 73.247978,1662.086670 
	C66.088432,1659.275879 64.486763,1661.791870 65.881149,1668.434204 
	C66.214378,1670.021484 65.979698,1671.661133 65.414871,1673.964111 
	C62.406689,1678.140137 63.233536,1682.509521 62.024677,1687.229248 
	C59.833950,1710.553833 61.735367,1733.140991 60.536144,1756.558105 
	C60.145370,1757.980103 59.913887,1758.524536 59.143505,1759.764282 
	C58.103703,1760.750244 57.602795,1761.040771 55.425644,1761.455811 
	C53.236931,1761.383057 52.724464,1761.185791 51.614433,1760.439819 
	C49.549969,1757.574341 49.479168,1755.012451 49.789902,1751.692749 
	C50.429092,1748.495483 51.693047,1745.896362 48.266022,1743.535767 
	C46.375694,1742.197266 44.728638,1741.492798 43.740131,1740.243042 
	C38.930809,1734.162109 32.887318,1735.775757 27.480579,1738.260132 
	C18.701311,1742.293701 8.687284,1738.861328 2.493857,1728.494385 
z"
        />
        <path
          fill="#091A29"
          opacity="1.000000"
          stroke="none"
          d="
M2112.512207,4033.380859 
	C2098.624756,4034.000000 2085.249268,4034.000000 2070.937012,4034.000000 
	C2062.898682,4021.542725 2060.836426,4007.111328 2054.430176,3994.346680 
	C2052.262939,3990.027832 2049.765381,3986.487793 2045.731934,3983.770996 
	C2037.482056,3978.213867 2028.868896,3973.134033 2022.113647,3965.560547 
	C2019.136353,3962.222900 2015.230835,3960.572754 2010.438232,3961.228760 
	C2001.702515,3962.424072 1996.273315,3958.739990 1994.938477,3949.794922 
	C1994.442139,3946.470215 1992.198608,3944.626709 1989.187622,3942.313477 
	C1985.957764,3935.755371 1987.451050,3930.020996 1989.985229,3923.634277 
	C1992.565430,3920.253174 1995.132202,3921.151367 1997.572998,3922.345947 
	C2010.320801,3928.585693 2022.811401,3935.282227 2034.378662,3943.571533 
	C2037.086670,3945.512207 2039.736450,3947.748779 2043.026611,3948.416260 
	C2063.162598,3952.501709 2078.276367,3966.353516 2095.783936,3975.525635 
	C2099.638428,3977.544922 2103.509277,3979.259766 2107.548096,3980.767090 
	C2113.468018,3982.976074 2119.554688,3985.022461 2123.024414,3991.943604 
	C2121.754395,4006.532227 2117.328125,4019.631348 2112.512207,4033.380859 
z"
        />
        <path
          fill="#4C6880"
          opacity="1.000000"
          stroke="none"
          d="
M2662.016602,2510.997070 
	C2662.513184,2509.728760 2663.061279,2508.837158 2663.463379,2508.898926 
	C2674.127441,2510.533691 2684.882080,2508.610107 2695.668701,2510.592529 
	C2710.281006,2513.277588 2724.903564,2516.914307 2739.991699,2516.013672 
	C2746.042969,2515.652588 2751.638184,2518.335693 2757.752197,2518.185791 
	C2763.797363,2518.037598 2769.385010,2515.716553 2775.504395,2515.884277 
	C2790.486328,2516.294922 2805.486328,2516.135498 2820.478027,2516.048584 
	C2828.477783,2516.002197 2836.178711,2518.468994 2844.267578,2518.189941 
	C2851.295654,2517.947510 2857.975342,2516.792969 2866.324463,2514.567871 
	C2853.759033,2507.937988 2840.322998,2512.644531 2829.337402,2507.212158 
	C2829.525879,2504.201172 2831.295654,2504.054199 2832.921387,2504.114502 
	C2848.504150,2504.694824 2864.061523,2503.036133 2879.714844,2505.591797 
	C2892.141357,2507.620605 2904.854980,2505.510986 2917.420654,2503.854736 
	C2928.435547,2502.403076 2939.415771,2501.181885 2950.748291,2503.402100 
	C2959.108887,2505.040039 2968.016357,2504.265625 2976.660400,2503.979980 
	C2986.365479,2503.659180 2995.979492,2504.459961 3005.368896,2506.471680 
	C3011.676514,2507.822998 3017.883301,2507.919189 3025.095215,2507.977539 
	C3026.000000,2512.083252 3026.000000,2516.166748 3026.000000,2521.125000 
	C3020.913818,2526.663330 3014.454590,2527.623047 3008.212891,2526.949463 
	C2970.562988,2522.885254 2932.514160,2522.214355 2895.352051,2513.998291 
	C2891.537109,2513.155029 2887.767578,2513.003906 2885.227051,2513.005371 
	C2893.865723,2510.662598 2901.744141,2517.487061 2910.815430,2519.258301 
	C2925.914551,2522.207031 2941.228271,2522.179932 2956.315674,2524.501709 
	C2961.431396,2525.288574 2968.761963,2524.879150 2968.407471,2531.901855 
	C2968.036865,2539.241943 2960.644531,2539.159912 2955.090820,2538.794189 
	C2927.681885,2536.988037 2900.169922,2536.255127 2872.998291,2531.728027 
	C2867.029297,2530.733154 2861.114014,2530.615967 2855.176758,2531.702881 
	C2838.868896,2534.688232 2822.862305,2532.386719 2806.071777,2528.780518 
	C2799.234375,2526.595947 2793.089111,2525.209473 2786.963379,2523.733154 
	C2783.989258,2523.016357 2781.145264,2523.087402 2778.131104,2523.596191 
	C2749.914551,2528.360352 2721.699219,2525.777832 2692.728027,2522.994141 
	C2686.965332,2517.987061 2680.437256,2518.053955 2674.333496,2517.051758 
	C2669.719727,2516.294434 2665.035889,2516.121826 2662.016602,2510.997070 
z"
        />
        <path
          fill="#10212F"
          opacity="1.000000"
          stroke="none"
          d="
M240.472076,3441.365479 
	C238.826767,3443.745117 237.005142,3444.992432 235.125931,3445.208496 
	C222.970230,3446.608154 213.884674,3455.241699 202.629776,3458.778320 
	C197.841766,3460.282471 194.249115,3465.523438 188.111481,3462.826416 
	C186.530167,3462.131592 185.147278,3464.023438 184.332443,3465.482666 
	C182.838821,3468.157715 180.702454,3470.086426 178.281769,3471.946289 
	C174.501663,3474.850586 172.101959,3481.239746 165.699539,3476.169189 
	C157.133530,3480.764893 146.317429,3477.944336 138.275253,3485.254883 
	C134.325638,3488.845703 128.631149,3490.611084 122.462372,3489.340576 
	C117.207458,3488.258301 112.078850,3489.483887 107.757294,3493.213379 
	C103.126984,3497.209717 99.130554,3502.751465 91.656509,3500.710938 
	C86.777443,3507.685547 77.006393,3502.396973 71.714325,3508.706055 
	C70.181854,3510.532959 67.352196,3510.000977 65.043739,3509.998291 
	C60.899323,3509.993652 58.363850,3512.182373 56.386074,3515.623291 
	C52.101273,3523.077393 52.025074,3523.033691 43.388615,3519.123047 
	C43.054264,3519.996582 42.407337,3520.874023 42.457306,3521.709473 
	C42.605171,3524.182129 44.787003,3527.077148 41.400284,3528.779541 
	C38.185169,3530.395996 36.650692,3527.508545 34.965904,3525.588623 
	C33.669731,3524.111328 32.406242,3521.623047 30.301435,3523.129883 
	C22.218302,3528.916748 12.321884,3526.877197 2.805063,3529.884277 
	C2.000000,3523.277344 2.000000,3516.554932 2.000000,3508.916016 
	C14.388131,3503.941650 26.955603,3500.553955 39.670071,3497.669922 
	C46.518696,3496.116211 52.913113,3493.155518 58.751587,3489.393799 
	C62.604061,3486.912354 66.383400,3485.535889 70.836700,3486.014404 
	C75.627968,3486.528809 79.412773,3484.637207 81.955070,3480.827148 
	C84.774521,3476.602051 87.822586,3475.639893 92.871605,3477.085449 
	C99.278641,3478.919678 105.010078,3479.203125 109.589752,3471.354736 
	C112.313126,3466.687744 119.002411,3463.069336 126.038383,3463.986328 
	C130.290359,3464.540771 133.862411,3463.529541 135.201416,3458.875977 
	C139.216827,3460.193848 136.958344,3467.286377 143.338898,3465.324219 
	C144.572556,3463.769043 145.750000,3462.584473 144.536484,3460.505615 
	C142.093292,3454.067627 142.885986,3452.350098 147.641830,3452.966797 
	C152.176605,3453.554688 156.367798,3455.230713 160.886795,3457.904297 
	C163.551590,3458.747803 164.105316,3460.564941 164.891647,3462.315186 
	C165.418427,3459.647705 164.763382,3457.351318 162.810760,3454.750488 
	C160.898758,3453.041992 160.187698,3451.679199 162.009125,3450.256348 
	C164.484039,3448.322754 167.412109,3447.843018 171.242126,3448.008057 
	C177.435974,3452.566162 181.897491,3449.284424 185.976807,3445.916748 
	C189.565964,3442.952881 192.500519,3438.881836 197.772079,3442.895996 
	C198.574112,3443.506592 199.850540,3443.672607 200.576370,3443.033203 
	C209.286560,3435.361328 220.386719,3440.178467 230.607773,3436.933350 
	C232.959503,3435.574707 234.793869,3434.865479 237.552780,3434.641602 
	C238.803940,3434.847412 239.288330,3435.021729 240.326157,3435.738281 
	C241.604095,3437.760498 241.643677,3439.250244 240.472076,3441.365479 
z"
        />
        <path
          fill="#69849B"
          opacity="1.000000"
          stroke="none"
          d="
M3025.348633,2449.469238 
	C3026.000000,2455.400146 3026.000000,2460.800049 3026.000000,2467.100098 
	C3023.504883,2470.125977 3020.393555,2470.782227 3017.540283,2469.817627 
	C2998.184326,2463.272217 2977.712402,2467.647705 2958.177246,2463.625244 
	C2941.537598,2460.198486 2924.924072,2457.291992 2907.857666,2457.858887 
	C2906.265625,2457.911621 2904.495605,2457.731689 2903.876465,2456.656250 
	C2906.371338,2458.803955 2910.019531,2458.099609 2913.345703,2457.927734 
	C2923.065186,2457.425293 2932.562744,2459.240479 2942.136475,2460.256348 
	C2944.977539,2460.557617 2947.920166,2462.002930 2947.926514,2464.802002 
	C2947.933350,2467.891357 2944.532227,2468.671631 2941.905029,2468.853027 
	C2932.542969,2469.498779 2923.357910,2467.494873 2914.114502,2466.524414 
	C2902.178223,2465.270752 2890.250244,2465.272949 2877.461182,2465.992676 
	C2865.187256,2468.358154 2853.689697,2464.236572 2841.405762,2466.540039 
	C2831.385986,2465.677734 2823.168457,2471.238037 2813.551025,2469.674805 
	C2805.490234,2468.364258 2797.068604,2468.778809 2788.924072,2467.161621 
	C2782.330078,2465.851807 2776.260986,2465.936768 2769.727783,2469.043213 
	C2761.916748,2472.757080 2753.505371,2469.368408 2744.681885,2467.604004 
	C2741.173096,2462.500244 2737.252686,2460.899658 2731.618652,2461.087646 
	C2723.687256,2461.352783 2715.697510,2461.688232 2707.753906,2461.000488 
	C2704.493408,2460.718018 2700.463135,2460.701416 2699.558594,2456.769531 
	C2698.578125,2452.506836 2702.686035,2451.392334 2705.542480,2450.030518 
	C2716.734863,2444.695557 2728.709229,2443.689209 2740.532959,2446.244873 
	C2749.541992,2448.191650 2757.880859,2452.588379 2766.689697,2445.347168 
	C2769.558350,2442.989258 2774.307861,2443.452637 2778.879150,2442.098145 
	C2782.207764,2441.204346 2784.801514,2441.198730 2788.127930,2441.886230 
	C2795.332275,2444.686279 2801.366943,2451.501709 2809.615234,2443.907227 
	C2822.898682,2441.275391 2835.553955,2443.095459 2849.117432,2442.926758 
	C2844.877197,2442.770752 2840.365723,2442.592285 2840.469971,2438.947998 
	C2840.577393,2435.201416 2845.249756,2435.122803 2848.563232,2435.055176 
	C2856.545654,2434.893066 2864.289551,2435.153076 2872.103760,2438.095947 
	C2882.751953,2442.106445 2894.250732,2442.315430 2905.580322,2442.505127 
	C2939.518799,2443.072266 2973.422607,2444.910400 3007.370605,2445.191650 
	C3013.314941,2445.240723 3019.185791,2446.468018 3025.348633,2449.469238 
z"
        />
        <path
          fill="#020D19"
          opacity="1.000000"
          stroke="none"
          d="
M1989.163574,3942.617432 
	C1995.567383,3941.334961 1998.080322,3943.385742 1998.180054,3949.686523 
	C1998.307007,3957.716797 2002.278809,3960.021973 2010.419067,3957.549072 
	C2014.411133,3956.336426 2017.460083,3958.000244 2020.506470,3959.800293 
	C2022.171509,3960.784180 2023.446167,3962.447266 2024.851074,3963.849854 
	C2032.432739,3971.419922 2041.372925,3977.215088 2050.260742,3983.034668 
	C2053.140625,3984.919922 2055.849854,3986.437988 2056.071289,3990.352783 
	C2056.385986,3995.911865 2061.114258,3999.491455 2062.614258,4004.660400 
	C2064.285889,4010.419922 2063.564209,4016.926270 2069.106934,4021.678711 
	C2071.551758,4023.774902 2069.796387,4028.656982 2070.000000,4033.135742 
	C2045.291504,4034.000000 2020.583130,4034.000000 1994.937256,4034.000000 
	C1993.379761,4033.222900 1992.360229,4032.499268 1992.297119,4031.588379 
	C1990.260254,4002.186035 1979.233887,3973.161621 1989.163574,3942.617432 
z"
        />
        <path
          fill="#4A667C"
          opacity="1.000000"
          stroke="none"
          d="
M2790.692383,2444.525635 
	C2787.218262,2443.996582 2784.445557,2443.973633 2780.841309,2443.957520 
	C2760.703857,2447.482910 2741.679688,2440.074219 2722.394043,2442.458984 
	C2720.750732,2442.662109 2718.355225,2442.476318 2717.724121,2441.231934 
	C2713.100098,2432.108887 2705.539307,2436.160400 2698.687500,2436.570557 
	C2684.938721,2437.394287 2671.618896,2433.689941 2658.064697,2432.406738 
	C2655.762451,2432.188965 2653.431641,2430.815430 2653.435303,2427.981934 
	C2653.439209,2425.136719 2655.847168,2424.213623 2658.095703,2423.525635 
	C2661.316650,2422.540771 2664.684326,2422.475342 2668.003662,2422.623779 
	C2687.623779,2423.501221 2707.222656,2423.384033 2726.799805,2421.676270 
	C2729.142090,2421.471680 2731.633301,2421.441650 2733.650879,2422.620361 
	C2752.070801,2433.383057 2771.742432,2427.899658 2791.021973,2427.060791 
	C2795.575928,2426.862793 2800.071289,2424.421875 2805.311279,2427.508789 
	C2818.800537,2427.464600 2831.533447,2428.512695 2845.122559,2430.000488 
	C2856.174072,2428.520264 2866.524414,2428.822754 2877.324707,2427.527832 
	C2883.273926,2429.830078 2888.719238,2430.363770 2895.125488,2429.971680 
	C2899.542480,2426.578857 2904.140869,2426.735596 2909.289551,2426.167969 
	C2920.331543,2426.285400 2930.624512,2426.421143 2940.540527,2422.863525 
	C2943.799561,2421.694580 2947.019287,2422.042725 2950.301270,2423.097168 
	C2957.962646,2425.557861 2965.851807,2426.614990 2973.876465,2425.854248 
	C2981.939209,2425.089844 2989.532471,2428.378418 2997.589600,2428.120117 
	C3006.542725,2427.832764 3015.483398,2427.919434 3025.144043,2425.987793 
	C3026.000000,2430.083252 3026.000000,2434.166748 3026.000000,2439.125000 
	C3021.385742,2444.139893 3016.112061,2440.852051 3011.694092,2440.285889 
	C2985.253418,2436.896484 2958.633789,2439.284424 2932.194336,2437.041016 
	C2930.025879,2436.856934 2927.979004,2436.071045 2926.032715,2436.018066 
	C2941.595459,2439.440918 2957.613525,2437.377930 2973.518555,2438.035645 
	C2990.670898,2438.745117 3007.859131,2439.240723 3025.359375,2443.510742 
	C3026.000000,2445.500000 3026.000000,2447.000000 3026.000000,2449.250000 
	C3005.979980,2446.286865 2985.622803,2449.319336 2965.515137,2447.018311 
	C2961.674072,2446.578857 2957.537842,2448.430664 2953.751709,2447.844971 
	C2931.395264,2444.386230 2908.732178,2447.572266 2886.339355,2444.356689 
	C2879.131592,2443.322021 2872.377686,2442.662842 2865.513184,2439.329346 
	C2858.507324,2435.926758 2850.168945,2438.494629 2842.413818,2437.958008 
	C2842.398926,2438.661377 2842.384277,2439.364746 2842.369385,2440.068115 
	C2846.930908,2440.068115 2851.494385,2439.994629 2856.053467,2440.101318 
	C2858.081787,2440.148926 2860.531250,2440.723633 2860.388428,2443.121338 
	C2860.250000,2445.444824 2857.737305,2445.840332 2855.718262,2445.877441 
	C2850.071533,2445.980713 2844.422607,2445.962891 2838.774414,2445.968262 
	C2829.138184,2445.977295 2819.502197,2445.967529 2808.928223,2445.973145 
	C2802.225342,2449.737061 2796.919189,2445.464600 2790.692383,2444.525635 
z"
        />
        <path
          fill="#2B3B46"
          opacity="1.000000"
          stroke="none"
          d="
M1014.421021,4033.571777 
	C997.291565,4034.000000 980.583069,4034.000000 962.937317,4034.000000 
	C958.307800,4024.304932 966.679260,4027.048584 971.516724,4025.308105 
	C976.800659,4025.585938 977.522827,4020.926758 980.909180,4018.628418 
	C984.546631,4017.093262 987.733215,4016.620605 991.655640,4016.448486 
	C992.781189,4016.627197 993.220520,4016.754639 994.249878,4017.213867 
	C998.451904,4021.432129 1000.943237,4017.208008 1004.448608,4015.997803 
	C1006.019226,4014.803955 1009.134460,4014.683105 1005.694214,4012.100586 
	C1003.574341,4011.118408 1002.260376,4010.055420 1000.756592,4008.192871 
	C999.467773,4006.116699 998.622864,4004.544922 997.151489,4002.554443 
	C996.669250,4001.512207 996.528992,4001.069824 996.329712,3999.930176 
	C996.341980,3998.770752 996.413208,3998.308838 996.771912,3997.206055 
	C999.994080,3993.262695 1002.876526,3994.579102 1005.893677,3996.530518 
	C1012.950562,4001.094971 1020.078552,3999.283936 1024.820435,3993.826172 
	C1028.168213,3989.972900 1035.756104,3989.977051 1035.039062,3982.066895 
	C1034.675415,3980.427246 1034.756226,3979.474854 1035.408203,3977.911133 
	C1043.204346,3975.660645 1049.529175,3972.714111 1054.884155,3966.746582 
	C1062.014160,3958.801270 1071.328613,3963.865234 1079.073608,3965.763916 
	C1085.075195,3967.235352 1084.194092,3974.813721 1085.015625,3980.335938 
	C1087.080688,3994.217041 1089.444824,4008.059814 1099.010132,4020.093750 
	C1102.845093,4024.010254 1104.268188,4027.953125 1102.475708,4033.321289 
	C1074.624878,4034.000000 1047.249756,4034.000000 1018.937317,4034.000000 
	C1017.153076,4032.955811 1016.101929,4032.664795 1014.421021,4033.571777 
z"
        />
        <path
          fill="#294159"
          opacity="1.000000"
          stroke="none"
          d="
M2913.258057,2825.581543 
	C2916.021484,2833.067383 2922.527100,2834.640625 2927.780518,2837.301025 
	C2931.719971,2839.295654 2933.739502,2834.424805 2937.273438,2832.411865 
	C2938.230225,2831.809814 2938.601318,2831.546143 2939.497559,2831.717773 
	C2944.883789,2835.173584 2949.757080,2834.337646 2955.610107,2832.752930 
	C2950.903564,2826.876465 2943.967529,2829.317383 2938.150391,2826.142090 
	C2943.096191,2822.941650 2946.686035,2826.297607 2950.385010,2826.106201 
	C2968.355713,2825.176514 2986.021729,2828.883789 3003.850098,2830.263672 
	C3010.741943,2830.797119 3017.046143,2835.031250 3025.144043,2834.046387 
	C3026.000000,2839.400146 3026.000000,2844.800049 3026.000000,2851.100098 
	C3018.351074,2857.509033 3009.977295,2855.967041 3002.141113,2853.380615 
	C2989.706787,2849.277100 2976.516113,2849.700684 2964.059814,2846.065918 
	C2962.494141,2845.609131 2960.377930,2845.306641 2959.158691,2846.115723 
	C2949.094727,2852.796631 2937.142090,2849.019775 2926.388184,2852.271484 
	C2917.026611,2855.102295 2907.292236,2852.853760 2897.728760,2851.583740 
	C2890.497070,2850.623291 2883.356689,2848.900879 2875.161621,2848.735840 
	C2867.451172,2845.444580 2859.697754,2848.317627 2852.080078,2845.090820 
	C2847.325684,2839.694824 2841.099854,2840.807617 2835.075195,2839.209961 
	C2828.901611,2839.466553 2823.518799,2839.442871 2817.372803,2838.850586 
	C2804.021729,2834.169434 2790.974365,2832.838623 2777.690674,2833.103027 
	C2776.039551,2833.135742 2774.369385,2833.012451 2772.770020,2832.583496 
	C2770.207520,2831.896240 2767.232422,2831.274170 2767.402832,2827.840332 
	C2767.568604,2824.505127 2770.526367,2823.944092 2773.128662,2823.410156 
	C2776.684814,2822.680664 2780.274414,2824.909912 2784.755127,2823.152832 
	C2779.421387,2824.865723 2775.128174,2822.736084 2770.713135,2822.882568 
	C2765.673340,2823.050049 2759.905029,2822.575684 2759.232422,2816.167480 
	C2758.645996,2810.584229 2763.310303,2808.433838 2768.904785,2807.251465 
	C2771.774902,2807.077393 2773.875000,2806.989746 2776.753418,2807.422363 
	C2783.228760,2808.871826 2788.852051,2809.394287 2794.973145,2805.887207 
	C2808.354980,2804.656006 2821.092773,2801.615723 2833.613281,2809.124023 
	C2833.424316,2815.842041 2829.115967,2815.811035 2824.550049,2816.067871 
	C2819.579346,2816.347412 2815.036377,2812.501709 2809.864502,2814.779297 
	C2808.037842,2817.872559 2811.836670,2819.209717 2812.050293,2821.749756 
	C2812.394043,2825.831299 2815.413818,2826.532959 2818.614990,2825.410889 
	C2823.565430,2823.675537 2828.242188,2824.573486 2833.265381,2825.217285 
	C2847.691406,2827.066650 2862.046875,2829.802246 2876.662109,2829.919678 
	C2878.972900,2829.938232 2881.366943,2829.961670 2883.575928,2829.402832 
	C2885.789551,2828.842529 2885.179932,2826.941406 2884.282715,2824.690918 
	C2893.713379,2818.829590 2903.190674,2822.251465 2913.258057,2825.581543 
z"
        />
        <path
          fill="#0B1926"
          opacity="1.000000"
          stroke="none"
          d="
M2.639535,3428.466309 
	C2.000000,3416.624023 2.000000,3405.247803 2.000000,3392.936035 
	C14.719234,3387.474609 26.851845,3381.047363 38.702454,3375.171875 
	C55.400803,3366.892822 72.443962,3365.204590 89.741661,3364.088867 
	C105.653030,3363.062256 120.140358,3358.575928 135.105835,3353.890869 
	C148.895126,3349.574219 163.730377,3347.830078 178.522400,3348.921143 
	C184.419510,3349.356201 189.498993,3351.479004 193.952179,3356.598145 
	C195.015427,3361.649170 191.762177,3363.169434 188.735611,3364.123047 
	C180.041214,3366.863281 171.803055,3371.152344 161.792282,3372.036133 
	C146.973541,3364.282715 132.838867,3367.545166 118.966759,3372.279785 
	C111.804062,3374.723877 104.797211,3377.595947 95.240944,3379.443848 
	C107.357590,3381.166260 116.652641,3378.522705 125.623512,3374.657227 
	C130.993820,3372.343506 136.187637,3370.891602 142.313965,3373.866699 
	C143.124023,3382.257324 136.491577,3383.178955 131.573120,3384.883301 
	C97.144371,3396.812744 63.417263,3410.687988 28.762949,3421.964844 
	C20.402842,3424.685303 12.792427,3430.375977 2.639535,3428.466309 
z"
        />
        <path
          fill="#091A29"
          opacity="1.000000"
          stroke="none"
          d="
M2922.566895,3540.577148 
	C2918.241943,3539.649170 2912.258301,3540.888672 2912.858643,3535.347656 
	C2913.489014,3529.531250 2918.863037,3532.529053 2922.482666,3533.185547 
	C2923.784180,3533.421631 2925.081055,3533.865234 2926.383057,3533.876953 
	C2928.284424,3533.894287 2930.781494,3534.215088 2930.849365,3531.382324 
	C2930.892822,3529.566162 2929.150146,3528.563477 2927.457275,3528.318115 
	C2921.569580,3527.463379 2915.648193,3526.047852 2909.762207,3526.149902 
	C2900.035156,3526.317627 2891.751953,3522.354004 2883.371826,3518.486084 
	C2880.047119,3516.950928 2879.963867,3513.803955 2883.288330,3512.785645 
	C2892.067871,3510.096191 2900.798096,3506.421875 2910.384277,3509.122559 
	C2915.488037,3510.560303 2920.718750,3511.547363 2925.822510,3512.985352 
	C2930.436035,3514.284668 2935.033691,3514.545410 2939.566650,3513.111572 
	C2946.818848,3510.817383 2953.229980,3512.656738 2959.498535,3516.347900 
	C2963.500977,3518.705078 2967.640869,3520.866455 2971.864502,3522.797363 
	C2975.536621,3524.475830 2978.975342,3524.968750 2980.155029,3519.511475 
	C2981.096191,3515.157471 2984.266846,3517.044678 2986.459717,3517.962646 
	C2998.942139,3523.190186 3012.925781,3524.286621 3025.277100,3531.914551 
	C3026.000000,3543.375977 3026.000000,3554.752197 3026.000000,3567.063965 
	C3021.417236,3572.127930 3016.740234,3569.850342 3012.035645,3568.255127 
	C3001.813965,3564.790039 2991.969971,3559.822266 2980.464844,3564.050781 
	C2976.201660,3565.617676 2971.214111,3563.478516 2967.007324,3561.584961 
	C2952.292725,3554.959961 2937.670410,3548.125977 2922.566895,3540.577148 
z"
        />
        <path
          fill="#344F68"
          opacity="1.000000"
          stroke="none"
          d="
M3026.000000,2905.063965 
	C3016.185303,2904.260742 3006.149414,2902.636719 2996.386963,2904.352295 
	C2986.443604,2906.099854 2976.572021,2906.177002 2966.678223,2905.885010 
	C2955.953369,2905.568848 2944.924805,2905.783691 2935.282227,2898.476807 
	C2932.864990,2904.774658 2939.828125,2904.323242 2941.116211,2908.473633 
	C2933.027344,2911.345703 2925.178223,2902.732178 2917.112305,2907.407227 
	C2919.164307,2913.729248 2926.576172,2910.236572 2929.630859,2915.348389 
	C2924.827881,2919.916748 2919.514893,2917.121826 2913.501953,2916.051025 
	C2907.798828,2910.157715 2900.129150,2909.555908 2893.625488,2905.232910 
	C2887.885010,2895.037109 2878.777344,2887.744385 2875.755859,2875.863770 
	C2875.237061,2870.155518 2876.933350,2866.382324 2882.138916,2865.522705 
	C2888.758057,2864.429932 2896.102783,2863.343994 2900.415771,2869.777100 
	C2903.675781,2874.639893 2907.282959,2876.578613 2913.527832,2876.060791 
	C2936.329834,2875.119141 2957.877441,2876.942627 2978.933594,2884.279541 
	C2987.812988,2887.373291 2997.747314,2885.402100 3006.286133,2886.885742 
	C2976.094971,2886.696533 2946.915283,2871.690918 2914.529297,2875.933105 
	C2907.736084,2875.086670 2901.538574,2874.540039 2902.595703,2865.210205 
	C2906.953857,2859.978760 2911.662598,2862.605713 2915.907227,2863.572998 
	C2924.054688,2865.429443 2931.895996,2866.234375 2940.283203,2864.110840 
	C2951.492188,2861.273193 2962.437744,2864.698730 2973.388428,2867.312988 
	C2985.213623,2870.135986 2997.197754,2872.573975 3010.154297,2869.298828 
	C3015.501221,2867.321289 3020.136719,2867.368652 3025.377441,2869.523193 
	C3026.000000,2881.375977 3026.000000,2892.752197 3026.000000,2905.063965 
z"
        />
        <path
          fill="#122230"
          opacity="1.000000"
          stroke="none"
          d="
M195.025574,3358.649414 
	C181.728638,3350.132324 168.274796,3351.922852 154.201859,3353.534424 
	C139.655441,3355.200195 126.578056,3360.487793 113.154373,3365.280029 
	C109.433281,3366.608398 105.835831,3367.144531 101.584572,3366.512207 
	C92.011444,3365.089600 82.546371,3368.301514 72.959183,3368.912109 
	C69.396149,3369.139404 65.773277,3370.128906 62.116993,3369.986328 
	C55.771488,3369.739258 50.084156,3371.517578 46.034466,3376.384766 
	C43.253006,3379.728027 39.983509,3380.145508 36.253349,3379.988770 
	C32.139721,3379.815918 28.430365,3380.298340 25.327028,3383.598877 
	C19.497858,3389.798340 11.929895,3391.923584 2.822480,3392.000000 
	C2.000000,3384.614258 2.000000,3377.228271 2.000000,3368.921387 
	C4.361709,3368.038818 6.547913,3367.477051 8.783311,3366.634521 
	C17.654533,3363.288818 26.147696,3359.512695 36.420326,3361.717285 
	C46.186382,3363.812988 56.080402,3359.713867 66.121574,3359.682373 
	C80.965797,3359.635986 95.449631,3355.472656 110.058563,3353.653564 
	C121.180710,3352.268311 131.511093,3346.895020 142.925522,3347.920166 
	C145.538284,3348.154785 147.266373,3346.907959 148.150116,3344.283203 
	C149.217346,3341.113525 150.785675,3338.198975 153.222000,3335.775391 
	C155.712463,3333.298340 158.099045,3331.856445 162.036499,3332.859619 
	C169.861847,3334.853271 177.724976,3334.270752 185.707016,3332.625244 
	C192.524643,3331.219727 199.650009,3331.457764 206.577667,3332.622314 
	C208.965088,3333.023682 211.220245,3333.828857 213.048691,3331.792969 
	C217.425034,3326.920166 223.393845,3326.309570 229.303802,3325.208984 
	C241.743820,3322.891846 254.226532,3321.442871 266.908081,3321.989990 
	C270.417999,3322.141357 273.775818,3322.154297 274.014526,3327.766113 
	C273.576660,3333.064453 269.916565,3334.453613 266.624542,3335.672607 
	C245.270508,3343.580078 223.882980,3351.399414 202.399796,3358.952393 
	C200.185257,3359.730713 197.913269,3360.063721 195.025574,3358.649414 
z"
        />
        <path
          fill="#3A4B59"
          opacity="1.000000"
          stroke="none"
          d="
M1099.230469,4021.011719 
	C1093.453369,4021.942383 1090.536499,4019.124023 1090.182617,4014.133545 
	C1089.916870,4010.386475 1088.745483,4006.958496 1087.526123,4003.574463 
	C1084.384155,3994.856201 1080.753174,3986.348145 1081.862183,3976.683594 
	C1082.241089,3973.381592 1080.562744,3970.279541 1078.573364,3966.674072 
	C1074.935059,3969.651855 1071.575562,3970.284912 1067.810913,3966.310791 
	C1065.294922,3963.654053 1053.809937,3967.393799 1052.973022,3971.825195 
	C1051.369751,3980.314209 1046.551758,3979.368652 1040.752319,3978.043945 
	C1039.804932,3977.827148 1038.767944,3978.001953 1036.977417,3978.011719 
	C1034.476685,3978.803955 1031.406738,3979.087402 1035.417847,3981.857422 
	C1037.577393,3982.196045 1039.456299,3981.689941 1040.595215,3983.148193 
	C1041.937622,3984.867676 1040.705078,3986.920654 1039.443604,3987.241943 
	C1031.477661,3989.269775 1028.320801,3998.008789 1020.566284,4000.327148 
	C1013.855164,4002.333008 1007.370239,4004.761719 1002.489441,3996.965820 
	C1001.790100,3995.848145 1000.078186,3996.391357 998.407471,3997.625488 
	C997.594971,3998.363037 997.261597,3998.591064 997.361328,3999.242188 
	C997.847595,4000.081787 997.900696,4000.497803 997.992676,4001.541992 
	C998.851135,4003.398682 999.408508,4004.873047 1001.500183,4005.665527 
	C1003.110779,4007.219971 1004.241638,4008.467529 1005.946289,4010.000244 
	C1007.372314,4012.244385 1007.487183,4014.275391 1006.923462,4016.942871 
	C1003.825317,4018.915283 1001.442261,4021.088867 998.794922,4022.819824 
	C995.555237,4024.938232 994.276306,4022.539307 994.046265,4018.736328 
	C995.228027,4016.866455 996.171570,4015.496338 997.208374,4014.204346 
	C996.366211,4015.074707 995.751648,4016.129150 994.455017,4017.496582 
	C990.276123,4018.449951 986.860229,4020.616211 982.134033,4019.741455 
	C977.310486,4019.949219 977.731384,4024.014893 975.462769,4026.641113 
	C971.789001,4027.897949 968.860779,4029.999268 965.048828,4028.887451 
	C962.584045,4028.168701 962.188599,4030.622070 962.011169,4033.290527 
	C951.955811,4034.000000 941.911621,4034.000000 930.933716,4034.000000 
	C938.356934,4026.359619 947.998352,4020.428467 957.278992,4014.089111 
	C991.586792,3990.653320 1026.192383,3967.654297 1060.597534,3944.360107 
	C1066.606445,3940.291748 1073.792480,3937.893799 1078.788330,3931.314941 
	C1081.540527,3924.895020 1088.060059,3925.246826 1092.927490,3921.736328 
	C1095.210205,3921.068848 1096.755615,3921.010254 1098.758911,3922.315186 
	C1101.169678,3935.569580 1094.938354,3947.168213 1094.558350,3960.312988 
	C1094.186768,3962.165527 1093.893555,3963.201904 1093.300781,3964.986572 
	C1090.110107,3974.613037 1092.096802,3983.091309 1094.459595,3991.762939 
	C1097.005005,4001.105225 1101.807495,4010.132324 1099.230469,4021.011719 
z"
        />
        <path
          fill="#7B98B0"
          opacity="1.000000"
          stroke="none"
          d="
M3009.789062,2042.860352 
	C3010.044434,2039.187622 3009.141846,2037.468140 3006.425781,2035.678589 
	C2996.205566,2028.944824 2985.276367,2029.771973 2974.212402,2031.758545 
	C2970.290527,2032.462646 2967.127686,2033.273071 2962.953613,2031.119873 
	C2958.383057,2028.762085 2952.402832,2028.625977 2947.629639,2031.728882 
	C2942.813965,2034.859253 2938.959961,2034.381592 2935.293213,2030.273926 
	C2928.939697,2023.156128 2921.655518,2022.685425 2913.831543,2027.453125 
	C2905.872314,2032.303467 2897.038330,2032.867188 2888.123779,2033.037720 
	C2883.606201,2033.123901 2880.115967,2035.465454 2880.784912,2038.745972 
	C2881.542725,2042.464111 2883.955566,2044.890381 2887.983887,2043.584473 
	C2893.392334,2041.831299 2897.810791,2043.565430 2901.753906,2046.880737 
	C2904.193604,2048.931885 2906.702637,2050.099609 2909.865479,2050.075439 
	C2912.386719,2050.056396 2914.414307,2051.153809 2915.578369,2053.426270 
	C2916.447998,2055.124023 2918.104492,2056.868896 2916.687988,2058.900635 
	C2915.500732,2060.604004 2913.484863,2061.741943 2911.502441,2061.207275 
	C2907.581299,2060.149902 2903.655273,2059.881348 2898.821777,2060.000000 
	C2895.677490,2058.153809 2897.641846,2055.107910 2895.526367,2052.768066 
	C2892.739502,2051.349121 2891.057617,2053.073242 2888.454102,2053.896484 
	C2877.764404,2054.403564 2868.069092,2052.344727 2857.631348,2050.031494 
	C2853.613037,2047.615845 2851.871582,2044.451904 2850.839111,2040.042114 
	C2851.014160,2038.800659 2851.183350,2038.327271 2851.876709,2037.327637 
	C2854.135010,2035.689697 2856.114990,2035.504883 2858.088135,2035.209595 
	C2867.791016,2033.757202 2870.381104,2030.609253 2867.849365,2021.510620 
	C2866.510254,2016.696777 2867.583496,2013.705322 2871.581787,2011.591309 
	C2876.088135,2009.208740 2880.628662,2008.871582 2883.904541,2013.673218 
	C2886.289795,2017.169556 2888.980957,2019.782471 2894.548340,2019.524048 
	C2888.786133,2008.416382 2901.834717,2006.361084 2904.638916,1998.282471 
	C2906.723877,1996.530884 2908.560059,1996.046753 2911.271729,1996.007324 
	C2916.083496,1995.982056 2920.166748,1995.973755 2925.125000,1995.973022 
	C2928.461914,1999.532227 2929.499756,2004.108521 2933.964355,2007.058228 
	C2935.545410,2013.583374 2929.612061,2013.068237 2926.640869,2015.740845 
	C2931.779541,2016.047729 2936.672119,2016.691284 2935.317627,2008.630859 
	C2935.281982,2003.152832 2928.875000,2001.890747 2929.574463,1996.485596 
	C2933.419189,1995.974609 2936.838623,1995.973267 2941.112305,1995.969482 
	C2943.329346,1994.804199 2944.976318,1994.351807 2947.340088,1994.052368 
	C2954.573242,1996.308594 2961.402100,1996.114136 2969.111328,1996.004028 
	C2970.846680,1997.042603 2971.894775,1997.337769 2973.576416,1996.438110 
	C2978.112549,1995.079102 2981.957520,1994.451538 2986.457275,1995.450806 
	C2992.121338,1996.708984 2998.243896,1995.903320 3005.084229,1995.998901 
	C3006.661865,1996.776001 3007.033203,1997.656982 3006.849854,1999.375244 
	C2997.151123,2007.587280 2985.558350,2005.759155 2977.084717,2007.403442 
	C2992.723389,2010.665039 3008.200195,2020.541992 3025.506592,2027.510742 
	C3026.000000,2030.777832 3026.000000,2033.555542 3026.000000,2037.166748 
	C3024.391846,2039.473755 3022.918945,2040.971313 3025.548828,2043.556641 
	C3026.000000,2046.777832 3026.000000,2049.555664 3026.000000,2053.166748 
	C3015.771729,2058.115479 3011.415039,2055.388184 3009.789062,2042.860352 
z"
        />
        <path
          fill="#495E72"
          opacity="1.000000"
          stroke="none"
          d="
M2.694861,2644.557373 
	C2.000000,2636.614258 2.000000,2629.228271 2.000000,2620.921387 
	C16.066851,2614.706299 29.383032,2605.558350 45.668316,2611.797363 
	C48.007473,2612.693604 50.652954,2613.911865 54.141823,2611.333008 
	C50.112656,2607.745117 45.216259,2609.873779 41.285378,2608.593018 
	C38.192352,2607.585205 33.921597,2607.504395 34.061470,2603.220459 
	C34.181801,2599.535156 38.187405,2599.310303 40.998497,2598.814209 
	C55.055668,2596.334717 69.174774,2594.543701 83.521957,2595.028076 
	C87.236870,2595.153564 91.795624,2596.504395 94.341591,2590.781738 
	C96.999741,2588.073242 99.625992,2587.576660 103.323326,2588.160156 
	C116.949844,2593.181885 129.768524,2591.746338 142.489395,2588.149902 
	C148.508957,2586.447754 154.488007,2584.850586 161.570740,2584.891602 
	C169.728607,2585.760498 177.093765,2587.281250 185.266968,2585.197266 
	C189.259186,2585.260254 192.298279,2586.063965 195.924728,2587.785156 
	C198.481079,2589.379150 200.079285,2590.788574 200.191513,2593.850098 
	C188.153168,2598.487061 175.750305,2598.419922 163.016724,2600.672363 
	C159.954224,2600.369141 157.685623,2600.549561 154.877045,2601.841797 
	C128.339539,2604.566650 102.681023,2609.120117 76.567039,2609.386719 
	C70.897156,2609.444336 77.395782,2611.792236 77.058067,2613.827148 
	C78.466263,2620.343506 74.734718,2621.993408 70.107674,2622.879883 
	C65.908401,2623.684326 61.533115,2622.772217 57.381752,2625.198730 
	C78.618874,2626.211670 99.903015,2623.191162 121.959930,2625.053223 
	C137.745193,2629.555664 153.499573,2626.864502 169.298080,2630.781494 
	C172.423737,2637.014648 168.205917,2638.143555 164.353134,2639.280518 
	C144.643631,2645.097656 124.517570,2648.118408 103.128525,2647.744629 
	C92.871910,2638.981445 81.685211,2644.721436 71.335846,2643.870117 
	C68.630409,2643.647949 65.566452,2644.808350 61.771854,2644.993652 
	C58.134083,2645.584961 55.413357,2646.557617 51.641479,2646.599609 
	C41.794506,2645.399902 32.893105,2645.804199 23.902870,2646.904785 
	C17.062597,2647.742432 9.947132,2648.808594 2.694861,2644.557373 
z"
        />
        <path
          fill="#8497A7"
          opacity="1.000000"
          stroke="none"
          d="
M2.000000,2162.937012 
	C4.999553,2162.005859 8.055848,2162.389648 10.988400,2161.949219 
	C21.182871,2160.418213 30.453711,2164.419434 40.109673,2166.259033 
	C48.342396,2167.828125 56.749321,2168.640625 60.569553,2158.267822 
	C61.236118,2156.458008 63.040081,2156.305176 64.664375,2155.912109 
	C68.536278,2154.975586 71.992981,2152.832031 71.452179,2148.605225 
	C70.741951,2143.054199 65.696976,2143.917969 61.864124,2144.077148 
	C52.953129,2144.447266 44.074429,2142.304932 35.145008,2143.917969 
	C33.272530,2144.256348 31.129805,2144.309082 29.439505,2142.710449 
	C29.107468,2139.433594 31.851101,2139.098877 33.906849,2138.521240 
	C35.784988,2137.993164 37.856892,2138.135986 39.845634,2138.022217 
	C51.291386,2137.367188 63.003841,2139.915527 74.066681,2134.311523 
	C76.274483,2133.193115 79.762421,2132.374512 83.425903,2133.830811 
	C84.042938,2135.579590 84.019630,2137.078125 83.997101,2139.327148 
	C86.563042,2140.862061 82.597351,2145.570557 87.537766,2145.602051 
	C87.417282,2149.403076 85.271675,2153.056641 89.927452,2155.497070 
	C91.192535,2156.160156 91.528427,2157.835693 90.654182,2158.998291 
	C88.298416,2162.130615 88.844719,2163.719482 92.836937,2164.008057 
	C95.209106,2164.179688 97.813255,2164.363770 97.422615,2167.760010 
	C97.105698,2170.514893 94.977776,2171.807861 92.511475,2171.825684 
	C87.481194,2171.862061 84.451752,2173.634766 83.606873,2179.046387 
	C82.912102,2183.496826 77.605995,2180.763184 75.011726,2183.945801 
	C80.004318,2187.626953 85.622749,2185.473389 90.753662,2185.982422 
	C95.948730,2186.497803 101.289124,2185.259766 106.277542,2186.827148 
	C106.583206,2188.341309 106.114304,2189.623291 105.205719,2189.542480 
	C95.339752,2188.666260 85.698212,2193.621338 75.703117,2190.459229 
	C72.053635,2189.304688 70.573174,2191.555664 69.918793,2195.224365 
	C68.608391,2202.571045 67.384026,2204.120850 60.513947,2203.794434 
	C41.536480,2202.892578 22.773634,2206.344971 2.925108,2206.000000 
	C2.000000,2191.958008 2.000000,2177.916260 2.000000,2162.937012 
z"
        />
        <path
          fill="#1B2B3A"
          opacity="1.000000"
          stroke="none"
          d="
M2.664448,3084.514648 
	C2.000000,3069.291504 2.000000,3054.583008 2.000000,3038.937256 
	C6.290275,3030.359375 13.730410,3028.856934 21.495611,3028.041748 
	C25.905346,3027.578857 31.027119,3027.208984 32.420654,3032.375732 
	C34.130699,3038.715820 27.607506,3038.064453 24.329102,3040.736328 
	C40.470497,3035.894531 56.519131,3029.857178 74.356476,3035.635742 
	C77.475578,3036.552979 79.333595,3038.460205 82.602112,3039.026855 
	C84.884621,3039.380127 86.301109,3039.930420 88.181351,3041.244141 
	C89.925369,3043.469482 89.987946,3045.484375 90.331818,3047.452148 
	C92.817596,3061.677734 92.836777,3061.674316 78.837418,3060.865967 
	C78.413635,3061.429688 78.663551,3062.153076 79.497856,3062.075684 
	C97.780884,3060.376221 115.456345,3067.201904 133.702881,3065.912109 
	C135.032211,3065.818359 136.383026,3065.836426 137.683456,3066.133545 
	C141.548508,3067.016357 146.215439,3067.332520 147.031326,3072.190918 
	C147.911621,3077.432861 143.480408,3078.648926 138.877014,3080.090332 
	C132.806747,3080.319092 127.509598,3080.816895 121.361488,3081.015137 
	C103.152267,3084.400391 85.796509,3083.120605 67.595863,3080.710938 
	C57.078545,3076.846680 48.015621,3077.156982 38.052094,3081.274170 
	C27.216141,3085.751953 15.351521,3087.986572 2.664448,3084.514648 
z"
        />
        <path
          fill="#051523"
          opacity="1.000000"
          stroke="none"
          d="
M2972.937256,4034.000000 
	C2971.904297,4030.500244 2969.653076,4028.768555 2966.790771,4027.386230 
	C2963.803711,4025.943848 2960.858887,4024.391113 2958.007812,4022.697998 
	C2955.774658,4021.371826 2954.430908,4018.583984 2951.477051,4018.439941 
	C2947.695312,4018.255127 2948.325439,4022.166992 2946.601562,4023.911377 
	C2944.129883,4026.412109 2941.293457,4026.091064 2938.284424,4025.108154 
	C2932.926270,4023.357910 2927.618408,4021.343750 2922.141357,4020.087402 
	C2906.428711,4016.483398 2890.641602,4013.204346 2874.884766,4009.792969 
	C2874.238037,4009.653076 2873.543945,4009.577881 2872.969482,4009.282715 
	C2868.612793,4007.044189 2861.500488,4005.975586 2861.550293,4001.011719 
	C2861.592773,3996.748535 2868.100586,3995.048828 2872.876465,3993.784668 
	C2876.358154,3992.863525 2879.840088,3991.394775 2882.880127,3989.471924 
	C2888.610107,3985.847168 2894.919434,3985.135742 2901.126465,3986.204590 
	C2910.540039,3987.824951 2920.024414,3989.767334 2929.001221,3992.928711 
	C2934.805420,3994.972900 2940.353516,3996.241699 2946.400146,3996.016602 
	C2956.545654,3995.638672 2965.599854,3999.461426 2974.779297,4003.169922 
	C2990.788574,4009.637451 3005.952881,4019.100098 3025.123047,4018.000000 
	C3026.000000,4023.265625 3026.000000,4028.531494 3026.000000,4034.000000 
	C3008.625244,4034.000000 2991.250000,4034.000000 2972.937256,4034.000000 
z"
        />
        <path
          fill="#21364A"
          opacity="1.000000"
          stroke="none"
          d="
M3025.417969,3153.433105 
	C3026.000000,3160.061279 3026.000000,3166.122314 3026.000000,3173.091797 
	C3021.857178,3177.681396 3017.269531,3175.953125 3012.777100,3175.045410 
	C2991.350342,3170.714844 2969.880127,3167.802734 2947.895264,3170.407227 
	C2940.701416,3171.259521 2933.203369,3169.834961 2926.316406,3167.703857 
	C2912.606445,3163.461670 2898.023438,3165.469727 2884.405273,3161.098389 
	C2882.806152,3160.585205 2881.126465,3160.984863 2879.284668,3162.882568 
	C2873.238525,3166.933350 2868.605957,3168.634033 2860.966064,3166.809326 
	C2852.501465,3164.787109 2844.602539,3160.564697 2835.514648,3160.039307 
	C2827.973877,3159.603516 2826.287109,3154.309326 2830.833740,3147.923828 
	C2833.128174,3147.369385 2834.685791,3147.374268 2836.989746,3147.606689 
	C2841.896973,3148.694336 2845.945557,3150.414795 2849.885010,3149.746338 
	C2840.402832,3142.371094 2829.353760,3137.508545 2817.264893,3134.748779 
	C2814.307373,3134.073975 2811.982910,3131.592285 2812.881348,3127.985352 
	C2813.848389,3124.102295 2817.217285,3124.079590 2820.125488,3124.655762 
	C2830.909668,3126.793213 2841.817871,3126.927734 2853.461426,3127.243408 
	C2855.162598,3127.468262 2856.104980,3127.726074 2857.667480,3128.423828 
	C2867.040283,3145.815918 2884.322021,3141.205566 2898.500244,3144.364014 
	C2890.706543,3142.364746 2882.650879,3142.176514 2874.768799,3140.884033 
	C2869.752930,3140.061768 2864.458008,3139.514160 2865.681152,3131.655762 
	C2877.997559,3128.191162 2889.944824,3128.663574 2901.204102,3132.823730 
	C2915.656982,3138.163574 2929.799561,3143.666260 2945.685303,3141.056641 
	C2949.618164,3140.410645 2953.882324,3141.107910 2957.359131,3143.045410 
	C2965.437012,3147.546875 2974.195557,3148.544434 2983.124268,3149.771240 
	C2992.043701,3150.996826 3000.637939,3152.582031 3009.745117,3150.334473 
	C3014.620850,3149.131348 3020.409668,3148.530518 3025.417969,3153.433105 
z"
        />
        <path
          fill="#1F3041"
          opacity="1.000000"
          stroke="none"
          d="
M2.598797,2916.490234 
	C2.000000,2906.621338 2.000000,2897.242920 2.000000,2886.932129 
	C3.739184,2883.789795 6.556127,2883.104492 9.202816,2880.958984 
	C10.664079,2880.154541 11.558021,2879.799316 13.201778,2879.337891 
	C15.970243,2878.865479 17.785288,2877.843994 20.542084,2877.108887 
	C32.910988,2873.919922 44.637264,2872.235107 57.496780,2873.106934 
	C69.995316,2876.750977 81.497833,2876.363037 93.423492,2873.777344 
	C100.572258,2872.227539 109.173340,2869.287354 114.820831,2878.710205 
	C113.459923,2881.692383 111.367699,2882.419189 108.347214,2882.969482 
	C101.057152,2883.657715 99.429962,2891.697266 91.717552,2892.547363 
	C115.522797,2893.558594 137.773880,2896.392334 160.014893,2899.258057 
	C166.993591,2900.157227 170.977310,2903.821289 168.639893,2912.183594 
	C166.875748,2913.849121 165.371674,2914.419189 162.926666,2914.789551 
	C156.051498,2915.280029 149.929825,2914.143311 143.183029,2916.007324 
	C137.514709,2917.392578 132.499039,2917.478760 127.547325,2917.175537 
	C105.981850,2915.855957 84.702797,2918.938477 63.355511,2921.035889 
	C60.454594,2921.321045 57.018669,2922.444336 55.517345,2918.834717 
	C54.158470,2915.567139 56.615238,2913.202881 58.683765,2911.042725 
	C65.310555,2904.122803 73.913223,2904.713379 81.020370,2905.072510 
	C71.676979,2901.908203 62.273808,2906.232178 52.504887,2909.748779 
	C38.434380,2914.813965 23.517975,2916.371338 8.807778,2918.628418 
	C6.843011,2918.930176 4.858165,2918.248535 2.598797,2916.490234 
z"
        />
        <path
          fill="#43596D"
          opacity="1.000000"
          stroke="none"
          d="
M104.776207,2590.538086 
	C101.644714,2590.115234 99.157463,2589.566895 96.464493,2591.543457 
	C92.977859,2595.189697 88.959023,2596.032471 85.044250,2595.842529 
	C61.946381,2594.722412 39.036949,2600.238281 15.905272,2597.665527 
	C11.667029,2597.194092 7.265037,2597.782715 2.733312,2594.593750 
	C2.000000,2584.621338 2.000000,2575.242920 2.000000,2564.932129 
	C5.500824,2560.515869 9.871614,2560.792725 14.138448,2561.530518 
	C25.057178,2563.419434 35.927643,2563.133545 46.732929,2560.998291 
	C57.386017,2558.893311 68.046494,2558.777832 78.790474,2560.624756 
	C75.662041,2559.833252 70.639191,2561.376953 70.478165,2557.242432 
	C70.289879,2552.407715 75.722687,2551.598877 79.497406,2551.798828 
	C89.754791,2552.342285 100.056923,2554.118652 109.639755,2546.624023 
	C113.560150,2546.001465 116.722633,2546.729736 119.958160,2547.538818 
	C124.139336,2548.584229 128.281342,2547.886475 132.586624,2546.818848 
	C142.319778,2544.405029 152.254761,2542.673340 163.064514,2545.730469 
	C168.287369,2544.501953 171.687775,2545.286377 172.829025,2551.125977 
	C170.779373,2554.261475 168.157013,2554.501709 164.721802,2554.472168 
	C160.225342,2553.111572 156.999893,2553.802002 153.851776,2557.335449 
	C144.057144,2560.154785 134.596573,2561.190918 125.233719,2562.773193 
	C122.150902,2563.294189 118.457428,2562.428711 116.439453,2566.840576 
	C115.623322,2569.769287 115.958527,2572.161133 115.139420,2575.140137 
	C107.334465,2583.831055 97.056854,2582.508301 88.574066,2580.770996 
	C73.025803,2577.586914 59.353477,2582.954834 49.888885,2585.290527 
	C59.405933,2583.450684 73.486755,2579.188721 88.509491,2582.180176 
	C96.348717,2583.740967 104.310020,2582.792969 111.747337,2578.850830 
	C116.955765,2576.090332 122.788200,2576.347168 128.305588,2577.923584 
	C133.116821,2579.298340 136.952438,2581.909424 134.129456,2588.652344 
	C124.319771,2591.635742 114.986702,2592.464844 104.776207,2590.538086 
z"
        />
        <path
          fill="#344F68"
          opacity="1.000000"
          stroke="none"
          d="
M2927.246582,2710.555908 
	C2949.855225,2706.356201 2970.865723,2713.750732 2992.424805,2714.328613 
	C2992.892578,2719.081299 2987.899170,2717.106689 2987.236816,2720.659180 
	C2993.003906,2723.252441 2999.252686,2721.666992 3005.238525,2721.941650 
	C3011.521973,2722.230225 3017.829346,2721.990723 3025.063232,2721.990967 
	C3026.000000,2725.428711 3026.000000,2728.857422 3026.000000,2733.143066 
	C3023.930664,2735.940918 3021.454102,2736.753906 3018.665771,2736.444580 
	C2997.506836,2734.097656 2976.300537,2732.990723 2955.015381,2734.008545 
	C2951.693115,2734.166992 2948.495850,2733.373779 2946.466309,2733.123535 
	C2943.906982,2732.969482 2944.949463,2735.346191 2944.975098,2734.013184 
	C2944.979004,2733.810547 2945.083252,2733.706787 2945.288086,2733.713379 
	C2956.118896,2734.058838 2966.910889,2734.291016 2977.731445,2735.672363 
	C2989.145264,2737.129639 3000.610596,2740.173096 3012.373535,2736.682861 
	C3016.357666,2735.500732 3020.963135,2736.389648 3025.380371,2739.486328 
	C3026.000000,2743.428711 3026.000000,2746.857422 3026.000000,2751.143066 
	C3024.148926,2753.607666 3021.859863,2754.557129 3019.561035,2754.163574 
	C2996.616455,2750.236084 2973.093750,2755.822998 2949.532715,2749.665283 
	C2942.432861,2749.862061 2936.315918,2745.308105 2929.170898,2748.906494 
	C2927.550049,2749.596680 2926.567871,2749.765625 2924.823486,2749.656250 
	C2920.557129,2748.356201 2920.599121,2745.320068 2920.279785,2741.732910 
	C2913.561768,2737.622559 2906.126465,2738.479248 2899.141357,2737.011230 
	C2894.909912,2736.122314 2889.813232,2736.416016 2886.765381,2733.684570 
	C2877.090576,2725.014160 2865.379639,2724.571533 2852.865234,2723.466309 
	C2851.793945,2722.637451 2851.429199,2722.217529 2850.801514,2720.990234 
	C2850.365479,2714.784180 2854.188477,2713.548584 2858.340088,2712.994141 
	C2864.309570,2712.196777 2870.321289,2712.378174 2876.264160,2713.293701 
	C2885.909668,2714.779785 2895.451660,2715.311035 2904.836670,2711.795410 
	C2911.962402,2709.125977 2919.372559,2713.571777 2927.246582,2710.555908 
z"
        />
        <path
          fill="#859CAF"
          opacity="1.000000"
          stroke="none"
          d="
M3009.219727,2042.022461 
	C3010.569824,2042.766602 3011.385742,2043.451294 3011.671143,2044.312134 
	C3014.678223,2053.379395 3014.655029,2053.386963 3025.190430,2053.999268 
	C3026.000000,2075.375244 3026.000000,2096.750244 3026.000000,2119.062744 
	C3015.518799,2119.368408 3005.037842,2118.728760 2994.555908,2118.111084 
	C2991.894043,2117.954346 2989.217285,2117.788818 2986.645508,2118.747559 
	C2983.396973,2119.958740 2980.054932,2119.352051 2979.121094,2116.186523 
	C2978.203857,2113.078125 2980.458008,2110.609375 2984.004639,2109.975342 
	C2985.270264,2109.749023 2986.723389,2109.577881 2986.970215,2107.427979 
	C2987.045898,2106.316895 2985.954346,2105.545898 2985.055176,2104.570312 
	C2979.582764,2098.633789 2979.603516,2098.614746 2973.396973,2102.614502 
	C2971.340576,2100.573975 2971.924072,2098.272705 2973.208252,2096.309326 
	C2974.896973,2093.727539 2976.159180,2091.165527 2976.023193,2087.983154 
	C2975.759033,2081.807617 2978.540283,2077.871826 2984.984375,2077.120361 
	C2980.048096,2066.604736 2972.586182,2063.014648 2963.660889,2067.847900 
	C2958.185303,2070.812988 2954.224365,2069.246826 2949.736328,2066.817627 
	C2947.534180,2065.625488 2945.918457,2064.114258 2946.273193,2061.349121 
	C2946.627197,2058.590820 2948.735596,2057.288818 2950.990479,2056.787598 
	C2955.830322,2055.711914 2960.506836,2053.556396 2965.616943,2054.026855 
	C2972.968018,2054.703613 2979.494629,2053.504395 2984.155762,2046.983765 
	C2985.072021,2045.702026 2986.680420,2044.819458 2988.119873,2044.024536 
	C2993.336670,2041.143066 2994.801758,2041.874512 2995.985107,2047.772339 
	C2996.049805,2048.094727 2996.001709,2048.438232 2996.029297,2048.769775 
	C2996.195312,2050.770752 2996.216309,2053.148438 2998.811523,2053.332275 
	C3001.473877,2053.520996 3001.878174,2051.234619 3001.945557,2049.187012 
	C3002.086670,2044.910645 3003.788818,2042.166992 3009.219727,2042.022461 
z"
        />
        <path
          fill="#A6A29C"
          opacity="1.000000"
          stroke="none"
          d="
M1972.502930,4033.435547 
	C1959.291138,4034.000000 1946.582397,4034.000000 1932.936768,4034.000000 
	C1928.717285,4030.771240 1929.100830,4026.763428 1929.744629,4022.781982 
	C1931.742554,4010.426514 1935.788574,3999.006348 1945.019409,3990.003906 
	C1945.970215,3989.076660 1945.609009,3990.372803 1945.627319,3989.305664 
	C1945.890137,3973.906250 1959.928101,3963.682617 1960.863281,3948.653809 
	C1961.307739,3941.511230 1964.199585,3935.239258 1966.764404,3928.809326 
	C1972.664429,3914.018311 1978.406982,3899.180664 1981.294678,3882.613770 
	C1981.503784,3881.301025 1981.612915,3880.775879 1981.962891,3879.476318 
	C1984.879272,3874.484131 1983.567749,3866.912109 1992.403809,3867.872070 
	C1996.852295,3873.835938 1993.237549,3879.305908 1992.817017,3884.640381 
	C1991.736206,3898.348389 1990.882080,3912.141357 1986.930176,3926.211914 
	C1986.997925,3928.127686 1983.146606,3928.588623 1986.512939,3930.778320 
	C1986.661987,3960.877197 1984.004395,3990.089844 1972.727539,4018.595947 
	C1972.034790,4020.206543 1970.223145,4020.301270 1970.775757,4021.814941 
	C1970.910645,4022.184814 1971.271851,4021.952393 1972.303955,4021.224609 
	C1977.847656,4025.111816 1975.814697,4028.990967 1972.502930,4033.435547 
z"
        />
        <path
          fill="#1F3041"
          opacity="1.000000"
          stroke="none"
          d="
M3026.000000,3487.070801 
	C3014.956787,3484.552979 3004.025879,3480.668213 2992.096191,3481.782959 
	C2989.937744,3481.984619 2987.189453,3481.297607 2985.482910,3480.004883 
	C2976.764648,3473.400146 2965.909180,3472.988525 2956.202881,3469.324219 
	C2952.716553,3468.008301 2950.016357,3469.408203 2948.096436,3471.372070 
	C2944.075684,3475.485596 2940.085449,3475.920654 2934.675537,3474.356934 
	C2930.934326,3473.275391 2926.941162,3472.659668 2923.197998,3471.399658 
	C2920.307617,3470.426758 2917.391357,3469.423828 2914.469482,3470.339600 
	C2905.589600,3473.122803 2897.885986,3469.053711 2889.625977,3467.048096 
	C2878.358154,3464.312012 2869.114502,3456.569580 2857.502441,3455.045898 
	C2857.033936,3454.984375 2856.673828,3454.094238 2856.040527,3452.891113 
	C2859.765625,3447.681152 2864.341553,3450.192627 2869.003418,3451.025146 
	C2869.155762,3448.110107 2863.733643,3446.621826 2866.760498,3443.073242 
	C2869.220947,3440.188965 2872.544678,3440.395020 2875.942383,3440.457520 
	C2897.657471,3440.855957 2918.253174,3447.129150 2938.976807,3452.575684 
	C2944.622314,3454.058838 2949.888916,3454.858398 2955.564453,3453.084229 
	C2965.009033,3450.132324 2973.822754,3454.864258 2982.957031,3455.799561 
	C2985.684814,3456.078857 2988.593994,3457.351562 2987.164062,3451.808105 
	C2988.718506,3448.174316 2991.476318,3447.645752 2994.083008,3448.221436 
	C3004.728760,3450.574219 3015.443604,3452.834717 3025.422363,3459.417480 
	C3026.000000,3468.713867 3026.000000,3477.427490 3026.000000,3487.070801 
z"
        />
        <path
          fill="#102131"
          opacity="1.000000"
          stroke="none"
          d="
M2858.537598,3130.520752 
	C2857.090576,3130.026611 2856.211182,3130.015625 2854.672852,3129.994141 
	C2852.343750,3124.539307 2851.600830,3118.288574 2843.035156,3117.991211 
	C2837.195312,3116.386230 2832.007568,3115.131592 2827.498535,3112.237305 
	C2822.890137,3109.279053 2820.941650,3105.634277 2826.257568,3100.507812 
	C2840.532715,3096.308838 2853.814453,3100.197510 2867.997559,3101.648438 
	C2875.221924,3107.300537 2883.359131,3107.957520 2891.014893,3109.908203 
	C2905.494385,3113.597656 2919.537842,3118.798340 2933.728027,3123.124268 
	C2953.716553,3129.217773 2973.612061,3137.752930 2995.655029,3135.974365 
	C3005.996582,3135.139893 3015.377930,3140.911621 3025.395020,3145.481934 
	C3026.000000,3148.133301 3026.000000,3150.266846 3026.000000,3153.200195 
	C3018.607422,3152.997070 3011.158203,3151.377686 3003.998291,3154.946777 
	C2998.556885,3157.658936 2993.549561,3154.549072 2988.427002,3153.837402 
	C2982.848877,3153.062744 2977.385254,3151.526123 2971.669189,3152.023193 
	C2968.183838,3152.326416 2965.513916,3150.952881 2962.614258,3148.879395 
	C2956.131104,3144.242676 2948.283936,3142.427979 2940.760986,3144.502686 
	C2933.431396,3146.524170 2926.868408,3144.645264 2920.328857,3142.889404 
	C2913.346191,3141.014404 2906.304443,3139.028809 2899.800049,3135.405762 
	C2895.870117,3133.216797 2891.277832,3132.053467 2886.408203,3133.461670 
	C2880.072754,3135.293457 2874.100830,3130.690918 2866.853271,3131.991455 
	C2863.431885,3133.500732 2861.221436,3132.581543 2858.537598,3130.520752 
z"
        />
        <path
          fill="#3A4B59"
          opacity="1.000000"
          stroke="none"
          d="
M171.231628,3169.875000 
	C174.747559,3169.818848 177.514755,3169.815674 181.111176,3169.772461 
	C189.488297,3169.118164 197.044464,3169.496826 205.325714,3169.665527 
	C209.431229,3171.395508 215.223984,3171.157227 214.415817,3176.513672 
	C213.636261,3181.680908 208.227264,3181.874268 203.947128,3182.230225 
	C196.658997,3182.835938 189.329361,3182.894531 181.090317,3183.685059 
	C184.945847,3186.123779 188.999512,3185.801758 191.976196,3187.997559 
	C194.089462,3189.556152 196.931335,3190.890137 195.893829,3194.148438 
	C194.949234,3197.114990 192.177658,3197.666992 189.359146,3197.639648 
	C186.693527,3197.613770 184.017334,3197.472412 181.387085,3197.144531 
	C164.117203,3194.991455 149.036133,3201.053955 132.562500,3209.468994 
	C141.294449,3212.912598 149.581284,3208.492432 156.467194,3214.205566 
	C157.717850,3216.938477 157.402908,3219.003418 156.363800,3221.821777 
	C155.758469,3223.053955 155.453995,3223.515869 154.519073,3224.520020 
	C152.394089,3225.842773 150.789246,3226.136719 148.323303,3226.072510 
	C140.348969,3224.032715 133.033737,3225.131592 125.771889,3225.028076 
	C121.380302,3224.965332 117.634079,3224.163818 114.289215,3220.491943 
	C108.056152,3213.650146 100.278404,3208.909180 90.213425,3207.003418 
	C78.182014,3206.409912 67.324577,3209.180176 55.479362,3210.751953 
	C47.783222,3211.205078 41.044628,3212.200439 33.658470,3214.649170 
	C22.934521,3215.488770 13.024228,3217.377686 2.727357,3212.587891 
	C2.000000,3211.111084 2.000000,3210.222168 2.000000,3208.666504 
	C7.163564,3201.625977 13.297935,3198.674072 21.981348,3199.753174 
	C34.273438,3201.280518 45.782166,3196.146484 56.028801,3190.724121 
	C64.370895,3186.309814 72.702927,3184.852539 80.716103,3186.317139 
	C95.172707,3188.959961 109.831970,3184.836914 124.192696,3189.137207 
	C128.467926,3190.417480 135.346176,3190.595947 135.772690,3182.398682 
	C135.817841,3181.531250 136.874115,3180.586426 137.830063,3180.395264 
	C149.112839,3178.136719 157.909149,3168.121338 171.231628,3169.875000 
z"
        />
        <path
          fill="#43596D"
          opacity="1.000000"
          stroke="none"
          d="
M2870.511230,3104.961670 
	C2855.809570,3103.496094 2841.918213,3101.373291 2826.928711,3102.026855 
	C2822.415283,3102.708008 2818.820068,3103.150391 2814.532715,3102.414062 
	C2812.265381,3102.047607 2810.661133,3101.601807 2808.391602,3101.875000 
	C2807.001221,3102.126465 2806.205322,3102.275879 2804.808105,3102.552734 
	C2802.976318,3102.864258 2801.781982,3103.202637 2799.964600,3103.670898 
	C2798.066650,3104.008301 2796.794922,3103.960449 2794.918945,3103.532715 
	C2792.759521,3102.734619 2791.558594,3101.473633 2789.322021,3100.886230 
	C2787.868164,3100.660156 2787.037354,3100.502686 2785.580811,3100.168457 
	C2780.450684,3098.585205 2776.122803,3096.195801 2770.455566,3097.642578 
	C2768.874023,3097.780029 2767.968262,3097.720947 2766.406982,3097.379883 
	C2763.368896,3095.827881 2760.964111,3094.557861 2757.366211,3094.907959 
	C2754.194824,3095.023926 2751.731201,3094.854492 2748.500977,3095.120605 
	C2739.580078,3097.491943 2731.606934,3095.310547 2722.837891,3094.028320 
	C2718.834717,3091.187012 2714.717285,3090.942871 2710.966797,3089.698242 
	C2706.672363,3088.273193 2700.137207,3087.706787 2701.655762,3081.659912 
	C2703.250977,3075.308594 2709.313965,3079.236328 2712.920166,3079.327393 
	C2697.088379,3073.637939 2680.011475,3071.524902 2662.731201,3071.292725 
	C2651.950195,3071.147705 2641.681152,3068.045410 2631.084229,3066.970703 
	C2627.815918,3066.639404 2623.714600,3066.245850 2623.331543,3062.111816 
	C2622.920166,3057.666260 2626.623047,3055.987549 2630.393311,3055.090088 
	C2631.706055,3054.777588 2632.966797,3054.462402 2634.730225,3053.264160 
	C2636.400146,3052.558105 2637.419189,3052.414307 2639.229492,3052.593750 
	C2648.498779,3057.632324 2657.388672,3060.523682 2668.097656,3057.769531 
	C2671.629150,3057.115234 2674.359131,3057.237305 2677.860352,3058.230469 
	C2680.133301,3059.270264 2681.565186,3060.741699 2683.137695,3060.580566 
	C2698.675049,3058.988037 2711.442627,3066.399414 2724.531982,3072.940918 
	C2737.749268,3079.546631 2752.563965,3077.988037 2766.538818,3080.796387 
	C2797.410400,3087.000000 2828.739258,3089.967041 2860.019531,3093.198486 
	C2864.297363,3093.640381 2868.350586,3095.749756 2873.597900,3094.702637 
	C2884.511963,3089.191406 2894.594971,3092.418457 2905.457764,3094.834229 
	C2927.152588,3101.281006 2948.523438,3105.874756 2970.061035,3108.741211 
	C2983.033203,3110.467773 2996.397461,3109.437744 3009.353027,3112.639160 
	C3014.856201,3113.999268 3020.356445,3115.117188 3025.402344,3119.475342 
	C3026.000000,3122.777832 3026.000000,3125.555664 3026.000000,3129.166748 
	C3014.414795,3133.168213 3002.878662,3130.647217 2991.907471,3127.932129 
	C2966.095215,3121.543701 2939.699463,3117.381348 2914.579346,3108.224365 
	C2906.966553,3105.449219 2899.049561,3104.974121 2890.954834,3106.456299 
	C2884.375244,3107.660889 2877.707031,3107.738770 2870.511230,3104.961670 
z"
        />
        <path
          fill="#9DAAB4"
          opacity="1.000000"
          stroke="none"
          d="
M2.000000,2038.937012 
	C5.461622,2038.259277 9.069510,2037.470825 11.489346,2035.489136 
	C15.390102,2032.294922 19.935171,2032.124878 24.402290,2031.369751 
	C22.755217,2023.521729 22.755186,2023.521606 30.262396,2021.591187 
	C39.738083,2019.154419 41.092197,2019.657104 44.788261,2027.677612 
	C45.661209,2029.571899 47.057247,2029.878174 48.726868,2029.983765 
	C51.204971,2030.140503 52.716515,2030.981323 54.029442,2033.526489 
	C56.918316,2039.126587 57.315086,2038.969849 61.972069,2034.030518 
	C66.611649,2029.109863 67.553154,2028.893433 72.326965,2033.475098 
	C74.902428,2035.946533 76.808975,2039.115112 78.883453,2041.796265 
	C77.071198,2042.782593 74.017487,2041.450195 71.223625,2042.161011 
	C67.237457,2043.174927 63.082512,2044.134399 64.860718,2049.969971 
	C65.119293,2050.818604 65.032753,2052.559814 64.666901,2052.694336 
	C57.775108,2055.228760 61.500961,2064.092041 55.878754,2067.329834 
	C55.383801,2067.614746 54.802074,2068.825439 54.921227,2068.944336 
	C58.634136,2072.650879 56.583572,2074.359131 52.933895,2076.364746 
	C49.217896,2078.406738 51.024952,2083.302246 49.035324,2086.174316 
	C47.221416,2088.792480 44.180992,2088.536377 42.480762,2085.722168 
	C39.442017,2080.693115 37.266861,2081.115723 33.512848,2085.433350 
	C29.609571,2089.922852 23.238117,2089.145752 17.820322,2089.832275 
	C13.226516,2090.414551 8.504243,2089.983154 2.919410,2090.000000 
	C2.000000,2088.500000 2.000000,2087.000000 2.000000,2084.750000 
	C4.886174,2080.844727 8.879717,2082.671631 11.753799,2081.765869 
	C9.150834,2081.832520 5.990230,2082.597168 2.518353,2080.467041 
	C2.000000,2066.624756 2.000000,2053.249512 2.000000,2038.937012 
z"
        />
        <path
          fill="#637E95"
          opacity="1.000000"
          stroke="none"
          d="
M2805.413574,2526.678467 
	C2822.995117,2528.821533 2839.890381,2532.277588 2857.281250,2528.292725 
	C2865.519775,2526.404785 2873.997559,2528.396729 2881.960449,2530.283203 
	C2892.785645,2532.847656 2903.761719,2531.673096 2914.638184,2533.343018 
	C2928.679443,2535.498535 2943.094482,2535.234619 2957.352295,2535.941895 
	C2958.991211,2536.023193 2960.730713,2535.908203 2962.284668,2535.431152 
	C2964.429932,2534.772217 2967.292480,2534.178711 2966.833008,2531.132324 
	C2966.503418,2528.946533 2964.229492,2528.053955 2962.162109,2528.186035 
	C2952.461182,2528.805908 2943.120850,2525.281738 2933.385498,2525.936523 
	C2927.832275,2526.310059 2922.487061,2524.651855 2917.024902,2523.463135 
	C2908.701172,2521.652100 2900.501709,2521.293457 2892.667969,2516.590820 
	C2888.714844,2514.218750 2882.246582,2516.122314 2876.923096,2515.910156 
	C2874.467529,2515.812012 2870.785645,2516.731445 2870.788818,2512.839844 
	C2870.791504,2509.755127 2874.058105,2510.235840 2876.299072,2510.055908 
	C2887.675537,2509.142822 2899.053223,2510.646973 2909.851807,2513.341064 
	C2921.279297,2516.192139 2932.832031,2516.671387 2944.280762,2517.661133 
	C2955.515625,2518.632080 2966.746582,2518.936768 2977.821045,2521.451416 
	C2981.327881,2522.247559 2985.109375,2521.850098 2988.766602,2521.962158 
	C2993.736816,2522.114502 2998.681885,2521.743896 3003.588623,2523.418701 
	C3010.580322,2525.804443 3017.302002,2521.500977 3025.139893,2521.988770 
	C3026.000000,2532.044189 3026.000000,2542.088379 3026.000000,2553.066406 
	C3024.814697,2555.073486 3023.429443,2555.627441 3021.080078,2555.472656 
	C3013.265381,2550.159668 3005.847900,2547.644531 2996.717529,2552.586426 
	C2989.685059,2553.192139 2983.414307,2553.771973 2976.577637,2551.245117 
	C2972.167236,2543.639160 2965.115234,2543.270264 2958.648438,2543.120850 
	C2941.404297,2542.721924 2924.154297,2542.003418 2906.931641,2541.815430 
	C2891.843018,2541.650635 2877.493164,2536.488281 2862.363525,2537.242676 
	C2854.781982,2537.620605 2847.259277,2537.232422 2839.603516,2538.376465 
	C2828.106445,2540.094971 2816.635254,2538.333740 2805.161621,2533.452881 
	C2804.001953,2530.909180 2804.078125,2529.137939 2805.413574,2526.678467 
z"
        />
        <path
          fill="#9DAAB4"
          opacity="1.000000"
          stroke="none"
          d="
M53.289825,1912.074829 
	C54.156929,1914.374878 53.847694,1917.131470 56.903183,1917.665649 
	C61.386406,1918.449585 64.807251,1920.079224 66.997253,1924.561646 
	C68.738220,1928.125000 73.445900,1927.377319 77.117966,1929.340698 
	C75.547279,1932.738525 72.562508,1934.815430 70.228714,1937.366211 
	C69.595802,1938.057861 67.840324,1938.227051 67.739708,1938.827148 
	C65.397980,1952.793213 53.817890,1947.015991 45.990948,1948.881958 
	C41.575191,1949.934570 36.862095,1949.732422 32.284931,1950.119141 
	C30.549595,1950.265747 28.868959,1950.767212 28.702391,1952.791138 
	C28.533754,1954.839844 30.085653,1955.777100 31.834393,1955.816650 
	C39.508057,1955.990234 46.850815,1957.947754 54.259285,1959.638794 
	C58.025852,1960.498413 61.501282,1960.014282 63.939919,1956.405762 
	C65.163773,1954.594727 67.055801,1953.472412 68.828316,1955.446533 
	C70.655411,1957.481567 70.081573,1959.851685 68.089325,1961.569214 
	C66.300797,1963.111084 64.035706,1963.612427 61.681927,1964.011963 
	C56.153633,1964.950073 50.652927,1966.082520 45.182796,1967.320679 
	C41.536545,1968.145996 37.790089,1968.693970 34.446239,1967.037109 
	C28.659838,1964.169922 22.521299,1964.495728 17.114595,1966.419556 
	C10.847274,1968.649536 7.189306,1966.152222 2.692446,1962.129883 
	C2.000000,1945.958130 2.000000,1929.916382 2.000000,1912.937256 
	C11.874881,1910.284424 21.926338,1913.057739 31.897364,1910.407471 
	C35.641266,1909.412476 40.160225,1911.333618 45.157486,1911.934814 
	C46.878407,1911.972534 47.767681,1911.999268 49.323883,1912.046997 
	C50.870693,1912.064331 51.750603,1912.059448 53.289825,1912.074829 
z"
        />
        <path
          fill="#020D19"
          opacity="1.000000"
          stroke="none"
          d="
M3026.000000,3899.067871 
	C3016.464111,3901.176270 3009.391846,3897.224609 3001.591064,3891.609619 
	C2989.638916,3883.006592 2976.312012,3876.110107 2961.748779,3872.038086 
	C2955.045898,3870.163818 2948.646729,3868.170654 2941.521484,3867.667969 
	C2933.768311,3867.121094 2926.118408,3864.377686 2918.562988,3862.123535 
	C2915.546631,3861.223633 2912.028809,3859.889160 2912.188721,3855.510498 
	C2912.320801,3851.895996 2915.053711,3850.581787 2917.732422,3849.125000 
	C2926.396240,3844.413818 2935.833008,3843.031250 2945.475586,3842.909180 
	C2947.441650,3842.884277 2949.331787,3843.066406 2951.307617,3842.595215 
	C2963.596436,3839.665039 2974.858154,3844.822754 2986.395020,3847.416260 
	C2990.706543,3848.385254 2993.722656,3851.990234 2995.869629,3855.855957 
	C2997.972900,3859.643066 3001.013672,3862.149902 3005.204102,3862.584717 
	C3012.332764,3863.324707 3018.253906,3866.992676 3025.270020,3869.907471 
	C3026.000000,3879.378662 3026.000000,3888.757080 3026.000000,3899.067871 
z"
        />
        <path
          fill="#3F5467"
          opacity="1.000000"
          stroke="none"
          d="
M2.690806,2718.530273 
	C2.000000,2716.500000 2.000000,2715.000000 2.000000,2712.750000 
	C8.002831,2706.453369 15.735946,2704.846436 23.177444,2705.247070 
	C44.576630,2706.398926 65.096054,2701.750244 85.718864,2697.416992 
	C88.673943,2696.795654 91.622391,2696.817871 95.377792,2697.014160 
	C101.753868,2696.857666 107.362907,2696.441650 112.840981,2698.184082 
	C108.624084,2696.602051 105.570145,2694.529541 108.454056,2688.859375 
	C125.123039,2683.559326 141.856903,2683.144775 158.196350,2683.620361 
	C179.875046,2684.251709 201.200165,2683.135010 223.303406,2679.366455 
	C231.995193,2680.560059 240.383118,2679.168701 246.534531,2686.981445 
	C249.373459,2690.586914 254.606018,2687.401855 258.539154,2685.875977 
	C269.975006,2681.439453 281.478424,2677.558594 294.707458,2680.260742 
	C298.414032,2683.259033 298.289917,2686.241943 295.525574,2688.789795 
	C293.337341,2690.806396 291.623810,2692.931641 290.332001,2696.336426 
	C288.549042,2697.731201 287.094452,2698.074951 284.795227,2698.188477 
	C274.108246,2698.172607 264.323425,2699.047119 254.082779,2702.488037 
	C236.785294,2703.219971 220.294693,2703.691162 203.958206,2700.471924 
	C197.361755,2699.172119 191.051788,2699.626709 184.505661,2701.891846 
	C176.331619,2704.719971 167.592392,2701.063965 159.096558,2702.836182 
	C152.718307,2704.166504 145.788437,2702.858398 139.253754,2707.357422 
	C122.639259,2711.715088 106.430977,2712.775391 90.160889,2713.862793 
	C68.440086,2715.314941 46.268505,2711.953857 25.086039,2719.564697 
	C19.585651,2721.541016 13.823646,2718.006104 7.466022,2720.316406 
	C5.437896,2720.119873 4.365349,2719.705322 2.690806,2718.530273 
z"
        />
        <path
          fill="#3E5871"
          opacity="1.000000"
          stroke="none"
          d="
M2867.426514,2933.334717 
	C2878.630127,2936.625977 2889.148926,2939.835938 2899.901123,2941.691895 
	C2904.655273,2942.512939 2909.365479,2944.236572 2915.158203,2943.993652 
	C2930.552979,2940.317383 2944.588867,2944.688721 2958.674805,2947.100342 
	C2962.693359,2947.788086 2967.328613,2947.739258 2971.376221,2951.409180 
	C2972.490479,2953.464600 2973.828125,2954.898682 2973.037354,2956.570801 
	C2972.351562,2958.020508 2968.317383,2957.398926 2972.742432,2961.002441 
	C2985.750977,2971.595703 3001.001465,2972.337158 3016.278809,2973.929199 
	C3018.884277,2974.201172 3021.541260,2973.979736 3025.087158,2973.993164 
	C3026.000000,2974.888916 3026.000000,2975.777832 3026.000000,2977.333496 
	C3015.175781,2985.696777 3003.519043,2980.682129 2992.196289,2980.109375 
	C2971.335938,2979.053711 2951.994629,2971.549072 2932.285400,2965.620117 
	C2926.231201,2963.798828 2920.126221,2962.070312 2912.863770,2962.213135 
	C2903.958252,2960.454102 2895.843262,2958.993408 2886.738770,2958.504883 
	C2881.613281,2956.531250 2877.084473,2956.921387 2871.717773,2957.043945 
	C2850.320312,2957.883545 2829.774658,2958.910400 2809.129883,2951.636719 
	C2804.906738,2944.338379 2810.128174,2943.857666 2814.397461,2943.299561 
	C2823.830811,2942.065674 2833.280029,2942.295898 2842.651367,2944.429443 
	C2843.995361,2944.075195 2842.283203,2942.512695 2842.729980,2943.768799 
	C2842.924316,2944.314941 2842.697998,2944.510010 2842.044434,2944.471924 
	C2835.708740,2944.101074 2830.469727,2941.751953 2827.091309,2936.119873 
	C2825.721924,2933.837158 2824.163086,2931.389160 2825.649414,2928.717529 
	C2827.174072,2925.977051 2830.129883,2926.093750 2832.753662,2926.343506 
	C2844.299072,2927.443604 2855.658691,2929.601074 2867.426514,2933.334717 
z"
        />
        <path
          fill="#646A6C"
          opacity="1.000000"
          stroke="none"
          d="
M1984.523926,3930.385742 
	C1986.449219,3912.480713 1988.877930,3894.940674 1991.247925,3877.392334 
	C1991.598267,3874.797363 1991.671143,3872.165283 1991.725586,3868.693848 
	C1985.328613,3855.584961 1990.304443,3843.548340 1992.726807,3830.632324 
	C1993.466675,3826.472900 1993.758179,3823.134766 1994.121460,3818.958252 
	C1996.939819,3813.904053 1995.689087,3809.383789 1994.864624,3804.319824 
	C1994.577881,3802.979980 1994.626709,3802.241211 1994.795410,3800.958984 
	C2001.180176,3779.383545 1999.811401,3757.590332 2001.443726,3736.087158 
	C2002.474731,3722.505127 2003.297485,3708.898926 2004.669922,3695.353760 
	C2005.183960,3690.280029 2006.154419,3684.157715 2014.720459,3686.113281 
	C2019.281616,3695.708008 2017.869019,3705.165039 2017.149658,3714.334961 
	C2015.822144,3731.256104 2014.206421,3748.158447 2012.921387,3765.981201 
	C2009.959595,3774.058105 2012.180176,3781.643555 2010.944824,3789.792480 
	C2010.431641,3793.826172 2009.636597,3796.968018 2009.090088,3800.958252 
	C2008.946289,3802.810059 2008.918945,3803.872803 2008.932129,3805.737793 
	C2009.197388,3808.697754 2009.008301,3810.841309 2008.658325,3813.789307 
	C2006.486206,3824.061279 2007.764160,3834.038086 2003.427368,3843.452881 
	C1999.138672,3849.649902 2000.036255,3856.541504 1999.030029,3863.020508 
	C1996.390747,3880.014893 1994.411133,3897.107422 1993.364502,3915.186523 
	C1994.099243,3917.840332 1994.081055,3919.520264 1992.419434,3921.502441 
	C1991.187988,3928.034180 1990.461060,3934.144043 1989.775391,3941.104980 
	C1989.382202,3946.210205 1988.883301,3950.459473 1988.525757,3954.720215 
	C1987.590088,3965.876221 1986.919312,3976.894287 1989.659424,3988.078857 
	C1993.187134,4002.478027 1994.475830,4017.269775 1994.059814,4033.073242 
	C1987.277466,4034.000000 1980.554810,4034.000000 1972.916138,4034.000000 
	C1972.545166,4031.547607 1973.109985,4029.099121 1973.629639,4026.641357 
	C1974.067261,4024.571045 1973.667847,4022.881348 1970.721924,4022.091309 
	C1969.424561,4014.722168 1973.647949,4008.650879 1975.423584,4001.964600 
	C1978.936890,3988.735107 1980.773315,3975.342773 1982.538574,3961.817139 
	C1983.890625,3951.456787 1983.467285,3941.037842 1984.523926,3930.385742 
z"
        />
        <path
          fill="#2B3E51"
          opacity="1.000000"
          stroke="none"
          d="
M2112.937256,4034.000000 
	C2111.139648,4026.660400 2113.806396,4019.855713 2115.465088,4012.954590 
	C2117.057129,4006.331543 2119.482666,3999.908936 2121.874512,3992.721191 
	C2125.775879,3986.423584 2131.220215,3986.674072 2136.316895,3988.453857 
	C2149.080322,3992.911133 2161.640625,3998.023438 2173.341309,4004.841064 
	C2178.872559,4008.064209 2184.381836,4010.665527 2191.478027,4011.460449 
	C2192.159180,4011.817139 2193.087402,4011.879395 2193.548584,4011.939697 
	C2198.371826,4017.376221 2204.265625,4019.987305 2211.514160,4021.411133 
	C2215.527832,4022.375244 2218.886475,4023.263428 2222.992920,4024.183838 
	C2224.139893,4024.567383 2224.539062,4024.918457 2225.350586,4025.955078 
	C2226.477539,4028.799316 2226.306641,4030.846924 2224.499023,4033.376221 
	C2187.291504,4034.000000 2150.583008,4034.000000 2112.937256,4034.000000 
z"
        />
        <path
          fill="#6E8AA1"
          opacity="1.000000"
          stroke="none"
          d="
M2852.751953,2332.543457 
	C2845.204102,2331.598145 2838.259521,2332.953125 2831.389893,2331.017334 
	C2832.132812,2327.020996 2834.979980,2328.056641 2836.979736,2328.064453 
	C2853.567383,2328.130371 2869.965576,2325.519043 2886.445068,2324.656738 
	C2897.366211,2324.084961 2908.056641,2323.921631 2918.522217,2320.369629 
	C2922.562500,2318.998047 2926.414062,2320.795898 2929.784180,2323.125732 
	C2935.884277,2327.342773 2942.181641,2326.930908 2948.644287,2324.223389 
	C2948.946289,2324.097168 2949.189941,2323.741211 2949.486816,2323.705078 
	C2960.006592,2322.429199 2969.978760,2319.347900 2979.469238,2314.664551 
	C2985.951416,2318.382812 2993.803711,2310.693115 3000.161133,2317.262451 
	C3001.517578,2318.664551 3004.093018,2317.246094 3004.842529,2315.071533 
	C3005.609863,2312.844727 3003.663086,2312.413330 3002.138428,2311.784180 
	C2999.019775,2310.497314 2995.814697,2309.904541 2992.418457,2309.982910 
	C2984.369385,2310.169189 2976.608643,2308.541016 2969.174805,2305.560303 
	C2966.272461,2304.396240 2963.574219,2303.879150 2960.496582,2304.769043 
	C2950.234131,2307.736572 2939.535400,2304.006592 2929.229736,2308.182373 
	C2928.773682,2302.920654 2931.502197,2300.297607 2934.164795,2300.631348 
	C2944.248535,2301.895752 2953.775635,2297.982422 2963.590576,2298.122070 
	C2980.283203,2298.359375 2996.962158,2299.440674 3013.654297,2299.909424 
	C3017.416748,2300.015381 3020.708008,2301.803955 3025.187012,2301.976318 
	C3026.000000,2308.061279 3026.000000,2314.122314 3026.000000,2321.091797 
	C3020.706055,2321.889893 3015.382568,2322.470215 3010.208740,2320.539795 
	C3008.211426,2319.794189 3006.030518,2320.574707 3005.268311,2322.733887 
	C3004.248047,2325.623779 3007.075195,2325.499756 3008.608887,2326.494141 
	C3012.252930,2328.856934 3017.102539,2326.726562 3020.825928,2329.508057 
	C3019.382080,2332.815430 3016.748047,2331.927002 3014.674072,2331.937988 
	C3010.135254,2331.962158 3005.821533,2331.770264 3005.800537,2338.267822 
	C3005.791016,2341.217529 3003.389404,2341.821045 3000.834229,2341.948242 
	C2998.256348,2342.076416 2998.186523,2340.355469 2997.144043,2338.548828 
	C2993.458496,2332.161377 2987.199707,2329.192383 2980.053223,2328.132324 
	C2978.073975,2327.838867 2975.489014,2327.793945 2974.625000,2329.737793 
	C2970.696533,2338.577148 2963.066895,2336.377930 2956.504395,2335.545410 
	C2940.469482,2333.511230 2924.389648,2333.067383 2908.277344,2332.859375 
	C2907.629639,2332.850830 2906.535156,2333.327881 2906.394043,2333.105713 
	C2901.163330,2324.863525 2896.683594,2332.568115 2891.726074,2333.474121 
	C2886.562256,2334.417480 2881.127686,2333.879883 2874.910645,2333.990234 
	C2866.897705,2339.690430 2860.276123,2334.485107 2852.751953,2332.543457 
z"
        />
        <path
          fill="#465869"
          opacity="1.000000"
          stroke="none"
          d="
M58.477680,2875.333008 
	C45.590244,2875.724365 33.585365,2877.297119 20.866856,2879.835205 
	C19.494236,2879.533203 18.880112,2878.821045 19.146053,2878.425049 
	C21.985819,2874.194092 18.629070,2876.042236 16.313745,2876.222168 
	C11.490123,2875.400879 7.109829,2876.860840 2.544227,2874.461914 
	C2.000000,2872.500000 2.000000,2871.000000 2.000000,2868.750000 
	C3.142379,2867.312500 1.674251,2868.154053 2.653672,2867.303223 
	C4.196740,2865.962646 6.168334,2865.994141 7.873191,2865.235596 
	C6.126303,2864.682617 4.302694,2864.291992 2.473914,2862.454590 
	C2.000000,2859.866699 2.000000,2857.733154 2.000000,2854.799805 
	C3.159725,2852.927734 4.524658,2852.375244 6.852436,2852.489258 
	C10.267657,2853.743652 12.746052,2855.538086 16.653851,2855.018799 
	C20.521576,2854.662842 23.632404,2854.651855 27.540066,2855.004150 
	C43.808517,2856.597656 59.430962,2856.073486 74.818741,2858.818115 
	C76.170189,2858.148193 74.282059,2856.615234 74.872635,2857.810547 
	C75.168343,2858.408691 75.029152,2858.670898 74.466194,2858.720215 
	C71.936241,2858.941895 68.822273,2859.996094 67.614525,2856.918457 
	C66.356590,2853.712646 69.225960,2852.249268 71.588531,2851.086426 
	C75.137207,2849.339844 78.712425,2847.598877 83.375320,2846.467041 
	C84.967796,2846.106201 85.830605,2846.100342 87.340263,2846.074951 
	C91.873779,2845.640625 93.581863,2841.897217 97.376228,2840.214844 
	C103.521889,2839.942383 109.084084,2840.485840 115.159592,2838.266357 
	C133.255753,2842.870361 150.276749,2836.241211 167.532761,2836.778320 
	C177.485092,2837.088135 186.404678,2840.442383 195.665543,2842.946045 
	C198.186386,2843.627197 200.993866,2845.021973 200.591431,2848.344482 
	C200.200607,2851.570801 197.236099,2852.173584 194.618134,2852.694580 
	C190.668304,2853.480469 186.465149,2853.990967 182.698318,2852.932129 
	C160.954056,2846.818848 141.287079,2854.526855 121.899818,2862.292969 
	C101.662392,2870.398926 81.833061,2879.310547 58.477680,2875.333008 
z"
        />
        <path
          fill="#102131"
          opacity="1.000000"
          stroke="none"
          d="
M2905.585205,3232.527344 
	C2914.837891,3231.187988 2922.945801,3234.897705 2931.354736,3236.521729 
	C2937.985352,3237.801514 2944.282471,3237.197754 2949.639893,3234.573242 
	C2959.866699,3229.564209 2968.257812,3233.385742 2976.737793,3238.010742 
	C2979.291260,3239.403564 2981.130127,3242.107422 2985.214111,3242.040527 
	C3000.389893,3241.942139 3013.029541,3247.260254 3025.451416,3255.452637 
	C3026.000000,3260.741211 3026.000000,3265.482178 3026.000000,3271.111816 
	C3016.801025,3275.330811 3007.807373,3272.805420 2998.856934,3270.856689 
	C2994.287598,3269.861572 2990.191406,3268.198242 2985.859863,3272.208252 
	C2982.328125,3275.478027 2977.546631,3273.703857 2973.371826,3272.790039 
	C2964.595703,3270.869141 2955.988525,3268.329346 2946.846436,3271.354492 
	C2943.313721,3272.523438 2939.678955,3270.559082 2936.186035,3269.438477 
	C2926.348145,3266.282227 2917.101562,3261.509033 2906.316895,3258.692139 
	C2900.823486,3256.392578 2894.798584,3256.104980 2893.177246,3248.998047 
	C2894.030273,3247.393066 2894.875488,3246.825684 2896.645752,3246.438232 
	C2905.524902,3248.427490 2913.453613,3249.358887 2921.767090,3246.145996 
	C2928.856201,3243.406006 2935.012695,3249.404785 2940.628418,3249.531982 
	C2932.630615,3246.328613 2923.191162,3243.321777 2913.421143,3241.244629 
	C2909.281250,3240.364746 2904.733643,3239.016357 2905.585205,3232.527344 
z"
        />
        <path
          fill="#3A4B59"
          opacity="1.000000"
          stroke="none"
          d="
M76.469131,3038.389160 
	C72.349831,3037.948486 68.755447,3037.184082 65.177467,3036.866211 
	C54.707150,3035.935303 44.231388,3035.920898 34.764153,3041.956299 
	C29.011549,3045.623535 22.539810,3044.035645 16.394432,3043.256592 
	C14.295053,3042.990723 14.721027,3040.491699 16.259975,3039.472168 
	C19.324198,3037.442139 22.881376,3036.540527 26.517134,3036.028076 
	C28.399294,3035.762939 31.179260,3036.080078 30.882687,3033.332520 
	C30.581976,3030.547119 27.815344,3029.864502 25.435028,3030.326416 
	C17.787598,3031.811035 9.584832,3031.783936 2.722659,3037.880371 
	C2.000000,3029.950928 2.000000,3021.901855 2.000000,3012.926270 
	C10.556678,3011.540771 19.308868,3010.968750 27.649458,3011.206787 
	C37.249550,3011.480469 46.606800,3008.993164 56.986488,3010.215576 
	C60.670433,3013.953125 65.042648,3014.010986 69.543900,3015.476074 
	C71.554695,3015.910400 73.013268,3015.918701 75.201141,3015.929199 
	C76.750450,3015.209229 77.701385,3014.925049 79.444534,3015.483887 
	C101.498070,3017.134766 122.750854,3013.843994 145.033447,3013.903809 
	C147.376526,3012.829102 148.923157,3012.486328 151.324860,3013.498779 
	C155.443176,3013.972900 158.868942,3013.987305 163.150940,3013.991699 
	C167.316696,3019.706543 163.330292,3021.759277 159.063110,3023.264160 
	C152.040512,3025.740967 144.736588,3025.407715 137.439026,3024.855713 
	C120.236183,3023.554932 103.326271,3025.743164 85.211082,3028.806641 
	C90.643417,3031.489990 95.255203,3031.364014 99.700302,3032.029785 
	C103.582680,3032.611572 107.097046,3034.071777 107.136086,3038.547363 
	C107.183182,3043.945068 102.730881,3044.489014 98.726311,3044.936768 
	C95.412178,3045.307129 92.093201,3045.133301 88.578270,3042.587646 
	C86.481300,3042.059814 85.011322,3042.009521 82.805908,3041.955322 
	C80.652031,3040.421387 78.791603,3039.605225 76.469131,3038.389160 
z"
        />
        <path
          fill="#C7C0B1"
          opacity="1.000000"
          stroke="none"
          d="
M1992.436035,3829.855225 
	C1994.803711,3835.666992 1994.033325,3841.595947 1993.174927,3847.392090 
	C1992.254883,3853.604736 1992.269043,3859.834229 1991.696045,3866.944580 
	C1986.296509,3869.810791 1987.549561,3875.560547 1984.511475,3879.581543 
	C1983.976196,3880.023438 1983.943848,3879.888672 1983.981934,3879.938477 
	C1986.079956,3888.964844 1981.969116,3896.943848 1978.979858,3904.715576 
	C1976.402344,3911.416260 1975.739868,3918.703613 1972.179443,3925.056152 
	C1971.434937,3926.384521 1971.692993,3928.290039 1970.474976,3929.602539 
	C1964.569458,3935.966309 1963.757446,3943.849854 1964.039429,3951.963379 
	C1964.233765,3957.556641 1962.586548,3961.353760 1958.012939,3965.497070 
	C1953.424683,3969.652832 1949.281250,3975.937012 1951.532593,3983.625732 
	C1952.791504,3987.925293 1952.095825,3991.523193 1946.831055,3992.834473 
	C1944.953491,3993.302246 1943.322388,3994.497314 1942.353882,3996.500977 
	C1936.869995,4007.843262 1931.391968,4019.168457 1932.030029,4033.137207 
	C1931.111084,4034.000000 1930.222168,4034.000000 1928.666626,4034.000000 
	C1923.135742,4022.998779 1920.952271,4022.114014 1909.542480,4027.159180 
	C1905.316772,4029.027832 1901.263916,4031.295166 1896.490723,4033.531738 
	C1887.950928,4034.000000 1879.901855,4034.000000 1870.926392,4034.000000 
	C1872.078247,4028.174805 1877.182739,4025.438477 1882.169434,4023.127197 
	C1892.202148,4018.476318 1902.492554,4014.317871 1913.693970,4013.328369 
	C1917.746216,4012.970459 1918.504028,4008.271484 1922.491455,4007.306396 
	C1926.184204,4006.412354 1928.431641,4003.278564 1926.733765,3998.730225 
	C1926.121094,3997.089111 1927.532104,3995.662598 1928.630249,3994.375488 
	C1934.426270,3987.583984 1938.159302,3979.182373 1945.125610,3973.288330 
	C1946.103027,3972.461914 1947.635010,3970.936768 1947.401245,3970.245361 
	C1945.371948,3964.246338 1953.466553,3962.959961 1952.971558,3957.658691 
	C1952.506470,3952.678955 1956.047729,3948.636475 1956.650757,3943.585449 
	C1957.208008,3938.916016 1962.794922,3935.355713 1963.117310,3929.734863 
	C1963.249146,3927.434814 1964.492188,3924.875244 1963.823853,3922.912354 
	C1961.639648,3916.498047 1964.150391,3911.006348 1967.347778,3906.244385 
	C1974.156738,3896.104980 1976.433716,3885.123535 1976.060181,3873.192139 
	C1975.891113,3867.792725 1976.121460,3863.123535 1980.450562,3857.955078 
	C1984.040161,3853.669434 1983.574341,3845.421631 1983.662231,3838.912354 
	C1983.770142,3830.924072 1983.211182,3830.916504 1992.436035,3829.855225 
z"
        />
        <path
          fill="#D0D1C8"
          opacity="1.000000"
          stroke="none"
          d="
M3025.250732,1751.400513 
	C3026.000000,1777.375122 3026.000000,1802.750244 3026.000000,1829.062744 
	C3015.158203,1833.591919 3012.908203,1843.078491 3009.884277,1853.045654 
	C3004.381592,1855.589722 2999.566162,1855.585571 2993.995850,1852.943604 
	C2991.768799,1842.329590 2989.649414,1832.355835 2992.733887,1822.201904 
	C2994.680176,1815.793823 2993.219238,1808.880005 2992.797607,1802.650635 
	C2992.130371,1792.787476 2995.902832,1783.232056 2993.353760,1773.390625 
	C2991.808350,1767.423706 2996.661133,1761.996338 2996.714600,1755.019531 
	C3005.732422,1749.008545 3014.819336,1747.446167 3025.250732,1751.400513 
z"
        />
        <path
          fill="#263B4D"
          opacity="1.000000"
          stroke="none"
          d="
M2.683146,2780.546387 
	C2.000000,2769.955811 2.000000,2759.911621 2.000000,2748.933594 
	C6.860321,2744.245361 12.268538,2746.086670 17.381239,2746.831787 
	C31.566853,2748.898926 45.585888,2749.489502 59.898937,2747.053711 
	C81.148666,2743.437256 102.757195,2742.139404 123.867889,2737.401367 
	C130.362122,2735.943848 137.065765,2736.017090 144.468170,2737.856445 
	C147.397369,2739.310059 148.694580,2741.047852 149.095093,2744.173340 
	C148.555725,2746.219238 147.725967,2747.155029 146.363785,2747.567871 
	C145.310989,2747.887207 144.221481,2747.890625 143.144135,2748.003906 
	C144.129700,2748.002197 145.115311,2747.996094 146.100800,2748.003906 
	C148.060150,2748.019531 150.054031,2747.746582 152.531281,2749.103516 
	C154.714615,2751.191650 155.449036,2753.093750 154.401337,2756.093262 
	C149.608414,2759.946777 144.555542,2759.330566 139.687271,2759.365234 
	C130.499252,2759.430908 121.295738,2759.102783 111.519318,2761.616455 
	C106.899429,2761.713135 103.868736,2757.713135 99.002960,2759.622559 
	C97.385460,2759.866455 96.458267,2759.895508 94.856323,2759.752930 
	C88.723724,2757.061035 86.680580,2761.139893 83.790421,2765.073730 
	C78.934166,2767.218262 74.713669,2765.706543 69.769821,2765.157715 
	C62.214310,2765.271240 56.722477,2767.263916 54.998158,2775.603760 
	C41.258698,2780.925293 27.476458,2781.932129 13.763809,2783.232422 
	C10.177274,2783.572266 6.514204,2783.340332 2.683146,2780.546387 
z"
        />
        <path
          fill="#4E6477"
          opacity="1.000000"
          stroke="none"
          d="
M2.000000,2780.856934 
	C16.765224,2780.371582 31.298021,2778.551025 45.631096,2775.141113 
	C48.541462,2774.448975 51.407055,2774.101074 55.205738,2774.166016 
	C62.162590,2772.703857 68.170830,2771.447754 69.153046,2781.198730 
	C60.299137,2790.328857 49.441593,2785.540771 41.398842,2786.961182 
	C53.638657,2788.200928 67.730225,2784.927246 82.377579,2788.419434 
	C87.524414,2795.543457 82.012604,2796.507080 77.824440,2796.738770 
	C69.219109,2797.214844 60.540215,2797.642090 54.094757,2796.513672 
	C63.433395,2796.782715 74.761574,2797.522949 86.355255,2795.321045 
	C102.992775,2792.160645 119.922119,2790.266602 136.948837,2790.698730 
	C144.580505,2790.892334 150.889435,2793.246094 150.650513,2803.485352 
	C146.846786,2807.757568 142.357330,2806.616455 137.294220,2807.014160 
	C107.530533,2813.864746 78.155617,2810.264404 49.007259,2811.373047 
	C42.471992,2811.621582 35.088577,2808.634033 30.198467,2816.460449 
	C28.622948,2818.982178 24.630602,2817.860596 20.792459,2817.847900 
	C18.461195,2817.752441 17.005236,2817.720459 14.821346,2817.666992 
	C10.408004,2818.679443 6.728238,2819.616211 2.627148,2816.507568 
	C2.000000,2809.938721 2.000000,2803.877686 2.000000,2796.908203 
	C11.739520,2791.294189 21.484205,2797.066406 29.583088,2795.906006 
	C21.878448,2794.483887 12.252479,2795.539551 2.514190,2792.455078 
	C2.000000,2788.571289 2.000000,2785.142578 2.000000,2780.856934 
z"
        />
        <path
          fill="#495E72"
          opacity="1.000000"
          stroke="none"
          d="
M146.832748,2740.045410 
	C130.945572,2738.382812 116.605164,2743.198730 101.908707,2744.934326 
	C78.655243,2747.680664 55.632191,2752.858887 32.020153,2751.945801 
	C22.451605,2751.575684 13.036380,2749.895996 2.828489,2748.024414 
	C2.000000,2738.621338 2.000000,2729.242920 2.000000,2718.932129 
	C2.907701,2718.001221 3.815401,2718.002441 5.403690,2718.020020 
	C19.228222,2719.534180 32.190834,2717.755371 45.145027,2715.859131 
	C46.456612,2715.667236 47.761593,2715.260498 49.078846,2715.303711 
	C53.500206,2715.447510 60.007320,2713.732178 60.335812,2719.947021 
	C60.625893,2725.435059 54.394386,2724.834717 50.220264,2724.987549 
	C42.862564,2725.257812 36.411591,2729.676514 28.999226,2729.699707 
	C33.008495,2730.664307 36.761845,2730.487549 40.393120,2727.952393 
	C43.499126,2725.783936 47.075714,2725.145996 51.521378,2726.583984 
	C58.533905,2737.293701 56.377441,2740.253906 46.903774,2740.964111 
	C57.398556,2738.470459 74.970406,2741.625732 88.759201,2734.156006 
	C111.808456,2721.669922 134.544113,2724.601318 158.086273,2728.687012 
	C159.389435,2728.913330 160.731873,2728.911865 162.834167,2729.159668 
	C171.718079,2729.877686 178.845642,2734.406250 187.765503,2735.092773 
	C192.051697,2735.232178 195.430038,2735.571045 198.387268,2739.107422 
	C199.044479,2748.402344 192.196442,2747.285156 187.545197,2746.971436 
	C174.063812,2746.062256 160.764664,2743.535645 146.832748,2740.045410 
z"
        />
        <path
          fill="#3F5467"
          opacity="1.000000"
          stroke="none"
          d="
M161.522049,2912.552734 
	C163.571899,2912.065430 165.042175,2912.029541 167.247757,2911.981934 
	C170.549118,2911.089844 172.709137,2912.291504 174.358536,2913.854248 
	C177.298248,2916.639404 183.647018,2917.465088 182.291992,2922.791260 
	C181.000717,2927.866699 175.476105,2926.987061 170.357941,2927.299316 
	C154.902603,2930.775879 139.937119,2931.278809 125.138405,2932.796875 
	C106.706665,2934.687256 88.192558,2936.418213 69.583282,2935.518311 
	C60.560341,2935.081787 51.720161,2934.140625 43.574642,2927.993652 
	C41.528851,2923.046875 37.973579,2923.358643 34.251934,2922.856445 
	C23.678385,2921.429199 13.632764,2926.418701 2.566501,2924.467041 
	C2.000000,2921.866699 2.000000,2919.733154 2.000000,2916.799805 
	C11.328201,2916.049561 20.265186,2913.471436 29.382326,2912.127686 
	C41.570541,2910.331543 52.842064,2906.084961 64.218880,2901.904297 
	C73.350403,2898.548828 81.752922,2902.221191 90.280304,2904.530518 
	C91.844360,2904.953857 93.414917,2906.138916 92.767601,2908.169434 
	C92.114182,2910.218750 90.039825,2910.176758 88.606392,2909.689453 
	C84.435081,2908.271973 80.159088,2908.362305 75.873322,2907.960693 
	C67.295029,2907.156982 61.569866,2911.116455 56.685635,2917.372314 
	C57.511269,2918.236084 58.108883,2919.116455 58.309071,2919.034180 
	C67.837334,2915.102539 77.869102,2916.136963 87.777443,2915.938232 
	C92.329506,2915.846924 97.218781,2916.862549 100.338806,2911.950928 
	C101.058899,2910.817383 102.644524,2910.444824 103.872322,2911.375488 
	C109.511276,2915.650391 116.034851,2913.685059 122.197220,2913.971924 
	C128.162094,2914.249756 134.150711,2914.017334 141.064926,2914.004639 
	C148.307098,2913.521484 154.719604,2915.066162 161.522049,2912.552734 
z"
        />
        <path
          fill="#3F5467"
          opacity="1.000000"
          stroke="none"
          d="
M193.875076,3358.635742 
	C200.883987,3357.666504 206.432098,3354.318359 212.320908,3352.180420 
	C232.638489,3344.804199 253.268341,3338.243164 273.570618,3329.065186 
	C276.242218,3328.461182 278.422089,3328.298096 281.412720,3328.262207 
	C282.115967,3338.121826 277.164368,3343.550293 267.639557,3345.244141 
	C261.124634,3346.403076 254.731369,3348.085693 248.693207,3352.596436 
	C236.528564,3353.578369 226.110016,3358.774902 214.365479,3361.336182 
	C212.333588,3361.807617 210.891998,3361.874268 208.729645,3361.975342 
	C206.297745,3365.784180 202.709488,3366.558105 199.231079,3367.610596 
	C184.803055,3371.975586 170.345871,3376.262451 155.848175,3382.625488 
	C154.823975,3383.307861 154.399872,3383.551514 153.315369,3384.091064 
	C143.216415,3390.502930 132.807053,3393.955322 121.716522,3395.312744 
	C119.921860,3395.532471 118.387627,3394.768311 120.879173,3397.640137 
	C116.763885,3406.518555 108.163704,3403.518311 101.665291,3405.536133 
	C79.528694,3412.409424 56.044983,3417.023193 36.569897,3429.174072 
	C25.339289,3436.181396 14.567407,3436.848633 2.562115,3438.460449 
	C2.000000,3435.222168 2.000000,3432.444336 2.000000,3428.833252 
	C17.092199,3424.002197 31.496067,3417.974854 46.178566,3412.796143 
	C74.003662,3402.981445 101.624466,3392.587646 129.324783,3382.419922 
	C133.943039,3380.724609 138.989822,3379.852051 142.646805,3375.088379 
	C147.414719,3369.613525 153.209442,3370.432129 159.646042,3370.566895 
	C171.604645,3367.297363 182.530762,3363.406250 193.875076,3358.635742 
z"
        />
        <path
          fill="#5B768D"
          opacity="1.000000"
          stroke="none"
          d="
M3025.342773,2633.475830 
	C3026.000000,2641.385742 3026.000000,2648.771729 3026.000000,2657.078613 
	C3015.580322,2663.586914 3005.165283,2665.614502 2994.274902,2658.913818 
	C2988.255371,2655.209961 2981.488281,2653.600342 2974.366699,2654.436768 
	C2964.836182,2655.556152 2955.325439,2651.853271 2944.992676,2654.560547 
	C2940.367188,2655.256836 2936.570801,2655.409912 2932.119141,2654.001465 
	C2927.006348,2647.452637 2916.680908,2646.889404 2908.365234,2652.865234 
	C2906.344238,2653.791260 2904.898682,2653.887451 2902.719727,2653.413330 
	C2894.232910,2647.677734 2886.411377,2647.054688 2877.852051,2653.403320 
	C2872.713623,2655.420654 2868.367188,2655.436523 2863.552246,2652.382080 
	C2859.265137,2642.140137 2859.587402,2641.636963 2869.388672,2640.827148 
	C2873.258545,2640.507568 2876.930176,2639.218262 2881.415039,2637.915527 
	C2889.941650,2635.810547 2897.443115,2638.334717 2905.314941,2638.827393 
	C2900.941650,2636.462646 2892.488037,2641.046143 2893.020996,2630.694336 
	C2894.526123,2628.691650 2896.048828,2628.165283 2898.524414,2627.911621 
	C2904.868164,2628.890381 2910.177002,2630.662598 2915.500977,2632.399658 
	C2922.815186,2634.785400 2930.201904,2635.948242 2938.504395,2633.231445 
	C2945.958984,2631.590088 2953.433350,2636.799561 2960.317139,2630.425781 
	C2971.118652,2627.745361 2981.177490,2627.985352 2992.003906,2630.552734 
	C2994.789551,2631.507812 2996.584961,2632.780762 2999.561768,2632.990479 
	C3001.414551,2633.006104 3002.479004,2633.005127 3004.356445,2632.978516 
	C3011.675049,2631.694580 3018.181641,2629.032471 3025.342773,2633.475830 
z"
        />
        <path
          fill="#3C556B"
          opacity="1.000000"
          stroke="none"
          d="
M3025.419922,2989.410156 
	C3026.000000,2990.888916 3026.000000,2991.777832 3026.000000,2993.333496 
	C3024.083008,2996.111816 3021.521240,2996.486084 3018.865723,2996.930176 
	C3015.278076,2997.530518 3009.072754,2993.708496 3009.735840,3002.266846 
	C3009.843506,3003.659424 3006.650391,3004.478516 3004.648438,3004.603516 
	C2998.057861,3005.016113 2991.467041,3004.795898 2984.866943,3004.637207 
	C2978.097656,3004.474121 2978.846680,2996.272705 2972.934570,2994.358643 
	C2971.842529,2993.784668 2971.408447,2993.536621 2970.343506,2992.868652 
	C2966.881104,2990.661377 2963.771240,2989.396240 2960.453857,2989.527588 
	C2948.938232,2989.983154 2937.653320,2987.700684 2925.458984,2986.924316 
	C2922.060303,2987.074951 2919.452881,2987.115234 2916.104492,2986.570068 
	C2912.949707,2985.929443 2910.563477,2985.127686 2907.353516,2986.297852 
	C2900.974365,2987.172852 2895.362793,2988.071289 2888.949219,2988.866699 
	C2879.032959,2990.915283 2869.737061,2992.226562 2860.091797,2995.609375 
	C2848.638428,2997.919189 2838.161377,2995.382080 2827.694092,2993.975098 
	C2823.016846,2993.346191 2818.545410,2992.671143 2813.120361,2994.039551 
	C2806.523682,2995.493164 2801.008789,2994.410400 2794.900879,2991.959229 
	C2790.888184,2988.188477 2784.165771,2985.396729 2786.690186,2980.208740 
	C2789.147461,2975.157959 2795.676758,2977.807373 2800.442627,2978.715088 
	C2813.681641,2981.237061 2827.270264,2981.085938 2840.381348,2984.736084 
	C2837.464111,2981.081055 2829.532715,2981.113281 2831.755371,2975.356201 
	C2834.304199,2968.752930 2840.889893,2972.264648 2845.691406,2973.719238 
	C2852.109863,2975.663086 2858.523926,2976.948242 2865.248291,2976.907959 
	C2869.923340,2976.879883 2874.085693,2977.125977 2877.485107,2972.406006 
	C2881.017578,2967.501465 2887.340576,2968.139404 2892.302490,2969.319336 
	C2901.810303,2971.580566 2911.414307,2973.357178 2921.786377,2975.096191 
	C2915.928223,2973.445068 2910.466553,2973.571045 2905.279785,2972.359863 
	C2901.988770,2971.591309 2898.402344,2971.202148 2899.752686,2965.678223 
	C2903.130615,2962.946289 2906.453613,2963.005615 2910.641113,2963.349121 
	C2930.896973,2966.452881 2948.970459,2974.476074 2968.160400,2978.593262 
	C2978.453613,2980.802002 2988.633545,2978.968262 2998.702393,2980.982666 
	C3007.674561,2982.777344 3017.266113,2982.394775 3025.419922,2989.410156 
z"
        />
        <path
          fill="#2B3E51"
          opacity="1.000000"
          stroke="none"
          d="
M19.269077,2971.231445 
	C20.244354,2972.775391 20.529083,2974.461182 20.978268,2974.506104 
	C28.469263,2975.257080 34.140568,2979.544189 40.223255,2983.405273 
	C43.079330,2985.218262 47.436420,2984.425293 50.961376,2983.305908 
	C58.152424,2981.022705 65.492607,2982.360352 72.761658,2982.088623 
	C74.341354,2982.029541 76.469818,2981.955811 76.746269,2984.088379 
	C77.070099,2986.586182 75.017365,2987.365479 72.998405,2987.788086 
	C71.105858,2988.184082 69.007339,2987.808594 66.845703,2989.592529 
	C70.050186,2993.584229 74.351578,2992.317139 77.581787,2991.497070 
	C87.808044,2988.901611 98.102257,2990.463867 108.350876,2990.065674 
	C110.353554,2989.987793 112.332092,2990.251221 114.068817,2991.440430 
	C123.673393,2998.018066 134.536026,2995.964600 145.038010,2995.621338 
	C148.540543,2995.506836 152.053223,2993.916260 155.229980,2991.912842 
	C160.450653,2988.620605 166.289261,2987.614014 172.414429,2988.018311 
	C175.103928,2988.195801 178.288330,2988.112793 178.940353,2991.291992 
	C179.756424,2995.270752 176.000015,2996.169678 173.356628,2997.083740 
	C169.618668,2998.376953 165.631760,2999.827393 161.782379,2999.745605 
	C152.755783,2999.553711 143.977051,3001.433594 135.061890,3001.980957 
	C127.765945,3002.428955 121.537163,3007.015381 114.094566,3007.061523 
	C110.839722,3007.081543 107.598320,3008.240234 104.339005,3008.814697 
	C99.915253,3009.593994 94.516663,3009.410400 92.542580,3005.795654 
	C88.167061,2997.783447 80.447762,2999.271973 74.255127,2998.906250 
	C69.705894,2998.637451 64.432808,2996.824463 59.690151,2999.578613 
	C58.718853,3000.142822 56.750904,3000.105713 55.837769,2999.478760 
	C48.411140,2994.377930 39.510178,2997.310791 31.660967,2994.813477 
	C28.816195,2993.908447 26.520847,2994.944824 24.028120,2995.955322 
	C17.528782,2998.591064 10.829056,3000.382324 2.856875,3000.000000 
	C2.000000,2992.614258 2.000000,2985.228271 2.000000,2976.921387 
	C6.270467,2970.244385 11.526125,2967.532959 19.269077,2971.231445 
z"
        />
        <path
          fill="#1C2E3E"
          opacity="1.000000"
          stroke="none"
          d="
M2921.928467,3540.707031 
	C2934.888428,3541.934570 2946.144775,3547.821289 2957.302246,3554.114502 
	C2963.033203,3557.346924 2969.291748,3559.441895 2975.744873,3561.013428 
	C2979.165283,3561.846191 2982.158936,3562.205811 2985.110352,3559.948242 
	C2988.308350,3557.502686 2992.140137,3557.099365 2995.416504,3558.946045 
	C3002.106934,3562.717773 3010.131592,3562.712158 3016.601074,3566.968018 
	C3018.915039,3568.489990 3021.735596,3567.916992 3025.180664,3568.010742 
	C3026.000000,3571.428711 3026.000000,3574.857422 3026.000000,3579.143066 
	C3016.691895,3580.203125 3007.563477,3578.351562 2998.472900,3576.915283 
	C2991.075195,3575.747070 2983.202148,3577.023682 2976.223877,3572.692627 
	C2973.712646,3571.134033 2972.675781,3574.256836 2971.526123,3576.372070 
	C2968.248047,3582.404053 2967.042725,3582.888428 2960.509521,3581.117432 
	C2944.555908,3576.792725 2929.114746,3570.954346 2913.655518,3565.158691 
	C2908.110840,3563.079590 2902.017334,3562.534668 2896.415283,3558.723389 
	C2896.147217,3552.688721 2900.329834,3554.922119 2903.074463,3554.631592 
	C2908.890137,3554.016113 2915.060547,3554.607666 2919.621582,3548.610352 
	C2920.615234,3545.817627 2921.231201,3543.606201 2921.928467,3540.707031 
z"
        />
        <path
          fill="#FBF9EC"
          opacity="1.000000"
          stroke="none"
          d="
M1099.106079,4022.283691 
	C1096.667969,4013.003174 1095.427368,4004.012451 1092.263428,3995.759033 
	C1088.424927,3985.746582 1087.473145,3975.916748 1089.818115,3964.815430 
	C1090.401245,3963.951172 1090.959473,3963.727783 1090.994873,3963.792236 
	C1097.038818,3974.760010 1108.682007,3979.249268 1118.083740,3987.329590 
	C1119.892578,3989.435303 1121.145020,3991.170898 1123.702881,3992.419189 
	C1124.874023,3993.361084 1125.387573,3994.039062 1125.854004,3995.453125 
	C1127.022339,4002.396973 1132.538818,4004.076660 1137.209229,4005.891357 
	C1144.518921,4008.731445 1149.846436,4013.160156 1151.848022,4020.744629 
	C1153.347534,4026.427002 1159.173584,4028.110596 1161.881836,4033.326660 
	C1142.624878,4034.000000 1123.249756,4034.000000 1102.937256,4034.000000 
	C1101.150513,4030.300049 1100.300903,4026.600098 1099.106079,4022.283691 
z"
        />
        <path
          fill="#445E74"
          opacity="1.000000"
          stroke="none"
          d="
M3026.000000,2869.100098 
	C3021.807373,2871.662109 3017.599365,2870.880615 3012.656006,2870.088867 
	C3009.972900,2871.708008 3007.779053,2871.818115 3005.358398,2871.467285 
	C2990.304932,2869.283447 2975.028809,2868.994385 2960.068604,2865.898193 
	C2950.336182,2863.884033 2940.459473,2864.339111 2930.538330,2864.169678 
	C2911.461426,2863.844238 2892.221924,2861.867432 2874.349609,2851.450195 
	C2873.126221,2849.744873 2872.910156,2848.607178 2873.568848,2846.645508 
	C2884.384521,2845.411133 2894.397461,2847.947021 2904.559814,2849.101318 
	C2913.749268,2850.145020 2923.014160,2851.584961 2931.881592,2847.175781 
	C2933.308105,2846.466309 2935.146729,2845.957031 2936.671875,2846.183838 
	C2943.299316,2847.170898 2949.723877,2850.080811 2955.321045,2842.586914 
	C2956.987305,2840.355957 2963.030273,2841.421631 2966.798584,2842.761230 
	C2973.458496,2845.128418 2980.190674,2846.229248 2987.257812,2846.217041 
	C2990.492676,2846.211426 2994.077148,2846.800537 2996.900879,2848.274414 
	C3005.635254,2852.833496 3014.921387,2851.992676 3025.085205,2851.996582 
	C3026.000000,2857.400146 3026.000000,2862.800049 3026.000000,2869.100098 
z"
        />
        <path
          fill="#21364A"
          opacity="1.000000"
          stroke="none"
          d="
M2870.015869,3105.228027 
	C2878.943848,3103.418945 2888.195312,3103.903320 2897.437988,3102.355957 
	C2908.519287,3100.501221 2918.880127,3105.847900 2929.213379,3109.042480 
	C2938.695801,3111.974365 2948.018066,3115.514648 2957.857910,3116.836914 
	C2972.351074,3118.784912 2985.984131,3124.579102 3000.428711,3126.247559 
	C3008.511719,3127.181152 3016.660156,3127.214111 3025.215088,3130.004883 
	C3026.000000,3134.741211 3026.000000,3139.482178 3026.000000,3145.111816 
	C3018.588379,3145.785889 3011.601318,3143.954346 3005.021240,3140.534912 
	C3002.048340,3138.990234 2998.923828,3137.225098 2995.546631,3138.571533 
	C2988.528076,3141.369385 2981.808838,3139.504883 2975.041016,3137.807617 
	C2970.554199,3136.682373 2966.163330,3135.092041 2961.437744,3135.250488 
	C2960.779053,3135.272705 2959.819824,3135.558105 2959.505127,3135.232666 
	C2953.830078,3129.364502 2946.260742,3130.245605 2939.170898,3129.318115 
	C2937.224854,3129.063721 2935.122314,3128.535889 2933.500977,3127.495850 
	C2916.833984,3116.802734 2897.202637,3115.529541 2878.855713,3110.210938 
	C2875.642090,3109.279053 2872.317383,3108.944824 2870.015869,3105.228027 
z"
        />
        <path
          fill="#516D85"
          opacity="1.000000"
          stroke="none"
          d="
M2875.765625,2464.617188 
	C2878.701172,2461.216797 2882.210693,2462.135498 2885.435547,2462.026611 
	C2902.029297,2461.465332 2918.485596,2462.738281 2934.838379,2465.669434 
	C2938.368652,2466.302002 2942.076416,2466.466553 2945.775635,2464.690430 
	C2931.791748,2459.844482 2916.766357,2460.791748 2902.380371,2458.222656 
	C2907.933350,2456.064209 2928.338867,2455.401123 2934.163818,2456.425049 
	C2952.742432,2459.691650 2971.319824,2463.126221 2990.321045,2461.864258 
	C3002.215332,2461.074707 3012.908447,2466.621582 3025.191895,2467.978760 
	C3026.000000,2472.083252 3026.000000,2476.166748 3026.000000,2481.125000 
	C3025.001953,2481.946289 3023.900146,2482.151855 3023.020996,2481.800781 
	C3005.348145,2474.738770 2987.341064,2476.809814 2969.292725,2479.722168 
	C2968.310303,2479.880371 2967.245605,2480.138428 2966.316406,2479.925293 
	C2949.926270,2476.165771 2932.658936,2480.258545 2916.388916,2473.081787 
	C2908.801514,2469.734863 2899.308838,2471.421387 2890.731689,2472.849609 
	C2888.113037,2473.285645 2885.642334,2474.509521 2882.510254,2472.429688 
	C2880.064209,2469.438477 2876.584473,2468.526123 2875.765625,2464.617188 
z"
        />
        <path
          fill="#233A4F"
          opacity="1.000000"
          stroke="none"
          d="
M2.000000,3438.888184 
	C5.598863,3438.438477 8.130618,3435.987549 11.156880,3434.824707 
	C14.309585,3433.613281 17.842537,3430.457520 21.310509,3433.455811 
	C25.468925,3437.050781 27.783594,3434.064209 28.943100,3431.172607 
	C30.988457,3426.071777 34.467255,3424.427002 39.545403,3423.851562 
	C45.793449,3423.143555 49.910412,3417.339600 56.551601,3416.603760 
	C67.384010,3415.403320 76.529861,3407.887207 87.765800,3407.523438 
	C89.187073,3407.477295 90.739990,3406.101807 91.917015,3405.004150 
	C94.559715,3402.540771 97.415268,3401.375244 100.974327,3402.592285 
	C101.601143,3402.806396 102.218269,3403.051025 102.851280,3403.244385 
	C106.670380,3404.410645 108.324066,3398.222412 111.335381,3399.956055 
	C114.804337,3401.953125 116.842972,3400.709229 119.716324,3398.500000 
	C122.357300,3395.276123 125.879051,3394.928467 128.784760,3393.437256 
	C132.507401,3391.527100 136.658203,3390.970215 139.982635,3393.767822 
	C143.439407,3396.677002 143.153381,3400.741211 140.671738,3405.319336 
	C137.748749,3408.749512 134.580582,3409.405273 131.284515,3410.163086 
	C127.143402,3411.115479 123.061821,3412.188721 119.622063,3415.234863 
	C113.821976,3420.370850 113.656075,3420.183594 106.461098,3414.991211 
	C102.920891,3415.406982 104.290443,3420.786621 100.812630,3420.974121 
	C96.969513,3421.181152 98.886208,3416.462646 96.033592,3415.102539 
	C91.796875,3418.716309 84.647774,3417.214600 81.177368,3423.011230 
	C81.038101,3423.243896 80.566360,3423.450195 80.293022,3423.405029 
	C70.199013,3421.739502 63.152790,3428.974609 54.798054,3432.282471 
	C49.383690,3434.426025 43.485916,3436.714600 43.795414,3444.481934 
	C43.892765,3446.925293 42.538979,3449.053711 39.812756,3449.437012 
	C36.452858,3449.909180 36.574768,3447.068115 35.736893,3444.976318 
	C34.255302,3441.278076 31.861906,3441.685303 29.933422,3444.460205 
	C26.682240,3449.138672 23.004482,3450.537598 17.176273,3448.996094 
	C12.159709,3447.669678 8.283448,3452.808838 2.717645,3453.903320 
	C2.000000,3449.258789 2.000000,3444.517822 2.000000,3438.888184 
z"
        />
        <path
          fill="#5D758A"
          opacity="1.000000"
          stroke="none"
          d="
M2923.587891,2748.577637 
	C2924.923828,2748.050537 2925.804932,2748.032715 2927.347168,2748.019287 
	C2934.202148,2745.260742 2940.734863,2749.807129 2947.552246,2746.744141 
	C2962.507812,2748.285400 2976.710205,2752.426514 2991.372314,2748.267090 
	C2996.007080,2746.952393 3000.463135,2749.638428 3005.070801,2750.015625 
	C3011.579346,2750.548340 3018.077393,2751.209961 3025.290039,2751.908203 
	C3026.000000,2756.741211 3026.000000,2761.482178 3026.000000,2767.111816 
	C3024.388428,2769.285645 3022.534668,2770.224609 3020.500732,2769.767578 
	C3001.490967,2765.494385 2982.200684,2768.300293 2962.184082,2767.268555 
	C2956.058350,2767.140137 2950.943359,2768.419678 2944.851074,2768.809814 
	C2941.512451,2769.074219 2939.268555,2768.518799 2936.599854,2766.445312 
	C2928.788330,2762.078125 2921.623291,2763.077881 2913.830566,2764.098389 
	C2895.910889,2766.445312 2878.922607,2774.388672 2860.173096,2772.178711 
	C2852.988525,2771.331787 2845.965820,2769.201660 2838.578857,2769.268799 
	C2844.340332,2767.529297 2850.321045,2767.391357 2856.127686,2766.335938 
	C2862.474854,2765.182129 2869.547119,2767.087646 2874.099609,2760.539062 
	C2882.406738,2760.980469 2890.144287,2756.254883 2899.278809,2757.906006 
	C2909.429443,2754.546143 2919.030762,2752.826660 2928.119873,2755.314941 
	C2925.495361,2754.737549 2922.958496,2753.179932 2923.587891,2748.577637 
z"
        />
        <path
          fill="#172837"
          opacity="1.000000"
          stroke="none"
          d="
M3026.000000,3775.083984 
	C3014.875244,3769.530762 3001.830078,3768.996826 2990.096680,3764.403076 
	C2989.500244,3764.169434 2988.679443,3763.979248 2988.143066,3764.201660 
	C2979.461670,3767.803467 2972.609131,3763.066650 2964.765869,3760.309814 
	C2947.669922,3754.300537 2930.052002,3749.611084 2911.822510,3747.935791 
	C2907.556885,3747.543945 2903.349121,3746.493652 2899.126465,3745.686523 
	C2898.149414,3745.500000 2897.270508,3745.029541 2897.162109,3743.819824 
	C2897.020020,3742.233398 2898.021973,3741.269775 2899.239258,3740.811279 
	C2902.773438,3739.480713 2906.511719,3739.628418 2910.050049,3740.489746 
	C2913.297852,3741.280762 2916.630127,3741.435059 2919.807617,3742.136719 
	C2927.813477,3743.903809 2935.481445,3743.607666 2943.109619,3740.646484 
	C2944.999512,3739.912842 2947.201416,3739.654785 2948.916504,3740.817871 
	C2955.809326,3745.491455 2962.926758,3745.139160 2970.456787,3742.632812 
	C2972.071045,3742.095215 2974.253906,3743.564453 2976.196533,3743.656982 
	C2981.634766,3743.916504 2987.185791,3747.053223 2992.529297,3742.964600 
	C2993.412842,3742.288330 2995.383789,3742.051270 2996.314941,3742.572021 
	C3005.245850,3747.564941 3016.372314,3747.001953 3025.279785,3753.895264 
	C3026.000000,3760.722656 3026.000000,3767.445068 3026.000000,3775.083984 
z"
        />
        <path
          fill="#536F86"
          opacity="1.000000"
          stroke="none"
          d="
M3026.000000,2369.200195 
	C3017.787109,2371.726318 3009.809570,2366.553955 3001.182617,2368.839355 
	C3002.477783,2374.485352 3008.983643,2369.751465 3010.791260,2374.904541 
	C3002.226807,2376.844727 2993.821045,2375.982910 2985.447510,2375.382324 
	C2983.820312,2375.265625 2982.223633,2374.177490 2980.630127,2374.218018 
	C2959.348633,2374.763672 2938.430664,2369.987793 2917.197510,2369.946777 
	C2902.981689,2369.919434 2888.765869,2369.950684 2874.550049,2369.932617 
	C2870.903809,2369.927979 2867.236328,2370.238037 2863.691162,2368.902344 
	C2861.462158,2368.062744 2858.896973,2367.505615 2857.051514,2369.274170 
	C2853.416748,2372.758545 2848.925049,2372.499512 2844.815918,2371.833740 
	C2834.327881,2370.133789 2823.705811,2370.434326 2812.596924,2368.551758 
	C2812.580566,2362.289307 2817.343018,2363.645020 2821.459229,2362.462158 
	C2838.693359,2362.093018 2855.279785,2362.075195 2871.866211,2362.032959 
	C2880.805420,2362.010254 2889.510986,2365.037109 2898.533203,2364.017578 
	C2900.830811,2363.758301 2903.434814,2364.452881 2905.436035,2363.611084 
	C2920.817871,2357.140625 2936.493652,2359.969727 2952.191162,2361.570312 
	C2957.449219,2362.106445 2962.787842,2361.818359 2968.086670,2361.995117 
	C2975.670166,2362.248291 2983.294922,2361.316406 2990.788086,2363.674072 
	C2996.556641,2365.488770 3003.036377,2363.679199 3008.138672,2362.558105 
	C3013.894531,2361.293945 3019.055420,2362.698730 3025.198975,2362.002441 
	C3026.000000,2364.133301 3026.000000,2366.266846 3026.000000,2369.200195 
z"
        />
        <path
          fill="#434F51"
          opacity="1.000000"
          stroke="none"
          d="
M3025.610352,3403.487793 
	C3026.000000,3416.708740 3026.000000,3429.417725 3026.000000,3443.063232 
	C3023.281738,3446.502686 3020.358887,3445.340820 3017.617432,3444.247070 
	C3003.913818,3438.780518 2989.209229,3435.425293 2977.649414,3425.389404 
	C2975.985352,3423.944336 2973.812256,3424.798340 2971.210449,3424.233398 
	C2966.599854,3420.261475 2966.860596,3415.659912 2967.800781,3410.237549 
	C2973.979736,3404.765381 2976.802490,3399.402588 2971.316406,3391.713379 
	C2973.045166,3387.390381 2976.760254,3385.879639 2979.573242,3387.399170 
	C2989.762207,3392.903076 3001.945801,3390.067627 3012.575195,3396.058594 
	C3016.226318,3400.345947 3021.159668,3400.867432 3025.610352,3403.487793 
z"
        />
        <path
          fill="#5A6668"
          opacity="1.000000"
          stroke="none"
          d="
M3022.539551,1939.600342 
	C3016.932617,1940.671265 3012.336426,1938.895508 3006.814209,1938.102051 
	C3003.319336,1932.387451 3000.235840,1932.982544 2996.244141,1937.789551 
	C2986.916748,1933.723511 2978.688477,1936.609619 2970.438477,1939.483154 
	C2969.530762,1939.799316 2968.477539,1939.698120 2966.708740,1939.816284 
	C2962.889648,1937.395508 2962.484863,1933.820923 2961.818115,1929.615479 
	C2961.928467,1927.216675 2962.106934,1924.978027 2963.555176,1924.486816 
	C2970.654297,1922.079468 2972.639648,1915.461304 2976.588379,1909.557373 
	C2978.555664,1908.001099 2980.260986,1907.370850 2982.457031,1906.258545 
	C2988.027832,1904.655029 2986.696045,1899.594360 2989.067871,1895.949829 
	C2989.834717,1895.121460 2990.200928,1894.844849 2991.197754,1894.280518 
	C3003.179199,1893.758057 3014.808350,1891.691772 3025.504395,1899.516235 
	C3026.000000,1908.049072 3026.000000,1916.098145 3026.000000,1925.073608 
	C3023.218262,1930.017334 3027.058594,1935.426514 3022.539551,1939.600342 
z"
        />
        <path
          fill="#DFD9CC"
          opacity="1.000000"
          stroke="none"
          d="
M1424.921265,4034.000000 
	C1424.086792,4031.015381 1425.861206,4029.349854 1428.245117,4027.792236 
	C1431.224731,4025.845459 1434.721802,4024.350098 1436.207886,4020.659912 
	C1437.133789,4018.360352 1439.039062,4017.850342 1441.068481,4018.746338 
	C1445.124512,4020.537109 1448.304688,4018.125244 1449.290161,4015.346680 
	C1451.408203,4009.375488 1458.128906,4011.661377 1461.440796,4007.212646 
	C1464.092529,4003.651367 1468.994507,4000.862793 1474.058350,4000.007568 
	C1476.926147,3999.522949 1479.092651,3998.457764 1480.164917,3994.059082 
	C1484.375488,3988.798828 1491.329834,3989.894775 1494.532227,3983.764648 
	C1495.350464,3982.972412 1495.699829,3982.680908 1496.617065,3982.019531 
	C1500.728271,3977.873047 1507.805664,3979.676514 1510.194824,3972.968262 
	C1510.765137,3971.941895 1511.033447,3971.547852 1511.815186,3970.642822 
	C1523.026367,3965.634033 1531.996582,3957.189941 1544.920288,3956.457031 
	C1546.981323,3957.625732 1547.699463,3958.911377 1547.992920,3961.239014 
	C1549.009888,3966.172852 1549.470337,3969.181396 1545.202515,3973.083496 
	C1540.179810,3977.674561 1537.723267,3984.992676 1539.957397,3994.090088 
	C1535.883301,3991.701416 1534.448242,3988.751465 1529.432129,3990.870361 
	C1523.734009,3993.276855 1516.176270,3993.943359 1509.125122,3992.849854 
	C1505.112549,3992.227539 1500.868896,3993.095459 1496.309814,3993.312500 
	C1496.865234,3998.546387 1497.473511,4002.810547 1502.879395,4004.823975 
	C1505.747314,4005.891846 1506.258423,4008.755127 1505.619751,4011.605957 
	C1504.922607,4014.717041 1502.598755,4015.839844 1499.695190,4015.899414 
	C1497.373657,4015.946777 1494.998901,4016.011963 1492.738525,4015.586670 
	C1490.951538,4015.250488 1489.473145,4013.171143 1490.843994,4011.948486 
	C1493.397339,4009.671631 1494.974731,4008.483398 1491.099487,4005.848633 
	C1488.028320,4003.760742 1485.060425,4000.234619 1481.528198,4003.063721 
	C1477.378784,4006.386719 1473.104370,4008.196045 1467.954956,4008.386719 
	C1464.010620,4016.120117 1454.731079,4017.468750 1449.575928,4023.742920 
	C1447.467651,4026.308838 1448.104492,4029.427979 1447.998169,4033.193359 
	C1440.614136,4034.000000 1433.228394,4034.000000 1424.921265,4034.000000 
z"
        />
        <path
          fill="#334D63"
          opacity="1.000000"
          stroke="none"
          d="
M2936.034180,2766.652100 
	C2938.063477,2765.926514 2940.214600,2765.970703 2943.170654,2766.042480 
	C2949.229492,2769.022705 2954.352783,2769.929443 2959.602295,2764.674805 
	C2975.046631,2764.167480 2989.998779,2764.106201 3004.950439,2764.000977 
	C3011.706543,2763.953613 3017.831543,2766.990479 3025.196289,2767.937988 
	C3026.000000,2769.500000 3026.000000,2771.000000 3026.000000,2773.250000 
	C3017.917236,2778.506836 3009.840576,2772.926758 3002.687988,2774.566895 
	C3009.112305,2778.588623 3019.149658,2777.430420 3025.583984,2785.447998 
	C3026.000000,2789.428711 3026.000000,2792.857422 3026.000000,2797.143066 
	C3013.258789,2796.288574 3000.387695,2795.565186 2987.762939,2792.958008 
	C2985.826904,2792.558105 2983.831543,2791.980469 2981.900146,2792.095459 
	C2968.463623,2792.895264 2955.497559,2790.878418 2942.899170,2786.174561 
	C2937.323975,2784.093262 2931.266357,2784.278320 2925.142334,2783.178223 
	C2926.210693,2778.884766 2929.494629,2779.705566 2931.557861,2780.190918 
	C2942.205078,2782.697510 2953.028564,2783.798584 2963.857910,2784.981445 
	C2972.050781,2785.876465 2980.208984,2787.106201 2988.410889,2787.890137 
	C2991.126465,2788.149902 2994.347900,2788.678467 2996.924805,2784.588623 
	C2978.949951,2784.059082 2962.639893,2778.508545 2945.792236,2776.417480 
	C2939.172119,2775.595947 2937.470459,2772.445068 2936.034180,2766.652100 
z"
        />
        <path
          fill="#010611"
          opacity="1.000000"
          stroke="none"
          d="
M318.937317,4034.000000 
	C318.000061,4033.334717 317.992218,4032.669189 318.001312,4032.003906 
	C318.223450,4015.750732 321.031250,4012.833008 336.881195,4011.970947 
	C344.140900,4011.576172 351.205719,4009.052246 358.604187,4009.991455 
	C375.301636,4012.111328 392.408813,4010.550537 408.784576,4015.711182 
	C411.787506,4016.657715 416.186005,4015.609375 415.699707,4020.670654 
	C415.260712,4025.239746 412.092651,4027.773193 407.561218,4027.879883 
	C402.257904,4028.004883 396.927063,4027.886475 391.638275,4027.480957 
	C383.937347,4026.890625 376.782288,4028.145752 370.164124,4033.283203 
	C353.291534,4034.000000 336.583069,4034.000000 318.937317,4034.000000 
z"
        />
        <path
          fill="#1F3041"
          opacity="1.000000"
          stroke="none"
          d="
M171.385406,3169.309570 
	C170.454636,3172.146240 168.044296,3172.071289 165.723145,3172.004883 
	C162.588882,3171.915283 159.256653,3172.477539 157.359802,3174.647949 
	C152.288910,3180.449951 145.519653,3181.349854 138.656418,3182.427490 
	C138.194138,3182.500000 137.847931,3183.312012 137.409027,3183.825439 
	C137.793655,3186.959473 141.633423,3185.208252 142.625946,3187.489014 
	C142.442688,3188.041260 142.415100,3188.968750 142.032074,3189.151367 
	C136.566116,3191.759033 130.371201,3191.454834 124.714211,3193.383545 
	C123.825470,3193.686523 122.481506,3193.845215 121.802223,3193.391113 
	C112.946030,3187.471680 103.015038,3190.940430 93.599701,3190.077393 
	C89.027153,3189.658203 84.210533,3190.825928 80.149651,3187.644531 
	C73.976891,3192.271240 63.736759,3183.792725 59.264446,3194.121094 
	C58.944233,3194.860352 57.791756,3195.500244 56.915733,3195.698730 
	C47.551781,3197.820557 38.233643,3201.004639 28.765673,3201.561035 
	C19.705793,3202.093262 11.178692,3202.760986 2.759737,3207.979980 
	C2.000000,3201.938721 2.000000,3195.877686 2.000000,3188.908203 
	C5.326840,3188.029541 8.667295,3187.882080 11.976752,3188.136963 
	C15.395550,3188.400635 18.945429,3189.348389 19.712608,3193.205811 
	C20.741850,3198.381348 24.350702,3198.388672 28.027594,3197.827393 
	C31.737310,3197.261230 35.579590,3196.609131 38.792847,3194.588623 
	C37.918499,3191.716309 36.023422,3192.104736 34.503487,3192.046875 
	C31.812819,3191.945068 29.181709,3191.743652 26.805851,3190.334717 
	C23.685452,3188.484131 19.172512,3186.550781 20.867062,3182.420410 
	C22.843222,3177.603516 27.755692,3180.223877 30.266975,3182.024658 
	C38.846077,3188.177002 47.507771,3184.479980 55.968704,3182.817627 
	C62.386871,3181.557129 68.535461,3177.596191 74.534309,3183.561768 
	C84.665672,3183.201416 94.827667,3187.148193 104.933624,3183.354492 
	C109.906334,3181.487793 115.010818,3179.901367 119.815292,3177.670410 
	C134.463989,3170.869141 150.281601,3169.905762 165.930328,3168.082764 
	C167.501266,3167.899902 169.168121,3168.539551 171.385406,3169.309570 
z"
        />
        <path
          fill="#172B3E"
          opacity="1.000000"
          stroke="none"
          d="
M2887.590332,2986.594238 
	C2894.123047,2985.344238 2900.058594,2983.363037 2907.152344,2984.115234 
	C2909.608154,2984.025146 2911.202148,2984.002197 2913.394531,2984.049072 
	C2916.732178,2984.113281 2919.472412,2984.161621 2923.032715,2984.262207 
	C2927.585938,2987.351074 2935.854248,2985.263184 2934.118164,2994.485352 
	C2929.043213,2997.702881 2924.198730,2995.298584 2918.797852,2995.906250 
	C2915.725586,2996.025635 2914.174316,2993.615234 2911.524902,2993.922607 
	C2913.669434,2995.981689 2916.190674,2995.841309 2919.377441,2995.996826 
	C2936.573242,2996.094727 2952.114258,3002.205566 2969.242676,3003.385254 
	C2978.850098,3004.843994 2987.677246,3003.591064 2996.475342,3003.999023 
	C3006.340820,3004.457031 3016.033936,3005.581055 3025.356445,3011.442871 
	C3026.000000,3014.133301 3026.000000,3016.266846 3026.000000,3019.200195 
	C3014.702637,3027.033203 3003.435303,3020.744141 2992.179199,3019.804932 
	C2989.653076,3019.593994 2987.316162,3017.526611 2984.548584,3018.885254 
	C2986.588379,3018.776123 2988.409424,3019.265137 2988.533936,3021.628418 
	C2988.716553,3025.098877 2985.955566,3026.125488 2983.358643,3026.582275 
	C2974.780518,3028.090820 2966.139160,3026.619385 2956.761230,3026.767334 
	C2951.074219,3025.983398 2946.094238,3025.891357 2941.140381,3025.691406 
	C2938.392822,3025.580078 2935.765869,3025.381836 2933.642334,3023.644287 
	C2931.214844,3021.658203 2927.073242,3020.156006 2928.357910,3016.243408 
	C2929.392334,3013.092773 2933.245850,3013.381348 2936.186523,3013.099609 
	C2940.378906,3012.697754 2944.586914,3013.299316 2948.882568,3013.111572 
	C2941.186279,3012.143311 2933.673828,3010.805908 2926.435303,3008.311523 
	C2921.011475,3006.442139 2915.609131,3005.873535 2909.831299,3006.275879 
	C2896.099854,3007.231934 2890.233398,3002.033203 2887.590332,2986.594238 
z"
        />
        <path
          fill="#2B3E51"
          opacity="1.000000"
          stroke="none"
          d="
M2.678935,2964.534180 
	C2.000000,2953.290039 2.000000,2942.580322 2.000000,2930.935059 
	C3.166363,2928.861572 4.115142,2927.497314 6.297615,2926.326660 
	C13.958340,2924.012207 20.834484,2920.967529 28.385799,2926.402100 
	C30.981895,2931.432617 34.976990,2932.280762 39.294975,2933.249023 
	C47.889725,2935.176270 56.714882,2936.499512 64.678947,2940.589844 
	C67.828636,2942.207275 71.385864,2943.474121 72.791946,2948.092041 
	C68.333038,2955.073486 62.010471,2958.935547 54.956478,2960.456543 
	C40.139473,2963.651367 25.022030,2965.208008 9.935213,2966.737061 
	C7.614506,2966.972168 5.334335,2966.475098 2.678935,2964.534180 
z"
        />
        <path
          fill="#859CAF"
          opacity="1.000000"
          stroke="none"
          d="
M2994.845703,2169.985352 
	C2993.829590,2165.098145 2994.940430,2159.963623 2990.932129,2156.121826 
	C2991.677979,2155.290283 2992.019775,2154.703369 2992.528320,2154.375977 
	C2995.521729,2152.448242 3001.448242,2152.720459 2998.941895,2146.585693 
	C2996.881836,2141.543213 2992.211182,2139.860352 2987.020752,2142.070312 
	C2985.099609,2142.888184 2983.487549,2144.035400 2981.699707,2142.160645 
	C2979.886719,2140.258545 2980.824951,2138.523926 2982.194580,2136.904785 
	C2983.689941,2135.136963 2985.541260,2133.585693 2985.842773,2131.093506 
	C2986.718262,2123.858154 2993.347168,2122.500000 2997.733887,2123.092285 
	C3006.582520,2124.287354 3015.749512,2122.681641 3025.162598,2125.977539 
	C3026.000000,2142.041748 3026.000000,2158.083740 3026.000000,2175.062744 
	C3023.051514,2175.873535 3019.696533,2176.674072 3017.552979,2174.036621 
	C3012.892822,2168.302979 3007.257080,2167.560791 3000.639160,2169.804932 
	C2999.135254,2170.314697 2997.335449,2169.951416 2994.845703,2169.985352 
z"
        />
        <path
          fill="#495E72"
          opacity="1.000000"
          stroke="none"
          d="
M2.000000,2964.856934 
	C16.178030,2963.626465 30.012255,2959.782227 44.073238,2959.241455 
	C54.643070,2958.834961 62.189556,2952.683350 71.488617,2948.402832 
	C72.755600,2947.161133 73.688164,2946.735840 75.529938,2946.619141 
	C81.707840,2948.532715 87.161064,2949.402344 93.574554,2947.346191 
	C114.399849,2943.632568 134.803146,2945.436768 154.882965,2942.746582 
	C157.840607,2942.350586 160.852966,2942.746094 164.525024,2944.225098 
	C168.834747,2947.973877 167.539566,2951.235107 164.564575,2955.145264 
	C158.484451,2956.500488 153.041595,2957.192139 146.775177,2957.864746 
	C140.788284,2958.164551 135.447220,2957.646240 130.452728,2961.473633 
	C125.241524,2961.948975 120.513664,2961.986084 114.902016,2961.952148 
	C102.962860,2961.762451 91.819023,2958.203369 80.876854,2963.068848 
	C78.512032,2964.120361 75.728241,2964.276367 72.731056,2965.904053 
	C69.932976,2966.147705 67.816055,2966.153076 64.906189,2966.198730 
	C55.021801,2970.245850 45.292526,2969.710693 34.859688,2970.162109 
	C29.708567,2970.258301 25.351572,2969.512695 20.506361,2971.380859 
	C13.976414,2971.279297 8.462467,2972.370361 2.740857,2975.860840 
	C2.000000,2972.571289 2.000000,2969.142578 2.000000,2964.856934 
z"
        />
        <path
          fill="#79868C"
          opacity="1.000000"
          stroke="none"
          d="
M3026.000000,1899.062744 
	C3021.807373,1901.324951 3017.587158,1895.085449 3015.439697,1897.542603 
	C3010.766113,1902.889526 3008.997803,1894.426270 3004.810303,1896.531372 
	C3001.884521,1898.002197 2997.348145,1896.270264 2992.745605,1895.933838 
	C2990.579590,1891.709961 2995.105957,1891.299805 2996.449707,1888.299438 
	C2996.285156,1886.975830 2996.156738,1886.210205 2995.985352,1884.835938 
	C2995.969727,1881.653931 2995.257324,1879.267090 2993.854980,1876.323853 
	C2993.307861,1874.717896 2993.082520,1873.759644 2992.841553,1872.805054 
	C2988.959229,1857.448486 2988.957764,1857.451172 3004.970215,1854.856567 
	C3006.645264,1854.585205 3008.032715,1853.752930 3009.543945,1851.853027 
	C3009.884277,1849.849731 3009.895508,1848.558350 3009.920410,1847.267212 
	C3010.197021,1833.001831 3010.197510,1833.001831 3025.182861,1830.047485 
	C3026.000000,1852.708496 3026.000000,1875.416870 3026.000000,1899.062744 
z"
        />
        <path
          fill="#263B4D"
          opacity="1.000000"
          stroke="none"
          d="
M2905.539551,3256.671387 
	C2917.110107,3257.118164 2926.505127,3263.118408 2936.798584,3266.451416 
	C2941.821289,3268.078125 2946.301025,3270.568115 2951.669189,3266.748535 
	C2954.250977,3264.911621 2958.049561,3265.933838 2961.340576,3266.741943 
	C2967.420654,3268.235107 2973.571289,3269.439209 2979.655762,3270.915527 
	C2982.837646,3271.687744 2985.860596,3272.839844 2984.920898,3267.408203 
	C2984.410400,3264.457764 2986.705811,3263.762207 2988.614502,3264.678467 
	C2999.937744,3270.115234 3012.479736,3269.307373 3025.165039,3271.981445 
	C3026.000000,3276.741211 3026.000000,3281.482178 3026.000000,3287.111816 
	C3016.469727,3292.466309 3006.274170,3289.838135 2995.691650,3290.645996 
	C2992.763672,3290.042725 2990.918457,3288.596680 2988.063965,3288.114258 
	C2986.598877,3287.979248 2985.762207,3287.889648 2984.298340,3287.694824 
	C2982.403076,3287.419189 2981.181885,3286.960693 2979.210205,3287.018799 
	C2975.725830,3287.510254 2972.911621,3287.925293 2969.347412,3287.073730 
	C2953.565674,3281.877441 2938.932617,3275.730713 2922.277344,3277.529785 
	C2913.067871,3278.524414 2905.321289,3268.311523 2905.539551,3256.671387 
z"
        />
        <path
          fill="#23394D"
          opacity="1.000000"
          stroke="none"
          d="
M3026.000000,3119.073730 
	C3015.132080,3117.605225 3004.297363,3115.039551 2993.381104,3112.890869 
	C2988.353271,3111.901367 2983.415771,3110.683105 2978.039795,3113.207031 
	C2973.312012,3115.426514 2968.360596,3112.068848 2963.707764,3110.742432 
	C2957.584961,3108.996582 2951.574463,3107.488037 2945.167480,3107.962158 
	C2940.781982,3108.286865 2936.735352,3107.203857 2932.634521,3105.504150 
	C2924.406006,3102.093750 2915.980225,3099.132080 2906.482422,3098.440430 
	C2907.825928,3093.417969 2911.939453,3092.717773 2915.722412,3093.423828 
	C2923.369385,3094.850830 2930.469238,3093.020996 2938.446289,3091.163574 
	C2941.899658,3091.199219 2944.669434,3091.357422 2946.855957,3092.901367 
	C2966.835205,3107.012695 2987.824463,3102.311523 3009.301270,3095.806641 
	C3015.412598,3086.145020 3014.807129,3084.409912 3004.971680,3081.760010 
	C3001.781982,3080.900635 2998.477295,3080.350342 2995.513916,3078.845459 
	C2992.428711,3077.279297 2989.539795,3075.435059 2992.649902,3070.784180 
	C3003.764893,3069.664062 3014.285400,3066.862061 3025.336914,3071.470703 
	C3026.000000,3078.061279 3026.000000,3084.122314 3026.000000,3091.091797 
	C3025.733643,3092.333252 3025.467041,3092.666748 3025.600342,3093.500000 
	C3026.000000,3102.049072 3026.000000,3110.098145 3026.000000,3119.073730 
z"
        />
        <path
          fill="#243748"
          opacity="1.000000"
          stroke="none"
          d="
M2.000000,3084.916016 
	C12.876621,3083.342773 23.618729,3081.617920 34.354359,3079.864990 
	C35.624763,3079.657471 37.083839,3079.312500 38.014122,3078.515869 
	C46.655960,3071.116455 55.767750,3073.285400 65.490845,3077.562012 
	C66.773277,3082.461914 61.766518,3082.694824 60.554691,3086.104980 
	C67.236122,3088.662598 74.867523,3085.080811 81.706268,3089.560791 
	C81.614296,3092.904297 79.747215,3093.644775 77.140434,3093.975342 
	C67.660454,3095.177490 58.415913,3096.436523 50.113724,3102.444580 
	C45.831814,3105.543457 39.570255,3106.031006 33.809006,3105.974121 
	C23.831739,3105.875488 13.852311,3105.995605 2.936895,3106.011963 
	C2.000000,3099.277344 2.000000,3092.554932 2.000000,3084.916016 
z"
        />
        <path
          fill="#05131D"
          opacity="1.000000"
          stroke="none"
          d="
M2393.577637,4021.273438 
	C2400.421631,4025.958984 2408.094727,4027.600098 2413.990723,4033.288086 
	C2381.291504,4034.000000 2348.583008,4034.000000 2314.937256,4034.000000 
	C2317.136230,4026.562744 2313.563477,4021.355469 2307.904541,4017.334961 
	C2304.769531,4015.107422 2300.938232,4013.860107 2296.710449,4012.085449 
	C2292.532959,4005.718750 2296.612305,4004.011963 2301.483154,4003.997803 
	C2308.506104,4003.977295 2315.302490,4005.469727 2321.483154,4009.258545 
	C2329.885010,4014.408691 2338.791992,4017.708496 2348.950684,4015.928467 
	C2352.322021,4015.337402 2355.692383,4015.991455 2358.525635,4017.911377 
	C2364.700439,4022.096191 2368.260498,4019.973389 2370.696533,4012.820312 
	C2380.186279,4010.542969 2386.973145,4014.752686 2393.577637,4021.273438 
z"
        />
        <path
          fill="#748B9F"
          opacity="1.000000"
          stroke="none"
          d="
M65.319855,2313.488281 
	C66.438957,2316.966553 68.287231,2317.784180 70.901688,2318.001465 
	C78.655174,2318.646484 86.188934,2321.003174 93.697739,2319.969971 
	C94.387344,2322.287598 93.569496,2323.178467 92.677528,2323.262695 
	C82.617355,2324.210693 82.614220,2324.177490 82.008621,2335.205566 
	C80.144852,2338.272217 77.534393,2339.044678 74.846901,2338.838135 
	C52.289612,2337.102295 29.684237,2337.060303 7.086279,2336.704346 
	C5.758795,2336.683594 4.563690,2335.917725 2.725073,2334.583496 
	C2.000000,2328.599854 2.000000,2323.199951 2.000000,2316.899902 
	C9.414783,2310.726074 17.888975,2310.665283 26.375105,2311.404541 
	C31.273758,2311.831055 36.099998,2312.327393 41.856327,2311.230469 
	C50.077759,2311.622314 57.581299,2309.630371 65.319855,2313.488281 
z"
        />
        <path
          fill="#4C6880"
          opacity="1.000000"
          stroke="none"
          d="
M3026.000000,2617.083984 
	C3017.573486,2616.232422 3009.275635,2613.692871 3000.512939,2613.983643 
	C2998.878662,2614.038086 2996.573730,2614.243164 2995.689209,2613.317139 
	C2988.429443,2605.718262 2978.846191,2608.514160 2970.498047,2608.360352 
	C2955.092529,2608.077148 2940.273193,2604.436768 2925.141846,2602.802979 
	C2916.249512,2601.843262 2907.487061,2599.293701 2898.452393,2600.063477 
	C2891.023193,2600.696777 2884.175049,2596.991943 2876.737061,2597.433594 
	C2886.468994,2593.218018 2896.746582,2593.195312 2906.841064,2594.356689 
	C2920.677490,2595.948486 2934.445557,2598.138672 2948.413330,2597.949463 
	C2954.494629,2597.866943 2959.963135,2600.812012 2966.778564,2601.009033 
	C2965.491943,2597.353760 2964.057373,2594.273438 2970.468750,2593.737305 
	C2980.502686,2592.897949 2990.319824,2592.886230 2999.770508,2596.102539 
	C3008.138672,2598.950928 3016.145508,2597.351562 3025.157715,2596.012695 
	C3026.000000,2602.722656 3026.000000,2609.445068 3026.000000,2617.083984 
z"
        />
        <path
          fill="#4C6880"
          opacity="1.000000"
          stroke="none"
          d="
M2943.476318,2652.683594 
	C2945.725830,2651.457764 2947.645752,2650.085938 2949.431885,2650.242676 
	C2962.087646,2651.353271 2974.934814,2649.610596 2987.423584,2652.386719 
	C2991.612305,2653.318115 2995.974365,2653.786133 2999.317627,2658.321533 
	C3002.044922,2662.020752 3008.089111,2660.399902 3012.485107,2659.328369 
	C3016.421387,2658.368408 3020.275146,2657.979004 3025.124512,2658.000732 
	C3026.000000,2666.713867 3026.000000,2675.427490 3026.000000,2685.070801 
	C3018.546631,2691.327881 3011.957031,2686.884521 3004.546387,2683.684082 
	C3001.264893,2678.331299 2997.431152,2676.568115 2991.736572,2676.901367 
	C2984.872803,2677.303467 2977.822998,2677.705811 2971.131104,2675.187744 
	C2967.709229,2673.900391 2964.267578,2672.584961 2962.918701,2667.912842 
	C2962.588623,2665.743896 2965.816406,2663.451172 2961.950439,2662.895996 
	C2958.083496,2662.340332 2954.216064,2661.786621 2950.457520,2660.731934 
	C2946.631104,2659.658203 2942.764893,2658.469727 2943.476318,2652.683594 
z"
        />
        <path
          fill="#10212F"
          opacity="1.000000"
          stroke="none"
          d="
M2991.679199,3287.477539 
	C2998.021729,3287.711670 3003.895264,3288.482178 3009.762451,3286.554199 
	C3014.609619,3284.960938 3019.284668,3289.020508 3025.155518,3287.997559 
	C3026.000000,3296.049072 3026.000000,3304.098145 3026.000000,3313.073730 
	C3021.186768,3317.865723 3016.428223,3314.893311 3011.846924,3313.604004 
	C3003.593506,3311.281982 2995.604736,3310.196533 2987.246826,3315.632812 
	C2981.077637,3316.463379 2976.137695,3314.862549 2970.693604,3312.170166 
	C2967.409424,3308.792725 2963.859375,3306.837646 2960.922607,3303.168457 
	C2960.369385,3301.518555 2960.317139,3300.531006 2960.670410,3298.831787 
	C2961.177490,3297.727783 2961.453369,3297.326660 2962.267334,3296.445068 
	C2970.658203,3295.864502 2965.046387,3288.071045 2969.402832,3285.738281 
	C2972.864502,3285.319824 2975.656494,3284.753662 2979.168213,3284.208496 
	C2980.795410,3284.934082 2981.699707,3285.644775 2983.427246,3285.962158 
	C2984.888916,3286.014160 2985.777832,3286.018555 2987.333496,3286.024658 
	C2989.191162,3286.046631 2990.276367,3286.381836 2991.679199,3287.477539 
z"
        />
        <path
          fill="#49544F"
          opacity="1.000000"
          stroke="none"
          d="
M2.000000,3888.933594 
	C12.359682,3887.520508 9.595481,3889.565430 13.709892,3879.310547 
	C14.338540,3877.809814 14.896157,3877.202393 16.426254,3876.898193 
	C18.649078,3877.292725 20.225792,3877.774902 22.441818,3876.745117 
	C24.422424,3876.248291 25.735126,3876.382080 27.497608,3877.474609 
	C30.369007,3880.258545 32.767986,3882.543213 35.075592,3884.916748 
	C36.913292,3886.806396 38.609558,3888.388916 40.160942,3884.689453 
	C40.739761,3883.309570 42.398808,3882.158203 42.986408,3883.622070 
	C45.237049,3889.229492 48.469406,3886.634277 51.753922,3885.193359 
	C55.907341,3883.371094 55.562000,3887.587891 57.612518,3889.699707 
	C58.319290,3893.656494 55.823624,3893.754395 52.650330,3893.707031 
	C44.677113,3897.063477 33.678493,3907.504883 29.798141,3914.910645 
	C28.874624,3916.672852 28.002089,3917.042236 26.046928,3917.012695 
	C18.459845,3916.897705 10.946280,3917.757324 2.793277,3919.856934 
	C2.000000,3909.955811 2.000000,3899.911621 2.000000,3888.933594 
z"
        />
        <path
          fill="#294159"
          opacity="1.000000"
          stroke="none"
          d="
M2985.161133,3315.089844 
	C2992.014404,3307.270996 2999.534912,3306.433350 3008.199463,3309.546143 
	C3013.460693,3311.435791 3019.005859,3312.534180 3025.211914,3313.993896 
	C3026.000000,3324.044189 3026.000000,3334.088379 3026.000000,3345.066406 
	C3018.813477,3351.486084 3010.720215,3349.333740 3001.938965,3348.526367 
	C2996.675537,3346.300049 2993.001953,3342.917480 2987.793701,3342.442871 
	C2984.270508,3342.121826 2982.153320,3339.429443 2981.379639,3334.990234 
	C2981.481445,3327.834473 2982.045410,3321.601807 2985.161133,3315.089844 
z"
        />
        <path
          fill="#8DA0AE"
          opacity="1.000000"
          stroke="none"
          d="
M2.000000,2210.856934 
	C10.910105,2209.512451 19.987362,2208.812256 28.710751,2208.895996 
	C41.892239,2209.022461 55.007881,2206.787354 68.164833,2208.130859 
	C70.192604,2208.338135 72.060013,2208.892090 73.720932,2210.338135 
	C73.607468,2213.190430 69.271378,2211.242188 69.264061,2214.922363 
	C76.351089,2217.463623 84.054153,2214.936035 91.339981,2216.436035 
	C87.996223,2219.381836 83.375534,2221.412598 74.992188,2221.813232 
	C67.811424,2222.156494 61.223915,2227.193848 53.675396,2224.300293 
	C53.030468,2224.053223 51.426918,2224.747803 51.127106,2225.399658 
	C48.081398,2232.021240 41.942505,2230.527100 37.223839,2229.722900 
	C25.953133,2227.802734 14.879725,2227.625732 2.863013,2229.969971 
	C2.000000,2229.111084 2.000000,2228.222168 2.000000,2226.666504 
	C2.751094,2225.046143 4.633114,2224.374512 2.401916,2222.388672 
	C2.000000,2218.571289 2.000000,2215.142578 2.000000,2210.856934 
z"
        />
        <path
          fill="#2B383B"
          opacity="1.000000"
          stroke="none"
          d="
M2.621195,1804.529785 
	C2.000000,1797.938843 2.000000,1791.877563 2.000000,1784.908203 
	C3.651672,1782.373779 5.331180,1780.776978 7.226291,1779.432129 
	C14.318787,1774.398438 19.354692,1775.687744 23.698915,1783.848022 
	C24.798283,1785.913086 25.232258,1788.681885 28.450804,1786.971191 
	C30.679863,1785.786499 31.488235,1783.560059 31.276281,1781.058960 
	C31.150194,1779.571167 30.194330,1778.813232 28.778435,1778.437622 
	C24.683720,1777.351807 17.031103,1779.908203 19.992161,1771.121094 
	C21.628704,1766.264404 32.507092,1764.203369 37.827942,1767.045288 
	C40.489632,1768.466797 42.212395,1771.072998 44.665783,1772.755005 
	C47.704643,1774.838135 50.872162,1776.645142 55.506935,1775.043213 
	C58.313728,1774.782471 60.139263,1775.480469 62.298927,1777.272461 
	C65.215569,1792.748169 64.894936,1793.048218 50.295406,1796.702637 
	C41.313046,1798.950806 35.618984,1806.230469 28.005756,1811.966064 
	C17.736795,1814.977173 10.520638,1809.915039 2.621195,1804.529785 
z"
        />
        <path
          fill="#5D758A"
          opacity="1.000000"
          stroke="none"
          d="
M109.353119,2546.107910 
	C108.422180,2553.050293 103.189522,2555.448975 96.851288,2555.869141 
	C92.598129,2556.151123 88.216019,2556.623535 84.061005,2555.020996 
	C79.640182,2553.316162 75.836563,2554.506104 71.654129,2557.265137 
	C77.548637,2559.090332 83.338089,2556.555664 89.747612,2559.697266 
	C83.370781,2563.073730 76.652893,2564.143799 71.633545,2562.985352 
	C61.988724,2560.759766 53.173187,2563.659912 44.159626,2564.688477 
	C33.884800,2565.860840 23.865171,2566.617676 13.675605,2564.451660 
	C10.488604,2563.774170 7.083679,2564.121826 2.889252,2564.000000 
	C2.000000,2560.571289 2.000000,2557.142578 2.000000,2552.856934 
	C5.421478,2548.480469 9.857367,2548.871338 14.131351,2549.032471 
	C31.681360,2549.692871 49.165146,2547.313477 66.712555,2547.962891 
	C68.692436,2548.036133 70.697983,2547.972168 73.273369,2546.477539 
	C76.047394,2543.516357 78.205666,2545.319580 80.341133,2545.729492 
	C89.699570,2547.525635 99.080437,2547.764160 109.353119,2546.107910 
z"
        />
        <path
          fill="#6E8AA1"
          opacity="1.000000"
          stroke="none"
          d="
M3026.000000,2241.250000 
	C3020.485352,2241.117676 3015.127441,2240.587158 3009.400146,2241.701172 
	C3004.326416,2242.688232 2998.793945,2242.705322 2993.597412,2240.884766 
	C2989.721924,2239.527100 2985.469238,2239.262939 2981.752197,2240.262695 
	C2970.169922,2243.377930 2958.831055,2239.067627 2947.381348,2239.851807 
	C2947.055908,2239.873779 2946.716309,2239.800781 2946.393555,2239.727295 
	C2940.894287,2238.472900 2940.515869,2236.611572 2945.253418,2231.293945 
	C2940.406738,2229.289795 2936.163330,2232.409912 2931.751953,2232.127197 
	C2915.535400,2231.087646 2899.233154,2233.396240 2882.999023,2230.276611 
	C2877.304199,2229.182129 2871.048340,2229.115479 2865.214844,2231.250732 
	C2864.290527,2231.589111 2863.253418,2231.894775 2862.298828,2231.821533 
	C2859.558350,2231.611084 2855.162109,2233.351318 2854.948242,2229.181396 
	C2854.729492,2224.914795 2859.203857,2226.108887 2861.887939,2226.061279 
	C2870.876221,2225.901855 2879.870361,2225.909424 2888.859863,2226.027100 
	C2896.715332,2226.130127 2904.452148,2223.938965 2912.460205,2225.808350 
	C2917.736084,2227.039795 2920.673584,2222.482178 2923.593018,2218.779053 
	C2927.808350,2213.432373 2929.647217,2213.057373 2932.987793,2218.711182 
	C2936.620361,2224.859131 2941.389648,2226.300293 2947.932373,2226.114258 
	C2954.746094,2225.920410 2961.690918,2225.187012 2969.516846,2227.073975 
	C2965.926025,2231.929443 2960.558838,2228.369873 2956.427734,2232.289551 
	C2968.173096,2235.967285 2979.523682,2232.822266 2990.359619,2234.315918 
	C3001.654297,2235.872803 3012.859619,2235.828613 3025.066406,2235.995850 
	C3026.000000,2237.500000 3026.000000,2239.000000 3026.000000,2241.250000 
z"
        />
        <path
          fill="#50677C"
          opacity="1.000000"
          stroke="none"
          d="
M2.000000,2522.666504 
	C19.445047,2523.043213 35.956474,2517.026123 53.021557,2515.133545 
	C56.621010,2514.734131 60.232101,2514.328369 63.842724,2513.927490 
	C74.549530,2512.738037 85.518120,2512.201660 93.810463,2503.727783 
	C96.161873,2501.325195 99.415871,2501.807373 102.330429,2502.176514 
	C107.238480,2502.798584 112.237442,2503.334717 116.952049,2504.735352 
	C131.614456,2509.091309 146.286407,2508.774902 161.210281,2506.363037 
	C166.864410,2505.449219 172.851715,2505.197998 178.817261,2508.670166 
	C172.425232,2510.334717 166.114853,2512.834229 159.622131,2513.469238 
	C149.763916,2514.433105 139.776031,2514.887451 129.872604,2513.017578 
	C123.028595,2511.725098 116.188309,2510.510254 109.702133,2514.780518 
	C105.930779,2517.263916 101.108307,2514.925049 96.553810,2517.454590 
	C93.891541,2519.955811 91.413521,2520.954834 87.722290,2520.933838 
	C84.201088,2520.805908 81.608498,2521.336182 78.315842,2522.488281 
	C64.907181,2522.939697 52.276787,2523.206787 39.818954,2529.155762 
	C34.359398,2531.510986 30.067278,2527.002197 24.558277,2528.689453 
	C16.934082,2528.443359 9.924728,2530.372070 2.680884,2526.543701 
	C2.000000,2525.111084 2.000000,2524.222168 2.000000,2522.666504 
z"
        />
        <path
          fill="#395166"
          opacity="1.000000"
          stroke="none"
          d="
M2878.640625,3162.393066 
	C2878.408203,3160.321045 2878.920166,3158.204346 2881.004639,3158.940186 
	C2886.115479,3160.743896 2891.636963,3159.055664 2896.676270,3161.136230 
	C2897.859863,3161.625244 2899.479492,3162.118896 2900.531494,3161.696533 
	C2908.692139,3158.419678 2916.226562,3161.877930 2923.667725,3164.072510 
	C2930.732178,3166.155762 2938.164307,3165.364258 2945.124268,3167.748047 
	C2946.572510,3168.243896 2949.011963,3168.334717 2949.927490,3167.475342 
	C2954.401611,3163.274902 2959.321777,3165.726562 2964.053955,3166.073242 
	C2977.393066,3167.050049 2990.847900,3166.715820 3003.991943,3169.948242 
	C3010.708496,3171.599854 3017.559570,3172.704346 3025.174805,3174.027832 
	C3026.000000,3176.133301 3026.000000,3178.266846 3026.000000,3181.200195 
	C3009.385010,3183.659180 2993.866943,3177.810791 2977.905518,3174.987793 
	C2973.991455,3174.295898 2970.053955,3173.734863 2966.125977,3173.123047 
	C2965.475830,3173.021729 2964.773193,3172.781250 2964.169922,3172.923096 
	C2948.007812,3176.720947 2948.032959,3176.635010 2933.773193,3173.020996 
	C2928.968018,3171.803467 2923.984619,3170.318115 2919.127930,3170.463867 
	C2906.418945,3170.845703 2895.034668,3166.277100 2882.688477,3162.221436 
	C2881.134521,3162.786133 2880.228271,3162.983398 2878.640625,3162.393066 
z"
        />
        <path
          fill="#A9AEB3"
          opacity="1.000000"
          stroke="none"
          d="
M66.752533,1645.183838 
	C56.807304,1646.534912 47.764782,1643.123779 38.432247,1643.942993 
	C32.637093,1644.451660 34.300716,1639.319458 33.474892,1636.172852 
	C30.864706,1637.503052 33.676785,1640.828003 31.156019,1642.189331 
	C28.869947,1640.647583 30.814598,1637.861816 29.383366,1636.076660 
	C28.759588,1635.344238 28.265543,1635.472168 28.100082,1636.490356 
	C27.888744,1637.791016 27.774479,1639.108032 27.637730,1640.420044 
	C27.379362,1642.898926 25.860842,1643.804810 23.552710,1643.652954 
	C21.640493,1643.527222 20.494122,1642.391479 20.241161,1640.570190 
	C19.928144,1638.316650 19.835182,1636.032715 19.624580,1633.489258 
	C18.769682,1634.086792 18.010763,1634.333130 17.941303,1634.707397 
	C16.263767,1643.745605 16.276999,1643.741577 7.715961,1643.821411 
	C6.390295,1643.833740 5.065440,1643.933350 2.870105,1643.996216 
	C2.000000,1638.599976 2.000000,1633.199951 2.000000,1626.899902 
	C7.418406,1628.772705 12.576601,1630.567505 18.010750,1625.499146 
	C21.120367,1622.598755 25.147032,1623.996460 28.100517,1626.236938 
	C34.916496,1631.408081 42.421986,1630.918823 49.805622,1628.921875 
	C55.621365,1627.348999 60.737534,1628.842407 64.500969,1632.295410 
	C67.771912,1635.296631 69.871849,1635.096924 73.868843,1634.387695 
	C81.782967,1632.983398 89.397034,1629.771240 97.598984,1630.022827 
	C99.879677,1630.092651 102.336731,1629.776367 103.744995,1633.202881 
	C96.980385,1638.627319 87.658684,1638.969849 81.394455,1646.345459 
	C75.941704,1649.145630 72.048836,1644.197876 66.752533,1645.183838 
z"
        />
        <path
          fill="#152533"
          opacity="1.000000"
          stroke="none"
          d="
M2.000000,3324.750000 
	C11.180268,3322.622070 20.377254,3321.645508 29.696917,3321.807861 
	C31.887684,3321.845947 34.187962,3320.734131 36.288307,3319.811035 
	C41.240391,3317.633545 46.635128,3316.603027 51.715248,3317.252441 
	C56.597267,3317.876953 60.716122,3317.428711 65.179321,3315.565918 
	C72.610588,3312.464844 75.132217,3313.207764 78.913925,3320.137451 
	C81.347420,3324.596436 84.431465,3326.351562 89.288666,3326.041504 
	C93.254463,3325.787842 97.202591,3326.072510 101.106453,3324.709961 
	C104.590630,3323.494141 108.342575,3323.665527 111.943748,3324.486328 
	C113.562943,3324.855469 115.069962,3325.842529 114.809616,3327.909668 
	C114.597839,3329.591309 113.105225,3329.750732 111.824295,3329.899414 
	C105.677933,3330.612793 99.859756,3331.640137 93.280937,3330.686035 
	C79.879379,3328.742188 66.318321,3329.668213 53.038403,3334.162598 
	C45.616383,3336.674561 37.092972,3339.740967 30.482637,3331.216309 
	C28.960411,3329.253174 26.106548,3330.052979 23.824366,3330.028320 
	C17.172564,3329.956299 10.519551,3329.996094 2.933507,3329.996094 
	C2.000000,3328.500000 2.000000,3327.000000 2.000000,3324.750000 
z"
        />
        <path
          fill="#5D758A"
          opacity="1.000000"
          stroke="none"
          d="
M2.000000,2594.856934 
	C12.602274,2594.252930 23.290873,2592.866455 33.758717,2595.672363 
	C53.762592,2591.645752 74.285530,2596.354248 95.127274,2592.076172 
	C96.046867,2595.483398 95.084290,2598.004883 91.028358,2597.981201 
	C88.029839,2597.963623 85.016281,2598.215576 82.034630,2597.999268 
	C66.306282,2596.860107 51.128922,2600.557617 35.125313,2602.739014 
	C38.085026,2607.125732 42.015690,2605.745361 45.391411,2605.964600 
	C49.697636,2606.244629 54.063431,2605.965088 58.316792,2606.553711 
	C60.499142,2606.855713 61.551861,2608.686523 59.590435,2610.954346 
	C54.503899,2616.835205 47.093613,2618.034912 41.040291,2613.365234 
	C39.096493,2611.865723 37.885006,2610.200195 35.129997,2611.486816 
	C25.131548,2616.155029 14.286837,2617.696533 2.798521,2619.895508 
	C2.000000,2617.866699 2.000000,2615.733154 2.000000,2612.799805 
	C5.196285,2608.505127 9.651314,2609.013184 13.665167,2608.045654 
	C10.363127,2606.348389 6.431556,2608.698730 2.522936,2606.452637 
	C2.000000,2602.571289 2.000000,2599.142578 2.000000,2594.856934 
z"
        />
        <path
          fill="#637E95"
          opacity="1.000000"
          stroke="none"
          d="
M3025.196533,2565.612793 
	C3026.000000,2569.428711 3026.000000,2572.857422 3026.000000,2577.143066 
	C3022.212646,2581.570068 3017.536377,2581.664795 3012.888184,2580.938965 
	C2990.224854,2577.401367 2967.352539,2578.658203 2943.674316,2577.817871 
	C2927.909912,2574.500000 2912.683838,2573.660889 2897.664307,2571.383057 
	C2893.552490,2570.759277 2888.728027,2571.392090 2887.234619,2565.046387 
	C2890.437744,2560.538574 2894.854004,2560.698486 2899.844238,2559.861328 
	C2917.825928,2561.515869 2935.152344,2559.729736 2953.009766,2563.317871 
	C2958.794922,2565.917236 2963.560059,2563.294189 2968.397217,2563.117432 
	C2987.108643,2562.433594 3005.696045,2565.315674 3025.196533,2565.612793 
z"
        />
        <path
          fill="#4C6174"
          opacity="1.000000"
          stroke="none"
          d="
M108.352829,2689.255859 
	C108.447021,2694.746094 111.491013,2695.913818 115.591995,2696.034180 
	C118.622322,2696.122803 121.964020,2695.217529 125.979324,2698.463379 
	C116.399307,2701.307617 107.968857,2699.399170 98.790771,2699.887695 
	C95.149117,2696.813477 91.725594,2696.141602 87.958717,2697.055908 
	C76.148216,2699.922607 63.736454,2699.632568 52.205517,2704.098145 
	C48.475563,2705.542969 44.393108,2705.094482 39.752457,2703.613281 
	C30.216610,2698.902100 20.950274,2701.158936 11.877013,2701.569824 
	C8.937963,2701.703125 6.093385,2702.801758 2.664753,2700.505371 
	C2.000000,2698.500000 2.000000,2697.000000 2.000000,2694.750000 
	C4.160882,2691.348145 6.784688,2689.395996 10.288826,2689.108398 
	C27.988325,2687.655273 45.484707,2682.973877 63.471924,2685.941406 
	C67.357079,2686.582520 71.298996,2686.541992 75.028038,2684.467041 
	C84.016594,2679.464844 93.781120,2678.823975 104.599533,2680.156250 
	C108.226082,2682.439697 109.033157,2685.193115 108.352829,2689.255859 
z"
        />
        <path
          fill="#434F51"
          opacity="1.000000"
          stroke="none"
          d="
M56.421432,1775.501953 
	C55.614853,1778.757324 54.222538,1780.184570 51.272484,1779.443726 
	C46.339684,1778.205078 42.271595,1775.815918 38.940407,1771.749634 
	C35.144260,1767.115723 25.226366,1767.417725 21.574991,1772.010376 
	C20.358767,1773.540161 20.813198,1775.182617 23.010393,1775.638428 
	C23.951059,1775.833374 25.025768,1775.862549 25.941679,1775.610840 
	C29.410347,1774.657593 33.134727,1772.626953 35.295330,1777.460571 
	C36.641075,1780.470947 33.446445,1788.472412 30.140533,1790.075562 
	C28.121702,1791.054565 26.595573,1793.193604 23.849695,1792.974609 
	C20.950815,1791.163330 22.172800,1787.614258 20.820633,1785.075073 
	C19.893301,1783.333862 21.695589,1780.010620 17.892847,1779.984619 
	C15.045313,1779.965210 11.523432,1776.456299 9.568919,1781.657104 
	C8.531457,1784.417603 5.764072,1783.744873 2.764739,1783.980347 
	C2.000000,1772.623901 2.000000,1761.247925 2.000000,1748.935913 
	C4.941895,1745.619873 8.009810,1746.234497 9.294787,1749.296997 
	C13.705491,1759.809570 21.830984,1757.053833 30.398050,1755.375000 
	C36.546070,1754.979126 40.570969,1759.333374 46.471321,1760.106934 
	C47.911835,1760.842041 48.619801,1761.412964 49.609993,1762.646240 
	C50.467422,1763.901123 50.931427,1764.625732 51.769554,1765.855957 
	C53.633015,1768.381592 55.250431,1770.313110 56.639160,1773.152344 
	C56.855049,1774.180054 56.855942,1774.606567 56.421432,1775.501953 
z"
        />
        <path
          fill="#627A90"
          opacity="1.000000"
          stroke="none"
          d="
M2.000000,2334.750000 
	C28.110226,2333.628418 54.226795,2333.008789 81.130966,2336.005371 
	C84.960747,2336.023682 87.953400,2335.770996 91.316406,2337.696045 
	C96.328140,2340.798828 102.876678,2336.563232 106.124252,2343.250977 
	C98.554909,2347.853516 90.689659,2346.160400 83.100639,2345.128662 
	C79.687805,2344.664307 76.733971,2344.669678 72.865067,2345.860840 
	C57.742680,2351.252930 42.510971,2351.248047 27.600285,2352.748535 
	C19.660755,2353.547607 11.186645,2355.511963 2.701232,2350.562988 
	C2.000000,2350.000000 2.000000,2349.000000 2.000000,2348.500000 
	C9.149192,2340.183350 18.504967,2344.622314 26.670654,2343.578125 
	C18.905405,2342.737305 10.674131,2344.461670 2.507224,2340.464844 
	C2.000000,2338.500000 2.000000,2337.000000 2.000000,2334.750000 
z"
        />
        <path
          fill="#05131D"
          opacity="1.000000"
          stroke="none"
          d="
M2224.460449,4026.495605 
	C2223.979736,4026.031250 2223.866943,4025.120850 2223.803711,4024.668457 
	C2225.788574,4022.678223 2225.669922,4021.044189 2224.096680,4019.194336 
	C2221.222168,4015.813721 2217.749512,4013.389404 2213.478027,4012.112549 
	C2210.872070,4011.333984 2208.043213,4010.512451 2209.005615,4006.865723 
	C2209.921143,4003.396484 2214.197510,4001.616699 2217.901123,4003.041016 
	C2221.069336,4004.259521 2223.874268,4006.123779 2225.502686,4009.187744 
	C2229.060791,4015.883301 2234.955566,4019.193115 2241.981689,4020.969727 
	C2247.088379,4022.260986 2252.353271,4022.779785 2257.193115,4025.288330 
	C2260.844238,4027.180664 2264.385986,4025.077393 2267.499023,4023.198242 
	C2274.837402,4018.768555 2282.149414,4018.793457 2289.013184,4023.655273 
	C2293.751953,4027.011719 2301.093262,4025.978760 2303.901367,4033.287598 
	C2283.291504,4034.000000 2262.583008,4034.000000 2240.937256,4034.000000 
	C2237.277832,4032.253418 2233.937256,4033.102539 2230.443115,4031.691895 
	C2227.599121,4030.608643 2226.452881,4028.589600 2224.460449,4026.495605 
z"
        />
        <path
          fill="#859CAF"
          opacity="1.000000"
          stroke="none"
          d="
M3026.000000,2027.066284 
	C3020.234619,2026.652100 3013.868896,2026.406494 3008.856934,2023.675659 
	C3002.662598,2020.300659 2995.641602,2018.734009 2989.933350,2014.727539 
	C2985.685059,2011.745850 2981.524902,2009.654907 2976.280029,2009.965210 
	C2973.960205,2010.102417 2971.600098,2009.993896 2969.694824,2008.376709 
	C2968.207031,2007.113770 2966.064697,2005.635986 2967.194824,2003.578735 
	C2968.398682,2001.386963 2970.712646,2002.259277 2972.320557,2003.563843 
	C2975.275879,2005.961792 2978.124268,2006.482666 2981.227295,2003.887695 
	C2981.967041,2003.269043 2983.248291,2002.314087 2983.770508,2002.567139 
	C2991.236816,2006.183350 2997.374268,1999.644531 3005.183105,2000.002197 
	C3007.042480,1998.916626 3008.136963,1997.885742 3009.623535,1996.449951 
	C3014.764648,1996.046997 3019.495605,1996.031006 3025.113281,1996.007446 
	C3026.000000,2006.044189 3026.000000,2016.088379 3026.000000,2027.066284 
z"
        />
        <path
          fill="#243B51"
          opacity="1.000000"
          stroke="none"
          d="
M2934.288086,2994.370605 
	C2933.710449,2990.631348 2931.262939,2989.613770 2928.874756,2988.584717 
	C2927.147705,2987.840332 2925.012695,2987.765381 2924.037598,2984.948242 
	C2937.411133,2984.035400 2950.812256,2986.964600 2964.413574,2986.236328 
	C2967.350342,2986.079102 2970.255859,2987.439453 2971.853516,2991.247559 
	C2976.991699,2992.087646 2982.608398,2991.366455 2980.961670,2999.550293 
	C2980.695312,3000.873535 2982.210449,3001.783447 2983.639893,3001.812256 
	C2992.000732,3001.980713 3000.387451,3002.476318 3008.720947,3001.481445 
	C3008.288086,2995.710938 3002.200684,2997.621338 3000.871338,2992.359375 
	C3008.944092,2994.144531 3016.565186,2994.176270 3025.094238,2994.019531 
	C3026.000000,2999.400146 3026.000000,3004.800049 3026.000000,3011.100098 
	C3021.258057,3012.693115 3016.752930,3011.058350 3012.554199,3009.585938 
	C3000.724121,3005.436523 2988.950684,3004.608154 2976.832764,3007.781982 
	C2974.947754,3008.275635 2972.907227,3008.130615 2970.549316,3006.518066 
	C2968.622803,3001.864502 2965.148438,3001.412598 2961.518066,3000.529785 
	C2952.629150,2998.369141 2943.338379,2998.177002 2934.288086,2994.370605 
z"
        />
        <path
          fill="#2F465E"
          opacity="1.000000"
          stroke="none"
          d="
M3026.000000,2989.143066 
	C3015.056885,2987.336670 3004.256104,2984.028809 2992.912598,2983.230225 
	C2990.922119,2983.090332 2989.075439,2982.963379 2987.061279,2983.256104 
	C2980.159424,2984.259033 2973.748047,2983.356689 2966.756104,2981.299805 
	C2957.978516,2978.718018 2948.680420,2977.531494 2939.941895,2974.287842 
	C2931.219238,2971.050293 2922.208496,2968.825439 2912.537598,2966.553955 
	C2910.777100,2964.566406 2910.491455,2962.944580 2911.703857,2960.509766 
	C2915.012207,2958.168213 2917.656250,2958.297363 2920.809570,2959.168945 
	C2932.591797,2962.424805 2944.471191,2965.304932 2955.920166,2969.689453 
	C2970.011230,2975.086182 2985.035889,2975.628418 2999.707764,2978.060303 
	C3007.983398,2979.432617 3016.100830,2978.156738 3025.144775,2978.012695 
	C3026.000000,2981.428711 3026.000000,2984.857422 3026.000000,2989.143066 
z"
        />
        <path
          fill="#172837"
          opacity="1.000000"
          stroke="none"
          d="
M2.000000,3212.888184 
	C12.065867,3211.958252 22.131735,3211.916504 33.135902,3211.868652 
	C37.766033,3217.286621 42.852295,3219.216553 49.375923,3218.813965 
	C54.981823,3218.468262 60.611866,3218.311279 66.230606,3218.024658 
	C69.705437,3217.847412 73.074104,3218.581787 74.446640,3222.237305 
	C75.966187,3226.283936 72.716446,3228.120850 70.114105,3230.166260 
	C63.577160,3235.303711 55.859558,3234.766602 47.458946,3234.822021 
	C43.541637,3235.113770 40.419949,3235.156006 36.560326,3234.656494 
	C32.942932,3233.742188 30.191662,3233.848633 26.961651,3235.924316 
	C21.626808,3237.435791 16.986378,3237.474121 11.620332,3236.006348 
	C7.945062,3233.892822 6.058642,3230.810303 2.539011,3228.499512 
	C2.000000,3223.258789 2.000000,3218.517822 2.000000,3212.888184 
z"
        />
        <path
          fill="#334D63"
          opacity="1.000000"
          stroke="none"
          d="
M2953.844727,3024.766113 
	C2964.819336,3023.607178 2975.716309,3024.551514 2986.414307,3023.350342 
	C2987.462402,3019.049072 2982.570312,3019.728027 2982.264404,3016.939453 
	C2982.723633,3016.655273 2983.171143,3016.160889 2983.670654,3016.101318 
	C2991.069824,3015.216797 2998.153809,3017.729248 3005.367432,3018.573242 
	C3011.650146,3019.308350 3017.882324,3019.905273 3025.093994,3020.005371 
	C3026.000000,3026.061279 3026.000000,3032.122314 3026.000000,3039.091797 
	C3024.713135,3041.075684 3023.404297,3042.114014 3020.885254,3042.617676 
	C3017.841309,3043.041504 3015.633301,3042.909180 3012.623535,3042.414551 
	C2999.703125,3040.078857 2988.277832,3034.662598 2974.901855,3034.467285 
	C2967.227051,3031.593994 2957.829590,3034.742676 2953.844727,3024.766113 
z"
        />
        <path
          fill="#4B6377"
          opacity="1.000000"
          stroke="none"
          d="
M51.196571,2459.520508 
	C52.532520,2463.546387 55.056065,2463.848877 57.894073,2463.967529 
	C72.871490,2464.594482 87.682220,2462.775391 102.445129,2460.727295 
	C109.101524,2459.803955 115.690460,2460.073486 122.318665,2460.203125 
	C123.694618,2460.230225 124.951668,2460.906006 125.263893,2462.399170 
	C125.698311,2464.476807 124.359650,2465.406982 122.605881,2465.821533 
	C112.584106,2468.190430 102.365540,2469.519775 92.129539,2469.851318 
	C77.833916,2470.314941 63.509357,2470.058594 49.200111,2469.878174 
	C36.543133,2469.718750 24.023689,2470.440918 11.689799,2473.578613 
	C9.165281,2474.220703 6.397352,2473.906250 2.870810,2474.016357 
	C2.000000,2471.866699 2.000000,2469.733154 2.000000,2466.799805 
	C17.579765,2459.418457 33.782459,2457.074463 51.196571,2459.520508 
z"
        />
        <path
          fill="#C4BDAE"
          opacity="1.000000"
          stroke="none"
          d="
M49.959026,1761.547241 
	C43.517456,1761.617432 37.371090,1762.671143 32.681591,1756.375244 
	C26.600222,1756.481323 21.515200,1756.170532 19.036140,1749.460449 
	C20.703350,1745.989868 23.422520,1746.143677 26.042360,1746.040527 
	C23.829563,1745.942139 21.655697,1746.085327 18.963390,1747.439697 
	C12.678385,1750.120239 8.209955,1746.242554 2.626499,1744.491211 
	C2.000000,1739.258911 2.000000,1734.517700 2.000000,1728.888306 
	C4.284595,1728.074707 6.823380,1727.632812 7.836983,1730.543213 
	C8.565273,1732.634277 8.217988,1735.828491 11.321925,1735.823975 
	C15.967381,1735.817261 20.372398,1738.203735 25.022984,1737.110474 
	C27.153641,1736.609619 27.836580,1734.511963 28.677767,1732.677124 
	C29.578522,1730.712646 29.457006,1728.075073 31.953012,1726.929932 
	C36.231815,1729.177002 31.947989,1734.116455 35.384640,1736.517334 
	C38.792370,1735.264771 37.603130,1732.164673 38.014610,1729.771118 
	C38.320255,1727.993530 38.880024,1726.412720 40.950470,1726.540405 
	C42.960098,1726.664551 44.230606,1728.463501 43.398647,1729.992065 
	C39.458469,1737.231689 46.004341,1737.988159 50.080757,1741.137329 
	C51.292446,1743.048462 51.456787,1744.511597 51.295410,1746.558228 
	C51.316002,1747.432251 51.352440,1747.777954 51.642097,1749.129883 
	C51.963581,1752.847534 52.031841,1755.558960 52.103172,1759.084839 
	C51.811848,1760.661865 51.281742,1761.202148 49.959026,1761.547241 
z"
        />
        <path
          fill="#0D1C2A"
          opacity="1.000000"
          stroke="none"
          d="
M438.937256,4034.000000 
	C438.081543,4031.508057 439.028717,4028.925781 441.153442,4028.126709 
	C452.200623,4023.974121 461.121674,4014.055908 474.431122,4015.962158 
	C481.603760,4016.989258 488.874176,4017.359863 496.024658,4018.501221 
	C498.382324,4018.877441 501.288666,4019.235107 503.044342,4022.089355 
	C499.084290,4026.648682 495.072571,4030.760742 488.273499,4030.115723 
	C486.511322,4029.948730 485.089661,4030.823242 484.174744,4033.275635 
	C469.291504,4034.000000 454.583008,4034.000000 438.937256,4034.000000 
z"
        />
        <path
          fill="#2F4457"
          opacity="1.000000"
          stroke="none"
          d="
M106.902077,2682.904053 
	C97.830933,2680.495850 89.891083,2684.332031 81.650269,2684.214111 
	C80.781853,2684.201660 79.452293,2684.772217 79.096329,2685.463135 
	C75.340904,2692.752930 68.317963,2690.817627 63.307133,2689.471924 
	C50.222172,2685.957520 37.650105,2689.381836 25.082888,2691.018311 
	C17.961643,2691.946045 10.432582,2690.437256 2.818014,2693.896973 
	C2.000000,2691.866699 2.000000,2689.733154 2.000000,2686.799805 
	C6.260479,2681.247803 11.986967,2679.307129 17.963737,2679.196045 
	C41.012970,2678.766846 64.059952,2678.634766 87.545822,2673.417480 
	C92.629608,2674.695557 96.917953,2675.436279 102.148178,2675.103027 
	C104.031067,2675.085938 105.107323,2675.078857 106.992210,2675.086426 
	C108.890053,2675.114746 109.979004,2675.140381 111.898605,2675.216553 
	C114.455978,2675.358887 116.046021,2675.879150 118.092010,2677.443359 
	C119.104027,2679.193604 119.147972,2680.331543 118.307983,2682.202637 
	C114.498947,2685.065674 111.105064,2685.204590 106.902077,2682.904053 
z"
        />
        <path
          fill="#50677C"
          opacity="1.000000"
          stroke="none"
          d="
M95.276352,2521.854004 
	C106.905617,2524.069092 117.432365,2522.536865 128.361755,2521.022705 
	C136.644974,2519.875000 146.655853,2520.241943 156.083923,2526.092773 
	C151.028671,2528.936768 146.870544,2528.263184 142.878647,2527.241211 
	C135.882874,2525.449707 129.272324,2525.597656 122.473930,2528.553467 
	C115.550323,2531.564209 107.850716,2530.810547 100.827766,2529.484131 
	C94.720726,2528.330811 89.734459,2529.048828 84.800201,2532.175293 
	C82.731628,2533.486328 80.575356,2534.147461 78.201302,2534.014648 
	C65.321732,2533.292480 52.447468,2534.848145 39.682556,2534.738525 
	C27.799881,2534.636475 15.802181,2534.575928 2.936125,2534.002930 
	C2.000000,2533.111084 2.000000,2532.222168 2.000000,2530.666504 
	C10.352198,2525.303223 19.026644,2529.775146 28.374851,2529.156738 
	C31.449755,2529.582520 33.657478,2530.070068 36.758522,2529.896973 
	C50.362740,2524.602051 64.104698,2524.309570 78.268936,2521.991943 
	C80.624802,2521.581543 82.156174,2521.108887 84.547348,2521.100830 
	C88.401321,2521.195068 91.515060,2520.643799 95.276352,2521.854004 
z"
        />
        <path
          fill="#23394D"
          opacity="1.000000"
          stroke="none"
          d="
M3025.473633,3057.481445 
	C3026.000000,3059.500000 3026.000000,3061.000000 3026.000000,3063.250000 
	C3015.770752,3067.736816 3005.565918,3071.741699 2994.047363,3066.302734 
	C2992.636719,3063.814941 2993.597656,3060.846680 2990.474121,3060.181885 
	C2979.591064,3057.865234 2969.995605,3051.256836 2958.510498,3050.820312 
	C2952.905273,3050.607422 2947.381104,3049.551514 2942.992188,3044.295898 
	C2941.565430,3035.582764 2947.183105,3034.949707 2952.607666,3035.400635 
	C2959.085205,3035.938721 2965.556641,3037.018311 2972.706787,3038.458496 
	C2973.971191,3038.569336 2974.466553,3038.650635 2975.639160,3039.028809 
	C2979.148682,3042.398438 2982.013916,3045.410400 2987.458252,3045.064209 
	C2997.083008,3044.909180 3005.783936,3046.015137 3014.866211,3049.412598 
	C3018.469971,3052.441406 3022.349365,3053.864990 3025.473633,3057.481445 
z"
        />
        <path
          fill="#445E74"
          opacity="1.000000"
          stroke="none"
          d="
M3026.000000,2565.143066 
	C3016.322510,2569.767334 3006.181641,2568.553467 2996.465820,2567.364258 
	C2984.818359,2565.938721 2973.420410,2565.125000 2961.895020,2567.549805 
	C2959.609375,2568.030762 2957.272217,2568.193848 2954.577637,2566.378174 
	C2953.291016,2562.659180 2955.432129,2560.926514 2957.993164,2558.687988 
	C2970.472412,2557.289551 2982.243652,2557.377441 2992.113037,2559.585205 
	C2987.508789,2557.647705 2980.860352,2557.912109 2974.229248,2557.986328 
	C2970.179443,2558.031494 2964.399658,2557.986816 2964.500244,2553.560791 
	C2964.622314,2548.173096 2970.369629,2548.744873 2975.339355,2549.687256 
	C2982.693848,2550.032471 2989.421387,2550.035889 2997.063965,2550.058105 
	C3004.747070,2552.114258 3012.231445,2550.022949 3019.376709,2553.762207 
	C3021.525879,2553.874756 3023.017822,2553.900635 3025.254883,2553.963379 
	C3026.000000,2557.428711 3026.000000,2560.857422 3026.000000,2565.143066 
z"
        />
        <path
          fill="#6F7981"
          opacity="1.000000"
          stroke="none"
          d="
M2.000000,3708.937256 
	C6.572819,3707.491943 8.941955,3710.117920 9.611762,3714.102539 
	C10.378420,3718.662354 12.076753,3722.987061 12.100109,3727.769531 
	C12.136740,3735.269531 12.434575,3735.542725 19.742308,3735.905273 
	C24.307121,3736.131348 28.081808,3737.892578 31.794624,3740.117676 
	C33.359138,3741.055176 34.121361,3742.552246 33.101986,3744.289062 
	C32.208843,3745.811035 30.785370,3745.133301 29.567741,3744.677002 
	C28.639877,3744.329102 27.667889,3743.947021 26.902159,3743.343506 
	C22.933901,3740.215332 18.981312,3738.318604 14.543490,3742.592041 
	C9.925341,3747.038574 8.904387,3753.124268 11.972361,3758.864014 
	C12.596081,3760.030762 13.398933,3761.099854 14.069919,3762.243164 
	C16.176447,3765.832275 15.693264,3768.409180 11.420839,3769.967529 
	C8.654602,3770.976807 6.077777,3772.505371 2.708016,3773.900635 
	C2.000000,3752.624756 2.000000,3731.249756 2.000000,3708.937256 
z"
        />
        <path
          fill="#3D494C"
          opacity="1.000000"
          stroke="none"
          d="
M2.000000,1804.937256 
	C6.146254,1804.093750 10.158447,1803.483032 13.398383,1807.682007 
	C16.596470,1811.826660 21.946697,1809.485840 27.195152,1810.175537 
	C29.302103,1811.513184 29.470961,1813.014893 28.503139,1815.291748 
	C18.177498,1817.067627 17.809130,1826.911743 12.801326,1832.492676 
	C14.966785,1835.613525 14.341430,1838.549927 13.661134,1842.258423 
	C11.953608,1851.567017 12.527888,1851.672363 2.779859,1853.964722 
	C2.000000,1837.958130 2.000000,1821.916382 2.000000,1804.937256 
z"
        />
        <path
          fill="#546D83"
          opacity="1.000000"
          stroke="none"
          d="
M90.774124,2676.095215 
	C84.269684,2675.463379 78.708946,2677.209717 73.439323,2678.715820 
	C62.044884,2681.972168 50.533295,2682.099609 38.935173,2681.989014 
	C26.987841,2681.875244 15.069097,2681.789795 2.795054,2685.927734 
	C2.000000,2683.866699 2.000000,2681.733154 2.000000,2678.799805 
	C3.959653,2675.924561 6.525859,2674.951904 9.935322,2673.830078 
	C21.100613,2672.718018 31.489986,2671.989990 42.531075,2669.916748 
	C51.102989,2671.731201 58.935684,2671.279053 67.484886,2670.397461 
	C70.067482,2669.382812 72.002457,2668.831299 74.603149,2667.932129 
	C77.207771,2667.222168 79.204697,2667.142090 81.714645,2666.055176 
	C85.847588,2665.119141 89.252419,2664.313232 93.437241,2663.478760 
	C95.797432,2663.418945 97.319817,2663.738037 99.433655,2664.816650 
	C102.397743,2668.586670 100.753708,2671.209961 97.980118,2674.236816 
	C95.602043,2675.940186 93.543243,2675.909180 90.774124,2676.095215 
z"
        />
        <path
          fill="#838F9A"
          opacity="1.000000"
          stroke="none"
          d="
M52.589134,2460.023682 
	C39.380436,2461.716309 26.929808,2463.049561 14.556980,2464.902344 
	C10.938494,2465.444092 7.362689,2465.749512 2.870171,2465.952148 
	C2.000000,2460.599854 2.000000,2455.199951 2.000000,2448.899902 
	C12.812701,2453.604980 23.883631,2455.580566 35.574211,2450.685791 
	C37.011471,2450.083984 38.942097,2449.926514 40.441845,2450.324951 
	C52.211143,2453.448730 64.213860,2451.030762 76.986664,2452.038086 
	C76.634430,2454.718018 74.476379,2455.920166 71.581108,2455.944580 
	C65.244766,2455.998047 59.363976,2458.179688 52.589134,2460.023682 
z"
        />
        <path
          fill="#294159"
          opacity="1.000000"
          stroke="none"
          d="
M3026.000000,2703.250000 
	C3022.697998,2703.910889 3019.352783,2704.319092 3016.151123,2703.102539 
	C3014.263916,2702.385742 3011.877686,2701.404785 3010.385010,2702.529053 
	C3002.963135,2708.119873 2995.101562,2705.387451 2987.481934,2703.898438 
	C2967.785645,2700.049805 2947.954834,2696.786133 2928.359375,2691.586182 
	C2930.458740,2688.869873 2933.171387,2689.735352 2935.316162,2690.025146 
	C2948.045898,2691.744385 2961.029297,2688.053711 2973.628174,2691.893311 
	C2975.833008,2692.565430 2978.353760,2692.882080 2980.119873,2694.186768 
	C2985.489990,2698.153076 2990.337891,2699.409668 2996.244629,2694.737061 
	C2998.657715,2692.828125 3002.864258,2693.387939 3005.828857,2695.060303 
	C3011.672363,2698.356201 3017.952637,2697.997559 3025.117676,2698.028320 
	C3026.000000,2699.500000 3026.000000,2701.000000 3026.000000,2703.250000 
z"
        />
        <path
          fill="#8497A7"
          opacity="1.000000"
          stroke="none"
          d="
M2.000000,2350.875000 
	C25.250006,2351.911621 47.926765,2345.830322 71.543373,2345.507324 
	C71.371399,2349.608398 73.873840,2352.702881 73.845047,2356.976562 
	C66.960297,2364.123047 59.257423,2359.344727 51.051567,2359.214844 
	C36.259468,2359.031006 22.220488,2358.167969 9.223183,2364.883057 
	C7.427802,2365.810547 5.362069,2365.780273 2.705657,2364.484863 
	C2.000000,2359.916748 2.000000,2355.833252 2.000000,2350.875000 
z"
        />
        <path
          fill="#8497A7"
          opacity="1.000000"
          stroke="none"
          d="
M2.000000,2270.856934 
	C24.913244,2270.067383 47.845673,2269.461914 70.775352,2271.877197 
	C64.149345,2275.116211 57.237709,2276.229248 50.004597,2276.081543 
	C46.950504,2276.019043 48.060074,2277.657959 49.630989,2279.483643 
	C41.692451,2282.975098 33.087006,2281.905273 24.593569,2282.034668 
	C17.689003,2282.139893 10.780896,2282.012451 2.937180,2281.994141 
	C2.000000,2278.571289 2.000000,2275.142578 2.000000,2270.856934 
z"
        />
        <path
          fill="#2F465E"
          opacity="1.000000"
          stroke="none"
          d="
M3026.000000,2829.250000 
	C3011.573730,2826.053467 2997.065186,2822.749512 2981.924561,2823.994385 
	C2978.567383,2824.270752 2975.292236,2823.824951 2972.281006,2822.125732 
	C2969.297852,2820.441895 2966.029297,2819.660400 2962.677734,2820.087646 
	C2954.407715,2821.142334 2947.287109,2816.312988 2939.347900,2815.726318 
	C2938.548096,2815.666992 2937.080078,2814.561523 2937.157227,2814.223389 
	C2938.549805,2808.118652 2934.235596,2808.072754 2930.288330,2807.381348 
	C2929.623779,2807.264893 2928.824463,2806.021729 2928.688721,2805.199951 
	C2928.448486,2803.744873 2929.414307,2802.815430 2930.822998,2802.361816 
	C2935.678711,2800.798584 2941.912842,2802.413818 2943.522217,2807.051514 
	C2945.168213,2811.793213 2948.073242,2812.027832 2951.876221,2811.994873 
	C2958.839844,2811.934814 2965.765869,2811.957520 2972.606689,2813.841064 
	C2975.197266,2814.554688 2977.881348,2814.649414 2979.527588,2817.108643 
	C2981.526123,2820.094482 2984.663086,2820.705078 2987.465820,2819.604492 
	C2995.994629,2816.254150 3003.771240,2819.426514 3011.695312,2821.678711 
	C3015.840332,2822.856445 3019.889648,2824.471924 3025.166016,2823.995605 
	C3026.000000,2825.500000 3026.000000,2827.000000 3026.000000,2829.250000 
z"
        />
        <path
          fill="#4C6880"
          opacity="1.000000"
          stroke="none"
          d="
M3026.000000,2739.250000 
	C3019.306641,2740.315186 3012.868164,2739.099609 3005.868164,2741.507568 
	C2997.838867,2744.269531 2988.968506,2740.238525 2980.411133,2739.620850 
	C2967.303955,2738.675293 2954.333496,2735.828613 2941.310303,2733.753174 
	C2940.359131,2733.601318 2939.359619,2733.260010 2939.385986,2732.126465 
	C2967.734375,2730.236084 2996.067627,2729.392578 3025.135986,2733.983887 
	C3026.000000,2735.500000 3026.000000,2737.000000 3026.000000,2739.250000 
z"
        />
        <path
          fill="#5D758A"
          opacity="1.000000"
          stroke="none"
          d="
M39.076637,2701.300781 
	C49.962757,2704.192139 58.661407,2698.008545 68.238869,2696.639160 
	C75.502975,2695.600586 83.236649,2697.569092 90.267204,2693.078125 
	C92.046791,2691.941406 97.694336,2693.177490 97.873291,2699.127197 
	C87.521851,2700.020996 77.524254,2701.636475 67.612297,2704.886719 
	C61.415222,2706.918701 54.595558,2707.728516 48.034519,2707.837402 
	C33.105141,2708.084961 18.153103,2707.452148 2.820234,2711.983887 
	C2.000000,2709.866699 2.000000,2707.733154 2.000000,2704.799805 
	C13.859464,2699.865723 26.009861,2699.052246 39.076637,2701.300781 
z"
        />
        <path
          fill="#2F4457"
          opacity="1.000000"
          stroke="none"
          d="
M2.000000,2504.856934 
	C10.281429,2504.222168 18.544966,2504.403320 26.607235,2501.882080 
	C31.721155,2500.282959 36.818027,2499.493164 42.156872,2501.545410 
	C44.187763,2502.325928 46.869308,2502.226318 49.043060,2501.682861 
	C58.637943,2499.283447 68.042480,2500.698975 78.401352,2502.731934 
	C70.152657,2507.411621 61.510311,2506.404541 53.451180,2507.799805 
	C36.872566,2510.669189 20.203737,2513.017334 2.786025,2515.790039 
	C2.000000,2512.571289 2.000000,2509.142578 2.000000,2504.856934 
z"
        />
        <path
          fill="#50677C"
          opacity="1.000000"
          stroke="none"
          d="
M49.467228,2356.788086 
	C57.577118,2355.281494 64.696945,2358.849609 72.978409,2357.764648 
	C77.359268,2358.537598 80.650345,2360.430908 85.220947,2359.999512 
	C89.135269,2358.522217 91.816872,2360.004395 93.650742,2362.082031 
	C95.368111,2364.027344 93.286339,2365.378906 90.784241,2366.229492 
	C83.097359,2365.740234 76.233780,2365.757080 69.302467,2364.697266 
	C63.662468,2363.834961 57.351093,2364.904297 51.772190,2366.571777 
	C36.060452,2371.267090 20.020195,2371.943848 2.924736,2371.980957 
	C2.000000,2371.111084 2.000000,2370.222168 2.000000,2368.666504 
	C13.509167,2361.382324 26.460346,2360.764893 39.156750,2359.185547 
	C42.440868,2358.777100 45.731201,2358.657715 49.467228,2356.788086 
z"
        />
        <path
          fill="#3F5A73"
          opacity="1.000000"
          stroke="none"
          d="
M2941.575928,2576.615234 
	C2952.951172,2575.667725 2963.829834,2576.101074 2974.793701,2574.394287 
	C2985.927002,2572.660889 2997.142334,2576.275146 3008.396729,2576.875488 
	C3013.613525,2577.153809 3018.908203,2577.863037 3025.101562,2577.988281 
	C3026.000000,2580.777832 3026.000000,2583.555664 3026.000000,2587.166748 
	C3015.059814,2585.437744 3003.908691,2584.750488 2992.772949,2583.550781 
	C2978.054199,2581.964600 2963.164795,2582.168457 2948.568604,2579.375977 
	C2947.009033,2579.077393 2945.309570,2579.509277 2942.973877,2579.653809 
	C2941.439453,2579.077148 2941.056885,2578.242188 2941.575928,2576.615234 
z"
        />
        <path
          fill="#69849B"
          opacity="1.000000"
          stroke="none"
          d="
M3026.000000,2443.333496 
	C3011.031738,2444.501953 2996.326660,2441.837158 2981.503174,2440.515137 
	C2970.947021,2439.573730 2960.233398,2439.678955 2949.629883,2440.169922 
	C2936.531494,2440.776855 2923.985596,2438.568359 2911.575439,2435.011230 
	C2911.201904,2431.475586 2913.807129,2432.262695 2915.515381,2432.037598 
	C2921.256836,2431.281738 2926.713623,2433.420410 2932.181396,2434.403809 
	C2939.798340,2435.773438 2947.339355,2435.717529 2954.949463,2435.975098 
	C2972.498291,2436.568604 2990.148926,2434.183594 3007.596924,2437.736084 
	C3013.734619,2434.627197 3018.959229,2438.415771 3025.239746,2439.985596 
	C3026.000000,2440.888916 3026.000000,2441.777832 3026.000000,2443.333496 
z"
        />
        <path
          fill="#131C25"
          opacity="1.000000"
          stroke="none"
          d="
M3026.000000,3459.166748 
	C3014.819092,3458.484375 3004.672607,3453.317871 2993.759521,3450.840820 
	C2992.103271,3450.464844 2990.571533,3450.053467 2988.473877,3451.399658 
	C2985.870361,3454.180908 2983.238770,3454.623779 2979.549316,3453.904785 
	C2970.022705,3448.372559 2970.824463,3439.738525 2971.455322,3430.721680 
	C2977.868652,3428.657227 2981.722168,3433.463135 2986.568115,3435.183105 
	C2999.342529,3439.716553 3012.454102,3443.356201 3025.411621,3449.505371 
	C3026.000000,3452.777832 3026.000000,3455.555664 3026.000000,3459.166748 
z"
        />
        <path
          fill="#63707D"
          opacity="1.000000"
          stroke="none"
          d="
M2.000000,1892.666748 
	C14.627628,1892.043701 27.256836,1892.212036 39.881893,1892.052246 
	C43.527714,1892.006104 48.106293,1893.925049 50.225357,1888.674072 
	C50.722397,1887.442261 52.083252,1887.666626 52.577385,1888.420532 
	C56.354141,1894.183350 62.164310,1891.897705 67.158760,1891.964722 
	C87.095436,1892.232422 107.036804,1892.134766 126.975990,1892.249268 
	C130.258469,1892.268188 133.635849,1891.629272 137.365875,1893.749023 
	C134.753632,1897.052734 130.938141,1895.944946 127.396133,1895.950562 
	C86.222343,1896.015625 45.048397,1895.981445 2.937246,1895.986816 
	C2.000000,1895.111084 2.000000,1894.222168 2.000000,1892.666748 
z"
        />
        <path
          fill="#4E6477"
          opacity="1.000000"
          stroke="none"
          d="
M3025.474609,3365.540527 
	C3026.000000,3367.500000 3026.000000,3369.000000 3026.000000,3371.250000 
	C3020.144043,3375.561279 3013.985596,3377.799561 3006.301758,3376.140625 
	C3005.206787,3375.709961 3004.793213,3375.490723 3003.862305,3374.811279 
	C3001.510742,3368.822998 2997.360596,3369.346924 2992.330078,3370.461426 
	C2990.243896,3370.558350 2988.870605,3370.343506 2986.848145,3369.728271 
	C2984.050049,3368.423340 2982.042725,3367.137695 2979.269043,3365.709961 
	C2977.831787,3364.689209 2977.175781,3363.927490 2976.380371,3362.316162 
	C2977.397461,3349.711182 2983.542480,3344.381104 2994.999512,3346.156982 
	C3002.294189,3357.665527 3014.382812,3360.166260 3025.474609,3365.540527 
z"
        />
        <path
          fill="#7B7F84"
          opacity="1.000000"
          stroke="none"
          d="
M2991.398438,3368.614502 
	C2994.640869,3367.440918 2997.362793,3366.507812 3000.158691,3366.144287 
	C3005.681641,3365.426514 3005.769043,3368.505127 3004.145020,3373.206055 
	C3011.116943,3375.128906 3017.795166,3372.552490 3025.331543,3371.967773 
	C3026.000000,3382.044189 3026.000000,3392.088379 3026.000000,3403.066406 
	C3021.542969,3403.764160 3015.807861,3406.994873 3013.938965,3398.257324 
	C3012.823486,3390.439697 3009.861816,3386.855469 3002.465088,3386.238525 
	C2991.769287,3385.346924 2986.934326,3377.361328 2991.398438,3368.614502 
z"
        />
        <path
          fill="#9DAAB4"
          opacity="1.000000"
          stroke="none"
          d="
M2.000000,2252.750000 
	C16.231936,2252.128174 30.700821,2253.427246 44.588070,2250.767090 
	C53.395832,2249.079834 61.980103,2248.104248 70.813904,2248.080078 
	C72.448448,2248.075684 74.083405,2248.224854 76.773186,2248.353516 
	C73.338928,2253.267578 68.741066,2253.856201 64.556931,2253.803711 
	C54.901150,2253.683105 45.569050,2256.965576 35.868237,2256.073486 
	C32.624252,2255.775391 29.785526,2256.548096 30.372938,2260.980225 
	C30.786324,2264.099121 28.316601,2263.903076 26.429838,2263.941650 
	C18.931192,2264.095947 11.098272,2265.411377 5.444120,2258.407715 
	C5.133331,2258.022949 4.183019,2258.154297 2.765188,2258.022949 
	C2.000000,2256.500000 2.000000,2255.000000 2.000000,2252.750000 
z"
        />
        <path
          fill="#1C2E3E"
          opacity="1.000000"
          stroke="none"
          d="
M8.298178,2854.644531 
	C6.306308,2854.215820 4.871021,2854.141357 2.717867,2854.033203 
	C2.000000,2850.571289 2.000000,2847.142578 2.000000,2842.856934 
	C4.323764,2839.728760 6.860724,2837.758057 10.631119,2836.130371 
	C18.707972,2834.337646 26.049686,2834.261475 34.240520,2835.331543 
	C36.957352,2836.013916 38.728230,2836.863770 40.449997,2837.781982 
	C42.844875,2839.060059 44.841629,2840.819092 44.730843,2843.791992 
	C44.608753,2847.067871 41.897614,2848.201904 39.383381,2848.596924 
	C34.293907,2849.395508 31.558651,2852.832764 28.460136,2857.269043 
	C24.532860,2857.865967 21.151995,2857.875732 16.926422,2857.862793 
	C14.169550,2855.629150 11.078316,2856.416992 8.298178,2854.644531 
z"
        />
        <path
          fill="#8DA0AE"
          opacity="1.000000"
          stroke="none"
          d="
M2.000000,2236.833252 
	C12.653304,2236.008789 23.306854,2236.064941 33.959824,2236.009766 
	C40.920258,2235.973633 47.864319,2236.391357 54.768646,2234.449951 
	C58.159081,2233.496582 62.061058,2233.302490 65.481407,2235.186035 
	C67.047485,2236.048340 68.037354,2237.662354 67.460983,2239.364258 
	C66.663277,2241.719727 65.169273,2240.842773 63.676300,2239.755615 
	C62.942909,2239.221436 61.523079,2238.597656 61.023899,2238.930176 
	C54.321850,2243.395020 45.814117,2240.488037 39.020393,2244.429443 
	C36.317619,2245.997559 33.206570,2246.540527 30.309595,2245.926758 
	C21.412245,2244.040771 12.594770,2246.177979 2.873449,2245.994873 
	C2.000000,2243.222168 2.000000,2240.444336 2.000000,2236.833252 
z"
        />
        <path
          fill="#8497A7"
          opacity="1.000000"
          stroke="none"
          d="
M2.000000,2284.856934 
	C19.093639,2285.751465 36.227348,2286.336182 53.409653,2286.149658 
	C56.615681,2286.114746 59.850300,2287.455078 63.045444,2288.264404 
	C64.446907,2288.619141 65.348770,2289.560059 64.812721,2291.142822 
	C64.343605,2292.527588 63.135128,2293.467529 61.805088,2293.210693 
	C50.274052,2290.982666 38.979462,2295.056885 27.413078,2294.301025 
	C19.649601,2293.793945 11.803204,2296.748779 2.907677,2295.983398 
	C2.000000,2292.571289 2.000000,2289.142578 2.000000,2284.856934 
z"
        />
        <path
          fill="#566C80"
          opacity="1.000000"
          stroke="none"
          d="
M2.000000,2384.875000 
	C7.066133,2385.759521 12.264401,2386.394287 17.626143,2386.114746 
	C22.163214,2385.878418 27.160646,2387.043701 29.267723,2381.084473 
	C29.536640,2380.324219 30.744558,2379.515625 31.605705,2379.403076 
	C41.303360,2378.131836 51.080734,2374.603760 62.619926,2380.241455 
	C54.766251,2382.802490 48.491615,2383.816162 41.935577,2384.306396 
	C43.551197,2387.390381 47.615646,2384.667236 48.888577,2388.750732 
	C41.272373,2391.410156 33.030643,2388.221680 25.846991,2390.458496 
	C18.410065,2392.774658 11.315807,2396.314453 2.770940,2397.887695 
	C2.000000,2393.916748 2.000000,2389.833252 2.000000,2384.875000 
z"
        />
        <path
          fill="#3C556B"
          opacity="1.000000"
          stroke="none"
          d="
M3026.000000,2945.111816 
	C3017.811523,2947.044434 3012.294922,2941.771973 3006.576660,2937.411865 
	C3002.193359,2934.069580 2998.381592,2931.463379 2992.225586,2933.351562 
	C2987.163818,2934.904053 2981.956787,2931.841797 2976.797363,2930.940186 
	C2976.109375,2930.819824 2975.237061,2929.509033 2975.150635,2928.677002 
	C2974.997803,2927.201904 2976.080566,2926.369629 2977.564209,2926.183350 
	C2977.893066,2926.141846 2978.222168,2926.037109 2978.548340,2926.048340 
	C2993.902832,2926.568115 3009.404053,2925.299805 3025.217285,2929.894531 
	C3026.000000,2934.741211 3026.000000,2939.482178 3026.000000,2945.111816 
z"
        />
        <path
          fill="#2B383B"
          opacity="1.000000"
          stroke="none"
          d="
M3026.000000,3449.250000 
	C3020.369873,3450.053223 3015.323242,3449.197021 3009.943115,3446.414795 
	C3003.075928,3442.863525 2995.148438,3441.166260 2987.707275,3438.623047 
	C2982.182129,3436.734619 2977.422119,3434.424805 2972.151367,3429.916016 
	C2970.710693,3428.527832 2970.500488,3427.781982 2970.506836,3426.411133 
	C2970.746826,3425.472900 2970.912354,3425.117676 2971.534180,3424.390625 
	C2972.623535,3423.281006 2973.537842,3422.642334 2973.842041,3421.786865 
	C2975.173584,3418.045654 2977.473145,3417.481934 2979.115723,3420.996338 
	C2981.794434,3426.726807 2985.896484,3429.697021 2992.060303,3430.057617 
	C2994.313477,3430.189697 2995.445312,3431.773682 2996.818848,3433.245361 
	C2999.371338,3435.980225 3002.274414,3438.190186 3006.395996,3436.822998 
	C3008.952637,3435.975098 3011.107910,3437.199463 3012.858398,3438.550049 
	C3016.381592,3441.268311 3020.484863,3442.423828 3025.275391,3443.890625 
	C3026.000000,3445.500000 3026.000000,3447.000000 3026.000000,3449.250000 
z"
        />
        <path
          fill="#344F68"
          opacity="1.000000"
          stroke="none"
          d="
M3026.000000,3365.091797 
	C3021.671631,3367.107666 3016.856445,3367.567871 3013.802002,3364.266846 
	C3009.420898,3359.532227 3003.912598,3357.919678 2998.221680,3356.323486 
	C2994.468506,3355.270752 2993.180420,3353.397705 2995.697510,3349.280518 
	C2996.360352,3347.004639 2997.593750,3346.367188 2999.834961,3345.993652 
	C3008.430420,3345.962891 3016.294922,3345.981445 3025.079834,3346.000000 
	C3026.000000,3352.061279 3026.000000,3358.122314 3026.000000,3365.091797 
z"
        />
        <path
          fill="#3C556B"
          opacity="1.000000"
          stroke="none"
          d="
M2993.429932,3063.242432 
	C2999.721436,3067.639160 3006.610840,3067.249023 3011.679443,3064.911865 
	C3016.425537,3062.724121 3020.117920,3063.180908 3025.220703,3063.984863 
	C3026.000000,3066.133301 3026.000000,3068.266846 3026.000000,3071.200195 
	C3015.191650,3071.360352 3004.391602,3073.565674 2992.752686,3072.142578 
	C2982.189941,3076.434570 2973.199463,3073.394531 2963.712646,3068.661133 
	C2961.220947,3058.833984 2967.799805,3059.538330 2974.306641,3059.072021 
	C2981.438721,3058.490234 2987.399902,3059.194092 2993.429932,3063.242432 
z"
        />
        <path
          fill="#2F4457"
          opacity="1.000000"
          stroke="none"
          d="
M2.000000,2644.799805 
	C11.928584,2643.662354 21.835205,2644.455322 31.778286,2642.321777 
	C37.800953,2641.029541 44.383377,2641.340332 51.300133,2643.820068 
	C53.602837,2645.748535 54.240807,2647.787842 53.437378,2650.888428 
	C52.791092,2652.069824 52.445435,2652.486328 51.422920,2653.335938 
	C46.334641,2655.383789 41.620457,2655.006836 36.356232,2655.846680 
	C33.713589,2655.770264 31.958429,2655.006104 29.472298,2654.478516 
	C27.127007,2653.929443 25.433514,2653.762939 23.121382,2654.708008 
	C17.799124,2656.723633 13.003323,2657.284424 7.334218,2656.105957 
	C5.147253,2655.312256 4.038451,2654.288330 2.535892,2652.542969 
	C2.000000,2649.866699 2.000000,2647.733154 2.000000,2644.799805 
z"
        />
        <path
          fill="#294159"
          opacity="1.000000"
          stroke="none"
          d="
M3003.274414,2681.311279 
	C3010.921631,2682.264648 3017.072021,2686.872314 3025.171143,2686.000000 
	C3026.000000,2688.133301 3026.000000,2690.266846 3026.000000,2693.200195 
	C3011.486816,2691.919922 2996.833252,2690.493164 2982.532959,2687.422119 
	C2977.187500,2686.274170 2971.117920,2685.301758 2966.265381,2680.849854 
	C2974.101807,2674.284180 2982.859375,2677.508789 2992.197754,2678.234863 
	C2994.636963,2678.964355 2996.314941,2679.074707 2998.812988,2679.317139 
	C3000.719727,2679.697021 3001.717285,2680.153076 3003.274414,2681.311279 
z"
        />
        <path
          fill="#8DA0AE"
          opacity="1.000000"
          stroke="none"
          d="
M2.000000,2302.750000 
	C10.209996,2301.767578 18.532032,2300.993408 26.600462,2301.264893 
	C44.342022,2301.861816 62.085880,2301.495361 79.807732,2302.205078 
	C84.015579,2302.373535 85.610947,2304.524414 80.628067,2307.888672 
	C78.645370,2309.138428 77.204170,2309.815430 74.738358,2309.978027 
	C67.690308,2308.568848 61.444542,2308.184082 54.396027,2310.136719 
	C52.488644,2310.544678 51.369877,2310.635986 49.377224,2310.610840 
	C37.262180,2307.723389 26.134289,2312.062256 14.884497,2311.175537 
	C10.925947,2310.863281 6.914292,2311.537109 2.689581,2308.552734 
	C2.000000,2306.500000 2.000000,2305.000000 2.000000,2302.750000 
z"
        />
        <path
          fill="#3A4B59"
          opacity="1.000000"
          stroke="none"
          d="
M9.368105,3233.273438 
	C15.453872,3233.764160 20.785408,3233.783447 27.005226,3233.770996 
	C29.292723,3232.585205 30.872097,3231.955078 33.365166,3232.106934 
	C37.406246,3232.124023 40.793934,3232.116455 45.028049,3232.113037 
	C51.090263,3237.025146 49.816124,3240.108154 43.493832,3242.806885 
	C35.546432,3246.199463 27.142427,3242.075439 18.573814,3245.413086 
	C15.781203,3245.874756 13.693397,3245.854980 10.822643,3245.826172 
	C7.669921,3245.914551 5.263539,3246.303711 2.538477,3244.471191 
	C2.000000,3241.222168 2.000000,3238.444336 2.000000,3234.833252 
	C3.976671,3232.342285 6.155778,3231.802002 9.368105,3233.273438 
z"
        />
        <path
          fill="#43596D"
          opacity="1.000000"
          stroke="none"
          d="
M73.091782,2545.965088 
	C73.555214,2549.311523 71.233299,2549.825195 68.312057,2550.092285 
	C46.868977,2552.053711 25.380564,2552.306152 2.936956,2552.028320 
	C2.000000,2550.500000 2.000000,2549.000000 2.000000,2546.750000 
	C16.182999,2545.377686 30.341942,2546.316162 44.601124,2544.536621 
	C53.485516,2543.427734 62.870983,2546.722168 73.091782,2545.965088 
z"
        />
        <path
          fill="#4C6880"
          opacity="1.000000"
          stroke="none"
          d="
M3026.000000,2633.143066 
	C3019.124756,2633.987793 3012.232666,2633.787598 3004.710938,2635.825195 
	C3002.516113,2630.142090 2998.400146,2628.207031 2993.129883,2627.217773 
	C2989.786133,2626.589844 2984.593506,2626.281982 2985.604004,2621.299072 
	C2986.569824,2616.537842 2991.403564,2618.162109 2994.909668,2618.400879 
	C2996.216064,2618.489746 2997.493652,2619.004883 2999.448242,2618.423096 
	C3005.410645,2618.306885 3010.962891,2616.831787 3015.774414,2621.078125 
	C3017.937988,2622.987549 3021.419434,2621.836426 3025.155273,2621.994629 
	C3026.000000,2625.428711 3026.000000,2628.857422 3026.000000,2633.143066 
z"
        />
        <path
          fill="#6A8297"
          opacity="1.000000"
          stroke="none"
          d="
M2.000000,2308.799805 
	C12.911027,2307.727783 23.847664,2308.716064 34.700428,2306.228760 
	C39.181053,2305.201660 43.972198,2305.929199 49.201710,2307.893066 
	C52.055271,2315.540283 45.819977,2311.914307 42.500153,2313.583008 
	C39.448368,2315.343018 36.762936,2316.737793 34.234562,2315.875488 
	C23.999697,2312.385742 13.897142,2315.703857 2.872739,2315.995117 
	C2.000000,2313.866699 2.000000,2311.733154 2.000000,2308.799805 
z"
        />
        <path
          fill="#687D90"
          opacity="1.000000"
          stroke="none"
          d="
M27.332336,2652.064941 
	C29.728180,2652.322754 31.441029,2652.579590 33.612919,2653.301758 
	C35.655483,2656.498535 33.807770,2659.069824 33.503334,2662.411377 
	C33.066547,2663.523926 32.827297,2663.947754 32.028790,2664.855469 
	C28.839687,2667.013672 25.584173,2666.559326 22.095089,2668.099365 
	C19.767729,2668.701172 18.171618,2668.877441 15.721815,2668.870605 
	C10.979754,2668.663818 6.886120,2669.600342 2.669552,2666.534180 
	C2.000000,2663.222168 2.000000,2660.444336 2.000000,2656.833252 
	C2.802184,2655.148193 3.690311,2654.426270 5.427509,2654.065918 
	C11.480612,2653.799805 17.029861,2654.385010 23.144905,2652.435547 
	C24.834337,2652.207275 25.739447,2652.112793 27.332336,2652.064941 
z"
        />
        <path
          fill="#102131"
          opacity="1.000000"
          stroke="none"
          d="
M2988.480957,3238.708008 
	C2987.361084,3235.613037 2983.923584,3234.308594 2985.491699,3230.568848 
	C2998.637207,3231.771973 3011.823975,3233.212158 3025.217285,3237.976074 
	C3026.000000,3240.133301 3026.000000,3242.266846 3026.000000,3245.200195 
	C3024.196533,3247.579834 3021.953857,3248.772705 3019.728516,3248.092285 
	C3009.535156,3244.975586 2998.565430,3244.600830 2988.480957,3238.708008 
z"
        />
        <path
          fill="#091A29"
          opacity="1.000000"
          stroke="none"
          d="
M3016.729248,3052.549072 
	C3006.682617,3050.108154 2997.439941,3047.833740 2986.976074,3047.919922 
	C2984.796143,3046.538330 2984.402588,3044.923828 2985.142822,3042.313477 
	C2986.380859,3040.724365 2987.357910,3040.114258 2989.294922,3039.500488 
	C2996.923340,3037.764648 3003.568848,3040.719482 3011.177002,3039.919434 
	C3014.110107,3039.997559 3016.200928,3040.111816 3019.073730,3040.294434 
	C3021.390381,3041.724365 3023.532959,3041.935303 3025.570557,3043.578613 
	C3026.000000,3046.133301 3026.000000,3048.266846 3026.000000,3051.200195 
	C3023.402588,3054.290527 3020.520264,3054.299561 3016.729248,3052.549072 
z"
        />
        <path
          fill="#E5DDC9"
          opacity="1.000000"
          stroke="none"
          d="
M1896.933716,4034.000000 
	C1898.246948,4027.635010 1904.721436,4026.886963 1909.530762,4023.995117 
	C1911.417114,4022.860840 1913.843018,4022.577148 1916.055420,4022.038818 
	C1927.508667,4019.251709 1929.296631,4020.837646 1928.025146,4033.294434 
	C1917.955811,4034.000000 1907.911621,4034.000000 1896.933716,4034.000000 
z"
        />
        <path
          fill="#627A90"
          opacity="1.000000"
          stroke="none"
          d="
M49.211441,2356.232178 
	C48.984921,2360.625244 45.458340,2361.428467 41.811329,2361.961914 
	C35.692909,2362.856934 29.702681,2364.706787 23.377686,2364.112061 
	C21.497940,2363.935059 18.941877,2363.916016 17.725681,2364.990479 
	C13.500099,2368.723633 8.577909,2367.878906 2.872752,2367.999756 
	C2.000000,2367.111084 2.000000,2366.222168 2.000000,2364.666504 
	C6.429496,2364.910645 9.165331,2361.202393 12.555373,2359.670410 
	C24.205086,2354.405762 36.285316,2356.290527 49.211441,2356.232178 
z"
        />
        <path
          fill="#233A4F"
          opacity="1.000000"
          stroke="none"
          d="
M2987.767090,3238.530762 
	C3000.379639,3239.494385 3012.244385,3243.362549 3025.192383,3245.984863 
	C3026.000000,3248.777832 3026.000000,3251.555664 3026.000000,3255.166748 
	C3012.775635,3252.379395 3000.407715,3246.168945 2986.477295,3242.588867 
	C2986.483398,3241.067139 2987.011963,3240.059570 2987.767090,3238.530762 
z"
        />
        <path
          fill="#EFF1EA"
          opacity="1.000000"
          stroke="none"
          d="
M3026.000000,1751.166748 
	C3017.171387,1750.866821 3008.739746,1754.963135 2999.048340,1753.959229 
	C2992.800537,1753.473267 2995.876465,1750.220215 2996.458984,1747.197510 
	C3000.741211,1741.049927 3006.551514,1742.513428 3012.817383,1742.613037 
	C3014.787354,1743.248169 3015.941162,1743.671265 3017.801025,1743.007568 
	C3020.417480,1742.626221 3022.457764,1742.483276 3025.249023,1742.170166 
	C3026.000000,1744.777832 3026.000000,1747.555542 3026.000000,1751.166748 
z"
        />
        <path
          fill="#49647C"
          opacity="1.000000"
          stroke="none"
          d="
M3026.000000,2785.143066 
	C3022.154785,2784.066406 3018.022949,2783.004639 3013.976807,2781.669434 
	C3008.014648,2779.702637 3001.860840,2778.247803 2996.015625,2775.918945 
	C2994.463867,2775.300537 2992.819824,2774.170410 2993.107178,2772.350098 
	C2993.532227,2769.656494 2996.161621,2769.692627 2997.853271,2770.229004 
	C3006.491699,2772.967773 3015.781738,2770.831787 3025.164062,2773.982422 
	C3026.000000,2777.428711 3026.000000,2780.857422 3026.000000,2785.143066 
z"
        />
        <path
          fill="#98999E"
          opacity="1.000000"
          stroke="none"
          d="
M20.193640,1747.512695 
	C21.679068,1753.676025 27.487625,1752.507202 31.570053,1755.517700 
	C30.524490,1759.087769 27.953770,1759.824829 24.751711,1759.878296 
	C19.878651,1759.959473 14.953385,1760.461304 10.208117,1759.300293 
	C8.170884,1758.801758 8.043846,1756.267944 8.000205,1754.275635 
	C7.932826,1751.199463 7.754300,1748.231934 2.798584,1748.035278 
	C2.000000,1747.111084 2.000000,1746.222168 2.000000,1744.666626 
	C7.392210,1743.121460 12.176356,1746.451660 18.247341,1746.219727 
	C19.543114,1746.395386 20.064426,1746.586548 20.193640,1747.512695 
z"
        />
        <path
          fill="#63707D"
          opacity="1.000000"
          stroke="none"
          d="
M28.438061,2928.305908 
	C21.087086,2924.704834 14.351376,2926.470459 6.805159,2927.984863 
	C4.807137,2927.979980 3.738503,2927.596191 2.411832,2926.408691 
	C2.000000,2926.000000 2.000000,2925.000000 2.000000,2924.500000 
	C14.467758,2921.211914 26.971706,2918.755859 39.864334,2919.959961 
	C41.806004,2920.140869 44.008652,2919.862549 45.619450,2920.701416 
	C47.991833,2921.936768 46.364674,2923.192627 44.241348,2924.356934 
	C39.516010,2928.746094 34.734207,2930.414062 28.438061,2928.305908 
z"
        />
        <path
          fill="#EEE9DC"
          opacity="1.000000"
          stroke="none"
          d="
M1588.888306,4034.000000 
	C1587.947144,4031.112061 1590.042603,4029.238037 1591.311401,4026.972412 
	C1592.331543,4025.150391 1592.209961,4021.780518 1595.663330,4023.011719 
	C1600.011108,4024.562256 1604.651611,4016.953125 1608.799316,4023.639160 
	C1609.774170,4025.210449 1611.628906,4026.235596 1613.067139,4027.501709 
	C1611.505249,4031.960205 1605.547729,4028.130615 1604.154785,4033.302734 
	C1599.258911,4034.000000 1594.517700,4034.000000 1588.888306,4034.000000 
z"
        />
        <path
          fill="#626669"
          opacity="1.000000"
          stroke="none"
          d="
M2.000000,3824.799805 
	C6.433724,3823.958252 9.662191,3820.214111 14.042972,3820.055908 
	C17.292917,3819.938477 20.908480,3819.286133 22.882357,3822.766357 
	C25.139481,3826.745605 21.934961,3828.649902 19.354239,3830.702637 
	C17.627291,3832.076904 16.989754,3834.710205 14.466181,3835.107910 
	C10.837276,3834.456299 13.132735,3829.936035 10.438755,3829.065918 
	C7.533381,3828.127930 6.036731,3831.616211 2.695816,3831.924316 
	C2.000000,3829.866699 2.000000,3827.733154 2.000000,3824.799805 
z"
        />
        <path
          fill="#8497A7"
          opacity="1.000000"
          stroke="none"
          d="
M2.000000,2340.799805 
	C11.455818,2339.092285 20.875885,2341.792725 30.368475,2340.219238 
	C32.471111,2339.870605 35.431805,2339.876709 35.334923,2343.312744 
	C35.262691,2345.874512 32.771587,2346.047119 30.939690,2346.385986 
	C29.385685,2346.673828 27.708464,2346.488770 26.109272,2346.306641 
	C18.519232,2345.441406 11.038019,2345.884033 2.835934,2347.983154 
	C2.000000,2345.866699 2.000000,2343.733154 2.000000,2340.799805 
z"
        />
        <path
          fill="#7B7F84"
          opacity="1.000000"
          stroke="none"
          d="
M42.926369,4034.000000 
	C49.680054,4027.850342 59.147453,4025.099609 67.731529,4019.679932 
	C70.725136,4024.414062 67.233261,4028.474609 67.969147,4033.216797 
	C59.950912,4034.000000 51.901821,4034.000000 42.926369,4034.000000 
z"
        />
        <path
          fill="#31475A"
          opacity="1.000000"
          stroke="none"
          d="
M2.000000,2792.666504 
	C15.671258,2791.418213 29.139383,2792.852051 42.471931,2796.271484 
	C29.527794,2798.724609 16.629587,2798.092285 2.865933,2796.030762 
	C2.000000,2795.111084 2.000000,2794.222168 2.000000,2792.666504 
z"
        />
        <path
          fill="#568CCD"
          opacity="1.000000"
          stroke="none"
          d="
M3026.000000,175.066284 
	C3022.270996,175.917740 3020.183105,173.249344 3017.979248,170.829346 
	C3016.297607,168.982849 3015.178467,166.329773 3017.109375,164.626892 
	C3022.572510,159.808746 3021.964355,153.519455 3022.236816,147.338699 
	C3022.300537,145.894608 3023.280762,145.010498 3025.252441,144.191559 
	C3026.000000,154.044189 3026.000000,164.088364 3026.000000,175.066284 
z"
        />
        <path
          fill="#2F4457"
          opacity="1.000000"
          stroke="none"
          d="
M39.604988,2701.031250 
	C32.916134,2701.230713 26.112217,2701.502441 19.423428,2703.180176 
	C14.258537,2704.475586 9.009185,2703.683838 2.903505,2704.000000 
	C2.000000,2703.111084 2.000000,2702.222168 2.000000,2700.666504 
	C12.074067,2698.340332 22.217964,2697.706787 32.416553,2698.046143 
	C34.927967,2698.129639 37.822487,2697.269775 39.604988,2701.031250 
z"
        />
        <path
          fill="#0D1C2A"
          opacity="1.000000"
          stroke="none"
          d="
M2.000000,3130.833252 
	C7.388121,3129.411377 12.432223,3131.091797 17.500353,3132.470947 
	C18.263241,3132.678467 19.104074,3133.696045 19.338600,3134.504150 
	C19.736935,3135.876221 19.064846,3137.120117 17.641455,3137.581543 
	C15.192563,3138.374756 12.867097,3140.159424 10.094050,3138.990723 
	C7.653487,3137.962402 5.615141,3139.434814 2.701735,3139.918701 
	C2.000000,3137.222168 2.000000,3134.444336 2.000000,3130.833252 
z"
        />
        <path
          fill="#748B9F"
          opacity="1.000000"
          stroke="none"
          d="
M30.381424,2531.247559 
	C21.096167,2532.779297 12.612071,2528.915283 2.892757,2530.041992 
	C2.000000,2529.111084 2.000000,2528.222168 2.000000,2526.666504 
	C9.385897,2525.999512 16.771793,2525.999268 25.078888,2526.013184 
	C28.843359,2526.272217 31.133581,2527.100098 30.381424,2531.247559 
z"
        />
        <path
          fill="#495E72"
          opacity="1.000000"
          stroke="none"
          d="
M2.000000,2606.750000 
	C7.777435,2603.814697 13.460745,2603.938477 18.982674,2606.805176 
	C20.138424,2607.405029 21.202175,2608.294922 20.973537,2609.833252 
	C20.720064,2611.538818 19.240541,2611.975586 18.067888,2611.500244 
	C13.132013,2609.498779 8.452334,2612.836670 2.807310,2611.988770 
	C2.000000,2610.500000 2.000000,2609.000000 2.000000,2606.750000 
z"
        />
        <path
          fill="#4C565C"
          opacity="1.000000"
          stroke="none"
          d="
M2.000000,3638.875000 
	C4.576242,3638.987549 8.629061,3633.695801 9.923733,3639.512939 
	C11.587568,3646.989258 10.069124,3649.468994 2.690268,3652.059570 
	C2.000000,3647.916748 2.000000,3643.833252 2.000000,3638.875000 
z"
        />
        <path
          fill="#6E8AA1"
          opacity="1.000000"
          stroke="none"
          d="
M3026.000000,2223.333496 
	C3022.481445,2224.300781 3018.839600,2223.239502 3015.691895,2226.226562 
	C3012.257568,2229.485352 3009.576172,2226.870605 3007.511719,2224.111328 
	C3005.630615,2221.597168 3007.444336,2218.211426 3010.516113,2218.893066 
	C3015.119141,2219.914551 3019.679932,2219.965576 3025.148926,2220.000000 
	C3026.000000,2220.888916 3026.000000,2221.777832 3026.000000,2223.333496 
z"
        />
        <path
          fill="#2F4457"
          opacity="1.000000"
          stroke="none"
          d="
M2.000002,2484.750000 
	C8.639094,2483.819336 15.278185,2483.638672 22.809519,2483.379883 
	C25.871468,2483.285645 26.754408,2484.533203 26.479061,2487.301270 
	C18.505394,2487.256592 11.217362,2489.904297 2.855305,2489.978271 
	C2.000000,2488.500000 2.000000,2487.000000 2.000002,2484.750000 
z"
        />
        <path
          fill="#556A7C"
          opacity="1.000000"
          stroke="none"
          d="
M2.000000,2874.799805 
	C6.250588,2874.780029 10.259643,2870.407227 15.262831,2873.705811 
	C18.213245,2876.666260 16.880424,2878.781738 14.268341,2881.279297 
	C12.829522,2881.764648 12.009611,2881.768066 10.579049,2881.846924 
	C8.024126,2883.653809 5.871243,2884.134033 2.725373,2882.504395 
	C2.000000,2879.866699 2.000000,2877.733154 2.000000,2874.799805 
z"
        />
        <path
          fill="#243748"
          opacity="1.000000"
          stroke="none"
          d="
M2.000000,3144.799805 
	C5.261644,3144.007812 8.525232,3143.949951 11.784252,3144.046387 
	C13.984617,3144.111572 16.798084,3143.629395 17.365017,3146.628174 
	C17.970734,3149.832275 14.573052,3149.620361 12.453014,3151.425537 
	C9.460431,3154.618896 6.579311,3154.620605 2.744550,3152.556396 
	C2.000000,3149.866699 2.000000,3147.733154 2.000000,3144.799805 
z"
        />
        <path
          fill="#3F5467"
          opacity="1.000000"
          stroke="none"
          d="
M2.000001,2666.856934 
	C6.126844,2666.051514 10.253686,2666.103027 15.258335,2666.265137 
	C18.560896,2671.907227 12.370325,2671.916504 10.347144,2675.347168 
	C8.395070,2678.323975 5.879280,2678.033691 2.735840,2678.031738 
	C2.000000,2674.571289 2.000000,2671.142578 2.000001,2666.856934 
z"
        />
        <path
          fill="#5486C5"
          opacity="1.000000"
          stroke="none"
          d="
M2783.166748,2.000000 
	C2783.669922,5.177779 2787.017578,6.977723 2787.078857,10.009648 
	C2778.766602,13.036860 2777.397217,12.349481 2774.016846,2.760497 
	C2776.777832,2.000000 2779.555664,2.000000 2783.166748,2.000000 
z"
        />
        <path
          fill="#556A7C"
          opacity="1.000000"
          stroke="none"
          d="
M18.431982,2415.121582 
	C13.080525,2416.145020 8.603815,2413.234375 2.820767,2414.031250 
	C2.000000,2412.500000 2.000000,2411.000000 1.999999,2408.750000 
	C4.080118,2407.937988 6.160237,2407.875977 9.020798,2407.807617 
	C16.007236,2405.545898 19.410559,2407.201904 18.431982,2415.121582 
z"
        />
        <path
          fill="#6E8AA1"
          opacity="1.000000"
          stroke="none"
          d="
M3026.000000,2341.250000 
	C3022.130371,2341.892334 3018.250000,2341.918945 3014.396240,2341.613281 
	C3012.770752,2341.484375 3011.201172,2340.349365 3011.159912,2338.568359 
	C3011.112061,2336.504639 3012.919922,2336.130615 3014.581787,2336.088623 
	C3017.809570,2336.006592 3021.039795,2336.027588 3025.134521,2336.002930 
	C3026.000000,2337.500000 3026.000000,2339.000000 3026.000000,2341.250000 
z"
        />
        <path
          fill="#2B3E51"
          opacity="1.000000"
          stroke="none"
          d="
M10.540823,2837.665039 
	C9.470602,2842.270996 6.349838,2841.925049 2.735480,2841.995605 
	C2.000000,2839.866699 2.000000,2837.733154 2.000000,2834.799805 
	C7.085726,2835.555664 10.101731,2830.499756 15.322041,2830.062012 
	C18.711746,2835.433350 13.870049,2835.570801 10.540823,2837.665039 
z"
        />
        <path
          fill="#0D1C2A"
          opacity="1.000000"
          stroke="none"
          d="
M2.000000,3152.799805 
	C4.770335,3151.996094 7.540669,3151.992188 11.140709,3151.962891 
	C15.177313,3155.279053 13.917300,3157.899658 10.270470,3159.379150 
	C8.290108,3160.182617 5.840958,3159.830566 2.801631,3160.000000 
	C2.000000,3157.866699 2.000000,3155.733154 2.000000,3152.799805 
z"
        />
        <path
          fill="#8DA0AE"
          opacity="1.000000"
          stroke="none"
          d="
M2.000000,2080.666504 
	C6.635313,2080.469727 11.036744,2079.229492 15.486515,2078.240967 
	C16.769478,2077.955811 18.295393,2078.354248 18.737009,2079.707275 
	C19.358557,2081.611084 18.019459,2082.848145 16.395325,2083.342773 
	C12.257969,2084.602783 7.963983,2083.800781 2.871329,2084.011230 
	C2.000000,2083.111084 2.000000,2082.222168 2.000000,2080.666504 
z"
        />
        <path
          fill="#2B3E51"
          opacity="1.000000"
          stroke="none"
          d="
M2.000000,2862.750000 
	C6.072647,2861.610596 9.850358,2862.800537 13.512827,2864.337402 
	C14.243580,2864.644043 15.109903,2865.770020 15.086309,2866.491211 
	C15.038943,2867.938232 13.810105,2868.853760 12.392645,2868.795410 
	C9.438752,2868.674561 6.494717,2868.313477 2.773313,2868.025635 
	C2.000000,2866.500000 2.000000,2865.000000 2.000000,2862.750000 
z"
        />
        <path
          fill="#2B3E51"
          opacity="1.000000"
          stroke="none"
          d="
M2.000000,2816.750000 
	C5.644074,2816.089111 9.343337,2815.310547 13.499287,2817.169434 
	C12.055315,2822.647949 7.656065,2821.946289 2.785148,2822.000977 
	C2.000000,2820.500000 2.000000,2819.000000 2.000000,2816.750000 
z"
        />
        <path
          fill="#091825"
          opacity="1.000000"
          stroke="none"
          d="
M2.000000,3260.666504 
	C5.304902,3260.178711 8.491467,3259.687500 11.612080,3258.542236 
	C12.912567,3258.064697 14.447612,3258.403564 14.853209,3259.780762 
	C15.407396,3261.663330 14.033892,3263.070801 12.396723,3263.345703 
	C9.525999,3263.828125 6.570466,3263.805664 2.825227,3263.997314 
	C2.000000,3263.111084 2.000000,3262.222168 2.000000,3260.666504 
z"
        />
        <path
          fill="#233444"
          opacity="1.000000"
          stroke="none"
          d="
M10.055358,3233.013672 
	C7.955649,3233.828857 5.788960,3233.912598 2.811136,3233.998047 
	C2.000000,3232.500000 2.000000,3231.000000 2.000000,3228.750000 
	C5.260139,3228.312988 9.433950,3226.921631 10.055358,3233.013672 
z"
        />
        <path
          fill="#172B3E"
          opacity="1.000000"
          stroke="none"
          d="
M3016.109619,3052.711914 
	C3018.760498,3052.024902 3021.545410,3052.014648 3025.165039,3052.001953 
	C3026.000000,3053.500000 3026.000000,3055.000000 3026.000000,3057.250000 
	C3022.488037,3057.012451 3018.510986,3057.008545 3016.109619,3052.711914 
z"
        />
        <path
          fill="#334D63"
          opacity="1.000000"
          stroke="none"
          d="
M3026.000000,2805.333496 
	C3021.348633,2806.074219 3017.415283,2804.171875 3011.996582,2801.809570 
	C3017.420654,2799.144531 3020.839111,2803.175537 3025.233887,2801.989746 
	C3026.000000,2802.888916 3026.000000,2803.777832 3026.000000,2805.333496 
z"
        />
        <path
          fill="#859CAF"
          opacity="1.000000"
          stroke="none"
          d="
M3026.000000,2043.250000 
	C3025.675293,2043.998291 3025.342285,2044.042480 3025.026855,2043.987671 
	C3022.949707,2043.626343 3019.708252,2044.961426 3019.232178,2042.026245 
	C3018.656982,2038.478882 3021.919189,2038.286011 3025.270996,2038.015869 
	C3026.000000,2039.500000 3026.000000,2041.000000 3026.000000,2043.250000 
z"
        />
        <path
          fill="#748B9F"
          opacity="1.000000"
          stroke="none"
          d="
M2.000000,2222.666504 
	C4.911254,2222.121094 7.927186,2221.524414 11.044372,2223.209717 
	C9.555140,2227.464600 6.036861,2225.420410 2.783975,2226.016602 
	C2.000000,2225.111084 2.000000,2224.222168 2.000000,2222.666504 
z"
        />
        <path
          fill="#2B3E51"
          opacity="1.000000"
          stroke="none"
          d="
M2229.264404,4029.835205 
	C2233.073730,4030.904297 2236.074951,4031.962891 2239.538086,4033.510742 
	C2236.571289,4034.000000 2233.142578,4034.000000 2228.856934,4034.000000 
	C2226.778320,4032.458008 2227.082520,4031.082764 2229.264404,4029.835205 
z"
        />
        <path
          fill="#172B3E"
          opacity="1.000000"
          stroke="none"
          d="
M2229.754395,4029.300293 
	C2229.423096,4030.827881 2228.774170,4031.809814 2228.062500,4033.395996 
	C2227.111084,4034.000000 2226.222168,4034.000000 2224.666748,4034.000000 
	C2223.993408,4031.874268 2223.986572,4029.748779 2223.979736,4026.827148 
	C2226.258545,4026.017578 2228.059570,4026.961670 2229.754395,4029.300293 
z"
        />
        <path
          fill="#D0D1C8"
          opacity="1.000000"
          stroke="none"
          d="
M3022.152344,1940.368408 
	C3025.882812,1936.594604 3022.579102,1931.089722 3025.750977,1926.693359 
	C3026.000000,1930.083374 3026.000000,1934.166626 3026.000000,1939.125000 
	C3024.815674,1940.252563 3023.631348,1940.505249 3022.152344,1940.368408 
z"
        />
        <path
          fill="#2B3E51"
          opacity="1.000000"
          stroke="none"
          d="
M2.000002,2882.666748 
	C4.125362,2881.962402 6.250721,2881.924805 9.172238,2881.904785 
	C9.611423,2886.128662 6.502930,2885.993164 2.749360,2886.011230 
	C2.000000,2885.111084 2.000000,2884.222168 2.000002,2882.666748 
z"
        />
        <path
          fill="#233444"
          opacity="1.000000"
          stroke="none"
          d="
M2.000000,3244.666504 
	C4.362146,3244.087646 6.795120,3243.589355 9.527689,3245.332520 
	C8.427733,3248.278809 5.885984,3247.943115 2.737243,3247.999512 
	C2.000000,3247.111084 2.000000,3246.222168 2.000000,3244.666504 
z"
        />
        <path
          fill="#172B3E"
          opacity="1.000000"
          stroke="none"
          d="
M3026.000000,3043.333496 
	C3023.808350,3043.861816 3021.401855,3044.243408 3020.046631,3040.990967 
	C3021.391113,3040.249023 3022.926270,3040.135010 3025.230713,3040.010742 
	C3026.000000,3040.888916 3026.000000,3041.777832 3026.000000,3043.333496 
z"
        />
        <path
          fill="#9DAAB4"
          opacity="1.000000"
          stroke="none"
          d="
M1014.666626,4034.000000 
	C1014.003113,4032.489258 1013.078125,4030.443848 1015.297729,4029.949707 
	C1016.888428,4029.595459 1017.338989,4031.236084 1017.820068,4033.281006 
	C1017.111084,4034.000000 1016.222229,4034.000000 1014.666626,4034.000000 
z"
        />
        <path
          fill="#3F5467"
          opacity="1.000000"
          stroke="none"
          d="
M5.656497,2653.538330 
	C5.375439,2655.108398 4.429275,2655.734131 2.594309,2655.962891 
	C2.000000,2655.111084 2.000000,2654.222168 2.000020,2652.666504 
	C3.102865,2652.353760 4.205689,2652.707520 5.656497,2653.538330 
z"
        />
        <path
          fill="#3F5467"
          opacity="1.000000"
          stroke="none"
          d="
M2.000030,2926.666504 
	C3.103856,2926.361084 4.207653,2926.722412 5.654351,2927.567383 
	C5.058680,2928.677734 4.120108,2929.304443 2.590768,2929.965576 
	C2.000000,2929.111084 2.000000,2928.222168 2.000030,2926.666504 
z"
        />
        <path
          fill="#122B42"
          opacity="1.000000"
          stroke="none"
          d="
M3026.000000,3093.500000 
	C3025.324707,3093.635498 3024.717773,3093.245117 3025.687500,3092.185059 
	C3026.000000,3092.000000 3026.000000,3093.000000 3026.000000,3093.500000 
z"
        />
        <path
          fill="#79A9DA"
          opacity="1.000000"
          stroke="none"
          d="
M284.089935,1104.690430 
	C287.905518,1104.143311 292.284546,1103.242676 293.895386,1109.257202 
	C291.743896,1114.014893 289.161530,1114.926636 286.381866,1110.544556 
	C285.392181,1108.984375 284.890747,1107.114380 284.089935,1104.690430 
z"
        />
        <path
          fill="#6DA3DE"
          opacity="1.000000"
          stroke="none"
          d="
M1609.274536,858.385315 
	C1603.149658,854.618591 1596.405884,856.144348 1590.666626,852.815247 
	C1587.031860,850.706787 1583.949341,853.730530 1584.771729,858.773682 
	C1585.338989,862.251770 1584.908325,864.523376 1582.181519,866.516296 
	C1579.167358,868.719238 1576.478516,867.519531 1573.973145,865.659668 
	C1571.540283,863.853638 1572.129272,861.606750 1573.640137,859.551697 
	C1575.062744,857.616882 1575.823975,855.527039 1576.176636,853.159546 
	C1576.703003,849.626038 1579.108521,848.161987 1582.501343,848.062378 
	C1590.988403,847.813354 1599.069092,843.121460 1607.882690,845.864197 
	C1609.257690,846.292053 1611.519531,845.871399 1612.589233,844.942383 
	C1618.060425,840.191223 1620.567871,842.670044 1622.524658,848.312683 
	C1623.696777,851.692871 1627.021606,852.908569 1629.892456,851.509155 
	C1637.423340,847.838013 1647.136475,848.794250 1652.591675,840.602722 
	C1654.544922,837.669678 1658.392334,837.396301 1661.517456,836.579224 
	C1667.161499,835.103455 1670.091675,837.269897 1669.142578,843.044922 
	C1668.115723,849.292419 1671.411743,849.753357 1675.796265,849.993225 
	C1679.428345,850.191895 1683.171021,849.348328 1686.668579,850.717163 
	C1688.857422,851.573730 1690.855591,853.085632 1691.119873,855.643616 
	C1691.421875,858.565979 1688.912231,859.428528 1686.865723,860.482666 
	C1685.983765,860.936890 1685.025391,861.573608 1684.103882,861.570312 
	C1670.607544,861.522522 1660.087036,870.051086 1648.020630,874.109009 
	C1638.886108,877.180847 1636.285156,876.984131 1632.043579,868.665649 
	C1629.926636,864.513733 1626.943237,863.762207 1623.151001,864.046997 
	C1617.849121,864.445190 1613.242798,863.375183 1609.274536,858.385315 
z"
        />
        <path
          fill="#6DA3DE"
          opacity="1.000000"
          stroke="none"
          d="
M1736.161133,862.000000 
	C1726.275635,861.953857 1717.080811,863.061829 1710.312500,854.982483 
	C1709.495605,854.007568 1707.194092,854.019531 1705.580444,854.018677 
	C1701.115601,854.016235 1699.941040,851.517517 1700.013062,847.580078 
	C1700.090332,843.361755 1701.489746,840.387634 1706.152832,840.114441 
	C1708.810059,839.958862 1711.665527,839.413513 1714.115356,840.128052 
	C1726.484863,843.736084 1738.400146,840.441772 1750.428345,838.319702 
	C1759.359985,836.743835 1766.530884,841.700806 1773.735107,845.496826 
	C1777.584595,847.525269 1776.752808,853.415100 1772.900024,856.523621 
	C1767.123901,861.183960 1759.911987,861.109314 1753.100098,861.873901 
	C1747.834961,862.464905 1742.450928,861.997070 1736.161133,862.000000 
z"
        />
        <path
          fill="#6DA3DE"
          opacity="1.000000"
          stroke="none"
          d="
M1444.987061,852.528564 
	C1446.328247,854.893066 1447.649658,856.575623 1447.303955,858.673157 
	C1446.551025,863.242493 1443.033447,866.272644 1439.205688,865.827332 
	C1434.967896,865.334351 1431.857178,861.287659 1432.073120,856.548523 
	C1432.293213,851.716370 1434.384277,850.013062 1440.043335,850.094543 
	C1441.764893,850.119324 1443.262207,850.750916 1444.987061,852.528564 
z"
        />
        <path
          fill="#6DA3DE"
          opacity="1.000000"
          stroke="none"
          d="
M1797.248047,853.282715 
	C1798.358887,849.812622 1796.166382,847.501648 1796.370239,844.869568 
	C1796.537231,842.713745 1797.572021,841.054016 1799.912354,840.884949 
	C1801.834229,840.746155 1802.997559,842.111267 1803.436401,843.756470 
	C1804.974487,849.523376 1803.278687,854.790039 1800.543579,859.807922 
	C1800.070679,860.675476 1799.113770,861.143494 1798.021851,860.883179 
	C1796.946533,860.626831 1796.266113,859.866455 1796.328247,858.816467 
	C1796.423828,857.203918 1796.761963,855.605713 1797.248047,853.282715 
z"
        />
        <path
          fill="#6DA3DE"
          opacity="1.000000"
          stroke="none"
          d="
M1519.680420,921.572571 
	C1519.292603,925.445923 1517.306641,926.314148 1514.743286,925.134460 
	C1509.816650,922.867065 1504.999756,920.823730 1499.443115,922.998718 
	C1498.140015,923.508728 1496.834717,922.906555 1496.641846,921.189697 
	C1496.519897,920.104614 1497.028076,918.869141 1497.950317,918.818970 
	C1505.250488,918.422058 1512.824707,915.418213 1519.680420,921.572571 
z"
        />
        <path
          fill="#78B0E5"
          opacity="1.000000"
          stroke="none"
          d="
M1966.889648,911.910767 
	C1967.632690,910.555847 1968.380249,910.539429 1969.040649,911.507568 
	C1969.178833,911.710266 1969.173462,912.179932 1969.029541,912.377319 
	C1968.341187,913.321899 1967.594116,913.287170 1966.889648,911.910767 
z"
        />
        <path
          fill="#6DA3DE"
          opacity="1.000000"
          stroke="none"
          d="
M1412.711914,874.857178 
	C1411.225220,875.273682 1410.685791,874.753784 1410.916138,873.599182 
	C1410.963989,873.359375 1411.304199,873.036987 1411.546509,873.000916 
	C1412.712769,872.826843 1413.201172,873.394165 1412.711914,874.857178 
z"
        />
        <path
          fill="#6DA3DE"
          opacity="1.000000"
          stroke="none"
          d="
M1778.889648,843.910767 
	C1779.632812,842.556091 1780.380371,842.539551 1781.040649,843.507812 
	C1781.178833,843.710449 1781.173340,844.179993 1781.029541,844.377502 
	C1780.341064,845.322021 1779.594116,845.287292 1778.889648,843.910767 
z"
        />
        <path
          fill="#6DA3DE"
          opacity="1.000000"
          stroke="none"
          d="
M1359.110352,834.089233 
	C1358.367310,835.443970 1357.619873,835.460754 1356.959473,834.492371 
	C1356.821289,834.289734 1356.826782,833.820129 1356.970581,833.622681 
	C1357.659058,832.677856 1358.405884,832.713135 1359.110352,834.089233 
z"
        />
        <path
          fill="#5486C5"
          opacity="1.000000"
          stroke="none"
          d="
M2626.254395,70.842255 
	C2628.383301,73.804855 2627.138428,75.747955 2625.420654,77.479149 
	C2616.312256,86.657944 2606.660156,87.257965 2595.502930,81.583900 
	C2594.445068,81.045815 2593.266113,79.779823 2593.068848,78.684914 
	C2592.263672,74.218590 2602.389648,65.536453 2607.106934,66.724464 
	C2613.225830,68.265511 2619.284180,70.018471 2626.254395,70.842255 
z"
        />
        <path
          fill="#6091CA"
          opacity="1.000000"
          stroke="none"
          d="
M518.473877,368.608459 
	C521.370056,365.832794 523.553406,362.869232 527.421692,365.134338 
	C529.705994,366.471863 529.944214,368.825531 529.940796,371.198730 
	C529.936584,374.049469 529.100586,376.592987 526.161316,377.464630 
	C523.288208,378.316620 520.188843,378.579071 517.782288,376.211945 
	C515.448853,373.916748 516.096313,371.470367 518.473877,368.608459 
z"
        />
        <path
          fill="#5486C5"
          opacity="1.000000"
          stroke="none"
          d="
M734.315186,288.579163 
	C736.510925,288.922455 738.091370,289.361816 739.539307,290.071533 
	C740.386841,290.486908 741.292969,291.378784 740.620300,292.456665 
	C739.333252,294.519043 737.128723,294.141296 735.311951,293.738190 
	C732.389771,293.089874 731.484802,291.353973 734.315186,288.579163 
z"
        />
        <path
          fill="#5486C5"
          opacity="1.000000"
          stroke="none"
          d="
M494.543457,315.572021 
	C491.031677,310.388763 491.226593,309.649536 497.299103,306.095520 
	C498.216858,309.424377 497.154602,312.443176 494.543457,315.572021 
z"
        />
        <path
          fill="#A2BBCE"
          opacity="1.000000"
          stroke="none"
          d="
M836.527100,1598.626587 
	C836.360779,1593.463135 839.275452,1591.980591 843.494019,1592.012451 
	C847.822571,1592.045044 852.153870,1592.090820 856.479980,1591.977417 
	C864.387024,1591.770386 864.396362,1591.725342 866.052795,1583.838379 
	C866.389221,1582.236816 866.388733,1580.481689 868.950317,1579.643555 
	C869.927429,1582.613159 870.850647,1585.615723 871.901428,1588.572998 
	C874.416382,1595.651001 875.522339,1596.414062 882.720093,1595.952271 
	C884.361938,1595.847046 886.071716,1595.653320 887.596191,1595.086182 
	C891.888245,1593.489624 894.287598,1594.770996 896.097107,1599.069458 
	C900.411743,1609.319214 908.075745,1618.046875 908.647217,1630.047119 
	C908.872437,1634.775513 912.961365,1635.747070 916.868469,1632.439087 
	C919.481812,1630.226196 922.208435,1629.555054 925.148743,1630.288818 
	C928.476685,1631.119507 931.654724,1630.347046 934.861145,1630.413330 
	C943.429504,1630.590088 951.999512,1631.590454 960.517639,1632.668701 
	C962.663879,1632.940552 965.833008,1634.271851 966.451843,1635.904785 
	C969.304626,1643.431763 977.483276,1645.250732 981.684204,1650.990356 
	C984.914368,1655.403564 988.785034,1656.206787 993.713501,1656.073364 
	C1000.983459,1655.876465 1008.300598,1655.306519 1015.155518,1659.160767 
	C1017.258667,1660.343140 1020.378723,1660.140259 1022.982300,1659.933228 
	C1032.555542,1659.171753 1042.083496,1661.633179 1051.655396,1660.178955 
	C1053.745361,1659.861328 1055.543945,1660.836548 1057.156250,1662.108276 
	C1058.908569,1663.490601 1060.760498,1663.667480 1062.714722,1662.581177 
	C1070.582275,1658.207275 1077.811157,1661.426392 1084.082764,1665.608398 
	C1089.766113,1669.398193 1096.447144,1670.149414 1102.223755,1673.456665 
	C1105.089722,1675.097656 1108.268433,1673.876221 1110.799805,1670.615601 
	C1115.647217,1664.372437 1121.479736,1664.254150 1126.429321,1669.750000 
	C1128.593872,1672.153564 1129.655151,1675.891724 1133.709229,1675.844238 
	C1139.651855,1675.774658 1146.389282,1678.044434 1151.342041,1674.680176 
	C1158.340942,1669.926147 1165.618164,1669.772217 1173.266602,1670.032837 
	C1179.054565,1670.230103 1184.400269,1669.558838 1189.315430,1665.932617 
	C1193.385742,1662.929443 1197.941284,1663.429321 1202.492065,1665.631348 
	C1218.655029,1673.452271 1235.547119,1678.319336 1253.708374,1678.046875 
	C1257.980713,1677.982666 1262.395508,1677.223999 1267.242310,1679.663086 
	C1268.784058,1680.879272 1269.681152,1681.585693 1271.422119,1681.943359 
	C1274.177368,1681.824829 1275.430786,1680.479370 1276.709839,1678.812622 
	C1281.932739,1672.007202 1288.131714,1667.820190 1297.252197,1671.367798 
	C1299.805664,1672.361084 1302.901611,1672.397583 1303.691772,1668.901489 
	C1304.927368,1663.433228 1307.488037,1663.661377 1311.450317,1666.241089 
	C1315.621582,1668.957031 1322.355347,1667.845337 1323.893066,1675.327881 
	C1323.802856,1677.000977 1323.393799,1677.841064 1322.164551,1678.968628 
	C1320.261475,1679.931274 1318.873901,1680.125732 1316.778809,1680.203125 
	C1309.170166,1681.069580 1302.337646,1682.371948 1295.081543,1684.817139 
	C1293.631958,1685.411987 1292.760742,1685.603271 1291.202881,1685.702393 
	C1287.054810,1684.893799 1283.681519,1684.459351 1281.061035,1688.782593 
	C1279.539673,1690.173828 1278.410156,1690.971558 1276.705566,1692.181152 
	C1268.947510,1698.318237 1260.231689,1702.086426 1255.104980,1710.452515 
	C1252.448364,1714.787842 1247.230835,1714.956421 1242.517090,1714.961548 
	C1233.557617,1714.971313 1224.641357,1714.806030 1216.180664,1718.702515 
	C1212.084473,1720.589233 1208.589478,1718.767700 1205.381958,1715.847900 
	C1195.291626,1706.662476 1188.315796,1706.008789 1176.380493,1712.830933 
	C1164.381958,1719.688965 1157.673462,1716.536621 1155.687988,1702.449829 
	C1155.211426,1706.422485 1156.755249,1710.010010 1154.211792,1712.991211 
	C1151.789673,1715.830444 1148.790405,1716.589600 1144.613770,1715.431152 
	C1138.585571,1711.647339 1140.844238,1703.529541 1134.403931,1699.960693 
	C1131.667114,1697.809082 1129.200928,1696.635864 1125.882812,1695.580688 
	C1119.483643,1691.309570 1112.372070,1693.204956 1105.528809,1690.743896 
	C1104.374512,1690.435913 1103.919189,1690.288208 1102.814697,1689.831787 
	C1100.488281,1688.585083 1099.329834,1687.055176 1097.470703,1685.307495 
	C1094.966431,1681.165405 1092.178711,1680.773560 1088.164062,1683.160400 
	C1086.604126,1683.504761 1085.690308,1683.498901 1084.117676,1683.117432 
	C1081.241943,1681.150635 1079.469482,1680.580933 1076.499146,1682.437134 
	C1065.785889,1689.131226 1054.482178,1688.019409 1044.252563,1679.569824 
	C1040.993530,1676.877930 1037.444946,1677.728638 1033.232300,1677.608887 
	C1032.100098,1677.695068 1031.647339,1677.704834 1030.520996,1677.672119 
	C1027.697510,1677.211182 1025.556763,1676.706909 1022.694580,1677.019653 
	C1016.195679,1679.984131 1020.455627,1686.123169 1017.558594,1690.743652 
	C1014.054321,1695.584351 1009.088806,1696.412354 1003.827393,1698.429810 
	C993.540222,1700.001587 991.122070,1705.894287 991.344238,1714.783081 
	C991.846680,1734.884888 990.347839,1754.926147 987.268921,1775.606079 
	C982.863403,1779.878662 978.017761,1778.778076 972.596375,1778.979736 
	C965.995605,1778.456299 960.264282,1782.149170 953.793335,1778.803833 
	C951.060242,1777.267944 949.136108,1775.828125 946.299805,1774.560059 
	C942.552124,1770.710205 943.300537,1766.303955 942.823486,1762.205444 
	C942.328491,1757.954102 941.451111,1753.899048 937.249023,1750.678711 
	C931.857971,1748.065918 927.062073,1748.569336 921.477783,1750.386353 
	C905.892090,1755.365845 900.967651,1744.016602 894.364990,1733.917969 
	C891.995789,1728.030273 892.357117,1722.686279 892.347412,1717.403198 
	C892.294678,1688.578247 892.366211,1659.753052 892.290344,1630.928345 
	C892.278198,1626.309937 892.542664,1621.635864 890.794739,1616.369873 
	C889.709900,1607.622559 887.800964,1602.791992 877.830078,1607.397461 
	C872.663452,1609.783936 866.245544,1606.540161 859.895996,1604.615601 
	C858.813477,1604.173462 858.389038,1603.977417 857.354431,1603.442627 
	C850.924133,1598.037964 843.374695,1601.525269 836.527100,1598.626587 
z"
        />
        <path
          fill="#9FA2A1"
          opacity="1.000000"
          stroke="none"
          d="
M835.807678,1598.630493 
	C843.296448,1597.800415 850.772217,1594.610229 857.857788,1600.418945 
	C856.802734,1611.018311 854.873413,1620.893677 854.259705,1631.674316 
	C853.386230,1633.823364 852.500488,1635.051392 851.009338,1635.693726 
	C850.429016,1635.943604 849.419067,1636.216675 849.458557,1636.420532 
	C849.701843,1637.675293 850.906494,1636.611450 852.076477,1637.508301 
	C854.598328,1641.802979 854.398071,1645.955811 853.748779,1650.795654 
	C852.830322,1653.518921 850.928528,1655.073608 850.938599,1658.126953 
	C850.930908,1659.683838 850.918213,1660.576904 850.888062,1662.148926 
	C850.831848,1665.805542 851.013489,1668.800537 850.640381,1671.754883 
	C850.317627,1674.310181 850.183838,1677.878418 846.934814,1677.788208 
	C843.752869,1677.700073 843.929504,1674.037231 843.247559,1671.545532 
	C842.337708,1668.220947 844.830994,1662.722900 841.408325,1661.846558 
	C837.098267,1660.743042 828.791016,1660.934570 827.770996,1661.937622 
	C821.742371,1667.865479 815.290649,1664.811401 808.467529,1664.460449 
	C805.751709,1662.189331 806.113892,1659.698730 806.269226,1656.546753 
	C809.710632,1652.464600 813.869629,1650.590942 818.328857,1649.197021 
	C820.423340,1648.542358 823.639832,1648.047607 822.091858,1644.877197 
	C820.955444,1642.549561 818.005188,1643.026978 815.899841,1643.999146 
	C813.613525,1645.054932 812.072388,1647.809082 808.444458,1646.422363 
	C807.683044,1641.000244 808.460571,1636.003052 806.736023,1631.121338 
	C805.153625,1626.641968 806.861328,1623.416626 811.147827,1621.392578 
	C817.705811,1618.295776 824.162170,1614.977905 830.774475,1612.005005 
	C832.939026,1611.031982 833.850952,1609.695190 833.895569,1606.750732 
	C833.186462,1605.186890 832.921021,1604.255981 833.494629,1602.567871 
	C834.462952,1600.996460 835.020813,1600.059937 835.807678,1598.630493 
z"
        />
        <path
          fill="#9DAAB4"
          opacity="1.000000"
          stroke="none"
          d="
M1321.501343,1678.714355 
	C1322.205200,1677.520874 1322.592163,1676.986084 1323.551270,1676.301514 
	C1324.317139,1676.049194 1324.653320,1676.049805 1325.493774,1676.056641 
	C1333.060547,1679.624268 1340.714966,1681.266724 1348.450562,1681.876099 
	C1361.817993,1682.928955 1361.839233,1682.659424 1362.112427,1696.549561 
	C1358.717773,1702.265991 1353.833618,1700.379028 1349.383423,1700.006104 
	C1342.399780,1699.421387 1335.525269,1698.180420 1330.790894,1705.669678 
	C1328.884277,1708.685913 1325.133911,1708.783813 1320.917969,1707.427490 
	C1318.717163,1705.940796 1317.994995,1704.412842 1317.458496,1701.869141 
	C1317.261230,1699.384521 1317.293701,1697.742920 1317.864502,1695.375732 
	C1321.461670,1689.973145 1319.100220,1684.205444 1321.501343,1678.714355 
z"
        />
        <path
          fill="#8AB7E0"
          opacity="1.000000"
          stroke="none"
          d="
M1966.011963,1533.192871 
	C1962.579956,1534.003662 1959.140259,1534.002319 1954.840576,1534.002930 
	C1954.186401,1531.738403 1953.300171,1529.031738 1955.225464,1527.380615 
	C1957.474365,1525.452271 1960.483643,1523.875854 1963.391968,1524.700073 
	C1967.293701,1525.805664 1965.549805,1529.642456 1966.011963,1533.192871 
z"
        />
        <path
          fill="#96B6D1"
          opacity="1.000000"
          stroke="none"
          d="
M1668.282471,1558.820435 
	C1670.383545,1559.546753 1671.623047,1560.533203 1670.881714,1562.446655 
	C1670.733154,1562.829590 1669.659668,1563.255127 1669.281982,1563.080078 
	C1667.454712,1562.233276 1665.763672,1561.172241 1668.282471,1558.820435 
z"
        />
        <path
          fill="#A2BBCE"
          opacity="1.000000"
          stroke="none"
          d="
M833.285950,1602.054443 
	C833.927429,1602.836426 833.949829,1603.739868 833.926697,1605.319214 
	C833.017090,1605.996460 832.153015,1605.997803 830.639587,1605.997070 
	C830.181396,1604.230713 830.704834,1602.699219 833.285950,1602.054443 
z"
        />
        <path
          fill="#96B6D1"
          opacity="1.000000"
          stroke="none"
          d="
M1271.644287,1681.525757 
	C1270.198975,1684.022095 1268.956299,1683.549438 1268.076050,1680.564697 
	C1269.099365,1680.350586 1270.193970,1680.704834 1271.644287,1681.525757 
z"
        />
        <path
          fill="#6AA6E3"
          opacity="1.000000"
          stroke="none"
          d="
M1731.516113,711.956909 
	C1733.483643,714.166870 1732.847778,715.823608 1730.811035,715.692627 
	C1723.633301,715.231323 1721.042969,721.665894 1716.347656,724.882507 
	C1714.955078,725.836548 1714.261597,727.584656 1714.850220,729.299438 
	C1716.177612,733.166138 1715.835815,735.971802 1710.906860,735.924438 
	C1706.763550,735.884644 1701.637207,737.168274 1700.367554,731.256897 
	C1697.509399,717.948792 1704.722900,710.249023 1714.379150,712.045166 
	C1719.968750,713.084717 1725.353760,709.697937 1731.516113,711.956909 
z"
        />
        <path
          fill="#699BD5"
          opacity="1.000000"
          stroke="none"
          d="
M1681.946045,669.973816 
	C1673.091187,672.421265 1664.371094,668.758667 1661.281250,661.767944 
	C1659.733398,658.265991 1662.089844,656.649292 1664.948486,656.290344 
	C1668.559937,655.836914 1672.256836,656.029236 1675.917236,656.008606 
	C1681.244873,655.978577 1686.573120,655.980957 1691.900513,656.020325 
	C1695.342651,656.045776 1699.135742,656.253479 1699.469482,660.637939 
	C1699.809082,665.102295 1696.247437,666.093140 1692.678223,665.918457 
	C1688.707886,665.724182 1685.604614,667.350464 1681.946045,669.973816 
z"
        />
        <path
          fill="#699BD5"
          opacity="1.000000"
          stroke="none"
          d="
M1100.494873,727.068481 
	C1101.462402,728.880005 1101.713867,730.320129 1100.282959,731.050903 
	C1099.154907,731.626892 1097.734985,732.080505 1096.516968,731.943848 
	C1093.322266,731.585693 1088.229370,736.216980 1087.382202,730.621948 
	C1086.837036,727.022156 1092.685913,726.962769 1096.024658,726.167236 
	C1097.226685,725.880859 1098.624634,726.416016 1100.494873,727.068481 
z"
        />
        <path
          fill="#699BD5"
          opacity="1.000000"
          stroke="none"
          d="
M991.468506,738.510986 
	C991.104858,741.077393 989.790039,741.801575 987.967468,741.816040 
	C985.856995,741.832825 984.030884,741.178223 983.075989,739.183044 
	C982.466492,737.909424 982.798157,736.656067 983.894470,735.654541 
	C985.751465,733.958008 987.560120,734.767395 989.271545,735.829956 
	C990.076477,736.329651 990.679016,737.155212 991.468506,738.510986 
z"
        />
        <path
          fill="#6AA6E3"
          opacity="1.000000"
          stroke="none"
          d="
M1848.315308,666.578979 
	C1850.510986,666.922302 1852.091553,667.361755 1853.539429,668.071472 
	C1854.387085,668.486938 1855.293091,669.378723 1854.620483,670.456604 
	C1853.333374,672.518860 1851.128906,672.141357 1849.312134,671.738281 
	C1846.389893,671.089966 1845.485352,669.353760 1848.315308,666.578979 
z"
        />
        <path
          fill="#568CCD"
          opacity="1.000000"
          stroke="none"
          d="
M2682.452881,219.524353 
	C2682.376465,217.561325 2682.751465,216.048279 2684.191162,215.858200 
	C2686.215332,215.590897 2685.964844,217.422104 2685.769287,218.581726 
	C2685.472900,220.336014 2684.758301,221.895554 2682.452881,219.524353 
z"
        />
        <path
          fill="#5F98D9"
          opacity="1.000000"
          stroke="none"
          d="
M1076.553833,564.093384 
	C1074.040527,561.656982 1074.975220,559.983154 1076.901245,558.929565 
	C1079.615112,557.444885 1082.681030,558.021423 1085.620361,558.109741 
	C1087.328125,558.161011 1088.985474,558.643494 1089.381348,560.577209 
	C1089.839966,562.817200 1088.670898,564.451599 1086.684937,565.246338 
	C1083.451782,566.540039 1080.245483,566.145752 1076.553833,564.093384 
z"
        />
        <path
          fill="#94ACC2"
          opacity="1.000000"
          stroke="none"
          d="
M1770.485352,1745.315063 
	C1770.025757,1747.004028 1769.849854,1748.045776 1770.068604,1748.996704 
	C1771.226440,1754.031006 1773.629517,1755.481689 1776.377197,1752.103760 
	C1783.700928,1743.099976 1794.340576,1746.922119 1803.238525,1744.320068 
	C1807.599976,1743.044556 1811.942749,1746.112915 1813.150513,1750.844849 
	C1814.741089,1757.076538 1818.706421,1758.235474 1824.312866,1758.070190 
	C1833.303711,1757.805054 1842.311646,1757.851440 1851.305542,1758.056030 
	C1856.521240,1758.174561 1861.125854,1757.065063 1865.297485,1753.818726 
	C1870.074341,1750.101318 1875.130005,1747.728394 1881.666626,1747.706055 
	C1888.381470,1747.683105 1893.382812,1743.583862 1895.865845,1736.980225 
	C1898.872803,1728.983154 1899.059692,1728.872314 1904.193604,1735.503052 
	C1906.558838,1738.557739 1909.049805,1738.258667 1910.626221,1735.786621 
	C1916.793823,1726.115356 1927.136108,1726.515015 1936.472412,1723.684204 
	C1945.814453,1720.851807 1954.873779,1725.583984 1963.939453,1722.795776 
	C1965.805298,1722.221802 1968.103638,1723.607422 1969.098755,1725.283325 
	C1972.290894,1730.659058 1977.367920,1730.072144 1982.359253,1729.998535 
	C1990.683105,1729.875977 1999.148438,1729.045654 2005.564575,1736.246338 
	C2007.222412,1738.107056 2009.888550,1738.481934 2012.116577,1737.819214 
	C2022.586182,1734.703491 2032.405151,1738.582886 2042.334473,1740.547485 
	C2050.628662,1742.188477 2058.774902,1743.568481 2066.684326,1739.614014 
	C2069.856934,1738.027954 2072.489258,1739.317993 2075.317139,1740.097168 
	C2082.604004,1742.104980 2089.752930,1745.552246 2097.534180,1742.126587 
	C2108.233643,1745.943970 2118.365723,1741.621948 2128.715332,1740.053833 
	C2136.509766,1738.872681 2144.295898,1736.703125 2152.260498,1739.795776 
	C2154.760010,1740.766479 2157.388184,1739.073364 2159.892578,1738.094971 
	C2169.610596,1734.298462 2173.278564,1735.021851 2175.867920,1744.538696 
	C2178.320801,1753.554199 2183.125488,1756.238281 2191.650879,1757.158936 
	C2198.174561,1757.863403 2204.809814,1757.995239 2211.363770,1759.526733 
	C2216.566650,1760.742676 2220.728271,1757.157715 2222.976807,1752.001465 
	C2223.983887,1749.692505 2224.152832,1746.474243 2227.647705,1746.213745 
	C2231.285889,1745.942627 2235.003174,1745.427979 2238.523926,1746.747192 
	C2241.498291,1747.861572 2241.622070,1750.631226 2242.030518,1753.349243 
	C2243.270752,1761.601807 2243.936279,1762.873779 2252.043945,1761.750488 
	C2260.720459,1760.548462 2269.648438,1762.140503 2278.207031,1758.593872 
	C2281.938965,1757.047363 2286.784668,1757.746094 2291.073242,1758.080688 
	C2298.951904,1758.695312 2305.191406,1756.285400 2310.725830,1750.472656 
	C2316.746094,1744.150391 2317.858643,1744.565430 2322.239014,1751.750366 
	C2326.137939,1758.145264 2330.913818,1759.289307 2337.258057,1755.192505 
	C2339.203857,1753.935913 2340.752686,1751.993652 2342.784424,1750.954468 
	C2347.976318,1748.298584 2353.101562,1750.470703 2353.763184,1756.308838 
	C2354.461426,1762.468140 2357.487793,1764.117065 2362.994873,1764.040527 
	C2374.324463,1763.882812 2385.657715,1763.947632 2396.989014,1764.023560 
	C2400.451172,1764.046631 2403.588623,1763.541992 2406.122803,1760.908813 
	C2409.288818,1757.619751 2413.354492,1757.010254 2417.161865,1758.861206 
	C2424.543701,1762.449463 2432.563965,1762.640015 2440.362305,1763.872070 
	C2443.891357,1764.429565 2446.596436,1765.483032 2448.925781,1768.195190 
	C2454.303711,1774.457031 2458.609375,1775.537964 2466.182617,1772.806763 
	C2468.683105,1771.904907 2471.240234,1771.043335 2473.556885,1769.772583 
	C2477.932373,1767.372070 2482.119385,1763.945435 2486.058838,1770.445435 
	C2486.720459,1771.537598 2488.579834,1771.724854 2489.674316,1770.765137 
	C2494.142822,1766.845947 2500.201172,1767.181763 2505.370117,1765.136719 
	C2513.834473,1761.787964 2523.481934,1763.270996 2531.456787,1767.477539 
	C2535.671387,1769.700684 2540.248779,1771.896606 2544.184082,1775.009644 
	C2549.245850,1779.014404 2551.249023,1778.960327 2554.718506,1773.695557 
	C2558.077637,1768.598389 2561.565918,1766.989136 2567.177734,1769.327637 
	C2569.992188,1770.500610 2573.228027,1770.506836 2576.034668,1769.535767 
	C2580.409180,1768.022095 2584.323242,1767.497925 2588.203613,1770.737671 
	C2589.876709,1772.134644 2592.318115,1772.158813 2593.533691,1770.215698 
	C2596.634521,1765.258057 2601.423828,1765.825928 2606.022217,1766.046387 
	C2615.093506,1766.481323 2623.923828,1765.146484 2632.596924,1762.731079 
	C2637.120850,1761.471313 2641.260742,1762.026611 2644.979492,1764.637451 
	C2655.475342,1772.007080 2667.029297,1774.814575 2679.804443,1774.105835 
	C2689.440186,1773.571289 2699.129639,1773.948608 2708.794189,1774.021118 
	C2713.817871,1774.058838 2718.744873,1774.078979 2723.330566,1771.428467 
	C2727.279785,1769.146118 2730.959229,1769.798706 2734.172119,1773.166260 
	C2736.234131,1775.327515 2738.532715,1777.018555 2741.426270,1778.250610 
	C2746.345947,1780.345825 2751.295166,1780.267456 2754.232666,1776.276733 
	C2760.871826,1767.257935 2769.930420,1767.503296 2779.371338,1767.984741 
	C2781.699219,1768.103516 2784.036865,1768.033936 2787.309570,1768.087158 
	C2791.701172,1773.181763 2789.466309,1776.814819 2785.262451,1779.922241 
	C2777.414551,1785.723877 2772.605469,1792.848267 2772.982910,1803.252319 
	C2773.221436,1809.828003 2771.367920,1816.397949 2771.035400,1823.042725 
	C2770.826660,1827.204956 2769.403320,1830.852295 2764.532715,1833.089966 
	C2752.387207,1834.536011 2743.674316,1841.260254 2734.435547,1848.237793 
	C2733.799805,1849.244995 2733.506348,1849.621460 2732.657471,1850.446045 
	C2722.474854,1852.784180 2721.793457,1845.412109 2719.615479,1839.455933 
	C2718.279541,1840.658813 2717.169922,1842.875122 2713.989258,1843.517944 
	C2706.503906,1840.386963 2700.174805,1836.025879 2692.181152,1836.149048 
	C2690.585693,1836.173706 2688.710693,1835.332031 2687.756348,1834.054443 
	C2680.808350,1824.752808 2673.423340,1824.940796 2662.908936,1829.427612 
	C2654.554688,1832.992676 2644.254150,1834.041504 2633.854736,1831.163818 
	C2631.522461,1829.692505 2630.496094,1827.377075 2628.396973,1827.388916 
	C2608.936279,1827.499146 2589.465332,1822.451172 2570.019287,1827.452637 
	C2569.405273,1827.610718 2568.906250,1828.114746 2567.867676,1829.174316 
	C2560.682373,1837.411987 2562.746582,1847.081909 2561.447998,1855.916626 
	C2560.595215,1861.716675 2561.171875,1867.739868 2557.471680,1873.700684 
	C2555.707275,1875.454834 2554.217285,1876.069702 2551.823730,1876.512207 
	C2545.895508,1876.114990 2540.929199,1875.742310 2536.819336,1880.155518 
	C2534.504395,1882.641479 2531.078857,1882.753784 2527.087646,1881.720459 
	C2517.189209,1875.715210 2509.340576,1867.743164 2497.959961,1864.179932 
	C2484.084229,1855.805298 2470.163574,1848.841675 2457.623291,1839.646484 
	C2450.526855,1834.442871 2442.107666,1829.944946 2444.641113,1817.701782 
	C2443.438477,1832.107056 2432.943115,1838.243896 2423.279053,1845.772217 
	C2421.138184,1846.720337 2419.365967,1846.828247 2417.572021,1848.379150 
	C2416.759766,1848.965210 2416.429932,1849.190308 2415.599609,1849.736206 
	C2413.649170,1850.779053 2412.525879,1851.851318 2411.309814,1853.741333 
	C2406.943848,1858.551270 2401.728760,1861.283203 2396.665039,1865.325073 
	C2381.325928,1873.790283 2367.688477,1883.476929 2354.378418,1894.891846 
	C2352.369141,1895.809692 2350.939697,1895.912964 2348.800781,1895.445068 
	C2337.707275,1885.243286 2328.408691,1883.734741 2318.222168,1890.875488 
	C2313.933350,1892.724609 2310.483887,1891.654297 2306.347656,1890.030518 
	C2292.551514,1881.723145 2278.387451,1881.100952 2263.850342,1883.922485 
	C2260.299316,1884.611694 2256.677490,1884.914307 2252.440918,1885.900879 
	C2249.771240,1885.920532 2247.977539,1885.157715 2245.418701,1884.851562 
	C2243.873291,1884.710693 2243.002197,1884.604248 2241.506104,1884.412109 
	C2239.181641,1884.176025 2237.567139,1884.522461 2235.338379,1885.246338 
	C2233.767090,1885.394043 2232.875977,1885.243042 2231.414795,1884.560303 
	C2229.369873,1882.766235 2228.406982,1881.020630 2227.454346,1879.262329 
	C2221.201660,1867.721924 2220.451172,1867.460327 2208.473389,1874.031738 
	C2204.436279,1876.246704 2199.940430,1877.867920 2196.228516,1875.083496 
	C2190.595459,1870.858032 2183.718994,1868.896973 2177.891602,1863.837891 
	C2175.573975,1861.261108 2175.626953,1858.146729 2173.007568,1855.762451 
	C2171.012939,1853.980957 2170.665283,1851.813110 2169.055420,1850.583740 
	C2168.481689,1854.129028 2166.675049,1857.151978 2164.804688,1860.916504 
	C2158.897949,1872.806274 2149.068115,1876.769775 2136.607910,1877.440430 
	C2131.678223,1875.774658 2131.466309,1871.994995 2131.046875,1868.437622 
	C2130.161621,1860.928711 2126.248779,1855.778320 2118.613281,1852.712402 
	C2112.511475,1850.358887 2106.901123,1849.180786 2100.417969,1848.772705 
	C2099.275635,1848.064819 2098.865234,1847.564087 2098.513672,1847.017700 
	C2096.696045,1844.193481 2096.723389,1839.550293 2091.784912,1839.753052 
	C2086.952148,1839.951782 2087.249512,1844.650391 2084.943115,1848.000366 
	C2081.063721,1851.370483 2076.977295,1848.851562 2072.633789,1850.076172 
	C2069.196045,1850.734009 2066.431885,1850.788086 2063.019043,1850.542725 
	C2060.323730,1850.217041 2058.439941,1850.463501 2056.617676,1852.720947 
	C2046.608276,1857.953735 2050.539062,1868.034424 2047.006592,1875.954590 
	C2042.397217,1880.270142 2036.338257,1877.523560 2031.334717,1881.140137 
	C2028.032715,1882.271606 2025.535278,1881.992432 2022.489990,1880.407227 
	C2018.121338,1876.201416 2014.157471,1874.678833 2009.206665,1878.142334 
	C2007.115967,1879.604980 2004.497925,1880.103271 2001.147217,1879.696777 
	C1999.989868,1879.387085 1999.538696,1879.212280 1998.464355,1878.662720 
	C1990.783936,1873.084595 1982.253906,1878.204590 1974.801636,1875.001099 
	C1972.474487,1874.000854 1968.953003,1874.533691 1967.692017,1872.179321 
	C1963.910278,1865.118164 1957.103394,1865.182373 1949.963623,1863.842285 
	C1941.509155,1858.622681 1932.895508,1861.979370 1923.958740,1860.977051 
	C1922.647827,1860.961914 1922.124146,1860.954834 1920.816162,1860.942383 
	C1917.941528,1860.984131 1915.877686,1860.824951 1913.175903,1859.877441 
	C1905.621338,1855.227051 1902.500732,1846.459473 1893.413574,1843.567383 
	C1890.592041,1840.851685 1887.031738,1840.641602 1884.555420,1837.387207 
	C1881.387451,1828.214478 1882.846436,1819.515015 1882.805054,1810.053955 
	C1883.046387,1807.488525 1883.064331,1805.774292 1882.977295,1803.213501 
	C1882.864746,1801.809082 1882.801880,1801.250244 1882.758057,1799.857422 
	C1882.394653,1792.168335 1876.863525,1793.609985 1873.088745,1793.663330 
	C1856.181885,1793.902832 1839.459351,1790.482788 1822.537109,1791.364624 
	C1819.895264,1791.502319 1817.244629,1791.168213 1813.989502,1790.051025 
	C1808.079102,1782.764526 1813.434448,1782.563965 1818.162476,1781.888672 
	C1811.859741,1782.861816 1805.024902,1781.804443 1798.908936,1786.763916 
	C1796.578735,1788.053223 1794.722046,1788.278320 1792.119385,1787.932495 
	C1789.464600,1786.897949 1787.486450,1785.971191 1784.746826,1787.848877 
	C1779.444580,1789.472534 1774.792236,1789.419189 1769.390259,1788.004028 
	C1767.244873,1786.947021 1766.244385,1785.810181 1765.488281,1783.621094 
	C1771.282959,1775.170410 1771.093872,1771.309937 1764.072998,1762.615723 
	C1761.825195,1752.803955 1762.209229,1743.659912 1763.621094,1733.673828 
	C1764.260010,1732.345825 1764.582520,1731.848267 1765.470825,1730.664551 
	C1766.916382,1729.983398 1767.796021,1729.988403 1769.335449,1729.996704 
	C1769.998779,1732.775146 1770.002441,1735.550415 1770.010376,1739.157959 
	C1771.156982,1741.367920 1771.489136,1742.908936 1770.485352,1745.315063 
z"
        />
        <path
          fill="#6B6164"
          opacity="1.000000"
          stroke="none"
          d="
M1611.280518,1739.467773 
	C1612.020996,1754.038086 1612.031738,1768.088989 1612.068115,1783.078125 
	C1612.161255,1788.096069 1613.701538,1791.928345 1614.245483,1796.642456 
	C1614.653809,1798.760254 1614.786743,1800.200317 1614.828857,1802.402588 
	C1614.774414,1814.422607 1612.769409,1825.587646 1613.150635,1836.894897 
	C1613.335815,1842.390625 1613.545654,1847.423828 1619.759888,1849.964478 
	C1623.426758,1851.463745 1623.983521,1854.754028 1622.316650,1859.060059 
	C1620.683228,1862.026489 1619.074951,1864.064819 1616.794922,1866.555908 
	C1615.356567,1867.629395 1614.439575,1868.057373 1612.746826,1868.500000 
	C1607.616211,1868.364868 1602.067505,1870.053345 1601.315430,1863.030884 
	C1600.933594,1859.467163 1598.376709,1861.273438 1596.804321,1860.973877 
	C1587.997803,1859.296509 1579.045410,1859.457520 1569.503906,1858.655029 
	C1563.788940,1851.607910 1567.055420,1844.244995 1566.972900,1836.353149 
	C1569.349854,1811.148926 1568.502441,1786.706665 1568.434692,1762.289062 
	C1568.425171,1758.820679 1570.330078,1754.262451 1564.582275,1752.227783 
	C1558.744385,1752.964966 1557.274658,1756.671143 1557.207153,1760.868774 
	C1557.085083,1768.465942 1556.274536,1776.077759 1557.905884,1783.670166 
	C1559.737305,1792.193115 1559.400269,1800.890259 1558.253540,1809.429565 
	C1557.327637,1816.324585 1554.528442,1822.359863 1545.281250,1822.858521 
	C1529.170288,1815.369141 1511.886841,1816.129517 1495.348877,1810.708984 
	C1489.733887,1808.020996 1489.369507,1802.419678 1488.438599,1798.569946 
	C1486.785645,1791.734619 1482.183350,1790.515381 1476.593140,1788.412842 
	C1476.107666,1787.097290 1476.099731,1786.266724 1476.202148,1784.821777 
	C1478.244141,1781.464355 1481.277466,1781.453491 1484.153442,1781.021851 
	C1486.831177,1780.619995 1489.614380,1780.024048 1489.283569,1776.498779 
	C1488.969360,1773.150391 1486.082764,1774.010864 1483.943726,1773.851685 
	C1480.708374,1773.611084 1477.064819,1773.984253 1477.480713,1768.380859 
	C1479.889404,1765.377075 1482.955566,1765.535767 1485.803223,1764.863525 
	C1477.228638,1763.017456 1477.228638,1763.017456 1483.080444,1756.364136 
	C1481.500854,1755.875000 1479.749268,1756.007568 1477.817017,1754.498047 
	C1476.930908,1753.035522 1476.798462,1752.075195 1477.173096,1750.384766 
	C1478.642456,1748.453491 1480.142334,1747.479248 1481.770020,1747.515259 
	C1490.663574,1747.712402 1494.620605,1742.365112 1497.128906,1734.083252 
	C1498.597656,1732.968140 1499.613647,1732.765259 1501.439941,1733.164185 
	C1510.170532,1734.332520 1518.176025,1734.026978 1527.104126,1733.818604 
	C1539.524292,1734.720581 1551.098267,1732.952393 1563.369141,1735.198975 
	C1565.009277,1735.725952 1565.848511,1735.817261 1567.321289,1735.901123 
	C1582.164673,1736.755005 1596.631958,1734.029053 1611.280518,1739.467773 
z"
        />
        <path
          fill="#626E74"
          opacity="1.000000"
          stroke="none"
          d="
M1766.688110,1781.209351 
	C1766.529663,1783.028931 1766.977051,1784.092529 1767.633179,1785.697998 
	C1768.174561,1794.006104 1766.234863,1801.813721 1768.356689,1810.369995 
	C1768.410645,1834.663086 1767.978027,1858.116577 1764.936035,1882.249023 
	C1760.642090,1891.073975 1743.398071,1893.047852 1737.775391,1886.119873 
	C1736.179810,1884.153931 1735.844360,1881.750122 1736.704834,1879.518433 
	C1739.887695,1871.263428 1739.462524,1862.566040 1740.188477,1853.999634 
	C1740.639282,1848.679810 1741.774536,1843.581055 1744.649292,1838.876099 
	C1747.016479,1835.001709 1747.242065,1830.871216 1744.166016,1826.845581 
	C1733.961060,1838.538940 1737.850586,1852.370728 1737.111450,1865.375854 
	C1736.922241,1868.704468 1736.688232,1872.016724 1735.090698,1875.036987 
	C1732.773315,1879.418091 1728.999878,1880.994263 1724.272705,1880.283203 
	C1719.432495,1879.555298 1717.744263,1876.450439 1718.026855,1871.752197 
	C1718.248169,1868.073242 1717.250244,1864.572876 1714.920532,1860.797119 
	C1714.022461,1859.887817 1713.680664,1859.502075 1712.927490,1858.454712 
	C1710.012573,1852.078735 1712.529907,1846.592896 1713.381836,1840.304688 
	C1714.291138,1836.533203 1715.618408,1833.543335 1714.443726,1829.633301 
	C1714.231812,1828.137695 1714.178223,1827.284668 1714.203613,1825.809082 
	C1714.394653,1823.601440 1714.643188,1822.016602 1714.372681,1819.812988 
	C1714.241821,1818.402588 1714.243652,1817.590576 1714.370117,1816.165283 
	C1714.711304,1813.929077 1715.079712,1812.321045 1715.197144,1810.042480 
	C1715.165039,1808.133545 1715.215576,1806.855347 1715.195679,1804.923340 
	C1715.181274,1803.385620 1715.166992,1802.501953 1715.111572,1800.942383 
	C1714.924072,1798.409302 1714.217773,1796.675415 1713.727539,1794.145142 
	C1713.521729,1792.938843 1713.465576,1792.447754 1713.381348,1791.207275 
	C1713.818970,1785.943481 1715.430786,1781.572632 1715.060547,1776.175537 
	C1715.131104,1771.720459 1715.118042,1768.042358 1714.996460,1763.570068 
	C1715.060791,1761.710449 1715.074341,1760.646362 1715.067627,1758.781006 
	C1715.017944,1756.374146 1714.882690,1754.768921 1715.032837,1752.355225 
	C1714.037354,1744.906616 1716.724487,1738.711548 1717.588379,1731.470215 
	C1718.390259,1729.083984 1719.274658,1727.694092 1721.224243,1726.104004 
	C1729.144165,1727.722900 1736.557617,1727.851807 1744.839600,1728.117310 
	C1747.179565,1729.340820 1748.776489,1730.072876 1751.350098,1729.850586 
	C1752.908813,1729.798706 1753.796631,1729.790405 1755.350342,1729.772705 
	C1758.991699,1729.825684 1761.839600,1730.708252 1765.322021,1731.606445 
	C1765.909546,1742.119141 1765.935181,1752.150146 1766.063843,1763.112549 
	C1770.238281,1769.259888 1769.753174,1774.788818 1766.688110,1781.209351 
z"
        />
        <path
          fill="#79868C"
          opacity="1.000000"
          stroke="none"
          d="
M1319.571045,1706.630127 
	C1324.950439,1705.677124 1330.676514,1707.052368 1330.400269,1698.978760 
	C1330.304688,1696.179199 1333.464355,1695.910278 1335.819092,1696.001709 
	C1341.404297,1696.218384 1347.020264,1695.456543 1352.573608,1697.031738 
	C1355.049805,1697.734253 1357.786987,1697.517456 1361.271484,1697.552124 
	C1363.060791,1697.514526 1363.984375,1697.642822 1365.605103,1697.834717 
	C1369.405762,1704.423950 1368.159180,1711.440186 1368.131592,1718.187988 
	C1368.079468,1730.900024 1368.198120,1743.616211 1367.958984,1757.172607 
	C1367.908203,1758.894531 1367.884644,1759.772583 1367.885742,1761.308960 
	C1366.681519,1771.888550 1370.340698,1782.514404 1363.103760,1792.231201 
	C1358.987793,1795.381836 1354.715210,1795.806396 1349.785156,1796.729980 
	C1348.460693,1796.831543 1347.929932,1796.860107 1346.602295,1796.916626 
	C1344.742310,1796.934570 1343.679077,1796.929199 1341.817261,1796.954834 
	C1336.728760,1797.408936 1332.468140,1797.260376 1327.484131,1796.089111 
	C1323.984375,1794.300171 1321.295898,1792.666016 1317.906250,1790.701904 
	C1315.974243,1788.436035 1315.461548,1786.406860 1315.172607,1783.517090 
	C1315.134277,1781.233032 1314.961060,1779.724365 1315.081787,1777.482788 
	C1315.206665,1773.473633 1316.074219,1770.248657 1315.755371,1766.259766 
	C1315.446777,1763.755859 1315.362061,1761.928833 1315.350830,1759.424561 
	C1315.432983,1755.682129 1316.875488,1752.802002 1316.535156,1749.032837 
	C1316.452759,1747.937500 1316.443970,1747.496338 1316.481934,1746.393799 
	C1316.807617,1743.990356 1317.264771,1742.270752 1317.178711,1739.803955 
	C1317.165894,1738.198364 1317.162598,1737.272827 1317.151978,1735.630859 
	C1317.129272,1732.959595 1317.156128,1731.004639 1317.104736,1728.291504 
	C1317.017578,1724.956787 1316.996460,1722.380127 1317.063232,1719.010620 
	C1317.066406,1716.612427 1316.873047,1715.004883 1317.173096,1712.581665 
	C1317.374146,1710.068726 1317.927979,1708.526123 1319.571045,1706.630127 
z"
        />
        <path
          fill="#E5DDC9"
          opacity="1.000000"
          stroke="none"
          d="
M1717.569580,1757.515625 
	C1718.131348,1758.872803 1718.184692,1759.767944 1718.122559,1761.327759 
	C1714.566406,1765.524048 1710.079102,1764.839478 1705.820068,1764.968384 
	C1692.222168,1765.379883 1678.598022,1764.252075 1664.098145,1765.495728 
	C1662.633057,1765.043213 1662.086060,1764.752930 1660.969971,1763.732910 
	C1658.751343,1759.609375 1658.616699,1755.994385 1658.907959,1751.446533 
	C1659.032837,1748.238647 1658.455444,1745.912598 1658.975342,1742.717529 
	C1658.697510,1738.356934 1658.702759,1734.880737 1659.882935,1730.626709 
	C1665.648071,1720.827515 1674.629150,1725.066406 1683.063477,1723.945312 
	C1692.707031,1723.923096 1701.488159,1723.926758 1711.202759,1723.828125 
	C1712.136353,1723.725708 1712.897705,1723.237061 1713.315430,1723.410400 
	C1714.649536,1723.696411 1715.565918,1723.809204 1717.174072,1723.956055 
	C1719.426514,1725.175781 1719.955200,1726.777344 1719.640137,1729.328735 
	C1719.158569,1736.773193 1718.790039,1743.562256 1718.282104,1751.202026 
	C1716.518433,1753.941772 1714.210693,1754.291260 1711.631836,1754.796631 
	C1714.053345,1754.677002 1715.617188,1755.730225 1717.569580,1757.515625 
z"
        />
        <path
          fill="#98999E"
          opacity="1.000000"
          stroke="none"
          d="
M1661.730713,1741.728882 
	C1662.030518,1744.316284 1661.866333,1746.425171 1661.643066,1749.325928 
	C1660.620972,1751.045288 1659.464478,1751.633667 1658.283691,1752.255371 
	C1655.694702,1753.619141 1650.347046,1751.102295 1650.706421,1756.315918 
	C1651.030029,1761.008545 1656.245361,1758.338989 1658.840698,1759.985352 
	C1659.388062,1760.332642 1660.025635,1760.555908 1661.065430,1761.416992 
	C1661.661621,1762.218140 1661.753662,1762.559448 1661.957642,1763.419189 
	C1662.297607,1764.352295 1662.525635,1764.767090 1662.478027,1765.828369 
	C1659.573486,1768.798828 1656.271362,1767.576050 1653.311768,1768.145508 
	C1655.935059,1768.019531 1658.560913,1767.806030 1661.765991,1768.944580 
	C1662.723877,1769.697388 1663.051392,1770.077026 1663.729248,1771.137695 
	C1664.420654,1772.800903 1664.638550,1773.809692 1664.804932,1775.628296 
	C1665.237549,1779.556885 1664.729614,1782.702759 1664.890625,1786.630371 
	C1664.845825,1789.494629 1664.997559,1791.573486 1664.920776,1794.433105 
	C1664.786377,1796.255981 1664.668091,1797.294312 1664.291992,1799.088379 
	C1663.693237,1800.833862 1663.151733,1801.727905 1661.876221,1803.052368 
	C1659.956787,1804.432861 1658.498047,1804.902466 1656.162231,1804.851929 
	C1651.465454,1802.153198 1646.753906,1803.305664 1641.828125,1801.805176 
	C1639.249634,1800.411011 1638.285889,1798.692017 1637.747070,1795.878662 
	C1638.420044,1789.819946 1636.562012,1784.564453 1637.608154,1778.602783 
	C1637.965698,1774.615234 1638.149902,1771.328979 1639.869263,1768.300049 
	C1641.374390,1765.648438 1641.432129,1762.955444 1641.189087,1759.778687 
	C1640.411377,1749.607666 1642.107056,1739.661255 1647.569214,1730.331543 
	C1649.501709,1738.026245 1654.069946,1741.145020 1661.730713,1741.728882 
z"
        />
        <path
          fill="#4E585F"
          opacity="1.000000"
          stroke="none"
          d="
M1439.430420,1811.171387 
	C1442.849731,1811.733521 1445.595825,1811.926514 1449.169678,1812.102295 
	C1454.633057,1812.243774 1459.603149,1810.941895 1463.664795,1815.883789 
	C1464.135254,1817.179688 1464.256104,1817.726318 1464.403809,1819.126221 
	C1464.355103,1820.552490 1464.279663,1821.125732 1463.932617,1822.533203 
	C1462.883179,1825.015625 1461.726685,1826.349243 1459.504395,1827.909424 
	C1445.578125,1833.058716 1432.073242,1832.142456 1418.700928,1830.209473 
	C1410.819214,1829.070312 1401.897583,1831.753784 1394.822754,1824.083252 
	C1392.393799,1811.396973 1394.761841,1808.219727 1406.219116,1807.848877 
	C1409.158325,1807.753662 1412.045654,1807.123535 1415.806885,1807.263184 
	C1422.579712,1807.892822 1427.728516,1812.939331 1435.002441,1810.361816 
	C1436.799072,1810.217407 1437.797974,1810.403442 1439.430420,1811.171387 
z"
        />
        <path
          fill="#B6BCC1"
          opacity="1.000000"
          stroke="none"
          d="
M1387.529541,1784.679688 
	C1397.468018,1779.942627 1407.273315,1782.363403 1416.979614,1782.695923 
	C1417.296753,1782.706665 1417.602051,1782.913330 1417.922974,1782.982056 
	C1432.009644,1785.991455 1432.009766,1785.990601 1432.003784,1801.097168 
	C1431.972168,1803.536377 1431.942993,1805.091553 1431.078857,1807.267944 
	C1427.377319,1810.629883 1424.213745,1810.828369 1421.016357,1806.740723 
	C1422.073486,1800.784668 1425.509888,1794.589111 1415.265381,1794.089844 
	C1414.871582,1794.106201 1414.452515,1794.369995 1414.452515,1794.369995 
	C1414.452515,1794.369995 1415.082275,1794.184814 1415.679443,1794.173096 
	C1420.554932,1794.768311 1422.134766,1797.200562 1420.734375,1802.047607 
	C1417.518555,1804.927124 1414.284546,1804.935913 1410.192627,1804.442871 
	C1401.591309,1800.532593 1396.482788,1792.925903 1387.915527,1788.911621 
	C1386.966309,1787.362183 1386.862915,1786.356689 1387.529541,1784.679688 
z"
        />
        <path
          fill="#947C7A"
          opacity="1.000000"
          stroke="none"
          d="
M1476.005859,1788.772949 
	C1480.089478,1787.981812 1484.066895,1787.952393 1488.035767,1788.122925 
	C1490.212646,1788.216431 1493.206177,1787.608276 1493.368164,1790.983276 
	C1493.532227,1794.402954 1489.907593,1793.250732 1487.432495,1795.157593 
	C1496.050049,1796.216064 1491.145874,1803.267700 1493.843018,1807.859375 
	C1495.576416,1817.226196 1487.673340,1816.579102 1482.440918,1819.607300 
	C1479.921631,1820.092407 1478.257935,1819.908447 1475.935303,1818.752930 
	C1474.899658,1817.773438 1474.577393,1817.289307 1473.983398,1815.975098 
	C1474.042725,1810.535645 1473.542603,1805.862305 1475.194824,1800.558838 
	C1475.707031,1796.402710 1475.801636,1793.010254 1476.005859,1788.772949 
z"
        />
        <path
          fill="#E7E3DA"
          opacity="1.000000"
          stroke="none"
          d="
M1387.181641,1784.037842 
	C1388.060303,1784.977173 1388.152710,1785.911865 1388.340454,1787.545166 
	C1387.667480,1791.095459 1384.515747,1791.925781 1383.095215,1795.015625 
	C1382.582520,1797.011108 1382.077515,1798.288818 1381.006836,1800.075439 
	C1378.502930,1802.656494 1375.457153,1802.970947 1372.602783,1805.098511 
	C1363.005005,1806.700195 1354.308716,1807.185791 1348.222656,1797.443848 
	C1347.646240,1796.359253 1347.500244,1795.883179 1347.773926,1794.789185 
	C1352.280640,1793.487793 1356.367798,1792.804199 1361.241821,1792.118652 
	C1365.938110,1790.954346 1370.031860,1790.121460 1372.935181,1785.789551 
	C1375.012207,1784.090576 1376.859863,1784.056396 1379.414795,1784.159180 
	C1382.200195,1784.150024 1384.297607,1784.091797 1387.181641,1784.037842 
z"
        />
        <path
          fill="#947C7A"
          opacity="1.000000"
          stroke="none"
          d="
M1497.418945,1733.513428 
	C1498.020752,1735.802246 1498.132812,1737.485596 1497.922974,1739.127808 
	C1496.818604,1747.771606 1494.551392,1749.771851 1485.718994,1749.999268 
	C1483.722900,1750.050659 1481.723877,1749.987183 1478.849854,1749.956909 
	C1475.993774,1748.017822 1475.988647,1745.771729 1477.343506,1742.611572 
	C1477.832520,1740.993774 1477.865845,1740.140503 1477.922852,1738.647217 
	C1482.489990,1729.918213 1489.771240,1731.903442 1497.418945,1733.513428 
z"
        />
        <path
          fill="#9DAAB4"
          opacity="1.000000"
          stroke="none"
          d="
M1368.614502,1757.881592 
	C1366.172852,1756.993042 1364.481445,1755.744995 1364.724609,1753.492554 
	C1366.653198,1735.624756 1365.763794,1717.685669 1366.243652,1698.839600 
	C1368.463623,1698.430420 1369.917236,1699.723877 1369.864990,1702.016113 
	C1369.657349,1711.124512 1371.174072,1720.105957 1370.586914,1729.357300 
	C1370.081787,1737.313232 1369.019775,1745.668335 1370.907593,1753.743042 
	C1371.341675,1755.599365 1371.183350,1756.951660 1368.614502,1757.881592 
z"
        />
        <path
          fill="#D0D1C8"
          opacity="1.000000"
          stroke="none"
          d="
M1637.224854,1778.069824 
	C1639.761963,1782.506348 1641.962402,1786.984009 1640.305176,1792.665894 
	C1639.182495,1794.943237 1637.784912,1795.906982 1635.175781,1796.593262 
	C1628.001099,1796.725586 1621.704834,1797.310303 1614.621948,1796.913086 
	C1612.830688,1794.973389 1612.463135,1793.341064 1613.557617,1790.814331 
	C1617.933960,1790.438965 1620.968018,1788.721069 1623.328125,1785.815552 
	C1626.776733,1781.569946 1631.419556,1779.518677 1637.224854,1778.069824 
z"
        />
        <path
          fill="#947C7A"
          opacity="1.000000"
          stroke="none"
          d="
M1477.293213,1767.721313 
	C1478.376831,1770.276855 1479.306763,1771.931885 1481.844482,1771.556641 
	C1484.106689,1771.222046 1486.307373,1770.316772 1488.568848,1770.165771 
	C1492.027344,1769.934692 1494.853882,1771.096436 1493.153931,1775.272095 
	C1492.074707,1777.922852 1493.996948,1780.497070 1492.584717,1783.104614 
	C1487.887207,1784.924316 1482.880859,1783.547729 1477.167114,1784.142090 
	C1476.148438,1779.387817 1475.984253,1774.568237 1475.805176,1768.851074 
	C1475.790161,1767.953491 1476.508789,1767.365356 1477.293213,1767.721313 
z"
        />
        <path
          fill="#9DAAB4"
          opacity="1.000000"
          stroke="none"
          d="
M1374.332886,1785.520752 
	C1372.625366,1793.488281 1371.735229,1794.034912 1362.533691,1792.612305 
	C1365.379028,1782.737427 1365.316650,1772.787598 1367.395020,1762.538330 
	C1371.265747,1767.913696 1368.641113,1774.817749 1371.471436,1781.475830 
	C1372.862671,1782.896484 1373.806274,1783.713257 1374.332886,1785.520752 
z"
        />
        <path
          fill="#947C7A"
          opacity="1.000000"
          stroke="none"
          d="
M1477.366333,1753.645996 
	C1483.382690,1754.274658 1489.023560,1753.167603 1495.753540,1755.087524 
	C1490.461060,1760.266602 1483.785156,1756.906250 1478.443481,1760.124023 
	C1483.618774,1764.145142 1489.983643,1759.925537 1495.377075,1763.832153 
	C1490.893188,1769.422363 1484.992065,1767.580200 1478.925293,1768.048950 
	C1478.077515,1768.077271 1477.895874,1767.903687 1477.804688,1767.817383 
	C1477.088867,1767.020996 1476.571411,1766.239990 1476.008545,1764.698730 
	C1475.881958,1761.223022 1475.907227,1758.436646 1475.928223,1754.814453 
	C1476.247192,1753.745483 1476.570679,1753.512451 1477.366333,1753.645996 
z"
        />
        <path
          fill="#9FA2A1"
          opacity="1.000000"
          stroke="none"
          d="
M1474.928833,1800.074097 
	C1475.721191,1804.530640 1475.829956,1809.266113 1475.974487,1814.880371 
	C1473.670532,1818.762573 1469.344360,1819.497437 1467.088745,1823.617432 
	C1465.189453,1824.856079 1463.995728,1824.414551 1462.645752,1822.758179 
	C1462.483276,1821.652832 1462.582153,1821.194092 1462.912842,1819.653320 
	C1461.060791,1812.552979 1455.332031,1814.509521 1450.572510,1812.586182 
	C1452.659302,1809.099609 1455.916870,1810.053589 1459.127441,1810.706055 
	C1470.166260,1812.949097 1470.135986,1812.942871 1472.453857,1802.581299 
	C1472.640625,1801.746704 1473.629395,1801.091675 1474.928833,1800.074097 
z"
        />
        <path
          fill="#9FA2A1"
          opacity="1.000000"
          stroke="none"
          d="
M1611.855347,1739.236572 
	C1597.892822,1736.917969 1583.414062,1738.983398 1568.588379,1736.434814 
	C1569.161987,1734.639160 1570.670288,1734.027222 1572.376831,1734.058716 
	C1584.192505,1734.276245 1596.079834,1733.135254 1607.759521,1736.015137 
	C1609.427856,1736.426392 1610.927856,1736.791138 1611.855347,1739.236572 
z"
        />
        <path
          fill="#98999E"
          opacity="1.000000"
          stroke="none"
          d="
M1683.323242,1723.407471 
	C1680.249634,1726.869263 1675.900146,1725.325562 1671.845459,1725.689697 
	C1668.747070,1725.968018 1665.228271,1724.937256 1663.075684,1729.443604 
	C1658.562744,1733.133545 1654.265503,1733.968140 1648.857178,1730.709717 
	C1648.083862,1730.083984 1647.887451,1729.889648 1647.786621,1729.789551 
	C1648.174194,1728.841553 1648.579102,1727.317749 1649.162964,1727.245850 
	C1660.358032,1725.865967 1670.919067,1719.482422 1683.323242,1723.407471 
z"
        />
        <path
          fill="#9FA2A1"
          opacity="1.000000"
          stroke="none"
          d="
M1563.875610,1734.911621 
	C1552.509766,1736.201050 1540.855835,1736.474609 1528.635254,1734.329956 
	C1539.913208,1731.144531 1551.771729,1730.626221 1563.875610,1734.911621 
z"
        />
        <path
          fill="#9FA2A1"
          opacity="1.000000"
          stroke="none"
          d="
M1498.754028,1734.075439 
	C1491.696655,1734.571899 1484.858398,1732.148438 1478.614502,1737.641968 
	C1476.058716,1736.220093 1476.325195,1734.234131 1478.416504,1733.272827 
	C1485.923828,1729.821411 1493.638916,1728.100708 1501.883545,1732.766846 
	C1501.274536,1733.636353 1500.362671,1733.824707 1498.754028,1734.075439 
z"
        />
        <path
          fill="#9FA2A1"
          opacity="1.000000"
          stroke="none"
          d="
M1477.851440,1753.333252 
	C1477.838623,1754.012573 1476.881958,1753.985840 1476.402832,1753.982178 
	C1473.370361,1749.962280 1472.701416,1746.029053 1477.118164,1742.068115 
	C1477.838867,1744.006592 1477.878662,1746.166260 1477.946045,1749.131470 
	C1477.937134,1750.842773 1477.900757,1751.748291 1477.851440,1753.333252 
z"
        />
        <path
          fill="#9FA2A1"
          opacity="1.000000"
          stroke="none"
          d="
M1613.991943,1789.400879 
	C1614.126343,1791.399780 1614.238892,1792.726929 1614.508057,1794.704468 
	C1614.333618,1795.742676 1614.002319,1796.130493 1612.971802,1796.207764 
	C1609.391846,1792.444580 1611.777344,1788.484375 1611.649170,1784.412598 
	C1613.746460,1785.178345 1613.933838,1786.923706 1613.991943,1789.400879 
z"
        />
        <path
          fill="#B6BCC1"
          opacity="1.000000"
          stroke="none"
          d="
M1375.013550,1786.108032 
	C1373.231079,1785.501221 1372.437988,1784.581055 1372.071777,1782.643799 
	C1374.412720,1782.144653 1376.934937,1781.576660 1379.622314,1783.653076 
	C1378.990479,1785.727783 1377.338989,1785.959717 1375.013550,1786.108032 
z"
        />
        <path
          fill="#98999E"
          opacity="1.000000"
          stroke="none"
          d="
M1765.930420,1731.561279 
	C1762.923828,1731.642578 1759.790649,1732.496704 1756.499634,1730.333618 
	C1758.905518,1726.569946 1761.786621,1729.304932 1765.353760,1729.876953 
	C1766.016724,1730.330322 1765.996704,1730.682373 1765.930420,1731.561279 
z"
        />
        <path
          fill="#9FA2A1"
          opacity="1.000000"
          stroke="none"
          d="
M1439.992432,1810.920532 
	C1439.201904,1811.665283 1438.300171,1811.790161 1436.712158,1811.913330 
	C1433.944702,1811.443970 1433.058838,1810.015747 1433.564941,1806.889893 
	C1436.993286,1806.192139 1439.003784,1807.541138 1439.992432,1810.920532 
z"
        />
        <path
          fill="#98999E"
          opacity="1.000000"
          stroke="none"
          d="
M1720.082031,1729.638916 
	C1718.296509,1728.647949 1718.204224,1726.930298 1717.906128,1724.629028 
	C1718.974121,1724.260986 1720.082397,1724.531860 1721.562744,1725.237305 
	C1721.502197,1726.879150 1721.069580,1728.086426 1720.082031,1729.638916 
z"
        />
        <path
          fill="#98999E"
          opacity="1.000000"
          stroke="none"
          d="
M1751.542725,1729.327393 
	C1749.875854,1732.705444 1747.888428,1732.699219 1745.893188,1728.855225 
	C1747.538818,1728.061523 1749.372314,1727.846802 1751.542725,1729.327393 
z"
        />
        <path
          fill="#9FA2A1"
          opacity="1.000000"
          stroke="none"
          d="
M1430.434082,1806.272461 
	C1430.270264,1804.689819 1430.664551,1803.657837 1431.526978,1802.303223 
	C1432.656372,1802.873291 1433.317505,1803.766113 1434.109009,1805.213867 
	C1434.239258,1805.769043 1434.069702,1805.934692 1433.985962,1806.018555 
	C1433.049194,1806.814697 1432.079346,1807.055054 1430.434082,1806.272461 
z"
        />
        <path
          fill="#9FA2A1"
          opacity="1.000000"
          stroke="none"
          d="
M1475.458252,1764.313354 
	C1476.469971,1764.882080 1477.083374,1765.755005 1477.705078,1767.179443 
	C1477.713379,1767.731079 1476.759033,1767.893066 1476.274658,1767.923340 
	C1474.500610,1767.070435 1474.021240,1766.010010 1475.458252,1764.313354 
z"
        />
        <path
          fill="#98999E"
          opacity="1.000000"
          stroke="none"
          d="
M1713.564087,1723.377686 
	C1713.733154,1723.583740 1712.942993,1723.765015 1712.539673,1723.745361 
	C1712.417603,1723.125122 1712.837158,1722.940430 1713.564087,1723.377686 
z"
        />
        <path
          fill="#040F1A"
          opacity="1.000000"
          stroke="none"
          d="
M168.013428,3534.853760 
	C176.347839,3537.981934 185.204819,3538.430908 194.223114,3538.040283 
	C196.860764,3537.925781 199.662354,3537.794678 202.140839,3538.519531 
	C209.493851,3540.668945 215.872833,3538.236328 221.945465,3534.688721 
	C225.105194,3532.842773 228.009720,3529.943115 231.738174,3530.208496 
	C241.886703,3530.930908 252.219498,3530.816162 261.854614,3534.926514 
	C270.631012,3538.670410 279.131409,3543.265137 289.356873,3540.135498 
	C293.928741,3538.735840 298.445251,3541.484131 303.046631,3541.982910 
	C313.541412,3543.120361 323.620636,3540.742920 333.365509,3537.601074 
	C342.202423,3534.751953 351.063354,3532.624756 360.271790,3532.092041 
	C368.901123,3531.592773 375.528809,3525.905518 383.463745,3523.614258 
	C386.477448,3522.744141 386.535675,3519.217529 384.500092,3518.095703 
	C376.679260,3513.786133 372.806458,3503.169189 361.859741,3503.908447 
	C359.750305,3504.050781 358.038086,3502.958252 356.374878,3500.626953 
	C356.141998,3494.835938 360.638275,3494.952881 363.803558,3493.890869 
	C370.124725,3491.770020 376.773804,3490.869385 383.273346,3489.402588 
	C389.817261,3487.926025 396.185791,3486.817139 398.623749,3478.757080 
	C400.132812,3473.768066 405.579041,3472.889648 410.207489,3472.171143 
	C418.511017,3470.882568 426.568085,3468.890625 433.842865,3464.575195 
	C440.290192,3460.750244 447.585541,3463.371582 456.255737,3461.480225 
	C448.554382,3457.752686 441.243225,3458.858398 435.424164,3455.403076 
	C431.936737,3453.332275 428.166168,3451.197021 428.824066,3446.378174 
	C429.478577,3441.584229 433.722565,3439.100830 437.523468,3439.731201 
	C454.543976,3442.555176 471.234314,3435.201904 488.180359,3438.585205 
	C490.134155,3438.975098 492.165131,3438.980225 494.091949,3439.546631 
	C496.397858,3440.224854 498.601837,3441.156250 498.863831,3443.952881 
	C499.069824,3446.152100 497.898499,3448.381348 496.093384,3448.872070 
	C486.762543,3451.408447 484.299377,3459.088867 480.597198,3466.735596 
	C476.348602,3475.511475 468.317017,3481.376465 458.460938,3485.093018 
	C461.318390,3489.370850 465.191864,3488.644287 468.573669,3488.749268 
	C486.715393,3489.311768 504.264740,3492.513184 520.955200,3499.889648 
	C524.012878,3501.240967 527.448181,3502.243164 529.759094,3504.528809 
	C551.142090,3525.677490 578.029175,3522.970215 605.299866,3521.894043 
	C608.252258,3521.333252 610.399658,3521.458740 613.268250,3522.329102 
	C617.753479,3525.854004 622.755798,3528.768799 621.104309,3534.673828 
	C619.329956,3541.018066 613.087952,3542.093018 608.015015,3541.626953 
	C584.376770,3539.455322 563.897888,3550.562744 542.179565,3556.414795 
	C537.606201,3557.647217 533.631897,3560.128174 529.906250,3562.979004 
	C524.193359,3567.349609 517.715271,3570.183838 510.642090,3570.650635 
	C495.184845,3571.670898 479.780334,3573.621826 464.214478,3570.231201 
	C452.188660,3567.611572 439.630981,3569.556641 427.391052,3567.669678 
	C422.964203,3566.987305 419.469116,3569.408936 419.046936,3574.288818 
	C418.492035,3580.702393 423.786194,3579.945557 427.384949,3579.970703 
	C437.354340,3580.040039 447.302246,3578.570068 457.305969,3579.417725 
	C461.266479,3579.753418 465.812469,3579.163574 467.197815,3584.167725 
	C468.328156,3588.250488 465.739716,3591.304932 462.707153,3593.501953 
	C452.487091,3600.906738 440.947815,3606.263916 431.186676,3614.511719 
	C426.818817,3618.202148 421.399139,3616.277832 418.251678,3611.378418 
	C414.279816,3605.196045 408.709045,3604.461914 402.520142,3606.243896 
	C391.398895,3609.445557 380.909943,3607.559814 370.250031,3603.859131 
	C355.520325,3598.746338 329.598938,3609.894531 322.528168,3623.747070 
	C319.698639,3629.290527 321.846741,3631.164307 327.370575,3630.668213 
	C339.655121,3629.565430 351.911255,3628.257568 363.749420,3624.512207 
	C373.224304,3621.514160 382.593140,3621.415283 392.250946,3624.226074 
	C404.034882,3627.655762 415.856567,3631.209717 428.512299,3629.556885 
	C432.378784,3629.051758 436.539673,3629.528809 440.316467,3631.100342 
	C444.324554,3632.767822 446.393829,3635.440186 443.632538,3639.646484 
	C437.602264,3648.833740 430.521790,3656.540527 418.392792,3656.937256 
	C396.795532,3657.644287 375.190002,3657.042725 353.587769,3657.405762 
	C343.744049,3657.571045 333.858704,3656.325439 323.721741,3661.325928 
	C328.228760,3665.306396 336.119568,3665.573975 334.669586,3672.448730 
	C333.274750,3679.061768 326.389771,3679.404541 321.016266,3680.718262 
	C309.872742,3683.442627 298.894867,3683.638672 287.470520,3680.735352 
	C273.481293,3677.179932 259.946564,3683.822266 246.092010,3684.914307 
	C244.491638,3685.040771 242.727509,3686.153320 243.451263,3688.299316 
	C244.131180,3690.314941 246.110764,3690.008301 247.699112,3689.810059 
	C252.335251,3689.231689 256.968018,3688.561035 261.639160,3689.192139 
	C265.393188,3689.699707 269.210602,3690.515381 269.325256,3695.362305 
	C269.424530,3699.558105 266.698822,3702.070557 263.107361,3703.578125 
	C258.524872,3705.501709 253.994110,3704.269531 249.395920,3703.220459 
	C240.270370,3701.138672 236.993927,3703.005615 235.209061,3712.053711 
	C233.986252,3718.252686 231.373962,3723.225342 226.672379,3727.400635 
	C223.280991,3730.412598 220.749725,3734.565674 223.213791,3738.913086 
	C225.634811,3743.184082 230.162201,3741.792969 234.068542,3740.627197 
	C245.709061,3737.154053 257.555634,3735.375977 267.381195,3726.227295 
	C276.713287,3717.538330 289.068848,3716.740234 301.519104,3723.417969 
	C301.093201,3729.395752 296.056213,3728.613525 292.651276,3729.984375 
	C287.857239,3731.914307 283.634949,3734.132080 281.452576,3739.176514 
	C279.840729,3742.901855 281.182861,3745.014404 284.838287,3745.728760 
	C288.501495,3746.444092 292.067780,3745.812988 295.585968,3744.433838 
	C302.580414,3741.692139 309.713959,3740.928955 317.052643,3743.287354 
	C318.906891,3743.883057 321.023499,3744.126709 322.958679,3743.930664 
	C335.793579,3742.630371 348.708160,3741.729980 361.402435,3739.561523 
	C370.140503,3738.068604 375.991852,3730.729492 383.172516,3726.043945 
	C393.565247,3719.262207 402.969269,3710.769775 415.613678,3707.628418 
	C421.253906,3706.227051 426.770081,3705.812500 432.381470,3706.059814 
	C446.648560,3706.688477 459.923035,3704.693604 472.186737,3696.234375 
	C479.251801,3691.360840 488.505798,3690.880615 496.754852,3688.381104 
	C500.651764,3687.200684 504.649017,3689.487061 508.344788,3690.627197 
	C513.927856,3692.349609 518.499390,3692.126465 523.287842,3688.532715 
	C528.831787,3684.372070 535.367981,3683.212891 542.255310,3684.142578 
	C543.734558,3684.342529 544.913147,3685.021484 544.920410,3686.484375 
	C544.924438,3687.298584 544.150635,3688.431641 543.404236,3688.867188 
	C541.992188,3689.691162 540.343262,3690.105713 538.799622,3690.708008 
	C532.129578,3693.309570 526.457886,3697.236572 522.433533,3703.264404 
	C521.319580,3704.933594 520.394836,3707.324463 518.353577,3707.350342 
	C508.839111,3707.470947 500.865662,3712.975098 491.567017,3714.475342 
	C481.489929,3716.101074 471.518768,3718.104980 461.281708,3718.683105 
	C458.355774,3718.848389 455.112366,3719.447266 452.658478,3720.920410 
	C438.123840,3729.645752 421.132629,3730.828613 405.746704,3736.686279 
	C396.575500,3740.177979 386.965942,3743.167480 378.940216,3749.648682 
	C374.408630,3753.307861 368.578308,3755.635742 362.449677,3755.948975 
	C357.394745,3756.207275 352.504181,3757.444824 347.953369,3759.250488 
	C339.452881,3762.623779 330.643219,3763.545898 321.711273,3763.964355 
	C312.447845,3764.398193 303.202393,3763.591797 293.918732,3765.647461 
	C288.684204,3766.806641 282.812561,3767.119873 277.664581,3763.174072 
	C273.887848,3760.279541 269.834778,3758.100830 269.891235,3752.513428 
	C269.926056,3749.069824 267.550446,3747.493164 264.608490,3748.193115 
	C258.161041,3749.727051 251.408112,3749.562500 245.120377,3751.929688 
	C243.198776,3752.653320 241.413773,3753.469482 240.167526,3755.092773 
	C239.601471,3755.830322 239.061462,3756.842773 239.079437,3757.718994 
	C239.113358,3759.371094 240.584076,3759.706787 241.911621,3759.882568 
	C246.041763,3760.429932 249.982376,3759.673096 253.288696,3757.168701 
	C257.272156,3754.151611 260.408142,3753.290039 262.083954,3759.265625 
	C262.656067,3761.305664 264.059601,3762.489258 265.767242,3763.709717 
	C270.556274,3767.131592 270.079102,3772.736328 264.600311,3773.005371 
	C253.757416,3773.537354 244.795593,3780.816895 233.763489,3780.139648 
	C227.777161,3779.772217 222.792099,3782.443848 220.187729,3788.387695 
	C217.124664,3795.378662 217.656693,3796.160645 224.522171,3800.602539 
	C230.041809,3804.173828 234.468048,3809.418213 241.264114,3811.030518 
	C242.465637,3811.315674 243.299957,3812.696777 243.131882,3814.219971 
	C242.918762,3816.152100 241.511261,3817.516357 239.921143,3817.520996 
	C231.379990,3817.545166 224.054138,3822.094482 215.945465,3823.668457 
	C210.676147,3824.691162 205.991531,3822.712891 201.555740,3820.602295 
	C195.477081,3817.709717 189.586334,3817.401123 183.183533,3819.204834 
	C177.461334,3820.816406 171.697922,3822.431641 165.564362,3822.078125 
	C160.176636,3821.767334 156.004440,3824.587402 152.610764,3828.507568 
	C149.623520,3831.958252 150.559555,3835.800293 154.734100,3835.540283 
	C163.378418,3835.001465 171.687805,3839.686035 180.307556,3836.824219 
	C183.228516,3835.854492 185.297974,3836.973633 187.676376,3838.451904 
	C193.986038,3842.373535 199.050568,3847.469482 203.795181,3853.820801 
	C195.190506,3860.270752 185.724014,3857.802734 176.754272,3857.671143 
	C165.313736,3857.502686 153.935852,3856.419922 142.896652,3861.063721 
	C138.711227,3862.824219 133.901810,3863.243652 129.322433,3863.887939 
	C124.124649,3864.619385 119.310577,3863.839355 116.297737,3858.734375 
	C115.420639,3857.247803 114.086433,3856.281982 111.643166,3855.624756 
	C107.287285,3848.604248 110.601341,3841.760742 111.020615,3834.978271 
	C111.406944,3828.728516 112.471313,3822.492432 113.475288,3815.423828 
	C118.295799,3803.165527 119.159866,3790.959229 120.993591,3778.103027 
	C120.915047,3770.215332 122.286766,3763.472412 125.380341,3756.198975 
	C126.821571,3752.303711 128.217514,3749.293701 129.065872,3746.136719 
	C129.472412,3744.624023 130.601044,3742.580811 127.527702,3741.488281 
	C124.038170,3740.247803 125.429916,3737.313721 127.547119,3736.474609 
	C133.848755,3733.976807 133.349411,3728.002686 132.533142,3724.014404 
	C131.388107,3718.418945 132.144241,3713.405029 133.921082,3708.644287 
	C136.817902,3700.882568 138.115494,3692.745117 141.046295,3684.311035 
	C154.739853,3682.513184 164.825348,3674.948486 174.760895,3667.134766 
	C190.516266,3654.744873 209.049789,3652.142334 228.283310,3651.261475 
	C239.809006,3650.733887 251.348846,3652.526611 263.937469,3651.372803 
	C254.174911,3645.561523 247.190247,3643.485596 238.353104,3644.168945 
	C232.286591,3644.638184 226.416412,3643.521729 220.488815,3642.825928 
	C217.169617,3642.436523 213.676437,3641.798340 212.752869,3637.760254 
	C211.979187,3634.377441 214.154037,3632.249268 216.343353,3630.063721 
	C224.710052,3621.710449 233.534378,3614.905273 246.244400,3613.939941 
	C251.529419,3613.538818 261.535767,3612.913330 260.650604,3606.794678 
	C259.678680,3600.075439 251.030899,3605.188232 245.932037,3605.210938 
	C224.734711,3605.305176 204.695328,3610.752686 185.768799,3620.218018 
	C180.648743,3622.778564 174.733292,3622.887451 169.762787,3625.682129 
	C163.507523,3629.199463 163.303619,3633.197021 169.471664,3636.333008 
	C175.001877,3639.145020 181.091003,3641.608643 179.260086,3649.210449 
	C177.475189,3656.621582 171.808807,3660.830322 164.453735,3662.443115 
	C160.518051,3663.305908 156.512405,3663.254150 152.089996,3661.062500 
	C144.267090,3642.093506 152.481262,3623.456787 168.872238,3613.297363 
	C181.281250,3605.605957 194.277176,3602.424561 208.034821,3600.170410 
	C221.482880,3597.966797 233.552185,3593.103271 242.722580,3582.454346 
	C245.676346,3579.024170 249.671387,3577.419922 254.165497,3577.095703 
	C258.808167,3576.760986 263.437683,3575.578857 268.017517,3575.606934 
	C279.585144,3575.677490 285.326569,3569.253906 288.472870,3559.085449 
	C265.038422,3557.049072 241.845764,3553.609375 217.743378,3557.222168 
	C211.789734,3553.488770 206.601471,3549.792969 198.808929,3548.124756 
	C202.907440,3551.253174 207.652618,3551.038330 208.828232,3556.279297 
	C207.099701,3560.463867 203.692657,3561.003418 200.535904,3561.556885 
	C192.986801,3562.880127 186.149536,3565.317871 180.242599,3570.582031 
	C175.071060,3575.191406 169.594055,3575.266357 164.538879,3568.569824 
	C163.363785,3560.967285 164.507935,3554.099854 166.588089,3547.465088 
	C167.814346,3543.553467 168.006714,3539.688477 168.013428,3534.853760 
z"
        />
        <path
          fill="#05101B"
          opacity="1.000000"
          stroke="none"
          d="
M812.479309,3673.377197 
	C811.997559,3674.000000 811.000000,3674.000000 810.501221,3674.000000 
	C805.959351,3678.384033 799.207214,3676.979736 794.498535,3681.504883 
	C787.328735,3681.993408 780.663513,3681.971924 773.998535,3682.006836 
	C767.676025,3682.040039 761.679138,3680.538086 755.501038,3679.327148 
	C741.491089,3676.580322 726.946716,3676.135254 713.445068,3682.400635 
	C706.032043,3685.840820 698.197632,3686.499268 690.442688,3688.148682 
	C675.119812,3691.407715 661.026855,3687.550293 646.776978,3682.778564 
	C641.514526,3681.016602 635.718872,3680.785156 630.139893,3680.060059 
	C627.824280,3679.759033 625.569092,3679.735840 623.503296,3678.324707 
	C617.125977,3673.968506 617.309814,3670.565674 624.168457,3666.898193 
	C627.775085,3664.970215 631.195923,3663.596436 635.506287,3665.367432 
	C638.415161,3666.562988 641.853394,3666.676270 645.338989,3664.474854 
	C643.161682,3660.484131 639.122131,3660.097656 636.349487,3658.029785 
	C634.081665,3656.338867 632.302368,3654.233154 632.904541,3651.354980 
	C633.465210,3648.675293 636.050415,3648.300781 638.415344,3647.914795 
	C648.863342,3646.209961 659.258850,3643.593994 669.763245,3643.053223 
	C675.753845,3642.744873 682.302795,3644.969727 687.883789,3647.583496 
	C692.558472,3649.772705 696.487061,3650.177002 701.200073,3648.718506 
	C703.672058,3647.953369 706.600342,3647.575195 709.082336,3648.118896 
	C719.667725,3650.437500 730.156128,3647.034180 740.694031,3647.941895 
	C742.632935,3648.108887 744.784119,3647.948486 746.578308,3647.262207 
	C752.339417,3645.059082 753.945129,3638.710205 749.164429,3634.430420 
	C737.959045,3624.398193 725.815063,3615.583496 710.739441,3612.209717 
	C703.295166,3610.543701 695.554871,3610.651611 688.049927,3609.694580 
	C676.725525,3608.250000 664.756165,3610.411621 654.080933,3604.509033 
	C652.627625,3603.705566 650.571716,3603.749512 649.442871,3605.144531 
	C648.158386,3606.732178 649.411560,3608.208008 650.543945,3609.464600 
	C650.989807,3609.959717 651.541504,3610.377930 651.906982,3610.923340 
	C653.788025,3613.729980 658.952454,3615.221436 656.632690,3619.712402 
	C654.541138,3623.761719 650.279236,3625.930420 645.796265,3625.823975 
	C637.051208,3625.615967 629.602478,3629.074463 622.181519,3632.916504 
	C620.999939,3633.528320 619.311035,3633.920410 618.780640,3634.909424 
	C614.462463,3642.961182 607.090027,3642.403809 598.843079,3641.972168 
	C598.035950,3633.108643 593.515991,3630.833984 585.046265,3631.098633 
	C569.758240,3631.576660 554.486389,3630.713623 539.186584,3632.818359 
	C524.234253,3634.875488 509.321655,3631.464111 494.403107,3630.384277 
	C489.054810,3629.997314 484.333435,3626.991699 479.744507,3624.268555 
	C475.308105,3621.635986 470.969421,3618.488770 472.956696,3612.438721 
	C474.851776,3606.668701 479.896027,3605.373535 485.183441,3606.362793 
	C497.970642,3608.754883 508.397095,3601.855225 519.595093,3598.063721 
	C523.715454,3596.668701 527.588318,3594.623779 532.804199,3596.447266 
	C535.218567,3606.551758 540.976257,3610.421387 550.782837,3609.334473 
	C554.474304,3608.925293 557.745483,3611.247314 560.874023,3613.230957 
	C571.803162,3620.160645 583.374268,3619.869141 595.511292,3616.935303 
	C607.774292,3613.970947 618.584351,3608.996582 626.901672,3599.191650 
	C629.269775,3596.399658 632.348267,3594.264404 635.958252,3593.170654 
	C639.770996,3592.015381 641.812683,3589.472656 643.354370,3585.774902 
	C646.539001,3578.137207 646.786072,3578.240234 655.572876,3575.933838 
	C647.296448,3574.697266 647.283020,3574.667969 640.429565,3577.814453 
	C638.306213,3578.789307 636.218384,3580.224365 633.917847,3580.243408 
	C624.253296,3580.322510 617.245728,3583.964111 611.466248,3592.285645 
	C606.882690,3598.884521 597.691956,3599.783936 590.447632,3599.765137 
	C577.123352,3599.730957 563.904602,3601.865234 550.594727,3601.036377 
	C547.925964,3600.870117 545.264832,3600.735596 542.250854,3598.927734 
	C539.779968,3590.674316 546.185486,3591.311279 550.172302,3590.896973 
	C565.180786,3589.336914 579.486694,3585.445801 593.342224,3579.605225 
	C602.263428,3575.844727 610.866211,3571.619385 617.930420,3564.785400 
	C619.843628,3562.934570 621.918518,3560.696777 624.503479,3560.455566 
	C635.810059,3559.400391 642.587646,3551.939209 649.699646,3544.270020 
	C655.486450,3538.030029 662.919006,3534.217285 672.719238,3536.368652 
	C677.010864,3539.828369 674.527954,3543.489990 674.246948,3546.942383 
	C673.374817,3557.656250 678.001648,3562.944092 688.899719,3563.605225 
	C697.535889,3564.129150 704.525574,3556.899902 704.657349,3547.721924 
	C704.708740,3544.142334 702.632202,3543.222656 699.926880,3542.439697 
	C696.108521,3541.334717 692.012817,3541.211426 687.912964,3538.699707 
	C686.909912,3537.283936 686.721863,3536.315918 687.028931,3534.560059 
	C688.878235,3531.577148 691.226807,3530.655518 694.571411,3530.143555 
	C699.433228,3530.960693 703.393005,3530.078125 706.757263,3527.887451 
	C720.240540,3519.108154 734.945007,3513.594971 750.689331,3510.620605 
	C754.720520,3509.859131 756.825073,3508.012207 753.954590,3503.780029 
	C751.983765,3500.874268 751.255554,3497.740479 753.397583,3493.822021 
	C754.775085,3492.731201 755.734131,3492.403320 757.547363,3492.246338 
	C764.209595,3494.244629 771.389038,3491.182373 776.717468,3497.496826 
	C773.266479,3508.319824 779.824097,3510.989258 787.607544,3513.652100 
	C791.034851,3514.824463 793.895325,3517.284668 798.446350,3515.413086 
	C800.508789,3515.096680 801.856567,3514.778564 803.833740,3514.088379 
	C807.236267,3513.237549 810.017212,3513.269287 813.453369,3513.301270 
	C814.535095,3513.367188 814.959656,3513.406494 816.000977,3513.521973 
	C818.207703,3513.826172 819.758972,3513.750488 821.774902,3512.742188 
	C823.483704,3511.978760 824.691223,3511.624512 826.519897,3511.057373 
	C828.005066,3510.604248 828.866455,3510.357910 830.396729,3509.914062 
	C834.312012,3508.914795 837.558594,3508.108887 841.650818,3508.187012 
	C849.869568,3509.363525 854.260437,3513.682861 855.277527,3521.183105 
	C856.170044,3527.764160 852.124512,3530.811523 845.569031,3532.611816 
	C854.942444,3541.742920 865.777832,3539.886719 875.683411,3537.794434 
	C887.268311,3535.346924 898.464172,3531.477051 911.119873,3535.104004 
	C921.304321,3535.314453 929.107544,3541.526123 938.269897,3542.867676 
	C934.521362,3541.441162 930.854370,3539.930908 927.635498,3537.542480 
	C921.703003,3533.140625 919.414856,3525.435059 922.459656,3518.852051 
	C925.447205,3512.392578 930.677856,3508.951904 937.834473,3510.877930 
	C945.654663,3512.982178 951.407776,3509.922363 957.416626,3504.625977 
	C967.138794,3501.463867 976.583618,3503.896729 986.072571,3502.117920 
	C981.071533,3501.558105 976.421204,3500.413818 976.005432,3494.508545 
	C975.541260,3487.916260 979.374329,3483.881836 985.039917,3481.609619 
	C990.660034,3479.355713 996.107727,3479.600098 1000.801392,3485.147949 
	C1000.270874,3489.974121 1000.716248,3492.720703 1006.111450,3493.002441 
	C1024.185547,3493.946533 1040.385864,3503.909424 1058.644287,3504.228271 
	C1059.610107,3504.245117 1060.581787,3504.720703 1061.494995,3505.124268 
	C1065.172729,3506.749023 1069.410400,3508.220459 1069.214722,3513.148682 
	C1069.015015,3518.182617 1064.626831,3519.815918 1060.736450,3520.112549 
	C1047.853638,3521.093018 1034.828369,3524.352539 1022.038574,3520.614014 
	C1012.333191,3517.777344 1002.527466,3516.930664 992.391602,3516.448975 
	C994.232605,3517.359863 996.145447,3517.050293 997.871521,3517.616943 
	C1003.907410,3519.597900 1011.035889,3521.115234 1011.409424,3528.878418 
	C1011.765259,3536.274658 1005.659424,3540.704834 999.630127,3542.120605 
	C984.023865,3545.785400 969.352905,3552.192139 954.019348,3556.433105 
	C942.183472,3559.706299 930.825745,3555.017822 920.279419,3549.992920 
	C907.504456,3543.906250 896.460693,3545.306885 883.288269,3550.947998 
	C864.331482,3559.065918 845.708313,3569.235596 824.146362,3570.424561 
	C813.985352,3570.984619 804.785889,3575.112549 799.659241,3584.842773 
	C824.158630,3593.461182 848.613342,3598.109619 874.630920,3589.996094 
	C884.232666,3587.001953 894.587402,3587.882568 902.783264,3579.758545 
	C907.780884,3574.805176 916.314026,3577.031494 920.606812,3582.404541 
	C925.809143,3588.916260 923.015076,3594.107422 917.849548,3598.910156 
	C916.139893,3600.499512 914.302063,3601.950439 912.615723,3603.548340 
	C910.611389,3605.448242 906.281433,3606.272461 907.697632,3610.007080 
	C908.793396,3612.896484 912.431702,3613.422363 915.364563,3614.065186 
	C925.093384,3616.197266 934.437073,3611.822510 946.565613,3613.242676 
	C941.105408,3608.646240 936.484131,3605.515381 939.074524,3599.834473 
	C941.662964,3594.157959 946.971863,3593.208008 952.509521,3592.986084 
	C957.224976,3592.797119 961.766968,3593.512939 966.261108,3595.035889 
	C973.518738,3597.495850 980.849060,3599.746094 988.183899,3601.970459 
	C992.104736,3603.159180 994.465698,3603.187988 993.491943,3597.526123 
	C991.150757,3583.911865 993.485535,3580.895752 1006.666016,3578.655762 
	C1009.955139,3578.096680 1013.220642,3577.448730 1016.464111,3576.677246 
	C1032.643921,3572.829834 1048.829468,3572.338867 1064.668335,3577.912354 
	C1070.773315,3580.060303 1076.845947,3580.842041 1083.812988,3581.291016 
	C1087.573730,3588.949219 1082.118774,3590.089111 1077.312988,3591.611084 
	C1072.651978,3593.086670 1067.670898,3591.104492 1062.503296,3592.271484 
	C1064.616821,3594.988281 1067.128784,3594.586182 1069.302612,3594.998535 
	C1083.258667,3597.645264 1085.892212,3599.653564 1086.783081,3613.403320 
	C1087.442871,3623.584961 1089.608276,3633.669434 1088.897461,3643.958740 
	C1088.596680,3648.312988 1088.429688,3652.582764 1084.660278,3656.505127 
	C1071.429565,3657.582031 1059.270508,3654.001709 1046.889771,3652.575195 
	C1035.797119,3651.296875 1025.807861,3651.341309 1016.647583,3658.360352 
	C1011.451538,3662.341797 1005.401123,3664.411133 998.937439,3663.898193 
	C984.484680,3662.751709 970.787903,3665.809570 957.170410,3670.114746 
	C952.465942,3671.602051 947.631042,3673.461914 942.602051,3673.067871 
	C919.319092,3671.243164 896.305115,3675.321045 873.141907,3676.059326 
	C866.286743,3676.277832 861.786560,3674.123779 862.263489,3666.737549 
	C862.645996,3660.814453 859.932556,3660.570801 855.132446,3660.674316 
	C840.578674,3660.988281 826.186096,3664.296143 810.779358,3662.844971 
	C813.278320,3666.019043 814.968628,3669.123291 812.479309,3673.377197 
z"
        />
        <path
          fill="#0B1926"
          opacity="1.000000"
          stroke="none"
          d="
M674.777649,3538.630859 
	C663.420471,3537.673828 655.065674,3541.297119 648.381226,3550.078613 
	C643.537415,3556.442139 638.367065,3563.677490 628.250916,3562.127441 
	C624.979980,3561.626221 622.737061,3564.097656 620.719238,3566.313721 
	C611.252625,3576.711182 598.353088,3581.000244 585.857666,3586.006592 
	C573.190857,3591.081787 559.903870,3593.167236 546.380798,3594.071045 
	C544.628357,3594.188477 543.177795,3594.772705 542.171631,3597.211914 
	C540.076904,3599.972168 537.866333,3600.055908 534.745728,3598.527588 
	C529.318726,3597.804688 525.287842,3599.444092 520.852234,3601.096436 
	C508.561859,3605.674072 496.721619,3613.829346 482.429718,3609.154297 
	C478.257355,3607.789551 475.963196,3610.225830 474.680298,3613.426514 
	C473.260162,3616.969727 475.861847,3618.844727 478.701843,3620.322754 
	C487.240051,3624.767090 495.579529,3629.758545 505.903412,3628.028076 
	C512.280151,3626.959229 518.267883,3629.836426 524.587280,3630.161865 
	C536.281799,3630.764160 547.613647,3627.567383 559.224487,3627.757080 
	C571.417480,3627.956299 583.613831,3627.943848 595.807861,3628.089844 
	C599.089111,3628.129150 601.737671,3629.783936 602.905334,3632.734131 
	C604.608521,3637.036865 598.872559,3637.429688 598.097778,3641.300293 
	C595.845337,3641.968018 593.720215,3641.975342 590.797485,3641.981445 
	C577.358765,3642.556641 565.309326,3646.176758 553.115906,3649.087646 
	C548.908508,3650.092285 546.134033,3652.496338 544.249390,3656.322266 
	C542.065002,3660.756592 538.607849,3663.618896 532.724731,3663.986328 
	C527.935120,3664.038086 523.870178,3664.062988 518.934692,3664.104004 
	C516.674011,3665.726807 514.462708,3665.907959 512.412476,3667.547852 
	C511.093109,3667.971191 510.209442,3667.971436 508.663086,3667.970215 
	C484.331635,3671.623535 459.961212,3669.962891 437.621246,3682.595459 
	C423.521759,3690.568359 406.419556,3690.399902 389.572906,3688.795410 
	C395.667236,3695.512695 392.262238,3700.080078 386.482758,3702.308105 
	C373.173431,3707.438721 361.103546,3715.412598 346.603973,3719.559326 
	C337.885437,3719.641846 330.065155,3722.035889 321.601196,3720.811768 
	C316.383698,3720.057373 310.214081,3725.068359 302.869354,3724.006104 
	C299.677368,3723.944824 297.011658,3724.599365 295.073517,3723.694824 
	C284.765930,3718.884277 276.482391,3724.624268 267.789276,3728.412109 
	C267.187439,3728.674316 266.253876,3728.973877 266.135010,3729.428711 
	C263.346832,3740.087402 253.210587,3737.992432 246.052673,3740.828369 
	C241.182846,3742.757812 235.861801,3743.318604 230.821732,3745.166260 
	C223.019287,3748.026367 217.151581,3742.005615 218.998428,3733.963623 
	C219.980591,3729.686279 221.165070,3725.967773 225.848511,3723.858887 
	C231.014313,3721.532471 231.740784,3716.409180 232.060333,3711.176270 
	C232.719711,3700.377441 239.695953,3695.914062 249.684448,3699.935791 
	C254.506470,3701.876953 259.177094,3703.267334 264.000549,3700.522949 
	C266.045776,3699.359131 267.871613,3697.505127 267.433746,3694.887695 
	C266.915100,3691.787598 264.160645,3691.973877 261.882141,3692.052490 
	C256.941223,3692.222168 251.998184,3691.420166 247.088837,3693.377686 
	C242.095352,3695.368408 239.028076,3693.645264 238.246368,3689.320557 
	C237.497559,3685.178467 240.342377,3680.737305 244.497482,3680.799805 
	C255.520432,3680.965088 266.110077,3678.622070 276.763519,3676.324219 
	C280.312408,3675.558838 284.164337,3675.373535 287.593933,3676.964111 
	C301.467651,3683.396729 314.926361,3679.059814 328.392242,3675.511230 
	C330.474426,3674.962646 333.519958,3674.212646 332.950653,3671.204834 
	C332.436615,3668.489502 330.196625,3666.190918 327.196136,3666.780029 
	C323.129913,3667.578125 318.992889,3667.575928 318.647797,3662.963623 
	C318.309204,3658.438965 322.352936,3656.769531 326.741821,3655.912354 
	C337.608948,3653.789062 348.326080,3653.232422 359.343750,3655.233887 
	C364.760712,3656.218018 370.435150,3654.123535 376.067535,3653.789551 
	C385.699005,3653.218506 395.117004,3654.051514 404.659790,3654.889404 
	C410.800140,3655.428711 417.188232,3654.188477 423.352844,3653.105469 
	C429.048523,3652.104736 433.871368,3649.637207 436.438538,3643.599365 
	C437.644257,3640.763428 440.658142,3638.696289 443.412445,3635.664795 
	C436.069458,3631.315674 428.248352,3632.300537 420.745239,3632.944092 
	C417.410492,3633.230225 414.405151,3633.763428 411.054016,3632.718262 
	C403.768188,3630.446045 396.252960,3628.899414 388.994843,3626.551270 
	C381.407288,3624.096680 373.793732,3624.266846 366.650940,3626.941162 
	C352.848724,3632.108398 338.120789,3631.662598 324.063873,3634.998047 
	C320.771820,3635.779053 319.022217,3634.170654 317.362549,3631.991699 
	C315.414154,3629.434082 316.317047,3626.842773 317.808624,3624.554443 
	C323.880890,3615.239014 331.189514,3607.163330 342.182556,3603.757324 
	C349.131317,3601.604004 356.329010,3600.255615 363.410706,3598.528320 
	C365.503113,3598.018066 367.356506,3598.675293 369.149353,3599.622559 
	C380.215210,3605.468506 391.487793,3606.365479 403.612640,3602.654785 
	C412.523407,3599.927490 415.483948,3601.879395 420.638397,3610.628662 
	C422.491425,3613.774414 425.208160,3614.531250 428.283020,3613.415039 
	C430.062286,3612.769043 431.765564,3611.367676 433.010132,3609.890869 
	C437.879944,3604.113525 444.238617,3600.634766 450.926453,3597.514648 
	C455.761902,3595.259033 460.225494,3592.336914 464.109222,3588.649414 
	C465.326935,3587.493164 466.029510,3585.897461 465.304108,3584.120117 
	C464.538300,3582.243164 462.727234,3581.794922 461.162659,3582.140625 
	C451.754089,3584.218506 442.114655,3580.055176 432.639099,3583.660156 
	C428.438507,3585.258057 423.715271,3582.441895 418.961914,3582.951904 
	C416.056641,3583.263672 413.988281,3580.709229 414.639160,3576.673828 
	C416.431793,3565.558105 420.347687,3562.086426 431.290894,3564.440918 
	C437.258514,3565.724609 443.123016,3566.044922 449.095551,3565.976562 
	C457.102295,3565.885010 465.095459,3565.667480 472.613220,3569.173340 
	C473.496277,3569.585205 474.529480,3569.741455 475.512054,3569.862793 
	C482.792236,3570.762939 489.257477,3565.723145 497.511963,3568.842041 
	C503.666656,3571.167480 511.692627,3567.606201 518.769348,3565.914307 
	C522.475281,3565.028320 525.554993,3562.799072 528.135620,3560.421387 
	C533.289917,3555.672119 539.482727,3553.810791 545.914856,3551.940430 
	C555.145264,3549.256592 564.566101,3546.848145 573.311768,3542.979736 
	C582.748230,3538.805664 592.507141,3538.482178 602.249939,3538.755859 
	C605.961731,3538.860352 609.386902,3538.046631 612.958191,3537.988281 
	C615.738220,3537.942627 618.359985,3537.414551 619.397095,3534.346924 
	C620.399475,3531.382080 617.850281,3530.376709 616.246338,3528.782715 
	C615.345825,3527.887695 614.849243,3526.586426 614.059937,3524.768066 
	C616.446228,3519.653076 620.825684,3518.538574 625.047241,3519.428223 
	C640.877258,3522.764160 658.223328,3518.427979 672.655518,3529.313721 
	C676.454712,3532.179932 682.467834,3530.001465 687.434082,3533.378174 
	C687.999390,3534.886719 687.996399,3535.773438 687.990601,3537.325195 
	C684.168335,3541.732178 679.955872,3541.534180 674.777649,3538.630859 
z"
        />
        <path
          fill="#010A16"
          opacity="1.000000"
          stroke="none"
          d="
M446.348999,3917.867188 
	C440.054077,3922.464600 433.572937,3925.132080 427.358185,3928.281250 
	C426.471008,3928.730957 425.403168,3929.597900 424.641418,3929.402832 
	C416.599365,3927.343994 410.035736,3932.208740 402.822235,3933.926270 
	C392.705902,3936.334473 383.089996,3933.727783 373.739471,3930.644775 
	C364.314270,3927.537109 355.260193,3923.303955 345.321106,3921.858398 
	C343.242340,3921.555908 341.375427,3920.779053 340.896912,3918.526611 
	C340.408112,3916.225830 342.115570,3914.845459 343.650726,3913.610596 
	C348.546143,3909.673096 354.674133,3908.961914 360.484650,3908.227295 
	C367.274323,3907.368408 373.344452,3905.972412 378.365540,3900.834229 
	C382.707275,3896.391357 388.357697,3896.938965 393.124329,3899.765625 
	C400.092865,3903.897949 407.399597,3899.002197 414.331757,3901.355469 
	C414.988617,3901.578125 416.488617,3899.996826 417.282166,3899.001709 
	C419.267151,3896.513672 419.699615,3892.386963 423.833679,3892.194580 
	C428.784454,3891.964111 434.677490,3890.167236 438.508545,3892.948242 
	C446.626984,3898.841797 455.429596,3897.867188 464.308746,3898.010254 
	C466.610138,3898.047363 469.018921,3897.715088 471.009338,3899.295410 
	C475.135986,3902.570801 474.616333,3909.121582 469.990814,3911.912598 
	C467.675323,3913.309814 465.151428,3914.162109 462.406891,3913.972412 
	C456.912415,3913.592285 451.965149,3915.490967 446.348999,3917.867188 
z"
        />
        <path
          fill="#040F1A"
          opacity="1.000000"
          stroke="none"
          d="
M107.495804,3898.724121 
	C111.013771,3896.603516 112.016830,3893.992920 112.045815,3890.742432 
	C112.092194,3885.541504 115.285225,3883.679443 119.919968,3884.061523 
	C128.154236,3884.740967 136.375671,3885.609619 144.584549,3886.554688 
	C153.764801,3887.612305 162.902405,3889.095703 172.100876,3889.923584 
	C180.452072,3890.675293 186.806458,3896.396240 187.798904,3904.790039 
	C188.143753,3907.706543 187.283325,3910.276367 184.951813,3911.821289 
	C179.155304,3915.661865 173.327118,3919.858154 166.015213,3919.910645 
	C159.419968,3919.958252 152.823288,3920.558350 146.212570,3918.623535 
	C142.534119,3917.546875 138.182861,3917.681152 134.374283,3918.452637 
	C130.787140,3919.178711 127.983658,3919.408936 125.390839,3916.505371 
	C123.744514,3914.661621 121.428596,3913.500977 119.115944,3914.660156 
	C113.725601,3917.362549 108.719414,3914.908691 102.769836,3914.000244 
	C97.537201,3906.956543 105.550537,3904.296143 107.495804,3898.724121 
z"
        />
        <path
          fill="#010A16"
          opacity="1.000000"
          stroke="none"
          d="
M812.751709,3673.983643 
	C812.464417,3668.366455 811.282715,3664.079834 804.426697,3663.622070 
	C802.438110,3663.489014 800.362000,3662.035889 801.318909,3659.602783 
	C802.036438,3657.778076 804.050476,3658.585938 805.686340,3658.820557 
	C817.888428,3660.572266 830.036499,3660.849121 842.258240,3658.459717 
	C848.715881,3657.197266 855.414246,3657.943359 861.980774,3656.306396 
	C866.906555,3655.078125 868.327209,3657.542725 866.566833,3662.707275 
	C866.032349,3664.274902 865.034729,3665.705566 864.638855,3667.295898 
	C863.738464,3670.913330 865.440430,3673.241455 868.974121,3673.682861 
	C871.557556,3674.005371 874.257324,3673.656006 876.876404,3673.355225 
	C879.165222,3673.092773 881.428162,3672.026611 883.677856,3672.097412 
	C896.015015,3672.486328 908.113342,3670.830322 920.150696,3668.388672 
	C924.877808,3667.429688 929.117554,3670.211426 933.875793,3670.126465 
	C947.184570,3669.889404 960.118286,3668.484375 971.822876,3661.495850 
	C973.832581,3660.296143 975.607483,3661.322754 977.533875,3661.429199 
	C987.439453,3661.977051 997.405701,3663.406738 1007.138916,3659.993652 
	C1010.308960,3658.881592 1013.344910,3657.602539 1015.767883,3655.190918 
	C1023.689331,3647.306641 1034.194092,3645.869873 1043.516113,3648.781738 
	C1054.200317,3652.119385 1065.121338,3651.476074 1075.671509,3654.052734 
	C1077.921387,3654.602051 1080.159424,3655.199707 1083.202393,3655.887695 
	C1085.483521,3656.684814 1086.460571,3657.857910 1087.414307,3660.027588 
	C1087.900879,3662.960938 1087.582397,3664.927490 1084.762939,3666.109619 
	C1075.176514,3658.665527 1065.008667,3655.564453 1053.589966,3655.961670 
	C1044.725098,3656.270264 1036.374146,3656.309570 1027.620117,3660.443848 
	C1019.368713,3664.340576 1009.734436,3667.344482 999.737183,3666.053467 
	C981.872375,3663.747070 965.833801,3671.377930 949.299438,3676.007324 
	C936.563110,3679.573486 924.013428,3683.720703 910.496216,3682.007812 
	C907.870178,3681.674805 905.035767,3681.982666 902.627869,3681.096680 
	C889.228821,3676.166992 875.877502,3677.181152 862.468384,3680.832031 
	C861.187500,3681.180908 859.920532,3681.670654 858.615295,3681.823242 
	C854.769104,3682.273193 852.010193,3681.240967 852.023071,3676.647705 
	C852.028687,3674.654541 852.158020,3672.579590 851.672668,3670.684814 
	C850.674683,3666.788330 848.130066,3664.012207 843.919312,3664.237061 
	C837.715881,3664.567627 831.543213,3665.472168 825.357056,3666.128906 
	C822.792114,3666.401611 821.135376,3667.908691 819.970886,3670.081299 
	C818.586426,3672.664062 816.461792,3674.004395 812.751709,3673.983643 
z"
        />
        <path
          fill="#05101B"
          opacity="1.000000"
          stroke="none"
          d="
M1084.506104,3729.453125 
	C1081.248291,3729.877686 1078.791260,3728.739746 1076.697510,3727.168701 
	C1065.702881,3718.918213 1053.289429,3715.410889 1039.697266,3716.048828 
	C1033.072266,3716.359375 1027.870850,3713.427734 1024.483521,3708.458252 
	C1019.417114,3701.024414 1011.804138,3699.704346 1004.112366,3698.030029 
	C1003.480408,3697.892578 1002.785217,3698.072998 1002.134888,3697.987549 
	C999.385986,3697.627197 995.108459,3699.179688 994.700134,3695.193848 
	C994.174377,3690.062256 998.715271,3690.138916 1003.206909,3690.003662 
	C1004.890808,3689.999756 1005.779114,3689.999512 1007.333740,3689.998535 
	C1017.999817,3691.561768 1027.407837,3697.280273 1038.141357,3693.970947 
	C1040.286499,3693.309814 1042.786499,3694.651123 1044.656860,3696.067871 
	C1053.774780,3702.976074 1064.447632,3700.551514 1074.602539,3701.145020 
	C1076.140381,3701.234619 1078.296875,3701.076172 1078.318970,3698.808350 
	C1078.337158,3696.960938 1076.669312,3696.078369 1075.093262,3695.454834 
	C1072.214233,3694.315918 1069.169312,3694.804443 1066.192139,3694.619873 
	C1061.236206,3694.313232 1056.654297,3692.887451 1055.222778,3687.613525 
	C1053.640381,3681.784424 1058.680908,3680.544678 1062.500488,3678.660156 
	C1063.396362,3678.218262 1064.205566,3677.620605 1065.495117,3676.497559 
	C1070.299438,3674.853516 1074.377197,3678.721680 1079.352539,3676.267822 
	C1081.248779,3674.813477 1082.573975,3674.952881 1084.362427,3676.350342 
	C1079.984985,3685.331787 1087.557861,3690.781494 1089.905273,3698.226074 
	C1090.495239,3700.898926 1090.415771,3702.866699 1089.826294,3705.555908 
	C1083.649658,3712.919678 1087.498657,3721.558105 1084.506104,3729.453125 
z"
        />
        <path
          fill="#05101B"
          opacity="1.000000"
          stroke="none"
          d="
M346.821289,3719.987305 
	C346.890594,3718.108643 348.300476,3716.752441 350.195343,3715.917725 
	C361.242340,3711.054443 371.056030,3703.417725 383.488983,3701.612061 
	C387.233154,3701.068115 389.655762,3698.021729 391.153961,3694.443848 
	C389.127899,3689.967285 384.122498,3693.594727 381.232330,3690.400391 
	C382.221100,3686.603271 385.548889,3686.231445 388.727600,3686.098633 
	C393.706177,3685.890625 398.736664,3685.614990 403.677551,3686.082520 
	C414.189209,3687.076660 424.088104,3684.484863 433.979858,3681.658447 
	C440.653290,3679.751709 445.364197,3674.333984 452.401459,3672.389404 
	C464.613983,3669.014648 476.680664,3666.683594 489.328522,3667.853027 
	C491.676880,3668.070068 493.933380,3667.741211 496.184326,3666.964111 
	C499.710541,3665.746826 503.303467,3665.412354 507.437866,3667.434082 
	C500.215607,3670.481445 492.599243,3673.967529 484.263641,3673.890137 
	C478.994232,3673.841309 474.433075,3675.375732 470.135254,3678.000000 
	C458.841064,3684.896973 446.952393,3688.579346 433.395081,3688.240479 
	C420.651642,3687.921875 409.182739,3693.594971 397.924774,3699.260986 
	C387.855164,3704.328369 377.744568,3709.063232 369.325439,3716.964111 
	C365.562469,3720.495117 360.408020,3722.547852 354.588623,3720.249756 
	C352.560181,3719.448486 349.981903,3720.039307 346.821289,3719.987305 
z"
        />
        <path
          fill="#817A79"
          opacity="1.000000"
          stroke="none"
          d="
M116.711548,3813.329102 
	C115.272758,3827.145264 113.171532,3840.468262 110.975601,3854.607422 
	C110.880898,3855.423828 110.326630,3855.668701 110.048843,3855.790527 
	C104.353371,3856.977295 102.238777,3860.427002 101.939041,3865.779541 
	C101.574837,3872.283203 98.699898,3877.705322 93.410698,3881.684570 
	C92.140320,3882.640625 90.804932,3884.016846 89.100441,3882.589111 
	C87.916153,3881.596924 88.293831,3880.170410 88.651611,3878.897217 
	C88.919258,3877.944580 89.146561,3876.880127 89.733269,3876.133545 
	C96.024422,3868.127441 91.611847,3861.632324 86.737793,3855.326416 
	C83.199860,3850.748535 83.166634,3847.086670 85.768349,3841.324707 
	C88.633820,3834.979248 92.078239,3829.257080 96.040176,3822.916016 
	C101.156158,3815.609863 107.895638,3812.728271 116.711548,3813.329102 
z"
        />
        <path
          fill="#010A16"
          opacity="1.000000"
          stroke="none"
          d="
M1065.240479,3676.109863 
	C1065.792725,3679.753906 1062.483643,3680.394775 1060.092773,3682.042236 
	C1055.711060,3685.062012 1055.920654,3688.488281 1060.505859,3690.941895 
	C1063.307007,3692.440430 1066.646606,3692.760010 1069.207397,3691.627441 
	C1074.239014,3689.402100 1077.395386,3692.325928 1080.956421,3694.606201 
	C1083.124512,3695.994385 1084.433105,3698.028076 1083.388672,3700.499268 
	C1082.195801,3703.321777 1080.156738,3706.084961 1076.635742,3705.077637 
	C1070.061768,3703.196533 1063.430298,3704.212891 1056.835327,3704.067871 
	C1052.292480,3703.968262 1048.185547,3702.908936 1044.978149,3699.785400 
	C1041.493164,3696.392578 1037.957764,3695.387207 1033.272339,3697.157227 
	C1028.355835,3699.015137 1023.534119,3697.982178 1018.901672,3695.513672 
	C1015.697693,3693.806152 1012.270691,3692.517334 1008.471680,3690.519287 
	C1013.793396,3688.414551 1019.358459,3689.977539 1024.902100,3691.339111 
	C1030.692261,3692.761230 1036.669922,3692.571045 1040.500854,3688.063232 
	C1046.959351,3680.462646 1055.277222,3677.709229 1065.240479,3676.109863 
z"
        />
        <path
          fill="#05101B"
          opacity="1.000000"
          stroke="none"
          d="
M532.523926,3664.538574 
	C536.269348,3660.247559 542.443665,3658.425537 543.047363,3651.073242 
	C543.289917,3648.118164 547.290405,3646.841309 550.188110,3646.395020 
	C561.675842,3644.624756 572.927185,3641.852295 584.145447,3638.915039 
	C586.443726,3638.313232 587.992920,3639.070068 589.664551,3641.379883 
	C587.106506,3647.601074 582.661072,3648.681152 576.694641,3647.356689 
	C568.315613,3645.496826 565.200806,3647.314697 561.877380,3655.756592 
	C560.210327,3659.990967 557.953979,3662.229980 553.180664,3662.087891 
	C546.332336,3661.884277 540.219910,3667.097168 532.523926,3664.538574 
z"
        />
        <path
          fill="#05101B"
          opacity="1.000000"
          stroke="none"
          d="
M959.246582,3694.008301 
	C960.265259,3695.715332 960.703064,3697.524658 962.867737,3697.298340 
	C968.991699,3696.657959 974.243591,3700.624023 980.414551,3700.251465 
	C984.862305,3699.982666 986.460632,3704.040039 987.410706,3707.574219 
	C988.368835,3711.138672 985.844421,3714.553223 982.854309,3713.537598 
	C973.900391,3710.497070 964.302002,3715.976318 955.721863,3710.896973 
	C955.159790,3710.564453 954.459656,3710.474609 953.857422,3710.198486 
	C948.096619,3707.555908 947.530884,3705.081055 951.330688,3699.619141 
	C952.262573,3698.279541 953.003113,3696.806641 953.919495,3694.702148 
	C955.504272,3694.008545 957.001160,3694.007568 959.246582,3694.008301 
z"
        />
        <path
          fill="#010A16"
          opacity="1.000000"
          stroke="none"
          d="
M1031.144775,3738.266602 
	C1034.692871,3741.871582 1037.746338,3744.909180 1040.612671,3748.113770 
	C1041.735840,3749.369629 1042.347778,3751.090820 1040.861572,3752.563965 
	C1039.658569,3753.756348 1038.246826,3753.122559 1037.380249,3752.128906 
	C1033.926758,3748.168213 1029.414307,3748.180420 1024.645508,3747.876465 
	C1015.127686,3747.269287 1007.171326,3742.125244 999.027283,3737.767822 
	C997.715820,3737.066162 996.434265,3735.520752 997.309814,3733.581055 
	C997.963867,3732.132324 999.420105,3732.062012 1000.708984,3732.075195 
	C1006.977234,3732.139404 1013.448303,3730.920654 1019.212585,3734.575928 
	C1022.699524,3736.786865 1027.284058,3734.947998 1031.144775,3738.266602 
z"
        />
        <path
          fill="#131C25"
          opacity="1.000000"
          stroke="none"
          d="
M106.994278,3898.195312 
	C108.178291,3902.086670 108.017807,3905.881348 103.958504,3908.256348 
	C102.434135,3909.148438 102.145073,3910.836182 101.994186,3913.230469 
	C100.717018,3914.023682 99.470497,3914.076904 97.621445,3914.187012 
	C93.997177,3907.022461 99.257149,3903.363037 103.858810,3898.961426 
	C104.857979,3898.549316 105.465141,3898.428223 106.994278,3898.195312 
z"
        />
        <path
          fill="#010A16"
          opacity="1.000000"
          stroke="none"
          d="
M794.732056,3681.899902 
	C794.317444,3678.342285 797.141052,3677.246338 799.980347,3676.359131 
	C802.775513,3675.485840 805.661682,3674.903564 809.255249,3674.096924 
	C809.814575,3676.758301 807.945007,3677.714355 805.590942,3678.410645 
	C802.180603,3679.419189 798.840332,3680.664795 794.732056,3681.899902 
z"
        />
        <path
          fill="#010A16"
          opacity="1.000000"
          stroke="none"
          d="
M1084.745728,3678.292480 
	C1083.058960,3677.382324 1082.117310,3676.744873 1080.585449,3676.048828 
	C1080.112549,3673.723633 1079.753662,3671.236816 1083.323975,3670.127441 
	C1084.484253,3669.793945 1084.968628,3669.587891 1086.119385,3669.810059 
	C1088.098755,3672.122803 1088.246216,3674.163818 1087.209229,3677.002441 
	C1086.375122,3677.984375 1085.932983,3678.274902 1084.745728,3678.292480 
z"
        />
        <path
          fill="#05101B"
          opacity="1.000000"
          stroke="none"
          d="
M1087.143555,3669.933350 
	C1085.922607,3670.035889 1085.538086,3670.027588 1084.576782,3670.009766 
	C1083.999023,3669.111084 1083.998047,3668.222168 1083.996094,3666.666504 
	C1085.683472,3664.882080 1085.973022,3663.166260 1085.999268,3660.662109 
	C1086.549805,3659.845947 1087.090088,3659.701904 1088.296631,3660.071289 
	C1090.769409,3663.891602 1090.838745,3667.012939 1087.143555,3669.933350 
z"
        />
        <path
          fill="#05101B"
          opacity="1.000000"
          stroke="none"
          d="
M512.673828,3667.967773 
	C512.250488,3665.127930 513.944946,3663.992432 517.359619,3664.098633 
	C517.723511,3666.895508 516.032043,3668.021973 512.673828,3667.967773 
z"
        />
        <path
          fill="#5B92D2"
          opacity="1.000000"
          stroke="none"
          d="
M632.487244,485.559753 
	C632.609985,483.621155 633.259460,482.621124 634.778137,483.340118 
	C635.715332,483.783844 635.865051,484.915619 635.425354,485.797638 
	C634.730408,487.191803 633.705505,487.352386 632.487244,485.559753 
z"
        />
        <path
          fill="#5B92D2"
          opacity="1.000000"
          stroke="none"
          d="
M654.426880,488.188354 
	C655.254578,486.849976 656.212646,486.473022 656.862549,487.393372 
	C657.729675,488.621155 657.102661,489.843079 655.817444,490.296600 
	C654.630371,490.715515 654.333191,489.684479 654.426880,488.188354 
z"
        />
        <path
          fill="#78B0E5"
          opacity="1.000000"
          stroke="none"
          d="
M781.273071,919.484802 
	C784.628052,920.122131 787.440857,920.004211 787.775635,923.664795 
	C787.964539,925.729797 787.343445,927.611328 785.575439,928.771606 
	C783.192566,930.335510 780.625244,930.294128 778.234070,928.759888 
	C775.634521,927.091980 773.994202,924.767944 774.013184,920.761597 
	C775.918945,918.015564 778.141113,917.928406 781.273071,919.484802 
z"
        />
        <path
          fill="#6DA3DE"
          opacity="1.000000"
          stroke="none"
          d="
M781.967896,919.210571 
	C779.868958,919.996948 777.733093,919.998718 774.796143,920.001465 
	C769.807373,919.784851 765.504761,920.578186 761.371277,918.400085 
	C767.183533,915.737854 773.132568,913.935486 779.516541,914.525879 
	C781.363892,914.696716 781.756653,916.718262 781.967896,919.210571 
z"
        />
        <path
          fill="#78B0E5"
          opacity="1.000000"
          stroke="none"
          d="
M519.452515,1004.955933 
	C517.893555,1001.293701 519.440063,1000.034729 522.341248,1000.564575 
	C524.153809,1000.895691 527.065735,1001.825928 526.529968,1003.826172 
	C525.621094,1007.219421 522.424133,1005.740479 519.452515,1004.955933 
z"
        />
        <path
          fill="#73828A"
          opacity="1.000000"
          stroke="none"
          d="
M2761.882568,1832.976440 
	C2766.596680,1829.904175 2768.592529,1826.708130 2768.263672,1821.266479 
	C2767.667480,1811.409912 2770.912842,1801.693604 2770.052734,1791.741577 
	C2769.946289,1790.505249 2770.391357,1788.166382 2770.851807,1788.095337 
	C2777.018066,1787.143555 2777.010254,1779.075684 2782.708008,1777.896973 
	C2787.430420,1776.920166 2787.861328,1773.440552 2788.159668,1768.899414 
	C2788.249268,1768.123291 2789.125977,1768.104614 2789.562988,1768.067383 
	C2791.891846,1766.441040 2792.688232,1763.855957 2795.520020,1762.378418 
	C2796.966797,1762.081909 2797.833984,1762.001831 2799.342773,1761.796387 
	C2803.856201,1759.004639 2808.256104,1760.337402 2813.239014,1760.045654 
	C2818.108154,1760.060303 2822.177002,1760.055908 2827.118164,1760.036377 
	C2832.794678,1760.453491 2837.425293,1758.326416 2843.069092,1759.108887 
	C2845.004639,1759.238037 2846.108887,1759.394775 2848.013184,1759.924438 
	C2849.311768,1760.532471 2849.799561,1760.847168 2850.846680,1761.835327 
	C2853.610352,1766.293579 2852.666260,1770.488281 2853.046387,1775.394043 
	C2854.305664,1783.825562 2856.726562,1791.352905 2853.183594,1799.751099 
	C2846.522949,1805.501221 2838.969971,1801.509888 2831.579102,1803.903076 
	C2823.121826,1806.163818 2815.180420,1804.433960 2806.825195,1806.087158 
	C2805.458984,1806.814209 2804.807861,1807.392578 2803.999023,1808.685791 
	C2802.708740,1812.962158 2803.123779,1816.790039 2802.404297,1821.237549 
	C2801.406738,1825.894653 2801.060303,1829.898682 2800.443848,1834.637695 
	C2799.481689,1838.392822 2799.266113,1841.390503 2800.511719,1845.195068 
	C2801.428223,1852.393921 2802.158691,1858.786865 2799.137695,1865.754395 
	C2797.225586,1867.794067 2795.536621,1868.437622 2792.772461,1868.821045 
	C2790.531738,1869.005981 2792.218994,1869.043091 2790.886475,1869.089478 
	C2769.332275,1869.840454 2763.303223,1871.785278 2764.313965,1845.384888 
	C2764.469727,1841.313354 2762.732178,1837.685181 2761.882568,1832.976440 
z"
        />
        <path
          fill="#83837F"
          opacity="1.000000"
          stroke="none"
          d="
M2929.407227,1839.438477 
	C2934.743652,1841.281616 2939.445801,1842.229492 2944.347656,1841.966187 
	C2947.587158,1841.792114 2950.896240,1841.692505 2953.362061,1845.694946 
	C2958.015381,1850.440918 2957.286865,1855.986938 2958.036133,1861.857422 
	C2957.677979,1866.317993 2954.848877,1868.903442 2953.640381,1872.254517 
	C2951.503418,1878.179077 2954.512207,1879.290161 2960.000244,1879.277710 
	C2963.648193,1885.286987 2959.105713,1887.030029 2955.205078,1889.462769 
	C2962.593018,1889.481934 2967.780029,1893.618408 2973.223145,1897.105347 
	C2975.782959,1898.745239 2977.979492,1900.752197 2975.883789,1904.833740 
	C2962.885010,1910.361328 2950.704346,1904.549316 2938.388916,1904.169434 
	C2930.459717,1903.924683 2922.680420,1903.186523 2914.401367,1906.054443 
	C2910.393799,1908.287720 2908.577637,1911.111938 2907.529053,1914.845337 
	C2906.554688,1918.315186 2904.798828,1922.360474 2900.579102,1922.010864 
	C2895.953613,1921.627563 2893.171875,1918.035522 2892.390869,1913.388672 
	C2891.900635,1910.474121 2891.717285,1907.511841 2890.231445,1904.115967 
	C2887.793945,1892.182373 2892.324219,1881.101074 2890.969727,1869.834595 
	C2890.655273,1867.221191 2891.130127,1864.518677 2890.324219,1861.942505 
	C2889.518066,1859.364990 2887.612793,1858.334595 2885.012939,1858.382935 
	C2882.445557,1858.430786 2881.932617,1860.323364 2881.283936,1862.289429 
	C2880.072266,1865.962402 2881.866699,1872.576904 2875.589355,1871.883301 
	C2868.323975,1871.080444 2867.780273,1864.855835 2868.871582,1858.782471 
	C2869.721924,1854.049316 2867.141846,1852.145508 2862.458496,1850.962891 
	C2859.643799,1840.021362 2870.757080,1831.225220 2883.140137,1835.312500 
	C2894.201904,1838.963257 2904.888428,1838.721558 2916.715332,1837.110474 
	C2921.335693,1837.109253 2925.301758,1836.391357 2929.407227,1839.438477 
z"
        />
        <path
          fill="#E7E3DA"
          opacity="1.000000"
          stroke="none"
          d="
M2851.346191,1800.412964 
	C2852.694336,1792.357666 2850.592041,1785.118652 2850.336182,1776.989746 
	C2851.838867,1771.794800 2854.939209,1767.479492 2850.456055,1762.466797 
	C2849.645508,1761.857666 2849.296387,1761.707031 2848.403320,1761.383545 
	C2844.428467,1757.790894 2845.055420,1753.761230 2846.549805,1748.944092 
	C2850.109863,1746.363281 2852.968506,1744.522583 2853.916260,1740.423462 
	C2855.012695,1735.682007 2858.949219,1733.081177 2863.448975,1732.911865 
	C2878.576904,1732.343140 2893.760254,1731.524902 2909.331543,1735.448730 
	C2909.993896,1736.846191 2910.046387,1737.765625 2910.035889,1739.373779 
	C2908.279785,1741.527222 2907.222656,1743.111328 2909.344238,1745.738037 
	C2909.720215,1748.975464 2909.754150,1751.703125 2909.926025,1755.239990 
	C2911.450439,1757.533691 2912.802246,1759.255127 2912.616455,1761.269531 
	C2911.759033,1770.581421 2917.046631,1774.126343 2925.735352,1775.626465 
	C2927.964355,1778.655762 2930.373779,1781.042358 2928.106201,1785.063477 
	C2926.102539,1788.437500 2923.136230,1788.981201 2920.276123,1788.956299 
	C2913.841797,1788.900146 2911.134521,1792.574219 2909.766602,1798.914062 
	C2908.092529,1801.839355 2905.735107,1802.912842 2903.069824,1804.766235 
	C2901.430420,1805.428467 2900.443848,1805.595825 2898.693115,1805.555420 
	C2885.650391,1802.636353 2873.052490,1805.945679 2860.687256,1803.724487 
	C2857.518799,1803.155273 2854.053223,1804.033569 2851.346191,1800.412964 
z"
        />
        <path
          fill="#838F9A"
          opacity="1.000000"
          stroke="none"
          d="
M2956.493652,1864.558838 
	C2954.402344,1858.837158 2953.599854,1853.443604 2953.922363,1847.076294 
	C2963.553711,1846.193970 2973.205322,1846.251465 2982.851807,1846.052979 
	C2989.728027,1845.911499 2991.214600,1844.116943 2989.451660,1837.456543 
	C2987.977539,1831.888794 2986.249268,1826.557129 2990.488770,1821.457031 
	C2991.468994,1820.277832 2991.212158,1818.993286 2990.565186,1817.765137 
	C2990.256836,1817.180176 2989.808838,1816.670654 2989.451172,1816.109741 
	C2985.552490,1809.997559 2991.763184,1804.314697 2990.763916,1799.402954 
	C2989.556641,1793.469604 2986.122559,1788.473389 2991.684570,1783.193726 
	C2993.817383,1781.169189 2993.242188,1779.264893 2991.435303,1777.149902 
	C2989.035156,1774.340210 2988.884766,1770.072754 2990.317139,1767.680664 
	C2994.225342,1761.154663 2993.080078,1753.659912 2996.151367,1746.746704 
	C2997.705322,1748.658813 2995.756592,1751.065186 2997.753662,1753.547852 
	C2995.720215,1773.312744 2996.191406,1792.806152 2995.604004,1812.265137 
	C2995.226562,1824.770874 2993.292969,1837.227051 2994.363770,1850.697754 
	C2991.577148,1856.345093 2984.255615,1853.887451 2981.381836,1859.932861 
	C2978.529541,1862.443359 2975.684326,1863.031738 2972.019531,1863.303467 
	C2966.273438,1861.531372 2961.982178,1866.175293 2956.493652,1864.558838 
z"
        />
        <path
          fill="#9FA2A1"
          opacity="1.000000"
          stroke="none"
          d="
M2907.159180,1800.525879 
	C2907.991211,1785.803467 2907.991211,1785.804077 2922.135498,1785.702148 
	C2923.454834,1785.692627 2924.774170,1785.669678 2926.982422,1785.628174 
	C2927.688232,1798.654419 2928.907471,1811.729614 2926.659424,1825.385010 
	C2921.169678,1830.175781 2915.636719,1829.848267 2909.089600,1827.690918 
	C2901.664307,1818.973755 2904.347900,1810.116699 2907.159180,1800.525879 
z"
        />
        <path
          fill="#C4C8C1"
          opacity="1.000000"
          stroke="none"
          d="
M2909.966797,1735.448730 
	C2896.794922,1735.708984 2883.618408,1736.922363 2870.500977,1734.924683 
	C2868.659912,1734.644409 2866.646484,1735.542603 2864.706543,1735.855713 
	C2856.650146,1737.156372 2856.663574,1737.151855 2856.096436,1744.830811 
	C2855.906494,1747.401489 2856.498291,1750.100952 2854.849121,1752.632324 
	C2851.313965,1753.435303 2852.130371,1748.438232 2848.666016,1748.163818 
	C2845.238770,1745.246582 2846.083740,1741.598389 2845.765137,1737.434326 
	C2846.093018,1735.445190 2846.449951,1734.364380 2847.525879,1732.667236 
	C2850.056885,1728.024780 2853.706299,1728.048340 2857.404541,1728.068970 
	C2865.629395,1728.114746 2873.854980,1728.100952 2883.011475,1728.107666 
	C2888.217041,1728.251831 2892.573486,1727.239014 2897.375977,1729.503418 
	C2901.184570,1730.749756 2904.761475,1725.570312 2907.778076,1731.304932 
	C2908.453369,1732.346191 2908.895996,1732.819580 2909.623535,1733.690918 
	C2909.988770,1734.013794 2909.991943,1734.970825 2909.966797,1735.448730 
z"
        />
        <path
          fill="#79868C"
          opacity="1.000000"
          stroke="none"
          d="
M2926.114746,1775.308350 
	C2924.960449,1776.246826 2923.646240,1776.335205 2922.356201,1776.560791 
	C2912.331055,1778.314941 2909.746826,1776.395874 2909.491211,1766.693604 
	C2909.413574,1763.752197 2909.775391,1760.799438 2909.999756,1756.950439 
	C2914.519775,1757.352417 2919.047363,1757.926147 2923.702881,1758.243286 
	C2929.234619,1758.620239 2930.763916,1761.343628 2928.311035,1766.175903 
	C2926.926758,1768.903687 2925.492676,1771.375854 2926.114746,1775.308350 
z"
        />
        <path
          fill="#7B7F84"
          opacity="1.000000"
          stroke="none"
          d="
M2907.121094,1824.930786 
	C2913.201660,1825.645874 2918.733154,1825.831665 2925.191406,1826.016357 
	C2926.146240,1828.145386 2926.174561,1830.275269 2926.105469,1833.200195 
	C2923.957031,1836.551636 2921.091309,1836.905640 2917.238525,1836.918945 
	C2913.428955,1836.986084 2910.496338,1836.712524 2907.555908,1836.569702 
	C2895.091309,1835.963623 2895.089600,1835.996704 2894.959717,1821.961060 
	C2894.403809,1815.718140 2893.973145,1810.356567 2897.386719,1804.745972 
	C2898.912842,1804.222900 2899.776855,1804.311157 2901.292480,1804.389404 
	C2905.181396,1807.065430 2904.791992,1810.529541 2904.340332,1814.198975 
	C2903.901123,1817.767944 2904.435303,1821.306274 2907.121094,1824.930786 
z"
        />
        <path
          fill="#94ACC2"
          opacity="1.000000"
          stroke="none"
          d="
M2844.261230,1761.391846 
	C2838.817871,1761.597778 2833.892578,1762.567993 2828.573730,1760.534668 
	C2832.846924,1757.128296 2838.388916,1754.915649 2838.675781,1747.879272 
	C2838.729736,1746.555908 2840.508545,1746.309204 2842.480469,1746.034424 
	C2847.832764,1750.392456 2845.988281,1755.556030 2844.261230,1761.391846 
z"
        />
        <path
          fill="#FBF9EC"
          opacity="1.000000"
          stroke="none"
          d="
M2844.375000,1762.272339 
	C2843.578125,1757.355225 2843.529297,1752.558594 2843.330566,1746.875732 
	C2843.355469,1745.131836 2843.530029,1744.274170 2843.690918,1742.755859 
	C2843.496582,1741.695923 2843.315918,1741.296509 2843.083252,1740.241577 
	C2843.014404,1738.705688 2843.095215,1737.834473 2843.756348,1736.525635 
	C2844.241211,1736.079346 2844.091553,1736.167603 2844.146240,1736.235107 
	C2844.924072,1735.358032 2845.865723,1735.016602 2847.477539,1735.953369 
	C2847.946289,1739.876831 2847.964111,1743.126831 2847.990723,1747.190918 
	C2847.953857,1751.868530 2847.907715,1755.732178 2847.860840,1760.403320 
	C2846.947510,1761.604736 2846.035156,1761.998779 2844.375000,1762.272339 
z"
        />
        <path
          fill="#9FA2A1"
          opacity="1.000000"
          stroke="none"
          d="
M2915.513184,1834.647949 
	C2918.758789,1833.991089 2921.546875,1834.006470 2925.171631,1834.008545 
	C2928.043457,1834.948975 2929.452148,1836.424683 2929.999023,1839.318481 
	C2925.966553,1839.954956 2921.850830,1839.960327 2916.857910,1839.902710 
	C2914.822266,1838.507202 2914.542480,1836.994873 2915.513184,1834.647949 
z"
        />
        <path
          fill="#94ACC2"
          opacity="1.000000"
          stroke="none"
          d="
M2897.834473,1729.265625 
	C2893.717285,1729.753052 2889.331299,1730.775757 2884.532227,1728.621338 
	C2888.611816,1725.071045 2893.209961,1724.290771 2897.834473,1729.265625 
z"
        />
        <path
          fill="#94ACC2"
          opacity="1.000000"
          stroke="none"
          d="
M2813.498535,1759.521118 
	C2810.273438,1763.245728 2805.796143,1761.364990 2800.814941,1761.739014 
	C2803.153076,1755.206787 2808.318604,1758.359131 2813.498535,1759.521118 
z"
        />
        <path
          fill="#94ACC2"
          opacity="1.000000"
          stroke="none"
          d="
M2795.679932,1761.674805 
	C2795.757324,1765.104248 2794.504150,1767.297607 2790.677734,1767.981689 
	C2790.306641,1764.636963 2789.545166,1760.422729 2795.679932,1761.674805 
z"
        />
        <path
          fill="#C4C8C1"
          opacity="1.000000"
          stroke="none"
          d="
M2909.776855,1745.475464 
	C2907.565430,1745.853394 2904.729248,1746.297852 2904.518066,1743.155273 
	C2904.344971,1740.571899 2906.564941,1740.301636 2909.256836,1740.080322 
	C2909.937988,1741.609375 2909.902832,1743.156128 2909.776855,1745.475464 
z"
        />
        <path
          fill="#96B6D1"
          opacity="1.000000"
          stroke="none"
          d="
M1770.667969,1745.884033 
	C1770.014526,1744.500854 1770.009399,1742.997559 1770.009521,1740.742310 
	C1774.186401,1741.072144 1775.213379,1742.862793 1770.667969,1745.884033 
z"
        />
        <path
          fill="#94ACC2"
          opacity="1.000000"
          stroke="none"
          d="
M2847.970215,1735.852539 
	C2847.101318,1736.534790 2846.274170,1736.442627 2844.823975,1736.326660 
	C2844.166260,1734.392090 2844.622559,1732.785767 2847.394043,1732.097900 
	C2848.036621,1733.016846 2848.024170,1734.047485 2847.970215,1735.852539 
z"
        />
        <path
          fill="#D0D1C8"
          opacity="1.000000"
          stroke="none"
          d="
M3017.600586,1742.565186 
	C3017.314697,1744.917847 3016.137939,1746.499023 3013.860352,1743.167480 
	C3014.709473,1742.587524 3015.766846,1742.474487 3017.600586,1742.565186 
z"
        />
        <path
          fill="#94ACC2"
          opacity="1.000000"
          stroke="none"
          d="
M2843.719971,1736.389526 
	C2844.178467,1736.942261 2844.115479,1737.805176 2843.934082,1739.307617 
	C2843.526123,1740.204102 2843.236572,1740.461304 2842.478271,1740.375000 
	C2842.406250,1738.920776 2842.802490,1737.810303 2843.719971,1736.389526 
z"
        />
        <path
          fill="#C4C8C1"
          opacity="1.000000"
          stroke="none"
          d="
M2842.205322,1740.437622 
	C2842.009766,1740.031250 2842.914307,1740.001343 2843.365234,1739.974121 
	C2843.836670,1740.307739 2843.857178,1740.668335 2843.777588,1741.562134 
	C2843.148193,1741.783569 2842.722900,1741.366577 2842.205322,1740.437622 
z"
        />
        <path
          fill="#94ACC2"
          opacity="1.000000"
          stroke="none"
          d="
M2909.751465,1733.574951 
	C2909.448486,1733.628906 2908.908203,1733.244019 2908.149170,1732.403564 
	C2908.567871,1732.197998 2909.095703,1732.593994 2909.751465,1733.574951 
z"
        />
        <path
          fill="#020D19"
          opacity="1.000000"
          stroke="none"
          d="
M2835.477783,3719.512695 
	C2836.887207,3719.993408 2837.774658,3719.996826 2839.327148,3720.000000 
	C2843.838135,3723.635742 2849.086670,3725.484863 2852.448486,3729.721436 
	C2854.118652,3731.825928 2855.761719,3734.167725 2854.572266,3736.951416 
	C2853.313477,3739.897705 2850.418213,3739.847412 2847.736816,3739.719238 
	C2844.437256,3739.561279 2841.139893,3739.061523 2837.869141,3739.967529 
	C2834.820068,3740.812500 2833.737061,3741.884766 2835.194580,3745.567383 
	C2839.053467,3755.317139 2836.966309,3758.046631 2824.703613,3759.986816 
	C2812.103027,3757.227783 2800.172852,3754.598389 2788.328369,3751.627441 
	C2784.424805,3750.648438 2780.603760,3749.801514 2776.571777,3749.986816 
	C2772.883545,3750.156738 2769.292480,3749.993652 2766.154541,3747.526367 
	C2765.173096,3746.754639 2763.680176,3746.108398 2762.489990,3746.209473 
	C2751.246582,3747.166016 2742.694580,3739.939697 2732.828369,3736.746094 
	C2719.323730,3732.374512 2705.489258,3730.646240 2691.478271,3734.555908 
	C2686.259521,3736.012207 2685.660645,3738.314453 2689.218750,3742.198730 
	C2691.283936,3744.453613 2693.716309,3746.231689 2696.590576,3747.157227 
	C2701.953125,3748.883545 2707.398438,3753.283936 2712.100342,3752.964844 
	C2718.758301,3752.512939 2724.348389,3755.964355 2730.195557,3755.644775 
	C2744.162354,3754.881348 2756.572998,3761.365479 2770.077637,3762.783691 
	C2778.342773,3763.651855 2786.667236,3767.604980 2794.083740,3771.749756 
	C2799.981201,3775.045654 2805.679443,3776.569580 2812.062256,3775.942871 
	C2820.770752,3775.087646 2828.646973,3776.894043 2835.192627,3782.766357 
	C2841.648438,3788.557617 2849.790527,3790.263184 2857.605713,3792.855957 
	C2859.492432,3793.481934 2861.410889,3794.083740 2863.175781,3794.972168 
	C2863.958984,3795.365723 2864.783936,3796.388672 2864.906494,3797.226074 
	C2865.120117,3798.688477 2864.020752,3799.333984 2862.635742,3799.887695 
	C2856.991699,3802.144287 2850.862793,3801.431396 2844.551758,3803.497070 
	C2842.588623,3805.465088 2840.869629,3806.121338 2838.199219,3806.164062 
	C2835.670654,3805.996582 2833.846436,3805.704590 2831.294922,3806.036133 
	C2829.115967,3806.222412 2827.656982,3806.114502 2825.462891,3805.696777 
	C2803.394775,3801.118164 2783.768311,3791.625488 2762.595215,3784.477539 
	C2760.457031,3782.732422 2758.932617,3781.469971 2757.368164,3780.259766 
	C2751.288574,3775.558350 2750.457031,3775.205322 2744.992920,3780.721191 
	C2741.867432,3783.876953 2738.815430,3784.720215 2735.243164,3783.341064 
	C2728.610840,3780.780518 2721.721191,3780.447021 2714.810791,3779.517090 
	C2708.702637,3778.695068 2702.457764,3777.589111 2696.641846,3774.669678 
	C2691.284912,3771.980469 2685.452393,3771.129883 2679.236084,3773.132324 
	C2670.961670,3775.797852 2662.830566,3772.076172 2654.766602,3770.895020 
	C2650.281738,3770.238281 2645.131104,3773.687012 2641.444824,3769.565674 
	C2637.113770,3764.723877 2630.818115,3764.488770 2625.620850,3761.773682 
	C2621.790039,3759.772217 2617.142822,3760.226807 2613.604492,3757.237549 
	C2611.754395,3755.674805 2609.885498,3757.264404 2608.423096,3758.715576 
	C2607.198730,3759.930664 2606.051514,3761.469971 2606.962646,3763.099365 
	C2608.391113,3765.653564 2609.819824,3769.727295 2612.712891,3769.441406 
	C2622.147461,3768.508545 2627.787598,3775.695068 2635.163818,3778.978516 
	C2646.947754,3784.223145 2657.992920,3791.364502 2671.757568,3790.172119 
	C2673.594238,3790.013428 2675.683838,3790.923096 2677.420654,3791.802979 
	C2682.787598,3794.522217 2688.228271,3794.451416 2693.913086,3793.065918 
	C2703.637939,3790.695801 2712.985107,3791.394043 2721.731445,3796.826416 
	C2724.676270,3798.655029 2727.302246,3800.452637 2727.029053,3804.609863 
	C2723.725830,3807.055176 2718.196533,3805.693359 2716.123047,3811.281738 
	C2714.911133,3813.260498 2713.471680,3813.715332 2711.117676,3813.771729 
	C2700.043945,3808.712402 2689.614014,3805.393799 2677.796631,3811.730957 
	C2669.990234,3810.581543 2662.620605,3810.233887 2656.350098,3804.677246 
	C2655.732422,3801.750977 2654.547119,3800.446045 2652.340332,3799.823242 
	C2645.330322,3797.845459 2638.776123,3794.267090 2631.290039,3793.933838 
	C2630.002930,3793.876465 2628.086914,3793.788574 2627.561768,3792.990479 
	C2623.069336,3786.163574 2614.870605,3784.605225 2608.628662,3780.684082 
	C2602.282471,3776.697266 2596.129639,3772.481689 2590.028320,3768.120850 
	C2586.897705,3765.883301 2583.338379,3765.536865 2579.578857,3767.113281 
	C2576.520508,3768.395264 2573.177002,3769.001465 2570.127197,3770.299805 
	C2564.825195,3772.555908 2559.699219,3772.619385 2554.881592,3769.400635 
	C2548.983398,3765.459961 2543.909668,3765.441650 2539.331299,3771.469482 
	C2538.427490,3772.659424 2536.534424,3773.097900 2534.376221,3773.964600 
	C2527.542236,3765.664795 2532.667725,3755.964844 2530.258545,3746.305664 
	C2529.706543,3744.411865 2529.509766,3743.312256 2529.417480,3741.369141 
	C2529.482178,3737.283203 2530.486084,3734.249756 2532.050293,3730.582031 
	C2537.400391,3722.020020 2540.222412,3713.238525 2542.983887,3703.665039 
	C2542.872559,3700.829346 2543.217773,3698.788086 2543.737305,3695.959473 
	C2546.474854,3687.111328 2544.068848,3678.694824 2545.980957,3669.770020 
	C2546.602051,3668.503418 2546.921387,3668.032959 2547.910645,3667.034668 
	C2560.091309,3663.336426 2570.765137,3662.787109 2578.878418,3674.066650 
	C2582.663818,3679.329102 2589.080078,3679.835449 2595.037354,3680.642578 
	C2621.959229,3684.291748 2648.655762,3689.744385 2675.918457,3690.684082 
	C2679.898926,3690.821045 2683.865234,3691.379883 2688.344727,3693.192871 
	C2706.241455,3705.210449 2726.752930,3700.621338 2745.367920,3705.475098 
	C2738.711426,3699.743408 2730.651123,3698.261475 2722.574951,3697.077637 
	C2714.913086,3695.954590 2707.548096,3694.017822 2700.807373,3690.202148 
	C2697.238281,3688.181885 2694.164307,3685.370850 2694.651855,3680.738281 
	C2695.133789,3676.159424 2699.107910,3675.447998 2703.362793,3674.241943 
	C2706.756348,3673.835205 2709.325195,3674.276855 2712.670898,3674.977539 
	C2723.660400,3676.070068 2733.513916,3679.652344 2744.728516,3679.088867 
	C2749.911377,3679.055908 2754.052490,3680.173584 2758.837402,3681.949951 
	C2765.353271,3688.380127 2773.754639,3689.410400 2781.383545,3693.576416 
	C2789.648438,3701.614990 2782.078613,3705.537109 2777.966797,3710.160889 
	C2775.168457,3713.307617 2771.402100,3714.488525 2767.130371,3714.607666 
	C2773.795654,3715.126465 2779.817139,3718.388428 2786.523193,3718.705078 
	C2793.260986,3719.023438 2799.518555,3716.358887 2806.065186,3715.639404 
	C2815.911621,3714.556641 2825.574707,3715.780762 2835.477783,3719.512695 
z"
        />
        <path
          fill="#0C0B0A"
          opacity="1.000000"
          stroke="none"
          d="
M2529.223633,3995.729492 
	C2528.458740,3995.536621 2528.361816,3995.124512 2528.207031,3994.080078 
	C2527.850586,3993.078369 2527.551758,3992.708740 2527.090820,3991.651855 
	C2527.278076,3987.578125 2525.479492,3986.552979 2522.361816,3986.050537 
	C2507.179199,3983.603027 2492.480225,3978.778320 2477.207764,3976.728760 
	C2474.564453,3976.374268 2472.056885,3975.674316 2469.640869,3973.155762 
	C2468.818604,3971.685303 2468.651855,3970.746582 2468.858398,3969.072754 
	C2470.874756,3965.103027 2474.967773,3965.654053 2477.961914,3963.001709 
	C2480.025146,3961.581543 2481.586914,3960.640869 2483.234375,3958.714844 
	C2488.862793,3954.498779 2487.082520,3949.365723 2486.317139,3944.567383 
	C2485.800293,3941.325439 2484.006836,3938.286133 2485.162598,3934.180176 
	C2486.907959,3932.075928 2488.882568,3931.609619 2491.294922,3930.445801 
	C2496.251465,3929.266113 2500.534180,3928.572266 2505.609375,3928.781738 
	C2507.031982,3929.026855 2507.749268,3929.204102 2508.604004,3929.735840 
	C2503.994141,3927.456299 2499.175049,3928.629395 2493.709229,3929.856445 
	C2490.925049,3929.833008 2488.818604,3930.287598 2486.132080,3929.969238 
	C2484.702637,3929.695557 2485.233887,3926.935303 2482.892578,3928.749023 
	C2481.830566,3928.775391 2481.400391,3928.677246 2480.376465,3928.260498 
	C2472.135254,3921.017578 2468.605469,3911.965088 2466.764404,3901.705811 
	C2463.768066,3906.782959 2465.767822,3915.674072 2458.750000,3915.649658 
	C2453.041504,3915.629639 2452.977051,3907.641846 2451.133789,3902.821289 
	C2450.025635,3899.922607 2448.296387,3897.588623 2445.700684,3894.927246 
	C2443.624512,3888.460693 2447.220703,3885.663818 2451.930908,3884.271729 
	C2456.876465,3882.810303 2461.958984,3881.342041 2466.125732,3882.030029 
	C2458.723389,3882.394287 2450.162354,3882.435303 2443.930176,3889.838867 
	C2442.828369,3891.147217 2441.082764,3891.367676 2438.719238,3891.012207 
	C2435.575928,3888.859375 2435.570068,3885.922852 2434.958496,3883.223633 
	C2434.483643,3881.128174 2433.297607,3880.896973 2431.095215,3882.434570 
	C2429.732910,3883.182617 2428.890137,3883.450195 2427.348145,3883.640137 
	C2423.346436,3883.242920 2419.823242,3882.010010 2416.915283,3886.224854 
	C2415.935791,3886.798340 2415.497803,3886.951660 2414.356445,3887.102783 
	C2412.739258,3886.823242 2411.951660,3886.340332 2410.953125,3885.033936 
	C2409.899170,3882.073730 2410.490234,3879.893555 2411.726074,3877.195801 
	C2412.769531,3875.308838 2413.743896,3874.081787 2413.861816,3871.943848 
	C2413.705078,3870.673340 2413.529053,3869.952637 2413.045410,3868.717285 
	C2411.175781,3866.101318 2407.332031,3867.112793 2406.552246,3863.026855 
	C2408.783203,3853.751953 2414.204590,3856.290039 2420.249023,3858.914795 
	C2422.188477,3860.076172 2422.887207,3861.289795 2423.535156,3863.332764 
	C2425.003418,3865.266846 2425.185547,3866.654297 2423.855957,3868.290771 
	C2425.208984,3867.239502 2424.841064,3866.043213 2424.305176,3864.377930 
	C2422.990723,3862.779785 2422.983887,3861.264648 2421.538574,3859.713135 
	C2416.514404,3857.150391 2412.229004,3854.808838 2407.009766,3857.684814 
	C2404.689453,3858.963135 2401.842773,3858.209473 2399.040527,3855.892090 
	C2396.535889,3850.613281 2397.434570,3846.385742 2400.962891,3841.850098 
	C2407.210938,3837.761475 2413.046875,3839.132080 2418.862793,3840.600098 
	C2447.098877,3847.729248 2475.937744,3851.996338 2504.286133,3858.606445 
	C2510.373291,3860.025635 2516.648438,3861.332031 2523.578613,3858.202637 
	C2528.503174,3858.549805 2530.187744,3861.188965 2529.924316,3864.964600 
	C2528.657227,3883.121826 2535.023926,3901.162598 2530.713379,3919.354492 
	C2533.410156,3927.694336 2531.663818,3936.415771 2533.046143,3944.970215 
	C2535.321777,3959.055664 2534.154541,3973.425537 2533.534912,3987.659912 
	C2533.418701,3990.331787 2532.687012,3993.636475 2529.223633,3995.729492 
z"
        />
        <path
          fill="#3A4B59"
          opacity="1.000000"
          stroke="none"
          d="
M2524.214844,3857.156738 
	C2523.890381,3863.372803 2521.423828,3865.589111 2515.821533,3864.318115 
	C2505.211670,3861.910889 2494.525391,3859.837158 2483.857422,3857.691895 
	C2464.464844,3853.791992 2445.045898,3850.019043 2425.671631,3846.030029 
	C2418.265381,3844.505127 2411.203857,3841.193359 2401.811035,3842.132568 
	C2396.346680,3842.197754 2392.784912,3841.080566 2389.307861,3838.003174 
	C2388.343018,3828.044922 2394.460449,3821.722168 2399.433350,3815.106445 
	C2402.631104,3810.852051 2407.006348,3812.783691 2411.422363,3815.511719 
	C2417.071045,3820.047363 2419.400879,3827.316406 2427.469727,3828.743652 
	C2442.041260,3817.422852 2458.649170,3809.004150 2467.137207,3791.908203 
	C2468.808350,3788.542236 2471.722168,3786.287598 2476.344971,3785.516113 
	C2482.254150,3785.539062 2487.253418,3786.800049 2493.062500,3787.805908 
	C2501.737061,3791.683350 2510.378662,3791.981445 2519.436035,3793.702637 
	C2519.357422,3809.361816 2521.836914,3824.353760 2523.242188,3839.427002 
	C2523.762695,3845.010742 2523.783691,3850.641113 2524.214844,3857.156738 
z"
        />
        <path
          fill="#0C1A26"
          opacity="1.000000"
          stroke="none"
          d="
M2712.483643,3677.499756 
	C2709.775146,3677.371094 2707.573242,3676.764404 2704.685547,3676.083496 
	C2693.031738,3667.406982 2681.330322,3661.573486 2665.659424,3666.429932 
	C2674.488770,3670.891846 2680.694336,3677.228027 2686.720215,3683.734375 
	C2689.612549,3686.857178 2692.869873,3690.010498 2690.490234,3695.514404 
	C2681.863037,3694.780273 2673.756104,3693.166992 2665.570068,3692.432617 
	C2659.126221,3691.854248 2652.671631,3693.711426 2646.071045,3692.084229 
	C2626.507324,3687.260742 2606.272461,3686.461914 2586.520264,3682.607910 
	C2580.756104,3681.483154 2576.373291,3678.998047 2574.119629,3673.886230 
	C2571.783691,3668.588135 2567.959229,3667.644531 2562.897705,3667.961182 
	C2558.597900,3668.230225 2554.268066,3668.024170 2549.041016,3668.039307 
	C2543.952148,3664.151855 2544.720947,3659.386230 2546.160645,3653.756104 
	C2546.968506,3652.502686 2547.383545,3652.065186 2548.698730,3651.537109 
	C2561.430176,3646.781494 2561.437256,3646.756836 2577.051758,3651.317627 
	C2582.555664,3652.925049 2588.364990,3650.962646 2593.843994,3652.922363 
	C2595.704834,3653.587402 2598.343018,3653.198975 2599.551270,3654.385986 
	C2607.779541,3662.469482 2620.248779,3661.111084 2628.982910,3667.914551 
	C2630.275879,3668.921387 2631.956543,3670.040771 2633.213135,3668.057617 
	C2634.390625,3666.199219 2633.983398,3664.218018 2632.281006,3662.714600 
	C2631.807617,3662.296387 2631.060791,3662.203369 2630.476074,3661.892334 
	C2627.240723,3660.170410 2623.030029,3658.314453 2625.075439,3653.954346 
	C2626.711182,3650.468018 2631.084473,3651.687500 2634.124023,3652.520264 
	C2641.386719,3654.510010 2648.385498,3654.201904 2655.736084,3652.651855 
	C2661.172607,3651.505615 2666.985107,3652.108398 2672.631592,3652.001465 
	C2678.250977,3651.895020 2683.828369,3652.400146 2689.191895,3649.585205 
	C2693.540283,3647.303223 2697.830566,3649.482422 2701.047119,3652.340576 
	C2704.906982,3655.770020 2708.305176,3657.284668 2714.015625,3657.205322 
	C2719.809570,3657.124756 2725.778076,3661.149414 2731.464355,3663.815186 
	C2739.236084,3667.458496 2746.302002,3672.553223 2754.573730,3675.188232 
	C2755.825439,3675.587158 2757.164307,3675.712402 2759.231445,3675.981934 
	C2760.628174,3677.577881 2761.256348,3679.156006 2761.947266,3681.370605 
	C2761.888428,3682.708984 2761.551270,3683.284912 2760.500000,3683.872559 
	C2755.656738,3684.655273 2751.764893,3682.484619 2746.812256,3681.996582 
	C2735.530273,3676.872314 2724.578369,3674.822998 2712.483643,3677.499756 
z"
        />
        <path
          fill="#091A29"
          opacity="1.000000"
          stroke="none"
          d="
M2709.300537,3811.770020 
	C2711.505615,3811.974365 2712.997559,3811.978271 2715.234863,3811.981201 
	C2724.299316,3810.334717 2731.484131,3814.332520 2738.890625,3816.844727 
	C2743.105469,3818.274658 2743.685547,3821.207520 2740.523682,3824.779541 
	C2737.557617,3828.130371 2737.691650,3830.403076 2741.379639,3832.811523 
	C2743.611084,3834.269043 2745.734375,3835.511963 2748.499268,3836.068115 
	C2758.522949,3838.083740 2770.129395,3848.234375 2775.103271,3859.146729 
	C2771.290527,3859.520508 2768.568359,3857.376709 2765.849121,3855.857178 
	C2754.567139,3849.553955 2742.405518,3845.322021 2730.439697,3840.656006 
	C2727.428711,3839.482178 2724.003418,3838.996338 2721.959473,3843.195801 
	C2719.646484,3847.948730 2716.998291,3853.554443 2712.115967,3855.151123 
	C2705.230957,3857.402832 2697.198730,3857.422119 2690.427002,3855.362549 
	C2681.550293,3852.663086 2672.460938,3850.669189 2663.694336,3847.620605 
	C2659.895264,3846.299561 2657.150879,3844.401611 2659.102295,3839.531494 
	C2661.110352,3834.519775 2658.942871,3832.791260 2653.743408,3831.713623 
	C2644.044434,3829.704102 2634.604736,3826.463135 2625.023193,3823.858398 
	C2621.571533,3822.920166 2619.931641,3820.830566 2620.239502,3817.357910 
	C2620.561035,3813.731689 2622.976074,3811.987549 2626.346680,3812.126221 
	C2636.636230,3812.550293 2645.917480,3809.146973 2655.530762,3804.502441 
	C2657.549316,3804.573975 2659.162109,3805.828613 2660.615967,3805.665771 
	C2666.527588,3805.003662 2671.384033,3807.759277 2677.228027,3809.946289 
	C2688.089600,3812.542969 2698.239502,3813.958252 2709.300537,3811.770020 
z"
        />
        <path
          fill="#091A29"
          opacity="1.000000"
          stroke="none"
          d="
M2762.018799,3784.703857 
	C2776.354004,3784.599609 2789.467773,3789.961182 2802.122803,3795.879883 
	C2806.696045,3798.018555 2810.659668,3800.352295 2815.768799,3800.006592 
	C2819.209961,3799.773682 2822.279297,3800.811035 2825.541504,3803.394043 
	C2822.569092,3808.059814 2817.327637,3806.428467 2813.066895,3807.970459 
	C2810.875488,3808.763672 2808.072998,3809.407227 2807.885986,3812.006592 
	C2807.659668,3815.151611 2810.849365,3815.165039 2812.945312,3815.533203 
	C2830.521973,3818.618652 2847.891357,3823.136719 2865.855713,3823.605957 
	C2871.549072,3823.754639 2877.047363,3825.054443 2882.625488,3825.875488 
	C2885.521240,3826.301758 2888.848389,3826.535400 2888.443359,3831.382324 
	C2885.848633,3832.335205 2884.115723,3833.410156 2884.156006,3835.761475 
	C2884.252686,3841.386475 2880.853760,3842.514160 2876.331543,3841.959229 
	C2870.145996,3841.200195 2864.270508,3845.744385 2857.821045,3842.319824 
	C2846.399414,3836.254150 2833.335205,3834.910645 2821.328369,3830.374512 
	C2816.469727,3828.538574 2811.147705,3827.464355 2805.698975,3827.925049 
	C2803.438721,3828.116455 2800.673584,3828.213379 2798.882568,3827.126465 
	C2787.162598,3820.015137 2773.397705,3820.272217 2760.787842,3816.392578 
	C2754.132568,3814.345215 2748.243896,3810.452881 2741.332520,3809.260742 
	C2740.017578,3809.033691 2738.973389,3807.784668 2739.108643,3806.360840 
	C2739.318848,3804.153076 2741.241699,3804.008545 2742.855713,3804.134766 
	C2744.812988,3804.287842 2746.843506,3804.582520 2748.658691,3805.288330 
	C2756.266846,3808.246094 2764.881104,3805.804688 2772.315918,3809.805176 
	C2773.300781,3810.334717 2775.090088,3810.068359 2776.200195,3809.533936 
	C2779.572754,3807.910645 2785.010254,3808.673096 2785.482422,3803.439941 
	C2785.995605,3797.752197 2780.475830,3798.084717 2777.088379,3796.725830 
	C2771.003662,3794.285889 2764.364014,3792.806885 2762.018799,3784.703857 
z"
        />
        <path
          fill="#152533"
          opacity="1.000000"
          stroke="none"
          d="
M2888.792480,3831.962402 
	C2887.819336,3830.650391 2887.497070,3828.993164 2886.089355,3828.844238 
	C2876.108398,3827.791260 2866.111084,3826.887451 2856.112061,3826.013672 
	C2849.643311,3825.448242 2843.277100,3822.316162 2836.642090,3825.207520 
	C2836.139648,3825.426514 2835.138428,3825.291016 2834.774170,3824.921143 
	C2827.121338,3817.156738 2816.516113,3821.059326 2807.650146,3818.074951 
	C2805.490967,3817.347900 2802.900879,3817.761475 2802.324707,3814.752930 
	C2801.844482,3812.246582 2802.857422,3810.209717 2804.640869,3808.533203 
	C2807.370850,3805.967529 2810.552246,3804.256104 2814.348633,3804.085205 
	C2817.652344,3803.936035 2820.966553,3804.016846 2825.157715,3803.978516 
	C2827.526611,3803.976562 2829.014404,3803.991943 2831.246094,3804.008789 
	C2832.881592,3804.007324 2833.772461,3804.005615 2835.331787,3804.005127 
	C2838.130859,3804.015869 2840.261719,3804.026367 2843.191406,3804.042969 
	C2844.686523,3808.101562 2847.454346,3809.226562 2851.143066,3809.290039 
	C2863.284180,3809.498535 2875.142822,3812.299561 2887.189941,3813.310303 
	C2894.892822,3813.957031 2903.020996,3816.458984 2909.642578,3809.692139 
	C2911.280273,3808.018799 2913.376953,3808.581299 2915.148682,3809.645264 
	C2919.679199,3812.366699 2924.691650,3812.373291 2929.610840,3811.934570 
	C2936.661133,3811.305908 2943.203369,3814.874268 2951.094727,3814.019775 
	C2956.191162,3821.247803 2955.534424,3823.362793 2948.160889,3824.749512 
	C2943.595703,3825.607910 2938.910156,3825.779785 2934.272217,3826.193604 
	C2932.287109,3826.370850 2929.346436,3825.590820 2928.456543,3826.780762 
	C2920.601318,3837.285400 2910.579834,3830.141357 2900.752441,3830.245850 
	C2896.304443,3829.530762 2893.141602,3831.900146 2888.792480,3831.962402 
z"
        />
        <path
          fill="#05121F"
          opacity="1.000000"
          stroke="none"
          d="
M2835.863281,3719.291992 
	C2823.051025,3719.534912 2810.066162,3716.010742 2797.177979,3720.562012 
	C2790.690430,3722.853027 2784.217773,3722.574463 2777.762939,3720.238770 
	C2774.031494,3718.888672 2770.249268,3717.853760 2766.174805,3717.830078 
	C2764.309082,3717.819092 2762.375977,3716.865479 2760.608887,3716.047119 
	C2759.003906,3715.303711 2758.055420,3713.898682 2758.787842,3712.012207 
	C2759.330566,3710.614014 2760.627686,3710.087646 2761.987061,3710.241943 
	C2763.606689,3710.425537 2765.220215,3710.828857 2766.783691,3711.308105 
	C2770.766113,3712.529053 2773.954346,3711.774658 2776.043213,3707.904053 
	C2777.015869,3706.101562 2778.461182,3704.755127 2780.286377,3703.858154 
	C2784.470459,3701.802490 2783.274658,3698.593750 2782.078613,3694.693359 
	C2786.881836,3693.986328 2791.802246,3693.659668 2797.210449,3695.890137 
	C2801.249023,3693.404785 2804.447998,3694.886230 2806.853760,3696.935547 
	C2812.050293,3701.361572 2818.296143,3704.205566 2823.745117,3709.287598 
	C2824.269287,3712.803223 2826.043701,3713.720947 2828.617188,3714.110596 
	C2831.552490,3714.555176 2834.401123,3715.450928 2835.863281,3719.291992 
z"
        />
        <path
          fill="#091A29"
          opacity="1.000000"
          stroke="none"
          d="
M2900.092041,3830.748047 
	C2904.917236,3826.712402 2909.983887,3827.090332 2914.817383,3829.975098 
	C2919.099121,3832.530518 2921.637451,3832.255127 2922.045898,3826.719727 
	C2922.402344,3821.886963 2925.411377,3821.764160 2929.097900,3822.673584 
	C2936.332764,3824.458252 2943.355225,3821.459229 2950.582031,3821.593262 
	C2955.020508,3821.675537 2952.367676,3817.774414 2952.068848,3814.750000 
	C2960.937012,3816.457275 2969.889404,3818.941895 2978.514893,3822.445801 
	C2981.361572,3823.602051 2984.247803,3824.908691 2983.770996,3828.739990 
	C2983.311768,3832.431885 2981.007080,3835.220947 2977.473633,3835.583496 
	C2970.125977,3836.337158 2962.679688,3839.329102 2955.467041,3833.943604 
	C2951.298096,3830.830566 2945.364746,3830.491455 2941.137451,3833.057373 
	C2934.468018,3837.106201 2927.586670,3836.040039 2920.770508,3835.787109 
	C2915.240234,3835.582031 2909.904053,3833.107178 2904.116943,3835.159912 
	C2902.159912,3835.854248 2900.750000,3833.530762 2900.092041,3830.748047 
z"
        />
        <path
          fill="#122230"
          opacity="1.000000"
          stroke="none"
          d="
M2918.203125,3939.283936 
	C2909.800537,3937.027832 2902.190186,3934.768555 2894.598389,3932.448486 
	C2887.317139,3930.223877 2880.251709,3927.526123 2872.320801,3927.758545 
	C2867.372314,3927.903564 2862.598389,3925.728271 2858.745361,3922.264648 
	C2856.087646,3919.875732 2856.511963,3917.098633 2859.972412,3916.584473 
	C2866.863770,3915.560303 2874.406250,3914.223145 2880.608154,3917.086426 
	C2886.337891,3919.731445 2892.880127,3919.524902 2898.194092,3923.446045 
	C2900.644531,3925.254150 2904.553711,3925.874512 2907.746094,3925.738770 
	C2917.113770,3925.340088 2926.098633,3928.856201 2935.458496,3928.020752 
	C2940.036621,3927.612061 2943.922363,3929.603271 2947.540283,3932.016602 
	C2950.609131,3934.063477 2950.221436,3936.428467 2947.200195,3938.189209 
	C2942.573242,3940.885498 2937.536377,3940.556396 2932.689209,3939.147949 
	C2928.027832,3937.794189 2923.554199,3937.648438 2918.203125,3939.283936 
z"
        />
        <path
          fill="#05121F"
          opacity="1.000000"
          stroke="none"
          d="
M2858.569336,3698.541504 
	C2861.373779,3694.738770 2865.802490,3695.318115 2869.281738,3696.476074 
	C2875.086426,3698.407471 2881.247559,3696.999023 2886.882324,3699.308594 
	C2889.645020,3700.440918 2892.179932,3699.133545 2894.411133,3697.748291 
	C2898.899902,3694.960449 2903.500732,3694.076904 2909.259766,3695.992188 
	C2909.696289,3696.949219 2909.548584,3697.982666 2909.057861,3698.813477 
	C2905.031006,3705.629883 2905.354004,3707.194336 2911.445312,3711.715576 
	C2917.443604,3716.167725 2924.690674,3717.566650 2931.530273,3719.797607 
	C2936.898193,3721.548828 2942.038330,3723.771973 2947.253906,3725.843018 
	C2948.839844,3726.472656 2949.947754,3727.873779 2949.266846,3729.813232 
	C2948.585938,3731.752197 2946.862305,3731.901855 2945.176758,3731.761475 
	C2929.965332,3730.494141 2915.277344,3727.144531 2901.400146,3720.626709 
	C2900.500977,3720.204346 2899.161621,3719.895020 2898.847412,3719.175049 
	C2894.190674,3708.505859 2881.932373,3708.568604 2874.477539,3701.821289 
	C2870.145996,3697.901123 2864.013916,3701.463867 2858.569336,3698.541504 
z"
        />
        <path
          fill="#05121F"
          opacity="1.000000"
          stroke="none"
          d="
M2824.527588,3760.521973 
	C2825.720947,3757.590576 2828.746338,3757.796387 2831.029297,3756.480713 
	C2836.964844,3753.060547 2837.275879,3750.330811 2832.003174,3746.288086 
	C2827.213135,3742.615234 2827.245605,3740.430908 2832.418945,3738.244629 
	C2837.063232,3736.281982 2841.923096,3735.042969 2846.998535,3736.675781 
	C2848.887939,3737.283447 2850.719238,3738.170654 2853.206787,3736.680908 
	C2851.531006,3729.288086 2843.804688,3726.967773 2840.110352,3720.665527 
	C2845.517334,3720.883789 2850.229004,3724.156494 2856.193115,3724.349609 
	C2862.828857,3724.564941 2865.621826,3730.901123 2863.808838,3738.884277 
	C2862.569824,3744.338867 2859.003174,3746.329346 2852.491699,3745.161133 
	C2851.847168,3745.045410 2851.256348,3744.627441 2850.611572,3744.514404 
	C2848.334717,3744.114990 2845.752441,3743.403320 2844.684814,3746.321045 
	C2843.792480,3748.758301 2846.006104,3749.763184 2847.585205,3750.892578 
	C2850.552979,3753.014404 2853.564209,3755.075439 2856.534912,3757.192871 
	C2858.129883,3758.329834 2860.165771,3759.378906 2859.238525,3761.844238 
	C2858.357422,3764.186768 2856.057861,3763.692383 2854.143799,3764.004639 
	C2844.174561,3765.630127 2834.563721,3763.882324 2824.527588,3760.521973 
z"
        />
        <path
          fill="#0C1A26"
          opacity="1.000000"
          stroke="none"
          d="
M2824.836426,3710.000000 
	C2817.660400,3708.081543 2811.458984,3706.086914 2807.173096,3700.376221 
	C2805.378662,3697.984863 2802.333252,3696.965332 2798.705078,3696.083008 
	C2798.177490,3693.317871 2795.761230,3691.116699 2796.672119,3687.835938 
	C2801.271973,3686.584717 2805.222412,3690.318359 2809.802979,3690.077637 
	C2814.081787,3689.853027 2818.473633,3689.962158 2822.683350,3690.690918 
	C2828.197998,3691.645508 2833.533447,3692.981445 2836.881836,3698.606689 
	C2839.002197,3702.168457 2844.020264,3701.755371 2847.435791,3703.766602 
	C2849.130615,3704.764404 2851.226562,3705.535889 2850.988525,3708.000000 
	C2850.802979,3709.922607 2848.921387,3710.415039 2847.527832,3711.206299 
	C2844.588379,3712.875000 2841.650146,3714.339844 2838.241699,3712.129150 
	C2834.392578,3709.632812 2829.995117,3709.938477 2824.836426,3710.000000 
z"
        />
        <path
          fill="#172837"
          opacity="1.000000"
          stroke="none"
          d="
M2656.278809,3882.001953 
	C2665.645508,3882.526611 2665.781250,3882.489258 2668.068115,3890.786621 
	C2668.880371,3893.734619 2670.535889,3895.290283 2673.243164,3896.188477 
	C2674.639893,3896.651367 2675.458252,3897.642822 2674.853760,3899.204590 
	C2674.328857,3900.560303 2673.090332,3901.611572 2671.812744,3901.211182 
	C2668.447266,3900.156006 2664.594971,3900.674072 2661.827393,3897.563232 
	C2656.920654,3892.047852 2650.529297,3890.321533 2643.212158,3889.814209 
	C2630.524414,3888.934814 2619.178467,3884.020264 2608.103027,3875.892334 
	C2612.669678,3873.134277 2616.320557,3874.275391 2620.149170,3874.979248 
	C2627.406494,3876.314209 2633.881348,3880.740723 2642.105469,3878.802734 
	C2646.138428,3877.852783 2650.668945,3881.905762 2656.278809,3882.001953 
z"
        />
        <path
          fill="#B6BCC1"
          opacity="1.000000"
          stroke="none"
          d="
M2520.526855,3793.764160 
	C2511.439941,3796.567383 2503.145752,3794.538818 2494.346191,3791.817871 
	C2491.238525,3787.235107 2492.677979,3783.376953 2494.930664,3778.854004 
	C2502.128662,3772.303711 2511.088135,3776.475830 2519.272949,3773.035156 
	C2520.677490,3772.956787 2521.247803,3773.027588 2522.591064,3773.505127 
	C2528.043945,3778.316895 2527.145264,3783.503662 2525.118408,3789.627930 
	C2523.522705,3791.431641 2522.258545,3792.409424 2520.526855,3793.764160 
z"
        />
        <path
          fill="#5A605E"
          opacity="1.000000"
          stroke="none"
          d="
M2525.410156,3790.232422 
	C2523.354492,3785.317627 2524.664307,3779.815186 2522.413086,3773.937256 
	C2519.705811,3765.566895 2526.035400,3758.953857 2524.364258,3750.781738 
	C2524.240723,3749.101562 2524.344727,3748.143066 2524.870605,3746.522461 
	C2526.514893,3744.173584 2528.290039,3743.490234 2531.060547,3743.906494 
	C2535.080566,3753.369385 2533.056152,3762.858887 2533.629639,3773.110352 
	C2532.075928,3779.826660 2530.528809,3785.622559 2525.410156,3790.232422 
z"
        />
        <path
          fill="#091A29"
          opacity="1.000000"
          stroke="none"
          d="
M2635.750488,3840.000000 
	C2639.970459,3840.375732 2645.495605,3839.201904 2644.785645,3844.268066 
	C2644.035156,3849.624756 2639.005859,3847.838379 2635.132080,3846.729004 
	C2629.138184,3845.012451 2623.081543,3843.514648 2617.054932,3841.911865 
	C2615.610107,3841.527588 2614.765137,3840.544434 2615.062988,3839.067627 
	C2615.343506,3837.675781 2616.527344,3836.876465 2617.819336,3836.504639 
	C2619.880371,3835.911377 2621.728760,3836.788330 2623.447754,3837.769287 
	C2627.035645,3839.817627 2630.909668,3840.206055 2635.750488,3840.000000 
z"
        />
        <path
          fill="#05121F"
          opacity="1.000000"
          stroke="none"
          d="
M2786.365723,3678.638916 
	C2796.483154,3677.523682 2806.747070,3678.038574 2815.845459,3685.277832 
	C2809.809326,3685.331787 2803.745117,3683.872070 2797.433105,3683.651855 
	C2793.730957,3683.522705 2789.507568,3682.645264 2786.365723,3678.638916 
z"
        />
        <path
          fill="#05121F"
          opacity="1.000000"
          stroke="none"
          d="
M2760.143799,3684.706543 
	C2760.237793,3683.385986 2760.623047,3682.873047 2761.582520,3682.239014 
	C2766.145996,3684.609131 2769.898193,3687.906738 2775.183594,3688.227051 
	C2778.022949,3688.399170 2779.730469,3690.635254 2781.670410,3693.377930 
	C2773.733154,3693.734131 2766.962646,3689.689209 2760.143799,3684.706543 
z"
        />
        <path
          fill="#05121F"
          opacity="1.000000"
          stroke="none"
          d="
M2838.301758,3690.658691 
	C2844.237305,3689.843506 2849.781006,3691.337891 2853.868652,3697.305420 
	C2847.939697,3697.911133 2841.180420,3699.432129 2838.301758,3690.658691 
z"
        />
        <path
          fill="#05121F"
          opacity="1.000000"
          stroke="none"
          d="
M2914.096191,3696.639160 
	C2915.780273,3696.193115 2917.314209,3696.730713 2917.905518,3699.361328 
	C2916.220947,3699.809326 2914.686279,3699.272217 2914.096191,3696.639160 
z"
        />
        <path
          fill="#98999E"
          opacity="1.000000"
          stroke="none"
          d="
M627.241211,1641.768433 
	C629.013000,1641.695923 629.890381,1641.768311 631.426514,1641.888672 
	C636.566528,1647.015503 634.590454,1653.197754 634.746765,1658.917969 
	C634.885620,1663.998291 636.218079,1666.819702 641.929077,1667.103149 
	C648.520203,1667.430176 653.426880,1671.194458 657.137695,1677.416992 
	C657.179138,1678.689941 657.101501,1679.203857 656.671997,1680.421021 
	C654.698608,1682.762207 652.921143,1684.221802 653.010864,1686.932129 
	C653.723206,1708.463257 646.376282,1729.678955 650.697144,1751.398193 
	C652.201721,1758.960938 647.234619,1766.257324 649.015442,1773.236816 
	C650.474182,1768.741211 645.570923,1761.142090 655.226562,1758.500732 
	C661.285400,1760.413452 662.448364,1764.562988 662.015503,1770.321289 
	C659.461487,1774.741699 658.901550,1778.989136 657.239502,1783.651855 
	C648.753113,1787.386230 640.101868,1787.858154 631.097778,1790.122803 
	C623.752014,1790.084106 616.787903,1787.452637 610.321045,1793.340332 
	C607.624084,1794.361816 605.594482,1794.391113 602.834961,1793.554443 
	C597.860046,1787.336914 590.994324,1787.584106 584.163452,1785.786621 
	C572.050720,1778.295166 559.852112,1776.256226 546.578125,1778.991821 
	C542.486572,1779.835083 537.933228,1779.509277 533.674194,1776.136230 
	C533.039673,1768.949829 538.155884,1769.364258 542.231567,1768.896729 
	C546.110535,1768.451538 548.369446,1767.053711 548.508972,1762.829224 
	C548.958313,1749.219849 551.520264,1735.687988 550.785278,1722.059448 
	C549.345947,1695.372314 551.447205,1668.691650 550.505127,1642.015259 
	C550.469910,1641.017944 550.862305,1640.062256 551.531860,1638.366333 
	C553.241272,1636.561157 554.873413,1635.661865 556.483215,1636.156860 
	C566.570740,1639.259033 576.929626,1637.421997 587.144287,1638.076538 
	C592.330261,1638.409058 597.661682,1636.920044 602.695068,1640.061523 
	C604.266907,1641.042358 607.391052,1640.476318 609.391785,1639.635742 
	C613.711182,1637.821167 617.117615,1639.528320 621.295044,1641.694580 
	C626.709595,1642.631348 624.795715,1646.426636 624.893005,1648.936523 
	C625.175415,1656.224365 624.941406,1663.534302 625.019958,1670.443359 
	C625.610535,1661.133179 623.566406,1651.525879 627.241211,1641.768433 
z"
        />
        <path
          fill="#49544F"
          opacity="1.000000"
          stroke="none"
          d="
M659.279419,1771.709961 
	C659.075317,1767.537964 658.888977,1764.057007 656.960571,1760.364990 
	C654.828552,1758.677246 653.489868,1756.943237 653.700012,1754.970581 
	C655.044617,1742.347412 651.592773,1729.383423 656.920166,1716.306396 
	C665.339417,1712.611328 666.314331,1717.937012 666.841370,1723.527954 
	C667.505981,1730.578125 665.746887,1737.925659 669.745300,1745.395752 
	C670.257751,1747.255493 670.463318,1748.316895 670.668457,1750.146606 
	C671.570435,1761.322632 663.350525,1771.574951 670.665222,1781.879761 
	C671.629028,1778.356689 670.539124,1774.166870 672.134277,1770.441895 
	C672.803711,1768.878662 673.690918,1767.422119 675.596008,1767.236084 
	C678.042786,1766.997314 679.087952,1768.690063 679.911621,1770.604980 
	C680.438354,1771.829834 680.331177,1770.404907 680.101440,1771.549316 
	C676.348328,1790.242310 693.149780,1789.296997 702.632568,1792.989746 
	C708.752014,1795.372559 718.112244,1794.885742 724.940613,1789.666992 
	C727.314697,1787.852661 729.511108,1785.576782 733.468628,1787.555176 
	C734.146912,1791.875732 734.299500,1795.741821 734.467163,1800.432373 
	C736.463928,1805.753906 733.516174,1808.046021 729.783752,1810.635742 
	C726.065002,1812.231079 723.263794,1811.104980 720.439148,1810.077271 
	C717.487793,1809.003296 715.198364,1809.882446 713.749634,1812.640259 
	C712.174438,1815.639038 713.446777,1818.148438 716.112915,1819.260132 
	C724.348267,1822.694214 728.395142,1828.833130 729.512451,1837.495117 
	C729.960632,1840.969849 732.645630,1843.234131 735.686584,1844.898560 
	C739.450806,1846.958740 741.147888,1844.341309 742.794250,1841.737183 
	C744.570679,1838.927002 744.029358,1836.631226 740.922852,1835.277588 
	C737.562988,1833.813477 734.109924,1832.551392 731.462341,1828.839355 
	C731.055359,1820.484131 735.017273,1817.511475 742.381775,1819.154663 
	C745.007996,1819.740723 747.836304,1821.022705 749.835510,1818.834229 
	C756.401245,1811.647583 764.947266,1811.710205 773.526978,1811.867188 
	C778.378113,1811.955811 781.468872,1809.242920 784.888428,1805.485718 
	C786.246765,1804.555664 787.102051,1804.173584 788.689941,1803.770996 
	C795.267273,1804.754517 801.342407,1806.539551 805.590332,1798.981079 
	C806.619629,1798.341309 807.063599,1798.127686 808.224426,1797.696777 
	C816.024353,1796.896729 823.061462,1796.068604 830.511475,1793.456055 
	C836.911865,1792.619507 843.611511,1794.294189 846.854126,1786.276855 
	C848.112549,1785.174927 848.986145,1784.814575 850.658569,1784.711914 
	C856.779785,1786.902222 860.466675,1790.645386 861.956604,1796.357910 
	C863.088623,1800.698364 866.100830,1801.693481 870.777222,1801.054321 
	C873.354736,1801.064087 875.163025,1800.443726 877.736389,1800.442505 
	C885.880981,1803.771362 889.524475,1801.427124 889.350891,1793.208252 
	C889.316589,1791.581543 889.918274,1790.009644 891.156860,1787.981201 
	C895.278320,1785.158203 898.495483,1786.647949 902.343384,1788.956299 
	C905.405334,1790.340088 907.777222,1791.375732 910.424072,1793.470703 
	C913.067139,1800.453613 911.305908,1807.251465 912.912354,1814.441406 
	C913.259827,1818.418457 911.655151,1821.804443 917.658691,1822.772461 
	C924.892639,1823.938721 933.535034,1841.803711 931.608276,1849.061157 
	C929.991516,1855.151123 924.564819,1855.777588 920.429016,1854.315552 
	C912.549377,1851.530273 905.548096,1855.551514 898.133240,1855.466919 
	C893.404541,1855.412842 893.959412,1858.606323 894.243530,1862.376953 
	C894.262146,1863.588013 894.242737,1864.076050 894.127258,1865.295654 
	C893.587280,1867.921143 892.828918,1869.752441 892.867065,1872.462524 
	C892.222290,1876.229614 892.548462,1880.589722 887.818481,1879.755493 
	C884.602112,1879.188232 883.810425,1875.940796 884.230774,1872.639404 
	C884.257019,1872.433716 881.928040,1873.985718 879.735840,1873.548584 
	C873.071350,1870.459229 869.012756,1865.018677 863.401794,1861.391724 
	C857.586609,1857.632935 859.919189,1853.594604 869.493225,1850.512817 
	C860.148987,1843.827148 859.986633,1841.974121 868.298035,1831.162231 
	C855.249084,1832.810913 844.466064,1828.039062 834.675659,1820.840454 
	C827.985779,1815.921509 822.402100,1816.362305 817.832825,1823.169678 
	C813.984741,1828.902710 808.655701,1831.045166 802.420349,1829.475098 
	C796.280212,1827.928955 796.863770,1831.213989 797.034546,1835.667725 
	C793.285461,1840.448120 792.995789,1845.037720 794.716797,1850.046387 
	C803.096436,1842.692871 803.696350,1842.704590 808.388672,1850.907715 
	C808.394775,1852.575317 808.237549,1853.518433 807.707275,1855.124268 
	C806.517212,1857.567261 804.963074,1858.798340 803.152588,1860.760010 
	C801.449768,1862.138916 800.172180,1862.824585 798.165405,1863.652344 
	C778.860535,1864.696899 760.260376,1866.212158 742.025391,1859.906738 
	C736.179138,1857.885254 728.799438,1855.073608 725.258972,1864.366821 
	C724.598877,1866.099487 722.911072,1866.566406 721.082153,1865.844971 
	C713.827942,1862.982910 707.539307,1866.749146 700.313293,1868.994751 
	C698.123352,1869.388184 696.766113,1869.029541 695.114014,1867.526367 
	C693.408447,1856.451782 689.533386,1845.866089 697.842468,1836.024292 
	C700.145569,1833.296631 698.299133,1828.386963 699.978394,1821.945679 
	C692.869934,1828.709351 687.112000,1834.098755 678.770325,1829.085083 
	C676.840698,1827.925415 675.029053,1829.487793 674.097473,1831.530640 
	C670.447266,1839.535522 666.246216,1838.131470 661.303101,1831.759033 
	C657.736755,1828.278564 655.132751,1822.083130 649.998718,1826.658936 
	C646.989441,1829.341187 643.583740,1834.596924 650.169739,1838.968018 
	C654.210083,1839.145630 657.581665,1838.662354 660.796143,1839.334595 
	C666.959656,1840.623535 669.701355,1843.728516 664.638123,1850.240479 
	C662.632019,1851.551147 661.139771,1851.956665 658.849854,1852.100708 
	C656.796509,1852.012329 655.494263,1851.705566 653.599487,1851.389893 
	C651.237793,1851.641479 650.579041,1853.091309 649.453125,1854.956177 
	C646.709595,1858.212158 643.302124,1857.566162 639.565125,1858.268555 
	C636.405579,1858.656250 633.972412,1858.663086 630.963013,1857.432495 
	C629.984131,1856.717041 629.653076,1856.358154 629.099121,1855.307861 
	C628.673828,1852.147827 630.649292,1850.256836 630.809509,1848.009033 
	C631.056030,1844.548950 630.208069,1841.652588 625.692383,1844.033569 
	C622.880188,1845.516357 619.998718,1846.591431 617.587280,1843.698853 
	C615.345215,1841.009399 616.624146,1838.021362 617.678101,1835.203369 
	C619.311584,1830.835571 620.912842,1826.478271 624.973694,1823.578857 
	C627.134033,1822.036499 629.298401,1820.301025 628.460693,1816.376709 
	C628.355652,1814.746460 628.467041,1813.825928 629.033752,1812.304810 
	C632.987366,1807.346313 639.136536,1811.718750 642.860779,1807.462769 
	C639.411560,1809.425049 635.533752,1807.737305 631.415771,1809.406250 
	C627.320435,1810.682007 628.487610,1814.596069 625.879639,1816.941895 
	C621.968933,1818.574829 619.095398,1818.056396 616.350769,1814.634766 
	C615.236816,1811.979004 614.694519,1809.955688 614.047852,1807.163330 
	C613.790710,1803.787842 614.481323,1801.371460 615.979614,1798.419922 
	C619.730591,1793.688599 624.164246,1791.104370 629.494873,1788.596436 
	C638.028748,1785.712158 645.699280,1781.329224 655.348511,1784.022827 
	C659.301636,1780.730591 656.941833,1776.018433 659.279419,1771.709961 
z"
        />
        <path
          fill="#7A7E89"
          opacity="1.000000"
          stroke="none"
          d="
M551.034790,1637.610840 
	C553.788025,1640.886230 554.084167,1644.446899 554.058777,1648.155762 
	C553.936157,1666.040649 553.919312,1683.926270 553.865295,1701.811646 
	C553.862305,1702.805298 553.711182,1703.826660 553.886963,1704.787964 
	C554.631042,1708.855103 549.097534,1712.852539 550.683044,1714.487671 
	C553.305115,1717.191772 553.815613,1719.461548 554.050598,1722.788086 
	C555.219727,1739.340698 550.621765,1755.506348 552.163513,1771.606323 
	C545.564453,1773.725098 539.149902,1769.168579 534.145813,1775.385498 
	C534.785156,1778.446045 536.677124,1779.774658 538.388000,1781.360474 
	C542.398926,1785.078125 541.858032,1788.197510 535.983948,1790.467285 
	C528.278076,1791.568237 521.487671,1788.411499 513.711365,1789.582520 
	C512.440674,1789.583496 511.931976,1789.548828 510.677002,1789.344238 
	C506.846008,1787.497314 503.320251,1786.518188 500.709412,1782.881836 
	C500.120239,1781.234131 500.004730,1780.250122 500.212799,1778.533936 
	C502.625793,1772.983887 507.405243,1773.408813 511.522888,1772.541748 
	C516.993530,1771.389893 522.770081,1772.472534 527.587524,1770.430542 
	C518.655334,1764.755615 507.321106,1768.808838 497.643433,1764.412598 
	C494.643280,1763.049805 490.642639,1761.765625 491.346100,1757.737305 
	C492.195679,1752.872314 496.360260,1753.831055 499.507355,1753.917480 
	C495.648865,1752.333252 491.343414,1751.947632 486.854797,1753.966187 
	C483.198059,1755.610596 479.725830,1755.525146 476.524841,1751.544434 
	C475.091461,1747.133301 475.129242,1743.677490 479.482788,1741.008057 
	C486.181580,1743.168091 492.094543,1740.962524 500.214233,1740.694580 
	C492.923431,1740.251587 487.929749,1743.369629 482.135681,1740.761963 
	C477.441376,1734.240967 473.213135,1728.213745 479.333221,1720.671997 
	C481.021973,1718.591064 479.928955,1715.173828 478.856873,1712.965454 
	C475.713196,1706.490479 476.897614,1702.139160 484.175659,1700.082275 
	C477.083252,1690.356934 476.271118,1679.345947 477.906311,1668.837402 
	C479.069092,1661.364624 479.267609,1654.612915 477.070129,1647.542236 
	C475.998810,1644.095093 474.697113,1640.498047 477.431213,1636.407959 
	C478.697998,1631.752808 481.907379,1632.006836 484.889801,1632.206543 
	C497.768494,1633.068237 510.647888,1633.938599 523.512573,1634.984009 
	C532.416565,1635.707642 541.296936,1636.721924 551.034790,1637.610840 
z"
        />
        <path
          fill="#817A79"
          opacity="1.000000"
          stroke="none"
          d="
M325.562408,1724.294434 
	C327.069489,1724.364746 327.891022,1724.215454 329.328003,1723.950317 
	C335.938690,1720.553955 341.380829,1723.477417 346.800873,1725.515503 
	C351.995209,1727.468628 357.200256,1729.413574 363.011475,1731.892578 
	C369.653442,1736.625244 376.240356,1737.517944 384.101562,1735.854736 
	C386.554352,1736.344604 387.946808,1737.187500 389.507721,1739.233154 
	C390.371216,1741.088013 390.639832,1742.229736 390.847107,1744.298096 
	C390.853760,1751.774658 392.622986,1758.471313 389.234100,1765.600464 
	C377.598969,1768.498901 366.333893,1767.199463 355.224670,1766.679932 
	C339.947418,1765.965576 324.634369,1766.291504 313.574829,1764.998047 
	C331.958191,1765.460449 354.493774,1767.131104 377.082428,1767.042725 
	C379.130463,1767.034790 381.018066,1767.632446 383.531616,1768.917725 
	C385.826813,1770.956055 386.511047,1772.985962 387.309509,1775.817383 
	C387.871704,1777.498413 388.051971,1778.481079 388.173950,1780.196533 
	C387.832214,1783.729736 388.459137,1786.392822 389.536377,1789.746338 
	C388.977081,1795.862061 385.028503,1795.601196 380.302856,1795.565186 
	C353.079376,1794.816284 326.854858,1791.844116 299.799408,1789.959717 
	C295.506897,1780.409790 299.628296,1775.137207 311.565216,1774.570190 
	C304.282196,1773.298096 297.898163,1776.037231 291.326233,1775.088623 
	C285.547974,1774.255005 282.200592,1772.329346 282.898407,1765.613403 
	C283.550507,1759.337524 282.786591,1752.956177 283.142151,1746.626587 
	C282.805817,1750.458984 283.008972,1754.305176 282.999146,1758.143555 
	C282.962799,1772.364136 282.982941,1772.364136 269.243347,1773.339600 
	C271.236786,1772.683838 273.197601,1773.127075 275.072296,1773.733154 
	C278.437164,1774.821167 281.459564,1776.672852 280.674286,1780.729736 
	C279.935974,1784.543701 276.539490,1785.708008 273.075836,1785.981445 
	C269.398987,1786.271729 265.920532,1784.682617 261.592255,1784.239014 
	C257.884827,1782.610596 254.984909,1780.982422 251.774368,1778.581787 
	C249.840622,1776.655273 249.559692,1774.288208 246.932709,1773.160645 
	C243.806564,1772.917236 241.281464,1772.862427 238.136292,1773.604248 
	C236.558517,1773.635376 235.679230,1773.449951 234.267319,1772.741943 
	C232.488663,1768.304688 234.546265,1764.172241 232.876022,1759.928345 
	C231.615570,1756.725586 234.124481,1754.508179 238.260437,1753.878906 
	C245.015457,1752.152344 251.257858,1753.584717 257.386993,1752.833008 
	C251.514755,1753.351562 245.601501,1752.520996 239.059204,1753.480103 
	C233.010162,1753.324951 230.439133,1749.688354 234.021072,1746.136719 
	C238.821671,1741.376343 234.392365,1738.662842 233.407776,1734.291992 
	C232.771835,1715.067383 233.286224,1696.623779 232.902954,1677.291260 
	C232.601425,1675.213623 232.293137,1674.053833 231.660278,1672.037598 
	C231.099136,1663.888672 229.984863,1656.591675 232.760529,1648.682129 
	C234.278519,1647.249634 235.376068,1646.926392 237.378113,1647.280151 
	C241.560806,1650.670166 240.965744,1654.814941 240.981522,1658.759888 
	C241.057297,1677.708984 241.226044,1696.660889 239.601868,1715.572632 
	C241.197266,1701.640015 239.132660,1687.467041 243.705658,1673.846436 
	C248.806000,1680.430420 246.153717,1687.678101 246.944000,1695.369141 
	C246.956024,1696.766235 246.960571,1697.316284 246.977356,1698.674316 
	C247.341537,1707.984009 245.773026,1716.560181 248.479401,1725.444092 
	C254.032074,1727.219971 258.695618,1728.496948 264.244385,1726.156494 
	C269.482758,1723.946777 273.340393,1728.206055 276.815979,1732.690186 
	C278.196259,1732.715332 278.721497,1733.848389 279.317322,1733.556396 
	C279.891693,1733.274658 279.532013,1732.920288 278.057434,1733.059448 
	C272.078339,1728.925293 273.920013,1724.572144 278.017761,1721.891724 
	C281.637878,1719.523804 285.261505,1716.181519 290.882751,1716.617676 
	C293.620850,1717.662476 294.535187,1720.051270 297.393433,1720.879150 
	C307.110260,1721.056396 315.932678,1723.093994 325.562408,1724.294434 
z"
        />
        <path
          fill="#4E585F"
          opacity="1.000000"
          stroke="none"
          d="
M668.438599,1748.444458 
	C662.369019,1740.881714 664.445129,1732.392456 663.999329,1724.322266 
	C663.810791,1720.908447 665.086426,1716.616089 658.702271,1716.031982 
	C650.434998,1708.666138 654.232544,1700.348633 656.101440,1691.528931 
	C661.754395,1686.131592 664.636108,1688.707275 666.381104,1694.244995 
	C666.976929,1696.135986 667.049500,1698.200195 666.819458,1700.140869 
	C666.315674,1704.390259 668.537598,1707.852295 670.102783,1711.365356 
	C672.270630,1716.231079 676.126343,1708.673218 679.437622,1712.440186 
	C680.358032,1713.140503 680.678833,1713.476929 681.313721,1714.430664 
	C681.871155,1716.139648 681.878784,1717.255615 681.993591,1718.360474 
	C682.130981,1719.682739 682.731445,1720.019531 682.103821,1718.843140 
	C681.476196,1717.666748 683.530090,1717.833740 682.371582,1717.451660 
	C682.761475,1716.512817 682.989990,1716.144897 683.742920,1715.337891 
	C685.568542,1714.239380 686.962097,1714.026733 689.128296,1714.111328 
	C691.747009,1714.526367 693.557556,1715.173340 696.068298,1715.931885 
	C703.307678,1719.540283 709.276367,1724.070068 717.496155,1723.529663 
	C721.789551,1723.247559 722.377686,1722.470581 723.071228,1718.127563 
	C724.656006,1708.203979 720.152222,1697.494385 727.416443,1687.953125 
	C732.430176,1687.429810 736.006531,1688.049561 735.832092,1693.841797 
	C734.903259,1724.687622 736.578064,1755.573486 734.211182,1787.205933 
	C732.998779,1788.078979 731.252258,1787.881958 731.119263,1788.256958 
	C728.828613,1794.715942 721.144165,1793.550049 717.508789,1798.022461 
	C716.442383,1799.334351 715.110474,1799.208130 714.373230,1797.798096 
	C711.328125,1791.973755 709.297913,1793.858398 707.468567,1798.566528 
	C706.967407,1799.856323 705.985413,1800.722168 703.772827,1801.199585 
	C701.503357,1799.236816 701.761108,1792.364380 695.458740,1795.150757 
	C694.718872,1795.477783 692.603821,1793.622559 691.655640,1792.376099 
	C688.828674,1788.660400 686.600220,1785.843018 683.182800,1792.147583 
	C681.712097,1794.860962 678.644653,1793.856567 678.390747,1790.768433 
	C678.041260,1786.518433 676.722229,1782.316528 677.669678,1777.921143 
	C678.283936,1775.071655 678.534485,1772.024048 676.399841,1768.700806 
	C672.346069,1772.531006 674.737366,1777.078369 673.969238,1780.938599 
	C673.306580,1784.268066 675.946960,1789.859497 671.689331,1790.469604 
	C666.874146,1791.159424 668.470947,1784.992920 667.096924,1781.947876 
	C666.022766,1779.567505 665.828979,1776.803589 665.096924,1774.252197 
	C663.772827,1769.637573 663.011108,1765.215698 666.672302,1761.125244 
	C668.598145,1758.973877 667.834900,1756.044312 668.101929,1752.583496 
	C669.097717,1750.941040 669.328430,1749.971069 668.438599,1748.444458 
z"
        />
        <path
          fill="#73828A"
          opacity="1.000000"
          stroke="none"
          d="
M117.902756,1651.543945 
	C121.170616,1653.632202 124.105301,1653.791016 127.119270,1653.850220 
	C132.687134,1653.959473 138.492340,1653.282715 141.522919,1659.968384 
	C142.500336,1662.124756 145.424026,1661.918457 147.800827,1662.042847 
	C159.659225,1662.663330 159.656540,1662.714722 160.030228,1675.137451 
	C163.603180,1679.718018 163.623825,1684.428589 163.639999,1689.074829 
	C163.689636,1703.329102 163.875565,1717.584473 163.414413,1732.717529 
	C162.565872,1742.974487 163.477127,1752.489868 160.731262,1762.499268 
	C159.652725,1765.828125 158.436157,1768.186279 156.213348,1770.871948 
	C153.930542,1772.642578 152.229248,1773.885010 149.803589,1775.483521 
	C147.005493,1776.602661 144.867767,1776.815063 141.882233,1776.446045 
	C135.051254,1774.243896 134.967545,1768.941650 134.705536,1764.008179 
	C133.473434,1740.808838 134.943039,1717.584229 134.189026,1694.368652 
	C133.945374,1686.867065 132.860138,1679.331421 134.656509,1671.138428 
	C133.195221,1672.570190 133.370758,1673.576782 133.359421,1674.498535 
	C133.117172,1694.154663 133.630692,1695.839600 110.132355,1692.595215 
	C108.483734,1692.367676 106.899483,1691.847778 104.578506,1690.984253 
	C100.996452,1690.218506 98.483177,1689.031616 95.573784,1686.782593 
	C89.138351,1674.924683 91.868324,1662.134888 88.726181,1649.605469 
	C93.724678,1641.868774 99.334900,1640.158936 106.796417,1646.593994 
	C110.900169,1647.977173 114.490524,1648.619385 117.902756,1651.543945 
z"
        />
        <path
          fill="#6F7981"
          opacity="1.000000"
          stroke="none"
          d="
M385.576202,1728.708252 
	C386.757019,1717.143433 385.396027,1706.206421 387.379089,1694.676025 
	C390.455017,1686.302368 388.931946,1678.354858 388.831146,1670.567505 
	C388.739349,1663.474976 391.859039,1660.213013 398.252014,1658.976440 
	C400.525330,1658.536865 402.794098,1658.068848 405.088562,1657.762329 
	C416.855133,1656.189941 419.845032,1658.409302 422.045959,1669.997192 
	C423.363312,1676.933105 421.787292,1684.864990 429.054474,1690.633057 
	C427.584381,1699.585571 429.321289,1706.787598 435.573761,1712.791016 
	C437.679871,1714.813354 438.075378,1717.348877 436.384216,1719.953979 
	C436.024750,1720.507812 435.069000,1721.073486 435.131897,1721.400024 
	C438.081482,1736.712769 426.083191,1734.493896 416.908875,1736.553467 
	C413.759491,1737.200562 411.363708,1737.990845 408.044769,1737.919067 
	C400.455414,1736.713257 393.490906,1736.268799 386.371765,1733.046387 
	C385.142700,1731.562256 384.939178,1730.514648 385.576202,1728.708252 
z"
        />
        <path
          fill="#797771"
          opacity="1.000000"
          stroke="none"
          d="
M727.419312,1687.166748 
	C726.375977,1693.731689 724.845276,1699.842163 727.074890,1706.149658 
	C727.637695,1707.741821 728.185486,1709.556519 727.145691,1710.942627 
	C724.670105,1714.242432 724.748779,1716.898193 726.814392,1720.753296 
	C729.247681,1725.294312 726.205505,1728.243530 720.891113,1727.270752 
	C718.296814,1726.795776 715.581665,1726.381958 713.237183,1725.272583 
	C709.089600,1723.310181 708.284729,1726.859985 705.926941,1729.575439 
	C703.471497,1725.433716 703.074219,1719.499634 696.447266,1718.716797 
	C693.857544,1711.583252 700.209412,1704.205444 694.125244,1698.622314 
	C696.909424,1704.295776 699.439697,1710.713501 690.711426,1715.646973 
	C688.567688,1715.941895 687.057617,1715.973999 684.792358,1716.009521 
	C683.242798,1715.471191 682.634399,1714.757935 682.358093,1713.843994 
	C681.955078,1712.511230 682.132080,1711.103271 681.939697,1709.739502 
	C681.896179,1711.007324 681.648010,1712.216675 680.390625,1713.690918 
	C676.371826,1712.994873 673.411804,1712.892334 672.425293,1717.467651 
	C672.028015,1719.310059 672.265442,1721.463257 670.604614,1722.843994 
	C667.591797,1722.279419 668.330078,1719.947754 668.098328,1718.140991 
	C667.806824,1715.867798 668.356689,1713.048950 667.203186,1711.407227 
	C663.885681,1706.685669 663.844910,1701.569580 664.024231,1696.268921 
	C664.132690,1693.062622 663.711975,1690.202637 658.718323,1690.047119 
	C652.470764,1688.621338 653.563354,1685.053711 655.568970,1680.632446 
	C656.129395,1679.896851 656.183655,1679.126831 656.144470,1678.742920 
	C657.710205,1673.777832 661.366272,1674.300293 664.986328,1674.825195 
	C669.685364,1675.506714 672.491943,1672.915405 675.409302,1669.244995 
	C677.179626,1669.786377 678.273560,1670.379639 679.784668,1671.438965 
	C682.589233,1674.436279 685.753296,1673.860107 689.379028,1674.058105 
	C690.910828,1674.090088 691.792847,1674.100464 693.335205,1674.069824 
	C695.088623,1674.026855 696.153320,1674.177246 697.669861,1674.811768 
	C701.787659,1675.253174 705.399597,1675.508301 709.013062,1675.740356 
	C711.367737,1675.891846 714.560242,1676.122192 714.727722,1678.602173 
	C715.290405,1686.932861 721.601501,1685.821289 727.419312,1687.166748 
z"
        />
        <path
          fill="#626669"
          opacity="1.000000"
          stroke="none"
          d="
M806.914856,1720.617310 
	C805.612915,1710.735840 805.774719,1701.373535 806.667603,1691.111084 
	C812.880249,1687.541260 815.719360,1692.286133 815.727661,1696.089600 
	C815.752136,1707.277710 823.004944,1708.473022 830.819153,1707.531250 
	C838.638000,1706.588867 844.353149,1711.640381 851.721191,1713.385376 
	C854.026917,1732.830811 853.468262,1751.577271 851.544861,1771.104736 
	C848.391846,1774.836914 844.838257,1774.986084 840.357605,1774.858154 
	C838.423645,1774.801880 837.341919,1774.714111 835.517212,1774.426270 
	C827.172913,1771.763184 818.490234,1772.270874 813.043701,1764.243286 
	C808.580444,1757.664795 808.437073,1753.705078 815.609436,1750.210083 
	C820.116272,1748.013794 821.770630,1744.348389 818.174438,1742.108887 
	C810.772888,1737.500000 810.511597,1730.478394 809.149231,1723.497070 
	C808.971741,1722.587402 808.037842,1721.875854 806.914856,1720.617310 
z"
        />
        <path
          fill="#947C7A"
          opacity="1.000000"
          stroke="none"
          d="
M467.340454,1639.774414 
	C468.793549,1639.590332 469.599915,1639.511963 471.009094,1639.356201 
	C474.898956,1638.408813 476.435120,1639.735962 477.169922,1643.026855 
	C477.904541,1646.317139 478.221832,1649.635498 478.145630,1652.917114 
	C477.749268,1669.991333 478.148346,1687.111084 478.237671,1704.140259 
	C478.289917,1714.099243 476.711792,1724.117676 477.810303,1734.294434 
	C478.811859,1743.572876 476.552338,1753.237183 479.697266,1763.515625 
	C476.899567,1773.809814 470.103424,1775.192993 458.562927,1767.896606 
	C455.137726,1758.769653 456.442749,1750.139160 456.391052,1741.646118 
	C456.305145,1727.540771 456.385040,1713.428589 456.927734,1698.401489 
	C457.015045,1696.258423 457.030121,1695.039062 457.018921,1692.921387 
	C457.582977,1678.542603 455.358459,1664.931030 459.437500,1650.883301 
	C459.972382,1650.176758 459.750793,1649.897949 459.780548,1649.722412 
	C460.874115,1649.041260 461.575623,1648.219849 462.012817,1646.548706 
	C462.096680,1645.149780 462.106903,1644.306030 462.174866,1642.832031 
	C463.244019,1640.513794 464.913452,1640.090454 467.340454,1639.774414 
z"
        />
        <path
          fill="#8C8D89"
          opacity="1.000000"
          stroke="none"
          d="
M292.232727,1717.703613 
	C289.162109,1717.899048 286.943756,1718.088745 286.092102,1721.015747 
	C285.451050,1723.219116 283.914490,1723.588013 281.649628,1723.949707 
	C275.604156,1724.915405 275.209320,1726.920532 279.586792,1733.509399 
	C275.663666,1734.913208 273.689575,1730.638916 270.148926,1729.860474 
	C267.705658,1729.323120 264.405609,1724.307007 263.555573,1731.409302 
	C263.343109,1733.184326 261.535492,1732.418091 260.446350,1731.630127 
	C256.882751,1729.051147 252.676804,1730.482788 247.969727,1729.562988 
	C245.981323,1727.275635 246.019012,1725.031006 246.860199,1722.002686 
	C248.388245,1719.973877 250.223892,1718.463257 251.460068,1719.174072 
	C260.376617,1724.300659 258.574677,1716.203003 258.868134,1712.708618 
	C259.807922,1701.517700 258.268707,1690.221802 260.117188,1678.229492 
	C263.918213,1672.763306 262.864258,1667.293335 263.104706,1662.031372 
	C263.299377,1657.770630 262.300964,1653.324829 265.419678,1648.729980 
	C265.964905,1648.068359 266.837860,1648.248901 267.277130,1648.324463 
	C268.179413,1648.755859 268.642426,1649.111694 269.559174,1650.213623 
	C272.060913,1660.495483 270.040344,1670.216431 271.530823,1680.541382 
	C274.455780,1691.442139 272.306396,1701.632935 271.972900,1709.574707 
	C276.499084,1695.982178 268.773712,1680.052490 272.731995,1663.535156 
	C275.917480,1659.535034 274.342834,1654.695312 277.629395,1650.840332 
	C279.592926,1650.319336 280.919434,1650.260132 282.911255,1650.206421 
	C286.756409,1662.516479 284.397919,1674.852905 284.145081,1685.172485 
	C285.841156,1675.339111 284.605774,1663.372314 285.734650,1650.820435 
	C287.609741,1650.172241 288.915405,1650.178223 290.874207,1650.174805 
	C295.371643,1654.217285 295.266815,1658.985718 294.095032,1663.985107 
	C292.065704,1672.643188 292.971924,1681.301514 293.347900,1689.980225 
	C292.784332,1682.695312 293.093140,1675.348999 293.217224,1667.151367 
	C294.686096,1664.719971 296.353546,1664.532593 298.924072,1665.566406 
	C299.719208,1677.455811 299.790283,1688.820068 299.899109,1701.123657 
	C300.674500,1702.802979 300.986847,1703.692749 300.423950,1705.327637 
	C299.899200,1706.815918 299.825867,1707.708130 299.657898,1709.264893 
	C298.863586,1710.701660 297.999207,1711.162354 296.226349,1711.230347 
	C292.862793,1709.979248 292.894104,1708.155396 293.245880,1706.641602 
	C291.672455,1709.522095 296.308716,1713.917480 292.232727,1717.703613 
z"
        />
        <path
          fill="#4C565C"
          opacity="1.000000"
          stroke="none"
          d="
M806.320007,1721.004883 
	C813.980042,1722.010986 814.599609,1722.427856 813.517578,1729.954468 
	C813.010681,1733.480591 812.738281,1735.466309 816.927979,1736.321655 
	C820.481445,1737.047119 822.012268,1740.523071 823.304138,1743.459961 
	C825.591064,1748.659424 823.198181,1752.658447 817.436462,1753.240845 
	C815.224243,1753.464600 812.654785,1751.959351 812.201050,1755.568848 
	C811.857422,1758.302368 811.974670,1760.957275 815.286011,1762.021118 
	C817.267578,1762.657593 818.982361,1763.775879 819.590393,1765.812012 
	C820.871155,1770.100830 823.765564,1770.603760 827.340881,1769.218872 
	C829.900879,1768.227295 831.830811,1768.625122 833.705933,1771.493286 
	C835.888550,1778.342529 831.037781,1777.792725 826.724854,1778.540039 
	C820.465210,1778.032959 814.465881,1774.526123 809.733215,1781.679932 
	C806.678162,1785.754883 802.659180,1784.234985 799.316162,1784.633667 
	C791.480835,1785.568726 786.621765,1788.747925 786.810425,1797.397705 
	C786.866699,1799.976929 786.250183,1802.577759 784.063354,1805.296387 
	C773.758728,1809.041992 772.594482,1808.461060 769.580444,1798.063477 
	C770.588074,1789.287964 768.558105,1780.172607 775.979126,1774.364746 
	C779.103333,1771.919678 779.168823,1770.951172 778.895569,1766.909424 
	C778.331726,1758.571411 780.087524,1749.845825 787.708740,1743.260010 
	C791.123535,1741.812988 794.057800,1741.961548 796.991211,1741.946655 
	C805.841431,1741.901611 805.837463,1741.876953 805.981628,1732.650757 
	C806.037537,1729.070679 806.115417,1725.490967 806.320007,1721.004883 
z"
        />
        <path
          fill="#A2BBCE"
          opacity="1.000000"
          stroke="none"
          d="
M160.609711,1735.981201 
	C160.043442,1716.079224 159.992569,1696.970947 160.016113,1676.926392 
	C171.045410,1673.562988 179.919083,1665.131958 191.711380,1665.081787 
	C197.639557,1665.056641 202.105164,1661.729126 207.167282,1658.112061 
	C211.648788,1661.407227 211.203522,1665.599731 209.715683,1670.735352 
	C208.588516,1672.544922 207.674332,1673.309326 205.744415,1674.130371 
	C204.359558,1674.506714 203.799286,1674.596558 202.406296,1674.739258 
	C185.401764,1673.516846 185.356689,1673.516724 185.305618,1689.915405 
	C185.293228,1693.894531 184.761627,1697.853638 185.089691,1702.678711 
	C185.510910,1715.092163 186.843140,1726.693115 183.610016,1738.986816 
	C173.923157,1744.701904 169.691727,1744.118530 160.609711,1735.981201 
z"
        />
        <path
          fill="#8C8D89"
          opacity="1.000000"
          stroke="none"
          d="
M807.896118,1664.850342 
	C811.635315,1663.191650 814.884705,1658.772705 819.272766,1663.191772 
	C820.793579,1664.723145 823.104370,1663.465332 823.867432,1661.198608 
	C824.669067,1658.817749 826.627869,1657.594360 828.544556,1655.964355 
	C831.226685,1656.814087 829.289978,1661.828491 832.632629,1661.381714 
	C835.200562,1661.038330 838.788269,1659.852417 839.787048,1657.931274 
	C842.177124,1653.333252 843.970032,1655.973511 845.226257,1657.993652 
	C846.164246,1659.501831 845.921082,1661.810059 846.003296,1663.770508 
	C846.155579,1667.399292 846.148682,1671.034424 846.252197,1674.665649 
	C846.259888,1674.937378 846.620483,1675.199097 847.176270,1675.952026 
	C848.616882,1671.564331 847.539734,1667.357788 848.229126,1662.457275 
	C855.052063,1660.676392 854.661133,1666.000244 855.112488,1669.860596 
	C856.066589,1678.021484 854.877075,1686.093384 852.590027,1694.774902 
	C851.081238,1697.736938 848.443726,1700.142334 847.342712,1696.932861 
	C843.133057,1684.661133 831.753296,1688.680542 826.470947,1690.858521 
	C815.850281,1695.237549 811.786438,1690.761230 807.027344,1682.738281 
	C804.785828,1676.526978 807.782288,1671.213257 807.896118,1664.850342 
z"
        />
        <path
          fill="#5A6668"
          opacity="1.000000"
          stroke="none"
          d="
M459.363953,1691.461060 
	C459.931427,1692.830444 459.990936,1693.734131 460.035187,1695.317139 
	C455.311584,1702.067383 449.167450,1698.785889 443.581512,1698.329468 
	C438.736328,1697.933716 433.726593,1697.779053 428.764954,1694.592041 
	C427.810852,1691.405029 426.245819,1690.198975 423.562164,1689.861572 
	C419.423859,1689.341064 417.864136,1687.078003 419.316132,1682.920776 
	C421.652954,1676.229980 417.698914,1669.998901 417.691528,1663.435791 
	C417.688293,1660.562744 414.965912,1659.799683 412.553131,1660.097778 
	C406.698761,1660.820923 400.835968,1661.619019 395.063019,1662.807007 
	C391.331116,1663.575073 390.113129,1666.429565 389.969818,1670.223633 
	C389.685181,1677.758789 391.202911,1685.376831 388.446533,1693.417969 
	C387.815186,1682.466675 387.769897,1670.927856 387.757721,1659.389160 
	C387.754608,1656.422119 387.427521,1653.072754 391.503265,1652.448364 
	C407.714478,1649.964844 423.796021,1645.466919 441.185852,1649.365234 
	C444.521790,1653.577271 441.763123,1655.339844 438.824432,1656.111206 
	C429.689392,1658.509033 431.102783,1665.866211 431.376434,1672.182373 
	C431.666168,1678.869751 434.681396,1683.890747 442.049347,1685.860718 
	C447.683350,1687.366943 454.094452,1686.607300 459.363953,1691.461060 
z"
        />
        <path
          fill="#46565D"
          opacity="1.000000"
          stroke="none"
          d="
M459.945679,1691.003052 
	C452.184662,1690.606445 444.485748,1689.349609 436.813721,1687.946167 
	C430.203552,1686.736816 430.109406,1680.194824 428.442841,1675.878662 
	C426.067993,1669.728027 429.327667,1662.881470 429.019348,1656.253784 
	C428.922546,1654.172852 431.236938,1654.285767 432.850433,1654.010010 
	C435.904663,1653.487915 439.737213,1655.368774 441.795044,1650.460449 
	C447.356415,1649.770508 452.772552,1649.849731 459.080536,1650.052856 
	C462.360229,1663.474731 459.067413,1676.779541 459.945679,1691.003052 
z"
        />
        <path
          fill="#6B6164"
          opacity="1.000000"
          stroke="none"
          d="
M771.441284,1795.504395 
	C772.938110,1806.413452 773.174500,1806.592896 783.484985,1804.059692 
	C785.296631,1803.884521 786.118103,1804.288208 786.334778,1805.827759 
	C783.892151,1812.122192 781.393799,1816.341919 773.799194,1814.583130 
	C769.949280,1813.691772 765.679077,1815.199219 761.317688,1814.692749 
	C757.419983,1814.240234 754.306702,1819.011597 751.201843,1821.878906 
	C748.276672,1824.580322 746.573059,1826.432617 742.563110,1822.273682 
	C737.218079,1816.730347 735.173767,1823.784546 732.075684,1827.277344 
	C728.596924,1829.960449 727.153809,1827.846069 725.765198,1824.452637 
	C725.466248,1822.703125 725.441833,1821.702271 725.576111,1819.986816 
	C726.239868,1816.603760 725.963684,1813.784058 727.421021,1810.646240 
	C728.955505,1806.835327 733.263306,1806.217285 734.259094,1801.953857 
	C745.213684,1799.751099 756.363708,1801.260254 767.312988,1796.347778 
	C768.225098,1795.647949 768.507080,1795.386230 769.346436,1794.974365 
	C770.277039,1794.917969 770.650269,1795.011963 771.441284,1795.504395 
z"
        />
        <path
          fill="#797771"
          opacity="1.000000"
          stroke="none"
          d="
M806.468445,1683.002930 
	C809.722656,1681.759277 810.908386,1683.471680 812.492126,1686.058472 
	C816.286072,1692.254761 818.889893,1692.184937 823.779236,1686.566650 
	C824.934753,1685.238770 826.944336,1683.479980 827.675964,1684.635132 
	C831.254333,1690.285156 836.167053,1686.013916 840.234863,1686.454346 
	C847.113953,1687.199219 847.178833,1686.480225 848.094055,1693.851196 
	C848.214417,1694.819946 848.550476,1695.761963 849.265259,1697.287354 
	C853.844788,1701.639038 853.493958,1705.966187 850.585083,1711.207275 
	C849.241028,1712.069458 848.298523,1712.039551 848.028076,1712.451050 
	C845.415710,1716.427979 842.945557,1716.065552 840.545227,1712.320557 
	C839.859009,1711.250000 839.353638,1710.063477 838.851196,1709.095337 
	C835.406677,1708.599976 834.054993,1719.084961 829.485352,1710.361694 
	C828.231506,1707.968140 825.200073,1709.912598 823.102417,1710.182251 
	C814.700806,1711.262573 814.240723,1711.139404 814.075928,1702.650757 
	C813.976379,1697.523193 813.924683,1692.672607 807.515747,1690.305420 
	C806.684265,1688.072754 806.512268,1685.935181 806.468445,1683.002930 
z"
        />
        <path
          fill="#687073"
          opacity="1.000000"
          stroke="none"
          d="
M262.316101,1677.042969 
	C262.153809,1689.953247 262.153229,1702.162720 262.027344,1714.370972 
	C261.998383,1717.178833 262.768036,1720.175781 259.121460,1722.023315 
	C256.254822,1723.475464 254.312378,1724.676025 252.342667,1720.946289 
	C251.826385,1719.968506 249.952332,1721.075317 248.224182,1721.972168 
	C243.871902,1721.722900 245.289490,1719.143066 245.668671,1717.122925 
	C246.702667,1711.614990 245.316727,1706.262329 244.352997,1700.215332 
	C243.920624,1699.577026 244.101639,1699.118164 244.123016,1698.872559 
	C246.541611,1689.839600 248.085175,1681.073975 242.952759,1671.796143 
	C240.136078,1664.827637 240.624542,1658.457886 242.384933,1651.300537 
	C243.263412,1650.158081 243.706863,1649.782715 245.000137,1649.256348 
	C250.587708,1649.838501 251.042130,1653.265625 251.000504,1657.119873 
	C250.952515,1661.561768 250.926773,1666.013794 251.449829,1670.424194 
	C252.139877,1671.701294 253.200363,1669.818237 252.048248,1670.489380 
	C251.611496,1670.743774 251.353378,1670.535767 251.296646,1669.863403 
	C250.776611,1663.700806 250.938217,1657.534912 251.875061,1650.851562 
	C253.286301,1649.859985 254.036026,1649.385376 255.143021,1648.647339 
	C259.554108,1648.938354 258.855560,1652.024292 258.863068,1655.395020 
	C258.823547,1657.382080 258.826508,1658.699829 258.844391,1660.666748 
	C258.918884,1662.628174 259.156311,1663.906128 259.767090,1665.797607 
	C260.274719,1667.784790 260.437958,1669.145996 260.540222,1671.216553 
	C260.484924,1672.378296 260.422363,1672.840332 260.126038,1673.957153 
	C259.715759,1675.036987 259.539307,1675.462280 259.145630,1676.539673 
	C257.419312,1681.054321 258.222931,1685.098389 257.971832,1689.059937 
	C258.200684,1685.546753 257.565247,1681.983643 258.859436,1677.867310 
	C259.374298,1676.826538 259.633667,1676.435181 260.562683,1675.812012 
	C261.632324,1675.833984 262.032288,1676.087891 262.316101,1677.042969 
z"
        />
        <path
          fill="#E7E3DA"
          opacity="1.000000"
          stroke="none"
          d="
M208.547943,1671.952393 
	C208.118530,1667.292114 208.054749,1663.470825 207.937210,1658.826416 
	C207.921356,1656.499878 207.959259,1654.996460 207.948746,1652.742188 
	C206.818344,1650.569824 206.539352,1649.020264 207.709549,1646.707764 
	C213.031342,1646.207520 217.844299,1645.439453 223.184387,1647.227051 
	C228.708801,1653.388062 227.601761,1660.469360 226.612381,1666.680542 
	C223.778290,1684.472656 225.533585,1702.288940 225.038956,1720.078613 
	C224.597992,1735.938354 225.381912,1751.815918 224.280609,1768.616943 
	C223.150070,1771.445679 221.830429,1772.735840 218.724426,1772.656982 
	C214.317047,1768.925049 215.114349,1764.408447 215.118698,1760.193604 
	C215.143295,1736.349609 214.433975,1712.489868 216.306091,1688.677490 
	C216.834503,1681.956543 215.557373,1676.142456 208.547943,1671.952393 
z"
        />
        <path
          fill="#9FA2A1"
          opacity="1.000000"
          stroke="none"
          d="
M478.379456,1765.104492 
	C474.962891,1762.527344 476.118164,1758.714600 476.110260,1755.186157 
	C476.032379,1720.371948 476.134155,1685.557251 475.999054,1650.743408 
	C475.984833,1647.077515 477.872437,1642.595947 472.422577,1639.634277 
	C472.422211,1636.927368 474.216125,1636.052856 477.199127,1635.919922 
	C479.251617,1642.736450 477.846039,1650.475342 484.221588,1655.850586 
	C485.702911,1657.099487 484.846436,1658.668091 483.852295,1660.212891 
	C479.250061,1667.364502 478.814697,1675.030273 481.466766,1682.984985 
	C482.219147,1685.241699 482.302124,1687.567505 481.784637,1689.857178 
	C481.265503,1692.154053 481.554657,1693.698242 484.296173,1694.049927 
	C488.217743,1694.552612 490.511597,1696.799194 489.659149,1700.810913 
	C488.797546,1704.865845 485.034271,1703.608643 482.262482,1704.088135 
	C481.320587,1704.250977 480.428223,1704.700562 478.682983,1705.308228 
	C482.146698,1709.055908 481.224152,1714.070312 483.386688,1717.955933 
	C485.213715,1721.238892 486.021179,1723.121948 481.441681,1724.277466 
	C477.281006,1725.327393 477.393616,1728.482910 479.480804,1731.192627 
	C481.228729,1733.461914 482.148315,1735.677734 481.982239,1739.223877 
	C482.219574,1740.350952 482.451935,1740.698364 482.170044,1741.404541 
	C476.635742,1743.066162 478.342651,1747.204468 478.017517,1751.250000 
	C480.500244,1756.005737 481.929169,1760.071045 478.379456,1765.104492 
z"
        />
        <path
          fill="#8C8D89"
          opacity="1.000000"
          stroke="none"
          d="
M222.869934,1770.435181 
	C221.966721,1740.092041 221.384415,1710.163574 222.180939,1680.271851 
	C222.452423,1670.083374 223.614624,1659.830078 223.787750,1648.607910 
	C224.695801,1647.744751 225.572571,1647.842651 227.805542,1648.623413 
	C232.652679,1656.192627 230.326874,1663.569336 230.960754,1671.605469 
	C230.915344,1679.839478 230.920319,1687.164062 230.947510,1695.418213 
	C231.787430,1720.223999 229.017197,1743.953491 227.584335,1768.597412 
	C226.219696,1770.452271 225.088074,1770.926880 222.869934,1770.435181 
z"
        />
        <path
          fill="#A9AEB3"
          opacity="1.000000"
          stroke="none"
          d="
M675.874084,1668.620117 
	C671.929688,1677.799194 671.765930,1677.833130 660.928040,1676.556763 
	C659.772827,1676.420776 658.504089,1677.249390 656.696655,1677.994629 
	C651.286560,1674.111816 646.534668,1670.041016 639.474854,1669.804810 
	C632.568359,1669.573730 632.112000,1668.733398 632.072693,1661.518311 
	C632.040527,1655.613037 632.069702,1649.707642 632.078369,1642.869385 
	C632.085144,1641.936646 633.026794,1641.950806 633.497437,1641.964966 
	C638.248230,1645.858276 636.585632,1651.132324 637.191223,1655.863770 
	C637.404419,1657.529541 637.421326,1659.453613 639.409546,1659.741455 
	C642.310486,1660.161621 642.112671,1657.548096 642.549561,1655.793335 
	C643.795349,1650.790405 640.551636,1646.206177 641.510010,1640.695923 
	C646.492004,1641.739624 652.396851,1640.420532 655.752136,1643.779175 
	C659.495117,1647.525635 654.952393,1652.946655 655.947510,1657.729980 
	C656.532227,1660.540894 655.749817,1663.611938 657.643677,1667.035156 
	C660.593201,1670.991089 664.171631,1671.030396 667.877197,1670.065186 
	C669.793884,1664.940186 672.511841,1665.248169 675.874084,1668.620117 
z"
        />
        <path
          fill="#797771"
          opacity="1.000000"
          stroke="none"
          d="
M771.882812,1795.077393 
	C771.859009,1795.902832 770.971375,1795.949219 770.527588,1795.972168 
	C767.799377,1791.288696 769.652954,1786.246582 768.433228,1780.735107 
	C767.886719,1771.187500 767.656982,1762.318359 767.775085,1753.453979 
	C767.879028,1745.657104 768.532837,1745.168213 776.028870,1744.217773 
	C779.598938,1743.765015 783.146423,1743.133667 787.513062,1742.755127 
	C784.740234,1749.006104 783.497192,1755.901978 781.538696,1762.538940 
	C779.660767,1768.902710 779.746582,1768.951050 786.182312,1770.019897 
	C787.745117,1770.279541 789.498962,1769.894165 790.840332,1772.048096 
	C789.238281,1774.720459 786.378906,1773.925049 784.034912,1773.941284 
	C778.122437,1773.982056 774.884705,1776.726440 773.843933,1782.588623 
	C773.154785,1786.470703 771.412537,1790.168457 771.882812,1795.077393 
z"
        />
        <path
          fill="#B6BCC1"
          opacity="1.000000"
          stroke="none"
          d="
M385.132324,1728.020386 
	C386.083893,1728.951294 386.134979,1729.878296 386.272766,1731.496460 
	C385.949707,1733.323242 385.272430,1734.266724 383.517090,1735.367188 
	C376.880066,1737.641479 371.161011,1736.598022 364.734039,1734.598022 
	C359.660339,1735.210327 356.589691,1732.210938 352.938782,1730.878296 
	C345.911926,1728.313477 338.634460,1726.435059 330.702179,1724.053223 
	C330.484192,1719.302368 333.677521,1717.073853 337.402893,1715.390747 
	C342.634979,1713.026733 345.297302,1713.390869 348.032196,1718.542725 
	C351.130402,1724.378906 355.580902,1726.226807 361.728058,1726.067261 
	C369.283081,1725.871094 376.562531,1729.239868 385.132324,1728.020386 
z"
        />
        <path
          fill="#E7E3DA"
          opacity="1.000000"
          stroke="none"
          d="
M244.774628,1649.615479 
	C244.365845,1656.920166 244.283035,1663.510620 244.274673,1671.001831 
	C244.498062,1672.250610 244.646988,1672.598755 244.342651,1673.288940 
	C240.727112,1676.174927 241.863663,1679.753784 241.851440,1682.926758 
	C241.800110,1696.238525 241.963913,1709.550903 242.010132,1722.863159 
	C242.019135,1725.457642 242.663727,1728.201416 240.654984,1730.440918 
	C238.193893,1730.766602 237.812195,1728.873535 237.891617,1727.347168 
	C238.424973,1717.095093 237.063141,1706.851196 239.288315,1696.541626 
	C240.949326,1688.845947 241.072784,1680.693726 239.003647,1672.787598 
	C237.028992,1665.242554 239.204590,1657.520752 238.033752,1649.009888 
	C237.691406,1647.749390 237.474213,1647.406372 237.764938,1646.727539 
	C239.749695,1646.783447 241.226547,1647.175537 243.226135,1647.835083 
	C244.199432,1648.368896 244.650024,1648.635132 244.774628,1649.615479 
z"
        />
        <path
          fill="#EEE9DC"
          opacity="1.000000"
          stroke="none"
          d="
M106.280434,1649.318481 
	C105.359558,1649.821411 104.963844,1649.784546 104.781548,1649.567871 
	C98.287338,1641.850464 98.290047,1641.848145 91.168114,1648.846680 
	C86.901802,1650.561890 83.768448,1650.093994 80.589371,1646.644287 
	C84.537933,1636.244629 93.020294,1634.316650 103.169662,1634.020996 
	C103.931198,1633.948364 104.892647,1634.074219 105.377472,1634.072266 
	C106.665024,1634.929932 107.372017,1635.862183 108.273727,1637.701660 
	C109.313751,1642.135620 109.210304,1645.594971 106.280434,1649.318481 
z"
        />
        <path
          fill="#98999E"
          opacity="1.000000"
          stroke="none"
          d="
M641.203247,1640.095947 
	C646.886658,1645.521118 646.063110,1652.165405 645.992615,1658.661011 
	C645.952454,1662.364258 644.086853,1663.923096 640.468079,1663.952759 
	C636.718750,1663.983521 633.960754,1663.112671 633.995117,1658.612305 
	C634.032837,1653.684326 633.979004,1648.755859 633.966553,1642.903320 
	C635.702454,1639.853882 638.091675,1639.944092 641.203247,1640.095947 
z"
        />
        <path
          fill="#8C8D89"
          opacity="1.000000"
          stroke="none"
          d="
M807.637329,1646.617432 
	C808.589905,1645.850220 809.258240,1645.602905 809.892944,1645.670654 
	C813.729614,1646.080444 812.416870,1639.034912 816.491333,1640.760620 
	C819.864319,1642.189331 822.029297,1638.747559 825.001465,1639.643311 
	C826.810791,1642.028687 825.786743,1644.741089 825.913269,1647.287720 
	C825.975708,1648.543579 825.995178,1650.474976 824.398865,1650.498901 
	C818.260620,1650.591187 813.955627,1656.061279 807.077087,1655.866699 
	C805.976868,1652.887695 805.864380,1649.993042 807.637329,1646.617432 
z"
        />
        <path
          fill="#B6BCC1"
          opacity="1.000000"
          stroke="none"
          d="
M326.023254,1723.705322 
	C322.384796,1727.082520 317.896790,1726.419800 314.081757,1725.477661 
	C308.553650,1724.112183 302.956146,1724.239624 296.647827,1723.291992 
	C295.238678,1722.681396 294.941803,1721.924927 295.041290,1720.442871 
	C295.770752,1718.680176 296.633698,1717.771362 298.779724,1716.489990 
	C306.277832,1719.415894 313.048462,1720.697021 320.248535,1720.067017 
	C322.546875,1719.866089 324.794617,1720.300537 326.023254,1723.705322 
z"
        />
        <path
          fill="#434F51"
          opacity="1.000000"
          stroke="none"
          d="
M106.458359,1650.039673 
	C105.787971,1646.577515 105.920135,1643.212891 106.095360,1639.006836 
	C108.612656,1635.821411 111.460663,1635.794189 115.245995,1637.283691 
	C120.187561,1641.264648 117.896500,1646.064697 118.516090,1651.135742 
	C114.648354,1652.128906 110.918991,1651.350830 106.458359,1650.039673 
z"
        />
        <path
          fill="#D7D5CF"
          opacity="1.000000"
          stroke="none"
          d="
M263.005066,1677.703857 
	C262.199921,1677.744141 262.014099,1676.912109 261.884521,1676.505981 
	C259.120697,1668.730469 258.762238,1661.144165 259.849945,1652.582764 
	C260.498169,1650.553833 261.180115,1649.589355 262.995209,1648.515869 
	C264.196716,1648.185669 264.550781,1648.166138 265.434875,1648.107544 
	C265.960205,1655.944092 266.007080,1663.820557 265.917145,1671.695312 
	C265.892487,1673.854736 266.618073,1676.425537 263.005066,1677.703857 
z"
        />
        <path
          fill="#99BAD6"
          opacity="1.000000"
          stroke="none"
          d="
M503.093506,1614.293335 
	C504.836914,1614.084839 505.833282,1613.921875 506.796417,1614.036987 
	C509.442413,1614.353027 513.359680,1612.993530 513.248474,1617.623901 
	C513.182983,1620.351196 507.648010,1622.768311 504.306519,1621.562256 
	C500.676941,1620.252319 498.530914,1618.065796 503.093506,1614.293335 
z"
        />
        <path
          fill="#D0D1C8"
          opacity="1.000000"
          stroke="none"
          d="
M285.621643,1650.145264 
	C286.304047,1662.652466 286.304047,1675.138794 286.304047,1688.391479 
	C282.548370,1686.562134 282.064575,1684.052124 282.668152,1681.523926 
	C285.002991,1671.744751 283.634521,1661.862915 283.679932,1651.118408 
	C284.030823,1650.182617 284.485046,1650.153564 285.621643,1650.145264 
z"
        />
        <path
          fill="#D0D1C8"
          opacity="1.000000"
          stroke="none"
          d="
M294.877747,1665.372681 
	C294.400909,1674.569092 294.400909,1683.077148 294.400909,1691.585205 
	C293.519196,1691.592529 292.637451,1691.599731 291.755737,1691.607056 
	C291.755737,1678.404053 291.755737,1665.201050 291.641541,1651.081665 
	C292.178894,1650.533936 292.830475,1650.902466 293.790741,1651.749146 
	C295.713226,1653.907104 296.622314,1655.896118 296.740356,1659.074463 
	C296.739594,1661.638062 296.316589,1663.239746 294.877747,1665.372681 
z"
        />
        <path
          fill="#A9AEB3"
          opacity="1.000000"
          stroke="none"
          d="
M627.991821,1641.072754 
	C625.229797,1644.185547 625.989929,1647.646362 625.955261,1650.863037 
	C625.881592,1657.710083 625.932739,1664.558472 625.317261,1671.419434 
	C622.534485,1662.116333 625.605652,1652.400635 622.548950,1642.503662 
	C623.576050,1639.969849 624.804626,1636.364380 627.991821,1641.072754 
z"
        />
        <path
          fill="#E7E3DA"
          opacity="1.000000"
          stroke="none"
          d="
M252.105469,1649.864258 
	C251.946091,1657.761475 253.229950,1665.291260 251.524246,1674.055542 
	C248.482880,1669.171997 249.572708,1665.032227 249.447708,1661.134277 
	C249.323822,1657.270996 250.568695,1653.251343 247.336029,1649.057251 
	C248.478622,1648.546753 250.278641,1648.034302 252.105469,1649.864258 
z"
        />
        <path
          fill="#A2BBCE"
          opacity="1.000000"
          stroke="none"
          d="
M277.839508,1649.802490 
	C277.584045,1654.717529 279.076538,1659.281738 276.162903,1663.809814 
	C272.144958,1661.326294 274.315674,1656.667114 271.375519,1653.157715 
	C270.275146,1652.184570 269.667175,1651.604492 268.616241,1650.572876 
	C268.154236,1650.141235 267.860077,1649.286743 267.788239,1648.843262 
	C271.345276,1644.020386 274.321228,1647.300293 277.839508,1649.802490 
z"
        />
        <path
          fill="#A2BBCE"
          opacity="1.000000"
          stroke="none"
          d="
M238.054352,1645.640991 
	C238.272858,1646.391357 238.148193,1647.253906 238.028397,1647.673096 
	C237.200775,1648.114014 236.492935,1648.135498 235.257935,1648.085938 
	C233.132004,1649.151611 231.408844,1649.600464 228.704468,1648.470215 
	C227.919754,1647.977051 227.907028,1648.128174 227.830627,1648.138184 
	C229.956879,1640.844482 231.552643,1640.328857 238.054352,1645.640991 
z"
        />
        <path
          fill="#E7E3DA"
          opacity="1.000000"
          stroke="none"
          d="
M263.423889,1647.659912 
	C263.355377,1648.783691 262.868073,1649.362305 261.895874,1650.249023 
	C260.338226,1652.874512 262.235840,1657.175171 256.621399,1656.397949 
	C255.939590,1653.773438 255.844498,1651.602661 255.624847,1648.907959 
	C257.711761,1646.245117 260.114410,1645.236938 263.423889,1647.659912 
z"
        />
        <path
          fill="#99BAD6"
          opacity="1.000000"
          stroke="none"
          d="
M485.342651,1621.119629 
	C482.507294,1621.198364 480.430603,1620.357422 481.156494,1617.878540 
	C481.869659,1615.443237 487.059570,1615.076294 488.571381,1617.494873 
	C489.759460,1619.395752 487.222504,1620.002930 485.342651,1621.119629 
z"
        />
        <path
          fill="#A9AEB3"
          opacity="1.000000"
          stroke="none"
          d="
M115.825233,1637.041748 
	C113.257561,1637.746704 110.501663,1637.745728 106.942078,1637.954956 
	C106.062553,1637.256470 105.986694,1636.347656 105.886574,1634.754639 
	C109.174561,1634.592285 113.097137,1632.480957 115.825233,1637.041748 
z"
        />
        <path
          fill="#8C8D89"
          opacity="1.000000"
          stroke="none"
          d="
M295.007812,1660.424438 
	C294.331268,1657.875977 294.235046,1655.818604 294.119141,1652.994263 
	C294.099457,1652.227295 295.010956,1651.965210 295.482788,1651.917725 
	C296.718170,1652.872559 297.891663,1652.979980 299.393372,1653.664551 
	C299.818787,1655.532227 299.837128,1656.994629 299.792297,1659.186035 
	C298.566315,1661.148926 297.162018,1661.383179 295.007812,1660.424438 
z"
        />
        <path
          fill="#797771"
          opacity="1.000000"
          stroke="none"
          d="
M667.191895,1669.569092 
	C665.239868,1673.836792 661.174194,1673.722412 658.004944,1668.437988 
	C660.811768,1668.419922 663.646057,1669.081421 667.191895,1669.569092 
z"
        />
        <path
          fill="#A9AEB3"
          opacity="1.000000"
          stroke="none"
          d="
M689.521484,1673.541260 
	C686.621887,1676.703003 683.505432,1676.259644 680.419800,1672.547607 
	C683.139832,1672.266968 686.227905,1671.423218 689.521484,1673.541260 
z"
        />
        <path
          fill="#838F9A"
          opacity="1.000000"
          stroke="none"
          d="
M294.308472,1660.694702 
	C295.754089,1659.942261 297.080750,1659.951050 299.068298,1659.937500 
	C299.774384,1661.461304 299.819611,1663.007568 299.756500,1665.322632 
	C298.336182,1666.069580 297.024231,1666.047607 295.056580,1666.043335 
	C294.330414,1664.526001 294.259918,1662.991089 294.308472,1660.694702 
z"
        />
        <path
          fill="#4C565C"
          opacity="1.000000"
          stroke="none"
          d="
M767.897461,1780.932617 
	C771.709900,1784.346313 769.382019,1789.536499 770.076904,1795.137817 
	C769.726868,1795.984497 769.370056,1795.973755 768.478149,1795.936401 
	C767.905334,1791.210083 767.867554,1786.510376 767.897461,1780.932617 
z"
        />
        <path
          fill="#838F9A"
          opacity="1.000000"
          stroke="none"
          d="
M295.036560,1710.783203 
	C296.337128,1710.088989 297.261932,1710.041992 298.874969,1709.962158 
	C299.657623,1711.443970 299.751984,1712.958496 299.880371,1715.230713 
	C299.914429,1715.988281 299.523773,1716.046631 299.327332,1716.066772 
	C298.283356,1716.679932 297.348907,1716.851196 295.752106,1716.199219 
	C294.345215,1714.377197 294.203522,1712.938843 295.036560,1710.783203 
z"
        />
        <path
          fill="#A2BBCE"
          opacity="1.000000"
          stroke="none"
          d="
M207.585892,1646.159424 
	C208.181168,1647.564087 208.099289,1649.040161 207.958862,1651.253784 
	C203.347351,1649.866943 203.256622,1649.339844 207.585892,1646.159424 
z"
        />
        <path
          fill="#9FA2A1"
          opacity="1.000000"
          stroke="none"
          d="
M467.522278,1639.261597 
	C467.063324,1641.495972 465.357483,1641.873535 462.891205,1642.132080 
	C462.299011,1638.863403 463.802277,1637.596436 467.522278,1639.261597 
z"
        />
        <path
          fill="#9FA2A1"
          opacity="1.000000"
          stroke="none"
          d="
M461.634705,1646.308105 
	C463.939178,1647.913208 463.568542,1649.163208 460.384338,1649.642822 
	C460.267822,1648.572266 460.725372,1647.597534 461.634705,1646.308105 
z"
        />
        <path
          fill="#A9AEB3"
          opacity="1.000000"
          stroke="none"
          d="
M698.002441,1674.543457 
	C697.031189,1675.530273 695.908447,1675.505127 694.402222,1674.453491 
	C695.296997,1672.269775 696.577332,1672.115479 698.002441,1674.543457 
z"
        />
        <path
          fill="#B6BCC1"
          opacity="1.000000"
          stroke="none"
          d="
M300.401733,1705.638184 
	C299.938904,1705.065674 299.905334,1704.207397 299.904297,1702.706055 
	C301.281372,1702.875000 301.925385,1703.877563 300.401733,1705.638184 
z"
        />
        <path
          fill="#838F9A"
          opacity="1.000000"
          stroke="none"
          d="
M299.556824,1653.512207 
	C298.738861,1653.659546 297.677216,1653.249390 296.285095,1652.354736 
	C297.074127,1652.231812 298.193634,1652.593140 299.556824,1653.512207 
z"
        />
        <path
          fill="#A2BBCE"
          opacity="1.000000"
          stroke="none"
          d="
M246.063187,1648.263184 
	C246.832092,1648.348511 246.772812,1648.466797 246.732422,1648.504639 
	C246.349976,1648.880981 246.007935,1649.219360 245.057251,1649.943726 
	C244.307144,1649.966187 244.165665,1649.602783 243.886505,1648.671021 
	C244.264008,1648.127686 244.779144,1648.152832 246.063187,1648.263184 
z"
        />
        <path
          fill="#627A90"
          opacity="1.000000"
          stroke="none"
          d="
M280.287231,2426.794678 
	C279.534546,2422.668457 277.230530,2421.983398 274.376587,2422.030273 
	C272.434082,2422.062256 270.486389,2422.095215 268.547974,2421.993896 
	C262.498016,2421.678467 262.499298,2421.653809 265.854309,2414.701660 
	C268.599091,2414.082275 271.402710,2413.544678 273.715942,2415.174561 
	C279.402496,2419.181152 284.574799,2416.690186 289.175323,2413.500488 
	C292.165283,2411.427490 294.523773,2410.152588 298.162262,2411.599121 
	C300.242004,2412.425781 302.953674,2412.558594 304.821838,2410.839111 
	C314.418701,2402.005371 326.203186,2401.688477 339.090210,2402.009521 
	C340.730072,2401.930908 341.340454,2401.620605 341.914795,2400.515381 
	C340.721283,2397.165039 338.463135,2395.928467 334.718811,2396.000000 
	C337.023560,2393.434082 340.896057,2392.342285 343.930817,2389.645264 
	C346.003113,2387.803955 349.417389,2386.346436 352.111481,2386.525391 
	C363.732727,2387.297852 374.885193,2383.819824 386.366333,2383.116699 
	C396.903564,2382.471191 407.136810,2383.149902 417.502808,2383.878174 
	C421.072235,2384.129150 424.544281,2384.208496 422.696350,2389.459717 
	C422.512238,2389.982910 422.760162,2390.819336 423.086761,2391.333008 
	C424.608521,2393.727295 427.113556,2395.499268 429.645905,2395.020752 
	C432.759460,2394.432373 432.756226,2391.250488 432.244141,2388.627197 
	C432.014313,2387.449707 430.577850,2386.531006 432.041656,2384.092285 
	C442.106720,2384.984375 449.446838,2390.900635 455.925079,2399.239502 
	C451.759857,2404.683350 445.961700,2403.382324 440.969208,2402.822754 
	C424.159271,2400.939941 407.134338,2403.483887 392.712463,2400.994873 
	C405.001495,2404.934082 420.085968,2402.259033 434.900909,2402.821777 
	C478.419800,2404.474121 521.970337,2403.249756 565.508545,2403.032715 
	C569.623413,2403.012207 573.463684,2402.280762 577.208252,2398.743896 
	C585.673889,2397.568604 593.539856,2399.965820 601.397400,2397.973633 
	C603.011475,2397.564209 604.973145,2397.558350 604.851868,2395.376221 
	C604.750610,2393.553223 603.137390,2392.528320 601.439209,2392.333252 
	C598.813538,2392.031494 596.150391,2392.054932 592.663452,2391.905273 
	C583.978027,2388.042969 575.418274,2390.384766 567.265564,2389.076904 
	C563.160339,2388.418213 558.756409,2389.659912 554.446655,2386.463867 
	C554.307495,2383.246826 554.665833,2380.654541 555.113403,2377.416504 
	C550.319641,2374.825684 543.648499,2374.967773 540.106384,2368.788574 
	C549.524414,2360.013672 561.147766,2362.788574 572.915527,2362.395508 
	C576.328918,2364.664551 577.218140,2367.488525 578.021545,2371.278809 
	C577.573242,2376.479736 578.644897,2380.025879 583.823181,2378.424316 
	C590.565979,2376.338867 596.872070,2378.281250 603.414673,2378.934570 
	C624.537354,2381.042969 645.425781,2380.892578 666.732056,2377.970947 
	C683.591736,2375.658691 700.566528,2371.346436 717.945374,2372.929443 
	C720.791138,2373.188721 723.995972,2373.720459 726.785034,2370.470703 
	C732.654175,2363.447021 739.638855,2364.369873 746.257690,2366.626465 
	C754.032288,2369.276855 761.688171,2369.293213 770.352051,2367.902344 
	C768.489319,2364.057617 767.501709,2360.148193 763.789124,2356.793945 
	C762.770752,2354.663818 762.933716,2353.207764 764.388916,2351.282959 
	C765.892151,2350.186768 766.875916,2349.784180 768.713989,2349.414795 
	C770.565918,2349.229248 771.625244,2349.201416 773.470337,2349.239258 
	C775.816162,2349.330078 777.301208,2349.718994 779.382263,2350.680664 
	C783.474426,2356.600830 791.935181,2357.020508 793.646423,2364.680664 
	C794.084900,2366.643555 797.357544,2365.264893 798.874451,2364.497559 
	C806.492554,2360.643799 812.826233,2361.927002 818.526672,2370.142578 
	C821.008057,2363.380859 824.451416,2359.107666 831.177307,2358.729980 
	C834.927917,2358.519043 836.738159,2355.544678 838.130066,2351.534912 
	C839.427185,2349.650391 840.649231,2348.812744 842.886719,2348.197266 
	C847.220520,2348.325195 850.713562,2349.180176 855.013184,2349.687012 
	C859.867249,2351.182373 863.740662,2352.870361 865.799927,2357.210938 
	C866.649963,2358.058838 866.665344,2355.804443 865.538940,2356.517822 
	C865.284485,2356.678955 865.144165,2356.411377 864.861877,2355.841797 
	C864.243835,2354.595459 864.190002,2353.313232 864.695374,2351.279297 
	C867.136353,2347.711914 870.108521,2347.366455 874.063904,2348.065674 
	C878.575012,2349.881104 878.790283,2355.186768 883.113464,2356.314453 
	C884.344849,2354.433594 882.043396,2352.714355 883.114502,2350.215088 
	C886.374207,2346.215088 890.025208,2344.427734 895.218750,2345.101318 
	C897.226440,2346.049561 898.613342,2346.829590 900.898560,2346.342773 
	C902.796936,2346.079346 904.061462,2346.225830 905.904053,2346.938965 
	C908.455688,2349.218506 910.161743,2351.418213 912.620239,2353.854980 
	C913.529175,2362.640381 904.066406,2359.160156 901.291504,2364.528076 
	C898.999878,2366.151611 896.994324,2365.907471 895.851868,2366.006348 
	C900.770874,2368.647217 911.043701,2364.600342 910.179260,2376.714355 
	C910.057556,2378.420898 912.285828,2377.604736 913.405334,2377.855957 
	C917.605774,2378.798828 921.952454,2379.211182 926.291260,2382.017090 
	C928.754333,2384.587646 931.491516,2385.938232 932.863159,2389.393555 
	C930.901611,2395.698242 925.949219,2395.886963 921.513672,2395.225586 
	C915.199707,2394.284180 909.902832,2396.111084 904.015076,2399.380371 
	C895.443481,2400.534912 887.629944,2399.170654 879.822815,2398.485596 
	C875.153503,2398.075684 870.326111,2396.182617 870.735107,2390.188721 
	C871.097534,2384.875977 875.576111,2383.798340 880.003601,2383.116943 
	C882.543884,2382.725586 885.283691,2383.812256 887.547974,2381.528320 
	C884.322205,2377.480713 879.355164,2378.093750 875.570618,2375.596924 
	C872.867859,2373.813965 874.217102,2377.894043 873.113892,2379.748535 
	C863.290527,2393.589355 861.856018,2393.978516 850.004395,2386.557617 
	C847.264343,2387.248291 848.708496,2389.854736 847.873047,2391.414307 
	C844.223267,2398.227295 848.135254,2399.162598 853.715332,2399.100830 
	C857.659790,2399.056885 861.697571,2398.770264 865.950439,2401.181641 
	C866.982849,2402.530029 867.193054,2403.472412 866.967651,2405.182129 
	C865.836792,2407.158447 864.535828,2407.839600 862.395996,2408.506348 
	C859.785217,2408.863525 857.892151,2409.104736 855.338013,2409.677734 
	C854.171021,2409.758545 853.704529,2409.754395 852.550171,2409.657227 
	C849.270813,2408.950684 846.818054,2407.691406 843.377991,2407.937988 
	C841.320251,2408.042969 839.974854,2407.853027 838.076660,2407.033691 
	C834.684753,2402.678711 831.861267,2399.334229 828.059204,2406.761230 
	C822.852844,2409.237549 818.251282,2406.507568 812.921448,2407.020264 
	C810.409363,2406.948730 808.785156,2407.535889 806.708740,2408.847900 
	C805.707336,2409.270020 805.290405,2409.398438 804.288818,2409.602783 
	C802.974548,2409.719727 802.463379,2409.605713 802.170593,2409.336914 
	C802.904419,2410.065186 803.847595,2409.881592 805.461060,2409.867676 
	C813.355957,2406.335205 820.869324,2407.734375 829.119995,2408.543213 
	C831.454773,2409.734863 832.439026,2411.131836 833.638367,2413.433594 
	C834.817932,2418.882324 835.246521,2423.374756 828.791138,2425.513184 
	C820.398376,2421.337158 812.732178,2419.456299 804.512268,2425.731445 
	C793.646729,2430.432129 782.628723,2427.415283 771.888367,2428.823242 
	C760.423706,2430.326172 749.060913,2430.716553 738.028503,2435.345215 
	C723.346741,2441.505127 707.962891,2437.085449 692.871460,2435.992432 
	C691.551208,2435.897217 693.128052,2435.596924 691.918091,2435.833984 
	C676.082947,2438.937988 660.684326,2431.010742 644.732483,2434.280029 
	C635.670837,2436.136719 626.307739,2432.997803 616.343384,2434.006348 
	C615.195557,2433.606934 614.765991,2433.392090 613.818970,2432.701416 
	C612.177429,2429.107422 610.473816,2427.271973 606.352905,2429.607422 
	C603.901489,2430.382324 602.030640,2430.570312 599.420532,2430.784180 
	C598.185974,2430.818848 597.688660,2430.814941 596.435181,2430.798340 
	C595.168030,2430.765137 594.657043,2430.744629 593.372131,2430.664795 
	C591.554749,2430.457031 590.529175,2430.228271 588.767822,2429.639648 
	C581.122925,2425.966064 573.585999,2427.417969 566.594482,2427.698486 
	C553.759033,2428.213867 540.882507,2429.091553 528.042480,2430.288818 
	C513.525330,2431.643066 498.910614,2429.956299 484.721497,2426.311279 
	C478.420959,2424.693359 474.443054,2424.075928 470.596924,2431.611572 
	C466.046997,2440.526123 457.509918,2436.413086 449.515259,2433.435059 
	C421.748749,2431.467529 395.114136,2435.752930 368.345642,2437.524902 
	C366.358154,2437.656738 364.360870,2437.840820 362.373474,2437.734863 
	C359.427582,2437.578125 355.790833,2437.864990 355.343079,2433.901855 
	C354.967285,2430.575928 356.576477,2427.886963 360.689331,2427.614990 
	C357.355804,2425.830322 354.074768,2426.527344 350.949615,2427.547119 
	C332.956726,2433.418213 314.218842,2432.072021 295.756897,2433.224854 
	C290.149078,2433.574951 284.345337,2432.914551 280.287231,2426.794678 
z"
        />
        <path
          fill="#71889C"
          opacity="1.000000"
          stroke="none"
          d="
M412.185699,2242.770020 
	C413.401459,2240.915039 414.319275,2239.303467 414.015228,2237.685059 
	C413.263580,2233.684082 421.698242,2234.141602 418.256348,2230.372559 
	C415.415710,2227.261963 410.348358,2226.136230 406.178680,2224.337158 
	C405.512390,2224.049805 404.086395,2224.581055 403.524231,2225.199707 
	C402.510742,2226.315186 403.211426,2227.516113 403.901123,2228.747070 
	C408.973022,2237.798584 408.944611,2237.911377 398.471161,2237.971924 
	C388.832550,2238.027832 379.446960,2241.136963 369.701294,2240.065674 
	C365.430878,2239.596436 361.058228,2240.341309 356.875549,2238.657715 
	C354.681732,2237.774658 352.311859,2238.039062 349.967316,2238.384766 
	C327.105133,2241.754395 304.126221,2239.429688 281.200073,2239.701416 
	C280.685486,2239.707275 280.162933,2239.041016 278.657532,2238.018066 
	C283.540222,2235.046143 288.316803,2234.048096 293.447357,2233.991699 
	C306.730103,2233.845947 319.881226,2232.312744 332.988373,2230.254883 
	C335.272736,2229.896240 337.769867,2229.565918 339.947144,2230.109619 
	C345.701019,2231.546631 350.609497,2229.773926 356.036133,2227.990967 
	C363.988190,2225.378174 372.633392,2227.191895 381.922424,2226.435059 
	C378.792389,2222.031250 380.598053,2216.104248 375.276917,2212.607666 
	C373.281555,2211.296631 374.650177,2208.945068 377.031281,2208.325928 
	C379.346558,2207.724121 381.769501,2207.839844 383.783386,2209.219238 
	C389.889496,2213.401123 397.234772,2212.117188 403.880920,2213.897949 
	C405.386597,2214.301270 407.190826,2214.113525 409.007660,2212.239258 
	C407.291626,2209.010498 402.176331,2208.745850 401.331940,2204.307129 
	C400.814575,2201.587402 397.610992,2202.054199 395.327332,2202.031738 
	C389.328217,2201.972656 383.327271,2201.937500 377.328705,2202.016846 
	C369.993835,2202.114014 362.106110,2200.149170 356.774536,2207.828857 
	C356.041382,2208.884766 353.778473,2208.878906 351.432007,2209.611816 
	C351.921387,2205.826904 354.310974,2200.997314 347.534943,2201.935059 
	C346.275604,2202.109375 344.888336,2201.724609 343.620972,2201.376465 
	C342.253754,2201.000977 341.172180,2199.916504 341.140106,2198.560303 
	C341.105896,2197.114258 342.256470,2196.240723 343.768768,2196.188232 
	C346.431030,2196.096680 349.095215,2196.013428 351.758636,2196.011475 
	C372.757538,2195.996094 393.756866,2195.927490 414.755035,2196.051758 
	C419.981537,2196.082764 424.670288,2195.716553 427.788269,2190.662598 
	C430.156677,2186.823486 432.564056,2188.446045 434.936249,2191.000000 
	C437.118134,2193.348877 439.537689,2195.513428 435.141144,2197.927490 
	C433.494781,2198.831299 431.979248,2200.445801 434.144409,2201.402100 
	C438.660339,2203.396729 438.880249,2209.552002 444.820190,2210.933105 
	C447.326569,2198.874756 459.784119,2202.104004 467.196045,2196.879395 
	C468.555328,2195.921143 470.392487,2197.344727 471.535919,2198.603760 
	C475.377136,2202.833008 479.509613,2202.245117 484.200745,2199.997559 
	C489.116028,2197.642578 495.079742,2197.399902 496.693237,2190.220215 
	C497.482605,2186.707764 501.092896,2189.413330 503.545013,2189.482178 
	C508.232391,2189.613770 510.247833,2196.381836 514.697205,2194.580566 
	C518.029785,2193.231445 518.394836,2187.576172 522.981995,2186.988037 
	C521.808350,2183.035645 519.050659,2184.088623 516.993347,2184.064209 
	C506.661987,2183.940674 496.328278,2184.030029 485.995758,2183.977295 
	C483.716278,2183.965576 481.069977,2184.651611 479.345490,2182.749756 
	C477.097046,2180.269775 480.094452,2178.735596 480.806427,2176.729248 
	C481.625671,2174.421143 479.602081,2171.957520 477.919617,2172.908691 
	C472.122711,2176.185791 466.316711,2174.176270 460.495911,2174.085693 
	C458.060150,2174.047852 455.535034,2174.662354 455.002319,2177.375244 
	C454.422485,2180.328369 456.757111,2181.498779 459.200317,2181.850342 
	C462.992188,2182.395996 464.498718,2184.417725 463.568451,2188.054199 
	C462.675446,2191.545166 460.797241,2193.750732 456.692657,2194.051758 
	C451.339783,2194.444092 448.122528,2190.734619 444.513367,2188.172607 
	C437.818542,2183.419922 430.887390,2184.067627 423.865631,2186.247559 
	C419.819672,2187.503906 416.189148,2190.075684 412.157684,2191.407715 
	C408.347961,2192.666260 403.914001,2192.912109 400.346802,2191.470215 
	C396.244385,2189.812012 392.567535,2188.368408 388.145203,2189.751465 
	C386.929840,2190.131348 385.481842,2189.514893 385.124084,2187.933350 
	C384.757355,2186.312256 385.955231,2185.184082 387.110077,2184.882324 
	C389.325409,2184.303223 391.676514,2184.123779 393.981232,2184.034424 
	C397.315399,2183.905029 399.842529,2183.419189 402.619781,2180.547363 
	C407.522858,2175.477051 414.452545,2178.023438 420.507904,2178.680664 
	C423.540039,2179.009521 425.765320,2179.517578 428.748749,2177.535889 
	C435.912964,2172.776855 436.135101,2173.111328 433.643768,2164.916504 
	C437.781311,2162.857910 441.291901,2164.979736 445.021271,2166.394775 
	C450.026031,2168.293457 455.084015,2170.261230 458.815002,2163.759766 
	C460.347565,2161.088867 462.775604,2162.653809 464.866425,2163.955566 
	C473.070251,2169.064453 480.380493,2167.720703 486.951874,2159.651611 
	C484.148682,2157.044922 480.674683,2158.079834 477.599304,2158.038330 
	C473.111053,2157.977295 471.826141,2155.557129 472.007904,2150.818848 
	C483.589020,2142.843994 491.132965,2143.791504 499.977386,2153.783447 
	C502.806061,2156.979492 505.835480,2155.916992 509.222534,2155.016113 
	C508.320984,2152.013184 505.043182,2149.531494 508.558075,2144.495117 
	C500.244812,2149.955811 493.426086,2147.006592 486.679291,2144.776611 
	C484.460144,2144.042725 482.596710,2141.972900 483.329926,2139.324951 
	C484.098511,2136.549316 486.394775,2136.158203 489.467102,2137.540527 
	C490.885620,2137.992188 491.778564,2137.994141 493.341187,2137.993164 
	C494.340332,2137.662842 494.669800,2137.335205 495.490509,2136.513672 
	C497.136475,2129.324463 502.320404,2128.241211 507.700653,2128.083740 
	C516.507080,2127.826172 525.326233,2128.003418 535.076111,2127.993652 
	C542.900024,2122.813477 550.743469,2125.368408 558.222290,2125.407959 
	C561.649170,2125.426025 564.896912,2126.755859 565.631409,2130.812012 
	C566.417297,2135.152832 564.015991,2137.779541 560.383789,2139.423096 
	C554.245361,2142.200439 547.441711,2142.526611 541.082642,2144.460205 
	C536.837280,2145.751221 529.733032,2145.826416 530.307068,2151.163086 
	C530.894409,2156.623047 537.746887,2156.705078 542.780579,2156.916748 
	C546.773987,2157.084717 550.773560,2156.979248 554.768799,2156.725098 
	C569.916870,2155.760498 572.542786,2157.385986 578.771057,2171.050537 
	C579.878174,2173.479492 581.490417,2175.871582 584.325806,2176.423584 
	C592.251404,2177.966309 598.179138,2188.071289 608.852539,2179.550293 
	C613.139526,2176.127686 622.467590,2176.624512 629.496704,2174.733398 
	C629.323669,2171.855713 627.778992,2170.161621 626.861572,2167.439941 
	C626.707764,2165.727295 626.947937,2164.773682 627.921997,2163.308105 
	C632.536621,2160.614990 636.642212,2160.829590 641.420166,2162.967285 
	C649.012695,2169.014648 652.736511,2175.261963 645.580383,2184.120605 
	C641.855408,2187.666016 637.755310,2188.473145 633.346130,2189.097900 
	C637.353943,2189.615967 641.557495,2188.728027 644.674988,2192.965332 
	C645.147705,2195.528809 644.615845,2197.283691 643.459351,2199.529785 
	C643.858582,2204.697510 647.839111,2208.815918 644.565186,2213.699707 
	C648.372009,2215.564697 651.728271,2213.640869 655.598022,2214.838135 
	C658.627136,2216.429932 660.267639,2218.387695 661.631348,2221.569824 
	C662.090759,2224.638184 661.683838,2226.683105 658.818359,2228.047363 
	C647.825317,2223.526367 637.210388,2225.948975 626.748962,2226.051514 
	C625.902405,2226.059814 624.806641,2226.390625 624.257080,2226.975830 
	C618.858948,2232.722900 618.884827,2232.747314 608.412048,2230.451660 
	C612.290222,2226.907715 616.332764,2224.204346 618.744751,2220.075928 
	C614.562927,2218.329590 594.231812,2217.065186 590.741638,2218.390381 
	C589.432312,2218.887451 588.547485,2219.794678 588.715210,2221.366211 
	C588.891113,2223.013916 590.152283,2223.604004 591.457825,2223.766846 
	C595.613464,2224.285156 601.825134,2223.599365 600.555298,2229.714600 
	C599.646912,2234.089355 593.648682,2231.800781 589.961731,2231.900391 
	C579.643005,2232.179199 569.310852,2232.061768 558.985474,2231.956543 
	C550.122192,2231.866211 549.444153,2231.001953 550.359253,2221.993164 
	C550.425415,2221.341797 550.759888,2220.722168 550.901062,2220.072998 
	C551.673828,2216.520508 549.685974,2215.774414 546.977844,2216.760010 
	C542.971497,2218.218262 539.336609,2220.792236 534.646423,2218.984375 
	C533.276917,2218.456787 532.372620,2220.120850 532.285706,2221.515137 
	C532.177979,2223.242432 532.874573,2224.465820 534.458374,2225.592285 
	C539.718323,2229.333496 539.624207,2229.404785 533.433472,2232.165283 
	C529.847351,2233.764404 526.722412,2236.324219 522.724426,2237.127441 
	C522.041931,2237.264893 521.170166,2238.628906 521.154846,2239.440674 
	C521.127502,2240.889404 522.227234,2241.769287 523.753845,2241.813477 
	C526.747864,2241.899902 529.744324,2241.912598 532.737427,2242.019287 
	C535.026733,2242.101074 537.671509,2241.518555 539.261719,2243.647217 
	C542.112366,2247.462891 544.585022,2248.131348 547.704224,2243.854980 
	C549.191345,2241.816406 551.947815,2241.526123 554.145996,2242.490479 
	C558.957092,2244.601074 563.070740,2243.746826 567.237061,2240.838867 
	C568.450745,2239.991943 570.452332,2239.978027 572.071228,2240.052246 
	C591.041077,2240.919678 609.895447,2238.468506 628.805664,2238.168701 
	C641.602539,2237.965820 654.424805,2239.369141 667.235718,2240.053467 
	C668.911987,2240.143066 670.549255,2240.335938 671.896362,2241.502686 
	C673.272156,2242.694092 673.969666,2244.207275 673.315063,2245.915527 
	C672.807373,2247.240234 671.553101,2247.802979 670.068542,2247.557373 
	C669.742615,2247.503418 669.420166,2247.428711 669.095154,2247.368896 
	C667.022888,2246.987549 664.746521,2244.765625 663.074829,2247.933594 
	C662.837891,2248.382568 663.014282,2249.389404 663.381104,2249.761230 
	C665.509521,2251.916992 668.381958,2254.662598 671.088684,2252.906250 
	C678.980286,2247.785645 682.247009,2255.024658 687.023254,2258.161621 
	C688.395996,2259.063477 689.454529,2260.701416 687.361633,2262.126709 
	C685.985229,2263.064209 683.824036,2264.119873 682.942383,2262.440430 
	C679.275085,2255.455078 674.306030,2257.922363 669.442200,2260.298584 
	C662.545959,2263.667480 654.902710,2260.761719 647.829102,2263.120605 
	C646.050598,2263.713623 644.532776,2261.334473 644.770874,2258.940186 
	C645.251953,2254.102295 648.902832,2252.059814 653.520508,2250.392334 
	C645.476990,2247.231445 626.943176,2244.998291 621.873230,2246.456055 
	C620.020691,2246.988770 617.507568,2247.467529 617.372681,2249.733154 
	C617.191223,2252.781006 620.199219,2253.009766 622.291321,2253.654785 
	C625.718506,2254.711914 628.692322,2252.155273 632.018677,2252.107178 
	C633.664429,2252.083496 635.574707,2251.778076 636.573425,2253.325928 
	C637.735779,2255.127197 636.292908,2256.440918 634.961914,2257.443115 
	C631.871887,2259.769531 632.194824,2262.223389 635.495850,2263.343018 
	C638.694641,2264.427979 640.192871,2266.851807 642.165955,2269.029541 
	C643.841675,2270.878906 645.770752,2271.964111 648.352783,2270.845215 
	C654.117493,2268.346436 660.901733,2273.621094 666.410156,2268.522461 
	C666.712769,2268.242188 668.302673,2268.902832 668.915955,2269.508057 
	C669.980957,2270.558838 669.700317,2271.973145 669.011475,2273.169189 
	C667.679993,2275.481201 665.410767,2275.887695 662.994080,2275.963379 
	C657.362366,2276.139160 651.614563,2277.025391 646.174561,2276.855469 
	C640.843445,2276.688721 636.513123,2277.556152 632.087341,2279.936279 
	C628.131287,2282.063965 624.016296,2282.856201 619.780762,2281.141602 
	C619.278809,2279.975830 619.364868,2278.984375 620.387939,2278.538574 
	C622.674805,2277.541992 626.209167,2277.754639 625.409119,2273.781738 
	C624.783936,2270.677490 623.157288,2268.014160 619.480347,2268.126221 
	C613.610168,2268.304932 609.763916,2266.583008 609.800659,2259.817383 
	C609.818054,2256.613037 607.105835,2255.448242 604.516235,2254.608887 
	C603.275085,2254.206299 601.869690,2253.923584 600.594666,2254.071289 
	C585.524841,2255.814697 570.269836,2252.404053 555.257385,2255.739502 
	C552.224548,2256.413330 549.397156,2255.344238 546.487732,2254.496094 
	C541.724976,2253.107422 536.809998,2252.250000 532.000671,2251.007812 
	C529.229248,2250.292236 527.331543,2251.150635 526.561890,2253.791260 
	C525.867920,2256.171875 527.346069,2257.384766 529.513123,2258.126709 
	C531.069458,2258.659424 532.622314,2259.389404 533.939148,2260.355225 
	C534.577332,2260.823242 535.175232,2262.613037 534.934326,2262.823486 
	C530.783508,2266.449219 529.594604,2273.770020 522.307678,2273.615479 
	C520.646301,2271.114746 522.341553,2269.570312 523.279846,2267.984131 
	C524.441162,2266.020996 526.698242,2264.205566 525.075439,2261.766113 
	C523.288147,2259.079102 520.282593,2259.886230 517.766357,2260.472412 
	C515.462463,2261.009277 513.327087,2261.801514 511.050385,2260.466309 
	C509.064270,2259.301270 506.497009,2258.531006 506.898224,2255.562500 
	C507.210693,2253.250732 509.166962,2252.489990 511.234985,2252.145264 
	C513.123962,2251.830322 515.244141,2252.279541 517.330444,2249.832031 
	C511.149384,2246.807129 504.758392,2246.359131 497.517517,2245.502441 
	C500.694427,2242.988037 503.701019,2242.387451 506.790161,2241.867432 
	C509.719025,2241.374512 511.825623,2239.831543 511.553009,2236.558594 
	C511.327881,2233.855957 509.376404,2232.242432 506.850922,2232.270508 
	C502.272217,2232.321777 498.496124,2230.870850 495.136169,2227.819336 
	C494.214355,2226.981934 492.730255,2226.353760 491.494476,2226.336914 
	C481.227661,2226.196045 470.934814,2224.843262 460.752319,2227.804932 
	C456.448303,2229.056885 454.777039,2231.318848 457.216278,2234.887939 
	C460.397369,2239.541992 456.308136,2247.571533 465.002167,2248.507568 
	C462.995239,2252.390625 460.313263,2251.660889 458.187653,2252.306152 
	C455.620422,2253.085205 452.292786,2253.825195 451.502014,2256.229248 
	C449.662231,2261.822266 444.948944,2262.363770 440.615631,2263.336914 
	C436.131165,2264.343750 431.466034,2265.123291 427.381104,2261.502686 
	C424.634521,2259.068359 420.966095,2260.410156 417.755310,2259.809082 
	C416.875397,2259.644043 415.810028,2259.148926 415.328796,2258.457764 
	C414.399170,2257.122314 415.374817,2255.881104 416.271027,2254.943115 
	C420.143616,2250.890381 424.701202,2251.290771 429.077362,2253.670410 
	C433.339264,2255.988037 437.216309,2256.209717 441.138397,2253.249268 
	C442.813782,2251.984619 444.213074,2250.456543 443.307709,2248.224365 
	C442.440002,2246.084961 440.666107,2247.064697 439.062042,2247.326172 
	C429.783905,2248.838135 420.949738,2247.087402 412.185699,2242.770020 
z"
        />
        <path
          fill="#728BA1"
          opacity="1.000000"
          stroke="none"
          d="
M658.097534,2228.713379 
	C659.683350,2226.886230 659.977417,2225.178467 659.993774,2222.679688 
	C666.970032,2221.137207 672.197876,2217.885986 677.569580,2212.986328 
	C685.138794,2206.082031 695.962219,2204.345215 705.752991,2202.465088 
	C711.931030,2201.278809 718.718506,2201.049072 722.740723,2201.019531 
	C714.584534,2202.113770 703.600952,2200.680176 693.363403,2205.786865 
	C689.626221,2207.650879 684.318359,2207.742920 681.549438,2203.557617 
	C678.569397,2199.052734 680.328674,2194.550537 684.471191,2191.390625 
	C685.739075,2190.423584 687.309631,2191.376953 684.549500,2189.778809 
	C677.607178,2185.758545 681.783325,2183.078125 686.429077,2181.380127 
	C691.424866,2179.554443 696.857422,2179.428467 701.987366,2178.068848 
	C707.687622,2176.557861 712.540649,2174.084473 712.328979,2166.472900 
	C709.526978,2161.411865 706.224304,2161.954346 702.009888,2164.721191 
	C697.810547,2166.014648 694.198303,2164.383057 690.424866,2165.050293 
	C692.854248,2167.877686 698.467163,2168.651611 696.524597,2173.869873 
	C694.872375,2178.307861 690.468506,2178.603027 686.474487,2178.924316 
	C679.235474,2179.506592 672.098450,2181.979980 664.736389,2180.302002 
	C661.442505,2179.551514 657.798401,2178.472168 657.387085,2174.616211 
	C656.896362,2170.017334 661.098999,2169.272949 664.208130,2169.178711 
	C672.404724,2168.929932 672.090027,2164.344482 670.339478,2158.023438 
	C670.440430,2156.665771 670.899475,2155.584473 669.804016,2155.456543 
	C668.974060,2155.358887 668.596436,2156.419678 667.917969,2157.722412 
	C665.115601,2162.428223 661.966858,2162.198486 657.982727,2159.245850 
	C656.171204,2157.741211 654.699707,2157.367676 652.371216,2158.077148 
	C646.445007,2159.074219 641.702820,2157.963135 637.122253,2153.869141 
	C635.379639,2149.360107 632.405518,2148.750732 628.777100,2148.958496 
	C622.482239,2149.319336 616.153564,2148.992676 609.881592,2149.888672 
	C605.705261,2150.485352 601.907532,2149.419189 598.671936,2146.804688 
	C594.932922,2143.783447 591.304504,2140.023193 593.454651,2134.979492 
	C595.392700,2130.433594 600.219238,2131.889404 604.132263,2132.250977 
	C612.696838,2133.042725 621.441833,2132.804688 629.351196,2137.152832 
	C631.750854,2138.471924 634.703186,2138.767578 637.096313,2137.511475 
	C653.163513,2129.077148 670.097656,2132.238281 685.271851,2133.042480 
	C676.469604,2127.188477 665.552185,2133.490479 654.845581,2132.344238 
	C644.148804,2131.198975 642.647095,2129.555420 647.999817,2120.710205 
	C652.474243,2113.315918 654.178650,2104.588379 659.690308,2096.927734 
	C663.476074,2091.171387 673.775391,2092.697510 672.572876,2082.616699 
	C669.785706,2077.254150 676.534058,2075.083740 675.133545,2070.199707 
	C674.238464,2066.601807 675.597717,2064.593018 678.734863,2063.008545 
	C680.058411,2062.699951 681.033081,2063.276611 681.432129,2062.894775 
	C682.379272,2061.988281 681.758850,2063.277588 680.529175,2061.770508 
	C680.255981,2052.617920 686.404663,2050.574463 692.956177,2050.509277 
	C698.892578,2050.450439 700.404114,2055.165039 699.910461,2060.420166 
	C699.664246,2063.040283 700.781982,2064.576904 702.827820,2066.200439 
	C710.483887,2072.276611 710.514771,2074.158203 703.916626,2081.593262 
	C701.464355,2084.356934 698.835876,2086.861572 693.192505,2088.519287 
	C699.322937,2087.803223 702.628784,2089.719482 705.614136,2092.322021 
	C709.494812,2095.704834 711.765930,2099.641846 708.090088,2104.398682 
	C705.531189,2107.709717 700.781067,2109.230713 699.752075,2116.048584 
	C710.554138,2110.955078 721.483643,2114.550293 732.731201,2111.763916 
	C737.258179,2111.513916 740.858093,2112.063965 742.467041,2116.013428 
	C743.969971,2119.702637 741.275208,2121.280029 737.738525,2122.812500 
	C731.264038,2123.909912 726.666321,2129.799805 719.609314,2126.197754 
	C720.039734,2131.978027 725.396851,2136.380615 722.292603,2141.614502 
	C720.181702,2145.173340 716.010559,2144.696045 711.677246,2144.911377 
	C706.122131,2145.625000 701.223511,2144.669434 695.724609,2145.721680 
	C691.935242,2146.697266 688.770203,2146.924316 687.489502,2146.855957 
	C690.792358,2146.331543 695.661499,2150.083984 701.708679,2147.829102 
	C705.303589,2146.914795 708.228638,2146.281006 711.914612,2145.463379 
	C717.490662,2144.876709 722.274841,2144.615967 727.453979,2147.199707 
	C732.388855,2150.958740 734.806030,2154.930176 731.510620,2160.415283 
	C735.165833,2161.035156 738.082703,2159.742920 741.061340,2160.203369 
	C743.499451,2160.580078 745.501099,2161.654297 746.501770,2163.983398 
	C747.613098,2166.570312 746.410339,2168.578857 744.296021,2169.901611 
	C739.015625,2173.205566 739.058899,2176.963135 742.980774,2181.276855 
	C747.816040,2186.595215 749.201355,2192.758301 747.497742,2199.709717 
	C746.605103,2203.351807 746.966858,2206.720215 751.627136,2206.855469 
	C757.053955,2207.012695 754.508972,2203.000977 754.457336,2200.435547 
	C754.381348,2196.657471 753.966553,2192.718018 760.402710,2193.622070 
	C763.568176,2197.328125 767.200134,2197.048828 770.796936,2197.016846 
	C779.581665,2196.938965 788.369141,2196.813477 797.944031,2197.423340 
	C802.114624,2197.231934 805.465027,2197.193115 808.635498,2200.393555 
	C810.317139,2204.801514 808.321716,2207.377197 805.445740,2210.346680 
	C800.664062,2213.325928 796.045593,2212.982422 791.452393,2212.877930 
	C795.801147,2213.380371 800.323608,2212.417480 805.146362,2214.673828 
	C806.053894,2215.472168 806.343445,2215.874023 806.681152,2217.024902 
	C806.259521,2219.263916 805.270508,2220.364746 803.466858,2221.712891 
	C795.097229,2234.195557 783.431763,2232.742920 771.691040,2231.158936 
	C767.732788,2230.625244 763.706421,2231.313721 760.906677,2230.908936 
	C767.354126,2230.404053 774.847229,2232.127441 782.458618,2232.011963 
	C793.861023,2231.838867 794.604126,2232.989014 790.186646,2243.621338 
	C794.891296,2246.254639 801.976990,2243.864990 804.951538,2251.150635 
	C804.883728,2253.510010 804.017029,2254.726562 802.107300,2256.140381 
	C797.572083,2258.528320 793.260925,2258.809570 788.354614,2260.006348 
	C785.344910,2260.455566 783.214478,2260.268555 780.643433,2258.500977 
	C780.471375,2254.014893 778.120972,2252.140137 774.931580,2250.384033 
	C764.687561,2244.743164 754.881042,2245.158203 744.089233,2252.302979 
	C749.330139,2254.440430 753.285706,2254.791260 757.693542,2252.333740 
	C762.364685,2249.729248 767.037476,2250.606201 771.308350,2253.718262 
	C772.381714,2254.500488 773.846741,2255.299072 773.516968,2256.762207 
	C772.542480,2261.086914 768.324463,2262.498291 765.361511,2264.889893 
	C763.278076,2266.571533 760.127319,2266.776123 758.480225,2263.747314 
	C757.309448,2261.594727 755.992188,2259.871094 753.696655,2258.967041 
	C751.687500,2258.176025 749.282959,2257.848389 748.563110,2260.143555 
	C746.304932,2267.343506 740.448181,2266.730713 735.466980,2265.640625 
	C729.521912,2264.339111 723.655396,2264.062988 717.681641,2263.968506 
	C715.743469,2263.937744 713.128174,2264.099365 713.181458,2261.384277 
	C713.225403,2259.142578 715.481140,2258.410156 717.572021,2258.126953 
	C723.144531,2257.372314 728.719666,2256.635254 734.284912,2255.830566 
	C736.038025,2255.577148 737.480469,2254.816650 737.333801,2252.688721 
	C737.222595,2251.074951 735.965698,2250.282959 734.653564,2250.247070 
	C729.763367,2250.113525 725.744629,2248.030029 721.830627,2245.326904 
	C718.895386,2243.299561 715.160095,2243.285645 712.194580,2244.502197 
	C706.432373,2246.865967 701.114807,2245.274414 694.826355,2244.008057 
	C693.160767,2242.840820 693.394714,2241.599609 693.805542,2239.759277 
	C694.532166,2237.254150 693.221191,2236.251221 691.609192,2234.712891 
	C688.457642,2228.789307 684.113892,2235.670654 680.520996,2232.452148 
	C675.406189,2228.825684 670.980530,2229.365723 666.480225,2232.353760 
	C662.587463,2234.938721 659.730896,2233.874512 658.097534,2228.713379 
z"
        />
        <path
          fill="#61798F"
          opacity="1.000000"
          stroke="none"
          d="
M844.650269,2349.646973 
	C842.861267,2350.367676 841.775635,2350.725586 840.341309,2351.551270 
	C833.895447,2357.465576 827.469177,2358.841309 820.109009,2352.943604 
	C822.065002,2347.376465 825.620422,2342.708740 823.223145,2336.154541 
	C825.162292,2333.032715 829.297913,2334.276855 830.315552,2330.385742 
	C827.426086,2325.573486 823.102539,2327.193848 819.357727,2327.039795 
	C810.541260,2326.677002 801.545593,2327.969727 792.631836,2323.397461 
	C789.454407,2318.834717 792.105530,2317.015869 795.412354,2315.656250 
	C798.066711,2314.565186 800.956116,2314.129150 805.176270,2312.662109 
	C797.576172,2313.220459 791.633606,2315.891602 784.859741,2313.118408 
	C778.633057,2309.291992 772.508423,2311.462646 766.662781,2311.092041 
	C762.701111,2310.841064 758.664978,2311.557861 754.524292,2308.556641 
	C751.937561,2302.711914 754.080688,2300.064209 759.307434,2300.093994 
	C767.269104,2300.139648 775.269043,2299.251953 783.190186,2300.704590 
	C784.842407,2301.007324 786.470276,2301.617676 787.420532,2299.568359 
	C788.028564,2298.257080 787.886536,2296.843750 787.125244,2295.757812 
	C786.028809,2294.193604 784.344116,2294.553467 782.633240,2294.733154 
	C760.110168,2297.098633 737.539490,2296.325928 715.002563,2295.203369 
	C708.756226,2294.891846 703.988831,2298.819580 698.013489,2299.226562 
	C693.727051,2299.518311 689.474304,2300.128174 685.172058,2299.693604 
	C684.214050,2299.596924 683.102417,2299.036377 683.217285,2297.856689 
	C683.302490,2296.981445 683.716003,2295.815674 684.380005,2295.368164 
	C692.872253,2289.647705 701.661133,2285.310059 712.371216,2289.070068 
	C717.504395,2290.871826 722.935547,2289.725342 729.139160,2289.960693 
	C738.100647,2287.506104 746.119568,2284.374512 755.260132,2289.418945 
	C758.260620,2291.202148 760.500244,2292.210205 763.476685,2290.196777 
	C769.825989,2285.088135 776.719910,2286.733643 784.187866,2286.773438 
	C788.977417,2286.843018 792.902893,2286.249756 797.593018,2286.988037 
	C803.122375,2287.654541 807.812439,2285.809326 812.594421,2286.241943 
	C808.602661,2285.333984 804.432678,2287.880615 799.828003,2285.699219 
	C795.712097,2281.156006 799.320679,2274.730225 793.376282,2270.444092 
	C790.243835,2268.185547 794.820679,2263.421143 798.717590,2263.548584 
	C812.712097,2264.005615 826.325806,2259.187744 840.278442,2260.647461 
	C848.471985,2261.504639 856.899963,2258.428223 865.494507,2262.616211 
	C874.748108,2278.122803 888.691711,2284.165527 905.776184,2280.207764 
	C910.701355,2279.066895 915.712158,2278.100586 920.007690,2281.806885 
	C921.624878,2283.202148 921.903687,2285.499268 920.743286,2287.316162 
	C917.656494,2292.149902 912.527405,2292.712158 907.515320,2292.658936 
	C898.886475,2292.566650 890.271362,2292.443359 881.642090,2291.433105 
	C869.986145,2290.068848 868.312073,2291.479492 864.558655,2302.845215 
	C863.720459,2305.383057 862.022095,2307.527344 861.332153,2310.986816 
	C866.551880,2312.256592 870.029785,2310.758301 872.164795,2305.766846 
	C873.740479,2302.082764 876.375671,2299.807617 880.670410,2300.257568 
	C884.851501,2300.695557 885.459595,2304.133789 886.740417,2307.237305 
	C891.682129,2319.210938 891.753296,2319.181641 884.996704,2323.834473 
	C891.848328,2325.490234 899.059204,2325.080566 906.080933,2328.735596 
	C908.003784,2330.155518 908.664246,2331.824219 910.087708,2332.645264 
	C917.201599,2336.748291 916.071777,2340.300537 908.686707,2343.819336 
	C886.911499,2343.904541 866.260193,2348.129150 844.650269,2349.646973 
z"
        />
        <path
          fill="#71889C"
          opacity="1.000000"
          stroke="none"
          d="
M224.878357,2265.993652 
	C225.341553,2257.886475 225.268219,2257.542725 218.227722,2259.044189 
	C213.768951,2259.995117 210.980011,2259.366943 210.180069,2254.609375 
	C209.387024,2249.892822 211.801956,2248.470459 216.273560,2247.715332 
	C221.524414,2246.828125 226.696579,2245.489502 232.033249,2246.042725 
	C240.107635,2246.880371 247.713989,2243.162354 255.762207,2243.855225 
	C257.509460,2244.005371 259.282379,2243.104248 259.322144,2241.170410 
	C259.376129,2238.542969 257.174866,2238.040039 255.113922,2238.112305 
	C252.816055,2238.192139 250.518509,2238.565186 248.242996,2238.944092 
	C242.936798,2239.827148 242.942169,2239.859375 243.920853,2232.693115 
	C245.514801,2229.406250 248.312607,2229.189941 251.390854,2228.156250 
	C258.884369,2227.559570 265.805359,2228.903809 272.634796,2226.905029 
	C275.256592,2226.137451 277.693115,2227.157471 279.022888,2230.708740 
	C274.122406,2233.651123 268.014740,2230.375488 262.828369,2233.435059 
	C264.745758,2237.145752 268.708740,2235.010742 270.677155,2237.255615 
	C271.784760,2240.474121 266.737091,2239.446777 267.126862,2242.055420 
	C267.508118,2244.607422 271.354523,2243.875000 271.880249,2247.226562 
	C270.859894,2250.227051 268.419312,2250.516602 267.305450,2250.978760 
	C287.272552,2251.597900 308.178467,2251.156006 329.752533,2249.994141 
	C333.641235,2249.935303 336.712891,2249.503662 339.517639,2246.490234 
	C343.138245,2242.443848 344.690460,2246.537354 347.455750,2247.731934 
	C347.963745,2251.364990 348.809662,2253.989746 352.893188,2253.941895 
	C356.213135,2253.902832 359.726440,2254.547363 362.817596,2253.684326 
	C373.316162,2250.752197 383.960510,2252.295166 394.528564,2252.090820 
	C401.761078,2251.950928 405.735382,2258.342285 405.072815,2265.806396 
	C404.796204,2268.922852 403.833954,2271.861084 403.025543,2275.230225 
	C411.700073,2276.952148 420.191711,2274.764893 428.108032,2277.854248 
	C429.887268,2278.548828 432.297913,2277.981689 433.517731,2279.831055 
	C436.093719,2283.736816 439.864777,2283.975830 444.007599,2284.061768 
	C454.960510,2284.288330 460.342682,2289.354492 462.034729,2301.387451 
	C460.082245,2304.057129 457.822113,2304.207031 454.697052,2302.744141 
	C453.825867,2300.344727 453.327576,2298.740479 450.625549,2298.108154 
	C444.438934,2294.150879 439.695862,2289.075684 432.521179,2286.488281 
	C427.033936,2285.371338 422.361420,2287.010742 416.790527,2287.992432 
	C412.589264,2290.583008 408.844421,2289.303955 405.476257,2288.435791 
	C388.539185,2284.070068 371.608276,2284.223877 354.344208,2286.487061 
	C345.298096,2287.673340 335.851593,2288.138672 326.726990,2285.672852 
	C322.343781,2284.487793 318.225403,2286.209961 313.984558,2286.573486 
	C309.709167,2286.939941 305.393158,2287.725830 300.738586,2284.598633 
	C296.759949,2283.897217 294.837494,2286.811279 292.009857,2287.599854 
	C287.624329,2288.822266 279.076599,2285.184326 276.740326,2281.183105 
	C275.776672,2279.532715 275.825409,2277.026855 273.529785,2276.503906 
	C257.633759,2272.883545 242.546799,2265.567383 224.878357,2265.993652 
z"
        />
        <path
          fill="#5D758A"
          opacity="1.000000"
          stroke="none"
          d="
M526.659851,2367.481445 
	C522.068420,2367.965332 518.096252,2368.001709 514.124939,2367.959961 
	C512.150940,2367.939209 510.015686,2368.037842 508.725464,2366.156006 
	C504.016663,2359.289062 498.089569,2361.600342 492.177734,2363.904541 
	C491.281799,2364.253662 490.581238,2365.218018 489.681946,2365.423340 
	C487.148651,2366.002197 483.723541,2366.941406 482.935944,2363.688477 
	C481.951355,2359.621582 485.804047,2360.183350 488.235504,2360.049072 
	C492.794464,2359.796875 496.806335,2358.124512 500.763977,2355.873047 
	C493.667694,2356.937744 486.470886,2354.824463 479.605072,2357.240234 
	C477.850800,2357.857178 474.535797,2358.032227 475.077576,2360.179932 
	C476.607452,2366.244385 470.948090,2370.647949 472.027496,2377.177734 
	C469.550140,2382.171875 464.609619,2381.081787 460.561707,2383.489258 
	C457.946930,2384.924072 455.982971,2386.104004 453.477203,2384.705811 
	C450.272827,2382.916992 450.004883,2380.923340 452.904541,2378.695557 
	C456.109619,2376.232910 460.684204,2376.489746 463.516846,2373.375244 
	C464.999603,2371.745117 467.726837,2370.503662 466.724731,2367.742920 
	C465.874237,2365.399658 463.331512,2365.927246 461.480774,2366.077148 
	C455.527069,2366.559570 450.484314,2365.547119 446.744293,2360.151855 
	C444.886475,2357.471436 441.321869,2357.946045 438.340057,2358.054443 
	C427.742004,2358.439697 417.030243,2356.634521 406.558502,2359.732422 
	C405.384979,2360.079590 403.894012,2359.860352 403.166992,2357.423584 
	C406.208862,2355.281250 409.710114,2353.951660 413.701630,2354.033447 
	C418.193726,2354.125488 422.156403,2353.803223 424.737335,2349.077881 
	C426.739319,2345.412354 430.271179,2345.107666 432.892670,2348.467529 
	C435.293152,2351.544189 438.187195,2351.903809 441.642365,2352.045410 
	C456.966553,2352.673096 472.159760,2351.167236 487.346130,2349.494385 
	C487.800964,2349.444336 488.171021,2348.624023 488.828491,2347.883789 
	C486.854736,2344.593994 481.482178,2346.861572 479.881348,2342.317627 
	C485.210571,2342.093018 490.404449,2340.689697 493.523712,2347.109131 
	C494.516663,2349.153076 497.149750,2349.786377 499.729065,2349.120361 
	C502.278503,2348.461914 504.889587,2348.042236 507.987762,2347.413330 
	C506.243896,2344.736328 501.681946,2345.409912 500.991638,2341.135986 
	C505.632324,2340.178711 510.024536,2339.139404 514.470825,2338.448486 
	C515.599731,2338.272949 517.272522,2338.850830 518.064941,2339.683105 
	C525.895325,2347.906006 534.639893,2343.340088 543.161743,2341.607666 
	C545.063110,2341.221436 545.748535,2339.662598 545.690979,2337.842041 
	C545.613953,2335.407227 544.102722,2334.374512 541.851501,2334.161621 
	C537.391357,2333.740479 533.357849,2335.724121 529.219421,2336.758789 
	C525.643677,2337.653076 523.086853,2337.331787 519.950928,2335.359863 
	C513.675232,2331.413086 507.203674,2329.527588 499.194031,2331.424072 
	C492.359070,2333.042236 484.837036,2331.758301 477.149506,2331.758301 
	C477.684631,2326.506348 483.836304,2329.360352 484.081146,2325.098389 
	C480.389526,2322.198975 475.057922,2325.939209 471.404510,2322.527344 
	C472.077393,2319.502930 474.360992,2320.171387 476.177399,2320.017334 
	C478.824188,2319.792969 481.962555,2320.529541 484.033051,2319.349854 
	C488.365112,2316.882080 492.758209,2317.930908 497.053406,2317.314941 
	C505.296204,2316.132568 513.709778,2317.130127 521.763428,2315.020508 
	C527.058289,2313.633301 532.432434,2313.420898 537.609131,2314.459717 
	C547.881287,2316.520752 558.010986,2316.857666 568.219238,2314.387939 
	C570.868408,2313.746826 573.480591,2314.194580 576.088745,2314.996582 
	C580.246216,2316.275146 584.432678,2316.650146 589.791260,2314.374268 
	C582.197449,2309.588867 574.154175,2313.927002 567.452576,2310.958984 
	C567.371033,2307.337646 570.076721,2306.671875 572.091431,2306.496338 
	C579.022827,2305.892578 586.030640,2305.044678 592.865479,2307.344238 
	C595.825745,2308.340576 601.194641,2307.323730 597.278625,2313.661865 
	C596.238159,2315.346191 599.892578,2316.194336 600.531372,2315.080566 
	C604.178101,2308.719971 609.370422,2310.838135 615.245361,2312.023438 
	C617.197510,2313.457520 617.578857,2315.063721 616.823181,2317.611816 
	C604.127991,2326.469482 589.888550,2324.749268 576.633850,2324.267578 
	C558.001953,2323.591064 539.518921,2324.841797 521.024353,2325.717773 
	C513.046326,2326.095459 505.116669,2323.302002 499.120972,2325.314941 
	C523.304443,2327.523682 549.246399,2322.463379 575.400635,2324.554932 
	C584.176575,2325.256836 593.317566,2324.426758 602.247803,2323.551270 
	C604.575867,2323.322998 606.876099,2322.816406 609.175293,2323.398682 
	C611.782349,2324.058594 614.674072,2324.800049 614.593506,2328.216309 
	C614.527588,2331.011230 612.081726,2332.017822 609.788025,2332.543701 
	C594.598145,2336.025879 579.458618,2339.969971 563.631531,2338.626709 
	C562.304504,2338.514160 560.983154,2338.284668 559.650269,2338.301758 
	C557.400574,2338.330566 555.568726,2339.133057 555.877502,2341.727051 
	C556.210205,2344.521973 558.541321,2344.264404 560.509827,2344.039062 
	C564.213074,2343.614990 567.483704,2341.936768 570.785583,2340.316650 
	C574.473633,2338.506836 578.710938,2336.602295 581.125183,2341.593750 
	C583.413025,2346.323730 580.858521,2350.188965 576.518372,2352.252197 
	C570.493408,2355.115723 564.138489,2356.876709 557.297424,2356.994385 
	C553.137146,2357.065674 548.626587,2355.417969 544.518494,2359.214355 
	C543.328003,2363.856445 541.096802,2366.012207 536.793701,2366.008301 
	C533.614990,2366.005615 530.316467,2365.432373 526.659851,2367.481445 
z"
        />
        <path
          fill="#627A90"
          opacity="1.000000"
          stroke="none"
          d="
M300.010742,2284.849365 
	C305.331726,2283.869141 310.613190,2284.239502 315.864288,2282.540039 
	C318.592133,2281.657227 321.908295,2281.691895 324.766296,2282.267334 
	C336.993866,2284.729980 349.030853,2283.617188 361.369263,2282.541992 
	C379.188934,2280.989258 397.352417,2280.311035 415.226868,2287.912598 
	C415.988892,2288.905029 415.993225,2289.790771 415.999237,2291.340820 
	C408.757874,2297.293945 400.549652,2295.696289 393.061859,2293.906494 
	C373.280701,2289.177734 352.747772,2287.613525 334.062225,2293.794678 
	C309.745972,2301.838135 285.305695,2299.006592 260.931061,2300.910156 
	C257.344574,2301.190186 253.788315,2301.927246 251.169785,2301.832031 
	C257.696594,2300.708496 265.285736,2300.722656 272.832855,2301.108643 
	C277.701935,2301.357910 282.342529,2302.723145 280.792023,2310.172363 
	C267.924835,2316.658691 254.441559,2314.862305 241.396530,2315.514160 
	C235.847580,2315.791260 230.119934,2315.314209 224.565765,2316.161865 
	C216.908173,2317.331299 209.281677,2317.820801 201.727783,2316.061035 
	C191.799789,2313.747803 181.845810,2316.257080 171.894318,2315.532715 
	C167.106598,2315.184326 164.156311,2314.202881 164.080750,2308.191406 
	C166.129288,2302.069336 170.927612,2301.595215 175.477905,2300.621094 
	C182.689651,2299.077393 190.061478,2300.561279 197.301819,2299.338135 
	C201.696045,2298.595947 206.406296,2299.587158 208.505508,2293.496582 
	C209.548203,2290.471436 213.843460,2289.667969 217.173492,2289.456787 
	C228.044952,2288.767090 238.943970,2288.039795 249.804291,2289.538574 
	C258.158539,2290.691406 266.311432,2289.222900 275.257477,2288.024414 
	C281.931000,2290.773682 288.024261,2292.427002 294.688568,2292.023682 
	C299.072052,2291.758545 300.108215,2289.356201 300.010742,2284.849365 
z"
        />
        <path
          fill="#71889C"
          opacity="1.000000"
          stroke="none"
          d="
M275.602905,2287.331543 
	C274.669312,2291.204102 271.593628,2292.820312 268.824066,2292.461914 
	C261.220062,2291.477295 253.370453,2296.758301 245.960419,2291.454346 
	C245.054901,2290.806152 243.350021,2290.733154 242.201553,2291.055664 
	C235.650223,2292.895996 228.986435,2291.841797 222.386948,2291.936035 
	C217.655289,2292.003906 213.311493,2292.877930 209.446732,2294.908447 
	C208.301895,2298.468994 213.512680,2297.474609 212.623810,2300.463379 
	C212.203583,2301.566162 211.216995,2301.796143 210.238220,2301.854980 
	C197.845825,2302.601074 185.448608,2303.271484 173.061127,2304.090088 
	C171.127716,2304.217773 169.236633,2304.985352 166.604507,2305.789551 
	C163.298096,2308.029297 160.890564,2307.851807 158.329315,2304.768555 
	C158.640381,2298.731689 157.355225,2295.531250 151.031754,2297.222412 
	C149.868332,2297.533691 148.404572,2296.573730 147.156128,2296.748535 
	C144.950775,2297.057617 142.316727,2298.945312 140.876709,2296.222412 
	C139.297150,2293.235840 142.593384,2292.728027 144.221512,2291.252441 
	C146.640991,2289.059326 145.737381,2287.120605 142.757889,2286.238770 
	C141.497620,2285.865723 140.110245,2285.939941 138.824280,2285.629883 
	C137.844879,2285.393555 136.864944,2284.728027 137.104462,2283.594238 
	C137.290054,2282.715576 137.925522,2281.858887 138.557175,2281.173096 
	C138.965546,2280.729980 139.840927,2280.253418 140.314163,2280.402588 
	C147.138870,2282.556641 152.508148,2279.805176 158.170456,2276.225342 
	C161.804764,2273.927490 166.400253,2273.614014 168.870193,2279.161377 
	C169.723846,2281.078369 171.412613,2281.737305 173.655563,2279.914795 
	C180.206543,2274.591309 180.901260,2274.750488 187.640411,2279.511475 
	C190.302780,2281.392334 192.641037,2284.069336 196.363007,2283.913818 
	C198.334167,2283.831299 200.569778,2284.090576 201.330124,2281.648438 
	C202.034836,2279.385010 200.790100,2277.743408 198.851913,2276.823730 
	C197.107422,2275.996338 195.191437,2275.530029 193.443192,2274.935791 
	C193.540085,2271.408691 196.051758,2272.134277 197.791977,2272.075684 
	C201.784561,2271.941162 205.764679,2272.078125 209.587296,2270.517578 
	C211.143890,2269.882324 212.966263,2269.820801 214.377792,2270.899170 
	C221.521286,2276.356201 228.323410,2277.596680 235.978165,2274.630371 
	C237.195221,2274.158936 238.261047,2273.262695 239.495010,2272.878662 
	C240.978363,2272.417236 242.188599,2273.354248 242.679626,2274.604004 
	C243.513931,2276.728516 242.123047,2277.782227 240.250824,2277.803467 
	C233.591217,2277.878906 227.302505,2280.825195 220.506943,2280.095215 
	C217.669205,2279.790527 213.231522,2279.638916 213.417786,2283.423096 
	C213.623428,2287.601074 218.144089,2285.984131 220.733597,2285.741455 
	C236.469254,2284.268555 252.233398,2283.952637 268.018066,2283.998047 
	C270.756561,2284.005859 273.362701,2284.248047 275.602905,2287.331543 
z"
        />
        <path
          fill="#71889C"
          opacity="1.000000"
          stroke="none"
          d="
M280.553711,2310.694580 
	C280.349548,2305.082275 277.536835,2303.899414 273.168671,2303.980713 
	C265.202087,2304.129150 257.230469,2304.010986 249.260956,2303.990723 
	C246.937012,2303.984863 244.610474,2303.992432 242.290192,2303.884521 
	C240.530716,2303.802979 238.751160,2303.113525 238.685760,2301.208252 
	C238.596054,2298.593750 240.777115,2298.208008 242.855408,2298.092773 
	C243.847748,2298.037842 244.869141,2297.948486 245.837738,2298.111084 
	C254.751495,2299.606445 263.514496,2297.977783 272.303741,2296.894043 
	C273.282806,2296.773438 274.341400,2296.203857 275.208405,2296.431641 
	C289.400299,2300.157227 303.448395,2293.987549 317.646240,2295.840332 
	C320.721771,2296.241455 326.768768,2297.863281 323.416321,2290.329102 
	C322.737061,2288.802979 325.410278,2287.860107 326.014252,2288.417236 
	C330.823792,2292.853760 335.188782,2288.056152 339.731689,2288.020264 
	C354.869019,2287.901123 370.006592,2286.982910 385.142761,2288.058838 
	C385.803772,2288.105957 386.608704,2287.967285 387.099884,2288.290283 
	C395.539764,2293.838867 405.030609,2291.594971 415.080048,2292.007324 
	C417.038971,2294.041016 420.244598,2293.666016 421.142426,2296.843750 
	C411.824615,2299.806152 402.216064,2296.274170 392.590485,2299.483643 
	C383.160400,2304.253906 373.345764,2301.350586 364.015381,2304.822021 
	C342.375946,2312.872314 319.317291,2310.849121 296.696350,2311.303467 
	C291.532288,2311.407227 286.346588,2314.470215 280.553711,2310.694580 
z"
        />
        <path
          fill="#627A90"
          opacity="1.000000"
          stroke="none"
          d="
M325.996033,2391.207031 
	C318.101562,2391.701660 310.142700,2392.876465 301.512665,2390.616211 
	C301.322754,2393.713867 304.334320,2397.354004 299.843964,2399.557373 
	C296.990051,2400.957764 295.026245,2404.093262 291.285553,2403.959229 
	C283.039581,2403.664307 274.724091,2404.915039 266.559601,2403.106689 
	C264.229431,2402.590576 261.747162,2401.611816 259.914764,2403.233887 
	C254.834686,2407.730225 249.082825,2406.774170 243.237671,2406.016602 
	C239.851135,2405.577881 236.705795,2406.749023 232.752014,2407.834961 
	C227.388702,2402.311035 219.868362,2407.108887 214.379608,2402.468018 
	C213.922897,2401.213379 213.950211,2400.563721 213.959946,2399.913574 
	C214.121948,2389.098145 212.369034,2387.725342 225.567200,2387.934326 
	C228.842865,2387.986084 233.179779,2389.308350 233.907837,2382.997559 
	C232.769669,2380.095215 228.151520,2381.298828 229.508331,2376.611328 
	C235.504745,2378.402100 241.277863,2377.765381 247.528015,2377.559326 
	C251.713165,2384.483643 257.866089,2382.353516 263.156586,2381.916748 
	C275.993683,2380.856934 288.700836,2378.268799 301.686798,2378.991211 
	C309.609070,2379.431885 317.434875,2380.310303 325.523315,2383.511230 
	C326.034058,2386.244629 326.015503,2388.334229 325.996033,2391.207031 
z"
        />
        <path
          fill="#50677C"
          opacity="1.000000"
          stroke="none"
          d="
M279.126801,2310.202637 
	C297.541107,2307.264404 315.558105,2309.932373 333.120941,2306.226074 
	C335.417938,2305.741211 337.705475,2306.358887 339.995605,2306.962402 
	C344.833374,2308.237305 349.795288,2309.792236 352.925110,2303.672852 
	C353.711945,2302.134766 355.743591,2302.066650 357.423737,2302.083496 
	C368.381378,2302.194092 379.117615,2298.758789 391.065552,2300.051270 
	C391.314667,2304.203369 388.129333,2303.842529 385.103546,2304.026367 
	C380.587433,2304.300537 375.918823,2303.152832 370.927490,2305.177979 
	C372.943359,2309.492676 377.249146,2306.773682 380.000580,2309.212646 
	C372.779663,2311.055176 365.918182,2312.103760 358.752258,2312.013428 
	C341.480713,2311.795410 324.364716,2314.498535 307.187012,2315.350830 
	C294.060089,2316.001709 280.800751,2316.302246 267.581543,2316.093994 
	C250.957108,2315.832520 234.538879,2318.807129 218.090958,2320.677246 
	C211.257935,2321.453857 204.695221,2320.558350 198.461823,2319.502686 
	C186.802780,2317.528564 175.233994,2318.485840 163.641190,2317.996582 
	C156.025848,2317.675537 148.476547,2317.865967 140.819473,2316.474365 
	C133.768066,2315.193115 126.337692,2315.755859 119.008751,2317.161621 
	C109.676842,2318.951416 100.225502,2318.945801 90.489548,2314.232910 
	C93.342506,2311.445801 96.081573,2312.074951 98.543037,2312.051025 
	C107.204987,2311.967285 115.884041,2311.678955 124.526070,2312.098145 
	C132.284470,2312.474365 139.589630,2309.597412 147.287872,2309.738281 
	C151.249924,2309.810791 153.351318,2305.928467 157.286102,2304.174316 
	C160.053619,2305.059082 162.096954,2306.150391 165.195892,2306.089355 
	C165.918732,2310.219238 165.467117,2313.692627 172.104706,2313.048340 
	C176.168945,2312.654053 180.404526,2313.126221 184.712753,2312.494141 
	C190.892593,2311.588135 197.311874,2311.236816 203.527573,2312.880615 
	C209.778427,2314.533447 216.008224,2314.525879 222.226608,2312.936523 
	C227.490448,2311.591064 232.933136,2311.336426 238.039261,2312.572266 
	C251.783722,2315.898438 264.998413,2312.682129 279.126801,2310.202637 
z"
        />
        <path
          fill="#4A667C"
          opacity="1.000000"
          stroke="none"
          d="
M228.978790,2376.063477 
	C229.362549,2379.604004 233.434097,2379.149658 234.200256,2381.762939 
	C220.985916,2381.721436 208.167908,2381.967773 195.948044,2387.351318 
	C193.663788,2388.357666 190.699539,2387.968994 188.048843,2387.971680 
	C170.060699,2387.989746 152.071625,2387.829590 134.084778,2387.971436 
	C127.715164,2388.021729 121.637817,2384.625732 115.330849,2386.077393 
	C103.236176,2388.861572 91.786705,2385.420654 80.185692,2383.458984 
	C79.594414,2380.885742 81.444809,2380.040039 82.857704,2380.460938 
	C94.274933,2383.862549 105.167900,2380.031006 116.224152,2378.382568 
	C118.193962,2378.088867 120.200172,2378.039307 123.040680,2377.833496 
	C125.569420,2375.315430 127.644798,2373.738525 131.471863,2374.418945 
	C137.089935,2374.096191 142.084625,2374.034424 147.079254,2374.039062 
	C151.353287,2374.042969 155.183380,2374.948975 159.382217,2376.568359 
	C167.079544,2379.537842 175.744781,2379.151367 183.725479,2376.617188 
	C186.664062,2375.684082 189.055542,2375.396240 192.117157,2375.540771 
	C204.076706,2376.105713 216.070358,2375.947998 228.978790,2376.063477 
z"
        />
        <path
          fill="#597288"
          opacity="1.000000"
          stroke="none"
          d="
M374.760559,2335.261719 
	C370.376923,2333.658203 366.544098,2333.350098 363.088501,2334.321777 
	C352.562012,2337.282227 341.890442,2335.519531 331.295410,2335.893799 
	C328.835541,2335.980713 325.020325,2336.793945 324.863251,2333.135986 
	C324.699463,2329.322266 328.563019,2329.943604 330.987244,2330.101807 
	C346.344147,2331.106201 361.085663,2326.998779 376.282166,2325.253662 
	C374.941254,2319.997314 369.254669,2323.713623 367.322693,2320.311523 
	C373.848389,2317.047363 387.203217,2317.024414 395.520264,2320.267334 
	C394.506775,2323.231689 391.928925,2321.636230 390.084900,2322.065430 
	C387.959045,2322.560303 385.942932,2323.190186 386.779938,2325.823975 
	C387.547211,2328.238037 390.235352,2328.491699 391.756592,2327.518066 
	C395.602997,2325.056885 399.711761,2325.213623 403.937805,2324.942139 
	C416.766327,2324.117188 429.564667,2322.645508 442.401489,2322.206543 
	C448.189148,2322.008789 454.040039,2323.661621 459.977936,2325.225098 
	C450.595856,2326.548340 442.512115,2332.029053 432.730316,2332.156738 
	C425.828217,2332.247070 418.994659,2334.113770 412.007202,2334.456787 
	C405.674469,2334.767822 399.491150,2335.332764 393.143738,2337.418945 
	C388.050323,2339.093018 381.500519,2336.335938 374.760559,2335.261719 
z"
        />
        <path
          fill="#4B6377"
          opacity="1.000000"
          stroke="none"
          d="
M577.112671,2398.132812 
	C578.477783,2403.526611 574.953064,2405.235352 570.663391,2405.720459 
	C567.040344,2406.130127 563.342651,2405.911865 559.677917,2405.915771 
	C544.012512,2405.932617 528.346985,2405.895996 512.681763,2405.949463 
	C507.043427,2405.968750 501.362335,2405.467041 495.870514,2407.533691 
	C494.378021,2408.095215 492.408875,2408.363281 490.948395,2407.883545 
	C478.947327,2403.941162 466.682709,2407.723389 454.494019,2406.464600 
	C439.649780,2404.931641 424.459137,2405.129150 409.564880,2406.356201 
	C401.541565,2407.017090 395.272308,2403.024170 387.024994,2402.914795 
	C388.431580,2399.431396 391.318817,2398.718750 392.857697,2398.924805 
	C404.112854,2400.430176 415.348755,2395.969971 426.348785,2398.317139 
	C435.670044,2400.306396 444.890594,2399.570068 455.092407,2399.944336 
	C460.324829,2400.105957 464.583160,2400.271484 468.789307,2398.802734 
	C470.591949,2398.173584 472.890289,2397.739014 474.610260,2398.299561 
	C484.862274,2401.640625 495.807007,2398.264648 506.044464,2401.765625 
	C508.132019,2402.479248 510.767395,2402.446289 512.942505,2401.912109 
	C528.499878,2398.089600 544.665039,2402.074951 561.033569,2398.102295 
	C564.790222,2399.947021 567.804016,2400.671631 571.585938,2398.530273 
	C573.551086,2398.104492 574.976868,2398.116699 577.112671,2398.132812 
z"
        />
        <path
          fill="#627A90"
          opacity="1.000000"
          stroke="none"
          d="
M131.508270,2373.785645 
	C130.365829,2376.450195 127.332695,2376.029297 124.527481,2377.607422 
	C120.582260,2368.103271 120.562202,2368.444336 111.044998,2367.884277 
	C104.353775,2367.490723 97.501709,2371.207520 90.487381,2366.627930 
	C97.403816,2359.979248 87.111832,2362.876465 86.564636,2360.254150 
	C85.822365,2359.365723 85.656509,2358.727539 85.460869,2358.098145 
	C84.661636,2355.527832 79.694870,2356.100830 80.703506,2352.601562 
	C81.827034,2348.704102 85.652542,2350.036133 88.387131,2350.076172 
	C93.809341,2350.155273 99.384109,2349.186279 105.446976,2351.060059 
	C102.792076,2355.005859 100.364296,2352.705566 98.142487,2352.452881 
	C95.054832,2352.101562 92.751930,2353.301514 92.592041,2356.563477 
	C92.429779,2359.873779 95.201180,2359.893799 97.524750,2359.942139 
	C103.749710,2360.071289 110.106461,2361.066406 113.736816,2353.671875 
	C115.770721,2349.528809 120.350182,2349.885986 125.192886,2350.000977 
	C126.245552,2352.540527 125.908798,2356.483887 129.952652,2354.787598 
	C140.106781,2350.528564 150.297348,2355.230225 160.328278,2353.931885 
	C179.448318,2351.456787 198.725586,2352.838623 217.859314,2351.082275 
	C218.826065,2350.993408 219.900131,2350.715576 220.743103,2351.022949 
	C221.561371,2351.321045 222.452988,2352.139893 222.767807,2352.943359 
	C223.371765,2354.484863 222.376724,2355.597900 220.916748,2355.695068 
	C214.760986,2356.104736 209.069214,2358.341553 202.340698,2356.897461 
	C194.213547,2355.152832 185.243515,2353.846191 177.225601,2359.007324 
	C174.907440,2360.499512 172.045670,2360.064697 169.408859,2359.961914 
	C159.829971,2359.589111 150.204041,2360.985596 140.671234,2358.307373 
	C136.331879,2357.087891 131.620728,2357.567139 131.937210,2364.179932 
	C132.088669,2367.344971 125.612968,2369.755615 131.508270,2373.785645 
z"
        />
        <path
          fill="#5B768D"
          opacity="1.000000"
          stroke="none"
          d="
M281.797241,2356.605469 
	C282.209534,2353.861816 282.805542,2351.310059 284.783997,2351.059082 
	C289.798248,2350.424072 293.856750,2346.676025 298.515350,2346.334473 
	C308.403992,2345.609375 318.602448,2344.836670 328.265503,2346.481689 
	C338.432404,2348.212402 348.122864,2352.741699 358.720215,2356.281250 
	C334.870422,2366.117920 311.417328,2366.032227 286.961487,2360.422119 
	C284.986176,2358.681641 282.457336,2359.374756 281.797241,2356.605469 
z"
        />
        <path
          fill="#50677C"
          opacity="1.000000"
          stroke="none"
          d="
M453.383423,2302.680664 
	C456.152008,2302.161133 458.295258,2302.154785 461.243713,2302.166748 
	C470.337921,2306.461182 479.469116,2304.588867 488.188416,2305.718994 
	C489.110779,2305.838379 490.617798,2305.661865 491.002045,2305.069824 
	C495.110687,2298.739990 501.282990,2301.382812 506.229095,2302.310547 
	C515.499939,2304.049561 524.744263,2303.857666 534.031433,2304.055664 
	C535.600891,2304.089111 537.373169,2303.808594 538.414856,2305.641357 
	C538.314026,2308.031494 536.356812,2307.922607 534.862671,2307.884033 
	C514.883606,2307.366211 495.351776,2312.050293 475.364532,2312.646484 
	C457.054749,2313.192627 439.096863,2311.941895 421.023468,2310.221191 
	C416.721466,2309.811768 412.395142,2309.648926 408.138611,2310.913086 
	C403.291290,2312.353271 398.413940,2312.314941 392.409515,2310.946777 
	C396.605133,2306.196289 401.342133,2305.817139 406.129028,2306.062012 
	C421.788300,2306.863281 437.246674,2304.647217 453.383423,2302.680664 
z"
        />
        <path
          fill="#4C6880"
          opacity="1.000000"
          stroke="none"
          d="
M526.909546,2367.973145 
	C527.071594,2362.613037 531.371277,2364.292236 534.679749,2364.031738 
	C537.900513,2363.777832 541.549927,2364.475586 541.993042,2358.844971 
	C544.022034,2355.545898 546.848633,2356.186523 550.263916,2356.082031 
	C554.198486,2356.835205 557.337219,2357.129150 561.311157,2356.087891 
	C569.942261,2354.120850 577.736389,2354.248047 585.755493,2354.870117 
	C601.419128,2356.085449 616.837646,2353.384277 632.133911,2350.120117 
	C641.490112,2348.123779 650.776733,2344.806885 661.257080,2348.647461 
	C666.937744,2358.759033 656.972656,2357.611816 652.577454,2361.479004 
	C626.488708,2365.396973 601.198303,2368.841309 574.815186,2364.404785 
	C563.195007,2365.152832 552.297241,2365.591064 540.796875,2368.035156 
	C535.898315,2368.064209 531.838562,2368.024902 526.909546,2367.973145 
z"
        />
        <path
          fill="#71889C"
          opacity="1.000000"
          stroke="none"
          d="
M130.008575,2246.708008 
	C130.949265,2245.093750 131.954956,2243.341064 132.858597,2243.392090 
	C140.928986,2243.848877 148.517197,2239.192871 156.382797,2240.576416 
	C162.024796,2241.569092 168.384872,2244.024170 171.469543,2248.856689 
	C174.440277,2253.510742 177.777496,2254.343506 182.193481,2253.946777 
	C189.287216,2253.308838 196.036316,2255.282471 202.921967,2256.223389 
	C203.928040,2256.360840 204.633728,2257.028564 204.826630,2258.590820 
	C199.744186,2259.392090 194.528778,2261.211670 189.500122,2259.399658 
	C180.478470,2256.148438 173.288940,2258.243164 166.615356,2265.670898 
	C165.094223,2258.509521 165.119812,2258.751709 158.311691,2259.470703 
	C154.483490,2259.875244 149.378159,2262.367676 148.016022,2254.816162 
	C144.093323,2251.293945 139.363861,2252.588135 134.584442,2251.664307 
	C131.166733,2251.770020 130.002609,2250.101562 130.008575,2246.708008 
z"
        />
        <path
          fill="#4A667C"
          opacity="1.000000"
          stroke="none"
          d="
M326.905884,2384.133301 
	C318.736938,2384.279541 311.567047,2382.942871 304.331299,2382.205078 
	C291.399719,2380.886963 278.545715,2383.142334 265.801880,2385.188965 
	C261.133759,2385.938721 256.545135,2386.082520 251.931976,2385.825439 
	C248.057831,2385.609619 245.449951,2383.898193 247.726410,2378.685059 
	C268.392670,2378.215332 288.668823,2376.529785 308.913177,2374.308350 
	C310.881500,2374.092041 312.974792,2373.873291 314.656738,2375.114502 
	C321.657745,2380.280273 329.009125,2378.057861 337.170319,2376.226074 
	C340.691498,2376.490967 342.113953,2378.145996 342.361023,2381.375977 
	C337.744934,2385.679688 332.532043,2383.380371 326.905884,2384.133301 
z"
        />
        <path
          fill="#597288"
          opacity="1.000000"
          stroke="none"
          d="
M616.402954,2318.766602 
	C616.001221,2316.511475 616.002441,2315.023193 616.007751,2312.791748 
	C623.113953,2311.904541 630.181824,2310.381104 637.327942,2311.952393 
	C644.119934,2313.446045 649.060120,2308.878906 655.286621,2306.108154 
	C657.376709,2304.842041 658.918640,2304.529541 661.298401,2305.521484 
	C669.966492,2307.885986 678.108765,2308.054443 687.127197,2308.016113 
	C690.549072,2310.972656 698.037781,2308.483154 696.056580,2316.475098 
	C692.796570,2318.842285 689.800903,2318.928711 686.802063,2318.484863 
	C667.680176,2315.654297 648.943115,2317.880615 630.440857,2322.827393 
	C625.169250,2324.236572 620.568054,2323.879883 616.402954,2318.766602 
z"
        />
        <path
          fill="#445E74"
          opacity="1.000000"
          stroke="none"
          d="
M753.039307,2307.963623 
	C761.884216,2307.965088 769.797485,2307.941650 777.707458,2308.089600 
	C780.529358,2308.142334 783.791931,2307.148193 785.904175,2311.271973 
	C785.424927,2317.085693 781.187439,2316.776367 777.780945,2317.229980 
	C772.860352,2317.885254 767.839478,2318.604492 762.943420,2318.554199 
	C747.781616,2318.398193 732.615051,2318.104736 716.647888,2317.234131 
	C713.978882,2314.583740 713.818237,2312.180176 715.412842,2308.866943 
	C728.046936,2308.084961 740.076599,2308.045654 753.039307,2307.963623 
z"
        />
        <path
          fill="#728BA1"
          opacity="1.000000"
          stroke="none"
          d="
M786.455566,2289.471924 
	C779.268494,2289.972168 772.566040,2289.973633 764.952881,2289.961914 
	C764.086487,2287.001709 763.528564,2283.891113 764.277222,2281.135010 
	C767.987793,2267.474365 764.828308,2269.605713 779.278809,2265.943848 
	C781.780396,2264.451416 783.728882,2263.843750 786.667847,2264.561523 
	C791.796753,2269.195312 786.934998,2271.322266 785.119690,2274.182617 
	C790.858704,2275.968994 790.055908,2280.322510 789.186401,2284.656494 
	C788.859314,2286.286865 788.120361,2287.758789 786.455566,2289.471924 
z"
        />
        <path
          fill="#71889C"
          opacity="1.000000"
          stroke="none"
          d="
M510.050842,2269.651855 
	C511.818726,2272.905518 510.007843,2273.409424 507.914246,2274.046143 
	C496.862610,2277.406738 485.544281,2275.763428 474.338348,2275.810303 
	C469.744293,2275.829590 468.865448,2271.859375 468.063843,2268.142578 
	C466.465088,2260.730225 466.396881,2260.744873 459.155365,2262.282715 
	C461.890930,2255.495361 461.890930,2255.495361 475.714050,2256.537354 
	C473.733398,2258.651123 471.425842,2260.361328 474.503998,2263.599609 
	C478.991852,2268.320312 480.374237,2268.721436 487.442383,2264.986328 
	C488.770935,2264.284424 490.336182,2264.180420 490.942535,2265.439941 
	C494.105377,2272.008789 499.632050,2270.017334 504.720490,2269.380615 
	C506.334503,2269.178711 507.866425,2268.353027 510.050842,2269.651855 
z"
        />
        <path
          fill="#4B6377"
          opacity="1.000000"
          stroke="none"
          d="
M553.050903,2385.896973 
	C562.200928,2386.436035 570.582886,2384.921631 578.718262,2387.659912 
	C581.646667,2388.645752 584.588318,2387.395020 587.496338,2386.614014 
	C590.523193,2385.801025 591.752197,2387.572510 591.848145,2391.129395 
	C569.038208,2391.633057 546.150208,2393.667236 522.487305,2388.943848 
	C525.734497,2384.766357 530.019897,2384.458740 533.292664,2385.086670 
	C539.618713,2386.300781 545.871155,2385.749268 553.050903,2385.896973 
z"
        />
        <path
          fill="#546D83"
          opacity="1.000000"
          stroke="none"
          d="
M106.808945,2344.003174 
	C102.370514,2340.507324 97.255333,2340.840332 92.475067,2338.513184 
	C101.364510,2336.556641 110.853142,2336.826172 120.319778,2335.377441 
	C130.222305,2333.861816 140.509644,2333.818848 150.624298,2332.934082 
	C153.081665,2332.718994 156.599640,2333.115967 159.280502,2336.362061 
	C150.451324,2339.989746 141.389862,2336.683838 132.891373,2338.689453 
	C124.516006,2340.666260 116.042755,2342.228027 106.808945,2344.003174 
z"
        />
        <path
          fill="#546D83"
          opacity="1.000000"
          stroke="none"
          d="
M175.929428,2338.030518 
	C171.850143,2337.834961 168.510071,2338.529541 165.523438,2336.968506 
	C165.591385,2333.492920 168.082855,2333.981445 169.827805,2334.055176 
	C181.672577,2334.554199 193.621872,2332.493164 205.373962,2335.757568 
	C208.548904,2336.639404 211.855804,2335.596680 215.116287,2334.715088 
	C218.771500,2333.727051 222.752716,2332.935547 226.703873,2336.365479 
	C224.474548,2338.522217 222.134766,2337.821289 220.072403,2337.938721 
	C209.876434,2338.519287 199.647659,2337.117432 189.430664,2339.420654 
	C185.523605,2340.301514 181.027740,2338.570801 175.929428,2338.030518 
z"
        />
        <path
          fill="#445E74"
          opacity="1.000000"
          stroke="none"
          d="
M281.060425,2355.938232 
	C283.173523,2356.985352 284.351624,2358.046143 285.893311,2359.595215 
	C271.519226,2363.025879 256.842590,2363.124023 242.222885,2359.345215 
	C254.550476,2354.190674 267.471344,2356.656006 281.060425,2355.938232 
z"
        />
        <path
          fill="#5D758A"
          opacity="1.000000"
          stroke="none"
          d="
M374.619110,2360.531250 
	C381.064148,2361.154541 386.528259,2360.597900 391.541962,2357.629883 
	C393.595947,2356.413818 396.212830,2355.049072 397.204987,2358.308838 
	C398.207184,2361.601318 395.048798,2361.666748 392.834595,2362.160645 
	C380.688568,2364.868896 368.542694,2367.539795 354.991028,2364.990723 
	C361.947845,2359.755371 361.947845,2359.755371 374.619110,2360.531250 
z"
        />
        <path
          fill="#50677C"
          opacity="1.000000"
          stroke="none"
          d="
M460.962341,2383.966797 
	C461.492584,2378.355225 466.586304,2379.215820 471.279205,2377.992676 
	C481.565277,2379.753174 490.910187,2374.398682 500.557800,2377.513916 
	C488.032715,2381.941650 475.334839,2385.137939 460.962341,2383.966797 
z"
        />
        <path
          fill="#627A90"
          opacity="1.000000"
          stroke="none"
          d="
M432.008423,2286.806152 
	C440.259979,2285.598877 446.578400,2288.312744 449.693176,2297.353516 
	C445.848999,2300.521484 441.252716,2300.226318 436.760651,2299.899414 
	C431.223236,2299.496338 430.710266,2298.527344 431.570831,2292.410889 
	C431.795197,2290.816406 431.862305,2289.199463 432.008423,2286.806152 
z"
        />
        <path
          fill="#71889C"
          opacity="1.000000"
          stroke="none"
          d="
M481.404480,2236.271973 
	C479.626221,2241.993164 481.329865,2245.009766 488.490021,2245.038086 
	C472.863312,2250.651367 475.529083,2250.821533 466.499054,2241.387451 
	C471.509644,2239.226074 473.486847,2231.202393 481.404480,2236.271973 
z"
        />
        <path
          fill="#71889C"
          opacity="1.000000"
          stroke="none"
          d="
M679.999512,2232.818848 
	C682.822815,2232.156006 685.075989,2231.377197 686.768555,2228.830078 
	C688.080505,2226.855225 690.149231,2225.093750 692.520996,2227.121094 
	C694.410278,2228.735840 692.245911,2230.758789 692.080444,2233.305908 
	C692.417908,2236.201660 694.730530,2236.985596 695.822632,2239.417480 
	C695.488525,2240.993408 694.972168,2241.977295 694.235291,2243.470703 
	C692.411438,2243.977051 690.793884,2244.110840 689.207092,2243.947021 
	C681.637146,2243.164307 680.761047,2242.148682 679.999512,2232.818848 
z"
        />
        <path
          fill="#728BA1"
          opacity="1.000000"
          stroke="none"
          d="
M755.925598,2289.262939 
	C747.984924,2290.001465 739.938049,2289.983398 730.970520,2289.956543 
	C730.351318,2288.688965 730.461792,2286.990723 731.887024,2286.756104 
	C736.434570,2286.006836 740.036621,2284.132568 743.761658,2281.117432 
	C748.539185,2277.250244 752.646912,2280.163086 755.925598,2289.262939 
z"
        />
        <path
          fill="#597288"
          opacity="1.000000"
          stroke="none"
          d="
M715.820312,2307.343994 
	C716.050110,2310.145020 716.082886,2312.165771 716.166443,2314.945557 
	C714.801941,2317.808838 712.679993,2318.084473 709.614502,2317.774414 
	C705.020752,2312.604736 696.774231,2314.133545 694.012207,2306.770508 
	C701.963562,2302.991211 709.541748,2303.188721 715.820312,2307.343994 
z"
        />
        <path
          fill="#4A667C"
          opacity="1.000000"
          stroke="none"
          d="
M353.561737,2372.761230 
	C359.065521,2369.823486 364.071594,2368.539551 368.935944,2372.208496 
	C370.527130,2373.408691 371.880035,2375.102539 371.422272,2377.249023 
	C370.903473,2379.681396 368.716522,2379.776855 366.721680,2379.812744 
	C363.108978,2379.878174 359.495087,2379.877441 354.996765,2379.899902 
	C352.232758,2378.005615 351.938538,2375.849854 353.561737,2372.761230 
z"
        />
        <path
          fill="#61798F"
          opacity="1.000000"
          stroke="none"
          d="
M662.491272,2305.485352 
	C660.477234,2306.014648 658.990662,2306.012207 656.760559,2306.009766 
	C656.056335,2300.043701 649.629517,2300.112793 647.106201,2296.592773 
	C647.575256,2293.609619 650.083374,2293.571533 651.542297,2294.437988 
	C656.513550,2297.390869 661.742371,2298.412842 667.418213,2298.129639 
	C669.421448,2298.029297 671.486511,2298.656494 671.304871,2301.327881 
	C671.164185,2303.396729 669.385254,2303.623291 667.745422,2303.915283 
	C666.158447,2304.197510 664.593384,2304.602539 662.491272,2305.485352 
z"
        />
        <path
          fill="#50677C"
          opacity="1.000000"
          stroke="none"
          d="
M42.764488,2314.067383 
	C43.563026,2310.962158 48.466160,2313.239746 49.878784,2308.704834 
	C50.898422,2308.003906 51.786297,2307.975830 53.340553,2307.952881 
	C60.156738,2308.061523 66.307480,2307.946777 73.225677,2308.063477 
	C75.477898,2308.079834 76.961784,2308.070068 79.185623,2308.074707 
	C80.190987,2312.041260 78.547821,2314.129639 74.382187,2313.966064 
	C72.145996,2313.877930 69.900978,2314.014160 66.798218,2314.119385 
	C58.525444,2314.154541 51.114399,2314.119385 42.764488,2314.067383 
z"
        />
        <path
          fill="#627A90"
          opacity="1.000000"
          stroke="none"
          d="
M353.322540,2372.021973 
	C354.075684,2374.151855 354.059418,2376.242676 354.077454,2379.114258 
	C350.886353,2381.901123 347.310913,2382.182373 342.869690,2382.024902 
	C340.903656,2380.420898 339.713196,2378.812012 338.252686,2376.671387 
	C342.250977,2369.696289 342.250977,2369.696289 353.322540,2372.021973 
z"
        />
        <path
          fill="#71889C"
          opacity="1.000000"
          stroke="none"
          d="
M483.078430,2290.047852 
	C487.813232,2290.558350 488.829071,2293.457764 488.119629,2296.495117 
	C487.594696,2298.742676 484.806732,2297.898682 482.955170,2297.966797 
	C479.996796,2298.076416 477.024384,2298.111328 474.070892,2297.945801 
	C471.957855,2297.827637 468.869781,2298.529297 469.085938,2294.956543 
	C469.229340,2292.585938 470.699005,2290.516602 473.396423,2290.276123 
	C476.337769,2290.014648 479.308746,2290.086670 483.078430,2290.047852 
z"
        />
        <path
          fill="#61798F"
          opacity="1.000000"
          stroke="none"
          d="
M693.386353,2306.259277 
	C696.288086,2308.222900 698.526550,2310.356689 702.213684,2310.147217 
	C705.071899,2309.984863 707.037292,2311.648193 707.907349,2315.244873 
	C704.843018,2319.245605 701.230408,2319.144775 696.701660,2316.666992 
	C695.761292,2313.436035 694.277893,2312.191895 691.782166,2311.949463 
	C690.130615,2311.789062 688.881958,2311.004639 688.134583,2308.697510 
	C689.592041,2307.519287 691.197998,2307.019043 693.386353,2306.259277 
z"
        />
        <path
          fill="#71889C"
          opacity="1.000000"
          stroke="none"
          d="
M145.140671,2267.081055 
	C147.284042,2268.335693 150.089279,2268.688232 148.710770,2271.331787 
	C147.056122,2274.505127 143.916702,2274.494141 141.057892,2273.124268 
	C138.282089,2271.794189 137.416290,2269.270996 139.075638,2266.608643 
	C140.708206,2263.989014 142.775833,2264.505859 145.140671,2267.081055 
z"
        />
        <path
          fill="#627A90"
          opacity="1.000000"
          stroke="none"
          d="
M125.997314,2349.995117 
	C126.205467,2346.743896 128.594635,2344.902344 131.171738,2344.761719 
	C135.523087,2344.524170 140.049744,2343.557617 145.781982,2345.284912 
	C139.354080,2349.239502 133.001053,2347.783936 126.810364,2349.983398 
	C126.005798,2350.009766 126.000000,2350.000000 125.997314,2349.995117 
z"
        />
        <path
          fill="#61798F"
          opacity="1.000000"
          stroke="none"
          d="
M788.708862,2265.669434 
	C785.883972,2265.978027 783.765198,2265.965576 780.854370,2265.928223 
	C780.041626,2263.796387 780.020935,2261.690186 780.000122,2258.791992 
	C782.133850,2257.993408 784.267761,2257.986572 787.201782,2257.975098 
	C791.736389,2259.804932 792.072937,2262.290039 788.708862,2265.669434 
z"
        />
        <path
          fill="#71889C"
          opacity="1.000000"
          stroke="none"
          d="
M473.028320,2283.957031 
	C471.352814,2283.556885 470.145538,2283.421875 469.657318,2282.758057 
	C469.082764,2281.976807 469.616272,2280.596924 470.613800,2280.491455 
	C475.297394,2279.996338 480.062073,2279.157227 485.834961,2281.675781 
	C481.415100,2285.116455 477.408356,2283.524170 473.028320,2283.957031 
z"
        />
        <path
          fill="#728BA1"
          opacity="1.000000"
          stroke="none"
          d="
M734.397522,2273.173584 
	C740.787415,2272.503174 747.249695,2272.503174 753.711914,2272.503174 
	C747.336609,2273.745850 741.203491,2278.678467 734.397522,2273.173584 
z"
        />
        <path
          fill="#748B9F"
          opacity="1.000000"
          stroke="none"
          d="
M134.710999,2252.319336 
	C139.384491,2247.217285 144.525711,2252.232910 149.787918,2252.437988 
	C149.831726,2252.439697 149.977020,2253.097656 149.850800,2253.324463 
	C149.712875,2253.572021 149.313644,2253.673828 148.500427,2253.934326 
	C143.771225,2253.739502 139.419296,2254.808350 134.710999,2252.319336 
z"
        />
        <path
          fill="#71889C"
          opacity="1.000000"
          stroke="none"
          d="
M165.240829,2265.994873 
	C166.509933,2266.977783 167.029465,2267.965088 167.776443,2269.461670 
	C164.475143,2269.477051 161.091064,2270.848877 157.390472,2271.283691 
	C159.153641,2266.924561 159.153641,2266.924561 165.240829,2265.994873 
z"
        />
        <path
          fill="#71889C"
          opacity="1.000000"
          stroke="none"
          d="
M417.195618,2266.270020 
	C420.185272,2266.072021 422.539825,2265.669678 425.204865,2267.548828 
	C422.116455,2270.973877 418.903839,2270.618896 415.674774,2268.739014 
	C414.949799,2268.316895 415.549225,2266.987305 417.195618,2266.270020 
z"
        />
        <path
          fill="#71889C"
          opacity="1.000000"
          stroke="none"
          d="
M178.627441,2269.918945 
	C178.001389,2268.991699 177.942383,2268.005371 178.044098,2267.035645 
	C178.206955,2265.483154 177.886948,2263.419189 179.996796,2263.090332 
	C182.450577,2262.708008 183.221497,2264.705811 183.918030,2267.298828 
	C182.429031,2268.602783 180.847824,2269.234863 178.627441,2269.918945 
z"
        />
        <path
          fill="#627A90"
          opacity="1.000000"
          stroke="none"
          d="
M571.271240,2398.059082 
	C569.080872,2403.005371 568.147156,2403.122559 562.257874,2398.694336 
	C564.726807,2398.078857 567.571960,2398.052490 571.271240,2398.059082 
z"
        />
        <path
          fill="#748B9F"
          opacity="1.000000"
          stroke="none"
          d="
M126.464035,2258.835938 
	C126.355820,2259.192871 125.970245,2259.484619 125.860771,2259.403809 
	C125.194191,2258.910156 125.373291,2258.604004 126.464035,2258.835938 
z"
        />
        <path
          fill="#71889C"
          opacity="1.000000"
          stroke="none"
          d="
M124.957108,2250.925781 
	C125.041588,2250.880859 124.872627,2250.970459 124.957108,2250.925781 
z"
        />
        <path
          fill="#0D1C2A"
          opacity="1.000000"
          stroke="none"
          d="
M2756.139893,3563.278076 
	C2754.454834,3566.663818 2750.839844,3565.208008 2748.321045,3568.697266 
	C2759.506104,3574.284912 2772.931885,3573.416016 2783.326904,3581.322021 
	C2781.251953,3585.261475 2778.104492,3586.239746 2774.288818,3585.653320 
	C2772.010254,3585.303223 2769.805908,3584.362793 2767.525635,3584.156494 
	C2764.549072,3583.886719 2762.167969,3584.520508 2763.206543,3588.588379 
	C2764.529053,3593.768555 2761.352783,3594.365967 2757.539307,3593.456543 
	C2749.799316,3591.611328 2741.998535,3590.524902 2734.084717,3589.615723 
	C2728.833740,3589.012939 2723.919922,3585.474854 2719.023193,3583.324463 
	C2719.975830,3579.096191 2722.778564,3579.998535 2724.820312,3580.136963 
	C2727.427490,3580.314209 2729.965820,3581.363770 2732.576660,3581.677246 
	C2735.091797,3581.978516 2738.367920,3582.944092 2739.308594,3579.448486 
	C2740.089844,3576.545410 2737.191895,3575.718994 2735.161377,3574.720703 
	C2725.365723,3569.903809 2715.364990,3565.721924 2704.375732,3564.411377 
	C2700.000977,3563.889160 2696.111816,3564.068604 2692.000488,3566.481445 
	C2686.896973,3569.476807 2681.893066,3570.389160 2675.272949,3567.454102 
	C2667.831787,3564.155029 2659.419678,3562.578125 2650.305176,3566.324463 
	C2640.468750,3570.366943 2629.321533,3568.590576 2618.852051,3567.089355 
	C2612.627197,3566.196777 2605.473633,3566.710938 2601.217041,3559.931396 
	C2599.969971,3557.945557 2597.066162,3558.644531 2594.712646,3559.406738 
	C2588.186035,3561.520508 2581.707520,3558.187744 2575.121338,3559.016113 
	C2573.500732,3559.219971 2572.621582,3557.099609 2572.063477,3554.750000 
	C2573.926270,3551.477539 2576.739990,3549.091064 2570.771484,3547.554443 
	C2563.593750,3545.706787 2556.048096,3544.312988 2550.409912,3537.596680 
	C2549.006592,3533.838379 2548.998291,3530.757568 2550.526123,3526.998535 
	C2551.628418,3525.521484 2552.504150,3525.022705 2554.297119,3524.858154 
	C2558.363037,3526.336670 2560.576660,3530.182861 2565.621094,3529.575684 
	C2568.243896,3530.127441 2570.079590,3530.808105 2572.646729,3531.669678 
	C2584.960449,3533.666992 2596.505371,3536.081055 2609.148438,3535.115234 
	C2611.545410,3535.046631 2613.142334,3535.142822 2615.528809,3535.535645 
	C2617.921143,3536.155273 2619.653076,3537.018066 2621.191650,3536.373779 
	C2642.860352,3527.301514 2665.208740,3537.876953 2687.253662,3533.991699 
	C2698.273926,3532.049561 2707.950439,3540.329590 2718.039307,3544.492432 
	C2727.534912,3548.410156 2736.270996,3554.101074 2746.656494,3555.958496 
	C2750.543701,3556.653320 2754.240234,3558.567139 2756.139893,3563.278076 
z"
        />
        <path
          fill="#1C2E3E"
          opacity="1.000000"
          stroke="none"
          d="
M2756.828125,3564.073486 
	C2750.814453,3560.493164 2745.296631,3558.425537 2738.821777,3557.482910 
	C2730.617188,3556.288086 2724.249023,3549.852783 2716.365967,3547.152344 
	C2707.356934,3544.066162 2699.503662,3538.042969 2689.721436,3536.803223 
	C2687.395264,3536.508301 2685.226807,3536.179199 2682.871094,3536.629150 
	C2670.343506,3539.022461 2657.742188,3539.226074 2645.437012,3535.796143 
	C2639.497070,3534.140381 2634.322754,3534.519287 2629.374023,3537.791504 
	C2625.393066,3540.424561 2621.362549,3540.525635 2616.538574,3538.504883 
	C2614.522217,3534.083008 2616.461914,3530.743164 2618.155029,3526.751953 
	C2626.752197,3517.640625 2635.916504,3522.547119 2644.816406,3524.222900 
	C2649.773926,3525.156250 2654.863770,3528.251953 2659.399658,3523.753906 
	C2659.298828,3522.392090 2658.993652,3521.686279 2658.323975,3521.543945 
	C2635.217773,3516.631104 2614.363281,3504.485352 2590.947998,3500.575684 
	C2590.291260,3500.465820 2589.593262,3500.425537 2588.999756,3500.153809 
	C2579.186768,3495.658691 2569.427490,3492.739746 2558.893311,3498.502686 
	C2556.452393,3499.837891 2552.992432,3498.934570 2549.385254,3497.586914 
	C2546.239746,3495.458008 2545.458252,3492.858398 2545.123047,3489.190430 
	C2545.175781,3486.066162 2545.223877,3483.792480 2546.447266,3480.885986 
	C2547.970459,3478.846191 2549.436279,3478.152100 2551.827148,3477.600098 
	C2556.052490,3477.006836 2559.325928,3479.078369 2563.523926,3478.086914 
	C2565.671875,3477.818359 2567.094238,3477.908203 2569.172119,3478.384277 
	C2573.714600,3481.138672 2577.628418,3482.768066 2582.406738,3478.749512 
	C2589.711914,3477.164795 2595.761963,3479.520508 2600.879150,3483.010986 
	C2608.974365,3488.532471 2618.142822,3491.263916 2627.023682,3494.763428 
	C2632.927246,3497.089355 2638.566406,3496.591309 2645.134766,3495.041260 
	C2657.895264,3492.028809 2670.437012,3496.707764 2683.345215,3501.076904 
	C2687.822266,3502.611816 2691.774170,3502.831299 2696.401855,3503.399902 
	C2705.227051,3503.343506 2713.298828,3503.488770 2721.869629,3505.931641 
	C2725.149902,3506.093018 2727.485596,3506.854492 2730.091309,3508.886230 
	C2731.127930,3510.246094 2731.501221,3511.138916 2731.802979,3512.793701 
	C2731.728760,3523.043213 2739.918701,3517.212402 2744.201904,3520.051758 
	C2745.281982,3520.546387 2745.691895,3520.799805 2746.586426,3521.599609 
	C2747.608154,3530.851562 2746.099121,3532.520508 2738.278809,3531.498291 
	C2734.312988,3530.979980 2730.354248,3530.023438 2726.859375,3528.104736 
	C2712.541504,3520.244141 2696.719727,3518.566406 2679.404785,3516.256836 
	C2693.292725,3519.060303 2705.909668,3520.604736 2713.233154,3533.301025 
	C2715.314209,3536.908691 2720.220215,3538.425781 2724.513428,3539.115234 
	C2738.582275,3541.373779 2752.538574,3544.727539 2766.965088,3543.928467 
	C2771.979980,3543.650635 2776.791748,3545.303467 2782.452148,3546.016113 
	C2783.603760,3546.561768 2784.040771,3546.832275 2785.030029,3547.642578 
	C2788.960205,3554.504883 2795.030029,3554.524658 2801.016602,3554.830078 
	C2807.207031,3555.145508 2813.701416,3554.229980 2819.454102,3559.464844 
	C2824.588867,3559.900879 2828.417969,3561.632324 2831.597168,3564.809570 
	C2832.505859,3565.717285 2833.430664,3566.796387 2832.808105,3568.289062 
	C2832.186523,3569.779053 2830.754150,3570.000977 2829.502441,3569.831055 
	C2823.961182,3569.078369 2818.437500,3568.193115 2812.918457,3567.287598 
	C2811.295898,3567.021240 2809.544922,3565.885254 2808.134033,3566.246582 
	C2798.792480,3568.638672 2789.232910,3564.500977 2779.875977,3567.542236 
	C2774.181641,3569.393311 2768.866943,3566.479492 2763.496338,3564.900146 
	C2761.624512,3564.349854 2759.598389,3564.325195 2756.828125,3564.073486 
z"
        />
        <path
          fill="#935F47"
          opacity="1.000000"
          stroke="none"
          d="
M2547.104980,3651.127441 
	C2547.361328,3656.751221 2547.578369,3661.515869 2547.962891,3667.165039 
	C2548.130371,3668.050049 2547.816650,3667.799805 2547.620117,3667.759766 
	C2544.352051,3676.455811 2546.868164,3685.516602 2545.779541,3695.158936 
	C2545.643066,3698.004395 2545.600586,3700.064209 2545.371582,3702.875488 
	C2544.224121,3705.139160 2542.758789,3705.478027 2540.370117,3705.161865 
	C2533.285156,3697.983154 2530.647705,3690.192871 2532.310303,3680.542725 
	C2533.701660,3672.467773 2534.049561,3664.270996 2536.145264,3656.212646 
	C2538.881592,3645.690186 2527.326660,3631.924561 2517.091064,3627.919189 
	C2515.164307,3627.165039 2513.342041,3628.757324 2510.961914,3630.100586 
	C2501.281494,3638.036133 2498.205566,3649.003906 2497.875977,3659.469482 
	C2497.343750,3676.363525 2493.357422,3693.103271 2495.288574,3710.047363 
	C2497.457031,3729.071289 2487.406982,3745.431641 2484.089600,3763.250732 
	C2483.494873,3766.445801 2481.802979,3769.470703 2481.063477,3773.510742 
	C2480.802979,3774.747070 2480.710205,3775.238037 2480.441406,3776.444336 
	C2479.859131,3778.527100 2479.371826,3779.868408 2478.875244,3781.923584 
	C2478.509033,3783.972168 2478.072266,3785.271240 2476.877441,3786.969238 
	C2471.699707,3787.961426 2470.254883,3791.794922 2468.182617,3795.212646 
	C2461.587158,3806.089600 2453.786133,3815.819336 2441.703857,3821.121338 
	C2439.217041,3822.212646 2437.226318,3823.742676 2435.828369,3826.197021 
	C2434.355713,3828.781494 2432.573975,3831.243408 2428.526367,3831.611328 
	C2426.484619,3830.137695 2426.872559,3828.480713 2427.211914,3826.255371 
	C2427.642578,3823.888428 2426.740723,3822.765869 2424.777588,3821.566650 
	C2421.967041,3819.588867 2419.710449,3817.952148 2419.674805,3814.181641 
	C2420.728516,3812.898193 2421.722656,3812.137939 2422.603271,3812.361572 
	C2430.679443,3814.411865 2438.786133,3814.308350 2445.203613,3808.807861 
	C2454.066895,3801.210938 2460.576660,3791.509277 2466.290039,3781.312256 
	C2469.929688,3774.816162 2462.923828,3770.173584 2463.666504,3763.555664 
	C2464.291504,3759.687500 2466.153564,3756.996338 2467.274414,3753.297852 
	C2467.633301,3752.216797 2467.795654,3751.790039 2468.267822,3750.748535 
	C2468.840332,3749.754883 2469.102539,3749.375977 2469.836914,3748.490967 
	C2476.256592,3744.036865 2473.932373,3738.360840 2473.398438,3732.369141 
	C2473.387451,3730.278320 2473.645020,3728.923584 2474.303711,3726.961670 
	C2476.267578,3723.848389 2478.605469,3721.524902 2476.518555,3717.412598 
	C2476.371094,3715.736084 2476.457031,3714.767822 2476.883545,3713.084717 
	C2483.866455,3696.213623 2485.978516,3679.509277 2483.140869,3661.426758 
	C2483.144531,3659.074707 2483.150391,3657.506348 2483.545898,3655.188477 
	C2487.513428,3645.694092 2484.357422,3634.913574 2492.226562,3626.717285 
	C2495.646240,3624.596924 2497.667969,3622.620605 2499.428223,3619.431885 
	C2506.407715,3606.788818 2513.637451,3594.696533 2509.677734,3579.041504 
	C2508.422363,3574.079102 2512.404053,3569.486816 2517.999756,3568.272217 
	C2523.925537,3566.986328 2527.218018,3570.612549 2529.564697,3575.475098 
	C2530.563965,3577.546631 2531.011230,3579.901855 2532.746826,3581.721680 
	C2536.975586,3568.615479 2532.132568,3556.143066 2530.304688,3542.742920 
	C2530.172607,3540.968506 2530.307861,3539.957031 2530.942871,3538.250000 
	C2532.344238,3536.271240 2533.686768,3535.436768 2536.014893,3534.767822 
	C2543.235840,3535.546875 2542.823242,3540.957520 2543.464600,3545.312256 
	C2544.910645,3555.127197 2544.593506,3565.146729 2547.057861,3575.591553 
	C2549.342529,3577.945068 2549.245850,3580.097412 2548.731689,3582.969238 
	C2548.716553,3605.852295 2545.008057,3627.994385 2547.104980,3651.127441 
z"
        />
        <path
          fill="#2F4457"
          opacity="1.000000"
          stroke="none"
          d="
M2919.168457,3548.025879 
	C2922.153320,3551.935791 2921.947754,3555.049072 2916.831055,3555.823242 
	C2910.231934,3556.821533 2903.669678,3558.808838 2896.415527,3557.666504 
	C2895.070557,3558.144043 2894.194824,3558.096680 2892.661621,3558.020752 
	C2886.007568,3553.788086 2878.154053,3554.713135 2872.008789,3550.615234 
	C2869.433350,3548.897705 2867.248047,3551.716553 2864.581543,3553.542480 
	C2851.896484,3552.614258 2839.827881,3551.396973 2826.885010,3550.191406 
	C2822.849365,3549.771484 2819.840332,3550.080322 2816.391602,3552.291016 
	C2805.282959,3553.516602 2795.169434,3552.599121 2784.708496,3548.461914 
	C2784.048096,3547.908936 2783.994873,3547.994873 2784.040039,3547.973633 
	C2782.474854,3545.963867 2779.670410,3546.252686 2777.457031,3544.041016 
	C2776.462402,3540.361816 2778.182373,3538.603027 2781.234375,3536.871582 
	C2784.672363,3535.902588 2787.245361,3536.592041 2790.560059,3537.601074 
	C2802.326416,3539.023438 2813.170410,3536.485107 2824.883545,3536.068848 
	C2827.718750,3535.358398 2829.813965,3535.369385 2832.660156,3535.730713 
	C2838.108154,3537.470703 2842.698242,3539.400146 2847.496338,3540.770264 
	C2851.215576,3541.832275 2854.523438,3544.256104 2857.597168,3538.836426 
	C2859.058594,3536.259521 2863.715576,3537.253662 2867.030029,3537.111572 
	C2881.928467,3536.472168 2895.842529,3539.919434 2909.473145,3547.408447 
	C2912.799561,3547.897949 2915.568604,3547.960205 2919.168457,3548.025879 
z"
        />
        <path
          fill="#5E4237"
          opacity="1.000000"
          stroke="none"
          d="
M2547.622559,3575.374023 
	C2541.955566,3573.542480 2543.061768,3567.851807 2542.208740,3563.409424 
	C2540.814697,3556.147217 2540.606201,3548.654785 2539.955078,3541.254395 
	C2539.779785,3539.260742 2539.717773,3537.293457 2536.836426,3536.097900 
	C2534.837158,3534.921387 2534.411621,3533.553223 2534.415527,3531.302734 
	C2537.624268,3522.875244 2541.137451,3515.224854 2537.885986,3505.890381 
	C2537.248291,3503.228271 2536.999512,3501.246094 2537.032715,3498.452148 
	C2535.118408,3490.954346 2535.699463,3483.871582 2533.746582,3476.319824 
	C2532.992432,3473.247070 2532.782959,3470.958984 2533.040771,3467.749268 
	C2533.520508,3447.501709 2531.676025,3428.108887 2534.259277,3407.938965 
	C2535.171387,3403.604736 2535.486084,3400.012451 2538.552490,3396.535156 
	C2539.729004,3395.745850 2540.272461,3395.556152 2541.629883,3395.642578 
	C2546.099609,3400.648438 2543.312500,3405.619873 2542.978271,3411.332031 
	C2542.985596,3413.281006 2542.980469,3414.396973 2542.948975,3416.356201 
	C2542.461670,3432.479980 2545.725586,3447.556641 2545.709717,3463.589844 
	C2547.329834,3468.326172 2548.452637,3472.338867 2548.038330,3477.314453 
	C2546.325928,3480.928223 2545.182129,3483.867676 2547.449951,3487.617676 
	C2547.909668,3490.466797 2547.583252,3492.828613 2549.079590,3495.570312 
	C2554.636963,3500.759521 2553.313477,3506.799072 2553.032227,3513.460449 
	C2552.920898,3517.666992 2553.614014,3521.072266 2552.019043,3525.126953 
	C2551.713135,3529.369385 2551.677002,3532.820557 2551.616699,3537.133545 
	C2552.720459,3541.601807 2553.481934,3545.185547 2550.376953,3549.117676 
	C2548.934326,3557.917236 2547.208984,3566.097412 2547.622559,3575.374023 
z"
        />
        <path
          fill="#0D1C2A"
          opacity="1.000000"
          stroke="none"
          d="
M2661.595703,3585.130859 
	C2672.393799,3585.368652 2681.574951,3590.102051 2691.523926,3591.036377 
	C2695.115234,3591.373535 2698.752930,3591.791504 2702.373535,3592.147949 
	C2705.368652,3592.442871 2707.382324,3594.004639 2707.726318,3597.087402 
	C2707.964355,3599.218750 2707.357666,3601.674316 2704.702881,3600.982910 
	C2694.950928,3598.443848 2684.880615,3601.216553 2675.000488,3598.465576 
	C2668.933105,3596.776367 2663.268066,3594.278809 2657.490967,3592.078857 
	C2653.841309,3590.688965 2650.694336,3588.045410 2646.487061,3587.881836 
	C2645.246094,3587.833740 2643.547363,3587.437256 2642.885498,3586.569824 
	C2638.289307,3580.544922 2630.641357,3579.010986 2624.424561,3573.773193 
	C2628.723633,3570.811768 2632.516113,3573.668213 2635.674561,3574.518066 
	C2642.351562,3576.314697 2648.484619,3579.953613 2655.637451,3580.407715 
	C2658.171875,3580.568848 2659.347168,3583.016846 2661.595703,3585.130859 
z"
        />
        <path
          fill="#102131"
          opacity="1.000000"
          stroke="none"
          d="
M2784.033447,3548.718262 
	C2794.878418,3546.836670 2805.406250,3550.540283 2817.103027,3549.962402 
	C2818.755127,3550.116455 2819.371338,3550.485352 2819.978027,3551.629395 
	C2820.076904,3554.252197 2820.046143,3556.332520 2820.022461,3559.191650 
	C2812.026611,3558.150879 2803.914551,3557.943848 2795.736328,3557.850830 
	C2787.515869,3557.757324 2785.675537,3556.090088 2784.033447,3548.718262 
z"
        />
        <path
          fill="#0D1C2A"
          opacity="1.000000"
          stroke="none"
          d="
M2593.136963,3586.822998 
	C2585.208984,3584.291748 2577.965820,3582.200195 2570.748535,3578.170166 
	C2574.354492,3574.706299 2578.285400,3575.730469 2581.755615,3577.028564 
	C2590.463135,3580.285889 2599.823730,3581.166016 2608.430176,3584.912598 
	C2612.114014,3586.516602 2616.583252,3586.316650 2621.163330,3587.006592 
	C2614.432373,3593.518555 2606.506836,3593.398193 2593.136963,3586.822998 
z"
        />
        <path
          fill="#0C1A26"
          opacity="1.000000"
          stroke="none"
          d="
M2685.773926,3632.523926 
	C2684.607910,3630.142090 2684.805176,3628.352051 2687.049316,3628.486328 
	C2699.905518,3629.257568 2712.018066,3632.994629 2723.707275,3638.222412 
	C2724.189209,3638.437988 2724.410156,3639.237549 2724.775146,3639.800537 
	C2723.691406,3642.850098 2721.000000,3642.006592 2718.927979,3641.935303 
	C2707.356201,3641.536621 2696.100342,3639.811523 2685.773926,3632.523926 
z"
        />
        <path
          fill="#1C2E3E"
          opacity="1.000000"
          stroke="none"
          d="
M2550.630371,3549.796875 
	C2550.054443,3546.155029 2549.271729,3542.559570 2551.120117,3538.565186 
	C2559.502441,3540.960938 2567.258057,3544.401123 2575.627197,3545.971680 
	C2577.893555,3546.396973 2580.571533,3546.594238 2580.905518,3549.358154 
	C2581.264404,3552.331055 2578.289551,3552.664795 2576.326904,3553.571533 
	C2575.479980,3553.962402 2574.408203,3553.866211 2572.700684,3554.027832 
	C2565.124268,3552.684326 2558.285645,3551.304443 2550.630371,3549.796875 
z"
        />
        <path
          fill="#5E4237"
          opacity="1.000000"
          stroke="none"
          d="
M2547.766113,3651.889160 
	C2544.231201,3651.211670 2544.078857,3648.889404 2544.070068,3646.491699 
	C2544.044434,3639.537842 2543.008057,3632.401123 2544.219971,3625.669678 
	C2546.414795,3613.477783 2545.775146,3601.296631 2546.007080,3589.094238 
	C2546.039062,3587.408203 2546.233887,3585.798096 2548.014648,3584.056152 
	C2551.358154,3594.881348 2550.249756,3606.206055 2549.145752,3617.527344 
	C2548.925781,3619.781982 2548.592773,3622.025879 2548.419922,3625.117676 
	C2550.961670,3629.306152 2551.378174,3632.808105 2548.789551,3637.252930 
	C2547.351807,3642.148193 2549.905762,3645.909180 2549.582031,3650.718750 
	C2549.195312,3651.561523 2548.791504,3651.676514 2547.766113,3651.889160 
z"
        />
        <path
          fill="#0C1A26"
          opacity="1.000000"
          stroke="none"
          d="
M2549.057129,3638.003418 
	C2548.215088,3634.556885 2548.194824,3631.107910 2548.351074,3626.809570 
	C2556.459717,3625.989746 2563.907715,3627.806396 2572.148682,3631.877197 
	C2564.706543,3637.205566 2557.143799,3637.089844 2549.057129,3638.003418 
z"
        />
        <path
          fill="#1C2E3E"
          opacity="1.000000"
          stroke="none"
          d="
M2864.523926,3554.409180 
	C2865.985840,3545.606934 2868.909180,3543.920898 2874.898438,3548.255615 
	C2877.943848,3550.459717 2880.764404,3552.037354 2884.586182,3552.219727 
	C2887.523438,3552.359619 2890.370605,3553.444580 2891.869385,3557.276367 
	C2882.857666,3558.535156 2874.041992,3556.118896 2864.523926,3554.409180 
z"
        />
        <path
          fill="#1C2E3E"
          opacity="1.000000"
          stroke="none"
          d="
M2820.758789,3552.127441 
	C2819.567627,3551.746338 2819.027588,3551.320312 2818.243652,3550.437500 
	C2820.142090,3547.546143 2822.582031,3547.614746 2825.602051,3549.664551 
	C2824.868164,3551.786133 2823.170410,3552.010498 2820.758789,3552.127441 
z"
        />
        <path
          fill="#010A16"
          opacity="1.000000"
          stroke="none"
          d="
M1093.382568,3922.524902 
	C1089.486938,3926.455078 1085.301880,3929.154541 1079.709961,3930.562988 
	C1077.571899,3929.972412 1075.908447,3928.429932 1074.585571,3928.681152 
	C1057.583252,3931.910889 1041.166504,3925.262207 1024.311401,3925.898438 
	C1015.679138,3926.224365 1007.316284,3928.867920 998.558472,3928.102295 
	C992.946228,3927.612061 987.277954,3928.324463 982.079834,3925.193115 
	C979.111694,3923.405518 975.524475,3923.505859 972.333496,3924.406250 
	C969.055359,3925.331543 965.801514,3927.369385 967.035095,3931.492432 
	C968.163391,3935.262939 967.267029,3937.877686 964.606750,3940.417236 
	C961.714539,3943.178467 959.154053,3946.316162 954.529907,3946.011230 
	C947.100098,3945.521484 941.400330,3950.223877 935.034912,3952.835449 
	C930.817505,3954.565430 930.141174,3958.508545 929.896240,3962.806396 
	C929.502197,3969.719482 929.228577,3969.976318 922.170715,3969.918457 
	C915.109192,3969.860596 908.423401,3970.948242 901.595215,3972.914551 
	C892.902405,3975.418213 883.747070,3973.876953 874.786438,3973.706055 
	C873.866943,3973.688721 872.679749,3973.239502 872.092224,3972.571777 
	C867.518555,3967.375244 861.452271,3967.718994 855.459534,3968.033691 
	C848.951233,3968.375732 843.803650,3967.314453 841.497009,3959.886963 
	C839.491211,3953.428223 833.408569,3952.974854 828.162109,3954.252197 
	C816.275513,3957.146973 804.301453,3955.427979 792.385742,3956.017822 
	C788.652710,3956.202637 785.103333,3955.553467 780.813477,3954.107666 
	C780.283508,3950.393799 783.235168,3950.573975 785.686279,3949.609619 
	C793.386230,3946.580078 795.242920,3944.032959 793.414978,3935.917969 
	C792.185608,3930.460449 794.122986,3928.944336 798.592590,3927.402588 
	C802.891541,3925.920166 807.833130,3924.104492 807.479858,3918.153809 
	C807.238098,3914.081543 802.796753,3912.043701 795.395264,3912.007324 
	C793.731445,3911.999023 791.950745,3911.669189 790.421814,3912.135986 
	C777.695923,3916.022705 765.612061,3910.273193 753.189880,3909.773193 
	C751.103149,3909.689209 749.135193,3908.642090 748.980164,3906.314209 
	C748.779907,3903.309082 751.252686,3901.846436 753.529358,3902.255127 
	C765.058289,3904.324463 773.949585,3894.254639 785.326233,3895.590576 
	C786.927124,3895.778809 788.758362,3893.819336 790.513733,3892.913330 
	C795.866821,3890.150879 801.291260,3888.647949 807.577759,3889.379639 
	C812.672485,3889.973389 817.979004,3889.109619 823.155518,3888.555420 
	C831.151001,3887.699463 838.351685,3887.172607 845.298706,3893.654541 
	C852.594910,3900.462158 875.614563,3896.000977 881.744751,3887.722656 
	C882.960327,3886.081055 883.685608,3884.050537 885.993469,3884.151855 
	C897.512756,3884.655518 909.298828,3879.020020 920.514587,3887.797852 
	C925.979492,3892.074463 934.612793,3890.413086 941.257751,3893.731201 
	C942.989014,3894.595947 945.174011,3893.449463 946.948242,3892.469727 
	C957.187683,3886.815674 968.322693,3885.949463 979.726685,3885.920654 
	C986.988953,3885.902344 994.327087,3886.976318 1001.527466,3884.618896 
	C1004.886230,3883.519531 1008.308533,3885.278564 1010.965393,3886.940674 
	C1014.539307,3889.177002 1018.383667,3889.462158 1022.159790,3890.554688 
	C1025.984131,3891.661377 1029.082153,3891.340820 1032.657104,3889.818115 
	C1038.470215,3887.341553 1044.777222,3886.566162 1051.049316,3886.056885 
	C1053.343628,3885.870605 1056.306396,3885.416992 1057.894897,3886.564941 
	C1063.570312,3890.666504 1069.875000,3890.163574 1076.134644,3889.934814 
	C1081.331909,3889.744629 1085.238159,3890.996338 1087.105347,3896.365723 
	C1088.983276,3901.766602 1088.161987,3904.304932 1082.558838,3908.865479 
	C1082.301270,3909.075195 1082.005615,3909.242920 1081.767212,3909.471191 
	C1078.928833,3912.192139 1074.800903,3914.677002 1076.616089,3919.309326 
	C1078.371826,3923.789307 1082.979248,3921.396973 1087.181274,3921.984375 
	C1089.746094,3920.477295 1091.521973,3920.296631 1093.382568,3922.524902 
z"
        />
        <path
          fill="#263037"
          opacity="1.000000"
          stroke="none"
          d="
M1086.261963,3670.833984 
	C1088.903076,3667.614258 1087.934082,3664.422852 1088.129272,3660.708496 
	C1087.016235,3658.177246 1086.493408,3656.106934 1086.823364,3654.208740 
	C1089.823608,3636.959229 1087.275513,3619.914307 1083.669312,3603.232178 
	C1081.787476,3594.526611 1087.107544,3587.106201 1085.172607,3578.069824 
	C1085.478027,3573.325684 1083.542236,3569.799072 1083.146240,3565.210205 
	C1082.673584,3561.296143 1082.996216,3558.193604 1083.416016,3554.347656 
	C1084.789429,3550.250244 1084.580811,3547.057373 1082.270508,3543.348145 
	C1081.400146,3535.363037 1086.323486,3528.903076 1085.068726,3520.794678 
	C1084.772705,3516.991699 1084.810669,3513.947998 1084.926880,3510.161377 
	C1085.052002,3507.474121 1085.159790,3505.525391 1084.249268,3502.957031 
	C1083.837280,3500.836426 1083.809937,3499.409424 1083.965698,3497.284668 
	C1084.324463,3494.814209 1085.109497,3493.130371 1085.016113,3490.621582 
	C1084.543213,3487.335449 1083.824097,3484.747803 1084.029541,3481.395508 
	C1084.475464,3478.285400 1085.671875,3475.852783 1084.065674,3472.748291 
	C1083.032837,3470.132324 1082.321411,3468.081055 1081.410645,3465.390625 
	C1077.140869,3457.034424 1070.781982,3459.849365 1064.093750,3461.443359 
	C1061.623291,3462.302979 1059.753906,3462.398193 1057.166748,3462.184082 
	C1052.439453,3460.828369 1048.446655,3460.007324 1044.145264,3463.447998 
	C1042.568726,3464.153564 1041.612793,3464.427734 1039.867554,3464.698975 
	C1027.196655,3465.230469 1015.331726,3464.408691 1003.495300,3463.320801 
	C1000.306763,3463.027832 996.327820,3462.511963 995.526245,3458.587646 
	C994.702576,3454.555664 998.391602,3453.015137 1001.178650,3451.326416 
	C1008.771179,3446.725098 1016.312439,3445.179199 1025.704712,3446.860840 
	C1038.068970,3449.074707 1050.145752,3442.853027 1062.822998,3441.745361 
	C1058.235474,3437.161377 1052.463501,3436.236572 1046.861450,3434.877930 
	C1040.430176,3433.318115 1033.758423,3432.490234 1027.856079,3429.234375 
	C1025.162231,3427.748291 1022.913818,3425.542480 1023.175354,3422.176025 
	C1023.471375,3418.366699 1026.616943,3417.768311 1030.440674,3417.239014 
	C1032.801392,3417.163330 1034.374268,3417.145020 1036.699219,3417.504883 
	C1040.300659,3418.623047 1043.182129,3419.353027 1046.954224,3418.992188 
	C1053.804077,3417.159668 1060.062988,3416.724121 1066.389160,3417.230957 
	C1073.430420,3417.795410 1077.539917,3414.610352 1079.583252,3407.891113 
	C1083.325806,3395.584229 1086.252563,3383.592773 1077.067017,3372.069580 
	C1073.538452,3367.642822 1069.506714,3364.787598 1063.410645,3363.484863 
	C1056.744019,3364.167480 1049.782593,3359.800293 1044.268555,3366.997559 
	C1042.771118,3367.900879 1041.825317,3368.245605 1040.060181,3368.571777 
	C1037.147217,3368.772949 1035.038208,3368.626221 1032.247070,3367.645020 
	C1028.014282,3363.918945 1023.554688,3363.520752 1019.003845,3363.063965 
	C1013.584473,3362.520020 1007.941833,3362.367188 1003.440308,3357.319824 
	C1003.735718,3351.023193 1008.532654,3351.555176 1012.133789,3350.544434 
	C1021.252930,3347.984863 1031.029907,3347.673340 1038.342285,3339.918457 
	C1042.092651,3335.940918 1047.714600,3337.727783 1052.532227,3339.392090 
	C1058.480713,3341.446533 1064.183472,3344.325928 1070.635254,3344.651367 
	C1080.522827,3345.150635 1084.507324,3341.180420 1080.652100,3332.239502 
	C1076.750977,3323.192383 1076.456543,3315.556885 1081.210571,3306.955322 
	C1083.853027,3302.174072 1080.588623,3296.514648 1081.051758,3290.468262 
	C1081.058960,3287.158203 1080.726318,3284.610596 1080.993896,3281.307129 
	C1080.731812,3279.244629 1081.097778,3280.874268 1080.850098,3279.564209 
	C1077.100708,3259.728516 1077.067871,3259.930176 1057.104980,3256.680420 
	C1049.916016,3255.509766 1042.362061,3253.868408 1040.745972,3244.671143 
	C1040.478516,3243.148926 1039.563965,3242.281738 1038.083008,3242.257080 
	C1026.897095,3242.070068 1015.742126,3237.372803 1004.516541,3242.075928 
	C1000.203613,3243.882812 995.719543,3245.164551 991.097534,3245.933105 
	C988.792358,3246.316406 986.479370,3246.680664 984.147095,3246.241943 
	C980.890015,3245.629150 977.505005,3241.279785 978.930298,3240.794189 
	C990.377258,3236.891602 966.668823,3221.620850 985.578979,3221.056396 
	C988.248047,3220.976807 991.075378,3220.493896 993.528259,3221.399902 
	C1008.196289,3226.818848 1023.857910,3224.896484 1038.891479,3227.754150 
	C1050.896240,3230.036133 1062.538330,3224.693604 1074.521606,3224.388428 
	C1077.547607,3224.311279 1078.559570,3222.245117 1076.404663,3219.587646 
	C1073.797241,3216.371582 1068.723633,3213.856201 1071.387573,3208.778809 
	C1074.069946,3203.666504 1079.260742,3201.949219 1085.712280,3202.102539 
	C1086.984863,3202.525391 1087.474609,3202.770996 1088.561035,3203.602051 
	C1091.026245,3207.240723 1091.334106,3210.596436 1091.024658,3214.897461 
	C1091.701050,3228.104980 1089.043945,3240.639160 1093.054688,3253.591553 
	C1093.623169,3254.753662 1093.891968,3255.193848 1094.543945,3256.301514 
	C1095.298462,3257.915527 1095.489380,3258.897217 1095.395020,3260.638672 
	C1094.817017,3263.200928 1093.390259,3264.628418 1092.998657,3267.239746 
	C1092.899536,3269.426758 1092.882568,3270.903564 1092.904175,3273.170410 
	C1093.344604,3286.164551 1091.293457,3298.575195 1096.169678,3311.289062 
	C1096.842651,3314.584717 1096.759888,3317.014648 1096.061523,3320.346191 
	C1091.952393,3333.447754 1092.001831,3346.209473 1093.445435,3358.442139 
	C1095.175903,3373.104736 1094.719116,3387.663818 1094.904785,3402.263428 
	C1095.137573,3420.573975 1093.916016,3438.963867 1095.197266,3457.192871 
	C1098.629517,3506.024414 1094.500732,3554.971924 1097.421387,3603.691650 
	C1100.244995,3650.790039 1098.472534,3697.961182 1102.395874,3745.092773 
	C1104.254272,3767.417236 1103.845947,3790.873535 1099.493164,3813.633545 
	C1097.885132,3822.041992 1098.751221,3830.874512 1099.489014,3839.514160 
	C1100.898560,3856.019775 1102.007568,3872.545166 1101.975098,3890.003174 
	C1099.037720,3894.351807 1096.265503,3893.496338 1092.754395,3890.578613 
	C1092.075928,3889.910889 1092.057495,3888.945312 1092.036133,3888.462891 
	C1086.006470,3878.755859 1089.434204,3869.150146 1091.401611,3858.807129 
	C1091.939819,3856.497070 1091.946045,3855.004395 1091.930420,3852.766113 
	C1089.933350,3850.153809 1089.768188,3847.417480 1088.460693,3844.534668 
	C1088.285767,3843.083496 1088.259888,3842.255859 1088.226562,3840.807129 
	C1091.041138,3836.232910 1090.006714,3831.917969 1089.301880,3826.917480 
	C1089.124023,3823.254150 1088.685913,3820.295410 1089.893311,3816.705566 
	C1090.083862,3814.494385 1090.098389,3812.999512 1090.112793,3810.757324 
	C1089.373901,3807.602295 1088.891479,3805.159912 1089.088867,3801.858887 
	C1088.646484,3798.010254 1088.922241,3794.821289 1087.471924,3791.050049 
	C1086.053711,3783.791504 1086.625000,3777.510986 1089.559814,3770.708740 
	C1090.100830,3770.018066 1090.178711,3769.093506 1090.184326,3768.629395 
	C1092.032471,3766.026855 1092.449585,3763.693604 1090.827393,3760.567139 
	C1090.066528,3758.762207 1089.949219,3757.471680 1090.001831,3755.510742 
	C1090.235840,3753.128906 1090.394531,3751.398193 1089.997070,3748.979248 
	C1089.835693,3747.342529 1089.893921,3746.395996 1090.191895,3744.722412 
	C1091.251587,3739.762451 1091.447998,3735.686523 1089.468750,3731.552734 
	C1085.762573,3723.811768 1086.235962,3715.676514 1088.030396,3706.752441 
	C1088.154785,3703.860840 1088.143677,3701.737305 1088.134033,3698.817383 
	C1086.991455,3691.837402 1084.014526,3685.825439 1086.358154,3678.631836 
	C1086.296631,3675.842285 1086.256592,3673.733398 1086.261963,3670.833984 
z"
        />
        <path
          fill="#0C1A26"
          opacity="1.000000"
          stroke="none"
          d="
M1091.218994,3858.164062 
	C1091.971191,3867.366455 1092.008667,3876.743652 1092.030273,3887.050537 
	C1086.213501,3885.423828 1083.085815,3881.730469 1085.307739,3874.839600 
	C1087.076172,3869.355469 1086.053833,3862.998047 1091.218994,3858.164062 
z"
        />
        <path
          fill="#263037"
          opacity="1.000000"
          stroke="none"
          d="
M1091.543213,3910.774902 
	C1092.074097,3906.653076 1092.120239,3903.279785 1092.177979,3899.063965 
	C1093.950317,3896.346436 1096.096680,3895.852783 1099.336914,3896.835449 
	C1100.512085,3897.667236 1100.897095,3898.111572 1101.639404,3899.363770 
	C1102.469727,3906.079102 1101.323853,3911.736328 1098.749756,3917.916992 
	C1092.153809,3919.869385 1090.814941,3916.492432 1091.543213,3910.774902 
z"
        />
        <path
          fill="#0C1A26"
          opacity="1.000000"
          stroke="none"
          d="
M1091.400146,3910.123047 
	C1091.963745,3913.364746 1092.334961,3916.478027 1096.742065,3917.955322 
	C1098.428833,3918.745117 1098.810181,3919.687500 1097.904419,3921.402832 
	C1096.322510,3922.356445 1095.389771,3922.794678 1093.931152,3923.251221 
	C1092.237671,3922.061523 1090.714966,3922.029541 1088.605957,3921.988281 
	C1090.133423,3918.339355 1087.213135,3913.520996 1091.400146,3910.123047 
z"
        />
        <path
          fill="#0C1A26"
          opacity="1.000000"
          stroke="none"
          d="
M1099.845459,3897.452148 
	C1097.232178,3898.008301 1095.392822,3898.062988 1092.871460,3898.169678 
	C1092.133911,3896.004639 1092.078369,3893.787354 1092.049316,3890.740723 
	C1094.097290,3890.574951 1096.118530,3891.239014 1098.779541,3891.981934 
	C1101.240356,3893.342041 1101.309570,3895.053223 1099.845459,3897.452148 
z"
        />
        <path
          fill="#0C1A26"
          opacity="1.000000"
          stroke="none"
          d="
M1087.785156,3844.249512 
	C1092.270996,3844.611084 1091.955933,3847.668945 1091.939819,3851.282227 
	C1089.536377,3852.020264 1088.292236,3850.342041 1086.503296,3848.362793 
	C1086.419556,3846.862793 1086.839111,3845.725342 1087.785156,3844.249512 
z"
        />
        <path
          fill="#010611"
          opacity="1.000000"
          stroke="none"
          d="
M1065.305664,3805.371338 
	C1066.120483,3808.765625 1068.011841,3810.074463 1070.202026,3811.082275 
	C1072.971558,3812.356445 1075.277588,3814.197510 1077.376831,3816.396240 
	C1080.107056,3819.256348 1080.919189,3822.452393 1077.697998,3825.060791 
	C1072.676636,3829.126953 1072.387451,3832.904297 1075.491943,3838.631104 
	C1077.865112,3843.009033 1074.451294,3849.069336 1069.727661,3849.483643 
	C1056.495483,3850.644043 1043.203979,3850.877441 1031.223877,3843.519531 
	C1029.822510,3842.658936 1028.492432,3841.565918 1027.399536,3840.339844 
	C1022.799988,3835.182129 1017.016724,3833.389893 1010.306702,3833.997803 
	C1004.866150,3834.490723 999.673889,3833.361084 994.742676,3831.207275 
	C988.945618,3828.675537 988.859558,3825.989014 994.025452,3821.965576 
	C1000.156311,3817.190430 1007.526245,3816.664062 1014.728210,3816.125244 
	C1021.430786,3815.624023 1027.947876,3814.937988 1033.828491,3811.450928 
	C1037.561157,3809.237549 1041.636353,3810.414795 1045.546509,3809.961914 
	C1048.972046,3809.565674 1053.021606,3811.557617 1055.800293,3806.786133 
	C1058.536743,3803.305908 1061.468506,3803.280762 1065.305664,3805.371338 
z"
        />
        <path
          fill="#05131D"
          opacity="1.000000"
          stroke="none"
          d="
M372.017334,3977.602051 
	C380.305298,3974.753418 388.029694,3976.067139 395.611786,3976.176025 
	C401.612549,3976.262207 404.943268,3981.064697 407.373169,3985.767334 
	C409.436554,3989.760986 406.683868,3993.788574 401.653778,3993.806641 
	C391.722015,3993.842285 381.658600,3995.391846 371.874969,3992.817627 
	C367.403961,3991.641357 364.064270,3992.492676 361.171082,3995.844727 
	C349.416962,4009.463867 333.484863,4008.311523 317.899994,4007.779297 
	C312.816925,4007.605469 309.520966,4003.866211 308.256439,3998.996826 
	C306.947662,3993.957031 309.763214,3990.939697 314.020050,3988.711426 
	C320.484497,3985.327881 326.808594,3981.382812 334.476532,3982.066406 
	C340.194519,3982.575928 345.129608,3980.074707 349.534760,3977.553711 
	C355.073029,3974.384277 359.886749,3974.277832 365.391083,3976.667725 
	C367.150574,3977.431641 369.242554,3977.429688 372.017334,3977.602051 
z"
        />
        <path
          fill="#05101B"
          opacity="1.000000"
          stroke="none"
          d="
M1028.078857,3805.223633 
	C1021.611755,3808.939697 1014.865967,3810.154297 1007.869080,3809.984619 
	C1001.855774,3809.838867 995.892273,3809.965576 990.127991,3811.979980 
	C981.396362,3815.031494 972.339905,3813.777344 963.393799,3813.975098 
	C960.986511,3814.028564 958.805420,3813.476318 956.953308,3811.787109 
	C954.664734,3809.700684 953.785706,3807.541016 956.155762,3804.941650 
	C958.188599,3802.712158 960.338440,3800.649414 963.172302,3799.432861 
	C968.413269,3797.183105 973.334229,3794.297607 977.347656,3790.280029 
	C985.198669,3782.420410 994.846436,3783.077881 1004.386108,3784.593018 
	C1016.326782,3786.489258 1028.083252,3786.703369 1040.002197,3784.505859 
	C1045.507324,3783.491211 1051.239990,3783.254883 1057.401611,3785.453125 
	C1057.945190,3790.978760 1053.763184,3790.617920 1050.821167,3791.046143 
	C1038.326538,3792.865723 1025.876587,3795.426758 1013.098206,3793.955566 
	C1009.956604,3793.594238 1005.043335,3792.531494 1004.595215,3796.496582 
	C1004.113342,3800.760010 1009.204468,3800.057373 1012.267456,3800.596191 
	C1017.653381,3801.543701 1023.794128,3799.322266 1028.078857,3805.223633 
z"
        />
        <path
          fill="#0C1A26"
          opacity="1.000000"
          stroke="none"
          d="
M1028.786865,3805.962891 
	C1023.983093,3802.416992 1019.123047,3804.500732 1014.654541,3804.041504 
	C1011.065796,3803.673340 1007.353760,3804.512939 1003.899170,3802.846680 
	C1001.193542,3801.542236 998.041321,3800.357910 998.449829,3796.664795 
	C998.857788,3792.977051 1001.519348,3790.848389 1005.072021,3790.264893 
	C1007.330750,3789.893555 1009.933960,3789.471924 1011.963562,3790.216064 
	C1021.879272,3793.851807 1031.574341,3789.720947 1041.307861,3789.334229 
	C1046.306274,3789.135498 1051.819946,3788.865234 1057.421021,3786.554443 
	C1058.885742,3786.016357 1059.771484,3786.022705 1061.321533,3786.033691 
	C1064.870117,3789.070801 1068.487793,3789.676025 1072.537598,3790.136963 
	C1076.650146,3790.605469 1080.016846,3793.239746 1079.312622,3798.156738 
	C1078.697998,3802.447998 1075.508057,3803.909912 1071.457764,3803.954590 
	C1066.212402,3804.012451 1060.967285,3803.980713 1054.860962,3803.992920 
	C1053.110962,3803.989014 1052.222046,3803.988037 1050.666626,3803.988770 
	C1047.777710,3803.998535 1045.555542,3803.995361 1042.666626,3804.006104 
	C1037.649902,3803.315186 1033.752441,3805.527832 1028.786865,3805.962891 
z"
        />
        <path
          fill="#010611"
          opacity="1.000000"
          stroke="none"
          d="
M927.371765,3837.733398 
	C933.046448,3836.474365 937.820496,3835.098633 942.689453,3834.306152 
	C950.306763,3833.066162 953.459839,3836.987061 950.878967,3844.091309 
	C948.195557,3851.477295 946.587524,3852.619629 937.832642,3852.305176 
	C934.811890,3852.196533 932.034546,3853.273438 929.162231,3853.656006 
	C919.131470,3854.993164 911.079285,3848.822754 901.837708,3847.462158 
	C899.828918,3847.166504 898.375610,3845.376709 898.561340,3843.087646 
	C898.771667,3840.495850 900.895935,3839.912109 902.898376,3840.116211 
	C910.937317,3840.935791 918.609070,3838.229980 927.371765,3837.733398 
z"
        />
        <path
          fill="#05101B"
          opacity="1.000000"
          stroke="none"
          d="
M1054.233765,3804.419434 
	C1057.302856,3800.713867 1061.484497,3802.389893 1065.293091,3802.022705 
	C1068.229980,3801.739014 1071.225708,3801.891113 1074.120972,3801.406006 
	C1076.208252,3801.055664 1077.513794,3799.353027 1077.535645,3797.144531 
	C1077.554077,3795.298340 1076.179077,3794.316162 1074.690796,3793.456299 
	C1072.606201,3792.251953 1070.336670,3792.055908 1068.020630,3791.936035 
	C1065.093140,3791.785156 1063.015625,3790.474121 1062.102051,3786.773438 
	C1070.494019,3785.986328 1071.077881,3785.350098 1077.020020,3782.622070 
	C1082.780396,3779.977783 1084.624756,3774.163086 1089.409912,3770.134033 
	C1090.090942,3776.732910 1090.080933,3783.447754 1090.079346,3791.078125 
	C1089.663696,3795.110840 1087.193726,3798.449707 1091.520020,3801.550293 
	C1091.928345,3804.367920 1092.414551,3806.799805 1090.612793,3809.511963 
	C1082.436035,3809.769043 1075.482910,3805.470215 1066.828857,3806.013428 
	C1063.217773,3806.016602 1060.445312,3806.013672 1056.841309,3806.015137 
	C1055.387085,3805.772217 1054.873047,3805.381836 1054.233765,3804.419434 
z"
        />
        <path
          fill="#0C1A26"
          opacity="1.000000"
          stroke="none"
          d="
M1087.512695,3706.320068 
	C1090.035156,3707.745850 1089.945312,3710.056396 1090.025513,3712.357910 
	C1090.270630,3719.399658 1089.128174,3726.443604 1093.410522,3733.143311 
	C1095.307251,3736.111084 1094.505493,3740.809814 1092.343750,3745.256348 
	C1090.596924,3746.898926 1089.580078,3746.173584 1088.330566,3744.569824 
	C1088.025513,3743.098877 1088.023438,3742.217285 1088.022705,3740.674805 
	C1089.521973,3737.339111 1087.374023,3735.291504 1086.406494,3732.485596 
	C1085.760254,3731.380859 1085.376099,3730.870117 1084.423828,3730.233887 
	C1083.714966,3727.464111 1083.848145,3724.790039 1083.063232,3722.419434 
	C1081.024414,3716.262939 1082.139648,3711.079590 1087.512695,3706.320068 
z"
        />
        <path
          fill="#05131D"
          opacity="1.000000"
          stroke="none"
          d="
M641.119873,3872.286621 
	C642.131653,3873.625244 643.238647,3874.783203 642.997681,3875.260742 
	C639.852966,3881.491455 629.475586,3882.562744 625.347229,3877.141357 
	C623.875732,3875.208984 624.133179,3872.918945 626.517273,3872.517822 
	C631.135254,3871.741211 635.717773,3867.920654 641.119873,3872.286621 
z"
        />
        <path
          fill="#05101B"
          opacity="1.000000"
          stroke="none"
          d="
M1087.715088,3744.574707 
	C1089.012817,3744.503174 1089.998291,3745.026367 1091.498779,3745.766113 
	C1092.022461,3746.874512 1092.031006,3747.766357 1092.044678,3749.326904 
	C1092.029541,3750.887451 1092.022461,3751.779297 1092.048462,3753.339844 
	C1092.061279,3755.506104 1092.055908,3757.003418 1092.052734,3759.250000 
	C1091.920776,3760.712158 1091.580200,3761.301270 1090.488525,3761.877930 
	C1081.362305,3756.040283 1080.912964,3753.100098 1087.715088,3744.574707 
z"
        />
        <path
          fill="#05101B"
          opacity="1.000000"
          stroke="none"
          d="
M1089.355713,3816.145508 
	C1092.804810,3818.337402 1091.806519,3821.533203 1091.978760,3825.229980 
	C1090.758911,3826.998047 1090.176758,3828.507324 1088.506836,3829.787109 
	C1087.080566,3829.625732 1085.420898,3829.247314 1085.417969,3828.856445 
	C1085.384644,3824.416748 1084.786621,3819.757324 1089.355713,3816.145508 
z"
        />
        <path
          fill="#0C1A26"
          opacity="1.000000"
          stroke="none"
          d="
M1087.978760,3830.854492 
	C1088.190918,3828.241699 1088.711304,3826.689453 1091.337402,3826.100098 
	C1091.207275,3830.567383 1094.027100,3835.735840 1088.941406,3839.941895 
	C1088.141724,3837.357178 1088.064209,3834.528076 1087.978760,3830.854492 
z"
        />
        <path
          fill="#0C1A26"
          opacity="1.000000"
          stroke="none"
          d="
M1089.984863,3762.778076 
	C1090.232178,3761.381104 1090.631836,3760.863281 1091.614990,3760.227539 
	C1094.560669,3762.801270 1095.080933,3765.458252 1090.836792,3767.989746 
	C1090.124512,3766.625732 1090.058960,3765.085938 1089.984863,3762.778076 
z"
        />
        <path
          fill="#05101B"
          opacity="1.000000"
          stroke="none"
          d="
M1042.442871,3804.503418 
	C1044.072754,3801.561035 1046.495850,3801.581543 1049.558594,3803.495117 
	C1047.928955,3806.448486 1045.504761,3806.410889 1042.442871,3804.503418 
z"
        />
        <path
          fill="#05101B"
          opacity="1.000000"
          stroke="none"
          d="
M1086.007568,3732.700684 
	C1088.876953,3733.613037 1090.932983,3735.566895 1088.504150,3739.599121 
	C1087.015625,3737.912598 1085.878906,3735.850342 1086.007568,3732.700684 
z"
        />
        <path
          fill="#7F98AE"
          opacity="1.000000"
          stroke="none"
          d="
M71.504799,1980.731201 
	C73.287590,1980.114624 74.670151,1979.956543 75.921158,1980.290894 
	C78.911865,1981.089722 81.591759,1981.005005 81.534760,1977.183105 
	C81.483246,1973.728394 78.811623,1974.105103 76.413055,1974.824829 
	C75.120819,1975.212646 73.894798,1975.529419 72.591660,1973.545654 
	C75.445511,1969.384277 77.671097,1965.694702 84.756973,1966.862671 
	C93.824875,1968.357178 96.936493,1964.960449 98.019875,1955.947998 
	C98.691010,1950.364868 103.149406,1945.543945 100.494194,1939.145386 
	C99.336594,1936.355957 105.085251,1935.561401 104.851349,1931.227661 
	C99.365204,1930.039429 95.216583,1927.156860 95.432930,1920.316162 
	C95.483231,1918.725708 93.392906,1917.067505 92.139145,1914.725098 
	C103.695114,1912.115234 115.396797,1912.181030 127.102051,1914.020264 
	C128.733398,1914.276489 130.632736,1914.208374 131.187653,1916.148071 
	C131.954880,1918.829712 129.945908,1919.470337 127.940186,1920.066406 
	C127.013451,1920.341797 125.553406,1920.702393 125.375641,1921.322632 
	C123.875435,1926.555542 120.574493,1928.808716 115.158310,1928.364258 
	C113.722466,1928.246460 112.724327,1929.494263 112.695305,1930.958130 
	C112.666183,1932.427368 113.646797,1933.573364 115.075348,1933.626587 
	C120.058098,1933.812744 125.050049,1933.907471 130.034744,1933.820923 
	C132.518219,1933.777954 133.629456,1932.219604 132.802551,1929.794922 
	C131.150101,1924.949829 134.277802,1921.436523 136.282288,1917.769897 
	C138.331802,1914.020996 141.785568,1911.761353 146.252365,1913.202271 
	C150.561066,1914.592041 155.651947,1912.853149 159.358429,1916.939941 
	C160.833389,1918.566040 162.962540,1917.067505 164.822113,1916.190674 
	C173.666672,1912.019775 182.496414,1911.506226 191.132416,1916.870972 
	C191.415237,1917.046509 191.694901,1917.227783 191.984421,1917.391479 
	C197.554276,1920.540405 202.191559,1912.849854 206.656433,1915.316895 
	C212.005157,1918.272217 216.175232,1918.514771 221.466293,1915.838867 
	C227.660370,1912.706299 234.438660,1912.685181 240.620605,1915.868042 
	C249.886337,1920.638550 258.906982,1920.603027 268.029053,1915.903809 
	C269.245300,1915.277344 270.672363,1914.222168 271.616699,1915.311401 
	C276.365479,1920.789917 282.287781,1917.997559 287.929016,1918.683105 
	C296.233307,1919.692505 304.310791,1919.431885 312.517242,1919.292236 
	C314.133148,1919.264771 315.729523,1919.597900 317.405060,1919.249268 
	C324.400238,1917.794189 330.949371,1917.062744 338.424072,1919.429810 
	C345.398193,1921.638306 353.614044,1920.274292 361.258942,1919.933960 
	C372.814117,1919.419678 383.690186,1922.246216 394.276123,1926.239990 
	C400.307251,1928.515381 406.740479,1929.353149 412.755829,1929.583496 
	C426.301392,1930.102173 439.967102,1931.363647 453.537231,1928.605835 
	C459.740173,1927.344971 465.872803,1931.085205 473.121094,1930.014282 
	C493.839386,1927.073242 513.317688,1929.481934 532.520996,1934.453491 
	C536.052979,1935.368042 538.472534,1934.958984 541.778015,1933.589478 
	C555.501099,1927.903931 569.458496,1922.754761 584.153015,1920.036743 
	C586.453613,1919.611328 588.841675,1919.645996 591.111511,1920.152466 
	C608.554932,1924.043457 626.178894,1920.716431 643.701782,1921.483643 
	C650.105896,1921.764038 656.286316,1921.569824 661.138611,1926.891968 
	C663.513672,1929.496948 666.900696,1930.246582 670.353455,1929.007202 
	C677.921448,1926.291016 685.380432,1927.366455 692.947266,1929.118652 
	C699.380920,1930.608643 705.527405,1928.312988 711.762329,1927.556396 
	C731.328918,1925.181763 750.555298,1918.626221 770.653992,1921.093262 
	C785.372009,1922.899902 799.939392,1923.828125 813.106689,1932.791016 
	C824.198669,1940.340942 827.081848,1954.605103 816.754456,1963.638428 
	C813.101929,1966.833496 810.115845,1969.650391 809.749512,1974.911987 
	C809.429138,1979.513550 806.027954,1982.577393 801.501465,1981.502197 
	C794.542114,1979.849243 793.692810,1983.953003 792.498169,1989.432373 
	C790.507385,1990.017456 789.012268,1990.020264 786.769531,1990.028564 
	C785.025696,1990.920288 783.975708,1992.226196 782.703613,1992.103394 
	C765.908936,1990.480347 748.144958,1996.142090 732.474060,1989.422485 
	C722.021545,1984.940552 711.642822,1982.193604 700.900452,1979.201538 
	C687.605347,1975.498413 673.674194,1978.456055 660.763428,1973.507202 
	C658.493591,1972.637085 657.459412,1974.274414 656.185547,1976.138550 
	C648.409485,1987.516602 641.414246,1989.439331 629.550903,1984.010986 
	C619.969177,1979.626831 611.549316,1976.172607 602.009705,1986.545532 
	C595.442932,1993.685669 583.281494,1991.705200 573.826233,1994.696411 
	C569.948059,1995.923218 565.785400,1991.088745 565.232849,1986.554810 
	C564.604797,1981.401733 568.832764,1980.368164 572.348206,1980.255005 
	C567.567383,1973.550537 560.603638,1972.522583 552.474915,1974.011841 
	C547.451050,1974.932251 542.579346,1973.431519 538.796143,1969.462891 
	C535.995728,1966.525513 532.202698,1965.059570 528.833923,1962.951416 
	C521.460327,1958.336914 516.605591,1958.588379 512.671387,1967.561401 
	C509.724640,1974.282227 502.499237,1975.235352 496.166687,1973.395020 
	C489.051605,1971.327148 482.731323,1972.628906 475.742157,1973.904419 
	C460.674530,1976.654297 445.868469,1975.703857 433.483917,1963.037354 
	C435.307800,1971.687378 431.306763,1973.937988 424.219666,1973.774292 
	C418.467346,1973.641235 414.147980,1975.785767 412.373291,1982.192505 
	C410.706696,1988.208984 406.840698,1991.428101 400.415161,1987.357178 
	C398.190186,1985.947388 396.057251,1986.048340 393.783173,1987.023071 
	C384.376923,1991.054932 374.719727,1992.468506 364.598236,1989.768311 
	C369.844666,1993.335693 375.789917,1993.185059 381.616119,1992.996216 
	C388.826263,1992.762329 394.696106,1995.442505 399.293030,2000.684448 
	C402.419037,2004.249268 400.214233,2008.647095 396.644348,2007.267944 
	C386.664368,2003.412476 376.980682,2008.591187 367.130829,2006.678345 
	C367.185364,2009.504395 367.351379,2012.370972 367.820099,2015.215332 
	C380.710327,2014.047729 381.959167,2015.203979 381.199799,2027.486816 
	C383.809784,2028.816650 385.668488,2027.015869 387.490265,2025.753296 
	C391.312836,2023.104004 395.298889,2020.896606 398.843140,2025.563599 
	C402.246765,2030.045288 405.735077,2034.934448 402.069611,2041.012451 
	C399.662476,2045.004028 396.264954,2048.263428 393.569580,2052.752930 
	C394.069061,2055.293457 394.744507,2057.094482 395.370605,2058.913818 
	C398.682190,2068.537354 397.585144,2069.922852 386.438965,2069.328857 
	C376.705933,2069.466553 369.966278,2072.491455 367.070770,2082.158936 
	C364.713165,2090.030762 357.531677,2091.539795 349.829865,2092.268555 
	C359.140686,2097.530518 366.279083,2091.308350 369.374023,2085.878418 
	C373.559967,2078.533936 379.865265,2075.347412 386.621521,2070.754395 
	C396.788544,2072.799072 397.630066,2074.826172 392.666534,2085.442871 
	C390.708160,2085.991943 389.417572,2085.902588 388.149078,2086.031006 
	C386.080811,2086.240234 382.909668,2085.444824 383.139282,2088.765869 
	C383.314606,2091.302490 386.033569,2091.806885 388.389130,2091.951904 
	C390.601562,2092.088379 392.897919,2091.708740 395.497162,2093.454590 
	C395.633820,2100.464600 391.868835,2102.150879 386.189850,2100.600586 
	C378.437775,2098.484619 374.924652,2101.200928 375.828613,2109.982910 
	C376.241119,2113.901855 374.197632,2116.210205 372.421997,2119.389404 
	C371.370361,2120.937012 370.735840,2121.874023 370.042786,2123.397461 
	C368.957458,2124.843750 368.655457,2125.897705 369.553802,2127.591309 
	C371.924805,2132.031006 375.566559,2132.030762 379.274017,2132.027344 
	C383.219360,2132.023438 387.204590,2131.844482 391.098267,2132.333008 
	C394.479370,2132.757324 396.562836,2135.043457 395.219238,2138.632812 
	C393.980560,2141.941895 391.493347,2141.864990 388.895996,2139.927734 
	C385.077087,2137.079346 380.702179,2137.836670 376.518646,2138.336670 
	C374.229614,2138.610596 372.052307,2139.912598 373.535278,2143.530762 
	C376.161530,2148.574219 372.641052,2149.781250 369.634064,2150.889893 
	C364.680481,2152.716797 359.479187,2153.403564 353.287415,2153.147461 
	C349.986389,2152.908691 347.580261,2152.915283 344.276184,2152.810303 
	C335.925262,2150.941650 328.626343,2150.416260 320.908630,2152.284180 
	C313.164917,2154.158203 305.127014,2153.257324 296.705719,2150.521729 
	C296.043945,2149.034668 296.031342,2148.167480 296.009369,2146.650391 
	C295.968323,2141.774414 298.762238,2139.962891 302.296722,2139.583984 
	C312.565704,2138.484131 322.640045,2136.144531 332.917175,2135.080811 
	C338.188843,2134.534912 342.377289,2138.141113 346.084442,2138.153320 
	C339.969788,2134.319092 332.187469,2131.244141 324.705994,2127.584473 
	C321.786865,2126.156494 317.804596,2123.003174 319.642487,2118.899902 
	C321.820831,2114.036865 326.814117,2115.285156 330.641876,2115.768066 
	C326.568329,2114.298340 319.988525,2115.303467 321.556671,2106.833740 
	C322.102325,2103.886719 318.509644,2103.869873 316.360321,2103.474854 
	C312.037018,2102.679932 307.756165,2103.559326 303.455750,2104.099365 
	C292.598633,2105.463135 283.072693,2102.340332 274.821686,2095.199219 
	C273.071838,2093.684570 270.836304,2092.374023 271.294952,2089.623047 
	C271.837921,2086.365723 274.696533,2085.701904 277.287354,2085.244141 
	C282.753052,2084.278320 288.559631,2082.088135 292.543854,2088.489258 
	C294.373535,2091.429199 296.530121,2089.744141 298.543640,2088.643799 
	C301.783356,2086.873291 305.312988,2086.095459 308.986420,2085.528320 
	C304.304779,2084.081543 299.528870,2084.788086 297.131134,2077.675293 
	C295.965942,2074.218750 284.933472,2073.145996 280.699615,2077.067627 
	C275.082123,2082.270752 268.385864,2081.454102 262.446747,2083.949951 
	C259.067200,2085.370117 255.728973,2085.938965 254.321503,2081.496826 
	C253.096420,2077.630127 254.087540,2074.410400 257.960754,2072.206055 
	C261.066864,2070.437988 267.635712,2072.492920 266.801270,2066.548096 
	C266.064667,2061.299805 259.777283,2062.647949 256.005554,2061.068359 
	C250.011276,2058.557861 243.715622,2059.982178 236.781845,2059.952637 
	C238.205170,2066.363525 241.954407,2070.798584 243.902603,2075.983398 
	C245.198105,2079.431396 246.639374,2082.940430 246.235214,2086.628174 
	C245.542328,2092.950195 247.223801,2097.641113 253.345642,2100.478271 
	C255.836914,2101.632812 257.802979,2104.070312 256.586304,2107.000977 
	C255.187332,2110.370605 251.892151,2110.363037 248.838638,2109.724121 
	C242.919128,2108.485596 238.568405,2103.426025 232.171951,2102.889893 
	C232.223557,2104.276367 233.639893,2104.870117 234.361237,2105.096680 
	C239.699814,2106.773438 233.143890,2113.169434 238.033737,2114.482422 
	C241.847412,2115.506348 246.147018,2114.675781 249.795074,2116.402588 
	C253.754272,2118.276855 259.028198,2119.892578 254.684906,2126.141113 
	C253.108521,2126.009033 252.223083,2126.008545 250.673584,2126.008789 
	C241.802551,2128.031250 233.707672,2127.220947 225.983093,2124.192627 
	C221.009979,2122.242676 216.073578,2120.344482 210.792480,2119.640381 
	C207.053223,2119.142334 203.129562,2117.508789 203.060516,2124.619385 
	C203.005722,2130.263428 189.604095,2131.943604 184.065887,2126.311279 
	C176.449387,2118.565186 166.854263,2116.816162 156.530701,2114.462891 
	C155.994095,2114.009766 156.000000,2114.000000 155.996017,2114.004883 
	C153.198898,2110.117676 156.402390,2107.875732 158.510529,2105.533447 
	C161.784698,2101.895752 161.032684,2099.927490 156.415451,2098.691650 
	C150.035965,2096.984375 145.937393,2093.875732 150.077469,2086.551758 
	C150.345322,2086.077637 151.893555,2085.493164 150.748596,2085.113525 
	C141.748047,2082.127441 149.379944,2077.249023 149.285080,2073.206299 
	C147.079498,2071.571777 144.113968,2072.479492 141.780884,2071.055420 
	C139.397919,2069.601074 137.419601,2067.809326 137.091721,2064.973389 
	C136.708786,2061.660889 139.217865,2060.390625 141.784821,2059.397949 
	C144.589890,2058.312988 147.316177,2057.120850 149.546082,2055.020996 
	C151.507904,2053.173828 153.375076,2051.194580 153.821030,2048.451904 
	C154.671707,2043.220093 142.436386,2022.716553 137.289581,2020.794312 
	C134.485947,2019.747070 130.986465,2019.320190 131.066544,2015.474365 
	C131.150970,2011.419800 133.944962,2008.809448 137.487213,2007.313721 
	C144.060822,2004.538330 153.655350,2008.776733 157.678802,2017.178589 
	C158.193863,2007.604004 162.505264,2004.936401 170.589157,2007.122070 
	C175.564148,2008.467163 180.684265,2008.107422 182.812653,2001.861084 
	C183.531494,1999.751343 185.113953,1998.242676 187.203476,1998.431152 
	C194.859756,1999.122681 202.106583,1999.087158 209.397446,1995.393433 
	C213.339935,1993.396118 214.415375,1999.633545 217.378098,2001.312012 
	C232.179260,1987.926392 242.245056,1986.174927 256.371368,2001.213257 
	C261.679657,2006.864502 264.723846,2005.999756 270.042297,1997.233643 
	C270.669067,1996.224731 270.963623,1995.849121 271.828918,1995.041626 
	C286.108032,1988.527832 292.680725,2000.275757 303.700775,2008.739746 
	C296.658112,1995.839600 309.334869,1996.723511 312.524384,1988.997192 
	C302.943787,1990.450317 294.414551,1991.527954 285.879303,1991.779419 
	C277.821381,1992.016846 271.602203,1990.390991 266.439423,1984.268066 
	C261.909851,1978.896240 255.740494,1978.626831 251.551483,1983.236328 
	C240.892929,1994.964600 227.685349,1991.520874 215.111877,1990.233154 
	C208.351990,1989.540649 203.349640,1987.773071 197.703812,1994.521606 
	C191.859772,2001.507446 183.872711,1995.754883 177.745529,1992.456665 
	C172.971176,1989.886597 168.859756,1987.036133 163.057434,1987.807617 
	C159.095169,1988.334351 155.342072,1986.768188 155.039078,1982.268066 
	C154.726624,1977.627075 158.056259,1975.056396 162.323669,1974.116089 
	C166.096146,1973.284790 169.911514,1974.564331 173.730560,1974.658936 
	C168.992065,1972.752075 163.934174,1973.631348 159.072342,1972.429810 
	C155.412827,1971.525513 151.741180,1972.634277 150.097244,1976.867188 
	C149.072952,1979.504272 147.569778,1981.687988 144.305786,1981.305664 
	C140.774506,1980.892212 139.548004,1978.184326 138.503052,1975.235229 
	C135.298279,1966.190063 135.870865,1967.484741 127.419014,1971.507080 
	C119.889420,1975.090454 112.952660,1981.941406 103.131416,1979.680786 
	C100.632843,1979.105469 98.282608,1981.567383 95.609032,1981.939209 
	C92.151535,1982.419922 93.081245,1985.181885 93.022636,1987.385986 
	C93.774025,1986.175659 94.446991,1984.677002 95.948524,1984.449829 
	C102.443352,1983.467285 108.812592,1981.889526 115.224403,1980.605347 
	C120.552406,1979.538208 124.095894,1985.300415 122.639908,1991.749023 
	C121.442917,1997.050415 119.815155,2001.119263 126.502953,2005.050171 
	C132.738998,2008.715820 127.510384,2013.091309 123.793335,2015.738403 
	C117.386978,2020.301025 109.643539,2018.380005 102.181442,2019.620605 
	C109.831924,2018.824341 114.762474,2020.665894 112.949562,2029.592407 
	C112.382690,2032.383667 113.787376,2034.744995 115.422783,2036.939087 
	C118.493561,2041.059082 121.604942,2045.437134 117.383118,2050.358643 
	C113.502365,2054.882812 108.515953,2055.922852 102.525757,2052.472168 
	C100.223404,2051.813232 98.687355,2051.277588 98.093552,2048.648682 
	C98.088211,2044.096313 100.317474,2041.360596 103.529358,2038.432007 
	C102.092239,2032.095215 100.190926,2030.905029 95.203659,2032.743164 
	C92.757851,2033.644409 90.957741,2036.003418 87.998138,2035.854492 
	C85.439034,2035.725586 83.346359,2034.856934 82.616310,2032.279175 
	C81.939575,2029.889526 83.259995,2028.556152 85.585068,2028.075073 
	C87.207039,2027.739502 89.231262,2027.419189 88.858971,2025.236450 
	C88.472420,2022.970337 86.379509,2022.372070 84.308662,2022.100586 
	C80.562843,2021.609497 77.035553,2022.485962 72.787231,2023.886719 
	C70.019958,2022.086426 69.934006,2019.873535 71.469711,2016.755859 
	C76.137932,2012.695801 69.435829,2010.433228 71.125641,2007.306152 
	C74.022446,2004.681030 76.703934,2008.310547 79.513313,2007.637207 
	C82.105141,2007.016235 84.937981,2006.370483 85.468178,2003.242920 
	C85.932823,2000.501709 84.077560,1998.603882 81.596626,1998.387207 
	C76.645409,1997.954956 75.859688,1994.859497 75.784218,1990.849365 
	C75.751457,1989.108276 74.612808,1987.806030 72.633743,1986.368408 
	C70.845642,1984.640747 70.536415,1983.111938 71.504799,1980.731201 
z"
        />
        <path
          fill="#7A91A4"
          opacity="1.000000"
          stroke="none"
          d="
M392.689514,2086.297363 
	C392.383789,2083.820068 392.624817,2081.614990 393.204620,2079.502686 
	C394.593323,2074.442383 392.211761,2072.406494 386.774200,2072.040039 
	C384.678131,2070.697510 384.499298,2069.123047 385.525940,2066.702637 
	C388.701416,2067.149414 391.320068,2068.965332 395.095001,2066.663086 
	C393.496307,2062.363281 391.840332,2057.909424 390.094604,2052.727783 
	C397.503448,2048.985596 403.940277,2043.140503 412.853302,2043.835083 
	C415.150848,2044.014160 417.529877,2042.544556 418.926453,2040.707153 
	C425.165314,2032.500244 433.906311,2030.338623 443.528320,2029.629395 
	C446.920563,2029.379150 452.012695,2029.210083 448.118500,2023.245728 
	C446.144012,2020.221191 445.048676,2016.879150 447.810608,2014.089966 
	C451.561279,2010.301758 456.523712,2011.545044 461.085938,2012.252808 
	C465.168976,2012.886108 467.319672,2015.524414 467.951324,2019.699951 
	C469.944366,2032.874634 470.053192,2032.626709 482.871765,2035.024536 
	C494.887665,2037.272095 506.704163,2037.541504 518.417358,2033.642334 
	C522.014526,2032.444946 525.572754,2032.224976 529.156860,2033.706909 
	C540.390564,2038.351929 552.123535,2037.512695 563.669861,2036.034668 
	C577.784546,2034.227905 591.890869,2032.915283 605.873962,2029.164307 
	C618.426208,2025.796997 632.086975,2029.211426 645.276611,2028.086670 
	C649.910889,2027.691650 662.471436,2040.846313 663.244629,2045.409424 
	C664.064026,2050.244385 660.771545,2051.822021 657.100952,2053.027588 
	C650.467407,2055.206055 643.657898,2057.068604 638.980652,2064.029785 
	C651.045593,2066.972900 663.213684,2066.323975 675.425049,2069.515869 
	C678.126587,2071.696533 681.540771,2071.275635 683.867676,2074.913574 
	C679.004272,2074.988770 673.707214,2073.846191 674.011230,2081.280518 
	C669.627930,2085.751953 664.656372,2088.991211 662.467896,2095.498047 
	C660.897156,2100.491455 660.094299,2105.101807 656.537537,2108.545166 
	C655.840820,2109.219482 654.765808,2110.333008 654.936707,2110.945068 
	C656.686096,2117.212891 652.559509,2120.617188 648.807251,2124.302002 
	C647.681641,2125.407227 646.294800,2126.691895 647.356384,2128.580566 
	C648.107788,2129.917236 649.550781,2129.964600 650.845703,2129.933594 
	C656.124329,2129.806641 661.397339,2130.469482 666.651733,2128.855225 
	C676.822205,2125.730225 685.661011,2129.709229 694.290222,2134.626221 
	C689.041382,2135.798584 684.009216,2139.078369 678.621521,2134.793945 
	C677.315308,2133.755127 674.638123,2133.667725 672.855835,2134.140381 
	C666.701721,2135.772705 660.615356,2135.930176 654.340820,2134.990479 
	C649.365295,2134.244873 644.973633,2135.111572 641.215210,2139.431885 
	C637.785034,2143.375000 632.222107,2143.405518 628.398621,2140.640625 
	C622.192322,2136.152100 615.575806,2135.626465 608.547241,2136.026611 
	C605.109497,2136.222168 602.092896,2134.568359 598.815918,2134.249756 
	C595.130432,2133.891113 594.081299,2135.609863 595.633057,2139.119141 
	C598.391663,2145.357666 603.685425,2147.956299 610.907471,2146.705322 
	C619.148071,2145.278076 627.457153,2146.170166 635.735168,2146.112793 
	C638.887817,2146.090820 639.799194,2147.844971 638.268188,2151.381836 
	C637.339172,2152.774170 636.593872,2153.403320 635.081665,2154.186768 
	C624.732666,2157.346924 614.760864,2156.320312 604.891968,2156.636230 
	C599.524292,2156.807861 594.151733,2157.600830 589.834656,2160.732178 
	C587.250977,2162.606445 582.622986,2165.905762 590.156372,2169.015381 
	C604.228760,2169.550537 617.504028,2170.232178 631.283325,2173.396973 
	C635.992065,2174.848877 636.230652,2178.714111 633.162048,2178.726562 
	C625.509155,2178.757812 618.756592,2181.700684 611.550842,2182.958252 
	C610.213135,2183.191650 609.179565,2180.574707 606.766907,2181.655762 
	C606.548828,2182.617920 606.346375,2183.877441 605.975403,2185.085205 
	C604.421631,2190.143066 601.905640,2191.482422 598.539551,2188.173828 
	C593.841064,2183.555420 588.479980,2181.223145 582.275635,2179.360107 
	C578.234192,2178.146729 574.057678,2174.787842 575.395996,2168.979492 
	C576.375061,2164.730225 574.073730,2163.072754 570.487122,2161.799805 
	C565.846558,2160.152344 561.689697,2158.396973 556.241516,2159.614990 
	C550.541321,2160.889648 544.340515,2159.785645 538.372681,2160.044922 
	C533.200378,2160.269775 529.961243,2156.502686 526.077148,2154.305176 
	C522.931885,2152.525635 523.794861,2148.800049 527.553101,2146.321289 
	C533.215088,2142.586670 539.787048,2141.410156 546.274170,2140.046387 
	C550.478333,2139.162842 554.727295,2138.432373 558.858276,2137.281982 
	C561.440491,2136.562988 563.908752,2135.179688 563.654419,2131.817383 
	C563.372070,2128.084961 560.496094,2128.065674 557.799866,2128.041992 
	C551.157104,2127.983887 544.513672,2128.007812 536.941345,2127.994385 
	C536.010681,2126.497559 536.009155,2125.005127 536.012329,2122.766113 
	C539.517822,2110.095459 539.517822,2110.095459 555.698242,2111.279541 
	C552.807312,2101.486084 543.729309,2098.158203 537.346680,2092.413086 
	C534.982056,2090.284912 530.577026,2089.649414 531.396240,2085.470215 
	C532.277222,2080.975830 536.613464,2081.871094 539.688782,2082.053467 
	C546.975708,2082.486816 554.333069,2081.222168 561.778931,2082.804932 
	C554.599670,2081.963623 550.429565,2078.983643 546.667725,2071.990723 
	C543.597351,2066.283203 538.947144,2061.810059 540.537964,2053.659912 
	C535.039856,2055.738525 536.590210,2062.222412 531.972778,2063.514404 
	C528.183960,2064.574219 524.063477,2064.216309 520.508179,2066.143066 
	C525.593323,2069.196289 530.109924,2073.166992 536.343140,2073.845215 
	C538.646240,2074.095703 541.007385,2075.394287 540.658936,2078.390137 
	C540.315857,2081.339844 537.887268,2081.671631 535.460388,2081.971924 
	C529.176331,2082.750000 522.903442,2083.536377 515.840515,2082.390625 
	C513.054871,2082.409424 511.040070,2082.922852 508.155090,2082.898926 
	C504.507660,2083.004150 501.716827,2082.918945 498.669495,2080.539551 
	C492.662170,2078.683105 492.810822,2078.799561 495.075531,2075.909180 
	C499.132446,2070.730957 502.013153,2065.245361 500.130615,2058.190430 
	C498.603668,2052.468018 495.904297,2050.068115 490.424347,2052.541748 
	C485.578918,2054.728760 481.730621,2056.444336 477.070740,2052.483887 
	C475.649231,2051.275635 473.277740,2052.696045 471.344116,2053.182861 
	C466.749329,2054.339355 462.223419,2055.193848 458.822571,2050.351807 
	C457.117432,2047.924194 454.849213,2048.426514 452.828613,2050.261475 
	C451.103790,2051.827637 449.403992,2053.383057 447.083344,2054.095947 
	C445.352142,2054.627686 444.172760,2055.762451 444.737976,2057.756836 
	C445.148468,2059.205322 446.440247,2059.688721 447.812134,2059.837402 
	C454.278992,2060.537842 461.139709,2058.558350 467.090271,2062.951904 
	C468.098602,2063.696533 469.491821,2062.406738 470.525421,2061.523193 
	C472.507721,2059.828613 475.074799,2059.406738 476.980988,2060.887695 
	C480.290039,2063.458008 483.525665,2063.429199 487.200409,2062.216553 
	C488.433594,2061.809814 490.067627,2062.121826 490.781647,2063.333984 
	C491.740723,2064.962158 490.692596,2066.498535 489.828796,2067.914795 
	C488.534241,2070.036621 486.248322,2070.806152 484.225067,2071.901123 
	C480.628265,2073.847656 478.023712,2076.865723 475.442535,2079.901611 
	C474.281860,2081.266846 473.901642,2082.998047 474.955322,2084.489502 
	C476.008606,2085.980225 478.232910,2086.553955 479.416565,2085.620117 
	C485.021667,2081.198242 489.690582,2083.658936 494.714539,2087.356201 
	C492.651031,2090.355713 489.872070,2091.947021 486.354858,2091.052734 
	C478.462372,2089.045898 470.389709,2091.047363 462.575500,2089.860596 
	C451.523529,2088.181885 440.308563,2090.905029 429.354218,2088.070312 
	C427.426178,2087.571533 425.513611,2087.496826 424.065125,2085.797119 
	C420.431854,2081.533447 416.751587,2079.611328 413.400848,2086.130615 
	C406.702820,2085.133057 400.153137,2090.304932 392.689514,2086.297363 
z"
        />
        <path
          fill="#7A91A4"
          opacity="1.000000"
          stroke="none"
          d="
M271.633179,1995.635254 
	C271.129181,2007.294800 268.701935,2009.452393 258.791382,2007.941528 
	C256.686432,2007.620850 254.649918,2007.185059 254.300735,2004.813110 
	C252.988708,1995.902222 243.886627,1996.691772 238.856247,1992.422485 
	C237.476486,1991.251709 235.018112,1992.473877 233.383820,1993.811157 
	C231.343307,1995.481323 229.592758,1997.632690 226.702072,1997.906738 
	C222.377762,1998.316406 219.714951,1999.927368 221.090683,2004.974365 
	C221.476151,2006.388550 220.506149,2007.443237 218.938965,2007.687988 
	C217.161606,2007.965454 215.377655,2007.660889 214.678802,2005.962036 
	C213.444260,2002.960938 213.699448,1999.297729 209.974380,1996.731079 
	C207.482742,2000.825562 203.844467,2002.455933 198.747116,2002.192383 
	C194.236679,2001.959106 189.816559,2000.348022 185.739807,2000.296997 
	C183.666672,2004.132324 188.091263,2005.637329 186.857193,2008.682129 
	C184.359451,2010.221436 181.275620,2007.529053 178.492661,2009.674072 
	C174.967728,2012.390747 171.228592,2012.322632 167.617493,2009.155396 
	C166.024002,2007.757568 163.833145,2007.947266 162.109192,2009.412598 
	C160.428360,2010.841553 159.698822,2012.718384 160.966324,2014.697632 
	C162.212006,2016.642700 164.360550,2018.343994 161.062439,2020.300415 
	C158.965363,2021.544189 156.802582,2022.394165 154.525314,2020.895020 
	C152.022507,2019.247559 152.668762,2016.998047 153.440857,2014.670776 
	C153.729294,2013.801392 153.487488,2012.756226 153.487488,2011.161499 
	C149.945740,2010.696655 146.185486,2011.991089 143.052368,2009.634277 
	C140.940384,2008.045532 138.653809,2008.334473 136.770645,2010.322144 
	C135.210831,2011.968628 132.544952,2013.342163 133.118027,2015.861572 
	C133.811981,2018.912231 136.931778,2018.078491 139.039795,2017.914551 
	C143.497604,2017.568237 145.172379,2019.871460 147.070343,2023.583008 
	C150.484619,2030.260132 150.641876,2038.440430 157.080765,2043.850098 
	C159.193466,2045.625122 158.639938,2049.793457 156.419998,2052.147705 
	C152.428238,2056.381104 149.385605,2062.160889 142.285995,2062.085938 
	C140.972778,2062.072021 139.537720,2062.308594 139.110107,2063.867920 
	C138.574356,2065.821289 140.042465,2066.938232 141.240585,2068.019531 
	C144.122925,2070.621094 147.329544,2070.099854 150.590424,2068.804688 
	C152.235275,2068.151123 154.074097,2068.007324 155.093719,2069.594238 
	C156.361893,2071.567871 155.251862,2073.394775 153.876999,2074.977783 
	C153.658829,2075.228760 153.374115,2075.430664 153.193039,2075.703857 
	C151.726990,2077.913330 146.461365,2078.577148 149.117065,2082.264648 
	C151.271118,2085.255615 153.403336,2080.417480 155.905472,2080.489990 
	C156.820435,2080.516357 158.033844,2080.647461 158.568054,2081.229492 
	C159.659088,2082.417480 158.962158,2083.637695 157.883926,2084.593750 
	C157.637802,2084.812256 157.383011,2085.049561 157.088791,2085.180664 
	C153.878647,2086.611572 149.191040,2087.110596 150.685791,2092.277832 
	C152.028030,2096.917969 156.493713,2095.763672 160.007965,2096.074463 
	C162.491119,2096.294434 164.581039,2097.120605 165.425064,2099.699219 
	C166.289429,2102.339600 164.735489,2104.125244 163.061264,2105.829834 
	C160.803299,2108.128662 156.850143,2108.708252 156.085083,2113.312500 
	C155.104889,2114.008545 154.217743,2114.007080 152.665146,2114.002930 
	C151.153488,2112.955811 150.102905,2112.665283 148.423004,2113.571777 
	C143.837860,2114.308594 139.518600,2113.196533 134.916382,2115.245850 
	C137.132385,2119.815186 142.047211,2116.555176 144.996628,2119.832031 
	C139.946304,2123.108398 134.789642,2124.702881 128.909317,2123.643066 
	C126.691605,2123.243652 125.500221,2121.435059 124.978622,2119.561768 
	C124.058533,2116.258057 121.245590,2114.098877 120.148872,2111.036621 
	C118.623306,2106.776855 115.615715,2105.482422 111.592804,2106.260254 
	C106.808945,2107.185791 108.382965,2111.251465 108.016312,2114.305908 
	C107.937965,2114.958252 108.004272,2115.628174 108.004829,2117.140137 
	C106.756897,2117.603027 104.923538,2117.608154 104.356041,2116.763184 
	C101.160942,2112.005127 97.794975,2111.749756 94.038986,2115.909424 
	C92.134285,2118.019043 90.014610,2117.935547 87.739914,2116.860107 
	C85.764000,2115.925781 82.735191,2116.160889 82.496986,2113.109619 
	C82.298103,2110.562012 84.870964,2110.343262 86.437851,2109.271240 
	C88.338341,2107.970703 90.085754,2106.447998 91.917984,2105.045410 
	C92.702423,2104.445068 94.305557,2103.547363 94.232582,2103.390137 
	C91.189026,2096.837646 99.048309,2092.995605 98.194641,2086.733398 
	C97.601402,2082.381592 96.754005,2077.479736 100.282845,2073.402100 
	C101.684669,2071.782227 99.793488,2070.655762 98.885933,2069.300537 
	C96.178543,2065.258301 91.353416,2064.108887 88.083405,2060.911133 
	C87.099174,2059.948975 86.428505,2058.648926 87.193283,2057.385010 
	C87.986771,2056.074219 89.490318,2056.197021 90.759041,2056.665771 
	C91.670815,2057.002686 92.404427,2057.802734 93.306374,2058.188477 
	C98.912659,2060.585938 99.061653,2060.489990 101.987595,2052.749023 
	C103.006897,2052.000000 104.005630,2051.983643 105.003685,2052.002930 
	C109.359650,2052.087158 114.294113,2052.628906 116.483757,2047.966431 
	C118.790573,2043.054565 114.811783,2040.242065 111.475906,2037.384155 
	C108.255989,2034.625610 108.401085,2031.414551 110.051582,2027.511353 
	C111.939514,2023.046631 110.530273,2021.719116 105.644051,2022.120850 
	C101.575478,2022.455322 97.621208,2025.817871 92.599831,2022.135620 
	C97.806290,2017.973633 102.915466,2015.319458 109.565636,2015.937866 
	C114.573769,2016.403687 119.606461,2016.171265 123.825844,2012.800659 
	C125.347488,2011.585083 127.371750,2010.366699 126.853638,2008.088379 
	C126.256691,2005.463745 123.734077,2006.280640 121.887619,2006.000977 
	C120.619217,2005.808838 119.347282,2005.640137 117.380226,2005.362549 
	C118.488083,2000.010254 116.817642,1994.629395 120.599243,1990.004883 
	C122.121475,1988.143433 121.070549,1986.261475 119.576546,1984.707642 
	C117.873199,1982.936035 115.012459,1980.960205 113.554070,1982.724365 
	C108.549896,1988.777588 102.044456,1985.748413 96.197098,1986.564941 
	C95.905441,1986.605713 95.652618,1986.924805 95.380615,1987.114258 
	C95.422485,1987.725342 95.277649,1988.438721 95.544235,1988.927979 
	C96.287407,1990.291504 98.903069,1989.960083 98.699463,1992.178833 
	C98.595161,1993.315552 97.569405,1993.681030 96.529167,1993.728638 
	C94.766418,1993.809204 93.190201,1993.406860 92.501160,1991.562988 
	C91.808899,1989.710449 91.396858,1987.742432 90.598923,1985.941650 
	C87.345642,1978.599365 87.425842,1978.285522 95.312981,1978.803589 
	C97.564880,1978.951294 98.695122,1978.947876 99.977898,1976.699341 
	C102.643219,1972.027344 102.895264,1972.174805 106.154190,1977.177979 
	C106.507881,1977.721069 106.920723,1978.225586 107.895828,1979.547241 
	C110.827507,1974.111694 115.218994,1971.435669 121.320618,1971.891724 
	C124.660469,1972.141479 127.389336,1970.571411 127.771667,1967.019775 
	C128.373230,1961.431519 131.682968,1963.241333 134.951080,1963.803467 
	C138.924408,1964.487061 142.249359,1965.458862 141.491455,1970.544189 
	C141.297440,1971.846313 140.536728,1973.199707 140.755661,1974.398193 
	C141.169296,1976.662598 141.924332,1979.383179 144.731888,1979.420410 
	C148.298782,1979.467896 147.885452,1976.764771 147.186951,1974.404785 
	C146.229080,1971.168823 147.893951,1970.168213 150.686661,1970.040283 
	C155.406464,1969.823975 160.197220,1966.580078 164.826111,1970.894287 
	C166.381287,1972.343750 168.600815,1970.488647 170.529907,1970.248291 
	C172.858963,1969.958008 175.234085,1969.737305 177.312515,1971.087036 
	C179.507538,1972.512085 180.256943,1974.681519 179.545975,1977.165405 
	C178.677383,1980.199341 176.191391,1980.129761 173.885422,1979.469482 
	C172.342194,1979.027710 171.025253,1977.835693 169.562134,1977.064697 
	C167.072784,1975.753052 164.327957,1975.543457 161.821198,1976.532959 
	C159.041916,1977.630005 155.945267,1979.372437 157.097900,1983.015137 
	C158.090317,1986.151855 161.026169,1986.135498 163.804153,1985.076538 
	C169.508087,1982.902344 173.740143,1984.345215 177.827759,1989.122925 
	C181.516220,1993.433960 187.808212,1993.457886 192.964203,1994.951538 
	C195.623337,1995.721802 197.998962,1992.459229 198.051910,1988.854980 
	C198.111710,1984.785522 200.295364,1983.680054 204.023209,1985.000977 
	C208.945694,1986.745117 214.734650,1985.167114 219.147217,1989.143677 
	C220.385422,1990.259644 222.311752,1989.588745 223.931198,1989.053711 
	C225.186111,1988.639282 226.487762,1988.210449 227.790710,1988.125000 
	C232.440903,1987.819824 237.191391,1989.163818 241.593750,1988.806519 
	C246.244446,1988.429199 246.930084,1981.990112 250.594727,1978.933594 
	C254.307129,1975.837402 257.637512,1978.698608 260.795074,1977.411743 
	C265.813873,1975.366333 266.761871,1978.622681 268.553589,1982.226196 
	C271.222778,1987.594482 277.037445,1989.508911 283.490875,1988.549316 
	C286.089630,1988.162842 288.984863,1987.636475 291.369232,1988.379761 
	C296.576782,1990.003296 301.584534,1989.023560 305.711304,1986.578491 
	C310.140320,1983.954468 313.052734,1984.875000 316.271393,1987.802368 
	C314.729614,1993.727783 312.587646,1998.582031 305.148926,1998.991211 
	C302.838867,1999.118286 301.439514,2002.115723 305.061432,2003.719971 
	C307.032623,2004.593018 310.615112,2004.517212 308.347687,2007.899902 
	C306.783936,2010.233032 303.934357,2012.386963 300.693268,2010.456421 
	C297.876648,2008.778809 294.226898,2007.069458 292.996155,2004.421143 
	C288.886047,1995.576904 281.567444,1995.532349 272.870026,1996.000000 
	C271.636200,1995.632324 271.270386,1995.266846 270.902374,1994.903564 
	C270.534088,1994.540161 270.655457,1994.662476 271.633179,1995.635254 
z"
        />
        <path
          fill="#7A91A4"
          opacity="1.000000"
          stroke="none"
          d="
M254.102875,2126.758301 
	C256.007660,2124.217529 255.538071,2122.530273 253.629715,2121.106934 
	C250.593338,2118.842041 247.144928,2117.728516 243.336441,2118.015625 
	C237.621094,2118.445801 232.440292,2116.661377 226.587921,2114.811279 
	C228.687408,2110.843994 232.709305,2113.008057 234.856308,2110.550781 
	C232.994904,2104.915283 226.192444,2104.411133 223.109573,2099.521240 
	C229.679382,2100.326904 236.620941,2097.998047 242.219147,2103.215332 
	C244.459412,2105.303467 247.029205,2106.857910 249.968277,2107.565918 
	C251.793549,2108.005615 254.209641,2108.583008 254.846573,2105.850342 
	C255.313599,2103.846924 253.522415,2102.978760 251.956085,2102.417969 
	C250.716919,2101.974121 249.287964,2102.045166 248.066376,2101.570801 
	C244.158554,2100.053223 241.628479,2096.205566 242.550629,2092.971680 
	C246.436111,2079.345703 237.091141,2070.592529 231.681915,2060.350342 
	C231.375641,2059.770752 231.160385,2059.142822 230.908356,2058.534180 
	C231.201340,2057.967529 231.510529,2056.901123 231.772949,2056.912598 
	C240.713501,2057.300293 249.945053,2053.912109 258.540466,2058.963379 
	C260.953766,2060.381592 263.721893,2059.921143 266.366669,2060.011719 
	C269.242706,2060.109863 271.008667,2060.351074 271.049286,2064.457031 
	C271.136383,2073.256836 271.592010,2073.366943 262.704163,2073.951660 
	C259.066528,2074.191162 255.916138,2074.471680 256.099152,2079.239746 
	C256.150299,2080.572021 256.188080,2081.956543 257.502594,2082.693115 
	C258.973572,2083.516846 260.117615,2082.535156 260.942017,2081.544189 
	C262.467987,2079.709473 263.956024,2077.527588 266.650085,2078.631104 
	C272.016327,2080.828857 275.119507,2079.903320 277.119812,2073.842773 
	C278.122711,2070.804199 282.524994,2071.732178 285.320190,2072.170410 
	C290.233734,2072.940430 294.964233,2074.031494 299.938660,2072.224121 
	C301.575378,2071.629395 304.353027,2071.485596 304.872620,2074.082520 
	C305.355438,2076.495850 303.072937,2077.248535 301.217224,2078.033447 
	C300.656799,2078.270508 300.231415,2078.826904 299.230560,2079.669434 
	C305.243103,2083.672607 312.621338,2082.378174 318.626068,2085.054688 
	C318.386139,2088.500244 315.946808,2087.759033 314.148895,2087.960205 
	C307.699860,2088.682861 301.055511,2088.232910 296.500488,2094.708496 
	C294.887695,2097.001221 290.745880,2096.344482 290.493713,2092.934326 
	C289.963593,2085.765625 286.441986,2085.797119 281.177185,2087.728027 
	C278.753876,2088.616699 275.840759,2087.304688 272.859955,2089.593994 
	C282.540283,2098.218506 292.401886,2104.147461 306.156342,2100.516846 
	C314.061920,2098.429932 321.996155,2099.722412 328.667206,2105.357910 
	C329.024963,2109.260498 323.851929,2106.848877 323.348785,2110.389648 
	C328.908722,2114.563721 336.228638,2113.166260 344.000641,2115.339600 
	C336.231781,2120.521240 328.165802,2115.983398 320.786804,2119.268555 
	C325.367218,2124.482666 329.052856,2129.731445 336.836761,2128.124512 
	C338.427948,2127.795654 340.764801,2128.279053 341.092773,2129.958008 
	C342.481232,2137.065186 347.865326,2135.876465 352.729950,2136.080811 
	C354.596191,2136.159180 357.246948,2135.960938 356.722595,2138.975586 
	C356.327576,2141.246826 354.127625,2141.796875 352.024841,2141.806885 
	C351.042664,2141.811523 349.933655,2141.785156 349.095184,2141.356201 
	C337.071075,2135.204590 325.071869,2139.225098 313.041718,2141.278809 
	C310.114075,2141.778564 307.101135,2141.949951 304.126831,2141.955566 
	C301.270203,2141.960938 298.881042,2142.619141 296.598145,2145.528320 
	C293.888947,2145.147461 291.739777,2143.302002 289.462036,2144.825928 
	C286.463928,2146.832031 283.599243,2149.125732 281.055542,2151.673340 
	C280.221405,2152.508789 281.471313,2154.076172 282.558685,2154.960449 
	C284.708588,2156.708252 287.870880,2154.985107 290.554657,2157.705566 
	C277.803802,2160.216309 265.686981,2165.201660 253.241974,2159.330811 
	C247.708664,2156.720459 243.774872,2161.039795 239.193710,2161.021729 
	C234.685745,2161.003906 230.474594,2155.484619 225.464325,2160.374023 
	C223.375229,2162.412598 221.062042,2160.156250 218.838913,2160.153076 
	C217.491608,2160.151367 215.928116,2160.109131 215.272919,2161.388672 
	C214.369568,2163.152832 215.664505,2164.447754 216.839691,2165.664795 
	C219.185577,2168.093994 222.210815,2167.949707 225.218231,2168.015625 
	C228.829361,2168.094482 232.558929,2167.973389 234.704742,2171.707031 
	C234.150345,2174.024170 232.289093,2174.110352 230.750549,2173.920654 
	C220.542618,2172.661865 210.452423,2176.195557 200.156113,2174.355713 
	C196.069229,2173.625488 191.897614,2176.769287 187.443085,2175.870605 
	C185.408401,2175.459961 183.320465,2174.896973 183.125977,2172.541992 
	C182.938721,2170.274414 185.014267,2170.144775 186.638779,2170.059570 
	C191.369843,2169.812012 195.185562,2165.138672 200.669479,2167.677490 
	C202.668945,2168.603271 204.936386,2166.288330 206.337402,2164.320068 
	C208.145660,2161.779785 207.168823,2159.807373 204.628159,2158.756104 
	C203.166870,2158.151611 201.219818,2157.768066 199.781860,2158.216553 
	C190.977783,2160.961670 181.955261,2159.689453 173.023254,2160.016602 
	C170.413269,2160.112061 168.046478,2159.981689 166.070816,2162.726562 
	C161.841797,2168.602539 161.815063,2169.256104 168.661896,2172.179688 
	C169.573959,2172.569092 170.427567,2172.922119 170.600571,2174.401855 
	C168.837585,2176.950684 165.944260,2175.822754 163.514084,2175.945068 
	C159.543137,2176.145264 155.522400,2175.642090 152.133514,2178.559814 
	C150.880112,2179.639160 149.029816,2180.335205 147.629883,2178.905029 
	C145.992920,2177.232666 147.562271,2175.950439 148.702118,2174.786133 
	C149.396042,2174.077393 150.393433,2173.579346 150.873154,2172.762695 
	C152.765732,2169.540039 151.610504,2166.980713 147.998718,2166.138672 
	C145.207703,2165.488037 142.008331,2167.020508 139.006561,2164.163574 
	C140.903946,2159.544922 147.343597,2156.861816 145.324127,2149.895508 
	C144.652695,2147.579590 149.497421,2144.603027 152.689392,2144.385010 
	C161.109055,2143.810303 169.609436,2143.369629 179.059814,2144.796143 
	C175.017792,2150.154297 168.740402,2146.086426 163.957779,2150.574463 
	C171.431946,2151.780029 176.244171,2156.864258 183.258820,2156.073486 
	C187.207977,2155.628174 191.372147,2156.527832 192.455170,2150.877686 
	C193.002640,2148.021240 195.820267,2147.935303 198.159775,2148.054199 
	C210.073578,2148.660156 221.973969,2147.121826 233.963913,2149.296143 
	C241.405396,2150.645752 248.891830,2147.662109 255.580612,2143.645996 
	C257.654358,2142.401123 257.633331,2140.536133 258.010406,2138.573975 
	C258.871429,2134.093750 255.506516,2131.177979 254.102875,2126.758301 
z"
        />
        <path
          fill="#7A91A4"
          opacity="1.000000"
          stroke="none"
          d="
M351.453308,2150.473633 
	C359.562592,2150.043945 367.067413,2149.972412 373.826355,2144.735107 
	C378.104218,2143.935791 380.925598,2147.349854 385.599243,2147.302979 
	C389.811127,2147.260498 387.215393,2151.281494 384.587524,2153.676758 
	C380.900787,2157.062988 377.299316,2156.835693 372.774384,2155.792480 
	C369.384277,2156.000000 366.762573,2156.000000 364.469452,2156.000000 
	C362.381042,2159.658936 368.434540,2159.839355 366.478668,2162.984619 
	C357.464264,2163.315674 348.901855,2167.175781 339.722351,2166.535645 
	C338.842072,2166.474365 337.508606,2167.005127 337.050873,2167.699707 
	C332.203033,2175.056885 326.063202,2172.956543 319.714294,2170.354736 
	C318.547791,2169.876709 317.063263,2169.901367 315.771271,2170.062012 
	C313.379089,2170.359619 309.680725,2168.771973 309.241333,2172.503174 
	C308.920074,2175.230957 312.233551,2175.291992 314.326447,2176.207275 
	C318.469147,2178.018799 319.069122,2180.357910 315.889313,2183.615479 
	C313.756409,2185.800781 311.942383,2188.039062 310.291748,2191.346924 
	C307.369568,2194.472656 304.102844,2193.922363 300.201721,2193.746582 
	C291.109100,2184.942627 287.865479,2184.857178 279.422913,2193.491943 
	C274.726257,2195.399902 270.617493,2195.272705 265.788879,2193.993652 
	C259.944275,2189.181396 262.746582,2185.255859 266.476685,2182.106689 
	C273.280487,2176.362793 280.747772,2172.342773 290.017365,2176.720215 
	C276.207520,2171.694092 264.604004,2177.569580 252.569244,2184.596680 
	C241.747314,2186.367188 231.673141,2189.104980 220.629486,2188.445312 
	C220.136459,2184.736816 217.483719,2182.980469 215.201279,2180.565430 
	C220.587280,2179.952637 224.729919,2177.005371 229.547852,2179.426025 
	C231.779587,2180.547363 234.524658,2179.413574 235.988647,2177.826660 
	C241.979645,2171.332764 250.425415,2172.304443 257.820404,2170.137695 
	C259.700806,2169.586670 262.031677,2169.963867 263.581024,2168.995361 
	C271.269714,2164.190430 279.643951,2163.667480 288.376587,2163.885254 
	C294.346069,2164.033936 299.287384,2161.528076 301.932617,2154.899902 
	C314.750488,2153.191650 327.273499,2149.428467 340.988708,2151.815430 
	C344.843109,2151.846191 347.978577,2152.540771 351.453308,2150.473633 
z"
        />
        <path
          fill="#7A91A4"
          opacity="1.000000"
          stroke="none"
          d="
M155.912262,2114.715820 
	C157.628479,2113.774902 159.595856,2114.040527 160.837311,2113.214600 
	C165.308167,2110.241211 169.032364,2108.023438 170.733963,2115.862549 
	C171.243820,2118.211670 173.883224,2117.733398 175.848770,2117.039551 
	C180.374725,2115.442383 183.802490,2115.695312 184.110870,2121.667725 
	C184.291107,2125.158203 186.668198,2125.966309 189.629349,2125.968750 
	C190.628189,2125.969727 191.670410,2126.197998 192.617752,2125.986572 
	C195.515533,2125.339111 199.004211,2129.062012 200.990250,2126.156738 
	C203.604263,2122.333008 196.685440,2121.730957 197.201752,2117.165771 
	C207.652344,2114.018066 217.579712,2116.943848 227.120789,2121.426025 
	C229.528503,2122.557373 231.809204,2124.826904 234.316330,2123.771240 
	C239.673386,2121.515137 243.971207,2124.144287 249.284988,2125.916504 
	C248.333771,2134.316650 240.845001,2133.440918 235.783783,2133.145508 
	C230.835922,2132.856934 228.252106,2134.072510 226.141876,2137.778809 
	C224.484131,2140.690186 221.992004,2142.757080 218.892258,2141.808594 
	C212.630157,2139.892578 205.796753,2141.077393 199.802551,2137.348145 
	C195.002151,2134.361816 189.680145,2133.522949 183.670639,2135.520752 
	C176.262726,2137.983643 168.944717,2139.372314 160.881805,2134.764160 
	C155.530701,2131.706055 147.815384,2134.960693 141.395538,2132.512207 
	C140.702209,2132.247559 139.150894,2132.807861 138.737152,2133.456787 
	C134.920044,2139.444580 125.934326,2135.942383 122.179810,2142.928467 
	C120.730507,2145.625488 112.102150,2141.383057 111.047867,2137.644043 
	C110.616409,2136.113770 111.059792,2134.854004 112.440422,2134.449463 
	C119.662476,2132.334229 126.245239,2128.343262 134.072357,2127.902588 
	C140.936127,2127.516357 147.748077,2126.209229 154.676987,2125.296387 
	C155.321777,2121.851562 154.139359,2118.441895 155.912262,2114.715820 
z"
        />
        <path
          fill="#71889C"
          opacity="1.000000"
          stroke="none"
          d="
M515.501404,2080.398193 
	C523.472900,2079.539795 531.072021,2080.992676 538.538147,2079.089111 
	C538.186523,2075.558594 535.848572,2076.019775 534.074036,2076.066406 
	C529.515747,2076.185303 525.491028,2075.721924 523.215271,2070.863037 
	C521.711060,2067.651855 518.770996,2069.024902 516.174927,2069.279541 
	C514.283020,2069.465332 513.065063,2068.135742 512.793579,2066.329834 
	C512.496277,2064.352295 514.159241,2062.458740 515.470947,2062.846191 
	C521.453491,2064.612793 526.474426,2058.406738 533.898560,2062.875244 
	C531.165771,2055.362305 534.095581,2051.642578 538.665100,2049.019775 
	C541.131287,2047.604248 543.380981,2048.393066 543.739075,2051.495850 
	C544.001587,2053.770264 544.247742,2056.384277 543.404541,2058.384521 
	C541.049255,2063.971436 543.395691,2066.652832 548.520386,2067.725586 
	C552.127136,2068.480225 553.830566,2068.787354 551.225586,2073.267334 
	C548.320618,2078.262939 554.084290,2078.052246 556.648010,2079.029541 
	C559.529114,2080.127930 563.161743,2077.418701 565.920532,2080.664551 
	C567.320251,2082.311279 570.638672,2082.972412 569.200012,2085.954102 
	C567.991943,2088.457764 565.682312,2086.811035 563.944275,2086.274658 
	C554.994202,2083.511963 545.582642,2086.878174 536.619995,2084.154053 
	C535.467590,2083.803711 533.968811,2084.157227 533.271851,2085.904297 
	C533.562378,2088.733643 536.187988,2088.929688 538.239685,2089.680176 
	C539.782227,2090.244385 542.265808,2089.961426 542.908691,2090.966064 
	C545.969788,2095.748291 548.269775,2101.278809 555.729736,2098.598633 
	C555.891235,2098.540283 556.849304,2099.874268 556.741638,2100.010498 
	C551.743713,2106.335449 561.789429,2107.416260 560.916992,2112.165527 
	C560.769897,2112.966309 559.834045,2113.789062 559.058472,2114.277832 
	C556.015686,2116.196533 552.828003,2116.652100 549.708496,2114.470703 
	C547.461426,2112.899414 545.136353,2111.328613 542.358154,2112.548584 
	C538.721191,2114.145752 541.280212,2117.127930 541.115784,2119.522217 
	C540.959656,2121.795166 539.036560,2121.710449 536.724854,2121.993408 
	C535.020142,2122.014648 534.023071,2122.013428 533.026184,2122.004395 
	C522.739685,2121.909912 522.739685,2121.909424 520.019897,2110.793945 
	C524.177734,2103.857422 524.177734,2103.857422 532.226685,2099.631348 
	C528.550537,2098.130859 524.249329,2099.657959 520.480591,2096.499756 
	C518.176514,2093.873047 515.488953,2092.232422 517.308105,2088.559326 
	C518.080811,2086.999023 516.459595,2085.608398 514.340820,2083.705078 
	C513.822693,2081.982666 514.292725,2081.302734 515.501404,2080.398193 
z"
        />
        <path
          fill="#7A91A4"
          opacity="1.000000"
          stroke="none"
          d="
M489.905426,2137.263672 
	C488.391266,2138.161865 486.338348,2137.574951 485.442230,2139.232910 
	C484.334747,2141.282227 486.352966,2141.963867 487.705109,2142.772461 
	C493.945923,2146.503906 500.368347,2145.438721 506.780457,2143.385742 
	C508.974243,2142.683350 511.039032,2141.577637 513.233398,2140.877930 
	C514.267273,2140.548340 515.106934,2141.382812 515.374451,2142.385010 
	C515.527893,2142.959961 515.414612,2143.729980 515.135315,2144.261719 
	C514.856018,2144.793457 514.261230,2145.198486 513.734131,2145.554688 
	C508.080933,2149.375732 508.070496,2149.383301 513.964722,2152.367920 
	C515.458008,2153.124268 517.119629,2153.839355 516.818176,2155.848389 
	C516.485962,2158.062744 514.437500,2158.052490 512.910095,2157.906982 
	C508.044037,2157.443359 503.387360,2161.367920 498.389282,2158.425049 
	C496.746033,2157.457520 494.099915,2157.251221 495.673584,2154.503662 
	C498.759735,2149.114746 494.005127,2149.064453 491.302521,2148.313477 
	C485.311157,2146.648926 479.610321,2149.861328 472.841064,2149.994141 
	C465.950989,2152.332031 460.954498,2151.216553 458.054993,2145.087402 
	C456.575836,2141.960693 453.935059,2140.997314 451.150909,2139.969971 
	C449.313019,2139.291260 448.272095,2138.120117 449.397156,2136.286133 
	C450.337799,2134.752930 451.951172,2134.224121 453.686859,2135.183350 
	C455.685699,2136.287842 457.381470,2138.032227 459.953735,2137.839111 
	C461.341980,2137.734619 462.590729,2137.400879 463.232086,2136.090576 
	C463.900360,2134.725342 463.477112,2133.187256 462.322266,2132.580566 
	C458.236908,2130.434814 455.796295,2127.568604 455.411896,2122.654541 
	C455.183289,2119.732422 451.896851,2120.458740 449.745148,2119.881592 
	C444.247925,2118.407227 440.764099,2113.920898 440.697632,2108.378418 
	C440.687225,2107.509277 441.170532,2106.359375 441.822815,2105.817383 
	C443.015198,2104.826172 444.601929,2105.297607 445.249664,2106.452148 
	C450.632385,2116.048340 459.815002,2113.813965 469.121521,2114.000000 
	C471.045868,2115.031006 471.151947,2116.290527 470.864594,2118.163818 
	C470.967224,2119.371338 471.332794,2119.912109 472.370514,2120.583740 
	C474.851013,2121.865967 475.662476,2123.597168 475.752930,2126.323975 
	C475.673737,2127.888184 475.309692,2128.894043 476.426056,2129.927246 
	C480.384033,2129.401611 482.811737,2125.093506 487.529602,2127.549805 
	C487.669281,2131.045166 489.007202,2133.734863 489.905426,2137.263672 
z"
        />
        <path
          fill="#61798F"
          opacity="1.000000"
          stroke="none"
          d="
M251.407303,2227.457031 
	C251.550659,2232.109619 248.467056,2231.952637 244.748001,2231.982910 
	C234.336258,2231.996826 224.669647,2232.027344 215.003204,2232.000000 
	C210.335037,2231.987061 205.651749,2231.702637 201.391083,2234.229736 
	C200.548767,2234.729736 199.449509,2235.368408 198.606705,2235.209717 
	C186.175980,2232.869873 173.705521,2238.510254 161.102692,2234.551270 
	C156.325745,2233.050781 150.823853,2236.136230 145.410690,2236.096191 
	C126.129028,2235.953857 106.973183,2232.781006 87.642418,2233.913818 
	C83.304756,2234.167969 79.340256,2232.184570 75.340523,2230.969727 
	C75.793228,2227.500244 78.189522,2228.076416 80.008087,2228.069824 
	C99.927414,2227.997559 119.886429,2227.064941 139.572830,2231.463379 
	C145.188766,2232.718262 150.539093,2229.592041 156.310638,2229.844971 
	C164.559921,2230.206299 172.804550,2229.733154 181.131653,2228.541260 
	C189.737976,2227.309082 198.763550,2228.490723 207.560242,2228.686523 
	C215.885925,2228.871094 223.940826,2227.975342 232.024750,2226.882812 
	C238.345474,2226.028564 244.534119,2225.468506 251.407303,2227.457031 
z"
        />
        <path
          fill="#7A91A4"
          opacity="1.000000"
          stroke="none"
          d="
M374.569977,2110.444824 
	C368.089508,2106.210938 373.269104,2102.749756 374.639374,2099.569336 
	C376.600800,2095.017090 381.464386,2094.758789 384.596649,2096.882080 
	C389.392242,2100.132324 392.153168,2098.219727 395.642883,2094.508057 
	C408.616638,2089.271729 419.558716,2093.633301 430.399261,2099.753418 
	C436.218048,2103.038574 437.379517,2105.418457 434.187805,2111.490479 
	C432.626495,2114.460449 430.814301,2117.772705 427.009613,2117.437500 
	C422.894470,2117.074707 424.244934,2112.759277 423.199646,2110.240479 
	C422.337067,2108.161865 422.296722,2105.743896 421.876190,2103.479492 
	C421.148468,2099.560303 419.099701,2097.543701 414.909149,2098.753174 
	C410.637970,2099.985840 410.575073,2102.603271 411.746063,2106.425781 
	C412.602570,2109.221924 417.192413,2110.969482 415.056976,2114.033447 
	C412.830994,2117.227295 407.859344,2116.546875 404.703217,2119.126953 
	C403.507965,2120.104004 401.574890,2118.740234 400.086609,2118.032471 
	C397.991821,2117.036377 395.924255,2115.959229 392.755859,2116.016113 
	C389.834534,2117.656250 388.334229,2116.797363 387.085968,2113.979248 
	C387.565826,2110.553223 389.961945,2109.942627 391.968445,2108.907471 
	C393.708282,2108.010254 395.505035,2107.205811 397.553467,2104.537842 
	C391.446167,2105.770752 386.682373,2107.658936 381.225952,2109.556641 
	C378.898468,2110.839844 377.129669,2111.507812 374.569977,2110.444824 
z"
        />
        <path
          fill="#627A90"
          opacity="1.000000"
          stroke="none"
          d="
M263.482422,2191.532715 
	C268.731293,2191.997314 273.466217,2191.984619 279.087799,2191.950684 
	C285.208099,2187.788086 290.739136,2187.752930 297.256165,2191.339844 
	C301.446381,2191.958984 304.868988,2191.972168 309.147308,2191.982910 
	C309.643250,2194.159424 308.579407,2195.758301 306.205933,2195.828369 
	C302.525665,2195.937500 298.982697,2197.426270 295.463165,2197.550049 
	C272.739777,2198.351074 250.009918,2198.333008 225.383408,2197.293945 
	C231.514313,2191.522949 238.561157,2196.558350 242.724533,2191.114014 
	C232.035873,2193.680420 221.178513,2190.457031 210.625732,2192.886230 
	C206.661255,2193.798828 203.632050,2193.722412 200.393402,2189.343994 
	C206.646149,2186.987549 212.561096,2188.473633 219.125381,2187.963135 
	C223.500992,2187.151855 227.101517,2186.706299 230.430557,2185.507324 
	C237.001770,2183.140869 243.610489,2182.708740 251.194702,2183.973389 
	C254.093445,2182.328125 256.128052,2183.014648 256.691010,2185.180420 
	C257.603027,2188.688721 260.530518,2189.489014 263.482422,2191.532715 
z"
        />
        <path
          fill="#7A91A4"
          opacity="1.000000"
          stroke="none"
          d="
M519.543152,2096.500977 
	C524.922485,2096.014893 529.845215,2096.002930 534.767273,2096.057129 
	C536.771851,2096.079346 538.982361,2096.266357 539.367981,2098.713379 
	C539.743591,2101.096680 537.715759,2102.657715 535.910095,2102.803955 
	C530.091492,2103.274902 525.782227,2106.484375 520.719788,2109.897461 
	C517.794373,2109.382812 515.588745,2108.765625 512.696289,2108.069336 
	C509.076080,2107.617676 506.207611,2106.980225 502.886200,2105.608398 
	C500.837860,2103.906982 499.556549,2102.173096 496.617676,2102.028809 
	C490.337982,2101.929688 484.671051,2101.988281 479.019714,2101.719238 
	C477.031189,2101.624268 474.903961,2100.644775 475.224731,2098.081543 
	C475.497955,2095.898193 477.831329,2095.672852 479.147583,2096.165527 
	C485.890076,2098.689209 493.128937,2096.905273 499.626099,2096.709229 
	C506.117157,2096.513672 512.602112,2096.963623 519.543152,2096.500977 
z"
        />
        <path
          fill="#50677C"
          opacity="1.000000"
          stroke="none"
          d="
M332.318848,2251.318115 
	C318.538025,2252.369385 305.375977,2254.697510 292.051758,2254.427979 
	C279.902435,2254.182129 267.754211,2253.880615 255.481689,2252.750977 
	C259.862579,2249.024902 265.222778,2248.824951 271.194061,2247.927979 
	C291.374878,2247.927490 310.735931,2247.980713 331.032288,2248.031250 
	C332.770752,2248.773193 333.055511,2249.680420 332.318848,2251.318115 
z"
        />
        <path
          fill="#7A91A4"
          opacity="1.000000"
          stroke="none"
          d="
M416.800720,2152.376709 
	C414.034302,2149.539795 411.214691,2150.144043 408.622162,2149.931885 
	C406.531281,2149.760498 404.379578,2149.315430 404.532196,2146.698975 
	C404.721893,2143.447266 407.098053,2141.608398 409.981140,2142.587891 
	C414.185822,2144.016602 418.587433,2143.400879 422.707092,2144.603027 
	C426.604675,2145.740967 429.197357,2145.602295 429.421051,2140.579102 
	C429.564087,2137.366211 431.915039,2137.959961 433.609253,2139.104004 
	C438.465759,2142.383057 442.894714,2146.022217 449.576202,2145.160400 
	C452.111908,2144.833496 454.447388,2147.366943 452.237305,2151.381836 
	C448.694061,2155.647705 444.321228,2155.365723 440.085693,2154.936035 
	C432.593231,2154.175537 425.045502,2153.982910 416.800720,2152.376709 
z"
        />
        <path
          fill="#71889C"
          opacity="1.000000"
          stroke="none"
          d="
M372.502258,2156.455566 
	C374.979340,2152.794922 378.882324,2154.417725 383.198059,2154.000000 
	C384.124176,2157.907471 383.087982,2162.764648 390.042603,2160.514404 
	C391.750183,2159.962158 393.639618,2161.073730 393.627930,2162.981445 
	C393.581482,2170.572266 398.770172,2170.123047 403.883301,2170.033203 
	C405.493225,2170.005127 407.206665,2169.863037 408.893860,2171.793701 
	C407.183136,2175.629395 402.428711,2173.292236 399.861786,2175.876709 
	C396.197083,2179.566406 394.415710,2174.178711 391.653229,2173.170898 
	C390.125488,2172.613770 388.489685,2172.312744 386.879211,2172.029053 
	C381.366425,2171.058350 381.362488,2171.080811 382.369812,2165.229248 
	C381.748596,2165.112061 381.105408,2164.828369 380.508972,2164.905518 
	C373.345703,2165.833496 373.366058,2165.848389 373.647888,2158.749756 
	C373.671814,2158.146240 373.226105,2157.524414 372.502258,2156.455566 
z"
        />
        <path
          fill="#71889C"
          opacity="1.000000"
          stroke="none"
          d="
M416.096191,2152.711914 
	C420.933929,2149.200195 426.448486,2149.171387 431.473785,2150.491699 
	C437.731720,2152.135742 443.920898,2151.827393 451.079071,2151.971191 
	C452.984039,2154.061523 456.226654,2153.603516 457.241486,2156.853516 
	C451.542603,2158.509766 446.529938,2160.839111 440.332581,2158.638672 
	C436.081787,2157.129150 431.174591,2157.264893 426.790436,2158.818604 
	C421.093781,2160.836914 418.129974,2158.358398 416.096191,2152.711914 
z"
        />
        <path
          fill="#5D758A"
          opacity="1.000000"
          stroke="none"
          d="
M344.382019,2153.350098 
	C335.361359,2154.180420 326.784424,2153.364502 318.317902,2155.687500 
	C313.416748,2157.032715 308.544556,2154.957764 302.868774,2154.172363 
	C299.202667,2154.063965 296.354462,2153.975342 293.513245,2153.782959 
	C292.156433,2153.690918 290.948212,2152.989990 291.412231,2151.481934 
	C291.842194,2150.084717 293.434723,2150.234131 295.356781,2149.966309 
	C306.288727,2149.593018 316.533600,2150.490723 326.737122,2148.284912 
	C331.863434,2147.176514 337.317841,2147.776611 343.218323,2149.869873 
	C344.775177,2150.766602 345.047363,2151.692139 344.382019,2153.350098 
z"
        />
        <path
          fill="#7790A8"
          opacity="1.000000"
          stroke="none"
          d="
M387.649475,2111.395996 
	C388.192780,2113.788086 388.737061,2115.322266 391.375305,2115.899902 
	C392.234589,2120.383301 397.107880,2120.911133 398.757996,2124.129150 
	C399.377289,2125.336670 399.904449,2126.521484 399.344727,2127.873779 
	C398.565857,2129.755127 396.787262,2130.177734 395.231201,2129.763184 
	C390.442169,2128.488281 384.996338,2127.256104 382.772552,2122.565186 
	C380.367950,2117.492676 377.518036,2117.377441 372.674744,2119.704590 
	C371.666504,2116.950928 373.007202,2114.258789 373.912109,2110.727295 
	C375.611694,2109.505127 377.219788,2109.010254 379.412720,2108.257812 
	C383.272095,2106.732422 385.126343,2109.178467 387.649475,2111.395996 
z"
        />
        <path
          fill="#71889C"
          opacity="1.000000"
          stroke="none"
          d="
M466.409943,2120.407715 
	C466.972107,2118.167236 469.447968,2117.543945 469.922668,2114.618652 
	C471.507324,2114.000000 473.002472,2114.000000 475.245361,2114.000000 
	C476.388885,2114.491699 476.839630,2114.926758 477.668091,2115.647461 
	C482.387665,2116.387207 485.476532,2119.701904 489.422607,2121.230225 
	C492.635712,2122.474365 491.740723,2125.390137 488.579224,2127.687500 
	C485.414764,2128.003662 482.977844,2128.186768 481.872314,2131.167725 
	C480.752808,2134.186035 478.047241,2133.915283 474.776306,2133.991943 
	C472.828491,2132.630859 472.577271,2131.092041 473.560913,2128.698486 
	C473.885986,2125.727783 474.246307,2123.243164 470.676575,2122.128662 
	C468.808807,2121.960205 467.739136,2121.591553 466.409943,2120.407715 
z"
        />
        <path
          fill="#8497A7"
          opacity="1.000000"
          stroke="none"
          d="
M88.517242,2134.526367 
	C89.243141,2131.604980 93.098175,2132.419189 93.881897,2128.679199 
	C97.855682,2124.727051 101.447701,2129.090820 105.282898,2128.488281 
	C106.699898,2128.265869 107.511154,2129.899902 107.353256,2131.303223 
	C107.191856,2132.738037 106.054192,2133.835693 104.635971,2133.696045 
	C99.333809,2133.173584 94.355354,2136.077148 88.517242,2134.526367 
z"
        />
        <path
          fill="#7A91A4"
          opacity="1.000000"
          stroke="none"
          d="
M463.640472,2102.354980 
	C459.846069,2104.003418 456.668182,2104.516602 453.387695,2103.317627 
	C452.093903,2102.844482 451.116943,2101.861816 450.913361,2100.509521 
	C450.632721,2098.644775 451.695312,2097.195068 453.363953,2096.744629 
	C457.931549,2095.510986 462.643860,2095.101318 466.258270,2098.831543 
	C468.006836,2100.635986 465.829590,2101.488770 463.640472,2102.354980 
z"
        />
        <path
          fill="#71889C"
          opacity="1.000000"
          stroke="none"
          d="
M501.484497,2103.502441 
	C505.324615,2104.115479 508.920441,2103.388184 511.652222,2107.368164 
	C510.905975,2109.883057 508.837067,2110.432373 506.575012,2111.741455 
	C504.645325,2113.193359 503.093353,2113.552002 500.674072,2112.495117 
	C500.001404,2109.859375 499.999176,2107.728760 499.997253,2104.798828 
	C500.324341,2103.680176 500.651123,2103.360107 501.484497,2103.502441 
z"
        />
        <path
          fill="#7A91A4"
          opacity="1.000000"
          stroke="none"
          d="
M195.307861,2144.185547 
	C194.093796,2144.820312 193.523575,2145.232422 192.900864,2145.339355 
	C190.141434,2145.814453 187.113617,2147.235596 185.156387,2143.919922 
	C184.876572,2143.445801 185.548874,2141.805908 186.167709,2141.507080 
	C189.267319,2140.009277 192.364716,2137.402100 196.032104,2139.721191 
	C197.733627,2140.797119 197.332169,2142.481689 195.307861,2144.185547 
z"
        />
        <path
          fill="#7790A8"
          opacity="1.000000"
          stroke="none"
          d="
M499.186279,2111.993896 
	C501.506134,2111.990234 503.008636,2111.990234 505.262878,2111.990234 
	C505.667145,2113.666016 505.037659,2115.465332 503.208618,2115.615234 
	C498.562500,2115.995605 493.782562,2116.853516 488.244720,2114.405029 
	C491.967102,2111.070312 495.347229,2112.344971 499.186279,2111.993896 
z"
        />
        <path
          fill="#9DAAB4"
          opacity="1.000000"
          stroke="none"
          d="
M51.785866,2263.165527 
	C46.564266,2263.632324 41.832943,2264.818359 37.537216,2262.014404 
	C42.612206,2260.342041 47.707638,2257.725098 51.785866,2263.165527 
z"
        />
        <path
          fill="#71889C"
          opacity="1.000000"
          stroke="none"
          d="
M498.004639,2080.733643 
	C500.780914,2080.007812 503.555695,2080.005859 507.163116,2080.005371 
	C508.719086,2080.795166 509.180359,2081.715088 509.324768,2083.549316 
	C507.785919,2088.194336 504.662292,2087.971924 500.698547,2087.735840 
	C497.725220,2086.289795 498.052704,2083.816895 498.004639,2080.733643 
z"
        />
        <path
          fill="#7A91A4"
          opacity="1.000000"
          stroke="none"
          d="
M500.678894,2088.304688 
	C501.637329,2085.292480 504.990692,2086.611572 507.560852,2084.501953 
	C509.367279,2082.806152 510.909210,2082.454590 513.318787,2083.513428 
	C513.991455,2084.006836 514.000000,2084.000000 513.996338,2084.004883 
	C514.247253,2088.155762 512.468750,2090.212402 508.147186,2090.014893 
	C505.792786,2089.907227 503.497345,2089.801025 500.678894,2088.304688 
z"
        />
        <path
          fill="#7790A8"
          opacity="1.000000"
          stroke="none"
          d="
M411.352539,2125.160156 
	C412.495453,2124.513428 413.121582,2124.259521 413.757599,2124.231445 
	C416.223602,2124.123291 418.454834,2124.731201 418.930176,2127.497314 
	C419.189941,2129.009033 417.754547,2129.787598 416.398224,2129.794678 
	C413.589935,2129.809326 409.946014,2130.703613 411.352539,2125.160156 
z"
        />
        <path
          fill="#8DA0AE"
          opacity="1.000000"
          stroke="none"
          d="
M87.998764,2146.000000 
	C84.635735,2145.349365 82.910362,2149.814453 79.274773,2148.878662 
	C77.966942,2144.571533 83.422600,2144.468018 83.885345,2140.715820 
	C84.878822,2140.067627 85.759750,2140.057617 87.301376,2140.053223 
	C89.155830,2141.400879 89.450684,2142.934326 88.467484,2145.319824 
	C87.998169,2146.000000 88.000000,2146.000000 87.998764,2146.000000 
z"
        />
        <path
          fill="#8497A7"
          opacity="1.000000"
          stroke="none"
          d="
M80.575195,2268.297363 
	C83.375114,2268.911377 85.515305,2269.592773 87.655495,2270.274170 
	C85.178398,2271.962646 82.972435,2274.686523 79.847641,2273.499756 
	C76.149597,2272.095459 76.508636,2270.041260 80.575195,2268.297363 
z"
        />
        <path
          fill="#8497A7"
          opacity="1.000000"
          stroke="none"
          d="
M88.729156,2145.998047 
	C87.993217,2144.513916 87.988258,2143.027588 87.972687,2140.800293 
	C88.612358,2140.065918 89.264801,2140.041504 89.912560,2140.085693 
	C92.290092,2140.248291 95.023582,2140.797119 94.881493,2143.555908 
	C94.715256,2146.783691 91.529793,2145.670410 88.729156,2145.998047 
z"
        />
        <path
          fill="#6A8297"
          opacity="1.000000"
          stroke="none"
          d="
M332.607056,2251.921875 
	C331.864655,2251.029541 331.912903,2250.173096 331.964355,2248.672852 
	C334.070251,2247.013184 336.141296,2245.892578 339.293091,2246.026367 
	C340.389557,2250.881104 337.753693,2252.374756 332.607056,2251.921875 
z"
        />
        <path
          fill="#7A91A4"
          opacity="1.000000"
          stroke="none"
          d="
M199.205322,2183.653320 
	C197.282043,2183.842285 195.540176,2183.825439 195.347672,2182.455078 
	C195.010117,2180.052490 197.170319,2180.097168 198.672653,2180.325195 
	C200.552277,2180.610107 201.637817,2181.641113 199.205322,2183.653320 
z"
        />
        <path
          fill="#7790A8"
          opacity="1.000000"
          stroke="none"
          d="
M501.720490,2103.437500 
	C501.991058,2103.965332 500.994354,2103.985352 500.495941,2103.992676 
	C498.901611,2103.645264 497.805695,2103.290527 496.354279,2102.467773 
	C498.343842,2099.138184 499.896088,2101.028564 501.720490,2103.437500 
z"
        />
        <path
          fill="#7790A8"
          opacity="1.000000"
          stroke="none"
          d="
M369.982544,2127.349121 
	C368.702850,2127.805176 367.250854,2127.685791 367.039001,2126.081787 
	C366.891296,2124.963135 367.853668,2124.458008 369.408997,2124.077637 
	C369.986664,2124.881348 369.989105,2125.778809 369.982544,2127.349121 
z"
        />
        <path
          fill="#7790A8"
          opacity="1.000000"
          stroke="none"
          d="
M425.911591,2127.110840 
	C424.555420,2126.367676 424.539398,2125.619629 425.507965,2124.958740 
	C425.710724,2124.820557 426.180450,2124.825928 426.378021,2124.969971 
	C427.323639,2125.658691 427.288940,2126.406006 425.911591,2127.110840 
z"
        />
        <path
          fill="#7A91A4"
          opacity="1.000000"
          stroke="none"
          d="
M444.712067,2100.857666 
	C443.223907,2101.273682 442.683014,2100.753174 442.913086,2099.597168 
	C442.960876,2099.357178 443.301697,2099.034180 443.544434,2098.998047 
	C444.711731,2098.824219 445.201630,2099.392334 444.712067,2100.857666 
z"
        />
        <path
          fill="#7F98AE"
          opacity="1.000000"
          stroke="none"
          d="
M148.668610,2114.000000 
	C148.203491,2112.724365 148.309464,2111.268555 149.916107,2111.048340 
	C151.037155,2110.894531 151.540192,2111.862305 151.910339,2113.422852 
	C151.111404,2114.000000 150.223099,2114.000000 148.668610,2114.000000 
z"
        />
        <path
          fill="#8497A7"
          opacity="1.000000"
          stroke="none"
          d="
M90.019119,2179.453125 
	C89.917702,2178.237549 90.107864,2178.198730 90.019119,2179.453125 
z"
        />
        <path
          fill="#7A91A4"
          opacity="1.000000"
          stroke="none"
          d="
M495.561951,2136.247559 
	C495.751556,2136.634033 495.374084,2137.138428 494.430115,2137.761230 
	C494.241302,2137.375732 494.618408,2136.870850 495.561951,2136.247559 
z"
        />
        <path
          fill="#7A91A4"
          opacity="1.000000"
          stroke="none"
          d="
M477.758118,2115.567139 
	C477.370605,2115.755859 476.859406,2115.375000 476.225189,2114.423828 
	C476.612457,2114.235840 477.123230,2114.617188 477.758118,2115.567139 
z"
        />
        <path
          fill="#98999E"
          opacity="1.000000"
          stroke="none"
          d="
M248.851059,3352.228271 
	C248.062729,3349.113770 249.327850,3347.883789 251.440247,3347.204102 
	C256.708344,3345.508545 261.875244,3343.045898 267.274384,3342.323486 
	C274.200470,3341.396484 278.873474,3339.079590 279.682343,3331.548584 
	C279.765076,3330.778320 280.958954,3330.127197 281.930359,3328.905273 
	C284.717224,3326.896973 287.408691,3325.988037 290.998840,3325.516357 
	C292.696899,3324.909668 293.738525,3324.751709 295.543762,3325.268555 
	C296.855560,3326.578857 297.048859,3327.600098 296.338654,3329.325684 
	C294.824524,3334.781738 302.724487,3335.537598 300.789703,3340.780518 
	C299.375671,3344.612305 295.733093,3346.675049 293.521240,3349.989502 
	C288.509979,3357.499512 282.680237,3364.462891 276.624146,3372.063477 
	C269.761871,3373.712891 263.410828,3372.336426 256.922058,3373.312744 
	C250.986450,3374.205811 247.989304,3368.452881 249.248016,3362.387695 
	C249.936737,3359.069336 250.635666,3356.048096 248.851059,3352.228271 
z"
        />
        <path
          fill="#122230"
          opacity="1.000000"
          stroke="none"
          d="
M539.224609,3231.469238 
	C538.079346,3229.257080 537.716614,3226.997314 535.509644,3226.430176 
	C524.115601,3223.502686 513.361694,3218.089844 501.234192,3217.934570 
	C497.131500,3217.882080 493.506073,3219.742188 488.688171,3219.860840 
	C485.134125,3215.686035 486.777985,3211.612305 488.463257,3206.861328 
	C489.898621,3205.002930 491.252197,3204.443848 493.485107,3204.257080 
	C496.300110,3204.644531 498.414520,3205.400391 501.200897,3204.098633 
	C504.238892,3203.271484 506.646210,3203.310547 509.805298,3203.734375 
	C522.825378,3204.575439 535.727844,3208.694824 546.523193,3197.059082 
	C552.378357,3195.410156 556.784363,3197.240234 561.590942,3200.449951 
	C565.052429,3204.178223 564.792114,3208.744873 567.369446,3212.917725 
	C568.136353,3215.117676 568.323975,3216.641846 568.176270,3218.951172 
	C567.915771,3220.178711 567.756836,3220.652344 567.215698,3221.760986 
	C562.657654,3225.503662 558.177856,3227.979492 552.373779,3229.103516 
	C550.138245,3229.140869 548.525452,3229.005127 546.628662,3230.404541 
	C544.159607,3231.965820 542.067261,3232.345703 539.224609,3231.469238 
z"
        />
        <path
          fill="#2F4457"
          opacity="1.000000"
          stroke="none"
          d="
M496.577393,3206.499512 
	C494.445282,3206.072266 492.965057,3206.059082 490.744751,3206.032715 
	C482.556824,3207.998535 474.717133,3206.994629 467.350311,3207.033203 
	C454.046997,3207.103027 441.964417,3212.053711 429.110840,3213.219971 
	C422.893158,3213.783691 418.327301,3218.122070 412.427307,3221.216064 
	C408.468079,3221.800537 405.084015,3221.896729 400.853760,3222.011230 
	C399.376984,3220.935059 398.430939,3220.302490 396.593597,3220.060059 
	C393.387756,3219.984131 390.759033,3220.184326 388.170441,3219.901367 
	C385.022552,3219.556885 381.112000,3219.788818 380.828644,3215.260742 
	C380.565338,3211.052490 383.830475,3209.680176 387.222900,3209.331787 
	C391.516205,3208.890869 395.503296,3207.684082 400.036316,3205.417969 
	C402.611145,3203.927246 404.685394,3203.477051 407.553772,3203.080078 
	C409.796844,3203.026855 411.251770,3202.863037 413.310455,3202.027832 
	C417.155640,3200.862061 420.333679,3199.978027 424.275635,3199.272217 
	C427.370178,3199.197266 429.487244,3198.365967 432.119812,3196.749023 
	C433.789551,3196.215332 434.792725,3196.081543 436.601257,3196.121094 
	C442.306091,3197.488037 446.324646,3194.359131 450.701416,3193.262695 
	C469.682281,3188.508301 488.730286,3194.532227 507.734406,3192.552979 
	C511.872986,3192.121826 516.338928,3194.580078 517.287598,3199.640381 
	C518.057007,3203.744141 515.612061,3205.716553 510.824493,3206.050049 
	C507.246124,3206.104004 504.490875,3206.094482 500.909149,3206.090332 
	C499.256226,3206.802734 498.305084,3207.075684 496.577393,3206.499512 
z"
        />
        <path
          fill="#233444"
          opacity="1.000000"
          stroke="none"
          d="
M319.005859,3240.058350 
	C305.235626,3237.165283 291.183044,3239.983643 278.240570,3231.635986 
	C283.601501,3228.837891 288.731995,3230.166016 293.342194,3228.728760 
	C303.320190,3225.617432 313.424591,3222.327148 324.228302,3223.961426 
	C326.809784,3224.352051 330.084991,3223.310303 331.766296,3225.565186 
	C335.624237,3230.740234 340.911133,3230.051758 346.166443,3229.990967 
	C362.060883,3229.807373 377.953308,3229.731689 393.730957,3232.089600 
	C396.007416,3232.429688 398.221771,3233.185547 401.293274,3233.825684 
	C402.983734,3233.912109 403.845856,3233.921631 405.355774,3233.937012 
	C407.331207,3235.286865 407.506866,3236.864258 406.464447,3239.280762 
	C405.099030,3239.979980 404.212555,3239.989502 402.661255,3239.991455 
	C397.149384,3240.008545 392.302338,3239.995117 386.728271,3239.992676 
	C378.235748,3237.872314 370.591827,3238.127197 362.541931,3239.208984 
	C352.454163,3240.564941 342.091919,3239.897217 331.847412,3240.058350 
	C327.877747,3240.120850 323.906097,3240.061768 319.005859,3240.058350 
z"
        />
        <path
          fill="#A9AEB3"
          opacity="1.000000"
          stroke="none"
          d="
M380.999023,3313.606201 
	C380.484863,3314.073486 379.910767,3314.491699 379.778870,3314.821289 
	C376.489136,3320.258545 371.627106,3318.930908 366.203308,3318.235596 
	C353.472778,3307.403564 353.596771,3306.607178 369.211365,3296.464844 
	C377.012787,3290.522217 385.293396,3288.268799 394.994720,3287.679443 
	C406.482544,3283.644531 416.744904,3278.610107 428.677979,3276.598633 
	C435.342133,3288.371826 435.021576,3289.537842 423.337646,3297.138184 
	C409.426300,3303.869873 394.770477,3306.656006 380.999023,3313.606201 
z"
        />
        <path
          fill="#233444"
          opacity="1.000000"
          stroke="none"
          d="
M412.716736,3221.800781 
	C413.981079,3213.913086 420.630310,3211.436768 427.198975,3210.247559 
	C435.007416,3208.833252 442.950195,3208.343750 450.311981,3204.982910 
	C451.490021,3204.445312 452.869690,3204.169434 454.171143,3204.111816 
	C461.278351,3203.797607 468.262695,3205.580566 475.562103,3202.560303 
	C479.093750,3201.099121 484.430969,3204.001709 489.477692,3205.474121 
	C490.192322,3210.244629 490.005035,3214.413818 488.005432,3219.087402 
	C483.815826,3219.857666 479.783325,3219.876221 474.882080,3219.896973 
	C472.814392,3219.991943 471.723541,3220.369385 470.334106,3221.514648 
	C462.994751,3222.564697 456.221863,3221.572754 449.733551,3219.473389 
	C444.177795,3217.676025 438.930664,3217.669434 432.783844,3219.851074 
	C429.594421,3220.160156 427.070129,3219.537842 424.413147,3221.452637 
	C420.490631,3221.862305 417.035980,3221.879883 412.716736,3221.800781 
z"
        />
        <path
          fill="#3A4B59"
          opacity="1.000000"
          stroke="none"
          d="
M430.485870,3278.587402 
	C425.683563,3277.565674 421.871033,3279.141113 418.550171,3280.876465 
	C411.808868,3284.399414 404.684357,3286.267578 396.576050,3287.589355 
	C398.018646,3282.550781 403.237610,3282.300293 407.255157,3280.714111 
	C436.473633,3269.178711 465.819244,3257.962646 495.186432,3246.808350 
	C499.784576,3245.062012 504.358978,3242.933594 510.207275,3242.982910 
	C513.275330,3244.791260 514.967896,3246.778809 515.182068,3250.369629 
	C514.900330,3251.454346 514.695618,3251.862061 513.933960,3252.670410 
	C499.158447,3253.024414 488.234711,3261.092041 476.146667,3268.137207 
	C470.934540,3269.350830 466.222626,3268.267334 461.101562,3269.717285 
	C459.635986,3271.645508 456.268402,3270.205078 457.021301,3273.601074 
	C458.169220,3274.597656 458.653595,3275.351074 459.161072,3276.930176 
	C459.228912,3278.783447 458.864563,3279.772705 457.542511,3281.178223 
	C447.881714,3285.048584 439.503143,3282.430176 430.485870,3278.587402 
z"
        />
        <path
          fill="#3A4B59"
          opacity="1.000000"
          stroke="none"
          d="
M525.953430,3238.198242 
	C526.574524,3237.723633 526.945557,3237.474365 527.165894,3237.511963 
	C537.567810,3239.029541 545.197144,3232.206055 554.009949,3229.260254 
	C557.066589,3228.238281 559.572937,3225.669922 563.753235,3225.816650 
	C566.312012,3225.970947 568.355347,3226.814453 570.337769,3224.394531 
	C571.147034,3223.687012 571.505066,3223.431641 572.503418,3222.961426 
	C574.075073,3222.661865 574.982605,3222.781738 576.473572,3223.452393 
	C582.264038,3230.033691 579.179382,3234.344727 573.522095,3238.768555 
	C570.086792,3249.719238 560.453491,3246.984131 553.348328,3249.394043 
	C551.982666,3249.857422 545.791626,3247.370361 550.631775,3253.005127 
	C551.685730,3254.232422 551.047729,3256.641846 549.565125,3257.450439 
	C543.463074,3260.779053 536.890015,3263.394531 529.937195,3263.201660 
	C523.701355,3263.028564 520.965576,3258.260010 519.960205,3251.820312 
	C520.053467,3248.230713 520.155212,3245.302246 519.819702,3241.756348 
	C519.826599,3241.135010 519.597290,3240.959717 519.649475,3240.825195 
	C521.037170,3238.507568 523.439209,3239.300049 525.953430,3238.198242 
z"
        />
        <path
          fill="#3F5467"
          opacity="1.000000"
          stroke="none"
          d="
M368.976898,3295.845215 
	C370.342102,3303.958252 362.036865,3303.174805 358.534363,3306.789551 
	C358.694427,3311.110107 364.805115,3309.540771 364.794739,3314.448486 
	C362.588440,3319.775391 359.279572,3316.385010 356.435333,3315.999756 
	C349.646271,3315.079834 342.865936,3312.268799 335.576355,3316.617920 
	C334.571716,3317.002441 334.177673,3317.164062 333.187164,3317.524414 
	C331.426971,3318.071533 330.244720,3318.358398 328.578156,3319.041016 
	C327.713287,3319.500000 327.370667,3319.689941 326.517212,3320.172363 
	C325.661072,3320.653564 325.315735,3320.842041 324.434204,3321.287598 
	C323.528625,3321.697998 323.159241,3321.850830 322.227600,3322.217529 
	C319.475098,3322.870117 318.217682,3324.539307 316.337585,3326.545654 
	C314.985535,3327.573242 314.094025,3328.009521 312.373810,3328.552734 
	C306.342224,3329.962402 301.432465,3328.438721 295.751465,3326.511719 
	C294.234497,3326.020264 293.490997,3325.914307 292.224976,3325.593262 
	C292.260559,3324.575684 292.642639,3323.343750 293.402191,3323.031982 
	C317.968201,3312.951416 342.760406,3303.482422 368.976898,3295.845215 
z"
        />
        <path
          fill="#626E74"
          opacity="1.000000"
          stroke="none"
          d="
M566.508850,3228.246338 
	C562.993591,3228.092773 559.716614,3227.487549 557.517395,3229.803223 
	C550.650757,3237.033203 540.294556,3236.575684 532.355225,3241.455811 
	C530.561707,3242.558350 528.602234,3241.239258 527.620667,3238.277344 
	C530.784180,3235.657715 534.182251,3233.766113 538.103210,3231.521484 
	C540.778625,3230.656738 542.931091,3230.145020 545.639282,3229.256104 
	C546.379944,3227.566650 547.132202,3226.966797 549.206665,3227.452148 
	C554.809448,3225.961914 559.658142,3224.099854 565.222046,3222.125000 
	C568.133118,3220.383545 570.226807,3220.243652 572.165222,3223.321289 
	C572.169983,3224.204102 572.028992,3224.926758 571.996948,3225.293457 
	C570.836975,3227.438965 569.057617,3228.159180 566.508850,3228.246338 
z"
        />
        <path
          fill="#A9ACA9"
          opacity="1.000000"
          stroke="none"
          d="
M519.083740,3241.315918 
	C522.263977,3242.753662 526.261353,3243.724365 522.249146,3248.412109 
	C520.896057,3252.101074 518.413391,3252.055420 514.985657,3251.414062 
	C514.244141,3248.208496 511.133667,3247.015137 510.891663,3243.812256 
	C513.429504,3242.636475 515.885132,3242.066650 519.083740,3241.315918 
z"
        />
        <path
          fill="#1B2B3A"
          opacity="1.000000"
          stroke="none"
          d="
M406.809509,3239.987305 
	C405.989655,3238.464600 405.993835,3236.958740 406.000793,3234.697754 
	C411.177490,3231.776611 415.774750,3234.703125 422.242889,3234.941406 
	C417.187775,3239.737793 412.486115,3240.093018 406.809509,3239.987305 
z"
        />
        <path
          fill="#1B2B3A"
          opacity="1.000000"
          stroke="none"
          d="
M386.553131,3240.536377 
	C390.735168,3236.520752 395.817383,3237.856934 401.437805,3239.448242 
	C397.260681,3243.442627 392.177032,3242.183594 386.553131,3240.536377 
z"
        />
        <path
          fill="#1B2B3A"
          opacity="1.000000"
          stroke="none"
          d="
M424.678406,3221.890137 
	C424.932861,3217.458496 427.707458,3217.494141 431.525970,3219.459473 
	C430.304443,3222.295898 427.803009,3221.933350 424.678406,3221.890137 
z"
        />
        <path
          fill="#122230"
          opacity="1.000000"
          stroke="none"
          d="
M470.502747,3221.881348 
	C470.345612,3220.151367 470.444916,3217.974365 473.645569,3219.489990 
	C473.354034,3221.006348 472.397614,3221.675049 470.502747,3221.881348 
z"
        />
        <path
          fill="#626E74"
          opacity="1.000000"
          stroke="none"
          d="
M525.726196,3237.662598 
	C525.196777,3239.459717 523.282532,3240.064697 520.442688,3240.485840 
	C520.221313,3237.640625 522.623230,3237.744629 525.726196,3237.662598 
z"
        />
        <path
          fill="#637E95"
          opacity="1.000000"
          stroke="none"
          d="
M2462.492188,2305.248047 
	C2454.541504,2305.920166 2446.884766,2307.173828 2439.482910,2301.335693 
	C2438.927734,2306.714844 2444.003174,2307.360596 2443.922363,2311.313721 
	C2441.410156,2317.380371 2434.997314,2315.724121 2430.533203,2319.210205 
	C2426.585449,2320.151611 2423.234863,2320.413330 2420.043457,2319.396973 
	C2417.467773,2318.576660 2414.972412,2316.980469 2415.338379,2313.802734 
	C2415.733887,2310.370361 2418.530029,2310.146729 2421.312500,2310.340576 
	C2409.482422,2308.501465 2397.608154,2310.620117 2386.304932,2309.927490 
	C2390.100098,2311.284668 2396.501221,2307.102295 2398.563965,2313.910889 
	C2399.540039,2317.132568 2395.840332,2317.759521 2394.657959,2317.849854 
	C2401.381104,2314.413818 2409.301270,2315.619141 2417.621826,2318.620117 
	C2426.582275,2321.851562 2435.849365,2319.398682 2445.610840,2317.400391 
	C2456.936279,2314.696045 2467.824463,2316.439941 2479.175293,2314.589355 
	C2485.639160,2315.706299 2491.547119,2316.003174 2497.565674,2316.079102 
	C2515.478516,2316.304688 2533.479248,2314.574707 2551.270752,2318.005859 
	C2569.740967,2321.567871 2588.402344,2319.439453 2606.973389,2319.967529 
	C2611.906982,2320.108154 2616.774170,2320.122803 2620.805420,2323.590088 
	C2620.338867,2324.360352 2619.903809,2325.355957 2619.737793,2325.312744 
	C2605.172607,2321.544189 2590.797852,2327.734863 2576.245361,2326.087158 
	C2568.701904,2325.233398 2560.930908,2327.058105 2553.489746,2324.331543 
	C2552.594971,2324.003662 2551.195312,2323.742676 2550.568604,2324.196045 
	C2543.489502,2329.317871 2537.625244,2326.448486 2531.051514,2322.537598 
	C2528.068359,2320.762451 2523.239258,2322.095947 2519.247803,2322.008301 
	C2514.925781,2321.913086 2510.415527,2322.547363 2506.331055,2321.510254 
	C2501.455078,2320.272217 2497.654541,2321.054199 2494.788330,2324.820557 
	C2492.012939,2328.467773 2488.645996,2328.374268 2484.981445,2327.329590 
	C2478.073242,2325.359863 2471.354492,2326.524658 2464.355713,2327.227783 
	C2454.261230,2328.241943 2443.912354,2330.197998 2433.752686,2326.940186 
	C2432.504639,2326.540039 2430.997070,2325.909180 2429.892090,2326.263916 
	C2419.206787,2329.696289 2408.961670,2326.040771 2398.572021,2324.605225 
	C2397.333496,2324.434082 2395.340088,2324.399658 2394.829346,2325.113037 
	C2390.023682,2331.824951 2382.988281,2329.634277 2376.578125,2330.048340 
	C2374.790039,2330.163818 2372.492432,2329.323730 2371.534912,2331.919189 
	C2372.383057,2334.562744 2374.637695,2333.830566 2376.442871,2333.965576 
	C2381.723145,2334.360840 2387.095703,2333.242676 2392.304443,2335.027100 
	C2393.184082,2335.328369 2394.637451,2335.517578 2395.170898,2335.034668 
	C2403.974854,2327.064209 2414.186035,2332.390869 2423.696045,2332.134033 
	C2441.329346,2331.657715 2458.984375,2331.946289 2476.629883,2332.033447 
	C2479.866211,2332.049561 2483.252686,2331.227051 2486.437988,2333.033691 
	C2488.130127,2336.391357 2477.645264,2337.442871 2485.371582,2341.892578 
	C2488.775391,2344.590820 2491.797852,2344.421387 2495.472168,2342.506104 
	C2498.061035,2334.572510 2502.261230,2329.911621 2510.702393,2329.873047 
	C2514.285156,2329.856689 2517.843506,2328.513672 2521.450684,2328.114014 
	C2523.286133,2327.911133 2525.762939,2327.403564 2526.678711,2329.760010 
	C2527.619385,2332.180176 2525.082031,2333.025635 2523.895996,2334.477783 
	C2523.538330,2334.916016 2523.533447,2335.642090 2523.236328,2336.702393 
	C2525.835693,2338.630615 2528.714844,2338.119629 2531.483887,2337.154785 
	C2540.443848,2334.032471 2549.685791,2333.719727 2559.035156,2333.969238 
	C2573.991943,2334.369141 2588.764648,2332.438721 2603.512695,2330.238525 
	C2604.410645,2330.104736 2605.400391,2330.587402 2606.667480,2330.849609 
	C2606.983398,2334.672852 2600.781982,2334.545898 2602.021973,2339.316895 
	C2602.171875,2342.691162 2600.371338,2343.593506 2598.286621,2343.605957 
	C2583.567139,2343.693848 2569.264404,2343.876709 2554.553467,2348.203369 
	C2542.263916,2351.817871 2528.179688,2348.850830 2514.893066,2349.052246 
	C2511.020752,2349.111328 2506.864502,2347.871094 2502.743164,2351.318115 
	C2498.474365,2352.079834 2494.801514,2351.793457 2491.270264,2353.200684 
	C2488.088379,2354.468506 2484.549072,2354.531250 2481.391113,2353.853271 
	C2465.875977,2350.522705 2450.200439,2352.270020 2434.607666,2352.088379 
	C2414.681885,2351.856201 2394.863770,2350.211670 2375.027100,2348.674316 
	C2374.051025,2348.598633 2372.491943,2348.301758 2372.162598,2348.774902 
	C2365.815674,2357.892578 2356.369629,2352.593506 2348.395752,2353.971436 
	C2345.590088,2354.456299 2342.240479,2353.055176 2340.181152,2357.292725 
	C2338.662109,2360.889648 2336.368896,2361.790283 2333.434814,2361.416748 
	C2299.886230,2357.147705 2265.899658,2358.758545 2232.398438,2353.978516 
	C2205.939209,2350.203857 2179.342041,2350.937744 2152.762451,2351.197021 
	C2147.753662,2351.245850 2142.791992,2351.293701 2137.853271,2350.542969 
	C2132.828369,2349.779297 2127.984375,2349.557861 2123.392090,2352.448975 
	C2119.848877,2354.679932 2115.809814,2354.635986 2111.769775,2354.181152 
	C2102.481934,2353.135498 2093.156494,2353.587891 2083.844727,2353.561279 
	C2080.850586,2353.552734 2077.047363,2352.810059 2075.065918,2354.585693 
	C2066.778320,2362.013428 2057.122070,2359.995850 2047.603516,2359.649902 
	C2044.950562,2359.553223 2041.956177,2359.235352 2039.723999,2360.491943 
	C2022.387207,2370.251953 2003.400513,2367.656006 1984.999512,2367.263672 
	C1963.716309,2366.809570 1942.474121,2367.946289 1921.213745,2367.521973 
	C1916.560059,2367.429199 1911.891113,2367.655518 1906.469971,2366.536621 
	C1897.934448,2362.503906 1890.237915,2360.025635 1881.967773,2367.089111 
	C1874.018066,2368.023193 1866.860718,2366.262939 1858.809204,2366.964355 
	C1854.987915,2367.338379 1851.623291,2367.900635 1849.192139,2366.321045 
	C1833.804199,2356.323730 1816.326538,2357.921875 1798.492920,2356.761230 
	C1796.040161,2356.425049 1794.535034,2355.833740 1792.456543,2354.603027 
	C1789.105591,2351.913818 1787.345459,2348.932129 1785.235840,2345.356201 
	C1784.656738,2343.869141 1784.498291,2342.989990 1784.575439,2341.465820 
	C1784.452881,2340.048828 1786.262573,2338.805664 1783.551514,2338.115234 
	C1775.401733,2325.793945 1770.334839,2312.711182 1766.418945,2298.450195 
	C1769.246948,2289.982178 1774.922729,2289.144043 1781.859253,2291.120361 
	C1789.225586,2293.219238 1796.624878,2296.191650 1800.619019,2285.581787 
	C1801.619385,2282.924072 1805.848999,2283.004150 1808.852905,2283.089355 
	C1814.674927,2283.254395 1820.046997,2281.899414 1825.753052,2278.688965 
	C1827.983521,2278.026367 1829.509277,2278.049316 1831.748779,2278.737793 
	C1839.895874,2283.013916 1845.885864,2289.229248 1853.686523,2292.643799 
	C1856.122803,2293.710205 1858.961548,2294.892822 1858.612671,2298.150146 
	C1858.205322,2301.956543 1854.811279,2302.422363 1851.860474,2302.787109 
	C1843.052979,2303.875732 1834.146484,2301.631836 1825.354004,2303.698730 
	C1840.764648,2301.373047 1856.347412,2304.793701 1871.736450,2302.134521 
	C1877.518066,2301.135742 1882.788818,2301.372070 1887.923584,2304.901367 
	C1894.488647,2309.414307 1902.179443,2306.253174 1909.316040,2307.119141 
	C1914.309082,2307.725098 1919.900146,2307.328125 1918.972778,2315.651855 
	C1914.007202,2320.756592 1907.529297,2320.050781 1902.067139,2322.715820 
	C1928.651245,2316.607422 1954.791870,2324.023438 1981.135742,2324.905762 
	C1999.733276,2325.528564 2018.243530,2322.152588 2036.825195,2324.325684 
	C2038.138916,2324.479248 2039.864746,2324.654541 2040.730347,2323.919922 
	C2050.295166,2315.800781 2063.129150,2319.064697 2073.640137,2314.322510 
	C2076.772217,2312.909424 2080.175293,2312.954346 2083.431152,2313.783936 
	C2086.002197,2314.439209 2088.009277,2314.471436 2089.363770,2310.892822 
	C2092.819824,2304.514648 2098.414795,2304.613770 2103.664795,2304.744141 
	C2122.230713,2305.205322 2140.797119,2304.610596 2159.359863,2304.973877 
	C2165.343262,2305.090820 2171.340332,2305.363525 2176.737793,2302.668945 
	C2172.791016,2304.087891 2168.447266,2305.314453 2164.128418,2304.787842 
	C2151.569824,2303.255859 2138.937256,2303.827148 2126.376465,2302.954102 
	C2120.110352,2302.518799 2113.468994,2301.366699 2113.506348,2291.988281 
	C2118.940674,2287.750732 2124.602539,2288.812500 2130.192139,2288.776611 
	C2138.376465,2288.723877 2146.671631,2290.415039 2155.382568,2287.051270 
	C2157.975830,2285.236328 2160.129395,2283.857178 2162.370117,2284.057373 
	C2177.436523,2285.401611 2192.225098,2282.846436 2207.856934,2281.349365 
	C2209.629150,2281.778809 2210.574219,2282.176270 2212.064941,2283.132324 
	C2214.093506,2284.743652 2215.098389,2286.491211 2217.836914,2286.962402 
	C2220.588623,2287.340088 2222.537109,2287.895752 2225.014648,2289.333008 
	C2231.860840,2298.116211 2240.504395,2300.851807 2250.447998,2302.754883 
	C2276.034668,2307.652588 2301.947754,2308.259277 2327.763428,2309.707520 
	C2338.616455,2310.316406 2349.575195,2308.076416 2359.771484,2313.775146 
	C2361.026367,2314.476562 2363.047852,2314.049805 2364.008057,2312.070068 
	C2363.108398,2309.075439 2360.343994,2309.708252 2358.279785,2309.563232 
	C2334.759521,2307.912354 2311.117920,2308.851074 2287.656250,2307.203857 
	C2273.592041,2306.216553 2259.482666,2303.173828 2245.520508,2300.343750 
	C2243.252930,2299.884033 2241.012695,2298.519775 2241.373535,2295.573486 
	C2241.651367,2293.302002 2243.561279,2292.378906 2245.501709,2291.731934 
	C2249.040039,2290.552490 2252.734375,2290.527100 2256.383789,2290.661133 
	C2283.291016,2291.650146 2310.190186,2290.343262 2337.093506,2290.464600 
	C2338.426270,2290.470703 2339.904297,2290.783691 2341.062988,2290.284424 
	C2360.201904,2282.033691 2378.292969,2291.284180 2396.527100,2294.271973 
	C2408.542725,2296.241455 2420.103027,2297.440186 2432.115479,2295.645752 
	C2442.412354,2294.106934 2452.686035,2294.082520 2462.577881,2300.367676 
	C2463.345947,2302.246826 2463.322754,2303.384521 2462.492188,2305.248047 
z"
        />
        <path
          fill="#6E8AA1"
          opacity="1.000000"
          stroke="none"
          d="
M2595.330566,2249.479492 
	C2596.000977,2250.998047 2595.945557,2251.999756 2596.011963,2252.993652 
	C2596.665771,2262.753174 2604.203613,2267.426514 2613.164307,2263.680908 
	C2614.219238,2263.239746 2615.055908,2262.323730 2614.623291,2261.518555 
	C2610.265137,2253.409668 2619.143555,2254.288818 2620.886230,2254.575928 
	C2628.113037,2255.766357 2635.298340,2253.040771 2642.158447,2254.566650 
	C2642.856445,2255.954590 2642.715576,2256.824707 2642.032959,2257.662354 
	C2636.864014,2264.006104 2638.254639,2268.693115 2645.965820,2269.862549 
	C2656.414062,2271.447021 2666.912598,2272.734619 2677.419189,2273.877930 
	C2679.793457,2274.136719 2682.701660,2274.910645 2684.596680,2272.435547 
	C2684.180908,2269.083252 2680.283691,2270.786133 2679.431152,2268.383545 
	C2679.473877,2267.306152 2680.027588,2266.315186 2681.036621,2266.313232 
	C2688.553223,2266.298096 2696.212891,2264.406250 2703.318115,2269.088623 
	C2705.266357,2270.372559 2708.470215,2269.933838 2711.101562,2269.964844 
	C2719.084961,2270.058838 2727.070312,2270.008301 2735.054932,2269.989990 
	C2739.017334,2269.980957 2743.179688,2270.557617 2745.857666,2266.512207 
	C2746.656982,2265.304443 2747.944580,2264.526123 2749.242432,2264.959961 
	C2754.121338,2266.591064 2759.159424,2265.642822 2764.095703,2266.152832 
	C2765.845459,2266.333252 2767.355225,2267.069336 2767.324219,2269.150879 
	C2767.301270,2270.697266 2766.173096,2271.540283 2764.779785,2271.721191 
	C2758.121826,2272.586670 2752.844727,2274.562500 2748.113281,2280.522461 
	C2745.072021,2284.353271 2737.321777,2282.909180 2732.043213,2280.791748 
	C2722.154541,2276.825928 2713.072510,2276.414795 2703.698486,2282.648926 
	C2698.283447,2286.249756 2691.617432,2285.475098 2685.468994,2283.484619 
	C2682.250732,2282.443115 2679.122070,2281.490479 2675.749756,2282.521240 
	C2674.389893,2282.936768 2673.287598,2283.875732 2673.064209,2285.199951 
	C2672.817627,2286.659180 2673.858154,2287.585449 2675.283203,2287.989258 
	C2677.513672,2288.620850 2679.745117,2289.835938 2681.963623,2289.807617 
	C2687.746582,2289.734863 2693.011963,2290.402832 2697.587891,2294.545166 
	C2699.721680,2296.476807 2703.258301,2296.470215 2704.600586,2293.726562 
	C2707.872314,2287.039551 2713.656006,2288.048584 2719.237549,2288.004395 
	C2721.898682,2287.983398 2724.562744,2287.943604 2727.221436,2288.031494 
	C2730.189209,2288.129639 2733.732910,2287.796875 2733.565674,2292.276611 
	C2733.426025,2296.020996 2730.297119,2296.136963 2727.666748,2295.916504 
	C2720.592041,2295.323486 2713.845459,2297.292236 2706.966064,2298.209717 
	C2706.328857,2298.294678 2705.728760,2298.660400 2704.550781,2299.433105 
	C2696.503906,2305.589111 2687.670410,2303.854980 2679.982178,2302.401367 
	C2655.902344,2297.848389 2631.666992,2299.196045 2607.693115,2300.529785 
	C2585.391113,2301.770264 2564.030762,2297.614502 2541.534668,2294.982910 
	C2535.050537,2293.615234 2530.914307,2295.632080 2527.582764,2301.169189 
	C2524.855225,2302.347900 2522.773926,2302.284180 2519.943604,2301.229492 
	C2512.838867,2292.719238 2506.031982,2288.037354 2494.562988,2292.302490 
	C2486.048340,2295.468994 2476.760254,2292.912842 2468.460205,2288.813721 
	C2465.422607,2287.313965 2462.406738,2285.232422 2463.446289,2281.215332 
	C2464.357422,2277.694580 2467.812256,2277.005371 2470.731689,2277.262695 
	C2483.609863,2278.400635 2496.622314,2277.701904 2509.391357,2279.096924 
	C2527.721191,2281.099365 2545.876465,2278.623779 2564.112061,2278.941406 
	C2584.652344,2279.299561 2605.151123,2277.757324 2625.659668,2276.618164 
	C2636.437988,2276.019531 2647.317139,2276.444092 2654.014160,2277.664795 
	C2636.469971,2274.594727 2614.522705,2277.701660 2592.789062,2273.990479 
	C2582.424805,2272.220703 2571.141602,2272.680908 2560.564209,2277.192627 
	C2551.142578,2281.211182 2541.541260,2278.265381 2532.411865,2274.940186 
	C2528.852783,2273.643555 2526.142578,2270.814453 2526.711182,2266.519531 
	C2527.251465,2262.436768 2530.691650,2261.868164 2534.017822,2260.968994 
	C2546.473633,2257.602295 2559.170410,2259.628418 2571.739502,2258.946045 
	C2575.537598,2258.739746 2579.819824,2260.500000 2583.404785,2256.065186 
	C2586.640625,2251.858154 2588.972900,2247.562744 2595.330566,2249.479492 
z"
        />
        <path
          fill="#536F86"
          opacity="1.000000"
          stroke="none"
          d="
M1857.593994,2364.734619 
	C1865.479736,2364.430176 1873.089600,2362.840576 1881.172241,2365.829102 
	C1888.323853,2360.754883 1895.483032,2361.436279 1903.386719,2363.482422 
	C1916.995728,2363.974365 1930.014038,2364.548340 1942.969482,2363.888184 
	C1966.828125,2362.672119 1990.648071,2364.673828 2014.491333,2364.079102 
	C2020.301880,2363.933838 2026.163940,2363.490234 2030.796509,2360.342773 
	C2040.026855,2354.071533 2049.812012,2355.597168 2059.615723,2357.007568 
	C2063.499268,2357.566162 2066.750977,2358.694336 2068.484863,2353.779541 
	C2069.685791,2350.374756 2072.811279,2349.880127 2075.925049,2350.034424 
	C2089.176514,2350.691895 2102.537109,2348.389648 2115.699219,2351.498535 
	C2117.709717,2351.973633 2120.132324,2352.189697 2121.164551,2350.260742 
	C2124.886719,2343.306885 2131.125977,2345.756592 2136.196289,2346.692383 
	C2141.174561,2347.611084 2146.050293,2347.956787 2151.024902,2347.965576 
	C2168.586426,2347.996582 2186.151123,2348.216309 2203.708008,2347.934814 
	C2215.077881,2347.752441 2226.120361,2349.802490 2237.291016,2351.285889 
	C2248.113281,2352.722900 2259.066162,2353.222900 2269.972656,2353.969971 
	C2278.228271,2354.535645 2286.593262,2353.008545 2294.773438,2355.609375 
	C2296.453369,2356.143555 2298.630859,2355.099609 2300.581543,2354.799316 
	C2302.215088,2354.548096 2304.367920,2353.528564 2305.407471,2354.205566 
	C2314.273438,2359.980713 2325.004395,2353.973145 2333.918213,2358.939209 
	C2335.510010,2359.825928 2337.143311,2359.369385 2339.331055,2358.343506 
	C2348.218262,2356.800049 2356.062500,2360.546143 2365.120117,2360.027832 
	C2366.911377,2360.860107 2367.523926,2361.876953 2367.899414,2363.985840 
	C2367.003418,2369.931885 2364.020996,2372.162109 2358.084961,2371.070068 
	C2353.692871,2365.885254 2348.449707,2367.108643 2343.576416,2366.973145 
	C2308.754150,2366.004639 2273.911377,2367.897461 2239.069336,2364.573730 
	C2224.356689,2363.169922 2209.225586,2366.102783 2194.267090,2365.328125 
	C2189.045898,2365.057373 2183.433105,2366.242676 2180.678955,2358.454346 
	C2178.915283,2353.466797 2171.843262,2356.945312 2168.050293,2356.028076 
	C2171.081299,2357.008301 2177.634277,2353.750732 2178.180176,2359.624512 
	C2178.752686,2365.784424 2171.858887,2365.497314 2167.648438,2366.086182 
	C2147.149658,2368.954102 2127.198486,2377.185791 2105.809570,2371.915283 
	C2099.948975,2370.471191 2094.054199,2373.413086 2088.402588,2375.448730 
	C2080.160156,2378.417480 2071.687988,2380.482422 2062.958496,2378.546875 
	C2039.919067,2373.437744 2016.517578,2375.124756 1993.326904,2375.382812 
	C1957.072144,2375.785889 1920.829712,2375.266602 1884.582764,2375.410645 
	C1877.493896,2375.438721 1870.725952,2373.716309 1864.062988,2371.564453 
	C1860.962524,2370.563477 1857.454468,2369.754639 1857.593994,2364.734619 
z"
        />
        <path
          fill="#6E8AA1"
          opacity="1.000000"
          stroke="none"
          d="
M2695.468018,2205.572021 
	C2698.665283,2208.892822 2702.181396,2207.833252 2705.405762,2207.979004 
	C2711.678955,2208.262939 2718.430420,2209.864502 2724.073975,2209.096680 
	C2737.315186,2207.295166 2751.250488,2211.404785 2763.895752,2204.727783 
	C2765.004395,2204.142334 2766.507080,2203.960205 2767.778320,2204.084229 
	C2773.794434,2204.670898 2778.903320,2203.264404 2782.830566,2198.311768 
	C2784.519531,2196.181396 2786.780518,2196.248047 2789.083984,2197.671387 
	C2791.383789,2199.092285 2790.905762,2200.478516 2789.760498,2202.560547 
	C2788.162354,2205.466309 2790.729248,2205.528564 2792.666260,2206.007080 
	C2800.138428,2207.853516 2807.718018,2208.393066 2815.372070,2207.984863 
	C2821.431152,2207.661865 2827.165283,2208.770020 2832.621826,2211.278320 
	C2832.249268,2214.481201 2829.848877,2214.018066 2828.041748,2213.929932 
	C2815.150635,2213.301758 2802.128662,2215.758057 2789.349609,2212.287598 
	C2787.746094,2211.852539 2785.962646,2212.074463 2784.610352,2213.179688 
	C2783.913330,2213.749756 2783.164307,2214.783447 2783.202393,2215.570801 
	C2783.237793,2216.299805 2784.199707,2217.453369 2784.911865,2217.589844 
	C2786.849609,2217.959961 2788.901123,2218.162354 2790.854004,2217.946289 
	C2797.607666,2217.199707 2802.738281,2219.533691 2807.787354,2224.224609 
	C2811.631348,2227.795898 2812.703125,2229.992188 2808.709717,2233.680176 
	C2808.116943,2234.227539 2808.248535,2235.559082 2808.027344,2237.254395 
	C2802.576172,2236.159668 2796.983154,2235.897705 2791.295898,2235.959717 
	C2778.981201,2236.093994 2766.663574,2235.975830 2754.347900,2236.046875 
	C2752.481689,2236.057861 2749.806396,2235.448730 2749.298096,2237.968994 
	C2748.670166,2241.083984 2751.596436,2241.440430 2753.730957,2242.195068 
	C2756.552246,2243.192627 2759.194336,2243.867432 2762.408447,2242.998535 
	C2769.885498,2240.977539 2777.609375,2242.092041 2785.232178,2242.270264 
	C2786.832275,2242.307617 2788.577148,2243.228271 2788.353516,2245.332764 
	C2788.196289,2246.812988 2786.814209,2247.409668 2785.484131,2247.696289 
	C2784.518799,2247.904541 2783.495850,2248.035156 2782.516846,2247.959961 
	C2764.873047,2246.602783 2747.424805,2250.278809 2728.902832,2249.979004 
	C2723.398926,2245.909668 2724.521973,2240.641357 2725.415527,2234.853516 
	C2714.911621,2235.918701 2704.781006,2232.829102 2694.257568,2236.887451 
	C2692.264160,2237.733887 2690.863037,2237.837158 2688.765625,2237.319336 
	C2683.627686,2231.690918 2679.002686,2227.282715 2671.868652,2234.966797 
	C2666.997314,2236.260254 2663.032715,2235.381836 2658.553467,2233.265381 
	C2655.156250,2227.768311 2658.617432,2225.464844 2662.252930,2223.498779 
	C2665.829834,2221.564697 2669.866455,2221.051758 2673.861816,2220.973145 
	C2679.596436,2220.860596 2682.476562,2218.235352 2682.456543,2211.712891 
	C2683.681885,2208.496338 2686.033447,2207.669434 2688.878418,2206.360352 
	C2690.405273,2205.743652 2691.297363,2205.458252 2692.885742,2205.097656 
	C2694.023193,2205.028809 2694.479492,2205.081543 2695.468018,2205.572021 
z"
        />
        <path
          fill="#738EA6"
          opacity="1.000000"
          stroke="none"
          d="
M2694.547363,2236.574951 
	C2693.716797,2234.467285 2692.666260,2232.242432 2695.224121,2232.204102 
	C2707.605713,2232.019531 2719.991455,2232.120605 2733.044922,2232.120605 
	C2732.452393,2236.975342 2726.636230,2236.019287 2726.781982,2239.720703 
	C2726.896484,2242.634033 2727.565186,2245.525146 2727.983887,2249.198486 
	C2715.126709,2250.634521 2702.589600,2248.218018 2689.914795,2246.633789 
	C2679.287354,2245.305420 2668.301514,2244.437012 2656.836670,2247.886963 
	C2655.236084,2248.769531 2654.314697,2249.060059 2652.612793,2248.456055 
	C2644.652588,2247.549805 2637.291748,2250.199707 2630.334961,2249.567871 
	C2619.375000,2248.572510 2608.656738,2251.104980 2596.916992,2249.998535 
	C2591.392090,2249.035645 2589.821777,2253.068115 2586.590332,2255.682617 
	C2582.895264,2259.213623 2578.890869,2259.005859 2574.918457,2259.002930 
	C2563.385986,2258.995117 2551.853516,2258.988281 2539.437012,2258.960938 
	C2534.243896,2257.657715 2529.929199,2256.305908 2525.096680,2260.034180 
	C2518.805664,2261.900391 2513.104980,2262.131348 2507.626953,2261.407227 
	C2489.644287,2259.031738 2471.591797,2260.170654 2452.757812,2259.220215 
	C2450.523926,2257.325439 2450.062256,2255.550049 2450.292480,2252.679688 
	C2452.038330,2248.213379 2455.165283,2247.270508 2458.577637,2247.259521 
	C2471.697754,2247.217041 2484.860107,2245.213623 2498.765137,2248.115479 
	C2502.927246,2249.223389 2505.793945,2247.817871 2509.373047,2246.248047 
	C2519.162354,2244.051270 2528.381592,2245.224121 2537.540283,2244.903809 
	C2540.820557,2244.789062 2544.244873,2245.123535 2547.251709,2243.749023 
	C2553.180664,2241.039062 2547.127441,2240.515137 2545.773438,2238.487305 
	C2545.196777,2229.231445 2552.096924,2231.653564 2556.781494,2230.846436 
	C2582.661133,2226.387207 2608.698486,2223.628662 2636.993164,2224.884277 
	C2632.548584,2221.467773 2628.826904,2222.203125 2626.024170,2220.488525 
	C2623.682373,2219.056152 2621.412598,2217.571045 2621.241943,2214.572754 
	C2621.053711,2211.271973 2623.822998,2210.535400 2626.085205,2209.072021 
	C2633.814697,2204.071777 2640.248047,2207.185303 2647.250977,2211.471191 
	C2650.294434,2209.774414 2653.270996,2209.716797 2656.006348,2207.442871 
	C2656.868896,2206.656982 2657.222900,2206.346924 2658.134277,2205.596191 
	C2662.632568,2201.932861 2661.447266,2194.711426 2668.399902,2193.602051 
	C2677.574219,2192.138184 2683.980713,2197.843750 2684.170410,2209.269531 
	C2684.188721,2210.969971 2684.334229,2211.956787 2684.572510,2212.920166 
	C2686.820068,2222.004395 2685.669189,2223.805908 2676.671387,2223.926270 
	C2671.638672,2223.993652 2666.754639,2224.352295 2662.062012,2226.268066 
	C2660.100342,2227.068848 2658.718506,2228.369873 2658.089844,2231.217285 
	C2648.626465,2237.192627 2638.375977,2239.330811 2627.884277,2239.425781 
	C2615.740967,2239.535889 2603.588135,2238.909668 2590.558105,2239.351074 
	C2586.836670,2238.885986 2584.019043,2238.824463 2580.522949,2240.182129 
	C2578.125000,2240.787109 2576.483643,2240.990723 2574.833252,2241.821045 
	C2616.776611,2240.096191 2658.706543,2237.550537 2701.415771,2239.016602 
	C2704.432861,2239.097168 2706.735596,2239.103516 2709.478027,2239.129639 
	C2707.366211,2238.783691 2705.672363,2238.948486 2703.256348,2238.932373 
	C2699.883789,2239.025391 2697.338379,2238.696777 2694.547363,2236.574951 
z"
        />
        <path
          fill="#597288"
          opacity="1.000000"
          stroke="none"
          d="
M2732.530762,2369.192871 
	C2725.463135,2369.909180 2718.470947,2371.511963 2714.910645,2363.376221 
	C2714.141602,2361.618652 2711.330322,2362.031738 2709.346436,2362.047119 
	C2695.770264,2362.152832 2682.137939,2361.124512 2668.697021,2364.029053 
	C2667.729736,2364.237793 2666.722168,2364.283447 2665.773193,2364.547852 
	C2664.417725,2364.926270 2663.231689,2365.876709 2663.117432,2367.203125 
	C2662.927734,2369.402100 2664.717285,2369.764893 2666.438232,2369.877197 
	C2667.763428,2369.963867 2669.095459,2369.950928 2671.212158,2369.991211 
	C2673.274902,2371.671631 2673.561279,2373.501709 2672.288086,2376.254150 
	C2662.567383,2381.676758 2652.648682,2380.286865 2643.301270,2379.364502 
	C2614.755859,2376.547119 2586.184814,2377.670898 2557.649170,2377.934814 
	C2537.939453,2378.116943 2518.618652,2376.180176 2498.501465,2372.287109 
	C2493.966797,2369.355225 2494.429443,2366.786865 2497.744385,2364.302490 
	C2499.824707,2362.743652 2502.847412,2363.267578 2505.351562,2360.632812 
	C2510.599854,2360.029541 2515.246582,2359.699951 2519.840820,2360.051025 
	C2535.380615,2361.239258 2550.706787,2358.080811 2567.083008,2358.022217 
	C2581.533691,2364.058594 2595.592773,2361.760742 2609.374756,2359.798096 
	C2615.270264,2358.958496 2622.397705,2361.151855 2627.569336,2354.602539 
	C2640.454590,2357.525635 2653.445801,2354.697510 2665.905518,2357.837158 
	C2669.991211,2358.866455 2674.363037,2357.903564 2679.197754,2356.136230 
	C2685.208008,2351.780029 2690.729004,2351.753662 2697.246094,2355.369141 
	C2705.588623,2354.723877 2712.756104,2358.174316 2721.101074,2357.905273 
	C2731.754150,2355.644287 2734.474854,2358.214600 2732.530762,2369.192871 
z"
        />
        <path
          fill="#5B768D"
          opacity="1.000000"
          stroke="none"
          d="
M2537.591309,2292.550293 
	C2552.476807,2293.624756 2566.940186,2294.881348 2581.295898,2296.902344 
	C2589.257568,2298.022949 2596.967041,2297.388916 2604.897461,2296.999268 
	C2617.632568,2296.374268 2630.472412,2294.758057 2643.259766,2296.785645 
	C2645.205078,2297.094238 2647.434814,2298.217041 2649.041016,2297.626221 
	C2658.795410,2294.037598 2667.940674,2297.634521 2677.311035,2299.332520 
	C2685.509033,2300.817871 2693.854492,2299.755615 2703.060303,2299.983398 
	C2707.259521,2305.013428 2701.959229,2305.131348 2700.066650,2305.382568 
	C2692.843262,2306.342529 2685.470215,2306.833252 2678.277588,2305.049072 
	C2666.848145,2302.213623 2654.632812,2301.284180 2644.078857,2304.793213 
	C2633.779297,2308.217529 2623.964600,2307.262695 2613.911865,2307.955566 
	C2610.272949,2308.206543 2606.652344,2307.948242 2603.069336,2306.777832 
	C2600.919434,2306.074951 2598.416504,2305.769531 2596.188965,2306.092529 
	C2568.379639,2310.123535 2540.406738,2306.733887 2512.558105,2308.098145 
	C2496.212402,2308.899170 2480.138428,2305.447998 2462.938232,2305.985107 
	C2462.013428,2305.093750 2462.007080,2304.207275 2462.000488,2302.655273 
	C2462.698975,2301.290039 2463.245117,2300.236816 2464.264648,2300.113770 
	C2482.291504,2297.934814 2500.339111,2296.388916 2519.253906,2299.344971 
	C2522.142822,2299.927490 2524.258301,2299.932617 2527.166992,2299.917480 
	C2529.220703,2298.779297 2530.564209,2297.803467 2532.315186,2297.660889 
	C2535.180420,2297.427490 2540.705811,2300.002197 2537.591309,2292.550293 
z"
        />
        <path
          fill="#4C6880"
          opacity="1.000000"
          stroke="none"
          d="
M2505.180664,2360.032227 
	C2506.096436,2363.371582 2505.313232,2365.935059 2501.255127,2365.986572 
	C2499.261963,2366.011963 2497.271973,2366.226807 2496.118164,2369.132324 
	C2492.133789,2374.213623 2486.889648,2373.354004 2481.995117,2373.659180 
	C2437.888428,2376.411377 2393.654541,2376.866699 2349.629883,2381.191650 
	C2338.041504,2382.329834 2326.354736,2382.020020 2313.795410,2381.054199 
	C2307.240234,2379.570557 2298.318848,2379.749756 2298.858887,2372.958496 
	C2299.337402,2366.934082 2308.080811,2368.802490 2313.418701,2368.597168 
	C2323.673096,2368.202881 2333.938965,2368.854248 2344.128418,2366.604980 
	C2348.242676,2365.697021 2352.705566,2366.420654 2357.260986,2369.383789 
	C2361.515137,2370.068359 2364.768066,2369.647217 2365.905762,2364.708252 
	C2377.956543,2360.984131 2390.076416,2359.444092 2403.228516,2360.078125 
	C2411.974365,2359.910889 2419.917969,2360.405518 2427.803711,2358.356445 
	C2431.181885,2357.478760 2435.051758,2358.347412 2438.680176,2358.643555 
	C2440.306396,2358.776367 2441.916260,2359.908936 2443.485352,2359.811523 
	C2456.478760,2359.001465 2469.251709,2361.933350 2483.083984,2361.931641 
	C2485.007080,2360.963379 2486.185303,2360.329590 2488.266113,2360.161621 
	C2491.537842,2360.704346 2494.123291,2360.350342 2497.356934,2359.958496 
	C2500.131592,2359.954102 2502.257568,2359.985352 2505.180664,2360.032227 
z"
        />
        <path
          fill="#637E95"
          opacity="1.000000"
          stroke="none"
          d="
M2732.885254,2369.971191 
	C2731.737061,2360.564697 2731.737061,2360.564697 2722.733398,2357.939941 
	C2721.735596,2354.809326 2722.985352,2352.040527 2723.854492,2349.208740 
	C2725.455078,2343.991455 2727.273438,2342.619141 2731.380127,2345.185059 
	C2736.522949,2348.398193 2741.141113,2348.653076 2746.278320,2345.895508 
	C2750.618408,2343.566162 2753.456543,2345.026123 2756.093262,2349.088623 
	C2760.615967,2356.057617 2768.093262,2357.010986 2775.095215,2351.970947 
	C2777.547363,2350.205566 2780.059570,2348.500244 2783.088867,2348.217285 
	C2786.445068,2347.903564 2790.307617,2347.453125 2788.636963,2353.126221 
	C2787.999756,2355.290527 2788.552734,2357.133789 2791.029541,2357.694824 
	C2797.901123,2359.251709 2804.695557,2362.654541 2811.885010,2359.634766 
	C2815.527588,2358.104248 2818.239502,2359.009766 2821.485596,2361.779785 
	C2821.228516,2367.801758 2815.773926,2364.890381 2812.481934,2367.491699 
	C2803.790283,2368.424561 2795.639648,2367.653564 2787.373047,2369.397705 
	C2782.206055,2370.487793 2776.240234,2368.859375 2771.816406,2364.799805 
	C2769.577881,2362.745605 2767.026367,2361.268555 2764.150391,2360.425293 
	C2762.579834,2359.964844 2760.755371,2359.696045 2759.507568,2361.093018 
	C2758.063965,2362.709473 2759.549805,2364.058594 2760.246094,2365.476807 
	C2760.651123,2366.301514 2760.800293,2367.252197 2761.281250,2368.880127 
	C2751.999023,2371.085693 2742.843018,2369.499756 2732.885254,2369.971191 
z"
        />
        <path
          fill="#637E95"
          opacity="1.000000"
          stroke="none"
          d="
M2698.102051,2355.267578 
	C2692.609131,2355.993408 2687.208252,2356.006348 2680.908691,2356.019531 
	C2677.111328,2349.793945 2677.111328,2349.793945 2680.945557,2345.943359 
	C2680.138428,2343.260010 2676.836182,2344.107422 2675.506592,2342.121826 
	C2672.472412,2337.589600 2669.471924,2337.669678 2664.735352,2340.634766 
	C2660.651367,2343.191162 2655.021484,2343.837158 2651.336670,2338.765137 
	C2648.768066,2335.229248 2644.449707,2336.911377 2640.985352,2334.642578 
	C2642.381348,2330.968262 2645.227295,2329.572266 2648.601562,2330.663574 
	C2655.485840,2332.890869 2662.509033,2331.262207 2669.389160,2332.069580 
	C2681.495605,2333.490479 2693.783691,2330.472900 2705.808594,2333.809814 
	C2708.448242,2334.542236 2711.064209,2333.576172 2713.278076,2331.823730 
	C2714.908447,2330.532959 2716.813721,2330.028320 2718.872559,2330.085205 
	C2721.391846,2330.154785 2723.571777,2330.723389 2723.677490,2333.840332 
	C2723.758545,2336.224854 2722.445068,2337.525635 2720.169434,2337.845947 
	C2713.952393,2338.721436 2707.886475,2340.639404 2701.455322,2340.054199 
	C2697.828613,2339.724365 2694.140869,2339.970947 2690.485352,2340.095459 
	C2688.822021,2340.152344 2686.923828,2340.517822 2687.194092,2342.771484 
	C2687.415771,2344.619873 2689.011963,2345.584717 2690.762695,2345.660156 
	C2692.378906,2345.730225 2694.062256,2345.502686 2695.631348,2345.086914 
	C2697.579346,2344.570801 2699.666992,2343.586914 2701.080078,2345.597656 
	C2702.428467,2347.517090 2701.138184,2349.336182 2699.992676,2351.042725 
	C2699.265137,2352.127197 2698.785400,2353.378418 2698.102051,2355.267578 
z"
        />
        <path
          fill="#637E95"
          opacity="1.000000"
          stroke="none"
          d="
M2852.145996,2332.695801 
	C2858.953613,2330.906006 2865.448730,2334.304443 2873.157227,2333.991699 
	C2872.587646,2343.086670 2864.724121,2342.862305 2858.621826,2343.444824 
	C2848.434326,2344.417236 2838.103760,2343.860596 2827.834473,2344.011719 
	C2820.888184,2344.113770 2813.956787,2343.590088 2807.063721,2345.618652 
	C2803.322021,2346.720215 2799.050781,2346.595459 2795.226074,2345.318848 
	C2791.317871,2344.014648 2787.390137,2344.056152 2783.406982,2343.997803 
	C2781.083496,2343.963623 2778.375000,2344.552734 2776.503418,2343.581787 
	C2766.125732,2338.200684 2755.022705,2340.516602 2744.190430,2340.002686 
	C2741.219482,2339.861572 2738.160645,2340.468506 2735.317139,2339.247559 
	C2734.128174,2338.737061 2733.012939,2337.868164 2733.156738,2336.272461 
	C2733.314941,2334.517578 2734.754395,2334.135498 2736.009521,2334.238281 
	C2754.967773,2335.793457 2773.950439,2334.796631 2793.026855,2337.165527 
	C2813.262939,2339.678711 2833.946289,2341.517822 2854.559814,2339.805420 
	C2855.162598,2339.755127 2855.731934,2339.302734 2856.513184,2338.948975 
	C2857.435303,2335.374512 2852.345215,2336.165527 2852.145996,2332.695801 
z"
        />
        <path
          fill="#536F86"
          opacity="1.000000"
          stroke="none"
          d="
M2503.043945,2351.909668 
	C2501.643799,2346.719727 2504.771973,2345.960205 2508.976807,2345.988037 
	C2522.238525,2346.076416 2535.501953,2345.948486 2548.764160,2345.991211 
	C2554.073242,2346.008301 2559.227783,2346.417480 2563.503906,2341.935547 
	C2566.536865,2338.756836 2571.317871,2339.270752 2574.881104,2340.262695 
	C2583.642090,2342.701660 2592.344971,2340.155029 2601.509033,2340.542236 
	C2608.061768,2340.030762 2614.122803,2340.022949 2621.091797,2340.012207 
	C2625.497559,2339.919678 2625.233887,2342.227783 2624.416748,2345.258301 
	C2608.579346,2345.969482 2593.235596,2345.996582 2576.955566,2346.036133 
	C2574.259033,2347.714600 2572.564209,2349.447998 2570.351562,2351.517090 
	C2547.921143,2351.918213 2525.945068,2351.918213 2503.043945,2351.909668 
z"
        />
        <path
          fill="#637E95"
          opacity="1.000000"
          stroke="none"
          d="
M2656.381836,2321.986328 
	C2663.292236,2321.683838 2670.066406,2323.931641 2674.011230,2316.779541 
	C2674.326172,2316.208496 2675.929443,2315.947754 2676.798828,2316.143555 
	C2687.578369,2318.569824 2699.286377,2316.108154 2709.358398,2322.345459 
	C2710.621826,2323.127930 2711.524170,2323.967041 2711.328613,2325.368408 
	C2711.130127,2326.791016 2710.021729,2327.600342 2708.566162,2327.765137 
	C2707.579346,2327.876465 2706.581543,2327.998535 2705.592529,2327.970215 
	C2681.000244,2327.268555 2656.407959,2326.552734 2631.816650,2325.813477 
	C2630.836182,2325.784180 2629.889893,2325.467773 2629.421631,2323.758057 
	C2637.736328,2318.384521 2646.567627,2319.770264 2656.381836,2321.986328 
z"
        />
        <path
          fill="#5B768D"
          opacity="1.000000"
          stroke="none"
          d="
M2763.249512,2299.564697 
	C2768.971191,2302.934326 2774.422607,2302.024658 2779.669678,2301.902100 
	C2785.698486,2301.760986 2791.240723,2303.080566 2796.754150,2305.076172 
	C2795.907959,2308.614258 2793.527588,2307.940918 2791.748047,2307.947510 
	C2773.448242,2308.015869 2755.146484,2308.134033 2736.849365,2307.896729 
	C2730.253174,2307.811279 2723.829834,2305.466553 2717.136963,2305.645020 
	C2716.252686,2305.668701 2715.054688,2305.042480 2715.259277,2303.791260 
	C2715.401611,2302.919434 2715.849609,2301.910889 2716.495361,2301.343750 
	C2718.409424,2299.662354 2720.867432,2300.016113 2723.166016,2300.008057 
	C2732.149658,2299.976562 2741.133301,2300.008057 2751.052246,2300.017822 
	C2755.275146,2297.425049 2758.823730,2297.776123 2763.249512,2299.564697 
z"
        />
        <path
          fill="#637E95"
          opacity="1.000000"
          stroke="none"
          d="
M2627.132812,2354.045410 
	C2628.077881,2356.369873 2628.077881,2358.670898 2628.077881,2361.106445 
	C2625.458740,2362.789551 2622.410400,2361.748779 2619.518555,2361.961914 
	C2617.203613,2362.132812 2614.438965,2361.245361 2612.614746,2362.217529 
	C2601.556885,2368.110352 2590.065674,2362.406494 2578.852783,2363.789307 
	C2575.085693,2364.253906 2570.966309,2363.041016 2568.159180,2358.750977 
	C2572.556152,2353.393555 2574.757080,2358.940186 2578.796387,2360.697266 
	C2580.433105,2354.788086 2584.988037,2353.777588 2590.594482,2353.914062 
	C2602.452881,2354.202881 2614.322510,2354.014648 2627.132812,2354.045410 
z"
        />
        <path
          fill="#6E8AA1"
          opacity="1.000000"
          stroke="none"
          d="
M2652.201172,2248.599121 
	C2652.875732,2248.023438 2653.770996,2248.014893 2655.338135,2248.007812 
	C2657.031494,2255.844482 2659.219482,2257.803711 2667.821777,2255.715088 
	C2673.212891,2254.405762 2677.999756,2252.140625 2683.346436,2255.671631 
	C2684.343994,2256.330322 2686.485107,2255.219971 2688.106689,2254.980713 
	C2690.383301,2254.644287 2692.861816,2253.629150 2694.914307,2254.176758 
	C2704.044678,2256.614014 2713.322510,2255.783936 2722.554199,2256.054932 
	C2724.541260,2256.113281 2727.000977,2256.136230 2727.193359,2258.665283 
	C2727.451660,2262.064697 2724.499268,2261.635498 2722.282959,2261.988770 
	C2716.200928,2262.958496 2710.608398,2259.878418 2704.615479,2259.967285 
	C2695.998535,2260.094971 2687.333008,2259.448242 2678.771484,2260.149658 
	C2669.507568,2260.908447 2660.499268,2257.652588 2651.287598,2259.155029 
	C2650.225586,2259.328369 2649.299805,2259.025879 2648.765625,2258.126709 
	C2647.967041,2256.782471 2648.473389,2255.545654 2649.534668,2254.607422 
	C2651.196533,2253.138428 2653.808350,2252.232910 2652.201172,2248.599121 
z"
        />
        <path
          fill="#49647C"
          opacity="1.000000"
          stroke="none"
          d="
M2479.025879,2314.078369 
	C2479.096191,2318.979004 2475.237061,2317.772949 2472.166016,2317.972900 
	C2464.047119,2318.501465 2455.777588,2316.708740 2446.998779,2319.682129 
	C2441.226318,2319.814697 2436.220703,2320.741943 2430.767578,2318.472656 
	C2430.409180,2315.613525 2431.504150,2314.093750 2433.887939,2314.118164 
	C2436.932617,2314.149414 2439.680176,2313.059326 2443.255859,2312.090820 
	C2455.255371,2315.261230 2466.938232,2311.366455 2479.025879,2314.078369 
z"
        />
        <path
          fill="#607B93"
          opacity="1.000000"
          stroke="none"
          d="
M2403.657715,2359.504883 
	C2402.197510,2362.258545 2399.742432,2364.041748 2397.173340,2363.573486 
	C2387.322754,2361.778076 2377.621094,2365.354004 2366.916504,2364.001709 
	C2365.997803,2363.117432 2365.995605,2362.234619 2365.991943,2360.690430 
	C2366.218262,2359.790039 2366.489014,2359.312988 2366.667236,2359.345215 
	C2376.226562,2361.082520 2385.136475,2357.571045 2394.308350,2356.087891 
	C2397.501465,2355.571533 2401.005615,2355.587158 2403.657715,2359.504883 
z"
        />
        <path
          fill="#637E95"
          opacity="1.000000"
          stroke="none"
          d="
M2624.765625,2345.962891 
	C2623.952881,2344.188721 2624.135254,2342.407227 2622.495850,2340.439209 
	C2625.216797,2336.293457 2633.172852,2334.588623 2636.282227,2336.934570 
	C2637.480713,2337.838623 2637.765869,2339.158691 2637.402832,2340.400391 
	C2636.761719,2342.593018 2635.922607,2345.012939 2633.384277,2345.503662 
	C2630.855713,2345.992676 2628.204834,2345.849365 2624.765625,2345.962891 
z"
        />
        <path
          fill="#6E8AA1"
          opacity="1.000000"
          stroke="none"
          d="
M2746.616699,2288.015137 
	C2753.088379,2288.071533 2758.387207,2284.915771 2764.177979,2286.159668 
	C2765.156738,2286.369873 2766.063965,2286.604248 2766.829590,2288.992676 
	C2759.175049,2289.969238 2751.649902,2290.995117 2744.102783,2291.818115 
	C2742.999756,2291.938477 2741.038574,2291.579102 2741.598145,2289.677246 
	C2742.183838,2287.687256 2744.248047,2288.234131 2746.616699,2288.015137 
z"
        />
        <path
          fill="#6E8AA1"
          opacity="1.000000"
          stroke="none"
          d="
M2774.809082,2270.328369 
	C2777.104004,2270.266357 2778.778320,2270.118896 2780.273438,2270.567627 
	C2782.241699,2271.158691 2783.838379,2272.622559 2783.034180,2274.876465 
	C2782.137939,2277.388184 2780.392090,2279.492676 2777.350098,2279.176758 
	C2774.112549,2278.840576 2772.811279,2275.118164 2774.809082,2270.328369 
z"
        />
        <path
          fill="#637E95"
          opacity="1.000000"
          stroke="none"
          d="
M2823.411133,2333.926758 
	C2820.851074,2333.737793 2818.664795,2334.579346 2817.034912,2331.668213 
	C2819.813232,2329.378906 2823.192383,2329.170410 2825.795410,2330.953857 
	C2828.114746,2332.542969 2825.604736,2333.367188 2823.411133,2333.926758 
z"
        />
        <path
          fill="#6E8AA1"
          opacity="1.000000"
          stroke="none"
          d="
M2763.676025,2299.407227 
	C2760.596924,2300.031250 2757.152344,2300.025635 2752.847656,2300.019775 
	C2755.383545,2294.839600 2757.063477,2294.655273 2763.676025,2299.407227 
z"
        />
        <path
          fill="#597288"
          opacity="1.000000"
          stroke="none"
          d="
M2497.513672,2359.415527 
	C2496.079590,2362.091309 2494.066650,2364.048828 2490.387207,2362.455566 
	C2489.239014,2361.233643 2488.947266,2360.312744 2489.572754,2358.626465 
	C2492.377197,2358.126709 2494.818359,2357.592041 2497.513672,2359.415527 
z"
        />
        <path
          fill="#536F86"
          opacity="1.000000"
          stroke="none"
          d="
M2495.159180,2342.037598 
	C2492.933838,2347.079346 2491.346924,2347.306396 2486.188965,2342.729492 
	C2488.802490,2342.028320 2491.566162,2342.027100 2495.159180,2342.037598 
z"
        />
        <path
          fill="#637E95"
          opacity="1.000000"
          stroke="none"
          d="
M2570.641113,2351.926514 
	C2569.960205,2348.783447 2570.446289,2345.984131 2575.284668,2346.052490 
	C2576.063965,2349.262207 2575.526611,2352.017822 2570.641113,2351.926514 
z"
        />
        <path
          fill="#637E95"
          opacity="1.000000"
          stroke="none"
          d="
M2489.321777,2358.020020 
	C2490.014648,2358.897949 2490.009766,2359.785889 2490.002441,2361.340332 
	C2488.495117,2361.998779 2486.990479,2361.991455 2484.733643,2361.949707 
	C2484.261719,2359.106201 2485.960693,2357.991943 2489.321777,2358.020020 
z"
        />
        <path
          fill="#637E95"
          opacity="1.000000"
          stroke="none"
          d="
M2668.915039,2350.115234 
	C2669.907471,2348.208252 2671.019775,2348.574951 2671.976074,2349.587158 
	C2672.271240,2349.899658 2672.370850,2350.922607 2672.136719,2351.121338 
	C2670.979248,2352.103271 2669.874512,2351.735352 2668.915039,2350.115234 
z"
        />
        <path
          fill="#7B95AB"
          opacity="1.000000"
          stroke="none"
          d="
M888.385986,2092.603516 
	C893.152283,2085.679199 894.679565,2078.655273 892.642700,2070.558350 
	C891.685181,2066.752197 894.054382,2065.134766 898.159790,2067.308838 
	C904.487854,2070.660400 911.047241,2069.949463 917.643250,2069.874268 
	C918.838135,2069.860596 920.418518,2069.797607 920.532715,2067.863037 
	C919.594116,2065.353516 917.245850,2065.969238 915.495056,2065.958008 
	C906.622131,2065.901611 900.804993,2058.293457 892.528564,2056.822021 
	C892.022705,2056.732178 891.534546,2055.952393 891.234741,2055.388672 
	C888.998413,2051.185791 893.786499,2037.592407 898.523987,2036.113770 
	C901.725342,2035.114624 903.218567,2032.616943 905.520081,2030.842529 
	C907.808228,2029.078613 910.619751,2032.549316 913.308655,2029.964600 
	C911.827271,2025.552368 907.402100,2023.352173 904.108643,2021.766846 
	C896.129883,2017.926392 899.056763,2009.329346 894.941711,2003.989624 
	C894.011780,2002.782959 896.107483,2001.103638 897.741272,2000.552002 
	C899.394714,1999.993530 900.974426,2000.686646 902.575806,2001.165161 
	C905.138245,2001.931030 908.095459,2002.868530 909.380676,1999.484741 
	C910.628967,1996.197754 909.310852,1993.094727 905.842468,1992.518188 
	C900.102600,1991.564453 899.941345,1988.864868 901.486633,1984.114990 
	C902.835144,1979.969849 902.295227,1976.078369 896.295349,1975.902100 
	C892.117676,1975.779419 892.542175,1973.065796 893.684814,1970.284546 
	C895.507751,1965.847656 895.230225,1961.562134 894.356262,1956.788574 
	C892.108643,1944.512939 896.514832,1937.993652 908.935974,1933.929199 
	C912.418884,1932.789551 914.771179,1930.707397 916.120605,1927.570557 
	C917.949585,1923.319336 920.902466,1921.538940 925.496277,1921.966431 
	C930.544861,1922.436279 935.370850,1921.671143 939.960571,1919.207397 
	C941.619385,1918.316772 944.006836,1917.669434 945.705505,1918.155640 
	C953.960388,1920.518799 962.083618,1917.506958 971.129028,1917.981201 
	C972.788574,1917.381470 973.575256,1916.624146 974.540955,1916.526489 
	C979.268066,1916.047974 984.270691,1916.313843 986.633423,1920.893433 
	C989.320129,1926.100952 983.796082,1928.090088 980.774048,1931.766724 
	C976.239380,1937.839355 970.481750,1938.892822 964.247314,1938.012329 
	C950.931519,1936.131714 937.625732,1937.197998 924.335449,1938.011963 
	C915.926697,1938.527100 913.115601,1942.265625 914.674561,1950.394775 
	C915.501709,1954.707642 915.744568,1958.877319 915.150574,1963.264160 
	C914.031250,1971.531128 916.259094,1974.473999 924.623230,1974.688721 
	C933.869141,1974.926147 943.129761,1973.708618 952.141479,1977.183960 
	C954.492676,1978.090820 958.213501,1977.736816 959.782532,1975.904297 
	C964.923706,1969.899048 970.141785,1965.700562 978.478149,1969.847900 
	C979.819824,1970.515381 980.843933,1969.126831 981.235046,1967.696655 
	C983.498596,1959.421021 990.800537,1952.144775 985.677429,1942.374268 
	C984.350220,1939.843018 985.124268,1936.462891 986.770264,1932.928955 
	C989.516418,1929.841431 992.502991,1929.756958 995.526367,1929.977417 
	C1027.943359,1932.340332 1060.521362,1930.656982 1092.903931,1933.863770 
	C1096.252686,1934.195435 1099.517456,1933.450684 1102.816895,1933.132568 
	C1118.258667,1931.643433 1132.424805,1935.598755 1146.437988,1943.578857 
	C1153.979980,1950.676880 1156.394287,1959.691528 1159.210571,1968.359619 
	C1160.301147,1971.716064 1160.343994,1974.851440 1164.994995,1974.436401 
	C1169.215210,1974.059814 1170.484131,1971.232422 1170.711060,1967.673218 
	C1171.089600,1961.738525 1171.727783,1955.891113 1173.834473,1950.254395 
	C1175.678711,1945.319702 1178.358276,1941.472534 1184.768433,1940.556152 
	C1190.313843,1934.396118 1196.673218,1932.791870 1204.094238,1932.591797 
	C1224.427612,1932.043457 1244.695312,1933.871826 1265.006226,1933.762085 
	C1267.280273,1933.749756 1269.782349,1934.802612 1271.755493,1933.061523 
	C1284.249390,1922.037109 1298.931152,1926.307251 1313.849121,1927.312500 
	C1315.521729,1928.022095 1316.385864,1928.566284 1317.685547,1929.756104 
	C1321.920532,1936.114502 1326.713135,1939.192383 1334.471313,1936.101685 
	C1337.928223,1935.095825 1340.791382,1935.008667 1344.355957,1935.018555 
	C1349.956665,1934.831177 1356.041748,1937.115234 1357.824219,1928.673584 
	C1361.915527,1924.953247 1366.016602,1924.682495 1371.076904,1926.492676 
	C1373.521606,1932.839600 1376.748169,1935.391724 1383.686401,1935.065918 
	C1399.282349,1934.333252 1414.936523,1934.800415 1430.566895,1934.856689 
	C1435.211914,1934.873413 1440.097290,1934.411011 1444.108154,1937.230347 
	C1447.768677,1939.803345 1450.610962,1938.466064 1454.186768,1936.975586 
	C1463.580933,1933.060181 1470.435059,1936.372925 1473.756592,1945.918091 
	C1475.059326,1949.661499 1476.830811,1953.652710 1473.738037,1957.266235 
	C1470.360474,1961.212646 1466.043457,1960.342041 1461.755981,1959.125854 
	C1469.905029,1962.029541 1478.952026,1962.112061 1486.854980,1961.233521 
	C1497.578857,1960.041260 1508.642822,1959.578369 1518.434326,1961.604248 
	C1532.998657,1964.617920 1547.279297,1961.978638 1562.239990,1963.891602 
	C1568.425659,1968.015137 1573.648193,1972.982422 1581.918823,1968.677734 
	C1584.051880,1967.998535 1585.531006,1967.955078 1587.764648,1968.365723 
	C1589.755859,1969.057007 1590.791138,1969.897949 1591.884766,1970.653320 
	C1594.692993,1972.593018 1597.615845,1974.727295 1596.738281,1978.587036 
	C1595.894287,1982.299316 1592.310791,1983.379395 1589.148315,1984.324097 
	C1580.062012,1987.038818 1570.638062,1985.519043 1561.390869,1986.278076 
	C1546.996460,1987.459717 1531.957764,1987.241211 1518.414551,1985.018921 
	C1501.738159,1982.281982 1487.107788,1989.369507 1471.413208,1988.125977 
	C1470.055176,1988.018433 1468.629761,1988.220215 1467.575684,1988.990723 
	C1457.347168,1996.468140 1446.389893,1992.979248 1435.973633,1990.362671 
	C1423.642456,1987.265137 1411.473511,1990.093750 1399.333984,1990.218018 
	C1381.111206,1990.404907 1363.003540,1989.868530 1344.877319,1988.438477 
	C1340.840576,1988.120117 1336.980225,1989.116455 1333.040161,1989.767944 
	C1327.245850,1990.725708 1321.632202,1990.401978 1318.463623,1983.335693 
	C1318.473022,1979.564575 1320.113281,1976.936157 1320.660156,1974.008423 
	C1321.661133,1968.649292 1322.824341,1963.491821 1315.509277,1961.233765 
	C1311.539062,1960.008301 1310.053101,1956.605713 1311.061401,1952.456665 
	C1312.090332,1948.223511 1310.091431,1945.517456 1306.785645,1943.335693 
	C1302.833984,1940.727783 1300.243774,1942.979248 1297.209229,1945.415649 
	C1291.280762,1950.175293 1283.876709,1948.085693 1277.116943,1948.873901 
	C1272.908569,1949.364624 1268.799072,1948.925903 1271.147339,1955.394531 
	C1272.165039,1958.197632 1270.684937,1961.166626 1267.805176,1961.683594 
	C1255.973633,1963.808105 1256.389038,1971.629883 1258.607666,1981.159912 
	C1257.021118,1992.075928 1255.145630,1993.683105 1245.822266,1990.758667 
	C1240.390503,1989.055176 1235.249146,1989.251465 1230.138428,1990.749268 
	C1221.660522,1993.233521 1213.725708,1991.744751 1205.892700,1988.470825 
	C1203.744629,1987.572998 1201.710449,1986.388550 1199.296753,1986.328003 
	C1193.204956,1986.175293 1184.539185,1986.053467 1183.610718,1991.681274 
	C1182.724976,1997.050415 1191.121826,1998.500610 1196.727905,1999.001587 
	C1200.984375,1999.382080 1205.665405,2003.662354 1208.710449,1995.425903 
	C1210.423828,1990.791748 1217.347046,1991.707886 1222.200317,1994.219849 
	C1226.335571,1996.360352 1230.231323,1998.962646 1234.664795,2000.527222 
	C1238.605835,2001.918091 1241.939331,2002.011475 1245.832886,1999.291260 
	C1253.560059,1993.892578 1260.543579,1994.676514 1267.037720,2000.977051 
	C1269.178711,2003.054077 1271.203369,2004.992065 1273.722168,2002.565552 
	C1278.976685,1997.503296 1286.423584,1999.022339 1292.253540,1995.854126 
	C1294.060791,1994.872070 1296.259033,1994.348877 1297.989380,1995.579346 
	C1307.391724,2002.266357 1318.768066,1998.412720 1328.814331,2001.528687 
	C1329.082886,2001.611938 1329.488647,2001.677368 1329.802734,2001.540894 
	C1343.721313,1995.492676 1358.936157,2001.647217 1373.004883,1996.943848 
	C1376.006470,1995.940552 1379.118408,1995.870972 1381.115967,1998.820923 
	C1383.448975,2002.266357 1384.553345,2006.178345 1382.603638,2010.154907 
	C1377.738770,2020.077148 1356.156006,2028.549561 1346.195679,2024.640015 
	C1340.822510,2022.530884 1339.024658,2019.861084 1346.695557,2014.149414 
	C1331.596924,2019.995117 1320.375610,2010.431030 1306.984131,2011.306274 
	C1308.096924,2013.911377 1310.618652,2016.270020 1309.154541,2019.513550 
	C1311.442993,2017.399902 1312.793457,2014.447998 1315.700439,2013.150757 
	C1318.010986,2012.119385 1320.186768,2012.134033 1322.329224,2013.548096 
	C1324.511963,2014.988525 1325.188354,2016.888550 1324.833740,2019.526123 
	C1322.903442,2033.887817 1328.831909,2041.185059 1343.112793,2041.788940 
	C1345.228882,2041.878296 1347.532349,2042.478149 1349.774414,2041.212158 
	C1342.720093,2042.311523 1336.507812,2040.963257 1331.666504,2035.481201 
	C1329.592773,2033.133179 1327.859253,2030.653687 1329.778931,2027.448730 
	C1331.572998,2024.453857 1334.457520,2023.892090 1337.622437,2024.561401 
	C1340.878662,2025.250244 1344.247192,2025.959717 1347.205322,2027.297119 
	C1356.400146,2031.454590 1365.566895,2026.835449 1374.731079,2027.677979 
	C1377.398682,2027.923218 1379.410889,2025.402710 1381.098267,2023.273315 
	C1382.970337,2020.910522 1384.882446,2018.417969 1387.664307,2017.239990 
	C1394.322021,2014.421143 1399.082275,2010.023560 1402.662109,2003.580200 
	C1405.723145,1998.070801 1410.764282,1994.599365 1417.760498,1997.544189 
	C1420.309570,1998.617188 1422.428223,2000.209717 1423.180054,2003.017944 
	C1424.399902,2007.575928 1412.674561,2023.645874 1407.890381,2023.786621 
	C1403.323486,2023.920776 1398.551880,2023.206177 1395.317505,2029.864502 
	C1407.897339,2030.639404 1418.872070,2025.631958 1432.235596,2027.042480 
	C1422.053833,2018.429077 1428.642212,2011.351562 1430.974243,2004.173218 
	C1433.213379,1997.280396 1438.999512,1997.039673 1444.819702,1997.015869 
	C1451.146606,1996.989868 1457.478638,1996.785400 1463.798462,1997.190430 
	C1469.433105,1997.551392 1474.283447,1999.271484 1476.451172,2005.178833 
	C1477.420044,2007.819580 1478.882568,2009.802979 1481.978638,2010.250000 
	C1486.454590,2010.896484 1488.716431,2013.475586 1486.949585,2017.931152 
	C1485.156616,2022.453003 1481.490967,2024.476440 1476.539795,2023.718994 
	C1474.147949,2023.353027 1472.436890,2021.558594 1470.311523,2020.641602 
	C1467.935913,2019.616699 1465.476196,2019.590454 1464.419922,2022.326416 
	C1463.267822,2025.311279 1464.603882,2027.467407 1467.739014,2028.407715 
	C1470.293823,2029.173828 1472.992065,2029.323364 1475.505981,2030.259277 
	C1481.050171,2032.322998 1483.110107,2036.459351 1482.311279,2042.092285 
	C1481.539185,2047.537354 1478.087402,2050.555420 1472.744751,2050.369141 
	C1455.134766,2049.754883 1437.833252,2052.747803 1419.576904,2054.798096 
	C1419.211182,2059.210938 1425.450317,2066.552246 1415.685425,2068.450684 
	C1409.848755,2069.584961 1403.899292,2068.528564 1397.398804,2069.241455 
	C1405.581787,2068.628174 1412.561768,2069.857666 1418.650024,2074.850586 
	C1422.566162,2078.062012 1427.055664,2078.766846 1431.717529,2076.346924 
	C1434.486938,2074.909668 1436.649536,2072.866699 1436.998047,2069.557373 
	C1437.392090,2065.814697 1434.205322,2065.531982 1431.864990,2064.539062 
	C1429.329468,2063.463623 1426.681152,2062.370361 1427.207764,2058.995605 
	C1427.664917,2056.067139 1429.955200,2054.540039 1432.639038,2053.713623 
	C1441.994995,2050.833496 1451.583374,2050.548584 1461.161987,2051.877441 
	C1467.424927,2052.746338 1473.478882,2054.275146 1479.894043,2052.339355 
	C1483.673218,2051.199219 1487.653198,2052.369141 1488.660278,2056.732422 
	C1489.755859,2061.480225 1485.754761,2062.904297 1482.221802,2064.069336 
	C1477.805054,2065.525146 1473.121582,2064.600098 1468.285645,2065.219727 
	C1471.524780,2066.002930 1475.185547,2066.563232 1474.781250,2070.380615 
	C1474.167847,2076.174316 1468.899048,2076.872070 1463.975098,2078.813721 
	C1471.970581,2076.630615 1474.147217,2081.171631 1474.960693,2088.032227 
	C1470.370361,2096.394287 1463.772583,2102.253906 1456.649414,2108.538330 
	C1455.631592,2109.094971 1455.215088,2109.296143 1454.152344,2109.735840 
	C1450.948853,2110.802246 1448.173706,2110.848877 1445.119629,2112.405029 
	C1441.940063,2113.709961 1439.712524,2115.595703 1436.065186,2115.624268 
	C1432.102783,2114.687012 1428.744751,2115.223389 1424.744873,2114.825928 
	C1410.247437,2112.250732 1405.531494,2112.323486 1393.091187,2115.706787 
	C1401.081909,2116.156738 1407.633667,2116.095215 1412.394043,2122.232422 
	C1412.496582,2126.265381 1409.752441,2127.406250 1406.918335,2129.354492 
	C1405.012329,2131.034180 1404.267212,2132.750977 1402.648438,2134.701660 
	C1400.574951,2136.438965 1398.764648,2136.999268 1396.072754,2137.004395 
	C1389.603516,2134.758057 1383.552246,2134.562256 1377.609375,2135.113770 
	C1363.182617,2136.452637 1350.001831,2131.974365 1336.860229,2127.116211 
	C1331.470947,2125.124023 1329.768677,2123.441650 1334.149292,2117.236816 
	C1342.593994,2105.275391 1353.735107,2107.140137 1363.337891,2107.706787 
	C1354.436401,2105.275146 1354.220581,2105.329346 1351.702515,2095.316406 
	C1350.923828,2092.219238 1348.840820,2093.202148 1347.217407,2092.680176 
	C1344.393677,2091.772217 1340.689087,2091.471191 1341.437744,2087.350342 
	C1342.097778,2083.717285 1345.703491,2083.614014 1348.648926,2083.275635 
	C1354.027588,2082.657715 1359.197998,2084.512695 1364.505737,2084.796631 
	C1366.114746,2084.882568 1367.800903,2085.258301 1369.485229,2084.009521 
	C1368.211792,2077.604004 1358.345947,2075.608154 1360.813599,2066.454590 
	C1361.442871,2064.120361 1353.222412,2064.949463 1349.610474,2062.613770 
	C1352.076416,2064.706543 1355.336548,2066.338379 1354.820801,2070.184082 
	C1354.326172,2073.873047 1350.848145,2074.083008 1348.102295,2075.169922 
	C1339.117554,2078.726318 1332.100830,2072.827148 1323.714233,2070.132568 
	C1322.681885,2069.595703 1322.292725,2069.339844 1321.426025,2068.578613 
	C1320.454956,2067.302979 1320.036499,2066.493896 1319.457031,2065.028076 
	C1318.937988,2063.572754 1318.500732,2062.824463 1317.474487,2061.660156 
	C1316.743774,2060.810303 1316.456665,2060.461182 1315.769775,2059.554199 
	C1314.497070,2057.571777 1313.941895,2056.031738 1313.445190,2054.430908 
	C1312.409546,2051.093506 1317.128906,2047.009033 1312.623047,2044.407837 
	C1308.520752,2042.039795 1303.469116,2041.522827 1299.030273,2042.478516 
	C1290.440430,2044.328125 1282.070068,2042.859131 1277.143311,2042.407349 
	C1280.964233,2042.506348 1287.373779,2043.762939 1289.468994,2052.563965 
	C1290.259521,2055.884033 1294.239746,2056.936035 1296.999756,2058.742676 
	C1299.275513,2060.232666 1301.630371,2061.960693 1300.518433,2065.110840 
	C1299.629272,2067.629883 1297.076416,2067.979736 1294.707764,2068.175049 
	C1293.049805,2068.311523 1291.362183,2068.339600 1289.732300,2068.066895 
	C1284.222168,2067.144775 1280.265015,2067.294922 1279.942261,2074.872314 
	C1279.673340,2081.182129 1274.180298,2082.071045 1268.790649,2082.124756 
	C1266.226318,2082.150635 1262.746094,2082.150391 1262.676880,2085.629150 
	C1262.621094,2088.435547 1265.897339,2088.408447 1268.056396,2089.013672 
	C1277.553589,2091.675537 1286.519409,2096.431641 1296.949951,2095.358154 
	C1300.400757,2095.002686 1305.028076,2095.678955 1304.792114,2100.786377 
	C1304.579590,2105.391602 1300.828003,2107.858643 1296.648926,2108.225098 
	C1288.665527,2108.925049 1280.872437,2110.157715 1273.302490,2112.857666 
	C1271.108398,2113.640137 1268.742554,2113.898682 1267.106812,2116.792969 
	C1261.508057,2125.164551 1252.889648,2126.480469 1244.847412,2127.760010 
	C1215.319824,2132.458008 1185.549561,2134.719971 1155.640869,2135.005127 
	C1153.643677,2135.024170 1151.643921,2135.048340 1149.649170,2134.952148 
	C1138.356201,2134.406982 1137.236938,2132.298340 1142.783691,2122.829346 
	C1146.159790,2117.066162 1150.062134,2111.216553 1142.674316,2105.254395 
	C1140.121338,2103.194580 1141.486816,2100.884521 1143.102539,2098.302246 
	C1146.987915,2092.092529 1150.625366,2085.657715 1142.674805,2079.330566 
	C1139.147705,2076.523682 1141.292114,2072.552979 1142.057861,2069.051270 
	C1143.255371,2063.574951 1143.491211,2058.831299 1135.591431,2059.269043 
	C1133.570679,2059.380859 1131.722534,2058.254395 1130.144409,2057.008057 
	C1127.557739,2054.965332 1124.155396,2052.950195 1125.434326,2049.053955 
	C1126.565796,2045.606445 1130.181274,2045.477417 1133.111328,2046.046387 
	C1141.789673,2047.731201 1145.083740,2044.716797 1142.756958,2035.985352 
	C1142.329956,2034.382690 1142.094604,2032.099365 1143.287842,2031.195312 
	C1147.239136,2028.201904 1146.514893,2026.671021 1142.993896,2023.431763 
	C1138.524170,2019.319336 1141.138428,2003.982788 1146.653076,1999.082397 
	C1149.583374,1996.478760 1151.684448,1993.880737 1151.551270,1989.824951 
	C1151.428467,1986.083740 1150.663818,1983.125244 1145.839111,1983.844971 
	C1132.836914,1985.784546 1120.257446,1981.126953 1107.216431,1981.493164 
	C1096.274292,1981.800537 1085.451538,1981.179810 1074.486938,1983.173584 
	C1063.080688,1985.247803 1051.293213,1982.020264 1039.719727,1984.040771 
	C1037.869873,1984.363770 1034.491211,1982.827393 1034.315796,1984.740112 
	C1032.919678,1999.972656 1020.965881,1993.883667 1012.957520,1995.668823 
	C1012.174622,1995.843262 1011.692932,1996.077759 1011.617737,1996.873413 
	C1012.208557,1998.055542 1013.147705,1999.825073 1014.392578,1998.931152 
	C1022.523804,1993.091187 1029.537476,1997.782959 1036.666626,2001.056641 
	C1039.481201,2002.349121 1041.815674,2004.348511 1041.338013,2007.958374 
	C1040.888306,2011.356445 1038.625366,2013.618530 1035.479980,2013.914062 
	C1030.079712,2014.421631 1027.356201,2015.841187 1027.579346,2022.316772 
	C1027.765381,2027.716187 1022.057373,2029.528442 1016.217896,2026.855225 
	C1012.671326,2025.231689 1010.724426,2022.198364 1008.875366,2018.973877 
	C1006.970520,2015.652222 1004.578735,2013.277710 999.289856,2016.508301 
	C1005.051453,2019.489624 1009.125427,2023.196289 1013.420227,2026.499146 
	C1018.489929,2030.398071 1024.324951,2033.769287 1031.865601,2031.874756 
	C1037.124756,2030.553589 1041.554443,2033.751343 1044.322510,2038.395386 
	C1047.253540,2043.313110 1045.268555,2047.271729 1041.349121,2050.682373 
	C1039.859741,2051.978516 1037.962402,2053.340576 1039.338623,2055.666748 
	C1043.652588,2062.958252 1038.343994,2066.673828 1033.853760,2070.644043 
	C1028.199585,2075.643555 1026.728638,2080.945801 1032.411865,2086.879395 
	C1035.168213,2089.757080 1036.806152,2092.776367 1033.706787,2097.115723 
	C1027.951416,2099.925293 1022.629456,2099.536377 1016.550049,2098.313965 
	C1010.560486,2095.053955 1012.471924,2091.172607 1014.377686,2087.141113 
	C1015.401611,2084.974854 1017.707336,2081.899170 1013.668152,2080.836426 
	C1010.659729,2080.045166 1009.287170,2083.033691 1008.577576,2085.681152 
	C1007.676086,2089.044189 1008.514587,2093.044678 1004.277710,2095.718506 
	C1001.209229,2096.834229 998.771484,2096.779785 995.627441,2096.007568 
	C991.747803,2094.389648 989.713501,2090.270264 984.397705,2092.091064 
	C986.215271,2095.733887 991.337769,2098.463867 988.661011,2104.139160 
	C988.221375,2105.197754 988.020081,2105.608398 987.477661,2106.604980 
	C984.736938,2109.754395 986.180054,2113.231689 984.773682,2116.723145 
	C982.359253,2121.417969 985.345154,2124.211182 986.929504,2127.396484 
	C990.819885,2135.217285 989.416687,2137.764160 980.961731,2137.814453 
	C977.626221,2137.834229 974.248596,2137.002197 972.503662,2140.948242 
	C975.416626,2144.910156 985.062683,2143.637207 982.286682,2151.031982 
	C980.126953,2156.785400 973.246460,2154.951904 968.005310,2154.309814 
	C966.769653,2154.158203 965.547852,2153.931641 964.322998,2154.334229 
	C967.318420,2153.906982 970.189514,2154.857178 973.089233,2155.260010 
	C978.936401,2156.072021 981.119019,2160.664062 982.696045,2165.541260 
	C984.111816,2169.920166 981.416565,2172.387207 978.058655,2174.359131 
	C976.267578,2175.410889 974.887329,2176.743652 974.399170,2178.841309 
	C973.620300,2182.188721 972.690613,2185.670898 968.346375,2185.460938 
	C963.478088,2185.226318 959.276428,2183.216553 956.782104,2178.799316 
	C950.401306,2167.499023 950.422852,2167.405273 938.433044,2172.882812 
	C934.623962,2174.623047 930.724609,2175.340820 926.817261,2174.092285 
	C921.627075,2172.433594 918.666687,2169.380615 922.699585,2163.318359 
	C923.457581,2162.484131 923.651001,2162.254639 923.651001,2162.254639 
	C923.995850,2161.764893 923.864990,2161.908203 922.791382,2163.072510 
	C916.754578,2167.394775 910.558167,2169.043701 903.025269,2168.382812 
	C901.814087,2168.030762 901.350586,2167.839844 900.280884,2167.222656 
	C896.180969,2160.995850 898.461731,2157.418701 904.180054,2155.233887 
	C910.401306,2152.856934 916.600952,2150.329590 923.651672,2151.226318 
	C923.155640,2150.159180 922.215515,2150.601074 921.567444,2150.359375 
	C916.615967,2148.511230 908.886719,2150.096680 908.442078,2142.299316 
	C907.973938,2134.090332 915.640747,2135.267090 920.709351,2133.692627 
	C921.656189,2133.398682 922.350647,2132.633301 923.005371,2130.479736 
	C915.808655,2128.129883 908.455811,2127.596680 901.426758,2130.015869 
	C887.688965,2134.743896 887.794495,2135.050537 884.738770,2119.766113 
	C884.073853,2104.360840 885.807495,2102.769775 899.869751,2105.114746 
	C900.523438,2097.099854 893.847290,2095.884277 888.385986,2092.603516 
z"
        />
        <path
          fill="#7790A8"
          opacity="1.000000"
          stroke="none"
          d="
M676.081055,2069.325684 
	C666.831421,2069.873291 657.630310,2071.745117 648.534119,2068.310791 
	C645.290771,2067.086426 641.296997,2067.689697 637.745483,2069.071045 
	C635.854065,2069.806641 633.751282,2070.250977 632.673767,2067.790527 
	C631.905090,2066.035645 632.923950,2064.636719 634.046509,2063.331787 
	C640.049622,2056.353760 647.361206,2051.707764 656.542542,2049.967041 
	C662.784546,2048.783691 662.660645,2048.047363 658.359375,2042.808960 
	C655.452820,2039.269165 650.918884,2037.085938 649.543884,2032.238403 
	C649.145874,2030.835083 647.751526,2030.052856 646.381653,2030.444336 
	C640.689819,2032.071899 634.486023,2028.984985 628.968689,2032.535767 
	C627.027771,2033.785034 624.411926,2034.673462 622.284424,2033.389160 
	C612.632141,2027.562866 604.114502,2030.988403 595.712891,2036.213379 
	C594.889648,2036.725220 593.918213,2037.554932 593.111755,2037.455688 
	C575.528809,2035.292236 558.393250,2042.193604 540.823486,2039.995361 
	C538.851929,2039.748657 536.833069,2039.713257 534.912231,2039.258789 
	C529.034668,2037.867798 517.528748,2036.844116 512.784912,2038.289307 
	C497.660309,2042.896851 483.217072,2037.667236 468.553497,2035.636841 
	C465.960144,2035.277710 464.524536,2033.383789 464.227325,2030.817627 
	C464.036896,2029.173584 464.088562,2027.497437 464.094818,2025.835815 
	C464.107697,2022.404419 468.098969,2018.052002 463.896790,2015.825928 
	C460.455688,2014.003052 455.697693,2014.545776 451.508331,2014.310425 
	C449.645477,2014.205933 448.480682,2015.592285 448.319183,2017.464600 
	C448.168945,2019.205688 448.601288,2020.830811 450.483368,2021.488159 
	C453.242401,2022.452148 457.208954,2022.826782 454.972504,2027.163696 
	C453.275726,2030.454224 451.816467,2033.952881 446.017883,2032.746704 
	C441.480377,2031.802734 436.357910,2034.288696 431.339691,2034.211060 
	C428.758514,2034.171265 427.448761,2036.223389 425.910858,2038.075439 
	C421.044830,2043.935425 416.426239,2050.560303 406.988617,2046.809326 
	C405.338257,2046.153442 403.690979,2047.242676 402.574097,2048.527100 
	C399.597656,2051.949707 395.698700,2052.223389 390.806488,2052.000000 
	C388.875854,2050.987305 388.365753,2049.155518 389.743683,2048.828369 
	C393.741791,2047.878906 395.946472,2045.897461 396.886719,2041.764404 
	C397.407043,2039.477295 402.535522,2039.769775 401.412506,2036.159302 
	C400.348389,2032.737671 398.734528,2029.345581 396.690125,2026.408569 
	C394.480652,2023.234253 392.151642,2024.919800 390.313812,2027.331421 
	C386.238647,2032.678833 380.935211,2032.458984 375.483398,2031.390015 
	C374.281219,2027.776001 376.770599,2026.541870 378.100616,2024.805542 
	C379.655975,2022.775269 380.410278,2020.662720 378.838806,2018.411133 
	C377.137360,2015.973267 374.833405,2016.562744 372.594971,2017.592041 
	C371.105499,2018.277100 369.492004,2018.842163 368.232361,2019.838257 
	C366.746033,2021.013794 365.396393,2021.598145 363.848663,2020.289917 
	C362.465210,2019.120483 361.589081,2017.157715 362.723907,2015.874878 
	C366.049561,2012.115234 366.792542,2009.201172 361.350128,2006.889526 
	C360.397583,2006.484863 360.374054,2005.233887 360.677856,2004.224487 
	C361.131165,2002.717773 362.385010,2002.198975 363.747101,2002.235229 
	C365.048553,2002.269775 366.587067,2002.296387 367.594635,2002.967285 
	C372.496338,2006.231812 376.949615,2005.648682 381.208099,2001.871216 
	C383.799255,1999.572632 386.123779,2001.254150 388.573456,2002.245483 
	C391.773804,2003.540405 394.585480,2006.457764 399.924591,2004.891602 
	C393.500854,1999.517334 388.571991,1994.313110 379.998047,1995.909424 
	C374.355927,1996.959961 368.795685,1995.226318 363.286194,1993.929443 
	C361.246185,1993.449097 359.557770,1992.544678 358.216339,1990.994385 
	C357.338135,1989.979736 356.461060,1988.746582 357.367096,1987.460815 
	C357.850067,1986.775635 358.966614,1986.276367 359.847870,1986.185791 
	C361.825439,1985.982422 364.413116,1985.269531 365.732697,1986.203979 
	C372.138000,1990.739868 378.804169,1986.632568 385.264282,1986.958496 
	C389.053528,1987.149536 393.121948,1984.024170 396.871002,1982.026733 
	C399.448395,1980.653564 401.192017,1980.235596 401.948120,1983.689575 
	C402.302856,1985.309937 402.537964,1987.236328 404.756042,1987.064453 
	C406.812500,1986.905151 408.502380,1985.759521 409.465149,1983.840088 
	C409.752625,1983.266846 409.826141,1982.563110 409.896423,1981.908203 
	C410.241638,1978.692261 409.166168,1974.452515 412.979980,1973.109131 
	C417.243683,1971.607422 421.542633,1969.712891 426.464020,1970.361084 
	C431.852295,1971.070679 432.866333,1969.563965 431.053894,1964.650269 
	C430.718658,1963.741455 429.907532,1963.019531 429.412201,1962.156128 
	C428.230988,1960.096924 428.541168,1958.300171 430.678528,1957.124756 
	C432.480255,1956.133911 434.134796,1957.016968 434.957031,1958.482056 
	C443.910553,1974.436157 459.263031,1971.785400 473.661163,1971.916870 
	C475.739044,1971.935913 477.454956,1970.939087 479.201263,1969.900635 
	C483.829681,1967.147827 489.027771,1966.662354 493.272980,1969.698486 
	C496.836853,1972.247314 500.349762,1970.486206 503.749084,1970.979126 
	C506.846252,1971.428223 510.297180,1968.903076 511.207794,1964.661377 
	C512.845032,1957.035156 517.924805,1954.295776 524.478577,1957.739746 
	C530.812866,1961.068237 538.440491,1962.306274 542.639709,1969.313721 
	C544.074707,1971.708252 554.704163,1972.564209 558.245605,1970.323364 
	C563.351624,1967.092651 566.347046,1969.706421 569.352966,1973.022827 
	C574.761108,1978.989868 574.699585,1979.045654 581.322510,1981.976929 
	C578.504639,1984.777344 575.832153,1983.592407 573.131470,1982.707153 
	C570.680176,1981.903564 568.296936,1982.184326 567.202576,1984.776001 
	C566.150513,1987.267700 568.923828,1987.921875 570.001953,1989.422241 
	C572.545288,1992.962036 575.031616,1994.591675 578.618530,1990.524292 
	C581.673706,1987.059937 586.030029,1988.450073 589.902832,1987.922485 
	C596.002686,1987.091675 602.905151,1987.403931 604.440735,1978.867798 
	C604.964539,1975.956177 607.804382,1976.241577 610.130127,1975.995239 
	C618.914917,1975.064697 626.642822,1978.759277 633.776489,1982.726685 
	C638.303162,1985.244141 641.133362,1986.365234 644.863037,1982.354370 
	C645.908447,1981.230225 647.666565,1980.401611 649.205933,1980.176025 
	C653.147827,1979.598145 653.658997,1977.809814 653.233093,1973.976929 
	C652.619873,1968.457275 655.346191,1966.724731 660.373840,1969.750000 
	C666.601196,1973.497070 674.386536,1970.281982 680.138428,1975.016479 
	C684.831665,1969.977661 691.055420,1972.714111 694.928040,1974.483032 
	C702.604980,1977.989868 710.224426,1977.911133 718.085754,1978.088867 
	C722.211487,1978.182373 724.491638,1981.472046 727.209106,1983.756104 
	C730.679993,1986.673584 734.183594,1988.427612 738.890869,1988.083496 
	C743.477783,1987.748169 748.144897,1987.455444 752.241699,1990.721680 
	C754.720703,1992.697998 757.441284,1991.771484 759.173706,1989.267822 
	C761.858704,1985.387207 765.235718,1985.422974 768.747314,1987.482422 
	C773.662903,1990.365112 778.970642,1990.026978 785.154053,1990.022339 
	C786.213745,1993.884644 784.913147,1998.161499 789.557373,2001.440308 
	C789.189026,2007.553833 784.213379,2007.255493 782.071655,2005.336426 
	C776.338684,2000.199463 768.478638,2002.935303 762.664795,1998.846924 
	C760.386902,1997.245239 757.429443,1997.731201 754.982483,1998.946289 
	C746.416504,2003.200195 737.030212,2003.855713 727.838989,2004.630005 
	C718.986023,2005.375854 715.907593,2011.174438 711.992126,2017.989014 
	C715.375793,2018.452881 714.708191,2016.522339 715.155396,2015.671143 
	C717.473206,2011.257935 720.628601,2007.610962 725.970764,2008.593994 
	C731.914001,2009.687744 735.118713,2014.565552 735.465271,2020.031250 
	C735.814819,2025.542969 735.346436,2030.900879 736.652527,2036.563110 
	C738.197876,2043.262573 729.825256,2043.272705 726.612854,2047.207764 
	C729.477539,2048.275391 731.610718,2046.203369 734.027710,2045.598633 
	C736.686523,2044.933472 739.411743,2044.077637 740.888733,2047.343750 
	C742.195984,2050.234131 740.668701,2052.462891 738.566467,2054.337402 
	C736.583374,2056.105713 734.409851,2057.642822 732.369385,2059.347412 
	C728.635742,2062.466309 727.119568,2064.766357 732.786377,2067.957764 
	C737.484070,2070.603516 739.839355,2075.230469 732.735657,2078.025391 
	C725.475586,2080.882080 729.493347,2083.649414 732.241943,2086.441650 
	C740.641113,2094.973877 741.025146,2095.337646 731.480835,2103.166992 
	C725.468628,2108.098877 735.195557,2109.337646 732.435547,2113.441895 
	C727.279663,2117.780518 721.754333,2115.452393 716.611328,2115.951416 
	C711.768921,2116.421387 706.705933,2115.000000 702.462585,2119.040527 
	C700.862000,2120.564697 697.875916,2120.693115 695.914612,2119.135742 
	C693.298401,2117.058594 695.682129,2114.848877 696.581055,2112.969482 
	C698.539612,2108.874268 699.877197,2104.144043 705.622803,2103.393799 
	C707.523193,2103.145752 707.930176,2101.092041 707.710754,2099.386230 
	C707.123108,2094.818604 698.223145,2089.628418 693.970886,2091.360352 
	C692.761597,2091.852783 691.775085,2092.889404 690.567627,2093.390137 
	C689.286072,2093.921631 687.765076,2093.767578 687.123413,2092.435547 
	C686.796021,2091.755615 687.132874,2090.371582 687.675049,2089.739258 
	C688.729980,2088.508057 690.007019,2086.929199 691.426697,2086.637939 
	C699.541809,2084.972412 702.594604,2077.912354 707.229492,2073.006836 
	C706.830994,2070.355957 705.060974,2070.130615 703.529968,2069.600586 
	C700.740540,2068.634766 697.201477,2068.413818 695.332520,2066.533447 
	C692.326172,2063.508545 697.448608,2061.732910 697.771912,2058.896729 
	C698.203857,2055.107910 695.821960,2052.108887 692.233276,2052.394043 
	C687.495850,2052.770996 683.363892,2056.836914 682.002930,2063.229492 
	C678.971619,2064.190430 676.840698,2065.508057 676.081055,2069.325684 
z"
        />
        <path
          fill="#6A8297"
          opacity="1.000000"
          stroke="none"
          d="
M887.354309,2092.369629 
	C890.258362,2092.240234 892.644897,2092.105469 894.841736,2092.686768 
	C898.703247,2093.708984 902.843811,2094.864746 902.033691,2100.341797 
	C901.500183,2103.949219 907.907837,2106.506592 904.145142,2110.058838 
	C899.987061,2113.984375 898.182800,2107.962402 895.178223,2106.774902 
	C889.902161,2104.689209 886.926697,2107.089111 887.536438,2113.283203 
	C887.669800,2114.637695 887.758728,2115.898193 886.498413,2117.542480 
	C881.918396,2121.485352 878.212402,2124.212891 882.854431,2130.793701 
	C886.218384,2135.562988 884.666260,2142.862305 889.980713,2147.727051 
	C892.790405,2150.298584 890.275452,2153.349609 886.583740,2153.818359 
	C881.272034,2154.492432 876.012329,2153.865234 870.868042,2152.209229 
	C873.729858,2154.202148 877.020996,2153.917236 880.160767,2153.943848 
	C884.285522,2153.978760 888.219971,2154.048584 890.529114,2158.268066 
	C892.410767,2161.706543 896.288879,2162.647949 899.526550,2165.501465 
	C900.039062,2165.970703 901.028137,2165.967285 901.522583,2165.973877 
	C905.846313,2173.154541 900.863647,2176.656006 895.554871,2177.113525 
	C887.643494,2177.795898 883.388062,2183.023193 877.386597,2187.518311 
	C883.932068,2186.819336 884.262573,2187.112549 888.328430,2182.530762 
	C891.962341,2178.435791 896.589355,2176.727783 901.448975,2176.365723 
	C906.222839,2176.010254 910.784546,2178.306641 907.857422,2184.124512 
	C905.037354,2189.729248 909.007629,2189.699463 911.960205,2190.355225 
	C917.168213,2191.511963 922.565796,2190.565918 927.842651,2191.206543 
	C931.912109,2191.700928 936.606384,2192.542480 936.716614,2197.446045 
	C936.842590,2203.045166 931.471069,2203.086182 927.790588,2202.819336 
	C913.348572,2201.770508 898.747864,2207.105225 884.397583,2201.595215 
	C882.050232,2200.694092 879.161499,2200.699219 875.939209,2202.001953 
	C868.778870,2207.099365 861.288940,2207.355713 851.794800,2205.894531 
	C856.037354,2208.298584 858.794434,2207.564697 861.328064,2208.155518 
	C864.928284,2208.994385 869.287476,2209.788574 868.595642,2214.552979 
	C867.962219,2218.915039 863.464478,2220.157715 859.976868,2219.333252 
	C853.109375,2217.709961 846.479614,2218.035400 840.018433,2219.357910 
	C848.426025,2218.488037 856.841492,2220.883301 865.385132,2221.637451 
	C870.819458,2222.116943 872.638000,2225.569824 870.338074,2230.906006 
	C868.023682,2236.275635 865.461304,2241.611328 864.742126,2248.811523 
	C869.075500,2246.815186 872.803650,2242.265625 876.458740,2247.507080 
	C879.426270,2251.762451 877.760071,2255.699463 875.096619,2259.416260 
	C873.936646,2261.034912 872.743286,2262.628662 870.674316,2264.406250 
	C869.107666,2265.108154 868.140198,2265.120605 866.564941,2264.458252 
	C859.382935,2265.323975 852.338501,2260.451172 846.005310,2265.515137 
	C845.603699,2265.836426 844.621155,2265.794678 844.093201,2265.539795 
	C835.164551,2261.228271 826.347168,2264.450195 817.408020,2265.577393 
	C811.213196,2266.358887 804.867432,2265.934082 798.589783,2266.075928 
	C796.862915,2266.114990 795.139587,2266.545898 794.686890,2268.420898 
	C794.220520,2270.352783 795.583618,2271.299072 797.246948,2271.998291 
	C800.769897,2273.479248 804.583435,2274.552490 806.933105,2277.437744 
	C805.846375,2282.230469 798.859009,2277.168457 799.591064,2283.590088 
	C800.951111,2285.561279 800.455688,2287.252197 800.293457,2289.428223 
	C795.917358,2289.986816 791.823120,2289.990234 786.849854,2289.982178 
	C785.038879,2285.708984 789.072876,2281.367188 786.819031,2277.630859 
	C784.509094,2273.801514 778.617493,2277.850342 774.789429,2273.858398 
	C778.591125,2270.166260 786.224670,2274.359863 787.886047,2266.734863 
	C789.156738,2263.766113 790.241089,2261.537354 788.242310,2258.540527 
	C790.835083,2254.563965 795.538513,2257.054443 799.340942,2254.298828 
	C804.797546,2258.611328 811.231628,2258.211670 815.741150,2255.789307 
	C823.604126,2251.565430 831.367859,2251.358887 839.699341,2251.936279 
	C842.770813,2252.149658 846.385254,2252.874512 849.741333,2248.574219 
	C838.760681,2251.271973 833.663147,2239.569824 824.606140,2240.521973 
	C827.561768,2242.496582 835.218140,2239.841797 834.421448,2246.438721 
	C833.727905,2252.181152 826.997986,2251.208740 822.708557,2250.282715 
	C816.430786,2248.927734 810.979980,2251.065430 804.638000,2252.283936 
	C801.799988,2247.361084 797.560364,2247.961426 793.596497,2248.023438 
	C789.084045,2248.093750 784.870300,2247.729736 782.963867,2242.725830 
	C783.239258,2242.078613 783.324402,2241.288086 783.759827,2240.954102 
	C785.988953,2239.242432 791.149902,2240.340332 790.638000,2236.587158 
	C790.006897,2231.959961 785.205078,2234.224365 782.264954,2234.097900 
	C774.946350,2233.782959 767.604553,2234.046143 760.273621,2233.956299 
	C757.969177,2233.928223 755.630554,2233.695557 753.382812,2233.208984 
	C752.118042,2232.935303 751.086060,2231.836914 751.217285,2230.294434 
	C751.362122,2228.592773 752.767700,2228.186035 754.076721,2228.174316 
	C759.735901,2228.123535 765.875854,2226.815186 770.942505,2228.565430 
	C778.329773,2231.117188 785.403259,2228.075195 792.574097,2229.454102 
	C795.772217,2230.069092 795.922241,2227.083008 795.238281,2224.545166 
	C793.840271,2219.356934 797.713501,2220.300537 801.267517,2220.009277 
	C805.053223,2220.964355 807.883057,2224.250488 811.745728,2220.343262 
	C812.492859,2219.519775 812.812561,2219.205811 813.672119,2218.484375 
	C817.299866,2216.365234 820.475647,2214.835693 824.019775,2213.197998 
	C824.304871,2212.917236 824.908142,2212.770752 824.908142,2212.770752 
	C824.908142,2212.770752 824.244629,2212.601318 823.953796,2212.982910 
	C820.550537,2214.428223 817.856750,2216.539062 813.845093,2217.028320 
	C812.745605,2216.919434 812.314087,2216.807129 811.325439,2216.326660 
	C809.297974,2213.648682 806.758118,2212.667969 804.343384,2210.531006 
	C803.857361,2206.956787 807.121460,2205.862549 807.903320,2202.640381 
	C811.666931,2197.934326 816.743469,2197.961182 821.522278,2197.179443 
	C818.745544,2196.603271 815.768494,2196.932617 812.355042,2195.657471 
	C803.438538,2192.229492 795.827148,2187.228760 786.592407,2186.737793 
	C783.649109,2186.581299 780.095337,2186.160156 779.453064,2182.562988 
	C778.727417,2178.499268 782.344177,2177.041992 785.179749,2175.382812 
	C789.775208,2172.693604 795.445007,2172.953369 800.577271,2169.948730 
	C795.737610,2160.876709 787.584534,2166.097412 780.371521,2164.928711 
	C771.742493,2164.500732 763.820129,2166.032227 756.087708,2164.056396 
	C752.443298,2163.125244 747.170532,2162.668213 747.644165,2157.693848 
	C748.104065,2152.864258 753.045349,2152.979004 757.911011,2152.983643 
	C767.527649,2151.999268 776.286255,2150.548584 785.303894,2155.431641 
	C786.320007,2155.972900 786.665100,2155.903320 786.983337,2155.967041 
	C792.231323,2157.019043 793.189941,2148.354248 798.873291,2150.645508 
	C803.378418,2152.461426 808.039917,2151.910156 812.667786,2151.985352 
	C814.656616,2152.017822 816.673889,2152.004883 818.633301,2151.712158 
	C821.052490,2151.350586 821.833496,2149.702881 821.381897,2147.365479 
	C820.356628,2142.058838 816.069580,2138.571533 810.101196,2137.984863 
	C806.959045,2137.675781 802.619202,2139.570312 801.029968,2135.974121 
	C799.115051,2131.641113 803.247070,2128.271484 805.830139,2125.904541 
	C812.987122,2119.345703 820.973938,2113.839600 831.676025,2117.536621 
	C832.288025,2117.748047 832.988525,2117.788086 833.644287,2117.770996 
	C835.373596,2117.725830 837.077759,2117.421387 837.307068,2115.296143 
	C837.534424,2113.188477 835.973694,2112.024902 834.292542,2112.233154 
	C827.982422,2113.014160 822.012695,2109.562012 815.648499,2111.037598 
	C811.460510,2112.008545 809.779541,2109.253906 808.164673,2104.721680 
	C815.189453,2106.354736 819.285645,2104.304443 818.804932,2095.957275 
	C822.847046,2101.349854 825.896973,2106.431641 833.396912,2102.543945 
	C830.819275,2099.544922 828.418152,2096.978271 826.278625,2094.209473 
	C822.984009,2089.946045 818.443420,2089.809570 813.698181,2089.938477 
	C810.815796,2090.016602 808.252197,2089.345947 808.276428,2085.694336 
	C808.296997,2082.593018 809.996704,2080.462891 813.025940,2080.300293 
	C818.191101,2080.022949 823.206848,2076.920654 828.598511,2079.650146 
	C829.912598,2080.315186 831.883423,2079.919189 833.503174,2079.679199 
	C835.191040,2079.429199 836.652405,2078.498047 836.980774,2076.637695 
	C837.283875,2074.919922 835.861877,2074.420410 834.639832,2073.947510 
	C830.938965,2072.515625 828.788330,2069.968506 831.233276,2066.322510 
	C833.757874,2062.557861 833.694153,2060.488525 829.059387,2059.217773 
	C827.753418,2058.859619 826.902344,2057.628174 827.020020,2056.164307 
	C827.160156,2054.421631 828.684814,2054.178467 829.961060,2054.111328 
	C837.274841,2053.725342 838.809692,2048.424316 840.055115,2042.659790 
	C841.775391,2034.697754 846.246155,2032.347290 853.812683,2035.207886 
	C859.378662,2037.312378 862.291077,2036.334717 863.659363,2031.902588 
	C865.034729,2027.447144 863.342590,2025.371216 857.077026,2023.747070 
	C856.457092,2023.586426 855.900818,2023.180054 855.312622,2022.886475 
	C854.088623,2020.094238 856.632629,2018.706177 857.605896,2016.751221 
	C859.239441,2013.470337 861.457825,2011.334351 865.527100,2012.817627 
	C869.545105,2014.282349 874.268677,2014.944702 873.960876,2020.897705 
	C873.712219,2025.706909 875.442444,2030.078247 877.279968,2034.327759 
	C880.194153,2041.067261 883.630981,2047.626221 880.176453,2055.226318 
	C879.921814,2055.786377 879.893982,2056.614502 880.102783,2057.194824 
	C882.394104,2063.564697 885.824463,2069.875977 880.687744,2076.467041 
	C885.442749,2082.362793 882.142395,2086.765869 878.047913,2091.092041 
	C876.633728,2092.585938 875.452393,2094.583740 876.937439,2096.423828 
	C878.899231,2098.855225 881.067688,2097.473877 882.961792,2095.923096 
	C884.246826,2094.870850 885.489685,2093.767090 887.354309,2092.369629 
z"
        />
        <path
          fill="#7F98AE"
          opacity="1.000000"
          stroke="none"
          d="
M732.760864,2113.995605 
	C731.705444,2109.694824 727.862793,2110.531006 725.378479,2109.305420 
	C722.158752,2107.717041 722.138245,2105.552002 725.249146,2103.493896 
	C729.495544,2100.684326 735.261230,2099.779297 737.751282,2093.457520 
	C729.870789,2090.940186 726.007629,2084.948730 722.563538,2078.056885 
	C725.516846,2074.707764 729.535828,2076.696045 732.810059,2075.752197 
	C733.768738,2075.475586 734.908875,2075.163086 734.838135,2073.962891 
	C734.785950,2073.077148 734.429016,2071.957275 733.800842,2071.402588 
	C731.509888,2069.378418 728.625732,2068.666016 725.721252,2067.811035 
	C721.584656,2066.593506 721.431091,2064.959717 725.052368,2062.040283 
	C729.385193,2058.546875 732.634033,2053.715820 738.126953,2051.611816 
	C739.333557,2051.149658 740.071106,2049.598633 739.406433,2048.222656 
	C738.398071,2046.135132 736.944275,2046.951660 735.337708,2047.742065 
	C726.002930,2052.334473 724.298279,2052.098633 718.051575,2044.857788 
	C725.868958,2046.741455 728.965637,2039.398315 734.775513,2039.445190 
	C735.470154,2035.833984 733.531067,2034.674561 732.272522,2033.250854 
	C729.946533,2030.619141 729.544739,2027.968750 731.865784,2025.217163 
	C734.508057,2022.084717 733.934998,2019.273682 731.505554,2016.296631 
	C729.111328,2013.362671 727.890076,2008.394043 722.500305,2011.080200 
	C720.145264,2012.253906 718.205200,2014.274780 716.997131,2016.711548 
	C715.661072,2019.406250 718.526184,2020.831543 719.091797,2022.961182 
	C719.228882,2023.477051 718.810669,2024.140503 718.511902,2025.232422 
	C714.217834,2024.800781 712.248413,2020.846191 709.117798,2018.807495 
	C707.933411,2018.036255 707.885010,2015.996460 708.796021,2015.197388 
	C714.792908,2009.937988 717.135376,2000.908325 728.578613,2001.426758 
	C737.330200,2001.823120 747.178833,2000.932861 755.331299,1995.441284 
	C757.608276,1993.907593 760.515198,1993.262207 762.918213,1994.842285 
	C767.228210,1997.675903 770.868408,2002.422607 776.525574,1996.706543 
	C777.685181,1995.534668 779.502930,1997.517944 779.838501,1999.296143 
	C780.966919,2005.275513 784.873291,2004.321533 789.471863,2002.536743 
	C790.884399,2002.013794 791.770386,2002.012817 793.320862,2002.013306 
	C798.706604,2008.119629 790.764160,2009.358276 789.588867,2013.165283 
	C791.093384,2016.671387 799.233459,2016.176880 796.173462,2021.274780 
	C792.619629,2027.194824 786.288635,2024.648315 780.657532,2023.092651 
	C788.438538,2028.946899 798.069946,2031.748413 805.912354,2037.556030 
	C808.687988,2039.611328 813.094849,2040.038574 812.445312,2045.454590 
	C810.771606,2047.955322 809.507202,2049.891846 808.294922,2051.860352 
	C805.005920,2057.200195 801.332214,2058.500732 795.294800,2056.165771 
	C793.531128,2055.483643 791.788818,2053.526611 790.556091,2056.492676 
	C789.838379,2058.219482 790.831116,2060.104980 792.348267,2060.686279 
	C796.280884,2062.193115 800.477966,2062.847412 799.072998,2068.988770 
	C798.690063,2070.662842 801.576660,2071.734131 803.665283,2071.956787 
	C806.655762,2072.275879 809.326721,2073.126465 811.703369,2076.824219 
	C803.926392,2077.667236 800.138550,2081.988770 798.551758,2088.670410 
	C797.452881,2093.297363 793.071472,2093.878662 789.488525,2094.796387 
	C789.154053,2097.679443 790.974609,2097.764648 792.543701,2098.052246 
	C794.989990,2098.500488 797.084778,2099.465088 797.476135,2102.184082 
	C797.961243,2105.554199 795.224121,2105.660156 793.018127,2106.234375 
	C791.558899,2106.614258 790.624023,2107.486328 791.130798,2109.063477 
	C791.722107,2110.903320 793.281494,2111.734131 795.022583,2111.208252 
	C797.952087,2110.323486 800.434814,2110.427734 801.452454,2113.697510 
	C802.329346,2116.515137 800.908386,2118.943359 798.915649,2120.800781 
	C797.886597,2121.760010 796.411621,2121.086426 794.604370,2120.236328 
	C784.846191,2118.927002 775.681580,2118.014160 765.538940,2119.151855 
	C769.665100,2119.741943 772.847473,2118.880371 776.056763,2118.973633 
	C782.972534,2119.175293 789.831116,2119.811035 791.553528,2128.166016 
	C792.902039,2134.707031 785.147888,2143.385010 777.249084,2144.682861 
	C771.669800,2145.599365 766.003967,2145.165527 760.375000,2145.163330 
	C752.481750,2145.159912 744.646301,2144.208496 736.692078,2144.525879 
	C729.181580,2144.825439 726.807861,2140.872314 730.708374,2134.620361 
	C733.515625,2130.120850 730.934143,2125.383789 733.587952,2120.621094 
	C734.650085,2119.996826 735.301697,2120.050537 735.939209,2119.978516 
	C737.751343,2119.774170 740.344604,2120.497559 740.778992,2118.042480 
	C741.306458,2115.061279 738.687866,2114.512451 736.419128,2114.125244 
	C735.473755,2113.964111 734.488647,2114.035645 732.760864,2113.995605 
z"
        />
        <path
          fill="#748B9F"
          opacity="1.000000"
          stroke="none"
          d="
M978.488525,1932.701660 
	C977.621704,1928.051392 979.588013,1926.126221 983.395325,1925.585693 
	C984.820923,1925.383301 985.521484,1924.156494 985.421326,1922.767456 
	C985.318237,1921.337524 984.291382,1920.550903 982.937683,1920.229004 
	C979.820862,1919.487915 976.700073,1918.763550 972.791260,1918.004028 
	C972.030640,1914.812256 971.460510,1912.115845 967.449890,1912.044922 
	C964.658142,1911.995483 962.273438,1911.392212 962.036621,1907.196045 
	C964.091431,1903.141235 967.358643,1903.304932 971.388489,1903.708984 
	C974.699707,1904.701294 976.338684,1906.719727 978.087708,1908.581177 
	C982.652466,1913.438599 986.878601,1913.136475 991.349976,1907.288330 
	C993.601990,1905.522827 995.651123,1904.980957 998.328613,1903.822510 
	C1000.259583,1903.349609 1001.399231,1903.195068 1003.434814,1902.965088 
	C1029.531982,1900.796631 1054.574829,1905.749146 1079.808838,1904.485718 
	C1093.016113,1903.824463 1106.329102,1903.674561 1120.406738,1905.110718 
	C1126.029663,1906.888306 1130.574829,1909.812012 1136.722900,1907.751465 
	C1139.436157,1907.020996 1141.317505,1906.171509 1144.088867,1905.654907 
	C1152.551025,1905.315552 1154.914917,1909.386108 1153.142700,1916.307617 
	C1151.802368,1921.542358 1152.798096,1925.040771 1158.905151,1924.613159 
	C1164.235474,1924.239868 1171.712036,1927.551147 1172.598145,1917.967773 
	C1173.201294,1911.442627 1172.415771,1904.674927 1176.795166,1898.196777 
	C1182.259644,1895.015991 1186.674561,1896.912231 1191.649658,1899.666138 
	C1200.303223,1908.676758 1218.245605,1908.999268 1226.963867,1900.250366 
	C1228.924683,1899.096313 1230.276123,1899.411621 1232.170166,1900.527222 
	C1238.222778,1908.010498 1245.337158,1908.879028 1253.997314,1906.186035 
	C1260.324951,1904.643433 1265.737427,1907.920288 1271.376221,1906.937378 
	C1274.345825,1906.419800 1278.351685,1908.126953 1279.461914,1902.673828 
	C1279.112061,1893.449951 1286.655273,1896.050415 1291.565796,1893.810791 
	C1294.361938,1893.582397 1296.265015,1894.088989 1298.467773,1895.916260 
	C1299.211060,1896.947876 1299.434937,1897.408081 1299.818359,1898.605347 
	C1299.975708,1899.833374 1299.973511,1900.324341 1299.814697,1901.520874 
	C1296.205322,1910.897339 1304.861816,1906.694214 1305.495117,1906.440552 
	C1310.466675,1904.449585 1315.023071,1905.021240 1320.351440,1907.187744 
	C1328.016479,1907.445923 1329.030273,1915.748779 1335.616455,1917.752930 
	C1342.295044,1924.357544 1337.556641,1926.838989 1332.316895,1928.465942 
	C1327.549194,1929.946289 1322.365234,1928.305542 1316.669800,1929.813354 
	C1302.405396,1927.050659 1289.107666,1931.347168 1276.184082,1930.025269 
	C1273.823853,1934.342285 1279.154907,1933.456177 1278.902954,1934.560913 
	C1278.628784,1937.234985 1277.578491,1937.866821 1276.711304,1937.724243 
	C1266.354248,1936.023560 1255.929199,1937.657471 1245.453125,1936.682617 
	C1228.999878,1935.151489 1212.307861,1936.240356 1195.718018,1936.090698 
	C1190.412598,1936.042847 1190.968018,1941.980713 1186.757812,1943.845581 
	C1185.379272,1943.316650 1184.851807,1942.529907 1184.292969,1940.960693 
	C1183.798706,1938.763672 1184.501709,1936.514160 1182.342896,1936.141235 
	C1171.414551,1934.253784 1160.651489,1934.324951 1150.429810,1940.331299 
	C1150.196533,1942.452393 1149.801147,1943.805542 1148.515381,1945.520752 
	C1145.343872,1945.895996 1142.576538,1946.393799 1140.212158,1944.802612 
	C1127.021973,1935.926025 1112.612915,1934.423218 1097.436279,1937.738892 
	C1093.265381,1938.650146 1089.648560,1936.355835 1085.701538,1936.084473 
	C1074.260010,1935.297485 1062.831421,1935.193970 1051.361938,1935.845459 
	C1045.116821,1936.200317 1038.758301,1936.771606 1032.556396,1934.631836 
	C1030.432373,1933.899048 1027.959961,1934.050903 1025.643921,1934.020874 
	C1013.440796,1933.862427 1001.217712,1934.435059 988.523438,1933.466919 
	C984.800903,1936.520996 981.823059,1935.911011 978.488525,1932.701660 
z"
        />
        <path
          fill="#7790A8"
          opacity="1.000000"
          stroke="none"
          d="
M792.418945,1990.473633 
	C791.367126,1987.438721 790.427490,1984.893921 790.159485,1982.280396 
	C789.602539,1976.848022 792.263916,1975.015137 797.483582,1977.035645 
	C798.391724,1977.387329 799.080811,1978.298706 799.987244,1978.661987 
	C805.021057,1980.679443 808.756348,1978.429565 807.273682,1973.593140 
	C805.362610,1967.358887 806.740417,1965.115601 812.951416,1962.918945 
	C821.159973,1960.015747 820.280273,1952.026001 819.963501,1944.946045 
	C819.685547,1938.735840 815.862244,1936.471313 810.257080,1935.627930 
	C807.313049,1935.185059 804.214905,1933.876831 802.798828,1931.073486 
	C800.389771,1926.304810 796.390137,1925.556519 791.859131,1926.035889 
	C781.802856,1927.100342 772.105652,1923.077759 762.096497,1924.022827 
	C750.383301,1925.128784 738.281860,1923.547974 727.197632,1929.178101 
	C724.455872,1930.570801 721.320129,1930.083130 718.331909,1929.724854 
	C710.318298,1928.764160 710.319458,1928.754761 706.460266,1937.258911 
	C699.976624,1930.392578 690.737488,1933.418945 683.007019,1930.249390 
	C679.071899,1928.635986 674.524597,1930.474731 670.577515,1932.622803 
	C667.028625,1934.554199 662.469727,1934.983154 660.736206,1931.287354 
	C656.326050,1921.884521 647.841858,1924.672241 641.076843,1924.597900 
	C636.557007,1924.548218 632.035828,1925.093140 627.497009,1924.103394 
	C626.222473,1923.825562 624.735291,1923.704834 623.523804,1924.083008 
	C611.889160,1927.713623 600.604126,1923.458130 589.153931,1922.907227 
	C583.346313,1922.627930 577.277588,1923.768921 571.621338,1925.332642 
	C564.034119,1927.430298 556.828003,1930.880493 549.274902,1933.136230 
	C543.910950,1934.738159 539.614502,1936.956299 538.480774,1945.791748 
	C534.682800,1936.908325 529.472839,1935.612183 523.104370,1935.635986 
	C519.247131,1935.650391 515.355713,1933.927368 511.530609,1932.810425 
	C508.239929,1931.849487 504.874695,1931.509644 501.685791,1932.541748 
	C498.305786,1933.635742 495.336121,1934.174438 492.369873,1931.598267 
	C490.411285,1929.897461 487.933044,1929.621338 485.962646,1931.403442 
	C482.038818,1934.952759 478.544800,1932.329712 474.454102,1930.526001 
	C479.419250,1921.508911 479.643646,1921.726196 488.699493,1924.935547 
	C493.525726,1926.646118 498.428009,1928.328369 504.140320,1926.578857 
	C509.101562,1925.059204 514.691833,1925.391235 519.919128,1926.974854 
	C524.816589,1928.458618 529.926636,1928.821777 534.674622,1927.254883 
	C539.993164,1925.499756 544.847107,1921.958496 550.180359,1927.287964 
	C551.358215,1928.464966 553.245422,1927.022705 554.731262,1926.219482 
	C563.492859,1921.483521 573.769470,1921.022949 582.616028,1916.396851 
	C584.220581,1915.557861 586.754028,1916.613525 588.501648,1915.908936 
	C595.049988,1913.269287 600.064453,1922.402466 606.332153,1918.335938 
	C611.980225,1914.671387 617.963074,1916.488770 623.804138,1916.043701 
	C628.131653,1915.713867 632.492615,1915.792969 636.400696,1918.178101 
	C639.013306,1919.772461 641.132690,1918.834229 643.776611,1917.656128 
	C651.354675,1914.279175 654.224976,1915.202148 660.447876,1921.899292 
	C661.417358,1922.942383 662.342163,1923.561157 663.795227,1923.570801 
	C681.985779,1923.690552 700.176025,1923.848633 718.366394,1923.991699 
	C719.365234,1923.999512 720.574585,1924.327393 721.330383,1923.890747 
	C727.576050,1920.282837 734.605591,1920.541626 741.278687,1919.095337 
	C749.274414,1917.362183 757.268188,1917.243896 765.208313,1916.145752 
	C770.764160,1915.377441 776.512329,1915.983154 782.174438,1916.011353 
	C783.836609,1916.019653 786.338867,1915.474121 787.031067,1916.332031 
	C794.746704,1925.895264 810.207275,1919.221069 816.878845,1930.699585 
	C817.028015,1930.956299 817.408569,1931.202271 817.703674,1931.232178 
	C825.030151,1931.975342 829.290955,1934.643921 830.722961,1943.186401 
	C831.789368,1949.547729 835.482849,1955.423462 832.910400,1962.228271 
	C832.304016,1963.832031 832.464600,1965.413208 832.656738,1967.105591 
	C833.596680,1975.382568 830.941040,1978.356689 823.301514,1977.877686 
	C815.717529,1977.402100 815.717529,1977.402100 813.919861,1984.333130 
	C811.956543,1991.902710 807.464294,1994.572876 800.471130,1991.368408 
	C797.635925,1990.069092 795.251282,1992.394165 792.418945,1990.473633 
z"
        />
        <path
          fill="#7B7F84"
          opacity="1.000000"
          stroke="none"
          d="
M698.455566,1903.512939 
	C669.296326,1902.121826 640.566162,1901.693115 610.878906,1901.932861 
	C605.715271,1898.270386 608.225281,1892.539795 605.328613,1887.791748 
	C602.532471,1883.867065 604.510193,1878.892700 600.330383,1875.543457 
	C599.092712,1873.823608 599.011597,1872.498413 599.408813,1870.478516 
	C600.530640,1867.433716 601.797485,1865.144043 602.357910,1861.887817 
	C602.741455,1860.828857 602.947144,1860.420532 603.647827,1859.524902 
	C604.566956,1858.841675 604.991089,1858.645386 606.111633,1858.333008 
	C607.752380,1858.252075 608.669189,1858.426147 610.149048,1859.128418 
	C615.078918,1864.042358 618.708069,1869.193604 623.473511,1874.190308 
	C625.538940,1875.940796 627.133240,1876.865112 629.438232,1874.512451 
	C631.542053,1873.054321 633.240967,1872.497559 635.779236,1873.316040 
	C638.840698,1877.465942 637.176392,1881.462524 636.740417,1885.321045 
	C636.184814,1890.237427 637.768005,1893.083618 643.464600,1893.384399 
	C641.584106,1887.918579 639.553894,1882.520508 641.201904,1876.066284 
	C643.481812,1873.562988 645.875122,1872.939819 649.216980,1872.891357 
	C653.712097,1875.328613 658.945435,1874.906982 661.952026,1879.886719 
	C662.823120,1882.322510 663.239197,1884.144897 664.042236,1886.458374 
	C664.593445,1887.496948 663.959106,1888.662842 665.104980,1888.583252 
	C666.430359,1888.491089 664.615112,1889.073730 665.125854,1887.262329 
	C665.130737,1884.923096 665.117249,1883.160767 665.337402,1880.672974 
	C665.606750,1878.969116 665.939270,1878.042969 666.843750,1876.573364 
	C667.737244,1875.774536 668.174255,1875.559692 669.328369,1875.429199 
	C673.539673,1880.975952 678.439819,1884.489502 684.768311,1886.144409 
	C686.937439,1883.468506 686.598694,1879.782471 688.914856,1876.463257 
	C689.781982,1875.709717 690.204285,1875.502441 691.331787,1875.343994 
	C695.874695,1878.636108 698.880676,1882.913696 704.278442,1885.346191 
	C704.033020,1890.672119 712.296570,1898.368286 699.855164,1899.362183 
	C698.864563,1898.768555 698.630493,1898.595215 698.630493,1898.595215 
	C698.015747,1897.937744 697.983948,1897.926270 698.826538,1899.005615 
	C699.567078,1900.667725 699.411926,1901.851196 698.455566,1903.512939 
z"
        />
        <path
          fill="#6F7981"
          opacity="1.000000"
          stroke="none"
          d="
M909.088867,1912.742310 
	C901.253418,1912.426758 895.022705,1916.970703 887.510376,1916.751221 
	C884.124084,1916.652344 880.438538,1918.033203 876.775696,1918.017944 
	C875.456116,1918.012451 873.428772,1917.696167 872.924072,1918.391724 
	C867.333557,1926.099731 861.673584,1920.018921 855.718079,1919.139038 
	C844.676514,1917.507568 834.511658,1914.359009 825.413879,1907.803589 
	C822.600342,1905.776367 819.125183,1906.025146 815.785645,1906.025391 
	C805.167908,1906.026001 794.550232,1905.957886 783.000366,1905.880981 
	C779.698059,1900.950928 783.602844,1900.312500 786.642517,1899.404297 
	C789.464966,1898.561035 792.533386,1899.337402 796.181030,1898.264160 
	C799.013855,1897.447754 801.100098,1897.448364 803.921570,1897.894287 
	C807.036377,1898.421753 809.425659,1901.476440 812.176392,1897.506226 
	C808.920532,1887.907471 815.347473,1880.816772 817.662231,1872.380371 
	C818.451477,1871.283447 819.578735,1871.443359 819.606812,1870.689331 
	C819.615967,1870.444092 818.547241,1870.504761 817.324341,1870.839478 
	C812.109253,1866.516479 811.710999,1863.323853 819.620850,1862.101074 
	C821.917969,1862.606445 823.216614,1863.412964 824.801758,1865.109009 
	C828.231201,1872.630249 826.941956,1879.811646 826.304688,1886.889038 
	C825.933777,1891.008179 826.464417,1894.019653 831.869751,1894.948120 
	C835.380798,1898.479858 839.021423,1899.158813 843.196960,1899.077515 
	C851.776062,1898.910034 860.362427,1898.855103 868.946045,1899.020142 
	C871.586365,1899.070923 874.626892,1898.319092 876.781311,1899.566284 
	C887.185303,1905.589600 896.622681,1901.280273 906.865967,1897.145508 
	C916.094727,1898.539185 924.253967,1901.504395 932.658142,1905.117920 
	C932.253479,1910.480957 928.137939,1910.204590 924.397217,1911.705566 
	C919.183838,1913.985718 914.489075,1914.313843 909.088867,1912.742310 
z"
        />
        <path
          fill="#7B7F84"
          opacity="1.000000"
          stroke="none"
          d="
M602.561584,1901.274658 
	C588.210693,1901.912476 574.280762,1902.140991 560.356262,1901.959229 
	C552.043518,1901.850708 543.697998,1901.822266 535.773438,1904.263672 
	C530.663269,1905.838013 526.054382,1904.943481 520.411255,1904.035645 
	C519.224060,1903.803711 518.850403,1903.583740 518.006470,1902.910034 
	C516.424805,1901.076782 515.442139,1899.620605 514.722168,1897.271118 
	C514.626404,1895.696533 514.829590,1894.827637 515.658081,1893.496826 
	C519.454590,1890.968384 523.796204,1890.996582 527.187134,1887.525391 
	C528.603943,1886.814941 529.491516,1886.673828 531.070801,1886.868896 
	C534.846008,1886.643677 533.981995,1896.045532 539.360474,1889.460449 
	C542.267029,1878.043091 547.865417,1877.166016 560.742188,1886.817261 
	C558.212341,1883.639282 556.642883,1881.881714 556.492615,1879.391602 
	C556.283142,1875.919312 559.121399,1874.177124 560.752869,1870.953735 
	C561.785889,1867.268677 563.195557,1864.659790 566.605225,1862.595215 
	C568.307129,1861.963745 569.328552,1861.824585 571.129150,1861.952026 
	C572.356934,1862.232178 572.827271,1862.405884 573.906433,1863.003906 
	C578.158752,1869.291626 582.855286,1874.045044 589.473877,1876.340576 
	C592.206360,1877.288208 594.043640,1878.803345 595.575745,1881.895386 
	C595.983582,1882.975220 596.115295,1883.416626 596.377197,1884.531982 
	C596.608398,1886.094360 596.677979,1886.985352 596.758545,1888.519043 
	C596.906311,1890.390625 597.065125,1891.601318 598.565552,1892.848145 
	C600.257690,1893.819702 601.377625,1894.514038 602.766235,1895.970703 
	C603.649353,1897.955566 603.610535,1899.336426 602.561584,1901.274658 
z"
        />
        <path
          fill="#7790A8"
          opacity="1.000000"
          stroke="none"
          d="
M812.688721,2045.914795 
	C811.755310,2044.701172 811.562073,2043.163940 810.226318,2042.659668 
	C804.000916,2040.308838 798.153748,2037.386353 793.223267,2032.789917 
	C792.297913,2031.927246 790.764465,2030.895996 789.734558,2031.095337 
	C780.875061,2032.809082 779.331970,2025.120483 775.583374,2020.311035 
	C774.767883,2019.264771 774.270874,2017.910278 775.695312,2016.877808 
	C776.898499,2016.005615 778.556152,2016.046875 779.309814,2017.066284 
	C782.660583,2021.598022 787.061218,2022.813721 792.350220,2021.820679 
	C793.337463,2021.635254 794.233215,2021.372192 794.491577,2019.961670 
	C793.907898,2017.477051 791.606506,2018.162109 789.830139,2018.020874 
	C787.057312,2017.800415 784.358521,2017.597900 784.271362,2013.910156 
	C784.199036,2010.851807 785.753967,2008.837646 788.737183,2008.272339 
	C791.537964,2007.741699 793.940186,2007.034058 793.958740,2002.776489 
	C795.979675,2002.057739 797.982910,2002.028442 799.600647,2003.520264 
	C801.505127,2005.276245 803.289978,2005.363525 803.834534,2002.500610 
	C804.839111,1997.219238 808.763916,1997.877319 812.343689,1998.161255 
	C815.876343,1998.441284 819.054810,2000.097290 823.005005,1998.862061 
	C826.978271,1997.619629 830.501404,2001.198364 829.015991,2005.110474 
	C825.956665,2013.168579 826.102966,2022.684082 817.171509,2028.257812 
	C810.757935,2032.260254 811.168762,2034.577515 815.888428,2040.388184 
	C818.696899,2043.846069 815.848389,2044.773193 812.688721,2045.914795 
z"
        />
        <path
          fill="#7790A8"
          opacity="1.000000"
          stroke="none"
          d="
M733.399048,2120.310547 
	C734.333008,2121.953125 734.330627,2124.039062 735.054016,2125.832275 
	C736.896484,2130.399170 740.795898,2135.291748 731.906555,2136.785889 
	C731.338806,2136.881348 730.607971,2138.394287 730.651917,2139.209961 
	C730.726990,2140.606689 731.768494,2141.568115 733.262451,2141.726807 
	C734.583191,2141.867188 735.934814,2142.090576 737.239014,2141.954102 
	C749.142029,2140.710449 761.036743,2142.171875 772.933167,2142.189453 
	C779.846680,2142.199707 784.179077,2139.885986 787.399170,2133.925537 
	C790.879944,2127.482422 789.078125,2122.906494 781.777771,2122.149170 
	C777.844421,2121.741455 773.808411,2122.235596 769.824890,2122.441895 
	C768.195679,2122.526123 766.266357,2123.610840 765.026611,2123.039795 
	C762.226257,2121.750000 756.850891,2123.479736 756.905640,2119.130371 
	C756.966919,2114.263672 761.976868,2116.615967 764.892944,2116.001709 
	C772.789673,2114.337646 780.685608,2116.267334 788.584534,2116.041992 
	C790.624390,2115.983643 792.609131,2116.257324 793.805298,2119.223633 
	C793.598816,2120.486816 792.731689,2121.242676 792.850647,2121.461182 
	C795.537781,2126.394287 798.968262,2131.272705 792.684448,2136.096680 
	C794.902100,2142.962158 790.598633,2147.191895 786.552124,2151.603027 
	C785.992676,2152.212891 786.184204,2153.511475 786.007751,2155.235840 
	C777.852112,2156.685791 769.717407,2153.887695 760.781128,2155.970215 
	C750.833191,2147.434814 739.909973,2149.618408 728.568481,2150.425781 
	C723.512207,2147.248291 718.523743,2148.137207 712.839050,2148.004150 
	C710.772888,2146.661865 710.531982,2145.114990 711.528809,2142.729492 
	C722.700806,2141.613281 722.804260,2141.346924 718.244507,2131.756348 
	C717.359192,2129.894287 716.322632,2128.431152 714.737244,2127.187500 
	C713.436646,2126.167725 712.162720,2124.793213 713.576294,2123.112549 
	C714.764954,2121.699219 717.033691,2121.586426 718.107361,2122.508301 
	C723.846558,2127.436035 728.302856,2124.298584 733.399048,2120.310547 
z"
        />
        <path
          fill="#434F51"
          opacity="1.000000"
          stroke="none"
          d="
M932.593750,1906.417969 
	C931.498718,1905.562378 930.938354,1904.811279 930.472900,1904.866211 
	C922.389587,1905.818115 915.323547,1903.714111 908.636780,1898.071777 
	C906.754028,1898.265015 905.795532,1897.929932 904.626160,1896.351318 
	C903.322998,1892.962036 903.697021,1890.425537 905.612671,1887.429199 
	C912.963257,1882.985107 919.168701,1881.168213 927.972107,1883.599487 
	C935.940613,1885.800171 946.841125,1887.278442 954.175781,1882.497070 
	C963.310425,1876.542236 968.179504,1881.845581 974.380493,1885.825073 
	C976.389771,1887.114380 977.991760,1888.754883 979.082642,1891.731201 
	C979.338379,1897.274902 978.094360,1901.648193 974.133423,1905.646484 
	C970.146423,1906.025269 966.877930,1906.092773 962.802002,1906.307617 
	C953.944885,1906.322510 945.895203,1906.189941 936.924255,1906.158936 
	C935.150879,1906.878784 934.207458,1907.091309 932.593750,1906.417969 
z"
        />
        <path
          fill="#63707D"
          opacity="1.000000"
          stroke="none"
          d="
M312.539001,1897.766113 
	C311.675964,1896.846313 311.782074,1896.077881 311.919189,1894.726074 
	C314.121216,1890.762451 317.563538,1889.861328 322.116211,1889.362061 
	C354.147675,1891.331055 385.352875,1890.570557 417.319763,1891.502441 
	C420.576477,1890.575928 422.238678,1888.407593 425.394562,1887.473877 
	C433.778107,1895.546875 443.071686,1896.755249 453.123901,1893.487061 
	C459.855682,1891.298462 466.713989,1892.952271 474.203674,1891.921387 
	C480.940643,1892.052979 486.455688,1893.375977 490.198700,1899.297485 
	C488.001343,1902.421753 485.241913,1902.199219 482.651459,1901.691650 
	C467.137451,1898.651733 451.585846,1899.856567 435.997650,1900.837524 
	C431.095642,1901.145874 426.114929,1899.994141 421.186523,1900.124878 
	C385.255951,1901.077515 349.432373,1897.035522 312.539001,1897.766113 
z"
        />
        <path
          fill="#D7D5CF"
          opacity="1.000000"
          stroke="none"
          d="
M697.983765,1898.822998 
	C700.185059,1897.804199 702.032349,1898.923096 704.611389,1897.186401 
	C700.307068,1894.317017 702.835144,1890.756836 703.463684,1886.553955 
	C701.345215,1882.800293 702.175720,1879.908447 704.364746,1876.508545 
	C707.023865,1874.449097 710.070068,1874.380249 710.596985,1876.654053 
	C711.670593,1881.287354 715.525452,1881.986694 718.955933,1884.537598 
	C720.670166,1888.116699 722.646118,1890.700684 725.027954,1893.907104 
	C726.333984,1898.644165 725.185547,1901.711792 720.383728,1903.459839 
	C712.988037,1905.695557 706.301025,1906.433105 698.736450,1904.262695 
	C697.870483,1902.660889 697.748840,1901.311646 697.983765,1898.822998 
z"
        />
        <path
          fill="#7A7E89"
          opacity="1.000000"
          stroke="none"
          d="
M720.128601,1904.070068 
	C721.006592,1901.001587 724.599915,1899.663818 723.564514,1895.261353 
	C724.276733,1892.363892 726.300293,1891.744019 728.210083,1891.876099 
	C740.237854,1892.707520 752.297791,1889.223511 764.992554,1892.466064 
	C766.090759,1893.067627 766.493347,1893.376465 767.358154,1894.295410 
	C768.973816,1897.238403 769.791077,1899.642212 769.049500,1903.000244 
	C759.509277,1903.795166 750.299194,1903.842285 741.091431,1904.011719 
	C734.277832,1904.136963 727.467102,1904.421265 720.128601,1904.070068 
z"
        />
        <path
          fill="#817A79"
          opacity="1.000000"
          stroke="none"
          d="
M490.654175,1900.069824 
	C485.707428,1897.278320 481.043427,1895.138794 475.195679,1894.087524 
	C473.636200,1893.537720 473.111115,1892.789795 473.054138,1891.085083 
	C475.912781,1887.509155 477.267975,1884.687866 477.222046,1880.109009 
	C477.138153,1871.751099 482.921997,1869.694580 490.684387,1875.148438 
	C493.662231,1880.596802 487.175690,1888.416870 496.531281,1891.840820 
	C497.845917,1895.209351 496.914978,1897.457031 494.210083,1899.715820 
	C492.731262,1900.039062 491.975616,1900.045532 490.654175,1900.069824 
z"
        />
        <path
          fill="#EFF1EA"
          opacity="1.000000"
          stroke="none"
          d="
M493.745361,1900.636963 
	C494.115387,1897.778320 494.743866,1895.524170 495.475464,1892.579956 
	C494.522491,1886.552124 497.124878,1882.873535 501.818604,1879.603271 
	C504.229797,1879.769775 505.201447,1880.894897 506.091309,1883.069458 
	C506.520294,1885.084595 506.793152,1886.476196 506.347137,1887.689697 
	C503.555298,1895.284790 506.892059,1898.689453 514.207947,1899.628296 
	C515.820312,1899.835205 517.352966,1900.551514 519.197876,1901.898438 
	C519.572815,1902.495605 519.591125,1903.259644 519.594482,1903.641602 
	C517.704163,1905.355225 515.519531,1905.338257 512.682617,1905.406494 
	C510.906799,1905.744019 509.800598,1905.764526 508.162140,1905.210815 
	C506.900299,1904.997681 506.179810,1904.941772 504.920654,1904.956909 
	C502.712891,1905.596191 501.023529,1905.893799 498.459290,1905.116333 
	C495.976868,1904.088989 494.640106,1903.020996 493.745361,1900.636963 
z"
        />
        <path
          fill="#C7C0B1"
          opacity="1.000000"
          stroke="none"
          d="
M908.577759,1913.062744 
	C912.305420,1909.134888 917.027222,1913.033813 921.494080,1910.338135 
	C925.542297,1908.019043 927.691406,1909.935303 929.711182,1913.346680 
	C929.507080,1915.341064 929.341003,1916.920288 927.908142,1917.228638 
	C922.467041,1918.399170 916.946838,1918.515259 911.484558,1917.479858 
	C909.820618,1917.164673 909.063660,1915.443604 908.577759,1913.062744 
z"
        />
        <path
          fill="#C4BDAE"
          opacity="1.000000"
          stroke="none"
          d="
M769.383240,1903.790039 
	C767.815247,1901.321899 766.912170,1898.948120 766.022827,1895.802124 
	C767.140015,1894.031494 767.212097,1892.836182 766.555237,1890.890015 
	C766.288269,1889.374146 766.249084,1888.494995 766.362305,1886.951782 
	C766.810547,1884.530518 767.451355,1882.867798 768.667236,1880.706543 
	C769.440613,1879.872192 769.817688,1879.601440 772.137695,1879.898682 
	C777.684204,1884.426147 776.148315,1889.606812 777.534546,1894.838379 
	C777.921509,1896.498657 778.007080,1897.470947 777.914917,1899.188965 
	C777.458008,1901.417847 776.681885,1902.652710 774.796143,1903.901978 
	C772.755432,1904.084839 771.401794,1903.984741 769.383240,1903.790039 
z"
        />
        <path
          fill="#7A7E89"
          opacity="1.000000"
          stroke="none"
          d="
M774.612427,1904.660156 
	C774.786255,1903.042969 775.463501,1901.900879 776.507080,1900.202148 
	C778.325378,1897.876221 779.797241,1896.127075 782.396240,1894.558228 
	C787.022278,1892.921021 790.813477,1893.567505 795.137085,1895.755737 
	C796.586609,1896.788208 797.221680,1897.564941 797.845093,1899.223511 
	C795.526794,1902.829224 792.156189,1901.748047 789.085876,1901.991333 
	C786.646729,1902.184692 783.866089,1901.433594 782.246582,1905.119873 
	C779.724670,1905.864258 777.334473,1906.339600 774.612427,1904.660156 
z"
        />
        <path
          fill="#6B6164"
          opacity="1.000000"
          stroke="none"
          d="
M602.916626,1901.875977 
	C602.095581,1900.322510 602.052856,1898.788696 601.941162,1896.488281 
	C599.540588,1891.490234 602.789551,1889.148071 605.714111,1886.151123 
	C609.009949,1886.431030 609.993286,1888.181030 609.979248,1890.694458 
	C609.961487,1893.863403 609.979492,1897.032715 609.960327,1901.045410 
	C607.857361,1901.891235 605.776123,1901.893433 602.916626,1901.875977 
z"
        />
        <path
          fill="#63707D"
          opacity="1.000000"
          stroke="none"
          d="
M182.113251,1897.165039 
	C190.002884,1896.373535 198.187149,1896.293457 207.300980,1896.429932 
	C207.672409,1897.461914 207.180634,1898.928589 206.546082,1898.993286 
	C198.457565,1899.817261 190.272079,1901.960571 182.113251,1897.165039 
z"
        />
        <path
          fill="#63707D"
          opacity="1.000000"
          stroke="none"
          d="
M216.101624,1897.308105 
	C224.091476,1895.776367 232.402924,1895.546143 241.365005,1897.178589 
	C235.059021,1900.756104 224.361710,1900.896240 216.101624,1897.308105 
z"
        />
        <path
          fill="#63707D"
          opacity="1.000000"
          stroke="none"
          d="
M144.092407,1895.178711 
	C150.170166,1893.942749 156.537247,1893.397339 163.399872,1895.635132 
	C157.384811,1898.724731 150.844086,1899.419800 144.092407,1895.178711 
z"
        />
        <path
          fill="#748B9F"
          opacity="1.000000"
          stroke="none"
          d="
M930.475830,1913.802490 
	C927.656006,1912.810913 925.468811,1911.525757 922.662109,1910.097656 
	C924.313965,1906.722168 928.396606,1908.999390 931.542053,1906.462891 
	C932.906067,1905.960449 933.797180,1905.982666 935.345642,1906.132568 
	C936.488525,1910.106201 934.273071,1912.128052 930.475830,1913.802490 
z"
        />
        <path
          fill="#63707D"
          opacity="1.000000"
          stroke="none"
          d="
M170.489258,1896.715576 
	C172.092453,1893.616089 174.543304,1893.865845 177.544662,1895.816406 
	C175.810745,1898.459351 173.433838,1898.353271 170.489258,1896.715576 
z"
        />
        <path
          fill="#6A8297"
          opacity="1.000000"
          stroke="none"
          d="
M800.668945,2145.006348 
	C798.932617,2143.778809 796.974365,2142.651611 797.735168,2141.136230 
	C798.612793,2139.388184 800.648499,2140.236816 801.809998,2141.324219 
	C803.267212,2142.687988 804.507629,2144.416992 800.668945,2145.006348 
z"
        />
        <path
          fill="#A9AEB3"
          opacity="1.000000"
          stroke="none"
          d="
M497.876312,1905.356689 
	C499.342743,1904.716064 501.042603,1904.609619 503.562195,1904.765625 
	C502.656067,1907.967407 500.645508,1908.838013 497.876312,1905.356689 
z"
        />
        <path
          fill="#A9AEB3"
          opacity="1.000000"
          stroke="none"
          d="
M507.855835,1905.516846 
	C508.765991,1904.952637 509.911163,1904.851685 511.537781,1905.095459 
	C510.967651,1907.496460 509.671051,1907.769897 507.855835,1905.516846 
z"
        />
        <path
          fill="#8DA0AE"
          opacity="1.000000"
          stroke="none"
          d="
M49.699081,1911.633057 
	C49.084576,1913.375854 48.017563,1913.948486 46.283188,1912.380859 
	C46.898792,1910.644653 47.966557,1910.067993 49.699081,1911.633057 
z"
        />
        <path
          fill="#E6E1D0"
          opacity="1.000000"
          stroke="none"
          d="
M1700.447510,3919.538086 
	C1698.502441,3920.004883 1697.004883,3919.999756 1694.758545,3919.992432 
	C1693.098267,3919.029297 1692.108521,3918.316650 1690.353027,3919.474121 
	C1682.460327,3924.913574 1682.391968,3925.002686 1675.810303,3919.950928 
	C1672.900146,3917.717285 1671.114136,3917.928223 1670.076904,3921.557373 
	C1668.433350,3927.306885 1662.930908,3929.060303 1659.366577,3926.898193 
	C1654.232788,3923.784668 1651.112183,3926.308105 1648.092041,3928.593506 
	C1643.963379,3931.717285 1640.413818,3934.422607 1634.796021,3932.303223 
	C1632.152710,3931.305908 1629.344238,3932.895996 1628.456909,3935.754883 
	C1627.145996,3939.979248 1623.940796,3939.585449 1620.614258,3940.115723 
	C1613.085205,3941.315674 1604.558228,3940.259521 1599.755249,3948.588623 
	C1598.846680,3950.164307 1596.738281,3949.225830 1594.596924,3948.254883 
	C1587.816650,3939.917969 1588.162842,3933.204590 1596.561646,3928.544922 
	C1602.723022,3925.126709 1605.388062,3921.770264 1602.826050,3914.947021 
	C1602.284058,3913.503906 1603.454468,3911.818604 1604.906982,3909.937012 
	C1606.271606,3908.950439 1607.184570,3908.674561 1608.870361,3908.694824 
	C1614.347656,3910.440674 1614.363525,3917.733398 1620.407837,3917.936035 
	C1616.957397,3908.396484 1620.543945,3903.648682 1630.691040,3904.850098 
	C1637.253174,3905.627197 1643.738281,3906.399414 1650.998901,3905.130371 
	C1652.591187,3905.465576 1653.393433,3905.913086 1654.507324,3907.071777 
	C1655.687134,3908.370850 1654.981445,3909.739746 1656.057617,3910.378662 
	C1656.066040,3909.465332 1656.195068,3908.568604 1656.750122,3907.078613 
	C1664.979736,3903.158203 1674.096191,3905.127197 1682.450195,3901.022705 
	C1685.814453,3902.677734 1685.527100,3906.712891 1688.819824,3907.774902 
	C1689.683960,3908.650146 1689.403076,3907.076660 1689.087646,3907.045166 
	C1686.449219,3906.781738 1685.995972,3904.014893 1683.952393,3901.944824 
	C1680.630859,3897.050293 1680.738647,3892.429932 1685.976196,3890.952393 
	C1691.503418,3889.393311 1692.927124,3885.765137 1694.654419,3881.305908 
	C1696.751709,3875.891846 1699.161621,3870.373047 1706.991089,3874.717773 
	C1710.297363,3876.552490 1711.992920,3874.150879 1712.078613,3870.968262 
	C1712.194580,3866.662354 1713.247925,3862.230225 1711.796997,3858.059814 
	C1709.623047,3851.810547 1711.579956,3847.610352 1717.514038,3845.276367 
	C1722.417603,3843.347656 1723.549561,3840.229736 1722.761230,3835.081299 
	C1721.646973,3827.803711 1721.742188,3820.488281 1727.329224,3814.414307 
	C1730.935059,3810.494141 1727.598999,3807.677490 1725.052856,3805.124512 
	C1720.060913,3800.120117 1719.562134,3795.510498 1725.645508,3791.022705 
	C1727.881592,3789.373535 1728.766479,3786.765381 1729.935547,3784.380859 
	C1731.830811,3780.516113 1735.006714,3779.248047 1739.046143,3780.149414 
	C1743.011719,3781.033936 1743.726318,3783.961670 1744.126709,3787.642334 
	C1744.724854,3793.141113 1743.066040,3798.703613 1744.367188,3804.590820 
	C1748.395752,3802.941895 1747.465576,3800.272461 1746.827026,3797.809814 
	C1743.336182,3784.347656 1744.692505,3782.639648 1759.058838,3782.610352 
	C1759.764893,3782.608887 1760.390503,3782.575195 1760.935303,3782.267578 
	C1749.899536,3776.593262 1749.899536,3776.593262 1761.619629,3759.844482 
	C1756.088623,3757.489746 1749.750244,3756.187988 1748.846191,3748.408203 
	C1748.118652,3742.146484 1749.213867,3737.989746 1755.823608,3736.305908 
	C1758.775391,3735.553955 1764.491821,3735.605469 1762.608154,3731.364014 
	C1760.400757,3726.392822 1771.660400,3719.748779 1759.990356,3716.384521 
	C1755.648193,3715.133057 1755.753662,3710.360840 1760.221802,3708.088135 
	C1771.815063,3702.191406 1771.356323,3691.764160 1773.905640,3680.802734 
	C1776.755615,3668.548096 1775.994385,3655.211914 1782.522827,3642.879639 
	C1777.066528,3644.054688 1772.121338,3643.099365 1767.573364,3638.790527 
	C1765.776978,3635.205811 1766.588135,3632.344482 1768.052979,3628.857422 
	C1775.811157,3622.680908 1783.452637,3628.718994 1790.426880,3627.495605 
	C1776.328613,3620.400635 1774.848999,3612.607910 1785.362549,3595.197266 
	C1786.936035,3594.164062 1788.078491,3593.724609 1789.750000,3593.202881 
	C1790.243164,3593.035889 1790.997437,3592.496826 1790.997437,3592.496826 
	C1790.997437,3592.496826 1790.691895,3591.586182 1790.414307,3591.038574 
	C1790.000854,3590.077881 1789.864868,3589.664795 1789.611084,3588.582275 
	C1790.885132,3570.271973 1797.158081,3554.607422 1807.401489,3540.143799 
	C1812.083740,3533.532959 1816.856934,3526.565918 1818.620239,3518.323242 
	C1819.395874,3514.697998 1821.390625,3512.053223 1824.630005,3510.914795 
	C1833.643555,3507.746582 1836.513428,3500.576172 1836.750366,3492.157471 
	C1836.984131,3483.849854 1840.532227,3476.667969 1843.370605,3469.273926 
	C1846.337036,3461.547119 1850.012451,3461.048584 1854.916260,3467.730957 
	C1858.026245,3471.968750 1862.464111,3472.781250 1867.317139,3474.869873 
	C1868.875977,3476.745605 1868.534912,3478.126953 1867.352417,3480.051270 
	C1865.237915,3482.150635 1862.818726,3482.207764 1860.819702,3483.728760 
	C1861.603638,3486.186035 1864.444580,3485.548340 1866.343628,3487.437012 
	C1867.842285,3489.704590 1867.765869,3491.525391 1866.705322,3493.971436 
	C1864.604370,3496.499023 1862.507446,3498.284668 1863.620117,3502.037354 
	C1863.640259,3503.606689 1863.491089,3504.484863 1862.882935,3505.917480 
	C1859.972290,3508.496826 1856.453369,3510.150391 1859.899170,3514.981934 
	C1861.064941,3528.145020 1857.477539,3539.698975 1852.437012,3551.714844 
	C1845.938232,3563.851074 1846.805786,3576.677490 1844.948608,3588.957275 
	C1844.405151,3592.551514 1844.807373,3596.291504 1842.760986,3600.363525 
	C1842.215698,3601.482422 1841.967529,3601.910645 1841.276489,3602.921387 
	C1835.133179,3607.497559 1838.321533,3614.027100 1836.507690,3619.239258 
	C1832.913208,3629.568359 1827.015869,3639.503174 1825.796753,3650.138184 
	C1824.179565,3664.245605 1817.489136,3675.727539 1811.498535,3687.766113 
	C1809.895630,3690.987305 1809.658936,3693.475342 1811.134521,3697.029785 
	C1814.178955,3704.362793 1813.961548,3710.772949 1805.408813,3715.523926 
	C1797.847534,3719.724365 1794.876221,3728.390869 1792.228027,3736.065918 
	C1789.672852,3743.470703 1787.168091,3751.064209 1786.851196,3759.288574 
	C1786.602417,3765.743164 1785.310669,3772.622803 1779.619995,3777.309570 
	C1776.495117,3779.883545 1776.410400,3783.564697 1775.715942,3787.208252 
	C1774.294434,3794.665771 1773.198242,3802.505859 1766.335938,3807.424316 
	C1763.617554,3809.373047 1764.476318,3812.076660 1763.916382,3814.575928 
	C1761.513550,3825.299805 1765.237061,3837.401611 1756.470459,3846.773193 
	C1754.957153,3848.390869 1755.545898,3851.482666 1757.125244,3853.339111 
	C1758.728271,3855.224365 1760.866455,3853.895020 1762.590576,3853.101074 
	C1766.481323,3851.309082 1770.268555,3851.598389 1774.179565,3853.257812 
	C1775.095825,3854.055664 1774.487305,3851.637695 1774.116455,3852.918457 
	C1773.984741,3853.373291 1773.811279,3853.052734 1773.213623,3852.772461 
	C1769.643677,3851.100098 1769.498535,3848.021973 1770.695923,3845.172119 
	C1775.937622,3832.695068 1779.835449,3819.495605 1789.240234,3809.156250 
	C1789.913208,3808.416504 1791.078613,3807.660645 1791.070312,3806.845459 
	C1790.866699,3786.756348 1813.357422,3778.161377 1815.189697,3758.401855 
	C1815.899902,3750.743408 1825.643066,3750.960693 1833.419434,3753.040527 
	C1837.135742,3757.725098 1833.774170,3761.762939 1833.546143,3765.910645 
	C1833.452026,3767.620117 1833.413208,3769.383545 1835.479370,3769.889893 
	C1837.006226,3770.264160 1837.742188,3769.076660 1838.484863,3767.877441 
	C1843.030884,3760.537598 1853.259521,3757.662842 1854.330322,3747.446777 
	C1854.522827,3745.609619 1856.935913,3744.671631 1859.038208,3744.264648 
	C1872.525269,3741.652588 1878.316406,3733.475586 1874.946777,3720.191895 
	C1872.869385,3712.002686 1873.577148,3706.436035 1882.856323,3704.421875 
	C1884.885498,3703.981201 1886.568848,3702.520508 1886.581909,3700.263184 
	C1886.601807,3696.819580 1883.610718,3697.370117 1881.489258,3696.878418 
	C1878.086304,3696.090088 1873.022217,3696.650635 1873.483765,3691.539307 
	C1873.885620,3687.089600 1878.867920,3687.446533 1882.256348,3686.431641 
	C1886.091797,3685.282959 1890.189453,3686.701660 1894.752563,3685.101562 
	C1890.486694,3682.973389 1884.474609,3686.116699 1882.756104,3680.275146 
	C1881.284180,3675.271240 1884.000244,3671.232422 1886.725098,3667.411133 
	C1889.407593,3663.648926 1892.952637,3663.095215 1897.315674,3664.491699 
	C1905.893799,3667.237549 1906.494263,3666.437988 1904.271973,3656.041260 
	C1899.863525,3658.077148 1897.977173,3663.640137 1891.942993,3663.633789 
	C1886.652954,3663.628418 1884.227539,3662.257080 1885.093018,3656.907715 
	C1885.925049,3651.766357 1885.365967,3646.003662 1891.421387,3642.370605 
	C1896.187622,3637.038330 1903.772827,3637.102783 1907.630615,3630.884766 
	C1908.033447,3628.037842 1908.573242,3625.884033 1908.831787,3623.002441 
	C1908.762573,3619.873047 1907.110474,3618.036377 1906.034546,3615.155273 
	C1904.976196,3611.340820 1904.933716,3608.218506 1905.725098,3604.305664 
	C1910.074463,3597.643311 1914.461060,3589.812500 1922.150391,3591.405273 
	C1929.229736,3592.871582 1929.055664,3602.013672 1930.170776,3608.149170 
	C1931.696899,3616.545654 1932.628052,3625.039795 1934.928223,3633.312500 
	C1935.831055,3636.559570 1936.431396,3639.771240 1940.689453,3641.587891 
	C1942.258057,3642.561523 1943.025024,3643.270508 1944.042114,3644.791260 
	C1944.517578,3645.973877 1944.622803,3646.474121 1944.567749,3647.716797 
	C1940.187500,3654.351074 1933.731689,3656.617676 1926.623047,3659.019043 
	C1919.469849,3655.166992 1922.272095,3649.264160 1922.783569,3643.984375 
	C1923.032471,3641.415527 1924.686157,3638.806396 1921.302002,3637.118896 
	C1920.676514,3636.806641 1919.887207,3636.615479 1919.387329,3637.041992 
	C1916.546021,3639.466064 1918.635254,3642.129150 1919.672974,3644.166260 
	C1922.251099,3649.227051 1918.572754,3656.653320 1926.357178,3660.021484 
	C1935.145874,3663.113037 1938.817383,3670.559570 1942.741211,3677.124023 
	C1946.602783,3683.584473 1941.944824,3689.071777 1934.325562,3687.980713 
	C1932.522217,3687.722656 1931.133057,3688.003906 1929.038330,3689.101562 
	C1922.227417,3690.322754 1922.774414,3684.362305 1920.097290,3679.699219 
	C1920.091187,3691.004883 1921.816162,3700.785156 1915.630615,3709.289795 
	C1914.583374,3710.729492 1915.137329,3712.226074 1916.178345,3713.792725 
	C1923.620239,3724.989014 1922.229370,3729.171143 1910.086670,3732.676514 
	C1927.678589,3740.895996 1928.029053,3741.709473 1914.067505,3755.958252 
	C1910.351074,3759.750977 1911.510742,3762.853027 1912.273682,3767.318848 
	C1912.462158,3776.243652 1911.463501,3782.995117 1900.438232,3782.996094 
	C1896.040039,3782.996582 1895.319458,3789.482666 1891.598511,3791.826172 
	C1885.119507,3795.907715 1891.250854,3798.802002 1892.687012,3802.193604 
	C1894.288208,3805.975586 1893.194702,3809.245850 1889.001709,3809.521484 
	C1883.659058,3809.872803 1882.651001,3812.600342 1882.324585,3817.173828 
	C1881.767822,3824.974365 1873.624756,3825.366943 1868.666992,3829.784668 
	C1861.267700,3830.688721 1859.217407,3837.457275 1854.429443,3840.783447 
	C1852.109009,3842.395508 1850.799927,3844.196777 1847.885010,3842.958252 
	C1845.947266,3842.134521 1844.028809,3842.207764 1842.075195,3842.967041 
	C1838.173218,3844.483643 1835.137329,3844.358887 1833.421631,3839.563965 
	C1832.244141,3836.273926 1828.970459,3835.047852 1826.028931,3836.728271 
	C1821.758911,3839.167236 1827.036499,3841.684814 1826.565063,3845.001465 
	C1817.786621,3848.337402 1807.777344,3842.021729 1798.868286,3847.661865 
	C1799.356323,3850.453369 1799.585693,3853.447266 1800.449951,3856.245117 
	C1801.361938,3859.196777 1801.455078,3861.061768 1797.984009,3862.426270 
	C1793.705811,3864.107910 1793.404175,3867.346924 1795.355591,3871.394043 
	C1798.196289,3877.285889 1797.540771,3879.548096 1792.529785,3882.614502 
	C1789.707886,3884.341309 1786.685181,3886.396973 1783.276611,3885.857178 
	C1777.231079,3884.899658 1771.626099,3888.540771 1765.582031,3887.084961 
	C1762.872681,3886.432373 1762.174561,3888.432373 1762.085083,3890.603027 
	C1761.962036,3893.592285 1762.118408,3896.599121 1761.883911,3899.576660 
	C1761.624390,3902.872070 1760.145020,3904.719727 1756.699951,3902.591064 
	C1755.327026,3901.742188 1753.998169,3900.060303 1752.685669,3902.130615 
	C1751.706299,3903.675293 1752.595947,3905.450195 1753.974854,3906.521240 
	C1755.242554,3907.505859 1756.790771,3908.129639 1758.728271,3909.194336 
	C1757.032593,3910.272705 1755.621826,3911.080322 1754.306519,3912.021240 
	C1746.530884,3917.583008 1746.117676,3917.551758 1739.780273,3910.879883 
	C1738.636841,3909.675781 1737.508545,3908.414307 1736.184692,3907.434082 
	C1733.443115,3905.403320 1731.050171,3903.730713 1736.051758,3901.367432 
	C1738.375854,3900.269287 1737.948975,3898.063721 1736.248657,3896.456299 
	C1732.942505,3893.329834 1723.168945,3895.133057 1721.201782,3899.215332 
	C1720.565796,3900.535400 1721.092041,3901.758057 1722.192993,3902.721924 
	C1723.917847,3904.231689 1726.980347,3904.864258 1727.233276,3907.260742 
	C1727.678711,3911.482422 1733.873169,3911.404541 1733.273804,3915.464111 
	C1732.786499,3918.764648 1727.895508,3918.815430 1726.683960,3922.346436 
	C1725.465576,3925.898193 1722.673462,3926.377441 1719.416138,3924.672607 
	C1713.603027,3921.630127 1706.882324,3921.553711 1700.447510,3919.538086 
z"
        />
        <path
          fill="#F6EEDE"
          opacity="1.000000"
          stroke="none"
          d="
M1867.995117,3830.760010 
	C1868.288696,3826.294434 1870.314209,3823.195801 1873.674194,3822.678467 
	C1880.218140,3821.670654 1880.378540,3817.696289 1879.812378,3812.658203 
	C1879.073364,3806.083984 1879.184448,3806.066406 1886.140991,3807.044189 
	C1887.107178,3807.179688 1888.112183,3807.673584 1889.013306,3807.514648 
	C1891.591553,3807.061035 1892.376343,3805.213623 1890.867920,3803.280273 
	C1889.490112,3801.515137 1887.324463,3800.381104 1885.583496,3798.878906 
	C1882.097534,3795.870117 1881.962891,3793.568115 1886.027832,3791.504883 
	C1890.285278,3789.343994 1893.968506,3787.598877 1894.757446,3782.041016 
	C1895.112671,3779.538574 1898.268799,3778.623047 1901.242676,3778.777588 
	C1908.261963,3779.142090 1908.228027,3779.031738 1908.378174,3772.050293 
	C1908.398071,3771.128418 1909.067139,3770.220215 1909.732422,3768.667480 
	C1914.597534,3764.282471 1919.722656,3763.587646 1925.852295,3765.771484 
	C1926.623413,3766.760986 1926.819702,3767.225586 1927.022095,3768.430664 
	C1921.276001,3775.683594 1915.469360,3781.800537 1921.283691,3791.730713 
	C1923.566528,3795.629395 1918.448853,3798.609131 1916.318970,3802.458984 
	C1916.293213,3804.314209 1916.314697,3805.550537 1916.078125,3807.390625 
	C1914.335815,3810.136475 1911.940796,3809.905518 1909.621582,3810.030518 
	C1907.714478,3810.132812 1905.177979,3809.777832 1905.049927,3812.526855 
	C1904.951294,3814.647949 1906.950806,3815.852783 1908.559326,3817.041748 
	C1910.967407,3818.821289 1914.618286,3816.805176 1917.284302,3820.449707 
	C1907.807495,3821.778809 1905.907837,3829.601807 1902.804199,3836.428223 
	C1901.699219,3838.858887 1901.704346,3843.286133 1897.144653,3839.376221 
	C1896.596802,3838.906494 1890.141357,3847.710693 1890.045654,3849.179932 
	C1889.804565,3852.882324 1890.593262,3855.569824 1895.050537,3856.253906 
	C1897.790527,3856.674316 1900.847778,3857.225342 1903.201172,3860.309570 
	C1900.450562,3862.656250 1897.565918,3862.172363 1894.863892,3861.253418 
	C1889.448608,3859.411621 1884.751221,3860.591064 1882.022217,3865.401611 
	C1880.295410,3868.445312 1876.072388,3866.785889 1874.957153,3870.651123 
	C1878.911133,3874.006592 1884.645874,3876.270264 1885.091064,3884.330811 
	C1874.132080,3879.816895 1863.112549,3883.644287 1852.398315,3880.624023 
	C1846.281616,3878.899902 1839.876831,3879.454590 1834.042480,3882.842529 
	C1832.293457,3883.858398 1829.973389,3884.410156 1828.847290,3882.121338 
	C1827.674316,3879.737549 1829.976318,3879.104248 1831.496216,3877.780762 
	C1837.433228,3872.610352 1844.205444,3871.288086 1851.788086,3871.909912 
	C1854.548706,3872.136475 1858.361572,3873.023193 1859.431763,3869.147461 
	C1860.369995,3865.749023 1861.274902,3861.825195 1856.546387,3859.833496 
	C1854.701660,3859.056152 1854.152344,3857.319092 1855.534790,3855.832275 
	C1857.335449,3853.895752 1856.167847,3851.374268 1857.232666,3849.403564 
	C1859.128174,3848.535400 1859.531006,3849.929932 1859.999146,3851.161133 
	C1861.164795,3854.226074 1863.321167,3857.366455 1866.391479,3854.763672 
	C1868.849976,3852.679932 1870.718872,3846.746338 1868.193726,3845.185547 
	C1862.803955,3841.853516 1866.101440,3839.528320 1867.399170,3836.428711 
	C1868.010498,3834.968506 1867.821289,3833.173096 1867.995117,3830.760010 
z"
        />
        <path
          fill="#E6E1D0"
          opacity="1.000000"
          stroke="none"
          d="
M1916.764404,3808.001465 
	C1915.364990,3807.059814 1914.729980,3806.119629 1914.033813,3804.591797 
	C1914.210815,3799.041016 1918.725708,3796.908203 1921.358276,3798.662109 
	C1927.463745,3802.731445 1933.948975,3800.766602 1939.597778,3801.805664 
	C1938.054565,3801.736328 1936.041626,3801.088379 1933.875366,3801.178955 
	C1924.246094,3801.581055 1923.255981,3800.498047 1926.406128,3791.420654 
	C1927.836914,3787.297119 1927.892334,3783.960449 1925.397339,3780.314453 
	C1923.455933,3777.477295 1922.972290,3774.210693 1925.546143,3770.537598 
	C1926.027222,3769.695068 1926.036133,3769.360596 1926.054688,3768.524902 
	C1928.103516,3764.881592 1929.256714,3761.160645 1928.449463,3757.752686 
	C1926.668457,3750.234131 1935.489014,3746.650879 1934.631592,3739.530273 
	C1934.518799,3738.593018 1937.411499,3738.487793 1939.530029,3739.550537 
	C1940.403076,3742.193115 1941.461060,3743.752686 1943.831909,3743.854736 
	C1948.121582,3744.039551 1952.319458,3743.966064 1956.611206,3742.899902 
	C1965.820801,3740.611572 1967.414429,3741.804443 1969.347412,3751.101807 
	C1969.744873,3753.013428 1970.218994,3755.181396 1969.705933,3756.956055 
	C1968.436279,3761.348145 1967.328003,3765.318604 1973.004517,3767.487793 
	C1973.387329,3767.634033 1973.514648,3769.384766 1973.213257,3770.182373 
	C1972.662842,3771.639648 1971.221924,3771.824219 1969.891357,3771.392334 
	C1968.349243,3770.892578 1966.577393,3768.239502 1965.762207,3772.031494 
	C1965.321045,3774.083008 1963.291260,3776.504639 1966.926025,3777.742432 
	C1968.665894,3778.334473 1971.399414,3778.118896 1970.820801,3781.000000 
	C1970.380371,3783.193115 1968.165283,3783.731934 1966.129028,3783.997314 
	C1964.392578,3784.223877 1962.958008,3784.847656 1962.148438,3787.311523 
	C1961.619751,3788.269287 1961.239624,3788.538330 1960.313110,3788.379883 
	C1958.888062,3787.548584 1958.061890,3787.058105 1957.305298,3786.454346 
	C1956.136475,3785.521973 1955.037231,3783.811035 1953.419556,3785.664307 
	C1952.557861,3786.651611 1951.982300,3788.424316 1953.175293,3789.090576 
	C1954.624634,3789.900879 1956.041992,3788.172607 1958.152588,3787.650879 
	C1959.961182,3787.545898 1960.188110,3789.374512 1961.780029,3788.514893 
	C1966.565430,3788.406494 1967.817993,3791.291260 1968.035278,3795.427002 
	C1968.454224,3803.400146 1966.601562,3810.981689 1964.301025,3819.290527 
	C1955.481689,3817.330811 1946.967896,3816.822266 1938.348511,3819.520996 
	C1934.712402,3820.659668 1931.262207,3819.499268 1927.825439,3818.239502 
	C1925.729980,3817.471680 1924.550537,3816.589844 1924.970459,3814.026855 
	C1925.977295,3807.882812 1921.514648,3808.165039 1916.764404,3808.001465 
z"
        />
        <path
          fill="#F0EBDD"
          opacity="1.000000"
          stroke="none"
          d="
M1871.739014,3898.183594 
	C1870.550903,3902.442383 1867.730347,3902.315186 1865.225586,3901.366211 
	C1857.263672,3898.349365 1848.713745,3901.590332 1840.731812,3898.845459 
	C1839.764893,3898.512695 1838.158447,3899.285400 1837.142822,3899.977295 
	C1832.577271,3903.090332 1830.378052,3899.979248 1828.708374,3896.608887 
	C1826.956787,3893.073242 1828.912231,3889.929932 1833.470459,3888.553223 
	C1842.550049,3885.810547 1851.870728,3887.269775 1860.770020,3888.720459 
	C1864.657471,3889.354248 1869.178467,3893.112549 1871.739014,3898.183594 
z"
        />
        <path
          fill="#E6E1D0"
          opacity="1.000000"
          stroke="none"
          d="
M1781.712402,3902.151367 
	C1779.166138,3897.385742 1775.721313,3897.777100 1771.991821,3898.918945 
	C1768.781128,3899.901855 1766.645874,3898.573486 1766.594727,3895.268311 
	C1766.544678,3892.017578 1769.363037,3892.115234 1771.657959,3892.047119 
	C1774.625732,3891.958984 1777.821533,3892.724854 1780.527100,3891.875244 
	C1789.505981,3889.053711 1791.694458,3897.349854 1796.585938,3901.162842 
	C1797.323364,3901.737549 1797.245850,3903.028564 1796.527954,3903.828125 
	C1793.835815,3906.826416 1789.852295,3906.279297 1786.454468,3907.125732 
	C1783.695190,3907.812744 1782.663330,3905.153809 1781.712402,3902.151367 
z"
        />
        <path
          fill="#E6E1D0"
          opacity="1.000000"
          stroke="none"
          d="
M1677.114502,4009.248047 
	C1676.264526,4014.325684 1679.181152,4013.944580 1681.947388,4013.667725 
	C1684.019043,4013.460449 1686.645752,4011.270020 1687.618896,4014.995850 
	C1688.387207,4017.937256 1687.053101,4020.077148 1684.387207,4021.549072 
	C1678.177368,4024.976807 1673.238892,4024.141846 1668.429688,4018.688232 
	C1667.995972,4018.196289 1667.615601,4017.604004 1667.389404,4016.992432 
	C1666.453247,4014.457764 1661.223022,4014.031494 1663.627319,4009.983887 
	C1665.893677,4006.167969 1672.146606,4005.676514 1677.114502,4009.248047 
z"
        />
        <path
          fill="#F0EBDD"
          opacity="1.000000"
          stroke="none"
          d="
M1778.602783,3973.924316 
	C1777.666870,3971.704346 1777.068604,3970.166748 1776.553223,3968.601562 
	C1776.108032,3967.249756 1776.461304,3965.136719 1777.475098,3965.201660 
	C1786.679932,3965.789795 1794.338623,3957.520996 1803.906494,3960.694092 
	C1804.162964,3960.779053 1804.330444,3961.132568 1805.062378,3961.932373 
	C1803.612183,3964.644531 1801.474609,3965.852539 1798.178589,3965.064209 
	C1795.013550,3964.307373 1792.995850,3965.752441 1794.938354,3968.506348 
	C1797.926270,3972.742676 1795.083008,3973.677002 1792.291870,3974.715088 
	C1787.895874,3976.349854 1783.390869,3977.031738 1778.602783,3973.924316 
z"
        />
        <path
          fill="#E6E1D0"
          opacity="1.000000"
          stroke="none"
          d="
M1738.794922,3931.201172 
	C1735.994995,3928.095459 1734.952148,3923.881348 1739.428345,3924.777832 
	C1745.975098,3926.089600 1752.165771,3925.381592 1758.873535,3924.437988 
	C1752.662842,3936.939209 1752.209717,3937.093018 1738.794922,3931.201172 
z"
        />
        <path
          fill="#F0EBDD"
          opacity="1.000000"
          stroke="none"
          d="
M1846.706055,3910.272705 
	C1850.922607,3906.781006 1855.789673,3908.042480 1860.152832,3906.612793 
	C1863.644653,3905.468750 1866.682617,3908.255859 1867.030151,3911.839355 
	C1867.352905,3915.168945 1864.213013,3915.835205 1861.477661,3915.919434 
	C1858.205933,3916.020508 1854.918335,3916.036133 1851.654175,3915.827393 
	C1848.756714,3915.642578 1846.715210,3914.209717 1846.706055,3910.272705 
z"
        />
        <path
          fill="#F0EBDD"
          opacity="1.000000"
          stroke="none"
          d="
M1936.024048,3893.024414 
	C1931.153687,3887.212402 1931.480835,3884.720703 1936.816895,3882.801514 
	C1940.342163,3881.533691 1944.088989,3881.530273 1947.603027,3882.525635 
	C1949.919434,3883.181396 1951.381592,3885.375977 1950.882935,3887.969971 
	C1950.488525,3890.022217 1948.452881,3889.898926 1946.848633,3889.943848 
	C1943.202271,3890.046143 1939.457642,3889.707275 1936.024048,3893.024414 
z"
        />
        <path
          fill="#E6E1D0"
          opacity="1.000000"
          stroke="none"
          d="
M1694.291992,4010.937988 
	C1698.631226,4007.405518 1700.035034,4010.515625 1700.941895,4013.639160 
	C1701.358521,4015.073242 1699.635986,4015.707764 1698.320068,4015.762451 
	C1695.523315,4015.878418 1694.166870,4014.388916 1694.291992,4010.937988 
z"
        />
        <path
          fill="#E6E1D0"
          opacity="1.000000"
          stroke="none"
          d="
M1641.374023,4006.156006 
	C1643.727783,4006.360352 1646.158203,4005.283203 1646.660400,4008.078857 
	C1646.845459,4009.107666 1645.659790,4009.878174 1644.748657,4009.699463 
	C1642.861206,4009.329102 1638.805054,4011.143311 1641.374023,4006.156006 
z"
        />
        <path
          fill="#020914"
          opacity="1.000000"
          stroke="none"
          d="
M163.892548,3568.778320 
	C164.957962,3568.206787 166.008850,3568.225098 166.856812,3568.649414 
	C174.240921,3572.343018 174.420410,3572.552002 179.984711,3566.895264 
	C184.360184,3562.446533 189.272369,3560.044678 195.527283,3559.611572 
	C199.365082,3559.345947 203.124802,3557.952148 207.459442,3556.527588 
	C209.896133,3553.916504 212.132919,3553.974365 215.244385,3555.460938 
	C226.230774,3553.713623 236.245209,3552.176025 247.128433,3552.739502 
	C257.596619,3553.281494 267.854401,3553.888184 278.176697,3555.759766 
	C281.847351,3556.425049 285.934387,3554.566650 289.861664,3554.137939 
	C291.908478,3553.914062 293.915619,3554.713135 294.850189,3556.735107 
	C295.918854,3559.046875 294.447815,3560.730469 292.606140,3561.551270 
	C288.679901,3563.302002 288.169830,3567.083008 286.759094,3570.370605 
	C284.594574,3575.414551 281.167572,3578.375000 275.268250,3578.040039 
	C267.631287,3577.606201 260.318207,3580.847412 252.574326,3580.125000 
	C248.033844,3579.701172 245.056793,3583.052734 243.114059,3586.458252 
	C240.433640,3591.156738 236.085678,3592.838379 231.795639,3595.414062 
	C219.116333,3603.025391 204.984833,3604.034912 191.009109,3605.988525 
	C185.183838,3606.802490 180.323730,3609.375732 175.960129,3613.089355 
	C173.707642,3615.005859 171.828888,3617.186768 168.644913,3617.963379 
	C158.333832,3620.478516 154.273468,3625.335693 153.101608,3635.482910 
	C152.228561,3643.043213 151.759750,3650.609131 152.014160,3659.119141 
	C152.365356,3664.195557 149.827896,3665.296387 145.543793,3664.875244 
	C144.908066,3659.553711 146.613525,3654.840820 145.743317,3649.044434 
	C145.844223,3647.096436 145.948227,3646.039551 145.830750,3644.440430 
	C145.101868,3640.953613 147.733444,3639.782471 149.352600,3637.400146 
	C149.294128,3635.581543 148.396118,3634.488770 147.804184,3632.514160 
	C151.850952,3623.252686 155.026398,3614.366455 155.261002,3603.944824 
	C158.087189,3592.005371 160.935547,3580.781006 163.892548,3568.778320 
z"
        />
        <path
          fill="#091825"
          opacity="1.000000"
          stroke="none"
          d="
M144.513763,3665.054199 
	C147.252487,3664.005371 149.716599,3663.576172 151.656601,3660.651611 
	C155.682129,3660.000000 159.359222,3660.173828 163.005463,3659.913818 
	C170.158051,3659.405029 174.282684,3654.520508 177.181549,3648.774170 
	C179.084946,3645.000732 176.058929,3642.441650 173.012466,3640.895508 
	C170.698074,3639.721191 167.840881,3639.651611 165.449554,3638.586914 
	C159.135788,3635.775391 158.439407,3631.239014 163.011719,3625.964844 
	C166.086899,3622.417725 170.283981,3620.929688 174.445267,3620.454102 
	C179.983932,3619.820801 184.243713,3616.800781 189.095352,3614.903076 
	C196.847794,3611.870850 203.942810,3605.813965 213.269302,3607.822266 
	C215.655823,3608.336182 217.926331,3607.267822 219.879532,3606.004639 
	C225.783859,3602.185547 232.290237,3601.731689 239.056702,3601.951172 
	C245.022507,3602.145020 250.939209,3602.052979 256.861420,3600.415527 
	C260.018219,3599.542969 264.470001,3598.526367 265.791626,3603.812988 
	C267.159302,3609.283936 265.065338,3612.908203 259.496246,3613.894287 
	C252.366852,3615.157227 245.857864,3618.599121 238.251221,3618.569824 
	C233.037354,3618.549561 227.953156,3621.446533 225.020660,3626.415283 
	C223.244019,3629.425537 221.163605,3631.642822 217.620422,3632.552002 
	C215.594925,3633.071777 214.225632,3634.833984 214.544052,3637.103027 
	C214.900604,3639.643799 217.076385,3640.118408 219.084320,3639.904053 
	C226.610107,3639.100098 233.958069,3643.507324 241.656479,3640.262451 
	C244.804245,3638.935791 248.194031,3640.981689 251.273270,3642.135742 
	C256.552002,3644.114502 261.601013,3646.772949 267.540558,3646.113281 
	C268.793213,3645.974365 270.285126,3646.228027 270.796173,3647.777100 
	C271.441895,3649.734131 270.101288,3651.049561 268.803467,3651.926758 
	C264.877594,3654.581299 260.355682,3656.777832 255.727921,3655.707520 
	C247.790253,3653.871826 239.874741,3655.325439 232.136093,3654.769043 
	C220.919617,3653.963135 210.433701,3656.098633 199.804276,3658.122803 
	C191.614502,3659.682129 184.256866,3663.350830 177.823303,3668.985596 
	C173.459747,3672.807129 168.495529,3676.094238 163.968948,3679.567871 
	C158.731110,3683.587891 151.143356,3688.316650 142.212234,3684.038574 
	C143.105392,3677.820068 143.972733,3671.816895 144.513763,3665.054199 
z"
        />
        <path
          fill="#465869"
          opacity="1.000000"
          stroke="none"
          d="
M117.717018,3813.259766 
	C110.272774,3815.273438 104.361343,3819.641113 96.886688,3822.062988 
	C81.151672,3821.662842 81.151672,3821.662842 76.231445,3806.608154 
	C82.044235,3800.175049 88.175316,3794.419678 94.864563,3788.356445 
	C96.085518,3787.681885 96.701118,3787.248291 97.645790,3786.323975 
	C98.022354,3785.900635 98.000740,3786.017578 97.945457,3785.995605 
	C105.799950,3782.009521 112.570160,3774.854492 123.293900,3777.651855 
	C123.172905,3789.900391 121.582764,3801.461914 117.717018,3813.259766 
z"
        />
        <path
          fill="#434F51"
          opacity="1.000000"
          stroke="none"
          d="
M95.054779,3601.966797 
	C95.138031,3605.323975 94.344238,3608.601074 92.842323,3611.512939 
	C90.494087,3616.066162 87.246887,3619.789795 81.475426,3619.890625 
	C74.608421,3620.010498 68.428116,3624.323486 60.882271,3623.684814 
	C60.716095,3618.339844 63.856571,3614.622559 65.327301,3610.424805 
	C66.243713,3607.809082 68.654915,3606.473145 71.476578,3605.958984 
	C78.947205,3604.598389 86.400269,3603.141602 95.054779,3601.966797 
z"
        />
        <path
          fill="#3D494C"
          opacity="1.000000"
          stroke="none"
          d="
M23.555309,3641.420898 
	C21.060118,3642.047119 19.216980,3643.976562 17.372076,3642.395752 
	C15.887304,3641.123535 16.792513,3639.304688 17.390354,3637.769531 
	C20.193298,3630.572266 22.668968,3623.042969 32.816593,3623.979492 
	C35.462711,3624.223877 36.931759,3622.060547 38.539524,3620.420166 
	C41.362000,3617.540527 44.488586,3615.313965 50.026585,3614.645020 
	C46.957310,3619.549072 44.675869,3624.047119 53.107964,3627.508789 
	C41.613941,3631.517334 31.551493,3633.734619 23.555309,3641.420898 
z"
        />
        <path
          fill="#313E41"
          opacity="1.000000"
          stroke="none"
          d="
M121.468689,3617.569336 
	C120.339394,3618.878174 119.502518,3619.974854 118.768677,3619.909912 
	C111.164032,3619.235596 103.734268,3616.939941 95.644714,3618.154785 
	C100.669586,3611.362549 106.388329,3606.321289 112.636620,3601.918945 
	C116.077477,3599.494385 119.951141,3601.032715 122.900398,3602.784424 
	C125.368195,3604.249756 124.035652,3607.629639 123.603119,3610.151367 
	C123.215912,3612.409912 122.422943,3614.598633 121.468689,3617.569336 
z"
        />
        <path
          fill="#434F51"
          opacity="1.000000"
          stroke="none"
          d="
M84.064255,3774.004883 
	C86.879089,3771.626953 89.102539,3769.661377 91.335487,3767.706787 
	C94.141098,3765.250244 96.621002,3765.919434 99.162079,3768.292969 
	C102.087120,3771.024414 101.303406,3773.340088 98.903465,3775.871338 
	C93.450172,3781.623291 87.945351,3787.286377 81.267242,3791.685791 
	C79.279160,3792.995850 77.291260,3794.014160 74.896698,3794.064209 
	C71.129211,3794.143066 69.464371,3791.824463 70.962021,3788.258301 
	C72.941284,3783.545410 76.951004,3780.457764 80.492439,3777.020264 
	C81.437401,3776.102783 82.479591,3775.285645 84.064255,3774.004883 
z"
        />
        <path
          fill="#18202C"
          opacity="1.000000"
          stroke="none"
          d="
M123.934097,3777.327881 
	C117.219368,3777.828125 112.128365,3782.436768 106.020752,3783.868164 
	C103.777214,3784.394287 101.623581,3785.303955 98.659531,3786.006348 
	C106.247978,3775.568359 116.645813,3766.985107 125.089157,3755.764160 
	C127.001198,3762.555176 124.833534,3769.458008 123.934097,3777.327881 
z"
        />
        <path
          fill="#626669"
          opacity="1.000000"
          stroke="none"
          d="
M42.014751,3816.278564 
	C46.493847,3813.847412 52.855385,3813.655029 50.018841,3806.265625 
	C49.638878,3805.276123 51.535717,3803.505615 52.180443,3802.011475 
	C53.917229,3797.986572 57.192062,3795.933838 61.228054,3795.026123 
	C62.680763,3794.699463 63.739498,3795.864990 63.784649,3797.341064 
	C63.923267,3801.874268 65.310074,3806.849365 60.635704,3810.093994 
	C57.384769,3812.350586 53.905468,3814.284668 50.483749,3816.286865 
	C45.916786,3818.958984 41.289112,3821.527344 36.723991,3824.202637 
	C35.171631,3825.112305 33.530735,3825.778076 32.567463,3823.827881 
	C31.773714,3822.220947 32.502113,3820.352783 34.035324,3819.518311 
	C36.330219,3818.269531 38.866798,3817.464844 42.014751,3816.278564 
z"
        />
        <path
          fill="#3D494C"
          opacity="1.000000"
          stroke="none"
          d="
M137.098572,3621.128662 
	C139.269409,3623.379395 142.787979,3624.317139 140.755127,3627.793945 
	C139.118637,3630.593018 136.309204,3630.405029 133.682999,3629.460693 
	C131.830307,3628.794678 129.997498,3627.887939 128.391998,3626.754150 
	C123.820686,3623.525391 123.697006,3621.709961 127.802650,3617.357422 
	C130.757767,3614.223877 133.859512,3611.220459 137.017014,3608.289307 
	C138.742599,3606.687256 140.847946,3605.073486 142.994614,3607.530762 
	C144.700836,3609.484375 143.062531,3611.113037 141.909271,3612.749512 
	C140.099792,3615.317383 136.098969,3616.433350 137.098572,3621.128662 
z"
        />
        <path
          fill="#626669"
          opacity="1.000000"
          stroke="none"
          d="
M42.192986,3806.459961 
	C44.339146,3806.545898 45.698788,3807.498047 45.050888,3808.861816 
	C43.173607,3812.812988 40.157978,3814.800537 35.380405,3813.174072 
	C36.477718,3809.671631 38.921314,3808.031494 42.192986,3806.459961 
z"
        />
        <path
          fill="#6F7981"
          opacity="1.000000"
          stroke="none"
          d="
M20.295950,3773.422363 
	C19.449028,3767.882080 19.449028,3767.882080 27.157448,3769.635254 
	C25.895533,3772.466553 23.626968,3773.318604 20.295950,3773.422363 
z"
        />
        <path
          fill="#040F1A"
          opacity="1.000000"
          stroke="none"
          d="
M147.194458,3632.258057 
	C148.951736,3632.686523 149.688416,3633.864502 149.718185,3636.173096 
	C147.635056,3636.231445 145.646942,3635.515869 147.194458,3632.258057 
z"
        />
        <path
          fill="#040F1A"
          opacity="1.000000"
          stroke="none"
          d="
M145.364807,3644.211914 
	C147.244888,3644.843994 147.922180,3645.960693 146.127655,3647.840820 
	C144.386063,3647.129639 144.399719,3645.872559 145.364807,3644.211914 
z"
        />
        <path
          fill="#434F51"
          opacity="1.000000"
          stroke="none"
          d="
M95.675117,3601.716309 
	C96.147537,3600.059814 96.152527,3597.352295 98.785118,3598.909668 
	C99.587135,3599.384277 98.100349,3600.862549 96.751740,3601.931641 
	C96.249321,3602.210449 95.867783,3601.879639 95.675117,3601.716309 
z"
        />
        <path
          fill="#3D494C"
          opacity="1.000000"
          stroke="none"
          d="
M52.896782,3613.017578 
	C52.857117,3612.949951 52.936443,3613.084961 52.896782,3613.017578 
z"
        />
        <path
          fill="#18202C"
          opacity="1.000000"
          stroke="none"
          d="
M97.463051,3786.075195 
	C97.530067,3786.480713 97.037872,3787.060791 95.984192,3787.844727 
	C95.916412,3787.448730 96.410133,3786.849121 97.463051,3786.075195 
z"
        />
        <path
          fill="#040F19"
          opacity="1.000000"
          stroke="none"
          d="
M2003.523193,3843.742676 
	C2001.687622,3834.101074 2004.229614,3824.858887 2005.256958,3814.740723 
	C2019.426270,3805.702393 2031.978638,3812.661621 2044.547241,3817.510498 
	C2049.787109,3819.532227 2054.676758,3822.458984 2059.946045,3824.582764 
	C2076.537598,3831.269531 2091.822266,3828.796875 2106.198730,3818.716553 
	C2112.208496,3814.502930 2119.083252,3813.653564 2126.137939,3813.324219 
	C2145.326172,3812.428955 2164.855225,3814.371094 2183.112793,3805.888916 
	C2186.736816,3804.205322 2190.985107,3804.920166 2194.966064,3805.534912 
	C2212.131348,3808.185303 2229.588135,3805.303955 2246.752441,3808.284668 
	C2250.119141,3808.869385 2253.297607,3807.540527 2256.087158,3805.475830 
	C2260.707275,3802.056885 2265.814941,3801.432373 2272.033691,3803.181885 
	C2281.969238,3807.729980 2282.459473,3818.779053 2289.861816,3825.457520 
	C2291.253418,3827.901855 2292.835449,3829.517334 2291.572754,3832.397461 
	C2282.458252,3841.739258 2273.319824,3843.151367 2261.299072,3838.877197 
	C2241.542725,3831.852783 2220.859619,3828.086426 2199.612305,3829.225098 
	C2193.418213,3829.557373 2180.614502,3840.236816 2179.649414,3845.995117 
	C2179.101562,3849.264404 2181.329590,3850.866943 2183.578125,3852.278809 
	C2187.469971,3854.722412 2191.808105,3854.632080 2196.155762,3853.980713 
	C2213.888428,3851.323975 2231.301758,3853.096680 2248.522705,3857.676270 
	C2256.624756,3859.831055 2264.688965,3860.290771 2273.172607,3859.455566 
	C2291.001953,3857.700684 2308.127930,3862.115479 2325.104004,3867.193604 
	C2330.106689,3868.689941 2336.291504,3873.337158 2340.471191,3866.853271 
	C2344.828613,3860.094238 2337.916260,3856.096680 2334.544922,3851.559570 
	C2328.092041,3842.876221 2318.430664,3843.568604 2309.110596,3843.160645 
	C2302.001709,3842.849365 2293.907227,3844.300049 2290.970703,3834.104980 
	C2294.136963,3828.051025 2300.656006,3828.011475 2304.889160,3824.084229 
	C2312.849365,3816.698975 2318.462891,3807.815674 2324.426270,3798.126221 
	C2330.017822,3791.840332 2331.089600,3784.433105 2333.069824,3777.492188 
	C2335.709473,3768.238037 2338.487305,3759.059570 2342.676025,3750.384766 
	C2346.298340,3742.883545 2350.992676,3742.605225 2355.916992,3749.408447 
	C2359.942139,3754.969238 2360.741211,3760.958740 2358.216553,3767.325195 
	C2351.312744,3784.734131 2348.916260,3803.821289 2338.626709,3819.965332 
	C2336.930420,3822.625977 2338.394043,3825.230713 2338.981445,3827.645020 
	C2341.528320,3838.117188 2345.890381,3847.756348 2353.797363,3855.361816 
	C2358.263428,3859.657959 2362.310303,3864.681885 2362.261719,3870.707764 
	C2362.135742,3886.392090 2365.331543,3901.453857 2368.957031,3917.337158 
	C2369.904541,3923.032227 2371.857666,3927.903076 2368.596191,3933.416260 
	C2365.326904,3933.973633 2362.650391,3934.106689 2360.003662,3933.883789 
	C2356.104492,3933.555176 2353.261475,3931.510498 2354.673096,3927.391357 
	C2356.630371,3921.678955 2352.672363,3919.397705 2349.350098,3916.917725 
	C2344.969482,3913.647949 2339.405518,3913.385498 2334.267090,3912.199951 
	C2331.664795,3911.599365 2328.781494,3911.940186 2327.165283,3914.606201 
	C2325.408203,3917.504639 2325.809082,3920.334473 2328.226562,3922.779297 
	C2329.333252,3923.898682 2330.989258,3924.670898 2330.586182,3927.425049 
	C2319.896240,3927.062500 2309.787598,3925.579590 2301.354736,3918.320801 
	C2300.095947,3917.237305 2299.033447,3915.926270 2297.781006,3914.834717 
	C2291.155518,3909.060547 2284.251953,3904.463867 2274.621582,3905.871826 
	C2268.316650,3906.793457 2262.249023,3903.906738 2256.029785,3903.005127 
	C2254.066650,3902.720459 2252.126709,3902.179932 2250.156982,3902.050781 
	C2242.281494,3901.534424 2238.009277,3903.324951 2236.501953,3907.610352 
	C2235.032715,3911.787598 2239.312744,3921.254150 2244.173584,3923.669434 
	C2250.453857,3926.789551 2256.476074,3930.171875 2261.096191,3935.590576 
	C2263.347900,3938.231445 2266.409668,3937.984619 2269.394043,3937.990234 
	C2279.383057,3938.009766 2289.376465,3938.179688 2299.359131,3937.930176 
	C2304.266846,3937.807373 2307.669434,3939.934082 2310.323975,3943.619629 
	C2312.767090,3947.011719 2312.036377,3950.618896 2308.017578,3951.388184 
	C2302.886719,3952.369873 2299.694336,3954.890381 2297.152100,3959.149658 
	C2295.355713,3962.158936 2292.278564,3962.513184 2289.214355,3961.292480 
	C2276.589111,3956.263916 2263.418457,3953.110596 2250.332764,3949.621338 
	C2246.086670,3948.489258 2241.891602,3947.636475 2237.560059,3948.039795 
	C2231.155029,3948.636719 2226.134521,3944.871582 2220.601562,3942.829590 
	C2218.630371,3942.101807 2218.402832,3940.279297 2220.378174,3938.160400 
	C2224.706055,3933.517578 2223.893555,3930.586914 2218.097656,3930.243652 
	C2212.672363,3929.922119 2207.647705,3929.023682 2202.743164,3926.659424 
	C2200.429932,3925.544434 2197.964844,3926.463135 2196.979980,3929.039551 
	C2196.096436,3931.351074 2197.874756,3932.674316 2199.635498,3933.756592 
	C2200.201416,3934.104248 2200.843750,3934.330322 2201.399414,3934.691650 
	C2203.537354,3936.081299 2207.385742,3936.605957 2206.858643,3939.684814 
	C2206.186035,3943.612793 2202.156006,3943.614502 2198.852783,3943.988770 
	C2185.310059,3945.522705 2173.089355,3942.550049 2162.458496,3933.690430 
	C2158.516846,3930.406006 2153.566162,3928.960449 2148.680420,3928.243164 
	C2140.327637,3927.016113 2132.641113,3924.045166 2125.066895,3920.620361 
	C2117.647461,3917.265381 2116.843506,3914.594727 2121.073486,3907.936279 
	C2121.930664,3906.586914 2123.729004,3905.484863 2122.773438,3903.645020 
	C2121.631836,3901.446533 2119.115967,3901.578125 2117.486572,3902.246582 
	C2110.390137,3905.157227 2103.845459,3903.268311 2097.196777,3900.770752 
	C2093.194580,3899.266846 2089.472412,3899.970459 2085.967773,3902.698730 
	C2083.046631,3904.972168 2079.620605,3906.370361 2075.733398,3905.894531 
	C2072.303711,3905.474854 2069.424316,3903.610840 2070.556152,3900.125977 
	C2072.343994,3894.619873 2069.477295,3892.379639 2065.673828,3889.675537 
	C2061.492432,3886.702393 2061.654785,3882.414795 2066.303467,3880.538086 
	C2071.804688,3878.317871 2076.647461,3874.279053 2082.904053,3874.008789 
	C2091.215820,3873.649902 2095.789062,3867.602783 2100.462158,3862.054932 
	C2103.055664,3858.975586 2101.536377,3855.510986 2097.670410,3854.378906 
	C2096.421875,3854.013184 2095.019043,3853.927246 2093.714355,3854.038086 
	C2080.651855,3855.150146 2067.455322,3853.348145 2054.459229,3855.869385 
	C2049.023926,3856.924072 2043.623413,3855.521484 2038.813477,3853.756104 
	C2031.590698,3851.104736 2023.046631,3851.192871 2017.499634,3844.582275 
	C2013.411255,3839.710693 2008.744019,3844.646729 2003.523193,3843.742676 
z"
        />
        <path
          fill="#040F19"
          opacity="1.000000"
          stroke="none"
          d="
M2372.686523,4011.301758 
	C2371.999512,4013.630127 2372.154541,4015.303467 2371.948975,4016.931641 
	C2370.889893,4025.317871 2366.422119,4027.753418 2360.135254,4022.601562 
	C2355.543457,4018.839111 2351.692383,4017.988525 2345.814697,4019.405762 
	C2337.039551,4021.521484 2328.666992,4017.901855 2321.723877,4012.783691 
	C2315.138184,4007.928223 2307.543457,4008.088135 2300.338379,4006.300781 
	C2297.562012,4005.612061 2296.221924,4007.748047 2296.032227,4011.227051 
	C2286.451660,4010.001953 2279.100098,4003.126465 2269.786621,4000.397217 
	C2260.891113,3997.790039 2252.270996,3994.529785 2242.838379,3997.408691 
	C2238.347656,3998.779541 2234.248779,3997.623047 2230.521240,3995.086182 
	C2221.159668,3988.714844 2210.884521,3985.627197 2199.541016,3985.949463 
	C2197.250000,3986.014893 2194.773926,3985.918213 2192.671143,3985.128906 
	C2185.215820,3982.331055 2177.871338,3979.234375 2170.515625,3976.176025 
	C2168.279541,3975.246094 2166.365234,3973.766357 2166.362305,3971.081787 
	C2166.358887,3968.072754 2168.380615,3966.128418 2170.812256,3964.949707 
	C2173.971924,3963.418701 2177.210205,3963.706787 2180.338623,3965.523193 
	C2186.233154,3968.946289 2192.714111,3969.819092 2199.435303,3970.064209 
	C2207.783203,3970.368652 2215.411865,3972.320312 2222.765869,3977.212891 
	C2228.916504,3981.304932 2236.713623,3982.966064 2243.851807,3985.507080 
	C2246.384521,3986.408447 2248.325195,3984.688477 2249.973389,3982.997070 
	C2253.632812,3979.241211 2257.515869,3979.657227 2261.774414,3981.913330 
	C2275.898193,3989.394531 2291.246826,3988.061035 2306.397217,3987.779541 
	C2310.471924,3987.703857 2312.070557,3984.849609 2311.799316,3980.911133 
	C2311.670410,3979.038330 2310.291748,3977.233643 2311.298096,3974.811035 
	C2321.135742,3976.112549 2329.439209,3981.513916 2338.797852,3983.357422 
	C2340.420654,3983.676758 2341.988281,3984.289551 2343.571045,3984.797607 
	C2347.409424,3986.029785 2349.181885,3984.796875 2349.193359,3980.614258 
	C2349.216064,3972.413818 2349.387207,3972.287354 2356.963379,3972.025879 
	C2360.621094,3971.899414 2364.286377,3971.995117 2368.833984,3971.983887 
	C2372.125000,3973.572510 2373.164795,3976.116455 2373.107178,3978.695068 
	C2372.868896,3989.344971 2376.104736,3999.967773 2372.686523,4011.301758 
z"
        />
        <path
          fill="#0C0D0F"
          opacity="1.000000"
          stroke="none"
          d="
M2368.301270,3934.698242 
	C2368.638672,3929.010742 2366.945068,3924.333984 2366.049805,3918.740723 
	C2371.511230,3914.265625 2374.679932,3916.525635 2376.440918,3922.068115 
	C2377.615479,3925.764648 2376.767334,3930.132568 2380.768311,3933.463867 
	C2381.776123,3934.130615 2382.149658,3934.431396 2383.014404,3935.238770 
	C2388.319580,3941.162109 2394.612305,3938.874756 2401.074707,3938.749512 
	C2402.583740,3938.276611 2403.475098,3938.153809 2405.049072,3938.203369 
	C2408.397461,3938.833984 2410.067871,3941.150391 2413.083984,3942.443604 
	C2414.066406,3942.938232 2414.451172,3943.156982 2415.365479,3943.776855 
	C2419.174561,3948.462402 2421.954346,3945.498779 2425.412109,3943.243164 
	C2426.903564,3942.662109 2427.806396,3942.562012 2429.390625,3942.747070 
	C2433.576904,3944.451172 2436.688965,3947.297363 2441.665771,3946.299805 
	C2443.222168,3946.214355 2444.104248,3946.296875 2445.601318,3946.678223 
	C2449.018555,3948.513672 2451.740967,3950.986084 2455.513916,3947.241211 
	C2457.365479,3946.266846 2458.725830,3946.082275 2460.835205,3946.381348 
	C2462.403320,3946.950684 2463.179932,3947.486572 2464.270508,3948.729980 
	C2465.480469,3953.715820 2466.505859,3958.054932 2469.383301,3962.459961 
	C2470.430420,3964.274414 2470.806641,3965.582275 2470.325928,3967.593750 
	C2469.967041,3969.011963 2469.978516,3969.843262 2469.991699,3971.298340 
	C2469.461426,3976.764893 2473.035645,3976.776367 2476.313232,3977.659424 
	C2492.785156,3982.098145 2509.571777,3985.044678 2526.250977,3988.504150 
	C2526.871094,3988.632568 2527.359131,3989.200684 2528.000244,3990.366699 
	C2528.165283,3991.447510 2528.153076,3991.849365 2528.145020,3992.849609 
	C2527.839111,3993.563721 2527.426270,3993.589844 2527.233398,3993.809326 
	C2521.433838,4000.394531 2521.597168,3999.785400 2512.852051,3997.503418 
	C2499.144287,3993.926758 2485.259033,3990.936523 2471.331055,3988.325928 
	C2458.646240,3985.947998 2446.141846,3982.885498 2432.817383,3979.832031 
	C2430.101562,3980.351807 2428.062988,3981.392090 2426.185303,3980.701416 
	C2409.350342,3974.511230 2391.134033,3973.871826 2374.309814,3967.694824 
	C2373.042236,3967.229736 2372.075684,3966.289062 2370.619385,3964.699951 
	C2369.563477,3954.549072 2369.087891,3944.972900 2368.301270,3934.698242 
z"
        />
        <path
          fill="#172B3E"
          opacity="1.000000"
          stroke="none"
          d="
M2191.785156,4011.233887 
	C2184.258789,4015.649902 2177.920410,4012.108154 2172.284180,4007.093994 
	C2168.917480,4004.098877 2165.366211,4002.765625 2161.041992,4001.557129 
	C2153.430176,3999.430176 2146.152344,3995.876221 2139.020020,3992.343018 
	C2133.798828,3989.756592 2128.902588,3989.429688 2122.957275,3991.926758 
	C2115.553223,3986.990479 2108.460693,3982.912354 2099.963135,3981.861572 
	C2099.010742,3981.743652 2097.799316,3981.502686 2097.227539,3980.857910 
	C2088.991943,3971.572021 2076.131836,3969.753174 2066.989990,3962.233643 
	C2059.505371,3956.077148 2051.230225,3953.080811 2041.993042,3951.587646 
	C2040.102417,3951.281982 2037.930786,3950.397705 2036.644653,3949.055908 
	C2029.902832,3942.020264 2019.428345,3940.278076 2013.320557,3932.415283 
	C2012.925903,3931.906738 2012.159912,3931.133545 2011.787598,3931.242188 
	C2002.973267,3933.811279 1999.308594,3925.530273 1992.547852,3922.206787 
	C1992.154419,3920.285889 1992.394043,3918.647705 1993.041992,3916.549561 
	C2002.916992,3916.287598 2010.178711,3922.106934 2017.954224,3926.223389 
	C2032.568848,3933.961182 2046.979614,3942.047363 2063.242920,3947.966309 
	C2076.378418,3949.946289 2087.145264,3955.423828 2097.405762,3963.632812 
	C2109.894531,3972.024170 2123.005127,3977.818604 2135.960693,3983.709473 
	C2149.283203,3989.767334 2162.336914,3996.333252 2175.441162,4002.802490 
	C2180.757568,4005.427246 2187.048340,4006.109131 2191.785156,4011.233887 
z"
        />
        <path
          fill="#3A4B59"
          opacity="1.000000"
          stroke="none"
          d="
M2370.000000,3964.909180 
	C2390.308105,3969.044922 2410.576904,3973.964844 2431.431152,3979.348389 
	C2430.154297,3986.513184 2425.572998,3984.072754 2421.623779,3982.677490 
	C2417.270020,3981.139648 2413.468018,3978.291748 2408.647461,3977.720947 
	C2405.724365,3977.374756 2402.803711,3975.512939 2401.460205,3979.999756 
	C2401.281494,3980.596436 2399.674805,3981.349609 2399.042480,3981.129639 
	C2395.588379,3979.929688 2393.181152,3981.191162 2391.401855,3984.020264 
	C2389.239990,3987.457764 2389.967529,3990.973877 2391.231934,3994.549072 
	C2393.154053,3999.984619 2393.166016,4000.018066 2398.271484,3994.461670 
	C2400.012695,3999.375000 2401.791504,4003.327148 2407.016602,4005.974121 
	C2411.159424,4008.072510 2412.178711,4014.966553 2411.760254,4020.457520 
	C2411.528320,4023.499268 2409.324951,4024.352539 2406.733154,4022.985596 
	C2405.001221,4022.072021 2403.689697,4020.381104 2401.983887,4019.394775 
	C2399.246338,4017.811768 2396.396240,4016.899902 2394.190918,4021.215088 
	C2387.122559,4018.794189 2380.200684,4015.690186 2372.644775,4012.281738 
	C2370.187012,3999.325928 2369.988770,3986.586182 2369.831543,3972.907715 
	C2369.799805,3969.882812 2369.880371,3967.787842 2370.000000,3964.909180 
z"
        />
        <path
          fill="#040F19"
          opacity="1.000000"
          stroke="none"
          d="
M2098.036621,3963.409668 
	C2096.588867,3964.000244 2094.997070,3964.239746 2094.021484,3963.574951 
	C2084.962891,3957.398193 2074.263672,3954.567871 2064.416992,3948.592285 
	C2065.397217,3946.047363 2067.523926,3944.676758 2069.774658,3944.521484 
	C2082.014893,3943.676514 2093.733398,3939.850342 2105.863770,3938.433350 
	C2107.991699,3938.184814 2109.748291,3938.969971 2111.558105,3939.798096 
	C2119.592285,3943.475342 2127.861816,3946.406250 2134.919189,3952.306396 
	C2138.435059,3955.245361 2143.873779,3956.223389 2147.846436,3959.195312 
	C2150.270264,3961.008057 2152.744385,3963.231445 2151.278320,3966.669922 
	C2149.801270,3970.134521 2146.897949,3970.066406 2143.701904,3968.939209 
	C2141.218262,3968.063232 2138.114014,3968.256592 2136.115479,3966.820068 
	C2128.505127,3961.350098 2119.296387,3961.207764 2110.821045,3958.765137 
	C2106.282959,3957.457520 2100.725098,3955.998535 2098.036621,3963.409668 
z"
        />
        <path
          fill="#172B3E"
          opacity="1.000000"
          stroke="none"
          d="
M2211.975586,4020.860352 
	C2204.748047,4024.789062 2197.371582,4021.542969 2194.017822,4012.751465 
	C2200.928223,4012.597900 2206.718750,4015.630859 2211.975586,4020.860352 
z"
        />
        <path
          fill="#040F19"
          opacity="1.000000"
          stroke="none"
          d="
M2327.409180,3942.881348 
	C2325.657959,3939.609863 2326.094482,3937.381836 2328.840088,3935.718506 
	C2331.041748,3934.384521 2333.102051,3934.415527 2335.243896,3935.763672 
	C2336.704346,3936.682861 2337.842041,3937.809326 2337.534668,3939.669678 
	C2337.099854,3942.301025 2335.253418,3943.583740 2332.780273,3943.779053 
	C2331.197754,3943.904053 2329.575195,3943.528076 2327.409180,3942.881348 
z"
        />
        <path
          fill="#7793AA"
          opacity="1.000000"
          stroke="none"
          d="
M2684.699219,2209.986816 
	C2683.378174,2208.884033 2682.451172,2207.842529 2682.184326,2206.652588 
	C2680.384766,2198.631592 2676.437988,2195.444824 2668.892334,2196.029541 
	C2666.334961,2196.227539 2664.310059,2196.655029 2665.046631,2200.057861 
	C2666.046387,2204.675537 2662.918213,2206.438232 2658.684326,2207.914062 
	C2648.378662,2206.646973 2638.795898,2203.687256 2629.158936,2208.183105 
	C2627.072510,2209.156250 2624.647217,2208.360352 2621.850830,2206.963867 
	C2617.176514,2199.273682 2610.898926,2196.923340 2602.825439,2197.181641 
	C2589.838867,2197.596680 2576.833496,2197.146240 2563.834473,2197.406738 
	C2560.118408,2197.481201 2554.305420,2199.460449 2553.655273,2194.278564 
	C2552.927002,2188.476318 2558.823730,2187.958008 2562.851562,2187.109375 
	C2552.648926,2187.501221 2542.180664,2185.934326 2531.981689,2182.597412 
	C2529.731201,2181.860840 2527.357178,2181.420410 2525.078369,2181.831787 
	C2504.425537,2185.557617 2483.866943,2181.392090 2463.266846,2181.138672 
	C2459.164062,2181.088379 2452.532959,2181.923584 2452.562500,2176.800293 
	C2452.588135,2172.333252 2459.353271,2172.389648 2463.496094,2172.095215 
	C2473.472168,2171.386719 2483.536865,2171.547363 2493.302490,2166.692871 
	C2493.814453,2165.830566 2492.101807,2166.104248 2491.723633,2165.492676 
	C2486.458496,2156.970459 2479.062988,2152.538330 2469.291016,2149.333008 
	C2463.757568,2147.517822 2458.615479,2144.366943 2452.708740,2145.375244 
	C2439.956543,2147.552490 2431.200684,2140.760742 2422.849609,2132.936768 
	C2419.553223,2129.848389 2417.473877,2125.711670 2419.355225,2121.218262 
	C2420.888672,2117.556641 2424.223633,2118.666504 2427.755859,2120.278076 
	C2431.712402,2125.850586 2435.838379,2127.326172 2441.875977,2124.877930 
	C2456.180176,2119.077393 2471.448975,2118.961182 2486.564941,2118.286133 
	C2489.640381,2118.148438 2489.656250,2118.504395 2497.206787,2115.656250 
	C2487.462891,2112.331787 2479.140625,2111.238525 2471.195068,2109.833740 
	C2458.845947,2107.649902 2446.269531,2106.335938 2433.615234,2107.118164 
	C2429.295410,2107.385498 2424.858398,2107.059570 2421.662598,2111.973633 
	C2408.914551,2117.601562 2398.080322,2126.230713 2383.271240,2124.873047 
	C2376.554688,2124.257568 2367.794922,2124.196289 2365.367920,2134.511230 
	C2364.909668,2136.458984 2363.823730,2136.509521 2365.871582,2138.443604 
	C2370.567383,2142.878662 2369.065186,2147.487549 2362.252197,2148.210693 
	C2353.996826,2149.086914 2346.476807,2149.811523 2340.358887,2156.698975 
	C2337.638916,2159.760986 2332.223633,2159.093750 2327.897217,2158.354248 
	C2322.304688,2157.398193 2316.674805,2157.141357 2310.224609,2156.969238 
	C2282.793945,2157.855957 2256.259033,2155.488281 2228.907471,2154.462646 
	C2224.231201,2152.271973 2224.062012,2149.246338 2225.882568,2145.852783 
	C2227.700195,2142.464600 2230.862305,2141.646729 2234.418457,2142.015137 
	C2262.832764,2144.959473 2291.347168,2144.911621 2319.860352,2144.912842 
	C2322.524902,2144.913086 2325.224609,2145.131592 2327.840332,2144.604248 
	C2332.145508,2143.735840 2334.907715,2142.408936 2330.998535,2137.405029 
	C2325.671387,2130.586426 2326.748291,2127.615967 2335.494873,2125.879150 
	C2341.018066,2124.782227 2345.868652,2123.977783 2346.026611,2116.442627 
	C2337.429199,2118.301514 2329.894531,2115.319824 2322.627930,2110.594238 
	C2326.848389,2115.643066 2326.661865,2120.890625 2323.837891,2126.135254 
	C2320.929932,2131.534912 2315.854736,2129.674072 2311.327881,2129.437256 
	C2290.387451,2128.341553 2269.350586,2130.278564 2248.458252,2127.223633 
	C2242.511230,2126.354248 2237.221436,2127.910156 2234.419678,2134.201416 
	C2232.464355,2138.592773 2228.647705,2140.695557 2224.008301,2140.789795 
	C2207.031982,2141.135010 2190.052246,2141.328369 2173.072754,2141.486572 
	C2171.090576,2141.505127 2169.076416,2141.158447 2167.142578,2140.651855 
	C2164.589355,2139.983398 2161.400146,2139.524414 2161.403320,2136.144287 
	C2161.406738,2132.163818 2164.967285,2131.847900 2167.873535,2131.307617 
	C2170.828613,2130.757812 2173.859619,2130.808350 2176.849854,2130.946777 
	C2184.575195,2131.303711 2192.105225,2129.653564 2199.719482,2128.882324 
	C2202.392090,2128.611328 2205.325195,2128.324707 2206.374512,2125.344238 
	C2208.242188,2120.040283 2212.161621,2117.551758 2217.307861,2116.189697 
	C2220.097656,2115.451172 2221.933838,2113.426025 2221.832275,2110.342529 
	C2221.712646,2106.720459 2219.754883,2104.074951 2216.728271,2102.256104 
	C2213.593994,2100.372314 2211.951172,2102.751953 2210.007568,2104.635742 
	C2203.160156,2111.273682 2194.892090,2110.279541 2186.355957,2108.738525 
	C2184.310303,2111.832031 2189.379639,2115.796387 2185.374268,2118.594971 
	C2182.330811,2120.721436 2178.943115,2119.699951 2175.666260,2119.449219 
	C2165.681152,2118.686523 2155.671387,2119.434326 2145.686523,2118.953125 
	C2137.578857,2118.562500 2126.325195,2118.090088 2123.930176,2111.497314 
	C2119.151611,2098.343262 2111.699951,2103.101807 2103.673096,2104.662598 
	C2100.740967,2105.232178 2097.679932,2104.540283 2094.788086,2103.680420 
	C2089.063965,2101.978271 2083.646973,2102.239990 2078.502930,2105.614746 
	C2074.431641,2108.285645 2070.349854,2108.576904 2066.782227,2104.706787 
	C2063.507812,2101.154541 2063.886963,2097.512695 2067.588379,2093.234863 
	C2058.411377,2093.466553 2050.260742,2092.182861 2043.668945,2085.903320 
	C2040.959229,2083.322266 2037.342041,2084.048828 2034.172974,2085.298096 
	C2024.799805,2088.992676 2015.004639,2090.291260 2005.061890,2091.320312 
	C1998.946655,2091.953613 1995.643433,2090.177002 1996.761597,2083.915039 
	C1998.049316,2076.703857 1994.963745,2074.502686 1988.301880,2073.947266 
	C1977.043213,2073.008545 1976.190186,2070.844971 1982.378418,2061.312988 
	C1979.414307,2059.534912 1976.066284,2059.508545 1972.809204,2059.246826 
	C1955.071533,2057.820801 1955.069824,2057.842773 1953.678223,2041.225586 
	C1949.701660,2041.496826 1946.641235,2044.290649 1941.860352,2044.799683 
	C1956.020752,2049.050049 1955.837402,2053.962891 1941.868652,2068.709473 
	C1951.595459,2072.373047 1961.521851,2073.861084 1971.715454,2072.645508 
	C1976.892578,2072.028076 1981.583496,2073.423096 1986.414673,2074.666748 
	C1990.226196,2075.647949 1993.450073,2077.242676 1993.863403,2081.591309 
	C1994.341431,2086.619141 1991.439331,2089.779053 1987.166016,2091.651367 
	C1977.325684,2095.962158 1968.335327,2094.873779 1960.677246,2086.408447 
	C1961.773926,2088.991943 1963.871704,2089.840820 1965.754517,2090.932617 
	C1974.570068,2096.044189 1974.559326,2096.016846 1968.360352,2104.668945 
	C1966.436523,2107.354248 1963.399902,2111.160156 1968.804077,2112.346924 
	C1978.355713,2114.443848 1977.467041,2119.425781 1974.198608,2126.072266 
	C1973.502319,2127.488281 1973.905884,2129.366943 1973.068604,2131.597656 
	C1965.130493,2136.483398 1958.282104,2136.424316 1952.796509,2128.687012 
	C1952.412720,2128.145752 1952.065552,2127.569824 1951.611816,2127.089355 
	C1948.211060,2123.487549 1943.525146,2122.704834 1939.059814,2121.580322 
	C1934.978760,2120.552246 1934.679932,2114.038086 1928.445923,2114.527100 
	C1930.667480,2119.984375 1935.425903,2120.189453 1938.975830,2121.944824 
	C1948.301758,2126.556641 1951.233643,2135.912842 1944.808228,2143.426270 
	C1939.327271,2145.419922 1934.238770,2145.582275 1929.374634,2144.602783 
	C1922.806152,2143.280762 1916.980957,2143.964111 1911.248901,2147.572754 
	C1906.592651,2150.504395 1901.852173,2149.931396 1899.613159,2144.513672 
	C1897.207275,2138.691895 1902.216431,2137.079590 1906.269775,2135.588379 
	C1910.527954,2134.021729 1914.897583,2132.748535 1919.891479,2131.067139 
	C1908.544067,2131.024170 1897.926025,2131.344482 1887.326416,2130.811279 
	C1884.088989,2130.648438 1879.701172,2130.516846 1879.497559,2126.326904 
	C1879.248291,2121.205322 1884.119019,2121.665039 1887.579224,2121.095215 
	C1894.483032,2119.958496 1901.942017,2122.848389 1909.215698,2118.680664 
	C1905.920166,2116.372070 1904.229004,2119.175781 1902.076660,2119.249756 
	C1895.483887,2119.475830 1889.642212,2119.363281 1887.510132,2110.869629 
	C1885.678589,2103.573242 1881.477173,2102.431396 1874.884399,2106.406250 
	C1869.691406,2109.536865 1864.364624,2112.192139 1858.259277,2112.772949 
	C1851.266968,2113.438721 1844.933228,2112.667969 1841.469971,2105.177002 
	C1838.115234,2097.920410 1838.739258,2088.568848 1844.770020,2084.074463 
	C1849.075806,2080.865479 1848.811523,2079.839844 1845.290039,2076.470947 
	C1841.805420,2073.137207 1841.275635,2068.181152 1841.786865,2063.375732 
	C1842.868652,2053.209229 1846.216187,2042.640869 1836.500610,2033.804810 
	C1836.776489,2032.667969 1836.960938,2032.236084 1837.600586,2031.275269 
	C1842.466187,2028.943481 1845.308228,2025.304810 1848.344971,2021.909058 
	C1850.478638,2019.523071 1847.635864,2019.625244 1846.105225,2018.415771 
	C1845.114990,2017.761108 1844.732178,2017.464844 1843.830811,2016.639648 
	C1840.420410,2012.595215 1839.641235,2009.009521 1843.215088,2005.488403 
	C1846.651245,2002.102905 1850.434448,1999.286987 1855.608887,2001.471436 
	C1859.924561,2003.293335 1864.272827,2004.858276 1869.695068,2005.429688 
	C1874.878052,2006.713013 1875.256836,2010.127808 1875.714722,2014.019653 
	C1877.088257,2025.693359 1885.008179,2028.917358 1894.550659,2021.932373 
	C1895.354248,2021.344116 1896.072632,2020.643066 1896.856934,2020.027588 
	C1901.254639,2016.577026 1906.225952,2013.316772 1910.880615,2018.601807 
	C1915.564941,2023.920654 1915.790527,2029.948364 1909.303589,2034.894043 
	C1913.972656,2035.695679 1917.258179,2035.104980 1918.474365,2029.815430 
	C1919.755249,2024.243774 1924.598267,2020.010620 1929.400879,2022.000732 
	C1941.743042,2027.114868 1954.420532,2022.364502 1966.606079,2024.945679 
	C1975.694580,2026.870850 1983.792969,2026.671143 1993.231934,2023.858032 
	C2001.977051,2021.251465 2012.580200,2024.212769 2022.323730,2021.787476 
	C2033.617310,2018.976685 2044.836670,2019.446411 2056.132812,2022.775024 
	C2061.113281,2024.242798 2066.583008,2023.345703 2071.936279,2022.861206 
	C2080.132080,2022.118896 2088.883301,2021.846802 2093.938965,2030.575806 
	C2095.761475,2033.722534 2097.934326,2033.673706 2100.562012,2032.183472 
	C2106.659424,2028.725342 2113.292969,2028.797485 2120.736816,2029.061279 
	C2131.630127,2028.468750 2141.642578,2030.111084 2151.711914,2030.647461 
	C2155.286377,2030.837646 2158.883301,2031.518677 2162.807861,2028.757080 
	C2167.866943,2026.623779 2172.348389,2026.718872 2177.677490,2027.313721 
	C2180.606689,2027.744263 2182.517822,2028.861206 2185.457031,2029.016113 
	C2188.314209,2028.893066 2190.379639,2029.033325 2193.202637,2029.409424 
	C2198.031006,2030.515015 2201.825928,2032.199707 2206.285645,2032.042603 
	C2211.462891,2031.860229 2215.869385,2034.022461 2217.308105,2040.504272 
	C2215.552979,2050.147461 2218.551270,2054.821777 2227.154541,2054.671143 
	C2231.899902,2054.587891 2237.768311,2056.654541 2241.606201,2050.422852 
	C2250.889404,2041.820312 2254.846436,2042.082031 2261.721191,2051.092773 
	C2266.457764,2057.300293 2270.689941,2057.877197 2275.179199,2051.734863 
	C2280.164307,2044.914185 2287.005859,2043.603271 2294.419434,2043.276001 
	C2299.143311,2043.067383 2304.212646,2043.583618 2306.556641,2048.404785 
	C2308.963623,2053.356201 2304.641846,2056.216309 2300.770264,2059.760498 
	C2309.671631,2061.604248 2317.161621,2058.506348 2325.913574,2058.031250 
	C2320.080811,2049.795654 2325.117188,2047.973267 2331.484619,2046.657959 
	C2337.325928,2045.451050 2342.413086,2046.449097 2347.648682,2049.518311 
	C2357.286621,2055.168213 2361.686768,2054.241455 2368.776123,2046.042969 
	C2370.905762,2043.580200 2373.339844,2042.179321 2376.546387,2042.392456 
	C2385.990967,2043.020630 2395.895996,2040.407227 2404.518311,2046.850830 
	C2407.261230,2048.900635 2410.995117,2047.862427 2414.949707,2048.509277 
	C2418.355957,2049.983643 2420.839111,2051.688965 2423.126953,2053.608398 
	C2429.889893,2059.282715 2429.990723,2064.994629 2423.583984,2071.282715 
	C2414.397705,2080.299072 2415.055908,2082.968750 2427.448730,2086.735596 
	C2429.681152,2087.414307 2432.747314,2087.541504 2433.669189,2089.552734 
	C2438.016113,2099.038086 2444.013428,2094.733154 2450.000732,2091.985107 
	C2456.141357,2089.166504 2462.115723,2087.549561 2466.520508,2094.918457 
	C2467.604980,2096.732666 2469.583984,2098.149902 2471.488770,2097.825684 
	C2480.189697,2096.342529 2487.971191,2100.483398 2496.221680,2101.735107 
	C2492.403076,2099.609131 2487.830811,2100.516357 2484.095947,2097.915039 
	C2479.890869,2094.985840 2477.021240,2091.439941 2478.556396,2086.237061 
	C2480.098145,2081.012451 2484.675049,2082.286621 2488.635010,2082.489746 
	C2496.077881,2082.871582 2504.272949,2085.527588 2509.266846,2075.716553 
	C2515.899170,2072.478516 2519.700439,2076.781494 2524.492920,2080.294189 
	C2528.135498,2085.525146 2531.666260,2086.895508 2537.670410,2085.188232 
	C2546.683838,2082.625732 2556.116699,2085.032959 2565.349365,2085.555664 
	C2569.452637,2085.788086 2572.182373,2088.417725 2571.659424,2092.926514 
	C2571.157471,2097.256836 2568.059814,2099.057373 2564.040283,2099.429199 
	C2560.489014,2099.758057 2556.854248,2097.207031 2553.122803,2100.139648 
	C2560.044189,2100.578857 2566.848877,2100.421875 2573.490723,2101.530029 
	C2580.128174,2102.637695 2585.942871,2103.342773 2588.838623,2095.132812 
	C2590.050537,2091.697510 2594.055420,2091.283203 2597.422119,2091.128174 
	C2603.408691,2090.852295 2609.332275,2089.841309 2615.277588,2089.472412 
	C2624.012451,2088.930420 2632.262939,2088.255615 2639.225098,2081.765869 
	C2642.678467,2078.546875 2647.956787,2078.913818 2652.632568,2078.948242 
	C2665.939697,2079.046387 2679.232910,2079.193604 2692.287109,2075.885498 
	C2699.801025,2073.981445 2706.562988,2077.901123 2713.312988,2079.143311 
	C2714.436279,2078.653076 2714.513428,2078.149414 2714.097656,2077.373535 
	C2712.186523,2073.805420 2706.844238,2071.021973 2710.039307,2066.187500 
	C2712.728027,2062.118896 2717.766113,2063.148926 2722.031738,2063.028076 
	C2723.693604,2062.980957 2725.478271,2062.889893 2726.975342,2063.505615 
	C2739.578613,2068.690918 2750.387939,2067.766602 2757.278809,2054.326904 
	C2749.148926,2054.362549 2746.396729,2046.570435 2740.957031,2040.981201 
	C2733.906006,2037.596313 2728.521484,2033.333618 2723.119629,2028.509155 
	C2718.534180,2024.413940 2711.771240,2019.788574 2705.455078,2028.573730 
	C2702.929443,2032.086548 2697.546631,2034.487915 2692.927002,2032.531372 
	C2688.300537,2030.572144 2689.673828,2025.695190 2690.735840,2021.953979 
	C2691.945801,2017.692871 2688.323730,2014.406372 2689.383301,2009.743652 
	C2701.344971,2001.968018 2713.943604,2001.159302 2727.017578,2003.414185 
	C2731.640625,2004.211426 2734.295898,2006.863403 2733.868896,2011.593750 
	C2733.297852,2017.917114 2736.639893,2021.472290 2741.672852,2024.107056 
	C2742.222412,2023.841431 2742.276367,2023.345093 2742.067871,2022.546753 
	C2737.383789,2004.595337 2737.380615,2004.541382 2755.716553,2002.529419 
	C2768.598389,2001.115967 2781.575439,1999.427856 2792.022949,2010.030396 
	C2793.715332,2011.748047 2795.656250,2011.151978 2797.461182,2009.994995 
	C2800.281738,2008.187012 2803.364746,2006.974243 2806.548828,2005.998535 
	C2816.018555,2003.096802 2821.828125,2005.853394 2821.600342,2015.335449 
	C2821.395508,2023.850342 2826.314209,2026.041504 2831.967529,2028.720825 
	C2838.242676,2031.694824 2845.417236,2032.547729 2851.570801,2037.413086 
	C2851.938721,2041.497070 2856.493896,2042.848633 2855.967773,2047.268799 
	C2852.274414,2052.051758 2847.416016,2050.654297 2842.914795,2050.587158 
	C2838.495117,2050.521240 2834.238525,2051.043457 2830.570312,2053.564697 
	C2825.526123,2057.031982 2830.375977,2060.576172 2830.687744,2064.123779 
	C2831.291992,2071.000000 2826.045410,2070.497070 2821.914551,2070.139648 
	C2815.792969,2069.609619 2814.848877,2071.938965 2817.081055,2076.958496 
	C2819.772949,2083.011475 2822.301514,2088.877686 2820.762695,2096.014160 
	C2819.705322,2100.918701 2824.432373,2101.403320 2828.036865,2102.114990 
	C2831.284668,2102.756592 2835.031982,2103.094238 2836.378906,2106.600830 
	C2838.092529,2111.061035 2832.570557,2111.370117 2831.427002,2113.930664 
	C2829.041504,2119.274170 2821.940430,2120.514404 2821.392090,2127.382080 
	C2823.462891,2130.664551 2824.446777,2133.573242 2824.681885,2137.547363 
	C2824.424072,2140.648193 2823.768555,2142.720215 2820.861328,2144.027832 
	C2813.443115,2141.858154 2807.052246,2139.028076 2799.811768,2139.781494 
	C2797.571533,2140.014648 2796.061523,2138.365234 2796.560791,2135.912842 
	C2796.919922,2134.148438 2798.165039,2133.060303 2800.164795,2133.344727 
	C2801.149658,2133.484619 2802.126953,2133.846436 2803.104736,2133.835938 
	C2805.613281,2133.808838 2809.357178,2134.807373 2809.296143,2130.992432 
	C2809.233887,2127.107666 2805.026123,2127.291260 2802.332031,2127.093506 
	C2800.695801,2126.973389 2799.016602,2129.147217 2797.186768,2129.593994 
	C2793.820068,2130.416504 2790.312256,2130.312744 2788.662598,2126.518555 
	C2787.252441,2123.275146 2788.483398,2120.662109 2791.723877,2119.179688 
	C2796.093994,2117.180664 2795.910400,2114.688232 2792.726807,2111.673340 
	C2790.970947,2110.010498 2789.605957,2107.979248 2790.500000,2105.472900 
	C2792.918213,2098.694580 2786.938965,2098.166504 2783.506104,2097.216797 
	C2779.383057,2096.076172 2774.028809,2097.183105 2772.692871,2101.812988 
	C2770.746826,2108.557617 2766.867920,2109.941650 2760.933838,2108.563965 
	C2757.591064,2107.787842 2755.257812,2109.212402 2752.112549,2112.060059 
	C2758.239990,2115.357666 2764.372803,2118.249023 2765.280273,2125.665771 
	C2765.341309,2126.166260 2766.152344,2126.625000 2766.682861,2127.009033 
	C2768.565186,2128.371338 2772.579102,2128.176758 2770.698975,2132.287598 
	C2769.014160,2135.971191 2765.874512,2138.429199 2761.995850,2137.402588 
	C2756.667725,2135.992432 2751.674561,2135.875244 2746.350342,2137.172363 
	C2743.716553,2137.813965 2741.382080,2135.697510 2739.206787,2134.171143 
	C2735.954346,2131.888916 2733.161133,2131.009033 2731.939453,2136.185059 
	C2730.842285,2140.834229 2726.485352,2141.826660 2723.368164,2140.988037 
	C2718.371094,2139.643799 2712.979248,2140.892578 2708.241699,2135.601562 
	C2704.276855,2131.173828 2696.186768,2130.777344 2689.427246,2132.725586 
	C2688.121094,2133.102051 2687.111328,2134.169678 2687.179443,2135.622070 
	C2687.254639,2137.220215 2688.563477,2137.790771 2689.948730,2137.832764 
	C2694.596436,2137.973389 2699.248779,2137.961182 2703.894775,2138.132812 
	C2705.627686,2138.196289 2707.353516,2138.925293 2707.311279,2140.937744 
	C2707.255127,2143.633301 2704.896484,2144.321045 2703.053223,2143.678955 
	C2698.737793,2142.176025 2694.678467,2142.517578 2690.481201,2143.902100 
	C2688.909668,2144.420410 2687.011963,2144.455566 2686.995361,2146.595703 
	C2686.984375,2148.004883 2688.211914,2148.891846 2689.443359,2149.475586 
	C2690.635254,2150.041016 2691.882812,2150.488770 2693.552979,2151.494141 
	C2694.098877,2153.238037 2693.736572,2154.308105 2692.419922,2155.596680 
	C2687.177734,2166.098389 2689.985596,2170.305176 2701.395264,2169.962891 
	C2703.576172,2169.897705 2707.226318,2170.545898 2706.970703,2167.500000 
	C2706.285156,2159.322510 2712.530762,2161.476562 2715.528076,2162.324219 
	C2727.891602,2165.820557 2740.785889,2161.910645 2753.031494,2165.630371 
	C2754.868652,2166.188477 2756.730957,2163.809326 2759.238525,2165.844238 
	C2756.418945,2171.235596 2750.853516,2172.244141 2745.920410,2173.846191 
	C2741.856934,2175.165527 2737.406006,2175.297607 2733.351562,2176.636719 
	C2729.261475,2177.987793 2725.673828,2178.321777 2722.120850,2175.486328 
	C2719.883789,2173.700684 2717.093750,2174.020020 2714.447754,2174.008789 
	C2708.466064,2173.983643 2702.483887,2173.950684 2696.502930,2174.014893 
	C2691.154053,2174.072266 2686.027344,2174.493652 2682.755615,2168.496582 
	C2680.935059,2165.159424 2677.022705,2165.320801 2673.938965,2167.442627 
	C2671.343506,2169.228516 2668.689697,2170.206543 2664.782959,2169.990234 
	C2662.995117,2169.019043 2662.343018,2167.859619 2661.973389,2165.832520 
	C2661.834961,2162.952881 2661.158936,2160.918213 2659.774414,2158.399170 
	C2657.974854,2151.364258 2654.099121,2148.251465 2647.335693,2148.765381 
	C2642.971191,2149.097656 2638.828613,2147.862793 2634.700439,2146.487305 
	C2631.920898,2145.561279 2629.199463,2145.519287 2629.761963,2150.575439 
	C2629.118652,2155.793213 2629.295898,2160.973633 2621.810547,2160.384521 
	C2618.489990,2156.696045 2614.205811,2157.079102 2610.194824,2155.105957 
	C2608.687012,2153.588623 2608.272217,2152.277588 2608.002930,2150.227051 
	C2604.095459,2145.225098 2600.411133,2139.782227 2594.408936,2148.318359 
	C2592.943848,2149.058594 2592.013428,2149.060791 2590.503174,2148.373535 
	C2588.985352,2146.812500 2587.990967,2145.733887 2587.295410,2143.634277 
	C2585.770996,2137.633789 2582.013428,2135.459473 2576.887939,2135.038818 
	C2574.898193,2134.875244 2572.891357,2134.787842 2570.959229,2134.227295 
	C2567.894775,2133.338135 2565.218018,2131.793701 2565.201904,2128.245117 
	C2565.186279,2124.875977 2568.080566,2124.252441 2570.687256,2123.458740 
	C2572.770508,2122.824219 2576.270264,2123.897217 2576.560303,2120.960938 
	C2576.880615,2117.722168 2573.848389,2115.866455 2571.216797,2114.364502 
	C2569.760742,2113.533447 2567.992676,2113.050781 2566.388916,2113.394043 
	C2555.092041,2115.813477 2543.885498,2112.786377 2532.634766,2112.682861 
	C2524.772461,2112.610840 2519.554443,2113.878418 2517.920410,2122.294189 
	C2521.728760,2125.289307 2523.987061,2122.301025 2526.171631,2119.936768 
	C2530.559814,2115.188232 2536.138184,2113.483398 2542.317871,2113.223389 
	C2552.014160,2112.815186 2554.648926,2114.901123 2555.427246,2124.788330 
	C2555.778809,2129.257812 2557.436035,2131.493896 2561.798096,2132.108154 
	C2565.839600,2132.677490 2569.489990,2134.225586 2572.628418,2138.004150 
	C2573.599854,2142.175293 2575.965820,2143.138184 2579.291748,2143.329102 
	C2583.277588,2143.557861 2587.046143,2144.705811 2590.344238,2148.421387 
	C2592.387451,2156.849854 2586.651611,2157.097168 2580.745605,2157.812012 
	C2578.928711,2157.642090 2578.323486,2156.107178 2577.010254,2156.699463 
	C2577.627441,2156.899658 2578.223389,2157.151367 2579.437012,2157.747070 
	C2588.669922,2160.401855 2598.424316,2155.967529 2606.797363,2163.207520 
	C2608.272461,2166.148438 2608.232910,2168.366211 2606.549316,2171.248779 
	C2603.422852,2171.855469 2600.344727,2172.672852 2598.562744,2170.277344 
	C2593.737549,2163.791748 2587.496338,2166.217773 2580.673096,2166.707520 
	C2582.698730,2172.934082 2584.446045,2176.951172 2591.793457,2178.795898 
	C2600.964111,2181.098633 2609.919434,2182.195557 2619.199707,2181.992432 
	C2624.057129,2181.885986 2627.903564,2182.994629 2627.975098,2188.841309 
	C2628.022217,2192.705078 2630.517578,2193.407227 2633.636230,2194.390137 
	C2641.972900,2197.019043 2650.377441,2197.241455 2657.627686,2192.626465 
	C2663.676758,2188.775879 2669.700195,2187.684570 2676.442627,2187.986816 
	C2679.420410,2188.120605 2682.409668,2187.969482 2685.392822,2188.014648 
	C2695.481934,2188.166992 2698.037598,2191.320801 2696.417236,2201.546875 
	C2696.262207,2202.527100 2696.159180,2203.515381 2696.016113,2205.254883 
	C2695.666748,2206.007812 2695.333252,2206.005859 2694.500000,2206.001953 
	C2693.594727,2206.484131 2693.139893,2206.916260 2692.314697,2207.642090 
	C2689.894531,2208.980225 2687.839355,2210.118408 2684.699219,2209.986816 
z"
        />
        <path
          fill="#859CAF"
          opacity="1.000000"
          stroke="none"
          d="
M2820.154541,2144.705811 
	C2821.686768,2142.876709 2821.975342,2141.170898 2821.993164,2138.678711 
	C2826.841797,2135.385742 2831.974365,2137.738281 2837.494385,2137.114014 
	C2838.738525,2137.126221 2839.325195,2136.908691 2839.876465,2136.002197 
	C2839.938721,2135.566895 2839.593506,2134.662354 2839.342529,2134.208008 
	C2837.578613,2131.541260 2835.344238,2130.921143 2832.129883,2130.936035 
	C2827.863525,2131.139648 2824.313721,2131.239746 2820.610840,2128.509521 
	C2817.804932,2127.754395 2814.946533,2128.400635 2814.525391,2125.146484 
	C2814.167725,2122.380615 2816.052734,2120.457031 2818.521973,2120.400146 
	C2823.815186,2120.278320 2825.970215,2117.971680 2826.589111,2112.706543 
	C2827.090576,2108.443604 2832.655029,2111.387939 2835.104492,2107.879395 
	C2832.746338,2105.555908 2830.001465,2104.858154 2826.647705,2104.767822 
	C2817.400879,2104.518066 2814.773438,2100.925293 2818.312744,2092.828369 
	C2819.621094,2089.835693 2818.828125,2087.806641 2817.720459,2085.414062 
	C2815.778320,2081.218506 2813.779785,2077.048096 2811.914795,2072.818604 
	C2810.013184,2068.505859 2811.836670,2065.925049 2816.633057,2066.205811 
	C2820.672363,2066.442139 2824.642334,2069.905518 2828.722412,2066.584961 
	C2829.364990,2064.401367 2827.749756,2063.567627 2826.745605,2062.304688 
	C2822.080811,2056.437988 2823.608154,2053.159912 2830.194336,2049.792725 
	C2838.210205,2045.694824 2846.269043,2048.862549 2855.107666,2047.981445 
	C2866.935791,2047.891602 2877.529541,2050.724121 2889.162598,2051.988037 
	C2891.231689,2050.529785 2892.863525,2050.042725 2895.351807,2050.020020 
	C2902.368896,2052.312256 2902.368896,2052.312256 2898.100098,2059.363525 
	C2895.789307,2061.992188 2893.798828,2064.163330 2891.090576,2065.626953 
	C2887.461914,2067.587646 2888.459229,2069.692871 2891.493896,2071.389404 
	C2892.054688,2071.702881 2892.756104,2071.788574 2893.405273,2071.915039 
	C2895.984375,2072.417480 2899.460205,2072.492676 2898.748047,2076.208740 
	C2898.190918,2079.117432 2894.907227,2079.488770 2892.282959,2079.888672 
	C2890.646484,2080.137939 2888.913818,2079.890137 2887.322021,2080.271973 
	C2884.301025,2080.996338 2883.607910,2082.643066 2885.656494,2085.286865 
	C2888.766113,2089.300781 2888.706543,2089.346924 2884.764160,2094.550293 
	C2887.857178,2096.578369 2891.222412,2097.544922 2894.750732,2098.195068 
	C2896.124268,2098.447998 2897.292236,2099.188232 2897.427979,2100.706055 
	C2897.556641,2102.147217 2896.848389,2103.295166 2895.401611,2103.705566 
	C2891.329102,2104.861328 2889.175049,2107.681396 2886.743896,2111.137451 
	C2883.544189,2115.687012 2877.344727,2115.189697 2872.174561,2115.955322 
	C2867.110107,2116.705078 2862.345215,2114.572754 2857.421631,2114.206299 
	C2854.455322,2113.985352 2849.371826,2112.299072 2849.027588,2116.692383 
	C2848.639160,2121.652588 2853.851074,2119.948242 2856.716553,2119.885498 
	C2862.378906,2119.760742 2866.578857,2120.428955 2866.422363,2127.667236 
	C2866.367676,2130.197754 2867.947266,2132.729248 2870.778809,2133.873291 
	C2871.989746,2134.362549 2873.418945,2134.833740 2874.205322,2135.769775 
	C2883.326904,2146.626953 2887.011963,2149.648926 2899.423340,2151.648193 
	C2905.480957,2152.624023 2906.363037,2155.469482 2905.977295,2161.128662 
	C2905.190674,2162.677734 2904.274170,2162.980225 2902.605469,2162.434082 
	C2900.494873,2161.998535 2898.999756,2161.996826 2896.757080,2161.997559 
	C2883.849121,2162.000000 2871.688721,2162.000000 2858.606689,2162.000000 
	C2860.001953,2157.069092 2864.399902,2158.176270 2866.068604,2154.166504 
	C2860.304199,2154.075439 2854.922363,2153.402344 2848.971924,2155.004639 
	C2853.279541,2162.820312 2858.749023,2166.974365 2867.476318,2166.095703 
	C2870.468994,2165.794434 2873.907715,2165.118652 2878.119141,2168.615479 
	C2856.024658,2172.640625 2835.873291,2167.205566 2815.521240,2166.645752 
	C2823.369385,2161.815674 2832.483398,2165.846680 2840.716797,2163.032959 
	C2840.018066,2158.013916 2834.137207,2161.909424 2833.292725,2157.870850 
	C2834.630127,2154.404053 2839.150146,2157.460938 2841.028809,2153.613037 
	C2834.320557,2150.387695 2824.895752,2153.381592 2820.154541,2144.705811 
z"
        />
        <path
          fill="#7793AA"
          opacity="1.000000"
          stroke="none"
          d="
M2697.575439,2152.435547 
	C2702.571777,2151.910400 2707.342285,2152.749023 2710.526855,2147.900146 
	C2712.207275,2145.342041 2715.312012,2146.032715 2717.910400,2146.023926 
	C2731.528809,2145.978271 2745.146973,2146.025635 2758.765381,2145.992432 
	C2763.856934,2145.979980 2768.443604,2146.855469 2772.401611,2153.253906 
	C2763.970703,2151.205322 2758.187988,2153.270996 2752.124023,2157.094238 
	C2747.982422,2159.705811 2741.743896,2158.635742 2736.567627,2157.128418 
	C2731.966064,2155.788330 2727.432861,2155.611084 2722.826172,2156.729248 
	C2714.315918,2158.794678 2705.777588,2158.419922 2696.609863,2156.566895 
	C2695.902344,2154.778320 2696.259766,2153.714355 2697.575439,2152.435547 
z"
        />
        <path
          fill="#6E8AA1"
          opacity="1.000000"
          stroke="none"
          d="
M2766.561035,2197.972168 
	C2756.467041,2197.926270 2747.337158,2197.913574 2738.291016,2201.250977 
	C2733.866455,2202.883301 2728.453857,2201.852539 2723.487305,2201.995605 
	C2717.548828,2202.166992 2711.565430,2201.423584 2705.750977,2203.579590 
	C2704.053223,2204.208740 2701.457764,2204.382324 2701.168701,2201.532959 
	C2700.977783,2199.652832 2702.718750,2198.627441 2704.369141,2198.343994 
	C2711.838867,2197.059326 2719.318848,2195.179199 2726.825439,2195.029297 
	C2730.883545,2194.948242 2734.720459,2194.312988 2738.440918,2193.379639 
	C2743.073242,2192.217773 2747.503418,2191.333008 2752.070068,2193.054443 
	C2755.062256,2194.182373 2757.876221,2193.855225 2760.778564,2192.739258 
	C2764.179932,2191.431152 2767.738525,2191.879150 2769.966553,2194.568848 
	C2772.110596,2197.156738 2769.323486,2197.600586 2766.561035,2197.972168 
z"
        />
        <path
          fill="#7995AC"
          opacity="1.000000"
          stroke="none"
          d="
M2896.022461,2182.204590 
	C2899.295166,2182.359375 2900.888672,2184.132080 2902.701416,2185.590576 
	C2906.922607,2188.987305 2911.358887,2189.089355 2915.348145,2185.466309 
	C2918.309570,2182.776855 2921.533203,2182.021973 2925.364746,2181.959717 
	C2936.957520,2181.771484 2937.511719,2182.208008 2938.625000,2195.174072 
	C2934.077148,2196.360840 2929.487305,2196.966797 2925.324707,2194.129883 
	C2921.657715,2191.630615 2917.635742,2191.285889 2913.861572,2192.526855 
	C2908.973633,2194.134033 2904.840576,2194.463623 2901.150635,2190.275391 
	C2900.031250,2189.005127 2898.412842,2188.894287 2896.670654,2188.690430 
	C2890.956543,2188.022461 2890.924316,2187.581787 2896.022461,2182.204590 
z"
        />
        <path
          fill="#6E8AA1"
          opacity="1.000000"
          stroke="none"
          d="
M2947.492676,2221.198242 
	C2944.835205,2221.799805 2942.845215,2222.471680 2940.775391,2219.755371 
	C2945.205078,2217.413818 2949.639404,2215.947021 2954.536377,2216.026855 
	C2962.140137,2216.150879 2969.776855,2215.319824 2977.271240,2217.767334 
	C2981.007812,2218.987793 2985.039551,2219.371338 2988.967773,2219.919189 
	C2992.780029,2220.450928 2996.812500,2219.252930 3002.130615,2221.763916 
	C2996.179688,2224.120117 2991.861816,2225.902588 2986.529785,2224.550781 
	C2973.970703,2221.366699 2961.000244,2222.070068 2947.492676,2221.198242 
z"
        />
        <path
          fill="#7B98B0"
          opacity="1.000000"
          stroke="none"
          d="
M2606.961426,2171.964355 
	C2606.019531,2169.831299 2606.009766,2167.711670 2606.000000,2164.795898 
	C2608.759277,2160.830811 2612.641113,2160.270020 2616.025879,2161.501221 
	C2623.274170,2164.137451 2631.020996,2163.913330 2639.040527,2166.635742 
	C2640.625732,2168.074951 2640.989258,2169.176514 2640.442139,2171.216309 
	C2629.297852,2171.992432 2618.595947,2171.985107 2606.961426,2171.964355 
z"
        />
        <path
          fill="#7793AA"
          opacity="1.000000"
          stroke="none"
          d="
M2672.486816,2176.009277 
	C2681.366943,2174.266357 2687.701660,2180.374023 2695.322998,2181.066650 
	C2683.908203,2181.189209 2672.510010,2183.510254 2661.059326,2180.623535 
	C2664.104248,2177.348633 2667.609863,2176.032227 2672.486816,2176.009277 
z"
        />
        <path
          fill="#7793AA"
          opacity="1.000000"
          stroke="none"
          d="
M2640.888672,2172.000000 
	C2640.000000,2171.111084 2640.000000,2170.222168 2640.000000,2168.666504 
	C2643.919434,2162.684570 2649.149414,2161.275635 2656.073486,2163.375732 
	C2658.159180,2166.077393 2658.239746,2168.296875 2656.523682,2171.279297 
	C2651.265625,2171.993652 2646.521729,2171.996826 2640.888672,2172.000000 
z"
        />
        <path
          fill="#7B98B0"
          opacity="1.000000"
          stroke="none"
          d="
M2656.695801,2171.914551 
	C2656.008057,2169.864746 2656.006104,2167.739502 2656.006836,2164.816895 
	C2657.924805,2162.004639 2660.177979,2161.865723 2663.314697,2163.412842 
	C2663.999023,2165.497803 2663.998047,2166.995361 2663.993652,2169.241699 
	C2661.787354,2170.606689 2659.584473,2171.222900 2656.695801,2171.914551 
z"
        />
        <path
          fill="#7B98B0"
          opacity="1.000000"
          stroke="none"
          d="
M2697.323242,2152.014648 
	C2697.635498,2153.112305 2697.283691,2154.205322 2696.465332,2155.654053 
	C2695.110107,2156.008301 2694.221680,2156.006348 2692.666504,2156.007324 
	C2692.354248,2154.910645 2692.708252,2153.811523 2693.531250,2152.356201 
	C2694.886230,2152.003174 2695.772461,2152.006592 2697.323242,2152.014648 
z"
        />
        <path
          fill="#7694AF"
          opacity="1.000000"
          stroke="none"
          d="
M2638.273926,1955.267456 
	C2636.373535,1957.980347 2634.044678,1958.025269 2631.698242,1958.002319 
	C2623.412598,1957.921631 2615.237793,1957.844238 2607.239990,1961.078613 
	C2602.444336,1963.017944 2596.871582,1961.044434 2591.858398,1960.946777 
	C2584.260498,1960.798706 2576.887695,1962.983154 2569.348389,1962.057373 
	C2564.411133,1961.450806 2560.503906,1962.064819 2559.211182,1968.412964 
	C2558.179443,1973.479614 2552.298584,1970.983154 2548.525391,1973.482544 
	C2546.472168,1975.769775 2544.430420,1976.208496 2541.444824,1976.220703 
	C2537.563477,1975.249512 2534.509521,1974.956787 2530.769043,1976.936523 
	C2527.766113,1977.925537 2525.463379,1978.698120 2523.190674,1978.481812 
	C2495.834229,1975.878662 2468.407227,1978.404175 2441.035400,1977.252563 
	C2439.382324,1977.182983 2437.767090,1976.802368 2435.391113,1975.965698 
	C2431.290283,1971.096558 2427.240723,1971.339233 2421.798828,1971.518311 
	C2409.683594,1971.916870 2397.441162,1973.116699 2385.399658,1972.155396 
	C2368.128662,1970.776611 2350.953125,1971.191162 2333.743164,1972.026489 
	C2324.090332,1972.494873 2314.439453,1969.961426 2304.918701,1971.441650 
	C2289.091553,1973.902588 2273.344238,1973.032227 2257.518799,1972.132202 
	C2253.285645,1971.891479 2248.802002,1972.244141 2245.136963,1976.686890 
	C2237.910889,1979.972412 2231.455322,1977.081177 2224.175781,1976.704346 
	C2213.835938,1974.296143 2204.160889,1973.267090 2193.643066,1974.201416 
	C2190.702148,1974.879761 2188.474365,1975.547729 2185.463867,1975.440430 
	C2183.129883,1974.982544 2181.557861,1974.152100 2179.128662,1974.369629 
	C2177.593262,1974.572754 2176.703857,1974.663574 2175.119141,1974.767334 
	C2168.784424,1974.773804 2163.176270,1975.060181 2157.596436,1973.378418 
	C2154.488037,1972.441284 2150.535889,1971.918457 2147.838867,1973.830811 
	C2137.935059,1980.854004 2126.766113,1979.477539 2115.947510,1978.755859 
	C2100.279297,1977.710815 2084.730225,1979.755249 2069.115234,1979.487183 
	C2066.202393,1979.437256 2063.304688,1980.235596 2060.144043,1981.171021 
	C2065.279053,1983.769165 2069.600342,1984.922363 2075.176514,1982.466675 
	C2079.687744,1980.479858 2084.641846,1983.170410 2086.519043,1988.102173 
	C2088.498535,1993.302002 2084.698486,1996.222046 2080.555176,1998.041870 
	C2074.133789,2000.862305 2067.669678,2003.373413 2060.415283,2000.079956 
	C2054.171387,1997.245361 2047.515503,1997.812866 2040.778320,1998.411133 
	C2030.861206,1999.291870 2027.963989,1995.908813 2028.885742,1985.902100 
	C2024.641846,2000.598389 2017.402954,2004.500122 2002.169800,1999.340454 
	C1994.965576,1996.900146 1987.123657,1998.005737 1980.125488,1993.974243 
	C1976.235352,1991.733276 1973.147583,1996.735107 1969.740234,1998.552856 
	C1960.701416,2003.375122 1954.002930,2000.765259 1952.172119,1990.936035 
	C1944.217407,1997.273682 1936.081055,2000.016968 1926.456299,1994.583008 
	C1923.159180,1992.721680 1918.162842,1993.917603 1914.785767,1995.647217 
	C1903.015015,2001.676514 1890.532349,1996.909790 1878.546875,1998.787964 
	C1876.608032,1999.091797 1874.591431,1998.372925 1872.177246,1996.940552 
	C1869.038574,1990.695923 1868.575806,1985.019043 1872.926147,1978.956177 
	C1882.623779,1972.342163 1892.314697,1969.610718 1903.578369,1970.265503 
	C1923.759521,1971.438721 1944.182983,1970.520996 1964.177490,1972.460083 
	C1980.662354,1974.058960 1995.276245,1965.757935 2011.283325,1967.709595 
	C2014.149414,1968.058960 2017.346069,1966.575439 2020.186157,1967.380981 
	C2029.466675,1970.012817 2035.409058,1966.761963 2039.242188,1957.369141 
	C2045.590088,1955.205322 2051.231689,1956.756958 2057.747314,1957.035767 
	C2085.143555,1959.442383 2111.738525,1958.771851 2138.318359,1958.631958 
	C2166.576172,1958.483398 2194.843994,1959.298340 2223.091797,1957.683350 
	C2226.489014,1957.489014 2229.886963,1957.624634 2232.594482,1955.506592 
	C2242.827881,1947.500732 2254.755615,1947.547485 2267.604004,1948.128174 
	C2268.885742,1948.548706 2269.361328,1948.785767 2270.363037,1949.590820 
	C2270.641357,1959.650146 2269.353271,1960.488892 2257.427246,1959.063354 
	C2265.981689,1961.083984 2274.029785,1960.673706 2281.405029,1953.654907 
	C2285.123535,1952.285278 2288.078369,1952.985474 2291.677002,1954.354736 
	C2295.355713,1958.665527 2299.351562,1958.877563 2303.911865,1958.170044 
	C2325.420166,1954.833008 2346.868408,1960.585815 2367.174316,1957.777954 
	C2360.699707,1955.105347 2347.747314,1960.502563 2346.648438,1945.276611 
	C2347.412354,1943.796509 2348.117432,1943.202515 2349.711182,1942.744385 
	C2352.643066,1942.780762 2354.896973,1943.058594 2357.774414,1941.991455 
	C2366.177979,1941.549316 2373.775146,1943.130371 2382.225342,1943.172363 
	C2388.843506,1944.237305 2394.924316,1950.576416 2401.148193,1942.161865 
	C2411.727051,1939.349121 2421.826172,1939.734375 2431.621094,1940.747192 
	C2470.632568,1944.780884 2509.758301,1943.950073 2548.851807,1944.298950 
	C2561.466309,1944.411621 2573.949951,1947.065063 2587.426270,1945.939941 
	C2598.027344,1952.516357 2608.469971,1951.808594 2619.841064,1948.769897 
	C2624.521973,1948.085815 2628.477783,1948.191772 2633.153809,1948.702881 
	C2636.180908,1950.155151 2637.779541,1951.976196 2638.273926,1955.267456 
z"
        />
        <path
          fill="#7B98B0"
          opacity="1.000000"
          stroke="none"
          d="
M2852.511719,2037.498047 
	C2840.624023,2036.940430 2830.834961,2031.368530 2820.662842,2026.932007 
	C2818.681641,2026.067871 2818.051270,2024.018066 2818.449707,2021.834473 
	C2818.802979,2019.897705 2819.659180,2017.934692 2819.510742,2016.044800 
	C2818.873779,2007.940063 2815.945801,2006.374146 2808.047607,2008.817383 
	C2803.260010,2010.298218 2798.699219,2011.343628 2795.532715,2016.010132 
	C2793.470215,2019.049927 2790.755615,2017.877441 2790.117188,2014.274170 
	C2788.939697,2007.632324 2784.642334,2005.766602 2778.583252,2005.966553 
	C2772.361816,2006.171997 2766.421143,2002.275635 2759.951904,2004.955811 
	C2754.045410,2007.402832 2747.418701,2004.856934 2740.375977,2007.123413 
	C2744.920410,2013.290527 2742.295410,2021.539917 2748.637451,2026.806030 
	C2750.176758,2028.084351 2747.980713,2029.697632 2746.180908,2029.784912 
	C2740.871094,2030.042969 2738.656982,2024.722534 2734.309814,2023.050903 
	C2728.901367,2020.971313 2729.480957,2016.520996 2730.963379,2012.151123 
	C2732.529297,2007.536865 2730.415039,2005.898315 2726.195557,2006.053955 
	C2720.853027,2006.251221 2714.874756,2003.500488 2710.611816,2004.948975 
	C2704.312012,2007.089722 2697.663574,2007.309814 2690.785156,2009.891113 
	C2686.749756,2009.738281 2684.239014,2008.809692 2683.545654,2014.287720 
	C2682.205322,2024.878052 2676.900879,2026.838623 2668.140625,2021.391235 
	C2656.666748,2014.256348 2643.255127,2009.820801 2631.274658,2015.170776 
	C2620.418457,2020.018433 2611.585449,2021.046997 2600.882324,2016.985718 
	C2596.427734,2015.295288 2591.961182,2018.834473 2588.419922,2022.035034 
	C2584.163818,2025.881714 2579.265381,2027.772217 2573.713135,2026.130249 
	C2567.622070,2024.328857 2567.223877,2020.707397 2571.080078,2015.842285 
	C2567.636963,2016.971680 2563.900879,2016.573486 2560.716064,2014.072510 
	C2548.797852,2004.713013 2535.602539,2005.773071 2522.132080,2009.298584 
	C2519.470215,2009.995239 2516.642334,2010.753052 2514.341553,2009.342285 
	C2505.165283,2003.715332 2495.052002,2005.173584 2485.138428,2004.988647 
	C2481.805176,2004.926392 2478.466797,2004.910645 2475.203125,2004.118530 
	C2464.875244,2001.611938 2462.265869,1995.817871 2467.457520,1985.211304 
	C2472.488037,1982.455322 2476.958984,1983.124634 2482.093262,1984.934082 
	C2486.056152,1986.545532 2488.838135,1990.783081 2493.622070,1986.718506 
	C2496.643066,1985.176514 2499.195557,1984.376465 2502.619629,1984.555420 
	C2505.269531,1985.133789 2506.945801,1986.823242 2509.851807,1986.137939 
	C2511.748047,1985.922607 2513.012451,1985.985840 2514.884521,1986.346924 
	C2517.444092,1987.287964 2519.406494,1987.856934 2522.136719,1986.701172 
	C2531.926025,1983.866211 2541.189941,1984.507690 2551.178223,1985.500488 
	C2555.127441,1986.804688 2558.160156,1989.862793 2562.514404,1986.454590 
	C2565.182617,1985.765625 2567.097656,1985.884766 2569.428223,1987.501465 
	C2571.683594,1989.988281 2574.009521,1989.885864 2576.324951,1990.010986 
	C2588.435059,1990.665039 2600.545410,1990.079590 2612.715088,1991.009766 
	C2620.862061,1991.632446 2629.632568,1990.149292 2639.070801,1990.012695 
	C2640.850830,1991.044312 2641.899414,1991.343140 2643.578857,1990.441162 
	C2656.778076,1991.929565 2669.632812,1989.712524 2682.567383,1991.022949 
	C2689.863525,1991.762085 2698.167480,1992.464355 2705.881104,1990.407715 
	C2707.147705,1990.070068 2708.644287,1989.736572 2709.830566,1990.088623 
	C2722.799805,1993.938110 2736.091064,1987.472290 2749.235352,1991.666016 
	C2754.382080,1993.308105 2760.483154,1991.959106 2767.074219,1991.968384 
	C2770.307617,1992.594849 2772.567627,1993.664307 2775.523926,1992.371338 
	C2783.385742,1992.001709 2790.771729,1992.003540 2799.078613,1992.002686 
	C2800.886963,1992.002563 2801.773682,1992.006958 2803.325684,1992.020386 
	C2808.208984,1992.271729 2812.565674,1991.227783 2817.293945,1993.702881 
	C2820.375000,1999.015625 2823.689209,2002.390381 2829.508057,2003.860107 
	C2836.104004,2005.526245 2841.459717,2009.716675 2844.325195,2016.235474 
	C2845.748779,2019.473511 2847.952637,2021.452881 2851.109863,2023.068115 
	C2859.468994,2027.344116 2859.620117,2029.277466 2852.511719,2037.498047 
z"
        />
        <path
          fill="#69849B"
          opacity="1.000000"
          stroke="none"
          d="
M2570.406738,1987.455322 
	C2567.856445,1988.006958 2565.732422,1988.005371 2562.811035,1988.014160 
	C2560.018066,1989.646729 2557.853516,1990.146362 2554.716797,1988.508301 
	C2543.217041,1988.703247 2532.275391,1986.312988 2520.826416,1989.893677 
	C2518.812744,1989.969727 2517.739014,1989.610718 2516.393066,1988.453857 
	C2514.487549,1988.023804 2512.998047,1987.995361 2510.764160,1987.962769 
	C2508.272461,1987.954712 2506.508545,1988.038574 2504.464111,1986.554443 
	C2501.129883,1986.748657 2498.300781,1987.360229 2494.740723,1987.993164 
	C2491.394531,1990.194092 2488.538818,1990.137329 2484.775391,1988.480469 
	C2479.316650,1986.664429 2474.601074,1985.578125 2468.839600,1986.051514 
	C2462.373535,1988.735107 2456.322510,1988.488892 2450.388428,1988.141846 
	C2422.493896,1986.509644 2394.767578,1990.270996 2366.132568,1990.339844 
	C2360.150146,1985.659058 2354.369629,1988.693359 2348.020020,1988.881470 
	C2338.439453,1988.831299 2329.708984,1991.459351 2320.078613,1989.263672 
	C2306.007324,1990.614502 2292.878174,1986.634644 2278.836426,1988.383301 
	C2276.508789,1988.710815 2274.964355,1988.717896 2272.706055,1988.566406 
	C2269.200928,1987.812378 2266.761963,1988.386963 2264.142822,1990.822754 
	C2262.629395,1991.330200 2261.724854,1991.346436 2260.183838,1990.940674 
	C2257.250244,1987.563110 2254.146729,1986.381592 2249.668945,1986.867065 
	C2248.523926,1986.869995 2248.061035,1986.877808 2246.895020,1986.910522 
	C2244.729004,1986.984619 2243.325928,1987.430420 2241.104004,1987.643799 
	C2239.800049,1987.668091 2239.271973,1987.635132 2237.961182,1987.424561 
	C2224.395264,1986.302979 2211.611084,1988.183594 2198.012695,1987.133057 
	C2191.268311,1988.104980 2185.620117,1984.667847 2178.904297,1986.212891 
	C2172.152832,1988.122559 2166.169678,1989.443970 2159.321533,1986.488525 
	C2157.617676,1985.803345 2156.714600,1985.400024 2155.741943,1985.232178 
	C2150.931885,1984.402710 2144.299072,1984.124756 2145.558594,1977.549805 
	C2146.722656,1971.477173 2153.346436,1972.880493 2158.090332,1973.343506 
	C2163.636230,1973.884644 2169.116943,1974.696655 2175.302246,1972.527588 
	C2176.894287,1972.021606 2177.779053,1972.029541 2179.326904,1972.041992 
	C2181.096680,1972.336914 2181.990479,1973.032104 2183.484619,1973.767822 
	C2186.220215,1973.344116 2188.421143,1972.754761 2191.306396,1972.092529 
	C2201.339844,1974.399048 2210.892090,1973.876099 2221.199219,1974.014771 
	C2228.878662,1973.356079 2235.544434,1974.953125 2243.104980,1975.917236 
	C2250.681152,1970.431030 2258.746094,1972.415894 2266.070068,1972.899048 
	C2287.657227,1974.323242 2309.157471,1971.655151 2330.762207,1972.728271 
	C2362.478027,1974.303833 2394.199219,1970.979370 2425.944580,1971.005371 
	C2428.354004,1971.007324 2430.558594,1971.436035 2433.279297,1973.399170 
	C2450.198242,1973.989502 2466.395020,1974.319580 2482.572266,1973.893677 
	C2495.363281,1973.557007 2508.094482,1972.996582 2520.716309,1975.712280 
	C2524.126465,1976.446045 2527.056152,1974.002808 2531.191895,1974.033691 
	C2534.220459,1974.003662 2536.443359,1973.999512 2539.333008,1974.016724 
	C2542.133301,1974.015869 2544.266602,1974.009888 2547.199707,1974.008911 
	C2554.211182,1977.009033 2560.886963,1977.579224 2567.644043,1978.143799 
	C2573.814209,1978.659180 2574.563721,1981.126831 2570.406738,1987.455322 
z"
        />
        <path
          fill="#5A6668"
          opacity="1.000000"
          stroke="none"
          d="
M2768.775146,1932.174072 
	C2766.447754,1932.072388 2764.943359,1932.035278 2762.691406,1932.087646 
	C2757.125732,1929.252075 2757.125732,1929.252075 2752.964111,1920.524902 
	C2749.014160,1925.528564 2749.187744,1932.241455 2744.132812,1936.604248 
	C2737.894043,1937.685791 2732.482422,1937.145874 2726.809082,1934.086914 
	C2723.951416,1927.930420 2721.049561,1926.429199 2716.410400,1932.561035 
	C2715.302734,1932.784302 2714.839600,1932.762573 2713.757812,1932.413574 
	C2708.710938,1927.097778 2707.101562,1921.186890 2707.325928,1914.632935 
	C2707.382568,1912.980225 2707.205322,1911.186646 2705.424561,1910.405029 
	C2703.605469,1909.606567 2702.030029,1910.467041 2701.303223,1912.094482 
	C2697.535645,1920.529785 2688.847900,1924.629517 2683.375488,1932.472290 
	C2679.476318,1934.882080 2675.772217,1935.590698 2671.278809,1935.952515 
	C2670.131836,1935.845215 2669.682129,1935.756714 2668.602295,1935.414917 
	C2666.414062,1934.280884 2665.259766,1932.905884 2663.378906,1931.360840 
	C2662.328613,1930.302734 2661.800537,1929.675537 2660.978027,1928.640625 
	C2660.642334,1928.267212 2659.821045,1927.844238 2659.359375,1927.933105 
	C2657.980469,1928.542114 2657.035645,1928.990845 2655.368652,1929.124756 
	C2654.359131,1928.937744 2653.962158,1928.783447 2653.059082,1928.210449 
	C2649.204102,1922.709839 2647.543213,1917.341064 2648.502686,1913.419434 
	C2646.902344,1917.457520 2645.175537,1924.131104 2640.462402,1930.079590 
	C2636.028076,1930.317871 2635.552979,1927.029175 2634.059570,1923.798950 
	C2632.804199,1920.152710 2633.936523,1916.587891 2630.874023,1913.529785 
	C2628.020264,1909.984741 2627.153076,1906.174561 2624.968750,1902.213379 
	C2624.305176,1897.139648 2626.902832,1894.862549 2631.162109,1892.960938 
	C2640.572998,1891.784912 2648.248047,1886.209229 2658.113037,1887.290527 
	C2666.367188,1888.801392 2673.668457,1891.456543 2682.212402,1890.768188 
	C2686.675049,1890.612549 2686.507568,1887.932007 2686.801758,1885.240601 
	C2687.295410,1880.723511 2686.323730,1876.063110 2688.453613,1871.054443 
	C2694.759766,1866.838867 2695.442627,1871.333130 2696.174316,1875.368286 
	C2696.927490,1879.520752 2696.234619,1884.112915 2700.656738,1887.593140 
	C2702.008057,1888.399292 2702.364502,1890.189819 2704.370605,1888.630127 
	C2706.222656,1887.806274 2707.561768,1887.590210 2709.637451,1887.565918 
	C2717.307373,1889.554688 2722.189941,1896.808716 2731.128174,1895.632324 
	C2732.259277,1895.905518 2732.703125,1896.047607 2733.777832,1896.485352 
	C2735.982422,1897.695923 2737.304932,1899.057983 2739.822754,1899.708252 
	C2748.853516,1905.794312 2758.767090,1903.460327 2768.697754,1905.154419 
	C2773.591797,1905.437012 2776.996338,1906.671265 2777.922363,1912.182007 
	C2776.085205,1919.815552 2775.569580,1927.215088 2768.775146,1932.174072 
z"
        />
        <path
          fill="#5A6668"
          opacity="1.000000"
          stroke="none"
          d="
M2891.591797,1903.605469 
	C2894.301270,1905.154785 2896.556641,1906.981812 2895.563721,1909.322510 
	C2893.027832,1915.300415 2896.933838,1917.619507 2901.235840,1920.483643 
	C2903.265137,1917.381104 2906.864014,1915.222046 2905.314453,1910.401855 
	C2903.646729,1905.213135 2907.791260,1906.384277 2911.206543,1906.064697 
	C2914.393311,1902.967163 2917.735840,1903.771606 2921.017822,1903.832642 
	C2929.247070,1903.985352 2937.414307,1905.248047 2945.663086,1905.034668 
	C2950.676758,1904.904785 2955.813721,1904.960938 2958.612793,1910.202271 
	C2961.545898,1915.695679 2958.947266,1920.378296 2955.927734,1924.874512 
	C2955.005371,1926.248169 2953.831055,1927.409790 2951.778564,1928.726929 
	C2947.520020,1929.304688 2944.137695,1929.446167 2942.824219,1933.941895 
	C2942.028320,1936.664917 2940.417969,1939.254517 2936.919434,1940.748047 
	C2934.517578,1937.680054 2931.470459,1938.129028 2928.642334,1938.068604 
	C2924.493408,1937.980225 2920.343018,1937.959595 2915.301514,1937.889648 
	C2911.497559,1936.774902 2912.242432,1933.236572 2909.580078,1931.021851 
	C2908.110596,1933.033936 2909.785156,1936.115967 2907.052002,1937.913818 
	C2905.493896,1938.209717 2904.480957,1937.960693 2903.532471,1938.113159 
	C2896.153564,1939.297485 2890.798828,1936.556519 2887.816162,1929.725586 
	C2884.780762,1931.250732 2887.085205,1934.286011 2885.226562,1935.818237 
	C2877.043457,1929.168579 2876.447266,1928.713135 2867.352783,1934.437134 
	C2862.816895,1937.291748 2858.458496,1938.200317 2852.620605,1937.890381 
	C2847.642578,1928.980469 2847.509521,1919.896973 2850.844238,1909.977295 
	C2851.406982,1908.331909 2851.839844,1907.444458 2852.885742,1906.062744 
	C2854.241455,1905.018066 2855.152588,1904.692505 2856.848389,1904.593750 
	C2863.395020,1906.253418 2868.441162,1903.318359 2873.824951,1902.025879 
	C2879.573486,1900.645996 2885.748291,1896.845825 2891.591797,1903.605469 
z"
        />
        <path
          fill="#859CAF"
          opacity="1.000000"
          stroke="none"
          d="
M2852.899414,2038.000000 
	C2852.114990,2037.350098 2852.092773,2036.635376 2852.364746,2036.059814 
	C2856.117920,2028.118042 2856.130371,2028.115112 2848.155762,2025.665894 
	C2844.804443,2024.636597 2842.344482,2023.116943 2841.939453,2019.016602 
	C2841.160889,2011.131958 2834.261230,2009.115479 2828.561035,2006.202759 
	C2826.895508,2005.351685 2824.643799,2005.739014 2822.754883,2005.217651 
	C2816.096924,2003.380005 2816.053467,2003.206665 2817.913330,1994.756592 
	C2825.807617,1992.509644 2833.575439,1993.154053 2841.444336,1993.233398 
	C2853.781250,1993.357910 2866.388916,1995.651489 2878.645508,1992.645630 
	C2884.211182,1991.280640 2888.962402,1995.172119 2895.140625,1994.016235 
	C2898.375977,1996.781006 2902.138916,1995.541382 2905.456055,1997.562012 
	C2906.235596,2003.641724 2903.576660,2007.168823 2898.658691,2009.846436 
	C2893.932861,2012.419678 2893.996094,2013.065186 2899.080811,2016.172729 
	C2902.466797,2018.242188 2900.964111,2019.894897 2899.234619,2021.534546 
	C2895.217041,2025.343140 2885.092529,2022.901245 2882.311768,2017.573486 
	C2879.166992,2011.548584 2876.638428,2010.802734 2871.306396,2014.479370 
	C2868.640137,2016.317627 2867.353760,2018.311157 2871.359375,2020.175293 
	C2874.186768,2021.491089 2873.727539,2023.583740 2872.962891,2026.012817 
	C2869.195801,2037.980591 2869.237305,2037.993652 2852.899414,2038.000000 
z"
        />
        <path
          fill="#5A6668"
          opacity="1.000000"
          stroke="none"
          d="
M2820.345215,1935.509155 
	C2809.822998,1936.031494 2799.810059,1936.072266 2788.863525,1936.204468 
	C2784.703857,1933.446899 2784.176270,1929.373779 2783.485352,1925.561279 
	C2781.881348,1916.713379 2784.111816,1913.532471 2794.060059,1910.304688 
	C2798.651611,1908.821899 2802.517334,1908.883545 2807.177002,1909.333252 
	C2810.364990,1909.742065 2812.675049,1911.096558 2815.939941,1909.986572 
	C2817.663818,1909.651489 2818.666992,1909.549316 2820.449707,1909.514893 
	C2826.311523,1910.660278 2831.523682,1910.679932 2837.479492,1911.293335 
	C2839.348145,1911.584106 2840.360107,1911.943848 2841.953613,1912.969727 
	C2845.194336,1917.887085 2844.326904,1921.600464 2839.672852,1925.012573 
	C2833.754639,1925.365112 2832.085693,1930.442261 2827.846191,1933.051758 
	C2826.256836,1933.371704 2825.340820,1933.271973 2823.881836,1932.542358 
	C2821.836426,1930.468262 2823.018066,1928.053101 2822.434082,1925.948853 
	C2822.353760,1929.342407 2822.500488,1932.268677 2820.345215,1935.509155 
z"
        />
        <path
          fill="#959693"
          opacity="1.000000"
          stroke="none"
          d="
M2796.447510,1911.976318 
	C2783.261230,1915.870239 2785.301025,1915.008911 2787.456299,1928.724121 
	C2787.757324,1930.641235 2787.804443,1932.598267 2787.949219,1935.416382 
	C2789.153564,1945.783081 2791.374268,1955.021118 2793.996338,1964.469482 
	C2778.236084,1974.228027 2777.571777,1974.261353 2770.142578,1964.537598 
	C2768.750488,1962.515869 2768.490479,1960.987671 2769.461426,1958.637939 
	C2769.912842,1953.381348 2769.926025,1948.810547 2769.899658,1943.389404 
	C2770.421143,1941.670044 2771.196777,1941.131958 2772.952637,1941.120361 
	C2778.272705,1944.973022 2775.219727,1949.668945 2775.124756,1954.026855 
	C2784.407959,1950.710938 2785.272949,1944.447388 2778.808105,1925.349854 
	C2776.970215,1921.742188 2777.354004,1918.312622 2776.083496,1914.551025 
	C2776.393311,1908.949951 2773.525879,1907.583374 2768.529297,1907.494385 
	C2764.496338,1904.166260 2764.855225,1900.109863 2765.285645,1896.189819 
	C2765.691162,1892.492798 2766.575195,1888.443481 2771.487793,1888.889038 
	C2776.176270,1889.314331 2775.512207,1893.252563 2775.357910,1896.560791 
	C2775.996094,1896.670532 2776.445557,1896.329712 2776.652832,1895.525391 
	C2777.665771,1891.593750 2776.055664,1884.377563 2780.620117,1884.663208 
	C2786.162842,1885.010132 2781.800293,1891.727295 2782.915283,1895.471069 
	C2783.335693,1896.883179 2783.033936,1898.529907 2783.052490,1900.070190 
	C2782.900391,1886.708496 2785.775146,1881.850952 2796.676758,1878.670898 
	C2801.948730,1882.812012 2797.004883,1887.070068 2797.780029,1891.830322 
	C2797.577637,1893.850098 2797.262451,1895.132812 2796.434082,1896.193604 
	C2794.958496,1898.084106 2789.495117,1899.588379 2796.870361,1901.676514 
	C2799.477539,1902.414551 2799.218506,1908.066895 2796.447510,1911.976318 
z"
        />
        <path
          fill="#959693"
          opacity="1.000000"
          stroke="none"
          d="
M2946.047363,1930.021606 
	C2953.366699,1927.637817 2954.468506,1920.953247 2957.389160,1916.004395 
	C2959.138672,1913.040161 2955.827637,1908.869629 2951.838623,1908.320190 
	C2948.888184,1907.913940 2945.857178,1908.019409 2942.862305,1908.026489 
	C2934.893555,1908.045288 2926.869629,1908.654053 2919.214355,1905.639038 
	C2917.179688,1904.837891 2915.289062,1905.599121 2912.649902,1906.046021 
	C2912.236572,1903.934937 2911.362305,1901.095703 2914.896973,1900.836914 
	C2928.422363,1899.846680 2941.833252,1900.705933 2955.134033,1903.364990 
	C2961.399902,1904.617676 2967.745117,1903.798584 2974.964355,1903.945312 
	C2977.673096,1904.765747 2978.229980,1906.328491 2978.064453,1908.772705 
	C2977.726562,1909.689453 2977.634277,1910.002808 2977.526855,1910.311035 
	C2973.331299,1922.353271 2973.331055,1922.353149 2963.298584,1927.987061 
	C2960.379883,1931.618896 2959.442383,1935.649780 2956.624512,1939.346558 
	C2954.836426,1939.925781 2953.470947,1939.992554 2951.422363,1940.088867 
	C2946.068359,1938.491089 2944.235352,1935.328979 2946.047363,1930.021606 
z"
        />
        <path
          fill="#7694AF"
          opacity="1.000000"
          stroke="none"
          d="
M2739.924316,1948.869873 
	C2740.502930,1955.489014 2744.145752,1953.384766 2747.295898,1952.602417 
	C2749.513916,1952.051636 2751.305176,1952.731689 2751.656982,1955.226196 
	C2752.049316,1958.006104 2750.724854,1959.615723 2747.863037,1959.840088 
	C2747.533203,1959.865845 2747.176270,1959.976196 2746.876465,1959.890991 
	C2735.947998,1956.789551 2724.618164,1962.801758 2713.716553,1958.412231 
	C2711.848389,1957.660034 2709.843018,1958.513794 2707.897217,1958.986816 
	C2701.377930,1960.570923 2694.772949,1960.626465 2688.278809,1959.024780 
	C2683.330811,1957.804565 2678.544678,1957.598877 2673.641113,1959.108887 
	C2669.744873,1960.308716 2665.820801,1960.919067 2662.270020,1956.632324 
	C2661.300293,1954.040039 2662.143066,1952.536621 2664.128906,1950.659302 
	C2678.666992,1945.460327 2693.031982,1947.210815 2707.172607,1946.696167 
	C2716.739258,1946.348022 2726.357422,1946.118042 2736.792236,1947.309448 
	C2738.190186,1947.772583 2738.726318,1948.024780 2739.924316,1948.869873 
z"
        />
        <path
          fill="#E7E3DA"
          opacity="1.000000"
          stroke="none"
          d="
M2742.689453,1936.187500 
	C2747.168945,1928.939697 2747.233887,1920.418457 2753.511230,1913.879028 
	C2757.529297,1919.397583 2756.381836,1926.591064 2761.390869,1931.693726 
	C2762.208984,1936.316895 2759.912109,1938.773926 2756.409180,1939.999146 
	C2756.569580,1941.076538 2756.455078,1941.831787 2756.779541,1942.220581 
	C2758.091064,1943.791504 2761.815918,1943.449585 2760.852539,1946.704590 
	C2760.241211,1948.769897 2757.847656,1949.047485 2755.928467,1949.143799 
	C2751.384033,1949.372437 2746.829102,1949.389893 2741.432129,1949.477783 
	C2740.586182,1949.462769 2740.403076,1949.560425 2740.375977,1949.660522 
	C2735.932129,1944.767334 2738.034180,1940.599731 2742.689453,1936.187500 
z"
        />
        <path
          fill="#959693"
          opacity="1.000000"
          stroke="none"
          d="
M2835.267090,1924.750488 
	C2841.416748,1923.993774 2841.851074,1920.082031 2841.863770,1915.095703 
	C2842.752686,1907.444214 2847.233643,1907.472778 2853.145508,1909.394531 
	C2851.953857,1916.838379 2848.990479,1923.650391 2853.112061,1930.840454 
	C2854.192383,1932.724731 2852.520020,1934.632446 2851.931152,1937.207031 
	C2847.488037,1937.806152 2843.201416,1937.723145 2837.996094,1937.486938 
	C2834.076416,1933.774170 2832.870361,1929.862427 2835.267090,1924.750488 
z"
        />
        <path
          fill="#8C8D89"
          opacity="1.000000"
          stroke="none"
          d="
M2664.120361,1951.049805 
	C2663.458740,1952.862915 2662.919678,1953.899658 2662.122070,1955.463623 
	C2654.545166,1955.986328 2647.227295,1955.981934 2638.998535,1955.983887 
	C2637.068604,1954.193237 2634.622559,1953.534424 2634.193359,1950.585693 
	C2634.057129,1947.197876 2635.442139,1945.141968 2638.055176,1942.980713 
	C2640.248047,1942.096802 2641.791748,1942.044067 2644.107910,1942.555176 
	C2647.648193,1945.182495 2651.104248,1944.601196 2654.365967,1944.968750 
	C2658.335693,1945.416260 2662.511230,1945.605957 2664.120361,1951.049805 
z"
        />
        <path
          fill="#D3CBBF"
          opacity="1.000000"
          stroke="none"
          d="
M2775.501953,1914.458008 
	C2781.010254,1914.793701 2780.184082,1918.754272 2780.201660,1923.061523 
	C2780.086426,1930.604614 2784.698486,1938.507080 2774.655273,1942.821533 
	C2772.904785,1942.479614 2772.032471,1942.386475 2770.510010,1942.416016 
	C2767.029541,1940.010864 2768.532471,1936.656616 2768.059570,1932.884155 
	C2770.335205,1926.419434 2772.718262,1920.729370 2775.501953,1914.458008 
z"
        />
        <path
          fill="#D7D5CF"
          opacity="1.000000"
          stroke="none"
          d="
M2946.553223,1928.843018 
	C2944.760254,1933.879150 2949.858643,1935.438965 2950.508301,1939.491455 
	C2946.942871,1947.179321 2941.858398,1947.916992 2936.376465,1941.508667 
	C2939.958008,1936.443604 2941.347412,1930.899170 2943.679688,1925.815918 
	C2944.066406,1924.973389 2945.069824,1924.186523 2945.872314,1925.307739 
	C2946.390869,1926.032593 2946.334717,1927.168701 2946.553223,1928.843018 
z"
        />
        <path
          fill="#EFF1EA"
          opacity="1.000000"
          stroke="none"
          d="
M2996.015137,1938.558594 
	C2997.900879,1934.244873 2999.472900,1929.970825 3003.094727,1926.444458 
	C3005.610352,1929.790771 3004.229492,1933.584839 3005.786621,1937.395386 
	C3002.880371,1943.004028 3002.635010,1943.029175 2996.015137,1938.558594 
z"
        />
        <path
          fill="#AAB7BE"
          opacity="1.000000"
          stroke="none"
          d="
M2906.925781,1938.690674 
	C2908.160645,1934.540894 2909.069580,1931.082397 2901.978027,1929.599609 
	C2908.083740,1931.447021 2910.194580,1928.401978 2912.312744,1923.380615 
	C2914.639160,1928.604736 2913.358154,1932.590332 2914.259766,1937.049927 
	C2912.364746,1939.984497 2909.989990,1940.229736 2906.925781,1938.690674 
z"
        />
        <path
          fill="#D7D5CF"
          opacity="1.000000"
          stroke="none"
          d="
M2956.824707,1939.950684 
	C2956.241943,1935.139282 2955.297119,1929.954956 2962.347168,1928.399536 
	C2964.175293,1931.775146 2965.659180,1934.891235 2965.846436,1939.117920 
	C2963.126709,1941.064697 2960.295166,1940.896606 2956.824707,1939.950684 
z"
        />
        <path
          fill="#E7E3DA"
          opacity="1.000000"
          stroke="none"
          d="
M2835.440430,1923.737305 
	C2834.783203,1928.272461 2837.752197,1931.871948 2837.211426,1936.623291 
	C2835.373291,1938.606445 2833.489258,1938.387939 2830.865723,1937.934326 
	C2827.759033,1937.234131 2826.799316,1935.650146 2827.495605,1932.586182 
	C2830.690674,1929.387695 2829.629150,1923.648560 2835.440430,1923.737305 
z"
        />
        <path
          fill="#859CAF"
          opacity="1.000000"
          stroke="none"
          d="
M2934.505859,2008.336914 
	C2926.457031,2006.534180 2926.457031,2006.534180 2926.001465,1996.794067 
	C2926.888916,1995.977539 2927.777832,1995.974609 2929.333496,1995.973877 
	C2930.289795,1998.718994 2931.902588,1999.876587 2934.570068,1999.985229 
	C2942.717529,2000.317139 2936.878174,2004.146606 2936.099121,2007.330200 
	C2935.654297,2008.211670 2935.328125,2008.442871 2934.505859,2008.336914 
z"
        />
        <path
          fill="#7995AC"
          opacity="1.000000"
          stroke="none"
          d="
M2906.645508,1998.009033 
	C2902.693848,1997.409668 2898.671387,1999.605957 2896.157227,1994.745605 
	C2901.033203,1994.347900 2906.081787,1994.671387 2911.565186,1995.492554 
	C2910.880859,1997.687378 2909.157471,1997.973755 2906.645508,1998.009033 
z"
        />
        <path
          fill="#7793AA"
          opacity="1.000000"
          stroke="none"
          d="
M2775.404785,1991.733643 
	C2773.318359,1996.944092 2772.564453,1997.073730 2768.139160,1992.646484 
	C2770.082275,1989.234375 2772.435791,1990.213745 2775.404785,1991.733643 
z"
        />
        <path
          fill="#D7D5CF"
          opacity="1.000000"
          stroke="none"
          d="
M2820.534668,1936.100342 
	C2819.902832,1932.227173 2819.894287,1928.438110 2823.125244,1925.631104 
	C2824.657227,1927.018799 2824.326904,1928.736694 2824.607910,1930.963989 
	C2825.109619,1932.667480 2825.207764,1933.735596 2824.499512,1935.517334 
	C2823.105469,1936.202515 2822.169678,1936.206177 2820.534668,1936.100342 
z"
        />
        <path
          fill="#959693"
          opacity="1.000000"
          stroke="none"
          d="
M2824.318359,1936.716553 
	C2824.131348,1935.176147 2824.221191,1934.153564 2824.487549,1932.380981 
	C2825.384033,1931.713867 2826.103760,1931.796753 2827.366699,1931.904175 
	C2828.159424,1933.628052 2828.095947,1935.424316 2829.782471,1937.331299 
	C2827.940674,1940.758667 2826.258545,1939.079346 2824.318359,1936.716553 
z"
        />
        <path
          fill="#7694AF"
          opacity="1.000000"
          stroke="none"
          d="
M2769.187012,1957.987915 
	C2769.908447,1959.443604 2769.916992,1960.935059 2769.866211,1963.167236 
	C2767.630371,1963.767212 2764.861572,1964.332031 2764.544189,1961.159180 
	C2764.288086,1958.601807 2766.473877,1958.224365 2769.187012,1957.987915 
z"
        />
        <path
          fill="#7995AC"
          opacity="1.000000"
          stroke="none"
          d="
M2947.634766,1993.576904 
	C2946.855225,1995.701904 2945.141357,1995.978271 2942.639160,1995.971680 
	C2943.212891,1994.035767 2944.180176,1991.582642 2947.634766,1993.576904 
z"
        />
        <path
          fill="#7995AC"
          opacity="1.000000"
          stroke="none"
          d="
M3009.361328,1996.040405 
	C3009.808838,1997.831543 3009.246582,1999.349365 3006.627441,1999.903320 
	C3006.000000,1999.113159 3006.000000,1998.226440 3006.000000,1996.674683 
	C3006.896484,1996.012451 3007.792725,1996.015015 3009.361328,1996.040405 
z"
        />
        <path
          fill="#7995AC"
          opacity="1.000000"
          stroke="none"
          d="
M2973.333496,1996.003784 
	C2973.796143,1997.287842 2973.687012,1998.740967 2972.080811,1998.955444 
	C2970.961426,1999.104858 2970.460205,1998.138550 2970.089844,1996.580811 
	C2970.888916,1996.000854 2971.777832,1995.996948 2973.333496,1996.003784 
z"
        />
        <path
          fill="#7793AA"
          opacity="1.000000"
          stroke="none"
          d="
M2803.691895,1991.597778 
	C2803.111328,1993.328369 2802.065674,1993.941650 2800.303223,1992.423340 
	C2800.876709,1990.685303 2801.923096,1990.063354 2803.691895,1991.597778 
z"
        />
        <path
          fill="#7793AA"
          opacity="1.000000"
          stroke="none"
          d="
M2643.334961,1990.012939 
	C2643.799561,1991.291138 2643.686279,1992.738403 2642.086670,1992.958008 
	C2640.969727,1993.111328 2640.465088,1992.150635 2640.085938,1990.599243 
	C2640.884033,1990.019409 2641.775391,1990.012817 2643.334961,1990.012939 
z"
        />
        <path
          fill="#152533"
          opacity="1.000000"
          stroke="none"
          d="
M232.113052,3437.704590 
	C231.512665,3441.235596 229.622818,3442.124512 226.986282,3441.815186 
	C224.438019,3441.516357 221.846466,3441.266846 219.350281,3441.010498 
	C210.929794,3440.145996 204.382172,3443.238037 198.313812,3448.427979 
	C197.673904,3448.975098 196.565674,3448.974609 195.855682,3449.176514 
	C193.046814,3447.467773 197.002548,3444.452637 194.023788,3442.807617 
	C193.301682,3443.061523 192.219208,3443.146240 191.578644,3443.722412 
	C189.615860,3445.487549 178.420609,3452.651855 176.401123,3453.698486 
	C170.456772,3456.779785 172.747360,3451.601807 172.006378,3448.732910 
	C175.361206,3447.953857 177.401215,3445.666992 179.474960,3443.514648 
	C181.658081,3441.249023 184.143188,3439.903076 187.395599,3439.967529 
	C189.685699,3440.012939 192.399887,3440.286133 193.538635,3437.711670 
	C195.287125,3433.759033 198.604965,3434.199707 201.933441,3433.947021 
	C206.397247,3433.608154 211.922379,3435.947998 214.937683,3431.230957 
	C218.620270,3425.469971 222.514618,3419.445068 221.074524,3411.900879 
	C220.963608,3411.319824 221.145447,3410.683105 221.195587,3410.014404 
	C221.737045,3410.155518 222.449677,3410.121338 222.763626,3410.458252 
	C225.221222,3413.098877 227.532089,3417.834961 230.797363,3417.168457 
	C236.462143,3416.012451 243.025681,3417.802002 247.818527,3413.133789 
	C250.104645,3410.906738 252.759109,3409.619141 255.911331,3410.051514 
	C261.545013,3410.824707 265.258545,3407.938721 268.909973,3404.196533 
	C273.681610,3399.306641 277.745178,3393.509766 285.970825,3393.423340 
	C291.061462,3393.369629 288.566223,3386.274658 293.076965,3384.174561 
	C297.904419,3385.345703 296.959717,3389.922852 299.157318,3392.823242 
	C302.049744,3389.862793 305.381470,3386.796875 303.855988,3382.766357 
	C302.704437,3379.724121 298.475800,3381.644043 295.122650,3380.507812 
	C291.729950,3378.017578 291.304657,3375.156006 294.999084,3374.493164 
	C300.442444,3373.516113 306.202545,3372.914062 311.508759,3375.889404 
	C313.712189,3377.125244 314.938599,3380.434326 318.158447,3379.471436 
	C321.662933,3378.423584 323.333374,3375.580322 324.354797,3372.256592 
	C324.540955,3371.651123 325.040619,3371.141602 325.978851,3370.159424 
	C330.985321,3366.170410 336.914246,3365.578125 341.766785,3361.581543 
	C343.305878,3360.118164 344.771973,3358.182617 345.780579,3360.718018 
	C346.773315,3363.213135 345.136078,3367.596191 349.384705,3367.590332 
	C353.789795,3367.584229 358.475006,3366.898926 362.028778,3363.589600 
	C363.694061,3362.039062 365.186707,3360.302979 367.377808,3358.325684 
	C371.291962,3354.493652 374.360565,3353.087891 379.101562,3356.516846 
	C385.283112,3360.987549 391.505859,3360.986816 397.744476,3355.145508 
	C406.534515,3346.915039 416.913818,3342.375000 430.118225,3345.538574 
	C432.358734,3345.517578 433.827332,3345.779297 435.941895,3346.623535 
	C441.957428,3355.727539 440.238770,3361.057373 430.414734,3362.308350 
	C421.950867,3363.385986 415.234375,3366.598877 409.472290,3372.561523 
	C407.854828,3374.235107 405.929657,3376.217285 403.721222,3376.386963 
	C388.066223,3377.590332 377.430328,3390.859619 363.215332,3393.897705 
	C346.583496,3397.452148 333.288727,3408.085938 317.270203,3412.106445 
	C296.055939,3417.430664 275.982605,3426.053711 255.218018,3432.647217 
	C251.722168,3433.757080 248.007568,3433.852051 243.632843,3434.416992 
	C241.910797,3434.346191 240.943802,3434.177734 239.283524,3433.849121 
	C236.367020,3433.163086 235.285706,3431.063232 233.432251,3429.866455 
	C232.735947,3432.264648 233.277527,3434.794678 232.113052,3437.704590 
z"
        />
        <path
          fill="#152533"
          opacity="1.000000"
          stroke="none"
          d="
M419.488831,3336.584229 
	C421.814667,3335.834717 423.339783,3335.280273 423.916504,3332.657715 
	C425.874756,3330.097656 428.322754,3327.807861 430.638092,3328.205566 
	C440.433960,3329.887939 447.372192,3320.775635 457.183929,3321.826904 
	C460.586090,3322.191406 465.783905,3320.460205 467.038269,3314.492676 
	C467.212799,3311.837646 467.289734,3309.883301 468.255280,3307.328125 
	C469.163757,3305.786377 469.947754,3305.190430 471.667053,3304.785156 
	C472.869324,3304.807861 473.337830,3304.905762 474.416138,3305.348145 
	C477.519928,3308.763428 480.070923,3310.091797 483.307587,3305.502197 
	C485.026886,3304.340820 486.356079,3304.025146 488.443787,3303.899414 
	C492.971893,3303.843750 497.429077,3305.735596 499.813446,3299.853271 
	C500.628479,3297.926514 501.426575,3296.802246 502.981049,3295.395264 
	C507.961700,3292.450684 513.823364,3296.577393 518.217834,3291.372803 
	C520.931824,3289.124268 521.327393,3286.188477 523.556030,3283.638672 
	C541.947388,3287.272705 554.331787,3276.921387 566.690002,3266.828857 
	C569.009521,3264.934326 570.735535,3262.168457 574.085022,3262.566650 
	C582.674194,3263.587402 589.345703,3259.584961 596.119812,3255.112305 
	C604.573975,3249.530029 609.639038,3252.359863 610.271423,3262.755859 
	C610.535278,3267.092285 610.575195,3271.376953 613.673218,3275.661865 
	C615.206665,3278.014404 615.101440,3279.897217 614.339844,3282.487793 
	C613.869873,3283.577881 613.643738,3283.995117 612.986450,3284.971191 
	C612.216370,3285.850830 611.877625,3286.171143 610.972473,3286.886230 
	C610.003174,3287.488037 609.600220,3287.695312 608.575562,3288.151855 
	C606.259766,3288.821045 604.634155,3289.380859 602.836487,3291.036133 
	C600.439148,3292.490723 598.633667,3293.633545 597.392334,3296.307129 
	C590.590088,3303.181641 587.073303,3294.297363 581.316345,3294.050293 
	C579.098816,3294.343750 577.512878,3294.621582 575.534607,3295.673828 
	C573.507629,3296.804199 572.566040,3298.122070 571.484558,3300.204346 
	C564.192383,3312.193115 552.761353,3308.809326 541.908386,3309.681152 
	C536.712463,3310.883057 533.458801,3313.324707 531.870728,3318.592773 
	C527.627502,3325.047363 522.128540,3326.859375 515.381958,3325.454102 
	C513.115601,3324.981689 510.857758,3324.704590 508.212036,3326.536621 
	C507.269653,3327.262451 506.879700,3327.539307 505.870789,3328.184326 
	C495.652283,3333.873779 485.210724,3336.749512 474.626892,3339.305664 
	C467.281128,3341.079834 459.914551,3342.801514 455.699310,3350.342285 
	C453.115112,3354.965820 449.267487,3354.080811 445.686920,3351.233887 
	C442.810516,3348.947021 440.547424,3346.143311 438.891449,3342.105469 
	C438.306061,3341.237061 438.022552,3340.938721 437.279419,3340.280762 
	C435.782715,3339.324463 434.747162,3339.284912 433.200989,3340.298828 
	C431.951660,3341.177979 431.182159,3341.633545 429.723816,3342.339844 
	C419.175232,3345.654541 418.835602,3345.469727 419.488831,3336.584229 
z"
        />
        <path
          fill="#98999E"
          opacity="1.000000"
          stroke="none"
          d="
M152.363281,3382.302734 
	C153.109009,3382.091553 153.259842,3382.480469 153.463531,3382.436035 
	C170.894501,3375.604004 188.487671,3369.958008 207.239410,3365.626221 
	C213.681381,3364.715088 219.111145,3367.003662 224.788513,3366.370850 
	C225.898926,3373.374512 221.876801,3375.770752 216.619644,3376.786133 
	C206.963425,3378.652588 199.407745,3383.090820 193.032043,3391.025879 
	C189.009933,3396.031494 181.062256,3396.795166 174.903320,3397.397461 
	C167.638290,3398.107910 161.672241,3400.918701 154.731384,3404.012695 
	C148.485077,3406.716797 145.162415,3403.415283 142.935150,3399.256104 
	C140.863663,3395.387939 142.169250,3391.411865 145.608047,3388.760010 
	C147.970139,3386.938721 148.607666,3383.598633 152.363281,3382.302734 
z"
        />
        <path
          fill="#1B2B3A"
          opacity="1.000000"
          stroke="none"
          d="
M474.639130,3305.461914 
	C472.832153,3306.102783 471.645660,3306.225830 469.581512,3306.524414 
	C467.937286,3308.771240 467.741394,3310.813232 469.445984,3313.247559 
	C469.906830,3315.374512 469.966583,3317.033691 469.961945,3318.692627 
	C469.953033,3321.860840 469.054596,3323.982910 465.228149,3322.694824 
	C462.530090,3321.786621 460.856049,3322.855713 459.748535,3325.416504 
	C458.771942,3327.674561 457.120087,3327.734375 454.882233,3326.833008 
	C447.710480,3323.944580 447.688629,3324.041260 445.641754,3331.119141 
	C445.478668,3331.682861 444.898407,3332.125977 444.337402,3332.850586 
	C438.353271,3329.001465 432.022888,3329.284912 424.805328,3331.907959 
	C424.036011,3328.798584 426.572784,3327.772461 429.443878,3326.243896 
	C433.610260,3323.378662 431.695740,3319.795654 431.747498,3315.886719 
	C431.867188,3314.282959 432.094177,3313.386963 432.753937,3311.883789 
	C434.327484,3309.674805 435.955719,3308.748047 438.592407,3308.277832 
	C445.774353,3308.499756 447.161469,3300.652100 453.414612,3299.314697 
	C455.165649,3298.378906 456.433777,3298.169678 458.336578,3298.367432 
	C459.564850,3298.690918 460.209747,3298.751709 460.786194,3297.921875 
	C459.989380,3294.205566 459.822815,3291.156494 463.534363,3288.586914 
	C480.849213,3287.095215 482.243805,3289.202148 474.639130,3305.461914 
z"
        />
        <path
          fill="#1B2B3A"
          opacity="1.000000"
          stroke="none"
          d="
M419.511230,3335.503418 
	C420.034637,3337.901123 420.034637,3339.762939 420.034637,3342.217773 
	C423.645111,3342.650635 426.017303,3339.086182 429.905090,3340.243896 
	C432.131165,3342.213379 432.226379,3344.279053 430.899231,3347.125488 
	C428.045959,3348.934326 425.621002,3350.302246 423.033875,3348.453369 
	C418.762543,3345.400635 414.766266,3346.884277 411.226990,3349.501465 
	C406.586212,3352.933594 400.784210,3354.469727 397.227783,3359.885986 
	C395.039124,3363.219238 390.442596,3365.029053 385.911560,3362.593506 
	C384.214508,3361.681152 382.681427,3359.936279 380.640137,3361.513916 
	C376.476257,3364.731934 377.458496,3362.438477 376.950195,3359.063232 
	C376.054077,3353.113037 372.028839,3358.039307 368.700317,3357.915527 
	C367.917847,3355.049805 370.000275,3353.180420 371.785950,3350.417480 
	C374.096100,3348.659912 376.168579,3347.623047 378.960480,3346.480957 
	C387.302917,3349.237305 392.607697,3345.069092 398.313904,3339.768555 
	C400.661316,3337.055176 403.304626,3335.515381 405.247650,3332.552490 
	C406.144073,3332.164307 406.522247,3332.129150 407.469116,3332.059082 
	C408.392181,3332.549805 408.837250,3332.982178 409.695068,3333.660645 
	C413.017944,3334.224609 416.134460,3333.376709 419.511230,3335.503418 
z"
        />
        <path
          fill="#243748"
          opacity="1.000000"
          stroke="none"
          d="
M332.743103,3317.008301 
	C341.178040,3307.312988 351.051117,3312.173828 360.770081,3314.691162 
	C361.700348,3314.931641 362.620758,3315.210693 364.253967,3315.298828 
	C369.322113,3315.066162 373.617737,3316.833740 378.833191,3315.368164 
	C380.019165,3320.520996 376.834229,3322.051270 372.223663,3321.981201 
	C366.199219,3321.889648 360.194916,3321.598145 357.027924,3328.467773 
	C356.331024,3329.979736 354.205536,3329.914551 352.540131,3329.981689 
	C350.570923,3330.061035 348.271942,3329.736328 346.928314,3331.351562 
	C337.403992,3342.804443 324.249786,3339.283203 312.124908,3339.891113 
	C309.232758,3340.036377 307.095581,3338.520264 306.137299,3334.784668 
	C307.234985,3330.944092 309.801025,3329.464600 313.393250,3328.218262 
	C321.557220,3325.992920 329.085083,3330.321045 336.563080,3327.775879 
	C339.040497,3326.932617 341.675232,3326.525879 344.069275,3325.421387 
	C345.538086,3324.743652 347.265228,3323.973633 346.781067,3321.920166 
	C346.463409,3320.572510 345.110779,3319.994873 343.817322,3319.604736 
	C340.366882,3318.564453 336.555939,3319.309326 332.743103,3317.008301 
z"
        />
        <path
          fill="#243B51"
          opacity="1.000000"
          stroke="none"
          d="
M247.890823,3351.455078 
	C256.857025,3355.457031 257.296783,3356.356934 251.856506,3362.140869 
	C249.412125,3364.739746 251.435379,3366.329346 252.070129,3368.198486 
	C253.223938,3371.595459 256.260712,3372.718262 259.215118,3371.589355 
	C264.555420,3369.548340 269.459045,3370.579834 275.260590,3372.423096 
	C275.491272,3374.791504 274.232666,3375.834961 271.776123,3374.930664 
	C268.263885,3373.637695 264.277557,3372.965820 261.192902,3374.778320 
	C255.195770,3378.301758 250.838531,3377.280762 246.840347,3371.916016 
	C244.772568,3369.141113 241.810944,3367.954346 238.241562,3367.839844 
	C236.366501,3367.780029 233.622955,3367.318848 232.813553,3366.052979 
	C228.219543,3358.867188 221.720932,3361.013184 214.587189,3361.819824 
	C214.223618,3360.485840 214.596909,3359.024170 215.993393,3358.609863 
	C223.299774,3356.444336 230.700500,3354.684570 237.421677,3350.762939 
	C240.406082,3349.021240 243.924194,3350.083984 247.890823,3351.455078 
z"
        />
        <path
          fill="#243748"
          opacity="1.000000"
          stroke="none"
          d="
M433.509827,3315.463867 
	C434.048920,3319.462402 438.095764,3324.041016 430.711670,3325.890137 
	C424.378387,3320.627686 422.928864,3311.713623 414.300079,3308.625244 
	C410.715149,3307.341797 408.325500,3305.943848 404.451569,3308.133545 
	C399.719513,3310.808105 393.914856,3311.064209 388.934387,3313.653320 
	C387.087677,3314.613281 384.403687,3313.962891 381.293213,3314.060059 
	C385.634125,3308.592041 390.855011,3303.146973 399.266235,3303.680420 
	C401.937012,3303.849609 403.586060,3302.877441 404.771484,3300.611328 
	C405.724243,3298.790039 407.874146,3296.618408 409.248779,3297.848633 
	C414.180267,3302.262207 416.385132,3296.125977 420.650269,3295.502441 
	C421.781982,3295.509033 422.208008,3295.670654 423.004089,3296.402588 
	C422.572937,3302.644043 423.680664,3307.333984 430.237549,3309.933105 
	C431.837708,3311.757812 431.971191,3313.627686 433.509827,3315.463867 
z"
        />
        <path
          fill="#243B51"
          opacity="1.000000"
          stroke="none"
          d="
M152.292511,3382.088379 
	C150.705307,3385.224609 151.442245,3390.130127 145.794876,3391.694580 
	C142.507874,3392.605469 143.266861,3397.325439 146.014969,3399.810059 
	C147.889938,3401.505371 150.421982,3402.473633 153.345551,3404.012695 
	C149.486633,3410.062744 147.522751,3410.420410 140.588013,3406.284180 
	C139.882095,3392.240967 139.655212,3392.126221 126.484482,3397.407959 
	C125.039246,3397.987549 123.293686,3397.818115 120.896957,3398.000488 
	C118.851402,3397.060303 117.596329,3396.115967 115.620819,3394.629639 
	C122.915947,3393.384766 128.969208,3389.573242 136.214584,3389.823975 
	C139.722397,3389.945312 142.214951,3387.340576 144.695511,3385.316406 
	C146.763733,3383.628906 148.550949,3381.793945 152.292511,3382.088379 
z"
        />
        <path
          fill="#243B51"
          opacity="1.000000"
          stroke="none"
          d="
M208.017853,3365.150146 
	C203.949707,3368.783691 199.231537,3370.469727 194.464569,3371.804688 
	C185.924591,3374.196045 176.876312,3374.488037 169.019073,3379.657227 
	C165.218323,3382.157471 160.020264,3381.649414 154.526520,3382.236816 
	C156.141418,3375.915771 162.945801,3376.554443 167.734619,3374.736084 
	C180.405838,3369.924805 193.612625,3366.524170 207.307602,3362.285889 
	C208.009323,3362.847168 208.009750,3363.684570 208.017853,3365.150146 
z"
        />
        <path
          fill="#9FA2A1"
          opacity="1.000000"
          stroke="none"
          d="
M294.428009,3380.858887 
	C295.718323,3379.700684 296.890778,3378.937256 298.160034,3378.706299 
	C301.370697,3378.122559 304.910095,3376.835938 307.490936,3379.902832 
	C310.723358,3383.743652 306.142761,3385.698242 304.986176,3388.450928 
	C302.955139,3393.283691 300.734467,3398.035889 294.541656,3399.432617 
	C292.730835,3394.535645 299.137329,3389.844971 294.138367,3384.657715 
	C293.959717,3383.154297 294.156982,3382.325195 294.428009,3380.858887 
z"
        />
        <path
          fill="#2B3E51"
          opacity="1.000000"
          stroke="none"
          d="
M314.997131,3328.980225 
	C312.311218,3330.992676 309.865204,3332.406250 306.716919,3333.914551 
	C303.139771,3332.579834 300.978851,3329.598877 296.553192,3329.968750 
	C295.649475,3329.103516 295.461060,3328.253662 295.125366,3326.764893 
	C300.786621,3326.094971 306.595184,3326.063965 313.344757,3326.063477 
	C314.911438,3326.728271 315.228577,3327.490723 314.997131,3328.980225 
z"
        />
        <path
          fill="#5A6668"
          opacity="1.000000"
          stroke="none"
          d="
M162.266815,3454.873291 
	C166.140228,3456.531006 170.128525,3465.536865 168.573029,3468.818848 
	C167.597275,3470.877441 165.828735,3470.359619 164.697250,3468.834229 
	C162.693054,3466.131836 161.547821,3463.067383 161.680084,3458.823730 
	C161.768768,3457.191162 161.954010,3456.348877 162.266815,3454.873291 
z"
        />
        <path
          fill="#98999E"
          opacity="1.000000"
          stroke="none"
          d="
M341.317932,3361.106445 
	C339.666077,3367.487305 335.094025,3370.734863 327.293945,3369.942383 
	C328.637329,3362.657227 335.048279,3362.714844 341.317932,3361.106445 
z"
        />
        <path
          fill="#79868C"
          opacity="1.000000"
          stroke="none"
          d="
M405.814453,3332.138672 
	C406.749451,3336.830566 403.417816,3337.983643 399.562927,3339.312500 
	C398.441895,3336.985352 399.814941,3335.379150 401.800751,3333.456543 
	C402.435974,3331.714355 403.250885,3331.083740 405.279724,3331.658936 
	C405.971344,3332.189941 405.863037,3332.077637 405.814453,3332.138672 
z"
        />
        <path
          fill="#838F9A"
          opacity="1.000000"
          stroke="none"
          d="
M379.048492,3345.993896 
	C378.581879,3349.108887 376.301208,3350.060303 372.753601,3349.951904 
	C373.501923,3347.311768 374.434235,3344.012939 379.048492,3345.993896 
z"
        />
        <path
          fill="#61798F"
          opacity="1.000000"
          stroke="none"
          d="
M168.436340,3418.101807 
	C170.547607,3418.002197 171.502243,3418.593750 170.544922,3420.261475 
	C170.038010,3421.144531 169.114471,3421.866211 168.091293,3421.377441 
	C166.470886,3420.602783 166.823517,3419.438965 168.436340,3418.101807 
z"
        />
        <path
          fill="#243748"
          opacity="1.000000"
          stroke="none"
          d="
M388.860962,3319.280273 
	C389.281067,3320.781006 388.755402,3321.324951 387.590912,3321.093994 
	C387.349030,3321.045898 387.023102,3320.702393 386.986938,3320.458008 
	C386.813019,3319.282471 387.384918,3318.787598 388.860962,3319.280273 
z"
        />
        <path
          fill="#838F9A"
          opacity="1.000000"
          stroke="none"
          d="
M144.001007,3460.909180 
	C145.374634,3460.174316 146.885117,3459.514648 147.535065,3461.165039 
	C148.340958,3463.211670 146.844177,3464.050781 144.676865,3464.979004 
	C143.908417,3463.966064 143.875656,3462.850586 144.001007,3460.909180 
z"
        />
        <path
          fill="#152533"
          opacity="1.000000"
          stroke="none"
          d="
M405.643921,3331.709961 
	C405.111633,3332.329590 404.251923,3332.469238 402.767822,3332.814453 
	C402.619751,3331.392822 403.255737,3330.048584 405.643921,3331.709961 
z"
        />
        <path
          fill="#152533"
          opacity="1.000000"
          stroke="none"
          d="
M409.785736,3333.580078 
	C409.397369,3333.772949 408.890106,3333.394775 408.266602,3332.445068 
	C408.654388,3332.254639 409.159882,3332.633057 409.785736,3333.580078 
z"
        />
        <path
          fill="#4A5448"
          opacity="1.000000"
          stroke="none"
          d="
M153.471893,1770.516724 
	C155.920563,1768.824463 155.833572,1766.404785 157.571274,1764.395996 
	C162.312668,1759.504272 169.326340,1758.924316 172.860764,1753.129517 
	C174.699448,1750.114990 177.485794,1752.334229 180.288696,1754.048828 
	C185.901749,1760.442139 193.680237,1758.477295 200.452667,1761.514282 
	C201.523621,1762.907227 201.861374,1763.858398 202.013535,1765.666016 
	C201.825928,1767.521606 201.409149,1768.487549 200.060272,1769.740112 
	C195.853867,1769.526245 192.822083,1766.861328 187.918228,1767.914307 
	C192.081146,1773.261230 194.794174,1778.746094 194.675812,1785.441528 
	C194.626389,1788.238525 198.186966,1787.734009 200.400665,1788.079590 
	C203.308975,1788.533691 205.515228,1789.863281 205.549713,1793.107910 
	C205.578613,1795.828979 203.550781,1796.810059 201.336853,1797.975952 
	C192.684692,1802.532227 192.586899,1803.669189 199.417740,1810.233276 
	C201.850235,1812.570801 202.998978,1821.140625 201.225647,1824.030884 
	C200.168137,1825.754517 198.255386,1826.211914 196.975754,1825.058838 
	C192.030258,1820.602417 187.070267,1823.314453 182.009827,1824.605347 
	C181.010559,1827.214966 183.790176,1829.188599 184.587524,1828.788818 
	C191.994278,1825.074219 192.209763,1832.383301 195.101486,1835.405762 
	C195.618668,1835.946167 195.355942,1837.232788 195.569580,1839.298828 
	C187.454773,1836.392090 179.753632,1839.839233 169.690308,1837.486206 
	C175.253830,1843.034668 175.269501,1846.977905 169.908875,1849.585571 
	C164.167297,1852.378662 165.555313,1854.476929 170.231339,1856.644287 
	C171.221008,1857.103027 171.570496,1858.047607 171.499130,1859.126465 
	C171.099472,1859.524902 170.554642,1860.349976 170.320450,1860.269775 
	C160.648468,1856.951172 160.651993,1856.940918 160.378204,1866.894043 
	C152.814484,1864.024902 149.246689,1858.173218 148.039001,1850.675781 
	C147.437164,1846.939331 146.114731,1843.784546 143.564896,1840.922485 
	C138.945648,1835.737427 139.380692,1832.351562 144.506897,1827.764526 
	C147.488983,1825.096069 151.244019,1823.821289 156.068314,1821.969604 
	C150.140182,1816.804810 145.191925,1812.278809 144.286057,1804.706787 
	C142.488037,1811.291504 139.713593,1816.739868 138.058334,1822.532715 
	C137.106827,1825.862549 135.157547,1827.588989 132.277115,1828.778198 
	C130.522903,1829.502563 128.392365,1829.384521 128.914597,1827.159180 
	C129.689407,1823.857422 128.208878,1822.671753 125.968849,1820.760254 
	C118.013649,1813.971436 117.195137,1798.202881 128.684723,1791.498169 
	C130.103470,1790.670532 131.621063,1789.914673 132.795715,1788.809692 
	C133.339340,1788.298096 133.594086,1786.434326 133.255524,1786.190674 
	C129.519135,1783.503784 125.640114,1781.015137 121.799744,1778.472656 
	C115.575661,1784.428833 110.352417,1779.387573 104.467041,1776.385986 
	C109.846016,1767.923706 116.729919,1765.982422 125.136719,1770.085449 
	C130.162933,1772.538574 134.826111,1775.992065 141.529877,1774.738281 
	C144.233871,1774.197510 146.304733,1774.051392 149.154999,1773.899414 
	C150.981781,1772.967529 151.990372,1772.000244 153.471893,1770.516724 
z"
        />
        <path
          fill="#5A6668"
          opacity="1.000000"
          stroke="none"
          d="
M141.394241,1774.064087 
	C139.351028,1780.174194 134.671295,1778.141724 130.464401,1776.835449 
	C128.363434,1776.183350 126.478004,1774.605103 124.701324,1773.187378 
	C120.723885,1770.013062 116.735199,1768.646240 112.399506,1772.432495 
	C110.277664,1774.285522 107.322037,1773.508057 104.546188,1775.345703 
	C102.457932,1776.500854 100.875458,1777.164062 98.653290,1777.895386 
	C97.668549,1777.694824 97.323517,1777.426025 96.570343,1776.643066 
	C94.512550,1773.521851 91.412827,1771.929199 90.880295,1767.856934 
	C92.035172,1763.786011 94.242821,1761.577759 98.456055,1760.901123 
	C100.060089,1761.255249 100.567070,1762.626831 101.236221,1762.587280 
	C102.355247,1762.520996 101.365883,1761.512207 101.321739,1761.001831 
	C101.128548,1758.767456 101.202255,1756.537964 101.198456,1753.627441 
	C101.201492,1751.544189 101.443840,1750.177490 102.099258,1748.174805 
	C103.618813,1745.257690 105.693634,1744.405029 108.109596,1744.437500 
	C112.777901,1744.500000 116.563232,1743.815552 116.842949,1737.830688 
	C117.108871,1732.141113 114.670029,1729.248291 109.077339,1728.750488 
	C107.445892,1728.605103 105.884499,1728.052368 103.816132,1726.805664 
	C102.906769,1725.904175 102.604294,1725.472778 101.979889,1724.301025 
	C100.608368,1719.736938 100.681519,1715.833740 100.912483,1711.075195 
	C101.028755,1709.076172 101.039749,1707.926636 100.970474,1705.901611 
	C100.407410,1699.659912 100.046654,1694.308472 103.116669,1688.596436 
	C110.625893,1690.823730 118.196762,1689.118896 125.225052,1691.269287 
	C128.672546,1692.324219 129.983871,1689.979004 129.984207,1686.820801 
	C129.984863,1680.522583 129.901398,1674.224121 129.951019,1667.926514 
	C129.968918,1665.655029 129.758270,1662.831543 132.958267,1662.600098 
	C136.265076,1662.360840 137.490540,1664.761230 137.835419,1667.686035 
	C137.912857,1668.342651 138.002686,1669.022583 137.918121,1669.669800 
	C135.447006,1688.585815 138.105453,1707.565186 137.512848,1726.544189 
	C137.066193,1740.849731 136.378464,1755.461060 138.035049,1769.879761 
	C138.236084,1771.629517 138.950562,1773.021118 141.394241,1774.064087 
z"
        />
        <path
          fill="#3D494C"
          opacity="1.000000"
          stroke="none"
          d="
M490.856781,1876.365112 
	C487.486176,1876.318237 486.096039,1873.645142 483.942017,1872.539185 
	C478.501587,1880.605103 478.501587,1880.605103 483.149902,1886.423584 
	C481.043274,1889.155518 477.940063,1889.862427 474.059357,1890.731689 
	C461.323853,1891.859741 449.332245,1892.763672 437.277588,1892.026978 
	C433.603363,1891.802612 430.040222,1891.615601 426.546722,1888.520752 
	C423.465424,1888.939575 424.079437,1891.734009 422.228333,1893.732178 
	C419.686157,1892.581909 419.155609,1890.349731 418.815918,1887.327148 
	C418.935638,1884.703247 420.571442,1882.421387 416.963989,1880.891846 
	C415.323883,1879.560791 414.932465,1878.364258 415.709961,1876.434692 
	C421.220276,1870.228638 421.935669,1870.263672 434.219208,1865.678833 
	C437.429230,1864.480835 439.606079,1857.710938 438.661224,1856.325684 
	C435.825043,1852.167969 437.657013,1846.131714 432.395508,1842.614624 
	C431.575928,1840.995239 431.785126,1840.050659 433.201538,1838.785034 
	C436.228668,1837.233765 438.756439,1837.158447 442.043396,1837.473877 
	C443.215179,1837.707886 443.665497,1837.845337 444.725494,1838.305786 
	C448.017517,1840.879150 447.226440,1844.130737 447.844696,1847.579102 
	C448.077454,1848.481812 448.214844,1848.808960 448.763611,1849.444580 
	C449.870758,1849.926636 450.576172,1850.045044 451.870911,1850.211182 
	C452.840668,1850.420898 453.228119,1850.542725 454.173889,1850.959229 
	C455.073151,1851.521973 455.414093,1851.790039 456.210327,1852.537720 
	C460.114838,1856.919067 464.464447,1857.603516 467.569824,1853.609497 
	C473.078583,1846.524414 481.087067,1845.188354 489.396667,1842.698120 
	C498.736298,1849.017822 498.736298,1849.017822 502.480225,1838.307373 
	C503.390778,1836.921387 504.080505,1836.283203 505.543304,1835.532104 
	C511.913025,1836.796143 513.924194,1841.040894 513.779175,1846.344604 
	C513.705505,1849.034546 512.887817,1851.572144 516.705444,1853.359497 
	C519.388367,1856.302856 520.254333,1859.234253 520.399902,1863.221436 
	C519.340149,1869.169678 518.890625,1874.583130 513.492188,1878.343750 
	C508.221771,1876.091064 508.877777,1871.612915 508.330627,1867.665039 
	C507.646484,1869.884277 507.270264,1872.519531 503.828033,1873.789795 
	C502.252319,1874.183472 501.331940,1874.267822 499.724426,1874.251953 
	C496.221924,1873.715698 493.917053,1875.154053 490.856781,1876.365112 
z"
        />
        <path
          fill="#4A5448"
          opacity="1.000000"
          stroke="none"
          d="
M342.540741,1835.221313 
	C338.902252,1834.076782 335.951599,1832.023438 332.304077,1831.049683 
	C328.992371,1830.165283 324.879303,1830.992798 324.034821,1832.855347 
	C321.055023,1839.427124 313.601562,1841.101440 310.212982,1846.642334 
	C307.392059,1851.255005 305.209167,1850.116821 302.678223,1847.050659 
	C300.727509,1844.687134 299.247955,1843.779053 297.821594,1847.342285 
	C297.433228,1848.312378 296.791138,1849.264771 295.653137,1848.908447 
	C287.213379,1846.267090 279.215210,1847.154663 271.186127,1850.889893 
	C267.767670,1852.480347 264.042084,1848.023438 264.517365,1843.417603 
	C265.690033,1832.053101 264.892273,1820.479004 268.184204,1808.483643 
	C272.516876,1806.319702 276.818024,1803.737671 281.495056,1808.322388 
	C291.480957,1816.242065 295.556854,1815.940186 300.949951,1806.823608 
	C304.945892,1803.751221 307.417908,1805.741699 308.119385,1808.880493 
	C309.951965,1817.080566 316.260468,1818.302368 322.909546,1819.572388 
	C328.064667,1820.557007 333.416870,1820.781006 338.972473,1823.567139 
	C343.249268,1826.712524 344.978180,1830.031738 342.540741,1835.221313 
z"
        />
        <path
          fill="#646A6C"
          opacity="1.000000"
          stroke="none"
          d="
M416.078003,1875.089111 
	C416.400024,1877.070190 416.656677,1878.212158 417.328735,1879.779907 
	C418.620392,1880.993652 419.090454,1881.975342 418.976807,1883.973389 
	C417.771149,1888.432007 416.609680,1891.587769 411.686096,1891.393188 
	C389.915283,1890.532104 368.101746,1891.416626 345.648315,1889.591309 
	C342.870697,1888.291748 342.077545,1885.914429 339.571594,1884.545898 
	C337.823090,1884.093384 336.647736,1883.824341 335.060181,1882.974243 
	C331.298828,1875.986450 332.045502,1868.757935 330.719025,1859.844849 
	C335.368805,1864.824097 334.893127,1869.756592 336.465637,1873.835205 
	C336.945190,1875.079102 337.776886,1875.467407 337.277374,1874.246704 
	C337.162537,1873.966064 338.019958,1873.287476 338.795593,1872.404785 
	C339.927277,1871.385620 340.808990,1871.028076 342.475403,1871.160400 
	C345.609772,1874.595703 347.939240,1875.360474 349.465088,1870.776855 
	C349.971130,1869.256836 349.695160,1867.480591 349.806244,1865.823486 
	C349.925262,1864.048462 350.491974,1862.423218 352.537659,1862.346558 
	C355.152985,1862.248291 355.825043,1864.074219 355.791992,1867.070557 
	C355.417328,1869.116211 355.638153,1867.381226 355.320251,1868.674316 
	C355.091309,1869.605591 354.998566,1870.533813 355.003265,1871.482178 
	C355.070801,1870.685059 355.135773,1869.887573 355.887726,1868.738281 
	C358.253357,1866.885864 356.932068,1863.598267 359.716461,1862.657349 
	C363.637115,1863.713989 360.690033,1868.202759 363.310608,1869.909546 
	C365.212738,1867.747559 362.362305,1862.648682 366.756226,1862.702271 
	C370.978607,1862.753418 369.486664,1867.118896 369.877197,1869.821777 
	C370.112823,1871.452393 369.974152,1873.151733 371.550201,1875.049561 
	C376.146759,1872.187988 373.566284,1865.745361 377.648773,1862.441040 
	C381.826935,1866.193115 377.977783,1872.425659 382.638306,1876.719849 
	C384.666016,1873.286865 383.573395,1869.705811 384.060974,1866.467773 
	C384.656433,1862.513550 388.594269,1860.727661 389.911804,1863.701294 
	C393.359283,1871.482300 394.105865,1863.171143 396.190216,1863.074951 
	C399.896729,1865.036499 396.297028,1870.426636 400.454010,1872.368164 
	C403.678497,1870.081665 400.388977,1865.946899 402.966797,1863.484619 
	C407.515411,1865.115601 404.246643,1870.449829 407.703369,1873.014282 
	C412.006348,1871.715698 409.202576,1867.230591 410.962067,1864.840576 
	C411.758209,1863.759399 412.531128,1862.675171 414.134338,1863.118164 
	C415.221771,1863.418579 415.806702,1864.269897 415.850311,1865.277344 
	C415.979645,1868.264893 415.968658,1871.258545 416.078003,1875.089111 
z"
        />
        <path
          fill="#434F51"
          opacity="1.000000"
          stroke="none"
          d="
M28.554411,1816.451660 
	C28.024084,1814.503662 28.027569,1813.077515 28.045307,1810.944092 
	C32.979259,1801.603394 41.164074,1797.159424 49.754139,1793.157227 
	C53.316807,1791.497437 57.718945,1796.338135 60.642521,1791.770752 
	C62.683178,1788.582642 58.727970,1784.781006 61.240448,1781.416748 
	C61.603344,1780.931030 61.601524,1780.172974 61.822983,1778.817871 
	C66.048302,1775.216919 68.938866,1777.418579 71.840210,1781.320068 
	C69.799774,1788.248169 71.642838,1794.140747 73.705650,1799.638550 
	C71.388611,1801.844116 70.388283,1800.015869 69.172607,1799.129639 
	C68.004967,1798.278442 66.636162,1798.031128 65.513954,1798.866089 
	C63.977287,1800.009399 63.723953,1801.940796 64.887299,1803.249146 
	C68.960617,1807.829590 66.005623,1810.163940 62.967762,1813.851929 
	C59.461266,1818.109253 52.091965,1821.241089 55.717228,1829.230347 
	C56.365440,1830.659058 55.574409,1832.591675 53.830322,1833.347656 
	C51.887001,1834.190063 49.898552,1833.891235 48.219154,1832.596558 
	C46.851044,1831.541992 46.083694,1829.971802 46.135860,1828.275513 
	C46.305424,1822.761597 43.085274,1821.599854 38.456837,1821.966919 
	C34.303253,1822.296387 30.427441,1821.981201 28.554411,1816.451660 
z"
        />
        <path
          fill="#374549"
          opacity="1.000000"
          stroke="none"
          d="
M83.087471,1856.061401 
	C84.424805,1851.060303 83.918755,1847.006104 80.868950,1843.317749 
	C79.624329,1841.812622 78.886841,1839.858032 78.063736,1838.042114 
	C77.132507,1835.987793 75.208527,1833.742676 77.005318,1831.588867 
	C79.351303,1828.776489 81.813126,1831.176514 84.127335,1832.213501 
	C85.220665,1832.703491 85.862701,1834.196411 87.915550,1833.512451 
	C87.443306,1828.187256 88.548492,1823.190918 93.263023,1819.583008 
	C94.867401,1818.355347 95.746788,1816.489746 95.954773,1814.393677 
	C96.233200,1811.587891 97.035500,1808.992676 100.235756,1808.445801 
	C104.065521,1807.791138 105.220123,1810.885620 106.752930,1813.466553 
	C107.760506,1815.163086 108.990685,1816.743042 110.244667,1818.274170 
	C112.112633,1820.555420 112.163284,1822.491699 109.289734,1823.835327 
	C107.279716,1824.775269 105.650276,1825.992432 106.868752,1828.326050 
	C107.759384,1830.031860 109.614891,1829.401367 111.304497,1829.359741 
	C115.912018,1829.245850 116.955376,1830.714355 114.561531,1834.046143 
	C110.902847,1839.138428 107.181984,1844.099731 105.450066,1850.285156 
	C104.747940,1852.792847 102.507874,1853.695068 100.026115,1853.987183 
	C94.618950,1854.623413 90.131363,1857.610718 84.598038,1859.882812 
	C82.878410,1859.040771 82.623840,1857.895874 83.087471,1856.061401 
z"
        />
        <path
          fill="#808489"
          opacity="1.000000"
          stroke="none"
          d="
M207.466949,1671.275879 
	C220.291916,1672.743896 221.132416,1672.810181 220.128448,1685.535400 
	C217.901627,1713.760742 218.040482,1742.000244 218.017807,1771.203247 
	C219.177963,1773.475830 219.379639,1774.976929 218.173492,1777.158936 
	C216.900940,1777.962891 216.230682,1778.228516 215.029068,1778.389160 
	C211.402313,1774.769165 210.946808,1770.445068 211.134750,1766.097778 
	C211.779266,1751.189453 209.346786,1736.176147 212.889145,1721.347290 
	C213.729065,1717.831543 212.863052,1714.077637 212.444183,1712.522095 
	C212.128189,1709.141113 213.650177,1710.529419 212.338104,1710.292358 
	C211.926102,1715.097900 214.783417,1720.098633 210.728668,1725.144531 
	C208.195694,1726.106812 206.448654,1725.955688 203.959137,1724.724609 
	C199.195190,1716.348999 201.871155,1707.932617 201.052109,1698.866699 
	C201.731033,1689.719727 199.533524,1681.329468 203.284500,1672.521729 
	C204.802597,1671.634521 205.777100,1671.536499 207.466949,1671.275879 
z"
        />
        <path
          fill="#423C3E"
          opacity="1.000000"
          stroke="none"
          d="
M434.611969,1839.352173 
	C433.503510,1840.390625 432.970886,1840.788330 432.189636,1841.626709 
	C427.631683,1841.853516 425.208435,1843.141357 427.925751,1847.731079 
	C430.670135,1852.366455 428.195221,1854.231445 424.235718,1855.680786 
	C418.649261,1857.725586 413.259583,1855.682495 407.246826,1855.592651 
	C407.650665,1851.312256 410.081879,1849.463257 413.061066,1848.041870 
	C415.143402,1847.048584 417.332977,1846.243408 419.307068,1845.071777 
	C420.762970,1844.207764 421.796143,1842.735107 420.955353,1840.900879 
	C419.972595,1838.756958 418.159088,1838.105103 416.058472,1839.124268 
	C412.811859,1840.699341 409.726685,1842.614990 406.450439,1844.119263 
	C401.999695,1846.162964 398.052765,1847.011719 394.255157,1842.000488 
	C391.976318,1838.993530 387.953522,1837.282104 386.269897,1832.669434 
	C384.530609,1829.424561 385.239838,1826.979492 387.126709,1824.015503 
	C389.667297,1822.358643 391.584351,1822.735840 394.040894,1824.242432 
	C397.139008,1827.963745 399.676605,1831.295776 403.971069,1826.875854 
	C406.535889,1824.236084 409.907837,1824.380981 413.832825,1823.919434 
	C415.926636,1823.759399 417.308777,1823.914795 419.325256,1824.521484 
	C423.097412,1827.818726 426.236481,1830.353271 430.387787,1824.877930 
	C432.635284,1824.172974 434.158142,1824.317627 436.278442,1825.492676 
	C439.356964,1830.662354 438.472198,1834.850708 434.611969,1839.352173 
z"
        />
        <path
          fill="#434F51"
          opacity="1.000000"
          stroke="none"
          d="
M157.179810,1874.016724 
	C157.489227,1879.449707 153.708038,1880.023926 149.388138,1880.020386 
	C145.180908,1880.016846 140.589249,1879.044189 139.475464,1885.416992 
	C139.012177,1888.067749 136.329163,1887.016235 134.476746,1886.349976 
	C132.705704,1885.713257 130.018829,1885.314575 131.680695,1882.519897 
	C134.277283,1878.153320 131.229828,1878.091064 128.455872,1878.057983 
	C119.487450,1877.950928 110.516884,1878.039429 101.547630,1877.979004 
	C98.299187,1877.957031 94.546944,1878.595459 94.052971,1872.840088 
	C101.674042,1871.761597 109.655693,1873.402222 117.235382,1868.104980 
	C118.900475,1868.202515 119.718216,1868.672485 120.636963,1868.807007 
	C120.312019,1867.905151 119.455109,1867.490601 118.417862,1866.428833 
	C115.795570,1865.774658 112.865448,1866.425049 112.680519,1863.094604 
	C112.533707,1860.450684 114.897644,1860.094482 116.910713,1860.093140 
	C124.881058,1860.088135 132.853790,1860.108398 140.820190,1860.322144 
	C142.796097,1860.375122 144.941360,1861.244263 144.816025,1863.769165 
	C144.704605,1866.014038 142.599945,1865.815063 141.020386,1865.952026 
	C139.039993,1866.123779 137.029724,1866.019653 135.064514,1866.278564 
	C133.113266,1866.535645 132.230865,1867.958374 132.506989,1869.823853 
	C132.762939,1871.553101 134.420929,1873.796143 135.533829,1873.053955 
	C142.859100,1868.167725 149.453323,1874.224854 157.179810,1874.016724 
z"
        />
        <path
          fill="#4A5448"
          opacity="1.000000"
          stroke="none"
          d="
M218.352539,1777.850098 
	C217.791321,1776.310669 218.011475,1774.924072 218.108002,1772.843994 
	C219.311371,1772.408325 220.416397,1771.948608 221.704224,1770.550171 
	C223.123764,1769.813721 224.238541,1769.621460 226.138947,1769.109619 
	C227.579437,1768.464600 228.273758,1768.283203 229.546875,1768.252686 
	C230.447113,1768.370361 230.801636,1768.458496 231.648224,1768.790527 
	C232.723877,1769.497437 233.141052,1770.075928 233.593872,1771.282593 
	C234.711441,1771.898926 235.595886,1772.024292 237.151184,1772.133179 
	C241.354813,1772.698242 244.916290,1773.086914 249.090851,1773.919067 
	C250.118164,1774.389038 250.499298,1774.637817 251.421906,1775.302246 
	C253.690353,1778.838745 258.882080,1776.515747 260.094269,1781.457275 
	C260.947021,1783.012939 261.372894,1783.974731 261.615906,1785.833252 
	C261.219269,1789.789429 260.881561,1792.983643 257.708435,1795.748535 
	C252.983475,1795.074829 248.961609,1794.040527 245.389893,1793.121948 
	C244.205688,1788.890259 248.908066,1788.792480 248.900986,1785.193481 
	C245.629211,1784.794800 242.026260,1785.888184 238.765396,1783.603149 
	C237.134048,1786.602783 241.920471,1789.427856 238.288712,1791.617188 
	C236.039093,1792.973511 233.892014,1794.516846 231.572083,1795.734741 
	C229.019119,1797.075073 226.320908,1798.138794 223.759781,1799.289917 
	C220.879593,1795.827881 223.670502,1793.420654 224.593964,1790.837158 
	C227.288010,1783.300415 226.762955,1781.970703 218.352539,1777.850098 
z"
        />
        <path
          fill="#D7D5CF"
          opacity="1.000000"
          stroke="none"
          d="
M334.428558,1883.373657 
	C335.714996,1882.447144 336.842865,1882.292480 338.526733,1882.350464 
	C341.420929,1882.241211 342.026062,1884.349121 343.586792,1886.169922 
	C359.445892,1887.132812 374.992432,1887.588379 390.542206,1887.777954 
	C397.746460,1887.865601 405.042023,1887.314087 412.050690,1889.017456 
	C416.824219,1890.177979 415.393463,1886.346313 417.303589,1884.343994 
	C418.356018,1884.341187 418.745819,1884.638062 419.229034,1885.663940 
	C420.172424,1888.490967 422.640808,1889.939697 421.778748,1893.402466 
	C408.548920,1894.065796 395.389893,1894.115112 382.233368,1893.957031 
	C362.718201,1893.722534 343.164185,1894.340210 322.841797,1891.578857 
	C320.878784,1890.627319 320.717133,1889.474487 321.512512,1887.720703 
	C322.459351,1887.176392 322.989441,1887.098145 324.281311,1886.763306 
	C324.955719,1886.032227 324.868378,1885.557617 325.422729,1885.417969 
	C328.755463,1884.991699 332.359863,1888.893555 334.428558,1883.373657 
z"
        />
        <path
          fill="#E5DDC9"
          opacity="1.000000"
          stroke="none"
          d="
M257.735657,1796.314941 
	C257.102753,1792.959351 256.866302,1789.703613 260.446259,1787.079346 
	C270.176849,1784.216797 279.275848,1783.211426 289.154114,1786.148926 
	C294.393341,1790.964233 291.642151,1798.086548 296.330383,1803.081055 
	C298.301849,1804.697998 299.891388,1805.693726 301.351715,1807.814331 
	C301.697968,1811.338379 302.843872,1814.307617 300.931213,1817.820435 
	C294.568634,1814.073486 285.652618,1819.414551 279.950867,1810.923584 
	C278.693695,1803.066162 272.867218,1802.014648 266.651917,1800.454346 
	C264.177551,1799.794678 262.716980,1798.625366 260.471436,1797.458008 
	C259.379822,1796.955078 258.922913,1796.738037 257.735657,1796.314941 
z"
        />
        <path
          fill="#3D494C"
          opacity="1.000000"
          stroke="none"
          d="
M383.547974,1831.385010 
	C383.755035,1832.693481 383.565796,1833.359863 383.191620,1833.897705 
	C381.216187,1836.738037 385.188385,1839.846558 382.947845,1842.457886 
	C380.715485,1845.059814 377.201111,1843.338013 374.468536,1844.333374 
	C371.959991,1845.247192 369.103333,1846.471069 372.417969,1849.671021 
	C373.481323,1850.697632 376.074188,1850.876953 374.457245,1853.112305 
	C373.286835,1854.730103 370.865051,1856.214233 369.326355,1854.780273 
	C366.849731,1852.472412 362.220581,1851.543091 362.813171,1846.834106 
	C363.073181,1844.768188 363.828217,1842.517578 360.880280,1842.396484 
	C358.627899,1842.304077 356.189941,1843.028564 355.445496,1845.784180 
	C355.107910,1847.033936 355.249664,1848.464111 354.709656,1849.589844 
	C354.362762,1850.312988 353.184204,1851.018799 352.353729,1851.048706 
	C350.636322,1851.110840 349.918182,1849.449951 350.426300,1848.313354 
	C352.091278,1844.589233 350.410034,1841.161377 349.951660,1836.889160 
	C349.517120,1832.933594 350.355713,1829.121948 353.660156,1829.381226 
	C363.459564,1830.150146 373.588593,1824.054565 383.547974,1831.385010 
z"
        />
        <path
          fill="#4A5448"
          opacity="1.000000"
          stroke="none"
          d="
M72.529289,1782.109009 
	C69.144020,1780.791504 66.204063,1779.487305 62.570786,1778.138916 
	C60.299984,1777.556763 58.722511,1777.018799 56.565536,1776.225830 
	C55.987370,1775.621582 55.988705,1775.272461 55.992256,1774.399658 
	C57.715214,1770.153687 60.533173,1767.169067 63.285931,1763.312744 
	C65.382347,1761.689697 67.212807,1761.135742 69.771179,1760.480591 
	C76.893509,1760.607666 81.989388,1765.315430 89.070923,1765.868286 
	C90.164307,1766.407227 90.568008,1766.687012 91.481705,1767.498779 
	C92.875351,1770.244995 94.620659,1771.378540 96.834938,1771.989868 
	C98.439438,1772.432983 99.747284,1773.152100 98.453339,1775.603516 
	C96.731842,1777.334106 95.299675,1778.386719 92.632339,1778.219482 
	C89.122429,1778.760986 86.288460,1779.366455 82.722389,1779.991943 
	C80.893532,1781.170166 80.511902,1782.864746 78.559326,1783.977295 
	C77.646942,1784.122925 77.313263,1784.106567 76.480530,1784.044922 
	C75.596588,1783.488037 75.141174,1783.049927 74.240311,1782.392700 
	C73.865379,1782.100220 72.974609,1782.122192 72.529289,1782.109009 
z"
        />
        <path
          fill="#595B54"
          opacity="1.000000"
          stroke="none"
          d="
M384.518005,1832.052979 
	C374.014618,1828.326294 363.836609,1833.681274 353.738953,1831.587524 
	C351.952911,1831.217285 352.074890,1834.151611 350.430481,1835.716064 
	C347.822113,1836.083008 345.715332,1836.008667 342.817108,1835.930420 
	C341.879639,1832.621826 342.579529,1829.061035 338.623993,1826.366089 
	C338.297272,1821.414429 342.049622,1821.199707 345.133820,1820.942261 
	C357.162933,1819.938354 369.337830,1822.598511 381.333405,1819.319092 
	C383.782715,1818.649414 386.516998,1819.967651 388.133331,1823.312012 
	C388.200928,1826.554199 387.849121,1828.784424 386.268860,1831.419678 
	C385.985016,1832.046997 385.006378,1832.019653 384.518005,1832.052979 
z"
        />
        <path
          fill="#8C8D89"
          opacity="1.000000"
          stroke="none"
          d="
M203.656952,1724.436035 
	C205.794052,1723.943115 207.212128,1723.957153 209.338989,1723.991211 
	C213.721176,1740.227539 212.076965,1756.552368 210.344528,1773.565063 
	C208.226471,1774.184570 206.806870,1774.103882 204.687408,1774.153809 
	C203.077072,1774.000366 202.353409,1773.456665 201.537292,1772.034668 
	C201.993530,1768.949829 201.480927,1766.574707 200.963547,1763.419434 
	C197.775513,1752.245605 198.557755,1741.860474 201.079163,1730.626953 
	C201.352234,1728.015625 201.818573,1726.359497 203.656952,1724.436035 
z"
        />
        <path
          fill="#E7E3DA"
          opacity="1.000000"
          stroke="none"
          d="
M209.999512,1774.822388 
	C209.860001,1758.135498 210.073944,1742.005493 210.167831,1724.943359 
	C212.440460,1721.857056 212.020126,1718.988525 211.229843,1716.571533 
	C209.349609,1710.820312 211.860641,1705.712769 212.094940,1700.295288 
	C212.748093,1700.318726 213.401260,1700.342163 214.054413,1700.365479 
	C214.054413,1725.711304 214.054413,1751.057007 214.276062,1777.343628 
	C212.336014,1778.429077 211.646225,1776.473755 209.999512,1774.822388 
z"
        />
        <path
          fill="#374549"
          opacity="1.000000"
          stroke="none"
          d="
M106.034775,1795.572754 
	C107.816483,1797.921143 108.321648,1800.087891 106.191635,1801.219482 
	C102.413933,1803.226196 98.248978,1805.005371 93.917061,1803.542725 
	C89.033653,1801.894043 84.361206,1799.620361 79.694679,1796.835449 
	C86.519974,1798.322266 92.601196,1796.244507 98.754654,1794.294678 
	C100.933746,1793.604248 103.330673,1793.861938 106.034775,1795.572754 
z"
        />
        <path
          fill="#A6A29C"
          opacity="1.000000"
          stroke="none"
          d="
M265.630188,1798.332397 
	C277.000153,1798.893799 277.626404,1799.383423 279.783295,1809.558350 
	C277.939484,1810.059082 277.851318,1805.381348 274.709930,1808.783081 
	C273.174316,1810.446045 271.086761,1809.144165 268.819305,1807.868774 
	C267.036499,1806.145874 265.834381,1804.658569 264.471771,1802.617432 
	C264.117554,1800.824707 264.318817,1799.686279 265.630188,1798.332397 
z"
        />
        <path
          fill="#E7E3DA"
          opacity="1.000000"
          stroke="none"
          d="
M321.390869,1886.704834 
	C321.926056,1888.330688 321.922852,1889.406738 321.927368,1891.020264 
	C319.338165,1893.475220 316.044830,1892.260132 312.621399,1893.810791 
	C309.781708,1893.902100 307.105194,1894.373047 306.600922,1891.299927 
	C306.180115,1888.735352 308.325348,1888.247925 310.160706,1887.638184 
	C312.290955,1886.930664 314.412781,1886.197754 317.281982,1885.218506 
	C318.967987,1885.359253 319.910248,1885.757202 321.390869,1886.704834 
z"
        />
        <path
          fill="#687073"
          opacity="1.000000"
          stroke="none"
          d="
M184.450760,1871.198853 
	C183.815842,1874.659180 183.640762,1878.135376 179.426041,1877.612793 
	C175.806107,1877.164062 176.174866,1874.147461 176.036621,1870.788086 
	C180.860992,1864.878296 181.546356,1864.919556 184.450760,1871.198853 
z"
        />
        <path
          fill="#6F7981"
          opacity="1.000000"
          stroke="none"
          d="
M318.041809,1881.413818 
	C312.025024,1881.432251 314.848083,1876.113892 312.877045,1872.537354 
	C312.029205,1868.521484 311.607239,1865.059204 311.185272,1861.597046 
	C314.011719,1867.899658 318.693329,1873.432861 318.041809,1881.413818 
z"
        />
        <path
          fill="#79868C"
          opacity="1.000000"
          stroke="none"
          d="
M82.666542,1855.259155 
	C83.496994,1856.613892 83.631096,1857.717041 83.812805,1859.389038 
	C83.552299,1860.482910 83.165558,1860.941772 82.322441,1861.581299 
	C81.040092,1861.967896 80.135490,1862.107666 78.573181,1862.460693 
	C75.391983,1861.257324 73.748711,1859.255493 75.098137,1856.330688 
	C76.645340,1852.977173 79.563881,1854.920044 82.666542,1855.259155 
z"
        />
        <path
          fill="#434F51"
          opacity="1.000000"
          stroke="none"
          d="
M173.261139,1869.993286 
	C172.877899,1878.006226 171.292236,1879.055054 165.987671,1874.558838 
	C165.545578,1868.249023 168.454926,1868.032837 173.261139,1869.993286 
z"
        />
        <path
          fill="#374549"
          opacity="1.000000"
          stroke="none"
          d="
M117.995621,1866.508789 
	C118.985489,1865.995239 119.992233,1865.878174 120.972092,1865.992188 
	C123.117355,1866.241821 126.055008,1865.053467 126.911522,1868.050903 
	C127.529945,1870.215332 125.566086,1871.764526 123.860893,1872.654785 
	C120.495903,1874.411499 119.076042,1872.182617 118.087402,1868.682129 
	C117.999062,1867.678345 117.999687,1867.344116 117.995621,1866.508789 
z"
        />
        <path
          fill="#374549"
          opacity="1.000000"
          stroke="none"
          d="
M78.099579,1784.779297 
	C78.961205,1782.932373 79.941765,1781.725464 81.456299,1780.265259 
	C81.605492,1783.321533 85.334579,1785.239258 85.262680,1789.094727 
	C81.780594,1789.387695 79.573135,1788.305786 78.099579,1784.779297 
z"
        />
        <path
          fill="#374549"
          opacity="1.000000"
          stroke="none"
          d="
M269.643250,1862.599609 
	C272.390472,1862.188843 275.241058,1861.654663 275.487762,1864.881226 
	C275.687469,1867.493042 273.414795,1867.772827 270.677063,1867.966431 
	C268.782257,1866.556763 268.465607,1864.983643 269.643250,1862.599609 
z"
        />
        <path
          fill="#374549"
          opacity="1.000000"
          stroke="none"
          d="
M92.025681,1778.739990 
	C93.034691,1776.367554 94.774055,1776.036011 97.342682,1776.010986 
	C98.027809,1776.000000 98.048584,1776.982422 98.031082,1777.473022 
	C97.153450,1779.623413 96.757126,1781.999512 94.867424,1782.444458 
	C93.095085,1782.861694 92.477486,1780.886353 92.025681,1778.739990 
z"
        />
        <path
          fill="#808489"
          opacity="1.000000"
          stroke="none"
          d="
M269.715271,1861.510498 
	C270.198914,1863.468506 270.200684,1864.976929 270.076843,1867.230225 
	C266.686798,1867.806274 265.622223,1865.897095 266.010223,1862.912231 
	C266.266418,1860.941406 267.336548,1860.245972 269.715271,1861.510498 
z"
        />
        <path
          fill="#A9AEB3"
          opacity="1.000000"
          stroke="none"
          d="
M343.045227,1870.744019 
	C342.276184,1871.559082 341.392548,1871.705566 339.835083,1871.934814 
	C339.541016,1869.042358 339.920776,1866.067017 340.300537,1863.091797 
	C340.684723,1863.071655 341.068909,1863.051636 341.453094,1863.031494 
	C341.945648,1865.379517 342.438171,1867.727417 343.045227,1870.744019 
z"
        />
        <path
          fill="#4A5448"
          opacity="1.000000"
          stroke="none"
          d="
M265.500366,1797.662109 
	C265.834290,1799.096558 265.572266,1800.240112 264.810760,1801.723633 
	C262.357544,1801.423218 261.019684,1800.099609 259.979553,1797.729736 
	C261.567383,1796.183594 263.237213,1796.743896 265.500366,1797.662109 
z"
        />
        <path
          fill="#374549"
          opacity="1.000000"
          stroke="none"
          d="
M112.479156,1795.569092 
	C112.608444,1793.623657 113.263840,1792.615845 114.788307,1793.336060 
	C115.730247,1793.781250 115.877899,1794.920532 115.434509,1795.807007 
	C114.734741,1797.205811 113.704437,1797.370361 112.479156,1795.569092 
z"
        />
        <path
          fill="#4A5448"
          opacity="1.000000"
          stroke="none"
          d="
M201.475403,1771.492920 
	C202.545334,1772.488892 203.056992,1772.925415 203.778107,1773.823242 
	C203.850861,1775.564087 203.507172,1777.035767 202.059631,1776.992310 
	C199.915512,1776.928223 200.186722,1774.905273 199.900803,1772.800293 
	C200.250183,1771.749268 200.583649,1771.341309 201.475403,1771.492920 
z"
        />
        <path
          fill="#B6BCC1"
          opacity="1.000000"
          stroke="none"
          d="
M202.183624,1771.497559 
	C201.682892,1772.108276 201.332031,1772.164429 200.448944,1772.188843 
	C199.861832,1771.807739 199.806961,1771.458130 199.553864,1770.616699 
	C199.488724,1769.201172 199.621826,1768.277832 199.900116,1766.668091 
	C200.442291,1765.614624 200.839294,1765.247437 201.819748,1765.443726 
	C204.507736,1767.682495 203.119064,1769.308472 202.183624,1771.497559 
z"
        />
        <path
          fill="#374549"
          opacity="1.000000"
          stroke="none"
          d="
M90.179146,1864.930420 
	C91.755898,1865.921631 92.121269,1867.026611 91.112144,1868.158813 
	C90.908440,1868.387451 89.893715,1868.263550 89.587181,1867.962158 
	C88.599220,1866.990601 88.258492,1865.877075 90.179146,1864.930420 
z"
        />
        <path
          fill="#728BA1"
          opacity="1.000000"
          stroke="none"
          d="
M326.107269,1884.996582 
	C326.064453,1885.752930 325.724091,1886.365845 325.383575,1886.436279 
	C324.661835,1883.585327 324.280640,1880.663696 323.906647,1876.916016 
	C327.137451,1878.126831 325.692200,1881.444702 326.107269,1884.996582 
z"
        />
        <path
          fill="#4A5448"
          opacity="1.000000"
          stroke="none"
          d="
M204.896912,1783.905273 
	C205.631119,1782.568970 206.368790,1782.549927 207.019897,1783.508423 
	C207.156143,1783.709106 207.151474,1784.172363 207.009384,1784.367065 
	C206.329208,1785.300781 205.591675,1785.262451 204.896912,1783.905273 
z"
        />
        <path
          fill="#728BA1"
          opacity="1.000000"
          stroke="none"
          d="
M324.012421,1876.005615 
	C322.563812,1875.015625 322.015228,1873.580200 322.132477,1871.891602 
	C322.172485,1871.315430 322.389801,1870.353516 322.717865,1870.262939 
	C324.262329,1869.836792 323.671509,1871.260010 323.816345,1871.882446 
	C324.029694,1872.799438 323.949341,1873.784668 323.953949,1875.415405 
	C323.913849,1876.089966 323.976074,1876.028809 324.012421,1876.005615 
z"
        />
        <path
          fill="#434F51"
          opacity="1.000000"
          stroke="none"
          d="
M74.133972,1782.503418 
	C74.528297,1782.262939 75.072136,1782.611450 75.739182,1783.572510 
	C75.360474,1783.772095 74.834167,1783.407837 74.133972,1782.503418 
z"
        />
        <path
          fill="#233444"
          opacity="1.000000"
          stroke="none"
          d="
M87.945549,3042.861816 
	C92.257942,3042.057861 96.565338,3042.038086 100.870979,3041.940674 
	C103.333702,3041.885254 105.844864,3041.432861 105.423065,3038.199463 
	C105.073639,3035.520508 103.215683,3034.297363 100.386703,3034.945801 
	C94.309853,3036.338623 88.727577,3033.979980 83.111412,3032.386475 
	C80.693703,3031.700684 78.188713,3030.359131 77.631645,3026.610840 
	C90.385521,3025.095215 102.777969,3022.565186 115.565880,3021.545410 
	C126.185379,3020.698730 136.512985,3021.923584 146.964203,3022.057129 
	C151.729294,3022.117920 156.091049,3020.741699 160.606232,3019.820801 
	C162.963211,3019.340088 163.769531,3017.707031 163.969467,3014.764160 
	C171.679733,3010.490723 177.778458,3013.451904 183.663895,3019.314941 
	C185.607819,3023.459229 188.671478,3023.376953 191.632294,3022.670898 
	C207.333344,3018.927002 223.239380,3021.593506 239.038055,3021.045410 
	C242.364502,3020.930176 245.569229,3022.076172 248.528870,3023.627930 
	C256.950500,3028.043457 265.852570,3029.979004 276.158203,3028.896484 
	C291.067780,3025.396973 305.507996,3024.492676 319.853455,3022.971680 
	C325.337982,3022.390625 331.096313,3021.562500 335.883545,3026.829590 
	C331.314606,3031.498535 325.296814,3030.213135 319.984131,3031.737549 
	C314.792145,3033.226562 309.191711,3032.633545 310.875183,3041.153564 
	C311.609406,3044.869873 307.209320,3043.713623 304.763763,3043.771973 
	C302.081390,3043.836426 298.138580,3040.055176 297.008331,3044.178223 
	C295.581970,3049.381836 301.358612,3047.803711 303.941803,3048.830322 
	C312.205078,3052.114014 320.399933,3048.937988 328.422485,3047.360596 
	C336.083344,3045.854248 343.507782,3045.226318 351.039215,3047.380615 
	C354.760956,3048.445068 358.193817,3047.431885 361.784363,3046.519043 
	C366.478912,3045.324951 369.621887,3047.067139 369.939392,3053.130859 
	C353.112183,3054.406006 336.452087,3053.742676 319.757050,3059.079590 
	C309.624878,3062.318604 299.273560,3065.600098 288.869415,3068.339111 
	C283.635590,3069.717041 278.446594,3070.054932 273.130615,3070.078857 
	C270.492371,3070.090820 267.529175,3069.727539 265.277283,3070.762451 
	C257.409180,3074.377197 250.073105,3074.142822 242.444519,3068.677734 
	C240.990631,3066.374512 239.890472,3065.076416 237.492905,3064.924805 
	C220.991333,3063.881104 204.689377,3064.251953 188.634552,3071.329102 
	C185.981125,3071.890869 183.826187,3071.968262 180.862274,3072.047119 
	C165.844681,3072.972412 154.535660,3081.945557 140.717224,3086.002930 
	C138.498260,3086.034424 137.001785,3086.021240 134.757278,3085.988281 
	C132.719940,3082.642822 135.291275,3080.989990 137.516922,3078.494629 
	C140.296509,3075.937012 145.928757,3076.971680 145.240479,3072.730469 
	C144.558762,3068.530029 139.177231,3069.970215 136.018600,3068.587646 
	C134.766266,3068.039307 132.865738,3069.034424 131.249634,3069.244141 
	C124.390785,3070.134766 117.794411,3071.028809 111.112579,3067.141113 
	C107.418503,3064.991699 102.688110,3064.700195 97.761230,3065.418945 
	C90.025284,3066.547363 81.957077,3067.218018 74.242165,3064.336182 
	C72.396355,3063.646484 70.241753,3063.174072 70.661514,3060.498535 
	C70.984573,3058.439453 73.043266,3058.684326 74.385223,3057.902588 
	C80.011292,3054.625244 84.920898,3058.309814 92.648811,3060.541016 
	C86.887115,3054.354248 85.256592,3049.284180 87.945549,3042.861816 
z"
        />
        <path
          fill="#3F5467"
          opacity="1.000000"
          stroke="none"
          d="
M418.663452,3017.927734 
	C413.247223,3017.549805 408.496460,3016.929443 402.947998,3016.378418 
	C399.334808,3019.562744 395.733307,3020.338623 391.093201,3019.909668 
	C389.675385,3019.752686 388.905731,3019.618896 387.744446,3019.836914 
	C387.133148,3020.531738 386.918793,3020.845703 386.247192,3021.500488 
	C380.483154,3021.949707 375.176300,3022.058350 368.983398,3022.187988 
	C365.313446,3020.746338 364.129181,3018.156738 363.262268,3014.617188 
	C365.053375,3008.127197 371.779053,3007.872314 375.430786,3003.100586 
	C377.769501,3001.753662 379.668427,3001.387207 382.311951,3001.088623 
	C386.406891,3001.312744 387.845276,2998.377930 390.768005,2996.391602 
	C416.636017,2993.808594 441.773407,2993.543701 466.795166,2997.878418 
	C469.843689,2998.406738 472.264954,3000.212402 474.721161,3001.935791 
	C479.147919,3005.041504 484.113861,3005.769287 488.941071,3003.669434 
	C505.588501,2996.426514 523.130920,2996.885986 540.624023,2997.542969 
	C546.065857,2997.747070 550.928406,2997.515381 556.074463,2995.338623 
	C565.447571,2991.374023 575.655518,2991.129395 585.522400,2992.233887 
	C594.820068,2993.274658 603.768372,2995.360596 612.467957,2989.877441 
	C614.937317,2988.320557 618.295593,2988.770264 622.033020,2988.212891 
	C624.829590,2987.425781 626.900879,2987.408691 629.708679,2987.592285 
	C633.526550,2988.422119 636.637085,2989.397949 640.051880,2986.542480 
	C645.094543,2984.610352 649.869751,2985.285156 655.011597,2983.945801 
	C659.106445,2983.470703 661.181885,2981.426270 662.955444,2977.878418 
	C663.801514,2977.065430 664.189209,2976.786377 665.237671,2976.206787 
	C669.772278,2974.808350 673.664246,2974.899170 678.239319,2975.944580 
	C682.064941,2977.893555 684.897400,2982.006836 689.497925,2977.197754 
	C690.986145,2976.637451 691.886047,2976.543945 693.468750,2976.724609 
	C698.804260,2979.811768 703.171387,2980.145996 707.528198,2975.000244 
	C709.374329,2973.974365 710.729187,2973.621338 712.827881,2973.358154 
	C721.435364,2975.143311 727.474182,2969.811768 735.084961,2967.661133 
	C739.658691,2966.281494 743.603088,2966.911133 748.269775,2967.054688 
	C761.158447,2964.119629 772.613281,2967.759766 784.104797,2970.848389 
	C786.601074,2971.519287 789.781555,2972.091797 790.044250,2975.300537 
	C790.319641,2978.665527 787.742249,2980.718506 784.982483,2982.048096 
	C778.052185,2985.387207 770.907288,2986.296631 763.967834,2982.273193 
	C757.354553,2978.439453 751.200256,2978.039307 744.286926,2981.985107 
	C737.737427,2985.722900 729.822205,2982.998779 722.884460,2985.272705 
	C730.508789,2986.575195 738.313965,2987.670410 746.052246,2989.132812 
	C750.611633,2989.994141 754.647583,2991.563477 752.495361,2998.279785 
	C746.929565,3002.030029 741.156677,3001.680908 734.686829,3002.112793 
	C719.598267,2996.972900 705.710510,3005.238037 691.523926,3003.153809 
	C688.616882,3002.726562 685.516113,3003.668701 682.550659,3003.360596 
	C663.609619,3001.392334 645.591125,3005.916748 627.574036,3010.782471 
	C612.398926,3014.880371 597.104919,3010.173340 581.871094,3011.034424 
	C555.123169,3012.546143 528.177551,3008.262695 501.579803,3016.138428 
	C488.938934,3019.881104 475.092438,3017.913330 461.809753,3016.460205 
	C450.886810,3015.265137 439.995972,3013.642822 428.190094,3015.116699 
	C424.292236,3014.635498 421.865692,3016.330566 418.663452,3017.927734 
z"
        />
        <path
          fill="#152533"
          opacity="1.000000"
          stroke="none"
          d="
M521.561462,3073.497803 
	C520.873352,3076.198730 519.750916,3078.438965 521.752930,3081.450195 
	C522.405762,3085.388916 524.091492,3087.103027 527.700623,3087.206543 
	C530.883911,3087.297363 534.357117,3087.249023 536.168091,3091.310547 
	C532.957825,3093.426025 530.219727,3094.876953 529.792786,3098.953125 
	C529.184143,3104.764648 525.274231,3106.409912 519.847900,3106.053711 
	C514.571167,3105.707520 509.270935,3105.024658 504.084473,3104.746094 
	C493.862396,3104.197266 483.200562,3102.238281 473.412384,3107.788330 
	C471.442627,3108.905273 469.020691,3109.692627 466.778473,3109.767578 
	C461.602783,3109.940186 457.723389,3111.644287 455.972473,3116.821045 
	C455.225037,3119.030762 453.415131,3120.456787 451.449127,3119.804443 
	C444.671722,3117.556885 437.865967,3120.608887 430.889313,3118.944580 
	C424.613800,3117.447021 417.623749,3118.236084 413.379730,3124.929688 
	C411.738861,3127.517822 409.415375,3127.903564 406.444061,3127.245850 
	C398.644501,3125.519775 391.011444,3124.474609 383.532959,3129.323975 
	C380.323608,3131.404785 375.400452,3131.121094 371.899872,3130.692871 
	C363.655914,3129.684570 354.965729,3134.962891 347.109497,3128.974854 
	C346.238922,3128.311035 344.404877,3128.223877 343.315155,3128.642578 
	C333.722565,3132.330322 325.441772,3128.848389 316.418121,3123.914062 
	C323.589813,3118.010498 331.682587,3119.482422 339.113953,3119.535889 
	C351.358704,3119.624023 363.648529,3121.437744 375.713348,3118.073242 
	C383.933594,3115.781006 391.990448,3113.420166 400.770050,3113.642822 
	C406.265503,3113.782471 407.149048,3111.462891 404.600220,3106.334961 
	C395.673553,3100.773926 386.513855,3099.502197 376.775208,3099.078369 
	C359.656311,3098.333252 342.652283,3101.413330 324.775879,3099.980957 
	C321.267883,3099.854980 318.805206,3100.937744 316.225128,3101.516602 
	C313.417572,3102.146729 310.200775,3103.024170 308.152496,3098.751465 
	C313.170135,3096.037598 318.181580,3093.567627 323.864166,3092.945801 
	C326.114685,3092.699463 328.882141,3092.097412 330.352325,3090.608887 
	C341.330109,3079.492676 355.815430,3083.379150 368.866760,3081.204834 
	C372.120575,3080.662842 375.511993,3080.484619 378.579987,3079.401855 
	C382.817078,3077.906738 387.084015,3078.139893 391.392365,3077.984375 
	C395.864838,3077.823242 399.177551,3079.010254 400.305756,3083.949707 
	C401.218994,3087.947510 404.110535,3089.427979 408.052277,3090.535400 
	C415.985992,3092.765381 424.019989,3092.714111 431.782135,3091.381592 
	C437.764984,3090.354492 443.638672,3090.203125 449.595947,3089.990479 
	C463.822388,3089.482910 478.022766,3088.547119 492.090546,3086.310303 
	C497.233246,3085.492676 501.795959,3086.628174 505.979340,3089.500000 
	C508.367218,3086.299561 505.363037,3084.914551 504.745514,3082.957031 
	C504.180756,3081.166504 504.464264,3078.679688 505.957428,3078.789795 
	C512.050598,3079.238770 515.516846,3073.375732 521.561462,3073.497803 
z"
        />
        <path
          fill="#243748"
          opacity="1.000000"
          stroke="none"
          d="
M423.531250,3012.666504 
	C446.208252,3008.585449 468.090637,3015.694092 490.257935,3014.076660 
	C496.160065,3013.645996 501.843506,3014.074219 507.592926,3010.926025 
	C511.981995,3008.522217 517.595581,3007.792480 523.070557,3007.917969 
	C535.048584,3008.193115 547.137390,3008.986328 558.995667,3007.766602 
	C573.607239,3006.263916 588.023499,3008.829590 602.530701,3008.082764 
	C605.841309,3007.912109 609.170776,3007.990479 612.400208,3009.121826 
	C615.648560,3010.259521 618.893616,3009.983643 622.187439,3008.948486 
	C629.460876,3006.662109 636.673950,3004.044678 644.434448,3003.858398 
	C644.764526,3003.850586 645.214905,3003.886230 645.404175,3003.696533 
	C652.625671,2996.472656 661.575195,3000.113770 669.743958,3000.210449 
	C681.978333,3000.355225 694.187439,2998.431885 706.426208,2999.912354 
	C708.041504,3000.107666 710.691833,3000.330322 711.229858,2999.491943 
	C715.617798,2992.656982 720.578735,2994.761230 726.098877,2997.724365 
	C728.002197,2998.746338 730.614197,2998.447754 733.479736,2999.251709 
	C732.709534,3008.577881 724.970154,3008.769287 719.057922,3009.320801 
	C706.450806,3010.496094 694.359802,3014.214600 681.769287,3015.772949 
	C690.563538,3017.708740 699.989929,3016.391602 708.586792,3021.855469 
	C709.677673,3024.114014 709.607849,3025.735107 708.518677,3028.092041 
	C705.522827,3031.246338 702.410583,3032.572998 699.056335,3032.789551 
	C683.198059,3033.812744 667.270691,3033.456299 650.730713,3034.550293 
	C649.382996,3033.729492 648.860168,3033.329102 648.215820,3032.339844 
	C644.474731,3028.355469 639.625366,3027.382812 635.252747,3027.240234 
	C621.416382,3026.788818 608.028809,3025.480469 594.264221,3022.443604 
	C579.714111,3019.233398 563.981689,3020.555176 549.017395,3017.163574 
	C546.494934,3016.591797 543.683289,3016.640137 540.844482,3019.187500 
	C531.761902,3017.849854 523.831360,3017.843506 515.112732,3020.842041 
	C504.022888,3024.655518 492.014679,3025.869629 480.347198,3027.885254 
	C477.157928,3028.436035 473.742218,3028.294434 470.520447,3027.184082 
	C464.387604,3025.071289 457.709930,3024.939453 451.881439,3026.418457 
	C442.760956,3028.732422 435.018005,3026.072021 426.495270,3022.526367 
	C423.858917,3019.493896 421.841309,3016.956787 423.531250,3012.666504 
z"
        />
        <path
          fill="#4A3831"
          opacity="1.000000"
          stroke="none"
          d="
M649.681641,3034.614990 
	C664.551331,3028.612549 679.699768,3030.019531 694.695374,3030.073486 
	C698.767212,3030.088135 702.366211,3028.375000 707.768799,3028.283936 
	C714.340210,3034.262939 721.946716,3034.146484 728.977295,3037.226562 
	C734.967285,3042.577393 741.959045,3043.472412 748.591675,3045.131348 
	C753.862488,3046.449463 756.994080,3048.923096 755.152954,3055.570557 
	C750.781616,3059.594971 746.584045,3059.020996 741.727783,3058.049316 
	C734.035645,3056.510010 726.349121,3053.614746 717.504761,3055.783203 
	C715.269714,3056.464844 713.735413,3056.672852 711.429077,3056.829834 
	C709.672424,3056.883057 708.674927,3056.909668 706.947815,3056.974609 
	C699.727722,3058.561035 693.453674,3061.094971 686.029785,3061.531494 
	C682.969910,3062.610596 680.207031,3062.403076 677.555908,3064.502441 
	C676.667236,3065.066406 676.299133,3065.269043 675.356262,3065.721191 
	C672.854431,3066.631592 670.810181,3067.042725 668.761719,3068.922119 
	C667.867554,3069.508057 667.493408,3069.724365 666.525696,3070.217041 
	C663.410156,3071.389893 661.026733,3072.577148 658.111084,3074.263672 
	C648.330383,3079.008545 644.531799,3078.113281 641.452393,3070.816162 
	C638.505249,3063.832275 640.793396,3059.270996 649.496216,3055.642822 
	C654.693665,3053.476074 659.863037,3051.213379 665.245605,3049.485596 
	C668.245239,3048.522705 671.658630,3047.947998 673.787659,3043.050781 
	C662.218628,3042.463135 652.102661,3044.492676 642.202576,3047.904785 
	C637.810486,3049.418945 633.604004,3051.717773 628.432861,3048.498047 
	C629.976318,3042.936279 633.818970,3040.463623 639.071655,3040.050781 
	C643.115723,3039.732666 646.646606,3038.406738 649.681641,3034.614990 
z"
        />
        <path
          fill="#5E4237"
          opacity="1.000000"
          stroke="none"
          d="
M627.345337,3048.234863 
	C632.265686,3048.072021 636.133911,3047.543457 640.265442,3045.392334 
	C650.068176,3040.288818 661.432617,3041.128906 671.982788,3038.618408 
	C676.706055,3037.494385 676.761169,3043.143555 680.546143,3045.563965 
	C675.127930,3048.784668 671.025208,3051.568848 665.667542,3052.509521 
	C659.519287,3053.589111 654.321594,3057.470215 648.225708,3059.154541 
	C642.010315,3060.872070 641.211304,3063.878662 643.838562,3069.665527 
	C646.890198,3076.387207 647.649414,3076.599854 654.807617,3072.829590 
	C655.381836,3072.527100 656.050964,3072.404541 657.392944,3072.133301 
	C658.734985,3072.793457 659.121765,3073.624756 659.132019,3075.272217 
	C655.684509,3081.706299 648.962585,3083.523438 644.267517,3088.712891 
	C640.292603,3092.368652 636.768738,3095.540771 633.857605,3100.158203 
	C632.208557,3102.536377 630.933716,3104.373047 629.120483,3106.677002 
	C627.564941,3108.144531 626.316406,3108.757568 624.176575,3108.937500 
	C616.989380,3106.223633 617.455994,3101.867920 620.588989,3096.904541 
	C622.313110,3094.172852 624.945251,3091.995117 626.070190,3088.252441 
	C617.281738,3087.435059 608.823181,3091.435303 600.505432,3087.939697 
	C598.259338,3086.995361 596.973022,3089.154541 595.480225,3090.357666 
	C592.547485,3092.721436 589.419983,3094.423096 584.836975,3093.045898 
	C583.952026,3092.302734 583.691895,3091.903564 583.703064,3090.843262 
	C588.380920,3085.435791 592.682983,3080.586426 597.230591,3075.979248 
	C600.255554,3072.914795 602.726929,3069.607666 604.176636,3064.785156 
	C608.434143,3063.819580 610.440002,3060.491455 613.028687,3058.073730 
	C617.146301,3054.227783 621.918762,3051.359375 627.345337,3048.234863 
z"
        />
        <path
          fill="#243748"
          opacity="1.000000"
          stroke="none"
          d="
M118.009125,3137.246094 
	C107.730736,3141.636475 97.231491,3144.766113 86.705399,3149.822021 
	C77.834625,3151.713867 70.267052,3154.981201 62.439644,3159.390625 
	C55.919109,3159.949707 49.926922,3160.050293 43.046329,3160.185547 
	C37.487629,3159.195312 33.820984,3161.321533 30.136637,3163.786865 
	C28.637733,3164.789795 26.448912,3166.402100 24.887480,3164.129395 
	C23.377815,3161.931641 23.839783,3159.193359 26.511700,3158.370361 
	C31.049139,3156.972656 30.370131,3151.764648 34.600517,3149.209961 
	C40.234241,3145.807861 44.701588,3143.670898 51.401333,3144.522705 
	C55.671688,3145.065674 60.201412,3143.425781 64.085464,3139.923828 
	C74.519646,3130.517090 85.943436,3123.732422 100.683578,3131.095703 
	C104.042809,3132.773682 107.915321,3131.873291 111.558228,3131.967773 
	C114.742500,3132.050293 117.702583,3132.240723 118.009125,3137.246094 
z"
        />
        <path
          fill="#3A4B59"
          opacity="1.000000"
          stroke="none"
          d="
M541.090637,3019.789062 
	C539.602478,3014.362793 542.987549,3013.808838 547.067749,3014.034424 
	C551.356628,3014.271240 555.522705,3015.467041 559.689331,3016.231201 
	C571.189331,3018.341064 582.707520,3017.882324 594.241272,3017.975830 
	C597.956299,3018.005859 601.536743,3018.048584 603.966003,3021.572510 
	C605.987427,3024.504639 609.183655,3023.993164 612.111633,3024.006836 
	C619.390076,3024.040771 626.729919,3023.403564 633.926697,3024.161377 
	C638.954224,3024.690674 645.452148,3023.435791 647.950073,3031.126709 
	C624.448914,3028.970459 600.794739,3030.437988 577.196716,3029.882080 
	C567.148987,3029.645508 557.397095,3031.752197 547.545227,3033.103271 
	C538.798889,3034.302979 530.090820,3034.720215 521.320251,3031.973877 
	C532.739502,3025.523193 546.329895,3028.266113 558.943665,3023.055664 
	C553.443848,3017.888672 547.277344,3020.925537 541.090637,3019.789062 
z"
        />
        <path
          fill="#485D70"
          opacity="1.000000"
          stroke="none"
          d="
M363.640442,3013.169922 
	C365.590240,3016.108398 368.444214,3017.492188 368.058777,3021.503906 
	C362.547455,3025.747070 355.941071,3023.059326 350.049194,3025.363037 
	C346.193542,3026.870361 341.789398,3026.974609 336.822205,3027.708984 
	C329.740326,3024.608887 323.000824,3025.591797 316.643616,3026.469971 
	C303.651215,3028.264893 290.414398,3028.410645 276.843872,3031.955078 
	C273.993591,3030.004883 270.258575,3028.884766 271.410583,3025.140869 
	C272.426086,3021.840576 275.753632,3021.270752 278.726257,3020.923828 
	C294.199921,3019.117188 309.553528,3016.279053 325.073212,3014.998047 
	C333.694824,3014.286377 342.145203,3013.676270 350.321838,3010.415771 
	C354.673309,3008.680420 359.457367,3008.391602 363.640442,3013.169922 
z"
        />
        <path
          fill="#152533"
          opacity="1.000000"
          stroke="none"
          d="
M412.238464,3072.580322 
	C413.101837,3070.109863 415.177856,3069.563477 417.443909,3068.253418 
	C418.351562,3070.827393 420.115631,3071.737793 422.769012,3072.017822 
	C433.590271,3073.160889 443.472931,3079.092529 454.727509,3078.262207 
	C456.725525,3078.114990 458.944397,3079.280762 458.735291,3081.656250 
	C458.499084,3084.339355 455.895233,3083.905518 454.024261,3083.956543 
	C444.460938,3084.216064 434.899994,3083.504639 425.377655,3085.734131 
	C421.215485,3086.708496 417.029755,3084.175537 413.121979,3082.379883 
	C410.772095,3081.300293 410.545258,3079.651123 412.325714,3077.723633 
	C413.670227,3076.268311 413.256287,3074.703369 412.238464,3072.580322 
z"
        />
        <path
          fill="#152533"
          opacity="1.000000"
          stroke="none"
          d="
M587.159180,3060.011475 
	C587.700134,3069.473877 587.700134,3069.473877 578.745544,3071.949463 
	C577.813660,3070.674316 577.969788,3069.253662 577.455505,3068.143799 
	C573.816833,3060.287842 573.772766,3060.302246 582.417847,3060.006836 
	C583.710876,3059.962646 585.006775,3060.002441 587.159180,3060.011475 
z"
        />
        <path
          fill="#243748"
          opacity="1.000000"
          stroke="none"
          d="
M135.851807,3129.205078 
	C133.195328,3129.961426 130.441605,3129.987793 126.861984,3130.024170 
	C124.275414,3129.716064 121.760460,3130.598389 121.255638,3128.050781 
	C120.861603,3126.062012 123.015137,3125.409424 124.421265,3124.985107 
	C128.890137,3123.636230 133.453232,3122.554688 135.851807,3129.205078 
z"
        />
        <path
          fill="#243748"
          opacity="1.000000"
          stroke="none"
          d="
M387.517334,3017.596924 
	C392.296082,3018.181885 396.489594,3017.717041 401.360809,3016.409668 
	C401.999847,3019.677002 400.908508,3022.002197 397.117157,3021.940674 
	C393.938751,3021.889160 390.758667,3021.943604 386.684631,3021.895996 
	C385.856750,3020.994141 385.923584,3020.146729 386.028656,3018.661865 
	C386.384125,3017.758789 386.701324,3017.493652 387.517334,3017.596924 
z"
        />
        <path
          fill="#152533"
          opacity="1.000000"
          stroke="none"
          d="
M287.316956,3104.126709 
	C287.972748,3109.846191 293.392456,3109.444824 298.454681,3111.901855 
	C292.597656,3115.330811 288.833588,3112.082520 284.492676,3110.548340 
	C284.118011,3107.755127 283.728882,3105.252197 287.316956,3104.126709 
z"
        />
        <path
          fill="#33495B"
          opacity="1.000000"
          stroke="none"
          d="
M423.314575,3012.085449 
	C424.656799,3014.846436 425.316010,3017.683105 425.986389,3021.254883 
	C423.289246,3021.843018 421.008392,3020.612549 418.407471,3018.663086 
	C418.159149,3015.100586 419.405396,3012.867432 423.314575,3012.085449 
z"
        />
        <path
          fill="#152533"
          opacity="1.000000"
          stroke="none"
          d="
M430.660278,3065.990234 
	C430.197723,3064.716553 430.311279,3063.265869 431.910278,3063.042480 
	C433.028595,3062.886475 433.536835,3063.847168 433.925964,3065.397949 
	C433.127411,3065.978271 432.230255,3065.985596 430.660278,3065.990234 
z"
        />
        <path
          fill="#152533"
          opacity="1.000000"
          stroke="none"
          d="
M291.412476,3100.145508 
	C291.806793,3101.831299 291.242767,3103.353027 288.624146,3103.903564 
	C288.195038,3102.205322 288.740967,3100.666016 291.412476,3100.145508 
z"
        />
        <path
          fill="#F5F1E5"
          opacity="1.000000"
          stroke="none"
          d="
M1099.284668,3898.458740 
	C1099.266479,3896.481934 1099.461304,3895.010498 1099.537598,3892.799805 
	C1100.605469,3888.803467 1100.107422,3885.482910 1099.694092,3882.195068 
	C1096.074463,3853.408691 1096.242065,3824.645020 1097.815308,3795.656494 
	C1099.342407,3767.518311 1100.121338,3739.242676 1096.709229,3711.002686 
	C1095.082764,3697.541016 1096.031372,3683.759277 1095.957886,3670.121582 
	C1095.845093,3649.208740 1096.518066,3628.283203 1094.306396,3607.418945 
	C1093.817383,3602.804199 1093.921143,3598.118164 1093.898926,3593.464111 
	C1093.719360,3555.900146 1093.979248,3518.328613 1093.268188,3480.775146 
	C1092.840332,3458.164062 1091.370850,3435.563721 1092.038818,3412.938232 
	C1092.549438,3395.636963 1092.237793,3378.433105 1090.801880,3361.137695 
	C1089.626831,3346.983643 1089.725586,3332.600098 1091.792847,3317.542969 
	C1096.840454,3318.281494 1099.107422,3321.771240 1098.944214,3326.571289 
	C1097.995483,3354.477051 1101.452881,3382.323730 1099.928467,3410.239502 
	C1099.183716,3423.880127 1100.591309,3437.509766 1100.692749,3451.135986 
	C1100.918213,3481.402100 1103.815430,3511.567383 1103.557861,3541.850098 
	C1103.422363,3557.781738 1103.211548,3573.725586 1104.230713,3590.324951 
	C1105.196899,3591.348145 1105.630981,3591.707031 1106.654297,3592.662598 
	C1112.159546,3600.453857 1117.644043,3607.419922 1116.684082,3617.765625 
	C1115.108521,3624.461182 1112.918945,3629.322021 1104.762695,3625.610352 
	C1101.373169,3619.741699 1102.395874,3613.768555 1101.992920,3610.597168 
	C1102.880859,3614.666260 1100.685059,3621.986328 1106.235596,3628.215332 
	C1114.678345,3631.565186 1118.839600,3636.745361 1116.526733,3646.172852 
	C1115.255493,3647.394287 1114.347046,3647.816406 1112.616455,3648.136719 
	C1109.195679,3648.421631 1107.161011,3646.615723 1104.961060,3645.865234 
	C1104.406372,3656.473145 1106.159668,3666.990479 1105.191406,3678.221436 
	C1104.879517,3680.211914 1104.784058,3681.547363 1104.082275,3683.375977 
	C1103.493286,3684.621094 1102.243408,3684.561768 1102.329956,3685.347900 
	C1102.386963,3685.866455 1103.146484,3685.695557 1104.114746,3684.712402 
	C1113.363281,3686.226074 1119.151611,3692.358154 1125.591919,3698.625977 
	C1127.434204,3705.617920 1129.638428,3711.630859 1134.976807,3716.812500 
	C1136.966431,3722.671387 1141.124146,3726.484863 1143.661865,3732.009033 
	C1144.745361,3735.187744 1144.901733,3737.986328 1147.057739,3740.778809 
	C1147.582397,3741.824951 1147.768188,3742.258545 1148.171875,3743.369629 
	C1152.933594,3767.720215 1163.301147,3788.413330 1180.039185,3805.836914 
	C1181.441162,3807.296631 1182.891846,3808.795166 1183.238892,3810.803955 
	C1186.708252,3830.886475 1201.075317,3840.871338 1218.152954,3847.990479 
	C1230.464355,3853.122559 1232.697754,3860.511963 1223.687378,3871.048828 
	C1219.986206,3875.376953 1216.378052,3879.603027 1213.846680,3885.355957 
	C1213.795654,3887.206787 1214.432373,3887.919922 1216.319336,3887.996338 
	C1217.760986,3887.956787 1218.595581,3887.993896 1220.069824,3888.226562 
	C1223.408081,3889.252197 1224.664673,3891.676025 1226.466919,3893.569580 
	C1228.983398,3896.213379 1231.856445,3897.560791 1234.894531,3893.055176 
	C1237.536377,3888.713135 1241.088135,3887.143555 1246.123901,3886.916016 
	C1255.248291,3890.070801 1260.280273,3895.104980 1257.100464,3905.466553 
	C1255.653809,3906.809570 1254.594360,3907.057861 1252.728760,3906.456543 
	C1245.243408,3904.861572 1241.876831,3911.885742 1235.907227,3912.758789 
	C1234.840698,3912.915039 1234.666504,3914.289307 1234.921631,3915.268066 
	C1236.548950,3921.510254 1230.778320,3923.742920 1228.102539,3927.572021 
	C1227.104492,3928.999756 1225.021484,3929.497070 1222.707764,3928.694580 
	C1215.619751,3926.235352 1215.559937,3926.386230 1212.164185,3932.812744 
	C1211.706299,3933.679199 1211.301392,3934.581055 1210.966187,3935.501221 
	C1210.874390,3935.753418 1211.124512,3936.129883 1211.260986,3936.598633 
	C1213.878540,3939.221436 1217.645752,3937.133057 1220.577393,3938.570557 
	C1222.511597,3939.519287 1225.739746,3939.784668 1224.833252,3942.997314 
	C1223.985352,3946.001465 1221.513306,3945.618896 1219.131348,3944.528564 
	C1216.747925,3943.437500 1214.475464,3941.924561 1211.577271,3943.024170 
	C1209.559082,3943.789795 1208.912109,3942.141357 1207.832397,3940.796387 
	C1205.172363,3937.485352 1203.587646,3933.467285 1200.168213,3930.595947 
	C1196.819580,3927.784424 1197.855225,3924.503906 1201.369995,3922.474854 
	C1205.313599,3920.197754 1209.075684,3917.691162 1214.381470,3919.545654 
	C1218.175171,3920.871582 1222.574097,3920.466064 1227.432983,3920.897949 
	C1224.990967,3915.833740 1220.482788,3915.396729 1217.012451,3914.883789 
	C1211.157471,3914.018066 1206.880493,3911.068359 1203.977051,3906.621826 
	C1200.543091,3901.361816 1196.586304,3902.353271 1193.143188,3905.216553 
	C1187.716064,3909.729736 1182.591187,3912.055176 1176.173828,3907.459473 
	C1174.189453,3906.038330 1172.216431,3906.716553 1170.969971,3908.544189 
	C1169.305420,3910.984863 1167.181763,3912.086670 1164.281982,3912.091309 
	C1162.514893,3912.093994 1160.820801,3912.715820 1161.087280,3914.875000 
	C1161.251099,3916.201660 1162.504639,3917.070068 1163.832275,3917.504395 
	C1164.458008,3917.708740 1165.140747,3917.733643 1165.776001,3917.915527 
	C1168.147949,3918.594727 1171.833008,3916.628906 1172.709351,3920.060547 
	C1173.550171,3923.353271 1169.530396,3923.711426 1167.768433,3925.469971 
	C1165.178223,3928.054688 1162.393188,3930.569092 1168.553101,3932.166504 
	C1173.383667,3933.419189 1173.338135,3938.612305 1172.921021,3941.153564 
	C1171.985718,3946.851807 1175.152222,3949.001221 1178.791138,3951.231201 
	C1181.670288,3952.995605 1184.275024,3954.975586 1186.367920,3957.614258 
	C1187.457886,3958.988281 1188.129761,3960.575439 1187.192383,3962.201416 
	C1186.198853,3963.924561 1184.403931,3963.949219 1182.821777,3963.259277 
	C1176.795898,3960.630127 1168.319092,3962.618896 1165.424805,3953.983154 
	C1164.926636,3952.496826 1163.250610,3953.137207 1161.982544,3952.958740 
	C1155.779419,3952.084717 1155.415039,3951.557373 1159.569336,3946.302734 
	C1161.591187,3943.744629 1161.017212,3942.422363 1159.389404,3940.400879 
	C1156.948242,3937.367920 1153.630249,3936.015137 1150.060913,3935.035889 
	C1144.848877,3933.605713 1144.802246,3933.673340 1145.903564,3941.418457 
	C1143.842407,3944.015869 1141.350708,3944.678467 1137.812378,3944.138916 
	C1133.726440,3942.755615 1131.268311,3940.365479 1130.582886,3937.013428 
	C1128.258789,3925.646484 1119.485107,3917.897217 1114.630737,3907.757568 
	C1113.020020,3914.350342 1114.121094,3920.824463 1114.284302,3927.279541 
	C1114.390137,3931.470703 1115.632324,3936.011963 1110.143311,3938.798096 
	C1101.982422,3929.363770 1103.460815,3917.698730 1101.645630,3906.868408 
	C1101.331909,3904.997070 1102.067139,3902.935059 1100.398193,3900.552734 
	C1099.750122,3899.649658 1099.623901,3899.306885 1099.284668,3898.458740 
z"
        />
        <path
          fill="#DFD9CC"
          opacity="1.000000"
          stroke="none"
          d="
M1135.332642,3942.029297 
	C1138.777466,3942.049805 1141.545410,3942.054688 1145.141968,3942.088379 
	C1146.841064,3951.868652 1150.837646,3960.013916 1159.257446,3965.216064 
	C1162.063721,3966.949951 1163.193604,3968.588135 1164.244873,3971.711426 
	C1167.100586,3980.195801 1172.552246,3986.597900 1182.151611,3987.997803 
	C1184.101685,3988.281982 1186.583130,3987.875977 1187.337769,3990.118408 
	C1190.193481,3998.603271 1198.540283,4002.129883 1203.638550,4008.571289 
	C1206.314575,4011.952148 1209.321045,4015.074463 1212.223145,4018.272461 
	C1213.452881,4019.627441 1214.140625,4021.100830 1212.890381,4022.658691 
	C1211.619629,4024.242676 1209.868652,4023.754883 1208.330078,4023.045410 
	C1199.946899,4019.178955 1191.578735,4015.280029 1183.236084,4011.327393 
	C1182.373657,4010.918701 1181.313232,4010.229004 1181.019897,4009.424072 
	C1177.169189,3998.853027 1165.770996,3997.458740 1159.684937,3990.812744 
	C1156.492310,3991.100098 1156.175659,3992.749756 1155.931274,3994.418457 
	C1155.334717,3998.493164 1152.666870,3999.661865 1148.910522,4000.026367 
	C1141.373413,4000.758057 1134.417725,3998.709473 1126.717407,3996.156738 
	C1125.327271,3995.825195 1124.815674,3995.432861 1124.156250,3994.493164 
	C1122.518921,3992.536621 1120.340820,3992.250732 1118.398682,3990.475098 
	C1113.061401,3986.934570 1109.155273,3982.836670 1103.074219,3980.812012 
	C1096.724365,3978.698730 1092.067993,3972.565918 1090.612305,3964.501953 
	C1089.783203,3963.506592 1089.758423,3962.960449 1090.295166,3962.086670 
	C1093.228394,3960.651367 1095.869141,3959.475098 1099.533691,3960.393066 
	C1101.868042,3960.981445 1103.520020,3961.173584 1105.563477,3959.838379 
	C1106.300537,3959.147461 1106.585449,3958.860352 1107.262939,3958.114746 
	C1109.367554,3955.846191 1108.597046,3953.853027 1107.930420,3951.221680 
	C1107.388306,3948.223389 1107.419678,3945.914307 1108.654785,3943.065918 
	C1109.747192,3941.681152 1111.288696,3941.177246 1111.573242,3941.772705 
	C1117.306641,3953.771484 1124.132080,3947.140137 1130.850342,3943.036377 
	C1131.988525,3942.340820 1133.303467,3942.043945 1135.332642,3942.029297 
z"
        />
        <path
          fill="#E6E1D0"
          opacity="1.000000"
          stroke="none"
          d="
M1251.886597,3906.697266 
	C1252.900391,3906.009521 1253.783081,3906.004883 1255.328003,3905.993652 
	C1261.596680,3896.919678 1269.123413,3893.387451 1280.235718,3894.511230 
	C1291.300659,3895.629883 1302.650757,3895.214600 1313.289673,3889.946289 
	C1320.287109,3886.481201 1328.541138,3887.681641 1336.218872,3885.489990 
	C1341.988647,3883.843262 1350.804810,3888.858887 1355.724121,3894.229492 
	C1360.471313,3899.412842 1356.823364,3905.926514 1352.960815,3910.506592 
	C1347.345093,3917.165039 1345.680298,3925.216797 1342.576172,3933.533936 
	C1341.111206,3934.021729 1340.222534,3934.014160 1338.667236,3934.008057 
	C1329.140869,3931.550537 1320.449829,3929.016602 1312.183472,3936.172607 
	C1308.211426,3939.611084 1293.842651,3933.717773 1292.728027,3928.699707 
	C1292.089600,3925.825928 1295.915894,3924.354248 1294.984009,3921.417480 
	C1290.424072,3918.773682 1285.872192,3916.165283 1281.355957,3913.496582 
	C1280.029785,3912.713135 1278.848877,3912.739746 1277.809814,3913.842285 
	C1276.303711,3915.440186 1276.673096,3916.951904 1278.556641,3917.748291 
	C1280.591553,3918.608398 1281.744995,3919.967285 1282.295776,3922.034912 
	C1283.036987,3924.817871 1287.280640,3927.112061 1285.178833,3929.934082 
	C1282.505493,3933.523682 1280.317383,3928.112793 1277.518921,3928.256348 
	C1272.472046,3928.514893 1268.903198,3925.971680 1265.350586,3922.613525 
	C1260.388306,3917.922363 1254.004883,3920.347412 1248.176025,3920.355957 
	C1246.081177,3920.359131 1243.528564,3922.587891 1242.413696,3919.048096 
	C1241.578491,3916.395996 1243.292114,3914.864014 1245.218506,3913.279541 
	C1247.476562,3911.422607 1250.562256,3910.422607 1251.886597,3906.697266 
z"
        />
        <path
          fill="#F5F1E5"
          opacity="1.000000"
          stroke="none"
          d="
M1273.923828,4005.360352 
	C1273.040649,4005.776123 1271.995972,4005.721680 1271.140503,4005.291260 
	C1269.627075,4004.529785 1267.992554,4003.125977 1269.347778,4001.542236 
	C1270.877075,3999.754883 1272.211060,4001.541504 1273.161377,4002.880859 
	C1273.527344,4003.396729 1273.626709,4004.102051 1273.923828,4005.360352 
z"
        />
        <path
          fill="#152533"
          opacity="1.000000"
          stroke="none"
          d="
M402.417297,3150.173828 
	C401.934235,3148.823975 401.996246,3148.156006 401.954285,3147.494873 
	C401.406403,3138.859131 402.125366,3137.136230 412.401489,3138.313232 
	C416.880035,3138.826172 420.672455,3138.874023 424.752991,3136.832520 
	C430.529236,3133.942871 436.872253,3133.712891 443.251678,3133.964111 
	C447.873291,3134.146240 452.669128,3134.592773 456.055328,3130.255371 
	C456.435089,3129.768799 457.270599,3129.174316 457.736267,3129.286621 
	C466.311432,3131.355957 471.535645,3124.560303 478.210632,3121.638672 
	C478.514771,3121.505615 478.843079,3121.425537 479.141174,3121.281250 
	C486.291809,3117.819336 491.440247,3109.884277 501.971344,3115.153320 
	C509.109680,3118.724854 518.404236,3114.595459 526.521484,3117.172607 
	C531.071716,3118.617432 535.773743,3117.702148 541.305542,3118.107666 
	C542.909485,3123.111816 545.123901,3125.958740 550.509583,3127.489014 
	C563.092957,3131.065186 563.821167,3132.688721 559.449890,3145.310059 
	C556.689270,3153.281006 557.190491,3161.779541 554.305054,3170.431396 
	C552.131714,3175.177979 553.691528,3180.003906 550.513794,3184.496094 
	C540.244141,3188.743408 530.034607,3187.895752 519.143433,3188.606201 
	C499.177826,3181.487793 479.614960,3179.540527 459.545959,3182.815186 
	C458.235809,3183.029053 456.894653,3183.181641 455.576050,3183.087158 
	C450.628784,3182.732910 445.037445,3183.123291 444.460541,3176.064697 
	C443.962280,3169.968018 450.505859,3162.426025 456.437042,3162.641357 
	C466.720490,3163.014648 476.985229,3163.519287 488.077942,3162.267578 
	C490.540009,3161.543213 492.217743,3161.479492 494.695648,3161.599121 
	C506.894867,3163.840332 511.437866,3162.068848 516.510620,3152.693359 
	C520.549316,3145.228760 527.873718,3145.548584 534.638245,3143.854248 
	C521.945007,3143.692139 509.268951,3141.615723 496.634888,3145.075684 
	C489.827209,3146.940186 482.722931,3146.881104 475.810638,3146.110107 
	C455.765778,3143.873291 436.162781,3149.457031 416.244781,3149.057373 
	C414.164398,3149.015625 412.484039,3150.276367 410.000061,3151.522217 
	C407.020294,3152.309082 404.889465,3152.108154 402.417297,3150.173828 
z"
        />
        <path
          fill="#152533"
          opacity="1.000000"
          stroke="none"
          d="
M182.584290,3168.417969 
	C179.510483,3159.631348 173.035477,3156.138916 165.953171,3160.283203 
	C160.505463,3163.470703 156.260498,3160.341309 151.860855,3158.980957 
	C149.099121,3158.127197 150.116119,3154.645508 152.289200,3153.422363 
	C156.624207,3150.981445 161.341705,3149.019775 166.513596,3150.508301 
	C172.401855,3152.202881 177.835907,3150.122314 182.780807,3147.826416 
	C187.327911,3145.715088 193.875610,3148.419434 196.657578,3141.905518 
	C197.577377,3139.751953 199.938950,3140.236084 201.888947,3141.233398 
	C211.236603,3146.016113 220.197769,3145.345459 228.993652,3139.608887 
	C232.307114,3137.448242 235.727844,3136.300293 240.209641,3137.464600 
	C245.245224,3138.772705 250.896454,3139.025146 255.716721,3136.056396 
	C260.478088,3133.124023 264.926117,3134.472656 268.764801,3137.115723 
	C274.362396,3140.969238 280.507172,3140.476807 286.647858,3140.727783 
	C286.960541,3140.740723 287.295685,3140.427246 287.596954,3140.463379 
	C300.304504,3141.974609 312.878906,3144.523682 325.713287,3145.119629 
	C328.037750,3145.227783 329.805847,3147.234131 330.025879,3150.710693 
	C328.744965,3154.240723 326.135101,3154.268799 322.864075,3154.436035 
	C320.239471,3154.262695 318.369232,3153.726074 315.748901,3154.327148 
	C314.556610,3154.593018 314.071777,3154.693604 312.838928,3154.874023 
	C303.227539,3158.614502 293.592896,3158.193604 283.616211,3160.403809 
	C276.614532,3161.668213 270.365448,3161.471436 263.273926,3161.187500 
	C244.815765,3161.122070 228.068466,3167.451660 209.719421,3168.268066 
	C200.312378,3167.959229 191.862717,3172.469238 182.584290,3168.417969 
z"
        />
        <path
          fill="#3A4B59"
          opacity="1.000000"
          stroke="none"
          d="
M319.272522,3152.231445 
	C322.806366,3151.923340 325.612762,3151.856689 329.249847,3151.625488 
	C340.698547,3149.433594 351.448120,3150.239258 363.077637,3149.941650 
	C366.526154,3149.234375 369.068970,3149.267090 372.239960,3149.719482 
	C374.450348,3149.959961 376.037598,3150.270264 378.848572,3150.057617 
	C386.737549,3149.994629 393.430939,3149.905029 401.022400,3149.652100 
	C404.032806,3149.581055 406.145081,3149.673096 409.048889,3149.703857 
	C415.168091,3146.711914 421.153687,3147.328613 426.487427,3148.223389 
	C447.028809,3151.668701 467.736084,3148.652344 488.279297,3150.573730 
	C491.931824,3150.915527 495.630981,3150.929932 499.086151,3152.318115 
	C502.248657,3153.588867 505.432770,3155.143066 505.163422,3159.250488 
	C504.858154,3163.905762 501.146362,3163.810547 496.883484,3163.975586 
	C494.498169,3163.985840 492.998779,3163.990479 490.749695,3163.998779 
	C467.232239,3164.706787 444.531860,3160.847412 421.708862,3163.292480 
	C411.775848,3164.356201 401.826538,3165.065186 392.442749,3168.678467 
	C379.446075,3173.682861 365.906708,3174.023682 351.560242,3173.696777 
	C345.674011,3167.449463 349.501709,3164.367920 354.143707,3161.815674 
	C345.415009,3161.463379 336.242310,3161.044434 327.056915,3160.944824 
	C323.193817,3160.903076 318.929077,3161.006348 316.954834,3155.649902 
	C317.147217,3153.887695 317.690857,3153.077148 319.272522,3152.231445 
z"
        />
        <path
          fill="#374549"
          opacity="1.000000"
          stroke="none"
          d="
M517.172791,3185.992188 
	C528.061707,3185.406738 538.000671,3183.146973 549.057434,3183.979980 
	C553.585083,3180.236572 551.342346,3175.544922 552.087524,3170.679443 
	C553.755493,3160.026611 554.545471,3149.902588 558.176086,3140.265625 
	C560.620056,3133.778564 560.210754,3133.743652 553.845520,3131.572021 
	C548.353088,3129.698242 542.870422,3127.814941 537.388611,3127.460938 
	C536.498352,3122.524902 541.265076,3122.383057 542.038208,3118.805176 
	C549.272156,3119.615967 556.334290,3121.231934 563.666748,3116.677979 
	C564.796509,3114.323730 566.042480,3113.460205 568.566040,3114.211670 
	C571.302185,3124.276123 566.529297,3132.600830 564.441345,3141.305420 
	C562.756775,3148.328369 560.337036,3155.127197 561.118530,3163.292236 
	C561.501892,3168.810791 561.197937,3173.532227 561.221924,3178.991699 
	C560.905334,3182.488037 560.508667,3185.257568 562.487854,3188.366211 
	C563.179810,3189.754395 563.432983,3190.594482 563.636597,3192.104980 
	C563.692566,3193.997070 563.569763,3195.246094 563.597778,3197.115234 
	C563.612549,3198.558838 563.503784,3199.373291 562.517090,3201.071045 
	C557.631165,3200.754639 553.534485,3199.543701 548.724243,3198.266357 
	C541.223022,3196.412109 534.424561,3195.583252 527.383423,3196.869141 
	C523.483398,3197.581299 519.456116,3197.472412 515.636108,3195.869629 
	C513.159668,3194.830811 510.481110,3194.001953 510.784393,3190.530762 
	C511.077393,3187.177734 513.468933,3186.284424 517.172791,3185.992188 
z"
        />
        <path
          fill="#1B2B3A"
          opacity="1.000000"
          stroke="none"
          d="
M324.391541,3100.679688 
	C324.390411,3097.791504 325.310394,3096.143311 327.947906,3097.035889 
	C332.581909,3098.604004 337.009583,3097.056152 341.597229,3096.699463 
	C356.797272,3095.517822 372.066650,3095.358643 387.292480,3096.800049 
	C388.591156,3096.923340 390.063049,3098.059082 391.095123,3097.697754 
	C398.024719,3095.272217 401.168457,3099.366943 403.883728,3105.328125 
	C400.773621,3107.913086 397.223663,3108.165771 392.808624,3107.956055 
	C389.707825,3105.322021 385.920715,3106.312744 382.649628,3104.213379 
	C369.300659,3107.163818 356.493835,3106.724121 343.721375,3104.812500 
	C340.427612,3104.319336 337.181000,3104.074219 333.886627,3103.997559 
	C330.619446,3103.921387 327.166138,3104.489746 324.391541,3100.679688 
z"
        />
        <path
          fill="#1B2B3A"
          opacity="1.000000"
          stroke="none"
          d="
M536.859131,3091.982666 
	C533.763367,3089.397949 530.708801,3090.114502 527.868408,3089.997559 
	C525.612610,3089.904541 523.338379,3090.017822 521.101868,3089.771240 
	C519.121277,3089.552979 517.103455,3088.751953 517.023376,3086.422852 
	C516.935486,3083.865479 519.812805,3084.252441 521.557861,3082.518555 
	C531.100891,3079.277832 539.984497,3081.115479 548.818481,3083.415771 
	C550.593872,3083.878174 553.140930,3084.028564 552.993164,3086.658447 
	C552.877258,3088.718750 551.116821,3090.106689 549.337036,3090.968018 
	C545.628113,3092.763184 541.596741,3091.700684 536.859131,3091.982666 
z"
        />
        <path
          fill="#152533"
          opacity="1.000000"
          stroke="none"
          d="
M568.891785,3114.556641 
	C567.223755,3114.848389 566.201965,3115.251953 564.686768,3115.902588 
	C556.699768,3111.150391 547.915833,3112.326172 539.245850,3111.678223 
	C544.148193,3103.712891 550.551819,3102.235596 565.192322,3105.634277 
	C567.271484,3107.651123 569.662048,3108.134766 570.920410,3110.771973 
	C571.123962,3111.827637 571.118408,3112.264160 570.861328,3113.306641 
	C570.252625,3114.164307 569.895386,3114.416504 568.891785,3114.556641 
z"
        />
        <path
          fill="#1B2B3A"
          opacity="1.000000"
          stroke="none"
          d="
M62.711205,3159.790527 
	C62.060032,3159.222412 62.009621,3158.227539 62.386852,3158.023682 
	C69.555878,3154.144775 75.030212,3146.030273 85.445503,3149.384277 
	C85.447838,3156.822021 79.411003,3156.499268 75.027626,3157.609619 
	C71.295250,3158.554932 67.217018,3158.206787 62.711205,3159.790527 
z"
        />
        <path
          fill="#1F3041"
          opacity="1.000000"
          stroke="none"
          d="
M181.943146,3168.354492 
	C189.253845,3167.858887 196.781143,3169.402100 203.324310,3164.362793 
	C203.995102,3163.846191 205.732910,3164.715576 207.490021,3165.460938 
	C209.699005,3168.012207 209.030960,3169.783691 206.689468,3171.767822 
	C198.288010,3171.587158 190.451172,3173.955322 182.417587,3170.313232 
	C181.940414,3169.732422 181.929779,3168.813721 181.943146,3168.354492 
z"
        />
        <path
          fill="#152533"
          opacity="1.000000"
          stroke="none"
          d="
M575.304321,3104.242432 
	C572.895935,3101.919678 572.211182,3099.594238 570.104370,3098.488037 
	C568.085449,3097.428467 565.704529,3096.780762 566.594666,3093.730225 
	C567.326538,3091.222412 569.332031,3090.321289 571.732544,3090.119873 
	C572.690186,3090.039551 573.659058,3090.093262 575.413574,3090.135010 
	C575.923584,3093.669678 579.718140,3094.034912 581.211792,3096.980469 
	C581.677673,3098.487793 581.703491,3099.390869 581.381348,3100.966797 
	C580.166626,3103.419922 578.527649,3104.235107 575.304321,3104.242432 
z"
        />
        <path
          fill="#152533"
          opacity="1.000000"
          stroke="none"
          d="
M374.649658,3151.905029 
	C370.998596,3151.768555 367.892944,3152.563232 364.513885,3150.464355 
	C366.513367,3142.779785 373.293823,3147.310303 377.686890,3145.062988 
	C379.258606,3144.259033 379.744171,3147.010010 380.012146,3149.349365 
	C380.044189,3150.084229 380.112549,3150.132324 380.087036,3150.167480 
	C378.583496,3151.085693 377.012024,3151.706055 374.649658,3151.905029 
z"
        />
        <path
          fill="#374549"
          opacity="1.000000"
          stroke="none"
          d="
M580.818726,3098.384033 
	C578.787781,3096.026611 576.595947,3095.447266 574.824890,3094.264160 
	C572.391846,3092.638672 574.782715,3091.981445 575.872437,3090.594727 
	C578.275024,3090.168457 580.345825,3090.153320 583.195557,3090.160156 
	C584.023804,3090.520752 584.073120,3090.859619 584.196045,3091.706055 
	C586.011108,3095.477051 585.749512,3097.931152 580.818726,3098.384033 
z"
        />
        <path
          fill="#152533"
          opacity="1.000000"
          stroke="none"
          d="
M382.166016,3104.733887 
	C386.258179,3101.166016 389.065063,3103.703369 391.895203,3107.300293 
	C388.721252,3107.417480 384.715057,3109.599365 382.166016,3104.733887 
z"
        />
        <path
          fill="#374549"
          opacity="1.000000"
          stroke="none"
          d="
M570.831177,3112.075195 
	C569.061462,3110.105957 566.568787,3109.512207 566.045532,3106.586914 
	C568.326050,3106.085449 570.660461,3105.891602 573.375244,3104.199707 
	C573.998047,3103.858154 573.970215,3104.130127 573.965576,3104.267090 
	C576.350586,3107.961914 574.652771,3110.290771 570.831177,3112.075195 
z"
        />
        <path
          fill="#738EA6"
          opacity="1.000000"
          stroke="none"
          d="
M2902.163818,2162.716309 
	C2902.875244,2161.992920 2903.760010,2161.985840 2905.307861,2161.969727 
	C2908.251709,2162.375732 2911.033691,2161.118408 2913.171387,2164.625977 
	C2909.063965,2165.981934 2905.552979,2167.664795 2902.163818,2162.716309 
z"
        />
        <path
          fill="#131C25"
          opacity="1.000000"
          stroke="none"
          d="
M539.341675,3309.331543 
	C542.505676,3304.641113 547.088806,3305.354492 550.525635,3306.233643 
	C558.772278,3308.343994 564.425598,3305.153076 569.687866,3298.639648 
	C570.605469,3297.401855 571.286743,3296.867188 572.670532,3296.255615 
	C576.017761,3295.749023 577.974548,3294.248779 579.771912,3291.478516 
	C584.467712,3288.822754 587.022217,3291.552734 589.160583,3294.640137 
	C590.712585,3296.881104 592.242126,3298.035645 595.426880,3296.229492 
	C597.251099,3294.767090 598.453186,3293.500977 600.665283,3292.503906 
	C604.280579,3291.265381 605.184753,3287.801514 608.523010,3286.231934 
	C609.089600,3285.935791 608.947693,3285.932617 608.922485,3285.999023 
	C609.739807,3285.817627 610.502319,3285.417480 611.641357,3284.397949 
	C612.098145,3283.931641 611.997742,3283.982422 612.053528,3283.986572 
	C612.571594,3283.789062 613.033875,3283.587402 614.169434,3283.737793 
	C617.290222,3286.823486 618.285583,3290.260986 619.620178,3294.370361 
	C619.534485,3302.417236 619.372253,3309.728027 613.831116,3316.286865 
	C611.950928,3317.813965 610.446106,3318.505371 608.863892,3319.030762 
	C594.177002,3323.907471 579.468140,3327.782471 567.831116,3339.805908 
	C558.164673,3349.793213 542.779846,3348.888184 530.171387,3353.466309 
	C525.072693,3355.318115 519.085510,3355.486572 514.707764,3359.146729 
	C503.423584,3368.581543 488.056122,3368.651123 476.061127,3376.406494 
	C473.888824,3377.811035 471.119446,3378.329346 468.802856,3379.641357 
	C465.376526,3381.582031 460.593933,3382.893066 460.193085,3387.205566 
	C459.755798,3391.909668 466.336334,3389.917480 468.384460,3393.920410 
	C469.449249,3400.187256 464.874939,3401.038574 461.296112,3402.219971 
	C438.003510,3409.909424 416.448975,3421.704590 393.024658,3431.032227 
	C390.477844,3432.330811 388.468628,3432.749023 385.654633,3432.988770 
	C376.517914,3434.276367 369.138031,3438.457275 360.514099,3441.393555 
	C358.202118,3441.917236 356.679596,3441.750732 354.626831,3440.539062 
	C349.304108,3439.727295 344.977173,3440.706787 340.512085,3442.568115 
	C332.071381,3446.086914 323.425110,3449.464111 313.914795,3448.363770 
	C311.702606,3448.107910 310.161163,3449.205811 308.553741,3450.465820 
	C306.206390,3452.305176 303.748444,3454.005127 301.316406,3455.734863 
	C290.866882,3463.166260 279.334076,3462.987305 266.407898,3461.068848 
	C272.585358,3452.391357 280.427185,3448.895508 289.548859,3448.113525 
	C293.453491,3447.779053 297.258759,3447.231689 298.871521,3443.573730 
	C301.940582,3436.612061 308.254578,3435.450684 314.371246,3434.453857 
	C318.810425,3433.730713 322.144989,3431.305908 325.977661,3429.706787 
	C334.656311,3426.086426 342.455750,3419.712402 352.588531,3419.933350 
	C354.191071,3419.968262 356.605377,3419.886719 357.293854,3418.908691 
	C363.284698,3410.395996 374.856079,3413.403809 381.746277,3406.845947 
	C382.548981,3406.082031 384.324005,3405.894043 385.544250,3406.089355 
	C392.077118,3407.134277 395.860870,3402.874023 400.236664,3399.228271 
	C403.164886,3396.788086 404.716675,3394.355957 403.576813,3390.722656 
	C402.737213,3388.046143 396.738068,3385.499023 393.260651,3386.692627 
	C389.198395,3388.087158 384.687714,3389.489258 382.207611,3393.133545 
	C379.072327,3397.740234 374.947418,3398.191895 370.107819,3398.046143 
	C363.409912,3397.844238 356.729584,3398.050781 351.912415,3403.875732 
	C342.384399,3404.340820 336.748352,3411.878418 329.348602,3416.226074 
	C324.083221,3419.319580 316.759460,3416.715576 312.178558,3422.564453 
	C311.147705,3423.880615 309.119965,3420.691895 307.406799,3419.792236 
	C303.451141,3417.714600 299.937897,3416.978516 297.083740,3421.509521 
	C294.681946,3425.322510 290.898865,3425.762207 286.696716,3426.125488 
	C278.032593,3426.874268 269.673096,3428.840576 263.302643,3435.590332 
	C261.209656,3437.807617 258.408081,3438.985107 254.793976,3439.919922 
	C253.712585,3440.254883 253.332825,3440.500244 252.444702,3440.296875 
	C251.730423,3437.024170 250.010559,3435.998291 246.715210,3436.014648 
	C245.190460,3436.716064 244.219681,3437.056641 242.398376,3436.682861 
	C241.114349,3435.401855 240.923615,3434.388916 241.559509,3432.652588 
	C248.095139,3432.059814 253.786911,3430.519531 259.227234,3427.867920 
	C260.700012,3427.150391 262.227753,3426.272461 263.803833,3426.083740 
	C274.461456,3424.810547 283.507385,3418.650391 293.805908,3416.335449 
	C296.141663,3415.810547 298.158508,3414.825439 299.668335,3412.837402 
	C300.212585,3412.120361 301.733521,3411.139160 301.907654,3411.293457 
	C306.706421,3415.554932 310.483154,3410.536377 314.603271,3409.782715 
	C321.337524,3408.551025 326.664581,3402.768311 334.082397,3403.309814 
	C336.291077,3397.600586 343.096527,3398.425537 346.601074,3394.508545 
	C347.959595,3392.990234 349.731842,3392.050293 351.932648,3392.398926 
	C363.899658,3394.293213 372.223663,3387.310059 380.501465,3380.462402 
	C383.098053,3378.314697 385.336182,3375.576172 389.042786,3376.075684 
	C399.011047,3377.418945 406.554749,3372.832275 412.542114,3365.798340 
	C416.478638,3361.174072 420.912872,3359.617188 426.545837,3359.984863 
	C427.539215,3360.049805 428.552673,3360.040527 429.540222,3359.923584 
	C437.215637,3359.018555 438.447296,3356.886475 435.839783,3347.794189 
	C435.413574,3346.718994 435.317810,3346.382568 435.253845,3345.522461 
	C435.393677,3344.303467 435.514343,3343.610596 435.732422,3342.390625 
	C436.029022,3340.787598 436.386230,3339.770020 437.411804,3338.499756 
	C439.531189,3338.354736 441.422791,3338.717285 441.603577,3340.680420 
	C441.951599,3344.458984 444.784088,3346.298584 447.116547,3348.558105 
	C451.417145,3352.724854 452.429108,3352.656982 453.855865,3347.156738 
	C454.612030,3344.241455 455.899323,3343.168701 458.541870,3341.762939 
	C468.556763,3336.435303 479.733643,3335.230225 490.335449,3332.095215 
	C495.435822,3330.586914 499.588074,3326.781738 505.821838,3326.700684 
	C506.568085,3320.164062 511.938019,3321.320068 514.911255,3322.428467 
	C521.783142,3324.990479 526.124207,3322.048584 530.718262,3316.772217 
	C534.262817,3314.658936 537.881592,3313.891113 539.341675,3309.331543 
z"
        />
        <path
          fill="#020914"
          opacity="1.000000"
          stroke="none"
          d="
M383.456238,3430.566650 
	C386.127563,3430.044434 388.247253,3430.007568 391.161987,3429.970703 
	C393.639160,3428.468262 395.476044,3428.297607 397.952026,3429.845703 
	C399.892670,3434.688721 396.580963,3436.072998 393.814453,3437.711426 
	C391.644958,3438.996582 388.714447,3439.392090 388.322144,3443.383545 
	C386.821838,3450.362549 382.465363,3453.119141 376.715576,3451.388672 
	C369.870270,3449.328857 363.974518,3450.893799 357.302734,3453.932373 
	C340.868500,3460.500000 325.023834,3466.557861 308.822968,3473.702637 
	C304.958130,3475.459961 300.785675,3474.990479 298.222656,3479.548828 
	C304.861328,3481.922852 310.016693,3478.604492 315.112762,3476.134033 
	C321.483734,3473.045410 327.813660,3470.275391 335.129486,3471.387939 
	C338.581726,3471.913086 341.974487,3472.983398 342.616943,3476.843262 
	C343.386292,3481.465820 339.409637,3482.050293 336.252289,3482.862305 
	C325.661469,3485.585449 314.833435,3487.407471 305.480927,3493.847168 
	C301.292267,3496.731689 295.681000,3494.819092 290.280853,3496.595459 
	C288.685974,3496.653809 287.788086,3496.617676 286.259216,3496.467041 
	C284.008667,3496.243164 282.405212,3496.216797 280.286987,3497.057129 
	C278.900391,3497.422852 278.083496,3497.468018 276.652832,3497.284180 
	C274.507629,3496.570557 272.981201,3495.943848 270.672882,3495.701660 
	C265.431793,3493.517090 260.061249,3494.973633 255.101318,3491.864258 
	C253.366257,3487.970215 253.772003,3485.037109 257.253967,3482.291992 
	C259.492645,3481.976807 260.982819,3481.972900 263.218018,3481.967529 
	C271.090363,3477.193604 278.321716,3472.638428 287.465149,3470.478271 
	C296.223724,3466.859131 304.447479,3463.718506 313.330261,3460.283936 
	C316.885651,3458.071533 320.058502,3456.648193 323.532898,3454.471680 
	C333.654602,3449.460938 345.184479,3449.212891 353.687805,3440.632812 
	C355.523560,3440.021484 357.019470,3440.013916 359.263672,3440.002930 
	C367.911011,3437.703369 375.672150,3435.053955 383.456238,3430.566650 
z"
        />
        <path
          fill="#020D19"
          opacity="1.000000"
          stroke="none"
          d="
M222.674835,3504.525879 
	C221.586090,3501.230469 220.285858,3499.631348 217.572296,3501.658936 
	C215.090958,3503.512695 212.455185,3504.151611 208.728882,3503.979004 
	C211.899841,3494.545654 220.457413,3494.271729 229.375946,3493.744385 
	C232.144501,3496.565430 231.270020,3499.204346 229.813156,3502.567627 
	C227.739990,3505.157471 225.736755,3505.771973 222.674835,3504.525879 
z"
        />
        <path
          fill="#020914"
          opacity="1.000000"
          stroke="none"
          d="
M228.648666,3502.532715 
	C228.616577,3499.810791 229.231750,3497.602051 229.925568,3494.706543 
	C232.837753,3493.350342 235.671295,3492.681152 239.239777,3491.996094 
	C244.268951,3490.039795 248.515503,3484.637695 252.878998,3492.647461 
	C246.333450,3500.009033 238.668442,3503.630127 228.648666,3502.532715 
z"
        />
        <path
          fill="#020D19"
          opacity="1.000000"
          stroke="none"
          d="
M252.614838,3494.222656 
	C251.637177,3491.165771 250.178482,3489.833984 247.245270,3490.542969 
	C245.322174,3491.008057 243.402695,3491.488281 240.728149,3491.970947 
	C243.875214,3486.241943 251.491348,3481.357910 257.556519,3481.486328 
	C256.554810,3484.640137 255.742767,3487.448975 256.002563,3491.254639 
	C255.568344,3493.369873 254.601883,3494.136475 252.614838,3494.222656 
z"
        />
        <path
          fill="#131C25"
          opacity="1.000000"
          stroke="none"
          d="
M233.066788,3476.683105 
	C237.071838,3471.058594 243.346603,3470.147705 248.333786,3465.729736 
	C246.990082,3475.145996 241.125839,3480.041504 233.569473,3478.969482 
	C233.246780,3478.923584 233.064346,3477.888672 233.066788,3476.683105 
z"
        />
        <path
          fill="#020D19"
          opacity="1.000000"
          stroke="none"
          d="
M287.176331,3470.000000 
	C287.654388,3470.913330 287.454559,3472.528076 286.941223,3472.634521 
	C279.120483,3474.260498 273.035706,3479.833496 264.712830,3481.962402 
	C264.377045,3480.742676 264.464386,3478.754395 265.256256,3478.416016 
	C272.216949,3475.443359 277.627075,3468.492188 287.176331,3470.000000 
z"
        />
        <path
          fill="#020D19"
          opacity="1.000000"
          stroke="none"
          d="
M323.297913,3454.022949 
	C323.131927,3458.847168 319.340790,3459.477539 314.721008,3459.979492 
	C315.886078,3456.601074 318.307831,3453.968994 323.297913,3454.022949 
z"
        />
        <path
          fill="#152533"
          opacity="1.000000"
          stroke="none"
          d="
M241.484436,3435.609619 
	C242.886887,3435.981445 243.784470,3435.987793 245.355881,3435.997070 
	C244.769440,3438.199219 244.179260,3440.948975 240.633636,3441.886963 
	C240.001053,3440.493164 240.005417,3439.006836 240.012207,3436.776855 
	C240.336273,3435.770264 240.657928,3435.507080 241.484436,3435.609619 
z"
        />
        <path
          fill="#152533"
          opacity="1.000000"
          stroke="none"
          d="
M251.322266,3439.992676 
	C252.294861,3439.880859 252.653381,3439.913574 253.552124,3439.978027 
	C253.790054,3441.588867 253.559128,3443.332520 251.614853,3443.641846 
	C250.808075,3443.770264 249.411057,3443.458008 249.118118,3442.904297 
	C248.380371,3441.509277 249.412308,3440.636963 251.322266,3439.992676 
z"
        />
        <path
          fill="#020D19"
          opacity="1.000000"
          stroke="none"
          d="
M191.372513,3506.106445 
	C191.805862,3507.798340 191.257034,3509.330078 188.611786,3509.887939 
	C188.192612,3508.203369 188.742493,3506.672852 191.372513,3506.106445 
z"
        />
        <path
          fill="#7A91A4"
          opacity="1.000000"
          stroke="none"
          d="
M103.208664,2037.960693 
	C103.523224,2041.673462 102.130554,2044.924683 98.581238,2047.697266 
	C93.175949,2048.019531 88.361946,2048.019531 82.599892,2048.019531 
	C87.139786,2042.964722 94.828751,2039.313477 103.208664,2037.960693 
z"
        />
        <path
          fill="#7A91A4"
          opacity="1.000000"
          stroke="none"
          d="
M71.120972,2016.023193 
	C71.987213,2018.153320 71.994423,2020.277222 72.002563,2023.198242 
	C70.227470,2026.194214 67.493797,2026.574829 66.189178,2024.541138 
	C63.985535,2021.105835 59.957771,2020.566040 56.155651,2017.585938 
	C61.442242,2015.075562 65.977226,2016.432617 71.120972,2016.023193 
z"
        />
        <path
          fill="#7F98AE"
          opacity="1.000000"
          stroke="none"
          d="
M65.993011,1992.168457 
	C70.194046,1992.805298 72.303162,1994.656616 71.375793,1998.332153 
	C70.739204,2000.855103 68.562393,2001.837402 66.003593,2001.839233 
	C63.446087,2001.840942 61.263988,2000.866821 60.621906,1998.344727 
	C59.686535,1994.670776 61.794674,1992.816284 65.993011,1992.168457 
z"
        />
        <path
          fill="#748B9F"
          opacity="1.000000"
          stroke="none"
          d="
M58.413658,2131.353516 
	C50.390442,2131.546631 42.821701,2131.129395 35.252964,2130.712402 
	C42.018135,2126.889160 49.194164,2128.470459 57.083542,2127.995361 
	C58.787979,2128.757324 59.076885,2129.671875 58.413658,2131.353516 
z"
        />
        <path
          fill="#7A91A4"
          opacity="1.000000"
          stroke="none"
          d="
M80.977196,2098.484375 
	C80.432693,2097.591064 80.296577,2097.279785 80.282997,2096.963135 
	C80.162422,2094.152832 79.204453,2090.717041 83.680916,2090.561768 
	C85.809700,2090.487793 87.402237,2092.149170 87.486580,2094.244873 
	C87.650826,2098.326416 85.740326,2100.322021 80.977196,2098.484375 
z"
        />
        <path
          fill="#8497A7"
          opacity="1.000000"
          stroke="none"
          d="
M58.639107,2131.906494 
	C57.973896,2131.083008 57.988605,2130.202393 58.002369,2128.660889 
	C61.215118,2125.417480 65.300827,2127.694092 69.312714,2126.027588 
	C70.196777,2126.324219 70.456810,2126.675293 70.365662,2127.580078 
	C69.647369,2130.405762 68.672798,2132.024658 66.067505,2130.969238 
	C63.576424,2129.960449 61.555595,2131.501221 58.639107,2131.906494 
z"
        />
        <path
          fill="#8497A7"
          opacity="1.000000"
          stroke="none"
          d="
M46.760353,2161.666260 
	C43.695202,2158.908936 43.453873,2156.782959 47.742348,2157.207520 
	C49.455696,2157.377441 53.112606,2157.173096 52.456841,2160.471191 
	C52.035702,2162.589111 49.265282,2161.811279 46.760353,2161.666260 
z"
        />
        <path
          fill="#7A91A4"
          opacity="1.000000"
          stroke="none"
          d="
M68.674103,2099.975586 
	C66.159554,2099.654297 64.532310,2098.647461 66.698914,2097.120361 
	C69.538124,2095.118652 72.112404,2096.585449 74.180000,2099.094238 
	C72.578705,2100.315918 70.898773,2099.882812 68.674103,2099.975586 
z"
        />
        <path
          fill="#7A91A4"
          opacity="1.000000"
          stroke="none"
          d="
M71.242126,1980.058350 
	C71.975616,1981.535034 71.986412,1983.021362 71.996307,1985.251343 
	C69.811653,1985.765137 66.941284,1986.427612 66.555481,1983.229614 
	C66.237526,1980.594360 68.474556,1980.236328 71.242126,1980.058350 
z"
        />
        <path
          fill="#9DAAB4"
          opacity="1.000000"
          stroke="none"
          d="
M22.305393,1975.746826 
	C23.404665,1977.409790 23.576996,1978.807617 21.895596,1979.049072 
	C20.061052,1979.312378 18.752745,1978.020020 19.005348,1976.178955 
	C19.236979,1974.490479 20.634010,1974.658691 22.305393,1975.746826 
z"
        />
        <path
          fill="#9DAAB4"
          opacity="1.000000"
          stroke="none"
          d="
M12.089171,1972.894897 
	C13.441175,1973.635498 13.456664,1974.380493 12.490743,1975.038330 
	C12.288598,1975.176147 11.820562,1975.170654 11.623571,1975.027222 
	C10.681664,1974.341064 10.714756,1973.596680 12.089171,1972.894897 
z"
        />
        <path
          fill="#748B9F"
          opacity="1.000000"
          stroke="none"
          d="
M70.325272,2127.909424 
	C70.002342,2127.773682 69.990211,2127.413818 69.957413,2126.513428 
	C70.645569,2126.349609 71.305382,2126.746094 70.325272,2127.909424 
z"
        />
        <path
          fill="#294159"
          opacity="1.000000"
          stroke="none"
          d="
M2789.289551,2747.463623 
	C2792.473633,2755.454346 2798.283447,2757.732422 2805.364990,2758.090332 
	C2807.016846,2758.173828 2808.958984,2758.387451 2808.832031,2760.627930 
	C2808.726074,2762.492188 2807.088379,2763.445312 2805.406982,2763.657227 
	C2803.110840,2763.946533 2800.755127,2764.079102 2798.449707,2763.918213 
	C2792.595947,2763.509766 2786.827148,2761.713867 2780.863770,2763.810059 
	C2779.198486,2764.395508 2776.572021,2764.063477 2775.098633,2763.082275 
	C2765.546387,2756.722900 2754.116943,2757.421387 2743.669189,2754.516113 
	C2738.961426,2753.207275 2733.696533,2753.335693 2728.865967,2754.817871 
	C2724.932373,2756.025391 2720.989746,2756.137695 2717.030762,2755.909180 
	C2707.458252,2755.356934 2698.000000,2753.233154 2688.327148,2753.875488 
	C2687.083008,2753.958008 2685.587158,2753.568359 2684.545898,2752.883789 
	C2673.024902,2745.302979 2660.574951,2744.775146 2647.571533,2747.637207 
	C2645.332031,2748.129883 2642.935791,2747.870605 2640.622070,2748.074951 
	C2637.920410,2748.313477 2634.794922,2748.656982 2635.043945,2752.236328 
	C2635.283203,2755.671631 2638.444336,2756.003906 2641.119141,2755.906494 
	C2654.942383,2755.402100 2666.229004,2763.341064 2678.762451,2767.107178 
	C2679.242432,2767.251465 2679.496338,2768.147461 2680.035156,2769.403564 
	C2677.584717,2773.025635 2674.149414,2772.896240 2669.864258,2772.505371 
	C2655.433350,2768.797607 2641.824463,2765.197266 2627.206543,2762.306152 
	C2609.527344,2761.035156 2592.905518,2758.190186 2576.387939,2754.781250 
	C2566.528320,2752.746094 2556.604492,2753.031006 2546.668701,2753.037842 
	C2543.356934,2753.040283 2539.860107,2753.944092 2536.773682,2752.770264 
	C2513.537842,2743.932129 2488.638672,2749.770264 2464.913330,2744.439697 
	C2455.082764,2742.231201 2444.531738,2746.567871 2433.475586,2744.566406 
	C2429.605225,2743.973389 2427.692627,2739.816406 2423.167969,2741.813965 
	C2418.753662,2743.064941 2415.256836,2741.589355 2410.851318,2741.115723 
	C2401.990234,2741.629150 2393.952148,2741.732910 2385.050293,2741.126465 
	C2377.875244,2742.056396 2371.571533,2740.875000 2364.435303,2740.990723 
	C2354.066895,2742.864502 2344.670410,2743.030273 2335.423584,2739.287354 
	C2327.651611,2736.141357 2321.395752,2740.666992 2314.145752,2744.096680 
	C2305.780273,2745.441406 2298.231201,2745.625732 2290.718994,2743.670410 
	C2284.308350,2742.001465 2278.281006,2744.285889 2271.382080,2745.870605 
	C2257.535645,2747.859375 2244.795898,2745.964111 2232.146240,2742.909180 
	C2231.181152,2742.676025 2230.125244,2742.731201 2229.210693,2742.349121 
	C2225.337646,2740.730469 2219.127441,2739.917725 2220.220215,2734.801270 
	C2221.243896,2730.008789 2227.708984,2729.941650 2231.455322,2731.354004 
	C2244.796631,2736.383789 2256.673340,2726.893066 2269.534424,2728.309814 
	C2271.870117,2728.566895 2272.963623,2727.395752 2271.971436,2724.571777 
	C2269.833008,2718.484375 2274.188232,2718.045654 2278.445312,2717.767578 
	C2279.775635,2717.680908 2278.167969,2717.531982 2279.434326,2717.833008 
	C2305.623291,2724.062256 2332.469727,2721.490234 2358.933838,2723.889648 
	C2374.879395,2725.334961 2390.897949,2723.774414 2406.804443,2725.723877 
	C2408.781250,2725.966309 2410.639404,2724.849609 2412.659668,2723.693848 
	C2407.178711,2721.822998 2399.931396,2723.158203 2399.284180,2714.139160 
	C2405.796875,2706.766357 2414.002197,2708.674561 2421.317383,2709.216797 
	C2436.775391,2710.362305 2452.625977,2710.349854 2467.457520,2718.806641 
	C2480.861572,2726.339844 2494.674316,2730.594482 2509.265625,2734.994873 
	C2540.210205,2733.468018 2570.439697,2734.574463 2600.460449,2730.922607 
	C2606.757324,2730.156494 2613.069580,2730.554932 2619.374512,2731.320801 
	C2634.624512,2733.172852 2649.920166,2733.169678 2665.161377,2731.455566 
	C2672.559082,2730.624268 2679.658936,2731.020752 2686.893311,2732.798584 
	C2700.780273,2736.210938 2714.759277,2739.275146 2728.892578,2741.533691 
	C2737.743408,2742.948242 2746.391846,2743.405273 2755.541992,2741.946777 
	C2766.686768,2740.169678 2777.825928,2743.793945 2789.289551,2747.463623 
z"
        />
        <path
          fill="#49647C"
          opacity="1.000000"
          stroke="none"
          d="
M2667.466797,2770.728271 
	C2671.483643,2770.032715 2674.967041,2770.065430 2679.332764,2770.105957 
	C2690.112793,2775.983887 2701.482666,2773.914795 2712.108887,2775.764160 
	C2731.009277,2779.053711 2750.413330,2777.729004 2769.410889,2781.523926 
	C2777.189697,2783.077881 2785.408691,2782.576172 2793.718750,2779.085449 
	C2801.021729,2776.018066 2809.968018,2776.421875 2818.217041,2776.149414 
	C2831.195801,2775.719971 2844.209961,2775.665283 2857.186768,2776.108887 
	C2866.777344,2776.436768 2876.249756,2778.768555 2885.957520,2778.016357 
	C2891.233887,2777.607910 2896.613281,2778.557373 2901.756104,2776.488525 
	C2903.311279,2775.863037 2905.037842,2776.013428 2906.625488,2776.719238 
	C2907.906738,2777.288818 2908.939453,2778.251709 2908.833984,2779.673584 
	C2908.719238,2781.220215 2907.406494,2781.806641 2905.991455,2781.833740 
	C2901.330322,2781.923096 2896.662842,2782.102783 2892.007080,2781.955566 
	C2885.295898,2781.743164 2878.887451,2782.070068 2872.882080,2785.882324 
	C2869.024902,2788.331055 2864.884277,2788.470459 2861.225342,2784.421631 
	C2858.126709,2780.992920 2853.728271,2781.960449 2849.541992,2781.339844 
	C2835.914062,2779.318115 2822.488281,2784.192383 2809.027100,2781.442139 
	C2806.528564,2780.931641 2804.425293,2781.266846 2802.767578,2784.507812 
	C2811.101318,2786.928467 2819.499512,2789.442383 2827.778320,2787.613281 
	C2834.312988,2786.169189 2840.254395,2786.932373 2846.355469,2788.162109 
	C2858.375244,2790.585938 2870.319824,2793.342773 2882.607178,2794.211426 
	C2885.122803,2794.389160 2887.777344,2795.027588 2890.162842,2797.541992 
	C2881.673096,2798.890137 2873.424561,2796.928467 2864.567383,2798.997070 
	C2870.353760,2806.006836 2880.556885,2800.746582 2886.441162,2809.364502 
	C2873.660645,2806.050049 2862.153564,2809.562256 2850.893066,2806.197021 
	C2845.686035,2804.640869 2840.455322,2807.326904 2834.804688,2809.586426 
	C2821.510010,2806.610840 2808.647705,2808.579102 2794.969727,2808.076172 
	C2789.039795,2808.141846 2783.757080,2805.551270 2778.595703,2809.936523 
	C2775.861816,2810.134521 2773.733643,2810.082764 2770.807617,2810.004150 
	C2765.107666,2804.136719 2757.736816,2805.830811 2751.547119,2803.917725 
	C2747.079346,2802.537109 2739.852295,2803.216064 2741.187500,2796.083496 
	C2742.369873,2789.767822 2749.027832,2790.562256 2753.753174,2791.522949 
	C2767.210205,2794.258057 2780.606689,2796.011719 2796.409180,2793.050049 
	C2766.343018,2786.559570 2737.249268,2792.199707 2708.618164,2784.646973 
	C2694.936279,2781.812256 2681.362793,2783.197021 2668.292969,2777.160889 
	C2666.514160,2774.995361 2666.357422,2773.273926 2667.466797,2770.728271 
z"
        />
        <path
          fill="#49647C"
          opacity="1.000000"
          stroke="none"
          d="
M2790.537109,2747.818848 
	C2781.198730,2748.502441 2772.628662,2747.854004 2764.323730,2745.124023 
	C2761.740967,2744.275146 2758.897949,2743.309814 2756.526611,2744.400146 
	C2742.270020,2750.958008 2728.581543,2744.689453 2714.925293,2742.348145 
	C2703.237549,2740.344238 2691.411621,2738.474365 2680.041016,2734.608154 
	C2676.752441,2733.489990 2672.719971,2733.769043 2669.174805,2734.330566 
	C2651.670410,2737.104248 2634.217773,2737.089355 2616.729248,2734.320068 
	C2614.450928,2733.959473 2612.064453,2733.896484 2609.766357,2734.109375 
	C2594.956543,2735.481934 2580.011963,2735.150879 2565.288818,2737.631836 
	C2563.662354,2737.906006 2561.923828,2738.149170 2560.328369,2737.875244 
	C2543.518066,2734.987793 2526.728516,2738.980225 2509.009277,2737.907227 
	C2506.784912,2736.499756 2506.408447,2734.875244 2507.182129,2732.265137 
	C2508.883057,2730.227783 2510.487061,2729.601807 2513.087402,2729.205811 
	C2518.394531,2728.566162 2522.868896,2728.674561 2528.163574,2728.862549 
	C2531.748535,2729.242920 2534.402100,2728.620850 2537.855469,2727.883789 
	C2552.550781,2725.009521 2567.216553,2726.037109 2580.263184,2719.029053 
	C2587.396973,2715.197266 2596.124512,2717.800293 2603.323975,2713.750977 
	C2604.395996,2713.147949 2606.246094,2713.353760 2607.199707,2714.099854 
	C2622.164062,2725.804199 2640.547119,2720.217041 2657.031006,2724.224365 
	C2653.815918,2723.719482 2650.890137,2723.033691 2651.535156,2718.040039 
	C2658.137207,2713.385742 2665.415527,2714.584961 2673.021484,2713.087891 
	C2676.515381,2713.129883 2679.125244,2712.541504 2681.746094,2712.991211 
	C2684.376465,2713.442627 2687.268066,2713.955078 2688.321777,2716.630615 
	C2689.733154,2720.215332 2686.991455,2721.923828 2683.529297,2723.564941 
	C2680.296631,2724.151855 2677.760498,2723.790283 2677.424316,2723.851807 
	C2677.187012,2723.929443 2679.116455,2724.031494 2681.807129,2723.937012 
	C2685.841064,2723.201904 2689.147949,2722.540527 2692.416504,2723.633789 
	C2717.983398,2732.181641 2744.950195,2730.477295 2771.147461,2734.400879 
	C2775.052002,2734.985352 2779.170898,2734.415771 2783.331055,2737.471436 
	C2787.233398,2739.985840 2790.828125,2739.987061 2794.422607,2740.138428 
	C2796.458984,2740.224121 2798.597412,2740.990723 2798.885742,2743.172363 
	C2799.256104,2745.978027 2796.611572,2745.654053 2794.847656,2746.159912 
	C2793.604736,2746.516602 2792.410889,2747.045410 2790.537109,2747.818848 
z"
        />
        <path
          fill="#243B51"
          opacity="1.000000"
          stroke="none"
          d="
M2927.115234,2710.026855 
	C2927.472412,2713.515625 2924.996094,2713.938477 2922.126953,2713.994141 
	C2914.275635,2714.146973 2906.699707,2713.967041 2898.910400,2717.333252 
	C2893.230957,2719.787109 2885.720947,2718.914795 2879.275635,2717.258057 
	C2872.009766,2715.390869 2864.759277,2716.321045 2857.502197,2716.050049 
	C2854.499756,2715.938232 2852.273438,2716.907715 2852.041504,2721.190918 
	C2847.893311,2726.282715 2842.637207,2727.799316 2836.940674,2728.036133 
	C2832.809570,2728.208008 2829.205566,2729.218994 2826.357666,2733.409668 
	C2819.144043,2732.547119 2812.439453,2731.225342 2804.927979,2729.880859 
	C2787.761963,2723.813721 2769.995117,2720.153076 2753.537109,2722.038574 
	C2737.863281,2723.834473 2724.582520,2717.768066 2710.066406,2717.052979 
	C2706.344482,2716.869385 2701.692139,2716.966797 2701.593262,2710.576660 
	C2714.233887,2710.247070 2726.512451,2709.174316 2738.729492,2711.688477 
	C2743.737549,2712.718994 2749.134766,2711.858643 2754.450439,2711.858643 
	C2754.838379,2706.935059 2749.912598,2708.897461 2749.378906,2706.096680 
	C2749.428711,2704.205322 2751.139160,2703.991943 2752.203369,2704.288330 
	C2764.390869,2707.677979 2777.040771,2708.177490 2789.397949,2710.417480 
	C2799.331299,2712.217773 2809.056152,2712.726562 2818.955322,2710.245850 
	C2822.393311,2709.384277 2826.155762,2709.816895 2830.518555,2709.625488 
	C2826.686035,2704.382324 2819.762695,2707.207520 2816.034912,2702.518311 
	C2827.294922,2700.555908 2837.816895,2703.392090 2848.416992,2705.147949 
	C2855.919434,2706.390381 2863.279053,2708.726074 2871.032715,2708.040771 
	C2872.354004,2707.923828 2873.923340,2708.435059 2874.985352,2707.890625 
	C2889.703613,2700.344971 2903.964355,2707.221924 2918.377930,2709.109131 
	C2921.002930,2709.452881 2923.638916,2709.711670 2927.115234,2710.026855 
z"
        />
        <path
          fill="#344F68"
          opacity="1.000000"
          stroke="none"
          d="
M2701.668945,2709.273682 
	C2702.417236,2713.251221 2704.708496,2714.055664 2707.666748,2713.993164 
	C2717.368408,2713.788086 2726.602051,2715.486572 2735.359619,2719.966553 
	C2738.350342,2721.496338 2742.250977,2723.659912 2744.423096,2718.289551 
	C2745.149658,2716.492676 2746.857910,2716.120605 2748.592773,2716.596191 
	C2763.934082,2720.803223 2780.190186,2719.038330 2795.459229,2723.829346 
	C2798.626465,2724.822998 2802.010742,2725.068848 2803.938477,2729.159668 
	C2800.098633,2732.504395 2795.553467,2731.981445 2791.095947,2732.020508 
	C2787.873291,2732.048584 2784.400391,2731.723877 2784.052734,2737.217773 
	C2768.827148,2738.408203 2753.885986,2735.832275 2738.833496,2734.480469 
	C2729.676514,2733.658447 2720.510742,2732.648438 2711.328857,2732.107422 
	C2706.295898,2731.810547 2701.093506,2731.185547 2697.014404,2728.869385 
	C2691.975342,2726.007812 2686.987549,2726.105225 2680.906494,2725.893555 
	C2679.335449,2725.125244 2679.008545,2724.246826 2679.569092,2722.616699 
	C2682.937988,2721.682617 2685.526123,2720.813232 2687.406494,2717.435059 
	C2683.360352,2715.184326 2679.437500,2716.360107 2674.911621,2716.061523 
	C2671.179932,2712.779541 2663.799072,2718.229492 2663.565918,2711.922852 
	C2663.367920,2706.570068 2670.063477,2707.690674 2673.700684,2705.884033 
	C2680.526611,2702.493652 2687.659180,2699.859619 2695.439941,2696.564697 
	C2701.511963,2694.627930 2706.592529,2694.482666 2711.495850,2699.289795 
	C2709.693115,2702.714111 2706.508545,2701.769531 2703.605225,2702.062988 
	C2698.104980,2702.618896 2698.108398,2702.651855 2701.668945,2709.273682 
z"
        />
        <path
          fill="#49647C"
          opacity="1.000000"
          stroke="none"
          d="
M2923.581787,2747.656006 
	C2924.601318,2752.179199 2927.829590,2753.042236 2931.092529,2753.814453 
	C2933.572754,2754.401367 2936.421387,2753.234863 2938.841797,2755.610596 
	C2937.254150,2759.371826 2933.698486,2758.161621 2931.316406,2757.814453 
	C2923.123047,2756.620605 2915.158691,2756.808105 2907.162109,2759.114746 
	C2905.485840,2759.598633 2903.364502,2758.538818 2900.714355,2758.080566 
	C2897.312256,2751.870605 2891.151855,2750.940674 2886.041748,2750.365723 
	C2876.784668,2749.324219 2868.116455,2745.169189 2858.623047,2745.647461 
	C2857.114258,2745.723633 2855.475098,2744.656250 2854.013672,2743.892822 
	C2851.882568,2742.779053 2849.866943,2741.281494 2850.619629,2738.493164 
	C2851.446777,2735.429932 2854.270020,2736.284180 2857.238281,2736.001709 
	C2861.994385,2732.208740 2866.930908,2732.951172 2872.617188,2733.231201 
	C2889.822266,2735.844727 2906.103027,2738.770996 2923.350098,2739.433105 
	C2924.354980,2742.491699 2921.616455,2744.610352 2923.581787,2747.656006 
z"
        />
        <path
          fill="#344F68"
          opacity="1.000000"
          stroke="none"
          d="
M2874.537842,2735.443848 
	C2869.242188,2736.019531 2864.511719,2736.007812 2858.895508,2735.993164 
	C2853.105957,2729.857178 2843.810547,2727.581543 2836.683838,2731.687744 
	C2833.609619,2733.458740 2830.789795,2733.997070 2826.697754,2733.914062 
	C2826.000244,2731.584717 2826.019287,2729.281250 2826.344971,2727.021973 
	C2826.618652,2725.125244 2828.263672,2723.858154 2829.849854,2724.575684 
	C2836.338135,2727.509033 2841.855225,2724.871826 2847.484863,2722.161133 
	C2848.296143,2721.770264 2849.425781,2722.039795 2851.210449,2722.008789 
	C2852.012695,2722.013672 2851.998291,2722.000000 2851.989258,2721.994141 
	C2854.274170,2724.049316 2857.327637,2722.255615 2860.362305,2723.838379 
	C2863.433350,2727.167969 2866.258301,2725.702881 2869.777832,2724.762207 
	C2875.534912,2726.537842 2876.876465,2729.988770 2874.537842,2735.443848 
z"
        />
        <path
          fill="#61798F"
          opacity="1.000000"
          stroke="none"
          d="
M2914.563477,2826.230225 
	C2904.515137,2825.269287 2895.282715,2823.252930 2884.910400,2824.043213 
	C2881.765625,2819.906006 2883.023926,2817.043457 2887.658447,2818.219482 
	C2898.973633,2821.090820 2911.648193,2817.164795 2921.552490,2825.429443 
	C2919.906738,2826.761475 2917.496094,2825.747070 2914.563477,2826.230225 
z"
        />
        <path
          fill="#294159"
          opacity="1.000000"
          stroke="none"
          d="
M2851.167480,2818.665527 
	C2851.124512,2811.736816 2855.341064,2814.785889 2859.429199,2814.288330 
	C2857.565430,2818.731445 2855.345703,2820.194336 2851.167480,2818.665527 
z"
        />
        <path
          fill="#2F465E"
          opacity="1.000000"
          stroke="none"
          d="
M2958.430176,2807.252686 
	C2957.980713,2807.342041 2957.491943,2807.169922 2957.502930,2807.126953 
	C2957.687500,2806.413086 2958.086182,2806.394775 2958.430176,2807.252686 
z"
        />
        <path
          fill="#61798F"
          opacity="1.000000"
          stroke="none"
          d="
M2933.051758,2829.068848 
	C2932.960449,2829.117676 2933.142822,2829.020020 2933.051758,2829.068848 
z"
        />
        <path
          fill="#61798F"
          opacity="1.000000"
          stroke="none"
          d="
M2939.828613,2831.824219 
	C2939.663330,2832.128418 2939.303711,2832.104004 2938.401855,2832.076172 
	C2938.318604,2831.474609 2938.730713,2830.731201 2939.828613,2831.824219 
z"
        />
        <path
          fill="#485D70"
          opacity="1.000000"
          stroke="none"
          d="
M465.578979,2784.544434 
	C467.533875,2784.109375 469.027802,2784.067383 471.268738,2784.052734 
	C481.071655,2789.148682 490.164276,2789.065674 499.456390,2784.593750 
	C502.723267,2783.020996 506.461639,2782.718506 509.961731,2781.744629 
	C513.405945,2780.786377 517.388916,2778.787354 520.096558,2782.292969 
	C522.884827,2785.902588 519.554443,2789.034912 517.238342,2792.686035 
	C512.219177,2796.631348 506.972015,2797.897461 500.819702,2798.929688 
	C491.855957,2803.076172 483.221283,2801.838379 474.615692,2801.336182 
	C471.937683,2801.179443 468.423279,2799.109375 468.212219,2805.089600 
	C468.086853,2808.643311 463.221680,2808.864014 459.930634,2809.055176 
	C431.462585,2810.708740 403.148346,2815.405762 374.468750,2813.095947 
	C368.860687,2812.644287 363.306702,2811.337646 357.625061,2811.958252 
	C375.689148,2813.510742 393.840668,2813.290283 411.936310,2813.613525 
	C421.866547,2813.791260 431.790161,2811.719238 441.302094,2816.245361 
	C443.492065,2817.287354 445.795044,2817.141846 447.958405,2815.744873 
	C459.288269,2808.427490 471.809296,2808.563721 484.494873,2810.100586 
	C490.132690,2810.783691 495.783783,2810.581055 501.426758,2810.828613 
	C505.101685,2810.989746 508.768005,2811.298828 511.903076,2813.510498 
	C513.572998,2814.688477 515.028748,2816.168213 514.581299,2818.441406 
	C514.111938,2820.825928 512.136597,2821.300537 510.067993,2821.525391 
	C500.808960,2822.532227 491.523132,2823.106201 482.218323,2822.592285 
	C478.124084,2822.365967 474.264160,2822.398682 470.956635,2825.270508 
	C464.089874,2831.232666 456.285156,2835.218750 446.797852,2837.038574 
	C445.934937,2838.567627 445.380981,2839.410889 444.180542,2840.723145 
	C441.214233,2842.600830 438.667633,2842.696533 435.312622,2841.996582 
	C428.793091,2838.219727 422.873779,2837.524170 416.050446,2841.239258 
	C410.142120,2842.318359 405.003326,2841.005615 399.043457,2841.036621 
	C395.107208,2840.941406 391.928925,2840.664551 388.149414,2842.221436 
	C370.641602,2844.183105 354.201080,2847.754150 336.833557,2850.377441 
	C330.871887,2849.828125 325.531952,2850.536621 320.518768,2848.408691 
	C314.200378,2845.726807 311.779724,2853.507324 305.751526,2853.790527 
	C285.272766,2854.982666 265.449249,2853.734131 244.993454,2856.517090 
	C242.493866,2856.099609 240.951996,2855.451660 238.850739,2854.006836 
	C237.840240,2853.020508 237.496429,2852.565430 236.917603,2851.286621 
	C236.629440,2849.303711 236.863083,2848.206299 237.916748,2846.514404 
	C245.140823,2841.350098 252.924210,2841.159180 260.487000,2840.833984 
	C276.170227,2840.159180 291.664856,2838.908203 306.323029,2832.599365 
	C310.883484,2830.636475 315.943756,2830.831787 320.782013,2830.032959 
	C312.165497,2829.048584 303.747894,2829.245361 295.392456,2831.977295 
	C289.396881,2833.937256 283.000031,2834.870850 276.725403,2834.961670 
	C258.421234,2835.226074 240.262939,2835.780518 222.376694,2840.639893 
	C213.935318,2842.933105 205.174606,2840.059082 196.645599,2838.946045 
	C183.410965,2837.219482 170.184586,2836.348633 156.883942,2837.408447 
	C153.263718,2837.697021 150.071503,2837.328125 148.248749,2834.025635 
	C147.138046,2832.012939 149.001740,2831.247070 151.451996,2830.508057 
	C158.232162,2829.985352 163.767807,2827.982422 169.379776,2825.848877 
	C176.187073,2823.261475 183.432968,2822.185303 190.725281,2822.022217 
	C198.045242,2821.858643 205.372971,2822.080811 212.696060,2822.008301 
	C219.559631,2821.940186 226.762131,2823.398438 232.317596,2817.309326 
	C233.203720,2816.337891 235.754227,2815.839355 236.966354,2816.385254 
	C243.649185,2819.395264 251.430878,2816.231934 258.054443,2821.017822 
	C261.636383,2823.605957 267.162933,2822.358398 271.725037,2821.534424 
	C275.029022,2820.937500 278.182739,2819.668701 276.659424,2814.808105 
	C275.546265,2811.255859 277.751038,2809.464844 281.019531,2810.754639 
	C286.724976,2813.005371 289.457611,2811.172607 291.294678,2805.559570 
	C292.961761,2800.466064 298.834625,2797.433594 304.818634,2798.740234 
	C315.734070,2801.124023 326.703522,2799.369629 337.624939,2799.970459 
	C338.942505,2800.043213 340.545288,2800.252686 341.562683,2799.642822 
	C352.031982,2793.368164 363.444153,2796.140137 374.554077,2795.973877 
	C381.117920,2795.875732 387.432922,2796.174561 393.879547,2792.839355 
	C398.460297,2790.469482 404.601685,2789.745850 410.273834,2792.281250 
	C415.945587,2794.816406 421.909241,2794.263184 427.894928,2793.060303 
	C437.952850,2791.039062 447.982239,2788.794922 459.097382,2787.978271 
	C461.269501,2786.273438 463.492371,2786.114502 465.578979,2784.544434 
z"
        />
        <path
          fill="#556A7C"
          opacity="1.000000"
          stroke="none"
          d="
M137.793732,2706.618164 
	C140.705048,2701.462158 145.017136,2700.114746 149.899979,2700.057617 
	C156.543915,2699.979492 163.287430,2698.446777 169.646805,2698.680664 
	C177.037552,2698.952148 183.778412,2698.945557 190.749664,2696.268799 
	C193.640350,2695.158691 197.567001,2695.369141 200.623047,2696.295898 
	C217.551697,2701.431641 234.900497,2699.654785 253.061340,2699.993896 
	C256.159393,2703.274170 253.859879,2705.068115 251.026672,2707.292480 
	C237.176498,2709.467529 224.075256,2705.099121 209.085632,2707.902832 
	C224.039566,2706.520020 237.093781,2707.664795 250.830627,2709.033936 
	C256.808899,2708.762939 261.989960,2707.727051 267.213043,2706.984131 
	C270.368408,2706.535156 274.044220,2705.293945 276.295135,2708.731934 
	C278.481964,2712.072266 273.753510,2713.513428 273.510986,2716.560791 
	C281.228729,2717.112061 288.770599,2717.316895 297.021729,2715.454102 
	C337.994263,2715.305420 378.148804,2713.303711 418.314850,2712.191650 
	C448.105652,2711.367432 478.148834,2711.651123 508.417297,2705.058105 
	C525.225830,2702.911377 540.955688,2705.713867 556.743591,2707.078613 
	C560.426941,2707.396729 564.181580,2707.775635 564.835571,2713.357178 
	C559.471985,2720.637207 552.287842,2718.705811 544.812561,2718.712646 
	C538.339722,2717.509521 532.638855,2716.265381 526.801819,2718.267334 
	C533.666748,2715.890625 539.432068,2722.100830 546.883911,2720.375244 
	C548.466003,2720.183838 549.368835,2720.221436 550.902100,2720.559570 
	C553.334717,2721.651855 555.095703,2722.937500 557.711731,2720.973389 
	C559.549805,2720.226318 560.846863,2720.110107 562.808655,2720.395508 
	C565.364014,2721.143555 566.982727,2722.872559 569.889771,2721.927246 
	C573.103760,2721.147949 575.645996,2720.350098 578.876099,2719.397461 
	C581.001770,2719.010010 582.365051,2718.489990 584.520386,2717.961914 
	C587.393372,2717.491455 589.478455,2717.669922 592.286499,2718.566406 
	C601.944519,2724.009277 611.595825,2723.652832 620.998779,2721.563965 
	C633.520447,2718.782715 646.311951,2717.166260 658.438171,2712.475342 
	C668.321289,2708.652344 679.200073,2708.720215 690.197754,2705.613281 
	C701.403442,2703.802734 711.862000,2704.719238 723.136108,2704.624756 
	C728.430481,2704.568848 732.647583,2703.270020 737.689087,2701.869141 
	C745.154053,2700.423584 751.697449,2701.167969 758.990845,2702.930664 
	C767.420776,2703.802979 774.218201,2698.116455 782.710083,2700.567627 
	C784.916565,2702.270996 784.655823,2703.802979 783.528076,2706.053223 
	C780.728516,2708.994629 777.811340,2710.074463 773.969299,2710.874512 
	C767.995117,2713.034424 762.411011,2714.126953 757.663208,2717.510742 
	C752.035583,2721.521240 746.058899,2717.451660 740.226257,2717.599854 
	C733.801392,2717.763184 728.361694,2716.242920 722.563721,2722.653564 
	C717.676758,2728.056641 708.501648,2729.885498 700.360168,2730.071289 
	C696.829163,2730.152100 693.802063,2730.503174 690.772766,2733.721191 
	C644.046326,2743.161865 613.781799,2743.771729 562.846558,2736.428223 
	C551.268005,2735.562012 540.545715,2738.846436 528.982056,2736.315430 
	C527.021301,2737.165771 525.915833,2737.987061 523.930420,2738.598633 
	C520.900208,2738.832520 519.108765,2740.197021 516.638000,2741.686035 
	C512.633728,2742.030029 509.258514,2742.136230 505.040771,2742.278564 
	C502.597351,2743.291748 500.906128,2743.762451 498.258667,2742.794434 
	C495.891418,2740.365723 495.658569,2738.121094 496.555542,2734.971680 
	C497.637268,2733.125000 498.886047,2732.622314 501.743439,2729.988037 
	C488.145203,2734.277832 476.345367,2731.052734 464.640808,2732.506836 
	C456.341553,2733.537842 447.992981,2733.742188 439.731262,2732.486328 
	C433.373627,2731.519775 427.158813,2731.644531 420.864227,2732.745361 
	C411.247101,2734.427002 402.120697,2733.152344 392.416901,2731.002197 
	C378.129852,2727.835938 362.869385,2724.369629 348.051880,2733.609863 
	C343.720886,2735.147217 340.071564,2734.943359 336.492340,2734.125977 
	C322.080231,2730.835449 307.578644,2730.869385 292.966888,2732.436035 
	C284.580780,2733.335205 276.588776,2730.264893 267.535797,2729.208496 
	C256.240967,2729.881348 246.454453,2726.136719 236.713531,2723.588867 
	C226.060867,2720.802979 215.918442,2718.194336 204.347366,2722.253418 
	C190.590302,2721.817627 177.596741,2721.592041 164.185486,2718.193359 
	C158.714813,2715.224854 162.586258,2711.865723 163.182007,2708.682373 
	C154.330948,2708.822021 145.565262,2714.769775 137.793732,2706.618164 
z"
        />
        <path
          fill="#4E6477"
          opacity="1.000000"
          stroke="none"
          d="
M497.394470,2733.592529 
	C498.035492,2736.211182 498.027313,2738.277588 498.026367,2741.119141 
	C487.957306,2748.771484 475.934235,2746.422852 464.902435,2748.775879 
	C461.774689,2749.443115 458.404114,2749.522705 454.284454,2749.195801 
	C449.402191,2747.857178 445.106476,2747.668701 440.381012,2746.134033 
	C438.179199,2745.987305 436.764984,2746.440674 435.312469,2746.499268 
	C436.231415,2746.853271 437.077484,2746.382568 438.673889,2746.215332 
	C442.988556,2746.333740 446.237701,2747.821777 450.186829,2749.388428 
	C451.520599,2751.452148 451.557251,2752.994141 450.364746,2755.166992 
	C442.756317,2756.932129 435.905823,2754.800537 429.152039,2753.579102 
	C422.193695,2752.320801 415.344177,2751.879883 408.378723,2752.027832 
	C399.378326,2752.218750 390.467712,2751.914307 381.825409,2748.943848 
	C379.978668,2748.309570 377.689117,2747.505859 376.018463,2748.051025 
	C364.369812,2751.853271 352.349792,2747.493408 340.679871,2749.916992 
	C339.389832,2750.185059 337.554352,2749.714844 336.811371,2750.443359 
	C326.874237,2760.186768 313.808350,2755.248047 302.382904,2757.872559 
	C299.854279,2758.453613 297.081879,2757.966064 294.421814,2757.983154 
	C284.160339,2758.049072 273.838165,2757.197998 262.903107,2760.021973 
	C258.300537,2763.891846 253.364212,2764.933594 248.408752,2764.918701 
	C231.591232,2764.868652 214.966324,2768.843994 195.662476,2766.502686 
	C202.240128,2768.317627 207.056351,2768.377441 208.189880,2774.546631 
	C205.638290,2778.200928 202.380051,2779.365967 199.049774,2779.195557 
	C178.706528,2778.155762 158.858902,2784.751709 138.480194,2782.981689 
	C134.199402,2782.609619 129.776794,2783.239746 125.217598,2780.316406 
	C120.678642,2773.368164 125.260666,2771.907715 129.889252,2772.139648 
	C136.834549,2772.487305 143.727280,2771.455811 149.283142,2772.002197 
	C139.447357,2773.226562 128.127823,2769.571045 116.219437,2772.927734 
	C103.051270,2772.799805 90.855804,2774.970459 77.716476,2776.048828 
	C71.511200,2773.441895 65.760452,2771.286621 67.611938,2762.859863 
	C73.008369,2761.361816 77.460052,2764.081543 83.051926,2763.797363 
	C86.554688,2761.543457 89.157127,2759.119385 93.342896,2758.045410 
	C94.886078,2757.940430 95.775902,2757.940430 97.333099,2757.937988 
	C100.984535,2758.168701 104.042519,2757.606445 107.604813,2759.122070 
	C122.534119,2753.888916 137.473007,2756.797363 153.053833,2755.994385 
	C155.042953,2755.164307 156.217117,2754.291260 157.540268,2754.429199 
	C173.398941,2756.085938 188.274597,2753.099121 202.964935,2745.163574 
	C216.463928,2747.837158 228.721710,2742.974854 242.077545,2743.023926 
	C246.298248,2742.655029 249.242249,2740.938232 253.245605,2739.614502 
	C274.155731,2737.328369 294.351990,2738.710205 314.466217,2736.634033 
	C327.876465,2735.250000 341.453522,2734.104248 355.913269,2735.062500 
	C365.815002,2736.596436 374.608978,2735.686035 383.248993,2732.768066 
	C386.766174,2731.580078 390.635956,2730.730469 393.935181,2732.558594 
	C402.885376,2737.518066 412.531677,2736.626709 422.099670,2736.634033 
	C436.274597,2736.644775 450.377289,2734.289307 464.665894,2737.208496 
	C470.899750,2738.482422 477.841217,2736.924072 484.206665,2734.672119 
	C488.237213,2733.246338 492.299835,2731.517090 497.394470,2733.592529 
z"
        />
        <path
          fill="#2B3E51"
          opacity="1.000000"
          stroke="none"
          d="
M241.411118,2854.000488 
	C243.315582,2854.038818 244.754898,2854.332031 245.957977,2853.925781 
	C258.218567,2849.786621 270.793915,2851.715088 283.249268,2852.034912 
	C290.514618,2852.221436 297.493744,2848.030518 305.449219,2851.387451 
	C307.360840,2852.896729 308.647858,2853.895264 308.478271,2856.541504 
	C305.468292,2861.727295 301.463318,2862.896973 295.810425,2861.498291 
	C284.044830,2856.138916 272.696533,2858.732422 262.104370,2861.427490 
	C250.691040,2864.331543 239.384628,2865.353516 227.800934,2865.562744 
	C224.517441,2865.622070 221.153793,2865.940186 217.716522,2868.597168 
	C215.150101,2870.169189 213.049545,2870.620117 210.113251,2870.940674 
	C206.574173,2870.774658 204.373581,2872.143555 201.437759,2873.821045 
	C189.939484,2877.788086 178.934189,2874.924805 168.046646,2875.282959 
	C159.089523,2875.577881 150.107056,2875.007324 141.157562,2875.558105 
	C134.989883,2875.937256 128.556427,2875.353516 123.581085,2880.661133 
	C121.416786,2882.969727 118.116722,2882.726318 114.620277,2880.636475 
	C113.521767,2876.666260 111.148506,2876.112793 108.221260,2875.982666 
	C100.865082,2875.656494 93.501862,2875.952148 86.504570,2878.135010 
	C77.052788,2881.083496 68.160355,2879.955811 58.558804,2875.933594 
	C58.622566,2872.511719 61.127079,2871.821777 63.949688,2872.063721 
	C70.439285,2872.619629 77.211609,2870.229980 83.404488,2874.150879 
	C85.794945,2875.664307 88.308357,2874.385498 89.442024,2872.353271 
	C92.746490,2866.429688 97.953926,2865.569092 103.915962,2865.960693 
	C106.628632,2866.139160 108.915092,2865.130371 111.328545,2863.701904 
	C122.947952,2856.824951 135.600464,2852.514893 148.852707,2850.215576 
	C152.454941,2849.590576 156.055008,2849.103760 159.477264,2847.863525 
	C165.326965,2845.743164 170.502258,2845.808838 176.592926,2848.615479 
	C183.359177,2851.733154 191.172134,2850.450684 198.586670,2849.053223 
	C198.166290,2845.406982 195.616043,2846.166016 193.964386,2845.700928 
	C182.601868,2842.500977 171.411499,2838.309814 159.212860,2840.001465 
	C152.018433,2840.999268 144.529007,2840.789551 137.627274,2842.736328 
	C129.764038,2844.953857 123.294952,2843.453613 116.438522,2838.666992 
	C126.428619,2831.318604 137.844742,2828.454590 151.242218,2830.081055 
	C151.444778,2831.664795 147.244476,2833.020020 151.952866,2834.846924 
	C153.608536,2835.489502 155.149704,2834.946777 156.788147,2834.808838 
	C169.340683,2833.752197 181.833328,2833.069092 194.412735,2835.501709 
	C200.519028,2836.682861 206.870514,2836.835205 213.094604,2837.825928 
	C217.266846,2838.489990 221.713531,2838.499268 225.910461,2836.980469 
	C238.281708,2832.503662 251.123718,2831.289307 264.123871,2832.129150 
	C274.182098,2832.779053 284.605652,2832.257080 293.571838,2829.038330 
	C302.879425,2825.697510 311.745544,2826.497314 320.855194,2826.035889 
	C324.709717,2825.840576 330.319458,2825.708496 330.299927,2830.387451 
	C330.284882,2834.000977 324.705719,2834.142578 321.105896,2833.914551 
	C313.587158,2833.438477 307.005859,2835.812012 300.068939,2838.565430 
	C289.602936,2842.719971 278.197052,2843.798828 266.926605,2843.850098 
	C257.576569,2843.892578 248.498718,2845.235352 238.740570,2847.684326 
	C236.986328,2848.852295 236.666245,2849.892578 237.538025,2851.592041 
	C237.943375,2852.031006 237.996262,2852.003662 237.982422,2852.030273 
	C238.796936,2852.876709 239.685837,2853.604004 241.411118,2854.000488 
z"
        />
        <path
          fill="#2F4457"
          opacity="1.000000"
          stroke="none"
          d="
M562.122131,2736.946045 
	C569.727295,2731.592773 578.029480,2734.548340 586.090515,2734.467285 
	C587.678955,2734.451416 589.380920,2735.877686 590.852295,2735.620117 
	C602.131714,2733.645752 612.905212,2738.081543 624.013184,2738.010986 
	C635.266479,2737.939209 646.520203,2737.933594 657.773560,2737.866455 
	C659.407166,2737.856934 661.657593,2738.254150 662.419861,2736.791504 
	C665.847229,2730.214355 671.888611,2732.096436 677.238159,2731.922852 
	C681.532837,2731.783203 685.840393,2732.042969 691.073608,2732.131348 
	C696.549683,2730.315430 701.176331,2731.038818 705.792847,2731.722900 
	C707.772522,2732.016113 709.778625,2732.433105 711.750366,2732.290527 
	C752.561829,2729.340332 793.360840,2737.728271 834.176208,2732.421875 
	C837.478149,2731.992432 840.833313,2731.995605 843.807251,2730.210938 
	C859.129272,2721.018555 875.206299,2720.469727 891.909485,2725.764160 
	C896.016296,2727.065674 900.392944,2727.449219 905.479553,2728.480957 
	C909.966125,2729.576904 912.961914,2728.010986 916.706909,2725.849854 
	C927.590515,2724.537842 937.700500,2722.553955 947.987549,2727.867432 
	C949.026550,2734.583496 944.246521,2734.546875 940.543213,2735.082031 
	C934.238831,2735.992676 928.114075,2737.367676 921.416077,2740.101318 
	C918.889526,2740.717773 917.188477,2741.054932 914.579407,2740.946289 
	C906.425781,2741.658691 899.053528,2740.990723 890.979614,2742.340576 
	C863.227051,2749.221924 835.833801,2748.753906 808.339844,2747.596924 
	C817.103149,2747.910645 826.383911,2745.441406 834.065063,2753.495117 
	C831.730530,2760.968262 825.731934,2758.349121 820.007690,2758.973145 
	C817.462280,2758.954590 815.776489,2758.967041 813.277344,2758.946289 
	C807.762329,2759.125977 803.067200,2758.895752 798.932617,2756.433350 
	C789.494812,2750.813232 778.815796,2750.058838 768.392517,2749.224609 
	C761.660583,2748.686035 756.153015,2746.992676 750.868042,2741.870117 
	C733.273376,2741.338623 717.092163,2746.286865 700.525085,2748.406250 
	C691.376099,2749.576416 682.351562,2751.399902 673.051941,2751.071533 
	C670.690613,2750.988281 667.562744,2749.845215 666.209961,2754.208984 
	C664.606506,2760.467529 661.997925,2764.797363 654.803589,2764.972656 
	C651.709167,2762.809814 651.232300,2760.282227 651.792969,2756.675781 
	C651.707153,2753.719727 654.540955,2750.406494 649.647339,2749.989258 
	C633.282288,2748.593994 617.438232,2742.883789 600.773132,2744.005859 
	C589.834229,2744.742188 579.071594,2742.994629 568.253601,2741.952148 
	C565.367920,2741.673828 563.166260,2740.638672 562.122131,2736.946045 
z"
        />
        <path
          fill="#3F5467"
          opacity="1.000000"
          stroke="none"
          d="
M651.095215,2755.904053 
	C654.219299,2757.545898 654.004944,2759.963867 653.884644,2763.059082 
	C649.186646,2767.230713 643.533264,2765.283936 637.683533,2766.583008 
	C618.225769,2758.033936 598.821167,2765.114502 582.921326,2763.448486 
	C595.074707,2762.206299 610.295959,2762.669922 625.446716,2765.038574 
	C628.600037,2765.531494 632.781006,2765.818359 632.516541,2770.345459 
	C632.304993,2773.967285 628.720459,2774.304199 625.780273,2775.036133 
	C617.256653,2777.156982 608.601624,2773.199707 599.363159,2775.977539 
	C597.598206,2776.549316 596.560242,2776.739258 594.719177,2776.849854 
	C576.252869,2777.506104 558.565125,2778.431152 542.915955,2776.906738 
	C545.316467,2778.039795 549.845276,2778.022949 554.535278,2777.605957 
	C557.178101,2777.371094 560.126221,2778.322998 560.566833,2781.556641 
	C561.041992,2785.043945 558.044373,2785.756836 555.505127,2786.527588 
	C553.915955,2787.009521 552.241150,2787.173828 550.587402,2787.146484 
	C541.925537,2787.003906 533.424683,2787.234131 525.730774,2792.236328 
	C523.270203,2793.835938 520.080261,2794.105713 516.515564,2792.402344 
	C516.184937,2790.931641 516.400330,2789.961182 516.669128,2789.006104 
	C517.237732,2786.985107 520.806580,2785.442383 518.390137,2783.075195 
	C516.247498,2780.975830 514.285645,2783.979736 512.212097,2784.388184 
	C503.801453,2786.045654 496.033936,2789.931396 487.696136,2791.515381 
	C481.772400,2792.641113 475.728424,2791.758545 472.117310,2784.802734 
	C471.390808,2781.514648 470.390350,2778.973389 470.221313,2776.378174 
	C469.898041,2771.415039 472.724091,2768.271240 477.828735,2768.127441 
	C484.718231,2767.932861 491.213074,2767.711426 497.897980,2764.561768 
	C502.079651,2762.591553 508.112640,2763.502686 513.348083,2763.427490 
	C528.456970,2763.210938 543.749329,2762.940430 559.217651,2758.217773 
	C564.504822,2757.674072 567.998657,2754.043457 573.334045,2754.087158 
	C576.773315,2754.047607 579.541626,2754.015625 583.140381,2753.972168 
	C585.358643,2752.802734 586.906189,2752.441895 589.350830,2753.468994 
	C600.619995,2753.938965 611.181580,2754.035156 621.742126,2753.968506 
	C631.298401,2753.908203 640.850586,2753.714844 651.095215,2755.904053 
z"
        />
        <path
          fill="#3C5163"
          opacity="1.000000"
          stroke="none"
          d="
M208.628662,2774.554199 
	C203.242905,2769.877441 197.214417,2770.073975 191.245621,2769.931152 
	C189.671066,2769.893799 187.584122,2770.078125 187.282913,2767.930176 
	C186.922318,2765.358154 189.046860,2764.704102 191.018738,2764.255859 
	C191.978836,2764.037842 193.009491,2763.961182 193.991989,2764.035400 
	C210.950562,2765.313965 227.719162,2761.680664 244.616211,2762.079346 
	C250.071198,2762.208008 254.996078,2759.884033 261.188171,2760.016113 
	C261.739594,2764.820068 263.550751,2768.015137 268.732697,2767.815918 
	C282.063873,2767.303467 295.254669,2769.027100 308.498871,2769.944580 
	C311.806915,2770.173584 315.124054,2770.029785 318.368622,2768.904297 
	C322.260803,2767.554199 326.194214,2767.933105 330.155701,2769.001465 
	C337.026428,2770.854248 344.072296,2769.675049 351.038452,2769.894531 
	C351.994568,2769.924561 353.570587,2769.806396 353.836182,2769.259521 
	C357.164093,2762.408203 365.205261,2763.734619 370.458588,2759.751709 
	C374.672485,2756.557129 381.770538,2756.636963 387.651459,2756.269287 
	C405.283600,2755.168457 422.801025,2756.536865 440.122253,2760.095215 
	C441.015930,2760.278809 441.815826,2760.918457 443.265808,2761.653076 
	C440.073669,2765.147217 436.251404,2764.202881 433.062347,2763.645508 
	C419.100006,2761.203857 405.969971,2761.812256 392.168640,2766.949463 
	C382.132263,2770.685547 371.585205,2774.981201 361.198700,2776.563477 
	C348.505219,2778.497314 336.018036,2780.833496 323.609680,2783.958740 
	C322.358459,2784.273682 320.955536,2783.986816 318.770325,2783.931152 
	C311.935150,2777.711182 304.605438,2783.062988 297.317993,2781.351562 
	C278.848358,2777.454590 261.241516,2773.037842 242.939346,2777.904541 
	C234.570709,2780.129883 225.937210,2779.919678 216.697968,2778.126465 
	C214.812790,2777.271729 213.845001,2776.308594 212.027771,2775.649902 
	C210.622299,2775.456299 209.839752,2775.260986 208.628662,2774.554199 
z"
        />
        <path
          fill="#3A4F62"
          opacity="1.000000"
          stroke="none"
          d="
M115.500473,2770.669922 
	C128.489731,2767.925049 156.101013,2769.403564 161.977051,2772.570312 
	C161.429214,2774.205322 159.954773,2774.098145 158.786194,2773.957275 
	C147.956070,2772.650879 137.226074,2776.847412 126.361649,2774.567871 
	C125.849220,2774.460449 125.031235,2775.809570 124.191238,2777.169434 
	C129.658234,2785.077148 137.958130,2782.107666 144.997589,2782.746094 
	C155.445343,2783.693604 165.906113,2784.467773 177.042953,2786.200195 
	C188.524094,2792.889160 201.194931,2791.430908 212.671051,2794.969971 
	C216.347534,2796.104004 220.521912,2795.604248 223.811295,2799.510742 
	C224.045883,2803.582031 222.940536,2805.995117 218.966675,2805.915283 
	C212.026505,2805.775635 204.939194,2806.556396 198.182449,2805.391602 
	C190.537170,2804.073975 183.251114,2803.525635 175.685959,2805.521484 
	C172.562714,2806.345459 169.112717,2805.930908 164.925903,2806.098145 
	C162.303909,2807.595703 160.189835,2808.134277 157.188538,2808.539795 
	C154.039169,2808.324219 152.060684,2807.451904 150.310425,2804.772217 
	C145.828842,2794.031738 145.824753,2793.828613 134.933701,2794.047852 
	C116.792114,2794.412842 98.846825,2796.909668 80.881767,2799.287598 
	C69.878700,2800.744141 58.781239,2801.212402 47.727478,2797.106689 
	C50.895672,2793.468018 54.686333,2794.095215 58.251785,2794.055176 
	C65.208076,2793.977051 72.166405,2794.058350 79.121933,2793.949219 
	C81.009293,2793.919434 83.344635,2793.978760 82.343582,2790.334473 
	C79.471504,2783.796387 84.254875,2782.320801 88.025269,2780.775635 
	C94.156876,2778.262695 100.662987,2776.921631 107.325386,2777.024902 
	C111.584236,2777.091064 114.263222,2775.541260 115.500473,2770.669922 
z"
        />
        <path
          fill="#2B3E51"
          opacity="1.000000"
          stroke="none"
          d="
M155.487762,2806.491699 
	C158.125824,2806.037598 160.276489,2806.035645 163.233856,2806.075195 
	C163.487595,2807.294678 163.071945,2808.563721 162.360275,2809.636719 
	C155.639160,2819.770020 155.611588,2819.793213 143.440033,2814.975830 
	C141.194519,2814.086914 138.880798,2813.746582 136.610626,2814.059082 
	C126.769363,2815.413574 116.956924,2814.387207 107.130409,2814.016846 
	C102.806664,2813.853760 98.737511,2814.746582 94.550491,2817.938721 
	C99.610466,2822.178711 106.036354,2817.962158 111.683159,2822.145508 
	C103.247620,2825.475586 95.467697,2824.257812 88.005081,2823.460693 
	C79.263031,2822.526855 70.789307,2818.935547 61.751709,2821.819824 
	C59.541771,2822.525146 57.107193,2821.686523 55.063732,2820.458496 
	C50.856674,2817.930420 46.230339,2817.882080 40.702240,2817.946289 
	C38.511593,2816.560547 37.036888,2815.937012 34.737671,2817.434814 
	C29.961201,2820.069580 25.573866,2820.993896 20.431801,2818.353760 
	C24.101788,2815.248779 30.001898,2815.871094 32.318302,2809.650879 
	C33.283867,2807.057861 37.757416,2808.091309 40.673260,2808.073730 
	C58.807022,2807.964600 76.959167,2807.464600 95.069817,2808.101562 
	C108.449692,2808.571533 121.356354,2806.510010 135.132782,2803.900391 
	C141.965958,2807.094727 148.415253,2807.446289 155.487762,2806.491699 
z"
        />
        <path
          fill="#2B3E51"
          opacity="1.000000"
          stroke="none"
          d="
M28.787533,2857.921875 
	C27.940985,2856.870361 27.915974,2855.879639 28.003763,2854.899170 
	C28.715876,2846.947021 28.879526,2846.850342 36.590172,2845.980713 
	C38.916142,2845.718262 42.644348,2847.177734 42.845032,2843.377197 
	C43.019257,2840.077393 39.010696,2840.453857 36.458183,2838.597412 
	C35.757504,2832.039551 41.977119,2832.269287 45.257504,2828.673584 
	C47.072887,2828.177246 48.404793,2828.013916 49.709488,2828.137939 
	C53.123531,2828.461670 54.614754,2829.988770 52.604053,2833.352783 
	C51.942459,2834.459961 50.843437,2835.406494 50.512051,2836.585938 
	C50.029255,2838.304443 50.866810,2839.841553 52.460415,2840.698975 
	C54.411156,2841.748047 56.066402,2840.528320 56.280315,2838.946289 
	C57.686340,2828.545898 65.192116,2829.757568 72.460289,2829.966553 
	C83.091194,2830.272461 83.998604,2831.307861 82.358345,2842.331055 
	C82.227570,2843.209717 82.806320,2844.194092 83.580162,2845.620850 
	C81.805931,2853.337646 74.041786,2852.213623 69.016579,2855.657959 
	C70.824127,2859.814697 75.441391,2856.394043 77.795120,2859.648926 
	C61.484379,2860.186523 45.530567,2860.780029 28.787533,2857.921875 
z"
        />
        <path
          fill="#4E6477"
          opacity="1.000000"
          stroke="none"
          d="
M224.773697,2800.199219 
	C209.957336,2797.776611 196.111557,2793.115234 181.517166,2793.173584 
	C178.645966,2793.185059 178.249847,2791.684814 178.118347,2788.847168 
	C182.536926,2784.438965 187.756790,2785.553955 193.561920,2785.841309 
	C200.353195,2788.526123 206.794281,2789.351318 213.195618,2790.356201 
	C217.352417,2791.008545 220.854492,2789.759033 224.517624,2786.416260 
	C226.171143,2785.688721 227.107620,2785.180908 228.079742,2785.205078 
	C249.564651,2785.739990 270.652924,2782.518311 291.664246,2778.552734 
	C293.339233,2778.236816 294.936127,2778.495850 297.258698,2779.559570 
	C303.064880,2778.417969 308.183868,2777.549316 313.513458,2778.050293 
	C316.243134,2778.306641 317.738861,2779.544189 317.903076,2783.096191 
	C304.937988,2784.141357 292.874359,2788.114258 280.930389,2792.762451 
	C277.297699,2794.175537 273.344574,2794.765381 268.766632,2795.815918 
	C267.107635,2795.942627 266.219818,2795.975342 264.665588,2796.014648 
	C263.134033,2796.047607 262.268677,2796.066406 260.754211,2796.098145 
	C256.276520,2800.180908 251.288834,2800.125977 246.281845,2799.989502 
	C240.668106,2799.836670 235.076538,2800.108887 228.673477,2800.549805 
	C227.021957,2800.241455 226.204102,2800.214844 224.773697,2800.199219 
z"
        />
        <path
          fill="#3A4F62"
          opacity="1.000000"
          stroke="none"
          d="
M451.762421,2746.251465 
	C463.500702,2746.256592 474.461151,2744.392334 485.444458,2742.571533 
	C488.983093,2741.985107 492.646118,2742.149170 497.142944,2741.931641 
	C499.576447,2741.953857 501.119293,2742.013672 503.430176,2742.194092 
	C500.579315,2748.380615 494.764862,2750.893555 488.182556,2751.954102 
	C485.938538,2752.315674 483.590271,2752.122314 481.293823,2752.050049 
	C471.137207,2751.730713 461.014343,2751.612793 450.646790,2755.845215 
	C449.888733,2754.309326 449.942444,2752.840820 450.003174,2750.633789 
	C449.170715,2748.349121 450.061798,2747.446533 451.762421,2746.251465 
z"
        />
        <path
          fill="#3F5467"
          opacity="1.000000"
          stroke="none"
          d="
M44.928955,2828.194336 
	C45.570900,2833.966309 40.887825,2835.197510 36.410843,2837.591797 
	C27.931469,2838.108398 19.884436,2838.104248 10.909031,2838.135010 
	C10.778797,2834.909912 14.848889,2834.439941 15.947786,2830.691162 
	C25.474554,2830.231201 34.856625,2830.205811 44.928955,2828.194336 
z"
        />
        <path
          fill="#3A4F62"
          opacity="1.000000"
          stroke="none"
          d="
M228.057953,2800.952148 
	C230.123398,2798.968262 232.472351,2797.816162 235.193832,2798.020264 
	C243.033798,2798.608643 250.704590,2797.310547 259.242126,2796.066895 
	C258.817200,2804.297607 258.790344,2804.424072 252.051865,2802.994629 
	C245.775925,2801.663574 239.585632,2801.279785 233.380737,2803.248535 
	C231.337326,2803.897217 229.433685,2803.893555 228.057953,2800.952148 
z"
        />
        <path
          fill="#2F4457"
          opacity="1.000000"
          stroke="none"
          d="
M573.624512,2753.405273 
	C570.914673,2759.087891 565.704468,2758.467041 560.464478,2758.562256 
	C559.839783,2756.728516 564.344727,2755.119385 560.385376,2754.235352 
	C556.531616,2753.375000 552.677917,2752.956787 549.225464,2750.720459 
	C553.403809,2747.816650 566.063721,2748.927734 573.624512,2753.405273 
z"
        />
        <path
          fill="#2F4457"
          opacity="1.000000"
          stroke="none"
          d="
M521.782166,2736.707764 
	C523.839844,2736.238770 525.277893,2736.207031 527.432373,2736.279297 
	C529.529663,2743.834229 529.529663,2743.834229 520.537720,2743.846191 
	C517.927979,2741.082764 519.152588,2739.030762 521.782166,2736.707764 
z"
        />
        <path
          fill="#2B3E51"
          opacity="1.000000"
          stroke="none"
          d="
M97.651947,2839.618652 
	C96.054436,2843.847900 94.369576,2847.999268 88.400551,2846.500244 
	C90.492279,2842.960205 91.788948,2838.240723 97.651947,2839.618652 
z"
        />
        <path
          fill="#4E6477"
          opacity="1.000000"
          stroke="none"
          d="
M521.689880,2736.250977 
	C521.620483,2738.344727 520.839233,2740.419189 519.905518,2743.138184 
	C518.723267,2743.461670 517.693604,2743.140625 516.336426,2742.371582 
	C516.244385,2738.785889 517.641785,2736.662354 521.689880,2736.250977 
z"
        />
        <path
          fill="#4E6477"
          opacity="1.000000"
          stroke="none"
          d="
M34.944145,2817.859863 
	C35.764988,2813.483398 36.624844,2813.291992 39.778053,2817.277344 
	C38.443375,2817.896973 37.043205,2817.879639 34.944145,2817.859863 
z"
        />
        <path
          fill="#3F5467"
          opacity="1.000000"
          stroke="none"
          d="
M465.337891,2784.116211 
	C465.729034,2786.963623 463.995667,2788.030518 460.648193,2787.972656 
	C460.249023,2785.152832 461.904694,2783.968750 465.337891,2784.116211 
z"
        />
        <path
          fill="#2F4457"
          opacity="1.000000"
          stroke="none"
          d="
M589.920288,2753.274902 
	C588.536865,2753.942139 587.014832,2753.970459 584.731812,2753.979980 
	C585.074158,2749.862549 586.866699,2748.787598 589.920288,2753.274902 
z"
        />
        <path
          fill="#3A4F62"
          opacity="1.000000"
          stroke="none"
          d="
M264.312866,2796.447754 
	C264.839966,2794.714355 265.877502,2794.108154 267.676453,2795.497070 
	C267.167908,2797.251953 266.143799,2797.918457 264.312866,2796.447754 
z"
        />
        <path
          fill="#EAE6D7"
          opacity="1.000000"
          stroke="none"
          d="
M1828.440918,3952.486816 
	C1830.380127,3952.609131 1831.381226,3953.258545 1830.661255,3954.778320 
	C1830.217285,3955.715576 1829.084961,3955.866455 1828.202393,3955.426758 
	C1826.806519,3954.731445 1826.648071,3953.706055 1828.440918,3952.486816 
z"
        />
        <path
          fill="#1B2B3A"
          opacity="1.000000"
          stroke="none"
          d="
M140.477921,3086.560303 
	C144.578522,3082.457764 148.268463,3077.340332 155.124115,3077.738281 
	C157.203293,3077.859131 158.724777,3076.210938 160.173538,3074.839844 
	C165.608002,3069.697998 171.730652,3068.800293 179.279694,3071.928223 
	C179.756058,3076.262451 181.698288,3078.336670 185.929825,3077.961914 
	C191.016571,3077.511719 195.783585,3079.033691 200.644913,3080.066895 
	C203.590469,3080.693115 205.504349,3082.239746 205.750244,3085.315674 
	C206.030670,3088.823486 203.655167,3089.645996 200.782501,3090.236816 
	C197.541504,3090.903564 194.426926,3091.925781 191.030029,3092.060059 
	C179.444717,3092.517822 167.759384,3090.565918 156.270050,3093.459961 
	C154.495819,3093.906982 153.158157,3092.962402 151.832443,3091.822266 
	C148.715271,3089.140381 144.460968,3088.950195 140.477921,3086.560303 
z"
        />
        <path
          fill="#3A4B59"
          opacity="1.000000"
          stroke="none"
          d="
M82.879990,3090.077148 
	C72.515694,3089.983154 62.910755,3089.845703 51.875252,3089.687744 
	C56.110615,3083.787354 60.077103,3080.802490 65.528580,3078.629883 
	C74.279472,3077.451416 82.411057,3078.793945 90.589584,3079.889648 
	C100.521873,3081.219971 110.264420,3078.257568 121.018921,3078.095947 
	C123.633171,3079.982666 124.031479,3082.091064 122.405243,3085.114746 
	C114.608986,3085.948730 107.360359,3086.114746 99.214836,3086.341309 
	C97.274872,3086.932373 96.327965,3087.659668 94.579895,3088.010986 
	C90.477715,3088.380859 87.289452,3090.431641 82.879990,3090.077148 
z"
        />
        <path
          fill="#33495B"
          opacity="1.000000"
          stroke="none"
          d="
M188.916641,3071.842773 
	C188.170532,3069.472656 187.979889,3067.035156 191.056915,3066.274170 
	C204.540771,3062.939453 218.053940,3060.089844 232.112930,3061.835693 
	C235.723053,3062.284180 239.427277,3061.934570 243.084152,3062.066650 
	C244.635361,3062.122314 246.827957,3061.979736 246.604767,3064.300537 
	C246.425095,3066.168945 243.899384,3065.900146 242.359131,3067.629395 
	C240.509949,3068.180664 239.069992,3068.185303 236.909164,3068.207764 
	C235.258240,3068.911621 234.334656,3069.609863 232.583466,3069.854980 
	C226.612045,3069.909668 221.218872,3069.950195 214.927933,3070.026855 
	C208.895859,3073.542725 203.123581,3071.429199 196.809647,3071.878418 
	C193.878815,3071.840576 191.788010,3071.856201 188.916641,3071.842773 
z"
        />
        <path
          fill="#243748"
          opacity="1.000000"
          stroke="none"
          d="
M122.726852,3085.860352 
	C121.889877,3083.734131 121.922142,3081.685303 121.922165,3078.868164 
	C126.790421,3078.732910 131.615585,3075.235107 137.274094,3077.920898 
	C138.274094,3080.924072 137.401154,3083.308594 134.415588,3085.438477 
	C130.538208,3085.958252 127.067146,3085.948242 122.726852,3085.860352 
z"
        />
        <path
          fill="#1B2B3A"
          opacity="1.000000"
          stroke="none"
          d="
M196.525955,3072.407715 
	C201.075729,3068.292236 206.852753,3070.732910 213.151443,3070.028809 
	C213.571884,3072.656982 212.014893,3074.020264 209.392410,3073.920654 
	C205.279633,3073.764648 201.069427,3074.810547 196.525955,3072.407715 
z"
        />
        <path
          fill="#243748"
          opacity="1.000000"
          stroke="none"
          d="
M94.351242,3088.484375 
	C94.718109,3086.969482 95.729759,3086.348877 97.689560,3086.335938 
	C97.679436,3088.052002 97.287811,3090.060547 94.351242,3088.484375 
z"
        />
        <path
          fill="#233444"
          opacity="1.000000"
          stroke="none"
          d="
M232.331543,3070.318115 
	C232.725388,3068.827637 233.716370,3068.253906 235.583374,3068.187012 
	C235.641006,3069.947266 235.405365,3072.101807 232.331543,3070.318115 
z"
        />
        <path
          fill="#4C6880"
          opacity="1.000000"
          stroke="none"
          d="
M2743.447266,2465.470703 
	C2749.966064,2466.249512 2755.950684,2465.387451 2761.814941,2467.442627 
	C2765.026611,2468.568359 2768.347168,2468.344482 2770.680176,2464.972168 
	C2773.178223,2461.361328 2777.255859,2461.316895 2780.656006,2462.285400 
	C2794.878906,2466.337158 2809.585693,2465.284424 2824.012695,2467.012695 
	C2826.245361,2467.280273 2827.999023,2466.959473 2829.253662,2465.171875 
	C2831.839355,2461.487549 2835.215332,2461.488770 2839.497559,2463.326172 
	C2840.793945,2464.733154 2841.195557,2465.851562 2842.034668,2467.407715 
	C2845.583252,2470.739014 2852.812500,2467.723389 2852.473633,2475.625732 
	C2845.607666,2478.211182 2838.956787,2478.273438 2832.310547,2477.960205 
	C2818.073975,2477.289551 2803.721436,2479.749512 2789.583252,2476.358643 
	C2787.924805,2475.960938 2786.067627,2476.081787 2784.880859,2477.337646 
	C2782.401855,2479.961182 2779.730469,2479.882080 2776.705811,2478.628662 
	C2775.811279,2478.257812 2774.710449,2477.997559 2773.781494,2478.152100 
	C2753.123535,2481.585693 2733.069336,2476.145020 2712.787842,2474.082031 
	C2712.138916,2474.015869 2711.467041,2474.177246 2710.364990,2474.269531 
	C2710.448242,2476.930664 2715.921875,2476.283691 2714.043945,2479.402588 
	C2712.836914,2481.407959 2710.188965,2482.135742 2707.777588,2481.509521 
	C2696.286621,2478.525879 2684.600098,2480.611328 2673.021484,2480.022461 
	C2668.729980,2479.803955 2665.132812,2480.574707 2663.752930,2485.542480 
	C2662.478027,2490.130615 2658.527100,2489.958496 2654.797119,2489.963623 
	C2642.148193,2489.981201 2629.499268,2490.004150 2616.850342,2490.051514 
	C2613.545898,2490.063721 2610.458252,2490.601807 2607.187744,2491.690430 
	C2595.528076,2495.572021 2583.975098,2496.044678 2573.540283,2487.838135 
	C2572.551270,2487.060303 2571.072754,2486.040039 2570.078857,2486.265625 
	C2558.956055,2488.788818 2550.177002,2479.787109 2538.740234,2480.063965 
	C2535.366455,2477.304932 2533.781738,2474.377197 2535.522949,2469.877441 
	C2557.095703,2464.113770 2577.773438,2456.538330 2600.192871,2460.766846 
	C2607.303955,2462.108398 2614.787354,2460.528564 2622.068848,2461.206787 
	C2625.028809,2461.482666 2628.620117,2462.268799 2628.603027,2466.018799 
	C2628.585449,2469.741455 2624.989746,2470.783447 2622.051270,2470.788086 
	C2610.406738,2470.806396 2599.315186,2474.321533 2587.896484,2475.611328 
	C2604.814209,2474.394287 2621.725586,2473.976807 2638.644287,2475.528076 
	C2640.296631,2475.679443 2642.330811,2476.622803 2643.542480,2475.854248 
	C2658.284912,2466.505127 2676.210938,2473.874023 2691.718262,2466.052002 
	C2700.626465,2461.558594 2712.033203,2466.855957 2723.061523,2465.063477 
	C2724.875488,2465.067139 2725.905029,2465.097412 2727.686523,2465.084473 
	C2730.444092,2465.107910 2732.330566,2464.476562 2734.978516,2463.875977 
	C2736.200928,2463.656738 2736.695557,2463.593750 2737.940186,2463.521240 
	C2740.214844,2463.636963 2741.628906,2464.071533 2743.447266,2465.470703 
z"
        />
        <path
          fill="#5B768D"
          opacity="1.000000"
          stroke="none"
          d="
M2459.258789,2496.645264 
	C2463.349365,2496.062988 2466.813721,2496.030273 2471.144043,2496.003662 
	C2480.708008,2501.372070 2491.400635,2499.682861 2501.019043,2504.388916 
	C2505.795654,2508.492920 2500.147949,2515.935303 2507.031738,2519.456299 
	C2505.781982,2526.062988 2501.180176,2527.032471 2496.353271,2526.789062 
	C2474.203857,2525.672119 2452.049316,2526.760986 2429.836914,2525.728271 
	C2404.850098,2524.566162 2379.437744,2523.877441 2354.363525,2527.758057 
	C2347.420654,2528.832764 2341.474121,2533.454102 2334.267578,2533.204102 
	C2317.017334,2532.605713 2299.803711,2531.214600 2282.545654,2530.731445 
	C2280.564697,2530.676025 2278.601074,2530.067139 2276.765137,2529.272217 
	C2274.344482,2528.224121 2271.378174,2527.298096 2271.488281,2524.075195 
	C2271.620605,2520.198975 2275.015381,2519.466309 2277.950195,2519.226807 
	C2285.216797,2518.633789 2292.541992,2517.878418 2299.585938,2520.252686 
	C2297.380859,2517.924316 2288.781738,2519.861572 2294.971191,2511.897949 
	C2314.861084,2512.577637 2333.755615,2508.358887 2353.636719,2509.501953 
	C2358.570801,2511.972900 2363.003418,2512.736572 2367.747803,2512.955078 
	C2376.098389,2513.339111 2381.846191,2510.667480 2383.982666,2501.288330 
	C2396.460693,2500.024658 2408.247070,2498.768066 2420.251953,2499.351562 
	C2433.025391,2499.972412 2445.985840,2500.409424 2459.258789,2496.645264 
z"
        />
        <path
          fill="#4C6880"
          opacity="1.000000"
          stroke="none"
          d="
M2629.927246,2503.062256 
	C2630.311035,2499.678467 2631.701660,2498.128662 2634.530518,2498.117676 
	C2638.180908,2498.103516 2641.814697,2497.878906 2645.442139,2498.814209 
	C2653.300781,2500.840332 2661.279541,2500.393311 2669.179443,2499.181396 
	C2672.133301,2498.728271 2674.923340,2497.322266 2677.669434,2499.623535 
	C2678.770996,2500.546631 2680.220459,2500.988770 2681.330811,2500.105469 
	C2685.851562,2496.509277 2690.706299,2495.914551 2696.112793,2497.695557 
	C2697.369385,2498.109863 2699.128174,2497.143066 2699.306396,2495.884277 
	C2700.268799,2489.083496 2705.508545,2490.785645 2709.894287,2489.830078 
	C2724.474121,2486.653564 2738.941406,2490.064697 2753.405029,2490.387939 
	C2767.131104,2490.694580 2781.018555,2492.373535 2794.437012,2496.166748 
	C2795.877197,2496.574219 2797.120361,2497.218994 2796.919922,2498.656738 
	C2796.671631,2500.434326 2795.155029,2501.770996 2793.389648,2501.511475 
	C2788.818115,2500.839844 2784.268066,2499.935791 2779.770508,2498.868408 
	C2774.490967,2497.615479 2769.213623,2497.706787 2763.944580,2498.560547 
	C2762.322754,2498.823242 2760.833984,2499.936279 2761.267578,2502.025635 
	C2761.608398,2503.669434 2763.188965,2503.965088 2764.394287,2503.814209 
	C2773.609375,2502.662109 2782.660645,2506.653320 2792.018799,2504.281982 
	C2796.074951,2503.254150 2800.726562,2503.598389 2804.917480,2504.362793 
	C2808.907959,2505.090576 2812.602051,2505.953613 2816.566650,2504.363037 
	C2818.040527,2503.771973 2820.266113,2503.933350 2820.747803,2505.819824 
	C2821.386719,2508.319092 2819.244385,2509.270508 2817.249512,2509.680908 
	C2815.640625,2510.012207 2813.937744,2509.948975 2812.276367,2509.951660 
	C2796.962158,2509.975586 2781.415527,2511.637939 2766.396240,2509.536865 
	C2749.854980,2507.223389 2733.123535,2510.321777 2716.827637,2506.238281 
	C2715.258545,2505.844971 2713.342041,2505.665283 2711.881348,2506.196533 
	C2701.233887,2510.068359 2690.890137,2506.632324 2680.459961,2505.170166 
	C2678.838867,2504.943115 2677.016846,2504.162842 2675.624023,2504.633301 
	C2669.317383,2506.763672 2663.260010,2505.886963 2656.994385,2504.353027 
	C2654.102783,2503.645264 2649.505127,2502.866455 2651.743164,2509.596436 
	C2652.825195,2514.906250 2648.906250,2517.156250 2646.287354,2520.820312 
	C2640.252930,2523.588135 2635.733887,2521.343262 2630.820557,2517.922607 
	C2626.616943,2512.985596 2636.405029,2508.056396 2629.927246,2503.062256 
z"
        />
        <path
          fill="#4C6880"
          opacity="1.000000"
          stroke="none"
          d="
M2502.731445,2506.615723 
	C2492.776367,2506.556152 2484.718262,2501.275879 2475.688477,2500.642578 
	C2474.132080,2500.533447 2472.878906,2499.117920 2472.156250,2496.734863 
	C2488.093750,2498.526123 2504.309082,2497.704102 2520.483887,2498.131592 
	C2525.445801,2498.262695 2530.310547,2495.275391 2535.125977,2497.500000 
	C2550.227539,2504.475586 2566.229736,2501.308594 2581.893311,2501.978760 
	C2584.848633,2502.105225 2587.870117,2501.550537 2591.369873,2503.293457 
	C2597.875977,2509.851807 2605.592041,2507.953613 2612.713623,2508.736084 
	C2617.709229,2509.284668 2623.940674,2507.823242 2625.096191,2516.257568 
	C2620.027588,2523.188477 2613.231445,2521.210449 2607.287109,2520.642334 
	C2585.822754,2518.590576 2564.156494,2518.339355 2542.875488,2515.167725 
	C2529.694580,2513.203369 2516.626709,2509.708740 2502.731445,2506.615723 
z"
        />
        <path
          fill="#3E5871"
          opacity="1.000000"
          stroke="none"
          d="
M2535.270020,2469.632324 
	C2536.534912,2473.067871 2537.245605,2475.788330 2537.951660,2479.215820 
	C2535.849121,2479.902344 2533.751221,2479.881836 2530.866699,2479.832520 
	C2523.896240,2478.255127 2518.288330,2479.827148 2512.448486,2483.425293 
	C2507.008545,2484.737061 2502.565430,2481.784180 2496.908203,2482.102051 
	C2493.459229,2484.533691 2490.169678,2485.491211 2485.901855,2486.352539 
	C2466.513184,2486.789795 2448.388672,2483.304199 2430.238281,2480.287109 
	C2425.626953,2479.520264 2421.495361,2476.982666 2421.150391,2470.513184 
	C2426.487061,2463.736084 2433.349854,2464.197021 2439.872314,2465.287109 
	C2457.277344,2468.195312 2474.944824,2468.452637 2493.213379,2471.083496 
	C2500.664551,2473.934814 2506.521484,2471.903564 2512.923584,2468.003418 
	C2520.818848,2466.319824 2527.903076,2465.315430 2535.270020,2469.632324 
z"
        />
        <path
          fill="#4C6880"
          opacity="1.000000"
          stroke="none"
          d="
M2485.313965,2484.479248 
	C2488.361572,2481.174561 2491.586914,2482.183838 2495.324463,2482.055664 
	C2494.114258,2491.776855 2494.093994,2492.088623 2484.977295,2491.892578 
	C2476.486816,2491.710205 2468.020020,2493.194580 2458.779541,2492.050781 
	C2454.028076,2495.535400 2449.606445,2494.395508 2444.892334,2493.869629 
	C2435.135986,2492.782227 2425.221924,2491.104004 2414.822266,2495.484863 
	C2408.701416,2497.725342 2403.319580,2497.683594 2397.050781,2496.135742 
	C2388.678467,2491.267578 2381.716309,2485.096680 2371.693604,2488.083496 
	C2369.901611,2488.617432 2367.833740,2487.327393 2365.911865,2486.835938 
	C2363.480225,2486.213623 2360.034912,2486.204590 2360.244629,2482.634766 
	C2360.420654,2479.634766 2363.311523,2478.295898 2365.885010,2477.761475 
	C2376.607666,2475.535645 2387.509277,2472.604492 2398.308838,2476.335693 
	C2407.267822,2479.430664 2416.268311,2480.807861 2425.660156,2480.674805 
	C2441.249023,2480.454102 2456.200928,2486.198486 2471.862549,2485.693359 
	C2476.130371,2485.555664 2480.439697,2486.625732 2485.313965,2484.479248 
z"
        />
        <path
          fill="#49647C"
          opacity="1.000000"
          stroke="none"
          d="
M2395.281250,2493.658691 
	C2401.444092,2494.003174 2406.849121,2493.993896 2413.156250,2493.972656 
	C2421.242188,2490.168457 2428.693359,2492.597656 2436.081299,2492.916016 
	C2442.920410,2493.210693 2449.722412,2493.668457 2457.222168,2495.481934 
	C2457.990234,2496.019531 2458.938965,2496.055664 2459.411865,2496.075684 
	C2460.068115,2499.507080 2459.050293,2502.388184 2455.099365,2501.883301 
	C2442.025146,2500.212158 2429.100098,2505.827881 2415.931396,2502.326660 
	C2412.264404,2501.351562 2407.785889,2501.431641 2404.195312,2502.604980 
	C2397.810791,2504.691162 2391.877686,2503.572998 2384.967285,2502.153809 
	C2380.596680,2504.020020 2378.094482,2502.342285 2377.358154,2499.104004 
	C2376.498291,2495.323242 2379.675049,2494.163086 2383.569336,2493.304688 
	C2387.795654,2492.927246 2391.164062,2492.690918 2395.281250,2493.658691 
z"
        />
        <path
          fill="#3C556B"
          opacity="1.000000"
          stroke="none"
          d="
M2624.684570,2516.388428 
	C2623.440186,2512.401367 2620.896484,2512.021240 2618.054443,2512.024658 
	C2612.726074,2512.031006 2607.396484,2511.918701 2602.069336,2511.988037 
	C2597.194336,2512.051758 2593.698730,2510.230713 2592.100098,2504.591309 
	C2604.159424,2505.054688 2616.069580,2500.896729 2629.142578,2502.305176 
	C2631.627930,2506.401855 2636.257080,2505.140625 2639.430664,2507.852051 
	C2636.905762,2510.847412 2632.352539,2511.140381 2630.201904,2515.191406 
	C2628.596924,2517.170654 2627.053467,2517.400635 2624.684570,2516.388428 
z"
        />
        <path
          fill="#49647C"
          opacity="1.000000"
          stroke="none"
          d="
M2859.727051,2498.014648 
	C2851.478271,2497.994873 2844.189697,2497.942627 2836.901367,2497.978760 
	C2832.586182,2498.000000 2828.609863,2497.149170 2824.837646,2494.324463 
	C2830.244141,2491.947998 2855.395264,2489.938721 2864.904053,2492.579102 
	C2871.436768,2494.392578 2879.479980,2491.557861 2885.868164,2498.371826 
	C2876.548584,2502.352295 2868.656982,2496.676270 2859.727051,2498.014648 
z"
        />
        <path
          fill="#334D63"
          opacity="1.000000"
          stroke="none"
          d="
M2646.718750,2520.480957 
	C2644.969238,2515.200928 2649.046631,2513.843506 2651.650879,2510.823486 
	C2654.767822,2510.207764 2657.561523,2510.156738 2661.194092,2510.140625 
	C2669.236328,2515.033203 2677.900146,2513.891357 2685.800049,2515.895508 
	C2687.990479,2516.451172 2690.529053,2515.882324 2691.879395,2519.333008 
	C2689.485840,2523.096924 2685.964600,2522.984619 2681.611816,2522.957520 
	C2679.054932,2522.971436 2677.363037,2523.271484 2674.810059,2523.194092 
	C2664.801270,2525.847412 2655.839111,2525.873779 2646.718750,2520.480957 
z"
        />
        <path
          fill="#344F68"
          opacity="1.000000"
          stroke="none"
          d="
M2838.677246,2468.510742 
	C2838.530273,2466.971436 2839.060791,2465.933105 2839.812988,2464.350342 
	C2851.447021,2460.943848 2862.834961,2463.489502 2875.116211,2463.972656 
	C2877.628418,2466.476318 2882.520996,2465.985352 2881.964355,2471.181152 
	C2876.530029,2471.817627 2871.076416,2471.764160 2864.710938,2471.690430 
	C2855.144775,2475.434814 2847.593262,2469.062256 2838.677246,2468.510742 
z"
        />
        <path
          fill="#4C6880"
          opacity="1.000000"
          stroke="none"
          d="
M2512.760010,2483.941895 
	C2514.447998,2476.729736 2521.570312,2474.524658 2529.873535,2479.102051 
	C2528.713379,2480.729736 2526.957520,2481.551514 2527.030762,2483.428711 
	C2527.145508,2486.370361 2529.658936,2485.721924 2531.500244,2485.977783 
	C2535.174561,2486.488281 2539.226318,2484.921143 2544.481689,2488.715332 
	C2535.735596,2489.809082 2528.913086,2492.781006 2522.927979,2486.348389 
	C2520.283447,2483.506348 2516.852295,2484.177734 2512.760010,2483.941895 
z"
        />
        <path
          fill="#5B768D"
          opacity="1.000000"
          stroke="none"
          d="
M2457.995117,2495.355713 
	C2443.852051,2497.291992 2429.947266,2493.763916 2414.959717,2493.965332 
	C2414.666748,2489.817627 2417.832275,2489.895508 2420.830078,2490.035156 
	C2430.010742,2490.462402 2439.286133,2488.752197 2448.384033,2491.578857 
	C2450.818115,2492.334961 2453.624268,2491.892578 2457.141113,2492.019775 
	C2458.015137,2492.923584 2458.007568,2493.807617 2457.995117,2495.355713 
z"
        />
        <path
          fill="#516D85"
          opacity="1.000000"
          stroke="none"
          d="
M2838.185059,2468.744873 
	C2846.280273,2467.812012 2854.233643,2470.212402 2863.089600,2471.373047 
	C2862.545654,2478.751465 2862.352539,2478.845459 2852.742920,2476.419189 
	C2851.626465,2472.699463 2849.274658,2472.117432 2846.335693,2471.966553 
	C2843.522217,2471.822266 2840.161133,2473.034180 2838.185059,2468.744873 
z"
        />
        <path
          fill="#49647C"
          opacity="1.000000"
          stroke="none"
          d="
M2818.885254,2495.990234 
	C2816.386719,2501.041504 2812.821777,2500.611572 2809.112061,2498.423096 
	C2808.667725,2498.161133 2808.528809,2496.219727 2808.955566,2495.828613 
	C2811.990967,2493.047363 2815.359863,2493.530762 2818.885254,2495.990234 
z"
        />
        <path
          fill="#607B93"
          opacity="1.000000"
          stroke="none"
          d="
M2536.732666,2470.244629 
	C2528.521240,2470.261963 2521.218506,2470.176025 2513.000977,2470.116211 
	C2507.585938,2472.755615 2502.727539,2474.014404 2496.764648,2473.984375 
	C2477.357422,2472.681885 2458.657715,2472.057373 2440.206055,2468.650879 
	C2434.675049,2467.629883 2429.053223,2467.585205 2422.862305,2469.915283 
	C2418.604492,2473.268555 2414.253662,2473.839600 2409.954834,2473.280273 
	C2397.803223,2471.699951 2385.453857,2472.015381 2372.504639,2469.253418 
	C2362.270508,2468.173096 2352.822266,2470.260498 2342.763672,2467.416992 
	C2340.086670,2464.966797 2339.729492,2462.625732 2340.543701,2459.269531 
	C2343.343506,2455.428223 2346.747559,2455.916504 2350.073242,2456.011475 
	C2379.996094,2456.867920 2409.927490,2457.002930 2439.873535,2457.102539 
	C2424.623535,2457.206055 2410.045410,2456.466064 2394.847656,2451.564453 
	C2379.480957,2446.608643 2362.173828,2447.189941 2345.401611,2447.893555 
	C2324.507324,2448.770264 2303.529541,2447.557129 2282.611328,2446.564453 
	C2277.364258,2446.315674 2272.552979,2443.759277 2267.170654,2440.691650 
	C2271.572998,2452.140381 2261.577393,2450.068115 2256.651123,2453.563965 
	C2233.504150,2452.891113 2210.931152,2455.254150 2188.666748,2450.799561 
	C2185.072998,2450.080811 2181.219971,2447.175781 2179.471924,2454.138916 
	C2178.614990,2457.553223 2173.224365,2457.209961 2169.574219,2456.824463 
	C2149.357422,2454.689453 2129.232666,2456.752930 2112.493896,2457.529541 
	C2119.973145,2455.822021 2130.649414,2456.874268 2141.108398,2460.300049 
	C2144.416504,2461.383545 2149.367432,2461.911377 2148.381104,2466.764648 
	C2147.520264,2471.000977 2142.926025,2470.816162 2139.255127,2471.029785 
	C2132.565918,2471.419189 2126.013916,2470.035645 2119.394531,2469.534180 
	C2093.238525,2467.553223 2066.966797,2467.285156 2040.898315,2463.889893 
	C2032.011108,2462.732910 2022.974854,2463.082520 2013.209473,2464.339844 
	C2009.030518,2464.945557 2005.646362,2464.829834 2001.434326,2464.937988 
	C1998.403809,2465.042236 1996.228394,2465.540039 1993.224854,2465.273438 
	C1978.533203,2471.056885 1964.309326,2466.568359 1950.235840,2466.506104 
	C1947.207031,2466.492920 1942.936279,2464.222656 1943.695801,2461.613281 
	C1946.396606,2452.335449 1939.041138,2456.562744 1936.478394,2456.270996 
	C1930.568726,2455.597656 1924.428467,2456.374512 1919.016357,2459.051270 
	C1903.607544,2466.671631 1886.901489,2463.918701 1871.159302,2463.121582 
	C1863.168213,2462.716797 1853.889404,2460.186523 1844.716797,2457.309082 
	C1840.520264,2450.422852 1843.204102,2445.054199 1847.609497,2439.342773 
	C1850.053589,2437.558838 1852.248657,2437.331055 1854.984375,2436.352051 
	C1857.226074,2435.864014 1858.725586,2435.887451 1860.919678,2436.316895 
	C1865.400146,2439.531494 1869.088989,2438.902588 1873.427490,2435.933838 
	C1879.470337,2434.262207 1884.856079,2434.770264 1891.063477,2435.418213 
	C1902.598999,2438.593018 1913.474365,2439.041992 1924.529541,2438.095947 
	C1931.427979,2437.505371 1938.419800,2435.835693 1946.024292,2438.847656 
	C1951.337646,2446.955566 1958.046021,2449.299805 1966.917725,2449.163086 
	C1997.742920,2448.687744 2028.538574,2450.054688 2059.296387,2452.157715 
	C2063.658447,2452.455811 2067.910645,2452.158691 2072.038818,2450.479980 
	C2082.023682,2446.418945 2092.594727,2445.550781 2102.580811,2444.967041 
	C2096.558350,2444.990967 2089.904785,2445.428223 2083.296387,2444.147949 
	C2081.016846,2443.706299 2078.536377,2443.565430 2076.365967,2444.409912 
	C2056.274902,2452.227295 2035.509766,2449.861816 2014.704346,2449.322021 
	C1997.469116,2448.874512 1980.187500,2448.784912 1962.923828,2448.812256 
	C1959.051758,2448.818604 1951.273682,2450.165527 1956.865723,2440.851562 
	C1969.742798,2438.192139 1982.066406,2440.060791 1994.319702,2438.849609 
	C2004.837524,2437.810303 2016.401733,2439.455566 2025.922485,2437.061768 
	C2046.465698,2431.896484 2067.265137,2437.270996 2086.984131,2433.129883 
	C2110.309082,2428.230713 2133.256592,2435.112549 2155.928711,2431.530273 
	C2173.535645,2428.748535 2190.916748,2428.476562 2209.308105,2429.191162 
	C2214.042725,2430.502686 2217.982422,2431.822266 2222.362793,2428.267822 
	C2230.911865,2425.761963 2239.022705,2428.055176 2247.615234,2426.268555 
	C2254.197510,2426.199463 2260.017578,2427.016113 2265.803711,2424.805176 
	C2271.776855,2422.522705 2275.245361,2427.851318 2279.826172,2430.879883 
	C2281.978027,2421.964355 2289.879639,2422.488770 2296.510986,2419.745605 
	C2310.740723,2417.857666 2324.313232,2419.102783 2335.334717,2419.062256 
	C2325.341553,2416.786865 2312.518066,2415.766846 2299.920410,2410.656250 
	C2299.497559,2404.854736 2295.677002,2404.760010 2292.005371,2404.461670 
	C2273.470215,2402.955811 2254.946533,2400.955078 2236.335938,2401.061768 
	C2224.927002,2401.127197 2213.826904,2399.493896 2201.900879,2397.117188 
	C2190.357422,2396.825684 2179.659180,2396.973145 2168.086182,2396.937988 
	C2159.759277,2397.890869 2152.657959,2393.943848 2144.332031,2395.332520 
	C2141.778320,2395.194092 2140.174805,2395.617432 2137.831787,2396.621826 
	C2124.721924,2401.208496 2111.939697,2399.475586 2099.534180,2398.601807 
	C2089.682129,2397.907471 2079.817383,2397.562012 2069.127686,2396.998047 
	C2049.546143,2400.281738 2030.868896,2402.236084 2011.907471,2397.847168 
	C2004.800171,2396.201904 1997.470947,2397.256836 1990.633545,2401.006348 
	C1984.565063,2404.334473 1977.558960,2404.057861 1971.055176,2402.914062 
	C1947.075928,2398.696289 1922.961182,2398.404785 1898.754150,2399.324463 
	C1895.104492,2399.463135 1891.400024,2399.470459 1887.864990,2398.344482 
	C1885.633789,2397.634033 1883.268188,2396.677002 1883.433594,2393.751709 
	C1883.592896,2390.937256 1885.914795,2390.053711 1888.189819,2389.355225 
	C1896.637451,2386.761963 1905.304565,2389.614258 1913.913940,2388.275391 
	C1918.989624,2387.486084 1924.413452,2386.926514 1927.467529,2387.317871 
	C1912.821045,2390.374268 1895.283325,2385.068848 1878.740356,2392.591797 
	C1870.094604,2396.523682 1860.630127,2393.818604 1850.780518,2394.349121 
	C1842.072876,2392.400391 1843.592285,2382.511963 1836.646973,2378.671875 
	C1830.161743,2374.713135 1824.045044,2375.777344 1817.792480,2376.910400 
	C1812.350464,2377.896729 1807.197876,2380.834229 1800.708130,2379.208740 
	C1799.249268,2378.224365 1798.662842,2377.427490 1798.063232,2375.783203 
	C1798.539307,2368.395752 1796.657715,2362.121338 1794.886475,2355.143555 
	C1808.798462,2353.026367 1822.509521,2354.380615 1836.206787,2356.085693 
	C1839.232300,2356.462402 1842.496216,2357.296631 1844.073242,2359.721191 
	C1847.241333,2364.591553 1851.809570,2363.951416 1857.196411,2364.005859 
	C1858.379272,2364.932861 1858.604980,2366.581299 1859.108032,2366.671143 
	C1867.219116,2368.114990 1874.678467,2372.195312 1883.212769,2372.069824 
	C1899.198120,2371.834229 1915.202637,2371.603516 1931.174927,2372.120605 
	C1948.048096,2372.666504 1964.891479,2371.809814 1981.750244,2371.964111 
	C1999.308960,2372.125000 2016.873657,2372.210938 2034.428833,2371.912598 
	C2043.143555,2371.764648 2051.826416,2372.177002 2060.089111,2374.576172 
	C2069.326416,2377.257324 2078.090576,2376.014404 2086.366943,2372.532715 
	C2096.259521,2368.370605 2105.786621,2367.091797 2116.431396,2369.383789 
	C2123.442627,2370.893555 2131.014648,2369.801270 2138.335449,2369.865723 
	C2138.665527,2369.868652 2139.083984,2369.903076 2139.313965,2369.728516 
	C2150.114014,2361.538330 2163.496826,2364.772461 2175.400879,2361.503906 
	C2175.848389,2361.381348 2176.104004,2360.558838 2177.074463,2359.162109 
	C2169.253418,2356.353760 2160.828857,2359.606445 2152.149658,2356.590820 
	C2160.831055,2353.199707 2182.865479,2353.146484 2190.637207,2355.950195 
	C2189.412109,2359.603516 2184.793701,2356.577637 2183.310791,2359.760742 
	C2184.261719,2362.905029 2187.038818,2362.001953 2189.096924,2361.913086 
	C2204.007568,2361.268555 2218.898682,2362.899170 2233.893555,2360.497559 
	C2242.480957,2359.122070 2251.574951,2362.104492 2260.566895,2362.321289 
	C2275.618652,2362.683838 2290.633057,2365.822998 2305.698730,2362.191650 
	C2307.908447,2361.658691 2310.462891,2361.573242 2312.654297,2362.110840 
	C2325.138184,2365.172852 2337.818848,2363.603027 2350.410400,2364.038330 
	C2353.643066,2364.150391 2357.443115,2363.353027 2357.935059,2369.151367 
	C2338.666260,2369.312012 2319.653320,2374.249268 2299.281738,2371.447510 
	C2302.563477,2377.139893 2307.559814,2375.033203 2311.306152,2377.635498 
	C2315.680420,2384.413574 2311.542969,2386.834961 2306.346436,2388.346436 
	C2302.546387,2389.451660 2298.529297,2388.838867 2294.610352,2389.017090 
	C2293.279297,2389.077637 2294.908936,2388.813232 2293.671631,2389.244629 
	C2293.281250,2389.380859 2293.752197,2390.299805 2295.178711,2390.828125 
	C2316.888428,2394.999023 2337.796387,2400.407959 2359.533203,2399.132080 
	C2362.854980,2398.937012 2366.330322,2398.238037 2369.437256,2399.282715 
	C2381.025391,2403.180176 2392.980469,2402.687500 2404.902832,2402.864014 
	C2411.314697,2402.959229 2416.794678,2405.647461 2422.674561,2407.943604 
	C2427.542480,2409.844482 2431.357422,2401.275879 2437.939209,2401.042236 
	C2448.995361,2400.650146 2459.754395,2402.687012 2470.616943,2403.812744 
	C2484.915039,2405.294922 2499.100098,2405.096924 2513.250488,2402.553711 
	C2516.884766,2401.900391 2520.328369,2400.832764 2523.735840,2397.755371 
	C2531.331787,2391.673340 2536.738770,2396.495850 2542.925293,2400.565430 
	C2545.045654,2404.101807 2547.494141,2405.127686 2550.853760,2405.036133 
	C2571.140869,2404.483154 2591.386475,2405.394287 2608.343018,2407.186279 
	C2604.260742,2407.502441 2597.035645,2406.240234 2589.147949,2405.173340 
	C2587.427246,2404.631104 2588.230225,2401.717041 2585.312012,2403.257568 
	C2583.503906,2403.453613 2582.479248,2403.274414 2580.801270,2402.464844 
	C2572.572754,2396.384521 2563.478760,2399.084717 2555.169434,2397.624512 
	C2548.000244,2396.364990 2540.590576,2396.225342 2533.440674,2394.597412 
	C2530.677734,2393.968262 2527.004639,2393.597900 2527.296387,2389.419434 
	C2527.540527,2385.928467 2530.322266,2383.728516 2533.317871,2383.714600 
	C2547.425049,2383.647949 2560.581787,2377.212402 2575.640869,2380.709229 
	C2587.847168,2383.543457 2601.343506,2381.405029 2614.313232,2382.018555 
	C2636.577637,2383.071533 2658.791016,2385.694092 2681.133301,2385.101807 
	C2688.222900,2384.913818 2695.183594,2386.144287 2701.333252,2389.588135 
	C2710.648438,2394.805420 2710.498047,2395.073730 2715.703125,2385.149414 
	C2721.816650,2382.057617 2727.242188,2381.883545 2733.323975,2385.304688 
	C2734.723389,2390.865234 2738.641113,2389.765137 2741.771240,2390.090820 
	C2743.544189,2390.275146 2745.207520,2390.806152 2745.433105,2392.743896 
	C2745.660889,2394.703613 2744.127686,2395.531982 2742.442139,2396.107666 
	C2739.596191,2397.080078 2736.731689,2398.197998 2734.668945,2400.439209 
	C2731.629883,2403.740234 2727.848389,2404.109863 2723.731445,2404.034668 
	C2717.080322,2403.913330 2710.424561,2403.978271 2703.771484,2404.030273 
	C2701.590820,2404.047363 2699.235840,2403.647949 2697.495605,2405.354492 
	C2697.833740,2408.159424 2699.804443,2407.960205 2701.271484,2407.901367 
	C2708.661377,2407.604980 2715.830566,2408.345947 2722.761719,2411.184326 
	C2725.573242,2412.335693 2728.860352,2412.790527 2731.258301,2410.458740 
	C2738.524902,2403.392578 2747.573486,2405.287842 2756.229492,2405.098145 
	C2756.540283,2405.091309 2756.884277,2405.452637 2757.164795,2405.397949 
	C2765.567383,2403.756836 2772.034912,2404.642334 2774.458496,2414.915039 
	C2775.315918,2418.550049 2781.217529,2419.418457 2784.026611,2417.322266 
	C2788.932129,2413.661865 2793.687744,2415.129150 2798.232422,2416.276855 
	C2805.469482,2418.104248 2812.708496,2418.190918 2819.988770,2417.933350 
	C2827.687988,2417.661133 2835.085205,2418.854736 2842.361572,2421.000977 
	C2842.693848,2424.575195 2840.062500,2423.884766 2838.386475,2423.912354 
	C2830.404297,2424.044434 2822.417725,2423.911133 2814.435303,2424.042725 
	C2811.600098,2424.089355 2808.290283,2423.046143 2806.199463,2427.242432 
	C2804.654053,2428.060059 2803.215820,2427.779053 2802.008301,2428.184814 
	C2785.831299,2433.618164 2769.105225,2431.779053 2752.549805,2431.902588 
	C2745.948242,2431.951904 2739.251709,2431.619873 2734.358398,2425.933105 
	C2732.673340,2423.974854 2730.151611,2423.695312 2727.887939,2424.518066 
	C2721.397705,2426.876953 2714.646484,2425.278809 2708.107422,2426.109375 
	C2693.558838,2427.956787 2679.078857,2425.482666 2664.572998,2426.003662 
	C2661.500000,2426.113525 2658.135254,2425.184326 2653.797852,2428.547363 
	C2677.643311,2432.292969 2699.821777,2434.739014 2721.546875,2431.394775 
	C2723.157715,2435.126953 2721.601074,2435.444580 2720.440430,2435.869385 
	C2719.240479,2436.309326 2717.847412,2436.356689 2717.397461,2437.895508 
	C2717.723145,2438.405518 2717.944824,2439.193359 2718.406494,2439.376465 
	C2719.300049,2439.730713 2720.457031,2440.122070 2721.281738,2439.841553 
	C2730.818115,2436.598389 2740.521973,2437.630615 2749.721924,2440.215576 
	C2759.456787,2442.950684 2769.545654,2439.054199 2779.498535,2443.452393 
	C2777.644287,2446.914795 2773.825928,2445.932129 2770.844727,2447.323730 
	C2767.546875,2448.863037 2761.284912,2446.335205 2762.883545,2454.084961 
	C2763.247803,2455.852295 2760.176270,2456.136963 2758.930176,2455.222168 
	C2753.117432,2450.955811 2745.741455,2451.111816 2739.328369,2449.096191 
	C2730.271484,2446.249023 2719.696777,2447.498535 2710.409424,2451.309326 
	C2707.482178,2452.510254 2704.229004,2453.442871 2701.520020,2455.153320 
	C2701.456299,2458.640869 2704.047119,2457.893311 2705.727295,2457.914551 
	C2715.713867,2458.039795 2725.703125,2458.038086 2735.690918,2457.998291 
	C2740.151855,2457.980469 2743.696777,2458.970459 2743.992676,2465.140137 
	C2742.536621,2465.984619 2741.045654,2466.028320 2738.809082,2466.065674 
	C2737.708496,2465.797852 2737.353027,2465.536133 2736.465820,2465.667969 
	C2734.349365,2466.638672 2732.764893,2467.215820 2730.543457,2467.794434 
	C2729.060303,2466.930908 2728.082031,2466.616699 2726.500732,2467.449219 
	C2717.966553,2469.003662 2709.958496,2469.722656 2701.654297,2468.334473 
	C2696.551025,2467.481689 2690.325195,2465.983643 2686.628662,2472.629639 
	C2685.463379,2474.725342 2682.341553,2473.937256 2680.048584,2473.960205 
	C2674.383057,2474.016602 2668.716064,2474.004883 2663.050049,2473.968262 
	C2657.360352,2473.931641 2651.564209,2473.426025 2648.085938,2479.497070 
	C2646.819336,2481.707764 2644.771484,2481.458740 2642.890869,2480.348877 
	C2637.993896,2477.458496 2632.601318,2477.810791 2627.301270,2478.007080 
	C2613.376221,2478.523193 2599.330811,2476.360840 2585.527100,2479.853760 
	C2584.287354,2480.166992 2582.867920,2479.917725 2581.554199,2479.752686 
	C2580.194824,2479.581787 2578.991211,2479.010986 2578.646729,2477.479492 
	C2578.228760,2475.622559 2579.073242,2474.248291 2580.492188,2473.211914 
	C2582.525391,2471.726807 2584.947998,2471.878662 2587.243408,2472.024414 
	C2592.679199,2472.369629 2597.897461,2471.447510 2602.872070,2469.403809 
	C2606.733887,2467.817139 2610.708984,2468.191650 2614.683838,2467.972412 
	C2618.593750,2467.756836 2622.666504,2468.950684 2626.390625,2466.895752 
	C2626.586670,2463.547852 2624.078125,2464.127930 2622.290527,2464.031494 
	C2612.358154,2463.495361 2602.444336,2463.807373 2592.452393,2462.736084 
	C2585.544189,2461.995361 2578.262939,2463.798096 2570.870117,2462.946777 
	C2567.602783,2462.570557 2562.732666,2465.094238 2559.128418,2466.937256 
	C2552.111816,2470.526123 2544.888672,2469.774902 2536.732666,2470.244629 
z"
        />
        <path
          fill="#607B93"
          opacity="1.000000"
          stroke="none"
          d="
M2785.230469,2376.704102 
	C2797.446045,2376.542969 2809.204346,2377.261719 2820.588867,2377.022217 
	C2834.874268,2376.721436 2849.676270,2381.935547 2863.692383,2375.188477 
	C2873.241455,2376.533691 2882.868652,2375.639404 2892.442871,2376.213379 
	C2894.284912,2376.323975 2895.657227,2377.415771 2895.364014,2379.429199 
	C2895.140137,2380.964355 2893.794922,2380.921875 2892.383057,2381.119873 
	C2879.606689,2382.911377 2866.866211,2384.980469 2853.882080,2383.996582 
	C2845.720215,2383.377930 2837.555908,2382.780029 2829.386963,2382.266357 
	C2827.247070,2382.131348 2824.732666,2381.078613 2823.235596,2383.986328 
	C2824.619629,2387.457764 2829.076416,2384.680908 2830.640381,2387.607910 
	C2830.574951,2388.697266 2829.955322,2389.692383 2828.978516,2389.683838 
	C2816.053223,2389.571289 2803.066162,2391.460449 2790.222656,2388.469482 
	C2787.288818,2387.786621 2783.835693,2387.081055 2781.430176,2388.794922 
	C2773.478271,2394.460938 2766.339844,2391.584473 2759.079102,2387.595703 
	C2757.926025,2386.961914 2756.615234,2386.615479 2754.637695,2386.021973 
	C2752.662109,2378.483887 2758.397705,2379.632568 2762.506836,2379.062500 
	C2769.831055,2378.046875 2777.470215,2380.867188 2785.230469,2376.704102 
z"
        />
        <path
          fill="#607B93"
          opacity="1.000000"
          stroke="none"
          d="
M2902.125244,2407.107666 
	C2891.490234,2411.519043 2880.957031,2415.334473 2869.663330,2411.818848 
	C2862.834717,2409.693604 2855.691406,2411.059326 2848.975342,2409.765381 
	C2838.211670,2407.691895 2827.747070,2411.687500 2817.142090,2410.009521 
	C2804.479492,2408.006348 2791.722656,2406.500244 2777.856934,2402.932861 
	C2783.671631,2398.248047 2789.019287,2399.833252 2793.775635,2400.513428 
	C2799.594971,2401.345459 2805.045654,2404.258545 2809.524902,2397.259277 
	C2811.303223,2394.480957 2815.161621,2396.400635 2817.706543,2397.964844 
	C2827.288574,2403.854492 2836.874512,2403.502441 2846.902100,2398.984863 
	C2851.963135,2396.704590 2857.337158,2397.882568 2863.235352,2399.914062 
	C2867.271484,2402.788574 2870.633789,2400.968506 2874.164795,2400.783447 
	C2883.866699,2400.274170 2894.420410,2396.858887 2902.125244,2407.107666 
z"
        />
        <path
          fill="#597288"
          opacity="1.000000"
          stroke="none"
          d="
M2902.766113,2407.836426 
	C2893.506348,2401.830078 2884.080078,2400.937256 2874.156494,2402.968506 
	C2870.690918,2403.677490 2867.115479,2405.505371 2864.206055,2400.771484 
	C2889.130127,2396.106689 2914.405273,2398.785889 2939.584717,2397.960693 
	C2943.701172,2397.826172 2948.363281,2399.289307 2950.770020,2393.876221 
	C2951.663574,2391.866211 2954.216064,2391.773682 2956.129150,2392.106445 
	C2965.500244,2393.735840 2975.019775,2393.995850 2986.768799,2394.406738 
	C2980.679443,2399.654785 2975.633301,2397.572998 2971.158203,2397.912109 
	C2966.247314,2398.284180 2961.286621,2397.997070 2956.273438,2398.638184 
	C2966.508545,2403.386475 2977.588379,2403.847412 2988.408447,2404.377441 
	C2988.554199,2407.757568 2986.794434,2407.862549 2985.152832,2407.957275 
	C2970.518311,2408.800293 2956.129395,2406.140137 2941.636230,2404.921143 
	C2928.809570,2403.842529 2915.865723,2401.834229 2902.766113,2407.836426 
z"
        />
        <path
          fill="#4C6880"
          opacity="1.000000"
          stroke="none"
          d="
M2784.936523,2376.102539 
	C2786.246826,2380.334961 2784.257568,2382.102295 2780.104492,2382.023193 
	C2774.193848,2381.910400 2768.276855,2381.900391 2762.367188,2382.036133 
	C2759.556396,2382.100586 2756.308594,2381.112061 2754.146240,2385.147217 
	C2747.867432,2385.923340 2741.836914,2385.938721 2734.898193,2385.967285 
	C2728.587646,2385.987061 2723.184814,2385.993652 2716.881348,2386.019531 
	C2712.244385,2389.054688 2708.538330,2388.449707 2704.254639,2385.326660 
	C2702.819824,2383.012939 2702.861816,2381.441650 2705.145996,2380.045898 
	C2717.243408,2380.136963 2728.244385,2377.745850 2739.375732,2376.729492 
	C2748.572510,2375.889893 2757.873291,2376.144043 2767.129395,2376.016357 
	C2772.756104,2375.938721 2778.385498,2376.056396 2784.936523,2376.102539 
z"
        />
        <path
          fill="#607B93"
          opacity="1.000000"
          stroke="none"
          d="
M2705.985596,2379.189453 
	C2705.369873,2380.959229 2704.749512,2381.889648 2704.069580,2383.400391 
	C2702.419922,2390.758057 2697.089600,2388.312500 2693.757568,2387.133057 
	C2689.138672,2385.498779 2690.256836,2381.645020 2694.137207,2378.027100 
	C2685.981689,2382.125244 2679.487305,2379.955811 2672.499023,2376.587402 
	C2671.980225,2374.478027 2671.989746,2372.985352 2671.999512,2370.746338 
	C2681.521973,2370.254395 2690.850098,2373.002686 2700.479248,2372.074463 
	C2704.914795,2371.646973 2706.146484,2374.500488 2705.985596,2379.189453 
z"
        />
        <path
          fill="#536F86"
          opacity="1.000000"
          stroke="none"
          d="
M2933.333496,2385.395020 
	C2929.123779,2383.411377 2926.031738,2385.495361 2922.803223,2385.767334 
	C2919.219971,2386.069580 2915.539062,2386.649658 2911.931885,2385.225342 
	C2920.729736,2377.955322 2949.259033,2378.211182 2971.287598,2385.929199 
	C2964.855713,2390.283936 2958.950439,2386.327148 2953.128174,2386.117188 
	C2946.813477,2385.889404 2940.493408,2385.794678 2933.333496,2385.395020 
z"
        />
        <path
          fill="#607B93"
          opacity="1.000000"
          stroke="none"
          d="
M2909.739258,2425.421143 
	C2909.600342,2428.710693 2908.016357,2429.956299 2905.385742,2429.958008 
	C2902.814697,2429.959473 2900.243408,2429.968750 2896.825195,2429.960938 
	C2897.017334,2425.220703 2894.111328,2422.315674 2890.440186,2419.897949 
	C2904.653564,2410.576172 2906.458252,2415.351318 2909.739258,2425.421143 
z"
        />
        <path
          fill="#607B93"
          opacity="1.000000"
          stroke="none"
          d="
M2877.981445,2427.316406 
	C2876.324707,2428.128906 2874.264404,2427.620117 2873.078125,2428.415039 
	C2864.873779,2433.912598 2856.049561,2432.285156 2846.612793,2430.567139 
	C2848.808838,2425.604980 2852.361084,2424.012939 2857.790039,2425.437256 
	C2862.929932,2426.785645 2868.341553,2426.759521 2872.973633,2423.098633 
	C2878.430176,2418.785889 2877.731689,2423.397949 2877.981445,2427.316406 
z"
        />
        <path
          fill="#597288"
          opacity="1.000000"
          stroke="none"
          d="
M2999.039062,2399.927734 
	C2995.928711,2399.556885 2992.996094,2399.315186 2993.291260,2396.364014 
	C2993.607910,2393.199219 2996.828125,2393.889648 2998.844971,2394.165527 
	C3004.148193,2394.890625 3009.390137,2396.063232 3014.639893,2397.939209 
	C3009.951172,2400.953613 3004.783203,2399.551025 2999.039062,2399.927734 
z"
        />
        <path
          fill="#607B93"
          opacity="1.000000"
          stroke="none"
          d="
M2841.896484,2391.875000 
	C2839.988037,2391.608398 2837.928711,2391.579346 2837.709717,2390.929932 
	C2836.457275,2387.217285 2840.114258,2388.143311 2841.728760,2387.275635 
	C2843.146484,2386.513672 2845.000488,2386.343750 2845.396484,2388.668457 
	C2845.744873,2390.713135 2844.258545,2391.415283 2841.896484,2391.875000 
z"
        />
        <path
          fill="#5D758A"
          opacity="1.000000"
          stroke="none"
          d="
M114.771988,2568.463379 
	C112.685135,2560.279297 112.694382,2559.909424 118.957077,2560.066162 
	C126.651611,2560.258545 133.961349,2558.148682 141.414200,2557.000977 
	C145.020020,2556.445557 148.587494,2556.074463 153.071289,2555.879395 
	C155.945221,2554.163086 157.701813,2552.031738 161.326477,2551.977051 
	C164.761307,2551.955811 167.535233,2551.975098 171.141205,2551.975586 
	C175.121140,2546.449707 179.478699,2541.720947 186.147263,2543.133545 
	C203.474380,2546.803955 221.268967,2542.541748 239.329712,2546.886719 
	C252.568344,2548.048584 264.708038,2544.464844 277.852600,2545.012695 
	C281.063202,2545.409424 283.487946,2545.690674 286.555664,2544.478271 
	C288.788391,2544.079834 290.245544,2544.276367 292.230530,2545.385254 
	C295.487091,2551.830322 300.411774,2551.568359 305.466309,2550.130127 
	C316.816895,2546.900635 328.389862,2546.551270 340.046448,2547.074219 
	C343.365723,2547.222900 347.398590,2547.209961 348.472351,2550.977295 
	C349.921112,2556.060303 344.089539,2555.250000 342.654144,2556.453613 
	C351.414520,2558.019775 360.385803,2556.567139 369.251312,2551.283203 
	C372.157410,2550.955566 374.276764,2551.068115 377.176514,2551.647949 
	C386.442627,2556.398682 395.389618,2555.637695 405.165222,2553.922363 
	C408.563324,2553.073975 411.235107,2552.894287 414.714844,2552.970703 
	C420.096405,2552.903809 424.893097,2554.049805 429.710999,2550.504883 
	C436.126770,2548.051758 442.271667,2549.765381 448.791473,2548.058105 
	C473.417847,2548.799316 497.181946,2551.971436 521.155151,2549.728760 
	C530.960144,2548.811523 539.005127,2553.668213 545.133789,2562.164795 
	C544.029236,2555.410645 547.290283,2553.929443 552.123230,2554.705566 
	C518.497681,2546.485352 484.523956,2546.340576 449.636719,2548.023438 
	C437.249451,2550.283203 425.829865,2548.316162 414.365845,2546.871094 
	C393.617432,2544.256348 372.859070,2548.525879 351.207794,2546.952148 
	C338.453918,2546.744385 326.095306,2548.938477 316.001099,2538.971924 
	C320.950378,2535.732910 325.423584,2535.232178 330.816833,2538.407959 
	C339.139984,2543.308838 347.041901,2538.201904 353.973999,2534.255859 
	C358.257080,2531.817627 361.494843,2529.499756 366.502899,2531.504639 
	C367.740295,2532.000244 369.668884,2531.003418 371.202271,2530.469482 
	C373.272125,2529.749268 376.309265,2529.467041 375.202759,2526.133545 
	C374.365082,2523.609619 372.118530,2524.532471 370.162292,2524.899170 
	C364.347595,2525.988525 358.529114,2527.107178 352.667084,2527.867920 
	C350.614746,2528.134277 347.431793,2528.779053 347.182678,2525.445801 
	C347.017365,2523.234131 349.380737,2522.491943 351.412567,2522.081543 
	C357.200134,2520.912354 362.980743,2519.708740 369.497528,2518.188477 
	C371.252258,2514.995117 373.510498,2514.165039 377.015930,2514.274902 
	C382.304382,2517.261719 388.839722,2516.958984 389.521637,2525.437988 
	C389.882050,2529.919434 396.906525,2526.885010 400.832581,2527.719238 
	C405.961243,2528.808350 402.349518,2524.837891 403.013428,2523.473877 
	C405.061676,2519.265869 407.935425,2515.763916 411.449829,2511.833008 
	C416.790497,2511.633301 417.555206,2514.844482 417.460754,2519.339844 
	C419.856598,2530.980713 428.723816,2532.871338 437.745331,2533.773926 
	C447.058777,2534.706055 454.809418,2531.803955 459.116028,2521.763428 
	C460.837006,2520.480469 462.190643,2520.191650 464.306152,2520.351562 
	C467.085419,2519.972412 469.989563,2526.399902 471.052429,2519.132324 
	C462.218658,2516.535156 453.678467,2516.529053 445.371643,2519.194824 
	C438.294739,2521.466309 431.561951,2520.304688 424.816437,2518.295654 
	C422.171722,2517.507812 419.521851,2515.763428 420.109009,2512.727295 
	C420.604187,2510.166748 423.516418,2510.093994 425.908234,2509.918457 
	C441.424225,2508.781982 456.944153,2507.863037 469.886658,2507.959473 
	C458.650116,2507.048096 444.836426,2509.348633 430.987732,2509.070068 
	C428.161194,2509.013184 424.206635,2510.689209 423.554047,2506.525391 
	C422.854675,2502.063232 427.209869,2501.389893 430.315369,2500.860107 
	C447.381256,2497.948975 464.736572,2498.701172 481.920013,2497.115234 
	C485.839905,2496.753418 489.882416,2497.291504 494.658752,2497.039307 
	C499.121216,2496.797119 502.800598,2496.710205 507.251099,2496.570557 
	C510.185577,2496.744873 511.901245,2495.577393 514.427856,2494.437988 
	C539.091064,2495.959717 562.833008,2492.668701 586.713562,2491.026123 
	C573.712097,2487.862305 560.058960,2490.835205 546.440063,2486.856445 
	C540.831543,2484.656982 536.570984,2486.030029 531.971680,2489.455078 
	C527.981079,2491.046143 524.544006,2491.036621 520.376465,2490.486328 
	C515.427307,2488.066406 512.238953,2489.474365 509.070068,2493.581055 
	C505.289490,2495.097168 502.127289,2494.902832 498.147827,2493.927246 
	C490.111938,2490.453125 482.511230,2492.322998 475.877655,2494.280518 
	C454.009735,2500.733154 431.660950,2500.200684 409.561157,2499.019531 
	C389.309448,2497.937012 370.113098,2504.594482 350.148193,2504.216309 
	C347.523132,2504.166504 344.570831,2505.698242 342.263367,2504.733154 
	C329.855164,2499.544678 317.066010,2501.416992 303.543274,2501.712402 
	C301.797668,2500.715332 301.176147,2499.829590 300.943726,2497.878174 
	C302.259277,2493.118652 306.464905,2494.739014 309.442444,2492.399414 
	C312.910187,2489.377197 316.571045,2488.123779 320.095856,2488.211914 
	C334.625977,2488.574707 348.838165,2483.522949 363.475891,2485.895508 
	C366.504761,2486.386475 369.674164,2486.001953 369.919922,2480.911133 
	C370.947540,2478.706787 372.348114,2477.718750 374.019226,2477.482666 
	C397.579102,2474.150391 420.755920,2466.638672 445.869995,2470.997559 
	C448.912842,2471.537109 450.997345,2472.271484 453.586182,2473.784668 
	C458.587830,2477.432861 463.290985,2477.339111 468.379700,2476.164795 
	C476.668793,2474.251953 484.957825,2471.763672 493.641357,2473.461914 
	C485.456940,2471.841309 477.491150,2473.576904 469.560333,2475.306152 
	C465.318207,2476.231201 461.159882,2477.254883 456.289795,2475.098633 
	C454.652374,2473.440430 454.307770,2471.993652 454.548645,2469.671387 
	C455.176453,2467.966553 455.865631,2467.242920 457.496094,2466.475342 
	C462.927948,2465.762939 465.359528,2461.217285 470.334900,2459.616699 
	C482.027039,2459.149658 492.944855,2458.405518 504.666199,2459.868164 
	C508.990906,2462.943604 512.461853,2464.382812 517.117432,2462.150391 
	C528.320801,2456.778564 540.376587,2459.242432 552.088928,2459.294678 
	C557.467651,2459.318604 560.779358,2462.375000 557.931213,2469.174805 
	C547.412903,2473.670410 536.523438,2472.328369 526.254395,2475.185303 
	C538.529236,2471.810303 551.396240,2473.376709 564.083191,2469.568359 
	C566.756714,2465.763184 570.512268,2465.449707 573.846436,2464.239258 
	C577.136780,2463.044922 580.921265,2462.403809 581.929077,2457.601074 
	C580.288757,2454.597168 577.826782,2455.091797 575.552795,2454.892578 
	C565.050232,2453.973633 563.561401,2452.092529 564.088257,2439.365723 
	C586.228088,2439.435791 607.643982,2439.009033 628.890137,2434.266602 
	C635.395935,2432.814453 642.874268,2432.578613 648.816833,2437.769043 
	C650.720642,2439.432129 653.130432,2439.209961 655.465027,2439.197998 
	C683.988403,2439.054443 712.273315,2444.526123 740.868347,2442.835205 
	C743.183411,2442.698242 745.506958,2443.217529 747.648438,2444.234375 
	C749.214722,2444.978271 750.612122,2446.098633 750.590393,2447.901855 
	C750.562256,2450.226562 748.882446,2451.639404 746.737732,2451.670898 
	C740.750305,2451.759033 735.218262,2455.136963 729.076050,2453.846924 
	C735.469177,2455.187500 741.944336,2453.329590 747.440369,2451.488525 
	C758.334351,2447.839844 768.788574,2448.790039 779.555359,2450.183838 
	C781.399048,2450.422363 782.654175,2449.307373 784.446228,2447.563477 
	C789.200623,2444.714111 793.514221,2445.291504 798.602539,2446.439941 
	C802.983582,2447.460449 806.385315,2447.066162 810.145813,2444.430420 
	C813.532898,2443.264160 816.189819,2443.289795 819.586243,2444.503906 
	C830.110413,2448.068604 838.703674,2455.759521 848.828613,2456.166504 
	C862.664062,2456.722656 876.501770,2459.869873 890.460449,2457.416016 
	C893.745728,2456.838623 897.063660,2457.898193 901.124512,2458.873291 
	C922.184204,2460.459717 942.233215,2456.204346 963.271240,2457.256104 
	C974.759216,2458.326660 985.521912,2459.980957 995.201538,2451.468262 
	C999.756042,2448.492920 1004.086670,2448.927002 1009.177185,2449.486084 
	C1012.301636,2450.369385 1014.504272,2451.755615 1017.896912,2451.244873 
	C1027.312744,2450.434326 1035.891602,2450.803711 1045.153320,2452.871094 
	C1058.018188,2451.449219 1070.055664,2447.426514 1082.200684,2452.942139 
	C1084.889648,2454.163330 1088.743774,2454.245605 1088.512329,2458.035645 
	C1088.244019,2462.431641 1084.000977,2463.020996 1080.909058,2462.533203 
	C1071.286499,2461.015869 1061.672607,2463.173340 1053.799316,2462.445557 
	C1077.707764,2462.720947 1103.231567,2464.597656 1129.510132,2467.589844 
	C1159.203979,2467.974365 1187.995483,2469.995361 1217.708496,2470.917725 
	C1227.155762,2467.331543 1235.498413,2467.447021 1244.691162,2469.645020 
	C1254.935181,2472.094482 1265.998535,2471.848389 1276.354492,2469.770996 
	C1291.437866,2466.745605 1306.444458,2465.333740 1321.771362,2466.248047 
	C1330.294189,2466.756348 1338.641235,2461.887695 1348.199463,2465.399902 
	C1352.402100,2466.968750 1353.932373,2469.313721 1352.730347,2473.767090 
	C1348.994141,2477.375732 1344.957031,2477.286621 1340.993164,2477.542236 
	C1298.744751,2480.268311 1256.486084,2482.824707 1214.201050,2484.915283 
	C1207.252441,2485.259033 1200.579102,2487.547119 1192.770996,2487.719727 
	C1186.622070,2488.149658 1181.822510,2489.321045 1176.923218,2492.010742 
	C1170.550415,2495.510498 1162.865601,2494.076416 1155.765259,2494.723877 
	C1150.182373,2495.232910 1144.536621,2495.128418 1138.197021,2496.371582 
	C1130.878296,2497.593506 1124.332153,2497.482178 1116.996338,2496.784424 
	C1111.437378,2495.345947 1106.695801,2493.312988 1100.962646,2496.164551 
	C1096.416504,2497.175781 1092.665649,2497.291992 1088.363403,2495.238037 
	C1085.486084,2485.785400 1088.069092,2481.659912 1097.245239,2483.477783 
	C1115.632202,2487.121094 1133.557373,2482.388428 1151.845825,2482.533691 
	C1149.590820,2480.478760 1147.077637,2481.348145 1144.865479,2481.755615 
	C1137.377075,2483.134521 1129.942017,2485.092529 1124.318848,2477.415039 
	C1123.683472,2476.547119 1121.777344,2477.045898 1119.652832,2476.989258 
	C1102.531982,2476.687988 1086.108643,2475.991211 1069.914673,2477.044922 
	C1054.399658,2478.054443 1038.767944,2479.766602 1023.351257,2481.992432 
	C1003.910217,2484.798828 984.926758,2480.841309 965.731873,2480.559570 
	C961.120483,2480.491943 956.442810,2480.188477 951.818481,2479.471924 
	C989.061951,2485.311768 1026.775269,2480.434326 1064.948975,2485.012695 
	C1069.642334,2485.514404 1072.905762,2486.625244 1073.328613,2492.024902 
	C1072.977783,2493.376953 1072.733643,2493.873779 1071.878906,2494.894043 
	C1058.780518,2498.187256 1046.484741,2502.091797 1033.337769,2499.259033 
	C1021.441711,2496.695801 1009.322632,2496.492432 998.902161,2498.098633 
	C1000.305054,2498.602783 1004.074463,2499.339600 1001.871094,2504.783691 
	C992.511536,2508.375977 983.424316,2509.241943 973.326782,2508.938232 
	C934.856140,2507.482910 897.428833,2509.989746 860.040283,2513.908936 
	C845.666992,2515.415527 831.587158,2511.782471 817.437134,2509.921387 
	C808.826843,2508.788818 800.268066,2508.052490 791.678040,2509.774658 
	C786.946533,2510.723633 782.239807,2510.662109 780.128235,2517.118408 
	C778.530701,2522.003174 766.528076,2523.550537 759.100647,2519.394287 
	C751.319458,2515.040283 744.699463,2515.552734 737.647339,2520.035400 
	C735.969299,2521.102539 734.032471,2522.263184 732.106079,2522.177979 
	C712.260498,2521.304932 692.450378,2530.328613 672.516541,2522.014893 
	C665.761963,2519.197998 658.256958,2519.069092 650.051514,2519.027100 
	C623.648438,2519.319824 598.112793,2523.508789 571.778564,2519.008789 
	C544.257019,2525.652100 516.843323,2524.807617 489.652618,2526.593994 
	C500.639069,2527.900879 511.634552,2525.823486 522.619263,2526.687012 
	C524.588318,2526.841797 526.588562,2526.273926 528.587708,2526.336914 
	C531.726807,2526.435303 535.030945,2527.100342 535.860229,2530.416260 
	C536.731689,2533.900879 532.038574,2533.390381 530.862244,2535.682861 
	C541.425415,2540.438721 544.340271,2539.620605 551.384399,2530.504639 
	C554.632935,2526.300537 559.071472,2527.981934 563.358276,2528.441406 
	C575.997375,2529.796631 588.463379,2529.587891 600.555908,2522.399170 
	C615.238586,2518.992920 629.433899,2522.871338 644.269043,2520.856445 
	C661.637695,2516.028076 676.880432,2525.827881 692.939880,2525.278809 
	C712.711792,2524.603027 732.482422,2526.830078 752.304749,2523.756104 
	C764.696106,2521.834473 777.367065,2520.354736 790.060059,2521.017578 
	C793.639526,2521.204590 798.849548,2520.574219 799.439087,2524.604492 
	C800.132019,2529.342041 794.437134,2528.473145 791.364502,2529.798096 
	C796.721863,2537.507080 796.290039,2539.014404 787.721680,2542.220947 
	C786.796448,2542.567139 785.336243,2542.357422 784.891235,2543.018311 
	C776.957764,2554.797363 764.730042,2550.063232 753.978333,2551.359131 
	C728.000488,2554.489746 701.717346,2554.127930 675.846741,2558.088379 
	C660.831421,2560.387207 646.096008,2561.103516 631.426025,2556.445068 
	C627.554871,2555.215576 623.497925,2555.067139 619.560974,2555.915039 
	C611.517212,2557.646973 603.574646,2559.885986 595.145874,2560.401855 
	C606.601562,2561.950439 619.184509,2551.843750 629.888428,2564.586914 
	C630.960938,2566.133789 631.243530,2567.145020 631.105591,2568.994629 
	C626.116882,2576.687744 618.206909,2574.397705 611.707642,2575.715332 
	C599.565247,2578.177246 587.296936,2577.854736 575.515076,2573.076416 
	C568.789734,2570.349121 561.574585,2570.052490 554.939697,2571.976318 
	C537.016968,2577.171875 520.288940,2576.426270 504.883881,2564.905273 
	C500.918304,2561.939453 497.898621,2563.348633 493.411499,2565.349854 
	C482.921509,2570.029053 471.584442,2569.455811 459.903320,2564.852051 
	C463.834503,2570.594971 460.157776,2571.802490 456.177582,2572.960938 
	C450.816040,2574.521973 444.855377,2574.023682 439.344849,2577.937012 
	C429.013123,2579.831299 419.358948,2578.665283 408.955841,2578.872070 
	C401.770569,2576.796387 395.293427,2575.778320 388.612732,2577.284424 
	C387.319611,2577.575684 385.504547,2578.312744 384.744354,2577.668213 
	C374.666077,2569.120117 362.932159,2578.114746 352.284515,2574.442627 
	C346.194397,2572.342773 339.860626,2575.211670 333.663269,2576.154053 
	C326.731842,2577.208008 319.706879,2578.080566 313.318909,2574.247559 
	C307.782410,2570.925293 308.077179,2567.658691 313.841644,2564.320312 
	C308.820618,2562.949951 304.157623,2562.786133 300.623810,2567.713379 
	C298.983337,2570.000732 296.279724,2570.608887 292.781982,2570.968506 
	C276.948822,2572.263672 261.905090,2575.373779 246.280396,2570.545898 
	C244.000214,2563.990234 248.257370,2562.567871 252.465179,2561.218506 
	C256.766327,2559.839111 261.511841,2560.004639 265.481903,2557.129150 
	C241.190079,2560.575928 216.029190,2554.349854 191.546021,2564.429932 
	C170.007172,2565.553711 149.433960,2568.052734 128.870621,2570.753906 
	C124.401680,2571.341064 119.613045,2571.960205 114.771988,2568.463379 
z"
        />
        <path
          fill="#4B6377"
          opacity="1.000000"
          stroke="none"
          d="
M301.459320,2499.549316 
	C307.436615,2500.574463 312.681549,2499.228027 317.664886,2497.596191 
	C323.855835,2495.569092 329.429962,2495.537842 334.587830,2499.696289 
	C337.778839,2502.269043 341.374176,2502.174561 345.020905,2501.695557 
	C347.309174,2501.395020 349.551270,2500.105469 351.789185,2500.166748 
	C363.141510,2500.477539 374.229462,2499.358887 385.190704,2496.140625 
	C391.238800,2494.365234 397.594910,2495.739014 403.839386,2495.889648 
	C419.133057,2496.258789 434.441620,2496.007568 449.744171,2496.013428 
	C452.744720,2496.014648 455.673401,2495.837402 458.572784,2494.794434 
	C468.916901,2491.072754 479.866669,2490.210205 490.589325,2488.295410 
	C493.128998,2487.841553 495.987976,2487.307861 497.833740,2491.085449 
	C499.541504,2494.289307 498.760254,2496.490967 496.726807,2499.148438 
	C491.375763,2500.325195 486.660706,2501.972656 481.565369,2500.178955 
	C479.207092,2499.348633 476.118011,2499.479736 473.671631,2500.213379 
	C464.520782,2502.958008 455.196075,2501.839111 445.935822,2501.980469 
	C438.775665,2502.089844 431.938599,2503.489746 425.243835,2506.110107 
	C444.331421,2504.391846 463.311646,2506.120117 482.297363,2506.592285 
	C483.026764,2506.610352 483.727936,2507.766113 484.654816,2508.577881 
	C480.082550,2511.800781 475.626190,2512.753662 470.497803,2510.516357 
	C468.447693,2509.621826 466.109131,2508.691162 463.729370,2509.337891 
	C450.111237,2513.040039 436.094543,2511.664062 422.425293,2512.070557 
	C421.523773,2514.988037 423.275818,2515.604736 424.803040,2515.741699 
	C428.775452,2516.097412 432.707794,2516.402832 436.546967,2517.687256 
	C438.422913,2518.314697 440.677277,2518.326660 442.359100,2517.158936 
	C451.198853,2511.021973 461.046143,2515.054199 470.401123,2514.282715 
	C472.780029,2514.086426 473.652924,2516.164551 473.914093,2519.147217 
	C473.104187,2524.374512 469.874908,2522.899902 466.597168,2522.380371 
	C464.509125,2522.041992 462.991974,2522.037354 460.716583,2522.058105 
	C444.950623,2528.334229 430.005096,2530.904541 414.631226,2520.683594 
	C415.451080,2517.339355 416.516876,2514.686035 412.549866,2512.290283 
	C401.701843,2517.285889 390.639740,2517.410156 378.725891,2516.430176 
	C375.686798,2515.890137 373.451111,2516.051270 370.829590,2517.568359 
	C365.067871,2513.113525 359.117035,2516.074219 353.385468,2515.714111 
	C337.597687,2514.721924 322.656036,2519.988037 307.064301,2520.650635 
	C292.614960,2521.264893 278.230286,2524.023438 263.643372,2524.029053 
	C255.989014,2524.031982 248.414581,2523.884766 240.536438,2520.751465 
	C239.545273,2513.599854 246.091736,2515.384766 249.520523,2512.251465 
	C251.185196,2510.127197 252.903275,2509.990723 255.307541,2509.925781 
	C258.123871,2507.783691 260.859467,2507.399414 263.192352,2508.897705 
	C267.314117,2511.545166 270.672943,2509.646973 274.901123,2507.715332 
	C294.113403,2506.530762 312.373993,2511.728760 333.219574,2509.843750 
	C314.749725,2509.855469 298.668701,2507.544434 281.692627,2507.653809 
	C279.295837,2506.705566 278.427368,2505.370605 278.021973,2502.842285 
	C279.990387,2499.945801 281.882965,2497.962402 276.995056,2497.353760 
	C275.734680,2497.196777 274.532532,2496.571777 272.648438,2496.093750 
	C271.186523,2494.589844 271.931091,2493.643555 273.547363,2492.442139 
	C285.434937,2492.037598 296.785339,2491.993652 309.072021,2491.909668 
	C309.663757,2496.152832 306.421906,2495.960938 302.659851,2496.199707 
	C299.946106,2497.981445 297.551880,2497.988770 294.561249,2497.999268 
	C293.324310,2497.995361 292.440125,2498.030518 292.441315,2498.090088 
	C292.467987,2499.421143 291.777618,2498.087402 293.689148,2498.063477 
	C296.526855,2498.088623 298.840607,2497.791992 301.459320,2499.549316 
z"
        />
        <path
          fill="#4B6377"
          opacity="1.000000"
          stroke="none"
          d="
M448.540039,2473.672607 
	C436.103210,2474.789795 424.251038,2472.812744 412.351135,2473.872803 
	C407.092926,2474.341309 402.278351,2474.719971 397.275421,2477.344971 
	C389.437439,2481.457764 380.386902,2479.872559 370.863770,2480.092285 
	C365.283691,2479.972656 360.763885,2480.493408 356.130707,2481.357910 
	C350.451416,2482.417725 344.233978,2483.422607 338.516907,2478.227539 
	C345.201294,2473.905762 352.485596,2477.494873 358.749756,2475.331543 
	C365.550568,2472.982666 372.645630,2474.917236 381.361908,2473.202881 
	C373.444427,2471.370117 367.490692,2468.879883 360.948486,2471.681641 
	C359.804749,2472.171387 358.150024,2472.295654 357.017426,2471.854248 
	C346.935242,2467.925293 337.709778,2473.177979 328.142456,2474.451416 
	C314.093781,2476.321533 300.312103,2479.625244 285.832672,2476.361328 
	C276.416138,2474.238525 266.730957,2476.220703 256.852814,2479.887695 
	C256.066467,2477.571045 256.066467,2475.268311 256.066467,2471.842773 
	C244.729874,2473.847412 233.487457,2468.703125 221.945099,2471.851318 
	C220.389740,2472.275391 218.632599,2471.982178 216.968872,2471.982666 
	C196.746536,2471.988770 176.523499,2471.894287 156.302292,2472.046631 
	C150.309235,2472.091797 144.773407,2470.836914 138.232941,2468.626953 
	C141.133530,2465.479248 143.952576,2466.553467 146.289597,2465.975586 
	C162.475876,2461.973145 178.980209,2463.687744 195.342377,2463.362793 
	C203.564957,2463.199463 211.754837,2463.927246 219.925995,2462.160645 
	C226.020309,2460.842773 231.515350,2464.690186 237.131210,2465.112305 
	C242.656097,2465.527588 248.204330,2460.881348 254.445557,2462.101318 
	C261.633331,2463.506348 268.841248,2464.279297 276.201996,2464.117676 
	C283.528076,2463.956543 290.832703,2464.262451 297.764465,2461.198486 
	C299.240021,2460.546143 301.110474,2459.929932 302.580536,2460.262451 
	C311.776123,2462.343262 320.951935,2460.870361 330.137268,2460.960693 
	C340.907196,2461.066895 351.932495,2461.326172 362.582306,2462.643311 
	C376.236267,2464.331787 389.774780,2463.403320 403.341187,2464.073975 
	C411.056305,2464.455566 418.362274,2461.054688 426.970642,2461.885742 
	C429.932343,2460.265137 432.182404,2459.788086 435.457306,2461.272217 
	C437.021881,2461.710693 437.790771,2461.711670 439.136353,2461.706055 
	C441.883392,2460.202393 442.679230,2457.289307 446.069641,2455.971436 
	C451.914978,2454.624756 456.774414,2454.964111 461.795898,2458.607910 
	C464.179779,2462.089355 463.138855,2464.605225 460.687714,2467.528564 
	C459.124634,2468.035400 458.242523,2468.038574 456.698822,2468.050293 
	C453.814270,2469.949707 452.202545,2472.635254 448.540039,2473.672607 
z"
        />
        <path
          fill="#4B6377"
          opacity="1.000000"
          stroke="none"
          d="
M26.966000,2488.089355 
	C25.621439,2486.809814 25.157658,2485.548340 24.197819,2483.794434 
	C26.103622,2478.258301 30.483812,2480.015625 34.181736,2480.579590 
	C47.895973,2482.670410 61.884178,2482.441650 75.463539,2485.836182 
	C80.338966,2487.054932 85.370232,2485.537109 90.082497,2483.751221 
	C95.304390,2481.772217 100.347267,2480.420654 104.349167,2486.045166 
	C105.660805,2487.888428 107.738876,2487.949463 109.746986,2487.953613 
	C118.369164,2487.971436 127.025276,2488.185547 135.002563,2484.030029 
	C135.880096,2483.572754 136.930145,2482.634033 137.659485,2482.827393 
	C148.131195,2485.600586 158.689697,2482.938232 169.174667,2483.525146 
	C171.830475,2483.674316 174.711380,2484.686035 176.977448,2483.261963 
	C185.572433,2477.860596 193.380081,2480.033936 201.488770,2485.692871 
	C200.846481,2492.142334 195.688919,2490.990234 191.918106,2491.816650 
	C184.575195,2493.426758 176.982956,2490.847900 168.915802,2493.271484 
	C163.327209,2492.941650 158.526993,2491.568848 153.455429,2491.922119 
	C149.206589,2492.218750 144.443893,2491.139404 143.457687,2497.390869 
	C143.229324,2498.838379 141.871902,2499.625977 140.509216,2499.656006 
	C135.525162,2499.766602 130.537292,2499.737305 125.551247,2499.704102 
	C124.134293,2499.694580 122.716049,2499.006836 122.263893,2497.781006 
	C119.762901,2491.001709 115.192612,2492.525146 110.056252,2494.136719 
	C103.243279,2496.274414 95.458641,2495.467041 89.638161,2493.260254 
	C82.156471,2490.424316 74.800179,2490.580811 67.587379,2490.332520 
	C54.313354,2489.875732 41.084312,2489.013428 26.966000,2488.089355 
z"
        />
        <path
          fill="#4B6377"
          opacity="1.000000"
          stroke="none"
          d="
M240.716431,2549.401367 
	C231.270737,2550.237061 222.703125,2546.766602 213.745117,2547.917725 
	C209.866913,2548.416016 205.844833,2547.547363 202.033661,2548.988525 
	C200.115219,2549.714111 198.130585,2549.986816 196.342331,2548.834717 
	C187.856628,2543.368652 180.387344,2545.430420 172.709564,2551.867432 
	C171.804367,2547.479492 167.439590,2548.249023 164.479614,2546.158203 
	C169.539536,2540.317627 174.976730,2535.240234 184.202713,2535.777588 
	C194.029709,2536.349121 204.066299,2537.149902 213.854767,2534.209229 
	C216.681763,2533.359863 219.768753,2534.079102 222.633118,2535.198242 
	C225.496475,2536.317383 228.949142,2536.946289 231.398773,2535.596680 
	C240.464905,2530.602539 246.722565,2535.737793 253.613861,2541.299805 
	C252.868149,2549.710693 245.192780,2545.937500 240.716431,2549.401367 
z"
        />
        <path
          fill="#4B6377"
          opacity="1.000000"
          stroke="none"
          d="
M370.546509,2552.957031 
	C369.589417,2553.777344 368.690277,2553.629883 368.308594,2554.006592 
	C359.161591,2563.035889 348.747345,2560.517578 338.161072,2557.364258 
	C339.420990,2552.950684 344.845795,2555.475830 346.768494,2552.209717 
	C345.595551,2549.163086 342.914825,2550.322998 340.794586,2550.084961 
	C326.371826,2548.463867 312.457703,2552.097900 298.407562,2554.280273 
	C290.779266,2555.465332 290.788849,2555.527100 291.911987,2546.862793 
	C293.522430,2543.147705 297.172913,2544.682617 299.780487,2542.312500 
	C303.852966,2536.485596 309.377228,2538.396484 315.117340,2538.061279 
	C326.172424,2543.026611 336.964844,2544.961670 349.203796,2544.107666 
	C351.093323,2545.642578 352.785217,2546.144531 354.621582,2547.448975 
	C356.606262,2548.644287 358.129120,2548.464355 360.334656,2547.773926 
	C365.071259,2547.474854 369.432831,2546.720459 370.546509,2552.957031 
z"
        />
        <path
          fill="#2B3E51"
          opacity="1.000000"
          stroke="none"
          d="
M249.230896,2511.780273 
	C248.396225,2516.030273 244.531128,2517.532227 240.350861,2519.685547 
	C237.900558,2520.268799 235.839691,2520.294189 233.007935,2520.390137 
	C221.355499,2524.529785 210.575592,2520.187988 198.880371,2520.123291 
	C193.253540,2520.055420 188.493271,2519.957764 183.116852,2521.543945 
	C180.284805,2519.921143 180.074509,2517.826904 181.615692,2514.819336 
	C186.740540,2511.512451 191.850098,2512.175293 196.784195,2511.890625 
	C211.035507,2511.068115 225.345612,2512.872559 239.594528,2510.822510 
	C242.431519,2510.414307 245.477203,2511.456543 249.230896,2511.780273 
z"
        />
        <path
          fill="#4B6377"
          opacity="1.000000"
          stroke="none"
          d="
M215.910370,2486.817871 
	C219.298584,2481.132080 223.351456,2480.085449 229.461151,2481.188965 
	C237.665314,2482.670410 246.215057,2482.237793 255.308823,2482.318359 
	C264.297546,2481.520020 272.277100,2482.930664 280.191925,2485.497559 
	C279.412628,2488.918701 277.013916,2487.968750 275.373566,2487.929932 
	C264.393646,2487.669922 253.669235,2491.092529 242.639999,2490.096191 
	C239.890564,2489.847656 237.482483,2491.270264 235.104111,2492.451172 
	C231.934296,2494.025146 228.775970,2494.383789 224.859100,2492.449463 
	C222.229172,2489.074707 217.490814,2491.060791 215.910370,2486.817871 
z"
        />
        <path
          fill="#5D758A"
          opacity="1.000000"
          stroke="none"
          d="
M193.373795,2504.241943 
	C194.181931,2502.686768 195.170029,2501.171875 196.185501,2501.153564 
	C205.961914,2500.974854 215.428360,2495.895020 225.462173,2499.439209 
	C228.670090,2500.572510 232.026459,2500.086426 235.098495,2498.409668 
	C241.303650,2495.022705 247.807510,2495.705811 254.991409,2496.718994 
	C253.585922,2502.006836 249.096176,2502.295654 245.721420,2502.927734 
	C228.466263,2506.159912 211.071274,2507.375244 193.373795,2504.241943 
z"
        />
        <path
          fill="#374D60"
          opacity="1.000000"
          stroke="none"
          d="
M299.438416,2541.806152 
	C299.587158,2545.815186 296.580261,2546.025635 292.670227,2546.069824 
	C290.480438,2546.173828 289.054199,2546.216309 286.914734,2546.307617 
	C285.211853,2546.951416 284.290558,2547.673584 282.584351,2548.101318 
	C268.597321,2547.265869 255.386307,2551.168213 241.046936,2549.881836 
	C240.295135,2547.595215 241.521912,2546.377686 243.712143,2545.794922 
	C246.868317,2544.955811 249.968781,2543.906982 253.570938,2542.390137 
	C254.891068,2541.837891 255.733231,2541.843262 257.207611,2541.841309 
	C267.958191,2536.454346 278.285675,2536.539795 289.571014,2541.036133 
	C293.117859,2541.546387 295.860870,2541.670898 299.438416,2541.806152 
z"
        />
        <path
          fill="#33495B"
          opacity="1.000000"
          stroke="none"
          d="
M215.150757,2486.070801 
	C217.844757,2489.003174 222.712189,2486.762939 224.024658,2491.456787 
	C212.618622,2492.372070 201.124130,2493.862061 189.575684,2494.523926 
	C182.881790,2494.907959 176.466385,2493.203125 169.061890,2493.794434 
	C169.040802,2488.945312 172.879868,2489.998047 175.895676,2490.024170 
	C184.326736,2490.097656 192.731766,2490.214600 201.369049,2486.768799 
	C206.037033,2486.186768 210.153748,2486.107666 215.150757,2486.070801 
z"
        />
        <path
          fill="#4B6377"
          opacity="1.000000"
          stroke="none"
          d="
M290.085510,2540.796631 
	C280.103882,2539.526367 269.922424,2542.687500 258.756042,2541.899414 
	C260.058929,2537.941895 251.745682,2534.884277 256.470551,2531.548828 
	C259.891449,2529.133789 263.836060,2533.170654 267.622101,2533.479736 
	C275.545105,2534.126465 283.330505,2534.851074 290.085510,2540.796631 
z"
        />
        <path
          fill="#5D758A"
          opacity="1.000000"
          stroke="none"
          d="
M274.412811,2509.588135 
	C273.131561,2511.008789 272.292328,2511.976807 271.457825,2512.948730 
	C268.980621,2515.834229 266.326508,2517.530762 264.045807,2512.956299 
	C262.556824,2509.968994 260.076447,2510.099365 256.732971,2509.947754 
	C255.972900,2506.617188 256.824554,2503.746094 260.827362,2504.123047 
	C266.212036,2504.629883 271.067841,2501.921387 277.180450,2501.999268 
	C278.664307,2502.920898 279.305267,2503.842041 280.020935,2505.342041 
	C279.105499,2508.224854 276.404083,2507.740967 274.412811,2509.588135 
z"
        />
        <path
          fill="#4B6377"
          opacity="1.000000"
          stroke="none"
          d="
M198.532379,2520.679199 
	C203.677109,2516.309814 210.041229,2517.764404 215.908981,2518.696045 
	C220.762192,2519.466553 225.567200,2519.911377 231.335480,2520.308594 
	C231.562027,2522.586426 230.183441,2523.793701 227.886826,2523.870605 
	C219.492966,2524.151611 210.899338,2527.714355 202.850174,2522.190186 
	C201.831696,2521.491211 200.327713,2521.499512 198.532379,2520.679199 
z"
        />
        <path
          fill="#4B6377"
          opacity="1.000000"
          stroke="none"
          d="
M181.278351,2514.082520 
	C182.164001,2516.156494 182.166260,2518.154541 182.261963,2520.889648 
	C173.974060,2522.044189 165.564926,2522.913818 155.456284,2520.518066 
	C164.287567,2515.164795 172.036484,2513.432861 181.278351,2514.082520 
z"
        />
        <path
          fill="#748B9F"
          opacity="1.000000"
          stroke="none"
          d="
M96.014648,2521.362793 
	C93.652779,2524.634033 90.503654,2523.679443 86.847603,2523.867920 
	C84.848022,2522.582031 84.588623,2521.090332 85.597046,2518.793457 
	C88.844925,2518.100830 91.594681,2518.041748 95.169571,2517.991211 
	C95.998680,2518.896729 96.002655,2519.793213 96.014648,2521.362793 
z"
        />
        <path
          fill="#5D758A"
          opacity="1.000000"
          stroke="none"
          d="
M273.353241,2492.146484 
	C273.537262,2493.054688 272.989960,2494.027832 272.217957,2495.513184 
	C269.058655,2500.081543 265.188049,2499.402832 259.018646,2498.638428 
	C264.847931,2495.884521 268.734924,2494.048096 273.353241,2492.146484 
z"
        />
        <path
          fill="#50677C"
          opacity="1.000000"
          stroke="none"
          d="
M161.825592,2528.859375 
	C164.562546,2528.036133 166.804092,2527.504883 168.688217,2529.400635 
	C164.876770,2531.817139 164.876770,2531.817139 161.825592,2528.859375 
z"
        />
        <path
          fill="#DAD3C3"
          opacity="1.000000"
          stroke="none"
          d="
M1541.383911,3821.472168 
	C1541.421997,3822.947754 1540.933960,3823.962646 1540.215576,3825.493652 
	C1535.566040,3825.220459 1531.608887,3824.918457 1530.534424,3830.960205 
	C1529.737793,3835.439453 1527.049194,3838.109619 1522.027344,3836.991211 
	C1520.696533,3836.694580 1519.372192,3836.396973 1518.194458,3837.224609 
	C1511.943237,3841.617432 1505.305420,3844.458740 1497.374390,3843.436523 
	C1496.042969,3843.264648 1494.089478,3843.883789 1492.841675,3846.340576 
	C1495.344604,3848.490479 1498.994629,3849.437744 1501.353027,3852.120117 
	C1503.219238,3854.242676 1506.999268,3855.272705 1505.204224,3859.170654 
	C1503.446655,3862.988037 1502.183594,3867.605957 1496.472656,3867.126953 
	C1495.190308,3867.019531 1493.890381,3866.292969 1492.857910,3867.665771 
	C1491.317627,3870.016602 1493.844727,3872.157959 1493.257446,3874.366943 
	C1492.669922,3876.575684 1492.180542,3880.012451 1490.729492,3880.585693 
	C1483.898193,3883.285156 1480.533936,3887.031006 1482.947266,3894.822754 
	C1483.474121,3896.523926 1480.629028,3897.894043 1478.415649,3896.959961 
	C1476.916992,3896.327637 1475.399902,3895.438721 1474.270264,3894.291260 
	C1471.492554,3891.469482 1468.184937,3891.272217 1465.123291,3892.913086 
	C1461.685913,3894.755615 1459.972778,3898.245361 1460.487427,3902.141113 
	C1460.878174,3905.099365 1462.627686,3909.093994 1458.098145,3908.948975 
	C1452.567627,3908.771729 1455.324585,3904.306641 1455.782959,3901.312500 
	C1455.883423,3900.656494 1455.992065,3899.979004 1455.935669,3899.325439 
	C1455.719971,3896.827393 1456.901733,3893.376953 1453.298950,3892.689453 
	C1450.127686,3892.084229 1450.043823,3895.409424 1449.277832,3897.427979 
	C1446.571777,3904.558105 1441.987915,3910.730469 1438.495117,3917.426025 
	C1435.253662,3923.639893 1429.510498,3930.207275 1420.428345,3928.372070 
	C1415.570068,3927.390869 1416.425537,3934.764160 1411.884033,3933.501465 
	C1408.682129,3932.611084 1405.385010,3931.084229 1402.180054,3932.602051 
	C1399.231201,3933.998291 1397.856323,3937.583740 1394.488770,3938.748047 
	C1392.174927,3939.547607 1390.062134,3940.416748 1388.249390,3937.963867 
	C1386.490723,3935.583008 1385.860718,3933.196289 1388.259644,3930.828369 
	C1392.282959,3926.857422 1395.567017,3922.736084 1397.292603,3916.924072 
	C1398.232544,3913.758301 1402.665039,3911.024414 1406.125854,3909.325439 
	C1416.542236,3904.212646 1420.929688,3894.774902 1425.485229,3884.456055 
	C1436.936768,3871.317383 1444.915894,3856.509033 1454.653076,3842.974365 
	C1458.853271,3837.135986 1466.861694,3834.167969 1467.960205,3826.338623 
	C1469.072754,3818.406982 1474.767822,3812.094727 1474.969238,3803.737061 
	C1475.096680,3798.455566 1477.687378,3793.492432 1483.353027,3790.257080 
	C1485.501953,3790.010010 1486.999756,3790.010498 1489.246460,3790.010254 
	C1493.117920,3789.226807 1495.807495,3792.712402 1499.527100,3790.472900 
	C1500.022217,3790.019531 1501.008667,3790.007568 1501.501953,3790.002441 
	C1505.004761,3785.857422 1505.248901,3780.776855 1507.066162,3776.487549 
	C1505.495239,3774.733154 1503.708130,3774.892090 1502.146240,3774.437744 
	C1499.875854,3773.777100 1497.371704,3773.108398 1497.227173,3770.272461 
	C1497.083130,3767.447754 1499.371948,3766.069824 1501.692383,3765.705322 
	C1514.284180,3763.727295 1518.315552,3754.818359 1521.739868,3743.875732 
	C1524.723999,3734.340576 1530.418213,3725.650879 1536.126709,3716.713867 
	C1542.975220,3717.341064 1547.105103,3719.433350 1543.426514,3726.666504 
	C1541.680420,3730.100098 1543.587280,3732.168457 1547.114258,3734.966309 
	C1549.133057,3740.736084 1550.580322,3744.866699 1547.099731,3750.021240 
	C1544.303345,3754.161865 1545.055908,3760.426514 1547.782593,3765.336426 
	C1549.945190,3769.230713 1549.751831,3772.806885 1548.128662,3776.751953 
	C1546.748291,3780.106445 1545.583740,3783.520752 1547.375366,3787.318848 
	C1548.940308,3790.636475 1547.462280,3794.098389 1545.888306,3796.882568 
	C1543.088989,3801.834229 1542.067749,3806.546387 1544.825806,3811.766602 
	C1545.733521,3813.484863 1545.279663,3814.954590 1542.744873,3815.935303 
	C1538.314697,3816.250244 1534.505249,3813.934326 1530.520874,3816.184570 
	C1533.201782,3819.658936 1538.053833,3818.196289 1541.383911,3821.472168 
z"
        />
        <path
          fill="#F0EBDD"
          opacity="1.000000"
          stroke="none"
          d="
M1485.979858,3767.335938 
	C1487.366821,3770.282227 1492.791016,3772.781006 1485.070679,3774.125732 
	C1479.210327,3775.146484 1475.503784,3779.341797 1474.064331,3785.153564 
	C1473.584473,3787.090332 1473.782959,3789.936768 1471.899536,3790.409668 
	C1464.590210,3792.244385 1461.645996,3797.849365 1458.572632,3803.822754 
	C1456.846436,3807.177490 1452.282959,3805.113770 1449.113281,3807.284424 
	C1449.578003,3811.014893 1455.055664,3812.506104 1453.829102,3817.267822 
	C1452.507324,3822.398682 1448.746460,3821.277344 1444.198975,3820.504150 
	C1446.635620,3826.932861 1446.582397,3831.428223 1439.727417,3833.818115 
	C1436.536133,3834.930908 1433.916870,3838.288330 1434.015381,3843.255859 
	C1430.040039,3844.004639 1425.991089,3843.744141 1422.769653,3847.897705 
	C1425.130615,3850.871826 1429.132935,3850.773193 1431.989258,3852.609131 
	C1434.124023,3853.981201 1434.078369,3856.968994 1431.971802,3857.200928 
	C1420.389648,3858.476562 1420.670044,3869.396973 1416.169434,3876.248535 
	C1414.520264,3878.759521 1413.975952,3882.537109 1410.371094,3881.258301 
	C1407.226196,3880.143066 1403.897705,3877.987305 1405.271606,3873.054199 
	C1405.996460,3870.452393 1409.131958,3867.012695 1406.071655,3864.965820 
	C1402.686646,3862.701416 1399.852661,3866.560303 1397.688477,3868.609619 
	C1393.670288,3872.414551 1389.671387,3872.860596 1385.067627,3870.460938 
	C1381.955200,3868.839111 1379.440308,3868.771484 1375.913086,3870.472412 
	C1367.978760,3874.299316 1364.907959,3871.842041 1364.471924,3862.426270 
	C1361.766724,3860.491943 1360.525269,3858.446045 1360.741455,3854.955322 
	C1361.640015,3852.353027 1363.001221,3850.920654 1364.804321,3848.928711 
	C1371.470337,3843.952881 1377.707520,3839.500732 1384.463867,3836.082520 
	C1387.773682,3834.407959 1386.333252,3834.515381 1384.719116,3832.933350 
	C1380.622925,3828.918213 1382.809692,3825.947754 1386.766602,3823.392090 
	C1392.361084,3819.778320 1398.163940,3816.508545 1403.781616,3812.937744 
	C1409.140869,3809.531494 1414.405396,3806.124268 1416.280273,3799.347412 
	C1417.215942,3795.965088 1420.604980,3794.528809 1423.214233,3792.458008 
	C1431.339966,3786.012207 1439.643066,3779.702637 1437.088745,3767.113770 
	C1436.152588,3762.499268 1440.340332,3760.143555 1443.713013,3758.011963 
	C1446.563354,3756.210938 1449.884644,3754.776611 1449.638916,3750.741943 
	C1448.685547,3735.083008 1462.458130,3731.587402 1471.534668,3722.646973 
	C1471.508301,3721.591797 1468.990601,3721.630615 1467.719238,3720.482178 
	C1465.605469,3718.573242 1463.995361,3716.577148 1464.807129,3713.584473 
	C1465.717529,3710.228027 1468.492310,3708.360840 1471.506226,3708.548584 
	C1479.776855,3709.063477 1483.528076,3705.123779 1485.135010,3697.405762 
	C1486.197754,3692.301514 1492.616821,3690.022705 1494.056763,3685.804443 
	C1496.580566,3678.411377 1505.771851,3673.476562 1501.220703,3662.950439 
	C1499.052490,3657.935791 1506.039673,3655.888916 1510.735718,3655.432617 
	C1519.875854,3654.544434 1529.186646,3655.765381 1536.607544,3647.658936 
	C1539.690796,3644.291016 1543.243896,3647.855469 1544.398926,3651.976807 
	C1545.026611,3654.216797 1544.806763,3656.625244 1545.664551,3659.570557 
	C1545.737183,3661.245850 1545.579224,3662.183594 1544.917236,3663.708008 
	C1540.439697,3666.705078 1535.542114,3667.948486 1532.729614,3672.770264 
	C1538.371948,3674.766846 1541.969116,3678.244873 1539.239014,3685.531250 
	C1537.937378,3687.132324 1536.708984,3687.696533 1534.846924,3688.484863 
	C1531.250854,3693.225586 1527.429077,3696.723877 1526.506836,3702.937012 
	C1525.441162,3710.116943 1518.853149,3711.122314 1511.552979,3709.527344 
	C1516.670532,3712.917480 1516.526245,3717.108887 1514.091797,3721.232422 
	C1508.942993,3729.952881 1502.341431,3737.763184 1502.580688,3749.033691 
	C1502.674561,3753.449463 1497.601074,3756.545166 1494.211182,3759.746094 
	C1491.595947,3762.215820 1487.588013,3763.001465 1485.979858,3767.335938 
z"
        />
        <path
          fill="#F0EBDD"
          opacity="1.000000"
          stroke="none"
          d="
M1341.994995,3934.817139 
	C1340.840942,3924.551270 1343.601685,3916.674316 1350.116211,3909.434326 
	C1352.795044,3906.457275 1356.721802,3902.547119 1354.633301,3897.131836 
	C1353.671387,3894.637695 1353.254517,3891.705322 1349.333252,3893.267578 
	C1345.081055,3894.961426 1342.540161,3893.054443 1340.513306,3889.302979 
	C1338.157593,3884.942871 1335.533447,3885.599854 1333.271729,3889.557861 
	C1331.907715,3891.944580 1330.694824,3894.351074 1327.189209,3892.551514 
	C1320.400146,3889.066406 1314.375488,3891.659668 1308.701660,3895.352783 
	C1307.028687,3896.441650 1305.658569,3898.132080 1303.506958,3897.767090 
	C1293.038208,3895.992188 1282.428223,3900.085449 1272.023926,3896.987305 
	C1269.314209,3896.180664 1267.652344,3897.570801 1265.854614,3899.144531 
	C1263.146484,3901.515625 1261.234131,3904.913818 1256.684326,3905.909668 
	C1257.435181,3897.874512 1253.772705,3892.596680 1246.342041,3888.605225 
	C1243.291138,3881.980225 1248.432129,3880.626221 1251.858643,3878.591553 
	C1255.084595,3876.675781 1257.642334,3877.662598 1260.486206,3880.567139 
	C1270.323242,3890.614014 1288.897583,3888.668213 1296.556763,3877.180908 
	C1297.477539,3875.800293 1298.369507,3874.399658 1299.324829,3873.042969 
	C1305.274170,3864.594482 1307.510620,3864.335693 1315.715332,3871.961426 
	C1312.196167,3865.369141 1307.592041,3865.236084 1301.861450,3865.950928 
	C1292.215820,3867.154053 1284.229370,3873.891846 1274.224976,3873.609863 
	C1270.957764,3873.517578 1267.232056,3874.751221 1265.261963,3871.161621 
	C1263.080566,3867.186768 1265.547241,3863.795166 1268.357300,3861.562988 
	C1275.662964,3855.760254 1280.807495,3844.829590 1293.516357,3850.713135 
	C1288.472168,3839.699219 1289.789673,3837.325684 1302.242432,3835.259766 
	C1303.425049,3835.357910 1303.896729,3835.416992 1305.055908,3835.666504 
	C1306.608398,3836.222168 1307.414795,3836.687744 1308.640137,3837.737305 
	C1310.651001,3840.201172 1311.396484,3842.955322 1314.966064,3843.805176 
	C1322.996216,3847.362305 1325.877686,3856.026611 1334.100342,3859.380615 
	C1336.464478,3860.402588 1338.023682,3861.382080 1339.544922,3863.512695 
	C1340.275146,3865.352051 1340.502075,3867.022217 1341.899780,3867.307861 
	C1348.573120,3868.671143 1354.239502,3872.008789 1359.763794,3875.770020 
	C1361.367065,3876.861816 1363.208374,3877.823486 1365.379028,3877.189209 
	C1366.322388,3876.913574 1367.747314,3876.319092 1368.230957,3876.727051 
	C1377.154663,3884.247070 1389.700562,3880.700195 1399.199341,3887.065674 
	C1402.685181,3889.401855 1405.294678,3891.979980 1407.497803,3895.215088 
	C1409.623047,3898.335693 1409.792236,3900.366455 1405.594971,3902.971924 
	C1399.879272,3906.519531 1395.930542,3912.508057 1388.176636,3913.218994 
	C1383.857422,3913.614990 1381.693726,3917.373047 1381.968140,3922.092041 
	C1382.099976,3924.358643 1382.642578,3927.062744 1380.721069,3928.733643 
	C1378.251709,3930.880371 1376.700928,3927.674805 1374.683594,3927.205322 
	C1371.417236,3926.445312 1368.286255,3927.895020 1365.378418,3928.999756 
	C1364.020386,3932.349121 1367.496460,3933.825928 1367.707397,3936.442383 
	C1367.914185,3939.008301 1367.506470,3941.891846 1364.625244,3941.618408 
	C1356.380127,3940.835693 1354.415527,3947.268555 1350.942261,3951.957764 
	C1352.186890,3954.698975 1354.682373,3955.194336 1356.516479,3956.515869 
	C1362.769409,3961.021240 1366.519653,3960.911377 1372.381226,3956.208984 
	C1380.290405,3949.863525 1382.406616,3950.275879 1387.476318,3959.534668 
	C1383.434082,3963.066650 1378.016602,3963.039795 1372.919434,3963.832764 
	C1369.013184,3964.440430 1364.909180,3963.667480 1362.998413,3968.452148 
	C1362.733032,3969.116211 1361.197998,3969.874512 1360.514526,3969.677246 
	C1354.398193,3967.910156 1351.344116,3968.952393 1352.671509,3976.365967 
	C1352.975098,3978.062012 1349.444580,3978.122803 1347.598267,3977.450439 
	C1344.856812,3976.451904 1342.813843,3973.773193 1338.757812,3974.017090 
	C1339.883179,3969.022217 1339.701050,3963.673340 1340.778687,3958.551025 
	C1340.913452,3957.911133 1341.448242,3956.977539 1341.202271,3956.691162 
	C1334.935059,3949.390869 1341.417358,3942.607422 1341.994995,3934.817139 
z"
        />
        <path
          fill="#E5DDC9"
          opacity="1.000000"
          stroke="none"
          d="
M1486.568970,3767.772461 
	C1481.878784,3767.161865 1481.753418,3765.155762 1484.757568,3762.834961 
	C1488.906250,3759.629883 1493.587036,3757.268311 1497.124512,3753.084717 
	C1500.129028,3749.530762 1502.107300,3746.541016 1500.406860,3741.635986 
	C1499.166992,3738.059326 1500.519287,3734.925293 1503.513550,3731.925537 
	C1506.918457,3728.513916 1509.409058,3724.104980 1511.913330,3719.900879 
	C1514.863159,3714.949219 1514.296509,3713.970459 1508.411499,3711.946533 
	C1504.509033,3710.604248 1503.365234,3708.073486 1505.630737,3705.886719 
	C1507.466309,3704.115234 1509.185425,3704.722412 1510.934570,3705.968018 
	C1514.178467,3708.278564 1517.722412,3709.002197 1521.078613,3706.657227 
	C1524.279541,3704.420898 1526.698364,3701.625977 1521.884277,3698.319580 
	C1521.075317,3697.764160 1520.684937,3696.741211 1520.889160,3696.366211 
	C1521.846680,3694.203613 1523.210327,3694.086914 1524.506836,3694.055664 
	C1528.835205,3693.951660 1530.659912,3692.270752 1528.975708,3687.784668 
	C1528.352905,3686.125977 1528.517456,3684.374512 1531.602905,3685.536621 
	C1534.231079,3688.219238 1537.518188,3687.566162 1540.671875,3689.355469 
	C1547.615479,3697.217285 1541.168701,3705.294434 1542.377930,3713.729492 
	C1541.837524,3716.063477 1541.067627,3717.387695 1538.828735,3718.122559 
	C1536.713501,3718.186523 1534.772217,3718.570801 1534.911865,3719.831299 
	C1535.758057,3727.468750 1531.590942,3732.794678 1527.025879,3737.975098 
	C1525.456421,3739.756348 1523.786255,3741.606689 1524.134766,3744.046631 
	C1525.013672,3750.199951 1520.921875,3754.668701 1518.848999,3759.716553 
	C1516.698120,3764.954346 1511.005371,3766.231201 1506.064941,3767.851318 
	C1503.961304,3768.541504 1501.397217,3767.430664 1499.093384,3769.719727 
	C1501.972412,3773.649414 1506.244751,3771.328857 1509.672363,3772.049561 
	C1511.386108,3772.409912 1513.035400,3772.768799 1513.376953,3774.669189 
	C1513.622925,3776.036865 1512.826538,3777.188965 1511.446777,3777.753662 
	C1506.258301,3779.875732 1508.798706,3787.461426 1502.626465,3789.664551 
	C1501.410522,3787.009033 1503.744873,3781.943115 1497.087158,3783.325684 
	C1493.983765,3783.970215 1490.130615,3785.485352 1488.606201,3781.275635 
	C1486.608887,3775.759521 1492.813110,3776.839600 1495.442017,3773.967773 
	C1493.289673,3771.043701 1493.485596,3765.686523 1486.568970,3767.772461 
z"
        />
        <path
          fill="#E5DDC9"
          opacity="1.000000"
          stroke="none"
          d="
M1483.588867,3789.547363 
	C1480.157593,3796.782715 1475.303101,3803.237793 1477.283447,3811.825928 
	C1477.412598,3812.385498 1477.193359,3813.299561 1476.789062,3813.667725 
	C1469.893921,3819.945312 1470.223755,3828.055420 1471.641602,3835.868896 
	C1468.533447,3837.728027 1467.602295,3834.848145 1465.808838,3834.516602 
	C1462.199707,3833.848877 1459.329102,3834.739990 1460.799194,3839.011475 
	C1462.453247,3843.817627 1460.954346,3845.381348 1456.304077,3846.431641 
	C1450.763428,3847.682861 1448.931763,3853.034424 1447.408081,3857.606934 
	C1444.903809,3865.121826 1441.165283,3871.514893 1434.447510,3875.776855 
	C1431.234985,3877.815186 1431.205200,3882.443115 1426.677002,3883.872070 
	C1424.710693,3873.961426 1432.579346,3868.430664 1437.233887,3861.362793 
	C1439.398926,3858.074951 1442.364746,3855.317627 1444.564087,3852.048340 
	C1447.910034,3847.074463 1447.479248,3844.402588 1442.561646,3838.940430 
	C1449.708740,3838.674561 1454.241821,3835.839355 1454.103027,3828.424072 
	C1454.050415,3825.620361 1455.784058,3824.353516 1458.403076,3823.912598 
	C1460.199463,3823.610596 1461.563354,3822.621582 1461.054321,3820.560791 
	C1460.076050,3816.599365 1462.499634,3816.125244 1465.445312,3815.935791 
	C1469.715210,3815.661377 1470.144531,3813.240234 1468.925781,3809.744385 
	C1467.090576,3804.480713 1467.678589,3800.080811 1473.229858,3797.149658 
	C1477.027466,3795.144531 1476.916260,3788.185547 1483.588867,3789.547363 
z"
        />
        <path
          fill="#F0EBDD"
          opacity="1.000000"
          stroke="none"
          d="
M1490.435059,3911.505859 
	C1489.683472,3911.991699 1489.343872,3911.993164 1488.494873,3911.992188 
	C1485.609375,3901.019775 1486.608643,3890.912109 1496.019653,3883.344238 
	C1498.543823,3881.314697 1500.245605,3879.299072 1500.092285,3875.950684 
	C1500.014282,3874.244385 1500.689209,3872.681152 1501.836914,3871.411865 
	C1502.927246,3870.205811 1504.029907,3868.726074 1505.915527,3869.127441 
	C1508.072021,3869.586670 1507.973267,3871.561279 1507.912476,3873.131836 
	C1507.629639,3880.448486 1505.641602,3887.480957 1503.781860,3894.482910 
	C1502.042603,3901.032471 1501.746094,3900.953613 1504.978271,3906.179688 
	C1501.133545,3910.320557 1495.973633,3910.622559 1490.435059,3911.505859 
z"
        />
        <path
          fill="#E5DDC9"
          opacity="1.000000"
          stroke="none"
          d="
M1340.306763,3863.443848 
	C1338.376221,3863.540283 1336.770630,3863.031982 1334.580078,3862.266602 
	C1329.095459,3849.687500 1334.074341,3841.524658 1346.758545,3841.134277 
	C1349.736938,3841.042725 1352.719971,3841.072266 1356.521240,3841.484863 
	C1362.426392,3843.735840 1361.843262,3848.361084 1362.171143,3853.307129 
	C1362.666992,3856.270264 1363.260010,3858.455322 1363.928955,3861.320312 
	C1361.315186,3863.225586 1358.696533,3864.661133 1355.911011,3865.603516 
	C1354.045898,3866.234375 1352.149902,3865.904297 1350.640503,3864.082764 
	C1346.290039,3858.830811 1346.231323,3858.879395 1340.306763,3863.443848 
z"
        />
        <path
          fill="#F0EBDD"
          opacity="1.000000"
          stroke="none"
          d="
M1471.333252,3921.723145 
	C1471.639893,3922.936035 1471.329956,3924.623291 1470.895996,3924.655518 
	C1460.661377,3925.423096 1455.193726,3933.882324 1447.611938,3938.894287 
	C1443.853516,3941.378662 1440.082642,3943.716064 1436.687378,3938.186523 
	C1436.086914,3937.208496 1434.506958,3936.832275 1432.678955,3936.079346 
	C1433.462524,3927.755615 1437.985352,3924.197998 1446.715210,3923.527344 
	C1454.671753,3922.916016 1462.580078,3921.187500 1471.333252,3921.723145 
z"
        />
        <path
          fill="#F0EBDD"
          opacity="1.000000"
          stroke="none"
          d="
M1411.227539,3941.993164 
	C1409.083374,3948.143799 1403.852417,3950.905762 1396.695801,3951.948486 
	C1398.160889,3944.800049 1404.441406,3943.586426 1411.227539,3941.993164 
z"
        />
        <path
          fill="#F0EBDD"
          opacity="1.000000"
          stroke="none"
          d="
M1485.276245,3912.033203 
	C1484.174072,3916.673584 1485.090332,3923.635742 1476.430542,3922.463379 
	C1476.059204,3916.289062 1479.343018,3913.345703 1485.276245,3912.033203 
z"
        />
        <path
          fill="#F0EBDD"
          opacity="1.000000"
          stroke="none"
          d="
M1492.526855,3918.525879 
	C1492.002075,3917.114502 1492.004028,3916.219238 1492.012817,3914.652344 
	C1496.567139,3913.839111 1500.308350,3915.764648 1504.928711,3919.310059 
	C1500.235474,3920.270020 1496.680298,3920.422119 1492.526855,3918.525879 
z"
        />
        <path
          fill="#E5DDC9"
          opacity="1.000000"
          stroke="none"
          d="
M1499.330444,3789.934570 
	C1497.739502,3797.189209 1494.107788,3792.510498 1490.419678,3790.537109 
	C1492.810425,3786.393799 1495.748657,3789.423340 1499.330444,3789.934570 
z"
        />
        <path
          fill="#F0EBDD"
          opacity="1.000000"
          stroke="none"
          d="
M1425.578125,3939.486572 
	C1425.703735,3943.406250 1423.969360,3944.037354 1420.540527,3942.216553 
	C1420.470703,3938.825684 1421.888428,3937.296143 1425.578125,3939.486572 
z"
        />
        <path
          fill="#F0EBDD"
          opacity="1.000000"
          stroke="none"
          d="
M1433.997559,3844.000000 
	C1435.208862,3844.198730 1436.583862,3844.233643 1436.882812,3845.771240 
	C1436.945190,3846.091553 1436.273193,3846.881836 1435.917725,3846.895752 
	C1434.805542,3846.939209 1434.410645,3846.029297 1434.083008,3844.544922 
	C1434.004883,3844.000000 1434.000000,3844.000000 1433.997559,3844.000000 
z"
        />
        <path
          fill="#738EA6"
          opacity="1.000000"
          stroke="none"
          d="
M2815.742188,2256.865723 
	C2813.808105,2256.041016 2812.265625,2255.810547 2811.483643,2254.915527 
	C2809.836914,2253.031006 2810.154053,2250.598389 2812.936523,2251.027344 
	C2823.458496,2252.648682 2834.431885,2251.402344 2845.871338,2255.824219 
	C2835.417236,2261.624023 2825.909912,2258.581055 2815.742188,2256.865723 
z"
        />
        <path
          fill="#69849B"
          opacity="1.000000"
          stroke="none"
          d="
M2804.711914,2284.856934 
	C2803.225342,2285.273193 2802.685303,2284.753662 2802.915771,2283.599121 
	C2802.963623,2283.359375 2803.303955,2283.037354 2803.546387,2283.001221 
	C2804.712891,2282.826416 2805.201172,2283.393799 2804.711914,2284.856934 
z"
        />
        <path
          fill="#E6E1D0"
          opacity="1.000000"
          stroke="none"
          d="
M1956.383301,3657.458740 
	C1954.335571,3660.271973 1951.860474,3659.931641 1948.805420,3660.061523 
	C1944.655151,3657.339111 1943.798584,3653.465576 1943.693726,3648.701660 
	C1943.993164,3648.009766 1944.000000,3648.000000 1944.002441,3647.995117 
	C1945.272461,3646.514160 1945.238892,3644.958984 1943.970459,3642.798828 
	C1942.175537,3639.735840 1940.008057,3637.400146 1941.870850,3633.635254 
	C1942.840088,3632.282227 1943.198486,3631.358887 1942.247559,3629.802002 
	C1935.140137,3613.489502 1943.446045,3594.041504 1959.880615,3588.125000 
	C1964.624023,3586.417236 1964.922974,3582.511475 1967.196411,3577.148193 
	C1962.156372,3582.958252 1959.493530,3580.500977 1957.280762,3576.486084 
	C1954.791504,3578.131348 1956.026123,3580.770020 1955.026855,3582.639648 
	C1953.342651,3585.790771 1950.624756,3586.435059 1947.459839,3585.956299 
	C1943.985107,3585.430176 1941.798462,3583.062012 1942.289917,3579.800049 
	C1942.954956,3575.386475 1941.418335,3571.310303 1941.456055,3566.315674 
	C1941.643066,3564.570068 1941.924194,3563.602051 1942.721436,3561.994141 
	C1952.127808,3551.007812 1963.694702,3552.498291 1976.669678,3555.174316 
	C1978.258057,3559.075928 1977.213501,3561.818848 1975.793335,3564.450195 
	C1974.630371,3566.605469 1974.954590,3568.442383 1975.709106,3570.875244 
	C1978.334717,3579.339355 1974.654663,3587.208496 1972.254150,3595.114746 
	C1972.101807,3595.616211 1970.477417,3595.670898 1968.754272,3595.966797 
	C1965.649048,3598.109619 1963.471680,3600.237549 1967.536621,3603.546387 
	C1972.626587,3606.610107 1972.962158,3609.850342 1969.307861,3613.558350 
	C1967.001099,3615.899414 1964.903442,3618.681152 1962.143433,3620.289551 
	C1957.086914,3623.236084 1958.899414,3628.079590 1959.182007,3631.693115 
	C1959.461670,3635.268799 1964.301025,3635.031494 1967.360962,3635.771729 
	C1969.571167,3636.306885 1971.982544,3635.949951 1974.283203,3636.190918 
	C1976.033936,3636.374268 1977.463501,3637.199951 1977.319702,3639.306885 
	C1977.210571,3640.901855 1975.918335,3641.312256 1974.660278,3641.811523 
	C1968.643066,3644.199951 1968.650879,3644.219727 1968.771240,3651.402588 
	C1973.176636,3642.717285 1979.558838,3649.081055 1984.822510,3647.221191 
	C1980.193237,3652.981934 1978.348755,3662.977539 1966.743530,3658.012939 
	C1963.176270,3655.490234 1960.604614,3650.884521 1956.383301,3657.458740 
z"
        />
        <path
          fill="#EAE6D7"
          opacity="1.000000"
          stroke="none"
          d="
M1940.499756,3739.665039 
	C1937.804932,3740.450439 1933.250122,3739.604004 1935.565918,3743.598633 
	C1937.456665,3746.860352 1938.774902,3747.821777 1934.268921,3749.018311 
	C1930.539795,3750.008545 1930.864990,3752.684570 1931.510254,3756.062744 
	C1932.333008,3760.370117 1933.641235,3765.522217 1926.804199,3767.899414 
	C1921.261475,3765.216553 1916.453857,3769.351074 1910.839722,3768.020996 
	C1911.158081,3763.562744 1906.703247,3762.294434 1904.138550,3759.145996 
	C1911.433594,3756.670166 1915.183228,3751.102051 1919.480225,3746.153809 
	C1922.708008,3742.436768 1921.871948,3739.567139 1917.298218,3738.270508 
	C1916.669067,3738.092285 1915.977661,3738.148193 1915.325073,3738.036133 
	C1905.712402,3736.387451 1905.712769,3736.385254 1906.839844,3725.842285 
	C1909.083984,3724.384766 1909.404785,3726.429688 1910.347656,3727.652100 
	C1913.080322,3731.195312 1915.477539,3729.721191 1917.296753,3726.771973 
	C1920.816895,3721.065186 1919.686890,3718.091309 1913.345703,3717.220703 
	C1910.509888,3716.831299 1910.054565,3715.445557 1910.228882,3713.360107 
	C1910.333740,3712.106689 1910.789185,3710.669189 1911.575684,3709.719238 
	C1918.459229,3701.408936 1918.895996,3692.039795 1916.415649,3682.112305 
	C1916.179688,3681.167969 1916.126221,3680.126465 1916.234253,3679.157715 
	C1916.468384,3677.060059 1917.320190,3675.277832 1919.645508,3674.874023 
	C1921.600708,3674.534668 1922.731689,3675.909912 1923.558350,3677.351562 
	C1924.700073,3679.342529 1926.018433,3681.355713 1925.058472,3683.880371 
	C1924.371216,3685.688477 1924.766602,3687.092529 1927.372314,3687.889893 
	C1932.018677,3688.327637 1936.055054,3688.614014 1938.908936,3693.141846 
	C1940.395508,3696.913330 1939.663696,3699.650146 1937.430664,3702.031738 
	C1936.251465,3703.289795 1935.008423,3704.689209 1936.410767,3706.330566 
	C1942.953857,3713.989258 1937.150757,3718.074707 1930.418457,3722.753662 
	C1938.443237,3723.867432 1944.213745,3726.024170 1942.155029,3735.338867 
	C1941.662354,3737.107178 1941.313232,3738.206055 1940.499756,3739.665039 
z"
        />
        <path
          fill="#D8D1C0"
          opacity="1.000000"
          stroke="none"
          d="
M2002.214233,3598.415527 
	C1995.855103,3595.177979 1995.450073,3589.150879 1994.278931,3584.091553 
	C1993.119629,3579.082764 1991.963623,3573.980469 1991.562378,3568.717529 
	C1991.250977,3564.633545 1989.745361,3560.422363 1986.566406,3556.458740 
	C1984.566284,3554.519043 1983.598145,3552.789795 1983.163574,3549.989990 
	C1982.995850,3547.738525 1982.595581,3546.315186 1981.638428,3544.262207 
	C1979.859619,3539.648926 1978.866577,3535.678223 1980.263428,3530.762207 
	C1995.207520,3520.483643 1993.980225,3504.182861 1996.433105,3489.994629 
	C1998.234619,3479.574707 1999.501831,3477.273682 2010.316650,3481.165771 
	C2012.052490,3483.439697 2012.356934,3485.419189 2012.283203,3488.226074 
	C2011.247559,3492.702637 2010.201416,3496.429688 2011.645020,3501.025879 
	C2011.937622,3502.724121 2011.986084,3503.710938 2011.898438,3505.450684 
	C2011.436279,3508.162109 2010.798340,3510.089844 2010.982666,3512.911133 
	C2011.085693,3517.928955 2010.971680,3522.170410 2010.906006,3527.221191 
	C2007.764282,3538.595459 2010.053467,3549.691406 2007.388184,3561.017822 
	C2006.845093,3562.132812 2006.625854,3562.562500 2006.010742,3563.563477 
	C2003.478027,3565.891846 2002.736450,3567.771729 2005.430420,3570.502686 
	C2007.029053,3574.199951 2006.951538,3577.440430 2006.521240,3581.384033 
	C2006.153198,3584.211182 2004.884888,3586.227783 2005.720947,3589.150879 
	C2006.174316,3591.575928 2006.274170,3593.359375 2005.863037,3595.818115 
	C2005.486328,3596.888672 2005.276123,3597.294922 2004.561035,3598.166016 
	C2003.607544,3598.716553 2003.159058,3598.802002 2002.214233,3598.415527 
z"
        />
        <path
          fill="#959693"
          opacity="1.000000"
          stroke="none"
          d="
M2013.813477,3685.575195 
	C2010.439087,3686.457520 2008.291748,3688.093262 2008.453369,3690.509766 
	C2008.891113,3697.056152 2004.406250,3703.301025 2007.515625,3710.014160 
	C2008.128174,3711.336914 2007.668335,3713.286377 2007.365845,3714.882812 
	C2003.868774,3733.347656 2004.185669,3752.018311 2003.664429,3770.693604 
	C2003.386230,3780.663818 2001.756836,3790.932373 1995.538818,3800.130859 
	C1992.845703,3784.829834 1993.576660,3769.124512 1994.591553,3753.604004 
	C1995.499634,3739.718750 1995.288696,3725.845703 1996.042603,3711.985352 
	C1997.106445,3692.425293 1997.974731,3672.854492 1999.375000,3652.591064 
	C2007.652344,3650.105713 2006.023071,3656.418457 2006.798340,3660.325195 
	C2007.429443,3663.503906 2006.543091,3667.631592 2012.876953,3665.544922 
	C2014.069580,3665.972168 2014.499023,3666.297363 2015.398438,3667.315918 
	C2017.822510,3673.920898 2018.381714,3679.735596 2013.813477,3685.575195 
z"
        />
        <path
          fill="#E6E1D0"
          opacity="1.000000"
          stroke="none"
          d="
M1942.651611,3736.116943 
	C1940.651123,3734.873535 1940.019775,3733.389648 1940.026733,3731.649170 
	C1940.046875,3726.534668 1937.321289,3725.222412 1932.900269,3726.691650 
	C1929.320557,3727.881104 1926.998535,3726.992920 1926.320312,3723.182129 
	C1925.733032,3719.882568 1927.376953,3718.081055 1930.672729,3718.074463 
	C1934.193481,3718.067139 1936.473511,3716.345703 1937.140137,3713.091064 
	C1937.955688,3709.110596 1934.269287,3708.434570 1931.871094,3706.898926 
	C1930.785522,3706.203613 1929.503784,3705.713623 1929.049072,3703.426514 
	C1931.896484,3701.124023 1938.607178,3702.017822 1937.982422,3694.757324 
	C1942.936157,3691.423340 1944.461914,3695.707520 1946.262573,3698.484131 
	C1949.984009,3704.222412 1950.764893,3710.793213 1950.013428,3717.774658 
	C1953.283325,3716.924561 1955.462524,3713.919678 1959.386108,3715.795410 
	C1963.327881,3718.033936 1960.642578,3722.488281 1963.462158,3724.775146 
	C1966.288330,3724.458496 1964.905640,3722.418213 1965.122559,3721.061279 
	C1967.059692,3708.946045 1971.734253,3697.660156 1975.676270,3686.150146 
	C1976.490356,3683.773193 1977.395630,3681.614746 1980.494263,3682.631592 
	C1982.930786,3683.431152 1982.613159,3685.344238 1982.491577,3687.494873 
	C1982.266113,3691.481689 1984.408325,3696.043213 1978.544556,3698.041016 
	C1976.543945,3698.722656 1977.114014,3700.433594 1978.033325,3702.126465 
	C1981.087158,3707.749023 1978.635742,3713.365479 1978.419067,3719.104248 
	C1978.025635,3729.524414 1967.523682,3730.145020 1964.217285,3737.139648 
	C1963.961060,3737.681396 1962.230347,3738.046631 1961.478638,3737.750000 
	C1956.600342,3735.823486 1952.057373,3736.176025 1947.396606,3738.635254 
	C1946.657959,3739.025391 1944.690918,3737.087158 1942.651611,3736.116943 
z"
        />
        <path
          fill="#EAE5D5"
          opacity="1.000000"
          stroke="none"
          d="
M1959.926392,3715.163330 
	C1958.418579,3718.395752 1953.323486,3716.349854 1953.440430,3721.467773 
	C1953.489868,3723.631592 1950.818726,3724.087646 1948.751587,3723.521729 
	C1946.384277,3722.874023 1945.953369,3720.866455 1946.291016,3718.853027 
	C1946.874634,3715.372314 1948.442749,3712.169922 1947.230957,3708.338135 
	C1945.558228,3703.049072 1943.713623,3698.023438 1938.697510,3694.141602 
	C1934.947144,3692.329590 1931.894287,3690.659180 1928.420654,3688.494141 
	C1930.536011,3686.428223 1929.416992,3682.995850 1931.271729,3681.112061 
	C1932.059326,3681.365723 1932.850952,3681.375977 1933.196655,3681.773682 
	C1934.891479,3683.724365 1934.790283,3688.752197 1938.969971,3686.409668 
	C1941.972900,3684.727051 1943.431396,3679.939941 1940.512939,3677.802734 
	C1934.814575,3673.629883 1933.702637,3665.811035 1926.619873,3662.290039 
	C1925.239258,3661.216309 1924.943359,3660.288818 1925.558472,3658.608887 
	C1930.757324,3653.411621 1937.593628,3652.363525 1943.284180,3648.098145 
	C1946.073975,3651.253418 1948.775024,3654.260254 1948.057373,3659.333008 
	C1948.076782,3660.946289 1948.067627,3661.809814 1948.059570,3663.321289 
	C1950.437012,3670.290527 1949.195679,3677.929199 1955.473145,3683.509766 
	C1955.954346,3684.898682 1955.957397,3685.780029 1955.954834,3687.322754 
	C1952.937866,3691.537598 1955.700073,3694.095459 1957.728271,3697.411621 
	C1958.123535,3703.508301 1957.405029,3709.112061 1959.926392,3715.163330 
z"
        />
        <path
          fill="#D8D1C0"
          opacity="1.000000"
          stroke="none"
          d="
M2001.008423,3628.257812 
	C1999.668579,3624.491943 1997.583008,3623.998779 1994.599243,3625.752197 
	C1990.804321,3627.981934 1987.755127,3627.861572 1985.622803,3623.459473 
	C1984.711914,3621.579102 1982.862061,3620.856445 1980.947754,3620.052734 
	C1976.671021,3618.256104 1976.016113,3611.540039 1979.963989,3611.049561 
	C1987.684326,3610.089600 1988.441406,3605.253174 1988.603394,3598.562500 
	C1993.922607,3600.941650 1994.951294,3605.248779 1995.751953,3609.155273 
	C1996.557495,3613.086182 1998.070435,3615.998047 2002.392822,3617.821777 
	C2005.061523,3618.304443 2005.446777,3619.867432 2005.683838,3622.336426 
	C2005.141113,3625.425049 2003.945435,3627.171875 2001.008423,3628.257812 
z"
        />
        <path
          fill="#A6A29C"
          opacity="1.000000"
          stroke="none"
          d="
M2000.200928,3629.185059 
	C2001.121826,3627.056885 2002.054077,3625.821533 2003.480835,3624.280273 
	C2007.128662,3625.458740 2008.274536,3628.484619 2009.908691,3632.093750 
	C2011.669922,3643.795410 2014.536743,3654.628662 2012.495361,3666.418457 
	C2011.076660,3669.578125 2009.927002,3672.097168 2006.613525,3671.439697 
	C2003.577881,3670.837402 2004.364868,3668.029053 2003.980225,3665.887207 
	C2003.166870,3661.358398 2006.165405,3656.089111 2000.603760,3652.110596 
	C1999.953125,3644.621826 2000.082764,3637.349609 2000.200928,3629.185059 
z"
        />
        <path
          fill="#E6E1D0"
          opacity="1.000000"
          stroke="none"
          d="
M1956.100586,3683.340332 
	C1947.089233,3679.984375 1946.757202,3679.517090 1947.394409,3670.513916 
	C1947.531128,3668.581543 1945.813477,3666.773926 1947.586426,3664.391113 
	C1950.823364,3663.981445 1953.586060,3663.993164 1957.178955,3664.007324 
	C1960.233643,3664.165039 1962.008545,3665.090576 1963.590454,3667.511230 
	C1968.558594,3673.906250 1966.922729,3680.140625 1965.076538,3686.390869 
	C1961.662842,3687.241455 1962.510864,3684.357178 1961.744629,3682.903076 
	C1960.071655,3679.727539 1958.229492,3679.340820 1956.100586,3683.340332 
z"
        />
        <path
          fill="#959693"
          opacity="1.000000"
          stroke="none"
          d="
M2004.075439,3584.628906 
	C2004.065918,3579.929932 2004.029419,3575.831299 2003.917603,3570.857910 
	C2002.808594,3568.568604 2002.509277,3567.029541 2003.520630,3564.669678 
	C2003.986694,3563.992432 2004.261841,3564.169189 2004.423584,3564.193848 
	C2005.423584,3563.307861 2006.461304,3562.849854 2008.551025,3563.066895 
	C2014.291260,3567.202148 2013.200439,3572.806152 2013.346680,3577.946777 
	C2013.525024,3584.210205 2013.560181,3590.483887 2013.724854,3597.571045 
	C2014.313477,3600.006592 2014.368530,3601.647705 2014.088135,3604.088623 
	C2011.750122,3610.630371 2008.548584,3612.359619 2003.213379,3606.820801 
	C2002.471680,3604.018311 2002.211060,3601.811523 2001.834229,3598.773926 
	C2002.058960,3597.905029 2002.400024,3597.866943 2003.279175,3597.753418 
	C2003.798950,3595.639160 2003.780518,3593.600342 2003.817383,3590.797607 
	C2003.988037,3588.434326 2003.998047,3586.831055 2004.075439,3584.628906 
z"
        />
        <path
          fill="#817A79"
          opacity="1.000000"
          stroke="none"
          d="
M2002.599121,3606.955566 
	C2004.773315,3605.979004 2006.472534,3606.375244 2008.091675,3607.914307 
	C2009.534912,3609.286377 2010.149658,3607.240479 2011.516724,3606.265137 
	C2015.279907,3607.925781 2014.354004,3612.280518 2017.247803,3615.357178 
	C2018.027588,3616.970947 2018.329712,3617.955322 2018.639893,3619.757324 
	C2019.105957,3623.273438 2018.821411,3625.932129 2017.465942,3629.243164 
	C2015.084351,3632.260254 2012.536987,3633.001709 2008.846313,3632.317383 
	C2005.756348,3630.344727 2005.134155,3627.743896 2004.133057,3624.621582 
	C2003.835571,3622.456787 2003.695801,3620.939453 2003.297974,3618.703125 
	C2004.489868,3614.460205 2002.047363,3611.152832 2002.599121,3606.955566 
z"
        />
        <path
          fill="#EAE5D5"
          opacity="1.000000"
          stroke="none"
          d="
M1956.841919,3658.024414 
	C1954.005615,3654.270020 1953.934326,3651.154053 1959.005859,3650.211914 
	C1963.843628,3649.312988 1966.382324,3651.281738 1965.993286,3657.206543 
	C1963.224731,3658.006348 1960.449463,3658.012695 1956.841919,3658.024414 
z"
        />
        <path
          fill="#E6E1D0"
          opacity="1.000000"
          stroke="none"
          d="
M1958.483887,3697.551025 
	C1952.331665,3698.594727 1951.390381,3694.529785 1950.938965,3690.465332 
	C1950.693237,3688.251953 1952.940186,3688.281494 1955.247803,3688.007812 
	C1958.350464,3690.560791 1961.631714,3692.844482 1958.483887,3697.551025 
z"
        />
        <path
          fill="#E6E1D0"
          opacity="1.000000"
          stroke="none"
          d="
M1985.343994,3547.469727 
	C1985.971680,3550.169189 1985.985474,3552.289551 1986.004517,3555.205078 
	C1983.873657,3556.000000 1981.737671,3556.000000 1978.800781,3556.000000 
	C1978.000000,3556.000000 1977.992920,3555.994141 1977.984253,3555.987305 
	C1975.134644,3552.256836 1974.757446,3548.674316 1979.405518,3545.085449 
	C1982.110962,3544.680664 1983.402100,3545.826172 1985.343994,3547.469727 
z"
        />
        <path
          fill="#EAE5D5"
          opacity="1.000000"
          stroke="none"
          d="
M1967.998779,3603.200195 
	C1967.663696,3603.998291 1967.329468,3604.024414 1967.001465,3603.989746 
	C1964.511108,3603.727051 1960.971680,3604.783936 1960.557495,3601.121094 
	C1960.127563,3597.320068 1963.175049,3596.223877 1967.218994,3596.019775 
	C1967.981201,3598.133301 1967.991821,3600.266846 1967.998779,3603.200195 
z"
        />
        <path
          fill="#959693"
          opacity="1.000000"
          stroke="none"
          d="
M1994.284912,3804.597168 
	C1996.109985,3804.176270 1998.265137,3805.222168 1997.592529,3806.652588 
	C1995.879028,3810.296875 2000.288696,3814.975098 1994.841675,3817.995850 
	C1994.142456,3813.905029 1994.091553,3809.690186 1994.284912,3804.597168 
z"
        />
        <path
          fill="#EAE5D5"
          opacity="1.000000"
          stroke="none"
          d="
M1964.248291,3667.398438 
	C1961.722412,3667.931641 1959.866943,3667.093018 1958.305054,3664.574951 
	C1959.649536,3661.914307 1961.829834,3661.539551 1964.052124,3662.625488 
	C1966.057251,3663.605469 1965.111572,3665.319336 1964.248291,3667.398438 
z"
        />
        <path
          fill="#D8D1C0"
          opacity="1.000000"
          stroke="none"
          d="
M1605.627441,3909.536133 
	C1602.181763,3916.333252 1609.834229,3920.650146 1608.856323,3927.029297 
	C1602.838013,3927.973145 1596.699707,3929.277100 1593.129761,3935.112061 
	C1590.749634,3939.001953 1592.656860,3942.733154 1593.885742,3947.229004 
	C1591.780640,3948.598145 1589.575806,3949.206055 1586.685547,3949.897461 
	C1581.144775,3951.944092 1577.137451,3951.912354 1575.637451,3944.903320 
	C1575.450439,3943.179688 1575.507935,3942.197754 1575.860352,3940.524902 
	C1582.346069,3933.292969 1583.186768,3923.821289 1588.489868,3916.713623 
	C1590.047852,3914.625977 1588.604004,3913.089111 1585.710449,3912.131836 
	C1580.013428,3908.955811 1579.294434,3905.002686 1582.132812,3900.094971 
	C1585.603882,3894.093750 1589.529175,3888.409424 1593.413330,3882.689697 
	C1600.821167,3871.780518 1608.146240,3860.045410 1607.648926,3845.973145 
	C1607.365967,3837.964600 1611.635132,3831.872070 1615.023193,3825.650146 
	C1620.314087,3815.933594 1625.709229,3806.239258 1634.607300,3798.768311 
	C1644.907104,3790.120361 1651.276978,3778.499268 1653.821411,3765.200439 
	C1654.667969,3760.774902 1656.191772,3756.991943 1658.830322,3753.470459 
	C1660.542480,3751.185059 1661.165527,3748.671631 1659.225586,3746.142334 
	C1658.570312,3745.287842 1657.558105,3745.063232 1656.531494,3745.252197 
	C1653.406494,3745.827637 1653.467407,3748.312012 1653.406982,3750.658936 
	C1653.289551,3755.211426 1650.645020,3758.781006 1648.770752,3762.638916 
	C1647.167603,3765.938721 1644.532104,3769.417725 1645.464478,3772.912354 
	C1649.806641,3789.189209 1635.841919,3792.989990 1627.050903,3799.697998 
	C1620.756714,3804.500488 1612.777344,3799.787842 1611.722778,3791.809082 
	C1611.458984,3789.813965 1611.498413,3787.755127 1612.127075,3785.869629 
	C1615.533447,3775.656738 1616.592285,3765.196533 1615.925781,3753.809814 
	C1616.336426,3752.681152 1616.545044,3752.247314 1617.182617,3751.235840 
	C1627.505249,3741.599121 1631.418701,3728.980713 1637.063477,3717.453613 
	C1639.936035,3711.587891 1642.199585,3705.773438 1649.830444,3705.087646 
	C1653.357788,3704.770508 1654.053223,3702.629639 1653.525391,3699.477295 
	C1651.293457,3686.147217 1662.095337,3680.275391 1669.257935,3672.370605 
	C1670.748291,3670.725586 1672.725220,3670.976562 1674.707031,3672.140137 
	C1681.825073,3676.318848 1684.752075,3690.054688 1679.015503,3696.053467 
	C1675.654785,3699.567871 1673.258911,3702.147949 1676.337646,3706.920166 
	C1677.958862,3709.433350 1676.786011,3712.023682 1675.558350,3714.488281 
	C1674.842773,3715.924561 1673.652588,3718.037109 1675.539062,3718.954590 
	C1686.276001,3724.173340 1680.640991,3732.411377 1678.355835,3738.683350 
	C1669.630249,3762.632812 1660.755493,3786.539795 1652.631226,3810.690430 
	C1648.332886,3823.467285 1641.040527,3834.560791 1635.090576,3846.374756 
	C1625.112793,3866.185547 1615.784180,3886.392090 1603.526733,3904.996338 
	C1602.278076,3906.891846 1603.885742,3907.974365 1605.627441,3909.536133 
z"
        />
        <path
          fill="#E6E1D0"
          opacity="1.000000"
          stroke="none"
          d="
M1577.560913,3943.565430 
	C1577.043091,3950.410156 1581.734741,3948.856445 1585.588379,3949.525879 
	C1582.775024,3954.966064 1579.380005,3959.804932 1577.937622,3965.770264 
	C1576.617554,3971.230225 1572.032104,3973.143311 1566.554565,3974.978516 
	C1566.050537,3967.547607 1569.945068,3962.172119 1572.653564,3956.525146 
	C1573.857544,3954.014893 1577.406006,3952.041504 1574.333252,3948.979492 
	C1571.893433,3946.548096 1562.996216,3948.673340 1563.224365,3951.683594 
	C1563.930664,3960.998291 1557.297607,3959.346191 1552.149902,3960.291748 
	C1551.194946,3960.467041 1550.307495,3961.010010 1548.701782,3961.691895 
	C1547.368408,3961.029297 1546.722168,3960.058350 1546.002686,3958.484863 
	C1546.244507,3948.778320 1552.134155,3941.859863 1557.667725,3936.362305 
	C1566.447388,3927.639160 1576.518921,3920.137695 1585.449463,3910.410889 
	C1588.529297,3910.335693 1591.773438,3909.002930 1593.135986,3911.962402 
	C1594.556641,3915.048096 1592.592407,3918.069336 1590.467651,3919.844727 
	C1587.100220,3922.657959 1585.779663,3925.773193 1585.848999,3930.040771 
	C1585.923950,3934.652344 1582.095093,3936.629395 1578.455078,3939.447754 
	C1576.961304,3940.828369 1576.666138,3941.877930 1577.560913,3943.565430 
z"
        />
        <path
          fill="#E6E1D0"
          opacity="1.000000"
          stroke="none"
          d="
M1635.615601,3942.408691 
	C1640.181274,3944.484375 1640.685547,3947.239502 1638.173706,3950.634277 
	C1636.001221,3953.569580 1633.829712,3956.508301 1631.563721,3959.371338 
	C1625.851196,3966.588135 1625.664917,3966.744385 1618.842773,3960.219238 
	C1614.879150,3956.428467 1609.703247,3959.408447 1605.092041,3956.762939 
	C1606.554321,3951.623535 1611.801514,3952.381348 1615.126099,3950.488770 
	C1618.592285,3948.516113 1622.115112,3946.304443 1624.897461,3952.128174 
	C1626.054565,3954.550293 1628.468140,3952.526855 1629.279175,3950.630859 
	C1630.632812,3947.466553 1629.812744,3942.941895 1635.615601,3942.408691 
z"
        />
        <path
          fill="#EAE6D7"
          opacity="1.000000"
          stroke="none"
          d="
M1690.676270,3920.000000 
	C1687.811646,3918.110107 1688.722168,3916.690674 1690.847046,3915.809814 
	C1693.820801,3914.576904 1693.818481,3916.840088 1694.000732,3919.378418 
	C1693.120728,3919.993652 1692.231812,3919.996826 1690.676270,3920.000000 
z"
        />
        <path
          fill="#F0EBDD"
          opacity="1.000000"
          stroke="none"
          d="
M1337.245117,3974.016846 
	C1337.374146,3980.087402 1333.796875,3984.895264 1329.855103,3990.780518 
	C1338.744141,3991.631836 1344.988159,3997.056396 1352.858521,3998.279541 
	C1355.175781,3998.639648 1356.822021,4001.259766 1354.794189,4002.466064 
	C1347.290771,4006.929443 1343.459595,4015.360596 1335.661499,4019.418213 
	C1333.569702,4020.506592 1331.860840,4022.578613 1329.682251,4020.017090 
	C1327.849609,4017.861816 1329.994141,4016.618164 1330.997803,4014.965088 
	C1336.058228,4006.628418 1335.206665,4003.003418 1327.230347,3997.644775 
	C1320.757446,3993.296143 1320.665771,3993.174805 1324.901123,3985.870605 
	C1327.741089,3980.972412 1328.381836,3973.811279 1337.245117,3974.016846 
z"
        />
        <path
          fill="#F0EBDD"
          opacity="1.000000"
          stroke="none"
          d="
M1344.788330,3989.941406 
	C1344.619507,3985.490234 1346.957275,3984.054688 1350.278442,3984.209229 
	C1353.614258,3984.364258 1356.762207,3985.567139 1358.589355,3988.505859 
	C1359.701416,3990.293945 1358.349243,3991.686035 1356.464355,3991.749023 
	C1352.645264,3991.876465 1348.687378,3992.976318 1344.788330,3989.941406 
z"
        />
        <path
          fill="#F0EBDD"
          opacity="1.000000"
          stroke="none"
          d="
M1258.089233,3942.889648 
	C1259.443359,3943.632812 1259.461792,3944.381104 1258.492432,3945.041748 
	C1258.289795,3945.179932 1257.819824,3945.174316 1257.622314,3945.030762 
	C1256.677002,3944.341797 1256.712402,3943.594727 1258.089233,3942.889648 
z"
        />
        <path
          fill="#3C5163"
          opacity="1.000000"
          stroke="none"
          d="
M164.867737,2955.866699 
	C163.677490,2952.880127 165.031448,2950.311768 165.847794,2946.900146 
	C170.351349,2941.465576 176.535767,2942.262939 182.931778,2941.059326 
	C189.625885,2940.861572 195.938324,2941.882080 199.034439,2934.816162 
	C199.376251,2934.036133 200.628082,2933.535156 201.580597,2933.365479 
	C217.451187,2930.536133 230.963943,2921.377930 246.178101,2916.790039 
	C251.858582,2915.077148 257.441345,2916.024658 262.892303,2917.475830 
	C271.180420,2919.682129 278.912140,2918.513672 287.282104,2916.670410 
	C312.505005,2911.115967 337.960358,2906.568115 364.085724,2909.821045 
	C376.341431,2911.347168 388.525055,2907.695068 400.851227,2908.573975 
	C406.467926,2908.974121 412.142395,2908.733398 417.764313,2909.269043 
	C422.056946,2909.677979 425.702728,2910.985840 425.002869,2917.246582 
	C424.283325,2918.475342 423.870117,2918.889893 422.679352,2919.724121 
	C411.361237,2920.971924 400.977020,2923.561768 389.444916,2922.972168 
	C369.271271,2926.177002 349.562195,2923.485840 330.263763,2926.947266 
	C328.333405,2927.293701 326.159119,2926.594238 324.343658,2927.318359 
	C309.529388,2933.226318 293.982574,2933.160889 277.704559,2933.458740 
	C262.583984,2935.697510 247.748062,2935.060547 234.068024,2941.153320 
	C231.468353,2942.311035 227.345779,2941.551758 226.677826,2945.088867 
	C226.051346,2948.406494 230.073105,2948.551025 232.021667,2950.099365 
	C235.913544,2953.192627 236.175308,2955.909180 230.721405,2958.134766 
	C228.853363,2956.320801 227.116608,2956.117432 224.654205,2956.053223 
	C222.241791,2955.966064 220.463577,2956.031250 218.474670,2954.461670 
	C200.554596,2953.359131 183.281067,2957.356201 164.867737,2955.866699 
z"
        />
        <path
          fill="#2F4457"
          opacity="1.000000"
          stroke="none"
          d="
M256.757568,2975.899170 
	C248.024368,2978.240967 240.138077,2980.905029 231.619797,2980.004150 
	C228.989929,2979.726074 226.233765,2980.190430 223.832993,2978.779297 
	C220.379028,2976.749023 217.311996,2976.397949 213.876221,2979.210449 
	C212.597183,2980.257080 210.043335,2979.997559 208.086456,2979.923340 
	C196.176178,2979.472900 184.153442,2981.409180 172.359695,2978.211670 
	C170.801193,2977.789307 169.022507,2977.868408 167.391266,2978.074707 
	C156.221222,2979.486816 145.096039,2980.770752 133.886139,2978.080811 
	C131.027802,2977.394775 127.976273,2978.177490 125.105850,2979.310303 
	C113.868797,2983.745361 102.197304,2981.888184 90.637733,2981.456543 
	C90.232193,2981.441406 89.858246,2980.579590 89.085556,2979.651367 
	C91.923775,2977.221680 95.278778,2976.091064 98.825859,2976.096924 
	C106.882957,2976.110352 114.527115,2973.919189 122.332161,2972.444092 
	C128.709824,2971.238525 134.966003,2970.477295 141.849411,2971.474365 
	C152.498322,2973.017578 163.535431,2971.881592 174.441605,2970.919189 
	C169.470673,2966.410889 163.840225,2967.988281 158.380981,2968.921143 
	C152.398361,2969.944092 152.421310,2970.078369 146.249619,2965.030762 
	C154.182541,2963.945557 161.159149,2962.752686 168.185791,2962.091553 
	C176.420395,2961.316650 184.806335,2963.213135 192.976242,2960.822998 
	C193.287720,2960.731934 193.703979,2960.640869 193.959885,2960.770508 
	C205.846359,2966.776367 218.125015,2961.026367 231.077927,2961.982422 
	C242.027023,2964.278564 251.861008,2962.496582 261.970520,2961.862305 
	C281.585663,2960.631104 301.457428,2962.823242 320.750183,2970.586914 
	C321.518433,2972.496094 321.549042,2973.657715 320.897400,2975.624756 
	C314.628326,2980.736816 308.102844,2978.965088 300.866943,2978.167480 
	C291.280182,2975.693115 282.124603,2975.402832 272.806854,2972.159424 
	C267.962067,2976.296875 262.246674,2974.133789 256.757568,2975.899170 
z"
        />
        <path
          fill="#33495B"
          opacity="1.000000"
          stroke="none"
          d="
M364.627716,3013.658691 
	C357.723724,3010.281982 351.749481,3012.531250 346.085022,3015.380615 
	C343.368500,3016.747314 340.883667,3018.045166 337.759369,3018.027344 
	C320.533539,3017.928955 303.657440,3020.936035 286.757690,3023.793457 
	C283.848999,3024.285156 280.803284,3023.907959 277.831512,3024.097656 
	C272.517426,3024.437500 272.520813,3024.490479 275.893036,3031.316650 
	C264.875397,3033.778320 254.621033,3031.785156 245.360458,3025.065674 
	C243.094177,3023.421387 240.204849,3023.923584 237.577637,3024.030273 
	C221.361832,3024.688721 205.028961,3022.237061 188.915161,3025.902100 
	C187.018372,3026.333740 184.934998,3026.032959 182.944565,3025.937256 
	C181.370514,3025.861816 179.203888,3025.994141 179.334427,3023.741211 
	C179.445831,3021.819336 181.987976,3022.094727 183.574463,3020.447510 
	C184.878540,3019.914795 185.750549,3019.892334 187.275635,3019.827393 
	C189.268356,3018.541504 190.880951,3018.015381 193.328430,3017.934570 
	C195.459763,3017.856689 196.941620,3017.820068 199.162750,3017.728027 
	C200.831543,3017.031738 201.732513,3016.337402 203.432373,3016.030762 
	C215.539078,3014.505859 226.918671,3017.593750 238.528625,3017.312744 
	C244.517044,3017.167725 251.124481,3020.429199 257.821259,3020.010010 
	C259.144897,3019.927246 260.718994,3020.396240 261.764435,3019.837402 
	C278.295502,3011.003418 297.486115,3014.921143 315.750031,3008.352051 
	C309.527893,3004.976807 303.428711,3005.103516 296.828247,3004.020508 
	C294.807861,3002.569336 294.366486,3000.949707 295.145050,2998.343262 
	C301.871399,2992.889893 308.613495,2996.435547 315.986816,2996.753418 
	C320.864288,2996.867920 325.147552,2998.110107 329.451141,2994.623047 
	C331.686462,2993.628418 333.276184,2993.304199 335.746460,2993.204590 
	C349.089722,2997.544189 362.608643,2997.813232 375.499634,3003.305664 
	C375.798279,3006.765381 374.696625,3009.004639 371.832489,3009.689697 
	C369.247131,3010.308350 367.261719,3011.814209 364.627716,3013.658691 
z"
        />
        <path
          fill="#233444"
          opacity="1.000000"
          stroke="none"
          d="
M320.646484,2972.576660 
	C315.876099,2968.090332 310.455780,2970.422363 305.686401,2969.096924 
	C293.195801,2965.625488 280.053802,2966.261475 267.304169,2964.117920 
	C264.119232,2963.582520 260.582092,2963.456787 257.645966,2965.603271 
	C252.235580,2969.558350 246.059433,2967.540283 240.194412,2967.884521 
	C236.522812,2968.099854 235.697342,2964.286865 232.626389,2962.341797 
	C231.465820,2960.977783 230.950073,2959.985107 230.225677,2958.470703 
	C232.194305,2956.166504 236.412933,2953.250488 230.419113,2952.457764 
	C222.897385,2951.463379 221.232941,2947.553711 221.025986,2941.079346 
	C238.729324,2937.491699 255.466080,2929.328857 275.039978,2931.926514 
	C276.975769,2932.156494 277.822327,2932.669434 278.774994,2934.141602 
	C278.387787,2941.565186 272.862366,2941.826416 268.127411,2942.595459 
	C261.919373,2943.603760 255.588638,2943.018311 248.977722,2943.285400 
	C255.737518,2945.840820 262.947266,2943.758301 270.013489,2946.823730 
	C277.517426,2955.384766 286.731995,2957.052246 296.724701,2956.660156 
	C300.997040,2956.492432 305.270752,2955.118164 310.288666,2956.977539 
	C315.573578,2962.201172 321.578705,2960.396484 326.981293,2960.512207 
	C336.367889,2960.713867 345.550751,2961.510742 354.073853,2965.753662 
	C357.362671,2967.390869 360.801453,2969.504639 360.689514,2973.709717 
	C360.561310,2978.524658 356.059814,2979.100098 352.727997,2979.797852 
	C341.367157,2982.177246 330.233704,2982.569580 320.646484,2972.576660 
z"
        />
        <path
          fill="#2B3E51"
          opacity="1.000000"
          stroke="none"
          d="
M295.692413,2997.437500 
	C296.018341,2999.554199 296.008240,3001.036133 295.997253,3003.259033 
	C280.654175,2997.626221 264.501343,2997.777832 248.320847,2997.978271 
	C244.385559,2998.026855 240.343765,2998.638672 236.531448,2997.086914 
	C234.703308,2996.343018 232.625031,2995.513428 232.538803,2993.167725 
	C232.444626,2990.606201 234.718628,2990.253174 236.559296,2989.562256 
	C244.024673,2986.760986 251.966553,2984.782471 259.663940,2984.511963 
	C271.881683,2984.082764 283.884552,2980.969971 297.071533,2981.979736 
	C303.332367,2979.215088 308.571045,2976.929688 311.303558,2985.944824 
	C305.502441,2993.489502 297.367706,2991.896484 289.356842,2993.118164 
	C288.174011,2993.332275 287.641510,2993.402100 287.176483,2993.616699 
	C286.397919,2993.976318 286.360291,2994.100830 287.546753,2993.774902 
	C290.926666,2993.765137 293.381104,2994.723877 295.692413,2997.437500 
z"
        />
        <path
          fill="#233444"
          opacity="1.000000"
          stroke="none"
          d="
M310.582336,2988.595215 
	C310.161041,2979.741699 303.912292,2982.582031 298.819855,2981.979492 
	C298.516144,2980.966553 299.047180,2979.962402 299.801453,2978.432129 
	C305.774231,2974.253906 312.160431,2976.724121 319.108154,2976.017090 
	C324.376495,2976.907959 326.655151,2979.646729 326.841003,2984.845703 
	C322.325348,2990.568359 317.157623,2991.652588 310.582336,2988.595215 
z"
        />
        <path
          fill="#2B3E51"
          opacity="1.000000"
          stroke="none"
          d="
M72.595901,2966.655273 
	C72.302246,2961.517090 75.294312,2960.629639 79.310654,2959.838623 
	C90.756027,2957.585449 101.975769,2958.469727 113.609489,2961.420410 
	C111.395363,2964.901611 108.006607,2964.355225 104.958481,2963.260742 
	C97.854309,2960.710205 91.365929,2962.258789 85.289459,2965.898438 
	C81.285454,2968.296875 77.382675,2968.579102 72.595901,2966.655273 
z"
        />
        <path
          fill="#2B3E51"
          opacity="1.000000"
          stroke="none"
          d="
M34.407207,2970.753906 
	C38.854267,2966.036133 44.676174,2965.904297 50.553932,2965.970459 
	C54.454479,2966.014404 58.355438,2966.022461 63.184727,2966.143066 
	C56.633507,2974.347656 45.010101,2976.399170 34.407207,2970.753906 
z"
        />
        <path
          fill="#233444"
          opacity="1.000000"
          stroke="none"
          d="
M256.550903,2976.530762 
	C259.433289,2969.814453 265.374451,2972.640137 271.193115,2972.035645 
	C268.391144,2978.823975 268.391144,2978.823975 256.550903,2976.530762 
z"
        />
        <path
          fill="#2B3E51"
          opacity="1.000000"
          stroke="none"
          d="
M69.952850,3015.172119 
	C60.643711,3018.451904 60.643711,3018.451904 57.924187,3010.973145 
	C62.300159,3010.363770 67.490822,3008.309814 69.952850,3015.172119 
z"
        />
        <path
          fill="#3C5163"
          opacity="1.000000"
          stroke="none"
          d="
M130.768005,2961.944824 
	C132.423447,2952.894287 139.187576,2956.504639 145.392578,2957.342285 
	C141.596893,2960.755859 136.632996,2961.545166 130.768005,2961.944824 
z"
        />
        <path
          fill="#233444"
          opacity="1.000000"
          stroke="none"
          d="
M193.558655,3017.435303 
	C192.881607,3019.656006 191.154190,3019.935303 188.608307,3019.836426 
	C188.522217,3016.726562 190.018661,3015.344482 193.558655,3017.435303 
z"
        />
        <path
          fill="#233444"
          opacity="1.000000"
          stroke="none"
          d="
M218.101990,2954.641113 
	C219.784515,2953.982178 221.593338,2953.837646 223.566193,2955.529053 
	C221.713745,2958.381836 219.726044,2958.575928 218.101990,2954.641113 
z"
        />
        <path
          fill="#233444"
          opacity="1.000000"
          stroke="none"
          d="
M151.895264,3013.314453 
	C150.502670,3013.966309 148.987961,3013.974365 146.716461,3013.934570 
	C147.126984,3009.799316 148.940277,3008.798828 151.895264,3013.314453 
z"
        />
        <path
          fill="#233444"
          opacity="1.000000"
          stroke="none"
          d="
M203.652435,3015.564941 
	C203.312943,3017.036133 202.352753,3017.639160 200.498291,3017.713867 
	C200.383942,3015.949463 200.651978,3013.937256 203.652435,3015.564941 
z"
        />
        <path
          fill="#2B3E51"
          opacity="1.000000"
          stroke="none"
          d="
M79.981033,3015.327637 
	C79.178123,3015.919922 78.250473,3015.951416 76.626633,3015.957031 
	C76.178535,3014.679932 76.333466,3013.256348 77.903625,3013.038086 
	C79.005508,3012.885010 79.533691,3013.811523 79.981033,3015.327637 
z"
        />
        <path
          fill="#2B3E51"
          opacity="1.000000"
          stroke="none"
          d="
M228.952942,2988.934570 
	C229.040909,2988.887207 228.864975,2988.981689 228.952942,2988.934570 
z"
        />
        <path
          fill="#516D85"
          opacity="1.000000"
          stroke="none"
          d="
M2793.552246,2573.110352 
	C2794.722168,2578.569580 2798.404053,2577.914795 2801.657715,2577.959717 
	C2810.909424,2578.087158 2820.215820,2577.391846 2829.287598,2580.007324 
	C2831.187012,2580.554932 2833.217773,2580.722656 2835.041748,2581.438477 
	C2837.429443,2582.375244 2842.269287,2581.268066 2840.977051,2585.826416 
	C2839.989990,2589.308350 2835.680908,2588.423584 2833.135254,2587.661377 
	C2826.605713,2585.705566 2820.020264,2586.027588 2813.410400,2586.044922 
	C2811.437500,2586.050049 2809.171631,2585.802979 2807.546143,2586.646484 
	C2801.970215,2589.540527 2796.502197,2590.441895 2790.420166,2588.203369 
	C2786.101074,2586.613770 2783.135742,2590.659424 2778.679443,2591.859131 
	C2754.092041,2583.101807 2729.603271,2583.467529 2704.457764,2587.406250 
	C2695.938721,2594.998291 2686.486572,2591.470459 2677.551514,2591.355713 
	C2674.712646,2591.319336 2671.891602,2589.853271 2668.538086,2588.478027 
	C2667.406494,2584.520020 2673.305420,2583.127686 2670.303467,2578.446777 
	C2668.236572,2575.223389 2672.197266,2574.159668 2674.754883,2573.779297 
	C2679.487793,2573.076172 2683.800781,2575.026123 2688.197266,2575.397949 
	C2660.807617,2571.358643 2633.682373,2575.906006 2606.438721,2577.636719 
	C2612.671875,2582.388672 2619.581299,2581.335693 2625.969727,2580.229736 
	C2635.623291,2578.559326 2645.189209,2579.991943 2654.779785,2579.644043 
	C2659.349365,2579.478271 2664.342285,2580.396240 2664.486572,2587.331055 
	C2661.854004,2588.240234 2659.388184,2587.456543 2656.239746,2590.346436 
	C2665.216553,2591.597656 2671.054199,2598.618408 2680.058838,2598.068115 
	C2697.262207,2597.017090 2714.584229,2599.768799 2731.736328,2596.541016 
	C2732.671143,2596.365234 2734.025879,2596.235596 2734.614014,2596.744629 
	C2739.436768,2600.920654 2745.256836,2599.777832 2750.825439,2600.065918 
	C2752.403564,2600.147461 2754.505371,2599.944580 2754.718750,2602.154053 
	C2754.964355,2604.697998 2752.858154,2605.601562 2750.867920,2605.715088 
	C2744.909668,2606.054932 2738.866455,2606.764893 2733.007812,2605.267334 
	C2726.411377,2603.581299 2720.025635,2604.295166 2713.351562,2604.996338 
	C2702.953125,2606.088623 2692.402588,2605.916016 2681.915771,2605.938477 
	C2663.673340,2605.977295 2645.569824,2609.222900 2627.273193,2608.145264 
	C2624.728027,2607.995361 2622.106201,2609.151367 2618.678711,2609.764648 
	C2615.399170,2605.888672 2611.209229,2605.600586 2607.329346,2605.166016 
	C2588.918213,2603.104248 2570.534668,2600.349609 2555.226807,2602.083740 
	C2559.802979,2602.246826 2567.883057,2601.035645 2575.703125,2605.596680 
	C2574.944336,2607.152588 2573.781250,2608.119385 2572.297363,2609.557617 
	C2567.464844,2614.992432 2561.807129,2610.854736 2557.315186,2611.987549 
	C2558.674316,2612.098389 2560.677490,2611.589600 2562.399658,2612.197510 
	C2582.602783,2619.324463 2603.636719,2618.691650 2624.574463,2619.099365 
	C2627.895508,2619.164062 2632.151611,2619.535400 2632.398926,2623.619385 
	C2632.701904,2628.631836 2627.875732,2628.709229 2624.403809,2629.070557 
	C2611.199219,2630.444336 2598.047363,2632.433838 2584.738770,2629.039795 
	C2570.795654,2625.484131 2556.273682,2626.980957 2542.056152,2625.694336 
	C2526.690918,2624.304199 2512.100342,2619.555176 2496.838623,2618.262939 
	C2492.565674,2617.901123 2488.482422,2616.780029 2484.098633,2617.752686 
	C2479.885498,2618.687988 2475.013672,2617.812988 2474.349365,2613.318359 
	C2473.782471,2609.484863 2478.847900,2609.373291 2481.900391,2608.376221 
	C2490.460938,2605.579346 2499.104004,2608.393066 2506.058350,2608.098145 
	C2496.060303,2606.360352 2483.658691,2609.074707 2472.437988,2601.303711 
	C2471.094971,2598.987793 2471.601318,2597.487305 2473.141113,2595.500488 
	C2476.093018,2593.477539 2478.737061,2593.168945 2481.419189,2593.444824 
	C2506.319824,2596.007812 2531.145996,2594.930664 2554.790771,2591.874512 
	C2551.746338,2591.355957 2547.443115,2593.833984 2542.651611,2591.096680 
	C2539.937744,2587.362793 2540.895752,2584.171631 2542.711914,2580.313477 
	C2551.908203,2574.270996 2561.347656,2578.492432 2568.622070,2577.047607 
	C2557.035889,2576.930664 2543.633301,2576.611084 2530.269531,2577.140381 
	C2520.198486,2577.538818 2510.343262,2576.048096 2500.526367,2574.718994 
	C2492.299561,2573.605713 2486.736572,2566.249756 2479.092041,2563.062256 
	C2475.662842,2561.632324 2476.670654,2557.975342 2480.509521,2556.565918 
	C2483.048096,2555.634033 2485.763672,2555.759766 2488.409180,2556.051270 
	C2497.763428,2557.082275 2506.860352,2557.216064 2515.470215,2552.191162 
	C2519.258545,2549.979980 2523.935303,2551.201416 2528.225098,2550.936523 
	C2533.065186,2550.637451 2538.254395,2552.538330 2543.365479,2548.622803 
	C2563.297119,2548.196045 2582.443604,2549.744873 2601.535400,2551.690186 
	C2610.629150,2552.616699 2619.376465,2552.872314 2627.999512,2549.354492 
	C2642.077637,2543.610352 2656.143066,2546.792969 2670.147217,2549.814941 
	C2672.763428,2550.379150 2676.290771,2552.003174 2675.665527,2554.649658 
	C2674.465332,2559.729980 2669.430908,2558.313965 2665.696533,2558.772949 
	C2668.062988,2559.595703 2670.453857,2558.154297 2673.593262,2558.802734 
	C2690.429443,2566.564941 2706.614990,2573.799072 2724.603516,2571.011230 
	C2712.721191,2567.755127 2699.164062,2570.207764 2687.202148,2562.261230 
	C2686.230469,2560.037109 2686.286133,2558.467773 2687.349121,2556.221680 
	C2709.204590,2557.658203 2730.873535,2557.320068 2752.487793,2563.848633 
	C2762.080811,2570.654297 2771.935791,2572.145996 2783.293457,2569.967285 
	C2787.482422,2569.360107 2790.734863,2569.474609 2793.552246,2573.110352 
z"
        />
        <path
          fill="#3F5A73"
          opacity="1.000000"
          stroke="none"
          d="
M2471.419434,2599.551514 
	C2488.430664,2604.954346 2505.434570,2606.167236 2522.536865,2607.528320 
	C2515.122803,2611.410645 2507.283936,2609.997803 2499.738037,2609.839355 
	C2491.651367,2609.669678 2484.011475,2611.952881 2475.962646,2612.385498 
	C2477.017090,2615.424072 2479.717529,2616.317383 2481.638428,2615.512451 
	C2490.727295,2611.704346 2499.764404,2615.000732 2508.112793,2616.796631 
	C2530.247803,2621.558105 2552.405518,2624.513672 2575.017334,2623.998291 
	C2579.801758,2623.889404 2584.196289,2625.561279 2588.681641,2626.633057 
	C2596.618652,2628.529541 2604.475098,2628.809082 2612.333008,2626.848145 
	C2615.976807,2625.938721 2619.607422,2626.276367 2623.235840,2625.962158 
	C2625.678467,2625.750244 2628.558350,2627.033691 2630.474854,2624.295410 
	C2629.775391,2621.510254 2627.491211,2622.081787 2625.705322,2622.064941 
	C2616.379150,2621.976562 2607.045898,2621.797119 2597.727051,2622.065674 
	C2589.617676,2622.299316 2581.845215,2621.008057 2574.260254,2618.310547 
	C2566.061768,2615.395020 2557.794434,2613.065430 2548.919434,2613.928711 
	C2547.101074,2614.105713 2544.899902,2614.497803 2542.968750,2611.437500 
	C2547.276855,2609.017578 2551.866943,2610.218994 2556.127686,2610.033203 
	C2560.781494,2609.830566 2565.452393,2610.018066 2571.045898,2610.032471 
	C2580.333252,2613.964844 2589.197021,2614.167725 2598.224854,2614.003418 
	C2614.486572,2613.707764 2630.595703,2616.638672 2646.926758,2616.352295 
	C2653.267822,2616.241211 2660.092529,2618.722412 2665.921631,2621.595703 
	C2671.505127,2624.348389 2676.852783,2624.375977 2682.352783,2623.428711 
	C2694.911377,2621.265869 2707.315674,2621.381104 2719.903809,2623.460449 
	C2729.300781,2625.012939 2738.833496,2626.237061 2749.152344,2625.774414 
	C2753.473145,2622.688477 2757.669189,2622.811523 2761.885010,2623.999756 
	C2766.109131,2625.190430 2770.187256,2625.741699 2774.898926,2622.980225 
	C2777.854248,2622.033447 2779.961670,2622.208984 2782.496094,2624.073242 
	C2785.211182,2629.636963 2789.580566,2630.649902 2794.342773,2630.998535 
	C2805.850342,2631.841797 2817.328369,2633.123047 2828.667236,2635.161621 
	C2836.756104,2636.616455 2845.001465,2638.619141 2848.202881,2648.773926 
	C2848.175537,2651.908691 2847.125977,2653.905518 2845.763672,2656.657227 
	C2838.578369,2662.436035 2831.259521,2657.909912 2823.467773,2658.612305 
	C2820.365234,2658.231689 2817.815186,2657.031738 2815.780762,2657.994873 
	C2799.859131,2665.534668 2784.448730,2659.371338 2769.092529,2656.099365 
	C2763.744629,2654.960205 2760.966064,2656.133789 2758.085449,2661.021484 
	C2749.491211,2665.359619 2741.694336,2661.602783 2733.951416,2660.786133 
	C2719.858398,2659.299805 2705.295166,2659.716553 2691.808105,2651.900391 
	C2691.047607,2648.262451 2691.176514,2644.383057 2686.685303,2645.563721 
	C2666.118408,2650.969482 2647.657227,2639.378906 2627.862305,2638.579834 
	C2607.962646,2637.776611 2588.195801,2634.104248 2568.145508,2636.349609 
	C2561.529297,2637.090820 2554.907715,2635.233398 2547.558105,2634.676758 
	C2544.738525,2634.033936 2542.750488,2632.854492 2539.821289,2633.538818 
	C2537.876221,2633.940674 2536.563477,2633.923096 2534.607666,2633.475586 
	C2531.963623,2632.340820 2530.185547,2630.731201 2527.012695,2631.418457 
	C2521.127441,2632.983643 2515.932129,2633.448242 2509.956299,2631.535156 
	C2500.151367,2624.701172 2490.117188,2622.406250 2479.146973,2624.333496 
	C2471.103027,2625.746826 2463.362549,2623.364258 2455.622803,2622.134521 
	C2443.199707,2620.160645 2430.727051,2618.728027 2418.275879,2617.050781 
	C2407.582031,2615.610107 2397.743896,2609.907471 2385.894043,2609.735107 
	C2380.195068,2608.191406 2375.208496,2609.825928 2369.496582,2609.371826 
	C2366.628418,2609.682617 2364.984863,2611.150879 2362.147461,2611.885742 
	C2350.387451,2609.158936 2339.049316,2609.164551 2327.965332,2607.376465 
	C2324.473389,2606.813232 2319.438721,2606.646973 2319.545410,2602.118164 
	C2319.669434,2596.854248 2324.902832,2597.139160 2328.804688,2597.007568 
	C2349.614990,2596.305176 2370.407959,2594.238770 2391.256592,2596.438477 
	C2406.871094,2598.085938 2422.332764,2593.241699 2438.753174,2595.291992 
	C2449.693115,2600.174072 2458.686768,2608.812500 2471.400879,2604.925049 
	C2472.673584,2605.322998 2471.813721,2602.923340 2471.711670,2604.252686 
	C2471.663574,2604.875732 2471.405762,2604.917725 2470.974609,2604.945557 
	C2467.818848,2605.149658 2466.913086,2603.448975 2467.610840,2600.058838 
	C2468.914307,2599.042480 2469.859863,2598.938232 2471.419434,2599.551514 
z"
        />
        <path
          fill="#2F465E"
          opacity="1.000000"
          stroke="none"
          d="
M2782.623291,2626.603027 
	C2780.850098,2624.270508 2779.103027,2624.050049 2776.583496,2624.106445 
	C2768.615479,2628.787598 2761.900146,2623.331787 2754.171631,2622.745850 
	C2751.011719,2620.969482 2748.500244,2619.615234 2744.733643,2619.875488 
	C2740.635986,2619.816162 2737.245605,2619.809814 2733.010010,2619.751709 
	C2730.843994,2618.497070 2730.611084,2617.025635 2731.585938,2614.746582 
	C2741.896973,2613.007080 2751.662109,2611.743408 2761.594238,2613.770508 
	C2765.389160,2614.544922 2769.542480,2614.258545 2773.420898,2613.037598 
	C2778.290039,2611.504883 2783.525635,2611.153076 2788.188232,2612.345703 
	C2797.934814,2614.837402 2807.989990,2614.857666 2817.694092,2615.193359 
	C2837.047852,2615.862793 2856.520264,2618.131592 2875.933350,2614.707520 
	C2884.890381,2613.127686 2893.562988,2616.625732 2903.173096,2617.919678 
	C2905.597656,2617.994873 2907.205078,2618.007812 2909.414551,2617.914795 
	C2920.065918,2617.923828 2930.130615,2617.729004 2940.887207,2619.771484 
	C2945.032227,2617.095703 2948.818604,2616.443359 2952.886475,2617.284668 
	C2955.518066,2617.829346 2958.492188,2618.649902 2958.522949,2621.822021 
	C2958.557129,2625.343994 2955.322021,2625.636719 2952.723145,2625.693359 
	C2945.148193,2625.858154 2937.608887,2626.260254 2929.332520,2627.992432 
	C2923.525635,2629.527832 2918.455078,2628.783936 2912.633301,2628.928223 
	C2908.373535,2629.217285 2904.808838,2628.411865 2900.735352,2630.067627 
	C2898.559814,2630.253906 2897.085205,2630.202393 2894.872070,2630.155762 
	C2884.617920,2632.380127 2876.042969,2627.207764 2866.765137,2626.621338 
	C2853.500244,2625.782715 2840.919434,2626.816406 2827.882324,2632.266113 
	C2812.131592,2633.327148 2797.383545,2632.578369 2782.623291,2626.603027 
z"
        />
        <path
          fill="#3F5A73"
          opacity="1.000000"
          stroke="none"
          d="
M2928.076660,2626.993652 
	C2937.175781,2622.566650 2946.510254,2620.549072 2957.968506,2623.608398 
	C2952.732422,2620.063965 2952.732422,2620.063965 2942.606201,2619.856445 
	C2941.667969,2616.061523 2939.556885,2614.509033 2936.085205,2614.118164 
	C2919.630371,2612.265137 2903.090820,2611.132324 2886.769531,2608.129639 
	C2881.070801,2607.081055 2875.689941,2609.821777 2869.991699,2610.003418 
	C2857.243896,2610.409424 2844.862061,2607.862305 2832.510742,2605.667969 
	C2823.209717,2604.015381 2814.006836,2604.126465 2804.721191,2604.017578 
	C2801.727539,2603.982422 2798.726562,2603.992676 2795.743652,2603.780029 
	C2793.978760,2603.654297 2792.540039,2602.777588 2792.722900,2600.664551 
	C2792.860107,2599.073242 2794.123047,2598.342041 2795.471191,2598.305908 
	C2803.969727,2598.075928 2812.203613,2595.065674 2821.039062,2597.945557 
	C2831.123779,2601.232178 2841.952148,2601.228027 2852.503906,2601.867432 
	C2862.460449,2602.470947 2872.487793,2602.283936 2882.469971,2601.979004 
	C2893.865723,2601.631348 2905.085693,2602.342285 2915.909912,2606.093018 
	C2925.190430,2609.308594 2935.146240,2606.873047 2944.446289,2609.920410 
	C2959.454346,2614.837891 2975.312500,2613.591064 2990.668945,2616.025879 
	C2993.281494,2616.440186 2995.848633,2617.141602 2999.245117,2617.838379 
	C2996.712646,2622.125000 2990.973145,2618.111328 2986.623291,2622.140381 
	C2990.864746,2625.186523 2995.783936,2623.820312 2999.859863,2625.456299 
	C3005.609863,2627.764160 3005.787842,2627.321045 3004.017090,2635.083008 
	C3003.041016,2635.813477 3002.205566,2635.829102 3000.742432,2635.831543 
	C2998.768066,2634.545410 2997.144043,2634.004395 2994.658447,2633.976562 
	C2983.457275,2630.632080 2972.599121,2632.605469 2960.962158,2632.041016 
	C2948.897949,2634.454834 2938.264160,2633.032227 2928.076660,2626.993652 
z"
        />
        <path
          fill="#445E74"
          opacity="1.000000"
          stroke="none"
          d="
M2900.535645,2561.288818 
	C2896.919189,2565.049072 2893.050293,2563.525635 2888.697510,2563.873291 
	C2877.057861,2567.983398 2867.054688,2561.955566 2856.625732,2561.122070 
	C2841.772217,2559.934326 2827.160156,2556.445801 2812.218994,2555.557617 
	C2828.379395,2556.621338 2844.265137,2559.619873 2860.250000,2561.923584 
	C2863.612061,2562.408203 2867.871094,2562.403809 2867.671875,2566.678223 
	C2867.482422,2570.747070 2863.010742,2571.049316 2859.865234,2570.958008 
	C2850.960449,2570.699219 2841.975342,2571.188232 2832.556641,2568.577637 
	C2828.540527,2568.138184 2825.169189,2568.153809 2820.955566,2568.197021 
	C2810.957764,2572.029785 2802.670654,2565.236084 2792.998535,2565.698975 
	C2787.387695,2559.226318 2783.118896,2552.699463 2774.731934,2550.405518 
	C2770.863281,2549.347656 2769.368652,2545.721924 2772.358154,2541.127441 
	C2787.281006,2540.999756 2801.269043,2544.191162 2815.624756,2543.098633 
	C2843.106934,2541.007080 2869.719482,2548.097168 2896.667236,2551.608398 
	C2897.981201,2551.779785 2899.084473,2552.682129 2900.653809,2554.304688 
	C2901.936523,2556.897217 2901.885254,2558.713135 2900.535645,2561.288818 
z"
        />
        <path
          fill="#536F86"
          opacity="1.000000"
          stroke="none"
          d="
M2831.847168,2568.835449 
	C2843.183105,2564.448486 2854.742188,2569.701660 2866.191650,2567.366211 
	C2865.268066,2563.227539 2862.122803,2564.464844 2859.733643,2564.006836 
	C2842.863525,2560.770996 2825.640381,2560.595215 2808.689209,2558.082520 
	C2805.799805,2557.654297 2802.740967,2557.378174 2800.827637,2553.286133 
	C2806.259521,2550.858154 2812.150391,2551.196533 2817.121826,2552.300049 
	C2840.030029,2557.384766 2863.467529,2558.879150 2887.174805,2563.550293 
	C2888.539062,2567.770020 2891.458252,2567.743652 2894.541016,2568.044434 
	C2910.032715,2569.555664 2925.338135,2572.299072 2941.306885,2575.700195 
	C2942.060059,2576.849854 2942.093262,2577.670166 2942.199707,2579.098145 
	C2924.494141,2575.690430 2906.165527,2577.062500 2888.257812,2574.211182 
	C2884.395752,2573.596191 2880.598877,2573.727051 2877.032471,2576.634766 
	C2873.695312,2579.356201 2869.241699,2580.444092 2864.824707,2579.922607 
	C2854.354004,2578.687500 2843.891846,2577.346436 2833.466309,2575.781738 
	C2828.887939,2575.094238 2831.611572,2571.840088 2831.847168,2568.835449 
z"
        />
        <path
          fill="#3E5871"
          opacity="1.000000"
          stroke="none"
          d="
M2664.743652,2587.916260 
	C2662.342285,2582.102783 2657.561768,2581.283936 2652.931152,2582.394287 
	C2648.526611,2583.450684 2644.577637,2583.048096 2640.312744,2582.270752 
	C2637.458740,2581.750244 2634.215820,2581.826172 2631.448730,2582.649414 
	C2622.930420,2585.182861 2614.294678,2583.555908 2605.728760,2583.951172 
	C2603.374512,2584.059814 2600.944824,2583.768555 2600.375977,2580.744629 
	C2599.960938,2578.538086 2601.029541,2577.030273 2602.718018,2575.672363 
	C2605.654297,2573.310547 2608.734863,2571.808594 2612.679199,2571.927490 
	C2619.642578,2572.137695 2626.625000,2572.235352 2633.583984,2571.970459 
	C2645.702637,2571.509277 2657.874023,2571.297852 2669.901367,2569.918945 
	C2679.875244,2568.775391 2687.960205,2572.936768 2696.330566,2576.827148 
	C2696.961182,2577.120605 2697.437012,2578.472900 2697.406738,2579.312256 
	C2697.345215,2581.035645 2695.924316,2581.410400 2694.561279,2581.055908 
	C2689.759033,2579.808105 2684.958252,2578.533203 2680.224854,2577.051270 
	C2677.369629,2576.157715 2674.643799,2575.581299 2671.756104,2576.738525 
	C2672.248535,2580.278564 2675.321533,2579.608398 2677.072266,2580.805664 
	C2678.203857,2581.579834 2679.182861,2582.458740 2678.929443,2583.968262 
	C2678.645264,2585.659180 2677.169434,2585.741455 2675.885986,2585.907471 
	C2673.609619,2586.201416 2671.213135,2585.640869 2668.544189,2587.405029 
	C2667.141357,2587.918457 2666.269287,2587.921875 2664.743652,2587.916260 
z"
        />
        <path
          fill="#445E74"
          opacity="1.000000"
          stroke="none"
          d="
M2754.798828,2566.696045 
	C2748.566650,2566.032715 2743.032959,2566.067871 2737.871826,2563.148438 
	C2734.312988,2561.135986 2730.127441,2562.030762 2726.209961,2562.002441 
	C2713.687012,2561.911377 2701.423096,2560.162109 2688.702148,2556.313477 
	C2687.852295,2555.438232 2687.714111,2554.866455 2688.123535,2553.602051 
	C2703.064209,2548.473877 2717.862305,2549.443848 2732.069092,2552.247070 
	C2745.408691,2554.879395 2758.452148,2553.297119 2771.614014,2553.358887 
	C2778.460938,2553.390869 2784.037354,2555.119385 2788.669189,2561.407471 
	C2791.154297,2567.083984 2795.550049,2565.806641 2799.357910,2566.002930 
	C2804.276611,2566.256592 2809.620117,2565.719971 2810.344238,2573.186523 
	C2805.134766,2573.881348 2800.409180,2573.894043 2794.790039,2573.887939 
	C2791.279541,2572.445557 2788.495361,2571.762695 2784.773926,2571.973633 
	C2774.010254,2573.372559 2764.882080,2569.379395 2754.798828,2566.696045 
z"
        />
        <path
          fill="#3E5871"
          opacity="1.000000"
          stroke="none"
          d="
M2576.950195,2606.165771 
	C2565.583252,2605.265137 2555.057617,2604.358154 2544.540283,2603.363525 
	C2544.188965,2603.330566 2543.920898,2602.416260 2543.613770,2601.908203 
	C2543.938477,2600.153809 2545.433350,2600.198486 2546.644531,2600.141113 
	C2556.223877,2599.687500 2565.863770,2600.611328 2575.348389,2598.135986 
	C2580.338135,2596.834229 2585.188477,2599.272949 2590.018555,2600.158203 
	C2597.818115,2601.587646 2605.829590,2601.563232 2613.500488,2603.878174 
	C2616.009766,2604.635498 2617.830811,2605.415771 2617.846436,2609.068359 
	C2612.443359,2608.139160 2606.906250,2607.623291 2601.290527,2607.907959 
	C2593.379883,2608.309326 2585.561279,2607.587646 2576.950195,2606.165771 
z"
        />
        <path
          fill="#3E5871"
          opacity="1.000000"
          stroke="none"
          d="
M2704.944824,2588.057129 
	C2703.148926,2585.638672 2701.203369,2582.218262 2705.804443,2582.241455 
	C2726.653076,2582.345947 2747.694092,2579.812256 2768.179199,2585.834717 
	C2771.532227,2586.820312 2775.483154,2587.002441 2777.791992,2591.235107 
	C2770.595459,2592.635986 2763.432861,2590.646484 2756.355225,2589.622559 
	C2739.510742,2587.185791 2722.691162,2588.682617 2704.944824,2588.057129 
z"
        />
        <path
          fill="#3F5A73"
          opacity="1.000000"
          stroke="none"
          d="
M2731.159668,2614.064453 
	C2732.073242,2615.515625 2732.073242,2616.914795 2732.119141,2619.007080 
	C2717.114258,2616.831055 2701.663086,2619.414307 2686.603760,2616.262207 
	C2681.035156,2615.096680 2675.020752,2616.899658 2669.550537,2613.907471 
	C2689.823975,2610.625977 2710.035645,2612.908447 2731.159668,2614.064453 
z"
        />
        <path
          fill="#4A667C"
          opacity="1.000000"
          stroke="none"
          d="
M2950.425781,2593.281738 
	C2944.532715,2592.261475 2938.958740,2591.568359 2933.307861,2592.010742 
	C2921.341309,2592.947998 2909.600098,2590.494385 2897.742920,2589.818604 
	C2896.718750,2589.760254 2895.926025,2589.291992 2895.531250,2588.221436 
	C2895.508545,2586.424805 2896.971191,2586.121338 2898.201416,2586.148193 
	C2913.661377,2586.481934 2929.357422,2583.729736 2944.411133,2589.639160 
	C2945.589844,2590.101807 2947.031982,2589.893555 2949.170898,2590.020996 
	C2950.700684,2590.790039 2950.973877,2591.680420 2950.425781,2593.281738 
z"
        />
        <path
          fill="#536F86"
          opacity="1.000000"
          stroke="none"
          d="
M2810.629639,2573.905762 
	C2808.477051,2567.848389 2803.581787,2568.439209 2799.088623,2567.967285 
	C2795.452393,2567.585205 2791.180176,2569.653564 2788.194824,2564.758057 
	C2788.798096,2563.298340 2789.725342,2563.006104 2791.405273,2563.552002 
	C2801.239258,2562.819824 2809.837158,2565.644287 2819.307129,2568.134521 
	C2819.378906,2573.438232 2815.712158,2574.182617 2810.629639,2573.905762 
z"
        />
        <path
          fill="#4C6880"
          opacity="1.000000"
          stroke="none"
          d="
M2751.494629,2620.157959 
	C2759.587158,2620.429688 2766.541504,2624.349121 2775.081543,2624.080078 
	C2774.125000,2630.516357 2768.874756,2628.016357 2765.238037,2627.609619 
	C2760.716064,2627.103760 2756.273438,2625.722900 2750.741943,2625.907227 
	C2749.894043,2625.000977 2749.930420,2624.151855 2749.973633,2622.663818 
	C2750.106445,2621.322266 2750.442627,2620.741455 2751.494629,2620.157959 
z"
        />
        <path
          fill="#4A667C"
          opacity="1.000000"
          stroke="none"
          d="
M2884.214355,2585.994629 
	C2885.821533,2586.272461 2886.675293,2586.537598 2887.529297,2586.802734 
	C2879.854736,2587.974121 2872.201172,2591.983154 2864.433350,2585.981689 
	C2870.776123,2585.981689 2877.118652,2585.981689 2884.214355,2585.994629 
z"
        />
        <path
          fill="#3F5A73"
          opacity="1.000000"
          stroke="none"
          d="
M2751.569336,2619.536133 
	C2751.751953,2620.650146 2751.360352,2621.154785 2750.402832,2621.786377 
	C2748.202148,2621.937500 2746.363525,2622.124023 2744.431641,2620.357910 
	C2746.177979,2617.573975 2748.584961,2617.638428 2751.569336,2619.536133 
z"
        />
        <path
          fill="#4C6880"
          opacity="1.000000"
          stroke="none"
          d="
M2950.655518,2593.889648 
	C2949.984375,2593.018555 2949.988037,2592.169189 2949.991211,2590.682373 
	C2951.742920,2590.276855 2954.302979,2589.407471 2954.737549,2591.824951 
	C2955.137695,2594.050537 2952.766357,2593.639404 2950.655518,2593.889648 
z"
        />
        <path
          fill="#3F5A73"
          opacity="1.000000"
          stroke="none"
          d="
M2909.611816,2617.441162 
	C2908.598145,2619.923096 2906.854248,2620.304199 2904.376465,2618.431641 
	C2905.452637,2616.115967 2907.190918,2615.796631 2909.611816,2617.441162 
z"
        />
        <path
          fill="#597288"
          opacity="1.000000"
          stroke="none"
          d="
M213.145752,2401.788330 
	C218.139404,2401.915283 222.374969,2402.001221 226.610657,2402.007568 
	C229.586151,2402.012207 231.711212,2402.953857 231.926376,2407.124756 
	C222.717743,2408.424316 213.371353,2406.980225 204.249115,2409.779541 
	C202.476013,2410.323730 199.903259,2410.325684 198.419083,2409.410400 
	C189.406448,2403.852295 180.026031,2405.910645 170.582855,2407.421143 
	C164.322983,2408.422363 157.889893,2408.787354 151.763260,2407.379150 
	C145.743393,2405.995361 140.090546,2405.672852 134.153122,2407.488770 
	C131.692337,2408.241699 128.885635,2407.988525 126.237122,2407.978027 
	C118.955269,2407.948975 118.955452,2407.904053 120.376251,2400.330078 
	C119.885765,2395.764893 123.030037,2396.174316 126.296463,2395.968018 
	C127.627747,2396.185303 128.345856,2396.305176 129.391052,2396.038086 
	C130.185135,2395.222900 130.702637,2394.829590 131.641663,2394.178711 
	C134.570511,2391.181152 137.404266,2392.319580 140.354019,2393.056152 
	C146.573212,2394.609619 152.943710,2394.597900 159.117874,2393.164795 
	C166.692642,2391.406738 173.323196,2391.884277 178.782227,2398.177002 
	C181.340118,2401.125488 185.334641,2400.587891 188.154510,2399.636719 
	C196.697510,2396.755615 204.519180,2398.675049 213.145752,2401.788330 
z"
        />
        <path
          fill="#597288"
          opacity="1.000000"
          stroke="none"
          d="
M18.647684,2415.657227 
	C18.236931,2411.201660 16.556946,2408.106689 10.568296,2407.880859 
	C10.238523,2406.919922 10.829194,2405.226074 11.090659,2405.275146 
	C19.023609,2406.773682 28.366461,2402.507568 34.630585,2410.923096 
	C38.087097,2415.566895 43.271725,2414.686035 47.443176,2413.489014 
	C52.602219,2412.008789 57.272812,2412.344727 61.981556,2414.041748 
	C66.632156,2415.717529 71.097939,2414.614990 75.612335,2413.848389 
	C76.838341,2413.640381 78.810509,2413.047119 79.004181,2412.271973 
	C81.156990,2403.655273 85.445389,2406.332275 90.602074,2409.452637 
	C93.070160,2410.945801 96.413605,2410.041748 99.363785,2409.229980 
	C105.529419,2407.533936 111.634186,2405.935791 115.847122,2413.169922 
	C116.664223,2414.573242 119.669533,2413.768311 121.322906,2416.931396 
	C113.434692,2417.108887 106.766624,2421.052246 99.068932,2418.790039 
	C93.262550,2417.083496 87.016991,2417.242188 81.265732,2418.368164 
	C64.746262,2421.602295 48.142517,2419.108154 31.616573,2420.042480 
	C28.315060,2420.229248 29.371441,2418.427246 28.614353,2416.334473 
	C27.000790,2411.874756 22.665094,2414.367676 18.647684,2415.657227 
z"
        />
        <path
          fill="#4B6377"
          opacity="1.000000"
          stroke="none"
          d="
M257.254333,2413.645996 
	C253.388596,2418.417236 249.235855,2423.488770 243.321075,2426.103516 
	C239.715439,2423.532227 245.071899,2420.791992 242.473022,2419.000488 
	C239.827545,2417.176270 236.788528,2418.020996 233.944794,2419.015625 
	C230.577759,2420.193359 227.144241,2420.990234 223.799759,2422.479980 
	C218.884903,2424.669434 213.227448,2426.114990 208.189667,2420.753906 
	C213.352524,2418.624512 218.512726,2416.920654 223.675964,2414.750977 
	C234.202286,2410.326904 245.432281,2411.368408 257.254333,2413.645996 
z"
        />
        <path
          fill="#43596D"
          opacity="1.000000"
          stroke="none"
          d="
M131.212708,2393.854492 
	C131.487549,2394.875488 130.958115,2395.890869 130.176666,2397.429199 
	C126.933350,2398.084717 123.861755,2397.446289 120.474022,2399.397217 
	C114.298309,2399.961182 108.654564,2399.986328 103.011543,2400.063721 
	C93.779205,2400.189941 84.442787,2399.226807 75.658600,2403.452393 
	C74.003578,2404.248779 71.733887,2403.767578 68.921814,2403.891357 
	C67.289597,2403.180176 66.951141,2402.278076 67.468796,2400.546387 
	C80.473030,2397.455322 93.109726,2394.527588 105.921600,2394.177002 
	C114.049416,2393.954346 122.235786,2390.418701 131.212708,2393.854492 
z"
        />
        <path
          fill="#597288"
          opacity="1.000000"
          stroke="none"
          d="
M67.124626,2399.798828 
	C67.906815,2400.785645 67.957062,2401.679443 68.051559,2403.239990 
	C62.476509,2403.925537 56.784615,2404.612061 51.320358,2402.371338 
	C56.057426,2399.003174 60.883152,2397.216553 67.124626,2399.798828 
z"
        />
        <path
          fill="#597288"
          opacity="1.000000"
          stroke="none"
          d="
M129.578217,2414.472656 
	C131.374313,2415.687744 131.228516,2416.714844 129.820007,2417.409912 
	C128.937943,2417.845215 127.794083,2417.708008 127.350235,2416.769531 
	C126.631683,2415.249756 127.634323,2414.594971 129.578217,2414.472656 
z"
        />
        <path
          fill="#4B6377"
          opacity="1.000000"
          stroke="none"
          d="
M203.325348,2420.020996 
	C203.793701,2421.294189 203.692139,2422.731201 202.107956,2422.966797 
	C200.996933,2423.131836 200.488510,2422.185547 200.126740,2420.640137 
	C200.917999,2420.052490 201.793243,2420.032959 203.325348,2420.020996 
z"
        />
        <path
          fill="#546D83"
          opacity="1.000000"
          stroke="none"
          d="
M90.084267,2422.878662 
	C91.469109,2423.634766 91.483536,2424.396484 90.495552,2425.069092 
	C90.288712,2425.210205 89.810432,2425.204346 89.608452,2425.057861 
	C88.641518,2424.357178 88.677994,2423.596680 90.084267,2422.878662 
z"
        />
        <path
          fill="#6A8297"
          opacity="1.000000"
          stroke="none"
          d="
M341.593536,2400.225586 
	C343.008484,2400.715088 343.810577,2401.602295 342.910797,2402.800293 
	C342.318787,2403.588135 341.404755,2403.387939 340.316772,2402.413818 
	C340.238373,2401.382812 340.627686,2400.866455 341.593536,2400.225586 
z"
        />
        <path
          fill="#D8D1C0"
          opacity="1.000000"
          stroke="none"
          d="
M1577.263916,3793.407227 
	C1564.909790,3791.695068 1561.709351,3788.862793 1560.347168,3779.511963 
	C1559.728394,3775.265381 1559.196899,3771.495117 1565.202148,3769.385986 
	C1570.222534,3767.622803 1571.871094,3761.592773 1573.750244,3756.703857 
	C1574.371826,3755.086914 1573.642578,3753.456055 1571.339722,3753.400879 
	C1566.352783,3753.281738 1567.071533,3750.464844 1567.254639,3746.625732 
	C1567.567993,3740.053467 1571.361328,3734.865479 1572.935425,3728.820801 
	C1574.954712,3721.065918 1576.226562,3713.598633 1574.349854,3705.698486 
	C1573.135498,3700.586914 1575.899414,3696.107910 1576.862183,3691.458252 
	C1580.613525,3690.415283 1579.710083,3696.237793 1583.105347,3695.148682 
	C1586.985229,3693.904541 1590.123413,3691.536621 1592.811523,3688.628418 
	C1592.398560,3685.554932 1590.034668,3686.145996 1588.212402,3686.045654 
	C1585.039917,3685.870361 1581.907104,3685.908691 1583.115845,3681.163086 
	C1583.356812,3680.217529 1583.082031,3679.092041 1582.010132,3678.657227 
	C1579.381836,3677.591553 1575.020264,3677.375488 1576.511597,3673.328857 
	C1577.875122,3669.629395 1579.325073,3665.131592 1584.257935,3663.957764 
	C1585.186157,3663.737061 1586.134399,3663.315918 1586.885132,3662.741455 
	C1587.326050,3662.404053 1587.605957,3661.573975 1587.586548,3660.977539 
	C1587.547852,3659.787354 1586.603882,3659.228760 1585.652832,3659.775146 
	C1583.071899,3661.258545 1581.036499,3661.043945 1580.436279,3657.916504 
	C1579.830811,3654.760742 1581.714600,3652.420410 1584.685303,3652.386719 
	C1588.659424,3652.341309 1592.327148,3649.675049 1596.382935,3651.188721 
	C1598.798584,3652.090088 1600.967407,3651.771973 1602.832275,3649.992188 
	C1604.265137,3648.624756 1605.947388,3647.194580 1604.998901,3644.915771 
	C1604.134399,3642.839111 1602.037354,3641.569092 1600.162598,3642.484863 
	C1594.734375,3645.135986 1588.395996,3640.050049 1583.879272,3646.157715 
	C1581.709717,3641.290283 1586.073242,3638.857178 1584.900513,3635.364502 
	C1576.420654,3634.379639 1570.765747,3638.067139 1566.723877,3645.871582 
	C1564.517822,3645.632812 1563.502930,3644.629150 1562.454468,3642.660645 
	C1558.429321,3633.281982 1560.199951,3625.101318 1564.471313,3617.103271 
	C1565.421631,3615.323975 1568.688354,3613.424316 1565.595703,3611.309082 
	C1560.474609,3607.806152 1558.322266,3604.369629 1563.982056,3599.598145 
	C1564.530396,3599.135742 1565.086182,3598.195801 1564.735596,3597.887207 
	C1555.279419,3589.549072 1565.641357,3585.880859 1568.675903,3580.382568 
	C1570.261841,3577.508789 1571.650635,3574.236084 1571.110352,3570.840332 
	C1570.239136,3565.363770 1572.135986,3561.073730 1576.517578,3557.003662 
	C1585.051270,3556.562012 1589.479492,3552.502197 1591.062866,3544.170410 
	C1591.913086,3543.392334 1592.328369,3543.169678 1593.444580,3542.852295 
	C1599.585205,3547.876709 1606.369751,3550.021729 1614.098877,3550.826904 
	C1602.855713,3550.366943 1602.721191,3538.419922 1595.311646,3533.894287 
	C1592.755127,3532.332520 1592.268066,3529.756592 1594.508301,3527.109131 
	C1599.143188,3521.631592 1608.746338,3520.145996 1614.927246,3524.053711 
	C1617.228760,3525.508789 1631.027954,3522.445557 1633.041626,3519.615967 
	C1636.158813,3515.235840 1633.712036,3511.410889 1630.940674,3507.973877 
	C1628.453735,3504.889648 1624.562988,3501.984131 1629.455078,3498.137695 
	C1633.563110,3494.907471 1638.015625,3495.000000 1642.185303,3498.463623 
	C1644.221680,3500.155273 1645.174561,3502.886963 1648.203369,3504.589844 
	C1651.140381,3509.039795 1656.023560,3511.153076 1656.997803,3516.829590 
	C1656.633179,3517.919189 1656.384888,3518.312256 1655.583496,3519.118164 
	C1652.641235,3520.766846 1649.892212,3520.814209 1646.852051,3522.117676 
	C1644.367554,3523.189697 1643.966431,3524.745605 1644.172241,3527.273926 
	C1644.269043,3529.293213 1644.556763,3530.991211 1643.737549,3531.834717 
	C1633.937988,3541.927246 1637.710938,3553.792725 1639.360107,3565.291504 
	C1639.913940,3569.153320 1639.324341,3572.446533 1637.359863,3575.703613 
	C1633.781250,3581.636963 1629.735962,3587.354980 1631.825195,3595.072998 
	C1632.548706,3597.745117 1630.987061,3600.211670 1628.100220,3601.347900 
	C1626.308228,3602.053223 1623.136353,3602.236328 1623.394409,3604.298340 
	C1624.653076,3614.365967 1619.598022,3625.131836 1622.597656,3633.837402 
	C1625.881104,3643.366699 1626.641235,3650.731201 1621.074951,3659.491699 
	C1620.564209,3660.459717 1620.365967,3660.843018 1619.832031,3661.762939 
	C1618.345459,3663.890137 1617.009888,3665.413818 1617.609863,3668.227539 
	C1617.640015,3669.649658 1617.529907,3670.454834 1617.085449,3671.814941 
	C1615.428101,3674.266357 1613.361816,3675.841797 1613.876465,3679.295410 
	C1613.836548,3688.483398 1608.447510,3695.652100 1607.725342,3704.750732 
	C1603.276123,3722.858398 1597.877319,3739.975342 1592.211426,3756.944580 
	C1588.868896,3766.955322 1590.869995,3778.530518 1582.882568,3787.842285 
	C1581.492798,3790.207031 1580.224365,3791.697510 1577.263916,3793.407227 
z"
        />
        <path
          fill="#BAB4A7"
          opacity="1.000000"
          stroke="none"
          d="
M1579.356079,3790.428223 
	C1579.966919,3788.251465 1579.494873,3785.787842 1580.389893,3785.098633 
	C1587.293579,3779.781006 1585.211792,3772.102051 1586.311035,3765.162842 
	C1587.593506,3757.066162 1592.712280,3750.126953 1593.837280,3741.851807 
	C1595.577759,3729.047852 1602.189453,3717.735596 1605.725586,3704.688477 
	C1608.877930,3697.240234 1613.459473,3691.488037 1617.357666,3685.374756 
	C1619.993164,3681.241455 1624.358276,3677.411377 1629.869873,3679.554688 
	C1635.275146,3681.656982 1633.725708,3686.925049 1634.002197,3691.590576 
	C1634.650879,3702.534180 1628.638550,3712.155762 1627.806274,3722.047852 
	C1626.895630,3732.873047 1623.117432,3741.647217 1618.488281,3751.430664 
	C1617.995117,3751.990234 1618.000000,3752.000000 1617.996582,3751.999023 
	C1614.456909,3763.321533 1613.601318,3775.378662 1608.512573,3786.285645 
	C1606.452026,3790.701904 1605.719971,3796.123779 1598.804932,3796.835938 
	C1594.380737,3791.843018 1597.642700,3787.076172 1598.163818,3782.321289 
	C1598.559082,3778.713867 1599.736572,3775.185547 1599.225830,3771.282471 
	C1595.744751,3772.995850 1597.186768,3776.244385 1596.932373,3778.649414 
	C1595.753784,3789.789307 1592.643799,3800.235840 1585.692383,3809.092773 
	C1582.606201,3813.024902 1579.857788,3818.465576 1572.560547,3816.458008 
	C1571.869629,3808.933350 1577.039307,3802.885742 1575.965088,3794.793457 
	C1575.942017,3793.960938 1575.980103,3793.972168 1575.971436,3793.958740 
	C1576.990479,3792.997070 1577.972534,3792.003662 1579.356079,3790.428223 
z"
        />
        <path
          fill="#BAB4A7"
          opacity="1.000000"
          stroke="none"
          d="
M1479.930298,3993.939209 
	C1477.278687,3994.264648 1474.627808,3994.475586 1472.030273,3994.967773 
	C1470.593018,3995.239990 1469.366089,3995.281250 1468.646362,3993.906982 
	C1467.960693,3992.597412 1468.452515,3991.252197 1469.252808,3990.166992 
	C1470.035034,3989.106445 1470.900879,3987.937500 1472.010254,3987.315918 
	C1479.849121,3982.923584 1482.630981,3972.853516 1491.704712,3969.832275 
	C1492.484863,3969.572510 1493.303833,3968.473877 1493.519531,3967.617676 
	C1496.640503,3955.232666 1505.771362,3947.620850 1515.010010,3939.969727 
	C1521.915039,3934.250732 1527.485474,3927.549561 1528.986694,3918.204102 
	C1529.721436,3913.629150 1531.382812,3909.186523 1531.918091,3904.601318 
	C1532.447632,3900.065674 1534.950073,3896.771729 1538.499512,3895.223145 
	C1543.347778,3893.107910 1547.198730,3890.691162 1548.950073,3885.469238 
	C1549.395020,3884.141846 1551.150024,3884.188721 1553.196533,3883.973633 
	C1558.126831,3888.184082 1555.753662,3892.700439 1554.144165,3896.918945 
	C1551.300049,3904.374268 1549.593994,3912.117920 1547.463623,3919.798340 
	C1551.985840,3915.087158 1555.545166,3910.118652 1555.902466,3903.239258 
	C1556.112061,3899.205566 1557.578125,3895.271973 1563.452271,3895.131836 
	C1571.516968,3906.378662 1568.440918,3924.847412 1557.334839,3935.734375 
	C1555.938354,3937.103271 1553.679688,3937.728760 1553.207520,3939.879883 
	C1550.479858,3952.307129 1539.110962,3953.762451 1530.065308,3958.056396 
	C1525.468384,3960.238525 1522.470215,3958.777100 1522.022949,3952.906982 
	C1522.781250,3961.077148 1518.409668,3966.330322 1512.546387,3971.563477 
	C1512.085571,3972.079834 1512.040161,3972.099854 1512.068115,3972.112305 
	C1510.848999,3973.123047 1509.795288,3974.382080 1507.699951,3975.183105 
	C1506.067871,3975.779541 1504.989868,3976.178955 1503.708862,3977.421387 
	C1502.624390,3978.343750 1501.946289,3978.812988 1500.691406,3979.584473 
	C1497.273193,3980.965088 1494.102417,3981.507080 1492.054810,3985.101074 
	C1489.196899,3989.729004 1483.816650,3989.583984 1480.430542,3993.470703 
	C1479.941772,3993.912598 1479.953369,3993.942139 1479.930298,3993.939209 
z"
        />
        <path
          fill="#A6A29C"
          opacity="1.000000"
          stroke="none"
          d="
M1564.638428,3895.530273 
	C1558.222046,3897.671143 1557.510986,3901.995850 1558.665039,3906.896973 
	C1559.235962,3909.322021 1559.721313,3911.556152 1557.585205,3913.185791 
	C1554.045776,3915.885742 1551.775391,3918.977051 1551.376709,3923.694580 
	C1551.184326,3925.970459 1548.533691,3927.449463 1546.030762,3926.830322 
	C1543.534302,3926.212646 1544.118896,3923.711426 1544.042847,3921.848145 
	C1543.702271,3913.490479 1548.257690,3906.421631 1550.424072,3898.729736 
	C1551.656372,3894.354004 1554.100830,3890.319092 1553.951660,3884.748535 
	C1556.960449,3860.996338 1563.552734,3839.020508 1571.988281,3816.777344 
	C1578.225220,3816.118896 1580.794434,3810.657715 1583.597290,3807.014404 
	C1590.758423,3797.706055 1594.017334,3786.642578 1594.012085,3774.759033 
	C1594.011230,3772.781494 1593.939941,3770.763428 1594.292114,3768.835693 
	C1594.827637,3765.903320 1596.614868,3763.932617 1599.808838,3764.532959 
	C1602.927246,3765.118896 1604.183350,3767.439209 1603.467285,3770.453613 
	C1601.569702,3778.441650 1601.060303,3786.726318 1598.247803,3795.310059 
	C1601.115845,3799.960938 1599.654419,3803.833496 1597.613647,3807.225342 
	C1582.922241,3831.642090 1574.709229,3858.359619 1569.133789,3886.055664 
	C1568.483398,3889.286377 1567.573242,3892.497803 1564.638428,3895.530273 
z"
        />
        <path
          fill="#D8D1C0"
          opacity="1.000000"
          stroke="none"
          d="
M1540.553711,3714.504395 
	C1539.947144,3709.360352 1537.365112,3704.685059 1541.420532,3700.004395 
	C1543.378906,3697.743896 1540.929199,3694.351074 1540.073975,3690.774902 
	C1539.308105,3689.298584 1538.931519,3688.440430 1539.075195,3686.773193 
	C1539.585205,3685.707520 1539.867676,3685.322754 1540.727295,3684.516602 
	C1546.539551,3682.122070 1550.867310,3678.794678 1555.024658,3674.345215 
	C1555.326050,3672.821045 1555.517212,3671.954834 1555.912842,3670.455078 
	C1556.870605,3667.733398 1557.309692,3665.560791 1557.929199,3662.730957 
	C1558.418335,3660.726807 1559.032593,3659.474365 1559.539307,3657.443604 
	C1560.356445,3655.420898 1561.216309,3654.311768 1563.292969,3653.837402 
	C1566.168945,3656.116455 1568.064087,3658.149658 1566.376465,3661.651123 
	C1561.580933,3671.599854 1561.213745,3682.498779 1560.033691,3693.196045 
	C1559.555908,3697.525635 1560.183105,3702.419922 1553.164551,3700.900635 
	C1551.098511,3700.453613 1550.250732,3701.998535 1550.192871,3703.859863 
	C1550.114502,3706.371338 1550.946899,3708.333008 1553.560547,3709.255859 
	C1560.019165,3711.536377 1560.342163,3712.192871 1557.514648,3718.041260 
	C1556.971924,3719.163818 1555.748413,3719.957520 1553.786621,3721.626465 
	C1549.225220,3719.258545 1545.405518,3716.658447 1540.553711,3714.504395 
z"
        />
        <path
          fill="#DAD3C3"
          opacity="1.000000"
          stroke="none"
          d="
M1564.077393,3653.314941 
	C1563.359131,3654.951660 1562.723145,3655.903320 1562.025146,3657.447998 
	C1561.100464,3658.895752 1560.008667,3659.565918 1559.843994,3661.425537 
	C1559.866089,3664.361572 1560.416016,3666.825928 1558.510010,3669.512207 
	C1552.518188,3671.385986 1548.205688,3669.417725 1544.308594,3664.685547 
	C1544.007324,3663.111084 1544.009766,3662.222168 1544.009888,3660.666504 
	C1543.428589,3654.495117 1544.891479,3649.907227 1550.971680,3649.060303 
	C1556.415283,3648.302246 1557.057251,3641.118652 1563.356812,3641.564453 
	C1564.523193,3643.000244 1565.046387,3644.000244 1565.798706,3645.518066 
	C1565.405151,3648.233887 1564.782471,3650.431885 1564.077393,3653.314941 
z"
        />
        <path
          fill="#D8D1C0"
          opacity="1.000000"
          stroke="none"
          d="
M1565.259155,3803.277100 
	C1569.412598,3806.354980 1568.781494,3810.017578 1566.077759,3813.296631 
	C1562.372192,3817.790283 1557.918579,3818.281982 1552.837280,3814.305664 
	C1553.332031,3811.651367 1553.746216,3808.747314 1554.449463,3805.914795 
	C1554.861084,3804.256104 1556.069946,3802.516846 1557.882568,3802.973633 
	C1560.179077,3803.552490 1558.864990,3805.586426 1558.770020,3807.104980 
	C1558.736084,3807.649170 1559.246826,3808.227539 1559.549438,3808.877197 
	C1562.971069,3808.759766 1562.718872,3805.081787 1565.259155,3803.277100 
z"
        />
        <path
          fill="#E6E1D0"
          opacity="1.000000"
          stroke="none"
          d="
M1541.935425,3821.201904 
	C1536.896729,3822.673340 1532.225586,3821.015381 1527.480591,3819.874023 
	C1525.752563,3819.458252 1524.451538,3818.572266 1524.501465,3816.621826 
	C1524.547363,3814.841064 1525.537964,3813.151123 1527.156738,3812.896973 
	C1532.096558,3812.121582 1537.332153,3809.826660 1541.891479,3815.323730 
	C1542.058838,3817.546631 1542.009766,3819.008789 1541.935425,3821.201904 
z"
        />
        <path
          fill="#DAD3C3"
          opacity="1.000000"
          stroke="none"
          d="
M1555.289062,3723.612305 
	C1555.319824,3726.715088 1554.489868,3729.403809 1553.991089,3732.152832 
	C1553.513062,3734.786865 1551.996582,3735.869385 1548.750000,3735.978271 
	C1544.189819,3730.590820 1546.312744,3726.856201 1552.194092,3723.779785 
	C1553.517578,3723.476318 1554.045410,3723.350342 1555.289062,3723.612305 
z"
        />
        <path
          fill="#D8D1C0"
          opacity="1.000000"
          stroke="none"
          d="
M1555.773071,3723.573242 
	C1555.665283,3723.999268 1555.325684,3723.998291 1554.476562,3723.993896 
	C1553.750977,3723.655029 1553.534912,3723.319580 1553.659424,3722.492188 
	C1554.625244,3722.232422 1555.139038,3722.614502 1555.773071,3723.573242 
z"
        />
        <path
          fill="#546D83"
          opacity="1.000000"
          stroke="none"
          d="
M446.506958,2457.441406 
	C444.144623,2459.111328 443.457367,2461.603271 440.356079,2461.719727 
	C436.781342,2449.880371 434.921448,2447.872803 423.876373,2448.112305 
	C416.180725,2448.279053 411.721313,2443.114990 406.080750,2438.758301 
	C408.550476,2433.544678 412.965820,2432.707764 417.190948,2434.158447 
	C428.352051,2437.991211 438.337708,2432.237305 449.396729,2430.573242 
	C454.863434,2430.458740 458.414307,2435.501221 464.005981,2433.997314 
	C467.195984,2433.139160 469.994751,2432.397461 469.038971,2428.283203 
	C467.944275,2423.570557 470.674072,2422.369385 474.457825,2422.086182 
	C476.111908,2421.962158 477.944366,2421.619385 479.421509,2422.145508 
	C491.166321,2426.330322 503.699982,2425.038086 515.680115,2427.519775 
	C517.614990,2427.920410 519.773193,2428.378418 521.610901,2427.926270 
	C532.654541,2425.210205 544.104492,2427.027832 555.180969,2424.670166 
	C561.401428,2423.346191 567.920288,2423.139893 574.001221,2424.622314 
	C579.037842,2425.850098 584.786438,2421.917969 589.557739,2427.456055 
	C592.038086,2431.554443 589.784363,2433.257324 586.910583,2433.279785 
	C570.294983,2433.409180 553.822205,2438.196289 538.754456,2435.166016 
	C544.624207,2436.715820 552.277222,2435.796143 560.606201,2436.188477 
	C562.280396,2436.910889 563.030823,2437.613281 563.814209,2439.283203 
	C562.157227,2444.508301 569.858093,2445.717529 567.623840,2450.750244 
	C567.171692,2451.768555 570.953552,2451.658447 572.807068,2452.019287 
	C577.071594,2452.850586 582.086426,2449.856934 585.798828,2455.267090 
	C579.191101,2461.080322 571.587280,2461.793945 563.730347,2459.574951 
	C559.284912,2458.319580 554.883850,2457.469727 549.475403,2457.526855 
	C545.134521,2456.283447 541.784973,2454.311523 537.005371,2455.049805 
	C523.936462,2455.665771 511.778564,2455.561768 499.658112,2453.050537 
	C483.481720,2449.698975 467.365540,2453.793213 451.212097,2454.226318 
	C449.504608,2454.271973 448.304901,2455.687012 446.506958,2457.441406 
z"
        />
        <path
          fill="#4B6377"
          opacity="1.000000"
          stroke="none"
          d="
M449.001160,2430.069580 
	C449.565979,2434.859619 446.420532,2436.082764 442.318146,2436.008057 
	C436.266632,2435.897705 430.345062,2436.156494 425.450439,2440.479492 
	C424.104675,2441.667969 421.968353,2442.268555 421.120361,2440.550293 
	C417.644470,2433.507080 412.470886,2436.396729 406.693390,2437.948975 
	C400.921600,2438.052002 395.687775,2437.558838 392.062805,2441.949707 
	C389.470032,2445.090332 384.820984,2438.940674 382.249939,2444.895264 
	C381.131775,2447.485107 375.436401,2446.295410 371.990509,2444.940186 
	C368.464539,2443.553955 365.511383,2445.862793 362.275085,2445.877930 
	C347.981842,2445.943115 333.687408,2445.942871 319.395142,2445.797119 
	C317.475800,2445.777588 315.566254,2444.794189 312.824371,2444.133545 
	C312.030518,2440.664795 311.195221,2438.058838 307.113556,2438.071777 
	C303.463074,2438.083496 299.812073,2438.056152 296.161835,2438.006592 
	C293.841705,2437.974854 291.116608,2438.644775 289.268463,2437.675293 
	C277.023621,2431.250000 263.914062,2434.931885 251.221786,2434.024658 
	C244.032135,2433.510986 236.742645,2434.784912 229.205566,2433.214600 
	C229.703293,2428.804199 232.950150,2428.397949 235.693298,2428.207275 
	C244.910187,2427.565674 254.243027,2428.735840 263.349487,2426.147949 
	C264.798035,2425.736328 267.291901,2425.881836 268.084503,2426.810059 
	C272.079620,2431.488770 275.190369,2427.126465 279.321808,2426.077148 
	C290.092987,2432.447266 301.285156,2430.288818 311.989166,2429.285156 
	C317.561462,2428.762695 323.128967,2428.079346 328.740479,2427.997070 
	C335.639465,2427.895752 342.522003,2428.671631 348.781860,2424.204834 
	C353.606018,2420.762695 358.932281,2423.020264 364.042633,2424.660400 
	C362.541351,2428.514648 356.743317,2429.443359 357.032684,2434.906738 
	C387.518402,2433.191895 417.633911,2428.420654 449.001160,2430.069580 
z"
        />
        <path
          fill="#4B6377"
          opacity="1.000000"
          stroke="none"
          d="
M114.573128,2483.087891 
	C112.181725,2481.750732 108.617256,2482.455811 109.116989,2479.172852 
	C109.521584,2476.514404 112.235748,2476.217285 114.600037,2476.145020 
	C117.961670,2476.042480 120.878784,2476.898193 123.176071,2479.802002 
	C120.912285,2482.609131 118.064407,2482.932617 114.573128,2483.087891 
z"
        />
        <path
          fill="#546D83"
          opacity="1.000000"
          stroke="none"
          d="
M436.073883,2461.122070 
	C434.012543,2461.787109 431.772095,2461.864502 428.691010,2461.900635 
	C430.327057,2455.767822 431.023315,2455.653076 436.073883,2461.122070 
z"
        />
        <path
          fill="#546D83"
          opacity="1.000000"
          stroke="none"
          d="
M426.954742,2454.927246 
	C427.043823,2454.880371 426.865662,2454.974365 426.954742,2454.927246 
z"
        />
        <path
          fill="#233444"
          opacity="1.000000"
          stroke="none"
          d="
M206.453735,3172.566406 
	C206.610977,3170.481445 207.245193,3168.876465 207.941940,3166.625977 
	C225.448669,3163.483154 242.531250,3158.609863 261.152588,3158.049072 
	C261.920471,3164.203125 263.444092,3168.533447 271.072479,3167.052734 
	C272.956116,3166.687256 275.034210,3167.415039 276.823792,3168.366211 
	C279.315643,3169.690674 281.325806,3171.521240 280.587341,3174.629395 
	C279.947174,3177.323242 277.485382,3178.064453 275.125488,3178.057129 
	C267.978485,3178.035156 260.867920,3176.852295 252.376053,3177.402832 
	C273.239471,3180.975586 292.589233,3176.986084 311.956299,3175.483398 
	C321.147095,3174.770508 330.414246,3173.322021 339.329254,3170.475830 
	C342.538727,3169.451416 345.825928,3168.960205 349.505432,3171.448242 
	C365.679810,3169.518066 381.710938,3170.510498 396.741669,3163.801758 
	C404.156128,3160.492432 412.756104,3159.994141 421.095337,3159.961182 
	C426.710022,3159.938965 432.376526,3160.532227 437.872223,3158.533447 
	C439.356842,3157.993408 441.233124,3157.933105 442.789307,3158.280762 
	C457.338440,3161.531494 472.166473,3161.496582 486.930695,3162.242920 
	C487.560760,3162.274902 488.172363,3162.672607 489.396301,3163.451172 
	C488.808502,3165.302002 487.297485,3165.924316 485.567963,3165.918213 
	C476.581146,3165.886475 467.593506,3165.879395 458.608337,3165.730225 
	C456.450439,3165.694580 454.009766,3163.465088 452.274994,3166.177246 
	C450.149658,3169.499756 448.118469,3172.946777 446.617157,3176.575928 
	C445.920685,3178.259766 447.654114,3179.673096 449.525726,3179.736328 
	C452.840973,3179.848877 456.329926,3180.359863 459.455566,3179.563477 
	C471.316925,3176.541260 483.371094,3177.697021 495.208008,3178.395020 
	C502.567596,3178.829102 509.728821,3182.625977 517.495239,3185.428711 
	C517.250977,3186.569336 516.568665,3187.346924 515.712891,3187.788086 
	C514.252686,3188.540527 512.390076,3188.931641 512.614807,3191.080322 
	C512.894897,3193.758545 515.093201,3193.935791 517.081360,3193.909180 
	C522.047729,3193.843262 527.076416,3194.651367 531.949097,3192.991455 
	C537.914917,3190.959473 543.060852,3192.222168 547.679810,3197.535645 
	C547.565857,3198.678467 546.910950,3199.081055 546.707520,3199.646240 
	C543.740479,3207.884766 537.299316,3208.829834 529.779419,3208.064209 
	C523.630554,3207.438232 517.363464,3208.925049 510.625244,3206.655518 
	C511.061707,3205.362061 512.182617,3204.682129 513.171265,3203.846191 
	C516.241028,3201.249268 516.398499,3198.588623 513.201355,3197.022949 
	C510.303070,3195.604004 507.275146,3194.263428 503.674133,3195.080811 
	C495.130554,3197.020508 486.062531,3197.271240 477.867798,3195.583008 
	C465.041412,3192.940918 453.580627,3195.808594 441.864868,3199.628906 
	C440.246826,3200.156738 438.570557,3199.941895 436.510010,3198.585449 
	C436.043274,3188.428467 435.991394,3188.307129 426.669952,3192.289551 
	C422.288269,3194.161621 418.046692,3195.133789 412.836731,3192.829102 
	C409.515411,3190.526367 406.374939,3190.552979 403.509552,3191.196777 
	C388.861542,3194.490967 374.489746,3193.668701 360.256378,3189.046387 
	C351.777191,3186.292480 343.019989,3186.457031 334.530884,3188.245605 
	C324.652924,3190.326904 314.686279,3190.584717 304.736115,3191.107178 
	C281.142487,3192.345947 257.786469,3195.871582 234.290390,3198.021973 
	C230.647095,3198.355713 227.051926,3199.214844 224.170380,3198.975098 
	C243.284882,3198.310059 262.898590,3200.560303 282.625946,3203.490234 
	C295.289154,3205.370850 308.510651,3203.418213 321.128693,3199.810791 
	C326.548920,3198.261230 331.804871,3195.887451 338.382751,3196.660156 
	C341.465424,3205.251465 341.483490,3205.097168 351.995667,3206.329102 
	C364.059723,3207.742432 375.733337,3205.229980 387.447998,3203.179199 
	C392.377075,3202.316162 397.216248,3200.677490 401.775604,3205.510254 
	C401.745056,3207.825928 401.040649,3209.193359 399.287811,3209.856201 
	C395.575226,3211.260498 391.821960,3212.238037 387.781311,3212.096924 
	C385.562469,3212.019531 382.569427,3211.896973 382.751129,3215.167725 
	C382.927185,3218.337402 385.939240,3217.797363 388.143616,3217.957520 
	C390.414307,3218.122803 392.763245,3217.692383 395.438293,3219.472168 
	C394.604919,3221.783447 392.552185,3222.372070 390.619385,3221.913330 
	C383.689880,3220.268799 376.739105,3221.345215 369.961823,3222.158691 
	C358.670776,3223.513672 348.682831,3218.438232 338.006104,3216.930176 
	C329.904694,3215.785889 321.595093,3215.242676 314.542999,3210.081543 
	C312.182709,3208.354492 308.916229,3208.768311 307.698181,3211.690186 
	C304.301849,3219.836426 297.094086,3219.617188 290.316101,3219.912842 
	C282.012329,3220.275635 273.680145,3220.088135 265.362213,3219.982422 
	C261.324066,3219.931152 257.398529,3220.246338 252.799561,3221.901855 
	C250.034286,3223.599365 248.027283,3223.640137 245.417786,3221.718750 
	C243.836838,3217.017090 240.985641,3215.409180 236.954605,3215.043945 
	C217.774750,3213.305908 198.767883,3211.304688 179.772141,3217.803955 
	C172.747681,3220.207275 164.351639,3222.940674 156.454681,3216.608154 
	C148.783707,3213.346924 146.179230,3213.264893 129.915344,3215.169189 
	C129.623260,3206.038330 129.835281,3206.493408 136.929871,3203.187256 
	C143.159836,3200.284424 150.506424,3200.307617 155.958405,3195.317627 
	C158.215530,3193.252441 161.690384,3193.976562 164.670029,3193.986084 
	C174.482285,3194.017090 184.298553,3193.651123 194.348801,3194.258545 
	C191.624680,3190.057373 187.640503,3188.512939 183.087723,3188.053467 
	C180.776459,3187.820312 178.411362,3187.954834 176.140839,3187.539551 
	C174.522186,3187.243408 173.016342,3186.263916 173.106674,3184.207275 
	C173.180786,3182.519775 174.666290,3182.285400 175.852295,3181.786865 
	C178.958588,3180.482178 182.178497,3179.569824 185.563110,3179.988770 
	C192.164688,3180.806641 198.606522,3178.197021 205.321655,3179.412598 
	C207.809677,3179.862793 211.047653,3178.632812 213.014160,3176.322754 
	C211.738831,3173.079834 208.588516,3174.611328 206.453735,3172.566406 
z"
        />
        <path
          fill="#102131"
          opacity="1.000000"
          stroke="none"
          d="
M245.395325,3219.372803 
	C247.096176,3221.713623 248.818085,3221.967529 251.302734,3222.014648 
	C251.838455,3225.477539 255.453705,3225.630859 256.972260,3227.611816 
	C256.455963,3230.571777 253.948486,3231.280518 251.807175,3231.581787 
	C244.607040,3232.594971 237.355637,3233.250000 230.118881,3233.993652 
	C228.138031,3234.197510 225.993591,3233.801514 224.174088,3234.418213 
	C214.572891,3237.670410 204.726395,3238.437012 194.685745,3238.059326 
	C191.833328,3237.951904 188.161652,3237.427002 189.064575,3242.454590 
	C189.432983,3244.506104 188.338303,3245.710449 186.422791,3245.783936 
	C181.427628,3245.975830 176.835815,3248.288574 171.701019,3248.053223 
	C163.453690,3247.674561 162.726212,3247.483643 159.576477,3239.566895 
	C158.043564,3235.714111 156.283859,3233.588135 151.390732,3235.077148 
	C142.333282,3237.832764 132.575287,3238.751465 125.583168,3246.197266 
	C124.041153,3247.839355 122.052086,3247.745850 120.106750,3248.091309 
	C112.942268,3249.363770 105.800919,3250.766357 98.636742,3252.040527 
	C96.608559,3252.401367 94.466095,3252.603271 94.602486,3255.313965 
	C94.723839,3257.725830 96.850090,3258.512207 98.711777,3259.051025 
	C99.883705,3259.390625 101.301254,3259.047363 102.577034,3258.827148 
	C104.924088,3258.423340 106.931175,3259.228760 106.875183,3261.574219 
	C106.810562,3264.281738 104.140190,3263.875977 102.243782,3263.954834 
	C97.220467,3264.164062 92.115936,3264.390869 88.253708,3260.314941 
	C85.571877,3257.484375 82.182007,3258.100098 78.914047,3258.027344 
	C73.594055,3257.908936 68.264374,3258.001953 62.955437,3257.706299 
	C60.410084,3257.564453 58.504147,3255.904297 58.499519,3253.201416 
	C58.495110,3250.624023 60.646263,3250.099854 62.741890,3250.051025 
	C65.857811,3249.978027 68.591782,3248.949219 71.534439,3246.562256 
	C73.017982,3244.839355 74.276443,3244.053955 76.471191,3243.442383 
	C80.173538,3243.235840 83.007812,3242.771240 85.875198,3240.117920 
	C101.204353,3237.313232 114.710571,3231.930664 127.845810,3224.920898 
	C133.210846,3222.058350 140.185730,3224.304443 147.210526,3223.953613 
	C149.491730,3223.919678 150.981277,3223.906250 153.215393,3223.874023 
	C156.330536,3223.452881 158.512512,3222.417725 161.550751,3221.627930 
	C166.934311,3220.718018 171.576019,3220.598633 176.932816,3221.985840 
	C188.440674,3227.956055 198.239197,3225.614258 208.216888,3218.275391 
	C220.994308,3214.052734 232.912918,3215.598877 245.395325,3219.372803 
z"
        />
        <path
          fill="#233444"
          opacity="1.000000"
          stroke="none"
          d="
M147.380188,3223.385742 
	C146.874359,3225.282227 145.346802,3226.148193 143.680054,3225.887695 
	C131.682983,3224.012939 122.279068,3230.159912 113.062386,3236.164062 
	C108.651649,3239.037354 104.351830,3240.378174 99.273621,3240.007568 
	C95.279144,3239.716064 91.385818,3240.277832 86.832596,3241.897461 
	C84.251221,3244.412354 81.066254,3243.520020 78.463303,3245.529297 
	C76.489098,3245.979004 74.992271,3245.977783 72.747032,3245.986816 
	C63.332466,3243.268311 54.415989,3240.316650 45.664307,3244.520264 
	C36.975227,3248.694092 28.276209,3245.038574 18.760590,3245.932617 
	C18.892179,3241.410645 22.502031,3242.209473 25.675068,3242.031738 
	C31.475023,3241.707520 37.271381,3242.569824 43.081905,3240.705566 
	C47.605061,3239.254883 47.626736,3236.966309 45.993958,3232.812012 
	C48.136738,3231.647461 50.888828,3230.116943 52.574913,3230.895264 
	C59.264236,3233.983154 63.938931,3229.630371 69.173401,3227.255127 
	C70.917809,3226.463623 73.404831,3225.764893 72.901558,3223.298096 
	C72.331833,3220.506348 69.848793,3219.852295 67.390717,3220.617676 
	C58.888172,3223.265869 50.192276,3221.698242 41.595615,3221.891846 
	C34.912621,3222.042725 34.904629,3221.688232 34.049706,3212.737305 
	C39.644806,3208.199951 45.860897,3207.556641 53.148399,3207.977539 
	C54.934822,3208.919434 56.018269,3210.337158 57.199905,3210.104736 
	C67.716248,3208.038330 78.598106,3208.299561 89.429932,3204.614746 
	C95.448486,3203.981445 101.074310,3202.928467 104.250275,3209.639893 
	C105.953941,3213.239990 110.108788,3211.708008 113.250214,3212.021484 
	C115.971596,3212.293213 117.733307,3213.145508 116.839882,3216.320068 
	C115.462776,3221.213135 118.432594,3221.825928 122.201576,3222.002930 
	C130.384430,3222.387451 138.626724,3221.031006 147.380188,3223.385742 
z"
        />
        <path
          fill="#2C2520"
          opacity="1.000000"
          stroke="none"
          d="
M52.081230,3894.291992 
	C53.742989,3892.706055 55.533180,3891.875977 57.722641,3890.587402 
	C59.963623,3889.467773 61.768959,3888.667969 63.654419,3888.173828 
	C67.902260,3887.061279 66.959373,3894.159424 71.019516,3893.053955 
	C73.882507,3892.273926 75.301147,3888.859375 78.439072,3888.341553 
	C79.012772,3888.246826 79.752274,3888.477051 80.258736,3888.801758 
	C81.234795,3889.426514 81.391502,3890.573486 80.803185,3891.386230 
	C79.657318,3892.968994 78.289352,3894.398926 76.947495,3895.830322 
	C75.009605,3897.897705 72.337372,3899.532959 72.612892,3903.473633 
	C78.934669,3903.448730 81.936783,3898.850342 85.760231,3895.545654 
	C88.327644,3893.326660 91.032501,3888.079590 94.288696,3890.988770 
	C97.504623,3893.861816 92.725822,3897.129395 90.675179,3899.845947 
	C88.519844,3902.701172 85.057739,3904.594482 84.280182,3909.193359 
	C78.287575,3913.535156 76.384315,3917.662842 78.736855,3925.215332 
	C82.284821,3936.605225 67.091331,3958.230713 55.181446,3960.912109 
	C52.355446,3961.548096 50.069500,3960.748535 47.632957,3959.377930 
	C38.627567,3954.311768 35.268173,3955.535400 31.540815,3965.048096 
	C30.696110,3967.203369 30.428604,3969.587402 28.370619,3971.883057 
	C25.735262,3971.831787 24.409668,3970.689453 24.142551,3967.749512 
	C24.480476,3962.316650 25.657537,3957.884521 28.828951,3954.279541 
	C30.680372,3952.175537 31.833757,3950.304443 30.779470,3947.211670 
	C29.506086,3943.475830 29.807301,3940.299316 35.300621,3942.462402 
	C35.660740,3942.604248 36.634892,3942.034912 36.848896,3941.575439 
	C42.230503,3930.018799 52.026714,3920.957031 54.747829,3907.710693 
	C55.836483,3902.411133 54.791985,3898.922363 52.081230,3894.291992 
z"
        />
        <path
          fill="#597288"
          opacity="1.000000"
          stroke="none"
          d="
M2758.662354,2660.298828 
	C2758.988770,2651.636719 2759.580811,2651.099365 2766.864502,2652.606445 
	C2775.510986,2654.394775 2784.146729,2656.241211 2792.761963,2658.173828 
	C2799.050537,2659.584473 2804.978760,2658.958252 2810.490967,2655.505371 
	C2813.847168,2653.403564 2817.318359,2653.632568 2821.443359,2655.311035 
	C2825.832031,2657.918701 2830.468750,2658.332275 2832.707520,2663.324219 
	C2835.257812,2669.010986 2843.098145,2663.476318 2846.190430,2669.365234 
	C2846.296143,2672.889648 2848.211182,2673.667480 2850.751465,2674.158691 
	C2858.440186,2675.645996 2866.086182,2677.353027 2874.189697,2679.067627 
	C2870.542236,2680.721436 2867.171387,2679.709229 2863.912354,2680.015381 
	C2861.927734,2680.202148 2859.637451,2679.997559 2858.624756,2682.051758 
	C2855.786377,2687.808838 2850.591797,2686.498291 2846.415527,2685.472656 
	C2836.337402,2682.997803 2825.899170,2684.868652 2815.839355,2682.176758 
	C2812.214600,2681.207031 2807.821289,2681.221191 2805.051270,2685.333740 
	C2804.369629,2686.345459 2802.927979,2687.528076 2801.867920,2687.497803 
	C2789.148926,2687.136230 2777.572266,2693.174316 2765.079834,2693.911133 
	C2762.777100,2694.047119 2760.344971,2694.228516 2758.498779,2692.372559 
	C2755.294678,2689.150879 2752.613770,2690.128174 2750.254883,2693.369141 
	C2748.822754,2695.336914 2747.111084,2695.069580 2744.990479,2694.696289 
	C2738.724609,2693.593018 2732.676758,2693.334229 2726.782715,2697.095947 
	C2722.829102,2699.619141 2717.547852,2696.912598 2712.576172,2699.465332 
	C2707.305420,2698.749756 2702.589111,2697.644287 2696.838867,2697.987793 
	C2687.526367,2701.132324 2679.263672,2696.257568 2670.782715,2697.303223 
	C2666.566162,2697.823242 2662.226562,2697.428223 2657.164062,2697.250977 
	C2652.933350,2696.776367 2649.510742,2696.453369 2646.720703,2700.467773 
	C2643.674072,2701.942383 2641.341309,2701.343994 2638.393311,2700.073730 
	C2632.445068,2695.023682 2627.871338,2696.628662 2622.970459,2701.726074 
	C2608.509033,2700.952393 2594.835449,2704.538818 2580.253174,2702.791504 
	C2572.720215,2700.671631 2566.170410,2702.356689 2558.843506,2703.849121 
	C2554.863281,2704.510010 2551.854980,2703.911377 2548.450928,2701.792969 
	C2545.351807,2699.244873 2545.167725,2694.956299 2540.456543,2694.005615 
	C2516.867676,2691.799561 2494.037842,2686.716309 2470.373047,2692.939453 
	C2468.403320,2694.493652 2466.921875,2695.260986 2464.563965,2696.157715 
	C2459.246338,2697.317139 2454.883057,2697.146973 2450.253174,2694.113281 
	C2446.364258,2686.920654 2440.972412,2685.983887 2433.767578,2688.272949 
	C2428.136475,2689.327148 2423.184082,2689.262207 2417.519531,2688.786133 
	C2414.600098,2688.361572 2412.399902,2688.178467 2409.682861,2689.574707 
	C2407.247559,2690.463135 2405.382324,2690.735107 2402.761475,2690.890137 
	C2401.040039,2690.919434 2400.050781,2690.956543 2398.312012,2691.128418 
	C2392.496094,2692.827393 2387.332275,2693.253662 2381.297852,2692.441895 
	C2377.582275,2691.237061 2374.518311,2690.775879 2370.680176,2690.267334 
	C2369.493164,2689.813477 2369.045410,2689.576660 2368.055176,2688.822998 
	C2366.577881,2679.396240 2373.568115,2681.103760 2377.986572,2681.429443 
	C2384.304688,2681.895020 2387.766602,2679.774658 2389.767090,2673.306396 
	C2396.962402,2669.223633 2403.779785,2671.752441 2411.313965,2672.249023 
	C2414.282471,2672.663818 2416.011230,2671.468994 2418.578613,2670.305664 
	C2427.443359,2669.199219 2435.400146,2670.755127 2444.138184,2671.907227 
	C2448.357666,2673.962646 2449.822266,2678.638428 2455.169922,2678.827637 
	C2462.163330,2678.492188 2468.410645,2678.955811 2475.434570,2678.492188 
	C2478.497070,2678.588867 2480.276123,2677.233154 2483.061035,2676.224121 
	C2504.131592,2676.038086 2524.195801,2679.563232 2545.241211,2679.565918 
	C2549.734619,2680.813477 2550.153076,2685.334473 2554.133301,2686.969727 
	C2557.992920,2687.711670 2560.921631,2689.497803 2564.993164,2689.051758 
	C2569.280762,2688.985596 2571.641357,2686.553467 2574.947021,2684.256836 
	C2578.172852,2682.948486 2580.921631,2682.265381 2583.479492,2682.749023 
	C2610.980225,2687.949707 2637.599609,2680.776367 2664.481201,2677.229980 
	C2668.431885,2676.708740 2672.439941,2677.056396 2676.431152,2676.526611 
	C2654.161377,2680.284668 2632.032471,2676.573486 2609.854980,2676.232910 
	C2592.360840,2675.964844 2574.651611,2677.220947 2557.039062,2677.724365 
	C2538.467773,2678.255127 2519.800781,2679.697998 2501.507568,2674.819092 
	C2490.807617,2671.965576 2479.490479,2675.427246 2468.217285,2670.974854 
	C2466.444336,2668.867432 2466.242676,2667.144531 2467.009277,2664.502441 
	C2480.249268,2658.378906 2493.556152,2661.217529 2506.658447,2661.916260 
	C2510.697021,2662.131348 2514.178711,2661.765381 2518.133301,2660.993164 
	C2534.404297,2657.814697 2550.870361,2655.999268 2567.416260,2659.468506 
	C2586.701416,2663.511963 2606.032959,2666.855225 2625.746094,2662.522949 
	C2629.410645,2661.717529 2632.987305,2661.509277 2637.417725,2662.843994 
	C2639.358887,2663.071289 2640.461914,2663.156982 2642.356201,2663.364014 
	C2646.089111,2664.632080 2649.051025,2665.224365 2652.855225,2663.626953 
	C2661.710449,2662.876953 2669.649170,2660.615723 2678.647705,2661.494629 
	C2687.523682,2664.487793 2695.589355,2667.628662 2704.687012,2662.016113 
	C2716.944580,2659.130127 2728.547852,2660.256104 2739.951416,2661.496826 
	C2747.839600,2662.354980 2755.675781,2662.977051 2764.375000,2663.122070 
	C2770.710693,2663.635742 2776.163086,2665.460938 2782.641846,2663.922607 
	C2775.718262,2663.007812 2769.897461,2659.863281 2762.638184,2661.557861 
	C2760.946777,2661.536865 2760.021484,2661.280029 2758.662354,2660.298828 
z"
        />
        <path
          fill="#61798F"
          opacity="1.000000"
          stroke="none"
          d="
M2846.886719,2670.018066 
	C2843.854248,2667.235107 2841.407715,2668.907227 2838.845215,2669.295898 
	C2833.332764,2670.132812 2831.298096,2668.625732 2829.316895,2662.457031 
	C2828.235352,2659.089111 2824.709473,2659.050293 2822.391113,2656.380615 
	C2829.010254,2655.852539 2836.012695,2655.789307 2843.480957,2656.580566 
	C2847.387451,2652.902832 2851.154053,2654.437988 2855.649902,2655.430176 
	C2859.563232,2657.076660 2862.810303,2657.347412 2867.027344,2656.613525 
	C2897.137695,2656.111816 2926.174316,2658.616699 2955.100342,2662.655273 
	C2957.702393,2663.018555 2960.436523,2663.036865 2963.312744,2665.414551 
	C2967.139893,2673.576660 2974.249023,2673.194580 2980.494873,2673.861328 
	C2986.322510,2674.483643 2992.280273,2673.793701 2998.145264,2674.196289 
	C3001.634033,2674.435791 3005.835449,2674.950439 3003.997070,2681.179199 
	C3003.075684,2681.812012 3002.232178,2681.798828 3000.757324,2681.723145 
	C2998.563965,2680.497559 2996.920166,2679.866699 2994.445068,2681.257324 
	C2985.316406,2679.505371 2976.617432,2679.869629 2967.022217,2680.101074 
	C2961.180176,2677.314209 2955.927979,2677.434082 2950.685059,2678.792725 
	C2945.031006,2680.258057 2939.680908,2681.405518 2935.382812,2675.766602 
	C2933.549805,2673.361816 2930.517334,2673.954102 2927.929932,2674.080078 
	C2907.044678,2675.098389 2886.346680,2670.758545 2865.455322,2671.959961 
	C2859.471191,2672.304443 2853.827148,2669.180908 2846.886719,2670.018066 
z"
        />
        <path
          fill="#172B3E"
          opacity="1.000000"
          stroke="none"
          d="
M2855.365234,3187.578125 
	C2855.953369,3191.158203 2855.460205,3193.762939 2851.845215,3193.887939 
	C2847.867920,3194.025635 2843.885010,3194.318604 2839.964844,3193.024658 
	C2834.781250,3191.313232 2829.434326,3191.756836 2824.152588,3192.466309 
	C2822.517334,3192.686035 2821.015137,3193.749512 2821.113770,3195.797119 
	C2821.193604,3197.450928 2822.622070,3197.861816 2823.869385,3198.179199 
	C2829.005859,3199.487061 2834.111084,3201.315918 2839.331543,3201.777832 
	C2844.086426,3202.198242 2848.354980,3203.381348 2852.739502,3205.106201 
	C2856.784668,3206.697510 2861.237305,3209.738281 2865.481934,3204.942383 
	C2866.345947,3203.966309 2868.059082,3204.150391 2869.264648,3204.792725 
	C2875.541016,3208.139648 2881.989502,3206.690918 2888.604248,3206.139648 
	C2901.163818,3205.093506 2909.217529,3207.215332 2916.790039,3212.366943 
	C2912.679199,3216.438965 2905.953857,3211.179932 2902.179688,3217.199463 
	C2900.574951,3219.584717 2898.717285,3220.117188 2895.930664,3220.046631 
	C2890.343506,3219.090088 2884.317627,3212.525635 2880.981445,3222.976807 
	C2867.740723,3224.440674 2855.739502,3221.581055 2844.149658,3217.493408 
	C2828.083496,3211.826904 2812.226562,3209.154541 2795.682129,3215.451660 
	C2788.848145,3218.052979 2781.146729,3216.348633 2773.337402,3218.806641 
	C2770.330322,3218.974854 2768.147461,3219.092041 2765.197266,3218.612305 
	C2759.985107,3216.486084 2755.231201,3217.433838 2750.625488,3217.032471 
	C2746.288086,3216.654297 2742.009277,3215.997803 2737.947021,3214.429688 
	C2734.839355,3213.230225 2731.101074,3211.890869 2731.399170,3207.896973 
	C2731.718506,3203.619385 2735.822021,3203.216797 2739.059814,3203.152832 
	C2747.315430,3202.990234 2755.646484,3202.076416 2763.781738,3204.166748 
	C2738.237793,3198.162354 2712.663574,3197.552246 2686.288330,3204.229004 
	C2674.314941,3206.777100 2663.872314,3202.809570 2653.317383,3200.268799 
	C2638.533203,3196.709961 2622.977539,3197.593018 2607.741211,3191.906006 
	C2601.761475,3186.483154 2602.368652,3181.258301 2607.112305,3175.432129 
	C2620.455322,3170.309326 2629.759521,3179.555908 2640.378662,3182.903809 
	C2635.703857,3179.137695 2634.204834,3176.832764 2635.561523,3174.639404 
	C2637.612305,3171.323730 2641.052979,3171.333008 2644.433350,3171.641357 
	C2646.435303,3171.823730 2648.291260,3172.583252 2650.064209,3173.522461 
	C2658.375732,3177.924805 2667.045410,3176.893799 2676.113525,3176.664307 
	C2689.637451,3176.322754 2703.387451,3172.130615 2716.863770,3177.352051 
	C2719.829590,3178.501221 2722.813232,3177.550781 2725.577393,3176.414795 
	C2731.453857,3174.000732 2737.536133,3172.719482 2745.073975,3173.092041 
	C2740.718750,3170.311035 2736.949463,3169.007812 2733.764648,3165.505371 
	C2731.377441,3163.292480 2729.016357,3163.224121 2726.742432,3162.764160 
	C2722.595215,3161.925537 2718.728027,3160.221924 2719.088623,3155.221924 
	C2719.438477,3150.371338 2722.891113,3147.413086 2727.451904,3146.544922 
	C2733.057373,3145.477783 2738.758789,3146.341064 2744.230713,3147.691162 
	C2776.732178,3155.709229 2809.501953,3162.447754 2842.391357,3168.637207 
	C2848.021729,3169.696533 2854.003662,3170.491699 2853.113037,3179.300781 
	C2843.664307,3186.482910 2835.022461,3180.511475 2825.504883,3178.865967 
	C2822.778809,3178.080078 2820.913818,3176.776123 2818.043457,3177.437012 
	C2816.237549,3177.860352 2815.015625,3177.905518 2813.191895,3177.604248 
	C2810.759766,3176.734131 2808.961914,3175.815430 2806.235840,3176.311035 
	C2800.876465,3177.202148 2796.520508,3175.176025 2791.286621,3174.514893 
	C2784.330322,3172.958984 2777.916016,3173.916504 2771.211426,3173.382812 
	C2770.822021,3173.531250 2770.387207,3173.845459 2770.387207,3173.845459 
	C2770.387207,3173.845459 2771.016113,3174.021484 2771.553711,3173.927246 
	C2777.825684,3174.657227 2783.601562,3174.902100 2790.189697,3175.093262 
	C2808.246582,3179.590820 2826.325439,3178.837646 2843.558838,3183.458740 
	C2847.361084,3184.478271 2851.449219,3184.514160 2855.365234,3187.578125 
z"
        />
        <path
          fill="#172B3E"
          opacity="1.000000"
          stroke="none"
          d="
M2877.280273,3161.988281 
	C2878.913086,3161.992920 2879.779297,3162.016113 2881.292236,3162.102783 
	C2882.250977,3165.590332 2884.248291,3167.376465 2887.484375,3168.191162 
	C2889.445557,3168.685059 2891.707764,3169.837158 2890.931152,3172.110107 
	C2890.008301,3174.809814 2886.897461,3176.544189 2884.662598,3174.795654 
	C2879.635986,3170.863037 2875.529785,3172.859619 2870.535156,3175.819580 
	C2869.060059,3176.926270 2868.070312,3177.366943 2866.099609,3177.356934 
	C2856.660400,3170.239014 2846.177002,3168.560303 2835.671875,3167.117920 
	C2826.139160,3165.808594 2816.860107,3163.436768 2807.658691,3160.771240 
	C2802.843994,3159.376221 2798.125000,3157.451172 2796.794922,3150.901367 
	C2796.858398,3147.087891 2800.420166,3146.444092 2801.316162,3143.515381 
	C2786.471191,3139.265137 2771.673340,3136.222168 2756.147949,3137.063477 
	C2742.807373,3137.786133 2729.477051,3137.445801 2717.631348,3129.712402 
	C2713.824707,3127.227051 2709.918213,3129.054688 2706.046875,3129.824707 
	C2702.418457,3130.546387 2698.348145,3131.361816 2695.973877,3127.788086 
	C2693.053467,3123.391846 2698.369629,3121.676270 2700.295654,3118.132812 
	C2703.992432,3115.045166 2707.583740,3115.626953 2709.339111,3115.966064 
	C2700.718506,3113.357178 2690.074951,3111.306396 2679.325439,3109.530762 
	C2672.542236,3108.410400 2665.819580,3106.012695 2660.680908,3099.683105 
	C2659.718262,3092.673340 2651.207764,3091.018066 2651.707764,3083.467285 
	C2658.395996,3075.676025 2665.876709,3076.816162 2673.896240,3079.311768 
	C2689.272217,3084.097168 2704.603760,3089.152588 2721.431641,3091.480469 
	C2727.734131,3092.203613 2733.405762,3091.211426 2738.848633,3093.122559 
	C2741.590332,3094.084961 2743.996338,3092.589355 2747.297852,3091.940430 
	C2750.459717,3094.713379 2753.901855,3094.766846 2757.833252,3095.306396 
	C2759.357422,3095.490479 2760.226074,3095.656738 2761.745361,3096.047119 
	C2764.088135,3096.933105 2765.621094,3098.089600 2768.294678,3097.974609 
	C2775.080078,3097.641357 2780.011230,3101.322021 2786.270752,3102.940430 
	C2800.559082,3111.156250 2814.838379,3116.922363 2830.925293,3117.462646 
	C2834.956543,3117.597900 2838.842529,3117.927246 2843.322021,3116.187012 
	C2845.323486,3116.110840 2846.702637,3116.023193 2847.965820,3116.364258 
	C2857.930420,3119.056152 2857.954102,3119.142822 2854.029053,3129.271973 
	C2842.099854,3130.008301 2830.144043,3130.587402 2818.490967,3127.235107 
	C2816.857910,3126.765625 2815.082764,3126.326660 2814.603027,3128.656738 
	C2814.174316,3130.738770 2815.762451,3131.525635 2817.397705,3132.064941 
	C2822.740967,3133.826904 2828.118652,3135.479736 2833.751953,3136.012451 
	C2836.052490,3136.229980 2839.338135,3135.677246 2840.115723,3137.883545 
	C2843.220703,3146.694824 2851.387451,3145.884766 2858.012695,3148.073242 
	C2858.924072,3148.374268 2859.778809,3148.848145 2862.372070,3150.007568 
	C2853.598633,3152.549805 2846.572021,3155.302002 2838.493164,3150.598145 
	C2836.744141,3148.405518 2834.833496,3148.079590 2832.987793,3147.614014 
	C2831.793701,3147.312988 2830.682129,3146.727295 2829.442139,3146.582520 
	C2830.618652,3147.042969 2831.826660,3147.459717 2832.211426,3149.363525 
	C2830.326904,3150.356689 2828.177979,3151.022461 2829.449951,3153.026367 
	C2831.441162,3156.163086 2834.297119,3158.488281 2838.546631,3158.010254 
	C2843.366211,3157.468018 2847.672363,3158.608643 2851.622314,3161.652344 
	C2854.006592,3163.489990 2856.930176,3164.505859 2860.066162,3162.994385 
	C2860.654541,3162.710693 2861.382568,3162.715576 2861.967285,3162.427002 
	C2866.017822,3160.430176 2865.735352,3169.439697 2869.204346,3165.800537 
	C2871.519287,3163.372070 2873.276855,3161.799805 2877.280273,3161.988281 
z"
        />
        <path
          fill="#2B3E51"
          opacity="1.000000"
          stroke="none"
          d="
M2771.502930,3216.872559 
	C2782.970459,3213.512939 2794.910889,3214.549805 2805.396729,3208.716064 
	C2807.232178,3207.694580 2809.980713,3207.784180 2812.208496,3208.087891 
	C2822.507568,3209.490723 2832.894775,3209.311279 2842.948242,3213.593018 
	C2852.229492,3217.546143 2862.286377,3220.300781 2872.738770,3220.010986 
	C2875.391113,3219.937500 2878.043457,3219.982666 2881.214844,3221.636475 
	C2884.914307,3219.075684 2888.670410,3218.316895 2893.296875,3217.907715 
	C2896.141357,3217.890137 2898.264404,3217.916748 2901.182861,3217.941406 
	C2904.212158,3220.943359 2908.895752,3219.942383 2910.909668,3223.497559 
	C2910.478271,3227.206787 2906.550537,3225.237061 2904.453613,3227.505859 
	C2903.693848,3228.324951 2903.387695,3228.650146 2902.536133,3229.365479 
	C2898.734619,3230.865234 2894.980469,3230.950439 2892.166992,3234.563965 
	C2888.684082,3235.500488 2885.894775,3234.716064 2882.381836,3234.254883 
	C2873.759521,3230.894043 2865.438232,3230.913086 2857.085938,3231.001709 
	C2848.816162,3231.089111 2840.246094,3232.203125 2835.299805,3222.801758 
	C2834.199463,3220.709961 2830.980469,3220.047363 2828.275879,3219.538818 
	C2835.530518,3228.455566 2835.323486,3233.281494 2827.039062,3240.458740 
	C2831.002930,3243.412598 2835.804443,3243.448486 2840.805664,3244.986572 
	C2844.819824,3245.225342 2848.104980,3245.325684 2850.889160,3248.816406 
	C2851.549561,3250.689453 2851.543213,3251.809326 2850.863525,3253.678223 
	C2847.227051,3257.041748 2843.722168,3256.398682 2840.110107,3255.406982 
	C2828.412354,3252.196289 2816.621094,3249.850830 2805.549316,3244.034668 
	C2798.249268,3240.198975 2789.091797,3239.658447 2780.553467,3238.909668 
	C2774.762695,3238.401855 2769.478760,3237.128906 2765.403809,3231.598633 
	C2765.391846,3225.894775 2769.305420,3226.565674 2772.467773,3226.190430 
	C2783.035156,3224.936523 2793.610107,3224.095703 2804.195801,3226.266357 
	C2796.597900,3222.888184 2788.982178,3220.526123 2780.460449,3222.151123 
	C2776.767090,3222.855225 2772.123047,3223.228516 2771.502930,3216.872559 
z"
        />
        <path
          fill="#374D60"
          opacity="1.000000"
          stroke="none"
          d="
M2865.545898,3176.513672 
	C2866.944092,3176.119385 2867.787598,3176.115234 2869.261719,3176.120605 
	C2874.461670,3180.821533 2880.860840,3180.304443 2886.518555,3181.818359 
	C2887.937256,3182.197998 2890.209229,3181.829102 2891.229004,3180.879883 
	C2901.016846,3171.775391 2912.941406,3175.150391 2923.278076,3177.180664 
	C2946.017822,3181.647217 2968.904541,3184.804199 2991.809570,3187.995850 
	C2996.326904,3188.625244 3000.241699,3192.129639 3005.269531,3190.502197 
	C3006.536133,3190.092041 3007.622559,3191.693115 3007.505127,3193.142334 
	C3007.379395,3194.696777 3006.080811,3195.597168 3004.712402,3195.059814 
	C3000.295898,3193.325928 2995.531006,3194.188232 2990.987549,3192.832520 
	C2987.609619,3191.824951 2983.349365,3189.901123 2980.402832,3194.833984 
	C2979.085449,3197.039551 2976.059570,3196.221436 2973.927490,3195.551758 
	C2970.381348,3194.438232 2966.781250,3194.301270 2963.152100,3193.889648 
	C2956.590820,3193.144775 2950.171387,3193.298340 2943.562500,3193.796387 
	C2933.457764,3194.558105 2924.028564,3190.977783 2915.187256,3186.097168 
	C2913.456787,3185.141602 2911.689209,3184.253418 2909.946777,3183.318604 
	C2908.270752,3182.419189 2906.618896,3182.503906 2905.538330,3184.072998 
	C2903.784424,3186.619385 2905.917236,3187.432373 2907.696045,3188.404785 
	C2908.206543,3188.683594 2908.489502,3189.378906 2909.306885,3190.443359 
	C2902.755127,3193.099609 2896.406006,3192.166748 2890.012207,3191.004639 
	C2884.506836,3190.003662 2878.902588,3188.397949 2873.759521,3192.217041 
	C2871.673340,3193.766113 2869.916016,3192.945557 2868.515137,3191.392334 
	C2865.466309,3188.011963 2861.586426,3187.824463 2856.701172,3188.194336 
	C2846.301270,3187.769043 2836.893066,3186.187256 2827.636719,3183.608643 
	C2825.108154,3182.904053 2822.452148,3182.033691 2819.897705,3182.154297 
	C2810.177979,3182.612305 2800.818848,3181.052734 2790.848633,3177.986816 
	C2788.851074,3176.629883 2788.546631,3175.096924 2789.530762,3172.732422 
	C2795.616699,3171.424561 2800.734375,3174.753418 2807.166016,3173.983154 
	C2809.168457,3174.024414 2810.241211,3174.349609 2811.639648,3175.404297 
	C2813.555420,3175.787842 2815.014160,3175.828613 2817.202637,3175.852051 
	C2818.766602,3175.165527 2819.711182,3174.919922 2821.395020,3175.523438 
	C2826.013916,3175.925293 2829.957275,3176.006348 2833.739258,3177.649658 
	C2839.021973,3179.945801 2844.644287,3180.289307 2851.196533,3180.137207 
	C2856.044678,3177.736572 2861.128906,3179.752441 2865.545898,3176.513672 
z"
        />
        <path
          fill="#172B3E"
          opacity="1.000000"
          stroke="none"
          d="
M2881.583984,3232.523682 
	C2884.933350,3232.027832 2887.937256,3231.768066 2891.305664,3233.677246 
	C2892.495117,3233.440674 2893.040527,3232.944580 2894.098633,3232.224609 
	C2895.562012,3231.548340 2896.583496,3231.227783 2897.994141,3230.491943 
	C2899.335938,3229.661133 2900.257080,3229.153076 2901.602539,3228.343262 
	C2902.009766,3228.009766 2903.004883,3228.002441 2903.502441,3228.001221 
	C2904.518066,3228.981201 2905.036133,3229.962402 2905.775391,3231.452393 
	C2906.270020,3236.008545 2908.705078,3237.581787 2912.486328,3238.119873 
	C2923.716064,3239.718506 2934.156982,3243.997559 2944.697510,3247.912598 
	C2947.070557,3248.793945 2949.881592,3249.052979 2951.019043,3252.791992 
	C2944.872070,3254.714111 2939.195312,3252.405518 2934.046875,3250.644531 
	C2928.103516,3248.611816 2923.304688,3247.312500 2917.179199,3250.806396 
	C2911.438721,3254.080078 2904.903809,3251.787109 2898.497070,3248.577637 
	C2895.334229,3244.457764 2891.150879,3243.094238 2887.599121,3240.820312 
	C2884.644287,3238.928467 2880.756836,3237.798584 2881.583984,3232.523682 
z"
        />
        <path
          fill="#0C1A26"
          opacity="1.000000"
          stroke="none"
          d="
M275.347961,3495.600098 
	C276.891174,3495.981934 277.777130,3495.983398 279.327545,3495.977539 
	C280.789032,3495.110840 281.691772,3494.413330 283.428009,3494.062012 
	C284.884613,3494.017578 285.764771,3494.022705 287.304993,3494.028564 
	C303.473999,3495.916992 318.591309,3495.001953 332.661133,3487.390381 
	C336.732666,3485.187500 340.571808,3482.581543 345.001587,3481.048828 
	C346.779846,3480.433350 349.258942,3479.865723 348.902740,3477.359619 
	C348.575623,3475.057861 346.078735,3474.846436 344.242523,3474.295654 
	C335.880096,3471.787598 327.567719,3470.548340 319.250305,3474.729736 
	C315.244934,3476.743164 311.063843,3477.725830 307.826843,3472.681885 
	C310.699768,3468.930908 314.490723,3467.702148 318.107910,3466.279541 
	C330.218018,3461.517334 342.378784,3456.884277 355.259247,3452.105957 
	C364.823914,3452.601318 373.443451,3455.426514 382.452759,3454.253906 
	C384.700439,3453.961426 387.085358,3454.520020 389.337311,3453.857178 
	C391.842010,3453.120361 394.934753,3452.643555 395.106415,3449.356934 
	C395.251556,3446.577637 392.576599,3445.812988 390.458008,3444.913818 
	C389.237122,3444.395996 388.034302,3443.863770 386.501709,3442.528809 
	C383.990875,3440.212646 384.732208,3438.892578 386.767670,3437.702393 
	C390.063416,3435.775879 394.153717,3435.657959 397.597473,3432.562988 
	C399.620972,3429.747314 401.941772,3428.462402 404.425293,3427.450195 
	C448.352753,3409.543457 492.432953,3392.013672 536.315369,3373.996582 
	C548.353882,3369.053711 560.498718,3366.721924 573.657410,3372.208252 
	C574.718994,3367.760498 568.720764,3364.901367 572.295471,3361.008301 
	C575.100647,3357.952637 578.599243,3355.978516 582.352966,3354.764404 
	C593.959167,3351.010010 604.263123,3343.495117 617.573242,3343.104492 
	C619.720764,3350.161865 621.076233,3356.452881 625.067261,3362.824463 
	C632.227356,3374.255859 624.782654,3386.936279 611.424316,3389.250244 
	C608.863098,3389.693848 606.096313,3389.536865 603.357666,3391.875488 
	C607.687622,3393.652588 611.988464,3392.824463 615.990051,3393.641846 
	C627.807190,3396.055908 633.881653,3403.108887 631.991577,3414.800293 
	C630.562500,3423.639648 633.699951,3429.152588 640.010559,3434.649414 
	C651.084229,3444.295410 660.202576,3455.710693 666.704651,3468.982178 
	C668.607971,3472.866943 670.828491,3475.839355 675.474854,3475.931885 
	C687.924072,3476.179932 698.400024,3480.976074 708.095764,3488.671143 
	C712.662720,3492.295654 718.575195,3494.485596 725.431519,3490.928711 
	C733.325745,3488.341797 739.948242,3494.080322 747.921692,3492.065186 
	C750.359131,3491.953613 751.890930,3492.348389 753.655029,3493.993652 
	C753.622559,3499.016357 754.677979,3502.397461 758.885193,3504.684814 
	C762.062988,3506.412598 761.657532,3508.617432 758.699341,3510.780029 
	C755.907288,3512.820801 753.118469,3514.222168 749.555481,3514.024170 
	C743.713257,3513.699463 738.296021,3515.590088 733.438721,3518.485107 
	C729.905029,3520.591309 726.591797,3522.395264 722.349426,3522.089355 
	C719.009583,3521.848145 716.918518,3524.173340 714.727783,3526.246582 
	C709.744873,3530.962158 703.928284,3533.550781 696.471436,3532.458008 
	C696.899231,3524.966309 703.603699,3523.593994 708.374390,3519.019043 
	C704.351501,3518.668213 701.225098,3519.949219 697.990051,3520.707764 
	C681.320374,3524.616455 665.190979,3523.708008 650.088928,3514.834473 
	C647.471680,3513.296387 644.830322,3511.726807 641.750977,3512.215576 
	C631.639832,3513.820068 621.732727,3516.471924 611.658081,3518.302979 
	C609.385315,3518.716064 607.715210,3520.633789 606.391113,3523.488281 
	C604.806030,3525.358398 603.278870,3526.034912 601.558167,3525.947021 
	C588.027710,3525.252930 574.218750,3528.281494 561.038391,3522.746582 
	C557.586548,3521.296875 553.781189,3522.101074 550.134033,3522.004395 
	C544.822998,3521.863281 540.645813,3520.108154 537.550964,3515.367920 
	C532.567383,3507.734619 524.705139,3504.028076 516.183167,3502.108154 
	C512.765503,3501.338135 510.219482,3499.308350 507.169861,3498.082520 
	C494.311279,3492.913818 480.720093,3493.071289 467.286652,3492.018066 
	C464.305023,3491.784180 461.247192,3492.100342 458.331421,3491.572266 
	C455.631531,3491.083740 453.243774,3489.305176 452.898926,3486.398682 
	C452.528534,3483.276611 455.133331,3480.732178 457.502502,3480.756836 
	C467.081543,3480.856445 470.882050,3473.175293 476.545074,3467.859375 
	C478.646942,3465.886230 479.642609,3463.863037 480.041351,3461.044434 
	C481.375763,3451.612305 482.863922,3450.114014 491.843567,3447.942383 
	C492.806274,3447.709473 493.820282,3447.609619 494.726379,3447.241211 
	C496.012604,3446.718262 496.982941,3445.718994 496.853760,3444.253906 
	C496.709015,3442.612549 495.300476,3442.261719 493.958954,3442.129150 
	C483.123138,3441.060303 472.227875,3439.452148 461.508240,3442.567627 
	C455.866882,3444.207275 450.430176,3444.628174 444.878357,3442.612549 
	C444.567383,3442.499756 444.238525,3442.425537 443.911865,3442.368896 
	C437.437653,3441.243652 430.494202,3443.127686 430.769409,3446.314941 
	C431.251617,3451.899414 436.305878,3452.848633 440.770844,3454.159180 
	C445.284088,3455.484131 449.809296,3456.209717 454.529266,3456.068359 
	C458.078064,3455.961914 461.610077,3456.769531 461.487976,3461.250244 
	C461.384460,3465.049561 458.102570,3466.515625 455.042877,3465.840820 
	C443.501923,3463.294678 433.872803,3467.678955 423.921570,3472.639893 
	C419.083588,3475.051758 413.020721,3475.276123 407.446289,3475.859375 
	C402.648590,3476.361328 399.788971,3478.238037 400.066223,3483.236084 
	C400.363007,3488.586426 397.418335,3489.474854 392.843201,3490.387695 
	C380.822479,3492.786865 368.968811,3496.023193 356.524841,3499.453125 
	C355.111389,3499.983398 354.221710,3499.986572 352.664795,3499.991211 
	C345.602814,3504.780029 340.056274,3511.022461 330.715149,3511.990967 
	C328.236084,3512.057861 326.425568,3511.878418 324.434998,3513.502686 
	C322.209229,3515.997559 319.813995,3515.987793 316.708557,3515.984863 
	C310.657013,3515.231201 305.712402,3517.613770 300.516357,3517.940918 
	C289.983582,3518.604248 279.673523,3517.296875 268.795532,3514.088867 
	C260.795471,3506.198975 265.949768,3501.280273 272.630035,3496.121582 
	C273.723846,3495.520264 274.207306,3495.370117 275.347961,3495.600098 
z"
        />
        <path
          fill="#0D171F"
          opacity="1.000000"
          stroke="none"
          d="
M273.879822,3495.510254 
	C273.822571,3497.677734 273.657349,3499.715820 272.121521,3500.370850 
	C266.072205,3502.949951 266.335419,3507.449707 267.990723,3513.247559 
	C260.881287,3512.724121 254.256424,3512.321045 246.731979,3514.552979 
	C241.149323,3516.208740 233.767563,3514.008301 227.160172,3513.993164 
	C221.290298,3513.979980 215.420364,3513.990723 208.142914,3513.990723 
	C210.488632,3509.450195 212.557846,3507.093262 216.110413,3507.320801 
	C218.588608,3507.479248 219.722321,3506.220215 221.510986,3504.370850 
	C223.770721,3503.965088 225.580246,3504.146729 227.567047,3502.517578 
	C236.125580,3501.140137 242.602219,3495.621094 251.237442,3494.019531 
	C252.991043,3493.483887 253.983902,3492.967529 255.489594,3492.225586 
	C260.927673,3492.144775 266.361176,3488.022217 271.309875,3493.870605 
	C272.714172,3494.103516 273.293396,3494.448975 273.879822,3495.510254 
z"
        />
        <path
          fill="#040F1A"
          opacity="1.000000"
          stroke="none"
          d="
M330.573669,3512.543457 
	C337.283783,3508.809570 342.039978,3501.228271 351.274200,3500.081055 
	C351.688873,3511.677734 349.441040,3513.937988 337.970306,3513.983887 
	C335.667145,3513.993164 333.300049,3514.302002 330.573669,3512.543457 
z"
        />
        <path
          fill="#040F1A"
          opacity="1.000000"
          stroke="none"
          d="
M316.457764,3516.489258 
	C317.691711,3513.716064 320.177338,3514.051514 323.271820,3513.999023 
	C323.136536,3518.539551 320.355042,3518.540283 316.457764,3516.489258 
z"
        />
        <path
          fill="#698196"
          opacity="1.000000"
          stroke="none"
          d="
M190.048401,2446.875000 
	C187.356445,2452.138916 183.340347,2449.581543 177.978882,2448.892822 
	C182.204285,2444.817383 185.993408,2446.357422 190.048401,2446.875000 
z"
        />
        <path
          fill="#102131"
          opacity="1.000000"
          stroke="none"
          d="
M2690.004883,3457.166748 
	C2690.619629,3460.176758 2691.233887,3462.392334 2691.929199,3465.296631 
	C2688.202393,3468.784668 2685.270752,3466.416504 2682.275391,3464.349609 
	C2677.695312,3461.188965 2673.213135,3457.876709 2668.322266,3455.192139 
	C2668.092773,3455.066162 2667.716309,3455.207520 2665.874756,3455.308350 
	C2676.427002,3461.404053 2687.931152,3465.052002 2689.966309,3478.381348 
	C2681.908691,3481.118652 2676.904297,3474.768311 2669.880859,3472.809082 
	C2666.875977,3469.210205 2664.137939,3469.880371 2660.608398,3472.026855 
	C2648.973877,3474.503174 2638.694336,3472.544434 2628.393066,3466.845215 
	C2625.701660,3458.915283 2633.230713,3458.567627 2635.769287,3455.318604 
	C2629.290527,3454.508057 2622.917236,3453.490479 2623.525391,3443.761475 
	C2630.804199,3438.973877 2637.361572,3443.123047 2643.593506,3443.748535 
	C2629.430176,3438.247803 2613.552490,3436.793213 2599.037354,3430.687744 
	C2594.128174,3428.622803 2589.052246,3426.893799 2587.353027,3420.352539 
	C2589.252441,3416.261475 2592.536621,3415.092773 2595.871338,3415.284424 
	C2609.056396,3416.042480 2622.442627,3414.204346 2635.388184,3418.266602 
	C2640.865967,3419.985596 2646.526123,3420.582520 2652.140625,3419.233398 
	C2655.989746,3418.308594 2658.515869,3415.623535 2659.027588,3411.551270 
	C2659.598633,3407.003662 2655.973877,3405.960938 2652.839355,3405.096436 
	C2646.611572,3403.378906 2640.666504,3401.541016 2637.678955,3394.976318 
	C2636.988525,3393.459229 2635.449707,3392.173828 2633.809326,3392.048828 
	C2623.296875,3391.249268 2613.367432,3385.778809 2602.271240,3388.575928 
	C2606.721436,3390.711914 2611.676758,3391.322998 2616.230225,3393.059082 
	C2620.558350,3394.708740 2624.085938,3397.060303 2623.812256,3402.311523 
	C2623.557129,3407.208008 2619.633057,3408.659668 2615.854004,3410.170898 
	C2607.300293,3413.592041 2599.159668,3411.095459 2591.824463,3407.203125 
	C2584.791260,3403.470703 2577.663574,3402.084229 2569.923340,3401.476318 
	C2561.792725,3400.837891 2553.097656,3401.106445 2546.519043,3393.499756 
	C2545.492188,3390.963623 2545.418457,3389.031494 2545.807617,3386.387207 
	C2546.587402,3383.622070 2547.962891,3381.756104 2547.745117,3378.764160 
	C2547.414795,3373.548584 2549.175293,3369.951660 2553.275391,3366.767090 
	C2555.832031,3365.206055 2557.255127,3363.665283 2557.381348,3360.479004 
	C2558.139404,3357.265137 2559.463379,3355.256836 2562.484375,3353.690918 
	C2571.802490,3349.518066 2582.134766,3352.978027 2590.380371,3346.808105 
	C2592.433350,3345.271729 2595.311768,3347.492432 2597.679199,3350.087402 
	C2602.915283,3357.479492 2610.571045,3358.630371 2617.740479,3360.544678 
	C2621.211426,3361.471924 2624.870117,3361.895508 2628.457275,3362.202393 
	C2638.129395,3363.030273 2644.466797,3367.592285 2646.762207,3378.090088 
	C2651.059082,3386.330322 2657.397705,3391.255371 2665.905273,3390.640381 
	C2679.097412,3389.687012 2690.650146,3397.194580 2703.781250,3396.709961 
	C2708.848877,3396.522949 2713.552979,3400.455811 2715.718750,3406.779297 
	C2715.806885,3409.826172 2714.984863,3411.794189 2713.117920,3414.171387 
	C2711.185303,3415.637939 2709.667969,3416.035889 2707.309570,3416.134277 
	C2699.696533,3415.060791 2692.916992,3413.398926 2685.944092,3413.158447 
	C2684.319824,3413.102295 2682.372559,3413.105713 2681.530518,3414.784668 
	C2680.641357,3416.557861 2682.059082,3417.785156 2683.252441,3418.948242 
	C2686.225830,3421.847656 2693.324219,3422.362061 2690.226074,3428.896729 
	C2686.898438,3435.915283 2680.348877,3433.863281 2674.226807,3432.982422 
	C2677.219238,3435.755127 2682.774902,3436.567383 2680.220703,3442.798584 
	C2680.012695,3443.306396 2687.790283,3444.269775 2689.821777,3449.333252 
	C2690.008301,3452.132080 2690.006592,3454.252441 2690.004883,3457.166748 
z"
        />
        <path
          fill="#2B3E51"
          opacity="1.000000"
          stroke="none"
          d="
M2705.383789,3414.296875 
	C2707.487793,3413.950439 2708.987793,3413.953857 2711.237793,3413.943115 
	C2718.710693,3411.481445 2725.921631,3414.493408 2733.423340,3411.842285 
	C2749.050537,3410.536621 2762.618164,3417.011230 2777.721191,3419.081543 
	C2781.714111,3420.517334 2784.990723,3421.656738 2788.974121,3422.936523 
	C2790.624023,3423.211426 2791.560547,3423.387451 2793.172852,3423.788086 
	C2795.210205,3424.328613 2796.482910,3424.963623 2798.588867,3425.231934 
	C2800.223877,3425.442627 2801.152100,3425.603271 2802.769043,3425.926758 
	C2814.865723,3434.854736 2828.865967,3434.863037 2842.091797,3437.508301 
	C2843.900391,3437.870117 2845.683105,3438.127441 2846.342529,3438.073730 
	C2840.563477,3437.147461 2833.979248,3434.910889 2827.439941,3432.602051 
	C2823.997559,3431.386719 2821.811279,3429.030762 2823.224854,3424.291748 
	C2824.417969,3423.017822 2825.338135,3422.627930 2827.124268,3422.448486 
	C2837.751953,3426.768799 2848.604980,3426.831299 2858.832031,3429.601807 
	C2861.810303,3430.408691 2864.792480,3430.081055 2867.535156,3428.572266 
	C2871.814697,3426.218018 2876.256348,3425.586182 2881.000244,3426.904785 
	C2883.266602,3427.534424 2885.568115,3427.522461 2887.875732,3426.816162 
	C2907.361816,3420.851074 2926.428223,3425.562256 2945.538330,3429.125732 
	C2946.853760,3429.371094 2948.152588,3429.702637 2949.294434,3430.391846 
	C2952.042480,3432.052246 2954.012451,3434.346680 2953.229736,3437.734131 
	C2952.351074,3441.535400 2949.156494,3441.657471 2946.065430,3441.395020 
	C2935.652588,3440.511230 2925.427490,3437.322266 2917.523682,3438.676758 
	C2934.841797,3446.434082 2956.737061,3444.356689 2977.583252,3451.333252 
	C2980.918213,3451.800781 2983.627441,3451.852783 2987.147461,3451.897949 
	C2989.324219,3452.789062 2990.686279,3453.692627 2992.055908,3454.584473 
	C2994.125000,3455.931641 2994.246826,3457.944092 2993.334961,3459.824463 
	C2992.412109,3461.727295 2990.638672,3461.003174 2988.997314,3460.584229 
	C2983.864014,3459.274658 2978.734863,3457.906494 2973.539307,3456.893311 
	C2966.517578,3455.523193 2959.634033,3453.008789 2952.393555,3457.296631 
	C2949.060547,3459.270752 2944.660889,3458.155273 2940.822021,3456.429443 
	C2934.723877,3453.688232 2928.324463,3452.034668 2921.571289,3451.973389 
	C2920.252441,3451.961426 2918.623291,3452.119873 2917.666504,3451.447021 
	C2904.320068,3442.067871 2888.211182,3447.148926 2873.904053,3442.952148 
	C2871.785156,3442.330566 2869.997559,3442.855469 2868.917236,3443.705078 
	C2871.692139,3446.817627 2874.567871,3450.043213 2877.814941,3453.685303 
	C2874.797363,3456.494629 2872.327393,3456.115967 2869.951660,3454.866211 
	C2866.025635,3452.800293 2861.973877,3451.563477 2856.658203,3452.133545 
	C2851.125488,3452.116455 2846.433594,3452.040527 2840.855957,3451.940918 
	C2836.935791,3452.798828 2834.076660,3454.098877 2830.644287,3455.783691 
	C2824.361084,3456.838623 2819.156494,3454.125732 2812.811035,3453.974854 
	C2795.506836,3453.104492 2779.081055,3451.840088 2763.340332,3446.360352 
	C2760.530762,3445.382324 2757.415771,3443.811768 2757.355713,3440.393555 
	C2757.278076,3435.950439 2761.369629,3435.654541 2764.461182,3434.869385 
	C2767.944092,3433.985352 2771.244141,3432.106689 2772.712646,3432.285400 
	C2768.889893,3432.307129 2763.224121,3429.832520 2757.259521,3428.711426 
	C2752.245605,3427.769043 2747.290527,3423.673096 2743.448975,3432.027588 
	C2741.599609,3436.050293 2734.921875,3434.034912 2732.232666,3430.543701 
	C2728.412354,3425.584717 2723.558838,3425.538086 2718.276611,3424.616455 
	C2712.510254,3423.610107 2704.538574,3424.293701 2705.383789,3414.296875 
z"
        />
        <path
          fill="#172B3E"
          opacity="1.000000"
          stroke="none"
          d="
M2705.418945,3413.409180 
	C2706.885742,3418.081543 2711.336182,3421.490234 2716.943848,3421.901123 
	C2720.903320,3422.190674 2724.897949,3421.979492 2728.875732,3422.049561 
	C2731.404053,3422.094238 2733.669434,3422.547852 2732.958008,3425.972900 
	C2732.573242,3427.824463 2732.919189,3429.376221 2734.918945,3429.820801 
	C2737.382324,3430.368164 2739.885498,3433.122314 2742.316162,3430.997314 
	C2745.388428,3428.311523 2739.223145,3426.086914 2742.122070,3422.074219 
	C2756.409912,3423.933838 2770.241455,3429.952881 2786.000732,3432.311279 
	C2781.300537,3438.860840 2776.054932,3432.898926 2772.665527,3435.234619 
	C2768.556641,3438.065918 2763.938232,3437.820312 2759.801025,3438.381836 
	C2758.548584,3441.888428 2760.946777,3442.562012 2762.597900,3443.129395 
	C2770.082031,3445.700684 2777.416748,3448.651855 2785.697998,3448.135742 
	C2794.397217,3447.593750 2802.394287,3451.758301 2811.434082,3453.435059 
	C2813.845459,3463.659180 2812.851074,3464.694824 2803.731201,3462.942383 
	C2800.506104,3462.322266 2797.038330,3462.559814 2793.991455,3461.507812 
	C2788.508057,3459.614502 2784.538330,3459.152588 2783.224609,3466.501465 
	C2782.630371,3469.825684 2779.558838,3469.469971 2776.744141,3468.491699 
	C2766.456787,3464.916992 2755.635498,3463.565430 2744.544434,3460.524902 
	C2745.228271,3452.789307 2751.752441,3457.584961 2756.728271,3453.786377 
	C2740.422852,3445.745117 2722.630859,3445.903076 2707.270996,3438.724854 
	C2708.257080,3439.576904 2709.407959,3439.905029 2710.628174,3439.982910 
	C2713.702881,3440.179199 2717.290039,3440.478760 2716.700195,3444.622559 
	C2716.143311,3448.538330 2712.640381,3449.498047 2709.057617,3449.340576 
	C2705.705566,3449.193115 2702.489502,3445.190186 2698.504883,3449.500977 
	C2695.876221,3449.998047 2693.742920,3450.005859 2690.809570,3450.012695 
	C2684.752197,3445.112793 2677.674316,3446.573242 2671.329590,3445.164307 
	C2671.851074,3439.930176 2677.692627,3443.834717 2678.871094,3439.618408 
	C2675.857178,3434.868896 2668.720947,3436.384521 2664.228516,3431.313232 
	C2669.949219,3429.402100 2674.795166,3429.397461 2679.437500,3430.915527 
	C2684.218750,3432.479004 2687.091797,3430.925049 2689.302734,3426.413818 
	C2687.534912,3422.718262 2682.628418,3424.352051 2680.292236,3421.357666 
	C2678.240967,3418.728027 2675.213623,3416.550537 2676.502930,3412.770020 
	C2677.802490,3408.960205 2681.431152,3410.186523 2684.257812,3410.025635 
	C2691.295898,3409.624756 2697.968506,3412.035156 2705.418945,3413.409180 
z"
        />
        <path
          fill="#263B4D"
          opacity="1.000000"
          stroke="none"
          d="
M2698.492432,3450.008789 
	C2699.951416,3443.619385 2700.804688,3442.742920 2705.544434,3445.235840 
	C2709.258057,3447.188965 2712.058594,3448.326172 2715.318604,3443.871338 
	C2709.762451,3440.357422 2702.788818,3441.517578 2696.487549,3438.312988 
	C2699.157715,3435.472412 2702.057861,3435.491211 2704.231689,3436.255371 
	C2710.429199,3438.434082 2717.293945,3437.217773 2723.305908,3441.052734 
	C2726.698242,3443.216797 2731.506592,3443.499268 2735.747559,3443.920166 
	C2743.454834,3444.685303 2750.508057,3447.404541 2757.594238,3450.180176 
	C2759.947266,3451.102051 2761.915527,3452.461914 2761.500000,3455.177979 
	C2761.018555,3458.323975 2758.201416,3459.193359 2755.570068,3459.558838 
	C2752.054199,3460.048096 2748.696289,3456.604004 2744.506348,3459.472412 
	C2734.692139,3460.878906 2726.186523,3457.071045 2717.312744,3455.423096 
	C2714.779053,3454.952637 2712.257812,3454.414795 2708.915283,3453.872559 
	C2706.206299,3451.642334 2703.103760,3452.431152 2700.435059,3450.498047 
	C2699.940674,3450.064941 2698.975098,3450.027100 2698.492432,3450.008789 
z"
        />
        <path
          fill="#1F3041"
          opacity="1.000000"
          stroke="none"
          d="
M2830.504395,3456.532715 
	C2830.817139,3450.417725 2834.029785,3449.089844 2839.495850,3451.419189 
	C2838.655518,3458.391357 2838.655518,3458.391357 2830.504395,3456.532715 
z"
        />
        <path
          fill="#172B3E"
          opacity="1.000000"
          stroke="none"
          d="
M2700.113281,3450.764648 
	C2702.707031,3450.300781 2705.943359,3449.016846 2707.907715,3453.152832 
	C2705.296143,3453.700928 2702.055908,3455.003418 2700.113281,3450.764648 
z"
        />
        <path
          fill="#0C0B0A"
          opacity="1.000000"
          stroke="none"
          d="
M27.905342,3972.679932 
	C28.165365,3968.035645 27.692751,3963.713135 30.069187,3959.750488 
	C34.560192,3952.262939 42.217953,3950.054199 48.236389,3956.041260 
	C52.217793,3960.000977 54.533550,3958.826904 58.254559,3956.719238 
	C67.820557,3951.301270 72.327324,3942.117432 76.495293,3932.746338 
	C78.495560,3928.248779 76.158051,3922.081299 70.683861,3915.511475 
	C74.812279,3913.718750 77.334579,3909.215820 83.423073,3909.912598 
	C89.295502,3912.738037 88.984734,3917.669434 88.202003,3922.165283 
	C84.824097,3941.566162 76.387924,3957.709961 58.396996,3967.736328 
	C51.187737,3971.754150 44.499313,3976.765137 34.850124,3976.255371 
	C32.348366,3975.981201 30.595039,3976.034668 28.791252,3974.333984 
	C28.395672,3973.829834 28.018583,3973.082764 27.905342,3972.679932 
z"
        />
        <path
          fill="#7B7F84"
          opacity="1.000000"
          stroke="none"
          d="
M18.663929,3878.043457 
	C17.116262,3878.082520 16.231678,3878.196777 14.701275,3878.498047 
	C13.810700,3875.776123 15.222763,3873.655029 17.212955,3871.729248 
	C19.101942,3869.901123 21.373304,3868.223633 21.850964,3865.444092 
	C23.098173,3858.185791 28.450089,3854.983887 34.551670,3852.912354 
	C41.815598,3850.445801 48.339100,3846.493164 55.281204,3843.399414 
	C57.446655,3842.434326 59.923725,3841.260254 61.317978,3844.062012 
	C62.611389,3846.661377 62.027390,3849.812012 59.215416,3850.895752 
	C48.674229,3854.959229 43.461857,3864.720703 36.136868,3872.151367 
	C33.905090,3874.415283 31.510429,3875.645752 28.456343,3877.436035 
	C26.497988,3877.888184 24.986475,3877.840820 22.719368,3877.767090 
	C21.095997,3877.944580 20.217110,3878.070801 18.663929,3878.043457 
z"
        />
        <path
          fill="#2C2520"
          opacity="1.000000"
          stroke="none"
          d="
M28.381948,3974.455078 
	C30.016184,3974.269775 31.636669,3974.709717 33.645096,3975.663086 
	C31.736158,3977.923584 29.649328,3978.584717 28.381948,3974.455078 
z"
        />
        <path
          fill="#817A79"
          opacity="1.000000"
          stroke="none"
          d="
M85.143105,3877.655273 
	C85.222649,3879.023926 84.419716,3879.811523 83.685226,3878.990723 
	C82.941238,3878.158691 83.926735,3877.516357 85.143105,3877.655273 
z"
        />
        <path
          fill="#091A29"
          opacity="1.000000"
          stroke="none"
          d="
M2690.214111,3478.564453 
	C2686.408691,3473.729980 2682.898193,3469.388184 2679.167236,3465.244629 
	C2677.365234,3463.242432 2674.849609,3462.165771 2672.126221,3462.043457 
	C2668.521973,3461.882080 2666.313232,3459.127686 2663.441406,3457.621338 
	C2661.981689,3456.855225 2660.382324,3455.621094 2661.051025,3453.791748 
	C2661.931885,3451.381348 2664.283691,3450.341797 2666.639160,3450.748291 
	C2669.588623,3451.257324 2672.017822,3453.058594 2674.198730,3455.175781 
	C2678.956543,3459.794434 2684.224365,3463.671875 2691.301758,3465.908691 
	C2702.444580,3467.543701 2712.290771,3469.822266 2721.875244,3475.337402 
	C2730.207764,3480.131836 2740.736328,3481.493652 2750.745850,3479.268555 
	C2754.236328,3478.492676 2757.011963,3479.263916 2760.278809,3479.956299 
	C2770.190186,3482.057373 2779.604492,3486.716309 2790.080566,3486.018555 
	C2795.562012,3485.653320 2800.473877,3487.694824 2805.393555,3489.718018 
	C2814.122803,3493.308594 2822.625977,3497.633057 2832.324951,3498.140869 
	C2832.975098,3498.175049 2833.737305,3498.234375 2834.240967,3498.581787 
	C2846.984131,3507.358643 2861.757568,3512.373291 2874.837158,3520.516357 
	C2877.773193,3522.344238 2880.028320,3524.674561 2879.971191,3529.212158 
	C2878.431396,3531.614014 2876.490967,3532.143555 2873.554932,3531.750244 
	C2869.268066,3529.441162 2866.328857,3525.814209 2860.750977,3526.958984 
	C2850.957031,3527.277832 2841.523926,3530.328857 2833.328857,3523.181396 
	C2841.733887,3529.169189 2842.100098,3531.629883 2834.684570,3538.068359 
	C2831.864502,3538.143311 2829.732666,3538.117920 2826.801514,3538.091797 
	C2819.143799,3538.840088 2812.870605,3536.337891 2805.773438,3534.429688 
	C2803.730957,3533.951416 2802.409424,3533.522949 2800.438477,3532.920898 
	C2798.880615,3532.686279 2797.997803,3532.529297 2796.464844,3532.243164 
	C2790.383057,3530.809570 2787.221191,3536.192139 2781.750000,3536.906250 
	C2776.976562,3538.189209 2773.701172,3535.726562 2769.475098,3534.213623 
	C2767.447021,3533.080811 2765.351807,3532.716309 2764.772705,3531.379395 
	C2761.280029,3523.312500 2754.073975,3523.391357 2746.533691,3522.449951 
	C2746.000000,3522.000000 2745.004883,3521.991455 2744.507324,3521.985840 
	C2740.533447,3517.799561 2734.096191,3519.476562 2730.326660,3514.614746 
	C2729.974121,3513.134277 2729.948242,3512.268311 2729.907227,3510.752930 
	C2729.582764,3506.431396 2730.949463,3502.968750 2731.221924,3499.360107 
	C2731.975098,3489.373779 2725.700684,3484.923828 2716.535645,3488.842041 
	C2709.585205,3491.813232 2702.509521,3495.369629 2695.089844,3488.388184 
	C2693.405273,3485.708740 2693.640137,3482.970703 2691.594727,3480.529297 
	C2690.909912,3479.786133 2690.664062,3479.462402 2690.214111,3478.564453 
z"
        />
        <path
          fill="#1C2E3E"
          opacity="1.000000"
          stroke="none"
          d="
M2834.458984,3538.697998 
	C2839.765869,3530.651123 2839.590820,3530.043457 2831.312256,3525.375244 
	C2829.066406,3524.109131 2827.088867,3522.366699 2825.034424,3520.876221 
	C2825.996582,3517.135742 2828.991943,3517.535645 2830.525879,3518.692871 
	C2839.003906,3525.087158 2848.294189,3526.302979 2859.168945,3524.068359 
	C2863.220703,3526.770996 2868.337402,3525.200195 2871.584961,3529.454834 
	C2874.207764,3529.919922 2876.307861,3529.937256 2879.195068,3529.955078 
	C2886.859131,3535.199219 2894.769287,3538.543701 2902.595947,3541.973145 
	C2905.262695,3543.141846 2908.293945,3543.437744 2909.874023,3547.153076 
	C2903.718262,3548.307129 2898.897461,3543.960938 2893.136963,3542.592285 
	C2888.381592,3541.462646 2883.917236,3538.873535 2878.743408,3541.322754 
	C2875.817627,3542.707275 2873.021240,3540.096436 2870.058838,3540.133301 
	C2865.854736,3540.186035 2861.569336,3539.381836 2857.696533,3540.733643 
	C2856.209961,3544.462891 2861.231934,3543.533691 2860.812256,3547.223877 
	C2851.902832,3544.923828 2843.011475,3543.563965 2834.458984,3538.697998 
z"
        />
        <path
          fill="#1F3041"
          opacity="1.000000"
          stroke="none"
          d="
M2767.301758,2899.388672 
	C2776.104736,2905.518799 2785.776367,2905.097656 2795.441406,2907.454834 
	C2792.421387,2915.015625 2785.729736,2912.156006 2780.377930,2913.080078 
	C2791.507568,2912.038330 2801.924561,2915.366211 2811.822510,2920.299805 
	C2818.557617,2923.657471 2818.242188,2926.376221 2810.481689,2930.966797 
	C2795.033936,2933.891602 2780.624512,2932.135010 2766.732910,2927.267578 
	C2756.884033,2923.816650 2746.834473,2923.337891 2736.709961,2921.894287 
	C2714.383301,2918.711426 2690.601318,2921.371338 2669.827148,2907.468018 
	C2667.750244,2901.126953 2671.763916,2899.966553 2675.723877,2899.099854 
	C2681.186035,2897.904297 2686.638428,2896.808838 2692.010986,2892.969971 
	C2702.587158,2891.805664 2712.365967,2892.817871 2723.011963,2892.901611 
	C2736.490479,2894.243896 2748.933838,2897.068604 2762.548828,2897.288086 
	C2764.580566,2897.665771 2765.645020,2898.143066 2767.301758,2899.388672 
z"
        />
        <path
          fill="#465F75"
          opacity="1.000000"
          stroke="none"
          d="
M2724.454346,2895.392578 
	C2713.731934,2895.651367 2703.565918,2896.999023 2692.267578,2893.602051 
	C2689.764404,2886.649658 2694.214111,2885.687256 2698.536621,2885.000732 
	C2708.233154,2883.460693 2717.745117,2880.718506 2728.414307,2880.208008 
	C2734.475586,2878.748047 2739.536621,2880.164551 2745.318848,2881.484375 
	C2748.122803,2882.388428 2750.012451,2883.789062 2753.044922,2883.631836 
	C2756.022217,2883.389160 2758.322266,2883.422363 2761.305664,2883.979980 
	C2765.973633,2885.689453 2769.833008,2888.091309 2775.068848,2885.589844 
	C2784.511963,2883.710693 2792.760010,2888.198975 2802.188477,2887.326660 
	C2814.107666,2892.260986 2824.644043,2899.019043 2838.327148,2898.134277 
	C2853.830566,2902.474365 2868.835205,2901.849609 2884.651855,2900.402344 
	C2888.266602,2899.923096 2890.783691,2900.559814 2893.339355,2903.319580 
	C2898.290527,2905.256836 2902.647217,2906.682129 2907.041504,2907.981934 
	C2909.422607,2908.686035 2911.175537,2909.908203 2911.828125,2913.161133 
	C2910.945068,2914.828369 2909.716064,2915.764893 2908.448486,2915.512207 
	C2889.528809,2911.744385 2870.259766,2915.619629 2851.318848,2912.866943 
	C2841.909180,2911.499756 2833.225098,2906.648438 2822.729736,2906.352783 
	C2818.694092,2903.233887 2814.467285,2902.534912 2810.809814,2903.060059 
	C2804.449219,2903.973145 2799.339355,2901.763916 2793.817871,2899.380127 
	C2785.620117,2895.840332 2777.067871,2893.407471 2768.198975,2899.187500 
	C2767.146240,2899.916748 2766.302979,2899.890381 2764.826904,2899.846680 
	C2751.630615,2894.417969 2738.378906,2894.516113 2724.454346,2895.392578 
z"
        />
        <path
          fill="#233A4F"
          opacity="1.000000"
          stroke="none"
          d="
M2809.466797,2928.674316 
	C2811.634766,2927.697754 2814.234619,2928.633545 2814.718262,2926.022461 
	C2815.090088,2924.013184 2812.895752,2923.370850 2811.472168,2922.920898 
	C2801.259033,2919.692627 2791.496094,2914.513672 2780.196289,2915.923828 
	C2776.175781,2916.425781 2772.594482,2914.713135 2769.201660,2912.800293 
	C2770.146240,2909.069824 2772.892822,2910.219971 2774.951660,2910.030762 
	C2781.454102,2909.434082 2788.123291,2911.234619 2795.254883,2908.326904 
	C2801.148926,2908.131836 2805.574707,2910.138428 2810.035400,2912.578369 
	C2814.675293,2915.116211 2819.635254,2917.184326 2825.232666,2914.655762 
	C2827.677246,2913.551270 2830.705078,2913.398193 2833.020020,2914.787354 
	C2842.404297,2920.419678 2852.937500,2921.785645 2863.401855,2923.502930 
	C2864.715332,2923.718262 2866.078125,2923.821533 2867.318848,2924.258301 
	C2872.128174,2925.950928 2872.362061,2927.405273 2868.434570,2933.423584 
	C2854.249268,2935.472900 2841.439941,2928.944580 2827.843750,2929.228760 
	C2831.231445,2938.705811 2831.231445,2938.705811 2845.576172,2946.022705 
	C2841.262207,2946.022705 2836.948486,2946.023193 2832.634521,2946.022461 
	C2827.979004,2946.021729 2823.309814,2945.800537 2818.672852,2946.092285 
	C2815.244385,2946.308105 2811.252686,2944.525879 2808.382324,2949.073975 
	C2797.900391,2955.281738 2790.721436,2952.591309 2789.142090,2941.856445 
	C2794.928955,2934.705322 2796.049316,2934.423340 2810.498535,2935.781250 
	C2810.342285,2933.525635 2808.172852,2931.711182 2809.466797,2928.674316 
z"
        />
        <path
          fill="#233A4F"
          opacity="1.000000"
          stroke="none"
          d="
M2822.485840,2906.407715 
	C2830.996338,2905.742432 2839.893799,2906.714111 2848.523682,2909.176025 
	C2859.972656,2912.442627 2871.481689,2909.991699 2882.944336,2909.927246 
	C2892.326904,2909.874268 2901.364746,2911.352783 2911.130859,2913.783691 
	C2917.509277,2913.635986 2922.957031,2914.594727 2929.171631,2915.945312 
	C2934.360107,2920.026123 2940.413086,2916.549805 2945.144531,2919.343262 
	C2946.490723,2920.138184 2948.944824,2919.497559 2949.875732,2920.478760 
	C2954.267334,2925.107666 2959.904541,2928.100342 2965.860840,2932.134766 
	C2959.458740,2935.547852 2954.131592,2934.175781 2950.193115,2930.876953 
	C2942.221436,2924.200684 2932.414307,2924.488770 2923.252686,2922.249268 
	C2922.616699,2922.093994 2921.886719,2921.937988 2921.282959,2922.096436 
	C2915.005859,2923.745361 2908.460449,2925.098877 2902.611084,2927.477295 
	C2893.386963,2931.228271 2892.190674,2932.128418 2885.014404,2925.899170 
	C2882.396729,2923.626709 2879.731934,2922.252441 2876.310547,2921.659912 
	C2858.060547,2918.497803 2840.333008,2913.375244 2822.485840,2906.407715 
z"
        />
        <path
          fill="#233A4F"
          opacity="1.000000"
          stroke="none"
          d="
M2972.219238,2951.169434 
	C2966.166260,2952.519043 2961.125000,2949.171875 2955.619873,2948.492676 
	C2946.530762,2947.371338 2937.638428,2944.283447 2928.210205,2945.886230 
	C2924.711182,2946.481201 2920.906494,2946.405762 2916.746338,2944.317871 
	C2913.967041,2939.223633 2908.337891,2939.390381 2904.291504,2935.830078 
	C2910.205566,2932.301270 2915.086914,2930.351562 2920.839600,2935.202393 
	C2924.625244,2938.394531 2929.123291,2939.910400 2934.437744,2940.359619 
	C2942.945068,2941.077637 2951.340820,2943.238037 2959.745117,2944.974121 
	C2961.732178,2945.384766 2963.726318,2946.164795 2965.534668,2945.101074 
	C2977.162109,2938.261719 2988.959473,2946.066162 3000.649170,2944.665527 
	C3001.663574,2944.544189 3003.250000,2945.278809 3003.906006,2948.006104 
	C2996.270264,2948.006104 2988.862061,2947.882812 2981.460938,2948.064697 
	C2978.344238,2948.140869 2974.671631,2946.702881 2972.219238,2951.169434 
z"
        />
        <path
          fill="#EAE6D7"
          opacity="1.000000"
          stroke="none"
          d="
M1705.612061,3951.021973 
	C1705.412109,3947.528564 1707.559204,3948.085449 1709.438599,3948.481934 
	C1710.459473,3948.697266 1711.144775,3949.819580 1710.541260,3950.665527 
	C1709.426270,3952.229248 1707.682129,3951.888184 1705.612061,3951.021973 
z"
        />
        <path
          fill="#EAE6D7"
          opacity="1.000000"
          stroke="none"
          d="
M1697.437500,3948.478027 
	C1700.146851,3950.643555 1698.994751,3952.018799 1696.973022,3952.972656 
	C1696.061157,3953.402832 1694.941406,3952.940430 1694.632080,3951.934570 
	C1694.054321,3950.056885 1694.896729,3948.833252 1697.437500,3948.478027 
z"
        />
        <path
          fill="#B9A795"
          opacity="1.000000"
          stroke="none"
          d="
M70.336395,1761.344971 
	C68.882416,1763.615845 67.197144,1764.110840 64.661804,1764.122559 
	C61.209999,1764.438354 59.108677,1763.282227 57.132660,1760.487305 
	C58.539700,1741.281128 57.703407,1722.724976 58.024845,1704.192627 
	C58.122330,1698.572144 58.385929,1692.954468 59.017666,1686.638550 
	C60.833496,1685.002197 62.245602,1684.152832 64.759964,1683.889404 
	C71.453407,1684.773804 77.310074,1684.936523 83.961548,1683.362549 
	C88.275093,1682.768188 91.681839,1682.800537 95.481445,1685.319214 
	C98.417191,1685.922363 100.628479,1686.093384 103.154716,1687.677979 
	C103.702660,1693.524902 104.636452,1699.102539 103.359024,1705.570312 
	C102.800644,1707.223755 102.719002,1707.988892 103.299545,1709.270630 
	C104.485252,1714.180420 104.677780,1718.573242 102.701370,1723.762451 
	C98.722137,1727.745239 94.177940,1726.449219 90.078995,1727.239624 
	C85.107277,1728.198120 84.979179,1731.738770 85.858635,1735.526611 
	C87.007355,1740.474487 89.723000,1737.011353 91.796097,1736.386475 
	C94.800476,1735.480713 97.603737,1735.583374 100.699753,1738.018555 
	C102.997482,1741.055054 103.160217,1743.962646 103.349945,1747.610962 
	C103.559578,1749.663574 103.627510,1751.002808 103.709625,1753.012451 
	C101.790665,1755.694092 102.389023,1758.676514 100.449432,1761.286133 
	C96.239052,1761.643799 95.182167,1765.312134 92.312408,1767.456055 
	C91.629066,1767.928955 91.266418,1767.827148 90.353134,1767.598877 
	C85.374832,1761.704590 87.256874,1756.636597 92.072220,1751.538086 
	C83.208923,1750.129272 74.557091,1751.973389 64.471878,1748.908081 
	C69.529091,1752.572388 71.761169,1756.002808 70.336395,1761.344971 
z"
        />
        <path
          fill="#9FA2A1"
          opacity="1.000000"
          stroke="none"
          d="
M96.142136,1684.975098 
	C92.734581,1685.866211 89.280807,1685.909912 84.969208,1685.950684 
	C76.365875,1678.689087 77.592415,1670.221313 81.412186,1660.745605 
	C80.276840,1652.000854 73.269608,1649.692261 66.673813,1645.880615 
	C66.231224,1643.204834 65.513458,1640.115234 69.619553,1641.842407 
	C72.676949,1643.128662 75.693054,1644.513062 79.445480,1645.880615 
	C83.096489,1646.707764 86.029533,1647.508057 89.673477,1648.637939 
	C93.931465,1654.761719 94.662468,1661.147339 94.000015,1667.708252 
	C93.426949,1673.383911 96.552071,1678.513428 96.142136,1684.975098 
z"
        />
        <path
          fill="#DFD9CC"
          opacity="1.000000"
          stroke="none"
          d="
M81.183014,1660.015747 
	C83.068672,1668.082397 80.704590,1676.406250 83.943062,1685.141846 
	C78.318047,1688.784790 72.436501,1688.556396 65.889244,1685.885742 
	C62.424450,1682.103394 62.787106,1678.268677 63.613461,1673.739990 
	C63.715321,1668.492065 61.273582,1664.243774 62.168720,1659.488892 
	C63.011551,1655.011841 65.193787,1653.840820 68.409447,1657.120239 
	C72.057564,1660.840210 76.218155,1659.786499 81.183014,1660.015747 
z"
        />
        <path
          fill="#A9ACA9"
          opacity="1.000000"
          stroke="none"
          d="
M53.563782,1655.976562 
	C56.581150,1657.884033 56.266182,1660.296387 56.230236,1662.528564 
	C56.184582,1665.363403 56.882637,1668.884888 52.841320,1669.595703 
	C49.682377,1670.151489 46.083752,1670.781250 43.632309,1667.966675 
	C41.014187,1664.960815 41.029068,1660.908203 42.808094,1657.839600 
	C44.935654,1654.169800 49.245514,1655.831543 53.563782,1655.976562 
z"
        />
        <path
          fill="#DED5C3"
          opacity="1.000000"
          stroke="none"
          d="
M56.871216,1759.805542 
	C58.939823,1760.842529 61.022388,1761.900024 63.547943,1763.481812 
	C61.965061,1767.294067 62.856396,1772.663574 56.635635,1773.645996 
	C54.180981,1772.432861 52.367477,1770.989624 50.097260,1769.189941 
	C49.737736,1768.021606 49.475609,1767.349976 48.444954,1766.407471 
	C48.250153,1764.981323 48.464645,1763.966309 49.023804,1762.246582 
	C50.028004,1761.090088 50.687534,1760.637939 51.726654,1760.042603 
	C52.106243,1759.899414 52.048973,1759.976318 52.027657,1759.937744 
	C52.263824,1760.156738 52.521305,1760.414307 53.306656,1760.811768 
	C54.579071,1760.967529 55.259739,1760.766724 56.366142,1760.106689 
	C56.855453,1759.863525 56.885178,1759.826172 56.871216,1759.805542 
z"
        />
        <path
          fill="#CBC5B5"
          opacity="1.000000"
          stroke="none"
          d="
M14.147799,1711.570679 
	C14.546432,1712.869629 14.777459,1713.489746 14.926975,1714.129150 
	C15.546792,1716.779419 14.916579,1718.914917 11.872396,1719.004395 
	C9.482965,1719.074585 8.578773,1717.027222 8.402224,1714.976807 
	C8.213312,1712.783081 7.466687,1710.161865 10.149828,1708.886230 
	C12.006405,1708.003296 13.185348,1709.260742 14.147799,1711.570679 
z"
        />
        <path
          fill="#595B54"
          opacity="1.000000"
          stroke="none"
          d="
M63.157578,1673.147461 
	C65.240555,1676.544678 65.389046,1680.162720 65.307312,1684.560791 
	C63.829060,1685.598389 62.380672,1685.879883 60.197090,1686.051514 
	C59.427128,1683.092896 59.376675,1680.244263 59.362881,1677.395508 
	C59.352959,1675.346558 59.872707,1673.633789 63.157578,1673.147461 
z"
        />
        <path
          fill="#D1C9BA"
          opacity="1.000000"
          stroke="none"
          d="
M53.627720,1714.634766 
	C53.703850,1717.407471 53.414497,1719.445190 50.787746,1719.536255 
	C48.544094,1719.614014 47.003849,1718.317261 46.862610,1716.047729 
	C46.737476,1714.036987 47.356045,1711.885010 49.304653,1711.064331 
	C51.739136,1710.038940 52.680996,1712.116821 53.627720,1714.634766 
z"
        />
        <path
          fill="#CBC5B5"
          opacity="1.000000"
          stroke="none"
          d="
M20.333714,1710.588379 
	C24.139620,1711.006226 24.070173,1713.404175 23.988567,1715.723145 
	C23.927547,1717.457153 23.316341,1719.036255 21.330065,1719.232666 
	C19.310999,1719.432373 18.443218,1718.024292 18.236387,1716.257568 
	C18.006557,1714.294312 17.979242,1712.316406 20.333714,1710.588379 
z"
        />
        <path
          fill="#D0C8B8"
          opacity="1.000000"
          stroke="none"
          d="
M28.096828,1715.704102 
	C28.215427,1712.983765 28.196552,1710.729248 30.810972,1710.518555 
	C32.763317,1710.361450 33.637032,1711.922974 33.763348,1713.683350 
	C33.923103,1715.910156 34.430820,1718.534302 31.385618,1719.256836 
	C29.404604,1719.726929 28.546238,1718.193970 28.096828,1715.704102 
z"
        />
        <path
          fill="#C7C0B1"
          opacity="1.000000"
          stroke="none"
          d="
M48.499573,1726.506104 
	C49.498577,1726.351562 49.852573,1726.333740 50.153095,1726.451904 
	C53.081238,1727.603271 54.465588,1729.549927 52.763096,1732.558105 
	C52.009300,1733.890015 50.371651,1733.821411 49.211941,1733.140747 
	C46.531578,1731.567749 45.440269,1729.460571 48.499573,1726.506104 
z"
        />
        <path
          fill="#DED5C3"
          opacity="1.000000"
          stroke="none"
          d="
M50.157955,1749.758545 
	C47.683807,1749.534790 45.916264,1748.826782 47.963333,1746.209229 
	C48.759144,1745.191650 49.396172,1744.049927 50.322083,1742.327393 
	C54.664612,1743.140259 54.452652,1745.957642 52.399895,1749.666260 
	C51.895321,1750.136230 51.918659,1750.051392 51.952171,1750.068359 
	C51.985683,1750.085205 51.427551,1749.322388 51.427551,1749.322388 
	C51.427551,1749.322388 50.487316,1749.419067 50.157955,1749.758545 
z"
        />
        <path
          fill="#B6AFA3"
          opacity="1.000000"
          stroke="none"
          d="
M43.348995,1699.587402 
	C41.521240,1702.008423 39.809631,1702.276978 38.500412,1700.016602 
	C37.817417,1698.837524 38.041054,1697.192261 39.333149,1696.587646 
	C41.506203,1695.571045 43.069386,1696.409668 43.348995,1699.587402 
z"
        />
        <path
          fill="#B6AFA3"
          opacity="1.000000"
          stroke="none"
          d="
M29.165035,1700.704102 
	C28.092690,1697.928345 28.514797,1696.180298 31.224525,1696.140991 
	C32.662697,1696.120117 33.720577,1697.152954 33.667896,1698.643555 
	C33.575111,1701.268799 31.982870,1701.903442 29.165035,1700.704102 
z"
        />
        <path
          fill="#C4BDAE"
          opacity="1.000000"
          stroke="none"
          d="
M54.394836,1761.298462 
	C53.414387,1761.705444 52.868477,1761.346924 52.229202,1760.345215 
	C52.006340,1759.899170 52.915157,1759.673828 53.381279,1759.717529 
	C54.131561,1760.084106 54.415718,1760.406982 54.394836,1761.298462 
z"
        />
        <path
          fill="#B9A795"
          opacity="1.000000"
          stroke="none"
          d="
M54.627468,1761.660156 
	C54.049511,1761.516724 54.009228,1761.166626 53.908173,1760.288818 
	C54.345409,1759.769531 54.843418,1759.777588 56.098450,1759.824707 
	C56.292030,1760.393555 55.728584,1760.923462 54.627468,1761.660156 
z"
        />
        <path
          fill="#607B93"
          opacity="1.000000"
          stroke="none"
          d="
M2688.155762,2553.218262 
	C2687.993164,2554.334961 2687.986328,2554.669922 2687.985107,2555.507324 
	C2687.974365,2557.463379 2687.958252,2558.916748 2687.919922,2561.097168 
	C2684.053955,2565.587402 2679.714355,2563.383789 2674.744629,2562.318359 
	C2669.336670,2558.077393 2663.615234,2561.292725 2657.572021,2559.088379 
	C2663.428955,2557.056885 2669.308838,2558.960938 2674.079346,2554.992188 
	C2669.968994,2549.981934 2663.758545,2552.173584 2658.809326,2550.840332 
	C2647.067627,2547.677490 2635.403564,2549.053223 2624.266357,2554.408691 
	C2617.673096,2557.579102 2610.439697,2556.614990 2603.774414,2555.347656 
	C2592.680908,2553.238037 2581.438232,2553.837402 2570.308594,2552.320312 
	C2562.223389,2551.218262 2553.857910,2551.675049 2544.883301,2549.976562 
	C2543.090088,2549.747803 2542.291260,2549.288818 2541.438477,2547.886719 
	C2541.317871,2546.760742 2541.412842,2546.306152 2541.983887,2545.352051 
	C2544.268066,2542.745850 2549.007080,2544.726318 2549.093506,2539.454346 
	C2549.351807,2538.349121 2549.551514,2537.927246 2550.320312,2537.082031 
	C2553.258545,2535.653320 2555.536377,2535.842529 2558.219238,2537.623535 
	C2559.866455,2541.256592 2561.423828,2540.046875 2563.663574,2538.171143 
	C2567.951416,2536.794434 2571.566895,2537.559570 2575.183105,2538.039795 
	C2593.373779,2540.456055 2611.290283,2540.737305 2629.666748,2537.383789 
	C2642.744873,2534.997070 2656.634277,2537.668457 2666.635010,2536.969482 
	C2657.758545,2536.795898 2645.320068,2537.716309 2632.955078,2535.912354 
	C2629.998535,2535.480957 2626.985352,2535.380615 2624.111328,2534.493896 
	C2621.854492,2533.797363 2619.306641,2532.914795 2619.390381,2530.114746 
	C2619.492676,2526.696289 2622.246826,2526.056396 2625.097168,2526.020508 
	C2628.088379,2525.982666 2631.080078,2525.990479 2633.419678,2526.006836 
	C2598.506104,2527.285645 2562.988525,2526.649902 2527.538086,2523.337158 
	C2520.622559,2522.690674 2513.544678,2522.832275 2506.684082,2518.557129 
	C2505.432129,2518.043701 2504.772705,2518.115723 2504.146484,2518.003418 
	C2501.774170,2517.579590 2498.514160,2519.175049 2497.276123,2516.157959 
	C2496.155762,2513.427734 2499.615479,2512.802979 2500.739014,2511.042725 
	C2501.395264,2510.015381 2501.571289,2508.681396 2501.989746,2506.762207 
	C2508.634277,2506.026611 2515.252686,2505.962402 2521.870117,2506.020264 
	C2526.047363,2506.056641 2530.470947,2505.093506 2532.976318,2510.274902 
	C2534.062012,2512.521240 2537.082031,2511.966309 2539.365723,2511.968994 
	C2554.220215,2511.987061 2568.897949,2515.124756 2583.600586,2515.398682 
	C2596.730469,2515.643555 2609.685303,2520.094238 2623.466797,2516.451172 
	C2625.492920,2515.860596 2626.944580,2515.862793 2629.117432,2515.813965 
	C2634.939941,2516.508301 2639.031250,2520.830322 2645.394287,2519.862305 
	C2654.875977,2519.738770 2663.443848,2524.070068 2673.008545,2520.423828 
	C2674.572754,2520.922607 2675.225098,2521.660889 2676.007568,2522.186523 
	C2677.138672,2522.946289 2677.019531,2525.400879 2678.902100,2524.714111 
	C2679.415771,2524.526855 2678.721191,2522.337158 2679.654053,2520.643311 
	C2683.541748,2520.135986 2686.951904,2520.085449 2691.218262,2520.059082 
	C2695.670654,2522.263672 2699.663330,2522.047363 2703.636475,2522.039062 
	C2717.856445,2522.009033 2732.076172,2521.998291 2746.296143,2522.005859 
	C2751.567139,2522.008545 2756.760010,2521.983887 2762.006104,2523.417969 
	C2766.389404,2524.616211 2771.219971,2524.915771 2775.322998,2521.775391 
	C2777.592041,2520.039062 2780.340820,2519.507080 2782.865723,2520.143311 
	C2790.184570,2521.986572 2798.010742,2521.452637 2805.486816,2525.431641 
	C2805.995361,2527.501709 2805.988525,2529.032471 2805.972168,2531.328613 
	C2801.180420,2536.437744 2796.185547,2534.961914 2791.207031,2532.568115 
	C2788.321777,2531.180420 2785.689453,2528.710938 2781.277588,2531.207764 
	C2786.957275,2535.635010 2793.345215,2537.552979 2799.590332,2537.877197 
	C2809.745605,2538.404785 2820.358398,2539.499023 2830.058594,2538.166504 
	C2846.633057,2535.889404 2862.027832,2538.836670 2877.508545,2542.824463 
	C2890.161865,2546.083740 2903.417725,2543.371582 2915.903564,2547.602051 
	C2919.083252,2548.679688 2916.406982,2546.598877 2916.363037,2546.097168 
	C2916.010742,2542.087891 2918.903809,2542.067627 2921.500244,2542.051514 
	C2932.786133,2541.981445 2944.074707,2541.849854 2955.358154,2542.119873 
	C2958.718262,2542.200439 2962.933350,2542.783936 2963.558594,2546.398193 
	C2964.289551,2550.621094 2959.565430,2550.886230 2956.728760,2552.118408 
	C2953.084229,2553.700928 2949.156982,2554.554688 2944.709229,2556.363770 
	C2929.847656,2561.777100 2915.670654,2562.337158 2900.706787,2556.810547 
	C2894.992432,2552.187256 2888.556885,2553.313232 2882.957031,2552.534180 
	C2858.095703,2549.075928 2833.299316,2544.418213 2807.944580,2545.963135 
	C2796.363281,2546.668701 2784.706055,2546.546875 2772.832031,2542.058838 
	C2767.395996,2546.195312 2762.067383,2544.731201 2756.915039,2543.702637 
	C2745.183594,2541.360596 2733.454102,2539.606201 2721.436035,2541.228271 
	C2717.487793,2541.761230 2713.568604,2541.998779 2710.054199,2544.222656 
	C2707.203857,2546.026367 2703.949219,2547.148682 2700.596680,2546.677734 
	C2689.391846,2545.104004 2678.209473,2545.004395 2668.739990,2547.003418 
	C2674.090332,2548.539551 2683.234375,2543.183105 2688.155762,2553.218262 
z"
        />
        <path
          fill="#6F7981"
          opacity="1.000000"
          stroke="none"
          d="
M232.632690,3438.360352 
	C228.120026,3434.274658 232.732117,3428.929688 230.013901,3424.629883 
	C229.902542,3424.453857 230.636032,3423.436768 231.140701,3423.244629 
	C232.102768,3422.878174 233.323349,3423.247559 233.538101,3424.194336 
	C234.134628,3426.824707 235.181686,3429.102051 237.650528,3431.414795 
	C238.772903,3432.833984 239.018173,3433.794922 238.106812,3435.367188 
	C236.287415,3437.033691 235.258804,3438.497314 232.632690,3438.360352 
z"
        />
        <path
          fill="#0D171F"
          opacity="1.000000"
          stroke="none"
          d="
M238.112885,3435.897461 
	C237.574203,3434.988281 237.668808,3434.132812 237.880768,3432.643066 
	C238.889847,3432.001953 239.781570,3431.995361 241.342346,3431.994629 
	C242.006378,3432.883545 242.001328,3433.767334 241.992798,3435.312988 
	C241.989288,3435.975098 241.001633,3435.999756 240.508118,3436.016602 
	C239.591797,3436.005859 239.168976,3435.978516 238.112885,3435.897461 
z"
        />
        <path
          fill="#607B93"
          opacity="1.000000"
          stroke="none"
          d="
M2790.146729,2444.714111 
	C2795.523438,2443.988281 2801.100586,2443.534180 2807.194824,2445.859375 
	C2804.718262,2452.595215 2804.325684,2453.135010 2799.377441,2450.001221 
	C2796.433838,2448.137207 2792.060059,2449.300781 2790.146729,2444.714111 
z"
        />
        <path
          fill="#D7D5CF"
          opacity="1.000000"
          stroke="none"
          d="
M1036.021729,3981.331543 
	C1030.272705,3984.083740 1030.272705,3984.083740 1026.013062,3979.374512 
	C1028.787598,3977.293701 1032.032104,3977.474609 1035.668701,3977.612549 
	C1036.140381,3978.908691 1036.098267,3979.789795 1036.021729,3981.331543 
z"
        />
        <path
          fill="#E7E3DA"
          opacity="1.000000"
          stroke="none"
          d="
M1007.252808,4017.287598 
	C1005.645081,4015.585205 1002.629883,4013.903320 1006.203186,4010.822266 
	C1008.623474,4009.991211 1011.463684,4008.461182 1012.203308,4011.616211 
	C1012.901306,4014.593750 1010.131531,4015.923828 1007.252808,4017.287598 
z"
        />
        <path
          fill="#9DAAB4"
          opacity="1.000000"
          stroke="none"
          d="
M975.655029,4027.060059 
	C973.721741,4022.124512 977.416870,4019.471924 980.349426,4016.597168 
	C980.859253,4016.097168 981.900574,4017.240234 981.491089,4019.025879 
	C979.329163,4021.883301 980.935669,4026.629883 975.655029,4027.060059 
z"
        />
        <path
          fill="#AAB7BE"
          opacity="1.000000"
          stroke="none"
          d="
M1002.049438,4005.209229 
	C1000.221497,4006.618896 999.325195,4009.892334 997.298462,4008.593018 
	C995.476257,4007.424805 996.384644,4005.058838 997.866760,4002.771484 
	C1000.249023,4001.393311 1002.126099,4001.227539 1002.049438,4005.209229 
z"
        />
        <path
          fill="#838F9A"
          opacity="1.000000"
          stroke="none"
          d="
M1068.114014,3993.848633 
	C1069.152954,3991.712646 1070.174072,3992.238525 1070.917236,3993.484863 
	C1071.124878,3993.832764 1070.831909,3994.826904 1070.457275,3995.105469 
	C1069.591675,3995.749023 1068.726074,3995.477783 1068.114014,3993.848633 
z"
        />
        <path
          fill="#9DAAB4"
          opacity="1.000000"
          stroke="none"
          d="
M997.808594,3999.281250 
	C996.476746,3999.743896 995.492798,4002.698975 994.201538,4000.642822 
	C993.120178,3998.920654 994.869385,3998.039307 997.264648,3998.010254 
	C997.928406,3998.135010 997.822632,3998.896973 997.808594,3999.281250 
z"
        />
        <path
          fill="#3A4B59"
          opacity="1.000000"
          stroke="none"
          d="
M1030.951172,4004.930176 
	C1031.036743,4004.883789 1030.865601,4004.976562 1030.951172,4004.930176 
z"
        />
        <path
          fill="#445E74"
          opacity="1.000000"
          stroke="none"
          d="
M2707.147461,2781.908203 
	C2710.985840,2782.208984 2714.119873,2781.888672 2716.909424,2782.721924 
	C2726.839844,2785.688477 2737.110107,2785.886963 2747.204346,2785.775391 
	C2762.200195,2785.609863 2777.054688,2786.953613 2791.918701,2788.252197 
	C2795.853027,2788.596191 2802.639404,2788.274170 2802.871826,2792.696045 
	C2803.179688,2798.560303 2795.923584,2796.580566 2792.486084,2797.115234 
	C2780.661133,2798.953125 2768.608887,2798.991699 2756.962891,2795.281006 
	C2753.033936,2794.029053 2749.161133,2793.760010 2745.159424,2794.305176 
	C2743.658936,2794.509277 2742.689453,2795.539062 2742.655762,2796.938477 
	C2742.622314,2798.337646 2743.546631,2799.620361 2745.037598,2799.621826 
	C2751.061523,2799.628174 2756.664062,2802.438965 2762.755859,2802.072510 
	C2767.048828,2801.813965 2769.777344,2803.755371 2769.985107,2809.183838 
	C2768.688232,2810.109375 2767.262207,2809.968750 2766.066406,2810.427979 
	C2763.193115,2811.531006 2760.271973,2813.225098 2761.053223,2816.786133 
	C2761.742432,2819.927734 2764.929199,2819.813232 2767.615967,2820.041992 
	C2776.646240,2820.811523 2785.837646,2820.357422 2794.712891,2823.634521 
	C2786.740967,2827.873779 2777.673828,2824.256104 2769.698242,2826.908203 
	C2769.437988,2829.092773 2770.560791,2829.798096 2771.957520,2829.731934 
	C2780.856445,2829.310791 2789.677979,2831.342285 2798.616943,2830.330322 
	C2804.521484,2829.662109 2809.104492,2834.134033 2815.260742,2835.540283 
	C2819.275146,2841.641602 2815.547119,2843.743164 2810.903076,2844.765869 
	C2805.741699,2845.902832 2800.423584,2846.041504 2794.328613,2845.720703 
	C2785.981201,2837.424316 2775.606201,2839.581787 2766.509521,2839.340820 
	C2754.047363,2839.010498 2742.089111,2836.136230 2729.878174,2834.687744 
	C2724.858643,2834.092529 2720.177002,2832.022949 2724.200439,2825.534180 
	C2726.210693,2822.291504 2723.246338,2822.436035 2721.949219,2821.637939 
	C2718.342529,2819.418213 2714.354248,2819.487549 2710.317871,2820.133789 
	C2706.668457,2820.718506 2703.007812,2820.906006 2699.427979,2819.748047 
	C2696.215332,2818.708984 2692.312744,2817.518555 2692.212646,2813.771973 
	C2692.091553,2809.229492 2696.551025,2809.813721 2699.701416,2809.084473 
	C2683.057373,2809.262695 2668.779785,2799.559326 2652.529053,2798.041260 
	C2649.507568,2797.759277 2646.690918,2795.398438 2647.351807,2791.808594 
	C2648.132324,2787.567383 2651.749756,2788.765137 2654.617188,2788.658447 
	C2645.024902,2789.279297 2635.702148,2792.223389 2624.667480,2790.791260 
	C2628.436523,2795.816162 2632.634521,2794.487793 2635.796387,2795.953613 
	C2638.663574,2797.282959 2641.444824,2798.891357 2640.625000,2802.521484 
	C2639.860840,2805.904053 2636.655518,2805.694336 2634.040283,2805.478760 
	C2622.737793,2804.546631 2611.266113,2802.871582 2600.651855,2799.713379 
	C2586.517822,2795.507568 2576.834961,2804.400146 2565.066162,2807.094971 
	C2552.350830,2810.006836 2540.908936,2817.829346 2526.925049,2816.199707 
	C2516.587402,2814.995361 2506.259766,2810.326660 2494.989258,2815.150879 
	C2492.466064,2816.249512 2490.523193,2816.368164 2487.844238,2815.871582 
	C2484.278809,2814.115479 2481.913818,2810.184326 2477.285889,2813.956787 
	C2473.281494,2815.058105 2469.930176,2815.058594 2465.897461,2814.192871 
	C2461.080566,2811.566406 2457.208984,2811.074951 2453.440674,2816.036621 
	C2443.960205,2820.405762 2434.645996,2821.365723 2424.220215,2820.410889 
	C2410.450928,2816.223877 2397.226318,2816.124023 2384.073975,2818.144287 
	C2367.593994,2820.675049 2350.911621,2818.259521 2334.443604,2820.843262 
	C2330.224854,2821.504883 2326.011719,2821.753906 2321.800537,2822.905762 
	C2313.206787,2825.256348 2308.195312,2821.555908 2307.407227,2811.937744 
	C2317.516113,2806.154053 2327.871094,2809.291016 2338.725586,2808.538086 
	C2342.859131,2809.277344 2345.993896,2809.112305 2349.509521,2806.645264 
	C2352.705811,2805.248535 2355.324463,2805.240479 2358.691406,2805.310547 
	C2367.052979,2807.118652 2374.506836,2806.941162 2382.097412,2804.618408 
	C2393.319580,2801.184570 2404.474365,2805.851318 2415.739746,2805.762207 
	C2421.261230,2805.718262 2427.074707,2806.228516 2432.579590,2807.319092 
	C2440.808594,2808.949951 2440.053223,2801.548584 2442.917969,2797.809570 
	C2442.998291,2797.704590 2441.467773,2796.634521 2440.145264,2795.574219 
	C2436.968994,2790.853027 2437.237793,2786.966064 2441.781738,2784.398193 
	C2444.649170,2782.778320 2445.126221,2780.795898 2444.328613,2777.250000 
	C2444.494629,2775.423096 2444.896484,2774.450439 2446.159180,2773.024414 
	C2453.007080,2769.917480 2459.338379,2771.009033 2466.398926,2772.119629 
	C2471.914551,2776.718750 2468.519043,2779.694092 2465.361328,2782.690674 
	C2473.043213,2785.527100 2480.605469,2782.656738 2488.157471,2782.847168 
	C2513.854248,2783.494629 2539.584961,2782.427246 2565.260254,2784.400146 
	C2568.959229,2784.684570 2572.557861,2784.224365 2575.889404,2780.682373 
	C2591.554932,2776.689209 2606.789062,2776.207031 2622.070312,2776.565918 
	C2642.169189,2777.038330 2662.280273,2776.031006 2681.872803,2783.171631 
	C2689.309082,2785.881592 2698.218506,2784.033691 2707.147461,2781.908203 
z"
        />
        <path
          fill="#50677C"
          opacity="1.000000"
          stroke="none"
          d="
M2791.197021,2843.322266 
	C2797.677002,2842.245605 2803.579834,2841.672119 2809.594971,2841.983154 
	C2812.793945,2842.148438 2815.714355,2841.703369 2815.898193,2836.682129 
	C2821.977051,2835.969727 2828.045898,2836.085693 2835.015625,2836.278320 
	C2840.188965,2840.294922 2846.002441,2839.279053 2851.522949,2841.488770 
	C2854.702393,2844.607422 2858.092529,2843.594482 2861.199951,2843.839111 
	C2865.005615,2844.137939 2868.924316,2843.503906 2873.292480,2845.598145 
	C2874.030518,2846.798340 2874.011963,2847.699951 2873.987061,2849.276367 
	C2868.843506,2854.151123 2863.460938,2850.959717 2858.196045,2850.407227 
	C2854.343750,2850.002930 2850.550537,2849.077393 2845.894531,2848.965332 
	C2839.494385,2849.354248 2833.822998,2848.816895 2828.199219,2849.483154 
	C2824.129395,2849.965576 2819.356445,2848.991943 2818.386719,2855.318848 
	C2818.017822,2857.727295 2814.964355,2858.658691 2812.671387,2858.490967 
	C2803.420898,2857.814941 2794.061768,2859.845947 2784.908691,2857.610352 
	C2769.350830,2853.810303 2753.855957,2850.520508 2738.237061,2857.371826 
	C2736.085693,2858.315918 2733.668213,2858.082031 2733.135498,2857.925537 
	C2741.380615,2858.777832 2751.439453,2859.460693 2762.293457,2859.636963 
	C2768.249268,2859.113525 2773.048340,2860.514160 2778.312744,2863.319092 
	C2780.246338,2866.185547 2780.377197,2868.522461 2779.034912,2871.726807 
	C2775.522461,2875.259277 2771.757568,2874.797852 2767.230957,2874.906738 
	C2749.056885,2873.691162 2732.149414,2869.897949 2714.225586,2867.182617 
	C2698.509033,2864.311035 2683.614746,2861.730225 2667.600098,2863.911621 
	C2665.230713,2864.709473 2663.604736,2864.971680 2661.147705,2865.062744 
	C2643.677979,2869.502686 2627.356445,2866.438965 2611.150391,2862.517334 
	C2601.158203,2860.099365 2591.319824,2857.163330 2581.017090,2856.065674 
	C2577.577637,2855.699463 2572.980713,2854.294922 2573.582520,2849.642334 
	C2574.180908,2845.015869 2578.824219,2845.308350 2582.413574,2845.205566 
	C2586.076416,2845.100342 2589.689697,2845.809570 2593.246582,2846.706055 
	C2587.859619,2843.468262 2578.823730,2846.183105 2577.519043,2836.122314 
	C2579.783203,2833.837402 2582.024902,2833.515869 2585.244629,2833.656494 
	C2599.707031,2837.846191 2613.755371,2836.140869 2628.440430,2837.048340 
	C2632.575928,2836.735352 2635.862305,2836.617188 2639.885254,2837.310791 
	C2648.520508,2839.993408 2656.331787,2842.832520 2664.348633,2844.129883 
	C2660.090820,2842.984375 2654.536865,2843.763428 2653.555664,2836.571777 
	C2654.331787,2835.366943 2654.794189,2834.680664 2655.376221,2834.593262 
	C2677.103271,2831.336914 2699.120850,2829.520752 2720.240234,2836.148682 
	C2730.189453,2839.271240 2739.961914,2841.303711 2750.985352,2842.477539 
	C2754.813477,2843.298828 2757.256104,2842.002930 2760.444824,2840.183105 
	C2771.273193,2838.048828 2780.895996,2840.383545 2791.197021,2843.322266 
z"
        />
        <path
          fill="#445E74"
          opacity="1.000000"
          stroke="none"
          d="
M2778.573242,2810.697754 
	C2778.019531,2807.081299 2778.358887,2804.125488 2782.465820,2804.117432 
	C2786.258057,2804.110107 2790.370605,2802.994873 2793.650146,2807.416016 
	C2793.614746,2810.660645 2792.082520,2811.989990 2789.485352,2811.948730 
	C2786.031250,2811.894043 2782.512695,2812.652588 2778.573242,2810.697754 
z"
        />
        <path
          fill="#445E74"
          opacity="1.000000"
          stroke="none"
          d="
M2851.894043,2841.224365 
	C2846.409424,2842.113525 2840.743164,2842.223389 2836.121826,2837.025635 
	C2841.055176,2838.199951 2847.187500,2836.263672 2851.894043,2841.224365 
z"
        />
        <path
          fill="#122230"
          opacity="1.000000"
          stroke="none"
          d="
M159.730957,3369.955811 
	C155.779175,3374.522461 150.007523,3374.225830 143.637695,3374.453125 
	C135.806503,3374.891357 128.904602,3375.182861 122.518608,3379.519531 
	C117.459023,3382.955322 97.805542,3384.317627 92.367538,3383.050537 
	C91.216721,3382.782471 90.667351,3382.115967 90.437927,3381.123779 
	C90.037094,3379.390625 90.264610,3377.602051 91.974312,3376.850586 
	C93.423889,3376.212891 95.172501,3376.262695 96.785255,3375.987549 
	C102.982315,3374.929443 109.012108,3373.502930 114.758240,3370.696045 
	C125.945366,3365.230469 138.199326,3364.955078 150.303253,3363.952881 
	C154.914062,3363.571045 156.264999,3367.519531 159.730957,3369.955811 
z"
        />
        <path
          fill="#122230"
          opacity="1.000000"
          stroke="none"
          d="
M83.287994,3379.143555 
	C84.774689,3378.727295 85.314323,3379.246338 85.083565,3380.401123 
	C85.035545,3380.640869 84.695549,3380.963135 84.453224,3380.999512 
	C83.287926,3381.172852 82.798195,3380.606445 83.287994,3379.143555 
z"
        />
        <path
          fill="#172837"
          opacity="1.000000"
          stroke="none"
          d="
M2983.076904,3524.947998 
	C2983.125977,3525.037109 2983.027832,3524.858887 2983.076904,3524.947998 
z"
        />
        <path
          fill="#445E74"
          opacity="1.000000"
          stroke="none"
          d="
M2778.360352,2872.177246 
	C2777.931396,2869.613281 2777.963867,2867.568848 2777.998047,2864.757324 
	C2773.906982,2859.068604 2767.954834,2859.949463 2762.567627,2859.088623 
	C2762.529053,2859.082764 2762.329834,2859.679688 2762.321533,2859.998047 
	C2762.313477,2860.321045 2762.448730,2860.648193 2762.263672,2861.542480 
	C2761.037109,2863.057373 2759.964111,2863.961426 2758.530273,2863.630127 
	C2748.207275,2861.242676 2737.542236,2861.830078 2727.155762,2859.986816 
	C2725.535156,2859.699219 2723.760986,2859.896729 2722.490479,2858.681641 
	C2721.854736,2858.073486 2721.360596,2857.114502 2721.215820,2856.245117 
	C2721.015137,2855.039307 2722.101074,2854.574219 2723.062256,2854.352783 
	C2724.019775,2854.132568 2725.202148,2853.753662 2725.989014,2854.106445 
	C2733.113525,2857.302734 2739.365967,2853.590088 2745.768799,2851.628174 
	C2752.308350,2849.624512 2758.598633,2850.072266 2765.302002,2851.203613 
	C2773.094971,2852.518066 2780.900635,2854.504150 2788.581055,2855.239258 
	C2797.695068,2856.111328 2807.230957,2855.664551 2816.213867,2855.772461 
	C2817.723389,2851.438965 2812.206543,2852.421387 2813.193604,2849.760010 
	C2814.246582,2846.921875 2816.932129,2846.948975 2819.744141,2846.792480 
	C2827.299072,2846.372314 2834.834717,2845.718018 2843.328857,2846.132812 
	C2847.352783,2849.485107 2855.549805,2851.507080 2847.721924,2857.535400 
	C2863.889404,2856.862305 2880.682617,2855.191406 2897.224609,2859.896484 
	C2899.482422,2860.538818 2901.717285,2860.921387 2903.615723,2863.455566 
	C2902.573730,2871.484375 2908.012939,2871.938965 2913.470703,2873.431152 
	C2914.761230,2874.741943 2915.056885,2875.682617 2914.442383,2877.406250 
	C2906.337891,2880.753906 2904.514893,2880.373535 2900.671875,2875.065186 
	C2898.983887,2872.733154 2898.524658,2868.908203 2895.275879,2868.475586 
	C2890.769531,2867.875488 2886.114990,2868.012207 2881.554932,2868.308594 
	C2877.963135,2868.542236 2878.161377,2871.676758 2878.000244,2875.128906 
	C2877.221191,2876.848877 2876.308594,2877.556152 2874.430908,2878.345459 
	C2860.205322,2880.425049 2847.133545,2879.229980 2834.584961,2874.000000 
	C2822.716553,2869.053467 2809.838135,2869.657471 2796.791260,2866.949707 
	C2792.366455,2866.246338 2789.061035,2866.452881 2786.379395,2869.659424 
	C2784.507324,2871.898193 2782.186768,2873.803467 2778.360352,2872.177246 
z"
        />
        <path
          fill="#294159"
          opacity="1.000000"
          stroke="none"
          d="
M2777.043701,2871.745605 
	C2782.217773,2871.826904 2784.572754,2869.205566 2785.817627,2865.584473 
	C2787.251709,2861.411865 2789.789795,2862.004639 2793.395752,2863.757812 
	C2798.610107,2868.437744 2804.667969,2869.030273 2810.416504,2870.314941 
	C2815.583740,2871.469971 2820.776611,2872.550781 2825.330566,2875.364258 
	C2829.662598,2878.040527 2830.656006,2881.154785 2825.243652,2884.891602 
	C2823.766357,2886.279785 2821.964600,2887.499268 2822.396240,2888.315918 
	C2823.325928,2890.075684 2822.941650,2888.042725 2823.003662,2887.833252 
	C2823.188477,2887.207764 2823.393066,2886.603760 2824.392578,2885.613770 
	C2826.366943,2884.222656 2828.209229,2883.334717 2829.592773,2883.983887 
	C2843.569336,2890.541016 2855.721924,2880.580811 2868.860596,2879.786377 
	C2870.539795,2879.685059 2871.722900,2878.257812 2873.439697,2876.588867 
	C2874.854004,2876.029785 2875.750244,2876.005615 2877.318848,2875.969727 
	C2881.402344,2885.916260 2891.097168,2892.092285 2893.916504,2903.145996 
	C2891.732178,2903.314697 2889.546631,2902.745605 2886.680420,2902.100586 
	C2872.367432,2901.931152 2858.841553,2900.621582 2845.375244,2898.551514 
	C2843.243652,2898.223877 2840.897217,2896.585938 2838.557129,2899.609375 
	C2829.131836,2901.967773 2820.873291,2899.097168 2812.794922,2895.718994 
	C2810.100098,2894.592285 2807.809570,2892.499023 2804.758789,2890.491943 
	C2796.928711,2887.189453 2788.895264,2887.327148 2781.625000,2884.496826 
	C2775.422363,2882.082275 2767.891357,2881.632324 2765.512695,2873.115723 
	C2769.303711,2872.214600 2772.745850,2872.023926 2777.043701,2871.745605 
z"
        />
        <path
          fill="#294159"
          opacity="1.000000"
          stroke="none"
          d="
M2904.725342,2864.081543 
	C2888.583740,2859.043701 2872.720947,2859.903320 2856.941406,2860.041260 
	C2850.096924,2860.100830 2843.592529,2858.944580 2837.004639,2856.694824 
	C2840.541016,2850.829834 2845.243164,2857.931152 2848.768311,2854.547852 
	C2848.827393,2851.245850 2844.153809,2850.836914 2844.264404,2846.844238 
	C2853.883545,2845.431152 2863.045410,2848.250732 2873.169922,2849.939941 
	C2879.080566,2849.255371 2882.444092,2853.354980 2886.885742,2854.651611 
	C2899.354736,2858.292969 2912.266846,2859.353027 2924.935303,2861.783203 
	C2927.096191,2862.197754 2929.587402,2861.603516 2931.782227,2860.973633 
	C2936.092041,2859.737061 2940.202637,2859.965820 2944.521729,2861.103027 
	C2957.717773,2864.576904 2971.108887,2866.677734 2984.834473,2866.027832 
	C2993.579102,2865.613525 3001.861816,2868.375977 3011.115479,2870.038574 
	C3011.536133,2872.930176 3009.723389,2874.007080 3007.072754,2873.951660 
	C2994.953125,2873.697510 2982.994873,2873.540039 2971.059082,2869.889404 
	C2959.568604,2866.375000 2947.726562,2863.417236 2935.311035,2868.856445 
	C2929.002930,2871.619873 2921.818604,2867.818604 2915.090820,2866.593506 
	C2911.856934,2866.004639 2908.712158,2864.927246 2904.725342,2864.081543 
z"
        />
        <path
          fill="#546D83"
          opacity="1.000000"
          stroke="none"
          d="
M2914.934326,2878.037109 
	C2914.000000,2877.132568 2914.000000,2876.222900 2914.000000,2874.630127 
	C2923.331299,2873.947754 2932.669922,2873.714844 2941.991943,2874.009766 
	C2952.556641,2874.343750 2962.964844,2875.169678 2972.942383,2879.724854 
	C2980.168457,2883.023926 2988.417969,2883.040283 2996.305664,2884.055908 
	C3004.006836,2885.047363 3011.937012,2885.359375 3018.854980,2889.173828 
	C3018.386230,2891.772217 3016.789795,2891.983154 3015.757324,2891.395752 
	C3008.692383,2887.379395 3000.986084,2887.918213 2993.351807,2888.011230 
	C2986.010010,2888.100830 2979.116211,2886.499512 2971.929688,2884.803711 
	C2953.603271,2880.478516 2935.066162,2875.938721 2914.934326,2878.037109 
z"
        />
        <path
          fill="#294159"
          opacity="1.000000"
          stroke="none"
          d="
M2968.479492,2898.029053 
	C2957.340820,2901.031250 2952.309814,2900.580078 2945.458252,2896.307617 
	C2953.667725,2896.349609 2961.125000,2894.670410 2968.479492,2898.029053 
z"
        />
        <path
          fill="#294159"
          opacity="1.000000"
          stroke="none"
          d="
M2980.451172,2896.008057 
	C2983.688232,2896.343506 2986.478760,2895.110596 2988.991455,2898.086182 
	C2985.697998,2901.280273 2981.688477,2899.593018 2978.157227,2899.800537 
	C2977.058838,2899.864746 2975.139648,2899.641357 2975.609619,2897.739990 
	C2976.113525,2895.700684 2978.150879,2896.248047 2980.451172,2896.008057 
z"
        />
        <path
          fill="#FBF9EC"
          opacity="1.000000"
          stroke="none"
          d="
M1110.471802,3941.409668 
	C1110.014648,3944.805664 1110.005127,3947.572754 1109.990234,3951.169922 
	C1109.891113,3954.348633 1110.305786,3956.773193 1108.480957,3959.471191 
	C1108.014648,3960.009766 1108.000000,3960.000000 1108.006348,3960.005127 
	C1106.707153,3961.731445 1104.477173,3961.896729 1102.385864,3963.468262 
	C1098.703857,3963.204346 1095.486450,3962.549072 1091.423218,3961.832764 
	C1092.940796,3949.008789 1095.304077,3936.245850 1097.461304,3922.700684 
	C1097.382324,3921.060791 1097.509399,3920.203369 1097.523682,3918.703125 
	C1098.031982,3912.545166 1098.652954,3907.029785 1099.575195,3900.753662 
	C1101.544067,3900.289307 1103.280029,3900.833984 1103.580078,3902.658447 
	C1105.469238,3914.150391 1107.225586,3925.664062 1109.479980,3937.598145 
	C1110.753174,3938.768799 1111.081543,3939.687012 1110.471802,3941.409668 
z"
        />
        <path
          fill="#2B3B46"
          opacity="1.000000"
          stroke="none"
          d="
M993.987183,4017.986572 
	C994.347412,4014.691162 995.572571,4012.256348 1000.751160,4012.437256 
	C998.790649,4014.826904 997.756409,4016.902100 994.635132,4017.918457 
	C994.024719,4018.019531 993.998779,4017.999756 993.987183,4017.986572 
z"
        />
        <path
          fill="#7995AC"
          opacity="1.000000"
          stroke="none"
          d="
M2933.628906,2008.645020 
	C2934.009766,2008.000000 2934.995361,2007.990234 2935.487793,2007.985352 
	C2941.592529,2014.480835 2940.851318,2017.507324 2933.307617,2019.255737 
	C2931.645996,2019.640747 2929.902100,2020.040039 2928.530518,2019.113525 
	C2925.625732,2017.151123 2919.570557,2019.865723 2919.460449,2015.559692 
	C2919.336426,2010.724976 2924.882812,2012.426880 2928.191406,2011.963745 
	C2930.250732,2011.675415 2931.982422,2010.977905 2933.628906,2008.645020 
z"
        />
        <path
          fill="#7995AC"
          opacity="1.000000"
          stroke="none"
          d="
M2961.296387,2018.035645 
	C2958.559326,2018.074585 2956.584229,2018.068237 2954.621094,2018.219604 
	C2952.837646,2018.357178 2951.083496,2019.936646 2949.130859,2018.412231 
	C2957.806641,2010.130127 2961.785156,2010.017456 2969.232422,2018.015625 
	C2966.784180,2020.414307 2964.349121,2017.769165 2961.296387,2018.035645 
z"
        />
        <path
          fill="#859CAF"
          opacity="1.000000"
          stroke="none"
          d="
M3018.563232,2033.043701 
	C3018.907227,2035.767334 3017.465820,2035.838135 3015.836914,2035.245483 
	C3015.458496,2035.107910 3015.024414,2034.136230 3015.163574,2033.740356 
	C3015.670410,2032.294800 3016.882324,2032.258179 3018.563232,2033.043701 
z"
        />
        <path
          fill="#7995AC"
          opacity="1.000000"
          stroke="none"
          d="
M2895.593262,2049.528564 
	C2894.885742,2051.684570 2893.177002,2051.977051 2890.676758,2051.986572 
	C2890.479980,2048.812744 2891.895996,2047.292236 2895.593262,2049.528564 
z"
        />
        <path
          fill="#5D758A"
          opacity="1.000000"
          stroke="none"
          d="
M100.936447,2645.147705 
	C113.412346,2644.443115 125.258835,2644.271484 136.820663,2641.806152 
	C146.745163,2639.689941 157.080215,2639.667725 166.680252,2635.877930 
	C167.968781,2635.369385 169.043640,2634.835205 169.977173,2632.956787 
	C170.767975,2631.309082 171.545761,2630.586182 173.300812,2629.820557 
	C176.170563,2629.074463 178.304657,2628.904297 181.263962,2629.031738 
	C184.804871,2629.186035 187.434052,2628.732178 190.740173,2627.318359 
	C203.653595,2623.584961 216.027954,2624.446533 229.259918,2624.868408 
	C253.288147,2622.468994 276.428925,2625.899658 299.628265,2624.729248 
	C320.641876,2623.669189 341.507965,2622.836914 361.831665,2616.825439 
	C365.658142,2615.693604 369.629425,2614.963135 374.498474,2616.394531 
	C377.038483,2617.861816 377.548401,2619.533203 377.383545,2622.305908 
	C376.918091,2623.667236 376.626801,2624.164307 375.681763,2625.202637 
	C357.007507,2632.421143 339.136383,2639.338135 319.135101,2635.514893 
	C313.959198,2634.525635 307.556610,2634.195801 304.486603,2641.891602 
	C303.515045,2644.228027 302.477234,2645.531982 300.494873,2647.130615 
	C293.819458,2649.966064 287.681030,2649.433350 280.758942,2648.497559 
	C278.041931,2647.742432 276.419983,2646.547363 274.027893,2645.384766 
	C271.578400,2645.130615 269.797516,2644.913330 267.341858,2644.974365 
	C263.288177,2645.002441 260.975037,2647.248535 257.768311,2649.298584 
	C255.704666,2650.063232 254.251572,2650.188232 252.040222,2650.002441 
	C225.964508,2637.296875 199.036438,2644.062744 170.210037,2643.311768 
	C177.836670,2644.009766 182.847427,2645.100342 187.498016,2647.341797 
	C190.928879,2648.996094 193.890167,2651.189453 192.154282,2656.415283 
	C182.673843,2660.021973 173.729416,2656.599854 163.939529,2656.802734 
	C155.934494,2655.527100 148.851364,2655.734619 141.689102,2657.224121 
	C130.360443,2659.580566 118.843079,2659.541260 107.342842,2658.865723 
	C102.978294,2658.609619 98.970222,2657.208984 95.386238,2653.180908 
	C94.150490,2650.851074 94.043419,2649.168945 94.916954,2646.670166 
	C96.721916,2644.601562 98.375290,2644.333252 100.936447,2645.147705 
z"
        />
        <path
          fill="#5D758A"
          opacity="1.000000"
          stroke="none"
          d="
M196.817993,2590.436035 
	C193.162109,2589.343994 190.320160,2588.687988 186.742157,2588.010986 
	C183.521271,2584.264893 186.368530,2582.102539 189.085663,2579.219727 
	C191.196960,2577.924561 192.772583,2577.504883 195.232758,2577.223145 
	C197.693665,2576.983398 199.336639,2576.812256 201.817581,2577.045654 
	C205.427689,2577.085938 208.208725,2577.114990 211.830780,2576.992432 
	C220.007782,2576.146973 227.360931,2576.688477 235.534607,2576.731934 
	C239.780457,2577.434570 242.812744,2576.491211 246.372238,2574.201904 
	C261.958679,2571.371338 276.913483,2575.079834 292.574677,2572.895508 
	C295.747559,2572.977539 298.226227,2572.093750 300.375885,2572.899902 
	C320.918304,2580.604248 342.440033,2576.400391 363.400635,2578.566162 
	C370.916138,2579.342529 379.529114,2578.697754 386.290222,2585.107422 
	C378.933380,2594.018066 369.222778,2593.260498 358.784180,2592.496826 
	C354.529510,2590.721680 351.078857,2588.467041 346.615906,2592.075684 
	C333.038727,2593.144531 320.232941,2595.733887 306.467041,2593.075439 
	C293.272247,2593.708496 281.277374,2597.877197 267.870178,2596.410156 
	C262.069824,2594.021729 256.867554,2593.528809 251.574005,2593.671631 
	C237.751282,2594.044434 223.952271,2592.741943 210.138718,2593.067139 
	C205.745941,2593.170654 201.706818,2592.130859 196.817993,2590.436035 
z"
        />
        <path
          fill="#374D60"
          opacity="1.000000"
          stroke="none"
          d="
M174.511368,2631.360840 
	C173.180008,2632.024658 172.329636,2632.099609 170.843307,2632.219971 
	C165.936981,2632.174072 161.652008,2631.812744 157.399063,2632.043213 
	C145.810669,2632.670898 134.495926,2631.390137 122.612900,2627.798828 
	C120.087448,2622.029297 124.671799,2621.918945 127.915077,2621.245361 
	C133.604965,2620.063721 139.360413,2619.111328 144.638626,2618.851562 
	C138.116852,2616.748779 129.833542,2617.782715 125.284393,2609.448730 
	C127.757072,2605.073975 131.520035,2605.360107 135.108185,2605.210449 
	C145.945129,2604.757568 156.786087,2604.484375 168.432846,2604.550781 
	C171.363281,2604.755615 173.293549,2604.216553 175.749298,2602.679199 
	C180.572754,2601.008057 184.606125,2601.929443 189.399048,2602.911865 
	C196.257065,2603.223389 202.484772,2603.981934 208.017212,2598.500488 
	C213.618576,2596.666992 218.554733,2596.935791 224.263855,2598.024902 
	C225.962494,2598.895752 226.688370,2599.655762 227.397675,2601.369629 
	C227.961182,2605.151855 226.266098,2607.777832 225.684601,2611.369873 
	C218.420059,2621.232666 209.025284,2615.149902 200.859940,2615.214844 
	C191.516037,2615.289307 182.469727,2613.847900 176.841187,2624.397461 
	C176.691452,2627.169678 176.253220,2629.102051 174.511368,2631.360840 
z"
        />
        <path
          fill="#627A90"
          opacity="1.000000"
          stroke="none"
          d="
M172.412109,2607.099365 
	C158.164948,2608.097412 144.484467,2608.690674 130.781097,2608.198975 
	C129.550018,2608.154785 128.293854,2608.809814 126.585129,2609.720703 
	C124.191521,2612.636963 121.422142,2612.761963 117.925400,2613.126465 
	C114.225334,2613.189697 112.033981,2615.076904 108.663116,2616.308105 
	C97.678024,2617.506592 87.484116,2619.245361 76.834465,2614.707764 
	C72.719124,2611.681641 67.532417,2612.513916 65.034645,2608.193359 
	C66.943718,2604.583496 70.307632,2606.027588 72.982605,2606.053711 
	C96.458908,2606.281738 119.720154,2604.143799 142.679047,2599.363770 
	C146.888504,2598.487549 150.454147,2600.609863 155.153168,2599.929443 
	C156.772202,2599.105469 157.680130,2598.412842 159.425537,2598.118896 
	C164.935471,2597.877686 169.614365,2597.351807 174.532150,2595.747314 
	C182.011887,2593.306885 190.235901,2593.734619 199.108154,2594.015869 
	C206.518051,2592.146484 213.170517,2593.061279 219.753189,2593.186523 
	C223.091415,2593.249756 226.454391,2594.167236 226.368774,2599.416992 
	C220.659851,2600.249756 215.357681,2600.245850 209.175690,2600.250000 
	C203.139572,2603.872314 196.894394,2603.480713 190.535339,2605.353516 
	C185.910080,2605.281738 181.896088,2604.775635 177.116119,2604.288086 
	C175.149200,2604.997314 174.101013,2605.903320 172.412109,2607.099365 
z"
        />
        <path
          fill="#243748"
          opacity="1.000000"
          stroke="none"
          d="
M76.050713,2614.930176 
	C86.663399,2613.910400 97.192322,2613.691895 108.626190,2613.458008 
	C111.551750,2613.118164 113.323029,2612.143311 115.678474,2611.015381 
	C118.961487,2610.548584 121.707840,2610.358398 125.287544,2610.234863 
	C134.992569,2614.448975 144.585800,2615.968018 154.374603,2617.665039 
	C151.392853,2620.989990 147.899323,2621.658203 144.065384,2621.995117 
	C137.531342,2622.568848 131.026611,2623.512695 124.532463,2624.458740 
	C123.728851,2624.575928 123.064987,2625.652832 122.065796,2626.992676 
	C114.508919,2627.792236 107.184654,2628.396484 99.944687,2627.861084 
	C87.625198,2626.950439 75.623795,2630.052246 63.410400,2630.015381 
	C60.445908,2630.006348 57.376690,2630.385010 54.584515,2629.101807 
	C52.004585,2627.916016 48.239597,2627.269775 48.995884,2623.334473 
	C49.598801,2620.197021 52.722221,2620.012207 55.434364,2619.980469 
	C60.402714,2619.922363 65.371681,2619.906494 70.340431,2619.898682 
	C73.258904,2619.894043 75.615990,2619.179688 76.050713,2614.930176 
z"
        />
        <path
          fill="#566C80"
          opacity="1.000000"
          stroke="none"
          d="
M225.957169,2600.862549 
	C225.548950,2596.267822 222.687759,2596.141602 219.589813,2595.966309 
	C213.468094,2595.619873 207.266525,2597.003174 200.613678,2594.578125 
	C198.889694,2592.996094 197.770264,2591.972900 196.327454,2590.474609 
	C216.258026,2589.996826 236.512024,2589.974609 256.765930,2590.009521 
	C260.327820,2590.015625 264.219818,2589.147217 267.282471,2593.438965 
	C270.827240,2598.092041 269.183136,2600.616943 264.820740,2601.730713 
	C262.056580,2602.436523 258.969330,2601.731689 255.638977,2603.874512 
	C245.680832,2605.040771 236.608582,2608.732910 226.656555,2604.538086 
	C226.005554,2603.099854 225.978943,2602.285645 225.957169,2600.862549 
z"
        />
        <path
          fill="#5D758A"
          opacity="1.000000"
          stroke="none"
          d="
M104.236794,2590.798096 
	C113.459488,2590.544434 122.553528,2586.804443 132.905670,2587.890625 
	C138.374237,2580.747070 147.028610,2579.777588 153.876465,2574.666504 
	C158.121628,2572.784424 161.899979,2573.259277 166.425232,2573.104492 
	C168.327820,2573.093018 169.417221,2573.110840 171.328125,2573.218262 
	C174.970413,2573.597168 177.480087,2574.632812 180.142639,2577.133301 
	C181.975845,2582.912354 178.306625,2584.026611 174.681122,2584.786377 
	C171.504959,2585.451904 168.120819,2585.148926 164.578674,2587.431885 
	C156.937073,2587.438965 150.193268,2588.981445 143.638092,2591.165283 
	C132.092834,2595.011963 120.230881,2593.995850 108.418114,2593.939453 
	C106.691856,2593.931152 105.232475,2593.254395 104.236794,2590.798096 
z"
        />
        <path
          fill="#2F4457"
          opacity="1.000000"
          stroke="none"
          d="
M101.724518,2644.810547 
	C100.187737,2645.794922 98.688240,2646.000000 96.453217,2646.397217 
	C90.626732,2652.251953 83.929695,2651.046143 76.592072,2650.729980 
	C70.673103,2647.140137 61.985916,2652.257324 59.608681,2642.807617 
	C71.419861,2640.674072 82.791893,2639.332520 94.274300,2639.976562 
	C97.341583,2640.148438 101.255951,2639.019043 101.724518,2644.810547 
z"
        />
        <path
          fill="#566C80"
          opacity="1.000000"
          stroke="none"
          d="
M164.709717,2587.948975 
	C165.021225,2583.004150 168.933548,2582.424805 172.947021,2582.025635 
	C175.456711,2581.775635 178.351730,2582.536377 179.875290,2578.621582 
	C181.216064,2574.486084 183.937790,2573.897705 187.757324,2574.165527 
	C188.897461,2574.540527 189.328003,2574.759766 190.245544,2575.512695 
	C191.065414,2576.965576 191.121902,2577.901123 190.452332,2579.410889 
	C190.174759,2583.015381 186.889313,2584.113525 186.111603,2587.337891 
	C185.042038,2588.003174 183.915680,2587.692139 183.139465,2588.080322 
	C177.199615,2591.053711 171.338913,2590.152832 164.709717,2587.948975 
z"
        />
        <path
          fill="#3F5467"
          opacity="1.000000"
          stroke="none"
          d="
M59.367027,2642.095947 
	C60.399857,2645.319092 62.617886,2645.985107 65.413795,2645.983643 
	C68.549332,2645.981689 71.745697,2645.682617 75.352455,2647.692139 
	C77.968407,2653.403320 73.771500,2654.223145 70.424873,2654.931396 
	C64.620186,2656.158691 58.751923,2656.197998 52.602646,2652.488281 
	C51.974060,2649.651123 52.015148,2647.571289 52.091820,2644.709473 
	C54.311737,2643.334229 56.496078,2642.741211 59.367027,2642.095947 
z"
        />
        <path
          fill="#566C80"
          opacity="1.000000"
          stroke="none"
          d="
M159.662598,2597.650635 
	C159.043289,2598.689209 158.084076,2599.286865 156.532867,2599.904053 
	C156.392380,2598.177002 156.451874,2595.941650 159.662598,2597.650635 
z"
        />
        <path
          fill="#748B9F"
          opacity="1.000000"
          stroke="none"
          d="
M62.111786,2195.356934 
	C57.701225,2196.609375 53.969341,2199.733643 49.436188,2196.701416 
	C56.506680,2193.093018 58.585365,2192.844238 62.111786,2195.356934 
z"
        />
        <path
          fill="#8DA0AE"
          opacity="1.000000"
          stroke="none"
          d="
M7.527232,2167.164307 
	C7.636333,2166.802490 8.026729,2166.506104 8.137958,2166.588379 
	C8.814682,2167.087646 8.633551,2167.397949 7.527232,2167.164307 
z"
        />
        <path
          fill="#233444"
          opacity="1.000000"
          stroke="none"
          d="
M76.294189,3038.576660 
	C78.270660,3038.100830 80.733429,3037.777344 81.917313,3041.283447 
	C79.751114,3041.981201 77.886444,3041.121094 76.294189,3038.576660 
z"
        />
        <path
          fill="#172B3E"
          opacity="1.000000"
          stroke="none"
          d="
M2858.070557,3130.742676 
	C2860.339111,3129.908936 2862.630371,3130.161865 2865.353027,3131.705322 
	C2866.415283,3136.623291 2869.399170,3138.291016 2873.535156,3137.924072 
	C2880.583252,3137.298340 2887.166992,3140.125000 2894.160156,3140.319336 
	C2898.717529,3140.446045 2903.891846,3141.587646 2906.595459,3146.335449 
	C2905.905029,3147.095215 2905.695068,3147.516113 2905.506592,3147.506592 
	C2892.383057,3146.851074 2879.229736,3146.295898 2866.672607,3141.851807 
	C2861.831543,3140.138184 2860.362549,3135.446045 2858.070557,3130.742676 
z"
        />
        <path
          fill="#102131"
          opacity="1.000000"
          stroke="none"
          d="
M2832.749512,3150.004883 
	C2827.988281,3145.601318 2821.645752,3150.191895 2817.287109,3146.352783 
	C2824.682129,3142.866699 2828.991699,3143.370850 2837.831787,3149.339355 
	C2836.496338,3150.054688 2834.997803,3150.032227 2832.749512,3150.004883 
z"
        />
        <path
          fill="#2B3E51"
          opacity="1.000000"
          stroke="none"
          d="
M424.574646,2918.502197 
	C424.102966,2914.368896 422.978180,2911.942627 418.949982,2912.100098 
	C404.683563,2912.657471 390.341797,2910.572510 376.132690,2913.504639 
	C371.889313,2914.380127 367.516724,2914.288086 363.224152,2913.380371 
	C362.575348,2913.243164 361.964691,2912.923096 361.315338,2912.790771 
	C351.246979,2910.740967 318.320190,2913.147949 308.567383,2916.636475 
	C308.254700,2916.748535 307.962067,2917.023682 307.655762,2917.030518 
	C296.673737,2917.280518 286.378601,2920.770508 275.807404,2923.137939 
	C270.511353,2924.323975 264.201538,2921.310791 258.417542,2919.917480 
	C248.015671,2917.411133 239.064011,2921.407227 230.739090,2926.588867 
	C226.414551,2929.280518 222.576935,2932.168701 217.223969,2932.060059 
	C211.432602,2931.942871 206.787048,2935.957520 200.978271,2936.510010 
	C195.867584,2936.996582 200.111435,2941.097900 199.557083,2943.983154 
	C194.977829,2943.983154 190.337311,2943.983154 184.826477,2943.961914 
	C182.445267,2939.463867 179.199432,2938.584229 174.799362,2938.785400 
	C170.725403,2938.971436 165.557968,2939.835205 163.554672,2935.043701 
	C161.629166,2930.438232 167.361130,2928.822021 169.318481,2924.903320 
	C173.380234,2923.596924 177.414154,2924.797607 182.756989,2921.765869 
	C175.759384,2920.183350 172.081055,2916.909180 168.589081,2912.321777 
	C166.225479,2902.425781 166.111877,2901.915527 157.886490,2902.130615 
	C151.488892,2902.298340 145.498016,2900.360107 139.307037,2899.605957 
	C133.070587,2898.846191 127.063759,2897.007080 120.829102,2899.370605 
	C119.962433,2899.698975 118.611008,2899.869141 117.940239,2899.424561 
	C109.155487,2893.600586 99.307114,2896.853516 89.953247,2895.988037 
	C87.771561,2895.786377 85.302238,2896.749756 83.577736,2894.669434 
	C82.789024,2892.819824 84.262360,2891.698730 85.245911,2891.626953 
	C93.048843,2891.057373 98.112785,2886.226318 102.908958,2880.832520 
	C103.279915,2880.415283 104.133400,2880.427490 105.480431,2880.213867 
	C113.126808,2885.717529 119.974686,2882.484131 126.554779,2879.642822 
	C138.197418,2874.615234 153.750397,2876.965088 162.831390,2886.215820 
	C161.058990,2891.044434 161.058990,2891.044434 156.306793,2894.136475 
	C167.262802,2891.376709 177.090530,2896.484131 187.523788,2894.655273 
	C186.668152,2891.934570 183.859955,2891.183594 181.997971,2888.618164 
	C180.313675,2885.037354 174.821396,2884.019531 176.313599,2880.470215 
	C178.035736,2876.373779 182.885117,2876.988281 186.827515,2876.982910 
	C189.462982,2876.979492 192.086105,2877.044922 195.285934,2878.451660 
	C199.176971,2884.780762 204.529694,2884.764404 210.297256,2884.384033 
	C217.462906,2883.911865 224.021393,2880.158691 232.069000,2881.067139 
	C235.811218,2885.583008 240.098099,2885.229736 244.436569,2884.602783 
	C258.935486,2882.507812 273.452637,2882.627197 288.752747,2884.353760 
	C292.801697,2886.180908 296.267853,2887.295410 299.837860,2888.178467 
	C303.271790,2889.027832 305.734070,2891.051514 306.957581,2894.526123 
	C309.398590,2901.458252 312.886383,2902.767822 318.146118,2897.940918 
	C337.116028,2880.532227 358.399994,2882.038574 380.536530,2889.788086 
	C382.104309,2890.337158 383.606110,2891.142334 385.265106,2891.188232 
	C397.635712,2891.531250 410.003235,2891.300781 419.755310,2891.652100 
	C411.127747,2892.660400 399.889099,2890.421631 388.638153,2890.765137 
	C385.476593,2890.861816 381.331940,2890.153809 381.410889,2885.664062 
	C381.481018,2881.677246 385.127594,2880.313721 388.397247,2879.615234 
	C402.080658,2876.691406 415.796478,2873.485107 429.930847,2875.081299 
	C437.189545,2875.900879 443.452850,2875.035889 449.605743,2870.399414 
	C454.760773,2866.514648 461.679626,2867.289062 467.926025,2867.108887 
	C475.241852,2866.897705 482.573059,2866.679199 489.904022,2867.032227 
	C493.680786,2867.214111 497.538025,2867.138428 497.700439,2861.012207 
	C498.163849,2855.155518 502.149689,2852.078857 505.214081,2847.603027 
	C509.968079,2844.539795 514.177551,2846.744629 518.222717,2847.279541 
	C524.119080,2848.058838 529.718567,2848.209717 535.920105,2845.250488 
	C553.422119,2842.452393 570.385742,2842.062744 587.875122,2838.966064 
	C593.859314,2839.191162 598.552063,2837.140869 604.270874,2835.901123 
	C615.417297,2835.650146 625.355896,2838.832764 635.664978,2839.065918 
	C642.606445,2839.222900 649.465881,2838.087891 657.114990,2838.824219 
	C660.727905,2847.339600 653.632690,2846.540039 649.621643,2848.271484 
	C632.258118,2855.768066 613.999939,2854.234619 595.823547,2853.371582 
	C593.832764,2853.277100 591.837402,2852.828125 590.477539,2853.553955 
	C601.601013,2854.577637 613.299622,2855.250244 625.030640,2854.080566 
	C627.346436,2853.849609 629.718506,2854.098633 631.786255,2855.388428 
	C634.367920,2856.998779 636.990540,2858.780762 636.644653,2862.285400 
	C636.336365,2865.409180 633.692505,2866.455078 631.104187,2867.306396 
	C623.428345,2869.830078 615.340332,2869.803711 607.450012,2870.560547 
	C600.184753,2871.257812 593.059143,2872.536621 589.281921,2873.096680 
	C616.950439,2871.866455 647.230408,2863.764648 678.351257,2862.209229 
	C688.216187,2861.716064 697.106995,2855.531738 707.526611,2856.562988 
	C714.166321,2857.219971 720.720276,2856.821533 726.022766,2862.190186 
	C729.275818,2865.483154 734.218506,2864.294189 739.212952,2865.239502 
	C762.930969,2872.177734 786.104797,2873.555420 809.583130,2868.790771 
	C813.873962,2867.920166 818.130249,2867.486816 822.819824,2870.301270 
	C825.331787,2871.831787 821.901733,2875.461914 826.919617,2875.749268 
	C837.687256,2876.365479 848.257385,2879.175049 858.896484,2881.140381 
	C863.637451,2882.016113 869.095337,2882.580566 868.241577,2890.293457 
	C861.791992,2894.567139 855.425964,2893.580811 849.323303,2891.962158 
	C841.818481,2889.971680 834.251282,2889.616211 826.585754,2889.189453 
	C819.042542,2888.769531 812.618958,2890.251953 805.767212,2894.020752 
	C795.367065,2899.741699 783.510376,2900.259766 770.924927,2898.065918 
	C762.852295,2893.645996 755.217102,2890.286377 747.032776,2888.518555 
	C739.078613,2886.800293 731.938660,2886.623291 724.980347,2893.361816 
	C718.190063,2899.937256 708.282837,2899.213379 699.294861,2898.128906 
	C687.392578,2896.693115 675.578613,2894.391846 663.570312,2897.166260 
	C661.313721,2897.687744 658.304138,2897.175293 656.955017,2898.969482 
	C647.774231,2911.178711 633.467468,2909.870850 621.307983,2910.542236 
	C604.199463,2911.486572 587.724365,2914.663574 570.327026,2918.295166 
	C563.151306,2919.593506 556.790527,2919.812988 549.613647,2918.151855 
	C546.618530,2916.909424 545.207947,2915.066162 543.171631,2912.758301 
	C540.414734,2904.480225 544.424866,2901.538818 551.165955,2901.131104 
	C556.755127,2900.792725 562.434143,2901.442627 567.199036,2898.972168 
	C562.135010,2900.616211 556.421204,2901.093750 550.599426,2901.024902 
	C540.978577,2900.911133 531.697510,2902.048828 522.372375,2905.271240 
	C512.984985,2908.514893 502.867340,2907.413818 492.464905,2904.977783 
	C488.087219,2897.823486 490.972168,2893.806396 497.706940,2893.661377 
	C521.368347,2893.152344 544.349792,2885.961914 568.148132,2886.929199 
	C576.139282,2887.254395 584.046997,2887.082031 591.851685,2889.592041 
	C597.141602,2891.293457 602.798035,2891.476562 609.251831,2889.379395 
	C605.683838,2885.856689 602.140869,2885.784668 598.608337,2886.110352 
	C582.972717,2887.550537 567.374329,2887.391846 551.735962,2885.893799 
	C545.142212,2885.262451 538.852600,2886.607666 532.294006,2888.805420 
	C519.511475,2893.089600 506.049835,2892.182617 492.891327,2889.388672 
	C489.328064,2888.632324 485.858063,2887.366211 482.237030,2886.740479 
	C478.853912,2886.155762 475.445862,2885.299316 474.888702,2890.445068 
	C474.745941,2891.763184 473.859344,2893.371338 472.650299,2893.568359 
	C459.558563,2895.701172 450.351135,2909.980469 435.111359,2905.501953 
	C432.651184,2904.779053 429.900909,2904.738525 427.193848,2905.139648 
	C429.737579,2906.112549 432.323486,2906.938965 433.851440,2910.441406 
	C434.349121,2917.431885 432.052124,2920.774902 424.574646,2918.502197 
z"
        />
        <path
          fill="#465869"
          opacity="1.000000"
          stroke="none"
          d="
M196.709747,2880.609863 
	C190.173157,2880.067139 184.317078,2880.067139 177.196060,2880.067139 
	C180.246994,2882.674316 183.583328,2883.414795 184.039047,2887.466797 
	C177.489914,2893.082764 170.504425,2892.280029 162.715027,2888.613525 
	C152.316788,2878.841309 140.779434,2878.463135 128.956635,2881.717773 
	C122.290855,2883.552979 115.883011,2886.074463 108.810402,2885.995605 
	C105.185493,2885.955322 105.194633,2884.074707 106.082260,2880.839600 
	C108.286621,2880.154297 110.375053,2880.120605 113.251816,2880.073730 
	C117.835289,2879.281494 124.323914,2882.666748 123.053467,2874.042236 
	C122.835365,2872.561768 124.743477,2872.225098 126.075493,2872.218994 
	C143.917831,2872.135742 161.779709,2871.584473 179.593063,2872.275146 
	C186.542511,2872.544678 193.365463,2871.936035 201.089478,2871.893799 
	C203.338348,2870.272949 205.543732,2870.086914 207.604767,2868.457275 
	C210.168610,2868.050049 212.291916,2868.061035 215.211456,2868.070312 
	C221.910904,2866.757080 227.808640,2865.458252 229.221558,2874.743164 
	C227.689804,2877.640869 225.543335,2878.288574 222.575073,2879.064941 
	C219.505295,2879.321777 217.765930,2880.721924 215.052704,2881.968750 
	C208.536621,2883.495117 202.832092,2884.283691 196.709747,2880.609863 
z"
        />
        <path
          fill="#2B3E51"
          opacity="1.000000"
          stroke="none"
          d="
M42.994942,2884.235107 
	C51.393269,2884.297119 58.964119,2883.149170 67.575890,2885.065674 
	C62.522942,2890.065918 57.033485,2887.042725 52.397469,2888.123291 
	C47.252983,2889.322510 42.013275,2889.742188 36.793217,2890.409668 
	C31.659441,2891.065918 26.696346,2893.249512 19.514008,2889.948730 
	C28.174246,2887.805908 35.138138,2886.082520 42.994942,2884.235107 
z"
        />
        <path
          fill="#2B3E51"
          opacity="1.000000"
          stroke="none"
          d="
M161.199768,2912.060547 
	C161.604813,2914.664307 160.071548,2916.054199 157.441208,2915.935059 
	C152.686661,2915.719971 147.853043,2916.788574 142.594025,2914.545654 
	C147.761673,2910.214600 154.198303,2912.674072 161.199768,2912.060547 
z"
        />
        <path
          fill="#2B3E51"
          opacity="1.000000"
          stroke="none"
          d="
M14.332387,2881.797363 
	C14.360141,2879.625732 15.070848,2877.490479 15.846425,2874.678955 
	C16.522625,2873.993408 17.143591,2874.050537 17.743782,2873.963867 
	C23.245060,2873.168701 22.034910,2875.790527 20.125458,2879.340820 
	C18.377234,2880.569336 16.696287,2881.201172 14.332387,2881.797363 
z"
        />
        <path
          fill="#597288"
          opacity="1.000000"
          stroke="none"
          d="
M154.248444,2575.142578 
	C153.811340,2578.008057 153.182800,2580.767334 151.423920,2580.780518 
	C144.889099,2580.828857 140.921906,2586.111084 134.567383,2587.901367 
	C134.384689,2584.388184 132.998428,2581.782959 129.612579,2581.050293 
	C123.460266,2579.718994 116.767944,2578.208496 111.311424,2582.045654 
	C103.683365,2587.409912 95.073738,2587.369873 87.366768,2585.494873 
	C75.398827,2582.583008 64.397964,2585.886963 53.036911,2587.421143 
	C47.485943,2588.170898 42.204609,2590.772217 36.416401,2589.895508 
	C35.197975,2589.710938 33.667309,2589.850342 33.349850,2587.388672 
	C36.241875,2584.841553 40.538891,2585.405273 44.037220,2584.236572 
	C52.111427,2581.539062 60.481808,2579.807861 67.851601,2575.213867 
	C70.411194,2573.618408 72.716209,2575.323975 75.281342,2575.920410 
	C87.868927,2578.847656 100.565247,2580.140137 113.512642,2574.432373 
	C126.493370,2570.041748 139.010498,2566.635254 152.728119,2572.270996 
	C153.807617,2573.289551 154.153076,2573.782227 154.248444,2575.142578 
z"
        />
        <path
          fill="#31475A"
          opacity="1.000000"
          stroke="none"
          d="
M154.685883,2573.941162 
	C141.288620,2570.476807 128.547424,2574.367432 114.902771,2573.862305 
	C113.795609,2572.083740 113.594261,2570.341064 113.759300,2568.208008 
	C126.081032,2568.402832 137.735840,2566.017334 149.510422,2564.539062 
	C162.925735,2562.854736 176.400345,2560.963379 190.934326,2562.276367 
	C195.169678,2567.627930 191.749710,2569.596191 187.457565,2570.561523 
	C182.579346,2571.658691 177.904022,2573.317383 172.516449,2575.275146 
	C171.032394,2575.733154 170.202698,2575.750977 168.750717,2575.752441 
	C164.205048,2572.711182 159.612762,2574.510742 154.685883,2573.941162 
z"
        />
        <path
          fill="#4B6377"
          opacity="1.000000"
          stroke="none"
          d="
M161.550232,2551.454102 
	C160.357178,2554.278809 157.885834,2555.162598 154.606873,2555.830078 
	C154.726425,2551.870361 156.312439,2549.058594 161.550232,2551.454102 
z"
        />
        <path
          fill="#243B51"
          opacity="1.000000"
          stroke="none"
          d="
M2860.443115,2726.345215 
	C2858.287842,2723.291992 2854.985352,2724.589844 2852.420410,2722.484131 
	C2855.638672,2721.998779 2859.297119,2722.027588 2862.955322,2722.015381 
	C2870.531006,2721.990479 2878.338867,2720.870361 2883.500488,2728.730469 
	C2884.903564,2730.867188 2887.722656,2731.628662 2890.468018,2732.179199 
	C2899.883545,2734.067139 2909.227295,2736.213135 2918.933105,2736.135254 
	C2921.097168,2736.117920 2924.574463,2734.593262 2924.429199,2739.490723 
	C2911.854004,2743.089111 2899.895752,2739.685547 2887.870850,2738.875732 
	C2883.626221,2738.589600 2879.425049,2737.656982 2874.588135,2736.525391 
	C2873.853271,2732.115967 2874.816162,2727.699707 2868.702637,2726.129395 
	C2865.714111,2727.302734 2863.449707,2728.511475 2860.443115,2726.345215 
z"
        />
        <path
          fill="#49647C"
          opacity="1.000000"
          stroke="none"
          d="
M2947.318359,2746.233154 
	C2947.477051,2749.036133 2945.585938,2750.075684 2942.979492,2749.944580 
	C2938.378662,2749.713623 2933.697266,2750.802734 2928.595947,2748.572754 
	C2928.843018,2743.138672 2932.534668,2743.668213 2935.713623,2744.445068 
	C2939.278320,2745.315430 2942.780762,2746.188477 2947.318359,2746.233154 
z"
        />
        <path
          fill="#646A6C"
          opacity="1.000000"
          stroke="none"
          d="
M1969.984619,4021.972656 
	C1969.966309,4021.947021 1969.512207,4022.751465 1969.512207,4022.751465 
	C1969.512207,4022.751465 1969.595093,4022.203125 1969.824219,4022.119629 
	C1970.053467,4022.036133 1970.002808,4021.998535 1969.984619,4021.972656 
z"
        />
        <path
          fill="#646A6C"
          opacity="1.000000"
          stroke="none"
          d="
M1983.988281,3929.979980 
	C1983.976196,3929.960449 1983.583374,3930.699219 1983.583374,3930.699219 
	C1983.583374,3930.699219 1983.657227,3930.250977 1983.848389,3930.137207 
	C1984.039429,3930.023682 1984.000488,3929.999756 1983.988281,3929.979980 
z"
        />
        <path
          fill="#21364A"
          opacity="1.000000"
          stroke="none"
          d="
M2843.495850,3115.488281 
	C2842.918213,3120.941162 2839.256348,3122.403320 2835.095215,3121.280762 
	C2828.746094,3119.567871 2822.267578,3119.657715 2815.894775,3118.732422 
	C2805.379150,3117.206055 2796.413574,3111.492920 2786.677734,3106.123779 
	C2784.618408,3103.970947 2783.922852,3101.836182 2785.443359,3098.760010 
	C2786.895020,3098.120117 2787.774658,3098.141846 2789.312500,3098.214844 
	C2791.022949,3099.177979 2792.078369,3100.086182 2793.656494,3101.453125 
	C2795.637207,3101.879395 2797.074951,3101.869873 2799.231445,3101.866699 
	C2801.117920,3101.929199 2802.195068,3101.671631 2803.585693,3100.602783 
	C2804.919189,3100.195068 2805.806885,3100.139404 2807.361572,3100.078613 
	C2808.915283,3100.041504 2809.802002,3100.013184 2811.354248,3099.968262 
	C2816.305176,3100.237549 2820.680664,3099.375244 2825.433105,3101.488281 
	C2824.092285,3105.372803 2823.805176,3108.260010 2828.217041,3109.805176 
	C2833.142090,3111.530518 2838.068115,3113.253174 2843.495850,3115.488281 
z"
        />
        <path
          fill="#3A4F62"
          opacity="1.000000"
          stroke="none"
          d="
M246.266968,3219.364258 
	C238.151840,3219.508301 230.305405,3220.607666 222.402603,3218.811279 
	C218.518738,3217.928467 214.039291,3219.666260 209.005554,3220.279785 
	C199.137695,3225.257080 189.707916,3227.870361 178.623993,3224.709961 
	C172.504501,3224.169434 167.148178,3224.084229 160.896667,3224.001953 
	C158.234299,3223.959961 156.441986,3224.027588 154.457382,3222.395020 
	C154.492020,3220.333496 154.994659,3218.742676 155.751617,3216.571533 
	C165.983643,3217.233643 175.425461,3217.738770 184.959595,3212.042969 
	C189.245499,3209.482666 196.233643,3210.553467 201.989777,3210.115967 
	C215.450836,3209.091553 228.832550,3211.232422 242.240845,3212.134766 
	C246.114212,3212.395508 248.600662,3214.246338 246.266968,3219.364258 
z"
        />
        <path
          fill="#172837"
          opacity="1.000000"
          stroke="none"
          d="
M89.041763,3204.095703 
	C90.062904,3207.547363 89.025757,3209.911133 85.031502,3209.942383 
	C77.157677,3210.003906 69.313126,3209.522461 61.858154,3213.451904 
	C58.715115,3215.108643 55.375492,3213.468994 54.102623,3208.722656 
	C65.260612,3205.636719 76.593094,3203.864990 89.041763,3204.095703 
z"
        />
        <path
          fill="#233444"
          opacity="1.000000"
          stroke="none"
          d="
M153.976379,3222.406738 
	C155.698853,3222.290527 157.408279,3222.656982 159.559601,3223.514160 
	C158.505112,3225.966064 156.752731,3226.323242 154.334076,3224.352051 
	C153.959976,3223.854980 153.963364,3222.889404 153.976379,3222.406738 
z"
        />
        <path
          fill="#172B3E"
          opacity="1.000000"
          stroke="none"
          d="
M2678.440918,3060.730469 
	C2675.008301,3060.264648 2672.316650,3060.221924 2668.817383,3060.182617 
	C2659.958008,3054.412598 2650.484619,3055.280273 2640.645508,3054.347168 
	C2639.067139,3054.213623 2638.211182,3054.215088 2636.713379,3054.203613 
	C2628.762695,3055.425293 2621.425537,3055.619873 2613.222168,3054.605225 
	C2605.400146,3050.804199 2600.239990,3045.937988 2600.922363,3036.555176 
	C2611.710693,3030.584229 2622.513672,3032.320312 2633.069092,3034.899902 
	C2647.119629,3038.333740 2660.318115,3044.796631 2673.488037,3046.828613 
	C2668.181885,3045.437744 2661.447998,3044.325439 2655.228027,3041.686523 
	C2651.524902,3040.114746 2646.145264,3038.382812 2651.999512,3032.222656 
	C2661.824951,3029.879395 2670.441650,3033.406250 2679.268799,3034.406982 
	C2696.397461,3036.348633 2713.521729,3038.407715 2731.360840,3041.170410 
	C2737.933350,3043.210938 2743.711426,3044.646240 2750.352539,3041.402100 
	C2754.185791,3041.051025 2757.250732,3041.115234 2759.831543,3042.881836 
	C2764.077393,3045.788086 2768.200684,3049.432617 2766.777344,3055.034668 
	C2765.312012,3060.801514 2759.974609,3060.406006 2755.192383,3060.123779 
	C2745.976807,3059.580078 2736.619629,3057.484863 2727.143066,3062.942383 
	C2725.327148,3063.847412 2724.208252,3064.138184 2722.177002,3064.289062 
	C2707.594238,3058.857178 2693.144287,3063.261719 2678.440918,3060.730469 
z"
        />
        <path
          fill="#243B51"
          opacity="1.000000"
          stroke="none"
          d="
M2611.336914,3052.610352 
	C2619.591064,3052.472900 2627.325439,3050.877197 2635.489258,3053.581299 
	C2635.282715,3059.050537 2631.091064,3057.475586 2628.196533,3058.371094 
	C2626.544434,3058.882080 2624.976318,3059.814941 2625.149414,3061.699951 
	C2625.344238,3063.818359 2627.326416,3063.735352 2628.950928,3063.961914 
	C2649.518066,3066.825439 2670.230469,3068.223145 2690.872070,3070.308594 
	C2702.797119,3071.513184 2714.134521,3076.067627 2725.107910,3081.513672 
	C2723.195557,3084.897949 2720.673096,3084.009766 2718.581543,3083.670654 
	C2715.333008,3083.143555 2712.167236,3082.120117 2708.952148,3081.368652 
	C2706.916992,3080.892578 2704.054688,3078.684326 2703.253174,3082.182129 
	C2702.579102,3085.124512 2705.884521,3085.122314 2707.948730,3086.036621 
	C2712.394043,3088.005371 2717.915771,3086.489258 2721.939453,3091.090820 
	C2715.330078,3095.677979 2708.370117,3092.596436 2702.234863,3090.677979 
	C2690.959961,3087.152588 2679.036621,3085.916504 2668.231201,3080.606445 
	C2663.050781,3078.060547 2658.013916,3081.090576 2652.673828,3083.979492 
	C2639.843262,3090.705322 2628.261963,3086.370605 2615.997803,3081.872559 
	C2613.819580,3079.254150 2613.249268,3076.940430 2613.016113,3073.645508 
	C2613.895752,3066.061035 2606.896484,3060.265381 2611.336914,3052.610352 
z"
        />
        <path
          fill="#374D60"
          opacity="1.000000"
          stroke="none"
          d="
M2721.573242,3062.761230 
	C2722.945068,3062.084473 2723.821533,3062.091064 2725.355957,3062.101807 
	C2737.937256,3058.688721 2748.903320,3062.331299 2760.523438,3066.847168 
	C2762.280518,3067.137207 2763.285400,3067.275146 2765.021973,3067.579102 
	C2769.048340,3069.143311 2772.390137,3069.396240 2776.569092,3068.045898 
	C2778.983154,3067.863281 2780.565674,3068.138672 2782.839111,3069.118164 
	C2794.321045,3076.143311 2806.630859,3075.793213 2818.399170,3076.301270 
	C2846.022949,3077.493408 2873.518555,3079.537598 2900.827637,3083.900879 
	C2907.507568,3084.968262 2914.089600,3083.783936 2920.734619,3083.285889 
	C2928.160645,3082.728760 2936.062744,3080.382080 2942.905273,3087.250000 
	C2943.330566,3089.976318 2942.616943,3091.509521 2940.608398,3093.384766 
	C2930.610352,3097.204834 2921.099365,3098.420654 2911.270752,3096.268311 
	C2910.147461,3096.022461 2908.763428,3096.967529 2906.781738,3097.680176 
	C2899.720459,3096.735107 2893.345459,3095.610352 2887.046631,3094.154785 
	C2882.906006,3093.198486 2880.413574,3096.007080 2876.659180,3097.827637 
	C2859.555664,3092.285400 2842.416016,3091.664551 2825.399170,3089.887451 
	C2801.354248,3087.376221 2777.351074,3084.086426 2754.090576,3077.250000 
	C2746.058838,3074.889160 2737.893311,3073.643799 2729.821777,3071.752686 
	C2725.549805,3070.751709 2719.930908,3070.455566 2721.573242,3062.761230 
z"
        />
        <path
          fill="#243B51"
          opacity="1.000000"
          stroke="none"
          d="
M2721.316406,3061.887207 
	C2722.047119,3064.888428 2722.317627,3067.495361 2725.926270,3067.922607 
	C2727.572510,3068.117676 2729.217285,3068.338379 2730.854736,3068.598389 
	C2748.157959,3071.344482 2764.552246,3078.572998 2781.660645,3080.947998 
	C2800.572021,3083.573730 2819.093506,3089.273926 2838.660889,3088.315186 
	C2851.232910,3087.699219 2863.852783,3090.443359 2875.589600,3097.337891 
	C2867.858154,3098.938232 2860.043457,3096.553223 2852.200928,3095.592529 
	C2820.649414,3091.727783 2788.928223,3088.947266 2757.738037,3082.428223 
	C2751.565186,3081.138184 2745.226562,3082.199707 2738.906006,3080.777344 
	C2728.399658,3078.413818 2718.575439,3074.994141 2709.545654,3069.021973 
	C2703.897461,3065.286377 2697.407959,3063.962646 2690.535156,3063.905762 
	C2686.444336,3063.871826 2681.794922,3065.689209 2678.006836,3061.028809 
	C2688.188965,3060.918945 2698.339111,3057.404785 2708.872070,3057.875488 
	C2713.330322,3058.074707 2717.162842,3058.886230 2721.316406,3061.887207 
z"
        />
        <path
          fill="#243B51"
          opacity="1.000000"
          stroke="none"
          d="
M2640.093262,3054.897461 
	C2651.071777,3049.401855 2661.574707,3051.160645 2667.720215,3059.502441 
	C2657.804688,3064.413086 2648.729980,3061.830811 2640.093262,3054.897461 
z"
        />
        <path
          fill="#243B51"
          opacity="1.000000"
          stroke="none"
          d="
M2785.370605,3097.759277 
	C2786.009521,3100.201904 2786.003662,3102.305664 2785.994141,3105.199707 
	C2780.499268,3104.003174 2775.008545,3102.016113 2768.766846,3100.004883 
	C2767.108154,3098.524170 2767.972168,3097.610352 2769.494385,3096.315674 
	C2775.384766,3091.720703 2779.989746,3095.198730 2785.370605,3097.759277 
z"
        />
        <path
          fill="#21364A"
          opacity="1.000000"
          stroke="none"
          d="
M2758.477051,3097.297852 
	C2754.055664,3097.864258 2750.206055,3097.531982 2748.160156,3092.558105 
	C2750.824707,3091.876465 2753.583740,3091.905762 2757.171387,3091.972412 
	C2759.156982,3093.372314 2759.461182,3094.909668 2758.477051,3097.297852 
z"
        />
        <path
          fill="#243B51"
          opacity="1.000000"
          stroke="none"
          d="
M2758.670654,3097.992676 
	C2758.005859,3096.487793 2758.001709,3094.995117 2757.999023,3092.756104 
	C2760.752441,3092.332520 2764.000977,3091.093262 2765.975342,3095.172363 
	C2765.774414,3097.373779 2764.688232,3098.146729 2762.623779,3098.302734 
	C2761.093994,3098.027832 2760.212646,3098.016357 2758.670654,3097.992676 
z"
        />
        <path
          fill="#21364A"
          opacity="1.000000"
          stroke="none"
          d="
M2762.419678,3098.530273 
	C2763.015137,3097.483154 2764.055420,3096.927246 2765.633789,3096.116699 
	C2767.018311,3095.872803 2767.864502,3095.883301 2769.347168,3095.888184 
	C2769.477295,3096.901123 2768.971436,3097.919678 2768.240723,3099.459473 
	C2766.244141,3099.955322 2764.435791,3100.124268 2762.419678,3098.530273 
z"
        />
        <path
          fill="#243B51"
          opacity="1.000000"
          stroke="none"
          d="
M2803.447266,3100.224609 
	C2803.595215,3102.035889 2803.372559,3104.110840 2800.288086,3102.315918 
	C2800.921143,3101.315186 2801.892090,3100.756836 2803.447266,3100.224609 
z"
        />
        <path
          fill="#243B51"
          opacity="1.000000"
          stroke="none"
          d="
M2811.697754,3099.545410 
	C2811.177734,3101.259277 2810.161377,3101.953857 2808.339844,3100.487793 
	C2808.854248,3098.720459 2809.899658,3098.156982 2811.697754,3099.545410 
z"
        />
        <path
          fill="#243B51"
          opacity="1.000000"
          stroke="none"
          d="
M2794.040039,3101.278564 
	C2792.413330,3101.850342 2790.852539,3101.439941 2790.100098,3098.874512 
	C2791.797119,3098.215088 2793.268555,3098.741943 2794.040039,3101.278564 
z"
        />
        <path
          fill="#8DA0AE"
          opacity="1.000000"
          stroke="none"
          d="
M26.285614,2045.036743 
	C21.529665,2046.247437 17.445095,2046.303101 12.951490,2045.172852 
	C17.755501,2039.851807 21.867420,2038.675903 26.404919,2041.107544 
	C27.792120,2041.851074 27.387804,2043.316284 26.285614,2045.036743 
z"
        />
        <path
          fill="#4C6880"
          opacity="1.000000"
          stroke="none"
          d="
M2943.586182,2555.291992 
	C2949.004150,2549.759277 2956.142822,2550.002930 2961.947021,2546.947021 
	C2953.820801,2543.613770 2934.159668,2542.697510 2918.290771,2544.890869 
	C2918.567383,2548.120117 2923.171387,2546.509033 2923.679932,2549.860840 
	C2909.645996,2550.825439 2895.946533,2546.754395 2882.020020,2547.845215 
	C2880.796143,2547.941162 2879.276123,2547.456055 2878.281250,2546.718018 
	C2873.822021,2543.408936 2869.047852,2543.417480 2864.057861,2544.978027 
	C2862.085938,2545.594727 2859.212402,2546.210205 2858.875000,2544.037109 
	C2857.764404,2536.882812 2853.010010,2540.219971 2849.186768,2539.820068 
	C2838.324219,2538.683594 2827.554932,2544.011963 2816.689209,2540.118896 
	C2815.813721,2539.804932 2814.603760,2539.850342 2813.730225,2540.191895 
	C2801.257568,2545.066162 2790.358398,2538.775146 2779.093262,2535.302490 
	C2777.012939,2534.661377 2774.260254,2533.817627 2775.019287,2530.656250 
	C2775.580078,2528.321045 2776.994629,2526.115479 2779.725830,2526.237305 
	C2784.070312,2526.431152 2788.983398,2524.067627 2792.303955,2529.389404 
	C2795.187012,2534.010498 2800.198242,2531.332520 2805.097168,2532.073730 
	C2817.227783,2534.517090 2828.557861,2536.925049 2840.135254,2535.509766 
	C2856.336182,2533.529297 2872.443115,2532.015381 2888.386719,2537.110840 
	C2889.946777,2537.609619 2891.656738,2538.021729 2893.265381,2537.927979 
	C2911.557373,2536.863770 2929.646973,2541.311035 2947.936523,2539.915771 
	C2958.944580,2539.075928 2969.553467,2542.134521 2980.413818,2542.533447 
	C2981.413818,2546.668945 2976.327881,2545.840576 2976.104004,2549.351074 
	C2972.947021,2552.054932 2969.166260,2551.769775 2965.738770,2552.557373 
	C2965.760254,2553.257080 2965.781738,2553.957031 2965.802979,2554.656738 
	C2979.364990,2556.007080 2992.927002,2557.357422 3007.892334,2558.847412 
	C3002.472900,2563.996582 2996.791748,2562.562012 2992.428223,2561.637939 
	C2981.547363,2559.333984 2970.683838,2560.360596 2958.878418,2560.027344 
	C2951.725830,2563.674316 2946.464111,2563.720703 2943.586182,2555.291992 
z"
        />
        <path
          fill="#4C6880"
          opacity="1.000000"
          stroke="none"
          d="
M3019.886963,2553.158691 
	C3019.812500,2555.142822 3018.863037,2557.290527 3018.215088,2556.806152 
	C3013.024170,2552.927979 3007.020752,2554.436035 3001.361572,2553.785400 
	C2999.954346,2553.623779 2999.036377,2552.775146 2998.186523,2550.824707 
	C3005.096680,2544.313965 3010.610840,2544.919922 3019.886963,2553.158691 
z"
        />
        <path
          fill="#5D758A"
          opacity="1.000000"
          stroke="none"
          d="
M137.115021,2705.949707 
	C143.804382,2709.544434 150.227692,2708.995850 155.885330,2706.670166 
	C161.338913,2704.428467 165.837280,2704.992920 170.777969,2707.215088 
	C170.176910,2712.553223 163.795349,2710.546143 162.083084,2715.034180 
	C156.590240,2720.962646 149.671722,2720.180664 142.278366,2720.580566 
	C135.398636,2717.805176 130.432098,2720.529785 124.784332,2724.062500 
	C116.112289,2725.432129 108.174019,2725.705811 100.235039,2725.495850 
	C89.889511,2725.222656 79.768875,2726.166748 69.831398,2729.313721 
	C64.485107,2731.006592 58.902225,2732.452393 52.778210,2728.625000 
	C47.826389,2728.040527 44.289017,2727.838867 40.819954,2732.197998 
	C36.832512,2737.208496 25.173912,2734.114990 18.154812,2726.306396 
	C32.808559,2727.126465 45.447880,2722.693359 60.304642,2720.811523 
	C55.493099,2716.854736 51.495651,2717.852539 47.955959,2718.513428 
	C34.412182,2721.041992 20.867451,2720.237061 6.690103,2718.592041 
	C10.247410,2717.253662 14.269298,2714.963379 18.691114,2717.186768 
	C20.588528,2718.140625 22.574200,2717.551758 24.479685,2716.767334 
	C32.523048,2713.456055 41.453545,2713.907959 49.599129,2710.697021 
	C53.573078,2709.130127 58.099960,2709.914795 62.383549,2711.084229 
	C67.597496,2712.507568 72.885910,2712.593018 78.134972,2710.984375 
	C79.397591,2710.597168 80.769485,2710.063721 82.018135,2710.212158 
	C93.651543,2711.595215 105.019287,2709.113281 116.510468,2708.297119 
	C123.168114,2707.824219 129.572922,2705.777100 137.115021,2705.949707 
z"
        />
        <path
          fill="#495E72"
          opacity="1.000000"
          stroke="none"
          d="
M249.687744,2706.668701 
	C250.961380,2704.200928 253.444077,2703.558350 253.912018,2700.622314 
	C259.137207,2696.909424 264.670044,2695.083252 270.668488,2696.057861 
	C274.942841,2696.752441 278.697479,2693.160156 283.481995,2695.515625 
	C287.574005,2702.847412 294.116302,2697.769043 297.463440,2699.918701 
	C295.761353,2700.033447 292.597076,2700.412598 290.221619,2696.671875 
	C290.105591,2693.538574 287.765961,2692.251221 287.007599,2689.898682 
	C288.741730,2686.180176 296.186584,2690.037598 295.950500,2682.822021 
	C297.498505,2675.240967 302.828644,2677.623535 307.921692,2677.686035 
	C311.966125,2679.626709 315.245972,2681.285156 318.610596,2684.104736 
	C318.570282,2692.387451 320.669189,2699.038086 327.156494,2704.724854 
	C328.101654,2711.877441 323.981110,2713.921631 318.671997,2714.911133 
	C311.612854,2716.226807 304.332947,2715.248291 296.659729,2717.497803 
	C286.837463,2722.008789 277.323151,2719.565186 267.944397,2719.456299 
	C266.618652,2719.440674 265.391510,2718.311035 265.130402,2717.011719 
	C264.696655,2714.852783 266.380768,2714.390137 268.076447,2714.006592 
	C270.851379,2713.379150 273.979095,2713.198486 274.983185,2709.431396 
	C267.495026,2708.471680 260.830566,2712.566895 252.778961,2711.972412 
	C250.653122,2710.628174 249.270416,2709.363281 249.687744,2706.668701 
z"
        />
        <path
          fill="#5D758A"
          opacity="1.000000"
          stroke="none"
          d="
M106.147034,2683.027344 
	C109.563072,2682.278564 112.935226,2682.194580 117.153687,2682.055176 
	C119.430298,2680.640625 120.718262,2679.086182 123.462608,2678.408691 
	C129.893829,2677.601318 136.441193,2681.956787 141.829620,2675.063477 
	C143.960922,2674.128662 145.488708,2674.035645 147.817322,2674.454834 
	C154.099854,2678.026123 159.747894,2677.151855 165.624969,2675.633301 
	C169.732162,2674.572021 174.091858,2675.179688 179.140808,2675.046875 
	C187.387360,2674.561279 194.865311,2674.647705 203.059387,2673.704346 
	C206.757751,2673.874512 209.653931,2675.400635 213.336914,2673.725586 
	C216.054276,2673.348877 218.024490,2673.428223 220.673462,2674.147217 
	C223.195450,2675.246826 224.577271,2676.624756 226.196106,2678.787842 
	C226.655487,2679.886963 226.740509,2680.362549 226.426483,2681.443604 
	C209.114136,2687.824707 191.282425,2685.546875 173.971451,2687.902100 
	C168.758942,2688.611328 163.428177,2688.239990 158.185852,2686.615723 
	C154.389618,2685.438965 150.277863,2686.004395 146.293381,2686.405029 
	C134.125427,2687.628662 121.953583,2688.813232 108.896423,2690.052246 
	C107.125114,2688.032715 105.867897,2686.084961 106.147034,2683.027344 
z"
        />
        <path
          fill="#556A7C"
          opacity="1.000000"
          stroke="none"
          d="
M226.801666,2682.154785 
	C226.022125,2681.699219 226.016815,2681.349609 226.009186,2680.475586 
	C225.332825,2678.049805 226.167511,2676.589844 228.104263,2674.766113 
	C247.824600,2673.903320 266.729950,2672.121582 286.440247,2670.128906 
	C288.332214,2669.513916 289.447876,2669.311768 291.440552,2669.213135 
	C302.962677,2670.443115 313.764404,2671.108154 324.032104,2676.972656 
	C324.530518,2678.303467 324.558594,2678.892334 324.068726,2680.204590 
	C319.550385,2683.303955 315.512817,2682.112305 310.747070,2680.536621 
	C305.710785,2680.339355 301.292847,2679.124512 296.536743,2681.517090 
	C281.917542,2681.382568 268.546021,2683.954346 256.067352,2690.609863 
	C252.295319,2692.621826 248.350372,2691.994141 244.427368,2691.606934 
	C243.524643,2691.518066 241.912643,2690.702148 241.936478,2690.298096 
	C242.502853,2680.700928 234.332001,2685.340820 230.401627,2683.169434 
	C229.551682,2682.699951 228.522537,2682.554932 226.801666,2682.154785 
z"
        />
        <path
          fill="#597288"
          opacity="1.000000"
          stroke="none"
          d="
M289.256866,2696.023438 
	C295.710999,2699.966064 301.169983,2697.106445 306.688751,2694.499512 
	C307.462738,2698.489502 305.976410,2701.260742 303.375946,2701.456055 
	C294.666748,2702.109863 285.841888,2703.032471 277.014496,2700.831299 
	C278.194946,2697.152832 281.382355,2698.559082 283.512573,2696.547852 
	C285.498016,2696.043213 287.001556,2696.035156 289.256866,2696.023438 
z"
        />
        <path
          fill="#172B3E"
          opacity="1.000000"
          stroke="none"
          d="
M2791.428711,2939.418945 
	C2790.661377,2947.676514 2790.843750,2948.165039 2797.859131,2949.524170 
	C2800.652832,2950.065430 2803.595703,2949.836914 2807.330566,2949.896973 
	C2813.534912,2949.361328 2818.607178,2951.059326 2823.710938,2952.078369 
	C2839.005859,2955.131836 2854.481201,2953.433594 2870.765625,2954.348633 
	C2875.729736,2957.144775 2880.334717,2955.559814 2885.342773,2956.059082 
	C2894.409668,2956.115479 2902.874756,2955.650391 2911.541748,2959.590332 
	C2912.190918,2961.401123 2912.098633,2962.940430 2912.003174,2965.249512 
	C2908.545898,2965.958008 2905.091553,2965.896484 2900.773926,2965.837402 
	C2895.900391,2968.287109 2891.476562,2967.430176 2887.220703,2967.202881 
	C2879.981934,2966.815674 2872.837646,2965.300537 2865.935547,2969.559082 
	C2862.800293,2971.493408 2858.655273,2970.355225 2855.177734,2969.269531 
	C2837.614014,2963.786865 2818.807129,2964.873047 2801.326660,2958.948242 
	C2798.513428,2957.994385 2795.242188,2957.457275 2792.663330,2959.522949 
	C2785.918457,2964.926514 2778.338623,2964.071045 2770.704834,2962.770752 
	C2760.742920,2961.073730 2750.949951,2960.608154 2741.119141,2963.971924 
	C2735.229980,2965.987061 2729.258789,2964.729004 2722.965088,2961.688477 
	C2719.915527,2957.002930 2716.549072,2954.542725 2711.614014,2953.274902 
	C2692.875977,2948.460693 2673.719238,2946.236572 2654.598389,2944.142578 
	C2644.576416,2943.044678 2635.513184,2938.939697 2625.749268,2937.054932 
	C2617.886230,2935.536865 2620.638672,2929.460205 2625.906006,2924.763916 
	C2633.936035,2922.067139 2641.075195,2925.710449 2649.194580,2925.027100 
	C2661.829590,2923.845703 2673.087646,2925.601074 2684.106689,2930.130371 
	C2702.593994,2937.728760 2722.554443,2936.453613 2742.648193,2939.153320 
	C2748.682861,2940.222168 2753.962891,2944.551758 2759.826904,2938.824707 
	C2762.348877,2937.717041 2764.288818,2937.657715 2766.967285,2938.209229 
	C2773.865723,2942.510986 2780.162109,2941.714844 2787.222412,2938.651855 
	C2788.938965,2938.460938 2789.903809,2938.624268 2791.428711,2939.418945 
z"
        />
        <path
          fill="#2F465E"
          opacity="1.000000"
          stroke="none"
          d="
M2885.445557,2955.519043 
	C2881.354492,2958.831055 2876.759277,2959.377441 2871.979980,2955.041504 
	C2876.099121,2954.175537 2880.547852,2953.261719 2885.445557,2955.519043 
z"
        />
        <path
          fill="#020914"
          opacity="1.000000"
          stroke="none"
          d="
M2015.008057,3630.614258 
	C2015.693726,3627.131104 2015.909180,3624.377686 2016.319824,3620.817627 
	C2018.806519,3615.884277 2023.546753,3615.573242 2027.682861,3613.002930 
	C2029.311035,3612.531494 2030.275879,3612.488037 2031.955322,3612.751221 
	C2044.309692,3618.397705 2052.363525,3609.505127 2061.564209,3605.491699 
	C2063.516846,3604.640137 2064.904541,3602.352783 2067.982910,3601.514648 
	C2080.548096,3602.813721 2092.366943,3603.765869 2104.296143,3602.948242 
	C2108.312988,3602.673096 2111.983887,3604.534180 2115.767334,3605.813232 
	C2138.327148,3613.438232 2160.640381,3622.146240 2185.139648,3621.349121 
	C2191.122314,3621.154541 2196.931396,3622.963867 2203.069580,3626.024170 
	C2209.165039,3631.177734 2207.302979,3639.709229 2213.503418,3644.892334 
	C2218.579590,3658.924316 2214.622559,3664.545166 2200.713623,3663.990479 
	C2192.087402,3663.646973 2183.567139,3665.012451 2174.861572,3664.513672 
	C2157.886719,3663.541748 2141.172607,3661.899658 2125.112061,3655.626221 
	C2134.485596,3661.938232 2144.075928,3667.134521 2155.748047,3667.008789 
	C2162.403809,3666.937012 2169.185303,3667.802734 2175.437012,3669.787354 
	C2184.723145,3672.735840 2193.421387,3670.745361 2201.605713,3667.437744 
	C2209.020508,3664.441162 2216.028809,3663.683105 2223.865967,3664.396973 
	C2231.649170,3665.106445 2241.034424,3667.887451 2244.333496,3656.197510 
	C2245.057861,3653.630615 2247.644287,3652.862549 2250.113281,3653.701172 
	C2264.684082,3658.651123 2280.434570,3657.470459 2295.710449,3662.802734 
	C2297.767578,3664.255615 2298.655762,3665.651611 2299.552734,3668.036621 
	C2298.630371,3715.165527 2287.257568,3759.685547 2275.667480,3804.968262 
	C2272.217285,3805.927734 2269.225830,3806.263428 2266.328857,3805.134766 
	C2263.442139,3804.010254 2260.573730,3804.897949 2259.157227,3807.098633 
	C2255.291016,3813.106934 2249.356934,3812.934570 2244.041748,3811.685791 
	C2235.123047,3809.590332 2226.223877,3810.245361 2217.297363,3809.994629 
	C2209.035645,3809.762939 2200.759766,3810.642578 2192.552002,3808.299805 
	C2188.828369,3807.237061 2184.616455,3807.558594 2180.893799,3809.593018 
	C2170.658691,3815.186035 2159.543213,3816.185303 2148.144531,3816.037842 
	C2136.985596,3815.893311 2125.860596,3816.596191 2114.770020,3817.947021 
	C2111.950195,3818.290283 2109.761230,3819.336670 2107.758545,3821.244385 
	C2097.619873,3830.904541 2084.713867,3832.894043 2071.897461,3831.529053 
	C2062.921631,3830.572754 2053.699219,3827.238037 2046.075562,3821.816895 
	C2038.788452,3816.635010 2029.420410,3817.627441 2022.203613,3812.593018 
	C2020.514404,3811.414795 2018.259033,3812.702637 2016.597778,3813.773682 
	C2013.442627,3815.808105 2010.314209,3815.268799 2006.358765,3814.078857 
	C2005.693848,3811.870117 2005.773682,3809.724365 2006.017944,3806.788818 
	C2007.042725,3805.124023 2007.410522,3804.123779 2006.625000,3802.458008 
	C2005.783447,3799.035645 2006.080566,3796.137695 2007.287476,3792.619141 
	C2013.983765,3787.815918 2020.521118,3791.852051 2026.989624,3791.643555 
	C2029.548096,3791.561035 2032.641968,3792.701660 2033.768066,3789.218994 
	C2034.898682,3785.723145 2032.734497,3783.544922 2029.986206,3781.934326 
	C2026.238892,3779.738525 2022.197876,3778.196533 2018.070190,3776.867676 
	C2014.137939,3775.601562 2010.575073,3773.907959 2009.709961,3768.507812 
	C2009.116943,3752.250488 2011.621582,3736.854980 2012.529785,3721.360840 
	C2013.187622,3710.136719 2013.777954,3698.946533 2013.228638,3686.804688 
	C2013.101562,3679.850586 2013.241455,3673.802979 2013.293701,3666.852539 
	C2013.502808,3665.561768 2013.799683,3665.173340 2014.694580,3664.369141 
	C2030.742065,3657.096191 2044.089722,3660.668213 2056.437256,3671.537598 
	C2059.851807,3674.543213 2065.706299,3681.609619 2067.159180,3669.452393 
	C2067.523926,3666.400879 2070.717529,3665.956543 2073.348145,3665.350586 
	C2076.457275,3664.633789 2080.002686,3665.916260 2083.566895,3662.679199 
	C2067.146973,3657.486084 2052.479248,3648.907471 2035.091675,3648.852783 
	C2028.194946,3648.831055 2021.140869,3648.392822 2014.679443,3643.494629 
	C2012.656372,3638.963379 2012.469727,3635.124512 2015.008057,3630.614258 
z"
        />
        <path
          fill="#040F19"
          opacity="1.000000"
          stroke="none"
          d="
M2009.146484,3768.530762 
	C2014.507324,3775.644531 2023.997803,3774.381104 2030.898804,3778.415283 
	C2036.958984,3781.957520 2038.627319,3783.721436 2037.940186,3789.817383 
	C2037.430420,3794.337891 2034.972900,3796.350098 2030.603882,3795.680664 
	C2023.487427,3794.590088 2016.408569,3793.254639 2008.447388,3791.985352 
	C2007.749634,3784.366699 2007.523804,3776.765137 2009.146484,3768.530762 
z"
        />
        <path
          fill="#040F19"
          opacity="1.000000"
          stroke="none"
          d="
M2006.136230,3802.657715 
	C2007.512573,3802.221680 2008.868408,3802.415771 2009.019287,3803.961914 
	C2009.123413,3805.028564 2008.223633,3805.525635 2006.733398,3805.904297 
	C2006.117798,3805.104736 2006.053223,3804.210449 2006.136230,3802.657715 
z"
        />
        <path
          fill="#2B3E51"
          opacity="1.000000"
          stroke="none"
          d="
M7.980114,2854.961914 
	C10.605385,2854.234375 13.801369,2852.954346 15.891460,2857.145020 
	C13.268809,2857.788330 10.109043,2858.972900 7.980114,2854.961914 
z"
        />
        <path
          fill="#172B3E"
          opacity="1.000000"
          stroke="none"
          d="
M2765.296631,3229.587402 
	C2770.940430,3234.441162 2776.923096,3235.182129 2783.224365,3236.319824 
	C2793.347656,3238.147461 2803.438232,3239.228760 2813.261475,3244.569336 
	C2823.631592,3250.207031 2836.199219,3252.488525 2849.185059,3254.019531 
	C2852.000000,3253.412109 2852.080566,3252.286865 2850.444336,3250.393311 
	C2847.710449,3247.209229 2844.498047,3248.219238 2840.816406,3247.960938 
	C2837.079346,3242.568115 2839.893799,3239.749023 2844.760742,3240.677979 
	C2855.253906,3242.681152 2865.200928,3242.435303 2875.705322,3239.871094 
	C2882.006104,3238.332764 2889.848633,3239.115723 2894.104492,3247.149902 
	C2895.256104,3253.785156 2901.593262,3252.356201 2905.416260,3255.759277 
	C2905.778809,3263.935303 2912.392090,3267.930908 2916.246582,3273.349854 
	C2917.576172,3275.219238 2921.320068,3275.644775 2924.577637,3274.867920 
	C2934.226807,3272.567383 2943.630127,3274.312500 2952.463379,3278.340088 
	C2958.068848,3280.896729 2964.948975,3279.691895 2969.881836,3285.184082 
	C2968.603027,3287.121826 2968.285156,3288.445801 2969.472656,3290.131592 
	C2970.829346,3292.057129 2974.003174,3291.350586 2975.068604,3294.595703 
	C2972.255859,3296.987305 2968.041748,3295.295410 2964.410156,3297.542480 
	C2958.155762,3290.268311 2949.199463,3290.332031 2941.477295,3288.000732 
	C2922.807373,3282.364502 2904.047852,3276.993164 2884.258789,3272.619873 
	C2881.554688,3272.221436 2879.605469,3271.832275 2876.849121,3271.834961 
	C2851.517090,3261.332031 2825.372314,3256.078857 2799.077637,3248.680664 
	C2795.742676,3248.152100 2793.314453,3247.214111 2790.058594,3246.327881 
	C2779.325928,3241.300049 2767.638672,3241.420410 2757.632324,3234.632080 
	C2756.802002,3232.864014 2756.785889,3231.762451 2757.579590,3229.947754 
	C2760.214111,3227.867188 2762.407471,3227.846436 2765.296631,3229.587402 
z"
        />
        <path
          fill="#21364A"
          opacity="1.000000"
          stroke="none"
          d="
M2894.654785,3247.953613 
	C2887.938232,3241.163330 2879.510010,3243.379883 2871.994629,3242.199219 
	C2871.166016,3242.069092 2869.641846,3242.583984 2869.380127,3243.199463 
	C2866.125732,3250.846191 2860.665283,3247.316406 2856.025635,3245.596191 
	C2851.912109,3244.071533 2847.582275,3244.256836 2843.468018,3242.992188 
	C2840.780029,3242.166016 2840.246094,3244.397705 2840.055176,3247.232666 
	C2834.922852,3246.593018 2829.789795,3245.258789 2824.674072,3243.861572 
	C2822.228516,3243.193604 2820.247070,3241.751221 2820.529053,3239.019531 
	C2820.857666,3235.836182 2823.657227,3236.167236 2825.940430,3236.005615 
	C2827.584229,3235.889160 2829.287109,3236.014404 2830.614014,3234.740967 
	C2831.857910,3232.362793 2829.994141,3230.260986 2830.030762,3227.977539 
	C2830.084473,3224.633057 2829.589111,3222.005371 2824.928711,3223.171875 
	C2822.218506,3223.850586 2820.403320,3221.926025 2820.529053,3219.160645 
	C2820.680420,3215.830322 2823.488770,3215.878662 2825.753662,3216.160889 
	C2829.361328,3216.609863 2832.914307,3217.496094 2836.489746,3218.201172 
	C2838.814697,3218.659668 2839.863525,3220.262939 2839.907715,3222.448730 
	C2840.013184,3227.643066 2843.429443,3228.074219 2847.432129,3228.016602 
	C2853.424805,3227.930420 2859.423340,3228.132324 2865.412842,3227.969727 
	C2870.822754,3227.823242 2876.027344,3228.416016 2881.451660,3231.412109 
	C2882.424561,3236.513916 2886.712158,3236.785156 2889.494873,3238.781250 
	C2892.916748,3241.235596 2898.306885,3241.567383 2898.416260,3247.628418 
	C2897.091064,3248.016113 2896.205322,3247.993652 2894.654785,3247.953613 
z"
        />
        <path
          fill="#A6A29C"
          opacity="1.000000"
          stroke="none"
          d="
M1984.008667,3879.995117 
	C1983.777100,3879.794189 1983.492676,3879.634277 1983.298950,3879.399658 
	C1982.498535,3878.430908 1981.883179,3877.405273 1982.816040,3876.186768 
	C1982.894653,3876.084229 1983.429077,3876.148682 1983.441284,3876.204102 
	C1983.638428,3877.096436 1983.785156,3878.000000 1983.959839,3879.462402 
	C1983.976196,3880.023438 1983.997437,3880.001709 1984.008667,3879.995117 
z"
        />
        <path
          fill="#687073"
          opacity="1.000000"
          stroke="none"
          d="
M2955.514648,1864.675293 
	C2959.611328,1863.698364 2962.590088,1861.837158 2965.473389,1859.767944 
	C2967.490723,1858.320190 2968.994629,1858.686401 2970.114746,1861.657471 
	C2972.350830,1863.818970 2972.477539,1866.168457 2972.803711,1868.400757 
	C2973.714600,1874.632324 2976.714844,1876.271606 2982.421387,1872.194580 
	C2983.041992,1870.437622 2983.115479,1869.202759 2983.063965,1867.328369 
	C2982.770752,1864.469727 2981.183838,1862.829956 2980.288818,1860.173828 
	C2979.444824,1854.360596 2981.287354,1851.548828 2986.911133,1851.986206 
	C2988.812988,1852.134155 2990.743408,1851.911011 2993.550781,1851.730835 
	C2999.012939,1851.564941 3003.584961,1851.525635 3009.011719,1851.313721 
	C3009.841309,1855.597412 3007.640381,1857.886230 3003.144775,1857.928955 
	C3000.821045,1857.951050 2998.483643,1857.879639 2996.177246,1858.105225 
	C2992.646240,1858.450439 2991.197998,1860.712769 2993.219238,1863.545532 
	C2995.985840,1867.422241 2996.083740,1871.535278 2995.846436,1876.679932 
	C2995.709229,1879.766479 2994.905762,1881.918457 2994.425781,1884.843262 
	C2993.919678,1886.425659 2993.468018,1887.262573 2992.349609,1888.513672 
	C2984.986816,1890.144165 2978.238281,1888.270386 2971.435303,1888.629395 
	C2965.417236,1888.947144 2964.133301,1882.956909 2959.816895,1879.950195 
	C2956.198730,1879.142700 2954.562988,1882.108032 2951.388184,1882.450195 
	C2949.631592,1875.993164 2947.596436,1869.751587 2955.514648,1864.675293 
z"
        />
        <path
          fill="#3A4F62"
          opacity="1.000000"
          stroke="none"
          d="
M204.541809,2747.191895 
	C201.649673,2748.027588 199.463547,2748.513184 197.241135,2749.436035 
	C193.853561,2750.842773 193.983078,2755.895264 189.153397,2756.039062 
	C177.974533,2756.371826 166.845703,2760.311768 154.809250,2756.128418 
	C153.498886,2754.397949 153.007935,2752.805420 152.257523,2750.634766 
	C150.962280,2748.740723 149.338791,2748.129639 148.016449,2746.325684 
	C147.358963,2744.182861 146.908417,2742.623779 146.237061,2740.484375 
	C147.165909,2739.312256 148.257675,2738.453369 149.478226,2738.188965 
	C151.481171,2737.755127 173.642654,2741.899658 175.867050,2742.489746 
	C183.170578,2744.427979 190.522659,2745.968506 197.776642,2740.779785 
	C204.635101,2737.350342 206.054398,2740.534424 204.541809,2747.191895 
z"
        />
        <path
          fill="#3A4F62"
          opacity="1.000000"
          stroke="none"
          d="
M67.347458,2762.207031 
	C66.757317,2768.275391 72.403145,2769.569336 75.686737,2773.297363 
	C77.371918,2779.521973 76.378540,2783.452393 68.658165,2782.471191 
	C68.190659,2778.183350 66.878960,2775.928955 62.672421,2775.976562 
	C60.834305,2775.997314 58.989872,2775.458984 56.599800,2774.677490 
	C51.643272,2765.530029 51.647812,2765.554443 61.938480,2763.646973 
	C63.506523,2763.356445 65.010178,2762.718262 67.347458,2762.207031 
z"
        />
        <path
          fill="#495E72"
          opacity="1.000000"
          stroke="none"
          d="
M146.938232,2745.791016 
	C149.588959,2746.140625 151.304642,2746.624268 151.895538,2749.397705 
	C145.127792,2749.883057 138.157822,2750.749756 131.389526,2748.402100 
	C136.004074,2744.966309 141.194229,2746.427002 146.938232,2745.791016 
z"
        />
        <path
          fill="#3A4F62"
          opacity="1.000000"
          stroke="none"
          d="
M93.575996,2757.425293 
	C92.103188,2761.405029 89.729225,2764.172119 84.568192,2763.872803 
	C83.897339,2762.814941 83.839867,2761.807617 84.015938,2760.843262 
	C85.260590,2754.025879 85.911133,2753.755859 93.575996,2757.425293 
z"
        />
        <path
          fill="#3A4F62"
          opacity="1.000000"
          stroke="none"
          d="
M108.006447,2758.853027 
	C105.170639,2759.937012 102.066269,2760.515137 98.531570,2758.481934 
	C101.329170,2755.344727 104.592361,2754.929199 108.006447,2758.853027 
z"
        />
        <path
          fill="#2B3E51"
          opacity="1.000000"
          stroke="none"
          d="
M67.101776,2781.986328 
	C73.417213,2783.026611 73.893402,2778.513672 75.743057,2774.553223 
	C88.612862,2771.574707 101.177910,2769.051758 115.014038,2770.089844 
	C115.667213,2773.273926 119.265091,2775.056641 118.869186,2778.933350 
	C115.739578,2780.781982 111.994278,2780.041016 108.528633,2779.923584 
	C101.034576,2779.670166 94.107613,2781.919922 87.091148,2783.961670 
	C84.674942,2784.664551 82.898033,2785.908203 82.032730,2789.108887 
	C63.787533,2789.814209 45.684708,2789.814209 27.581881,2789.814209 
	C27.498636,2789.081299 27.415392,2788.348633 27.332148,2787.615723 
	C31.914833,2784.779541 37.106850,2784.073242 42.565147,2784.050293 
	C50.513702,2784.017090 58.513763,2784.437744 67.101776,2781.986328 
z"
        />
        <path
          fill="#243748"
          opacity="1.000000"
          stroke="none"
          d="
M155.341705,2805.969238 
	C155.628006,2808.141846 154.628342,2809.764893 152.325562,2809.820312 
	C146.559662,2809.958740 140.856186,2809.712158 136.136887,2804.651123 
	C140.059494,2803.946777 144.152573,2803.977539 149.121399,2804.003418 
	C151.567535,2804.632324 153.137894,2805.265625 155.341705,2805.969238 
z"
        />
        <path
          fill="#33495B"
          opacity="1.000000"
          stroke="none"
          d="
M139.254150,2717.981934 
	C146.899261,2718.849854 153.309509,2715.035889 161.058151,2715.765137 
	C175.354721,2717.129150 188.799591,2718.569580 203.119171,2720.016846 
	C207.555603,2720.380615 210.238861,2723.018555 214.307373,2723.990234 
	C216.280624,2724.868652 217.367081,2725.785400 218.493408,2727.682617 
	C218.785431,2729.428711 218.537949,2730.405273 217.549881,2731.926514 
	C216.648819,2732.841553 216.230972,2733.146973 215.100281,2733.755371 
	C211.419281,2734.843018 208.286545,2734.819580 205.901184,2733.041992 
	C196.948364,2726.370605 187.135818,2726.642334 176.262634,2728.850586 
	C171.878006,2728.806641 168.430466,2729.760986 164.533844,2731.556396 
	C158.347290,2732.023926 152.820374,2731.855957 147.248642,2730.337402 
	C135.598633,2727.162598 123.777901,2726.703857 111.895584,2729.524902 
	C109.357826,2730.126953 106.615990,2729.863525 103.969048,2730.014160 
	C101.990852,2730.126465 99.287102,2729.966064 98.852142,2732.134277 
	C97.686241,2737.946533 93.514641,2737.389893 89.146812,2738.200439 
	C78.083900,2740.253418 67.096672,2741.910156 55.801495,2742.618164 
	C50.433220,2742.954834 44.745651,2745.347900 38.928276,2745.362793 
	C37.640232,2745.365967 36.247849,2745.515137 35.088593,2745.090820 
	C33.459358,2744.494873 32.451092,2743.095459 32.523121,2741.235107 
	C32.601116,2739.220703 33.951504,2738.295654 35.753033,2738.215088 
	C39.396824,2738.052002 43.051723,2738.143066 46.696323,2737.991455 
	C53.927513,2737.690918 53.925911,2737.652344 52.146770,2728.827393 
	C59.111603,2728.350830 65.906433,2727.369873 72.574814,2724.892334 
	C80.476509,2721.956787 88.793678,2721.912109 97.133911,2722.018311 
	C106.102997,2722.132812 115.077446,2721.826172 124.976913,2721.624023 
	C129.732727,2719.030518 133.916260,2717.777100 139.254150,2717.981934 
z"
        />
        <path
          fill="#33495B"
          opacity="1.000000"
          stroke="none"
          d="
M204.827591,2748.020264 
	C203.721649,2744.659424 205.062057,2740.402100 198.737106,2740.034668 
	C195.346176,2738.511963 192.537659,2737.764404 188.752991,2737.989746 
	C187.224670,2737.008301 186.968384,2735.912109 187.748123,2733.969727 
	C197.570877,2729.325684 206.606735,2735.468506 216.726227,2734.015869 
	C222.459900,2735.846680 227.328171,2737.101074 232.669067,2737.246582 
	C237.658524,2737.382568 243.379807,2737.754639 244.422165,2745.218262 
	C239.771835,2746.182129 235.332413,2745.625000 231.392456,2746.776611 
	C222.819809,2749.282715 214.390030,2750.643066 204.827591,2748.020264 
z"
        />
        <path
          fill="#3A4F62"
          opacity="1.000000"
          stroke="none"
          d="
M187.817230,2733.409180 
	C188.021790,2734.953125 188.016022,2735.818359 188.010101,2737.332031 
	C179.953857,2738.326172 173.179184,2733.299072 164.679642,2732.258545 
	C164.501907,2726.556396 168.214340,2725.831299 173.334412,2726.061523 
	C177.353592,2730.833008 184.502670,2727.673584 187.817230,2733.409180 
z"
        />
        <path
          fill="#1C2E3E"
          opacity="1.000000"
          stroke="none"
          d="
M168.985107,2924.444092 
	C170.891861,2928.830078 168.738388,2931.760986 164.857498,2934.435303 
	C172.360855,2936.760986 179.596573,2936.101807 186.704346,2934.884277 
	C187.748322,2938.560303 184.256821,2939.930664 184.049469,2943.262207 
	C178.474091,2944.340820 173.250183,2946.757568 166.775650,2946.113281 
	C146.219055,2947.780273 126.397285,2947.363037 106.720482,2949.611816 
	C102.466743,2950.097656 98.127541,2949.835693 92.922241,2949.891602 
	C87.510994,2946.419922 83.484665,2940.255615 76.566574,2947.566895 
	C75.095947,2947.907227 74.198112,2947.922363 72.626312,2947.903809 
	C59.984901,2940.909180 47.126297,2936.755615 33.283348,2936.016846 
	C28.719263,2935.772949 27.924772,2933.103516 27.914322,2928.601074 
	C32.635452,2926.713623 37.408405,2925.675781 42.880806,2924.626953 
	C49.817135,2930.095215 57.596512,2931.618408 65.350822,2931.693848 
	C79.902039,2931.835938 94.424850,2931.593750 109.028687,2930.562256 
	C126.523109,2929.326172 143.799759,2925.756348 161.393326,2925.867920 
	C163.654785,2925.882324 165.922165,2924.966309 168.985107,2924.444092 
z"
        />
        <path
          fill="#344F68"
          opacity="1.000000"
          stroke="none"
          d="
M2856.651855,2657.992676 
	C2852.322754,2658.478027 2849.116943,2656.275879 2844.784180,2656.141846 
	C2844.506592,2654.506836 2844.990723,2652.888184 2845.742188,2650.687012 
	C2851.182861,2645.811523 2856.675537,2645.697021 2863.175049,2649.469238 
	C2867.686035,2653.204834 2872.168945,2651.537598 2877.162109,2651.972168 
	C2885.461426,2650.114990 2892.995850,2650.400146 2901.279053,2651.623047 
	C2903.495361,2651.972168 2904.981201,2651.963867 2907.209473,2651.937500 
	C2914.573730,2647.231445 2921.590820,2648.521484 2929.342041,2651.264160 
	C2934.067871,2652.022217 2938.108643,2652.065186 2943.008545,2652.115234 
	C2944.392822,2656.024902 2946.869141,2657.229736 2950.529541,2658.094238 
	C2957.096680,2659.646240 2964.102295,2659.028320 2970.488525,2661.555420 
	C2969.869873,2665.048340 2967.308838,2665.102295 2964.602539,2665.985352 
	C2945.460938,2664.970947 2926.997314,2663.892334 2908.709229,2660.723633 
	C2899.175049,2659.071045 2889.467773,2660.399658 2879.843994,2660.069336 
	C2875.859375,2659.932617 2871.866943,2660.014648 2866.942383,2659.953613 
	C2863.426025,2657.814697 2860.341553,2658.010986 2856.651855,2657.992676 
z"
        />
        <path
          fill="#3F5A73"
          opacity="1.000000"
          stroke="none"
          d="
M2827.871338,2630.743408 
	C2839.383057,2622.706787 2851.711914,2622.299805 2864.817383,2623.479492 
	C2874.517334,2624.352783 2883.839355,2625.627930 2893.450684,2629.818359 
	C2894.030762,2633.030762 2894.385254,2635.644287 2898.059326,2635.831055 
	C2901.413818,2636.002197 2904.525879,2636.879395 2907.501709,2639.983398 
	C2899.619141,2639.983398 2891.736572,2639.983398 2882.927002,2639.979492 
	C2874.094238,2640.450195 2866.193359,2641.345215 2858.271973,2641.111084 
	C2847.259766,2640.785889 2836.433838,2639.561523 2827.871338,2630.743408 
z"
        />
        <path
          fill="#4C6880"
          opacity="1.000000"
          stroke="none"
          d="
M2827.058838,2629.916748 
	C2836.373535,2635.529053 2846.044189,2636.545410 2855.703613,2638.202881 
	C2864.163086,2639.654541 2872.563232,2636.543701 2881.432617,2639.444336 
	C2877.394043,2642.539307 2872.488770,2644.665039 2867.216064,2643.364746 
	C2859.785156,2641.531738 2863.657471,2646.134521 2863.885254,2649.401855 
	C2858.556152,2650.079590 2853.180176,2650.069824 2846.906982,2650.082031 
	C2844.494385,2641.067139 2836.475830,2639.644287 2830.020508,2638.375732 
	C2817.835693,2635.981445 2805.315674,2635.271484 2792.924072,2633.965332 
	C2789.256592,2633.579102 2785.847168,2632.439209 2782.568115,2630.916016 
	C2780.942627,2630.160889 2780.255371,2628.656982 2781.598145,2626.350098 
	C2796.737061,2627.549072 2811.293945,2630.359375 2827.058838,2629.916748 
z"
        />
        <path
          fill="#4C6880"
          opacity="1.000000"
          stroke="none"
          d="
M2927.131836,2626.082520 
	C2934.350342,2628.893066 2941.112793,2629.557861 2947.859131,2629.928955 
	C2951.495850,2630.128662 2955.003906,2630.504150 2959.220459,2631.904785 
	C2959.400391,2636.527100 2957.031494,2638.758789 2952.492920,2637.135254 
	C2948.918213,2635.856201 2945.310303,2636.114746 2940.840820,2636.006348 
	C2937.355957,2632.651123 2933.997070,2635.163330 2930.988770,2634.798584 
	C2923.203613,2633.854980 2913.826416,2637.096680 2909.571777,2626.585938 
	C2915.409424,2626.011230 2920.818848,2626.032227 2927.131836,2626.082520 
z"
        />
        <path
          fill="#3F5A73"
          opacity="1.000000"
          stroke="none"
          d="
M2909.204102,2625.980225 
	C2912.067627,2632.074951 2917.531250,2631.565186 2922.224609,2631.888428 
	C2928.131348,2632.294678 2934.089600,2631.877930 2940.018555,2632.058594 
	C2943.095703,2632.152344 2941.797852,2633.717529 2940.337402,2635.504395 
	C2926.249756,2640.853760 2913.885986,2634.287598 2900.562744,2630.790039 
	C2900.669922,2624.696777 2905.047363,2626.315186 2909.204102,2625.980225 
z"
        />
        <path
          fill="#4C6880"
          opacity="1.000000"
          stroke="none"
          d="
M2901.965820,2651.164062 
	C2894.535156,2655.190430 2886.863281,2654.325928 2878.583008,2652.526367 
	C2878.378174,2650.404053 2878.250244,2648.249023 2879.242920,2647.390625 
	C2882.375488,2644.682861 2898.173340,2645.654297 2901.450684,2648.484863 
	C2901.847656,2648.827881 2901.776123,2649.713623 2901.965820,2651.164062 
z"
        />
        <path
          fill="#4C6880"
          opacity="1.000000"
          stroke="none"
          d="
M2930.501953,2651.475098 
	C2923.282715,2651.986816 2916.538086,2651.994385 2908.873047,2651.960449 
	C2908.163086,2649.635742 2908.005859,2646.601807 2910.940674,2646.427734 
	C2919.575195,2645.916260 2928.287354,2645.156738 2937.135010,2646.962646 
	C2935.756592,2650.787109 2932.638672,2649.472656 2930.501953,2651.475098 
z"
        />
        <path
          fill="#4C6880"
          opacity="1.000000"
          stroke="none"
          d="
M2994.438965,2634.464355 
	C2996.219238,2631.658203 2998.211914,2631.399902 2999.967285,2635.209961 
	C2998.304688,2635.937744 2996.484619,2636.117920 2994.438965,2634.464355 
z"
        />
        <path
          fill="#2F465E"
          opacity="1.000000"
          stroke="none"
          d="
M2721.196289,2959.835693 
	C2724.996582,2959.980713 2727.929443,2960.186768 2730.832520,2961.176025 
	C2734.033203,2962.267334 2737.660645,2962.774658 2740.586670,2961.199707 
	C2750.086670,2956.085938 2759.803711,2957.563232 2769.601318,2959.488525 
	C2773.838623,2960.321045 2778.221191,2959.541260 2782.422119,2961.021973 
	C2785.512695,2962.111572 2787.561035,2959.851562 2788.959473,2957.623779 
	C2791.390137,2953.752197 2794.845703,2953.459229 2798.530029,2954.571777 
	C2812.844971,2958.895996 2827.377686,2961.497803 2842.357910,2962.424561 
	C2848.421143,2962.799805 2854.278809,2966.068359 2860.476318,2967.225830 
	C2863.594238,2967.808105 2866.429199,2968.526855 2868.072510,2964.879395 
	C2869.245850,2962.276123 2871.694580,2961.462891 2873.874023,2962.242432 
	C2881.873535,2965.103027 2890.437012,2962.710205 2899.165283,2965.530762 
	C2901.881836,2972.118164 2907.449219,2971.578613 2912.189453,2971.888428 
	C2918.035645,2972.270264 2923.456543,2973.726562 2929.150391,2975.447754 
	C2927.576416,2978.141357 2925.982666,2977.473633 2924.385010,2977.174072 
	C2912.671631,2974.978271 2900.933594,2972.764404 2889.029053,2972.107666 
	C2879.574463,2971.586426 2879.554688,2971.944336 2878.486328,2981.141113 
	C2870.673340,2979.278320 2862.686279,2980.584229 2854.819336,2979.699707 
	C2847.579102,2978.885986 2841.239502,2973.187012 2832.953613,2975.040527 
	C2833.995361,2978.850830 2836.707031,2979.288574 2839.210449,2979.808838 
	C2841.459473,2980.276367 2843.940430,2979.784668 2845.910156,2981.358154 
	C2847.433350,2982.574951 2849.390625,2983.731934 2848.781982,2986.093262 
	C2848.246826,2988.169189 2846.224854,2987.902832 2844.637207,2987.942627 
	C2841.642090,2988.018311 2838.312988,2988.767822 2835.701172,2987.750732 
	C2822.466309,2982.597900 2807.948975,2985.196777 2794.666260,2980.590332 
	C2792.497803,2979.838135 2790.112061,2979.525879 2787.739258,2980.862793 
	C2787.981201,2984.906494 2794.969482,2983.624512 2793.980469,2989.299805 
	C2795.805908,2993.762939 2793.503174,2996.645020 2791.537109,3000.272461 
	C2789.728027,3002.245117 2788.131104,3003.183594 2785.472656,3003.241211 
	C2782.915283,3002.441895 2781.159424,3001.082764 2778.251465,3001.992432 
	C2771.233643,3001.957764 2765.606445,3000.528320 2763.046631,2993.142090 
	C2766.142578,2987.998047 2771.783691,2989.540527 2776.838867,2985.999023 
	C2769.881104,2982.829102 2763.064209,2982.364990 2756.633057,2982.003906 
	C2744.851562,2981.342773 2733.016113,2977.799561 2721.175293,2981.804932 
	C2718.766846,2982.619385 2716.505127,2983.178711 2718.814453,2987.023438 
	C2719.905762,2993.104980 2716.018311,2993.668701 2712.168213,2994.755615 
	C2709.944580,2995.383301 2705.792480,2992.760986 2705.925781,2998.626465 
	C2706.608398,3004.976562 2711.697021,3006.224609 2715.300049,3008.927246 
	C2717.163574,3010.325195 2719.075684,3011.846436 2718.527100,3014.592041 
	C2718.066406,3016.897949 2715.917236,3018.327148 2714.182129,3017.982666 
	C2696.041260,3014.383301 2678.181152,3024.669189 2660.020508,3018.954834 
	C2657.160400,3018.054688 2654.293213,3017.295654 2651.085449,3015.283691 
	C2648.389893,3013.203125 2646.342773,3011.610352 2643.815186,3010.587158 
	C2640.441406,3009.221191 2636.094238,3007.451172 2637.657227,3003.056396 
	C2639.196045,2998.728516 2643.508789,2999.873779 2647.076660,3000.627441 
	C2619.661865,2999.530518 2592.211914,3003.737793 2564.009766,3000.670410 
	C2557.857422,2999.102783 2554.192627,2996.193848 2554.649170,2989.281738 
	C2561.878174,2983.024902 2569.545166,2985.824707 2576.626709,2987.538818 
	C2586.751953,2989.989258 2596.353027,2987.665283 2606.115723,2986.239746 
	C2609.568848,2985.735840 2611.280273,2983.521973 2610.596191,2979.077637 
	C2612.613770,2972.485107 2617.731689,2973.086914 2622.255859,2972.730957 
	C2635.447998,2971.693115 2648.416016,2974.609131 2659.252686,2975.064209 
	C2641.492188,2969.671387 2620.245361,2973.815186 2600.323486,2967.331787 
	C2595.878174,2965.885010 2591.388184,2964.644287 2587.498779,2961.967285 
	C2585.291504,2960.447998 2582.842773,2958.682129 2583.393066,2955.656494 
	C2584.047363,2952.058105 2587.356689,2951.526367 2590.336914,2951.096191 
	C2595.358643,2950.370850 2600.269531,2951.283936 2605.173340,2952.326660 
	C2628.581787,2957.303711 2652.251709,2960.895508 2675.960693,2963.826172 
	C2690.804688,2965.660889 2705.661865,2962.022217 2721.196289,2959.835693 
z"
        />
        <path
          fill="#243B51"
          opacity="1.000000"
          stroke="none"
          d="
M2790.410645,3000.717285 
	C2790.158447,2996.765625 2792.031494,2994.104736 2793.718750,2990.639160 
	C2799.443359,2990.682861 2804.886719,2991.346436 2811.165039,2992.012207 
	C2824.790283,2992.367188 2836.795898,2997.960449 2849.716553,2997.606934 
	C2853.274902,2997.509521 2856.959961,2998.622803 2859.849854,2994.645508 
	C2860.314941,2992.333984 2860.928955,2990.338379 2862.720459,2990.396240 
	C2869.709961,2990.620605 2876.125732,2988.677734 2882.473633,2986.090576 
	C2883.602295,2985.630615 2885.069092,2986.000488 2887.215820,2986.030762 
	C2888.074707,2988.860352 2888.263916,2991.468750 2891.931396,2991.907959 
	C2894.158447,2992.174805 2896.624756,2991.407959 2898.499023,2993.198486 
	C2899.006592,2994.391846 2898.283691,2994.981689 2897.570557,2995.710205 
	C2893.520508,2999.845459 2893.537354,3000.621094 2898.114990,3001.941650 
	C2902.850830,3003.307861 2907.610840,3005.182129 2912.658203,3002.789551 
	C2915.990234,3001.209717 2919.167236,3002.677734 2922.294922,3003.793701 
	C2931.068604,3006.923096 2940.052002,3009.124756 2949.333740,3010.076416 
	C2950.638184,3010.210205 2952.083984,3010.321777 2953.167969,3010.952637 
	C2955.125732,3012.093018 2957.700684,3013.499512 2956.760254,3016.178955 
	C2955.879150,3018.688965 2953.490723,3017.387939 2951.639160,3016.901855 
	C2944.750488,3015.093750 2937.753906,3016.390869 2930.600098,3015.952148 
	C2933.639648,3023.748291 2940.187256,3023.317139 2946.406982,3023.293945 
	C2948.614502,3023.285645 2950.750000,3021.370605 2953.522949,3023.450195 
	C2958.727783,3030.424561 2966.061279,3029.768555 2973.404297,3031.204834 
	C2977.476562,3033.880127 2981.533936,3033.428711 2985.333008,3034.172119 
	C2988.734619,3034.837646 2992.062500,3035.687012 2990.359375,3041.106445 
	C2988.935303,3041.767090 2988.041260,3041.774414 2986.476074,3041.785156 
	C2982.776123,3043.606201 2979.960205,3042.925049 2976.655518,3040.575928 
	C2976.065674,3040.093262 2976.021484,3040.030762 2975.998047,3040.000732 
	C2958.090820,3037.743896 2941.219482,3029.372070 2922.644775,3031.234375 
	C2920.036377,3031.496094 2917.328369,3030.933350 2914.537109,3033.432373 
	C2906.680664,3038.267578 2899.321777,3036.779785 2891.243164,3033.877197 
	C2882.066650,3025.826660 2871.445068,3032.179932 2862.104248,3029.144531 
	C2857.556641,3027.666260 2852.457275,3027.192871 2847.697510,3025.989258 
	C2844.040039,3025.064209 2841.301025,3022.441895 2840.656006,3018.466064 
	C2839.937500,3014.035645 2842.656250,3011.835205 2847.205811,3010.391113 
	C2867.794678,3013.982910 2887.570801,3017.925049 2907.943848,3015.979004 
	C2888.855713,3015.403076 2869.707764,3015.422363 2850.336426,3010.423096 
	C2847.264893,3009.719482 2844.868164,3009.440674 2841.761719,3009.085693 
	C2839.093994,3008.843262 2837.266113,3009.468262 2834.677734,3010.068115 
	C2833.440430,3010.231445 2832.937988,3010.264648 2831.675049,3010.258545 
	C2830.411133,3010.133789 2829.907715,3010.048340 2828.664551,3009.759277 
	C2819.320068,3005.377686 2809.667480,3008.530273 2800.149902,3005.405762 
	C2796.377441,3004.322021 2793.423096,3003.303467 2790.410645,3000.717285 
z"
        />
        <path
          fill="#2F465E"
          opacity="1.000000"
          stroke="none"
          d="
M2859.113770,2993.995605 
	C2861.579346,2994.157471 2863.659424,2993.566650 2864.504639,2995.189697 
	C2865.666992,2997.420654 2863.136230,2997.357178 2862.085205,2998.325439 
	C2857.213867,3002.813721 2851.787354,3000.204590 2846.400146,3000.173096 
	C2834.699219,3000.104248 2823.898682,2996.529785 2812.501709,2992.543945 
	C2814.928467,2988.854980 2819.016357,2989.381104 2822.192627,2990.208496 
	C2831.480469,2992.628174 2841.177002,2990.883057 2850.398193,2993.590088 
	C2852.839844,2994.306885 2855.614990,2993.888672 2859.113770,2993.995605 
z"
        />
        <path
          fill="#243B51"
          opacity="1.000000"
          stroke="none"
          d="
M2913.614258,2983.581055 
	C2912.547607,2985.929443 2910.820557,2986.277344 2908.406494,2984.580566 
	C2909.411377,2982.039307 2911.161133,2981.744873 2913.614258,2983.581055 
z"
        />
        <path
          fill="#1B2B3A"
          opacity="1.000000"
          stroke="none"
          d="
M97.477081,2998.993652 
	C99.187286,2998.256592 100.413940,2998.303223 100.878624,2999.785156 
	C101.005402,3000.189697 100.537949,3001.152344 100.149948,3001.282227 
	C98.494240,3001.837158 97.042709,3001.733643 97.477081,2998.993652 
z"
        />
        <path
          fill="#F0EBDD"
          opacity="1.000000"
          stroke="none"
          d="
M1118.091553,3990.732910 
	C1120.263062,3990.055176 1122.130615,3990.889404 1123.650146,3993.483154 
	C1121.672485,3993.914307 1119.211304,3994.228760 1118.091553,3990.732910 
z"
        />
        <path
          fill="#3E5871"
          opacity="1.000000"
          stroke="none"
          d="
M2959.264893,2764.179443 
	C2958.847168,2772.035400 2957.572021,2773.146729 2950.978271,2771.220947 
	C2948.586426,2770.522461 2945.544678,2770.465820 2944.145020,2766.777344 
	C2948.880859,2766.049316 2953.410645,2763.254395 2959.264893,2764.179443 
z"
        />
        <path
          fill="#49647C"
          opacity="1.000000"
          stroke="none"
          d="
M2948.351562,2754.086426 
	C2951.713623,2753.955078 2954.015137,2754.740723 2956.795654,2757.153320 
	C2952.574463,2758.218018 2949.010254,2758.914062 2946.124268,2757.037109 
	C2943.860596,2755.564941 2946.196045,2754.663330 2948.351562,2754.086426 
z"
        />
        <path
          fill="#7B7F84"
          opacity="1.000000"
          stroke="none"
          d="
M2979.404297,3364.271484 
	C2982.170166,3364.104736 2984.532715,3363.628174 2985.893066,3366.802246 
	C2986.302246,3380.615479 2994.352295,3385.486816 3006.166260,3387.422607 
	C3010.308350,3388.101318 3015.031494,3390.481445 3014.712402,3396.978516 
	C3014.370850,3397.593506 3014.052734,3397.940430 3013.892578,3398.116699 
	C3012.749512,3398.214844 3011.598145,3398.444092 3010.810303,3398.011230 
	C3004.085205,3394.313477 2996.515381,3395.189941 2989.342773,3393.994629 
	C2986.690430,3393.552734 2984.078369,3393.336914 2982.186523,3391.252686 
	C2978.947266,3387.684570 2975.771240,3389.307373 2972.165771,3391.760742 
	C2971.300537,3392.390869 2970.819580,3392.455811 2969.733398,3392.114746 
	C2968.554688,3390.924805 2968.115479,3390.072266 2967.666016,3388.451172 
	C2965.805176,3386.767090 2971.062988,3380.076416 2964.234619,3383.479980 
	C2956.821777,3387.174561 2951.492188,3384.880127 2946.638672,3378.072754 
	C2946.063965,3376.951660 2945.874512,3376.474609 2945.520752,3375.231201 
	C2944.786377,3365.767578 2944.711670,3357.147217 2949.845459,3348.735596 
	C2952.023193,3347.241455 2953.292236,3347.558350 2954.896973,3348.644531 
	C2955.362793,3348.960205 2953.875000,3354.501221 2957.791504,3349.917725 
	C2963.692627,3343.011475 2970.339600,3345.555908 2972.690430,3354.539795 
	C2973.691162,3358.364746 2971.261963,3364.588379 2979.404297,3364.271484 
z"
        />
        <path
          fill="#442F21"
          opacity="1.000000"
          stroke="none"
          d="
M2969.302490,3387.727539 
	C2969.879883,3389.203857 2969.997070,3390.175537 2970.375244,3391.833008 
	C2973.829834,3397.081787 2973.876465,3401.760742 2970.586914,3407.279297 
	C2970.279785,3412.884766 2969.026611,3417.885254 2971.643555,3423.298096 
	C2971.990234,3424.019531 2971.959229,3424.993652 2971.950684,3425.480957 
	C2968.600342,3428.945557 2965.817871,3425.701172 2962.098389,3425.070557 
	C2959.993896,3425.027100 2958.591797,3424.931641 2956.487305,3424.691406 
	C2951.391846,3423.911621 2947.787842,3421.773926 2944.299316,3418.067871 
	C2943.322510,3416.823975 2942.945068,3416.024414 2942.716797,3414.521973 
	C2942.876709,3413.129150 2943.131348,3412.391846 2943.185791,3411.224609 
	C2942.629639,3410.410645 2942.401123,3410.074463 2941.850586,3409.194824 
	C2940.896973,3407.469971 2940.403564,3406.230469 2939.774658,3404.271973 
	C2939.100830,3399.595215 2938.775879,3395.615967 2940.223633,3390.972656 
	C2941.835205,3388.014404 2943.777832,3386.444336 2946.747803,3385.039551 
	C2952.846191,3387.348389 2952.846191,3387.348389 2959.106689,3397.099365 
	C2963.512207,3394.947021 2962.072510,3386.982178 2969.302490,3387.727539 
z"
        />
        <path
          fill="#DFD9CC"
          opacity="1.000000"
          stroke="none"
          d="
M2970.698975,3407.721436 
	C2970.230713,3403.418701 2970.441650,3398.820801 2970.644287,3393.371094 
	C2970.635986,3392.519287 2971.250488,3392.666016 2971.515869,3392.496094 
	C2972.974609,3393.383789 2974.390381,3394.274902 2975.325684,3395.525391 
	C2981.941895,3404.372803 2981.910400,3404.396240 2970.698975,3407.721436 
z"
        />
        <path
          fill="#A9ACA9"
          opacity="1.000000"
          stroke="none"
          d="
M2959.273926,1880.639038 
	C2961.773438,1877.578613 2964.855225,1876.538086 2965.641602,1881.021973 
	C2966.568604,1886.307739 2969.890625,1885.979858 2973.604492,1885.892456 
	C2979.318604,1885.757935 2985.197266,1884.297119 2991.218994,1887.851074 
	C2993.561768,1887.601562 2995.169678,1887.194946 2997.397949,1887.872559 
	C2999.654541,1888.206665 3001.302734,1888.442749 3002.717773,1890.599487 
	C3000.047607,1893.549072 2994.669189,1890.647095 2992.285645,1895.283203 
	C2991.960205,1895.899048 2991.076416,1896.184204 2990.629395,1896.309814 
	C2984.538330,1898.086304 2988.377197,1904.406982 2984.498047,1907.624268 
	C2982.807617,1909.776367 2981.029053,1909.828003 2978.502686,1909.515869 
	C2977.234863,1907.949341 2976.644043,1906.520386 2975.964844,1904.500488 
	C2969.727783,1897.809814 2963.847656,1891.094116 2953.791504,1893.011230 
	C2951.037842,1893.536255 2949.044189,1892.724731 2948.515381,1890.071045 
	C2947.891113,1886.938232 2950.168701,1886.261963 2952.745605,1886.121582 
	C2955.933350,1885.947876 2959.816650,1886.660767 2959.273926,1880.639038 
z"
        />
        <path
          fill="#959693"
          opacity="1.000000"
          stroke="none"
          d="
M2984.796875,1907.956421 
	C2984.148438,1904.551025 2983.974609,1900.959839 2984.355225,1897.428345 
	C2984.678955,1894.421631 2986.620117,1893.758545 2989.708984,1895.861328 
	C2989.020508,1900.384888 2991.600098,1905.872192 2984.796875,1907.956421 
z"
        />
        <path
          fill="#C7C0B1"
          opacity="1.000000"
          stroke="none"
          d="
M1563.998779,3896.750000 
	C1566.753906,3877.958740 1571.707397,3860.382324 1576.779541,3842.984131 
	C1580.122559,3831.517334 1587.139648,3821.107910 1592.644043,3810.292236 
	C1594.743774,3806.166260 1597.524292,3802.362061 1598.131104,3796.838379 
	C1603.837280,3794.144775 1604.691406,3788.902344 1605.900513,3784.220947 
	C1608.205322,3775.297363 1613.038696,3767.147217 1613.833618,3757.771729 
	C1614.006104,3755.738281 1614.976562,3753.952637 1617.357178,3752.355469 
	C1618.152344,3756.295166 1617.483765,3760.651855 1619.258301,3764.817871 
	C1620.532593,3767.810059 1619.379028,3770.625000 1618.445801,3773.519287 
	C1616.918945,3778.255127 1615.563477,3783.059814 1614.427734,3787.904053 
	C1613.460083,3792.030762 1613.826050,3795.733887 1618.262695,3798.139404 
	C1622.331177,3800.345703 1624.986206,3798.532715 1628.057373,3796.087158 
	C1632.087769,3792.877441 1634.750732,3787.781738 1640.776978,3787.135498 
	C1642.792969,3786.919678 1644.337280,3784.362793 1643.524780,3782.461670 
	C1638.904663,3771.652100 1645.058716,3763.296387 1649.537842,3754.565674 
	C1650.120850,3753.429443 1651.385986,3752.549561 1651.077881,3751.124023 
	C1650.561890,3748.735596 1647.172607,3746.907959 1649.565918,3743.969971 
	C1651.698120,3741.352295 1655.185547,3742.318115 1657.882446,3741.097656 
	C1659.045410,3740.571533 1660.407471,3740.485107 1661.779541,3740.176514 
	C1664.753052,3747.483643 1663.923340,3753.410645 1658.244141,3759.145508 
	C1654.933716,3762.487549 1657.474487,3767.892090 1656.520752,3772.317139 
	C1656.124512,3774.155518 1657.086914,3776.427002 1654.826538,3777.433594 
	C1649.675659,3779.727783 1648.170654,3784.480469 1646.329712,3789.139404 
	C1644.601929,3793.511963 1642.392456,3797.789062 1638.326416,3800.359131 
	C1633.030884,3803.706055 1626.566772,3805.864990 1627.443481,3814.029541 
	C1619.217651,3818.071533 1618.478394,3827.104492 1614.178467,3833.771240 
	C1611.572388,3837.812500 1608.854614,3842.021484 1610.691528,3846.678467 
	C1613.968262,3854.985352 1609.085571,3860.364014 1604.753784,3865.973877 
	C1603.768311,3867.250488 1601.740479,3868.197510 1602.521362,3869.878906 
	C1606.463745,3878.367188 1598.791382,3881.817139 1594.833740,3886.102539 
	C1591.874634,3889.306641 1588.339600,3891.583008 1587.964600,3896.348877 
	C1587.764526,3898.891113 1586.235229,3900.504150 1584.307739,3902.000000 
	C1580.941284,3904.612549 1582.155396,3907.078857 1585.442871,3909.682129 
	C1581.361938,3919.327393 1576.100708,3928.033691 1565.814819,3932.410645 
	C1561.788574,3934.124023 1558.242432,3937.342773 1556.807007,3941.321045 
	C1554.577637,3947.500732 1550.122803,3951.769043 1546.052979,3957.216797 
	C1540.808228,3957.759766 1537.076416,3959.900879 1532.847534,3963.071289 
	C1527.316040,3967.218018 1520.047852,3969.048096 1512.806152,3971.977051 
	C1512.082397,3969.975830 1513.128052,3968.351318 1514.228516,3966.626221 
	C1516.815430,3962.570801 1520.759399,3959.045898 1518.851929,3953.195068 
	C1518.349243,3951.653076 1519.740601,3949.378906 1520.540039,3947.563232 
	C1521.203369,3946.056396 1522.233154,3944.481445 1524.298828,3945.203369 
	C1525.747559,3945.710205 1525.980347,3947.192871 1525.885254,3948.507080 
	C1525.698486,3951.087891 1525.322632,3953.655029 1524.968384,3956.716797 
	C1532.119873,3955.484131 1537.072876,3949.730469 1544.186768,3949.379150 
	C1547.176392,3949.231445 1547.606079,3945.759033 1548.543823,3943.258789 
	C1550.017334,3939.330566 1550.447876,3934.709961 1555.875854,3933.458252 
	C1558.275269,3932.905273 1558.916382,3930.129150 1559.743042,3927.949219 
	C1561.274658,3923.910645 1562.414673,3919.668701 1564.407104,3915.873291 
	C1567.201172,3910.550781 1568.578979,3905.616943 1564.238770,3900.460693 
	C1563.724854,3899.850342 1564.069580,3898.517578 1563.998779,3896.750000 
z"
        />
        <path
          fill="#CBC5B5"
          opacity="1.000000"
          stroke="none"
          d="
M1505.427246,3974.046875 
	C1507.603394,3973.377441 1509.201782,3972.765137 1511.448120,3972.138672 
	C1512.028687,3974.082520 1512.027466,3976.045898 1511.876831,3977.997559 
	C1511.692749,3980.383301 1510.337891,3981.864990 1508.020874,3980.801514 
	C1504.016357,3978.963623 1502.166992,3982.567871 1498.677002,3983.850586 
	C1496.374390,3981.983887 1496.847168,3980.316650 1499.385254,3978.430908 
	C1500.607178,3977.739746 1501.112061,3977.352783 1501.748901,3976.409912 
	C1502.799438,3975.148193 1503.690063,3974.422363 1505.427246,3974.046875 
z"
        />
        <path
          fill="#CBC5B5"
          opacity="1.000000"
          stroke="none"
          d="
M1480.693970,3993.848145 
	C1479.835693,3987.812256 1486.851929,3988.747559 1489.471680,3984.406250 
	C1491.421143,3982.940430 1492.958008,3982.474121 1495.376099,3983.523193 
	C1495.375366,3988.780029 1492.564087,3991.273926 1487.893066,3992.048096 
	C1485.708252,3992.410400 1483.592896,3993.192139 1480.693970,3993.848145 
z"
        />
        <path
          fill="#C7C0B1"
          opacity="1.000000"
          stroke="none"
          d="
M1496.532715,3984.018066 
	C1494.535889,3984.016357 1493.026367,3984.008789 1490.762817,3984.002197 
	C1491.871826,3980.578613 1494.307617,3977.975342 1499.293213,3978.003418 
	C1499.370239,3979.561768 1498.742798,3981.133301 1498.054321,3983.337891 
	C1497.993286,3983.970703 1497.019775,3984.012207 1496.532715,3984.018066 
z"
        />
        <path
          fill="#3C556B"
          opacity="1.000000"
          stroke="none"
          d="
M2933.003418,2993.840332 
	C2946.378174,2992.436523 2957.987305,2997.976074 2970.331055,2998.466553 
	C2975.990967,2998.691406 2971.647217,3002.510254 2970.311035,3005.359131 
	C2956.052002,3006.498291 2942.894287,3002.029541 2929.457520,2999.404785 
	C2926.239746,2998.776367 2923.077637,2998.030029 2918.963867,2997.852295 
	C2917.168457,2996.270508 2912.234131,2998.004639 2915.396973,2993.636230 
	C2921.450684,2993.713135 2926.784424,2993.795410 2933.003418,2993.840332 
z"
        />
        <path
          fill="#243B51"
          opacity="1.000000"
          stroke="none"
          d="
M2915.890869,2992.960449 
	C2914.095215,2995.804199 2916.815430,2996.020020 2917.831787,2997.440918 
	C2913.921875,2997.941650 2909.502197,2998.950439 2906.234863,2995.053711 
	C2905.384521,2994.039307 2904.485352,2992.856201 2905.291992,2991.567383 
	C2905.726318,2990.872803 2906.835938,2990.248047 2907.664307,2990.217285 
	C2910.498779,2990.111572 2913.662354,2989.088867 2915.890869,2992.960449 
z"
        />
        <path
          fill="#2B3E51"
          opacity="1.000000"
          stroke="none"
          d="
M76.368317,2948.550049 
	C76.374893,2944.870605 74.896820,2940.405029 79.896675,2940.405029 
	C83.365730,2940.405273 86.956306,2941.700195 90.258949,2943.007080 
	C92.291107,2943.811279 91.873779,2946.361816 92.014015,2949.082764 
	C86.763252,2953.417725 81.676003,2953.104004 76.368317,2948.550049 
z"
        />
        <path
          fill="#A9ACA9"
          opacity="1.000000"
          stroke="none"
          d="
M2992.917969,1884.375122 
	C2992.129883,1881.556152 2991.935059,1879.248047 2995.183105,1877.757812 
	C2998.771973,1878.251953 3000.262939,1879.753540 2998.261719,1883.457886 
	C2996.680908,1885.154053 2995.184814,1885.432129 2992.917969,1884.375122 
z"
        />
        <path
          fill="#959693"
          opacity="1.000000"
          stroke="none"
          d="
M2992.195068,1884.518066 
	C2993.729492,1883.926758 2995.155518,1883.997437 2997.298828,1884.052246 
	C2998.012939,1884.913330 2998.009766,1885.790649 2998.003418,1887.325806 
	C2996.514160,1889.989746 2994.756104,1890.332031 2992.325195,1888.351318 
	C2991.996094,1886.960938 2992.041504,1886.070435 2992.195068,1884.518066 
z"
        />
        <path
          fill="#102131"
          opacity="1.000000"
          stroke="none"
          d="
M3016.210449,3276.853516 
	C3016.921631,3278.960938 3015.937500,3279.932861 3014.517578,3279.731689 
	C3007.858398,3278.789307 3001.420654,3277.023438 2995.300049,3274.031982 
	C3002.595703,3271.208008 3008.921143,3275.539307 3016.210449,3276.853516 
z"
        />
        <path
          fill="#172B3E"
          opacity="1.000000"
          stroke="none"
          d="
M2983.635254,3285.538086 
	C2982.310303,3287.957764 2981.035400,3287.612305 2980.001465,3284.768311 
	C2981.015381,3284.508545 2982.142822,3284.787354 2983.635254,3285.538086 
z"
        />
        <path
          fill="#172B3E"
          opacity="1.000000"
          stroke="none"
          d="
M2992.018066,3287.292969 
	C2991.000244,3287.548828 2989.870850,3287.254883 2988.370605,3286.493652 
	C2989.714844,3284.016113 2990.985107,3284.428223 2992.018066,3287.292969 
z"
        />
        <path
          fill="#122B42"
          opacity="1.000000"
          stroke="none"
          d="
M2963.561523,3067.284180 
	C2972.926514,3069.211914 2981.583740,3070.648438 2991.083008,3072.138184 
	C2993.101318,3077.303955 2997.869141,3076.732178 3001.344482,3077.816162 
	C3007.173096,3079.634277 3013.346924,3080.360107 3018.464600,3082.742676 
	C3019.972656,3085.979736 3010.466064,3087.293701 3018.088379,3090.753418 
	C3020.252686,3091.735840 3018.307617,3093.325684 3016.628662,3093.859375 
	C3015.394775,3094.251465 3014.148682,3094.605713 3012.454102,3095.474854 
	C3007.750000,3099.722900 3002.939697,3097.925537 2997.516113,3097.125000 
	C2994.048828,3095.518066 2991.271484,3094.611816 2987.501465,3096.176514 
	C2984.084229,3096.793945 2981.450195,3096.668945 2978.080078,3095.648926 
	C2970.010010,3091.318604 2962.048096,3089.801270 2953.854492,3088.832275 
	C2948.515869,3088.200928 2942.273438,3088.379639 2941.508789,3080.072998 
	C2952.944336,3073.717285 2963.654053,3079.702148 2975.246582,3080.511719 
	C2977.894775,3081.054443 2979.818115,3081.110107 2981.875000,3080.671631 
	C2980.259277,3078.945801 2978.341797,3080.294678 2975.898926,3079.972168 
	C2959.911621,3077.452393 2944.425781,3076.947998 2928.471191,3073.687256 
	C2925.158447,3071.147705 2922.080322,3068.851074 2923.473877,3065.116211 
	C2925.016846,3060.980469 2929.223389,3061.048584 2932.842285,3061.142334 
	C2943.132568,3061.408691 2953.382812,3062.090332 2963.561523,3067.284180 
z"
        />
        <path
          fill="#43596D"
          opacity="1.000000"
          stroke="none"
          d="
M2995.361816,3094.126221 
	C3000.737061,3095.306396 3005.444580,3096.403076 3011.171875,3095.985352 
	C3011.751953,3098.420654 3010.642578,3101.765381 3008.406738,3101.062012 
	C3002.807129,3099.299561 2998.747803,3102.920166 2994.082764,3104.036133 
	C2981.497314,3107.047363 2969.088135,3102.525391 2956.596436,3103.816895 
	C2954.385986,3104.045410 2953.144531,3102.630615 2951.937988,3100.786377 
	C2949.513184,3097.078857 2946.196777,3094.407227 2940.744873,3094.033936 
	C2940.371826,3092.884521 2940.753174,3091.759033 2941.616455,3090.265625 
	C2947.629395,3085.319092 2953.713379,3088.671143 2959.189209,3089.495117 
	C2964.953613,3090.362549 2970.873291,3091.239990 2977.308594,3093.361328 
	C2980.805176,3093.962158 2983.555420,3094.079590 2987.131104,3094.217285 
	C2990.196289,3093.983398 2992.441650,3093.983154 2995.361816,3094.126221 
z"
        />
        <path
          fill="#627A90"
          opacity="1.000000"
          stroke="none"
          d="
M2979.656006,2602.834229 
	C2977.464355,2602.211426 2975.951416,2601.847900 2974.438477,2601.484619 
	C2979.224121,2599.816162 2983.892578,2599.397705 2989.823486,2601.462646 
	C2986.241699,2604.685303 2983.306641,2603.989746 2979.656006,2602.834229 
z"
        />
        <path
          fill="#5A605E"
          opacity="1.000000"
          stroke="none"
          d="
M2979.458984,3363.557617 
	C2978.302490,3366.386963 2976.577637,3368.773926 2974.239746,3372.009766 
	C2971.264893,3366.609375 2970.782471,3361.494385 2970.111816,3356.544189 
	C2969.604736,3352.801514 2969.726807,3348.939453 2964.865723,3348.239258 
	C2960.497070,3347.610596 2959.506104,3351.283936 2957.878662,3354.121094 
	C2957.092285,3355.491943 2956.829590,3357.337158 2954.836182,3357.890869 
	C2952.925293,3355.047363 2955.109863,3351.284424 2952.192383,3348.096191 
	C2945.977295,3334.405518 2954.878174,3314.147705 2969.168945,3309.708496 
	C2974.649658,3311.503174 2979.477295,3312.774414 2985.020508,3314.250244 
	C2986.764648,3320.693359 2985.462402,3326.719238 2983.925049,3333.568848 
	C2981.802490,3342.933105 2978.118164,3350.977051 2976.966309,3360.158447 
	C2977.880127,3361.496582 2978.385498,3362.306152 2979.458984,3363.557617 
z"
        />
        <path
          fill="#442F21"
          opacity="1.000000"
          stroke="none"
          d="
M2969.738037,3309.544189 
	C2956.222412,3318.308350 2952.600342,3331.451416 2951.655762,3346.784424 
	C2948.575928,3356.298096 2947.567139,3365.286621 2947.653809,3375.355713 
	C2946.513184,3376.863037 2945.432861,3377.003662 2943.629883,3376.160156 
	C2940.507080,3371.614990 2940.948242,3367.131348 2941.006592,3361.934570 
	C2941.557861,3356.190674 2944.105957,3351.319092 2941.613281,3345.497803 
	C2941.515869,3343.748535 2941.567383,3342.754883 2941.830566,3341.045898 
	C2943.008301,3337.193115 2944.020508,3334.045654 2945.941895,3330.546875 
	C2947.485352,3328.263672 2948.829102,3326.602783 2947.946777,3323.639648 
	C2947.431641,3320.777832 2947.399414,3318.546875 2947.799072,3315.633545 
	C2948.214844,3313.619873 2948.657471,3312.325439 2949.293945,3310.374023 
	C2949.587891,3309.266602 2949.721680,3308.826660 2950.090576,3307.736328 
	C2951.681396,3304.144775 2952.598389,3300.883545 2956.630371,3299.249512 
	C2958.808594,3299.211182 2959.958252,3299.946289 2961.382080,3301.560547 
	C2963.798584,3305.090820 2969.222412,3304.098633 2969.738037,3309.544189 
z"
        />
        <path
          fill="#395166"
          opacity="1.000000"
          stroke="none"
          d="
M2958.651855,3300.076660 
	C2954.400879,3301.280029 2954.517090,3304.929199 2952.660645,3308.052734 
	C2948.958984,3308.760498 2947.643311,3306.164062 2948.286621,3304.120605 
	C2950.947754,3295.667969 2945.412109,3294.075928 2939.402588,3292.412598 
	C2922.853760,3287.832764 2906.150635,3283.756348 2890.076416,3277.593018 
	C2886.735352,3276.311768 2882.844727,3275.799072 2881.858887,3270.805664 
	C2893.571289,3269.982666 2903.993896,3274.861572 2914.904053,3277.489014 
	C2928.437256,3280.748047 2941.843994,3284.542969 2955.261230,3288.265137 
	C2959.376953,3289.406738 2963.984863,3290.392090 2963.758789,3297.006836 
	C2963.686523,3297.742432 2962.816650,3297.829590 2962.383789,3297.890625 
	C2961.215576,3298.862549 2960.330811,3299.570557 2958.651855,3300.076660 
z"
        />
        <path
          fill="#172B3E"
          opacity="1.000000"
          stroke="none"
          d="
M2958.420166,3300.624512 
	C2958.650635,3299.062744 2959.519043,3298.323242 2961.357178,3298.003662 
	C2961.949707,3298.869629 2961.948242,3299.787109 2961.897949,3301.392578 
	C2960.817383,3301.741699 2959.785400,3301.402832 2958.420166,3300.624512 
z"
        />
        <path
          fill="#626669"
          opacity="1.000000"
          stroke="none"
          d="
M18.014832,3878.649414 
	C18.810980,3876.650635 19.831989,3876.027344 21.644428,3877.349854 
	C21.725267,3879.541260 22.608667,3882.145020 20.266518,3882.696777 
	C17.971468,3883.237793 18.373203,3880.764404 18.014832,3878.649414 
z"
        />
        <path
          fill="#D7D5CF"
          opacity="1.000000"
          stroke="none"
          d="
M2977.458008,3360.032227 
	C2975.947754,3360.905518 2974.354004,3360.626709 2974.628174,3358.970703 
	C2976.054443,3350.349854 2978.216553,3341.923828 2983.038086,3334.253174 
	C2983.626465,3338.822998 2985.882080,3341.076904 2990.183594,3341.121826 
	C2993.849609,3341.160400 2997.423584,3341.122559 3000.047852,3345.418945 
	C3000.228516,3347.590820 2999.201660,3348.453857 2996.811035,3348.660156 
	C2990.068115,3346.597900 2985.183594,3347.452637 2981.739746,3351.780762 
	C2979.961914,3354.014893 2978.034912,3356.350098 2977.458008,3360.032227 
z"
        />
        <path
          fill="#748B9F"
          opacity="1.000000"
          stroke="none"
          d="
M80.297646,2523.420166 
	C72.063850,2525.837158 64.147385,2527.616211 55.910671,2528.145996 
	C50.450661,2528.497070 45.098259,2530.521729 38.910999,2531.789307 
	C37.301533,2531.080811 36.949940,2530.194092 37.446129,2528.466309 
	C44.017666,2522.531982 51.717430,2521.276123 59.336052,2520.334961 
	C64.867142,2519.651855 70.535042,2520.076416 77.067261,2520.008301 
	C79.261009,2520.568848 80.202660,2521.428467 80.297646,2523.420166 
z"
        />
        <path
          fill="#687D90"
          opacity="1.000000"
          stroke="none"
          d="
M37.128540,2527.830566 
	C37.895695,2528.696045 37.969097,2529.580322 38.083397,2531.122559 
	C35.932907,2531.839111 33.741520,2531.897217 30.727898,2531.908203 
	C30.018803,2529.471924 28.306229,2528.288818 26.355915,2526.527588 
	C27.686674,2523.886963 29.676487,2523.665039 31.556221,2525.559814 
	C32.963623,2526.978760 34.424717,2527.898193 37.128540,2527.830566 
z"
        />
        <path
          fill="#687D90"
          opacity="1.000000"
          stroke="none"
          d="
M80.781418,2524.027344 
	C79.502205,2523.028320 78.980156,2522.031494 78.225342,2520.521973 
	C80.121933,2519.022705 82.199547,2517.857910 85.385834,2518.113281 
	C86.073463,2519.584473 86.051750,2521.009277 86.058609,2523.144531 
	C84.570976,2523.913330 83.054779,2523.971191 80.781418,2524.027344 
z"
        />
        <path
          fill="#F4EEDC"
          opacity="1.000000"
          stroke="none"
          d="
M50.267719,1637.205078 
	C52.176582,1638.480469 52.722145,1640.133789 50.605324,1640.869873 
	C49.533009,1641.242798 47.016083,1641.759766 46.795658,1639.420044 
	C46.651981,1637.894897 48.621258,1637.865112 50.267719,1637.205078 
z"
        />
        <path
          fill="#F4EEDC"
          opacity="1.000000"
          stroke="none"
          d="
M40.347042,1640.527344 
	C39.785549,1638.818481 39.656467,1637.527832 41.106392,1636.993774 
	C41.471115,1636.859253 42.413895,1637.381836 42.591427,1637.809692 
	C43.336246,1639.604736 42.739876,1640.777100 40.347042,1640.527344 
z"
        />
        <path
          fill="#2F465E"
          opacity="1.000000"
          stroke="none"
          d="
M2943.148438,2554.478516 
	C2946.153564,2560.607178 2951.507568,2559.765381 2957.153076,2560.036865 
	C2958.043457,2562.403076 2956.449707,2563.650391 2954.419434,2565.330078 
	C2936.645264,2563.808105 2919.000977,2565.295166 2900.835938,2562.166992 
	C2900.015625,2560.619385 2900.021484,2559.170654 2900.016357,2557.005371 
	C2905.969971,2556.247314 2911.859863,2556.314941 2917.843994,2557.270264 
	C2926.107666,2558.589844 2934.258301,2556.153320 2943.148438,2554.478516 
z"
        />
        <path
          fill="#C4BDAE"
          opacity="1.000000"
          stroke="none"
          d="
M47.922333,1766.622681 
	C48.530540,1766.558105 49.025360,1767.119751 49.580360,1768.257446 
	C49.030006,1768.305176 48.419479,1767.777100 47.922333,1766.622681 
z"
        />
        <path
          fill="#3C556B"
          opacity="1.000000"
          stroke="none"
          d="
M2971.991211,2991.987549 
	C2971.982178,2991.975098 2971.508301,2992.829102 2971.508301,2992.829102 
	C2971.508301,2992.829102 2971.550049,2992.214355 2971.787598,2992.115967 
	C2972.024902,2992.017578 2972.000000,2992.000000 2971.991211,2991.987549 
z"
        />
        <path
          fill="#233444"
          opacity="1.000000"
          stroke="none"
          d="
M33.626244,3231.630615 
	C32.801422,3233.735840 31.084827,3233.989990 28.567043,3233.819092 
	C28.593082,3230.657715 30.131535,3229.293213 33.626244,3231.630615 
z"
        />
        <path
          fill="#172B3E"
          opacity="1.000000"
          stroke="none"
          d="
M2990.750000,3041.840332 
	C2989.965820,3038.946289 2989.666504,3036.333252 2986.060791,3036.122070 
	C2982.071777,3035.888428 2978.397949,3034.700928 2974.437500,3032.293213 
	C2986.897949,3031.030029 2998.574463,3036.658691 3011.468018,3039.377441 
	C3005.591309,3044.108154 2998.439941,3041.106689 2990.750000,3041.840332 
z"
        />
        <path
          fill="#DED5C3"
          opacity="1.000000"
          stroke="none"
          d="
M20.625580,1748.199829 
	C19.666870,1747.910522 19.532204,1747.573730 19.209667,1746.720581 
	C23.428293,1742.883423 28.262579,1743.682495 33.041939,1744.943481 
	C34.166183,1745.240112 35.307037,1746.321167 34.963909,1747.928101 
	C34.621471,1749.531982 33.291084,1749.810913 32.000683,1749.817017 
	C28.403215,1749.833984 25.150116,1747.572021 20.625580,1748.199829 
z"
        />
        <path
          fill="#DED5C3"
          opacity="1.000000"
          stroke="none"
          d="
M41.073822,1746.977417 
	C41.120228,1747.060181 41.027412,1746.894653 41.073822,1746.977417 
z"
        />
        <path
          fill="#F4EEDC"
          opacity="1.000000"
          stroke="none"
          d="
M50.368195,1750.150269 
	C50.284508,1749.509277 50.657913,1748.704590 51.798382,1749.750977 
	C51.626385,1750.124390 51.267090,1750.163452 50.368195,1750.150269 
z"
        />
        <path
          fill="#4C6174"
          opacity="1.000000"
          stroke="none"
          d="
M141.551514,2675.400879 
	C142.031128,2679.334229 141.231506,2681.977051 137.196335,2681.910156 
	C133.297623,2681.845459 129.313843,2682.652588 124.868805,2680.463867 
	C122.837379,2678.739746 120.943100,2678.798828 118.673065,2678.029297 
	C116.550636,2677.863037 115.069069,2677.863037 112.846985,2677.840576 
	C111.158951,2676.955078 110.098183,2676.583252 108.359360,2677.423828 
	C107.041946,2677.791504 106.205772,2677.799805 104.742477,2677.797852 
	C102.152122,2675.606934 99.044632,2676.459717 96.389046,2674.502441 
	C97.236633,2671.824463 98.493828,2669.672363 99.803543,2666.893799 
	C102.928123,2663.136475 106.630661,2664.410156 110.099373,2664.996826 
	C120.848763,2666.815186 131.178146,2670.048340 141.551514,2675.400879 
z"
        />
        <path
          fill="#3F5467"
          opacity="1.000000"
          stroke="none"
          d="
M95.304321,2673.995117 
	C98.702644,2674.343994 101.969131,2673.000732 103.917160,2677.110840 
	C99.796463,2677.810547 95.436417,2678.699951 90.609131,2676.661377 
	C91.052887,2674.341064 92.781334,2674.048584 95.304321,2673.995117 
z"
        />
        <path
          fill="#3F5467"
          opacity="1.000000"
          stroke="none"
          d="
M118.023628,2678.552979 
	C120.428459,2675.503418 122.432724,2675.478760 124.009590,2679.487305 
	C122.532532,2680.732910 120.914062,2681.334229 118.647797,2681.967773 
	C118.005020,2681.081055 118.010033,2680.162109 118.023628,2678.552979 
z"
        />
        <path
          fill="#3F5467"
          opacity="1.000000"
          stroke="none"
          d="
M108.581604,2677.844727 
	C108.603966,2675.187500 109.497589,2673.216797 111.982620,2677.266602 
	C111.166008,2677.847168 110.225540,2677.876709 108.581604,2677.844727 
z"
        />
        <path
          fill="#172B3E"
          opacity="1.000000"
          stroke="none"
          d="
M2891.350342,3031.539551 
	C2898.317627,3033.242676 2904.552002,3034.503418 2911.462891,3032.668945 
	C2924.079346,3030.418457 2933.951904,3035.123535 2943.354004,3043.322754 
	C2949.644043,3046.698242 2955.611572,3048.466797 2962.129883,3048.053711 
	C2965.104492,3047.864746 2967.935303,3048.216553 2970.770264,3049.739258 
	C2978.707764,3054.003174 2987.381348,3056.298096 2996.091553,3058.428955 
	C2997.259033,3058.714844 2999.029541,3059.347412 2998.064941,3061.014404 
	C2997.504395,3061.983154 2995.917969,3062.358398 2994.409668,3063.467285 
	C2988.411865,3064.713867 2983.314453,3061.267334 2976.881836,3061.910156 
	C2966.019775,3058.819336 2956.117432,3054.311279 2945.390381,3059.230713 
	C2944.797607,3059.502441 2944.064453,3059.693359 2943.444336,3059.569580 
	C2925.463135,3055.983154 2907.113770,3058.774414 2889.068359,3056.401123 
	C2887.085205,3056.140381 2885.171875,3055.454590 2883.446777,3054.446533 
	C2880.655762,3052.815918 2876.671387,3051.623291 2877.401611,3047.531982 
	C2878.179199,3043.174072 2882.104980,3043.640381 2885.461670,3043.952393 
	C2888.745850,3044.257568 2892.027344,3042.765869 2894.574219,3043.741455 
	C2889.746094,3043.845215 2884.175049,3043.855469 2878.634033,3043.594482 
	C2870.803955,3043.225830 2868.266846,3039.251465 2870.749023,3030.786865 
	C2877.910645,3028.781250 2884.299316,3028.879639 2891.350342,3031.539551 
z"
        />
        <path
          fill="#334D63"
          opacity="1.000000"
          stroke="none"
          d="
M2943.962646,3043.098877 
	C2933.746338,3040.023682 2923.603271,3036.159424 2912.724609,3032.235596 
	C2913.386719,3028.989746 2915.986328,3028.003906 2919.264893,3028.029297 
	C2926.594971,3028.085693 2933.926025,3028.016602 2941.256104,3028.085693 
	C2942.561523,3028.098145 2944.118164,3028.148682 2945.126709,3028.825439 
	C2953.758057,3034.618896 2963.800293,3034.950195 2973.546631,3036.504883 
	C2974.319580,3036.628174 2974.923828,3037.807129 2975.790527,3039.232910 
	C2969.820068,3042.750732 2963.950928,3039.698486 2958.037598,3038.852539 
	C2954.805420,3038.390137 2951.637451,3037.993164 2948.391602,3038.087646 
	C2945.653809,3038.167480 2944.274414,3039.650391 2943.962646,3043.098877 
z"
        />
        <path
          fill="#172B3E"
          opacity="1.000000"
          stroke="none"
          d="
M2976.070312,3040.824707 
	C2978.937988,3040.047607 2981.848145,3039.722656 2985.157715,3041.512207 
	C2985.881592,3043.319336 2985.957764,3044.849365 2986.067871,3047.138672 
	C2981.504639,3047.768066 2976.923096,3047.613525 2976.070312,3040.824707 
z"
        />
        <path
          fill="#2F465E"
          opacity="1.000000"
          stroke="none"
          d="
M2962.953369,2562.936279 
	C2963.034424,2562.894287 2962.872314,2562.978271 2962.953369,2562.936279 
z"
        />
        <path
          fill="#3F5467"
          opacity="1.000000"
          stroke="none"
          d="
M142.799835,2676.058594 
	C135.794418,2675.868164 130.072464,2673.886475 124.180954,2671.733887 
	C116.915565,2669.079590 109.060608,2668.039062 100.658249,2666.284180 
	C98.461739,2666.236328 97.067398,2666.205322 94.973877,2666.155029 
	C89.035896,2663.159424 81.983261,2667.269531 77.005096,2661.059570 
	C76.370071,2659.194092 76.367409,2658.078613 76.974396,2656.188965 
	C82.227180,2650.376221 88.458229,2650.680420 95.391891,2651.576660 
	C106.867119,2658.556396 118.631020,2655.647217 130.085220,2655.520508 
	C140.446335,2655.405762 150.484039,2651.395752 161.510162,2653.304688 
	C164.713226,2660.135254 160.265060,2661.207275 155.789200,2661.857910 
	C152.255569,2662.371582 148.577698,2660.961914 145.063583,2662.962891 
	C153.070114,2664.052734 161.112549,2665.022949 168.998383,2662.004150 
	C177.573593,2658.721680 186.444580,2658.759033 195.387268,2659.152100 
	C200.578735,2659.380371 205.060043,2661.092041 208.831284,2665.864502 
	C209.613953,2667.491211 209.824112,2668.500244 209.794785,2670.333984 
	C209.349564,2672.713623 208.542221,2674.054932 206.685623,2675.583984 
	C198.042709,2677.753174 189.985550,2678.268799 180.958466,2677.918701 
	C173.528381,2674.259033 166.739594,2676.588867 160.000595,2676.865723 
	C156.457001,2677.011719 152.879013,2677.916992 148.623108,2676.522949 
	C146.465973,2676.063721 144.999100,2676.059082 142.799835,2676.058594 
z"
        />
        <path
          fill="#2B3E51"
          opacity="1.000000"
          stroke="none"
          d="
M77.334587,2659.425781 
	C79.599144,2662.074707 81.933807,2662.030029 84.208496,2661.969727 
	C87.616150,2661.879639 90.589882,2662.955811 93.768814,2665.566162 
	C90.704124,2666.775391 87.133553,2667.415527 82.783638,2668.080078 
	C80.245316,2668.046631 78.479332,2667.949707 76.406235,2669.429443 
	C74.351700,2670.469971 72.816116,2671.104492 70.655159,2671.788086 
	C61.833824,2671.946533 53.637333,2672.003174 44.718315,2672.128662 
	C33.483082,2675.262451 22.781387,2676.723145 10.898373,2675.899658 
	C8.257234,2672.633301 8.160947,2670.203613 12.758960,2669.838379 
	C14.124511,2669.729980 15.163116,2668.889404 15.951710,2666.991211 
	C17.625883,2666.352783 19.115623,2666.330078 21.351021,2666.357178 
	C25.028494,2665.585693 28.254108,2666.670166 31.586531,2664.637695 
	C32.114140,2664.250488 32.196152,2663.375488 32.146820,2662.938965 
	C32.925591,2660.960205 34.344894,2660.363037 36.751499,2660.049805 
	C43.869553,2662.193604 49.639347,2659.515869 56.311707,2657.721680 
	C62.027493,2656.632080 66.992050,2656.557861 72.804802,2657.222168 
	C74.735939,2657.712891 75.748016,2658.211182 77.334587,2659.425781 
z"
        />
        <path
          fill="#3F5467"
          opacity="1.000000"
          stroke="none"
          d="
M44.629780,2672.691650 
	C44.767178,2671.612549 45.538467,2670.550781 46.310333,2670.550537 
	C54.111263,2670.546143 61.913761,2668.535156 69.871651,2671.163574 
	C61.973175,2675.896240 53.577831,2673.810059 44.629780,2672.691650 
z"
        />
        <path
          fill="#3F5467"
          opacity="1.000000"
          stroke="none"
          d="
M76.565788,2669.897949 
	C76.528282,2666.712891 78.040184,2665.287109 81.609612,2667.602051 
	C80.834419,2669.791992 79.095978,2670.021484 76.565788,2669.897949 
z"
        />
        <path
          fill="#49647C"
          opacity="1.000000"
          stroke="none"
          d="
M2999.041016,2699.069336 
	C2998.957031,2699.111084 2999.125244,2699.027344 2999.041016,2699.069336 
z"
        />
        <path
          fill="#1F3041"
          opacity="1.000000"
          stroke="none"
          d="
M2955.729004,3422.517090 
	C2957.724609,3422.033203 2959.146240,3421.988037 2961.278809,3421.943848 
	C2964.256592,3425.308350 2967.940430,3426.872559 2971.557129,3429.566895 
	C2972.023682,3430.019531 2972.000977,3430.004883 2971.993164,3429.991211 
	C2974.702881,3436.585205 2973.392578,3444.341309 2978.016602,3451.104492 
	C2961.586670,3449.330811 2944.450928,3450.857422 2928.281494,3444.608643 
	C2923.802002,3442.877441 2918.821777,3441.555664 2913.677246,3441.918945 
	C2909.323486,3442.226074 2906.033691,3440.518799 2904.507568,3435.093506 
	C2912.188232,3435.626953 2919.355225,3435.112793 2926.306396,3434.993164 
	C2932.581543,3434.885010 2939.608398,3436.818848 2946.202881,3438.355957 
	C2948.005371,3438.776123 2950.282715,3440.404053 2951.322266,3437.726562 
	C2952.361816,3435.049805 2950.504639,3432.918213 2948.079346,3432.511230 
	C2939.368896,3431.048828 2931.142822,3427.325684 2921.771973,3428.708740 
	C2916.103760,3429.545410 2909.957764,3428.089355 2904.177002,3426.919678 
	C2899.699219,3426.013428 2895.784912,3425.635742 2891.975342,3428.728271 
	C2887.999512,3431.954834 2883.669678,3432.573975 2879.109619,3429.625000 
	C2875.850098,3427.516846 2872.812256,3428.020264 2870.152588,3430.843750 
	C2866.710205,3434.498291 2862.565186,3434.627930 2858.210693,3433.028564 
	C2852.544434,3430.946777 2846.821289,3429.644043 2840.694092,3429.864990 
	C2836.196289,3430.027344 2832.523682,3427.390625 2828.446289,3424.466797 
	C2823.426025,3415.319092 2814.755615,3414.510742 2806.667969,3413.007324 
	C2784.089600,3408.810791 2761.670654,3404.043457 2740.346191,3395.269287 
	C2735.812500,3393.404297 2727.560059,3393.339355 2729.268311,3386.380859 
	C2731.148193,3378.721191 2739.761230,3380.752686 2744.589355,3382.425049 
	C2764.164551,3389.205566 2785.091797,3390.160400 2805.393311,3397.648438 
	C2798.653564,3391.511963 2791.693604,3388.111816 2783.588135,3387.032959 
	C2776.223633,3386.053467 2769.404297,3383.437500 2763.056396,3379.665771 
	C2758.937988,3377.218750 2755.117920,3374.401367 2757.392090,3367.970703 
	C2761.895020,3365.343750 2765.648438,3367.084229 2769.381348,3368.335938 
	C2797.715332,3377.837891 2826.789795,3384.940674 2855.793945,3394.900146 
	C2863.229736,3396.087646 2869.430664,3399.038818 2876.884766,3400.036621 
	C2892.122803,3407.291260 2906.423096,3414.857666 2922.728027,3417.119629 
	C2929.656494,3418.080811 2935.981201,3421.325684 2942.751221,3422.803711 
	C2946.759766,3423.678711 2951.002197,3425.989258 2955.729004,3422.517090 
z"
        />
        <path
          fill="#9FA2A1"
          opacity="1.000000"
          stroke="none"
          d="
M3015.006104,3397.558838 
	C3013.247559,3393.408203 3010.568604,3390.515137 3006.340820,3389.942383 
	C3001.276367,3389.256104 2997.408936,3385.108398 2991.661133,3385.953125 
	C2988.223389,3386.458252 2983.971436,3377.695801 2984.654785,3372.730225 
	C2984.829102,3371.462891 2985.467285,3370.259521 2985.959961,3368.234375 
	C2987.499023,3367.564453 2988.970459,3367.687012 2991.163818,3367.963379 
	C2992.136475,3371.298340 2988.102539,3375.067871 2991.211914,3377.270020 
	C2994.593750,3379.665039 2998.570312,3381.276123 3001.519531,3384.428223 
	C3001.722656,3384.645020 3002.691895,3384.344727 3003.179443,3384.065918 
	C3007.679932,3381.490967 3008.327881,3381.207031 3009.439453,3383.910156 
	C3010.817871,3387.261719 3013.949219,3388.562012 3015.864502,3391.139160 
	C3017.478027,3393.310547 3019.178711,3395.518066 3015.006104,3397.558838 
z"
        />
        <path
          fill="#9FA2A1"
          opacity="1.000000"
          stroke="none"
          d="
M3022.036621,3384.627441 
	C3021.625732,3386.982666 3021.515137,3390.575928 3019.135742,3388.462891 
	C3016.224365,3385.877686 3012.305908,3383.131104 3012.184082,3377.668213 
	C3015.539795,3377.079834 3017.078857,3383.576660 3021.380859,3379.555664 
	C3022.662109,3378.358398 3021.323730,3382.478760 3022.036621,3384.627441 
z"
        />
        <path
          fill="#9FA2A1"
          opacity="1.000000"
          stroke="none"
          d="
M3004.035400,3373.951172 
	C3004.145264,3375.063477 3005.697021,3376.214355 3004.063965,3377.309326 
	C3003.890381,3377.426025 3003.359131,3377.265381 3003.175293,3377.065918 
	C3002.391846,3376.217285 3002.528320,3375.339355 3003.659180,3374.200195 
	C3004.060059,3373.891846 3003.997559,3373.975342 3004.035400,3373.951172 
z"
        />
        <path
          fill="#434F51"
          opacity="1.000000"
          stroke="none"
          d="
M3023.026611,3399.122314 
	C3022.925049,3399.182373 3023.128174,3399.062256 3023.026611,3399.122314 
z"
        />
        <path
          fill="#131C25"
          opacity="1.000000"
          stroke="none"
          d="
M2972.013184,3429.344482 
	C2968.235107,3428.519531 2962.743896,3429.592285 2962.019043,3422.681641 
	C2965.523193,3421.736084 2967.418213,3425.195068 2971.268066,3425.877686 
	C2971.962646,3426.868652 2971.982666,3427.769043 2972.013184,3429.344482 
z"
        />
        <path
          fill="#23394D"
          opacity="1.000000"
          stroke="none"
          d="
M2869.692871,3031.664551 
	C2871.721436,3039.505371 2873.249268,3041.193848 2879.404297,3041.107666 
	C2886.522949,3041.007568 2893.661621,3042.061768 2900.762207,3040.821533 
	C2902.608398,3040.499268 2904.321289,3041.011963 2904.728516,3042.850586 
	C2905.400879,3045.890137 2902.875244,3045.875000 2900.965576,3045.928467 
	C2896.302490,3046.059326 2891.633545,3045.962402 2886.968018,3046.029541 
	C2884.417725,3046.066406 2881.743408,3045.601318 2878.416016,3047.724365 
	C2883.409668,3051.458496 2887.738525,3054.027588 2893.679688,3054.114502 
	C2900.859863,3054.219238 2907.848389,3055.888184 2915.149414,3053.113525 
	C2918.384521,3051.884521 2923.112793,3053.359863 2926.752197,3054.713379 
	C2931.921631,3056.636719 2937.438965,3055.004395 2942.486328,3057.062744 
	C2943.742188,3057.574707 2945.444580,3057.444092 2946.133545,3056.238770 
	C2949.410400,3050.508057 2954.187988,3052.683838 2958.359131,3054.162842 
	C2964.156006,3056.218262 2971.124023,3055.153320 2975.881348,3061.181152 
	C2973.494873,3061.959961 2970.939941,3062.041016 2968.385010,3062.119141 
	C2965.706543,3062.200928 2964.265137,3063.530518 2964.235596,3067.015625 
	C2958.761475,3067.806152 2953.636230,3066.993896 2948.141357,3065.447754 
	C2942.819336,3063.949707 2936.064453,3063.786133 2929.903320,3064.029785 
	C2924.485107,3064.243896 2924.440674,3064.415039 2927.957031,3071.119385 
	C2930.540771,3077.975830 2938.590088,3073.189453 2941.678223,3079.208008 
	C2943.946777,3086.030518 2949.474854,3085.296143 2954.222900,3085.953613 
	C2962.339355,3087.077881 2970.551270,3087.781006 2977.869629,3093.140137 
	C2969.343262,3095.018311 2960.958252,3092.875000 2952.584473,3091.089355 
	C2949.644043,3090.462646 2946.765625,3089.944824 2942.938965,3089.915527 
	C2941.547607,3087.166016 2939.784180,3085.976074 2937.101318,3086.081543 
	C2930.479004,3086.341309 2923.870605,3085.505371 2917.227051,3087.143311 
	C2910.747559,3088.740967 2904.018555,3088.769287 2897.483154,3086.810791 
	C2889.428711,3084.396729 2881.223877,3083.524658 2872.837646,3083.991211 
	C2868.500000,3084.232422 2864.307861,3083.189209 2860.036865,3082.540283 
	C2847.011719,3080.561279 2833.840332,3079.951416 2820.643066,3079.970459 
	C2809.661377,3079.986328 2798.881836,3078.076172 2788.122070,3076.062500 
	C2785.285400,3075.531738 2783.027344,3074.569824 2782.040527,3070.834961 
	C2775.808105,3062.503906 2770.182861,3062.144287 2762.541016,3069.373047 
	C2761.114014,3069.842773 2759.979004,3070.154297 2759.193848,3069.763184 
	C2749.109131,3064.738525 2738.190918,3063.858398 2726.619873,3062.635986 
	C2727.122314,3056.569336 2731.528809,3056.000488 2735.851074,3056.090820 
	C2743.019531,3056.240234 2750.284180,3054.928223 2757.347168,3057.422363 
	C2759.902588,3058.324951 2762.724609,3058.337891 2764.510010,3055.825195 
	C2766.679199,3052.773193 2763.555664,3051.253418 2762.276611,3049.142578 
	C2759.789307,3045.037842 2756.319580,3043.286133 2750.793457,3044.004883 
	C2748.688721,3041.731201 2748.458740,3039.476074 2750.695312,3036.826660 
	C2761.348877,3034.120850 2768.434814,3039.571045 2775.822754,3046.110352 
	C2776.947021,3047.387451 2777.486084,3048.184570 2778.243164,3049.663574 
	C2781.582520,3055.748779 2785.361816,3058.223877 2791.540039,3052.989502 
	C2795.052246,3051.058838 2798.652588,3051.208252 2802.014160,3048.707520 
	C2805.368408,3047.837402 2807.748291,3048.372803 2810.313965,3050.716064 
	C2811.875977,3053.096191 2811.846191,3055.087891 2811.699707,3057.097412 
	C2811.375000,3061.556152 2812.975342,3062.987305 2817.649658,3061.929443 
	C2831.370605,3058.823975 2844.762451,3060.867676 2857.718018,3065.830566 
	C2870.252197,3070.632324 2882.866943,3074.417725 2897.098145,3072.019043 
	C2898.171143,3072.468750 2898.640625,3072.767578 2899.094727,3073.090576 
	C2899.565674,3073.425781 2899.425537,3073.314941 2898.185059,3072.404297 
	C2886.211670,3068.869385 2874.180420,3068.541504 2862.700439,3065.334717 
	C2860.152344,3064.622803 2857.547852,3064.077393 2855.139404,3062.954346 
	C2852.071777,3061.524170 2848.787354,3059.820801 2849.338135,3055.861328 
	C2849.923340,3051.653076 2853.794922,3051.574707 2857.072021,3051.237793 
	C2859.692871,3050.968750 2862.359619,3051.564209 2865.333008,3050.495117 
	C2865.387695,3047.008301 2862.650391,3046.533691 2860.665283,3046.247314 
	C2848.704346,3044.524414 2839.047119,3037.319824 2827.706055,3032.424316 
	C2826.367676,3029.683105 2826.980957,3027.790283 2828.941895,3025.505371 
	C2833.171875,3023.028809 2836.730713,3023.642822 2840.907227,3025.627441 
	C2851.146484,3035.173096 2852.283691,3035.400879 2862.739502,3029.917480 
	C2865.523193,3029.509521 2867.477295,3029.820068 2869.692871,3031.664551 
z"
        />
        <path
          fill="#838F9A"
          opacity="1.000000"
          stroke="none"
          d="
M74.343246,2658.986816 
	C68.480019,2660.287109 63.178249,2660.616211 56.945889,2659.898438 
	C53.980400,2659.389160 52.045666,2658.601318 49.910400,2656.815430 
	C49.113594,2655.550049 48.982655,2654.718750 49.472294,2653.316895 
	C50.180561,2652.600830 50.526508,2652.418213 51.402714,2651.983154 
	C57.812592,2652.704346 63.723103,2652.269043 69.626053,2652.102295 
	C71.933350,2652.037354 74.287415,2652.005127 75.830864,2648.799805 
	C82.136543,2648.012939 88.225883,2648.183594 94.941299,2646.563721 
	C95.815300,2647.956787 95.912910,2649.324463 96.041328,2651.385742 
	C90.679329,2653.473877 85.286530,2654.868164 79.146744,2656.399170 
	C77.171303,2657.121338 76.079628,2657.932129 74.343246,2658.986816 
z"
        />
        <path
          fill="#9FA2A1"
          opacity="1.000000"
          stroke="none"
          d="
M49.049316,2652.552002 
	C49.883621,2653.535645 49.932632,2654.288086 50.039425,2655.606934 
	C48.899200,2662.416260 43.331718,2660.603271 38.728764,2661.944336 
	C36.515747,2662.112061 35.045376,2662.272949 32.836250,2662.468262 
	C30.665874,2659.630859 29.836697,2656.866943 33.764732,2654.333008 
	C38.710705,2652.382812 43.428211,2651.771240 49.049316,2652.552002 
z"
        />
        <path
          fill="#3F5467"
          opacity="1.000000"
          stroke="none"
          d="
M27.693062,2651.626221 
	C27.158640,2653.317627 26.099043,2653.889893 24.280106,2652.713379 
	C24.719156,2650.895020 25.724369,2650.092529 27.693062,2651.626221 
z"
        />
        <path
          fill="#4C6880"
          opacity="1.000000"
          stroke="none"
          d="
M2994.642578,2681.758789 
	C2995.164062,2677.814697 2997.002686,2676.715332 2999.963867,2681.057861 
	C2998.557617,2681.735596 2996.989014,2681.810303 2994.642578,2681.758789 
z"
        />
        <path
          fill="#6A8297"
          opacity="1.000000"
          stroke="none"
          d="
M73.434181,2307.507568 
	C67.880882,2311.564941 61.512005,2310.136475 54.584526,2308.515137 
	C60.127304,2304.531250 66.505638,2305.816162 73.434181,2307.507568 
z"
        />
        <path
          fill="#3F5467"
          opacity="1.000000"
          stroke="none"
          d="
M31.326353,2664.154053 
	C30.589279,2669.597412 26.702055,2667.983887 22.613455,2666.853271 
	C24.191656,2663.040283 27.569468,2664.283203 31.326353,2664.154053 
z"
        />
        <path
          fill="#808489"
          opacity="1.000000"
          stroke="none"
          d="
M15.052040,3827.085693 
	C14.936181,3827.150635 15.167899,3827.020752 15.052040,3827.085693 
z"
        />
        <path
          fill="#4E585F"
          opacity="1.000000"
          stroke="none"
          d="
M849.913208,1712.835327 
	C849.889282,1707.804077 849.906860,1703.713379 849.834106,1698.740723 
	C849.718079,1688.342773 853.106079,1679.083374 851.853760,1669.470093 
	C851.525085,1666.947021 852.420044,1664.100586 849.064941,1661.920532 
	C848.396484,1660.935425 848.454773,1660.283203 848.526611,1659.137939 
	C849.342834,1656.754517 850.018188,1654.809448 851.110901,1652.369629 
	C851.486938,1647.728394 851.558411,1643.626709 851.577637,1638.862549 
	C850.993103,1637.284424 850.792847,1636.300903 851.245850,1634.550537 
	C851.737366,1629.564209 852.193726,1625.265137 852.012573,1620.993164 
	C851.722778,1614.161377 853.049927,1607.886108 857.561890,1601.660645 
	C858.100037,1601.066528 858.384888,1601.447754 858.605591,1601.536621 
	C872.115784,1608.572632 871.539368,1620.001587 871.593811,1633.200317 
	C871.724670,1664.933716 869.729797,1696.620361 871.009460,1722.882690 
	C870.735535,1701.907837 870.825073,1675.466919 871.394714,1649.046021 
	C871.586426,1640.156372 870.711670,1631.269653 871.778748,1622.300293 
	C873.379395,1608.845825 877.129944,1606.174805 891.262512,1611.629883 
	C896.523315,1614.290771 896.118225,1618.345825 896.095337,1622.394531 
	C895.957886,1646.676270 895.877991,1670.958252 895.832275,1695.240234 
	C895.809082,1707.549561 895.897888,1719.859131 895.881287,1733.107300 
	C898.013428,1742.147339 897.664551,1750.214722 895.341797,1759.041992 
	C895.074829,1764.117676 894.700317,1768.336914 891.421387,1772.497070 
	C890.023865,1774.324219 889.006653,1775.451050 887.284607,1776.916748 
	C881.609070,1779.973511 876.699890,1785.093262 873.237244,1774.797485 
	C871.206177,1768.758179 867.800232,1773.723511 864.770264,1776.391602 
	C863.299194,1777.319336 862.344849,1777.608887 860.570190,1777.763062 
	C856.208496,1777.228394 852.487244,1776.991699 849.861328,1773.046387 
	C849.637207,1763.328125 850.123169,1754.468384 848.667236,1745.447632 
	C847.601685,1738.845947 850.700867,1731.805176 850.076599,1724.762695 
	C849.754089,1721.125366 849.977844,1717.439819 849.913208,1712.835327 
z"
        />
        <path
          fill="#A2B7C8"
          opacity="1.000000"
          stroke="none"
          d="
M1143.285645,1714.756348 
	C1153.584351,1713.185547 1153.584839,1713.185547 1152.298828,1703.715454 
	C1152.254150,1703.386230 1152.150024,1703.061646 1152.136108,1702.732300 
	C1152.023926,1700.070679 1151.738403,1697.340454 1154.222778,1695.437744 
	C1155.431763,1694.511719 1156.822388,1694.616455 1157.886719,1695.508789 
	C1160.253906,1697.493042 1160.458008,1700.265259 1159.352173,1702.801880 
	C1157.392456,1707.296875 1158.492188,1710.773804 1162.590454,1712.917847 
	C1166.409668,1714.915894 1170.745972,1714.813354 1173.529541,1711.247925 
	C1179.666016,1703.387695 1188.904175,1706.500122 1196.599243,1704.457520 
	C1200.608276,1703.393311 1203.219238,1708.143921 1205.618286,1711.318726 
	C1210.841431,1718.231567 1211.050415,1718.646362 1218.242310,1714.732544 
	C1225.536987,1710.762939 1233.189575,1711.950684 1240.693359,1712.154541 
	C1247.982788,1712.352539 1253.753296,1711.420654 1256.427246,1703.473999 
	C1257.518555,1700.230957 1260.672119,1698.423462 1263.809082,1697.128662 
	C1267.839355,1695.465576 1271.184326,1692.771851 1275.325195,1690.123291 
	C1278.023438,1692.127686 1277.366943,1694.513550 1276.420898,1697.578857 
	C1275.045898,1701.313110 1271.616577,1702.934692 1271.084229,1707.080078 
	C1271.136963,1710.684082 1271.111450,1713.583130 1270.954712,1717.230225 
	C1270.955444,1719.501343 1271.117188,1721.021729 1270.965942,1723.319824 
	C1271.016968,1726.176758 1271.308838,1728.256470 1270.982910,1731.123291 
	C1271.064819,1733.991821 1271.104004,1736.077148 1270.936523,1738.938477 
	C1270.991699,1741.778198 1271.241943,1743.838501 1270.976074,1746.671387 
	C1270.871948,1748.969727 1271.014771,1750.503784 1270.811523,1752.797363 
	C1270.545898,1755.609009 1270.025879,1757.579712 1269.053711,1760.228149 
	C1268.470337,1761.940186 1268.012939,1762.861938 1266.894043,1764.291748 
	C1265.511475,1765.467529 1264.587524,1765.928833 1262.845459,1766.434937 
	C1260.592041,1766.866089 1259.075928,1766.911987 1256.816040,1766.841187 
	C1254.626831,1766.799316 1253.180054,1766.711426 1251.042480,1766.490479 
	C1244.909180,1765.383057 1243.634644,1769.033203 1243.168091,1773.121094 
	C1242.695557,1777.261353 1242.695068,1781.450806 1244.150146,1786.226318 
	C1244.804565,1789.480835 1244.759888,1792.034912 1244.344727,1795.387695 
	C1241.792114,1802.525513 1244.343994,1811.002930 1235.441650,1815.247070 
	C1233.090576,1816.163818 1231.435913,1816.310669 1228.931152,1816.223877 
	C1224.437012,1814.800415 1220.563721,1815.041626 1215.926758,1814.577148 
	C1213.969604,1814.148804 1212.927490,1813.675293 1211.323975,1812.428223 
	C1204.794067,1802.310547 1203.949219,1791.872314 1205.014648,1780.187378 
	C1205.828979,1766.669312 1203.608643,1754.052856 1205.052490,1740.584595 
	C1208.600464,1727.043701 1207.761719,1724.674805 1196.235962,1723.722412 
	C1185.435059,1722.829834 1174.596802,1722.343628 1163.814087,1721.063599 
	C1160.920410,1720.720215 1157.814209,1720.613525 1154.667358,1723.381104 
	C1151.643799,1724.572266 1149.337646,1724.689209 1146.142090,1723.827393 
	C1142.218872,1721.543457 1141.644409,1718.785034 1143.285645,1714.756348 
z"
        />
        <path
          fill="#7B7F84"
          opacity="1.000000"
          stroke="none"
          d="
M1143.711670,1713.228516 
	C1143.813354,1716.413452 1143.440063,1718.847900 1145.145996,1721.590576 
	C1146.577148,1722.703979 1147.080078,1723.570557 1147.142944,1725.447266 
	C1145.993530,1729.249756 1144.677856,1732.141235 1141.906250,1735.092773 
	C1137.197021,1737.542603 1132.848877,1737.492554 1128.573242,1737.097046 
	C1101.542969,1734.597534 1074.420532,1733.751831 1047.336182,1732.213623 
	C1042.487793,1731.938232 1038.456909,1734.868652 1033.021973,1733.643066 
	C1022.973389,1719.812500 1025.518066,1705.798828 1030.573364,1691.022949 
	C1035.594971,1687.515137 1033.770996,1679.510376 1038.690186,1678.385498 
	C1044.755981,1676.998291 1043.277588,1685.876831 1047.320923,1687.620850 
	C1053.505737,1690.289185 1060.414185,1690.574829 1066.960205,1689.729370 
	C1077.950928,1688.309814 1088.498291,1690.895996 1099.950562,1691.978271 
	C1101.174683,1692.025757 1101.669312,1692.063110 1102.906250,1692.221069 
	C1110.318115,1693.356934 1117.133179,1692.300781 1124.478394,1694.098633 
	C1127.593140,1695.521606 1130.209473,1695.846924 1133.457397,1696.813110 
	C1140.135986,1697.024658 1142.989502,1699.621338 1142.391235,1705.883301 
	C1142.187866,1708.011353 1143.154175,1710.251221 1143.711670,1713.228516 
z"
        />
        <path
          fill="#A9ACA9"
          opacity="1.000000"
          stroke="none"
          d="
M1034.521240,1689.552490 
	C1028.027710,1703.565796 1028.168091,1717.000244 1033.314087,1731.211914 
	C1034.213135,1738.348389 1034.602905,1744.837646 1034.263916,1752.124512 
	C1035.591431,1764.211670 1034.279663,1775.425293 1032.909424,1787.470703 
	C1032.859131,1790.028809 1032.407715,1791.638184 1031.093750,1793.830811 
	C1028.231689,1796.193726 1025.467651,1796.671631 1023.083374,1795.312378 
	C1013.500793,1789.850464 1002.550293,1791.350952 991.557251,1788.927979 
	C989.059265,1788.879761 987.380859,1788.989502 984.947632,1788.293457 
	C982.608948,1787.249634 981.489807,1786.103638 981.023193,1783.597656 
	C981.754517,1780.591064 983.553101,1779.557983 986.190430,1778.449707 
	C987.825928,1778.082520 988.763184,1778.031860 990.351929,1778.214600 
	C992.573547,1778.737061 994.146362,1779.188110 996.368896,1778.492554 
	C1002.788696,1776.072388 999.276855,1771.050171 1000.360535,1766.639893 
	C1001.577637,1760.000732 1000.956726,1754.040283 1000.881042,1747.221191 
	C1000.866211,1730.118774 998.096741,1713.784424 1003.249023,1697.020996 
	C1006.840332,1692.850586 1011.641357,1693.245361 1015.605042,1690.399780 
	C1020.203430,1687.197632 1018.263184,1682.047607 1021.084961,1678.122803 
	C1023.113403,1676.764526 1025.010986,1676.443115 1026.039673,1677.900391 
	C1028.363281,1681.192261 1028.255981,1677.886230 1029.603516,1676.572021 
	C1030.154053,1676.165894 1031.072144,1676.097412 1031.532227,1676.086426 
	C1036.993652,1679.371338 1036.394165,1683.996582 1034.521240,1689.552490 
z"
        />
        <path
          fill="#646A6C"
          opacity="1.000000"
          stroke="none"
          d="
M892.459717,1760.375366 
	C895.641785,1751.976929 892.866394,1743.352783 895.304810,1734.658081 
	C900.127808,1735.701660 904.664612,1736.852905 903.879517,1743.300293 
	C903.483582,1746.551392 906.269958,1747.612305 909.263916,1747.640991 
	C912.905396,1747.675903 916.546143,1747.781128 921.086731,1747.919189 
	C926.826355,1748.070679 931.669373,1747.877686 937.229126,1748.212280 
	C942.580505,1747.962402 944.035828,1750.457153 944.830444,1754.730103 
	C945.974976,1760.885742 943.786865,1767.334229 947.383179,1773.784912 
	C949.273621,1774.158691 950.407715,1774.403442 952.015076,1775.251343 
	C957.823792,1777.745728 963.115845,1778.901123 969.333374,1775.956177 
	C970.791565,1776.733765 971.145569,1777.761475 970.950562,1779.717529 
	C969.845886,1782.042725 968.535950,1783.077637 966.187378,1784.122803 
	C961.520020,1785.015503 957.615051,1784.982910 952.888489,1785.016113 
	C948.236145,1785.276978 944.418152,1785.417969 939.946777,1784.048584 
	C938.359436,1783.244751 937.631348,1782.584473 936.869751,1781.113281 
	C936.303711,1779.639038 937.009155,1778.284180 934.943848,1778.190674 
	C929.723938,1782.290527 921.471130,1776.701782 917.969238,1785.114502 
	C916.185791,1788.763062 913.863770,1791.100586 910.772339,1793.623047 
	C907.692261,1793.922729 905.209412,1794.375732 902.479126,1792.444092 
	C899.806580,1788.493286 898.546875,1784.811768 898.846191,1779.918701 
	C898.956055,1772.396729 900.140015,1765.281494 892.459717,1760.375366 
z"
        />
        <path
          fill="#626E74"
          opacity="1.000000"
          stroke="none"
          d="
M1003.065796,1696.202148 
	C1003.783386,1712.852295 1003.683472,1729.389038 1003.499023,1746.843018 
	C1000.685608,1753.869507 1002.461121,1760.289429 1002.198853,1767.292480 
	C1002.795105,1770.846802 1003.480896,1773.653564 1003.787659,1776.501221 
	C1004.312134,1781.371460 1001.551880,1781.799072 997.130432,1780.582275 
	C995.411072,1781.056274 994.303467,1781.247192 992.339172,1780.833740 
	C990.780640,1780.342529 990.025391,1780.146851 988.695496,1779.841187 
	C987.078308,1779.146606 986.315125,1778.332764 986.011108,1776.572632 
	C986.511108,1753.888916 987.047058,1731.782715 987.475037,1709.674561 
	C987.698853,1698.110596 987.611023,1698.096680 999.282715,1696.097046 
	C1000.243530,1695.932617 1001.258545,1696.083984 1003.065796,1696.202148 
z"
        />
        <path
          fill="#C4C8C1"
          opacity="1.000000"
          stroke="none"
          d="
M1034.830078,1690.271973 
	C1034.258911,1685.954712 1034.194580,1681.653809 1032.102905,1676.851318 
	C1040.544189,1673.246948 1043.013550,1673.629517 1047.661499,1679.771240 
	C1049.900269,1682.729614 1052.015869,1684.726562 1055.807617,1683.145264 
	C1058.893677,1681.858032 1061.541992,1684.055664 1064.475220,1684.029419 
	C1068.713013,1683.991577 1072.530151,1684.584961 1074.978271,1679.218506 
	C1077.014893,1674.754395 1078.606567,1675.260254 1083.768066,1680.786865 
	C1084.482422,1683.745117 1085.805542,1683.543213 1087.770020,1682.016113 
	C1094.525146,1674.635132 1095.193359,1674.700562 1097.941162,1683.201294 
	C1098.232666,1684.162354 1098.510010,1684.425049 1099.151123,1685.135010 
	C1099.722412,1685.908691 1099.929932,1686.235352 1100.409912,1687.080078 
	C1100.839355,1687.959839 1100.996094,1688.321167 1101.383301,1689.232666 
	C1101.798462,1691.014282 1101.605347,1692.164185 1100.463013,1693.637573 
	C1098.425781,1695.686279 1096.529053,1696.225464 1094.859497,1694.461548 
	C1092.942505,1692.436157 1091.195068,1691.845093 1088.934448,1693.802002 
	C1087.921753,1694.678711 1086.374146,1695.637939 1085.351196,1694.886841 
	C1082.126343,1692.518921 1080.004517,1688.823608 1074.792603,1692.784180 
	C1072.435913,1694.575073 1067.674561,1692.385254 1063.664551,1692.909546 
	C1058.072510,1693.640503 1052.074585,1691.341064 1046.094360,1691.591064 
	C1043.854370,1691.684814 1042.650513,1690.078003 1042.176025,1687.860352 
	C1041.642456,1685.367065 1042.712158,1682.455444 1039.932983,1680.101196 
	C1036.285278,1682.529907 1041.353271,1688.959717 1034.830078,1690.271973 
z"
        />
        <path
          fill="#9FA2A1"
          opacity="1.000000"
          stroke="none"
          d="
M891.931763,1611.362671 
	C888.254395,1612.127686 884.577209,1612.270020 880.955566,1611.887329 
	C875.568054,1611.317749 874.007996,1613.834961 874.002991,1618.817993 
	C873.984619,1637.092041 874.281067,1655.392822 873.403564,1673.632812 
	C872.416565,1694.148071 871.166870,1714.644287 870.177551,1735.215210 
	C870.177551,1729.113403 870.303772,1723.008423 870.149719,1716.910522 
	C869.873840,1705.989014 870.794312,1695.068970 868.719910,1684.143188 
	C867.625610,1678.379639 867.866272,1672.199707 869.172058,1666.299316 
	C869.900635,1663.006958 870.136475,1659.715332 869.865479,1656.398193 
	C868.903381,1644.625000 868.525879,1632.869141 869.597900,1621.066040 
	C870.336731,1612.931152 870.144958,1612.832397 861.909180,1609.452271 
	C858.836670,1608.191162 858.326965,1605.874512 858.625854,1602.342773 
	C862.382507,1602.440430 865.944397,1603.231445 869.493530,1604.075806 
	C874.686768,1605.311035 879.319214,1606.225220 884.109375,1601.684570 
	C887.954590,1598.039429 891.245850,1600.069092 891.935425,1605.519165 
	C892.142517,1607.155762 891.960571,1608.841797 891.931763,1611.362671 
z"
        />
        <path
          fill="#C4C8C1"
          opacity="1.000000"
          stroke="none"
          d="
M1274.929932,1698.956055 
	C1274.781860,1696.032837 1275.309326,1693.728271 1275.918457,1690.711914 
	C1276.943237,1689.367798 1277.886353,1688.735474 1279.415039,1688.030762 
	C1282.388794,1685.592407 1285.207886,1684.172607 1289.303589,1684.015991 
	C1290.887207,1683.994629 1291.779053,1683.994141 1293.339355,1683.982422 
	C1300.546631,1680.853516 1308.713623,1683.610352 1315.300659,1678.122070 
	C1317.468628,1678.010742 1318.967651,1678.031250 1321.213745,1678.105347 
	C1322.544922,1680.924072 1323.274170,1683.668823 1323.671631,1686.460571 
	C1324.065430,1689.226318 1324.758789,1692.258789 1320.447510,1693.893066 
	C1305.403076,1699.070435 1290.879028,1702.306641 1274.929932,1698.956055 
z"
        />
        <path
          fill="#C4C8C1"
          opacity="1.000000"
          stroke="none"
          d="
M1126.465332,1695.419922 
	C1118.458618,1695.554077 1110.974976,1696.691895 1103.001709,1694.360840 
	C1102.093018,1692.129517 1101.983154,1690.395142 1103.727417,1688.472534 
	C1111.364990,1689.595093 1119.109497,1688.182983 1125.829590,1693.309082 
	C1126.365479,1694.379761 1126.708008,1694.702515 1126.465332,1695.419922 
z"
        />
        <path
          fill="#A2B7C8"
          opacity="1.000000"
          stroke="none"
          d="
M1315.510742,1677.513428 
	C1311.268921,1687.048462 1302.528442,1683.394165 1294.662720,1684.261841 
	C1294.354004,1682.280273 1295.005615,1680.269531 1296.849121,1680.399780 
	C1303.253296,1680.852783 1308.923828,1677.776245 1315.510742,1677.513428 
z"
        />
        <path
          fill="#808489"
          opacity="1.000000"
          stroke="none"
          d="
M985.132996,1776.025269 
	C986.661011,1776.873779 987.302063,1777.751587 988.031921,1779.180420 
	C986.493530,1780.426270 984.866272,1781.121338 982.580444,1781.899170 
	C978.208435,1784.991333 974.542236,1785.162598 970.534058,1781.020508 
	C970.120605,1779.261108 970.107971,1778.280029 970.097107,1776.561279 
	C974.814575,1775.900513 979.530273,1775.977539 985.132996,1776.025269 
z"
        />
        <path
          fill="#D7D5CF"
          opacity="1.000000"
          stroke="none"
          d="
M1022.505981,1677.486694 
	C1022.058472,1680.369873 1022.011169,1682.612671 1021.975464,1684.855835 
	C1021.929321,1687.757690 1020.990356,1689.885010 1016.839966,1689.896362 
	C1016.049805,1686.327026 1016.049194,1682.787354 1016.023987,1679.248047 
	C1016.002808,1676.279785 1017.070740,1674.253418 1021.169739,1674.106079 
	C1022.743713,1674.868652 1023.057251,1675.778442 1022.505981,1677.486694 
z"
        />
        <path
          fill="#C4C8C1"
          opacity="1.000000"
          stroke="none"
          d="
M1022.828857,1678.234741 
	C1022.066895,1677.234619 1022.033142,1676.342285 1021.985107,1674.783569 
	C1024.404663,1674.154663 1026.925537,1673.533569 1029.657715,1675.616943 
	C1029.464600,1680.257812 1030.846802,1684.623901 1028.623413,1689.136841 
	C1023.749878,1686.637329 1028.352295,1680.193237 1022.828857,1678.234741 
z"
        />
        <path
          fill="#808489"
          opacity="1.000000"
          stroke="none"
          d="
M937.405396,1747.756836 
	C933.129761,1751.126343 928.114746,1750.156616 922.539673,1748.520630 
	C926.812012,1745.160400 931.832092,1746.107788 937.405396,1747.756836 
z"
        />
        <path
          fill="#A2B7C8"
          opacity="1.000000"
          stroke="none"
          d="
M1289.520752,1683.487549 
	C1287.765381,1686.675293 1284.819824,1687.808838 1280.710449,1687.959595 
	C1280.866943,1682.394531 1284.043457,1681.028320 1289.520752,1683.487549 
z"
        />
        <path
          fill="#7B7F84"
          opacity="1.000000"
          stroke="none"
          d="
M1087.446411,1681.639526 
	C1087.736572,1683.431152 1089.251343,1686.486206 1086.045166,1686.731445 
	C1083.692993,1686.911377 1081.946289,1685.339600 1083.474609,1681.946777 
	C1084.838501,1681.550171 1085.786011,1681.641235 1087.446411,1681.639526 
z"
        />
        <path
          fill="#9DAAB4"
          opacity="1.000000"
          stroke="none"
          d="
M1126.534790,1696.139038 
	C1125.880249,1695.814575 1125.949097,1694.934937 1125.986084,1694.496094 
	C1128.662598,1694.274170 1131.622925,1693.194946 1133.910645,1696.524414 
	C1131.739014,1698.127441 1129.430054,1697.666260 1126.534790,1696.139038 
z"
        />
        <path
          fill="#9DAAB4"
          opacity="1.000000"
          stroke="none"
          d="
M1103.810303,1688.024658 
	C1103.905151,1689.705078 1103.570068,1691.349731 1102.806885,1693.443848 
	C1101.991943,1693.936768 1101.605103,1693.980225 1100.635498,1694.050781 
	C1099.965820,1692.695435 1099.879272,1691.312866 1099.771973,1689.239014 
	C1100.147095,1688.117310 1100.543091,1687.687134 1101.728516,1687.639160 
	C1102.517944,1688.021362 1103.380371,1687.988647 1103.810303,1688.024658 
z"
        />
        <path
          fill="#808489"
          opacity="1.000000"
          stroke="none"
          d="
M952.344116,1774.951416 
	C951.362793,1775.357422 950.147705,1775.270630 948.492188,1774.749512 
	C949.483215,1772.138062 950.821106,1772.610229 952.344116,1774.951416 
z"
        />
        <path
          fill="#7B7F84"
          opacity="1.000000"
          stroke="none"
          d="
M1102.358276,1687.333008 
	C1102.051270,1688.075562 1101.584473,1688.129761 1100.434570,1688.365723 
	C1099.751221,1688.547485 1099.397949,1688.217896 1099.216064,1688.058838 
	C1098.986816,1687.529663 1098.939453,1687.159546 1098.429932,1686.473877 
	C1097.975342,1685.781982 1097.982910,1685.405762 1097.972900,1684.464600 
	C1100.003662,1683.834473 1101.672485,1684.356323 1102.358276,1687.333008 
z"
        />
        <path
          fill="#8C8D89"
          opacity="1.000000"
          stroke="none"
          d="
M850.886719,1634.010010 
	C851.632446,1634.819580 851.697815,1635.787964 851.644287,1637.478271 
	C848.046631,1637.809448 848.567627,1642.206177 845.977356,1642.922363 
	C844.353271,1637.691528 844.353271,1637.691528 850.886719,1634.010010 
z"
        />
        <path
          fill="#8C8D89"
          opacity="1.000000"
          stroke="none"
          d="
M850.772217,1652.071289 
	C851.832581,1654.160767 852.064575,1656.441406 849.134277,1658.338135 
	C848.027649,1656.271729 847.528503,1653.899902 850.772217,1652.071289 
z"
        />
        <path
          fill="#F2EBDA"
          opacity="1.000000"
          stroke="none"
          d="
M1273.764648,1698.745361 
	C1285.713623,1698.215942 1296.978516,1696.543335 1308.208252,1694.371338 
	C1311.374390,1693.758911 1314.725220,1694.101440 1318.846436,1694.021851 
	C1319.692261,1695.439819 1319.682861,1696.842041 1319.631104,1698.945679 
	C1318.850952,1700.560059 1317.988037,1701.333496 1316.188232,1702.281982 
	C1300.145630,1704.604248 1285.173828,1709.323730 1268.878906,1706.404297 
	C1268.148926,1702.277954 1270.529053,1700.577637 1273.764648,1698.745361 
z"
        />
        <path
          fill="#C4C8C1"
          opacity="1.000000"
          stroke="none"
          d="
M1315.410400,1700.781372 
	C1316.789551,1700.079956 1317.595703,1699.981689 1318.995239,1699.765259 
	C1319.746948,1701.267090 1319.905273,1702.887085 1320.148804,1705.314941 
	C1320.059204,1707.649048 1319.884277,1709.175537 1319.536987,1711.450317 
	C1317.986450,1713.392334 1316.412354,1713.533081 1314.032227,1712.597168 
	C1311.087646,1708.165894 1312.061279,1704.649658 1315.410400,1700.781372 
z"
        />
        <path
          fill="#4C565C"
          opacity="1.000000"
          stroke="none"
          d="
M1762.603760,1884.422119 
	C1765.302124,1865.066895 1762.177246,1845.760376 1765.879883,1826.937134 
	C1766.922607,1821.636108 1763.709961,1816.618896 1765.770386,1810.744141 
	C1776.867065,1804.294312 1787.071167,1808.456299 1797.962158,1812.491699 
	C1802.409180,1818.714722 1801.192749,1825.157959 1801.341675,1831.345459 
	C1801.587524,1841.559204 1801.139893,1851.788208 1802.407959,1862.081787 
	C1804.889282,1856.476318 1804.442749,1850.471924 1804.633423,1844.542725 
	C1804.971069,1834.053345 1803.248901,1823.335449 1808.411621,1812.646362 
	C1822.545044,1808.146973 1836.267334,1808.751343 1848.901611,1814.777710 
	C1858.174927,1819.201050 1857.672852,1820.253174 1859.394043,1810.424683 
	C1861.745239,1796.997559 1873.411621,1791.581177 1885.324585,1799.266846 
	C1885.730225,1800.321289 1885.770752,1800.664062 1885.749268,1801.520752 
	C1886.381104,1803.945923 1880.763062,1804.703613 1885.107178,1807.645752 
	C1886.349609,1817.390503 1885.583496,1826.706177 1885.490967,1836.944458 
	C1887.348877,1842.354370 1888.312378,1847.061768 1888.894775,1852.576172 
	C1889.696899,1858.904053 1889.021484,1864.387939 1887.303711,1870.431152 
	C1887.257568,1873.328125 1888.375610,1875.093140 1890.246338,1877.199463 
	C1897.026367,1881.393677 1901.982788,1878.519043 1906.808716,1874.490601 
	C1916.805298,1866.145874 1918.900513,1865.981445 1929.174072,1872.868774 
	C1931.170288,1874.206909 1933.170044,1875.507690 1935.538818,1875.170532 
	C1947.368042,1873.486572 1959.213013,1875.430176 1971.080078,1874.936890 
	C1980.262939,1874.555298 1989.875366,1873.351440 1999.439575,1877.549805 
	C1999.981689,1878.030273 2000.017700,1878.013306 2000.020508,1877.993652 
	C2003.104736,1880.802979 2006.110962,1879.147827 2008.876221,1877.561035 
	C2012.855591,1875.277588 2016.774658,1875.228516 2021.449707,1877.450195 
	C2024.032349,1879.204346 2026.146851,1880.122925 2029.301025,1879.882324 
	C2036.029663,1878.049072 2042.081665,1876.058838 2047.940918,1881.975464 
	C2048.781494,1882.798340 2049.094238,1883.147949 2049.823242,1884.055908 
	C2052.774902,1888.895874 2058.878174,1890.190552 2059.742188,1896.604736 
	C2059.680420,1898.723267 2059.185059,1900.012451 2058.002441,1901.760986 
	C2057.247559,1902.633789 2056.916016,1902.955322 2056.044678,1903.704102 
	C2054.761475,1904.676514 2054.025391,1905.231323 2052.787109,1906.281982 
	C2050.163574,1908.796509 2048.288818,1911.126587 2044.675537,1912.444092 
	C2036.417969,1913.978516 2028.882324,1912.649780 2020.611084,1912.754883 
	C2013.571411,1911.132568 2012.898438,1905.496582 2011.150024,1900.494263 
	C2009.098633,1903.968872 2008.157227,1908.041870 2003.357910,1909.899414 
	C1989.740723,1911.599854 1976.811890,1911.520630 1963.095703,1911.005737 
	C1960.335083,1910.634277 1958.383789,1910.140381 1955.654053,1909.977783 
	C1954.444702,1910.005981 1953.960571,1910.031616 1952.749023,1910.167725 
	C1951.530640,1910.358521 1951.039673,1910.439087 1949.801636,1910.627075 
	C1938.809082,1910.373291 1928.560669,1911.372192 1917.592285,1910.506958 
	C1913.486328,1909.252808 1911.542236,1906.807373 1910.349976,1903.021240 
	C1910.225342,1902.503540 1910.058960,1901.590698 1909.836304,1901.269531 
	C1909.613647,1900.948364 1908.818359,1901.306519 1908.446899,1901.601807 
	C1907.541504,1902.303467 1906.977539,1902.662964 1905.881104,1903.161743 
	C1904.647461,1903.494873 1903.910034,1903.520142 1902.635254,1903.318970 
	C1902.102173,1903.183350 1901.207153,1902.744995 1900.821045,1902.584595 
	C1899.724976,1903.289795 1899.726562,1904.415649 1899.018799,1906.032349 
	C1895.707642,1910.365356 1891.892578,1911.087158 1886.829346,1909.402344 
	C1883.785400,1906.978638 1884.750488,1904.389893 1884.997314,1901.208252 
	C1884.692383,1899.949829 1885.587646,1899.383789 1884.980103,1899.545166 
	C1884.888306,1903.297729 1884.360474,1906.213623 1881.712646,1909.117188 
	C1878.841553,1910.343140 1876.694458,1910.230469 1873.908081,1908.785278 
	C1868.452026,1892.702515 1868.857056,1876.422729 1866.888062,1859.934326 
	C1866.922363,1859.404419 1866.813477,1858.642700 1866.460205,1858.831055 
	C1865.401611,1861.821411 1864.955322,1864.702026 1863.203369,1867.886230 
	C1858.074585,1871.742432 1851.146973,1872.833130 1855.207764,1882.189819 
	C1857.691895,1887.913574 1847.690552,1894.645630 1842.605591,1890.474854 
	C1830.597778,1880.625732 1819.461792,1888.088257 1808.048096,1890.666260 
	C1805.223145,1891.304321 1802.652222,1893.287964 1799.668945,1893.674316 
	C1795.794678,1894.176025 1792.778687,1894.814697 1792.829102,1900.018677 
	C1792.864746,1903.702271 1789.135620,1904.151245 1785.470459,1904.778442 
	C1782.860474,1904.881470 1780.984375,1905.239868 1778.362793,1905.310547 
	C1777.175171,1905.173340 1776.709351,1905.058838 1775.624512,1904.583496 
	C1766.907959,1892.231201 1767.926147,1888.091187 1780.257690,1884.120361 
	C1774.297485,1883.927734 1768.819214,1887.315674 1762.603760,1884.422119 
z"
        />
        <path
          fill="#AAB7BE"
          opacity="1.000000"
          stroke="none"
          d="
M2353.959473,1895.352661 
	C2353.979980,1891.290405 2355.664551,1888.885864 2358.235352,1887.120728 
	C2368.220703,1880.263916 2378.468994,1873.787476 2387.915527,1866.155884 
	C2389.696045,1864.717163 2392.010498,1863.956909 2395.173340,1863.963013 
	C2399.434570,1863.940063 2401.968994,1861.363281 2405.162354,1860.416260 
	C2417.521240,1856.751831 2423.333984,1860.727417 2423.976074,1873.594604 
	C2429.746094,1873.870483 2431.783447,1864.364746 2438.575684,1867.829956 
	C2441.573486,1869.359375 2440.945557,1872.446533 2440.963135,1874.917725 
	C2440.840088,1872.079224 2441.595459,1869.016235 2439.639404,1865.996460 
	C2437.983398,1863.440308 2437.257568,1860.391602 2437.707275,1857.365356 
	C2438.423096,1852.546753 2438.016357,1846.244629 2444.016846,1845.017090 
	C2450.482422,1843.694702 2450.463623,1850.277588 2452.268555,1854.275757 
	C2452.812500,1855.480469 2453.216064,1856.751343 2454.928467,1857.939087 
	C2458.330078,1856.107666 2458.595459,1850.154175 2463.798828,1850.057373 
	C2468.517822,1849.969849 2471.888916,1852.829346 2475.654785,1854.848389 
	C2480.053467,1857.206299 2484.690430,1859.194214 2488.589600,1862.378540 
	C2491.331787,1864.618042 2494.029297,1864.457153 2497.447510,1862.395508 
	C2499.629883,1862.190430 2501.524414,1861.915161 2502.825684,1862.666626 
	C2510.885010,1867.320923 2519.196533,1871.651489 2525.821289,1879.198853 
	C2521.745605,1885.562378 2518.796143,1894.704468 2510.479492,1892.908081 
	C2501.375488,1890.941895 2506.967529,1880.944702 2504.029541,1874.515869 
	C2496.639160,1874.257690 2498.247070,1881.734863 2494.062500,1885.246338 
	C2492.542480,1886.542358 2491.252441,1886.995239 2490.460449,1888.069336 
	C2482.039551,1899.485840 2481.897705,1899.540283 2467.310547,1895.887695 
	C2458.378662,1887.737427 2456.959229,1887.746094 2450.524658,1896.239990 
	C2444.184570,1900.220703 2438.468506,1899.479248 2433.221924,1895.858154 
	C2430.574951,1894.031128 2428.234131,1892.911133 2425.058105,1894.018188 
	C2409.969971,1899.277466 2394.260986,1896.287842 2378.014648,1897.363525 
	C2371.018066,1898.169312 2364.903320,1899.553833 2357.787598,1898.382690 
	C2355.860596,1897.749878 2354.939453,1897.083252 2353.959473,1895.352661 
z"
        />
        <path
          fill="#646A6C"
          opacity="1.000000"
          stroke="none"
          d="
M2375.788086,1895.130615 
	C2384.620605,1892.578613 2392.950928,1894.481445 2401.205322,1894.434082 
	C2408.294922,1894.393433 2415.039307,1893.815796 2421.663574,1891.235352 
	C2426.501465,1889.351074 2431.058838,1888.781860 2435.154541,1893.589722 
	C2438.998535,1898.101807 2444.177490,1896.444580 2449.490723,1894.395264 
	C2454.343018,1890.305664 2459.085693,1890.141724 2464.841309,1892.717773 
	C2469.102539,1894.258911 2472.755371,1893.834351 2476.345703,1894.997314 
	C2481.887451,1896.792236 2484.705322,1895.146118 2486.160889,1888.920288 
	C2487.082764,1884.977539 2489.001465,1884.139404 2493.081299,1884.261597 
	C2498.914307,1890.661255 2497.793213,1896.854004 2491.744385,1901.196167 
	C2486.815918,1904.734497 2484.299805,1909.122681 2482.392090,1915.281250 
	C2481.316650,1917.552124 2480.280273,1918.839233 2478.141113,1920.179688 
	C2474.772461,1919.856934 2473.956543,1917.791138 2473.193848,1914.746094 
	C2472.257568,1909.117554 2468.933594,1907.716431 2464.576172,1907.344727 
	C2456.726074,1906.675415 2448.975098,1905.386475 2440.995850,1906.714478 
	C2428.801025,1908.743896 2416.735596,1906.007202 2403.872803,1904.740112 
	C2395.306641,1902.186401 2392.087158,1905.834351 2391.387695,1913.222412 
	C2390.894043,1918.439819 2390.895752,1923.982422 2384.771729,1927.508179 
	C2379.453369,1929.388428 2374.604248,1929.703979 2369.039062,1930.578369 
	C2362.007568,1930.571777 2358.836670,1926.914551 2357.514404,1921.321533 
	C2356.846436,1918.495728 2356.691650,1915.535034 2354.034912,1912.721924 
	C2341.605225,1907.031738 2329.128418,1906.990845 2315.819824,1907.719971 
	C2308.333008,1904.449585 2304.580566,1906.027222 2304.499512,1914.054443 
	C2304.472900,1916.682007 2303.396484,1919.147339 2301.018066,1921.636841 
	C2298.528320,1923.128174 2296.685547,1922.617432 2294.273926,1921.359131 
	C2291.262695,1918.298706 2292.002686,1910.103882 2284.569092,1916.151001 
	C2282.830566,1917.565186 2281.517334,1912.704102 2278.034424,1913.436768 
	C2262.614502,1909.070068 2246.865967,1911.656128 2231.413330,1906.762695 
	C2227.048828,1900.709961 2225.308594,1895.285156 2232.409912,1889.950439 
	C2237.340576,1888.749268 2241.469482,1888.991089 2246.435059,1889.321533 
	C2254.397461,1892.130493 2261.996582,1892.221558 2270.091309,1893.717285 
	C2277.040283,1896.761353 2283.281006,1899.173096 2290.172852,1893.865967 
	C2292.334717,1891.710083 2294.431885,1890.616699 2296.949707,1888.892456 
	C2299.870117,1887.640869 2302.205566,1887.240479 2305.340820,1888.052490 
	C2309.548828,1888.691650 2313.054932,1889.172852 2317.315430,1889.547974 
	C2319.990234,1889.133789 2321.772705,1889.541748 2324.006348,1890.874023 
	C2326.500977,1893.612915 2327.495117,1898.166504 2332.681152,1894.192505 
	C2337.796631,1892.865601 2342.234619,1891.475098 2347.442383,1893.620483 
	C2349.461670,1894.213379 2350.889404,1894.303101 2353.025635,1894.456177 
	C2354.023682,1895.099731 2354.420410,1895.594116 2355.324707,1896.266602 
	C2362.394775,1899.369019 2368.833740,1898.264893 2375.788086,1895.130615 
z"
        />
        <path
          fill="#6B6164"
          opacity="1.000000"
          stroke="none"
          d="
M1798.225952,1813.767578 
	C1787.857666,1811.529175 1777.827271,1810.811035 1766.939209,1810.063477 
	C1763.365967,1802.405884 1761.928345,1794.775513 1767.376465,1786.810547 
	C1773.316895,1786.170654 1778.791992,1786.101685 1785.162354,1785.877563 
	C1786.859497,1785.165527 1787.746216,1784.934814 1789.331787,1785.389160 
	C1792.111328,1785.864136 1794.276733,1785.980347 1797.247192,1785.958862 
	C1802.286011,1784.574463 1806.478271,1784.375122 1811.286255,1786.706665 
	C1814.607178,1787.393188 1817.239258,1788.033691 1819.844604,1787.938354 
	C1839.400024,1787.222168 1858.779297,1790.736816 1878.334839,1790.227051 
	C1885.189697,1790.048340 1885.201294,1790.492065 1885.833252,1799.133789 
	C1882.425293,1797.871948 1878.760986,1797.843628 1875.104126,1798.096436 
	C1865.611694,1798.752441 1860.786255,1805.837158 1862.557861,1816.571899 
	C1862.965942,1819.044556 1865.177734,1821.980713 1861.521362,1823.374390 
	C1858.434082,1824.551025 1853.808472,1825.221680 1852.225220,1822.326538 
	C1846.413452,1811.699829 1836.702271,1813.975952 1827.724121,1814.082153 
	C1822.334961,1814.145874 1817.435669,1811.253296 1811.182861,1812.129761 
	C1805.937378,1809.796997 1808.419067,1803.959473 1804.278198,1800.673828 
	C1799.627197,1803.714722 1802.038574,1809.945068 1798.225952,1813.767578 
z"
        />
        <path
          fill="#9FA2A1"
          opacity="1.000000"
          stroke="none"
          d="
M2632.991943,1830.880737 
	C2643.172363,1830.757202 2652.173096,1827.223999 2661.538086,1827.648315 
	C2664.063965,1827.762817 2666.428223,1825.853516 2668.535156,1824.108887 
	C2674.433594,1819.224487 2680.153076,1820.051514 2684.360840,1826.526489 
	C2687.467773,1831.307739 2691.204590,1834.936401 2697.324951,1833.929199 
	C2704.480957,1832.751221 2709.364990,1835.441040 2713.281250,1841.866943 
	C2715.148926,1844.343994 2714.327393,1846.395630 2712.539307,1849.016113 
	C2705.961914,1853.771606 2699.381104,1853.654663 2691.896484,1851.968506 
	C2689.860596,1850.102295 2688.971191,1849.614624 2687.958496,1850.729736 
	C2684.673096,1852.928467 2681.627197,1852.996460 2677.819336,1852.890137 
	C2671.132080,1852.948120 2665.458008,1848.654541 2658.663086,1851.975342 
	C2656.447266,1852.719849 2654.895996,1853.333984 2653.283691,1853.588867 
	C2646.660889,1854.635010 2643.958984,1855.863159 2649.571777,1862.976807 
	C2656.901123,1872.265869 2654.371582,1879.661987 2643.178467,1884.633179 
	C2638.710205,1886.617676 2638.902344,1894.398438 2631.557129,1892.265625 
	C2627.239990,1883.274292 2629.453369,1874.336426 2629.339355,1865.567017 
	C2629.292969,1861.987183 2630.530273,1858.416382 2629.156738,1854.238647 
	C2628.321777,1853.380249 2627.948242,1853.074829 2627.001465,1852.323486 
	C2626.103760,1851.517944 2625.779541,1851.158081 2625.121338,1850.153687 
	C2624.549561,1848.583496 2624.448242,1847.644653 2624.666504,1846.017334 
	C2627.232178,1840.839478 2631.830566,1837.526733 2632.991943,1830.880737 
z"
        />
        <path
          fill="#B6BCC1"
          opacity="1.000000"
          stroke="none"
          d="
M2497.472168,1861.582764 
	C2497.358887,1863.847534 2496.981934,1865.866699 2495.997559,1867.525146 
	C2494.179199,1870.588867 2491.198975,1870.549072 2489.524170,1867.937988 
	C2484.119385,1859.511597 2473.572021,1859.173828 2466.749023,1852.921997 
	C2464.518066,1850.878052 2460.907471,1851.660767 2460.133301,1855.896729 
	C2459.837402,1857.516479 2459.739990,1859.202637 2459.210938,1860.741089 
	C2458.642334,1862.393799 2457.260010,1863.404541 2455.464600,1863.575684 
	C2453.212158,1863.790161 2451.419678,1862.731567 2450.771973,1860.696167 
	C2449.571289,1856.923218 2448.745850,1853.030762 2447.770264,1849.186646 
	C2447.418701,1847.801025 2446.665039,1846.696167 2445.173096,1846.625977 
	C2443.732422,1846.557983 2442.567871,1847.363647 2442.369385,1848.843994 
	C2441.658447,1854.139160 2438.093018,1858.993286 2442.403564,1864.885986 
	C2445.630859,1869.298218 2441.081055,1874.676514 2440.752441,1879.801636 
	C2440.505859,1878.897827 2440.119873,1878.006348 2440.038330,1877.087769 
	C2439.780518,1874.178345 2440.629395,1870.516113 2437.175049,1869.252075 
	C2433.805664,1868.019287 2433.072998,1871.925049 2431.416016,1873.669067 
	C2427.316650,1877.984375 2422.839600,1879.180786 2417.534424,1876.999878 
	C2416.864014,1874.306396 2419.079346,1873.934937 2419.905762,1872.662109 
	C2423.245361,1867.518311 2421.042236,1862.717285 2414.964111,1862.091431 
	C2411.311279,1861.715332 2407.711914,1862.008789 2404.315430,1863.809814 
	C2402.042725,1865.015015 2399.704102,1866.730591 2396.406738,1864.464355 
	C2398.834961,1858.485596 2404.854004,1856.782227 2409.574707,1852.494629 
	C2411.082764,1850.952393 2412.130127,1849.871338 2413.611328,1848.427002 
	C2414.012695,1848.030640 2414.981201,1847.978882 2415.465820,1847.960205 
	C2417.053223,1846.917358 2418.135254,1845.871216 2419.608154,1844.417847 
	C2432.212891,1837.346069 2441.392822,1828.100586 2444.951416,1811.916260 
	C2445.619629,1815.182495 2446.045654,1816.100098 2445.946289,1816.956787 
	C2444.693115,1827.762085 2452.165283,1832.729614 2459.735107,1837.591553 
	C2460.820557,1838.288818 2462.540771,1838.178345 2463.399414,1839.020630 
	C2472.263428,1847.715698 2483.750000,1852.171143 2494.174561,1858.340210 
	C2495.270020,1858.988403 2496.022461,1860.215942 2497.472168,1861.582764 
z"
        />
        <path
          fill="#D0D1C8"
          opacity="1.000000"
          stroke="none"
          d="
M2555.331543,1874.638672 
	C2558.925781,1861.381836 2558.238770,1848.456177 2559.873291,1835.772827 
	C2560.278320,1832.628052 2559.218750,1828.864746 2564.614014,1827.770996 
	C2574.017334,1827.339233 2582.667725,1826.325317 2591.841797,1829.174561 
	C2593.034180,1830.732788 2593.276611,1831.821899 2593.033936,1833.808838 
	C2588.512207,1841.042236 2588.955322,1848.313232 2588.902100,1855.544189 
	C2588.827148,1865.721558 2589.283691,1875.905762 2588.397949,1886.826050 
	C2580.812988,1892.643677 2568.359375,1889.011230 2565.212646,1880.930298 
	C2564.851562,1880.002319 2563.802490,1879.598877 2562.211914,1880.104736 
	C2559.845459,1884.259888 2561.977295,1889.404785 2556.864014,1892.200684 
	C2551.601807,1886.788452 2552.317627,1881.142090 2555.331543,1874.638672 
z"
        />
        <path
          fill="#8DA0AE"
          opacity="1.000000"
          stroke="none"
          d="
M2177.231934,1861.708374 
	C2182.034424,1862.975220 2184.920410,1866.877197 2189.680420,1866.439941 
	C2191.712158,1866.253296 2192.752686,1868.557495 2194.092773,1870.021851 
	C2198.768311,1875.131592 2202.301514,1875.399536 2207.616943,1871.277710 
	C2208.916260,1870.270142 2209.923828,1868.866211 2211.269043,1867.940186 
	C2217.332520,1863.766968 2224.697998,1865.720093 2227.747803,1872.336914 
	C2229.263428,1875.625000 2230.500977,1879.041260 2231.978027,1883.118408 
	C2225.480225,1888.070190 2227.654297,1898.257690 2219.250488,1902.016968 
	C2213.448975,1903.461792 2208.286865,1903.354004 2202.361572,1902.983887 
	C2201.118896,1902.952515 2200.628174,1902.945923 2199.413574,1902.932739 
	C2192.731934,1901.613892 2182.827148,1906.323853 2185.508789,1892.543945 
	C2186.404785,1887.939819 2181.534912,1891.611938 2178.642822,1892.560303 
	C2174.074463,1893.668823 2170.947754,1890.463989 2172.499023,1888.222290 
	C2177.058838,1881.633301 2170.201660,1875.438110 2173.076904,1868.585449 
	C2173.188232,1866.315796 2173.595703,1864.868408 2174.711670,1862.847412 
	C2175.611816,1861.938843 2176.060791,1861.670532 2177.231934,1861.708374 
z"
        />
        <path
          fill="#959693"
          opacity="1.000000"
          stroke="none"
          d="
M1999.853516,1877.319946 
	C1992.291016,1875.977417 1984.253296,1878.569092 1976.537109,1876.259033 
	C1975.129150,1875.837402 1973.258423,1876.511719 1971.725342,1877.040039 
	C1966.333130,1878.898560 1961.411987,1878.878784 1955.528564,1877.452026 
	C1949.152954,1875.905762 1941.838013,1877.622681 1935.045776,1878.658447 
	C1931.605103,1879.183228 1929.539307,1878.611694 1927.373779,1875.970337 
	C1921.171387,1868.404785 1916.636475,1868.075928 1909.914429,1875.069214 
	C1907.582275,1877.495728 1905.026367,1879.169678 1902.320190,1881.010010 
	C1896.856323,1884.725830 1892.572876,1883.533325 1888.195679,1878.671143 
	C1889.657593,1871.935791 1890.348511,1864.619995 1900.765747,1869.691406 
	C1896.621826,1862.468994 1892.081909,1856.433472 1888.518311,1849.820923 
	C1885.868408,1844.903931 1886.947388,1842.278931 1893.396240,1842.158936 
	C1903.517456,1842.128418 1906.281006,1850.524170 1911.693481,1856.407715 
	C1911.700317,1861.794189 1915.138062,1860.792358 1918.007446,1860.908813 
	C1928.431030,1861.331787 1938.910278,1860.146729 1949.980957,1862.022949 
	C1953.969238,1862.076782 1957.260864,1862.113770 1960.552368,1862.093384 
	C1964.229614,1862.070801 1967.150635,1863.240479 1967.746948,1867.269287 
	C1968.302856,1871.025879 1970.566650,1872.021118 1973.950684,1872.043579 
	C1979.403931,1872.079834 1984.544434,1874.781250 1990.407837,1872.687744 
	C1993.379028,1871.626709 1997.511597,1873.135620 1999.853516,1877.319946 
z"
        />
        <path
          fill="#626E74"
          opacity="1.000000"
          stroke="none"
          d="
M2761.184570,1832.037598 
	C2766.575684,1834.242432 2768.666260,1837.446777 2768.125000,1842.850952 
	C2767.540527,1848.688110 2768.026123,1854.631470 2768.029053,1860.528564 
	C2768.030762,1864.158081 2769.847656,1865.919067 2773.453857,1865.925537 
	C2779.023438,1865.935669 2784.592773,1865.966309 2791.088867,1866.001465 
	C2793.937988,1867.843994 2795.744629,1869.732056 2795.182129,1873.459473 
	C2793.062988,1877.216064 2790.400879,1878.161621 2786.363525,1876.760986 
	C2784.649658,1876.409790 2784.665771,1873.315796 2782.262695,1875.855225 
	C2773.198242,1879.969727 2764.076904,1879.273315 2755.227295,1880.249390 
	C2751.737061,1880.634277 2748.746094,1879.079102 2746.915527,1874.939453 
	C2746.673340,1871.982178 2748.544189,1869.084229 2744.340088,1868.404907 
	C2741.407471,1867.931152 2738.864746,1866.638550 2738.505371,1862.447266 
	C2738.728027,1861.300659 2738.901855,1860.859985 2739.573242,1859.907471 
	C2741.423584,1858.829224 2742.832031,1858.821167 2744.954590,1858.977661 
	C2747.799316,1858.592529 2750.108887,1859.695679 2752.250244,1858.259155 
	C2750.253174,1853.507202 2748.080078,1849.597290 2741.628174,1851.787109 
	C2738.411865,1852.878540 2735.197266,1851.803345 2732.534180,1848.337646 
	C2732.318359,1846.505737 2732.342285,1844.140381 2732.786133,1844.058960 
	C2742.936768,1842.193726 2748.170166,1829.071289 2761.184570,1832.037598 
z"
        />
        <path
          fill="#D0D1C8"
          opacity="1.000000"
          stroke="none"
          d="
M2048.669434,1886.521973 
	C2047.978760,1886.035278 2047.551147,1885.351807 2047.373779,1884.989746 
	C2046.503540,1882.193604 2044.566040,1880.128662 2045.437866,1876.688477 
	C2045.909668,1872.796753 2046.369507,1869.517090 2045.971924,1866.344727 
	C2045.022095,1858.768433 2046.872070,1853.134277 2055.498047,1850.545898 
	C2056.780762,1849.153809 2057.671143,1848.434814 2059.415039,1848.116455 
	C2063.412109,1847.980835 2066.831543,1847.877441 2071.105469,1847.824951 
	C2076.133789,1847.966797 2079.489990,1849.173218 2080.312012,1854.746338 
	C2078.909668,1864.322144 2084.700195,1873.183594 2079.716797,1882.660645 
	C2076.681641,1887.824707 2072.427490,1889.277588 2067.673828,1888.843994 
	C2061.567383,1888.286987 2055.363281,1888.595093 2048.669434,1886.521973 
z"
        />
        <path
          fill="#959693"
          opacity="1.000000"
          stroke="none"
          d="
M2503.406738,1870.690186 
	C2506.927002,1870.468506 2507.931396,1872.301880 2507.927246,1874.960938 
	C2507.922119,1878.289185 2507.848633,1881.621338 2507.986572,1884.944214 
	C2508.091064,1887.454468 2507.188721,1890.775391 2510.837891,1891.467651 
	C2513.918457,1892.052246 2515.564697,1889.510254 2517.009521,1887.259766 
	C2517.715332,1886.160645 2517.905762,1884.728271 2518.622070,1883.639160 
	C2519.978516,1881.576660 2522.208008,1880.781982 2525.189453,1880.028320 
	C2530.800781,1880.147095 2535.338623,1880.363281 2538.204102,1874.762085 
	C2540.700439,1869.881592 2545.011475,1873.374268 2549.328125,1873.903076 
	C2553.700439,1878.035645 2553.320557,1882.753296 2551.988770,1888.364990 
	C2545.421875,1895.203857 2538.025146,1899.856323 2529.497803,1900.511719 
	C2521.410156,1901.133301 2516.894531,1904.392578 2514.309570,1912.463623 
	C2508.915527,1914.636963 2505.710693,1912.563232 2504.304688,1908.260376 
	C2500.339111,1896.126343 2498.582520,1883.807495 2503.406738,1870.690186 
z"
        />
        <path
          fill="#A9ACA9"
          opacity="1.000000"
          stroke="none"
          d="
M2174.436035,1866.557617 
	C2175.186768,1868.800293 2175.574219,1870.759888 2175.351562,1872.647461 
	C2175.069824,1875.037109 2172.209961,1877.961792 2177.599121,1878.439087 
	C2178.388916,1878.508911 2177.876465,1882.040405 2176.464844,1883.616577 
	C2174.021973,1886.344482 2172.551514,1889.023560 2177.233887,1891.818970 
	C2181.051025,1896.713379 2178.275146,1899.095703 2174.244385,1900.691895 
	C2170.025635,1902.362671 2165.567383,1903.038574 2160.149658,1902.916138 
	C2158.137207,1902.704346 2157.016357,1902.477783 2155.180908,1901.770752 
	C2154.037109,1901.064697 2153.631348,1900.721802 2152.794434,1899.734619 
	C2151.062256,1894.118408 2149.613037,1889.201904 2146.989258,1884.156616 
	C2147.009033,1883.287476 2147.395996,1883.079712 2146.791748,1883.045166 
	C2144.746094,1884.296753 2143.005127,1884.388550 2140.477783,1883.996826 
	C2137.072510,1882.664673 2135.642822,1880.621094 2135.500732,1876.976562 
	C2139.100830,1873.938965 2143.241699,1873.919434 2147.004639,1872.680664 
	C2152.676270,1870.813843 2158.457031,1869.453369 2159.443604,1862.101929 
	C2159.533936,1861.427002 2160.932861,1860.927612 2162.380371,1860.171387 
	C2169.353760,1862.647705 2164.754883,1867.962158 2165.928223,1871.868408 
	C2165.657959,1873.172241 2168.109863,1871.865967 2166.797363,1871.630981 
	C2166.602539,1871.595947 2166.526611,1871.554932 2166.521973,1871.512695 
	C2165.832275,1865.449585 2170.090332,1866.223511 2174.436035,1866.557617 
z"
        />
        <path
          fill="#79868C"
          opacity="1.000000"
          stroke="none"
          d="
M2135.202637,1875.534546 
	C2136.756348,1877.917969 2137.847656,1879.642944 2139.448730,1881.705688 
	C2143.360352,1887.034912 2140.422363,1889.786377 2136.344727,1892.022461 
	C2133.771973,1893.433105 2130.807373,1893.797729 2127.297852,1895.062988 
	C2118.247314,1895.258057 2117.060547,1887.359741 2112.407715,1882.154541 
	C2110.003418,1874.216675 2110.662354,1867.144775 2113.190918,1859.350342 
	C2114.211426,1855.958374 2114.954590,1853.328979 2117.209229,1850.554932 
	C2126.769043,1850.776855 2133.793701,1857.767944 2134.070068,1866.167847 
	C2134.165771,1869.079346 2133.656250,1872.039795 2135.202637,1875.534546 
z"
        />
        <path
          fill="#9DAAB4"
          opacity="1.000000"
          stroke="none"
          d="
M2305.890381,1887.475098 
	C2304.368652,1890.519531 2301.861328,1890.129883 2298.778809,1890.252563 
	C2281.874023,1890.160767 2265.735596,1891.109375 2248.790527,1892.206909 
	C2243.946289,1892.197144 2239.910889,1892.089478 2235.014160,1891.901367 
	C2230.616699,1884.717896 2236.062744,1884.806885 2241.222168,1884.008423 
	C2244.187988,1883.999146 2246.336426,1884.005981 2249.281494,1883.971191 
	C2255.919678,1882.041504 2262.410400,1882.391113 2267.772949,1878.617920 
	C2268.856934,1877.855347 2271.118164,1878.041748 2272.522949,1878.592285 
	C2277.309326,1880.467896 2282.249512,1880.051392 2287.144287,1879.951904 
	C2294.277344,1879.806763 2300.185547,1882.713379 2305.890381,1887.475098 
z"
        />
        <path
          fill="#F0EBDD"
          opacity="1.000000"
          stroke="none"
          d="
M2592.684570,1830.532959 
	C2583.501465,1829.661133 2574.878906,1832.712036 2565.987793,1828.217773 
	C2566.284912,1823.961426 2569.346924,1823.793335 2572.322021,1823.192139 
	C2582.536865,1821.127686 2592.878662,1821.827271 2603.068604,1822.529541 
	C2610.656494,1823.052246 2618.239258,1823.730957 2625.854492,1823.963989 
	C2629.161865,1824.065063 2632.738770,1823.851074 2633.723389,1829.100830 
	C2633.813721,1829.874390 2633.710938,1829.736816 2633.627441,1829.756592 
	C2630.901123,1832.766479 2627.434326,1833.276367 2622.829102,1833.031494 
	C2618.377686,1833.337402 2614.817383,1833.713745 2610.351074,1833.038696 
	C2604.057861,1832.731201 2598.404785,1834.681274 2592.684570,1830.532959 
z"
        />
        <path
          fill="#B6BCC1"
          opacity="1.000000"
          stroke="none"
          d="
M2711.703125,1848.028809 
	C2712.376709,1845.920532 2712.938477,1844.495117 2713.748047,1842.544678 
	C2720.055420,1839.702515 2716.461182,1833.043823 2719.655273,1828.880127 
	C2723.032959,1831.162354 2721.845703,1834.376831 2721.939453,1837.022339 
	C2722.154785,1843.119751 2724.686279,1847.512573 2731.154785,1849.931396 
	C2738.675781,1851.323242 2738.216553,1855.207764 2734.774902,1860.498535 
	C2728.215576,1864.254517 2721.595459,1862.787720 2714.562988,1863.782227 
	C2709.594238,1862.955811 2705.226074,1862.590210 2701.367432,1859.164551 
	C2701.082031,1851.489868 2709.459961,1853.448242 2711.703125,1848.028809 
z"
        />
        <path
          fill="#BAB4A7"
          opacity="1.000000"
          stroke="none"
          d="
M1893.909668,1841.531006 
	C1891.286865,1844.203979 1886.420776,1844.115479 1891.867432,1850.011230 
	C1897.030273,1855.599976 1900.035278,1863.414307 1905.095703,1869.551270 
	C1906.179932,1870.866333 1906.366577,1872.766113 1905.233276,1874.180420 
	C1903.467529,1876.383667 1901.702637,1874.669434 1900.032471,1873.789551 
	C1897.972168,1872.703979 1897.575073,1869.762939 1895.015381,1869.213989 
	C1890.592529,1870.325195 1893.658325,1876.451538 1888.697144,1877.810059 
	C1885.863892,1877.224731 1884.361206,1875.895630 1884.293213,1872.784912 
	C1887.499268,1866.764038 1890.141113,1861.300415 1886.594482,1854.431030 
	C1884.908936,1849.052246 1883.571899,1844.192993 1885.105469,1838.478882 
	C1888.388672,1838.385742 1892.012573,1836.882446 1893.909668,1841.531006 
z"
        />
        <path
          fill="#E7E3DA"
          opacity="1.000000"
          stroke="none"
          d="
M2117.165527,1849.790527 
	C2117.981445,1852.404663 2118.242920,1854.794312 2116.400391,1857.454834 
	C2107.870850,1862.164551 2099.516113,1864.973145 2089.504395,1863.778809 
	C2087.768311,1862.787598 2087.102539,1861.918213 2086.628906,1859.999023 
	C2086.605469,1857.526733 2087.198486,1856.047119 2088.562012,1854.072510 
	C2092.237793,1851.317871 2095.310059,1848.958496 2099.520752,1847.789429 
	C2105.927490,1844.779297 2111.260742,1846.612671 2117.165527,1849.790527 
z"
        />
        <path
          fill="#9DAAB4"
          opacity="1.000000"
          stroke="none"
          d="
M2324.470215,1892.610107 
	C2322.164551,1891.790771 2320.252197,1891.965332 2318.416992,1890.040039 
	C2318.049561,1884.817261 2319.169678,1881.735229 2324.948730,1883.252319 
	C2327.720947,1883.980225 2330.795166,1884.256104 2333.630615,1883.906860 
	C2340.853027,1883.016724 2345.516113,1885.777588 2347.934082,1893.373047 
	C2343.525146,1895.846558 2338.844238,1896.592773 2333.232422,1896.692871 
	C2328.933594,1897.635010 2326.912842,1895.487183 2324.470215,1892.610107 
z"
        />
        <path
          fill="#9FA2A1"
          opacity="1.000000"
          stroke="none"
          d="
M2734.566406,1860.407715 
	C2734.077393,1856.827881 2737.488037,1853.347778 2732.306396,1850.463135 
	C2731.924805,1849.608398 2731.953613,1849.248047 2732.040527,1848.347412 
	C2736.756836,1849.449219 2741.153809,1851.523438 2745.709229,1846.801270 
	C2747.483887,1844.961548 2751.448242,1845.619995 2751.676270,1849.143799 
	C2751.960449,1853.531372 2753.530273,1855.752441 2757.872803,1856.349243 
	C2759.239502,1856.537109 2759.788818,1858.052612 2759.463867,1859.487427 
	C2759.141113,1860.911011 2758.095459,1861.807739 2756.689209,1861.872803 
	C2753.071045,1862.040649 2749.445557,1862.045898 2744.944824,1862.042480 
	C2743.209229,1860.955444 2742.592041,1859.675415 2740.538086,1859.621338 
	C2739.939941,1859.916748 2740.000000,1859.990112 2739.966797,1859.961060 
	C2738.567627,1861.214844 2736.987061,1861.494141 2734.566406,1860.407715 
z"
        />
        <path
          fill="#F0EBDD"
          opacity="1.000000"
          stroke="none"
          d="
M1950.119385,1861.423584 
	C1948.772095,1864.290649 1946.206909,1864.555786 1943.497070,1864.631836 
	C1933.317017,1864.917358 1923.161377,1863.301636 1912.967041,1864.282227 
	C1911.720825,1864.401978 1910.338745,1863.976562 1909.139160,1863.502075 
	C1907.536499,1862.868286 1906.279297,1861.665161 1906.636963,1859.757935 
	C1907.003906,1857.802368 1908.796875,1857.545776 1911.166260,1857.081543 
	C1914.330078,1857.286987 1916.723999,1857.593872 1920.024658,1857.931152 
	C1920.931274,1857.961548 1921.027588,1858.771118 1921.070312,1858.365723 
	C1927.655273,1857.877197 1934.197510,1857.792236 1940.739624,1857.711670 
	C1944.049194,1857.670898 1947.357178,1857.642700 1950.119385,1861.423584 
z"
        />
        <path
          fill="#8497A7"
          opacity="1.000000"
          stroke="none"
          d="
M2100.092773,1847.297119 
	C2097.991699,1851.127808 2096.751465,1855.388794 2090.885986,1854.282959 
	C2086.140381,1854.787598 2085.477051,1851.680786 2084.301270,1848.274414 
	C2084.247070,1837.649536 2084.940186,1836.470459 2090.947998,1836.044434 
	C2097.870361,1835.553589 2099.337158,1837.232544 2100.092773,1847.297119 
z"
        />
        <path
          fill="#8C8D89"
          opacity="1.000000"
          stroke="none"
          d="
M2244.487793,1885.414185 
	C2240.558838,1886.966064 2235.169434,1883.527222 2234.160645,1891.063477 
	C2228.958984,1895.883301 2228.958984,1895.883301 2232.521729,1905.564209 
	C2233.679199,1907.668335 2233.785889,1909.130371 2233.062988,1911.326660 
	C2226.240723,1915.673706 2226.293945,1907.760864 2222.105957,1905.887939 
	C2220.448486,1904.334595 2219.756348,1903.043701 2219.714844,1900.804199 
	C2221.204590,1898.021484 2223.766846,1897.087646 2223.098877,1893.452393 
	C2222.140869,1888.237183 2224.763916,1884.505249 2231.347656,1883.916504 
	C2232.935791,1883.841919 2233.779785,1883.846558 2235.257568,1883.821045 
	C2236.791504,1883.071167 2237.705322,1882.376099 2239.446533,1882.109863 
	C2240.877930,1882.089233 2241.734375,1882.093872 2243.233154,1882.100098 
	C2244.697998,1882.809814 2245.038574,1883.702026 2244.487793,1885.414185 
z"
        />
        <path
          fill="#F0EBDD"
          opacity="1.000000"
          stroke="none"
          d="
M2175.253662,1866.284180 
	C2172.796631,1869.103149 2166.110596,1866.067017 2167.656250,1873.153442 
	C2167.796875,1873.797852 2165.001709,1873.317017 2164.800537,1872.290649 
	C2164.114014,1868.784302 2163.879150,1865.189453 2163.254150,1860.812256 
	C2162.894531,1854.121582 2168.980713,1851.017456 2169.568359,1845.109253 
	C2171.046387,1847.851929 2170.652100,1851.288330 2173.884766,1853.492065 
	C2175.949951,1855.895508 2176.028320,1858.216431 2175.929199,1861.276367 
	C2175.749756,1863.215210 2175.593506,1864.455444 2175.253662,1866.284180 
z"
        />
        <path
          fill="#96B6D1"
          opacity="1.000000"
          stroke="none"
          d="
M1812.271973,1786.436401 
	C1807.710571,1787.969604 1803.449219,1788.064819 1798.633057,1786.330078 
	C1798.193481,1783.491333 1798.084839,1780.520874 1801.110474,1780.378418 
	C1810.219604,1779.949585 1819.383789,1779.223877 1830.130859,1781.106201 
	C1824.037720,1786.430908 1817.070190,1781.508545 1812.271973,1786.436401 
z"
        />
        <path
          fill="#A9ACA9"
          opacity="1.000000"
          stroke="none"
          d="
M2548.982666,1891.396362 
	C2549.658447,1885.662964 2549.853516,1880.739502 2550.064941,1874.895264 
	C2551.531738,1873.965088 2552.982422,1873.955444 2555.156250,1873.930420 
	C2556.519043,1879.544678 2555.114258,1885.303833 2557.511230,1891.441772 
	C2561.319580,1892.179199 2561.654541,1894.413452 2560.796143,1896.646362 
	C2559.197510,1900.805054 2555.615723,1900.555908 2551.187500,1899.501221 
	C2548.135010,1897.216675 2547.842773,1894.838013 2548.982666,1891.396362 
z"
        />
        <path
          fill="#C4C8C1"
          opacity="1.000000"
          stroke="none"
          d="
M2083.441406,1847.700073 
	C2087.441406,1848.117554 2087.832031,1851.149780 2089.655273,1853.845215 
	C2089.725830,1855.362183 2089.315918,1856.395630 2088.417236,1857.722290 
	C2084.629395,1860.520020 2081.676758,1859.898071 2078.446289,1856.365234 
	C2078.669922,1851.607910 2075.866943,1850.177612 2072.393066,1848.400635 
	C2075.529541,1844.159546 2079.125244,1848.812866 2083.441406,1847.700073 
z"
        />
        <path
          fill="#96B6D1"
          opacity="1.000000"
          stroke="none"
          d="
M1766.861084,1781.960693 
	C1766.042847,1776.585205 1766.003662,1771.205200 1766.065674,1764.934448 
	C1768.925049,1764.074463 1771.575073,1764.342529 1771.871216,1767.913086 
	C1772.114502,1770.843018 1772.226196,1773.734619 1773.140259,1776.615479 
	C1774.750732,1781.690796 1771.161255,1781.898804 1766.861084,1781.960693 
z"
        />
        <path
          fill="#98999E"
          opacity="1.000000"
          stroke="none"
          d="
M2021.904785,1877.225830 
	C2016.710938,1877.613647 2011.429443,1877.368042 2006.647095,1880.543335 
	C2004.359741,1882.062012 2002.218140,1881.435303 2000.305664,1878.592773 
	C2003.430786,1878.214966 2006.588989,1877.235840 2009.180908,1875.193481 
	C2013.959106,1871.428101 2017.990967,1872.249268 2021.904785,1877.225830 
z"
        />
        <path
          fill="#98999E"
          opacity="1.000000"
          stroke="none"
          d="
M2045.020996,1876.014526 
	C2046.396362,1878.409546 2046.952881,1880.791382 2047.352905,1883.900513 
	C2042.824585,1878.812866 2036.798584,1880.072998 2030.446533,1880.315186 
	C2033.496460,1873.935181 2039.293945,1876.624512 2045.020996,1876.014526 
z"
        />
        <path
          fill="#6B6164"
          opacity="1.000000"
          stroke="none"
          d="
M1885.655518,1807.333862 
	C1882.062012,1807.827637 1878.482178,1808.580933 1874.384155,1806.641968 
	C1877.360107,1802.648071 1881.828369,1804.955566 1885.039795,1802.349121 
	C1885.737183,1803.553467 1885.787231,1805.072266 1885.655518,1807.333862 
z"
        />
        <path
          fill="#A9ACA9"
          opacity="1.000000"
          stroke="none"
          d="
M2176.417969,1861.994751 
	C2173.982422,1860.137329 2174.555664,1857.661133 2174.237305,1854.705566 
	C2178.242188,1854.612915 2177.921143,1857.681763 2177.959229,1861.279297 
	C2177.612549,1862.014526 2177.271240,1862.014526 2176.417969,1861.994751 
z"
        />
        <path
          fill="#96B6D1"
          opacity="1.000000"
          stroke="none"
          d="
M1789.933350,1785.103027 
	C1789.078613,1785.823608 1788.211548,1785.899536 1786.700928,1785.848877 
	C1786.298828,1784.046631 1785.468872,1781.607056 1787.843018,1781.205444 
	C1789.965332,1780.846558 1789.664307,1783.060791 1789.933350,1785.103027 
z"
        />
        <path
          fill="#8DA0AE"
          opacity="1.000000"
          stroke="none"
          d="
M2244.747070,1886.032715 
	C2244.033447,1885.171753 2243.988525,1884.293213 2243.909668,1882.758179 
	C2245.694336,1882.024536 2247.530762,1881.840576 2249.614746,1883.463379 
	C2248.959473,1885.630371 2247.254639,1885.974243 2244.747070,1886.032715 
z"
        />
        <path
          fill="#8DA0AE"
          opacity="1.000000"
          stroke="none"
          d="
M2239.680176,1881.648315 
	C2239.043457,1882.669312 2238.065430,1883.254150 2236.489258,1883.814819 
	C2236.361816,1882.024170 2236.581055,1879.921021 2239.680176,1881.648315 
z"
        />
        <path
          fill="#AAB7BE"
          opacity="1.000000"
          stroke="none"
          d="
M2419.378906,1844.111084 
	C2419.800781,1845.807739 2419.241699,1847.336304 2416.585693,1847.862549 
	C2416.194824,1846.179077 2416.767822,1844.663208 2419.378906,1844.111084 
z"
        />
        <path
          fill="#C4C8C1"
          opacity="1.000000"
          stroke="none"
          d="
M2059.641113,1847.664429 
	C2059.040039,1848.686890 2058.087402,1849.289551 2056.547363,1849.937256 
	C2056.345703,1848.188843 2056.483887,1846.094604 2059.641113,1847.664429 
z"
        />
        <path
          fill="#AAB7BE"
          opacity="1.000000"
          stroke="none"
          d="
M2413.381836,1848.121094 
	C2413.805908,1849.812500 2413.242432,1851.332153 2410.612305,1851.886841 
	C2410.188721,1850.196289 2410.745117,1848.671021 2413.381836,1848.121094 
z"
        />
        <path
          fill="#959693"
          opacity="1.000000"
          stroke="none"
          d="
M1921.067871,1858.002197 
	C1921.113159,1857.960327 1921.127563,1858.439575 1921.127563,1858.439575 
	C1921.127563,1858.439575 1920.794556,1858.307373 1920.862915,1858.134521 
	C1920.931274,1857.961548 1921.022705,1858.044067 1921.067871,1858.002197 
z"
        />
        <path
          fill="#5A605E"
          opacity="1.000000"
          stroke="none"
          d="
M1569.276855,1857.492188 
	C1582.939819,1853.968506 1595.632446,1858.644043 1608.457764,1858.540405 
	C1608.659180,1863.223999 1603.247314,1860.749146 1603.433594,1864.456787 
	C1605.345703,1867.030273 1608.289551,1865.537720 1611.479004,1866.264160 
	C1613.970825,1871.063721 1610.185791,1871.998657 1607.288940,1873.145752 
	C1599.979370,1876.039917 1592.127563,1877.307495 1584.935913,1880.680176 
	C1581.460938,1882.309814 1578.577759,1880.706665 1576.530762,1876.637207 
	C1575.749146,1873.265625 1577.394043,1870.828247 1577.072876,1868.136841 
	C1574.019287,1869.016357 1576.230103,1871.934082 1574.541016,1874.026245 
	C1564.599365,1875.205933 1556.570801,1879.521362 1548.290283,1885.130371 
	C1546.216797,1885.869507 1544.783813,1885.897095 1542.671875,1885.291504 
	C1540.002686,1884.052124 1538.761841,1879.841675 1535.691528,1883.385010 
	C1531.737427,1887.948120 1527.469360,1886.484863 1523.132446,1884.230103 
	C1513.458740,1879.200928 1504.015991,1872.989258 1492.489624,1879.431030 
	C1491.083008,1880.217041 1489.088013,1879.800171 1487.660156,1878.975464 
	C1482.500000,1875.995483 1479.500244,1875.850952 1476.725830,1882.691284 
	C1474.573120,1887.998413 1469.399658,1884.542480 1467.134033,1881.497070 
	C1461.876221,1874.429443 1456.501587,1875.651611 1448.422363,1876.797485 
	C1433.598145,1878.900146 1416.959595,1885.907837 1403.381836,1870.635742 
	C1399.825562,1863.779907 1401.398193,1857.052612 1401.351318,1850.485718 
	C1401.332642,1847.864868 1401.880493,1844.131470 1404.922241,1844.238281 
	C1408.707886,1844.371094 1407.632935,1848.428223 1408.024048,1850.869995 
	C1408.180542,1847.564697 1408.847290,1843.451660 1413.125732,1844.234131 
	C1417.426270,1845.020752 1415.464600,1849.702881 1415.996826,1852.460571 
	C1416.354858,1849.713989 1415.345459,1845.267944 1420.101196,1845.475220 
	C1424.338257,1845.659912 1423.233154,1849.554688 1423.167480,1852.506348 
	C1423.111816,1855.006958 1422.063232,1858.757080 1425.093262,1859.434570 
	C1428.448853,1860.184937 1428.724976,1856.731323 1429.186646,1853.990356 
	C1429.834961,1850.141846 1428.507324,1844.861938 1435.856445,1845.674805 
	C1442.171997,1846.373291 1445.494507,1848.485718 1444.024292,1855.306519 
	C1443.818970,1856.258911 1443.998657,1857.294312 1443.996582,1857.895874 
	C1444.804932,1854.540527 1442.080322,1849.737305 1447.270996,1847.291138 
	C1449.367798,1851.896973 1459.025391,1844.807739 1457.948975,1854.544922 
	C1458.842407,1851.363403 1459.547485,1847.673950 1463.281250,1848.192139 
	C1466.666992,1848.662109 1465.712891,1852.326660 1465.980591,1854.651489 
	C1466.391846,1852.262573 1465.501709,1847.828125 1469.271973,1848.411133 
	C1473.683105,1849.092773 1471.048340,1854.015747 1472.029053,1856.156250 
	C1472.075439,1854.742798 1471.819824,1852.527222 1472.386963,1850.373657 
	C1472.848022,1848.622559 1473.971069,1847.385864 1475.810669,1847.263550 
	C1477.658081,1847.140869 1479.403687,1848.252075 1479.547485,1849.909302 
	C1480.795288,1864.294434 1491.885742,1860.223145 1500.308472,1861.121704 
	C1501.285034,1861.225830 1502.295776,1861.017456 1503.980347,1860.992554 
	C1506.946289,1861.187012 1509.398804,1860.341919 1511.485840,1861.286987 
	C1522.534424,1866.289673 1530.238403,1861.144531 1537.699341,1852.767212 
	C1539.636963,1851.750488 1541.048218,1851.411743 1543.222900,1851.191162 
	C1553.157227,1849.133301 1560.194458,1856.183228 1569.276855,1857.492188 
z"
        />
        <path
          fill="#434F51"
          opacity="1.000000"
          stroke="none"
          d="
M1548.493652,1884.557251 
	C1554.338745,1875.741699 1564.335083,1871.629517 1573.952148,1873.565430 
	C1575.439697,1873.046875 1576.197632,1872.887085 1577.487549,1873.390625 
	C1581.021606,1880.274292 1581.171143,1880.480591 1585.632324,1877.245605 
	C1591.972778,1872.647949 1599.954590,1873.320923 1606.753540,1870.239014 
	C1608.471680,1869.460083 1610.692261,1869.770752 1611.995972,1867.009888 
	C1613.091553,1866.306152 1613.918701,1866.260376 1615.369141,1866.209717 
	C1617.665283,1865.865234 1619.457397,1865.912598 1620.702637,1866.983643 
	C1629.195557,1874.287476 1638.088379,1880.749634 1649.359619,1883.231934 
	C1651.597046,1883.724731 1653.054810,1885.874023 1652.924316,1889.183838 
	C1648.761963,1894.838745 1641.046265,1890.800293 1636.917603,1896.844360 
	C1635.856689,1898.536133 1635.124023,1899.651855 1633.975952,1901.303345 
	C1633.243774,1902.157959 1632.919067,1902.471680 1632.021362,1903.144531 
	C1629.397827,1904.432495 1627.190796,1904.754639 1624.353516,1905.253906 
	C1621.112183,1905.432251 1618.490356,1905.238525 1615.356689,1906.477783 
	C1601.282715,1900.583374 1586.432617,1906.051270 1572.199951,1901.083862 
	C1571.290283,1900.223877 1570.997070,1899.809570 1570.436035,1898.681885 
	C1570.402466,1887.182739 1570.402466,1887.184204 1559.386719,1886.981201 
	C1555.820068,1886.915527 1552.181396,1887.245728 1548.493652,1884.557251 
z"
        />
        <path
          fill="#5A605E"
          opacity="1.000000"
          stroke="none"
          d="
M1652.632080,1890.751465 
	C1651.766602,1886.419067 1648.899048,1884.627808 1646.036377,1884.478271 
	C1635.485352,1883.927124 1628.344727,1877.842163 1621.614380,1870.720581 
	C1620.274292,1869.302734 1618.446533,1868.345825 1616.417969,1866.690674 
	C1616.155273,1863.884521 1615.693481,1861.281616 1619.379761,1860.095703 
	C1620.892578,1859.258911 1621.818237,1858.762573 1623.632690,1858.396484 
	C1632.033569,1859.304932 1639.649292,1859.514282 1647.980469,1858.157471 
	C1660.355957,1858.529907 1662.406250,1868.756226 1666.619751,1876.508789 
	C1670.708130,1875.435913 1671.675903,1871.665161 1674.979736,1869.480957 
	C1676.465576,1868.750977 1677.391479,1868.580078 1679.052612,1868.702393 
	C1681.944214,1869.756104 1683.735474,1871.381958 1686.531738,1872.692627 
	C1689.284668,1874.570068 1691.500488,1875.938599 1694.127686,1876.383911 
	C1700.439697,1877.453857 1702.995239,1881.202393 1701.892090,1888.197876 
	C1701.397461,1889.415161 1701.132446,1889.881226 1700.289062,1890.926025 
	C1695.312378,1894.458130 1690.361450,1895.079102 1684.382324,1894.942017 
	C1678.992920,1894.994019 1674.451904,1894.902222 1669.055908,1894.747437 
	C1663.165649,1893.802002 1657.933472,1893.783936 1652.632080,1890.751465 
z"
        />
        <path
          fill="#947C7A"
          opacity="1.000000"
          stroke="none"
          d="
M1624.572510,1859.956055 
	C1622.973145,1860.123657 1622.143188,1860.075317 1620.691772,1859.987793 
	C1620.390381,1856.496704 1623.585571,1851.866699 1616.197388,1851.855835 
	C1610.874878,1851.847900 1610.062500,1847.982544 1610.051147,1843.589233 
	C1610.016235,1830.146118 1610.013184,1816.710938 1612.209229,1802.591553 
	C1617.220947,1797.115356 1623.067017,1795.277344 1630.540039,1796.033691 
	C1636.159912,1795.993896 1637.951538,1798.956909 1638.528564,1803.236694 
	C1638.946533,1806.336548 1638.031982,1809.675171 1640.435059,1813.173096 
	C1640.792725,1814.359375 1640.842041,1814.858154 1640.703857,1816.081299 
	C1636.172607,1828.282471 1633.639648,1839.700073 1642.079346,1851.392944 
	C1642.982178,1852.982544 1643.124390,1853.998901 1642.645264,1855.783203 
	C1637.272583,1860.826172 1630.820801,1857.727051 1624.572510,1859.956055 
z"
        />
        <path
          fill="#7B7F84"
          opacity="1.000000"
          stroke="none"
          d="
M1569.797607,1857.156128 
	C1566.322144,1858.739868 1563.499146,1861.475464 1559.434692,1856.802246 
	C1556.925781,1853.917358 1550.562256,1853.502686 1544.746826,1853.994385 
	C1538.167236,1846.151733 1541.978027,1837.265991 1541.082520,1828.003784 
	C1541.247314,1825.227539 1541.430908,1823.388062 1543.055298,1821.050415 
	C1552.560303,1819.203003 1552.426636,1819.166016 1554.723511,1810.854370 
	C1556.997314,1802.626343 1556.811035,1794.150024 1555.311401,1786.121094 
	C1553.380493,1775.784180 1554.234741,1765.556763 1554.166504,1755.294189 
	C1554.124878,1749.035156 1555.053955,1748.743652 1562.974365,1749.728149 
	C1566.570679,1751.855103 1566.600464,1754.846191 1566.091064,1758.797363 
	C1560.437744,1773.526367 1562.895508,1787.741333 1564.385742,1802.639404 
	C1566.022827,1806.825562 1566.695801,1810.313110 1565.031860,1814.564453 
	C1564.003052,1816.744507 1563.204834,1818.272217 1563.889893,1820.708740 
	C1565.332153,1826.029419 1564.343628,1831.527588 1569.458740,1835.502197 
	C1571.385864,1842.815430 1567.479126,1849.588623 1569.797607,1857.156128 
z"
        />
        <path
          fill="#98999E"
          opacity="1.000000"
          stroke="none"
          d="
M1563.162598,1761.099243 
	C1563.730835,1757.382202 1563.816895,1754.458374 1563.794922,1750.654053 
	C1564.509521,1746.612671 1566.537720,1745.428467 1569.616333,1746.427734 
	C1571.899536,1747.168823 1572.185425,1749.221069 1572.180420,1751.262573 
	C1572.156982,1760.833862 1572.220093,1770.407349 1572.032593,1779.975830 
	C1571.677856,1798.081543 1572.913818,1816.248779 1569.998535,1835.126465 
	C1562.296387,1835.053223 1562.267212,1835.054321 1562.035156,1828.543945 
	C1561.955200,1826.302612 1562.060425,1824.054443 1562.069214,1820.931030 
	C1561.259888,1819.255127 1560.953857,1818.299927 1561.609131,1816.552124 
	C1563.468994,1813.045654 1564.766357,1810.164429 1562.622803,1806.469727 
	C1560.139160,1791.264771 1562.483765,1776.591187 1563.162598,1761.099243 
z"
        />
        <path
          fill="#A9AEB3"
          opacity="1.000000"
          stroke="none"
          d="
M1542.912109,1820.237549 
	C1543.778809,1821.757324 1543.911255,1823.176514 1543.997803,1825.311279 
	C1543.135864,1826.920288 1542.154053,1827.479736 1540.457520,1827.471436 
	C1539.760620,1827.600342 1538.896973,1825.938110 1539.362793,1828.211914 
	C1539.647339,1829.997681 1539.318115,1831.141724 1538.125488,1832.615845 
	C1534.362671,1834.212891 1531.150879,1833.448364 1530.642578,1830.114868 
	C1529.548828,1822.941650 1524.459473,1821.882202 1518.181763,1821.015137 
	C1514.173462,1821.221802 1510.831909,1821.173340 1506.854248,1822.106689 
	C1502.204956,1822.869751 1498.379761,1823.669434 1495.315796,1826.554199 
	C1492.073486,1829.606934 1488.466187,1830.293213 1483.938477,1827.297363 
	C1481.570435,1824.642090 1480.857422,1822.187744 1481.631958,1818.690186 
	C1484.875122,1813.686523 1491.973145,1815.550049 1493.994995,1809.171387 
	C1505.921021,1809.740845 1517.379761,1814.145630 1529.285767,1813.811768 
	C1535.397949,1813.640503 1537.246338,1819.478638 1542.912109,1820.237549 
z"
        />
        <path
          fill="#98999E"
          opacity="1.000000"
          stroke="none"
          d="
M1632.468506,1797.605713 
	C1625.137329,1795.807739 1619.988281,1801.211426 1613.074707,1801.840698 
	C1612.299072,1800.330566 1612.277710,1798.856323 1612.264404,1796.639771 
	C1612.676880,1795.839111 1613.081177,1795.780762 1614.075195,1795.538574 
	C1620.377808,1793.756470 1626.241943,1793.746948 1632.952881,1793.760254 
	C1635.133789,1795.313110 1634.116333,1796.259644 1632.468506,1797.605713 
z"
        />
        <path
          fill="#5A605E"
          opacity="1.000000"
          stroke="none"
          d="
M1761.890625,1884.697266 
	C1768.677612,1882.104492 1775.950195,1883.351685 1782.067139,1879.352295 
	C1784.100464,1878.022949 1785.922119,1878.777466 1786.935059,1880.901367 
	C1787.804321,1882.723877 1786.721680,1884.151733 1785.316406,1885.100220 
	C1783.116821,1886.584473 1780.670898,1887.440796 1778.010620,1888.005127 
	C1771.428711,1889.401245 1771.044067,1890.268311 1773.373169,1896.591797 
	C1774.057373,1898.449341 1774.990356,1900.215332 1775.955200,1902.728149 
	C1775.657715,1904.400269 1774.668701,1905.306763 1773.874756,1904.952393 
	C1758.742310,1898.200439 1743.579956,1904.672241 1728.445312,1904.718628 
	C1727.796875,1904.720581 1727.185181,1904.373779 1726.612305,1904.291870 
	C1727.284180,1904.844116 1728.153442,1904.868774 1728.926270,1905.150269 
	C1730.910767,1905.873169 1732.348877,1907.055298 1732.288086,1909.998535 
	C1729.763672,1913.065063 1726.359009,1912.850830 1722.965088,1914.628662 
	C1716.678101,1917.632812 1711.125000,1920.249756 1704.052734,1917.108643 
	C1699.806396,1912.554321 1699.078247,1907.619629 1699.096191,1901.648926 
	C1699.075684,1898.715088 1699.042847,1896.593140 1699.855713,1893.799316 
	C1700.821899,1891.268555 1702.493042,1890.013550 1703.220215,1887.425781 
	C1705.196167,1883.212402 1706.810059,1879.519165 1711.369385,1877.906982 
	C1711.927246,1878.016113 1712.892212,1878.251587 1713.217041,1877.864014 
	C1714.079224,1874.011353 1713.208618,1870.720215 1712.181885,1866.740967 
	C1712.050049,1865.574097 1712.038574,1865.099731 1712.128296,1863.914062 
	C1712.689331,1861.195190 1714.027100,1859.837769 1716.230347,1858.299805 
	C1722.167358,1861.129272 1720.323242,1866.760010 1720.906128,1871.282959 
	C1721.279663,1874.181274 1719.456055,1877.350342 1724.078857,1877.875122 
	C1727.891846,1878.307861 1731.520386,1878.058228 1733.022583,1873.606567 
	C1733.442505,1872.361816 1733.877930,1871.038696 1733.881348,1869.750488 
	C1733.907104,1860.120728 1734.229126,1850.466309 1733.652466,1840.868042 
	C1733.184082,1833.075317 1732.687622,1833.105103 1740.653809,1830.829834 
	C1741.452026,1826.495483 1734.991089,1827.624268 1735.280273,1823.036255 
	C1740.547363,1821.227417 1746.387207,1822.603760 1748.999634,1826.472534 
	C1751.454590,1830.108276 1752.172485,1837.483643 1748.451050,1840.470459 
	C1741.321289,1846.192871 1743.173096,1853.964478 1743.002197,1860.382935 
	C1742.823120,1867.105103 1742.230835,1873.277832 1739.735718,1879.385986 
	C1737.732178,1884.290771 1738.084473,1885.568726 1743.046509,1885.820068 
	C1748.054199,1886.073730 1752.629150,1888.739868 1757.743530,1887.959106 
	C1759.491821,1887.692261 1760.924805,1887.158447 1761.890625,1884.697266 
z"
        />
        <path
          fill="#EBE4D0"
          opacity="1.000000"
          stroke="none"
          d="
M1662.574463,1772.342285 
	C1662.175903,1771.838623 1662.115112,1770.872192 1661.978760,1770.407715 
	C1661.684692,1769.472900 1661.527222,1769.002686 1661.819092,1767.950073 
	C1668.754517,1763.635864 1675.874146,1765.349731 1682.745117,1765.037964 
	C1691.348145,1764.647705 1699.973389,1765.526855 1708.580322,1764.471191 
	C1712.113403,1764.037720 1715.677246,1765.154419 1716.463135,1768.945923 
	C1717.526245,1774.075073 1713.208984,1773.595703 1710.217041,1773.982300 
	C1712.255859,1774.215454 1714.650024,1773.673096 1717.168457,1775.649292 
	C1718.342529,1781.093994 1717.314087,1785.951660 1716.831299,1791.641357 
	C1714.615356,1793.231934 1712.460571,1792.907837 1709.548340,1792.971069 
	C1701.569092,1792.910400 1694.376831,1793.213623 1687.193359,1792.788574 
	C1693.839600,1793.283569 1700.502563,1792.840210 1707.945801,1793.044312 
	C1710.866089,1793.015625 1712.994873,1792.976562 1715.889160,1793.342285 
	C1716.874878,1795.208496 1717.093384,1796.836060 1717.173218,1799.065430 
	C1712.373901,1805.010620 1705.816040,1803.175903 1700.317017,1803.123169 
	C1687.997925,1803.005371 1675.514404,1804.585327 1662.708252,1800.598877 
	C1662.074585,1799.075928 1662.070068,1798.230347 1662.089233,1796.750854 
	C1664.788818,1793.024048 1667.921265,1792.447144 1670.837036,1793.626709 
	C1668.929321,1792.131836 1664.372314,1793.056641 1662.459351,1788.503418 
	C1661.605347,1784.608765 1661.708862,1781.296143 1662.389038,1777.167480 
	C1663.129517,1775.247925 1663.754272,1774.150024 1662.574463,1772.342285 
z"
        />
        <path
          fill="#EBE4D0"
          opacity="1.000000"
          stroke="none"
          d="
M1715.936035,1837.306641 
	C1715.820557,1844.058228 1715.637939,1850.094116 1715.425415,1856.940186 
	C1713.217651,1860.359253 1710.048706,1860.307129 1707.160889,1860.449951 
	C1691.728149,1861.213013 1676.349731,1858.787598 1660.257568,1859.066040 
	C1657.158569,1853.630249 1657.653931,1848.462524 1658.997314,1842.616821 
	C1666.617310,1837.896973 1674.225952,1841.418945 1680.632202,1840.407837 
	C1677.289429,1839.156616 1672.749634,1840.758789 1668.330566,1840.028198 
	C1665.448120,1839.551514 1661.589355,1840.216797 1661.469482,1836.108643 
	C1661.343506,1831.784790 1665.341187,1830.764404 1668.571899,1830.833252 
	C1675.865112,1830.988525 1683.145508,1830.694702 1690.420654,1830.769287 
	C1695.710205,1830.823364 1701.030396,1830.889648 1706.326904,1831.178101 
	C1710.362061,1831.397827 1714.451660,1831.683594 1715.936035,1837.306641 
z"
        />
        <path
          fill="#EBE4D0"
          opacity="1.000000"
          stroke="none"
          d="
M1716.119873,1815.476074 
	C1716.557495,1816.899292 1716.575439,1817.779663 1716.496094,1819.314453 
	C1714.323242,1822.866089 1711.258057,1821.711182 1708.725830,1821.918701 
	C1710.868164,1822.235962 1713.371460,1821.584106 1715.965332,1823.509644 
	C1716.441162,1824.911499 1716.507202,1825.790405 1716.478027,1827.325439 
	C1715.575562,1829.059692 1714.590454,1830.138062 1713.280029,1830.278198 
	C1696.363281,1832.087646 1679.442993,1833.474365 1662.017822,1828.879883 
	C1660.960449,1827.241699 1660.805908,1826.165894 1661.304932,1824.320679 
	C1670.715820,1817.720337 1680.491455,1824.463135 1688.224487,1821.999390 
	C1680.862427,1822.057617 1671.947021,1822.186401 1662.557739,1819.369385 
	C1661.048828,1818.152466 1660.427856,1817.230347 1659.756348,1815.337402 
	C1659.245483,1812.731079 1659.235107,1810.977417 1659.964722,1808.410522 
	C1662.819580,1803.112671 1667.184692,1802.987305 1671.544922,1802.997192 
	C1686.294922,1803.030518 1701.046875,1801.729248 1716.328613,1803.705322 
	C1716.949463,1805.679321 1717.036987,1806.997803 1716.912231,1808.947998 
	C1714.041260,1813.853516 1709.759033,1812.303711 1706.850220,1812.858765 
	C1709.224121,1813.345825 1712.810425,1812.709473 1716.119873,1815.476074 
z"
        />
        <path
          fill="#C7C0B1"
          opacity="1.000000"
          stroke="none"
          d="
M1660.013916,1827.842285 
	C1678.609741,1830.827759 1696.640503,1829.892822 1715.530518,1828.022461 
	C1717.984131,1830.913086 1717.704712,1833.947876 1716.440063,1837.517578 
	C1714.033203,1836.315308 1712.503174,1834.032349 1709.411621,1833.999878 
	C1695.496460,1833.854004 1681.582397,1833.530396 1667.667847,1833.525024 
	C1666.096924,1833.524414 1663.418213,1833.366821 1662.990845,1836.997559 
	C1672.558594,1839.315186 1682.895386,1836.045166 1693.641968,1840.217896 
	C1684.663696,1844.821777 1676.600098,1842.644775 1668.722534,1842.461792 
	C1666.408936,1842.408203 1664.100952,1842.110229 1660.453857,1841.580566 
	C1658.625122,1840.536255 1658.250610,1839.777588 1657.860474,1838.339355 
	C1657.565430,1836.460205 1657.570801,1835.196289 1657.609619,1833.302002 
	C1657.720703,1831.831909 1657.882812,1831.006348 1658.367676,1829.613525 
	C1658.883545,1828.704590 1659.149902,1828.377319 1660.013916,1827.842285 
z"
        />
        <path
          fill="#C7C0B1"
          opacity="1.000000"
          stroke="none"
          d="
M1717.876831,1775.293213 
	C1714.350830,1775.803955 1711.117188,1775.684082 1707.903931,1776.955200 
	C1704.482666,1778.308350 1701.511230,1777.362183 1698.725220,1774.115234 
	C1702.420044,1770.312012 1706.575195,1770.621216 1711.094360,1771.840332 
	C1712.510132,1772.222290 1714.695679,1772.326416 1714.909302,1770.013550 
	C1715.104004,1767.905640 1712.970215,1767.085815 1711.606445,1767.074707 
	C1707.703735,1767.042603 1703.799316,1767.787231 1699.890381,1767.839600 
	C1688.014893,1767.998779 1676.136841,1767.975708 1663.353271,1768.099609 
	C1662.092285,1767.935913 1661.737793,1767.696045 1661.522949,1766.829590 
	C1661.726807,1765.824951 1661.791016,1765.447021 1661.962402,1764.503296 
	C1672.380127,1760.957397 1682.958618,1762.302979 1693.430908,1762.060913 
	C1700.993652,1761.885986 1708.564209,1762.042114 1717.069092,1762.021484 
	C1718.039795,1766.137451 1718.072632,1770.282471 1717.876831,1775.293213 
z"
        />
        <path
          fill="#C7C0B1"
          opacity="1.000000"
          stroke="none"
          d="
M1659.595703,1858.732422 
	C1677.889160,1854.829956 1695.835693,1858.832153 1714.566162,1857.642090 
	C1715.395386,1857.750244 1716.139771,1857.755493 1716.500732,1857.850586 
	C1716.435669,1859.464966 1716.009521,1860.984131 1715.154785,1862.919189 
	C1711.821167,1866.751221 1707.842407,1867.529419 1703.977295,1866.357544 
	C1693.988281,1863.329102 1683.747192,1866.563477 1673.748047,1864.653564 
	C1670.761230,1864.083008 1673.259155,1866.118042 1672.923462,1867.608887 
	C1670.773804,1872.582275 1667.226685,1872.300781 1665.045532,1869.377197 
	C1662.810303,1866.380615 1659.112183,1863.928101 1659.595703,1858.732422 
z"
        />
        <path
          fill="#C7C0B1"
          opacity="1.000000"
          stroke="none"
          d="
M1717.158691,1803.604492 
	C1708.209961,1805.056885 1699.559082,1804.105957 1690.826294,1805.486938 
	C1683.611328,1806.627930 1676.038330,1804.033569 1668.594849,1805.793701 
	C1666.722534,1806.236572 1664.647339,1805.796997 1662.364746,1807.613770 
	C1661.305298,1808.295898 1660.814331,1808.403076 1659.753174,1808.015747 
	C1658.462280,1806.069092 1658.598755,1804.612427 1659.636719,1802.568848 
	C1660.739624,1801.738403 1661.220825,1801.324097 1661.817871,1800.331177 
	C1679.851685,1799.925293 1697.623901,1801.032471 1716.215576,1799.801147 
	C1717.174683,1800.727539 1717.314941,1801.787842 1717.158691,1803.604492 
z"
        />
        <path
          fill="#C7C0B1"
          opacity="1.000000"
          stroke="none"
          d="
M1716.911865,1815.452393 
	C1710.129150,1815.707397 1703.508789,1816.384033 1696.252441,1811.159912 
	C1703.385010,1809.142212 1709.360352,1810.260864 1715.911377,1809.680420 
	C1717.663818,1811.263916 1717.768921,1813.042480 1716.911865,1815.452393 
z"
        />
        <path
          fill="#5A605E"
          opacity="1.000000"
          stroke="none"
          d="
M1736.487915,1814.392090 
	C1737.757202,1810.842407 1740.030884,1809.897095 1742.856445,1810.222656 
	C1745.305054,1810.505127 1747.267822,1811.714233 1747.456177,1814.392578 
	C1747.683594,1817.627075 1745.197754,1817.935181 1742.864136,1817.937134 
	C1740.375244,1817.939087 1737.482178,1818.568726 1736.487915,1814.392090 
z"
        />
        <path
          fill="#C7C0B1"
          opacity="1.000000"
          stroke="none"
          d="
M1716.714600,1823.702393 
	C1712.054199,1823.766235 1707.603760,1824.513184 1701.710693,1822.734009 
	C1706.625977,1818.785400 1710.866211,1820.490723 1715.519409,1819.991333 
	C1717.564575,1820.920776 1717.596558,1822.091187 1716.714600,1823.702393 
z"
        />
        <path
          fill="#C7C0B1"
          opacity="1.000000"
          stroke="none"
          d="
M1718.209717,1757.243652 
	C1713.487549,1757.948486 1709.340698,1756.292969 1705.050537,1754.382446 
	C1708.740723,1752.255005 1712.742432,1752.361572 1717.397217,1752.044434 
	C1718.208984,1753.538330 1718.275146,1755.023926 1718.209717,1757.243652 
z"
        />
        <path
          fill="#C7C0B1"
          opacity="1.000000"
          stroke="none"
          d="
M1716.741943,1793.297607 
	C1714.374512,1793.756958 1712.092773,1793.932861 1708.963379,1794.178711 
	C1707.904297,1793.844238 1707.692627,1793.439697 1707.785156,1792.421387 
	C1710.375366,1791.933716 1712.661255,1792.059692 1715.811279,1792.313843 
	C1716.675415,1792.442017 1716.827515,1793.014160 1716.741943,1793.297607 
z"
        />
        <path
          fill="#B6AFA3"
          opacity="1.000000"
          stroke="none"
          d="
M1317.323730,1781.440674 
	C1318.080444,1784.049683 1318.157349,1786.116943 1317.845703,1789.556152 
	C1307.087402,1794.147461 1296.551025,1796.320679 1285.615234,1795.981689 
	C1292.613159,1796.280029 1299.498413,1795.331543 1306.373413,1794.190552 
	C1309.988770,1793.590454 1313.615601,1793.199463 1316.930054,1796.803223 
	C1317.574219,1798.429810 1317.627808,1799.424683 1317.188721,1801.155151 
	C1314.227417,1805.184082 1309.520508,1805.530640 1306.116943,1809.211182 
	C1299.193604,1812.477539 1292.374023,1809.809448 1285.260010,1811.948730 
	C1281.307373,1812.919678 1278.113281,1812.885986 1274.228271,1812.171387 
	C1273.152588,1811.762573 1272.760010,1811.561646 1271.909424,1810.988647 
	C1270.775146,1810.632202 1271.157349,1808.541626 1269.667236,1810.320923 
	C1269.078613,1811.578247 1268.495728,1812.164795 1267.087769,1812.828369 
	C1259.460083,1811.046265 1261.768066,1805.188843 1261.084351,1799.748779 
	C1261.069580,1797.759766 1261.064331,1796.620483 1261.050537,1794.618896 
	C1261.383057,1789.184082 1260.414795,1784.314697 1264.760254,1779.967407 
	C1266.042847,1779.304688 1266.613281,1779.162842 1268.056396,1779.126099 
	C1272.993896,1781.096313 1277.060059,1780.797241 1281.470093,1780.250610 
	C1293.175415,1778.799561 1304.989014,1777.291504 1317.323730,1781.440674 
z"
        />
        <path
          fill="#C4BDAE"
          opacity="1.000000"
          stroke="none"
          d="
M1269.238770,1780.680420 
	C1268.506470,1780.140625 1267.777832,1780.022949 1267.409912,1779.995361 
	C1265.126465,1780.004150 1264.457397,1778.582886 1263.538818,1776.445557 
	C1263.135864,1772.189697 1260.853882,1769.087158 1261.517822,1764.749268 
	C1262.693481,1764.021362 1263.598877,1763.993408 1265.183594,1763.968018 
	C1278.734619,1767.050049 1291.441772,1763.205322 1304.235596,1763.091309 
	C1308.476807,1763.053467 1312.923340,1762.109253 1317.429810,1765.459229 
	C1321.165161,1768.844482 1317.537598,1771.771118 1318.062134,1775.347412 
	C1314.631226,1779.597046 1310.154541,1778.921143 1305.977173,1779.168579 
	C1293.976807,1779.879150 1281.998169,1780.979370 1269.238770,1780.680420 
z"
        />
        <path
          fill="#C4BDAE"
          opacity="1.000000"
          stroke="none"
          d="
M1266.686279,1760.691040 
	C1266.693481,1758.448730 1267.286011,1756.865845 1267.952393,1754.651123 
	C1271.164917,1750.565796 1275.820923,1749.739990 1279.704956,1749.725098 
	C1292.134399,1749.677124 1304.309692,1746.472412 1317.576416,1747.550415 
	C1320.944702,1750.759399 1318.984131,1753.668579 1318.340332,1757.214722 
	C1317.180908,1758.723511 1318.629028,1757.912598 1317.472656,1758.531616 
	C1302.660156,1766.457397 1286.703735,1766.330688 1270.627441,1764.889160 
	C1269.163818,1764.757935 1268.303467,1762.643433 1266.686279,1760.691040 
z"
        />
        <path
          fill="#C4BDAE"
          opacity="1.000000"
          stroke="none"
          d="
M1268.767578,1748.640625 
	C1268.057007,1745.913574 1268.039307,1743.747314 1268.047363,1740.768799 
	C1271.621948,1734.947388 1277.254639,1736.018555 1282.212646,1734.656372 
	C1291.630005,1732.069214 1301.236938,1734.019775 1310.620850,1732.188599 
	C1314.740601,1731.384766 1317.513550,1733.705444 1318.649414,1738.781494 
	C1319.735718,1741.393433 1320.144043,1743.242920 1318.973755,1745.769165 
	C1305.054443,1747.596802 1291.620483,1749.635376 1278.049561,1750.408569 
	C1274.997559,1750.582397 1272.134399,1751.016846 1268.767578,1748.640625 
z"
        />
        <path
          fill="#E5DDC9"
          opacity="1.000000"
          stroke="none"
          d="
M1319.118408,1738.836548 
	C1315.539185,1735.089355 1311.468018,1734.947021 1305.834595,1735.512695 
	C1296.703735,1736.429688 1287.484985,1736.873291 1278.319580,1737.881104 
	C1275.326782,1738.210205 1272.478394,1738.768188 1268.869751,1739.842041 
	C1268.061157,1737.848022 1268.049194,1735.739380 1268.062500,1732.839355 
	C1273.261963,1727.214111 1279.942627,1729.162720 1285.950317,1728.095581 
	C1294.049194,1726.656738 1302.296997,1726.476685 1311.281738,1725.247192 
	C1313.259888,1725.297607 1314.366455,1725.476685 1316.204102,1726.089355 
	C1319.705444,1728.261963 1318.813477,1731.107544 1318.822754,1734.444336 
	C1318.874390,1736.181885 1319.052612,1737.145020 1319.118408,1738.836548 
z"
        />
        <path
          fill="#E9E1D0"
          opacity="1.000000"
          stroke="none"
          d="
M1313.333008,1711.536133 
	C1315.387085,1712.070068 1316.717407,1712.100342 1318.706299,1712.164551 
	C1319.404785,1713.589722 1319.444946,1714.980835 1319.357422,1717.061035 
	C1318.194458,1719.223633 1316.776001,1720.134521 1314.212769,1720.771729 
	C1298.841553,1723.397705 1284.389893,1726.720093 1268.797241,1724.457153 
	C1268.103271,1722.454834 1268.084961,1720.978638 1268.083008,1718.764160 
	C1282.381958,1712.170044 1297.370239,1710.803345 1313.333008,1711.536133 
z"
        />
        <path
          fill="#E5DDC9"
          opacity="1.000000"
          stroke="none"
          d="
M1268.045410,1748.821045 
	C1281.392700,1748.369995 1294.527588,1746.658203 1307.628418,1744.516113 
	C1310.898438,1743.981323 1314.224731,1743.372070 1317.964600,1745.776123 
	C1318.572266,1746.368286 1318.482666,1747.129517 1318.442505,1747.510010 
	C1311.738159,1751.306763 1304.232422,1750.820557 1297.195801,1751.570312 
	C1288.095825,1752.539673 1278.864258,1752.684814 1268.888916,1754.005859 
	C1268.022949,1752.533447 1268.019409,1751.047729 1268.045410,1748.821045 
z"
        />
        <path
          fill="#E1D9C6"
          opacity="1.000000"
          stroke="none"
          d="
M1266.041992,1760.687012 
	C1271.176514,1763.344116 1276.649292,1764.933228 1282.746948,1763.963745 
	C1294.051147,1762.166870 1305.570312,1761.629395 1317.395264,1758.017334 
	C1318.197144,1760.027954 1318.193604,1762.191406 1318.141357,1765.162720 
	C1302.868286,1764.937012 1287.847778,1767.944824 1272.688599,1768.424561 
	C1269.065308,1768.539062 1268.120850,1767.135010 1266.402588,1764.366699 
	C1265.903198,1763.094360 1265.943237,1762.218384 1266.041992,1760.687012 
z"
        />
        <path
          fill="#7B7F84"
          opacity="1.000000"
          stroke="none"
          d="
M1347.780151,1795.528564 
	C1350.888550,1795.949951 1353.378662,1796.220215 1354.278687,1799.533691 
	C1355.355957,1803.499146 1357.969482,1804.919800 1361.725830,1802.997070 
	C1364.879028,1801.383301 1367.324463,1804.499023 1370.973511,1803.782104 
	C1373.971558,1803.305420 1374.668457,1804.613525 1374.467041,1807.338623 
	C1370.755859,1811.968384 1365.653076,1810.028931 1360.850464,1812.052246 
	C1356.812378,1813.076782 1353.537476,1812.878784 1349.477173,1812.408813 
	C1345.359619,1811.151855 1343.976685,1808.395508 1342.596680,1804.722290 
	C1341.848267,1802.680054 1341.444580,1801.299683 1340.787476,1799.263672 
	C1340.387573,1797.674927 1340.347290,1796.747437 1340.676025,1795.161621 
	C1341.964600,1794.368408 1343.010742,1794.227173 1344.846191,1794.021973 
	C1346.233032,1794.343018 1346.819336,1794.744019 1347.780151,1795.528564 
z"
        />
        <path
          fill="#DED5C3"
          opacity="1.000000"
          stroke="none"
          d="
M1268.564575,1780.892700 
	C1284.401367,1778.757446 1300.296143,1777.374390 1317.077148,1776.010986 
	C1317.980469,1777.518311 1317.997681,1779.005981 1318.009155,1781.238037 
	C1304.168091,1782.477173 1290.263916,1781.010498 1276.492310,1783.783203 
	C1273.936646,1784.297729 1270.414429,1785.326172 1268.564575,1780.892700 
z"
        />
        <path
          fill="#EFF1EA"
          opacity="1.000000"
          stroke="none"
          d="
M1340.128784,1794.465088 
	C1341.211792,1795.277710 1341.505249,1796.045654 1341.905518,1797.421387 
	C1342.771484,1799.107056 1342.996704,1800.287598 1342.116821,1802.315674 
	C1333.660156,1806.614624 1325.739380,1806.442993 1317.137451,1802.634277 
	C1316.491089,1801.143921 1316.493774,1800.339722 1316.541870,1798.933838 
	C1317.391357,1796.588379 1317.763794,1794.602905 1320.031738,1792.957031 
	C1322.119873,1792.153442 1323.556885,1792.194092 1325.644043,1793.023438 
	C1330.651489,1793.695679 1334.995239,1794.058105 1340.128784,1794.465088 
z"
        />
        <path
          fill="#C4C8C1"
          opacity="1.000000"
          stroke="none"
          d="
M1313.408691,1718.904053 
	C1315.292847,1718.090942 1316.611084,1717.983398 1318.579590,1717.813110 
	C1320.244995,1720.674927 1319.883911,1723.636475 1318.781494,1727.560059 
	C1317.196045,1728.365845 1316.296021,1728.211060 1314.711914,1728.009399 
	C1311.815552,1725.436035 1311.272461,1722.672852 1313.408691,1718.904053 
z"
        />
        <path
          fill="#B6AFA3"
          opacity="1.000000"
          stroke="none"
          d="
M1326.047852,1792.720215 
	C1324.808350,1793.633057 1323.308960,1793.932739 1321.054443,1794.355225 
	C1318.253662,1794.714111 1317.398560,1793.573975 1317.451660,1790.992065 
	C1317.763550,1790.239380 1318.007568,1789.990967 1318.130615,1789.864136 
	C1320.745850,1790.588989 1323.874023,1789.418213 1326.047852,1792.720215 
z"
        />
        <path
          fill="#E9E1D0"
          opacity="1.000000"
          stroke="none"
          d="
M1318.075684,1729.096680 
	C1318.096069,1728.520630 1318.512085,1728.372925 1318.724609,1728.334961 
	C1319.037598,1730.177612 1319.138184,1732.058350 1318.967529,1734.578857 
	C1318.482544,1733.370239 1318.268921,1731.521484 1318.075684,1729.096680 
z"
        />
        <path
          fill="#9DAAB4"
          opacity="1.000000"
          stroke="none"
          d="
M1348.192017,1795.466675 
	C1347.515137,1795.573120 1346.865967,1795.248169 1345.926270,1794.440674 
	C1346.064331,1793.959106 1346.493042,1793.960083 1347.557617,1794.066162 
	C1348.193604,1794.171265 1348.219727,1795.035400 1348.192017,1795.466675 
z"
        />
        <path
          fill="#83837F"
          opacity="1.000000"
          stroke="none"
          d="
M1661.756592,1761.047852 
	C1656.949829,1761.874634 1652.320312,1762.053467 1647.714722,1761.797363 
	C1645.258423,1761.660767 1643.948730,1760.410400 1646.501099,1758.210083 
	C1647.080078,1757.710815 1648.919312,1757.530151 1647.439697,1756.342041 
	C1640.952148,1751.132935 1646.937500,1750.843262 1650.044922,1750.270386 
	C1653.238281,1749.681763 1656.621704,1750.124023 1660.752930,1750.116455 
	C1662.764771,1753.447144 1662.368164,1756.832520 1661.756592,1761.047852 
z"
        />
        <path
          fill="#797771"
          opacity="1.000000"
          stroke="none"
          d="
M1648.037598,1730.887207 
	C1652.319336,1730.032715 1656.554810,1729.981323 1661.716309,1729.998535 
	C1662.561646,1733.534302 1662.480835,1737.001587 1662.297363,1741.338135 
	C1658.440918,1741.617065 1655.182373,1743.998169 1651.529541,1743.989136 
	C1648.595581,1743.981934 1646.191284,1743.618408 1647.147217,1739.530762 
	C1647.741333,1736.990479 1647.730591,1734.308838 1648.037598,1730.887207 
z"
        />
        <path
          fill="#C7C0B1"
          opacity="1.000000"
          stroke="none"
          d="
M1693.856689,1752.350464 
	C1696.290161,1753.123901 1698.076172,1753.800903 1700.255127,1754.626831 
	C1696.169678,1758.295410 1692.260376,1757.675171 1687.825562,1755.090332 
	C1689.152954,1753.104614 1691.065430,1752.492554 1693.856689,1752.350464 
z"
        />
        <path
          fill="#B9A795"
          opacity="1.000000"
          stroke="none"
          d="
M1669.345947,1735.126465 
	C1672.279541,1733.953003 1674.670044,1733.550537 1675.396851,1736.775146 
	C1675.706055,1738.147461 1674.863892,1739.428589 1673.343262,1739.641724 
	C1670.358887,1740.060181 1667.833984,1739.747192 1669.345947,1735.126465 
z"
        />
        <path
          fill="#B9A795"
          opacity="1.000000"
          stroke="none"
          d="
M1712.152344,1738.450439 
	C1709.238525,1740.224854 1707.123901,1740.221802 1706.556152,1737.068115 
	C1706.223511,1735.221436 1707.572876,1733.967651 1709.406250,1733.957397 
	C1711.924805,1733.943115 1714.145630,1734.522217 1712.152344,1738.450439 
z"
        />
        <path
          fill="#C7C0B1"
          opacity="1.000000"
          stroke="none"
          d="
M1674.044922,1753.071777 
	C1675.825317,1754.020630 1676.109009,1755.209717 1675.223022,1756.416626 
	C1674.531006,1757.359009 1673.468994,1757.319458 1672.807007,1756.372803 
	C1671.953003,1755.151489 1672.219360,1753.965576 1674.044922,1753.071777 
z"
        />
        <path
          fill="#6B6164"
          opacity="1.000000"
          stroke="none"
          d="
M1632.648926,1798.182129 
	C1632.423096,1796.989014 1632.935791,1795.955811 1633.626221,1794.373413 
	C1635.332764,1793.644409 1636.861816,1793.464355 1639.157593,1793.240112 
	C1640.271606,1795.103516 1640.618774,1797.010986 1641.413574,1799.478271 
	C1642.628784,1800.771362 1643.207886,1801.631958 1643.743896,1803.393677 
	C1643.714966,1807.353271 1643.154907,1810.426758 1640.759521,1813.671387 
	C1636.635986,1814.043091 1633.903076,1813.671387 1635.336182,1808.651123 
	C1636.314331,1805.224976 1637.517090,1801.098389 1632.648926,1798.182129 
z"
        />
        <path
          fill="#6F7981"
          opacity="1.000000"
          stroke="none"
          d="
M1660.798584,1766.127686 
	C1661.779419,1766.536987 1661.896362,1766.870972 1662.229980,1767.690430 
	C1662.446777,1768.175903 1662.139404,1769.057495 1661.990845,1769.500244 
	C1657.845459,1770.000610 1653.927124,1770.382935 1650.036377,1771.516602 
	C1647.882446,1772.144165 1644.911621,1772.384644 1644.653076,1769.070801 
	C1644.449219,1766.457397 1646.982056,1766.236328 1648.964966,1766.167969 
	C1652.618530,1766.041992 1656.277710,1766.081543 1660.798584,1766.127686 
z"
        />
        <path
          fill="#C7C0B1"
          opacity="1.000000"
          stroke="none"
          d="
M1661.994385,1788.750488 
	C1665.044434,1790.413818 1669.651855,1789.650391 1672.244507,1793.014648 
	C1669.953979,1795.970215 1666.751343,1795.964111 1662.837280,1796.056030 
	C1662.044067,1793.933350 1661.975098,1791.749512 1661.994385,1788.750488 
z"
        />
        <path
          fill="#A9ACA9"
          opacity="1.000000"
          stroke="none"
          d="
M1642.949829,1804.462402 
	C1642.219727,1803.045288 1641.999268,1802.198608 1641.820068,1800.694824 
	C1646.388550,1800.834229 1651.457642,1798.184082 1655.691406,1802.894531 
	C1655.165894,1805.119751 1653.825439,1805.820923 1651.556030,1806.440796 
	C1649.768433,1806.672607 1648.739990,1806.681885 1646.947754,1806.507690 
	C1645.181763,1806.133667 1644.273193,1805.677368 1642.949829,1804.462402 
z"
        />
        <path
          fill="#6B6164"
          opacity="1.000000"
          stroke="none"
          d="
M1649.553467,1804.941284 
	C1651.477783,1804.086426 1652.958984,1803.982666 1655.170410,1803.744507 
	C1656.950195,1803.207764 1657.999878,1802.805420 1659.581299,1802.193359 
	C1660.147705,1803.492676 1660.182495,1805.001587 1660.037598,1807.247559 
	C1657.591553,1810.535889 1654.789917,1810.615479 1651.015625,1809.302002 
	C1649.407959,1807.976685 1649.026489,1806.918091 1649.553467,1804.941284 
z"
        />
        <path
          fill="#C7C0B1"
          opacity="1.000000"
          stroke="none"
          d="
M1662.089478,1772.591675 
	C1662.814575,1771.894043 1663.471924,1771.871582 1664.088257,1772.019897 
	C1665.764160,1772.423462 1668.689453,1771.449707 1668.529419,1774.162720 
	C1668.387085,1776.573242 1665.517090,1775.666626 1663.132690,1776.223145 
	C1662.308105,1775.341309 1662.155518,1774.343018 1662.089478,1772.591675 
z"
        />
        <path
          fill="#687073"
          opacity="1.000000"
          stroke="none"
          d="
M1482.988525,1824.700928 
	C1487.700317,1826.419800 1491.904541,1829.305786 1494.483887,1822.368408 
	C1496.062500,1818.122314 1500.474121,1819.712769 1504.710693,1820.089966 
	C1508.717896,1821.673096 1510.361084,1825.249390 1514.424805,1826.620483 
	C1518.935791,1830.513550 1525.846558,1833.526001 1518.072388,1839.670166 
	C1515.176758,1841.958618 1518.300781,1841.745972 1518.790161,1842.752197 
	C1521.241943,1847.793823 1518.434570,1853.255493 1519.963379,1858.602783 
	C1520.521240,1860.554199 1516.444702,1862.319458 1513.671143,1861.828979 
	C1511.235596,1861.398315 1509.139648,1861.439209 1506.640869,1863.404785 
	C1500.114990,1863.201782 1493.795288,1867.280884 1487.967896,1862.574463 
	C1487.306152,1862.040039 1485.464600,1862.810913 1484.226929,1863.171265 
	C1478.241821,1864.913818 1478.273071,1864.936279 1477.949341,1858.613892 
	C1477.790283,1855.507690 1478.586304,1852.192627 1476.042725,1848.059692 
	C1472.514648,1854.677368 1474.826660,1860.541260 1474.495850,1867.227417 
	C1467.831665,1862.392456 1471.328247,1855.748535 1469.407837,1850.724365 
	C1466.738037,1852.444580 1468.442505,1855.090698 1468.010254,1857.264893 
	C1467.527222,1859.694458 1468.989746,1862.583740 1465.674194,1865.020752 
	C1462.111572,1860.399658 1465.584595,1854.649048 1463.072510,1850.057007 
	C1457.900391,1853.604614 1461.605469,1859.980957 1458.640991,1864.913452 
	C1453.998657,1860.416382 1457.444092,1854.782104 1455.136108,1849.948975 
	C1452.519653,1854.063721 1455.680054,1859.196045 1451.962158,1863.352905 
	C1448.130981,1858.957642 1451.800171,1853.137207 1447.544556,1848.109497 
	C1444.505615,1856.007935 1447.375610,1863.440186 1444.919800,1871.342773 
	C1439.566650,1863.604248 1443.541016,1855.685303 1441.469482,1848.370850 
	C1438.395508,1850.483398 1441.547241,1854.803467 1437.819824,1856.920776 
	C1434.455322,1854.654663 1437.366943,1849.851562 1434.620605,1847.451050 
	C1432.888428,1847.415283 1432.306641,1848.465454 1432.165283,1849.909180 
	C1431.200439,1859.755249 1431.203247,1859.755493 1422.854492,1866.614014 
	C1418.028320,1860.751221 1420.394531,1854.127686 1421.302979,1846.856323 
	C1416.581177,1849.229370 1418.484253,1852.781860 1418.054688,1855.469482 
	C1417.569214,1858.507202 1418.845337,1861.915649 1415.791870,1865.683350 
	C1412.199097,1858.752808 1415.504272,1851.860718 1412.839722,1845.135254 
	C1407.324097,1850.324219 1412.384521,1857.728394 1407.870239,1863.580200 
	C1404.454346,1857.788574 1407.186646,1851.947998 1404.047485,1846.809204 
	C1404.047485,1853.956177 1404.047485,1861.103271 1404.069336,1869.179932 
	C1403.267944,1870.753418 1402.335815,1871.063599 1400.587646,1870.804443 
	C1395.834351,1866.343384 1399.450317,1861.044434 1398.019531,1857.774536 
	C1398.041870,1858.187866 1398.046021,1859.927734 1397.888550,1861.653809 
	C1397.706665,1863.647217 1397.315430,1865.853638 1394.906006,1866.048340 
	C1392.199463,1866.267212 1390.955078,1864.141724 1390.087646,1861.905273 
	C1388.896729,1858.834106 1386.931152,1857.747070 1383.462891,1858.137695 
	C1379.701782,1851.936890 1379.890747,1845.429565 1379.434082,1839.295776 
	C1378.799072,1830.771118 1375.467651,1826.482056 1366.659668,1827.032104 
	C1364.693848,1827.154785 1362.742065,1826.465332 1360.229004,1825.098633 
	C1357.851440,1822.762939 1357.390137,1820.499878 1357.370239,1817.204834 
	C1357.558594,1815.111206 1357.918823,1813.983765 1359.070557,1812.255859 
	C1363.339844,1809.307861 1367.464355,1810.221069 1372.272461,1809.991943 
	C1375.929565,1809.738770 1378.458496,1810.649536 1381.520508,1812.372559 
	C1384.939453,1817.593140 1389.934570,1818.939697 1395.160400,1821.202637 
	C1397.069336,1824.123535 1399.019287,1826.064453 1401.973267,1825.834595 
	C1411.345215,1825.105469 1420.428345,1827.826538 1429.706543,1827.949585 
	C1439.316040,1828.077026 1448.962158,1828.468750 1459.269287,1826.030273 
	C1461.868164,1826.088501 1463.540527,1825.892944 1465.460693,1824.364258 
	C1466.225952,1816.265747 1466.225952,1816.265747 1475.177734,1815.804932 
	C1476.037964,1816.178711 1476.065552,1816.598389 1476.172363,1817.643799 
	C1476.767822,1821.880859 1480.209839,1822.490967 1482.988525,1824.700928 
z"
        />
        <path
          fill="#9FA2A1"
          opacity="1.000000"
          stroke="none"
          d="
M1395.724487,1820.846191 
	C1393.122437,1821.764160 1390.544678,1821.820679 1387.977905,1822.020020 
	C1383.242798,1822.387573 1381.577637,1819.965576 1381.983765,1814.758057 
	C1382.168091,1808.003540 1388.347168,1810.522583 1391.068115,1807.152344 
	C1396.184692,1795.577393 1386.136230,1797.520752 1380.710693,1794.534302 
	C1379.929199,1789.122070 1384.569336,1790.230957 1387.730347,1788.430542 
	C1396.787842,1788.932861 1402.059204,1794.325073 1407.439209,1800.723633 
	C1410.373657,1805.333984 1416.841553,1803.859863 1418.720459,1809.539062 
	C1412.719849,1809.285278 1407.036133,1811.904785 1400.693481,1810.600952 
	C1395.315552,1809.495361 1395.823853,1815.925049 1395.724487,1820.846191 
z"
        />
        <path
          fill="#687073"
          opacity="1.000000"
          stroke="none"
          d="
M1419.297485,1810.497314 
	C1415.308960,1807.591064 1410.416504,1806.937378 1408.123047,1801.835938 
	C1411.395874,1801.515625 1414.830078,1801.933350 1419.127441,1802.336426 
	C1420.356567,1802.513672 1420.722290,1802.705566 1421.264404,1803.491577 
	C1422.331543,1809.331055 1424.953613,1810.188965 1429.500977,1806.419922 
	C1430.770020,1805.825195 1431.664307,1805.928467 1433.230347,1806.067139 
	C1434.447632,1807.656250 1434.992920,1809.210083 1435.782104,1811.337646 
	C1430.334717,1815.783813 1425.021362,1814.668701 1419.297485,1810.497314 
z"
        />
        <path
          fill="#9DAAB4"
          opacity="1.000000"
          stroke="none"
          d="
M1465.487549,1823.766846 
	C1466.170654,1825.830078 1466.626099,1827.927490 1464.608154,1829.010620 
	C1462.798706,1829.981689 1461.796265,1828.352539 1460.470093,1826.595215 
	C1460.644409,1825.034668 1461.154541,1824.017700 1462.024658,1822.556152 
	C1463.263306,1822.599487 1464.142090,1823.087402 1465.487549,1823.766846 
z"
        />
        <path
          fill="#9DAAB4"
          opacity="1.000000"
          stroke="none"
          d="
M1463.012207,1818.783447 
	C1463.144653,1818.571045 1463.219360,1819.219360 1463.188965,1819.315430 
	C1463.158569,1819.411499 1462.879883,1818.995728 1463.012207,1818.783447 
z"
        />
        <path
          fill="#9FA2A1"
          opacity="1.000000"
          stroke="none"
          d="
M1422.117432,1803.795776 
	C1420.945190,1803.861938 1420.555054,1803.518677 1420.130493,1802.688721 
	C1420.198975,1798.397705 1419.246582,1795.520020 1413.694824,1796.133057 
	C1412.331299,1795.864136 1411.689209,1795.600220 1411.514893,1794.405762 
	C1415.582886,1790.857910 1418.972900,1792.323242 1422.157837,1794.229858 
	C1426.646240,1796.916382 1427.266479,1800.093140 1422.117432,1803.795776 
z"
        />
        <path
          fill="#687073"
          opacity="1.000000"
          stroke="none"
          d="
M1411.470703,1793.811279 
	C1412.106079,1793.932983 1412.229614,1794.390503 1412.663330,1795.488037 
	C1412.235352,1796.448730 1411.178467,1797.245605 1410.816895,1797.003784 
	C1409.568359,1796.167847 1410.137207,1795.076904 1411.470703,1793.811279 
z"
        />
        <path
          fill="#E7E3DA"
          opacity="1.000000"
          stroke="none"
          d="
M1483.596191,1824.464478 
	C1475.973999,1826.406982 1475.525513,1826.082275 1475.951782,1818.827393 
	C1477.676636,1818.150757 1479.101807,1818.031860 1481.244141,1817.939331 
	C1482.819214,1819.843628 1484.254028,1821.553711 1483.596191,1824.464478 
z"
        />
        <path
          fill="#6B6164"
          opacity="1.000000"
          stroke="none"
          d="
M1483.671387,1805.877197 
	C1481.687256,1805.843506 1480.078613,1806.001221 1479.466919,1804.736938 
	C1478.757080,1803.269775 1479.839600,1802.272705 1481.307007,1802.253906 
	C1482.883057,1802.233643 1484.980957,1802.014526 1485.937378,1802.885620 
	C1488.081909,1804.838623 1485.801025,1805.299194 1483.671387,1805.877197 
z"
        />
        <path
          fill="#6F7981"
          opacity="1.000000"
          stroke="none"
          d="
M1380.159302,1794.872925 
	C1385.684204,1790.714722 1390.226807,1794.276489 1394.314697,1796.826904 
	C1398.022705,1799.140381 1398.746460,1803.056519 1394.354858,1806.984741 
	C1388.991211,1812.095581 1384.903076,1808.603027 1380.158447,1805.358643 
	C1379.078613,1803.718262 1378.925781,1802.647217 1379.660767,1800.873291 
	C1380.161255,1798.694702 1380.149658,1797.165649 1380.159302,1794.872925 
z"
        />
        <path
          fill="#9FA2A1"
          opacity="1.000000"
          stroke="none"
          d="
M1379.426514,1800.245361 
	C1380.339722,1800.824341 1380.409546,1801.436035 1380.242798,1802.501465 
	C1380.749878,1805.773315 1378.821045,1807.042847 1376.212402,1808.487549 
	C1375.113037,1808.630493 1374.664551,1808.580688 1373.854980,1807.958252 
	C1373.607178,1806.247681 1373.559326,1805.154175 1372.172119,1803.989746 
	C1372.656860,1799.738159 1375.760010,1800.189453 1379.426514,1800.245361 
z"
        />
        <path
          fill="#152533"
          opacity="1.000000"
          stroke="none"
          d="
M606.810669,3524.060059 
	C603.666077,3519.915771 603.270752,3516.357178 609.218811,3515.368408 
	C620.292236,3513.527832 631.303101,3511.483154 642.161987,3508.544434 
	C645.363037,3507.677979 649.123169,3509.198486 651.237122,3511.619873 
	C653.901306,3514.671143 657.321960,3514.909912 660.517761,3516.367188 
	C671.044312,3521.166504 681.819275,3520.418213 692.553711,3518.965576 
	C698.255798,3518.194092 703.749023,3515.823486 709.592102,3515.062256 
	C712.237061,3514.717285 714.427917,3514.619873 715.104553,3517.470215 
	C715.650940,3519.771973 713.853760,3521.397461 711.804626,3521.662109 
	C705.353638,3522.494629 702.405334,3528.733398 696.458618,3531.440430 
	C693.801086,3532.572266 691.590149,3533.206055 688.690796,3533.919922 
	C684.019287,3533.993408 680.035889,3533.954346 676.053162,3533.991211 
	C673.336060,3534.016602 670.009888,3534.060303 668.611206,3531.938232 
	C664.480530,3525.670654 657.751831,3526.718506 651.970886,3524.844971 
	C645.549316,3522.763428 638.931824,3527.044922 633.547607,3524.920410 
	C627.212891,3522.420166 621.738098,3521.294678 614.738403,3523.940918 
	C611.834106,3524.059570 609.719177,3524.050781 606.810669,3524.060059 
z"
        />
        <path
          fill="#0D171F"
          opacity="1.000000"
          stroke="none"
          d="
M215.952560,3555.225586 
	C213.846695,3555.977539 211.720856,3555.984375 208.797165,3555.990723 
	C204.624496,3552.997559 200.302460,3552.614990 196.181381,3551.623535 
	C194.557083,3551.232422 193.015167,3550.291260 193.089005,3548.347656 
	C193.175552,3546.069824 195.264542,3546.396240 196.743958,3545.916260 
	C200.291016,3544.765381 203.909775,3543.637451 206.492340,3547.495850 
	C208.842529,3551.006592 215.801239,3548.117432 215.952560,3555.225586 
z"
        />
        <path
          fill="#0D171F"
          opacity="1.000000"
          stroke="none"
          d="
M237.536377,3545.711670 
	C234.379211,3545.735107 231.579498,3545.550781 233.511017,3542.024658 
	C234.748581,3539.764893 236.982712,3537.177002 239.847458,3539.566650 
	C242.849014,3542.070312 240.983200,3544.156982 237.536377,3545.711670 
z"
        />
        <path
          fill="#0C1A26"
          opacity="1.000000"
          stroke="none"
          d="
M1032.429199,3419.416260 
	C1031.006470,3420.011230 1029.999023,3419.961426 1029.009888,3420.073730 
	C1027.434692,3420.252441 1025.448486,3419.946289 1025.069458,3422.166016 
	C1024.743286,3424.075439 1026.304321,3425.102295 1027.494385,3426.217285 
	C1029.331421,3427.938721 1031.860596,3427.635010 1034.017090,3428.395508 
	C1043.412964,3431.709229 1053.745972,3431.583008 1062.754883,3436.293701 
	C1064.224609,3437.062012 1065.810913,3437.607178 1067.340454,3438.261963 
	C1069.076172,3439.004639 1069.824219,3440.369873 1069.705200,3442.203369 
	C1069.548828,3444.608887 1068.031494,3445.997070 1065.816772,3445.829102 
	C1059.398071,3445.342529 1053.357422,3447.887451 1047.057983,3447.941162 
	C1042.641968,3447.979004 1038.517090,3448.594238 1034.522583,3450.620361 
	C1030.767822,3452.525391 1026.828003,3452.781250 1023.174316,3450.150879 
	C1018.382507,3446.701416 1013.429749,3447.488525 1009.256958,3450.664307 
	C1005.512329,3453.514404 1000.457581,3453.781738 996.295593,3458.028320 
	C1003.686829,3460.590576 1010.278931,3460.726562 1017.390137,3460.837646 
	C1024.204712,3460.943848 1031.224121,3461.755127 1039.085083,3461.956299 
	C1042.346680,3465.250488 1040.080811,3467.033691 1037.534668,3467.877930 
	C1032.645996,3469.499268 1027.870483,3472.089355 1022.280884,3471.062256 
	C1026.340576,3473.981201 1029.426270,3477.263184 1024.588501,3482.739990 
	C1016.247986,3483.658691 1009.096069,3487.785156 1000.564941,3486.458740 
	C994.225525,3482.327148 988.457397,3481.674561 982.563477,3485.825195 
	C979.913208,3487.691162 978.511902,3490.214600 978.179199,3493.299561 
	C977.693298,3497.803955 980.314636,3499.895020 984.695435,3498.041504 
	C987.036072,3497.051514 988.843994,3496.042969 990.933716,3498.095459 
	C992.374390,3499.510010 993.866638,3500.977295 993.450867,3503.208008 
	C992.976990,3505.750488 990.827209,3505.929443 988.815918,3505.940918 
	C979.168274,3505.996826 969.520325,3505.986816 958.936218,3506.000000 
	C940.597412,3510.478516 923.334900,3512.445801 906.059448,3502.679688 
	C904.500061,3500.879395 904.192566,3499.384521 904.461609,3497.053955 
	C907.155640,3490.936035 912.337524,3488.493408 917.173645,3485.608154 
	C915.269043,3483.286621 912.759460,3483.519775 910.639771,3482.884766 
	C906.335999,3481.595947 903.281860,3479.379639 904.274536,3474.388672 
	C905.210938,3469.680664 908.701050,3467.770264 913.141663,3468.184082 
	C929.258179,3469.685791 944.717834,3464.850586 960.536072,3463.592529 
	C964.893188,3463.245850 967.640137,3462.434082 966.478149,3457.173096 
	C965.871887,3454.427490 966.620789,3451.327637 969.238953,3450.209229 
	C978.300720,3446.338379 984.193237,3436.697998 995.187378,3436.277344 
	C997.956726,3436.171631 1002.198547,3435.694092 1002.281372,3432.140381 
	C1002.388977,3427.526611 997.904053,3428.432129 994.752136,3427.779541 
	C983.070496,3425.360596 971.533386,3422.565918 959.431824,3422.313232 
	C951.162170,3422.140625 944.677917,3416.308350 938.299622,3411.705811 
	C930.318909,3405.947021 922.524414,3402.051025 912.588257,3405.950195 
	C911.085266,3406.540039 909.300049,3405.794189 906.943909,3405.645996 
	C904.337097,3405.250244 902.831665,3404.251953 901.287048,3402.209473 
	C900.289490,3400.179199 900.162476,3398.518799 898.924194,3396.719238 
	C898.162537,3396.121582 897.841614,3395.895508 897.009033,3395.356445 
	C895.773926,3394.624268 895.054443,3394.198242 893.764832,3393.418945 
	C890.434082,3391.170654 888.099548,3388.851807 885.877808,3385.395508 
	C874.860046,3367.478271 872.315491,3347.509033 866.789307,3328.542236 
	C865.872314,3325.394531 865.414368,3322.046875 867.135986,3318.142090 
	C879.757629,3314.115479 891.848267,3319.148438 902.337219,3317.453857 
	C898.909729,3316.812500 895.594788,3314.018066 895.823181,3308.420898 
	C896.050537,3302.850098 899.535889,3299.744141 904.707520,3299.405762 
	C920.302795,3298.385254 935.921448,3298.245361 951.502869,3299.871338 
	C955.024048,3300.238770 958.223328,3301.613770 959.144287,3305.270264 
	C960.605225,3311.070801 964.952271,3310.778320 969.330566,3310.788330 
	C971.918396,3310.793945 973.747681,3310.654541 972.932434,3306.926270 
	C971.479065,3300.279785 976.101318,3297.052002 981.394897,3297.422119 
	C999.130737,3298.662354 1016.022888,3292.095215 1033.803711,3292.899902 
	C1045.970093,3293.450684 1057.852173,3288.432617 1069.998047,3286.624268 
	C1074.660889,3285.929932 1079.250977,3284.696777 1083.475098,3289.474854 
	C1084.015259,3291.647217 1083.605347,3293.445801 1084.107056,3294.937500 
	C1086.610107,3302.379395 1085.763550,3308.907959 1080.835938,3315.374268 
	C1078.770752,3318.084473 1078.054565,3321.335449 1082.196167,3325.386230 
	C1085.828247,3328.938721 1086.194458,3336.372070 1085.357422,3342.189209 
	C1084.811157,3345.985840 1081.225952,3347.810791 1077.104492,3348.034912 
	C1070.729004,3348.381592 1064.560913,3347.769531 1058.629150,3345.262695 
	C1055.585938,3343.976562 1052.410522,3342.985352 1049.425659,3341.582764 
	C1044.081421,3339.071777 1039.828491,3340.043213 1036.539429,3345.021240 
	C1033.624268,3349.433350 1029.938354,3350.605225 1024.317505,3350.860596 
	C1018.022888,3351.146484 1011.662292,3354.037354 1004.591553,3355.504883 
	C990.374573,3359.864502 976.371826,3362.149902 962.321045,3360.071045 
	C950.067383,3358.258301 937.839111,3358.522217 925.590454,3358.033691 
	C923.611023,3357.954590 921.261230,3357.566162 919.813232,3358.978516 
	C913.032410,3365.590332 905.258362,3363.964355 897.355103,3362.105957 
	C904.725952,3366.007080 912.313599,3364.387695 919.975037,3363.328125 
	C940.160950,3360.536621 960.348816,3363.396973 980.522278,3363.893799 
	C988.267517,3364.084229 996.422852,3360.287842 1004.280945,3366.053223 
	C1005.563049,3369.791992 1004.117432,3372.387451 1001.466003,3373.117432 
	C994.083130,3375.149414 987.606628,3380.909668 979.922974,3380.504639 
	C963.447144,3379.635498 948.730530,3390.479736 931.726257,3386.385254 
	C939.866394,3391.140137 947.166687,3396.120361 954.512268,3401.023682 
	C958.403687,3403.621338 962.008057,3403.649902 965.156860,3399.945557 
	C968.301514,3396.246582 972.008118,3396.457764 976.035156,3398.032471 
	C982.943176,3400.733887 990.125977,3401.187256 997.498962,3401.760010 
	C1011.233337,3402.826660 1023.181458,3408.771484 1032.429199,3419.416260 
z"
        />
        <path
          fill="#020914"
          opacity="1.000000"
          stroke="none"
          d="
M999.572510,3486.525879 
	C1004.383545,3484.640137 1008.810608,3483.457520 1013.113586,3481.926514 
	C1016.331421,3480.781494 1019.406189,3480.398438 1023.290466,3481.932617 
	C1024.667603,3481.954834 1025.319214,3481.732910 1025.967896,3481.720459 
	C1043.741333,3481.375977 1060.521729,3485.237793 1076.115601,3493.843262 
	C1080.540405,3496.285156 1083.381836,3500.256836 1082.211792,3505.339111 
	C1079.953125,3515.151123 1079.878174,3525.812500 1070.580444,3532.991943 
	C1065.885742,3536.617432 1067.419434,3540.368652 1074.258667,3542.423096 
	C1079.120483,3543.883057 1084.077148,3544.719238 1085.904785,3551.288574 
	C1086.141846,3555.441162 1086.143433,3558.863525 1086.125732,3563.141113 
	C1082.624268,3567.687256 1084.946655,3571.614990 1085.733643,3576.170410 
	C1085.881958,3578.303955 1085.773682,3579.712646 1084.523193,3581.502197 
	C1081.221313,3582.080322 1078.948730,3583.593018 1076.539917,3584.687988 
	C1074.906006,3585.430664 1073.218872,3585.736572 1071.842407,3584.551270 
	C1064.245483,3578.007812 1054.170532,3579.489746 1045.416504,3576.702148 
	C1037.699341,3574.244873 1029.133179,3578.099365 1020.913269,3578.892578 
	C1014.715271,3579.490479 1008.783020,3581.928223 1002.356445,3582.216064 
	C996.037109,3582.499512 993.014526,3588.410889 995.383728,3594.583008 
	C996.335327,3597.062256 997.905029,3599.314209 998.760864,3601.817627 
	C1000.097290,3605.725830 997.438538,3608.828125 993.566772,3607.380615 
	C984.570984,3604.017578 975.068298,3602.041748 966.309570,3598.423340 
	C960.303345,3595.941895 954.704041,3596.317627 948.848877,3596.091064 
	C944.759277,3595.932861 941.551575,3597.985352 940.590942,3601.955078 
	C939.514648,3606.403320 943.196899,3607.240723 946.418396,3608.479248 
	C949.062256,3609.495605 953.354675,3610.282471 952.676880,3613.764648 
	C951.937317,3617.563477 947.656006,3615.795898 944.916504,3615.926514 
	C938.952942,3616.210693 933.077393,3616.265381 927.112671,3617.561035 
	C919.886841,3619.130127 912.645813,3618.111328 905.959167,3614.075195 
	C899.655640,3610.270264 899.096741,3608.114990 905.177856,3604.654297 
	C909.579895,3602.148926 913.339233,3599.127441 916.887756,3595.625488 
	C919.210083,3593.333984 922.660583,3591.760742 920.694641,3587.526367 
	C918.779846,3583.402100 916.274841,3580.233643 911.129883,3579.972900 
	C906.310791,3579.729004 902.651978,3581.249023 900.280579,3585.499512 
	C897.733154,3590.065674 893.517334,3592.405029 888.681641,3591.838867 
	C876.749146,3590.441895 865.991211,3595.853027 854.498779,3597.045410 
	C844.107239,3598.124023 834.115723,3598.683594 824.061768,3595.107910 
	C813.526123,3591.360840 802.773438,3588.248291 791.448547,3588.861572 
	C790.147827,3586.015137 792.246582,3585.146729 793.861633,3584.137695 
	C796.095764,3582.741943 799.186829,3582.107178 800.072632,3579.405762 
	C802.302917,3572.604248 808.408203,3570.839355 813.931763,3569.333740 
	C822.839844,3566.905273 832.029236,3565.185791 841.210632,3564.203369 
	C846.689453,3563.617432 851.498718,3562.666016 856.018921,3559.329346 
	C861.610168,3555.201660 867.297363,3551.383545 875.012085,3551.603271 
	C879.234497,3551.723389 883.121155,3548.785889 886.608521,3546.196289 
	C895.966248,3539.248047 905.870056,3540.619141 915.638367,3544.614746 
	C926.977112,3549.253174 937.845642,3555.454346 950.743652,3553.965820 
	C960.064819,3552.890137 968.237915,3548.108643 976.865967,3544.954346 
	C981.330872,3543.322510 985.456543,3542.068115 990.155518,3541.926025 
	C997.184204,3541.714600 1002.929626,3538.400635 1007.547180,3533.294434 
	C1010.895386,3529.592041 1010.567261,3526.861328 1006.384155,3524.333008 
	C1002.425049,3521.939941 998.323486,3519.639648 993.332275,3519.965576 
	C989.633972,3520.207520 985.972900,3519.917480 982.833374,3516.316650 
	C985.506653,3512.973145 989.193542,3511.606689 993.019409,3512.786621 
	C997.981445,3514.317383 1002.973694,3514.356445 1007.852844,3513.860107 
	C1014.860657,3513.146973 1020.339966,3517.582764 1026.876465,3518.718018 
	C1033.829712,3519.925537 1039.972534,3517.714111 1046.501221,3517.901123 
	C1052.093750,3518.061279 1057.838135,3518.913574 1063.276611,3516.507324 
	C1065.060425,3515.718018 1067.418213,3515.525391 1067.442627,3512.960449 
	C1067.465454,3510.559814 1065.397827,3509.611572 1063.564575,3508.931885 
	C1059.208496,3507.317871 1054.786255,3506.269531 1050.047363,3505.832520 
	C1041.597900,3505.053467 1033.185791,3504.411865 1025.250122,3500.009521 
	C1019.294739,3496.705322 1012.016541,3495.929688 1004.962158,3495.994385 
	C1002.963928,3496.012695 1000.963623,3496.004639 998.966858,3495.934814 
	C997.295044,3495.876465 995.509644,3495.771729 994.757629,3493.940674 
	C993.846863,3491.723145 995.055542,3490.135010 996.698059,3488.815918 
	C997.474548,3488.192139 998.320740,3487.655273 999.572510,3486.525879 
z"
        />
        <path
          fill="#010611"
          opacity="1.000000"
          stroke="none"
          d="
M533.909607,3598.786133 
	C536.138855,3598.005615 538.259460,3597.991699 541.174622,3597.969971 
	C553.228088,3597.499268 564.612305,3599.414062 575.731018,3596.157471 
	C576.659241,3595.885498 577.864441,3595.725342 578.695435,3596.083252 
	C587.160156,3599.727539 594.869934,3595.281006 602.911865,3594.115967 
	C607.390015,3593.467285 610.402283,3591.660889 611.854492,3587.423828 
	C614.417480,3579.946533 619.821655,3577.288330 627.380249,3577.974365 
	C631.435425,3578.342773 635.991272,3578.364990 638.741089,3575.346436 
	C644.093079,3569.470703 649.878967,3570.818604 655.941895,3573.020996 
	C659.586914,3574.345215 664.101013,3573.563477 666.974609,3577.667725 
	C665.474976,3580.907715 662.882629,3579.657471 660.403748,3579.172363 
	C650.835266,3577.299561 647.433044,3579.316162 646.104736,3588.583984 
	C645.531677,3592.582764 643.685364,3595.007080 640.162048,3595.492432 
	C630.736572,3596.791016 625.593506,3603.889404 620.112061,3610.182861 
	C617.031860,3613.719482 614.262512,3616.390625 609.382202,3616.028809 
	C605.930664,3615.772949 602.804016,3617.245850 599.756592,3618.433105 
	C584.507935,3624.372803 569.988037,3624.492920 556.585876,3613.791748 
	C554.313599,3611.977295 551.660278,3612.000244 548.986267,3612.002930 
	C546.986877,3612.004883 544.987244,3612.023682 542.988220,3611.996094 
	C531.943054,3611.843262 530.886963,3610.408203 533.909607,3598.786133 
z"
        />
        <path
          fill="#704A3A"
          opacity="1.000000"
          stroke="none"
          d="
M818.594849,3515.912598 
	C817.651123,3515.900635 817.274170,3515.855713 816.351929,3515.646729 
	C814.363770,3514.172852 813.230408,3512.657959 812.519043,3510.069824 
	C812.002625,3505.022217 813.914429,3500.943604 813.659302,3496.519775 
	C812.917725,3483.659912 810.896973,3482.076904 797.935120,3485.828857 
	C795.656067,3486.142090 794.144958,3485.946045 791.977417,3485.015137 
	C788.784607,3482.142334 787.807129,3479.182373 788.665283,3474.968262 
	C797.203064,3466.898926 801.339355,3458.085449 799.269226,3446.779053 
	C797.610535,3437.719727 806.682251,3433.044678 808.896362,3424.725098 
	C811.761841,3422.736816 813.085938,3424.444092 814.900757,3426.522705 
	C816.922119,3437.440674 820.908997,3446.719482 827.735840,3455.458740 
	C829.829285,3457.906494 830.789429,3460.278809 832.446777,3462.908203 
	C833.039124,3463.668213 833.291809,3463.954346 833.978271,3464.594727 
	C836.712769,3466.146484 839.121338,3464.659180 842.091919,3465.485352 
	C848.955017,3471.910156 849.414001,3480.188965 850.901123,3488.862061 
	C851.370850,3495.605225 849.555908,3500.968506 846.232300,3506.739258 
	C845.258972,3508.334961 844.468445,3509.025635 842.723755,3509.658203 
	C838.504517,3510.562256 835.070007,3511.330566 830.882324,3512.207275 
	C829.740906,3512.549316 829.352600,3512.782715 828.419128,3513.422363 
	C826.437622,3513.929443 825.001282,3514.031006 822.849060,3514.209717 
	C821.208618,3514.940918 820.305359,3515.634766 818.594849,3515.912598 
z"
        />
        <path
          fill="#704A3A"
          opacity="1.000000"
          stroke="none"
          d="
M754.697144,3494.529053 
	C752.506348,3494.562500 750.969543,3494.419189 748.657715,3494.310547 
	C747.479004,3494.054688 747.075317,3493.764648 746.228516,3492.878418 
	C743.194885,3480.075684 736.865417,3471.112793 724.747803,3466.466064 
	C719.546631,3464.471924 720.184021,3460.486816 723.645691,3456.948730 
	C728.194702,3452.299072 730.432129,3447.125244 727.815002,3439.906250 
	C727.313049,3437.584473 727.213440,3435.995605 727.297363,3433.562256 
	C729.740967,3418.270508 729.405090,3403.764893 727.946167,3389.255859 
	C727.481873,3384.637939 726.087524,3379.871826 732.351685,3376.987061 
	C739.341187,3379.326416 739.320862,3385.195557 740.269226,3390.199219 
	C744.466492,3412.343506 749.600403,3434.248291 756.334229,3456.558105 
	C757.801331,3464.960693 761.346069,3471.511230 766.860596,3477.859863 
	C768.008423,3479.112061 768.626221,3479.869141 769.649658,3481.262695 
	C771.508667,3485.047119 774.316589,3487.848877 772.244873,3492.401611 
	C767.818237,3494.818604 763.623901,3493.599365 758.819824,3494.160645 
	C757.152771,3494.270996 756.251953,3494.311768 754.697144,3494.529053 
z"
        />
        <path
          fill="#0C1A26"
          opacity="1.000000"
          stroke="none"
          d="
M842.408691,3510.346436 
	C842.430847,3509.276367 842.922607,3508.758789 843.826416,3507.887451 
	C845.633667,3506.224854 846.938416,3504.787109 849.598145,3504.108887 
	C851.270264,3503.854004 852.233093,3503.871582 853.887451,3504.204102 
	C859.672363,3506.862061 860.606384,3512.647461 864.568359,3516.912109 
	C874.133850,3531.531494 882.501221,3533.338379 893.813232,3523.080078 
	C898.289185,3519.318848 900.249817,3514.628174 903.367188,3509.911865 
	C904.370544,3509.350830 904.835144,3509.248535 906.009888,3509.230225 
	C908.656860,3509.802490 910.598694,3510.245850 912.615479,3510.241699 
	C918.952637,3510.228027 921.127075,3513.968262 919.605469,3519.394043 
	C917.923279,3525.392822 917.190186,3531.865234 912.480652,3537.468750 
	C906.858826,3537.493164 901.937744,3537.936279 896.619934,3535.733887 
	C892.816895,3534.158691 886.491333,3537.088135 881.915283,3539.298828 
	C872.614380,3543.792969 862.952881,3542.054688 853.512329,3541.632812 
	C846.366699,3541.313721 843.518127,3534.106201 838.416565,3530.216797 
	C841.108398,3527.508301 842.909241,3528.993408 844.790894,3529.225830 
	C851.174988,3530.013916 853.725708,3526.750244 852.537354,3520.598389 
	C851.446228,3514.949219 848.277893,3511.961426 842.408691,3510.346436 
z"
        />
        <path
          fill="#010611"
          opacity="1.000000"
          stroke="none"
          d="
M673.917603,3538.749512 
	C678.095154,3538.016357 682.168640,3538.003418 687.114990,3537.990234 
	C694.068176,3537.200684 699.706909,3539.750000 705.605347,3540.409912 
	C708.643311,3540.749756 710.230896,3543.134766 708.720276,3546.278076 
	C707.124268,3549.598633 706.433105,3553.146240 705.380066,3556.616699 
	C703.098022,3564.138428 694.158813,3568.577637 686.303223,3566.291992 
	C681.876099,3565.003906 677.551575,3563.350830 673.220215,3561.755371 
	C672.387878,3561.448730 671.012695,3560.402832 671.111755,3560.105469 
	C673.347412,3553.388672 667.813232,3545.645996 673.917603,3538.749512 
z"
        />
        <path
          fill="#05121F"
          opacity="1.000000"
          stroke="none"
          d="
M912.759888,3538.011963 
	C912.762390,3531.770508 914.147827,3525.726807 916.890442,3520.032959 
	C917.907898,3517.920898 918.674500,3515.505371 916.938660,3513.519531 
	C914.878052,3511.162109 912.790588,3512.818115 910.852051,3514.087158 
	C907.203186,3516.475586 906.247131,3514.374512 905.901611,3510.443848 
	C903.986755,3507.732422 903.967102,3505.523926 905.773987,3502.609375 
	C908.476013,3500.657227 910.744751,3499.702881 912.810852,3501.509521 
	C917.612793,3505.708252 923.648560,3505.584473 929.347107,3506.904541 
	C933.720520,3507.917480 937.505432,3508.320557 941.582275,3505.747559 
	C946.335144,3502.748047 951.668884,3503.654053 957.425293,3505.455078 
	C956.698364,3508.795166 954.221191,3510.582031 952.213867,3512.775879 
	C949.695618,3515.526855 946.894714,3516.452393 943.302490,3514.812012 
	C941.836548,3514.142334 940.084290,3514.100342 938.463074,3513.768799 
	C931.705444,3512.387207 926.444275,3514.616211 924.527222,3519.674805 
	C922.177673,3525.874756 924.394470,3532.653076 930.137512,3535.966553 
	C933.274597,3537.776611 936.449158,3539.626465 940.213806,3540.057373 
	C942.850708,3540.359619 945.421143,3541.018066 947.149170,3544.486084 
	C941.306946,3546.824219 935.782410,3546.805420 930.277039,3544.097656 
	C924.910583,3541.458252 919.544250,3538.872803 912.759888,3538.011963 
z"
        />
        <path
          fill="#96664C"
          opacity="1.000000"
          stroke="none"
          d="
M772.377563,3492.116943 
	C772.094604,3488.193359 768.872681,3486.533936 767.955566,3482.958008 
	C768.007996,3481.375000 768.377258,3480.518066 769.570251,3479.365723 
	C771.074890,3478.690430 772.006775,3478.592773 773.625122,3478.924805 
	C774.678589,3479.407959 775.064941,3479.667725 775.951111,3480.402832 
	C780.549438,3484.997803 786.105591,3481.902588 791.328918,3483.903076 
	C793.490295,3484.014648 794.985901,3483.972900 797.229614,3483.921387 
	C806.263245,3481.479248 809.918396,3484.084961 809.889832,3493.194580 
	C809.873291,3498.488281 812.411743,3503.755615 813.319153,3509.682129 
	C815.431396,3511.062500 815.867981,3512.544678 815.806641,3514.873535 
	C812.436707,3515.635498 809.066772,3515.788818 804.849121,3515.907959 
	C801.257446,3516.031494 798.537659,3516.020264 795.402588,3514.418701 
	C794.437866,3513.854248 794.070923,3513.602295 793.210083,3512.910156 
	C791.339050,3510.847412 790.114319,3509.068115 787.638123,3507.666504 
	C785.086121,3505.711914 783.308044,3503.851562 780.936096,3501.682129 
	C778.066162,3498.174561 775.405457,3495.355957 772.377563,3492.116943 
z"
        />
        <path
          fill="#0C1A26"
          opacity="1.000000"
          stroke="none"
          d="
M1083.972900,3582.767090 
	C1084.002319,3580.500000 1084.004639,3579.000000 1084.005859,3576.750000 
	C1084.799927,3575.302246 1085.722656,3575.016357 1087.384033,3575.580078 
	C1089.088257,3582.437988 1086.098511,3588.375488 1085.845703,3594.710938 
	C1085.444824,3604.758057 1087.204956,3614.651123 1088.629517,3624.333984 
	C1090.343994,3635.988770 1089.359131,3647.238281 1088.382446,3659.305176 
	C1087.769653,3659.947754 1087.417603,3659.960449 1086.537598,3659.981445 
	C1085.379028,3659.051514 1084.748413,3658.112549 1084.059814,3656.586914 
	C1086.640137,3646.275635 1087.221802,3636.480957 1084.486206,3626.706299 
	C1083.197510,3622.101074 1085.191895,3617.644531 1084.254272,3612.956787 
	C1081.784180,3600.608398 1080.388306,3598.741943 1067.847046,3598.146973 
	C1062.534790,3597.894775 1058.256836,3596.660156 1054.727539,3591.740479 
	C1058.889160,3587.165039 1064.117065,3587.191895 1069.108276,3588.845947 
	C1074.102661,3590.500732 1078.116333,3588.176270 1082.439819,3586.973877 
	C1083.797241,3586.596191 1083.746704,3584.831055 1083.972900,3582.767090 
z"
        />
        <path
          fill="#0C1A26"
          opacity="1.000000"
          stroke="none"
          d="
M781.354736,3499.528320 
	C783.879395,3501.536621 785.697327,3503.132568 787.842590,3505.241699 
	C789.605530,3507.747559 791.366699,3509.419434 793.833008,3511.195801 
	C794.386230,3511.450195 794.463013,3511.514160 794.503052,3511.543945 
	C795.414490,3514.517334 799.065979,3514.257812 800.205383,3517.301270 
	C795.243286,3519.485107 791.160400,3520.344482 786.549683,3516.114990 
	C782.076965,3512.011963 774.978699,3512.882324 768.470642,3510.781738 
	C770.683105,3506.781250 775.212524,3505.408447 775.988708,3500.600830 
	C777.448914,3498.695068 778.988342,3498.559326 781.354736,3499.528320 
z"
        />
        <path
          fill="#020914"
          opacity="1.000000"
          stroke="none"
          d="
M942.890381,3526.056641 
	C947.913635,3526.367188 952.364807,3524.915527 956.247498,3527.608154 
	C957.601196,3528.546875 958.997620,3529.747803 958.925476,3531.511475 
	C958.837463,3533.663330 956.950867,3533.961426 955.302979,3533.906006 
	C951.927002,3533.792725 948.830322,3535.098389 945.594727,3535.541260 
	C942.213379,3536.004639 940.010315,3533.667236 938.825195,3530.779297 
	C937.649963,3527.915283 939.303711,3526.493408 942.890381,3526.056641 
z"
        />
        <path
          fill="#2E2D30"
          opacity="1.000000"
          stroke="none"
          d="
M781.803101,3499.331055 
	C780.568787,3499.941650 779.076050,3499.942383 776.839844,3499.924561 
	C774.360474,3496.514648 771.355591,3495.872803 767.887756,3495.896484 
	C764.994019,3495.916016 762.027344,3496.435791 758.637756,3494.717041 
	C761.645752,3490.433105 766.414124,3492.787354 771.396545,3491.660156 
	C776.298096,3492.666260 779.006287,3495.584473 781.803101,3499.331055 
z"
        />
        <path
          fill="#2E2D30"
          opacity="1.000000"
          stroke="none"
          d="
M800.605835,3517.895508 
	C798.764587,3515.557861 794.653992,3516.395264 794.462158,3512.250488 
	C797.430542,3512.653076 800.317871,3513.732178 803.603333,3515.342773 
	C803.419373,3517.027344 802.461914,3517.649658 800.605835,3517.895508 
z"
        />
        <path
          fill="#2E2D30"
          opacity="1.000000"
          stroke="none"
          d="
M818.358398,3516.369873 
	C818.696899,3514.860596 819.668396,3514.265381 821.534302,3514.217773 
	C821.657227,3516.046143 821.307739,3517.979248 818.358398,3516.369873 
z"
        />
        <path
          fill="#2E2D30"
          opacity="1.000000"
          stroke="none"
          d="
M828.343018,3513.735352 
	C828.167786,3513.238037 828.607422,3512.791992 829.661072,3512.402832 
	C829.864380,3512.931152 829.425354,3513.373291 828.343018,3513.735352 
z"
        />
        <path
          fill="#0C1A26"
          opacity="1.000000"
          stroke="none"
          d="
M1085.752930,3677.972168 
	C1087.960693,3678.352295 1089.861206,3679.035645 1089.124268,3680.994629 
	C1087.096924,3686.385010 1088.616699,3691.671875 1088.590576,3697.511963 
	C1083.600342,3690.291748 1083.600342,3690.291748 1076.122070,3685.734131 
	C1078.668091,3683.443848 1081.026489,3681.322266 1083.692749,3678.610107 
	C1084.390137,3678.010498 1084.779541,3678.001709 1085.752930,3677.972168 
z"
        />
        <path
          fill="#D1C9BA"
          opacity="1.000000"
          stroke="none"
          d="
M2796.219971,1892.755859 
	C2791.389160,1887.894287 2797.682373,1885.301514 2797.750244,1880.842041 
	C2797.166748,1879.265991 2796.966309,1878.283325 2797.646729,1876.563477 
	C2801.836426,1873.676880 2806.261230,1875.641357 2810.406006,1873.229004 
	C2819.139160,1868.146362 2824.314697,1861.790161 2823.907959,1851.210449 
	C2823.667480,1844.953369 2824.057861,1838.631958 2824.692627,1832.361450 
	C2825.402344,1825.349976 2821.924805,1822.863159 2815.233887,1823.257446 
	C2810.366211,1823.544312 2805.432861,1825.644287 2800.051758,1822.638184 
	C2799.647949,1817.334351 2799.762695,1812.558228 2799.954590,1806.890381 
	C2800.548828,1805.574951 2800.991699,1805.084351 2801.600342,1804.091431 
	C2810.388184,1800.190552 2819.358643,1802.743652 2829.004883,1801.804199 
	C2844.172363,1801.323120 2843.536133,1802.592529 2843.190186,1815.881958 
	C2842.683105,1835.351685 2841.204346,1854.821167 2840.223145,1875.140869 
	C2840.203857,1888.285156 2840.050293,1900.574341 2838.634766,1913.594238 
	C2832.458984,1914.377075 2826.701660,1914.930664 2820.306152,1912.930420 
	C2817.348145,1908.827271 2821.119385,1906.831543 2822.168213,1904.009888 
	C2825.189941,1895.881104 2822.572754,1892.266357 2813.155762,1892.596069 
	C2811.566162,1892.606689 2810.662842,1892.542480 2809.097900,1892.354736 
	C2804.453369,1890.905273 2800.776123,1894.131104 2796.219971,1892.755859 
z"
        />
        <path
          fill="#C4BDAE"
          opacity="1.000000"
          stroke="none"
          d="
M2838.545410,1876.423950 
	C2835.558350,1873.943481 2835.494385,1871.062988 2836.345947,1868.623291 
	C2838.731445,1861.789429 2838.312744,1854.759155 2837.900879,1847.856812 
	C2837.183105,1835.825073 2841.169434,1824.142090 2840.012695,1812.133545 
	C2839.760498,1809.514771 2840.498047,1806.811768 2839.363770,1804.200073 
	C2836.716797,1802.574829 2833.397949,1805.116577 2830.391113,1802.340576 
	C2836.187744,1798.739624 2843.004639,1801.042114 2850.265137,1799.761230 
	C2859.307129,1800.427124 2867.526611,1801.440308 2875.769043,1801.686035 
	C2882.490723,1801.886719 2889.238525,1801.115967 2895.979004,1800.948486 
	C2896.599121,1800.932983 2897.250000,1802.171143 2897.967773,1803.480957 
	C2898.036865,1809.480225 2898.024902,1814.825562 2897.979004,1821.065063 
	C2889.587891,1828.132324 2879.964600,1824.966187 2870.909424,1825.357788 
	C2860.029785,1825.828247 2859.882324,1825.489990 2859.513916,1836.303467 
	C2859.378418,1840.282837 2859.506348,1844.274414 2858.524414,1848.957520 
	C2853.273193,1855.899902 2843.784180,1859.383301 2845.792725,1870.689941 
	C2844.937012,1873.347778 2843.634521,1874.809448 2841.328369,1876.345581 
	C2840.161865,1876.734985 2839.665283,1876.786499 2838.545410,1876.423950 
z"
        />
        <path
          fill="#A6A29C"
          opacity="1.000000"
          stroke="none"
          d="
M2799.083984,1822.721802 
	C2807.145020,1821.965576 2814.500000,1819.234741 2822.245850,1820.074341 
	C2825.775146,1820.456909 2827.998779,1821.578003 2827.842285,1825.399658 
	C2827.290527,1838.872437 2826.699219,1852.343628 2826.053711,1865.812134 
	C2826.009277,1866.735962 2825.447510,1868.398193 2825.031982,1868.428589 
	C2815.846436,1869.102905 2810.742188,1882.577148 2798.227051,1875.915039 
	C2796.745605,1874.816772 2796.531250,1874.400757 2796.206543,1873.292236 
	C2795.990234,1870.742676 2795.655273,1868.859497 2797.098633,1866.547363 
	C2797.596680,1859.227173 2797.715088,1852.495117 2797.799805,1844.842773 
	C2796.617188,1841.891968 2797.098633,1839.694702 2797.463379,1836.809448 
	C2798.087402,1831.826782 2798.361328,1827.579956 2799.083984,1822.721802 
z"
        />
        <path
          fill="#98999E"
          opacity="1.000000"
          stroke="none"
          d="
M2787.330566,1785.963623 
	C2783.810059,1785.518921 2781.781006,1784.646240 2784.168457,1781.618164 
	C2785.258301,1780.236084 2787.049561,1780.175781 2788.707031,1780.060669 
	C2796.900146,1779.491821 2805.184814,1780.976196 2813.323242,1778.853027 
	C2815.119629,1778.384277 2816.549072,1779.579590 2817.031250,1781.325195 
	C2817.551514,1783.208984 2816.377441,1784.722168 2814.780273,1785.159424 
	C2812.599121,1785.756836 2810.246094,1785.884033 2807.962158,1785.919189 
	C2801.365723,1786.020874 2794.767090,1785.975220 2787.330566,1785.963623 
z"
        />
        <path
          fill="#C4C8C1"
          opacity="1.000000"
          stroke="none"
          d="
M2849.976074,1762.892212 
	C2853.797363,1761.788452 2856.504150,1763.009033 2855.580811,1767.374878 
	C2854.948242,1770.364502 2854.854492,1773.785522 2851.142090,1775.968994 
	C2850.301025,1772.037598 2850.129150,1767.906860 2849.976074,1762.892212 
z"
        />
        <path
          fill="#83837F"
          opacity="1.000000"
          stroke="none"
          d="
M2796.799316,1865.972412 
	C2797.562256,1868.030640 2797.646729,1870.102173 2797.679688,1872.950684 
	C2797.397949,1874.073486 2797.167480,1874.419189 2796.325195,1874.885254 
	C2795.315430,1874.885742 2794.917236,1874.766113 2794.180176,1874.122681 
	C2793.279053,1871.505249 2792.716553,1869.411377 2792.084473,1866.666260 
	C2793.383545,1866.005005 2794.751953,1865.995239 2796.799316,1865.972412 
z"
        />
        <path
          fill="#83837F"
          opacity="1.000000"
          stroke="none"
          d="
M2797.357910,1836.517456 
	C2797.842773,1838.168457 2797.871582,1840.263184 2797.833496,1843.140137 
	C2795.592041,1841.836182 2795.637451,1839.474487 2797.357910,1836.517456 
z"
        />
        <path
          fill="#C4BDAE"
          opacity="1.000000"
          stroke="none"
          d="
M2801.376221,1803.901367 
	C2801.523926,1804.250854 2801.207275,1804.845581 2800.461426,1805.719482 
	C2800.325195,1805.381226 2800.618408,1804.763794 2801.376221,1803.901367 
z"
        />
        <path
          fill="#959693"
          opacity="1.000000"
          stroke="none"
          d="
M2892.049805,1903.194092 
	C2884.749268,1901.467163 2877.890869,1902.600220 2870.894043,1905.800659 
	C2867.034424,1907.565918 2862.733154,1910.025024 2858.050293,1906.653564 
	C2856.272461,1900.665771 2857.323242,1895.184204 2857.333008,1888.914795 
	C2857.506592,1885.792480 2858.685791,1883.745728 2858.900879,1880.600098 
	C2859.119385,1870.119141 2857.713623,1860.408691 2861.533203,1850.409058 
	C2864.144287,1849.343750 2866.031494,1848.617065 2867.967529,1848.446533 
	C2871.584473,1848.128296 2873.697510,1849.638672 2873.057861,1853.639160 
	C2872.695312,1855.907959 2872.613281,1858.294922 2871.816406,1860.406494 
	C2869.653076,1866.137451 2871.780029,1868.928101 2877.724854,1869.680176 
	C2877.811279,1868.337158 2877.946533,1867.032959 2877.971924,1865.726685 
	C2878.202881,1853.849731 2878.176025,1854.191895 2889.834961,1854.916016 
	C2893.990479,1855.174072 2894.064941,1857.194946 2893.947754,1859.869629 
	C2893.471680,1870.714355 2895.201172,1881.636475 2892.451172,1892.423462 
	C2891.655029,1895.547119 2892.134521,1898.995972 2892.049805,1903.194092 
z"
        />
        <path
          fill="#A6A29C"
          opacity="1.000000"
          stroke="none"
          d="
M2862.288574,1849.140625 
	C2862.129395,1859.957642 2862.023682,1870.095947 2861.864014,1881.173950 
	C2853.897217,1883.024780 2845.627930,1885.644897 2839.786133,1876.725342 
	C2840.929688,1874.786255 2841.896729,1873.601318 2843.380859,1872.145752 
	C2846.436768,1869.174072 2850.279297,1869.046875 2853.761475,1866.763672 
	C2857.977783,1864.005127 2856.687500,1861.242920 2854.685059,1857.779785 
	C2854.170898,1855.688354 2854.296387,1854.312012 2855.359131,1852.415405 
	C2855.844482,1844.174072 2856.122070,1836.520142 2855.982422,1828.873901 
	C2855.888184,1823.703125 2858.082031,1821.777954 2863.147461,1821.878052 
	C2874.122070,1822.095093 2885.103516,1821.990601 2897.013428,1821.988281 
	C2897.944824,1825.889282 2897.944824,1829.819458 2897.944824,1835.261108 
	C2903.873047,1830.412842 2909.122803,1834.026123 2915.189941,1833.937500 
	C2915.976074,1835.443848 2915.981445,1836.912231 2915.983887,1839.110107 
	C2908.626465,1840.773560 2901.239258,1844.518066 2893.954102,1839.580688 
	C2890.059814,1845.746704 2888.114014,1839.116577 2885.296387,1838.254883 
	C2878.486084,1836.171997 2871.863770,1838.167236 2865.514893,1839.960693 
	C2862.332031,1840.859863 2863.793701,1845.695435 2862.288574,1849.140625 
z"
        />
        <path
          fill="#C4BDAE"
          opacity="1.000000"
          stroke="none"
          d="
M2907.762207,1824.717285 
	C2903.508545,1826.560059 2901.526855,1824.973755 2901.611084,1820.656006 
	C2901.705811,1815.816162 2901.708008,1810.974487 2901.847168,1805.256592 
	C2902.045410,1801.581055 2903.494385,1800.099976 2906.978271,1799.852295 
	C2908.923096,1807.828003 2905.983154,1815.919678 2907.762207,1824.717285 
z"
        />
        <path
          fill="#C4BDAE"
          opacity="1.000000"
          stroke="none"
          d="
M2865.878418,1791.894043 
	C2865.624023,1792.958618 2865.370361,1793.497559 2865.116699,1794.036499 
	C2863.490234,1792.632690 2863.527832,1791.215332 2865.081787,1789.785400 
	C2865.347656,1790.313110 2865.613525,1790.840820 2865.878418,1791.894043 
z"
        />
        <path
          fill="#C4BDAE"
          opacity="1.000000"
          stroke="none"
          d="
M2864.635986,1780.146484 
	C2864.145508,1778.910767 2864.069336,1778.015625 2865.095215,1777.538452 
	C2865.183105,1777.497559 2865.609131,1777.901367 2865.678467,1778.153442 
	C2865.927979,1779.058350 2865.934570,1779.935547 2864.635986,1780.146484 
z"
        />
        <path
          fill="#C4BDAE"
          opacity="1.000000"
          stroke="none"
          d="
M2893.729980,1790.614258 
	C2893.542725,1791.527832 2893.276855,1792.009521 2893.010986,1792.491211 
	C2892.747803,1792.010620 2892.247314,1791.513672 2892.279053,1791.053223 
	C2892.323242,1790.411255 2892.269287,1788.978760 2893.729980,1790.614258 
z"
        />
        <path
          fill="#E6E1D0"
          opacity="1.000000"
          stroke="none"
          d="
M2979.404785,1859.657959 
	C2982.385742,1860.529419 2985.236816,1860.830811 2985.518555,1864.777100 
	C2984.519775,1867.405762 2983.683105,1869.427246 2985.082520,1872.298462 
	C2984.774902,1875.924316 2983.188965,1877.539917 2980.152344,1877.912109 
	C2977.220459,1878.271484 2974.281250,1878.461426 2971.640381,1877.067505 
	C2970.095459,1876.251831 2970.140625,1874.369141 2970.161133,1872.730835 
	C2970.197266,1869.826782 2970.212402,1866.922485 2970.264160,1863.150269 
	C2972.725830,1860.253662 2975.638428,1859.834106 2979.404785,1859.657959 
z"
        />
        <path
          fill="#05121F"
          opacity="1.000000"
          stroke="none"
          d="
M2690.430908,3696.553223 
	C2689.995361,3693.675049 2690.121338,3691.169434 2688.240967,3689.477295 
	C2680.686035,3682.678955 2675.374512,3673.178955 2664.851807,3669.934082 
	C2662.494385,3669.207275 2660.313232,3668.031006 2660.589844,3665.222900 
	C2660.972656,3661.333984 2664.276367,3662.125977 2666.765137,3662.046631 
	C2671.078125,3661.908691 2675.422119,3662.291748 2679.711670,3661.946045 
	C2690.506592,3661.075928 2697.748291,3666.664795 2703.909668,3675.314941 
	C2703.770508,3676.249756 2703.529541,3676.479980 2703.313477,3676.731689 
	C2701.444092,3678.907715 2696.511230,3676.857666 2696.480713,3681.167969 
	C2696.454102,3684.949219 2699.581055,3686.958496 2702.688721,3688.224121 
	C2708.810059,3690.716797 2714.931152,3693.702393 2721.629883,3693.876953 
	C2729.789795,3694.089600 2736.974121,3697.759766 2744.692383,3699.468994 
	C2746.257568,3699.815430 2747.679932,3700.887207 2749.115723,3701.715576 
	C2751.417969,3703.043701 2752.347656,3705.007812 2751.392334,3707.580078 
	C2750.520508,3709.927246 2748.364014,3710.097900 2746.415283,3709.846924 
	C2741.836670,3709.257568 2736.900635,3708.966309 2732.829590,3707.462646 
	C2725.451904,3704.738037 2718.151367,3705.767334 2710.796631,3705.159668 
	C2703.502930,3704.556641 2696.676758,3701.827148 2690.430908,3696.553223 
z"
        />
        <path
          fill="#935F47"
          opacity="1.000000"
          stroke="none"
          d="
M2526.371094,3745.809082 
	C2525.944580,3746.891602 2525.934082,3747.508301 2525.969238,3748.587158 
	C2527.304199,3755.161377 2522.426025,3757.420898 2518.253418,3760.898926 
	C2516.394287,3762.056641 2515.048828,3762.644775 2513.192139,3763.846680 
	C2511.216309,3764.995850 2509.805908,3765.585449 2507.561523,3766.213379 
	C2504.584717,3766.630371 2502.474609,3766.354004 2499.867676,3764.773926 
	C2497.073975,3760.408936 2497.572266,3757.005127 2501.585938,3753.705322 
	C2514.502441,3750.025146 2523.619629,3738.291504 2522.164795,3727.717041 
	C2520.227539,3713.634766 2520.639160,3713.002441 2533.945801,3709.321045 
	C2535.549072,3708.877441 2537.140137,3708.529541 2539.455078,3709.148926 
	C2542.233887,3717.002930 2533.935059,3721.926514 2534.829834,3729.893555 
	C2533.711182,3733.193848 2532.880615,3735.839600 2531.794189,3739.132080 
	C2530.280518,3741.918701 2528.385254,3743.511475 2526.371094,3745.809082 
z"
        />
        <path
          fill="#051523"
          opacity="1.000000"
          stroke="none"
          d="
M2709.567383,3811.424316 
	C2699.432861,3818.508789 2689.016846,3817.744385 2678.364990,3810.620117 
	C2689.481689,3802.365479 2696.709961,3802.574951 2709.567383,3811.424316 
z"
        />
        <path
          fill="#05121F"
          opacity="1.000000"
          stroke="none"
          d="
M2712.806885,3677.989258 
	C2712.405762,3674.036865 2714.566895,3671.684326 2718.554443,3672.256104 
	C2726.044434,3673.330078 2733.472412,3674.832764 2740.928955,3676.141113 
	C2743.465332,3676.586426 2745.111572,3677.987793 2745.905273,3681.231934 
	C2734.882324,3683.475342 2724.391602,3679.659424 2712.806885,3677.989258 
z"
        />
        <path
          fill="#5E4237"
          opacity="1.000000"
          stroke="none"
          d="
M2535.169678,3730.250244 
	C2529.753906,3722.367188 2536.501221,3717.297119 2539.739990,3710.577637 
	C2539.036377,3708.303711 2538.469482,3706.720947 2539.811035,3704.625000 
	C2541.536621,3704.092529 2542.760742,3703.947754 2544.585205,3703.715088 
	C2544.621582,3713.317139 2539.809326,3721.491943 2535.169678,3730.250244 
z"
        />
        <path
          fill="#5E4237"
          opacity="1.000000"
          stroke="none"
          d="
M2546.301270,3695.384766 
	C2543.065430,3686.716309 2542.729492,3677.515625 2546.721680,3668.039551 
	C2548.369141,3676.775879 2548.594482,3685.818115 2546.301270,3695.384766 
z"
        />
        <path
          fill="#5E4237"
          opacity="1.000000"
          stroke="none"
          d="
M2526.587402,3746.178955 
	C2526.047363,3743.266846 2527.340820,3741.094727 2530.895264,3739.905273 
	C2531.680176,3740.768555 2531.822266,3741.758057 2531.843750,3743.495117 
	C2530.215576,3744.856201 2528.708008,3745.469482 2526.587402,3746.178955 
z"
        />
        <path
          fill="#051523"
          opacity="1.000000"
          stroke="none"
          d="
M2835.694824,3803.579590 
	C2835.124268,3805.311279 2834.077881,3805.933350 2832.298584,3804.430664 
	C2832.865234,3802.699463 2833.906738,3802.050293 2835.694824,3803.579590 
z"
        />
        <path
          fill="#DFD9CC"
          opacity="1.000000"
          stroke="none"
          d="
M2485.463867,3933.425781 
	C2486.089111,3935.885986 2486.625000,3937.795654 2487.470459,3939.557129 
	C2490.906494,3946.717773 2490.950439,3953.527832 2484.363770,3959.659180 
	C2479.858398,3953.420410 2481.231934,3946.025391 2476.883789,3939.648193 
	C2474.533936,3931.546387 2465.718994,3928.146729 2465.397217,3919.573975 
	C2455.425049,3927.698486 2452.506836,3926.965576 2448.684570,3915.177490 
	C2447.143311,3910.424072 2445.151611,3905.976807 2442.430664,3901.062988 
	C2442.228516,3899.942871 2442.202393,3899.487549 2442.295410,3898.362549 
	C2442.764404,3896.845459 2443.314941,3896.159912 2444.702393,3895.855957 
	C2448.553223,3893.322998 2450.966553,3893.036621 2452.512207,3897.851074 
	C2454.406494,3903.751465 2455.045654,3910.165039 2459.209961,3915.581543 
	C2463.141357,3911.427246 2462.078613,3906.098389 2463.352539,3901.505859 
	C2463.961426,3899.309814 2463.745850,3896.904053 2465.447998,3895.040527 
	C2466.437744,3893.957031 2467.675049,3893.376465 2468.933838,3893.979736 
	C2469.565918,3894.282471 2470.228027,3895.603516 2470.080322,3896.293701 
	C2467.405029,3908.770508 2475.479004,3917.605957 2481.026855,3927.924316 
	C2483.575439,3929.219971 2484.852295,3930.738281 2485.463867,3933.425781 
z"
        />
        <path
          fill="#E7E3DA"
          opacity="1.000000"
          stroke="none"
          d="
M2429.927979,3882.647949 
	C2430.538086,3879.436279 2429.183594,3875.821289 2433.905273,3875.850098 
	C2436.687744,3875.866943 2438.987305,3875.917969 2438.511963,3879.868164 
	C2438.210205,3882.374756 2438.658447,3884.971924 2438.755615,3888.387695 
	C2438.962891,3891.349854 2441.445312,3891.933838 2442.005371,3894.629150 
	C2442.268066,3896.353271 2442.485352,3897.330078 2442.894531,3899.051270 
	C2443.638428,3904.534668 2446.517578,3908.376465 2448.187012,3912.696045 
	C2449.511475,3916.122070 2450.362061,3919.140137 2447.396729,3921.793701 
	C2444.080566,3924.761230 2440.712646,3924.105225 2437.214355,3920.524902 
	C2436.580078,3913.187012 2432.062256,3908.333496 2429.550781,3901.213867 
	C2428.360352,3908.840088 2437.174561,3910.899414 2435.095947,3918.023682 
	C2427.516846,3924.755371 2424.287109,3919.013184 2421.150635,3913.814941 
	C2418.577637,3909.550293 2419.536621,3904.045410 2416.178223,3899.290771 
	C2414.950684,3898.185059 2414.218262,3897.584473 2412.899414,3896.566406 
	C2411.919189,3895.861328 2411.535400,3895.562012 2410.614258,3894.757568 
	C2407.797852,3891.469238 2405.937256,3888.551758 2407.443115,3884.082031 
	C2408.797363,3882.983887 2409.764648,3882.837646 2411.434570,3883.404297 
	C2412.330811,3884.405762 2412.696289,3884.955322 2413.587402,3885.713867 
	C2422.230469,3888.904053 2426.974121,3888.094727 2429.927979,3882.647949 
z"
        />
        <path
          fill="#A9ACA9"
          opacity="1.000000"
          stroke="none"
          d="
M2528.448242,3990.550293 
	C2515.511230,3989.613037 2503.476074,3984.347412 2490.488281,3984.834229 
	C2489.864746,3984.857178 2488.875488,3984.699463 2488.648682,3984.294434 
	C2485.003662,3977.778809 2478.588623,3981.018555 2473.456299,3980.083008 
	C2467.451416,3978.988525 2467.386230,3979.345947 2469.388184,3972.173584 
	C2484.042480,3975.742676 2498.074219,3979.701416 2512.666992,3980.857178 
	C2517.659912,3981.252441 2521.955566,3986.730713 2527.772461,3982.898438 
	C2528.155029,3982.645996 2529.232910,3987.421387 2528.448242,3990.550293 
z"
        />
        <path
          fill="#C7C0B1"
          opacity="1.000000"
          stroke="none"
          d="
M2401.729980,3842.148926 
	C2402.221191,3846.369629 2397.155029,3849.706299 2400.035400,3854.973633 
	C2402.771484,3857.894043 2404.784180,3860.137695 2405.047119,3863.921631 
	C2405.271240,3867.147217 2406.023926,3862.683350 2407.282227,3863.945312 
	C2408.437988,3865.102783 2409.135010,3865.219727 2409.916992,3864.326904 
	C2413.088867,3860.707275 2414.392090,3862.989746 2415.496582,3866.649658 
	C2415.451660,3868.388672 2414.918457,3869.496582 2415.626465,3871.000488 
	C2417.603271,3873.515625 2418.370117,3875.662109 2414.870605,3877.653320 
	C2409.334717,3879.297363 2404.923340,3876.670410 2399.550781,3876.174072 
	C2394.787354,3874.140625 2393.091064,3869.074951 2387.573730,3868.090820 
	C2384.539062,3866.462402 2382.864258,3864.677490 2383.009277,3861.069092 
	C2388.950684,3857.443604 2392.992676,3853.400879 2393.966309,3846.554443 
	C2394.459961,3843.084473 2397.024902,3840.893799 2401.478516,3841.765869 
	C2401.981689,3842.268066 2401.828613,3842.075195 2401.729980,3842.148926 
z"
        />
        <path
          fill="#959693"
          opacity="1.000000"
          stroke="none"
          d="
M2440.985840,3896.538330 
	C2439.691650,3894.355713 2439.199707,3892.519531 2438.724121,3889.965332 
	C2442.201904,3889.521973 2443.874512,3886.674561 2446.211670,3884.978271 
	C2453.095459,3879.982666 2460.793213,3878.983643 2468.918701,3880.612061 
	C2472.964600,3881.422852 2473.345947,3881.978516 2476.038574,3888.110107 
	C2469.579834,3882.587158 2462.706787,3884.380127 2455.546875,3885.604980 
	C2449.700195,3886.605225 2445.722656,3888.873047 2444.606445,3895.305420 
	C2445.334229,3896.072266 2445.287842,3896.114258 2445.196777,3896.151123 
	C2444.093262,3896.864990 2442.987305,3897.097656 2440.985840,3896.538330 
z"
        />
        <path
          fill="#2C2520"
          opacity="1.000000"
          stroke="none"
          d="
M2429.593750,3881.912109 
	C2432.638672,3884.301270 2431.731201,3887.532715 2432.245605,3890.374512 
	C2432.542236,3892.014404 2432.575439,3893.815674 2430.199463,3894.004883 
	C2428.545410,3894.136230 2426.670898,3894.189209 2426.489258,3891.911377 
	C2426.097412,3886.997803 2422.340576,3888.821045 2421.009033,3889.990723 
	C2416.597656,3893.866211 2415.452148,3890.756348 2414.032959,3886.720703 
	C2414.574463,3885.947510 2415.156494,3885.891846 2416.612549,3885.760498 
	C2419.363525,3882.405518 2421.811768,3880.308350 2426.526611,3881.794434 
	C2427.789795,3881.774902 2428.442383,3881.730469 2429.593750,3881.912109 
z"
        />
        <path
          fill="#83837F"
          opacity="1.000000"
          stroke="none"
          d="
M2407.733398,3863.294678 
	C2405.974121,3864.800537 2406.351318,3866.857422 2405.329102,3868.228760 
	C2401.866943,3865.333008 2402.149658,3860.808105 2400.293213,3856.487305 
	C2401.648682,3856.562988 2403.261719,3857.209229 2404.862793,3856.390625 
	C2410.947998,3853.278809 2416.359863,3854.859863 2421.897949,3859.163086 
	C2422.335938,3860.314941 2422.397949,3860.871582 2421.620850,3861.385742 
	C2417.444824,3858.843506 2413.837891,3857.342529 2409.636230,3858.755859 
	C2407.965576,3859.317871 2406.796631,3860.432373 2407.733398,3863.294678 
z"
        />
        <path
          fill="#2C2520"
          opacity="1.000000"
          stroke="none"
          d="
M2397.575684,3874.722656 
	C2403.299561,3872.711670 2408.040039,3875.385498 2413.588867,3877.138672 
	C2413.499756,3879.442627 2412.841553,3881.074951 2412.134766,3883.241699 
	C2411.287109,3883.849609 2410.488281,3883.923340 2409.085938,3884.068359 
	C2408.115479,3884.398438 2407.748291,3884.657715 2406.717773,3885.040283 
	C2405.166992,3885.009766 2404.353027,3884.681396 2403.172852,3883.673340 
	C2401.407959,3880.190186 2396.751465,3879.485840 2397.575684,3874.722656 
z"
        />
        <path
          fill="#BAB4A7"
          opacity="1.000000"
          stroke="none"
          d="
M2508.243408,3931.016357 
	C2503.029785,3929.947754 2498.344971,3930.372803 2492.923340,3931.145508 
	C2491.509033,3930.765137 2491.176025,3930.000732 2491.755371,3928.650879 
	C2501.235840,3927.242676 2510.208984,3926.233643 2518.922363,3931.063232 
	C2516.861328,3933.639893 2515.229980,3932.425537 2512.843750,3932.263672 
	C2510.854004,3932.041504 2509.769043,3931.772949 2508.243408,3931.016357 
z"
        />
        <path
          fill="#423C3E"
          opacity="1.000000"
          stroke="none"
          d="
M2512.556885,3920.056396 
	C2507.624023,3923.872314 2507.624023,3923.872314 2498.797852,3923.131348 
	C2502.329346,3919.555664 2506.550537,3918.983887 2511.272461,3917.952148 
	C2512.402100,3918.480225 2512.772217,3918.937744 2512.556885,3920.056396 
z"
        />
        <path
          fill="#BAB4A7"
          opacity="1.000000"
          stroke="none"
          d="
M2477.611572,3905.509766 
	C2471.786377,3898.174316 2472.006348,3897.145020 2480.423828,3893.114502 
	C2479.377441,3896.548340 2475.770020,3899.578857 2479.923340,3902.510498 
	C2480.909424,3903.206543 2479.598389,3904.850098 2477.611572,3905.509766 
z"
        />
        <path
          fill="#423C3E"
          opacity="1.000000"
          stroke="none"
          d="
M2491.764160,3927.937988 
	C2492.324951,3928.936768 2492.384033,3929.644287 2492.314453,3930.871094 
	C2491.071045,3933.482178 2489.162842,3934.078857 2486.194092,3934.098633 
	C2483.714600,3932.933594 2482.430908,3931.482422 2481.627930,3928.972168 
	C2482.153076,3928.107910 2482.764160,3927.834717 2484.269043,3927.103271 
	C2485.000732,3926.949463 2484.839111,3927.254395 2484.770508,3927.050293 
	C2486.390869,3930.413330 2489.458252,3925.375000 2491.764160,3927.937988 
z"
        />
        <path
          fill="#83837F"
          opacity="1.000000"
          stroke="none"
          d="
M2424.196533,3864.325928 
	C2427.297607,3865.945068 2427.461914,3868.669189 2424.890381,3871.033936 
	C2423.134521,3872.648438 2421.242920,3871.031982 2419.586182,3870.116943 
	C2418.341797,3869.430176 2417.607422,3868.072998 2418.116211,3866.866943 
	C2419.084473,3864.569580 2420.672852,3865.975586 2422.093262,3866.577881 
	C2423.528076,3867.186768 2423.443359,3865.903076 2424.196533,3864.325928 
z"
        />
        <path
          fill="#B6BCC1"
          opacity="1.000000"
          stroke="none"
          d="
M2426.464355,3881.644531 
	C2424.394531,3883.408203 2420.271240,3882.802490 2419.446045,3887.137695 
	C2419.424561,3887.250000 2418.974854,3887.415283 2418.838135,3887.342529 
	C2418.581299,3887.206299 2418.406738,3886.914551 2417.842285,3886.186279 
	C2418.543213,3879.083984 2419.099121,3878.791260 2426.464355,3881.644531 
z"
        />
        <path
          fill="#A6A29C"
          opacity="1.000000"
          stroke="none"
          d="
M2470.690430,3968.273438 
	C2469.247314,3967.337891 2468.539307,3966.495361 2467.583984,3965.147949 
	C2467.529053,3964.194824 2467.720947,3963.746582 2468.492188,3962.847168 
	C2472.311768,3961.030273 2475.667236,3960.598389 2479.783447,3961.523926 
	C2480.586914,3962.349365 2480.751465,3962.810059 2480.486816,3963.865723 
	C2478.278809,3968.190918 2474.941406,3968.476318 2470.690430,3968.273438 
z"
        />
        <path
          fill="#A9ACA9"
          opacity="1.000000"
          stroke="none"
          d="
M2512.749512,3920.596680 
	C2511.929688,3920.266846 2511.887939,3919.816650 2511.939209,3918.694336 
	C2512.315430,3917.880371 2512.625977,3917.775391 2512.877930,3917.590576 
	C2514.250000,3916.582275 2514.684082,3913.864258 2517.175293,3915.009033 
	C2517.761475,3915.278564 2518.162598,3916.567383 2518.162354,3917.387207 
	C2518.161621,3920.599121 2515.894531,3920.667969 2512.749512,3920.596680 
z"
        />
        <path
          fill="#A6A29C"
          opacity="1.000000"
          stroke="none"
          d="
M2519.656006,3893.165039 
	C2520.353027,3891.869629 2520.270752,3888.998047 2521.552734,3890.990967 
	C2523.046631,3893.312256 2522.770020,3896.533691 2521.596436,3899.525635 
	C2519.297119,3897.849365 2519.643066,3895.800537 2519.656006,3893.165039 
z"
        />
        <path
          fill="#817A79"
          opacity="1.000000"
          stroke="none"
          d="
M2491.453613,3908.318848 
	C2492.371094,3911.087158 2491.166748,3913.336182 2489.033447,3915.239502 
	C2486.461914,3912.187500 2489.427490,3910.498779 2491.453613,3908.318848 
z"
        />
        <path
          fill="#817A79"
          opacity="1.000000"
          stroke="none"
          d="
M2491.924805,3907.926514 
	C2491.731445,3905.249512 2491.663574,3902.679443 2491.501465,3899.338867 
	C2491.408936,3897.975098 2491.410156,3897.381836 2492.299316,3896.843994 
	C2494.703613,3900.328613 2494.387695,3903.808838 2492.892578,3907.772949 
	C2492.307861,3908.239990 2492.050781,3908.033447 2491.924805,3907.926514 
z"
        />
        <path
          fill="#DFD9CC"
          opacity="1.000000"
          stroke="none"
          d="
M2480.669922,3964.296387 
	C2480.154053,3964.112549 2480.250732,3963.763916 2480.252441,3962.881836 
	C2478.892822,3960.568848 2480.135254,3959.809814 2482.216309,3959.075928 
	C2482.950684,3959.058594 2483.615234,3959.656494 2483.947266,3959.956055 
	C2484.043945,3962.137939 2482.485352,3962.997559 2480.669922,3964.296387 
z"
        />
        <path
          fill="#817A79"
          opacity="1.000000"
          stroke="none"
          d="
M2487.472900,3892.867432 
	C2490.327881,3892.305420 2491.395996,3894.147217 2492.891602,3896.362549 
	C2492.894775,3897.181641 2492.602295,3897.463867 2491.858643,3898.157227 
	C2490.430908,3897.726562 2489.454346,3896.885010 2488.195801,3895.642090 
	C2487.606445,3894.710938 2487.299316,3894.180664 2487.472900,3892.867432 
z"
        />
        <path
          fill="#423C3E"
          opacity="1.000000"
          stroke="none"
          d="
M2507.827637,3931.305664 
	C2508.869141,3930.964111 2510.014404,3931.127441 2511.570801,3931.726562 
	C2510.481201,3933.776611 2509.138184,3933.579834 2507.827637,3931.305664 
z"
        />
        <path
          fill="#BAB4A7"
          opacity="1.000000"
          stroke="none"
          d="
M2487.081055,3891.957520 
	C2487.970215,3892.610352 2487.986816,3893.136230 2487.958496,3894.451660 
	C2487.264648,3895.214600 2486.615723,3895.182861 2485.966553,3895.162109 
	C2484.450928,3895.114014 2482.015381,3895.891357 2481.903564,3893.924072 
	C2481.747314,3891.164795 2484.541016,3892.156006 2487.081055,3891.957520 
z"
        />
        <path
          fill="#BAB4A7"
          opacity="1.000000"
          stroke="none"
          d="
M2523.916016,3931.871582 
	C2525.970459,3933.016357 2526.234131,3934.721680 2525.032715,3935.060791 
	C2523.286865,3935.553711 2520.954834,3934.953613 2520.134766,3933.174072 
	C2519.759033,3932.359131 2521.603027,3931.479004 2523.916016,3931.871582 
z"
        />
        <path
          fill="#83837F"
          opacity="1.000000"
          stroke="none"
          d="
M2421.874512,3860.129395 
	C2423.502441,3860.215820 2424.040527,3861.234619 2424.530273,3862.954590 
	C2424.647949,3863.520508 2424.283936,3863.780029 2424.107178,3863.917480 
	C2423.102051,3863.418457 2422.273682,3862.781494 2421.113525,3861.743652 
	C2421.012939,3861.062256 2421.243896,3860.781494 2421.874512,3860.129395 
z"
        />
        <path
          fill="#2C2520"
          opacity="1.000000"
          stroke="none"
          d="
M2416.087402,3870.620117 
	C2414.000977,3870.901123 2413.984863,3869.542480 2415.068115,3867.565918 
	C2415.714600,3868.129639 2415.881836,3869.031494 2416.087402,3870.620117 
z"
        />
        <path
          fill="#DFD9CC"
          opacity="1.000000"
          stroke="none"
          d="
M2484.904297,3926.581787 
	C2484.863525,3926.541016 2484.948975,3926.771973 2485.055664,3926.708496 
	C2485.162354,3926.644775 2484.944824,3926.622559 2484.904297,3926.581787 
z"
        />
        <path
          fill="#704A3A"
          opacity="1.000000"
          stroke="none"
          d="
M2412.677246,3817.932373 
	C2411.028320,3817.830078 2410.000488,3817.771729 2409.147705,3817.354980 
	C2402.457031,3814.085449 2402.392334,3814.021729 2398.948975,3820.061768 
	C2395.893555,3825.421387 2391.917725,3830.294922 2390.107910,3837.070312 
	C2386.834473,3844.929932 2380.538330,3848.177734 2373.000977,3850.989014 
	C2371.807129,3851.291748 2371.311523,3851.347168 2370.070312,3851.290771 
	C2365.251953,3849.127686 2364.090332,3845.287842 2363.421875,3840.529785 
	C2364.342285,3839.109863 2365.173828,3838.591797 2366.841797,3838.159424 
	C2379.415039,3840.459961 2379.553223,3829.912354 2383.803955,3822.979248 
	C2386.314941,3818.298096 2390.231445,3815.810059 2393.649902,3811.945312 
	C2394.314941,3811.037354 2394.590820,3810.680176 2395.308350,3809.809570 
	C2402.632080,3801.486572 2404.888184,3791.971924 2406.012451,3781.320312 
	C2407.368408,3777.749512 2409.747559,3775.610840 2411.512695,3772.307617 
	C2412.317139,3770.541260 2412.964355,3769.405273 2413.192871,3767.424805 
	C2413.311523,3766.305176 2413.364258,3765.852783 2413.546387,3764.719727 
	C2416.677490,3757.175781 2420.518799,3750.612305 2421.323730,3742.198242 
	C2421.711182,3740.514648 2422.108154,3739.612305 2423.176514,3738.244629 
	C2424.704346,3737.501709 2425.631836,3737.644531 2426.937988,3738.779053 
	C2430.821289,3746.750977 2426.380371,3754.019775 2426.802979,3762.202148 
	C2427.402100,3759.050781 2428.255371,3756.940918 2430.921631,3755.210938 
	C2432.079102,3754.968506 2432.565918,3754.981201 2433.725098,3755.305664 
	C2435.436035,3756.802002 2435.840088,3758.226318 2435.947021,3760.517090 
	C2435.767334,3762.777588 2435.184326,3764.181641 2434.825684,3766.393066 
	C2433.335693,3773.804443 2431.912109,3780.474121 2428.701416,3787.359375 
	C2425.910889,3794.086914 2422.748779,3799.877930 2420.453125,3806.807129 
	C2419.753906,3808.794678 2419.089355,3810.036621 2418.322754,3812.001465 
	C2417.103516,3814.925293 2415.625977,3816.715820 2412.677246,3817.932373 
z"
        />
        <path
          fill="#0C0B0A"
          opacity="1.000000"
          stroke="none"
          d="
M2372.376953,3850.488281 
	C2372.471680,3849.284912 2372.675537,3848.347412 2373.120361,3848.214355 
	C2379.326660,3846.353760 2383.255859,3841.099854 2389.301758,3837.860596 
	C2393.795898,3838.834229 2397.570801,3839.899170 2401.663818,3841.616211 
	C2398.248535,3843.347900 2395.348389,3844.976074 2395.815674,3849.701660 
	C2396.034180,3851.907959 2395.635498,3854.040771 2394.802246,3856.344727 
	C2392.951904,3861.463623 2390.107666,3863.069824 2384.504883,3860.773682 
	C2378.789062,3858.899414 2376.066406,3854.556396 2372.376953,3850.488281 
z"
        />
        <path
          fill="#E7E3DA"
          opacity="1.000000"
          stroke="none"
          d="
M2477.197510,3787.688477 
	C2476.382568,3786.398193 2476.338135,3785.312988 2476.173340,3783.694092 
	C2476.413818,3781.786865 2477.022217,3780.484375 2477.694824,3778.528809 
	C2478.161621,3777.991455 2478.027588,3778.031006 2478.065674,3778.089844 
	C2482.320801,3771.100586 2487.365234,3776.516357 2492.772705,3777.485352 
	C2493.855225,3778.014893 2494.261963,3778.278564 2495.149414,3779.087891 
	C2496.996582,3780.786865 2497.831055,3782.152588 2496.291992,3783.648438 
	C2494.531006,3785.360107 2493.886475,3787.383789 2493.875977,3790.533691 
	C2488.294434,3791.318359 2483.223389,3789.181885 2477.197510,3787.688477 
z"
        />
        <path
          fill="#BA815C"
          opacity="1.000000"
          stroke="none"
          d="
M2412.210449,3818.693848 
	C2413.185547,3816.442383 2414.395752,3814.830322 2415.906250,3812.693359 
	C2417.496826,3811.223877 2418.452393,3811.900146 2419.551025,3813.407471 
	C2420.781250,3816.542480 2423.189697,3817.581543 2425.651123,3819.484375 
	C2427.419434,3821.496338 2429.048584,3822.820068 2429.991455,3825.392578 
	C2429.425781,3827.455566 2428.733887,3828.912598 2427.934570,3830.953613 
	C2418.960449,3832.182861 2415.866211,3825.552734 2412.210449,3818.693848 
z"
        />
        <path
          fill="#5A605E"
          opacity="1.000000"
          stroke="none"
          d="
M2496.186523,3778.974609 
	C2495.630127,3779.633057 2494.650879,3779.724121 2494.177734,3779.589355 
	C2490.674805,3774.898193 2493.258545,3771.461426 2496.576172,3767.560791 
	C2500.259766,3765.140381 2504.157471,3766.922119 2507.779541,3764.398926 
	C2509.137939,3763.420410 2509.935547,3762.917725 2511.152832,3762.199707 
	C2512.473633,3761.296387 2513.489258,3760.997559 2515.422119,3761.251221 
	C2520.284668,3764.398682 2520.838379,3768.602539 2520.383545,3773.992920 
	C2517.610840,3778.580078 2513.503174,3777.991455 2509.532471,3778.112549 
	C2505.269043,3778.242432 2500.973389,3776.495850 2496.186523,3778.974609 
z"
        />
        <path
          fill="#1B1E22"
          opacity="1.000000"
          stroke="none"
          d="
M2520.932617,3774.451660 
	C2516.767334,3771.878418 2518.007812,3767.151855 2516.202393,3762.745117 
	C2515.670654,3761.667480 2515.246338,3761.344727 2515.498535,3760.595215 
	C2519.221680,3756.885254 2522.268311,3753.601807 2525.655273,3749.683838 
	C2529.346680,3752.559326 2527.166260,3756.260986 2526.475830,3759.975830 
	C2525.702148,3764.139160 2524.342285,3768.061035 2522.293945,3772.473145 
	C2521.897705,3773.447754 2521.659424,3773.772217 2520.932617,3774.451660 
z"
        />
        <path
          fill="#A9AEB3"
          opacity="1.000000"
          stroke="none"
          d="
M655.331177,1679.954468 
	C656.541626,1682.906982 655.604614,1686.021729 657.365906,1689.488037 
	C659.325867,1698.183594 654.952515,1706.260010 657.677185,1715.220703 
	C657.655273,1730.064941 657.976074,1744.158325 656.619019,1759.044678 
	C652.174683,1760.262573 649.950317,1762.593262 649.974365,1767.016357 
	C649.993652,1770.559570 649.978271,1774.102905 648.980591,1777.643433 
	C649.614990,1759.444580 644.943359,1741.585693 645.996643,1723.417725 
	C646.149475,1720.781494 645.824097,1718.085205 648.024536,1715.990356 
	C649.621033,1714.470825 649.565002,1712.638672 649.235901,1710.510742 
	C647.877563,1701.729126 649.610168,1693.008057 650.062012,1684.259399 
	C650.210144,1681.391479 651.846375,1680.168335 655.331177,1679.954468 
z"
        />
        <path
          fill="#687073"
          opacity="1.000000"
          stroke="none"
          d="
M535.283081,1788.818726 
	C539.240967,1787.389404 540.671631,1785.773560 537.379395,1783.136597 
	C536.249390,1782.231567 534.327515,1782.260742 532.745789,1781.989746 
	C531.190063,1781.722900 529.057068,1781.862915 529.269043,1779.693237 
	C529.445862,1777.883179 531.900635,1778.115967 533.539734,1776.614624 
	C544.453125,1776.635742 554.804138,1775.192993 565.190430,1774.114990 
	C569.011597,1773.718506 573.027100,1773.199829 574.718933,1778.489380 
	C576.042236,1782.626465 581.345276,1779.878540 583.791077,1783.320557 
	C586.278259,1786.312378 588.712646,1788.691162 590.800781,1792.301758 
	C590.024780,1799.344971 585.674316,1798.219849 580.735107,1797.051025 
	C570.831909,1789.143921 559.220825,1789.742310 548.423645,1787.604370 
	C547.591919,1787.439697 546.853394,1788.633179 546.134705,1790.214355 
	C543.456482,1794.730835 543.475830,1801.684937 535.224548,1797.993164 
	C533.336792,1794.744385 533.330872,1792.105347 535.283081,1788.818726 
z"
        />
        <path
          fill="#595B54"
          opacity="1.000000"
          stroke="none"
          d="
M617.968506,1797.235352 
	C617.373718,1800.212769 616.750183,1802.426392 616.046326,1805.329834 
	C614.333923,1809.138794 608.782898,1809.090332 609.297852,1814.754883 
	C601.830688,1822.564087 598.769836,1822.196655 592.798279,1812.810547 
	C591.312988,1808.083862 592.400574,1804.254517 593.778931,1799.746338 
	C594.776917,1797.278198 596.115173,1795.885986 597.556885,1793.713257 
	C598.242981,1792.757202 598.557678,1792.403442 599.470459,1791.666016 
	C600.540405,1791.243408 601.012085,1791.204590 601.921997,1791.724365 
	C604.332153,1792.147095 606.304138,1792.011353 609.018921,1791.848633 
	C614.030884,1790.657715 616.636047,1792.420410 617.968506,1797.235352 
z"
        />
        <path
          fill="#5A605E"
          opacity="1.000000"
          stroke="none"
          d="
M618.615967,1797.715576 
	C615.759033,1796.001831 613.930176,1793.490234 610.364380,1792.112793 
	C614.263062,1782.537964 621.610535,1786.182007 629.259583,1787.924194 
	C628.244812,1793.352905 623.343811,1794.932007 618.615967,1797.715576 
z"
        />
        <path
          fill="#D7D5CF"
          opacity="1.000000"
          stroke="none"
          d="
M602.200439,1791.499756 
	C601.950195,1792.522827 601.540222,1792.762817 600.535156,1793.394653 
	C599.523560,1793.887085 599.106934,1793.987427 598.070374,1794.270142 
	C597.020386,1794.501587 596.590332,1794.550659 595.509033,1794.588257 
	C593.545959,1794.439453 592.302490,1794.087280 590.615601,1793.057373 
	C586.247437,1791.765869 582.998535,1790.309570 583.729736,1784.661255 
	C588.811340,1783.596558 593.553345,1784.251221 598.234436,1785.839722 
	C600.761230,1786.697144 601.996521,1788.132812 602.200439,1791.499756 
z"
        />
        <path
          fill="#4E585F"
          opacity="1.000000"
          stroke="none"
          d="
M658.810669,1771.382080 
	C659.405884,1775.265747 661.946777,1779.941040 656.884155,1783.827148 
	C656.678040,1779.937012 654.519226,1775.391357 658.810669,1771.382080 
z"
        />
        <path
          fill="#374549"
          opacity="1.000000"
          stroke="none"
          d="
M832.327087,1896.376709 
	C829.086975,1896.813599 826.404541,1899.595093 823.530212,1897.038696 
	C820.924927,1894.721436 821.882629,1891.519165 822.817932,1888.659912 
	C825.044373,1881.853516 823.691223,1874.828735 824.152954,1867.010986 
	C825.191772,1858.271729 830.703491,1861.666992 834.764893,1862.125488 
	C840.241333,1862.743896 844.386230,1860.546143 842.053040,1855.305298 
	C838.612427,1847.576782 833.495300,1840.404785 823.789795,1837.938965 
	C821.820496,1838.518799 820.484436,1838.827881 818.512207,1839.412354 
	C815.050415,1840.022339 812.430542,1839.178101 809.148193,1838.334229 
	C806.450989,1837.414185 804.369446,1837.004639 801.543030,1837.800537 
	C799.490295,1837.936890 798.173950,1837.671509 796.486328,1836.487793 
	C795.911560,1833.904053 795.023438,1832.174927 793.740906,1830.575195 
	C791.582703,1827.883301 792.633972,1825.203491 794.853760,1823.623535 
	C797.655945,1821.629272 797.455444,1825.361816 798.753601,1826.361938 
	C801.700745,1828.631836 804.908752,1825.306519 808.273804,1826.855103 
	C812.135864,1828.632446 813.393555,1825.189941 814.239258,1822.203247 
	C815.544983,1817.592041 822.876282,1818.234131 822.758911,1812.125244 
	C822.729065,1810.576050 824.919250,1811.000366 825.586060,1812.363892 
	C826.552368,1814.339966 827.543518,1815.843262 830.063599,1814.962402 
	C832.809875,1814.002563 834.391174,1815.470093 835.818726,1817.566650 
	C836.731079,1818.906616 837.759888,1820.290771 839.046326,1821.231323 
	C845.130493,1825.680786 846.066528,1825.625122 852.388733,1821.193848 
	C854.554932,1823.316284 851.280945,1825.593140 852.919434,1828.245361 
	C856.806641,1827.248169 860.773132,1830.291260 865.505798,1827.338257 
	C868.584412,1825.417480 872.081665,1829.047607 875.557678,1831.732544 
	C871.938660,1834.693237 866.302368,1834.575439 865.027283,1839.906006 
	C864.313721,1842.889038 863.916138,1845.300903 867.688293,1845.896606 
	C872.088562,1846.591064 874.860413,1848.778564 876.058838,1854.249878 
	C872.090210,1854.249878 868.649475,1854.173096 865.214966,1854.286133 
	C863.769592,1854.333618 862.686035,1855.339844 862.578979,1856.781128 
	C862.474915,1858.182739 863.226868,1859.356079 864.746399,1859.653931 
	C871.162659,1860.911865 874.563904,1865.719116 877.945679,1871.388672 
	C878.298035,1872.459473 878.544006,1872.829346 878.555908,1873.825928 
	C877.979675,1874.741699 877.637512,1875.030518 876.683533,1875.600464 
	C875.207581,1876.132324 874.327515,1876.302612 872.764160,1876.458008 
	C871.646851,1876.515259 871.199097,1876.518433 870.081177,1876.479004 
	C868.524292,1876.327515 867.645203,1876.174438 866.126038,1875.823730 
	C864.207397,1875.318848 862.975708,1874.817017 860.990906,1874.606201 
	C847.951538,1875.301636 846.125244,1876.909058 844.870544,1888.328857 
	C844.586792,1890.911011 844.507507,1893.517944 842.472412,1896.272461 
	C841.014221,1897.124512 840.054749,1897.228027 838.380615,1896.824219 
	C836.092407,1895.014038 836.112061,1892.985352 835.829224,1890.312500 
	C834.807800,1887.188477 837.039429,1884.786499 835.251953,1882.445557 
	C832.182983,1883.634521 832.816895,1885.367065 833.835327,1887.180298 
	C835.276367,1889.746094 836.406616,1892.362305 834.658569,1895.900269 
	C833.765137,1896.574585 833.314270,1896.703125 832.327087,1896.376709 
z"
        />
        <path
          fill="#B9A795"
          opacity="1.000000"
          stroke="none"
          d="
M969.591431,1780.361572 
	C973.415222,1781.660278 976.872437,1782.009888 981.161377,1781.953613 
	C982.317810,1783.101807 982.713745,1784.221558 983.600098,1785.696289 
	C989.402771,1792.070679 986.406799,1799.305664 986.908813,1806.002075 
	C987.277405,1810.918091 987.068970,1815.880493 986.797119,1821.731934 
	C986.491272,1825.111450 985.653381,1827.284302 982.882812,1829.267822 
	C981.101318,1830.009399 980.041321,1830.192871 978.206421,1830.138428 
	C971.360291,1827.887207 966.016052,1822.581055 957.999756,1825.397217 
	C953.333313,1823.915894 951.019775,1820.992065 949.435791,1817.204346 
	C948.353455,1814.615967 947.760681,1811.396729 943.284973,1811.146729 
	C932.192993,1813.646484 922.549377,1820.258545 910.706726,1816.034302 
	C910.288086,1814.056519 910.727600,1812.524658 910.205872,1811.466675 
	C907.560364,1806.102417 908.476379,1800.836914 910.149658,1794.714111 
	C910.637756,1790.859741 912.132874,1788.411499 915.155396,1786.243286 
	C922.956787,1784.314819 930.426575,1783.873779 938.352173,1781.841919 
	C942.901733,1781.923340 946.697083,1781.998291 951.308350,1782.167236 
	C956.125122,1789.066162 960.685486,1784.965088 965.610352,1782.445068 
	C967.070374,1781.467651 968.059998,1780.974243 969.591431,1780.361572 
z"
        />
        <path
          fill="#595B54"
          opacity="1.000000"
          stroke="none"
          d="
M910.195862,1816.201172 
	C919.500366,1813.524902 929.244385,1813.857544 937.572205,1808.381104 
	C938.809998,1807.567383 940.766541,1807.846680 943.178345,1807.750488 
	C948.929565,1814.446777 951.787109,1821.644287 950.435059,1830.799561 
	C950.058228,1832.009644 949.861206,1832.470581 949.232178,1833.529175 
	C943.744690,1836.214966 938.743530,1839.521851 933.651184,1835.084473 
	C936.732666,1841.273682 942.589111,1836.472046 947.752075,1837.953735 
	C951.146362,1838.631226 953.882080,1839.112915 957.057861,1840.462769 
	C959.484741,1841.738281 959.401794,1845.702271 963.522949,1843.904053 
	C972.837585,1843.069336 981.487000,1839.843262 990.781616,1844.005981 
	C995.602905,1851.147583 989.868530,1853.244873 985.800659,1853.556519 
	C973.457336,1854.502075 964.184814,1859.977173 956.720886,1869.529785 
	C955.042847,1871.677490 950.027283,1874.221069 949.583435,1870.908447 
	C948.608459,1863.631592 942.407166,1867.036255 939.089478,1863.991211 
	C936.813843,1863.320068 935.707825,1864.497559 933.971924,1865.783203 
	C930.165955,1867.838379 926.473328,1868.200684 922.996277,1869.257202 
	C915.696594,1871.475220 914.264038,1870.790894 908.620667,1864.406250 
	C900.883301,1875.029297 899.242615,1875.105591 892.475281,1864.626099 
	C891.713440,1862.832031 891.420105,1861.545898 891.109863,1860.263794 
	C888.880249,1851.048706 889.578674,1850.222290 898.357971,1852.418579 
	C900.653259,1852.992676 902.965027,1854.201294 904.765259,1852.030884 
	C908.296448,1847.773804 911.891541,1848.796997 916.154053,1850.873779 
	C920.104004,1852.798218 924.530029,1852.813477 928.551819,1850.738403 
	C930.089355,1849.944946 928.640015,1839.490479 926.516296,1836.814697 
	C925.113647,1835.047363 923.167725,1833.872559 922.229065,1831.530029 
	C919.111084,1823.749390 918.992432,1823.796875 910.584045,1825.861572 
	C909.488708,1822.968872 910.155334,1819.978271 910.195862,1816.201172 
z"
        />
        <path
          fill="#313E41"
          opacity="1.000000"
          stroke="none"
          d="
M624.729004,1875.977783 
	C622.962219,1876.238770 620.754578,1876.473877 620.566101,1875.963257 
	C618.391907,1870.075562 611.960266,1867.584106 609.473755,1861.226318 
	C608.617249,1859.086060 608.169739,1857.596802 608.752014,1855.238647 
	C615.609802,1852.728882 621.965149,1848.516724 629.502197,1853.413696 
	C629.957092,1854.022705 629.972656,1855.011597 629.988770,1855.505859 
	C632.904480,1857.535034 635.648315,1860.544434 639.562439,1856.515259 
	C641.934998,1852.583496 645.091858,1856.718872 647.633667,1854.434814 
	C649.758545,1852.724854 649.895996,1850.458252 651.600586,1848.470215 
	C653.127747,1848.395020 654.215393,1848.739014 655.653931,1849.546387 
	C657.517761,1851.524414 658.885559,1853.935669 661.554321,1850.411133 
	C663.043762,1848.089478 666.208862,1847.331909 665.406189,1844.489014 
	C664.579285,1841.560181 661.855347,1842.122803 659.641235,1842.061035 
	C656.359802,1841.969238 653.074768,1842.006104 648.901733,1841.984375 
	C644.953735,1837.739258 647.040161,1835.230591 651.146362,1834.209839 
	C654.711853,1833.323486 657.469116,1831.138062 661.317505,1829.798950 
	C662.335938,1830.045898 662.670776,1830.193237 662.988586,1830.162720 
	C666.504700,1829.825684 665.288879,1836.701538 669.424988,1834.694946 
	C673.516663,1832.709961 670.978577,1828.871826 670.562744,1825.136719 
	C675.411377,1825.856567 680.324585,1822.840088 683.936768,1828.338867 
	C685.286560,1830.393799 687.689331,1829.318359 689.109680,1827.752930 
	C691.324280,1825.312134 694.816345,1824.217041 696.137512,1820.841797 
	C697.200745,1818.125244 699.437134,1817.552490 701.857971,1818.628418 
	C704.217590,1819.677246 703.798096,1821.809448 703.039368,1823.762817 
	C700.958740,1829.119629 698.943970,1834.455200 705.974792,1839.771973 
	C696.251099,1838.958740 696.175293,1838.957275 696.038635,1845.817017 
	C695.912537,1852.146118 695.973083,1858.478760 695.891968,1865.784180 
	C693.210266,1867.832764 690.524963,1866.928589 687.211304,1866.987427 
	C682.670532,1867.217407 678.806213,1868.820312 674.312988,1866.878174 
	C673.341431,1866.471680 672.958130,1866.292725 672.001587,1865.854126 
	C670.658325,1865.341797 669.870117,1865.208862 668.481934,1865.394897 
	C667.158264,1865.818604 666.408691,1866.073853 665.103149,1866.521851 
	C663.367615,1867.009521 662.381104,1867.679199 660.843994,1868.648682 
	C659.895935,1869.090698 659.494202,1869.224487 658.455811,1869.430664 
	C656.069824,1869.468140 654.481140,1868.858032 652.374390,1867.846436 
	C651.148804,1867.127197 650.486084,1866.677246 649.360840,1865.910645 
	C647.434387,1864.776978 646.139893,1865.119019 644.451172,1866.512573 
	C640.381104,1869.978394 636.041321,1870.750854 630.882629,1871.009033 
	C628.503418,1871.109741 627.194824,1871.817505 626.245483,1874.056396 
	C625.714478,1874.936890 625.470215,1875.271118 624.729004,1875.977783 
z"
        />
        <path
          fill="#3D494C"
          opacity="1.000000"
          stroke="none"
          d="
M630.388916,1853.489502 
	C626.898743,1853.917480 624.029846,1851.428223 620.750122,1854.795410 
	C618.320007,1857.290161 614.509399,1856.127075 610.701050,1854.310913 
	C609.785217,1853.644531 609.553528,1853.275391 609.309326,1852.258301 
	C609.864014,1849.380371 611.575928,1847.164795 609.060425,1844.364502 
	C607.512451,1844.113525 609.518616,1839.053467 606.493286,1842.437134 
	C604.309265,1844.879761 601.923157,1846.243896 598.072876,1844.520508 
	C593.721252,1839.331421 597.859131,1833.721558 596.059998,1828.294556 
	C587.033569,1831.804565 573.603027,1827.894409 572.360107,1844.078857 
	C568.588440,1844.860107 566.214844,1842.601440 563.380310,1841.740112 
	C549.390259,1837.488770 545.488281,1825.299438 554.099548,1813.037598 
	C558.162964,1807.251465 562.943787,1806.704712 568.542297,1811.384644 
	C567.863770,1809.183472 566.412964,1807.558472 567.075317,1804.754150 
	C572.724915,1800.017700 577.781555,1802.403442 581.438049,1806.236084 
	C584.817810,1809.778442 588.682617,1810.290161 593.385254,1811.431763 
	C594.823730,1812.168945 596.102905,1812.239746 596.517334,1812.874756 
	C600.531006,1819.024536 600.489502,1819.051636 607.672363,1814.625488 
	C610.163147,1810.870850 612.924072,1809.541260 617.324768,1811.566406 
	C618.514587,1816.208740 621.620972,1815.964844 625.329712,1815.948975 
	C626.842896,1815.267700 627.730591,1814.990234 629.336792,1815.521973 
	C634.351501,1818.446899 635.045715,1822.075439 630.114380,1823.641602 
	C621.859009,1826.263672 622.294189,1834.237671 618.624573,1839.617798 
	C617.906799,1840.670410 618.317749,1842.315308 619.571594,1843.086548 
	C621.132996,1844.046875 622.695068,1843.621460 624.063721,1842.451660 
	C630.072510,1837.314941 629.630432,1837.631104 633.980530,1843.760498 
	C637.737000,1849.053345 633.872925,1850.602295 630.388916,1853.489502 
z"
        />
        <path
          fill="#313E41"
          opacity="1.000000"
          stroke="none"
          d="
M699.399719,1869.777100 
	C700.002197,1867.682861 700.527466,1865.328735 701.644775,1865.002563 
	C706.700562,1863.526611 711.969788,1862.797852 717.068970,1861.447998 
	C720.069336,1860.653687 720.338318,1864.665161 722.984497,1864.243042 
	C725.321716,1861.931763 722.809143,1858.404663 725.090698,1855.860352 
	C738.777527,1854.799194 751.823608,1858.162842 764.570312,1863.033447 
	C767.775085,1864.257935 769.957153,1863.284424 772.741028,1861.546143 
	C776.067566,1859.468994 780.200439,1856.016113 783.860291,1861.547241 
	C787.927063,1860.124878 791.850830,1861.858887 796.540894,1862.119385 
	C799.817932,1862.378296 802.048401,1863.911377 805.299927,1864.240601 
	C806.882202,1864.562988 807.781982,1864.819702 809.357178,1865.389404 
	C810.960571,1866.101196 811.787842,1866.672852 812.965454,1868.014893 
	C814.426453,1871.315674 814.197144,1873.811401 811.876709,1876.628052 
	C810.366516,1877.613159 809.401550,1877.928955 807.645264,1878.096802 
	C798.677795,1876.194336 790.832764,1877.706299 782.537354,1881.153809 
	C780.997620,1881.887817 780.052185,1882.142578 778.327515,1882.324951 
	C776.053772,1882.154419 774.682861,1881.526733 772.690674,1880.454224 
	C772.058533,1880.040527 771.949280,1880.025635 771.955688,1879.970825 
	C771.642578,1879.694702 771.322998,1879.473511 770.556458,1879.626465 
	C770.109436,1880.000732 770.097961,1880.072876 770.071045,1880.097656 
	C769.127136,1881.244507 768.388306,1882.544312 766.292236,1883.373291 
	C756.643127,1881.193604 746.381958,1883.735718 738.086548,1874.856812 
	C737.638306,1885.734375 730.615601,1886.569458 722.709473,1886.953857 
	C715.115784,1882.158813 711.723206,1872.615967 701.162354,1871.827271 
	C700.242065,1871.126465 699.918579,1870.788574 699.399719,1869.777100 
z"
        />
        <path
          fill="#687073"
          opacity="1.000000"
          stroke="none"
          d="
M877.478394,1874.574707 
	C877.873047,1873.932373 877.953857,1873.009277 878.002930,1872.549438 
	C882.286987,1872.455078 884.548828,1870.295288 885.758423,1866.321655 
	C885.905945,1869.244995 885.925354,1872.182983 886.253906,1875.085815 
	C886.423401,1876.583496 886.905273,1878.780151 889.000366,1878.024170 
	C890.736023,1877.397705 889.960815,1875.152588 890.148193,1872.893066 
	C890.780212,1871.453003 891.564819,1870.933228 893.228699,1870.657959 
	C897.522644,1871.557373 900.467285,1873.463989 902.913086,1876.916260 
	C903.001343,1878.060791 902.880310,1878.970581 903.680420,1878.873657 
	C904.984924,1878.715698 903.506104,1879.483398 904.110107,1877.755371 
	C905.898743,1876.828491 907.181824,1876.988037 908.903015,1878.304199 
	C910.670715,1881.623291 910.347107,1884.227051 908.269287,1887.354248 
	C905.701294,1889.908569 906.209229,1892.495728 905.842407,1895.616455 
	C904.939026,1896.883301 904.034729,1897.126465 902.404053,1896.723389 
	C900.655090,1894.706055 901.232788,1892.724487 900.909668,1890.882812 
	C901.043091,1892.247437 900.962097,1893.613403 900.819946,1895.649170 
	C892.127502,1904.972900 890.972839,1895.372925 887.123901,1890.776367 
	C882.450745,1889.097656 882.356689,1892.952759 880.345947,1895.445312 
	C878.870544,1896.832275 877.810852,1897.833984 876.528259,1897.924683 
	C865.283630,1898.719727 854.032410,1899.370483 842.379272,1896.388428 
	C841.913086,1893.421387 841.934570,1891.093872 841.958191,1888.766479 
	C842.102051,1874.602661 845.868042,1871.134155 861.316040,1871.772705 
	C864.237122,1873.416870 863.935425,1876.601074 865.545288,1877.739502 
	C864.743042,1877.307861 864.894653,1876.070801 865.540466,1874.352783 
	C866.867126,1873.904053 867.756531,1873.918335 869.312134,1873.969727 
	C870.507385,1874.477295 870.848267,1875.030029 870.911133,1875.673584 
	C871.022095,1876.807617 870.972046,1877.948242 870.992798,1879.086060 
	C871.018188,1877.759521 871.011902,1876.432129 871.571899,1874.559204 
	C872.854919,1874.044556 873.684509,1874.078735 875.136414,1874.144287 
	C878.098328,1876.066040 876.360535,1878.800049 877.145020,1880.809082 
	C876.934753,1879.079956 877.014526,1877.148560 877.478394,1874.574707 
z"
        />
        <path
          fill="#3D494C"
          opacity="1.000000"
          stroke="none"
          d="
M902.447815,1880.359131 
	C901.629333,1875.525635 897.247192,1875.304565 894.387512,1872.572754 
	C892.552673,1870.733765 891.795410,1869.050781 891.709106,1866.554688 
	C892.028748,1865.928711 892.029541,1865.020996 892.022095,1864.568604 
	C896.409607,1864.023804 896.653931,1869.425171 900.893494,1870.541870 
	C902.727051,1867.153809 906.336792,1864.641357 906.168701,1859.900635 
	C906.124390,1858.650391 906.533020,1857.215454 908.244568,1857.088745 
	C909.765076,1856.976196 910.545593,1858.043457 911.235474,1859.192749 
	C912.917847,1861.995605 910.387085,1867.008911 915.818298,1867.854492 
	C919.309387,1868.398071 922.369446,1867.086182 925.155457,1865.368774 
	C927.683289,1863.810425 930.389893,1865.722412 933.387634,1864.219971 
	C935.045044,1862.922119 936.137085,1861.869751 937.593140,1860.377197 
	C943.572937,1856.265503 940.570496,1863.716919 943.430908,1863.952271 
	C943.801941,1862.490356 944.069641,1860.938110 944.603271,1859.483276 
	C945.069275,1858.212646 946.056335,1857.016968 947.458130,1857.076172 
	C949.570984,1857.164917 949.718872,1858.973999 949.977722,1860.645142 
	C950.464966,1863.791016 949.051514,1867.157471 950.923523,1870.122437 
	C953.091187,1870.529053 954.594177,1869.046387 955.031006,1867.796875 
	C957.583740,1860.496582 965.186523,1858.923218 969.913452,1854.160645 
	C972.362305,1851.693481 975.332214,1852.119263 978.262512,1852.034302 
	C983.216980,1851.890625 988.197815,1851.859863 991.848389,1846.726929 
	C992.720398,1841.472900 994.929810,1843.257324 997.482544,1845.421387 
	C998.865112,1848.205688 998.950745,1850.744873 1001.031616,1853.204834 
	C1001.588318,1854.252686 1001.778503,1854.687866 1002.170410,1855.804443 
	C1002.773193,1861.132324 1003.879211,1866.478638 998.686951,1868.878784 
	C993.613831,1871.223511 987.889465,1870.536377 983.675110,1866.631104 
	C978.662720,1861.986328 973.242249,1860.546143 969.080811,1866.052246 
	C959.698242,1878.466553 946.952332,1876.230469 934.810425,1874.329224 
	C929.689392,1873.527222 926.046753,1873.809692 921.929871,1876.409424 
	C918.102234,1878.826660 913.692566,1878.731201 908.634521,1878.091309 
	C906.847656,1878.384277 905.753906,1878.693481 904.315247,1879.472656 
	C903.634888,1880.209106 903.299438,1880.475464 902.447815,1880.359131 
z"
        />
        <path
          fill="#374549"
          opacity="1.000000"
          stroke="none"
          d="
M727.146362,1809.978271 
	C727.716431,1812.540771 730.357910,1814.558838 728.449707,1817.597168 
	C727.006958,1818.841431 726.612732,1819.870850 727.356689,1821.643921 
	C727.986145,1824.355347 727.915894,1826.763794 731.275757,1827.833008 
	C735.874573,1828.493652 738.301147,1832.021484 742.725281,1832.455444 
	C748.505127,1833.022461 750.422546,1839.948242 745.544006,1843.248535 
	C741.910767,1845.706543 744.056763,1848.521362 742.366211,1851.256836 
	C739.207214,1849.905396 735.800354,1848.847290 732.820312,1847.072510 
	C729.046082,1844.824951 725.870483,1841.856934 725.981445,1836.822754 
	C726.138000,1829.717407 723.312500,1825.333008 716.079163,1822.738770 
	C707.005676,1819.484375 706.478333,1814.788086 713.081238,1807.916748 
	C715.596985,1805.298828 717.898254,1803.258911 721.245789,1807.104736 
	C722.450928,1808.489014 724.699097,1808.965332 727.146362,1809.978271 
z"
        />
        <path
          fill="#83837F"
          opacity="1.000000"
          stroke="none"
          d="
M848.885620,1772.226685 
	C852.321777,1775.259155 856.249329,1773.313232 859.730042,1775.370605 
	C861.410339,1776.641357 861.840698,1779.648804 864.119507,1776.521851 
	C863.974182,1771.972778 865.043213,1768.890015 869.648682,1768.190552 
	C875.029297,1767.373657 876.936035,1769.236206 876.439026,1775.085449 
	C876.328613,1776.385742 875.942322,1777.660034 877.256592,1778.854370 
	C880.275818,1779.829346 881.690430,1774.966064 885.684814,1776.449707 
	C888.989746,1779.164551 888.834656,1782.334229 887.898804,1786.295166 
	C882.108765,1790.039307 880.819885,1795.706787 878.438110,1801.441650 
	C876.401978,1802.530640 874.872620,1803.189575 872.713745,1803.873291 
	C872.385193,1798.681641 864.832214,1796.404419 867.145996,1790.225708 
	C867.956299,1788.062012 865.426880,1789.012817 864.497925,1788.987671 
	C860.548096,1788.880493 856.697144,1788.368164 852.392151,1786.645386 
	C850.992188,1786.233398 850.168396,1786.212402 848.727295,1786.162109 
	C846.348816,1787.497681 844.465698,1787.743286 841.900146,1786.297852 
	C840.200806,1781.824097 837.471191,1778.425903 837.028198,1773.644653 
	C840.782471,1771.800415 844.403442,1772.182861 848.885620,1772.226685 
z"
        />
        <path
          fill="#B9A795"
          opacity="1.000000"
          stroke="none"
          d="
M786.565247,1806.312012 
	C785.673462,1805.848755 785.378723,1805.218628 784.660461,1804.276978 
	C784.369446,1799.731689 784.212219,1795.464478 784.696899,1791.271362 
	C785.597595,1783.479858 785.753418,1783.496094 793.892090,1783.122314 
	C794.540588,1783.092407 795.599121,1783.368164 795.780884,1783.075562 
	C799.304688,1777.404297 803.185486,1780.618530 807.562012,1782.260010 
	C809.316162,1781.797974 810.317993,1782.177246 811.553955,1783.843750 
	C813.231628,1789.695801 814.158386,1794.757446 808.626709,1799.035400 
	C807.803040,1799.259033 806.895142,1799.628906 806.439209,1799.808838 
	C800.707947,1801.037231 795.857971,1803.379761 790.177734,1805.322510 
	C788.779480,1805.818115 787.970825,1805.981689 786.565247,1806.312012 
z"
        />
        <path
          fill="#83837F"
          opacity="1.000000"
          stroke="none"
          d="
M808.678467,1799.444092 
	C810.756287,1795.075439 809.447571,1790.236084 809.959473,1784.845459 
	C813.117737,1780.567383 817.281616,1780.882935 822.188477,1780.914062 
	C825.904114,1781.087280 828.761414,1781.186279 831.944641,1783.333130 
	C832.914856,1784.341919 833.237244,1784.803833 833.876770,1786.043213 
	C834.300842,1787.366211 834.407776,1787.911621 834.548218,1789.285889 
	C834.514404,1791.799438 834.029480,1793.340698 832.483643,1795.318604 
	C824.692627,1798.392090 817.494202,1801.263672 808.678467,1799.444092 
z"
        />
        <path
          fill="#595B54"
          opacity="1.000000"
          stroke="none"
          d="
M795.245850,1836.025146 
	C797.517517,1835.975098 799.013000,1835.969727 801.256653,1835.962036 
	C804.362366,1836.610596 806.548340,1837.729126 809.523132,1838.901978 
	C813.348694,1842.772583 814.730042,1846.668457 812.663391,1851.965698 
	C810.593445,1853.563721 808.979004,1853.636597 806.636841,1852.421875 
	C805.806580,1849.995483 806.288879,1847.639893 804.041992,1846.735352 
	C801.589478,1845.748169 800.589722,1847.573120 799.821533,1849.586670 
	C798.308411,1853.552612 794.772339,1855.100342 790.270447,1857.942993 
	C791.269653,1851.455688 791.433838,1846.391968 790.188965,1841.315796 
	C789.470642,1838.387085 791.040710,1836.190674 795.245850,1836.025146 
z"
        />
        <path
          fill="#646A6C"
          opacity="1.000000"
          stroke="none"
          d="
M852.031677,1786.918701 
	C858.617737,1786.370972 865.558960,1784.743896 872.671692,1787.550903 
	C869.411987,1791.239868 864.995789,1794.341675 873.085022,1797.120850 
	C876.917053,1798.437622 871.919373,1800.670776 872.155029,1803.298340 
	C868.524292,1804.227295 864.964417,1804.556763 860.918762,1804.931152 
	C859.622009,1798.413452 859.129150,1791.303955 852.031677,1786.918701 
z"
        />
        <path
          fill="#3D494C"
          opacity="1.000000"
          stroke="none"
          d="
M661.916992,1829.277466 
	C659.521729,1833.945679 658.219177,1839.421997 651.201599,1836.532837 
	C649.033752,1835.640259 648.211060,1838.438110 648.030518,1841.256592 
	C644.896545,1841.888184 641.727905,1841.979858 643.271423,1837.149902 
	C643.859436,1835.309570 643.641907,1833.219971 644.169495,1831.350586 
	C645.302979,1827.334351 645.946289,1821.766602 650.418823,1821.201660 
	C655.116089,1820.608276 660.140137,1822.838379 661.916992,1829.277466 
z"
        />
        <path
          fill="#646A6C"
          opacity="1.000000"
          stroke="none"
          d="
M878.530151,1802.351807 
	C878.007874,1798.563965 878.375427,1795.231567 878.098572,1791.953613 
	C877.570679,1785.703125 881.424744,1786.924438 885.812134,1787.454834 
	C887.291626,1786.822021 888.104187,1786.508911 889.664795,1786.513428 
	C890.720154,1786.809448 891.114929,1787.014038 891.860229,1787.785767 
	C892.148254,1791.332520 892.250671,1794.327271 891.975342,1797.286987 
	C891.715088,1800.083984 893.222229,1803.814941 889.717957,1805.282349 
	C885.909302,1806.877319 881.897217,1806.911621 878.530151,1802.351807 
z"
        />
        <path
          fill="#E7E3DA"
          opacity="1.000000"
          stroke="none"
          d="
M698.656250,1869.039307 
	C699.204346,1869.103271 699.151123,1869.650391 699.204590,1869.919922 
	C700.243958,1872.286377 699.961487,1874.266602 698.078247,1876.630737 
	C695.894592,1877.671387 694.380554,1877.636475 692.389771,1876.240723 
	C691.864441,1875.660645 690.930237,1875.501831 690.488281,1875.672852 
	C687.002869,1877.747314 684.022034,1876.977295 680.424438,1875.165161 
	C676.822083,1869.006958 681.795593,1867.648682 685.554932,1864.837646 
	C689.554138,1861.402344 692.190002,1862.546753 695.136963,1866.307495 
	C696.591064,1867.497314 697.349548,1868.236206 698.656250,1869.039307 
z"
        />
        <path
          fill="#83837F"
          opacity="1.000000"
          stroke="none"
          d="
M892.885620,1788.291992 
	C891.722351,1788.155273 891.233826,1787.957520 890.014160,1787.458984 
	C887.245422,1782.475464 887.403076,1777.910400 890.459473,1772.826050 
	C896.325378,1768.661011 898.202087,1773.152466 901.098022,1777.062378 
	C901.711365,1781.815430 901.825500,1785.975586 901.966431,1791.021484 
	C899.066101,1790.948120 897.205322,1787.543091 892.885620,1788.291992 
z"
        />
        <path
          fill="#646A6C"
          opacity="1.000000"
          stroke="none"
          d="
M832.852539,1795.949341 
	C832.156494,1794.335083 832.352905,1792.730225 832.688782,1790.328613 
	C833.893372,1788.080688 833.968140,1786.223755 835.257324,1784.168945 
	C837.299377,1782.471680 839.009155,1782.204712 841.401855,1783.453613 
	C843.082031,1785.627197 844.881653,1785.852173 847.421753,1786.045166 
	C847.785645,1795.912476 847.785706,1795.917603 838.603943,1796.007568 
	C836.984619,1796.023438 835.364624,1795.976685 832.852539,1795.949341 
z"
        />
        <path
          fill="#3D494C"
          opacity="1.000000"
          stroke="none"
          d="
M629.481445,1808.747559 
	C630.267883,1806.094849 631.443970,1803.268188 633.384277,1804.511719 
	C638.794800,1807.979370 641.227478,1804.215942 643.728027,1800.541626 
	C646.860840,1802.929565 648.420532,1805.390869 647.251770,1808.568604 
	C645.990479,1811.997559 642.761536,1811.994263 639.725525,1811.997681 
	C637.077576,1812.000732 634.429565,1811.993164 630.907715,1811.981812 
	C629.380310,1811.223389 629.066650,1810.351318 629.481445,1808.747559 
z"
        />
        <path
          fill="#6F7981"
          opacity="1.000000"
          stroke="none"
          d="
M805.936157,1852.584717 
	C807.487610,1851.956177 809.018433,1851.995605 811.313171,1852.077515 
	C812.779663,1852.826050 813.267334,1853.658081 813.634033,1855.341309 
	C813.426331,1859.871216 813.786621,1863.723389 812.413452,1868.005737 
	C811.094299,1868.359497 810.271362,1868.194580 808.835815,1867.882568 
	C807.828735,1867.496094 807.434326,1867.256592 806.407593,1866.735596 
	C802.876038,1865.923584 798.237732,1870.251465 797.399841,1862.830811 
	C798.376404,1861.483887 799.513733,1860.819458 801.308350,1860.054688 
	C802.977722,1859.016968 803.938293,1858.029785 805.272705,1856.448975 
	C805.777832,1855.027954 805.846741,1854.140381 805.936157,1852.584717 
z"
        />
        <path
          fill="#6B6164"
          opacity="1.000000"
          stroke="none"
          d="
M790.322754,1805.779297 
	C791.861694,1800.736084 797.724060,1800.554932 801.135498,1796.983276 
	C802.296204,1795.767944 803.912537,1797.999390 805.514954,1799.564941 
	C805.720581,1805.166870 800.822144,1807.164551 798.872864,1811.208130 
	C795.371094,1810.192383 795.276733,1804.993652 790.322754,1805.779297 
z"
        />
        <path
          fill="#5A605E"
          opacity="1.000000"
          stroke="none"
          d="
M617.934937,1811.432373 
	C614.941895,1811.660400 612.259705,1812.993286 608.753174,1813.932495 
	C606.367676,1810.554810 603.924744,1806.792725 611.111389,1807.251221 
	C612.266846,1807.324829 613.477112,1806.538818 615.313965,1806.084839 
	C616.604980,1807.608643 617.244019,1809.197876 617.934937,1811.432373 
z"
        />
        <path
          fill="#374549"
          opacity="1.000000"
          stroke="none"
          d="
M707.837830,1839.641357 
	C712.239380,1837.705078 715.145508,1840.852905 720.510437,1840.307373 
	C715.712708,1845.397827 711.200378,1843.989136 707.442139,1844.144287 
	C705.604248,1844.220337 706.547729,1841.552612 707.837830,1839.641357 
z"
        />
        <path
          fill="#3D494C"
          opacity="1.000000"
          stroke="none"
          d="
M638.137573,1840.685547 
	C637.230286,1840.550537 636.847168,1840.357422 636.793518,1840.096558 
	C636.180237,1837.113892 633.857178,1834.292114 635.650452,1831.091553 
	C636.106262,1830.278076 637.500488,1830.148315 638.205994,1830.902222 
	C641.028198,1833.918213 640.340271,1837.252686 638.137573,1840.685547 
z"
        />
        <path
          fill="#3D494C"
          opacity="1.000000"
          stroke="none"
          d="
M639.199158,1856.048340 
	C639.914490,1857.345459 640.089355,1858.744995 639.615601,1859.871826 
	C638.237122,1863.150513 636.370728,1864.973755 633.525024,1861.001587 
	C632.591187,1859.697876 631.579346,1858.450195 630.304016,1856.588501 
	C632.790710,1856.008789 635.576538,1856.017456 639.199158,1856.048340 
z"
        />
        <path
          fill="#595B54"
          opacity="1.000000"
          stroke="none"
          d="
M893.184265,1843.001465 
	C891.251465,1845.158569 889.409790,1846.170654 888.433777,1843.300903 
	C887.733582,1841.242310 887.167786,1838.520020 889.725464,1837.204346 
	C891.565002,1836.258057 892.737915,1837.967896 893.367615,1839.500366 
	C893.711853,1840.337769 893.478271,1841.412720 893.184265,1843.001465 
z"
        />
        <path
          fill="#3D494C"
          opacity="1.000000"
          stroke="none"
          d="
M661.219910,1849.985596 
	C662.169006,1853.408936 661.293762,1856.427124 658.632629,1859.094360 
	C654.577026,1857.518188 656.621460,1854.045044 656.000671,1850.777710 
	C657.495056,1850.003296 658.985168,1849.996704 661.219910,1849.985596 
z"
        />
        <path
          fill="#5A605E"
          opacity="1.000000"
          stroke="none"
          d="
M629.180786,1808.258789 
	C629.911926,1808.989868 629.953979,1809.841553 630.015015,1811.333252 
	C630.011475,1812.856201 629.989075,1813.739014 629.950439,1815.282837 
	C629.076538,1815.944214 628.218872,1815.944824 626.718750,1815.938965 
	C626.352356,1813.245605 624.954407,1810.023315 629.180786,1808.258789 
z"
        />
        <path
          fill="#3D494C"
          opacity="1.000000"
          stroke="none"
          d="
M651.356689,1848.155029 
	C651.877197,1850.295044 652.236145,1852.785522 648.678711,1853.868652 
	C648.109863,1851.715698 647.733276,1849.215576 651.356689,1848.155029 
z"
        />
        <path
          fill="#374549"
          opacity="1.000000"
          stroke="none"
          d="
M863.045288,1817.438477 
	C865.765564,1817.096191 865.830078,1818.539551 865.242859,1820.163818 
	C865.106506,1820.541016 864.135437,1820.975586 863.741638,1820.836548 
	C862.301941,1820.328003 862.262878,1819.117798 863.045288,1817.438477 
z"
        />
        <path
          fill="#595B54"
          opacity="1.000000"
          stroke="none"
          d="
M891.514526,1866.385254 
	C892.653442,1867.538574 893.278198,1869.148315 893.943970,1871.407837 
	C893.128113,1872.069214 892.271118,1872.080933 890.775635,1872.144165 
	C890.025208,1870.346558 889.831787,1868.484375 891.514526,1866.385254 
z"
        />
        <path
          fill="#595B54"
          opacity="1.000000"
          stroke="none"
          d="
M805.122192,1856.062012 
	C805.861633,1857.823853 805.405579,1859.350830 802.614136,1859.887695 
	C802.203125,1858.280884 802.756165,1856.823975 805.122192,1856.062012 
z"
        />
        <path
          fill="#797771"
          opacity="1.000000"
          stroke="none"
          d="
M668.052490,1748.674072 
	C669.326477,1748.157593 670.728210,1748.251465 670.991455,1749.823486 
	C671.175720,1750.923706 670.275391,1751.460327 668.708496,1751.718994 
	C668.117554,1750.923096 668.089783,1750.105957 668.052490,1748.674072 
z"
        />
        <path
          fill="#374549"
          opacity="1.000000"
          stroke="none"
          d="
M723.073792,1840.954346 
	C723.120483,1841.040771 723.027100,1840.868042 723.073792,1840.954346 
z"
        />
        <path
          fill="#5A605E"
          opacity="1.000000"
          stroke="none"
          d="
M593.981934,1811.064697 
	C592.662476,1812.035767 590.977234,1811.783447 590.161743,1812.508911 
	C585.329956,1816.807251 583.070618,1816.435791 581.188477,1809.608276 
	C579.844299,1804.732666 573.873047,1805.094727 568.778687,1803.987549 
	C565.668762,1806.701904 562.453308,1808.646606 559.184814,1807.566528 
	C546.801697,1803.474243 537.448181,1810.820801 527.842468,1815.907471 
	C519.743835,1820.196167 519.493103,1824.266235 524.353821,1832.181519 
	C526.238037,1835.249756 529.428284,1838.268921 523.510986,1841.302368 
	C521.382690,1841.392456 520.010986,1841.047485 517.972107,1840.467651 
	C503.323334,1839.113525 509.144409,1826.864868 505.177185,1818.232422 
	C503.830841,1827.520752 499.451538,1829.597046 492.440216,1825.382324 
	C491.576263,1824.863037 490.644348,1824.564819 489.706879,1824.238403 
	C479.283203,1820.607910 479.284729,1820.603516 475.220886,1832.438110 
	C480.806427,1829.324097 485.028412,1820.839478 491.789581,1826.584717 
	C496.471985,1830.563721 505.554321,1825.822388 506.431183,1835.479248 
	C505.721405,1836.599854 505.339996,1837.095459 504.422638,1837.731812 
	C500.512604,1841.655518 495.706268,1842.149780 490.543915,1843.607666 
	C485.351349,1845.258911 482.346802,1850.833496 476.440369,1848.793457 
	C475.073486,1848.321411 474.475311,1850.130859 473.771149,1851.261353 
	C472.910400,1852.643311 471.863037,1853.954956 470.698029,1855.093994 
	C462.903259,1862.714844 461.088989,1862.497192 454.153351,1852.590454 
	C459.099152,1847.419800 457.932465,1841.592896 457.651367,1835.261475 
	C457.844177,1834.138428 457.959351,1833.695557 458.341095,1832.619873 
	C459.428040,1830.365723 460.900482,1829.163940 461.850647,1826.811279 
	C466.775116,1818.329712 470.208374,1809.404663 480.350983,1805.664551 
	C480.818604,1803.827026 481.013916,1802.564087 481.171753,1800.652344 
	C480.336914,1796.658936 484.240265,1793.588013 481.258484,1790.387207 
	C478.211731,1794.308594 481.443542,1801.524536 473.754669,1801.999512 
	C470.050415,1802.228394 467.154175,1800.902954 466.046783,1797.140991 
	C464.291748,1791.179077 465.618774,1785.995483 470.423340,1781.860840 
	C472.213623,1780.319946 474.285461,1779.297241 477.283508,1778.364258 
	C482.384613,1777.738892 486.552979,1779.575928 491.710510,1779.085815 
	C495.316132,1779.198608 498.144928,1779.072876 501.267700,1781.271240 
	C503.419739,1785.027466 508.245331,1782.665894 509.660767,1786.934448 
	C510.148254,1789.534058 510.442322,1791.490845 508.992645,1793.940308 
	C505.939240,1796.607422 502.989594,1796.216064 500.025269,1795.602539 
	C495.886780,1794.745728 494.615540,1796.789062 494.975586,1801.223267 
	C498.881683,1801.509399 502.285217,1798.650757 506.811279,1799.092651 
	C508.450287,1799.064697 509.389191,1799.003784 511.026001,1798.739502 
	C519.303711,1795.792847 526.950806,1794.061279 535.483276,1797.510132 
	C539.495300,1798.064575 542.550903,1797.717896 541.488037,1792.951416 
	C541.176636,1791.555176 541.604553,1790.573120 543.572693,1789.919678 
	C552.350647,1784.473633 560.225281,1790.861572 568.976135,1790.746216 
	C572.764404,1790.615234 570.942871,1800.433838 577.201416,1793.979980 
	C578.167114,1793.416504 578.611572,1793.300781 579.587524,1793.623291 
	C583.326355,1795.452271 586.544983,1797.734985 589.876587,1793.324829 
	C591.511047,1792.727539 592.954895,1792.744751 595.089600,1792.978516 
	C597.113892,1794.142944 597.313599,1795.432861 596.453918,1797.453857 
	C596.651611,1802.309204 594.536133,1806.171875 593.981934,1811.064697 
z"
        />
        <path
          fill="#687073"
          opacity="1.000000"
          stroke="none"
          d="
M477.621033,1766.428711 
	C477.745209,1761.722656 477.845123,1757.734619 477.997559,1752.886963 
	C480.190094,1753.329346 483.769409,1753.863281 484.559387,1752.519897 
	C489.318237,1744.427368 495.017944,1750.208984 499.851074,1751.542358 
	C502.721191,1752.334473 505.811646,1753.921753 510.266541,1755.500488 
	C504.064636,1760.418579 498.285889,1753.158936 493.598694,1757.056030 
	C492.480133,1759.911499 494.971344,1760.596069 496.582947,1761.104004 
	C502.932556,1763.105713 509.259674,1766.605835 516.084595,1762.780396 
	C517.085999,1762.219116 517.396729,1762.063599 518.159851,1763.064453 
	C522.046204,1768.162354 528.264465,1766.714355 533.563965,1768.528564 
	C528.673523,1773.087402 524.023071,1776.643555 516.236206,1774.714722 
	C512.228027,1773.722046 507.340668,1776.278564 502.351501,1777.843262 
	C499.460388,1780.205200 496.680847,1779.935791 493.149658,1779.949829 
	C488.677979,1779.248901 484.941650,1779.042847 480.524933,1778.143433 
	C471.272064,1779.968018 462.348083,1778.170288 453.358398,1781.537354 
	C450.288116,1782.554932 448.781219,1784.225220 447.382507,1787.049072 
	C440.882233,1791.514526 435.672028,1786.595215 429.407410,1785.651978 
	C421.405945,1773.709717 428.681976,1764.846558 434.925049,1756.427734 
	C440.120178,1749.422119 440.992065,1757.260132 443.793549,1760.177002 
	C446.534790,1761.390503 447.923889,1763.241089 450.216003,1764.998047 
	C453.435669,1766.108765 456.378204,1765.420898 459.322968,1767.494263 
	C465.036377,1770.306152 470.597321,1768.698608 475.957642,1769.333862 
	C476.411194,1769.387695 477.046082,1767.911865 477.621033,1766.428711 
z"
        />
        <path
          fill="#3D494C"
          opacity="1.000000"
          stroke="none"
          d="
M536.031128,1797.168213 
	C528.510071,1799.127075 520.514709,1797.274414 512.807495,1801.697510 
	C508.437347,1798.308838 509.795959,1794.010498 511.272003,1788.972168 
	C515.146484,1783.968384 519.640747,1786.062378 523.570129,1786.480469 
	C527.079712,1786.853760 530.532471,1788.010742 534.967834,1788.096436 
	C535.867371,1790.886719 535.936340,1793.616455 536.031128,1797.168213 
z"
        />
        <path
          fill="#687073"
          opacity="1.000000"
          stroke="none"
          d="
M482.288757,1742.354736 
	C481.655762,1741.763184 481.824188,1740.883789 481.905701,1740.443604 
	C488.082428,1740.236938 493.662689,1737.923340 499.434174,1736.526733 
	C502.123322,1735.876099 505.082855,1735.620239 505.617645,1739.348633 
	C506.121216,1742.859131 503.690491,1744.055420 500.664825,1743.911377 
	C494.749115,1743.629883 488.735229,1745.059692 482.288757,1742.354736 
z"
        />
        <path
          fill="#C4C8C1"
          opacity="1.000000"
          stroke="none"
          d="
M511.291870,1788.016846 
	C511.874542,1792.218628 511.974091,1796.250244 512.137329,1801.145142 
	C511.353821,1802.078125 510.506622,1802.147827 509.031616,1801.995483 
	C506.273346,1800.053589 505.970032,1797.890503 507.346161,1794.695801 
	C508.239044,1792.206055 508.652252,1790.457031 509.443207,1788.141357 
	C510.150208,1787.665527 510.479492,1787.756104 511.291870,1788.016846 
z"
        />
        <path
          fill="#D0D1C8"
          opacity="1.000000"
          stroke="none"
          d="
M491.695953,1778.021729 
	C494.937073,1777.808960 497.599854,1778.041504 501.057526,1778.364502 
	C501.815765,1779.197632 501.779083,1779.939941 501.838806,1781.233398 
	C499.104431,1781.751709 496.273712,1781.719238 492.632812,1781.431152 
	C491.219696,1780.368408 490.984650,1779.465576 491.695953,1778.021729 
z"
        />
        <path
          fill="#646A6C"
          opacity="1.000000"
          stroke="none"
          d="
M388.718292,1824.207275 
	C383.583832,1820.666992 378.696259,1821.014771 374.317108,1823.913208 
	C371.149323,1826.010010 368.217255,1826.184570 364.891022,1825.308594 
	C356.363068,1823.062988 347.794312,1823.626099 338.608582,1825.443848 
	C332.262360,1823.054199 325.780334,1823.385498 319.674957,1822.287842 
	C311.422974,1820.804199 304.761597,1818.989136 305.598846,1808.561401 
	C305.710022,1807.177124 304.052460,1806.678833 302.140503,1807.931396 
	C300.055847,1808.113037 298.435059,1807.799438 296.306946,1807.129883 
	C294.337189,1801.075928 289.507812,1795.730469 294.450134,1788.821167 
	C295.345459,1787.960327 295.773193,1787.689575 296.955994,1787.499512 
	C325.310516,1786.992920 352.619995,1791.828003 381.106262,1792.011719 
	C383.120422,1793.701782 384.347137,1795.501099 386.585022,1795.042847 
	C389.769867,1794.390625 389.190155,1791.541504 388.890472,1789.473633 
	C388.069397,1783.807861 388.148712,1779.273682 396.528809,1781.648438 
	C400.889923,1790.859131 399.576630,1798.666870 391.488678,1806.278687 
	C411.566528,1805.278564 430.274261,1806.963623 449.657532,1807.127075 
	C450.881653,1808.409424 451.173157,1809.364990 450.991241,1811.157227 
	C446.227478,1815.483154 445.065338,1820.980957 442.356293,1826.467285 
	C440.226196,1827.687500 438.663849,1827.736694 436.357117,1826.784790 
	C434.309418,1826.372681 432.982513,1826.345459 430.991882,1826.333374 
	C427.517609,1828.296875 424.584991,1828.968262 420.626404,1826.677368 
	C418.376465,1826.085815 416.919281,1826.017090 414.732849,1825.932861 
	C407.424957,1821.907227 401.537720,1830.278687 394.440918,1826.536499 
	C392.367493,1825.462036 390.851868,1824.829590 388.718292,1824.207275 
z"
        />
        <path
          fill="#46565D"
          opacity="1.000000"
          stroke="none"
          d="
M397.387085,1781.829346 
	C392.569580,1781.719849 389.744934,1782.815796 389.907135,1787.832642 
	C390.011963,1791.074829 389.926849,1794.323120 389.926849,1798.187744 
	C386.042145,1797.956055 383.633667,1796.498779 382.109741,1792.697754 
	C384.620300,1792.776611 387.782074,1794.781006 387.559998,1788.872192 
	C386.382172,1786.695557 386.289886,1784.977051 386.539642,1782.541748 
	C386.326447,1781.037598 386.304932,1780.168945 386.245667,1778.649780 
	C386.021759,1776.243896 386.010986,1774.481812 385.989319,1772.052734 
	C385.881317,1770.035034 385.907623,1768.702148 386.931122,1766.903931 
	C388.503265,1759.511230 387.903992,1752.619873 387.913269,1744.813721 
	C393.673553,1740.720947 398.128815,1741.736450 400.387634,1748.231079 
	C401.862152,1752.470947 405.274689,1752.274048 408.499084,1752.138672 
	C412.601990,1751.966064 412.643036,1748.556030 412.941803,1745.569458 
	C413.300110,1741.987915 412.657257,1738.207275 415.584778,1734.567383 
	C419.297577,1731.342163 423.768951,1734.238647 426.173767,1732.786987 
	C429.039062,1731.057373 430.809692,1727.578613 434.930237,1729.127441 
	C435.185577,1726.338501 433.681549,1726.269531 432.424072,1726.084106 
	C430.070862,1725.737183 427.193604,1725.397461 427.088623,1722.647461 
	C426.958466,1719.236816 430.347870,1720.313477 432.372833,1719.772461 
	C433.661041,1719.428101 434.998749,1719.169434 435.345642,1717.610840 
	C435.808502,1715.531250 434.493347,1714.604248 432.820618,1714.047363 
	C430.475342,1713.266479 428.074036,1711.938965 428.363434,1709.392334 
	C429.027649,1703.548462 425.648102,1700.403687 421.128052,1697.941528 
	C423.172058,1695.268433 427.034821,1699.608276 428.033997,1694.690430 
	C436.287079,1693.712402 444.209259,1695.128296 452.017120,1697.044678 
	C454.551941,1697.666748 456.443054,1696.472778 459.325562,1696.063477 
	C459.980072,1719.361938 459.940277,1742.727417 459.869385,1767.025513 
	C456.643066,1768.035889 453.447845,1768.113525 449.776215,1767.723389 
	C445.029114,1760.882568 448.131195,1753.565674 446.772766,1746.548706 
	C444.258179,1751.328003 447.815918,1756.532104 444.534027,1761.483887 
	C442.345947,1762.736572 441.450165,1764.947510 439.524719,1765.044800 
	C436.272064,1761.733398 441.771210,1758.641479 439.086456,1755.630005 
	C436.235992,1755.541748 436.107330,1757.844971 435.827454,1759.604736 
	C435.054077,1764.467041 431.340485,1767.146973 428.492523,1770.599243 
	C425.330780,1774.432129 431.153320,1778.828613 428.290894,1783.552734 
	C423.858185,1786.176636 422.231171,1783.100708 422.139038,1780.234375 
	C421.802277,1769.760010 415.350861,1768.314453 406.973053,1769.052856 
	C405.040466,1769.223022 403.077759,1768.927368 400.459351,1768.597534 
	C398.635895,1768.597778 397.027161,1767.209717 396.502777,1768.602173 
	C396.143250,1769.557129 398.037964,1770.429688 399.086426,1772.116211 
	C400.412659,1775.855347 399.831879,1778.695801 397.387085,1781.829346 
z"
        />
        <path
          fill="#959693"
          opacity="1.000000"
          stroke="none"
          d="
M297.914032,1786.932617 
	C297.711060,1787.580322 296.899658,1787.724609 296.495422,1787.804688 
	C294.451538,1789.231934 292.624023,1789.617676 289.723877,1788.502441 
	C280.209747,1785.258179 271.220062,1788.940674 261.840088,1786.681152 
	C260.842590,1785.715942 260.553223,1784.716431 260.280334,1782.933838 
	C263.613037,1781.971313 266.937225,1781.641602 270.126160,1783.002075 
	C272.053711,1783.824219 274.017426,1783.892700 275.891266,1782.885864 
	C277.295410,1782.131226 279.155426,1781.629150 279.000061,1779.515625 
	C278.854614,1777.536499 277.253723,1776.579834 275.633606,1776.449463 
	C271.672272,1776.130127 267.680634,1776.206909 263.704376,1776.045532 
	C261.409698,1775.952515 258.409546,1776.069702 258.591858,1772.869751 
	C258.767212,1769.791748 261.785522,1770.060669 264.063873,1770.024536 
	C268.049683,1769.961060 272.039490,1769.957397 276.024109,1770.062012 
	C280.284851,1770.173584 281.972321,1768.041992 281.931213,1763.965088 
	C281.858337,1756.741089 281.910736,1749.515991 281.910736,1742.291260 
	C282.606323,1742.288574 283.301941,1742.285889 283.997528,1742.283203 
	C283.997528,1749.127319 283.928345,1755.972168 284.014709,1762.815186 
	C284.127411,1771.745483 284.179077,1771.810425 292.788208,1771.988037 
	C299.099121,1772.118408 305.414398,1772.089722 311.727264,1772.052246 
	C315.784393,1772.028076 319.417725,1773.171387 323.414124,1775.559326 
	C320.570129,1779.164795 316.558655,1778.785400 314.196259,1777.716797 
	C306.765076,1774.354980 303.252411,1778.875610 299.580536,1783.700684 
	C298.986572,1784.481323 298.599701,1785.419556 297.914032,1786.932617 
z"
        />
        <path
          fill="#A6A29C"
          opacity="1.000000"
          stroke="none"
          d="
M386.547119,1766.186768 
	C387.379425,1767.586914 387.442291,1768.823730 387.470398,1770.684204 
	C387.161835,1772.436890 386.445587,1772.961670 384.640991,1772.490967 
	C382.327759,1770.021729 379.976166,1770.006836 377.628265,1770.000122 
	C354.354401,1769.932983 331.171478,1766.960327 307.866547,1767.821411 
	C305.210724,1767.919556 302.539001,1767.811890 299.884521,1767.643555 
	C298.128174,1767.532227 296.483124,1766.765381 296.634979,1764.721924 
	C296.784882,1762.704712 298.552734,1762.150635 300.279877,1762.138184 
	C306.938538,1762.090210 313.716949,1761.333008 320.233856,1762.300903 
	C337.471649,1764.860718 354.772339,1763.516235 372.035004,1764.008301 
	C376.747070,1764.142822 381.299774,1764.641846 386.547119,1766.186768 
z"
        />
        <path
          fill="#646A6C"
          opacity="1.000000"
          stroke="none"
          d="
M415.397858,1734.106567 
	C416.036041,1739.354614 416.029419,1744.629272 415.950317,1749.902832 
	C415.920898,1751.863281 415.947174,1753.911011 414.009521,1755.190796 
	C411.000671,1757.178467 397.761932,1754.874756 395.380585,1751.689087 
	C393.440247,1749.093506 396.462891,1748.579224 398.291626,1746.801758 
	C395.206299,1745.507080 392.542114,1744.108276 388.634399,1743.924805 
	C387.895874,1743.051270 387.887878,1742.221680 387.885742,1740.769043 
	C393.018036,1732.748047 400.867371,1738.827515 407.627502,1736.079468 
	C410.427582,1735.290283 412.575928,1734.711548 415.397858,1734.106567 
z"
        />
        <path
          fill="#C4BDAE"
          opacity="1.000000"
          stroke="none"
          d="
M234.086792,1771.168701 
	C233.168640,1771.783203 232.655838,1771.514771 232.037872,1770.593628 
	C226.781387,1759.961548 229.498154,1749.214355 230.182434,1737.750854 
	C231.356598,1735.758179 232.425323,1735.173340 234.689484,1735.449097 
	C235.732620,1736.074219 235.908234,1736.364868 236.010773,1736.679321 
	C237.197601,1740.317017 243.246704,1738.555298 243.326263,1742.404053 
	C243.427795,1747.315918 237.861389,1744.613281 235.701096,1746.777954 
	C233.820145,1748.662842 234.277695,1750.250854 237.509338,1751.162476 
	C238.822266,1752.165405 239.048630,1753.218140 238.328979,1755.016357 
	C235.336227,1756.452271 232.848648,1758.980103 235.349564,1760.309326 
	C241.855072,1763.766724 237.414658,1766.066406 235.335022,1768.874390 
	C234.948975,1769.395630 234.673248,1769.998535 234.086792,1771.168701 
z"
        />
        <path
          fill="#E7E3DA"
          opacity="1.000000"
          stroke="none"
          d="
M235.778320,1734.868164 
	C234.871857,1735.776855 234.152725,1735.781128 232.893661,1735.778809 
	C227.666031,1729.626343 231.002274,1722.533813 230.026398,1715.119385 
	C230.552078,1709.464111 230.041565,1704.572021 231.027283,1698.955322 
	C231.469238,1690.848877 230.294510,1683.499390 232.378998,1675.516357 
	C233.243271,1674.394775 233.684402,1674.024902 234.958755,1673.881592 
	C236.338745,1694.058716 236.527908,1714.010254 235.778320,1734.868164 
z"
        />
        <path
          fill="#A6A29C"
          opacity="1.000000"
          stroke="none"
          d="
M238.756805,1755.787842 
	C237.887772,1754.683716 237.964401,1753.697388 238.104523,1751.958008 
	C246.898331,1748.407104 255.822311,1750.497314 264.651062,1750.226196 
	C266.439789,1750.171265 267.627502,1751.542969 267.345703,1753.540039 
	C267.124481,1755.107544 265.848846,1755.715088 264.493805,1755.732666 
	C256.230682,1755.839966 247.966385,1755.858032 238.756805,1755.787842 
z"
        />
        <path
          fill="#46565D"
          opacity="1.000000"
          stroke="none"
          d="
M407.664398,1735.361572 
	C407.156494,1740.247437 405.394257,1743.452515 400.445160,1739.595581 
	C396.570190,1736.575806 393.107880,1740.205322 388.660461,1740.099243 
	C387.040680,1739.419556 386.189758,1738.693481 384.769775,1737.856323 
	C383.403320,1737.068115 383.040070,1736.207642 383.531128,1734.546753 
	C384.407806,1733.443115 384.920441,1733.025024 385.924133,1732.431641 
	C393.435608,1731.683838 400.166687,1733.857910 407.664398,1735.361572 
z"
        />
        <path
          fill="#FBF9EC"
          opacity="1.000000"
          stroke="none"
          d="
M227.994965,1648.895752 
	C229.621872,1647.939209 231.323975,1647.901367 233.878418,1647.939209 
	C234.578903,1655.405884 234.427048,1662.796753 234.294632,1671.110596 
	C232.874435,1673.179199 231.290680,1673.522827 228.870239,1672.355469 
	C228.162415,1664.483765 228.116302,1657.149048 227.994965,1648.895752 
z"
        />
        <path
          fill="#8C8D89"
          opacity="1.000000"
          stroke="none"
          d="
M243.907013,1674.586914 
	C243.889389,1673.631104 243.944382,1672.720459 244.146759,1672.311523 
	C245.221115,1672.364136 246.755127,1672.704224 246.865280,1673.305420 
	C248.390381,1681.628906 250.063950,1690.005127 244.945267,1698.351318 
	C244.071136,1690.932129 243.997894,1683.237427 243.907013,1674.586914 
z"
        />
        <path
          fill="#C4BDAE"
          opacity="1.000000"
          stroke="none"
          d="
M228.141907,1672.757324 
	C229.732468,1671.865845 231.256409,1671.913452 233.547211,1671.997314 
	C235.010849,1672.126465 235.471146,1672.506226 235.743469,1673.640503 
	C235.791992,1674.108276 234.967133,1674.071289 234.557343,1674.021362 
	C231.488007,1680.862183 233.970856,1688.108643 232.172318,1695.748291 
	C231.123932,1696.547974 230.588959,1696.693726 229.262634,1696.583618 
	C227.293793,1688.802246 228.401321,1681.236694 228.141907,1672.757324 
z"
        />
        <path
          fill="#E7E3DA"
          opacity="1.000000"
          stroke="none"
          d="
M243.913818,1700.542603 
	C247.026321,1700.261230 246.824646,1702.901855 246.909607,1705.092773 
	C247.114456,1710.375122 247.053406,1715.667603 247.364624,1721.586670 
	C247.562485,1724.146484 247.495270,1726.075439 247.270660,1728.706543 
	C243.517548,1729.774170 243.756470,1727.167603 243.757584,1724.924927 
	C243.761459,1717.119385 243.850418,1709.313721 243.913818,1700.542603 
z"
        />
        <path
          fill="#646A6C"
          opacity="1.000000"
          stroke="none"
          d="
M383.050049,1733.837524 
	C383.995819,1734.780396 384.040619,1735.631104 384.143097,1737.113525 
	C377.204010,1740.400269 370.456024,1740.844604 364.183716,1734.807861 
	C370.028290,1732.577393 376.103790,1733.926514 383.050049,1733.837524 
z"
        />
        <path
          fill="#A6A29C"
          opacity="1.000000"
          stroke="none"
          d="
M312.561707,1757.396240 
	C308.563629,1757.219116 302.752930,1759.662964 302.986694,1754.937744 
	C303.242279,1749.771851 309.290771,1752.623657 312.818359,1752.169189 
	C314.274017,1751.981445 316.477081,1751.917480 316.636108,1754.148560 
	C316.778015,1756.140381 314.626373,1756.371094 312.561707,1757.396240 
z"
        />
        <path
          fill="#D0D1C8"
          opacity="1.000000"
          stroke="none"
          d="
M295.102783,1709.593506 
	C295.487762,1711.580566 295.563171,1713.025269 295.648926,1715.194214 
	C296.629883,1716.731812 297.026733,1717.765381 296.339722,1719.736572 
	C295.810547,1721.090088 295.809631,1721.726440 295.833923,1722.836792 
	C292.198608,1724.480469 292.073364,1721.899902 291.699341,1718.795898 
	C291.873779,1713.532227 292.126770,1708.973511 292.386963,1704.285522 
	C295.251709,1704.996338 293.731201,1707.657837 295.102783,1709.593506 
z"
        />
        <path
          fill="#A6A29C"
          opacity="1.000000"
          stroke="none"
          d="
M329.002289,1742.392700 
	C330.366455,1742.232056 331.026398,1742.182129 331.682037,1742.215820 
	C334.055603,1742.337524 336.632416,1742.651245 337.034241,1745.449341 
	C337.337189,1747.558960 335.243805,1748.000488 333.588928,1747.835083 
	C330.669342,1747.543091 326.312134,1748.826050 327.532379,1743.139404 
	C327.584442,1742.896729 328.032501,1742.739014 329.002289,1742.392700 
z"
        />
        <path
          fill="#A6A29C"
          opacity="1.000000"
          stroke="none"
          d="
M250.244263,1775.075928 
	C246.232971,1775.472778 241.811142,1777.613403 238.329376,1772.674927 
	C240.017349,1769.136353 243.285187,1770.077026 246.992950,1769.837646 
	C249.277557,1771.005615 250.023163,1772.595215 250.244263,1775.075928 
z"
        />
        <path
          fill="#959693"
          opacity="1.000000"
          stroke="none"
          d="
M295.265869,1745.927002 
	C292.099426,1745.596069 289.325043,1746.917236 286.833588,1743.785889 
	C290.648132,1741.118774 294.766968,1741.010498 298.120636,1743.201538 
	C300.108246,1744.500122 297.473938,1745.470581 295.265869,1745.927002 
z"
        />
        <path
          fill="#B6AFA3"
          opacity="1.000000"
          stroke="none"
          d="
M270.574585,1741.138428 
	C272.150421,1744.941284 270.287384,1745.793457 267.563568,1745.699585 
	C265.716553,1745.635864 264.246552,1744.589966 264.679993,1742.644653 
	C265.368683,1739.553833 267.660858,1739.704712 270.574585,1741.138428 
z"
        />
        <path
          fill="#C4BDAE"
          opacity="1.000000"
          stroke="none"
          d="
M361.760071,1774.550781 
	C364.207153,1776.173706 364.829163,1777.922607 362.648621,1779.284180 
	C361.307739,1780.121582 359.269531,1779.933716 358.522919,1778.281738 
	C357.541138,1776.109253 358.581116,1774.654419 361.760071,1774.550781 
z"
        />
        <path
          fill="#C7C0B1"
          opacity="1.000000"
          stroke="none"
          d="
M342.208130,1733.571289 
	C343.246826,1735.871948 342.986389,1737.487427 340.844421,1737.640137 
	C339.462860,1737.738525 338.470001,1736.364380 338.529938,1734.993652 
	C338.618896,1732.956909 339.800262,1732.151123 342.208130,1733.571289 
z"
        />
        <path
          fill="#646A6C"
          opacity="1.000000"
          stroke="none"
          d="
M384.017029,1772.767456 
	C384.770172,1771.960083 385.544891,1771.820190 386.877563,1771.494141 
	C387.846558,1773.209106 388.031219,1775.097046 386.657684,1777.459717 
	C384.464874,1776.962524 384.095245,1775.273071 384.017029,1772.767456 
z"
        />
        <path
          fill="#646A6C"
          opacity="1.000000"
          stroke="none"
          d="
M386.027405,1782.432617 
	C387.703064,1783.323608 387.913757,1785.061279 387.625122,1787.581055 
	C384.292084,1787.377563 384.074554,1785.482178 386.027405,1782.432617 
z"
        />
        <path
          fill="#E7E3DA"
          opacity="1.000000"
          stroke="none"
          d="
M250.581909,1775.773682 
	C249.092392,1774.343506 247.802521,1773.083130 247.772263,1770.514648 
	C251.044022,1769.465820 252.624481,1770.802612 252.067551,1774.960449 
	C251.963364,1775.717896 251.042984,1775.809570 250.581909,1775.773682 
z"
        />
        <path
          fill="#A6A29C"
          opacity="1.000000"
          stroke="none"
          d="
M243.809708,1762.528320 
	C243.599365,1763.515259 243.293457,1764.031860 242.987549,1764.548584 
	C242.682495,1764.032837 242.102081,1763.498535 242.135590,1763.005737 
	C242.188446,1762.228638 242.238831,1760.757446 243.809708,1762.528320 
z"
        />
        <path
          fill="#8C8D89"
          opacity="1.000000"
          stroke="none"
          d="
M280.088043,1733.938721 
	C280.358276,1734.195312 280.548370,1734.524292 280.738464,1734.853271 
	C280.548981,1734.785156 280.359497,1734.717041 280.017303,1734.410767 
	C279.864563,1734.172607 280.007904,1734.011108 280.088043,1733.938721 
z"
        />
        <path
          fill="#8C8D89"
          opacity="1.000000"
          stroke="none"
          d="
M690.487244,1716.440796 
	C690.084412,1715.577759 690.179565,1715.217651 690.084717,1714.917725 
	C688.512268,1709.949219 697.856506,1710.283813 695.064392,1705.719604 
	C693.097412,1702.504272 692.020996,1699.236206 690.946106,1695.825073 
	C690.552551,1694.576416 690.230896,1693.239258 690.252441,1691.948608 
	C690.267273,1691.058594 690.688721,1689.853027 691.352356,1689.365845 
	C692.577332,1688.466675 693.946716,1689.157104 694.889160,1690.116821 
	C695.794800,1691.039062 697.208984,1692.331665 697.073364,1693.269775 
	C695.944275,1701.082642 700.183716,1709.008057 696.235718,1717.493652 
	C694.117065,1718.346436 692.316833,1718.400513 690.487244,1716.440796 
z"
        />
        <path
          fill="#A6A29C"
          opacity="1.000000"
          stroke="none"
          d="
M679.953674,1714.515137 
	C680.320862,1708.539185 678.944580,1702.746460 682.049194,1697.386597 
	C684.695312,1702.858643 683.777039,1708.599365 684.001953,1715.111816 
	C684.037170,1716.012817 683.997742,1716.009155 684.006226,1716.023926 
	C683.231323,1716.713013 682.328857,1716.971680 680.767700,1716.303955 
	C680.228210,1715.793701 680.052979,1714.939575 679.953674,1714.515137 
z"
        />
        <path
          fill="#797771"
          opacity="1.000000"
          stroke="none"
          d="
M680.167847,1716.661011 
	C681.068787,1715.852295 681.909363,1715.911011 683.382324,1716.004150 
	C683.671814,1720.239746 684.903870,1724.595459 682.593445,1729.319824 
	C678.255981,1725.798584 680.834839,1721.265381 680.167847,1716.661011 
z"
        />
        <path
          fill="#797771"
          opacity="1.000000"
          stroke="none"
          d="
M692.114807,1730.513672 
	C692.694092,1727.804565 693.765015,1725.460449 695.389343,1728.615845 
	C696.564209,1730.898071 696.798096,1734.146240 694.377991,1736.704468 
	C691.254089,1735.796387 692.437256,1733.175659 692.114807,1730.513672 
z"
        />
        <path
          fill="#797771"
          opacity="1.000000"
          stroke="none"
          d="
M672.073853,1734.001221 
	C671.693237,1735.907471 671.157532,1737.508301 669.675293,1736.867676 
	C667.509766,1735.931763 667.940735,1733.759277 668.403320,1731.907349 
	C668.720825,1730.636353 669.712219,1729.302490 670.956421,1729.943237 
	C671.719116,1730.335938 671.747070,1732.155518 672.073853,1734.001221 
z"
        />
        <path
          fill="#5A6668"
          opacity="1.000000"
          stroke="none"
          d="
M180.416000,1755.839355 
	C177.136169,1755.739502 174.576157,1751.839600 172.879074,1757.691040 
	C171.077576,1763.902466 164.226593,1762.196045 158.824341,1763.994141 
	C158.702774,1755.004395 159.356659,1746.009033 160.052429,1736.100586 
	C166.670807,1737.425171 173.116714,1740.232910 181.126572,1740.032227 
	C186.313431,1745.533081 185.992279,1750.622314 180.416000,1755.839355 
z"
        />
        <path
          fill="#5A6668"
          opacity="1.000000"
          stroke="none"
          d="
M153.338776,1770.102051 
	C153.847260,1771.857788 153.304840,1773.387817 150.579742,1773.850342 
	C150.155731,1772.128540 150.766937,1770.655640 153.338776,1770.102051 
z"
        />
        <path
          fill="#A9ACA9"
          opacity="1.000000"
          stroke="none"
          d="
M708.064453,1695.320190 
	C707.318604,1701.005371 709.229065,1706.331421 705.560425,1712.305420 
	C702.914001,1705.018433 702.367615,1699.114868 704.069702,1693.009155 
	C704.374207,1691.916626 705.091125,1691.331909 706.161438,1691.497192 
	C707.859436,1691.759399 707.787781,1693.287231 708.064453,1695.320190 
z"
        />
        <path
          fill="#A9ACA9"
          opacity="1.000000"
          stroke="none"
          d="
M668.174561,1701.066650 
	C667.966370,1699.382812 667.577576,1698.200195 667.993958,1697.489136 
	C669.283752,1695.286743 664.554565,1690.567749 669.859619,1690.791138 
	C673.567139,1690.947266 671.894958,1695.572632 671.821350,1698.206787 
	C671.775757,1699.839722 673.637634,1704.694946 668.174561,1701.066650 
z"
        />
        <path
          fill="#A9ACA9"
          opacity="1.000000"
          stroke="none"
          d="
M841.957397,1783.215576 
	C840.297852,1784.046143 838.642456,1784.224365 836.438232,1784.702148 
	C835.734680,1785.476929 835.579895,1785.952271 834.710693,1786.656494 
	C833.519775,1786.732300 833.043335,1786.579224 832.324829,1785.715820 
	C829.924561,1783.763306 827.532166,1783.927368 824.469482,1783.802490 
	C822.126770,1781.910767 821.911133,1779.844849 823.289246,1776.960449 
	C827.122375,1775.584961 831.277039,1777.802856 833.817627,1772.816040 
	C834.789429,1772.245361 835.533875,1772.394897 836.786987,1772.802124 
	C837.322021,1776.978271 843.665039,1777.795654 841.957397,1783.215576 
z"
        />
        <path
          fill="#6F7981"
          opacity="1.000000"
          stroke="none"
          d="
M270.731873,1650.894531 
	C274.209503,1650.008911 275.703857,1651.656494 275.709961,1654.741699 
	C275.715027,1657.291138 275.665009,1659.840454 275.527069,1663.271240 
	C272.956726,1670.209717 273.479034,1676.523438 274.710236,1682.593018 
	C276.247009,1690.169067 276.815399,1697.566895 274.668335,1705.051758 
	C273.820435,1708.007690 273.994720,1710.914795 274.965546,1713.814575 
	C276.224396,1717.574585 275.644806,1720.704590 270.669495,1723.982178 
	C269.354462,1709.776489 270.309479,1696.758301 269.993164,1682.854126 
	C267.514374,1671.813354 269.828735,1661.788330 270.731873,1650.894531 
z"
        />
        <path
          fill="#EFF1EA"
          opacity="1.000000"
          stroke="none"
          d="
M270.562378,1650.124146 
	C270.765533,1660.074707 270.491333,1670.096802 270.086334,1681.015625 
	C267.498474,1679.970703 268.038239,1677.218872 268.042664,1674.645386 
	C268.055725,1667.090820 268.123138,1659.536255 268.161743,1651.061523 
	C268.799988,1650.083130 269.443573,1650.101318 270.562378,1650.124146 
z"
        />
        <path
          fill="#EEE9DC"
          opacity="1.000000"
          stroke="none"
          d="
M823.148315,1775.948730 
	C823.877014,1778.301758 823.949097,1780.288452 823.890503,1783.012207 
	C819.756104,1783.835815 815.752319,1783.922485 810.886719,1784.016602 
	C809.772827,1783.425293 809.408630,1782.904785 808.518005,1782.221924 
	C807.475525,1776.868896 808.855835,1773.759155 814.872803,1774.846069 
	C817.375183,1775.297974 819.949951,1775.348999 823.148315,1775.948730 
z"
        />
        <path
          fill="#959693"
          opacity="1.000000"
          stroke="none"
          d="
M179.788742,1756.784668 
	C182.959717,1751.850830 181.508957,1746.705566 181.968231,1741.071533 
	C181.958984,1728.101807 181.966095,1715.954590 182.037109,1702.868164 
	C185.824142,1695.308838 191.472610,1694.044312 199.092865,1696.520264 
	C200.271851,1697.379272 200.672668,1697.818237 201.386612,1699.096558 
	C202.339005,1709.622070 201.845566,1719.319458 201.981140,1729.943604 
	C200.222778,1740.971680 201.510117,1751.193604 199.840057,1761.760620 
	C192.417068,1761.631836 185.062500,1764.193604 179.788742,1756.784668 
z"
        />
        <path
          fill="#7B7F84"
          opacity="1.000000"
          stroke="none"
          d="
M200.470291,1697.203613 
	C194.228073,1698.353760 188.145386,1696.861694 182.824860,1701.804565 
	C182.091232,1693.656128 181.931961,1685.379883 182.118607,1677.111572 
	C182.270035,1670.403320 183.007050,1669.764893 189.746948,1670.132568 
	C193.355133,1670.329468 196.930969,1671.119263 201.355316,1671.701416 
	C202.851715,1680.073608 202.160629,1688.326416 200.470291,1697.203613 
z"
        />
        <path
          fill="#959693"
          opacity="1.000000"
          stroke="none"
          d="
M199.930634,1697.933838 
	C200.463806,1689.714355 199.187943,1681.413452 202.111847,1672.622803 
	C202.189224,1671.761475 203.010269,1671.738403 203.419189,1671.735474 
	C203.856674,1679.952515 203.885254,1688.172363 203.844788,1697.334717 
	C203.045456,1698.840332 202.218277,1699.047363 200.755554,1698.576294 
	C200.203232,1698.204224 200.021866,1698.023682 199.930634,1697.933838 
z"
        />
        <path
          fill="#5A6668"
          opacity="1.000000"
          stroke="none"
          d="
M448.188477,1681.910645 
	C446.318268,1678.739990 446.018646,1676.317993 449.145264,1674.800171 
	C451.568420,1673.624023 453.700592,1674.522583 454.954803,1676.726196 
	C456.288147,1679.068726 456.836304,1682.121704 455.066071,1684.074463 
	C452.656128,1686.732910 450.572174,1683.873901 448.188477,1681.910645 
z"
        />
        <path
          fill="#49544F"
          opacity="1.000000"
          stroke="none"
          d="
M727.786255,1821.453613 
	C725.003174,1821.316528 723.263855,1820.293213 727.416809,1818.070557 
	C727.953064,1818.914795 727.919983,1819.840088 727.786255,1821.453613 
z"
        />
        <path
          fill="#8C8D89"
          opacity="1.000000"
          stroke="none"
          d="
M259.402069,1675.846313 
	C259.966644,1683.698242 259.630768,1691.090210 259.294922,1698.482300 
	C258.604828,1698.458130 257.914734,1698.433838 257.224640,1698.409668 
	C255.288559,1691.630371 255.574631,1684.749390 257.544586,1677.080566 
	C258.088654,1676.031616 258.295166,1675.709106 259.402069,1675.846313 
z"
        />
        <path
          fill="#8C8D89"
          opacity="1.000000"
          stroke="none"
          d="
M255.914429,1656.614258 
	C261.340515,1658.454224 259.066956,1653.008179 261.014771,1651.067261 
	C263.114471,1658.419312 261.652527,1666.349365 261.837067,1675.171143 
	C261.754913,1676.099854 261.015869,1676.122070 260.659180,1676.214111 
	C260.302490,1676.306152 260.029297,1676.037354 259.890839,1675.905029 
	C259.541168,1675.498413 259.329987,1675.224243 259.060150,1674.435059 
	C258.918274,1673.226562 258.686737,1672.586548 258.090576,1671.530273 
	C257.025177,1670.140991 256.974457,1669.051147 257.529968,1667.442627 
	C258.243073,1665.207642 257.682129,1663.692993 256.175293,1661.845947 
	C255.781967,1660.003174 255.788055,1658.643799 255.914429,1656.614258 
z"
        />
        <path
          fill="#8C8D89"
          opacity="1.000000"
          stroke="none"
          d="
M256.300049,1707.074463 
	C257.265442,1705.024170 258.663849,1704.595093 259.239197,1706.187378 
	C260.202606,1708.853516 258.443024,1711.174072 256.708374,1715.152100 
	C256.460144,1711.667847 256.319244,1709.690308 256.300049,1707.074463 
z"
        />
        <path
          fill="#E7E3DA"
          opacity="1.000000"
          stroke="none"
          d="
M255.813049,1662.172852 
	C260.381714,1660.511597 259.898773,1663.328613 258.574585,1666.609985 
	C256.009979,1666.242554 256.061981,1664.546631 255.813049,1662.172852 
z"
        />
        <path
          fill="#D7D5CF"
          opacity="1.000000"
          stroke="none"
          d="
M259.031311,1673.693970 
	C259.740936,1673.946777 259.817047,1674.859619 259.784729,1675.316162 
	C259.752380,1675.772583 258.816345,1676.059937 258.349243,1676.207031 
	C257.674957,1676.034180 257.467773,1675.714111 257.161133,1674.830566 
	C257.481720,1673.991821 257.901703,1673.716431 259.031311,1673.693970 
z"
        />
        <path
          fill="#E7E3DA"
          opacity="1.000000"
          stroke="none"
          d="
M259.746338,1673.266846 
	C259.295685,1673.992676 258.850464,1674.038574 257.733490,1674.175781 
	C256.963837,1673.673462 256.865997,1673.079834 257.321350,1671.773315 
	C258.856140,1671.131470 259.499939,1671.618164 259.746338,1673.266846 
z"
        />
        <path
          fill="#EFF1EA"
          opacity="1.000000"
          stroke="none"
          d="
M228.316101,1697.229248 
	C228.471268,1696.327759 229.380798,1696.219238 229.817963,1696.082764 
	C230.998627,1696.888428 231.490555,1697.947876 231.785065,1700.012329 
	C230.875427,1704.420288 231.513260,1708.022949 231.141968,1712.492920 
	C230.869858,1731.693726 231.176300,1749.982666 230.369522,1769.086426 
	C229.191864,1769.764404 228.495697,1769.625610 227.362045,1769.138672 
	C225.768234,1745.209717 227.565582,1721.680664 228.316101,1697.229248 
z"
        />
        <path
          fill="#A9AEB3"
          opacity="1.000000"
          stroke="none"
          d="
M637.039551,1659.066040 
	C636.962097,1659.107666 637.116943,1659.024414 637.039551,1659.066040 
z"
        />
        <path
          fill="#817A79"
          opacity="1.000000"
          stroke="none"
          d="
M296.494141,1720.407349 
	C295.736115,1719.445923 295.642303,1718.437988 295.603882,1716.674316 
	C296.435059,1715.957642 297.210876,1715.996826 298.558777,1716.061523 
	C299.554749,1718.010620 299.117188,1719.536743 296.494141,1720.407349 
z"
        />
        <path
          fill="#698196"
          opacity="1.000000"
          stroke="none"
          d="
M865.981934,2264.854736 
	C866.859741,2264.013916 867.755798,2264.007568 869.323486,2264.000732 
	C882.567017,2270.575195 896.477417,2268.379639 909.796021,2270.010254 
	C920.494873,2271.320312 930.881470,2272.735596 941.723267,2268.080811 
	C947.895020,2265.431152 955.900269,2268.126953 963.184631,2267.832031 
	C981.342468,2267.096680 999.074585,2262.352295 1014.635498,2262.824463 
	C1010.386292,2262.480225 1003.358643,2264.149658 995.902527,2261.241699 
	C992.535400,2258.145752 989.895142,2256.152832 985.610474,2258.085938 
	C976.092102,2262.380859 966.179016,2261.513184 956.288391,2259.510254 
	C952.963745,2258.836914 949.704468,2258.760254 946.472351,2259.925293 
	C943.268127,2261.080078 939.919067,2261.186035 936.611633,2260.843994 
	C932.912170,2260.460938 928.886658,2259.820312 928.837708,2254.924561 
	C928.794373,2250.594971 932.453125,2249.843750 935.771240,2249.191895 
	C938.720276,2248.612549 941.733215,2248.753906 944.725342,2248.692383 
	C951.599121,2248.551270 958.743164,2249.846924 964.191406,2243.636475 
	C966.141846,2241.413330 969.273010,2243.155029 971.834473,2242.886230 
	C977.893677,2242.250488 984.447876,2245.240479 990.596436,2240.009277 
	C992.765564,2238.736816 994.338806,2238.714844 996.633179,2239.652344 
	C1011.630981,2245.325928 1026.137085,2248.201416 1040.427612,2240.627686 
	C1048.249390,2236.482666 1055.093750,2236.964600 1063.184814,2240.203857 
	C1073.023926,2244.143311 1083.560913,2245.444824 1093.555664,2237.394531 
	C1098.872925,2234.283936 1103.976196,2234.300293 1109.973755,2234.833252 
	C1115.983521,2238.864258 1122.022827,2238.549072 1127.932007,2238.504883 
	C1140.489380,2238.410889 1153.035156,2238.417236 1165.416016,2240.875488 
	C1168.559937,2241.499756 1172.723145,2241.863281 1172.511963,2246.199219 
	C1172.300537,2250.537842 1168.188721,2250.590820 1164.939453,2250.774902 
	C1157.431885,2251.200928 1149.755249,2250.815186 1142.930786,2256.955078 
	C1134.272461,2260.126221 1126.118652,2258.585693 1117.269043,2258.586914 
	C1109.230591,2252.830322 1101.324585,2256.866699 1093.733643,2257.146729 
	C1088.730835,2257.331543 1091.490845,2261.515625 1090.906250,2264.728760 
	C1081.058960,2271.122314 1069.838745,2270.737549 1059.377441,2273.200684 
	C1052.041992,2274.927979 1044.207275,2274.452393 1037.946411,2275.035889 
	C1041.529419,2275.094727 1046.404663,2274.259277 1051.315552,2274.090088 
	C1054.672241,2273.974121 1058.572876,2273.861816 1059.045410,2278.349609 
	C1059.592407,2283.544922 1055.394897,2283.980225 1051.673096,2284.253418 
	C1048.690796,2284.472412 1045.734863,2285.052246 1044.057373,2284.967041 
	C1047.649292,2284.793213 1052.571045,2285.032471 1057.489136,2284.924072 
	C1063.029175,2284.802002 1069.090088,2286.812012 1069.506714,2277.019043 
	C1069.739014,2271.557617 1083.368896,2268.014160 1089.470825,2271.625488 
	C1101.397095,2278.684082 1113.374878,2277.238037 1126.043457,2274.274414 
	C1139.173340,2271.202881 1152.828613,2270.695068 1166.204102,2269.455566 
	C1183.993408,2267.807129 1201.795288,2265.607178 1219.527954,2264.524658 
	C1234.807617,2263.591797 1250.616699,2264.230469 1266.139526,2265.910889 
	C1277.962524,2267.190918 1289.776978,2268.812012 1301.697754,2268.927246 
	C1303.263916,2268.942383 1305.941162,2270.156494 1305.713379,2266.155762 
	C1301.675903,2260.153076 1295.906860,2259.855957 1290.253174,2259.500000 
	C1284.271606,2259.123047 1278.238647,2259.357178 1272.341553,2258.063721 
	C1270.058594,2257.562988 1267.564819,2256.860596 1267.378662,2253.968994 
	C1267.177490,2250.844482 1269.367188,2249.204590 1271.940918,2248.098633 
	C1273.481689,2247.437012 1275.193359,2247.019287 1276.827637,2247.258545 
	C1293.080933,2249.640869 1309.384277,2248.209229 1326.454590,2248.324219 
	C1331.791260,2252.603760 1329.218628,2256.635986 1327.121826,2261.416016 
	C1325.820679,2265.535889 1324.727295,2268.894775 1323.815186,2273.057373 
	C1321.168091,2277.173340 1318.233887,2280.381592 1318.970703,2285.793701 
	C1322.134399,2297.439697 1316.891968,2306.428223 1311.846436,2316.224121 
	C1310.665894,2317.701416 1309.854248,2318.417725 1308.219727,2319.423340 
	C1301.913452,2322.148193 1296.570312,2319.810547 1290.319336,2318.995605 
	C1282.756714,2318.562500 1276.135742,2319.307617 1268.730469,2320.965820 
	C1266.105103,2321.341064 1264.349976,2321.246826 1261.704590,2320.838867 
	C1241.726685,2316.810303 1222.546753,2319.907471 1203.424927,2320.204346 
	C1197.855469,2320.291016 1192.221558,2320.933838 1185.700806,2321.054932 
	C1170.096436,2323.185303 1155.504761,2326.831543 1139.625977,2324.009766 
	C1133.768677,2319.319824 1131.333008,2314.281738 1135.054932,2307.048828 
	C1141.925415,2302.655518 1149.227539,2301.976562 1155.780884,2303.365967 
	C1171.031494,2306.599365 1186.231567,2304.585205 1201.361084,2304.105225 
	C1213.572144,2303.717529 1225.833130,2303.194580 1234.419067,2302.922363 
	C1213.983032,2302.707275 1189.769531,2305.950439 1165.947021,2304.256348 
	C1145.704346,2302.817139 1126.644409,2307.781494 1106.138794,2308.516602 
	C1101.430786,2308.568604 1097.742798,2307.296143 1093.135254,2306.881348 
	C1085.256226,2305.259521 1077.979858,2304.711670 1071.210693,2301.509277 
	C1065.015381,2298.578613 1058.935181,2303.564941 1051.927612,2302.760742 
	C1047.211060,2302.713867 1043.670898,2297.495117 1038.625000,2301.937500 
	C1004.107727,2302.395752 970.715271,2310.967529 936.661926,2307.723145 
	C957.785156,2309.168213 978.861816,2307.410156 1000.765442,2307.339844 
	C1014.961243,2311.224854 1028.263062,2311.549805 1041.455200,2306.581787 
	C1044.031738,2305.611328 1047.050293,2305.116455 1049.148804,2306.734131 
	C1053.979370,2310.458008 1059.678223,2310.145020 1065.122925,2311.256348 
	C1073.232056,2312.911133 1074.328369,2315.869141 1069.610352,2324.253662 
	C1053.179443,2330.197021 1037.279053,2323.624268 1024.326782,2325.136230 
	C1039.014526,2325.840576 1056.596558,2327.843018 1074.937744,2325.709961 
	C1081.955566,2317.385986 1090.063965,2316.396973 1099.334229,2319.144531 
	C1105.811401,2321.064453 1109.302124,2324.292969 1105.750977,2332.030518 
	C1103.409058,2334.741699 1100.980835,2336.044434 1098.209839,2335.519531 
	C1083.813721,2332.793457 1070.080811,2340.000488 1055.746216,2338.245117 
	C1053.741333,2337.999512 1049.782837,2337.142334 1052.669067,2341.803467 
	C1053.147705,2342.576416 1052.415405,2343.938232 1051.571167,2344.370605 
	C1044.495483,2347.995117 1036.576294,2345.833008 1029.122559,2347.477051 
	C1024.569092,2348.481201 1022.555542,2343.849609 1020.319763,2339.985107 
	C1022.103394,2345.276611 1020.018311,2348.059814 1015.091248,2347.489990 
	C1004.117859,2346.220215 993.243652,2349.434326 982.221497,2347.532715 
	C974.268616,2346.160889 966.603638,2348.167969 958.776611,2350.613770 
	C944.001038,2355.230957 928.925964,2359.983887 912.559021,2354.488281 
	C908.831970,2354.057617 906.036926,2353.587158 906.013977,2348.717285 
	C906.357544,2347.655273 906.726868,2347.329834 907.783936,2346.748535 
	C917.112183,2347.011719 925.551453,2346.155762 933.885010,2342.882080 
	C931.022827,2340.479248 927.962585,2342.083984 925.121216,2341.855957 
	C922.323730,2341.631348 919.598022,2341.191162 918.926270,2337.978027 
	C918.162109,2334.322510 920.588745,2332.516113 923.576294,2331.310303 
	C930.914124,2328.349121 938.624451,2329.093018 945.032959,2329.044434 
	C936.597351,2328.831787 926.985657,2328.441162 917.671936,2332.156006 
	C914.316895,2333.494385 910.437012,2333.330566 906.602600,2330.544434 
	C897.578369,2328.753906 889.093384,2327.422363 880.613770,2326.057373 
	C878.621094,2325.736816 876.339844,2325.332031 876.708801,2322.645508 
	C877.060791,2320.082520 879.443481,2320.291016 881.400085,2320.005615 
	C883.889404,2319.642822 887.349060,2321.247070 888.612122,2318.307373 
	C889.939148,2315.219238 886.578979,2313.834717 884.619446,2312.272217 
	C882.705811,2310.746582 882.671570,2309.107178 883.306152,2307.007568 
	C884.139343,2304.250732 883.207764,2302.355469 880.105530,2302.236084 
	C877.093628,2302.119873 875.082703,2303.454590 874.363098,2306.586914 
	C873.828125,2308.915771 877.140808,2312.904053 873.259888,2313.357422 
	C868.045654,2313.966553 863.013855,2316.625488 857.658142,2315.381104 
	C854.596802,2314.669434 853.639648,2311.082275 856.351990,2309.389893 
	C860.961426,2306.513672 861.817505,2302.691162 862.272217,2297.727783 
	C862.867065,2291.234131 867.042542,2288.114746 873.758789,2288.056396 
	C879.045349,2288.010742 884.432678,2289.098145 889.534058,2289.111328 
	C898.272583,2289.134033 907.247070,2291.609131 915.904114,2288.282471 
	C917.775757,2287.563232 919.904175,2286.995361 919.232971,2284.298096 
	C918.732605,2282.286865 916.890381,2282.203857 915.234680,2282.078125 
	C911.194458,2281.771729 907.245667,2282.041260 903.632751,2284.153564 
	C896.361145,2288.404785 889.417603,2283.435547 882.297729,2283.307373 
	C881.013062,2283.284668 880.320068,2281.619629 880.090149,2280.278564 
	C879.527588,2276.996826 877.291321,2276.412842 874.380188,2275.933838 
	C866.683289,2274.666748 866.710632,2274.500488 865.981934,2264.854736 
z"
        />
        <path
          fill="#5D758A"
          opacity="1.000000"
          stroke="none"
          d="
M865.558105,2406.635498 
	C865.992065,2405.153564 865.987244,2404.278076 865.969238,2402.745605 
	C871.751892,2397.298096 876.574768,2400.101807 880.645203,2404.479004 
	C884.380188,2408.495605 887.442871,2409.098877 891.267334,2404.750488 
	C895.414978,2400.034912 900.944641,2399.257080 906.798889,2400.397461 
	C909.790710,2400.979980 912.678467,2401.496826 916.088806,2399.585449 
	C917.482117,2398.876221 918.324890,2398.603516 919.848145,2398.373535 
	C922.174927,2398.278076 923.869629,2398.374023 926.103882,2397.527344 
	C929.538025,2396.246582 932.157593,2396.699951 935.195984,2398.750000 
	C940.175110,2403.440674 944.720520,2406.656738 951.533020,2404.407471 
	C955.388245,2403.134766 959.659912,2404.436035 962.994629,2406.408691 
	C971.708496,2411.563232 981.020447,2411.916992 991.416260,2411.082520 
	C1007.424927,2411.253662 1022.693726,2410.078369 1038.625488,2412.676514 
	C1044.295654,2412.704346 1048.974609,2414.805176 1054.618530,2414.105957 
	C1067.463013,2414.006836 1079.651489,2415.798584 1090.257812,2412.973633 
	C1080.600952,2412.638916 1069.557861,2415.074219 1057.725342,2413.874023 
	C1054.401367,2413.019775 1051.682861,2411.891357 1052.059814,2408.677979 
	C1052.408936,2405.701660 1055.233643,2404.507812 1057.821167,2403.966797 
	C1063.958862,2402.682861 1070.668335,2400.513428 1076.356079,2401.807861 
	C1087.388550,2404.318848 1093.680298,2398.059326 1100.417114,2391.591553 
	C1099.092651,2388.834229 1098.807251,2386.635254 1100.313965,2383.807861 
	C1105.558105,2378.330078 1098.310669,2380.929932 1097.630737,2378.700928 
	C1095.082397,2369.680908 1087.341553,2372.518066 1081.427612,2370.133789 
	C1080.118530,2368.187256 1079.889282,2366.662109 1080.195801,2364.261230 
	C1084.184082,2356.979248 1089.932861,2356.511230 1097.080933,2358.592529 
	C1100.771362,2360.728760 1103.101196,2365.985596 1107.773682,2360.199219 
	C1109.150879,2357.277344 1110.001831,2354.857910 1112.595947,2352.598633 
	C1120.729736,2349.546631 1128.332764,2350.588135 1136.782959,2351.205078 
	C1153.663330,2353.787354 1169.812134,2353.908936 1186.780518,2353.974854 
	C1190.527344,2352.954102 1193.467163,2352.992188 1197.273804,2353.555176 
	C1207.835449,2358.697998 1218.285400,2355.880127 1228.395386,2356.657471 
	C1233.441650,2357.045898 1241.471191,2355.305908 1241.953613,2361.361816 
	C1242.557251,2368.939941 1233.823730,2367.024902 1228.875854,2368.153076 
	C1220.674194,2370.022949 1212.534058,2368.906006 1204.402100,2366.523926 
	C1198.240479,2364.718994 1191.570312,2363.027344 1186.030151,2370.071777 
	C1183.393311,2374.039062 1180.168457,2374.673828 1175.765625,2374.127197 
	C1164.772827,2366.748047 1154.135620,2360.831543 1140.275391,2362.635742 
	C1135.666138,2362.796387 1132.890747,2359.542725 1129.104004,2359.063477 
	C1127.785278,2358.896484 1126.974854,2358.672363 1128.100586,2359.159668 
	C1128.646606,2359.395996 1129.396973,2360.176270 1130.616333,2361.079102 
	C1133.919067,2365.291504 1137.340210,2363.421387 1140.882080,2362.965332 
	C1146.569214,2362.232910 1151.590332,2363.303711 1151.212158,2371.478027 
	C1148.624146,2375.354736 1145.238647,2376.024170 1141.557129,2375.968262 
	C1140.225220,2375.939209 1140.786499,2377.418213 1140.684448,2376.088867 
	C1140.679565,2376.024170 1140.971436,2375.972656 1141.555664,2376.006348 
	C1144.687866,2376.185303 1148.069214,2375.071777 1151.112305,2378.395020 
	C1153.310181,2386.552246 1148.615479,2388.601318 1142.673462,2389.003174 
	C1135.679321,2389.476074 1128.743530,2388.603027 1123.478149,2387.303467 
	C1136.476074,2389.109375 1150.387451,2391.660156 1165.721802,2387.576172 
	C1180.210449,2383.717773 1196.147339,2386.520996 1211.440186,2387.261963 
	C1212.435913,2387.310059 1213.449585,2387.757324 1214.405884,2387.640137 
	C1242.853271,2384.152344 1271.409546,2388.683350 1299.869751,2386.452148 
	C1302.561401,2386.241211 1305.093872,2385.406982 1307.496338,2384.204590 
	C1313.608032,2381.145752 1319.650757,2381.085693 1326.277588,2385.093262 
	C1329.832031,2392.090820 1331.930176,2398.662354 1329.813843,2406.597412 
	C1321.915649,2414.087646 1311.802612,2412.362305 1303.851807,2411.686768 
	C1291.837158,2410.666260 1280.917236,2413.550293 1269.508911,2414.867676 
	C1264.168335,2415.484375 1255.414917,2421.316895 1255.549438,2408.457520 
	C1255.565308,2406.928467 1252.106934,2407.191162 1250.232788,2406.747559 
	C1237.736328,2403.791260 1225.023438,2406.121582 1212.456909,2405.877197 
	C1201.459351,2405.662842 1190.223389,2405.104492 1179.166870,2406.922852 
	C1187.775879,2406.397705 1196.914429,2405.245605 1204.868042,2407.343506 
	C1220.545288,2411.478760 1236.516357,2412.053711 1252.300781,2414.518799 
	C1255.524902,2415.021973 1258.742554,2415.969971 1262.761230,2416.625977 
	C1265.872070,2419.183350 1265.362793,2421.757812 1263.701660,2424.922119 
	C1249.601196,2428.671143 1235.955444,2426.299805 1222.388062,2426.641357 
	C1202.839966,2427.132568 1183.540283,2424.409424 1164.200439,2422.208252 
	C1163.526367,2422.131348 1162.543457,2421.603027 1162.532837,2421.647461 
	C1159.208008,2435.694092 1146.289307,2427.202881 1138.622314,2431.630859 
	C1130.162109,2436.517334 1119.849976,2435.154297 1110.206909,2435.337158 
	C1103.233032,2435.469482 1096.237183,2435.000000 1089.325562,2436.323730 
	C1114.837158,2436.338623 1140.533447,2438.154053 1165.868042,2428.623779 
	C1171.830444,2433.347168 1176.988159,2437.021973 1184.888550,2434.900146 
	C1189.284058,2433.719727 1193.221191,2436.171631 1193.128784,2442.555176 
	C1190.849243,2446.396240 1187.620850,2446.532959 1184.378906,2447.102539 
	C1177.732178,2448.270264 1171.036865,2449.423340 1164.043213,2452.633789 
	C1148.372925,2453.730713 1133.413086,2451.498535 1117.840454,2454.881836 
	C1105.751953,2454.885254 1094.609619,2454.631348 1083.398193,2453.290527 
	C1075.439819,2452.338623 1069.966431,2445.990479 1061.303955,2445.567383 
	C1052.125244,2446.968018 1043.708374,2447.538086 1034.397949,2447.322266 
	C1031.873535,2447.172363 1030.256592,2447.498779 1027.870850,2448.294678 
	C1022.642578,2449.249023 1018.238831,2449.563721 1013.230957,2447.641602 
	C1008.439514,2441.150146 1013.137024,2438.988037 1017.437744,2437.169922 
	C1022.452209,2435.050293 1027.871826,2436.302002 1031.831543,2436.036377 
	C1016.381287,2434.275635 1001.533569,2441.768799 985.249268,2445.973145 
	C975.706482,2447.792969 966.813965,2446.677734 957.192200,2446.925293 
	C951.358276,2445.749023 943.739380,2448.341553 944.764282,2438.322998 
	C944.935669,2436.647705 941.173889,2437.285156 939.574463,2437.772949 
	C930.843201,2440.434082 921.849426,2439.616455 912.236084,2440.192871 
	C906.168152,2438.681396 900.395020,2438.994141 894.706665,2435.929688 
	C890.254517,2430.987305 883.613342,2427.076416 888.939392,2420.619873 
	C884.583984,2423.114014 878.632996,2423.884033 871.842773,2420.905273 
	C868.910950,2416.205322 865.260742,2412.536377 865.558105,2406.635498 
z"
        />
        <path
          fill="#597288"
          opacity="1.000000"
          stroke="none"
          d="
M1137.431152,2321.504883 
	C1153.057739,2322.717041 1167.826050,2320.204834 1183.490601,2318.277344 
	C1188.075073,2322.402588 1192.993286,2320.969727 1197.453735,2320.920654 
	C1218.554199,2320.688232 1239.591309,2318.331543 1261.415649,2318.447998 
	C1263.557617,2317.936035 1265.006348,2317.930664 1267.179199,2317.944092 
	C1271.603149,2321.127686 1276.880127,2315.770996 1280.581299,2321.037842 
	C1280.178345,2326.100342 1276.569458,2326.559082 1273.407959,2327.259521 
	C1244.799316,2333.597656 1216.111450,2339.191895 1186.541138,2335.598633 
	C1181.541748,2334.990967 1176.578613,2335.584961 1171.632446,2336.376465 
	C1166.003906,2337.277344 1160.375977,2337.531006 1154.791016,2336.031250 
	C1146.693604,2333.856445 1138.395752,2329.463379 1132.353394,2340.141846 
	C1130.887939,2342.731201 1126.807495,2341.533203 1123.851318,2341.738281 
	C1120.007446,2342.005127 1115.587036,2341.077881 1113.292725,2346.710938 
	C1109.672241,2351.281738 1105.438965,2351.217773 1100.300537,2350.149902 
	C1098.056274,2349.102539 1096.900024,2347.941895 1096.760986,2346.183350 
	C1096.485352,2342.695557 1095.003906,2340.798096 1091.200806,2341.043457 
	C1086.759888,2341.329590 1087.674561,2344.923096 1086.565796,2348.101562 
	C1080.494995,2353.274902 1074.270264,2347.547119 1068.061401,2349.934082 
	C1068.685425,2353.491455 1073.672729,2354.066650 1072.754761,2359.058350 
	C1066.626099,2364.212158 1059.853516,2365.195801 1052.841309,2364.077637 
	C1049.199951,2363.496826 1045.739258,2362.303955 1041.612549,2364.761475 
	C1034.848389,2366.798828 1028.770630,2363.809326 1022.040833,2364.619873 
	C1018.501282,2353.881104 1019.717285,2352.416260 1031.608643,2352.003662 
	C1022.367004,2351.828613 1022.332764,2351.789062 1015.559875,2357.636963 
	C1012.709656,2360.097656 1009.549194,2361.538818 1005.770752,2361.775146 
	C1005.001892,2361.823242 1003.938293,2360.602051 1003.375122,2362.121094 
	C1004.340515,2362.357910 1005.234802,2362.760986 1006.565308,2363.842285 
	C1007.715942,2365.848145 1007.787903,2367.338623 1007.053101,2369.562012 
	C1002.648438,2373.724365 997.616150,2373.941650 993.610718,2371.613037 
	C986.450073,2367.450928 979.625916,2368.646973 972.494751,2370.722656 
	C971.219421,2371.094238 969.918274,2371.407227 968.604004,2371.593018 
	C964.681030,2372.146973 959.997620,2373.557617 957.791138,2369.240723 
	C955.561035,2364.877930 960.811890,2363.370361 963.677063,2359.376221 
	C948.014343,2358.980957 933.560181,2360.789062 919.349060,2364.736084 
	C913.215027,2366.439941 907.208435,2368.504150 900.505005,2364.580566 
	C900.301392,2360.238770 902.346741,2358.294922 906.103882,2358.057373 
	C908.291382,2357.919189 910.667542,2358.099609 911.849365,2354.726562 
	C925.350037,2354.580811 938.521851,2353.488770 951.451477,2350.057617 
	C953.684082,2349.465088 956.133972,2349.088379 958.016296,2347.882568 
	C966.568848,2342.403320 976.034302,2343.575195 985.180237,2344.498047 
	C989.839661,2344.968018 994.083069,2345.356445 998.784302,2344.397461 
	C1005.213867,2343.086182 1011.890686,2343.838379 1018.574768,2345.230713 
	C1019.415833,2338.875488 1013.327515,2341.191406 1011.479919,2338.632080 
	C1017.207947,2334.650391 1020.991882,2334.848877 1023.597351,2340.387939 
	C1025.985229,2345.464355 1028.050415,2346.677979 1033.443115,2343.979004 
	C1038.587280,2341.404297 1044.749268,2343.377686 1050.967896,2343.477295 
	C1050.213623,2338.225830 1042.677979,2341.313232 1043.167969,2335.206787 
	C1053.017456,2336.475586 1062.848145,2333.453369 1072.787964,2335.295166 
	C1082.216309,2328.656982 1092.703613,2333.651611 1103.319336,2332.289062 
	C1104.973511,2331.128662 1106.550659,2330.541504 1106.986084,2329.541016 
	C1113.524902,2314.517334 1125.579224,2319.200195 1137.431152,2321.504883 
z"
        />
        <path
          fill="#50677C"
          opacity="1.000000"
          stroke="none"
          d="
M871.397461,2419.451172 
	C878.475281,2419.638672 885.151062,2421.438965 891.068909,2416.951904 
	C892.623230,2415.773682 894.824097,2417.101807 896.181763,2418.831055 
	C894.811279,2421.987793 890.953308,2421.152832 888.946411,2423.429443 
	C888.427185,2427.584229 893.502747,2428.836182 893.939453,2433.249023 
	C892.274231,2436.250244 889.698975,2437.147217 887.045715,2437.164551 
	C862.898132,2437.321777 838.991333,2440.388916 815.038696,2442.924072 
	C813.725220,2443.062988 812.398376,2442.802490 810.350647,2442.168457 
	C804.798950,2437.538574 807.219177,2435.406250 810.564270,2434.366211 
	C793.880249,2435.385254 775.637756,2434.782227 759.593933,2437.288330 
	C759.957458,2437.291748 762.115051,2437.936035 764.404297,2440.019043 
	C765.894897,2444.326660 768.430603,2445.225098 772.155334,2445.075439 
	C776.599243,2444.896484 781.247253,2444.367676 785.698181,2447.659180 
	C785.278076,2452.730469 782.769897,2454.749756 778.286682,2453.098389 
	C771.875916,2450.737793 764.862244,2451.178955 758.614807,2451.455078 
	C752.834595,2451.710205 746.520325,2453.659912 740.450928,2454.935547 
	C732.251526,2456.659180 724.144409,2455.951904 715.127014,2455.555420 
	C716.597656,2452.178223 718.240540,2449.936768 721.246643,2450.920654 
	C730.716736,2454.018555 739.457031,2450.056641 748.492065,2449.225342 
	C748.432678,2445.493652 745.949951,2446.089600 744.179626,2446.070068 
	C734.525330,2445.963867 724.863281,2445.770264 715.215576,2446.033691 
	C701.877930,2446.397461 688.810364,2444.508301 675.691162,2442.482178 
	C668.829041,2441.422119 661.703674,2441.146973 654.857788,2442.842041 
	C650.584595,2443.900146 647.158264,2444.759033 645.309814,2439.333984 
	C644.323914,2436.440430 641.393372,2436.073730 638.611755,2436.014893 
	C635.265015,2435.943848 632.075867,2436.508789 628.797363,2437.333984 
	C610.286621,2441.991699 591.395020,2442.543457 572.440063,2441.876465 
	C570.171021,2441.796875 567.933044,2440.835205 564.857544,2440.165771 
	C563.404541,2439.784424 562.880188,2439.382324 562.217896,2438.406738 
	C567.784485,2430.505371 576.238525,2434.497070 583.422424,2433.413330 
	C586.762573,2432.909424 590.562500,2433.378418 595.021118,2433.069336 
	C601.392090,2432.671875 606.946045,2432.934814 613.205933,2431.846191 
	C622.179016,2431.183594 630.331421,2429.159424 638.487061,2431.945557 
	C638.785400,2432.047119 639.151794,2431.999268 639.478821,2431.959473 
	C646.835388,2431.061768 654.024658,2427.267334 661.655518,2431.727295 
	C663.784912,2432.971680 666.605408,2430.451904 669.077026,2429.526123 
	C676.059448,2426.912354 676.463501,2427.246338 676.456421,2434.823975 
	C676.455872,2435.383789 677.011353,2435.944336 677.402405,2436.681641 
	C681.462708,2436.481934 679.075439,2430.417480 683.384521,2430.143311 
	C686.987244,2429.913574 689.870117,2432.711182 692.612732,2432.713867 
	C703.013245,2432.724121 713.048157,2435.746338 723.517151,2435.351562 
	C735.006042,2434.918213 744.346008,2427.373779 755.745056,2426.887451 
	C758.973877,2426.749512 762.280396,2426.083496 765.592529,2426.013184 
	C771.870667,2425.879883 778.170593,2426.511230 784.343750,2424.410156 
	C787.146606,2423.456299 790.279297,2423.752686 793.185852,2424.720947 
	C796.392517,2425.789062 799.584045,2426.539795 803.420593,2424.523438 
	C810.810486,2422.291260 817.730835,2422.382568 825.337158,2423.501221 
	C832.536804,2424.441162 832.031799,2419.866455 831.974731,2414.805664 
	C833.369568,2412.858154 835.062500,2412.569336 836.559631,2413.325684 
	C845.644653,2417.913818 855.184937,2419.048340 865.165100,2417.557861 
	C867.126953,2417.265137 869.062134,2417.812012 871.397461,2419.451172 
z"
        />
        <path
          fill="#698196"
          opacity="1.000000"
          stroke="none"
          d="
M574.012634,2364.718262 
	C580.216370,2360.688232 587.314941,2360.873047 593.344360,2362.434082 
	C604.079712,2365.213623 614.405762,2363.248535 624.576660,2361.558838 
	C633.146973,2360.135010 641.592651,2360.385498 651.017883,2359.929688 
	C660.190552,2356.488770 669.095032,2356.913086 678.564575,2355.471680 
	C702.635925,2354.924072 725.794067,2353.259033 748.993103,2352.691162 
	C753.595215,2352.578369 758.368408,2352.108398 763.235962,2355.380371 
	C768.254211,2356.080078 773.280701,2355.425049 770.988647,2362.692627 
	C770.342407,2364.741211 771.977356,2365.486084 773.751343,2365.914551 
	C775.478760,2366.331787 777.897278,2365.279785 778.885132,2368.889160 
	C767.752502,2371.349854 757.005676,2374.933838 745.683411,2369.258789 
	C741.142456,2366.982666 736.038757,2367.520508 730.576416,2369.445557 
	C729.022278,2371.019287 727.828125,2371.590088 725.738770,2371.659180 
	C722.881287,2371.142090 720.726807,2371.140137 717.943604,2372.271484 
	C710.072144,2373.430176 702.867859,2372.965332 694.930786,2372.701660 
	C688.811462,2369.550293 684.528625,2371.199219 680.165771,2376.012695 
	C668.813477,2376.409424 658.218567,2377.600830 647.647034,2377.413330 
	C627.367615,2377.054443 607.145020,2379.328613 586.853210,2378.266357 
	C582.578857,2378.042725 578.913696,2377.885254 576.371338,2372.847412 
	C575.031006,2369.910889 573.870911,2367.860352 574.012634,2364.718262 
z"
        />
        <path
          fill="#698196"
          opacity="1.000000"
          stroke="none"
          d="
M899.123962,2364.000000 
	C909.910767,2365.884521 918.523193,2360.423828 927.854553,2359.100098 
	C940.263367,2357.339844 952.663147,2355.185303 965.292114,2356.014893 
	C967.518066,2356.161133 970.431519,2355.447510 971.217041,2358.344971 
	C972.105774,2361.623291 968.879333,2361.661133 966.956848,2362.645996 
	C964.136780,2364.091064 960.880127,2364.934814 958.973450,2367.565918 
	C960.348145,2370.640869 962.486938,2369.944824 964.309021,2369.528809 
	C969.138855,2368.426270 973.970154,2367.282715 978.714661,2365.866699 
	C983.930664,2364.309814 988.569153,2362.752686 992.941101,2368.374268 
	C995.532043,2371.705811 1000.463684,2369.603027 1005.204529,2369.919922 
	C1009.210938,2366.750488 1013.229553,2366.925049 1017.195190,2367.015625 
	C1030.633667,2367.321777 1044.114014,2366.865234 1057.385620,2369.835205 
	C1061.649902,2370.789307 1065.940918,2369.559570 1070.074951,2368.529785 
	C1073.685913,2367.630127 1077.129150,2366.803955 1081.256348,2369.400391 
	C1084.154663,2369.369873 1086.340698,2368.639404 1088.586304,2368.277588 
	C1095.898071,2367.100830 1097.126465,2368.193604 1097.948486,2377.081055 
	C1090.713379,2381.991943 1082.649170,2379.582764 1075.046143,2380.826172 
	C1070.184692,2381.621338 1065.090332,2380.283203 1059.452393,2382.283203 
	C1054.641113,2383.243408 1050.824219,2382.338623 1046.212158,2381.160645 
	C1026.591187,2379.498291 1007.819275,2377.648438 988.884583,2379.589355 
	C984.243103,2380.065186 979.631226,2380.047607 974.517334,2382.156494 
	C970.664368,2382.778564 967.767761,2383.778809 964.322693,2385.614990 
	C951.464600,2387.665771 939.409180,2385.799316 926.684692,2384.517090 
	C918.128357,2380.936523 909.645874,2380.332031 900.790955,2379.351318 
	C902.512451,2374.662109 906.699158,2377.072266 908.525269,2374.841553 
	C908.927124,2373.829346 908.794312,2372.656982 907.892944,2372.287842 
	C901.491455,2369.669189 895.115112,2366.701172 887.886963,2368.934814 
	C886.486755,2369.367432 885.106689,2369.145508 884.721558,2367.633789 
	C884.216675,2365.651367 885.470886,2364.402100 887.326233,2364.278809 
	C890.956909,2364.036865 894.606262,2364.076416 899.123962,2364.000000 
z"
        />
        <path
          fill="#4A667C"
          opacity="1.000000"
          stroke="none"
          d="
M723.395386,2369.444824 
	C725.532104,2369.882812 727.020996,2369.936279 729.255005,2369.989990 
	C729.482605,2375.343262 725.742737,2376.314453 721.431580,2375.907715 
	C703.427979,2374.208984 686.101074,2380.896973 668.182129,2380.083252 
	C652.272705,2386.813232 635.528442,2383.149658 619.148560,2383.974854 
	C614.864075,2384.190918 610.671082,2383.573975 606.393799,2382.635498 
	C598.424683,2380.886230 590.527588,2379.132080 582.004883,2381.571045 
	C575.290405,2383.492432 574.020020,2381.230225 575.697876,2372.708984 
	C579.804993,2374.992676 583.963623,2376.290771 588.934814,2376.075439 
	C611.497986,2375.095947 634.060486,2374.064453 656.641602,2373.456543 
	C664.550659,2373.243408 672.357056,2374.420898 681.054810,2373.722900 
	C684.348572,2371.245850 687.625244,2371.074951 691.366577,2370.221436 
	C700.722778,2370.022949 709.417969,2369.981201 719.039673,2369.907715 
	C720.771118,2369.187256 721.698303,2368.915283 723.395386,2369.444824 
z"
        />
        <path
          fill="#546D83"
          opacity="1.000000"
          stroke="none"
          d="
M917.432495,2399.388916 
	C916.929382,2405.381104 912.384583,2404.673828 909.403442,2403.681396 
	C900.968933,2400.874023 895.047363,2402.656738 890.190735,2410.363281 
	C887.342346,2414.883301 884.258789,2414.613525 881.108093,2409.499756 
	C877.808044,2404.143799 873.969910,2401.122803 866.762451,2402.067627 
	C859.673462,2402.044678 853.390808,2401.980225 847.108093,2401.967285 
	C844.567627,2401.961914 842.709534,2401.002686 841.478760,2398.691162 
	C839.904175,2395.733643 841.814941,2394.779053 843.918945,2393.404053 
	C847.218994,2391.246826 845.662659,2388.185303 844.765808,2385.386963 
	C844.228699,2383.711182 844.018433,2381.894043 845.598511,2380.911865 
	C847.561096,2379.691406 849.364258,2380.706543 850.848328,2382.262695 
	C852.203003,2383.683105 853.455994,2385.242432 854.985107,2386.446777 
	C860.302612,2390.636475 862.481384,2390.406494 865.905029,2384.783691 
	C867.156250,2382.729004 868.062012,2380.740967 871.307495,2380.075195 
	C877.320862,2375.114990 883.083740,2378.170166 889.355469,2379.499512 
	C892.386475,2380.302734 895.091675,2380.868408 894.893066,2383.767578 
	C894.718079,2386.322266 892.000122,2385.909424 890.094238,2385.952148 
	C886.121704,2386.041748 882.142822,2385.924805 878.174194,2386.083496 
	C875.325684,2386.197266 872.761841,2387.276611 872.546997,2390.519775 
	C872.319702,2393.951904 875.311646,2394.811035 877.802673,2395.453369 
	C880.001953,2396.020508 882.403015,2396.104248 884.690308,2395.972656 
	C890.698364,2395.627197 896.548523,2396.366943 903.161987,2397.976074 
	C908.405945,2396.346680 912.701416,2396.509521 917.432495,2399.388916 
z"
        />
        <path
          fill="#698196"
          opacity="1.000000"
          stroke="none"
          d="
M819.504456,2349.339355 
	C822.083679,2354.433350 826.080933,2354.272217 829.955750,2353.859863 
	C832.798706,2353.557129 835.580261,2352.676270 839.190918,2352.036377 
	C838.274048,2361.269287 838.274048,2361.269287 830.826782,2362.002197 
	C821.862427,2362.884521 821.680969,2363.201172 825.254944,2373.100830 
	C819.719421,2372.585205 814.050842,2374.235840 811.665649,2367.043457 
	C810.538208,2363.643555 807.575684,2363.388916 805.257751,2365.178955 
	C799.822693,2369.375732 793.373535,2367.654053 787.437927,2369.731201 
	C787.437927,2368.320801 787.179382,2367.490967 787.476746,2367.159668 
	C791.727356,2362.423584 791.802673,2362.311523 786.332642,2359.880615 
	C783.215332,2358.495361 780.023254,2357.466553 780.392090,2351.957031 
	C783.029785,2349.250244 785.629578,2348.952881 788.725952,2347.761230 
	C796.268005,2346.595215 802.946594,2344.566895 810.723389,2345.538330 
	C814.171509,2346.281982 816.758606,2347.058838 819.504456,2349.339355 
z"
        />
        <path
          fill="#4C6880"
          opacity="1.000000"
          stroke="none"
          d="
M763.990723,2355.332520 
	C747.408752,2356.249268 730.892456,2355.452393 714.448669,2357.770752 
	C709.940491,2358.406250 705.143738,2358.568848 700.603577,2357.176270 
	C698.560974,2356.549805 696.539490,2356.459473 694.866821,2357.649658 
	C690.437927,2360.801758 685.972534,2358.812988 680.723755,2358.067383 
	C677.813232,2356.122070 677.808350,2353.731201 678.937927,2350.388672 
	C683.527893,2346.251465 688.352661,2346.533447 694.071472,2346.996338 
	C697.184021,2347.030762 699.430481,2347.076172 702.482666,2347.161621 
	C708.406189,2347.550537 713.506409,2349.266357 719.428223,2347.284668 
	C724.843018,2346.568359 729.576904,2346.884033 734.924805,2345.580078 
	C747.133057,2345.316895 757.423462,2339.144043 769.466431,2339.491455 
	C772.548523,2344.573486 767.277283,2347.046631 766.516235,2351.419922 
	C765.416992,2352.863525 764.749390,2353.803467 763.990723,2355.332520 
z"
        />
        <path
          fill="#4A667C"
          opacity="1.000000"
          stroke="none"
          d="
M799.195190,2399.998047 
	C786.105408,2404.212891 773.391846,2398.167480 761.089355,2401.614990 
	C752.503418,2398.241455 743.256714,2401.190186 734.465820,2398.723145 
	C730.111145,2397.501221 725.223877,2398.130127 720.575256,2398.018311 
	C717.036804,2397.933350 713.494629,2398.001465 707.730408,2398.001465 
	C714.968750,2391.735840 721.426025,2391.007324 727.782593,2392.546143 
	C733.413818,2393.909424 738.954529,2394.124268 744.583008,2393.944580 
	C756.892029,2393.552246 769.094238,2394.578613 781.321350,2395.859131 
	C786.534241,2396.404785 791.956665,2396.672363 797.116516,2394.644287 
	C798.998962,2393.904785 801.085083,2393.872070 802.973083,2394.721924 
	C804.349609,2395.341309 806.467041,2395.590088 806.119690,2397.796143 
	C805.880981,2399.312256 804.293274,2399.579102 803.001099,2399.820312 
	C802.028870,2400.001953 801.012390,2399.946289 799.195190,2399.998047 
z"
        />
        <path
          fill="#546D83"
          opacity="1.000000"
          stroke="none"
          d="
M872.026245,2419.183594 
	C869.117126,2420.141602 866.086609,2419.923096 863.286621,2420.624756 
	C852.219666,2423.397705 842.385681,2420.999268 832.705566,2414.103271 
	C831.330139,2413.093750 830.685791,2412.187988 829.957825,2410.710693 
	C833.221741,2405.151855 838.878662,2409.585205 843.403076,2406.474609 
	C846.728699,2404.333496 848.926453,2406.191162 851.632202,2407.546875 
	C852.388123,2408.743164 852.989075,2409.152344 854.414490,2409.065918 
	C856.506531,2408.483398 857.955200,2407.888184 859.609497,2406.448242 
	C861.523865,2406.034424 863.014893,2406.029541 865.251465,2406.027100 
	C866.637573,2410.824463 873.961060,2412.378662 872.026245,2419.183594 
z"
        />
        <path
          fill="#4C6880"
          opacity="1.000000"
          stroke="none"
          d="
M908.466309,2347.268799 
	C907.963501,2347.926270 906.975891,2347.950195 906.482056,2347.965332 
	C904.489441,2347.983154 902.990540,2347.985596 900.742188,2348.001465 
	C899.216309,2348.767822 898.295166,2349.053955 896.616333,2348.422363 
	C891.698608,2344.400879 889.014587,2349.909424 884.658569,2349.972412 
	C881.997375,2351.706299 879.839050,2352.133545 876.734741,2350.537109 
	C873.023926,2350.221924 869.945984,2349.513916 866.526489,2351.408203 
	C863.359558,2354.284668 860.291138,2352.696777 856.664429,2352.402344 
	C852.346619,2351.827148 848.689575,2352.499512 844.650574,2350.307617 
	C844.322754,2348.345703 844.945312,2346.652832 846.784119,2346.371826 
	C852.608276,2345.482422 858.514465,2343.910645 864.293823,2344.245605 
	C872.169617,2344.701172 879.738953,2339.977783 887.629028,2343.266846 
	C893.595825,2338.605713 900.038513,2341.208740 907.174988,2342.040771 
	C909.253113,2343.362793 909.468323,2344.910156 908.466309,2347.268799 
z"
        />
        <path
          fill="#5D758A"
          opacity="1.000000"
          stroke="none"
          d="
M598.424866,2435.391113 
	C586.397278,2436.464600 574.768250,2435.048096 562.598145,2437.419189 
	C553.709839,2437.977295 545.445007,2438.020752 537.180786,2437.967529 
	C534.887268,2437.952881 532.541260,2437.896484 530.320862,2437.402344 
	C528.630188,2437.026123 527.525818,2435.525635 527.853333,2433.656006 
	C528.145569,2431.987793 529.731567,2432.162842 530.985596,2432.067139 
	C531.972168,2431.991943 533.008362,2431.849365 533.953491,2432.054443 
	C549.430359,2435.415771 564.670044,2432.789551 579.842712,2430.007568 
	C583.188599,2429.393799 586.756958,2433.651611 589.860718,2428.674805 
	C590.902039,2428.011719 591.804016,2428.023438 593.382446,2428.054199 
	C595.976318,2430.091797 599.613342,2430.880615 598.424866,2435.391113 
z"
        />
        <path
          fill="#334D63"
          opacity="1.000000"
          stroke="none"
          d="
M766.711548,2351.908691 
	C765.456726,2348.414795 762.629944,2344.139404 769.597412,2342.660645 
	C783.023132,2335.275635 796.558716,2338.208252 810.873291,2340.462891 
	C813.294495,2342.522461 813.585388,2344.387695 812.561035,2347.285156 
	C804.484802,2347.397705 797.263672,2350.521973 788.868408,2350.022949 
	C785.586670,2350.122803 783.090271,2349.576172 780.333252,2351.304443 
	C779.820801,2351.637939 779.761230,2351.698242 779.779114,2351.729004 
	C778.407715,2351.751465 777.018494,2351.743652 774.931763,2351.739502 
	C773.206848,2350.949951 772.100891,2350.564941 770.334839,2351.468994 
	C769.012268,2351.865479 768.175415,2351.879639 766.711548,2351.908691 
z"
        />
        <path
          fill="#698196"
          opacity="1.000000"
          stroke="none"
          d="
M918.657715,2400.001465 
	C913.784058,2399.753418 909.433899,2400.789307 904.717407,2398.319092 
	C908.353516,2392.761963 914.595093,2390.774414 920.767090,2392.574463 
	C924.412354,2393.637695 927.672791,2391.866699 931.524597,2390.482910 
	C932.851501,2389.196289 933.848999,2388.871338 935.754883,2389.292969 
	C939.020752,2392.169189 939.869385,2395.227783 938.841736,2399.455322 
	C937.517334,2400.822510 936.484802,2401.060303 934.677185,2400.445801 
	C931.792542,2398.830811 929.585449,2397.720215 926.597168,2399.721680 
	C925.257751,2400.742432 924.326660,2401.043457 922.613464,2400.432617 
	C921.096313,2399.990479 920.209595,2399.990723 918.657715,2400.001465 
z"
        />
        <path
          fill="#50677C"
          opacity="1.000000"
          stroke="none"
          d="
M936.658447,2390.499023 
	C935.107483,2389.999023 934.219788,2389.988525 932.666138,2389.972168 
	C930.710571,2387.634033 926.907227,2388.406982 926.142334,2384.666992 
	C929.608093,2383.830322 933.362061,2384.269287 936.690552,2383.278564 
	C941.332825,2381.896973 945.538452,2382.205078 950.039612,2383.572266 
	C954.078369,2384.799072 958.322571,2385.023193 963.254883,2383.957275 
	C964.828857,2384.759277 965.329529,2385.741699 965.429565,2387.738525 
	C965.135986,2389.094238 964.906494,2389.606934 964.026978,2390.653809 
	C954.684448,2392.096191 945.998047,2392.196045 936.658447,2390.499023 
z"
        />
        <path
          fill="#50677C"
          opacity="1.000000"
          stroke="none"
          d="
M843.255188,2405.981934 
	C843.618896,2408.655762 842.045532,2409.919678 839.376282,2409.938477 
	C836.781250,2409.957031 834.186584,2410.013428 830.732910,2410.096191 
	C821.716248,2409.363037 813.709595,2410.578125 804.869934,2411.962646 
	C803.257812,2411.193359 802.947754,2410.281250 803.546753,2408.607178 
	C804.000000,2408.000000 804.980225,2407.980469 805.470398,2407.975586 
	C807.041748,2406.921875 808.131287,2405.881592 809.613647,2404.435059 
	C815.611084,2403.070557 820.752808,2406.017822 827.062378,2405.897949 
	C830.553406,2403.737305 833.417969,2403.189697 837.297974,2405.411133 
	C839.537781,2405.968262 841.024780,2405.975830 843.255188,2405.981934 
z"
        />
        <path
          fill="#546D83"
          opacity="1.000000"
          stroke="none"
          d="
M825.894531,2423.265137 
	C819.192200,2424.788574 812.411316,2427.803223 804.767090,2424.107422 
	C801.658936,2421.444092 801.695129,2418.090088 805.306030,2418.692627 
	C810.690430,2419.591553 815.635620,2419.984619 820.941772,2418.500000 
	C823.444885,2417.799805 825.112488,2420.035400 825.894531,2423.265137 
z"
        />
        <path
          fill="#698196"
          opacity="1.000000"
          stroke="none"
          d="
M889.814087,2379.256104 
	C884.489075,2380.543457 878.991211,2378.365234 872.749939,2379.965576 
	C871.446289,2378.442139 871.037170,2376.827637 870.268188,2375.408691 
	C869.483704,2373.960938 868.085693,2372.480713 869.551880,2371.032471 
	C871.074768,2369.528320 874.394592,2369.346924 875.034851,2370.470947 
	C878.356018,2376.300293 885.497498,2374.628418 889.814087,2379.256104 
z"
        />
        <path
          fill="#546D83"
          opacity="1.000000"
          stroke="none"
          d="
M598.799438,2435.987305 
	C598.167786,2432.965576 594.902771,2431.895996 594.153564,2428.714844 
	C594.058777,2428.072998 595.012939,2428.074463 595.490051,2428.078369 
	C596.312195,2428.324707 596.657166,2428.566895 597.517456,2428.442871 
	C600.157410,2428.055908 602.281921,2428.035645 605.203247,2428.012695 
	C608.352417,2428.157471 610.835388,2427.357666 613.492920,2429.486084 
	C614.000000,2430.000000 614.015076,2430.971191 614.020752,2431.456055 
	C610.170654,2436.654785 604.802856,2435.993896 598.799438,2435.987305 
z"
        />
        <path
          fill="#698196"
          opacity="1.000000"
          stroke="none"
          d="
M876.012817,2350.826660 
	C878.138245,2350.030762 880.261475,2350.024658 883.180176,2350.043457 
	C883.562622,2354.963135 888.950317,2355.194336 891.343018,2358.184570 
	C880.687134,2361.272461 878.082520,2360.069336 876.012817,2350.826660 
z"
        />
        <path
          fill="#546D83"
          opacity="1.000000"
          stroke="none"
          d="
M838.016602,2405.193848 
	C835.227173,2405.959229 832.403442,2405.957520 828.729126,2405.921387 
	C828.674500,2403.403564 824.320190,2400.040771 828.972229,2398.302246 
	C832.907166,2396.831787 835.485901,2398.738525 838.016602,2405.193848 
z"
        />
        <path
          fill="#546D83"
          opacity="1.000000"
          stroke="none"
          d="
M803.325073,2407.700928 
	C804.002686,2408.878418 804.005432,2409.756836 804.015625,2411.292969 
	C803.357849,2411.957520 802.676636,2412.060791 802.030151,2411.953857 
	C799.339600,2411.508301 794.496887,2413.180664 794.853455,2409.102051 
	C795.304993,2403.938721 799.613525,2406.354004 803.325073,2407.700928 
z"
        />
        <path
          fill="#5D758A"
          opacity="1.000000"
          stroke="none"
          d="
M614.246460,2429.413574 
	C611.633240,2429.871338 609.174561,2430.415283 606.489075,2428.524902 
	C607.305786,2426.044434 609.354553,2424.982666 611.463806,2424.542969 
	C615.240051,2423.755859 615.998108,2425.613281 614.246460,2429.413574 
z"
        />
        <path
          fill="#698196"
          opacity="1.000000"
          stroke="none"
          d="
M856.344604,2352.433350 
	C858.707031,2351.926270 861.507385,2351.924072 865.144287,2351.916016 
	C865.980835,2353.817871 865.980835,2355.725586 865.980835,2359.398926 
	C862.880981,2355.539307 859.827271,2354.247070 856.344604,2352.433350 
z"
        />
        <path
          fill="#698196"
          opacity="1.000000"
          stroke="none"
          d="
M807.290466,2374.347168 
	C809.726868,2376.349121 808.685364,2377.410156 806.773193,2377.698242 
	C805.275269,2377.923828 803.084045,2378.013916 803.420593,2375.574707 
	C803.611450,2374.190918 805.358215,2374.170166 807.290466,2374.347168 
z"
        />
        <path
          fill="#698196"
          opacity="1.000000"
          stroke="none"
          d="
M905.486328,2387.002930 
	C907.183228,2386.271973 908.397339,2386.326172 908.858093,2387.792236 
	C908.983948,2388.192627 908.519958,2389.147705 908.136780,2389.274902 
	C906.497620,2389.818115 905.054443,2389.720459 905.486328,2387.002930 
z"
        />
        <path
          fill="#5D758A"
          opacity="1.000000"
          stroke="none"
          d="
M859.388367,2406.087402 
	C859.750366,2408.834961 858.088684,2410.005859 854.704712,2409.984375 
	C853.754028,2409.676758 853.500854,2409.353760 853.605469,2408.530273 
	C855.556946,2407.398193 857.150452,2406.766846 859.388367,2406.087402 
z"
        />
        <path
          fill="#4C6880"
          opacity="1.000000"
          stroke="none"
          d="
M770.580078,2351.901855 
	C770.567444,2349.280029 771.427307,2347.191406 774.075806,2351.200439 
	C773.259827,2351.813232 772.285400,2351.882812 770.580078,2351.901855 
z"
        />
        <path
          fill="#698196"
          opacity="1.000000"
          stroke="none"
          d="
M896.090637,2348.552246 
	C896.890259,2347.979492 897.776733,2347.985840 899.328003,2348.003418 
	C899.792480,2349.292236 899.684570,2350.750244 898.067261,2350.952637 
	C896.945374,2351.093506 896.452515,2350.116211 896.090637,2348.552246 
z"
        />
        <path
          fill="#546D83"
          opacity="1.000000"
          stroke="none"
          d="
M809.385864,2404.125488 
	C809.797485,2405.820312 809.235779,2407.346436 806.593689,2407.883789 
	C806.183838,2406.191650 806.740662,2404.660156 809.385864,2404.125488 
z"
        />
        <path
          fill="#50677C"
          opacity="1.000000"
          stroke="none"
          d="
M853.471008,2408.023438 
	C853.963379,2408.029297 853.991699,2409.014404 853.999512,2409.507324 
	C853.289551,2410.991211 852.407043,2411.800293 851.202026,2410.910889 
	C850.407043,2410.324219 850.603394,2409.410156 851.587646,2408.326660 
	C851.993896,2408.009033 852.978638,2408.017822 853.471008,2408.023438 
z"
        />
        <path
          fill="#4A667C"
          opacity="1.000000"
          stroke="none"
          d="
M701.981567,2396.557617 
	C702.080261,2397.739746 701.894348,2397.775879 701.981567,2396.557617 
z"
        />
        <path
          fill="#5D758A"
          opacity="1.000000"
          stroke="none"
          d="
M597.516357,2428.064453 
	C597.631165,2428.700684 597.207642,2429.263672 596.170288,2428.371582 
	C596.311401,2428.072510 596.655640,2428.062744 597.516357,2428.064453 
z"
        />
        <path
          fill="#6A8297"
          opacity="1.000000"
          stroke="none"
          d="
M633.546631,2152.649414 
	C634.922668,2152.038086 635.802429,2152.037354 637.342102,2152.041016 
	C642.234497,2155.412842 647.192444,2156.142578 653.195862,2156.001953 
	C654.775635,2155.239014 655.697693,2154.945801 657.386841,2155.564453 
	C660.451233,2157.320068 662.167786,2163.029053 665.896973,2156.658691 
	C667.334961,2154.681152 668.899719,2154.468262 671.287231,2155.540283 
	C671.744934,2158.897949 673.044189,2160.986084 674.858521,2163.104980 
	C676.552307,2165.083008 677.021912,2167.750000 677.208252,2170.508301 
	C671.432007,2171.168457 665.993164,2171.760254 660.569031,2172.465332 
	C660.124146,2172.523193 659.779724,2173.353516 658.977905,2174.320068 
	C662.864441,2179.125732 668.355286,2178.252441 673.281006,2177.798340 
	C678.820557,2177.287354 684.221680,2175.514648 689.906799,2175.872314 
	C691.955078,2176.000977 694.145752,2175.271240 694.793640,2173.202393 
	C695.659912,2170.435547 693.005127,2170.270020 691.297119,2169.512451 
	C690.089783,2168.977051 688.828430,2168.503662 687.734802,2167.784424 
	C685.583557,2166.369141 682.203979,2164.926514 683.239258,2162.085693 
	C684.516418,2158.581299 687.597961,2161.174561 689.927734,2161.652344 
	C693.458252,2162.376953 697.255310,2161.404053 701.321899,2163.612793 
	C705.305359,2161.194092 708.866638,2161.779785 713.235352,2163.464600 
	C718.487427,2166.666260 718.994385,2169.519043 715.107178,2173.472168 
	C710.290955,2178.370361 705.089417,2181.733398 697.937195,2182.127930 
	C693.069641,2182.396484 688.263977,2183.786621 682.854187,2184.781494 
	C683.541199,2187.875244 684.969604,2189.058105 687.682678,2188.594971 
	C688.298889,2188.489502 688.868591,2188.024902 689.424744,2187.670654 
	C691.828003,2186.139893 694.220520,2186.105225 694.808716,2189.206299 
	C695.367188,2192.151123 692.467896,2192.053223 690.671875,2191.906982 
	C686.083984,2191.534180 684.043945,2193.892334 683.016296,2197.950439 
	C681.870789,2202.473389 682.885376,2203.760254 687.845459,2203.999268 
	C690.949890,2204.148438 693.693787,2202.952148 696.565063,2202.142578 
	C705.848145,2199.525879 715.267334,2197.784424 724.970093,2198.004150 
	C728.622742,2198.086914 732.321106,2197.999023 734.700562,2201.521484 
	C734.156738,2204.467041 731.745422,2204.129639 729.984924,2203.907471 
	C721.779541,2202.872559 713.541016,2204.514648 705.457520,2204.774414 
	C696.609192,2205.058350 687.801819,2210.569092 679.277344,2214.460938 
	C676.869690,2215.560059 675.698303,2219.143311 673.643494,2221.283203 
	C668.406677,2226.736816 668.284302,2226.671875 660.675781,2222.130371 
	C659.027893,2220.180664 656.561462,2219.549072 656.094238,2216.627686 
	C656.311523,2213.687744 658.765381,2212.620117 660.163818,2209.526855 
	C651.454834,2211.444580 646.900696,2206.628662 642.354309,2200.579590 
	C642.051880,2198.224121 641.864380,2196.411621 643.507202,2194.424805 
	C648.008240,2189.324951 654.196228,2192.325928 659.821411,2189.342529 
	C654.999695,2185.714111 649.005127,2188.174316 644.443604,2184.536865 
	C644.310608,2182.757080 644.471558,2181.467041 644.940491,2180.300293 
	C647.908447,2172.915771 647.921265,2172.939697 641.195923,2168.604004 
	C640.921997,2168.427246 641.156677,2167.461914 641.582764,2166.422852 
	C643.108826,2164.587158 645.157593,2163.045410 641.576294,2162.239014 
	C638.863098,2161.627930 635.809082,2162.552246 633.013794,2159.799316 
	C631.807739,2157.133301 631.911194,2155.156494 633.546631,2152.649414 
z"
        />
        <path
          fill="#728BA1"
          opacity="1.000000"
          stroke="none"
          d="
M633.197937,2152.029785 
	C634.029724,2154.161621 634.016541,2156.283936 634.005310,2159.203125 
	C630.265442,2163.709473 626.171021,2160.131592 623.022888,2160.661865 
	C620.947815,2160.401611 622.394287,2162.857178 622.169922,2161.542969 
	C622.122620,2161.266113 622.409912,2161.186523 623.022827,2161.150635 
	C624.954529,2161.037598 626.948425,2160.647705 628.154785,2163.293701 
	C627.997742,2164.879150 627.997131,2165.768066 627.994995,2167.323730 
	C621.231995,2173.208984 613.758606,2170.899170 605.737427,2169.710449 
	C603.053040,2169.171875 604.106567,2165.188232 600.718872,2166.666016 
	C597.271179,2170.522705 592.590576,2169.211426 588.499756,2171.529541 
	C585.107971,2171.807373 581.719299,2172.322266 581.036560,2168.291504 
	C580.681824,2166.197021 586.732971,2157.265381 588.912537,2156.799561 
	C595.935120,2155.298584 603.198120,2152.422852 610.026733,2153.175781 
	C617.757874,2154.028320 624.950562,2152.993164 633.197937,2152.029785 
z"
        />
        <path
          fill="#7A91A4"
          opacity="1.000000"
          stroke="none"
          d="
M643.237183,2184.098633 
	C648.923401,2184.015869 653.841980,2184.147705 658.754822,2184.033203 
	C662.536255,2183.945312 663.757141,2186.296143 664.690735,2189.341064 
	C665.611816,2192.344971 664.471069,2193.807129 661.562134,2193.883789 
	C656.321289,2194.021973 651.075500,2193.969971 644.919312,2193.993652 
	C638.044922,2189.658936 631.428223,2193.055664 624.311768,2192.965820 
	C628.303650,2184.118896 636.846375,2187.252930 643.237183,2184.098633 
z"
        />
        <path
          fill="#7A91A4"
          opacity="1.000000"
          stroke="none"
          d="
M641.216858,2199.995605 
	C643.939697,2200.090088 646.585571,2199.716309 647.248230,2201.731445 
	C648.956116,2206.924316 651.833435,2208.328369 656.879272,2206.291260 
	C658.256165,2205.735352 660.122681,2206.091064 661.694275,2206.393555 
	C663.744324,2206.787842 665.240784,2208.090332 665.515320,2210.286865 
	C665.836426,2212.855957 664.083313,2213.539795 661.998291,2213.986328 
	C660.421631,2214.323975 658.901672,2214.926514 656.681885,2215.710449 
	C651.251465,2217.233643 646.506531,2218.787109 641.514526,2217.774170 
	C638.655090,2217.194092 637.534668,2215.594727 639.554688,2212.896729 
	C645.207947,2205.345947 645.205872,2205.360840 636.194153,2203.887451 
	C635.262878,2203.735352 634.382935,2203.269043 633.089233,2202.810059 
	C634.484802,2198.572266 638.006836,2200.636719 641.216858,2199.995605 
z"
        />
        <path
          fill="#6A8297"
          opacity="1.000000"
          stroke="none"
          d="
M588.903503,2172.002441 
	C589.212891,2165.900391 595.300415,2168.617188 599.277954,2166.073242 
	C600.795593,2165.230469 601.716736,2164.935059 603.392456,2165.532227 
	C611.078735,2170.100342 618.804443,2167.037109 627.079712,2167.990234 
	C630.793701,2168.232422 632.000244,2169.855713 631.963928,2173.257080 
	C617.819763,2175.450439 603.909668,2171.695068 588.903503,2172.002441 
z"
        />
        <path
          fill="#6A8297"
          opacity="1.000000"
          stroke="none"
          d="
M588.091431,2244.103027 
	C592.574036,2243.986572 596.178223,2244.059082 598.665649,2247.346191 
	C598.440186,2250.368164 596.108765,2249.877930 594.295227,2249.949463 
	C588.732788,2250.169678 583.156738,2250.124268 577.603882,2250.469482 
	C575.503540,2250.600098 573.154053,2250.972656 573.111267,2248.397461 
	C573.067627,2245.772949 575.798828,2245.977783 577.660583,2246.055176 
	C581.046692,2246.195557 584.059692,2244.762939 588.091431,2244.103027 
z"
        />
        <path
          fill="#7A91A4"
          opacity="1.000000"
          stroke="none"
          d="
M628.833008,2163.992920 
	C626.284302,2161.010254 622.555481,2162.969971 619.142456,2160.000244 
	C624.385742,2160.000244 628.351257,2160.000244 633.162048,2160.000000 
	C639.549316,2160.598145 645.721680,2158.266846 650.570923,2163.002686 
	C648.871277,2166.693359 645.949951,2165.729980 642.744446,2165.986084 
	C638.352478,2162.486816 633.793518,2164.587158 628.833008,2163.992920 
z"
        />
        <path
          fill="#6A8297"
          opacity="1.000000"
          stroke="none"
          d="
M625.080200,2204.341797 
	C628.829407,2206.981934 628.498535,2209.880615 626.139771,2212.244385 
	C623.462952,2214.926514 621.347351,2212.070312 619.270081,2210.696533 
	C617.459473,2209.498779 614.336731,2208.812744 615.277954,2205.878662 
	C616.187805,2203.042236 619.202576,2204.281006 621.364136,2204.043945 
	C622.340637,2203.937012 623.347412,2204.106934 625.080200,2204.341797 
z"
        />
        <path
          fill="#6A8297"
          opacity="1.000000"
          stroke="none"
          d="
M613.205322,2201.648438 
	C611.282410,2201.838135 609.540588,2201.823730 609.348267,2200.452393 
	C609.011658,2198.052490 611.169006,2198.091797 612.673157,2198.320801 
	C614.549438,2198.606445 615.641113,2199.635498 613.205322,2201.648438 
z"
        />
        <path
          fill="#6A8297"
          opacity="1.000000"
          stroke="none"
          d="
M619.966614,2261.809326 
	C617.980286,2261.522705 616.503174,2260.660645 617.449524,2259.363525 
	C618.762573,2257.564209 621.129150,2257.563477 622.458069,2259.338135 
	C623.417419,2260.619141 621.953979,2261.505371 619.966614,2261.809326 
z"
        />
        <path
          fill="#6A8297"
          opacity="1.000000"
          stroke="none"
          d="
M602.563843,2199.043945 
	C602.907532,2201.769287 601.464722,2201.839111 599.835022,2201.246826 
	C599.456543,2201.108887 599.021851,2200.136475 599.160828,2199.740723 
	C599.668701,2198.295166 600.881897,2198.259033 602.563843,2199.043945 
z"
        />
        <path
          fill="#6A8297"
          opacity="1.000000"
          stroke="none"
          d="
M631.110718,2218.089355 
	C630.367798,2219.443604 629.620056,2219.461670 628.959412,2218.492920 
	C628.821167,2218.290039 628.826599,2217.820557 628.970398,2217.623047 
	C629.658997,2216.677979 630.405884,2216.713623 631.110718,2218.089355 
z"
        />
        <path
          fill="#7F98AE"
          opacity="1.000000"
          stroke="none"
          d="
M728.284180,2150.574707 
	C736.167236,2145.101807 744.669617,2145.120605 753.427490,2148.199707 
	C756.596313,2149.313721 759.832764,2150.208740 759.960938,2155.205566 
	C756.482605,2156.452881 752.737488,2155.047363 748.540833,2157.837891 
	C759.044128,2163.254639 769.727356,2161.829590 781.095703,2161.988281 
	C784.304199,2167.582275 779.831726,2167.761230 776.546143,2168.592773 
	C762.721619,2172.092041 762.718506,2172.046387 763.955322,2185.816406 
	C764.168762,2188.192871 763.585388,2190.390869 761.089111,2193.167480 
	C756.392578,2194.498535 755.859924,2196.525879 756.833862,2199.707520 
	C757.878906,2203.121094 759.981689,2206.325195 759.384155,2210.015137 
	C757.444214,2212.140625 755.579712,2210.519775 753.728516,2210.266113 
	C742.541321,2208.732666 742.560791,2208.743408 745.213928,2197.361084 
	C746.527344,2191.726074 744.895874,2187.194092 740.642456,2183.119873 
	C733.508301,2176.286133 734.181824,2172.222656 742.780945,2167.865967 
	C744.103882,2167.195801 745.179565,2166.572510 744.915771,2165.098145 
	C744.669250,2163.719727 743.469116,2162.656982 742.142395,2162.581055 
	C740.549500,2162.489990 738.891052,2162.870605 737.316467,2163.267334 
	C733.453308,2164.240967 729.649841,2164.283447 725.452637,2163.385498 
	C725.939819,2158.820068 735.760803,2156.936768 728.284180,2150.574707 
z"
        />
        <path
          fill="#61798F"
          opacity="1.000000"
          stroke="none"
          d="
M803.640686,2252.465820 
	C809.434021,2246.843994 815.278748,2244.462891 822.905273,2247.597412 
	C825.702515,2248.747070 829.431335,2248.784180 833.319763,2246.313721 
	C827.069031,2241.927002 819.510925,2243.310791 811.284058,2239.929932 
	C820.722534,2236.841064 828.680115,2236.473877 834.446655,2241.302734 
	C839.867737,2245.842773 844.510193,2246.910889 850.644043,2244.756592 
	C851.887451,2244.319824 853.288452,2243.953857 854.401672,2245.004639 
	C856.008301,2246.520508 854.916138,2248.109619 854.163818,2249.491699 
	C852.004333,2253.458008 849.675110,2256.335449 844.019897,2255.291504 
	C839.328674,2254.425537 834.341370,2255.465576 829.470642,2254.182861 
	C826.603943,2253.427734 823.919128,2254.360107 821.414185,2256.792236 
	C815.631775,2262.406738 808.480652,2260.050781 801.738953,2258.998291 
	C800.407776,2258.790771 800.300415,2256.942139 800.050171,2254.821533 
	C801.103333,2253.650879 802.194458,2253.291260 803.640686,2252.465820 
z"
        />
        <path
          fill="#6A8297"
          opacity="1.000000"
          stroke="none"
          d="
M720.311768,2210.303711 
	C726.396057,2209.758301 729.530396,2215.287598 735.591248,2214.417236 
	C734.023926,2219.721436 728.707642,2223.484131 732.762024,2229.048096 
	C733.484009,2230.039062 730.218140,2232.245850 728.305786,2231.545898 
	C723.173401,2229.667725 717.822998,2230.526611 712.649902,2229.696289 
	C705.424561,2228.536621 700.088745,2224.676514 697.045959,2217.452637 
	C704.236389,2213.557861 712.776672,2214.747070 720.311768,2210.303711 
z"
        />
        <path
          fill="#6A8297"
          opacity="1.000000"
          stroke="none"
          d="
M798.467651,2199.264648 
	C786.483154,2199.944580 774.960510,2199.909912 763.440552,2199.711426 
	C761.077271,2199.670654 760.339661,2197.704834 760.031372,2194.803955 
	C759.980469,2193.980469 759.997192,2193.999756 759.989746,2193.990234 
	C772.424133,2189.626221 784.880615,2186.474609 797.998169,2194.249023 
	C799.044312,2196.104980 799.176331,2197.277344 798.467651,2199.264648 
z"
        />
        <path
          fill="#61798F"
          opacity="1.000000"
          stroke="none"
          d="
M798.774536,2200.028809 
	C798.001099,2199.135742 797.997253,2198.233887 797.990051,2196.656250 
	C801.223572,2191.050537 805.782288,2191.595703 811.254150,2193.460449 
	C817.218628,2194.010986 822.466858,2193.999756 827.711548,2194.129883 
	C828.950195,2194.160645 830.449829,2194.339111 830.737549,2196.000244 
	C831.090881,2198.040771 829.659607,2199.191650 828.075806,2199.447754 
	C821.970093,2200.435303 815.821899,2201.160400 808.852600,2201.986816 
	C805.675049,2199.255859 802.474121,2200.220459 798.774536,2200.028809 
z"
        />
        <path
          fill="#61798F"
          opacity="1.000000"
          stroke="none"
          d="
M803.065430,2209.985352 
	C807.279663,2210.320068 811.576355,2208.887939 811.951172,2215.214355 
	C810.654724,2217.316162 809.090698,2217.512695 806.671509,2216.485840 
	C799.332703,2215.966309 792.686646,2215.962891 786.043396,2215.825928 
	C784.281555,2215.789795 782.660828,2214.900146 782.720398,2212.919678 
	C782.779541,2210.952393 784.439941,2210.172607 786.202332,2210.134766 
	C791.516663,2210.020508 796.833740,2210.029541 803.065430,2209.985352 
z"
        />
        <path
          fill="#7790A8"
          opacity="1.000000"
          stroke="none"
          d="
M662.745422,2095.985352 
	C662.091492,2084.732910 662.091492,2084.732910 673.206665,2082.020508 
	C678.953369,2087.579102 679.100769,2088.012695 673.726624,2090.999512 
	C670.437195,2092.827881 667.782715,2096.171143 662.745422,2095.985352 
z"
        />
        <path
          fill="#7A91A4"
          opacity="1.000000"
          stroke="none"
          d="
M681.673584,2113.497803 
	C682.248840,2119.201416 680.096497,2122.009521 674.565430,2122.023438 
	C670.293335,2122.033936 665.572327,2121.191895 665.239563,2116.800537 
	C664.898926,2112.304199 669.930664,2112.410400 673.448975,2112.031738 
	C674.423096,2111.927002 675.416931,2112.005859 677.202087,2112.000977 
	C679.225586,2111.971436 680.364075,2112.226074 681.673584,2113.497803 
z"
        />
        <path
          fill="#7790A8"
          opacity="1.000000"
          stroke="none"
          d="
M701.834961,2149.374512 
	C701.222961,2150.564941 700.373596,2151.669922 699.656677,2151.589600 
	C693.249084,2150.873291 686.846436,2150.058105 680.487915,2148.998047 
	C679.100220,2148.767090 677.342834,2147.960938 677.428040,2144.978271 
	C682.096619,2143.093506 687.266357,2144.384277 693.112366,2144.000000 
	C696.997131,2144.859131 699.834473,2145.980469 701.834961,2149.374512 
z"
        />
        <path
          fill="#7A91A4"
          opacity="1.000000"
          stroke="none"
          d="
M702.754211,2150.005615 
	C699.618225,2148.112305 695.630127,2148.857910 694.114502,2144.656738 
	C699.020752,2140.108887 704.845215,2142.801514 711.124756,2142.035156 
	C712.001648,2143.537354 712.000854,2145.026123 712.000000,2147.260742 
	C709.349915,2149.455566 706.546143,2150.250488 702.754211,2150.005615 
z"
        />
        <path
          fill="#61798F"
          opacity="1.000000"
          stroke="none"
          d="
M813.503784,2219.460938 
	C810.468689,2227.556396 809.104126,2227.714111 802.138550,2220.691895 
	C802.995544,2219.482910 803.978882,2218.955811 805.473755,2218.204590 
	C808.479309,2217.167236 810.754395,2218.025879 813.503784,2219.460938 
z"
        />
        <path
          fill="#7790A8"
          opacity="1.000000"
          stroke="none"
          d="
M672.647827,2156.020508 
	C670.461975,2156.045654 668.979309,2156.032471 666.754761,2156.019043 
	C664.973389,2155.011475 664.352478,2153.459961 665.705322,2152.799072 
	C668.726196,2151.323242 672.077332,2151.909912 675.293945,2152.355225 
	C676.272156,2152.490723 677.032776,2153.499023 676.670959,2154.456543 
	C676.138062,2155.866699 674.628235,2155.820557 672.647827,2156.020508 
z"
        />
        <path
          fill="#7790A8"
          opacity="1.000000"
          stroke="none"
          d="
M713.842041,2163.296143 
	C710.576050,2163.976807 707.162048,2163.963379 702.897217,2163.923828 
	C702.065430,2160.629639 702.258667,2157.501709 706.955566,2159.400391 
	C709.260559,2160.332031 712.498535,2159.335205 713.842041,2163.296143 
z"
        />
        <path
          fill="#7790A8"
          opacity="1.000000"
          stroke="none"
          d="
M670.547668,2143.044678 
	C672.110840,2146.710693 670.555603,2147.961426 667.658203,2147.431152 
	C665.846069,2147.098877 662.931763,2146.165771 663.467773,2144.169922 
	C664.378479,2140.778564 667.576538,2142.265381 670.547668,2143.044678 
z"
        />
        <path
          fill="#6A8297"
          opacity="1.000000"
          stroke="none"
          d="
M696.581543,2239.691162 
	C693.046875,2239.512207 690.026367,2239.073486 691.551758,2234.389404 
	C693.246826,2234.012695 694.506531,2233.917725 695.732788,2234.078369 
	C700.370667,2234.685547 700.144775,2236.674561 696.581543,2239.691162 
z"
        />
        <path
          fill="#7790A8"
          opacity="1.000000"
          stroke="none"
          d="
M692.584473,2104.579102 
	C689.853271,2105.702148 688.034607,2105.404053 687.122681,2103.106445 
	C686.500854,2101.539795 687.439087,2100.458496 688.922607,2100.322998 
	C691.056885,2100.128174 693.722412,2098.916504 694.751587,2102.077393 
	C694.915894,2102.582275 693.734009,2103.525146 692.584473,2104.579102 
z"
        />
        <path
          fill="#7790A8"
          opacity="1.000000"
          stroke="none"
          d="
M682.557617,2113.701904 
	C680.902283,2113.645996 679.806946,2113.291992 678.357056,2112.469727 
	C678.831238,2110.992188 679.502136,2109.762695 680.527161,2109.026123 
	C681.945068,2108.007324 683.917969,2107.704102 684.917664,2109.344482 
	C685.957764,2111.051514 684.455933,2112.357666 682.557617,2113.701904 
z"
        />
        <path
          fill="#7A91A4"
          opacity="1.000000"
          stroke="none"
          d="
M705.580444,2130.479980 
	C707.375610,2131.695312 707.227600,2132.723877 705.822876,2133.420898 
	C704.940125,2133.859375 703.799011,2133.715576 703.354858,2132.777588 
	C702.634827,2131.257080 703.635681,2130.602051 705.580444,2130.479980 
z"
        />
        <path
          fill="#6A8297"
          opacity="1.000000"
          stroke="none"
          d="
M813.989502,2220.010254 
	C811.621826,2220.077148 809.325378,2219.848145 806.622070,2218.270508 
	C805.985352,2217.980469 805.988770,2216.999756 805.983215,2216.509766 
	C807.477295,2216.007080 808.976929,2215.994629 811.225342,2215.976318 
	C811.974060,2215.970703 811.995117,2215.995850 812.002197,2215.999512 
	C813.261292,2216.563232 814.190002,2217.417725 814.279358,2219.390625 
	C814.010071,2219.990234 813.998535,2220.001221 813.989502,2220.010254 
z"
        />
        <path
          fill="#7790A8"
          opacity="1.000000"
          stroke="none"
          d="
M681.998779,2064.000000 
	C683.211365,2064.198486 684.586853,2064.232910 684.883423,2065.772217 
	C684.945190,2066.093018 684.272278,2066.882324 683.916321,2066.895996 
	C682.804565,2066.939209 682.409973,2066.028564 682.080200,2064.544922 
	C682.000854,2064.000000 682.000000,2064.000000 681.998779,2064.000000 
z"
        />
        <path
          fill="#7790A8"
          opacity="1.000000"
          stroke="none"
          d="
M657.914185,2155.432129 
	C657.115723,2156.007568 656.226135,2156.005615 654.669495,2156.001953 
	C654.203552,2154.723389 654.309082,2153.265625 655.919739,2153.049561 
	C657.041321,2152.899170 657.542236,2153.870605 657.914185,2155.432129 
z"
        />
        <path
          fill="#6A8297"
          opacity="1.000000"
          stroke="none"
          d="
M812.340820,2342.666992 
	C798.478943,2342.246338 785.192444,2342.193848 770.973999,2342.169434 
	C766.893066,2338.718994 763.512390,2341.575928 761.211914,2342.815674 
	C753.759216,2346.831299 745.672302,2346.911865 736.858521,2347.954590 
	C732.416687,2346.937012 729.757141,2344.126465 726.271851,2342.926025 
	C721.555847,2341.301270 718.928223,2338.234619 720.128052,2333.078613 
	C721.429382,2327.486084 725.784058,2328.549072 730.467163,2329.185791 
	C723.612061,2327.823730 717.459473,2325.727295 712.402283,2329.004150 
	C714.618530,2329.168945 717.172546,2330.588623 717.424011,2334.431885 
	C717.597778,2337.088135 716.230408,2338.964600 713.899353,2339.920410 
	C710.190613,2341.441406 705.436401,2343.762939 702.495972,2340.967285 
	C694.883179,2333.729248 685.267578,2335.144531 675.724060,2333.135010 
	C667.976990,2331.169922 661.079956,2332.895752 652.165222,2333.814453 
	C660.972290,2334.468262 667.850403,2331.625244 675.193176,2334.806152 
	C677.076965,2341.680420 668.715271,2341.708984 667.611938,2347.433350 
	C666.208679,2349.165527 664.935486,2349.809570 662.732422,2350.047852 
	C650.603333,2349.351807 639.727966,2351.879150 628.705017,2354.519043 
	C611.864380,2358.552246 594.531006,2359.132324 577.353638,2357.513916 
	C571.921997,2357.002197 566.885864,2358.590576 560.828308,2357.993164 
	C557.778870,2357.999023 555.555237,2357.993652 552.664612,2358.011719 
	C549.230896,2358.022949 546.464172,2358.028076 542.869263,2358.041016 
	C540.310974,2357.629150 537.896118,2358.641113 537.123962,2354.979004 
	C542.158752,2353.186523 547.454956,2353.912354 552.521484,2354.134521 
	C560.359253,2354.477539 567.647095,2352.751465 574.878601,2350.077393 
	C577.630188,2349.060303 579.571106,2347.656250 579.793762,2344.563477 
	C579.892578,2343.190918 579.668335,2341.804688 578.489380,2341.004395 
	C577.347961,2340.229980 575.664856,2339.983643 574.798889,2341.042236 
	C568.829895,2348.338379 559.921814,2347.124268 551.434021,2349.447266 
	C550.784851,2344.491455 553.264648,2339.714111 549.334412,2334.534668 
	C561.947205,2333.511963 572.303284,2337.707275 582.870605,2335.445312 
	C592.798035,2333.320312 602.349609,2329.632324 612.494751,2329.175781 
	C612.495728,2326.798828 611.388550,2326.140625 609.973572,2326.181885 
	C600.063354,2326.469482 590.055847,2324.541748 580.257263,2327.541748 
	C577.999023,2328.232666 575.648132,2328.000244 573.348206,2327.451416 
	C563.830994,2325.178955 554.381409,2325.746826 544.756470,2327.249756 
	C533.568115,2328.996826 522.185608,2328.265625 510.930573,2327.583984 
	C506.702393,2327.328125 502.711395,2325.660645 498.319031,2327.397217 
	C495.377075,2328.560059 492.868073,2326.355469 489.860992,2325.313721 
	C490.630371,2324.586182 490.814453,2324.262695 490.989441,2324.267578 
	C511.572693,2324.837891 531.970581,2321.409180 552.595459,2321.733154 
	C565.413025,2321.934570 578.272461,2321.949707 591.112305,2322.024658 
	C597.151978,2322.059814 603.162659,2322.063965 608.063293,2317.857666 
	C610.396179,2315.854980 612.429260,2317.528564 615.300293,2317.920166 
	C618.348816,2318.021973 620.541931,2318.297363 622.287109,2320.227539 
	C623.840210,2321.945312 625.901794,2322.155518 627.469360,2320.430664 
	C630.376282,2317.232178 634.299622,2318.797119 637.626160,2317.829834 
	C656.381775,2312.375732 675.281128,2313.245117 695.136353,2316.003662 
	C699.437927,2315.998047 702.870972,2315.996094 707.163330,2315.982422 
	C710.206665,2315.944336 712.390686,2315.918213 715.395996,2315.798340 
	C720.208740,2316.027100 724.048889,2314.986084 727.955811,2314.553223 
	C735.136353,2313.757812 742.438660,2313.506104 749.630310,2314.894287 
	C761.523865,2317.190186 773.079041,2313.340332 785.483826,2312.556152 
	C793.431580,2312.292236 800.333618,2310.168213 807.328186,2308.588379 
	C809.001953,2308.209961 810.681702,2308.276367 811.316284,2310.275146 
	C811.921021,2312.179688 810.769653,2313.424561 809.237976,2314.192383 
	C805.419556,2316.105957 801.434448,2317.580078 797.139587,2317.980469 
	C795.138550,2318.166992 793.170654,2318.297363 792.187378,2321.317871 
	C786.580994,2326.913086 779.765381,2325.426514 772.647217,2326.109375 
	C765.260071,2328.232910 758.862793,2331.109619 751.848572,2331.860596 
	C759.531860,2331.983154 766.424561,2329.163330 774.090881,2326.153320 
	C794.179382,2326.346924 813.498657,2325.893555 833.332458,2329.373291 
	C834.454102,2336.598389 829.786987,2336.007324 824.738281,2336.039062 
	C820.656372,2339.014893 820.288574,2346.552490 812.340820,2342.666992 
z"
        />
        <path
          fill="#597288"
          opacity="1.000000"
          stroke="none"
          d="
M908.694702,2348.267578 
	C907.973267,2346.455322 907.983093,2344.984131 907.988892,2342.777832 
	C908.927917,2339.886963 912.201843,2340.536621 912.956604,2337.693115 
	C911.373840,2335.029541 905.491638,2336.662842 906.070801,2330.818604 
	C911.960754,2329.884521 917.955078,2330.801025 923.333801,2326.888428 
	C924.954529,2325.709229 927.831604,2325.941406 930.111023,2326.040039 
	C938.894714,2326.418945 947.788696,2324.905273 958.006104,2327.216553 
	C953.145813,2331.737305 948.456482,2332.085693 943.623962,2332.030762 
	C936.982422,2331.955078 930.349915,2331.823486 923.915405,2334.005371 
	C921.862488,2334.701660 919.932007,2335.417236 920.851440,2337.984863 
	C921.534912,2339.893555 923.508972,2339.893799 925.076111,2339.270752 
	C928.276184,2337.998047 931.556396,2338.013916 934.891785,2338.070312 
	C938.790833,2338.136230 940.153137,2340.254150 939.856323,2343.864258 
	C939.559937,2347.470459 937.083435,2348.520508 934.268250,2347.581055 
	C930.696777,2346.389404 928.143188,2347.843018 924.977295,2349.008301 
	C919.993286,2350.842773 914.558228,2350.791016 908.694702,2348.267578 
z"
        />
        <path
          fill="#4C6880"
          opacity="1.000000"
          stroke="none"
          d="
M834.441772,2329.538086 
	C821.452148,2329.996826 808.897888,2329.748779 796.358887,2330.081055 
	C788.712708,2330.283691 781.471313,2326.934082 772.913452,2328.072021 
	C769.922852,2328.378418 769.281555,2327.133057 769.571167,2324.676025 
	C776.920959,2324.948730 783.470520,2321.892090 791.180420,2322.063232 
	C802.790283,2324.347656 813.718750,2323.718018 824.594849,2324.166992 
	C828.363647,2324.322754 833.392029,2323.452148 834.441772,2329.538086 
z"
        />
        <path
          fill="#4C6880"
          opacity="1.000000"
          stroke="none"
          d="
M811.824707,2343.020996 
	C815.051331,2341.857666 819.179016,2342.733887 819.184814,2337.246582 
	C819.185242,2336.850830 821.408752,2336.457764 823.262878,2336.061035 
	C824.521912,2337.562012 824.871826,2339.230225 825.752747,2340.541504 
	C830.295410,2347.304932 830.342957,2347.272949 820.741882,2349.958984 
	C817.669250,2349.814941 815.230408,2350.388428 812.523071,2348.619873 
	C811.981750,2346.661133 811.932800,2345.202148 811.824707,2343.020996 
z"
        />
        <path
          fill="#728BA1"
          opacity="1.000000"
          stroke="none"
          d="
M800.802490,2289.982666 
	C799.321472,2288.370361 798.429504,2286.773682 799.819458,2284.522217 
	C805.265869,2284.032959 810.509399,2284.032959 815.752991,2284.032959 
	C811.730591,2288.356689 806.885925,2289.768799 800.802490,2289.982666 
z"
        />
        <path
          fill="#597288"
          opacity="1.000000"
          stroke="none"
          d="
M392.637634,2270.561279 
	C392.497589,2272.029541 392.031921,2273.458008 391.486023,2273.489258 
	C377.711487,2274.280273 364.045563,2278.295654 350.093262,2274.536621 
	C346.353973,2273.528809 342.248169,2273.881348 338.167694,2273.599854 
	C339.285950,2271.183594 341.626648,2270.802246 343.247681,2270.742676 
	C358.434784,2270.181885 373.470215,2266.770752 388.749329,2268.257812 
	C389.965271,2268.375977 391.101410,2269.313721 392.637634,2270.561279 
z"
        />
        <path
          fill="#566C80"
          opacity="1.000000"
          stroke="none"
          d="
M338.519836,2260.011719 
	C340.643127,2260.066650 341.956573,2260.127686 343.522583,2260.200439 
	C338.137451,2264.913330 323.870239,2265.930908 303.414032,2263.122070 
	C308.875854,2258.108154 315.109741,2260.427002 320.726196,2260.055908 
	C326.363708,2259.683838 332.046814,2260.002197 338.519836,2260.011719 
z"
        />
        <path
          fill="#71889C"
          opacity="1.000000"
          stroke="none"
          d="
M220.358551,2301.261230 
	C224.048538,2297.040527 228.376740,2297.291504 232.892410,2300.150391 
	C228.836304,2300.716797 224.994202,2302.633545 220.358551,2301.261230 
z"
        />
        <path
          fill="#71889C"
          opacity="1.000000"
          stroke="none"
          d="
M306.705353,2290.532227 
	C310.018524,2290.094727 312.882111,2289.487305 316.775879,2291.166016 
	C312.472809,2292.017822 309.353180,2292.957520 306.705353,2290.532227 
z"
        />
        <path
          fill="#708497"
          opacity="1.000000"
          stroke="none"
          d="
M222.954041,2398.928467 
	C223.038055,2398.883789 222.870010,2398.973145 222.954041,2398.928467 
z"
        />
        <path
          fill="#6A8297"
          opacity="1.000000"
          stroke="none"
          d="
M117.069962,2358.956055 
	C117.115448,2359.040527 117.024483,2358.871582 117.069962,2358.956055 
z"
        />
        <path
          fill="#5D758A"
          opacity="1.000000"
          stroke="none"
          d="
M661.969849,2350.758057 
	C662.938721,2349.370850 663.877380,2348.741699 665.401978,2348.051270 
	C669.220154,2345.042236 672.840149,2345.191895 677.333374,2347.065918 
	C678.441528,2347.842773 678.841309,2348.212891 679.630310,2349.301270 
	C680.007874,2352.161621 679.996216,2354.303711 679.969238,2357.251221 
	C671.467712,2361.868408 662.509521,2359.251465 652.867676,2359.947754 
	C652.347778,2357.143066 654.104126,2356.036377 656.752075,2356.022461 
	C659.769043,2356.006592 661.891052,2354.952637 661.969849,2350.758057 
z"
        />
        <path
          fill="#5D758A"
          opacity="1.000000"
          stroke="none"
          d="
M552.442749,2358.508301 
	C554.068909,2355.563721 556.496460,2355.620850 559.557617,2357.499512 
	C557.929077,2360.430420 555.504395,2360.390869 552.442749,2358.508301 
z"
        />
        <path
          fill="#6A8297"
          opacity="1.000000"
          stroke="none"
          d="
M437.909576,2295.108398 
	C436.552307,2294.364502 436.532318,2293.615967 437.504669,2292.954346 
	C437.707825,2292.816162 438.177551,2292.822266 438.375916,2292.966553 
	C439.323425,2293.655518 439.290192,2294.403076 437.909576,2295.108398 
z"
        />
        <path
          fill="#243748"
          opacity="1.000000"
          stroke="none"
          d="
M2552.443115,3525.960449 
	C2549.146973,3523.542969 2550.181641,3520.424072 2550.086182,3516.786133 
	C2552.288574,3513.210938 2555.142090,3511.294189 2558.668701,3511.941895 
	C2578.435547,3515.571533 2598.425049,3518.379395 2617.492920,3527.357178 
	C2620.467773,3531.454346 2619.059570,3534.232910 2616.293457,3537.401611 
	C2614.520508,3537.942383 2613.031250,3537.924072 2610.797607,3537.889648 
	C2597.981934,3535.512207 2585.861572,3533.613281 2572.753174,3533.980713 
	C2570.400879,3533.474609 2568.795166,3532.975830 2566.602539,3532.223877 
	C2563.589111,3529.191406 2558.947754,3530.439697 2556.407715,3526.518311 
	C2554.985840,3526.051270 2554.061523,3526.026855 2552.443115,3525.960449 
z"
        />
        <path
          fill="#1C2E3E"
          opacity="1.000000"
          stroke="none"
          d="
M2572.495117,3534.498779 
	C2575.330566,3530.699951 2579.531006,3532.058105 2583.341309,3532.084961 
	C2592.376221,3532.149414 2601.262451,3533.462402 2609.924316,3537.254395 
	C2597.531738,3539.071045 2585.296387,3536.544922 2572.495117,3534.498779 
z"
        />
        <path
          fill="#1C2E3E"
          opacity="1.000000"
          stroke="none"
          d="
M2555.970215,3526.843018 
	C2559.505859,3526.900391 2563.429443,3527.015625 2565.878906,3531.294434 
	C2562.374512,3531.234131 2557.093994,3534.250732 2555.970215,3526.843018 
z"
        />
        <path
          fill="#061420"
          opacity="1.000000"
          stroke="none"
          d="
M2695.422363,3485.379883 
	C2703.468994,3492.406738 2713.473633,3490.397705 2719.942627,3480.658203 
	C2722.891846,3482.996582 2725.338867,3485.937744 2729.636230,3486.540039 
	C2733.912109,3487.138672 2736.481445,3490.360840 2734.549561,3495.358643 
	C2732.815186,3499.845215 2735.991211,3505.521484 2730.555176,3509.701172 
	C2727.716553,3509.457275 2725.540527,3508.810791 2722.682373,3508.086914 
	C2716.934082,3506.229980 2712.678467,3502.309814 2706.261230,3502.174316 
	C2698.627441,3499.702881 2691.908203,3501.488281 2684.582520,3503.556641 
	C2674.372559,3500.612549 2664.239502,3499.625000 2654.443604,3497.161865 
	C2650.262451,3496.110352 2646.017578,3494.337158 2643.452148,3499.957764 
	C2642.675537,3501.659668 2640.780029,3501.922607 2639.134521,3501.333008 
	C2632.955811,3499.120117 2626.506836,3497.645508 2620.247803,3495.893555 
	C2607.187988,3492.237793 2597.436768,3481.367920 2582.774902,3480.025635 
	C2578.491943,3481.895752 2574.890625,3480.962402 2570.627686,3480.222168 
	C2568.469482,3480.081543 2566.983154,3480.071777 2564.753906,3480.059570 
	C2560.933105,3480.111328 2558.336426,3475.611816 2554.447266,3479.532715 
	C2552.495850,3479.981689 2550.993896,3479.992676 2548.741211,3480.022949 
	C2547.664795,3480.287354 2547.339600,3480.532471 2546.531982,3480.402100 
	C2546.419434,3475.707520 2544.376465,3471.808105 2543.880859,3466.849609 
	C2543.968750,3453.884033 2543.968750,3453.884033 2554.922119,3453.267090 
	C2563.399902,3455.924805 2570.997803,3459.073975 2580.214111,3457.315430 
	C2611.252441,3462.910645 2640.044434,3474.567383 2670.652832,3478.669678 
	C2676.867920,3479.502930 2683.026611,3480.804443 2690.092773,3481.600586 
	C2692.532471,3482.378662 2693.815918,3483.409668 2695.422363,3485.379883 
z"
        />
        <path
          fill="#10212F"
          opacity="1.000000"
          stroke="none"
          d="
M2618.681641,3528.027832 
	C2608.746094,3525.578857 2599.349121,3523.911133 2590.130371,3521.345459 
	C2579.395508,3518.357666 2568.332520,3516.511230 2557.357910,3514.458496 
	C2555.588379,3514.127441 2553.538086,3515.299805 2550.876465,3515.901611 
	C2549.975830,3509.983154 2549.815918,3503.940186 2549.590820,3497.000488 
	C2556.003906,3495.710938 2562.680420,3497.148193 2568.197754,3491.497559 
	C2570.805664,3488.826660 2575.053711,3489.150635 2578.105469,3492.219482 
	C2581.347412,3495.479492 2585.710205,3497.056885 2589.776123,3497.334717 
	C2597.217529,3497.843994 2603.999512,3500.516602 2611.033203,3502.281738 
	C2615.986084,3503.524658 2621.757324,3503.430420 2625.086670,3508.687256 
	C2625.639160,3509.559814 2627.423584,3509.856934 2628.693359,3510.038330 
	C2639.870117,3511.637207 2650.150146,3516.808105 2661.411865,3518.128662 
	C2666.062500,3518.673828 2666.850098,3521.132324 2663.966553,3524.901611 
	C2660.875000,3528.942139 2656.950928,3530.950439 2651.788330,3529.559326 
	C2649.866455,3529.041992 2648.008301,3528.290771 2646.091553,3527.750488 
	C2637.175781,3525.237793 2628.239502,3521.712402 2618.681641,3528.027832 
z"
        />
        <path
          fill="#102131"
          opacity="1.000000"
          stroke="none"
          d="
M2745.996582,3522.780518 
	C2748.894775,3521.344727 2751.760742,3520.370850 2754.690918,3520.106934 
	C2759.424316,3519.680664 2764.328613,3519.387207 2764.211182,3526.545654 
	C2764.162109,3529.530518 2767.211914,3529.686279 2769.507324,3531.486572 
	C2769.564209,3534.914307 2765.403076,3538.841553 2772.303223,3539.651611 
	C2774.282471,3539.884033 2775.716797,3541.544189 2777.545898,3543.500488 
	C2780.292236,3544.127930 2782.739258,3543.862061 2783.939941,3547.286133 
	C2766.712158,3547.220703 2749.284180,3547.293945 2732.124268,3543.748047 
	C2726.133301,3542.510010 2720.106201,3542.199219 2714.551270,3538.718506 
	C2711.321289,3536.694824 2708.125977,3535.678467 2707.839111,3531.229736 
	C2707.643799,3528.198486 2705.388672,3526.961182 2702.717529,3525.845215 
	C2696.260742,3523.148438 2689.717773,3520.781738 2682.750244,3520.045410 
	C2678.442871,3519.589844 2675.729980,3517.141846 2671.063965,3512.831055 
	C2691.489014,3515.095947 2709.827881,3515.028076 2726.378906,3524.740967 
	C2730.539551,3527.182617 2735.788330,3527.790527 2740.558350,3529.167969 
	C2745.810303,3530.685059 2745.906006,3527.104004 2745.996582,3522.780518 
z"
        />
        <path
          fill="#091A29"
          opacity="1.000000"
          stroke="none"
          d="
M2684.691895,3504.242920 
	C2684.835938,3499.379150 2688.430420,3498.323975 2692.111572,3498.156250 
	C2696.339111,3497.963623 2700.684082,3497.216309 2705.416992,3499.446289 
	C2706.455566,3504.913574 2701.984619,3504.337158 2698.708984,3505.962646 
	C2693.777100,3505.738281 2689.405029,3506.765381 2684.691895,3504.242920 
z"
        />
        <path
          fill="#102131"
          opacity="1.000000"
          stroke="none"
          d="
M2698.494629,3506.507324 
	C2699.723389,3503.372559 2703.039062,3502.996338 2705.617188,3500.500488 
	C2712.527832,3499.137451 2717.528320,3501.910156 2721.858887,3507.309570 
	C2721.102783,3509.083984 2719.790039,3509.922852 2718.635254,3509.139648 
	C2712.427734,3504.930176 2705.696045,3506.230225 2698.494629,3506.507324 
z"
        />
        <path
          fill="#102131"
          opacity="1.000000"
          stroke="none"
          d="
M2729.471191,3514.526611 
	C2734.495361,3516.399902 2741.347168,3513.830566 2743.903809,3521.261719 
	C2740.480225,3521.975342 2736.949951,3522.019287 2733.421631,3521.953125 
	C2728.585938,3521.862549 2727.135010,3519.495850 2729.471191,3514.526611 
z"
        />
        <path
          fill="#091A29"
          opacity="1.000000"
          stroke="none"
          d="
M2570.121094,3480.777344 
	C2573.320068,3476.692383 2576.980469,3477.879639 2581.331055,3479.700684 
	C2581.874268,3483.135254 2581.934570,3486.524170 2577.112305,3484.604980 
	C2574.787109,3483.679443 2571.560059,3484.673340 2570.121094,3480.777344 
z"
        />
        <path
          fill="#091A29"
          opacity="1.000000"
          stroke="none"
          d="
M2554.683350,3480.072754 
	C2557.273193,3472.588379 2558.592773,3472.424561 2563.728027,3479.427979 
	C2561.176758,3483.533936 2558.255859,3480.613037 2554.683350,3480.072754 
z"
        />
        <path
          fill="#243748"
          opacity="1.000000"
          stroke="none"
          d="
M2545.533203,3480.468262 
	C2546.049805,3480.026855 2547.020264,3479.988037 2547.505371,3480.015137 
	C2547.978027,3482.201172 2547.965820,3484.359863 2547.909180,3487.332520 
	C2543.546631,3487.059814 2543.487549,3484.297119 2545.533203,3480.468262 
z"
        />
        <path
          fill="#B0714F"
          opacity="1.000000"
          stroke="none"
          d="
M2494.537354,3627.449707 
	C2491.321289,3633.177979 2487.998291,3637.972900 2489.156982,3644.507568 
	C2489.624268,3647.143311 2487.817871,3650.182617 2486.527832,3653.519043 
	C2482.043213,3654.547363 2481.991699,3651.247314 2481.349121,3648.922607 
	C2480.289795,3645.092041 2479.700439,3641.138672 2479.256348,3637.175293 
	C2477.679932,3623.107910 2477.141602,3622.855469 2464.339600,3629.880615 
	C2461.463867,3631.458984 2458.795898,3633.653564 2454.515625,3632.932617 
	C2451.256592,3630.547852 2451.659912,3627.257324 2450.406738,3623.609375 
	C2447.211182,3628.462158 2446.017090,3633.444824 2444.867676,3638.335693 
	C2443.622559,3643.634766 2441.422363,3648.309814 2437.990234,3652.518066 
	C2431.894043,3659.993164 2429.134277,3668.696777 2428.950195,3678.986328 
	C2429.057617,3682.138916 2430.045898,3684.505127 2429.375977,3687.714355 
	C2425.925537,3696.729004 2423.790039,3705.181396 2425.640137,3714.911621 
	C2425.866699,3716.613281 2425.815186,3717.583740 2425.435303,3719.228516 
	C2419.528809,3728.586426 2417.195557,3738.230225 2417.170410,3749.150391 
	C2417.526123,3751.190674 2417.329834,3752.513672 2416.428467,3754.347656 
	C2415.057373,3756.254883 2414.330566,3757.672363 2415.139160,3760.068359 
	C2415.217285,3761.593262 2415.090332,3762.469482 2414.640381,3763.969482 
	C2409.389893,3770.121826 2407.000000,3777.386230 2399.593262,3781.541504 
	C2394.079346,3784.635010 2399.563965,3789.300537 2402.719482,3793.295410 
	C2403.943604,3795.752197 2403.372559,3797.530029 2402.472168,3799.914551 
	C2401.248047,3801.993164 2399.501953,3802.839844 2398.884766,3805.297363 
	C2398.249023,3807.654541 2397.502686,3809.269043 2396.345703,3811.404785 
	C2395.908203,3811.915527 2395.868652,3811.906982 2395.851074,3811.896973 
	C2390.336670,3815.737793 2384.778564,3819.438721 2376.966309,3819.053223 
	C2372.633789,3813.920410 2377.047607,3810.664307 2378.955322,3807.246826 
	C2386.716797,3793.342041 2386.723389,3791.992920 2376.096191,3780.530029 
	C2374.269775,3778.560303 2372.515381,3776.567871 2371.591064,3774.023193 
	C2370.451660,3770.887695 2368.899170,3767.272217 2371.819092,3764.713867 
	C2374.710449,3762.181152 2377.402832,3765.191650 2379.713867,3766.749023 
	C2385.548828,3770.680664 2389.087891,3769.872559 2389.421631,3762.351074 
	C2389.664795,3756.869141 2392.433838,3752.052002 2396.323242,3749.577148 
	C2407.048096,3742.752441 2406.807129,3733.056885 2405.314941,3721.851074 
	C2405.083496,3719.027344 2405.410645,3717.008545 2406.303711,3714.286621 
	C2413.076172,3703.458740 2416.903076,3692.789062 2414.661621,3680.492676 
	C2413.595459,3674.644043 2416.034180,3669.075928 2418.675293,3664.263428 
	C2422.393066,3657.489014 2423.716064,3650.675781 2422.898193,3643.203613 
	C2421.859131,3633.710938 2427.674072,3625.176025 2426.799316,3614.916992 
	C2427.251709,3611.598145 2426.933105,3608.973389 2428.017090,3605.793213 
	C2429.021484,3603.955322 2429.918945,3603.012207 2431.297119,3602.680908 
	C2435.299561,3601.718262 2440.663574,3604.680908 2443.215332,3599.941162 
	C2446.602295,3593.651123 2452.109863,3588.204834 2452.084961,3579.616699 
	C2452.239502,3577.920166 2452.490723,3576.978516 2453.216309,3575.421387 
	C2456.922607,3571.500977 2459.611816,3567.677979 2462.627930,3564.132080 
	C2464.642578,3561.763672 2467.016113,3559.842285 2470.401123,3560.961426 
	C2473.819336,3562.091309 2474.500977,3565.196289 2474.704834,3568.200928 
	C2475.244629,3576.165039 2475.267090,3584.163574 2476.146973,3590.661865 
	C2479.990967,3576.816650 2479.600830,3560.780762 2484.868896,3545.792969 
	C2485.533936,3543.901123 2486.699219,3541.757812 2486.088135,3540.006104 
	C2481.840576,3527.828613 2487.794434,3515.910156 2486.768799,3503.827637 
	C2486.431885,3499.860840 2487.099609,3495.824463 2489.166260,3492.248291 
	C2493.395996,3484.929688 2501.826416,3483.831787 2506.068848,3490.943359 
	C2512.325439,3501.430664 2519.293213,3511.919922 2520.282959,3524.645264 
	C2520.838135,3531.784912 2523.958984,3538.091797 2525.941895,3545.558594 
	C2526.670166,3550.196289 2526.189209,3554.172363 2522.369385,3555.828125 
	C2506.890381,3562.535889 2509.561035,3576.200684 2510.550049,3588.312744 
	C2511.175293,3595.970703 2512.289795,3602.151611 2505.420898,3607.882080 
	C2499.782471,3612.585693 2500.930908,3621.797852 2494.537354,3627.449707 
z"
        />
        <path
          fill="#BA7C55"
          opacity="1.000000"
          stroke="none"
          d="
M2524.630859,3546.463867 
	C2520.748535,3538.610352 2515.839111,3531.746582 2517.562744,3522.827393 
	C2517.954834,3520.797119 2516.697266,3518.264404 2515.722656,3516.186523 
	C2512.062744,3508.383789 2508.426758,3500.555420 2504.355469,3492.965576 
	C2500.968750,3486.652100 2494.852051,3486.838379 2491.554199,3493.322754 
	C2489.262207,3497.829590 2488.210449,3502.837891 2490.282959,3507.758301 
	C2492.013184,3511.866211 2492.224854,3515.375244 2488.557129,3518.546387 
	C2485.471924,3521.214600 2486.425049,3524.346680 2487.530762,3527.693359 
	C2490.336182,3536.184814 2491.171387,3544.595215 2485.146240,3552.295410 
	C2484.753662,3552.797119 2484.286133,3553.608398 2484.431396,3554.106934 
	C2486.502197,3561.204590 2482.469971,3567.562500 2482.102051,3574.324463 
	C2481.865234,3578.677246 2481.948975,3582.982178 2479.620117,3586.875000 
	C2478.291504,3589.096191 2478.952148,3591.266357 2479.476074,3593.596680 
	C2480.165771,3596.663818 2480.804688,3599.933838 2477.742188,3603.961914 
	C2474.072021,3593.912354 2471.772217,3584.972656 2471.807373,3575.291260 
	C2471.822754,3571.096924 2474.836670,3564.527588 2469.175049,3563.040527 
	C2464.984375,3561.940186 2463.508057,3568.514648 2460.925537,3571.765381 
	C2459.460449,3573.609375 2457.883545,3575.314209 2454.742188,3575.934326 
	C2446.382324,3567.723633 2450.910156,3558.491455 2452.725342,3549.854736 
	C2453.515869,3546.091553 2454.934326,3542.368896 2455.358398,3537.646484 
	C2456.275391,3534.368896 2457.018311,3531.859863 2457.677246,3529.315918 
	C2459.507812,3522.248535 2463.321289,3518.234131 2471.274170,3521.047607 
	C2477.274658,3523.170410 2480.196777,3521.086182 2479.531738,3514.640869 
	C2478.636475,3505.961426 2485.570068,3497.477295 2479.910645,3487.959473 
	C2478.825684,3483.498779 2478.692871,3479.790771 2480.177246,3475.395508 
	C2485.287842,3470.696533 2490.920410,3467.721191 2494.785889,3462.597412 
	C2495.764404,3461.300537 2497.411133,3459.806152 2498.863037,3459.917725 
	C2511.094971,3460.856934 2513.966797,3453.168945 2514.767090,3443.280518 
	C2514.837646,3442.408447 2516.022949,3441.805664 2517.674805,3441.229980 
	C2522.297607,3442.170410 2522.536621,3445.793945 2524.089355,3449.442383 
	C2525.192383,3454.053467 2525.145508,3457.903320 2524.597168,3462.564453 
	C2523.979736,3465.613525 2522.549805,3467.820557 2523.167236,3470.130127 
	C2528.987549,3491.900635 2524.735107,3515.095703 2532.306641,3537.286865 
	C2532.011719,3538.894287 2532.013916,3539.779053 2532.019287,3541.326904 
	C2531.159668,3545.533936 2529.052246,3547.369629 2524.630859,3546.463867 
z"
        />
        <path
          fill="#B0714F"
          opacity="1.000000"
          stroke="none"
          d="
M2539.935547,3703.552734 
	C2540.365967,3705.893311 2540.419678,3707.549805 2540.107422,3709.540283 
	C2536.881104,3709.998291 2535.078125,3712.255371 2532.704346,3713.359619 
	C2529.559326,3714.822754 2524.641113,3712.618408 2523.135254,3717.071289 
	C2521.926758,3720.646240 2524.480225,3724.046875 2525.522949,3727.477783 
	C2528.290039,3736.583252 2521.768799,3742.599365 2517.310059,3748.523193 
	C2515.147949,3751.395752 2510.736816,3753.033203 2508.392822,3756.877441 
	C2507.227539,3758.788574 2504.899414,3757.113770 2503.773682,3754.319336 
	C2502.068848,3752.172119 2501.473389,3750.306152 2501.406006,3748.310303 
	C2500.934082,3734.298340 2502.395020,3720.307617 2502.216309,3705.592773 
	C2506.746826,3700.579102 2508.851807,3704.528809 2511.165039,3707.174316 
	C2514.037842,3710.459229 2516.086426,3709.374023 2518.160889,3706.270996 
	C2526.939697,3693.138184 2528.303955,3678.476807 2526.586914,3663.389160 
	C2525.661377,3655.255371 2514.075439,3643.985107 2506.057129,3642.565918 
	C2502.674072,3636.956787 2505.092529,3632.985840 2509.067383,3628.665039 
	C2510.734863,3626.877441 2511.870850,3625.697754 2512.969482,3624.484375 
	C2515.480713,3621.709961 2517.894043,3621.309814 2520.282715,3624.653076 
	C2523.043945,3628.518066 2526.172607,3631.870361 2531.305176,3632.429199 
	C2531.877930,3632.491211 2532.770264,3633.020508 2532.874268,3633.478271 
	C2535.063477,3643.122314 2543.178711,3650.745117 2538.200195,3662.917236 
	C2533.045410,3675.520508 2532.446777,3689.783691 2539.935547,3703.552734 
z"
        />
        <path
          fill="#603A24"
          opacity="1.000000"
          stroke="none"
          d="
M2504.612793,3703.274414 
	C2503.749023,3708.291748 2503.873779,3712.540771 2504.998779,3716.779541 
	C2505.582520,3718.979980 2506.085205,3721.476562 2505.631104,3723.629883 
	C2503.644775,3733.044922 2505.870361,3742.704102 2503.648682,3752.854980 
	C2500.441895,3755.782715 2499.932373,3758.961670 2500.097412,3763.128906 
	C2501.048340,3765.366211 2500.167725,3766.320557 2498.566406,3767.656738 
	C2496.473633,3771.351074 2494.581299,3774.471680 2493.822754,3778.781250 
	C2489.943848,3780.368164 2486.890381,3778.610352 2484.025635,3776.639160 
	C2481.983398,3775.233887 2480.624023,3775.464111 2478.774414,3777.845215 
	C2477.709473,3772.122314 2477.687500,3766.184814 2481.832031,3761.144287 
	C2482.900146,3759.845703 2483.801758,3758.396484 2483.222656,3756.619385 
	C2481.845703,3752.393066 2483.783691,3748.625000 2485.503418,3745.248779 
	C2492.081299,3732.333252 2492.432373,3718.587646 2492.069336,3704.593994 
	C2491.657227,3688.721191 2495.148926,3672.995361 2494.100830,3657.078125 
	C2493.919678,3654.330078 2495.430908,3652.013428 2496.215088,3649.501465 
	C2497.982422,3643.843018 2501.946533,3639.127930 2502.837891,3633.057373 
	C2503.220215,3630.452881 2504.865479,3628.090332 2508.909912,3628.117676 
	C2509.318848,3632.487793 2505.974854,3636.019043 2506.013672,3641.269531 
	C2508.093506,3645.827148 2506.806641,3649.715820 2506.360107,3653.620117 
	C2505.043457,3665.130615 2505.066895,3676.641846 2506.556152,3688.146729 
	C2507.189941,3693.041748 2508.093506,3697.997070 2504.612793,3703.274414 
z"
        />
        <path
          fill="#BA7C55"
          opacity="1.000000"
          stroke="none"
          d="
M2417.753906,3808.604980 
	C2418.886230,3801.106689 2421.488770,3794.840820 2425.798340,3788.537109 
	C2430.421387,3785.343994 2432.159668,3789.183594 2434.382080,3791.261230 
	C2439.754395,3796.284668 2443.718750,3794.806641 2447.202637,3789.036133 
	C2452.933594,3779.543457 2451.534180,3771.913086 2441.794678,3760.211914 
	C2440.062256,3758.130859 2437.676514,3756.853760 2435.324951,3754.415527 
	C2433.781738,3751.333252 2434.104492,3748.985107 2436.160645,3746.255859 
	C2437.800293,3745.011963 2439.014160,3744.661865 2440.384033,3744.755859 
	C2443.779053,3744.988037 2448.285400,3749.507324 2448.911865,3748.212646 
	C2451.522461,3742.819824 2458.288574,3740.945557 2459.782959,3734.092285 
	C2460.311035,3732.965820 2460.573730,3732.529297 2461.399414,3731.550293 
	C2463.952637,3729.865723 2466.060547,3729.718506 2469.027344,3730.600586 
	C2470.228027,3731.324951 2470.651855,3731.707275 2471.517090,3732.829590 
	C2473.496826,3738.702637 2473.981445,3743.773926 2470.544189,3749.327393 
	C2470.009766,3750.007080 2470.000000,3750.000000 2470.004883,3750.004883 
	C2469.397217,3750.734619 2468.579346,3750.958252 2467.673340,3750.965820 
	C2468.128662,3751.120117 2468.612793,3751.074219 2469.533691,3751.588867 
	C2470.617188,3755.601318 2468.735840,3758.249512 2466.357910,3761.445312 
	C2462.500977,3764.422607 2461.834473,3767.487305 2463.027832,3771.553711 
	C2465.595947,3780.302002 2463.100830,3788.672363 2457.285400,3794.737061 
	C2448.109619,3804.307373 2439.418213,3815.949463 2423.187744,3813.058838 
	C2420.599854,3812.597656 2418.818604,3811.803223 2417.753906,3808.604980 
z"
        />
        <path
          fill="#BA7C55"
          opacity="1.000000"
          stroke="none"
          d="
M2453.548828,3632.575928 
	C2459.890137,3630.466553 2464.156250,3626.152588 2469.305908,3623.379883 
	C2476.472656,3619.522217 2481.775391,3621.413330 2481.834473,3629.479736 
	C2481.892822,3637.471436 2483.711914,3645.103271 2485.460205,3653.437012 
	C2486.001465,3655.497314 2485.993164,3656.994385 2485.973877,3659.240234 
	C2482.143066,3665.392822 2484.316895,3671.494629 2484.301758,3677.222656 
	C2484.285889,3683.190674 2483.267334,3688.284424 2477.837402,3692.497559 
	C2468.307373,3690.113525 2468.908447,3680.478027 2469.378662,3675.783203 
	C2470.927979,3660.309570 2463.689453,3649.965088 2453.586914,3639.444824 
	C2452.960693,3638.393311 2452.762451,3637.938721 2452.436035,3636.745117 
	C2452.308594,3634.982178 2452.541504,3634.014404 2453.548828,3632.575928 
z"
        />
        <path
          fill="#AB7453"
          opacity="1.000000"
          stroke="none"
          d="
M2523.988281,3546.800293 
	C2526.884766,3546.084473 2528.749023,3544.093750 2531.437500,3542.285156 
	C2534.273926,3552.728516 2540.487305,3562.886963 2536.394775,3574.553223 
	C2535.122070,3578.181885 2538.348389,3581.535156 2537.948730,3585.264404 
	C2537.807373,3586.583984 2537.820312,3587.980713 2536.542725,3588.739746 
	C2535.141602,3589.572021 2533.952881,3588.683105 2532.979980,3587.821289 
	C2530.741211,3585.839600 2528.495361,3583.854736 2528.025391,3580.592529 
	C2527.791016,3578.967773 2527.380127,3577.328369 2526.772705,3575.806152 
	C2525.476318,3572.557617 2523.591797,3569.712891 2519.520996,3570.220215 
	C2514.940430,3570.790527 2512.774414,3573.749512 2512.029541,3578.221436 
	C2511.025146,3584.251709 2513.818848,3589.834229 2513.564697,3595.916504 
	C2513.323242,3601.703125 2512.326660,3606.142334 2507.613281,3609.687012 
	C2504.300537,3612.177979 2502.552490,3615.813477 2502.015137,3619.929688 
	C2501.484863,3623.991211 2499.312744,3626.594238 2494.718262,3627.916016 
	C2496.714111,3620.961670 2497.767578,3612.361816 2502.548584,3607.244629 
	C2509.047607,3600.288574 2511.806396,3594.544189 2506.690918,3586.195068 
	C2508.872559,3578.768555 2507.347656,3571.091309 2508.220215,3563.597656 
	C2508.907227,3557.694336 2514.350098,3555.937988 2518.753174,3554.443359 
	C2522.647949,3553.121094 2524.298584,3551.460449 2523.988281,3546.800293 
z"
        />
        <path
          fill="#A36F51"
          opacity="1.000000"
          stroke="none"
          d="
M2417.141113,3808.322266 
	C2423.556152,3812.373291 2433.576416,3812.981934 2438.178711,3808.011719 
	C2441.194092,3804.755127 2444.526855,3802.660400 2448.157959,3800.416748 
	C2456.334717,3795.363037 2459.443848,3786.902832 2461.566406,3778.047852 
	C2462.233643,3775.264893 2460.658203,3772.835205 2459.663818,3770.450928 
	C2456.713867,3763.377441 2456.602783,3763.423828 2465.283691,3762.042969 
	C2466.105713,3764.372314 2465.187256,3767.352295 2466.896484,3768.827393 
	C2475.855957,3776.560303 2468.999756,3783.368408 2465.176270,3789.372314 
	C2461.489746,3795.161133 2456.441162,3800.204590 2452.738281,3806.097900 
	C2448.241699,3813.253906 2440.337646,3814.584229 2433.505615,3817.352783 
	C2429.547852,3818.956543 2425.858154,3813.708496 2420.535400,3814.003418 
	C2418.864746,3813.525391 2417.985352,3813.055420 2416.656250,3812.376953 
	C2416.382324,3811.027100 2416.558350,3809.885742 2417.141113,3808.322266 
z"
        />
        <path
          fill="#AB7453"
          opacity="1.000000"
          stroke="none"
          d="
M2532.610107,3537.941406 
	C2528.244141,3533.899170 2527.444824,3528.550781 2526.377930,3523.459473 
	C2525.509521,3519.316895 2526.136963,3514.889893 2523.057373,3511.272705 
	C2522.362061,3510.455811 2522.349365,3508.715088 2522.580078,3507.506104 
	C2524.824951,3495.737793 2523.998047,3484.183350 2520.573730,3472.743408 
	C2519.809570,3470.190674 2519.566406,3467.537842 2521.559082,3464.524414 
	C2527.590820,3463.449951 2527.923828,3467.961426 2529.402100,3471.373779 
	C2531.195068,3475.511719 2529.996582,3480.486328 2533.391113,3484.973633 
	C2534.317139,3487.151367 2534.622314,3488.682861 2534.787598,3491.012451 
	C2534.086914,3499.587402 2533.963379,3507.390381 2535.432129,3515.847168 
	C2536.267334,3520.553223 2535.241211,3524.654785 2536.096680,3529.339355 
	C2536.035645,3531.483398 2536.049561,3532.957031 2536.089111,3535.165039 
	C2535.146729,3536.557373 2534.178467,3537.215088 2532.610107,3537.941406 
z"
        />
        <path
          fill="#B0714F"
          opacity="1.000000"
          stroke="none"
          d="
M2475.520264,3692.502686 
	C2479.019287,3687.128174 2482.903564,3683.256104 2480.968506,3675.965820 
	C2479.791260,3671.530762 2480.370117,3664.895508 2485.231201,3660.176514 
	C2486.129639,3663.578857 2486.033936,3667.145264 2487.144531,3670.696289 
	C2487.906006,3673.132080 2488.257324,3675.938965 2487.885498,3678.445068 
	C2486.181641,3689.927246 2485.916992,3701.722656 2481.004639,3712.551270 
	C2480.775391,3713.055908 2479.991943,3713.309326 2478.738281,3713.843994 
	C2472.746582,3714.648926 2472.630127,3711.027100 2472.561035,3707.399902 
	C2472.493896,3703.865723 2474.030518,3700.579590 2474.208008,3696.376953 
	C2474.322754,3694.734375 2474.602051,3693.847168 2475.520264,3692.502686 
z"
        />
        <path
          fill="#BA7C55"
          opacity="1.000000"
          stroke="none"
          d="
M2475.198730,3695.555420 
	C2478.277588,3697.851074 2477.122314,3699.526367 2476.546143,3701.923828 
	C2475.680908,3705.524414 2472.033691,3709.806396 2477.443359,3713.712402 
	C2478.019043,3714.893066 2478.028320,3715.777832 2478.048096,3717.326172 
	C2478.115479,3720.372314 2477.383789,3722.572754 2476.366211,3725.419434 
	C2476.187744,3727.583008 2476.143311,3729.065186 2476.073730,3731.288086 
	C2474.665039,3733.155029 2473.120117,3733.473145 2470.718750,3732.492188 
	C2470.029297,3732.029297 2470.007324,3732.007080 2469.996338,3731.998535 
	C2466.610840,3729.301025 2465.131592,3725.723145 2465.868408,3721.555420 
	C2466.902344,3715.706055 2466.849121,3709.822266 2467.160156,3703.937500 
	C2467.396973,3699.457520 2468.824707,3695.453369 2475.198730,3695.555420 
z"
        />
        <path
          fill="#B0714F"
          opacity="1.000000"
          stroke="none"
          d="
M2470.020264,3732.926270 
	C2471.533203,3732.021729 2473.037109,3732.014404 2475.294922,3732.018066 
	C2476.333252,3733.913330 2476.316162,3735.907471 2476.956787,3737.661377 
	C2479.132568,3743.619141 2477.324219,3747.600586 2470.730957,3749.919434 
	C2470.010254,3744.612549 2470.010742,3739.218018 2470.020264,3732.926270 
z"
        />
        <path
          fill="#B0714F"
          opacity="1.000000"
          stroke="none"
          d="
M2476.862793,3725.997559 
	C2472.743896,3723.282227 2474.162109,3720.887695 2477.469238,3718.297852 
	C2482.817871,3722.306885 2482.815430,3722.342041 2476.862793,3725.997559 
z"
        />
        <path
          fill="#A36F51"
          opacity="1.000000"
          stroke="none"
          d="
M2430.550293,3825.588135 
	C2428.003418,3825.082764 2426.536865,3823.625977 2426.105225,3820.612793 
	C2429.006348,3820.462646 2431.951904,3820.968262 2430.550293,3825.588135 
z"
        />
        <path
          fill="#B0714F"
          opacity="1.000000"
          stroke="none"
          d="
M2469.998047,3751.509766 
	C2469.179199,3751.673096 2468.368408,3751.336426 2467.557373,3750.999756 
	C2468.055908,3750.690674 2468.554443,3750.381592 2469.531250,3750.041016 
	C2470.008545,3750.343018 2470.007080,3750.676514 2469.998047,3751.509766 
z"
        />
        <path
          fill="#603A24"
          opacity="1.000000"
          stroke="none"
          d="
M2477.572754,3778.434570 
	C2478.485596,3779.544922 2477.945557,3780.899414 2476.622559,3782.682617 
	C2476.363281,3781.732910 2476.673584,3780.305420 2477.572754,3778.434570 
z"
        />
        <path
          fill="#1C2E3E"
          opacity="1.000000"
          stroke="none"
          d="
M2795.394531,3530.348389 
	C2796.892090,3530.054199 2797.765381,3530.057617 2799.293701,3530.054199 
	C2800.770020,3530.885742 2801.674316,3531.593506 2803.420898,3531.930664 
	C2811.677246,3530.794678 2817.763184,3536.285156 2825.497070,3537.537109 
	C2825.752441,3538.308594 2825.515137,3538.699707 2825.251465,3538.718750 
	C2813.888916,3539.531982 2802.691650,3542.794189 2790.587402,3540.487305 
	C2789.110352,3535.562744 2792.540283,3533.411377 2795.394531,3530.348389 
z"
        />
        <path
          fill="#1C2E3E"
          opacity="1.000000"
          stroke="none"
          d="
M2778.106201,3543.357910 
	C2773.267578,3542.618164 2768.506836,3541.222656 2763.717529,3539.933594 
	C2761.995361,3539.469971 2760.458740,3538.822510 2760.595215,3536.797363 
	C2760.733154,3534.753906 2762.412354,3534.338623 2764.139160,3534.070801 
	C2765.756104,3533.820068 2767.347656,3533.404297 2769.473633,3532.543457 
	C2773.927734,3532.179443 2776.873291,3535.090088 2781.303467,3535.897461 
	C2782.272705,3536.334473 2782.538330,3536.688477 2782.409180,3537.576172 
	C2780.982422,3539.860596 2778.481689,3540.394043 2778.106201,3543.357910 
z"
        />
        <path
          fill="#102131"
          opacity="1.000000"
          stroke="none"
          d="
M2782.698242,3538.177490 
	C2782.011475,3537.755127 2782.009033,3537.400146 2782.006348,3536.512695 
	C2784.395996,3533.961670 2786.781982,3531.938965 2789.175781,3529.925781 
	C2790.984131,3528.405029 2792.899658,3527.554688 2795.587402,3529.546875 
	C2795.628174,3533.791992 2793.907959,3536.750488 2790.414307,3539.415771 
	C2787.806641,3539.370850 2785.594727,3538.808105 2782.698242,3538.177490 
z"
        />
        <path
          fill="#AB7453"
          opacity="1.000000"
          stroke="none"
          d="
M2542.131592,3395.576660 
	C2541.333740,3395.640625 2540.505371,3395.302002 2540.062500,3395.239258 
	C2536.947266,3397.137451 2533.736816,3398.911865 2530.682617,3398.240234 
	C2522.760498,3396.498535 2521.846436,3401.353027 2520.898438,3407.584717 
	C2519.104248,3408.924561 2517.728271,3408.712646 2515.707520,3407.876953 
	C2509.892090,3402.981934 2504.492432,3398.821777 2503.996582,3390.830322 
	C2503.757324,3386.975098 2501.198975,3385.190430 2496.222900,3386.343506 
	C2487.630615,3388.333984 2484.327881,3385.022217 2484.167725,3375.607910 
	C2484.142334,3374.111572 2484.011230,3373.602783 2482.867920,3372.668945 
	C2472.028076,3363.814209 2472.060547,3363.774414 2476.922119,3350.486328 
	C2483.687256,3346.188965 2485.933350,3354.950439 2491.699707,3353.998291 
	C2498.681641,3351.766113 2498.527588,3359.251221 2503.504150,3361.224609 
	C2502.040771,3358.223633 2499.736816,3355.702637 2501.375244,3352.756592 
	C2503.095703,3349.663330 2506.110596,3349.609375 2509.847168,3349.951904 
	C2510.978516,3350.278320 2511.416016,3350.455811 2512.448242,3351.011719 
	C2513.546875,3351.807617 2514.134521,3352.736328 2514.449707,3352.662598 
	C2527.161133,3349.675049 2532.813477,3360.744385 2541.928711,3366.465820 
	C2544.064941,3368.055664 2545.355469,3369.503418 2547.001465,3371.618652 
	C2548.211182,3375.237305 2548.140625,3378.196777 2546.934570,3381.838867 
	C2546.291504,3383.506836 2546.099365,3384.507080 2546.028564,3386.315674 
	C2545.850342,3390.219727 2546.426025,3393.610352 2542.131592,3395.576660 
z"
        />
        <path
          fill="#0D171F"
          opacity="1.000000"
          stroke="none"
          d="
M2541.559326,3396.448730 
	C2542.227295,3392.835449 2543.120850,3390.030273 2544.299805,3386.536133 
	C2544.863281,3385.080566 2545.002930,3384.290771 2544.943359,3382.868164 
	C2545.158691,3379.380615 2545.412842,3376.499023 2545.546387,3372.895020 
	C2544.015625,3369.702881 2543.739014,3366.922852 2543.205322,3363.373535 
	C2543.132324,3360.894043 2543.120850,3359.243652 2543.519531,3356.804688 
	C2544.983643,3348.409180 2547.963135,3341.832520 2552.779297,3335.520508 
	C2558.236328,3328.368164 2559.369873,3318.981689 2562.040039,3310.438477 
	C2563.178467,3306.795410 2563.085693,3302.253174 2568.807617,3301.082275 
	C2573.811523,3307.480469 2580.714355,3310.269775 2586.667480,3314.326172 
	C2590.206299,3316.737061 2594.058594,3318.953125 2595.915039,3324.081543 
	C2595.901367,3331.905029 2592.379883,3334.817139 2584.736328,3333.098145 
	C2580.445068,3329.091797 2578.061768,3323.633057 2571.605957,3323.942383 
	C2570.752197,3326.376709 2572.915039,3327.589844 2573.353516,3330.115234 
	C2572.265381,3339.401123 2568.797852,3347.176514 2564.468262,3355.388672 
	C2561.144043,3356.208984 2559.982666,3357.888672 2559.968262,3361.270996 
	C2558.255371,3363.291748 2558.095703,3365.532715 2556.426270,3367.557129 
	C2551.699707,3370.185059 2549.528320,3373.594482 2549.994141,3379.255371 
	C2549.958496,3381.186768 2549.604248,3382.257324 2548.431885,3383.584961 
	C2547.997559,3386.122803 2547.975586,3388.257080 2547.946777,3391.191895 
	C2547.246094,3395.783203 2548.421631,3398.387451 2552.528320,3398.986572 
	C2554.809570,3399.319092 2557.016113,3399.847900 2559.039795,3400.959473 
	C2561.774414,3402.460938 2563.992676,3404.497314 2563.195557,3407.918945 
	C2562.317627,3411.687012 2559.292480,3411.741699 2556.183838,3411.250732 
	C2554.229980,3410.941895 2552.289551,3410.925781 2549.690674,3411.837402 
	C2546.587646,3412.926270 2544.170654,3413.658691 2541.063965,3412.307129 
	C2539.313965,3406.791992 2542.581299,3402.200684 2541.559326,3396.448730 
z"
        />
        <path
          fill="#1C2E3E"
          opacity="1.000000"
          stroke="none"
          d="
M2540.396484,3412.510254 
	C2542.585205,3411.204590 2544.644531,3410.637451 2547.359863,3410.001953 
	C2561.661621,3409.883301 2574.179443,3414.768799 2587.423828,3419.425781 
	C2595.796143,3429.176514 2607.216797,3431.387207 2617.846191,3433.537109 
	C2629.800049,3435.954590 2640.824707,3440.993408 2654.122559,3442.679688 
	C2650.813232,3447.162842 2648.129639,3448.261719 2644.088867,3447.094482 
	C2638.098633,3445.364258 2632.099121,3443.359863 2624.826416,3444.049316 
	C2623.167480,3444.749268 2622.253662,3445.193359 2620.455566,3445.397949 
	C2609.655762,3440.934570 2599.843018,3435.845947 2587.952637,3434.443848 
	C2580.320068,3431.236084 2573.221191,3429.015869 2564.842285,3428.908447 
	C2559.951904,3429.188232 2556.066895,3428.001465 2551.458008,3426.668701 
	C2548.920654,3425.798828 2547.209961,3424.881592 2544.831055,3423.747803 
	C2542.088867,3421.933838 2542.255371,3419.352783 2540.881348,3416.784180 
	C2540.424072,3415.233154 2540.345459,3414.241211 2540.396484,3412.510254 
z"
        />
        <path
          fill="#935F47"
          opacity="1.000000"
          stroke="none"
          d="
M2536.836182,3405.403564 
	C2534.749268,3419.667725 2536.261230,3433.536621 2535.955322,3447.334717 
	C2535.816650,3453.596680 2535.929932,3459.864014 2535.877930,3467.064941 
	C2529.921143,3470.058350 2529.598145,3465.079102 2528.273682,3461.769287 
	C2525.776123,3455.529541 2527.851074,3448.795166 2526.287354,3441.561279 
	C2525.828125,3439.578125 2525.747559,3438.420166 2525.890381,3436.388672 
	C2527.621582,3429.423340 2527.004150,3423.395020 2526.082764,3417.209473 
	C2524.862549,3409.013916 2528.169922,3405.384766 2536.836182,3405.403564 
z"
        />
        <path
          fill="#96664C"
          opacity="1.000000"
          stroke="none"
          d="
M2537.659668,3405.198730 
	C2530.067383,3407.109619 2527.417236,3410.340332 2528.919434,3417.531738 
	C2530.138428,3423.367676 2530.641602,3429.061279 2528.494629,3435.427734 
	C2522.464355,3435.293213 2523.403320,3430.511719 2522.456055,3427.087646 
	C2521.416748,3423.329590 2520.906738,3419.427979 2519.928223,3414.844727 
	C2519.503662,3412.548096 2519.463379,3411.010254 2519.906250,3408.719238 
	C2520.756836,3403.134033 2518.635254,3398.486572 2518.484375,3392.000732 
	C2525.404053,3398.390869 2532.024170,3395.063477 2539.075439,3394.823730 
	C2540.155029,3398.483154 2537.944336,3401.257324 2537.659668,3405.198730 
z"
        />
        <path
          fill="#0D171F"
          opacity="1.000000"
          stroke="none"
          d="
M2549.450684,3423.668945 
	C2554.173584,3424.623779 2558.289307,3425.294189 2563.197754,3425.977539 
	C2566.170410,3428.146240 2568.369141,3430.433105 2566.265625,3433.695068 
	C2564.640625,3436.215088 2561.820557,3436.015137 2559.145996,3436.257568 
	C2555.374268,3436.598877 2550.454102,3431.353027 2547.970459,3437.741943 
	C2545.507568,3444.077637 2550.739502,3446.281738 2555.065186,3448.841797 
	C2557.285889,3450.156250 2557.864014,3452.407715 2556.432373,3455.453857 
	C2552.723145,3456.249023 2549.387939,3455.423828 2546.134521,3456.649658 
	C2544.563965,3459.333008 2547.166016,3462.602051 2544.395996,3465.597656 
	C2543.287354,3464.900879 2542.156982,3463.714844 2542.208496,3462.582520 
	C2542.886719,3447.658691 2539.160645,3432.935059 2540.375000,3417.115234 
	C2543.256348,3416.433838 2543.909668,3418.302002 2544.010254,3421.287842 
	C2545.284668,3423.614990 2546.989990,3423.747559 2549.450684,3423.668945 
z"
        />
        <path
          fill="#96664C"
          opacity="1.000000"
          stroke="none"
          d="
M2527.204590,3439.878906 
	C2531.312744,3443.213623 2529.811035,3447.389404 2529.937012,3451.168457 
	C2530.133057,3457.052002 2530.428711,3462.811279 2535.115234,3467.814453 
	C2535.870117,3470.149658 2535.913330,3472.298340 2535.916504,3475.252197 
	C2535.573486,3479.543945 2537.300537,3483.496338 2532.771240,3486.101562 
	C2528.856445,3484.523438 2528.505859,3481.718994 2527.827637,3478.653320 
	C2526.781494,3473.925293 2527.191406,3468.609375 2522.735352,3464.376221 
	C2522.092529,3459.924316 2522.070312,3455.845459 2522.052734,3450.892822 
	C2523.397461,3446.541992 2522.535400,3442.113037 2527.204590,3439.878906 
z"
        />
        <path
          fill="#96664C"
          opacity="1.000000"
          stroke="none"
          d="
M2534.750000,3518.199219 
	C2530.097168,3510.139160 2530.601074,3501.912842 2532.006836,3492.802246 
	C2537.437744,3489.135986 2537.188721,3494.281494 2539.216797,3497.406982 
	C2539.732178,3500.164795 2539.833984,3502.312012 2539.892578,3505.265137 
	C2539.537354,3510.573975 2541.815674,3515.985352 2534.750000,3518.199219 
z"
        />
        <path
          fill="#935F47"
          opacity="1.000000"
          stroke="none"
          d="
M2533.901123,3518.682129 
	C2539.238770,3515.985352 2537.101562,3510.646973 2539.363281,3506.566162 
	C2544.172119,3514.557129 2542.265381,3522.357178 2536.628906,3529.911621 
	C2532.220947,3527.090332 2532.163818,3523.399658 2533.901123,3518.682129 
z"
        />
        <path
          fill="#935F47"
          opacity="1.000000"
          stroke="none"
          d="
M2539.710449,3497.200439 
	C2535.700439,3498.081299 2536.552979,3492.931885 2532.671631,3492.110840 
	C2532.010254,3490.495117 2532.000977,3489.000000 2531.990967,3486.757324 
	C2535.190430,3483.739502 2533.071533,3479.745605 2535.388184,3476.543945 
	C2539.741211,3482.339600 2537.141357,3489.866455 2539.710449,3497.200439 
z"
        />
        <path
          fill="#05131D"
          opacity="1.000000"
          stroke="none"
          d="
M886.877258,3894.048340 
	C893.894226,3888.621826 900.746155,3891.835449 907.321167,3894.069092 
	C910.472351,3895.139893 909.318237,3897.858887 906.838501,3899.203369 
	C899.594299,3903.132080 893.145874,3901.630127 886.877258,3894.048340 
z"
        />
        <path
          fill="#0C1A26"
          opacity="1.000000"
          stroke="none"
          d="
M1086.374756,3203.369629 
	C1083.445312,3203.986572 1081.363037,3204.604248 1079.215942,3205.797852 
	C1070.581665,3210.597900 1070.534912,3210.495850 1078.101196,3216.063965 
	C1079.159424,3216.842529 1080.347900,3217.586914 1081.087524,3218.624023 
	C1082.427612,3220.503418 1083.960205,3222.627686 1083.014160,3225.032715 
	C1082.044312,3227.497559 1079.598389,3228.117676 1077.170776,3227.974609 
	C1070.143433,3227.561523 1063.179443,3228.250732 1056.465088,3230.100586 
	C1045.797485,3233.039795 1035.445557,3231.272705 1025.185059,3228.608643 
	C1021.345764,3227.612061 1017.952759,3230.109863 1014.412964,3229.042236 
	C1008.467468,3227.248779 1002.158386,3228.302490 996.175781,3224.949707 
	C991.549500,3222.357178 985.232910,3223.667236 978.796082,3224.789062 
	C980.686218,3228.928467 984.796936,3229.206543 987.224670,3231.446045 
	C988.646301,3232.757568 991.154968,3233.412354 990.914185,3235.669189 
	C990.631775,3238.315186 988.054321,3237.826172 986.154968,3238.030518 
	C985.165466,3238.137207 984.163513,3238.158203 983.183777,3238.319092 
	C981.726135,3238.558594 980.652771,3239.472900 980.671204,3240.923828 
	C980.696716,3242.941406 982.260742,3243.945801 984.012756,3243.739502 
	C988.603333,3243.198730 993.889221,3243.583252 997.585571,3241.388916 
	C1007.545837,3235.474854 1017.752747,3236.244385 1028.307129,3237.953125 
	C1032.838501,3238.686768 1037.335205,3238.943359 1041.996582,3238.543945 
	C1045.779541,3238.220215 1047.897339,3242.054443 1045.475220,3243.594482 
	C1040.765259,3246.589600 1042.849609,3248.029053 1046.102295,3249.714355 
	C1051.427246,3252.473389 1056.818970,3254.796875 1063.106567,3253.986816 
	C1067.502686,3253.420410 1071.572998,3254.690918 1075.591797,3256.634521 
	C1079.941772,3258.738525 1082.709351,3261.486084 1082.004150,3266.619873 
	C1081.437622,3270.744141 1083.681030,3274.377441 1083.968750,3279.169434 
	C1083.020020,3280.897705 1082.317383,3282.431885 1081.120361,3282.692871 
	C1063.955078,3286.437744 1046.042358,3291.090576 1029.176758,3288.351318 
	C1016.153320,3286.235840 1003.544678,3285.886719 990.837524,3286.351074 
	C958.336365,3287.539062 925.996704,3291.430664 893.528015,3293.342529 
	C883.931763,3293.907715 875.312439,3300.265137 864.457214,3297.536621 
	C859.772583,3294.388184 858.895630,3290.337402 858.949219,3285.026367 
	C857.590149,3269.731445 856.902527,3255.273682 856.003052,3240.041992 
	C862.009521,3231.476318 871.284912,3229.588379 879.445129,3226.527832 
	C900.322510,3218.696533 920.872620,3210.095459 941.515076,3201.705566 
	C944.008667,3200.692383 946.523315,3199.895264 949.981812,3200.140625 
	C961.922485,3203.448975 966.978210,3201.945557 974.137634,3192.669922 
	C975.395081,3189.458496 977.307068,3188.309082 980.752441,3188.591309 
	C986.239197,3191.635254 990.466492,3189.274414 994.620117,3187.239746 
	C1010.167114,3179.624512 1026.974976,3175.001465 1042.452759,3167.323242 
	C1054.251587,3161.470215 1066.248291,3163.194092 1079.105957,3164.839111 
	C1085.799927,3177.034424 1088.998169,3189.377686 1086.374756,3203.369629 
z"
        />
        <path
          fill="#05121F"
          opacity="1.000000"
          stroke="none"
          d="
M1032.752441,3420.000000 
	C1031.949951,3419.661133 1031.999268,3419.125732 1031.823364,3419.036621 
	C1021.118958,3413.634766 1011.322327,3406.218018 999.084656,3404.211426 
	C998.433289,3404.104492 997.709900,3403.922607 997.109802,3404.092773 
	C987.104370,3406.927246 978.646301,3401.558350 969.375305,3398.889893 
	C968.485046,3400.530518 967.837402,3402.476562 966.585632,3403.885986 
	C963.159607,3407.743408 959.279480,3408.483643 955.178955,3405.377930 
	C950.935059,3402.163574 946.344604,3399.562744 942.010925,3396.562988 
	C938.701965,3394.272461 935.899475,3391.631592 931.478210,3391.836914 
	C929.009827,3391.951416 927.146851,3390.343994 925.715881,3388.394531 
	C924.549561,3386.805420 924.538635,3385.127197 926.481384,3384.358154 
	C929.828247,3383.032959 933.098145,3381.572021 937.026672,3382.573975 
	C946.379028,3384.958984 955.347168,3382.893066 963.720398,3378.754639 
	C966.624451,3377.319336 968.866760,3376.965332 972.028259,3377.496338 
	C978.212097,3378.534912 984.613708,3379.235840 989.612061,3373.722656 
	C991.289917,3371.871826 993.832886,3371.998779 996.167603,3372.009766 
	C998.900574,3372.022949 1001.498169,3371.746338 1003.625732,3368.613770 
	C1008.737305,3363.410400 1014.499817,3364.811279 1020.849915,3365.360596 
	C1027.187012,3368.653076 1032.988770,3369.019287 1039.495239,3366.472656 
	C1040.861450,3365.971924 1041.723022,3365.944092 1043.230957,3365.915039 
	C1048.529541,3360.795410 1055.572754,3364.030273 1061.439697,3360.606201 
	C1073.934570,3359.594482 1078.458008,3369.385254 1084.785156,3376.536377 
	C1085.740601,3377.616455 1085.808105,3379.601318 1086.016846,3381.203125 
	C1087.457520,3392.260742 1084.710815,3402.798096 1081.658936,3413.229004 
	C1080.373779,3417.621826 1073.285156,3421.098145 1068.269653,3420.482178 
	C1061.995239,3419.711182 1055.749390,3419.540039 1048.850586,3421.768799 
	C1045.523804,3419.326904 1042.309692,3420.164307 1038.639648,3420.041504 
	C1036.486694,3420.042969 1034.993042,3420.027344 1032.752441,3420.000000 
z"
        />
        <path
          fill="#EAE5D5"
          opacity="1.000000"
          stroke="none"
          d="
M1114.640869,3618.464111 
	C1114.579102,3611.859619 1113.018188,3606.440430 1109.066040,3601.583984 
	C1107.675171,3599.874756 1107.096924,3597.503906 1106.054199,3594.717773 
	C1106.432007,3593.247070 1107.002808,3592.556885 1107.386719,3591.748779 
	C1106.276855,3591.709229 1105.942749,3592.892090 1104.550903,3593.615234 
	C1102.511475,3594.294189 1100.518433,3595.381348 1100.188965,3592.764893 
	C1099.858765,3590.142822 1100.004639,3587.455811 1100.002930,3584.797363 
	C1099.986572,3560.842285 1100.968506,3536.833252 1099.666260,3512.948730 
	C1098.617065,3493.709717 1099.057251,3474.432373 1097.615601,3455.288330 
	C1096.598999,3441.788086 1095.450317,3428.264893 1096.306885,3414.869141 
	C1097.211914,3400.719727 1097.187500,3386.659180 1095.859253,3372.660156 
	C1094.508057,3358.418213 1096.730957,3344.296875 1096.016357,3330.118896 
	C1095.815918,3326.143555 1096.305786,3322.072754 1092.732422,3317.736328 
	C1092.205688,3315.763428 1092.328613,3314.810059 1092.820801,3313.617676 
	C1091.939819,3300.532227 1091.853516,3287.650146 1092.001465,3273.791992 
	C1092.004150,3270.882812 1092.002686,3268.942627 1092.097900,3266.031250 
	C1092.341309,3263.769775 1092.659180,3262.511719 1092.421387,3260.586914 
	C1091.956055,3259.427734 1092.317871,3259.226807 1093.144287,3259.350586 
	C1092.351929,3253.740967 1096.113159,3254.772705 1100.110596,3255.415283 
	C1103.272217,3256.936768 1104.514893,3259.265869 1106.490723,3261.932129 
	C1107.148804,3262.997070 1107.377075,3263.441650 1107.862671,3264.585449 
	C1108.805298,3268.631348 1109.259033,3271.995850 1108.600830,3276.077637 
	C1106.182739,3288.806885 1103.135376,3300.813965 1105.342773,3313.229492 
	C1106.266846,3318.427979 1108.051025,3323.433105 1106.623291,3328.947754 
	C1105.618286,3332.829590 1108.661255,3334.993652 1111.751099,3336.566406 
	C1117.161865,3339.319824 1121.341309,3341.659424 1114.240967,3347.809570 
	C1110.715332,3350.863525 1111.842651,3357.141357 1108.496704,3361.208008 
	C1107.274902,3362.693359 1109.553955,3364.744385 1111.229614,3365.903564 
	C1113.159302,3367.238525 1115.485229,3367.827881 1117.479980,3369.001465 
	C1121.446045,3371.334961 1128.727051,3371.419189 1126.842773,3378.400635 
	C1125.124878,3384.765625 1118.911987,3380.488525 1114.380615,3381.439941 
	C1115.314453,3386.449463 1119.180786,3388.477783 1122.736328,3390.716309 
	C1125.570679,3392.500977 1128.852905,3393.327637 1131.864014,3394.730957 
	C1134.812134,3396.104492 1138.952515,3397.380371 1137.721558,3401.484863 
	C1136.715332,3404.840088 1132.784912,3404.272217 1129.849731,3403.828125 
	C1125.245972,3403.131348 1120.432983,3403.452881 1116.048706,3401.819092 
	C1111.274780,3400.040771 1109.262573,3402.559326 1109.852417,3406.325684 
	C1111.584961,3417.390625 1107.402344,3431.405029 1123.494263,3435.983154 
	C1125.435425,3436.535400 1127.021484,3438.011719 1126.919556,3440.365479 
	C1126.803955,3443.032471 1125.075439,3444.497314 1122.752197,3445.407959 
	C1120.172974,3446.419189 1117.472534,3445.369629 1114.222046,3445.959473 
	C1118.062622,3451.240723 1123.693237,3456.328125 1113.486816,3461.127197 
	C1108.991333,3463.240723 1112.131226,3467.707275 1114.371216,3470.475098 
	C1118.469604,3475.538086 1117.683472,3479.760254 1112.503540,3483.020020 
	C1108.387329,3485.609619 1107.549072,3488.659424 1108.941040,3493.122559 
	C1110.567505,3498.337402 1107.795654,3503.506348 1108.479736,3508.940430 
	C1109.682495,3518.494873 1114.442261,3526.493896 1118.351807,3535.683838 
	C1117.958496,3540.804688 1122.633057,3546.376709 1115.008789,3549.477051 
	C1113.534424,3549.491943 1112.744263,3549.453369 1111.625366,3549.819092 
	C1111.392212,3551.284668 1111.730103,3552.345703 1112.158325,3554.088623 
	C1117.630737,3568.096436 1119.963867,3582.155518 1122.286865,3596.215820 
	C1123.158203,3601.490723 1123.586670,3606.810791 1122.757324,3612.105225 
	C1122.114258,3616.209961 1121.043579,3620.248535 1114.640869,3618.464111 
z"
        />
        <path
          fill="#EEE7D5"
          opacity="1.000000"
          stroke="none"
          d="
M1089.051147,3216.553955 
	C1088.406738,3212.649902 1088.170532,3209.269287 1087.738281,3205.059082 
	C1086.532471,3194.478271 1094.526001,3193.290771 1100.718872,3191.175049 
	C1106.979980,3189.036133 1109.414551,3194.435303 1111.677612,3198.820801 
	C1120.182739,3215.304199 1124.843994,3233.393799 1133.300781,3250.746582 
	C1140.208130,3271.597168 1149.153931,3290.747559 1158.291626,3309.812744 
	C1161.289795,3316.068359 1164.593994,3322.183594 1166.502441,3329.734131 
	C1168.232910,3342.777832 1172.448120,3354.459229 1174.787109,3367.388916 
	C1174.500732,3381.473389 1180.344116,3393.941162 1179.698242,3407.966797 
	C1174.376953,3414.041748 1170.819946,3410.014648 1170.466064,3405.734375 
	C1169.696655,3396.428467 1163.703979,3395.732910 1156.238525,3394.932617 
	C1155.012329,3393.760498 1154.592896,3392.891113 1154.316650,3391.208984 
	C1159.786865,3383.433350 1158.577148,3374.349609 1161.529541,3366.541016 
	C1163.059937,3362.493408 1160.575562,3361.428711 1157.764404,3360.013916 
	C1152.230835,3357.229004 1146.011230,3359.305908 1139.784668,3356.921631 
	C1137.336670,3354.645752 1136.425537,3352.422852 1136.859619,3349.079590 
	C1139.639771,3344.626221 1143.979004,3347.185547 1147.691162,3345.337402 
	C1145.722412,3333.472900 1135.825195,3325.863281 1131.135620,3315.435547 
	C1128.575195,3309.742432 1126.890991,3302.565186 1118.883545,3299.854492 
	C1114.238525,3293.240234 1112.018311,3286.320557 1110.199463,3278.532715 
	C1110.004883,3275.425537 1111.802124,3272.826904 1109.038574,3270.154541 
	C1107.440796,3268.340820 1107.061523,3266.686279 1106.402100,3264.486084 
	C1106.000000,3263.986816 1106.000000,3264.000000 1106.006348,3264.001465 
	C1105.380859,3263.297119 1104.984375,3262.481689 1104.943359,3260.850098 
	C1108.496582,3256.226318 1112.691162,3252.225342 1103.513672,3248.946045 
	C1096.521118,3240.288086 1095.587402,3229.753174 1091.108154,3219.967041 
	C1090.480591,3218.535645 1090.005615,3217.784180 1089.051147,3216.553955 
z"
        />
        <path
          fill="#172837"
          opacity="1.000000"
          stroke="none"
          d="
M863.326477,3296.754883 
	C870.816772,3295.591553 877.649841,3294.683350 883.957092,3291.420410 
	C885.111877,3290.823242 886.463074,3290.098145 887.667725,3290.187500 
	C895.051453,3290.733887 902.167297,3287.716064 909.376770,3288.158691 
	C924.387329,3289.081299 939.102905,3285.713623 954.041138,3285.873779 
	C958.275818,3285.919434 962.545715,3284.756836 966.762634,3283.955078 
	C976.769287,3282.052734 986.875793,3283.671387 996.870789,3282.974365 
	C1003.805420,3282.490723 1010.717468,3282.003662 1017.663208,3282.017334 
	C1020.290649,3282.022461 1023.713562,3281.099365 1025.292603,3283.246094 
	C1029.245850,3288.620850 1033.929565,3285.938477 1038.640259,3285.541748 
	C1051.031738,3284.498535 1063.149780,3280.880615 1075.759888,3281.753174 
	C1077.952881,3281.904541 1080.236206,3280.748779 1083.231934,3280.093750 
	C1083.994995,3282.768311 1084.003784,3285.546631 1084.008789,3289.157471 
	C1079.266357,3289.047119 1075.147095,3287.836914 1069.727905,3290.130371 
	C1065.740601,3291.817871 1060.176514,3292.484619 1055.173218,3292.911377 
	C1050.366821,3293.321533 1045.226807,3293.098877 1041.143066,3296.666748 
	C1039.421509,3298.170654 1037.443970,3297.110352 1035.476562,3296.896484 
	C1026.306274,3295.901855 1017.103821,3294.108887 1008.311462,3299.245117 
	C1006.253479,3300.447021 1003.117859,3299.938721 1000.474487,3299.977051 
	C994.149658,3300.069092 987.822754,3300.017090 981.496643,3300.025635 
	C978.727844,3300.029541 975.946472,3300.381348 974.790955,3303.290771 
	C973.303162,3307.036377 976.580139,3307.593994 978.884521,3308.737061 
	C980.139099,3309.359375 981.083252,3310.419434 980.884521,3311.836914 
	C980.668396,3313.379150 979.286194,3313.862305 977.906921,3313.872070 
	C971.916504,3313.914795 965.909729,3314.152832 959.940735,3313.792480 
	C956.171997,3313.565186 953.251343,3312.171143 956.380249,3307.426025 
	C958.215332,3304.643555 956.513733,3302.812744 953.656189,3302.753418 
	C937.426880,3302.416748 921.193359,3302.101562 904.962158,3302.139648 
	C899.748352,3302.151855 897.505249,3305.081543 898.127808,3309.727539 
	C898.743774,3314.324219 901.759521,3315.964355 905.938232,3314.882324 
	C909.589844,3313.937012 912.426575,3314.464355 915.757324,3318.771240 
	C899.752197,3321.242188 884.771301,3321.386719 868.919922,3318.064453 
	C860.458252,3312.695557 862.077148,3304.950684 863.326477,3296.754883 
z"
        />
        <path
          fill="#05101B"
          opacity="1.000000"
          stroke="none"
          d="
M1086.605713,3551.596436 
	C1081.684570,3548.298096 1077.291748,3544.882080 1070.757690,3545.577881 
	C1068.094849,3545.861572 1062.367920,3538.026123 1062.406738,3535.350098 
	C1062.449341,3532.414551 1064.774536,3531.285156 1066.944580,3530.754150 
	C1073.138550,3529.238770 1075.586426,3524.994629 1075.950684,3519.177490 
	C1076.185059,3515.433105 1077.789673,3512.122070 1078.768799,3508.616699 
	C1080.953735,3500.793701 1080.293701,3500.717285 1073.764526,3495.954346 
	C1067.888306,3491.667969 1062.159302,3488.832031 1054.800415,3489.917725 
	C1051.166138,3490.454102 1048.780396,3487.416016 1045.702148,3486.292236 
	C1039.050415,3483.863037 1031.955566,3484.518311 1024.586914,3482.565430 
	C1026.473022,3478.121094 1025.189819,3475.158447 1021.502502,3473.092285 
	C1020.133423,3472.325439 1018.397095,3472.218750 1016.832581,3471.795166 
	C1014.884705,3471.268311 1012.452515,3470.426025 1013.170776,3468.149414 
	C1014.052795,3465.354248 1016.459961,3467.267090 1018.044556,3467.822266 
	C1026.173584,3470.671387 1032.785522,3466.444092 1039.831055,3462.691406 
	C1040.859375,3461.954346 1041.726562,3461.937744 1043.244019,3461.919922 
	C1047.029053,3459.186768 1050.867798,3460.268066 1055.238037,3459.943848 
	C1058.110107,3459.896729 1060.195923,3459.875000 1063.063599,3459.837891 
	C1070.374512,3459.520996 1077.026611,3458.463135 1083.423096,3463.429688 
	C1084.678101,3466.171387 1085.292603,3468.390625 1085.981567,3471.301270 
	C1085.993774,3474.221191 1085.982910,3476.449219 1086.072998,3479.345703 
	C1085.785889,3483.027100 1087.017334,3485.735352 1087.852051,3489.260742 
	C1086.671631,3491.302979 1085.499023,3492.655273 1085.930176,3495.335205 
	C1086.078735,3497.470947 1086.103760,3498.946533 1086.152344,3501.160156 
	C1087.075562,3503.498535 1085.870728,3505.464600 1087.514648,3507.507324 
	C1087.939453,3511.417480 1087.913696,3514.835205 1087.855347,3519.105957 
	C1086.363037,3527.664307 1082.907837,3534.959961 1083.580566,3543.477539 
	C1086.874268,3545.607422 1088.911011,3547.583984 1086.605713,3551.596436 
z"
        />
        <path
          fill="#797771"
          opacity="1.000000"
          stroke="none"
          d="
M1131.879517,3253.455566 
	C1124.058838,3241.088135 1120.921021,3226.953613 1114.915894,3213.991455 
	C1112.821167,3209.470215 1111.093872,3204.780273 1109.087891,3200.216064 
	C1105.253174,3191.490967 1105.059326,3191.319824 1096.889160,3195.546631 
	C1093.510742,3197.293945 1089.070679,3198.106689 1087.824219,3203.553955 
	C1087.542114,3204.229492 1086.670898,3204.188232 1086.242798,3204.103516 
	C1085.167114,3191.443604 1081.434937,3179.433594 1078.612305,3166.567139 
	C1077.802979,3165.605957 1077.447998,3165.250977 1076.656250,3164.280029 
	C1075.594482,3162.266357 1075.384766,3160.784668 1075.333252,3158.532471 
	C1078.708008,3149.474365 1080.973633,3148.957764 1086.324951,3155.609375 
	C1090.717529,3161.069580 1093.702881,3167.478027 1097.644409,3173.239990 
	C1099.581055,3176.071045 1100.401367,3180.348389 1105.829346,3180.466553 
	C1111.571899,3181.880615 1113.540405,3186.174072 1116.193970,3190.018311 
	C1126.372803,3204.762939 1135.309570,3220.318848 1145.723022,3234.908203 
	C1148.475220,3238.763916 1151.680298,3241.988770 1156.400146,3244.739746 
	C1158.316284,3246.751465 1159.202271,3248.544678 1160.204834,3251.125000 
	C1160.809937,3253.428711 1160.904907,3255.064453 1161.255737,3256.656494 
	C1161.949829,3259.805664 1161.356689,3262.571777 1158.468140,3264.321289 
	C1155.472656,3266.135254 1152.714478,3264.812744 1150.360352,3262.946777 
	C1144.986694,3258.687744 1139.804810,3254.158691 1131.879517,3253.455566 
z"
        />
        <path
          fill="#1C2E3E"
          opacity="1.000000"
          stroke="none"
          d="
M1024.685059,3368.340820 
	C1017.935791,3367.997559 1011.875427,3367.979492 1004.906616,3367.962891 
	C1002.847412,3367.364990 1001.671448,3366.203369 1000.549683,3366.253906 
	C990.034546,3366.723633 979.548767,3366.736084 969.010925,3366.428223 
	C959.466675,3366.148926 949.928955,3366.053467 940.420898,3365.117188 
	C938.784668,3364.956055 936.647278,3363.652344 935.644104,3364.291992 
	C928.351990,3368.941650 920.081116,3364.920898 912.466797,3367.328125 
	C905.193787,3369.628174 898.036804,3366.873779 891.138794,3364.370605 
	C889.535645,3363.788574 888.260376,3362.492676 888.640564,3360.564209 
	C889.029846,3358.590088 890.742126,3358.329102 892.449951,3358.065186 
	C896.937866,3357.371582 901.032837,3359.227051 905.133179,3360.321289 
	C909.739746,3361.551270 913.513306,3362.478516 915.723572,3356.911865 
	C916.540283,3354.855225 918.635010,3353.583984 920.580872,3354.217285 
	C926.715027,3356.213379 933.173645,3355.094482 939.110962,3354.895508 
	C949.842102,3354.536865 959.959351,3357.522217 970.419312,3357.906982 
	C974.620850,3358.061768 979.136536,3359.016113 982.726318,3355.449707 
	C984.019592,3354.165039 985.793274,3354.215088 987.416748,3354.831787 
	C992.265869,3356.674072 997.317139,3355.668213 1003.161194,3356.019043 
	C1009.197510,3359.034668 1014.810181,3360.028320 1020.684204,3360.002686 
	C1024.941284,3359.984131 1029.210938,3360.042969 1031.856445,3365.191162 
	C1030.399536,3368.274902 1028.097290,3368.980713 1024.685059,3368.340820 
z"
        />
        <path
          fill="#F5F1E5"
          opacity="1.000000"
          stroke="none"
          d="
M1088.459473,3216.948730 
	C1089.373047,3216.028076 1090.103149,3216.025879 1091.380859,3216.002930 
	C1093.593994,3221.964844 1094.546021,3228.111328 1096.110596,3234.984375 
	C1094.489502,3240.850098 1095.227417,3246.061035 1094.674194,3252.027832 
	C1093.995972,3254.530762 1093.241821,3256.102783 1092.969971,3258.624023 
	C1089.625000,3261.332520 1089.254395,3258.444092 1089.095459,3256.367188 
	C1088.694092,3251.123047 1088.623413,3245.850098 1088.529419,3240.586914 
	C1088.394287,3233.014404 1088.355957,3225.440430 1088.459473,3216.948730 
z"
        />
        <path
          fill="#F5F1E5"
          opacity="1.000000"
          stroke="none"
          d="
M1093.418945,3271.572998 
	C1093.901489,3285.124512 1093.827271,3298.236084 1093.471558,3312.363281 
	C1090.383423,3312.685791 1091.213745,3310.334961 1091.147583,3308.561279 
	C1090.715698,3296.994629 1090.388550,3285.423828 1090.180176,3272.932129 
	C1091.029785,3271.294922 1091.880127,3271.024170 1093.418945,3271.572998 
z"
        />
        <path
          fill="#0C1A26"
          opacity="1.000000"
          stroke="none"
          d="
M1083.959473,3463.247803 
	C1077.805542,3462.811523 1071.547485,3461.671143 1064.567505,3460.176514 
	C1064.726929,3454.016846 1069.080444,3457.670410 1071.660767,3457.270752 
	C1076.512329,3456.520264 1081.509399,3456.851074 1083.959473,3463.247803 
z"
        />
        <path
          fill="#0C1A26"
          opacity="1.000000"
          stroke="none"
          d="
M1084.083618,3543.166016 
	C1080.581787,3543.580811 1078.955811,3540.899902 1080.509277,3538.466797 
	C1083.882202,3533.184082 1083.564331,3527.242188 1084.932129,3521.614990 
	C1085.053589,3521.115479 1085.884399,3520.788086 1087.104248,3520.169922 
	C1089.414185,3527.902588 1085.473999,3534.927979 1084.083618,3543.166016 
z"
        />
        <path
          fill="#0C1A26"
          opacity="1.000000"
          stroke="none"
          d="
M1061.337402,3360.115967 
	C1061.649170,3361.822266 1061.026733,3363.366455 1059.111938,3363.705322 
	C1054.594604,3364.504150 1050.063110,3365.221924 1044.707397,3365.943115 
	C1044.889526,3360.862793 1046.324829,3356.532227 1052.878418,3360.276611 
	C1055.467041,3361.755859 1058.036011,3359.738037 1061.337402,3360.115967 
z"
        />
        <path
          fill="#0C1A26"
          opacity="1.000000"
          stroke="none"
          d="
M1024.146851,3368.713135 
	C1026.095947,3366.989990 1028.177246,3365.903809 1031.319946,3365.958984 
	C1034.149170,3365.937012 1036.276489,3365.952148 1039.201904,3365.983643 
	C1036.105103,3370.705566 1029.723755,3372.091797 1024.146851,3368.713135 
z"
        />
        <path
          fill="#0C1A26"
          opacity="1.000000"
          stroke="none"
          d="
M1091.984619,3801.230469 
	C1089.065674,3801.929443 1085.739502,3802.166504 1084.864624,3798.581543 
	C1084.134033,3795.587891 1086.953247,3794.218994 1089.479126,3792.290771 
	C1092.823730,3794.341553 1091.790039,3797.542480 1091.984619,3801.230469 
z"
        />
        <path
          fill="#05101B"
          opacity="1.000000"
          stroke="none"
          d="
M1087.990479,3575.250488 
	C1087.108398,3576.013184 1086.221680,3576.006592 1084.669922,3576.000000 
	C1080.773560,3571.970215 1081.154175,3568.203125 1085.467529,3564.335938 
	C1086.732788,3567.491455 1087.359375,3570.986572 1087.990479,3575.250488 
z"
        />
        <path
          fill="#F4EEDC"
          opacity="1.000000"
          stroke="none"
          d="
M1093.234375,3259.412354 
	C1092.797241,3257.597168 1092.426025,3255.724609 1092.055054,3252.896484 
	C1093.575439,3250.525146 1095.189819,3249.283691 1098.142090,3249.116943 
	C1102.253052,3250.631592 1103.629272,3253.131104 1102.432861,3257.360840 
	C1099.345215,3258.385986 1096.668335,3254.979980 1093.781372,3258.765137 
	C1093.464600,3259.415771 1093.300415,3259.354736 1093.234375,3259.412354 
z"
        />
        <path
          fill="#0C1A26"
          opacity="1.000000"
          stroke="none"
          d="
M1055.490234,3459.401855 
	C1053.047119,3463.247314 1049.089111,3461.594482 1044.713867,3461.951172 
	C1046.165405,3455.743408 1050.759399,3458.038330 1055.490234,3459.401855 
z"
        />
        <path
          fill="#0C1A26"
          opacity="1.000000"
          stroke="none"
          d="
M1038.511719,3420.583008 
	C1041.347168,3417.318848 1044.620361,3416.692871 1047.869385,3421.165527 
	C1045.017944,3421.882568 1041.942017,3422.576904 1038.511719,3420.583008 
z"
        />
        <path
          fill="#0C1A26"
          opacity="1.000000"
          stroke="none"
          d="
M1086.583252,3479.563477 
	C1083.496704,3477.967529 1083.583618,3475.519775 1085.546143,3472.430420 
	C1088.448975,3474.075195 1088.395020,3476.503662 1086.583252,3479.563477 
z"
        />
        <path
          fill="#0C1A26"
          opacity="1.000000"
          stroke="none"
          d="
M1086.548584,3495.558594 
	C1084.604736,3495.677734 1082.741943,3495.655518 1082.899292,3493.550781 
	C1083.058594,3491.421875 1084.800659,3490.640625 1087.280518,3490.067871 
	C1087.979248,3491.757812 1088.144165,3493.548096 1086.548584,3495.558594 
z"
        />
        <path
          fill="#F4EEDC"
          opacity="1.000000"
          stroke="none"
          d="
M1093.985107,3271.258789 
	C1093.166504,3272.010986 1092.357178,3272.009033 1090.940552,3272.008301 
	C1090.161499,3270.503418 1089.989746,3268.997070 1089.973999,3266.742676 
	C1090.885620,3265.309570 1091.770508,3265.021973 1093.382446,3265.556152 
	C1093.985107,3267.488525 1093.989624,3268.996338 1093.985107,3271.258789 
z"
        />
        <path
          fill="#0C1A26"
          opacity="1.000000"
          stroke="none"
          d="
M1087.987671,3507.329102 
	C1084.420776,3507.642334 1084.028564,3505.800293 1085.898926,3502.460449 
	C1087.751221,3503.113281 1088.017578,3504.833008 1087.987671,3507.329102 
z"
        />
        <path
          fill="#0C1A26"
          opacity="1.000000"
          stroke="none"
          d="
M1092.480103,3753.697754 
	C1090.716309,3753.147461 1090.056152,3752.106689 1091.614746,3750.298584 
	C1093.350464,3750.872559 1093.924805,3751.930176 1092.480103,3753.697754 
z"
        />
        <path
          fill="#F5F1E5"
          opacity="1.000000"
          stroke="none"
          d="
M1093.887207,3264.882812 
	C1093.124878,3265.984863 1092.269409,3265.989014 1090.771973,3265.993896 
	C1089.493286,3264.018799 1089.114990,3262.056885 1091.089844,3260.037109 
	C1092.423340,3261.187012 1093.108521,3262.485840 1093.887207,3264.882812 
z"
        />
        <path
          fill="#7A91A4"
          opacity="1.000000"
          stroke="none"
          d="
M197.555054,2065.188965 
	C201.732635,2066.458740 205.278061,2067.312012 208.810013,2068.217529 
	C210.151184,2068.561279 211.326660,2069.243896 211.363495,2070.816650 
	C211.412460,2072.907227 209.924927,2073.796631 208.187073,2073.825684 
	C205.218124,2073.875244 202.223099,2073.759521 199.275406,2073.408203 
	C192.170105,2072.561279 191.784912,2073.016113 191.542282,2079.947754 
	C191.395294,2084.146484 189.863007,2088.395996 194.792938,2091.144043 
	C195.999069,2091.816406 195.929428,2093.944092 194.843933,2094.642334 
	C189.527527,2098.061279 187.843140,2105.701172 180.484268,2106.775879 
	C178.551529,2107.058105 176.497345,2108.435303 175.074219,2106.408936 
	C173.427048,2104.063721 173.090836,2100.487061 175.476913,2099.229492 
	C180.690933,2096.481445 180.125793,2091.859863 181.093719,2087.905029 
	C179.513870,2086.022705 177.822006,2086.099365 176.182526,2086.016602 
	C165.934509,2085.498291 165.934479,2085.499023 165.000336,2073.619873 
	C167.259171,2071.725342 169.368240,2074.002930 171.570648,2073.924561 
	C173.900360,2073.842041 176.644943,2074.302246 177.752655,2071.779053 
	C179.735184,2067.262939 184.384918,2065.431641 187.040787,2062.067871 
	C190.260834,2057.989502 191.794525,2058.558594 194.450394,2061.660889 
	C195.314041,2062.669678 196.097443,2063.747070 197.555054,2065.188965 
z"
        />
        <path
          fill="#7790A8"
          opacity="1.000000"
          stroke="none"
          d="
M385.191284,2066.007324 
	C386.001282,2067.514160 386.003784,2069.018555 386.013367,2071.276123 
	C385.937408,2074.770020 385.799255,2077.825195 382.176147,2077.819092 
	C375.444061,2077.807617 373.636627,2081.529297 373.909393,2087.414307 
	C374.007568,2089.531738 373.274109,2091.741699 370.571259,2090.986084 
	C367.028809,2089.995361 366.104736,2092.731445 364.261108,2094.485107 
	C358.975464,2099.512451 347.274078,2097.858398 343.336823,2091.620361 
	C343.030762,2091.135254 342.901703,2090.279053 343.114807,2089.774902 
	C343.726044,2088.328857 345.209167,2087.916504 346.367493,2088.554199 
	C350.402191,2090.775391 354.128418,2088.831055 357.900970,2087.939941 
	C362.690857,2086.808594 366.184570,2082.694824 365.316833,2078.796143 
	C363.965973,2072.727051 367.347504,2072.880859 370.943665,2070.957520 
	C375.292603,2068.631592 378.863678,2064.956299 385.191284,2066.007324 
z"
        />
        <path
          fill="#7A91A4"
          opacity="1.000000"
          stroke="none"
          d="
M80.279533,1984.852051 
	C83.125885,1986.117554 85.534485,1987.378662 84.909134,1990.534302 
	C84.813545,1991.016968 83.189964,1991.693848 82.532143,1991.480957 
	C79.773788,1990.587769 78.704170,1988.445435 79.030373,1985.610718 
	C79.060127,1985.352173 79.435249,1985.133301 80.279533,1984.852051 
z"
        />
        <path
          fill="#7790A8"
          opacity="1.000000"
          stroke="none"
          d="
M559.416504,1960.438232 
	C559.024170,1961.867310 558.654480,1963.068115 557.943176,1963.325928 
	C556.130737,1963.982422 553.722778,1964.552368 553.170349,1961.919067 
	C552.663025,1959.500366 554.747803,1958.543823 556.841675,1958.480835 
	C557.641785,1958.456909 558.468750,1959.321045 559.416504,1960.438232 
z"
        />
        <path
          fill="#7A91A4"
          opacity="1.000000"
          stroke="none"
          d="
M112.623474,2008.978271 
	C110.263344,2009.707275 107.858223,2010.799805 107.281364,2008.004639 
	C106.885246,2006.085449 109.053810,2005.342773 110.582748,2005.038696 
	C113.614693,2004.435547 113.821251,2006.251831 112.623474,2008.978271 
z"
        />
        <path
          fill="#748B9F"
          opacity="1.000000"
          stroke="none"
          d="
M344.666748,2153.962402 
	C343.944061,2153.064697 343.977112,2152.183594 344.023285,2150.640625 
	C346.166901,2149.977051 348.297394,2149.975830 351.228729,2149.977539 
	C351.530396,2154.127441 348.444153,2154.000000 344.666748,2153.962402 
z"
        />
        <path
          fill="#7790A8"
          opacity="1.000000"
          stroke="none"
          d="
M595.438232,1970.477539 
	C598.145691,1972.642578 597.000061,1974.020142 594.974548,1974.974243 
	C594.062073,1975.404175 592.942688,1974.941284 592.632935,1973.935059 
	C592.054688,1972.057007 592.895752,1970.832397 595.438232,1970.477539 
z"
        />
        <path
          fill="#748B9F"
          opacity="1.000000"
          stroke="none"
          d="
M406.519714,2077.099121 
	C405.491150,2084.072266 401.279694,2081.882812 395.974731,2080.884521 
	C399.397095,2077.417236 402.405060,2076.335938 406.519714,2077.099121 
z"
        />
        <path
          fill="#748B9F"
          opacity="1.000000"
          stroke="none"
          d="
M514.193848,2083.481445 
	C512.495056,2084.005859 510.998688,2084.004883 508.754150,2084.005371 
	C508.003815,2083.117920 508.001648,2082.228760 507.997620,2080.673340 
	C510.130646,2080.004639 512.265564,2080.002441 515.200806,2080.001465 
	C515.466187,2080.987305 514.931213,2081.971680 514.193848,2083.481445 
z"
        />
        <path
          fill="#7F98AE"
          opacity="1.000000"
          stroke="none"
          d="
M271.909302,1995.311279 
	C271.359039,1995.902832 270.665100,1995.930908 270.086731,1995.685913 
	C268.100433,1994.844604 265.448334,1994.754517 265.161346,1991.833130 
	C265.121277,1991.425293 265.821686,1990.624634 266.278931,1990.539307 
	C269.517273,1989.934570 271.013519,1991.842407 271.909302,1995.311279 
z"
        />
        <path
          fill="#7F98AE"
          opacity="1.000000"
          stroke="none"
          d="
M139.909760,2049.113770 
	C138.550293,2048.369873 138.534668,2047.620850 139.505142,2046.958740 
	C139.708374,2046.820312 140.178787,2046.825439 140.376846,2046.969482 
	C141.325287,2047.659180 141.288971,2048.407471 139.909760,2049.113770 
z"
        />
        <path
          fill="#7F98AE"
          opacity="1.000000"
          stroke="none"
          d="
M257.287567,1989.142090 
	C258.775848,1988.725220 259.315552,1989.245850 259.085175,1990.401489 
	C259.037354,1990.641602 258.696777,1990.964355 258.454132,1991.000488 
	C257.287354,1991.173950 256.797607,1990.606689 257.287567,1989.142090 
z"
        />
        <path
          fill="#748B9F"
          opacity="1.000000"
          stroke="none"
          d="
M257.408997,2152.267578 
	C266.882263,2147.867188 266.882263,2147.867188 275.576355,2151.887695 
	C269.203796,2155.287598 263.402130,2154.312988 257.408997,2152.267578 
z"
        />
        <path
          fill="#748B9F"
          opacity="1.000000"
          stroke="none"
          d="
M264.020081,2191.265869 
	C260.725861,2192.009766 257.455383,2192.009766 254.642960,2192.009766 
	C252.200592,2189.279541 258.184479,2185.992188 252.592407,2184.076416 
	C251.583511,2178.895752 252.855713,2175.525146 259.000885,2175.945312 
	C263.604736,2176.260010 267.771790,2175.639160 272.128082,2172.780029 
	C278.412842,2168.655518 285.572632,2171.614502 292.164001,2173.579590 
	C294.583069,2174.300781 296.662354,2176.161621 299.814880,2178.053711 
	C293.782135,2180.892090 288.473663,2180.608154 283.179230,2178.866943 
	C281.722687,2178.387939 280.005310,2176.014893 278.919800,2177.334229 
	C274.629700,2182.548096 265.191956,2181.909180 264.020081,2191.265869 
z"
        />
        <path
          fill="#748B9F"
          opacity="1.000000"
          stroke="none"
          d="
M298.106567,2191.220703 
	C292.609283,2191.955811 287.194794,2191.965576 280.877380,2191.952393 
	C279.772461,2186.626953 282.133240,2183.826416 287.631226,2183.997314 
	C289.946503,2184.069336 292.268158,2184.083984 294.582672,2184.002930 
	C300.088562,2183.810303 299.387329,2186.999756 298.106567,2191.220703 
z"
        />
        <path
          fill="#748B9F"
          opacity="1.000000"
          stroke="none"
          d="
M336.711426,2158.858643 
	C335.225677,2159.274414 334.685791,2158.755371 334.915863,2157.601318 
	C334.963593,2157.362061 335.304230,2157.040771 335.546753,2157.004395 
	C336.711609,2156.830566 337.201508,2157.395996 336.711426,2158.858643 
z"
        />
        <path
          fill="#7F98AE"
          opacity="1.000000"
          stroke="none"
          d="
M179.138870,2128.716797 
	C178.718887,2127.220947 179.245010,2126.682617 180.403473,2126.912842 
	C180.644516,2126.960938 180.969162,2127.302246 181.005386,2127.545654 
	C181.179688,2128.718018 180.608139,2129.207520 179.138870,2128.716797 
z"
        />
        <path
          fill="#748B9F"
          opacity="1.000000"
          stroke="none"
          d="
M473.447601,2128.282715 
	C473.997131,2129.495605 473.999176,2130.995117 474.001831,2133.244141 
	C469.173615,2133.092041 470.070770,2131.026611 473.447601,2128.282715 
z"
        />
        <path
          fill="#748B9F"
          opacity="1.000000"
          stroke="none"
          d="
M466.071594,2120.567383 
	C467.095825,2120.354248 468.192841,2120.708496 469.645447,2121.531494 
	C468.197876,2124.019531 466.954285,2123.544189 466.071594,2120.567383 
z"
        />
        <path
          fill="#748B9F"
          opacity="1.000000"
          stroke="none"
          d="
M388.763275,2111.996582 
	C385.071594,2112.280762 382.682220,2111.390381 380.534119,2108.405273 
	C380.161041,2107.372070 380.243225,2106.231689 380.499939,2106.190918 
	C388.038544,2104.994141 394.903717,2100.414062 403.842072,2101.419434 
	C400.221741,2107.406494 396.904785,2112.337891 388.763275,2111.996582 
z"
        />
        <path
          fill="#765242"
          opacity="1.000000"
          stroke="none"
          d="
M572.805786,3224.121826 
	C571.159363,3222.182617 569.339600,3221.913086 566.650024,3221.998047 
	C565.949036,3221.636719 565.960754,3221.261230 566.000366,3220.322754 
	C567.812317,3218.014893 567.354919,3216.277832 565.442871,3214.341797 
	C561.697632,3211.180420 562.108215,3207.228760 561.823242,3202.704346 
	C561.727905,3201.965820 562.263977,3201.968018 562.531006,3201.944336 
	C573.790588,3201.469971 584.387512,3206.080078 596.183044,3204.452637 
	C598.377991,3204.587402 599.646790,3205.170654 600.960022,3206.943848 
	C597.192749,3221.607666 590.109558,3226.552734 576.679810,3224.118164 
	C575.151917,3223.980225 574.296814,3224.010010 572.805786,3224.121826 
z"
        />
        <path
          fill="#091825"
          opacity="1.000000"
          stroke="none"
          d="
M549.852051,3227.236328 
	C549.127930,3228.067383 548.295044,3228.310791 546.828613,3228.716309 
	C546.356140,3227.440186 545.813477,3225.601074 547.858826,3225.117676 
	C548.830688,3224.887939 549.423340,3225.748047 549.852051,3227.236328 
z"
        />
        <path
          fill="#233444"
          opacity="1.000000"
          stroke="none"
          d="
M496.032410,3206.657227 
	C496.849213,3206.063477 497.772919,3206.041504 499.389618,3206.057617 
	C499.842041,3207.365967 499.681458,3208.803223 498.083344,3209.006104 
	C496.966827,3209.147949 496.445374,3208.203857 496.032410,3206.657227 
z"
        />
        <path
          fill="#626E74"
          opacity="1.000000"
          stroke="none"
          d="
M564.966797,3214.856689 
	C568.871765,3216.218994 569.201477,3217.025635 566.600342,3219.759033 
	C565.704773,3218.364258 565.381226,3216.968994 564.966797,3214.856689 
z"
        />
        <path
          fill="#10212F"
          opacity="1.000000"
          stroke="none"
          d="
M402.875061,3206.100586 
	C397.620880,3204.510986 393.395599,3204.861084 388.605804,3205.873291 
	C375.719879,3208.596191 362.682281,3212.118652 349.354828,3208.692139 
	C345.322815,3207.655762 341.582855,3211.276123 337.678558,3208.833008 
	C336.094360,3207.842041 333.275330,3208.391357 333.031097,3205.812012 
	C332.799286,3203.363281 334.969208,3202.623779 336.883850,3201.992432 
	C338.213257,3201.553711 339.233795,3200.812012 339.982544,3198.846680 
	C341.436768,3196.260986 343.425018,3195.927734 346.238373,3195.820557 
	C355.205750,3197.636719 363.412628,3197.020752 372.255981,3195.306152 
	C373.360809,3194.886719 373.821167,3194.763428 374.995148,3194.564697 
	C378.120941,3194.791260 380.482178,3194.596191 383.521515,3193.580322 
	C393.867493,3194.080322 403.021851,3189.396729 413.348297,3191.506348 
	C418.637909,3192.104736 423.257294,3192.213135 426.975952,3188.625732 
	C427.654480,3187.970703 428.680450,3187.624512 429.599792,3187.279297 
	C433.347900,3185.872314 438.104950,3184.294189 440.466217,3187.734375 
	C442.398560,3190.549561 439.948151,3194.569092 436.400879,3197.514648 
	C435.117859,3198.083984 434.250000,3198.091064 432.731079,3198.110352 
	C430.623169,3199.591064 428.732239,3200.334473 426.423035,3201.554199 
	C422.459259,3202.582520 418.993347,3203.267334 414.764893,3203.980957 
	C412.805939,3204.033936 411.722260,3204.381592 410.344666,3205.520020 
	C407.824554,3205.956055 405.739258,3206.005127 402.875061,3206.100586 
z"
        />
        <path
          fill="#233444"
          opacity="1.000000"
          stroke="none"
          d="
M426.625916,3201.916016 
	C426.954224,3199.881592 428.416016,3198.425781 431.432068,3198.132812 
	C431.707367,3200.904297 429.998840,3202.020264 426.625916,3201.916016 
z"
        />
        <path
          fill="#233444"
          opacity="1.000000"
          stroke="none"
          d="
M410.510956,3205.892090 
	C410.361847,3204.157959 410.474518,3201.989990 413.648682,3203.587646 
	C413.038910,3204.632324 412.075500,3205.254639 410.510956,3205.892090 
z"
        />
        <path
          fill="#243748"
          opacity="1.000000"
          stroke="none"
          d="
M474.010864,3305.988037 
	C473.140015,3300.752197 475.169098,3296.042725 477.648224,3290.692627 
	C472.666473,3290.331543 468.379181,3292.222168 463.795593,3289.762207 
	C458.110291,3291.015869 451.714874,3290.983154 453.926941,3299.839111 
	C454.632050,3305.906250 450.898315,3304.308594 447.806732,3305.152832 
	C444.906616,3305.945068 449.802643,3311.779785 444.953583,3313.177490 
	C442.964844,3313.750732 442.124847,3311.890869 440.499084,3310.370605 
	C438.031830,3300.947998 430.331848,3299.322266 422.649567,3296.560547 
	C422.124969,3296.125488 421.741241,3295.737061 421.548615,3295.542480 
	C422.505371,3294.226562 423.465668,3292.699463 424.839447,3292.060059 
	C429.026031,3290.110107 435.076538,3289.249756 428.539734,3282.915039 
	C428.180298,3282.566895 429.369476,3280.620605 429.807983,3278.734863 
	C438.353516,3277.738525 446.640472,3281.065674 456.107300,3280.071045 
	C461.233337,3279.592773 465.540863,3280.068604 469.726746,3280.650391 
	C473.686462,3281.200928 476.671448,3281.295410 478.705658,3277.122070 
	C479.991028,3274.485352 482.666718,3273.789795 485.516449,3274.543701 
	C488.939545,3275.449707 488.347290,3278.302002 488.738922,3280.902588 
	C489.804993,3287.981689 490.025635,3295.070801 490.072205,3302.806641 
	C490.463745,3303.867432 490.540710,3304.319092 490.300079,3305.380371 
	C488.492188,3306.002686 487.001831,3306.015381 484.766388,3306.038330 
	C481.153107,3307.790527 478.152313,3308.119141 474.514099,3306.437500 
	C474.022339,3305.980469 474.003082,3305.996338 474.010864,3305.988037 
z"
        />
        <path
          fill="#1B2B3A"
          opacity="1.000000"
          stroke="none"
          d="
M520.134827,3291.814453 
	C519.341003,3296.824707 516.605469,3298.377930 512.494873,3297.710938 
	C510.312286,3297.356934 508.190613,3296.627686 505.307617,3296.045654 
	C502.891846,3295.850586 501.699127,3294.920166 500.462280,3292.859375 
	C499.759460,3290.633301 499.787750,3289.119385 500.400391,3286.916504 
	C503.317047,3282.973389 505.391541,3279.975098 500.272614,3276.775391 
	C495.765991,3273.958496 495.845642,3269.986816 499.314392,3265.408203 
	C502.231659,3263.389648 505.056030,3263.175781 508.358154,3262.256348 
	C510.848267,3260.927246 514.052917,3262.368896 514.469727,3258.564941 
	C513.456360,3257.045166 512.951660,3255.921387 512.745850,3254.003906 
	C512.975159,3252.921387 513.177673,3252.515137 513.738281,3251.529785 
	C514.096313,3250.950928 514.163086,3250.931885 514.150024,3250.977539 
	C516.220642,3250.548340 518.304382,3250.073730 521.131348,3249.278076 
	C522.600220,3251.478516 523.064453,3254.115234 524.113708,3256.494141 
	C525.635254,3259.942871 530.974487,3262.579834 533.821655,3260.637207 
	C537.785217,3257.932617 541.523743,3256.480469 546.334106,3257.285889 
	C547.613098,3257.500000 549.594788,3256.068848 549.148254,3253.994141 
	C548.678955,3251.813232 546.874512,3252.007568 545.244385,3252.505859 
	C543.724243,3252.970459 542.247681,3253.827393 540.685730,3252.142090 
	C541.526306,3248.452393 543.926331,3245.613037 547.441345,3246.486328 
	C552.669434,3247.785645 557.031494,3244.123291 562.307495,3245.195312 
	C564.949585,3245.732178 569.890747,3244.631836 569.937622,3238.661133 
	C580.848755,3235.291016 591.663330,3232.319824 603.089844,3234.632324 
	C608.431091,3235.713379 612.413086,3238.077881 614.003601,3243.485352 
	C617.226440,3254.441406 618.620239,3265.491455 614.687866,3277.344238 
	C607.127197,3275.778076 607.615051,3269.726562 607.010559,3264.305664 
	C606.908569,3263.390869 607.583801,3262.415283 607.731079,3261.442139 
	C608.160767,3258.603271 607.600220,3256.054199 604.773865,3254.735840 
	C602.307678,3253.585205 600.021667,3254.632568 598.603821,3256.568848 
	C595.455566,3260.867920 590.254395,3261.733154 586.191895,3264.469238 
	C585.137268,3265.179688 583.546021,3266.089355 582.574097,3265.770508 
	C573.812012,3262.895752 569.031616,3267.887451 564.651123,3274.240234 
	C563.141785,3276.428955 560.779724,3277.633545 557.946899,3278.067383 
	C552.259094,3278.939209 547.312317,3281.893311 542.692932,3284.997070 
	C534.952393,3290.197754 535.134644,3290.458496 526.943604,3285.077881 
	C526.404114,3284.723633 525.749878,3284.543945 524.433899,3284.198730 
	C523.334412,3284.291992 522.950012,3284.470215 522.014648,3284.336426 
	C520.705750,3283.588867 520.670837,3281.791260 519.657959,3282.379150 
	C518.594849,3282.996094 519.522522,3281.730469 520.441528,3283.403320 
	C522.098572,3286.427002 521.405273,3288.817139 520.134827,3291.814453 
z"
        />
        <path
          fill="#A9ACA9"
          opacity="1.000000"
          stroke="none"
          d="
M501.578674,3291.929688 
	C502.600677,3293.235596 503.328461,3294.116699 504.315033,3295.510254 
	C503.847778,3296.846191 503.121704,3297.668945 502.073730,3298.938965 
	C499.457947,3303.571533 495.236572,3303.404297 490.609863,3303.876953 
	C487.675629,3303.143555 485.481445,3302.525635 482.842987,3301.782959 
	C483.380463,3298.457764 488.112701,3298.528564 487.851807,3295.231445 
	C487.621460,3292.320068 488.300629,3289.056641 486.242493,3286.671875 
	C483.157440,3283.096924 486.609680,3279.947998 486.910767,3276.267578 
	C482.449188,3275.975586 479.614502,3276.139893 479.520142,3281.672363 
	C479.444061,3286.133301 476.456055,3287.590088 473.327423,3284.703613 
	C470.596008,3282.183350 468.926697,3281.679199 465.880219,3283.577881 
	C462.567688,3285.642578 460.426575,3282.918213 457.579956,3280.534668 
	C457.190002,3279.180664 457.435242,3278.289551 457.901489,3276.738525 
	C458.153015,3275.658447 458.183533,3275.238037 458.808289,3273.696777 
	C459.988434,3271.940918 460.628021,3271.365234 461.851196,3270.468750 
	C463.520203,3269.418213 464.716614,3268.879150 466.602478,3268.298340 
	C469.439117,3267.987793 471.630920,3267.952881 474.460785,3268.363770 
	C475.893280,3268.841553 476.757141,3269.076172 477.562897,3269.023682 
	C478.378510,3268.970947 478.309021,3268.727051 477.390839,3268.878174 
	C477.323730,3268.889404 477.034302,3268.804199 476.018555,3268.477539 
	C475.394867,3267.762207 475.301453,3267.351807 475.638977,3266.480469 
	C481.196411,3261.780029 485.653503,3256.474121 493.150208,3256.198486 
	C494.657776,3256.143066 496.675537,3255.215332 497.507965,3254.025146 
	C501.714478,3248.011230 507.090057,3249.563965 513.340210,3251.093750 
	C514.142090,3251.483643 514.187866,3252.016602 514.158936,3253.348877 
	C510.624634,3259.036865 501.516541,3256.774170 500.195068,3265.386230 
	C497.167908,3273.406738 497.168121,3273.406250 503.070435,3276.220703 
	C510.375793,3279.703613 510.376312,3279.896240 502.548279,3285.967285 
	C501.714294,3286.506104 501.437744,3286.727783 500.804688,3287.334717 
	C500.336121,3288.047607 500.224121,3288.375244 500.173523,3289.216553 
	C500.515839,3290.362549 500.869202,3290.951904 501.578674,3291.929688 
z"
        />
        <path
          fill="#9DAAB4"
          opacity="1.000000"
          stroke="none"
          d="
M465.584290,3269.291748 
	C465.324432,3270.627197 464.664825,3271.213135 463.549438,3271.863281 
	C462.251648,3272.171631 461.501434,3272.585938 460.377930,3273.614746 
	C459.924957,3274.081543 459.998718,3274.012695 460.042664,3273.988037 
	C459.325287,3274.638916 458.430542,3274.934326 456.763123,3274.568359 
	C453.562225,3274.184326 450.002869,3278.086182 448.792267,3274.072998 
	C447.869415,3271.013916 451.910583,3270.027100 454.849243,3269.703125 
	C455.487823,3269.632568 456.130310,3269.596924 457.410461,3269.283691 
	C460.214600,3265.682617 462.694305,3267.033936 465.584290,3269.291748 
z"
        />
        <path
          fill="#243748"
          opacity="1.000000"
          stroke="none"
          d="
M466.801697,3269.887207 
	C463.983124,3268.649170 461.798157,3268.229980 458.737976,3268.910645 
	C462.626770,3263.502686 468.890198,3267.139648 475.174774,3266.025391 
	C476.069916,3266.019043 476.213013,3266.829102 476.210083,3267.240479 
	C476.541718,3268.011719 476.876312,3268.371582 476.554169,3269.065918 
	C473.138123,3269.511475 470.378754,3269.622314 466.801697,3269.887207 
z"
        />
        <path
          fill="#243748"
          opacity="1.000000"
          stroke="none"
          d="
M456.344360,3274.639648 
	C457.016083,3273.885010 457.925690,3273.531982 459.460968,3273.571289 
	C459.748566,3274.534424 459.410522,3275.105713 458.597504,3275.877686 
	C457.530151,3275.850098 457.016724,3275.504395 456.344360,3274.639648 
z"
        />
        <path
          fill="#423C3E"
          opacity="1.000000"
          stroke="none"
          d="
M613.791016,3278.571533 
	C614.030579,3267.401367 614.463623,3256.821045 611.774841,3246.418945 
	C609.947876,3239.351562 609.330933,3238.912598 602.040527,3237.889648 
	C595.561096,3236.980957 589.542969,3235.562500 583.055542,3239.354004 
	C580.409729,3240.900146 575.483826,3238.544189 570.777344,3237.936035 
	C570.281372,3236.298584 570.783630,3234.609619 572.633362,3234.401367 
	C579.601135,3233.618164 577.542969,3229.409668 576.093384,3224.684082 
	C578.371582,3221.303711 581.313904,3222.029541 584.261414,3222.454346 
	C591.911133,3223.556152 592.249939,3223.056885 589.383240,3215.134277 
	C592.760498,3212.976807 598.173950,3214.116455 599.865356,3208.232666 
	C607.359802,3202.773682 614.356628,3205.690918 622.037354,3209.222656 
	C625.428772,3219.265381 623.011658,3228.711670 622.961609,3238.138428 
	C622.918091,3246.336914 622.852173,3254.535889 623.140137,3263.618164 
	C624.021179,3269.731201 625.484131,3274.930420 623.540283,3281.052734 
	C621.138794,3284.336182 618.475830,3285.271973 614.494934,3284.924316 
	C613.637634,3284.745850 612.778198,3284.561035 612.443726,3284.275879 
	C611.611267,3282.094727 612.679260,3280.664062 613.791016,3278.571533 
z"
        />
        <path
          fill="#423C3E"
          opacity="1.000000"
          stroke="none"
          d="
M566.318726,3228.371826 
	C567.493286,3227.224365 569.096863,3226.591064 571.332764,3225.808838 
	C571.618774,3228.987793 570.373047,3230.861084 566.318726,3228.371826 
z"
        />
        <path
          fill="#A9ACA9"
          opacity="1.000000"
          stroke="none"
          d="
M315.550903,3329.543457 
	C314.559662,3328.811523 314.362152,3328.043701 314.225189,3326.685059 
	C314.868439,3326.040771 315.358582,3325.799316 315.943390,3324.997314 
	C317.181702,3322.126953 319.662537,3321.562744 322.444946,3320.274902 
	C323.138489,3320.081543 323.466705,3320.415283 323.658997,3320.281738 
	C324.314087,3319.634277 324.807098,3319.150879 325.733337,3318.372070 
	C326.136292,3318.046143 326.000854,3318.029297 326.007751,3318.097168 
	C327.360596,3316.524902 329.325989,3317.232422 331.561920,3316.477539 
	C332.120422,3316.106201 332.044861,3316.351074 332.132629,3316.446533 
	C333.524841,3316.384277 334.831665,3316.243896 336.133301,3316.065674 
	C344.025452,3314.985596 350.733337,3316.346436 354.004242,3324.884766 
	C342.024475,3330.578857 329.641815,3333.647705 315.550903,3329.543457 
z"
        />
        <path
          fill="#243748"
          opacity="1.000000"
          stroke="none"
          d="
M322.301025,3320.068115 
	C321.615204,3321.958252 319.642670,3323.201904 316.786011,3324.373779 
	C316.117554,3321.011719 318.284729,3319.942871 322.301025,3320.068115 
z"
        />
        <path
          fill="#243748"
          opacity="1.000000"
          stroke="none"
          d="
M331.426239,3316.064453 
	C331.257019,3318.291992 329.208069,3318.223633 326.702332,3318.379639 
	C327.048370,3316.264648 328.820740,3315.990723 331.426239,3316.064453 
z"
        />
        <path
          fill="#243748"
          opacity="1.000000"
          stroke="none"
          d="
M325.653259,3318.270996 
	C325.761169,3318.653564 325.386047,3319.260986 324.431091,3320.008301 
	C324.290924,3319.597656 324.730591,3319.046875 325.653259,3318.270996 
z"
        />
        <path
          fill="#69849B"
          opacity="1.000000"
          stroke="none"
          d="
M2113.406250,2291.436523 
	C2115.247314,2298.871582 2120.578613,2299.716309 2126.037354,2299.937500 
	C2132.005859,2300.179443 2137.991455,2299.997803 2143.969727,2300.000244 
	C2145.962402,2300.001221 2148.031250,2299.649658 2149.935547,2300.060303 
	C2161.182617,2302.485107 2172.193115,2301.675049 2183.110596,2298.235107 
	C2184.297363,2297.861084 2185.754639,2298.144531 2187.045166,2298.365479 
	C2188.001953,2298.529541 2189.074463,2299.109131 2188.855469,2300.299561 
	C2188.694336,2301.174561 2188.052490,2302.636230 2187.546387,2302.670410 
	C2181.197754,2303.098877 2176.143555,2307.702393 2169.765381,2308.005127 
	C2156.882080,2308.616211 2143.963379,2308.452881 2131.080811,2308.985107 
	C2121.110107,2309.397217 2110.659912,2308.358398 2100.422119,2308.066895 
	C2097.607910,2307.986572 2094.290039,2307.045410 2092.209229,2311.240967 
	C2090.202881,2313.605225 2088.184326,2314.772705 2085.713867,2314.527588 
	C2058.526855,2311.832275 2031.384521,2313.996582 2004.283447,2315.725098 
	C1992.679810,2316.464844 1982.185913,2311.822266 1970.129395,2311.012695 
	C1958.704102,2310.786133 1948.054688,2310.153809 1938.091309,2317.381836 
	C1931.254395,2319.859619 1925.229370,2320.346680 1918.657715,2316.648438 
	C1918.195801,2311.827637 1916.330811,2309.867676 1912.071167,2309.977539 
	C1906.751099,2310.114746 1901.424316,2310.016602 1896.100708,2309.984131 
	C1891.829590,2309.958008 1887.541016,2310.540283 1884.479248,2306.116699 
	C1882.573975,2303.363770 1879.039185,2303.512695 1876.168701,2304.417480 
	C1868.440918,2306.853516 1860.697754,2306.768066 1852.709473,2306.157471 
	C1845.484741,2305.604980 1838.186035,2305.587891 1830.942627,2307.625000 
	C1826.898315,2308.762207 1822.324951,2308.635986 1818.418945,2305.986572 
	C1817.237671,2305.185303 1816.424683,2304.136230 1816.613281,2302.727783 
	C1816.805298,2301.292480 1817.870850,2300.174316 1819.301636,2300.295654 
	C1824.866089,2300.767334 1829.949951,2297.741699 1835.946045,2299.309570 
	C1842.485107,2301.020020 1849.612305,2300.630371 1857.987427,2298.947998 
	C1853.938599,2294.765137 1849.955322,2294.255371 1845.830078,2293.976807 
	C1844.258789,2293.870850 1841.174683,2293.806152 1841.659912,2292.497803 
	C1845.178711,2283.007812 1836.448120,2287.378906 1833.668213,2285.164062 
	C1832.484375,2284.220703 1832.303589,2282.971191 1832.070068,2280.900635 
	C1839.550293,2275.120850 1847.602295,2279.176758 1855.159424,2279.516602 
	C1866.448608,2280.024414 1877.744019,2280.745605 1889.032715,2280.466309 
	C1914.938599,2279.825439 1940.844360,2282.960938 1966.703613,2280.859375 
	C1981.792480,2279.633301 1996.751343,2276.834961 2011.726929,2274.427246 
	C1999.302246,2276.328613 1986.880737,2278.260010 1974.428833,2279.955811 
	C1970.805908,2280.449463 1967.213623,2281.369141 1963.541138,2280.951416 
	C1926.119141,2276.698486 1888.542725,2280.819824 1851.102173,2278.818848 
	C1838.301758,2278.134766 1825.469727,2278.299561 1812.690186,2277.077393 
	C1800.387085,2275.900879 1788.326782,2280.359375 1775.156982,2279.023926 
	C1770.520630,2279.487061 1767.412964,2282.446777 1762.564209,2281.989502 
	C1754.370239,2278.306396 1751.477783,2272.108643 1751.915771,2263.635742 
	C1756.149536,2260.635742 1760.113647,2261.364746 1764.038330,2262.190430 
	C1788.908569,2267.423340 1814.178101,2262.850586 1832.030884,2265.404053 
	C1809.959839,2266.369629 1781.195923,2259.390381 1751.247314,2260.419434 
	C1750.043701,2260.081543 1749.583740,2259.893555 1748.529907,2259.273926 
	C1742.205078,2246.512207 1749.802856,2238.489746 1757.173828,2230.443604 
	C1765.832031,2220.992432 1761.355225,2211.485840 1756.979980,2201.401611 
	C1754.834839,2198.474609 1753.330200,2195.987793 1751.828003,2192.652344 
	C1751.048706,2190.048340 1750.680298,2188.057373 1749.956421,2186.239746 
	C1747.584229,2180.283447 1751.203003,2172.040039 1741.923584,2168.490479 
	C1738.408936,2167.145752 1739.713623,2160.477051 1736.587769,2156.321289 
	C1735.395264,2147.328857 1729.581299,2140.140625 1731.183838,2130.735352 
	C1735.113525,2127.548340 1737.754761,2129.750488 1740.580200,2131.619385 
	C1744.745361,2134.374268 1749.078857,2136.853027 1753.610962,2138.983887 
	C1760.255127,2142.107910 1765.769287,2143.906738 1772.160156,2136.784180 
	C1779.110107,2129.038818 1807.838745,2131.814209 1815.361328,2139.618652 
	C1817.429810,2141.764648 1819.883789,2144.065430 1818.377563,2147.427734 
	C1816.855591,2150.824951 1813.390747,2152.072266 1810.120605,2152.253418 
	C1796.860107,2152.987305 1783.634155,2154.651123 1770.324707,2154.170166 
	C1766.710327,2154.039307 1763.116455,2152.702148 1758.190063,2154.740479 
	C1764.936279,2156.188477 1771.852905,2154.831299 1773.908081,2163.447266 
	C1774.730469,2166.894775 1779.684326,2163.184326 1782.687012,2162.580078 
	C1791.725708,2160.761475 1794.169312,2163.302979 1791.500732,2172.540771 
	C1803.830444,2175.193848 1816.283081,2174.971436 1828.740845,2174.859131 
	C1833.328003,2174.817871 1837.011597,2172.928955 1840.629639,2170.351807 
	C1846.363647,2166.267578 1852.475952,2164.158691 1859.752930,2168.720703 
	C1861.893311,2172.728271 1864.043823,2175.101318 1868.509888,2174.968018 
	C1888.930420,2174.358887 1909.401978,2177.397949 1929.809204,2173.605713 
	C1935.194336,2172.604736 1936.581665,2170.407715 1935.335571,2165.594482 
	C1933.792236,2159.632324 1935.524536,2155.804932 1942.876221,2155.152344 
	C1944.595703,2155.112793 1945.584595,2155.103271 1947.330933,2155.084961 
	C1950.120361,2154.997559 1952.151123,2154.764648 1954.942627,2155.058105 
	C1957.189819,2155.155273 1958.684082,2155.170410 1960.843384,2155.674316 
	C1966.315308,2158.179688 1966.416748,2161.687744 1963.728394,2165.559082 
	C1960.988159,2169.505127 1964.194824,2169.825439 1967.307373,2170.802734 
	C1970.026001,2170.966797 1971.958740,2171.427734 1974.560059,2172.393555 
	C1980.793579,2176.894043 1979.885742,2180.225098 1973.864868,2182.746338 
	C1971.713989,2183.646973 1969.359131,2184.423340 1967.079834,2184.225342 
	C1942.047485,2182.050049 1917.908691,2192.541260 1892.799561,2189.419678 
	C1889.989380,2189.070312 1886.861328,2188.897705 1884.049561,2190.914795 
	C1885.649414,2193.404541 1888.149536,2192.608154 1890.196655,2192.613037 
	C1918.754761,2192.679199 1947.311157,2193.516846 1975.873169,2192.760254 
	C1979.801758,2192.656494 1983.077881,2192.916260 1983.100220,2187.486816 
	C1983.108887,2185.413574 1985.379395,2183.950195 1987.352783,2183.813721 
	C1998.317627,2183.055176 2009.878540,2178.923828 2019.171143,2188.891113 
	C2019.831177,2189.599365 2020.995239,2189.575439 2022.754517,2189.324707 
	C2024.511597,2188.958740 2025.523438,2188.750732 2027.319580,2188.422363 
	C2028.627563,2188.257324 2029.154663,2188.229492 2030.468628,2188.267578 
	C2038.503052,2191.338867 2046.405151,2190.208984 2054.514404,2192.214600 
	C2058.879639,2197.465332 2059.716797,2205.473633 2069.220459,2204.947266 
	C2073.095947,2204.559326 2076.239014,2204.716797 2080.229248,2204.939941 
	C2087.349854,2204.019775 2093.657959,2204.662109 2099.946045,2204.457764 
	C2107.428223,2204.214355 2114.888916,2205.483398 2122.232910,2201.804199 
	C2126.162598,2199.835449 2131.445801,2200.073242 2135.940674,2201.406738 
	C2151.069092,2205.895508 2166.583496,2206.602783 2183.081299,2206.829102 
	C2196.527588,2207.308594 2209.063232,2206.994385 2222.360840,2206.306641 
	C2227.311279,2210.128418 2224.471924,2216.666504 2229.827148,2220.379639 
	C2232.996582,2222.577393 2228.914551,2226.420654 2226.103760,2226.193115 
	C2216.646729,2225.427246 2205.553955,2233.207031 2198.009277,2221.048340 
	C2196.606201,2218.787354 2193.115479,2219.416992 2190.473389,2219.317871 
	C2169.258057,2218.519287 2147.937256,2220.166992 2126.850830,2216.235352 
	C2122.008545,2215.332275 2117.811768,2218.183838 2113.823975,2220.563965 
	C2108.926025,2223.487305 2104.150635,2224.852539 2098.836670,2221.625977 
	C2095.375000,2219.524170 2091.851807,2219.395264 2088.798584,2222.499023 
	C2084.279785,2227.093018 2078.681885,2227.632324 2073.024414,2226.027832 
	C2062.079590,2222.923828 2049.490234,2225.643066 2040.171753,2216.404297 
	C2038.240356,2214.489746 2034.409546,2215.283936 2031.410278,2215.272461 
	C2024.040161,2215.243652 2016.807861,2214.065918 2009.648193,2212.490479 
	C2006.237671,2211.739990 2002.842407,2209.970947 2003.315918,2205.977783 
	C2003.859131,2201.397461 2007.994263,2201.753906 2011.214355,2202.337891 
	C2017.525879,2203.482910 2023.847290,2202.670654 2030.151367,2203.046387 
	C2005.787354,2202.484619 1981.701294,2204.195312 1957.864990,2209.910645 
	C1946.935425,2212.531494 1936.227173,2207.314941 1925.406006,2205.897949 
	C1915.729980,2204.631592 1906.299805,2203.563232 1895.807983,2205.834961 
	C1898.728882,2208.702393 1903.601074,2211.268066 1900.094116,2215.818115 
	C1897.277222,2219.473145 1892.974609,2218.979492 1889.076660,2217.394775 
	C1882.341309,2214.655762 1875.745728,2214.855469 1868.600952,2216.225098 
	C1861.764160,2217.535889 1853.650391,2219.642578 1849.630737,2210.329102 
	C1849.131592,2209.172607 1848.457275,2207.733887 1846.706055,2208.543457 
	C1845.791382,2208.966309 1845.189697,2209.855957 1844.824463,2210.773682 
	C1844.072876,2212.660889 1844.835205,2213.997559 1846.642334,2214.695801 
	C1854.604492,2217.773193 1862.835815,2217.937012 1871.050537,2216.302734 
	C1875.696899,2215.378174 1880.181396,2214.552979 1884.812378,2216.106201 
	C1887.798462,2217.107666 1890.669556,2218.505127 1890.868652,2221.955322 
	C1891.447388,2231.990479 1895.597778,2227.232178 1898.646606,2223.708008 
	C1903.435669,2218.172607 1908.016357,2216.659424 1913.757446,2222.298584 
	C1916.609619,2225.099854 1920.574951,2224.598633 1924.205933,2224.682373 
	C1936.499146,2224.965820 1948.815308,2224.035645 1961.021729,2225.920898 
	C1962.025269,2222.525391 1959.144653,2221.589111 1958.158813,2219.701416 
	C1955.775146,2215.135742 1957.748413,2212.733643 1962.053345,2211.598389 
	C1967.029785,2210.286133 1972.020630,2210.807129 1976.816406,2212.465332 
	C1984.312256,2215.057617 1991.802002,2215.296143 1999.392822,2213.059814 
	C2000.351440,2212.777100 2001.326050,2212.538086 2002.315186,2212.401123 
	C2007.171753,2211.728027 2011.459473,2213.151611 2012.531372,2218.115234 
	C2013.885986,2224.386963 2008.477417,2224.171875 2004.491211,2225.412109 
	C2037.401245,2219.572021 2070.077148,2228.643066 2102.968262,2226.771973 
	C2109.618408,2226.393311 2115.800049,2226.393066 2121.654785,2221.682129 
	C2128.380859,2216.269775 2137.130371,2218.919434 2144.956299,2219.241943 
	C2152.916016,2219.570312 2160.794434,2220.939941 2168.839355,2219.903809 
	C2172.493164,2219.433105 2176.843750,2219.829102 2177.132568,2224.796875 
	C2177.416016,2229.675293 2173.188232,2230.551270 2169.500488,2230.882568 
	C2161.197754,2231.628174 2152.862793,2230.644043 2147.283447,2230.925293 
	C2158.722168,2231.049072 2172.854980,2231.315674 2187.085449,2230.604980 
	C2201.403320,2229.890137 2215.371338,2226.565186 2229.770752,2226.697998 
	C2240.772705,2226.799805 2251.706299,2227.593994 2262.669189,2228.058105 
	C2267.024170,2228.242432 2272.130371,2228.775635 2272.965576,2234.035400 
	C2273.951172,2240.240967 2268.436768,2240.522461 2264.261963,2241.083984 
	C2260.344238,2241.610840 2258.197510,2243.400879 2257.494141,2248.073242 
	C2247.845947,2254.629395 2238.229248,2250.798096 2227.860352,2249.340332 
	C2215.967285,2244.294922 2204.527344,2243.716797 2192.870850,2246.228027 
	C2183.811279,2248.179688 2174.947266,2246.099121 2166.094727,2244.918945 
	C2145.358887,2242.153809 2124.513428,2242.359131 2103.679932,2241.881104 
	C2077.551270,2241.281250 2051.311035,2244.101562 2025.312134,2239.453857 
	C2020.783325,2238.644531 2016.316162,2239.229004 2011.826050,2239.376221 
	C2025.457764,2239.139893 2038.914795,2241.590576 2052.484131,2242.025635 
	C2070.655518,2242.608887 2088.927246,2240.303223 2106.953369,2241.772461 
	C2129.204346,2243.586426 2152.277344,2238.682617 2174.289062,2249.400879 
	C2176.050293,2251.325684 2176.661133,2252.875000 2176.502197,2255.424072 
	C2173.463135,2258.765625 2169.995850,2258.250244 2166.812256,2257.698975 
	C2146.170898,2254.124268 2125.353516,2256.020508 2104.617676,2255.555420 
	C2100.064697,2255.453369 2096.066895,2259.381348 2092.167725,2258.068115 
	C2102.012939,2255.799316 2113.031738,2257.840332 2124.050049,2256.031738 
	C2139.753906,2253.453613 2155.571289,2257.239258 2171.341553,2258.225098 
	C2176.154297,2258.526123 2177.818848,2262.266846 2178.506348,2267.263916 
	C2180.537842,2273.890381 2185.628906,2272.630371 2190.002441,2273.010742 
	C2196.742432,2273.596924 2203.778076,2271.476807 2210.680664,2275.812988 
	C2212.190918,2278.531738 2212.131592,2280.641357 2210.575195,2283.367676 
	C2203.549072,2284.152100 2197.337891,2283.725342 2191.044678,2287.248047 
	C2186.797852,2289.625244 2180.778320,2288.037354 2175.515137,2287.573486 
	C2170.146973,2287.100342 2164.735596,2285.579590 2158.682617,2287.691895 
	C2149.906738,2290.232178 2141.515625,2289.503174 2133.438721,2288.717773 
	C2114.041504,2286.831543 2094.937012,2290.591553 2075.694336,2290.551514 
	C2036.138306,2290.469727 1996.832764,2299.014648 1957.151611,2293.893311 
	C1949.510742,2292.907227 1941.932495,2296.120117 1933.502930,2294.103027 
	C1917.616455,2291.880615 1902.850220,2298.189209 1888.233154,2296.584229 
	C1890.184448,2298.387695 1892.797729,2300.793945 1896.137573,2302.050537 
	C1898.601807,2302.977783 1902.674072,2304.204102 1903.587769,2302.104492 
	C1907.545166,2293.011963 1912.885986,2296.173096 1918.763916,2299.353271 
	C1924.550903,2302.484131 1927.893555,2297.484131 1932.664062,2294.714111 
	C1941.921387,2295.680176 1950.589600,2293.675049 1959.865723,2295.723389 
	C1965.632324,2302.285156 1971.372314,2299.336914 1977.864258,2297.083740 
	C2000.301514,2294.749756 2021.770752,2298.326172 2044.203125,2298.709717 
	C2052.032471,2298.863037 2059.033203,2299.935303 2066.714844,2297.650391 
	C2073.088379,2296.329834 2078.702637,2296.727295 2085.102051,2296.693848 
	C2089.985840,2297.804932 2092.874268,2295.878906 2096.062744,2292.544678 
	C2101.979492,2290.064941 2107.218994,2288.106445 2113.406250,2291.436523 
z"
        />
        <path
          fill="#69849B"
          opacity="1.000000"
          stroke="none"
          d="
M2519.929199,2299.225586 
	C2501.380127,2302.526611 2482.614014,2301.902832 2462.937012,2301.990723 
	C2452.226318,2298.581299 2442.363281,2296.963135 2431.944824,2298.930176 
	C2420.515625,2301.087891 2409.224121,2301.363525 2397.666016,2297.883301 
	C2387.593750,2294.850342 2376.897705,2293.882324 2366.470459,2292.040771 
	C2365.166016,2291.810059 2363.517334,2292.278320 2362.542480,2291.638672 
	C2357.231445,2288.154053 2353.458252,2288.433105 2347.848877,2292.783447 
	C2343.291260,2296.318359 2335.575684,2294.560547 2329.401855,2293.289062 
	C2325.694336,2292.525391 2322.427734,2292.588623 2318.704346,2293.272949 
	C2308.097900,2295.223389 2297.442871,2295.297852 2286.565918,2294.420654 
	C2272.998047,2293.326660 2259.271729,2294.177490 2245.614258,2294.246338 
	C2244.679199,2294.250977 2243.661377,2294.634766 2243.535645,2296.786133 
	C2250.921631,2298.297363 2258.362793,2300.119629 2266.159180,2300.002441 
	C2270.494385,2299.937500 2274.571777,2300.098633 2278.528809,2302.793701 
	C2281.720459,2304.967285 2285.822510,2304.567871 2289.878906,2304.705078 
	C2305.981201,2305.249268 2322.090820,2303.150146 2338.214111,2305.456299 
	C2346.389893,2306.625488 2354.841064,2305.778076 2363.160400,2306.061279 
	C2369.019775,2306.260498 2371.032959,2308.949219 2369.813721,2314.215820 
	C2369.402832,2315.991699 2368.570312,2317.496582 2366.713135,2317.620117 
	C2362.149658,2317.923828 2356.838623,2319.710938 2353.437500,2315.733398 
	C2348.764893,2310.268311 2343.036377,2311.564209 2337.796631,2312.651367 
	C2332.397705,2313.771240 2327.377930,2314.443115 2322.161133,2312.457031 
	C2321.256348,2312.112549 2320.175781,2312.007812 2319.203613,2312.094727 
	C2305.183350,2313.345947 2291.389160,2310.302979 2277.460938,2309.677490 
	C2263.314697,2309.042725 2249.146729,2306.467285 2235.273682,2303.435791 
	C2230.063477,2302.297119 2226.849365,2296.420654 2224.080566,2290.711182 
	C2227.601807,2284.772705 2233.044678,2283.244385 2238.853271,2283.570557 
	C2246.353516,2283.991699 2253.220703,2280.875244 2260.538086,2280.433594 
	C2262.893066,2280.291260 2265.351074,2279.211182 2265.039551,2276.169678 
	C2264.776855,2273.608643 2262.320068,2273.679199 2260.426514,2273.264160 
	C2255.870850,2272.265869 2248.707520,2273.923340 2249.099121,2266.712646 
	C2249.468506,2259.908203 2256.440918,2261.611816 2261.099854,2260.837402 
	C2262.715820,2260.568604 2264.449463,2260.734619 2265.856689,2259.288818 
	C2265.226562,2256.114502 2261.619385,2256.781494 2259.485107,2254.336670 
	C2258.258057,2252.250488 2258.138184,2250.677734 2258.753906,2248.276367 
	C2266.537354,2240.188721 2275.568359,2236.435547 2285.963135,2236.718262 
	C2301.606934,2237.143311 2317.145264,2239.308594 2332.784424,2239.990479 
	C2336.367432,2240.146484 2339.593750,2241.437012 2339.203369,2245.880859 
	C2338.861816,2249.769043 2336.188232,2252.166016 2332.445557,2251.770020 
	C2320.420410,2250.498535 2308.588867,2253.942627 2296.670898,2253.577148 
	C2313.597412,2253.541504 2330.625488,2254.485840 2346.731201,2247.280273 
	C2348.259033,2246.596680 2349.766602,2245.893311 2351.428711,2245.646484 
	C2376.601318,2241.906982 2401.770508,2238.984131 2427.357910,2240.963867 
	C2444.510986,2242.290771 2461.337646,2241.842285 2478.744385,2238.236816 
	C2500.044922,2233.824951 2522.469971,2235.907959 2545.203613,2237.773926 
	C2546.658447,2238.008545 2547.337891,2237.899414 2547.971436,2238.024414 
	C2551.225586,2238.666016 2556.493408,2236.099121 2557.143555,2240.875488 
	C2557.689453,2244.886963 2551.843750,2244.750977 2549.179443,2246.971436 
	C2547.125732,2248.682617 2544.114014,2247.979492 2541.498291,2247.987061 
	C2531.623291,2248.015869 2521.748535,2247.998291 2510.936768,2247.994141 
	C2507.100098,2247.741699 2504.813477,2248.915039 2502.453613,2251.499512 
	C2488.716797,2249.411377 2475.306641,2250.018799 2461.930420,2250.026123 
	C2459.017578,2250.027588 2456.020752,2249.508545 2452.608887,2251.431641 
	C2444.145264,2257.652100 2435.276123,2255.403320 2426.901855,2254.579102 
	C2417.503662,2253.653809 2408.972412,2254.503662 2399.668457,2257.215576 
	C2379.930664,2262.968994 2360.486572,2270.546387 2339.602539,2271.753418 
	C2335.949951,2271.964600 2332.330566,2272.178711 2328.731201,2271.079102 
	C2319.083008,2268.130615 2309.294678,2269.348145 2299.516602,2270.309570 
	C2315.737549,2270.678223 2332.012451,2270.263428 2347.944824,2274.175537 
	C2349.233887,2274.492188 2351.028564,2274.847656 2351.851562,2274.125732 
	C2360.427002,2266.599609 2368.151367,2271.149170 2376.795898,2274.569336 
	C2384.034180,2277.433350 2392.400635,2277.060547 2401.070801,2277.968262 
	C2418.895264,2284.125244 2436.231445,2284.838379 2454.360840,2280.325928 
	C2461.479736,2273.280273 2469.485840,2270.554199 2478.538086,2271.439209 
	C2485.341309,2272.104492 2491.430420,2268.028320 2496.145996,2268.790039 
	C2485.365967,2266.422852 2471.714844,2266.783203 2460.111084,2270.474121 
	C2444.177002,2275.542725 2428.112061,2274.682373 2412.273682,2277.397217 
	C2407.067871,2278.289307 2403.051758,2277.488281 2399.861572,2273.461426 
	C2397.368164,2270.313965 2393.854492,2269.599609 2390.329102,2268.620117 
	C2387.741211,2267.900879 2384.845947,2266.849609 2385.376709,2263.453369 
	C2385.864746,2260.329346 2388.854492,2259.396484 2391.475342,2259.305176 
	C2397.140625,2259.107422 2402.673096,2257.580078 2408.251953,2257.432129 
	C2422.405518,2257.057129 2436.580078,2258.070068 2451.374023,2257.525391 
	C2465.100342,2255.630615 2478.454834,2254.663330 2491.484863,2256.426514 
	C2502.422119,2257.906494 2513.234863,2257.617432 2525.043457,2257.957031 
	C2528.414795,2255.546143 2531.533203,2256.113037 2535.248535,2256.003906 
	C2552.039307,2256.005371 2568.078613,2256.007324 2585.054199,2256.009277 
	C2586.202881,2260.160400 2584.346436,2262.083008 2580.079834,2262.020020 
	C2571.761230,2261.896973 2563.419922,2262.348145 2555.124268,2261.897217 
	C2547.377441,2261.475830 2539.968018,2263.366943 2532.395020,2264.075439 
	C2530.651855,2264.238525 2529.028809,2264.680908 2528.577637,2266.598389 
	C2528.155762,2268.389160 2528.986572,2269.999512 2530.451660,2270.870361 
	C2532.425293,2272.043701 2534.572510,2273.420898 2536.754395,2273.644043 
	C2541.065674,2274.084961 2545.242188,2275.083008 2549.484863,2275.752686 
	C2554.056641,2276.474365 2558.836426,2276.668213 2562.475098,2272.626465 
	C2563.836914,2271.113770 2565.797852,2269.668945 2567.719482,2270.189209 
	C2576.716064,2272.624023 2585.658691,2267.741455 2594.566406,2270.154053 
	C2608.504639,2273.928955 2622.730225,2274.519287 2637.020264,2273.945801 
	C2647.029541,2273.544189 2656.492188,2277.335449 2666.407227,2276.087646 
	C2666.516357,2280.463379 2664.007812,2279.968994 2662.235596,2279.926514 
	C2647.839844,2279.583008 2633.447266,2279.112305 2619.053467,2278.692627 
	C2617.754150,2278.654785 2616.030273,2278.165527 2615.218262,2278.800537 
	C2609.010010,2283.656738 2601.811523,2281.832520 2594.981445,2281.928711 
	C2578.816895,2282.156738 2562.662598,2282.615479 2546.505615,2283.050049 
	C2526.771484,2283.580811 2506.982422,2282.611084 2487.235840,2281.823975 
	C2481.337158,2281.589355 2475.544434,2279.376953 2469.492188,2280.037842 
	C2467.907715,2280.210693 2465.840576,2279.792725 2465.299072,2281.812744 
	C2464.609619,2284.385010 2466.734863,2285.195557 2468.618408,2285.846436 
	C2479.355713,2289.555420 2489.923828,2293.084473 2501.215088,2286.896973 
	C2506.416748,2284.046387 2510.718262,2285.976318 2514.701172,2291.286621 
	C2516.472656,2293.648438 2519.178223,2295.373047 2519.929199,2299.225586 
z"
        />
        <path
          fill="#50494B"
          opacity="1.000000"
          stroke="none"
          d="
M1752.017944,2263.298340 
	C1754.537842,2269.065674 1757.422729,2274.278320 1760.839600,2279.900635 
	C1763.310059,2281.020264 1764.370117,2282.588623 1765.577515,2284.955811 
	C1767.224731,2289.152344 1768.341797,2292.753418 1768.295532,2297.266602 
	C1773.678711,2311.071045 1779.249023,2324.195312 1785.366699,2337.762695 
	C1786.207153,2338.476318 1786.500122,2338.747070 1786.536133,2339.558594 
	C1786.257568,2340.938477 1786.235840,2341.778320 1786.195801,2343.247803 
	C1780.972290,2349.118652 1773.701294,2349.949707 1766.600464,2352.552246 
	C1764.913818,2351.413330 1764.030273,2350.208984 1764.242432,2348.881104 
	C1766.925171,2332.088135 1757.204468,2321.173584 1746.057617,2311.198975 
	C1741.261108,2306.906738 1737.151978,2302.545410 1734.512329,2296.503174 
	C1731.173340,2288.859863 1722.932007,2285.775635 1717.248779,2279.347168 
	C1719.348877,2271.958008 1722.632446,2265.414307 1729.284668,2262.598877 
	C1738.243896,2258.807861 1733.742065,2256.001221 1728.946411,2252.205078 
	C1727.817017,2251.174316 1727.309570,2250.488037 1726.796631,2249.124512 
	C1726.664307,2248.167725 1726.674683,2247.790283 1726.846802,2246.909668 
	C1727.576904,2245.922363 1727.734985,2245.330322 1727.121216,2244.277832 
	C1726.020020,2243.133545 1725.389404,2242.256104 1724.431396,2240.885742 
	C1723.479858,2239.339844 1723.041260,2238.215088 1722.818237,2236.391602 
	C1723.022583,2234.989746 1723.361694,2234.273682 1724.328247,2233.293457 
	C1725.445312,2232.644775 1726.135986,2232.435303 1727.345215,2232.320312 
	C1728.520752,2232.294434 1729.081055,2232.023193 1729.852051,2231.054932 
	C1731.708984,2229.069824 1733.496704,2228.434814 1736.225830,2229.335449 
	C1740.949707,2233.604980 1743.565796,2237.255127 1740.501343,2243.854736 
	C1737.978394,2249.288086 1745.729614,2252.113770 1747.339722,2257.643066 
	C1747.735474,2258.190918 1747.891357,2258.080811 1747.846191,2258.165039 
	C1749.415283,2259.658447 1751.840332,2260.241455 1752.017944,2263.298340 
z"
        />
        <path
          fill="#536F86"
          opacity="1.000000"
          stroke="none"
          d="
M1967.550049,2308.508789 
	C1973.029297,2306.201660 1978.421387,2306.265625 1982.411133,2309.086670 
	C1986.747925,2312.152832 1991.074097,2311.790039 1995.650269,2312.070312 
	C2014.644653,2313.234131 2033.421509,2309.526367 2052.425049,2309.750732 
	C2065.102295,2309.900635 2077.569092,2312.089111 2091.101074,2312.017578 
	C2092.110840,2316.045898 2090.721191,2318.515869 2086.140625,2317.623535 
	C2083.551758,2317.119629 2080.990967,2316.370117 2078.379883,2316.113281 
	C2076.110596,2315.890137 2073.365967,2315.720215 2072.408447,2318.473145 
	C2069.937500,2325.577637 2065.763428,2323.540771 2061.706787,2320.462646 
	C2057.643311,2317.379639 2054.812012,2317.763428 2051.845459,2322.054932 
	C2048.016113,2327.595215 2041.689697,2328.734619 2035.868042,2327.729004 
	C2027.567383,2326.295898 2019.619995,2326.778320 2011.353516,2327.732422 
	C2002.832153,2328.715576 1994.104004,2327.762207 1985.475464,2327.996582 
	C1972.837524,2328.339600 1960.494629,2326.630127 1948.011475,2324.710938 
	C1936.311401,2322.912354 1924.110107,2322.775146 1912.344482,2325.077148 
	C1904.818359,2326.549561 1898.124512,2325.523438 1889.558350,2322.108154 
	C1899.286377,2318.523438 1907.855591,2317.408203 1917.180420,2315.982666 
	C1924.070190,2316.001953 1930.127563,2316.033203 1937.093018,2316.066895 
	C1944.044800,2313.468018 1949.558594,2315.918701 1955.276245,2317.589844 
	C1959.068481,2318.698486 1963.162598,2317.867188 1967.118408,2318.017822 
	C1963.154785,2317.829590 1959.147095,2318.438232 1955.231079,2317.538574 
	C1953.307251,2317.096680 1951.169434,2316.422119 1951.326416,2313.866211 
	C1951.468018,2311.560791 1953.189453,2310.556641 1955.320801,2310.189697 
	C1959.192749,2309.523193 1963.278564,2310.874268 1967.550049,2308.508789 
z"
        />
        <path
          fill="#536F86"
          opacity="1.000000"
          stroke="none"
          d="
M1974.917603,2342.216309 
	C1986.941895,2342.597168 1998.094482,2343.121094 2008.989502,2340.008789 
	C2010.234375,2339.653320 2011.603149,2338.617920 2012.658691,2338.889893 
	C2020.558472,2340.926270 2028.587402,2339.545410 2036.532471,2340.059082 
	C2038.515381,2340.187256 2040.896362,2340.123291 2041.195190,2342.700928 
	C2041.510254,2345.416748 2039.198608,2346.011230 2037.196777,2345.873047 
	C2018.161133,2344.561523 1999.798950,2352.078857 1980.689575,2350.062744 
	C1975.102905,2349.473633 1969.383789,2350.471680 1963.879517,2348.469482 
	C1962.381470,2347.924805 1960.500488,2347.807373 1958.941284,2348.152344 
	C1942.785645,2351.731445 1926.420776,2349.315674 1910.169434,2349.984375 
	C1907.189941,2350.107178 1904.075562,2349.648438 1901.524170,2351.679688 
	C1898.376099,2354.185547 1894.706421,2353.986328 1891.038696,2353.984131 
	C1879.709106,2353.977539 1868.377930,2353.879150 1857.050293,2354.026367 
	C1847.961548,2354.144287 1839.509888,2350.603760 1830.566284,2349.762939 
	C1825.627319,2349.298584 1820.893921,2348.065186 1815.933228,2349.814209 
	C1814.149536,2350.443115 1811.967041,2350.027344 1809.972168,2349.948730 
	C1807.688354,2349.859131 1804.627197,2350.088379 1804.766724,2346.869873 
	C1804.902100,2343.748047 1807.979004,2344.096436 1810.247070,2344.039795 
	C1814.576538,2343.931641 1818.910034,2343.997803 1823.239502,2343.891602 
	C1825.885010,2343.826660 1827.813477,2342.728760 1827.657837,2339.716064 
	C1827.495605,2336.578369 1825.124023,2335.830322 1822.707031,2336.180420 
	C1817.794922,2336.891846 1812.914062,2337.896484 1808.076172,2339.020264 
	C1804.500488,2339.851318 1801.018311,2340.769531 1797.116943,2338.695557 
	C1798.888672,2333.104980 1801.275269,2330.204590 1808.213013,2331.676758 
	C1814.275024,2332.963135 1820.822144,2331.856445 1827.148804,2332.030029 
	C1834.515747,2332.232178 1836.723877,2333.865479 1836.966797,2341.352295 
	C1837.092041,2345.214844 1838.380005,2346.274170 1841.424072,2345.914062 
	C1846.379883,2345.327637 1851.001221,2348.053955 1856.202759,2346.503418 
	C1860.487427,2345.225830 1865.449463,2346.431152 1867.343994,2340.423584 
	C1868.076050,2338.101318 1871.171875,2337.640625 1873.484009,2338.367432 
	C1882.968384,2341.349121 1892.697754,2340.035400 1902.274536,2339.888184 
	C1926.239136,2339.520264 1950.056519,2343.242188 1974.917603,2342.216309 
z"
        />
        <path
          fill="#597288"
          opacity="1.000000"
          stroke="none"
          d="
M1929.591919,2292.683350 
	C1935.320557,2291.831543 1940.566040,2292.115479 1945.861328,2290.611084 
	C1949.581787,2289.554443 1953.926636,2289.499268 1957.747803,2290.247314 
	C1965.336426,2291.732910 1972.688477,2291.312012 1980.355957,2290.956787 
	C1987.738770,2290.614502 1995.515503,2292.368652 2003.108398,2291.876953 
	C2013.002930,2291.236328 2022.834351,2289.676514 2032.720459,2288.836670 
	C2040.903320,2288.141602 2048.993408,2286.651123 2057.365234,2287.663818 
	C2063.888916,2288.453125 2070.591064,2288.664307 2077.192871,2286.746338 
	C2080.303955,2285.843018 2083.781006,2286.135010 2087.096924,2286.016113 
	C2096.040527,2285.694336 2105.057617,2286.883057 2113.888428,2284.238770 
	C2116.040039,2283.594727 2118.596680,2283.674072 2120.844727,2284.086914 
	C2132.601318,2286.244873 2144.712891,2284.864014 2157.210449,2287.866699 
	C2157.192627,2292.629395 2153.535156,2291.969238 2150.327393,2291.981934 
	C2138.837891,2292.027832 2127.348389,2292.008057 2114.919678,2292.030762 
	C2108.344971,2291.062744 2103.180420,2293.995850 2096.841064,2294.023438 
	C2094.034424,2296.289795 2091.067383,2297.153320 2088.427734,2299.549316 
	C2081.934570,2299.985352 2075.888672,2299.980225 2068.937012,2299.969238 
	C2060.872803,2297.695068 2053.918945,2299.341797 2046.472412,2301.522461 
	C2023.255859,2302.231445 2000.648682,2298.660889 1976.961914,2299.964355 
	C1971.839355,2298.812256 1967.319458,2302.290283 1962.636353,2298.531250 
	C1952.552490,2297.333984 1942.933960,2299.581787 1932.837036,2296.150879 
	C1929.904541,2296.368408 1929.288940,2295.115479 1929.591919,2292.683350 
z"
        />
        <path
          fill="#536F86"
          opacity="1.000000"
          stroke="none"
          d="
M1771.562622,2276.715820 
	C1781.271729,2275.715088 1790.477905,2275.762451 1799.807007,2274.506104 
	C1809.753662,2273.166748 1820.096924,2274.981445 1830.269775,2275.063721 
	C1846.757568,2275.197266 1863.288696,2278.106689 1879.728394,2274.482666 
	C1884.500854,2273.430664 1888.755615,2276.334961 1893.502197,2276.030029 
	C1907.407471,2275.137207 1921.326172,2277.019043 1935.326904,2274.551758 
	C1942.203003,2273.339844 1949.820557,2275.400635 1956.943237,2276.794922 
	C1963.609863,2278.100342 1970.037231,2277.295898 1976.663086,2277.082764 
	C1989.675903,2276.664307 2001.868652,2270.828857 2015.002197,2272.048828 
	C2017.314087,2272.263672 2019.675293,2272.151611 2021.942749,2272.572266 
	C2023.578369,2272.875977 2025.058960,2273.914795 2024.692627,2276.001465 
	C2024.396973,2277.685059 2022.851562,2277.849121 2021.599365,2277.859375 
	C2012.295654,2277.937012 2003.076172,2278.494873 1993.809204,2279.743652 
	C1986.256592,2280.761475 1978.776123,2282.137451 1971.243896,2283.216309 
	C1963.805664,2284.281982 1956.462524,2287.737305 1948.779663,2284.239014 
	C1947.936890,2283.855225 1946.760742,2283.896973 1945.813354,2284.094971 
	C1932.618164,2286.856445 1919.477173,2283.101074 1906.251465,2283.853760 
	C1893.296875,2284.591064 1880.241089,2284.496826 1867.277222,2283.836182 
	C1856.061401,2283.264648 1844.911987,2281.386963 1832.888672,2280.111328 
	C1830.526245,2280.100342 1829.008545,2280.064209 1826.732178,2280.040039 
	C1815.257568,2284.980469 1803.568115,2281.689697 1791.754150,2284.231445 
	C1789.880493,2284.630615 1788.789062,2284.740479 1786.863037,2284.816406 
	C1783.804199,2284.978271 1781.586792,2284.884766 1778.575439,2284.229492 
	C1774.782471,2282.600586 1771.201294,2281.779297 1771.562622,2276.715820 
z"
        />
        <path
          fill="#6E8AA1"
          opacity="1.000000"
          stroke="none"
          d="
M2259.336914,2253.497070 
	C2262.701172,2254.069824 2265.369385,2254.021973 2268.023438,2254.196533 
	C2270.957031,2254.389160 2271.885010,2256.381348 2271.872070,2259.011963 
	C2271.859131,2261.649902 2270.853516,2263.668701 2267.968994,2263.812012 
	C2263.316895,2264.042969 2258.649902,2263.969482 2253.994873,2264.161133 
	C2252.229248,2264.233887 2250.631348,2265.116455 2250.723389,2267.116699 
	C2250.814697,2269.099121 2252.473145,2269.805908 2254.241699,2269.878418 
	C2257.234375,2270.000977 2260.233398,2269.968994 2263.229248,2270.031006 
	C2266.275391,2270.093994 2269.374023,2269.985352 2269.853516,2274.242432 
	C2270.342529,2278.581299 2267.875000,2284.052246 2264.532471,2283.536133 
	C2255.213623,2282.096680 2247.041016,2289.556641 2237.569092,2286.181396 
	C2233.028320,2284.563721 2230.066406,2290.228760 2224.765869,2289.987061 
	C2221.864746,2290.000244 2219.739502,2289.990723 2216.816895,2289.976074 
	C2214.944336,2288.915283 2213.869873,2287.859131 2212.410889,2286.388184 
	C2211.770020,2285.369873 2211.384766,2284.859131 2210.429199,2284.224121 
	C2210.011719,2281.917969 2210.018799,2279.845703 2210.038086,2276.996094 
	C2215.783203,2271.061768 2222.975098,2274.784912 2230.033691,2273.108398 
	C2231.691162,2273.299316 2232.575195,2273.568359 2233.468994,2273.642090 
	C2234.320801,2273.712646 2235.253906,2274.743896 2236.063477,2273.547852 
	C2235.227539,2273.164062 2234.341553,2273.014160 2232.714355,2272.979492 
	C2226.145996,2271.910645 2219.838867,2275.903320 2213.810547,2270.791016 
	C2212.499268,2262.253662 2219.199219,2263.875244 2223.369873,2262.298584 
	C2229.239258,2260.079346 2237.185547,2263.386719 2241.542236,2255.060791 
	C2247.283691,2250.324219 2252.963623,2251.697998 2259.336914,2253.497070 
z"
        />
        <path
          fill="#536F86"
          opacity="1.000000"
          stroke="none"
          d="
M2090.497559,2333.101562 
	C2088.897461,2332.095459 2087.008789,2331.480469 2088.612061,2330.037598 
	C2090.979492,2327.906494 2094.102051,2328.041504 2097.116455,2327.997559 
	C2115.685791,2327.726807 2134.181641,2328.127686 2152.543213,2331.567139 
	C2157.691650,2332.531494 2163.146973,2331.809326 2168.458252,2331.970215 
	C2171.449707,2332.061035 2173.123047,2330.341064 2175.100098,2328.363281 
	C2181.183838,2322.277344 2185.153564,2322.789307 2192.790283,2327.983887 
	C2198.606201,2331.939941 2205.732910,2331.150391 2211.745850,2328.504395 
	C2217.055908,2326.167969 2222.830566,2325.207275 2227.857910,2321.546387 
	C2233.068115,2317.751953 2239.251221,2320.279541 2244.702393,2322.968018 
	C2244.143799,2326.994629 2241.250732,2325.757324 2239.248047,2325.929932 
	C2235.945312,2326.214355 2232.590576,2325.957520 2229.301025,2326.319824 
	C2226.434570,2326.635498 2224.484375,2328.940186 2223.358398,2331.199707 
	C2221.950195,2334.026367 2226.954590,2333.616211 2226.666016,2337.121826 
	C2219.419189,2338.581299 2211.936523,2338.702148 2204.585449,2337.070068 
	C2199.934082,2336.036621 2195.284424,2335.414062 2190.777100,2336.366211 
	C2179.553223,2338.736084 2168.550537,2337.634277 2157.252441,2336.632324 
	C2146.887451,2335.713623 2136.401367,2334.334229 2125.745361,2335.689697 
	C2114.223389,2337.155273 2102.589600,2335.732422 2090.497559,2333.101562 
z"
        />
        <path
          fill="#4A3831"
          opacity="1.000000"
          stroke="none"
          d="
M1765.325684,2351.938232 
	C1771.169067,2346.820557 1777.737183,2345.070068 1785.388916,2343.940674 
	C1788.954102,2345.416992 1790.067017,2348.195312 1791.507446,2351.454346 
	C1791.312012,2352.477539 1790.971558,2352.884277 1789.960938,2353.715332 
	C1781.342896,2355.983398 1775.955444,2360.932861 1769.903687,2366.453857 
	C1760.483154,2375.048096 1748.562012,2381.189453 1735.194092,2382.702393 
	C1732.555298,2383.000977 1729.921143,2382.355469 1727.406494,2379.770996 
	C1726.571045,2377.847656 1725.973511,2375.899902 1726.913818,2375.335938 
	C1739.600464,2367.729736 1745.863281,2349.750000 1765.325684,2351.938232 
z"
        />
        <path
          fill="#69849B"
          opacity="1.000000"
          stroke="none"
          d="
M2429.369629,2317.911621 
	C2435.102539,2318.225830 2440.102295,2317.133545 2445.557617,2319.297852 
	C2437.353760,2324.267090 2427.820312,2322.496826 2418.907715,2321.400391 
	C2409.763428,2320.275146 2401.023926,2317.229736 2391.554199,2319.780762 
	C2388.502197,2320.603027 2384.488525,2320.999512 2380.641113,2318.080078 
	C2385.740723,2313.885010 2392.148193,2317.977783 2398.209961,2313.546387 
	C2389.909424,2310.625732 2382.194824,2313.267578 2373.937988,2310.782959 
	C2385.911133,2305.800537 2419.444824,2305.816650 2431.323242,2310.400879 
	C2426.794189,2313.363037 2421.705078,2310.810791 2416.879639,2313.234131 
	C2419.475342,2318.684570 2424.211914,2317.877197 2429.369629,2317.911621 
z"
        />
        <path
          fill="#69849B"
          opacity="1.000000"
          stroke="none"
          d="
M1777.235107,2282.007568 
	C1780.139404,2281.979736 1782.273926,2281.977295 1785.208740,2281.978027 
	C1787.170044,2281.911133 1787.123047,2286.250488 1789.591064,2282.416992 
	C1792.622437,2280.611328 1795.382080,2279.958252 1798.395142,2279.975342 
	C1807.102173,2280.024902 1815.810181,2279.912842 1825.245850,2279.958252 
	C1822.897583,2286.237793 1816.679321,2284.877441 1811.537842,2285.930664 
	C1808.162231,2286.622070 1804.414062,2285.197021 1801.393188,2286.947021 
	C1801.045410,2290.278320 1804.510986,2289.704834 1804.814087,2291.812012 
	C1804.499268,2292.334473 1804.295044,2293.166992 1803.839600,2293.340820 
	C1795.509766,2296.519775 1787.115967,2297.280518 1778.838379,2293.284180 
	C1774.079956,2290.986328 1771.361084,2293.201660 1768.633789,2297.511230 
	C1766.186401,2294.655029 1764.166138,2291.405273 1763.622314,2286.788086 
	C1766.999634,2281.906250 1772.058960,2283.012207 1777.235107,2282.007568 
z"
        />
        <path
          fill="#3E5871"
          opacity="1.000000"
          stroke="none"
          d="
M2294.821289,2330.237305 
	C2288.716553,2326.527832 2281.778320,2328.676514 2275.789307,2324.721436 
	C2291.363525,2319.777832 2306.967529,2322.077393 2322.270264,2322.458740 
	C2330.337646,2322.659912 2339.307617,2320.153564 2346.875977,2325.732178 
	C2345.701172,2328.905518 2342.564697,2327.385742 2340.387695,2329.344727 
	C2332.774658,2335.447998 2325.221680,2334.339111 2316.772461,2330.649902 
	C2310.405029,2331.085938 2305.025391,2326.906494 2298.693848,2329.623047 
	C2297.287354,2330.663086 2296.408447,2330.931641 2294.821289,2330.237305 
z"
        />
        <path
          fill="#0C0D0F"
          opacity="1.000000"
          stroke="none"
          d="
M1789.580444,2352.728027 
	C1790.157227,2352.249756 1790.910278,2352.195068 1791.281494,2352.132812 
	C1792.461060,2352.703613 1793.269531,2353.336670 1794.527466,2354.206299 
	C1799.331421,2359.688232 1802.527222,2365.268066 1799.955933,2373.106689 
	C1791.619995,2382.374512 1780.619751,2380.723145 1769.704346,2381.723633 
	C1765.112305,2378.274658 1767.501221,2375.643799 1769.764038,2373.132812 
	C1775.947510,2366.270996 1782.129150,2359.402588 1789.580444,2352.728027 
z"
        />
        <path
          fill="#536F86"
          opacity="1.000000"
          stroke="none"
          d="
M1840.718140,2326.175781 
	C1847.460083,2322.671143 1854.339966,2323.334229 1860.618774,2324.480469 
	C1868.250732,2325.873779 1875.778809,2325.962158 1883.379761,2325.952393 
	C1888.132080,2325.946045 1892.544922,2326.693115 1896.556885,2329.016113 
	C1896.462769,2332.510498 1893.989746,2331.894287 1892.233765,2331.914307 
	C1883.262207,2332.017090 1874.287598,2331.887451 1865.316650,2332.012451 
	C1859.709717,2332.090820 1854.145752,2331.712646 1848.526367,2333.325684 
	C1843.007324,2334.909668 1842.347168,2333.957764 1840.718140,2326.175781 
z"
        />
        <path
          fill="#69849B"
          opacity="1.000000"
          stroke="none"
          d="
M2315.918945,2330.736816 
	C2323.360107,2329.964600 2330.715820,2329.899658 2338.979980,2329.823975 
	C2342.447021,2332.864014 2345.979736,2331.820801 2349.200928,2331.979248 
	C2352.186035,2332.126465 2355.184570,2332.017090 2358.167236,2332.189209 
	C2359.951660,2332.291992 2361.367676,2333.177979 2361.446533,2335.196777 
	C2361.518311,2337.034912 2360.506348,2338.377441 2358.911377,2339.123779 
	C2356.068604,2340.454346 2352.900635,2340.504395 2350.133789,2339.532227 
	C2345.154053,2337.782227 2340.546143,2338.382080 2335.534180,2339.518799 
	C2330.405273,2340.682373 2324.941650,2340.499023 2319.685791,2339.483643 
	C2315.012695,2338.580811 2313.871826,2335.680664 2315.918945,2330.736816 
z"
        />
        <path
          fill="#607B93"
          opacity="1.000000"
          stroke="none"
          d="
M1903.798462,2363.201172 
	C1896.931396,2362.934814 1890.215210,2365.389160 1882.653687,2366.010254 
	C1882.234009,2363.878906 1882.804932,2361.434082 1884.828491,2361.071777 
	C1891.135010,2359.942871 1897.657227,2357.827148 1903.798462,2363.201172 
z"
        />
        <path
          fill="#536F86"
          opacity="1.000000"
          stroke="none"
          d="
M1947.453125,2339.822266 
	C1944.927490,2338.494385 1944.543091,2337.093262 1946.908691,2336.421387 
	C1951.611572,2335.085449 1955.534180,2337.914795 1961.262207,2338.929199 
	C1955.937012,2340.595459 1952.034790,2339.806152 1947.453125,2339.822266 
z"
        />
        <path
          fill="#69849B"
          opacity="1.000000"
          stroke="none"
          d="
M2293.737793,2330.216309 
	C2295.093994,2329.792725 2295.928467,2329.831055 2297.391113,2329.892090 
	C2297.703369,2332.369629 2298.469971,2335.941406 2294.345703,2335.317383 
	C2291.721191,2334.920166 2292.087402,2332.616699 2293.737793,2330.216309 
z"
        />
        <path
          fill="#536F86"
          opacity="1.000000"
          stroke="none"
          d="
M1786.802856,2339.982910 
	C1786.279297,2340.098877 1786.029419,2339.164062 1785.971924,2338.685059 
	C1786.639038,2337.174561 1787.675537,2336.069824 1788.718384,2337.326416 
	C1789.769897,2338.593750 1788.523438,2339.398682 1786.802856,2339.982910 
z"
        />
        <path
          fill="#69849B"
          opacity="1.000000"
          stroke="none"
          d="
M2212.105957,2286.622559 
	C2213.783691,2286.192383 2215.315186,2286.730957 2215.911133,2289.345703 
	C2214.218262,2289.808838 2212.682861,2289.273193 2212.105957,2286.622559 
z"
        />
        <path
          fill="#69849B"
          opacity="1.000000"
          stroke="none"
          d="
M2199.069824,2300.957031 
	C2199.114990,2301.040771 2199.024902,2300.873047 2199.069824,2300.957031 
z"
        />
        <path
          fill="#69849B"
          opacity="1.000000"
          stroke="none"
          d="
M2537.346191,2291.952637 
	C2538.061768,2294.812744 2538.223633,2297.439209 2541.848145,2297.896973 
	C2544.238770,2298.198975 2547.002686,2297.475830 2550.211182,2301.045654 
	C2542.303955,2300.625244 2536.016846,2300.291016 2528.845215,2299.927246 
	C2528.671387,2290.315918 2528.671387,2290.315918 2537.346191,2291.952637 
z"
        />
        <path
          fill="#69849B"
          opacity="1.000000"
          stroke="none"
          d="
M2587.582520,2236.462646 
	C2600.192383,2236.025879 2612.357666,2236.071045 2624.521973,2235.976562 
	C2631.002197,2235.926025 2637.444824,2236.715820 2643.585205,2232.840332 
	C2647.114014,2230.613281 2651.963135,2232.210449 2657.091309,2231.963379 
	C2662.240723,2231.833008 2666.468262,2232.006592 2671.242188,2233.865234 
	C2677.126465,2230.073242 2681.968750,2231.009033 2687.313477,2235.362793 
	C2689.482666,2235.969727 2690.994385,2235.978516 2693.262939,2235.979004 
	C2696.791504,2235.980469 2699.563721,2235.990234 2703.167969,2236.000000 
	C2705.897705,2237.413818 2707.672363,2238.852539 2704.520020,2241.577881 
	C2692.060791,2241.331055 2680.150635,2242.177734 2668.144531,2241.030518 
	C2660.953125,2240.343506 2652.941406,2241.163086 2645.470947,2242.599854 
	C2635.216797,2244.572021 2625.067139,2244.792969 2614.843262,2242.641113 
	C2610.571777,2241.742188 2606.140381,2241.235352 2602.076172,2243.134277 
	C2590.735352,2248.432617 2579.318359,2245.815674 2567.873047,2243.885742 
	C2566.097900,2243.586182 2564.526855,2242.824707 2564.663818,2240.835205 
	C2564.799072,2238.869141 2566.467285,2238.183594 2568.245605,2238.130615 
	C2571.574463,2238.031250 2574.906250,2238.040039 2579.118408,2238.006836 
	C2582.412842,2237.848877 2584.949219,2238.471191 2587.582520,2236.462646 
z"
        />
        <path
          fill="#738EA6"
          opacity="1.000000"
          stroke="none"
          d="
M2687.958740,2235.172852 
	C2683.086670,2236.125488 2678.634277,2233.167480 2672.839844,2233.971680 
	C2671.770020,2229.830322 2673.583008,2227.861328 2677.848145,2227.986084 
	C2682.558350,2228.124023 2687.557861,2227.537109 2687.958740,2235.172852 
z"
        />
        <path
          fill="#738EA6"
          opacity="1.000000"
          stroke="none"
          d="
M2773.537598,2212.442871 
	C2775.884521,2214.325684 2776.300293,2216.161377 2773.883545,2217.286865 
	C2771.828125,2218.244141 2769.738037,2217.234619 2769.208496,2215.110596 
	C2768.464355,2212.125732 2770.939209,2212.310059 2773.537598,2212.442871 
z"
        />
        <path
          fill="#738EA6"
          opacity="1.000000"
          stroke="none"
          d="
M2692.419434,2207.757568 
	C2692.234863,2207.369141 2692.620605,2206.861084 2693.575439,2206.231445 
	C2693.761230,2206.619629 2693.376221,2207.128906 2692.419434,2207.757568 
z"
        />
        <path
          fill="#6E8AA1"
          opacity="1.000000"
          stroke="none"
          d="
M2546.208496,2237.234375 
	C2543.328857,2240.893311 2539.805176,2239.949219 2536.579346,2239.964111 
	C2519.925049,2240.041748 2503.270264,2239.994629 2486.615479,2240.005127 
	C2481.973877,2240.008057 2477.575195,2240.018799 2473.260498,2242.879395 
	C2469.138184,2245.612549 2464.020752,2246.193115 2458.864014,2246.020996 
	C2439.479980,2245.373535 2420.135986,2243.599609 2400.714355,2243.979248 
	C2394.055176,2244.109619 2387.391113,2243.987305 2380.729248,2244.004883 
	C2377.101074,2244.014648 2373.826660,2243.824463 2371.281494,2247.812988 
	C2368.767822,2251.752930 2363.819336,2250.426270 2360.203613,2249.489990 
	C2353.998047,2247.882812 2348.475342,2246.500244 2344.772949,2253.664551 
	C2343.989258,2255.180908 2342.090088,2255.716064 2340.359131,2255.047852 
	C2332.605469,2252.055176 2324.949707,2254.648193 2317.862305,2256.705566 
	C2307.279785,2259.778076 2297.442871,2255.930908 2287.273438,2255.696045 
	C2286.299072,2255.673584 2285.420166,2255.100586 2285.216064,2253.930908 
	C2284.933105,2252.310059 2286.023682,2251.463623 2287.157959,2250.841553 
	C2287.998535,2250.380615 2289.114990,2249.997314 2290.019287,2250.140625 
	C2303.552979,2252.286377 2316.967285,2244.962402 2330.551270,2249.665527 
	C2332.442383,2250.320312 2334.523926,2249.838379 2336.121338,2248.319336 
	C2337.449951,2247.056152 2338.012207,2245.479492 2337.188965,2243.837402 
	C2336.553955,2242.570312 2335.211182,2242.390869 2333.768311,2242.475342 
	C2321.575439,2243.190918 2309.494873,2242.191650 2297.429688,2240.349609 
	C2284.572754,2238.386963 2272.375977,2240.267334 2260.702148,2247.917480 
	C2259.205566,2248.765625 2258.285156,2249.062988 2256.610107,2248.460938 
	C2256.205811,2244.862793 2253.167725,2242.858154 2252.695068,2239.317383 
	C2257.233887,2236.948975 2261.958984,2238.475830 2266.406738,2237.993652 
	C2268.377686,2237.779541 2270.822998,2238.017090 2271.283203,2235.545654 
	C2271.898682,2232.240234 2268.693848,2231.320801 2266.806885,2231.517334 
	C2259.163574,2232.312500 2251.817871,2229.931152 2244.276123,2229.984131 
	C2229.999023,2230.084717 2215.697266,2229.262207 2201.719238,2233.566650 
	C2195.692383,2235.422119 2189.421387,2232.165283 2183.043701,2233.839111 
	C2167.969971,2237.795898 2152.732422,2232.799072 2137.554199,2233.924561 
	C2135.636475,2234.067139 2133.009521,2233.993408 2133.229248,2231.229004 
	C2133.404541,2229.022217 2135.678467,2228.172852 2137.783203,2228.149658 
	C2147.446777,2228.043213 2157.115234,2228.166260 2166.774170,2227.913818 
	C2169.778564,2227.835205 2175.342773,2229.447998 2174.956787,2225.181152 
	C2174.478271,2219.888916 2169.512939,2222.983398 2166.240234,2223.538330 
	C2161.612061,2224.323242 2156.754395,2224.813721 2152.345703,2223.507324 
	C2145.413086,2221.452881 2138.468018,2222.202393 2131.516846,2222.033691 
	C2127.251953,2221.930176 2122.769043,2221.198486 2120.954834,2227.056641 
	C2120.060547,2229.943848 2116.779785,2230.084961 2114.070801,2229.959473 
	C2097.487793,2229.191895 2080.920166,2229.969238 2064.259033,2228.726807 
	C2050.294922,2227.685547 2036.159790,2224.276367 2021.702148,2227.585449 
	C2013.782837,2229.397949 2005.112183,2227.946777 1996.780273,2227.920898 
	C1995.065552,2227.915527 1993.158325,2227.546875 1993.184814,2225.439209 
	C1993.207642,2223.618408 1994.851685,2222.484619 1996.559937,2222.341797 
	C2000.536255,2222.010498 2004.557739,2222.164795 2008.520264,2221.742188 
	C2011.205322,2221.455811 2011.707031,2219.604004 2010.148315,2217.503418 
	C2007.893066,2214.464111 2005.303711,2213.566406 2001.526611,2215.493896 
	C1993.385498,2219.647949 1984.840454,2219.500732 1976.711914,2215.659180 
	C1971.809814,2213.342773 1966.947144,2214.290771 1962.032471,2214.136963 
	C1960.638184,2214.093018 1959.262817,2214.684814 1959.074951,2216.198730 
	C1958.903320,2217.582764 1959.864014,2218.652588 1961.062256,2219.379639 
	C1961.630981,2219.724609 1962.215942,2220.043457 1962.800171,2220.362305 
	C1966.027466,2222.124023 1970.339722,2224.132080 1968.544067,2228.266357 
	C1967.091675,2231.610352 1962.550049,2230.202393 1959.476074,2229.416748 
	C1948.275024,2226.553955 1936.917725,2228.680176 1925.658325,2228.019775 
	C1922.668457,2227.844238 1919.660034,2227.959717 1916.660522,2227.983887 
	C1913.489624,2228.009277 1911.326172,2227.400146 1909.785156,2223.791260 
	C1907.768311,2219.068359 1904.406494,2219.108154 1902.539307,2223.975586 
	C1900.249756,2229.943604 1896.639893,2232.518799 1890.478271,2231.991699 
	C1888.223511,2231.798340 1885.489868,2232.427979 1884.587769,2229.500000 
	C1883.907349,2227.291748 1885.490112,2225.808350 1887.049438,2224.452637 
	C1887.758423,2223.836182 1888.303589,2223.031250 1889.286743,2221.889404 
	C1885.164062,2216.940674 1878.884155,2216.389404 1874.836792,2218.617188 
	C1867.478882,2222.666992 1860.420776,2220.098633 1853.576294,2219.483398 
	C1848.167603,2218.997314 1841.559814,2219.338379 1836.595337,2214.417480 
	C1838.639404,2211.503418 1842.399414,2210.488281 1842.493042,2206.570801 
	C1842.544678,2204.405762 1844.909668,2203.549072 1846.758057,2202.808594 
	C1851.290161,2200.992676 1854.088013,2202.766846 1852.973511,2207.528320 
	C1851.685669,2213.030518 1854.002930,2214.083984 1858.489136,2213.985596 
	C1860.821045,2213.934570 1863.322021,2214.483887 1865.457886,2213.825684 
	C1874.488525,2211.041992 1883.142090,2211.697510 1891.734863,2215.492920 
	C1893.142334,2216.114502 1895.013672,2215.820557 1896.658569,2215.729980 
	C1898.089966,2215.650879 1899.220337,2214.786377 1899.377930,2213.309570 
	C1899.530762,2211.879395 1898.808350,2210.694336 1897.305786,2210.393311 
	C1894.345581,2209.800049 1891.463867,2209.043945 1888.953735,2207.278809 
	C1887.841797,2206.497070 1886.880615,2205.523438 1887.167847,2204.044189 
	C1887.483398,2202.419434 1888.940674,2202.103516 1890.245483,2202.121826 
	C1902.368652,2202.293213 1914.398071,2199.354248 1926.676758,2202.931152 
	C1934.505859,2205.211670 1943.069458,2204.719727 1950.981567,2207.687256 
	C1954.924927,2209.166016 1958.908569,2206.911377 1962.542114,2205.461426 
	C1969.501221,2202.684814 1976.592041,2201.979492 1984.019043,2201.891846 
	C2000.282715,2201.699707 2016.459717,2198.896973 2032.782349,2200.023193 
	C2035.988159,2200.244141 2039.399170,2199.080566 2043.292847,2201.839355 
	C2038.843018,2204.887695 2034.337646,2206.020996 2029.582520,2205.929932 
	C2023.302124,2205.808838 2016.947021,2206.899414 2010.751953,2204.648682 
	C2008.759766,2203.924805 2005.680664,2203.003418 2005.245483,2206.488281 
	C2004.956421,2208.802490 2007.362915,2209.793213 2009.404175,2209.791748 
	C2018.059570,2209.785400 2026.395142,2212.901123 2035.166382,2212.096191 
	C2039.962036,2211.656250 2045.374023,2210.806885 2046.891235,2217.950195 
	C2047.350342,2220.112061 2050.128662,2220.043457 2052.093506,2219.933350 
	C2059.860107,2219.499023 2067.205322,2221.768799 2074.688232,2223.207764 
	C2079.543457,2224.141357 2084.301270,2225.828613 2086.733887,2218.622803 
	C2088.062012,2214.689209 2096.839111,2214.365967 2100.644775,2218.464355 
	C2104.523438,2222.640137 2106.818848,2221.564697 2110.553223,2218.661377 
	C2117.958984,2212.903564 2126.046143,2209.720947 2135.396729,2214.667236 
	C2137.079102,2215.557129 2139.276123,2215.997559 2141.180176,2215.862549 
	C2159.367188,2214.572754 2177.530762,2216.756104 2195.717773,2216.090332 
	C2203.296631,2215.812744 2203.311768,2216.223877 2203.341064,2225.422607 
	C2211.581543,2222.501221 2220.208984,2225.339355 2229.404297,2222.807861 
	C2227.182373,2219.399658 2224.354736,2220.264648 2222.035645,2219.895508 
	C2220.706787,2219.683594 2219.375732,2219.440674 2218.816162,2217.988525 
	C2218.041748,2215.979736 2219.159912,2214.794678 2220.824951,2214.133545 
	C2222.985352,2213.275146 2223.864990,2211.704102 2223.958984,2208.768555 
	C2230.648682,2203.445068 2235.707520,2205.256592 2239.691895,2212.699707 
	C2239.534912,2216.637695 2239.683838,2219.279297 2243.961914,2219.681152 
	C2247.571045,2220.019531 2251.092285,2221.235107 2255.560547,2220.844482 
	C2252.955322,2218.760010 2249.870850,2218.705811 2247.534180,2216.139648 
	C2247.619385,2207.934570 2252.713867,2208.222168 2258.796143,2209.426758 
	C2261.376709,2211.594238 2263.317627,2213.236816 2266.096191,2209.649414 
	C2268.428711,2208.762695 2270.327148,2208.842285 2271.385986,2210.152100 
	C2279.424072,2220.093750 2289.188965,2216.008789 2299.115723,2214.093018 
	C2297.799561,2209.468262 2295.223145,2209.607178 2292.272705,2211.497070 
	C2287.959717,2214.260010 2283.918457,2213.480957 2280.283691,2210.488281 
	C2276.736328,2207.567627 2271.959473,2204.517090 2273.545898,2199.242920 
	C2275.040039,2194.275879 2280.340332,2196.626953 2284.054443,2195.979736 
	C2294.297607,2194.195068 2303.879883,2194.977051 2312.286133,2202.047119 
	C2314.236084,2203.687256 2317.000488,2204.313232 2319.863281,2202.929443 
	C2309.513916,2196.663330 2298.917725,2193.491943 2286.704102,2195.756592 
	C2280.246582,2196.954102 2273.356201,2196.371338 2267.237793,2192.954590 
	C2265.165283,2191.797119 2263.060547,2190.381104 2263.379150,2187.664062 
	C2263.701416,2184.917725 2266.104980,2184.137939 2268.405029,2183.492920 
	C2269.370117,2183.222168 2270.380615,2182.939697 2271.358643,2183.039795 
	C2299.393799,2185.904541 2327.641113,2181.756836 2355.759521,2187.497314 
	C2380.210938,2192.489014 2405.689941,2192.720947 2430.419678,2190.964844 
	C2442.573486,2190.102051 2454.987549,2186.564697 2467.626953,2186.712646 
	C2469.626953,2186.736084 2471.641846,2186.635986 2473.619629,2186.905518 
	C2477.183105,2187.390869 2482.077637,2187.170654 2482.403076,2191.679443 
	C2482.753906,2196.537842 2477.890381,2196.554443 2474.298828,2196.945557 
	C2470.001221,2197.413086 2465.746826,2198.149902 2461.496338,2199.078613 
	C2481.947266,2194.685303 2502.761719,2194.906494 2523.475342,2193.850098 
	C2525.141357,2193.765137 2526.808105,2193.605957 2528.458252,2193.916504 
	C2532.018555,2194.586670 2534.836914,2196.278076 2535.198486,2200.191895 
	C2535.581299,2204.338623 2532.770020,2206.026611 2529.246338,2206.931641 
	C2524.999023,2208.023193 2520.720459,2208.109375 2516.358154,2207.744873 
	C2509.446533,2207.167725 2502.510010,2206.756348 2495.143555,2208.455811 
	C2498.083740,2211.840820 2501.413086,2210.555664 2504.278809,2210.130615 
	C2526.990479,2206.763184 2549.777344,2204.524414 2572.768555,2204.646973 
	C2579.788330,2204.684570 2586.707764,2203.668945 2593.569824,2202.140137 
	C2602.935303,2200.053711 2612.072266,2200.683350 2621.395996,2205.501221 
	C2625.597412,2205.930176 2629.301758,2206.453857 2632.656738,2204.712158 
	C2640.667969,2200.553223 2648.564697,2203.505127 2656.477295,2205.016846 
	C2656.940186,2205.105469 2657.249512,2205.999268 2657.810303,2207.259277 
	C2657.990234,2208.000000 2658.000000,2208.000000 2658.000000,2207.998047 
	C2656.164307,2210.493408 2652.924316,2209.419189 2650.334717,2211.442383 
	C2645.823730,2216.865234 2642.104248,2217.575928 2640.635010,2213.206299 
	C2638.652588,2207.310303 2635.877686,2207.910400 2631.771729,2210.084229 
	C2630.603760,2210.702637 2629.334961,2211.133057 2628.105713,2211.632812 
	C2626.388428,2212.331299 2623.570068,2211.454346 2623.180176,2214.009033 
	C2622.704346,2217.125000 2625.622559,2217.364502 2627.725342,2218.213623 
	C2630.897461,2219.494873 2633.890137,2219.990967 2637.329834,2218.645752 
	C2640.574219,2217.376709 2642.264648,2219.855957 2642.962158,2222.645020 
	C2643.766602,2225.861328 2640.967285,2226.730225 2638.898926,2227.217773 
	C2636.036865,2227.892090 2632.963135,2228.135742 2630.022949,2227.935059 
	C2612.723877,2226.754883 2595.614014,2229.874023 2578.380615,2230.030273 
	C2569.076660,2235.947510 2558.468018,2233.027588 2548.510254,2234.513916 
	C2547.732910,2234.629883 2547.111084,2235.786377 2546.208496,2237.234375 
z"
        />
        <path
          fill="#6E8AA1"
          opacity="1.000000"
          stroke="none"
          d="
M2452.005859,2257.240479 
	C2441.384521,2260.185303 2431.157959,2263.019531 2419.759521,2260.458008 
	C2409.217529,2258.089355 2398.433838,2263.038330 2386.361816,2262.114746 
	C2388.966553,2267.173340 2392.297119,2265.790771 2394.864258,2265.934326 
	C2398.372559,2266.130615 2401.407471,2266.207764 2402.173828,2270.764648 
	C2402.963623,2275.462402 2405.988525,2275.731689 2410.383789,2275.033447 
	C2420.817383,2273.375488 2431.251221,2271.297119 2441.953125,2271.944092 
	C2446.163086,2272.198975 2450.467041,2272.722168 2453.088623,2267.615723 
	C2454.368164,2265.123291 2457.922852,2266.099609 2460.532471,2266.015625 
	C2468.136963,2265.770752 2475.728760,2265.134521 2483.282715,2264.614502 
	C2492.398682,2263.987061 2502.121094,2264.922119 2512.130127,2269.521973 
	C2507.783691,2272.942871 2504.060791,2271.730713 2500.683105,2271.977783 
	C2495.823242,2272.333496 2490.708496,2270.933105 2486.519043,2275.004639 
	C2485.056641,2276.425537 2482.749512,2275.879639 2480.787598,2275.277832 
	C2471.621094,2272.465820 2464.098633,2275.626465 2456.690918,2281.872070 
	C2452.236084,2284.960205 2447.741699,2284.331055 2443.654053,2283.838623 
	C2430.331299,2282.233154 2416.815674,2283.659668 2402.793945,2280.545410 
	C2392.317383,2281.236084 2382.943359,2277.880127 2373.344482,2278.010498 
	C2369.164551,2278.067383 2367.808350,2275.098633 2365.927734,2272.368408 
	C2364.271729,2269.964600 2362.114014,2269.528809 2360.508301,2272.352783 
	C2359.422363,2274.262695 2359.814209,2277.230225 2356.803223,2277.646729 
	C2352.516846,2278.239990 2347.513672,2279.392822 2344.058594,2277.303955 
	C2337.171143,2273.139648 2330.002686,2274.151123 2322.812744,2274.030273 
	C2314.841064,2273.896240 2306.864990,2274.025391 2298.891357,2273.981201 
	C2295.274902,2273.961182 2291.575439,2274.016357 2288.703613,2269.530029 
	C2294.179932,2267.836914 2299.254883,2266.125000 2304.735107,2266.035156 
	C2310.714111,2265.937256 2316.696045,2266.004150 2322.676514,2266.011230 
	C2325.342285,2266.014160 2327.876709,2266.115234 2330.125977,2268.077637 
	C2333.287842,2270.837158 2337.039307,2270.697998 2340.242920,2268.396484 
	C2343.828369,2265.820801 2347.836426,2265.383301 2351.653320,2266.130615 
	C2359.762207,2267.717529 2366.839600,2262.533691 2374.715820,2263.254883 
	C2375.002686,2263.281006 2375.427002,2263.081787 2375.604248,2262.848389 
	C2381.176270,2255.513428 2389.178711,2255.649902 2397.199219,2255.705078 
	C2398.109131,2255.711426 2399.305664,2255.259277 2399.891846,2254.596924 
	C2405.369873,2248.406494 2412.847168,2249.077881 2419.410156,2250.348145 
	C2429.678467,2252.335205 2439.884521,2251.715088 2451.060059,2251.992676 
	C2451.994141,2253.490479 2451.998047,2254.990479 2452.005859,2257.240479 
z"
        />
        <path
          fill="#6E8AA1"
          opacity="1.000000"
          stroke="none"
          d="
M2704.849609,2241.980225 
	C2704.686768,2240.356201 2705.561768,2238.755859 2704.194824,2236.494629 
	C2712.289795,2236.197266 2712.289795,2236.197266 2719.504395,2240.424072 
	C2714.719482,2243.070068 2710.055664,2241.530762 2704.849609,2241.980225 
z"
        />
        <path
          fill="#7793AA"
          opacity="1.000000"
          stroke="none"
          d="
M2650.737061,2212.003906 
	C2648.767822,2211.757568 2647.324463,2211.537842 2647.213135,2210.072998 
	C2647.170166,2209.509766 2648.273193,2208.401367 2648.944580,2208.323730 
	C2651.414551,2208.038086 2653.922607,2208.080811 2657.208008,2207.998047 
	C2657.542969,2212.122314 2654.466064,2211.983154 2650.737061,2212.003906 
z"
        />
        <path
          fill="#6E8AA1"
          opacity="1.000000"
          stroke="none"
          d="
M2535.504395,2255.488525 
	C2533.671631,2258.796387 2530.453857,2257.775879 2526.748535,2257.965820 
	C2527.451904,2252.302979 2531.326904,2253.932129 2535.504395,2255.488525 
z"
        />
        <path
          fill="#6E8AA1"
          opacity="1.000000"
          stroke="none"
          d="
M2502.634766,2251.904785 
	C2502.704102,2247.922363 2504.249023,2245.057129 2509.563477,2247.500732 
	C2508.324707,2250.246582 2505.883301,2251.171631 2502.634766,2251.904785 
z"
        />
        <path
          fill="#6E8AA1"
          opacity="1.000000"
          stroke="none"
          d="
M2587.296875,2236.028076 
	C2587.121094,2240.522217 2584.345215,2240.534180 2580.463135,2238.509277 
	C2581.695557,2235.719971 2584.188232,2236.057617 2587.296875,2236.028076 
z"
        />
        <path
          fill="#69849B"
          opacity="1.000000"
          stroke="none"
          d="
M2671.037109,2375.974121 
	C2679.918457,2374.895264 2687.329346,2379.110596 2695.346680,2377.859863 
	C2695.730713,2380.781982 2691.657227,2380.766846 2692.496826,2383.385498 
	C2693.436768,2386.317139 2695.976318,2385.972656 2698.192139,2385.920166 
	C2699.779297,2385.883057 2701.355225,2385.356689 2703.472900,2384.514648 
	C2707.539551,2384.378174 2710.738281,2386.485107 2715.209717,2386.019043 
	C2715.026855,2393.442627 2715.026855,2393.442627 2716.863525,2399.052979 
	C2711.809814,2400.534668 2706.769287,2401.197021 2703.656494,2396.213623 
	C2698.232422,2387.530762 2689.442383,2388.559814 2681.572021,2388.306396 
	C2668.400391,2387.882080 2655.181885,2388.887939 2642.103760,2386.421875 
	C2641.125488,2386.237549 2640.112061,2386.028076 2639.133057,2386.094727 
	C2622.476318,2387.225342 2606.011719,2383.895020 2589.400879,2383.975342 
	C2585.391846,2383.994873 2581.697998,2384.307373 2577.943359,2386.435303 
	C2573.409912,2389.004639 2572.530518,2381.220947 2567.908203,2381.995361 
	C2563.131592,2382.795654 2557.965576,2380.624756 2553.528320,2384.319824 
	C2550.122803,2387.155762 2545.841064,2385.659668 2541.936279,2385.973389 
	C2540.612549,2386.079834 2539.267578,2385.916260 2537.941650,2386.008545 
	C2534.747314,2386.230225 2529.677734,2385.104492 2529.499023,2389.062500 
	C2529.269531,2394.142822 2534.511475,2391.612305 2537.348145,2391.949707 
	C2550.161377,2393.475098 2563.006348,2394.737793 2575.845215,2396.043457 
	C2578.764893,2396.340332 2580.931641,2397.471680 2581.885742,2401.242432 
	C2569.361328,2407.324463 2556.474609,2405.518311 2542.806641,2402.667480 
	C2536.865723,2401.287842 2533.213867,2395.633545 2526.641602,2397.714355 
	C2515.538818,2401.870605 2504.931152,2398.063477 2494.405762,2398.046875 
	C2479.805176,2398.023926 2465.145508,2396.243652 2450.684570,2393.931152 
	C2431.746582,2390.902588 2412.868896,2394.279541 2394.031250,2393.579590 
	C2371.746094,2392.751465 2349.510742,2394.287598 2327.260498,2393.488037 
	C2316.663330,2393.107178 2306.026367,2395.566650 2294.739502,2392.525146 
	C2291.038086,2391.388916 2285.800537,2393.688477 2285.533203,2389.836182 
	C2285.179932,2384.739258 2290.677979,2386.307861 2293.974854,2386.060059 
	C2297.588135,2385.788574 2301.235596,2385.968750 2304.868164,2385.959961 
	C2309.148682,2385.949463 2311.793701,2384.139160 2311.950684,2378.750000 
	C2319.918945,2377.965332 2327.877930,2377.938965 2335.834961,2378.032959 
	C2348.759766,2378.185303 2361.484863,2376.398438 2374.212402,2374.429199 
	C2377.785889,2373.876465 2381.480957,2374.082031 2385.121582,2373.992188 
	C2403.615723,2373.535156 2422.196777,2375.645264 2440.584229,2372.016602 
	C2458.296143,2368.521484 2476.238525,2370.632080 2494.993896,2369.875977 
	C2500.568604,2369.886475 2505.215820,2369.909912 2509.861572,2370.004639 
	C2511.847168,2370.045166 2514.338867,2369.505371 2515.728760,2370.476807 
	C2523.271484,2375.749756 2531.731689,2373.867676 2539.862549,2373.922852 
	C2567.082275,2374.106934 2594.318604,2373.485107 2621.519287,2374.233643 
	C2637.706543,2374.679199 2653.859131,2376.613525 2671.037109,2375.974121 
z"
        />
        <path
          fill="#70889D"
          opacity="1.000000"
          stroke="none"
          d="
M991.377014,2239.515137 
	C992.055603,2243.536865 991.034912,2245.970703 987.005005,2245.939453 
	C982.028503,2245.900879 977.048767,2246.037354 972.075623,2245.904541 
	C968.749329,2245.815674 963.930786,2242.245361 964.790833,2250.089600 
	C965.021973,2252.197998 961.697510,2251.937256 959.790649,2251.955811 
	C952.822693,2252.023682 945.853577,2251.968994 938.885315,2252.018066 
	C936.902710,2252.032227 934.901794,2252.110840 932.946228,2252.405518 
	C931.529419,2252.618652 930.607910,2253.695557 930.731018,2255.184326 
	C930.852295,2256.650635 931.857788,2257.564941 933.322998,2257.701660 
	C937.620483,2258.102783 941.914062,2258.398926 946.122314,2256.968994 
	C950.014893,2255.646240 953.923157,2255.730469 957.849365,2256.941895 
	C964.350525,2258.947754 971.030029,2257.703613 977.628723,2257.929199 
	C980.946228,2258.042480 982.888123,2255.518799 985.368347,2253.919922 
	C990.747559,2250.451660 992.962708,2251.505615 995.979065,2259.226074 
	C992.287109,2268.537354 984.487488,2265.747803 978.813416,2264.814453 
	C965.231506,2262.579834 951.564514,2264.077148 939.282898,2267.814941 
	C925.997559,2271.858154 913.727234,2270.077881 901.008179,2268.831543 
	C893.796692,2268.125000 883.498230,2266.757568 883.980591,2258.578857 
	C884.523926,2249.368408 895.064636,2253.717285 902.184631,2252.654297 
	C908.786072,2253.171875 914.528564,2251.619385 920.541931,2251.775391 
	C915.937744,2248.130371 910.433167,2247.518555 904.171021,2247.068359 
	C900.430786,2247.859375 897.413269,2248.504150 893.779053,2246.581055 
	C890.339294,2241.397461 892.126953,2236.723389 893.417542,2231.325195 
	C895.130127,2227.176758 897.933228,2225.724365 901.775269,2225.309814 
	C897.267761,2224.107422 893.464539,2226.777100 889.360840,2226.577637 
	C886.338135,2226.430664 882.969971,2226.525146 881.584717,2223.332031 
	C880.003540,2219.687744 883.171936,2218.073486 885.596375,2216.362305 
	C886.668335,2215.605957 887.832031,2214.954834 888.394836,2213.025391 
	C884.502686,2208.121582 875.980347,2208.419678 873.777832,2200.694824 
	C879.625122,2196.793457 885.366211,2197.047363 890.901367,2200.042236 
	C893.364807,2201.375244 895.864380,2202.574219 898.458496,2201.777832 
	C908.268860,2198.766846 918.300659,2200.466553 928.224182,2199.997314 
	C929.553284,2199.934326 930.883789,2199.897461 932.211487,2199.814209 
	C933.710999,2199.720215 934.847595,2198.932861 934.813965,2197.432129 
	C934.782654,2196.036865 933.686218,2195.036377 932.351685,2194.660400 
	C931.088806,2194.304199 929.750610,2194.117676 928.436890,2194.061768 
	C926.108887,2193.962402 923.764343,2193.874023 921.444214,2194.034668 
	C912.965881,2194.620605 904.819824,2194.341797 899.235352,2185.860352 
	C899.699890,2183.032959 902.674011,2184.392090 904.652222,2183.848145 
	C906.089722,2183.452637 907.190918,2182.731934 906.971313,2181.218750 
	C906.772095,2179.846191 905.550964,2178.526611 904.290649,2178.619629 
	C899.810974,2178.950195 894.369995,2178.425293 892.195801,2183.261719 
	C889.847595,2188.485596 884.596313,2189.529785 880.871399,2192.600342 
	C876.547424,2196.164795 874.378479,2190.095459 870.916504,2189.226318 
	C871.624695,2185.176025 874.709961,2184.941650 876.835876,2183.632812 
	C879.350525,2182.084961 882.639221,2181.351318 883.624634,2178.138184 
	C884.972778,2173.741699 887.793762,2174.123291 891.477234,2174.671875 
	C897.895508,2175.627686 899.053711,2174.558105 901.996216,2166.728516 
	C909.300476,2167.158447 915.559631,2163.023193 923.210144,2162.018066 
	C924.201965,2165.000488 920.348755,2168.830322 924.793640,2170.865723 
	C929.008545,2172.795898 934.283386,2173.721924 937.734131,2169.718018 
	C942.077881,2164.677734 947.402039,2165.833740 952.480103,2166.297119 
	C956.683289,2166.680908 960.143494,2168.960449 958.839172,2174.281982 
	C958.342163,2176.309570 957.767456,2178.641357 960.117310,2179.419434 
	C963.295776,2180.471680 965.520569,2183.592529 969.030212,2183.535156 
	C970.952820,2183.503906 972.212219,2181.798340 971.488525,2180.062256 
	C968.789124,2173.585449 971.820374,2172.031006 977.669983,2171.530029 
	C980.669495,2171.273193 981.698425,2169.229980 979.921021,2165.715088 
	C976.215027,2158.386475 972.719543,2156.184570 963.643677,2157.175049 
	C960.281189,2157.542236 955.152649,2159.835449 955.018860,2154.680420 
	C954.911438,2150.541260 960.334229,2152.283691 963.380798,2152.079834 
	C967.686768,2151.791992 972.026001,2152.036377 976.349060,2151.952637 
	C978.274841,2151.915527 980.877869,2152.114746 980.826416,2149.389160 
	C980.783569,2147.120361 978.526123,2146.196777 976.441895,2146.168701 
	C972.338745,2146.113281 968.753906,2144.626709 965.389343,2143.160889 
	C964.824951,2140.913330 966.237061,2140.624268 967.328308,2139.916748 
	C971.463318,2137.235107 979.533081,2135.315430 984.457092,2135.772217 
	C985.265930,2135.847168 986.156738,2135.038574 987.522034,2134.397949 
	C985.721680,2129.908447 982.909912,2126.363525 980.010986,2122.789795 
	C977.283386,2119.427490 977.349243,2116.650635 983.274536,2116.022705 
	C988.162170,2112.052246 993.242554,2112.801758 998.181091,2113.447754 
	C1001.897095,2113.933838 1005.448303,2115.240967 1006.949280,2119.138672 
	C1008.656250,2123.571289 1004.981140,2125.611084 1003.487915,2127.056885 
	C1013.578125,2141.340088 1027.536865,2145.547607 1044.963257,2141.889160 
	C1043.141846,2136.475098 1038.427368,2136.236084 1034.748657,2134.780762 
	C1027.550537,2131.933350 1023.342224,2126.840332 1022.661133,2119.117920 
	C1022.254761,2114.510986 1024.350220,2111.916016 1029.213745,2113.149902 
	C1038.686401,2115.553711 1048.342041,2114.129395 1057.889893,2114.832520 
	C1063.770508,2115.265625 1069.908936,2114.313232 1071.911621,2122.660645 
	C1072.855469,2126.594727 1077.868286,2126.465332 1081.455688,2126.586670 
	C1090.789673,2126.902832 1100.044067,2127.968750 1109.290039,2129.119385 
	C1112.531128,2129.522949 1116.649170,2129.828857 1116.675781,2134.211914 
	C1116.699951,2138.169678 1112.943237,2139.241943 1109.755371,2140.364258 
	C1101.404419,2143.303711 1092.659058,2140.127930 1084.249512,2142.046143 
	C1096.457153,2141.524414 1108.814941,2145.039062 1120.854126,2139.025146 
	C1125.568237,2136.670410 1132.091919,2135.791504 1136.458496,2141.351318 
	C1138.737915,2144.253662 1138.638794,2147.736084 1136.761597,2150.764404 
	C1133.279053,2156.383057 1128.253784,2158.062012 1121.488892,2157.895264 
	C1116.114136,2157.762451 1110.483765,2157.169922 1105.169678,2157.978271 
	C1104.977905,2159.276611 1105.367188,2160.095947 1106.009277,2159.958252 
	C1124.139526,2156.067383 1141.328125,2163.521973 1159.074707,2164.396484 
	C1169.846069,2164.927002 1180.362915,2165.370361 1190.372681,2159.848877 
	C1189.003296,2157.861328 1185.697632,2155.895508 1187.691162,2152.410400 
	C1189.174683,2149.817139 1191.959595,2149.442383 1194.638672,2149.180176 
	C1197.645508,2148.885742 1200.716309,2148.916992 1203.557495,2149.878662 
	C1221.983765,2156.114990 1240.609619,2155.742188 1259.535767,2152.521240 
	C1266.772461,2151.289551 1274.114990,2153.282715 1281.253174,2155.021240 
	C1288.522705,2156.791992 1295.532593,2157.736084 1302.332764,2152.988770 
	C1305.761597,2150.594971 1310.045288,2150.824951 1313.826294,2152.767822 
	C1317.478149,2154.644775 1321.075928,2157.370605 1320.704834,2161.770996 
	C1320.246582,2167.206299 1315.155273,2166.811279 1311.350586,2166.972168 
	C1300.035278,2167.450684 1288.703125,2167.143066 1277.377930,2167.157471 
	C1271.038574,2167.165283 1264.890625,2167.963135 1258.620850,2169.240967 
	C1247.378296,2171.532715 1236.468140,2165.822510 1225.112183,2166.493896 
	C1222.898804,2166.625000 1220.509521,2166.043213 1218.184326,2165.989746 
	C1220.751099,2166.216797 1223.775879,2165.932373 1224.542358,2169.232666 
	C1225.332031,2172.633545 1222.434082,2173.758789 1220.135620,2175.012939 
	C1213.742920,2178.500732 1207.309448,2181.834473 1200.122314,2183.567871 
	C1196.263672,2184.498535 1192.407715,2184.622803 1189.810913,2181.795898 
	C1183.518188,2174.944336 1175.817017,2176.612305 1168.367798,2177.781738 
	C1162.920410,2178.636719 1157.710938,2180.849609 1151.633423,2181.779541 
	C1155.372803,2185.872314 1161.401611,2184.495850 1163.219360,2190.296875 
	C1164.012939,2192.829102 1168.130981,2190.985840 1170.178223,2190.075684 
	C1176.453003,2187.285400 1182.563354,2185.657227 1189.418213,2187.967773 
	C1192.327026,2188.948486 1195.031982,2187.471191 1197.815552,2185.935791 
	C1207.718384,2180.473389 1217.873047,2176.912354 1227.842285,2185.983154 
	C1228.947754,2186.989014 1230.133545,2187.361084 1231.449951,2186.749268 
	C1246.154053,2179.916504 1261.428833,2183.438232 1276.563110,2184.145996 
	C1279.106201,2184.264893 1281.663208,2184.049561 1284.175537,2184.746826 
	C1279.717651,2183.376221 1275.029175,2184.624268 1270.520752,2183.133789 
	C1267.360474,2182.088867 1263.248047,2182.024658 1263.375488,2177.714355 
	C1263.486938,2173.946045 1267.404053,2173.949707 1270.243042,2173.208496 
	C1283.686035,2169.699463 1297.453491,2170.015625 1310.981079,2171.331787 
	C1325.974243,2172.790527 1340.864014,2173.099854 1355.846802,2172.251709 
	C1360.176147,2172.006592 1364.936035,2171.298096 1365.440430,2178.461182 
	C1362.513062,2185.579346 1357.802856,2188.574463 1350.615112,2188.676758 
	C1347.582031,2188.719727 1344.210693,2188.882568 1341.139648,2190.882324 
	C1338.030640,2192.906738 1333.186157,2193.322998 1330.794922,2190.806641 
	C1325.263062,2184.984619 1317.741699,2184.842773 1311.127930,2182.175293 
	C1314.842651,2190.021729 1314.020752,2191.130127 1306.532837,2192.426514 
	C1314.200806,2189.962158 1325.429688,2190.899414 1336.677734,2192.011719 
	C1339.424927,2192.283203 1342.295410,2192.416260 1342.787354,2195.782959 
	C1343.236694,2198.857666 1341.020752,2200.582764 1338.548828,2201.863525 
	C1334.516846,2203.952148 1329.630127,2204.546631 1326.585693,2208.652588 
	C1338.763550,2207.474121 1341.824585,2205.516846 1345.797974,2195.698975 
	C1347.923462,2190.447510 1350.994507,2187.282471 1357.733398,2189.183594 
	C1361.498657,2195.166504 1357.267456,2200.998779 1358.838135,2207.404297 
	C1358.933105,2211.066162 1358.415161,2213.811768 1355.786865,2216.389160 
	C1354.517700,2216.858398 1353.790894,2217.021973 1353.135254,2217.754883 
	C1353.134399,2219.052246 1352.893921,2219.773438 1352.110840,2220.944824 
	C1340.472778,2227.984375 1332.710815,2237.180664 1329.058594,2250.091064 
	C1309.098633,2254.129395 1289.692993,2250.520508 1270.379272,2251.367432 
	C1269.821045,2251.391846 1269.324707,2252.823975 1268.422852,2254.156250 
	C1277.130737,2256.111572 1285.481812,2255.813477 1293.814819,2256.105713 
	C1299.800537,2256.315674 1306.009155,2256.958984 1307.901611,2265.249023 
	C1304.037598,2270.433105 1298.567505,2269.570068 1293.950562,2268.815430 
	C1270.332153,2264.955566 1246.294312,2267.258545 1222.650269,2263.341553 
	C1211.445190,2261.484863 1200.542114,2266.847168 1189.337280,2267.944824 
	C1185.713013,2268.299561 1182.194092,2269.153564 1177.734253,2268.164795 
	C1174.633179,2266.998291 1173.423340,2264.899658 1171.783936,2263.275635 
	C1170.060059,2261.568359 1168.584717,2259.623779 1169.483643,2257.116699 
	C1170.601929,2253.997559 1173.526733,2254.025391 1176.193237,2254.050049 
	C1188.781860,2254.166260 1201.377563,2255.122070 1212.498779,2253.758057 
	C1204.189087,2253.703857 1194.507080,2255.143066 1184.654541,2254.159912 
	C1174.705566,2253.167236 1164.742432,2254.322021 1154.385376,2257.972168 
	C1150.040649,2259.063721 1146.561157,2259.183838 1142.656982,2256.598145 
	C1142.286743,2252.483887 1144.129517,2250.614990 1147.567139,2250.032715 
	C1152.097534,2249.264648 1156.439697,2247.591553 1161.199585,2247.926270 
	C1164.305420,2248.144775 1167.626221,2248.547363 1171.047607,2246.719482 
	C1167.024780,2244.564453 1154.117065,2242.066406 1147.312866,2242.022705 
	C1138.056030,2241.963135 1128.796631,2241.892090 1119.542114,2242.045898 
	C1115.600464,2242.111328 1112.297974,2241.514893 1110.346924,2236.719238 
	C1111.235718,2229.920410 1116.229736,2231.136230 1120.217529,2231.071777 
	C1136.497192,2230.808594 1152.792847,2231.839844 1168.936523,2233.339844 
	C1199.791382,2236.206787 1230.660278,2235.811768 1254.314819,2235.983887 
	C1213.743896,2238.248047 1166.237671,2232.014893 1118.548828,2230.972656 
	C1113.894165,2230.871094 1109.225464,2231.755615 1104.613159,2231.020020 
	C1101.404785,2230.508301 1097.208252,2229.988525 1097.617554,2225.589844 
	C1097.977295,2221.722656 1101.907959,2222.309814 1104.827026,2222.017090 
	C1114.767700,2221.020020 1124.676880,2223.900635 1134.617432,2222.418457 
	C1124.703369,2223.887939 1114.839844,2221.326416 1104.905029,2221.873779 
	C1099.694214,2222.160645 1095.431030,2223.016846 1091.618408,2227.092773 
	C1086.127563,2232.963379 1079.632324,2234.722168 1070.952271,2233.575928 
	C1057.511353,2231.801270 1043.736572,2232.877441 1029.307495,2233.339355 
	C1016.561768,2231.329102 1004.689880,2229.870361 993.048218,2237.565430 
	C967.380920,2238.230957 942.483826,2238.001953 920.644165,2237.100586 
	C941.962524,2238.957764 966.445251,2236.649170 991.377014,2239.515137 
z"
        />
        <path
          fill="#778FA4"
          opacity="1.000000"
          stroke="none"
          d="
M1365.332520,2180.185059 
	C1364.003784,2176.097412 1363.167480,2174.083252 1358.143555,2174.761475 
	C1343.296753,2176.765869 1328.469727,2176.040039 1313.485107,2174.669922 
	C1300.481567,2173.480957 1286.988525,2171.501221 1274.085327,2176.444336 
	C1271.256104,2177.527832 1267.638550,2174.339111 1264.420532,2178.788574 
	C1272.428223,2180.436279 1279.332153,2183.812256 1287.060791,2180.673584 
	C1288.220337,2180.202881 1289.704224,2180.209961 1290.965576,2180.424072 
	C1291.785278,2180.563232 1292.730957,2181.329346 1293.164062,2182.078369 
	C1293.958862,2183.452637 1293.243408,2184.883301 1291.988647,2185.381104 
	C1289.253540,2186.466064 1286.643921,2188.004150 1283.414551,2187.523438 
	C1270.966064,2185.671631 1258.415283,2186.036621 1245.884888,2185.992920 
	C1241.216797,2185.976562 1236.615845,2185.562988 1233.356323,2190.263672 
	C1231.685791,2192.673096 1228.527222,2191.991455 1225.876709,2191.832520 
	C1224.408936,2191.744385 1222.761719,2190.702637 1223.378418,2189.534180 
	C1226.905396,2182.853271 1221.765137,2184.179688 1218.433716,2184.041016 
	C1214.773926,2183.888184 1211.102295,2184.007324 1207.436035,2184.029053 
	C1205.049561,2184.043213 1202.287476,2184.152100 1201.279297,2186.506592 
	C1197.621094,2195.049805 1191.150391,2192.307373 1185.303589,2190.433105 
	C1181.610596,2189.249756 1179.109253,2188.131104 1175.686768,2191.696533 
	C1170.953979,2196.626953 1164.297485,2193.039062 1158.190918,2194.148438 
	C1159.786377,2191.262939 1163.381104,2188.189209 1156.787231,2187.948486 
	C1153.093506,2187.813477 1149.517944,2186.751953 1146.642456,2184.344238 
	C1145.448486,2183.344482 1144.561035,2181.692383 1145.125732,2179.875732 
	C1145.611084,2178.314209 1147.063110,2178.091797 1148.346680,2178.104248 
	C1159.918701,2178.215088 1170.590576,2172.670166 1182.201782,2172.787354 
	C1184.664307,2172.812500 1186.299194,2172.472412 1188.003052,2174.935303 
	C1193.326050,2182.629639 1199.758789,2184.020752 1207.957642,2178.405273 
	C1212.633789,2175.202393 1217.110107,2172.035156 1224.161377,2170.999268 
	C1217.762817,2165.493652 1210.722900,2170.163330 1205.537231,2166.781738 
	C1205.707886,2163.446045 1208.192261,2164.106445 1209.959229,2164.071289 
	C1216.290039,2163.945801 1222.635254,2164.239258 1228.954712,2163.942139 
	C1237.367310,2163.546631 1245.408325,2166.677002 1253.302734,2167.084473 
	C1260.525635,2167.457275 1268.302490,2163.546387 1276.392456,2163.887451 
	C1287.039795,2164.336182 1297.720581,2164.064697 1308.385864,2163.970947 
	C1311.656006,2163.942383 1315.037231,2164.660889 1318.195312,2163.106934 
	C1314.033813,2152.633057 1309.426758,2151.143066 1301.476318,2158.033447 
	C1299.426147,2159.810547 1297.357666,2159.752930 1295.085449,2160.010498 
	C1288.956055,2160.705078 1283.265503,2158.511719 1277.384399,2157.529785 
	C1269.428101,2156.200928 1261.482422,2152.935791 1253.418457,2157.609619 
	C1251.887573,2158.496826 1249.493774,2157.890869 1247.498535,2157.982422 
	C1238.262451,2158.405029 1229.045044,2156.411865 1219.778442,2157.920898 
	C1216.829102,2158.401367 1214.185669,2157.326660 1211.393555,2155.683350 
	C1205.554077,2152.246582 1198.872314,2151.496582 1192.107300,2152.117676 
	C1190.760864,2152.240967 1189.320923,2152.545654 1189.100708,2154.137207 
	C1188.902832,2155.566162 1189.799194,2156.671143 1191.089355,2157.232666 
	C1193.507935,2158.285645 1196.194214,2158.795898 1197.942993,2161.082520 
	C1198.784912,2162.183105 1199.560425,2163.348633 1198.790405,2164.635986 
	C1197.969360,2166.008545 1196.160156,2166.079346 1195.264404,2165.280518 
	C1191.011475,2161.487061 1187.688843,2163.016113 1184.134155,2166.423828 
	C1181.787598,2168.672852 1178.463623,2167.942139 1175.497803,2167.976318 
	C1160.525879,2168.147217 1145.563721,2167.029541 1131.047485,2163.697266 
	C1123.844116,2162.043701 1117.367065,2160.038086 1110.512817,2163.858887 
	C1110.249390,2164.005859 1109.856079,2163.910400 1109.525269,2163.946533 
	C1098.950195,2165.104980 1098.167969,2164.171631 1101.127686,2152.975342 
	C1108.329224,2153.843750 1115.586548,2155.407959 1123.015503,2155.532959 
	C1128.341919,2155.622803 1131.942383,2153.429932 1134.844971,2149.566162 
	C1136.323608,2147.597900 1136.274292,2145.152344 1135.066040,2142.932617 
	C1133.784302,2140.577393 1131.253784,2139.343018 1129.162476,2140.329834 
	C1123.620361,2142.945312 1116.185181,2139.843994 1111.915283,2146.390381 
	C1110.560669,2148.467041 1107.568359,2148.397461 1105.415649,2147.423584 
	C1099.102295,2144.567383 1092.778076,2144.867920 1086.077515,2145.753662 
	C1082.405762,2146.238770 1075.883301,2148.250977 1075.615112,2142.174561 
	C1075.470459,2138.899414 1081.654175,2138.169678 1085.618530,2138.038574 
	C1092.847534,2137.799316 1100.055542,2139.806885 1107.328369,2138.026855 
	C1110.193359,2137.326172 1113.253296,2137.369873 1115.140137,2133.254150 
	C1110.385010,2130.990723 1105.245728,2132.892822 1100.719849,2131.635254 
	C1096.152344,2130.365967 1091.588623,2129.663574 1086.919312,2130.037842 
	C1079.041016,2130.668945 1071.825806,2128.958008 1065.277832,2124.714111 
	C1064.312500,2121.544434 1069.768433,2122.413086 1068.506470,2119.525146 
	C1067.532959,2117.297363 1064.978149,2117.854004 1063.156982,2118.074951 
	C1054.009399,2119.184326 1044.780640,2115.275391 1035.615723,2118.907471 
	C1031.702759,2120.458008 1030.184692,2114.131592 1026.068970,2114.897705 
	C1025.036743,2125.885986 1027.362061,2129.353516 1037.884888,2132.308838 
	C1042.772339,2133.681641 1047.201050,2135.545166 1050.136230,2140.122070 
	C1052.064819,2143.129395 1052.015991,2145.281494 1048.245605,2145.765625 
	C1042.015747,2146.565430 1035.134033,2147.820557 1029.597900,2146.921387 
	C1023.719788,2145.966553 1017.808044,2143.597168 1011.473206,2143.209717 
	C1009.006531,2143.059082 1008.147888,2140.219727 1008.062134,2137.722168 
	C1007.857910,2131.774414 1005.517273,2128.949707 999.163025,2131.166260 
	C996.658508,2132.040039 994.819519,2130.287109 993.103638,2128.900146 
	C993.754028,2125.041016 996.692200,2126.423584 998.677856,2125.885254 
	C1001.552673,2125.106201 1005.420227,2124.666504 1005.310974,2121.062500 
	C1005.184937,2116.908447 1001.075012,2116.393799 997.779785,2116.138672 
	C993.802307,2115.830811 989.785950,2116.024902 984.891968,2116.000977 
	C984.567322,2112.773926 979.489319,2109.171875 985.313477,2106.120605 
	C985.980469,2105.990234 986.571533,2106.590332 986.280884,2106.285400 
	C987.013672,2101.518555 989.260071,2096.549805 981.566101,2095.856934 
	C979.741638,2095.693115 978.449158,2094.625977 978.466980,2092.682617 
	C978.483398,2090.904541 979.535339,2089.614014 981.115356,2088.812256 
	C985.108704,2086.785889 989.740601,2088.141113 993.890747,2093.242188 
	C994.376770,2095.821045 995.755981,2097.245850 997.287415,2097.294922 
	C1009.550415,2097.687988 1020.287170,2104.872070 1032.553223,2105.088623 
	C1036.536743,2105.158936 1040.419556,2105.759033 1044.303589,2106.657959 
	C1046.186646,2107.093994 1049.178711,2109.195801 1049.755371,2105.345947 
	C1050.135376,2102.809326 1048.062256,2101.263184 1045.574463,2100.591553 
	C1041.619263,2099.523926 1036.756714,2101.820068 1033.855225,2096.716797 
	C1032.554932,2089.621826 1032.554932,2089.621826 1024.564819,2086.060059 
	C1027.184814,2081.358887 1023.608826,2074.652100 1027.814575,2071.172852 
	C1031.856934,2067.829102 1036.453613,2065.279541 1039.185791,2061.015381 
	C1039.061279,2057.832031 1036.200439,2058.257324 1034.964478,2056.836182 
	C1031.945435,2053.364746 1032.222046,2051.600586 1036.626099,2050.001953 
	C1040.697876,2048.523926 1044.210815,2046.651123 1043.428467,2041.717285 
	C1042.704346,2037.149292 1035.245483,2032.357056 1030.930786,2034.434692 
	C1025.411987,2037.091919 1022.110046,2036.918335 1017.454834,2032.339844 
	C1014.095581,2029.036133 1008.009033,2028.994141 1005.924316,2022.993896 
	C1005.391235,2021.459595 1000.387695,2021.766724 997.690735,2020.629028 
	C993.614014,2018.909058 993.280701,2015.703613 996.547974,2012.968994 
	C1002.051514,2008.362915 1009.646973,2010.050049 1011.623474,2017.089600 
	C1012.887146,2021.590210 1015.131348,2024.013306 1019.157715,2025.363770 
	C1021.090759,2026.012085 1023.188538,2026.341064 1024.743774,2024.668457 
	C1026.286743,2023.009033 1025.742310,2021.125366 1024.989502,2019.127075 
	C1022.263000,2011.889526 1022.856384,2011.219849 1030.523193,2011.731812 
	C1032.479370,2011.862305 1034.521973,2011.784180 1036.421265,2011.349243 
	C1038.087158,2010.967896 1039.229614,2009.602905 1039.467896,2007.782959 
	C1039.727539,2005.799072 1038.606689,2004.796387 1036.849854,2004.185059 
	C1031.933838,2002.474487 1027.398682,1999.932373 1022.141907,1998.865356 
	C1016.799805,1997.781372 1016.206543,1999.458496 1017.512207,2003.869507 
	C1017.750305,2004.674194 1017.229675,2005.703369 1016.898804,2007.500854 
	C1011.917725,2004.206543 1010.423035,1998.032104 1004.918945,1996.085693 
	C1003.755920,1995.674194 1004.754517,1992.866455 1006.657715,1992.455078 
	C1008.573486,1992.041260 1010.869629,1991.568726 1012.551941,1992.240967 
	C1017.274231,1994.127686 1021.719666,1991.994263 1026.279785,1991.964355 
	C1028.653442,1991.948853 1031.084473,1991.801636 1031.668091,1988.757935 
	C1032.220703,1985.875244 1030.428467,1984.560547 1028.142944,1983.502197 
	C1027.718506,1983.305542 1027.603271,1982.441406 1027.346191,1981.883667 
	C1027.725952,1980.161377 1029.299072,1979.876099 1030.429932,1980.137207 
	C1042.806396,1982.995483 1055.462158,1977.305542 1067.818604,1981.406250 
	C1069.203613,1981.865845 1071.069580,1981.177368 1072.635498,1980.743652 
	C1084.677856,1977.407959 1096.812988,1976.699585 1109.059814,1979.401367 
	C1111.517090,1979.943237 1113.601318,1978.873169 1115.859741,1978.442749 
	C1119.128418,1977.819580 1122.304199,1978.112671 1125.657837,1978.971680 
	C1132.989258,1980.849487 1140.359253,1983.517822 1148.033081,1979.423462 
	C1152.614136,1976.979126 1153.724854,1981.361816 1154.897827,1984.253906 
	C1156.275635,1987.651001 1154.160400,1991.147583 1154.836670,1994.936279 
	C1155.549927,1998.931763 1150.601318,2000.165283 1147.520020,2001.833008 
	C1145.989990,2002.661011 1143.907471,2003.533569 1144.478638,2005.178467 
	C1146.144409,2009.975952 1145.320435,2014.199219 1143.047119,2018.511353 
	C1142.088135,2020.330322 1143.532104,2021.659546 1145.115723,2022.405151 
	C1146.912109,2023.250854 1148.864502,2023.767456 1150.653076,2024.626953 
	C1152.563110,2025.545044 1153.980347,2027.065918 1153.476807,2029.347046 
	C1152.886841,2032.018677 1150.825684,2031.804688 1148.755127,2031.300659 
	C1147.521362,2031.000244 1146.221924,2030.255127 1144.779053,2032.133667 
	C1145.484375,2035.238525 1146.372192,2038.719238 1147.052368,2042.240112 
	C1148.099365,2047.659058 1146.578857,2049.222412 1141.159302,2050.044678 
	C1136.470337,2050.756104 1132.294556,2046.736206 1127.501465,2048.820801 
	C1131.949951,2056.574951 1132.656982,2057.012939 1140.323364,2055.083252 
	C1143.324951,2054.327881 1145.091309,2055.573242 1145.504639,2058.105225 
	C1146.249634,2062.667236 1147.321899,2067.680176 1144.635620,2071.724121 
	C1141.476440,2076.479248 1143.798462,2077.939209 1147.692139,2079.705322 
	C1153.974243,2082.555176 1153.905518,2082.706543 1150.110596,2088.133545 
	C1148.081177,2091.036133 1148.081177,2091.036133 1151.118408,2097.987549 
	C1149.569946,2101.380615 1145.446777,2098.889404 1143.405518,2101.142822 
	C1142.997070,2102.213135 1143.384155,2103.041504 1144.291382,2103.591553 
	C1152.255371,2108.418945 1151.486572,2108.200195 1149.027832,2117.138184 
	C1147.547119,2122.520996 1144.427124,2126.220459 1140.988892,2129.817383 
	C1143.050537,2132.775146 1145.572388,2131.851318 1147.751953,2131.975098 
	C1170.038696,2133.239258 1192.152100,2128.635986 1214.444580,2129.975098 
	C1224.704590,2125.999512 1235.628296,2126.402588 1246.200439,2124.713379 
	C1252.276489,2123.742920 1260.187134,2122.971680 1265.356934,2116.340820 
	C1276.423462,2114.514160 1286.773926,2116.080078 1297.073975,2117.354980 
	C1300.342163,2117.759766 1303.389038,2120.414062 1302.705200,2124.416748 
	C1302.090942,2128.013428 1298.632935,2128.562744 1295.653809,2128.766113 
	C1287.691040,2129.309570 1279.703979,2128.834229 1272.885498,2129.008789 
	C1283.397461,2129.429688 1295.112183,2128.129395 1306.744751,2130.158691 
	C1309.598389,2130.656494 1313.337036,2131.633301 1315.030762,2128.696045 
	C1316.876831,2125.495117 1312.740479,2124.583740 1311.181274,2122.739746 
	C1305.590698,2116.128418 1306.635376,2110.970215 1314.469727,2107.079590 
	C1319.816895,2104.424072 1319.816895,2104.424072 1323.812622,2099.004395 
	C1319.699341,2095.001221 1310.214722,2099.790283 1309.464966,2091.519287 
	C1308.937622,2085.702637 1315.819946,2085.936035 1320.495850,2083.889404 
	C1314.289185,2078.396240 1312.161011,2073.041016 1321.332397,2068.156006 
	C1326.298950,2068.066162 1330.650513,2067.407715 1334.145386,2071.066162 
	C1339.879883,2077.069580 1346.015015,2071.704102 1351.895020,2071.133789 
	C1353.889648,2070.939941 1353.003662,2068.385010 1351.679321,2067.241455 
	C1349.078613,2064.995850 1345.812134,2064.273926 1342.534180,2063.574219 
	C1341.113403,2063.270996 1340.323608,2062.150635 1340.613037,2060.599854 
	C1340.934570,2058.876709 1342.269897,2058.627930 1343.617920,2058.984375 
	C1351.823120,2061.154541 1360.299072,2061.828369 1368.536011,2062.834473 
	C1368.947144,2066.335938 1366.375732,2066.503418 1364.941772,2067.594971 
	C1361.695312,2070.065674 1360.807007,2072.655518 1365.510864,2073.792725 
	C1371.307129,2075.194336 1370.198853,2082.960449 1376.306030,2084.351807 
	C1378.165649,2084.775391 1376.200317,2087.791016 1373.906616,2087.838867 
	C1368.610229,2087.949463 1363.257812,2088.690674 1358.046875,2087.014648 
	C1353.186279,2085.451172 1348.363770,2085.867432 1341.987671,2086.705811 
	C1346.454102,2091.679199 1350.991333,2089.314941 1354.775269,2090.014648 
	C1356.361938,2090.308105 1358.394775,2089.933594 1358.851440,2092.004395 
	C1359.275513,2093.927002 1357.571411,2094.673828 1356.216431,2095.680176 
	C1352.200317,2098.663330 1352.585449,2101.966797 1357.683105,2103.344727 
	C1363.646362,2104.956299 1369.121826,2107.450928 1376.193359,2109.791260 
	C1371.803467,2112.741943 1368.723755,2112.002441 1365.652344,2110.871338 
	C1358.505493,2108.239258 1351.255005,2112.358643 1344.056641,2110.525635 
	C1342.910889,2110.233643 1340.898315,2111.676758 1341.156982,2112.739502 
	C1342.826782,2119.599365 1337.148560,2119.932373 1332.882812,2122.029053 
	C1346.280396,2126.107178 1358.281128,2133.617920 1372.754639,2132.096191 
	C1378.353027,2131.507568 1384.065308,2131.984375 1389.725586,2132.012207 
	C1392.062622,2132.023682 1394.473267,2131.964355 1395.862305,2135.427490 
	C1398.670288,2141.427979 1393.377075,2143.393066 1391.801758,2146.880615 
	C1394.633057,2146.092041 1397.970459,2143.572754 1399.591797,2149.347900 
	C1397.834351,2157.985107 1391.986816,2163.142090 1386.252808,2169.174316 
	C1384.359741,2171.278076 1382.432861,2172.251465 1380.184326,2173.852783 
	C1379.348389,2174.496826 1378.998535,2174.737061 1378.083252,2175.275879 
	C1376.722778,2175.909180 1375.912720,2176.203369 1374.458984,2176.638672 
	C1371.218750,2177.558105 1369.244141,2179.591553 1365.332520,2180.185059 
z"
        />
        <path
          fill="#728DA3"
          opacity="1.000000"
          stroke="none"
          d="
M1319.476685,1981.546021 
	C1324.633423,1987.729614 1328.583252,1989.344116 1334.029785,1986.457397 
	C1337.263428,1984.743652 1339.929565,1983.915527 1343.338623,1985.401855 
	C1345.563721,1986.372192 1347.874878,1985.728760 1350.153687,1984.998535 
	C1351.697998,1984.503784 1354.043091,1983.658203 1354.912354,1984.358032 
	C1361.633423,1989.768677 1369.609375,1986.130859 1376.810791,1987.729858 
	C1378.724121,1988.154663 1380.985229,1988.482910 1382.747681,1987.873901 
	C1392.825928,1984.391113 1402.829468,1986.267944 1412.883423,1987.744751 
	C1414.136475,1987.928955 1415.941284,1987.947021 1416.711914,1987.218384 
	C1420.557129,1983.582886 1424.895264,1984.944580 1428.900024,1986.064819 
	C1437.257446,1988.402344 1446.119019,1987.947998 1454.311279,1990.890015 
	C1457.368042,1991.987671 1459.793945,1990.575195 1461.582031,1988.878540 
	C1465.823242,1984.854126 1470.112305,1982.614624 1475.869263,1985.561279 
	C1476.685547,1985.979126 1478.136963,1986.202026 1478.775269,1985.762085 
	C1485.928101,1980.830566 1495.248779,1984.144897 1502.369507,1979.543457 
	C1506.068604,1977.152954 1508.948364,1979.105347 1511.447998,1981.011230 
	C1516.208008,1984.640381 1520.379761,1984.980225 1524.625488,1980.450928 
	C1526.899658,1978.024780 1529.269165,1978.241821 1531.517090,1980.557983 
	C1533.702637,1982.809814 1536.395264,1984.781128 1539.450928,1983.877075 
	C1550.757935,1980.531372 1562.242065,1984.194824 1573.623657,1982.610352 
	C1579.348633,1981.813110 1585.280762,1983.520264 1590.982544,1980.691895 
	C1596.239624,1978.083862 1596.460571,1977.817993 1591.608154,1973.808716 
	C1590.591309,1972.968750 1589.622803,1972.070435 1588.306152,1970.606934 
	C1590.739014,1966.938354 1594.190674,1967.027710 1598.580811,1968.185913 
	C1606.175659,1981.112915 1617.975952,1982.778198 1630.576172,1982.795776 
	C1636.844604,1982.804565 1643.114746,1982.713867 1650.112793,1982.988403 
	C1663.494263,1985.368652 1676.106323,1988.386963 1688.888916,1983.503662 
	C1690.984131,1982.703247 1693.475220,1983.386719 1695.778076,1983.733643 
	C1711.510132,1986.103027 1713.421753,1984.221680 1709.493164,1970.052734 
	C1717.783325,1967.049805 1724.871826,1969.226929 1731.409912,1973.628540 
	C1735.073608,1976.094971 1738.244385,1979.238892 1737.411377,1984.219482 
	C1736.597534,1989.084961 1732.918701,1991.143799 1728.554688,1992.548706 
	C1718.954346,1995.639282 1718.995117,1995.713135 1723.543945,2004.362793 
	C1726.608887,2010.191162 1728.802612,2016.502197 1733.644531,2021.318970 
	C1735.309326,2022.975098 1736.739502,2026.139282 1735.280518,2027.660767 
	C1727.328247,2035.952393 1732.673096,2048.505127 1725.382324,2056.998535 
	C1722.775024,2060.035889 1722.111572,2064.252441 1723.267334,2068.347656 
	C1724.580811,2073.002441 1723.725708,2077.541260 1722.759277,2082.807861 
	C1722.182373,2086.541504 1722.346680,2089.443604 1724.862793,2091.594971 
	C1731.985352,2097.685303 1730.125732,2102.557861 1722.918457,2106.778564 
	C1721.488770,2107.615723 1720.532104,2108.976074 1718.726562,2110.438965 
	C1711.956177,2112.577148 1709.476929,2109.871826 1711.595337,2104.215820 
	C1713.553223,2098.989014 1712.247314,2096.345703 1707.502441,2093.931152 
	C1701.544800,2090.899902 1701.340576,2086.064209 1706.011841,2081.868164 
	C1711.401123,2077.026611 1711.940430,2072.876465 1706.504150,2067.842285 
	C1704.395874,2065.889893 1703.844482,2062.565186 1705.851929,2060.520020 
	C1713.363281,2052.868164 1709.917725,2044.209351 1708.006958,2036.101562 
	C1706.632446,2030.269043 1706.964478,2026.374023 1715.531494,2023.790894 
	C1706.277466,2023.462646 1704.343506,2020.033203 1704.816284,2013.417969 
	C1705.501343,2003.831543 1698.321167,1997.828369 1688.841431,1999.488403 
	C1683.620605,2000.402710 1678.601807,2002.403320 1673.146240,2001.102661 
	C1671.322144,2000.667847 1669.293213,2001.433594 1669.838989,2003.489990 
	C1671.301514,2009.002319 1662.631226,2013.403076 1668.270630,2019.121826 
	C1669.659546,2020.530029 1671.604248,2021.314209 1673.107788,2022.640259 
	C1676.542480,2025.669556 1678.916748,2029.249878 1677.647461,2034.026855 
	C1676.272949,2039.199951 1672.182129,2042.008057 1667.296631,2041.920654 
	C1660.960815,2041.807251 1657.175171,2041.690063 1657.484741,2050.387695 
	C1657.754517,2057.963135 1645.072388,2060.469727 1638.309937,2054.582520 
	C1635.312500,2051.972900 1633.434204,2048.107422 1629.763550,2046.279663 
	C1625.749390,2044.281128 1621.557373,2042.730103 1622.813721,2050.242432 
	C1623.271851,2052.981445 1623.685181,2056.198242 1620.651489,2057.197266 
	C1613.617188,2059.513916 1615.087158,2064.125488 1616.560913,2069.148193 
	C1618.542603,2075.902344 1618.664062,2082.965332 1619.367798,2089.895996 
	C1619.940918,2095.540283 1618.465088,2100.664795 1610.677490,2101.583984 
	C1604.050903,2102.961182 1598.068970,2105.342773 1591.619629,2101.064453 
	C1590.604614,2100.532959 1590.203125,2100.317139 1589.229248,2099.739258 
	C1585.534790,2096.734863 1581.844238,2095.458496 1577.167480,2094.881592 
	C1575.032471,2094.816162 1573.599121,2094.655518 1571.397949,2094.462891 
	C1558.805664,2096.014648 1547.782837,2093.588135 1536.994263,2088.593994 
	C1529.747803,2085.239502 1522.456177,2085.388916 1514.108032,2087.143066 
	C1501.511230,2089.790527 1488.903809,2095.870850 1474.748535,2091.142578 
	C1472.009277,2087.816895 1469.345581,2085.225342 1471.436401,2081.054443 
	C1461.046021,2082.369385 1461.046021,2082.369385 1454.605103,2077.640869 
	C1459.271729,2074.899902 1464.666138,2076.449707 1468.932373,2074.068848 
	C1470.664917,2073.102051 1473.089355,2072.668213 1472.821289,2070.283936 
	C1472.517578,2067.583496 1469.885498,2068.327637 1468.033203,2068.006592 
	C1464.775146,2067.441895 1459.157104,2069.390869 1459.596436,2064.129883 
	C1459.917480,2060.286133 1465.183472,2062.378174 1468.247925,2062.041016 
	C1473.498779,2061.462402 1478.921143,2062.989990 1484.052979,2060.818359 
	C1485.623047,2060.153809 1487.322388,2059.194336 1486.921509,2057.283691 
	C1486.458130,2055.075195 1483.997925,2053.426270 1482.417969,2054.341309 
	C1470.379028,2061.313965 1458.743164,2051.660400 1446.743652,2053.748047 
	C1441.872681,2054.595703 1436.981201,2055.547607 1432.101196,2056.434570 
	C1430.517822,2056.722168 1428.965942,2057.758789 1429.174927,2059.844482 
	C1429.350098,2061.593994 1430.880615,2061.599854 1432.136353,2061.887695 
	C1432.456665,2061.961182 1432.799683,2061.937988 1433.132446,2061.954590 
	C1436.437134,2062.120361 1440.069702,2061.728760 1441.431641,2065.855957 
	C1442.424561,2068.864746 1439.235596,2075.749756 1436.029053,2077.923828 
	C1428.138550,2083.273682 1423.598999,2082.665771 1415.205078,2075.623535 
	C1411.775635,2072.746338 1407.480713,2072.129639 1403.050415,2071.957275 
	C1398.538818,2071.781494 1393.854980,2072.955078 1388.741699,2070.684326 
	C1391.280151,2066.153320 1395.104370,2066.011719 1399.003174,2065.992188 
	C1404.666138,2065.964111 1410.346436,2066.166748 1415.985962,2065.782227 
	C1419.719360,2065.527588 1420.393921,2063.481201 1418.138062,2060.406982 
	C1417.366211,2059.354980 1416.064331,2058.692383 1415.291870,2057.641113 
	C1414.034912,2055.930420 1413.755493,2053.908936 1414.783447,2051.998779 
	C1415.920898,2049.885254 1418.146484,2049.774414 1420.051025,2050.173584 
	C1433.621948,2053.018555 1446.631958,2047.743286 1459.996948,2047.303711 
	C1463.980713,2047.172852 1467.715088,2047.255493 1471.606689,2047.809814 
	C1475.547119,2048.371094 1478.925903,2047.468018 1479.842529,2042.705933 
	C1480.863037,2037.403809 1479.152954,2033.829956 1474.399536,2032.582031 
	C1472.189087,2032.001709 1469.787720,2032.063965 1467.468384,2032.001343 
	C1463.804443,2031.902466 1460.685791,2030.935547 1460.249634,2026.742798 
	C1459.878052,2023.171631 1458.961670,2018.839478 1463.151733,2017.020020 
	C1466.701904,2015.478394 1470.518921,2014.853149 1473.955444,2018.663818 
	C1477.815186,2022.943726 1481.047729,2022.543945 1484.323120,2018.437012 
	C1485.432495,2017.045898 1486.113892,2015.439331 1485.220581,2013.855347 
	C1484.495239,2012.568970 1483.029541,2012.705566 1481.671875,2013.159302 
	C1476.739868,2014.807495 1473.819336,2013.481812 1473.999878,2007.808228 
	C1474.164062,2002.652954 1470.897827,2000.421143 1466.544922,2000.257202 
	C1457.229858,1999.906250 1447.893799,2000.085571 1438.566406,2000.106079 
	C1436.370850,2000.110962 1434.615845,2001.075928 1434.248413,2003.413574 
	C1433.557495,2007.809692 1431.123047,2011.459351 1429.341919,2015.384888 
	C1427.495483,2019.454712 1428.412598,2021.781250 1433.156982,2022.030273 
	C1435.236084,2022.139404 1437.490112,2022.572021 1437.473022,2025.129639 
	C1437.451172,2028.386108 1435.015503,2029.910156 1432.110596,2029.832153 
	C1423.418823,2029.599243 1414.865845,2030.550659 1406.394775,2032.332153 
	C1403.478516,2032.945312 1400.702148,2034.029541 1397.619507,2034.027832 
	C1394.118530,2034.026001 1391.850708,2032.576172 1390.903076,2029.289673 
	C1390.073486,2026.412720 1392.273682,2025.265747 1394.194824,2023.851562 
	C1398.096802,2020.979614 1401.982422,2019.340576 1407.131714,2020.562622 
	C1413.485474,2022.070312 1414.636841,2021.088379 1413.218140,2015.126709 
	C1412.112305,2010.479248 1412.437134,2008.014404 1417.731567,2007.915161 
	C1419.486938,2007.882324 1421.129639,2007.266235 1421.472046,2005.323853 
	C1421.873657,2003.045654 1420.481567,2001.608643 1418.619019,2000.564697 
	C1412.576172,1997.177246 1404.874756,2000.138306 1402.767578,2008.541138 
	C1401.343994,2014.218140 1398.663208,2015.769287 1393.956543,2017.256226 
	C1389.241699,2018.745728 1384.968994,2021.184204 1382.659668,2026.320557 
	C1380.475220,2031.178833 1376.168091,2032.657715 1370.957764,2031.361572 
	C1369.042725,2030.885132 1366.500854,2029.596558 1365.240112,2030.348022 
	C1355.142700,2036.365967 1346.659424,2030.082520 1337.796387,2027.094482 
	C1335.306641,2026.255127 1333.130493,2025.903442 1331.518921,2028.067993 
	C1329.593140,2030.654419 1331.328125,2032.527344 1333.540894,2033.669189 
	C1337.728027,2035.829468 1340.419556,2041.905884 1347.222046,2037.214478 
	C1349.995972,2035.301270 1354.270020,2039.666260 1357.200928,2042.086060 
	C1358.334106,2043.021606 1356.962891,2045.791382 1355.101318,2045.575317 
	C1344.419312,2044.335693 1332.890625,2045.636597 1324.146240,2037.598389 
	C1320.772339,2034.496704 1321.654663,2028.401733 1316.776611,2025.612061 
	C1315.473389,2024.866699 1317.313232,2022.723267 1318.996826,2022.097168 
	C1321.270020,2021.251831 1323.509277,2020.300537 1323.157593,2017.347900 
	C1322.998535,2016.012695 1321.856323,2014.991333 1320.523438,2014.588013 
	C1317.362183,2013.631470 1315.465454,2015.369995 1314.083252,2017.905151 
	C1312.242920,2021.280151 1309.501465,2023.559448 1305.927979,2024.896729 
	C1304.529785,2025.420044 1303.176880,2025.286987 1302.661255,2023.827271 
	C1302.396484,2023.077637 1302.757080,2021.832520 1303.273193,2021.135986 
	C1304.521484,2019.450928 1308.199097,2019.747681 1307.358643,2016.359009 
	C1306.655640,2013.524780 1303.550903,2014.099976 1301.747070,2012.779663 
	C1300.704590,2012.016846 1299.417847,2011.531982 1299.307617,2009.140869 
	C1306.676392,2006.710327 1313.986084,2007.766479 1320.982300,2010.744263 
	C1325.998535,2012.879517 1330.470825,2014.367065 1336.230713,2012.608398 
	C1340.710938,2011.240356 1345.897827,2013.672119 1350.840454,2012.357666 
	C1352.010376,2012.046509 1353.347290,2013.258667 1353.385132,2014.833618 
	C1353.420654,2016.306152 1352.585571,2017.215454 1351.144043,2017.662231 
	C1348.641968,2018.438232 1345.737183,2017.248169 1343.481934,2019.237305 
	C1350.972778,2024.963867 1357.965210,2024.883789 1365.203247,2020.100952 
	C1369.791138,2017.069580 1372.885498,2011.953613 1379.046997,2010.916138 
	C1381.679077,2010.472778 1381.747192,2004.050537 1379.483887,2000.237793 
	C1377.817627,1997.430908 1375.489624,1998.071411 1373.591919,1999.670044 
	C1370.828491,2001.997925 1367.302490,2001.664795 1364.266968,2002.916138 
	C1360.511108,2004.464355 1357.172119,2004.167603 1354.174194,2001.192139 
	C1352.531250,1999.561646 1350.531616,1997.411621 1348.167236,1999.348267 
	C1338.471802,2007.289917 1327.820801,2005.204834 1317.104736,2002.659546 
	C1314.457031,2002.030640 1311.692749,2001.478882 1309.211914,2002.266968 
	C1304.283691,2003.832886 1300.304199,2004.309937 1297.859985,1998.394043 
	C1296.814331,1995.863281 1294.509155,1996.737671 1293.059326,1998.457642 
	C1291.515991,2000.288574 1289.866333,2002.716064 1287.427856,2001.723999 
	C1281.539673,1999.328613 1278.291504,2002.608887 1274.599487,2006.254150 
	C1270.011108,2010.784668 1268.584473,2010.479736 1265.682007,2005.166626 
	C1263.732300,2001.597534 1263.449951,1996.338867 1256.822632,1999.277588 
	C1253.503418,2000.749390 1248.429688,1996.945801 1246.548096,2003.578247 
	C1245.663330,2006.696289 1241.619141,2006.174927 1238.582275,2005.204834 
	C1232.852661,2003.374756 1227.299194,2001.218018 1222.406738,1997.579346 
	C1219.403687,1995.345825 1216.355591,1993.800415 1212.280029,1995.577515 
	C1208.859619,1997.068604 1206.828491,1998.431152 1209.937988,2001.939575 
	C1211.682739,2003.908203 1211.347778,2005.644165 1209.367920,2006.917847 
	C1207.449341,2008.151978 1205.367920,2007.872559 1204.330322,2005.658569 
	C1203.208740,2003.265503 1201.611084,2001.772949 1198.972656,2002.085693 
	C1191.852173,2002.929932 1185.902710,2000.787109 1180.945068,1995.626953 
	C1179.385498,1994.003662 1176.334839,1994.690308 1174.861938,1991.394165 
	C1178.866089,1988.917358 1183.291504,1985.937500 1187.321411,1984.469238 
	C1192.817627,1982.466675 1199.384644,1980.674316 1205.789062,1984.593018 
	C1212.477539,1988.685425 1219.349854,1990.791870 1227.991943,1987.851562 
	C1233.828857,1985.865845 1240.978271,1982.810547 1247.947388,1988.540161 
	C1252.218140,1992.051392 1254.278809,1990.207275 1255.995605,1982.747070 
	C1256.739380,1981.323853 1257.567871,1980.848633 1259.218628,1980.543091 
	C1268.036011,1986.541016 1276.574585,1986.802856 1286.139893,1982.192627 
	C1290.793335,1980.882935 1294.677002,1981.073486 1299.076172,1983.118896 
	C1305.362915,1987.390381 1310.302002,1985.460449 1315.615967,1981.265381 
	C1317.149292,1980.776001 1318.058105,1980.817139 1319.476685,1981.546021 
z"
        />
        <path
          fill="#6E8AA1"
          opacity="1.000000"
          stroke="none"
          d="
M1600.644043,1970.483643 
	C1596.573242,1970.004517 1593.136719,1970.004150 1588.840332,1970.009277 
	C1586.491089,1970.015137 1585.001587,1970.015625 1582.767334,1970.017822 
	C1575.788818,1972.635620 1570.802979,1968.247192 1564.619141,1966.520996 
	C1555.567505,1966.916992 1546.695190,1963.640625 1538.735596,1969.218872 
	C1536.319702,1970.912109 1533.359253,1969.102539 1531.351807,1967.499390 
	C1527.393799,1964.338623 1522.994873,1964.020996 1518.229980,1963.916870 
	C1511.462646,1963.768921 1504.399780,1966.996704 1497.945679,1962.433838 
	C1497.706177,1962.264404 1497.170044,1962.174683 1496.982666,1962.319824 
	C1488.071045,1969.213623 1478.497681,1962.066895 1469.240723,1963.524414 
	C1464.871582,1964.212402 1460.115967,1962.480469 1455.541138,1961.805786 
	C1454.169678,1961.603516 1452.980591,1961.012573 1452.614990,1959.522705 
	C1452.162964,1957.680542 1452.968628,1956.180664 1454.409302,1955.230103 
	C1456.862305,1953.611572 1459.470215,1953.778931 1461.941284,1955.300049 
	C1463.634888,1956.342773 1465.153809,1957.721313 1467.279419,1957.828491 
	C1469.764526,1957.953857 1472.003418,1957.438843 1473.067139,1954.898193 
	C1473.795410,1953.158569 1468.821045,1940.841919 1467.075562,1939.694336 
	C1462.777954,1936.869385 1457.248169,1937.696045 1452.887207,1941.815430 
	C1448.017700,1946.415527 1447.488892,1946.737305 1444.212158,1941.356323 
	C1441.855713,1937.486450 1438.819946,1938.056030 1435.654663,1938.043823 
	C1416.324219,1937.969360 1396.993408,1937.995972 1377.662720,1937.989014 
	C1374.370361,1937.987915 1371.077881,1937.988770 1367.602295,1937.988770 
	C1366.821167,1933.334229 1373.277710,1933.486206 1371.970215,1928.728149 
	C1372.419922,1922.626343 1376.887573,1923.352539 1380.164917,1923.010376 
	C1386.424438,1922.356689 1392.799683,1922.247314 1399.035278,1922.864624 
	C1414.811646,1924.426270 1430.065918,1921.571045 1445.291748,1918.229126 
	C1453.935303,1916.331909 1462.162109,1917.005615 1470.636230,1919.838867 
	C1485.335083,1924.753174 1500.620850,1926.781982 1516.900879,1926.489746 
	C1519.831543,1925.432617 1522.063965,1925.341675 1525.148193,1925.572266 
	C1530.153687,1927.094482 1534.504517,1926.441528 1538.775024,1926.754639 
	C1549.533325,1927.543091 1559.954346,1927.558228 1570.310913,1933.188599 
	C1580.348267,1938.645508 1591.949463,1935.711060 1603.142700,1932.378296 
	C1623.488037,1926.320190 1644.078125,1925.471680 1664.580688,1935.354614 
	C1667.119385,1944.288574 1663.432373,1948.046631 1654.578979,1948.229614 
	C1648.990967,1946.013550 1644.281494,1942.748657 1638.377441,1947.342407 
	C1627.468628,1950.456177 1616.472168,1947.889404 1605.555420,1952.260864 
	C1608.130493,1955.440186 1611.813477,1955.711182 1614.921875,1956.950806 
	C1617.687988,1958.053955 1621.057861,1958.844604 1620.741211,1962.691406 
	C1620.461304,1966.091187 1617.712036,1967.723511 1614.681885,1968.501709 
	C1612.100220,1969.164795 1609.534546,1969.766724 1606.523438,1971.422363 
	C1604.219604,1971.908447 1602.706055,1971.717163 1600.644043,1970.483643 
z"
        />
        <path
          fill="#1B1E22"
          opacity="1.000000"
          stroke="none"
          d="
M1473.569580,2091.003906 
	C1489.539917,2089.781494 1504.578735,2086.660889 1519.520508,2082.899902 
	C1525.515015,2081.391113 1532.086060,2081.974854 1536.952515,2084.785889 
	C1547.674316,2090.979004 1558.861450,2092.606689 1571.067383,2092.498779 
	C1572.734253,2103.157227 1572.486206,2103.395020 1562.388672,2101.808838 
	C1559.491333,2101.353760 1556.622925,2101.131104 1553.628418,2103.621582 
	C1546.687012,2113.704590 1546.664429,2113.753662 1535.908203,2108.811035 
	C1531.944458,2106.989746 1526.243042,2109.594727 1523.781250,2103.763916 
	C1522.942993,2101.778320 1523.207520,2104.100830 1522.904297,2104.298096 
	C1514.708618,2109.632568 1510.918579,2108.565186 1504.954712,2098.591553 
	C1501.296509,2102.692627 1497.943115,2107.436523 1491.531128,2106.400635 
	C1490.194092,2106.184570 1488.836182,2107.077881 1488.559570,2108.526855 
	C1486.232544,2120.707764 1474.390991,2123.831299 1466.376953,2131.164551 
	C1464.063110,2130.868408 1462.596191,2130.065430 1461.000366,2129.700684 
	C1459.700562,2129.403564 1461.345703,2129.926758 1460.033569,2129.721924 
	C1459.393433,2129.622070 1459.578613,2129.787598 1461.314819,2129.874023 
	C1465.198242,2131.179932 1468.600464,2132.288330 1467.271851,2136.842529 
	C1466.438477,2139.699463 1464.125000,2141.824463 1461.309937,2140.809326 
	C1457.087524,2139.286621 1456.281372,2141.061035 1455.531860,2144.577148 
	C1454.702271,2148.468750 1455.340576,2151.123779 1459.478760,2152.244629 
	C1460.761963,2152.592041 1462.066650,2152.870605 1463.325684,2153.294434 
	C1465.842285,2154.141357 1468.880005,2154.822998 1468.699219,2158.199219 
	C1468.498169,2161.954346 1465.164429,2162.178223 1462.427612,2162.829834 
	C1464.209229,2162.967529 1465.882690,2161.959961 1467.731689,2161.949951 
	C1471.302490,2161.930176 1475.259033,2162.781006 1475.683105,2166.382812 
	C1476.208984,2170.849365 1472.903198,2174.493408 1469.127319,2176.677734 
	C1465.839722,2178.579346 1463.763428,2176.237549 1462.067749,2173.322021 
	C1458.459473,2167.117432 1452.073730,2166.855469 1446.371582,2167.978271 
	C1438.719604,2169.485107 1436.883301,2166.411133 1437.459717,2160.888428 
	C1423.619263,2160.282227 1416.079834,2171.848145 1405.531494,2178.164062 
	C1403.047241,2179.651611 1402.327881,2182.316162 1401.602539,2184.878906 
	C1400.241089,2189.688721 1397.042603,2189.995605 1392.513916,2188.172852 
	C1391.170410,2187.114258 1390.334473,2186.397705 1389.472778,2185.200928 
	C1389.309937,2184.859619 1388.665283,2184.825928 1388.988770,2185.096436 
	C1389.756226,2185.898926 1390.257568,2186.372559 1391.242920,2187.271973 
	C1395.621826,2192.509521 1402.015625,2191.790039 1406.878784,2194.529297 
	C1410.497559,2196.567871 1413.711914,2198.800293 1413.856812,2203.410156 
	C1414.014404,2208.423340 1410.048706,2208.410400 1406.631226,2209.823242 
	C1402.130737,2211.684082 1398.619019,2215.617676 1393.260010,2217.552734 
	C1388.231323,2231.347656 1387.658936,2231.786377 1375.562988,2230.916016 
	C1370.468262,2236.566162 1364.503418,2237.370361 1357.399414,2236.654053 
	C1355.502808,2236.480225 1354.272339,2236.286865 1352.488037,2236.628418 
	C1351.615845,2237.002686 1351.281982,2237.182861 1350.485107,2237.694336 
	C1349.417114,2238.489502 1348.842163,2238.988281 1347.890015,2239.933594 
	C1347.202148,2240.609619 1346.921753,2240.874023 1346.207153,2241.516113 
	C1344.920776,2242.620117 1343.957153,2243.217041 1342.967651,2244.563477 
	C1342.554688,2245.357666 1342.407715,2245.679932 1342.138428,2246.500000 
	C1341.989136,2248.165039 1342.627686,2248.990479 1343.864990,2250.187500 
	C1345.147095,2251.651855 1345.752319,2252.805664 1346.361938,2253.963379 
	C1353.242798,2267.032715 1351.211182,2270.109375 1334.870361,2270.208984 
	C1333.599854,2269.575684 1333.512573,2268.318359 1332.623535,2268.468506 
	C1332.049072,2268.565186 1332.224365,2269.300537 1333.091675,2270.359619 
	C1330.895386,2277.070557 1328.401733,2282.925781 1319.807129,2282.166016 
	C1318.125122,2278.821777 1319.936646,2276.748779 1321.257690,2273.750488 
	C1321.343262,2269.603516 1321.350464,2266.231934 1323.811523,2262.667969 
	C1329.242188,2259.598877 1333.053589,2258.307617 1331.695068,2249.853760 
	C1330.187500,2240.470703 1337.778198,2232.420410 1345.123657,2226.061279 
	C1347.079346,2224.367920 1349.713135,2223.465820 1351.273071,2220.409912 
	C1351.692871,2218.934082 1351.963013,2218.128906 1352.141846,2216.711426 
	C1356.153198,2215.788330 1355.894409,2212.491211 1356.544922,2209.008301 
	C1359.087646,2204.128418 1358.322998,2195.026611 1367.792847,2202.017090 
	C1369.364624,2207.693848 1368.698242,2213.012451 1370.966187,2217.870361 
	C1371.927490,2214.176758 1371.770386,2209.407959 1377.765747,2208.170898 
	C1380.682129,2207.319580 1382.999756,2207.193848 1385.986328,2207.086426 
	C1389.213867,2207.113770 1391.781860,2207.163818 1394.127441,2204.608398 
	C1395.356689,2197.984619 1390.023926,2198.879639 1386.875366,2197.913818 
	C1381.235718,2196.183594 1375.391479,2195.144775 1371.983887,2188.568848 
	C1370.977051,2183.509277 1370.204834,2179.153320 1373.298340,2174.405762 
	C1374.728271,2173.424316 1375.699341,2173.141357 1377.274048,2173.339111 
	C1380.309937,2176.456543 1377.686890,2179.471680 1377.874756,2180.634766 
	C1377.533569,2179.744141 1379.900146,2177.327637 1378.266479,2174.311035 
	C1377.411011,2170.153320 1380.644897,2170.992188 1383.076660,2169.918457 
	C1398.150635,2167.278076 1401.150391,2164.122559 1399.929688,2152.111816 
	C1398.611938,2146.749023 1400.821411,2142.849609 1403.039917,2138.296875 
	C1403.558105,2137.334229 1403.787354,2136.962646 1404.423096,2136.080078 
	C1405.735107,2134.642822 1406.777710,2133.879150 1408.267334,2132.655518 
	C1410.046997,2131.107178 1411.265991,2129.893799 1413.048828,2128.310059 
	C1417.849365,2126.228760 1421.600830,2128.281982 1424.589600,2130.529297 
	C1431.697876,2135.873291 1437.642212,2135.180908 1443.736328,2128.104248 
	C1445.687012,2126.815674 1447.131470,2126.017578 1448.642578,2125.298096 
	C1455.274414,2122.141602 1453.686401,2119.332275 1448.167603,2116.801758 
	C1445.765869,2115.700439 1442.991333,2114.953613 1443.694092,2110.944580 
	C1446.623047,2108.826172 1449.317993,2107.830322 1453.008301,2107.606201 
	C1461.308472,2103.605713 1464.581665,2094.446045 1473.569580,2091.003906 
z"
        />
        <path
          fill="#8497A7"
          opacity="1.000000"
          stroke="none"
          d="
M1322.013550,2067.504883 
	C1321.703003,2068.944580 1321.621582,2070.074463 1321.033203,2070.783936 
	C1315.435791,2077.532715 1315.389526,2077.585938 1323.829346,2080.043945 
	C1325.912964,2080.650879 1327.762939,2081.133301 1327.442383,2083.756104 
	C1327.166626,2086.013672 1325.581787,2087.353271 1323.478149,2087.644043 
	C1320.520752,2088.052490 1317.497559,2087.969971 1314.531616,2088.334717 
	C1312.819824,2088.545166 1311.198120,2089.606445 1311.161011,2091.437012 
	C1311.117554,2093.585449 1313.043701,2093.885010 1314.735474,2093.916992 
	C1319.391968,2094.005371 1324.063843,2093.790527 1328.455566,2095.867920 
	C1330.010376,2096.603760 1331.542969,2097.210938 1331.402832,2099.280518 
	C1331.294556,2100.878662 1330.070679,2101.597168 1328.746094,2101.799072 
	C1326.252930,2102.179199 1324.308960,2103.228760 1323.276245,2105.579590 
	C1321.706909,2109.151367 1318.865845,2110.206787 1315.196899,2110.085449 
	C1312.698608,2110.002686 1310.532837,2110.753662 1310.202515,2113.770020 
	C1309.855957,2116.934082 1310.223022,2119.857666 1313.516724,2121.373291 
	C1314.704468,2121.919678 1316.175049,2121.855713 1317.355591,2122.411865 
	C1320.542603,2123.913818 1321.111938,2127.089600 1320.886841,2129.965088 
	C1320.676880,2132.647705 1317.745850,2133.228271 1315.544556,2133.659912 
	C1313.290527,2134.101562 1310.769165,2134.406006 1308.599854,2133.851318 
	C1296.443726,2130.742188 1284.086914,2132.449951 1271.827026,2131.983643 
	C1271.161621,2131.958496 1270.481445,2132.064209 1269.830200,2131.964355 
	C1267.155884,2131.554199 1262.848267,2133.092285 1262.931885,2128.713135 
	C1263.000732,2125.115479 1266.959473,2126.100586 1269.426392,2126.053467 
	C1276.420776,2125.920166 1283.419434,2126.020020 1290.416504,2126.015137 
	C1292.748535,2126.013428 1295.084961,2126.048096 1297.411011,2125.917236 
	C1298.686035,2125.845459 1300.199585,2125.824951 1300.694458,2124.250488 
	C1301.346680,2122.175781 1299.881470,2121.009521 1298.340454,2120.546143 
	C1295.519897,2119.697754 1292.689697,2117.409912 1289.754883,2118.587158 
	C1281.810913,2121.773682 1274.749878,2118.594482 1266.748047,2116.112305 
	C1264.164429,2112.302490 1266.814697,2110.933350 1269.460938,2110.582764 
	C1277.878662,2109.466797 1285.464355,2104.057373 1294.468628,2105.909424 
	C1296.766479,2106.381836 1299.123413,2105.632080 1300.893921,2103.849609 
	C1302.034424,2102.701416 1303.504761,2101.591064 1302.667969,2099.639404 
	C1302.046387,2098.189941 1300.606567,2098.119141 1299.311035,2098.081543 
	C1296.318848,2097.994385 1293.318237,2097.900146 1290.330200,2098.025879 
	C1284.898071,2098.254883 1279.653931,2097.871338 1275.247314,2094.197998 
	C1273.056030,2092.371094 1270.505005,2091.900635 1267.779663,2092.003418 
	C1265.033569,2092.106934 1262.512939,2091.373779 1260.157104,2090.026855 
	C1255.795166,2087.533447 1255.534180,2084.796387 1259.637085,2081.650391 
	C1262.530396,2079.431641 1265.519775,2077.920654 1269.707153,2078.730469 
	C1276.354980,2080.015869 1278.251587,2078.095459 1277.867676,2071.774902 
	C1277.768799,2070.147461 1276.868042,2068.559814 1276.820557,2066.939941 
	C1276.720093,2063.521973 1278.676880,2061.525635 1281.770874,2063.324463 
	C1287.316406,2066.548828 1292.984009,2064.765625 1298.726440,2065.485107 
	C1298.689941,2061.095215 1295.506226,2061.138672 1293.285645,2060.074463 
	C1288.769165,2057.910400 1283.976685,2055.973633 1285.041992,2049.451660 
	C1285.509644,2046.588257 1283.477417,2046.370972 1281.412231,2046.004517 
	C1276.045288,2045.052368 1270.768311,2043.363037 1265.084473,2044.458618 
	C1271.842407,2038.404663 1279.874146,2039.894165 1287.533691,2040.398193 
	C1290.523804,2040.594971 1292.954712,2040.485840 1295.956543,2039.672607 
	C1302.616455,2037.868408 1309.610474,2039.389526 1316.015991,2041.662720 
	C1318.959351,2042.707153 1322.010620,2046.624512 1318.065552,2049.429688 
	C1312.994141,2053.035889 1317.261841,2055.651611 1317.945190,2059.358398 
	C1319.157593,2061.658203 1319.368042,2063.867920 1321.562744,2065.565674 
	C1322.002441,2066.326904 1322.004883,2066.663330 1322.013550,2067.504883 
z"
        />
        <path
          fill="#859CAF"
          opacity="1.000000"
          stroke="none"
          d="
M977.495239,1932.556396 
	C980.841187,1932.670898 983.681580,1933.318604 987.257324,1933.980713 
	C988.184814,1938.903198 987.375854,1943.815308 990.741455,1948.344727 
	C993.546814,1952.120117 991.927124,1956.917603 988.894104,1959.216675 
	C983.172058,1963.554688 983.513000,1968.565430 985.494568,1974.463013 
	C980.607178,1977.091064 977.824585,1973.404419 974.529297,1971.706299 
	C969.293579,1969.008179 967.600220,1969.391724 966.154297,1975.066895 
	C965.207764,1978.782104 963.497742,1980.133179 960.032593,1979.967896 
	C954.102478,1979.685059 947.575928,1983.598633 942.500854,1977.046143 
	C941.215637,1975.386841 938.782776,1976.877075 936.867371,1977.341675 
	C931.619141,1978.614380 926.241577,1978.198242 920.968262,1977.869385 
	C913.035767,1977.375000 909.220520,1971.835083 911.745789,1964.325806 
	C913.809753,1958.188477 913.102722,1952.519531 910.024292,1947.242065 
	C907.948669,1943.683716 909.604980,1942.397217 911.859497,1939.873779 
	C917.273987,1933.813599 924.034058,1935.018555 930.850891,1934.535645 
	C941.165649,1933.804932 951.554504,1933.184937 961.679565,1934.635986 
	C967.276855,1935.438354 972.058228,1935.456421 977.495239,1932.556396 
z"
        />
        <path
          fill="#859CAF"
          opacity="1.000000"
          stroke="none"
          d="
M1260.526733,1981.579346 
	C1259.093750,1981.961792 1258.210327,1981.965454 1256.664062,1981.965454 
	C1255.549927,1978.354492 1253.821411,1975.061523 1253.928589,1971.257935 
	C1254.145996,1963.548706 1255.858887,1961.295654 1263.449219,1960.005615 
	C1270.631958,1958.784790 1270.685913,1958.744629 1266.307495,1952.938843 
	C1265.271851,1951.565674 1264.102417,1950.276978 1264.593994,1948.540771 
	C1265.272705,1946.143555 1267.449097,1946.091919 1269.464111,1946.036865 
	C1275.752808,1945.865112 1282.056763,1945.292725 1288.281616,1945.080444 
	C1292.893921,1944.923340 1295.950562,1945.461548 1296.803345,1940.069092 
	C1296.909302,1939.399780 1298.178467,1938.728149 1299.039185,1938.394043 
	C1305.780396,1935.777588 1309.242310,1941.453491 1313.681396,1944.426880 
	C1317.449707,1946.951050 1315.849365,1950.068970 1313.991943,1953.066650 
	C1310.871704,1958.102783 1315.354736,1958.670288 1318.225098,1959.635498 
	C1325.144775,1961.962646 1325.259399,1961.832153 1324.175781,1968.937622 
	C1323.554199,1973.012207 1325.485107,1977.822632 1320.460083,1981.420654 
	C1319.110229,1982.009399 1318.224365,1982.005859 1316.674194,1982.006104 
	C1311.412476,1984.484375 1306.036499,1984.177979 1300.643066,1986.082520 
	C1296.149536,1983.643188 1291.863770,1983.973877 1286.811768,1984.027344 
	C1277.703613,1982.986206 1269.590820,1980.323364 1260.526733,1981.579346 
z"
        />
        <path
          fill="#708497"
          opacity="1.000000"
          stroke="none"
          d="
M1518.140503,1927.264648 
	C1517.031982,1929.799683 1515.661987,1931.931763 1513.549561,1931.232910 
	C1507.493164,1929.229248 1500.869019,1930.934814 1494.980225,1928.050049 
	C1493.220093,1927.187622 1490.798828,1925.922119 1489.332764,1926.499146 
	C1480.302246,1930.052490 1473.555542,1924.130371 1466.082275,1921.372192 
	C1459.900513,1919.091064 1453.549927,1919.419434 1447.463623,1920.753906 
	C1443.339233,1921.658081 1438.710327,1921.842651 1435.245361,1923.140991 
	C1423.560303,1927.519043 1411.861694,1926.078613 1400.044800,1926.088135 
	C1391.109619,1926.095337 1382.111328,1925.002563 1372.606323,1927.438232 
	C1367.771606,1928.267700 1363.413208,1927.236084 1358.672363,1929.665283 
	C1355.072754,1933.565674 1350.884888,1935.122559 1346.465942,1937.517578 
	C1342.562012,1937.977295 1339.136963,1937.983765 1334.855957,1937.985596 
	C1329.583496,1939.293823 1326.042480,1937.040527 1321.942627,1934.539062 
	C1321.444702,1933.439209 1321.419556,1932.937256 1321.801270,1931.807129 
	C1323.011108,1930.581543 1323.884521,1929.810669 1324.825806,1929.821777 
	C1334.904419,1929.940063 1341.819946,1928.265625 1339.899536,1914.890747 
	C1338.825684,1907.411865 1346.191895,1908.131470 1352.346191,1909.387939 
	C1356.019653,1910.384033 1359.030151,1911.065552 1362.801758,1910.177246 
	C1365.952393,1909.373901 1368.573242,1909.015991 1371.285645,1907.005249 
	C1372.708130,1906.363281 1373.562500,1906.106445 1375.099365,1905.816406 
	C1382.956055,1902.168213 1389.271851,1905.351318 1396.229370,1908.595459 
	C1400.550903,1912.899292 1405.340576,1913.452759 1411.092041,1913.111084 
	C1416.433472,1913.420410 1420.903442,1912.519043 1425.952881,1910.836670 
	C1427.087402,1910.601318 1427.547119,1910.536133 1428.702026,1910.450684 
	C1431.687744,1910.772339 1433.970337,1910.900269 1436.942871,1910.069580 
	C1439.184082,1909.852539 1440.682007,1910.006714 1442.921143,1910.567139 
	C1462.036255,1913.858887 1480.628540,1912.168457 1499.825439,1913.765869 
	C1503.603149,1915.041382 1504.553955,1918.618530 1508.169189,1920.074341 
	C1511.251831,1923.591797 1516.252075,1922.756958 1518.140503,1927.264648 
z"
        />
        <path
          fill="#70889D"
          opacity="1.000000"
          stroke="none"
          d="
M900.033691,2165.171387 
	C894.569336,2166.025391 889.340515,2166.027588 887.611572,2158.984375 
	C886.658142,2155.100342 882.831909,2156.467285 880.037170,2155.955811 
	C875.433228,2155.113525 871.029297,2158.708252 866.389099,2156.382080 
	C864.966797,2155.668945 862.936768,2155.627686 863.004761,2153.442139 
	C863.048035,2152.055176 864.124084,2151.101807 865.324524,2150.454102 
	C868.566650,2148.704346 871.968567,2146.603027 875.429138,2149.565186 
	C879.565735,2153.105469 883.941956,2152.103027 888.301270,2151.374023 
	C889.353882,2148.815918 887.782288,2147.899658 886.325012,2147.267578 
	C881.729126,2145.273682 879.547607,2142.591309 881.645203,2137.221191 
	C882.720764,2134.467529 882.292175,2131.248291 878.632324,2129.783691 
	C877.794678,2129.448242 877.033203,2128.621094 876.516296,2127.836182 
	C873.592102,2123.395996 874.385254,2121.180420 879.674744,2119.183594 
	C881.202026,2118.606934 882.871704,2118.407227 885.238037,2118.017578 
	C887.984802,2121.311768 890.936584,2124.080811 889.045532,2128.825195 
	C887.988098,2131.478271 890.663574,2131.815186 892.811279,2131.383545 
	C893.447327,2131.255859 894.202087,2131.036621 894.617432,2130.592773 
	C903.326416,2121.285645 914.336975,2127.190430 924.256104,2126.329590 
	C928.841003,2125.931885 929.275757,2127.926514 925.944824,2132.348633 
	C925.066956,2133.513672 924.661804,2134.459229 924.935852,2135.865723 
	C925.695251,2139.763184 924.017456,2139.998291 921.243652,2138.147461 
	C919.826965,2137.201904 918.356689,2136.136475 916.692261,2136.862305 
	C914.158569,2137.967041 910.320679,2137.695801 910.285217,2141.900146 
	C910.253662,2145.643066 913.102478,2145.695312 915.811462,2146.093750 
	C919.398438,2146.621338 922.949341,2147.403320 926.506165,2148.123047 
	C929.042419,2148.636475 931.683594,2149.043213 932.873108,2152.532959 
	C930.363281,2154.703125 927.176147,2154.341797 924.536682,2153.876709 
	C916.501038,2152.460938 909.939270,2157.265869 902.571228,2158.564209 
	C899.667542,2159.075684 900.180908,2162.107422 900.033691,2165.171387 
z"
        />
        <path
          fill="#778FA4"
          opacity="1.000000"
          stroke="none"
          d="
M963.954590,2053.685059 
	C974.642395,2051.492188 984.720276,2049.855713 994.802917,2048.249268 
	C995.444336,2048.146973 996.192566,2048.094238 996.772400,2048.321777 
	C1002.971069,2050.752686 1002.255798,2057.650635 1005.388550,2062.134033 
	C1007.443604,2065.075439 1004.932007,2068.426025 1002.083679,2070.617188 
	C998.479248,2073.389893 994.363953,2074.195801 989.893066,2074.052979 
	C987.600220,2073.979492 985.265564,2074.310547 982.994751,2074.711914 
	C980.820435,2075.096436 978.503662,2076.883545 976.808960,2074.067871 
	C975.232483,2071.449219 978.936096,2070.219238 978.641907,2067.131592 
	C975.935852,2065.132324 972.039124,2065.849854 968.746582,2064.693359 
	C961.830017,2062.263672 961.730103,2062.548096 963.954590,2053.685059 
z"
        />
        <path
          fill="#9DAAB4"
          opacity="1.000000"
          stroke="none"
          d="
M1148.095947,1946.736816 
	C1147.956909,1944.585205 1147.933472,1943.170532 1147.882080,1941.049072 
	C1148.445435,1939.556030 1149.214844,1939.063477 1150.897949,1939.012085 
	C1154.781494,1941.023315 1156.357422,1944.458130 1158.970825,1946.912476 
	C1161.015747,1948.832764 1162.516479,1952.320679 1166.118408,1950.628174 
	C1169.533447,1949.023560 1169.015625,1945.913696 1167.955322,1942.945190 
	C1167.354492,1941.263062 1167.421143,1939.755371 1169.677979,1938.233643 
	C1175.338379,1936.398438 1180.240112,1936.161255 1185.503296,1939.477051 
	C1186.049561,1940.987793 1186.042114,1941.851074 1186.040527,1943.362061 
	C1180.905273,1943.186035 1178.345703,1945.506958 1176.674072,1950.388184 
	C1174.476074,1956.806641 1173.738647,1963.247437 1173.951050,1969.924561 
	C1174.062378,1973.426392 1173.497192,1976.991577 1169.316162,1977.551147 
	C1164.810791,1978.154297 1160.042480,1979.195068 1155.856079,1976.253052 
	C1155.196167,1975.789307 1154.533813,1974.049561 1154.826782,1973.700317 
	C1159.908203,1967.643188 1153.499878,1963.559692 1152.253052,1958.667847 
	C1151.278076,1954.841797 1149.585449,1951.198364 1148.095947,1946.736816 
z"
        />
        <path
          fill="#70889D"
          opacity="1.000000"
          stroke="none"
          d="
M1033.096558,2096.026367 
	C1035.710449,2098.160400 1038.101440,2098.439941 1040.283691,2097.521973 
	C1043.338501,2096.237305 1046.026855,2097.297119 1048.929443,2097.796143 
	C1052.018066,2098.327393 1054.057495,2099.126709 1052.763306,2102.817383 
	C1051.738892,2105.738281 1055.438965,2107.278809 1054.934814,2110.409180 
	C1052.521851,2112.999512 1049.757935,2111.615967 1047.057007,2110.625000 
	C1042.059814,2108.791504 1036.986938,2107.762695 1031.588013,2107.929199 
	C1025.798828,2108.107910 1020.021729,2108.947266 1015.299194,2103.370605 
	C1012.762451,2100.374512 1007.718689,2101.900879 1003.844116,2102.099854 
	C998.797607,2102.359619 994.788330,2100.572266 991.468079,2097.121826 
	C990.385803,2095.997070 991.335510,2094.749268 993.395386,2094.104980 
	C996.782410,2093.997559 999.555054,2094.004883 1003.159729,2094.016602 
	C1006.861572,2095.004150 1010.114075,2092.550293 1013.423584,2095.480225 
	C1020.080750,2095.977539 1026.134644,2095.998291 1033.096558,2096.026367 
z"
        />
        <path
          fill="#626E74"
          opacity="1.000000"
          stroke="none"
          d="
M1446.319214,2129.414307 
	C1442.434937,2132.978027 1439.457275,2137.126953 1435.446899,2138.799072 
	C1430.493774,2140.864502 1428.548950,2133.620361 1423.847412,2132.880859 
	C1420.645874,2132.377441 1418.308472,2129.330078 1414.415039,2129.597412 
	C1412.672607,2131.025146 1411.101074,2131.811523 1410.314331,2134.163818 
	C1409.296875,2135.766357 1408.211792,2136.009521 1406.427490,2135.819824 
	C1403.665894,2134.026611 1404.112671,2131.605713 1404.130127,2128.621826 
	C1405.789917,2125.532471 1408.914307,2126.240479 1411.502441,2124.359863 
	C1412.578247,2119.745361 1413.103638,2115.515869 1419.236450,2116.280762 
	C1421.659424,2116.583008 1419.847290,2111.818359 1423.695312,2112.513184 
	C1427.821289,2112.480957 1431.412231,2111.590088 1435.461426,2113.282227 
	C1435.236816,2116.635010 1437.199463,2119.855469 1434.948486,2122.606689 
	C1439.491455,2123.623291 1447.613647,2117.907471 1446.319214,2129.414307 
z"
        />
        <path
          fill="#D7D5CF"
          opacity="1.000000"
          stroke="none"
          d="
M1354.421387,1910.503662 
	C1353.215088,1911.237061 1352.570435,1911.377563 1351.960938,1911.609741 
	C1348.908691,1912.772339 1344.957764,1908.188599 1342.997314,1911.881104 
	C1341.106689,1915.442383 1340.120239,1920.768799 1342.293335,1924.090942 
	C1345.467773,1928.943481 1342.681030,1929.636230 1339.437134,1930.657227 
	C1334.348755,1932.258667 1329.000244,1931.475098 1322.925049,1932.222656 
	C1321.732056,1932.598022 1321.406982,1932.805908 1320.524170,1933.172852 
	C1319.582153,1933.280396 1319.198120,1933.228760 1318.377930,1932.792847 
	C1317.476318,1931.880127 1317.010620,1931.351685 1316.204346,1930.418701 
	C1316.841919,1925.674683 1320.194580,1925.588989 1323.650024,1925.642334 
	C1326.972290,1925.693481 1330.401123,1926.226318 1333.589478,1925.584839 
	C1337.107910,1924.876953 1339.335815,1922.804443 1334.517456,1919.227051 
	C1330.085571,1915.186279 1331.083740,1910.535400 1331.411621,1905.216431 
	C1333.016968,1897.509155 1333.608643,1890.427490 1336.557007,1883.095215 
	C1338.401733,1881.105591 1339.885620,1881.420410 1342.002075,1882.646240 
	C1346.259155,1889.738037 1345.633057,1898.830322 1354.468506,1903.318970 
	C1355.976685,1905.916626 1356.012451,1907.932129 1354.421387,1910.503662 
z"
        />
        <path
          fill="#778FA4"
          opacity="1.000000"
          stroke="none"
          d="
M1423.452148,2112.035156 
	C1421.829468,2114.562988 1421.688110,2119.466309 1416.275024,2118.704346 
	C1414.306152,2118.427246 1414.331543,2121.734863 1412.610596,2123.634277 
	C1406.728516,2119.609863 1400.967529,2117.282471 1393.810913,2119.564209 
	C1391.596191,2120.270264 1387.870605,2121.265381 1387.207397,2117.670410 
	C1386.676758,2114.793701 1389.631836,2113.049805 1392.231079,2112.359375 
	C1402.289795,2109.687744 1412.424194,2108.419189 1423.452148,2112.035156 
z"
        />
        <path
          fill="#313E41"
          opacity="1.000000"
          stroke="none"
          d="
M1446.350342,2129.679443 
	C1446.505005,2124.923096 1444.423584,2123.236572 1439.508301,2124.354492 
	C1436.299194,2125.083984 1433.635620,2129.273682 1428.804077,2125.635986 
	C1432.994751,2122.931152 1434.457275,2118.910156 1435.795654,2114.220703 
	C1438.939941,2113.968018 1440.384033,2111.133301 1443.518188,2110.479004 
	C1446.118652,2116.720459 1453.420166,2112.688965 1456.397705,2117.996582 
	C1455.841064,2127.008545 1455.841064,2127.008545 1446.350342,2129.679443 
z"
        />
        <path
          fill="#778FA4"
          opacity="1.000000"
          stroke="none"
          d="
M1014.035522,2095.198975 
	C1011.020325,2095.800781 1007.920288,2096.592285 1004.520142,2094.545898 
	C1005.795410,2092.612061 1006.183960,2090.787842 1005.311707,2088.766602 
	C1003.551331,2084.687988 1005.845825,2081.757080 1008.189941,2079.019531 
	C1010.843506,2075.921143 1014.466309,2074.728516 1017.758606,2077.501221 
	C1020.876221,2080.126953 1021.052063,2084.088623 1018.430664,2087.197266 
	C1016.532776,2089.447754 1013.875000,2091.009521 1014.035522,2095.198975 
z"
        />
        <path
          fill="#778FA4"
          opacity="1.000000"
          stroke="none"
          d="
M1379.296875,2120.539551 
	C1375.968872,2128.823242 1372.626831,2130.646240 1367.198975,2125.590332 
	C1362.972656,2121.653564 1357.311279,2123.448975 1353.411499,2119.396973 
	C1362.260986,2118.745850 1370.991577,2115.697021 1379.296875,2120.539551 
z"
        />
        <path
          fill="#778FA4"
          opacity="1.000000"
          stroke="none"
          d="
M1006.125854,1979.066650 
	C1009.399170,1980.397827 1011.924683,1978.545532 1014.286438,1978.886597 
	C1015.995056,1981.954102 1013.824097,1982.800049 1012.141907,1983.578857 
	C1006.262756,1986.300537 999.992981,1986.293701 993.702148,1985.870728 
	C991.623901,1985.731079 989.461121,1984.995728 989.157288,1982.820312 
	C988.735352,1979.799438 991.547913,1979.083496 993.669434,1978.556274 
	C997.542908,1977.593872 1001.510254,1977.663086 1006.125854,1979.066650 
z"
        />
        <path
          fill="#626E74"
          opacity="1.000000"
          stroke="none"
          d="
M1404.984863,2137.231445 
	C1405.257935,2141.772949 1401.631348,2144.640625 1401.798462,2149.288818 
	C1401.208496,2150.610107 1400.388916,2150.976562 1398.776611,2150.578369 
	C1397.769409,2148.698486 1397.688477,2146.971436 1395.262085,2147.467529 
	C1392.424438,2148.048340 1392.081055,2151.497559 1389.225464,2152.432861 
	C1388.618286,2146.094482 1391.571655,2141.552490 1395.945190,2136.894775 
	C1397.602661,2135.580078 1399.134888,2134.938721 1401.292969,2134.276855 
	C1403.215698,2134.642578 1403.968872,2135.626221 1404.984863,2137.231445 
z"
        />
        <path
          fill="#728DA3"
          opacity="1.000000"
          stroke="none"
          d="
M1564.099365,1966.742432 
	C1569.942383,1965.329346 1575.505005,1966.367432 1581.271240,1969.907715 
	C1581.685425,1971.260010 1581.532104,1973.136475 1580.184082,1973.183105 
	C1574.166870,1973.390869 1567.942505,1973.800659 1564.099365,1966.742432 
z"
        />
        <path
          fill="#6E8AA1"
          opacity="1.000000"
          stroke="none"
          d="
M1346.841797,1937.981079 
	C1347.698730,1932.946655 1351.568115,1930.773682 1357.228027,1929.993408 
	C1358.885742,1932.013306 1359.820312,1934.051147 1361.014893,1936.655640 
	C1356.759155,1939.172241 1352.067993,1937.484131 1346.841797,1937.981079 
z"
        />
        <path
          fill="#778FA4"
          opacity="1.000000"
          stroke="none"
          d="
M1025.395264,2064.852539 
	C1021.560852,2065.113525 1018.577759,2064.341064 1018.503540,2060.507568 
	C1018.451782,2057.835938 1020.597717,2056.537354 1023.148804,2056.231445 
	C1027.268555,2055.737549 1028.055054,2058.022217 1025.395264,2064.852539 
z"
        />
        <path
          fill="#6E8AA1"
          opacity="1.000000"
          stroke="none"
          d="
M1317.676514,1932.984375 
	C1318.303101,1932.422852 1318.664185,1932.437256 1319.559082,1932.546265 
	C1320.482910,1932.777832 1320.873047,1932.914551 1321.668213,1933.520752 
	C1325.728271,1934.981201 1329.383301,1935.972046 1333.519165,1937.471924 
	C1333.012451,1939.751343 1331.578857,1942.197388 1329.632690,1941.129028 
	C1325.782593,1939.015625 1321.634521,1938.341187 1317.594971,1937.078247 
	C1316.324341,1936.680908 1316.796753,1934.799927 1317.676514,1932.984375 
z"
        />
        <path
          fill="#70889D"
          opacity="1.000000"
          stroke="none"
          d="
M924.003296,2162.004883 
	C925.522705,2156.356201 929.096741,2157.929443 933.339417,2159.226562 
	C931.242310,2163.083252 928.052551,2161.535645 924.772217,2161.990723 
	C923.992676,2161.990234 924.000000,2162.000000 924.003296,2162.004883 
z"
        />
        <path
          fill="#778FA4"
          opacity="1.000000"
          stroke="none"
          d="
M1405.531250,2137.254883 
	C1403.780151,2137.598389 1402.465576,2137.109131 1401.998047,2134.805664 
	C1402.054810,2132.389404 1401.828857,2130.445068 1403.627563,2128.372070 
	C1406.348022,2129.640137 1406.009644,2132.002930 1405.842529,2135.022217 
	C1405.808105,2136.068115 1405.762939,2136.415771 1405.531250,2137.254883 
z"
        />
        <path
          fill="#778FA4"
          opacity="1.000000"
          stroke="none"
          d="
M1322.000000,2065.325195 
	C1320.696411,2065.967285 1319.369995,2066.092529 1318.093262,2065.895752 
	C1313.083374,2065.123779 1315.017578,2063.082764 1317.641846,2060.517822 
	C1318.019653,2060.009766 1318.997559,2060.005859 1319.486572,2060.002930 
	C1320.403809,2060.466064 1320.845825,2060.918701 1321.650879,2061.678711 
	C1322.000000,2062.886719 1322.000000,2063.773438 1322.000000,2065.325195 
z"
        />
        <path
          fill="#778FA4"
          opacity="1.000000"
          stroke="none"
          d="
M997.579529,2036.479980 
	C999.373413,2037.695801 999.224976,2038.724731 997.821960,2039.422485 
	C996.939209,2039.861328 995.799011,2039.715576 995.355347,2038.777710 
	C994.637085,2037.259277 995.633240,2036.600952 997.579529,2036.479980 
z"
        />
        <path
          fill="#8497A7"
          opacity="1.000000"
          stroke="none"
          d="
M1321.763184,2061.573975 
	C1321.378052,2061.759277 1320.864136,2061.374268 1320.216797,2060.422852 
	C1320.602661,2060.233887 1321.119629,2060.616455 1321.763184,2061.573975 
z"
        />
        <path
          fill="#7F98AE"
          opacity="1.000000"
          stroke="none"
          d="
M415.683899,2019.193726 
	C413.352295,2022.557739 411.232361,2020.780273 409.666718,2019.390503 
	C408.032410,2017.939697 407.153656,2015.672363 405.665527,2014.011963 
	C404.397125,2012.597046 401.949524,2011.401855 403.424072,2009.351807 
	C404.975861,2007.194214 407.737701,2007.794678 409.631866,2009.182861 
	C412.766510,2011.480225 416.072327,2013.876953 415.683899,2019.193726 
z"
        />
        <path
          fill="#7F98AE"
          opacity="1.000000"
          stroke="none"
          d="
M391.994415,2017.834717 
	C388.243164,2016.870850 383.762726,2016.687744 384.978516,2012.192627 
	C385.928619,2008.679810 389.997925,2010.263916 392.758514,2010.084717 
	C394.725647,2009.957275 397.045746,2010.203247 397.389954,2012.661011 
	C397.843628,2015.900269 395.652130,2017.295532 391.994415,2017.834717 
z"
        />
        <path
          fill="#7F98AE"
          opacity="1.000000"
          stroke="none"
          d="
M522.793213,1970.483887 
	C519.562134,1971.925171 517.407776,1971.269165 516.847107,1968.458252 
	C516.320862,1965.820068 518.536438,1964.370239 520.504028,1963.115356 
	C521.785950,1962.297729 523.156799,1962.649536 524.112122,1963.632690 
	C526.422791,1966.010742 526.299744,1968.272095 522.793213,1970.483887 
z"
        />
        <path
          fill="#7F98AE"
          opacity="1.000000"
          stroke="none"
          d="
M410.375061,1993.666260 
	C412.942535,1996.045532 414.831909,1998.219727 411.719360,2000.601440 
	C409.748901,2002.109131 407.592926,2001.069092 406.952209,1998.992676 
	C406.205383,1996.572510 403.963593,1992.665405 410.375061,1993.666260 
z"
        />
        <path
          fill="#7F98AE"
          opacity="1.000000"
          stroke="none"
          d="
M711.690369,2043.437256 
	C710.436218,2043.393921 709.693665,2043.336060 709.237366,2042.944702 
	C707.684998,2041.613159 706.369446,2039.861084 706.981628,2037.828857 
	C707.426758,2036.351074 709.138306,2036.651001 710.346375,2037.304443 
	C712.827637,2038.646484 715.143982,2040.128784 711.690369,2043.437256 
z"
        />
        <path
          fill="#61798F"
          opacity="1.000000"
          stroke="none"
          d="
M873.253479,2200.169678 
	C878.474731,2203.977783 883.144592,2207.501953 889.389526,2208.341309 
	C892.426575,2208.749512 893.804626,2211.000244 893.759705,2214.039307 
	C893.714722,2217.081543 891.570862,2217.704834 889.129456,2218.124756 
	C886.823853,2218.521484 884.654846,2219.302734 882.903259,2221.919922 
	C887.366516,2225.869629 891.884521,2222.983154 896.404053,2222.392578 
	C901.133484,2221.774902 906.022705,2221.281982 911.198975,2223.139160 
	C907.860229,2229.986328 901.358093,2226.635254 896.540649,2229.478027 
	C892.600220,2233.355225 888.548889,2231.040283 884.791992,2231.291260 
	C882.800232,2231.424316 880.572144,2231.595459 880.044861,2233.848145 
	C879.628113,2235.628662 881.398132,2236.654541 882.748474,2237.548096 
	C886.279480,2239.884766 890.066162,2241.864014 893.511963,2245.495605 
	C897.040466,2246.310791 899.721130,2244.988281 903.239258,2244.133789 
	C906.959595,2246.998779 908.853333,2250.263672 906.465027,2255.276611 
	C899.985291,2256.002930 894.012207,2256.033936 888.040222,2256.129150 
	C886.661804,2256.151123 885.302307,2256.640137 885.107239,2258.190918 
	C884.928223,2259.613037 885.833496,2260.878906 887.137207,2261.222412 
	C892.227905,2262.563477 896.513306,2266.342285 902.152466,2266.035645 
	C906.142517,2265.818604 910.006409,2266.879639 913.919128,2267.391846 
	C921.022522,2268.321777 927.882202,2267.055908 935.139160,2265.389160 
	C948.353271,2262.353516 961.874084,2258.075439 976.075623,2261.424561 
	C982.075623,2262.839600 988.573425,2262.763916 995.388855,2260.542725 
	C1006.807007,2259.990234 1017.618958,2259.990234 1028.430908,2259.990234 
	C1028.523804,2260.803223 1028.616821,2261.616211 1028.709717,2262.429199 
	C1024.372803,2264.849854 1019.640625,2266.252930 1014.484009,2265.972900 
	C1005.095886,2265.463135 995.994751,2266.964111 986.960022,2269.307129 
	C981.405396,2270.748047 975.704590,2269.772461 970.073975,2269.995850 
	C966.135376,2270.152344 962.107788,2269.444580 958.606506,2272.227295 
	C955.348022,2274.817139 952.131409,2272.354492 949.197144,2271.358643 
	C944.072021,2269.619873 939.540405,2269.238037 935.423950,2273.462891 
	C932.229614,2276.741455 928.004395,2276.596680 924.176758,2275.578125 
	C911.651550,2272.244629 898.886719,2271.732422 886.096313,2272.129395 
	C879.565674,2272.332031 874.310547,2270.945801 870.331909,2264.634033 
	C869.849609,2261.166748 870.666931,2258.919678 873.184875,2257.198242 
	C876.020813,2255.259766 876.487793,2252.362793 875.258911,2249.247314 
	C874.762207,2247.987793 873.840637,2247.011230 872.322510,2246.984619 
	C871.161743,2246.964355 870.570007,2247.729492 870.211792,2248.708740 
	C869.047302,2251.891846 867.479492,2254.626221 862.861633,2255.377197 
	C862.798950,2249.545654 858.166809,2244.680176 864.179077,2238.795898 
	C866.391785,2236.630127 864.454285,2230.433594 869.209534,2227.663818 
	C870.256470,2227.053955 868.678650,2224.229492 866.830200,2224.367920 
	C856.578918,2225.136230 846.633972,2219.854492 836.303467,2222.894775 
	C834.070312,2223.552002 831.310547,2224.652588 830.738831,2221.320312 
	C830.162781,2217.962158 833.447388,2218.274170 835.559875,2217.762695 
	C840.374329,2216.597168 845.202148,2215.486572 850.028442,2214.370605 
	C852.425903,2213.816162 854.657104,2214.205811 856.615540,2215.737061 
	C860.692200,2218.925049 863.713623,2216.188721 868.188354,2214.149902 
	C860.592041,2208.596680 852.019714,2212.269775 844.250427,2208.181396 
	C849.429260,2204.653320 853.453003,2201.770264 859.713135,2203.741211 
	C864.248535,2205.169189 868.850037,2203.711914 873.253479,2200.169678 
z"
        />
        <path
          fill="#728BA1"
          opacity="1.000000"
          stroke="none"
          d="
M811.814697,2193.256348 
	C807.716003,2193.806152 803.523865,2194.079590 798.762207,2195.877197 
	C792.772949,2192.922363 787.732300,2191.781250 781.325012,2193.412842 
	C775.176025,2194.978271 768.356934,2193.911621 760.907349,2193.990967 
	C760.634155,2189.264893 764.979919,2183.914551 757.253235,2180.705322 
	C755.655029,2180.041748 756.387817,2177.814453 757.630554,2176.511230 
	C762.893616,2170.990967 767.027588,2163.529053 776.767151,2165.883057 
	C778.655579,2166.339355 780.688293,2165.662354 781.824707,2162.736328 
	C783.683228,2161.981445 785.566162,2162.488770 786.972778,2161.885742 
	C797.873840,2157.210449 801.946045,2165.383789 808.050537,2172.540527 
	C798.315063,2175.515381 788.089478,2174.409424 780.481445,2182.192383 
	C786.181152,2185.884277 793.062866,2181.544189 797.113403,2185.190674 
	C801.516174,2189.154053 807.574585,2188.700928 811.814697,2193.256348 
z"
        />
        <path
          fill="#61798F"
          opacity="1.000000"
          stroke="none"
          d="
M833.353455,2235.718506 
	C828.473145,2235.908203 824.488831,2236.062012 820.512756,2235.942139 
	C818.398560,2235.878662 815.091675,2236.674805 815.259644,2233.247314 
	C815.392700,2230.529785 818.298340,2230.186279 820.702454,2230.035645 
	C823.342285,2229.870605 825.980164,2229.672119 828.617676,2229.472656 
	C835.102112,2228.983398 841.580444,2228.386475 848.072021,2228.028076 
	C852.023560,2227.810059 856.020386,2227.881104 859.362976,2232.170166 
	C851.054626,2236.174316 842.027832,2231.974121 833.353455,2235.718506 
z"
        />
        <path
          fill="#728BA1"
          opacity="1.000000"
          stroke="none"
          d="
M814.710449,2219.899902 
	C813.490051,2219.003418 812.969971,2218.016357 812.229614,2216.516357 
	C815.544800,2214.719727 819.080261,2213.436279 823.320801,2212.061768 
	C824.357727,2211.728516 824.689514,2211.486328 825.500122,2211.631836 
	C824.388916,2217.238037 819.001892,2217.305420 814.710449,2219.899902 
z"
        />
        <path
          fill="#61798F"
          opacity="1.000000"
          stroke="none"
          d="
M826.655273,2211.996094 
	C825.979004,2212.019531 825.002014,2211.999023 824.513977,2211.984863 
	C824.335266,2209.887207 825.176941,2208.304443 827.585205,2208.212646 
	C828.887512,2208.163086 830.482605,2208.264648 830.664246,2209.695557 
	C830.972290,2212.121582 828.747803,2211.624023 826.655273,2211.996094 
z"
        />
        <path
          fill="#434F51"
          opacity="1.000000"
          stroke="none"
          d="
M1193.067627,1903.092041 
	C1188.465454,1899.707397 1183.642944,1899.550781 1178.411865,1898.395142 
	C1172.813110,1897.260986 1173.709839,1893.081787 1173.149048,1889.677490 
	C1172.525269,1885.891968 1174.035156,1881.855469 1171.385986,1877.008545 
	C1164.954590,1889.921143 1157.513794,1901.048462 1144.189697,1907.601074 
	C1141.950806,1908.735962 1140.246948,1909.434326 1137.860474,1910.269775 
	C1131.890259,1903.708862 1133.515991,1895.130127 1130.907593,1887.556396 
	C1127.146851,1893.654053 1128.708130,1901.386963 1123.960938,1907.509277 
	C1096.062134,1907.781982 1068.787598,1908.643311 1041.596069,1907.511108 
	C1029.023682,1906.987549 1016.432739,1906.659302 1002.921936,1906.082397 
	C998.949341,1902.207886 993.366455,1902.131348 990.115356,1897.458984 
	C988.942322,1895.498291 988.713928,1894.063965 989.513489,1891.907471 
	C1005.674438,1888.280396 1021.274963,1882.766235 1037.590942,1887.509766 
	C1042.388306,1888.904541 1046.755249,1886.761719 1051.366089,1886.831299 
	C1062.588867,1887.000610 1073.787842,1886.654663 1085.033936,1887.588989 
	C1095.568115,1888.464233 1096.594116,1886.758179 1096.286377,1874.937012 
	C1096.462402,1873.724487 1096.588623,1873.251221 1097.073486,1872.142578 
	C1100.353149,1868.641479 1104.305664,1866.785767 1107.887695,1866.418213 
	C1115.252441,1865.662476 1118.821533,1861.592773 1121.617798,1854.923096 
	C1123.567261,1853.919800 1124.988525,1853.683960 1127.173096,1853.733521 
	C1130.939087,1854.478394 1134.083618,1855.727173 1134.916504,1858.772339 
	C1136.957886,1866.236328 1143.088989,1864.905151 1147.881714,1864.563721 
	C1153.698242,1864.149292 1151.625854,1858.613037 1152.237427,1854.969116 
	C1153.268799,1848.824219 1152.856445,1841.637695 1162.402222,1842.078247 
	C1166.898804,1842.285767 1166.133423,1838.246338 1167.016968,1835.631470 
	C1169.968628,1826.895142 1172.907715,1827.041626 1180.479980,1831.972046 
	C1189.625854,1837.927124 1197.987671,1846.017212 1210.442627,1847.288086 
	C1215.366455,1848.657715 1219.809204,1848.742310 1224.729614,1850.171387 
	C1228.566650,1852.066650 1229.513184,1855.841797 1233.020020,1858.130127 
	C1236.258179,1861.485107 1235.568481,1864.896240 1235.016602,1868.973877 
	C1234.778687,1873.297729 1234.452148,1876.896484 1232.399292,1880.039429 
	C1230.609009,1882.780640 1227.283569,1885.058594 1225.079834,1882.666626 
	C1218.197021,1875.196411 1215.527344,1882.984253 1211.071411,1886.334473 
	C1206.841553,1890.497437 1207.307739,1895.832520 1204.857666,1900.799316 
	C1201.293091,1904.362549 1197.748657,1905.123535 1193.067627,1903.092041 
z"
        />
        <path
          fill="#374549"
          opacity="1.000000"
          stroke="none"
          d="
M1209.293823,1887.063354 
	C1208.706909,1882.477173 1206.732666,1877.855591 1214.029907,1879.073975 
	C1218.136719,1879.759766 1219.355835,1877.646118 1219.955933,1874.379761 
	C1220.260376,1872.723022 1220.591919,1870.816772 1222.677979,1871.037720 
	C1224.421997,1871.222412 1225.396362,1872.704102 1225.274902,1874.631226 
	C1225.127441,1876.972778 1222.440918,1880.484741 1226.410889,1881.203735 
	C1230.807129,1881.999878 1231.061157,1877.600098 1231.861938,1874.517700 
	C1232.104370,1873.584717 1231.961670,1872.551392 1231.997681,1870.774658 
	C1236.230103,1867.030396 1239.801758,1869.459351 1244.031982,1871.821899 
	C1247.322144,1875.530640 1250.780640,1877.108521 1255.024048,1877.166382 
	C1257.669434,1877.202393 1260.317993,1877.410522 1262.840576,1878.369507 
	C1273.983276,1882.605835 1273.984497,1882.602539 1280.256104,1871.849854 
	C1281.320190,1870.492310 1282.055298,1869.830933 1283.542969,1868.910889 
	C1286.803711,1867.758423 1289.198853,1868.218140 1292.020996,1870.087646 
	C1297.716431,1879.032593 1302.968994,1873.267090 1308.907715,1870.453613 
	C1318.630737,1872.451172 1328.890747,1871.433350 1335.370850,1881.025391 
	C1335.132935,1882.948730 1334.619141,1883.904541 1333.166992,1885.196899 
	C1327.860229,1887.309326 1322.927124,1887.052124 1317.406738,1887.995850 
	C1307.382080,1886.844971 1298.237427,1886.009644 1291.521973,1895.712158 
	C1288.688843,1895.441528 1287.883789,1899.374390 1284.425415,1897.136230 
	C1282.054443,1895.601807 1282.485229,1899.961670 1282.166260,1902.609497 
	C1278.912598,1905.557007 1275.267700,1905.147705 1270.894165,1904.318115 
	C1266.828979,1898.092773 1262.249146,1899.625854 1256.833618,1902.261230 
	C1249.692383,1903.274902 1243.385010,1905.588867 1236.038940,1904.066162 
	C1233.226929,1902.730591 1232.402954,1900.031250 1229.223145,1899.302490 
	C1227.698364,1899.371826 1226.825073,1899.517822 1225.299072,1899.491821 
	C1222.693604,1898.697144 1220.769287,1897.779785 1217.896240,1898.344482 
	C1214.665283,1898.625610 1212.216553,1898.354736 1209.433105,1896.366699 
	C1207.314453,1893.120117 1207.512939,1890.439209 1209.293823,1887.063354 
z"
        />
        <path
          fill="#EFF1EA"
          opacity="1.000000"
          stroke="none"
          d="
M1144.477051,1908.066406 
	C1142.058960,1903.007202 1142.733521,1899.604126 1149.133301,1899.621704 
	C1157.407959,1899.644531 1158.915771,1891.920410 1162.594604,1887.014771 
	C1166.118896,1882.314941 1168.502319,1876.766113 1171.478027,1871.643311 
	C1172.128052,1870.524292 1173.240967,1869.498047 1174.643066,1869.700195 
	C1176.708252,1869.998047 1176.981812,1871.792358 1176.949463,1873.440063 
	C1176.794800,1881.316162 1175.362549,1889.174805 1177.150391,1897.788818 
	C1175.590820,1905.859131 1174.798706,1913.329224 1175.565430,1920.938965 
	C1176.064575,1925.891968 1174.070923,1928.360107 1168.875366,1928.155396 
	C1163.930298,1927.960693 1158.969727,1928.173950 1154.023193,1928.001587 
	C1147.750244,1927.783325 1146.619873,1926.017090 1148.649536,1919.860107 
	C1149.163696,1918.300781 1150.042603,1916.865479 1150.644043,1915.330200 
	C1152.994873,1909.330688 1152.978394,1909.324219 1144.477051,1908.066406 
z"
        />
        <path
          fill="#E59F70"
          opacity="1.000000"
          stroke="none"
          d="
M990.826660,1891.241211 
	C990.318787,1893.040161 990.324585,1894.108032 990.623291,1895.635620 
	C993.589844,1896.846680 992.807495,1899.541626 993.981079,1902.011230 
	C994.185181,1903.867310 993.988892,1904.904175 993.081543,1906.533081 
	C989.045898,1917.124390 983.882935,1918.459106 976.713989,1911.193848 
	C975.611877,1910.076660 974.878235,1908.596069 973.694580,1906.620605 
	C974.876343,1901.791016 976.337769,1897.624146 977.872437,1892.741089 
	C977.677673,1890.730835 977.669067,1889.425781 977.872437,1888.129272 
	C978.647461,1883.188477 980.939148,1878.510620 986.307617,1879.214111 
	C992.546326,1880.031494 993.247375,1885.112305 990.826660,1891.241211 
z"
        />
        <path
          fill="#E7E3DA"
          opacity="1.000000"
          stroke="none"
          d="
M1209.357056,1896.946655 
	C1212.645996,1895.494751 1215.310181,1894.807007 1218.995850,1896.307617 
	C1220.914551,1896.229736 1221.989868,1896.202759 1223.542114,1896.853760 
	C1224.676514,1897.438721 1225.313232,1897.796875 1226.487183,1898.263916 
	C1227.595825,1898.432129 1228.167358,1898.491455 1228.359863,1899.324951 
	C1228.246582,1903.195435 1227.489624,1906.022461 1225.508179,1908.415527 
	C1223.172852,1911.236084 1220.394409,1907.921021 1218.300659,1908.635620 
	C1210.598755,1911.264893 1203.305664,1907.618652 1195.812744,1907.812744 
	C1193.318481,1907.877441 1192.264893,1906.521973 1192.433105,1903.348145 
	C1195.819824,1901.389771 1199.974121,1904.581909 1203.751709,1900.618896 
	C1205.431030,1898.681885 1206.717651,1897.435791 1209.357056,1896.946655 
z"
        />
        <path
          fill="#EFF1EA"
          opacity="1.000000"
          stroke="none"
          d="
M1291.608398,1896.034668 
	C1289.595093,1891.513794 1290.781494,1888.677490 1295.582520,1887.035522 
	C1301.854248,1884.890503 1307.979614,1883.121948 1315.264404,1885.738892 
	C1317.835571,1887.622681 1318.345947,1889.735352 1318.456055,1892.836914 
	C1318.056763,1896.450195 1317.135864,1899.045654 1315.276855,1902.157959 
	C1309.580688,1905.750977 1304.335083,1906.047607 1298.436157,1902.552490 
	C1297.846924,1901.968506 1297.703369,1900.992798 1297.755737,1900.502441 
	C1297.808105,1900.012207 1298.400635,1899.642944 1298.720581,1899.019409 
	C1299.040527,1898.395874 1298.339233,1898.467529 1298.050049,1898.265869 
	C1296.364990,1896.301880 1294.298340,1896.357666 1291.608398,1896.034668 
z"
        />
        <path
          fill="#E7E3DA"
          opacity="1.000000"
          stroke="none"
          d="
M1123.644287,1908.407837 
	C1125.469727,1902.151733 1123.814209,1895.924194 1126.523315,1890.316162 
	C1128.098511,1887.055176 1129.237061,1883.763550 1132.896240,1881.135986 
	C1134.539429,1890.845093 1136.054810,1899.799561 1137.374023,1909.580322 
	C1132.579102,1911.966064 1128.019409,1913.138184 1123.644287,1908.407837 
z"
        />
        <path
          fill="#F0EBDD"
          opacity="1.000000"
          stroke="none"
          d="
M1186.582275,1939.565918 
	C1180.682739,1940.080322 1175.308228,1940.036011 1169.043945,1940.057495 
	C1163.564453,1944.209595 1158.586182,1943.901245 1152.595581,1940.875000 
	C1150.901855,1940.268677 1150.028931,1940.214355 1148.505127,1940.250977 
	C1145.268066,1936.941284 1145.132324,1933.642456 1150.146240,1933.663452 
	C1160.941162,1933.708496 1171.559326,1931.055542 1182.408813,1932.067505 
	C1187.309692,1932.524414 1188.939697,1934.387329 1186.582275,1939.565918 
z"
        />
        <path
          fill="#E7E3DA"
          opacity="1.000000"
          stroke="none"
          d="
M1233.205078,1901.401367 
	C1241.400757,1901.026367 1248.861328,1902.379761 1257.051025,1900.693726 
	C1258.215454,1901.145142 1258.539917,1901.461548 1259.070068,1902.426514 
	C1259.419067,1906.080688 1257.386353,1906.855835 1254.553467,1907.773560 
	C1246.312744,1912.223389 1237.888184,1910.657593 1232.463379,1903.678223 
	C1232.299438,1902.522583 1232.355591,1901.994873 1233.205078,1901.401367 
z"
        />
        <path
          fill="#D7D5CF"
          opacity="1.000000"
          stroke="none"
          d="
M1297.317627,1902.358154 
	C1302.561157,1901.218262 1307.198975,1902.346558 1312.435547,1902.268799 
	C1314.481567,1901.103027 1315.975220,1900.234741 1318.647217,1900.734497 
	C1321.719727,1902.581909 1321.884888,1904.746338 1320.658081,1907.850098 
	C1318.267700,1909.292480 1316.351807,1909.997437 1314.536499,1908.519897 
	C1313.525879,1907.697510 1311.967651,1906.133667 1311.291992,1907.202759 
	C1306.510010,1914.768188 1300.314453,1907.716431 1294.988770,1909.005127 
	C1293.224976,1909.432007 1291.412964,1908.884644 1291.096069,1906.943848 
	C1290.605347,1903.938354 1293.512085,1904.546509 1295.077759,1903.741089 
	C1295.663330,1903.439819 1296.218994,1903.080566 1297.317627,1902.358154 
z"
        />
        <path
          fill="#D3CBBF"
          opacity="1.000000"
          stroke="none"
          d="
M1254.968262,1908.240234 
	C1255.322388,1906.696899 1256.526367,1905.103394 1257.775879,1902.895752 
	C1260.752563,1899.776245 1263.478638,1901.542236 1266.682861,1903.157959 
	C1271.404175,1901.244507 1275.931641,1902.092285 1281.344727,1902.954590 
	C1283.898804,1906.639526 1285.425781,1909.589478 1279.420654,1910.003784 
	C1271.425781,1910.555420 1263.700684,1908.536133 1254.968262,1908.240234 
z"
        />
        <path
          fill="#E7E3DA"
          opacity="1.000000"
          stroke="none"
          d="
M1320.812256,1908.917725 
	C1320.077148,1906.811279 1320.011475,1905.075073 1319.977051,1902.673828 
	C1325.268555,1895.537231 1329.416138,1899.801025 1333.790039,1904.227295 
	C1333.935059,1909.160645 1333.920776,1913.286987 1333.887207,1918.146606 
	C1330.032227,1919.675903 1328.348999,1916.786255 1327.112793,1914.246338 
	C1325.836060,1911.623413 1324.379883,1909.794678 1320.812256,1908.917725 
z"
        />
        <path
          fill="#313E41"
          opacity="1.000000"
          stroke="none"
          d="
M992.568970,1904.432861 
	C991.748291,1901.874146 991.379761,1899.783691 990.963745,1896.894287 
	C993.958313,1897.275757 996.080078,1900.129395 999.631653,1900.532104 
	C1001.537231,1900.748291 1001.779724,1902.779053 1001.955933,1905.216553 
	C1001.105408,1906.014404 1000.218872,1906.068604 998.667419,1906.070801 
	C996.271423,1905.961304 994.511414,1906.032715 992.568970,1904.432861 
z"
        />
        <path
          fill="#434F51"
          opacity="1.000000"
          stroke="none"
          d="
M992.052002,1904.513672 
	C993.855591,1904.060791 995.657410,1903.848389 997.591797,1905.508545 
	C996.959045,1907.563477 995.462769,1907.817139 993.181396,1907.335693 
	C992.392456,1906.465942 992.189819,1905.764404 992.052002,1904.513672 
z"
        />
        <path
          fill="#434F51"
          opacity="1.000000"
          stroke="none"
          d="
M1233.783569,1900.660522 
	C1233.782104,1901.697266 1233.565796,1902.059082 1232.796509,1902.735474 
	C1231.240479,1902.207031 1230.237549,1901.363892 1228.607666,1900.309937 
	C1227.980957,1900.098999 1227.369873,1899.309570 1227.197144,1898.841187 
	C1229.385620,1898.181030 1231.890259,1897.406738 1233.783569,1900.660522 
z"
        />
        <path
          fill="#D7D5CF"
          opacity="1.000000"
          stroke="none"
          d="
M1297.677246,1898.553711 
	C1298.135376,1898.046631 1298.509644,1898.029053 1299.436768,1898.001953 
	C1299.574463,1898.524292 1299.159180,1899.056396 1298.276001,1899.800293 
	C1297.808105,1900.012207 1297.593628,1899.042969 1297.677246,1898.553711 
z"
        />
        <path
          fill="#EEE7D5"
          opacity="1.000000"
          stroke="none"
          d="
M594.592285,1884.453857 
	C593.062988,1882.645264 592.162231,1881.278320 591.279785,1879.899658 
	C590.525024,1878.720581 589.507996,1878.049561 588.062866,1878.152710 
	C581.607300,1878.613892 582.230408,1878.356812 579.797119,1872.453369 
	C578.996399,1870.510742 578.513000,1877.722656 574.862732,1875.581055 
	C572.341675,1874.101929 572.810303,1871.801025 573.283142,1869.515015 
	C573.548584,1868.232056 573.792053,1866.944580 573.989990,1864.798462 
	C573.398315,1861.131104 575.397949,1859.905884 578.040527,1858.246094 
	C584.946350,1856.838867 591.101685,1856.701172 598.100525,1857.606934 
	C600.570740,1858.423828 601.900696,1859.426025 603.396973,1861.556152 
	C605.856812,1864.827393 604.427612,1866.428345 602.060120,1868.290894 
	C601.672607,1869.630493 601.222473,1870.316650 599.977295,1871.152832 
	C596.094482,1873.445679 597.999634,1875.632446 599.401428,1878.443115 
	C599.907288,1880.558350 599.753967,1881.970947 598.677856,1883.925781 
	C597.253357,1884.988525 596.262695,1885.111694 594.592285,1884.453857 
z"
        />
        <path
          fill="#646A6C"
          opacity="1.000000"
          stroke="none"
          d="
M679.606201,1873.260132 
	C683.057373,1874.247681 685.875183,1874.808228 689.369690,1875.606201 
	C690.718018,1880.628906 688.303772,1884.890869 687.709412,1889.466064 
	C687.541382,1890.759521 686.976624,1892.123901 685.495544,1892.483154 
	C683.952026,1892.857300 682.940796,1891.853394 682.536987,1890.556641 
	C681.661926,1887.746582 680.231934,1885.979492 676.957886,1886.022583 
	C671.166260,1886.098633 669.742004,1882.368042 669.872009,1876.622803 
	C669.716858,1875.316650 669.586243,1874.827393 669.892334,1873.712524 
	C671.164551,1872.478149 672.083496,1872.047119 673.848022,1871.689453 
	C676.182983,1871.588379 677.647583,1871.967041 679.606201,1873.260132 
z"
        />
        <path
          fill="#D99469"
          opacity="1.000000"
          stroke="none"
          d="
M641.683105,1874.569336 
	C641.930298,1879.306396 644.415833,1882.846313 643.773254,1886.975586 
	C643.052917,1891.604858 647.431274,1891.710815 649.865540,1893.556030 
	C650.646790,1894.148071 651.209412,1895.100464 650.770935,1896.161377 
	C650.361206,1897.152466 649.441833,1897.520264 648.413635,1897.440063 
	C647.765259,1897.389526 647.132507,1897.157349 646.488770,1897.026123 
	C631.188171,1893.902954 630.972229,1893.476562 635.928467,1875.181763 
	C637.631958,1872.290161 639.494568,1872.419678 641.683105,1874.569336 
z"
        />
        <path
          fill="#EFF1EA"
          opacity="1.000000"
          stroke="none"
          d="
M642.572754,1875.426514 
	C640.492920,1874.261719 638.863220,1874.248169 636.631348,1874.323608 
	C634.321350,1874.668457 632.615967,1874.914062 630.313721,1875.426514 
	C629.255798,1875.939941 628.794861,1876.186523 627.718262,1875.945068 
	C625.981628,1873.442139 626.009399,1871.476929 628.080627,1869.266602 
	C634.054626,1869.416260 638.905457,1868.431396 643.708008,1864.604858 
	C645.661926,1862.895020 647.247742,1862.456299 649.881592,1863.069092 
	C651.151550,1863.782104 651.538086,1864.270874 652.070068,1865.230469 
	C653.603577,1867.588013 654.349670,1869.652954 653.281128,1872.782471 
	C652.446655,1873.875000 651.995117,1874.212402 650.755981,1874.242432 
	C647.603760,1873.890747 645.822632,1876.317383 642.572754,1875.426514 
z"
        />
        <path
          fill="#595B54"
          opacity="1.000000"
          stroke="none"
          d="
M603.907410,1861.659912 
	C602.877625,1862.090698 601.804016,1861.988892 600.279663,1861.589355 
	C599.119690,1858.721924 596.671021,1857.082642 595.943359,1853.642090 
	C595.366699,1850.731079 595.412415,1848.612549 597.027832,1845.269897 
	C604.393127,1845.177979 604.994019,1838.154419 609.091064,1835.979370 
	C610.609985,1838.108032 609.682861,1840.416870 609.977539,1843.279053 
	C611.698730,1845.968994 611.941650,1848.151855 610.440674,1851.264282 
	C609.987305,1852.014526 609.983032,1853.014282 610.000000,1853.514038 
	C609.931824,1855.646118 609.846741,1857.278442 609.557861,1859.714600 
	C608.649536,1860.438843 607.945007,1860.359497 606.706665,1860.227539 
	C604.388428,1858.248657 604.988037,1856.014282 605.274780,1853.811279 
	C604.552490,1855.493408 605.254028,1857.317627 604.434570,1859.553833 
	C603.962097,1860.422119 603.912842,1860.774902 603.907410,1861.659912 
z"
        />
        <path
          fill="#C2825D"
          opacity="1.000000"
          stroke="none"
          d="
M606.286499,1885.047363 
	C606.607239,1889.476562 604.303040,1891.888306 602.048706,1895.066284 
	C600.424194,1895.776611 598.976318,1895.831421 596.802124,1895.878174 
	C594.567383,1894.595947 595.209473,1892.771484 594.464355,1890.727661 
	C594.076416,1889.292847 594.103943,1888.326294 594.151489,1886.634644 
	C594.730774,1885.342896 595.401245,1885.040283 596.778564,1885.074951 
	C601.036865,1882.276001 597.594238,1878.146729 599.748413,1874.518921 
	C608.236206,1874.009644 605.177734,1880.295898 606.286499,1885.047363 
z"
        />
        <path
          fill="#646A6C"
          opacity="1.000000"
          stroke="none"
          d="
M691.981812,1876.410400 
	C693.256287,1875.486450 694.648071,1875.312134 696.732910,1875.026733 
	C700.223633,1873.768555 703.029236,1873.305298 706.634521,1875.588989 
	C706.306396,1879.088745 705.201721,1881.816162 703.752502,1885.196167 
	C696.461975,1887.086792 693.546143,1883.079468 691.981812,1876.410400 
z"
        />
        <path
          fill="#4E585F"
          opacity="1.000000"
          stroke="none"
          d="
M650.153564,1874.635742 
	C649.968323,1873.934814 650.739319,1873.500732 651.157654,1873.356934 
	C652.979980,1872.501343 654.333740,1871.670532 656.704224,1871.309692 
	C659.619263,1871.151733 661.736084,1871.170044 664.549744,1872.063721 
	C666.250732,1872.874390 667.008606,1873.603760 667.748779,1875.314209 
	C667.926880,1876.946289 667.926392,1877.801270 667.924316,1879.297485 
	C666.127380,1881.863159 664.328979,1883.780640 660.662354,1882.426392 
	C659.698425,1879.416504 658.189392,1878.250977 655.681519,1878.026978 
	C653.588928,1877.840088 651.194763,1878.059204 650.153564,1874.635742 
z"
        />
        <path
          fill="#4E585F"
          opacity="1.000000"
          stroke="none"
          d="
M667.606079,1887.458252 
	C668.049500,1888.645264 668.130432,1889.309326 668.086487,1889.965210 
	C667.936157,1892.207397 668.399231,1895.320679 665.308105,1895.427490 
	C661.454712,1895.560547 662.115417,1892.093262 661.957031,1888.779297 
	C663.299377,1885.584595 664.894836,1884.560303 667.606079,1887.458252 
z"
        />
        <path
          fill="#646A6C"
          opacity="1.000000"
          stroke="none"
          d="
M668.029297,1887.181274 
	C666.388672,1887.293335 664.767212,1886.400146 662.486633,1887.796997 
	C661.358948,1886.413452 660.729431,1884.829590 660.070923,1882.602295 
	C662.217957,1881.341431 664.393921,1880.723755 667.246216,1880.022461 
	C667.959412,1882.085083 667.996094,1884.231323 668.029297,1887.181274 
z"
        />
        <path
          fill="#6B6164"
          opacity="1.000000"
          stroke="none"
          d="
M600.478027,1873.425781 
	C600.087036,1878.218872 599.896912,1882.347656 599.691772,1886.475708 
	C599.679077,1886.730957 599.609314,1887.151611 599.462646,1887.203735 
	C598.716064,1887.468628 598.280640,1887.112549 598.014404,1885.760010 
	C597.456726,1885.120361 597.086975,1884.986084 596.972961,1884.320190 
	C597.337280,1882.390747 597.445862,1880.993042 597.482666,1878.897217 
	C596.231384,1876.010254 595.859680,1873.755005 597.296021,1870.621216 
	C598.644104,1869.502319 599.546326,1869.149780 601.176147,1868.796021 
	C601.494324,1870.117065 601.084900,1871.439209 600.478027,1873.425781 
z"
        />
        <path
          fill="#D7D5CF"
          opacity="1.000000"
          stroke="none"
          d="
M697.704895,1898.372192 
	C697.173462,1898.576538 696.824890,1898.597412 696.515137,1898.497925 
	C694.210999,1897.756226 690.803711,1898.852417 690.398621,1894.918213 
	C690.285278,1893.817261 690.924377,1892.855469 691.905945,1892.667603 
	C694.945007,1892.085815 696.982971,1893.486694 698.235901,1896.986572 
	C698.340271,1897.683838 697.905762,1898.132935 697.704895,1898.372192 
z"
        />
        <path
          fill="#313E41"
          opacity="1.000000"
          stroke="none"
          d="
M604.550293,1860.126709 
	C604.307983,1855.715454 603.136169,1851.123779 606.719482,1847.197266 
	C606.522278,1850.983398 606.325134,1854.769409 606.150391,1859.365234 
	C605.811646,1860.177979 605.450439,1860.180908 604.550293,1860.126709 
z"
        />
        <path
          fill="#A9AEB3"
          opacity="1.000000"
          stroke="none"
          d="
M628.110352,1875.488770 
	C628.842834,1875.939453 629.232239,1875.641602 629.474487,1875.667480 
	C629.913574,1878.177246 629.278870,1880.510254 626.653870,1880.991577 
	C624.442261,1881.397217 624.475220,1879.206909 624.269165,1876.988159 
	C624.231934,1876.350098 624.226318,1876.246582 624.277222,1876.242432 
	C625.143188,1875.325928 626.159790,1874.926147 628.110352,1875.488770 
z"
        />
        <path
          fill="#A9AEB3"
          opacity="1.000000"
          stroke="none"
          d="
M668.403320,1875.991943 
	C667.275146,1875.958496 666.720703,1875.649902 666.112549,1874.708008 
	C665.625793,1870.465088 667.559875,1871.022339 669.905518,1873.033447 
	C669.978638,1873.839233 670.030151,1874.832397 669.938843,1875.319092 
	C669.847473,1875.805908 668.879150,1875.892578 668.403320,1875.991943 
z"
        />
        <path
          fill="#B9A795"
          opacity="1.000000"
          stroke="none"
          d="
M812.304688,1856.213257 
	C812.241577,1854.769409 812.245911,1854.008057 812.163635,1852.683350 
	C812.911133,1848.089355 810.393005,1844.746460 809.446045,1840.404053 
	C807.975891,1839.728516 807.404358,1839.159424 808.100159,1837.622803 
	C811.510376,1835.682495 814.025085,1836.921753 817.235779,1838.115723 
	C819.180481,1838.132324 820.311646,1837.683716 821.780273,1836.476562 
	C830.869202,1832.404785 832.002930,1832.620605 833.836609,1840.506470 
	C834.605835,1843.814575 836.846130,1844.534668 839.006592,1845.858276 
	C843.879761,1848.844238 847.136108,1856.861084 845.609253,1861.990479 
	C844.906738,1864.350464 843.054749,1864.656738 841.047180,1865.086670 
	C837.206665,1865.908936 833.868713,1864.144287 830.418579,1863.150879 
	C827.956116,1862.441895 825.898621,1861.862183 824.418396,1865.500000 
	C823.207581,1865.536011 822.334900,1864.972656 820.990173,1864.187134 
	C817.907288,1861.488159 813.980042,1860.528198 812.304688,1856.213257 
z"
        />
        <path
          fill="#595B54"
          opacity="1.000000"
          stroke="none"
          d="
M811.728821,1856.081055 
	C813.470581,1856.606323 814.623596,1857.810791 816.437073,1857.857422 
	C819.449768,1857.934937 820.247986,1859.929932 820.453857,1863.220703 
	C818.752380,1864.188110 816.216736,1862.975220 815.593079,1865.516113 
	C815.089600,1867.567139 816.576050,1869.127441 819.372742,1869.891968 
	C819.115723,1874.154053 817.214355,1878.093384 817.958923,1882.519165 
	C818.423828,1885.282471 817.404053,1886.827881 814.283020,1886.753418 
	C812.702087,1886.715576 812.110535,1888.097900 812.316345,1889.531128 
	C812.541382,1891.098999 813.663574,1891.726562 815.161072,1891.339966 
	C821.642456,1889.666992 819.701355,1892.554565 817.414856,1895.523682 
	C816.828552,1896.284790 815.932617,1896.807251 814.593994,1897.729980 
	C811.773865,1899.596680 809.167542,1899.611938 805.957031,1899.821899 
	C803.365417,1899.880859 801.415039,1899.900757 798.733521,1899.943848 
	C797.375610,1899.703369 796.858459,1899.299194 796.246704,1898.301758 
	C794.687927,1895.727783 793.982910,1892.879639 790.424866,1893.449951 
	C787.010071,1893.997437 783.951172,1893.077881 780.971802,1890.075928 
	C779.440613,1886.989746 779.425842,1884.524536 781.215698,1881.515381 
	C783.628784,1876.697144 784.880859,1871.361816 791.354492,1876.437500 
	C792.034668,1876.971069 794.706543,1875.876099 795.774780,1874.837524 
	C799.463074,1871.251465 802.301636,1873.114990 805.785278,1876.154785 
	C807.612427,1875.870361 806.531555,1880.784058 809.214600,1877.063965 
	C811.924988,1874.936279 811.964355,1872.475342 811.903076,1869.249512 
	C813.377686,1864.460938 808.860962,1860.747437 811.728821,1856.081055 
z"
        />
        <path
          fill="#CBC6BA"
          opacity="1.000000"
          stroke="none"
          d="
M886.862183,1888.581787 
	C892.074341,1885.005615 892.042236,1888.862915 891.920898,1891.810913 
	C891.716248,1896.783203 893.477295,1898.688110 899.055847,1896.740234 
	C900.160400,1895.967407 900.589844,1895.394287 901.642395,1895.696289 
	C903.048035,1896.563599 903.830627,1896.555664 905.193787,1896.452148 
	C906.123230,1896.872925 906.558533,1897.301758 907.491394,1897.849854 
	C907.867615,1900.922852 906.467102,1902.279541 903.560242,1902.071045 
	C899.050476,1901.747803 895.115173,1902.198364 892.868713,1907.171509 
	C891.334045,1910.568970 887.571289,1909.747803 886.338562,1907.615723 
	C883.239563,1902.256470 878.444824,1902.532227 873.603760,1902.444214 
	C862.673096,1902.245483 851.740417,1902.148193 840.808350,1902.032837 
	C837.121582,1901.993896 833.324768,1902.238525 831.920532,1896.728027 
	C832.112427,1895.816895 832.434082,1895.711914 833.183960,1895.334473 
	C834.630615,1894.612671 835.691589,1894.461670 837.553284,1894.910889 
	C839.096558,1895.438599 839.881592,1895.663940 841.278625,1895.819092 
	C846.957581,1895.954102 852.360474,1898.222900 856.585083,1893.299438 
	C857.662476,1892.043701 858.969788,1892.357910 860.040466,1893.212769 
	C865.602905,1897.654053 871.811157,1896.154907 878.661377,1894.990234 
	C880.044678,1893.585449 881.078674,1892.938354 882.890259,1892.490234 
	C884.189270,1892.496582 884.859619,1892.387939 885.644287,1891.527344 
	C885.986389,1890.347046 886.246277,1889.686768 886.862183,1888.581787 
z"
        />
        <path
          fill="#D0D1C8"
          opacity="1.000000"
          stroke="none"
          d="
M805.676270,1900.487793 
	C806.859985,1896.145874 809.421692,1895.437378 813.432739,1897.664673 
	C812.958191,1904.665894 812.116455,1904.992554 805.676270,1900.487793 
z"
        />
        <path
          fill="#595B54"
          opacity="1.000000"
          stroke="none"
          d="
M819.985596,1869.980957 
	C820.216248,1870.098755 820.061829,1868.485229 820.311768,1869.927002 
	C820.040649,1870.032593 820.001465,1870.000244 819.985596,1869.980957 
z"
        />
        <path
          fill="#313E41"
          opacity="1.000000"
          stroke="none"
          d="
M517.341431,1838.371338 
	C519.111328,1838.447876 520.187073,1838.778320 521.618164,1839.559326 
	C534.636719,1842.226685 547.025452,1846.297363 560.126160,1845.557251 
	C564.763977,1845.295288 568.835938,1846.210327 570.533813,1852.245361 
	C570.821716,1854.714355 571.019470,1856.368774 570.685913,1858.856689 
	C570.219849,1860.765015 569.703247,1861.740723 568.399536,1863.205811 
	C565.462891,1865.432495 563.971191,1867.558716 563.920288,1871.210815 
	C558.259155,1875.980957 552.119751,1877.725830 545.177979,1876.104858 
	C540.866150,1875.098022 536.595520,1875.844360 531.579956,1876.702148 
	C529.870239,1876.310791 529.083923,1875.733154 528.157959,1874.279053 
	C527.367065,1868.158203 521.953430,1867.889893 518.443481,1864.673096 
	C518.375854,1861.062012 517.019775,1858.315186 516.057007,1854.721680 
	C514.941650,1848.806519 512.842407,1843.582397 517.341431,1838.371338 
z"
        />
        <path
          fill="#5A605E"
          opacity="1.000000"
          stroke="none"
          d="
M529.519226,1876.336426 
	C533.722168,1871.074097 539.077637,1871.202271 543.720764,1872.557007 
	C550.166565,1874.437866 556.140015,1873.879028 563.084595,1871.969238 
	C565.062317,1876.473633 556.834106,1875.468994 558.809204,1880.025269 
	C560.068604,1882.930664 562.685974,1885.229980 564.574280,1887.886475 
	C565.771912,1889.571289 566.570007,1891.637329 564.505066,1893.093384 
	C562.090698,1894.796021 559.609009,1893.574341 558.893677,1891.250488 
	C557.342346,1886.211304 554.422791,1883.888184 549.132202,1883.275635 
	C542.978394,1882.563110 543.026245,1882.149902 541.934326,1890.375000 
	C538.611450,1892.841064 535.629333,1892.642578 532.733215,1888.954102 
	C531.580933,1888.146729 530.846985,1888.035889 529.556274,1887.926270 
	C526.965881,1888.011963 526.666199,1886.467896 526.217712,1884.264771 
	C526.237366,1882.508545 526.419250,1881.526367 526.973877,1879.867676 
	C527.670349,1878.276367 528.228088,1877.463867 529.519226,1876.336426 
z"
        />
        <path
          fill="#D7D5CF"
          opacity="1.000000"
          stroke="none"
          d="
M502.649475,1872.704102 
	C507.814240,1870.961182 506.164093,1865.712524 509.322693,1862.692993 
	C510.275055,1867.468140 511.144714,1871.828613 512.208374,1876.941528 
	C513.262756,1878.241333 514.094116,1878.829956 515.371765,1880.066650 
	C517.682068,1884.458862 519.149231,1888.280762 516.545166,1893.052979 
	C515.951477,1894.588623 515.853943,1895.536377 515.583862,1897.177856 
	C505.433075,1899.457520 504.050873,1897.833252 504.491455,1884.786865 
	C503.721588,1882.978271 503.362823,1881.905762 502.519592,1880.524658 
	C501.649414,1879.802002 501.263733,1879.388184 500.592224,1878.174072 
	C500.097595,1875.595703 500.445923,1873.996704 502.649475,1872.704102 
z"
        />
        <path
          fill="#E5DDC9"
          opacity="1.000000"
          stroke="none"
          d="
M516.889771,1893.710449 
	C516.672791,1889.566895 514.528259,1885.961426 514.072998,1881.326416 
	C515.774780,1876.650513 520.759949,1876.792236 523.426392,1872.976074 
	C524.243286,1872.210449 524.591309,1871.923340 525.548950,1871.333496 
	C527.066589,1870.846924 527.953735,1870.929077 529.222290,1871.791260 
	C529.692383,1873.152832 529.763062,1873.963257 529.991699,1875.362793 
	C529.964722,1876.750000 529.779907,1877.548096 529.581543,1878.963867 
	C529.419678,1879.966187 529.271362,1880.350708 528.974609,1881.335205 
	C528.794617,1883.437012 528.763062,1884.938843 528.865479,1887.183838 
	C528.197815,1895.567139 522.555237,1893.886108 516.889771,1893.710449 
z"
        />
        <path
          fill="#A9AEB3"
          opacity="1.000000"
          stroke="none"
          d="
M504.001343,1884.848999 
	C505.182892,1884.927856 505.961090,1886.094971 505.736694,1887.473755 
	C504.663574,1894.067139 508.841492,1896.203735 514.739380,1897.693359 
	C517.112427,1898.527588 518.492188,1899.568481 519.480225,1901.842773 
	C516.606567,1902.215210 513.608826,1902.119751 510.680084,1901.619751 
	C502.037781,1900.144531 501.183075,1898.852417 502.857544,1890.486938 
	C503.181671,1888.867554 503.565857,1887.260132 504.001343,1884.848999 
z"
        />
        <path
          fill="#3D494C"
          opacity="1.000000"
          stroke="none"
          d="
M568.556763,1860.555786 
	C568.054504,1858.577393 568.032349,1857.049927 568.009949,1854.758789 
	C570.406677,1851.863892 573.137756,1851.246948 577.000854,1852.587891 
	C578.183777,1853.429932 578.577942,1853.868896 579.263672,1855.141602 
	C579.684814,1857.170044 579.489563,1858.308838 578.605225,1860.190430 
	C577.111694,1861.791992 575.980164,1862.594604 574.391602,1863.667480 
	C573.934631,1863.937866 573.195129,1863.411133 572.756409,1863.294434 
	C571.114868,1862.619141 570.019043,1861.898926 568.556763,1860.555786 
z"
        />
        <path
          fill="#D7D5CF"
          opacity="1.000000"
          stroke="none"
          d="
M532.227722,1889.001709 
	C535.040527,1889.136230 537.766174,1890.014893 541.205688,1891.063721 
	C542.492371,1892.633423 543.794312,1894.278687 541.576355,1895.227295 
	C536.813599,1897.264160 534.577454,1895.890503 532.227722,1889.001709 
z"
        />
        <path
          fill="#5A605E"
          opacity="1.000000"
          stroke="none"
          d="
M568.017578,1860.720947 
	C569.656189,1860.209229 571.085510,1860.592041 572.097900,1862.666626 
	C571.330078,1863.412231 570.342407,1863.646606 568.588745,1863.843750 
	C567.867981,1862.983398 567.913147,1862.160278 568.017578,1860.720947 
z"
        />
        <path
          fill="#C4BDAE"
          opacity="1.000000"
          stroke="none"
          d="
M594.016541,1890.897461 
	C595.938782,1891.138184 595.942627,1892.881104 596.053406,1895.227661 
	C594.362915,1894.915161 593.979675,1893.318848 594.016541,1890.897461 
z"
        />
        <path
          fill="#C4BDAE"
          opacity="1.000000"
          stroke="none"
          d="
M596.682251,1883.829834 
	C597.228699,1883.788452 597.653870,1884.478027 597.740173,1884.866333 
	C597.030884,1885.532104 596.235291,1885.809692 594.805542,1885.998535 
	C594.171509,1885.909668 593.999023,1884.970947 593.977417,1884.494141 
	C594.682434,1883.968628 595.409180,1883.920044 596.682251,1883.829834 
z"
        />
        <path
          fill="#313E41"
          opacity="1.000000"
          stroke="none"
          d="
M991.195190,1891.980103 
	C988.926758,1888.347168 990.305786,1883.950317 987.018311,1880.555176 
	C982.051086,1882.247803 981.426697,1887.412598 978.390747,1891.491211 
	C974.268311,1887.199829 967.059753,1887.100220 963.889771,1881.455444 
	C963.249756,1880.315552 961.092896,1880.510010 960.479004,1881.788452 
	C956.252380,1890.590820 948.000366,1886.883789 941.656555,1887.154541 
	C937.468994,1887.333374 933.702515,1886.182617 929.536316,1888.729492 
	C924.045410,1892.085938 924.551697,1885.733521 923.051941,1883.435791 
	C920.067200,1883.216919 920.352478,1885.299683 919.875244,1886.725586 
	C919.259644,1888.565063 917.649292,1890.092163 916.663086,1887.897461 
	C913.887695,1881.720581 911.532471,1887.670654 908.357483,1887.873047 
	C907.766052,1885.210815 907.853333,1882.465210 907.941040,1878.897461 
	C908.371277,1876.496704 908.814148,1874.104736 910.826294,1874.924927 
	C917.325073,1877.573730 921.622375,1874.047363 925.862366,1870.188843 
	C928.093384,1868.158569 931.134155,1866.854492 932.755676,1869.377686 
	C935.373169,1873.450317 936.590149,1874.178589 939.141174,1869.480347 
	C940.119812,1867.678101 941.988342,1869.518555 943.345825,1870.373657 
	C951.639343,1875.597900 956.044067,1875.315063 961.874390,1867.861938 
	C965.120483,1863.712402 970.057983,1862.404175 973.703491,1858.993408 
	C975.726440,1857.100708 979.785828,1856.949951 981.457947,1860.276123 
	C985.444092,1868.204956 992.276062,1867.042480 998.860107,1866.921753 
	C1000.632629,1863.670776 999.776550,1860.620117 1000.028320,1856.886719 
	C1008.121582,1853.371704 1014.367249,1858.194946 1017.181824,1869.291138 
	C1017.832214,1871.855225 1017.318237,1874.343018 1016.982117,1876.054565 
	C1025.660645,1867.078613 1036.882568,1865.621704 1048.530396,1869.157104 
	C1057.406982,1871.851196 1064.315918,1870.308228 1071.208618,1864.530273 
	C1077.921021,1858.903564 1088.221802,1861.602783 1097.693604,1871.431396 
	C1099.768555,1877.142700 1100.432495,1882.346680 1099.921265,1887.697510 
	C1099.705688,1889.953491 1098.862671,1891.136108 1096.160645,1890.971680 
	C1087.073242,1890.418945 1077.947510,1890.403076 1068.881470,1889.643677 
	C1065.560669,1889.365234 1062.413818,1890.497925 1059.674683,1890.471436 
	C1052.520508,1890.402710 1044.172852,1894.029907 1038.709106,1891.111206 
	C1028.795654,1885.815308 1019.880615,1889.232300 1010.771790,1890.534424 
	C1004.510498,1891.429565 998.345703,1891.949463 991.195190,1891.980103 
z"
        />
        <path
          fill="#C4C8C1"
          opacity="1.000000"
          stroke="none"
          d="
M426.013428,1888.760864 
	C438.834900,1889.024292 451.719635,1889.975342 464.597168,1888.535645 
	C467.519073,1888.208862 469.630432,1889.392578 472.647766,1890.713623 
	C473.558075,1891.668823 473.793610,1892.364990 474.252777,1893.568848 
	C472.931244,1895.863403 471.063019,1895.293335 469.030457,1895.170898 
	C463.397461,1894.831421 458.103729,1894.051392 452.248657,1896.913208 
	C443.245483,1901.313599 431.511780,1897.138428 426.013428,1888.760864 
z"
        />
        <path
          fill="#46565D"
          opacity="1.000000"
          stroke="none"
          d="
M720.489014,1885.337158 
	C724.457825,1886.612915 725.273315,1882.519897 727.209961,1882.355713 
	C733.201599,1881.847778 733.303833,1882.213501 734.073486,1876.734375 
	C734.448547,1874.064575 734.568542,1871.069580 737.891663,1870.727783 
	C740.728882,1870.436035 743.147827,1872.153809 743.652466,1875.037598 
	C744.469727,1879.708862 746.312012,1879.586304 749.428345,1876.965698 
	C750.011780,1876.475098 751.798767,1876.363892 752.125305,1876.782837 
	C755.386353,1880.967041 760.850220,1879.160645 765.378296,1881.949219 
	C767.066345,1882.980469 767.753723,1883.883545 768.141663,1885.648315 
	C768.346497,1886.978638 768.454590,1887.732788 768.541016,1889.059326 
	C768.216431,1890.828247 767.927551,1892.030640 766.529663,1893.289551 
	C760.916931,1893.801758 755.955750,1896.615479 750.725342,1892.984985 
	C748.249939,1891.266968 745.815857,1895.089844 742.590881,1895.338013 
	C736.611206,1895.798462 730.975098,1893.630737 724.300049,1894.438477 
	C718.787048,1894.227417 718.301147,1890.326172 716.831299,1886.282471 
	C717.533691,1884.952026 718.604614,1884.741943 720.489014,1885.337158 
z"
        />
        <path
          fill="#A9AEB3"
          opacity="1.000000"
          stroke="none"
          d="
M721.494751,1884.999268 
	C720.186523,1885.595459 719.148682,1885.446167 717.325989,1885.428711 
	C715.618835,1885.251953 715.921082,1882.309692 714.101868,1884.223755 
	C713.111511,1885.265747 714.300964,1887.594482 712.211975,1888.382568 
	C707.705933,1885.580200 712.004395,1879.976074 707.964844,1876.621826 
	C704.736694,1876.040894 702.062317,1875.720459 698.406982,1875.157837 
	C697.787109,1873.581055 698.148193,1872.246704 698.883667,1870.550903 
	C703.668762,1869.667236 708.154114,1874.246582 713.210999,1867.646606 
	C713.014893,1875.277100 713.613403,1880.361206 720.717407,1880.814697 
	C722.004639,1880.896973 721.958374,1882.909668 721.494751,1884.999268 
z"
        />
        <path
          fill="#595B54"
          opacity="1.000000"
          stroke="none"
          d="
M766.048828,1893.950439 
	C766.490967,1892.504517 766.991333,1891.416382 768.005554,1889.980103 
	C769.823120,1891.872925 771.333618,1894.160034 766.655273,1895.115723 
	C766.036621,1895.029785 766.107056,1894.308228 766.048828,1893.950439 
z"
        />
        <path
          fill="#D99469"
          opacity="1.000000"
          stroke="none"
          d="
M501.476440,1878.224121 
	C501.724365,1878.832642 501.877777,1879.523193 501.956482,1879.869629 
	C500.143494,1883.745483 498.251801,1887.274902 495.969360,1891.347168 
	C493.960449,1891.913696 492.338776,1892.016235 490.724762,1891.945312 
	C486.660278,1891.766479 485.684937,1889.640503 487.245575,1886.107422 
	C488.418121,1883.453003 490.051514,1880.973145 490.199036,1877.226196 
	C492.314758,1874.579590 492.372681,1869.944214 497.433899,1871.710449 
	C499.874390,1873.387451 499.976624,1875.851318 501.476440,1878.224121 
z"
        />
        <path
          fill="#595B54"
          opacity="1.000000"
          stroke="none"
          d="
M772.205322,1880.792236 
	C773.732910,1880.116333 775.407349,1880.192139 777.904785,1880.432251 
	C780.470703,1882.816406 779.694763,1885.634155 780.613403,1888.713135 
	C780.572205,1891.150146 779.521240,1892.533447 778.201843,1894.484619 
	C777.503052,1895.289429 777.187744,1895.579712 776.267273,1896.086548 
	C772.513733,1891.842163 775.524902,1885.999634 772.205322,1880.792236 
z"
        />
        <path
          fill="#595B54"
          opacity="1.000000"
          stroke="none"
          d="
M781.174744,1895.388184 
	C781.752258,1898.286499 780.648376,1899.665894 777.477661,1899.630127 
	C776.545715,1898.921509 776.218018,1898.197388 775.776245,1896.888184 
	C775.662109,1896.303223 776.026794,1895.966919 776.207031,1895.796509 
	C777.643433,1894.540405 779.065796,1894.458862 781.174744,1895.388184 
z"
        />
        <path
          fill="#595B54"
          opacity="1.000000"
          stroke="none"
          d="
M768.637207,1885.698486 
	C767.555054,1885.306274 766.871765,1884.388062 766.121826,1882.890137 
	C767.023193,1881.697754 767.991089,1881.084961 769.501526,1880.297363 
	C770.457092,1881.955322 769.801086,1883.574829 768.637207,1885.698486 
z"
        />
        <path
          fill="#595B54"
          opacity="1.000000"
          stroke="none"
          d="
M770.574158,1880.013550 
	C770.290588,1879.077759 770.572876,1878.338989 771.828857,1879.522461 
	C771.962097,1879.916016 771.038879,1880.026367 770.574158,1880.013550 
z"
        />
        <path
          fill="#313E41"
          opacity="1.000000"
          stroke="none"
          d="
M782.584961,1895.661255 
	C780.415405,1895.866943 779.061401,1895.889526 777.047302,1895.769043 
	C776.110596,1893.363647 775.749573,1891.063110 779.440674,1890.121582 
	C780.283813,1889.595703 780.477539,1889.245972 781.116638,1889.297729 
	C785.396667,1890.675903 789.021423,1891.180420 793.198730,1890.316895 
	C798.106323,1889.302490 798.657593,1892.687500 796.342163,1897.205933 
	C791.725098,1897.609375 787.882141,1894.840942 782.584961,1895.661255 
z"
        />
        <path
          fill="#C7C0B1"
          opacity="1.000000"
          stroke="none"
          d="
M1618.717163,3751.901855 
	C1617.205933,3745.276123 1620.781128,3739.735596 1623.445312,3734.176758 
	C1625.202026,3730.511230 1626.453369,3727.820068 1623.595825,3724.316650 
	C1621.261108,3721.455078 1622.820923,3719.245605 1624.817993,3716.496582 
	C1628.916016,3710.855469 1634.136719,3705.077881 1629.408569,3697.181641 
	C1628.067139,3694.941406 1629.853882,3693.506104 1630.775635,3691.842773 
	C1632.327393,3689.043213 1632.405029,3686.109619 1631.236938,3683.209717 
	C1630.569702,3681.553467 1628.669189,3680.041992 1627.403076,3681.244385 
	C1624.247314,3684.241699 1618.962280,3684.207520 1617.627808,3690.210693 
	C1616.419189,3695.646729 1612.470093,3700.481445 1606.738525,3703.907471 
	C1604.607178,3702.900391 1603.544922,3701.226562 1604.558960,3699.764160 
	C1608.810913,3693.632812 1609.562866,3686.324463 1611.901123,3678.759766 
	C1612.149048,3675.745605 1613.714600,3674.354736 1615.558838,3672.434082 
	C1615.997803,3671.111084 1616.000488,3670.222168 1616.008667,3668.666504 
	C1616.042603,3666.247559 1615.968018,3664.473145 1617.552979,3662.483154 
	C1618.009766,3662.009766 1618.000000,3662.000000 1618.004883,3662.004883 
	C1623.627686,3660.367188 1626.420410,3657.071045 1628.508667,3651.057617 
	C1632.790649,3638.726562 1635.972900,3625.770996 1643.606079,3614.779297 
	C1645.006836,3612.762207 1645.388672,3610.579834 1644.388428,3607.585449 
	C1643.447144,3604.746826 1642.376709,3602.752441 1640.398071,3600.451416 
	C1634.013428,3594.536865 1633.422607,3588.469971 1636.335205,3581.117188 
	C1638.816162,3574.854004 1642.415527,3568.656494 1640.289062,3561.201172 
	C1639.573730,3558.693359 1641.766846,3556.260742 1644.176758,3553.783936 
	C1646.284424,3552.056396 1648.078125,3551.240479 1650.443848,3549.987305 
	C1660.543701,3548.063477 1663.615479,3540.188477 1667.089966,3532.852295 
	C1659.417114,3528.696533 1658.734619,3526.237061 1665.566284,3521.789307 
	C1675.817993,3515.115234 1678.133911,3506.403809 1674.331299,3494.659180 
	C1674.153931,3493.000488 1674.213135,3492.051025 1674.565918,3490.419434 
	C1677.765747,3486.263672 1677.613403,3478.575928 1685.886597,3482.230713 
	C1688.925415,3483.573486 1688.958984,3479.621338 1689.777222,3477.713135 
	C1693.917358,3468.056641 1695.001709,3457.287354 1701.598022,3448.458984 
	C1708.518066,3439.196777 1714.917480,3429.674072 1725.666260,3424.244385 
	C1728.135498,3422.996826 1730.365723,3421.882080 1733.714600,3423.284668 
	C1738.413208,3430.562012 1737.872803,3437.671143 1733.984619,3444.163574 
	C1731.082153,3449.009766 1730.262329,3453.852051 1730.780518,3459.187256 
	C1730.959106,3461.026367 1730.096802,3463.548584 1732.631348,3464.216553 
	C1734.685303,3464.757812 1736.202148,3463.011719 1737.710571,3461.752441 
	C1740.619873,3459.324219 1742.007080,3454.868164 1747.447510,3455.142334 
	C1752.234863,3465.515869 1756.197754,3475.539307 1753.989014,3487.053223 
	C1753.082275,3491.780029 1750.536133,3493.828857 1746.925659,3495.787109 
	C1741.383301,3498.794189 1736.637695,3502.217041 1739.958374,3509.818359 
	C1740.731812,3511.588623 1740.078003,3513.803955 1739.582642,3515.733398 
	C1736.579956,3527.426025 1730.746338,3538.624023 1734.234619,3551.396729 
	C1734.915894,3553.891357 1732.882446,3556.493896 1730.613525,3558.148438 
	C1725.000000,3562.241943 1718.755981,3565.917725 1721.704346,3574.671143 
	C1721.986572,3575.509277 1721.321045,3577.229004 1720.670776,3577.307617 
	C1710.142822,3578.576416 1712.060547,3589.431885 1707.026733,3594.944580 
	C1701.285522,3601.232178 1700.658569,3610.100342 1696.844238,3617.414795 
	C1699.435303,3613.768799 1701.094849,3609.753906 1702.316895,3605.428711 
	C1704.452881,3597.868652 1711.155396,3596.139160 1717.622192,3594.486572 
	C1719.607056,3593.979248 1722.072266,3594.813721 1722.551025,3596.951416 
	C1724.071289,3603.739258 1725.837769,3610.502930 1726.750366,3617.394043 
	C1727.219727,3620.937012 1723.936523,3621.772217 1721.292969,3621.807373 
	C1715.786377,3621.880859 1714.148193,3625.584717 1713.022827,3629.825684 
	C1714.726562,3625.856689 1717.387207,3623.381104 1722.059570,3625.535645 
	C1726.561523,3627.611816 1728.704712,3631.177246 1727.339111,3636.077148 
	C1725.406494,3643.010742 1723.699463,3649.165283 1730.666870,3655.145508 
	C1734.478149,3658.416504 1728.153442,3662.713867 1728.243164,3667.647949 
	C1728.375122,3669.762939 1728.269043,3671.169434 1727.919067,3673.264160 
	C1726.088501,3678.254639 1723.831909,3682.293213 1723.585327,3687.141357 
	C1723.391113,3690.958740 1721.523804,3694.020996 1717.072754,3694.507324 
	C1720.687012,3695.153809 1724.865601,3695.567871 1724.918091,3700.376953 
	C1724.963989,3704.567627 1721.767700,3706.842529 1718.100342,3707.989502 
	C1714.152954,3709.224609 1711.054199,3711.304443 1712.315552,3715.728027 
	C1715.824219,3728.031494 1710.443359,3736.197998 1700.718384,3742.665283 
	C1699.903198,3743.207520 1699.455811,3744.183594 1698.898682,3745.770996 
	C1699.276978,3758.695068 1694.385620,3768.468262 1688.502808,3779.310547 
	C1683.178467,3789.123291 1673.913574,3798.075195 1673.739014,3811.328613 
	C1673.573486,3812.504639 1673.462646,3812.970703 1673.050049,3814.094238 
	C1671.622437,3816.338135 1670.242432,3817.669434 1668.021240,3819.109375 
	C1667.017334,3820.419189 1664.119263,3818.121094 1665.301392,3820.786133 
	C1667.737061,3826.277344 1663.665039,3827.883545 1660.164185,3829.479004 
	C1656.565186,3831.119385 1653.035278,3832.736816 1655.355469,3837.359619 
	C1659.001099,3844.623535 1655.510254,3848.275635 1649.507935,3851.507812 
	C1646.393799,3853.184814 1641.493164,3853.779541 1645.005493,3860.290039 
	C1647.130493,3864.228516 1642.011108,3866.438965 1638.261597,3866.559814 
	C1630.980225,3866.794678 1628.315430,3871.463379 1625.688110,3877.266113 
	C1620.870728,3887.905762 1620.745117,3900.711182 1610.565918,3909.544434 
	C1609.113159,3909.968262 1608.239624,3909.999023 1606.711792,3910.051025 
	C1603.177124,3910.379639 1600.296753,3910.686768 1597.637329,3910.970459 
	C1596.611084,3909.912109 1596.489624,3908.824707 1597.170654,3908.069580 
	C1607.426025,3896.695557 1612.159424,3881.923096 1620.875000,3869.589355 
	C1623.324097,3866.123535 1624.323853,3861.710449 1625.832886,3857.781006 
	C1629.159302,3849.118408 1634.333740,3841.427979 1638.749146,3833.534668 
	C1644.718628,3822.862549 1648.438354,3811.406738 1653.666016,3800.541016 
	C1654.516846,3798.772461 1656.020020,3796.997314 1655.204834,3794.955078 
	C1653.809692,3791.459229 1655.691040,3789.294678 1657.807617,3787.067871 
	C1659.237427,3785.563965 1660.132568,3783.718018 1659.932007,3781.650635 
	C1659.519287,3777.395264 1661.188354,3773.933350 1663.486206,3770.545410 
	C1668.136963,3763.688477 1669.734009,3755.676514 1671.488647,3747.804199 
	C1672.288208,3744.216797 1672.395508,3740.600098 1676.020508,3738.401367 
	C1676.699829,3737.989502 1677.434326,3736.394775 1677.155884,3735.877930 
	C1674.670898,3731.265869 1678.640137,3727.959961 1679.733521,3724.654297 
	C1678.763062,3723.126221 1677.886963,3722.626709 1676.875488,3722.842773 
	C1674.654419,3723.317871 1672.078125,3724.752197 1670.689331,3721.944336 
	C1669.494141,3719.527832 1669.170288,3716.115723 1671.332520,3714.452881 
	C1676.330933,3710.608643 1674.706543,3708.176025 1671.098633,3704.389160 
	C1668.945312,3702.128906 1668.789917,3697.886230 1673.162598,3696.294678 
	C1675.620972,3695.399658 1679.537720,3695.367432 1679.024536,3692.213867 
	C1678.351074,3688.074707 1681.140991,3683.489014 1677.645142,3679.828857 
	C1675.641479,3677.731201 1673.975586,3675.380371 1671.928589,3671.331543 
	C1669.936890,3676.640137 1667.532593,3679.468994 1663.792114,3681.813232 
	C1655.730347,3686.866211 1654.201050,3694.903320 1657.896851,3705.413330 
	C1655.984863,3707.622559 1653.348022,3708.193604 1650.687012,3707.936523 
	C1644.781616,3707.366455 1642.845947,3710.824951 1640.949585,3715.682373 
	C1638.088379,3723.011719 1635.738892,3730.706543 1630.343018,3736.842529 
	C1629.302002,3738.026611 1628.202271,3739.749023 1628.242188,3741.191895 
	C1628.415649,3747.466064 1624.088989,3749.708740 1618.717163,3751.901855 
z"
        />
        <path
          fill="#DAD3C3"
          opacity="1.000000"
          stroke="none"
          d="
M1869.007080,3475.989014 
	C1860.874512,3476.267334 1854.111816,3475.552490 1851.685059,3466.912598 
	C1851.407471,3465.924072 1850.878296,3465.100342 1849.671631,3465.101074 
	C1848.139648,3465.101807 1846.917969,3466.074219 1846.778442,3467.319580 
	C1845.750977,3476.484375 1838.343384,3483.911621 1839.889648,3493.837891 
	C1840.753418,3499.382324 1836.835449,3503.850098 1835.187134,3508.829834 
	C1834.050049,3512.264648 1830.287354,3511.582275 1827.548828,3512.494141 
	C1821.627319,3514.465576 1821.083130,3515.395508 1822.810059,3522.040771 
	C1823.526978,3524.799561 1822.186890,3525.376465 1820.183105,3526.254883 
	C1816.709351,3527.777588 1814.241211,3529.662109 1814.902344,3534.492920 
	C1815.548462,3539.214844 1810.282837,3541.202393 1807.916992,3544.550537 
	C1805.038452,3548.624023 1801.646973,3552.101562 1801.167480,3557.398926 
	C1801.022217,3559.004883 1800.553223,3560.843506 1799.589600,3562.070557 
	C1793.496094,3569.830322 1793.521362,3579.280762 1792.028076,3589.151367 
	C1784.904785,3590.035400 1783.456665,3584.560547 1784.607056,3579.875000 
	C1789.442383,3560.179932 1785.893188,3541.327881 1780.335693,3522.539795 
	C1779.255737,3518.888672 1781.780884,3515.291992 1780.048584,3510.994873 
	C1779.512329,3508.871338 1779.354370,3507.420654 1779.138428,3505.243652 
	C1779.083130,3502.118408 1779.294800,3499.702148 1778.358887,3496.652344 
	C1778.168579,3494.959961 1778.191528,3493.987793 1778.428345,3492.301758 
	C1773.062500,3476.905273 1781.367676,3465.267334 1787.840820,3453.742188 
	C1791.435059,3447.343262 1793.798950,3438.833984 1803.502930,3437.110840 
	C1807.389648,3440.821289 1807.025757,3444.968506 1805.267456,3448.580322 
	C1802.371948,3454.528564 1801.959595,3460.671387 1802.353516,3466.842529 
	C1808.463501,3459.117188 1809.191284,3459.088623 1813.783691,3466.570557 
	C1815.824097,3469.894775 1815.823975,3469.894775 1822.806763,3468.820801 
	C1822.752686,3466.165527 1819.975342,3466.443115 1818.762939,3465.063965 
	C1815.064453,3460.856201 1816.125000,3456.500488 1821.148193,3456.330811 
	C1830.128662,3456.027832 1833.629517,3452.195068 1833.381470,3443.721680 
	C1833.355347,3442.831543 1834.212891,3441.908203 1834.882202,3441.174561 
	C1839.012085,3436.646729 1838.076782,3432.782959 1833.307861,3429.530273 
	C1827.879150,3425.827637 1826.202026,3421.117676 1831.139282,3416.287598 
	C1835.463135,3412.057617 1839.593018,3414.120850 1842.760254,3418.689697 
	C1846.092896,3423.497070 1849.065063,3421.829834 1852.392456,3418.343262 
	C1858.367432,3412.081787 1861.554443,3405.281738 1858.717407,3396.485352 
	C1856.913452,3390.891846 1858.527100,3386.720215 1865.674194,3385.362549 
	C1861.366943,3383.054199 1857.447876,3384.451172 1854.184204,3382.657471 
	C1851.755005,3381.322510 1849.533081,3379.788818 1849.155640,3376.877686 
	C1848.724854,3373.555664 1851.308228,3372.315430 1853.759033,3371.151123 
	C1855.566040,3370.292969 1857.504395,3368.969727 1859.362427,3369.180176 
	C1866.444580,3369.982422 1867.666748,3366.967285 1869.327881,3360.183105 
	C1871.508911,3351.275391 1867.960938,3352.052734 1862.320557,3352.909668 
	C1850.976074,3354.633789 1838.604126,3366.817871 1835.865479,3378.762695 
	C1835.615601,3379.852783 1835.969604,3380.632812 1836.257446,3381.584961 
	C1839.456909,3392.167480 1839.487427,3392.384766 1829.394287,3394.728516 
	C1822.782593,3396.264160 1819.431396,3399.339844 1820.943848,3406.589600 
	C1821.670532,3410.072266 1822.550537,3413.894043 1818.949585,3417.457031 
	C1812.061035,3419.853027 1807.435913,3424.076416 1803.870117,3429.350586 
	C1796.708618,3439.943115 1787.721191,3449.386963 1783.296509,3461.726318 
	C1782.100220,3465.062256 1780.182007,3467.862549 1776.123413,3467.131592 
	C1771.989624,3466.387451 1771.529053,3462.768066 1771.274780,3459.445557 
	C1770.808105,3453.348145 1771.637939,3447.295166 1775.588623,3442.512939 
	C1781.151611,3435.779053 1784.904419,3428.094727 1789.093018,3419.756836 
	C1789.764893,3418.744385 1790.052490,3418.359131 1790.841064,3417.471924 
	C1796.981934,3414.854980 1797.380249,3409.960449 1797.829468,3404.354492 
	C1798.134155,3403.268799 1798.290649,3402.843018 1798.768799,3401.816406 
	C1800.193604,3399.776855 1801.333984,3398.365479 1802.716675,3396.273438 
	C1811.486572,3385.189941 1821.032227,3375.295166 1821.844482,3360.734863 
	C1821.881104,3360.077637 1821.899658,3359.057861 1822.365723,3358.895752 
	C1835.468140,3354.333740 1831.096680,3338.522705 1840.732666,3330.850342 
	C1847.014404,3325.848877 1849.651978,3316.351318 1853.348267,3308.565918 
	C1855.575928,3303.873779 1855.958618,3298.209717 1861.470093,3295.343750 
	C1862.935059,3294.582031 1860.344116,3292.681641 1860.721680,3290.394043 
	C1865.872681,3279.015381 1873.336426,3269.453857 1875.599731,3257.424072 
	C1876.192383,3254.273926 1878.542603,3252.138672 1882.184204,3252.678467 
	C1885.578857,3253.181641 1887.399780,3255.568115 1888.130981,3258.664062 
	C1889.680176,3265.223877 1888.813843,3271.871826 1888.363770,3279.672852 
	C1893.017944,3273.309570 1895.308228,3266.209473 1902.723267,3262.784424 
	C1911.247559,3262.716797 1910.571533,3268.515381 1908.031128,3272.187256 
	C1902.464722,3280.232178 1904.365723,3292.520508 1892.413452,3297.252686 
	C1887.868652,3299.052002 1891.053101,3306.675781 1889.378906,3311.172607 
	C1887.745605,3315.559082 1887.937012,3317.718506 1892.336792,3320.249756 
	C1897.428833,3323.179199 1898.449219,3328.145264 1896.878174,3334.173096 
	C1895.193848,3340.636230 1890.458374,3347.259766 1896.933716,3354.220215 
	C1898.401245,3355.798340 1894.417236,3361.194336 1900.974609,3362.035645 
	C1903.389160,3366.393066 1903.264160,3370.418213 1902.489380,3374.357422 
	C1898.055542,3396.895996 1894.357788,3419.578369 1889.292969,3441.992920 
	C1888.968262,3443.429932 1871.206909,3474.333496 1869.007080,3475.989014 
z"
        />
        <path
          fill="#DAD3C3"
          opacity="1.000000"
          stroke="none"
          d="
M1671.367065,3814.096436 
	C1669.392700,3805.996582 1670.104492,3799.128906 1678.227539,3794.428711 
	C1680.560425,3793.079102 1682.298218,3790.771240 1681.571411,3787.756592 
	C1680.123047,3781.751465 1683.658081,3777.228271 1687.343140,3774.165527 
	C1695.439331,3767.437744 1697.359985,3759.535889 1694.984619,3749.874512 
	C1694.763306,3748.974365 1695.009277,3747.958740 1695.515137,3746.493652 
	C1700.203491,3741.850586 1702.937256,3743.047607 1704.429565,3748.381592 
	C1705.742432,3753.073730 1705.927246,3758.042480 1707.277100,3763.172852 
	C1711.326782,3760.424072 1709.145264,3757.228516 1709.184937,3754.558105 
	C1709.270142,3748.824951 1710.581055,3743.941650 1716.361328,3741.510010 
	C1720.888672,3739.605469 1721.401245,3736.476807 1720.848633,3731.860352 
	C1719.850464,3723.522705 1722.622803,3715.750732 1724.847412,3707.666504 
	C1727.679932,3697.373047 1731.896118,3686.351318 1726.203125,3674.665283 
	C1725.992065,3672.498291 1725.984253,3670.996338 1725.974121,3668.743652 
	C1726.772217,3665.232666 1729.082397,3663.740967 1731.170532,3662.205811 
	C1734.372437,3659.851562 1737.955566,3657.182861 1741.988647,3659.754883 
	C1745.861450,3662.224365 1745.765991,3665.910645 1744.384644,3670.261963 
	C1742.544189,3676.058594 1744.214600,3681.159424 1750.373291,3684.625488 
	C1744.226196,3679.890625 1741.263794,3674.866943 1746.094116,3667.996582 
	C1749.137939,3663.666992 1748.344360,3656.697510 1755.377808,3655.017578 
	C1757.338623,3654.549072 1755.500854,3653.200928 1755.097534,3652.134766 
	C1751.796997,3643.410400 1756.458496,3637.561768 1767.493652,3637.542480 
	C1773.514160,3639.546875 1778.882446,3643.158691 1784.665283,3638.371582 
	C1785.728638,3637.490967 1788.004150,3637.893799 1788.869995,3639.744385 
	C1789.551147,3641.200439 1788.574463,3642.517090 1787.555664,3643.212402 
	C1780.759277,3647.850342 1780.593872,3654.522949 1781.532349,3661.663330 
	C1782.043701,3665.554199 1779.078125,3669.336670 1781.049316,3673.357422 
	C1781.228516,3673.722656 1780.671387,3674.938477 1780.318970,3675.002197 
	C1773.655273,3676.211426 1776.389404,3679.431885 1777.095093,3683.932129 
	C1777.885254,3688.972412 1775.576172,3694.528076 1773.112793,3699.808105 
	C1770.624878,3705.140869 1768.411499,3709.072266 1762.414795,3709.968506 
	C1760.745972,3710.218018 1758.708984,3710.544922 1759.065308,3712.674805 
	C1759.454346,3715.000244 1761.459595,3715.862061 1763.657227,3715.009521 
	C1767.102661,3713.673340 1769.484375,3714.473633 1770.853516,3717.951660 
	C1772.182617,3721.328369 1770.308594,3722.991943 1767.449219,3723.879883 
	C1764.171997,3724.897217 1764.466431,3726.867188 1765.676025,3729.288086 
	C1766.270020,3730.477051 1766.986572,3731.696289 1767.186157,3732.973877 
	C1767.783936,3736.796387 1765.794678,3738.804199 1761.638306,3738.807617 
	C1761.318848,3738.807861 1761.003418,3738.565918 1760.679077,3738.544434 
	C1756.929932,3738.298340 1752.101074,3738.701904 1751.349976,3742.137451 
	C1750.605347,3745.543701 1751.192139,3750.115723 1753.293579,3753.620850 
	C1754.660645,3755.900879 1757.062134,3756.053467 1759.517822,3755.184082 
	C1761.731323,3754.400635 1764.299194,3753.336914 1765.446777,3756.418701 
	C1766.550049,3759.381348 1766.267822,3762.280762 1763.220825,3764.356201 
	C1760.452026,3766.241699 1758.022827,3768.547852 1756.309204,3771.476562 
	C1753.787842,3775.785889 1754.522583,3777.355225 1759.307739,3777.962158 
	C1760.293823,3778.087158 1761.306396,3777.991211 1762.305054,3778.034912 
	C1765.057861,3778.155762 1768.384155,3778.321045 1767.333374,3782.186279 
	C1766.468750,3785.365723 1764.384033,3788.438477 1760.007202,3786.669434 
	C1758.790405,3786.177490 1757.661865,3784.789062 1756.548340,3784.848389 
	C1753.282959,3785.023682 1749.416016,3785.035889 1747.263550,3787.479736 
	C1744.811035,3790.263184 1747.778931,3792.873047 1749.281006,3795.397705 
	C1753.360718,3802.253906 1751.277954,3808.391113 1744.737183,3809.414062 
	C1741.710571,3809.887451 1740.344482,3808.598877 1740.114502,3805.761230 
	C1739.897461,3803.083496 1740.028564,3800.442627 1741.031250,3797.889893 
	C1742.416992,3794.362305 1741.986206,3790.666260 1741.921875,3787.011963 
	C1741.875977,3784.401855 1740.942017,3782.468262 1737.995605,3782.213623 
	C1735.087280,3781.962158 1732.321167,3782.367676 1732.220581,3785.966553 
	C1732.090454,3790.616699 1730.157104,3793.205566 1725.605347,3794.630615 
	C1722.350464,3795.649658 1721.932251,3797.931396 1724.895630,3800.541016 
	C1728.270752,3803.512695 1730.140381,3808.122070 1734.914307,3809.018066 
	C1734.773315,3812.504150 1732.016968,3813.187500 1730.587646,3814.863770 
	C1724.775879,3821.679443 1723.933472,3829.286377 1726.163208,3837.565430 
	C1727.932129,3844.133057 1727.943481,3844.107422 1721.799194,3846.387939 
	C1720.240967,3846.966309 1718.735840,3847.693848 1717.226440,3848.396240 
	C1713.928101,3849.931885 1711.326904,3851.419189 1714.418823,3855.764648 
	C1718.706665,3861.791748 1713.343018,3868.555176 1715.332397,3875.180420 
	C1716.283203,3878.346924 1709.686035,3880.612549 1705.977417,3878.450684 
	C1704.359253,3877.507324 1703.792236,3875.034424 1701.185425,3875.073730 
	C1698.182617,3878.769775 1696.130737,3882.776367 1695.924805,3887.664795 
	C1695.760742,3891.555176 1693.869995,3894.522217 1689.684814,3893.429688 
	C1682.077271,3891.443359 1684.567993,3897.024658 1683.958130,3901.171387 
	C1683.299805,3902.086182 1682.397339,3901.975098 1682.030029,3902.366455 
	C1675.438965,3909.384277 1667.236084,3908.332031 1658.487549,3907.568604 
	C1657.080322,3908.906982 1656.073486,3908.797607 1654.522949,3908.182373 
	C1653.393311,3907.770020 1652.880127,3907.381104 1652.236084,3906.426514 
	C1651.307861,3905.281250 1650.918213,3904.433105 1651.087646,3902.739258 
	C1661.776001,3899.188232 1667.036987,3889.473633 1675.640137,3883.080566 
	C1676.256470,3882.103760 1676.531372,3881.729736 1677.279785,3880.843506 
	C1685.603027,3867.669922 1698.493408,3857.893555 1701.686523,3840.437744 
	C1695.205322,3842.212646 1691.934570,3846.646240 1686.427612,3845.979980 
	C1681.657959,3845.402832 1678.538086,3844.523682 1679.540161,3838.993408 
	C1680.492310,3833.738037 1678.079224,3830.780518 1672.740723,3828.903320 
	C1671.540527,3824.786865 1667.066895,3823.047607 1667.563965,3818.447510 
	C1668.176392,3816.177734 1668.724365,3814.644043 1671.367065,3814.096436 
z"
        />
        <path
          fill="#C4BDAE"
          opacity="1.000000"
          stroke="none"
          d="
M1867.965576,3476.492676 
	C1870.720947,3469.405518 1875.459717,3463.613770 1874.837402,3455.917236 
	C1874.653687,3453.645996 1876.790405,3452.381592 1878.855957,3452.265625 
	C1883.191040,3452.021729 1884.464233,3448.895264 1885.036377,3445.630615 
	C1887.716553,3430.336670 1892.524414,3415.487793 1894.147949,3399.954834 
	C1894.934937,3392.425049 1896.563477,3384.915283 1898.570557,3377.604980 
	C1899.837036,3372.992188 1899.894409,3368.448730 1900.110352,3362.949219 
	C1898.888428,3354.090088 1899.837036,3345.997559 1901.627075,3338.445801 
	C1910.714844,3300.108887 1918.749146,3261.548096 1927.072144,3223.047607 
	C1927.424927,3221.415283 1928.010010,3219.876709 1929.402832,3217.854492 
	C1938.220093,3214.890625 1942.484131,3206.547363 1950.395508,3203.596680 
	C1942.018921,3206.313721 1940.494629,3205.478516 1940.902344,3197.744629 
	C1941.261108,3190.942871 1940.276855,3183.737305 1947.778564,3179.234863 
	C1951.138062,3177.218750 1950.663086,3172.812500 1950.988037,3169.147217 
	C1952.615356,3150.791016 1956.671143,3133.261719 1967.077881,3117.536377 
	C1972.105469,3109.939209 1972.690308,3100.516602 1975.058350,3091.922607 
	C1979.310303,3076.492920 1983.549561,3061.153320 1990.814697,3046.813477 
	C1992.351196,3043.780762 1992.770508,3040.474121 1993.100952,3037.148193 
	C1993.436279,3033.771484 1994.164307,3030.507324 1996.847412,3028.150391 
	C1999.565063,3025.763672 2002.192749,3022.403809 2006.200684,3023.281006 
	C2011.430420,3024.425049 2008.645386,3029.362305 2009.782349,3033.244385 
	C2009.890381,3036.525635 2008.908691,3038.670898 2006.702026,3041.004395 
	C2003.729980,3042.965332 2002.543457,3045.102783 2002.888184,3048.696777 
	C2002.962769,3053.211182 2002.447388,3056.929443 2000.379883,3061.017334 
	C1998.969971,3063.548584 1997.006958,3064.987061 1996.842529,3068.106445 
	C1996.657227,3070.152100 1996.312378,3071.473145 1995.561279,3073.395264 
	C1990.695190,3078.135742 1992.092651,3084.117676 1990.359863,3089.852051 
	C1990.025269,3090.970703 1989.865356,3091.411133 1989.406738,3092.487793 
	C1986.218018,3102.060059 1984.611816,3111.438232 1978.936035,3119.373779 
	C1976.620605,3122.611084 1979.310791,3126.336914 1979.476318,3130.610596 
	C1970.364014,3168.734375 1962.688110,3206.382812 1957.001221,3245.185059 
	C1956.853027,3246.891113 1956.757935,3247.850342 1956.526123,3249.479980 
	C1956.047852,3251.840088 1955.501099,3253.502686 1955.805908,3255.913330 
	C1955.926880,3256.996582 1955.962769,3257.435059 1955.986206,3258.541260 
	C1955.454224,3262.767334 1953.687378,3266.047852 1953.466431,3270.390137 
	C1953.080322,3271.486328 1952.862549,3271.902832 1952.129639,3272.803223 
	C1939.658203,3280.492676 1934.489258,3291.924805 1932.396606,3305.006592 
	C1931.773193,3308.904053 1932.319702,3312.965820 1929.894287,3316.465820 
	C1929.142090,3317.551514 1927.959717,3319.134521 1928.690918,3320.079590 
	C1938.368408,3332.586670 1927.117676,3343.500000 1924.522339,3354.146729 
	C1922.072266,3364.198242 1921.162598,3374.853027 1917.169678,3385.312256 
	C1916.924805,3386.904541 1916.807617,3387.818115 1916.520264,3389.402344 
	C1915.380737,3393.455322 1913.357544,3396.744141 1915.625488,3401.099121 
	C1910.532959,3420.519531 1907.399292,3439.963867 1899.706665,3457.817139 
	C1895.420776,3467.764160 1897.171265,3478.112061 1894.084106,3487.836182 
	C1892.755615,3492.020752 1893.606934,3498.083740 1887.602661,3499.017578 
	C1881.310425,3499.996094 1881.447021,3493.112549 1877.891968,3489.438721 
	C1874.780273,3492.186768 1875.488892,3495.908203 1875.097046,3499.230469 
	C1873.032349,3516.735596 1874.872070,3534.377930 1873.221924,3551.913574 
	C1872.723999,3557.205566 1872.609375,3562.509766 1871.180908,3568.439453 
	C1871.527954,3573.464844 1871.371582,3577.710449 1870.950562,3582.745361 
	C1869.321411,3592.926514 1868.498413,3602.494629 1861.659668,3610.146973 
	C1857.711548,3614.564453 1844.286255,3617.567871 1840.151245,3614.075195 
	C1836.604126,3611.078613 1837.138184,3607.081787 1839.496094,3602.602295 
	C1839.980469,3601.986816 1840.000000,3602.000000 1839.993042,3601.990234 
	C1843.611450,3599.336182 1843.962158,3595.595947 1844.091064,3591.437744 
	C1844.364502,3582.620361 1844.993530,3573.659424 1851.023193,3566.531250 
	C1855.690552,3561.013916 1849.076294,3557.636230 1849.569702,3552.544922 
	C1853.632080,3540.116455 1857.084229,3528.187012 1858.099487,3514.856445 
	C1857.867920,3511.147217 1859.137939,3508.913330 1861.422607,3506.417969 
	C1861.873169,3505.040527 1861.908203,3504.169434 1861.945557,3502.645020 
	C1862.119629,3499.651367 1861.693359,3497.216797 1863.507935,3494.522461 
	C1865.641968,3492.864746 1865.892944,3491.165527 1865.857178,3488.688721 
	C1865.365356,3485.789307 1864.977295,3483.554932 1866.091064,3480.678955 
	C1866.753662,3479.017334 1867.244995,3478.012207 1867.965576,3476.492676 
z"
        />
        <path
          fill="#D1C9BA"
          opacity="1.000000"
          stroke="none"
          d="
M1768.103271,3637.271973 
	C1762.965942,3637.482666 1760.229614,3640.573975 1757.590332,3644.270264 
	C1753.774902,3649.614014 1753.816162,3650.141113 1760.196167,3650.895508 
	C1761.539185,3651.054443 1762.964722,3650.764648 1763.415527,3652.240723 
	C1763.981201,3654.093750 1762.794556,3655.279785 1761.275513,3656.234131 
	C1759.239136,3657.512939 1756.970337,3657.880371 1754.667480,3658.231689 
	C1752.924194,3658.498047 1750.546997,3659.972168 1750.936035,3660.936523 
	C1753.661377,3667.693115 1743.309326,3669.699219 1745.772095,3676.572510 
	C1747.556641,3681.552490 1750.853516,3681.991211 1755.028687,3682.083740 
	C1756.909180,3682.125732 1759.437622,3682.099365 1758.729858,3685.148438 
	C1758.297852,3687.009766 1756.549683,3687.322266 1754.744995,3687.211670 
	C1745.999878,3686.676270 1741.785034,3681.827881 1742.130371,3672.371582 
	C1742.201538,3670.422363 1743.183228,3668.503662 1743.237305,3666.557129 
	C1743.382324,3661.343262 1740.191284,3659.694336 1735.480103,3662.264893 
	C1732.577148,3663.848389 1730.885864,3667.166748 1726.669922,3667.908447 
	C1724.238770,3666.479492 1723.633545,3664.746582 1725.290283,3662.859619 
	C1726.154297,3661.875977 1727.346924,3661.188965 1728.290161,3660.265137 
	C1729.584595,3658.997314 1730.189087,3657.265869 1729.221680,3655.761230 
	C1727.871460,3653.661621 1725.904175,3654.208984 1723.984253,3655.253662 
	C1722.966675,3655.807129 1721.976074,3657.307617 1720.588135,3655.796875 
	C1720.093262,3653.779541 1721.153076,3652.787109 1722.827759,3652.027344 
	C1725.223511,3650.940674 1726.034058,3649.478516 1723.109863,3648.090576 
	C1718.980957,3646.130615 1720.233032,3643.668213 1722.071533,3640.870117 
	C1723.340210,3638.939453 1724.658813,3636.924561 1725.346558,3634.754639 
	C1726.675537,3630.561035 1723.696777,3628.810059 1720.506348,3627.343750 
	C1716.844360,3625.660400 1715.785889,3628.370117 1714.762329,3630.844482 
	C1714.013550,3632.654541 1713.904541,3634.774170 1712.961914,3636.446533 
	C1711.977051,3638.194092 1710.038086,3637.862793 1708.588623,3636.904053 
	C1707.130859,3635.939453 1705.950073,3634.099121 1707.628540,3632.822998 
	C1710.833984,3630.385742 1711.518311,3627.019775 1712.685669,3623.522461 
	C1714.530640,3617.994629 1719.107544,3617.536865 1724.841309,3619.509766 
	C1724.592407,3615.288330 1717.844727,3612.495117 1722.811523,3608.247070 
	C1722.935303,3608.141113 1722.446167,3606.802490 1722.023804,3606.651123 
	C1715.642334,3604.368896 1719.724976,3600.579834 1721.357788,3596.881592 
	C1714.267578,3595.477295 1710.781860,3601.384521 1705.643555,3602.910400 
	C1704.191284,3603.341309 1705.322754,3605.295410 1705.063599,3606.447266 
	C1703.245117,3614.528320 1700.002808,3621.658447 1689.846069,3626.232422 
	C1695.784790,3613.649170 1698.962036,3602.059570 1705.279907,3591.661865 
	C1707.492065,3588.021240 1714.181030,3584.549316 1707.689697,3578.604248 
	C1706.390503,3577.414551 1710.917236,3574.376709 1714.136475,3575.427246 
	C1715.851929,3575.986572 1717.410034,3578.459717 1719.189087,3576.037354 
	C1720.491333,3574.264648 1719.825073,3572.175293 1718.469360,3570.534668 
	C1714.468628,3565.692139 1715.959106,3563.268555 1721.618408,3561.359131 
	C1724.888428,3560.256104 1727.063477,3556.866211 1730.232300,3554.948486 
	C1733.411499,3553.023926 1732.336304,3548.789062 1731.430786,3545.725342 
	C1730.272705,3541.806885 1729.914551,3537.929688 1730.014282,3533.929688 
	C1730.064087,3531.931885 1729.784180,3529.495605 1731.446777,3528.316650 
	C1735.868774,3525.180664 1735.501953,3520.512207 1736.517456,3516.027344 
	C1737.685425,3510.867920 1740.027344,3506.030762 1732.642944,3504.759521 
	C1733.440430,3500.866943 1735.971436,3500.287354 1737.763550,3498.916016 
	C1741.381470,3496.146973 1744.508057,3493.128174 1749.128784,3491.298828 
	C1755.181030,3488.903076 1748.046875,3483.058594 1750.287598,3479.028564 
	C1751.075928,3477.610596 1752.267700,3476.403564 1752.963135,3474.950684 
	C1754.043335,3472.694092 1753.862183,3471.074219 1750.630249,3471.556885 
	C1747.556519,3472.015381 1746.086426,3470.820312 1747.187500,3467.625732 
	C1748.304688,3464.383789 1747.873901,3461.043213 1748.040771,3456.914062 
	C1748.154419,3455.048828 1748.524902,3454.112549 1749.710693,3452.758789 
	C1755.411499,3449.326904 1759.690063,3444.987549 1765.387329,3442.974609 
	C1769.802368,3441.414795 1770.002319,3437.182861 1771.784668,3433.728271 
	C1775.878540,3425.793213 1779.253296,3416.466309 1791.471924,3417.513184 
	C1788.912720,3427.413574 1785.136719,3436.219727 1778.067017,3443.696777 
	C1773.091431,3448.958984 1773.198364,3456.510986 1774.542236,3463.477783 
	C1774.986572,3465.781738 1777.613770,3466.361572 1779.084473,3464.339111 
	C1780.992676,3461.715332 1782.419556,3458.670166 1783.662109,3455.645752 
	C1789.737183,3440.861084 1799.891235,3429.082520 1811.069214,3418.037842 
	C1812.562866,3416.561768 1814.454102,3416.051758 1817.237549,3416.021973 
	C1820.844116,3419.632568 1817.995850,3421.937744 1815.897949,3424.215088 
	C1813.737427,3426.561035 1810.914185,3428.288574 1809.152222,3431.869141 
	C1808.757324,3433.442871 1808.511597,3434.343506 1807.895264,3435.873535 
	C1807.010498,3437.306885 1806.286499,3437.913330 1804.708984,3438.174316 
	C1802.336060,3438.199951 1800.325928,3438.362793 1799.622559,3439.932129 
	C1795.854492,3448.338867 1788.446533,3454.602295 1785.784546,3463.650879 
	C1785.324219,3465.215820 1784.793701,3467.199951 1783.616577,3468.043945 
	C1775.805542,3473.646240 1779.379517,3480.010742 1782.358276,3486.133057 
	C1783.545776,3488.573486 1784.426025,3490.286377 1780.756836,3491.875000 
	C1769.853271,3491.289062 1775.367310,3481.991211 1770.284790,3476.127930 
	C1779.120117,3502.135742 1765.260864,3524.092041 1762.688477,3547.576172 
	C1759.446411,3577.176025 1752.152466,3606.275391 1749.741089,3637.860107 
	C1753.805908,3630.458496 1751.555420,3622.608887 1755.512939,3617.367432 
	C1764.660156,3605.251709 1760.869507,3591.796631 1760.426392,3578.769287 
	C1760.159058,3570.912109 1762.964111,3564.385742 1766.860107,3558.214844 
	C1771.820068,3550.358154 1773.939453,3542.253906 1773.904541,3532.817871 
	C1773.875244,3524.891846 1773.280151,3515.946289 1781.253418,3509.875000 
	C1782.543335,3510.796387 1783.706543,3511.669678 1783.607788,3512.363525 
	C1782.192139,3522.307373 1785.178467,3531.280518 1789.409180,3540.012939 
	C1789.552246,3540.308350 1789.544678,3540.690674 1789.723999,3540.952637 
	C1792.464844,3544.957764 1784.537720,3548.835693 1788.458618,3551.305176 
	C1793.312378,3554.362061 1789.867554,3557.715576 1790.813354,3560.657227 
	C1792.268433,3565.182129 1788.870361,3568.209717 1788.464111,3571.975098 
	C1787.829102,3577.861328 1784.105347,3584.373779 1791.413086,3589.659424 
	C1792.026733,3589.983398 1792.052979,3590.962158 1792.058228,3591.451660 
	C1792.436157,3592.295654 1792.808716,3592.649658 1792.596802,3593.506836 
	C1791.070435,3594.629639 1790.128540,3595.249756 1788.601318,3595.920410 
	C1787.217285,3595.354980 1786.166016,3594.715576 1785.331177,3595.152344 
	C1777.541626,3599.228271 1768.208252,3618.342773 1769.960083,3627.326660 
	C1770.342651,3631.046143 1769.003540,3633.737305 1768.103271,3637.271973 
z"
        />
        <path
          fill="#DAD3C3"
          opacity="1.000000"
          stroke="none"
          d="
M1839.336426,3602.103027 
	C1840.450562,3605.520020 1839.050537,3609.280762 1841.126099,3612.572021 
	C1843.818970,3613.708496 1844.757812,3611.592529 1846.084595,3610.218994 
	C1847.076294,3609.192139 1848.345947,3608.390137 1849.340454,3609.929688 
	C1850.603149,3611.884521 1851.399536,3614.484375 1854.443359,3613.371094 
	C1857.441040,3612.274170 1859.862427,3609.936768 1859.756958,3606.758057 
	C1859.595093,3601.884033 1866.453979,3602.180176 1865.956177,3597.267822 
	C1865.550415,3593.263184 1866.158691,3589.296143 1867.922852,3584.747559 
	C1872.155273,3581.413086 1874.538696,3583.130371 1876.173584,3587.964355 
	C1873.252808,3610.158691 1874.348999,3632.230469 1865.041016,3653.221680 
	C1860.201538,3656.515381 1858.414551,3660.592285 1858.112305,3666.241943 
	C1857.769531,3667.362549 1857.600830,3667.801025 1857.101318,3668.859863 
	C1853.612793,3674.690674 1852.766968,3681.594727 1843.056274,3684.524658 
	C1855.143311,3686.196045 1854.521851,3692.060791 1851.323242,3698.483398 
	C1849.799561,3701.543213 1848.550903,3704.381348 1849.674316,3708.464844 
	C1849.672729,3709.579834 1849.628052,3710.020264 1849.400879,3711.088867 
	C1848.598877,3713.273438 1847.282959,3714.445557 1846.656372,3716.675781 
	C1846.261353,3718.075195 1845.980957,3718.867920 1845.399902,3720.253662 
	C1843.993408,3722.785645 1842.560425,3724.545654 1841.575195,3727.285645 
	C1841.004883,3729.095947 1841.093262,3730.334717 1841.121460,3732.187500 
	C1840.882446,3734.038086 1840.361328,3735.146484 1839.350830,3736.695312 
	C1838.059570,3738.462891 1837.147949,3739.751953 1836.381836,3741.802734 
	C1836.032349,3742.718262 1835.883057,3743.078613 1835.471436,3743.955566 
	C1834.822021,3745.115723 1834.441895,3745.763916 1833.907227,3746.930664 
	C1833.526367,3748.959229 1834.052124,3750.354980 1834.229126,3752.901367 
	C1830.369995,3754.028076 1826.810181,3754.098145 1823.181885,3755.163086 
	C1819.104614,3756.359619 1814.906128,3756.725586 1817.686401,3762.665527 
	C1817.808228,3762.926025 1817.774414,3763.406738 1817.607544,3763.633057 
	C1811.355835,3772.111084 1812.205200,3785.520508 1799.420410,3789.521240 
	C1795.916504,3790.617920 1796.004517,3794.023438 1796.009888,3797.089600 
	C1796.020996,3803.328369 1797.083618,3809.723877 1789.301392,3813.121826 
	C1787.118530,3814.074463 1786.030396,3817.956055 1784.851074,3820.672852 
	C1783.583130,3823.593994 1783.894653,3827.156738 1779.975830,3828.734863 
	C1777.435547,3829.758301 1778.025269,3832.711670 1778.034424,3834.980225 
	C1778.048218,3838.419678 1777.198120,3841.145020 1774.684692,3843.917725 
	C1770.397705,3848.647217 1771.472778,3850.710938 1777.981201,3851.977539 
	C1779.757935,3852.323486 1782.165405,3851.408203 1782.688232,3854.398926 
	C1780.053101,3856.220947 1777.653076,3853.822021 1775.082397,3853.955811 
	C1770.150879,3854.212891 1765.032715,3853.185791 1761.201782,3857.676270 
	C1759.444824,3859.735596 1757.246216,3859.682861 1754.904663,3858.340820 
	C1752.449585,3856.934082 1751.498291,3854.864014 1752.881348,3852.531494 
	C1755.110229,3848.773193 1750.013550,3846.654053 1751.071777,3843.420410 
	C1752.481201,3841.961182 1754.199341,3842.095947 1755.825928,3841.922363 
	C1758.138916,3841.675293 1759.461792,3840.771729 1758.950684,3838.079590 
	C1757.753540,3831.773193 1760.153320,3825.684570 1760.426758,3819.427246 
	C1760.639160,3814.565674 1762.918945,3809.845703 1758.341309,3806.518311 
	C1761.453003,3802.351562 1765.876343,3807.374268 1767.109985,3804.093262 
	C1769.383057,3798.047607 1773.984741,3792.450195 1772.684448,3785.486572 
	C1771.905029,3781.313965 1773.000366,3777.112549 1776.038818,3775.814941 
	C1784.404907,3772.242676 1783.507568,3764.253906 1783.918335,3758.380371 
	C1784.694580,3747.281738 1788.259399,3737.526611 1792.748779,3727.705566 
	C1794.522217,3723.826172 1797.908936,3720.818604 1798.443115,3716.258057 
	C1798.746460,3713.667725 1800.913086,3712.237061 1803.441528,3712.182373 
	C1807.883301,3712.086426 1810.339600,3710.437256 1810.005249,3705.601562 
	C1809.718628,3701.455811 1811.215454,3696.517822 1804.745239,3695.639160 
	C1804.247559,3695.571533 1803.842651,3694.818115 1802.720825,3693.730713 
	C1809.869141,3689.946533 1809.715332,3681.767822 1813.865723,3676.008545 
	C1815.788330,3673.340576 1816.814331,3670.058594 1819.260010,3667.674072 
	C1820.711914,3666.259033 1822.131836,3664.535645 1821.602295,3662.428467 
	C1818.909668,3651.716064 1823.457397,3643.128174 1828.874756,3634.441650 
	C1831.260132,3630.616943 1830.190796,3625.404053 1831.905762,3620.989014 
	C1833.408936,3617.118896 1838.126465,3613.819580 1833.562622,3608.437256 
	C1832.120239,3606.736084 1836.053345,3603.678223 1839.336426,3602.103027 
z"
        />
        <path
          fill="#F2EBDD"
          opacity="1.000000"
          stroke="none"
          d="
M1861.518066,3654.443359 
	C1868.608276,3642.422363 1868.287109,3629.372314 1869.874390,3616.767334 
	C1870.405151,3612.552002 1869.713013,3608.142822 1871.700073,3604.178467 
	C1873.861206,3599.866699 1873.352783,3595.383057 1873.425781,3590.391113 
	C1882.838257,3582.577148 1891.881958,3581.544189 1900.136719,3587.031006 
	C1903.581421,3589.321045 1906.527710,3592.220459 1905.286133,3596.797119 
	C1904.156616,3600.960205 1900.654419,3602.033447 1896.693970,3601.913818 
	C1895.374146,3601.874023 1894.055298,3601.702637 1892.734985,3601.801270 
	C1891.028076,3601.928711 1889.354248,3602.172119 1889.302612,3604.439941 
	C1889.279419,3605.452637 1889.572388,3606.459473 1890.299683,3607.138184 
	C1891.779419,3608.519043 1893.402466,3608.040039 1894.775024,3606.991943 
	C1898.398438,3604.225342 1901.606079,3600.331055 1907.484741,3603.521973 
	C1907.980347,3607.437500 1907.977539,3610.863770 1907.982788,3615.145996 
	C1909.402466,3617.497070 1909.710571,3619.664062 1911.554932,3621.586914 
	C1912.053345,3624.368408 1911.847412,3626.658447 1910.279541,3629.355469 
	C1907.269531,3632.925049 1903.328735,3633.772949 1900.019287,3635.706299 
	C1897.106689,3637.407959 1894.495483,3638.998291 1894.354614,3643.443359 
	C1889.035278,3645.138916 1887.438354,3648.791748 1888.808228,3652.999512 
	C1889.837036,3656.160889 1887.363647,3657.843262 1887.213623,3660.885986 
	C1892.122681,3662.971680 1895.772949,3662.149170 1898.494629,3657.097900 
	C1900.077026,3654.160889 1900.693848,3649.391113 1906.001953,3651.161377 
	C1911.192993,3652.892334 1909.281494,3656.614258 1908.491455,3660.197021 
	C1908.165039,3661.677246 1909.108521,3663.396240 1909.235596,3665.025635 
	C1909.544189,3668.979248 1906.262329,3669.804688 1903.638184,3671.059326 
	C1901.196899,3672.226807 1899.729126,3671.412109 1897.897827,3669.135254 
	C1893.489136,3663.653564 1891.654785,3664.016357 1888.002441,3670.109131 
	C1887.149902,3671.531250 1886.434814,3673.046387 1885.773926,3674.569824 
	C1884.878784,3676.633301 1883.152100,3678.862061 1885.479126,3680.897949 
	C1887.409302,3682.586426 1889.902832,3682.235840 1892.089233,3681.338135 
	C1894.295776,3680.431885 1896.109375,3677.051270 1898.778564,3680.440430 
	C1900.162842,3682.197998 1899.732422,3684.163818 1898.875244,3686.083496 
	C1897.421753,3689.338867 1894.804932,3690.828857 1891.622681,3689.465332 
	C1885.718018,3686.935059 1880.809204,3690.600342 1874.528809,3690.781250 
	C1877.290527,3695.289795 1880.888062,3693.556641 1883.793579,3693.988037 
	C1886.306030,3694.361084 1889.780396,3692.961914 1890.966553,3695.931885 
	C1892.412720,3699.553223 1890.211914,3702.973145 1888.307495,3706.045654 
	C1887.399170,3707.510986 1885.533447,3707.607178 1883.878784,3707.952148 
	C1874.748657,3709.855225 1874.566284,3710.290039 1878.341553,3719.492432 
	C1880.142090,3723.881104 1878.053223,3736.530273 1874.841309,3739.960205 
	C1873.077515,3741.843506 1870.615234,3742.902832 1869.950806,3745.944092 
	C1868.903564,3750.737305 1867.500854,3754.358887 1862.944824,3747.581299 
	C1861.004150,3744.695068 1857.574097,3745.618408 1856.016479,3749.315186 
	C1855.629761,3750.232910 1855.097046,3751.171387 1855.028809,3752.128418 
	C1854.526733,3759.162109 1852.020630,3763.962891 1843.920776,3764.593750 
	C1840.734253,3764.842285 1840.722534,3766.399170 1843.122803,3773.170410 
	C1839.084229,3772.737305 1835.007690,3771.749756 1832.360352,3776.737061 
	C1827.556763,3768.003418 1831.427246,3761.844727 1833.915283,3754.785645 
	C1834.019287,3754.029297 1834.009399,3754.003662 1834.021484,3754.010742 
	C1835.590210,3751.115723 1837.294434,3748.221924 1834.235107,3744.521240 
	C1834.221558,3741.198486 1833.044189,3737.938721 1837.278320,3736.132812 
	C1838.855713,3734.914551 1841.022949,3734.989746 1840.324951,3732.450439 
	C1839.363892,3731.093018 1838.761719,3730.156982 1838.116211,3728.641846 
	C1840.814697,3726.489990 1842.762939,3724.226318 1843.981812,3720.651855 
	C1844.027222,3719.124268 1844.044800,3718.248291 1844.072998,3716.715820 
	C1845.086182,3714.936523 1846.148438,3713.871582 1847.608887,3712.407227 
	C1848.011719,3712.012207 1848.000000,3712.000000 1848.007324,3712.004883 
	C1848.821289,3704.372314 1851.100586,3697.274658 1854.465454,3690.300293 
	C1857.712402,3683.570801 1853.674561,3676.119385 1855.554443,3668.518799 
	C1856.004883,3668.000000 1856.000000,3668.000000 1856.002441,3668.000000 
	C1859.661499,3664.406494 1859.008545,3659.138184 1861.518066,3654.443359 
z"
        />
        <path
          fill="#EAE5D5"
          opacity="1.000000"
          stroke="none"
          d="
M1908.318115,3603.403564 
	C1902.683472,3603.253174 1899.332275,3605.998047 1896.360229,3610.002441 
	C1893.256104,3614.185303 1888.062012,3613.346924 1886.161987,3608.537109 
	C1885.558350,3607.008789 1885.421265,3605.302734 1884.909546,3603.730225 
	C1883.606934,3599.727783 1885.389282,3598.240234 1889.197266,3598.011230 
	C1892.275879,3597.826172 1895.074341,3598.830078 1897.975220,3599.520020 
	C1900.114380,3600.028320 1901.903320,3599.137695 1902.999512,3597.201416 
	C1904.165894,3595.141357 1903.317505,3593.551025 1901.871338,3591.886475 
	C1897.720581,3587.107422 1887.707886,3584.690186 1881.850220,3587.516113 
	C1879.762695,3588.523438 1877.946777,3590.038818 1874.675293,3589.922852 
	C1874.105103,3586.701660 1873.534424,3584.079102 1868.790527,3583.982910 
	C1868.114624,3579.269531 1868.161987,3574.590332 1868.239502,3569.035156 
	C1871.691772,3563.563477 1876.271729,3562.507080 1882.340576,3564.061523 
	C1886.620850,3566.316406 1887.165649,3570.150146 1888.588623,3573.425781 
	C1889.612793,3575.782959 1891.142090,3577.309570 1893.858765,3576.992676 
	C1896.949463,3576.631836 1898.762695,3574.656738 1898.720459,3571.691895 
	C1898.618774,3564.553223 1896.934937,3557.025879 1906.102295,3553.522217 
	C1907.473877,3552.998047 1906.902588,3550.475830 1905.918213,3549.033691 
	C1904.828857,3547.437988 1903.130859,3547.370850 1901.478638,3547.840820 
	C1897.096191,3549.087158 1892.841064,3551.586182 1887.610840,3548.430420 
	C1885.865723,3538.413574 1891.495483,3532.485596 1898.718506,3527.090332 
	C1900.900391,3527.956543 1901.598267,3529.240723 1902.184082,3531.379395 
	C1902.130615,3536.522949 1904.970459,3536.018799 1908.523682,3535.359619 
	C1918.708618,3534.481689 1922.326050,3540.699463 1923.320435,3548.440918 
	C1924.112671,3554.607666 1924.949341,3560.879639 1924.678101,3567.190430 
	C1924.590332,3569.228516 1922.746338,3572.268799 1926.188477,3573.036133 
	C1929.021606,3573.667480 1932.417847,3573.710693 1934.090454,3570.530762 
	C1936.028320,3566.846191 1937.684814,3563.198975 1943.458496,3565.537598 
	C1943.529663,3570.513184 1946.859985,3574.632324 1944.703003,3579.565918 
	C1943.222412,3582.952637 1946.370728,3583.964355 1949.369019,3583.904541 
	C1952.240479,3583.847168 1953.757202,3582.745117 1952.808105,3579.570068 
	C1952.434692,3578.321045 1952.146484,3576.974609 1952.192505,3575.685791 
	C1952.298828,3572.701416 1953.729004,3570.431641 1956.906372,3570.388672 
	C1960.536011,3570.339355 1961.214844,3572.629395 1960.400146,3575.799805 
	C1960.064697,3577.104736 1960.414795,3578.375977 1962.082031,3578.868896 
	C1964.928467,3577.695801 1963.571899,3574.872314 1964.290894,3572.866455 
	C1964.799683,3571.447510 1965.716309,3570.516846 1967.114990,3570.594727 
	C1968.495972,3570.671143 1969.624268,3571.609863 1969.689209,3573.126221 
	C1969.949097,3579.191650 1968.494751,3584.928711 1965.495605,3590.130859 
	C1964.175781,3592.419922 1961.467651,3592.200928 1959.190308,3591.402832 
	C1956.666626,3590.518799 1954.475830,3590.556152 1953.496460,3593.356201 
	C1952.000000,3597.635010 1951.255127,3602.387695 1945.440796,3603.039307 
	C1945.211426,3603.064941 1944.798950,3603.611816 1944.856812,3603.735352 
	C1947.189819,3608.714844 1945.061279,3610.988525 1941.136841,3614.623291 
	C1938.641479,3616.934814 1942.493164,3623.755371 1943.909668,3629.253418 
	C1944.768066,3630.795166 1945.052490,3631.715820 1944.414307,3633.390625 
	C1940.401978,3637.395508 1946.023560,3638.288330 1945.901245,3641.381348 
	C1945.601562,3643.388916 1944.638428,3644.165039 1942.647217,3644.253418 
	C1933.110229,3644.829102 1931.377808,3642.905273 1931.972168,3635.191650 
	C1932.203613,3632.188965 1931.850708,3629.251221 1929.846802,3626.746094 
	C1928.077881,3624.535156 1927.589844,3621.810059 1928.076050,3619.220947 
	C1929.696167,3610.594238 1924.854126,3603.225830 1923.653320,3595.157959 
	C1923.195068,3592.080322 1920.015869,3591.149902 1918.889038,3593.857422 
	C1916.820312,3598.827881 1911.304565,3599.026611 1908.318115,3603.403564 
z"
        />
        <path
          fill="#D8D1C0"
          opacity="1.000000"
          stroke="none"
          d="
M1651.635864,3901.405273 
	C1651.996582,3902.896484 1651.998047,3903.783203 1651.999756,3905.334961 
	C1648.045532,3911.187744 1642.498779,3908.617920 1637.625977,3908.705566 
	C1634.395142,3908.763428 1631.101929,3907.781494 1627.910889,3906.972168 
	C1624.146606,3906.017822 1622.236206,3908.312988 1620.823364,3910.970947 
	C1619.774292,3912.944824 1619.911377,3914.973145 1623.212036,3914.454834 
	C1624.847534,3914.198242 1626.757202,3913.912109 1627.034912,3916.229736 
	C1627.267334,3918.170410 1625.585205,3919.051758 1624.144287,3919.857178 
	C1618.877563,3922.801025 1613.961670,3920.852783 1611.886841,3915.034912 
	C1611.443237,3913.791260 1611.012573,3912.542725 1610.281494,3910.616943 
	C1610.119751,3907.957520 1609.519409,3904.793457 1610.501831,3904.184814 
	C1618.417480,3899.281982 1616.247314,3889.894287 1620.222168,3883.313477 
	C1621.567139,3881.086426 1622.286621,3878.441162 1623.057495,3875.915527 
	C1625.465576,3868.027588 1629.030640,3861.898193 1638.978394,3863.878418 
	C1640.880981,3864.257324 1643.221924,3863.742920 1643.463745,3861.128418 
	C1643.736328,3858.181885 1642.155151,3856.946045 1639.172974,3857.308594 
	C1638.261353,3857.419678 1637.108276,3857.224609 1636.384033,3856.716064 
	C1635.682495,3856.223877 1635.135010,3855.193848 1634.980957,3854.318848 
	C1634.775146,3853.149658 1635.873657,3852.245117 1636.793579,3852.499512 
	C1644.895630,3854.742920 1648.179443,3846.075684 1654.502319,3845.455566 
	C1655.276001,3842.080078 1652.684448,3840.797852 1652.038086,3838.581787 
	C1649.582764,3830.162109 1649.470947,3830.316406 1658.045532,3827.577637 
	C1660.669067,3826.739014 1663.890991,3826.199951 1664.844971,3822.338135 
	C1661.381348,3819.593262 1656.925781,3824.150146 1653.446777,3820.357178 
	C1657.234497,3816.469238 1662.072510,3818.589600 1667.145752,3817.981689 
	C1668.150635,3819.262451 1668.421021,3820.396240 1670.032959,3820.680176 
	C1675.240967,3821.596680 1675.235962,3821.625244 1671.797363,3827.942871 
	C1666.548828,3831.505615 1666.292725,3835.401123 1667.921143,3840.542969 
	C1669.712646,3850.230469 1671.398926,3851.362549 1679.700195,3848.745117 
	C1683.048706,3847.689453 1686.723633,3844.752197 1689.161377,3849.618652 
	C1691.146973,3853.583008 1688.711426,3856.590332 1685.702026,3859.052002 
	C1683.095947,3861.183105 1681.353394,3864.062012 1679.125000,3866.516602 
	C1676.976929,3868.882324 1673.791748,3870.959961 1671.231323,3868.979248 
	C1663.113770,3862.699707 1661.164185,3869.683350 1658.437744,3874.529297 
	C1656.179199,3878.543213 1654.698486,3882.780029 1650.894653,3886.009521 
	C1643.680298,3892.134521 1643.957275,3893.785889 1651.635864,3901.405273 
z"
        />
        <path
          fill="#DAD3C3"
          opacity="1.000000"
          stroke="none"
          d="
M1770.625244,3627.788086 
	C1765.279297,3624.166504 1763.408081,3618.820312 1766.053345,3615.168945 
	C1770.023804,3609.688721 1775.245117,3605.271973 1776.918213,3598.017090 
	C1777.552979,3595.264648 1781.729248,3592.888184 1784.783447,3591.267334 
	C1787.467896,3589.842285 1787.662964,3592.699463 1787.993896,3595.281494 
	C1784.947876,3597.467041 1783.935059,3600.046143 1783.997437,3603.299561 
	C1784.041626,3605.601074 1783.718994,3607.782715 1782.758789,3610.019531 
	C1779.848145,3616.799805 1782.706543,3622.388428 1789.603760,3623.867676 
	C1790.878418,3624.140869 1792.240479,3623.998779 1793.561401,3624.065674 
	C1795.296265,3624.153809 1797.269897,3624.600342 1797.018066,3626.606445 
	C1796.786865,3628.447998 1795.639038,3630.271240 1794.565430,3631.883057 
	C1793.528198,3633.440186 1791.252197,3634.004639 1790.317749,3632.603271 
	C1787.385498,3628.206055 1784.439331,3628.479736 1780.521118,3631.222412 
	C1779.119629,3632.203369 1777.531982,3630.384277 1776.317261,3629.283691 
	C1774.843628,3627.948730 1773.477173,3626.384033 1770.625244,3627.788086 
z"
        />
        <path
          fill="#EAE6D7"
          opacity="1.000000"
          stroke="none"
          d="
M1899.254639,3752.154785 
	C1898.488403,3755.423828 1898.283813,3758.069580 1897.734253,3760.642090 
	C1896.980103,3764.172119 1894.119019,3764.148438 1891.432495,3763.738525 
	C1888.322998,3763.263672 1887.846558,3761.004883 1888.885010,3758.516357 
	C1890.438965,3754.792480 1889.455811,3750.548340 1891.541016,3746.946289 
	C1893.980103,3742.733154 1894.796021,3738.321045 1892.906372,3733.593506 
	C1891.917358,3731.118652 1892.205811,3728.827148 1895.320068,3728.283203 
	C1897.971802,3727.819824 1901.120361,3727.652588 1901.660767,3731.186279 
	C1902.119507,3734.185791 1904.917480,3737.329834 1901.576660,3740.447021 
	C1898.219360,3743.578613 1903.638672,3748.377686 1899.254639,3752.154785 
z"
        />
        <path
          fill="#DAD3C3"
          opacity="1.000000"
          stroke="none"
          d="
M1849.266113,3552.190918 
	C1850.312256,3553.284180 1850.612183,3554.539307 1851.831665,3555.339111 
	C1853.577759,3556.483887 1857.364624,3555.432373 1856.949585,3558.668945 
	C1856.549072,3561.792236 1857.003784,3565.765137 1854.406494,3567.924561 
	C1846.644531,3574.378174 1847.028931,3583.430908 1846.047363,3592.051270 
	C1845.610840,3595.885254 1846.672852,3600.203857 1840.757080,3601.871826 
	C1841.936768,3592.643066 1842.523682,3583.186523 1843.308228,3573.695312 
	C1843.895630,3566.588867 1844.576782,3558.924805 1849.266113,3552.190918 
z"
        />
        <path
          fill="#DBD5C8"
          opacity="1.000000"
          stroke="none"
          d="
M1925.552002,3657.581055 
	C1926.011841,3658.888916 1926.004028,3659.777832 1925.990723,3661.333496 
	C1923.511963,3661.840820 1921.460693,3660.864746 1920.385620,3658.562012 
	C1917.213013,3651.766602 1917.004028,3644.127930 1914.290161,3637.122070 
	C1913.039429,3633.893555 1915.562866,3631.409912 1919.554443,3632.899902 
	C1921.991089,3633.809082 1924.757935,3633.974609 1926.771362,3635.924072 
	C1927.970581,3637.084961 1929.716431,3638.916260 1928.343628,3640.011475 
	C1922.182983,3644.926514 1925.974487,3651.440186 1925.552002,3657.581055 
z"
        />
        <path
          fill="#EAE5D5"
          opacity="1.000000"
          stroke="none"
          d="
M1894.470703,3644.446777 
	C1890.892700,3638.591309 1891.114380,3638.922119 1896.623291,3635.229980 
	C1900.326172,3632.748291 1903.065430,3628.671387 1909.188721,3629.995117 
	C1910.133423,3632.289795 1910.271973,3634.589355 1910.401733,3636.743896 
	C1908.962891,3638.232666 1907.242310,3638.116211 1905.653198,3637.965088 
	C1901.021973,3637.524414 1897.919434,3638.867432 1897.361450,3644.041992 
	C1897.263672,3644.947998 1895.996094,3645.294434 1894.470703,3644.446777 
z"
        />
        <path
          fill="#DAD3C3"
          opacity="1.000000"
          stroke="none"
          d="
M1865.416504,3480.017334 
	C1868.412842,3482.241211 1868.340088,3484.650879 1866.361694,3487.602051 
	C1863.542114,3487.994385 1861.236694,3487.998047 1858.933960,3487.923828 
	C1856.880859,3487.857422 1854.771729,3487.506104 1854.522949,3485.007324 
	C1854.304077,3482.807861 1855.670410,3481.094238 1857.732422,3480.610840 
	C1859.937622,3480.093994 1862.285400,3480.185791 1865.416504,3480.017334 
z"
        />
        <path
          fill="#EAE6D7"
          opacity="1.000000"
          stroke="none"
          d="
M1683.981934,3901.975586 
	C1687.926270,3902.421143 1690.116943,3907.189697 1694.747437,3906.275635 
	C1696.170288,3905.994873 1697.234009,3907.315918 1696.913452,3908.810303 
	C1696.738403,3909.626465 1695.861816,3910.662598 1695.081177,3910.912842 
	C1692.031494,3911.890625 1689.289062,3910.095459 1686.474243,3909.407959 
	C1683.583008,3908.701172 1684.199585,3905.781982 1683.973755,3902.750488 
	C1683.953003,3901.963135 1684.000000,3902.000000 1683.981934,3901.975586 
z"
        />
        <path
          fill="#EAE6D7"
          opacity="1.000000"
          stroke="none"
          d="
M1742.185181,3866.301025 
	C1738.954956,3867.768799 1735.436523,3869.746094 1735.104370,3864.916504 
	C1734.945923,3862.612305 1735.339844,3858.150879 1739.480347,3858.583740 
	C1743.644165,3859.019043 1744.651978,3862.017822 1742.185181,3866.301025 
z"
        />
        <path
          fill="#EAE6D7"
          opacity="1.000000"
          stroke="none"
          d="
M1792.751831,3849.509277 
	C1789.329346,3852.501953 1786.894409,3852.449463 1786.176147,3848.081299 
	C1785.815918,3845.890381 1785.569458,3843.519775 1787.526489,3840.843018 
	C1790.294922,3842.974609 1792.289307,3845.455078 1792.751831,3849.509277 
z"
        />
        <path
          fill="#EAE6D7"
          opacity="1.000000"
          stroke="none"
          d="
M1736.044434,3883.491211 
	C1732.622437,3883.271729 1732.427368,3881.580566 1733.281006,3879.483154 
	C1733.980713,3877.763184 1733.932373,3875.466553 1736.077148,3874.722168 
	C1737.886719,3874.094238 1739.221191,3874.964355 1739.630737,3876.848389 
	C1740.245728,3879.677734 1739.345093,3881.909912 1736.044434,3883.491211 
z"
        />
        <path
          fill="#EEE9DC"
          opacity="1.000000"
          stroke="none"
          d="
M1653.931274,3908.710938 
	C1654.912720,3906.546143 1656.021973,3907.111572 1657.564453,3907.880127 
	C1657.581177,3911.553955 1658.993164,3915.275635 1656.771118,3918.631104 
	C1650.637695,3917.074219 1652.942261,3913.021240 1653.931274,3908.710938 
z"
        />
        <path
          fill="#DAD3C3"
          opacity="1.000000"
          stroke="none"
          d="
M1861.081909,3505.943848 
	C1862.088623,3508.759033 1861.866577,3511.443115 1858.698975,3513.656250 
	C1855.420898,3513.668457 1853.889771,3512.291260 1854.288452,3509.434570 
	C1854.801880,3505.755615 1857.779785,3506.203369 1861.081909,3505.943848 
z"
        />
        <path
          fill="#DAD3C3"
          opacity="1.000000"
          stroke="none"
          d="
M1793.920410,3604.323730 
	C1794.979736,3606.652344 1796.398560,3608.712402 1793.216553,3609.450684 
	C1791.796509,3609.780029 1790.592407,3608.865967 1790.411011,3607.359863 
	C1790.141968,3605.127686 1789.473022,3602.290039 1793.920410,3604.323730 
z"
        />
        <path
          fill="#DAD3C3"
          opacity="1.000000"
          stroke="none"
          d="
M1863.302246,3494.112305 
	C1863.899292,3496.364746 1864.365234,3498.822266 1862.474854,3501.510010 
	C1859.446655,3499.166504 1858.821411,3496.508057 1863.302246,3494.112305 
z"
        />
        <path
          fill="#DBD5C8"
          opacity="1.000000"
          stroke="none"
          d="
M1942.247681,3644.529297 
	C1943.014038,3643.493896 1943.995850,3642.970215 1945.486328,3642.218262 
	C1948.350342,3644.244873 1948.623779,3646.239746 1944.638916,3647.886719 
	C1943.490967,3647.007080 1942.977051,3646.023682 1942.247681,3644.529297 
z"
        />
        <path
          fill="#EAE5D5"
          opacity="1.000000"
          stroke="none"
          d="
M1916.481445,3618.420898 
	C1917.696777,3616.626709 1918.724854,3616.777588 1919.422607,3618.178711 
	C1919.861938,3619.061279 1919.716309,3620.200439 1918.778809,3620.644043 
	C1917.260986,3621.361572 1916.602661,3620.366699 1916.481445,3618.420898 
z"
        />
        <path
          fill="#EAE5D5"
          opacity="1.000000"
          stroke="none"
          d="
M1911.923584,3621.378906 
	C1909.167847,3621.758545 1908.000977,3620.089844 1908.009644,3616.700928 
	C1910.056030,3616.947998 1911.502563,3618.400146 1911.923584,3621.378906 
z"
        />
        <path
          fill="#DAD3C3"
          opacity="1.000000"
          stroke="none"
          d="
M1826.859741,3607.286133 
	C1827.280884,3608.779785 1826.750488,3609.309814 1825.598755,3609.081543 
	C1825.358276,3609.033936 1825.033569,3608.694336 1824.997559,3608.451904 
	C1824.823242,3607.281982 1825.396606,3606.797607 1826.859741,3607.286133 
z"
        />
        <path
          fill="#DAD3C3"
          opacity="1.000000"
          stroke="none"
          d="
M1792.487915,3593.971924 
	C1792.014526,3593.665283 1792.016968,3593.320557 1792.041382,3592.458496 
	C1792.861084,3592.297363 1793.658813,3592.653076 1794.456543,3593.008789 
	C1793.958984,3593.317383 1793.461304,3593.625732 1792.487915,3593.971924 
z"
        />
        <path
          fill="#DBD5C8"
          opacity="1.000000"
          stroke="none"
          d="
M1924.953857,3630.927734 
	C1925.040283,3630.880615 1924.867554,3630.974609 1924.953857,3630.927734 
z"
        />
        <path
          fill="#EAE6D7"
          opacity="1.000000"
          stroke="none"
          d="
M1925.297363,3770.108887 
	C1926.082153,3774.242676 1925.258423,3778.401611 1930.385498,3781.115723 
	C1933.206665,3782.609375 1932.282715,3786.528809 1931.019653,3789.335938 
	C1930.074097,3791.437256 1928.491211,3793.245605 1927.470215,3795.321045 
	C1926.091797,3798.123047 1927.000366,3800.485596 1930.236694,3799.393799 
	C1936.389038,3797.318604 1942.401855,3801.296143 1948.391602,3798.658936 
	C1942.676025,3804.450439 1931.747681,3806.242188 1924.501099,3802.144775 
	C1920.594238,3799.936035 1917.909180,3799.474854 1914.543579,3803.571777 
	C1910.825562,3800.685059 1912.468628,3799.095947 1915.961792,3797.650635 
	C1920.395264,3795.816406 1920.536499,3792.872314 1917.973511,3788.989746 
	C1912.893188,3781.293457 1915.167725,3775.137451 1925.297363,3770.108887 
z"
        />
        <path
          fill="#DBD5C8"
          opacity="1.000000"
          stroke="none"
          d="
M1942.005371,3758.771484 
	C1947.852295,3758.961670 1954.291260,3755.716064 1959.801758,3761.239258 
	C1958.901245,3766.809326 1953.445923,3766.029785 1950.489136,3769.520996 
	C1948.276855,3771.986816 1946.580811,3771.584229 1945.158447,3769.697021 
	C1942.908813,3766.711670 1941.737427,3763.335205 1942.005371,3758.771484 
z"
        />
        <path
          fill="#EAE6D7"
          opacity="1.000000"
          stroke="none"
          d="
M1936.062988,3814.917480 
	C1930.578613,3814.009277 1932.124390,3810.781982 1934.848633,3810.599121 
	C1942.901001,3810.058838 1950.634888,3807.302734 1961.057251,3806.986328 
	C1954.680420,3811.136963 1950.539551,3814.397461 1944.588745,3814.062744 
	C1942.012085,3813.917969 1939.396606,3814.463135 1936.062988,3814.917480 
z"
        />
        <path
          fill="#EAE6D7"
          opacity="1.000000"
          stroke="none"
          d="
M1959.713745,3787.353027 
	C1957.685913,3788.966309 1956.544067,3790.993652 1955.342529,3793.013428 
	C1954.442627,3794.526367 1952.910034,3795.837646 1951.304199,3794.790527 
	C1950.073486,3793.987305 1948.874390,3792.125000 1948.850098,3790.713379 
	C1948.811646,3788.482666 1949.549072,3786.150391 1950.362915,3784.015625 
	C1951.314087,3781.520752 1952.774292,3778.747559 1955.859741,3779.123047 
	C1958.994141,3779.504639 1957.447998,3783.034424 1958.593994,3784.893799 
	C1958.940308,3785.455811 1959.153442,3786.099609 1959.713745,3787.353027 
z"
        />
        <path
          fill="#EAE6D7"
          opacity="1.000000"
          stroke="none"
          d="
M1950.800537,3769.993652 
	C1949.053223,3763.033203 1955.428833,3764.648193 1959.328613,3762.295410 
	C1960.000000,3762.000000 1960.983398,3762.006836 1961.475098,3762.009033 
	C1962.228882,3762.333496 1962.491089,3762.655518 1962.383911,3763.479004 
	C1961.372070,3771.033203 1961.372070,3771.033203 1950.800537,3769.993652 
z"
        />
        <path
          fill="#EAE6D7"
          opacity="1.000000"
          stroke="none"
          d="
M1935.010742,3767.457520 
	C1937.813843,3766.757812 1939.266968,3767.700684 1938.966675,3770.229736 
	C1938.846802,3771.239502 1937.693726,3771.720703 1936.708984,3771.546631 
	C1934.684082,3771.188477 1934.202271,3769.768799 1935.010742,3767.457520 
z"
        />
        <path
          fill="#EAE6D7"
          opacity="1.000000"
          stroke="none"
          d="
M1939.003418,3792.510742 
	C1938.270386,3790.813965 1938.326782,3789.601562 1939.792603,3789.141602 
	C1940.193359,3789.015625 1941.147827,3789.478760 1941.275635,3789.862305 
	C1941.821289,3791.502686 1941.719727,3792.942871 1939.003418,3792.510742 
z"
        />
        <path
          fill="#EAE6D7"
          opacity="1.000000"
          stroke="none"
          d="
M1942.002441,3758.000488 
	C1941.678467,3757.993652 1941.346313,3758.022217 1941.026489,3757.972412 
	C1939.523926,3757.737549 1937.576294,3758.276611 1937.158813,3756.044434 
	C1937.080444,3755.625000 1937.645386,3754.771484 1938.084351,3754.622559 
	C1939.999268,3753.973633 1941.134033,3754.968750 1941.888184,3757.359131 
	C1942.004883,3758.002686 1942.000000,3758.000000 1942.002441,3758.000488 
z"
        />
        <path
          fill="#EAE6D7"
          opacity="1.000000"
          stroke="none"
          d="
M1961.500000,3788.000488 
	C1962.125000,3788.297852 1962.273926,3788.588135 1962.370972,3788.894775 
	C1962.816406,3790.303955 1964.364746,3792.369873 1962.085693,3792.855713 
	C1960.117554,3793.275391 1960.210327,3790.811768 1960.012817,3788.634766 
	C1960.333374,3788.000000 1960.666626,3788.000244 1961.500000,3788.000488 
z"
        />
        <path
          fill="#DBD5C8"
          opacity="1.000000"
          stroke="none"
          d="
M1962.562500,3763.906494 
	C1962.014648,3763.980469 1961.994507,3762.995605 1961.980713,3762.503418 
	C1962.767700,3761.018799 1963.886719,3760.002441 1964.800415,3761.425049 
	C1965.672729,3762.782959 1964.340576,3763.473145 1962.562500,3763.906494 
z"
        />
        <path
          fill="#808489"
          opacity="1.000000"
          stroke="none"
          d="
M79.624054,3610.221191 
	C80.279045,3615.053711 80.279045,3615.053711 72.887360,3617.339600 
	C73.006317,3612.967529 75.153938,3610.905518 79.624054,3610.221191 
z"
        />
        <path
          fill="#808489"
          opacity="1.000000"
          stroke="none"
          d="
M86.571472,3782.298584 
	C84.904213,3783.413818 83.443794,3783.859131 82.926575,3782.362061 
	C82.350090,3780.693115 83.795387,3779.425537 84.973877,3778.380615 
	C86.095161,3777.386230 87.577087,3775.651611 88.925880,3777.280029 
	C90.613419,3779.316406 88.199478,3780.523926 86.571472,3782.298584 
z"
        />
        <path
          fill="#808489"
          opacity="1.000000"
          stroke="none"
          d="
M57.028446,3807.035156 
	C56.931877,3807.081787 57.125015,3806.988281 57.028446,3807.035156 
z"
        />
        <path
          fill="#020914"
          opacity="1.000000"
          stroke="none"
          d="
M2291.485840,3831.819336 
	C2292.683594,3838.225830 2296.847412,3839.831299 2302.483154,3840.041504 
	C2310.401855,3840.336914 2318.325439,3839.219482 2326.258057,3841.495361 
	C2330.751709,3842.784424 2333.989258,3844.528809 2336.427246,3848.542480 
	C2338.119873,3851.329346 2340.817139,3853.491455 2342.958252,3856.023438 
	C2346.617676,3860.350342 2346.932861,3864.834229 2344.051758,3869.738281 
	C2341.476807,3874.121094 2338.753906,3874.914795 2331.960938,3872.948242 
	C2324.952637,3870.919922 2317.979004,3868.769043 2311.010986,3866.605225 
	C2300.483154,3863.335938 2289.120361,3860.572266 2278.797607,3862.520996 
	C2261.220215,3865.839355 2245.526855,3858.866699 2228.962158,3857.154785 
	C2218.006104,3856.022217 2207.255859,3855.166016 2196.383057,3857.485107 
	C2189.172363,3859.022949 2182.484131,3857.995850 2177.184326,3852.171875 
	C2173.384033,3847.995117 2173.107666,3845.949463 2177.100342,3842.049805 
	C2181.112793,3838.130371 2185.930664,3835.055176 2186.555420,3828.643799 
	C2186.781006,3826.327637 2189.319336,3825.976807 2191.358643,3826.072510 
	C2202.604736,3826.600830 2213.817871,3825.365967 2225.139404,3827.170898 
	C2241.414307,3829.766357 2257.353271,3833.207275 2272.713135,3839.209229 
	C2275.820068,3840.423584 2278.568359,3840.100342 2280.518066,3836.990723 
	C2282.856689,3833.260254 2286.295410,3831.652832 2291.485840,3831.819336 
z"
        />
        <path
          fill="#C2825D"
          opacity="1.000000"
          stroke="none"
          d="
M2275.516602,3806.270508 
	C2273.180176,3789.927002 2279.199951,3775.518311 2283.325928,3760.935791 
	C2286.953125,3748.114990 2289.950684,3735.209717 2291.772705,3722.083984 
	C2293.643311,3708.611328 2294.483643,3694.997314 2295.870850,3681.454834 
	C2296.276855,3677.493652 2297.010986,3673.566162 2297.618652,3668.818359 
	C2297.797363,3661.980469 2296.542236,3655.517090 2303.559082,3651.239990 
	C2310.113037,3653.904785 2308.620850,3658.710205 2307.383545,3663.031738 
	C2305.208984,3670.625732 2303.636719,3678.279053 2303.258301,3686.920654 
	C2302.272461,3693.873291 2304.517334,3699.436279 2307.585205,3704.426758 
	C2309.255615,3707.143799 2311.479736,3712.103760 2316.670166,3707.061035 
	C2317.208008,3706.083008 2317.464355,3705.713867 2318.250000,3704.927246 
	C2326.249512,3702.490723 2326.286133,3694.152588 2331.488281,3689.322021 
	C2332.562500,3687.507324 2333.490723,3686.449463 2335.068359,3685.008545 
	C2339.558350,3682.837402 2342.295410,3678.887939 2347.649902,3678.604004 
	C2353.314453,3681.494385 2351.497314,3685.610840 2349.894043,3689.074951 
	C2346.698730,3695.979248 2343.039062,3702.903809 2336.523682,3707.170654 
	C2332.041016,3710.106445 2330.151855,3713.524170 2330.663330,3719.349121 
	C2330.385254,3722.096924 2329.682617,3723.962646 2328.526855,3726.460938 
	C2326.253174,3730.659668 2324.300537,3734.165039 2318.754639,3733.721191 
	C2317.542969,3733.267822 2317.037354,3732.917725 2316.561035,3732.527100 
	C2316.082520,3732.134033 2315.653320,3731.693604 2315.281006,3731.196777 
	C2314.748779,3731.801514 2314.707764,3732.042969 2315.059814,3731.786377 
	C2315.565186,3731.418213 2315.961670,3731.573730 2316.784912,3732.801514 
	C2321.567627,3738.888428 2323.402832,3745.791992 2325.365234,3752.262207 
	C2328.892822,3763.893799 2318.789062,3779.661865 2306.849854,3782.182373 
	C2302.520020,3783.096436 2300.319580,3784.578125 2300.101562,3789.042969 
	C2299.800049,3795.220459 2296.513428,3799.611084 2290.983154,3803.110352 
	C2293.519043,3806.910645 2298.533203,3804.450684 2301.953613,3808.216797 
	C2304.708252,3812.057861 2304.646484,3815.656494 2304.063721,3819.326172 
	C2302.600342,3828.539551 2299.815674,3830.370117 2289.089600,3828.137939 
	C2282.505859,3821.689453 2278.400635,3814.742920 2275.516602,3806.270508 
z"
        />
        <path
          fill="#2C2520"
          opacity="1.000000"
          stroke="none"
          d="
M2437.246094,3917.572021 
	C2438.926758,3919.740234 2439.897217,3921.578613 2442.085693,3921.995117 
	C2444.161133,3922.389648 2446.206055,3922.106445 2447.295654,3920.066406 
	C2448.091309,3918.575684 2448.354248,3916.532715 2447.051025,3915.388184 
	C2442.345947,3911.256592 2442.457764,3905.410400 2441.118652,3899.252441 
	C2441.559082,3898.033447 2442.030762,3897.618408 2443.346924,3897.622559 
	C2447.475586,3905.755615 2451.112793,3913.312500 2453.739746,3921.313232 
	C2454.738525,3924.354980 2457.189453,3923.739014 2459.407471,3922.192383 
	C2462.638672,3919.938965 2463.485107,3916.286621 2465.133057,3912.327393 
	C2466.707520,3916.162109 2470.886230,3918.294922 2468.851562,3923.158203 
	C2468.133057,3924.875244 2470.123779,3925.979980 2471.523438,3926.837402 
	C2475.646973,3929.363525 2476.930420,3933.340576 2477.484863,3938.592285 
	C2477.487305,3939.723877 2477.249756,3940.097900 2476.416016,3940.756348 
	C2470.098389,3941.538330 2470.325928,3948.647705 2464.941162,3950.152344 
	C2463.702881,3949.701660 2463.115479,3949.341553 2462.269043,3948.527344 
	C2461.614746,3947.530762 2461.237305,3946.950195 2461.185791,3946.306885 
	C2459.981201,3931.210693 2456.462891,3928.359863 2439.918457,3929.904785 
	C2438.399658,3930.514648 2437.503418,3930.783691 2435.886230,3931.067383 
	C2432.418457,3931.199463 2430.241211,3929.286865 2427.404541,3928.810303 
	C2425.588867,3931.975098 2429.779053,3937.309082 2424.294189,3938.991699 
	C2419.322754,3940.517090 2417.385498,3936.218750 2414.882080,3933.222656 
	C2412.838867,3930.777344 2412.172852,3925.903809 2408.414551,3926.512695 
	C2404.562988,3927.137207 2405.810547,3931.983398 2404.725098,3934.929932 
	C2404.264160,3936.181396 2403.912109,3937.449951 2402.609375,3939.031982 
	C2401.389404,3940.584229 2400.228271,3942.631104 2399.963623,3942.520752 
	C2394.250488,3940.146973 2387.385498,3944.447754 2382.084961,3938.754639 
	C2384.661865,3934.926514 2388.395264,3935.412598 2391.912354,3935.147461 
	C2394.388916,3934.961182 2396.584961,3934.496094 2395.338379,3930.557129 
	C2394.793945,3929.594482 2394.579102,3929.204834 2394.079590,3928.225830 
	C2391.542480,3923.273193 2385.489990,3928.476074 2383.199463,3923.121582 
	C2383.728027,3927.413574 2385.412109,3931.346191 2382.503418,3935.570801 
	C2374.553467,3934.244141 2374.405762,3934.250488 2374.078857,3926.509521 
	C2373.888916,3922.009766 2374.092285,3917.614014 2366.852539,3917.954102 
	C2366.659668,3913.477783 2365.650635,3908.855957 2363.709473,3905.350830 
	C2359.201172,3897.210938 2359.824463,3888.684814 2360.150879,3880.166992 
	C2360.515869,3870.638428 2357.871338,3863.049805 2350.674072,3856.222168 
	C2341.498535,3847.517578 2337.615234,3835.641113 2334.821533,3823.501709 
	C2334.332031,3821.375732 2334.923096,3819.921143 2335.956543,3818.033447 
	C2342.581299,3805.933105 2349.725586,3793.999023 2350.299561,3779.591553 
	C2350.448486,3775.846680 2352.655518,3772.656006 2354.451660,3769.518799 
	C2358.899902,3761.749512 2357.155029,3755.008545 2351.837891,3748.507324 
	C2349.467285,3745.608398 2347.402588,3745.585693 2346.083008,3749.033203 
	C2342.424072,3758.592041 2338.476318,3768.023682 2335.743652,3777.957764 
	C2333.699219,3785.391113 2333.184814,3793.649170 2326.301270,3799.512207 
	C2322.326660,3790.520020 2329.647461,3783.809570 2330.912109,3775.036133 
	C2331.180664,3773.374268 2331.348877,3772.431152 2331.758301,3770.815918 
	C2332.563965,3768.393555 2333.310791,3766.694824 2333.835449,3764.204102 
	C2333.739990,3753.796875 2340.671875,3746.983398 2344.435547,3738.124512 
	C2346.640381,3735.375977 2347.922363,3732.671143 2351.452148,3731.448730 
	C2357.680176,3735.275391 2358.472168,3742.408203 2363.373047,3747.576660 
	C2365.151367,3752.346924 2364.575439,3756.359863 2363.135742,3761.093506 
	C2362.946777,3763.354004 2362.754639,3764.848633 2362.189941,3767.036133 
	C2361.125488,3770.532959 2359.331787,3772.959229 2358.812988,3776.625000 
	C2357.123535,3791.353760 2350.422852,3803.624268 2345.567871,3816.546387 
	C2339.961182,3831.469971 2346.107422,3845.007568 2361.370605,3851.528320 
	C2362.500000,3851.970459 2362.933350,3852.186768 2363.937256,3852.844727 
	C2368.375488,3864.606934 2367.797363,3876.713623 2370.897461,3888.295654 
	C2372.746826,3895.206055 2376.038574,3901.943848 2375.923584,3910.993408 
	C2379.478760,3906.238037 2376.694824,3902.251953 2377.856445,3898.128906 
	C2378.292725,3896.529053 2378.680664,3895.664307 2379.566406,3894.250244 
	C2380.584229,3892.920654 2381.245117,3892.228271 2382.499756,3891.112061 
	C2383.850098,3890.100586 2384.712891,3889.683594 2386.350098,3889.374268 
	C2387.491455,3889.588867 2387.914307,3889.809082 2388.790283,3890.579590 
	C2390.855713,3894.774902 2389.593262,3899.139893 2392.120117,3902.560303 
	C2394.395508,3905.640625 2394.758301,3913.146240 2401.222900,3906.450195 
	C2401.703613,3905.952148 2402.556885,3905.596436 2403.042236,3905.926758 
	C2416.324219,3914.966553 2411.090820,3900.016602 2415.431152,3896.356445 
	C2419.614258,3895.493408 2420.162354,3897.823975 2420.633789,3900.706543 
	C2421.426025,3905.546143 2421.502441,3910.614746 2424.150146,3914.987305 
	C2426.041992,3918.112061 2427.887695,3921.613525 2433.047119,3918.177490 
	C2434.513916,3917.138672 2435.510986,3916.916748 2437.246094,3917.572021 
z"
        />
        <path
          fill="#5E4237"
          opacity="1.000000"
          stroke="none"
          d="
M2332.321289,3773.333740 
	C2333.138184,3782.484375 2327.750977,3789.784424 2325.675781,3798.815674 
	C2321.642578,3809.954102 2315.048340,3818.422363 2306.906738,3825.931641 
	C2303.064209,3829.475830 2299.176514,3832.071045 2293.056152,3831.723145 
	C2292.323975,3831.747070 2292.262939,3831.897949 2292.242188,3831.826904 
	C2291.102051,3830.697021 2289.982178,3829.638428 2288.677734,3828.030518 
	C2290.743652,3827.728516 2292.989258,3828.085205 2295.246338,3828.186523 
	C2296.894287,3828.260742 2299.273193,3827.747803 2299.359863,3826.305176 
	C2299.695312,3820.728027 2304.482910,3815.375488 2299.921143,3808.944336 
	C2299.098633,3804.594482 2302.025635,3803.787354 2304.502930,3803.496826 
	C2315.978027,3802.148926 2320.226807,3793.886963 2323.592285,3784.462402 
	C2325.182617,3780.009521 2325.224609,3774.270752 2332.321289,3773.333740 
z"
        />
        <path
          fill="#D3CBBF"
          opacity="1.000000"
          stroke="none"
          d="
M2402.812988,3939.714355 
	C2400.727783,3936.147705 2401.728271,3932.876709 2403.173828,3929.911865 
	C2404.561279,3927.065674 2403.582031,3924.841797 2402.349609,3922.469482 
	C2404.543945,3921.094238 2406.933594,3922.218506 2409.147217,3921.773193 
	C2412.058838,3921.187744 2413.334717,3922.260254 2413.831299,3925.210449 
	C2414.512451,3929.256348 2417.622803,3932.147705 2419.499023,3935.640381 
	C2420.718018,3937.908936 2422.507324,3938.980957 2425.762695,3937.884033 
	C2425.189941,3933.077881 2423.316162,3928.732422 2420.637939,3924.797363 
	C2427.687744,3921.139893 2430.375000,3927.373535 2435.178223,3930.390625 
	C2443.784424,3932.610596 2447.586182,3937.057861 2444.303467,3945.965332 
	C2439.751221,3948.118652 2437.997070,3944.831055 2435.268066,3942.555420 
	C2434.863770,3942.301025 2433.980957,3942.280029 2433.627930,3942.579102 
	C2432.683838,3943.721436 2431.964111,3944.412842 2430.458008,3944.746582 
	C2429.070557,3944.614502 2428.218994,3944.544922 2426.731689,3944.588623 
	C2422.657471,3946.220703 2419.219482,3947.416748 2415.379395,3943.868408 
	C2414.970947,3943.051270 2414.804443,3942.978516 2414.728516,3942.929199 
	C2411.512207,3942.953857 2412.436035,3934.643066 2406.687012,3939.685547 
	C2405.339355,3940.059814 2404.416260,3939.973145 2402.812988,3939.714355 
z"
        />
        <path
          fill="#D3CBBF"
          opacity="1.000000"
          stroke="none"
          d="
M2437.831299,3928.790039 
	C2437.668213,3927.242676 2437.638184,3926.157715 2438.925537,3925.959229 
	C2445.316895,3924.972656 2451.471924,3926.386963 2457.102051,3928.995361 
	C2463.612061,3932.011230 2465.301758,3938.456543 2462.371582,3947.373047 
	C2460.492676,3947.980957 2458.975586,3947.888428 2456.765869,3948.111816 
	C2454.235596,3949.844971 2452.169678,3950.169189 2449.205322,3949.415771 
	C2444.679688,3942.758301 2445.843750,3933.818848 2437.831299,3928.790039 
z"
        />
        <path
          fill="#D0D1C8"
          opacity="1.000000"
          stroke="none"
          d="
M2395.566406,3928.364014 
	C2398.711914,3928.456055 2399.373047,3930.632080 2399.789551,3932.883301 
	C2400.444336,3936.423340 2399.158447,3939.110352 2395.312012,3938.281494 
	C2391.320557,3937.421631 2387.602783,3939.250977 2382.866699,3938.281738 
	C2381.964844,3937.708252 2381.950195,3937.369385 2381.951904,3936.521729 
	C2382.367920,3928.147217 2381.935547,3926.992432 2376.290527,3920.855469 
	C2377.623779,3919.087646 2379.781982,3919.275391 2381.591309,3918.682617 
	C2384.318604,3917.789307 2385.889893,3918.447998 2385.895752,3921.783447 
	C2385.899414,3923.755859 2386.944824,3925.934082 2389.435303,3924.991211 
	C2391.214355,3924.317627 2390.694336,3922.309082 2390.197021,3920.672607 
	C2390.035889,3920.142334 2390.362549,3919.463623 2390.484131,3918.745605 
	C2395.159424,3919.328857 2395.399902,3923.127930 2396.174316,3927.272217 
	C2395.163574,3928.915283 2396.442871,3927.750244 2395.450684,3928.640869 
	C2394.986572,3929.058105 2394.526611,3929.479980 2394.067871,3929.903564 
	C2394.399902,3929.531738 2394.783447,3929.211426 2395.566406,3928.364014 
z"
        />
        <path
          fill="#A6A29C"
          opacity="1.000000"
          stroke="none"
          d="
M2464.273438,3950.682129 
	C2465.882324,3947.204102 2467.748291,3944.460693 2468.998779,3941.460205 
	C2470.627686,3937.551758 2473.209717,3936.741943 2477.423340,3938.742432 
	C2476.247314,3947.021973 2470.572510,3953.213623 2469.809570,3961.934082 
	C2469.111572,3962.931885 2468.756592,3963.274170 2467.869385,3964.129639 
	C2463.328125,3960.884521 2464.107422,3956.020752 2464.273438,3950.682129 
z"
        />
        <path
          fill="#423C3E"
          opacity="1.000000"
          stroke="none"
          d="
M2437.048340,3928.495361 
	C2442.298340,3927.726074 2444.449463,3930.681641 2445.788818,3934.780518 
	C2447.014160,3938.530762 2448.504150,3942.213135 2448.017578,3947.074219 
	C2447.891113,3948.188721 2447.520020,3948.520264 2446.416992,3948.702637 
	C2444.860352,3948.473145 2444.034912,3948.393066 2442.594727,3948.221191 
	C2441.765137,3947.786621 2441.550781,3947.443848 2441.658691,3946.587402 
	C2446.671875,3943.250732 2443.740967,3939.830078 2442.221924,3937.102295 
	C2441.022705,3934.948730 2438.524902,3933.518555 2436.222168,3931.229004 
	C2436.118652,3930.075195 2436.397217,3929.459961 2437.048340,3928.495361 
z"
        />
        <path
          fill="#2C2520"
          opacity="1.000000"
          stroke="none"
          d="
M2441.609863,3945.548096 
	C2441.981689,3946.415771 2441.982666,3946.758057 2441.981445,3947.614990 
	C2437.341309,3951.359619 2433.835938,3948.485840 2430.094238,3945.320068 
	C2431.214600,3943.968750 2432.547363,3943.282715 2432.164062,3940.718750 
	C2432.123779,3939.242676 2432.452148,3938.606934 2433.565430,3937.950928 
	C2437.387451,3939.305908 2439.199707,3942.276123 2441.609863,3945.548096 
z"
        />
        <path
          fill="#2C2520"
          opacity="1.000000"
          stroke="none"
          d="
M2406.686523,3940.625488 
	C2406.976562,3937.642090 2403.646973,3933.097168 2409.659424,3933.268066 
	C2414.235596,3933.397949 2415.084961,3935.151855 2414.266357,3942.260254 
	C2411.202881,3946.261963 2408.652832,3945.823242 2406.686523,3940.625488 
z"
        />
        <path
          fill="#2C2520"
          opacity="1.000000"
          stroke="none"
          d="
M2414.770020,3943.779785 
	C2418.256592,3942.605225 2421.495605,3942.660889 2425.357178,3944.327637 
	C2420.042236,3951.547119 2417.958740,3951.511963 2414.770020,3943.779785 
z"
        />
        <path
          fill="#2C2520"
          opacity="1.000000"
          stroke="none"
          d="
M2445.954346,3949.146484 
	C2446.110352,3948.418945 2446.534912,3948.284668 2447.610840,3948.003906 
	C2450.354980,3947.931641 2452.447754,3948.006348 2455.307129,3948.254395 
	C2454.354248,3954.500000 2453.524902,3954.610352 2445.954346,3949.146484 
z"
        />
        <path
          fill="#738EA6"
          opacity="1.000000"
          stroke="none"
          d="
M2621.996338,2205.220215 
	C2617.351318,2205.961914 2612.814941,2205.653320 2608.177734,2204.620361 
	C2603.131104,2203.496094 2597.999268,2203.693848 2592.642334,2205.878662 
	C2587.720215,2207.886230 2581.735596,2208.153076 2576.291260,2207.857910 
	C2556.957031,2206.809814 2537.849121,2210.829346 2518.554932,2209.994629 
	C2515.442627,2209.859863 2512.867188,2211.091309 2510.103516,2212.433838 
	C2503.975586,2215.410889 2494.095215,2214.469238 2486.805664,2210.607422 
	C2489.022949,2206.507568 2492.328613,2204.104492 2496.907471,2204.111328 
	C2503.201660,2204.120361 2509.684570,2202.831543 2515.749756,2205.035400 
	C2520.983398,2206.937256 2525.558350,2204.852539 2530.376709,2203.900879 
	C2532.135742,2203.553711 2533.546387,2202.691650 2533.499268,2200.683594 
	C2533.445557,2198.395020 2531.963623,2197.000244 2529.914062,2196.456543 
	C2528.680908,2196.129883 2527.168945,2196.047119 2525.989502,2196.454590 
	C2517.850586,2199.264160 2509.448730,2198.024170 2501.204102,2197.866943 
	C2491.135254,2197.675293 2481.349365,2199.155762 2471.614990,2201.200684 
	C2465.964600,2202.387451 2460.346924,2201.859863 2454.710938,2201.877441 
	C2452.679199,2201.884033 2450.525146,2201.377686 2450.783203,2198.621338 
	C2450.978760,2196.535400 2452.834229,2196.070312 2454.499512,2196.130371 
	C2461.854248,2196.395264 2468.823730,2193.026855 2476.250244,2193.927979 
	C2477.783691,2194.114014 2479.537598,2194.055176 2480.428223,2192.523926 
	C2480.460938,2190.333252 2478.926514,2190.161621 2477.711914,2190.149902 
	C2467.053955,2190.048584 2456.395020,2190.012695 2445.736816,2190.057129 
	C2444.108887,2190.063721 2441.788330,2189.989746 2440.965088,2190.955811 
	C2433.634033,2199.559570 2424.186768,2194.171875 2415.815186,2194.594482 
	C2411.354004,2194.819336 2406.541016,2193.747803 2401.949219,2194.088379 
	C2386.927490,2195.201660 2372.174316,2191.423584 2357.207031,2191.964111 
	C2343.974609,2184.697754 2329.023926,2190.316162 2315.284912,2186.406250 
	C2311.208496,2185.245850 2306.717773,2185.677734 2302.432617,2186.825439 
	C2295.561768,2188.665039 2288.643066,2188.541260 2281.727051,2186.915527 
	C2276.265137,2185.631592 2270.825195,2185.395752 2263.474121,2187.355469 
	C2272.199219,2192.656982 2279.736084,2195.386475 2288.064453,2193.490723 
	C2297.487305,2191.345215 2306.149170,2190.878418 2314.752441,2196.794678 
	C2318.039307,2199.054932 2322.698975,2199.733154 2326.464600,2201.839111 
	C2327.937744,2202.662842 2329.699951,2203.122559 2328.796143,2205.352295 
	C2328.261719,2206.670166 2327.004883,2207.783691 2325.739746,2207.522461 
	C2319.102051,2206.152344 2310.934082,2210.233643 2306.183350,2201.779785 
	C2304.919434,2199.530273 2301.853027,2198.385010 2299.213623,2198.302979 
	C2291.327637,2198.057861 2283.429443,2198.215088 2274.817871,2198.215088 
	C2275.689941,2203.992432 2279.949951,2206.030029 2282.645508,2208.884033 
	C2285.316406,2211.712158 2288.382080,2212.422363 2291.407227,2207.748047 
	C2294.894531,2202.359863 2296.197021,2202.835938 2300.458984,2208.389648 
	C2301.447510,2209.677734 2302.641357,2210.839844 2303.880859,2211.895752 
	C2305.340088,2213.138672 2307.674805,2213.935059 2306.603027,2216.450195 
	C2305.820557,2218.286377 2303.781494,2218.095459 2302.220947,2217.932861 
	C2295.145264,2217.197510 2288.556152,2220.596191 2281.458496,2220.101807 
	C2276.667725,2219.767822 2271.983154,2220.427490 2271.366455,2213.618896 
	C2271.187500,2211.641113 2269.419678,2210.152832 2266.458984,2211.541504 
	C2264.624756,2213.157227 2263.082520,2213.475098 2260.684570,2212.471191 
	C2255.358643,2209.697266 2251.439209,2210.578369 2248.118164,2215.327393 
	C2245.452148,2218.642578 2242.523926,2218.665283 2238.692383,2216.633545 
	C2235.926758,2208.602783 2235.860107,2208.559814 2224.795898,2208.015137 
	C2211.366211,2210.300781 2198.627930,2210.165771 2184.953613,2209.975342 
	C2182.334961,2201.091797 2190.013428,2202.023438 2194.183350,2201.719971 
	C2213.674072,2200.301758 2233.088135,2196.064209 2252.779053,2199.239014 
	C2254.353760,2199.492920 2255.974609,2199.916748 2257.755859,2199.304932 
	C2255.255859,2196.361816 2250.480225,2195.344971 2250.605225,2190.364746 
	C2250.756104,2184.342041 2247.093018,2184.790771 2243.327637,2186.563965 
	C2237.908691,2189.115723 2232.827637,2192.337158 2227.697510,2195.422363 
	C2223.239746,2198.103271 2218.550293,2199.393066 2213.416748,2198.910400 
	C2210.116699,2198.600098 2206.313232,2198.472168 2205.288330,2194.496826 
	C2204.312744,2190.711670 2207.112793,2188.389404 2209.811523,2186.306885 
	C2211.885742,2184.706055 2214.485107,2184.261719 2216.680176,2183.310059 
	C2200.256348,2182.032227 2182.943848,2184.574463 2167.342529,2180.078125 
	C2152.492676,2175.798828 2137.773193,2176.878662 2123.202148,2174.454590 
	C2110.340576,2172.314697 2098.174072,2177.221924 2085.591797,2175.972168 
	C2093.705811,2177.772705 2101.962891,2176.100342 2110.097168,2177.026367 
	C2114.009521,2177.471680 2118.916260,2177.511963 2119.065186,2182.890869 
	C2119.217529,2188.402832 2114.398926,2188.395264 2110.605957,2188.915527 
	C2115.537598,2189.395020 2120.729248,2188.223877 2125.802734,2189.531738 
	C2128.505615,2190.228760 2130.936035,2191.364014 2131.209717,2194.574951 
	C2131.473633,2197.670166 2129.677002,2199.699219 2126.990723,2200.647217 
	C2120.480713,2202.945801 2114.108398,2206.114258 2107.201416,2200.948730 
	C2102.798828,2197.656494 2097.297363,2197.209961 2091.854248,2198.004883 
	C2082.277588,2199.403809 2072.749023,2195.363525 2063.113525,2197.886475 
	C2060.842529,2198.480713 2058.645020,2196.901855 2056.516602,2194.519043 
	C2051.081787,2193.999756 2046.167725,2193.840332 2041.266968,2194.027344 
	C2037.470947,2194.172119 2034.156250,2193.322510 2030.657349,2190.382324 
	C2025.957642,2186.704346 2024.414795,2182.493896 2026.565186,2177.686279 
	C2028.708984,2172.894043 2033.496948,2172.897217 2037.732056,2173.199219 
	C2045.934570,2173.783691 2054.231934,2173.377441 2063.457764,2175.646484 
	C2060.045654,2171.770508 2052.665283,2173.317627 2053.471924,2167.388428 
	C2054.109619,2162.701416 2059.010010,2162.472168 2062.863770,2161.771484 
	C2057.449951,2159.299316 2052.441895,2155.708008 2045.626221,2154.728027 
	C2038.549072,2152.456055 2035.366699,2156.835938 2031.438232,2161.369141 
	C2024.615234,2164.153809 2019.200195,2160.498291 2013.438599,2159.535645 
	C2007.240967,2158.500000 2001.062012,2157.287598 1993.952759,2157.261230 
	C1985.528931,2159.338623 1977.827759,2158.004150 1969.409546,2157.764893 
	C1967.193848,2158.132080 1965.750366,2157.997803 1963.693726,2157.233398 
	C1960.994385,2156.413574 1958.955933,2154.952881 1956.459473,2157.508301 
	C1953.860718,2157.970703 1951.731323,2157.980225 1948.803345,2157.990234 
	C1946.944336,2156.808594 1946.116333,2155.503662 1945.784180,2153.114258 
	C1945.780518,2151.792236 1945.830078,2151.262451 1946.067261,2149.955078 
	C1947.086304,2147.224365 1947.740234,2145.176514 1949.873169,2143.124023 
	C1960.660889,2141.071777 1970.965088,2143.546143 1981.615967,2141.632080 
	C1982.120850,2141.688721 1982.824829,2141.103516 1982.474487,2140.688965 
	C1979.772949,2134.916748 1983.909058,2134.128906 1987.333740,2133.293945 
	C1991.485474,2132.281494 1995.767090,2132.676758 2000.701782,2133.175537 
	C2006.840088,2135.665039 2010.585205,2134.140137 2013.005615,2127.970215 
	C2014.249634,2127.000488 2015.088989,2126.684570 2016.672607,2126.563721 
	C2020.260254,2127.232666 2022.895630,2129.463867 2026.823242,2128.011230 
	C2041.601440,2128.973389 2055.769287,2125.688965 2069.959961,2129.854492 
	C2077.232666,2131.989502 2070.053467,2126.486084 2071.556152,2124.344482 
	C2083.020508,2120.189209 2095.387695,2119.465088 2103.024902,2110.523926 
	C2099.089844,2110.610840 2094.225098,2110.282471 2090.606934,2116.793945 
	C2086.812012,2123.623779 2064.738770,2122.428711 2058.766113,2115.543457 
	C2055.309082,2111.558105 2051.593750,2107.738037 2048.983154,2102.960693 
	C2046.319092,2098.085205 2043.442505,2097.509766 2040.863403,2103.551514 
	C2036.012451,2114.915283 2032.658569,2117.011719 2020.132080,2113.837402 
	C2009.617798,2111.173096 1999.871338,2111.723389 1990.034912,2115.879150 
	C1990.854492,2117.716553 1992.720703,2117.961670 1994.331055,2119.683594 
	C1995.473633,2122.894531 1994.411133,2125.058350 1992.121948,2125.821533 
	C1985.761230,2127.941895 1980.245972,2132.319824 1972.639038,2132.396973 
	C1971.965942,2131.615967 1972.038208,2131.258789 1971.943359,2130.953125 
	C1969.537231,2123.200195 1969.534058,2123.201172 1974.858032,2117.466553 
	C1971.988525,2114.953613 1968.470581,2116.506104 1965.373779,2115.857910 
	C1960.986816,2114.940430 1959.844238,2113.039795 1961.733154,2108.789795 
	C1963.181274,2105.531250 1964.128540,2101.704834 1968.092773,2100.258789 
	C1969.673462,2099.682129 1971.500000,2099.134521 1970.931152,2096.990479 
	C1970.474243,2095.267822 1968.884399,2094.339111 1967.129639,2094.212646 
	C1962.114624,2093.851074 1958.773926,2090.365479 1954.994019,2087.760742 
	C1953.715210,2086.879395 1952.349365,2085.465088 1953.284546,2083.543701 
	C1953.946167,2082.183838 1955.397095,2082.192871 1956.711548,2082.054688 
	C1960.292480,2081.678467 1963.024048,2082.670410 1965.259277,2085.835205 
	C1967.326172,2088.761719 1969.855835,2092.457031 1973.918823,2091.672607 
	C1978.731812,2090.743408 1983.927734,2090.915039 1988.298340,2088.295410 
	C1990.679565,2086.868408 1992.060059,2084.538574 1991.756592,2081.552246 
	C1991.441895,2078.455322 1988.700317,2078.744141 1986.738037,2077.682373 
	C1982.476929,2075.377197 1978.379639,2075.427490 1973.588501,2075.633545 
	C1965.523438,2075.980469 1956.928223,2078.282227 1949.289185,2073.077393 
	C1947.793579,2072.058594 1946.085693,2073.103760 1944.497681,2073.538818 
	C1940.953247,2074.509033 1937.976929,2073.343018 1934.751221,2070.693115 
	C1937.050293,2066.481201 1940.968872,2064.167969 1944.651367,2061.649658 
	C1947.501221,2059.700928 1949.898682,2057.521484 1949.926514,2053.716309 
	C1949.951782,2050.288086 1949.302246,2047.505493 1944.852051,2048.737793 
	C1943.213989,2049.191162 1941.616333,2049.688477 1940.016602,2048.878906 
	C1937.709839,2047.711792 1934.357422,2046.996948 1934.911621,2043.637451 
	C1935.518921,2039.956421 1938.408447,2040.433716 1941.178711,2041.412842 
	C1944.458618,2042.572266 1946.817383,2041.383545 1948.932007,2038.666382 
	C1951.316406,2035.602661 1954.661011,2035.115356 1957.759888,2037.435303 
	C1960.897949,2039.784424 1959.285400,2042.405518 1957.909668,2045.339111 
	C1954.885376,2051.787842 1957.216431,2055.708740 1964.193848,2055.903320 
	C1971.184570,2056.098389 1978.143066,2055.967529 1984.931763,2058.083496 
	C1987.184082,2058.785645 1989.306396,2059.488281 1990.965088,2061.433350 
	C1989.544312,2065.462646 1985.651733,2063.210205 1983.319946,2064.650879 
	C1981.486206,2065.783691 1980.018433,2067.137695 1980.586060,2069.411865 
	C1981.249634,2072.069824 1983.388916,2071.894287 1985.363770,2071.185791 
	C1987.648560,2070.366211 1989.753540,2070.466309 1992.155640,2070.963867 
	C2001.854858,2072.973145 2001.889038,2072.853271 1999.897095,2082.759521 
	C1999.635254,2084.061279 1999.047729,2085.316406 1998.921509,2086.623047 
	C1998.638428,2089.552734 2001.009399,2090.532227 2002.620728,2089.235840 
	C2006.872681,2085.815186 2011.292480,2087.913818 2015.660645,2087.484619 
	C2022.082520,2086.853271 2028.366333,2084.044922 2034.512817,2081.658936 
	C2038.547974,2080.092285 2042.541260,2078.864014 2045.449097,2082.671143 
	C2052.289307,2091.627197 2061.472168,2090.615234 2070.825439,2089.403809 
	C2072.096680,2089.239014 2073.313721,2088.347168 2074.992676,2089.630127 
	C2075.041016,2093.482910 2072.127686,2094.830811 2069.023193,2096.077148 
	C2065.476074,2097.500732 2065.724365,2099.946289 2067.712402,2102.392578 
	C2069.627441,2104.749756 2072.343262,2107.531738 2075.150391,2104.494141 
	C2081.371094,2097.762939 2088.271729,2098.275635 2095.988770,2100.888184 
	C2101.860596,2102.875732 2107.682861,2102.931885 2112.967773,2098.669678 
	C2115.742432,2096.432373 2118.915527,2095.935791 2122.204102,2098.117920 
	C2124.689941,2099.767334 2126.604248,2101.545166 2124.295410,2104.514160 
	C2121.303467,2108.361816 2123.904297,2109.076660 2126.869629,2110.791504 
	C2133.130127,2114.411865 2139.746094,2115.726562 2146.811768,2116.039551 
	C2158.407959,2116.552734 2170.109619,2114.500000 2181.610840,2117.464844 
	C2182.888916,2117.794189 2184.297607,2117.758789 2184.843018,2116.097168 
	C2185.197266,2115.017822 2184.687256,2114.170654 2183.950195,2113.452393 
	C2183.000732,2112.527344 2181.772217,2111.792725 2181.091797,2110.707764 
	C2179.477051,2108.132080 2179.795166,2105.497803 2182.088623,2103.521729 
	C2183.977539,2101.894775 2186.014160,2102.851074 2187.334473,2104.472656 
	C2190.812256,2108.743408 2194.805176,2107.102295 2199.144043,2106.323730 
	C2203.436768,2105.552979 2207.064941,2105.899902 2208.857666,2100.148682 
	C2210.341064,2095.390137 2214.742432,2095.627686 2218.987793,2099.551758 
	C2220.450439,2100.903809 2222.174805,2102.039307 2223.390381,2103.580811 
	C2228.811768,2110.453369 2225.892090,2118.740479 2217.254639,2119.780762 
	C2212.467285,2120.357422 2209.700439,2121.405029 2209.917725,2126.722412 
	C2210.105713,2131.323486 2206.948730,2131.834473 2203.248779,2132.032959 
	C2191.328613,2132.672852 2179.500732,2134.859375 2167.487793,2134.083984 
	C2165.997070,2133.987549 2164.068115,2133.771484 2163.324951,2136.665283 
	C2168.011719,2139.219971 2173.811523,2139.355225 2178.291504,2138.868164 
	C2192.619629,2137.310791 2206.895020,2138.363037 2221.176758,2138.109619 
	C2227.096680,2138.004883 2231.519287,2137.224609 2233.019531,2130.388672 
	C2234.088379,2125.516113 2237.893799,2124.115967 2242.871094,2123.873779 
	C2253.909424,2123.336426 2264.580566,2126.402832 2275.638184,2126.230957 
	C2289.549072,2126.014160 2303.587646,2124.418457 2317.440918,2127.404053 
	C2319.782227,2127.908691 2321.671143,2126.933350 2321.745117,2124.579590 
	C2321.852295,2121.174072 2325.883057,2117.752441 2322.057373,2114.407227 
	C2320.331299,2112.898193 2318.063477,2112.026367 2316.178223,2110.675049 
	C2314.649902,2109.579590 2314.232910,2107.894287 2315.318115,2106.305420 
	C2316.332520,2104.819824 2317.884521,2104.204590 2319.646973,2105.163330 
	C2321.103271,2105.955811 2322.583252,2106.741455 2323.916260,2107.716553 
	C2331.785889,2113.473389 2340.593262,2115.169189 2350.131348,2114.064697 
	C2351.392334,2113.918457 2352.737549,2114.403320 2354.025879,2114.679688 
	C2354.265869,2114.730957 2354.419922,2115.181885 2354.692871,2115.557129 
	C2354.334961,2120.818604 2349.332764,2117.520264 2347.214844,2119.404297 
	C2346.356201,2122.624023 2351.573730,2121.469727 2350.737793,2124.317139 
	C2350.069336,2126.593994 2347.494385,2126.323730 2345.741211,2127.005371 
	C2340.397705,2129.083008 2333.747803,2126.864258 2329.273193,2131.591309 
	C2329.458252,2133.693359 2330.925537,2133.832520 2332.101562,2134.273193 
	C2335.807617,2135.662354 2338.794922,2137.498291 2337.553467,2142.268799 
	C2336.310547,2147.045166 2332.972412,2148.168213 2328.456055,2148.052490 
	C2320.463379,2147.847656 2312.457764,2148.146973 2304.463867,2147.963623 
	C2293.526855,2147.713135 2282.513184,2149.101318 2271.677246,2146.190186 
	C2269.194824,2145.523193 2266.256836,2145.611816 2263.731445,2146.209961 
	C2253.811035,2148.560547 2244.230957,2149.293701 2234.546387,2144.661133 
	C2229.668945,2142.328125 2227.388184,2146.249023 2226.102051,2151.250488 
	C2225.236084,2153.144775 2223.275391,2153.510498 2222.993652,2154.312744 
	C2222.336426,2156.184570 2224.358154,2155.123291 2225.082275,2155.357666 
	C2227.654053,2156.189697 2230.787842,2156.711426 2230.724365,2160.013672 
	C2230.653076,2163.751465 2227.167236,2164.310059 2224.305176,2165.211426 
	C2220.614990,2166.373535 2217.085205,2164.642822 2213.343750,2164.614746 
	C2221.578613,2170.971436 2237.571045,2173.535156 2245.816162,2169.844238 
	C2247.912109,2168.906006 2249.851562,2168.145264 2249.637695,2164.925049 
	C2249.074463,2156.441650 2251.075195,2154.580078 2259.665527,2155.255371 
	C2273.588379,2156.349365 2287.509277,2157.350342 2301.488770,2157.011963 
	C2304.773682,2156.932373 2308.227051,2157.574707 2311.483887,2154.501221 
	C2317.273193,2154.142822 2322.537842,2153.663330 2327.765381,2155.461670 
	C2331.968750,2156.907715 2336.292725,2155.876953 2340.387939,2154.137939 
	C2341.680420,2153.588867 2343.314209,2152.758545 2342.727051,2151.516602 
	C2338.999756,2143.631348 2345.631592,2146.392090 2348.475342,2146.110840 
	C2353.417480,2145.621826 2358.434326,2145.942383 2363.405273,2145.663818 
	C2365.037109,2145.572754 2366.712158,2144.523682 2366.808838,2142.627197 
	C2366.924072,2140.366943 2364.979004,2140.274902 2363.342773,2140.076904 
	C2362.684814,2139.997314 2362.012939,2140.039795 2361.351807,2139.979736 
	C2358.741699,2139.743896 2355.106689,2139.874268 2355.343750,2136.443115 
	C2355.600098,2132.739502 2359.555420,2134.611816 2361.842041,2133.898926 
	C2362.744385,2133.617188 2363.616455,2133.239014 2364.384766,2132.949219 
	C2365.578125,2129.371582 2360.309082,2129.342285 2361.449463,2125.549316 
	C2361.612549,2125.419434 2362.338623,2124.341797 2363.060791,2124.344238 
	C2368.765625,2124.362305 2374.241943,2123.032471 2379.702393,2121.682861 
	C2382.313965,2121.037354 2384.702393,2120.721680 2387.461670,2121.388672 
	C2393.125732,2122.758301 2398.189209,2122.454346 2402.621094,2117.189941 
	C2406.460693,2112.628906 2413.375488,2114.639404 2419.237305,2112.097412 
	C2426.910156,2109.292236 2433.883789,2110.672363 2441.682129,2111.954102 
	C2444.864990,2113.671631 2445.767334,2115.733643 2445.066650,2119.239014 
	C2440.200928,2123.886475 2435.020264,2124.322998 2428.756348,2122.504395 
	C2425.731445,2122.280029 2423.739990,2120.000488 2420.837402,2121.481689 
	C2421.692627,2127.955322 2423.196533,2133.712158 2431.534424,2134.062256 
	C2433.259033,2134.134766 2435.437012,2134.641357 2435.072510,2136.609375 
	C2433.900146,2142.938965 2438.237793,2141.933350 2441.759521,2141.971924 
	C2448.756104,2142.048096 2455.754395,2141.978271 2462.751465,2142.020508 
	C2465.734863,2142.038574 2469.029785,2141.699951 2469.887939,2145.733154 
	C2470.860352,2150.304443 2474.158691,2150.228516 2477.619873,2149.656738 
	C2479.575928,2149.333496 2481.447266,2148.417725 2483.404785,2148.197998 
	C2488.523926,2147.622314 2490.048340,2149.237793 2489.561035,2154.342529 
	C2488.804443,2162.267334 2489.093506,2162.698242 2496.441895,2163.925049 
	C2497.748047,2164.143066 2499.117920,2163.979980 2500.423096,2164.201416 
	C2502.186035,2164.500488 2503.582275,2165.345703 2503.278564,2167.467529 
	C2503.053955,2169.039551 2501.758545,2169.804443 2500.439697,2169.754150 
	C2491.644287,2169.417969 2484.125732,2175.157227 2475.500977,2175.081543 
	C2468.374268,2175.019287 2461.299316,2174.594482 2454.358643,2177.056152 
	C2454.653076,2177.326172 2454.844727,2177.651611 2455.024170,2177.644775 
	C2472.653809,2176.992920 2490.087158,2180.703613 2507.720459,2180.010498 
	C2512.360352,2179.827881 2516.963135,2180.134766 2521.548096,2178.596191 
	C2524.999023,2177.438232 2529.031494,2177.132080 2532.362305,2178.677002 
	C2543.895508,2184.025879 2555.974365,2184.547852 2568.307129,2183.617676 
	C2569.289062,2183.543701 2570.406738,2183.126709 2571.224121,2183.456543 
	C2572.982422,2184.166016 2573.943359,2185.663574 2572.362061,2187.280518 
	C2570.137451,2189.555908 2567.151123,2190.017578 2564.062500,2190.013428 
	C2560.413330,2190.008545 2556.903076,2190.370605 2555.041748,2194.005371 
	C2573.226318,2194.005371 2591.109131,2193.902100 2608.989502,2194.051758 
	C2617.176514,2194.120117 2620.064697,2196.665039 2621.996338,2205.220215 
z"
        />
        <path
          fill="#7392AD"
          opacity="1.000000"
          stroke="none"
          d="
M2363.443115,1987.543823 
	C2381.623047,1984.559326 2399.671143,1987.766602 2417.268311,1984.267090 
	C2419.826904,1983.758301 2422.554443,1983.989136 2425.200439,1984.044189 
	C2439.142578,1984.334106 2453.083740,1984.677734 2467.517578,1985.529541 
	C2465.157959,1997.503418 2467.819824,2001.351440 2479.628418,2001.912476 
	C2487.276123,2002.275513 2494.954834,2002.037842 2502.618896,2001.980469 
	C2506.030029,2001.954956 2509.240967,2002.211182 2511.843994,2004.841919 
	C2515.830811,2008.871216 2519.931396,2008.977783 2524.055908,2004.959106 
	C2525.758057,2003.300903 2527.974854,2001.225220 2530.146240,2002.568970 
	C2535.492676,2005.877075 2540.930664,2004.326416 2546.483887,2004.143188 
	C2551.163574,2003.988770 2556.451904,2004.951538 2559.232910,2008.706543 
	C2563.676758,2014.706909 2569.022461,2014.671021 2575.128174,2013.307251 
	C2576.405273,2013.022095 2577.620117,2012.430420 2578.914795,2013.685791 
	C2579.170410,2016.775391 2577.299316,2017.617554 2574.601562,2017.279175 
	C2572.100098,2016.965576 2570.590820,2018.104492 2570.470215,2020.391602 
	C2570.298096,2023.656250 2572.864014,2023.930420 2575.296143,2023.952759 
	C2579.256104,2023.989136 2582.723389,2023.991699 2586.003906,2020.338135 
	C2593.682617,2011.786255 2601.522217,2010.626953 2612.163330,2017.201172 
	C2617.959961,2020.782471 2618.552002,2020.983154 2622.268311,2015.153687 
	C2624.262695,2012.025879 2625.688477,2010.371338 2630.042725,2011.374390 
	C2633.988770,2012.283447 2638.461182,2011.266846 2642.646240,2010.694824 
	C2648.635254,2009.876465 2654.570557,2008.444702 2658.946777,2014.574585 
	C2659.565186,2015.441162 2661.380859,2015.965698 2662.553467,2015.836914 
	C2668.394043,2015.196655 2671.867432,2018.060181 2673.405273,2023.500977 
	C2682.302490,2017.716431 2682.697998,2017.271484 2680.079102,2011.294556 
	C2679.053711,2008.954590 2677.875000,2007.053101 2680.320312,2005.211670 
	C2682.511475,2003.561890 2684.743896,2004.122803 2686.671875,2005.849731 
	C2687.656006,2006.731445 2688.471680,2007.801270 2689.681641,2009.393066 
	C2690.221680,2011.313721 2690.434570,2012.945679 2691.741211,2013.340088 
	C2695.879150,2014.588867 2695.201172,2017.040894 2694.297852,2020.276855 
	C2693.380615,2023.563599 2689.927979,2027.628784 2693.313721,2030.311646 
	C2696.336914,2032.707153 2699.483887,2028.113525 2703.018555,2027.510376 
	C2708.084473,2026.646118 2702.193848,2021.516113 2705.943115,2018.867432 
	C2710.463623,2019.407227 2714.962891,2022.686035 2720.283691,2022.609009 
	C2721.663574,2022.588989 2722.967529,2024.495850 2724.479248,2025.025024 
	C2728.825439,2026.547485 2729.516113,2030.750977 2731.564453,2034.005981 
	C2734.047607,2037.952148 2739.549561,2031.083252 2741.870117,2037.334717 
	C2740.869385,2039.418945 2739.480469,2040.507446 2737.287354,2041.618164 
	C2734.489014,2042.984985 2733.706055,2044.323853 2735.653320,2046.417603 
	C2737.219238,2048.101074 2739.029053,2049.727783 2737.998779,2053.043457 
	C2730.463135,2057.596924 2723.647949,2055.399658 2716.301270,2052.090576 
	C2713.698730,2049.197266 2713.513916,2046.353882 2713.665527,2043.378296 
	C2713.706787,2042.572144 2714.768799,2041.656250 2713.567871,2040.890625 
	C2713.103760,2041.747314 2712.508057,2042.506958 2711.322021,2043.684692 
	C2709.646484,2044.828125 2711.149414,2044.119019 2709.937256,2044.658447 
	C2698.251221,2049.857910 2684.754395,2046.222412 2673.212891,2052.164062 
	C2662.634766,2042.761108 2649.037598,2045.179077 2636.658936,2042.821655 
	C2631.702393,2041.877686 2630.373047,2038.514038 2630.887451,2033.888672 
	C2630.592285,2038.371948 2629.528809,2042.561646 2624.396973,2043.138306 
	C2610.043701,2044.750732 2595.818359,2048.177979 2581.200684,2046.493652 
	C2579.205566,2046.263794 2577.125488,2046.228516 2575.374023,2045.272705 
	C2567.892578,2041.189453 2562.113281,2041.049072 2557.375244,2049.884766 
	C2553.964844,2056.244629 2547.075195,2054.469971 2541.072998,2053.576660 
	C2533.620850,2052.467529 2525.758057,2049.972656 2520.479492,2058.258301 
	C2520.700195,2060.677490 2523.237549,2061.557373 2523.996582,2060.982910 
	C2531.824219,2055.059814 2541.049805,2060.749756 2549.137451,2057.703369 
	C2549.651611,2057.509766 2550.006836,2057.522949 2550.114014,2057.505371 
	C2559.167725,2056.027344 2566.697510,2059.554688 2567.113525,2065.468994 
	C2567.510254,2071.110352 2562.024170,2074.769531 2552.093994,2074.913574 
	C2545.034912,2075.015625 2538.174316,2074.614746 2533.280518,2081.402832 
	C2531.532715,2083.826660 2528.191650,2084.461182 2524.533936,2082.456055 
	C2518.796143,2082.152100 2516.862549,2075.416748 2510.708984,2076.027344 
	C2508.724609,2077.111816 2507.275391,2077.589600 2504.882568,2077.121094 
	C2502.889893,2075.827393 2502.346436,2074.447266 2502.186768,2072.163330 
	C2502.728271,2068.397217 2504.759766,2065.675049 2504.075684,2061.725342 
	C2503.894043,2059.725830 2504.019043,2058.402344 2504.438721,2056.450439 
	C2501.805908,2049.255615 2497.726318,2044.032715 2490.121338,2043.183350 
	C2486.674561,2042.798584 2483.545898,2042.138306 2482.601807,2048.026855 
	C2481.536133,2054.673340 2470.325684,2057.498047 2462.289307,2053.109131 
	C2453.436035,2048.274170 2445.076660,2048.320801 2435.916992,2051.748779 
	C2429.779541,2054.045898 2423.494141,2051.566895 2416.675293,2050.160156 
	C2409.680664,2052.318115 2403.959717,2052.688477 2398.641602,2047.123657 
	C2396.549561,2044.934326 2392.916260,2044.115112 2389.291016,2045.170654 
	C2386.793457,2045.898071 2383.882568,2046.420044 2381.429443,2045.853027 
	C2374.739502,2044.306641 2369.392578,2044.018921 2369.480957,2053.230469 
	C2369.488037,2053.968018 2368.384033,2055.286621 2367.680664,2055.371094 
	C2361.436035,2056.120117 2353.624268,2058.508057 2349.325928,2054.427979 
	C2341.512695,2047.011475 2333.895020,2049.063721 2325.569824,2050.884033 
	C2325.002686,2054.442627 2327.658447,2053.682373 2329.364990,2054.003906 
	C2330.992188,2054.310303 2332.903320,2054.279541 2332.948486,2056.519287 
	C2332.976562,2057.911621 2331.836914,2058.836182 2330.666260,2059.540527 
	C2330.381104,2059.712158 2330.108154,2059.966309 2329.799072,2060.026855 
	C2319.433838,2062.055176 2309.148682,2064.927979 2298.426270,2063.624268 
	C2296.373535,2063.375000 2294.757324,2061.930908 2294.548096,2059.640869 
	C2294.304443,2056.977783 2296.172852,2056.363525 2298.310791,2056.062744 
	C2299.615723,2055.879395 2301.036377,2055.832764 2302.168213,2055.257812 
	C2304.049561,2054.302734 2305.063721,2052.490967 2305.071045,2050.382812 
	C2305.078125,2048.357178 2303.305420,2047.628052 2301.793457,2047.080444 
	C2296.909912,2045.311890 2292.100098,2045.984009 2287.115967,2047.204468 
	C2282.340332,2048.374268 2278.405273,2049.334961 2277.427246,2055.333496 
	C2276.822266,2059.042480 2272.825928,2059.733887 2269.392822,2059.913330 
	C2264.559082,2060.166260 2261.185547,2057.396729 2258.589355,2053.840820 
	C2256.998291,2051.661133 2259.127197,2046.557129 2255.013916,2046.718384 
	C2251.459229,2046.857788 2246.891357,2046.637817 2244.162109,2051.287598 
	C2241.673828,2053.979248 2238.501953,2054.327881 2236.960449,2052.155518 
	C2231.964600,2045.117188 2223.760010,2045.982666 2216.534180,2042.599609 
	C2215.013184,2035.794678 2210.387695,2033.783325 2205.327393,2034.790161 
	C2201.204346,2035.610352 2198.419678,2034.104858 2194.618652,2032.278320 
	C2191.609375,2026.969849 2195.631104,2025.630737 2198.596436,2026.096436 
	C2212.054688,2028.210449 2215.400635,2019.332153 2218.010254,2009.286011 
	C2218.255615,2008.342529 2218.787598,2007.490723 2219.717285,2006.063110 
	C2226.651611,2002.453857 2237.074707,2006.256470 2238.722168,2012.372925 
	C2239.938477,2016.888062 2236.742188,2018.876709 2233.594238,2020.998657 
	C2230.501709,2023.083130 2222.644043,2023.145996 2226.615967,2028.735962 
	C2228.976562,2032.058105 2237.587646,2032.545654 2241.203857,2028.555176 
	C2245.695557,2023.598389 2253.353516,2019.374878 2250.578125,2010.173584 
	C2249.720703,2007.330444 2251.524414,2004.903076 2254.480469,2004.103149 
	C2259.072510,2002.861084 2263.799072,2002.348389 2267.816162,2005.588257 
	C2270.221924,2007.528687 2269.752197,2010.327759 2268.788574,2012.972290 
	C2267.614258,2016.194092 2264.597900,2019.132935 2267.077148,2023.287964 
	C2273.386963,2009.394287 2285.195801,2003.674194 2299.919922,2008.208862 
	C2306.412842,2010.208618 2307.381592,2007.966797 2307.615723,2002.452271 
	C2307.933838,1994.959229 2310.596436,1988.786865 2319.476562,1986.532959 
	C2325.130371,1988.382812 2330.219482,1986.666504 2335.363525,1986.111084 
	C2338.940674,1985.724609 2342.590820,1986.014648 2347.104248,1986.010986 
	C2350.564697,1994.473022 2348.864258,1997.260132 2340.818848,1997.609131 
	C2338.494385,1997.710083 2336.169678,1997.271362 2333.838867,1997.292236 
	C2324.506836,1997.375854 2322.763672,1999.812622 2324.715088,2008.914551 
	C2325.833740,2014.131104 2328.064697,2016.088867 2332.854004,2012.859985 
	C2344.620361,2004.926758 2357.887695,2003.065308 2371.166260,2005.818970 
	C2383.487305,2008.374023 2394.429443,2001.277222 2407.723145,2003.359741 
	C2392.452148,2001.679321 2378.596680,2000.450195 2364.648193,2000.626343 
	C2360.738525,2000.675781 2355.672852,2000.959473 2355.243896,1995.627686 
	C2354.877930,1991.077026 2357.284668,1987.247803 2363.443115,1987.543823 
z"
        />
        <path
          fill="#728DA3"
          opacity="1.000000"
          stroke="none"
          d="
M1949.587646,2143.483398 
	C1949.951172,2145.772705 1950.136597,2147.583008 1948.499023,2149.566406 
	C1944.686157,2151.703857 1941.018799,2151.904541 1937.492432,2151.729980 
	C1931.927979,2151.455566 1927.603394,2152.438232 1924.146973,2157.587402 
	C1919.576660,2164.396240 1913.237061,2161.274170 1906.596191,2159.109375 
	C1897.006226,2157.540527 1888.354980,2161.647705 1878.785767,2159.410889 
	C1873.666260,2153.075195 1874.502930,2147.608887 1880.029785,2141.985840 
	C1880.976685,2141.018066 1881.411987,2140.585938 1881.845825,2140.152344 
	C1882.497559,2139.500977 1883.186523,2138.893311 1883.931519,2138.350098 
	C1883.363403,2138.694824 1882.891235,2139.159424 1881.923462,2140.064209 
	C1879.446045,2143.116943 1876.134399,2144.357666 1874.653442,2147.564697 
	C1873.658081,2149.720459 1871.790405,2151.434326 1869.119751,2150.635010 
	C1866.492310,2149.848389 1865.215576,2147.017090 1866.048218,2145.115723 
	C1869.862305,2136.405762 1856.460693,2133.659668 1859.227783,2125.188965 
	C1859.370483,2124.752441 1853.189087,2122.707031 1850.245728,2124.612793 
	C1848.071167,2126.020752 1849.677856,2128.442383 1851.261353,2129.458496 
	C1859.677979,2134.858643 1857.469360,2138.843018 1849.865234,2142.472900 
	C1848.714233,2143.022217 1848.367310,2144.596191 1847.093506,2146.155762 
	C1843.473511,2149.039551 1839.734619,2148.737305 1835.617065,2149.029785 
	C1841.192871,2149.221191 1846.102539,2150.387207 1851.711914,2151.772949 
	C1856.631104,2153.664551 1861.012329,2154.840088 1866.118164,2156.358398 
	C1868.280518,2157.496826 1869.608643,2158.534180 1871.249878,2158.923096 
	C1874.444214,2159.679688 1878.351562,2159.885498 1878.764038,2164.071045 
	C1879.137573,2167.861816 1876.087036,2169.837646 1872.306763,2171.818848 
	C1867.999878,2173.048340 1864.568726,2173.190430 1860.673340,2170.560059 
	C1851.890137,2168.154053 1844.373901,2168.133789 1839.192749,2176.183838 
	C1837.941284,2178.128662 1835.748169,2178.051270 1833.787842,2177.947998 
	C1821.555786,2177.303711 1809.207031,2179.594971 1797.064575,2176.271484 
	C1793.477905,2175.289551 1789.825439,2176.267578 1786.300537,2177.460205 
	C1785.041260,2177.886475 1783.594238,2177.713135 1783.104492,2176.080811 
	C1782.633789,2174.511475 1783.736328,2173.595459 1784.857544,2172.897217 
	C1785.686279,2172.380615 1786.693604,2172.150879 1787.621704,2171.794189 
	C1790.379272,2170.734131 1792.709961,2168.900879 1791.088867,2165.875244 
	C1789.510498,2162.929688 1786.495361,2164.135254 1783.979492,2165.087891 
	C1780.877075,2166.262939 1777.766846,2167.425293 1774.715454,2168.723877 
	C1772.702026,2169.580566 1770.831177,2169.505859 1769.022949,2168.360352 
	C1767.058838,2167.116699 1766.253052,2166.214844 1768.612549,2164.003174 
	C1771.705078,2161.104248 1771.032349,2159.157227 1767.545410,2158.274170 
	C1763.297852,2157.198730 1759.838745,2159.197266 1756.850098,2161.245117 
	C1753.388062,2160.297607 1753.723511,2157.480225 1753.008911,2155.470947 
	C1752.001221,2152.637207 1753.835571,2150.992920 1756.325439,2150.400635 
	C1758.231689,2149.947266 1760.420654,2149.606201 1762.253174,2150.089600 
	C1776.108887,2153.746582 1789.898682,2148.705322 1803.725586,2149.956543 
	C1808.380981,2150.377686 1812.865601,2149.521973 1817.029541,2146.420654 
	C1813.739624,2140.178467 1808.472290,2137.020508 1802.040039,2136.520752 
	C1794.104370,2135.905029 1786.098999,2136.139404 1778.123413,2136.110596 
	C1775.623535,2136.101562 1773.850708,2137.390137 1772.528564,2139.617432 
	C1768.442017,2146.502441 1764.760132,2147.695801 1757.828369,2144.518555 
	C1749.987427,2140.924805 1742.181519,2137.272705 1735.797363,2131.229492 
	C1735.156738,2130.623291 1733.956299,2130.608643 1731.942139,2129.768066 
	C1730.223755,2126.575928 1731.099731,2123.930176 1730.835083,2121.283691 
	C1730.379272,2116.721680 1732.013306,2115.096680 1737.022583,2114.861328 
	C1746.918335,2114.396729 1754.942139,2108.197998 1764.584229,2105.598877 
	C1761.684448,2100.341064 1756.639404,2101.550781 1753.277100,2098.339355 
	C1750.523560,2092.323242 1749.122070,2086.509766 1745.907715,2081.360352 
	C1743.821045,2078.017334 1744.043701,2074.339355 1746.895752,2071.233154 
	C1749.619995,2068.266357 1753.031250,2067.428223 1756.878174,2068.340332 
	C1760.984985,2069.313721 1764.664185,2072.426025 1769.598145,2070.636230 
	C1764.477051,2066.052979 1759.616089,2061.769531 1752.934204,2059.281250 
	C1746.565063,2056.909424 1746.452515,2050.541504 1747.869385,2044.739136 
	C1749.214600,2039.229614 1753.272339,2037.367676 1758.578369,2038.668213 
	C1766.642700,2040.645264 1771.069092,2036.136597 1772.382324,2029.480835 
	C1774.118042,2020.683594 1779.631714,2018.629028 1786.801025,2019.898438 
	C1797.876465,2021.859741 1808.604370,2020.001343 1819.435669,2018.563232 
	C1834.220703,2016.600220 1834.403687,2016.719116 1837.852539,2031.285034 
	C1837.703735,2036.162964 1839.596069,2037.864746 1843.807617,2038.153564 
	C1848.326538,2038.463379 1849.418457,2039.839722 1847.479980,2045.241577 
	C1844.770752,2052.790527 1844.291626,2061.337402 1845.015869,2069.505615 
	C1845.384888,2073.667236 1846.965942,2078.176270 1852.881104,2078.051025 
	C1854.436035,2078.018066 1856.566895,2077.917236 1856.822632,2080.087891 
	C1857.120605,2082.615967 1855.052246,2083.628662 1853.043091,2083.728760 
	C1848.359009,2083.961914 1845.209351,2085.162598 1843.394531,2090.373047 
	C1841.007324,2097.227051 1842.046021,2103.236084 1846.628174,2107.953857 
	C1850.673584,2112.118896 1857.246704,2111.093750 1861.682739,2109.249756 
	C1866.340820,2107.313232 1873.113647,2107.463867 1875.119141,2100.607178 
	C1875.708862,2098.591064 1878.231934,2097.941650 1880.131348,2099.481445 
	C1882.556152,2101.447266 1885.553223,2101.934082 1888.236084,2103.202637 
	C1891.005981,2104.511963 1892.369751,2106.402100 1891.174072,2109.574219 
	C1890.341675,2111.782227 1889.400024,2115.031006 1892.456177,2115.293457 
	C1896.093628,2115.605957 1899.920654,2119.159912 1903.550293,2115.685791 
	C1908.603394,2110.849609 1908.513794,2110.772949 1912.213623,2116.029297 
	C1912.973022,2117.108154 1913.856934,2118.098877 1914.632324,2119.167480 
	C1914.791260,2119.386475 1914.720093,2119.772217 1914.827271,2120.722412 
	C1904.563232,2127.163086 1892.394531,2121.981445 1881.548584,2124.769287 
	C1881.708984,2128.572266 1884.210938,2127.910645 1885.951782,2127.927246 
	C1896.595093,2128.030273 1907.239746,2127.979980 1917.884033,2128.008301 
	C1920.209473,2128.014648 1922.555786,2127.964355 1924.853760,2128.250488 
	C1926.853271,2128.499756 1928.957520,2129.272705 1928.744019,2131.811768 
	C1928.557983,2134.023926 1926.427490,2134.051270 1924.891357,2133.900635 
	C1917.280273,2133.153564 1911.334839,2138.758545 1904.014526,2139.239990 
	C1902.225342,2139.357422 1899.992798,2140.984863 1901.244873,2143.472168 
	C1902.430298,2145.827148 1904.194702,2148.254639 1907.455322,2146.855225 
	C1908.920776,2146.226318 1909.913452,2144.494385 1911.379272,2143.868652 
	C1916.436035,2141.710205 1920.603516,2137.328857 1927.464233,2141.384277 
	C1931.192383,2143.587891 1937.210205,2141.917236 1943.092773,2141.992188 
	C1945.763550,2142.062744 1947.584717,2141.878906 1949.587646,2143.483398 
z"
        />
        <path
          fill="#6E8CA6"
          opacity="1.000000"
          stroke="none"
          d="
M2222.216064,2005.313721 
	C2220.817871,2008.375000 2219.671875,2010.819458 2220.029541,2013.531372 
	C2220.926758,2020.334351 2214.370850,2023.349854 2212.198486,2028.697754 
	C2211.124756,2031.340942 2206.220947,2030.118408 2203.009521,2029.322144 
	C2199.931152,2028.558838 2196.712646,2026.452637 2194.160645,2031.266357 
	C2191.859131,2031.991211 2189.732422,2031.992188 2186.807617,2031.991699 
	C2184.781982,2030.526611 2183.146240,2030.032471 2180.650391,2029.997925 
	C2175.252197,2029.987183 2170.513916,2029.992065 2164.887939,2029.998535 
	C2159.368164,2033.599365 2156.130371,2031.796997 2153.746338,2027.242188 
	C2152.399414,2024.668945 2150.429688,2022.105713 2147.768799,2024.503418 
	C2140.881592,2030.709351 2132.877441,2030.268433 2124.637451,2029.681030 
	C2123.408691,2029.593262 2122.102051,2030.291626 2120.490234,2031.519043 
	C2112.833252,2032.230713 2105.682617,2030.913940 2099.363525,2036.726929 
	C2095.545410,2040.239380 2092.646484,2038.026123 2091.622559,2031.697632 
	C2091.393799,2030.285889 2091.093506,2029.009888 2089.791016,2028.463867 
	C2084.263428,2026.146606 2078.217773,2024.020874 2072.531006,2025.359375 
	C2062.213135,2027.787720 2052.382812,2026.807861 2042.951904,2022.979492 
	C2039.204224,2021.458008 2036.726318,2020.207031 2033.242920,2023.133057 
	C2030.384155,2025.534424 2026.896973,2026.510986 2023.012207,2024.717896 
	C2020.042358,2023.347168 2017.242554,2024.343628 2014.613525,2026.205933 
	C2010.743286,2028.947266 2005.468262,2029.389282 2002.250122,2027.339111 
	C1995.208618,2022.852905 1990.217285,2026.960815 1985.335327,2030.109619 
	C1981.537231,2032.559204 1978.880981,2034.979126 1975.662598,2029.971924 
	C1975.486084,2029.697388 1975.246216,2029.334839 1974.973999,2029.269165 
	C1967.948853,2027.573486 1959.859863,2024.836426 1953.818359,2027.138306 
	C1946.151001,2030.059448 1940.586182,2028.483887 1934.295288,2025.516479 
	C1933.696411,2025.233887 1933.155884,2024.799072 1932.535645,2024.594971 
	C1925.637939,2022.325073 1921.283203,2025.659180 1921.396240,2033.096680 
	C1921.478882,2038.533081 1920.608887,2039.269897 1915.218994,2038.723511 
	C1911.596680,2038.356445 1907.951050,2038.223999 1904.323486,2037.900757 
	C1903.042236,2037.786499 1901.541260,2037.678467 1901.223999,2036.029419 
	C1900.824585,2033.953735 1902.352051,2033.088013 1903.908813,2032.478638 
	C1904.811890,2032.125122 1905.855957,2032.041138 1906.842529,2032.006592 
	C1911.451294,2031.845337 1912.364990,2029.285645 1911.455811,2025.284058 
	C1909.664795,2017.401733 1905.859741,2016.060425 1899.761841,2021.289795 
	C1898.502441,2022.369751 1897.432129,2023.668335 1896.189941,2024.770508 
	C1889.921631,2030.331787 1885.838135,2030.920044 1879.085327,2027.298462 
	C1873.345459,2024.219971 1871.744385,2020.548828 1872.918823,2012.286987 
	C1873.183105,2010.428223 1873.238403,2009.068237 1870.667236,2008.123291 
	C1867.994873,2005.856201 1867.306396,2003.342041 1868.053711,1999.693237 
	C1868.774658,1997.901611 1869.483276,1997.090210 1871.196045,1996.229736 
	C1877.928345,1995.743408 1883.993408,1996.851929 1889.769287,1994.456299 
	C1890.362061,1994.210205 1891.275635,1993.945679 1891.706787,1994.218018 
	C1902.138062,2000.805664 1910.928955,1991.248657 1920.760742,1991.089111 
	C1925.195068,1991.017212 1928.979614,1989.878418 1932.602417,1993.431519 
	C1935.965210,1996.729492 1939.153198,1996.657104 1942.848755,1992.904053 
	C1947.559814,1988.119507 1953.783813,1987.272705 1960.257202,1988.514771 
	C1960.305786,1989.618408 1960.591064,1990.406128 1960.315186,1990.783813 
	C1958.837036,1992.806274 1954.023804,1991.464355 1954.721924,1995.223755 
	C1955.485107,1999.333618 1959.524292,1997.623291 1962.273804,1997.960938 
	C1966.868408,1998.525146 1969.510254,1995.364136 1972.444824,1992.609009 
	C1977.819702,1987.562988 1978.863281,1987.156738 1984.173218,1991.629883 
	C1987.989624,1994.844849 1991.175293,1996.217896 1995.575684,1993.360352 
	C1997.131592,1992.350098 1998.717896,1992.746948 1999.989624,1994.216675 
	C2005.933594,2001.087036 2013.590332,1997.556396 2020.559692,1997.270996 
	C2023.581299,1997.147339 2026.116577,1995.105469 2024.680786,1990.988037 
	C2023.364502,1987.213379 2025.824951,1983.882446 2026.646606,1980.394165 
	C2027.196655,1978.059448 2029.528198,1977.930542 2031.602295,1978.605835 
	C2033.526489,1979.232178 2033.924316,1980.953369 2033.740601,1982.635620 
	C2033.602295,1983.902588 2032.929932,1985.122192 2032.432251,1986.337036 
	C2029.378174,1993.790161 2031.507690,1997.226440 2039.454590,1995.708008 
	C2046.173828,1994.423950 2052.507324,1994.119385 2059.007080,1996.004150 
	C2059.639160,1996.187500 2060.566162,1995.951416 2060.943848,1996.325928 
	C2068.147217,2003.470459 2074.466553,1995.604614 2081.142090,1994.905762 
	C2083.626465,1994.645874 2086.669922,1991.778809 2084.505615,1988.049805 
	C2082.796143,1985.104370 2075.883057,1983.174316 2073.797607,1984.815186 
	C2073.332031,1985.181396 2073.066406,1985.853882 2072.814209,1986.431274 
	C2070.062012,1992.730103 2069.761230,1992.935059 2066.403076,1987.847656 
	C2064.866211,1985.519165 2063.910156,1984.317261 2061.059570,1985.281006 
	C2059.563232,1985.786621 2057.756348,1985.817139 2056.181885,1985.539551 
	C2054.069336,1985.167114 2052.587646,1983.708374 2052.542969,1981.421021 
	C2052.491943,1978.783325 2054.450928,1978.277954 2056.561523,1978.069824 
	C2064.141113,1977.322632 2071.570557,1975.631470 2079.319824,1975.857910 
	C2087.907715,1976.109009 2096.508057,1975.510498 2105.133057,1974.717651 
	C2112.406494,1974.048950 2120.203125,1976.025269 2127.857422,1976.054688 
	C2134.284424,1976.079346 2140.410889,1975.901367 2145.823486,1971.591309 
	C2149.206787,1968.897583 2153.682373,1969.382568 2157.396973,1970.446655 
	C2162.996582,1972.050903 2168.572998,1971.934448 2175.112793,1972.005127 
	C2175.331543,1976.125732 2172.195557,1975.982178 2169.138672,1975.989868 
	C2163.143555,1976.004883 2157.148193,1975.991333 2151.153809,1976.072021 
	C2149.540283,1976.093750 2147.434082,1976.048462 2147.238770,1978.177612 
	C2147.013428,1980.642700 2149.071045,1981.406128 2151.108154,1981.800781 
	C2153.695068,1982.301880 2156.471924,1981.488159 2159.474121,1983.447266 
	C2156.394775,1991.025146 2163.984375,1999.826172 2155.544678,2007.143921 
	C2161.864990,2007.103149 2167.146240,2008.183472 2172.438965,2009.198608 
	C2177.733154,2010.213867 2182.991455,2009.351440 2189.326660,2008.929443 
	C2186.339844,2007.360840 2183.958496,2006.458862 2181.622559,2006.935669 
	C2174.661133,2008.356934 2170.728760,2005.186768 2169.183838,1998.853760 
	C2167.549561,1992.152466 2171.528320,1989.123169 2177.447021,1987.829346 
	C2180.417236,1987.180054 2177.481201,1986.104248 2177.831055,1984.638672 
	C2179.947754,1981.640259 2182.529541,1981.702759 2184.974121,1982.666992 
	C2188.046143,1983.878784 2191.166748,1984.035645 2195.186523,1984.001465 
	C2202.571045,1989.364624 2208.616211,1995.435791 2216.928467,1998.294189 
	C2219.808838,1999.284912 2222.007324,2001.293091 2222.216064,2005.313721 
z"
        />
        <path
          fill="#6E8AA1"
          opacity="1.000000"
          stroke="none"
          d="
M1838.495117,2031.996582 
	C1834.728516,2031.527588 1832.599609,2030.530518 1832.791748,2026.338135 
	C1833.073853,2020.185425 1831.817627,2018.834473 1826.587280,2020.442749 
	C1820.146118,2022.423096 1813.463379,2021.802979 1806.988770,2023.544800 
	C1801.993652,2024.888550 1796.411621,2024.600464 1791.183105,2023.323486 
	C1788.909180,2022.768066 1786.825806,2022.425903 1784.398193,2022.725342 
	C1777.020142,2023.634888 1776.500488,2023.425171 1776.138184,2030.556396 
	C1775.863037,2035.972656 1770.952271,2037.087646 1768.747437,2040.597656 
	C1765.461426,2045.829346 1761.852295,2043.530884 1757.725952,2041.296997 
	C1753.630005,2039.079712 1750.952026,2040.974976 1750.101562,2045.722900 
	C1748.649780,2053.827148 1750.641113,2056.568604 1759.379395,2058.000977 
	C1761.777222,2058.394287 1764.516846,2058.659424 1765.124023,2061.134766 
	C1766.670898,2067.441162 1771.133179,2068.349365 1776.489258,2068.233154 
	C1777.486084,2068.211426 1778.396851,2068.678711 1778.666382,2070.598145 
	C1774.755127,2072.177002 1770.671753,2073.932129 1766.505371,2075.460938 
	C1764.457764,2076.212402 1762.656250,2075.248779 1761.016968,2073.937500 
	C1759.473389,2072.702393 1757.980591,2071.188232 1756.194946,2070.519287 
	C1753.095093,2069.357910 1750.416504,2070.579102 1748.146973,2072.960205 
	C1745.767822,2075.456299 1746.478149,2078.108154 1748.231323,2080.150635 
	C1752.285889,2084.875000 1755.110840,2089.855225 1754.003296,2097.164062 
	C1742.119995,2102.399170 1738.355103,2100.429688 1738.744385,2089.207520 
	C1738.902222,2084.660156 1737.069580,2084.326904 1733.635620,2084.548584 
	C1729.437988,2084.819336 1725.272095,2086.459229 1720.511841,2084.469238 
	C1719.647339,2078.025391 1722.669067,2072.304199 1719.162109,2065.847168 
	C1717.440430,2062.677490 1720.896729,2057.083252 1723.777710,2053.303467 
	C1727.167358,2048.856689 1728.993042,2044.347900 1726.623535,2038.575806 
	C1725.048340,2034.738892 1726.540039,2031.560181 1730.839111,2029.941162 
	C1735.125000,2028.327026 1734.165771,2025.302979 1731.750977,2022.982056 
	C1727.471558,2018.869263 1724.444702,2014.598755 1722.441284,2008.601318 
	C1720.817383,2003.739868 1717.329834,1998.951538 1714.427612,1993.625610 
	C1718.157471,1991.422363 1721.688477,1990.024902 1725.734009,1990.003540 
	C1728.063232,1989.991211 1730.327759,1989.867798 1732.355347,1988.385742 
	C1736.359619,1985.458862 1736.984985,1981.636353 1733.176758,1978.465942 
	C1729.444458,1975.359009 1725.724854,1971.659790 1720.189941,1972.019897 
	C1717.104126,1972.220581 1714.347290,1971.014526 1710.695557,1970.152710 
	C1708.151855,1971.990601 1706.273926,1973.658691 1702.693115,1972.962158 
	C1697.070435,1963.257568 1705.681274,1961.273926 1710.707642,1958.505859 
	C1717.792847,1954.604126 1717.475464,1951.799438 1710.679199,1947.419434 
	C1709.289551,1944.011597 1709.476440,1941.346191 1711.329224,1938.072510 
	C1719.332886,1932.614502 1727.887451,1935.108032 1735.891235,1933.925171 
	C1747.232056,1932.249390 1758.711060,1931.517334 1770.760010,1929.076416 
	C1776.577515,1927.536987 1782.975586,1935.593018 1787.083252,1926.046143 
	C1789.208252,1916.099487 1795.636719,1914.250000 1804.585205,1915.375000 
	C1810.265137,1917.486084 1815.333374,1918.786743 1821.137085,1920.274414 
	C1824.661011,1922.815186 1827.573730,1924.823730 1831.324585,1925.197876 
	C1838.468140,1925.909912 1842.314331,1930.172119 1844.172852,1937.531006 
	C1844.785156,1938.546509 1844.993408,1938.972290 1845.400757,1940.071045 
	C1845.658081,1941.200439 1845.716431,1941.656982 1845.769775,1942.793457 
	C1844.677612,1947.089844 1846.017578,1950.265503 1847.846924,1954.026001 
	C1848.967163,1956.856323 1849.534180,1959.130371 1849.813477,1962.199097 
	C1846.687256,1977.712891 1842.585815,1980.424561 1828.488525,1976.715942 
	C1824.249390,1975.600708 1819.974976,1974.657837 1815.661865,1975.256226 
	C1807.949463,1976.326050 1800.563721,1974.982056 1792.938110,1973.955078 
	C1778.511475,1972.012573 1765.031128,1977.720459 1751.134644,1981.249512 
	C1762.165405,1985.488403 1770.625000,1991.243042 1776.771240,2002.027100 
	C1784.888428,2016.269165 1800.027588,2016.176270 1809.927612,2003.474976 
	C1815.218018,1996.687378 1822.957031,1995.612915 1830.443970,1994.918335 
	C1835.342529,1994.463623 1839.725342,1998.786865 1837.438110,2003.308716 
	C1832.733398,2012.609741 1840.257324,2012.614502 1845.415894,2015.592529 
	C1849.130615,2022.543213 1844.513062,2026.516479 1840.530273,2031.431519 
	C1839.661743,2031.987793 1839.328491,2031.990356 1838.495117,2031.996582 
z"
        />
        <path
          fill="#7B98B0"
          opacity="1.000000"
          stroke="none"
          d="
M2715.556396,2049.550293 
	C2722.675781,2052.589111 2729.638184,2052.870850 2737.336426,2052.284180 
	C2738.714844,2047.880127 2730.881592,2046.015137 2735.545654,2040.486572 
	C2737.120361,2038.321899 2738.830566,2038.030762 2741.329590,2038.011963 
	C2744.097168,2040.515625 2747.403076,2041.801758 2748.659668,2045.232056 
	C2750.284424,2049.667480 2752.753174,2052.917480 2758.368408,2050.419678 
	C2759.917480,2049.730713 2761.593750,2050.824951 2763.047852,2051.663818 
	C2764.551758,2052.531250 2765.772461,2053.613281 2765.375977,2055.586182 
	C2765.061523,2057.149414 2763.740967,2057.843506 2762.443848,2057.806152 
	C2757.322510,2057.659424 2755.091309,2060.318848 2753.046631,2064.882568 
	C2750.424805,2070.733643 2742.121826,2072.287598 2733.495361,2068.482910 
	C2727.667969,2065.912598 2721.879395,2066.024414 2715.921387,2066.035400 
	C2713.990967,2066.039062 2711.530762,2065.723145 2711.062744,2068.308594 
	C2710.758301,2069.992676 2712.187744,2071.461182 2713.609863,2072.328369 
	C2717.466064,2074.679443 2720.961914,2077.170410 2719.807129,2082.490723 
	C2719.616211,2083.370117 2718.981689,2084.153320 2718.664551,2084.764160 
	C2716.858154,2085.442383 2715.887207,2084.347168 2714.712646,2083.800781 
	C2705.886963,2079.695068 2697.127441,2076.582520 2687.006104,2079.877197 
	C2675.077393,2083.760498 2662.570557,2081.693848 2650.309570,2081.941406 
	C2644.373291,2082.061279 2638.986816,2082.823730 2635.670166,2088.504150 
	C2633.914551,2091.511230 2631.050049,2092.249268 2627.931396,2091.956543 
	C2617.227783,2090.952393 2606.878906,2095.020264 2596.179932,2094.052002 
	C2592.093750,2093.681885 2589.838623,2095.603760 2590.022949,2099.853027 
	C2590.274658,2105.660156 2586.545410,2106.349365 2582.134277,2105.951416 
	C2575.872803,2105.386719 2569.731201,2104.022217 2563.405029,2103.605957 
	C2559.333740,2103.338135 2555.675781,2102.738770 2551.977539,2104.883789 
	C2550.169189,2105.932617 2547.827881,2106.264648 2546.738281,2104.041260 
	C2545.446777,2101.406494 2545.907715,2098.123047 2548.750488,2097.205811 
	C2552.602783,2095.962891 2556.674805,2093.024658 2560.954346,2096.025146 
	C2563.193848,2097.595459 2565.170410,2097.566895 2567.377441,2096.136963 
	C2569.282471,2094.902588 2570.049316,2093.236328 2569.594482,2091.053955 
	C2569.201172,2089.167480 2567.817871,2088.312744 2566.025879,2088.163330 
	C2557.779297,2087.474609 2549.389404,2089.034424 2541.232422,2086.476074 
	C2540.271240,2086.174805 2538.740234,2087.132812 2537.673096,2087.818115 
	C2530.864990,2092.189697 2530.846924,2092.250732 2526.035889,2086.864990 
	C2525.187256,2085.915039 2524.784668,2084.566406 2524.087891,2082.700928 
	C2528.503906,2081.835938 2532.830566,2081.260010 2534.053955,2075.862793 
	C2534.827393,2072.449463 2537.266113,2072.030762 2540.082764,2072.024902 
	C2544.407227,2072.016113 2548.731689,2072.027100 2553.055908,2071.991211 
	C2555.049072,2071.974609 2557.091553,2072.120605 2559.023682,2071.743408 
	C2562.122070,2071.138672 2564.993408,2069.472656 2565.055908,2066.089844 
	C2565.118164,2062.696533 2562.289062,2060.638672 2559.212646,2060.348633 
	C2554.687012,2059.921143 2549.241211,2058.181152 2545.849365,2061.911621 
	C2541.540527,2066.651123 2539.499756,2064.880859 2537.042480,2060.674072 
	C2536.588379,2059.896729 2535.538330,2059.467529 2534.886230,2058.971191 
	C2530.709961,2059.334473 2533.062744,2065.246338 2529.560303,2065.259766 
	C2525.663818,2065.274902 2521.665283,2064.514893 2517.890381,2063.454346 
	C2513.923340,2062.339355 2513.397705,2058.708008 2516.441406,2055.956299 
	C2524.961426,2048.253418 2527.395020,2047.711060 2541.728516,2050.321777 
	C2553.079102,2052.389160 2553.236572,2052.465820 2558.101807,2042.466797 
	C2559.900391,2038.770752 2562.428955,2037.502930 2566.062744,2038.448120 
	C2568.926758,2039.193237 2571.766602,2040.278809 2574.379883,2041.661621 
	C2576.459717,2042.762451 2578.549316,2044.603271 2580.790527,2043.825806 
	C2587.740723,2041.415039 2594.513672,2044.061035 2601.359375,2044.029419 
	C2605.408447,2044.010742 2609.315674,2044.053345 2612.383545,2040.690430 
	C2615.022705,2037.796875 2618.033203,2037.451172 2621.158936,2040.109253 
	C2623.357422,2041.978760 2625.326660,2041.504028 2627.295410,2039.602539 
	C2629.105225,2037.854248 2628.846191,2036.247314 2627.554443,2034.341797 
	C2625.975098,2032.011841 2625.065674,2029.326416 2627.423828,2027.104980 
	C2629.166748,2025.462524 2631.136963,2026.624023 2632.764160,2027.924194 
	C2635.583740,2030.177612 2636.101807,2032.660400 2633.689697,2035.616333 
	C2633.148926,2036.278687 2633.147461,2037.381226 2632.708496,2038.964111 
	C2639.976318,2040.189087 2646.402832,2043.193970 2654.071533,2040.641846 
	C2658.806396,2039.065796 2663.467041,2041.465088 2667.176025,2045.677490 
	C2672.157471,2051.334961 2676.939941,2052.035645 2681.593506,2046.996094 
	C2686.203613,2042.003662 2690.970215,2042.471313 2695.866211,2044.474731 
	C2700.899902,2046.534912 2704.782471,2045.599731 2709.283203,2042.150391 
	C2712.772461,2043.596436 2713.081299,2046.970581 2715.556396,2049.550293 
z"
        />
        <path
          fill="#7B98B0"
          opacity="1.000000"
          stroke="none"
          d="
M2416.129150,2050.692871 
	C2419.153076,2046.870728 2422.679199,2048.072510 2426.225342,2049.067383 
	C2431.207764,2050.465332 2435.992920,2051.153564 2439.572998,2045.981323 
	C2440.192871,2045.085815 2442.759766,2044.290527 2442.889404,2044.483154 
	C2447.521484,2051.371826 2454.476807,2041.940430 2459.382812,2046.945557 
	C2464.533691,2052.200195 2471.718018,2053.641113 2477.974121,2050.842529 
	C2480.959473,2049.506836 2482.576172,2048.072998 2478.455811,2045.594971 
	C2475.093750,2043.572998 2477.258545,2041.499146 2479.582764,2040.686646 
	C2483.724365,2039.238770 2488.135742,2040.178345 2492.429443,2040.027832 
	C2497.441650,2039.852173 2500.554443,2042.448975 2502.083496,2047.030273 
	C2503.328857,2050.761230 2507.442871,2049.187988 2509.465820,2051.180908 
	C2512.520508,2054.190186 2511.023193,2055.629639 2506.700684,2055.981934 
	C2504.368164,2057.502197 2503.758301,2059.168945 2505.583740,2061.580811 
	C2509.197754,2064.862549 2507.775391,2067.192627 2504.574707,2069.692139 
	C2501.387939,2073.517334 2496.770996,2071.874512 2493.424805,2073.916992 
	C2496.465820,2076.664062 2499.921631,2073.980225 2503.466309,2075.556641 
	C2505.495361,2076.003174 2506.990723,2076.006348 2509.233398,2076.014648 
	C2509.105469,2085.507568 2508.886475,2085.875977 2500.287354,2085.943848 
	C2495.332764,2085.983154 2490.386963,2086.358643 2485.414307,2084.923828 
	C2481.318115,2083.741943 2479.125000,2086.602539 2480.551025,2090.452148 
	C2482.104980,2094.647461 2486.685303,2097.980225 2491.324219,2097.109619 
	C2495.347412,2096.354248 2498.512939,2097.739502 2501.724854,2099.343018 
	C2503.207520,2100.083008 2503.886475,2101.804932 2503.370117,2103.656738 
	C2502.941895,2105.191162 2501.671631,2105.634521 2500.346680,2105.836182 
	C2495.843262,2106.521484 2491.646729,2105.528564 2488.088867,2102.751953 
	C2484.902100,2100.264648 2481.628174,2099.292969 2477.904053,2101.059814 
	C2473.405029,2103.194580 2469.485107,2100.277100 2465.289307,2099.816650 
	C2463.768799,2099.649902 2463.273193,2098.575684 2462.762207,2097.223145 
	C2460.542969,2091.350830 2459.072266,2090.554443 2453.434570,2093.475586 
	C2449.026367,2095.759766 2444.691162,2098.000000 2439.902100,2099.418457 
	C2435.102295,2100.840332 2432.625977,2100.044434 2432.944092,2095.146484 
	C2433.240234,2090.588379 2431.562012,2090.171387 2428.139160,2089.866699 
	C2425.854004,2089.663574 2423.636230,2088.770020 2421.371338,2088.259277 
	C2418.615723,2087.637695 2414.778809,2088.605225 2414.272461,2084.411377 
	C2413.759766,2080.166748 2413.038818,2075.609375 2416.902832,2072.291748 
	C2418.398682,2071.007324 2420.257812,2070.155762 2421.891113,2069.020508 
	C2427.298096,2065.261719 2427.479980,2060.143555 2422.293457,2056.257568 
	C2420.219238,2054.703857 2417.239502,2054.293701 2416.129150,2050.692871 
z"
        />
        <path
          fill="#728DA3"
          opacity="1.000000"
          stroke="none"
          d="
M1845.708252,2015.424561 
	C1840.892090,2016.691528 1836.274536,2014.221924 1831.338013,2013.651611 
	C1827.906860,2013.255493 1827.436279,2010.431030 1830.354614,2008.128662 
	C1833.167969,2005.909180 1837.696045,2004.416504 1835.198364,1999.874512 
	C1832.691040,1995.315186 1828.911499,1996.585205 1825.049805,1999.044312 
	C1823.257690,2000.185547 1820.535278,1999.775024 1818.304688,2000.342651 
	C1815.714111,2001.001831 1812.682983,2001.898682 1812.380005,2004.925171 
	C1811.562866,2013.089111 1803.511353,2013.040527 1799.095459,2017.065308 
	C1798.339355,2017.754395 1796.410522,2017.814819 1795.330200,2017.388428 
	C1784.551270,2013.135742 1772.916870,2009.790039 1769.994873,1996.173462 
	C1769.533325,1994.023315 1768.377808,1993.056519 1766.402832,1992.005005 
	C1759.154419,1988.145630 1751.694702,1984.958740 1743.717163,1982.990723 
	C1743.530273,1982.944702 1743.450928,1982.463013 1743.321289,1982.184448 
	C1747.546265,1978.556641 1759.110229,1974.184326 1765.552612,1974.044434 
	C1769.247314,1973.964111 1772.743774,1973.609619 1776.177246,1971.984497 
	C1782.809937,1968.844727 1789.743774,1969.290527 1796.562866,1971.300659 
	C1801.342896,1972.709595 1805.757080,1973.516602 1811.015991,1972.498047 
	C1817.702881,1971.202759 1824.665405,1971.388550 1831.463745,1974.482178 
	C1839.549194,1978.161499 1843.710571,1975.646729 1846.100220,1967.186768 
	C1846.458618,1965.917725 1846.938721,1964.683228 1847.668213,1962.767578 
	C1848.349854,1961.808105 1848.725830,1961.513916 1849.813232,1961.263916 
	C1852.847534,1962.552124 1855.561890,1963.078735 1857.652466,1964.667114 
	C1861.516357,1967.602539 1862.790405,1970.828491 1857.709595,1973.913574 
	C1854.555786,1975.828491 1848.938843,1976.257568 1851.007446,1981.791382 
	C1852.957397,1987.007568 1853.872437,1993.726440 1861.845459,1994.113159 
	C1864.427612,1994.238403 1867.007446,1995.218628 1869.582031,1997.444092 
	C1869.997314,2000.777588 1869.999512,2003.550293 1870.003296,2007.154175 
	C1864.687012,2008.334717 1859.609863,2007.789307 1855.274292,2004.231079 
	C1849.985107,1999.890137 1847.972534,2005.903564 1844.777466,2007.753662 
	C1840.414307,2010.280396 1844.160278,2012.558350 1845.708252,2015.424561 
z"
        />
        <path
          fill="#7B98B0"
          opacity="1.000000"
          stroke="none"
          d="
M2679.703613,2115.502441 
	C2679.952393,2117.371582 2679.973877,2118.706055 2679.906494,2120.036133 
	C2679.778809,2122.547363 2678.866455,2124.651611 2676.275391,2125.416504 
	C2673.296387,2126.295410 2672.354980,2123.902100 2670.972656,2122.000000 
	C2666.027100,2115.196289 2659.936279,2110.207520 2651.091797,2109.346191 
	C2645.304443,2108.782471 2644.799805,2109.045166 2645.265137,2114.656494 
	C2646.234863,2126.361328 2641.158203,2131.269775 2629.113281,2130.169189 
	C2626.013672,2129.886230 2623.152100,2130.157959 2620.650391,2132.243164 
	C2619.330078,2133.343506 2617.539307,2134.324463 2616.059326,2133.506348 
	C2610.844238,2130.623779 2606.996094,2124.902832 2599.760742,2126.178711 
	C2599.123779,2126.290771 2597.954834,2123.624756 2597.101562,2122.208008 
	C2596.070312,2120.495117 2595.181396,2118.673828 2592.958740,2118.195312 
	C2590.680664,2117.705322 2587.937256,2117.539062 2589.211914,2113.974121 
	C2590.239014,2111.101318 2592.298828,2109.708984 2595.413086,2110.276123 
	C2596.387695,2110.453125 2597.315674,2110.875000 2598.284912,2111.097900 
	C2602.763916,2112.129150 2607.025146,2113.390625 2602.868408,2106.033691 
	C2601.283447,2103.229004 2604.325195,2102.121338 2606.852539,2102.081055 
	C2612.849854,2101.985352 2619.053223,2101.041260 2624.798340,2102.239502 
	C2632.953613,2103.940430 2641.177979,2101.675537 2649.212402,2103.767822 
	C2650.691650,2104.152832 2652.641602,2103.867188 2654.027100,2103.185303 
	C2655.728516,2102.347900 2655.964355,2100.614258 2654.485107,2099.116211 
	C2654.052979,2098.678711 2653.366943,2098.392090 2652.748779,2098.256348 
	C2649.980469,2097.648193 2646.026367,2098.341064 2646.382812,2093.820557 
	C2646.756104,2089.088135 2650.791748,2088.263672 2654.464600,2088.141602 
	C2663.306396,2087.847900 2672.275635,2087.110352 2680.809814,2091.218750 
	C2679.783447,2094.881348 2677.008789,2093.622314 2674.996094,2094.037842 
	C2673.447510,2094.357422 2671.308350,2094.027344 2671.240723,2096.316650 
	C2671.166016,2098.842773 2673.277832,2099.550537 2675.290771,2099.774170 
	C2677.598877,2100.031250 2679.945801,2099.937012 2682.275879,2100.000244 
	C2688.444824,2100.167725 2694.114014,2099.658691 2699.819092,2104.617188 
	C2703.734619,2108.020264 2710.939453,2108.023682 2717.017090,2108.097900 
	C2724.000244,2108.183594 2724.303223,2108.911377 2722.318848,2115.824219 
	C2721.676514,2118.061523 2720.905273,2120.261719 2720.091797,2123.229492 
	C2711.610107,2126.666016 2703.433594,2123.705078 2694.591064,2122.538086 
	C2694.011230,2120.503418 2694.012695,2119.016846 2694.021729,2116.786621 
	C2690.183594,2109.439697 2684.096680,2114.262207 2679.177734,2113.130127 
	C2679.108398,2113.449951 2678.950439,2113.782227 2678.993896,2114.085938 
	C2679.038330,2114.394043 2679.280762,2114.673584 2679.703613,2115.502441 
z"
        />
        <path
          fill="#6E8AA1"
          opacity="1.000000"
          stroke="none"
          d="
M1950.854980,2144.012207 
	C1948.235352,2143.950195 1946.416748,2144.137939 1944.426147,2142.491211 
	C1947.150146,2139.081543 1948.567627,2137.052246 1945.231323,2132.218994 
	C1940.968140,2126.042236 1936.057251,2122.860596 1929.038940,2121.784424 
	C1924.920532,2121.152832 1923.919556,2118.099854 1924.133789,2114.391357 
	C1924.378906,2110.144287 1927.437012,2109.854980 1930.533203,2110.072998 
	C1933.425659,2110.277100 1937.891357,2108.548828 1936.874512,2114.602051 
	C1936.182495,2118.720947 1939.080200,2119.784424 1942.323853,2119.911621 
	C1948.985718,2120.173340 1955.012451,2121.058105 1956.486572,2129.266357 
	C1956.908447,2131.615967 1959.203247,2131.904541 1961.255127,2131.941406 
	C1964.241455,2131.994873 1967.229248,2131.970215 1971.093994,2131.964355 
	C1978.243774,2126.456787 1986.822388,2125.727051 1993.843506,2120.723633 
	C1997.077148,2116.617188 2001.194214,2116.441650 2005.011108,2117.421143 
	C2021.581299,2121.673340 2038.443237,2119.945801 2055.203369,2120.354248 
	C2060.443604,2120.481934 2065.775146,2120.123779 2071.342041,2123.314453 
	C2073.526367,2128.060303 2077.829834,2127.684570 2081.551270,2129.231934 
	C2078.440186,2134.015869 2074.330322,2134.785400 2070.154541,2133.558350 
	C2059.600830,2130.457764 2049.147705,2128.361816 2038.224365,2131.802002 
	C2034.598267,2132.944092 2031.259888,2130.126953 2026.815430,2130.023438 
	C2023.638062,2130.040771 2021.306030,2129.903076 2018.562378,2128.431396 
	C2017.111206,2128.009521 2016.223633,2128.009277 2014.670532,2128.009277 
	C2011.264160,2130.784180 2009.359985,2134.494141 2004.649902,2135.896484 
	C1997.671875,2136.077881 1991.342651,2136.114014 1985.016724,2136.277344 
	C1984.019897,2136.303223 1983.078125,2137.280518 1983.322754,2138.259033 
	C1983.706665,2139.794434 1985.270752,2139.791504 1987.289795,2139.977539 
	C1988.757202,2140.772949 1989.049561,2141.693604 1988.436401,2143.380859 
	C1983.371948,2144.040527 1978.851196,2144.169434 1974.251953,2145.421387 
	C1966.715210,2147.472656 1959.195679,2145.068604 1950.854980,2144.012207 
z"
        />
        <path
          fill="#7B98B0"
          opacity="1.000000"
          stroke="none"
          d="
M2428.028809,2122.799561 
	C2432.690918,2120.682861 2437.396973,2119.531250 2443.160156,2119.993164 
	C2445.771240,2117.512695 2450.857666,2114.618652 2442.575684,2113.668457 
	C2435.175781,2113.447998 2428.193115,2115.313721 2420.758301,2112.323730 
	C2419.596924,2106.962891 2420.847900,2104.082520 2426.939697,2103.871338 
	C2442.223877,2103.342041 2457.418213,2104.546875 2472.483154,2106.428711 
	C2479.198242,2107.267334 2485.561035,2110.517090 2492.750488,2110.163330 
	C2497.738525,2109.918213 2501.797607,2113.609619 2501.226074,2116.563232 
	C2500.679688,2119.385742 2492.555664,2124.434326 2490.047852,2122.708740 
	C2483.505615,2118.206299 2477.076660,2121.227051 2470.851807,2122.425537 
	C2465.586426,2123.438721 2460.419434,2124.099609 2455.109863,2123.993164 
	C2450.745605,2123.905762 2447.122314,2124.503174 2443.440430,2127.917725 
	C2438.058594,2132.908936 2431.131592,2130.480957 2428.028809,2122.799561 
z"
        />
        <path
          fill="#6E8AA1"
          opacity="1.000000"
          stroke="none"
          d="
M2311.051514,2154.014648 
	C2311.555176,2158.696533 2308.537109,2160.172363 2304.373535,2159.963623 
	C2294.789795,2159.482910 2285.246094,2160.517822 2275.576416,2158.615723 
	C2267.316162,2156.990967 2258.547607,2157.178467 2250.144043,2158.995361 
	C2249.509521,2162.262207 2251.766602,2161.699951 2253.038574,2162.211426 
	C2256.725098,2163.693359 2256.125488,2166.124756 2254.210449,2168.495850 
	C2251.591553,2171.738281 2248.043457,2173.544922 2243.890625,2173.902832 
	C2239.986328,2174.240234 2235.926270,2173.441162 2232.750488,2176.947266 
	C2231.591064,2178.227539 2229.460693,2177.782715 2228.421631,2176.182129 
	C2225.395264,2171.519531 2221.905273,2168.862061 2215.991943,2171.494629 
	C2213.593018,2172.562744 2211.520752,2170.661621 2210.015869,2168.821533 
	C2208.354492,2166.790527 2205.877686,2164.308350 2207.229248,2161.842529 
	C2208.948486,2158.706055 2212.864014,2159.344971 2215.124268,2161.007568 
	C2220.111084,2164.675293 2224.275635,2162.573486 2229.582275,2160.266113 
	C2225.382568,2156.526367 2220.802979,2158.767578 2216.925537,2157.756836 
	C2214.956543,2157.243408 2212.839600,2156.568848 2213.287354,2153.989990 
	C2213.654297,2151.876709 2215.742188,2152.138672 2217.322998,2152.062012 
	C2219.647705,2151.949463 2221.980957,2152.009277 2225.160156,2151.990967 
	C2254.078857,2151.258789 2282.030518,2155.408447 2311.051514,2154.014648 
z"
        />
        <path
          fill="#7B98B0"
          opacity="1.000000"
          stroke="none"
          d="
M2572.580566,2140.678223 
	C2568.145020,2136.791992 2564.398926,2134.115479 2558.664795,2135.125488 
	C2554.480225,2135.862549 2551.842285,2132.977783 2552.066406,2128.447266 
	C2552.180420,2126.149170 2552.607422,2123.851318 2553.069824,2121.589600 
	C2553.661377,2118.696045 2552.202881,2117.183594 2549.746582,2116.356689 
	C2542.010498,2113.752441 2531.724854,2116.981445 2527.066650,2124.085205 
	C2524.503662,2127.993164 2521.733398,2128.679688 2517.866455,2126.849365 
	C2514.870605,2125.430908 2511.802490,2124.041260 2510.859375,2120.246094 
	C2512.250977,2116.493896 2516.447754,2119.143066 2518.503418,2116.975098 
	C2520.748047,2113.118164 2514.775391,2112.800781 2515.300537,2109.706055 
	C2515.813965,2109.256592 2516.402832,2108.421143 2516.715820,2108.514160 
	C2523.204590,2110.442139 2529.861084,2109.900879 2536.478027,2110.017578 
	C2541.434082,2110.104736 2546.430664,2109.480469 2551.276123,2111.379639 
	C2553.782471,2112.361816 2556.549561,2112.227783 2559.116699,2111.280029 
	C2568.752197,2107.722412 2575.937256,2110.343506 2580.638184,2119.577393 
	C2581.228271,2120.736084 2582.151123,2121.721924 2582.872559,2122.818848 
	C2583.040039,2123.073730 2583.013184,2123.455566 2583.164062,2124.234863 
	C2579.990479,2127.355713 2575.785889,2125.432617 2572.091309,2126.013428 
	C2570.253662,2126.302734 2567.516602,2125.511963 2567.293457,2128.222900 
	C2567.038818,2131.315430 2569.839844,2131.619873 2572.154053,2131.905762 
	C2573.467041,2132.067627 2574.836670,2132.154541 2576.137451,2131.968994 
	C2583.142822,2130.970703 2587.529541,2134.044189 2589.900879,2141.274414 
	C2584.216553,2146.219238 2578.697998,2143.488770 2572.580566,2140.678223 
z"
        />
        <path
          fill="#738EA6"
          opacity="1.000000"
          stroke="none"
          d="
M2664.423828,2163.649414 
	C2661.869141,2164.004150 2659.738281,2164.008301 2656.808594,2164.016113 
	C2650.795898,2163.858643 2646.337402,2166.593750 2640.741211,2167.904297 
	C2632.034912,2167.640625 2623.880615,2169.418213 2616.407715,2164.417969 
	C2614.348877,2163.040283 2610.677246,2164.073242 2606.872803,2164.000000 
	C2600.862549,2160.968994 2595.188965,2162.330811 2589.718750,2161.995361 
	C2585.928955,2161.762695 2581.751953,2163.045166 2578.395996,2158.664062 
	C2577.739990,2157.656494 2577.489990,2157.322510 2577.629883,2156.499268 
	C2582.742188,2155.201904 2588.409424,2156.122803 2589.975098,2148.745605 
	C2590.891113,2147.999268 2591.782227,2147.998535 2593.341553,2147.994141 
	C2598.511963,2143.602051 2603.547363,2145.506592 2609.259033,2147.384766 
	C2609.992676,2149.504883 2609.994873,2151.000000 2609.993652,2153.242676 
	C2609.990234,2153.990234 2609.476318,2154.732666 2609.183105,2154.988525 
	C2608.889648,2155.244385 2609.355713,2155.954834 2609.860840,2155.976562 
	C2613.887207,2156.276855 2617.538330,2155.302002 2621.452148,2157.543457 
	C2623.345459,2159.491943 2624.776611,2159.705078 2625.677490,2157.732910 
	C2626.602051,2155.709717 2627.137207,2153.508301 2627.926270,2150.697266 
	C2636.524170,2144.925537 2644.735596,2147.292236 2652.735352,2151.256592 
	C2655.625977,2152.688965 2657.909668,2155.188965 2661.344727,2157.450928 
	C2662.542480,2159.987793 2666.489258,2160.140381 2664.423828,2163.649414 
z"
        />
        <path
          fill="#738EA6"
          opacity="1.000000"
          stroke="none"
          d="
M2207.297852,2085.748047 
	C2203.289795,2085.262939 2200.071289,2084.537109 2196.812744,2084.136475 
	C2195.204590,2083.938477 2193.282227,2084.209229 2193.103760,2086.390625 
	C2192.989990,2087.782959 2194.105957,2088.698975 2195.343018,2089.345459 
	C2197.047607,2090.235840 2199.379639,2089.579346 2201.186523,2092.295166 
	C2197.354736,2094.888184 2193.072266,2097.682617 2188.916260,2094.907715 
	C2183.376953,2091.209961 2178.805420,2092.427979 2174.391357,2095.714355 
	C2168.855713,2099.836182 2163.753906,2097.601562 2158.480225,2095.636963 
	C2157.102295,2095.123291 2156.412109,2093.978027 2156.680420,2092.532959 
	C2156.953613,2091.062012 2158.113281,2090.341553 2159.517578,2090.227051 
	C2161.827881,2090.038574 2164.313721,2090.481934 2166.431885,2089.776855 
	C2171.059570,2088.236572 2176.970703,2089.291504 2178.917480,2082.179199 
	C2179.859375,2078.738770 2184.867920,2078.299561 2188.445557,2078.151123 
	C2195.729736,2077.848389 2203.034668,2078.007324 2210.330566,2078.049072 
	C2211.915283,2078.058105 2213.922119,2077.756348 2214.620361,2079.661377 
	C2215.541992,2082.177246 2213.374268,2083.083740 2211.705322,2084.042480 
	C2210.564209,2084.697998 2209.287109,2085.116943 2207.297852,2085.748047 
z"
        />
        <path
          fill="#738EA6"
          opacity="1.000000"
          stroke="none"
          d="
M2557.332275,2151.500488 
	C2559.534668,2154.908203 2565.756348,2153.526611 2564.694824,2157.949707 
	C2563.846191,2161.486816 2559.026367,2160.329834 2556.064941,2161.400879 
	C2554.133057,2162.099365 2551.616211,2161.181396 2549.268311,2160.979492 
	C2548.826660,2162.069336 2548.309082,2162.935547 2548.131836,2163.866699 
	C2547.714600,2166.060059 2548.327393,2168.928955 2545.244141,2169.485352 
	C2542.544434,2169.972412 2541.042725,2167.935547 2539.906738,2165.814209 
	C2538.122314,2162.481689 2535.606201,2162.609131 2532.312988,2163.455566 
	C2527.409180,2164.717041 2522.429688,2164.275391 2517.714600,2162.150635 
	C2516.546387,2161.624512 2515.016846,2161.410156 2515.065674,2159.665283 
	C2515.121094,2157.677002 2516.620117,2156.889893 2518.209229,2156.337891 
	C2528.566650,2152.738770 2539.170898,2150.909424 2551.088867,2151.985840 
	C2553.380371,2150.793457 2554.933105,2150.472656 2557.332275,2151.500488 
z"
        />
        <path
          fill="#738EA6"
          opacity="1.000000"
          stroke="none"
          d="
M2215.288818,2042.162354 
	C2218.936768,2042.000000 2221.882080,2042.144409 2224.808350,2041.969116 
	C2230.389404,2041.635010 2235.212402,2042.125854 2236.854004,2048.899170 
	C2237.449951,2051.358398 2240.068359,2051.888184 2243.236816,2051.981934 
	C2243.797119,2054.467529 2242.778809,2057.591797 2240.406738,2057.120850 
	C2235.296387,2056.106445 2230.655762,2058.265381 2225.712158,2058.106934 
	C2219.600586,2057.910400 2216.058350,2053.872070 2212.781982,2049.958008 
	C2211.107910,2047.958252 2211.010498,2044.182007 2215.288818,2042.162354 
z"
        />
        <path
          fill="#738EA6"
          opacity="1.000000"
          stroke="none"
          d="
M2290.205078,2111.897461 
	C2295.367920,2111.792480 2299.313232,2109.384766 2303.757080,2109.993896 
	C2305.999023,2110.301025 2308.935791,2109.706543 2309.345215,2112.775391 
	C2309.770508,2115.963379 2308.208496,2118.516846 2304.957031,2119.678467 
	C2304.648682,2119.788574 2304.307373,2119.860107 2303.981445,2119.859863 
	C2293.705811,2119.851318 2283.426758,2119.968018 2273.156250,2119.732666 
	C2269.956299,2119.659424 2267.942871,2117.356445 2266.403076,2113.557617 
	C2274.234863,2111.063232 2281.479004,2108.539307 2290.205078,2111.897461 
z"
        />
        <path
          fill="#7392AD"
          opacity="1.000000"
          stroke="none"
          d="
M2120.899902,2031.993408 
	C2121.227783,2026.980835 2124.443115,2026.000244 2129.038574,2027.344360 
	C2134.970947,2029.079590 2140.104492,2027.914673 2144.192627,2022.923950 
	C2145.202881,2021.690918 2146.719727,2020.776855 2148.159668,2020.008911 
	C2152.698486,2017.588623 2154.933838,2018.300171 2155.730713,2023.608154 
	C2156.395508,2028.038452 2157.704346,2030.424683 2163.203613,2029.993774 
	C2163.559814,2032.717896 2161.904785,2033.964966 2159.211670,2033.947266 
	C2153.932129,2033.912354 2148.436768,2034.794189 2143.424072,2033.618652 
	C2136.202881,2031.925171 2129.044189,2032.112183 2120.899902,2031.993408 
z"
        />
        <path
          fill="#7B98B0"
          opacity="1.000000"
          stroke="none"
          d="
M2661.892822,2157.222656 
	C2659.058838,2157.899658 2655.466797,2158.792969 2653.570801,2156.180908 
	C2648.102539,2148.648438 2640.280518,2150.095215 2632.783691,2150.002441 
	C2631.791748,2149.990234 2630.799316,2150.003418 2628.908447,2150.007324 
	C2624.081543,2147.724365 2623.172607,2145.434082 2627.985596,2143.111572 
	C2630.531982,2141.882568 2633.309814,2141.416260 2635.563477,2143.165283 
	C2639.318604,2146.079590 2643.515625,2146.013184 2647.856445,2146.023926 
	C2658.595215,2146.050293 2658.595215,2146.098877 2661.892822,2157.222656 
z"
        />
        <path
          fill="#738EA6"
          opacity="1.000000"
          stroke="none"
          d="
M2693.259766,2121.997070 
	C2702.365234,2119.680176 2710.430420,2121.347168 2719.215088,2123.877441 
	C2724.185791,2124.174316 2728.472900,2123.541260 2732.467041,2124.657715 
	C2732.564941,2127.719971 2730.824951,2127.912354 2729.197998,2127.920166 
	C2717.304199,2127.976074 2705.410645,2127.967773 2693.516846,2127.971436 
	C2692.857910,2127.971680 2692.192139,2127.943115 2691.541260,2127.847900 
	C2689.518799,2127.552979 2687.178223,2126.953613 2687.223389,2124.656250 
	C2687.292236,2121.150879 2690.463135,2122.387207 2693.259766,2121.997070 
z"
        />
        <path
          fill="#738EA6"
          opacity="1.000000"
          stroke="none"
          d="
M2173.114746,2045.481445 
	C2175.965088,2048.499023 2179.099609,2047.806519 2182.013916,2048.076660 
	C2185.077148,2048.360596 2187.817627,2049.492432 2190.029785,2051.617676 
	C2191.517578,2053.047363 2191.018311,2055.724121 2189.504883,2055.386475 
	C2180.151367,2053.299072 2169.960449,2055.836426 2160.090332,2051.079346 
	C2162.701904,2047.549805 2165.416992,2045.883545 2167.068115,2043.022217 
	C2169.838867,2038.220459 2171.492676,2041.955933 2173.114746,2045.481445 
z"
        />
        <path
          fill="#738EA6"
          opacity="1.000000"
          stroke="none"
          d="
M2842.439453,2139.366699 
	C2835.827881,2139.578125 2829.516602,2141.133789 2822.745605,2138.337402 
	C2822.329834,2134.965088 2821.003662,2132.271973 2820.098633,2128.745117 
	C2824.090088,2128.015869 2828.170166,2128.012207 2833.125244,2128.014160 
	C2836.087402,2130.650146 2838.223389,2133.232178 2841.529785,2135.548340 
	C2841.990234,2135.990234 2842.000000,2136.000000 2842.004883,2136.004883 
	C2842.760498,2136.778076 2843.050537,2137.692139 2842.439453,2139.366699 
z"
        />
        <path
          fill="#738EA6"
          opacity="1.000000"
          stroke="none"
          d="
M2680.850098,2116.027588 
	C2676.622314,2115.955322 2673.365967,2115.649658 2671.288086,2112.536621 
	C2671.690186,2109.512207 2674.052734,2110.092773 2675.843018,2110.063477 
	C2682.113525,2109.960693 2688.387695,2109.964355 2694.658691,2110.056885 
	C2696.509521,2110.083984 2698.985352,2109.842041 2698.852783,2112.778564 
	C2698.767822,2114.661865 2697.062012,2115.356201 2694.727539,2115.937744 
	C2689.929199,2116.034424 2685.829102,2116.025146 2680.850098,2116.027588 
z"
        />
        <path
          fill="#7B98B0"
          opacity="1.000000"
          stroke="none"
          d="
M2558.610107,2151.679443 
	C2556.502197,2151.996826 2555.004639,2151.993652 2552.758301,2151.985352 
	C2551.498047,2150.803711 2551.284668,2149.303955 2550.422119,2148.507324 
	C2547.068848,2145.410645 2548.162842,2142.747803 2550.955811,2140.213867 
	C2553.929688,2137.515869 2557.947998,2137.876709 2561.562500,2141.020264 
	C2564.304688,2143.405273 2564.423340,2146.014160 2562.105469,2148.712891 
	C2561.261230,2149.695801 2560.188477,2150.482422 2558.610107,2151.679443 
z"
        />
        <path
          fill="#7B98B0"
          opacity="1.000000"
          stroke="none"
          d="
M2610.610840,2147.885742 
	C2605.254639,2148.005371 2600.519287,2148.000977 2594.896973,2147.993408 
	C2594.008301,2147.001465 2593.933594,2146.006348 2594.016602,2145.024658 
	C2594.627441,2137.796143 2595.364014,2137.189209 2601.390381,2141.013672 
	C2604.624268,2143.065918 2607.955322,2143.635010 2611.449707,2144.492676 
	C2613.743652,2145.056396 2613.140625,2146.760498 2610.610840,2147.885742 
z"
        />
        <path
          fill="#7B98B0"
          opacity="1.000000"
          stroke="none"
          d="
M2669.341553,2137.992676 
	C2664.508301,2138.333008 2660.701904,2137.464355 2657.003906,2135.917725 
	C2655.324951,2135.215332 2654.083984,2134.192871 2654.725342,2132.245605 
	C2655.218750,2130.748535 2656.604736,2130.568848 2657.939941,2131.071777 
	C2662.291016,2132.710449 2667.217285,2131.196289 2671.423828,2133.572266 
	C2672.701660,2134.294189 2675.177734,2134.133057 2674.411865,2136.489990 
	C2673.809082,2138.344727 2671.701172,2137.810303 2669.341553,2137.992676 
z"
        />
        <path
          fill="#728DA3"
          opacity="1.000000"
          stroke="none"
          d="
M1840.695801,2031.900635 
	C1840.586304,2026.589478 1844.580566,2022.556641 1845.913818,2016.711670 
	C1849.177979,2018.295166 1853.446167,2017.472900 1857.149170,2019.934326 
	C1849.348022,2020.965942 1847.799927,2029.450684 1840.695801,2031.900635 
z"
        />
        <path
          fill="#738EA6"
          opacity="1.000000"
          stroke="none"
          d="
M2571.499512,2140.489258 
	C2577.614258,2139.292480 2582.707031,2142.793945 2589.164551,2142.004883 
	C2590.000000,2143.507324 2590.000000,2145.004639 2590.000000,2147.250977 
	C2584.489014,2147.696777 2579.269531,2145.518555 2573.625732,2145.735840 
	C2570.754395,2145.846680 2569.735596,2143.767334 2571.499512,2140.489258 
z"
        />
        <path
          fill="#738EA6"
          opacity="1.000000"
          stroke="none"
          d="
M2738.461914,2106.592285 
	C2737.683350,2101.137695 2740.285645,2099.321045 2744.667725,2100.195312 
	C2748.708740,2101.001953 2752.574219,2102.873047 2757.520264,2099.492188 
	C2756.013672,2107.911377 2750.039062,2104.736816 2746.501709,2107.484375 
	C2743.447754,2109.428711 2741.142334,2108.692139 2738.461914,2106.592285 
z"
        />
        <path
          fill="#738EA6"
          opacity="1.000000"
          stroke="none"
          d="
M2549.527832,2177.945801 
	C2556.537354,2175.054199 2563.476318,2175.667725 2570.432617,2177.840576 
	C2563.581299,2180.656982 2556.601318,2180.729736 2549.527832,2177.945801 
z"
        />
        <path
          fill="#7B98B0"
          opacity="1.000000"
          stroke="none"
          d="
M2737.392578,2106.271973 
	C2740.356445,2105.936768 2742.652832,2106.162109 2745.362305,2107.717285 
	C2745.916016,2110.708984 2745.834229,2113.528564 2742.225830,2113.827637 
	C2739.312744,2114.069092 2735.955811,2114.786621 2733.863037,2112.197266 
	C2731.031006,2108.693115 2734.929688,2108.005371 2737.392578,2106.271973 
z"
        />
        <path
          fill="#7B98B0"
          opacity="1.000000"
          stroke="none"
          d="
M2410.793945,2097.953613 
	C2407.878662,2097.613037 2406.005615,2096.738037 2407.395508,2094.226807 
	C2408.639648,2091.979492 2410.471191,2089.619385 2413.601807,2091.186035 
	C2415.572021,2092.172119 2417.242432,2093.757568 2419.226074,2095.204590 
	C2417.377441,2099.304932 2414.033936,2097.441895 2410.793945,2097.953613 
z"
        />
        <path
          fill="#738EA6"
          opacity="1.000000"
          stroke="none"
          d="
M2229.277832,2079.918457 
	C2228.381348,2082.553467 2227.019775,2084.063965 2224.740723,2082.968750 
	C2222.311523,2081.802002 2220.366455,2079.949463 2220.394531,2076.939697 
	C2220.409424,2075.332764 2221.636230,2074.503662 2223.028076,2074.885254 
	C2225.561279,2075.579346 2228.056641,2076.550537 2229.277832,2079.918457 
z"
        />
        <path
          fill="#7B98B0"
          opacity="1.000000"
          stroke="none"
          d="
M2603.724854,2154.586914 
	C2605.497803,2153.998535 2606.995361,2153.997070 2609.241699,2153.992920 
	C2613.890137,2154.756592 2618.546631,2152.046875 2621.819092,2157.263184 
	C2617.261963,2157.995605 2612.523682,2157.991211 2606.897949,2157.983643 
	C2604.999023,2157.188965 2603.885498,2156.490479 2603.724854,2154.586914 
z"
        />
        <path
          fill="#738EA6"
          opacity="1.000000"
          stroke="none"
          d="
M2168.876465,2080.058594 
	C2168.151611,2081.087891 2167.612549,2081.637207 2167.033447,2081.682617 
	C2164.199219,2081.904297 2161.101562,2082.967529 2158.949951,2080.130859 
	C2158.536377,2079.585693 2158.682861,2077.848389 2159.162842,2077.516602 
	C2162.501709,2075.207031 2165.769531,2075.291016 2168.632080,2078.463135 
	C2168.840088,2078.693604 2168.909424,2079.049072 2168.876465,2080.058594 
z"
        />
        <path
          fill="#728DA3"
          opacity="1.000000"
          stroke="none"
          d="
M1854.926025,2053.058105 
	C1851.269409,2049.612549 1852.464233,2047.319946 1856.244019,2046.881714 
	C1858.505615,2046.619263 1861.250488,2047.457275 1863.135010,2050.151611 
	C1861.100830,2052.558594 1858.279419,2052.860107 1854.926025,2053.058105 
z"
        />
        <path
          fill="#7B98B0"
          opacity="1.000000"
          stroke="none"
          d="
M2577.925537,2155.326660 
	C2578.019531,2156.009766 2578.005127,2157.000000 2577.997559,2157.495117 
	C2577.024414,2157.982666 2576.055908,2158.018555 2575.093262,2157.958740 
	C2572.783203,2157.814941 2570.380127,2157.686279 2570.456299,2154.452148 
	C2570.498291,2152.666016 2571.562500,2151.239502 2573.191406,2150.897217 
	C2576.012451,2150.303711 2577.168701,2152.287354 2577.925537,2155.326660 
z"
        />
        <path
          fill="#7B98B0"
          opacity="1.000000"
          stroke="none"
          d="
M2698.133301,2082.400391 
	C2700.546631,2082.677979 2701.932373,2083.583496 2700.308838,2085.339600 
	C2699.137939,2086.605469 2697.058350,2088.359619 2695.488037,2086.652588 
	C2693.597412,2084.597168 2696.013184,2083.524902 2698.133301,2082.400391 
z"
        />
        <path
          fill="#738EA6"
          opacity="1.000000"
          stroke="none"
          d="
M2578.794922,2182.348633 
	C2580.718506,2182.159180 2582.459717,2182.175293 2582.652344,2183.546875 
	C2582.989014,2185.947021 2580.831543,2185.908691 2579.327148,2185.679688 
	C2577.447266,2185.393555 2576.364014,2184.360352 2578.794922,2182.348633 
z"
        />
        <path
          fill="#7B98B0"
          opacity="1.000000"
          stroke="none"
          d="
M2711.723389,2092.901367 
	C2713.803955,2092.101807 2715.557373,2091.753418 2716.623535,2093.360107 
	C2717.193604,2094.219727 2716.454102,2095.310059 2715.437988,2095.510010 
	C2713.552979,2095.881592 2711.410156,2096.391846 2711.723389,2092.901367 
z"
        />
        <path
          fill="#738EA6"
          opacity="1.000000"
          stroke="none"
          d="
M2203.999023,2038.917847 
	C2206.125244,2040.980225 2206.050537,2042.662720 2203.493896,2043.435913 
	C2202.827148,2043.637451 2201.319824,2043.006470 2201.115234,2042.431885 
	C2200.436768,2040.522705 2201.744141,2039.479858 2203.999023,2038.917847 
z"
        />
        <path
          fill="#7B98B0"
          opacity="1.000000"
          stroke="none"
          d="
M2677.004395,2144.511719 
	C2676.273193,2142.815674 2676.326660,2141.601807 2677.793213,2141.142090 
	C2678.194092,2141.016357 2679.149170,2141.479492 2679.276855,2141.863281 
	C2679.822021,2143.503174 2679.721436,2144.944336 2677.004395,2144.511719 
z"
        />
        <path
          fill="#7392AD"
          opacity="1.000000"
          stroke="none"
          d="
M2180.425293,2030.485107 
	C2182.256104,2027.636719 2184.252930,2027.395142 2185.901367,2031.357300 
	C2184.236084,2031.950195 2182.417969,2032.138306 2180.425293,2030.485107 
z"
        />
        <path
          fill="#7793AA"
          opacity="1.000000"
          stroke="none"
          d="
M2841.977051,2135.242188 
	C2840.688965,2135.989746 2839.380127,2135.895996 2838.088135,2136.004883 
	C2832.527588,2136.471680 2832.158691,2133.628662 2833.914795,2128.728271 
	C2838.566162,2127.826416 2841.876709,2129.178223 2841.977051,2135.242188 
z"
        />
        <path
          fill="#7995AC"
          opacity="1.000000"
          stroke="none"
          d="
M2841.559570,2117.511719 
	C2839.627441,2117.388428 2838.620117,2116.745850 2839.340332,2115.227539 
	C2839.783691,2114.292725 2840.916748,2114.147705 2841.797119,2114.584229 
	C2843.191406,2115.275635 2843.354004,2116.296875 2841.559570,2117.511719 
z"
        />
        <path
          fill="#7793AA"
          opacity="1.000000"
          stroke="none"
          d="
M2842.577637,2139.896973 
	C2842.000732,2139.098145 2842.001465,2138.215820 2842.005859,2136.671631 
	C2843.287354,2136.203857 2844.741211,2136.310059 2844.958740,2137.918213 
	C2845.110596,2139.039307 2844.139648,2139.537842 2842.577637,2139.896973 
z"
        />
        <path
          fill="#6E8CA6"
          opacity="1.000000"
          stroke="none"
          d="
M2040.210083,1957.201538 
	C2039.282715,1959.798462 2038.239258,1961.469482 2038.259521,1963.600952 
	C2038.325317,1970.551636 2036.596191,1973.304688 2030.231079,1971.678467 
	C2017.178467,1968.343994 2004.565674,1973.625122 1991.756470,1972.033569 
	C1987.884766,1971.552612 1984.712280,1972.488892 1982.651611,1976.426270 
	C1980.111084,1981.281128 1976.225952,1980.974976 1973.015991,1977.346313 
	C1970.400146,1974.389282 1967.792114,1973.490601 1964.281860,1974.892700 
	C1960.109131,1976.559937 1955.611694,1976.596924 1951.486206,1975.862061 
	C1933.254517,1972.614136 1914.730103,1975.873169 1896.414673,1972.941162 
	C1891.344727,1972.129395 1885.425293,1976.050537 1880.038208,1978.163818 
	C1878.554199,1978.745972 1877.249634,1979.847900 1874.775879,1979.967041 
	C1867.600952,1976.691528 1867.811646,1971.173340 1869.237793,1964.556152 
	C1870.010864,1961.431885 1870.951538,1959.177734 1872.671875,1956.517822 
	C1875.773315,1954.023804 1878.879639,1955.308716 1881.721069,1954.757568 
	C1884.683716,1954.182861 1887.238281,1953.254028 1883.373657,1949.363525 
	C1881.507935,1947.522217 1880.195801,1946.109375 1878.694824,1943.866943 
	C1878.303955,1931.844116 1879.902710,1930.669556 1895.637207,1931.112549 
	C1896.880737,1931.262451 1897.369751,1931.327637 1898.569092,1931.531982 
	C1902.304443,1932.573730 1905.302734,1933.774658 1909.167725,1932.329834 
	C1911.112671,1931.908813 1912.431519,1931.819214 1914.386230,1931.613647 
	C1920.021729,1931.020142 1918.782959,1926.329834 1920.787476,1922.880493 
	C1926.410034,1917.349854 1932.972412,1918.459106 1939.094604,1918.617554 
	C1949.915405,1918.897705 1960.680542,1920.144531 1971.551392,1918.699829 
	C1976.404663,1918.054810 1981.518433,1918.549438 1986.029419,1922.885742 
	C1988.141113,1929.781616 1992.588867,1933.366699 1998.585449,1936.337158 
	C1999.009277,1937.277466 1999.103027,1937.672729 1999.179688,1938.651489 
	C1999.004395,1939.978271 1998.884644,1940.727661 1998.933838,1942.035034 
	C1999.650879,1943.074707 2000.255005,1943.552979 2001.334106,1944.381714 
	C2003.622314,1947.722778 2004.369263,1944.145020 2006.335693,1943.846924 
	C2013.056519,1942.716431 2019.143921,1942.477539 2026.007568,1943.620605 
	C2032.311279,1946.947021 2037.691528,1950.249023 2040.210083,1957.201538 
z"
        />
        <path
          fill="#69849B"
          opacity="1.000000"
          stroke="none"
          d="
M2366.719727,1929.246826 
	C2372.050049,1926.719971 2377.055908,1925.388794 2383.289551,1925.964111 
	C2389.574219,1923.406982 2395.220703,1922.042725 2402.101318,1922.732788 
	C2410.612061,1921.947754 2418.286377,1919.175171 2426.576172,1923.379395 
	C2432.018555,1926.991699 2435.277832,1934.561401 2443.603760,1928.853027 
	C2451.324219,1924.900635 2459.230713,1924.109741 2467.513672,1922.236938 
	C2470.526123,1922.841431 2471.840332,1924.678711 2473.878906,1926.538574 
	C2474.774658,1926.933960 2475.150146,1927.039673 2476.089355,1927.276001 
	C2477.406982,1927.623779 2478.154541,1927.862183 2479.468262,1928.268555 
	C2480.829834,1928.502319 2481.583008,1928.365234 2482.821533,1927.676514 
	C2483.703369,1927.062500 2484.072021,1926.825195 2485.057617,1926.322266 
	C2487.966309,1925.393066 2490.239990,1925.452393 2493.120850,1926.541870 
	C2494.472168,1927.454956 2495.079834,1928.153442 2495.905762,1929.552979 
	C2499.372803,1934.871216 2502.556152,1933.597778 2505.379150,1929.983032 
	C2507.732910,1926.969116 2510.696777,1925.501221 2515.130859,1925.096436 
	C2526.035645,1924.193359 2536.153076,1924.809814 2547.044434,1925.632080 
	C2551.006836,1924.951050 2554.179199,1924.953857 2558.080322,1925.763916 
	C2563.562988,1928.746216 2568.681396,1928.978027 2574.680176,1928.161133 
	C2576.376221,1928.269897 2577.332275,1928.442017 2579.007812,1928.736328 
	C2582.205566,1928.745850 2584.669678,1928.905884 2587.878418,1928.956909 
	C2593.447021,1929.313477 2598.856201,1928.124023 2601.299561,1934.272217 
	C2602.468994,1937.215454 2605.098389,1936.221191 2607.308594,1936.257202 
	C2610.673340,1936.312012 2613.198242,1937.570557 2613.588135,1941.218384 
	C2614.011230,1945.178711 2611.844238,1947.569092 2608.159912,1948.313843 
	C2602.631348,1949.431274 2597.031738,1949.910400 2590.765137,1948.327881 
	C2572.578857,1952.284912 2555.146729,1946.984863 2537.641113,1947.126831 
	C2533.355713,1947.161621 2529.210938,1948.060913 2525.005615,1947.905884 
	C2513.731445,1947.490479 2502.367676,1949.406982 2491.185303,1946.703369 
	C2489.132568,1946.207153 2487.245850,1946.512451 2485.334229,1947.213501 
	C2482.437744,1948.275635 2479.272217,1948.510376 2476.448486,1947.853149 
	C2465.931885,1945.405029 2455.084229,1947.382935 2444.713623,1945.639648 
	C2430.402100,1943.233643 2416.162354,1944.639404 2400.973633,1944.047363 
	C2395.652832,1948.402710 2390.553467,1946.194458 2384.811035,1945.797485 
	C2375.155273,1946.436890 2366.399170,1945.821045 2356.879395,1944.042847 
	C2355.238037,1944.778931 2354.307129,1945.062866 2352.608398,1944.461426 
	C2351.105469,1944.033813 2350.232178,1944.038696 2348.703857,1944.051514 
	C2335.106689,1950.801758 2321.676270,1948.328003 2307.499512,1945.781616 
	C2305.815430,1944.734375 2305.201416,1943.863037 2304.926514,1941.991211 
	C2311.950928,1937.855957 2310.472168,1931.692993 2310.173340,1925.171387 
	C2311.345215,1922.312622 2312.776367,1920.414917 2314.507812,1917.857910 
	C2315.998291,1916.225830 2317.245850,1915.522095 2319.421387,1915.178467 
	C2323.604248,1916.696411 2327.451904,1916.886841 2331.524902,1918.662354 
	C2334.606445,1921.681274 2335.723877,1927.421387 2341.978516,1922.894775 
	C2346.079346,1921.871460 2349.310547,1922.756348 2353.108398,1924.334717 
	C2357.300537,1927.852173 2358.702148,1936.441040 2366.719727,1929.246826 
z"
        />
        <path
          fill="#6E8CA6"
          opacity="1.000000"
          stroke="none"
          d="
M2270.750977,1949.730957 
	C2263.154297,1950.634888 2256.644287,1952.967041 2249.363770,1951.129395 
	C2244.054688,1949.789551 2240.026611,1955.437988 2235.751709,1958.036011 
	C2230.743164,1961.079712 2226.677246,1962.765869 2221.056152,1960.756958 
	C2215.440186,1958.749878 2210.116943,1962.319702 2204.397949,1962.168213 
	C2186.869629,1961.704102 2169.318115,1961.806641 2151.781250,1962.057861 
	C2126.955322,1962.413330 2102.132812,1961.713501 2077.311523,1962.059082 
	C2071.273682,1962.143066 2065.398926,1961.591553 2058.744385,1959.975830 
	C2053.507080,1954.633667 2061.042480,1948.594727 2056.601074,1942.618286 
	C2055.948730,1940.525024 2055.867920,1939.073242 2056.146973,1936.867920 
	C2056.736084,1934.682861 2057.476562,1933.392334 2058.940430,1931.691650 
	C2062.518555,1928.625610 2067.062256,1930.032837 2070.587402,1926.739746 
	C2072.880127,1925.118408 2074.731934,1924.363892 2077.606934,1924.486328 
	C2079.358398,1924.994141 2080.192871,1925.583252 2081.251465,1927.051758 
	C2082.679688,1930.709961 2081.916016,1933.565552 2080.908203,1937.066040 
	C2086.411377,1944.323120 2093.832275,1940.035767 2100.348389,1940.818481 
	C2104.576172,1941.326416 2109.721191,1941.965576 2112.407959,1935.760742 
	C2118.193115,1928.939819 2125.400879,1928.340210 2132.686523,1929.485596 
	C2139.435791,1930.546265 2144.985840,1929.136719 2150.322266,1923.857422 
	C2151.802246,1922.962158 2152.733154,1922.675537 2154.433594,1922.625000 
	C2157.773926,1923.415161 2159.965332,1925.338135 2163.460449,1925.683838 
	C2164.529053,1926.052490 2164.944580,1926.232666 2165.928467,1926.784180 
	C2168.190186,1928.588989 2169.009766,1930.875977 2171.696289,1932.282715 
	C2177.182373,1938.067383 2183.042236,1937.771240 2190.020264,1935.297485 
	C2209.898438,1933.646729 2229.018799,1934.686646 2248.124023,1934.479614 
	C2256.368896,1934.390381 2264.590576,1934.138184 2273.675293,1933.493408 
	C2275.108643,1933.689819 2275.667725,1933.821411 2277.001953,1934.303223 
	C2283.088867,1942.820312 2283.088867,1942.820312 2270.750977,1949.730957 
z"
        />
        <path
          fill="#6E8CA6"
          opacity="1.000000"
          stroke="none"
          d="
M2433.988281,1973.230957 
	C2422.027588,1973.990112 2409.994629,1973.312866 2398.078613,1974.202515 
	C2387.376709,1975.001587 2376.609619,1975.085083 2365.882324,1976.203003 
	C2357.561523,1977.070190 2348.437988,1979.870361 2339.614990,1976.458496 
	C2336.978760,1975.438965 2333.690186,1976.017212 2330.700439,1976.013794 
	C2302.409424,1975.980469 2274.118408,1975.979736 2244.884033,1975.938721 
	C2245.514404,1968.357300 2252.021973,1968.024048 2257.245850,1968.564941 
	C2262.095459,1969.067139 2267.043213,1969.075928 2271.902588,1969.625854 
	C2279.161133,1970.447388 2286.753418,1973.414185 2294.131592,1968.588135 
	C2296.283691,1967.180542 2300.000488,1968.313843 2302.964355,1967.973022 
	C2314.498779,1966.646240 2325.899658,1969.647949 2337.573730,1968.313965 
	C2349.083740,1966.998535 2360.865479,1968.217651 2372.518555,1967.936279 
	C2380.419434,1967.745605 2388.067139,1970.056763 2396.089111,1969.264404 
	C2403.488770,1968.533813 2411.043457,1966.978760 2418.592529,1969.649048 
	C2420.284424,1970.247681 2422.876465,1968.277588 2425.061279,1967.510742 
	C2430.908447,1965.458374 2431.005127,1965.515869 2433.988281,1973.230957 
z"
        />
        <path
          fill="#6E8CA6"
          opacity="1.000000"
          stroke="none"
          d="
M2305.461914,1944.168335 
	C2319.499023,1943.905518 2332.868652,1945.232300 2347.143555,1944.038818 
	C2349.716553,1951.997681 2350.895264,1954.281250 2356.642822,1953.682251 
	C2363.526611,1952.964722 2369.018555,1957.855103 2375.666260,1956.891113 
	C2376.820801,1956.723633 2377.358398,1957.748291 2377.446777,1958.799805 
	C2377.533936,1959.835693 2377.164551,1960.741211 2376.179199,1961.253540 
	C2374.311523,1962.224365 2372.341064,1961.880737 2370.363770,1961.736328 
	C2362.977051,1961.196411 2355.663330,1960.258423 2348.176514,1961.730347 
	C2344.078125,1962.536255 2339.541260,1962.111938 2335.368408,1961.341553 
	C2325.178955,1959.460938 2315.119629,1959.330688 2304.924316,1961.291626 
	C2300.269531,1962.186890 2294.846191,1964.038940 2292.110352,1956.707031 
	C2296.238281,1952.049561 2301.733887,1949.527344 2305.461914,1944.168335 
z"
        />
        <path
          fill="#8C8D89"
          opacity="1.000000"
          stroke="none"
          d="
M2057.358887,1941.876831 
	C2062.183838,1944.091675 2062.778320,1947.406494 2061.023926,1951.233887 
	C2059.934082,1953.611572 2057.859619,1955.548462 2057.932129,1959.185303 
	C2052.406006,1959.905640 2046.846558,1960.559204 2040.812500,1958.081787 
	C2037.084839,1953.237793 2030.959717,1951.842529 2028.030151,1946.162109 
	C2023.839966,1938.994751 2021.221680,1932.107300 2022.157227,1923.611206 
	C2022.786987,1921.021362 2026.000854,1921.031128 2025.426758,1919.043701 
	C2025.226318,1918.349365 2022.506958,1918.628784 2020.291626,1917.988281 
	C2018.723145,1917.269775 2017.974121,1916.629517 2017.006592,1915.161133 
	C2016.472900,1913.424805 2016.525146,1912.401367 2017.317627,1910.773560 
	C2026.705933,1910.249268 2035.611938,1910.342163 2045.451782,1910.638672 
	C2048.412354,1912.888306 2048.471924,1915.386597 2047.822510,1918.704468 
	C2046.551880,1921.811890 2044.474609,1923.589722 2043.412109,1925.947510 
	C2041.755127,1929.624634 2037.298584,1933.268066 2040.196167,1937.335327 
	C2042.699707,1940.849854 2047.517822,1938.124756 2051.967041,1938.435303 
	C2054.523438,1938.852051 2055.616943,1940.268066 2057.358887,1941.876831 
z"
        />
        <path
          fill="#8DA0AE"
          opacity="1.000000"
          stroke="none"
          d="
M1871.410889,1963.517822 
	C1869.926758,1969.169556 1870.981934,1974.007324 1873.726196,1979.351318 
	C1870.248657,1984.468506 1873.948486,1989.908325 1872.050049,1995.310059 
	C1871.732178,1996.599487 1871.349609,1997.109863 1870.411987,1997.760742 
	C1864.314331,1998.602295 1859.141602,1995.466187 1853.482178,1995.764282 
	C1852.096313,1995.837402 1850.362671,1994.687744 1850.695068,1993.573853 
	C1852.136841,1988.739624 1848.842896,1986.023193 1846.800049,1982.464233 
	C1844.690796,1978.789795 1846.989380,1975.552490 1850.207886,1973.231812 
	C1851.907837,1972.006104 1853.938110,1972.136108 1855.889893,1971.914062 
	C1857.166138,1971.768921 1858.638550,1971.649170 1858.889648,1969.985596 
	C1859.198608,1967.939819 1857.693115,1967.063477 1856.146118,1966.483032 
	C1854.082031,1965.708374 1851.409424,1966.242554 1850.070557,1962.930176 
	C1857.084106,1956.190918 1863.838257,1962.516113 1871.410889,1963.517822 
z"
        />
        <path
          fill="#8C8D89"
          opacity="1.000000"
          stroke="none"
          d="
M2590.213623,1948.750977 
	C2594.310547,1945.406494 2598.997559,1946.151001 2603.560547,1945.989624 
	C2605.521484,1945.920288 2607.575439,1946.015015 2609.409912,1945.454956 
	C2611.640869,1944.773315 2612.039551,1942.730347 2611.515625,1940.628418 
	C2611.022461,1938.648315 2609.449951,1938.227051 2607.702148,1938.508545 
	C2606.752930,1938.661621 2605.877441,1939.228027 2604.943848,1939.531006 
	C2602.312744,1940.384521 2599.580566,1943.427612 2596.949951,1940.358154 
	C2594.851318,1937.909790 2598.852295,1935.948975 2599.044189,1933.688232 
	C2595.729736,1930.959839 2592.244385,1932.475586 2588.340332,1931.600098 
	C2586.399170,1928.701050 2586.231689,1925.969971 2588.504150,1922.909790 
	C2591.350098,1921.410400 2593.702637,1921.405029 2596.766602,1922.123047 
	C2599.896484,1923.443359 2602.178223,1924.917480 2605.265869,1926.256592 
	C2607.342529,1927.454590 2608.559814,1928.683716 2610.312744,1930.242065 
	C2612.232910,1932.134399 2613.846436,1933.343628 2616.572266,1933.908081 
	C2622.612549,1937.037842 2625.089844,1932.835815 2628.716064,1929.635742 
	C2633.777100,1932.831543 2633.185059,1937.542480 2633.060059,1942.776367 
	C2630.596436,1947.045044 2626.280273,1947.086548 2622.559082,1949.554443 
	C2619.785400,1951.786987 2618.033447,1954.139160 2614.583008,1954.001831 
	C2608.742920,1953.769287 2602.887451,1953.943237 2597.038330,1953.917847 
	C2593.869629,1953.904175 2591.268311,1952.964844 2590.213623,1948.750977 
z"
        />
        <path
          fill="#9DAAB4"
          opacity="1.000000"
          stroke="none"
          d="
M2306.081543,1943.424316 
	C2304.035645,1950.232910 2301.172607,1955.631714 2292.734619,1955.914062 
	C2289.048584,1955.906738 2286.053467,1956.190186 2282.710693,1954.221191 
	C2278.375244,1953.487793 2274.608398,1954.102173 2270.536621,1952.346436 
	C2270.048828,1951.847046 2270.051514,1950.915405 2270.020996,1950.450439 
	C2270.189453,1946.608765 2271.849365,1944.398682 2275.299561,1944.143677 
	C2281.052002,1943.718384 2278.716797,1940.075562 2278.319336,1936.423462 
	C2279.215576,1935.242432 2280.109619,1935.051514 2281.703125,1935.470337 
	C2289.207764,1941.583008 2296.523926,1944.701294 2305.719238,1940.016846 
	C2306.163086,1941.263672 2306.098389,1942.049316 2306.081543,1943.424316 
z"
        />
        <path
          fill="#6E8CA6"
          opacity="1.000000"
          stroke="none"
          d="
M2269.936768,1952.594482 
	C2273.930176,1952.265869 2278.150635,1947.040527 2281.883057,1953.235229 
	C2282.225830,1959.158936 2280.032715,1961.672729 2274.472168,1962.112305 
	C2267.214111,1962.686035 2260.033936,1962.664673 2252.821289,1961.936035 
	C2250.772949,1961.729004 2248.517334,1961.429688 2248.728271,1958.778931 
	C2248.933594,1956.197632 2251.300049,1956.119873 2253.295654,1956.052979 
	C2256.282715,1955.952637 2259.251709,1955.903564 2262.142822,1957.036377 
	C2265.928711,1958.520386 2268.623047,1957.398926 2269.936768,1952.594482 
z"
        />
        <path
          fill="#6E8CA6"
          opacity="1.000000"
          stroke="none"
          d="
M2221.426270,1973.470947 
	C2213.943115,1977.028076 2198.792969,1976.794189 2192.204590,1972.791748 
	C2201.717529,1968.919556 2211.244385,1972.228882 2221.426270,1973.470947 
z"
        />
        <path
          fill="#708497"
          opacity="1.000000"
          stroke="none"
          d="
M2622.620605,1950.249512 
	C2621.547363,1943.387573 2626.171631,1943.948486 2631.227051,1943.962891 
	C2633.437744,1942.692505 2635.025391,1942.510742 2637.371094,1943.710693 
	C2637.934570,1946.475464 2636.976074,1948.165405 2634.646729,1949.679688 
	C2630.495117,1950.113281 2627.036621,1953.327515 2622.620605,1950.249512 
z"
        />
        <path
          fill="#6E8CA6"
          opacity="1.000000"
          stroke="none"
          d="
M2384.659180,1946.288330 
	C2388.356934,1942.332642 2393.540771,1944.671753 2399.177246,1944.035034 
	C2400.302979,1948.821777 2397.812988,1950.442993 2393.394531,1949.886475 
	C2390.409668,1949.510376 2387.933105,1947.901855 2384.659180,1946.288330 
z"
        />
        <path
          fill="#6E8CA6"
          opacity="1.000000"
          stroke="none"
          d="
M2061.516602,1971.096191 
	C2058.690430,1971.315552 2056.644287,1970.444946 2057.152832,1968.132324 
	C2057.804199,1965.170654 2060.752930,1966.127075 2062.848389,1966.100464 
	C2064.314209,1966.081909 2066.649902,1965.794189 2066.577393,1968.005127 
	C2066.498291,1970.426636 2063.685059,1969.945190 2061.516602,1971.096191 
z"
        />
        <path
          fill="#6886A3"
          opacity="1.000000"
          stroke="none"
          d="
M2539.560059,1973.524170 
	C2537.913574,1976.439331 2535.492676,1976.413818 2532.439453,1974.503174 
	C2534.077148,1971.549561 2536.505127,1971.610474 2539.560059,1973.524170 
z"
        />
        <path
          fill="#6E8CA6"
          opacity="1.000000"
          stroke="none"
          d="
M2183.938965,1973.371094 
	C2182.278564,1975.975586 2181.008301,1975.612061 2180.066895,1972.611084 
	C2181.730957,1970.009644 2182.995361,1970.391602 2183.938965,1973.371094 
z"
        />
        <path
          fill="#6E8CA6"
          opacity="1.000000"
          stroke="none"
          d="
M2352.076660,1944.601318 
	C2352.879150,1944.022827 2353.779297,1944.016724 2355.354980,1944.026123 
	C2355.805908,1945.306519 2355.677246,1946.751343 2354.081543,1946.960449 
	C2352.966553,1947.106689 2352.461914,1946.149902 2352.076660,1944.601318 
z"
        />
        <path
          fill="#7392AD"
          opacity="1.000000"
          stroke="none"
          d="
M2484.340576,1988.643066 
	C2486.774658,1988.023682 2489.557129,1988.009644 2493.174805,1988.005127 
	C2491.174072,1993.048828 2490.150879,1993.184204 2484.340576,1988.643066 
z"
        />
        <path
          fill="#7392AD"
          opacity="1.000000"
          stroke="none"
          d="
M2554.135498,1988.681641 
	C2556.129883,1988.000977 2558.269531,1987.997070 2561.211670,1988.008789 
	C2559.307373,1992.934692 2558.579590,1993.060303 2554.135498,1988.681641 
z"
        />
        <path
          fill="#7392AD"
          opacity="1.000000"
          stroke="none"
          d="
M2504.084473,1986.757080 
	C2505.739258,1986.058838 2507.537109,1985.873291 2509.571777,1987.468750 
	C2507.825195,1990.338501 2505.841309,1990.620605 2504.084473,1986.757080 
z"
        />
        <path
          fill="#7793AA"
          opacity="1.000000"
          stroke="none"
          d="
M2825.937988,2016.761719 
	C2827.737061,2018.169312 2827.112061,2019.070679 2825.700928,2019.563843 
	C2824.820801,2019.871338 2823.729492,2019.659546 2823.630371,2018.555298 
	C2823.538330,2017.529541 2824.468018,2016.984253 2825.937988,2016.761719 
z"
        />
        <path
          fill="#7392AD"
          opacity="1.000000"
          stroke="none"
          d="
M2516.058350,1988.613892 
	C2517.083252,1988.390381 2518.189941,1988.728271 2519.648193,1989.541992 
	C2518.215088,1992.019775 2516.968750,1991.554688 2516.058350,1988.613892 
z"
        />
        <path
          fill="#738EA6"
          opacity="1.000000"
          stroke="none"
          d="
M2222.595703,2005.750244 
	C2220.965820,2003.860840 2219.317383,2001.036621 2217.407715,2001.157471 
	C2209.046631,2001.686646 2205.868164,1993.306641 2199.131348,1991.210083 
	C2196.632324,1990.432373 2196.101318,1987.940796 2196.023438,1984.754883 
	C2210.259766,1984.526245 2224.550537,1982.942871 2239.419922,1985.364014 
	C2238.784668,1992.931885 2232.623291,1989.718018 2228.183105,1991.581665 
	C2225.541992,1993.613892 2226.352051,1995.207764 2228.346191,1997.189941 
	C2235.138184,2000.562378 2241.827637,1998.885498 2248.325928,1999.017822 
	C2262.179443,1999.300049 2276.044189,1999.224731 2289.900146,1999.009277 
	C2292.854736,1998.963135 2296.435791,2000.735718 2298.276367,1996.937500 
	C2296.573975,1996.378052 2295.339844,1996.127930 2293.416992,1995.579224 
	C2289.563477,1993.208984 2285.970459,1992.982544 2282.318604,1992.710815 
	C2278.998535,1992.463989 2275.572021,1991.972168 2275.526611,1986.837646 
	C2282.002930,1984.125488 2287.872803,1984.133667 2294.360840,1985.362549 
	C2302.055176,1986.819824 2310.197266,1985.912598 2319.076904,1986.054565 
	C2319.821289,1986.713745 2319.833740,1987.688354 2319.417236,1987.932495 
	C2311.674561,1992.474976 2308.843262,1998.908569 2310.745850,2007.825073 
	C2311.634766,2011.991577 2307.197021,2014.820068 2302.921631,2013.127319 
	C2301.102539,2012.407104 2299.564209,2011.000000 2297.848877,2009.989258 
	C2295.372070,2008.529907 2292.841064,2007.764526 2290.837646,2010.664673 
	C2288.767334,2013.661133 2286.697510,2014.787842 2283.649170,2011.733032 
	C2281.578125,2009.657593 2279.457031,2009.839478 2278.192383,2012.783813 
	C2277.532227,2014.321411 2277.116455,2016.234497 2275.517822,2016.830078 
	C2271.281982,2018.407959 2269.250732,2020.361938 2272.914551,2024.572754 
	C2273.873535,2025.675049 2273.306641,2027.108643 2272.328613,2028.166504 
	C2270.977783,2029.628052 2269.478027,2029.422852 2267.832520,2028.616211 
	C2261.553223,2025.537720 2260.367920,2020.990845 2264.337891,2014.775269 
	C2265.225586,2013.385132 2266.481445,2012.200073 2267.200439,2010.739868 
	C2268.025879,2009.062866 2267.562744,2007.118042 2265.741211,2006.637817 
	C2261.874268,2005.617798 2257.756592,2005.251953 2254.020996,2006.833496 
	C2251.629395,2007.846436 2252.202637,2010.143555 2253.826904,2011.736694 
	C2256.698730,2014.552856 2255.479980,2016.956787 2253.546631,2019.626465 
	C2250.569092,2023.737671 2245.019531,2025.899902 2244.390625,2031.885620 
	C2244.313477,2032.618408 2242.259277,2033.654907 2241.104736,2033.676270 
	C2235.561768,2033.778687 2230.049805,2032.817017 2224.447998,2033.553345 
	C2222.471436,2033.813110 2220.225586,2031.817139 2220.905762,2029.701538 
	C2222.004395,2026.283569 2220.368164,2020.971802 2226.052979,2020.187012 
	C2229.072998,2019.770142 2231.978516,2019.275269 2234.551514,2017.610962 
	C2238.073242,2015.332642 2238.351074,2011.510498 2234.851807,2011.059448 
	C2230.012695,2010.435669 2229.039551,2002.861206 2222.595703,2005.750244 
z"
        />
        <path
          fill="#738EA6"
          opacity="1.000000"
          stroke="none"
          d="
M2363.852539,1987.246582 
	C2359.121338,1988.646240 2358.791260,1993.051514 2356.554688,1995.342285 
	C2358.199219,1998.171753 2360.066406,1998.232788 2361.556885,1997.909546 
	C2370.461426,1995.978516 2379.303467,1998.395142 2388.193848,1998.129272 
	C2396.362793,1997.884888 2404.423096,2001.840942 2412.781006,1998.981934 
	C2414.015381,1998.559692 2415.337402,1999.423340 2415.579590,2001.054688 
	C2415.910400,2003.284790 2415.289307,2005.241089 2412.922119,2005.583862 
	C2409.984619,2006.009399 2406.966553,2005.860596 2403.985352,2006.005127 
	C2398.225342,2006.284180 2391.896729,2004.331299 2387.724854,2010.634644 
	C2386.831787,2011.983887 2384.820557,2011.831177 2383.097412,2011.278198 
	C2371.046631,2007.410889 2358.920410,2007.074219 2346.511230,2009.774536 
	C2341.363281,2010.894653 2337.489014,2013.832886 2333.298828,2016.420898 
	C2325.092773,2021.489502 2323.232178,2020.718018 2321.957520,2011.582764 
	C2321.501953,2008.316772 2320.455078,2005.118530 2320.170654,2001.846558 
	C2319.717041,1996.627319 2321.518555,1994.474854 2326.838867,1994.127808 
	C2331.129395,1993.847778 2335.485107,1993.970581 2339.771484,1994.359863 
	C2346.567627,1994.976929 2346.553467,1995.134155 2347.997314,1986.799805 
	C2350.719238,1986.140015 2353.186768,1985.079590 2355.767334,1984.497070 
	C2358.571777,1983.863892 2361.790771,1982.983154 2363.852539,1987.246582 
z"
        />
        <path
          fill="#738EA6"
          opacity="1.000000"
          stroke="none"
          d="
M2177.219482,1984.033936 
	C2180.041016,1987.280273 2184.559082,1984.648315 2186.835693,1987.761353 
	C2185.386963,1991.082397 2182.370605,1989.728516 2180.005371,1989.978149 
	C2170.747559,1990.955811 2169.396729,1993.473389 2173.711914,2001.962036 
	C2174.153809,2002.831299 2174.529053,2003.734863 2175.330322,2005.486450 
	C2181.871826,1999.163208 2187.652344,2006.472656 2194.075928,2006.181274 
	C2195.277100,2006.126831 2196.844727,2006.555908 2196.824463,2008.370361 
	C2196.801758,2010.392578 2195.196289,2011.645386 2193.683105,2011.570923 
	C2187.107666,2011.247070 2180.756104,2013.472412 2174.046631,2012.496948 
	C2166.016113,2011.329590 2158.060059,2007.439697 2150.271729,2011.632812 
	C2147.749023,2008.471436 2149.863037,2007.130737 2151.220215,2006.031006 
	C2157.858398,2000.652344 2158.197021,2000.601807 2155.045166,1992.266235 
	C2153.279297,1987.595947 2155.885742,1986.376587 2159.558594,1984.491943 
	C2165.482178,1986.305542 2170.958252,1985.742554 2177.219482,1984.033936 
z"
        />
        <path
          fill="#959693"
          opacity="1.000000"
          stroke="none"
          d="
M2224.714844,1991.000000 
	C2228.349365,1985.626099 2234.415771,1989.821533 2239.515381,1986.462769 
	C2240.066162,1985.891479 2240.864502,1985.924194 2241.262695,1985.957642 
	C2242.833984,1986.060547 2243.779053,1985.466919 2245.242676,1984.686523 
	C2246.095215,1984.356323 2246.448730,1984.270630 2247.343262,1984.111328 
	C2250.514160,1988.052368 2255.833740,1986.154907 2259.456055,1989.565918 
	C2260.857666,1990.055298 2261.763916,1990.055664 2263.349609,1990.041260 
	C2265.461426,1988.581299 2266.770752,1986.979370 2269.410645,1986.242432 
	C2271.497070,1986.156250 2272.973145,1986.126953 2275.186035,1986.114746 
	C2276.628174,1990.139648 2279.715088,1989.953003 2282.711426,1990.058105 
	C2286.246094,1990.181885 2289.987305,1989.333130 2293.564941,1992.536621 
	C2293.540527,1994.819824 2292.179443,1995.786987 2290.488770,1995.989624 
	C2269.728516,1998.475830 2249.113770,1996.308472 2228.518066,1993.879639 
	C2227.247559,1993.729858 2226.206055,1992.661011 2224.714844,1991.000000 
z"
        />
        <path
          fill="#738EA6"
          opacity="1.000000"
          stroke="none"
          d="
M2259.935059,1989.260498 
	C2257.694824,1990.040283 2255.438232,1990.007202 2253.181641,1990.015991 
	C2250.181885,1990.027588 2248.231689,1988.844604 2247.956055,1984.821289 
	C2250.168457,1984.039429 2252.453613,1984.057861 2254.738037,1984.038452 
	C2257.760254,1984.012695 2259.796631,1985.111694 2259.935059,1989.260498 
z"
        />
        <path
          fill="#738EA6"
          opacity="1.000000"
          stroke="none"
          d="
M2269.649902,1985.696777 
	C2269.015381,1988.154419 2267.562012,1989.555176 2264.659180,1989.971558 
	C2264.511475,1987.038940 2264.890137,1983.938721 2269.649902,1985.696777 
z"
        />
        <path
          fill="#FBC18E"
          opacity="1.000000"
          stroke="none"
          d="
M2245.155029,1984.339355 
	C2244.922119,1985.665771 2244.674805,1987.760132 2242.000244,1986.334717 
	C2242.350586,1984.943237 2243.269775,1984.275513 2245.155029,1984.339355 
z"
        />
        <path
          fill="#B6AFA3"
          opacity="1.000000"
          stroke="none"
          d="
M2793.287109,1873.993164 
	C2794.204102,1873.671143 2794.566895,1873.743164 2795.479004,1873.890137 
	C2796.339844,1874.257812 2796.651611,1874.550537 2797.433350,1875.280396 
	C2797.903076,1875.717407 2797.981201,1875.918457 2798.011230,1876.023560 
	C2798.019043,1877.024658 2797.996826,1877.920654 2797.894531,1879.488159 
	C2796.192871,1880.373779 2793.393799,1880.158447 2793.134277,1880.869385 
	C2790.880859,1887.047729 2781.929932,1889.555298 2783.906738,1898.109375 
	C2784.448486,1900.454224 2784.838135,1903.364380 2781.989258,1906.921753 
	C2781.989258,1899.290771 2781.989258,1892.879028 2781.989258,1886.467285 
	C2779.439697,1890.812500 2780.423340,1895.582397 2778.408203,1899.903687 
	C2777.092285,1902.724854 2775.635254,1904.715210 2772.506104,1903.401245 
	C2769.207031,1902.015747 2769.809570,1899.327759 2771.295166,1896.802612 
	C2771.794189,1895.954712 2772.739258,1895.328735 2773.093018,1894.445923 
	C2773.590820,1893.203857 2773.687988,1891.713623 2772.218506,1890.986694 
	C2770.354492,1890.064697 2768.661133,1890.966919 2768.471436,1892.760010 
	C2768.018066,1897.039795 2768.055908,1901.371460 2767.825195,1906.509277 
	C2761.093018,1909.835938 2754.589600,1904.752686 2747.638184,1907.165894 
	C2744.679932,1908.192749 2742.601807,1903.517090 2739.143555,1901.665771 
	C2736.908936,1901.052734 2735.972412,1899.802002 2734.527832,1898.315674 
	C2734.180908,1897.861816 2734.013672,1897.920410 2734.083984,1897.865723 
	C2727.311768,1882.897339 2731.084961,1874.823242 2747.245361,1871.734253 
	C2748.591064,1877.478516 2752.273193,1877.633057 2756.557617,1877.181030 
	C2765.239258,1876.265015 2773.939697,1875.528198 2783.490234,1874.597412 
	C2784.593018,1874.092163 2784.838135,1873.707153 2785.232910,1873.990479 
	C2787.917969,1876.882080 2790.343506,1876.130981 2793.287109,1873.993164 
z"
        />
        <path
          fill="#708497"
          opacity="1.000000"
          stroke="none"
          d="
M2664.860840,1951.882080 
	C2661.691650,1947.427368 2657.530273,1948.209839 2653.631348,1947.959961 
	C2650.250977,1947.743408 2646.181396,1949.376465 2643.804199,1944.213501 
	C2643.062256,1942.718018 2642.856201,1941.931274 2643.133301,1940.486206 
	C2643.957275,1939.305420 2644.651123,1938.872925 2646.069092,1938.570068 
	C2647.072754,1938.536987 2647.470215,1938.570435 2648.436279,1938.704590 
	C2649.787109,1938.908691 2650.533447,1938.772949 2651.835938,1938.222900 
	C2656.965576,1936.279419 2662.341797,1938.257690 2667.124023,1934.552002 
	C2671.734131,1933.464966 2675.715332,1932.634155 2680.454590,1931.760498 
	C2691.729248,1934.293823 2702.196777,1934.505859 2713.163574,1931.544922 
	C2714.114258,1932.016113 2714.490234,1932.015991 2715.430664,1932.020508 
	C2719.003906,1933.595215 2721.980957,1936.031494 2725.827148,1932.126587 
	C2731.353271,1934.573242 2737.032471,1933.289917 2742.737793,1935.368164 
	C2743.306641,1940.130371 2739.653320,1943.690308 2740.343262,1949.028076 
	C2715.474854,1950.524414 2690.531494,1948.909668 2664.860840,1951.882080 
z"
        />
        <path
          fill="#83837F"
          opacity="1.000000"
          stroke="none"
          d="
M2748.722168,1871.906250 
	C2745.840088,1873.804443 2744.214111,1876.171753 2740.691895,1876.152222 
	C2735.031494,1876.120728 2733.126465,1879.817383 2734.694580,1884.725220 
	C2736.004150,1888.823975 2733.517578,1892.412964 2734.166016,1897.044434 
	C2731.824219,1897.888306 2729.481445,1897.838623 2727.166748,1898.068848 
	C2721.808838,1898.601318 2717.285645,1897.691406 2714.652832,1892.229126 
	C2714.180664,1891.249634 2712.502441,1890.851440 2710.662598,1890.057251 
	C2704.504150,1880.895996 2705.428223,1872.001953 2711.274170,1862.616333 
	C2718.425781,1858.361572 2725.422119,1860.589233 2733.022461,1859.951904 
	C2735.412842,1859.900146 2736.921143,1859.923828 2739.181396,1859.939697 
	C2739.558350,1866.687988 2744.362793,1865.934326 2748.681396,1866.065796 
	C2750.513672,1866.121460 2753.063721,1865.731445 2752.878662,1868.759766 
	C2752.763916,1870.628784 2751.053955,1871.338501 2748.722168,1871.906250 
z"
        />
        <path
          fill="#959693"
          opacity="1.000000"
          stroke="none"
          d="
M2711.513184,1861.670166 
	C2709.928711,1870.616577 2709.774414,1879.351807 2709.957520,1889.022095 
	C2708.524658,1890.040283 2707.104980,1890.150879 2704.973389,1890.295532 
	C2703.841553,1890.478516 2703.421387,1890.627441 2702.365234,1890.569092 
	C2695.921875,1890.639648 2693.951660,1888.017456 2693.413818,1882.117065 
	C2693.065430,1878.292358 2696.300293,1873.497803 2690.883301,1870.255615 
	C2688.226807,1871.480957 2686.928711,1873.541504 2684.386230,1875.310181 
	C2682.004639,1876.692993 2680.109619,1877.358765 2677.450684,1878.205078 
	C2673.042236,1879.091187 2669.402344,1879.278198 2665.149170,1877.661621 
	C2664.243652,1877.098389 2663.853516,1876.913330 2663.793457,1876.652344 
	C2662.731689,1872.025513 2669.297607,1856.942139 2674.206787,1854.306519 
	C2675.843262,1854.521973 2676.732666,1854.830322 2678.201172,1855.588379 
	C2683.567871,1859.249634 2688.391602,1861.744385 2694.450439,1856.856445 
	C2697.064453,1855.712646 2699.028564,1855.763794 2701.529297,1857.173828 
	C2702.741211,1859.051025 2703.936523,1859.860352 2705.243164,1859.245605 
	C2707.999268,1857.948975 2709.573730,1859.271851 2711.513184,1861.670166 
z"
        />
        <path
          fill="#C7C0B1"
          opacity="1.000000"
          stroke="none"
          d="
M2677.492676,1876.533203 
	C2679.115234,1874.639893 2680.593506,1874.081543 2682.990234,1874.303467 
	C2688.737549,1880.165649 2686.466309,1886.354492 2683.849121,1892.792358 
	C2674.828369,1895.396362 2667.075928,1892.885010 2658.545898,1890.362549 
	C2653.086670,1877.448486 2652.941162,1864.596313 2657.239258,1850.858643 
	C2663.670410,1843.743652 2669.317627,1849.042603 2675.771240,1850.488281 
	C2677.531494,1852.221313 2677.528564,1853.821289 2675.972900,1855.895020 
	C2667.999512,1858.721313 2667.906982,1861.608643 2675.035645,1867.997070 
	C2673.266113,1870.796753 2670.323486,1869.613403 2667.934082,1870.005737 
	C2665.653809,1870.380127 2664.256348,1871.306396 2664.281250,1874.499512 
	C2667.297363,1884.640625 2672.490723,1878.320068 2677.492676,1876.533203 
z"
        />
        <path
          fill="#9FA2A1"
          opacity="1.000000"
          stroke="none"
          d="
M2713.802002,1931.236816 
	C2711.726318,1932.000732 2709.507324,1931.900635 2708.285400,1933.744019 
	C2704.543701,1939.388428 2699.357666,1938.928589 2694.165527,1936.917847 
	C2690.236328,1935.395996 2685.242432,1937.159912 2681.719971,1932.358643 
	C2682.564941,1928.141113 2686.670654,1926.155396 2686.400879,1921.450439 
	C2686.241211,1918.663818 2688.223633,1918.334717 2689.971436,1920.761230 
	C2690.454102,1921.431152 2691.721436,1922.779053 2691.698730,1921.311646 
	C2691.617432,1916.066650 2698.597900,1915.722656 2698.461426,1910.016724 
	C2698.331055,1904.554565 2703.555908,1906.167847 2706.878906,1906.408691 
	C2710.611816,1906.679321 2712.181641,1909.155151 2710.731201,1912.647583 
	C2707.949951,1919.344727 2712.769287,1924.540283 2713.802002,1931.236816 
z"
        />
        <path
          fill="#8C8D89"
          opacity="1.000000"
          stroke="none"
          d="
M2657.065674,1850.076050 
	C2657.885742,1862.812866 2657.904785,1875.536255 2657.855957,1889.210449 
	C2651.803711,1889.601929 2646.234375,1891.072388 2640.883545,1893.615967 
	C2638.565186,1894.718018 2636.317383,1896.264526 2632.853516,1893.449219 
	C2632.449951,1891.995972 2632.987305,1891.999756 2633.062012,1891.815796 
	C2635.982910,1884.614990 2641.363770,1880.779907 2649.022461,1879.897339 
	C2650.436523,1879.734253 2651.971191,1877.733521 2651.495117,1877.356445 
	C2646.335205,1873.266724 2652.056152,1864.451050 2644.510986,1861.533691 
	C2643.617188,1861.188110 2642.904785,1860.370605 2642.113037,1859.765503 
	C2640.293457,1858.375366 2639.927002,1856.538574 2640.593750,1854.461914 
	C2641.205566,1852.556030 2642.880615,1852.030396 2644.550293,1852.131958 
	C2648.647217,1852.381592 2652.312744,1850.458618 2657.065674,1850.076050 
z"
        />
        <path
          fill="#EFF1EA"
          opacity="1.000000"
          stroke="none"
          d="
M2648.512207,1939.221924 
	C2647.095215,1939.680054 2646.212891,1939.704834 2644.669434,1939.762329 
	C2639.278076,1938.619385 2637.701660,1935.550781 2639.233154,1930.293213 
	C2641.800049,1922.813477 2644.089600,1916.103516 2649.093506,1909.541382 
	C2650.795654,1915.721924 2652.283447,1921.124878 2654.064941,1927.234619 
	C2655.225586,1929.153320 2655.714355,1930.482788 2655.448730,1932.688232 
	C2654.099854,1936.220337 2651.003906,1936.847900 2648.512207,1939.221924 
z"
        />
        <path
          fill="#B6BCC1"
          opacity="1.000000"
          stroke="none"
          d="
M2639.254639,1929.328857 
	C2640.049316,1932.990479 2640.687988,1936.189697 2643.681641,1939.185059 
	C2643.945068,1940.608643 2643.881592,1941.422363 2643.692139,1942.828613 
	C2642.173584,1943.634766 2640.781494,1943.848389 2638.689453,1944.174927 
	C2636.501709,1944.192017 2635.013672,1944.096069 2632.781250,1943.975220 
	C2628.670410,1940.117798 2630.442139,1935.220337 2628.913574,1930.299316 
	C2628.162842,1929.402832 2627.833984,1929.126465 2627.572754,1928.206055 
	C2627.866211,1927.193237 2628.092285,1926.824097 2628.756836,1925.977417 
	C2630.894775,1924.192627 2632.250244,1922.392456 2635.074707,1922.059448 
	C2636.700928,1924.531494 2637.718750,1926.771606 2639.254639,1929.328857 
z"
        />
        <path
          fill="#B6BCC1"
          opacity="1.000000"
          stroke="none"
          d="
M2647.922363,1939.876465 
	C2647.903076,1936.399170 2648.743408,1933.904785 2653.416016,1933.947754 
	C2655.429688,1932.459595 2654.840332,1930.636597 2655.587402,1928.526611 
	C2656.643311,1927.140991 2657.258301,1926.225708 2657.967773,1924.751099 
	C2658.791260,1923.269409 2659.725830,1922.953369 2661.241699,1923.947021 
	C2662.313721,1926.011108 2663.009033,1927.370117 2663.981689,1929.232056 
	C2665.051025,1931.048706 2666.144287,1932.119019 2667.454102,1933.746582 
	C2667.784424,1934.212158 2667.819824,1934.246704 2667.786621,1934.271240 
	C2667.925293,1937.289795 2667.171143,1939.512817 2663.601318,1939.684937 
	C2659.640625,1939.875854 2655.685303,1940.185425 2650.838867,1940.508423 
	C2649.949707,1940.573608 2648.958984,1940.706177 2648.466064,1940.623779 
	C2647.973145,1940.541260 2647.867676,1940.097656 2647.922363,1939.876465 
z"
        />
        <path
          fill="#83837F"
          opacity="1.000000"
          stroke="none"
          d="
M2683.955078,1893.305786 
	C2682.075195,1887.302490 2683.974121,1881.645020 2683.658203,1875.146240 
	C2684.138184,1871.558594 2685.657471,1869.926270 2689.462402,1870.156372 
	C2690.135010,1876.171143 2690.044434,1882.157959 2689.975830,1888.145020 
	C2689.936768,1891.564819 2689.021973,1894.115845 2683.955078,1893.305786 
z"
        />
        <path
          fill="#E7E3DA"
          opacity="1.000000"
          stroke="none"
          d="
M2726.246582,1931.092041 
	C2725.914551,1934.553711 2727.021240,1938.699707 2723.376953,1939.050049 
	C2720.282715,1939.347412 2715.956055,1938.718506 2716.089844,1932.765869 
	C2718.519775,1927.964233 2719.882568,1923.096436 2724.287354,1919.381104 
	C2726.935791,1923.203979 2725.541992,1926.922852 2726.246582,1931.092041 
z"
        />
        <path
          fill="#A9ACA9"
          opacity="1.000000"
          stroke="none"
          d="
M2625.309570,1901.712402 
	C2631.633545,1902.673828 2629.743164,1907.803711 2631.598633,1911.551392 
	C2633.697021,1914.159302 2633.615723,1916.145386 2631.281250,1918.595703 
	C2621.625000,1919.195801 2621.279541,1912.687744 2620.815918,1905.543335 
	C2621.581787,1903.040649 2622.819580,1902.037598 2625.309570,1901.712402 
z"
        />
        <path
          fill="#8C8D89"
          opacity="1.000000"
          stroke="none"
          d="
M2626.181152,1901.245361 
	C2625.130371,1902.376465 2624.146484,1902.863159 2622.667480,1903.583496 
	C2620.671143,1904.988403 2619.342529,1904.300049 2617.515869,1902.850342 
	C2616.517822,1901.329590 2616.240479,1900.332642 2616.222412,1898.492676 
	C2616.664062,1896.125000 2617.484619,1894.794800 2619.247070,1893.163208 
	C2621.307617,1891.941406 2622.841064,1891.576538 2625.216064,1891.385254 
	C2627.605957,1891.416748 2629.102051,1891.828247 2631.072998,1893.171631 
	C2631.675293,1893.668213 2632.006104,1894.022827 2632.170898,1894.205078 
	C2630.375977,1896.526611 2626.530273,1896.818115 2626.181152,1901.245361 
z"
        />
        <path
          fill="#8C8D89"
          opacity="1.000000"
          stroke="none"
          d="
M2629.888184,1918.715088 
	C2631.852783,1916.785767 2631.911377,1915.146362 2631.831787,1912.855713 
	C2637.560059,1912.957397 2635.301758,1917.480469 2635.756836,1921.484863 
	C2634.110107,1924.049927 2632.537354,1925.808594 2630.401855,1927.793701 
	C2627.584961,1925.277344 2627.171631,1922.390991 2629.888184,1918.715088 
z"
        />
        <path
          fill="#708497"
          opacity="1.000000"
          stroke="none"
          d="
M2667.758057,1933.574463 
	C2665.742676,1934.137451 2664.487061,1933.167358 2664.237061,1930.406250 
	C2665.978516,1930.192871 2667.424561,1930.947144 2667.758057,1933.574463 
z"
        />
        <path
          fill="#83837F"
          opacity="1.000000"
          stroke="none"
          d="
M2734.223389,1898.536133 
	C2735.862793,1898.065796 2737.220703,1898.773682 2738.257324,1900.887207 
	C2736.497559,1901.795898 2734.898193,1901.418823 2734.223389,1898.536133 
z"
        />
        <path
          fill="#708497"
          opacity="1.000000"
          stroke="none"
          d="
M2655.612793,1927.998047 
	C2656.057373,1929.798828 2656.194336,1931.559204 2654.668213,1933.515381 
	C2654.145264,1932.405396 2654.148193,1930.912964 2654.254883,1928.680908 
	C2654.358643,1927.941528 2655.197510,1927.940063 2655.612793,1927.998047 
z"
        />
        <path
          fill="#708497"
          opacity="1.000000"
          stroke="none"
          d="
M2661.480957,1924.092773 
	C2660.833008,1924.496460 2660.047852,1924.340820 2658.662354,1924.188477 
	C2658.832764,1922.361450 2659.891846,1921.969238 2661.480957,1924.092773 
z"
        />
        <path
          fill="#83837F"
          opacity="1.000000"
          stroke="none"
          d="
M2702.002441,1890.643066 
	C2702.150391,1890.319214 2702.571289,1890.276489 2703.626953,1890.281738 
	C2703.736084,1890.984131 2703.153076,1891.446655 2702.002441,1890.643066 
z"
        />
        <path
          fill="#B6AFA3"
          opacity="1.000000"
          stroke="none"
          d="
M2839.452637,1875.985474 
	C2841.578125,1879.420410 2844.759033,1880.975586 2847.910645,1879.786865 
	C2852.804199,1877.940796 2856.375000,1880.585693 2861.104004,1882.051147 
	C2861.383057,1883.702393 2860.956055,1885.291382 2860.283203,1887.458252 
	C2856.022949,1893.033081 2858.861572,1900.037231 2854.614746,1905.716675 
	C2854.144043,1907.144043 2854.099854,1907.946045 2853.963867,1909.343506 
	C2849.814209,1909.799927 2845.898682,1910.162964 2842.302734,1913.725098 
	C2841.064941,1914.397705 2840.238037,1914.447876 2838.796387,1914.426514 
	C2834.682861,1902.170410 2836.119629,1889.955566 2837.938721,1876.874023 
	C2838.262207,1876.006348 2838.602539,1876.002930 2839.452637,1875.985474 
z"
        />
        <path
          fill="#A6A29C"
          opacity="1.000000"
          stroke="none"
          d="
M2854.811279,1906.208252 
	C2853.560303,1899.831055 2854.200684,1893.628174 2859.302002,1888.183594 
	C2859.545410,1893.628662 2860.839355,1899.323608 2858.525879,1905.435303 
	C2857.130859,1906.043213 2856.282715,1906.058716 2854.811279,1906.208252 
z"
        />
        <path
          fill="#B6AFA3"
          opacity="1.000000"
          stroke="none"
          d="
M2795.520752,1893.137085 
	C2799.813477,1892.559937 2802.145996,1886.385864 2807.556152,1889.557617 
	C2811.519287,1894.395386 2808.131348,1899.041260 2808.916748,1903.571289 
	C2808.863525,1899.254883 2808.986816,1894.958496 2811.560059,1890.506226 
	C2812.369385,1889.948853 2812.710938,1889.881470 2813.027832,1889.942383 
	C2817.064941,1890.717041 2821.962158,1885.304688 2824.874756,1890.066528 
	C2827.594238,1894.513428 2825.786865,1900.341675 2825.728760,1905.580566 
	C2825.684082,1909.582886 2820.382812,1907.984375 2819.706299,1911.998657 
	C2818.743896,1912.557739 2817.969727,1912.477783 2816.612305,1912.327393 
	C2814.437500,1912.033813 2812.833008,1912.003784 2810.629883,1911.911987 
	C2805.956055,1912.036865 2801.883301,1912.194092 2796.941895,1912.443115 
	C2796.073730,1909.291748 2796.073730,1906.048462 2796.073730,1902.145630 
	C2792.721191,1903.351196 2794.965576,1908.222656 2790.758301,1908.117554 
	C2788.819336,1902.240967 2789.649170,1897.304321 2795.520752,1893.137085 
z"
        />
        <path
          fill="#959693"
          opacity="1.000000"
          stroke="none"
          d="
M2810.374756,1912.370972 
	C2811.595703,1910.009888 2813.357422,1909.765015 2815.677734,1911.750610 
	C2814.420898,1913.872437 2812.667480,1914.217651 2810.374756,1912.370972 
z"
        />
        <path
          fill="#ECA777"
          opacity="1.000000"
          stroke="none"
          d="
M2773.833984,1943.319214 
	C2776.680176,1941.699951 2777.939697,1939.817627 2777.929932,1936.723755 
	C2777.917725,1932.812012 2777.481689,1928.816895 2779.640381,1924.447998 
	C2781.919922,1924.949097 2783.775391,1926.355957 2783.747070,1928.389526 
	C2783.639404,1936.101196 2785.165771,1943.497070 2787.036377,1950.914551 
	C2787.867432,1954.210571 2785.376221,1955.683716 2782.411621,1956.037476 
	C2779.231201,1956.417114 2775.871094,1955.665894 2772.975098,1957.894287 
	C2770.734375,1952.938721 2774.462402,1948.649536 2773.833984,1943.319214 
z"
        />
        <path
          fill="#A9ACA9"
          opacity="1.000000"
          stroke="none"
          d="
M368.831299,3366.185059 
	C370.859619,3366.135498 372.188354,3366.027588 373.447815,3366.274170 
	C375.237976,3366.624268 376.382874,3367.803467 375.909393,3369.713867 
	C375.594910,3370.982666 374.594391,3372.003906 373.226166,3372.289062 
	C369.679840,3373.027832 366.737183,3371.224121 364.867004,3368.802979 
	C363.916931,3367.572754 366.708313,3366.857422 368.831299,3366.185059 
z"
        />
        <path
          fill="#8C8D89"
          opacity="1.000000"
          stroke="none"
          d="
M436.745636,3345.357422 
	C436.353668,3345.927002 435.835968,3346.421631 435.672668,3346.738281 
	C434.251617,3347.336182 432.993896,3347.617188 431.084412,3347.845947 
	C430.406342,3345.792480 430.380035,3343.791260 430.498108,3341.051025 
	C431.104858,3340.246826 431.498718,3340.046631 431.983887,3339.415039 
	C432.694550,3336.573730 434.616638,3336.972412 437.162842,3337.653809 
	C437.901886,3338.182861 437.916809,3338.088379 437.874878,3338.119629 
	C437.902374,3339.513184 437.971802,3340.875977 437.949341,3342.922852 
	C437.617554,3344.000488 437.377594,3344.394043 436.745636,3345.357422 
z"
        />
        <path
          fill="#838F9A"
          opacity="1.000000"
          stroke="none"
          d="
M367.083740,3376.694336 
	C366.572815,3378.015869 365.485382,3379.013184 364.820709,3377.269531 
	C364.548737,3376.556152 365.794495,3376.109375 367.083740,3376.694336 
z"
        />
        <path
          fill="#959693"
          opacity="1.000000"
          stroke="none"
          d="
M539.012451,3308.560059 
	C542.470642,3311.930176 542.637451,3314.737305 538.122742,3316.231934 
	C536.326721,3316.826904 534.236389,3316.532959 531.698364,3316.340332 
	C532.006714,3312.041992 532.722473,3307.882080 539.012451,3308.560059 
z"
        />
        <path
          fill="#1B2B3A"
          opacity="1.000000"
          stroke="none"
          d="
M489.908569,3304.317871 
	C492.376556,3299.995117 496.469574,3300.097412 501.012665,3299.440430 
	C501.837830,3301.574463 502.864838,3304.393311 501.269379,3305.784424 
	C498.321808,3308.354248 494.324707,3306.339355 490.406433,3306.456055 
	C489.970795,3305.618652 489.959045,3305.246826 489.908569,3304.317871 
z"
        />
        <path
          fill="#1B2B3A"
          opacity="1.000000"
          stroke="none"
          d="
M474.101349,3306.699219 
	C476.969330,3306.057617 479.758148,3303.221680 483.423035,3305.775635 
	C483.946106,3309.441162 483.827026,3313.181396 479.457001,3313.502441 
	C475.647705,3313.782471 475.184692,3310.131104 474.101349,3306.699219 
z"
        />
        <path
          fill="#959693"
          opacity="1.000000"
          stroke="none"
          d="
M608.291748,3285.970703 
	C608.502380,3289.456543 606.136047,3291.649902 602.725586,3293.706055 
	C601.338196,3293.153320 600.917542,3292.312744 601.042114,3290.641113 
	C602.478699,3287.257812 604.465637,3285.814697 608.291748,3285.970703 
z"
        />
        <path
          fill="#808489"
          opacity="1.000000"
          stroke="none"
          d="
M520.523315,3283.770996 
	C521.451660,3283.964844 521.907349,3283.954590 523.041016,3284.029297 
	C524.182678,3287.084961 525.161926,3290.205811 520.724609,3292.204102 
	C516.899231,3290.348145 519.147217,3288.025146 519.683716,3285.137939 
	C519.769287,3284.192871 519.910034,3283.879883 520.523315,3283.770996 
z"
        />
        <path
          fill="#98999E"
          opacity="1.000000"
          stroke="none"
          d="
M579.382874,3290.960449 
	C580.715088,3294.423828 579.945923,3297.060059 575.169556,3297.748047 
	C573.430298,3297.239746 573.000427,3296.398926 573.553345,3294.637207 
	C575.548096,3292.997803 577.114929,3291.995850 579.382874,3290.960449 
z"
        />
        <path
          fill="#1B2B3A"
          opacity="1.000000"
          stroke="none"
          d="
M600.610718,3289.999023 
	C601.467712,3290.820557 601.643005,3291.683838 601.936157,3293.196777 
	C600.975830,3295.617188 599.245728,3295.949951 596.690308,3295.974854 
	C596.012329,3293.140625 596.027039,3290.307373 600.610718,3289.999023 
z"
        />
        <path
          fill="#1B2B3A"
          opacity="1.000000"
          stroke="none"
          d="
M560.521973,3290.185059 
	C562.010193,3288.195557 563.593140,3287.722900 564.806030,3289.386230 
	C565.666931,3290.567139 564.871704,3292.020020 563.673035,3292.679199 
	C561.754456,3293.734131 560.652588,3292.831299 560.521973,3290.185059 
z"
        />
        <path
          fill="#838F9A"
          opacity="1.000000"
          stroke="none"
          d="
M469.949921,3312.984375 
	C465.145294,3312.040527 464.740784,3310.867676 468.111450,3306.790527 
	C469.883057,3308.375244 470.383087,3310.212158 469.949921,3312.984375 
z"
        />
        <path
          fill="#131C25"
          opacity="1.000000"
          stroke="none"
          d="
M437.836548,3337.540039 
	C436.451813,3338.338623 435.001709,3338.494141 432.847656,3338.884277 
	C434.292389,3334.094482 434.659790,3333.949463 437.836548,3337.540039 
z"
        />
        <path
          fill="#1B2B3A"
          opacity="1.000000"
          stroke="none"
          d="
M487.278687,3317.140137 
	C488.783936,3316.716797 489.324982,3317.246582 489.094055,3318.412109 
	C489.046082,3318.654297 488.701660,3318.979980 488.456390,3319.016846 
	C487.276398,3319.193359 486.783417,3318.619385 487.278687,3317.140137 
z"
        />
        <path
          fill="#1B2B3A"
          opacity="1.000000"
          stroke="none"
          d="
M499.127014,3320.078125 
	C498.367645,3321.457764 497.603607,3321.489502 496.927246,3320.493652 
	C496.786499,3320.286377 496.793854,3319.806885 496.941406,3319.604980 
	C497.643646,3318.643799 498.407440,3318.666992 499.127014,3320.078125 
z"
        />
        <path
          fill="#1B2B3A"
          opacity="1.000000"
          stroke="none"
          d="
M573.349731,3294.100586 
	C574.082947,3294.843262 574.184692,3295.686523 574.350464,3297.163574 
	C573.435181,3297.867432 572.455811,3297.937256 570.740417,3298.022949 
	C570.183777,3296.246826 570.704834,3294.696289 573.349731,3294.100586 
z"
        />
        <path
          fill="#1B2B3A"
          opacity="1.000000"
          stroke="none"
          d="
M540.766724,3300.862305 
	C539.220459,3301.305420 538.668030,3300.765137 538.913635,3299.566895 
	C538.964661,3299.318359 539.315552,3298.988770 539.568787,3298.948242 
	C540.786926,3298.752197 541.282288,3299.343262 540.766724,3300.862305 
z"
        />
        <path
          fill="#1B2B3A"
          opacity="1.000000"
          stroke="none"
          d="
M522.888062,3301.910645 
	C523.632080,3300.554932 524.380737,3300.537354 525.042297,3301.506836 
	C525.180664,3301.709717 525.174927,3302.179932 525.030884,3302.377686 
	C524.341553,3303.323486 523.593628,3303.289062 522.888062,3301.910645 
z"
        />
        <path
          fill="#1B2B3A"
          opacity="1.000000"
          stroke="none"
          d="
M470.717102,3332.854004 
	C469.223816,3333.273926 468.683685,3332.751953 468.916504,3331.592529 
	C468.964935,3331.351807 469.305756,3331.028809 469.549500,3330.991943 
	C470.722839,3330.813477 471.209290,3331.385498 470.717102,3332.854004 
z"
        />
        <path
          fill="#959693"
          opacity="1.000000"
          stroke="none"
          d="
M611.435913,3284.051270 
	C611.512207,3284.612549 610.926331,3285.293701 609.618835,3286.020020 
	C609.522705,3285.433594 610.148254,3284.802246 611.435913,3284.051270 
z"
        />
        <path
          fill="#122B42"
          opacity="1.000000"
          stroke="none"
          d="
M422.067627,3296.835693 
	C427.001038,3295.962646 431.877625,3295.473877 436.389374,3298.197021 
	C440.553497,3300.710693 442.685486,3303.764160 440.068848,3309.302734 
	C438.396576,3310.488281 436.805359,3310.976807 434.636719,3311.723633 
	C433.417328,3312.668213 432.634277,3313.016846 431.105286,3312.625000 
	C427.046997,3310.093018 423.710571,3307.630859 419.297150,3307.322754 
	C420.313080,3303.661865 421.161713,3300.603760 422.067627,3296.835693 
z"
        />
        <path
          fill="#A9ACA9"
          opacity="1.000000"
          stroke="none"
          d="
M454.762360,3300.328125 
	C451.391052,3299.681641 448.465668,3298.415527 449.489868,3295.233154 
	C450.416656,3292.353516 451.350433,3288.516602 455.419647,3288.188965 
	C457.637085,3288.010254 460.011169,3287.338623 462.620483,3289.084961 
	C458.243713,3292.424316 462.822144,3295.019287 463.389221,3298.343262 
	C463.641846,3300.390137 462.974915,3301.043701 460.776886,3300.603027 
	C458.488098,3300.223633 456.990479,3300.182129 454.762360,3300.328125 
z"
        />
        <path
          fill="#243748"
          opacity="1.000000"
          stroke="none"
          d="
M460.002625,3300.953613 
	C460.769135,3299.941406 461.552612,3299.617920 462.952942,3299.138184 
	C463.656952,3299.908936 463.840820,3300.840088 463.807037,3301.762695 
	C463.763458,3302.952393 463.894226,3304.536377 462.342194,3304.751221 
	C460.033081,3305.071045 460.348785,3303.027344 460.002625,3300.953613 
z"
        />
        <path
          fill="#8DA0AE"
          opacity="1.000000"
          stroke="none"
          d="
M430.398010,3312.952637 
	C431.306061,3312.165039 432.088745,3312.052246 433.465363,3311.960693 
	C434.115906,3312.859375 434.172516,3313.736816 434.097290,3315.266846 
	C432.159760,3316.081299 430.107422,3316.640625 430.398010,3312.952637 
z"
        />
        <path
          fill="#FBF9EC"
          opacity="1.000000"
          stroke="none"
          d="
M199.139648,1762.831543 
	C200.419785,1752.164429 199.093765,1742.052612 200.027496,1731.088013 
	C200.775055,1729.395264 201.547577,1729.099854 202.732208,1730.016846 
	C202.720932,1741.827637 202.490402,1752.986206 202.331528,1765.075928 
	C202.010132,1766.008179 201.617081,1766.009155 200.634674,1765.995850 
	C199.795349,1765.144409 199.545410,1764.307007 199.139648,1762.831543 
z"
        />
        <path
          fill="#2B383B"
          opacity="1.000000"
          stroke="none"
          d="
M190.981354,1795.267456 
	C187.978683,1795.677124 185.782867,1798.321289 182.592728,1795.982422 
	C184.823471,1793.294556 187.176117,1790.583740 190.478195,1790.528809 
	C194.579086,1790.460571 193.502472,1792.870483 190.981354,1795.267456 
z"
        />
        <path
          fill="#6F7981"
          opacity="1.000000"
          stroke="none"
          d="
M104.026871,1747.810913 
	C101.129745,1746.717529 100.064507,1744.463379 100.082298,1740.975098 
	C101.649712,1736.197266 100.196945,1731.526978 102.746506,1726.906006 
	C104.170258,1726.255005 105.149689,1726.159546 106.131104,1726.130005 
	C119.490517,1725.728027 121.094353,1727.385132 119.974876,1740.411011 
	C119.436440,1746.676147 117.481339,1748.299805 111.137711,1747.871094 
	C108.940781,1747.722534 106.902817,1745.739258 104.026871,1747.810913 
z"
        />
        <path
          fill="#EAE5D5"
          opacity="1.000000"
          stroke="none"
          d="
M102.964157,1725.904297 
	C101.883224,1730.638184 105.151024,1735.600952 100.668907,1739.940186 
	C95.531021,1737.618164 91.537140,1736.036011 89.774429,1743.306274 
	C89.070137,1746.211060 86.720573,1745.673950 85.387444,1743.799316 
	C81.955002,1738.972412 81.275200,1733.378418 81.838806,1727.621094 
	C82.127113,1724.676270 84.271767,1723.925415 86.839928,1723.947144 
	C91.495705,1723.986450 96.151253,1724.053833 101.567474,1724.373169 
	C102.328064,1724.636841 102.738411,1725.488770 102.964157,1725.904297 
z"
        />
        <path
          fill="#6F7981"
          opacity="1.000000"
          stroke="none"
          d="
M100.008842,1762.266602 
	C100.084152,1758.976562 98.912735,1755.824707 103.070396,1753.954834 
	C104.098366,1757.447021 104.499367,1761.221313 101.217659,1764.091064 
	C101.129074,1764.168335 100.635124,1763.920776 100.453346,1763.715576 
	C100.254578,1763.491089 100.199150,1763.139526 100.008842,1762.266602 
z"
        />
        <path
          fill="#EAE5D5"
          opacity="1.000000"
          stroke="none"
          d="
M103.645233,1709.203857 
	C102.378975,1709.891235 101.086479,1709.892578 100.697258,1708.373047 
	C100.444168,1707.385010 100.908997,1706.604736 102.618759,1706.498047 
	C103.318489,1707.204590 103.481308,1707.926392 103.645233,1709.203857 
z"
        />
        <path
          fill="#4A5448"
          opacity="1.000000"
          stroke="none"
          d="
M517.604736,1837.597412 
	C517.013733,1840.121338 516.044678,1842.058838 517.171692,1844.439087 
	C518.495972,1847.236084 518.110535,1850.030762 516.255371,1853.329346 
	C513.166870,1853.695312 510.024780,1854.871216 507.833923,1852.047729 
	C513.213501,1845.916382 513.213501,1845.916382 506.520752,1836.432129 
	C505.637665,1834.315063 505.074799,1832.602417 503.146454,1832.362427 
	C498.022064,1831.724731 493.077179,1832.203369 488.634827,1827.610107 
	C486.408569,1825.308350 482.114594,1829.427490 480.346100,1832.508057 
	C478.215698,1836.219116 475.379944,1838.266968 471.029999,1839.362671 
	C469.419495,1834.574829 472.052002,1831.206299 473.569031,1827.760986 
	C474.495819,1825.656006 475.688843,1823.934204 475.595795,1821.349731 
	C475.409546,1816.177979 478.044678,1814.194336 481.320007,1817.209595 
	C485.555817,1821.108887 490.860931,1821.335815 495.531586,1823.519409 
	C501.089081,1826.117432 502.226593,1825.337646 501.809784,1819.135742 
	C501.722412,1817.835693 500.966766,1816.575439 500.911865,1815.280151 
	C500.876160,1814.438599 501.330963,1813.172607 501.968750,1812.815186 
	C502.660614,1812.427368 504.124847,1812.511353 504.723053,1813.016479 
	C507.499756,1815.361450 510.279724,1817.685303 510.044922,1822.003784 
	C509.794250,1826.614136 509.988464,1831.248535 509.988464,1835.472046 
	C512.597229,1837.286865 515.235901,1835.276245 517.604736,1837.597412 
z"
        />
        <path
          fill="#D0D1C8"
          opacity="1.000000"
          stroke="none"
          d="
M441.537415,1826.701416 
	C442.041992,1822.794922 442.508392,1819.543945 442.279633,1816.342773 
	C441.957092,1811.828857 444.938324,1811.934204 448.695892,1811.627808 
	C455.393951,1809.773926 456.210083,1814.054932 456.787506,1818.007446 
	C457.566498,1823.340454 456.408264,1828.451904 453.454590,1833.953613 
	C452.217285,1836.822144 450.965424,1838.557007 447.854431,1839.112305 
	C447.057251,1838.951172 446.087250,1838.931030 445.647430,1839.135742 
	C437.114441,1837.622314 440.203827,1832.147217 441.537415,1826.701416 
z"
        />
        <path
          fill="#83837F"
          opacity="1.000000"
          stroke="none"
          d="
M446.658203,1839.616333 
	C447.963440,1838.288208 448.869629,1837.625122 450.014008,1836.594238 
	C452.305267,1833.742432 454.791168,1832.276733 458.806030,1833.247803 
	C459.912872,1836.890259 460.470642,1840.114136 461.199280,1843.347168 
	C462.140778,1847.524414 460.980896,1851.356323 454.841248,1851.879639 
	C454.101898,1851.906250 454.078125,1851.939453 454.082581,1851.922363 
	C452.358429,1850.602051 453.392456,1848.569580 452.775330,1846.972900 
	C453.069519,1848.271118 452.957458,1849.580200 452.384277,1851.402588 
	C451.007080,1852.076294 450.114258,1852.235962 448.548615,1852.497070 
	C447.168427,1852.277222 446.621185,1851.775879 446.118774,1850.556519 
	C445.279877,1846.736938 443.446716,1843.453247 446.658203,1839.616333 
z"
        />
        <path
          fill="#83837F"
          opacity="1.000000"
          stroke="none"
          d="
M525.923462,1872.653809 
	C524.919556,1878.963135 520.188782,1879.531982 514.750244,1880.333008 
	C513.536682,1880.011963 513.017578,1879.438232 512.450439,1878.279175 
	C516.313354,1874.547363 515.667908,1869.469116 517.803467,1864.825073 
	C522.501770,1865.477905 528.352295,1864.353638 529.749207,1871.610107 
	C528.997864,1872.413330 528.374451,1872.487427 527.298828,1872.431519 
	C526.846191,1872.298462 526.299438,1871.534546 526.299438,1871.534546 
	C526.299438,1871.534546 525.692383,1872.246704 525.923462,1872.653809 
z"
        />
        <path
          fill="#4A5448"
          opacity="1.000000"
          stroke="none"
          d="
M490.462158,1844.424927 
	C492.127899,1837.846924 497.705994,1838.799927 503.227264,1837.981567 
	C503.992950,1840.892334 504.056641,1843.812866 502.769409,1846.566650 
	C501.598267,1849.072021 501.930145,1852.990967 498.080078,1853.103149 
	C494.749756,1853.200317 493.241425,1850.348755 492.136139,1847.526855 
	C491.784088,1846.627686 491.365845,1845.754517 490.462158,1844.424927 
z"
        />
        <path
          fill="#595B54"
          opacity="1.000000"
          stroke="none"
          d="
M441.106232,1826.030273 
	C443.126099,1827.981689 443.570221,1830.019165 442.720825,1832.283691 
	C441.828369,1834.663208 442.860535,1836.542969 444.840881,1838.779175 
	C442.098358,1839.547363 438.989075,1839.754272 434.957947,1839.977051 
	C433.434753,1835.783936 436.551483,1832.124268 435.729797,1827.148193 
	C437.199738,1826.278931 438.763153,1826.157959 441.106232,1826.030273 
z"
        />
        <path
          fill="#2B383B"
          opacity="1.000000"
          stroke="none"
          d="
M419.935669,1886.076660 
	C418.734100,1886.077759 418.366608,1885.594604 418.093079,1884.493896 
	C417.953522,1883.188721 417.940765,1882.333130 417.836090,1880.841675 
	C419.337738,1878.243286 421.121155,1878.035400 422.601318,1880.135742 
	C424.480408,1882.802002 422.595764,1884.375732 419.935669,1886.076660 
z"
        />
        <path
          fill="#D0D1C8"
          opacity="1.000000"
          stroke="none"
          d="
M437.039612,1882.211182 
	C434.044891,1882.937500 431.744598,1882.737183 431.679596,1879.518921 
	C431.647217,1877.917114 433.064331,1877.260132 434.356384,1877.200684 
	C437.512512,1877.055054 438.279297,1878.883423 437.039612,1882.211182 
z"
        />
        <path
          fill="#4A5448"
          opacity="1.000000"
          stroke="none"
          d="
M501.972839,1878.061401 
	C498.637024,1878.397583 497.768738,1876.319946 497.842468,1872.822876 
	C499.119507,1872.149536 500.375946,1872.213623 502.564636,1872.481934 
	C503.071655,1874.220947 502.646484,1875.755493 501.972839,1878.061401 
z"
        />
        <path
          fill="#728BA1"
          opacity="1.000000"
          stroke="none"
          d="
M447.189026,1878.021729 
	C451.239349,1877.530396 449.610321,1879.862061 449.145050,1881.325439 
	C448.942566,1881.962158 447.324921,1882.846191 447.032562,1882.641724 
	C445.371063,1881.479980 445.932892,1879.921875 447.189026,1878.021729 
z"
        />
        <path
          fill="#5A605E"
          opacity="1.000000"
          stroke="none"
          d="
M445.613586,1850.298096 
	C446.546082,1850.500610 447.067291,1851.006836 447.732147,1852.055786 
	C447.147491,1853.402222 446.221954,1853.880005 445.289734,1853.187378 
	C444.348236,1852.487915 444.489716,1851.492432 445.613586,1850.298096 
z"
        />
        <path
          fill="#5A605E"
          opacity="1.000000"
          stroke="none"
          d="
M452.446838,1851.941406 
	C452.307587,1850.629639 451.243195,1848.818726 453.257263,1848.103760 
	C453.524231,1848.990845 453.791168,1849.877808 454.072571,1851.334961 
	C453.722626,1851.925415 453.358185,1851.945801 452.446838,1851.941406 
z"
        />
        <path
          fill="#728BA1"
          opacity="1.000000"
          stroke="none"
          d="
M440.130188,1868.434326 
	C441.738190,1866.501099 441.637543,1868.175171 441.685059,1868.851318 
	C441.722412,1869.382812 441.205048,1869.953369 440.934143,1870.506592 
	C440.639709,1869.975708 440.345306,1869.444824 440.130188,1868.434326 
z"
        />
        <path
          fill="#2B383B"
          opacity="1.000000"
          stroke="none"
          d="
M433.088867,1872.943359 
	C433.132782,1873.029053 433.044922,1872.857544 433.088867,1872.943359 
z"
        />
        <path
          fill="#2B383B"
          opacity="1.000000"
          stroke="none"
          d="
M356.273193,1868.232666 
	C356.217194,1870.882690 355.992706,1873.397217 355.707916,1876.586792 
	C353.637054,1873.645264 354.298065,1871.242554 355.325073,1868.337158 
	C355.765289,1867.829102 356.104675,1868.097168 356.273193,1868.232666 
z"
        />
        <path
          fill="#2B383B"
          opacity="1.000000"
          stroke="none"
          d="
M343.900024,1886.108521 
	C341.652832,1887.030884 340.368164,1885.812256 339.305054,1883.240234 
	C342.888397,1879.823486 343.339111,1882.721558 343.900024,1886.108521 
z"
        />
        <path
          fill="#2B383B"
          opacity="1.000000"
          stroke="none"
          d="
M105.042694,1841.077881 
	C104.957512,1841.124390 105.127876,1841.031250 105.042694,1841.077881 
z"
        />
        <path
          fill="#B6BCC1"
          opacity="1.000000"
          stroke="none"
          d="
M203.249268,1729.855713 
	C202.341461,1730.510498 201.729095,1730.361572 200.641235,1730.197144 
	C200.117783,1720.121460 200.067459,1710.071045 200.110184,1699.112549 
	C200.999695,1698.172607 201.796143,1698.140991 203.184174,1698.193237 
	C203.867111,1706.222168 203.958496,1714.167236 204.212936,1723.020630 
	C204.099686,1725.633301 203.823364,1727.337769 203.249268,1729.855713 
z"
        />
        <path
          fill="#595B54"
          opacity="1.000000"
          stroke="none"
          d="
M393.931213,1826.873291 
	C395.806549,1825.920288 398.354767,1826.469482 399.542542,1825.443604 
	C404.078186,1821.525635 408.485779,1823.572632 413.509277,1825.424561 
	C411.066711,1828.360229 406.400604,1826.508179 403.803253,1831.152710 
	C402.233215,1833.960449 396.898804,1831.725464 393.931213,1826.873291 
z"
        />
        <path
          fill="#595B54"
          opacity="1.000000"
          stroke="none"
          d="
M419.984161,1826.861328 
	C422.747009,1826.142090 425.660370,1826.129517 429.450958,1826.232666 
	C427.598267,1833.552734 425.493378,1833.804565 419.984161,1826.861328 
z"
        />
        <path
          fill="#8C8D89"
          opacity="1.000000"
          stroke="none"
          d="
M230.358383,1770.008789 
	C230.036499,1751.302124 230.184952,1732.701050 230.364319,1713.179932 
	C230.640625,1711.953613 230.886017,1711.647217 231.633057,1711.649048 
	C232.139282,1719.296875 232.143845,1726.636475 232.251068,1734.874268 
	C232.179779,1746.619629 232.005859,1757.466919 231.809540,1769.266602 
	C231.787140,1770.218994 230.828720,1770.114380 230.358383,1770.008789 
z"
        />
        <path
          fill="#4A5448"
          opacity="1.000000"
          stroke="none"
          d="
M288.982117,1788.902588 
	C290.662170,1787.930908 292.468994,1787.815918 295.183533,1787.792725 
	C295.906158,1789.504028 296.006042,1791.223145 295.480377,1792.723145 
	C294.127258,1796.584961 292.726624,1800.133423 297.799866,1802.651855 
	C298.820129,1803.158447 296.866821,1804.618530 296.037659,1806.204102 
	C291.821014,1807.103516 290.629303,1804.838745 290.653351,1801.338013 
	C290.680328,1797.411743 291.155090,1793.413574 288.982117,1788.902588 
z"
        />
        <path
          fill="#A6A29C"
          opacity="1.000000"
          stroke="none"
          d="
M286.189819,1803.737427 
	C287.418518,1805.934692 287.550964,1807.496460 285.156281,1807.682739 
	C283.273254,1807.829224 281.992523,1806.762573 282.240875,1804.776611 
	C282.514191,1802.591064 283.948303,1802.335205 286.189819,1803.737427 
z"
        />
        <path
          fill="#DED5C3"
          opacity="1.000000"
          stroke="none"
          d="
M70.717239,1762.073242 
	C69.370071,1760.104004 68.534096,1758.289551 68.159538,1756.384521 
	C67.624802,1753.664551 67.162415,1752.545044 63.691395,1753.663086 
	C60.810387,1754.590942 60.617790,1751.051270 60.613052,1748.928467 
	C60.608559,1746.914917 62.525967,1745.974854 64.110962,1746.406006 
	C73.488297,1748.957153 83.115479,1747.443604 92.576279,1748.414307 
	C95.072098,1748.670410 97.412331,1749.634766 97.033150,1752.512085 
	C96.729385,1754.817139 96.429237,1758.435181 92.284172,1756.399048 
	C90.163681,1755.357422 90.083336,1756.716064 90.012474,1758.052979 
	C89.873093,1760.682251 89.861412,1763.318481 89.799706,1766.712158 
	C82.388214,1770.200195 77.594711,1763.818848 70.717239,1762.073242 
z"
        />
        <path
          fill="#3A4B59"
          opacity="1.000000"
          stroke="none"
          d="
M203.447174,3040.313477 
	C194.855301,3039.270996 187.522324,3042.725342 179.816010,3042.156982 
	C178.628265,3042.069336 176.677628,3042.630615 176.244934,3043.483643 
	C171.286911,3053.251953 161.524551,3049.089600 154.005737,3050.978271 
	C150.089859,3051.962158 145.502487,3050.376709 141.235611,3049.819824 
	C138.741394,3049.494385 136.666367,3048.277100 136.564713,3045.498779 
	C136.442429,3042.157227 139.118530,3042.017334 141.450729,3042.047852 
	C144.088760,3042.082275 146.790512,3042.186768 149.341568,3042.785156 
	C153.279572,3043.709229 157.424072,3043.117676 159.189728,3040.006348 
	C161.582367,3035.790527 164.821228,3035.962402 168.448746,3035.975342 
	C178.960495,3036.013184 189.347351,3033.656250 199.893372,3034.354248 
	C200.547012,3034.397705 201.276413,3034.029785 201.865723,3034.205322 
	C214.473923,3037.959961 227.319290,3036.551270 240.127625,3036.055908 
	C242.119339,3035.978760 244.332169,3035.905273 245.836380,3037.360596 
	C254.403824,3045.649170 264.269836,3043.611084 274.169403,3041.012451 
	C276.365997,3040.435791 278.069946,3040.998535 279.760315,3042.145752 
	C281.524811,3043.343262 281.545227,3044.950195 280.173065,3046.309570 
	C277.986664,3048.476074 275.184082,3049.472656 272.145721,3049.947754 
	C261.401245,3051.628906 250.751770,3053.435547 239.714767,3052.101318 
	C231.821426,3051.147217 223.906830,3052.239258 216.043243,3054.230225 
	C206.435059,3056.663574 196.519196,3057.674316 186.578964,3057.934082 
	C182.932159,3058.029541 177.463043,3057.925537 177.653641,3054.053467 
	C177.888763,3049.276611 182.958160,3051.652832 186.445068,3051.542480 
	C198.019928,3051.176025 209.536667,3049.556152 221.161667,3049.694580 
	C223.155380,3049.718262 225.080383,3048.429688 225.047867,3045.982910 
	C225.015640,3043.558594 223.154236,3042.586426 221.089233,3042.294922 
	C215.523773,3041.508545 209.732208,3042.778564 203.447174,3040.313477 
z"
        />
        <path
          fill="#3A4B59"
          opacity="1.000000"
          stroke="none"
          d="
M129.548157,3060.700439 
	C123.113449,3056.531494 115.564255,3059.401855 110.012436,3055.113281 
	C113.404129,3052.361084 122.319748,3050.296875 126.739128,3052.469727 
	C131.944885,3055.029297 136.578522,3058.747559 144.813309,3058.861328 
	C138.884674,3063.128662 134.595398,3062.106445 129.548157,3060.700439 
z"
        />
        <path
          fill="#172837"
          opacity="1.000000"
          stroke="none"
          d="
M680.554810,2978.539062 
	C675.203674,2978.086426 670.491089,2978.063477 664.894775,2978.029785 
	C657.121826,2976.716309 650.194275,2976.131592 643.182922,2976.763672 
	C639.517822,2977.094238 635.839233,2977.131104 632.359192,2975.688721 
	C629.828064,2974.640137 627.338074,2973.165771 627.345764,2970.107178 
	C627.353821,2966.909180 630.193420,2966.174561 632.688843,2965.260010 
	C627.680420,2966.345459 622.880005,2965.719482 618.327515,2963.489990 
	C615.945496,2962.323242 613.241394,2961.129150 613.380615,2957.910400 
	C613.523926,2954.598877 616.401489,2953.842041 618.935303,2953.048340 
	C624.511353,2951.302246 630.135193,2952.470947 635.773987,2952.781982 
	C661.200623,2954.184570 686.552551,2957.991943 712.102600,2955.419189 
	C705.852051,2955.004395 699.633545,2955.467529 693.434204,2956.335449 
	C688.284546,2957.056641 683.111267,2956.695312 679.458923,2952.750000 
	C673.504700,2946.318115 666.488647,2946.695312 659.220703,2948.854248 
	C651.239319,2951.225586 643.419128,2951.042480 635.691406,2948.312744 
	C630.456299,2946.463379 630.470276,2942.065430 634.931763,2940.735352 
	C651.629639,2935.757568 667.907654,2924.755615 686.339905,2935.417480 
	C690.257812,2937.683838 694.630066,2937.591553 699.652588,2935.645508 
	C718.817871,2931.817871 737.296326,2928.932129 755.857300,2926.356201 
	C773.540100,2923.902344 790.525818,2925.215332 808.601074,2925.240479 
	C814.213989,2927.290771 815.776794,2922.876221 819.145447,2920.270508 
	C832.820435,2917.270264 845.539307,2919.873779 858.230835,2922.076660 
	C861.188293,2922.590088 864.109131,2922.914307 867.922852,2923.178711 
	C871.614380,2923.540039 874.247925,2924.474854 877.410034,2926.319092 
	C882.027466,2932.419678 878.546570,2935.936279 874.287598,2938.757812 
	C869.163208,2942.152100 863.701416,2945.035156 857.936279,2948.872559 
	C855.497375,2950.194580 853.582031,2950.805420 851.074463,2951.909668 
	C841.126770,2955.659668 833.646301,2962.488525 823.636597,2966.182129 
	C821.778442,2966.501709 820.690247,2966.514648 818.765137,2966.238525 
	C795.751831,2965.869141 773.548828,2963.782227 750.559204,2966.953613 
	C745.832092,2967.011230 741.941650,2966.798828 737.348022,2967.876953 
	C730.442017,2968.157959 724.330200,2968.395508 717.515198,2969.025391 
	C708.735413,2970.354492 700.633972,2971.361328 692.467102,2971.322021 
	C687.749634,2971.299316 686.278015,2973.634521 686.080139,2978.383301 
	C684.115906,2979.582275 682.658813,2979.592041 680.554810,2978.539062 
z"
        />
        <path
          fill="#243748"
          opacity="1.000000"
          stroke="none"
          d="
M699.825806,2937.317871 
	C693.625427,2941.050537 687.367798,2941.952148 681.955139,2936.204590 
	C679.810791,2933.927490 677.244019,2934.030029 674.611755,2934.019043 
	C660.156128,2933.959229 647.367798,2940.469482 633.744507,2944.150635 
	C642.318359,2946.547607 650.617859,2949.759277 659.327332,2945.340820 
	C663.659241,2943.143311 668.479370,2944.125000 673.108765,2944.054688 
	C675.962646,2944.011475 678.412781,2944.895752 679.567017,2947.767090 
	C682.346924,2954.682617 687.763672,2954.588379 693.666504,2953.117676 
	C702.212891,2950.988281 710.886414,2952.352783 719.499939,2952.116455 
	C721.191895,2952.070312 722.985657,2952.625000 722.803406,2954.807617 
	C722.690247,2956.162598 721.509521,2957.290771 720.133118,2957.441406 
	C705.690674,2959.024658 691.280090,2962.316650 676.707031,2958.479492 
	C674.464111,2957.888672 672.032166,2956.439209 670.002136,2956.869629 
	C655.637085,2959.916504 641.644836,2956.794678 627.532349,2955.016113 
	C623.371887,2954.491943 619.228760,2955.748291 613.198792,2957.655518 
	C620.357544,2961.092041 625.292480,2964.312012 632.045654,2962.472656 
	C634.820312,2961.717285 637.975586,2962.245850 640.949036,2962.381348 
	C642.447937,2962.449707 643.373108,2963.653564 643.331543,2965.058838 
	C643.290100,2966.458252 642.306030,2967.360107 640.819519,2967.696045 
	C637.073547,2968.543457 632.937866,2966.989746 628.311096,2969.783447 
	C633.576538,2975.301514 639.357727,2974.131836 645.048401,2973.318604 
	C651.596497,2972.382812 657.925659,2972.694824 663.827637,2977.279785 
	C664.011108,2978.019531 664.087280,2978.036865 664.086914,2978.076172 
	C661.417908,2979.732422 660.817139,2982.899658 658.426208,2985.499268 
	C652.902344,2989.016602 647.242310,2987.648926 640.965942,2988.076660 
	C638.241089,2989.979980 635.816589,2989.981934 632.689209,2990.073730 
	C629.847656,2990.098877 627.724731,2990.088379 624.805847,2990.067383 
	C618.474792,2988.826172 615.637390,2981.954590 608.279419,2982.916992 
	C599.287354,2982.059082 591.074341,2981.166992 582.798645,2981.233887 
	C575.412048,2981.293701 568.519409,2980.145752 562.084778,2975.867920 
	C555.879883,2971.742676 548.654907,2973.529053 541.781250,2974.206055 
	C536.870239,2974.689209 531.884338,2975.965332 527.179016,2971.455566 
	C525.557312,2967.803467 526.066895,2964.689941 525.965454,2960.918945 
	C526.283325,2956.855225 526.063416,2953.822510 522.297119,2952.275391 
	C519.567383,2951.153809 517.297058,2949.415527 516.256470,2945.722900 
	C516.801147,2937.887207 520.600037,2935.763916 527.680725,2936.500732 
	C538.552490,2937.632324 549.518372,2937.916992 560.301453,2935.049316 
	C564.395752,2933.960693 569.714661,2931.842773 572.006592,2936.594482 
	C574.487915,2941.738770 568.454834,2943.441650 565.497009,2946.184570 
	C564.553650,2947.059082 563.138184,2947.316406 562.416382,2949.059814 
	C573.041321,2940.577148 584.994141,2945.333252 595.691345,2945.041016 
	C591.708191,2945.578369 587.132141,2943.718750 582.370178,2944.192139 
	C580.363403,2944.391357 578.422974,2943.376953 577.528076,2941.336914 
	C576.410156,2938.788574 577.751038,2936.860107 579.484009,2935.175049 
	C584.430786,2930.365479 590.836243,2930.356934 597.830688,2929.744141 
	C603.330078,2928.977539 607.874023,2930.277588 613.212158,2931.350098 
	C624.723083,2932.687256 634.546936,2932.313477 643.493286,2924.017822 
	C650.246460,2917.755859 660.496155,2919.861816 669.787964,2918.173096 
	C665.657166,2916.349609 661.417603,2916.989258 658.158691,2914.534424 
	C656.518555,2913.298828 654.974243,2911.953125 655.346069,2909.664795 
	C655.740540,2907.237305 657.801514,2906.294922 659.773682,2906.549561 
	C673.511902,2908.325684 687.444214,2903.557129 701.241760,2909.037354 
	C710.764221,2912.819580 721.041992,2913.797119 731.261353,2909.183350 
	C736.112000,2906.993164 741.773010,2908.944580 747.842896,2909.046875 
	C750.776917,2908.914307 752.837219,2909.301758 755.468262,2910.607422 
	C759.915649,2920.813721 757.199951,2926.996094 747.593018,2926.630127 
	C731.070312,2926.000488 715.542358,2931.055420 699.501648,2932.970703 
	C697.752441,2933.179443 697.759216,2933.236084 699.825806,2937.317871 
z"
        />
        <path
          fill="#2B3E51"
          opacity="1.000000"
          stroke="none"
          d="
M609.361084,2980.603516 
	C617.471191,2978.260986 620.016479,2984.165039 623.693359,2989.436035 
	C621.994141,2992.417480 619.196960,2991.871826 616.596802,2992.042969 
	C613.217957,2992.265137 608.915894,2992.808105 607.519165,2995.264160 
	C604.430054,3000.696045 601.369568,2999.029297 596.886047,2997.704590 
	C590.075928,2995.691895 583.111633,2993.527344 575.638062,2995.533203 
	C572.207703,2996.453857 568.381653,2995.965088 564.734192,2995.991699 
	C561.676331,2996.013916 558.708801,2996.239502 556.473083,2998.642090 
	C552.796936,3002.593018 548.187866,3002.508301 543.537170,3001.458740 
	C534.340515,2999.383301 525.248230,2999.206299 516.026733,3001.478027 
	C512.872681,3002.255127 509.430481,3001.882080 506.118561,3001.994385 
	C500.565125,3002.183594 494.552490,3001.102539 491.525635,3007.780273 
	C490.913116,3009.131348 489.505341,3009.440430 488.129547,3008.937988 
	C484.591461,3007.645996 480.906067,3008.140869 477.279053,3007.912354 
	C476.292267,3007.850342 475.194763,3007.877686 474.351105,3007.457031 
	C455.409637,2998.010254 455.415100,2997.988037 435.735626,2997.985352 
	C421.126770,2997.983398 406.517914,2998.015869 390.974243,2998.041016 
	C388.296112,2999.719971 386.884247,3001.732422 384.392944,3003.502197 
	C381.821381,3003.931885 379.699829,3003.956055 376.782104,3003.985352 
	C363.005615,3001.802734 349.511322,3002.073242 336.559875,2995.853027 
	C334.115173,2990.478271 338.261383,2990.214844 342.374512,2989.151855 
	C345.964233,2988.966797 348.704559,2988.810303 352.159058,2989.609619 
	C363.556976,2993.304199 374.159027,2998.476562 385.246033,2990.129883 
	C385.468140,2982.271484 380.596893,2979.084229 375.178528,2981.504883 
	C365.383606,2985.880859 355.063202,2985.026855 344.343292,2987.013184 
	C337.874603,2988.435303 332.320160,2988.118896 326.510315,2984.716797 
	C324.182953,2981.804688 322.377411,2979.477539 320.286072,2976.584961 
	C320.003052,2975.119873 320.005829,2974.220459 320.018585,2972.645752 
	C322.012360,2972.033203 324.129639,2971.866943 325.759705,2973.167969 
	C335.261749,2980.752441 345.653290,2978.288818 356.044495,2975.971436 
	C357.322906,2975.686523 358.766327,2975.455811 358.922760,2973.798340 
	C359.060272,2972.340820 358.108948,2971.356689 356.958069,2970.655029 
	C350.040344,2966.439697 342.483185,2964.280273 334.421814,2964.046875 
	C328.469666,2963.874268 322.509064,2963.987549 316.552246,2963.983154 
	C313.444824,2963.980713 310.408142,2963.892578 310.074097,2958.868164 
	C318.665863,2949.227539 330.710907,2950.179932 339.872742,2952.639893 
	C356.317535,2957.055664 373.371307,2956.333984 389.584747,2961.234619 
	C406.843201,2966.451660 424.628296,2967.865234 443.459259,2966.662598 
	C447.200226,2967.180664 449.797058,2965.776123 453.406494,2965.293457 
	C454.830200,2965.157959 455.403076,2965.119385 456.840637,2965.069336 
	C467.030609,2964.851074 476.354553,2965.008301 486.544373,2965.218262 
	C488.549347,2965.388672 489.636627,2965.687500 491.341187,2966.653809 
	C493.945007,2969.701660 493.614532,2972.820557 491.477356,2974.352783 
	C482.809021,2980.569092 475.606934,2989.537109 463.102966,2988.698730 
	C452.956512,2988.018311 442.767334,2989.472656 437.039001,2989.033447 
	C451.260040,2991.022949 470.341888,2986.466797 488.721893,2992.951172 
	C495.745819,2995.429443 502.492432,2991.731201 509.418396,2991.816162 
	C519.064209,2991.934570 528.638123,2990.343018 538.299194,2990.658691 
	C541.637024,2990.768066 545.471252,2990.725098 547.767822,2988.258301 
	C557.829041,2977.451904 570.382568,2981.106689 582.429504,2981.802002 
	C591.249573,2982.311035 600.090576,2984.469971 609.361084,2980.603516 
z"
        />
        <path
          fill="#2B3E51"
          opacity="1.000000"
          stroke="none"
          d="
M747.557617,2964.707031 
	C767.992126,2960.443848 788.149902,2962.506348 808.215759,2961.974609 
	C811.825073,2961.879150 815.513306,2961.594971 819.635925,2963.892578 
	C820.765198,2965.805176 820.257202,2967.143555 818.952393,2968.950195 
	C813.291138,2973.708740 807.416077,2976.711914 801.155640,2978.898438 
	C793.873962,2981.441162 788.547852,2985.921387 785.380798,2993.811523 
	C783.386108,2996.187744 781.042053,2996.945557 778.699707,2998.913574 
	C772.939026,3001.576660 767.914185,3004.217285 761.653320,3005.516357 
	C759.026184,3005.247803 757.425476,3004.294922 755.365723,3002.747803 
	C753.826050,3001.376709 752.999329,3000.297852 752.104797,2998.470703 
	C752.276611,2993.495850 750.077820,2992.156250 745.982117,2991.913330 
	C734.767944,2991.247314 723.905701,2987.766846 712.620605,2987.444824 
	C712.242798,2987.434082 711.890137,2986.543457 710.717041,2984.996826 
	C719.877625,2984.296387 727.417419,2978.654053 736.670715,2981.101807 
	C739.690063,2981.900391 742.597717,2980.499268 744.952515,2978.654541 
	C746.616821,2977.350342 746.888062,2973.472168 749.756226,2974.632812 
	C754.359253,2976.495605 759.604309,2974.722900 763.952820,2978.128174 
	C771.743713,2984.229492 780.354858,2983.167236 789.061096,2974.953613 
	C775.501892,2972.865479 763.338806,2966.212646 748.773010,2969.831055 
	C746.807556,2968.463867 746.553101,2966.979736 747.557617,2964.707031 
z"
        />
        <path
          fill="#0D1C2A"
          opacity="1.000000"
          stroke="none"
          d="
M751.066589,2997.871582 
	C753.041992,2998.250732 754.175354,2998.677002 755.663574,2999.599609 
	C755.339050,3001.577881 755.593933,3003.690186 754.153748,3004.573242 
	C740.232483,3013.108154 726.846863,3023.008057 708.752930,3022.573486 
	C696.625061,3019.630371 684.432800,3021.648438 673.054138,3017.203125 
	C673.900574,3012.818848 677.049561,3012.387939 679.883972,3012.179688 
	C689.459595,3011.477295 698.873108,3010.059814 708.018738,3007.042480 
	C709.242249,3006.638916 710.670288,3006.239746 711.872070,3006.484619 
	C719.933472,3008.126465 726.725708,3005.134521 733.647217,3000.348633 
	C739.353516,2998.322510 744.749084,2997.768066 751.066589,2997.871582 
z"
        />
        <path
          fill="#243748"
          opacity="1.000000"
          stroke="none"
          d="
M686.539856,2978.352051 
	C686.183044,2973.208496 680.495972,2975.103516 678.912109,2971.209473 
	C684.940674,2969.541748 690.690979,2967.709717 696.852234,2968.045898 
	C702.814148,2968.371094 708.599365,2967.556396 715.171387,2966.042236 
	C718.410645,2969.250732 717.293091,2972.101318 714.560059,2975.452393 
	C712.548462,2976.066650 711.060303,2976.064941 708.828003,2976.058838 
	C704.509216,2979.877930 700.287231,2980.953857 694.638855,2978.556641 
	C693.063843,2978.069336 692.213440,2978.075684 690.725342,2978.073242 
	C689.241821,2978.791992 688.273438,2979.037598 686.539856,2978.352051 
z"
        />
        <path
          fill="#2B3E51"
          opacity="1.000000"
          stroke="none"
          d="
M714.752930,2976.075684 
	C713.644592,2973.001709 714.985291,2970.294678 715.916138,2966.755859 
	C722.194702,2967.206543 728.294189,2962.396240 735.259338,2965.878418 
	C736.761292,2966.749023 737.051880,2967.662842 736.450073,2969.339111 
	C732.117432,2972.301758 727.143188,2972.374756 723.813049,2976.020752 
	C721.216064,2978.864014 718.261230,2975.516602 714.752930,2976.075684 
z"
        />
        <path
          fill="#2B3E51"
          opacity="1.000000"
          stroke="none"
          d="
M694.126709,2978.768066 
	C698.211182,2978.123047 702.478333,2977.979736 707.295410,2976.133789 
	C704.117065,2983.076660 702.289612,2983.531250 694.126709,2978.768066 
z"
        />
        <path
          fill="#243748"
          opacity="1.000000"
          stroke="none"
          d="
M736.680176,2969.916504 
	C736.013489,2969.072754 736.011536,2968.189697 736.011353,2966.643555 
	C739.506531,2965.338379 743.000000,2964.696289 747.264038,2964.061523 
	C748.014221,2965.499268 747.993835,2966.929688 747.953247,2969.070312 
	C744.408203,2970.307129 740.859985,2968.266846 736.680176,2969.916504 
z"
        />
        <path
          fill="#2B3E51"
          opacity="1.000000"
          stroke="none"
          d="
M685.150757,2977.918701 
	C686.827820,2977.936035 687.758850,2977.970947 689.388733,2978.035156 
	C686.079529,2984.640869 684.243713,2984.893799 680.027832,2978.771729 
	C681.412415,2978.051758 682.908630,2977.993896 685.150757,2977.918701 
z"
        />
        <path
          fill="#2B3E51"
          opacity="1.000000"
          stroke="none"
          d="
M658.680176,2985.867432 
	C658.277100,2982.604736 656.952942,2978.324463 663.321167,2978.102051 
	C663.973511,2981.566162 663.569458,2984.839111 658.680176,2985.867432 
z"
        />
        <path
          fill="#33495B"
          opacity="1.000000"
          stroke="none"
          d="
M384.680420,3003.915771 
	C384.223633,3000.920654 385.549286,2998.798096 389.362457,2998.110840 
	C390.040253,3001.219727 389.543427,3003.999023 384.680420,3003.915771 
z"
        />
        <path
          fill="#2B3E51"
          opacity="1.000000"
          stroke="none"
          d="
M632.452637,2990.581787 
	C633.657227,2987.719482 636.197205,2988.059570 639.367554,2988.057129 
	C639.111572,2992.435059 636.317139,2992.454834 632.452637,2990.581787 
z"
        />
        <path
          fill="#33495B"
          opacity="1.000000"
          stroke="none"
          d="
M387.932617,3017.419922 
	C388.016144,3017.965576 387.041290,3018.012451 386.554108,3018.018311 
	C385.048553,3017.251221 384.012115,3016.153809 385.420380,3015.216064 
	C386.763855,3014.321777 387.472107,3015.651123 387.932617,3017.419922 
z"
        />
        <path
          fill="#4A3831"
          opacity="1.000000"
          stroke="none"
          d="
M708.078857,3022.871094 
	C715.634399,3019.906738 723.549683,3018.493896 730.598999,3015.217529 
	C739.090942,3011.271240 746.847168,3005.741699 755.470215,3000.496582 
	C757.198608,3001.232910 758.378723,3002.369873 759.829834,3004.064453 
	C759.512329,3010.921875 754.184082,3012.079102 749.017700,3014.423828 
	C739.043396,3018.372559 730.094299,3022.811279 720.812988,3026.481445 
	C717.132812,3027.936768 713.665527,3030.235352 708.779541,3028.569336 
	C708.156433,3028.150391 708.060974,3028.058105 708.012817,3028.011475 
	C707.994812,3026.508789 708.024963,3025.052979 708.078857,3022.871094 
z"
        />
        <path
          fill="#6C5246"
          opacity="1.000000"
          stroke="none"
          d="
M858.172607,3237.320068 
	C860.269714,3252.628174 860.323303,3267.412354 861.553223,3282.950195 
	C855.403748,3285.583740 856.093140,3280.595703 855.702515,3277.556152 
	C854.559875,3268.666992 852.725098,3259.928955 850.831116,3251.193848 
	C858.537537,3290.421631 863.519714,3330.135742 876.137756,3368.452148 
	C882.295288,3387.150879 885.342468,3407.100830 888.109558,3427.538574 
	C887.610718,3428.753662 887.344360,3429.206543 886.479919,3430.161621 
	C879.101868,3431.500488 878.255432,3426.041504 877.786133,3421.829590 
	C876.677795,3411.881592 872.203308,3402.946289 870.196472,3393.321045 
	C869.287659,3388.962158 866.812561,3386.365479 861.888306,3384.938965 
	C859.151062,3382.679688 857.944153,3380.276123 857.578735,3377.610596 
	C854.543884,3355.475586 844.075867,3335.546387 838.815613,3314.037598 
	C834.962952,3298.283936 831.981750,3282.397949 829.019104,3266.488281 
	C828.581909,3264.140869 827.585449,3262.091309 826.392395,3259.207520 
	C825.577454,3256.913330 825.358337,3255.315430 825.282776,3252.899170 
	C827.981750,3244.269287 825.658325,3237.563477 821.827698,3230.480225 
	C806.683228,3202.476807 791.260437,3174.429443 780.963562,3144.293945 
	C776.123169,3130.127686 771.828369,3115.947998 764.780640,3102.692871 
	C762.703003,3098.785645 762.999084,3094.296387 762.904236,3089.967529 
	C762.528748,3072.837646 755.084656,3064.883057 737.669678,3063.182129 
	C729.690552,3062.402832 721.791626,3061.572754 715.822205,3054.696289 
	C724.794006,3049.127441 733.523926,3053.565918 742.026489,3055.058838 
	C745.574402,3055.681885 749.040344,3055.743408 753.276855,3056.131592 
	C767.590820,3056.705322 775.149963,3066.096924 783.108154,3076.143066 
	C790.136841,3085.393311 795.417419,3094.929443 801.637817,3104.698730 
	C805.309143,3110.635986 809.359619,3115.524414 811.929932,3121.295654 
	C814.308289,3126.635498 819.100952,3128.801514 824.480225,3131.456543 
	C826.651428,3134.739746 826.754517,3137.567383 825.332214,3140.491455 
	C824.996277,3141.181641 824.131836,3141.304688 823.924927,3142.054199 
	C836.210999,3142.427734 828.089478,3150.035645 828.388855,3154.655518 
	C828.563660,3157.354492 829.623352,3159.798828 830.505493,3162.304199 
	C836.794617,3180.166748 843.856201,3197.729736 851.324341,3215.898438 
	C852.873047,3219.116455 853.943237,3221.765625 855.488647,3224.983398 
	C856.937927,3229.222412 858.585632,3232.699951 858.172607,3237.320068 
z"
        />
        <path
          fill="#BA815C"
          opacity="1.000000"
          stroke="none"
          d="
M863.606018,3519.286621 
	C858.667358,3515.870850 857.531738,3510.820557 854.912598,3505.857910 
	C852.968933,3504.514648 852.556519,3503.097168 852.573730,3500.749023 
	C854.046204,3497.357178 855.460876,3494.847656 854.640381,3491.552246 
	C852.984802,3484.903076 854.000000,3478.509033 856.232422,3471.995117 
	C859.395691,3462.764893 856.920715,3456.739990 848.510376,3451.934570 
	C844.369019,3449.568359 841.065430,3446.489990 837.861145,3443.043945 
	C832.985596,3437.800049 832.303223,3433.536377 834.603027,3425.697266 
	C840.272766,3406.371582 840.777161,3386.212646 833.939575,3366.575684 
	C827.711060,3348.687988 819.919983,3331.421875 813.007935,3313.805664 
	C810.163635,3306.556641 808.686035,3299.135742 809.150818,3291.348145 
	C809.680542,3282.471191 806.755920,3275.369141 799.734741,3269.445068 
	C791.806458,3262.756104 786.162842,3254.984131 784.023376,3243.773438 
	C782.091980,3233.653320 774.989197,3224.726074 772.258240,3214.333740 
	C767.176941,3194.997559 756.076843,3177.934814 751.763062,3158.042236 
	C750.063110,3150.203369 741.942017,3143.535400 741.786377,3134.082764 
	C730.722839,3128.941406 730.119202,3114.269287 718.570312,3108.586426 
	C717.814453,3107.643066 717.539917,3107.245117 717.012939,3106.169678 
	C715.404480,3104.197510 720.434448,3099.516357 713.800598,3100.611572 
	C710.833130,3101.101318 711.198425,3097.353271 712.205688,3095.235596 
	C714.800842,3089.778809 712.901672,3087.497070 707.248779,3086.406738 
	C699.193298,3084.852783 699.249512,3084.561523 701.521973,3076.521240 
	C689.774719,3075.649902 680.791016,3085.693604 668.531860,3084.805176 
	C667.530701,3085.297119 667.137024,3085.508545 666.155151,3086.041260 
	C658.913025,3089.799072 654.353394,3097.060059 645.140747,3097.238281 
	C643.381470,3097.268555 642.439575,3096.941895 641.127808,3095.717773 
	C639.720032,3092.884521 640.421143,3090.847656 641.539307,3088.067871 
	C645.689026,3082.151367 651.488037,3079.689453 657.326904,3076.138672 
	C660.317749,3073.677979 662.995667,3071.882568 665.931885,3069.377197 
	C666.572754,3069.124268 667.359924,3068.748535 667.716431,3068.497559 
	C669.277466,3066.299072 671.369690,3065.660889 673.858093,3064.501465 
	C674.454468,3064.275635 675.252991,3064.249756 675.632568,3064.125244 
	C676.627991,3063.341309 677.373779,3062.926025 678.921631,3062.880371 
	C679.962402,3063.146729 680.353516,3063.328857 681.251038,3063.888672 
	C684.559937,3067.243896 685.877075,3063.781738 688.386230,3062.556152 
	C691.723267,3061.151367 694.568970,3061.190918 698.142151,3061.463867 
	C705.877380,3060.985840 712.366943,3064.294434 720.023560,3064.327637 
	C721.705017,3064.363770 722.633118,3064.589844 724.093994,3065.404785 
	C725.366516,3072.089600 728.638062,3076.487793 733.384094,3080.650391 
	C738.949463,3085.531250 744.030579,3090.560791 746.388855,3098.746582 
	C748.783630,3107.059570 754.581482,3114.530029 759.381531,3122.131104 
	C769.054016,3137.447754 772.733887,3155.241455 778.410034,3172.184082 
	C779.706543,3176.054199 780.825684,3179.967285 784.261292,3182.372314 
	C794.113953,3189.270264 796.511169,3200.268799 799.917175,3210.631104 
	C801.745972,3216.195068 800.834717,3221.861084 798.265808,3227.169189 
	C792.957581,3238.136963 795.682251,3247.682373 804.120361,3257.771484 
	C801.421509,3252.081543 800.702881,3247.618164 801.752380,3243.009033 
	C802.795532,3238.428467 805.317017,3234.938721 810.306458,3234.489014 
	C815.171387,3234.050537 817.368530,3237.600342 818.904602,3241.474365 
	C823.097412,3252.047607 827.780762,3262.575439 820.626343,3273.846436 
	C818.947205,3276.491943 819.695435,3279.680664 820.449890,3283.361328 
	C820.792786,3285.537598 820.955994,3287.003906 820.933350,3289.231445 
	C822.079041,3308.591309 828.991699,3325.253906 838.774292,3340.767090 
	C842.915100,3347.333496 843.328247,3355.344727 848.307739,3362.222656 
	C849.153992,3363.707520 849.467041,3364.620117 849.692993,3366.277832 
	C848.667236,3371.856934 848.250916,3376.612061 852.661377,3381.104492 
	C853.326721,3382.557129 853.509888,3383.451416 853.516968,3385.064697 
	C851.375305,3391.193359 854.415894,3396.526123 853.854614,3402.605957 
	C853.912170,3403.725098 853.913269,3404.170898 853.876343,3405.276367 
	C852.995300,3408.694580 854.620605,3410.903564 855.598633,3413.947021 
	C855.741089,3415.523926 855.604004,3416.415527 855.015625,3417.895020 
	C849.709229,3423.062256 847.002075,3427.690430 853.724609,3433.127686 
	C855.265015,3434.374023 855.678833,3436.513672 855.612000,3439.176758 
	C855.356995,3440.493164 854.579590,3440.643799 854.266907,3441.204102 
	C854.900513,3441.271484 855.381958,3440.980225 856.130066,3439.797119 
	C865.680481,3435.404785 866.447632,3435.920166 869.092957,3444.441895 
	C875.842346,3466.185059 872.101746,3487.815918 868.669678,3510.239990 
	C867.894897,3513.019043 866.904663,3514.889160 865.898804,3517.601807 
	C865.140320,3518.692139 864.766174,3519.077393 863.606018,3519.286621 
z"
        />
        <path
          fill="#8C5B43"
          opacity="1.000000"
          stroke="none"
          d="
M724.392334,3066.630371 
	C723.071838,3066.053223 722.194336,3066.051758 720.658569,3066.048340 
	C714.892273,3064.121094 708.553833,3063.509277 709.409973,3055.080566 
	C711.375977,3054.290283 712.891541,3054.119873 715.169373,3053.937988 
	C723.424805,3057.372803 730.893433,3060.227539 739.507507,3060.208496 
	C750.128784,3060.184814 758.061768,3066.207520 764.570190,3074.189697 
	C765.927673,3075.854736 765.956970,3077.879883 765.973206,3079.876221 
	C766.008301,3084.201172 766.120422,3088.531250 765.969055,3092.850830 
	C765.813660,3097.287354 767.038513,3101.013184 769.210266,3105.032959 
	C775.325195,3116.349854 780.612671,3127.802979 782.795471,3141.027100 
	C784.207886,3149.583984 790.594543,3157.584717 793.365784,3166.322998 
	C799.904541,3186.940918 813.815674,3203.927246 821.219788,3224.093506 
	C822.455750,3227.459717 824.805908,3230.434082 826.755554,3233.517090 
	C830.209473,3238.979004 831.034912,3244.608398 827.996521,3251.336426 
	C822.602478,3254.511963 822.280334,3250.102539 821.602722,3247.173828 
	C818.078857,3231.942139 809.709717,3219.153809 801.604065,3206.170410 
	C797.030273,3198.843994 794.117188,3190.629395 790.118652,3182.966309 
	C787.327820,3177.617920 784.221436,3172.755615 780.392578,3168.053711 
	C774.264221,3160.527344 772.498901,3150.700439 769.498291,3141.753906 
	C764.223633,3126.026611 758.869202,3110.325439 752.283997,3095.139893 
	C749.333557,3088.336670 742.484070,3083.950195 737.296753,3078.586914 
	C733.399475,3074.557861 728.590332,3071.444824 724.392334,3066.630371 
z"
        />
        <path
          fill="#5E4237"
          opacity="1.000000"
          stroke="none"
          d="
M782.598267,3077.969482 
	C780.387146,3076.791504 778.473083,3075.785645 777.532532,3074.201660 
	C772.784973,3066.205566 766.027649,3061.185547 756.936890,3059.200439 
	C756.107300,3059.019531 755.464294,3057.984131 754.376038,3056.748535 
	C754.643311,3050.616455 752.824036,3048.187988 746.621338,3047.719482 
	C739.858582,3047.208496 732.787415,3045.687256 728.088135,3038.767822 
	C729.948364,3035.459473 732.783997,3035.135010 736.578430,3035.103760 
	C739.645752,3035.039551 741.862610,3035.043213 744.780090,3035.941162 
	C753.323364,3040.969482 762.626343,3041.061523 770.912170,3044.500488 
	C782.278381,3049.218018 792.862183,3055.292480 804.215332,3061.650879 
	C810.172363,3066.561279 815.109009,3071.332031 814.701660,3079.749023 
	C807.819153,3083.858643 801.397888,3080.753174 794.982605,3080.016846 
	C791.082458,3079.569336 787.415161,3077.596191 782.598267,3077.969482 
z"
        />
        <path
          fill="#765242"
          opacity="1.000000"
          stroke="none"
          d="
M738.405640,3037.517578 
	C735.180542,3038.023193 732.406677,3038.016602 728.799377,3038.026855 
	C722.634338,3038.360107 717.574585,3037.189209 712.685852,3035.178711 
	C710.195862,3034.154541 707.869934,3032.919922 708.109680,3028.888916 
	C721.250366,3023.553711 733.866272,3017.812988 747.224976,3012.051758 
	C750.920837,3016.714844 746.459412,3018.333008 744.143921,3020.698242 
	C740.873718,3024.038086 735.737000,3024.631836 732.361816,3028.282471 
	C734.216248,3031.494629 740.247742,3031.596924 738.405640,3037.517578 
z"
        />
        <path
          fill="#8C5B43"
          opacity="1.000000"
          stroke="none"
          d="
M700.670898,3063.730225 
	C696.558350,3063.994385 693.137878,3064.009033 688.862549,3064.031250 
	C686.042725,3065.664795 683.881165,3066.251709 680.671936,3064.821533 
	C679.918335,3064.358398 679.870605,3064.154053 679.931763,3064.068848 
	C679.042297,3061.139160 681.291809,3061.276123 683.453552,3060.418945 
	C690.897522,3058.210693 697.811401,3056.420898 705.482605,3054.755859 
	C709.391418,3060.456543 704.526245,3061.486084 700.670898,3063.730225 
z"
        />
        <path
          fill="#A36F51"
          opacity="1.000000"
          stroke="none"
          d="
M700.423279,3064.453613 
	C700.201782,3061.840332 701.271484,3060.427490 703.391846,3059.805664 
	C705.185974,3059.279785 705.757141,3057.783936 706.149353,3055.506348 
	C707.038269,3054.704346 707.836670,3054.527588 709.247681,3054.405762 
	C710.062622,3056.658203 710.053528,3059.036621 712.753723,3059.805420 
	C715.636963,3060.626709 717.713074,3062.420410 719.692627,3065.422852 
	C717.899658,3068.473145 714.850464,3068.790283 712.883850,3067.003906 
	C709.100891,3063.567627 704.930725,3065.142578 700.423279,3064.453613 
z"
        />
        <path
          fill="#8C5B43"
          opacity="1.000000"
          stroke="none"
          d="
M665.907349,3068.885254 
	C665.879578,3073.196777 664.032288,3076.040527 658.643555,3075.846191 
	C657.959473,3074.973145 658.003357,3074.143311 658.078613,3072.690918 
	C659.972717,3069.855469 662.333069,3068.679199 665.907349,3068.885254 
z"
        />
        <path
          fill="#5E4237"
          opacity="1.000000"
          stroke="none"
          d="
M683.240967,3060.000488 
	C682.860779,3061.007324 681.738037,3062.014404 680.304077,3063.502441 
	C679.107483,3064.028564 678.222107,3064.073730 676.674438,3064.059814 
	C676.446960,3059.866699 679.558533,3060.072510 683.240967,3060.000488 
z"
        />
        <path
          fill="#5E4237"
          opacity="1.000000"
          stroke="none"
          d="
M673.701904,3064.199219 
	C673.336243,3066.313965 671.680481,3067.646729 668.728516,3068.192627 
	C668.185242,3065.082520 670.046448,3063.986572 673.701904,3064.199219 
z"
        />
        <path
          fill="#FBC18E"
          opacity="1.000000"
          stroke="none"
          d="
M621.014038,3265.965820 
	C618.149841,3247.971924 619.035339,3230.050293 621.154846,3211.365723 
	C620.409424,3208.944092 619.472473,3207.397461 619.240906,3204.696777 
	C621.937683,3191.375000 622.707275,3178.733643 623.253052,3165.199707 
	C623.149231,3152.171631 623.113098,3139.955566 621.717163,3127.872803 
	C620.818848,3120.097656 622.039551,3113.423096 627.499817,3106.721680 
	C628.959412,3104.197510 629.977295,3102.413818 631.498779,3100.268555 
	C634.730286,3100.157959 636.500061,3101.927734 638.643433,3104.416504 
	C639.329224,3105.455566 639.567993,3105.892090 640.084229,3107.015625 
	C640.713867,3111.836914 643.774597,3114.641113 646.664368,3116.281738 
	C659.278625,3123.443115 658.885803,3139.174072 668.428406,3148.323486 
	C670.728333,3150.528320 668.783142,3154.050537 667.648010,3156.829590 
	C663.405334,3167.217285 661.522522,3177.611572 664.303467,3188.970215 
	C666.191040,3196.680176 665.582642,3204.910889 663.847595,3212.804199 
	C661.843933,3221.919189 660.640625,3231.241943 658.368164,3240.225342 
	C655.053223,3253.330322 649.131104,3265.217773 633.912720,3269.574707 
	C630.402222,3268.105469 630.471191,3265.719482 631.053833,3262.528320 
	C631.374084,3261.408203 631.515198,3260.968262 631.891602,3259.886963 
	C632.465698,3258.406494 632.765015,3257.553711 633.120972,3256.013672 
	C633.451111,3252.340576 634.599121,3249.532227 635.078064,3246.598389 
	C635.587219,3243.479980 637.502502,3238.968018 633.469421,3237.866943 
	C628.675720,3236.558350 626.558960,3240.438965 626.346191,3245.773926 
	C625.387329,3253.129395 627.839783,3260.545410 621.014038,3265.965820 
z"
        />
        <path
          fill="#765242"
          opacity="1.000000"
          stroke="none"
          d="
M579.977051,3098.569336 
	C582.769104,3097.751709 583.853149,3096.048584 584.160645,3092.876465 
	C592.005432,3092.162109 595.934265,3086.907227 599.812744,3079.288086 
	C601.798157,3085.909180 604.784058,3087.878662 610.719543,3086.260498 
	C615.949707,3084.834961 621.987915,3086.971680 626.900940,3082.922119 
	C627.864136,3082.128174 629.499939,3082.805664 630.428162,3083.931885 
	C631.324524,3085.019775 631.721252,3086.294189 630.882324,3087.576904 
	C628.341553,3091.461914 625.908936,3095.406494 622.489136,3098.661865 
	C619.525757,3101.482178 619.231018,3104.369629 623.615356,3107.243896 
	C625.083252,3112.088623 622.721069,3115.279785 619.906799,3119.016602 
	C616.887939,3121.674805 614.191345,3124.329590 610.715698,3122.416748 
	C607.539246,3120.668701 608.507812,3117.197021 609.107361,3114.347168 
	C609.983765,3110.181152 609.283875,3108.610840 604.304688,3108.709229 
	C598.934814,3108.815430 593.824585,3104.813477 587.996887,3108.838135 
	C585.868286,3110.308838 582.602417,3107.312012 579.843506,3104.871582 
	C579.294312,3103.854004 579.185974,3103.392578 579.393555,3102.294434 
	C579.779663,3100.833984 579.849854,3100.010498 579.977051,3098.569336 
z"
        />
        <path
          fill="#BA815C"
          opacity="1.000000"
          stroke="none"
          d="
M617.572327,3118.636719 
	C619.736023,3114.924561 621.467468,3111.849365 623.627197,3108.256104 
	C625.007202,3107.253662 625.958801,3106.769043 627.425964,3106.132812 
	C628.312805,3108.417480 627.501648,3110.597412 626.393127,3112.675537 
	C623.917053,3117.317139 623.556641,3121.970459 624.996948,3127.142334 
	C625.953979,3130.578613 625.708435,3134.396240 625.707947,3138.043213 
	C625.706848,3146.341797 625.840027,3154.619385 627.045654,3163.449463 
	C617.831116,3165.152100 612.516296,3157.709961 605.282959,3154.827881 
	C603.004761,3153.920166 604.599792,3149.351318 605.676758,3146.565674 
	C609.236572,3137.359131 613.320984,3128.371826 617.572327,3118.636719 
z"
        />
        <path
          fill="#D99469"
          opacity="1.000000"
          stroke="none"
          d="
M638.793213,3105.516602 
	C635.637695,3104.828369 633.967285,3103.033691 632.219482,3100.488770 
	C632.918457,3094.511719 637.297607,3091.657227 641.271973,3087.695557 
	C641.902954,3089.687988 641.945801,3092.059082 641.998779,3095.310547 
	C644.635315,3100.168945 642.507507,3102.855469 638.793213,3105.516602 
z"
        />
        <path
          fill="#EAE6D7"
          opacity="1.000000"
          stroke="none"
          d="
M1304.505127,3837.294434 
	C1302.705444,3837.980957 1301.376587,3837.999268 1300.047852,3838.007568 
	C1296.982300,3838.027100 1293.732788,3838.103516 1292.646851,3841.645996 
	C1291.507446,3845.362793 1294.048706,3847.024658 1297.124634,3848.144775 
	C1299.382568,3848.967041 1300.203003,3850.643066 1299.201660,3852.841797 
	C1298.088867,3855.285156 1295.955933,3856.367432 1293.487915,3855.334961 
	C1291.717163,3854.593506 1290.250488,3853.088623 1288.708252,3851.849121 
	C1287.283936,3850.704346 1285.480469,3850.003906 1284.204712,3851.283447 
	C1278.833496,3856.670410 1272.188843,3860.657471 1267.400635,3866.660156 
	C1266.531128,3867.750244 1266.084106,3869.061035 1266.901001,3870.318359 
	C1267.688965,3871.531006 1269.056885,3871.984619 1270.374390,3871.447510 
	C1276.942139,3868.769775 1284.284546,3870.490479 1290.964233,3865.522949 
	C1295.329712,3862.276123 1303.147217,3861.303467 1309.967407,3863.538086 
	C1316.370483,3865.635742 1319.302856,3868.807373 1319.266357,3875.297852 
	C1319.253174,3877.642334 1318.068359,3879.107422 1315.870850,3879.460693 
	C1313.348511,3879.866455 1312.388428,3878.232666 1312.082031,3875.980225 
	C1311.860352,3874.350586 1311.814087,3872.596924 1311.147827,3871.147705 
	C1309.603394,3867.789551 1306.057861,3867.373779 1304.429932,3870.582764 
	C1303.044678,3873.313721 1300.517578,3875.315430 1299.500854,3877.939453 
	C1293.926880,3892.324707 1281.580200,3889.379883 1270.499878,3889.637451 
	C1268.919434,3889.674561 1267.310181,3888.371582 1265.726685,3888.399414 
	C1259.927246,3888.500488 1256.939941,3886.038086 1257.939697,3879.505127 
	C1252.263428,3880.005859 1248.981689,3882.879150 1246.118286,3887.320801 
	C1242.928833,3889.651611 1239.857544,3891.312988 1236.393066,3893.487061 
	C1228.492432,3893.399658 1227.811890,3892.204102 1227.991577,3879.817383 
	C1221.952881,3880.101562 1222.893311,3885.654053 1220.498779,3889.511963 
	C1219.118774,3890.011230 1218.227661,3890.012939 1216.668457,3890.017090 
	C1214.248779,3889.962158 1212.469360,3890.031250 1210.479980,3888.446289 
	C1209.756958,3881.057129 1216.137939,3877.341797 1218.033691,3871.449219 
	C1225.868530,3869.824707 1223.244873,3861.661133 1226.770996,3857.226562 
	C1228.577881,3854.954590 1225.986206,3852.379150 1223.606689,3852.436279 
	C1214.221558,3852.660889 1206.965820,3846.632812 1200.264648,3842.278076 
	C1192.113892,3836.981445 1185.947876,3828.009277 1180.750977,3819.165771 
	C1179.865479,3817.658936 1180.177490,3815.916992 1180.848389,3814.344482 
	C1181.746948,3812.238525 1181.536011,3809.846436 1179.690186,3808.991211 
	C1171.141479,3805.029541 1170.589844,3793.934326 1162.249390,3789.870850 
	C1160.228271,3788.885986 1160.262329,3786.622803 1159.779541,3784.696045 
	C1158.560669,3779.831299 1156.961914,3775.161377 1152.888428,3771.759521 
	C1151.913208,3770.945068 1150.616577,3769.540283 1150.729370,3768.550537 
	C1151.733643,3759.738770 1145.406494,3752.375732 1145.991943,3742.855469 
	C1150.025024,3737.786133 1153.503540,3732.466064 1152.228638,3726.984619 
	C1149.313354,3714.449219 1154.197144,3705.933105 1164.248901,3698.406250 
	C1171.277466,3693.785645 1172.964355,3699.050049 1175.665161,3703.801514 
	C1178.313354,3706.915771 1180.078125,3709.741211 1181.331421,3713.655762 
	C1179.978638,3724.449219 1169.950195,3727.229004 1165.221191,3733.653564 
	C1167.223389,3732.050049 1169.575439,3729.657471 1172.500244,3728.141113 
	C1178.096069,3725.239502 1183.520386,3725.901367 1185.865967,3731.984619 
	C1189.240723,3740.736328 1195.972046,3742.520264 1203.991333,3743.253662 
	C1211.853638,3743.972900 1220.090332,3742.793457 1227.262817,3747.510742 
	C1230.361572,3749.548584 1233.806885,3751.343262 1233.376831,3755.781738 
	C1232.996338,3759.709961 1229.911377,3760.805664 1226.636597,3762.135254 
	C1218.795654,3765.319336 1218.253662,3769.065674 1224.257446,3775.196533 
	C1228.821289,3779.856934 1227.316650,3784.344971 1220.602417,3787.190918 
	C1217.871704,3788.348389 1212.241211,3786.314453 1212.867554,3791.104004 
	C1213.516479,3796.066406 1215.080566,3802.501953 1220.483887,3804.244629 
	C1226.153320,3806.073730 1231.275635,3809.242920 1237.285889,3810.279541 
	C1247.619629,3812.061768 1248.524048,3814.243408 1244.032837,3823.476807 
	C1243.061523,3825.473633 1240.977417,3827.145996 1241.995361,3829.982910 
	C1248.233032,3830.437012 1252.350098,3822.133301 1259.457275,3825.829346 
	C1265.323853,3828.880127 1263.043945,3837.397705 1269.299072,3840.072510 
	C1272.535034,3838.893066 1270.180298,3836.651611 1270.377686,3835.005371 
	C1271.335938,3827.012451 1275.454346,3823.822998 1283.141846,3825.842285 
	C1287.416626,3826.965088 1291.570801,3827.717041 1296.677979,3826.559082 
	C1302.987671,3827.612305 1306.384888,3830.329590 1304.505127,3837.294434 
z"
        />
        <path
          fill="#EAE6D7"
          opacity="1.000000"
          stroke="none"
          d="
M1111.175049,3548.825928 
	C1116.083740,3548.156494 1118.336914,3547.019531 1116.478638,3542.329346 
	C1115.909546,3540.892578 1116.141113,3539.138428 1116.005493,3536.760010 
	C1122.659790,3533.395020 1123.341187,3538.896484 1124.188477,3542.707275 
	C1126.964844,3555.195312 1133.753906,3566.506348 1133.624756,3579.960938 
	C1133.548218,3587.941162 1139.725952,3593.379639 1146.038696,3598.289795 
	C1155.436279,3605.598877 1155.065186,3607.989502 1145.100098,3614.371582 
	C1140.978149,3617.011475 1137.592041,3620.479980 1133.204834,3623.026123 
	C1126.444336,3626.949707 1134.434082,3628.587646 1136.672852,3631.612793 
	C1137.628052,3632.311035 1137.983521,3632.625000 1138.759033,3633.511719 
	C1138.741943,3643.140869 1139.730469,3651.887451 1144.801514,3660.400879 
	C1144.679199,3662.507324 1142.858765,3664.796875 1147.147461,3664.375000 
	C1153.930054,3667.720459 1159.788696,3672.716553 1166.741577,3665.386963 
	C1168.475586,3663.559326 1171.927734,3663.772705 1174.009033,3666.058350 
	C1176.107300,3668.362305 1175.511475,3671.326660 1174.035767,3673.499512 
	C1169.729492,3679.838379 1166.760376,3686.812988 1163.117676,3694.218262 
	C1157.358276,3696.906250 1151.627441,3696.399658 1146.155396,3697.451416 
	C1140.797852,3698.480957 1131.776123,3688.638916 1132.784790,3681.641357 
	C1134.152832,3672.149658 1129.361694,3668.417236 1121.406250,3665.167969 
	C1115.720703,3659.970215 1114.177490,3654.031494 1115.519775,3646.646484 
	C1115.447998,3644.081543 1114.892822,3642.177002 1114.370483,3640.263428 
	C1112.362183,3632.905273 1112.365967,3632.904297 1104.414062,3632.491211 
	C1102.452148,3629.983398 1103.150146,3627.649414 1103.586426,3624.673828 
	C1108.808838,3625.649658 1112.826538,3625.734375 1113.919312,3618.685547 
	C1118.080811,3618.192627 1119.756226,3616.392334 1120.085327,3612.170654 
	C1120.789185,3603.140137 1118.692993,3594.442627 1117.389771,3585.684082 
	C1116.679077,3580.907715 1114.083496,3576.511230 1115.162598,3571.298096 
	C1115.506226,3569.637939 1114.515503,3566.488281 1113.266113,3565.915283 
	C1106.851562,3562.973145 1108.908325,3558.214111 1109.981689,3552.777588 
	C1110.024658,3551.210693 1110.139893,3550.424561 1111.175049,3548.825928 
z"
        />
        <path
          fill="#EAE5D5"
          opacity="1.000000"
          stroke="none"
          d="
M1121.542358,3664.572998 
	C1126.567871,3663.665771 1130.564941,3664.335205 1132.785767,3669.141113 
	C1134.061768,3671.902344 1138.012085,3672.125732 1139.093994,3675.230957 
	C1133.990356,3680.812012 1134.024780,3680.790283 1138.297607,3687.556152 
	C1139.166382,3688.931641 1139.668579,3690.538818 1140.337402,3692.040039 
	C1141.790527,3695.302002 1144.630615,3697.210205 1147.377563,3694.836670 
	C1151.854370,3690.967285 1156.079712,3693.378906 1161.242920,3693.982422 
	C1162.372803,3693.722900 1162.735840,3693.446045 1163.773193,3693.299805 
	C1165.678101,3694.211914 1166.409912,3695.316895 1166.306641,3697.382568 
	C1166.353394,3702.158447 1164.423096,3704.608887 1160.794312,3706.708740 
	C1153.137573,3711.139648 1152.814331,3711.564941 1155.219360,3720.194580 
	C1156.974609,3726.492432 1154.751709,3732.022217 1153.601440,3737.801758 
	C1152.996826,3740.839600 1150.473511,3741.885010 1146.770020,3741.986084 
	C1145.990234,3742.009766 1146.000000,3742.000000 1145.997070,3742.004883 
	C1144.105225,3740.025146 1144.433594,3737.073730 1142.454346,3734.465332 
	C1140.512085,3731.206299 1139.499878,3728.062500 1136.624878,3726.256104 
	C1134.046021,3724.635986 1134.012207,3722.093506 1133.997559,3718.764648 
	C1131.165771,3712.248779 1127.009033,3707.196289 1124.416504,3700.530273 
	C1117.551514,3694.735840 1111.435181,3689.018311 1102.732178,3686.012695 
	C1101.992310,3684.500000 1101.983765,3683.000000 1101.978027,3680.750000 
	C1106.474121,3674.690674 1112.121460,3674.834717 1118.131470,3676.536621 
	C1120.726929,3677.271729 1123.770996,3678.462158 1122.112549,3672.863770 
	C1121.062744,3669.913330 1120.222900,3667.620605 1121.542358,3664.572998 
z"
        />
        <path
          fill="#F0EBDD"
          opacity="1.000000"
          stroke="none"
          d="
M1164.774292,3893.868408 
	C1164.721558,3891.200684 1166.117065,3890.687500 1167.766235,3890.022461 
	C1175.035400,3887.091309 1175.503174,3884.275879 1169.911987,3878.917236 
	C1167.447266,3876.555176 1164.535156,3875.053223 1161.323853,3874.084473 
	C1159.351318,3873.489746 1157.061768,3872.764160 1157.093140,3870.530273 
	C1157.130981,3867.838867 1159.749512,3868.190430 1161.665039,3868.010498 
	C1162.325439,3867.948242 1162.992432,3867.960205 1163.653809,3867.904785 
	C1165.379028,3867.760010 1167.008667,3867.330078 1167.432495,3865.375732 
	C1167.823120,3863.574951 1166.971191,3862.107666 1165.508423,3861.149414 
	C1164.413330,3860.432129 1163.004028,3860.174072 1161.962158,3859.403564 
	C1161.253906,3858.880127 1160.567383,3857.846924 1160.515991,3857.002686 
	C1160.348633,3854.245117 1170.783569,3846.157959 1173.422485,3846.971191 
	C1177.290894,3848.163574 1178.913696,3851.003662 1177.386230,3854.673828 
	C1175.131104,3860.092529 1177.387695,3861.981445 1182.412598,3862.468506 
	C1183.368652,3862.561035 1184.250366,3863.266113 1185.212646,3863.473877 
	C1189.101929,3864.313477 1190.557617,3866.454346 1187.874268,3869.710449 
	C1184.831909,3873.402588 1183.630737,3875.412598 1189.237915,3878.437744 
	C1196.376587,3882.289795 1195.115479,3896.262207 1187.950928,3900.705811 
	C1185.684326,3902.111328 1183.246338,3902.140137 1181.437134,3900.564941 
	C1176.671265,3896.415283 1170.510498,3896.479736 1164.774292,3893.868408 
z"
        />
        <path
          fill="#F0EBDD"
          opacity="1.000000"
          stroke="none"
          d="
M1110.569458,3942.477783 
	C1110.006714,3941.146484 1109.989380,3940.254150 1109.953247,3938.693848 
	C1113.085205,3934.984619 1112.453125,3931.077148 1111.426636,3927.714355 
	C1109.646973,3921.884766 1112.014160,3915.902100 1110.146484,3910.137695 
	C1109.793213,3909.047119 1110.361694,3907.447754 1110.946167,3906.310303 
	C1111.926514,3904.401367 1111.404419,3900.968018 1114.550415,3901.071289 
	C1117.368286,3901.163818 1117.499512,3904.152100 1118.382568,3906.269287 
	C1121.505371,3913.756592 1125.096802,3921.019043 1131.139038,3926.642578 
	C1133.160156,3928.523438 1133.751953,3930.300537 1132.692749,3932.780518 
	C1131.223511,3936.221680 1133.679077,3938.399658 1135.694702,3941.426270 
	C1131.662598,3947.105957 1124.620239,3948.525391 1120.379517,3953.939453 
	C1118.056519,3956.905029 1115.362427,3955.056396 1114.495239,3952.096680 
	C1113.569702,3948.938232 1113.301758,3945.597412 1110.569458,3942.477783 
z"
        />
        <path
          fill="#EAE6D7"
          opacity="1.000000"
          stroke="none"
          d="
M1123.528809,3671.524902 
	C1124.826904,3673.021729 1125.775635,3673.966064 1126.428467,3675.083008 
	C1127.441650,3676.816162 1128.475098,3678.895752 1126.903442,3680.607666 
	C1125.088135,3682.584961 1122.432129,3682.351074 1120.520752,3680.891846 
	C1115.020996,3676.692627 1109.367554,3677.686279 1102.768677,3679.891846 
	C1102.306152,3676.792236 1103.165649,3673.828857 1102.709473,3670.319580 
	C1101.732910,3662.805664 1102.072021,3655.112061 1102.028076,3647.491211 
	C1102.017456,3645.643555 1101.218140,3643.532959 1103.107422,3641.814697 
	C1106.290405,3641.795410 1107.515747,3645.682129 1111.319214,3645.916992 
	C1113.229004,3647.192139 1114.397949,3648.801514 1113.879883,3650.326660 
	C1110.546265,3660.141357 1116.609253,3665.648193 1123.528809,3671.524902 
z"
        />
        <path
          fill="#F0EBDD"
          opacity="1.000000"
          stroke="none"
          d="
M1104.000244,3858.347168 
	C1104.399536,3853.386719 1102.970215,3849.063965 1105.142700,3845.381104 
	C1107.699585,3845.727539 1107.673340,3847.166016 1108.041626,3848.368652 
	C1110.111694,3855.132568 1109.811890,3861.814453 1109.165283,3868.884521 
	C1108.594849,3875.121582 1113.236816,3881.306396 1112.022705,3888.153564 
	C1111.622681,3890.410156 1112.033325,3893.278320 1108.869263,3893.412598 
	C1105.595825,3893.551514 1104.207642,3891.022461 1104.158813,3888.137451 
	C1103.995850,3878.523926 1104.037354,3868.906982 1104.000244,3858.347168 
z"
        />
        <path
          fill="#F0EBDD"
          opacity="1.000000"
          stroke="none"
          d="
M1220.632080,3890.368652 
	C1219.816162,3888.369385 1219.932861,3886.612061 1219.366821,3885.112305 
	C1218.047119,3881.615234 1218.848267,3879.328125 1222.477051,3878.157959 
	C1223.420898,3877.853760 1224.381226,3877.558105 1225.265503,3877.120117 
	C1227.532593,3875.996582 1229.711914,3872.873535 1232.291992,3874.942383 
	C1235.719604,3877.690430 1231.999756,3880.108887 1230.970215,3882.653564 
	C1229.096191,3887.284912 1230.402100,3891.006104 1235.294189,3893.860840 
	C1237.026611,3895.219238 1238.053345,3896.438477 1239.815552,3898.531250 
	C1235.281128,3899.187256 1231.188599,3897.782471 1227.410034,3899.475098 
	C1225.819092,3900.187988 1224.457031,3898.468750 1224.186890,3896.668457 
	C1223.839111,3894.350586 1223.334839,3892.165283 1220.632080,3890.368652 
z"
        />
        <path
          fill="#F4EEDC"
          opacity="1.000000"
          stroke="none"
          d="
M1123.931396,3671.312256 
	C1119.605469,3671.421875 1115.849731,3669.498291 1113.259521,3665.916016 
	C1110.289673,3661.808838 1106.475098,3657.709961 1111.411743,3652.276855 
	C1112.287231,3651.313232 1111.834839,3649.143066 1111.997803,3646.765137 
	C1112.886353,3645.998535 1113.772827,3645.997070 1115.323730,3645.992920 
	C1117.531982,3651.640137 1118.163330,3657.585693 1121.698730,3663.344238 
	C1122.617310,3666.208252 1123.234619,3668.416260 1123.931396,3671.312256 
z"
        />
        <path
          fill="#EAE5D5"
          opacity="1.000000"
          stroke="none"
          d="
M1104.031250,3623.083740 
	C1104.021484,3626.098877 1104.008789,3628.256592 1103.991333,3631.223145 
	C1099.957642,3621.587402 1099.285034,3610.871826 1101.471191,3599.907227 
	C1104.188721,3607.136963 1104.011353,3614.692871 1104.031250,3623.083740 
z"
        />
        <path
          fill="#EAE6D7"
          opacity="1.000000"
          stroke="none"
          d="
M1123.998535,3700.752197 
	C1128.606934,3704.709961 1134.056763,3708.925293 1133.991943,3717.253662 
	C1127.423706,3714.474365 1124.977417,3708.440674 1123.998535,3700.752197 
z"
        />
        <path
          fill="#F4EEDC"
          opacity="1.000000"
          stroke="none"
          d="
M1104.516602,3594.008301 
	C1104.489502,3592.491943 1105.239746,3590.990234 1105.323730,3589.452148 
	C1105.430786,3587.492676 1103.298096,3584.436279 1107.294556,3584.617676 
	C1110.323120,3584.754883 1109.883423,3587.738525 1109.875000,3589.948730 
	C1109.868286,3591.706055 1109.075317,3593.006348 1106.675537,3593.839355 
	C1105.960938,3594.000000 1104.998047,3594.007080 1104.516602,3594.008301 
z"
        />
        <path
          fill="#F0EBDD"
          opacity="1.000000"
          stroke="none"
          d="
M1220.763062,3911.835693 
	C1218.508911,3909.957031 1218.854492,3908.412354 1221.092285,3908.492432 
	C1223.908813,3908.593018 1226.761353,3909.403809 1229.238525,3911.991943 
	C1226.440552,3915.146240 1223.868042,3912.447998 1220.763062,3911.835693 
z"
        />
        <path
          fill="#F0EBDD"
          opacity="1.000000"
          stroke="none"
          d="
M1152.287842,3857.721680 
	C1153.601318,3859.654297 1154.457153,3861.724365 1152.711914,3862.497803 
	C1150.486938,3863.484131 1146.526367,3859.528809 1147.509644,3857.300049 
	C1148.276489,3855.562012 1150.354126,3856.404053 1152.287842,3857.721680 
z"
        />
        <path
          fill="#F0EBDD"
          opacity="1.000000"
          stroke="none"
          d="
M1102.907837,3815.856689 
	C1103.909668,3818.962891 1104.666748,3821.452148 1102.912476,3824.349121 
	C1101.010376,3820.269043 1099.047241,3816.596924 1100.629028,3812.136475 
	C1102.243286,3812.707764 1102.156128,3814.148682 1102.907837,3815.856689 
z"
        />
        <path
          fill="#F0EBDD"
          opacity="1.000000"
          stroke="none"
          d="
M1105.367432,3833.085449 
	C1105.726196,3835.006348 1105.733887,3836.545410 1104.167969,3836.777832 
	C1102.353638,3837.047363 1102.144897,3835.294189 1102.168457,3834.066895 
	C1102.194336,3832.729248 1101.028320,3830.689697 1103.453491,3830.323730 
	C1103.901978,3830.256104 1104.583130,3831.731201 1105.367432,3833.085449 
z"
        />
        <path
          fill="#EAE6D7"
          opacity="1.000000"
          stroke="none"
          d="
M1142.008301,3734.694824 
	C1146.122925,3734.461426 1145.984863,3737.533936 1145.993652,3741.263184 
	C1143.161133,3740.708740 1141.934204,3738.442627 1142.008301,3734.694824 
z"
        />
        <path
          fill="#F0EBDD"
          opacity="1.000000"
          stroke="none"
          d="
M1210.107300,3888.625488 
	C1211.771851,3888.048584 1213.587402,3887.862549 1215.568726,3889.515625 
	C1213.728516,3892.372314 1211.735352,3892.600098 1210.107300,3888.625488 
z"
        />
        <path
          fill="#EAE6D7"
          opacity="1.000000"
          stroke="none"
          d="
M1102.000000,3686.000000 
	C1101.838257,3686.066895 1101.676636,3686.134033 1101.757812,3686.100586 
	C1102.000732,3686.000000 1102.000000,3686.000000 1102.000000,3686.000000 
z"
        />
        <path
          fill="#F0EBDD"
          opacity="1.000000"
          stroke="none"
          d="
M1108.683228,3959.877930 
	C1108.088013,3957.633545 1107.584229,3955.180420 1109.467041,3952.487793 
	C1112.549194,3954.808838 1113.185913,3957.460205 1108.683228,3959.877930 
z"
        />
        <path
          fill="#F0EBDD"
          opacity="1.000000"
          stroke="none"
          d="
M1102.627930,3963.888184 
	C1102.269775,3961.088135 1103.974487,3959.979980 1107.316895,3960.018555 
	C1107.775879,3962.896240 1106.051636,3964.000977 1102.627930,3963.888184 
z"
        />
        <path
          fill="#F0EBDD"
          opacity="1.000000"
          stroke="none"
          d="
M1306.687866,3932.971924 
	C1304.270752,3931.116455 1301.539307,3930.340088 1300.794800,3927.163086 
	C1304.742554,3925.573486 1307.406982,3927.070068 1309.265381,3929.886963 
	C1310.318848,3931.484863 1309.368042,3932.920166 1306.687866,3932.971924 
z"
        />
        <path
          fill="#233444"
          opacity="1.000000"
          stroke="none"
          d="
M496.538940,3164.516113 
	C497.525238,3160.829102 504.141327,3163.229004 503.210541,3158.835449 
	C502.311768,3154.593262 497.008179,3153.908447 493.278076,3154.225098 
	C483.094574,3155.089600 473.221863,3151.665527 462.786530,3153.609131 
	C452.830719,3155.463135 442.347260,3154.004395 432.445526,3151.099365 
	C425.547333,3149.075439 418.590302,3150.503906 410.767517,3149.743896 
	C410.560974,3146.945068 412.494751,3145.820557 415.081726,3146.079102 
	C425.454865,3147.115967 435.516174,3143.803955 445.823242,3143.980957 
	C448.794037,3144.031982 451.848145,3144.315918 454.212585,3141.788086 
	C455.132904,3140.804199 456.847595,3139.763184 457.745087,3140.589600 
	C463.759277,3146.125977 471.132782,3142.188232 477.665436,3142.964600 
	C484.088165,3143.727783 491.851074,3145.423340 498.349640,3140.661621 
	C499.720306,3139.657227 502.217041,3140.051025 504.196442,3140.038818 
	C514.187988,3139.977295 524.180115,3139.979492 534.171997,3139.995605 
	C536.487122,3139.999268 538.891968,3139.709961 541.013123,3140.914062 
	C542.157593,3141.563477 543.126770,3142.495361 543.086487,3144.053711 
	C543.046997,3145.579102 542.125977,3146.549805 540.949097,3147.146240 
	C538.470093,3148.403076 535.732544,3147.947998 533.087891,3148.000732 
	C529.766418,3148.066650 526.445374,3147.843750 523.307495,3149.407959 
	C520.111633,3151.000977 517.273865,3152.861572 518.686584,3156.989258 
	C519.846741,3160.379395 518.409546,3162.708008 515.524841,3162.950928 
	C509.348145,3163.470947 503.571320,3167.607666 496.538940,3164.516113 
z"
        />
        <path
          fill="#233444"
          opacity="1.000000"
          stroke="none"
          d="
M551.547852,3170.559082 
	C554.814331,3174.706299 555.870789,3179.313965 550.713623,3183.808594 
	C550.380798,3179.681152 549.130920,3175.270020 551.547852,3170.559082 
z"
        />
        <path
          fill="#2B3E51"
          opacity="1.000000"
          stroke="none"
          d="
M350.087036,3171.128418 
	C345.804199,3172.042236 341.617065,3171.890137 337.718140,3174.670654 
	C333.757172,3177.495117 328.664246,3175.727295 324.102203,3176.789062 
	C315.088165,3178.886719 305.943695,3180.413818 296.543640,3180.062256 
	C286.602539,3179.690674 276.894196,3182.645752 266.818359,3182.193359 
	C258.843231,3181.835449 251.212006,3180.244385 242.062347,3179.067871 
	C245.955826,3174.665283 250.037354,3174.084717 254.175903,3174.058838 
	C261.125977,3174.014893 268.126831,3173.366455 274.938934,3175.672119 
	C276.371490,3176.156982 278.481995,3176.022949 278.782471,3173.772217 
	C279.053497,3171.741699 277.407043,3170.595947 275.795715,3170.480713 
	C270.830933,3170.125977 265.838715,3170.175537 260.861481,3169.965088 
	C258.636169,3169.871094 255.752228,3170.620361 254.814545,3167.784668 
	C253.711304,3164.447998 257.014221,3164.364746 258.871857,3163.326172 
	C260.417480,3162.461670 261.353729,3161.175781 261.920654,3158.783203 
	C268.747131,3158.036621 275.455872,3158.002197 283.080200,3157.962158 
	C293.858917,3157.543701 304.402130,3160.987305 313.452393,3152.429443 
	C313.999939,3152.000000 314.997314,3151.962158 315.496307,3151.954590 
	C316.715790,3152.063965 317.295929,3152.419922 317.880524,3153.494629 
	C318.855896,3158.801514 322.674683,3158.161377 325.847717,3157.951660 
	C337.084106,3157.208740 348.294891,3159.619629 359.533112,3158.085449 
	C361.392975,3157.831787 363.422028,3157.876709 365.302551,3160.051514 
	C362.516785,3162.694824 359.365845,3163.465576 356.213013,3163.920898 
	C352.339111,3164.480469 350.102753,3166.293213 350.087036,3171.128418 
z"
        />
        <path
          fill="#233444"
          opacity="1.000000"
          stroke="none"
          d="
M313.194031,3152.015137 
	C308.905731,3160.269531 302.367767,3164.776123 292.521027,3159.870361 
	C289.963196,3158.596191 287.255585,3160.036865 284.393494,3158.429688 
	C285.693848,3155.981934 288.021637,3155.842773 290.314484,3156.031494 
	C298.044373,3156.667480 304.981964,3153.081543 313.194031,3152.015137 
z"
        />
        <path
          fill="#233444"
          opacity="1.000000"
          stroke="none"
          d="
M318.432251,3153.774902 
	C317.381256,3153.752441 316.866760,3153.354980 316.228455,3152.381592 
	C316.892426,3150.676758 317.945068,3150.067139 319.697601,3151.568848 
	C319.774323,3152.620850 319.394135,3153.137939 318.432251,3153.774902 
z"
        />
        <path
          fill="#233444"
          opacity="1.000000"
          stroke="none"
          d="
M374.348969,3152.369141 
	C375.176788,3150.261719 376.887909,3150.014648 379.391418,3150.142334 
	C378.765625,3152.092041 377.680542,3154.413574 374.348969,3152.369141 
z"
        />
        <path
          fill="#935F47"
          opacity="1.000000"
          stroke="none"
          d="
M596.448425,3205.349609 
	C590.103882,3209.831055 584.270935,3207.400879 578.532288,3205.585693 
	C574.610962,3204.345459 574.680603,3204.125244 570.948059,3208.441406 
	C567.424316,3207.724121 567.575867,3202.802002 563.382202,3202.052246 
	C562.604736,3201.052734 562.411499,3200.184814 562.193604,3198.649170 
	C562.090271,3197.089844 562.012085,3196.198242 561.866516,3194.638916 
	C561.769226,3193.162598 561.740051,3192.353760 561.696594,3190.938477 
	C559.810791,3188.220703 560.344727,3185.268311 558.728516,3182.390869 
	C558.262329,3176.498779 558.165222,3171.154541 558.093079,3164.916992 
	C559.367432,3161.919189 559.256165,3158.601562 563.697876,3158.730957 
	C569.672180,3160.738525 570.772400,3164.871582 570.328003,3169.772217 
	C570.039490,3172.953857 568.398621,3176.000244 569.621582,3180.009766 
	C570.118591,3181.648193 570.321167,3182.598145 570.587036,3184.261475 
	C570.272705,3191.458740 574.821472,3193.651123 580.420532,3195.752930 
	C581.439880,3196.257324 581.830872,3196.486816 582.754578,3197.130371 
	C584.938721,3199.816650 586.915161,3201.272705 590.597412,3200.102539 
	C592.746033,3199.975098 594.141174,3200.232666 596.017090,3201.362305 
	C596.982300,3202.783203 597.071838,3203.750244 596.448425,3205.349609 
z"
        />
        <path
          fill="#765242"
          opacity="1.000000"
          stroke="none"
          d="
M564.395508,3159.386719 
	C561.488220,3159.952148 560.292786,3161.630371 558.591858,3163.643555 
	C557.305237,3155.596191 559.300598,3147.701904 561.959778,3139.747559 
	C564.551025,3131.996338 566.129150,3123.906494 568.198792,3115.205078 
	C568.245544,3114.444824 569.120361,3114.172852 569.569214,3114.081543 
	C570.848206,3113.285889 571.807007,3112.943604 573.611816,3113.276855 
	C576.900391,3124.655518 569.479614,3133.657715 568.014648,3143.905518 
	C567.513062,3147.414062 564.333923,3150.332520 565.600708,3155.064209 
	C565.654785,3156.887939 565.408447,3157.888184 564.395508,3159.386719 
z"
        />
        <path
          fill="#765242"
          opacity="1.000000"
          stroke="none"
          d="
M558.242126,3182.588867 
	C562.311523,3182.759766 561.969177,3185.844971 561.778564,3189.568359 
	C557.889465,3189.324951 557.956665,3186.355469 558.242126,3182.588867 
z"
        />
        <path
          fill="#765242"
          opacity="1.000000"
          stroke="none"
          d="
M561.401428,3194.311035 
	C563.170593,3194.743164 563.927673,3195.730469 562.569397,3197.649658 
	C560.745911,3197.233154 560.066406,3196.224854 561.401428,3194.311035 
z"
        />
        <path
          fill="#5E4237"
          opacity="1.000000"
          stroke="none"
          d="
M574.733093,3113.968750 
	C573.095703,3113.975830 572.216248,3113.986328 570.677368,3113.993652 
	C570.003967,3113.647705 569.989929,3113.304932 570.013184,3112.448730 
	C572.240845,3110.389160 573.102234,3108.040283 573.910767,3105.016602 
	C575.467346,3103.590332 576.973755,3102.776611 579.094788,3101.810303 
	C579.791870,3102.062988 579.874329,3102.468750 580.032715,3103.490723 
	C580.971619,3108.519775 580.310486,3112.218994 574.733093,3113.968750 
z"
        />
        <path
          fill="#2E2D30"
          opacity="1.000000"
          stroke="none"
          d="
M725.962402,3491.710938 
	C725.716064,3493.960449 724.932007,3495.567871 723.021545,3495.677979 
	C717.270386,3496.010498 710.755676,3495.577881 707.154297,3491.523682 
	C700.908752,3484.493408 691.967041,3483.742676 684.696899,3479.386963 
	C682.173706,3477.875732 679.717224,3478.681396 677.031860,3479.197021 
	C670.221436,3480.505127 665.399048,3477.460449 664.169556,3470.609863 
	C663.406982,3466.360596 661.306946,3462.844971 658.696655,3460.205566 
	C650.161194,3451.576416 644.940430,3439.929932 634.293091,3433.299805 
	C627.902588,3429.320312 627.116150,3423.774658 628.652588,3416.970215 
	C631.531860,3404.218506 626.375732,3397.270264 613.370483,3396.067139 
	C609.404480,3395.700439 605.380981,3395.998535 601.397644,3395.760010 
	C598.887817,3395.609863 596.787659,3394.373291 596.605896,3391.593994 
	C596.384949,3388.215576 599.115540,3388.314697 601.410950,3387.982178 
	C605.992432,3387.318359 610.380676,3385.519287 615.153564,3385.913330 
	C618.016357,3386.149658 620.208435,3384.840332 621.798950,3382.334473 
	C624.904419,3377.442139 623.922241,3372.029785 623.935608,3366.764160 
	C623.953308,3359.797119 618.550598,3358.397217 613.126465,3357.011230 
	C612.436707,3352.047852 618.941040,3350.310547 618.042969,3344.794678 
	C618.055420,3344.063721 617.296265,3343.511719 617.091309,3343.103516 
	C616.587646,3342.091797 616.117981,3341.681885 614.922607,3341.583984 
	C597.328125,3346.997803 581.816956,3356.560791 563.335327,3359.954102 
	C556.265564,3361.908203 549.766541,3363.320801 543.338440,3367.063721 
	C539.391174,3369.569336 535.904358,3371.335693 531.193665,3371.894043 
	C524.535950,3370.473145 520.291870,3374.725830 515.787842,3376.990967 
	C508.076691,3380.869141 498.483856,3379.281738 491.330139,3386.223877 
	C490.042267,3387.242188 489.219147,3387.682373 487.688599,3388.237305 
	C481.324371,3389.329834 475.656128,3390.350586 469.233093,3393.559570 
	C464.766083,3393.685791 461.888489,3392.158203 458.254486,3393.337646 
	C454.691406,3394.494141 453.820404,3391.858887 454.964081,3388.529297 
	C457.906067,3379.963867 465.795197,3377.289795 472.904114,3374.488770 
	C477.262329,3372.770996 481.287018,3370.806396 485.339355,3368.549072 
	C488.929047,3366.549805 492.424622,3363.265625 497.316467,3365.439941 
	C499.298187,3366.320557 501.353363,3365.571045 502.694000,3363.879883 
	C506.130066,3359.543945 511.472382,3358.142090 515.777466,3355.154297 
	C520.034363,3352.199951 524.026733,3348.105713 530.003845,3351.531006 
	C530.691040,3351.924561 532.410645,3351.758545 532.808533,3351.224365 
	C537.261902,3345.242432 545.240356,3347.477783 550.478699,3343.490967 
	C551.973328,3342.353271 553.408508,3342.132080 555.039795,3343.062256 
	C555.858276,3343.528809 557.431580,3344.339111 557.441833,3344.320557 
	C560.154724,3339.399414 566.466248,3339.209717 569.652771,3335.311035 
	C575.708984,3327.901367 582.586914,3323.014160 592.529968,3321.621582 
	C598.501465,3320.785400 603.997437,3316.749023 611.094482,3315.909180 
	C615.374939,3325.385498 613.528259,3327.944824 598.399109,3334.732178 
	C607.562622,3331.728271 616.327454,3324.265625 626.995605,3332.580078 
	C629.617981,3335.553711 630.453369,3338.390625 629.847961,3342.317871 
	C629.551270,3344.160400 627.609619,3345.093262 629.780090,3346.993652 
	C632.518860,3351.446533 632.942627,3355.749268 633.162415,3360.089844 
	C634.209290,3380.768555 639.148193,3400.876465 642.147949,3421.268555 
	C642.534363,3423.895264 643.359009,3426.461426 645.618652,3429.141113 
	C655.226440,3437.959473 660.913635,3448.911865 668.570496,3458.326904 
	C673.271790,3464.107422 678.499329,3468.743408 686.061523,3471.742676 
	C700.557373,3474.343262 709.808533,3486.212402 723.816406,3489.865723 
	C724.851013,3490.477295 725.232849,3490.775146 725.962402,3491.710938 
z"
        />
        <path
          fill="#020D19"
          opacity="1.000000"
          stroke="none"
          d="
M561.507080,3358.469727 
	C566.517517,3355.091553 572.134155,3355.351318 576.761230,3353.167969 
	C583.410950,3350.030762 591.005127,3349.888916 597.152283,3344.529297 
	C602.144287,3340.177002 609.819214,3339.785645 617.251709,3338.107666 
	C618.767761,3338.806152 619.070862,3339.735596 618.554810,3341.471191 
	C618.173462,3342.134277 618.135742,3343.099609 618.095581,3343.581543 
	C602.663208,3349.703613 587.111816,3354.984131 573.070679,3362.670166 
	C573.316528,3366.016602 575.219666,3366.270508 576.515991,3367.170410 
	C578.184631,3368.329102 581.935730,3367.709717 580.807739,3371.290039 
	C579.974854,3373.933594 577.642517,3375.643066 574.748535,3375.732178 
	C573.173950,3375.780762 571.278992,3375.493896 570.038147,3374.627930 
	C563.618469,3370.148682 556.257019,3371.050781 549.842834,3372.770508 
	C541.335754,3375.050781 532.875671,3377.942871 524.754272,3381.860840 
	C516.191650,3385.991699 507.409485,3390.083008 498.524506,3393.242676 
	C466.331421,3404.691162 435.253052,3418.844238 403.425323,3431.145020 
	C402.210480,3431.614258 400.849945,3431.706543 398.797241,3432.004883 
	C396.904846,3430.333252 395.172791,3430.026367 392.637146,3429.988525 
	C392.270721,3427.769775 393.420471,3426.382080 395.659058,3425.845459 
	C410.041718,3422.397217 422.424316,3414.539062 435.322571,3407.815918 
	C436.487579,3407.208740 437.806702,3406.173828 438.927948,3406.318115 
	C449.304718,3407.654297 456.270416,3398.662109 465.816406,3397.535889 
	C466.519592,3397.453369 467.077484,3396.138672 467.839905,3394.688477 
	C467.976898,3393.980469 467.995453,3393.996338 467.987793,3393.985596 
	C472.913483,3389.872070 480.360077,3391.895996 485.492798,3386.512695 
	C486.895172,3385.999268 487.775055,3385.978760 489.314728,3385.936523 
	C495.462677,3381.861084 502.475220,3382.443115 508.505707,3379.758789 
	C515.336975,3376.717529 522.066711,3373.537109 529.322449,3369.936035 
	C533.937256,3369.768066 536.887024,3366.904053 541.274048,3366.002930 
	C548.397888,3363.789307 554.888611,3361.862061 561.507080,3358.469727 
z"
        />
        <path
          fill="#423C3E"
          opacity="1.000000"
          stroke="none"
          d="
M626.535889,3333.431641 
	C616.604553,3328.763916 608.460388,3331.763428 600.862122,3337.734619 
	C598.562439,3339.541260 596.189087,3340.723145 593.670654,3338.971680 
	C591.381958,3337.379883 591.847595,3334.824707 593.065491,3332.688965 
	C594.198547,3330.702148 596.126404,3331.028076 598.234619,3330.959717 
	C606.544434,3330.689453 610.513184,3326.552490 611.921143,3316.664551 
	C616.399353,3309.865479 615.157349,3302.143311 618.039917,3294.822266 
	C618.909058,3293.250977 619.787964,3292.770508 621.617371,3292.662598 
	C623.406433,3293.046387 624.264526,3293.613281 625.381409,3295.083984 
	C626.201843,3296.728271 626.481445,3297.736328 626.716370,3299.558105 
	C626.903015,3301.370850 626.978394,3302.407959 626.990723,3304.220947 
	C626.835510,3309.106201 626.855469,3313.217773 626.944214,3318.104004 
	C626.968262,3321.005371 627.763916,3322.964844 628.375977,3325.788086 
	C628.653015,3328.814697 628.335388,3330.941406 626.535889,3333.431641 
z"
        />
        <path
          fill="#020D19"
          opacity="1.000000"
          stroke="none"
          d="
M383.247009,3430.156738 
	C378.151794,3436.995850 370.429535,3439.701660 360.825928,3440.000000 
	C364.132050,3434.176270 371.735779,3435.677490 376.928619,3431.993164 
	C378.437866,3430.922363 380.615051,3430.792725 383.247009,3430.156738 
z"
        />
        <path
          fill="#2E2D30"
          opacity="1.000000"
          stroke="none"
          d="
M623.301636,3293.062500 
	C621.758972,3293.900635 620.742493,3293.952881 618.966858,3294.030273 
	C615.412781,3292.099365 614.463806,3289.157471 613.772217,3285.375977 
	C615.539307,3283.244873 617.526672,3281.951904 620.803345,3282.070312 
	C625.322998,3284.775146 624.900635,3288.452148 623.301636,3293.062500 
z"
        />
        <path
          fill="#79868C"
          opacity="1.000000"
          stroke="none"
          d="
M506.409424,3326.838379 
	C508.312103,3326.725586 510.544556,3325.183594 511.483215,3327.923096 
	C512.121277,3329.785400 510.751404,3331.318604 509.127502,3331.451904 
	C507.012115,3331.625732 506.787445,3329.746338 506.748779,3327.476318 
	C506.579803,3326.933350 506.269409,3326.858887 506.409424,3326.838379 
z"
        />
        <path
          fill="#1B2B3A"
          opacity="1.000000"
          stroke="none"
          d="
M523.220581,3328.578125 
	C522.325439,3328.353271 522.199524,3327.760010 522.793030,3327.443604 
	C523.341492,3327.151611 523.668030,3327.628662 523.220581,3328.578125 
z"
        />
        <path
          fill="#2E2D30"
          opacity="1.000000"
          stroke="none"
          d="
M556.962646,3336.939453 
	C557.044006,3336.896484 556.881287,3336.982178 556.962646,3336.939453 
z"
        />
        <path
          fill="#152533"
          opacity="1.000000"
          stroke="none"
          d="
M436.731201,3345.634277 
	C436.342621,3345.240723 436.577423,3344.652588 437.457764,3343.884766 
	C437.804443,3344.270264 437.554871,3344.848389 436.731201,3345.634277 
z"
        />
        <path
          fill="#020D19"
          opacity="1.000000"
          stroke="none"
          d="
M307.197632,3472.001465 
	C310.735931,3474.884033 314.336853,3474.721680 317.167664,3473.125244 
	C326.203796,3468.028076 335.790436,3471.848389 345.039795,3470.806641 
	C345.620544,3470.741211 346.334259,3471.151123 346.870483,3471.511963 
	C349.737610,3473.440674 355.118652,3473.508301 354.734375,3477.834229 
	C354.417511,3481.401123 350.162201,3483.072021 346.548157,3483.920410 
	C337.494049,3486.046387 330.623505,3492.776367 321.692535,3495.402832 
	C316.843018,3496.829102 312.508850,3498.920410 307.404816,3496.838867 
	C304.208679,3495.535156 300.935608,3495.880371 297.707855,3497.105713 
	C294.378815,3498.369629 291.207489,3498.425049 288.343262,3494.663086 
	C292.259857,3494.607666 296.087433,3491.141602 300.633057,3492.723877 
	C301.846161,3493.145996 304.077637,3492.229980 305.154449,3491.205811 
	C314.195923,3482.606445 326.166656,3482.563721 337.255005,3479.962646 
	C338.841461,3479.590576 340.832703,3479.710205 340.819031,3477.481445 
	C340.807404,3475.580811 339.178131,3474.520264 337.533325,3474.357666 
	C332.265961,3473.836426 326.978455,3473.290771 321.982483,3475.980469 
	C317.898071,3478.179443 313.713959,3480.200439 309.523193,3482.193604 
	C304.510284,3484.577393 299.341583,3484.958984 294.255280,3482.553223 
	C292.696533,3481.815918 291.646332,3479.720947 292.654388,3478.334229 
	C295.981293,3473.759033 300.864807,3472.035889 307.197632,3472.001465 
z"
        />
        <path
          fill="#020D19"
          opacity="1.000000"
          stroke="none"
          d="
M385.961853,3442.812744 
	C386.311646,3442.069092 386.644440,3442.043945 386.976349,3442.052734 
	C395.629211,3442.281494 399.754120,3444.734863 399.803741,3449.676758 
	C399.842041,3453.492188 393.179199,3458.263916 386.507812,3457.604736 
	C381.204498,3457.080566 376.171936,3457.241211 370.937897,3457.811279 
	C365.784424,3458.372803 361.745087,3454.348389 356.480591,3452.537354 
	C358.625671,3449.250000 362.537598,3449.119141 365.649384,3447.299072 
	C368.744507,3445.489258 371.688385,3446.155518 374.951263,3447.816895 
	C381.098267,3450.947021 382.111359,3450.323730 385.961853,3442.812744 
z"
        />
        <path
          fill="#020D19"
          opacity="1.000000"
          stroke="none"
          d="
M283.648376,3493.587891 
	C283.059570,3494.635986 282.114716,3495.259277 280.580902,3495.926758 
	C280.387421,3494.206543 280.390137,3491.980713 283.648376,3493.587891 
z"
        />
        <path
          fill="#020D19"
          opacity="1.000000"
          stroke="none"
          d="
M274.504608,3496.010254 
	C273.385681,3495.770752 272.873444,3495.381836 272.232483,3494.426270 
	C273.788727,3491.987793 275.036926,3492.442139 275.930115,3495.415039 
	C275.671722,3495.987305 275.338287,3495.994141 274.504608,3496.010254 
z"
        />
        <path
          fill="#020D19"
          opacity="1.000000"
          stroke="none"
          d="
M380.927124,3443.044678 
	C380.880829,3442.958984 380.973450,3443.130371 380.927124,3443.044678 
z"
        />
        <path
          fill="#395166"
          opacity="1.000000"
          stroke="none"
          d="
M2399.364014,2713.607666 
	C2401.340576,2719.539551 2405.786377,2719.670898 2410.173340,2719.999268 
	C2414.300781,2720.308594 2418.635010,2719.098389 2424.033447,2721.772461 
	C2419.027832,2724.297363 2414.848145,2725.817139 2411.518311,2728.797119 
	C2409.448242,2730.649414 2406.350342,2730.398438 2403.846191,2729.494629 
	C2398.655273,2727.620850 2393.336670,2728.000488 2388.038818,2728.055664 
	C2362.117920,2728.326416 2336.322266,2725.098389 2310.351318,2725.663818 
	C2300.270264,2725.883301 2290.103760,2722.710449 2280.000488,2720.943115 
	C2277.736084,2720.546875 2275.569824,2719.439941 2273.105957,2721.460693 
	C2273.927002,2725.593750 2279.887695,2723.906006 2280.877441,2728.255371 
	C2276.169678,2732.690430 2270.137451,2731.887695 2264.322754,2732.015381 
	C2258.803223,2732.136475 2253.113525,2730.918457 2248.904053,2736.583008 
	C2246.848633,2739.349121 2243.357666,2737.466309 2240.553711,2736.419434 
	C2234.544922,2734.176270 2228.410889,2733.280273 2221.546875,2734.510498 
	C2222.895752,2738.927734 2226.885254,2738.734131 2229.587158,2739.372559 
	C2237.626221,2741.272217 2245.870117,2742.284668 2253.971191,2743.949951 
	C2258.971436,2744.978027 2263.697510,2741.662598 2269.319336,2743.702881 
	C2270.787842,2744.937500 2271.072510,2746.019531 2270.412109,2747.998535 
	C2262.150879,2753.471436 2253.582764,2753.535400 2244.062500,2752.593750 
	C2235.358398,2749.425781 2227.140625,2747.426514 2218.446533,2744.394043 
	C2212.722412,2741.580078 2207.050537,2741.508057 2201.187500,2739.463867 
	C2198.583984,2737.087402 2199.994629,2735.483887 2201.272217,2733.991455 
	C2202.933350,2732.051025 2203.100098,2730.093262 2200.395020,2729.588867 
	C2191.334961,2727.899902 2182.543945,2721.280518 2172.164062,2727.932617 
	C2153.399414,2726.332275 2135.291992,2727.350098 2117.268311,2725.690430 
	C2108.139160,2724.849365 2098.863525,2725.600830 2089.067383,2722.970459 
	C2080.745605,2723.410889 2075.341553,2718.915283 2068.955566,2714.818359 
	C2065.715820,2713.166260 2063.273193,2711.615479 2060.064697,2709.981201 
	C2056.812256,2707.651855 2054.060303,2705.870850 2050.315430,2704.333984 
	C2043.078735,2700.831787 2036.447144,2697.740479 2030.102905,2694.127197 
	C2026.842163,2692.270020 2022.564575,2690.667969 2024.930542,2684.912598 
	C2036.546509,2684.541992 2047.436401,2684.076416 2058.357422,2683.214600 
	C2070.267090,2682.274902 2082.276611,2682.712158 2094.101074,2685.025635 
	C2110.753418,2688.283447 2128.000977,2682.053711 2144.478271,2688.193115 
	C2145.396973,2688.535645 2146.452148,2688.493652 2147.423340,2688.723633 
	C2151.642822,2689.722656 2157.083984,2690.449219 2156.464111,2695.995117 
	C2155.849365,2701.496582 2150.169922,2700.701172 2146.016357,2701.277100 
	C2128.964111,2703.641113 2112.494873,2700.362793 2101.442871,2697.513672 
	C2118.405273,2703.240234 2141.483398,2703.906982 2164.551270,2704.432617 
	C2178.236572,2704.744385 2191.741211,2706.168213 2205.169922,2708.864502 
	C2212.665039,2710.369385 2220.326172,2711.221191 2227.993408,2709.616699 
	C2233.429443,2708.478516 2238.666016,2709.784424 2243.677246,2711.496582 
	C2255.503174,2715.537354 2267.209961,2715.364990 2277.513672,2711.907227 
	C2269.989746,2714.241211 2260.826172,2713.991211 2251.788818,2712.316895 
	C2240.331787,2710.193848 2228.930176,2708.298096 2217.211670,2709.322754 
	C2214.243652,2709.582275 2211.205811,2709.336914 2208.294189,2708.492676 
	C2205.641357,2707.723633 2203.170166,2706.606689 2202.969727,2703.366211 
	C2202.733398,2699.541260 2205.426025,2697.996582 2208.505127,2697.451904 
	C2216.753418,2695.992920 2225.103760,2696.644287 2234.239746,2696.723145 
	C2249.566406,2692.987061 2263.969482,2691.147705 2279.123047,2694.040039 
	C2289.399414,2696.001221 2300.248535,2695.662109 2310.632324,2692.683594 
	C2317.421875,2690.736328 2324.321045,2690.508789 2331.298584,2690.996094 
	C2334.257568,2691.202881 2337.548828,2692.398193 2340.134277,2691.052490 
	C2352.325195,2684.709473 2359.278320,2690.785156 2364.890381,2701.696777 
	C2360.977783,2712.438965 2352.188965,2707.750977 2344.556152,2708.980469 
	C2332.409668,2708.302246 2321.041504,2707.458252 2309.720215,2709.150635 
	C2319.837646,2710.268311 2329.861084,2706.472412 2340.692383,2709.042480 
	C2342.739014,2709.095947 2344.086182,2709.233154 2346.031738,2709.761719 
	C2348.572266,2710.779297 2350.509033,2711.371582 2353.218994,2710.299805 
	C2358.117676,2709.656006 2362.150146,2710.798828 2366.839844,2712.086670 
	C2371.458008,2714.818359 2375.482910,2718.696777 2379.689941,2711.493164 
	C2386.582520,2710.493408 2392.584961,2712.874268 2399.364014,2713.607666 
z"
        />
        <path
          fill="#445E74"
          opacity="1.000000"
          stroke="none"
          d="
M2270.643555,2748.505859 
	C2270.050293,2746.986084 2270.030029,2746.130127 2270.000000,2744.632324 
	C2277.083984,2739.526611 2284.612061,2738.962158 2292.605225,2741.116699 
	C2299.015625,2742.844727 2305.639648,2741.720215 2313.081543,2742.036865 
	C2325.156738,2741.074951 2335.814209,2745.681396 2347.788574,2745.010010 
	C2366.164551,2746.662842 2383.620850,2748.967041 2401.896973,2750.978516 
	C2414.877930,2751.585693 2427.012207,2753.012695 2439.244873,2753.302490 
	C2445.242432,2753.444336 2451.099365,2754.067139 2456.929443,2751.093994 
	C2452.693359,2751.044189 2448.635742,2752.295654 2444.577881,2752.144287 
	C2437.956787,2751.897705 2431.115967,2751.588135 2429.617188,2742.510254 
	C2439.914795,2741.707031 2449.891602,2742.999023 2459.691162,2740.260986 
	C2461.518555,2739.750488 2463.773682,2739.852539 2465.603271,2740.414795 
	C2479.165283,2744.581543 2493.080566,2744.203369 2507.000732,2743.952881 
	C2513.974854,2743.827637 2520.680908,2744.248779 2527.382568,2747.187744 
	C2532.716797,2749.527100 2538.785156,2750.345459 2544.893555,2750.108154 
	C2553.178955,2749.786133 2561.485840,2750.018311 2569.783447,2750.002441 
	C2573.113281,2749.995850 2576.368896,2750.008789 2579.309326,2752.103760 
	C2580.592529,2753.018311 2582.387939,2753.846680 2583.879883,2753.738281 
	C2598.590576,2752.669678 2612.217041,2759.640137 2627.425781,2759.653809 
	C2626.416504,2766.703857 2620.558838,2766.366699 2615.586426,2766.855957 
	C2610.366455,2767.370361 2605.061768,2766.857178 2598.886719,2767.011230 
	C2587.876709,2768.267578 2577.816895,2766.645752 2566.924072,2766.409180 
	C2562.815430,2765.279785 2561.918213,2761.856445 2558.933838,2759.620361 
	C2553.923096,2758.434814 2549.662109,2759.277344 2544.668945,2760.160889 
	C2540.944824,2760.855713 2537.933350,2760.927002 2534.168945,2760.468750 
	C2531.981689,2760.077881 2530.580566,2759.641602 2528.510254,2758.975830 
	C2526.878418,2758.730225 2525.956299,2758.561523 2524.371338,2758.191406 
	C2521.087646,2756.898193 2518.478516,2756.976074 2515.202637,2758.223633 
	C2505.566406,2759.347412 2496.610107,2759.426270 2488.690430,2758.221924 
	C2492.986084,2759.857666 2498.968994,2757.859619 2504.666016,2761.387695 
	C2509.614014,2769.732178 2517.095947,2767.946777 2523.708252,2767.257568 
	C2530.983398,2766.499512 2538.161377,2765.518555 2545.382080,2767.146973 
	C2549.471924,2768.069336 2553.344971,2769.847656 2553.286865,2774.729492 
	C2553.222900,2780.096436 2548.719238,2780.678955 2544.702637,2781.057373 
	C2538.055664,2781.683838 2531.426270,2781.236084 2524.814697,2780.246582 
	C2517.867432,2779.206299 2511.274658,2779.159912 2504.470703,2782.517578 
	C2498.230469,2785.597168 2491.457764,2783.661621 2485.695801,2779.732910 
	C2482.933594,2777.849365 2480.385010,2775.724609 2476.078613,2775.157715 
	C2468.796631,2771.981934 2461.832275,2771.266602 2454.920410,2771.130615 
	C2448.240479,2770.998779 2441.610840,2770.447510 2435.105469,2769.280029 
	C2420.003174,2766.569824 2405.043457,2765.435547 2389.605713,2767.130615 
	C2372.832275,2768.972412 2356.718018,2763.012207 2340.695801,2759.063965 
	C2320.615723,2754.116211 2300.604492,2750.140381 2279.848145,2750.934326 
	C2276.823730,2751.050049 2273.860596,2750.897949 2270.643555,2748.505859 
z"
        />
        <path
          fill="#21364A"
          opacity="1.000000"
          stroke="none"
          d="
M2368.643066,2714.482910 
	C2362.961914,2714.654297 2358.346680,2712.980957 2352.817383,2712.097656 
	C2351.128906,2712.211182 2350.234375,2712.241699 2348.671875,2712.072266 
	C2346.515381,2711.952148 2345.023193,2711.942871 2342.784668,2711.918945 
	C2339.951904,2710.575684 2338.388916,2709.135010 2341.480469,2706.416016 
	C2346.670166,2705.994141 2351.330078,2705.945801 2355.989746,2705.951660 
	C2358.715820,2705.955078 2361.338379,2705.755615 2363.602295,2702.708496 
	C2368.015869,2697.763428 2373.220459,2699.540527 2378.929199,2699.347168 
	C2412.764648,2703.609375 2446.095947,2703.696045 2479.787109,2709.328125 
	C2483.637451,2710.313965 2486.435547,2711.427490 2488.782715,2714.789307 
	C2489.510254,2718.267090 2488.580322,2720.466553 2486.164062,2722.971191 
	C2480.001465,2725.781006 2474.707764,2724.004639 2468.650391,2722.478760 
	C2458.403320,2717.142334 2448.361572,2714.876465 2437.715576,2713.690430 
	C2429.194824,2712.740723 2420.744873,2712.070068 2412.198242,2711.959961 
	C2408.500977,2711.912354 2404.997314,2712.506104 2400.739746,2713.954102 
	C2393.502930,2716.350098 2387.270264,2716.874268 2380.816406,2712.190918 
	C2377.008789,2714.901855 2373.403564,2715.638428 2368.643066,2714.482910 
z"
        />
        <path
          fill="#395166"
          opacity="1.000000"
          stroke="none"
          d="
M2429.987793,2741.209717 
	C2433.702393,2747.448242 2438.572998,2751.113281 2445.458984,2749.097900 
	C2452.972900,2746.898682 2459.771240,2748.529785 2466.616699,2751.144287 
	C2466.430908,2754.489258 2463.936523,2753.506348 2462.202148,2753.990967 
	C2443.356689,2759.257324 2424.522461,2755.674805 2404.814209,2753.965332 
	C2398.680420,2747.750732 2390.266357,2750.583984 2383.526367,2746.425293 
	C2381.845947,2743.723389 2381.847168,2741.599609 2383.421875,2738.789551 
	C2392.691895,2738.088623 2401.458496,2738.066162 2411.160156,2738.085205 
	C2415.273926,2740.856689 2418.518799,2743.328125 2423.506836,2740.460938 
	C2424.347412,2738.373047 2424.638672,2736.588379 2426.760742,2736.508057 
	C2429.486572,2736.404785 2429.744629,2738.493896 2429.987793,2741.209717 
z"
        />
        <path
          fill="#3E5871"
          opacity="1.000000"
          stroke="none"
          d="
M2597.488770,2764.921631 
	C2607.607422,2763.636230 2617.589844,2765.149658 2627.518066,2760.473877 
	C2630.853271,2758.070312 2633.709473,2758.489502 2636.434570,2759.926758 
	C2644.071777,2763.956299 2652.287598,2765.589111 2660.803467,2766.165771 
	C2663.148926,2766.324219 2665.155273,2767.520996 2667.529053,2769.420898 
	C2668.010254,2771.471191 2668.020508,2772.942383 2668.044189,2775.149170 
	C2666.285400,2778.229736 2663.655029,2779.270996 2661.055908,2778.678223 
	C2643.017822,2774.563721 2624.057861,2777.908203 2606.284180,2771.822510 
	C2602.696045,2770.593994 2598.115479,2770.867188 2597.488770,2764.921631 
z"
        />
        <path
          fill="#395166"
          opacity="1.000000"
          stroke="none"
          d="
M2348.717285,2747.429932 
	C2336.687012,2749.023438 2325.815918,2746.436523 2314.528564,2742.566895 
	C2314.760010,2741.421875 2315.603760,2740.882568 2316.263184,2740.172363 
	C2324.621826,2731.172363 2334.246338,2734.400879 2343.091797,2738.367676 
	C2349.386475,2741.190186 2354.682617,2738.822998 2361.238525,2738.071777 
	C2363.140625,2739.386230 2363.600586,2740.878662 2363.071777,2743.316406 
	C2358.909912,2747.883789 2353.506836,2744.557617 2348.717285,2747.429932 
z"
        />
        <path
          fill="#3C556B"
          opacity="1.000000"
          stroke="none"
          d="
M2468.100342,2722.674316 
	C2473.330811,2721.949219 2478.749756,2721.947021 2485.067871,2721.938477 
	C2492.532471,2719.134277 2498.437744,2722.403809 2505.239746,2724.351074 
	C2507.776855,2726.215332 2508.629883,2728.135254 2508.305664,2731.218018 
	C2508.008057,2733.452393 2508.019531,2734.928955 2508.058594,2737.140625 
	C2498.195068,2737.751953 2490.405762,2731.496338 2481.578125,2728.333984 
	C2477.203613,2726.766357 2471.555664,2727.945312 2468.100342,2722.674316 
z"
        />
        <path
          fill="#445E74"
          opacity="1.000000"
          stroke="none"
          d="
M2362.695312,2744.518799 
	C2362.082764,2742.371094 2362.051758,2740.938232 2362.038086,2738.791016 
	C2368.740479,2738.079346 2375.425293,2738.082031 2383.017578,2738.097900 
	C2383.924805,2740.139160 2383.924805,2742.167480 2383.890625,2744.958008 
	C2376.844482,2752.004395 2370.108398,2746.594971 2362.695312,2744.518799 
z"
        />
        <path
          fill="#395166"
          opacity="1.000000"
          stroke="none"
          d="
M2294.001953,2732.003662 
	C2291.192139,2731.652832 2288.584229,2732.843994 2287.080566,2729.326660 
	C2291.823486,2727.433105 2296.140625,2727.432129 2300.908447,2729.309814 
	C2299.415771,2732.829834 2296.805908,2731.647705 2294.001953,2732.003662 
z"
        />
        <path
          fill="#445E74"
          opacity="1.000000"
          stroke="none"
          d="
M2423.231201,2739.988770 
	C2419.925781,2745.422607 2418.822510,2745.375732 2412.207520,2738.813477 
	C2415.612305,2738.402344 2418.808838,2740.468750 2423.231201,2739.988770 
z"
        />
        <path
          fill="#395166"
          opacity="1.000000"
          stroke="none"
          d="
M2306.957031,2732.925781 
	C2307.042725,2732.879883 2306.871094,2732.971680 2306.957031,2732.925781 
z"
        />
        <path
          fill="#294159"
          opacity="1.000000"
          stroke="none"
          d="
M2596.917969,2764.115723 
	C2598.296143,2765.354248 2598.543457,2767.035645 2599.984619,2767.262939 
	C2614.214355,2769.507080 2628.076904,2774.956055 2642.886719,2772.260254 
	C2645.285156,2771.823730 2647.481445,2772.779541 2649.653320,2773.625244 
	C2655.022705,2775.716309 2660.588379,2776.364746 2667.180908,2775.928223 
	C2680.965088,2777.575684 2693.872314,2779.266357 2707.394775,2781.478516 
	C2705.728271,2785.706055 2703.670166,2789.425049 2698.109131,2786.498047 
	C2695.695068,2785.227051 2692.976074,2787.005371 2690.385254,2787.452393 
	C2685.139160,2788.357666 2679.756348,2789.114746 2675.446533,2784.596191 
	C2673.472412,2782.526611 2670.837402,2782.372070 2668.147949,2781.983154 
	C2651.649902,2779.596191 2635.040039,2780.408691 2618.476562,2779.807617 
	C2604.787354,2779.311279 2591.390137,2782.378906 2576.859375,2781.941895 
	C2574.813477,2782.688477 2573.542480,2783.030029 2572.220703,2782.794434 
	C2568.247314,2782.086670 2563.634521,2781.021240 2563.495850,2776.410156 
	C2563.325439,2770.725586 2568.853516,2772.119141 2571.561768,2771.015137 
	C2567.197266,2770.119385 2560.840820,2772.916260 2557.270508,2765.879395 
	C2558.424316,2762.791016 2560.332520,2762.424561 2563.327637,2763.418457 
	C2574.691650,2763.924316 2585.345215,2764.018311 2596.917969,2764.115723 
z"
        />
        <path
          fill="#2F465E"
          opacity="1.000000"
          stroke="none"
          d="
M2577.370850,2700.262207 
	C2587.556885,2699.646973 2597.097412,2700.595459 2606.631592,2698.604736 
	C2611.687744,2697.549072 2616.903076,2700.743164 2623.108887,2699.954102 
	C2627.681885,2696.706543 2631.913574,2696.469238 2637.202393,2698.041992 
	C2640.173828,2698.762207 2642.357910,2699.234131 2645.216309,2699.734375 
	C2647.914795,2697.504639 2651.111572,2697.045654 2653.786133,2694.628418 
	C2667.763184,2694.741211 2681.462891,2692.176025 2695.239258,2697.857178 
	C2695.678955,2699.678467 2695.142822,2701.557373 2693.206299,2701.627441 
	C2682.767822,2702.005859 2676.347900,2713.114502 2664.270752,2709.906738 
	C2666.955322,2715.437012 2670.917725,2712.957764 2673.574219,2715.540283 
	C2667.225342,2716.075439 2660.619873,2718.863037 2652.799316,2717.904297 
	C2639.282471,2714.409912 2625.818848,2714.740479 2613.531006,2709.591064 
	C2608.878174,2707.641113 2604.577637,2707.147705 2601.705322,2712.628662 
	C2600.760254,2714.431885 2598.935303,2715.790283 2596.893555,2715.747559 
	C2582.509766,2715.448242 2568.201172,2723.545654 2553.043701,2715.899902 
	C2548.685547,2711.830566 2543.035156,2711.350098 2538.938965,2707.013428 
	C2538.350342,2705.226807 2538.349365,2704.159180 2538.921143,2702.341309 
	C2541.260742,2699.398682 2543.636963,2698.306396 2547.322510,2699.554199 
	C2550.850098,2700.644531 2553.702393,2701.261230 2557.290771,2701.872559 
	C2564.266357,2701.534424 2570.688721,2703.614014 2577.370850,2700.262207 
z"
        />
        <path
          fill="#3C556B"
          opacity="1.000000"
          stroke="none"
          d="
M2551.327881,2713.729736 
	C2559.064941,2713.408203 2565.738281,2715.298340 2572.429932,2717.157715 
	C2575.260742,2717.944336 2578.388428,2719.344971 2578.877441,2714.284424 
	C2579.064941,2712.345947 2581.066406,2712.058594 2582.771240,2712.065674 
	C2586.396484,2712.080566 2590.071045,2711.580322 2593.597412,2712.858398 
	C2596.945312,2714.072021 2599.691895,2713.894531 2599.900391,2709.545654 
	C2600.189941,2703.502930 2604.162354,2703.423096 2608.268799,2704.511475 
	C2616.104980,2706.588623 2623.401611,2710.423340 2631.719482,2711.094971 
	C2634.625244,2711.329590 2637.559326,2712.037598 2640.533203,2712.033203 
	C2644.787354,2712.027100 2648.257568,2713.566162 2651.366699,2717.463379 
	C2652.495850,2724.007812 2659.693604,2720.543457 2662.213135,2725.864990 
	C2652.406982,2725.864990 2643.032471,2726.541504 2633.817627,2725.621094 
	C2627.921631,2725.031982 2622.077881,2723.809082 2616.089355,2723.939697 
	C2613.275146,2724.000977 2610.898193,2723.497803 2610.018799,2720.150146 
	C2608.476562,2714.279053 2603.867432,2715.305176 2600.537109,2717.272217 
	C2596.516846,2719.647217 2592.529053,2720.161377 2588.188965,2720.011963 
	C2584.727295,2719.893066 2581.364014,2720.562012 2579.021729,2723.104004 
	C2576.002441,2726.380615 2571.841797,2726.787109 2568.141602,2727.408203 
	C2560.127441,2728.753418 2551.633789,2727.072754 2544.105225,2731.722656 
	C2543.133301,2732.322998 2541.012695,2731.063965 2538.744629,2730.359375 
	C2535.690186,2728.242676 2532.277832,2729.167236 2529.553223,2726.449219 
	C2528.192627,2723.971436 2528.271973,2722.114990 2529.994629,2719.873535 
	C2535.475586,2717.637207 2541.510742,2720.997070 2546.298828,2715.668701 
	C2547.925049,2714.245850 2549.192383,2713.633057 2551.327881,2713.729736 
z"
        />
        <path
          fill="#294159"
          opacity="1.000000"
          stroke="none"
          d="
M2551.867188,2713.308105 
	C2551.012695,2714.525879 2550.025391,2715.051758 2548.523926,2715.799805 
	C2542.809326,2716.053711 2537.585693,2715.988770 2532.623535,2719.799072 
	C2530.311523,2721.114258 2530.008789,2722.812500 2530.016113,2725.311768 
	C2531.155273,2727.332031 2531.454590,2728.852539 2530.451904,2731.198242 
	C2525.246826,2731.882324 2520.524170,2731.905762 2514.917969,2731.879639 
	C2512.111572,2729.506348 2509.117432,2728.737793 2506.407227,2726.465332 
	C2499.551514,2725.996582 2493.486084,2724.163330 2486.668457,2722.268066 
	C2486.481689,2720.336182 2486.996094,2718.740723 2487.770020,2716.562988 
	C2489.212646,2712.791748 2484.239990,2708.594971 2490.393066,2705.782715 
	C2504.086670,2703.712158 2517.218018,2704.633545 2530.171631,2702.771240 
	C2533.056396,2702.356201 2536.034180,2703.092773 2539.282715,2705.385254 
	C2543.132324,2709.519775 2549.695312,2707.085449 2551.867188,2713.308105 
z"
        />
        <path
          fill="#2F465E"
          opacity="1.000000"
          stroke="none"
          d="
M2530.673340,2731.894043 
	C2529.981689,2730.390625 2529.994141,2728.922363 2530.011719,2726.717285 
	C2532.770508,2726.332764 2536.107666,2724.990967 2537.895020,2729.344727 
	C2535.922852,2730.989014 2533.839600,2732.134277 2530.673340,2731.894043 
z"
        />
        <path
          fill="#2F465E"
          opacity="1.000000"
          stroke="none"
          d="
M2506.023438,2726.667969 
	C2509.256836,2726.286133 2513.459961,2724.872070 2513.994141,2731.089355 
	C2512.524902,2731.881836 2511.015869,2731.933838 2508.751465,2731.980957 
	C2507.364014,2730.416016 2506.731445,2728.856445 2506.023438,2726.667969 
z"
        />
        <path
          fill="#3C556B"
          opacity="1.000000"
          stroke="none"
          d="
M2679.308105,2722.023438 
	C2680.014404,2722.876465 2680.019043,2723.733398 2680.035156,2725.230713 
	C2677.387695,2725.719727 2674.595947,2726.702881 2671.928467,2725.054932 
	C2673.608154,2722.467773 2676.065674,2722.154541 2679.308105,2722.023438 
z"
        />
        <path
          fill="#3E5871"
          opacity="1.000000"
          stroke="none"
          d="
M2860.144531,2726.568359 
	C2862.375732,2725.691895 2864.734131,2725.505859 2867.532471,2725.679199 
	C2865.269043,2730.953125 2864.478760,2731.078369 2860.144531,2726.568359 
z"
        />
        <path
          fill="#233444"
          opacity="1.000000"
          stroke="none"
          d="
M505.405365,2847.457520 
	C507.229065,2853.335938 502.091187,2855.132080 500.119873,2859.378906 
	C494.037811,2867.687012 484.996918,2865.437988 477.269104,2866.591553 
	C469.628693,2867.732422 461.703003,2867.229492 453.071899,2867.563477 
	C447.804230,2862.555908 442.553772,2861.478027 436.051575,2862.250977 
	C421.228271,2864.013916 406.106354,2863.042236 391.528198,2867.230225 
	C388.992615,2867.958496 386.178253,2867.755615 383.788666,2869.042236 
	C371.219391,2875.807617 358.082397,2874.564941 344.054749,2871.550781 
	C338.186096,2866.062256 331.597656,2868.003906 325.545502,2867.440186 
	C315.856445,2866.537354 305.801147,2869.606689 295.958740,2864.044434 
	C295.373413,2862.752930 295.305542,2862.170898 295.623718,2860.799316 
	C300.057068,2860.395752 303.742004,2859.621094 307.375427,2856.324219 
	C314.747925,2848.962646 324.583801,2851.618652 333.235504,2848.112549 
	C345.897308,2845.938965 357.868652,2844.168457 369.653870,2841.557617 
	C375.199127,2840.329346 380.655579,2840.062988 387.110046,2839.975098 
	C391.048462,2839.778564 394.212097,2840.648193 397.603607,2838.533691 
	C403.542664,2838.240479 409.036591,2837.621826 415.076324,2839.658691 
	C421.270813,2836.987305 426.873840,2837.810547 433.308411,2839.259766 
	C436.855865,2839.876465 439.580505,2840.024902 443.123474,2840.200439 
	C457.316772,2836.971924 470.367493,2842.861816 484.431793,2841.862793 
	C487.953461,2843.371338 490.407684,2845.542480 494.575867,2845.016846 
	C498.683472,2844.839844 501.947296,2844.940430 505.405365,2847.457520 
z"
        />
        <path
          fill="#374D60"
          opacity="1.000000"
          stroke="none"
          d="
M444.673767,2836.561523 
	C443.147156,2835.606445 442.050781,2835.385742 441.491577,2834.716309 
	C440.629242,2833.684082 441.543091,2832.842041 442.473083,2832.487793 
	C443.378052,2832.143311 444.477631,2831.848389 445.384308,2832.040039 
	C456.177216,2834.320312 462.942169,2827.066162 470.137665,2821.305664 
	C472.665588,2819.281982 474.877502,2817.322510 478.006348,2818.479492 
	C482.613708,2820.182373 487.596375,2818.189941 491.541718,2819.171387 
	C499.050842,2821.039551 505.674072,2815.333740 512.895081,2819.167480 
	C511.211365,2814.673096 508.010406,2814.176758 504.679443,2814.100342 
	C495.393982,2813.888428 486.160828,2814.012207 476.888641,2812.302246 
	C469.733124,2810.982422 462.567841,2814.746094 455.183960,2814.359375 
	C453.333618,2814.262451 452.814362,2816.328125 451.730774,2817.525635 
	C447.211090,2822.520508 445.100250,2823.301514 439.790253,2819.674561 
	C431.762207,2814.191406 423.264343,2815.613281 414.827789,2816.903320 
	C410.787170,2817.521240 407.126282,2817.736328 403.093201,2816.835693 
	C394.906433,2815.007568 386.512177,2816.291260 378.209900,2816.063965 
	C371.885712,2815.890625 365.552490,2816.060303 359.227112,2815.912842 
	C356.931885,2815.859619 354.579956,2815.568604 352.379303,2814.944336 
	C350.492126,2814.409424 348.401276,2813.342285 348.606689,2810.925781 
	C348.824371,2808.365234 351.051270,2808.110596 353.093323,2808.082275 
	C356.088959,2808.041016 359.157898,2807.703369 362.071045,2808.209473 
	C380.489594,2811.407715 399.098480,2811.188232 417.520264,2809.728027 
	C433.845947,2808.433350 450.037384,2805.256836 467.117096,2805.992188 
	C465.795013,2800.548096 461.192261,2803.289795 459.642670,2800.422119 
	C460.281860,2799.812012 460.937134,2798.629639 461.646942,2798.595947 
	C467.950562,2798.296631 474.483246,2797.115479 480.514923,2798.336182 
	C487.108734,2799.670410 492.675232,2798.243164 499.231812,2796.016113 
	C511.026825,2797.687500 522.054016,2800.507812 533.168213,2796.704346 
	C536.256104,2795.647461 539.169800,2794.079346 543.287598,2794.577148 
	C548.634155,2797.422119 553.382996,2799.511230 558.998291,2799.127441 
	C562.320007,2798.900879 565.595642,2799.750488 568.636108,2801.246338 
	C570.774231,2802.298340 572.932251,2803.464355 572.668396,2806.286377 
	C572.401611,2809.139648 570.017273,2810.081299 567.716309,2810.386230 
	C560.124268,2811.393555 552.697693,2812.812988 545.981812,2816.911621 
	C547.256104,2818.052002 548.989014,2818.194824 550.653442,2819.892578 
	C551.745544,2822.057129 551.740540,2823.626709 550.789246,2825.928223 
	C545.897888,2829.984619 540.931824,2828.851074 535.992737,2828.427490 
	C525.373291,2827.516113 514.256287,2823.858398 505.385956,2833.527344 
	C505.352570,2833.563965 505.004395,2833.924072 504.470245,2833.863770 
	C485.060364,2831.677246 466.634583,2842.820557 447.053436,2838.086914 
	C446.431610,2837.936523 445.895660,2837.466553 444.673767,2836.561523 
z"
        />
        <path
          fill="#2F4457"
          opacity="1.000000"
          stroke="none"
          d="
M544.729065,2796.537842 
	C541.545898,2796.785400 538.532898,2796.890381 536.621826,2798.394775 
	C530.146729,2803.492188 523.125305,2802.559570 516.046997,2800.939209 
	C512.100098,2800.035645 508.282532,2799.590332 504.407288,2800.831543 
	C501.226959,2801.849854 500.215424,2800.248779 500.021057,2796.732422 
	C505.168030,2796.003906 509.664490,2793.478516 515.211548,2792.018555 
	C520.988342,2790.641602 526.364502,2790.415283 530.024292,2785.673340 
	C531.780457,2783.397705 534.921753,2784.039795 537.552246,2784.016113 
	C542.867065,2783.968262 548.183716,2784.054688 553.497070,2783.953613 
	C555.306580,2783.919434 557.534363,2784.541748 558.832458,2780.977783 
	C548.880432,2779.650635 539.151917,2778.353516 529.423462,2777.056396 
	C529.435181,2776.344727 529.446960,2775.633057 529.458679,2774.921387 
	C539.645081,2772.100342 550.259705,2775.430420 560.604797,2774.622314 
	C570.972107,2773.812988 581.631531,2773.035400 593.098877,2773.989746 
	C598.058716,2780.455566 604.554871,2780.713135 611.190125,2781.098389 
	C621.683655,2781.707520 622.901672,2784.324707 617.032471,2794.401123 
	C613.775513,2796.699219 610.733337,2796.812500 606.909668,2796.705078 
	C603.692871,2796.375244 601.351013,2796.833008 598.380920,2798.186035 
	C580.013977,2805.936768 562.756348,2799.747070 544.729065,2796.537842 
z"
        />
        <path
          fill="#495E72"
          opacity="1.000000"
          stroke="none"
          d="
M443.999115,2836.726562 
	C450.299286,2836.012451 456.596161,2835.942139 462.891907,2835.997803 
	C468.212830,2836.044678 473.257507,2836.153320 477.635925,2831.872070 
	C480.985352,2828.597412 485.830658,2829.802734 490.024017,2830.486084 
	C492.935333,2830.960449 495.729919,2832.031982 498.772156,2832.030518 
	C502.949310,2832.028809 506.733063,2831.792725 508.141846,2826.741455 
	C509.439972,2822.087158 512.608398,2822.518799 516.762451,2823.098145 
	C527.184937,2824.552002 537.732605,2825.108154 549.119385,2826.007324 
	C555.534729,2821.767334 561.317261,2821.999023 567.841675,2825.649170 
	C569.675415,2833.564453 564.109924,2834.203369 559.398438,2835.247070 
	C548.272095,2837.712891 536.403748,2836.779053 525.214172,2842.684326 
	C520.321106,2843.075195 516.221985,2842.930664 511.420807,2842.559082 
	C508.173218,2841.964111 506.120972,2839.839111 502.683228,2840.299072 
	C501.631531,2840.421875 501.210327,2840.472412 500.155945,2840.589600 
	C496.978210,2840.819824 494.452759,2841.057373 491.678833,2842.854492 
	C489.899750,2843.723877 488.639587,2844.052246 486.648743,2843.992432 
	C480.393707,2844.196289 474.701996,2843.050293 469.208771,2845.097168 
	C468.319855,2845.428467 466.923615,2845.754395 466.330139,2845.314941 
	C459.909821,2840.562012 452.158813,2842.916260 444.569214,2840.709229 
	C443.959686,2839.293457 443.977509,2838.359375 443.999115,2836.726562 
z"
        />
        <path
          fill="#33495B"
          opacity="1.000000"
          stroke="none"
          d="
M333.559326,2847.571777 
	C327.064880,2854.367676 317.797882,2853.489258 308.810455,2855.971924 
	C307.486298,2854.987549 306.965332,2853.984863 306.242371,2852.452148 
	C311.232361,2851.841309 309.756348,2844.341064 315.268097,2843.976562 
	C319.756531,2843.679688 322.692963,2846.445312 326.388123,2847.632568 
	C328.645264,2848.357666 330.740875,2845.757324 333.559326,2847.571777 
z"
        />
        <path
          fill="#374D60"
          opacity="1.000000"
          stroke="none"
          d="
M433.882874,2839.005371 
	C428.650909,2839.777344 423.170563,2839.826660 416.776672,2839.791992 
	C420.665863,2835.086182 425.843842,2834.670898 433.882874,2839.005371 
z"
        />
        <path
          fill="#374D60"
          opacity="1.000000"
          stroke="none"
          d="
M397.337219,2838.061523 
	C396.508331,2843.613770 392.665833,2842.035400 388.497772,2840.480225 
	C390.374451,2837.218750 393.592651,2838.168213 397.337219,2838.061523 
z"
        />
        <path
          fill="#33495B"
          opacity="1.000000"
          stroke="none"
          d="
M241.646515,2853.579102 
	C240.172226,2856.055664 238.937836,2855.528320 238.045883,2852.618164 
	C239.081421,2852.400635 240.194290,2852.744141 241.646515,2853.579102 
z"
        />
        <path
          fill="#33495B"
          opacity="1.000000"
          stroke="none"
          d="
M237.966537,2851.360840 
	C236.660858,2851.819580 235.207657,2851.684814 235.017288,2850.063721 
	C234.885178,2848.938721 235.858307,2848.442627 237.430679,2848.090088 
	C238.005051,2848.903320 237.997375,2849.796875 237.966537,2851.360840 
z"
        />
        <path
          fill="#4C6174"
          opacity="1.000000"
          stroke="none"
          d="
M948.582153,2730.450684 
	C944.733521,2726.925293 940.583862,2728.218506 936.726562,2728.109131 
	C930.444946,2727.930908 924.154114,2728.078369 916.934692,2728.098145 
	C913.304993,2728.268311 910.834778,2729.000000 908.321533,2731.343262 
	C899.998901,2730.736084 892.392395,2729.057373 884.721497,2727.243408 
	C872.271973,2724.299072 858.981628,2723.900391 847.648254,2732.437500 
	C844.884399,2734.519287 842.073669,2736.185303 838.598694,2735.966064 
	C819.978088,2734.792236 801.582397,2738.856689 782.942444,2738.129639 
	C772.956299,2737.739746 763.164124,2736.439941 753.396484,2734.741943 
	C749.068909,2733.989990 744.661194,2733.427979 740.523071,2734.236328 
	C724.614807,2737.343262 708.899597,2736.341309 692.596558,2732.640869 
	C692.261353,2729.843018 690.174988,2727.854736 691.424805,2724.622070 
	C699.649048,2729.829590 707.338257,2727.175781 714.975464,2722.904785 
	C716.604492,2721.993896 718.819458,2722.152100 720.750610,2721.750977 
	C721.732483,2721.547363 723.081482,2721.003662 722.681458,2719.957275 
	C719.048096,2710.454346 727.001282,2714.226562 729.949768,2714.234863 
	C737.293579,2714.255615 744.832397,2713.617432 751.925110,2716.570068 
	C752.752258,2716.914307 754.477234,2717.090332 754.626160,2716.782715 
	C757.948792,2709.919189 765.558533,2711.664551 771.439575,2708.368164 
	C775.280457,2711.275879 779.647644,2710.218262 783.375916,2711.365479 
	C785.927917,2712.150635 788.955261,2712.823730 788.721985,2716.211426 
	C788.486084,2719.636475 785.701599,2720.003174 784.220215,2719.896484 
	C791.765747,2713.331543 802.869385,2719.848633 812.473145,2714.170410 
	C818.382812,2710.676270 826.022034,2714.195312 832.601440,2716.804443 
	C846.204834,2722.198975 846.227722,2722.141357 855.939392,2712.089355 
	C859.171509,2710.969971 861.777893,2710.734863 865.246338,2710.990234 
	C874.824646,2710.848877 883.755676,2712.350586 892.948486,2708.145752 
	C895.999573,2707.284180 898.280151,2707.237793 901.425476,2707.567871 
	C920.532837,2713.512451 939.010010,2714.511963 958.000732,2711.576172 
	C969.046631,2709.868652 980.343811,2714.406250 992.380005,2711.503906 
	C995.932068,2711.173340 998.656128,2711.149170 1002.124146,2711.846680 
	C1007.976868,2714.878906 1012.153015,2720.190918 1019.884155,2717.976562 
	C1040.447021,2724.127930 1061.710693,2720.892090 1081.418579,2727.733887 
	C1086.479248,2729.490479 1091.942017,2727.867432 1097.155518,2726.680420 
	C1108.613281,2724.072998 1120.141113,2723.671143 1132.465454,2726.215332 
	C1136.686279,2730.205322 1134.724854,2733.160400 1131.145508,2733.854980 
	C1124.205811,2735.202148 1118.510864,2738.184082 1113.080078,2742.633301 
	C1109.551880,2745.523682 1104.823608,2742.662354 1099.816895,2742.967285 
	C1090.535400,2744.218018 1082.592773,2747.727051 1074.005859,2748.487793 
	C1067.771362,2749.040039 1061.572388,2750.166992 1054.766235,2747.434814 
	C1050.777954,2741.753174 1053.279053,2739.009521 1058.298828,2737.529053 
	C1061.715820,2736.521240 1065.349731,2736.872070 1068.858521,2736.258057 
	C1054.300781,2734.518555 1039.708618,2735.710449 1025.158447,2734.995117 
	C1015.576904,2734.523682 1005.845459,2735.794922 996.678772,2731.746338 
	C993.431396,2730.311768 990.916565,2731.991455 987.846436,2734.278564 
	C986.296326,2735.146973 985.330933,2735.404541 983.583069,2735.424072 
	C974.916382,2731.704346 967.352783,2726.190186 958.070923,2732.654053 
	C955.308594,2734.577393 951.591003,2733.970215 948.582153,2730.450684 
z"
        />
        <path
          fill="#3A4F62"
          opacity="1.000000"
          stroke="none"
          d="
M856.211670,2712.925781 
	C852.805420,2716.735107 853.558350,2721.850342 850.105469,2725.383301 
	C843.705261,2722.628906 836.530396,2722.364746 829.917053,2718.854980 
	C824.262634,2715.854004 816.110535,2713.033447 810.966309,2717.218506 
	C804.786316,2722.246094 800.556885,2724.050537 794.209473,2718.630859 
	C792.696289,2717.339111 790.820312,2719.912354 789.095154,2720.666504 
	C783.977051,2722.904785 778.634277,2724.985352 772.867065,2720.260986 
	C776.592529,2715.623535 783.079590,2720.117676 787.424805,2715.934326 
	C782.519958,2712.291016 775.151123,2715.936768 772.250793,2708.823486 
	C775.149658,2708.374268 777.787598,2706.989746 781.281616,2706.103027 
	C782.329102,2704.062256 787.315063,2705.859619 784.626587,2701.915283 
	C779.828064,2702.000977 775.656555,2701.691162 771.920715,2704.512207 
	C768.878113,2706.810303 765.048340,2705.865723 760.654541,2705.758789 
	C751.445984,2702.523193 742.685730,2700.905273 734.015259,2698.959229 
	C732.409180,2698.598633 730.675049,2698.720703 729.109070,2698.172363 
	C725.366760,2696.861572 720.012878,2695.586670 720.514038,2691.261475 
	C721.107483,2686.138916 726.752991,2687.125732 730.334534,2687.963867 
	C745.457397,2691.504395 759.600586,2684.217773 774.399658,2684.335449 
	C776.938660,2684.355957 779.565674,2683.502686 782.232056,2683.935303 
	C782.618958,2683.998047 783.264526,2684.584717 783.597961,2683.750732 
	C782.978027,2683.523926 782.399536,2683.207764 781.375854,2682.116211 
	C779.553833,2674.647461 784.147705,2672.962158 789.059937,2673.341797 
	C808.762695,2674.864990 828.703125,2672.613770 848.217224,2677.042969 
	C850.158325,2677.483643 852.103394,2677.864502 854.759094,2678.972656 
	C862.888123,2681.968262 868.255127,2685.820801 864.489624,2695.668945 
	C861.265198,2701.007568 857.163330,2703.395508 850.977844,2703.112793 
	C846.571350,2687.312012 832.918030,2693.015381 823.328491,2690.798584 
	C817.584534,2689.470947 811.195129,2691.036621 804.022278,2691.059082 
	C808.705322,2694.648438 813.734009,2694.772949 817.717834,2698.469238 
	C824.664612,2705.647705 833.538757,2703.012695 841.204224,2704.895264 
	C846.856262,2706.283447 853.116028,2705.772217 856.211670,2712.925781 
z"
        />
        <path
          fill="#31475A"
          opacity="1.000000"
          stroke="none"
          d="
M296.935242,2718.000000 
	C296.405212,2715.318848 298.044586,2713.841797 300.667542,2714.137207 
	C306.950012,2714.844971 312.770813,2712.067871 318.914459,2711.960938 
	C323.122681,2711.887695 325.704010,2710.149902 326.098633,2704.959473 
	C326.973450,2703.270752 327.977600,2702.680664 330.050049,2702.346436 
	C364.830811,2706.221924 398.577148,2701.831543 432.378052,2700.380371 
	C435.764954,2700.234863 439.022064,2699.697510 441.991394,2696.298828 
	C454.763031,2692.464600 466.305573,2687.197021 479.758820,2690.098633 
	C488.721313,2692.031738 497.887299,2687.978271 507.755432,2686.623047 
	C516.086365,2689.464844 523.621704,2692.527588 532.251465,2687.295166 
	C540.191528,2684.542480 547.440857,2683.688232 555.514404,2686.833740 
	C556.966187,2688.294922 557.389526,2689.372314 557.420654,2691.444580 
	C552.082153,2701.014404 543.408752,2698.488770 534.863892,2698.943848 
	C522.633850,2700.288086 511.196747,2703.732178 499.707245,2698.694092 
	C504.330017,2700.423584 511.644073,2697.711182 510.392181,2707.344482 
	C499.643036,2708.389893 489.639404,2711.046387 479.570404,2712.684570 
	C469.971802,2714.246338 460.649323,2713.601807 451.214417,2714.164062 
	C422.773987,2715.858643 394.225769,2714.904297 365.888824,2717.184570 
	C353.931335,2718.146973 342.347473,2716.919434 330.651367,2717.176025 
	C319.753021,2717.415039 308.793915,2717.592285 296.935242,2718.000000 
z"
        />
        <path
          fill="#2B3E51"
          opacity="1.000000"
          stroke="none"
          d="
M781.194275,2681.318848 
	C784.539307,2682.722168 787.250549,2683.604004 789.961792,2684.485840 
	C780.399963,2685.248291 771.548584,2688.827637 762.253967,2688.009521 
	C761.271057,2687.922852 760.091553,2687.804688 759.292419,2688.236084 
	C747.927551,2694.377197 736.158936,2692.145020 724.377319,2690.099121 
	C723.783752,2689.996094 723.109375,2690.358887 721.720398,2690.677002 
	C725.504395,2694.761230 729.846008,2696.165283 734.804688,2695.971924 
	C738.975830,2695.809570 743.188293,2696.470703 745.964355,2699.412842 
	C749.836121,2703.515625 756.056763,2699.865723 759.651733,2704.984375 
	C753.126587,2705.106445 746.544556,2703.346191 738.885620,2704.060791 
	C734.177734,2704.446289 729.601990,2701.843262 726.369751,2707.246582 
	C714.514221,2707.819824 703.192139,2707.947266 690.934998,2708.031250 
	C665.896240,2709.605225 641.770020,2709.546631 616.810059,2708.946533 
	C613.266602,2708.851562 610.526917,2708.980225 607.216431,2707.729004 
	C602.147644,2701.677490 605.195007,2698.810791 610.676880,2697.551025 
	C615.203003,2696.510742 619.943420,2697.140381 624.585999,2696.946533 
	C629.258423,2696.751221 633.420898,2696.540771 637.214539,2692.641602 
	C642.504211,2687.205078 649.929199,2690.939453 656.336670,2690.144775 
	C661.956848,2689.447754 656.728638,2687.396484 657.726013,2685.591309 
	C664.581848,2678.415283 672.782654,2680.503906 680.390076,2681.249268 
	C686.169189,2681.815674 691.997986,2683.155518 698.433228,2685.048584 
	C703.485962,2685.831055 707.508850,2686.013428 711.416687,2683.370850 
	C716.328125,2680.049316 722.139160,2681.368164 728.374329,2681.066406 
	C730.134277,2681.061035 731.134399,2681.055176 732.869690,2681.060547 
	C739.768799,2680.259033 745.849426,2682.584473 752.654907,2681.216797 
	C754.210449,2680.548096 755.155457,2680.336914 756.856934,2680.215576 
	C760.021851,2680.661133 762.418579,2680.571777 765.523376,2679.548584 
	C771.143311,2678.822021 776.024719,2678.077881 781.194275,2681.318848 
z"
        />
        <path
          fill="#3A4F62"
          opacity="1.000000"
          stroke="none"
          d="
M498.849762,2734.094727 
	C488.871674,2734.956299 481.014008,2740.398926 471.350372,2740.648926 
	C465.002960,2740.813477 458.929657,2740.500244 452.934357,2739.180908 
	C448.242096,2738.148193 443.927704,2738.388184 439.244537,2739.111084 
	C426.739227,2741.041016 414.092285,2739.878906 401.500427,2739.864258 
	C401.170868,2739.864014 400.698608,2739.916260 400.532257,2739.730225 
	C391.740356,2729.891846 382.163055,2737.314453 373.101990,2738.873291 
	C368.302826,2739.698730 363.995148,2739.905518 358.750793,2738.093262 
	C355.772583,2734.392822 351.101318,2735.472900 348.050812,2732.255615 
	C354.738190,2726.798584 361.985504,2722.309570 371.039246,2724.796143 
	C374.981689,2725.878906 378.877930,2726.074219 382.852783,2726.017578 
	C387.850586,2725.946533 392.392853,2726.560303 397.119629,2729.051270 
	C405.516266,2733.477051 414.602539,2730.402588 423.284851,2729.080566 
	C427.946075,2728.371094 432.423523,2727.826172 437.057434,2728.707520 
	C447.242157,2730.644287 457.342316,2729.429688 467.600739,2728.887207 
	C478.962524,2728.286377 490.480255,2728.867432 501.941223,2728.493896 
	C504.712189,2728.403809 507.538666,2730.017822 510.847015,2730.998535 
	C507.630402,2735.457764 503.245667,2733.297363 498.849762,2734.094727 
z"
        />
        <path
          fill="#2B3E51"
          opacity="1.000000"
          stroke="none"
          d="
M346.886597,2731.690918 
	C351.196869,2733.051270 356.988525,2729.507568 357.946350,2737.181641 
	C337.784668,2738.021729 317.738861,2740.041260 297.643494,2741.670166 
	C283.136108,2742.845947 268.466156,2742.016113 252.931122,2742.091553 
	C243.903610,2739.191162 235.787430,2736.487061 226.984497,2736.920898 
	C223.181961,2737.108398 219.905930,2735.491455 216.475296,2732.590820 
	C216.544678,2730.954590 217.057571,2729.983154 217.785843,2728.505859 
	C221.641541,2723.952148 226.464966,2725.479980 231.717651,2725.315430 
	C240.904663,2727.504883 249.848709,2726.180664 258.054749,2729.608398 
	C260.892212,2730.793945 263.177460,2729.135498 265.682739,2726.645020 
	C267.245209,2726.196533 268.299500,2726.011475 269.231476,2726.269043 
	C284.540710,2730.499756 300.097351,2727.888184 315.543976,2727.882568 
	C323.277283,2727.879639 330.666809,2729.677002 338.181213,2730.917725 
	C340.783722,2731.347656 343.436951,2731.470215 346.886597,2731.690918 
z"
        />
        <path
          fill="#3F5467"
          opacity="1.000000"
          stroke="none"
          d="
M615.561829,2706.613281 
	C628.896545,2705.985596 641.753784,2706.000732 654.611084,2706.034424 
	C657.899048,2706.043213 661.203918,2705.844727 664.371033,2707.116211 
	C665.935303,2707.744141 668.161255,2708.237061 669.058594,2706.960449 
	C674.865662,2698.699707 681.119080,2703.923584 687.289490,2706.081543 
	C687.904236,2706.296387 688.472656,2706.643799 689.531311,2707.458496 
	C686.882202,2710.932129 682.585754,2711.214600 678.899902,2711.583740 
	C667.626160,2712.713623 656.899231,2715.402100 646.627380,2720.090576 
	C646.028320,2720.363770 645.373901,2720.633057 644.730469,2720.674072 
	C631.028442,2721.554199 618.017700,2727.708984 603.961243,2725.959717 
	C599.693909,2725.428711 595.407959,2725.162109 592.105652,2720.895020 
	C594.280273,2714.161377 599.896362,2715.200684 604.553406,2714.627686 
	C614.158936,2713.445557 623.822510,2712.815186 633.502625,2712.662842 
	C634.849243,2712.915527 633.583252,2710.637451 633.803711,2711.952393 
	C633.872742,2712.364014 633.646606,2712.689209 633.276794,2712.543213 
	C627.441101,2710.240479 621.272278,2716.124023 615.171387,2711.106445 
	C614.598755,2709.327637 614.690918,2708.282227 615.561829,2706.613281 
z"
        />
        <path
          fill="#3F5467"
          opacity="1.000000"
          stroke="none"
          d="
M510.693298,2707.904541 
	C510.227325,2703.624512 508.175018,2702.205566 504.054993,2701.892334 
	C499.766205,2701.565674 495.830261,2703.985107 491.210205,2703.373047 
	C497.091766,2696.233887 499.647186,2695.700439 507.394287,2698.136475 
	C513.121826,2699.937012 518.935120,2697.643555 524.717896,2697.353027 
	C526.651611,2697.255615 528.553284,2696.521729 531.297974,2696.092285 
	C540.260742,2700.277832 549.313904,2699.605469 557.979736,2700.839111 
	C565.146606,2701.859375 572.191101,2703.055664 579.538452,2706.399902 
	C583.100464,2710.328369 584.633545,2714.299561 584.368103,2719.565186 
	C583.153198,2720.884766 582.269165,2721.461182 580.839111,2722.076172 
	C574.656311,2720.394531 567.787292,2721.816406 563.952026,2714.910156 
	C563.516724,2710.119141 560.550171,2709.651855 557.481506,2710.014893 
	C548.539307,2711.073242 539.908508,2708.541016 531.192261,2707.564941 
	C524.466797,2706.812012 518.053040,2710.538330 510.693298,2707.904541 
z"
        />
        <path
          fill="#495E72"
          opacity="1.000000"
          stroke="none"
          d="
M265.374786,2726.110840 
	C264.938904,2733.351318 263.115936,2735.733154 259.185974,2733.514160 
	C251.470398,2729.158203 243.172867,2730.860840 234.573944,2728.914062 
	C228.256012,2723.626953 221.222672,2726.982178 214.507416,2725.655273 
	C209.832169,2726.428711 206.847336,2724.582275 204.316055,2720.627441 
	C213.978760,2713.656494 224.192307,2717.512451 234.227539,2719.679932 
	C236.638535,2720.200928 238.839203,2722.030029 241.608368,2721.980469 
	C246.230347,2721.898193 250.701889,2721.912598 254.811783,2724.948975 
	C257.535706,2726.961670 261.240021,2725.767822 265.374786,2726.110840 
z"
        />
        <path
          fill="#21364A"
          opacity="1.000000"
          stroke="none"
          d="
M584.823120,2720.239014 
	C582.397766,2716.931641 578.959961,2714.222656 580.204956,2709.076172 
	C582.806641,2704.910156 586.624573,2704.535645 590.107056,2705.387207 
	C598.140686,2707.351562 606.454651,2707.070312 615.233704,2709.476562 
	C616.917847,2711.023682 618.169312,2712.135498 619.392700,2711.570557 
	C625.735535,2708.641357 631.506470,2711.813477 639.522095,2712.877197 
	C622.793518,2715.249512 608.090515,2717.334717 592.649231,2719.797607 
	C589.810303,2720.173828 587.709656,2720.171875 584.823120,2720.239014 
z"
        />
        <path
          fill="#33495B"
          opacity="1.000000"
          stroke="none"
          d="
M249.111145,2706.038086 
	C250.618851,2707.602051 251.237030,2709.149902 251.893661,2711.323975 
	C242.628799,2711.989258 233.412354,2708.113037 224.202362,2710.292480 
	C218.021103,2711.755127 212.407104,2710.308838 206.567612,2709.616943 
	C204.271500,2709.344971 202.265289,2708.312012 200.499313,2705.099365 
	C216.993942,2702.012939 232.604691,2705.281982 249.111145,2706.038086 
z"
        />
        <path
          fill="#21364A"
          opacity="1.000000"
          stroke="none"
          d="
M563.205078,2714.221191 
	C569.106018,2716.453857 574.189819,2718.767822 579.783325,2721.598145 
	C577.361389,2722.683838 574.429565,2723.252441 570.753418,2723.820312 
	C568.258423,2723.940430 566.511047,2724.038818 564.336792,2722.789062 
	C562.371277,2722.397217 560.972473,2722.334473 558.873413,2722.286133 
	C556.675537,2723.284424 555.082214,2723.637207 552.611938,2722.605713 
	C551.016724,2722.143799 550.153198,2722.109863 548.641724,2722.063477 
	C544.612183,2721.940674 542.080688,2720.705811 541.599854,2716.324219 
	C548.710022,2715.683838 555.646973,2716.551025 563.205078,2714.221191 
z"
        />
        <path
          fill="#3F5467"
          opacity="1.000000"
          stroke="none"
          d="
M541.245239,2715.120117 
	C542.665222,2718.372559 545.169312,2719.479492 547.624878,2721.528320 
	C545.609985,2724.661865 542.232300,2724.724609 539.768799,2723.416992 
	C533.334473,2720.001465 526.528442,2719.908447 519.618835,2719.787842 
	C518.644775,2719.770752 517.715027,2719.392090 517.582092,2718.169434 
	C522.810852,2715.812256 534.223022,2714.062012 541.245239,2715.120117 
z"
        />
        <path
          fill="#4C6174"
          opacity="1.000000"
          stroke="none"
          d="
M783.798096,2702.386719 
	C784.800110,2700.387695 785.780151,2699.088623 787.445007,2698.604980 
	C789.945984,2697.877930 792.166626,2698.506836 792.998291,2701.045410 
	C793.949524,2703.948242 791.768066,2705.178711 789.410889,2705.657959 
	C787.527100,2706.041016 785.540894,2705.920410 782.801758,2706.016602 
	C782.529907,2704.981689 783.056274,2703.953613 783.798096,2702.386719 
z"
        />
        <path
          fill="#3A4F62"
          opacity="1.000000"
          stroke="none"
          d="
M726.577576,2707.703613 
	C727.130981,2699.388672 727.130981,2699.388672 737.545288,2703.501465 
	C735.043152,2707.010742 730.723633,2706.031006 726.577576,2707.703613 
z"
        />
        <path
          fill="#3F5467"
          opacity="1.000000"
          stroke="none"
          d="
M551.927734,2722.834473 
	C553.455505,2722.143799 555.030884,2722.109863 557.389648,2722.188477 
	C557.652222,2724.535889 557.096375,2726.973145 554.285583,2727.067139 
	C552.085754,2727.140625 552.277405,2725.005371 551.927734,2722.834473 
z"
        />
        <path
          fill="#3F5467"
          opacity="1.000000"
          stroke="none"
          d="
M563.959839,2723.046631 
	C565.574646,2722.060059 567.361755,2721.882812 569.532593,2723.348633 
	C567.972961,2726.366211 566.010071,2726.612061 563.959839,2723.046631 
z"
        />
        <path
          fill="#263B4D"
          opacity="1.000000"
          stroke="none"
          d="
M297.628448,2779.352783 
	C288.632904,2781.558594 279.158203,2782.745850 270.215698,2786.234131 
	C266.440948,2787.706299 262.564484,2787.972900 258.578339,2787.965088 
	C247.701035,2787.943848 236.823502,2788.041748 225.014130,2788.108398 
	C214.572006,2791.149414 204.992401,2787.535156 194.717529,2788.249023 
	C189.138428,2787.184326 184.336288,2789.540283 178.808685,2788.190430 
	C160.628418,2788.368652 143.435776,2784.862549 126.034058,2783.926514 
	C122.783249,2783.751465 122.194801,2781.929199 123.753090,2778.528320 
	C139.098114,2780.699951 154.180710,2781.946777 169.114700,2777.267334 
	C173.362000,2775.936768 177.614838,2776.228516 181.894562,2775.962158 
	C190.119644,2775.450684 198.461884,2777.295654 207.377151,2774.448975 
	C209.009995,2774.135010 209.848419,2774.115479 211.316925,2774.067139 
	C212.287567,2773.787598 212.628143,2773.536621 213.485077,2773.654541 
	C223.650726,2777.427490 233.512238,2777.073486 243.076111,2774.380859 
	C251.936646,2771.885742 260.463715,2771.340332 269.493469,2773.339844 
	C276.550110,2774.902832 283.887604,2775.233643 291.114960,2775.968262 
	C293.524567,2776.213379 295.699707,2776.650635 297.628448,2779.352783 
z"
        />
        <path
          fill="#3A4F62"
          opacity="1.000000"
          stroke="none"
          d="
M216.018311,2731.956543 
	C225.140289,2734.992432 234.857361,2733.696777 244.127991,2735.847656 
	C247.421310,2736.611572 250.731628,2736.503906 251.888840,2741.330811 
	C250.668839,2744.903809 248.378357,2746.052002 244.677460,2745.968262 
	C241.966843,2739.987549 236.597076,2740.498047 231.983276,2740.116699 
	C227.474380,2739.744141 222.863144,2740.324463 218.509399,2736.645752 
	C217.043655,2735.244873 215.917633,2734.549561 215.734314,2732.645508 
	C215.989319,2732.050537 216.004929,2731.987549 216.018311,2731.956543 
z"
        />
        <path
          fill="#243748"
          opacity="1.000000"
          stroke="none"
          d="
M451.502991,2745.822021 
	C451.700043,2746.855957 451.095673,2747.852539 450.250763,2749.372314 
	C446.357544,2750.300781 442.899536,2749.437988 438.730835,2748.189697 
	C437.375000,2747.293457 437.110962,2746.395508 437.766876,2744.892578 
	C442.596710,2743.406494 446.543457,2745.537598 451.502991,2745.822021 
z"
        />
        <path
          fill="#3A4F62"
          opacity="1.000000"
          stroke="none"
          d="
M437.473206,2744.275879 
	C438.216125,2745.213623 438.131989,2746.034180 438.011353,2747.477051 
	C433.797485,2752.111084 430.868805,2747.392090 426.096985,2747.304443 
	C429.665955,2742.707764 433.425720,2744.462891 437.473206,2744.275879 
z"
        />
        <path
          fill="#33495B"
          opacity="1.000000"
          stroke="none"
          d="
M295.801208,2859.233154 
	C296.009766,2860.009766 295.957977,2860.899902 295.955170,2861.345703 
	C292.621979,2865.591309 288.197479,2865.062988 283.088379,2864.768066 
	C279.481232,2864.027100 276.650604,2863.497559 273.366211,2865.759277 
	C269.196564,2867.145020 265.611023,2867.190186 261.242126,2867.063477 
	C254.407242,2866.256104 249.858475,2869.510742 245.364349,2872.802734 
	C240.428238,2876.418457 235.200317,2878.648682 228.401672,2876.299316 
	C228.137985,2872.166016 226.922760,2869.939453 222.911804,2870.009277 
	C220.935562,2870.043457 218.903152,2870.184570 216.545227,2868.585938 
	C216.330215,2864.394531 219.091415,2863.207764 222.033798,2862.428955 
	C223.600281,2862.013916 225.324585,2861.888184 226.943787,2862.027100 
	C241.299774,2863.259277 255.062195,2860.200439 268.877869,2856.843262 
	C276.931152,2854.886475 285.290314,2855.821533 293.508636,2856.537109 
	C294.253387,2856.601807 294.900269,2857.790039 295.801208,2859.233154 
z"
        />
        <path
          fill="#33495B"
          opacity="1.000000"
          stroke="none"
          d="
M207.346161,2868.033691 
	C207.744171,2870.870117 206.047394,2872.002686 202.636017,2871.882812 
	C202.281738,2869.079102 203.981873,2867.959717 207.346161,2868.033691 
z"
        />
        <path
          fill="#3C5163"
          opacity="1.000000"
          stroke="none"
          d="
M947.572693,2730.464600 
	C952.310364,2731.420654 956.363037,2731.719727 960.175049,2728.969727 
	C965.126038,2725.397705 970.156433,2725.785156 975.210388,2728.700928 
	C977.382385,2729.954102 980.534790,2729.520996 981.869263,2733.154297 
	C980.245117,2741.422119 973.936584,2738.377686 969.405151,2738.961426 
	C963.490662,2739.723633 956.910522,2738.101562 952.271667,2745.044434 
	C958.030151,2748.027100 963.748535,2746.321045 969.715576,2747.427734 
	C972.274414,2747.784668 974.121948,2748.197266 976.757690,2748.035645 
	C981.009277,2748.133545 984.432556,2748.869629 988.584839,2749.789062 
	C1001.955811,2756.940186 1015.437927,2753.187256 1028.289551,2751.895508 
	C1042.660400,2750.451172 1056.739746,2751.692627 1070.942627,2752.369629 
	C1073.261841,2752.479980 1076.933228,2752.917236 1076.383301,2755.340820 
	C1074.777710,2762.415771 1081.004150,2760.790527 1084.314453,2763.531738 
	C1085.847900,2766.315186 1085.637573,2768.389648 1083.570801,2770.874756 
	C1077.889893,2773.842285 1072.378174,2774.018799 1067.339355,2772.545410 
	C1055.516724,2769.088623 1043.579712,2771.563232 1031.764648,2770.764404 
	C1012.805664,2769.482666 994.152832,2774.492188 975.414368,2773.049316 
	C958.191528,2771.723145 941.136536,2770.285889 923.981567,2773.093262 
	C922.357544,2773.359131 920.656067,2773.129639 919.015869,2772.858154 
	C915.346924,2772.250732 910.605042,2771.472656 910.324036,2767.542236 
	C909.993958,2762.926270 915.450562,2763.819336 918.728210,2762.421875 
	C916.147644,2760.675537 912.923096,2761.038086 910.106812,2758.605957 
	C907.313232,2754.001465 909.266541,2751.138916 912.666443,2749.356201 
	C916.896545,2747.138916 917.948120,2743.271729 919.477417,2738.690430 
	C925.263123,2733.775879 931.708557,2732.712402 938.263184,2732.038818 
	C941.174438,2731.739746 944.209595,2732.543701 947.572693,2730.464600 
z"
        />
        <path
          fill="#495E72"
          opacity="1.000000"
          stroke="none"
          d="
M919.193726,2738.106934 
	C922.430847,2747.647461 922.442688,2747.673096 913.097168,2751.979248 
	C911.825989,2752.564941 910.789185,2753.216309 910.127197,2755.220703 
	C902.823669,2761.729004 894.048462,2758.259766 885.417114,2760.059082 
	C881.036987,2760.172852 877.326050,2757.630615 873.213379,2760.812500 
	C871.359131,2761.683838 870.036255,2761.940430 868.026123,2762.118896 
	C865.124451,2762.097656 863.091980,2762.853271 860.434937,2764.008057 
	C856.804199,2764.950684 853.889648,2764.854248 850.384521,2763.357910 
	C846.462463,2757.354492 839.983215,2757.604980 834.544373,2754.494629 
	C828.471008,2753.177490 823.516113,2750.098877 817.716309,2749.955566 
	C812.586853,2749.829346 807.121033,2751.254395 802.175903,2747.737549 
	C814.710754,2743.895264 826.060364,2742.992920 836.527527,2745.199463 
	C840.169189,2745.967529 843.769348,2746.385254 847.303711,2745.190430 
	C850.863586,2743.987061 854.484802,2744.118164 858.120178,2744.108887 
	C864.412659,2744.093018 870.635925,2744.247070 876.499451,2741.035645 
	C880.078064,2739.075439 884.323303,2740.270752 889.135498,2739.994629 
	C897.870239,2744.073242 905.589355,2744.448730 913.611328,2738.633545 
	C915.584229,2738.160889 917.028503,2738.128662 919.193726,2738.106934 
z"
        />
        <path
          fill="#233444"
          opacity="1.000000"
          stroke="none"
          d="
M637.267944,2764.487305 
	C642.785339,2762.689697 647.536682,2761.166016 653.122437,2763.726074 
	C659.171570,2763.430176 661.797058,2759.806641 663.990784,2754.853271 
	C665.321533,2752.430176 667.111877,2751.252686 669.116394,2751.276855 
	C691.687744,2751.547607 714.096252,2745.496582 737.554626,2749.783691 
	C744.247681,2755.386475 741.135498,2758.519043 735.600891,2760.909668 
	C722.097595,2766.742920 708.241638,2762.412598 694.828735,2762.624512 
	C696.115417,2763.957520 697.697205,2765.678223 695.776794,2768.849121 
	C677.515198,2772.656494 659.633728,2772.789062 640.874146,2772.786621 
	C633.206299,2769.172852 632.859863,2768.409668 637.267944,2764.487305 
z"
        />
        <path
          fill="#485D70"
          opacity="1.000000"
          stroke="none"
          d="
M740.382080,2751.281738 
	C715.260315,2750.864746 690.653259,2754.264160 665.021362,2754.126709 
	C660.432861,2753.188232 659.453979,2751.352051 662.929382,2749.005859 
	C665.531555,2747.248779 668.763611,2747.932861 671.702393,2748.045654 
	C687.746338,2748.661377 703.222778,2744.641846 718.820129,2742.019043 
	C729.271423,2740.261475 739.823914,2739.736572 751.107056,2738.023926 
	C753.038452,2739.433105 753.505249,2741.000244 752.919800,2743.523438 
	C746.577576,2749.315674 740.119690,2745.825684 734.243042,2745.581787 
	C736.376038,2746.713379 740.918518,2745.588867 740.382080,2751.281738 
z"
        />
        <path
          fill="#3C5163"
          opacity="1.000000"
          stroke="none"
          d="
M751.743896,2744.728027 
	C751.987610,2742.459473 751.987244,2740.982178 751.977783,2738.766602 
	C756.725342,2745.018066 763.412354,2746.905273 771.546936,2746.111816 
	C778.367554,2745.446533 784.576660,2750.916992 791.827393,2748.573242 
	C792.836792,2748.246826 794.750854,2749.897461 795.905945,2750.991699 
	C800.028625,2754.897705 804.898682,2756.411133 811.274292,2756.080322 
	C813.305725,2757.617920 813.607422,2759.310791 812.644531,2761.934570 
	C808.502319,2765.512451 804.330139,2764.804932 799.365479,2764.159668 
	C788.199219,2757.864990 776.654236,2756.417236 765.061218,2755.094971 
	C758.837769,2754.385010 753.218384,2752.676514 751.743896,2744.728027 
z"
        />
        <path
          fill="#3C5163"
          opacity="1.000000"
          stroke="none"
          d="
M833.446899,2754.527832 
	C837.591187,2754.001953 841.148499,2754.084229 844.703674,2754.029053 
	C849.142822,2753.959961 850.107605,2756.680908 849.990845,2761.185059 
	C848.521240,2765.927734 845.198364,2766.321045 840.862549,2766.282227 
	C835.325989,2764.194580 830.347717,2765.402832 824.674927,2764.909668 
	C822.137573,2764.706299 820.466431,2764.435791 818.253662,2763.068359 
	C816.506897,2760.989990 816.356812,2759.330322 817.431396,2756.850586 
	C822.872742,2755.528564 827.976318,2756.876709 833.446899,2754.527832 
z"
        />
        <path
          fill="#3C5163"
          opacity="1.000000"
          stroke="none"
          d="
M913.213135,2738.131348 
	C914.039062,2740.897705 913.550476,2743.462646 910.637695,2744.533936 
	C904.374084,2746.837646 897.988708,2746.598145 891.656006,2744.930908 
	C890.376892,2744.594238 890.286072,2742.883789 890.046936,2740.792969 
	C897.140686,2735.944580 904.871094,2738.845215 913.213135,2738.131348 
z"
        />
        <path
          fill="#3C5163"
          opacity="1.000000"
          stroke="none"
          d="
M908.502625,2731.810547 
	C908.082031,2726.859375 910.139587,2724.728760 915.534241,2727.579346 
	C914.608887,2730.931885 912.314514,2732.135742 908.502625,2731.810547 
z"
        />
        <path
          fill="#495E72"
          opacity="1.000000"
          stroke="none"
          d="
M817.178284,2756.109863 
	C817.923157,2757.551758 817.940918,2758.980225 817.940063,2761.124512 
	C816.644043,2763.253906 815.083679,2763.420166 812.730164,2762.331543 
	C812.076782,2760.366699 812.055237,2758.945557 812.060364,2756.817383 
	C813.541809,2756.105469 814.996460,2756.100830 817.178284,2756.109863 
z"
        />
        <path
          fill="#3C5163"
          opacity="1.000000"
          stroke="none"
          d="
M637.290161,2770.322754 
	C656.734863,2770.420410 675.315125,2766.551758 695.018799,2767.966309 
	C705.901245,2762.558350 715.531250,2762.587646 725.257324,2770.464355 
	C727.384766,2776.152832 728.060303,2781.164307 723.398560,2784.575928 
	C719.696167,2787.285156 716.002686,2785.683105 712.590576,2781.784180 
	C710.953674,2777.516846 709.686646,2773.627197 704.741333,2775.387695 
	C690.375305,2780.501465 675.080261,2777.495361 660.522461,2780.720215 
	C658.156311,2781.244629 654.674133,2780.499023 655.664551,2785.587891 
	C656.118652,2790.302490 659.332092,2792.328125 661.550659,2795.124756 
	C664.839172,2799.269775 663.490356,2803.790039 658.372253,2803.473633 
	C644.142883,2802.594482 629.616638,2802.332275 616.224609,2794.660645 
	C615.346985,2790.738281 617.760742,2788.569824 619.088562,2785.210938 
	C611.789246,2783.108398 604.285156,2783.874023 597.132690,2782.261230 
	C591.856873,2781.071777 592.768311,2778.737793 593.938354,2774.710205 
	C594.896667,2773.990234 595.766418,2773.990234 597.288879,2773.990234 
	C608.073792,2774.390137 618.116577,2776.231689 628.361511,2775.156982 
	C632.083008,2774.766602 634.435486,2773.241455 637.290161,2770.322754 
z"
        />
        <path
          fill="#2F4457"
          opacity="1.000000"
          stroke="none"
          d="
M637.728943,2769.562256 
	C638.056458,2773.992432 637.156006,2777.826416 632.374084,2777.738770 
	C621.219360,2777.534668 609.794006,2780.188232 598.482178,2774.539062 
	C603.411804,2770.256592 609.683044,2771.532715 615.252869,2772.532715 
	C620.880310,2773.543213 625.481995,2771.416016 630.614868,2770.508301 
	C610.259399,2764.548096 589.360901,2766.409668 567.014893,2765.891113 
	C574.486267,2761.074707 581.659485,2760.793457 588.414612,2760.215576 
	C599.265991,2759.287598 610.332275,2761.094238 621.037842,2757.991455 
	C627.803467,2756.030762 631.593079,2761.305420 637.257446,2763.997070 
	C637.060669,2765.659424 633.742676,2767.000977 637.728943,2769.562256 
z"
        />
        <path
          fill="#243748"
          opacity="1.000000"
          stroke="none"
          d="
M482.535034,2779.002930 
	C480.819672,2779.728760 479.586609,2779.686523 479.114410,2778.206543 
	C478.986481,2777.805420 479.460175,2776.838379 479.846954,2776.712402 
	C481.496338,2776.175537 482.969360,2776.253662 482.535034,2779.002930 
z"
        />
        <path
          fill="#4E6477"
          opacity="1.000000"
          stroke="none"
          d="
M213.805939,2773.716797 
	C213.659271,2774.026611 213.317108,2774.030029 212.460968,2774.035889 
	C212.346664,2773.418945 212.725464,2772.744385 213.805939,2773.716797 
z"
        />
        <path
          fill="#4E6477"
          opacity="1.000000"
          stroke="none"
          d="
M47.043839,2815.087891 
	C46.958885,2815.130859 47.128796,2815.044678 47.043839,2815.087891 
z"
        />
        <path
          fill="#3A4F62"
          opacity="1.000000"
          stroke="none"
          d="
M194.490753,2788.605957 
	C195.200958,2787.039795 196.688644,2784.916992 197.611237,2785.139404 
	C205.846619,2787.123779 214.481827,2784.380615 223.328979,2787.756836 
	C224.264114,2793.089355 221.319946,2794.418945 217.074661,2793.965088 
	C209.548981,2793.160645 202.068466,2792.087402 194.490753,2788.605957 
z"
        />
        <path
          fill="#607B93"
          opacity="1.000000"
          stroke="none"
          d="
M2571.312500,2472.803955 
	C2566.717041,2474.547119 2561.913574,2474.280273 2557.271729,2472.237305 
	C2561.954102,2469.348633 2566.379883,2473.167236 2571.312500,2472.803955 
z"
        />
        <path
          fill="#69849B"
          opacity="1.000000"
          stroke="none"
          d="
M2726.721191,2467.847168 
	C2726.242188,2466.581055 2726.376709,2465.218262 2727.912842,2465.008789 
	C2728.976318,2464.864258 2729.494629,2465.747559 2729.834229,2467.248535 
	C2729.057373,2467.822510 2728.207764,2467.849121 2726.721191,2467.847168 
z"
        />
        <path
          fill="#69849B"
          opacity="1.000000"
          stroke="none"
          d="
M2736.466309,2466.063232 
	C2736.343750,2465.408691 2736.749268,2464.744385 2737.857910,2465.743652 
	C2737.708740,2466.061035 2737.353760,2466.062988 2736.466309,2466.063232 
z"
        />
        <path
          fill="#4A667C"
          opacity="1.000000"
          stroke="none"
          d="
M2505.931641,2518.801758 
	C2517.925049,2519.196777 2529.759277,2521.036377 2541.626953,2521.283691 
	C2553.786377,2521.537109 2565.883789,2524.713379 2578.124512,2522.183594 
	C2580.363281,2521.720703 2582.822021,2521.743652 2585.091309,2522.101074 
	C2602.833008,2524.897217 2620.727783,2522.994385 2638.525146,2523.957520 
	C2641.217773,2524.103271 2643.697998,2524.543213 2646.858398,2527.230469 
	C2637.814453,2529.242432 2629.331299,2526.660645 2619.452637,2529.515381 
	C2640.290039,2537.164795 2660.506836,2531.482666 2680.508789,2535.908447 
	C2673.915771,2539.694336 2673.918945,2539.768799 2670.361084,2539.915527 
	C2662.426758,2540.243408 2654.380615,2539.078613 2646.626465,2541.785645 
	C2646.027100,2541.994873 2645.146240,2542.123047 2644.665283,2541.836914 
	C2635.615967,2536.450684 2626.705322,2541.140137 2617.956543,2542.595459 
	C2607.977051,2544.255371 2598.307129,2544.436523 2588.348389,2542.610840 
	C2580.236328,2541.123535 2571.908447,2540.812256 2562.808838,2539.975098 
	C2561.192383,2540.696533 2560.293457,2540.973633 2558.673828,2540.412842 
	C2556.329834,2537.751709 2553.813721,2538.083008 2550.701172,2537.941895 
	C2544.769043,2539.491211 2539.162354,2539.863281 2534.221924,2538.536133 
	C2519.428711,2534.562256 2504.073975,2537.145508 2489.252441,2533.841309 
	C2483.436279,2532.544678 2477.359375,2533.068848 2470.934082,2535.934082 
	C2463.654541,2537.016357 2457.849854,2533.783447 2451.646973,2533.094971 
	C2429.387207,2530.624512 2407.762451,2531.388916 2386.478271,2540.524170 
	C2374.038574,2545.863525 2359.688477,2544.320801 2346.171387,2545.190918 
	C2323.522949,2546.648926 2301.222656,2541.960205 2278.754883,2540.141846 
	C2275.127930,2539.848145 2271.510498,2539.177979 2267.983398,2538.162354 
	C2262.043457,2536.452393 2258.100098,2526.510498 2249.444580,2535.586426 
	C2244.798340,2540.458252 2235.090088,2536.427246 2228.398438,2541.968750 
	C2219.047607,2550.566650 2208.214844,2549.043457 2199.776367,2544.658936 
	C2184.220703,2536.575684 2168.340820,2536.708740 2152.064941,2538.355225 
	C2143.245850,2539.247314 2134.913086,2539.362061 2126.672852,2535.543945 
	C2120.863770,2532.852051 2114.336426,2532.349854 2108.088867,2532.060059 
	C2078.497070,2530.687256 2048.918945,2527.948242 2019.338623,2527.882812 
	C1998.751953,2527.837402 1978.164795,2530.592773 1957.563965,2527.472412 
	C1953.616943,2526.874512 1949.547485,2526.952881 1946.124634,2524.475342 
	C1944.442139,2523.257568 1943.004272,2521.708740 1943.451172,2519.511230 
	C1943.901123,2517.299316 1945.842529,2516.490967 1947.880859,2516.047119 
	C1949.870483,2515.613525 1951.913086,2515.566650 1953.821167,2516.170166 
	C1966.528076,2520.187988 1979.540649,2518.473145 1992.505493,2517.864746 
	C1977.328003,2519.246826 1962.456665,2517.890625 1947.453857,2515.234863 
	C1925.136108,2511.283447 1902.422607,2513.420898 1879.869873,2513.221680 
	C1874.498291,2513.174072 1869.084839,2513.027832 1865.310669,2507.086426 
	C1866.903198,2499.006592 1873.196655,2499.962891 1878.507202,2498.570801 
	C1900.664429,2492.761719 1923.455444,2498.396973 1945.591431,2494.706787 
	C1958.981934,2492.474609 1972.109863,2489.161377 1986.611328,2491.143066 
	C1992.691772,2492.325439 1997.029785,2496.474854 2003.507812,2495.866211 
	C2006.151367,2495.546387 2008.069580,2495.607910 2010.676514,2496.119385 
	C2013.606812,2497.204590 2015.890747,2497.752686 2019.105713,2497.122559 
	C2037.356323,2496.693115 2054.752686,2493.392578 2073.101562,2496.117676 
	C2087.314941,2504.856934 2101.598145,2504.182861 2115.913086,2499.896973 
	C2121.122070,2498.337402 2126.109375,2496.202637 2131.269531,2498.698730 
	C2139.232178,2502.550781 2147.626221,2501.968506 2155.673828,2500.650879 
	C2170.209473,2498.271484 2184.792480,2498.000244 2199.331543,2498.718750 
	C2219.200439,2499.700439 2239.009521,2494.868652 2258.856934,2498.525635 
	C2261.131592,2498.944824 2263.476807,2498.983643 2266.479004,2500.066650 
	C2269.873047,2501.957275 2270.687744,2504.577881 2271.417725,2508.043945 
	C2271.475098,2510.491455 2270.394531,2511.793457 2269.765137,2513.464844 
	C2274.482910,2512.656250 2279.547363,2514.163818 2284.204590,2510.114502 
	C2289.304199,2508.665039 2294.227295,2505.374756 2296.475586,2513.140137 
	C2295.329346,2514.349609 2292.607666,2514.495361 2294.888916,2515.784424 
	C2297.676758,2517.359863 2301.009277,2518.219727 2304.460449,2518.068604 
	C2307.807373,2517.921875 2310.859863,2518.749512 2314.086670,2523.293213 
	C2299.701660,2524.881104 2286.443115,2519.146484 2271.886719,2523.646484 
	C2276.651611,2526.635498 2280.682617,2527.955078 2284.670166,2527.839111 
	C2300.947021,2527.365479 2317.100830,2529.069580 2333.298828,2530.001953 
	C2337.366455,2530.236084 2341.157715,2529.743164 2344.534912,2527.001709 
	C2346.578369,2525.343018 2349.116455,2524.192627 2351.859863,2524.132080 
	C2372.991211,2523.666016 2394.083252,2519.416504 2415.259033,2523.423584 
	C2417.328125,2523.814941 2419.176514,2523.209961 2421.099121,2522.667236 
	C2428.120117,2520.686035 2434.890625,2523.804443 2441.741455,2523.797119 
	C2456.538574,2523.781494 2471.405273,2524.700439 2486.123779,2522.339355 
	C2489.291992,2521.831299 2491.950439,2523.507324 2494.880127,2523.705322 
	C2499.067627,2523.988281 2503.795166,2525.211182 2505.931641,2518.801758 
z"
        />
        <path
          fill="#3C556B"
          opacity="1.000000"
          stroke="none"
          d="
M2296.990234,2513.888672 
	C2295.605225,2511.114014 2294.090820,2509.853516 2291.249268,2510.530273 
	C2289.349365,2510.982666 2287.451172,2511.442139 2284.809570,2511.922852 
	C2279.657471,2513.701904 2275.347168,2513.433838 2270.554443,2510.698730 
	C2269.085205,2507.975342 2267.863281,2505.963623 2268.096680,2502.842285 
	C2280.121338,2498.958252 2292.397949,2500.614014 2304.352051,2501.507568 
	C2317.948730,2502.523193 2331.465576,2503.161865 2345.063477,2502.733154 
	C2349.130127,2502.605225 2353.004150,2503.184082 2357.162109,2506.134766 
	C2359.106201,2508.496582 2359.187988,2510.203613 2356.845215,2512.038086 
	C2345.007568,2512.096680 2334.047852,2511.479980 2323.464111,2515.107178 
	C2320.545654,2516.107666 2317.569580,2516.080078 2314.667236,2515.122314 
	C2309.160889,2513.305420 2303.477783,2514.306152 2296.990234,2513.888672 
z"
        />
        <path
          fill="#4C6880"
          opacity="1.000000"
          stroke="none"
          d="
M2356.161621,2512.939209 
	C2356.560547,2511.214111 2357.198730,2510.236328 2357.890137,2508.658691 
	C2359.169678,2502.443848 2352.513428,2502.694580 2351.099609,2498.250488 
	C2352.720459,2492.135254 2357.736328,2490.074951 2362.435547,2486.641602 
	C2368.670654,2486.371094 2373.919922,2488.120605 2379.455322,2488.146484 
	C2382.900146,2488.162598 2386.456055,2489.931885 2386.482910,2495.189697 
	C2385.082275,2495.967529 2384.077637,2495.922852 2383.091309,2496.035645 
	C2381.516846,2496.215820 2379.392578,2495.868896 2379.094971,2498.039551 
	C2378.752441,2500.534912 2380.828369,2501.276855 2383.464355,2502.015137 
	C2385.735107,2513.886230 2384.729004,2515.495605 2372.469971,2515.777832 
	C2367.173340,2515.899658 2361.203369,2518.357666 2356.161621,2512.939209 
z"
        />
        <path
          fill="#5B768D"
          opacity="1.000000"
          stroke="none"
          d="
M2386.815430,2495.944824 
	C2384.648193,2490.651367 2379.820068,2490.597900 2375.963623,2490.277588 
	C2371.722412,2489.925293 2367.781982,2488.524658 2362.823242,2488.003418 
	C2349.378906,2492.874756 2335.982178,2492.552490 2322.827393,2493.257080 
	C2309.621826,2493.964111 2296.352783,2493.397461 2283.093018,2493.998535 
	C2302.818604,2492.840088 2322.289795,2498.096924 2342.053955,2495.894531 
	C2345.035645,2495.562500 2348.021240,2495.312500 2351.364990,2497.520508 
	C2353.594727,2500.102051 2356.090576,2499.543213 2358.177734,2500.224121 
	C2363.111816,2501.833252 2363.027100,2504.567871 2358.646729,2507.880615 
	C2347.549561,2504.712891 2336.808594,2505.857910 2326.228271,2506.022461 
	C2309.593994,2506.280518 2293.156250,2502.672363 2276.487305,2503.925049 
	C2274.290527,2504.090088 2271.992432,2502.907715 2268.925781,2502.250000 
	C2260.851562,2501.750488 2253.504883,2502.637207 2246.379150,2500.241211 
	C2244.576416,2499.634766 2242.363770,2499.747559 2240.437012,2500.085693 
	C2218.991943,2503.850586 2197.367432,2501.199463 2175.842285,2501.976074 
	C2171.203613,2502.143555 2166.614014,2501.980957 2162.073730,2503.534424 
	C2154.516113,2506.120117 2146.360596,2503.058594 2138.702881,2505.710693 
	C2136.735352,2506.392334 2135.079590,2504.828369 2133.467041,2503.498535 
	C2128.222412,2499.173096 2122.813232,2498.312500 2117.453857,2503.453613 
	C2115.976074,2504.871094 2114.231689,2505.853760 2112.140137,2505.879883 
	C2100.542969,2506.024414 2088.922363,2506.621338 2077.486084,2503.840576 
	C2075.140381,2503.270020 2074.257568,2501.802246 2073.972168,2498.859863 
	C2073.760010,2496.464111 2074.030518,2494.937500 2075.273926,2492.904297 
	C2087.461182,2484.016602 2099.428955,2488.863037 2111.154541,2492.055908 
	C2114.355469,2492.927490 2117.603027,2494.904541 2120.672363,2493.799316 
	C2131.192871,2490.011230 2141.713867,2492.560059 2153.364746,2493.319580 
	C2141.994873,2491.353271 2131.820557,2489.846191 2121.597656,2493.689209 
	C2119.005859,2494.663574 2114.978516,2494.760010 2113.772705,2491.285156 
	C2112.222900,2486.819824 2116.190918,2485.971191 2118.142578,2485.279541 
	C2096.271729,2484.542725 2072.788086,2480.484619 2050.550293,2493.294189 
	C2047.721069,2494.357178 2045.593994,2494.374756 2042.738281,2493.188965 
	C2038.962158,2488.221436 2041.470703,2484.752930 2044.440552,2481.647705 
	C2046.645264,2479.342773 2049.453125,2477.633789 2052.236084,2474.639404 
	C2081.962402,2469.468750 2110.989258,2467.766357 2140.860352,2473.042480 
	C2144.731445,2477.335693 2148.422363,2479.984863 2154.563477,2478.896973 
	C2179.553711,2471.850342 2204.315674,2476.112549 2228.807861,2475.089355 
	C2230.827148,2475.004883 2233.739746,2475.240723 2234.321045,2476.773926 
	C2237.588135,2485.386963 2244.717285,2482.207764 2250.632568,2482.512939 
	C2270.612793,2483.543945 2290.448242,2480.897705 2307.821777,2479.840576 
	C2291.494873,2482.915283 2272.190430,2482.189941 2254.003174,2478.738770 
	C2231.624756,2474.491943 2208.988770,2477.376709 2186.913818,2472.737793 
	C2179.348389,2471.147949 2171.650391,2471.868408 2164.047607,2471.140869 
	C2160.510254,2470.802246 2155.868896,2470.738037 2155.450195,2466.094238 
	C2154.989502,2460.982910 2159.459473,2459.596924 2163.510254,2459.095703 
	C2173.427734,2457.867676 2183.379395,2456.919678 2193.210449,2459.923096 
	C2198.644775,2461.583008 2204.208008,2462.914062 2209.908936,2462.384033 
	C2237.623047,2459.807373 2264.629395,2467.611572 2292.216553,2467.737305 
	C2318.369141,2467.856445 2344.521240,2470.946533 2371.308350,2466.510742 
	C2388.043945,2467.509521 2404.020264,2470.060547 2421.133545,2470.013184 
	C2423.232422,2474.911621 2427.149658,2477.113281 2431.363037,2477.693115 
	C2448.939453,2480.111572 2466.431152,2483.343506 2485.111328,2483.960449 
	C2485.500000,2487.376221 2483.115234,2488.053955 2480.187012,2487.983154 
	C2467.602539,2487.678711 2454.885986,2489.330322 2442.564453,2485.242920 
	C2435.426270,2482.874756 2428.024902,2484.161865 2420.746582,2484.035889 
	C2412.712646,2483.896240 2404.792725,2483.691895 2397.712402,2479.195557 
	C2397.158203,2478.843994 2396.523438,2478.485840 2395.892090,2478.408691 
	C2384.254150,2476.989502 2373.129395,2479.548584 2362.011230,2482.490479 
	C2367.675781,2484.351807 2373.035156,2486.414795 2379.133057,2484.374512 
	C2382.493164,2483.250000 2386.538818,2483.386230 2388.809814,2487.381104 
	C2390.374023,2490.132568 2394.612549,2489.308594 2395.888184,2493.314453 
	C2393.377197,2495.390381 2390.588867,2496.214355 2386.815430,2495.944824 
z"
        />
        <path
          fill="#4C6880"
          opacity="1.000000"
          stroke="none"
          d="
M2496.505859,2474.503662 
	C2500.336182,2470.828613 2505.736328,2471.640381 2511.294434,2470.116211 
	C2508.388428,2475.865723 2502.915039,2476.109375 2496.505859,2474.503662 
z"
        />
        <path
          fill="#4C6880"
          opacity="1.000000"
          stroke="none"
          d="
M2679.339844,2520.123291 
	C2679.558594,2524.068359 2683.917236,2525.088135 2684.910889,2528.102295 
	C2677.088867,2530.578613 2677.088867,2530.578613 2673.878662,2521.087402 
	C2675.379150,2520.259277 2676.963379,2520.159668 2679.339844,2520.123291 
z"
        />
        <path
          fill="#445E74"
          opacity="1.000000"
          stroke="none"
          d="
M2142.676270,2476.285645 
	C2139.057617,2474.401855 2135.825195,2474.063965 2132.449951,2473.971436 
	C2119.234619,2473.609619 2105.921875,2475.342529 2092.822510,2472.088379 
	C2090.968506,2471.627930 2088.463867,2471.466797 2086.945557,2472.347900 
	C2076.400391,2478.466309 2064.921143,2475.150146 2052.941406,2476.011230 
	C2044.459961,2480.531006 2036.368164,2476.992432 2028.552368,2477.445801 
	C2019.238281,2477.986572 2010.010742,2475.875977 2000.729614,2475.098633 
	C1994.313354,2474.561523 1988.702515,2472.208984 1991.504150,2462.943359 
	C1994.175171,2462.140137 1996.285767,2462.096191 1999.188232,2462.060303 
	C2003.478760,2464.914551 2007.222412,2464.466553 2011.514648,2462.426270 
	C2022.994019,2458.151123 2034.226440,2460.045166 2045.367310,2460.896729 
	C2054.604004,2461.602783 2063.816895,2463.921631 2073.001709,2463.725586 
	C2089.342285,2463.376709 2105.452393,2466.126709 2121.741211,2466.157715 
	C2128.319336,2466.170166 2134.768555,2468.741211 2141.505371,2468.004639 
	C2143.395508,2467.798096 2145.363037,2468.013672 2147.021729,2466.263184 
	C2130.395264,2457.900635 2112.666992,2460.723145 2095.628662,2459.704102 
	C2095.542725,2455.429443 2098.116211,2456.066650 2099.829346,2456.094482 
	C2106.180664,2456.197021 2112.474854,2455.948242 2118.547852,2453.908203 
	C2123.716553,2452.171631 2128.840088,2451.176025 2134.077881,2453.613525 
	C2134.653564,2453.881104 2135.428955,2454.006592 2136.038574,2453.870605 
	C2149.215820,2450.922119 2162.374268,2452.055664 2175.551025,2453.880859 
	C2176.713867,2454.041992 2178.278076,2453.869873 2178.668457,2451.877930 
	C2177.339111,2448.588867 2172.935791,2451.299072 2171.008789,2447.778564 
	C2176.139648,2444.566650 2181.824463,2445.384521 2186.832520,2446.876465 
	C2196.919434,2449.881104 2207.135986,2450.655762 2217.422363,2449.906982 
	C2229.812744,2449.004883 2241.851807,2452.745850 2255.083252,2451.987061 
	C2258.202148,2451.716064 2258.770996,2453.084961 2258.618652,2455.670166 
	C2257.933594,2456.802979 2257.526855,2457.162354 2256.398438,2457.883301 
	C2248.047607,2459.911377 2240.639404,2457.525635 2233.121582,2457.085449 
	C2240.656006,2457.744629 2248.126709,2459.104492 2256.562012,2459.043457 
	C2280.554199,2458.501221 2303.633545,2461.283447 2326.801514,2460.595947 
	C2331.687988,2460.451172 2336.893066,2460.386963 2341.386719,2465.149414 
	C2351.407715,2465.848389 2360.764404,2465.935059 2371.050781,2466.015625 
	C2371.571533,2468.824951 2369.795654,2470.043457 2367.114258,2469.882324 
	C2359.679932,2469.436035 2352.702637,2471.920898 2345.522949,2473.106201 
	C2343.900146,2473.374023 2342.101562,2473.973389 2340.632812,2473.552246 
	C2330.794678,2470.730469 2320.757080,2472.142578 2310.823975,2472.052002 
	C2294.201172,2471.900146 2277.679932,2470.004639 2261.065186,2469.492432 
	C2250.339600,2469.161621 2239.588867,2465.915771 2228.659180,2465.067871 
	C2225.689209,2464.837402 2222.924072,2464.277832 2219.869629,2464.954834 
	C2211.297607,2466.854248 2202.629395,2466.990479 2194.344238,2463.741699 
	C2183.303223,2459.413086 2172.066406,2462.260254 2160.910645,2462.332275 
	C2158.963623,2462.344727 2156.820801,2463.445801 2157.336914,2466.045166 
	C2157.752197,2468.135010 2159.854736,2467.987793 2161.416992,2467.915039 
	C2184.400391,2466.846191 2206.848633,2474.478760 2229.914551,2472.119385 
	C2231.875244,2471.919189 2234.236572,2471.664551 2235.831787,2472.518311 
	C2245.226074,2477.549072 2255.456055,2474.858643 2265.203369,2476.216309 
	C2281.782715,2478.525879 2298.382080,2477.730225 2314.980225,2478.049805 
	C2318.691895,2478.121338 2321.760010,2479.749023 2325.146240,2481.116455 
	C2324.317139,2482.385742 2323.881836,2483.361328 2323.680908,2483.315186 
	C2310.705566,2480.339844 2297.981689,2482.523193 2285.319824,2485.242676 
	C2280.675781,2486.239746 2276.036133,2485.912354 2271.394043,2485.947998 
	C2259.401367,2486.039795 2247.407227,2486.041504 2235.414795,2485.932861 
	C2232.660645,2485.907959 2229.531982,2486.809814 2227.190674,2484.460693 
	C2228.658203,2480.449219 2233.274902,2483.484375 2235.155518,2479.073730 
	C2226.271484,2477.002930 2217.451660,2478.670654 2208.834473,2477.963135 
	C2199.910889,2477.230225 2191.058105,2479.732422 2182.125244,2478.141846 
	C2172.925293,2476.503174 2164.615723,2481.540039 2154.845459,2481.973145 
	C2151.330078,2478.369141 2146.820068,2478.465820 2142.676270,2476.285645 
z"
        />
        <path
          fill="#020914"
          opacity="1.000000"
          stroke="none"
          d="
M1799.434937,2379.402100 
	C1806.655151,2377.239014 1812.875488,2374.367920 1819.790161,2373.289551 
	C1825.818970,2372.349854 1831.482178,2372.160889 1837.547729,2375.364746 
	C1842.084473,2385.588379 1839.345947,2395.526855 1838.600586,2405.211426 
	C1837.682373,2417.138672 1836.320801,2428.888184 1840.165771,2440.538330 
	C1842.434692,2447.413086 1838.880249,2454.232178 1840.308594,2461.791748 
	C1843.187988,2468.955566 1839.004150,2473.821777 1836.357788,2479.866699 
	C1831.802490,2487.229980 1825.819946,2491.028809 1817.174561,2491.775879 
	C1813.244507,2491.229492 1810.419312,2490.015381 1807.485596,2487.355469 
	C1799.925293,2467.667969 1783.655640,2454.781982 1773.640869,2437.652832 
	C1772.475342,2435.659668 1770.791138,2433.969482 1771.103516,2430.778320 
	C1771.926514,2429.432861 1772.651001,2428.902588 1774.122803,2428.353027 
	C1780.473267,2422.174072 1775.299316,2415.655762 1775.738525,2408.718506 
	C1776.869507,2402.819580 1779.986816,2398.479248 1782.191406,2392.992432 
	C1787.337402,2387.325684 1792.866821,2383.198975 1799.434937,2379.402100 
z"
        />
        <path
          fill="#4A667C"
          opacity="1.000000"
          stroke="none"
          d="
M1833.665527,2480.254395 
	C1835.521240,2474.894287 1837.718262,2470.166992 1839.993530,2464.727539 
	C1839.417358,2460.857422 1840.057007,2458.168945 1843.597168,2456.107910 
	C1849.310547,2455.948486 1854.307739,2455.917480 1859.304199,2455.971924 
	C1862.902588,2456.011230 1866.695557,2455.382812 1868.581787,2459.911865 
	C1869.470093,2462.044922 1871.857056,2462.212158 1873.821533,2461.612549 
	C1883.959473,2458.519043 1894.343140,2460.533691 1904.598389,2460.000244 
	C1908.901855,2459.776367 1912.938354,2460.106445 1916.364380,2456.177734 
	C1920.237305,2451.736084 1926.438110,2454.201660 1931.514893,2452.819580 
	C1938.575562,2450.896973 1945.796997,2454.398682 1953.025391,2454.943604 
	C1954.233154,2455.034912 1955.111206,2456.495117 1954.977173,2458.109863 
	C1953.343872,2460.396484 1951.448730,2458.312988 1949.653931,2458.303711 
	C1947.768677,2458.293945 1945.188354,2457.605713 1945.192383,2460.572266 
	C1945.195557,2462.975830 1947.479004,2463.691162 1949.510742,2463.789307 
	C1953.499512,2463.982666 1957.500244,2463.919678 1961.496094,2463.971191 
	C1965.158813,2464.018311 1969.214844,2463.079346 1972.402100,2464.336914 
	C1979.102417,2466.981445 1984.720093,2464.748779 1991.305664,2462.219238 
	C1992.932007,2471.582520 1993.148071,2471.215088 2001.847168,2472.112793 
	C2017.980957,2473.778809 2034.302490,2472.953369 2051.165039,2475.974121 
	C2051.868896,2478.291260 2052.181396,2480.926514 2049.300293,2481.754150 
	C2044.820557,2483.041504 2043.453735,2486.712402 2042.004639,2491.206299 
	C2038.229126,2495.885010 2033.660889,2495.282227 2028.323120,2494.211914 
	C2023.245117,2491.620117 2024.490479,2488.230957 2026.088379,2484.654053 
	C2022.004395,2485.175049 2017.997192,2484.896240 2013.934204,2487.649902 
	C2010.265625,2488.971924 2007.176270,2489.064697 2003.270508,2488.769043 
	C1998.856689,2488.110840 1995.269897,2487.323242 1990.915771,2486.462646 
	C1989.709961,2486.071045 1989.248535,2485.874268 1988.168945,2485.274902 
	C1980.347412,2476.934814 1961.466553,2475.486572 1955.820068,2482.959961 
	C1949.890625,2486.061523 1944.017212,2486.040771 1938.687256,2484.512939 
	C1921.793335,2479.671631 1905.028442,2479.109131 1888.144287,2484.606689 
	C1900.409912,2480.888916 1912.772461,2479.644531 1925.395264,2481.940186 
	C1928.675293,2482.536865 1932.601196,2481.972900 1933.111206,2486.386719 
	C1933.675537,2491.270264 1929.696655,2492.403809 1926.012939,2493.008545 
	C1904.903564,2496.475098 1883.688232,2495.238770 1862.504150,2494.027100 
	C1856.244629,2493.668701 1850.130615,2491.989014 1843.721802,2492.328857 
	C1832.726685,2492.911865 1831.394165,2491.111816 1833.665527,2480.254395 
z"
        />
        <path
          fill="#465F75"
          opacity="1.000000"
          stroke="none"
          d="
M2250.239746,2427.246094 
	C2241.552002,2431.731201 2232.592041,2429.264893 2222.961670,2430.053467 
	C2219.589355,2432.445068 2216.474121,2431.894775 2212.751953,2431.980469 
	C2201.002197,2431.989014 2190.003174,2431.912109 2179.007080,2432.055420 
	C2173.150879,2432.131836 2167.166260,2430.902100 2161.804688,2435.197998 
	C2159.597168,2436.966309 2155.945312,2436.235840 2153.062988,2435.267334 
	C2150.157959,2434.291016 2147.096680,2433.360352 2144.244629,2434.118164 
	C2129.900635,2437.928711 2115.938477,2434.145264 2101.870850,2432.394531 
	C2099.368652,2432.083008 2097.262451,2432.450439 2095.710205,2434.635498 
	C2094.281006,2436.646973 2092.105957,2438.559814 2089.875732,2437.725586 
	C2082.414551,2434.935303 2074.796631,2438.867920 2067.437256,2436.748291 
	C2064.733643,2435.969971 2062.246094,2436.342773 2059.606934,2437.034424 
	C2057.389160,2437.615967 2054.877197,2438.254395 2052.744629,2437.770264 
	C2045.124634,2436.040283 2037.908936,2434.521240 2031.310181,2440.938721 
	C2029.266479,2442.926514 2025.557983,2442.107422 2022.624756,2441.170166 
	C2017.366821,2439.490723 2012.227539,2440.707275 2006.911865,2441.096924 
	C1991.203369,2442.249268 1975.456787,2444.638184 1958.834961,2442.020508 
	C1954.352539,2442.370361 1950.612915,2443.581055 1946.597412,2440.521484 
	C1938.803711,2440.500244 1931.603638,2440.716553 1924.470337,2441.523682 
	C1913.835693,2442.727051 1903.422119,2441.946045 1892.533936,2438.681152 
	C1891.782837,2432.746826 1897.382324,2433.226807 1900.641357,2430.133301 
	C1940.658081,2429.043457 1979.310425,2419.808838 2018.743896,2422.338623 
	C2027.008423,2422.868896 2035.381348,2422.875000 2044.531006,2421.374023 
	C2061.559814,2420.116211 2077.580322,2417.065186 2093.925781,2419.739258 
	C2100.420166,2420.801758 2107.342285,2419.583496 2113.370117,2416.780029 
	C2125.476318,2411.150146 2137.745117,2411.649414 2151.125977,2414.546631 
	C2158.356445,2414.962158 2164.652588,2415.907959 2171.818359,2416.951172 
	C2193.236572,2418.782227 2213.786865,2420.441406 2235.287842,2420.681152 
	C2238.400635,2421.134277 2240.678711,2421.092041 2243.776611,2421.321289 
	C2245.151611,2421.529785 2245.694092,2421.654297 2246.994141,2422.124756 
	C2249.189697,2423.432861 2250.093750,2424.755615 2250.239746,2427.246094 
z"
        />
        <path
          fill="#49647C"
          opacity="1.000000"
          stroke="none"
          d="
M2417.890137,2434.027100 
	C2429.017822,2435.775146 2438.538818,2432.466797 2447.940918,2429.748779 
	C2459.397461,2426.436768 2470.296631,2429.420410 2481.439697,2430.784180 
	C2502.891113,2433.409668 2524.423584,2434.744873 2546.046143,2433.804932 
	C2547.651855,2433.735352 2549.483154,2433.315918 2550.774170,2432.427002 
	C2560.808838,2425.515869 2571.675049,2428.222656 2582.549072,2429.090088 
	C2596.420410,2430.197510 2610.187744,2433.000000 2624.208252,2431.973877 
	C2630.600830,2431.505859 2636.707764,2432.607422 2642.489014,2434.981689 
	C2642.530762,2437.804443 2640.710205,2437.920898 2639.119141,2437.876953 
	C2625.493652,2437.503418 2611.819336,2439.211182 2598.247803,2437.045654 
	C2596.072998,2436.698486 2594.229736,2436.771240 2592.762451,2438.344238 
	C2587.886963,2443.570801 2581.704102,2442.336914 2575.880371,2441.474854 
	C2555.969971,2438.528564 2536.147461,2439.691895 2516.321289,2442.114990 
	C2515.369629,2442.231201 2513.934814,2442.444824 2513.617432,2443.067871 
	C2510.237793,2449.699951 2504.102783,2447.881104 2498.719238,2447.949463 
	C2479.439209,2448.194092 2460.249512,2445.969727 2440.978760,2445.428955 
	C2429.447021,2445.105225 2417.804932,2444.210938 2406.196289,2444.051758 
	C2399.517822,2443.960205 2393.061768,2442.309814 2386.461182,2441.643066 
	C2368.628174,2439.842773 2350.768555,2438.733887 2332.882812,2438.069824 
	C2327.230469,2437.860107 2322.627197,2435.328369 2317.858154,2433.152100 
	C2315.715820,2432.174561 2316.050049,2429.548340 2318.458008,2428.630127 
	C2320.242920,2427.949951 2322.375488,2428.054932 2324.355225,2428.035156 
	C2331.352295,2427.965088 2338.350342,2427.964355 2345.348145,2427.994629 
	C2348.964844,2428.010254 2352.534668,2427.545654 2354.712646,2431.903809 
	C2356.297607,2435.075195 2360.051758,2433.973145 2362.927002,2433.986572 
	C2380.920898,2434.070068 2398.915283,2434.029541 2417.890137,2434.027100 
z"
        />
        <path
          fill="#536F86"
          opacity="1.000000"
          stroke="none"
          d="
M2294.206543,2392.762695 
	C2319.164307,2389.311768 2344.408203,2390.374756 2369.612793,2389.839600 
	C2376.445801,2389.694580 2383.295166,2386.828613 2389.707275,2391.468018 
	C2390.150635,2391.788818 2391.247559,2391.849609 2391.616455,2391.534424 
	C2397.919434,2386.154785 2404.946289,2390.604248 2411.585205,2390.012939 
	C2413.896484,2389.806885 2416.481689,2390.550537 2418.520752,2389.765869 
	C2429.825928,2385.414062 2440.917236,2389.763916 2451.975342,2390.655518 
	C2463.136963,2391.555176 2474.314697,2392.839355 2485.469482,2394.083496 
	C2498.470459,2395.533203 2511.710449,2394.017334 2525.290771,2397.665283 
	C2525.499756,2403.452148 2521.280029,2405.182373 2516.979248,2405.694824 
	C2500.551025,2407.653564 2484.197998,2409.423340 2467.514648,2406.618652 
	C2459.464355,2405.265137 2451.142822,2404.315918 2442.866211,2403.996338 
	C2439.172363,2403.853516 2435.587158,2404.189209 2432.127197,2405.664062 
	C2430.571045,2406.327393 2428.854492,2406.755859 2428.876953,2408.811035 
	C2428.900391,2410.936035 2431.195312,2414.080078 2428.099854,2414.812988 
	C2425.044922,2415.536621 2420.390137,2414.620850 2419.395752,2411.541504 
	C2417.507812,2405.695312 2412.926514,2406.594482 2409.088135,2406.291016 
	C2402.474854,2405.768066 2395.796875,2406.066650 2389.146240,2406.007812 
	C2383.546143,2405.958496 2377.964355,2406.544189 2372.857422,2402.933594 
	C2370.112061,2400.992432 2366.243408,2401.531250 2363.090576,2402.123291 
	C2344.285889,2405.656006 2326.468994,2399.067383 2308.277588,2396.767090 
	C2303.773682,2396.197754 2298.056641,2398.404541 2294.206543,2392.762695 
z"
        />
        <path
          fill="#4A667C"
          opacity="1.000000"
          stroke="none"
          d="
M1847.773071,2394.657715 
	C1853.263428,2386.476562 1853.442505,2386.427246 1860.938599,2391.491943 
	C1862.030396,2392.229736 1863.249146,2393.712158 1864.388916,2392.783203 
	C1870.804077,2387.554443 1879.596069,2392.013672 1886.173584,2387.162354 
	C1888.912720,2385.141846 1892.642212,2386.142578 1895.947510,2385.961182 
	C1905.875244,2385.415771 1915.799683,2386.693359 1925.794678,2384.817139 
	C1930.453003,2383.942871 1935.694580,2386.177002 1940.637207,2388.230225 
	C1922.242310,2390.595703 1903.714722,2390.019531 1885.563477,2392.453369 
	C1885.417725,2396.585693 1888.015259,2395.974609 1889.690796,2395.908447 
	C1904.271851,2395.335938 1918.945923,2397.632568 1933.459961,2394.296143 
	C1936.258179,2393.652832 1939.674927,2393.712891 1942.319336,2394.723145 
	C1955.933472,2399.923828 1970.172729,2399.448730 1984.315674,2399.924316 
	C1985.921631,2399.978027 1988.210327,2399.931641 1989.065552,2398.954590 
	C1996.647583,2390.289795 2006.817627,2393.270996 2015.236084,2394.746582 
	C2030.196777,2397.368408 2044.659546,2398.901123 2059.372803,2394.246826 
	C2061.503662,2393.572754 2064.005371,2394.071289 2067.206787,2394.062500 
	C2071.702393,2397.386475 2077.674805,2395.103516 2081.180420,2400.236328 
	C2081.903564,2402.738037 2081.660400,2404.391113 2080.200439,2406.595703 
	C2071.397217,2410.433105 2062.487549,2412.457031 2054.263184,2410.383545 
	C2039.230957,2406.593994 2024.510986,2409.687012 2009.711914,2410.166260 
	C1989.917847,2410.807129 1970.154541,2411.601318 1950.336060,2411.098877 
	C1929.420410,2410.568604 1908.328491,2409.479736 1887.641968,2412.286621 
	C1873.503906,2414.205322 1862.387695,2409.949219 1850.521484,2402.419434 
	C1847.841797,2400.164795 1846.698730,2398.105225 1847.773071,2394.657715 
z"
        />
        <path
          fill="#536F86"
          opacity="1.000000"
          stroke="none"
          d="
M2250.741699,2428.351562 
	C2249.505615,2427.044922 2248.972168,2426.051514 2248.209717,2424.543457 
	C2248.675049,2414.882568 2242.073975,2416.392822 2237.234131,2417.498291 
	C2222.126709,2420.949219 2206.861328,2419.591797 2191.645752,2420.143066 
	C2185.366699,2420.370361 2179.471191,2415.903320 2172.550781,2419.532715 
	C2165.691895,2420.995605 2159.903076,2417.436035 2152.812988,2417.979492 
	C2148.860596,2411.961182 2152.760498,2410.079590 2157.449707,2409.287842 
	C2162.031738,2408.514160 2166.744385,2408.891602 2171.388672,2409.034912 
	C2190.896484,2409.636230 2210.309570,2408.855225 2221.612061,2406.252686 
	C2203.989258,2411.115479 2178.014893,2408.359131 2152.205322,2409.148926 
	C2147.827881,2409.282715 2143.531006,2409.455322 2139.612061,2405.433594 
	C2143.046631,2393.981934 2151.759766,2395.221191 2161.171387,2395.992676 
	C2174.883545,2395.495605 2187.753174,2397.080322 2201.248047,2394.559082 
	C2204.615723,2394.030518 2207.211426,2394.150879 2209.834717,2394.887207 
	C2218.821289,2397.407959 2227.992432,2398.264160 2237.346680,2398.044678 
	C2258.563721,2397.545898 2279.627441,2399.782471 2300.674072,2402.122803 
	C2302.008545,2402.271240 2303.281494,2402.612305 2304.164551,2404.003906 
	C2304.472900,2406.819092 2300.147705,2406.037109 2300.095947,2409.371582 
	C2294.933838,2414.777832 2288.814209,2413.175537 2283.155273,2412.252441 
	C2277.690430,2411.360840 2272.292236,2410.799316 2267.250244,2411.042236 
	C2274.122803,2410.248047 2281.088379,2412.746826 2288.249512,2413.540283 
	C2292.348145,2413.994385 2295.660400,2416.234375 2296.282471,2421.440430 
	C2292.446045,2425.399902 2287.392334,2425.032959 2283.402832,2427.040771 
	C2282.271729,2430.359131 2287.121094,2429.543701 2286.623047,2433.111816 
	C2281.563965,2433.972900 2276.366211,2436.612305 2273.106689,2429.042236 
	C2271.656250,2425.673096 2268.474365,2425.549072 2265.575439,2427.625000 
	C2261.030762,2430.879395 2256.318848,2430.562988 2250.741699,2428.351562 
z"
        />
        <path
          fill="#4C6880"
          opacity="1.000000"
          stroke="none"
          d="
M2258.462402,2456.889160 
	C2257.697266,2454.985596 2257.319336,2453.861084 2256.465820,2452.363281 
	C2257.130859,2445.438232 2263.589600,2449.739258 2266.900146,2446.434814 
	C2265.289551,2442.067871 2258.328613,2445.068115 2257.104492,2439.046875 
	C2262.487549,2437.626221 2267.723633,2436.946045 2272.872559,2439.983154 
	C2278.207031,2443.129639 2283.929199,2444.127930 2290.056396,2444.029541 
	C2300.365723,2443.864014 2310.679932,2443.987793 2320.992188,2444.034424 
	C2324.968506,2444.052490 2328.931396,2443.881836 2332.833008,2445.226562 
	C2336.357178,2446.440918 2339.974121,2446.003662 2343.637451,2445.188477 
	C2352.489746,2443.218262 2361.450439,2443.206299 2370.289551,2445.158203 
	C2375.923584,2446.402344 2381.546631,2445.883789 2387.172852,2445.931152 
	C2392.602295,2445.976562 2397.691650,2446.700684 2402.186279,2450.109619 
	C2405.719727,2452.789062 2409.848145,2454.147705 2414.286377,2453.940430 
	C2423.871826,2453.492920 2433.554688,2455.375488 2443.059082,2452.705322 
	C2444.261719,2452.367676 2445.712402,2452.478760 2446.945068,2452.790771 
	C2448.523193,2453.190186 2450.368652,2453.882080 2450.697510,2455.628906 
	C2451.079102,2457.656738 2449.115723,2458.254883 2447.666992,2458.984375 
	C2445.179443,2460.236328 2442.478271,2459.934082 2439.830566,2459.994629 
	C2434.536133,2460.114990 2429.190674,2459.514404 2424.055420,2461.557861 
	C2422.886230,2462.023193 2421.379883,2462.055420 2420.119141,2461.814209 
	C2400.985840,2458.156250 2381.664062,2460.702148 2362.441895,2460.073975 
	C2356.119385,2459.867432 2349.856201,2459.751465 2342.871094,2458.104004 
	C2339.908691,2459.928955 2337.441650,2460.618164 2334.647461,2460.544922 
	C2310.843750,2459.919922 2287.039551,2459.321289 2263.223389,2459.735596 
	C2261.480469,2459.765625 2260.029053,2458.946777 2258.462402,2456.889160 
z"
        />
        <path
          fill="#4C6880"
          opacity="1.000000"
          stroke="none"
          d="
M1945.989502,2440.807617 
	C1949.567993,2440.105225 1953.259033,2439.433105 1957.313965,2441.699707 
	C1957.950439,2443.347168 1953.776245,2444.739990 1957.843506,2445.894287 
	C1959.997681,2446.505615 1962.475464,2445.885498 1964.796021,2446.017334 
	C1977.358521,2446.731934 1990.043945,2446.844482 2002.507935,2446.914062 
	C2013.108643,2446.973633 2023.886719,2449.469971 2034.873657,2446.683350 
	C2043.017090,2444.618164 2052.062500,2446.075928 2060.706299,2446.032715 
	C2064.714844,2446.012695 2068.402344,2446.094971 2071.380859,2442.270020 
	C2074.175537,2438.681152 2078.971436,2439.372803 2082.611084,2440.342529 
	C2088.862061,2442.007812 2095.093262,2441.909668 2101.388916,2442.035889 
	C2103.039307,2442.069092 2104.804443,2442.012939 2106.305908,2442.568359 
	C2108.983398,2443.558350 2113.385254,2444.243652 2112.644287,2447.628906 
	C2111.753662,2451.700195 2107.943604,2449.060791 2105.212891,2448.787354 
	C2094.864502,2447.751709 2084.436768,2448.778320 2075.177002,2453.055420 
	C2068.596191,2456.095215 2062.323486,2456.677002 2055.935791,2455.362793 
	C2050.629395,2454.270508 2045.421021,2454.257812 2040.137329,2453.949951 
	C2014.288086,2452.444092 1988.426147,2451.370361 1962.531616,2452.072998 
	C1956.804688,2452.228271 1952.878174,2448.060059 1947.702271,2446.928223 
	C1945.707642,2446.492432 1946.123413,2443.560303 1945.989502,2440.807617 
z"
        />
        <path
          fill="#131C25"
          opacity="1.000000"
          stroke="none"
          d="
M1848.138184,2393.201172 
	C1848.042358,2395.685303 1848.602539,2397.274902 1849.652222,2399.393311 
	C1857.686890,2408.659668 1852.163696,2418.801270 1852.981079,2429.025879 
	C1852.908936,2431.377930 1852.985229,2432.956787 1852.519775,2435.294922 
	C1851.983032,2437.092041 1851.404785,2437.970459 1849.856689,2438.954590 
	C1846.393677,2443.941895 1845.723511,2449.317383 1844.328247,2455.207031 
	C1843.217896,2458.337646 1842.122192,2460.732422 1840.549072,2463.571289 
	C1834.158569,2462.615479 1835.523438,2457.759277 1836.519165,2454.435303 
	C1838.543701,2447.677002 1837.404175,2441.849121 1835.116821,2435.321777 
	C1832.388184,2427.535400 1833.291382,2418.875000 1834.543335,2410.835693 
	C1835.515259,2404.593994 1836.136841,2398.447266 1835.920410,2392.219482 
	C1835.745483,2387.184082 1836.941284,2382.436279 1837.989502,2376.767578 
	C1844.398560,2379.614258 1846.093628,2386.099854 1848.138184,2393.201172 
z"
        />
        <path
          fill="#516D85"
          opacity="1.000000"
          stroke="none"
          d="
M2212.488281,2385.685547 
	C2221.749756,2386.705078 2229.751709,2382.836426 2238.155029,2383.952148 
	C2240.121338,2384.213623 2242.156982,2383.905029 2244.147705,2384.044189 
	C2246.382080,2384.200195 2249.356445,2383.870361 2249.201416,2387.258545 
	C2249.084473,2389.818848 2246.605225,2389.917725 2244.604736,2389.951660 
	C2228.132812,2390.231201 2211.640625,2392.014160 2195.244385,2388.399414 
	C2191.099365,2387.485840 2186.613281,2388.168945 2182.287354,2388.017090 
	C2173.011719,2387.691650 2163.642822,2389.027832 2154.494629,2386.212646 
	C2152.664062,2385.649170 2150.406250,2385.656982 2148.542725,2386.148682 
	C2136.394531,2389.353271 2124.028076,2387.660645 2111.762207,2387.932373 
	C2106.749023,2388.043701 2101.854736,2388.476562 2096.956543,2389.512207 
	C2095.251465,2389.872803 2093.589111,2388.787109 2093.213135,2386.943848 
	C2092.744873,2384.649902 2094.587158,2384.151367 2096.265625,2384.104736 
	C2121.775879,2383.396729 2147.219971,2379.585205 2172.835938,2383.561035 
	C2181.307617,2384.875977 2190.129150,2383.950928 2198.790771,2384.023682 
	C2203.115967,2384.059814 2207.477783,2383.729004 2212.488281,2385.685547 
z"
        />
        <path
          fill="#465F75"
          opacity="1.000000"
          stroke="none"
          d="
M2296.950684,2422.062744 
	C2293.882568,2417.769775 2290.393311,2416.394775 2285.628174,2415.842529 
	C2276.486572,2414.781982 2267.204102,2414.478516 2258.254639,2411.996094 
	C2257.068115,2411.666992 2255.557861,2411.702637 2255.525635,2409.128174 
	C2269.766113,2405.224365 2283.959473,2410.851318 2299.083496,2410.016113 
	C2309.410645,2411.249756 2318.938477,2411.341553 2328.110352,2414.736328 
	C2333.579590,2416.760254 2339.709473,2416.994873 2345.540283,2418.047607 
	C2346.772949,2418.270020 2348.214355,2418.205811 2348.679688,2421.293213 
	C2340.413574,2422.661133 2332.021484,2421.827881 2323.720947,2421.949707 
	C2315.110596,2422.075928 2306.497803,2422.019043 2296.950684,2422.062744 
z"
        />
        <path
          fill="#4C6880"
          opacity="1.000000"
          stroke="none"
          d="
M2518.243652,2459.331055 
	C2517.198242,2457.921631 2517.436768,2456.795166 2518.420654,2456.530518 
	C2525.787354,2454.548584 2533.173828,2452.632812 2540.591797,2450.852051 
	C2542.829102,2450.314941 2545.291016,2449.690918 2547.469482,2450.090332 
	C2565.335938,2453.365967 2583.145752,2448.530762 2601.001709,2449.874756 
	C2605.373291,2450.203857 2609.572021,2450.739258 2613.672119,2452.202881 
	C2615.315430,2452.789551 2616.956543,2453.745117 2616.882324,2455.547119 
	C2616.797607,2457.608887 2614.850098,2457.941162 2613.148193,2457.897949 
	C2605.514648,2457.702881 2597.819824,2458.835205 2590.260010,2457.043945 
	C2583.989990,2455.558105 2577.763916,2455.402832 2571.526367,2457.140625 
	C2570.566895,2457.407715 2569.567139,2457.874756 2568.622314,2457.799072 
	C2551.968994,2456.462646 2535.547852,2461.132324 2518.243652,2459.331055 
z"
        />
        <path
          fill="#3C556B"
          opacity="1.000000"
          stroke="none"
          d="
M2162.524414,2397.568359 
	C2154.334473,2396.871338 2147.510498,2399.300781 2140.472168,2403.431641 
	C2130.250732,2410.633301 2119.963867,2406.562256 2108.941406,2405.338623 
	C2099.139160,2404.135254 2089.699463,2406.709961 2080.742188,2400.668701 
	C2076.781982,2396.938721 2071.119629,2399.893555 2068.215820,2394.791016 
	C2084.975098,2393.266846 2101.718018,2396.424316 2118.614990,2396.011963 
	C2124.622559,2395.865479 2130.520020,2395.569580 2137.130859,2393.750488 
	C2139.163818,2393.842285 2140.249023,2393.598877 2141.625000,2392.458984 
	C2149.620605,2390.867920 2156.828369,2393.997314 2165.163086,2393.978516 
	C2166.968994,2396.853760 2164.721924,2396.726562 2162.524414,2397.568359 
z"
        />
        <path
          fill="#5B768D"
          opacity="1.000000"
          stroke="none"
          d="
M2257.587402,2456.117188 
	C2260.501221,2457.270020 2262.818359,2457.944092 2265.710205,2457.259033 
	C2274.879639,2455.087402 2284.091309,2455.385986 2293.317383,2457.384521 
	C2298.883789,2458.590332 2304.803467,2458.987061 2310.150391,2457.375244 
	C2318.433594,2454.878418 2326.300293,2454.729492 2334.402832,2457.622803 
	C2336.204346,2458.265869 2338.358643,2457.921387 2341.189941,2458.059570 
	C2342.027100,2460.137451 2342.024902,2462.187256 2342.037109,2464.999268 
	C2328.998047,2463.045654 2315.783447,2463.630859 2302.587402,2463.829346 
	C2299.261230,2463.879150 2295.760742,2464.598633 2292.638916,2463.817871 
	C2281.130127,2460.938232 2269.467773,2462.504395 2256.953125,2461.984375 
	C2254.845703,2460.636719 2254.567139,2459.113037 2255.613281,2456.782227 
	C2256.122559,2456.138916 2257.099121,2456.124268 2257.587402,2456.117188 
z"
        />
        <path
          fill="#536F86"
          opacity="1.000000"
          stroke="none"
          d="
M2542.071289,2402.855957 
	C2554.737305,2402.029053 2567.435547,2402.019287 2581.071777,2402.009766 
	C2582.890869,2402.015625 2583.771729,2402.021729 2585.313965,2402.023682 
	C2586.313477,2401.775391 2586.652344,2401.531250 2587.495605,2401.658203 
	C2591.707520,2405.432129 2596.254150,2403.715088 2600.426758,2403.918701 
	C2606.404541,2404.211182 2612.406250,2403.988281 2618.396729,2404.069824 
	C2620.339355,2404.096436 2622.958008,2403.992676 2622.792236,2406.726074 
	C2622.659180,2408.921143 2620.382324,2409.779785 2618.272949,2409.827881 
	C2613.283936,2409.942139 2608.133301,2410.725586 2603.329346,2409.791504 
	C2587.134766,2406.644043 2570.830078,2408.619873 2554.594238,2407.977051 
	C2551.604492,2407.858398 2548.604736,2407.937744 2545.620850,2407.749023 
	C2543.192139,2407.595703 2542.343994,2405.839355 2542.071289,2402.855957 
z"
        />
        <path
          fill="#4C6880"
          opacity="1.000000"
          stroke="none"
          d="
M2626.685059,2445.932617 
	C2633.202393,2445.931641 2638.809326,2446.251465 2644.350098,2444.462158 
	C2646.782715,2443.676758 2649.679443,2443.718506 2652.257812,2444.104736 
	C2662.095459,2445.577148 2671.878906,2447.124512 2681.736572,2444.196045 
	C2682.470215,2443.977783 2683.538086,2444.786621 2684.406006,2445.209473 
	C2684.559326,2445.284180 2684.492920,2445.810547 2684.618896,2446.955322 
	C2676.802246,2447.003906 2670.334717,2451.746826 2661.512695,2450.633301 
	C2652.303223,2449.470947 2642.538086,2452.039307 2632.904541,2452.145020 
	C2624.943848,2452.231934 2618.901367,2447.682861 2611.665771,2446.141846 
	C2616.642578,2442.280762 2621.183838,2445.289062 2626.685059,2445.932617 
z"
        />
        <path
          fill="#33495B"
          opacity="1.000000"
          stroke="none"
          d="
M1850.397705,2436.399414 
	C1849.914795,2434.416992 1849.920166,2432.897217 1850.038086,2430.625488 
	C1858.763916,2424.752197 1868.185181,2426.733643 1878.299316,2426.880371 
	C1885.871948,2427.410889 1892.552734,2427.722168 1899.347900,2431.361816 
	C1899.807983,2436.835449 1895.167725,2435.332764 1892.395630,2437.629150 
	C1885.944824,2438.157227 1879.940186,2438.145752 1873.036621,2438.143799 
	C1869.410889,2440.112549 1866.479126,2440.271484 1862.607422,2438.683838 
	C1860.362061,2438.208740 1858.910400,2438.198242 1856.733276,2438.207275 
	C1854.242554,2437.989258 1852.405151,2438.022949 1850.397705,2436.399414 
z"
        />
        <path
          fill="#434F51"
          opacity="1.000000"
          stroke="none"
          d="
M1800.058838,2378.362793 
	C1796.973755,2385.472168 1790.150146,2387.429932 1784.390503,2391.491211 
	C1782.355225,2388.779541 1783.588867,2386.755127 1784.578491,2384.774170 
	C1774.752075,2383.906982 1766.286499,2390.772461 1756.304199,2386.611816 
	C1755.548584,2385.640625 1755.343994,2385.174316 1755.163086,2383.930664 
	C1757.853760,2378.604492 1762.060791,2378.671143 1767.196167,2379.768066 
	C1778.134888,2378.724854 1788.392334,2377.457764 1798.993408,2373.977051 
	C1799.819458,2374.989258 1799.908813,2376.214600 1800.058838,2378.362793 
z"
        />
        <path
          fill="#536F86"
          opacity="1.000000"
          stroke="none"
          d="
M2338.685547,2405.240234 
	C2346.567383,2406.607422 2353.604736,2407.690186 2360.642334,2408.772949 
	C2360.562012,2409.485107 2360.481689,2410.197266 2360.401367,2410.909424 
	C2345.714355,2414.665039 2331.713379,2409.135254 2317.453125,2406.247070 
	C2324.072754,2402.813477 2330.954102,2403.843994 2338.685547,2405.240234 
z"
        />
        <path
          fill="#69849B"
          opacity="1.000000"
          stroke="none"
          d="
M2611.664551,2394.089600 
	C2606.813232,2394.040283 2602.865967,2393.790283 2599.010254,2395.299316 
	C2596.463135,2396.296631 2593.760254,2396.265869 2591.632324,2394.062500 
	C2590.683838,2393.080322 2590.236572,2391.861328 2590.596680,2390.447754 
	C2590.996826,2388.876709 2592.381104,2388.117188 2593.570312,2388.469482 
	C2606.296631,2392.236816 2620.123047,2387.163086 2633.521729,2393.891113 
	C2626.027832,2397.786865 2619.278320,2395.667236 2611.664551,2394.089600 
z"
        />
        <path
          fill="#4A667C"
          opacity="1.000000"
          stroke="none"
          d="
M2162.926270,2397.997070 
	C2163.112549,2396.970215 2164.236328,2395.951172 2165.689697,2394.459717 
	C2177.378662,2394.000977 2188.738281,2394.014404 2201.028809,2394.038086 
	C2201.137939,2398.752441 2197.426025,2397.954102 2194.273682,2397.974121 
	C2184.137695,2398.038818 2174.000732,2398.002441 2162.926270,2397.997070 
z"
        />
        <path
          fill="#4C6880"
          opacity="1.000000"
          stroke="none"
          d="
M2481.718262,2457.988281 
	C2474.544434,2457.321289 2467.716064,2459.830078 2462.182617,2454.681885 
	C2472.629150,2454.808350 2483.216797,2451.990723 2494.189941,2456.663330 
	C2492.150391,2458.508789 2490.246094,2457.860596 2488.606201,2457.960693 
	C2486.617676,2458.082031 2484.616455,2457.988037 2481.718262,2457.988281 
z"
        />
        <path
          fill="#4A667C"
          opacity="1.000000"
          stroke="none"
          d="
M2011.123291,2461.958984 
	C2008.547729,2467.047852 2006.218872,2467.354248 2000.160400,2462.779785 
	C2003.409180,2462.031250 2006.838257,2461.994141 2011.123291,2461.958984 
z"
        />
        <path
          fill="#536F86"
          opacity="1.000000"
          stroke="none"
          d="
M2408.789062,2410.753906 
	C2411.203369,2410.511963 2413.856934,2409.949707 2412.330078,2413.130371 
	C2411.774170,2414.288086 2409.787598,2414.884766 2408.341797,2415.450928 
	C2407.117676,2415.930176 2405.590088,2415.683350 2405.282227,2414.229980 
	C2404.842041,2412.150635 2406.972656,2411.886963 2408.789062,2410.753906 
z"
        />
        <path
          fill="#536F86"
          opacity="1.000000"
          stroke="none"
          d="
M2212.491455,2432.494141 
	C2214.338135,2429.194824 2217.551514,2430.186523 2221.266846,2430.030762 
	C2220.533447,2435.676025 2216.667969,2434.069092 2212.491455,2432.494141 
z"
        />
        <path
          fill="#4C6880"
          opacity="1.000000"
          stroke="none"
          d="
M2244.955566,2441.707275 
	C2241.869629,2437.762939 2245.077881,2438.202148 2247.044678,2438.132080 
	C2248.488770,2438.080811 2250.975586,2437.910156 2250.513184,2440.196289 
	C2250.022461,2442.621094 2247.429443,2441.741943 2244.955566,2441.707275 
z"
        />
        <path
          fill="#465F75"
          opacity="1.000000"
          stroke="none"
          d="
M1862.032471,2438.891113 
	C1864.681885,2438.149902 1867.550049,2438.080566 1871.278076,2438.082520 
	C1868.844238,2442.973633 1867.468506,2443.169922 1862.032471,2438.891113 
z"
        />
        <path
          fill="#465F75"
          opacity="1.000000"
          stroke="none"
          d="
M1849.782837,2436.485107 
	C1851.723755,2436.085693 1853.613770,2435.899170 1855.596436,2437.676025 
	C1854.520020,2439.942871 1852.597290,2439.975098 1849.838745,2439.415527 
	C1849.285889,2438.478516 1849.471069,2437.756104 1849.782837,2436.485107 
z"
        />
        <path
          fill="#4A667C"
          opacity="1.000000"
          stroke="none"
          d="
M2740.242188,2425.553711 
	C2742.087158,2423.526367 2742.087158,2423.526367 2746.005371,2425.169922 
	C2744.364014,2426.543945 2742.497803,2425.807129 2740.242188,2425.553711 
z"
        />
        <path
          fill="#4A667C"
          opacity="1.000000"
          stroke="none"
          d="
M2141.463379,2392.065186 
	C2141.575195,2393.833740 2141.528809,2396.166260 2138.284668,2394.162354 
	C2138.944580,2393.139893 2139.914551,2392.604004 2141.463379,2392.065186 
z"
        />
        <path
          fill="#4A667C"
          opacity="1.000000"
          stroke="none"
          d="
M2710.852539,2439.290527 
	C2711.268555,2440.765137 2710.745605,2441.290039 2709.607422,2441.065430 
	C2709.370361,2441.018555 2709.050781,2440.681885 2709.014893,2440.442383 
	C2708.841309,2439.286377 2709.406494,2438.807861 2710.852539,2439.290527 
z"
        />
        <path
          fill="#69849B"
          opacity="1.000000"
          stroke="none"
          d="
M2587.812012,2401.720215 
	C2587.662598,2402.027344 2587.325195,2402.025391 2586.481445,2402.021484 
	C2586.364258,2401.382324 2586.753174,2400.744385 2587.812012,2401.720215 
z"
        />
        <path
          fill="#50677C"
          opacity="1.000000"
          stroke="none"
          d="
M1137.472412,2494.678223 
	C1139.369995,2492.398193 1141.277100,2492.083496 1143.321655,2492.046143 
	C1152.796021,2491.872314 1162.269653,2491.581299 1171.744263,2491.552246 
	C1174.155640,2491.544922 1174.974243,2490.229004 1176.297363,2488.625977 
	C1179.464722,2484.788086 1183.589966,2483.208496 1189.182129,2485.610352 
	C1191.032959,2486.914062 1192.135620,2487.990234 1192.655762,2490.280762 
	C1189.153442,2496.770020 1183.831787,2498.614502 1177.398804,2498.157715 
	C1172.087891,2497.780273 1166.764160,2498.111084 1161.447754,2497.970703 
	C1179.402100,2497.986084 1179.402100,2497.986084 1185.380615,2505.981934 
	C1185.041382,2511.437988 1181.452148,2512.989990 1177.791870,2512.829102 
	C1160.133789,2512.052246 1143.671997,2518.989990 1126.446655,2520.427246 
	C1114.661377,2521.409912 1102.779663,2521.259033 1091.005371,2522.899658 
	C1087.738892,2523.355225 1084.421509,2523.240234 1080.786987,2525.383057 
	C1074.727661,2529.749512 1068.740601,2527.404053 1065.833130,2527.613525 
	C1068.788696,2527.541504 1074.794800,2529.678467 1080.920166,2525.340332 
	C1091.303589,2520.527100 1102.070068,2522.342041 1111.878662,2521.968018 
	C1133.211060,2521.153809 1153.530396,2514.833496 1174.701538,2513.629395 
	C1187.450928,2512.904297 1199.940186,2514.002686 1212.393921,2515.141113 
	C1235.909180,2517.290527 1259.178101,2517.914551 1283.132324,2511.854980 
	C1288.197388,2511.074951 1292.390015,2510.724609 1297.213379,2512.658203 
	C1300.873413,2517.081543 1297.184570,2519.482422 1295.388184,2522.463135 
	C1299.696289,2522.839355 1303.719971,2522.329102 1307.584229,2523.472656 
	C1309.853149,2524.143799 1312.354004,2524.939697 1312.522949,2527.706787 
	C1312.732422,2531.138184 1309.861206,2532.194336 1307.251953,2532.603027 
	C1298.322021,2534.001465 1288.614380,2535.492188 1280.523193,2532.465576 
	C1266.397461,2527.181885 1252.197021,2530.549072 1238.153687,2528.945068 
	C1236.838867,2528.794922 1235.492310,2528.927979 1233.386841,2528.938965 
	C1216.762939,2524.316895 1202.111206,2533.300049 1192.987427,2533.269775 
	C1205.515991,2534.231689 1224.219360,2530.032471 1243.880859,2531.245605 
	C1249.406982,2532.238037 1253.797852,2530.371338 1258.354248,2529.792969 
	C1264.850464,2528.968506 1272.593262,2525.981934 1275.649780,2535.939941 
	C1276.571289,2538.942383 1279.541260,2537.709473 1281.732056,2537.508789 
	C1298.656616,2535.959229 1315.510010,2536.378174 1332.431641,2538.136475 
	C1343.977783,2539.336426 1355.636475,2538.910889 1367.212158,2537.159424 
	C1373.454346,2536.215332 1379.795166,2536.228271 1386.928223,2537.456299 
	C1395.736938,2541.567871 1399.037476,2547.897949 1397.183594,2557.432617 
	C1394.273071,2561.331787 1390.820190,2562.278076 1387.012085,2562.104004 
	C1372.142212,2561.424805 1357.158325,2563.395264 1342.397949,2559.918213 
	C1337.154297,2558.682861 1331.804932,2559.862061 1326.505981,2560.725098 
	C1351.624756,2558.715576 1376.262329,2566.300781 1402.234131,2564.239746 
	C1407.421631,2565.439941 1408.359741,2568.545410 1407.518311,2573.333496 
	C1402.225952,2580.718506 1394.787231,2577.293457 1388.603882,2578.199219 
	C1381.871704,2579.185303 1374.689697,2578.801270 1367.888794,2576.542236 
	C1364.397583,2575.382812 1360.578735,2575.040771 1357.007935,2575.565674 
	C1333.767822,2578.980957 1310.619873,2574.546875 1287.428711,2575.018311 
	C1279.407715,2575.181396 1271.499268,2574.512207 1263.647949,2572.938965 
	C1252.891113,2570.783203 1242.780273,2576.341064 1233.367676,2575.444824 
	C1263.038086,2571.370605 1293.670410,2575.203125 1324.464600,2577.708496 
	C1350.566650,2579.832031 1376.886353,2579.455322 1403.103271,2577.845215 
	C1405.111450,2577.721924 1407.082886,2577.500244 1409.682861,2578.979492 
	C1411.553223,2581.059082 1411.818848,2582.819092 1411.334229,2585.576416 
	C1407.715332,2592.029541 1402.286743,2593.802246 1396.473389,2592.906494 
	C1373.335571,2589.341309 1350.100220,2594.003418 1326.952881,2591.638672 
	C1302.553467,2589.145996 1277.950806,2592.631348 1252.673584,2589.387939 
	C1247.661499,2588.323730 1243.923828,2590.580566 1239.076660,2590.811035 
	C1237.074585,2590.933838 1235.927612,2590.967773 1233.915527,2590.995117 
	C1222.161743,2593.641113 1211.146240,2594.006836 1199.187988,2592.713867 
	C1191.468628,2590.332520 1185.136719,2591.772705 1178.090942,2593.271973 
	C1159.917725,2597.138184 1141.200439,2598.258789 1123.166382,2592.205566 
	C1114.911865,2589.434814 1107.549438,2589.480469 1098.990112,2591.109863 
	C1083.204590,2594.114502 1067.264404,2598.050537 1050.204590,2595.019043 
	C1046.663330,2587.977539 1050.922119,2585.975098 1055.907227,2585.017578 
	C1078.720215,2580.636475 1101.487183,2574.543457 1124.882935,2576.248779 
	C1145.124756,2577.724609 1164.480835,2573.288086 1184.185791,2570.774902 
	C1201.162598,2568.609863 1217.305054,2561.031982 1231.786011,2563.741943 
	C1224.705078,2565.379639 1214.545776,2563.255859 1204.398071,2562.618896 
	C1197.496216,2562.185303 1191.146240,2562.780273 1185.763428,2567.657715 
	C1182.608765,2570.516357 1178.574829,2570.676514 1174.576660,2571.231934 
	C1154.164307,2574.067627 1133.868896,2576.210693 1113.053223,2575.572998 
	C1095.287476,2575.028564 1077.425781,2578.971191 1059.621582,2580.909424 
	C1039.492432,2583.101318 1019.060608,2583.709473 999.301392,2588.995361 
	C995.632690,2589.977051 991.651550,2589.917480 990.380310,2585.160889 
	C989.020386,2580.072510 991.295776,2576.611328 996.293396,2575.384521 
	C1007.922180,2572.530518 1019.414246,2568.897217 1031.543457,2568.621338 
	C1039.562012,2568.439209 1047.427734,2567.158447 1056.048096,2565.199219 
	C1058.579224,2565.094971 1060.245850,2564.703125 1062.775024,2564.911865 
	C1067.437744,2565.003906 1071.315552,2565.545410 1075.803711,2563.753418 
	C1081.492798,2562.552490 1086.414551,2562.636963 1092.099609,2563.239014 
	C1095.783936,2563.886963 1098.881714,2564.888916 1101.687744,2563.854248 
	C1112.305908,2559.937256 1123.182739,2561.951172 1133.984253,2562.048584 
	C1128.054688,2561.980225 1121.972778,2562.807129 1116.180664,2558.308838 
	C1108.764648,2552.547607 1100.682983,2554.794434 1093.342651,2554.873047 
	C1078.162231,2555.035400 1064.400879,2548.391602 1049.565674,2547.140869 
	C1043.435547,2546.623779 1037.615967,2547.870850 1031.803589,2548.678955 
	C1020.025146,2550.316406 1009.081238,2545.704834 997.615295,2545.333008 
	C991.617249,2545.138428 991.326660,2540.522217 992.371948,2535.038574 
	C995.381714,2530.732422 998.846130,2529.603516 1003.760986,2531.223145 
	C1005.788879,2532.561035 1006.633423,2535.057617 1007.791199,2535.001709 
	C1016.940430,2534.559326 1026.372681,2537.506348 1035.222900,2532.338379 
	C1035.545654,2525.632080 1031.456421,2524.891357 1026.841431,2524.746826 
	C1020.204956,2524.539551 1014.825195,2521.927734 1010.723022,2515.695068 
	C1010.100891,2513.500488 1010.225891,2512.026855 1011.237671,2509.969727 
	C1018.124329,2507.740967 1024.622070,2505.664307 1029.943970,2512.014160 
	C1031.133789,2513.433838 1032.947876,2513.542236 1034.578003,2513.162354 
	C1049.031616,2509.791016 1063.799194,2511.408936 1078.391235,2510.296875 
	C1079.684814,2510.198242 1081.030762,2509.957031 1083.362427,2507.737061 
	C1060.437866,2510.836670 1038.891968,2507.416992 1017.281738,2507.973877 
	C1012.304688,2508.102295 1007.225098,2508.444092 1002.433411,2504.589844 
	C1001.595276,2501.184082 999.845032,2500.101318 997.155579,2500.054443 
	C994.500366,2500.008301 991.843323,2499.891113 989.195007,2499.696289 
	C987.685120,2499.585449 986.713623,2498.523193 986.647278,2497.118896 
	C986.580383,2495.706543 987.485413,2494.551758 988.977600,2494.400879 
	C990.946777,2494.202148 993.093445,2493.755371 994.897400,2494.299072 
	C1000.499634,2495.986816 1005.832947,2497.083984 1010.961914,2492.941162 
	C1011.794006,2492.268799 1013.696045,2492.186768 1014.753296,2492.642822 
	C1023.823975,2496.558105 1033.786011,2494.955078 1043.074951,2497.387451 
	C1046.407349,2498.260498 1049.732300,2498.315186 1052.754761,2496.524414 
	C1057.633179,2493.634521 1062.962158,2493.950684 1069.146606,2493.998047 
	C1088.024902,2496.342773 1105.556396,2502.224609 1124.081543,2500.895996 
	C1129.271118,2500.523926 1133.885010,2499.935791 1137.472412,2494.678223 
z"
        />
        <path
          fill="#465F75"
          opacity="1.000000"
          stroke="none"
          d="
M630.645569,2566.506836 
	C628.678772,2565.864014 626.741760,2566.257324 626.130310,2565.532471 
	C619.097229,2557.195801 611.178040,2560.739990 603.804260,2564.222168 
	C598.000793,2566.962158 593.548096,2565.036621 588.850159,2562.287598 
	C587.340027,2561.403809 586.118408,2560.203857 586.702393,2558.306152 
	C587.284485,2556.414795 589.034790,2556.141602 590.736328,2556.089844 
	C592.732300,2556.029297 594.735535,2555.969238 596.727783,2556.067627 
	C602.769958,2556.365723 608.575806,2555.561768 614.391174,2553.685059 
	C621.767212,2551.304688 628.826172,2551.519287 636.575256,2554.529541 
	C643.385071,2557.175049 651.755249,2558.183594 658.955627,2556.997070 
	C680.314819,2553.477051 701.624207,2550.817871 723.303711,2551.977539 
	C724.939697,2552.065186 727.038391,2552.276123 728.179504,2551.429199 
	C736.154480,2545.511963 745.183289,2548.137939 753.844727,2547.946289 
	C759.501953,2547.821045 765.165039,2547.976318 770.825378,2547.964355 
	C775.380310,2547.954590 780.517273,2549.310791 778.695251,2541.048828 
	C777.964050,2537.733643 782.240906,2537.933838 784.832947,2538.669434 
	C787.778687,2539.505127 790.687378,2540.774170 792.785950,2537.385986 
	C793.205017,2536.709473 793.191284,2535.322266 792.767517,2534.642578 
	C790.814514,2531.509521 787.848572,2531.770996 784.812805,2532.669189 
	C783.551270,2533.042480 782.301208,2533.612793 780.690063,2531.765625 
	C785.337097,2527.110596 791.593567,2526.582764 799.163208,2525.335205 
	C794.900757,2523.432373 792.021118,2523.794922 789.373352,2524.045166 
	C774.302551,2525.470215 758.907043,2524.101318 744.259216,2529.423828 
	C741.906189,2530.278809 738.888245,2530.163818 736.384521,2529.592041 
	C726.499329,2527.334229 716.536072,2529.402832 706.831787,2528.808594 
	C695.971985,2528.143311 684.317932,2530.859131 673.873901,2524.442139 
	C668.379822,2521.066650 661.714844,2521.196289 655.396606,2522.590576 
	C652.795532,2523.164795 650.289185,2524.065186 646.722900,2523.762695 
	C643.524902,2519.945068 646.696289,2518.696289 649.442871,2516.529053 
	C655.763733,2515.242676 661.274048,2516.985107 666.589294,2518.645020 
	C676.830933,2521.843018 687.603638,2521.293213 697.915222,2523.799561 
	C700.954407,2524.538330 704.177124,2523.626953 706.294373,2521.453613 
	C710.115540,2517.531250 714.524597,2517.218506 719.140930,2518.754883 
	C726.772278,2521.295410 733.232239,2519.634766 739.722168,2515.107178 
	C746.911499,2510.091309 751.309631,2510.709229 758.849609,2516.424805 
	C760.270996,2517.502441 761.773682,2517.983643 763.455017,2517.913574 
	C768.401672,2517.707275 773.412354,2518.614990 778.230896,2517.251709 
	C779.626831,2514.062256 776.347717,2514.333252 775.355103,2512.968994 
	C773.799561,2510.831055 774.435059,2508.832031 776.815308,2508.454102 
	C790.834229,2506.228271 804.824707,2504.119141 819.131042,2506.640381 
	C830.844910,2508.704834 842.669617,2510.320068 854.512695,2511.380127 
	C858.976624,2511.779541 863.631531,2510.184326 868.249512,2509.693604 
	C886.377197,2507.767334 904.497192,2506.068848 922.750916,2506.011719 
	C929.369751,2505.990967 935.984375,2506.274170 942.572571,2504.478760 
	C945.971436,2503.552734 950.046204,2503.543213 953.432800,2504.489502 
	C959.030640,2506.053467 964.600708,2505.957031 971.137329,2506.037109 
	C973.281982,2506.854736 974.498962,2507.677490 974.214539,2510.023438 
	C967.529724,2514.746338 959.850464,2511.669434 953.214355,2514.493408 
	C951.370178,2515.278076 949.226501,2515.205078 951.195312,2518.706543 
	C951.516663,2526.897461 946.101501,2527.512939 939.862915,2527.931885 
	C932.426880,2523.607666 925.207031,2526.160889 918.749023,2527.468994 
	C896.313965,2532.014404 873.660706,2531.035156 851.071899,2531.351807 
	C844.534180,2531.443604 839.833923,2531.794189 836.608276,2538.711182 
	C833.227478,2545.960693 826.323364,2546.539062 821.705078,2539.975342 
	C817.355957,2533.794189 814.884766,2534.214111 811.363098,2540.205322 
	C809.860168,2542.761963 807.298645,2544.589111 804.749023,2545.925293 
	C818.474243,2545.717773 832.503845,2541.515137 846.114441,2548.040527 
	C850.065918,2549.935059 854.658081,2549.523682 858.924683,2549.556396 
	C863.542908,2549.591553 868.917297,2549.758301 865.813232,2541.779297 
	C863.826050,2536.671387 868.276611,2535.965576 871.588501,2537.168945 
	C876.955933,2539.119385 882.260010,2539.817627 887.942200,2540.335205 
	C896.818848,2541.144287 891.347412,2546.317871 889.561707,2549.735107 
	C898.063293,2555.705078 907.117920,2557.456543 917.096497,2555.188477 
	C920.286194,2554.463379 923.800171,2554.745605 926.977600,2555.925781 
	C932.066956,2557.815918 932.503418,2561.120361 928.255554,2566.946289 
	C933.735657,2562.832031 938.782471,2563.379395 943.469177,2562.037354 
	C942.626343,2560.909912 941.586609,2561.052490 940.662720,2560.854736 
	C937.467224,2560.171387 933.317444,2560.184814 933.352051,2555.709473 
	C933.379089,2552.209229 936.854492,2551.029297 939.732971,2550.259033 
	C945.533325,2548.707275 951.196838,2546.768311 956.899414,2544.919678 
	C961.291138,2543.496338 965.571655,2544.595459 969.309448,2547.062744 
	C972.740967,2549.327881 975.227966,2552.323486 970.085693,2555.335938 
	C963.925537,2558.944824 971.009705,2558.639404 972.023071,2560.736816 
	C975.865967,2563.510254 979.646729,2562.852539 983.232605,2563.245605 
	C986.212158,2563.572021 989.733826,2563.749512 990.406616,2567.329834 
	C991.213257,2571.622070 987.474487,2573.279541 984.367920,2573.843018 
	C970.081970,2576.434082 956.023254,2581.291748 941.175354,2579.109863 
	C933.948486,2578.047852 926.645508,2577.742432 918.674072,2576.436523 
	C909.095642,2568.202881 896.302612,2570.376709 886.681824,2564.059326 
	C884.063904,2562.340332 882.325256,2565.823730 880.985596,2569.006836 
	C878.076355,2574.131348 874.193970,2577.016113 869.434509,2578.625244 
	C864.201294,2580.394531 860.294373,2579.603760 860.427002,2572.084961 
	C859.809387,2570.216797 864.503601,2567.741211 860.642029,2568.282227 
	C832.961121,2572.161377 805.456665,2571.995361 777.480896,2570.386963 
	C751.796448,2568.910645 725.565857,2567.495117 700.027039,2569.812256 
	C678.635010,2571.753418 657.896179,2564.494141 636.833801,2568.906250 
	C634.940063,2569.302979 632.931152,2568.281006 630.645569,2566.506836 
z"
        />
        <path
          fill="#1D1712"
          opacity="1.000000"
          stroke="none"
          d="
M1396.637573,2559.011719 
	C1396.468140,2551.072510 1392.740723,2545.899658 1388.950928,2540.120605 
	C1382.251465,2535.818359 1380.000244,2528.713135 1374.789429,2523.141113 
	C1372.689087,2519.741211 1371.707397,2516.607178 1370.230835,2512.935303 
	C1365.824219,2503.816162 1362.634766,2494.801514 1355.669922,2487.111084 
	C1353.091187,2483.197021 1352.102905,2479.515625 1352.593140,2474.841064 
	C1352.459351,2471.833252 1352.124756,2469.476562 1349.519043,2467.503418 
	C1345.433228,2466.918213 1345.072510,2463.589355 1343.001953,2460.997803 
	C1341.254761,2457.448730 1340.333374,2454.306152 1338.666870,2450.703125 
	C1338.058350,2448.629883 1338.050537,2447.218506 1338.598145,2445.137207 
	C1339.861938,2442.810791 1341.131836,2441.301514 1341.835449,2439.445312 
	C1343.825195,2434.195801 1346.970825,2435.143066 1349.872803,2438.331055 
	C1353.574585,2442.397705 1358.540894,2445.341797 1361.008301,2451.255127 
	C1360.908203,2452.782227 1360.516846,2453.530518 1359.422852,2454.564209 
	C1359.377075,2455.945801 1357.355957,2454.945557 1358.429199,2455.669922 
	C1359.395996,2454.610107 1359.992065,2454.024902 1361.062012,2452.976074 
	C1365.942261,2449.230225 1369.684814,2444.567871 1376.690918,2445.980957 
	C1378.975342,2448.583496 1380.033936,2451.094727 1380.249268,2453.736328 
	C1382.088989,2476.304932 1394.002808,2494.256592 1406.677246,2511.880127 
	C1409.577637,2515.913086 1412.928467,2519.665771 1414.815796,2525.217773 
	C1420.903442,2537.826660 1427.253540,2549.436035 1428.612915,2563.047119 
	C1429.342407,2570.349365 1430.483398,2577.471436 1428.102051,2584.688477 
	C1425.525146,2592.498047 1430.033447,2600.488770 1427.367676,2609.047363 
	C1420.755127,2613.082275 1419.308960,2607.969727 1418.290405,2604.154297 
	C1416.648804,2598.004639 1414.112915,2592.485840 1410.018433,2586.764160 
	C1409.715088,2584.584717 1409.601318,2583.173096 1409.325928,2581.070312 
	C1408.573120,2578.367432 1406.341431,2577.385986 1405.859619,2574.644043 
	C1406.110229,2571.137451 1407.091431,2568.036133 1402.657715,2566.291016 
	C1400.238403,2565.413818 1399.333862,2563.882324 1397.963623,2561.916260 
	C1397.403320,2560.998291 1397.202759,2560.618408 1396.637573,2559.011719 
z"
        />
        <path
          fill="#263B4D"
          opacity="1.000000"
          stroke="none"
          d="
M305.667328,2591.084961 
	C319.520660,2592.292480 332.678711,2588.959473 346.834930,2589.237793 
	C349.961548,2588.148682 352.248291,2587.910889 355.435791,2588.789307 
	C366.060791,2590.903076 375.548218,2589.219482 385.754578,2586.435059 
	C389.411346,2582.834473 393.247223,2584.161621 396.702728,2584.479004 
	C405.606140,2585.296631 414.516388,2584.842529 423.420990,2585.026367 
	C417.776703,2584.758545 412.007385,2585.837402 406.508636,2584.418457 
	C401.568970,2583.143311 398.248779,2580.601318 405.318237,2576.235596 
	C416.687378,2576.059082 427.347382,2575.912598 438.932159,2575.722656 
	C449.238831,2572.161621 458.008087,2576.004395 466.909546,2578.015381 
	C468.096100,2578.283691 469.394257,2579.103271 469.637909,2580.592285 
	C469.993439,2582.765137 468.164093,2583.279785 466.772736,2583.989502 
	C464.637848,2585.078125 462.276794,2584.960938 459.959290,2584.989746 
	C469.453796,2585.184082 478.831451,2583.692627 488.263092,2583.078857 
	C514.399048,2581.377930 540.423340,2583.842773 567.139404,2586.653809 
	C568.646729,2587.998047 569.058167,2589.030029 568.883911,2591.050049 
	C564.738953,2596.480957 558.960876,2593.898193 553.487061,2595.848145 
	C550.043945,2596.858398 547.317627,2596.975586 543.795898,2596.999023 
	C541.972900,2596.923584 540.939453,2596.903564 539.153442,2596.899902 
	C537.423096,2596.919678 536.444946,2596.924316 534.761841,2596.906250 
	C525.474304,2596.244873 517.164368,2596.543457 510.098328,2603.769531 
	C507.935577,2604.192383 506.475037,2604.111328 504.299683,2603.610840 
	C493.812592,2600.236572 484.381287,2602.557861 474.256134,2605.477539 
	C467.033020,2607.888184 460.772827,2605.320312 453.612396,2604.633301 
	C448.749634,2602.753906 444.628448,2603.028809 439.684998,2604.384277 
	C434.381744,2605.359619 429.880157,2605.345215 424.543823,2604.359863 
	C420.027405,2602.633057 416.189667,2601.583984 412.215698,2601.527344 
	C389.539429,2601.202393 366.806366,2599.677979 343.489441,2604.488281 
	C336.879608,2605.149414 331.279175,2603.827637 324.765656,2603.064209 
	C316.282684,2601.511963 307.079285,2602.690186 305.667328,2591.084961 
z"
        />
        <path
          fill="#465F75"
          opacity="1.000000"
          stroke="none"
          d="
M568.813721,2588.133301 
	C543.834839,2589.579102 519.834534,2584.783936 495.661926,2586.055908 
	C483.810120,2586.679688 472.069489,2588.978516 460.099335,2588.031250 
	C455.482269,2587.666016 451.073486,2587.369385 445.906250,2583.625244 
	C453.727295,2580.665039 460.953766,2583.031982 467.610809,2580.865967 
	C458.539246,2580.878174 450.354126,2576.758301 440.688477,2575.813477 
	C440.460052,2572.891846 442.428955,2572.230225 445.002289,2571.970947 
	C449.931183,2571.473877 454.589447,2569.310059 461.325897,2570.144775 
	C457.283478,2566.965576 452.586914,2568.141113 451.014038,2563.422607 
	C454.865997,2560.833008 459.143158,2561.597656 463.071075,2562.802734 
	C469.140106,2564.664795 474.960602,2566.826172 481.426788,2564.287598 
	C483.548279,2563.454834 486.016663,2564.272217 488.270477,2565.028809 
	C490.494781,2565.776123 492.017456,2564.798584 493.231537,2562.871582 
	C496.923279,2557.012207 499.157043,2556.268066 503.826447,2560.270020 
	C509.312164,2564.971436 516.185059,2567.743164 522.239685,2570.806152 
	C529.036865,2574.244873 538.640808,2573.387695 546.612366,2570.586914 
	C554.092285,2567.959229 561.613220,2568.048584 569.255066,2567.996826 
	C572.342102,2567.975830 574.880005,2569.094238 577.684326,2570.354004 
	C588.216492,2575.086182 599.574890,2574.989990 610.528259,2573.019531 
	C614.318481,2572.337402 617.722778,2571.080078 621.750977,2571.726807 
	C623.855591,2572.064453 626.219055,2570.788574 629.215210,2570.156006 
	C632.428406,2570.942627 633.449646,2572.783203 633.286072,2576.114502 
	C628.095032,2583.156982 620.566956,2583.057129 613.927185,2583.291504 
	C598.987244,2583.818359 584.233643,2585.238770 568.813721,2588.133301 
z"
        />
        <path
          fill="#43596D"
          opacity="1.000000"
          stroke="none"
          d="
M838.733337,2485.729004 
	C881.920532,2488.005371 924.320374,2487.823486 966.474304,2493.203613 
	C966.180115,2494.074463 966.011475,2495.116455 965.798889,2495.125488 
	C952.907959,2495.674561 940.749023,2501.687988 927.530396,2500.037354 
	C919.974670,2499.093994 912.498718,2497.635498 904.759094,2497.806885 
	C894.121216,2498.042480 883.667236,2501.495850 873.001343,2499.884766 
	C855.465027,2497.235352 837.835144,2498.216064 820.232544,2497.910400 
	C813.911438,2497.800537 807.285339,2493.446045 801.485535,2499.471680 
	C796.087280,2495.873779 790.156738,2498.135498 784.452637,2497.946777 
	C782.458069,2497.880615 780.305176,2497.538086 778.482178,2498.123779 
	C760.615356,2503.864014 742.185120,2504.042236 723.710632,2504.031494 
	C718.916748,2504.028809 713.740295,2502.729492 710.631470,2508.571045 
	C709.489319,2510.717041 706.367249,2509.991211 704.099976,2509.550537 
	C700.549866,2508.860352 697.046997,2507.927490 692.946167,2506.961426 
	C693.810730,2502.421631 696.890015,2498.548096 696.274536,2493.815430 
	C696.043152,2492.036133 697.363281,2490.635742 699.179810,2490.302246 
	C704.484131,2489.328857 709.262878,2487.159424 713.947937,2484.558350 
	C715.675232,2483.599609 717.937927,2483.965820 719.588440,2485.348389 
	C720.249390,2485.901855 720.729004,2486.945557 720.835022,2487.821533 
	C720.980347,2489.022217 719.849182,2489.528809 718.886292,2489.658203 
	C715.607422,2490.097900 712.205383,2490.019775 709.056213,2490.886963 
	C706.946289,2491.467773 704.624207,2493.222656 705.207947,2495.953613 
	C705.755188,2498.514160 708.368652,2497.967041 710.226379,2497.911621 
	C718.404114,2497.668701 726.266174,2497.750244 734.546143,2494.869629 
	C744.653442,2491.353271 756.114258,2491.939209 766.823242,2489.883789 
	C778.894897,2487.566895 790.993408,2486.432861 803.216064,2485.357666 
	C812.173523,2484.569824 821.079834,2484.300781 830.016785,2484.133789 
	C832.603516,2484.085693 835.209595,2485.070068 838.733337,2485.729004 
z"
        />
        <path
          fill="#61798F"
          opacity="1.000000"
          stroke="none"
          d="
M632.700134,2576.468750 
	C631.542419,2574.286865 631.018738,2572.738770 630.229980,2570.630859 
	C629.975403,2569.163818 629.985962,2568.256836 629.998291,2566.670166 
	C639.817627,2565.375488 649.629211,2564.614746 659.455505,2564.210938 
	C663.965454,2564.025391 668.261169,2563.695068 672.836853,2566.323486 
	C679.047974,2569.891357 686.393250,2568.174316 693.265686,2567.191895 
	C706.471069,2565.304443 719.669495,2565.088623 732.834167,2567.317871 
	C739.463928,2568.440674 746.312317,2568.981201 752.660522,2567.584717 
	C769.034241,2563.982666 784.944641,2567.088379 800.961304,2569.372314 
	C804.286499,2569.846680 807.734192,2570.615967 810.835999,2569.680908 
	C817.370117,2567.710449 823.979126,2567.924805 830.596069,2568.025391 
	C840.919922,2568.182129 851.137207,2567.686768 861.092163,2564.631104 
	C862.034790,2564.341553 863.045715,2564.125488 864.024109,2564.128906 
	C867.250610,2564.139648 872.189575,2563.048096 872.556946,2566.944824 
	C873.059082,2572.271729 867.563049,2569.028809 864.951904,2570.213867 
	C864.353516,2570.485596 863.709045,2570.656006 862.534119,2571.373291 
	C857.006714,2576.708008 850.540161,2576.969238 844.275513,2577.203857 
	C829.650757,2577.751709 815.088623,2578.677979 800.725159,2581.676270 
	C798.756348,2582.087402 796.697388,2582.361084 794.787720,2581.777588 
	C769.786072,2574.141113 744.014038,2580.708008 718.833679,2577.607910 
	C702.255188,2575.566650 686.094727,2580.074219 668.835938,2578.937500 
	C665.782043,2578.567627 663.578979,2578.100586 660.491577,2578.802246 
	C650.815186,2579.122070 641.853271,2581.276611 632.700134,2576.468750 
z"
        />
        <path
          fill="#465F75"
          opacity="1.000000"
          stroke="none"
          d="
M405.536133,2575.635498 
	C405.263519,2577.630859 402.932861,2578.152100 403.213928,2580.010010 
	C403.526672,2582.077637 405.565674,2581.930908 407.148346,2581.958252 
	C413.086456,2582.061279 419.029694,2581.977295 424.963257,2582.185059 
	C428.114319,2582.295410 431.488098,2581.092529 434.460114,2583.342041 
	C426.953217,2588.773682 406.433990,2590.100586 387.328735,2586.356445 
	C383.686523,2583.163330 379.864685,2584.182861 376.324493,2583.992920 
	C372.459167,2583.785400 368.342560,2584.825928 365.183929,2581.362061 
	C364.626801,2580.750977 363.137085,2580.227783 362.527344,2580.540283 
	C352.830658,2585.512939 342.948547,2578.680908 332.719025,2581.498779 
	C324.208740,2583.843018 314.583038,2581.676025 306.248627,2576.831299 
	C303.040466,2574.966553 299.103790,2576.239258 294.645020,2575.692871 
	C292.043732,2573.794189 291.957886,2571.745117 293.348022,2568.894531 
	C296.176758,2567.687012 299.813965,2568.584961 299.039062,2564.272949 
	C298.442444,2560.953125 300.074524,2560.109863 302.801056,2560.061035 
	C305.771973,2560.007812 309.144958,2559.233154 311.504059,2561.187012 
	C314.819397,2563.932373 318.984436,2563.517578 323.166931,2565.153564 
	C319.834442,2571.383545 314.316803,2563.706787 310.846130,2567.811523 
	C311.860199,2572.484131 316.004089,2573.291504 319.749634,2573.748291 
	C324.314545,2574.304688 329.042633,2574.610596 333.563141,2573.120850 
	C342.596619,2570.143799 351.667206,2570.052002 360.790161,2572.584473 
	C363.302032,2573.281738 365.291504,2574.194092 367.871277,2571.627441 
	C373.457550,2566.069336 376.066559,2566.463379 380.152344,2573.067139 
	C381.811066,2575.748291 383.581757,2576.029297 385.856842,2574.935303 
	C392.317657,2571.829590 398.664246,2573.148926 405.536133,2575.635498 
z"
        />
        <path
          fill="#334D63"
          opacity="1.000000"
          stroke="none"
          d="
M1351.847900,2474.108398 
	C1355.314087,2477.042480 1356.251587,2480.445801 1356.353027,2484.865723 
	C1352.483643,2490.514893 1346.686523,2490.174805 1341.709961,2489.445312 
	C1314.948975,2485.524658 1288.190552,2491.054688 1261.304199,2488.686768 
	C1244.048340,2487.167480 1226.329468,2490.862549 1208.850098,2492.590332 
	C1203.576660,2493.111572 1198.289673,2494.211670 1192.640259,2490.709473 
	C1191.346680,2489.051758 1190.697754,2488.082764 1189.938110,2486.515625 
	C1203.354614,2482.831055 1216.987671,2479.955566 1230.888184,2480.089600 
	C1251.794800,2480.291016 1272.626465,2480.121826 1293.264038,2476.279541 
	C1293.912354,2476.158936 1294.588623,2476.116211 1295.248169,2476.140381 
	C1313.828491,2476.815918 1332.228516,2472.691406 1351.847900,2474.108398 
z"
        />
        <path
          fill="#50677C"
          opacity="1.000000"
          stroke="none"
          d="
M600.008240,2523.080566 
	C600.033508,2527.149658 599.345276,2530.214111 595.245972,2529.832520 
	C588.811646,2529.233154 582.850220,2531.799805 576.548889,2532.085205 
	C571.017822,2532.335205 565.483154,2534.193848 560.282593,2530.532715 
	C559.347351,2529.874756 557.693359,2530.076660 556.384155,2530.141357 
	C552.347839,2530.341553 551.633484,2532.154541 554.183044,2535.870605 
	C554.882019,2536.889160 556.497131,2537.316162 555.943665,2539.564453 
	C550.631836,2539.951904 545.144775,2540.566162 539.747803,2541.860596 
	C533.580566,2543.339355 529.338013,2538.427002 524.178955,2536.535156 
	C523.231628,2536.187500 522.926880,2535.063721 523.121094,2534.000977 
	C523.331543,2532.849609 524.237366,2532.506592 525.228455,2532.248535 
	C528.065186,2531.510010 531.145325,2532.812500 534.079468,2531.169434 
	C532.312683,2528.278809 529.994629,2528.556152 527.192200,2529.218750 
	C521.976746,2530.451660 516.709595,2530.537598 511.479553,2528.859375 
	C509.952637,2528.369629 507.290344,2527.756836 506.739441,2528.455322 
	C501.465759,2535.140381 496.619446,2530.264648 491.584473,2528.320068 
	C490.400970,2527.862793 488.956207,2528.096436 487.634827,2527.977051 
	C486.670349,2527.890137 485.711029,2527.745605 484.527008,2527.599609 
	C485.464966,2524.922119 487.607117,2526.253418 489.075989,2526.043213 
	C491.077789,2525.756836 493.046112,2525.588623 494.622772,2524.178711 
	C496.107849,2522.850830 497.515717,2521.435547 499.016205,2520.125732 
	C500.748138,2518.614502 502.405853,2518.473877 504.222565,2520.221191 
	C509.181091,2524.991211 515.080811,2525.019287 520.910889,2522.571533 
	C529.171936,2519.103271 537.835327,2521.061523 546.260132,2520.377197 
	C553.326294,2519.803711 559.959167,2518.348877 567.371338,2516.196533 
	C574.518860,2520.186035 581.550293,2521.204102 588.896790,2520.610352 
	C592.765503,2520.298096 596.789246,2518.655273 600.008240,2523.080566 
z"
        />
        <path
          fill="#3E5871"
          opacity="1.000000"
          stroke="none"
          d="
M1344.140869,2459.502441 
	C1348.480347,2460.555420 1347.739502,2463.730957 1348.722900,2466.579346 
	C1341.282593,2466.594727 1333.885376,2467.696777 1326.547607,2469.676270 
	C1324.678467,2470.180664 1322.571533,2469.994385 1320.590820,2469.865234 
	C1306.080078,2468.918213 1291.935791,2470.103271 1277.555298,2473.184570 
	C1266.740967,2475.501709 1255.067139,2473.815430 1243.775879,2473.882812 
	C1243.129150,2473.886719 1242.302368,2473.843262 1241.859985,2473.467529 
	C1234.978760,2467.623047 1228.168579,2470.890869 1220.590332,2473.512695 
	C1218.606689,2472.289062 1218.128418,2470.800293 1218.214844,2468.325195 
	C1219.170288,2464.780518 1221.057129,2462.838623 1223.922852,2460.685547 
	C1236.392090,2458.789062 1248.180298,2457.688232 1260.815063,2457.972168 
	C1267.857178,2462.216797 1274.127441,2462.224609 1281.182373,2457.862305 
	C1300.277710,2455.414551 1318.639160,2457.119629 1336.969482,2456.755371 
	C1339.287109,2456.709229 1341.473389,2457.580322 1344.140869,2459.502441 
z"
        />
        <path
          fill="#3C556B"
          opacity="1.000000"
          stroke="none"
          d="
M1066.515503,2487.454102 
	C1052.000366,2488.521973 1038.218140,2484.831543 1024.219116,2486.053711 
	C1002.637451,2487.937744 981.373291,2484.847656 960.059143,2482.221680 
	C955.131836,2481.614502 950.076660,2482.108887 945.087524,2481.912598 
	C942.612122,2481.815186 939.684265,2482.797119 937.967346,2479.723145 
	C938.997620,2477.201416 941.250427,2478.092041 942.949402,2478.080322 
	C955.270569,2477.995117 967.593872,2477.951904 979.914246,2478.090820 
	C987.533813,2478.176758 994.923828,2480.629639 1002.690796,2479.988770 
	C1015.891785,2478.899414 1029.139160,2478.386230 1042.265503,2476.439453 
	C1044.230469,2476.148193 1046.245117,2476.167236 1048.238647,2476.101318 
	C1050.568237,2476.024414 1053.224121,2476.730713 1055.176514,2475.850830 
	C1065.326904,2471.275635 1075.798828,2474.153076 1086.125000,2474.043945 
	C1096.780273,2473.931152 1107.437866,2474.016602 1119.029419,2474.022461 
	C1121.123169,2475.309570 1121.596680,2476.805176 1121.072754,2479.285156 
	C1120.044678,2480.901123 1119.169800,2481.549561 1117.360474,2482.243164 
	C1115.503174,2482.682861 1114.424805,2482.833252 1112.534424,2482.942139 
	C1110.140259,2482.850098 1108.551880,2482.767090 1106.187866,2482.614746 
	C1092.130005,2479.653320 1079.623657,2483.553711 1066.515503,2487.454102 
z"
        />
        <path
          fill="#698196"
          opacity="1.000000"
          stroke="none"
          d="
M547.308655,2455.955811 
	C551.959961,2453.768799 556.769897,2452.531738 560.360535,2454.802490 
	C566.761292,2458.850586 572.803772,2458.191895 579.343750,2456.223389 
	C580.876099,2455.762207 582.637878,2456.063721 585.144653,2456.021973 
	C586.247131,2460.162842 585.622620,2463.166260 580.778564,2464.972900 
	C576.229248,2466.669678 571.570007,2467.671143 566.492065,2469.628906 
	C563.014282,2473.667725 559.579651,2473.893555 555.907776,2470.790283 
	C557.179993,2462.211914 557.179260,2462.012939 549.965759,2462.039062 
	C537.735168,2462.083496 525.278015,2460.346191 513.835815,2466.908203 
	C510.275024,2468.950195 507.418701,2467.419678 506.068573,2462.671143 
	C506.111603,2461.123047 506.398651,2460.347900 507.295441,2459.265137 
	C508.374847,2458.426025 508.707916,2457.569092 508.347748,2457.465576 
	C507.405518,2457.195068 506.436554,2457.719971 504.793579,2457.999756 
	C493.529297,2460.144287 482.826294,2460.190674 471.426544,2459.981445 
	C468.156494,2459.677246 465.643951,2460.300781 462.571655,2460.260254 
	C457.300262,2458.698242 452.557037,2457.514893 446.749481,2458.036621 
	C447.091705,2450.225342 447.090302,2450.117188 453.911285,2450.027832 
	C462.862061,2449.910400 471.776917,2450.339844 480.672302,2448.254883 
	C484.750458,2447.299316 489.619354,2447.196777 493.530334,2448.502441 
	C507.494934,2453.164795 521.805481,2451.802979 536.971069,2452.095459 
	C540.115662,2454.959961 543.525513,2455.048828 547.308655,2455.955811 
z"
        />
        <path
          fill="#374D60"
          opacity="1.000000"
          stroke="none"
          d="
M679.641846,2476.141602 
	C693.005981,2478.645752 705.525879,2476.800293 717.987793,2477.177979 
	C718.738892,2477.200684 719.457336,2478.300049 721.266663,2479.775635 
	C672.478943,2487.544922 624.912537,2492.102051 577.437195,2479.148682 
	C577.472351,2477.143555 578.257629,2476.332764 579.217407,2476.345703 
	C592.104126,2476.518066 604.956238,2475.427490 617.866028,2475.763916 
	C638.128601,2476.291748 658.411133,2476.056396 679.641846,2476.141602 
z"
        />
        <path
          fill="#50677C"
          opacity="1.000000"
          stroke="none"
          d="
M1059.164551,2442.807861 
	C1065.202759,2441.428223 1070.160522,2442.060059 1073.928467,2446.824707 
	C1075.625854,2448.971191 1078.102051,2449.656494 1080.867065,2450.033203 
	C1091.939453,2451.541992 1103.027344,2452.484619 1115.142334,2452.055908 
	C1117.432007,2452.914551 1118.559326,2453.903564 1118.052246,2456.287109 
	C1114.192139,2458.821533 1110.440186,2457.621582 1107.395508,2457.981934 
	C1112.861328,2458.430664 1118.804077,2457.286133 1124.775146,2457.212158 
	C1129.221069,2457.156738 1134.387939,2455.988037 1137.004272,2462.258301 
	C1135.979614,2467.104248 1131.471436,2466.669678 1128.533691,2469.528564 
	C1124.052856,2470.836914 1120.230957,2470.015869 1116.411743,2469.175049 
	C1114.797974,2468.820068 1113.049438,2467.842285 1111.598145,2468.175537 
	C1096.945557,2471.539551 1082.895996,2464.169922 1068.333496,2465.933594 
	C1058.994507,2467.064941 1049.955933,2463.651367 1040.668213,2463.523193 
	C1040.299194,2463.518066 1039.942505,2462.616455 1038.519287,2460.716797 
	C1055.139160,2459.982422 1070.617676,2456.765625 1086.526611,2459.463135 
	C1081.481445,2455.018066 1068.793091,2452.249023 1063.005493,2453.306152 
	C1057.818359,2454.253174 1052.859619,2456.693359 1046.524658,2455.740967 
	C1042.849976,2450.151611 1046.323975,2447.833252 1050.680664,2446.446533 
	C1053.415771,2445.575684 1056.536133,2445.854248 1059.164551,2442.807861 
z"
        />
        <path
          fill="#43596D"
          opacity="1.000000"
          stroke="none"
          d="
M172.557068,2575.880371 
	C174.419006,2570.500732 178.576431,2568.007568 184.407928,2567.963623 
	C187.504242,2567.940430 191.435333,2568.929688 191.850677,2563.094238 
	C193.045212,2561.191162 194.261612,2559.021240 195.423782,2559.049805 
	C206.851105,2559.331787 217.239090,2550.795166 229.199844,2555.316895 
	C233.250748,2556.848389 237.876968,2556.674316 242.020828,2555.527344 
	C251.548752,2552.889160 261.191956,2554.519043 270.770660,2554.109131 
	C272.354462,2554.041260 274.385712,2553.988037 274.779510,2556.096680 
	C275.143341,2558.044678 273.566315,2559.092041 271.974396,2559.602783 
	C265.983246,2561.524170 259.988464,2563.532959 253.657394,2563.972168 
	C250.915054,2564.162354 248.353302,2564.456055 246.138611,2567.493652 
	C241.758759,2574.107666 235.467270,2572.968018 229.696030,2573.418457 
	C217.045258,2574.406250 204.129120,2570.734619 190.847977,2575.791260 
	C187.072601,2576.076904 184.085648,2575.749023 180.720673,2577.601807 
	C177.785492,2577.272217 175.518753,2576.658691 172.557068,2575.880371 
z"
        />
        <path
          fill="#2F4457"
          opacity="1.000000"
          stroke="none"
          d="
M786.858154,2448.186279 
	C778.857727,2448.124268 771.621521,2448.191162 764.405212,2447.843750 
	C759.709351,2447.617676 760.606934,2445.426025 763.609497,2442.540527 
	C768.866821,2437.007568 775.106934,2437.081787 782.198669,2437.403076 
	C787.589355,2439.046387 792.193909,2439.899658 797.691406,2437.878174 
	C801.231628,2437.205566 803.942810,2437.168457 807.171753,2439.027832 
	C816.913086,2441.431885 825.774414,2438.635986 834.545105,2437.540039 
	C853.716370,2435.144287 872.849121,2433.595947 893.089966,2433.985840 
	C899.519470,2435.131104 905.010559,2436.325684 911.211060,2437.784668 
	C914.072632,2444.378174 909.038269,2444.202148 905.458191,2444.831787 
	C901.617920,2445.507080 897.574219,2444.473145 892.912109,2446.217285 
	C886.704773,2447.547363 881.317505,2447.685791 875.015076,2447.118652 
	C856.545044,2447.237061 838.964233,2447.735107 820.669556,2446.628906 
	C817.209656,2446.105469 814.461914,2446.111572 810.891479,2446.170898 
	C807.480347,2448.817383 803.671326,2447.271973 800.207336,2449.308105 
	C795.640503,2448.917725 791.746521,2447.656494 786.858154,2448.186279 
z"
        />
        <path
          fill="#50677C"
          opacity="1.000000"
          stroke="none"
          d="
M891.843018,2444.794189 
	C895.140564,2440.680664 899.223022,2442.413086 902.898315,2441.939209 
	C905.891113,2441.553467 909.445374,2443.155029 911.735657,2438.798828 
	C916.388855,2437.019775 920.706482,2435.122070 925.475952,2437.374512 
	C928.044617,2438.587891 930.792358,2436.973389 933.108215,2435.901855 
	C940.103271,2432.664795 946.397766,2434.578857 952.883667,2437.266357 
	C951.672363,2441.510986 947.847656,2438.811768 945.987488,2440.885986 
	C947.402039,2443.595215 950.031372,2443.718994 953.306396,2443.975098 
	C960.912781,2448.446289 968.674988,2446.706055 976.133545,2446.963379 
	C982.713867,2447.190674 989.393311,2446.630615 995.620728,2451.547119 
	C996.451294,2457.447754 994.710938,2460.912109 988.819153,2459.948486 
	C981.474670,2458.747070 974.555969,2463.903809 966.584473,2460.658691 
	C960.478943,2455.768799 952.916077,2458.581787 946.630615,2454.315918 
	C941.403564,2448.783936 935.387573,2450.969238 930.073181,2451.321045 
	C920.150452,2451.977539 910.324707,2453.023682 900.421082,2451.016113 
	C896.699463,2450.261963 893.191650,2449.711670 891.843018,2444.794189 
z"
        />
        <path
          fill="#334D63"
          opacity="1.000000"
          stroke="none"
          d="
M497.076874,2499.729248 
	C495.872375,2497.350098 496.262360,2495.148438 497.758240,2492.458984 
	C501.395020,2491.803467 504.761383,2491.794189 508.968323,2491.743652 
	C511.866364,2490.054443 513.935181,2488.428223 517.348877,2488.072754 
	C522.065247,2487.976074 526.130371,2487.952148 531.066223,2487.919434 
	C535.167053,2485.761230 538.698669,2484.439209 543.283691,2484.029785 
	C556.853577,2485.787842 569.756409,2486.492920 582.725220,2486.009521 
	C586.500366,2485.868896 589.868347,2487.507324 593.173523,2488.996094 
	C595.073730,2489.852539 596.163391,2491.742676 595.243164,2493.963135 
	C594.475647,2495.815186 592.554199,2496.085205 591.010925,2495.648682 
	C587.079834,2494.537109 583.253418,2495.367920 579.350098,2495.395996 
	C561.747070,2495.521484 544.332886,2499.360840 526.689697,2497.902100 
	C523.068054,2497.602783 519.479065,2496.909668 515.052979,2496.424316 
	C513.063110,2497.194336 511.972504,2498.041748 510.351929,2499.334473 
	C505.838531,2499.754150 501.877472,2499.758789 497.076874,2499.729248 
z"
        />
        <path
          fill="#4B6377"
          opacity="1.000000"
          stroke="none"
          d="
M449.551636,2546.622070 
	C464.803192,2543.111328 479.799591,2543.833008 494.734802,2544.090576 
	C506.329681,2544.290527 517.812988,2546.929199 529.492004,2546.082764 
	C530.145081,2546.035156 531.051697,2545.959961 531.441528,2546.324219 
	C540.031799,2554.351318 551.643860,2551.152100 561.470459,2554.511719 
	C561.938599,2554.671387 562.194214,2555.453125 562.944763,2556.494873 
	C557.887085,2560.354248 552.054688,2552.952148 547.216370,2557.470459 
	C548.024780,2561.975342 553.709839,2558.182861 554.857483,2562.163574 
	C552.949768,2565.677734 550.102356,2565.682373 546.578735,2564.542969 
	C542.883911,2563.348633 537.827820,2563.984619 536.561401,2559.375977 
	C535.290161,2554.749756 532.299500,2554.827393 528.658569,2553.695312 
	C519.266052,2550.774658 510.269562,2555.059570 501.041046,2554.068848 
	C494.474609,2553.364258 487.767059,2554.535156 481.241486,2552.403564 
	C479.111725,2551.708008 476.575653,2551.806396 474.296875,2552.098877 
	C466.533905,2553.094238 458.854980,2553.749023 450.755127,2549.998047 
	C449.228363,2549.200684 448.945007,2548.288330 449.551636,2546.622070 
z"
        />
        <path
          fill="#50677C"
          opacity="1.000000"
          stroke="none"
          d="
M819.994080,2446.835938 
	C824.990234,2446.326904 829.798035,2444.884521 834.700134,2444.130127 
	C847.226501,2442.201660 859.757202,2444.843506 873.222229,2444.235352 
	C878.104492,2450.603271 883.659668,2452.388184 890.943604,2451.441895 
	C896.619385,2450.704346 902.699951,2449.620117 908.339722,2454.229980 
	C909.341248,2460.559814 904.249146,2459.308105 900.855530,2461.328125 
	C894.737061,2460.746094 889.315552,2458.997070 883.653320,2461.573486 
	C881.980225,2462.334717 879.561096,2462.320801 877.751953,2461.774170 
	C868.268311,2458.909912 858.574219,2460.336182 848.970825,2459.989990 
	C846.013000,2459.883301 842.550659,2460.927002 840.293884,2458.677490 
	C835.270203,2453.670166 830.361389,2449.314453 822.388000,2451.383545 
	C820.756104,2451.806885 820.232788,2449.255371 819.994080,2446.835938 
z"
        />
        <path
          fill="#3E5871"
          opacity="1.000000"
          stroke="none"
          d="
M974.610718,2510.627441 
	C973.495789,2509.005615 972.982300,2508.033691 972.253052,2506.557129 
	C981.565979,2507.113281 990.795898,2504.049072 1001.118774,2504.000000 
	C1015.391602,2507.192383 1028.821411,2505.013916 1042.290771,2504.724609 
	C1049.091064,2504.578369 1056.081543,2506.161621 1063.067139,2505.970459 
	C1068.040161,2505.834473 1072.945801,2505.993652 1077.892090,2504.546631 
	C1082.363037,2503.238525 1087.141113,2503.314697 1091.360718,2507.854492 
	C1086.326294,2511.962402 1081.192871,2514.296875 1074.505371,2514.092041 
	C1059.565063,2513.634521 1044.589600,2513.492188 1029.971924,2517.588623 
	C1028.410889,2518.026123 1026.172852,2518.329834 1025.603638,2516.543945 
	C1024.862061,2514.217773 1029.270386,2509.814209 1022.653320,2510.023682 
	C1019.658142,2510.118408 1016.657227,2510.028320 1012.801453,2510.047119 
	C1008.898926,2514.062500 1004.603943,2513.013672 999.750427,2512.905762 
	C997.340942,2512.927490 995.721985,2512.911621 993.257874,2512.929199 
	C986.667419,2512.506104 980.706360,2514.309082 974.610718,2510.627441 
z"
        />
        <path
          fill="#33495B"
          opacity="1.000000"
          stroke="none"
          d="
M449.064209,2546.012695 
	C449.983398,2546.890381 449.980042,2547.770752 449.971741,2549.311523 
	C444.042999,2551.871826 437.918365,2551.993164 430.915558,2552.051270 
	C426.231964,2555.547607 421.123474,2552.854248 416.340515,2555.204102 
	C413.001770,2555.658447 410.314301,2555.725586 406.819824,2555.788086 
	C397.100250,2556.590088 388.307922,2555.612305 378.776093,2553.962891 
	C375.923981,2553.878906 373.854004,2553.833984 371.015747,2553.734863 
	C368.243134,2548.133789 363.374512,2550.664795 358.857300,2549.937256 
	C357.256195,2550.744141 356.307343,2551.034424 354.610474,2550.300293 
	C353.485352,2547.376465 348.294067,2549.312744 349.897461,2544.645264 
	C355.786163,2544.141357 361.448425,2544.102295 367.110779,2544.093750 
	C374.411011,2544.082520 381.595062,2543.960693 388.910248,2542.542969 
	C398.994720,2540.588135 409.350037,2542.542480 419.368744,2544.114990 
	C428.975464,2545.622559 438.497070,2546.191162 449.064209,2546.012695 
z"
        />
        <path
          fill="#50677C"
          opacity="1.000000"
          stroke="none"
          d="
M1066.706055,2488.017578 
	C1066.170288,2485.731445 1065.885498,2482.665771 1068.798462,2482.455566 
	C1079.216553,2481.703613 1089.082153,2477.162842 1099.696045,2478.057617 
	C1101.289917,2478.191895 1102.841553,2478.827393 1105.136475,2479.576660 
	C1107.385986,2481.417725 1109.235596,2480.985107 1111.582031,2480.361328 
	C1112.955200,2479.915039 1113.791260,2479.901123 1115.254272,2479.895508 
	C1116.996704,2480.974121 1118.067139,2482.219238 1119.824097,2480.369873 
	C1119.896118,2478.380859 1119.937500,2476.929932 1119.971680,2474.754150 
	C1123.224243,2474.125244 1127.634644,2473.028076 1128.844727,2476.763672 
	C1130.870850,2483.017822 1134.636597,2480.923828 1138.206177,2479.575684 
	C1144.380493,2477.244141 1150.684814,2478.028320 1156.968018,2478.286865 
	C1159.029053,2478.371582 1160.904175,2479.478760 1161.014648,2481.881348 
	C1161.128296,2484.357178 1159.192505,2485.178711 1157.202026,2485.730957 
	C1156.570190,2485.906250 1155.873535,2485.980713 1155.220703,2485.927490 
	C1140.221191,2484.705566 1125.448975,2488.114990 1110.491821,2488.130859 
	C1105.056030,2488.136719 1100.148438,2485.803223 1094.745850,2485.924316 
	C1090.321899,2486.023682 1087.601685,2487.199951 1088.091797,2492.985352 
	C1083.599243,2498.161621 1078.504883,2496.545654 1072.699707,2494.582520 
	C1072.084961,2490.727539 1071.514771,2488.044922 1066.706055,2488.017578 
z"
        />
        <path
          fill="#374D60"
          opacity="1.000000"
          stroke="none"
          d="
M189.998779,2576.000000 
	C191.479630,2570.046631 196.328552,2570.051514 200.952682,2570.010010 
	C211.473969,2569.915527 221.997314,2569.949707 232.518875,2570.041260 
	C236.495407,2570.075928 240.401428,2569.933838 244.918823,2568.255371 
	C255.750717,2571.660156 266.117981,2570.229980 276.233887,2569.302002 
	C281.509369,2568.818115 286.775330,2568.295166 292.959656,2568.201660 
	C293.862701,2570.208496 293.885132,2572.176270 293.836121,2574.870605 
	C278.558868,2579.190918 263.257721,2575.102295 247.103760,2576.161621 
	C244.246567,2578.296875 241.209961,2577.598877 238.437897,2579.340332 
	C229.916153,2579.764404 221.921188,2579.797607 213.008148,2579.775391 
	C209.255035,2577.511230 206.192093,2577.649902 202.455444,2579.219238 
	C200.440247,2579.645508 198.996658,2579.692627 196.831375,2579.698242 
	C194.591919,2578.281738 192.455338,2578.061768 190.426788,2576.420166 
	C189.998169,2576.000000 190.000000,2576.000000 189.998779,2576.000000 
z"
        />
        <path
          fill="#4B6377"
          opacity="1.000000"
          stroke="none"
          d="
M535.560669,2509.663086 
	C534.321594,2510.434814 533.718323,2511.117920 533.189819,2511.064209 
	C523.463623,2510.076416 513.682373,2509.855469 503.919708,2509.970947 
	C504.006348,2507.328125 505.060669,2506.340088 506.414917,2506.365479 
	C526.323730,2506.736816 545.902039,2502.629639 565.729797,2502.054443 
	C572.003723,2501.872559 578.223328,2501.985352 584.508301,2503.478027 
	C589.915894,2504.762451 595.768250,2504.655029 601.295776,2503.162354 
	C604.276245,2502.357422 607.100098,2501.829102 610.041504,2503.026855 
	C613.400574,2504.394531 616.403198,2503.861572 619.422424,2501.791260 
	C623.902527,2498.719238 629.076477,2500.229492 633.978760,2500.150635 
	C635.744873,2500.122070 637.366638,2501.089600 637.320374,2503.046875 
	C637.274170,2505.002197 635.582275,2505.973877 633.848816,2505.803467 
	C625.793945,2505.012207 618.130798,2508.608154 610.166748,2507.951416 
	C600.957825,2507.192139 591.740540,2508.480469 582.665283,2508.884766 
	C573.131042,2509.308838 563.515625,2509.606934 553.943848,2509.898438 
	C548.051941,2510.078125 542.136292,2509.480225 535.560669,2509.663086 
z"
        />
        <path
          fill="#334D63"
          opacity="1.000000"
          stroke="none"
          d="
M996.978394,2452.219238 
	C986.085083,2450.084473 975.930908,2449.856201 965.724121,2449.848633 
	C961.555359,2449.845459 957.173035,2449.359863 954.150696,2444.741699 
	C964.021667,2443.941895 974.038452,2443.883789 984.982300,2443.787842 
	C994.279541,2440.808838 1002.477478,2442.298340 1011.375732,2445.250488 
	C1016.820496,2445.851807 1021.528870,2445.913818 1027.121094,2445.974609 
	C1029.213379,2445.961426 1030.324829,2445.638916 1031.829590,2444.643555 
	C1040.938110,2443.695801 1049.682007,2445.024658 1058.922119,2442.355957 
	C1059.722900,2446.451660 1057.044678,2447.600342 1053.488892,2448.145508 
	C1049.898560,2448.695557 1046.465454,2449.761719 1045.796387,2454.947266 
	C1036.421143,2454.549316 1027.168457,2453.525879 1016.909607,2454.034912 
	C1014.839600,2453.989258 1013.769104,2453.681885 1012.336792,2452.709961 
	C1007.185852,2452.339111 1002.518127,2452.263428 996.978394,2452.219238 
z"
        />
        <path
          fill="#334D63"
          opacity="1.000000"
          stroke="none"
          d="
M1128.839600,2470.065918 
	C1128.232300,2465.856445 1130.721558,2464.093994 1135.370361,2463.779297 
	C1139.839233,2459.773926 1144.557373,2459.093506 1149.568848,2459.863037 
	C1155.071777,2460.708008 1160.550903,2460.776855 1166.814697,2460.031982 
	C1169.545044,2459.961426 1171.508057,2460.340820 1174.220337,2460.069824 
	C1175.945923,2459.998535 1176.932617,2460.045654 1178.647461,2460.302734 
	C1188.790894,2463.882324 1199.025879,2461.990723 1209.200439,2465.094482 
	C1211.629639,2465.684814 1213.344360,2466.271240 1215.838623,2466.243896 
	C1217.388794,2466.355225 1218.232544,2466.599609 1219.524414,2467.436035 
	C1219.988159,2469.285400 1219.960327,2470.692871 1219.883789,2472.789062 
	C1206.606201,2473.707031 1193.456665,2474.275146 1180.175293,2472.458252 
	C1169.746338,2471.031738 1158.974609,2471.985352 1148.357788,2472.103760 
	C1142.028931,2472.174561 1135.796997,2471.802979 1128.839600,2470.065918 
z"
        />
        <path
          fill="#395166"
          opacity="1.000000"
          stroke="none"
          d="
M600.913940,2523.891602 
	C596.798401,2520.903564 593.250671,2521.950928 589.817749,2523.093262 
	C582.772461,2525.437500 576.304138,2522.404297 569.675232,2521.042969 
	C568.310730,2520.762695 568.306824,2518.979492 568.144409,2516.915283 
	C578.426270,2515.275879 588.411438,2518.272217 598.718994,2518.108154 
	C615.212646,2517.845703 631.562256,2514.548584 649.037476,2516.034424 
	C650.201172,2519.064209 647.049438,2520.101562 646.032471,2523.094971 
	C631.211914,2526.779785 616.517822,2523.123535 600.913940,2523.891602 
z"
        />
        <path
          fill="#3C556B"
          opacity="1.000000"
          stroke="none"
          d="
M1071.474243,2493.964844 
	C1076.744751,2493.937012 1081.529053,2493.930664 1087.213135,2493.860840 
	C1092.161377,2493.887695 1096.209717,2493.978516 1101.129150,2494.080566 
	C1105.551514,2493.479736 1109.112549,2494.197021 1113.335205,2494.021484 
	C1121.385132,2494.051514 1128.766724,2494.073975 1137.068359,2494.103516 
	C1137.548340,2503.878906 1137.548584,2503.908447 1128.011841,2503.987305 
	C1121.059814,2504.044678 1114.104980,2503.913574 1107.155029,2504.049072 
	C1101.791138,2504.153809 1096.673828,2504.001709 1092.189697,2500.274170 
	C1089.870605,2498.346436 1086.994873,2498.064453 1083.764526,2498.965576 
	C1078.801758,2500.350098 1074.208008,2498.885254 1070.168945,2494.720215 
	C1070.014648,2494.009766 1070.987915,2493.986084 1071.474243,2493.964844 
z"
        />
        <path
          fill="#687D90"
          opacity="1.000000"
          stroke="none"
          d="
M414.015991,2520.772461 
	C425.916168,2523.358887 437.833221,2525.926270 450.230621,2522.181152 
	C452.675110,2521.442871 455.526337,2522.050781 459.074158,2522.060547 
	C460.249237,2525.879395 459.497498,2530.417725 456.947723,2532.187012 
	C452.833466,2535.041992 447.371338,2537.670166 442.592285,2537.483154 
	C433.557861,2537.129639 424.222504,2535.785889 417.384247,2528.402588 
	C415.532410,2526.403076 413.918671,2524.405762 414.015991,2520.772461 
z"
        />
        <path
          fill="#687D90"
          opacity="1.000000"
          stroke="none"
          d="
M378.315491,2516.580566 
	C388.692291,2514.495605 399.147064,2510.872559 411.090332,2511.993164 
	C412.814911,2516.769531 408.818604,2519.079346 406.549622,2522.101318 
	C404.199768,2525.230957 404.530396,2527.231201 409.274658,2529.941895 
	C400.957031,2529.247070 394.482147,2532.108643 387.576965,2530.126953 
	C382.091431,2528.552734 381.998718,2528.920898 386.018311,2523.668457 
	C386.382935,2523.191895 386.494843,2522.522217 386.815430,2521.711182 
	C384.915436,2518.862549 380.233673,2520.661865 378.315491,2516.580566 
z"
        />
        <path
          fill="#334D63"
          opacity="1.000000"
          stroke="none"
          d="
M901.156067,2461.827637 
	C900.238037,2456.968994 904.935913,2458.720459 907.516724,2456.382568 
	C920.447510,2451.181885 933.217407,2449.703125 947.098572,2453.139648 
	C953.973694,2453.500000 959.734680,2455.775879 965.736755,2456.483643 
	C966.596008,2456.584961 966.654907,2457.954834 966.186890,2459.529785 
	C944.655334,2459.589844 923.434326,2463.429932 901.156067,2461.827637 
z"
        />
        <path
          fill="#445E74"
          opacity="1.000000"
          stroke="none"
          d="
M672.954956,2464.192383 
	C685.312744,2460.399902 697.581299,2463.332520 709.261536,2460.180908 
	C713.372070,2459.071777 717.785828,2460.194824 723.213562,2463.873779 
	C705.987427,2462.937988 691.820557,2469.906738 676.589783,2471.566162 
	C671.396851,2472.131836 667.804688,2469.844238 662.870789,2467.117920 
	C666.710327,2465.956787 669.432617,2465.133545 672.954956,2464.192383 
z"
        />
        <path
          fill="#4B6377"
          opacity="1.000000"
          stroke="none"
          d="
M455.492065,2474.128174 
	C462.705902,2474.544922 469.047089,2472.392334 475.453735,2471.098389 
	C484.742218,2469.222412 493.667114,2469.469238 502.416473,2473.070557 
	C502.560669,2476.549072 499.983307,2475.905762 498.318329,2475.868896 
	C488.054077,2475.642822 477.973053,2476.343262 468.064728,2479.474365 
	C463.565491,2480.896240 458.880310,2480.219727 454.403564,2476.583252 
	C454.060089,2475.304688 454.416656,2474.721680 455.492065,2474.128174 
z"
        />
        <path
          fill="#395166"
          opacity="1.000000"
          stroke="none"
          d="
M695.470093,2456.045654 
	C681.645569,2459.158203 668.608337,2457.799561 655.419006,2456.859619 
	C657.802002,2453.229004 661.560486,2452.577393 664.731995,2452.443604 
	C675.700317,2451.980469 686.698181,2452.185547 697.684448,2452.222900 
	C698.905701,2452.227051 700.767212,2452.447510 700.387085,2454.238281 
	C699.954529,2456.275635 697.839233,2455.782959 695.470093,2456.045654 
z"
        />
        <path
          fill="#465F75"
          opacity="1.000000"
          stroke="none"
          d="
M555.102356,2469.980225 
	C558.766724,2473.520020 561.742676,2470.529785 565.311035,2470.232666 
	C566.894287,2472.561279 568.576355,2476.772217 564.124756,2475.330566 
	C553.313293,2471.828857 543.040039,2475.285889 532.779480,2476.659424 
	C526.541016,2477.494385 521.376831,2475.893799 515.686768,2474.174805 
	C528.469421,2471.978027 541.157715,2469.570801 555.102356,2469.980225 
z"
        />
        <path
          fill="#4B6377"
          opacity="1.000000"
          stroke="none"
          d="
M467.590668,2458.467773 
	C478.281494,2456.915283 488.915070,2460.068848 498.904785,2454.949219 
	C500.279480,2454.244873 502.645660,2455.474609 505.273315,2455.882324 
	C508.260101,2455.449463 508.708191,2456.905518 508.511993,2459.361816 
	C507.831024,2460.677979 507.402710,2461.160889 506.404541,2461.742188 
	C493.936066,2461.965088 481.907593,2461.974365 468.943054,2461.980225 
	C467.308929,2461.148438 467.036560,2460.198486 467.590668,2458.467773 
z"
        />
        <path
          fill="#4B6377"
          opacity="1.000000"
          stroke="none"
          d="
M378.467163,2554.535645 
	C380.345856,2551.315674 383.636200,2551.437012 386.292664,2552.286133 
	C392.370026,2554.228271 398.714966,2553.386719 405.403717,2555.280029 
	C396.896698,2559.486816 387.873444,2559.237793 378.467163,2554.535645 
z"
        />
        <path
          fill="#546D83"
          opacity="1.000000"
          stroke="none"
          d="
M467.622681,2457.417480 
	C467.994751,2458.736328 467.998566,2459.663330 468.004700,2461.283447 
	C465.675171,2464.361816 465.347900,2468.848145 460.388885,2468.456055 
	C460.626831,2465.794189 461.246948,2463.556152 461.952698,2460.619385 
	C463.975922,2459.313721 464.183105,2455.589355 467.622681,2457.417480 
z"
        />
        <path
          fill="#687D90"
          opacity="1.000000"
          stroke="none"
          d="
M466.103027,2522.705566 
	C468.177246,2520.348877 470.691223,2520.521973 473.573059,2520.310059 
	C474.838470,2520.340332 476.403107,2520.578125 476.511719,2521.111816 
	C477.403290,2525.493164 472.672607,2524.668701 470.798706,2526.504639 
	C468.597839,2528.660889 466.964722,2525.686279 466.103027,2522.705566 
z"
        />
        <path
          fill="#4B6377"
          opacity="1.000000"
          stroke="none"
          d="
M416.556030,2555.716797 
	C418.509644,2549.443848 424.086853,2553.027588 429.202942,2552.040039 
	C429.603302,2554.791992 427.911011,2555.953369 425.262299,2555.925293 
	C422.649170,2555.897705 420.036011,2555.870117 416.556030,2555.716797 
z"
        />
        <path
          fill="#4B6377"
          opacity="1.000000"
          stroke="none"
          d="
M569.158081,2562.668457 
	C567.995728,2559.496338 567.574463,2556.622314 571.425110,2556.666260 
	C574.038513,2556.696289 575.305176,2559.372559 574.963623,2561.543457 
	C574.455017,2564.776855 571.823242,2563.862061 569.158081,2562.668457 
z"
        />
        <path
          fill="#50677C"
          opacity="1.000000"
          stroke="none"
          d="
M1113.568359,2493.533691 
	C1110.605225,2496.993408 1106.839111,2496.163086 1102.524902,2494.591309 
	C1105.180054,2488.127930 1109.374390,2492.884521 1113.568359,2493.533691 
z"
        />
        <path
          fill="#546D83"
          opacity="1.000000"
          stroke="none"
          d="
M456.002258,2473.248047 
	C455.750854,2474.626221 455.348846,2475.145752 454.354675,2475.782227 
	C452.319916,2475.504883 450.727234,2474.994629 448.554138,2474.218750 
	C449.474518,2471.169678 450.482300,2467.828613 455.340576,2468.055664 
	C456.025421,2469.541016 456.013550,2471.023682 456.002258,2473.248047 
z"
        />
        <path
          fill="#4B6377"
          opacity="1.000000"
          stroke="none"
          d="
M517.593140,2487.475098 
	C516.663330,2490.900146 514.346558,2492.084717 510.519135,2491.785645 
	C510.644409,2483.854248 510.644409,2483.854248 517.593140,2487.475098 
z"
        />
        <path
          fill="#4B6377"
          opacity="1.000000"
          stroke="none"
          d="
M543.544312,2483.520752 
	C540.907104,2486.493408 537.282043,2487.541748 532.681274,2487.939941 
	C534.079407,2482.693115 538.316101,2482.247314 543.544312,2483.520752 
z"
        />
        <path
          fill="#465F75"
          opacity="1.000000"
          stroke="none"
          d="
M329.487244,2567.030762 
	C330.870880,2559.095703 334.844788,2564.475830 338.005035,2564.833740 
	C335.451263,2566.248047 333.089203,2568.173096 329.487244,2567.030762 
z"
        />
        <path
          fill="#50677C"
          opacity="1.000000"
          stroke="none"
          d="
M800.413452,2449.763672 
	C801.155334,2444.971191 805.118225,2446.231689 809.285095,2446.142090 
	C808.775269,2451.454346 804.559570,2449.588379 800.413452,2449.763672 
z"
        />
        <path
          fill="#687D90"
          opacity="1.000000"
          stroke="none"
          d="
M302.003174,2499.391113 
	C299.228882,2500.055908 296.445465,2500.092285 292.827484,2500.147461 
	C291.295380,2499.347412 291.012451,2498.404053 291.594666,2496.715820 
	C294.785889,2496.109863 297.527008,2496.124512 301.084839,2496.200684 
	C301.932404,2497.095947 301.963226,2497.929443 302.003174,2499.391113 
z"
        />
        <path
          fill="#4B6377"
          opacity="1.000000"
          stroke="none"
          d="
M282.379272,2548.588379 
	C282.659302,2547.049072 283.628540,2546.384277 285.578369,2546.308105 
	C285.668793,2548.046631 285.339264,2550.005371 282.379272,2548.588379 
z"
        />
        <path
          fill="#50677C"
          opacity="1.000000"
          stroke="none"
          d="
M1011.979370,2452.937012 
	C1012.989990,2452.641113 1014.126404,2452.867432 1015.627563,2453.569580 
	C1014.382874,2455.929199 1013.095093,2455.680908 1011.979370,2452.937012 
z"
        />
        <path
          fill="#4B6377"
          opacity="1.000000"
          stroke="none"
          d="
M510.429688,2499.756104 
	C510.226227,2498.036865 510.882446,2496.536865 513.591858,2496.398926 
	C513.773132,2498.128662 512.982910,2499.488037 510.429688,2499.756104 
z"
        />
        <path
          fill="#687D90"
          opacity="1.000000"
          stroke="none"
          d="
M274.904419,2510.048096 
	C274.222046,2507.163818 275.938690,2505.993652 279.382446,2505.963135 
	C293.059479,2505.960449 306.027313,2506.207520 318.985596,2505.958252 
	C326.292023,2505.817627 333.090820,2508.092285 340.939575,2508.684814 
	C338.298553,2513.042969 334.511475,2511.774658 331.565491,2512.419434 
	C321.808624,2514.555908 312.076172,2516.141357 302.628510,2510.782715 
	C299.683563,2509.112305 296.093933,2510.049072 292.792725,2510.019287 
	C287.142456,2509.968262 281.491058,2510.038086 274.904419,2510.048096 
z"
        />
        <path
          fill="#5D758A"
          opacity="1.000000"
          stroke="none"
          d="
M291.269653,2496.062012 
	C292.029968,2496.998291 292.015106,2497.901123 291.996582,2499.485352 
	C290.885651,2500.726074 289.823120,2501.613770 288.661072,2501.772217 
	C286.663422,2502.044678 284.562805,2501.556152 284.512115,2499.042969 
	C284.461121,2496.516602 286.587555,2496.201660 288.572662,2496.059814 
	C289.210754,2496.013916 289.853760,2496.037354 291.269653,2496.062012 
z"
        />
        <path
          fill="#687D90"
          opacity="1.000000"
          stroke="none"
          d="
M127.278290,2493.183594 
	C128.746872,2492.780029 129.308670,2493.269287 129.072250,2494.428467 
	C129.023987,2494.665039 128.682281,2494.980713 128.440384,2495.015625 
	C127.293594,2495.182373 126.787582,2494.637939 127.278290,2493.183594 
z"
        />
        <path
          fill="#5D758A"
          opacity="1.000000"
          stroke="none"
          d="
M354.088623,2550.386230 
	C354.913177,2549.827148 355.808502,2549.869385 357.376892,2549.928955 
	C357.545898,2550.958984 357.283722,2552.491211 356.481812,2552.864502 
	C355.234924,2553.445557 354.484863,2552.209961 354.088623,2550.386230 
z"
        />
        <path
          fill="#CBC5B5"
          opacity="1.000000"
          stroke="none"
          d="
M1553.471680,3721.479004 
	C1554.000000,3722.000000 1553.980591,3722.995117 1553.973877,3723.492676 
	C1550.744141,3726.795898 1547.027222,3729.321045 1547.996216,3735.253174 
	C1537.912109,3734.838135 1536.080200,3731.625732 1541.692505,3724.138184 
	C1545.098022,3719.594482 1539.984863,3720.566650 1538.446655,3718.499756 
	C1538.672485,3717.087891 1539.301636,3716.155029 1540.005371,3714.645264 
	C1541.353760,3714.049561 1542.658447,3714.203369 1543.896240,3713.982178 
	C1549.524780,3712.977295 1552.074463,3715.850098 1553.471680,3721.479004 
z"
        />
        <path
          fill="#E5DDC9"
          opacity="1.000000"
          stroke="none"
          d="
M1564.256104,3641.390625 
	C1562.720215,3642.318359 1560.967896,3642.468018 1560.745728,3643.879395 
	C1559.824097,3649.729736 1556.856934,3652.651367 1550.679077,3652.115723 
	C1545.746826,3651.688232 1546.526855,3656.328125 1544.536499,3659.479492 
	C1540.855347,3657.501953 1542.720581,3653.694824 1541.568115,3650.697998 
	C1540.660645,3648.338135 1537.913452,3647.504639 1537.179932,3649.645264 
	C1533.959229,3659.045654 1526.043091,3657.316650 1519.116943,3657.966064 
	C1515.166382,3658.336426 1511.116699,3657.435791 1507.224365,3658.633545 
	C1502.784790,3659.999512 1502.052368,3661.748047 1504.076294,3665.969971 
	C1504.645142,3667.156494 1505.487183,3668.212891 1506.215698,3669.321533 
	C1508.845581,3673.323242 1508.077026,3676.500244 1503.379028,3677.644775 
	C1499.798462,3678.517090 1498.050903,3679.490967 1499.451294,3683.586426 
	C1500.459595,3686.534912 1498.700439,3688.559570 1495.858276,3689.746094 
	C1489.846069,3692.255615 1486.540771,3696.213135 1487.186890,3703.372070 
	C1487.557861,3707.482178 1482.021362,3711.846436 1477.708862,3711.804199 
	C1476.098511,3711.788574 1474.507324,3710.838623 1472.885254,3710.758057 
	C1470.086060,3710.618896 1467.362793,3711.419922 1466.622314,3714.449463 
	C1465.883667,3717.472168 1468.634155,3718.464844 1470.729980,3719.170898 
	C1475.512817,3720.783691 1479.621094,3722.533691 1476.568604,3729.582275 
	C1471.286133,3725.960938 1466.811890,3725.715332 1464.685059,3732.853760 
	C1464.483398,3733.530762 1463.077759,3733.899658 1462.185303,3734.312744 
	C1451.472412,3739.273438 1451.358276,3748.254150 1453.495483,3758.967285 
	C1448.356812,3757.651367 1444.745972,3759.763916 1441.369629,3763.100586 
	C1439.348633,3765.097656 1438.456787,3766.326904 1440.605225,3768.734619 
	C1442.634766,3771.009277 1442.126221,3773.779297 1440.788696,3776.207275 
	C1436.476318,3784.033691 1432.284912,3792.150879 1423.412720,3795.770020 
	C1419.795654,3797.245117 1417.972168,3799.262939 1417.994263,3803.070557 
	C1418.010864,3805.921143 1417.087769,3808.286133 1414.421387,3809.698975 
	C1404.888062,3814.751221 1396.756836,3822.140869 1386.717407,3826.334717 
	C1385.294556,3826.929199 1384.502441,3827.856445 1384.605591,3829.266357 
	C1384.714111,3830.750977 1385.869751,3831.479248 1387.264648,3831.779053 
	C1390.240112,3832.418701 1395.174438,3831.449951 1394.473633,3836.067139 
	C1393.973511,3839.361816 1389.365845,3837.477783 1386.626221,3838.055908 
	C1385.341064,3838.327393 1383.223877,3838.314697 1382.924927,3839.031494 
	C1380.023682,3845.986328 1372.294800,3845.751221 1366.749023,3849.890625 
	C1363.179443,3840.481934 1364.537720,3831.684326 1371.503906,3824.336670 
	C1379.817139,3815.568115 1386.596313,3805.580566 1394.270264,3796.406006 
	C1399.098633,3790.633301 1405.337891,3785.407715 1410.974121,3780.077637 
	C1410.572266,3778.754639 1409.532959,3778.698486 1408.597778,3778.607910 
	C1403.151245,3778.079102 1396.442017,3779.292236 1395.377930,3771.484619 
	C1394.888672,3767.895508 1406.865356,3760.683350 1411.608276,3762.382080 
	C1420.262573,3765.481689 1423.000488,3761.843506 1426.094727,3754.316406 
	C1430.100220,3744.572510 1440.167358,3739.683594 1447.702148,3732.812500 
	C1451.411499,3729.430176 1455.490112,3726.461914 1457.674072,3721.830078 
	C1459.204956,3718.583252 1459.187134,3714.610840 1456.174316,3712.744873 
	C1453.163818,3710.880127 1451.967041,3714.874023 1450.388916,3716.717041 
	C1444.057983,3724.109863 1434.908813,3728.599121 1430.428833,3738.168457 
	C1428.364258,3742.578613 1422.061401,3745.489014 1416.727905,3745.333252 
	C1406.312866,3745.029785 1397.650391,3750.239258 1388.126221,3752.601562 
	C1379.870850,3754.649658 1371.023926,3753.789307 1361.991943,3756.316406 
	C1356.427246,3754.830566 1352.137451,3756.271729 1347.848633,3758.449707 
	C1344.315430,3760.243164 1340.416870,3760.535889 1337.952637,3756.912842 
	C1334.760620,3752.218994 1334.818726,3746.751709 1336.630127,3741.568359 
	C1337.940674,3737.819092 1341.552368,3737.450439 1345.012329,3737.647217 
	C1349.382812,3737.895508 1353.433228,3739.585938 1358.311890,3740.894043 
	C1359.449097,3741.264893 1359.888306,3741.428467 1360.946045,3741.874268 
	C1363.050049,3742.892822 1364.483154,3743.829834 1366.864624,3743.149902 
	C1378.678833,3735.071289 1384.412476,3722.659912 1392.725708,3712.403564 
	C1402.253174,3700.649414 1412.287109,3689.341309 1422.241577,3677.963867 
	C1424.200684,3675.724609 1424.994263,3673.611328 1424.638184,3670.797363 
	C1423.742554,3663.722900 1427.306030,3659.874512 1433.585938,3657.142090 
	C1442.857056,3653.108643 1448.224976,3640.022949 1444.888428,3631.086426 
	C1442.996460,3626.018555 1449.769287,3613.433105 1456.601074,3609.840088 
	C1459.530640,3608.299561 1462.689819,3607.190186 1466.024902,3604.878906 
	C1468.722168,3603.375488 1470.857544,3602.319092 1473.496216,3600.684570 
	C1475.074829,3600.099854 1476.012329,3599.908203 1477.678467,3599.830078 
	C1479.676758,3599.981934 1481.518066,3601.049805 1482.106201,3600.484131 
	C1489.210815,3593.648926 1497.184692,3597.560791 1505.519775,3598.491943 
	C1506.698120,3598.842529 1507.194946,3599.168701 1507.625122,3599.585693 
	C1508.066162,3600.012939 1508.002563,3599.816162 1507.479736,3598.363770 
	C1508.905762,3593.546631 1512.947998,3592.365234 1516.074951,3590.140625 
	C1517.849121,3588.878906 1518.490479,3587.072754 1517.944824,3585.004395 
	C1517.358276,3582.781494 1515.492310,3582.071289 1513.460449,3581.924561 
	C1509.302979,3581.624512 1505.018555,3583.412354 1500.429688,3580.711426 
	C1496.893433,3575.464844 1497.910034,3569.955566 1497.147705,3564.758545 
	C1496.602783,3561.042725 1496.113037,3557.250488 1500.228027,3555.167236 
	C1504.109863,3553.202393 1507.657104,3554.928955 1510.174072,3557.622070 
	C1516.844238,3564.760010 1524.260864,3565.577637 1532.642334,3561.311035 
	C1533.528931,3560.859863 1534.223633,3560.149902 1534.297852,3558.768799 
	C1530.970337,3555.542480 1526.469116,3556.355225 1522.356079,3555.848877 
	C1519.606812,3555.510742 1515.312134,3554.293213 1515.930420,3552.400391 
	C1518.763184,3543.728516 1511.094849,3546.373047 1508.009155,3544.575439 
	C1503.192993,3541.769775 1498.039673,3539.148926 1500.312256,3532.553223 
	C1502.657593,3525.745850 1508.704468,3526.780273 1513.792236,3528.435547 
	C1524.635010,3531.964111 1536.174561,3533.646729 1546.493408,3540.541504 
	C1549.277710,3543.618408 1549.809814,3546.966064 1551.248535,3550.255859 
	C1552.482544,3541.590820 1562.825684,3536.385010 1559.187256,3525.613281 
	C1557.943726,3521.932129 1562.941040,3519.832764 1566.802856,3519.521729 
	C1571.168213,3519.170166 1576.781128,3518.959473 1577.292847,3524.393066 
	C1578.030884,3532.227295 1582.559326,3532.947754 1588.626099,3533.501709 
	C1594.031250,3533.995361 1597.378540,3536.817383 1594.489258,3543.499756 
	C1594.000000,3544.000000 1593.009766,3544.008789 1592.514648,3544.014160 
	C1584.887939,3548.221924 1576.675415,3543.607910 1569.031250,3546.960205 
	C1574.363403,3548.523438 1582.241455,3547.514160 1578.470459,3557.487305 
	C1576.531616,3560.548828 1574.995728,3563.056885 1573.609619,3565.645264 
	C1573.173950,3566.458984 1572.795654,3567.563477 1572.977905,3568.408203 
	C1575.198120,3578.700928 1573.713623,3582.296631 1564.431152,3588.429199 
	C1561.682617,3590.245117 1561.637451,3592.003906 1564.552246,3593.530273 
	C1565.690918,3594.126953 1567.152710,3594.075684 1568.366455,3594.568115 
	C1569.949707,3595.209961 1571.293945,3596.254150 1571.125488,3598.234619 
	C1570.963379,3600.137695 1569.567993,3601.057861 1567.957153,3601.626465 
	C1567.027832,3601.954346 1566.011108,3602.027588 1565.043701,3602.256836 
	C1563.636841,3602.591309 1562.576538,3603.441895 1562.550903,3604.910889 
	C1562.526001,3606.331299 1563.366211,3607.359131 1564.850342,3607.700928 
	C1566.775269,3608.144531 1568.734863,3608.377686 1570.413696,3609.653076 
	C1573.534424,3612.023682 1573.504150,3613.858643 1570.156738,3615.992920 
	C1564.571655,3619.554443 1561.309204,3630.305908 1564.327271,3636.178711 
	C1565.214722,3637.905518 1565.221558,3639.214111 1564.256104,3641.390625 
z"
        />
        <path
          fill="#DED5C3"
          opacity="1.000000"
          stroke="none"
          d="
M1361.523682,3754.384277 
	C1364.746460,3754.105957 1367.262695,3753.529053 1369.445557,3751.721436 
	C1371.674927,3749.875977 1374.440063,3749.671387 1377.049561,3750.104736 
	C1382.212524,3750.962891 1387.305786,3752.736084 1391.074585,3746.930664 
	C1391.921021,3745.626709 1394.214111,3746.235840 1395.780762,3746.890869 
	C1401.176880,3749.146729 1402.005371,3741.803711 1406.830200,3741.835938 
	C1411.038086,3741.864014 1415.137451,3744.886230 1419.444702,3742.246582 
	C1425.280640,3738.670654 1432.018066,3736.159912 1433.123413,3727.915771 
	C1433.585571,3724.468750 1435.326172,3721.677979 1439.683472,3721.924561 
	C1443.141479,3722.120117 1444.962158,3719.793945 1446.590332,3717.067383 
	C1448.288330,3714.223633 1450.234863,3711.507812 1452.284180,3708.902344 
	C1453.517456,3707.333984 1455.209473,3705.987793 1457.426392,3706.534180 
	C1459.903809,3707.144775 1459.895142,3709.357422 1459.998291,3711.339844 
	C1460.105103,3713.393311 1460.640869,3715.255615 1461.915161,3716.898438 
	C1463.286743,3718.666016 1463.485474,3720.248047 1461.813965,3722.165527 
	C1457.912354,3726.640869 1455.041626,3732.086670 1449.572510,3735.055176 
	C1445.690063,3737.162109 1442.493042,3739.963867 1440.115601,3743.711914 
	C1439.412720,3744.820068 1438.678589,3746.440186 1437.652344,3746.726562 
	C1430.512695,3748.718018 1427.552490,3753.380859 1427.321655,3760.626465 
	C1427.220093,3763.809570 1423.924438,3765.029053 1421.566895,3766.899414 
	C1418.257202,3769.524902 1416.767700,3768.562988 1414.302246,3765.933350 
	C1411.684326,3763.140625 1407.834839,3763.163330 1405.516357,3766.178223 
	C1403.281860,3769.084229 1399.995972,3769.576904 1396.851318,3771.561035 
	C1399.201172,3775.876221 1402.961182,3776.207764 1406.980347,3775.811279 
	C1408.927490,3775.619629 1410.945312,3774.648438 1412.759888,3774.976807 
	C1415.099243,3775.399902 1416.858398,3777.249756 1417.158325,3779.799072 
	C1417.383911,3781.717285 1415.973999,3783.273193 1414.359253,3783.377930 
	C1409.102783,3783.717285 1406.634521,3787.188721 1405.383911,3791.441406 
	C1403.787964,3796.869141 1398.117188,3794.962646 1395.253296,3797.122803 
	C1395.088257,3800.354980 1398.648682,3799.626221 1398.826538,3801.894531 
	C1398.502930,3802.415771 1398.246826,3803.327637 1397.835571,3803.403809 
	C1391.503052,3804.578613 1387.140625,3807.869873 1384.570679,3814.001465 
	C1382.766235,3818.307129 1379.330811,3821.696777 1375.536743,3824.427246 
	C1370.241089,3828.237793 1367.438354,3833.107422 1367.993408,3839.705811 
	C1368.253662,3842.800537 1367.060791,3845.580322 1366.132080,3849.245605 
	C1365.822510,3851.811523 1365.264648,3853.327148 1362.693604,3853.963623 
	C1359.448242,3851.626221 1357.407837,3848.997070 1359.731812,3844.581055 
	C1357.544800,3841.278564 1358.901367,3838.315918 1359.580688,3834.700684 
	C1362.117798,3823.424072 1361.423706,3822.281006 1351.337036,3821.587158 
	C1348.256836,3821.375488 1344.771973,3821.420654 1343.495850,3818.459229 
	C1340.277466,3810.991455 1338.332275,3803.004395 1331.480469,3797.478760 
	C1329.643799,3795.997070 1331.289795,3793.079590 1332.846313,3791.262939 
	C1334.737671,3789.054932 1337.502075,3787.411621 1337.320801,3783.232422 
	C1337.551514,3782.099854 1337.679443,3781.656494 1338.078491,3780.581543 
	C1340.364868,3776.027588 1345.081665,3774.047363 1346.276611,3768.738770 
	C1347.469116,3766.669434 1348.700928,3765.464111 1350.295166,3763.748047 
	C1351.873413,3762.125977 1353.147583,3761.082764 1354.588135,3759.341797 
	C1356.702637,3757.055908 1358.726685,3755.693848 1361.523682,3754.384277 
z"
        />
        <path
          fill="#E5DDC9"
          opacity="1.000000"
          stroke="none"
          d="
M1543.300781,3663.916016 
	C1548.709229,3664.719238 1552.183350,3668.661133 1557.516357,3669.488525 
	C1557.985474,3670.880127 1557.979980,3671.760498 1557.971069,3673.300781 
	C1556.607666,3674.363281 1543.795044,3684.021729 1542.541016,3685.582031 
	C1541.988892,3685.990234 1540.999268,3686.002930 1540.504395,3686.011230 
	C1539.656006,3686.263428 1539.302490,3686.507080 1538.443726,3686.351074 
	C1537.660767,3677.106934 1537.661011,3677.105469 1529.292358,3675.962402 
	C1527.737793,3675.750000 1525.684814,3676.139404 1525.262329,3674.080078 
	C1524.746460,3671.564941 1526.752808,3670.596191 1528.712891,3670.279541 
	C1531.539062,3669.823486 1533.413818,3668.790039 1534.423462,3665.812744 
	C1535.970825,3661.250000 1539.465698,3662.877686 1543.300781,3663.916016 
z"
        />
        <path
          fill="#DAD3C3"
          opacity="1.000000"
          stroke="none"
          d="
M1537.197021,3685.955566 
	C1538.283569,3685.965088 1538.628662,3685.979248 1539.491699,3686.006348 
	C1540.004028,3686.910156 1539.998535,3687.801025 1539.984375,3689.360352 
	C1537.156250,3690.045654 1534.408081,3689.879883 1532.325684,3686.565674 
	C1533.484741,3685.959717 1534.969971,3685.959717 1537.197021,3685.955566 
z"
        />
        <path
          fill="#E6E1D0"
          opacity="1.000000"
          stroke="none"
          d="
M1361.434692,3833.569824 
	C1362.339355,3837.049072 1360.999268,3839.738525 1360.076172,3843.264160 
	C1354.657471,3843.998047 1349.333374,3844.081055 1344.011963,3843.991943 
	C1339.348145,3843.914062 1336.422241,3845.580078 1335.867188,3850.641602 
	C1335.508667,3853.909668 1333.629517,3856.925781 1334.000000,3861.187500 
	C1328.455933,3860.569580 1324.450562,3856.937500 1321.366211,3852.422363 
	C1319.679199,3849.953125 1317.972900,3847.807373 1314.620117,3846.286621 
	C1312.301514,3844.272705 1310.571045,3842.577393 1308.446411,3840.419922 
	C1307.772827,3839.368164 1307.384644,3838.852783 1306.430054,3838.202637 
	C1305.923096,3832.350098 1313.316650,3833.104736 1314.637939,3827.733887 
	C1317.891602,3824.126709 1321.237915,3824.069336 1325.515381,3825.716309 
	C1336.614502,3833.931396 1347.416016,3839.982178 1361.434692,3833.569824 
z"
        />
        <path
          fill="#E5DDC9"
          opacity="1.000000"
          stroke="none"
          d="
M1316.207520,3827.308838 
	C1315.375610,3835.073486 1315.375610,3835.073486 1306.533569,3837.442383 
	C1306.005371,3837.960693 1305.019287,3837.971436 1304.526733,3837.969238 
	C1304.229492,3833.078125 1302.182251,3829.706543 1296.707764,3828.085449 
	C1292.326660,3822.824219 1296.034180,3820.031006 1300.176392,3816.727051 
	C1303.286621,3815.125977 1305.776733,3814.270752 1309.255127,3814.924561 
	C1313.096436,3818.441895 1314.778687,3822.511963 1316.207520,3827.308838 
z"
        />
        <path
          fill="#EAE6D7"
          opacity="1.000000"
          stroke="none"
          d="
M1308.096313,3840.666016 
	C1311.041382,3840.192871 1313.164673,3841.516602 1313.917725,3845.326172 
	C1310.970215,3845.807373 1308.838257,3844.491943 1308.096313,3840.666016 
z"
        />
        <path
          fill="#EAE5D5"
          opacity="1.000000"
          stroke="none"
          d="
M1982.476074,3545.868896 
	C1976.673828,3546.925781 1978.373657,3551.162598 1977.982666,3555.198242 
	C1975.737671,3556.412109 1973.231201,3557.718018 1971.307373,3557.127197 
	C1961.778809,3554.200195 1953.363770,3556.228027 1944.744263,3561.904541 
	C1942.034180,3563.693848 1939.833984,3563.092041 1939.142822,3561.174072 
	C1936.726562,3554.469238 1933.674072,3546.350098 1935.864502,3540.559570 
	C1938.689209,3533.092773 1938.460693,3525.179199 1941.482666,3517.413574 
	C1940.932373,3514.149170 1939.071411,3512.392822 1937.208618,3509.861084 
	C1942.109985,3500.601074 1943.148804,3487.744629 1957.373901,3488.742188 
	C1952.926025,3487.851807 1946.428833,3488.375000 1950.809570,3479.541992 
	C1951.544189,3478.900146 1951.820679,3478.714111 1952.096802,3478.527588 
	C1952.372803,3478.341309 1952.648682,3478.154297 1952.924438,3477.967529 
	C1951.255249,3475.691650 1948.739624,3477.123047 1946.660156,3476.590820 
	C1943.127319,3475.686279 1940.327637,3474.245361 1940.262939,3470.131104 
	C1940.198364,3466.016357 1942.905396,3463.586426 1946.470459,3463.705322 
	C1955.427856,3464.003174 1955.780640,3459.363770 1954.170044,3452.490723 
	C1951.895020,3442.782715 1950.942749,3432.584961 1943.076660,3425.229492 
	C1940.498535,3422.818848 1940.781494,3420.911865 1943.043701,3418.519531 
	C1951.365479,3409.719482 1959.635010,3410.818115 1964.198242,3421.712891 
	C1965.612549,3425.089355 1967.483276,3426.538086 1970.963135,3425.948730 
	C1975.187378,3425.232910 1979.346313,3424.306885 1983.078735,3421.836182 
	C1981.739380,3420.093506 1980.327393,3420.609863 1979.095459,3420.593262 
	C1973.624634,3420.519531 1969.031860,3419.031738 1967.926636,3412.894287 
	C1966.750610,3406.363281 1971.391602,3403.306396 1976.078491,3400.586426 
	C1979.850098,3398.397461 1985.452759,3399.275146 1988.180908,3392.690186 
	C1981.457031,3393.964844 1977.451416,3397.869629 1973.444092,3401.144531 
	C1966.471924,3406.841553 1957.076416,3407.721436 1949.999878,3402.490967 
	C1945.121094,3398.884277 1945.301636,3394.616699 1951.050781,3391.958740 
	C1954.604858,3390.315674 1956.665894,3388.418701 1958.422119,3384.746094 
	C1962.251465,3376.738037 1970.959961,3377.222900 1978.129272,3375.250732 
	C1980.679688,3374.549072 1983.437012,3375.277588 1986.639282,3374.323730 
	C1983.567505,3372.332764 1980.795410,3370.708252 1978.053589,3369.032227 
	C1975.568726,3367.513672 1972.074707,3366.397217 1973.602051,3362.440674 
	C1974.825317,3359.272217 1977.939209,3357.985596 1980.990723,3358.437988 
	C1987.254883,3359.366943 1989.117188,3357.734863 1990.193848,3350.865479 
	C1990.980103,3345.848145 1988.638062,3348.242432 1987.272705,3347.795166 
	C1984.326660,3346.831055 1981.234009,3345.721436 1981.320923,3342.069336 
	C1981.408081,3338.413818 1984.748535,3338.496826 1987.312500,3338.251465 
	C1992.708740,3337.735840 1993.400879,3334.413574 1993.235352,3329.816162 
	C1992.804932,3317.866699 1995.950928,3315.130371 2008.418335,3316.917480 
	C2014.488281,3324.606934 2012.525269,3332.950928 2012.198364,3341.722900 
	C2010.420166,3344.773682 2010.172485,3347.143799 2012.228760,3350.224609 
	C2013.277710,3361.989990 2013.024414,3373.056641 2012.469971,3384.878906 
	C2012.069214,3386.255127 2011.903442,3387.117920 2011.399902,3387.356201 
	C1999.224365,3393.116699 1995.778198,3401.229736 2003.439209,3413.334473 
	C2005.647339,3416.822998 2004.475342,3421.251709 2000.685791,3423.886475 
	C1996.677368,3426.673340 1994.923096,3429.002686 1999.939331,3432.737793 
	C2006.681396,3437.758301 2000.374390,3441.909912 1998.508667,3446.307129 
	C1997.734619,3448.130859 1996.937378,3449.918945 1995.542480,3451.409180 
	C1994.280396,3452.757324 1991.735352,3453.802734 1992.859253,3456.090576 
	C1994.232910,3458.886719 1996.848633,3457.398193 1999.038330,3457.048584 
	C2000.027466,3456.890137 2001.005371,3457.010742 2002.538574,3457.791504 
	C2008.365967,3467.780518 2000.508423,3468.327637 1994.103271,3470.515137 
	C1985.794800,3474.393311 1988.220947,3480.060303 1990.978271,3485.252441 
	C1994.476562,3491.840088 1996.028198,3498.269531 1991.874023,3505.024658 
	C1989.753662,3508.472168 1987.292358,3511.994629 1982.602661,3511.361084 
	C1977.646851,3510.691650 1976.957642,3506.385986 1976.188110,3502.420654 
	C1975.956543,3501.227783 1976.149414,3499.988770 1976.102905,3499.702881 
	C1974.678589,3509.415527 1981.531738,3518.364258 1982.047974,3529.307617 
	C1981.811646,3534.257080 1982.000854,3538.462402 1983.869385,3543.216309 
	C1983.868896,3544.702881 1983.538940,3545.288330 1982.476074,3545.868896 
z"
        />
        <path
          fill="#F2EBDD"
          opacity="1.000000"
          stroke="none"
          d="
M1943.357178,3515.706543 
	C1945.470215,3517.248779 1945.838989,3518.761230 1944.535034,3520.246582 
	C1942.158569,3522.954590 1941.607422,3526.152588 1942.101440,3529.484619 
	C1942.715820,3533.628418 1944.254272,3537.778564 1938.208984,3539.447998 
	C1936.582031,3539.896973 1935.944336,3542.695068 1937.352783,3543.564453 
	C1942.686157,3546.858398 1940.070801,3551.523193 1940.041016,3555.781738 
	C1940.025024,3558.067383 1939.816895,3560.505371 1943.272949,3561.825195 
	C1943.998535,3562.886719 1943.997192,3563.773682 1943.993042,3565.325195 
	C1936.756714,3567.581543 1936.756714,3567.581543 1938.281372,3575.476074 
	C1932.123047,3576.242920 1925.863647,3575.773682 1918.964355,3578.034424 
	C1919.406616,3572.274414 1923.000854,3568.256348 1922.125732,3563.152344 
	C1921.366211,3558.723389 1919.515137,3554.507812 1920.666138,3549.492188 
	C1921.321289,3546.636963 1918.812256,3542.739502 1917.019409,3539.734131 
	C1915.460083,3537.120361 1912.214966,3538.208496 1908.822144,3538.000244 
	C1906.555054,3535.629395 1907.312988,3533.345459 1908.668945,3530.457031 
	C1909.421143,3529.525635 1909.781372,3529.207031 1910.784058,3528.572754 
	C1915.034546,3527.104492 1918.548462,3528.312256 1922.097168,3528.676758 
	C1927.930420,3529.276123 1933.262207,3529.394287 1934.811035,3521.678467 
	C1935.576782,3517.863525 1938.780029,3515.857910 1943.357178,3515.706543 
z"
        />
        <path
          fill="#DBD5C8"
          opacity="1.000000"
          stroke="none"
          d="
M1944.639893,3633.987305 
	C1943.973267,3633.115234 1943.985474,3632.230469 1944.005859,3630.682617 
	C1945.762207,3630.276123 1948.330200,3629.394775 1948.746948,3631.848877 
	C1949.122314,3634.061035 1946.758911,3633.682129 1944.639893,3633.987305 
z"
        />
        <path
          fill="#EAE5D5"
          opacity="1.000000"
          stroke="none"
          d="
M1954.088257,3624.890625 
	C1955.442261,3625.633301 1955.458984,3626.380371 1954.490967,3627.040039 
	C1954.288574,3627.178467 1953.819336,3627.172852 1953.621948,3627.029297 
	C1952.677368,3626.341064 1952.713623,3625.594971 1954.088257,3624.890625 
z"
        />
        <path
          fill="#817A79"
          opacity="1.000000"
          stroke="none"
          d="
M2010.312500,3490.694580 
	C2010.333008,3487.892822 2010.236816,3485.747070 2010.048340,3482.800781 
	C2007.921021,3479.790527 2007.232544,3477.118164 2007.315063,3473.360840 
	C2007.989380,3462.681396 2009.208740,3452.870361 2009.015991,3442.131836 
	C2008.432007,3437.741699 2008.878296,3434.308105 2010.511719,3430.170410 
	C2016.931519,3424.881592 2015.867065,3417.899902 2015.993896,3412.000000 
	C2016.452515,3390.677979 2021.751587,3369.741211 2021.659912,3348.550781 
	C2021.550659,3323.285156 2026.155273,3298.519775 2027.304199,3273.425537 
	C2027.671509,3265.403564 2027.306763,3256.855225 2033.114990,3249.352539 
	C2034.566284,3249.175537 2035.160278,3249.268799 2036.443604,3249.948730 
	C2040.803223,3257.961914 2039.285278,3265.788086 2038.831909,3274.413086 
	C2038.548706,3278.832764 2037.485229,3282.229736 2037.122925,3286.626953 
	C2039.082886,3300.891846 2036.219604,3314.086182 2035.407837,3327.329834 
	C2033.984009,3350.558838 2032.022949,3373.756592 2031.023193,3397.908203 
	C2031.040649,3405.219727 2030.561035,3411.572510 2028.316895,3418.529785 
	C2024.113037,3425.086670 2024.057007,3431.241699 2026.417114,3438.422852 
	C2027.776489,3446.644287 2027.579102,3454.076660 2026.760620,3462.362305 
	C2025.516968,3467.049805 2025.691406,3471.062500 2025.046387,3475.819824 
	C2025.642822,3484.161621 2025.850952,3491.641602 2024.375732,3499.921387 
	C2021.776978,3504.532715 2020.022583,3508.953125 2013.916260,3509.579834 
	C2011.990723,3509.125977 2011.097656,3508.489990 2010.119385,3506.791992 
	C2009.862915,3505.117920 2009.889648,3504.227539 2009.933350,3502.669434 
	C2010.098022,3498.452637 2009.841064,3494.894531 2010.312500,3490.694580 
z"
        />
        <path
          fill="#DBD5C8"
          opacity="1.000000"
          stroke="none"
          d="
M2009.756836,3471.425293 
	C2010.277954,3474.817139 2010.260864,3477.588623 2010.099854,3481.180176 
	C2008.960083,3481.954346 2007.952759,3481.989258 2006.969971,3481.850586 
	C1999.774414,3480.835205 1997.594849,3483.358154 2000.364380,3490.156494 
	C2001.169922,3492.134033 2002.097412,3493.763672 2000.878540,3495.630615 
	C1996.642578,3502.119141 1994.337280,3509.217773 1994.717163,3516.937256 
	C1995.121948,3525.163086 1989.773682,3527.683594 1982.653687,3529.705078 
	C1973.861816,3519.807373 1974.868652,3507.046143 1972.284912,3495.302490 
	C1971.848999,3493.321289 1972.117065,3491.021484 1974.659180,3490.574951 
	C1977.099731,3490.145752 1977.981079,3492.273926 1978.891846,3494.060547 
	C1980.408569,3497.035400 1977.203979,3499.906250 1978.604858,3502.778076 
	C1979.716919,3505.058105 1979.275757,3508.479492 1982.367920,3509.365967 
	C1985.652100,3510.307617 1986.869751,3507.530029 1988.402466,3505.327881 
	C1994.383301,3496.734375 1992.600952,3488.506592 1983.004028,3482.690430 
	C1983.302490,3479.351807 1989.417236,3477.456055 1985.107056,3472.045898 
	C1982.292603,3468.513428 1987.710815,3468.310303 1991.241455,3467.996094 
	C1997.325684,3471.475586 2003.994019,3466.708984 2009.756836,3471.425293 
z"
        />
        <path
          fill="#A6A29C"
          opacity="1.000000"
          stroke="none"
          d="
M2008.794678,3563.491211 
	C2007.334473,3564.043945 2006.542725,3564.072510 2005.167969,3564.160156 
	C2007.489014,3552.765869 2004.307251,3540.696533 2008.334351,3528.494629 
	C2009.568115,3527.099365 2010.629028,3526.999512 2012.358154,3527.901367 
	C2013.889160,3529.951660 2014.156982,3531.597412 2014.030518,3534.154053 
	C2011.121216,3544.108398 2015.779907,3554.414062 2008.794678,3563.491211 
z"
        />
        <path
          fill="#817A79"
          opacity="1.000000"
          stroke="none"
          d="
M2013.114258,3527.844727 
	C2011.433594,3527.863525 2010.604492,3527.830811 2009.163208,3527.726074 
	C2008.614990,3523.165527 2008.678833,3518.676758 2008.843018,3513.351074 
	C2009.509399,3511.470947 2010.367432,3510.800537 2012.364136,3510.458496 
	C2021.574097,3515.858887 2017.193237,3521.851074 2013.114258,3527.844727 
z"
        />
        <path
          fill="#A6A29C"
          opacity="1.000000"
          stroke="none"
          d="
M2009.827393,3490.475830 
	C2012.925049,3493.571289 2012.379395,3497.265869 2010.481934,3501.502930 
	C2007.209839,3498.468506 2007.803589,3494.718018 2009.827393,3490.475830 
z"
        />
        <path
          fill="#DBD5C8"
          opacity="1.000000"
          stroke="none"
          d="
M2003.264404,3564.019043 
	C2003.997070,3565.492188 2004.007324,3566.991943 2003.929932,3569.237305 
	C2001.661255,3569.778076 1998.837402,3570.336670 1998.538696,3567.150391 
	C1998.296021,3564.561523 2000.531250,3564.230713 2003.264404,3564.019043 
z"
        />
        <path
          fill="#A6A29C"
          opacity="1.000000"
          stroke="none"
          d="
M2012.583008,3511.408936 
	C2011.477661,3512.104492 2010.741089,3512.185059 2009.473999,3512.389648 
	C2008.046387,3510.691162 2007.863037,3508.854004 2009.332275,3506.500488 
	C2010.429199,3506.414795 2011.022339,3506.821533 2011.927734,3507.675781 
	C2012.816162,3508.923096 2013.053467,3509.813477 2012.583008,3511.408936 
z"
        />
        <path
          fill="#A6A29C"
          opacity="1.000000"
          stroke="none"
          d="
M2003.630859,3584.402100 
	C2006.014404,3585.546387 2006.308594,3587.296875 2004.365723,3589.665527 
	C2002.138794,3588.484375 2001.810547,3586.743896 2003.630859,3584.402100 
z"
        />
        <path
          fill="#DBD5C8"
          opacity="1.000000"
          stroke="none"
          d="
M1982.325073,3546.458008 
	C1982.217163,3545.376953 1982.603882,3544.867188 1983.550049,3544.225830 
	C1984.608154,3544.944336 1985.243652,3545.898193 1985.918457,3547.450439 
	C1984.866089,3547.674561 1983.774414,3547.300293 1982.325073,3546.458008 
z"
        />
        <path
          fill="#646A6C"
          opacity="1.000000"
          stroke="none"
          d="
M2014.830811,3629.913574 
	C2015.620850,3634.003174 2015.763428,3638.121826 2015.988525,3643.119629 
	C2021.123047,3651.023682 2019.159180,3657.874512 2015.050293,3665.447754 
	C2014.543457,3666.210449 2013.854004,3666.188965 2013.530029,3666.069336 
	C2013.206055,3665.949951 2012.610107,3666.509766 2012.280273,3666.753174 
	C2010.041504,3655.981934 2008.429443,3644.932617 2008.086670,3632.855225 
	C2010.122559,3631.296875 2012.152954,3630.619873 2014.830811,3629.913574 
z"
        />
        <path
          fill="#817A79"
          opacity="1.000000"
          stroke="none"
          d="
M2008.662842,3564.642090 
	C2011.048950,3554.600098 2008.948486,3544.696045 2011.520264,3534.613037 
	C2021.589355,3535.485596 2015.715698,3544.197754 2019.211182,3549.253418 
	C2019.592773,3550.850342 2019.627197,3551.782227 2019.376953,3553.394043 
	C2015.529419,3558.419434 2017.506470,3562.462402 2020.130127,3567.184082 
	C2021.330811,3571.492676 2021.145874,3575.163330 2020.989990,3579.659180 
	C2021.520752,3585.625977 2021.653564,3590.748047 2019.898438,3596.601562 
	C2018.178223,3599.625488 2016.129639,3600.581055 2012.755127,3600.322021 
	C2008.944946,3597.025391 2010.240601,3593.225342 2010.026733,3589.733887 
	C2009.528076,3581.588135 2010.988159,3573.374512 2008.662842,3564.642090 
z"
        />
        <path
          fill="#646A6C"
          opacity="1.000000"
          stroke="none"
          d="
M2011.928833,3600.760254 
	C2013.244019,3599.385498 2014.567139,3598.753174 2016.432373,3598.032715 
	C2022.120483,3600.553711 2021.123169,3605.265869 2020.820435,3610.622559 
	C2020.222656,3613.502930 2019.344116,3615.141357 2016.624634,3616.080566 
	C2010.538086,3614.841064 2012.569580,3610.621582 2012.000488,3606.730469 
	C2011.954590,3604.472656 2011.945679,3602.988037 2011.928833,3600.760254 
z"
        />
        <path
          fill="#817A79"
          opacity="1.000000"
          stroke="none"
          d="
M2003.909302,3597.756836 
	C2004.342041,3598.380615 2004.874512,3599.077881 2005.361328,3599.805664 
	C2005.378540,3599.831787 2004.944458,3600.159424 2004.720703,3600.346191 
	C2004.486206,3599.801270 2004.251831,3599.256592 2003.893433,3598.248535 
	C2003.769409,3597.785156 2004.001343,3597.835205 2003.909302,3597.756836 
z"
        />
        <path
          fill="#040F1A"
          opacity="1.000000"
          stroke="none"
          d="
M2015.802979,3616.655273 
	C2016.213745,3614.988770 2016.748169,3613.999268 2017.435303,3612.452637 
	C2020.931641,3609.589600 2024.175049,3609.233643 2027.621338,3613.200684 
	C2026.973022,3620.022461 2020.841064,3617.093262 2017.210693,3619.890137 
	C2016.318970,3619.117920 2016.122803,3618.225098 2015.802979,3616.655273 
z"
        />
        <path
          fill="#EEE9DC"
          opacity="1.000000"
          stroke="none"
          d="
M1577.994629,3943.323730 
	C1576.709961,3943.788818 1575.250610,3943.686523 1575.044312,3942.069824 
	C1574.901245,3940.949219 1575.874878,3940.451904 1577.436279,3940.081299 
	C1578.011108,3940.879150 1578.007446,3941.768311 1577.994629,3943.323730 
z"
        />
        <path
          fill="#172837"
          opacity="1.000000"
          stroke="none"
          d="
M492.799652,2968.674316 
	C491.149628,2968.019287 490.266296,2967.989502 488.720032,2967.955566 
	C477.825928,2964.316895 467.540894,2962.746582 456.543518,2967.566895 
	C455.986877,2967.990234 455.001404,2967.983398 454.508667,2967.986816 
	C451.073853,2964.824707 448.978638,2967.153564 446.361908,2969.502441 
	C435.662598,2970.683838 425.308044,2968.693115 415.132507,2971.251953 
	C413.952881,2971.548828 412.464172,2971.391357 411.319672,2970.937744 
	C393.362457,2963.819580 374.726837,2960.251221 355.430206,2959.964355 
	C352.790436,2959.925049 349.424072,2960.549805 347.636108,2959.210205 
	C335.837616,2950.368164 323.666351,2957.151855 310.850403,2957.914062 
	C299.927185,2960.023682 289.810455,2961.417725 279.724945,2957.968994 
	C275.333527,2956.466797 270.891815,2954.947510 269.985962,2948.670166 
	C271.325531,2944.329102 274.334869,2943.777588 278.378540,2943.857422 
	C287.170837,2947.111328 295.404968,2949.453369 304.400269,2949.531250 
	C303.519409,2946.990723 300.250549,2945.911133 301.307678,2942.212402 
	C302.632568,2940.486572 303.935394,2939.860352 306.041199,2939.193359 
	C308.804596,2938.641846 310.352814,2937.263428 312.860321,2936.042480 
	C321.462189,2933.916260 329.362915,2932.305420 338.274841,2933.026855 
	C346.969086,2932.656250 354.105042,2932.712402 361.733673,2927.139648 
	C369.289062,2921.620361 380.555695,2924.906250 390.814575,2925.757324 
	C388.072479,2924.907715 386.983429,2923.497803 387.670227,2920.674561 
	C398.921204,2920.985107 409.407379,2917.322510 421.080170,2918.028564 
	C425.059113,2923.068115 420.385437,2924.020752 419.233490,2925.251465 
	C437.231567,2929.963867 451.480621,2914.803467 469.830750,2911.718018 
	C474.234497,2910.583008 477.889099,2909.561279 482.545135,2909.171143 
	C499.904297,2908.388672 516.343018,2905.013184 532.926270,2908.099609 
	C536.173706,2908.703369 539.554077,2908.718750 543.141541,2911.219238 
	C545.575989,2912.124756 547.221436,2912.585205 548.074097,2915.115479 
	C544.788940,2920.074463 539.830811,2918.964844 535.320801,2919.177734 
	C526.700073,2919.584717 518.013489,2918.509033 509.453125,2920.053467 
	C507.000397,2920.496338 503.640717,2921.029785 504.004272,2924.623291 
	C504.350006,2928.040771 506.186432,2932.120117 509.895233,2931.481689 
	C522.515137,2929.311035 535.312439,2934.114014 548.199707,2926.039551 
	C560.518127,2918.322266 575.975708,2922.527832 590.027832,2925.461182 
	C593.274780,2926.139404 596.494385,2927.212646 598.125854,2931.264648 
	C594.191040,2935.777344 588.265930,2930.562744 584.400024,2935.399414 
	C583.034851,2937.107178 578.367981,2936.520508 579.395630,2940.110107 
	C580.313599,2943.316650 583.981689,2941.824951 586.446777,2941.941162 
	C591.758301,2942.191162 597.087646,2942.039795 602.405579,2942.193604 
	C604.489319,2942.253662 606.713684,2943.127686 606.761963,2945.395020 
	C606.821045,2948.166748 604.181519,2948.015381 602.289551,2947.944824 
	C594.738831,2947.662842 587.128723,2947.688721 579.673462,2946.648438 
	C575.642700,2946.086182 572.629700,2946.655029 569.526245,2949.022217 
	C565.592957,2952.021729 560.936218,2952.365479 556.197205,2951.894775 
	C554.922791,2951.768066 553.453674,2951.637451 553.157898,2949.938721 
	C552.886902,2948.382324 553.912537,2946.926025 555.065857,2946.924316 
	C562.004639,2946.914062 565.645447,2941.258789 571.563110,2938.062988 
	C568.860657,2935.531738 566.441284,2936.078125 563.986267,2937.022461 
	C551.753723,2941.727051 539.071533,2940.733887 526.637939,2938.934082 
	C520.984924,2938.115723 519.484314,2940.339355 518.084351,2945.228027 
	C517.389587,2946.677490 516.622375,2947.090576 515.035950,2947.195068 
	C511.940613,2945.988281 509.463715,2945.311768 506.109985,2946.468018 
	C487.181091,2947.298340 468.928070,2948.795410 450.887146,2944.286621 
	C443.845154,2942.526855 437.678864,2944.166016 431.065826,2947.918701 
	C418.038513,2955.312256 403.434692,2956.368896 388.669678,2954.554688 
	C387.012360,2954.351318 384.906067,2953.895752 384.268677,2952.501709 
	C379.566223,2942.216797 371.197449,2944.711426 363.057068,2946.069092 
	C361.431702,2946.340088 359.744263,2945.922852 358.483887,2945.997559 
	C361.564453,2946.572021 364.999603,2945.951172 368.463715,2945.695312 
	C371.439117,2945.475342 375.290100,2945.079346 377.012665,2947.410156 
	C387.073151,2961.022217 401.533386,2958.099121 414.936890,2957.375244 
	C442.086456,2955.909668 469.044647,2950.926270 497.176086,2953.192871 
	C502.066559,2954.705566 506.273163,2955.416992 511.458557,2955.010986 
	C517.348755,2955.427979 522.363770,2956.069092 527.410278,2959.409668 
	C529.219421,2962.282227 530.196350,2964.551758 528.219727,2967.582764 
	C516.692505,2973.964355 505.164124,2972.683350 492.799652,2968.674316 
z"
        />
        <path
          fill="#263B4D"
          opacity="1.000000"
          stroke="none"
          d="
M387.262512,2920.124023 
	C388.406189,2922.448730 389.678864,2923.610352 391.972595,2923.927734 
	C394.825348,2924.322510 397.963684,2923.145508 400.511780,2925.350830 
	C400.379883,2928.499512 397.933105,2927.896973 396.174408,2927.920410 
	C387.912170,2928.031250 379.647858,2927.998047 371.384308,2928.003906 
	C361.627716,2928.010498 361.627716,2928.004883 358.169434,2937.367920 
	C352.840485,2935.441406 347.209290,2936.159912 340.741882,2935.890137 
	C329.580078,2929.149170 319.324463,2934.901123 309.410431,2936.626709 
	C299.019775,2938.435059 289.261932,2937.779541 278.720947,2934.541992 
	C277.541931,2933.616699 277.000519,2933.214111 276.212128,2932.361328 
	C276.392365,2929.646240 277.404785,2928.545410 280.033691,2928.976074 
	C293.293365,2931.148682 306.090393,2929.849121 319.038910,2925.708740 
	C327.349213,2923.051270 336.534180,2923.049316 345.351868,2922.082520 
	C353.127838,2921.229736 360.845642,2919.882324 368.767426,2921.872314 
	C374.665619,2923.354248 380.358521,2919.360352 387.262512,2920.124023 
z"
        />
        <path
          fill="#263B4D"
          opacity="1.000000"
          stroke="none"
          d="
M470.203735,2913.321533 
	C460.050232,2916.407471 451.069611,2920.998779 442.467041,2926.385498 
	C440.047058,2927.901123 437.468506,2927.917969 434.811218,2927.979980 
	C428.209534,2928.134033 421.536011,2927.337646 415.101654,2929.727783 
	C413.951752,2930.155029 412.479034,2929.807373 411.168243,2929.682129 
	C409.658997,2929.537842 408.690613,2928.500732 408.635315,2927.103516 
	C408.580353,2925.714600 409.448364,2924.691895 410.945862,2924.331543 
	C414.841888,2923.393555 420.055634,2925.395996 421.869812,2918.778809 
	C421.975464,2918.029297 422.915283,2917.944336 423.384949,2917.919922 
	C428.547699,2918.325439 432.554962,2917.875000 431.996399,2910.762695 
	C437.915619,2904.449463 445.142365,2907.863037 452.537659,2907.185547 
	C458.306549,2911.357910 466.484772,2905.152588 470.203735,2913.321533 
z"
        />
        <path
          fill="#122230"
          opacity="1.000000"
          stroke="none"
          d="
M344.483521,2991.266113 
	C341.242004,2992.339600 338.017456,2991.161865 335.991028,2995.125488 
	C334.321106,2995.826172 332.883789,2995.860352 330.724426,2995.904785 
	C326.336853,2997.231689 322.232086,2996.960693 318.219330,2999.365234 
	C310.900360,3000.587158 304.492096,2997.238281 296.881348,2998.047852 
	C293.178223,2997.395752 290.328033,2996.718994 286.740753,2996.020996 
	C285.246490,2995.228271 284.941772,2994.307129 285.499084,2992.593750 
	C293.115814,2989.247314 300.592224,2988.078125 309.125427,2988.002930 
	C315.244232,2988.437012 320.219879,2987.386475 325.482727,2984.650879 
	C331.482544,2982.724854 336.928558,2986.789062 343.154419,2984.192383 
	C345.668640,2986.010254 346.087006,2988.150146 344.483521,2991.266113 
z"
        />
        <path
          fill="#2B3E51"
          opacity="1.000000"
          stroke="none"
          d="
M318.512817,2999.800293 
	C320.023438,2994.720459 323.841919,2993.218018 329.509460,2995.432617 
	C328.379120,3002.390137 323.137726,2999.183350 318.512817,2999.800293 
z"
        />
        <path
          fill="#3C5163"
          opacity="1.000000"
          stroke="none"
          d="
M277.903381,2934.753418 
	C285.269958,2934.436279 292.561188,2933.002441 299.641998,2935.444092 
	C302.344788,2936.375977 304.751984,2934.587158 307.256836,2933.933838 
	C315.846039,2931.693604 324.592743,2930.554443 333.429138,2930.079590 
	C336.452972,2929.916992 339.587799,2930.000000 339.855225,2935.050049 
	C331.061096,2935.373535 322.439026,2936.822510 312.952759,2938.220459 
	C310.868256,2939.142822 309.795135,2940.193604 308.147583,2941.416016 
	C306.207977,2941.708496 304.759521,2941.728516 302.586670,2941.747559 
	C295.037689,2943.119141 288.568665,2946.186523 280.718201,2946.049316 
	C277.055054,2946.095215 274.069519,2945.937256 270.639648,2947.646484 
	C259.674561,2948.348633 249.484070,2947.874756 238.637024,2944.579590 
	C242.830902,2940.798584 247.539597,2940.350342 252.197510,2940.101318 
	C256.839478,2939.853027 261.512970,2940.178223 266.152649,2939.908203 
	C270.405823,2939.660889 274.774353,2939.322510 277.903381,2934.753418 
z"
        />
        <path
          fill="#233444"
          opacity="1.000000"
          stroke="none"
          d="
M285.234863,2991.958740 
	C285.937073,2992.851074 285.965363,2993.750977 285.998657,2995.325684 
	C285.380402,2995.989502 284.750946,2996.031494 284.135101,2995.958496 
	C282.320068,2995.743652 279.655762,2995.774170 279.907623,2993.466309 
	C280.161041,2991.144287 282.901978,2992.305176 285.234863,2991.958740 
z"
        />
        <path
          fill="#597288"
          opacity="1.000000"
          stroke="none"
          d="
M2442.562988,2598.711426 
	C2422.138916,2597.427734 2402.658691,2601.460693 2383.000732,2599.977295 
	C2378.042969,2599.603516 2373.125977,2599.739502 2368.149902,2598.624512 
	C2362.671631,2597.396729 2356.993896,2597.870605 2351.331787,2599.108887 
	C2344.313721,2600.643311 2337.303711,2603.059082 2329.837402,2600.278076 
	C2327.365967,2599.357422 2324.023193,2599.352295 2319.856689,2602.409424 
	C2334.862793,2605.690430 2349.087891,2605.301514 2363.102051,2609.856445 
	C2365.059082,2608.920654 2366.127930,2607.866455 2367.632324,2606.455078 
	C2372.828369,2606.094971 2377.612305,2606.117676 2383.133789,2606.162842 
	C2385.210693,2614.897461 2376.648438,2612.279297 2372.414551,2615.821777 
	C2361.068359,2619.454590 2350.404541,2614.418945 2338.980957,2616.625732 
	C2334.128662,2617.291992 2330.123291,2617.348877 2325.281738,2616.598633 
	C2316.483887,2613.593018 2308.373779,2612.647217 2299.986084,2614.261475 
	C2295.700195,2615.086182 2291.397217,2614.914307 2286.430176,2613.311035 
	C2279.822754,2613.252686 2274.130127,2614.449951 2267.628906,2614.968262 
	C2254.622559,2613.980469 2243.425293,2619.375000 2232.233887,2620.984375 
	C2233.755371,2621.174316 2235.991943,2621.012695 2238.133057,2621.660156 
	C2240.840820,2622.478516 2243.149902,2623.640869 2242.565430,2627.753418 
	C2238.509766,2631.076416 2234.136230,2630.989258 2229.959473,2630.727051 
	C2218.227539,2629.989746 2206.517334,2629.435791 2193.954590,2630.633301 
	C2189.252930,2630.033936 2185.320312,2629.678223 2181.613037,2628.508057 
	C2177.403320,2627.179199 2173.380615,2625.309082 2176.757324,2618.988281 
	C2182.789307,2615.729980 2188.312744,2618.797852 2194.492676,2618.015625 
	C2197.486572,2617.731689 2199.721436,2619.021240 2201.955078,2618.033203 
	C2200.635498,2618.604736 2199.297852,2618.249023 2197.224121,2618.060059 
	C2191.815430,2617.891357 2187.072998,2618.452637 2181.860107,2616.218506 
	C2173.971436,2610.260010 2165.409424,2610.924072 2157.241211,2611.296143 
	C2151.596924,2611.552979 2146.054688,2611.171387 2139.633301,2611.048584 
	C2121.034912,2610.205811 2103.258545,2615.748291 2084.694336,2610.992676 
	C2077.125488,2611.805176 2069.636475,2609.074463 2063.218506,2615.451172 
	C2059.946045,2616.802734 2057.265869,2616.990723 2054.648682,2616.472656 
	C2038.986816,2613.372070 2023.713867,2617.214111 2007.481323,2618.951172 
	C2001.708984,2619.684814 1996.777466,2619.130371 1991.063232,2618.925049 
	C1987.793823,2618.766113 1985.429810,2617.688965 1982.172607,2617.960938 
	C1978.579834,2618.093994 1975.887573,2616.944580 1972.372925,2616.678223 
	C1966.187012,2614.009033 1960.927979,2609.912354 1953.703125,2613.982910 
	C1948.943115,2615.230469 1945.192749,2613.339355 1940.543945,2612.852051 
	C1934.035400,2612.202881 1928.381348,2609.814209 1921.745850,2611.829834 
	C1914.957031,2612.224854 1908.877197,2612.283691 1902.103027,2612.292236 
	C1899.097656,2612.637695 1896.781250,2612.636230 1893.787354,2612.424072 
	C1888.970459,2611.465576 1886.594971,2614.024414 1883.734375,2617.220947 
	C1880.719849,2618.670410 1878.201050,2618.689209 1874.871094,2618.339844 
	C1871.559326,2617.422852 1869.010620,2616.730713 1865.492798,2617.004395 
	C1859.680786,2616.845215 1854.624268,2617.615967 1848.977539,2615.714355 
	C1842.632812,2606.421143 1833.061401,2610.553955 1824.617065,2608.129883 
	C1822.264771,2606.973145 1821.225830,2605.718262 1821.116089,2603.018555 
	C1822.426025,2599.910645 1824.515869,2598.846924 1827.575439,2597.706055 
	C1833.063232,2596.907471 1837.713379,2597.147461 1843.216919,2597.252197 
	C1871.152710,2601.434570 1898.162476,2603.933838 1925.328247,2597.301758 
	C1933.009644,2595.426270 1941.166626,2596.203125 1948.842285,2598.987061 
	C1953.010498,2600.499023 1957.278198,2600.450195 1962.416748,2599.822021 
	C1967.804932,2598.767334 1972.094849,2600.123291 1977.203003,2601.476074 
	C1982.299561,2603.994141 1986.749390,2605.692627 1992.589478,2604.978027 
	C2004.644165,2603.967041 2015.898926,2605.036133 2027.918335,2604.253418 
	C2033.017212,2602.698730 2037.301392,2601.361084 2042.316650,2599.483643 
	C2056.615723,2596.990967 2070.189453,2600.429199 2084.513672,2598.199219 
	C2086.556396,2597.700928 2087.750244,2597.594482 2089.852783,2597.708496 
	C2112.657227,2600.102051 2134.491943,2594.901855 2157.210938,2597.162598 
	C2171.576904,2597.171143 2184.721191,2597.387451 2198.316895,2601.249756 
	C2209.248779,2604.354736 2222.000000,2603.066895 2233.962646,2602.689941 
	C2235.936523,2602.627930 2238.520264,2602.629150 2238.763916,2600.168213 
	C2239.556885,2592.162109 2245.302246,2594.142822 2250.018799,2594.417725 
	C2257.601318,2594.860107 2265.124023,2594.990723 2272.531738,2592.881592 
	C2256.904785,2595.471680 2241.375977,2590.229248 2224.983887,2592.493164 
	C2220.889648,2592.796875 2217.633301,2592.605957 2213.824707,2590.768555 
	C2204.524170,2586.283203 2195.502930,2584.505615 2185.169434,2585.107422 
	C2162.079834,2587.203369 2140.024658,2582.505127 2116.964844,2583.306885 
	C2110.725098,2583.202637 2104.885254,2581.357910 2100.107910,2587.334717 
	C2094.881348,2589.537842 2090.347412,2589.565918 2085.018311,2587.714844 
	C2079.751221,2580.934570 2072.625000,2577.515381 2065.779297,2576.744873 
	C2050.764160,2575.054932 2035.434448,2571.554199 2020.443359,2577.096924 
	C2015.998901,2578.739990 2011.230225,2578.410645 2006.068604,2580.339355 
	C1995.743896,2580.461670 1986.468872,2584.311279 1976.102051,2582.120605 
	C1970.126099,2577.281006 1972.321289,2573.975098 1977.361206,2571.520508 
	C1981.726807,2569.394287 1986.618042,2569.285645 1991.905396,2568.708008 
	C1988.545898,2567.831055 1985.735840,2567.098633 1983.672119,2563.826660 
	C1983.982422,2560.698730 1985.746704,2559.401855 1988.544189,2557.942871 
	C2006.962158,2556.105225 2024.591919,2557.392090 2043.064331,2557.028564 
	C2053.581055,2562.763428 2063.202881,2555.014404 2073.695312,2557.077148 
	C2075.704102,2557.054932 2076.863037,2557.023193 2078.915039,2556.841797 
	C2126.992920,2553.102051 2174.199219,2553.190674 2221.406250,2556.095215 
	C2228.875488,2556.554688 2235.902100,2554.581055 2243.131592,2553.624023 
	C2252.104980,2552.436279 2255.076660,2555.561523 2253.893799,2565.231934 
	C2249.452637,2569.630371 2244.635254,2569.207520 2239.029785,2568.851562 
	C2234.280029,2567.735840 2230.319092,2565.935547 2225.875000,2569.604980 
	C2219.351318,2571.764404 2213.586670,2571.134033 2207.111328,2569.367188 
	C2195.326172,2563.973877 2184.075195,2567.192383 2172.116699,2568.457031 
	C2169.812500,2568.739502 2168.256104,2568.784424 2165.902100,2568.918701 
	C2152.549316,2569.340576 2140.041992,2569.099609 2126.933350,2566.092041 
	C2121.867188,2565.966797 2117.607910,2566.007080 2112.581543,2566.010742 
	C2107.322754,2565.662109 2103.041016,2566.557861 2098.164551,2568.361572 
	C2094.082520,2569.138916 2090.683105,2568.934326 2086.700928,2568.952148 
	C2085.538574,2568.928955 2085.253662,2568.923096 2085.253662,2568.923096 
	C2084.268311,2568.877197 2084.211426,2568.930908 2085.632812,2569.069824 
	C2089.319092,2569.032959 2092.458252,2568.916748 2096.273438,2569.447754 
	C2103.223389,2574.154541 2107.952637,2570.806396 2113.247559,2566.377686 
	C2118.342529,2565.908447 2122.700684,2565.936035 2127.900391,2566.355957 
	C2142.868652,2573.733643 2158.147461,2569.605469 2172.870605,2570.762939 
	C2178.811768,2571.229980 2184.791748,2570.979004 2191.450439,2572.729492 
	C2215.224854,2576.852539 2238.062256,2572.653809 2260.943604,2571.987793 
	C2262.931152,2571.929932 2264.911865,2571.387451 2266.908936,2571.563721 
	C2271.349121,2571.955566 2276.608154,2572.664551 2276.434082,2578.108154 
	C2276.273926,2583.125488 2271.000977,2581.707275 2267.574219,2581.975342 
	C2265.241211,2582.157715 2262.964355,2582.587158 2260.675537,2582.732666 
	C2297.713379,2580.139404 2334.751465,2581.775391 2372.545166,2583.201660 
	C2375.062256,2582.684570 2377.883789,2585.781494 2378.642090,2581.788086 
	C2380.601807,2571.468994 2386.796631,2572.920166 2394.268555,2576.721680 
	C2404.550781,2589.321045 2420.607666,2591.108154 2435.787109,2581.032227 
	C2436.339844,2579.498535 2436.731201,2578.641846 2437.700195,2577.272461 
	C2441.233887,2574.586670 2444.559326,2573.652588 2448.977783,2574.563965 
	C2452.696289,2577.292236 2455.260498,2580.233154 2458.479492,2583.486084 
	C2460.201416,2588.550049 2455.938232,2589.231201 2453.857910,2591.299805 
	C2460.118164,2593.740967 2467.111328,2591.677734 2473.546875,2595.498779 
	C2473.460205,2597.025879 2472.949463,2598.008545 2472.219238,2599.500488 
	C2471.117676,2600.015137 2470.235107,2600.020264 2468.690918,2600.022461 
	C2459.567627,2606.833008 2451.511963,2601.588379 2442.562988,2598.711426 
z"
        />
        <path
          fill="#49647C"
          opacity="1.000000"
          stroke="none"
          d="
M2395.524414,2578.281494 
	C2392.431396,2577.573486 2390.870117,2577.072754 2389.271973,2576.754395 
	C2382.133789,2575.331543 2381.745605,2576.122559 2383.102539,2583.531738 
	C2384.080322,2588.871094 2380.416016,2588.259277 2376.486816,2586.579102 
	C2367.591797,2580.950684 2358.000732,2582.432861 2348.913330,2581.584717 
	C2341.013428,2580.847168 2332.993896,2580.772949 2325.015381,2580.980957 
	C2321.671143,2581.067871 2318.329834,2580.923828 2315.333984,2579.202148 
	C2312.978027,2577.847900 2310.553467,2576.209717 2311.581055,2573.020020 
	C2312.433838,2570.370850 2314.969482,2569.931641 2317.376465,2570.400391 
	C2322.658447,2571.429199 2327.991211,2570.708496 2333.278809,2571.171143 
	C2321.833496,2570.275635 2310.234863,2571.385010 2298.950684,2568.297852 
	C2294.928467,2567.197510 2291.147217,2569.264893 2287.253662,2569.750488 
	C2275.603271,2571.203125 2263.733887,2574.180908 2252.277344,2566.716797 
	C2253.798096,2556.699463 2251.366943,2553.982422 2242.589600,2556.953369 
	C2232.808350,2560.264648 2223.267578,2561.062012 2213.281982,2558.378906 
	C2210.470947,2557.623779 2207.332275,2558.004883 2204.343994,2558.003418 
	C2172.082520,2557.986816 2139.820801,2558.104980 2107.560059,2557.933350 
	C2098.240234,2557.883789 2089.225342,2561.269043 2078.944336,2559.962402 
	C2077.065186,2559.083252 2076.513428,2557.607422 2075.564209,2557.068359 
	C2074.863770,2556.670166 2075.356201,2558.515381 2074.387207,2559.575195 
	C2067.645020,2560.463623 2061.125488,2558.704590 2055.123047,2562.880127 
	C2052.771973,2564.516113 2049.609375,2562.093262 2046.459229,2560.552734 
	C2046.058716,2553.424072 2053.153076,2557.220703 2055.335205,2553.144531 
	C2042.390991,2548.965332 2029.190674,2551.857422 2016.135010,2551.024414 
	C2011.518188,2550.729492 2006.845703,2551.399902 2002.265137,2550.434814 
	C1999.926758,2549.942383 1997.638794,2548.969238 1997.359253,2546.235107 
	C1997.040039,2543.113770 1999.116455,2541.386719 2001.778687,2540.429443 
	C2005.258667,2539.178223 2008.987427,2539.249268 2012.579224,2539.176758 
	C2030.459961,2538.816895 2048.346191,2539.123535 2066.229980,2539.044922 
	C2070.623535,2539.025635 2074.662598,2540.667725 2078.869385,2541.244629 
	C2081.781250,2541.644287 2078.467285,2539.581787 2079.182617,2537.914307 
	C2087.854248,2529.071533 2098.633545,2531.050049 2107.341309,2534.240723 
	C2122.040771,2539.627441 2137.220215,2540.745361 2152.213135,2541.963623 
	C2173.653564,2543.705566 2195.173584,2547.289551 2216.649414,2545.913086 
	C2234.499512,2544.769287 2251.560791,2545.255615 2268.469727,2551.582764 
	C2274.298584,2553.763916 2281.008789,2552.584473 2287.322266,2552.411133 
	C2310.671143,2551.770264 2333.703369,2553.753418 2356.522461,2559.101074 
	C2372.499756,2562.845703 2388.994629,2561.111328 2405.213623,2562.596191 
	C2406.546875,2561.758057 2404.402832,2560.726074 2405.196533,2561.797363 
	C2405.604736,2562.348633 2405.457520,2562.685303 2404.754639,2562.686523 
	C2392.845947,2562.704346 2381.013428,2561.269043 2369.130371,2560.738281 
	C2364.035645,2560.510742 2360.299561,2557.813477 2360.642578,2551.040527 
	C2364.951660,2544.449463 2370.960449,2543.136230 2377.447021,2543.032715 
	C2385.056885,2542.911377 2392.500732,2542.970947 2398.624268,2536.924561 
	C2401.425781,2534.158447 2406.165039,2533.651123 2409.881348,2533.908691 
	C2421.368896,2534.705078 2433.076172,2533.288818 2445.120605,2537.037354 
	C2447.187988,2537.625732 2448.906250,2537.946045 2449.291992,2539.105957 
	C2452.662354,2549.247070 2459.721191,2548.040527 2467.286133,2545.048584 
	C2470.100830,2543.935547 2472.911621,2543.171631 2475.933594,2543.155029 
	C2496.396240,2543.041992 2516.880127,2542.342285 2537.225342,2545.418213 
	C2538.570068,2545.621582 2539.731445,2546.203613 2541.389160,2547.511719 
	C2542.601074,2548.244385 2543.117920,2548.621094 2543.780273,2549.555420 
	C2543.502197,2553.356689 2541.135010,2554.014648 2538.210938,2553.991699 
	C2533.229004,2553.952637 2528.246582,2554.032715 2523.264648,2553.988281 
	C2518.918701,2553.949463 2514.559326,2553.628418 2511.670654,2557.964111 
	C2510.438721,2559.813721 2508.309082,2559.983887 2506.301758,2559.947021 
	C2500.670410,2559.843506 2494.976562,2560.102051 2489.433838,2559.320312 
	C2485.838623,2558.812988 2482.530518,2558.680420 2477.593750,2559.292969 
	C2482.262939,2563.031006 2486.197754,2563.979980 2490.505859,2564.039062 
	C2492.458008,2564.065674 2495.419189,2564.105225 2495.023438,2566.595947 
	C2494.017578,2572.925537 2498.250000,2571.545166 2501.718506,2572.035400 
	C2520.180420,2574.645264 2538.754883,2573.672363 2557.289062,2574.119141 
	C2564.452148,2574.291748 2571.489014,2577.069580 2578.828613,2574.917969 
	C2580.209229,2574.513184 2581.915527,2575.888916 2583.042969,2578.030518 
	C2577.064697,2583.273193 2570.465576,2577.027588 2564.553467,2578.874023 
	C2558.336182,2580.815918 2552.016113,2579.605713 2544.843994,2580.041748 
	C2537.413086,2585.024170 2529.354004,2585.177002 2522.104736,2584.864258 
	C2498.433838,2583.843750 2474.939453,2580.519287 2450.764648,2576.574219 
	C2446.326660,2575.491699 2443.150879,2577.912354 2438.777832,2577.967041 
	C2434.797363,2581.040527 2431.138184,2580.342285 2426.656006,2579.156738 
	C2416.255859,2579.523193 2406.704346,2575.702637 2395.524414,2578.281494 
z"
        />
        <path
          fill="#49647C"
          opacity="1.000000"
          stroke="none"
          d="
M2474.842041,2596.030273 
	C2464.312256,2596.582031 2454.875244,2595.174072 2445.511719,2592.985107 
	C2444.949219,2589.417969 2447.853271,2589.298828 2449.780762,2588.466553 
	C2452.366211,2587.349854 2456.008545,2589.083740 2457.910156,2584.823730 
	C2458.885254,2583.325928 2459.833252,2582.891357 2461.739746,2582.913574 
	C2467.908447,2586.156982 2473.818604,2584.820312 2480.357178,2584.993652 
	C2497.610596,2583.546143 2513.764893,2586.432861 2530.792969,2588.298828 
	C2533.618652,2589.020508 2535.685059,2589.114258 2538.533203,2588.834717 
	C2539.788574,2588.864746 2540.285645,2588.964844 2541.386475,2589.527588 
	C2551.458252,2590.409912 2561.018311,2589.117432 2570.431396,2591.837402 
	C2538.989502,2596.481201 2507.580322,2601.062744 2474.842041,2596.030273 
z"
        />
        <path
          fill="#607B93"
          opacity="1.000000"
          stroke="none"
          d="
M2542.292236,2589.321289 
	C2541.990234,2589.990234 2540.995117,2589.987549 2540.497559,2589.988770 
	C2538.243164,2590.032959 2536.460693,2589.954834 2534.446533,2591.519043 
	C2516.632080,2590.157715 2499.377930,2587.386719 2480.937988,2587.987305 
	C2474.843262,2584.464111 2468.776123,2585.669189 2462.518555,2584.431885 
	C2461.073730,2584.100342 2460.223389,2584.095215 2458.736816,2584.103516 
	C2454.341797,2583.215820 2450.318604,2582.635010 2450.047852,2576.773926 
	C2453.237305,2575.662354 2456.195801,2574.867920 2459.696533,2575.319824 
	C2475.031738,2577.299561 2490.682129,2577.422119 2505.790771,2580.392822 
	C2518.223389,2582.837158 2530.054688,2579.612793 2543.041748,2580.052490 
	C2544.020264,2583.006836 2544.223877,2585.972168 2542.292236,2589.321289 
z"
        />
        <path
          fill="#3E5871"
          opacity="1.000000"
          stroke="none"
          d="
M2674.389648,2562.392578 
	C2678.040039,2561.808105 2682.104248,2561.831543 2687.033203,2561.839355 
	C2700.253906,2565.615234 2713.229248,2565.592773 2725.827393,2567.926514 
	C2729.083984,2568.529541 2732.441162,2568.368164 2734.678955,2571.346191 
	C2734.444580,2574.393799 2732.074219,2573.855225 2730.258789,2573.933838 
	C2724.668457,2574.176514 2719.023682,2573.593262 2713.578369,2575.599121 
	C2712.436279,2576.019775 2710.785889,2575.996582 2709.697510,2575.483398 
	C2698.466797,2570.184082 2685.773926,2568.991211 2674.389648,2562.392578 
z"
        />
        <path
          fill="#3E5871"
          opacity="1.000000"
          stroke="none"
          d="
M2515.751953,2564.019043 
	C2525.821289,2563.996338 2535.076904,2563.953613 2544.332275,2563.984131 
	C2546.635010,2563.991699 2548.961670,2564.077148 2551.230225,2564.430176 
	C2552.613037,2564.645508 2553.549805,2565.853271 2553.200195,2567.311279 
	C2553.000977,2568.141602 2552.235596,2569.159912 2551.476074,2569.457275 
	C2550.288818,2569.922119 2548.891357,2569.975586 2547.582275,2569.980469 
	C2538.326904,2570.015137 2529.071533,2569.986816 2519.816162,2569.992432 
	C2516.721191,2569.994141 2513.584961,2569.614014 2512.166016,2566.526367 
	C2510.999268,2563.987061 2513.473145,2564.297363 2515.751953,2564.019043 
z"
        />
        <path
          fill="#627A90"
          opacity="1.000000"
          stroke="none"
          d="
M2754.231445,2566.927246 
	C2764.203125,2564.975830 2773.058838,2569.849365 2783.266602,2571.878418 
	C2781.439209,2574.923584 2777.913574,2574.134521 2774.774414,2573.883789 
	C2769.241943,2573.441895 2763.739258,2572.533691 2758.255859,2571.628662 
	C2756.179932,2571.285645 2754.836670,2569.777832 2754.231445,2566.927246 
z"
        />
        <path
          fill="#294159"
          opacity="1.000000"
          stroke="none"
          d="
M2680.821045,2664.040527 
	C2671.169678,2663.311035 2662.633545,2666.911377 2652.887207,2665.827637 
	C2649.775879,2666.012939 2647.554199,2666.011230 2644.665527,2665.906494 
	C2643.153809,2665.845215 2642.308350,2665.818115 2640.829102,2665.760254 
	C2635.296631,2663.100586 2629.514160,2662.309570 2625.488281,2657.769531 
	C2623.256836,2655.253174 2619.983398,2656.831787 2617.017334,2657.318359 
	C2596.893555,2660.620117 2576.785400,2660.703857 2556.764648,2656.413086 
	C2554.804688,2655.992920 2553.015137,2655.081299 2550.761230,2653.320312 
	C2549.496338,2651.650879 2549.541748,2650.604736 2550.931641,2649.035400 
	C2554.653076,2646.726318 2558.039062,2647.066895 2562.161133,2647.030762 
	C2568.502930,2647.629150 2572.294434,2643.606445 2577.675049,2641.562988 
	C2590.261719,2642.541016 2602.054688,2644.079346 2614.795410,2643.165527 
	C2616.730957,2643.180420 2617.836182,2643.257080 2619.752686,2643.578613 
	C2628.770752,2645.837891 2636.988525,2645.674072 2646.060059,2643.719727 
	C2661.424805,2646.099609 2676.246094,2646.149902 2691.388184,2649.329590 
	C2700.181641,2652.406738 2708.361328,2655.525635 2716.859619,2655.735107 
	C2724.924316,2655.934082 2732.727539,2657.459961 2740.523438,2658.794434 
	C2745.850830,2659.706299 2751.050293,2659.982666 2757.269775,2659.850586 
	C2759.033936,2659.739990 2759.887451,2659.731201 2761.381836,2659.686035 
	C2765.092285,2660.279541 2768.862549,2659.953369 2768.486328,2665.385742 
	C2747.369873,2665.940918 2726.768311,2662.867432 2705.111328,2664.273438 
	C2696.635986,2667.384277 2689.159668,2663.840332 2680.821045,2664.040527 
z"
        />
        <path
          fill="#597288"
          opacity="1.000000"
          stroke="none"
          d="
M2620.812256,2645.939941 
	C2619.135254,2645.901367 2618.270508,2645.888672 2616.757324,2645.867188 
	C2603.924072,2641.436768 2591.161621,2643.503662 2577.844238,2642.731445 
	C2569.800293,2645.795654 2562.493408,2643.303955 2555.412842,2642.638428 
	C2540.636475,2641.250000 2525.762207,2639.964355 2510.647949,2635.460693 
	C2509.254395,2633.848145 2508.979736,2632.713867 2509.576904,2630.684570 
	C2515.496582,2629.885254 2521.040771,2630.511475 2527.115479,2628.275879 
	C2529.350098,2629.724365 2531.528809,2629.929199 2533.618164,2631.514648 
	C2535.533447,2631.918213 2536.995850,2631.918701 2539.189941,2631.913574 
	C2540.746094,2631.198975 2541.696045,2630.924072 2543.424561,2631.497803 
	C2550.676758,2632.260254 2557.371826,2631.162354 2563.846191,2633.472656 
	C2566.112305,2634.281006 2568.385254,2633.285400 2570.674316,2632.830322 
	C2578.566650,2631.260742 2586.618164,2631.333496 2594.441162,2632.700195 
	C2599.070557,2633.509277 2603.614746,2633.796387 2608.248047,2634.000732 
	C2624.074219,2634.698975 2639.808838,2634.945801 2655.069336,2640.931641 
	C2662.792480,2643.961426 2671.524414,2645.651855 2680.395508,2642.546143 
	C2684.267090,2641.190674 2688.949707,2642.076904 2693.263672,2642.089600 
	C2695.620850,2642.096436 2698.447021,2642.396973 2698.811035,2645.161621 
	C2699.278564,2648.710938 2695.601807,2647.485840 2693.839844,2648.513428 
	C2693.553223,2648.681152 2693.254150,2648.827637 2692.480469,2649.485596 
	C2677.051025,2650.673828 2662.339600,2648.659424 2646.839111,2646.085449 
	C2637.876221,2645.887451 2629.749756,2646.077881 2620.812256,2645.939941 
z"
        />
        <path
          fill="#4C6880"
          opacity="1.000000"
          stroke="none"
          d="
M2509.903320,2629.177246 
	C2510.010254,2630.800537 2510.008057,2631.705811 2510.012207,2633.289551 
	C2503.664062,2639.311279 2496.807129,2637.893555 2490.538818,2634.456055 
	C2482.550537,2630.075684 2474.311523,2628.781982 2465.565186,2630.308350 
	C2457.528809,2631.710938 2449.466309,2630.622559 2441.821289,2629.145996 
	C2421.950439,2625.307129 2401.812744,2624.891357 2381.806641,2622.874512 
	C2379.817871,2622.674316 2377.918701,2622.042969 2375.359131,2620.661133 
	C2373.022705,2619.132812 2372.061035,2617.661621 2371.839111,2614.918701 
	C2373.367188,2611.068115 2375.798340,2609.982666 2378.926270,2609.974121 
	C2380.858887,2609.968750 2382.778809,2609.759277 2383.739258,2606.864258 
	C2389.019043,2607.376221 2394.165039,2608.445557 2399.520508,2608.029053 
	C2403.221436,2607.740967 2406.767578,2607.963379 2409.167969,2611.602783 
	C2410.315674,2613.343262 2412.364258,2613.885742 2414.426270,2613.913574 
	C2433.629639,2614.171875 2452.299805,2618.242188 2471.055664,2621.739014 
	C2473.985596,2622.285156 2476.944092,2621.941406 2479.874756,2621.068115 
	C2489.759277,2618.122803 2498.704834,2621.941406 2507.642334,2625.308350 
	C2508.616943,2625.675537 2509.089111,2627.376709 2509.903320,2629.177246 
z"
        />
        <path
          fill="#516D85"
          opacity="1.000000"
          stroke="none"
          d="
M2441.996582,2598.888916 
	C2449.871338,2599.409180 2457.975830,2600.599609 2467.119629,2600.010742 
	C2468.005127,2604.107910 2471.937988,2603.678223 2474.252197,2605.553711 
	C2465.441895,2607.597168 2456.646240,2612.526855 2448.902588,2603.228271 
	C2447.250000,2601.243896 2443.460449,2602.615967 2441.996582,2598.888916 
z"
        />
        <path
          fill="#4C6880"
          opacity="1.000000"
          stroke="none"
          d="
M2533.915039,2631.248535 
	C2531.799561,2631.906006 2529.372559,2632.269043 2528.077148,2628.846191 
	C2530.216797,2628.109131 2532.650391,2627.720459 2533.915039,2631.248535 
z"
        />
        <path
          fill="#4C6880"
          opacity="1.000000"
          stroke="none"
          d="
M2543.961426,2631.346680 
	C2543.153809,2631.928711 2542.230469,2631.947021 2540.614258,2631.936523 
	C2540.176758,2630.657471 2540.338379,2629.232422 2541.915039,2629.030762 
	C2543.018799,2628.889648 2543.536377,2629.822266 2543.961426,2631.346680 
z"
        />
        <path
          fill="#4C6880"
          opacity="1.000000"
          stroke="none"
          d="
M2367.413086,2606.140137 
	C2367.788818,2607.849121 2367.213867,2609.377686 2364.531738,2609.815918 
	C2364.189941,2608.121582 2364.780762,2606.607666 2367.413086,2606.140137 
z"
        />
        <path
          fill="#344F68"
          opacity="1.000000"
          stroke="none"
          d="
M2838.959229,2652.927246 
	C2839.042725,2652.883301 2838.875732,2652.971191 2838.959229,2652.927246 
z"
        />
        <path
          fill="#516D85"
          opacity="1.000000"
          stroke="none"
          d="
M2688.937012,2554.004883 
	C2686.039551,2549.887451 2682.317871,2549.876465 2678.588867,2550.042725 
	C2672.230957,2550.325928 2666.046875,2549.608398 2659.464844,2547.117676 
	C2664.594727,2542.174072 2671.136719,2544.055908 2676.612793,2542.590576 
	C2681.884521,2541.179932 2687.981689,2541.480957 2693.440918,2542.501953 
	C2699.844238,2543.698975 2705.700195,2545.631104 2710.600830,2539.296875 
	C2711.383301,2538.285889 2713.651855,2538.247559 2715.265869,2538.084961 
	C2730.141602,2536.587158 2744.941650,2536.879395 2759.437988,2540.904053 
	C2763.073242,2541.913574 2766.650146,2541.901855 2771.163818,2542.016357 
	C2771.309082,2547.003662 2774.818115,2548.555420 2778.479736,2549.058350 
	C2786.543945,2550.165283 2790.458252,2555.024414 2792.003418,2563.236816 
	C2791.133789,2563.987061 2790.238281,2563.993652 2788.671387,2564.000000 
	C2785.780762,2563.775146 2784.530029,2562.526855 2783.697998,2560.465820 
	C2782.584473,2557.708008 2779.625244,2555.807373 2777.404541,2556.479248 
	C2769.922119,2558.742676 2762.389160,2554.502197 2755.020752,2557.298584 
	C2748.346680,2559.831055 2741.804199,2556.709473 2735.482910,2555.658691 
	C2720.264160,2553.129395 2705.069580,2554.450928 2688.937012,2554.004883 
z"
        />
        <path
          fill="#708497"
          opacity="1.000000"
          stroke="none"
          d="
M129.971100,2397.971680 
	C130.760956,2398.197266 131.573898,2398.450928 132.386841,2398.704590 
	C131.741196,2400.063721 131.087173,2399.198730 130.179596,2398.332031 
	C129.924622,2397.952393 129.994171,2398.000000 129.971100,2397.971680 
z"
        />
        <path
          fill="#E1D9C6"
          opacity="1.000000"
          stroke="none"
          d="
M1594.635132,3544.369141 
	C1592.970337,3536.270752 1592.970703,3536.259033 1586.069702,3536.010498 
	C1584.410156,3535.950684 1582.743164,3536.064209 1581.086060,3535.976318 
	C1574.296387,3535.617432 1573.941772,3535.366455 1575.022583,3528.210205 
	C1575.464600,3525.283691 1576.055176,3522.513916 1572.282471,3522.256104 
	C1568.497314,3521.997559 1564.130981,3520.561768 1561.226196,3524.586426 
	C1560.063477,3526.197510 1561.259644,3527.625244 1562.136108,3529.076416 
	C1565.784058,3535.118652 1565.796021,3535.210449 1560.547119,3538.950928 
	C1557.622803,3541.034912 1555.995239,3543.598389 1555.969116,3547.270508 
	C1555.955322,3549.227295 1555.899292,3551.406738 1555.056885,3553.080566 
	C1553.907349,3555.364990 1552.243530,3558.063965 1548.984741,3556.942627 
	C1546.107666,3555.952881 1546.347656,3553.497559 1547.340454,3551.129150 
	C1548.517334,3548.321777 1546.686157,3545.988525 1546.094116,3542.723145 
	C1540.281494,3536.817627 1533.183350,3533.286377 1527.754639,3526.662354 
	C1526.905884,3525.242676 1526.764404,3524.310303 1527.055420,3522.655273 
	C1527.624023,3521.612549 1527.955566,3521.259521 1528.917847,3520.551270 
	C1534.877930,3518.145264 1539.961670,3521.557129 1543.465332,3521.199463 
	C1550.297485,3503.727051 1565.307739,3503.722168 1580.110962,3501.402588 
	C1577.225952,3496.843018 1568.436523,3493.311523 1577.734741,3487.523438 
	C1576.312134,3487.163330 1575.184326,3487.184814 1575.132935,3487.591064 
	C1573.898804,3497.344727 1568.309326,3498.901123 1559.857910,3496.297363 
	C1554.695190,3494.706787 1548.400757,3498.405029 1543.644043,3492.398438 
	C1543.574463,3488.772705 1545.748413,3487.538330 1548.699707,3486.130371 
	C1555.235718,3485.682129 1560.276733,3484.690674 1562.903320,3478.165283 
	C1564.729492,3473.628662 1569.688110,3473.313477 1574.044434,3472.996582 
	C1579.558350,3472.595703 1582.249146,3469.653320 1583.075073,3464.250488 
	C1584.379150,3455.720703 1586.112061,3447.244873 1581.835327,3439.145264 
	C1578.994751,3451.853271 1574.387207,3463.384277 1559.489990,3467.679688 
	C1557.811035,3467.480225 1556.936768,3467.091797 1555.678223,3465.973877 
	C1555.073853,3464.956055 1554.925171,3464.501221 1554.777710,3463.333496 
	C1555.410767,3459.754150 1557.628540,3457.993896 1559.854004,3455.465576 
	C1561.431030,3453.798584 1562.167969,3452.407959 1562.467529,3450.165039 
	C1563.101807,3448.083008 1564.131592,3446.939453 1565.478638,3445.389160 
	C1565.765869,3444.961914 1565.831543,3444.001465 1565.444580,3443.654053 
	C1562.218018,3442.007324 1557.643555,3442.544434 1557.402588,3438.288818 
	C1557.121948,3433.333008 1562.752930,3433.135742 1565.818115,3429.928955 
	C1568.708740,3427.435303 1574.819946,3425.989014 1566.993896,3421.917969 
	C1566.095093,3421.234375 1565.763794,3420.917969 1565.087891,3419.999023 
	C1563.518311,3412.971680 1567.528076,3411.882812 1572.398438,3412.279297 
	C1577.174683,3412.667725 1579.272217,3410.484619 1581.324951,3406.304688 
	C1587.610474,3393.504395 1593.678589,3380.681152 1597.544189,3366.885010 
	C1599.036865,3361.557617 1602.749756,3356.840576 1607.380127,3354.768066 
	C1614.842651,3351.428223 1619.662109,3346.605469 1622.149658,3339.027100 
	C1622.426514,3338.183838 1624.297607,3337.294922 1624.141724,3337.001221 
	C1618.926025,3327.178467 1629.289062,3325.239014 1633.006226,3320.346680 
	C1636.536377,3315.700439 1639.552124,3311.305664 1640.278442,3305.454346 
	C1640.643433,3302.513672 1642.300781,3300.531738 1645.558838,3300.614502 
	C1648.139893,3300.680176 1650.938110,3302.205322 1650.799683,3304.352539 
	C1650.335693,3311.550049 1654.597534,3314.898193 1659.982910,3317.907471 
	C1661.595947,3318.808838 1660.322266,3320.921387 1660.925659,3323.002441 
	C1664.085693,3315.970459 1659.276123,3310.724609 1658.253906,3304.210693 
	C1658.377930,3303.031250 1658.481567,3302.566650 1658.865845,3301.448730 
	C1662.151001,3293.991211 1666.823730,3289.077881 1674.653320,3288.819580 
	C1678.256470,3288.700439 1678.840698,3286.387939 1680.015381,3284.054199 
	C1681.916382,3280.278076 1685.796021,3278.254883 1689.093018,3279.888184 
	C1695.115234,3282.871826 1695.715454,3278.237549 1696.920288,3275.154053 
	C1700.819580,3265.173340 1706.122681,3257.364014 1717.735229,3254.944092 
	C1729.291260,3252.536377 1732.055176,3254.416504 1730.377563,3266.335205 
	C1745.659424,3265.632812 1756.076538,3257.901611 1763.754028,3244.663330 
	C1769.135010,3244.248535 1772.219971,3237.504883 1778.389648,3241.517578 
	C1779.756226,3243.476562 1780.336914,3245.183594 1779.823486,3246.758057 
	C1776.386108,3257.296143 1777.765625,3267.566895 1781.167114,3277.795654 
	C1781.919067,3280.056641 1781.670532,3282.506836 1780.958130,3284.677734 
	C1777.783447,3294.352295 1776.930298,3304.800781 1768.917603,3312.754150 
	C1765.418579,3316.227295 1764.357544,3322.250000 1765.038086,3327.693359 
	C1765.378540,3330.417969 1765.945557,3333.179688 1763.839233,3335.207275 
	C1754.963257,3343.750244 1751.787354,3355.316895 1747.121826,3366.101318 
	C1746.858398,3366.709961 1746.716187,3367.524414 1746.232788,3367.857666 
	C1739.950684,3372.187500 1740.192139,3378.276855 1741.273926,3385.343262 
	C1740.639771,3386.336426 1740.315063,3386.685547 1739.385132,3387.412598 
	C1736.463257,3388.896484 1733.044556,3388.916748 1732.422852,3391.927734 
	C1729.135498,3407.850342 1715.361084,3418.017334 1710.388672,3433.244629 
	C1705.795898,3447.309814 1693.620483,3457.321289 1686.862793,3471.238281 
	C1686.151733,3472.152832 1685.858276,3472.510010 1685.087646,3473.364258 
	C1679.791626,3477.761963 1677.951050,3483.168213 1676.517822,3489.516602 
	C1676.065063,3490.914307 1676.052124,3491.798096 1676.031982,3493.345215 
	C1674.151245,3497.905518 1670.968872,3496.633057 1668.461914,3495.402344 
	C1659.520264,3491.011963 1654.318604,3494.618164 1650.970825,3502.932861 
	C1650.610229,3503.828125 1649.864014,3504.545410 1648.588013,3505.624512 
	C1645.679321,3505.873291 1643.757690,3505.716309 1642.077881,3503.326416 
	C1637.226685,3496.423584 1636.785156,3496.529053 1628.268188,3502.346924 
	C1634.791870,3504.359375 1636.960815,3509.225830 1637.802856,3514.970947 
	C1638.746704,3521.409424 1637.628296,3522.932373 1631.091431,3524.014160 
	C1623.487427,3525.272705 1623.374756,3525.389648 1622.478516,3533.408691 
	C1617.476440,3532.250000 1615.313965,3527.723145 1611.922241,3524.871582 
	C1609.669067,3522.978271 1607.323730,3521.357666 1605.110962,3524.145996 
	C1602.487549,3527.450928 1598.152588,3527.379150 1594.774170,3529.553223 
	C1605.630249,3538.386475 1605.630249,3538.386475 1605.306641,3545.483643 
	C1610.918579,3542.652832 1614.492188,3547.368408 1618.963135,3548.931396 
	C1621.526733,3549.827393 1622.535400,3552.529297 1618.988159,3553.791992 
	C1615.280029,3555.111816 1611.078735,3558.658936 1607.819336,3552.694580 
	C1606.154297,3549.647949 1605.244995,3551.801514 1603.714722,3553.323486 
	C1600.365356,3556.655518 1599.116211,3556.278809 1598.172485,3551.818848 
	C1597.621216,3549.214844 1597.782104,3546.479980 1594.635132,3544.369141 
z"
        />
        <path
          fill="#D0C8B8"
          opacity="1.000000"
          stroke="none"
          d="
M1648.011475,3506.709717 
	C1647.810425,3499.528320 1652.857178,3495.466309 1655.332642,3490.227295 
	C1656.045410,3488.718750 1658.036987,3488.079346 1659.607910,3489.195312 
	C1664.198730,3492.457275 1669.912842,3492.269043 1675.438232,3494.206543 
	C1685.005981,3510.406494 1675.988770,3519.874023 1662.511475,3527.098145 
	C1664.779053,3530.927002 1667.035034,3527.909912 1669.053223,3528.259766 
	C1671.233032,3528.637695 1672.871338,3529.651855 1673.411865,3531.718506 
	C1674.019287,3534.041748 1672.761108,3535.485596 1670.442139,3535.920166 
	C1668.378540,3536.306641 1666.210205,3537.015137 1666.268677,3539.376221 
	C1666.491821,3548.388184 1659.968506,3549.887207 1652.680786,3551.709473 
	C1649.708252,3549.265869 1647.682251,3548.109375 1646.336914,3553.454590 
	C1644.341064,3558.041992 1641.819946,3561.355957 1643.410889,3566.730225 
	C1645.108032,3572.462891 1640.771973,3578.190918 1638.714600,3583.562744 
	C1636.489502,3589.373047 1635.966309,3593.215088 1641.589844,3596.630859 
	C1641.958252,3596.854492 1641.840820,3597.878174 1641.969849,3599.270508 
	C1641.896606,3600.329346 1641.818726,3600.652832 1641.706421,3600.964355 
	C1640.871948,3603.281982 1638.751953,3604.400391 1637.067017,3605.925049 
	C1639.079346,3605.382812 1640.834595,3603.835693 1643.133667,3604.402100 
	C1643.773193,3604.559570 1644.401733,3604.739014 1645.500244,3605.538330 
	C1648.957153,3609.662354 1652.268311,3612.371338 1645.895996,3617.010742 
	C1641.420776,3620.269287 1639.918579,3626.945801 1638.121216,3632.672363 
	C1634.722534,3643.501221 1629.768311,3653.786377 1627.467651,3664.729248 
	C1623.753906,3664.923340 1622.372925,3661.385254 1618.719604,3662.025879 
	C1617.745239,3658.854004 1619.215088,3656.419434 1620.796631,3653.778564 
	C1625.503052,3645.919678 1625.092407,3642.539307 1618.282227,3635.749512 
	C1615.518799,3632.993652 1615.332275,3630.132324 1617.556152,3627.994141 
	C1622.434692,3623.304199 1622.182495,3618.341553 1620.225952,3612.600342 
	C1619.397217,3610.168701 1619.614258,3607.372803 1619.411255,3604.738281 
	C1619.069458,3600.304443 1620.290649,3597.337158 1625.555786,3598.811035 
	C1628.743164,3599.703125 1631.031006,3597.529541 1629.633423,3595.549316 
	C1623.623291,3587.034912 1630.659180,3581.420654 1634.026733,3575.611084 
	C1636.575439,3571.214355 1639.284668,3568.043457 1635.041016,3563.566650 
	C1634.823975,3563.337402 1634.727783,3562.985107 1634.601929,3562.679199 
	C1633.207153,3559.285645 1637.046631,3557.298340 1636.938965,3554.479736 
	C1636.795898,3550.731934 1631.147339,3553.021729 1630.561523,3549.433350 
	C1630.361694,3548.209717 1630.442505,3545.909668 1630.915283,3545.761719 
	C1637.533325,3543.688232 1634.481445,3534.424561 1641.045654,3532.239990 
	C1641.786011,3531.993408 1641.684570,3529.217773 1641.975342,3526.826172 
	C1641.943848,3524.263184 1642.482544,3522.628418 1643.592529,3520.522949 
	C1646.639771,3518.541016 1649.444702,3517.729736 1653.249878,3517.998047 
	C1654.199463,3518.808105 1654.686890,3519.054443 1655.712891,3518.344971 
	C1655.847534,3515.420654 1654.204468,3514.090332 1652.142090,3513.082275 
	C1649.776245,3511.925537 1647.888428,3510.414062 1648.011475,3506.709717 
z"
        />
        <path
          fill="#E1D9C6"
          opacity="1.000000"
          stroke="none"
          d="
M1578.709106,3558.088379 
	C1576.859619,3548.730713 1576.317627,3548.493408 1565.090576,3552.368164 
	C1563.574829,3550.310547 1563.993286,3547.962891 1564.207153,3545.697754 
	C1564.427002,3543.368896 1566.029541,3541.834717 1568.193115,3542.298828 
	C1575.544067,3543.876709 1583.225098,3540.656494 1591.214844,3543.991943 
	C1592.008301,3545.955811 1592.123535,3547.902832 1591.963623,3549.826660 
	C1591.366211,3557.008301 1587.468384,3559.566895 1578.709106,3558.088379 
z"
        />
        <path
          fill="#D0C8B8"
          opacity="1.000000"
          stroke="none"
          d="
M1615.359253,3672.072266 
	C1615.950073,3674.281494 1615.091797,3676.133301 1612.572144,3677.703613 
	C1608.741821,3674.112549 1612.042236,3673.272217 1615.359253,3672.072266 
z"
        />
        <path
          fill="#D0C8B8"
          opacity="1.000000"
          stroke="none"
          d="
M1617.375122,3662.112793 
	C1617.958496,3663.772461 1618.144653,3665.581299 1616.513184,3667.566895 
	C1613.643555,3665.743896 1613.402954,3663.755371 1617.375122,3662.112793 
z"
        />
        <path
          fill="#C7C0B1"
          opacity="1.000000"
          stroke="none"
          d="
M1643.456299,3520.276855 
	C1643.947876,3521.783447 1644.133911,3523.602295 1642.478516,3525.597412 
	C1639.128906,3523.661621 1641.015381,3522.100830 1643.456299,3520.276855 
z"
        />
        <path
          fill="#C7C0B1"
          opacity="1.000000"
          stroke="none"
          d="
M1655.475220,3517.965820 
	C1656.958862,3518.753662 1657.990967,3519.874512 1656.567871,3520.793701 
	C1655.206177,3521.673340 1654.518555,3520.326660 1654.074707,3518.549316 
	C1653.995117,3518.000000 1654.981934,3517.980469 1655.475220,3517.965820 
z"
        />
        <path
          fill="#CBC5B5"
          opacity="1.000000"
          stroke="none"
          d="
M1579.198975,3790.024902 
	C1579.800171,3791.748779 1579.288208,3793.269043 1576.600342,3793.857910 
	C1576.157349,3792.209229 1576.731812,3790.736572 1579.198975,3790.024902 
z"
        />
        <path
          fill="#C7C0B1"
          opacity="1.000000"
          stroke="none"
          d="
M1505.645630,3973.566406 
	C1505.059448,3974.622070 1504.114014,3975.254883 1502.576904,3975.938965 
	C1502.381714,3974.224854 1502.366089,3971.981934 1505.645630,3973.566406 
z"
        />
        <path
          fill="#DAD3C3"
          opacity="1.000000"
          stroke="none"
          d="
M1542.746582,3685.976074 
	C1544.221436,3682.316162 1544.005249,3678.690918 1542.673218,3674.625488 
	C1541.529053,3671.133789 1543.758789,3669.723389 1547.157715,3670.774414 
	C1550.281494,3671.739990 1552.960938,3674.018066 1557.207275,3673.954346 
	C1557.785156,3675.259033 1557.945435,3677.496826 1557.366699,3677.707764 
	C1552.196533,3679.592041 1549.388916,3685.398438 1542.746582,3685.976074 
z"
        />
        <path
          fill="#E5DDC9"
          opacity="1.000000"
          stroke="none"
          d="
M1560.457886,3661.638672 
	C1559.666138,3661.989746 1559.320557,3662.055664 1559.010986,3661.977783 
	C1557.043335,3661.483398 1553.397461,3663.165527 1553.618408,3659.807373 
	C1553.809814,3656.898682 1557.358643,3658.410889 1559.436646,3658.021484 
	C1559.754150,3657.961914 1560.093018,3658.016113 1561.192627,3658.029297 
	C1561.615723,3659.123047 1561.268188,3660.205322 1560.457886,3661.638672 
z"
        />
        <path
          fill="#5D758A"
          opacity="1.000000"
          stroke="none"
          d="
M538.286133,2442.626709 
	C543.236145,2442.213867 547.543518,2442.090820 551.848755,2442.140869 
	C553.119507,2442.155762 554.587891,2442.432129 554.823120,2444.137207 
	C555.034607,2445.669922 554.061401,2446.610596 552.836487,2447.181152 
	C548.638794,2449.137695 541.689636,2447.183105 538.286133,2442.626709 
z"
        />
        <path
          fill="#5D758A"
          opacity="1.000000"
          stroke="none"
          d="
M547.649536,2455.403809 
	C541.598511,2459.087402 540.731506,2458.874512 537.963257,2452.846680 
	C541.291992,2452.082031 544.876831,2451.374512 547.649536,2455.403809 
z"
        />
        <path
          fill="#627A90"
          opacity="1.000000"
          stroke="none"
          d="
M484.197388,2431.109131 
	C480.875092,2431.927734 477.940002,2432.825928 475.824158,2429.791992 
	C480.970734,2427.983398 480.970734,2427.983398 484.197388,2431.109131 
z"
        />
        <path
          fill="#4B6377"
          opacity="1.000000"
          stroke="none"
          d="
M450.086151,2438.738770 
	C449.561676,2439.934814 448.196503,2440.076904 447.994965,2439.231201 
	C447.685089,2437.931396 449.022217,2438.297607 450.086151,2438.738770 
z"
        />
        <path
          fill="#627A90"
          opacity="1.000000"
          stroke="none"
          d="
M350.956299,2434.918945 
	C351.044739,2434.871826 350.867828,2434.966064 350.956299,2434.918945 
z"
        />
        <path
          fill="#2F4457"
          opacity="1.000000"
          stroke="none"
          d="
M348.690613,3198.029297 
	C345.903992,3198.083984 343.811005,3198.106445 340.938477,3198.155762 
	C336.594910,3198.943848 332.691956,3199.020264 329.526550,3200.586914 
	C316.853882,3206.858887 302.807617,3205.007324 289.584869,3207.865479 
	C287.993134,3208.209473 286.162750,3208.292969 284.614929,3207.868896 
	C261.253143,3201.474121 237.294937,3202.223389 213.434631,3201.750488 
	C212.836746,3201.738770 212.248520,3201.238525 211.157166,3200.736816 
	C212.533508,3196.419189 216.122253,3196.197021 219.638123,3196.049561 
	C236.859497,3195.328125 253.755554,3190.545654 271.143494,3191.933594 
	C284.343048,3185.708984 298.696411,3188.038574 312.437469,3187.289307 
	C325.622498,3186.570312 338.394623,3182.776123 351.601349,3183.252930 
	C356.796906,3183.440186 362.099609,3185.070557 366.506134,3186.989990 
	C377.612610,3191.827148 388.609528,3189.892090 399.733124,3189.012451 
	C403.341980,3188.726807 406.877472,3187.586426 410.465759,3186.944580 
	C413.285706,3186.439941 413.755127,3188.395508 413.968750,3191.263184 
	C403.924561,3193.375732 394.065521,3196.377441 382.874817,3195.909668 
	C380.395142,3195.980957 378.796082,3195.995605 376.597229,3196.008301 
	C375.997559,3196.013428 375.027985,3196.070557 374.542725,3196.089844 
	C365.903778,3197.662598 357.521210,3196.224609 348.690613,3198.029297 
z"
        />
        <path
          fill="#2B3E51"
          opacity="1.000000"
          stroke="none"
          d="
M240.299561,3182.957275 
	C240.971146,3184.374268 241.402496,3185.929443 241.017426,3186.168457 
	C236.443008,3189.005859 231.203354,3188.679199 226.121979,3188.774414 
	C225.139984,3188.792725 223.501572,3187.442383 223.265854,3186.459229 
	C222.810638,3184.561035 224.610977,3183.701172 226.130692,3183.096436 
	C230.562698,3181.333496 235.124619,3181.849609 240.299561,3182.957275 
z"
        />
        <path
          fill="#233444"
          opacity="1.000000"
          stroke="none"
          d="
M178.127869,3224.985840 
	C187.671448,3224.657715 197.248749,3223.431152 207.356750,3220.245361 
	C206.620300,3225.174805 201.576935,3226.462402 198.151596,3227.087891 
	C191.747986,3228.256836 184.652863,3230.269287 178.127869,3224.985840 
z"
        />
        <path
          fill="#1B2B3A"
          opacity="1.000000"
          stroke="none"
          d="
M78.732208,3245.984619 
	C78.473404,3241.872070 81.544891,3242.018311 85.269348,3242.014893 
	C85.526497,3246.125488 82.456985,3245.982178 78.732208,3245.984619 
z"
        />
        <path
          fill="#442F21"
          opacity="1.000000"
          stroke="none"
          d="
M51.527176,3937.440674 
	C55.339252,3933.758545 58.268379,3930.089355 62.957405,3928.746582 
	C65.724098,3927.954346 67.602814,3928.784180 67.836685,3931.763428 
	C68.119308,3935.363770 68.429047,3939.033203 64.765854,3941.523926 
	C62.056065,3943.366211 59.372192,3945.300781 56.943890,3947.490479 
	C53.294460,3950.781250 49.839298,3948.824219 47.398525,3946.527100 
	C44.273754,3943.586426 47.949249,3941.492188 49.632587,3939.348633 
	C50.036144,3938.834961 50.554657,3938.411133 51.527176,3937.440674 
z"
        />
        <path
          fill="#626669"
          opacity="1.000000"
          stroke="none"
          d="
M69.182907,3907.157715 
	C69.011307,3907.274902 68.931717,3906.826660 68.931717,3906.826660 
	C68.931717,3906.826660 69.354500,3907.040283 69.182907,3907.157715 
z"
        />
        <path
          fill="#3E5871"
          opacity="1.000000"
          stroke="none"
          d="
M2548.566406,2682.550781 
	C2529.740479,2682.830566 2511.597168,2681.065918 2493.435303,2679.895508 
	C2490.165527,2679.684814 2486.942871,2678.746338 2482.860840,2678.142090 
	C2480.986084,2679.079346 2479.966309,2680.036133 2478.453857,2681.490234 
	C2470.614258,2681.953857 2463.282715,2681.937256 2455.038574,2681.922852 
	C2451.878418,2679.463867 2449.857178,2676.767090 2446.447266,2674.603516 
	C2437.160400,2674.140869 2428.370361,2674.001709 2418.873047,2672.128906 
	C2416.886963,2673.132812 2415.807129,2674.175049 2414.231934,2675.504150 
	C2406.411621,2675.721924 2399.255371,2672.999512 2390.912109,2674.088379 
	C2382.987549,2680.239746 2374.650146,2679.418945 2366.329102,2678.437256 
	C2348.940186,2676.385498 2331.707031,2672.094971 2313.979004,2674.832275 
	C2315.998779,2687.375488 2313.632568,2690.559326 2300.443115,2691.093994 
	C2288.505615,2691.578125 2276.517334,2692.060303 2263.870605,2690.125732 
	C2259.779297,2687.822266 2257.192627,2684.853027 2253.615723,2682.908691 
	C2247.225098,2679.436279 2247.613281,2675.782715 2254.387695,2672.543457 
	C2261.231934,2669.270752 2268.472168,2669.486084 2275.811279,2670.706787 
	C2279.947998,2671.395020 2283.962402,2673.965820 2288.710449,2671.311523 
	C2290.053223,2666.947754 2292.554443,2663.491211 2291.683594,2658.516357 
	C2291.618896,2655.592529 2292.052979,2653.538574 2294.026367,2651.187500 
	C2307.235596,2648.521973 2319.818604,2650.462402 2332.234131,2651.249023 
	C2355.027344,2652.693848 2377.873535,2653.185547 2400.655029,2655.884277 
	C2415.900146,2657.690674 2430.462891,2662.232178 2446.433594,2664.201660 
	C2449.486572,2665.114258 2451.791504,2665.112305 2454.901855,2665.459473 
	C2459.219727,2667.320068 2463.484131,2666.425781 2467.428955,2669.340088 
	C2486.449463,2668.937256 2504.513428,2671.122559 2522.379150,2675.163818 
	C2525.081299,2675.775146 2527.553955,2675.657227 2530.210693,2675.001709 
	C2532.758789,2674.373535 2535.479980,2673.892822 2538.071045,2674.082764 
	C2555.263672,2675.342285 2572.438721,2673.149170 2589.625000,2673.962646 
	C2593.262695,2674.135010 2596.903809,2674.147705 2600.474121,2672.887207 
	C2604.977295,2671.297119 2609.970215,2671.101562 2614.277100,2672.332520 
	C2623.356201,2674.927979 2632.824219,2672.995605 2641.883789,2675.714111 
	C2645.871826,2676.911133 2650.367188,2675.590332 2654.680420,2674.978027 
	C2666.081543,2673.359863 2677.589111,2672.649658 2688.629395,2677.209229 
	C2688.196777,2680.022949 2686.303223,2680.049561 2684.781494,2679.889893 
	C2669.822021,2678.323486 2655.316895,2683.690674 2640.406006,2682.741455 
	C2639.777100,2682.701416 2639.045898,2682.829590 2638.500244,2683.129883 
	C2623.748291,2691.249512 2608.265381,2688.128906 2592.797363,2686.349121 
	C2587.204590,2685.705322 2581.499023,2686.040771 2574.924561,2685.929199 
	C2565.528076,2686.598389 2557.218994,2685.841797 2548.566406,2682.550781 
z"
        />
        <path
          fill="#546D83"
          opacity="1.000000"
          stroke="none"
          d="
M2261.199951,2687.259277 
	C2274.566406,2687.875977 2287.221191,2687.930908 2299.876221,2687.960693 
	C2302.534912,2687.966797 2305.257080,2688.166748 2307.741455,2686.992432 
	C2309.740967,2686.047119 2312.750244,2686.387695 2313.356689,2683.533447 
	C2314.003662,2680.488281 2311.155518,2679.823242 2309.301514,2678.618408 
	C2307.551270,2677.481201 2305.944336,2676.311768 2306.565430,2673.900635 
	C2307.253662,2671.228760 2309.356934,2670.226807 2311.822754,2670.150879 
	C2314.129395,2670.079590 2316.612305,2669.936279 2318.725342,2670.667969 
	C2322.404053,2671.942383 2325.772705,2671.910645 2329.404053,2670.574463 
	C2332.248535,2669.528320 2335.433105,2669.541260 2338.276855,2670.483154 
	C2348.460938,2673.855469 2359.270020,2673.343262 2369.600586,2675.796143 
	C2375.959473,2677.305908 2382.050049,2674.323486 2389.178223,2674.072754 
	C2390.043945,2674.760742 2389.990479,2675.425049 2390.083496,2676.068115 
	C2391.375244,2684.992920 2390.849854,2685.279297 2382.026367,2684.481934 
	C2377.455566,2684.068848 2372.304199,2681.945801 2368.177246,2687.304199 
	C2366.453369,2692.833008 2368.765869,2694.532715 2373.195801,2695.161377 
	C2376.589111,2695.643311 2380.830566,2695.596924 2380.390869,2701.454346 
	C2375.262695,2702.031738 2370.523438,2702.045654 2364.896973,2702.052734 
	C2361.860352,2699.156250 2355.029053,2700.814941 2356.731689,2693.715332 
	C2357.258789,2691.516357 2349.371826,2689.591797 2346.473633,2692.159180 
	C2342.430176,2695.740967 2338.748535,2695.497803 2333.748779,2694.638428 
	C2326.647705,2693.417480 2319.337158,2693.685791 2312.007568,2695.575195 
	C2298.457031,2699.068848 2284.891357,2699.285400 2270.959473,2695.756104 
	C2264.252441,2694.056396 2256.544434,2695.945312 2249.342285,2696.706299 
	C2245.416504,2697.121338 2241.600342,2698.574707 2236.967285,2699.620605 
	C2229.845947,2693.612305 2222.033691,2695.650635 2214.574707,2695.826904 
	C2204.152344,2696.073486 2202.451904,2693.284424 2207.844727,2682.952881 
	C2226.119141,2680.788086 2243.409912,2682.443115 2261.199951,2687.259277 
z"
        />
        <path
          fill="#3F5A73"
          opacity="1.000000"
          stroke="none"
          d="
M2549.561523,2651.272461 
	C2568.664307,2657.570801 2587.869141,2655.690918 2606.919678,2655.994141 
	C2610.555420,2656.052002 2613.750977,2655.416016 2617.143311,2653.385986 
	C2622.611084,2650.114502 2627.559326,2651.464111 2629.817871,2657.347656 
	C2631.826172,2662.580078 2637.601562,2660.562012 2639.976318,2665.062500 
	C2634.031250,2665.235352 2628.138184,2664.413330 2621.907471,2667.297119 
	C2618.028564,2669.092285 2612.716797,2668.241699 2608.097412,2667.904541 
	C2592.685791,2666.780029 2576.875000,2668.106445 2562.404785,2660.693359 
	C2560.802734,2659.872559 2558.311523,2659.825684 2556.566406,2660.412109 
	C2549.389160,2662.824707 2542.040527,2661.747070 2534.759766,2661.981201 
	C2528.520264,2662.181641 2522.181885,2661.107178 2516.460693,2665.188721 
	C2514.854004,2666.334473 2511.812988,2666.251953 2509.670410,2665.735596 
	C2496.495850,2662.559814 2483.138428,2664.584229 2468.945801,2664.012695 
	C2464.610596,2667.165283 2460.664795,2667.422363 2455.595215,2666.114746 
	C2453.653564,2665.960449 2452.567627,2665.821777 2450.811523,2665.220703 
	C2447.481934,2656.197266 2454.534180,2657.426025 2458.733154,2657.135498 
	C2472.941895,2656.152344 2487.097656,2653.911133 2501.416992,2654.908203 
	C2508.895264,2655.428955 2512.247559,2652.493896 2509.225098,2644.024414 
	C2508.846436,2640.036133 2510.701660,2638.048340 2513.607910,2637.967529 
	C2527.016113,2637.594238 2539.947266,2638.870605 2549.561523,2651.272461 
z"
        />
        <path
          fill="#395166"
          opacity="1.000000"
          stroke="none"
          d="
M2380.582031,2702.560791 
	C2379.632568,2699.841064 2378.583008,2698.388672 2376.191650,2698.137695 
	C2373.565430,2697.862549 2370.873047,2697.750977 2368.348633,2697.059570 
	C2361.514160,2695.188721 2361.425293,2694.466064 2367.312988,2688.179688 
	C2367.987305,2688.058594 2368.911621,2688.099365 2369.374268,2688.096680 
	C2372.366211,2690.662109 2375.638672,2689.820312 2379.351562,2690.036133 
	C2385.515625,2690.088135 2391.062988,2690.607178 2397.099365,2688.188232 
	C2398.811523,2689.040527 2399.895996,2689.372314 2401.642822,2688.465332 
	C2404.201904,2688.015137 2406.303955,2687.977295 2409.192383,2687.886230 
	C2410.828613,2687.070312 2411.718750,2686.374023 2413.424316,2686.036865 
	C2420.041260,2686.009277 2426.092285,2686.035156 2433.048584,2686.061768 
	C2438.576416,2688.951172 2444.379150,2688.293945 2449.517090,2691.549072 
	C2453.765625,2694.327637 2457.989258,2694.074219 2462.942627,2694.194336 
	C2464.121582,2694.460693 2464.506348,2694.715088 2465.397949,2695.443848 
	C2467.674072,2705.675781 2477.781006,2702.369141 2483.181396,2707.470947 
	C2483.480225,2709.108154 2483.333496,2710.037354 2482.485352,2711.457031 
	C2465.754883,2711.421143 2449.617432,2709.531494 2433.571289,2707.227783 
	C2427.591064,2706.369385 2421.685303,2705.760986 2415.742920,2705.986328 
	C2404.073975,2706.428467 2392.595947,2705.173828 2380.582031,2702.560791 
z"
        />
        <path
          fill="#49647C"
          opacity="1.000000"
          stroke="none"
          d="
M2482.682129,2708.407227 
	C2480.843018,2706.564941 2479.431396,2706.129883 2477.637451,2706.409180 
	C2468.872314,2707.774902 2468.857910,2707.752686 2465.168701,2700.233887 
	C2464.739258,2699.358887 2464.498291,2698.391113 2464.070312,2696.742920 
	C2465.065186,2694.787598 2466.970459,2694.681396 2467.939453,2692.599609 
	C2468.376709,2689.830322 2469.443604,2688.161621 2471.813477,2688.150146 
	C2483.646729,2688.092773 2495.135254,2685.013184 2507.400879,2687.300537 
	C2518.278076,2689.329102 2529.767090,2688.910889 2541.446045,2691.445312 
	C2545.178955,2695.823242 2539.537842,2697.604736 2540.013184,2701.419678 
	C2540.035400,2702.904053 2540.016846,2703.772705 2539.990967,2705.291992 
	C2523.258301,2706.198730 2506.630127,2708.940430 2488.917236,2707.925781 
	C2486.632324,2709.207764 2485.080322,2709.481934 2482.682129,2708.407227 
z"
        />
        <path
          fill="#3F5A73"
          opacity="1.000000"
          stroke="none"
          d="
M2768.773926,2666.379883 
	C2768.168701,2662.433594 2764.815674,2662.262695 2762.395264,2660.217529 
	C2763.549316,2658.093506 2765.525391,2657.882324 2767.497070,2658.072754 
	C2775.479736,2658.842285 2783.051025,2661.280762 2790.712158,2663.979980 
	C2783.739746,2666.410400 2777.030273,2669.730713 2768.773926,2666.379883 
z"
        />
        <path
          fill="#546D83"
          opacity="1.000000"
          stroke="none"
          d="
M2547.990234,2682.846436 
	C2556.452148,2681.948975 2564.864502,2682.336182 2573.514648,2685.432373 
	C2574.378418,2690.666504 2571.928955,2692.375488 2566.738281,2691.973145 
	C2562.616699,2689.721436 2559.254883,2687.541260 2554.434082,2689.559326 
	C2549.279297,2690.372559 2547.646484,2687.911621 2547.990234,2682.846436 
z"
        />
        <path
          fill="#546D83"
          opacity="1.000000"
          stroke="none"
          d="
M2449.992920,2691.195801 
	C2444.918457,2692.192871 2439.881836,2692.096436 2435.067627,2690.098145 
	C2433.666016,2689.516357 2433.160889,2688.655029 2433.762207,2686.642578 
	C2437.444580,2682.626953 2441.790283,2684.178467 2445.793701,2684.194824 
	C2449.817871,2684.210938 2449.925537,2687.396973 2449.992920,2691.195801 
z"
        />
        <path
          fill="#3F5A73"
          opacity="1.000000"
          stroke="none"
          d="
M2680.527344,2664.589355 
	C2687.494873,2659.608887 2694.998779,2662.657959 2703.343262,2664.151611 
	C2703.523438,2666.894287 2701.732422,2667.857910 2699.153076,2667.951660 
	C2692.928467,2668.177490 2686.770020,2668.105957 2680.527344,2664.589355 
z"
        />
        <path
          fill="#49647C"
          opacity="1.000000"
          stroke="none"
          d="
M2577.496582,2699.528809 
	C2577.314941,2705.245117 2574.040527,2706.448975 2569.487549,2705.473877 
	C2565.990479,2704.724609 2562.526123,2703.821045 2558.537109,2702.426758 
	C2564.161377,2699.734131 2570.191895,2696.916748 2577.496582,2699.528809 
z"
        />
        <path
          fill="#546D83"
          opacity="1.000000"
          stroke="none"
          d="
M2540.735840,2701.958496 
	C2537.778564,2701.758057 2535.286865,2701.249512 2535.027588,2698.633545 
	C2534.772217,2696.060303 2537.397461,2696.240967 2539.115723,2695.781982 
	C2540.456787,2695.423584 2541.249756,2694.559082 2541.884277,2692.660156 
	C2546.764160,2691.627441 2548.355713,2694.155029 2547.996338,2699.252930 
	C2545.804199,2700.645752 2543.610840,2701.263428 2540.735840,2701.958496 
z"
        />
        <path
          fill="#49647C"
          opacity="1.000000"
          stroke="none"
          d="
M2637.835938,2697.513672 
	C2634.224854,2701.387451 2629.766846,2699.538574 2624.802002,2699.982910 
	C2626.432373,2692.718262 2629.652344,2691.965088 2637.835938,2697.513672 
z"
        />
        <path
          fill="#4C6880"
          opacity="1.000000"
          stroke="none"
          d="
M2453.724854,2664.622559 
	C2458.075439,2664.020264 2462.165771,2664.007812 2467.132812,2664.007324 
	C2468.020264,2665.475586 2468.030762,2666.931396 2468.066406,2669.111084 
	C2464.416992,2669.790283 2460.678467,2670.686035 2456.554688,2668.553223 
	C2455.006592,2667.217529 2453.899658,2666.518311 2453.724854,2664.622559 
z"
        />
        <path
          fill="#546D83"
          opacity="1.000000"
          stroke="none"
          d="
M2445.990234,2674.906494 
	C2450.647461,2673.636963 2454.483154,2674.191650 2454.082031,2681.144531 
	C2450.239502,2681.340576 2446.470215,2680.605469 2445.990234,2674.906494 
z"
        />
        <path
          fill="#49647C"
          opacity="1.000000"
          stroke="none"
          d="
M2554.705566,2690.118164 
	C2558.101562,2684.551758 2559.250000,2684.605713 2565.839111,2691.270508 
	C2562.308350,2692.307129 2558.857666,2691.436035 2554.705566,2690.118164 
z"
        />
        <path
          fill="#49647C"
          opacity="1.000000"
          stroke="none"
          d="
M2653.527832,2694.201416 
	C2654.141357,2698.759277 2651.664795,2700.407959 2646.662109,2699.832520 
	C2645.718994,2694.908691 2648.480957,2693.607178 2653.527832,2694.201416 
z"
        />
        <path
          fill="#546D83"
          opacity="1.000000"
          stroke="none"
          d="
M2379.494141,2689.504395 
	C2376.591553,2692.590088 2373.274902,2693.302979 2370.036865,2688.773438 
	C2372.900146,2688.179688 2376.047119,2687.381836 2379.494141,2689.504395 
z"
        />
        <path
          fill="#546D83"
          opacity="1.000000"
          stroke="none"
          d="
M2467.449463,2692.242676 
	C2469.751953,2692.205566 2472.476807,2691.563721 2472.639648,2693.826904 
	C2472.822021,2696.368896 2470.057373,2695.772949 2468.329346,2695.968506 
	C2467.402588,2696.073730 2466.455566,2695.996582 2464.744141,2696.010742 
	C2463.970703,2696.019531 2463.869141,2695.108887 2463.803223,2694.657715 
	C2464.792969,2693.632568 2465.848877,2693.058838 2467.449463,2692.242676 
z"
        />
        <path
          fill="#3F5A73"
          opacity="1.000000"
          stroke="none"
          d="
M2644.421631,2666.399414 
	C2646.084961,2663.538086 2648.505615,2663.612549 2651.543457,2665.354736 
	C2649.971191,2668.527588 2647.501709,2668.338623 2644.421631,2666.399414 
z"
        />
        <path
          fill="#546D83"
          opacity="1.000000"
          stroke="none"
          d="
M2413.639893,2685.565918 
	C2413.046387,2686.597168 2412.102295,2687.210449 2410.568604,2687.828613 
	C2410.379639,2686.071777 2410.508789,2683.975586 2413.639893,2685.565918 
z"
        />
        <path
          fill="#546D83"
          opacity="1.000000"
          stroke="none"
          d="
M2401.398438,2688.033691 
	C2401.829102,2689.307129 2401.678711,2690.754395 2400.085693,2690.959229 
	C2398.971436,2691.102539 2398.446289,2690.166016 2398.011230,2688.627686 
	C2398.823730,2688.045166 2399.760254,2688.030029 2401.398438,2688.033691 
z"
        />
        <path
          fill="#546D83"
          opacity="1.000000"
          stroke="none"
          d="
M2478.574951,2681.890381 
	C2478.196045,2680.211182 2478.723877,2678.660889 2481.389160,2678.192871 
	C2481.794678,2679.910156 2481.191406,2681.391602 2478.574951,2681.890381 
z"
        />
        <path
          fill="#546D83"
          opacity="1.000000"
          stroke="none"
          d="
M2414.424316,2675.803711 
	C2414.164307,2674.132324 2414.787109,2672.625732 2417.396729,2672.204102 
	C2417.769531,2673.955811 2417.127197,2675.440918 2414.424316,2675.803711 
z"
        />
        <path
          fill="#3F5A73"
          opacity="1.000000"
          stroke="none"
          d="
M2856.520996,2658.524902 
	C2859.378174,2654.867432 2862.445312,2655.630859 2865.666016,2659.323242 
	C2862.995605,2659.864014 2859.909912,2660.607910 2856.520996,2658.524902 
z"
        />
        <path
          fill="#21364A"
          opacity="1.000000"
          stroke="none"
          d="
M2865.561035,3175.622559 
	C2863.967773,3182.830322 2863.403809,3183.036621 2852.773438,3180.446777 
	C2852.104492,3175.653809 2850.846680,3171.986084 2845.513672,3172.110352 
	C2838.368896,3172.276855 2831.810791,3169.765381 2825.043457,3168.177979 
	C2819.545654,3166.888428 2814.061035,3165.980713 2808.333008,3165.841797 
	C2800.738281,3165.657227 2793.602539,3162.421143 2786.247070,3160.705322 
	C2776.896240,3158.524170 2767.503418,3156.208984 2758.081543,3154.673828 
	C2749.014893,3153.196533 2740.593262,3149.038086 2731.113770,3149.122559 
	C2727.770996,3149.152344 2725.408691,3149.400879 2723.268799,3151.767578 
	C2721.940918,3153.236328 2720.185791,3154.646484 2720.961670,3156.899414 
	C2721.687744,3159.007324 2723.652100,3159.546387 2725.710205,3159.943604 
	C2729.077881,3160.593506 2733.235352,3158.356201 2735.848145,3163.311035 
	C2733.415771,3167.656982 2729.564453,3168.154785 2724.856689,3168.123291 
	C2722.072998,3167.614014 2720.313965,3166.198242 2717.529297,3165.898438 
	C2716.068359,3165.738037 2715.240479,3165.571777 2713.823242,3165.099854 
	C2711.691162,3164.049316 2710.333496,3162.816650 2707.727051,3162.843750 
	C2697.734619,3162.382568 2688.028320,3159.240723 2680.848877,3169.061035 
	C2678.828857,3171.824707 2673.905762,3171.729248 2670.436768,3170.914551 
	C2658.664795,3168.149414 2645.433838,3169.974609 2636.915771,3157.410156 
	C2632.465576,3150.846436 2623.187012,3154.770752 2615.502930,3152.790527 
	C2608.524658,3144.001709 2612.683350,3135.450928 2614.870117,3126.208496 
	C2618.949951,3122.741943 2623.107178,3122.705811 2626.726562,3124.456787 
	C2644.495605,3133.053223 2663.863525,3130.072266 2682.465088,3132.352295 
	C2717.900879,3136.696045 2753.813965,3137.040527 2788.811035,3145.119141 
	C2791.747314,3145.797119 2794.625244,3146.488037 2797.456055,3149.309814 
	C2799.331787,3153.703369 2801.852539,3155.878906 2805.720703,3156.945557 
	C2816.532715,3159.926758 2827.100586,3163.788330 2838.539307,3164.085205 
	C2845.817627,3164.273926 2852.309814,3168.579590 2859.820068,3168.499512 
	C2863.385254,3168.461426 2863.552490,3172.659912 2865.561035,3175.622559 
z"
        />
        <path
          fill="#102131"
          opacity="1.000000"
          stroke="none"
          d="
M2713.642822,3163.542480 
	C2714.934814,3163.925781 2715.810547,3163.929443 2717.342773,3163.936523 
	C2719.181152,3164.011963 2720.261963,3164.364258 2721.658691,3165.456055 
	C2726.433105,3166.682617 2730.319092,3164.461182 2735.250488,3164.062988 
	C2740.417725,3169.354492 2748.255615,3168.740479 2752.762207,3173.827637 
	C2752.417969,3174.351074 2752.089600,3175.319092 2751.725586,3175.332520 
	C2741.100830,3175.727783 2730.602539,3176.488525 2720.791504,3181.330566 
	C2717.928711,3182.743408 2714.723633,3182.092529 2712.791992,3179.192627 
	C2710.281982,3175.425537 2707.375244,3176.176025 2704.173584,3177.920410 
	C2699.500732,3180.466553 2694.714111,3180.350830 2689.669678,3178.960205 
	C2684.481201,3177.530518 2679.358643,3177.127686 2674.316895,3180.323730 
	C2671.228271,3182.282227 2654.060303,3179.742676 2650.554199,3177.464600 
	C2649.167969,3176.563965 2647.972168,3175.079834 2646.476318,3174.700195 
	C2643.370605,3173.911377 2638.865479,3172.397217 2637.527588,3175.796387 
	C2636.044922,3179.564453 2640.963623,3179.394775 2643.302246,3180.786621 
	C2645.551025,3182.124512 2650.731934,3181.644531 2649.014160,3185.808350 
	C2647.345703,3189.852051 2643.457520,3187.224609 2640.388916,3186.225586 
	C2632.205322,3183.561523 2625.164062,3178.114746 2616.567139,3176.507568 
	C2614.940186,3176.203369 2613.269287,3176.135498 2610.808350,3175.934570 
	C2606.936523,3167.258545 2608.536133,3159.491943 2615.228271,3152.246582 
	C2621.139893,3150.740967 2626.148926,3149.052490 2631.578613,3150.791016 
	C2636.523926,3152.374023 2642.246826,3152.481201 2642.538818,3159.865723 
	C2642.639160,3162.403320 2645.080078,3163.884033 2647.570068,3163.775879 
	C2653.371338,3163.524170 2658.495361,3165.563477 2663.858154,3167.333984 
	C2667.472412,3168.527588 2671.713379,3167.826660 2675.673828,3167.969238 
	C2678.967041,3168.087891 2681.508057,3167.302490 2682.075439,3163.416016 
	C2682.660156,3159.409180 2685.458008,3157.826660 2689.190430,3158.090332 
	C2695.641602,3158.545898 2702.074219,3159.262695 2709.233398,3159.967285 
	C2711.054443,3161.085938 2712.130371,3162.135254 2713.642822,3163.542480 
z"
        />
        <path
          fill="#1B1E22"
          opacity="1.000000"
          stroke="none"
          d="
M2615.703613,3151.252686 
	C2611.941406,3158.865234 2612.633545,3167.013916 2610.109375,3175.134766 
	C2604.471924,3179.046387 2605.855225,3184.011230 2607.001221,3189.396729 
	C2607.812744,3190.419922 2608.208008,3190.849609 2608.135742,3192.022217 
	C2605.551758,3194.197998 2603.302246,3194.279541 2600.231934,3192.962158 
	C2597.526855,3169.372314 2591.156006,3146.404541 2598.131348,3123.009033 
	C2601.427979,3111.952148 2602.409668,3100.216064 2607.964111,3089.810059 
	C2609.813721,3086.344971 2610.667480,3082.257080 2615.137207,3079.997314 
	C2627.134521,3083.279297 2638.578369,3084.054443 2651.017334,3084.080322 
	C2653.360596,3086.807617 2655.551514,3088.739014 2658.219482,3090.139404 
	C2660.820068,3091.504395 2662.012939,3093.639648 2661.962402,3097.256348 
	C2654.802246,3101.564941 2647.690186,3098.439941 2640.592041,3097.677246 
	C2636.971924,3097.288330 2633.484619,3095.926270 2629.896973,3095.194092 
	C2620.648682,3093.306152 2619.935059,3093.965576 2620.180176,3104.615967 
	C2620.342285,3106.354004 2620.336426,3107.354980 2620.186523,3109.113525 
	C2619.753662,3112.384766 2619.149658,3114.859131 2618.891846,3118.170898 
	C2618.879150,3121.113525 2618.397705,3123.128418 2616.598145,3125.527588 
	C2615.765869,3132.409668 2616.458740,3138.732178 2614.327881,3144.838867 
	C2613.663330,3146.743408 2614.388916,3148.764160 2615.703613,3151.252686 
z"
        />
        <path
          fill="#2B3E51"
          opacity="1.000000"
          stroke="none"
          d="
M2758.427490,3229.379150 
	C2758.011963,3230.765381 2758.026855,3231.631836 2758.049072,3233.148438 
	C2750.369873,3237.163818 2743.890625,3231.819092 2736.033691,3230.709961 
	C2724.330566,3227.449463 2713.534180,3223.788330 2701.487793,3222.073730 
	C2696.887207,3219.377930 2692.739990,3217.421875 2688.511475,3215.595459 
	C2685.497314,3214.293457 2681.222412,3212.672363 2682.080078,3209.135742 
	C2683.223877,3204.419678 2687.811279,3206.251465 2691.192871,3206.720947 
	C2689.438965,3205.668945 2686.750244,3205.905762 2685.869873,3202.741211 
	C2695.775146,3197.470947 2706.400635,3197.716064 2716.771484,3196.577393 
	C2718.034180,3196.438721 2719.796143,3197.388672 2720.572510,3196.814453 
	C2728.483154,3190.963135 2735.661133,3196.384033 2743.054932,3198.078613 
	C2744.631104,3198.439453 2746.548340,3200.141113 2747.568604,3199.659180 
	C2758.073730,3194.698242 2765.662598,3202.833496 2775.566162,3205.353271 
	C2770.610107,3209.661621 2765.968262,3207.953125 2761.550049,3206.796143 
	C2756.936523,3205.587891 2752.289307,3206.053711 2747.664551,3206.064697 
	C2742.890381,3206.076172 2737.963623,3205.298340 2732.050293,3207.317383 
	C2738.070312,3213.299072 2744.720459,3213.176758 2751.015869,3213.947510 
	C2754.933594,3214.427246 2759.002686,3213.238770 2763.393066,3215.555908 
	C2764.231689,3225.837158 2757.810791,3225.063965 2751.329102,3224.195801 
	C2754.028809,3224.614014 2756.942139,3226.418701 2758.427490,3229.379150 
z"
        />
        <path
          fill="#374D60"
          opacity="1.000000"
          stroke="none"
          d="
M2685.126953,3202.056152 
	C2687.426025,3203.788818 2689.387695,3203.926025 2691.406982,3204.025635 
	C2693.048340,3204.106445 2694.783203,3204.065430 2696.294922,3204.595947 
	C2698.704590,3205.441162 2700.343262,3207.334717 2699.260254,3209.958252 
	C2698.287598,3212.314697 2696.091797,3211.697754 2694.143799,3211.006104 
	C2690.857422,3209.839844 2687.943848,3206.916016 2683.269775,3209.032471 
	C2687.801025,3214.390625 2695.635254,3213.373779 2699.904297,3219.129395 
	C2691.311035,3225.479004 2682.374512,3227.003906 2673.567627,3220.192627 
	C2668.875244,3216.563721 2663.234375,3216.104736 2657.188232,3214.180664 
	C2644.880859,3208.584473 2633.020020,3204.095215 2619.832031,3201.040283 
	C2613.802246,3200.711670 2609.233643,3199.353271 2607.277344,3192.930908 
	C2607.338623,3191.750977 2607.378418,3191.400391 2607.417969,3190.520020 
	C2608.413818,3189.985107 2609.461182,3189.773682 2610.398682,3190.005371 
	C2628.901855,3194.578369 2648.333984,3194.079102 2666.534668,3200.178711 
	C2672.368164,3202.133545 2678.272461,3202.034912 2685.126953,3202.056152 
z"
        />
        <path
          fill="#3A4F62"
          opacity="1.000000"
          stroke="none"
          d="
M2758.793457,3229.936279 
	C2757.677002,3228.208252 2757.108887,3226.127197 2755.048096,3226.849121 
	C2751.496826,3228.093262 2748.540771,3226.146484 2745.320312,3225.756348 
	C2743.567139,3225.543945 2742.155762,3224.495361 2742.688965,3222.370361 
	C2743.202148,3220.326660 2744.979004,3220.119629 2746.647217,3220.250244 
	C2747.944092,3220.352051 2749.378174,3220.608398 2750.456055,3221.274170 
	C2756.375244,3224.927490 2759.973633,3221.793457 2763.552490,3216.645996 
	C2766.074951,3216.144287 2768.181885,3216.156006 2771.073730,3216.217529 
	C2773.403076,3221.434570 2777.604980,3220.562500 2780.783936,3219.297852 
	C2792.457764,3214.653076 2801.582275,3223.464844 2812.083496,3224.766113 
	C2812.625244,3224.833252 2813.382324,3226.370117 2813.323975,3227.170410 
	C2813.265869,3227.967529 2812.441406,3229.171387 2811.718506,3229.378906 
	C2808.164307,3230.398926 2804.534424,3230.199951 2800.956055,3229.340088 
	C2793.131104,3227.459717 2785.194336,3226.898926 2777.514648,3229.376221 
	C2773.697510,3230.607178 2770.427979,3228.042236 2766.421875,3229.647949 
	C2763.830322,3230.100830 2761.709961,3230.037354 2758.793457,3229.936279 
z"
        />
        <path
          fill="#21364A"
          opacity="1.000000"
          stroke="none"
          d="
M2789.538086,3171.545410 
	C2790.028564,3173.548096 2790.024414,3175.019531 2790.036133,3177.226562 
	C2783.125244,3178.723633 2776.437256,3177.011963 2768.840820,3176.000244 
	C2767.955566,3172.719971 2768.795410,3169.321045 2772.713135,3170.258301 
	C2778.207520,3171.572754 2783.690430,3169.350830 2789.538086,3171.545410 
z"
        />
        <path
          fill="#21364A"
          opacity="1.000000"
          stroke="none"
          d="
M2893.639648,3217.251465 
	C2891.888428,3222.712646 2887.549316,3221.947021 2882.744629,3221.976074 
	C2882.685303,3218.747559 2880.282959,3216.600098 2878.636230,3213.053467 
	C2884.090576,3213.947754 2889.168945,3212.687012 2893.639648,3217.251465 
z"
        />
        <path
          fill="#102131"
          opacity="1.000000"
          stroke="none"
          d="
M2767.456299,3176.082520 
	C2767.799072,3177.210205 2767.765869,3178.584717 2766.228027,3178.878174 
	C2765.906738,3178.939941 2765.118164,3178.268066 2765.104980,3177.913818 
	C2765.064209,3176.803223 2765.977051,3176.415039 2767.456299,3176.082520 
z"
        />
        <path
          fill="#21364A"
          opacity="1.000000"
          stroke="none"
          d="
M2821.928711,3175.382324 
	C2821.114014,3175.953369 2820.204346,3175.955322 2818.613525,3175.895996 
	C2818.191162,3174.587646 2818.368652,3173.170410 2819.957764,3173.013916 
	C2821.058105,3172.905518 2821.550781,3173.848633 2821.928711,3175.382324 
z"
        />
        <path
          fill="#21364A"
          opacity="1.000000"
          stroke="none"
          d="
M2811.989990,3175.207031 
	C2810.972168,3175.474121 2809.847412,3175.201172 2808.361328,3174.454590 
	C2809.677002,3172.044678 2810.955322,3172.364990 2811.989990,3175.207031 
z"
        />
        <path
          fill="#102131"
          opacity="1.000000"
          stroke="none"
          d="
M2616.930664,3126.118164 
	C2616.095703,3123.966553 2616.065674,3121.765869 2616.056641,3118.743652 
	C2621.206055,3113.411621 2627.328125,3114.191650 2633.110840,3115.414307 
	C2647.895996,3118.539307 2662.397949,3119.419434 2677.321533,3115.376221 
	C2684.801270,3113.349854 2693.091064,3113.079834 2701.327637,3117.376953 
	C2702.880615,3122.161621 2699.389404,3123.575928 2696.526367,3126.148193 
	C2700.628418,3129.094971 2704.251465,3128.007080 2707.700439,3126.087158 
	C2712.724854,3123.290283 2716.746826,3123.912354 2721.182861,3127.860840 
	C2726.889404,3132.939697 2734.429443,3133.639648 2741.760010,3133.943359 
	C2748.065674,3134.204834 2754.405762,3134.271973 2760.704590,3133.935303 
	C2775.770508,3133.130127 2790.068359,3136.318359 2804.026611,3141.598389 
	C2806.142822,3142.398926 2808.741699,3141.364990 2811.115723,3143.877197 
	C2807.673584,3147.197021 2802.615723,3146.624512 2798.483887,3149.481445 
	C2779.560791,3146.874268 2761.183594,3143.303955 2742.663086,3140.789307 
	C2733.611572,3139.560303 2724.330322,3138.491211 2715.112061,3139.829102 
	C2710.654053,3140.476318 2706.618896,3138.790039 2702.382080,3138.191406 
	C2686.338135,3135.925049 2670.242676,3133.527832 2654.013184,3134.172363 
	C2644.354248,3134.555908 2635.717041,3131.731201 2627.272949,3128.012451 
	C2624.061768,3126.598633 2621.071289,3125.959717 2616.930664,3126.118164 
z"
        />
        <path
          fill="#21364A"
          opacity="1.000000"
          stroke="none"
          d="
M2702.773926,3118.036621 
	C2695.679443,3118.025391 2689.401367,3117.963867 2683.123535,3117.987793 
	C2680.816650,3117.996338 2678.202637,3117.537598 2676.253174,3118.433594 
	C2662.055908,3124.958008 2647.837891,3121.953369 2633.646484,3118.815918 
	C2628.380371,3117.651611 2623.126221,3118.211914 2616.973145,3117.938965 
	C2616.174072,3115.577637 2615.663818,3113.154053 2617.487793,3110.474854 
	C2627.605957,3105.598877 2637.463379,3109.025391 2645.891846,3109.043213 
	C2637.941895,3106.924561 2628.323975,3109.269775 2618.522461,3106.491699 
	C2617.476318,3104.847412 2616.745117,3103.680176 2616.503906,3102.418945 
	C2614.495605,3091.920410 2617.443848,3088.934082 2627.655273,3091.657227 
	C2638.417725,3094.527344 2649.460693,3095.716553 2661.164551,3097.870117 
	C2669.366943,3104.919678 2678.517822,3107.505615 2688.276367,3107.927002 
	C2698.403076,3108.364746 2707.165283,3113.921875 2717.101318,3115.029785 
	C2719.029297,3115.245117 2720.229736,3117.747559 2719.330078,3119.854248 
	C2718.493896,3121.812256 2716.609375,3121.306396 2715.096436,3120.464844 
	C2711.498779,3118.463623 2707.672852,3117.670410 2702.773926,3118.036621 
z"
        />
        <path
          fill="#21364A"
          opacity="1.000000"
          stroke="none"
          d="
M2901.202148,3228.004639 
	C2901.481445,3229.002930 2900.953369,3229.996094 2900.197754,3231.504639 
	C2899.115234,3232.867432 2897.960693,3232.961426 2896.453613,3233.612793 
	C2895.126465,3233.985107 2894.227783,3233.989746 2892.654785,3233.982422 
	C2891.584473,3227.469971 2896.122559,3227.921875 2901.202148,3228.004639 
z"
        />
        <path
          fill="#21364A"
          opacity="1.000000"
          stroke="none"
          d="
M2850.019531,3250.647217 
	C2850.987549,3250.021240 2851.969727,3249.955078 2852.900146,3250.145020 
	C2855.203369,3250.615723 2857.879639,3251.625977 2856.784912,3254.334961 
	C2855.796875,3256.779053 2853.069580,3256.084717 2850.469238,3254.520020 
	C2850.007568,3253.127441 2850.008789,3252.225342 2850.019531,3250.647217 
z"
        />
        <path
          fill="#2B3E51"
          opacity="1.000000"
          stroke="none"
          d="
M2896.606934,3233.931885 
	C2896.953369,3233.360107 2897.881592,3232.739502 2899.390137,3232.069336 
	C2899.043457,3232.641113 2898.115967,3233.262207 2896.606934,3233.931885 
z"
        />
        <path
          fill="#040F1A"
          opacity="1.000000"
          stroke="none"
          d="
M600.473267,3473.875977 
	C597.875793,3472.990234 595.991333,3472.067383 593.993530,3473.128906 
	C591.443848,3474.483643 587.346191,3474.898193 587.200928,3478.132080 
	C587.018372,3482.197754 591.050232,3481.814453 593.948792,3482.036621 
	C596.045044,3482.197754 597.797424,3482.965820 599.273499,3484.435059 
	C600.485535,3485.641113 601.596802,3486.985840 601.054688,3488.788086 
	C600.537231,3490.508545 599.067139,3491.409180 597.355835,3491.732422 
	C583.553101,3494.338379 569.612671,3494.369629 555.651917,3493.842773 
	C553.593567,3493.765137 551.567810,3493.037598 551.139160,3490.724609 
	C550.720520,3488.465088 552.860779,3488.319824 554.290710,3487.645508 
	C555.490112,3487.080078 556.666992,3486.458740 557.817383,3485.798584 
	C559.107605,3485.057861 559.669250,3483.801270 559.340332,3482.440674 
	C559.005432,3481.055420 557.855713,3480.280762 556.398254,3480.196777 
	C552.078064,3479.947021 547.754028,3479.694824 543.459656,3480.516357 
	C527.281006,3483.612305 510.945587,3481.333984 494.693634,3481.924805 
	C492.966156,3481.987793 491.231689,3481.515625 490.686371,3479.659668 
	C490.137848,3477.793213 491.289734,3476.414551 492.901489,3475.713135 
	C497.086060,3473.891846 499.923492,3470.908691 501.725800,3466.715576 
	C503.653473,3462.231689 507.753754,3459.869385 512.124878,3460.242676 
	C521.050110,3461.005127 529.804749,3457.261719 538.728333,3459.670654 
	C539.849487,3459.973145 541.981812,3459.678955 542.401367,3458.947510 
	C546.898804,3451.109131 553.791260,3455.272705 557.919434,3457.489502 
	C567.249451,3462.500000 576.232971,3462.785889 585.820984,3459.900391 
	C595.935913,3456.856445 606.298889,3458.263428 616.564453,3458.036377 
	C624.220886,3457.866943 631.885681,3457.968994 639.544739,3458.082764 
	C641.892334,3458.117432 644.486755,3458.523926 644.894287,3461.448975 
	C645.247986,3463.988281 642.731934,3464.498047 641.099182,3465.420410 
	C628.702942,3472.425781 615.557007,3476.058838 600.473267,3473.875977 
z"
        />
        <path
          fill="#2E2D30"
          opacity="1.000000"
          stroke="none"
          d="
M747.454224,3491.773193 
	C747.974731,3492.390625 747.904358,3493.364502 747.893555,3493.854492 
	C745.365784,3496.707520 742.677368,3495.776367 739.842224,3494.980469 
	C735.884094,3493.868408 731.833374,3493.086670 727.066162,3492.256348 
	C726.309998,3492.348877 726.050476,3492.043701 725.920715,3491.891113 
	C725.213196,3484.858887 729.196594,3483.835693 734.505493,3485.369873 
	C738.898315,3486.639160 743.274353,3488.194092 747.454224,3491.773193 
z"
        />
        <path
          fill="#0D1C2A"
          opacity="1.000000"
          stroke="none"
          d="
M2564.771729,3355.854004 
	C2563.567627,3346.872314 2568.869385,3339.515869 2571.704102,3330.661865 
	C2575.183594,3326.694092 2578.734863,3326.872803 2583.235352,3329.481689 
	C2590.670410,3333.013916 2591.758057,3332.513428 2593.978516,3324.728516 
	C2600.819580,3318.619385 2608.531494,3320.691650 2615.875000,3321.834961 
	C2627.984131,3323.720215 2639.636475,3327.592041 2651.294922,3331.305664 
	C2653.479980,3332.001465 2655.528564,3333.278320 2658.732422,3332.130371 
	C2661.875000,3331.534912 2664.187744,3331.856445 2666.276611,3332.924316 
	C2689.100830,3344.593018 2713.846924,3350.822021 2738.096924,3358.478027 
	C2744.678223,3360.555664 2751.362549,3362.577393 2757.606445,3367.251221 
	C2757.814453,3371.057861 2758.239258,3373.989502 2761.388428,3375.614502 
	C2768.468750,3379.269287 2775.385742,3383.766113 2783.622559,3383.900146 
	C2795.255127,3384.089355 2802.718994,3392.454102 2811.885254,3397.473633 
	C2812.509033,3397.815430 2812.943115,3399.194336 2812.845215,3400.017334 
	C2812.644775,3401.700195 2811.072754,3401.839844 2809.776611,3401.761475 
	C2808.474365,3401.682861 2807.156494,3401.339844 2805.911621,3400.918213 
	C2790.522705,3395.704590 2774.662354,3392.519287 2758.651611,3390.039307 
	C2755.943604,3389.620117 2753.133545,3389.141357 2751.200684,3387.470459 
	C2745.679688,3382.696289 2738.996826,3386.172852 2733.083496,3384.464355 
	C2731.889404,3384.119385 2730.467285,3385.293945 2730.601074,3386.881104 
	C2730.672852,3387.731689 2731.275635,3388.968506 2731.962891,3389.256104 
	C2734.086914,3390.144043 2736.431396,3390.497070 2738.575928,3391.346680 
	C2753.998535,3397.457764 2769.683105,3402.647217 2786.043457,3405.682617 
	C2796.621094,3407.644775 2807.098145,3410.188477 2817.712158,3411.900146 
	C2823.059570,3412.762939 2825.772217,3417.515625 2830.554443,3419.187744 
	C2832.157959,3419.748291 2829.811035,3421.786377 2828.384766,3423.396484 
	C2827.118408,3423.877930 2826.223389,3423.867920 2824.657715,3423.841797 
	C2819.733154,3424.241943 2815.738037,3422.796875 2811.728516,3421.851074 
	C2806.941650,3420.721924 2804.461426,3422.739990 2802.442627,3427.338867 
	C2801.095215,3427.777832 2800.272461,3427.773193 2798.832520,3427.753418 
	C2797.249756,3427.079590 2796.322021,3426.351807 2794.574463,3425.987305 
	C2793.127930,3425.916016 2792.263916,3425.913086 2790.751465,3425.902832 
	C2787.630127,3423.638672 2784.636230,3422.571045 2780.698730,3422.069336 
	C2768.501221,3419.789307 2756.612061,3419.500488 2745.569580,3414.722168 
	C2742.721924,3413.489746 2739.038330,3414.188965 2734.869385,3413.986328 
	C2727.426270,3413.246826 2720.440186,3414.188232 2714.454834,3408.618652 
	C2710.083740,3401.143799 2704.977295,3396.808838 2696.432373,3399.533447 
	C2692.604736,3400.753662 2688.767578,3399.328125 2686.000000,3397.441895 
	C2680.567871,3393.739990 2674.720947,3393.381836 2668.880371,3394.126709 
	C2657.462891,3395.583008 2650.419189,3389.433594 2644.096680,3380.638184 
	C2646.113525,3377.994873 2648.638184,3377.118408 2652.262207,3377.007324 
	C2655.183594,3377.089600 2657.253174,3376.849365 2659.974121,3375.825195 
	C2671.524170,3374.006348 2682.373535,3374.985840 2694.400391,3375.486328 
	C2690.510742,3371.760010 2686.604004,3371.520020 2682.830322,3371.178467 
	C2676.377930,3370.594482 2670.492676,3368.815918 2665.734619,3364.150146 
	C2662.103271,3360.588867 2657.530518,3360.773438 2652.811279,3360.401611 
	C2634.129150,3358.929688 2615.723145,3355.218750 2596.565918,3352.453369 
	C2590.819580,3347.515625 2590.439697,3347.331787 2588.108398,3352.151367 
	C2586.783203,3354.890381 2585.618652,3355.277588 2582.942139,3354.677002 
	C2577.100098,3353.366943 2571.254150,3353.558350 2564.771729,3355.854004 
z"
        />
        <path
          fill="#0D1C2A"
          opacity="1.000000"
          stroke="none"
          d="
M2588.717773,3419.698242 
	C2580.607178,3420.315674 2573.922607,3419.298584 2567.150879,3415.321289 
	C2562.045166,3412.322510 2555.153564,3412.364746 2548.536621,3410.493652 
	C2551.137451,3406.841797 2554.672607,3408.033691 2558.167480,3408.977051 
	C2559.582275,3409.358643 2560.792969,3409.178711 2561.356689,3407.714355 
	C2561.905762,3406.287598 2561.148193,3405.102051 2560.062988,3404.308105 
	C2557.884766,3402.713623 2555.349854,3402.277832 2552.675537,3401.939941 
	C2546.180176,3401.119385 2546.197998,3400.979248 2547.421875,3392.539062 
	C2550.771484,3397.407959 2556.617676,3396.917725 2561.102783,3397.498535 
	C2566.521729,3398.199951 2572.024658,3399.922363 2577.778809,3398.571289 
	C2581.397949,3397.721680 2585.490234,3399.181152 2587.979492,3401.567871 
	C2592.954834,3406.338135 2599.421631,3406.568115 2605.267090,3408.586914 
	C2610.796143,3410.496094 2615.649902,3407.280762 2620.309570,3405.045898 
	C2623.160156,3403.678223 2622.224609,3397.718018 2618.795898,3396.988281 
	C2613.049805,3395.766113 2607.823730,3391.791748 2601.589355,3393.234619 
	C2598.673584,3393.909668 2595.792725,3393.976807 2595.024658,3390.788574 
	C2594.162354,3387.207764 2597.166992,3385.147217 2600.083740,3384.528809 
	C2604.956787,3383.495117 2610.089844,3383.277832 2614.905029,3384.746826 
	C2620.970703,3386.596924 2626.913574,3388.471924 2633.402588,3388.127930 
	C2637.524170,3387.909668 2640.819824,3390.279541 2641.479736,3394.187256 
	C2642.265625,3398.843750 2645.017090,3399.908203 2648.885498,3400.672607 
	C2652.438965,3401.374756 2655.952148,3402.483643 2659.333008,3403.799316 
	C2662.848389,3405.167236 2664.271729,3407.601318 2663.185791,3411.710449 
	C2660.918457,3420.289307 2656.693359,3423.196289 2647.478516,3422.994629 
	C2641.154297,3422.856201 2634.977051,3423.035156 2629.544434,3419.083984 
	C2628.788330,3418.533936 2627.523438,3418.012695 2626.746582,3418.262939 
	C2615.552246,3421.867920 2604.411377,3416.203369 2593.259766,3418.166748 
	C2591.969238,3418.394287 2590.736816,3418.952637 2588.717773,3419.698242 
z"
        />
        <path
          fill="#091A29"
          opacity="1.000000"
          stroke="none"
          d="
M2556.694336,3456.144043 
	C2556.258057,3451.907227 2554.445801,3449.927979 2550.244629,3449.911133 
	C2546.874756,3449.897461 2544.369873,3448.182617 2544.254883,3444.659424 
	C2544.108887,3440.184326 2542.470215,3435.239746 2546.217285,3431.491211 
	C2548.249512,3429.457520 2551.115479,3429.725098 2553.598877,3431.105713 
	C2555.596680,3432.216797 2557.316650,3433.864990 2559.799805,3433.895752 
	C2561.438477,3433.916016 2563.281006,3434.275391 2564.476562,3432.962158 
	C2566.119873,3431.156982 2564.340820,3429.272217 2564.075684,3426.701660 
	C2572.001953,3425.891113 2579.869629,3426.407959 2587.354492,3431.656250 
	C2598.659912,3434.915771 2606.888184,3443.975342 2619.321045,3444.048340 
	C2620.882080,3443.988037 2621.763428,3444.008301 2623.305664,3444.048340 
	C2627.990234,3452.549805 2631.194824,3454.063965 2639.787842,3451.445801 
	C2641.050049,3451.061035 2642.351807,3450.232910 2643.194580,3451.854248 
	C2643.854492,3453.124512 2643.636230,3455.010986 2642.259521,3455.150391 
	C2636.542480,3455.730713 2634.412109,3462.190918 2628.460938,3463.471191 
	C2625.339111,3466.581055 2622.170898,3465.682129 2619.682129,3464.336670 
	C2611.350098,3459.832764 2602.174072,3457.981445 2593.116211,3456.763672 
	C2582.321777,3455.312988 2575.325684,3446.906494 2565.458008,3443.895264 
	C2570.729004,3448.609863 2577.969238,3451.256348 2580.266602,3459.300293 
	C2572.077637,3461.330566 2564.240723,3461.906250 2556.694336,3456.144043 
z"
        />
        <path
          fill="#1C2E3E"
          opacity="1.000000"
          stroke="none"
          d="
M2654.428711,3379.507812 
	C2651.178223,3379.952148 2648.421875,3379.957520 2644.839355,3379.952148 
	C2643.174805,3377.117676 2642.561523,3374.200195 2641.436768,3371.495361 
	C2640.256104,3368.655762 2638.060547,3366.662598 2634.833252,3366.194580 
	C2629.663574,3365.445068 2624.502441,3364.259033 2619.312744,3364.106689 
	C2611.011719,3363.863037 2604.648193,3358.928223 2597.479736,3355.959473 
	C2596.811768,3355.682861 2596.610596,3354.278809 2596.096191,3352.678711 
	C2598.650391,3351.976074 2601.336670,3352.284424 2603.944824,3351.961426 
	C2617.355469,3350.300293 2630.080811,3354.196045 2642.935059,3356.774414 
	C2646.934570,3357.576660 2650.547607,3357.620117 2654.588135,3356.991699 
	C2660.040527,3356.143799 2665.246582,3356.594971 2668.864990,3362.656250 
	C2672.023926,3367.947266 2678.382080,3368.041992 2684.031738,3367.912598 
	C2689.665283,3367.783691 2694.488037,3369.307129 2698.361572,3373.432373 
	C2699.652588,3374.807861 2701.800293,3376.097412 2700.723633,3378.370117 
	C2699.674561,3380.584717 2697.198730,3380.256348 2695.447510,3379.745605 
	C2684.369873,3376.513916 2673.052002,3378.648193 2660.934570,3377.990234 
	C2658.240479,3378.033203 2656.455322,3377.955078 2654.428711,3379.507812 
z"
        />
        <path
          fill="#172837"
          opacity="1.000000"
          stroke="none"
          d="
M2580.804199,3460.069336 
	C2573.865479,3452.913086 2566.476318,3447.670654 2556.985352,3444.760254 
	C2558.268799,3440.788818 2561.365234,3439.855469 2564.642090,3440.263184 
	C2568.226074,3440.708984 2572.346191,3441.273682 2574.733643,3444.047363 
	C2583.175537,3453.854736 2595.054688,3454.070312 2606.316650,3455.898926 
	C2609.403809,3456.400391 2612.005615,3457.282959 2614.512451,3459.046143 
	C2618.109131,3461.575928 2622.450195,3462.378174 2627.257324,3463.891846 
	C2638.118164,3469.250732 2648.961182,3470.661865 2661.101074,3469.991699 
	C2663.993652,3468.352539 2666.150391,3467.854004 2669.278076,3469.502930 
	C2676.641357,3471.561768 2682.112305,3475.836182 2689.257812,3477.977539 
	C2690.009766,3477.990234 2691.000000,3478.002197 2691.495117,3478.005859 
	C2693.195312,3479.381104 2693.556396,3480.935791 2692.504395,3483.361816 
	C2667.835449,3482.949219 2644.332031,3478.120850 2621.477539,3470.491455 
	C2613.668457,3467.884766 2605.562012,3466.833496 2597.719727,3464.426025 
	C2592.501709,3462.823730 2586.915039,3461.775879 2580.804199,3460.069336 
z"
        />
        <path
          fill="#172B3E"
          opacity="1.000000"
          stroke="none"
          d="
M2713.745117,3408.572266 
	C2720.214600,3410.082764 2727.052002,3409.775391 2733.496582,3413.446533 
	C2727.222900,3417.595947 2720.172363,3416.058594 2712.569336,3414.502930 
	C2712.488525,3412.337402 2712.989502,3410.745850 2713.745117,3408.572266 
z"
        />
        <path
          fill="#091A29"
          opacity="1.000000"
          stroke="none"
          d="
M2669.858398,3469.331055 
	C2667.869141,3470.006104 2665.738281,3470.002197 2662.808594,3469.994385 
	C2664.674561,3465.062988 2665.420410,3464.933350 2669.858398,3469.331055 
z"
        />
        <path
          fill="#0D1C2A"
          opacity="1.000000"
          stroke="none"
          d="
M2556.678223,3367.865723 
	C2556.109375,3365.730713 2555.752930,3363.259766 2559.277100,3362.108154 
	C2559.892578,3364.256348 2560.265381,3366.734863 2556.678223,3367.865723 
z"
        />
        <path
          fill="#0D1C2A"
          opacity="1.000000"
          stroke="none"
          d="
M2548.585205,3383.918213 
	C2548.365234,3382.894531 2548.710693,3381.801025 2549.523193,3380.353516 
	C2552.010010,3381.784424 2551.565430,3383.031006 2548.585205,3383.918213 
z"
        />
        <path
          fill="#1F3041"
          opacity="1.000000"
          stroke="none"
          d="
M2802.429688,3428.347412 
	C2801.624268,3424.889160 2801.330811,3421.995850 2800.998535,3418.720215 
	C2809.216797,3417.274414 2816.340088,3419.135986 2823.597656,3423.200684 
	C2823.881836,3427.111328 2824.740723,3429.120117 2828.549805,3430.180664 
	C2837.034180,3432.542480 2845.302002,3435.684082 2853.553223,3439.687744 
	C2849.859863,3439.687744 2845.991943,3440.396240 2842.510498,3439.537598 
	C2835.610840,3437.835938 2828.902100,3437.625977 2821.921631,3438.851318 
	C2817.591797,3439.611328 2813.168701,3439.067383 2811.896973,3433.521973 
	C2810.714600,3428.367432 2805.578369,3431.114258 2802.429688,3428.347412 
z"
        />
        <path
          fill="#1F3041"
          opacity="1.000000"
          stroke="none"
          d="
M2780.460693,3422.561035 
	C2784.250244,3419.143066 2787.045654,3421.778076 2789.947021,3425.251465 
	C2786.804443,3425.716797 2783.899414,3424.259277 2780.460693,3422.561035 
z"
        />
        <path
          fill="#1F3041"
          opacity="1.000000"
          stroke="none"
          d="
M2794.361328,3426.409668 
	C2795.721436,3424.006836 2796.999512,3424.461426 2798.079346,3427.205078 
	C2797.053955,3427.458984 2795.892090,3427.179688 2794.361328,3426.409668 
z"
        />
        <path
          fill="#172837"
          opacity="1.000000"
          stroke="none"
          d="
M2871.961182,3529.173340 
	C2868.019043,3529.049316 2863.788330,3528.546631 2860.339111,3524.678711 
	C2864.354248,3524.404541 2869.599609,3522.315674 2871.961182,3529.173340 
z"
        />
        <path
          fill="#102131"
          opacity="1.000000"
          stroke="none"
          d="
M2692.359131,3484.497070 
	C2691.999023,3482.528564 2691.996826,3481.021973 2691.992432,3478.762451 
	C2696.115723,3478.447510 2695.986084,3481.512695 2695.996826,3485.236816 
	C2694.905518,3485.639893 2693.811279,3485.299316 2692.359131,3484.497070 
z"
        />
        <path
          fill="#102131"
          opacity="1.000000"
          stroke="none"
          d="
M2803.639648,3531.505371 
	C2802.230713,3534.020020 2800.979248,3533.562744 2800.038086,3530.610352 
	C2801.058838,3530.377197 2802.168945,3530.707031 2803.639648,3531.505371 
z"
        />
        <path
          fill="#233A4F"
          opacity="1.000000"
          stroke="none"
          d="
M2691.975586,2894.139648 
	C2689.528809,2900.572266 2683.820312,2901.124023 2678.297852,2901.958984 
	C2675.516846,2902.379883 2672.175049,2900.981689 2670.202881,2905.228027 
	C2669.017578,2906.750488 2667.935791,2906.559326 2666.331543,2906.049072 
	C2664.797119,2905.504639 2663.763184,2905.383057 2662.153320,2905.914062 
	C2659.992920,2906.536621 2658.348877,2906.700928 2656.028320,2906.799561 
	C2647.306641,2905.497559 2641.135498,2908.130127 2637.012695,2915.361328 
	C2635.840088,2917.417725 2633.936523,2918.836914 2630.992432,2919.974609 
	C2615.789062,2918.256592 2601.266846,2916.847168 2586.635010,2916.769531 
	C2584.982422,2916.760742 2583.300537,2916.257568 2581.843750,2915.453369 
	C2577.571777,2913.094482 2570.951172,2911.446533 2571.745117,2905.832764 
	C2572.661377,2899.354248 2579.614990,2901.597656 2584.811768,2900.715332 
	C2572.469727,2898.981445 2560.685547,2898.872314 2548.850098,2900.789062 
	C2539.123535,2902.364014 2529.621094,2899.796387 2520.052246,2898.677979 
	C2515.753906,2898.175537 2511.335449,2896.678711 2507.157471,2897.801758 
	C2481.123779,2904.800049 2455.804932,2895.621826 2430.137451,2894.678223 
	C2422.848389,2894.410156 2416.587891,2888.772217 2408.091797,2890.252441 
	C2398.427490,2890.314209 2389.861572,2889.649414 2381.348633,2886.086182 
	C2374.819336,2883.353027 2367.398193,2881.965820 2359.432617,2884.990234 
	C2353.107910,2887.226074 2348.458252,2884.401123 2343.740723,2882.094727 
	C2334.579346,2877.614746 2326.207764,2871.511475 2315.510986,2868.522949 
	C2313.427979,2867.807129 2312.136230,2867.124756 2310.212402,2866.104004 
	C2307.557373,2864.555664 2305.760742,2862.959473 2302.828125,2861.869385 
	C2295.616455,2856.489014 2286.060547,2855.989258 2281.570801,2847.186035 
	C2288.958252,2841.198975 2296.421631,2845.128662 2303.590576,2846.450195 
	C2310.825195,2847.783691 2317.874023,2849.427246 2326.088623,2847.629150 
	C2326.526855,2844.372070 2322.462158,2840.215332 2326.656494,2837.228760 
	C2330.269043,2834.656738 2334.272461,2835.911621 2338.220703,2835.999756 
	C2355.170654,2836.377686 2371.910156,2838.974854 2388.750977,2840.490479 
	C2405.174561,2841.968506 2421.177490,2838.979736 2436.860352,2834.446533 
	C2443.362549,2832.567383 2449.686523,2831.766846 2456.451416,2832.440918 
	C2466.785889,2833.470703 2476.957275,2831.945312 2487.620850,2828.530273 
	C2491.463379,2827.550293 2494.598145,2827.838623 2496.976562,2829.787109 
	C2507.865723,2838.707275 2521.609863,2841.307129 2533.999512,2846.890137 
	C2538.388672,2848.868164 2542.973877,2849.089355 2547.668213,2849.242432 
	C2551.224365,2849.358154 2556.187256,2849.135986 2556.327148,2853.831543 
	C2556.480713,2858.977295 2551.225098,2859.647949 2547.408447,2859.734131 
	C2538.094971,2859.945312 2528.773193,2859.458984 2519.493896,2858.351562 
	C2490.427490,2854.883301 2461.072754,2854.103516 2432.261475,2848.324951 
	C2449.567383,2851.864258 2466.823486,2855.452881 2484.194092,2858.413330 
	C2487.789307,2859.025879 2491.336670,2860.140137 2494.738525,2861.492432 
	C2497.671875,2862.658691 2502.209961,2863.395264 2501.491943,2867.372559 
	C2500.815430,2871.120361 2496.313477,2870.912109 2493.080322,2871.130371 
	C2476.982178,2872.217285 2461.264404,2869.863281 2445.600342,2866.228516 
	C2429.442627,2862.478760 2412.743896,2862.254639 2396.433105,2859.327148 
	C2407.431396,2863.562012 2419.028076,2864.030762 2430.521240,2864.526367 
	C2441.328613,2864.992432 2451.688232,2866.778809 2461.288818,2871.792236 
	C2465.939941,2874.220703 2470.803955,2874.617188 2475.819824,2874.635254 
	C2491.149658,2874.689941 2506.493652,2875.069336 2521.806396,2874.560059 
	C2545.294189,2873.779053 2568.278564,2877.758545 2591.313232,2881.169678 
	C2600.626709,2882.548828 2609.893066,2882.592773 2619.212646,2882.635986 
	C2621.188965,2882.645264 2623.541016,2883.006104 2624.918945,2881.419678 
	C2630.830322,2874.615234 2638.072998,2876.926025 2644.277344,2879.556152 
	C2655.460938,2884.297119 2667.006348,2886.715576 2678.911865,2888.230957 
	C2683.169922,2888.772705 2687.529785,2889.564697 2691.455078,2893.344482 
	C2692.070557,2893.874023 2692.015381,2894.054443 2691.975586,2894.139648 
z"
        />
        <path
          fill="#3C556B"
          opacity="1.000000"
          stroke="none"
          d="
M2655.633789,2904.643799 
	C2658.201660,2904.087646 2660.288818,2904.089355 2663.158691,2904.079102 
	C2664.306885,2903.786133 2664.672607,2903.505371 2665.571289,2903.665527 
	C2667.064941,2904.613525 2668.025635,2905.120850 2669.502930,2905.797852 
	C2683.363037,2913.820557 2698.496094,2914.011963 2713.203369,2915.831055 
	C2717.173584,2916.322021 2721.072998,2916.814209 2724.966309,2917.769531 
	C2730.070312,2919.021729 2734.988281,2919.590088 2740.568115,2918.584229 
	C2749.119141,2917.042969 2757.878662,2919.481201 2765.655029,2923.282959 
	C2771.209717,2925.998535 2776.829834,2927.410645 2782.774902,2927.888428 
	C2788.065918,2928.313477 2793.388184,2927.936035 2798.583008,2929.647949 
	C2801.882568,2930.735107 2805.152344,2929.381104 2809.093750,2928.209473 
	C2810.233154,2932.189941 2813.253662,2934.225586 2816.192627,2936.366455 
	C2817.298828,2937.172363 2817.992920,2938.376709 2817.382080,2939.766357 
	C2816.443848,2941.899658 2814.747559,2941.262451 2813.288086,2940.523682 
	C2806.808838,2937.244873 2800.072754,2937.375000 2792.613770,2939.356445 
	C2791.125732,2939.891113 2790.235107,2939.906494 2788.677246,2939.939453 
	C2781.752930,2940.354492 2775.680176,2943.720459 2768.563965,2940.692871 
	C2765.788818,2940.197021 2763.687500,2940.127930 2760.797119,2940.057617 
	C2756.827393,2941.086670 2753.618896,2940.963135 2749.602539,2940.086426 
	C2718.982910,2932.337646 2689.084229,2925.186768 2657.387207,2923.245605 
	C2654.901855,2922.903564 2653.229736,2922.765137 2650.756836,2922.333984 
	C2646.721436,2920.415771 2642.209717,2919.715576 2644.111816,2913.678223 
	C2647.121582,2911.223389 2650.089111,2911.265869 2653.027344,2911.093750 
	C2655.947998,2910.923096 2658.724365,2912.268799 2661.651367,2911.891357 
	C2659.243652,2912.073975 2657.366455,2911.062988 2655.436035,2908.698730 
	C2654.842041,2907.108887 2654.871338,2906.151855 2655.633789,2904.643799 
z"
        />
        <path
          fill="#294159"
          opacity="1.000000"
          stroke="none"
          d="
M2724.677979,2895.910400 
	C2724.462891,2893.246338 2726.185059,2892.134277 2728.787109,2892.044434 
	C2739.233643,2891.683105 2749.633057,2891.918945 2759.851562,2894.435547 
	C2761.905518,2894.941162 2763.180664,2896.381836 2764.015381,2899.102539 
	C2751.040771,2901.223633 2738.360107,2897.867676 2724.677979,2895.910400 
z"
        />
        <path
          fill="#3C556B"
          opacity="1.000000"
          stroke="none"
          d="
M2692.089355,2893.081543 
	C2680.011719,2891.779785 2667.850830,2890.131104 2655.936768,2887.405029 
	C2649.895020,2886.022461 2644.554443,2882.137939 2638.217285,2880.961182 
	C2634.903564,2880.345947 2631.716553,2879.787109 2628.466309,2880.273193 
	C2626.586182,2881.910889 2634.509766,2886.061279 2627.116943,2885.885010 
	C2614.859863,2885.592529 2602.661865,2886.361816 2590.326660,2884.401855 
	C2566.457520,2880.609375 2542.513916,2876.823486 2518.121094,2877.947266 
	C2505.826660,2878.513672 2493.482178,2878.053467 2481.161133,2877.948486 
	C2472.338623,2877.873291 2463.640869,2878.934082 2455.603516,2872.609131 
	C2451.547607,2869.417236 2444.908691,2868.232910 2439.476562,2868.329346 
	C2428.104736,2868.530762 2417.008789,2865.911133 2405.725342,2866.056641 
	C2398.952148,2866.144043 2393.870361,2862.452637 2388.512207,2859.289551 
	C2387.461182,2858.668945 2386.552734,2857.392578 2387.065918,2855.881836 
	C2387.615479,2854.263916 2388.999512,2854.319092 2390.327148,2854.513916 
	C2408.295898,2857.151611 2426.482666,2858.812744 2444.168213,2862.732422 
	C2462.515381,2866.798584 2480.776367,2868.770020 2499.589844,2867.065918 
	C2473.616699,2857.852539 2445.857910,2856.201660 2419.378906,2849.175293 
	C2419.874512,2844.966309 2422.814941,2846.182617 2424.777832,2846.052246 
	C2440.497070,2845.003906 2455.871582,2847.729004 2471.155762,2850.835449 
	C2474.791260,2851.574219 2478.349121,2852.138428 2481.998779,2852.007812 
	C2495.666260,2851.519775 2509.043945,2854.161865 2522.551025,2855.324463 
	C2533.099609,2856.231934 2544.076904,2858.823242 2555.555420,2854.803223 
	C2552.544922,2850.846924 2548.946045,2851.663086 2546.173584,2852.500244 
	C2542.656738,2853.561768 2539.431396,2853.281738 2536.525635,2851.772949 
	C2526.219482,2846.420654 2514.839600,2843.878418 2504.324463,2839.113525 
	C2501.870605,2838.001465 2499.659912,2836.677979 2498.065918,2834.357666 
	C2496.040771,2831.408447 2493.000244,2830.187012 2488.743164,2830.025879 
	C2485.541748,2828.861084 2484.576660,2827.011963 2485.895508,2823.707031 
	C2493.551270,2820.563232 2500.333496,2822.242920 2506.742188,2825.112549 
	C2524.984863,2833.281006 2543.773438,2834.547852 2563.124023,2830.395996 
	C2568.675049,2829.205078 2574.382568,2827.482666 2578.029785,2834.870117 
	C2579.118896,2841.443604 2584.178223,2841.230225 2588.125732,2841.793701 
	C2593.804932,2842.604492 2598.850098,2844.886475 2605.272949,2846.765381 
	C2600.093994,2851.649902 2594.759033,2850.236328 2590.080811,2849.446045 
	C2585.163574,2848.615967 2580.458496,2847.234375 2574.626709,2849.233398 
	C2577.802734,2853.453369 2582.061279,2854.311768 2586.030273,2853.824463 
	C2595.682129,2852.639893 2603.831543,2857.691406 2612.697754,2859.602783 
	C2627.978027,2862.896973 2643.106934,2865.875732 2659.578125,2862.414062 
	C2672.082031,2873.261719 2687.198486,2872.596924 2701.444092,2874.651855 
	C2711.104492,2876.045410 2721.330078,2874.431885 2730.041748,2881.173584 
	C2729.195312,2882.546631 2728.342773,2883.633789 2727.592529,2883.567139 
	C2716.868652,2882.615967 2707.217041,2888.857910 2696.527832,2888.108643 
	C2693.805908,2887.917480 2692.266846,2889.503662 2692.089355,2893.081543 
z"
        />
        <path
          fill="#1F3041"
          opacity="1.000000"
          stroke="none"
          d="
M2730.823242,2882.003418 
	C2721.173828,2879.122070 2712.032959,2877.876953 2702.844971,2878.141357 
	C2691.682861,2878.462402 2681.309570,2874.553955 2670.560547,2872.795410 
	C2666.358154,2872.107910 2660.324219,2870.615967 2660.331543,2863.130859 
	C2661.805664,2862.390869 2663.181152,2862.349854 2665.250488,2862.313965 
	C2681.636719,2862.222900 2697.110840,2865.849121 2713.530762,2864.632324 
	C2726.208252,2866.759766 2738.520264,2867.139160 2750.541504,2869.700195 
	C2755.028320,2870.656250 2759.682617,2871.236328 2765.049805,2872.265381 
	C2768.921631,2877.649658 2774.363037,2879.359375 2779.578369,2880.686035 
	C2786.537354,2882.456543 2792.941162,2886.124756 2800.286133,2886.587891 
	C2801.359375,2886.655518 2802.348877,2888.056641 2803.780273,2889.488770 
	C2794.597656,2891.066650 2785.389160,2887.539551 2774.986084,2887.712646 
	C2770.413818,2887.652100 2766.948730,2886.230469 2762.620117,2885.838135 
	C2759.195801,2885.771973 2756.454102,2885.776367 2752.889648,2885.773193 
	C2750.878418,2885.872070 2749.787109,2885.619873 2748.395996,2884.503418 
	C2742.526367,2883.416748 2737.086426,2882.713867 2730.823242,2882.003418 
z"
        />
        <path
          fill="#344F68"
          opacity="1.000000"
          stroke="none"
          d="
M2838.896973,2900.234619 
	C2838.523438,2897.368164 2834.639648,2896.225098 2835.579590,2892.668213 
	C2851.506104,2898.824707 2868.674316,2896.758545 2885.437500,2901.473633 
	C2884.582275,2903.658447 2882.677002,2904.125488 2880.668213,2903.958252 
	C2871.616943,2903.205322 2862.702637,2906.061523 2853.475098,2904.268311 
	C2848.662842,2903.333252 2843.873047,2903.048340 2838.896973,2900.234619 
z"
        />
        <path
          fill="#3C556B"
          opacity="1.000000"
          stroke="none"
          d="
M2762.354492,2886.348633 
	C2765.935547,2883.036621 2769.719727,2883.344971 2773.703125,2887.125977 
	C2770.020264,2892.061523 2766.411133,2889.265625 2762.354492,2886.348633 
z"
        />
        <path
          fill="#3C556B"
          opacity="1.000000"
          stroke="none"
          d="
M2748.053223,2884.683105 
	C2749.073242,2884.382324 2750.180176,2884.645264 2751.677002,2885.336914 
	C2750.456787,2887.875732 2749.142334,2887.742188 2748.053223,2884.683105 
z"
        />
        <path
          fill="#EEE7D5"
          opacity="1.000000"
          stroke="none"
          d="
M71.654411,1692.946533 
	C74.395386,1689.712402 76.287437,1690.975586 77.436485,1693.322632 
	C78.105804,1694.689941 77.930977,1696.553345 77.867516,1698.183105 
	C77.415276,1709.794678 76.865120,1721.402344 76.435707,1733.014771 
	C76.389214,1734.271973 76.905716,1735.552002 77.176178,1736.818359 
	C77.690651,1739.227539 79.279320,1742.656372 75.350525,1742.836670 
	C72.116898,1742.985107 71.753510,1739.443237 72.046913,1736.750244 
	C72.718834,1730.582764 71.975296,1724.452515 73.171326,1718.139282 
	C74.403976,1711.632812 71.367714,1704.598022 72.063499,1696.757568 
	C71.331856,1695.208008 71.036423,1694.401489 71.654411,1692.946533 
z"
        />
        <path
          fill="#E9E1D0"
          opacity="1.000000"
          stroke="none"
          d="
M67.682693,1722.313110 
	C67.586800,1728.217285 64.330345,1733.208008 67.726921,1738.479126 
	C68.507797,1739.690918 67.565086,1742.013428 65.610527,1742.341919 
	C62.853428,1742.805420 61.690819,1740.093262 62.354527,1738.610352 
	C65.475609,1731.637695 60.464333,1724.315186 63.752728,1717.335449 
	C65.021027,1714.643555 63.678688,1711.492432 62.618332,1708.624512 
	C62.025272,1707.020142 62.305096,1705.271484 64.295105,1704.574585 
	C64.848137,1704.380859 65.670319,1704.422607 66.169914,1704.706665 
	C67.874306,1705.675659 68.328598,1707.500244 67.558319,1708.988770 
	C65.362732,1713.231201 65.775497,1717.327271 67.682693,1722.313110 
z"
        />
        <path
          fill="#E1D9C6"
          opacity="1.000000"
          stroke="none"
          d="
M82.096512,1703.575195 
	C82.084923,1699.749756 81.964897,1696.777222 81.908127,1693.803589 
	C81.878616,1692.257690 82.835075,1691.131714 84.227028,1691.141724 
	C85.994591,1691.154541 87.902611,1692.477295 87.357864,1694.174316 
	C84.576004,1702.840088 89.176033,1712.263794 83.994293,1721.980225 
	C81.609581,1715.563110 82.709557,1709.859131 82.096512,1703.575195 
z"
        />
        <path
          fill="#EAE5D5"
          opacity="1.000000"
          stroke="none"
          d="
M94.139252,1704.874756 
	C98.183266,1710.272217 93.077873,1715.117676 93.979942,1720.308716 
	C93.731102,1715.092285 87.516571,1710.165405 94.139252,1704.874756 
z"
        />
        <path
          fill="#E5DDC9"
          opacity="1.000000"
          stroke="none"
          d="
M71.394524,1692.366089 
	C72.115608,1693.210815 72.092285,1693.966309 72.062592,1695.289551 
	C69.748070,1696.505127 66.636757,1698.349121 64.586723,1700.541626 
	C64.527054,1697.061890 60.501202,1694.537720 63.378799,1691.356201 
	C65.680382,1688.811157 68.181557,1692.026611 71.394524,1692.366089 
z"
        />
        <path
          fill="#EAE5D5"
          opacity="1.000000"
          stroke="none"
          d="
M95.282486,1692.901855 
	C96.429108,1695.574707 96.033394,1697.293823 93.431473,1697.262207 
	C92.163498,1697.246826 91.006325,1696.044800 90.955101,1694.751099 
	C90.845940,1691.994019 92.704567,1691.890991 95.282486,1692.901855 
z"
        />
        <path
          fill="#516D85"
          opacity="1.000000"
          stroke="none"
          d="
M2542.002930,2547.524414 
	C2520.793457,2545.618164 2499.519287,2545.969727 2478.256836,2546.026123 
	C2474.088135,2546.037109 2469.416992,2544.861816 2467.161621,2550.274170 
	C2466.635254,2551.537354 2464.989014,2551.864502 2463.648682,2551.419678 
	C2457.084229,2549.242188 2449.747314,2550.402588 2443.388184,2546.671387 
	C2443.331787,2543.136230 2447.104736,2544.745850 2448.328125,2543.084473 
	C2449.392334,2541.639893 2448.577393,2540.740723 2446.558594,2540.065674 
	C2444.049805,2533.665039 2448.850098,2533.817871 2452.539062,2533.420166 
	C2455.448730,2533.106689 2458.228760,2534.501953 2461.865234,2535.630127 
	C2472.466797,2540.292480 2482.647461,2542.095215 2493.194092,2540.109375 
	C2508.411377,2537.244141 2523.286133,2540.714600 2538.275146,2542.114014 
	C2539.559814,2542.234131 2540.526367,2543.406250 2541.715820,2545.254395 
	C2542.061279,2546.068115 2542.025391,2547.039307 2542.002930,2547.524414 
z"
        />
        <path
          fill="#516D85"
          opacity="1.000000"
          stroke="none"
          d="
M2469.601074,2534.873047 
	C2476.156494,2526.995117 2484.324951,2529.762695 2491.431152,2530.580322 
	C2499.169189,2531.470703 2506.597168,2535.147705 2514.612793,2532.116943 
	C2515.185547,2531.900635 2515.969971,2531.978271 2516.580078,2532.166016 
	C2527.068359,2535.396729 2537.970947,2535.745850 2549.367432,2537.381836 
	C2549.971436,2538.272461 2549.973877,2538.623535 2549.968506,2539.501465 
	C2541.357422,2545.798584 2531.845947,2542.089355 2523.121094,2541.433350 
	C2511.556885,2540.563477 2500.166748,2539.756836 2488.608154,2540.909424 
	C2482.675781,2541.500977 2476.751953,2540.653076 2470.317383,2538.298828 
	C2469.496094,2536.992432 2469.349121,2536.369385 2469.601074,2534.873047 
z"
        />
        <path
          fill="#49647C"
          opacity="1.000000"
          stroke="none"
          d="
M2469.350098,2535.599854 
	C2471.467285,2537.709473 2473.411621,2538.075928 2475.449707,2537.963379 
	C2499.724365,2536.622314 2523.840576,2540.823242 2549.029541,2540.023926 
	C2551.570801,2540.358643 2553.283936,2540.661133 2553.476074,2542.719482 
	C2553.666504,2544.756348 2552.310059,2545.663574 2550.539307,2545.817871 
	C2548.270996,2546.015381 2545.982178,2545.976807 2542.881836,2546.053955 
	C2540.522217,2545.450928 2538.972900,2544.275391 2537.445312,2544.302979 
	C2521.640381,2544.587158 2506.102539,2539.864014 2489.961426,2543.493896 
	C2481.003906,2545.508545 2471.242676,2543.158447 2462.164062,2538.718506 
	C2463.226318,2534.673096 2466.004639,2534.978760 2469.350098,2535.599854 
z"
        />
        <path
          fill="#516D85"
          opacity="1.000000"
          stroke="none"
          d="
M2558.054688,2540.716797 
	C2558.939941,2539.964111 2559.797852,2539.967529 2561.298584,2539.970703 
	C2561.984131,2540.296631 2562.095703,2540.631104 2562.057861,2540.947266 
	C2561.794922,2543.130127 2565.430664,2546.565186 2561.182617,2547.264648 
	C2557.314209,2547.901611 2558.291504,2543.902832 2558.054688,2540.716797 
z"
        />
        <path
          fill="#395166"
          opacity="1.000000"
          stroke="none"
          d="
M2485.460938,2823.666992 
	C2486.530029,2825.794434 2487.021484,2827.333984 2487.756348,2829.436523 
	C2487.613037,2832.174561 2486.521973,2833.784912 2484.160400,2833.834961 
	C2475.548340,2834.017822 2467.064697,2837.621338 2458.497314,2835.826904 
	C2449.384521,2833.918213 2441.065674,2836.535889 2432.715820,2838.891846 
	C2426.604248,2840.615967 2420.263916,2840.888916 2414.292480,2842.306396 
	C2395.067383,2846.870361 2376.507324,2842.115723 2357.757568,2839.986816 
	C2348.911377,2838.982666 2339.882324,2841.318115 2331.163818,2838.211426 
	C2329.737305,2837.702881 2327.724854,2838.062256 2326.870605,2839.854492 
	C2325.714111,2842.282471 2327.567383,2843.410156 2329.168945,2844.622314 
	C2331.217529,2846.172852 2333.225342,2847.777344 2335.593994,2849.626709 
	C2318.692871,2855.012939 2303.334961,2849.061035 2287.747803,2846.788330 
	C2286.082764,2846.545654 2284.533447,2846.039307 2282.467285,2847.509766 
	C2279.815430,2850.097656 2277.391602,2849.253906 2274.297852,2848.507812 
	C2264.946289,2843.425537 2258.848145,2835.209717 2249.581055,2829.986328 
	C2242.291748,2824.480225 2233.918457,2822.801025 2226.088867,2818.429688 
	C2223.679443,2816.728027 2222.106201,2815.053467 2219.490234,2813.648926 
	C2214.463379,2809.633057 2210.461426,2805.237305 2203.642334,2804.158936 
	C2197.758545,2801.468506 2192.590576,2798.914795 2188.754883,2793.378418 
	C2186.852051,2787.024902 2181.640381,2787.053955 2176.648926,2785.299316 
	C2175.656982,2784.471924 2175.323730,2784.054443 2174.755371,2782.849854 
	C2174.338867,2777.918213 2176.950439,2776.212402 2180.448486,2775.306641 
	C2182.385010,2774.805420 2184.383301,2774.530273 2186.361816,2774.644775 
	C2205.749512,2775.768555 2225.032959,2778.720215 2245.242676,2778.217773 
	C2248.522461,2780.064209 2251.010254,2782.471924 2254.692871,2778.902344 
	C2258.970215,2777.344727 2262.620605,2778.105225 2266.254883,2778.312744 
	C2270.578857,2778.559326 2275.023193,2779.123047 2279.168457,2777.865479 
	C2306.439697,2769.593262 2333.578125,2776.343262 2361.435059,2778.834473 
	C2364.192871,2787.844238 2357.337158,2786.450439 2353.011719,2787.176514 
	C2334.887939,2790.219482 2316.569580,2789.291504 2298.350098,2788.813721 
	C2278.309326,2788.288086 2258.792725,2792.539551 2238.991943,2794.081299 
	C2232.923340,2794.553955 2226.653320,2794.830811 2220.692383,2796.880371 
	C2226.105713,2799.076660 2231.965088,2799.604248 2236.791260,2797.041016 
	C2246.576172,2791.843994 2256.639160,2792.238281 2266.885010,2793.735596 
	C2273.411133,2794.689209 2279.186768,2793.350098 2285.206055,2790.820557 
	C2295.505127,2786.491699 2302.070068,2788.535889 2305.939697,2796.976562 
	C2300.429932,2803.103027 2302.102783,2807.173340 2308.087891,2811.130859 
	C2309.882324,2818.792969 2314.815186,2822.497803 2320.439453,2820.654297 
	C2331.274658,2817.103271 2342.466553,2816.398682 2353.703857,2816.049072 
	C2360.692871,2815.831543 2367.694092,2816.018066 2374.689697,2815.996094 
	C2376.686035,2815.989746 2378.960205,2816.497803 2380.633545,2815.733398 
	C2392.782227,2810.183350 2405.351074,2815.834961 2417.614746,2814.113525 
	C2419.790039,2813.808594 2421.347168,2815.231445 2423.185547,2817.386230 
	C2427.349365,2820.738281 2431.689209,2820.635010 2435.929932,2821.004150 
	C2452.217773,2822.423096 2468.472900,2824.567383 2485.460938,2823.666992 
z"
        />
        <path
          fill="#21364A"
          opacity="1.000000"
          stroke="none"
          d="
M2362.869141,2780.776123 
	C2342.366211,2780.377930 2322.738281,2777.257568 2302.965088,2777.942627 
	C2299.927734,2778.047852 2297.100098,2777.189697 2294.190430,2776.542480 
	C2292.328369,2776.128418 2289.418457,2775.709961 2288.453125,2776.712891 
	C2279.202881,2786.324463 2268.398438,2781.337646 2258.011963,2780.422852 
	C2257.065430,2780.339355 2256.081299,2780.688232 2254.550293,2781.316895 
	C2252.641357,2783.195557 2251.040771,2783.483887 2248.540039,2782.377686 
	C2242.194336,2778.997803 2236.294678,2779.603271 2230.468262,2780.903076 
	C2223.569336,2782.442139 2216.821533,2782.571777 2210.032715,2780.354980 
	C2200.766602,2777.329834 2191.165527,2777.994873 2181.627930,2778.035400 
	C2178.707031,2778.047852 2176.376465,2778.730957 2176.020264,2782.996582 
	C2173.080322,2785.260010 2170.895996,2783.441162 2168.728760,2782.092773 
	C2157.284424,2774.970459 2146.328369,2767.114746 2135.320801,2759.353516 
	C2132.060059,2757.053955 2127.987549,2754.382568 2131.127930,2748.581055 
	C2144.125244,2745.851318 2156.192383,2749.192627 2168.332031,2750.099854 
	C2183.872559,2751.261475 2199.254150,2754.697998 2215.802490,2752.849365 
	C2224.589600,2751.947510 2232.471680,2750.429932 2241.240234,2749.789307 
	C2250.916260,2750.899902 2259.589111,2748.956299 2269.202637,2747.910889 
	C2275.030518,2747.797607 2280.018799,2748.454102 2284.888672,2746.777832 
	C2288.886230,2745.401367 2292.702881,2747.069336 2296.617188,2747.676758 
	C2307.066650,2749.298096 2317.566406,2750.596191 2328.045410,2752.029053 
	C2329.683838,2752.253174 2331.482666,2752.149658 2332.928223,2752.808838 
	C2346.360107,2758.934814 2361.143799,2759.556152 2375.197510,2763.138428 
	C2383.170410,2765.170654 2391.709473,2764.949707 2399.872803,2762.949951 
	C2405.172852,2761.651855 2410.593750,2761.297852 2415.665771,2762.520020 
	C2432.775146,2766.642090 2450.359375,2767.190674 2467.613037,2770.101807 
	C2469.883789,2770.484619 2472.095459,2771.218018 2475.126953,2771.914307 
	C2475.357666,2776.486084 2472.397217,2775.807861 2468.673828,2774.596191 
	C2461.250488,2774.098389 2454.569092,2774.088135 2446.977295,2774.108887 
	C2441.720215,2778.460938 2436.262695,2779.021484 2430.515869,2778.921143 
	C2415.057129,2778.651367 2399.683105,2776.531738 2384.193359,2776.898193 
	C2379.872559,2777.000488 2375.581787,2776.843018 2371.551758,2780.875488 
	C2368.383301,2782.320801 2365.998535,2782.395752 2362.869141,2780.776123 
z"
        />
        <path
          fill="#334D63"
          opacity="1.000000"
          stroke="none"
          d="
M2754.361816,2845.504395 
	C2745.211914,2847.163330 2736.913574,2843.422607 2728.271484,2843.874756 
	C2727.039307,2843.939209 2725.325684,2843.601318 2724.579590,2842.776123 
	C2718.000732,2835.502197 2709.286133,2835.822754 2700.729004,2836.045410 
	C2685.527588,2836.441162 2670.290771,2834.854004 2654.478027,2837.401367 
	C2650.839355,2841.510986 2647.138184,2840.019775 2642.732666,2839.019287 
	C2638.030029,2834.003174 2633.125488,2833.187744 2627.117676,2832.605225 
	C2610.299805,2830.973877 2593.683350,2828.015381 2576.700928,2826.926270 
	C2556.121582,2825.606934 2535.409668,2826.502930 2514.928467,2823.444092 
	C2507.317139,2822.307129 2499.766113,2821.367432 2493.940918,2814.790039 
	C2494.695312,2809.787354 2497.885742,2810.136719 2500.901855,2810.015137 
	C2510.260742,2809.638428 2519.298828,2812.002441 2528.480713,2813.140625 
	C2534.119141,2813.839844 2540.463135,2816.878418 2542.937012,2807.888672 
	C2543.601807,2805.473389 2546.936035,2805.904053 2549.168945,2806.762695 
	C2552.708740,2808.123291 2556.333008,2808.122803 2559.990967,2807.873291 
	C2561.749512,2807.753418 2563.445801,2807.077881 2563.045654,2804.901367 
	C2562.160645,2800.091309 2564.475586,2799.731689 2568.100830,2801.042969 
	C2571.926514,2802.426758 2575.160645,2802.085449 2577.821533,2798.555420 
	C2581.593262,2793.552002 2586.571777,2793.832764 2592.208008,2794.954102 
	C2607.629883,2798.022705 2623.153076,2800.584473 2639.015381,2803.414795 
	C2638.634766,2798.031982 2635.388428,2798.258301 2632.473145,2798.023926 
	C2629.505615,2797.785156 2626.428711,2797.983643 2623.596680,2797.239746 
	C2620.597168,2796.451660 2619.774414,2793.729248 2620.298584,2790.820312 
	C2620.864746,2787.678955 2622.687744,2786.331543 2626.008545,2787.035645 
	C2633.594727,2788.644531 2641.062256,2788.273926 2648.664795,2786.432373 
	C2654.877197,2784.927490 2660.985596,2786.555908 2667.932129,2789.975342 
	C2661.212402,2793.721924 2655.161133,2791.467285 2649.251953,2790.801025 
	C2648.995117,2795.275146 2651.693848,2795.782471 2654.317871,2795.884521 
	C2663.748291,2796.250977 2672.408936,2798.845459 2680.992432,2802.885254 
	C2685.891602,2805.190918 2691.911865,2805.354492 2697.484375,2805.915527 
	C2702.124512,2806.382568 2706.444092,2807.202637 2711.444336,2810.581299 
	C2705.108887,2813.482910 2698.995117,2810.685547 2692.387939,2813.365723 
	C2698.196045,2816.708984 2702.901367,2819.126221 2708.266357,2817.466797 
	C2714.738037,2815.464844 2720.625244,2814.876221 2725.689453,2820.395996 
	C2728.080811,2823.002441 2732.310547,2820.733398 2735.016357,2824.062500 
	C2732.857178,2826.923340 2729.508545,2825.510986 2726.934326,2826.313721 
	C2725.449463,2826.776855 2724.580322,2827.749023 2724.655029,2829.134521 
	C2724.730713,2830.537109 2725.781006,2831.665527 2727.252197,2831.652588 
	C2736.973633,2831.568359 2746.192871,2835.692871 2755.694824,2835.704346 
	C2764.891602,2835.715332 2773.975342,2838.234131 2783.294434,2836.381592 
	C2787.209961,2835.603516 2791.376709,2837.039307 2791.887451,2843.139160 
	C2781.740234,2845.193359 2771.930664,2841.143311 2760.922607,2842.041748 
	C2758.691162,2843.746338 2756.450439,2843.903564 2754.361816,2845.504395 
z"
        />
        <path
          fill="#4E6477"
          opacity="1.000000"
          stroke="none"
          d="
M2361.857666,2780.969727 
	C2364.226318,2780.184326 2366.332275,2780.135010 2369.229980,2780.105469 
	C2372.520020,2779.730469 2374.953369,2778.816162 2378.319824,2779.207520 
	C2384.710449,2779.708496 2390.260986,2782.638672 2396.783203,2779.489746 
	C2413.203857,2777.724609 2428.931641,2778.591309 2445.264893,2777.494629 
	C2447.632568,2779.897949 2449.275146,2781.805664 2450.906494,2783.700684 
	C2449.937744,2786.829346 2447.282471,2785.956543 2445.272949,2785.985352 
	C2440.881836,2786.048584 2439.875732,2788.626709 2439.967041,2793.083740 
	C2429.800781,2798.984863 2419.375977,2794.577393 2408.254395,2794.713867 
	C2393.541504,2793.259766 2379.789551,2795.505371 2365.174072,2796.352783 
	C2349.030762,2797.516846 2333.760986,2799.489502 2317.591309,2799.875000 
	C2313.045654,2801.099854 2309.288330,2802.200439 2304.756348,2800.343262 
	C2304.424072,2794.631104 2302.793213,2791.559814 2296.817139,2791.971680 
	C2291.864014,2792.312988 2287.104980,2791.818604 2282.868408,2795.760254 
	C2278.529785,2799.796631 2272.557129,2798.566650 2267.574951,2797.304932 
	C2261.253662,2795.703613 2255.011719,2796.280029 2248.729492,2796.028809 
	C2246.737305,2795.948975 2244.415039,2795.756348 2243.046387,2797.331055 
	C2235.722900,2805.757568 2226.321777,2801.224121 2217.759521,2801.371094 
	C2215.372803,2801.412109 2212.261963,2799.951660 2212.536377,2796.474121 
	C2212.794922,2793.204346 2215.409912,2792.075684 2218.256836,2792.102539 
	C2226.251465,2792.177979 2234.107178,2791.234375 2242.025391,2790.140137 
	C2255.789795,2788.238281 2269.712646,2787.468262 2283.465332,2785.500977 
	C2287.539307,2784.918457 2291.401855,2785.926025 2295.080566,2785.538330 
	C2310.866455,2783.873291 2326.741455,2788.427002 2342.475830,2784.418701 
	C2346.250488,2783.457275 2350.427490,2784.112305 2354.416260,2783.942383 
	C2356.987793,2783.832764 2359.868652,2784.580078 2361.857666,2780.969727 
z"
        />
        <path
          fill="#233A4F"
          opacity="1.000000"
          stroke="none"
          d="
M2493.187012,2813.924805 
	C2502.181152,2817.072998 2510.343262,2819.451416 2519.353760,2820.423828 
	C2533.300781,2821.929443 2547.287109,2820.684326 2561.056396,2822.250732 
	C2581.055420,2824.526123 2601.541992,2822.862549 2621.056152,2829.308838 
	C2624.591309,2830.476562 2628.199707,2829.936768 2631.860596,2829.113281 
	C2639.759766,2827.335693 2640.321533,2827.874512 2641.954102,2837.162598 
	C2642.229004,2838.262695 2642.511719,2838.564697 2642.351562,2839.301758 
	C2638.600098,2839.713135 2635.291504,2839.689941 2631.157959,2839.617920 
	C2616.398438,2834.471924 2601.986328,2835.027344 2586.766357,2835.924316 
	C2583.854492,2835.879395 2581.718750,2835.863770 2578.782715,2835.787109 
	C2574.858887,2830.649414 2569.416748,2830.719727 2565.663574,2832.782471 
	C2555.199219,2838.533203 2544.249756,2835.685791 2533.617188,2835.506836 
	C2528.239746,2835.416016 2522.514893,2834.381836 2517.023926,2833.293945 
	C2514.758545,2832.845459 2511.745850,2832.937256 2510.420898,2831.521729 
	C2504.087646,2824.756592 2495.349609,2826.236572 2486.853516,2824.214600 
	C2483.284180,2823.919434 2480.802734,2824.496582 2478.593018,2826.269775 
	C2476.047607,2828.312256 2472.921387,2828.442627 2470.039795,2827.821289 
	C2458.372559,2825.305420 2446.375732,2826.021240 2434.674561,2823.968262 
	C2430.802734,2823.288818 2426.789795,2822.961670 2423.977295,2818.632568 
	C2433.497314,2818.231689 2443.004883,2816.567871 2453.247803,2814.300293 
	C2456.895508,2813.302002 2459.820068,2812.686523 2463.364990,2811.931641 
	C2468.086426,2811.902344 2472.168457,2811.936279 2477.125000,2811.979736 
	C2480.402832,2811.717285 2482.842041,2811.194580 2485.506836,2813.457031 
	C2488.179199,2813.921387 2490.288086,2813.927490 2493.187012,2813.924805 
z"
        />
        <path
          fill="#344F68"
          opacity="1.000000"
          stroke="none"
          d="
M2467.916504,2774.859131 
	C2470.279053,2773.797607 2472.760742,2774.394287 2475.438232,2772.553223 
	C2480.528564,2771.994141 2485.104248,2771.166016 2487.271973,2777.358154 
	C2488.546875,2780.999512 2493.169678,2779.436768 2496.212158,2780.439453 
	C2499.540039,2781.535889 2502.505859,2782.665771 2504.526123,2778.297607 
	C2505.922852,2775.277588 2509.082275,2776.256348 2511.642334,2776.004639 
	C2521.340820,2775.051514 2530.699951,2778.889160 2540.400635,2778.013672 
	C2542.714111,2777.804932 2545.062256,2778.010254 2547.389160,2777.907959 
	C2549.511963,2777.814697 2551.430420,2777.203369 2551.422119,2774.588135 
	C2551.414795,2772.259521 2549.771240,2770.462158 2547.809814,2770.495850 
	C2541.609619,2770.602783 2535.589355,2766.712402 2529.272705,2769.797119 
	C2522.153076,2773.273682 2514.954346,2770.338867 2507.803955,2769.801270 
	C2504.182129,2769.529053 2504.114014,2766.579590 2503.900879,2763.070801 
	C2511.550293,2757.776611 2519.709229,2762.288574 2527.603516,2761.077637 
	C2532.632812,2760.305908 2538.045898,2762.396973 2543.375488,2758.510986 
	C2548.341309,2754.348389 2553.484619,2756.677979 2559.080078,2756.049805 
	C2563.631592,2760.357422 2556.877441,2761.475342 2557.575195,2765.343262 
	C2560.763184,2769.092041 2564.604248,2767.896973 2568.091797,2767.995361 
	C2573.261963,2768.141846 2578.139648,2769.057861 2583.954102,2771.985352 
	C2577.345459,2775.757568 2570.689697,2772.365479 2565.034668,2775.182617 
	C2566.716553,2780.395020 2571.673584,2779.304688 2575.306396,2781.649902 
	C2576.231201,2786.041016 2574.511230,2788.222412 2570.200195,2787.955566 
	C2553.892822,2786.946045 2537.452637,2788.059570 2521.282959,2784.782715 
	C2516.829590,2783.880371 2512.354248,2782.349854 2509.236572,2788.078857 
	C2507.498779,2791.271973 2503.913086,2789.934570 2501.206543,2788.526123 
	C2493.674561,2784.606934 2485.403564,2784.892822 2477.905762,2786.753418 
	C2469.746582,2788.777588 2464.026611,2785.485840 2456.735596,2782.382568 
	C2460.071289,2777.967041 2467.737549,2782.818604 2467.916504,2774.859131 
z"
        />
        <path
          fill="#395166"
          opacity="1.000000"
          stroke="none"
          d="
M2407.571289,2792.544189 
	C2418.181641,2792.612305 2428.304688,2793.166260 2439.187500,2793.796631 
	C2440.916748,2794.086914 2442.093750,2794.022949 2442.818115,2794.564453 
	C2445.407959,2796.500244 2450.733643,2794.535400 2451.115479,2798.534180 
	C2451.600830,2803.619629 2446.223633,2801.185547 2443.651855,2802.523682 
	C2436.652100,2806.165527 2444.483887,2807.611084 2444.831299,2809.856445 
	C2444.283203,2811.862793 2442.804688,2811.948486 2441.583008,2811.767334 
	C2428.547363,2809.834229 2415.392822,2809.071533 2402.322998,2807.669189 
	C2392.917480,2806.660156 2384.229004,2806.437744 2375.188477,2809.539062 
	C2371.024170,2810.967529 2366.005371,2810.797363 2360.843750,2808.396484 
	C2353.863281,2804.002197 2346.908203,2805.387451 2340.066650,2805.213135 
	C2331.778564,2805.002197 2322.749512,2807.383301 2316.030273,2799.000244 
	C2318.217529,2795.405518 2321.336914,2796.217773 2324.199219,2795.958008 
	C2333.441895,2795.119629 2342.962402,2798.102295 2351.986816,2792.833984 
	C2354.402588,2791.423340 2358.860840,2793.511963 2363.182373,2794.006836 
	C2379.273193,2797.195801 2395.006348,2796.891357 2411.092041,2800.303467 
	C2407.329834,2798.518066 2405.883057,2796.395020 2407.571289,2792.544189 
z"
        />
        <path
          fill="#233A4F"
          opacity="1.000000"
          stroke="none"
          d="
M2315.257324,2798.115234 
	C2320.144287,2801.173828 2324.859619,2801.992676 2329.841309,2801.975098 
	C2336.043701,2801.953369 2342.255615,2801.840088 2348.445801,2802.138184 
	C2352.601807,2802.338379 2356.951172,2802.623291 2359.924561,2807.320557 
	C2357.335693,2808.108887 2354.553955,2808.155762 2350.942871,2808.258789 
	C2348.118408,2810.324707 2345.071289,2809.555664 2342.369873,2811.458984 
	C2331.191895,2811.872559 2320.525635,2811.878174 2308.921875,2811.851074 
	C2306.381836,2809.765137 2303.860596,2810.286133 2301.781494,2809.562012 
	C2299.379150,2808.726074 2296.386230,2807.997803 2296.460693,2804.774902 
	C2296.547119,2801.057861 2299.520508,2800.253174 2303.378662,2799.884277 
	C2307.628662,2799.469727 2310.855225,2797.538574 2315.257324,2798.115234 
z"
        />
        <path
          fill="#395166"
          opacity="1.000000"
          stroke="none"
          d="
M2378.656006,2781.438477 
	C2375.750000,2781.901367 2373.299316,2782.412842 2370.528320,2780.627930 
	C2371.482178,2775.882324 2374.108398,2773.656494 2378.942627,2773.928955 
	C2393.167236,2774.730713 2407.445068,2772.708984 2421.681641,2775.675781 
	C2429.124023,2777.226807 2436.912598,2776.182373 2445.219727,2774.116211 
	C2446.046631,2774.995117 2446.025879,2775.850342 2445.997803,2777.347656 
	C2429.989990,2779.666992 2414.102295,2782.673584 2396.987305,2782.051758 
	C2390.474609,2780.995361 2384.903076,2779.610352 2378.656006,2781.438477 
z"
        />
        <path
          fill="#334D63"
          opacity="1.000000"
          stroke="none"
          d="
M2504.273438,2799.790039 
	C2505.063721,2799.074951 2505.424316,2798.693848 2505.616699,2798.765381 
	C2513.176270,2801.569824 2521.684326,2801.389648 2528.775879,2806.054443 
	C2520.667236,2805.564209 2512.663330,2806.429443 2504.710938,2805.847168 
	C2498.588135,2805.398926 2501.710938,2802.599365 2504.273438,2799.790039 
z"
        />
        <path
          fill="#334D63"
          opacity="1.000000"
          stroke="none"
          d="
M2463.832031,2811.166016 
	C2462.761963,2817.661865 2458.776123,2816.043945 2454.557373,2814.695312 
	C2455.450928,2807.218262 2457.275391,2806.506348 2463.832031,2811.166016 
z"
        />
        <path
          fill="#334D63"
          opacity="1.000000"
          stroke="none"
          d="
M2486.032227,2813.218262 
	C2483.665039,2813.943115 2481.205322,2814.414307 2478.485840,2812.517090 
	C2482.584473,2806.767822 2483.753174,2806.845703 2486.032227,2813.218262 
z"
        />
        <path
          fill="#395166"
          opacity="1.000000"
          stroke="none"
          d="
M2342.605957,2811.908691 
	C2342.624512,2807.927490 2345.601562,2807.993408 2349.366699,2808.224121 
	C2349.314941,2812.230713 2346.276123,2811.982666 2342.605957,2811.908691 
z"
        />
        <path
          fill="#3C556B"
          opacity="1.000000"
          stroke="none"
          d="
M2586.517578,2836.462891 
	C2600.979980,2829.416504 2615.570312,2830.318604 2630.056152,2838.917236 
	C2615.845703,2839.711426 2601.234619,2841.965820 2586.517578,2836.462891 
z"
        />
        <path
          fill="#3C556B"
          opacity="1.000000"
          stroke="none"
          d="
M2713.393555,2864.010254 
	C2713.538574,2866.846924 2711.734375,2867.965332 2709.102051,2867.955322 
	C2704.123291,2867.936035 2699.030762,2868.573242 2694.194580,2867.732910 
	C2687.955322,2866.649170 2681.768311,2865.829346 2675.454346,2865.974854 
	C2672.332031,2866.046875 2669.619629,2864.923828 2666.484375,2862.839844 
	C2671.184570,2857.883301 2677.425537,2860.198486 2683.147217,2860.158447 
	C2693.051514,2860.088867 2702.871582,2861.702637 2713.393555,2864.010254 
z"
        />
        <path
          fill="#3C556B"
          opacity="1.000000"
          stroke="none"
          d="
M2642.396484,2840.327637 
	C2641.908691,2839.736572 2641.914062,2838.848877 2641.930176,2838.404785 
	C2645.349365,2837.960938 2648.752441,2837.960938 2652.995605,2837.954590 
	C2660.354004,2843.964844 2669.114014,2840.676025 2676.900635,2843.086426 
	C2669.893311,2847.592529 2658.200195,2846.730957 2642.396484,2840.327637 
z"
        />
        <path
          fill="#294159"
          opacity="1.000000"
          stroke="none"
          d="
M2762.816650,2862.101074 
	C2760.590576,2859.410889 2756.862549,2860.851074 2755.264160,2858.388672 
	C2756.100830,2854.995605 2758.895752,2856.147705 2760.930664,2856.082520 
	C2766.933350,2855.890869 2772.657227,2858.012939 2778.674072,2858.375977 
	C2783.142090,2858.645508 2780.411133,2861.295166 2778.401855,2863.462402 
	C2773.039307,2864.642578 2768.533936,2861.848389 2762.816650,2862.101074 
z"
        />
        <path
          fill="#445E74"
          opacity="1.000000"
          stroke="none"
          d="
M2754.608887,2845.923096 
	C2754.269531,2843.139160 2755.945557,2841.972168 2759.328125,2842.041992 
	C2759.736572,2844.894775 2758.029785,2846.027100 2754.608887,2845.923096 
z"
        />
        <path
          fill="#1F3041"
          opacity="1.000000"
          stroke="none"
          d="
M2824.736816,2886.058594 
	C2823.792725,2883.517822 2826.374268,2882.280762 2826.959473,2879.721680 
	C2819.970215,2874.257324 2811.340088,2873.583740 2803.085938,2872.124512 
	C2798.731689,2871.354736 2795.598633,2869.828857 2794.120605,2864.776611 
	C2809.513428,2865.390137 2825.022949,2865.888428 2839.670654,2872.550049 
	C2849.843262,2877.176514 2861.172363,2875.943115 2873.039795,2875.997559 
	C2874.329590,2881.491211 2871.811279,2884.234375 2866.344971,2883.955322 
	C2860.532715,2883.658691 2855.356445,2884.846680 2850.366699,2888.228271 
	C2845.658936,2891.418457 2840.188721,2890.632568 2835.107422,2888.443359 
	C2832.032959,2887.118652 2829.041016,2885.707764 2824.736816,2886.058594 
z"
        />
        <path
          fill="#344F68"
          opacity="1.000000"
          stroke="none"
          d="
M2823.968262,2886.051758 
	C2823.316162,2889.911133 2828.788574,2889.195557 2828.901123,2892.576172 
	C2825.394775,2895.262939 2822.705811,2893.344238 2820.123291,2891.244385 
	C2818.945557,2890.286621 2816.650635,2889.735107 2817.373779,2887.724609 
	C2818.210205,2885.399170 2820.658203,2886.285645 2823.196289,2886.057861 
	C2823.898926,2886.061523 2823.989258,2886.004395 2823.968262,2886.051758 
z"
        />
        <path
          fill="#566C80"
          opacity="1.000000"
          stroke="none"
          d="
M423.234863,2602.147949 
	C428.738617,2602.019043 433.481995,2601.971924 439.113770,2601.895020 
	C443.440369,2603.968018 446.995392,2603.373535 451.263794,2602.284424 
	C458.745880,2600.945801 465.216766,2604.093994 472.764526,2603.665039 
	C483.201202,2601.508789 492.966309,2600.892334 503.615082,2601.207520 
	C505.823975,2601.674561 507.199646,2601.723877 509.263031,2601.800293 
	C518.248474,2601.701660 525.489441,2597.941650 533.546631,2594.720215 
	C534.937073,2594.295898 535.785278,2594.274658 537.268066,2594.303711 
	C538.874634,2595.074219 539.908447,2595.488770 541.613037,2594.688965 
	C544.857361,2594.256592 547.612610,2594.141113 551.195923,2594.057129 
	C558.980652,2594.707764 566.304871,2591.394287 571.366821,2601.108643 
	C573.868835,2605.910400 583.005615,2602.443115 589.864197,2601.400879 
	C596.602966,2600.547363 602.463684,2600.955322 608.351990,2602.711670 
	C614.711121,2604.608154 621.103455,2605.890869 627.927124,2603.763672 
	C632.913635,2602.208740 637.657654,2604.976318 642.360962,2606.665771 
	C646.838318,2608.273682 651.364990,2609.600586 655.055420,2604.845947 
	C657.609802,2601.554932 661.531616,2601.958740 665.115662,2601.390381 
	C678.830566,2599.216309 692.314819,2603.325684 705.971680,2602.795166 
	C711.252747,2602.590088 716.549622,2602.789307 722.119690,2602.144287 
	C717.333191,2600.994629 712.656799,2601.636719 708.148193,2600.806396 
	C704.511230,2600.136719 700.610718,2599.491943 701.605042,2593.512695 
	C705.015625,2589.696289 708.944641,2588.515137 712.924133,2589.121582 
	C728.483093,2591.492920 743.934509,2587.710938 760.322876,2588.791016 
	C767.698364,2589.348389 774.130005,2590.207275 781.265137,2592.245850 
	C787.247192,2597.224121 785.439819,2601.249512 780.316101,2605.552246 
	C746.899109,2615.244873 713.551208,2616.799561 679.933167,2615.213623 
	C674.898376,2614.976318 670.159912,2614.891846 665.468994,2617.421143 
	C660.647949,2620.021240 655.089600,2619.960205 649.832642,2619.283447 
	C638.938416,2617.880371 627.983704,2617.701416 616.203918,2616.915039 
	C598.765503,2611.485596 582.149963,2612.831543 564.696716,2616.732422 
	C554.914307,2618.205078 546.332581,2614.871338 537.313049,2614.926758 
	C524.713135,2615.004150 512.218445,2613.761230 499.719604,2616.781738 
	C494.943420,2617.936035 489.792236,2617.602539 484.027405,2617.152344 
	C473.452850,2617.627686 463.620605,2611.566650 455.180237,2616.983643 
	C458.961212,2616.484863 466.483032,2612.400879 468.168579,2619.216064 
	C470.075317,2626.925293 461.735138,2626.347412 456.392548,2627.340820 
	C454.122894,2627.763184 451.948822,2628.704590 448.836823,2628.785645 
	C446.517548,2628.897949 444.958557,2628.819092 442.602509,2628.445312 
	C438.123383,2626.727295 434.250092,2626.854248 429.517395,2627.028320 
	C414.058441,2626.081543 399.464630,2626.584473 384.869415,2627.170654 
	C382.166199,2627.279297 379.563721,2626.789307 376.611145,2624.645020 
	C375.990265,2622.518799 375.985962,2621.051025 375.973358,2618.849121 
	C378.534943,2614.147705 382.665863,2613.642334 386.794586,2613.166016 
	C355.146088,2615.213135 323.499023,2616.412598 292.242859,2623.703857 
	C274.230469,2627.905762 255.322800,2625.619629 236.255981,2621.297363 
	C222.216797,2622.460938 208.968903,2621.914795 194.921417,2621.298828 
	C189.140411,2620.153076 185.212555,2622.667236 180.499176,2625.276855 
	C178.169220,2625.852051 176.639862,2625.696045 174.604950,2624.388672 
	C173.564499,2622.374023 171.795288,2619.922363 173.270706,2619.477051 
	C180.170349,2617.394287 184.837494,2610.047363 193.514648,2611.856445 
	C200.407135,2613.293213 207.617920,2613.262451 214.698242,2613.712158 
	C217.840622,2613.911377 221.331909,2615.117920 223.983765,2611.014893 
	C231.902527,2606.065186 239.274597,2611.906494 246.920288,2610.994629 
	C251.062592,2610.500732 255.276871,2611.925049 260.235291,2612.645996 
	C269.520050,2611.435547 278.454834,2613.714111 287.411163,2609.606689 
	C291.705627,2609.010254 295.268127,2609.196777 299.553162,2609.042969 
	C307.918152,2606.468506 315.651550,2604.222168 323.713104,2600.804443 
	C329.836334,2599.366699 335.055023,2601.979004 341.331848,2601.769043 
	C343.113770,2603.994141 344.167999,2605.406494 347.245087,2605.117188 
	C360.791992,2603.843506 374.565216,2606.286621 387.991760,2602.625977 
	C390.593109,2601.916992 393.350464,2601.956787 395.893890,2602.839355 
	C404.855621,2605.949707 413.653687,2604.048340 423.234863,2602.147949 
z"
        />
        <path
          fill="#566C80"
          opacity="1.000000"
          stroke="none"
          d="
M302.534485,2642.606934 
	C302.934601,2633.972412 304.070953,2632.532227 311.034943,2632.115479 
	C315.623718,2631.840820 320.597260,2631.073242 324.786133,2632.424561 
	C334.656708,2635.608887 343.598541,2632.328857 352.755371,2630.038574 
	C359.438385,2628.367188 365.654694,2625.101074 373.328766,2624.068604 
	C377.718048,2628.063477 382.447235,2627.344971 387.041168,2627.155518 
	C402.266693,2626.528564 417.452393,2626.592285 432.550507,2629.135742 
	C435.521027,2629.635986 438.450409,2629.923828 441.428345,2632.437500 
	C442.923157,2641.172607 436.817749,2639.531494 432.251282,2640.087402 
	C447.093872,2638.330566 461.818390,2642.071289 476.691315,2641.193848 
	C480.199066,2640.986816 484.670349,2641.624756 484.683075,2646.540527 
	C484.693146,2650.419434 481.261871,2652.015381 477.776917,2652.375977 
	C474.023468,2652.764648 470.644745,2653.648682 467.510071,2657.089844 
	C465.979095,2657.998291 465.015137,2658.338867 463.224976,2658.663086 
	C460.871948,2658.896729 459.306488,2659.150635 456.940979,2658.986816 
	C451.107239,2659.159180 445.864441,2657.838867 440.392639,2661.037598 
	C438.562439,2661.403320 437.517700,2661.283447 435.864594,2660.435059 
	C433.996246,2650.196289 436.103333,2648.722656 449.426147,2648.750732 
	C440.173920,2648.068359 429.385803,2650.849365 418.416504,2651.406738 
	C414.423523,2651.609863 410.518188,2652.271240 406.684113,2655.520508 
	C397.908112,2657.629395 389.908203,2657.912842 381.459534,2654.562012 
	C379.648621,2653.246826 378.615509,2651.867920 376.205872,2652.566406 
	C374.386444,2653.186523 373.148590,2653.120850 371.433655,2652.137207 
	C368.238220,2644.940918 369.831848,2640.748291 377.615295,2639.719727 
	C381.436096,2639.215088 385.138306,2637.854980 387.396667,2637.940186 
	C376.793060,2640.933350 363.855408,2639.765381 351.785248,2644.087402 
	C344.926605,2646.543457 337.972260,2643.258301 330.991211,2644.068115 
	C327.054718,2644.524902 323.651398,2644.655518 322.571350,2650.091309 
	C313.090332,2655.050293 309.532959,2645.420654 302.534485,2642.606934 
z"
        />
        <path
          fill="#2B3E51"
          opacity="1.000000"
          stroke="none"
          d="
M208.580688,2668.385498 
	C198.114227,2658.519287 185.946564,2660.243164 175.274216,2663.829590 
	C164.908234,2667.312988 154.536636,2666.010254 144.319687,2667.890137 
	C141.768784,2668.359619 138.679230,2668.075928 138.320831,2664.365234 
	C137.977142,2660.806641 139.821396,2658.610840 143.348129,2658.229736 
	C146.977936,2657.838135 150.625183,2657.724854 154.177551,2659.053223 
	C157.976013,2660.473145 160.668137,2659.373291 161.924225,2654.532227 
	C171.455154,2653.869873 180.875626,2654.053223 191.020279,2655.850586 
	C196.683655,2652.830078 202.251877,2654.200928 207.442184,2653.423584 
	C213.389923,2652.532471 219.326523,2652.414307 224.154739,2648.000244 
	C226.516434,2645.841064 229.712036,2645.682617 232.684357,2646.718262 
	C238.549133,2648.762207 244.540665,2647.966309 251.268478,2647.759277 
	C253.410233,2647.640869 254.795395,2647.623535 256.870728,2647.540039 
	C260.193451,2646.213623 262.638092,2644.621826 265.697998,2642.848633 
	C268.245148,2642.489258 270.254211,2642.447266 273.015686,2642.465332 
	C274.938690,2643.262695 276.061188,2644.069092 277.763794,2645.249023 
	C284.949615,2645.589600 291.542847,2645.574463 299.039276,2645.506592 
	C306.298767,2650.313721 302.108337,2653.235352 297.791321,2655.561279 
	C290.713501,2659.374512 282.756989,2659.994141 274.942963,2660.808594 
	C270.979065,2661.221680 267.050934,2661.334473 263.263306,2664.739746 
	C256.361694,2668.659912 249.820572,2662.878906 242.818848,2666.093262 
	C237.944397,2667.236328 233.769882,2666.918213 228.876373,2666.904053 
	C224.234833,2665.720947 220.855560,2666.745361 217.179901,2669.649902 
	C213.901077,2670.661377 211.416763,2670.582764 208.580688,2668.385498 
z"
        />
        <path
          fill="#374D60"
          opacity="1.000000"
          stroke="none"
          d="
M235.362259,2618.745117 
	C252.105652,2622.252197 268.824310,2623.527100 285.230255,2621.668213 
	C298.204010,2620.198242 311.390686,2618.082031 323.983459,2613.946045 
	C326.515991,2613.114258 328.920685,2612.103027 331.654846,2612.072266 
	C349.882843,2611.865967 368.148346,2612.658447 386.290588,2610.038330 
	C387.918243,2609.803223 389.613190,2609.966064 391.272919,2610.036865 
	C394.201050,2610.161865 398.596497,2609.675537 398.578613,2613.269775 
	C398.556976,2617.617432 393.882904,2615.847168 391.347565,2615.776123 
	C386.567444,2615.642334 382.196564,2617.228760 376.800171,2618.039551 
	C368.968842,2618.239502 362.160034,2619.258789 355.898224,2622.603760 
	C352.490906,2624.423828 348.727051,2623.601807 345.180328,2624.280273 
	C331.161255,2626.962646 317.036774,2628.228027 302.755524,2628.065918 
	C288.814606,2627.907715 274.819305,2628.742188 260.945404,2627.761230 
	C251.064484,2627.062500 241.315536,2626.428223 230.634338,2627.456543 
	C227.840576,2622.280029 232.891479,2621.711914 235.362259,2618.745117 
z"
        />
        <path
          fill="#3F5467"
          opacity="1.000000"
          stroke="none"
          d="
M251.475693,2647.210449 
	C245.572098,2650.478760 239.310532,2654.639404 232.059341,2649.389648 
	C229.139282,2647.275879 226.165436,2648.137695 224.566147,2651.229980 
	C222.123795,2655.951660 218.018143,2656.464111 213.665436,2655.909912 
	C206.879501,2655.046143 200.220383,2660.108398 192.648651,2656.037109 
	C191.808960,2652.243408 189.610046,2650.702393 186.375824,2649.806396 
	C180.299362,2648.123047 174.354202,2645.971680 167.907684,2645.989502 
	C165.734207,2645.995605 163.232254,2646.733398 160.929749,2643.686523 
	C166.864944,2641.253418 172.840302,2640.446289 178.806503,2640.180176 
	C190.402557,2639.662842 201.980896,2640.354004 213.591934,2638.296875 
	C223.610062,2636.521729 233.299194,2640.264893 242.536896,2643.924805 
	C245.314514,2645.025146 248.207535,2645.609375 251.475693,2647.210449 
z"
        />
        <path
          fill="#3F5467"
          opacity="1.000000"
          stroke="none"
          d="
M261.497864,2664.402100 
	C262.758118,2657.776855 267.342194,2658.071045 271.710022,2658.033203 
	C281.071289,2657.951660 289.976074,2655.952881 298.575348,2652.203369 
	C301.616394,2650.877930 301.622375,2649.230957 300.177185,2646.134277 
	C300.485046,2644.612061 301.027588,2643.770020 301.788879,2642.470703 
	C307.138489,2641.760742 311.967468,2641.783691 314.315369,2647.842285 
	C315.291840,2650.362061 318.174438,2649.952148 321.281006,2649.851074 
	C322.916321,2649.165527 323.869354,2648.917969 325.588684,2649.396973 
	C328.264771,2655.148193 324.080688,2656.245117 320.631805,2656.728271 
	C306.607941,2658.692139 294.052155,2667.271240 279.100220,2665.521729 
	C282.004974,2667.887207 286.272614,2667.010742 288.089539,2671.229492 
	C279.665955,2675.477539 270.612671,2674.266602 261.977783,2675.861572 
	C257.500885,2676.688965 252.803802,2675.918945 248.276794,2677.475342 
	C241.968735,2679.644775 236.007690,2675.234863 228.847595,2676.031250 
	C226.278687,2666.147217 234.058136,2667.955566 240.157867,2666.562744 
	C247.455063,2663.201172 254.503098,2667.501221 261.497864,2664.402100 
z"
        />
        <path
          fill="#243748"
          opacity="1.000000"
          stroke="none"
          d="
M234.965332,2618.208984 
	C237.240341,2623.211426 232.250732,2623.591309 230.022614,2626.691895 
	C217.123856,2628.630127 204.283356,2627.429932 190.852036,2629.975098 
	C187.527603,2629.663574 184.974960,2628.793457 182.423294,2631.482422 
	C179.839127,2631.938721 177.726059,2631.972168 174.821686,2631.977539 
	C174.018799,2629.797852 174.007217,2627.645996 174.003159,2624.682861 
	C175.449692,2623.827637 176.888702,2623.783691 179.046844,2623.700684 
	C183.531433,2622.145020 187.258530,2620.534424 191.600098,2618.683594 
	C206.134399,2618.240234 220.116348,2618.189941 234.965332,2618.208984 
z"
        />
        <path
          fill="#3A4F62"
          opacity="1.000000"
          stroke="none"
          d="
M442.622223,2634.520996 
	C423.499695,2630.866943 404.948669,2628.567383 386.027252,2630.742920 
	C381.867249,2631.220947 375.695251,2632.666504 374.102386,2624.826660 
	C374.117065,2624.072998 375.056671,2624.046875 375.525635,2624.016846 
	C386.518585,2623.643555 397.033386,2624.804688 407.565887,2622.345703 
	C414.294861,2620.774658 421.117188,2625.051025 429.019043,2624.059082 
	C433.073700,2627.186279 437.101074,2625.569580 441.386017,2626.056641 
	C443.484985,2626.082764 444.951538,2626.088867 447.151337,2626.093750 
	C449.875732,2628.092529 452.654205,2627.461426 454.975677,2628.251953 
	C459.997742,2629.961670 458.421204,2632.499756 454.712585,2635.331787 
	C451.448395,2636.732422 448.796051,2636.784424 445.333771,2636.180176 
	C444.103241,2635.662598 443.645477,2635.379883 442.622223,2634.520996 
z"
        />
        <path
          fill="#374D60"
          opacity="1.000000"
          stroke="none"
          d="
M182.688126,2631.985107 
	C184.251205,2625.652100 184.449203,2625.571045 189.713440,2629.416992 
	C188.373444,2632.476807 185.831024,2632.056396 182.688126,2631.985107 
z"
        />
        <path
          fill="#3F5467"
          opacity="1.000000"
          stroke="none"
          d="
M265.545532,2642.445801 
	C265.149506,2646.123291 262.235962,2647.135986 258.271729,2647.571533 
	C258.769501,2644.025391 261.651672,2642.963623 265.545532,2642.445801 
z"
        />
        <path
          fill="#3F5467"
          opacity="1.000000"
          stroke="none"
          d="
M278.133789,2645.020020 
	C276.546204,2645.871094 275.024506,2645.510742 273.973267,2643.103760 
	C275.621033,2642.138184 277.075653,2642.615234 278.133789,2645.020020 
z"
        />
        <path
          fill="#395166"
          opacity="1.000000"
          stroke="none"
          d="
M288.514893,2611.506348 
	C282.913422,2614.082764 278.068817,2616.643799 272.004974,2614.656006 
	C269.504883,2613.836670 266.263611,2615.278076 262.582336,2615.597656 
	C261.287933,2606.562988 268.608795,2609.243408 272.381104,2607.952148 
	C266.998291,2608.208008 261.353455,2606.763916 256.083771,2602.333740 
	C256.738800,2597.791748 259.278107,2597.979736 262.329529,2599.121094 
	C265.788422,2600.414551 267.844543,2599.346680 267.897736,2594.680664 
	C276.685669,2594.395996 285.226044,2592.213623 293.839142,2590.939941 
	C297.399353,2590.413818 300.882935,2590.032715 305.338654,2590.270508 
	C306.216644,2592.015625 305.837463,2594.404053 307.404846,2594.782959 
	C312.700104,2596.064209 316.877563,2600.451416 323.495239,2600.283203 
	C323.542084,2604.681152 320.240448,2605.929443 316.658936,2606.714355 
	C311.949310,2607.746826 307.554474,2609.599365 302.455353,2611.555176 
	C297.551697,2609.183594 293.296967,2609.516113 288.514893,2611.506348 
z"
        />
        <path
          fill="#465F75"
          opacity="1.000000"
          stroke="none"
          d="
M298.296204,2584.016113 
	C295.931427,2584.019043 294.298645,2584.019043 292.665833,2584.019043 
	C296.057526,2579.437500 300.139465,2579.500732 304.809875,2581.173584 
	C303.819855,2584.949463 301.049744,2583.620850 298.296204,2584.016113 
z"
        />
        <path
          fill="#43596D"
          opacity="1.000000"
          stroke="none"
          d="
M202.729111,2579.720215 
	C205.279785,2574.989014 206.660492,2574.818359 211.895050,2579.052734 
	C209.251511,2579.760742 206.412949,2579.801514 202.729111,2579.720215 
z"
        />
        <path
          fill="#43596D"
          opacity="1.000000"
          stroke="none"
          d="
M238.655457,2579.804688 
	C238.668060,2575.750488 241.735794,2576.016113 245.432098,2576.104004 
	C245.380508,2580.054443 242.393295,2579.972168 238.655457,2579.804688 
z"
        />
        <path
          fill="#465F75"
          opacity="1.000000"
          stroke="none"
          d="
M355.987244,2588.532715 
	C354.025848,2589.875244 351.726105,2589.849365 348.572021,2589.336426 
	C349.914520,2585.266846 352.589935,2585.025146 355.987244,2588.532715 
z"
        />
        <path
          fill="#43596D"
          opacity="1.000000"
          stroke="none"
          d="
M189.998322,2576.661621 
	C192.219940,2576.071533 194.627548,2575.692871 195.938934,2579.019531 
	C194.584290,2579.759766 193.058884,2579.863037 190.769562,2579.967529 
	C190.003250,2579.086914 190.000854,2578.205078 189.998322,2576.661621 
z"
        />
        <path
          fill="#2B3E51"
          opacity="1.000000"
          stroke="none"
          d="
M254.884247,2601.770996 
	C264.396423,2603.634033 273.029358,2605.537109 281.653107,2607.481201 
	C281.821686,2607.519287 281.882355,2608.035889 282.294495,2609.125000 
	C275.714081,2611.228516 267.717560,2608.360352 262.057953,2614.846680 
	C249.722397,2614.228516 237.352798,2614.996094 224.984528,2610.377441 
	C220.974487,2607.760742 223.692062,2606.445312 225.694916,2604.413086 
	C235.446945,2604.425293 244.570374,2601.052734 254.884247,2601.770996 
z"
        />
        <path
          fill="#566C80"
          opacity="1.000000"
          stroke="none"
          d="
M190.779373,2605.866699 
	C190.385269,2603.611084 191.581650,2602.044922 193.923233,2602.123291 
	C198.304016,2602.270020 202.167999,2599.410400 207.451447,2600.173340 
	C204.729950,2607.976807 197.663208,2605.440430 190.779373,2605.866699 
z"
        />
        <path
          fill="#566C80"
          opacity="1.000000"
          stroke="none"
          d="
M172.478333,2607.563477 
	C172.266846,2605.765869 173.135910,2604.483643 175.709930,2604.284668 
	C175.843155,2605.973145 175.188416,2607.496094 172.478333,2607.563477 
z"
        />
        <path
          fill="#374D60"
          opacity="1.000000"
          stroke="none"
          d="
M115.587440,2610.568115 
	C115.404060,2613.411865 113.453804,2614.083252 110.230362,2613.594238 
	C110.984596,2611.792725 112.581047,2610.397949 115.587440,2610.568115 
z"
        />
        <path
          fill="#43596D"
          opacity="1.000000"
          stroke="none"
          d="
M154.016190,2574.441895 
	C158.667282,2571.179932 163.245895,2570.465820 167.905289,2575.039062 
	C164.005219,2575.816406 159.881973,2575.896973 154.878540,2575.993652 
	C154.001068,2575.661377 154.003799,2575.312744 154.016190,2574.441895 
z"
        />
        <path
          fill="#3C5163"
          opacity="1.000000"
          stroke="none"
          d="
M740.464844,2867.477783 
	C736.305969,2868.025879 732.643494,2868.001709 728.981384,2867.957520 
	C726.557556,2867.928223 723.785583,2867.564697 723.058472,2865.128906 
	C721.329895,2859.338867 716.902466,2859.958008 712.576416,2859.994385 
	C704.068726,2860.065674 695.497559,2858.931885 687.564880,2863.860352 
	C684.957581,2865.480469 681.351379,2865.887939 677.935059,2866.039307 
	C655.402954,2867.036621 633.224792,2870.673584 610.990967,2874.343994 
	C599.453064,2876.248779 587.341980,2877.140381 575.483704,2874.412842 
	C594.659058,2867.219971 615.118103,2867.257568 636.142517,2863.863525 
	C631.375488,2856.537598 626.552856,2855.492920 619.950439,2856.839111 
	C607.150085,2859.448975 594.460022,2858.385254 580.095520,2853.253418 
	C594.004333,2849.723145 605.748108,2848.982666 617.944458,2851.371826 
	C624.475159,2852.651123 631.874451,2854.258057 637.208923,2847.279297 
	C638.687805,2845.344727 641.580994,2846.193115 643.871094,2845.984863 
	C648.765869,2845.539551 653.643677,2845.009521 657.729492,2840.843750 
	C658.453979,2839.429443 659.188843,2839.009766 660.722656,2838.834473 
	C662.999390,2839.134277 664.424866,2840.373291 666.637024,2839.582520 
	C662.273682,2836.176514 655.886475,2840.254639 651.139832,2835.179443 
	C654.757690,2823.773438 664.264099,2827.998291 672.397461,2826.512451 
	C675.339905,2826.654785 677.141968,2825.675049 679.657715,2824.406982 
	C683.336243,2823.156982 686.414917,2823.061768 690.253784,2822.949219 
	C692.572144,2822.952881 694.052979,2822.552979 696.294861,2821.968262 
	C699.737854,2821.611328 702.271606,2821.874268 704.579834,2824.642090 
	C705.309753,2828.371582 703.459473,2830.122314 701.706482,2831.194336 
	C717.721802,2830.793213 734.019409,2827.360107 751.375488,2827.924316 
	C759.266968,2831.449951 766.900635,2830.224854 774.365601,2830.553467 
	C784.451782,2830.997559 794.677673,2829.299316 805.435425,2832.671875 
	C815.315063,2835.036621 825.161133,2832.807373 834.278076,2838.544434 
	C835.914429,2840.825928 836.275879,2842.607666 836.134583,2845.410645 
	C833.535339,2853.429932 827.877563,2855.049561 820.316895,2854.998535 
	C810.149292,2856.419678 800.884888,2854.483398 790.832275,2854.986328 
	C784.984680,2854.857666 780.583557,2856.510742 775.943542,2858.659912 
	C770.709656,2861.083984 763.550354,2864.673584 761.502869,2854.547607 
	C760.884155,2851.487793 759.128540,2853.152100 757.963928,2853.049805 
	C745.469788,2851.951416 733.002258,2850.277100 721.490234,2851.089600 
	C728.428284,2852.877441 736.483337,2854.740234 741.405640,2863.259033 
	C741.611816,2865.003906 741.405701,2865.975586 740.464844,2867.477783 
z"
        />
        <path
          fill="#465869"
          opacity="1.000000"
          stroke="none"
          d="
M740.685181,2868.353516 
	C739.976746,2867.138428 739.985168,2866.218506 739.996765,2864.608887 
	C742.984924,2860.772705 746.718689,2861.764648 750.314514,2862.076904 
	C758.270508,2862.767578 766.239502,2863.612793 774.050049,2865.159668 
	C786.837097,2867.692383 798.275879,2863.040527 810.691162,2859.046143 
	C815.153015,2857.036133 818.811279,2858.393799 823.240173,2858.988281 
	C840.229797,2861.570312 856.223572,2858.170410 868.964233,2856.810547 
	C859.438049,2855.097168 845.447327,2857.451660 834.394531,2846.561523 
	C833.997192,2844.485596 833.994324,2842.980713 833.987366,2840.723633 
	C843.446350,2834.707520 853.872864,2835.419922 863.988831,2835.945801 
	C872.983093,2836.413086 881.932007,2837.074951 890.909485,2836.270752 
	C896.649109,2835.756836 902.373108,2835.927734 907.621277,2838.211914 
	C914.868652,2841.365967 922.201782,2842.628174 929.616150,2841.042969 
	C926.221313,2838.945312 919.668457,2838.960693 922.304321,2831.082520 
	C923.505432,2830.216797 924.114258,2829.687744 924.706787,2829.720215 
	C946.658997,2830.908203 968.781677,2828.428955 991.292114,2833.135742 
	C997.226929,2837.847412 992.911926,2839.605469 989.688599,2841.071533 
	C984.005127,2843.656250 978.087708,2842.685303 971.000610,2841.052734 
	C975.179016,2843.894775 980.580383,2844.963135 978.557007,2850.635498 
	C977.106689,2854.701172 974.319458,2858.012451 970.431702,2861.246582 
	C967.418518,2862.638916 964.955200,2862.708740 961.740112,2862.076660 
	C957.378052,2859.976562 953.807800,2857.600586 948.734619,2859.962402 
	C945.315735,2860.996094 942.555847,2861.776855 939.061401,2862.674072 
	C935.807007,2863.128174 933.539429,2864.417725 930.247925,2864.902832 
	C924.318237,2866.893311 919.002197,2867.461426 912.758423,2866.999512 
	C900.890198,2868.450195 889.818542,2867.545166 878.012024,2867.022949 
	C872.823181,2865.690430 869.302429,2867.016113 866.027039,2871.195801 
	C864.933105,2871.839844 864.458313,2872.056396 863.211304,2872.502441 
	C850.015625,2872.446533 837.687256,2878.032959 824.608826,2872.696777 
	C818.125549,2870.448975 812.256104,2870.726807 806.562988,2872.801514 
	C801.710693,2874.569336 796.737244,2873.863525 791.806458,2874.043457 
	C785.880371,2874.260010 779.910400,2873.416992 774.086182,2875.466553 
	C771.573608,2876.350830 768.830383,2876.071045 766.282104,2875.075439 
	C758.244995,2871.934814 749.688782,2870.815430 740.685181,2868.353516 
z"
        />
        <path
          fill="#172837"
          opacity="1.000000"
          stroke="none"
          d="
M748.486572,2911.503418 
	C744.011963,2911.895020 739.922974,2912.305176 736.082153,2911.512451 
	C732.511414,2910.775391 730.251587,2910.679688 729.752380,2914.941406 
	C729.570129,2916.497559 727.862366,2917.702881 727.039124,2916.895996 
	C722.622375,2912.565918 717.434265,2916.506836 712.924011,2915.221680 
	C707.285583,2913.614502 701.345093,2913.899414 695.827515,2910.696045 
	C691.917480,2908.426025 686.743591,2908.313965 681.443726,2909.446533 
	C673.796326,2911.080566 665.710632,2910.385254 657.673096,2908.952148 
	C660.634766,2913.503662 660.627441,2913.588867 664.476318,2913.919434 
	C667.448975,2914.174805 670.473267,2913.961426 673.418091,2914.366455 
	C675.968140,2914.717529 677.758667,2916.466797 677.406982,2919.178955 
	C677.088562,2921.635498 674.838013,2921.911133 672.785767,2921.939209 
	C665.831177,2922.034180 658.949585,2922.112061 652.037598,2923.687256 
	C648.005554,2924.606445 644.251709,2924.442139 642.898132,2929.435059 
	C642.396179,2931.286377 640.473877,2933.232666 638.579529,2933.219971 
	C630.693176,2933.167969 623.165771,2937.556885 614.562622,2934.502197 
	C615.171387,2923.373047 608.105652,2921.972656 600.145630,2921.445801 
	C593.581787,2921.011230 587.008301,2921.384521 580.449890,2921.078369 
	C576.586914,2920.897949 572.419861,2921.308594 569.875610,2916.712891 
	C582.742737,2912.952637 594.994263,2907.513428 608.489624,2908.068359 
	C618.165466,2908.466064 627.707886,2907.872803 637.022156,2904.777588 
	C639.466736,2903.965332 642.269409,2904.202637 644.911926,2904.019775 
	C648.141663,2903.796143 651.685730,2904.680908 650.972168,2898.960938 
	C650.497070,2895.152588 654.533081,2894.581055 657.393127,2894.214600 
	C671.853516,2892.362549 686.342957,2891.177734 700.652893,2895.263672 
	C707.783020,2897.299561 714.300842,2893.091309 721.269409,2893.498291 
	C722.646606,2893.578613 723.506470,2892.090820 723.769287,2890.752197 
	C724.950195,2884.736816 729.520203,2884.200684 734.385437,2883.993896 
	C747.396301,2883.439941 759.047668,2887.412109 769.926270,2895.215332 
	C769.184937,2900.999268 764.938477,2902.514404 760.993469,2904.184814 
	C756.709839,2905.998291 752.224915,2907.380371 748.486572,2911.503418 
z"
        />
        <path
          fill="#0D1C2A"
          opacity="1.000000"
          stroke="none"
          d="
M748.821533,2912.018311 
	C747.718506,2907.519043 749.516663,2904.860107 754.119080,2903.608643 
	C759.381836,2902.177490 764.695129,2900.523926 769.461487,2896.252930 
	C780.018311,2896.707275 789.766174,2894.696045 799.599487,2893.949463 
	C802.057495,2893.762939 803.917175,2892.790039 805.528503,2890.893066 
	C809.667114,2886.020020 814.790833,2885.671631 821.174500,2885.325684 
	C836.591431,2884.490723 850.959290,2891.196289 867.109131,2890.019531 
	C878.455872,2884.030273 889.234131,2885.592529 899.664734,2889.479736 
	C905.378723,2891.609131 907.911255,2896.933838 907.660339,2904.002441 
	C902.729553,2911.705322 895.291870,2911.909668 888.626221,2910.592285 
	C872.763123,2907.456787 856.792664,2914.185547 841.022705,2909.265381 
	C836.454651,2907.840576 838.147278,2912.340332 837.157898,2915.141602 
	C835.546753,2917.567871 833.659485,2918.012451 830.919128,2918.060547 
	C827.999023,2917.628662 826.107422,2916.192139 823.149902,2916.019043 
	C821.565674,2915.917236 820.658386,2915.796143 819.067627,2915.458008 
	C807.550415,2912.403076 796.755981,2912.668945 785.243896,2915.932373 
	C775.287720,2917.874512 766.177429,2918.490234 756.926392,2913.063965 
	C754.049561,2912.354004 751.849976,2912.190430 748.821533,2912.018311 
z"
        />
        <path
          fill="#3C5163"
          opacity="1.000000"
          stroke="none"
          d="
M451.432678,2865.388672 
	C464.612701,2864.598145 477.113068,2863.205566 489.639343,2862.109375 
	C492.678345,2861.843262 495.511475,2861.107178 499.187744,2860.166016 
	C501.458221,2862.508545 501.538727,2864.871094 500.444092,2867.626709 
	C499.301086,2870.503906 498.091248,2872.058838 494.643188,2871.294189 
	C481.520142,2868.383545 468.287445,2870.782959 455.101898,2870.672852 
	C451.899536,2870.645996 450.305695,2872.618652 448.723755,2874.740723 
	C445.838959,2878.609863 441.711517,2880.177246 437.360352,2879.297607 
	C423.676636,2876.531982 410.302277,2877.899658 397.011414,2881.364746 
	C393.159149,2882.368896 388.924377,2881.172119 385.351837,2883.490234 
	C384.543579,2884.014648 383.890656,2884.778320 383.244080,2885.361572 
	C383.472473,2887.073242 384.441956,2888.017334 385.905212,2887.835693 
	C398.747925,2886.240967 411.534851,2891.560303 424.387939,2888.128174 
	C425.012421,2887.961426 425.714722,2888.045410 426.378296,2888.072754 
	C429.054932,2888.183350 432.745361,2888.145020 432.710541,2891.351562 
	C432.669312,2895.147461 428.715454,2893.761230 426.323364,2893.916992 
	C414.425629,2894.691895 402.463715,2892.918457 390.558136,2895.581055 
	C386.085419,2896.581299 381.434784,2893.952881 377.410828,2891.636963 
	C372.294373,2888.693115 366.947906,2887.778809 361.145782,2887.940918 
	C350.962372,2888.225830 340.721588,2887.052490 331.035217,2891.978271 
	C327.141937,2893.958008 322.865570,2894.807861 321.664246,2899.829834 
	C321.158905,2901.942383 319.574951,2903.484131 317.496094,2903.639160 
	C311.383179,2904.094971 305.221619,2904.543213 299.117523,2903.294434 
	C299.355377,2901.933594 299.315369,2901.572266 299.472900,2901.352051 
	C301.170227,2898.980225 306.864929,2899.470703 304.818573,2894.542969 
	C303.367279,2891.047852 300.499725,2890.204102 296.565918,2891.211426 
	C293.965790,2891.876953 291.466217,2890.181152 290.058502,2886.799072 
	C289.295593,2883.547363 289.250061,2881.003174 291.056976,2877.972168 
	C293.182922,2876.052246 295.130402,2875.719727 297.879395,2875.736816 
	C301.630920,2876.427979 304.727295,2877.606201 308.043640,2874.555420 
	C310.920471,2873.399658 313.283752,2873.182617 316.382080,2873.006348 
	C318.607910,2872.965088 320.021729,2872.552734 322.149139,2871.794922 
	C326.538361,2870.757080 330.176910,2869.668457 334.595276,2871.578125 
	C344.565796,2879.682617 355.620667,2878.643799 366.254456,2877.427979 
	C373.746979,2876.571045 381.326691,2874.860840 388.516174,2872.292236 
	C404.935089,2866.425293 422.493256,2867.041992 439.248871,2863.096191 
	C443.072906,2862.195801 447.211578,2862.472900 451.432678,2865.388672 
z"
        />
        <path
          fill="#1B2B3A"
          opacity="1.000000"
          stroke="none"
          d="
M905.965332,2906.847656 
	C904.629761,2893.341553 899.396362,2889.978027 887.824402,2890.000000 
	C881.838867,2890.011475 875.853271,2890.018066 868.938721,2890.018311 
	C867.073425,2884.961670 863.076843,2884.136475 859.073792,2884.118896 
	C848.836060,2884.073730 839.408997,2878.874023 829.034241,2879.686279 
	C826.291016,2879.901123 824.275146,2877.544678 822.276489,2875.938721 
	C820.773071,2874.730713 822.638000,2873.854736 823.695801,2872.513184 
	C837.486450,2873.073975 850.687012,2869.106445 865.058716,2869.957520 
	C874.894958,2876.820312 884.148376,2880.509033 895.219971,2872.622559 
	C906.576538,2870.109863 917.464478,2869.617920 929.068115,2869.114258 
	C932.500183,2869.031006 934.986328,2870.516357 939.636353,2871.399658 
	C931.787109,2866.762695 931.787109,2866.762695 937.568237,2860.602539 
	C940.908081,2859.479492 943.725159,2858.804688 947.272705,2858.109863 
	C951.621887,2858.155762 955.288208,2857.492188 959.506348,2859.179199 
	C962.916687,2859.650635 965.646851,2859.739014 969.196899,2859.792969 
	C970.813538,2860.442627 971.339783,2861.293701 971.653076,2863.070312 
	C967.304199,2874.434326 958.664062,2881.186523 949.578674,2887.247314 
	C937.731445,2895.149902 924.277771,2900.427002 912.192261,2909.430908 
	C909.268005,2909.913574 907.291138,2909.598633 905.965332,2906.847656 
z"
        />
        <path
          fill="#3F5467"
          opacity="1.000000"
          stroke="none"
          d="
M456.683472,2909.895508 
	C448.603302,2909.960205 441.223480,2909.968018 432.922729,2909.972168 
	C427.931793,2905.653076 421.571838,2910.155518 416.752533,2905.933594 
	C419.069946,2902.847900 422.060516,2901.983887 425.409912,2902.081543 
	C431.029541,2902.245361 436.724426,2901.248535 442.245575,2903.091064 
	C446.556183,2904.529785 450.256958,2904.001953 452.199951,2899.385986 
	C454.021973,2895.057129 457.149231,2893.601318 461.671478,2893.911621 
	C465.690216,2894.187012 469.442230,2893.043945 472.675049,2890.737305 
	C473.353210,2887.275391 466.950439,2886.518799 469.663483,2883.221191 
	C471.652130,2880.804199 475.376587,2881.678711 478.171722,2882.496094 
	C493.164368,2886.880615 508.353546,2889.324463 524.013062,2887.936279 
	C526.312378,2887.732178 528.887207,2888.020996 530.849731,2887.075684 
	C540.843140,2882.261719 546.493896,2881.983643 557.219116,2883.191406 
	C570.056396,2884.636475 583.127441,2885.258057 595.900513,2882.816162 
	C601.985107,2881.652832 607.011353,2883.288818 612.252380,2885.299805 
	C614.129639,2886.020020 615.859558,2887.230957 615.504456,2889.701416 
	C615.180481,2891.955322 613.581787,2893.539795 611.552063,2893.593506 
	C603.978638,2893.793701 596.181213,2895.629639 588.927368,2892.323975 
	C584.488098,2890.301025 580.016113,2890.188721 575.352356,2889.960205 
	C552.650574,2888.847900 530.511353,2893.479980 508.230347,2896.502930 
	C504.803619,2896.967773 501.744598,2896.682861 498.495361,2896.189941 
	C493.759888,2895.471191 491.670990,2897.738770 491.343994,2903.509277 
	C478.813538,2901.452881 467.802582,2903.763428 456.683472,2909.895508 
z"
        />
        <path
          fill="#0D1C2A"
          opacity="1.000000"
          stroke="none"
          d="
M569.076416,2915.978027 
	C572.951172,2919.077881 576.797241,2917.840576 580.305481,2917.961914 
	C590.483582,2918.314453 600.746033,2917.038086 610.787415,2919.917969 
	C612.658142,2920.454590 614.755920,2920.271484 616.351562,2921.639160 
	C618.830872,2923.764404 620.836121,2925.963623 616.885010,2928.624023 
	C615.467468,2929.578857 614.547607,2930.922119 614.087280,2933.284668 
	C609.013123,2934.807373 604.549744,2931.726807 598.821106,2932.033447 
	C591.548035,2927.259766 583.694153,2927.161621 576.300598,2926.202148 
	C569.439941,2925.311523 562.369629,2925.915771 555.395874,2926.064209 
	C551.282593,2926.151611 547.210754,2926.902588 546.086548,2931.781738 
	C545.169922,2935.759766 542.703247,2936.072998 539.352051,2935.491943 
	C528.750916,2933.654785 518.199341,2932.750000 507.924683,2937.297852 
	C506.079407,2938.114502 504.528076,2936.902344 504.322998,2935.039551 
	C503.931549,2931.483887 501.661285,2929.145752 499.769928,2926.415771 
	C497.016602,2922.441162 497.980103,2920.103516 502.781982,2918.113037 
	C506.482513,2916.579102 510.341003,2915.888672 514.426453,2915.941650 
	C525.055481,2916.079834 535.688110,2915.939453 547.279541,2915.825195 
	C554.880981,2915.820312 561.522217,2915.900391 569.076416,2915.978027 
z"
        />
        <path
          fill="#495E72"
          opacity="1.000000"
          stroke="none"
          d="
M650.967773,2835.077393 
	C659.306763,2836.395752 666.955872,2836.291992 675.419312,2833.718262 
	C674.449829,2838.664062 671.097290,2839.306152 668.717590,2841.614258 
	C666.194824,2843.410400 664.769165,2841.858154 662.684570,2840.620117 
	C661.210510,2840.131104 660.261414,2840.095215 658.598389,2840.091797 
	C640.318848,2843.456299 622.917664,2842.411865 604.824219,2838.142822 
	C599.335754,2837.526611 594.942810,2838.141113 590.528442,2841.502441 
	C572.742249,2845.413086 555.140503,2845.597656 536.930176,2847.939941 
	C533.870972,2839.818115 535.277283,2837.370117 544.169861,2837.196777 
	C554.237976,2837.000488 563.934326,2834.981934 573.735657,2833.226807 
	C576.508911,2832.729980 578.845093,2832.023682 581.209473,2829.142090 
	C598.005066,2825.604980 613.927979,2826.028809 630.517883,2830.996094 
	C637.636536,2832.577148 644.286011,2831.756104 650.967773,2835.077393 
z"
        />
        <path
          fill="#3F5467"
          opacity="1.000000"
          stroke="none"
          d="
M543.897217,2910.983887 
	C534.902588,2913.112061 526.239563,2910.255371 517.375061,2910.018311 
	C506.167419,2909.718750 495.202820,2912.977539 483.086456,2911.780518 
	C479.202545,2903.009033 484.717468,2903.621826 491.180573,2904.169922 
	C492.025574,2903.991455 492.003906,2904.000000 491.993408,2904.009766 
	C499.538757,2904.327637 507.052551,2902.213867 514.668030,2903.870361 
	C517.196350,2904.420410 519.954651,2904.066406 522.328979,2902.420654 
	C528.396118,2898.216064 535.314148,2897.904541 542.390747,2897.927246 
	C550.007935,2897.951660 557.598328,2898.299316 565.205261,2896.409424 
	C569.411743,2895.364258 574.241028,2895.107666 580.645996,2897.872070 
	C569.997131,2901.373535 561.528503,2905.142090 551.812378,2904.093262 
	C547.435059,2903.620605 543.636292,2904.358643 543.897217,2910.983887 
z"
        />
        <path
          fill="#233444"
          opacity="1.000000"
          stroke="none"
          d="
M227.072830,2875.791992 
	C234.456421,2874.675781 240.788910,2873.649902 246.001083,2868.338379 
	C248.984375,2865.298340 253.681000,2863.498047 259.336121,2864.110352 
	C261.428192,2866.880859 260.208099,2871.502441 266.380066,2870.066895 
	C270.720032,2869.056641 274.067261,2871.666748 272.959106,2877.799316 
	C271.498230,2880.230713 269.972961,2881.893311 268.023651,2882.015869 
	C256.615051,2882.732910 245.285370,2885.612305 233.076721,2882.873535 
	C226.063232,2883.933105 219.706909,2885.470703 213.411346,2887.226562 
	C209.491837,2888.319580 205.539658,2887.829102 201.595779,2887.973389 
	C197.181183,2888.135010 195.818954,2885.636230 196.028839,2880.890137 
	C201.999527,2879.973633 207.969833,2879.879883 214.830246,2879.775879 
	C217.026566,2878.976318 218.077667,2877.881104 219.618591,2876.488525 
	C222.124619,2876.019775 224.209625,2875.931152 227.072830,2875.791992 
z"
        />
        <path
          fill="#374D60"
          opacity="1.000000"
          stroke="none"
          d="
M581.530457,2829.446289 
	C582.083679,2833.454834 581.041870,2835.423828 577.124329,2836.099121 
	C566.038452,2838.010010 555.111755,2840.957275 543.691040,2840.020752 
	C539.213013,2839.653809 535.572571,2840.494141 536.063965,2847.102051 
	C526.604736,2853.916748 517.087830,2849.520020 506.765991,2848.080322 
	C502.564758,2848.025391 499.183411,2847.964600 494.955780,2847.887451 
	C492.633575,2846.329590 490.150269,2845.797607 489.801880,2842.730957 
	C491.658722,2838.247559 494.984039,2837.901611 499.274384,2838.023926 
	C506.668732,2843.387939 515.319031,2840.824951 522.645447,2843.626221 
	C523.312805,2843.881592 522.802490,2841.928711 523.550781,2840.583496 
	C524.913513,2840.003418 526.429993,2840.279053 526.784302,2839.760498 
	C531.607483,2832.703369 539.417297,2835.734863 545.788940,2834.174316 
	C551.748474,2832.714844 558.000610,2832.480225 564.087524,2831.484375 
	C565.219421,2831.299072 566.179138,2830.061035 567.607971,2828.654541 
	C572.448853,2826.263672 576.761658,2826.761475 581.530457,2829.446289 
z"
        />
        <path
          fill="#4C6174"
          opacity="1.000000"
          stroke="none"
          d="
M232.324860,2883.090332 
	C243.496628,2880.713135 254.601715,2879.190430 265.871674,2879.685547 
	C267.928650,2879.775879 269.751007,2878.999268 271.609406,2876.806641 
	C278.549103,2871.269775 285.125214,2871.201660 291.911743,2876.893311 
	C291.822449,2880.286621 292.424805,2882.848145 290.446442,2885.620850 
	C285.077972,2887.348389 280.433990,2887.463623 275.313080,2886.553711 
	C268.219543,2885.292969 260.767944,2885.364502 253.565796,2887.136475 
	C247.994308,2888.507568 242.327225,2887.754395 236.704575,2887.949707 
	C234.015045,2888.043213 232.247833,2886.862305 232.324860,2883.090332 
z"
        />
        <path
          fill="#172837"
          opacity="1.000000"
          stroke="none"
          d="
M702.031067,2883.637207 
	C686.996399,2884.510742 672.826477,2884.461182 659.239990,2878.755127 
	C665.158997,2872.265137 673.863281,2870.517822 681.688721,2873.098389 
	C688.259888,2875.264893 695.168213,2876.416016 701.938660,2877.966797 
	C704.181091,2878.480957 706.569824,2878.616943 707.952271,2880.915283 
	C706.677979,2882.746094 704.413452,2882.418457 702.031067,2883.637207 
z"
        />
        <path
          fill="#172B3E"
          opacity="1.000000"
          stroke="none"
          d="
M161.626129,2888.447266 
	C168.783585,2887.946045 175.533890,2888.007812 183.215881,2888.112793 
	C186.974258,2889.834717 189.562653,2891.914795 192.870850,2892.752930 
	C194.201462,2893.090088 195.135773,2894.435059 194.958420,2895.793701 
	C194.772079,2897.221191 193.459930,2897.877197 192.015213,2897.874756 
	C186.384613,2897.865234 180.599167,2898.630127 175.161530,2897.590088 
	C167.874115,2896.195801 160.873535,2895.216309 153.661484,2897.593750 
	C152.068115,2898.119141 150.423874,2897.369873 149.367813,2895.270264 
	C152.306610,2891.298096 157.963181,2892.269287 161.626129,2888.447266 
z"
        />
        <path
          fill="#3C5163"
          opacity="1.000000"
          stroke="none"
          d="
M358.157318,2892.110107 
	C366.350922,2892.549072 371.713776,2898.182129 380.571503,2899.287109 
	C375.045258,2903.378418 371.708618,2900.723633 368.447845,2899.264648 
	C364.630920,2897.556641 360.604462,2898.550781 356.749451,2897.932373 
	C352.812622,2897.300781 349.288544,2900.844482 344.587067,2898.584473 
	C347.740021,2893.544922 353.060425,2893.780029 358.157318,2892.110107 
z"
        />
        <path
          fill="#3C5163"
          opacity="1.000000"
          stroke="none"
          d="
M590.718445,2841.893799 
	C594.260681,2835.330322 594.260681,2835.330322 603.504883,2837.576904 
	C600.237854,2840.717773 595.733093,2840.894287 590.718445,2841.893799 
z"
        />
        <path
          fill="#3F5467"
          opacity="1.000000"
          stroke="none"
          d="
M410.955841,2904.923340 
	C411.045929,2904.874268 410.865753,2904.972656 410.955841,2904.923340 
z"
        />
        <path
          fill="#2B3E51"
          opacity="1.000000"
          stroke="none"
          d="
M219.407715,2876.154785 
	C219.780807,2877.934082 219.134933,2879.440186 216.378784,2879.757324 
	C216.158432,2878.079834 216.783478,2876.554443 219.407715,2876.154785 
z"
        />
        <path
          fill="#43596D"
          opacity="1.000000"
          stroke="none"
          d="
M140.976135,2579.824219 
	C139.500397,2579.913086 137.763641,2579.798096 138.010025,2579.031982 
	C138.488281,2577.545166 140.187851,2578.039551 141.465332,2578.316895 
	C141.728149,2578.374023 142.088669,2578.771729 142.090546,2579.014648 
	C142.092392,2579.250732 141.691742,2579.489990 140.976135,2579.824219 
z"
        />
        <path
          fill="#243B51"
          opacity="1.000000"
          stroke="none"
          d="
M2749.612305,3037.411865 
	C2750.680176,3039.633545 2751.562012,3041.241699 2750.195312,3043.505127 
	C2745.226807,3046.046143 2740.601562,3050.782959 2734.795898,3044.455566 
	C2725.015869,3037.894531 2714.356201,3037.640869 2703.875244,3036.431396 
	C2690.062988,3034.837158 2676.172363,3033.880127 2662.472168,3031.298340 
	C2659.347656,3030.709717 2655.847656,3029.938965 2652.625000,3033.271973 
	C2650.124268,3035.815186 2650.657471,3037.234375 2653.223877,3038.007324 
	C2659.862061,3040.007324 2666.509766,3041.978760 2673.176270,3043.881836 
	C2676.030029,3044.696777 2678.932373,3045.346436 2681.827148,3046.008545 
	C2683.849609,3046.471436 2686.021729,3047.004150 2685.250488,3049.686768 
	C2684.619629,3051.883057 2682.722656,3050.870605 2681.125000,3050.697754 
	C2670.906982,3049.590576 2660.729004,3047.715332 2651.290771,3043.892334 
	C2635.963135,3037.683838 2620.336914,3034.783203 2602.967285,3036.044922 
	C2590.879883,3032.907227 2578.846680,3017.957520 2580.973145,3008.521973 
	C2601.461182,3003.344482 2621.152588,3002.795166 2640.721191,3009.180664 
	C2643.890381,3010.214600 2647.267822,3010.307861 2651.252441,3011.611328 
	C2662.569824,3017.237549 2673.629150,3020.325195 2685.067383,3016.222900 
	C2692.612061,3013.516602 2699.921875,3013.500977 2707.427246,3014.900146 
	C2710.626221,3015.496338 2713.634277,3016.671387 2717.105957,3014.652344 
	C2715.193848,3009.787354 2710.509033,3010.316895 2707.269287,3008.650391 
	C2703.147705,3006.530029 2700.770752,3004.039307 2703.712646,2998.647949 
	C2707.801270,2994.636230 2712.016357,2996.225830 2716.031250,2997.218750 
	C2722.654297,2998.856689 2729.352783,3000.136963 2736.738770,3002.018066 
	C2743.659668,3009.080078 2752.827148,3007.771240 2761.149902,3010.994629 
	C2776.907227,3015.188721 2792.384521,3014.649658 2807.651611,3015.984131 
	C2810.625732,3016.243896 2813.471436,3016.708740 2817.071045,3017.713379 
	C2821.353516,3021.230957 2827.114258,3020.152100 2830.067627,3025.161621 
	C2829.377930,3027.535889 2828.751465,3029.126953 2828.062500,3031.359131 
	C2826.700439,3033.824951 2824.769531,3034.392334 2821.958496,3034.718506 
	C2818.923096,3034.906006 2816.602539,3034.488770 2813.017822,3034.933350 
	C2816.693848,3037.741699 2820.220947,3038.087402 2823.359619,3039.129150 
	C2829.263428,3041.088867 2831.705078,3044.379395 2827.802734,3050.778320 
	C2817.995605,3051.970215 2809.665527,3047.929688 2801.139404,3045.844238 
	C2791.257812,3043.427490 2781.156006,3041.040771 2771.121094,3036.399170 
	C2768.783203,3032.940430 2763.819824,3031.219238 2765.646729,3026.907227 
	C2767.519775,3022.487793 2772.233643,3023.166504 2776.172607,3023.037354 
	C2780.662354,3022.889893 2785.158447,3023.104736 2787.957520,3023.013428 
	C2763.780029,3020.625244 2738.423828,3030.894531 2711.721191,3025.655273 
	C2721.270020,3029.291016 2730.966797,3023.571045 2740.165039,3029.147461 
	C2742.877686,3032.789795 2746.176270,3034.655029 2749.612305,3037.411865 
z"
        />
        <path
          fill="#423C3E"
          opacity="1.000000"
          stroke="none"
          d="
M2579.620605,3009.539551 
	C2583.826660,3018.975830 2591.659668,3024.600098 2597.533691,3031.808594 
	C2598.551270,3033.057129 2599.811523,3034.107666 2601.499512,3035.650391 
	C2601.630127,3043.045654 2607.585205,3046.413086 2611.353271,3051.691162 
	C2610.081055,3058.844482 2616.258545,3063.915039 2615.940918,3071.197754 
	C2609.771240,3075.177979 2605.239746,3070.611572 2600.881592,3068.020508 
	C2595.148926,3064.612793 2589.760010,3063.047607 2582.730713,3065.364014 
	C2574.265869,3061.561279 2572.408203,3053.411377 2568.333740,3046.901855 
	C2564.950928,3041.497070 2563.737793,3034.416260 2556.146484,3031.360840 
	C2555.278809,3030.531738 2554.979492,3030.155273 2554.351807,3029.142578 
	C2552.724121,3021.177734 2549.791992,3014.719482 2544.812988,3009.032227 
	C2540.357178,3003.942627 2534.956055,2999.493408 2535.869385,2991.125977 
	C2536.387207,2986.383545 2527.856445,2984.970703 2529.373047,2978.186035 
	C2530.549805,2977.014404 2531.453369,2976.689697 2533.172363,2976.625244 
	C2540.828125,2980.345459 2548.043457,2983.398438 2555.453125,2987.532227 
	C2555.821533,2992.932861 2557.835693,2996.306885 2563.230957,2997.924072 
	C2570.018555,3000.284424 2574.615234,3004.655273 2579.620605,3009.539551 
z"
        />
        <path
          fill="#3C556B"
          opacity="1.000000"
          stroke="none"
          d="
M2653.010742,3033.848145 
	C2651.198486,3031.822510 2650.224121,3029.827881 2648.793213,3026.898926 
	C2659.716309,3028.135986 2670.021484,3029.307861 2680.328125,3030.469238 
	C2690.824951,3031.652100 2701.299072,3033.226807 2711.829346,3033.857178 
	C2718.663086,3034.265869 2724.724121,3036.746582 2730.990234,3038.819336 
	C2732.652344,3039.369141 2733.428467,3040.976562 2734.044922,3043.373291 
	C2719.389893,3043.223145 2704.728760,3041.015625 2690.050049,3038.960449 
	C2677.982422,3037.270996 2665.915771,3035.573486 2653.010742,3033.848145 
z"
        />
        <path
          fill="#2E2D30"
          opacity="1.000000"
          stroke="none"
          d="
M2581.512207,3064.608398 
	C2588.346680,3060.127441 2594.076172,3058.351074 2600.572998,3064.766846 
	C2604.097168,3068.247314 2609.800537,3069.520996 2615.225098,3071.892578 
	C2615.949707,3074.143555 2615.969727,3076.291992 2615.983398,3079.248047 
	C2612.241455,3088.410400 2608.382568,3096.713379 2604.858398,3105.156250 
	C2604.007324,3107.195068 2604.249023,3109.694092 2604.013184,3111.984131 
	C2603.776367,3114.279297 2604.023438,3116.737061 2603.279785,3118.849609 
	C2597.612305,3134.949707 2595.684570,3151.391846 2600.299561,3167.917969 
	C2602.473877,3175.703857 2601.056396,3182.906982 2600.066895,3191.213379 
	C2602.955566,3204.078125 2599.062744,3214.860107 2593.387451,3225.996826 
	C2584.452148,3229.803223 2581.922363,3224.160645 2580.597168,3217.961426 
	C2578.746582,3209.302979 2581.251465,3200.829834 2582.720703,3192.321045 
	C2585.829102,3174.319580 2587.048096,3156.151367 2584.953857,3137.988770 
	C2584.085449,3130.456543 2584.521240,3122.692383 2584.837158,3115.548340 
	C2585.553955,3099.342529 2580.742676,3084.014893 2580.506592,3068.151855 
	C2580.491455,3067.143311 2580.708008,3066.176270 2581.512207,3064.608398 
z"
        />
        <path
          fill="#172B3E"
          opacity="1.000000"
          stroke="none"
          d="
M2762.741943,3069.874512 
	C2763.989258,3060.693848 2765.394775,3059.634277 2773.546631,3061.579590 
	C2777.068359,3062.420166 2780.780273,3063.027832 2783.940674,3064.648682 
	C2786.694092,3066.060303 2786.118408,3068.372803 2782.593262,3069.931152 
	C2780.430908,3070.060303 2778.954834,3070.022949 2776.739502,3069.973389 
	C2773.088623,3069.991699 2770.177002,3070.043945 2766.644043,3069.867676 
	C2765.147217,3069.843506 2764.272217,3069.867188 2762.741943,3069.874512 
z"
        />
        <path
          fill="#23394D"
          opacity="1.000000"
          stroke="none"
          d="
M2766.470215,3070.371826 
	C2768.804443,3067.211426 2771.877930,3067.638916 2775.518311,3069.458252 
	C2773.241211,3072.811768 2770.135010,3072.388184 2766.470215,3070.371826 
z"
        />
        <path
          fill="#556A7C"
          opacity="1.000000"
          stroke="none"
          d="
M139.501404,2717.458008 
	C136.417633,2721.303223 131.397995,2719.679932 126.661781,2721.588379 
	C128.973587,2716.312012 133.626816,2715.591309 139.501404,2717.458008 
z"
        />
        <path
          fill="#597288"
          opacity="1.000000"
          stroke="none"
          d="
M330.813507,2704.029053 
	C329.154419,2704.189453 328.296143,2704.168213 326.796814,2704.163086 
	C323.849884,2704.048584 321.529388,2704.033447 319.243896,2703.742676 
	C317.939270,2703.576904 316.155884,2702.479492 317.492371,2701.253174 
	C323.398834,2695.833740 317.429138,2696.661621 314.761627,2695.645996 
	C314.209778,2695.436279 313.785828,2694.890625 313.165039,2694.388428 
	C313.397522,2691.223877 317.783539,2690.670166 317.917847,2686.692383 
	C320.500092,2682.838379 324.435394,2682.892090 328.570251,2681.544922 
	C330.388245,2681.359131 331.428284,2681.375732 333.226929,2681.605469 
	C334.472961,2681.865723 334.958801,2682.006836 336.131439,2682.445557 
	C344.782776,2686.226318 353.385498,2685.994385 362.426453,2687.038818 
	C364.299438,2687.049316 365.516449,2687.023926 367.115662,2687.005615 
	C367.497650,2686.991211 367.821472,2686.895264 367.456451,2686.946533 
	C366.027985,2686.964600 364.963867,2686.961182 363.178772,2686.920410 
	C358.574524,2686.462891 354.904144,2685.772705 355.680176,2679.843750 
	C363.963531,2675.673828 372.394501,2676.359619 381.413940,2675.780762 
	C386.274078,2677.984619 390.350861,2679.887451 395.311584,2675.901855 
	C403.995880,2673.950195 411.997040,2674.474365 420.866272,2674.875488 
	C424.697052,2674.860596 427.649658,2674.214844 431.508331,2674.934326 
	C440.842041,2675.662842 449.282715,2673.798584 458.599487,2675.185059 
	C467.805389,2679.119629 476.762604,2675.106934 485.960144,2677.665039 
	C489.593933,2679.446045 491.858521,2681.690430 490.464417,2685.044922 
	C488.942444,2688.707520 485.170776,2688.706055 481.837341,2688.545166 
	C469.627533,2687.955322 457.242828,2689.106445 444.912201,2684.722168 
	C449.103363,2688.429932 451.478455,2691.754150 444.358429,2695.030762 
	C438.271271,2698.230957 432.779877,2696.771484 427.335938,2695.610107 
	C421.883270,2694.446777 416.487640,2692.906738 410.031372,2694.107910 
	C408.182648,2694.441895 407.117371,2694.577637 405.242554,2694.760986 
	C403.365082,2694.998779 402.286163,2695.070557 400.391541,2695.045654 
	C398.495911,2694.966309 397.412933,2694.987793 395.517822,2695.179932 
	C373.597870,2697.572998 352.248322,2698.075195 330.813507,2704.029053 
z"
        />
        <path
          fill="#3F5467"
          opacity="1.000000"
          stroke="none"
          d="
M330.298676,2683.369141 
	C326.593018,2684.485107 323.304565,2686.487061 318.790314,2686.017090 
	C316.353973,2682.933350 311.447021,2685.241455 310.144348,2680.688721 
	C314.098969,2680.031494 318.180420,2680.043213 323.137146,2680.056396 
	C326.660797,2679.641113 329.023743,2679.935791 330.298676,2683.369141 
z"
        />
        <path
          fill="#4C6174"
          opacity="1.000000"
          stroke="none"
          d="
M242.434952,2667.339600 
	C240.108200,2670.407959 237.376114,2669.961914 234.824890,2670.028076 
	C231.612274,2670.111816 229.104874,2671.127441 228.102783,2675.297119 
	C227.476715,2677.008057 226.962112,2677.977295 226.227173,2679.448730 
	C224.264099,2679.807373 222.769501,2679.289795 222.077667,2676.794189 
	C221.671707,2675.812500 221.367599,2675.446777 220.691620,2674.469727 
	C219.861710,2672.464111 219.862366,2671.053711 220.417267,2668.968750 
	C221.566132,2666.530518 223.196243,2665.651855 225.477829,2664.493408 
	C230.757858,2664.035645 235.466690,2663.956787 241.057770,2663.872314 
	C242.674042,2664.671387 242.984268,2665.615479 242.434952,2667.339600 
z"
        />
        <path
          fill="#4C6174"
          opacity="1.000000"
          stroke="none"
          d="
M148.153503,2676.816895 
	C157.284668,2672.831543 166.938232,2674.165283 176.502914,2674.212891 
	C177.932404,2674.220215 178.900482,2675.166016 179.823029,2677.134766 
	C173.801865,2678.248535 167.582657,2677.980469 161.306915,2679.465576 
	C157.224014,2680.432129 152.093628,2681.977783 148.153503,2676.816895 
z"
        />
        <path
          fill="#4C6174"
          opacity="1.000000"
          stroke="none"
          d="
M206.391815,2676.471436 
	C206.361298,2674.885254 206.724915,2673.790283 207.564941,2672.347656 
	C209.942139,2670.399902 211.905075,2670.242188 214.359894,2672.441162 
	C214.800385,2673.677734 214.820679,2674.218750 214.374313,2675.424316 
	C211.618958,2677.074219 209.424911,2678.831543 206.391815,2676.471436 
z"
        />
        <path
          fill="#3F5467"
          opacity="1.000000"
          stroke="none"
          d="
M214.716812,2676.089355 
	C213.927658,2675.734375 213.947647,2675.379883 213.978104,2674.493164 
	C215.904984,2671.878418 218.150024,2671.960449 221.268005,2673.478516 
	C221.997482,2674.354004 221.994370,2674.718018 221.983536,2675.630371 
	C219.825867,2676.148926 217.675919,2676.119385 214.716812,2676.089355 
z"
        />
        <path
          fill="#2B3E51"
          opacity="1.000000"
          stroke="none"
          d="
M384.571045,2678.722900 
	C375.121796,2678.907715 366.557831,2680.740967 356.920746,2679.846680 
	C355.259399,2679.107910 354.800079,2678.232178 354.695312,2676.460938 
	C356.652649,2670.629883 361.649750,2671.619385 364.879883,2670.563477 
	C341.241791,2663.923828 317.156128,2672.208984 292.422913,2671.399414 
	C291.003510,2671.847900 290.140106,2671.879639 288.628235,2671.915527 
	C284.654510,2668.697510 280.467316,2670.428223 276.667297,2670.002441 
	C274.275543,2669.734619 271.471710,2669.458496 271.137024,2666.864746 
	C270.689667,2663.397461 274.187897,2663.195068 276.522339,2662.264893 
	C277.110168,2662.030762 277.839294,2661.978027 278.474670,2662.061523 
	C294.790070,2664.203857 308.698029,2653.990234 324.421692,2653.072510 
	C324.866302,2653.046387 325.259857,2652.144287 325.896729,2650.926270 
	C327.932068,2647.612305 330.668640,2647.538574 334.229858,2647.598389 
	C338.093109,2648.621094 341.156616,2649.156982 345.036743,2647.530518 
	C354.304260,2646.972412 363.040405,2644.470215 371.309814,2651.112793 
	C373.051086,2651.518555 374.162537,2651.333008 375.676147,2650.733398 
	C376.806763,2649.371338 377.760315,2648.997314 379.543213,2649.753906 
	C382.345551,2652.987793 385.056885,2654.166260 388.628296,2654.001953 
	C393.798309,2653.763672 398.988403,2653.960938 405.084473,2653.956543 
	C415.952850,2653.472168 425.299927,2656.524414 435.420776,2659.393799 
	C436.928833,2659.884521 437.780121,2659.879150 439.269653,2659.856201 
	C444.455475,2656.284424 450.161591,2659.292969 455.632874,2656.781738 
	C457.646332,2656.366455 458.990631,2656.350098 461.006714,2656.366211 
	C463.117279,2657.337646 464.627625,2657.829590 466.956970,2656.997070 
	C468.893219,2656.390381 470.205994,2656.351074 472.139343,2657.137207 
	C474.214355,2658.838867 474.965973,2660.552734 475.299561,2663.182129 
	C475.046173,2666.810547 470.904755,2668.628906 473.018494,2672.923828 
	C471.226471,2680.326660 465.643463,2677.168457 460.778137,2677.974365 
	C451.348022,2677.895264 442.693787,2677.825928 433.123901,2677.738037 
	C432.686920,2673.801270 432.686920,2673.801270 437.471191,2670.804199 
	C431.489594,2672.350342 426.686707,2673.314209 422.312622,2677.186523 
	C413.217499,2677.833740 404.674194,2678.078857 395.224182,2678.354736 
	C391.465485,2680.074951 388.482117,2680.280029 384.571045,2678.722900 
z"
        />
        <path
          fill="#3F5467"
          opacity="1.000000"
          stroke="none"
          d="
M292.703125,2671.889404 
	C297.210632,2665.629395 304.467255,2665.101562 311.505737,2666.516846 
	C319.566711,2668.137939 326.905518,2668.364990 334.769012,2665.048096 
	C340.855988,2662.480957 348.225311,2662.640137 354.328979,2664.905762 
	C359.816620,2666.942383 364.673187,2668.933838 370.467438,2666.925537 
	C372.670502,2666.161621 374.707947,2667.161133 374.935516,2669.474609 
	C375.135712,2671.509766 373.035034,2671.678711 371.371979,2672.000000 
	C366.516937,2672.937988 361.685852,2674.000000 356.297180,2675.567871 
	C350.354095,2680.742188 344.919891,2685.279297 336.554779,2684.506348 
	C335.935211,2684.056152 334.935913,2684.083740 334.436096,2684.083008 
	C332.041901,2680.481201 328.201447,2680.244629 324.517883,2678.440918 
	C313.873474,2675.967773 303.706390,2673.965088 292.703125,2671.889404 
z"
        />
        <path
          fill="#495E72"
          opacity="1.000000"
          stroke="none"
          d="
M324.028564,2678.492188 
	C327.650818,2678.795166 333.062531,2675.704834 333.927002,2683.299316 
	C333.098633,2684.091553 332.260986,2684.100830 330.798431,2684.109131 
	C329.001953,2682.018799 326.497498,2682.230469 324.417725,2680.533447 
	C324.013824,2679.709961 324.015228,2679.362061 324.028564,2678.492188 
z"
        />
        <path
          fill="#23394D"
          opacity="1.000000"
          stroke="none"
          d="
M2534.802734,2978.482422 
	C2533.146729,2978.058350 2532.226318,2978.072021 2530.615723,2978.118408 
	C2524.100830,2981.191406 2520.340088,2977.269775 2517.166992,2973.664062 
	C2513.480469,2969.475342 2509.126465,2967.331055 2503.943604,2965.627197 
	C2489.391113,2960.842285 2476.832764,2952.009766 2463.643555,2944.527832 
	C2455.988037,2940.185303 2446.436768,2939.057861 2440.491943,2930.644043 
	C2439.331543,2921.748535 2445.691162,2921.540527 2451.035400,2920.096680 
	C2446.975586,2919.022705 2442.721680,2919.443359 2438.661865,2918.515137 
	C2434.252197,2917.506836 2431.380859,2915.368896 2433.619385,2909.651855 
	C2439.594971,2906.363525 2445.374268,2908.087891 2450.764893,2908.844238 
	C2474.344971,2912.153320 2497.848877,2909.013672 2521.361816,2908.553711 
	C2538.070312,2908.227051 2554.193604,2912.923828 2570.805664,2912.966309 
	C2575.137939,2912.977295 2579.162598,2914.927246 2582.963379,2917.107666 
	C2585.774414,2918.720459 2587.930420,2920.689941 2586.926758,2924.228027 
	C2586.010498,2927.456543 2583.127441,2927.619873 2580.440918,2927.613770 
	C2575.124756,2927.601562 2569.817139,2927.232422 2564.134277,2926.936279 
	C2566.942871,2928.534424 2569.683105,2929.504639 2572.107422,2931.110107 
	C2575.457031,2933.328857 2578.087402,2931.607910 2581.710938,2930.574463 
	C2593.269775,2927.277832 2602.625977,2915.791016 2616.500000,2920.714111 
	C2619.617432,2921.820557 2623.010742,2922.132324 2625.730957,2925.490234 
	C2625.525391,2927.361328 2624.983643,2928.543945 2624.414551,2929.713135 
	C2622.593750,2933.454102 2623.590332,2936.194092 2626.962402,2935.503662 
	C2634.389404,2933.982666 2640.528564,2936.238281 2646.509277,2940.127441 
	C2647.562012,2940.812012 2648.978760,2941.227051 2650.240234,2941.258057 
	C2672.037842,2941.798096 2693.330078,2945.563721 2714.363037,2951.082520 
	C2716.266113,2951.581787 2718.266846,2951.712402 2720.223877,2952.003174 
	C2725.321777,2952.760742 2725.322021,2952.758301 2722.116211,2959.354736 
	C2715.165771,2964.580811 2707.734375,2966.828857 2699.450439,2966.046387 
	C2697.475830,2965.860107 2695.279053,2965.599854 2693.493408,2966.238037 
	C2682.511230,2970.162354 2671.832764,2966.266357 2661.013184,2965.423828 
	C2648.178467,2964.425049 2636.085449,2959.313477 2622.909912,2959.635498 
	C2616.167969,2959.800537 2609.290771,2956.995605 2602.581055,2955.146240 
	C2596.729492,2953.533691 2591.090576,2953.929932 2585.309570,2954.445312 
	C2585.408447,2958.725342 2588.185791,2959.028320 2590.060547,2959.968018 
	C2599.350830,2964.625244 2609.422852,2967.508789 2619.608643,2967.743164 
	C2635.294434,2968.104492 2650.724854,2969.270996 2665.831543,2973.607666 
	C2668.656738,2974.418701 2671.890625,2973.149414 2674.410645,2975.265381 
	C2674.528809,2976.390381 2674.248535,2977.540527 2673.174316,2977.581787 
	C2663.232666,2977.964844 2653.316162,2978.735840 2643.375000,2976.878174 
	C2633.847412,2975.098389 2624.166504,2975.864502 2614.550293,2976.465576 
	C2613.794678,2976.512695 2613.109375,2977.687744 2612.153809,2979.106445 
	C2604.483154,2985.726807 2596.563232,2980.327148 2588.090088,2980.749756 
	C2580.124756,2978.570068 2573.090332,2977.574219 2566.060547,2983.355957 
	C2554.602783,2987.107910 2545.353271,2981.273926 2534.802734,2978.482422 
z"
        />
        <path
          fill="#3C556B"
          opacity="1.000000"
          stroke="none"
          d="
M2626.914551,2926.151611 
	C2620.527588,2923.720459 2614.372559,2924.251953 2608.599854,2922.682373 
	C2605.164307,2921.748291 2600.076904,2920.064453 2601.048584,2927.434326 
	C2601.305420,2929.383057 2599.164062,2929.868652 2597.444580,2929.914795 
	C2594.124756,2930.004395 2590.798584,2929.931885 2587.482666,2930.083740 
	C2584.614014,2930.214844 2581.709961,2930.371582 2584.356445,2934.604980 
	C2585.604736,2936.601807 2584.124756,2937.528564 2582.233887,2937.839355 
	C2579.129395,2938.349854 2576.075684,2937.578857 2573.595947,2936.066406 
	C2568.036377,2932.674316 2562.824219,2928.786133 2555.756592,2929.362793 
	C2554.468262,2929.467773 2553.241699,2928.082520 2553.158447,2926.727051 
	C2553.026367,2924.581543 2554.787842,2924.015869 2556.490723,2924.031738 
	C2564.773193,2924.109131 2573.086426,2923.383545 2581.323242,2924.781738 
	C2582.961670,2925.060059 2584.720459,2925.767334 2585.371094,2923.529053 
	C2585.973877,2921.455078 2584.375244,2920.720215 2582.800293,2919.998047 
	C2574.884277,2916.367432 2566.742920,2914.160156 2557.924805,2915.626465 
	C2554.962402,2916.118896 2551.925537,2916.334229 2549.054932,2915.272705 
	C2531.610596,2908.822266 2513.577881,2912.058105 2495.888916,2912.852295 
	C2488.028564,2913.205322 2479.980469,2914.601318 2472.185791,2913.793457 
	C2462.951904,2912.836426 2453.776855,2911.842529 2444.482178,2911.773926 
	C2441.558105,2911.752441 2438.641357,2910.713379 2434.883545,2910.116943 
	C2430.267578,2910.752686 2425.303467,2910.714111 2425.671875,2906.236328 
	C2426.188477,2899.959961 2431.964355,2901.646484 2436.329346,2901.982178 
	C2427.885010,2901.299805 2419.566162,2899.931396 2411.539307,2897.057617 
	C2407.719482,2895.689697 2403.703369,2894.664062 2405.522949,2888.541016 
	C2411.532959,2886.040039 2416.311035,2887.108887 2422.011719,2889.148438 
	C2431.543457,2892.559814 2441.863525,2894.149658 2452.248047,2894.000488 
	C2461.187744,2893.872314 2469.939941,2894.001465 2478.790771,2896.885986 
	C2485.464844,2899.061035 2493.085205,2899.698730 2499.980713,2895.444824 
	C2504.504150,2892.653809 2509.952637,2893.222656 2514.569580,2894.372559 
	C2521.060791,2895.989014 2527.713135,2895.773926 2534.195068,2897.411621 
	C2539.587402,2898.774170 2545.435303,2898.496582 2551.015137,2897.273193 
	C2563.844727,2894.459961 2576.630371,2896.478271 2589.407227,2897.610840 
	C2591.370361,2897.784912 2594.928467,2897.137695 2594.806641,2900.469238 
	C2594.678955,2903.959717 2591.296631,2903.771973 2588.683838,2903.992432 
	C2583.583008,2904.423096 2578.310303,2903.283691 2572.547119,2905.114990 
	C2579.305420,2911.868408 2586.108643,2915.216064 2595.027588,2914.097412 
	C2607.044678,2912.590332 2618.745117,2915.188477 2631.189209,2917.939209 
	C2638.116699,2916.574463 2643.530273,2919.028076 2649.697754,2921.487549 
	C2651.351807,2923.401123 2651.500488,2924.953857 2650.412598,2927.239746 
	C2642.426514,2928.517822 2635.091064,2927.276855 2626.914551,2926.151611 
z"
        />
        <path
          fill="#294159"
          opacity="1.000000"
          stroke="none"
          d="
M2650.828613,2927.940430 
	C2649.923584,2926.442627 2649.935791,2924.998535 2650.395020,2922.363770 
	C2652.158447,2920.989746 2653.492188,2921.036621 2655.339844,2920.486816 
	C2672.861572,2919.444580 2689.237793,2923.796387 2705.805420,2926.446045 
	C2720.441406,2928.787354 2734.720947,2932.846191 2749.525391,2937.338135 
	C2751.103271,2940.459229 2749.500732,2941.560303 2746.717285,2942.223633 
	C2732.703369,2942.813965 2719.666992,2939.827393 2706.410156,2939.528076 
	C2698.052490,2939.339600 2689.173584,2936.817139 2681.612305,2933.123047 
	C2671.861328,2928.359375 2662.000732,2927.897461 2650.828613,2927.940430 
z"
        />
        <path
          fill="#233A4F"
          opacity="1.000000"
          stroke="none"
          d="
M2768.132812,2940.977539 
	C2774.343994,2941.283447 2780.449707,2937.399902 2787.474121,2939.434814 
	C2781.877686,2945.210449 2775.336182,2945.825684 2768.132812,2940.977539 
z"
        />
        <path
          fill="#233A4F"
          opacity="1.000000"
          stroke="none"
          d="
M2746.449219,2942.546631 
	C2747.165039,2941.006592 2748.354736,2939.977295 2749.801270,2938.398926 
	C2753.037109,2938.187744 2756.158936,2937.390137 2759.495605,2939.512695 
	C2759.232422,2945.266846 2756.429199,2946.848145 2751.557373,2944.814697 
	C2750.033447,2944.178711 2748.468750,2943.640381 2746.449219,2942.546631 
z"
        />
        <path
          fill="#0B1926"
          opacity="1.000000"
          stroke="none"
          d="
M2028.669922,3614.007324 
	C2025.260254,3612.450439 2022.309082,3611.784180 2018.375244,3611.929443 
	C2017.503540,3607.816650 2017.418945,3603.737793 2017.154297,3598.801758 
	C2017.307739,3592.538330 2017.640991,3587.132080 2018.180908,3580.844238 
	C2021.722534,3575.148438 2026.202271,3573.763184 2032.105835,3573.651611 
	C2056.390869,3573.193115 2080.584961,3573.712158 2104.720703,3576.764648 
	C2110.716064,3577.522949 2116.911621,3577.791748 2122.173096,3580.618896 
	C2134.964355,3587.493408 2149.303223,3589.537354 2162.687988,3594.482422 
	C2167.256348,3596.170166 2171.718262,3595.642334 2176.817139,3594.454834 
	C2174.588379,3589.090088 2169.896240,3588.363281 2166.162109,3586.882568 
	C2152.848633,3581.602539 2139.428711,3576.595459 2126.369629,3570.689209 
	C2123.918701,3569.581055 2121.271729,3568.918701 2118.636719,3568.766846 
	C2096.703857,3567.501465 2075.331299,3561.979980 2053.326416,3560.697266 
	C2041.540894,3560.009766 2028.449585,3560.026123 2018.794189,3549.132812 
	C2015.634644,3533.276123 2017.692627,3518.394775 2020.835327,3502.700439 
	C2021.588745,3493.890869 2022.005737,3485.929199 2022.601929,3477.068848 
	C2031.362549,3472.938721 2038.661011,3477.917969 2046.099976,3480.391602 
	C2055.984375,3483.678467 2065.823486,3485.600098 2078.499268,3484.861816 
	C2069.710205,3475.172852 2059.899658,3472.042725 2049.095947,3471.066162 
	C2040.561890,3470.295166 2031.843018,3470.491699 2024.056396,3464.603027 
	C2023.842896,3455.933838 2023.964111,3447.883057 2024.011719,3438.906738 
	C2024.296265,3432.458008 2023.428101,3426.886963 2024.913696,3420.631348 
	C2025.933350,3413.803223 2026.620117,3407.737549 2027.468628,3400.853027 
	C2028.348389,3399.380371 2029.179077,3398.958740 2030.840332,3398.924805 
	C2040.448853,3407.932861 2041.241577,3407.862549 2050.221191,3397.084717 
	C2059.285156,3395.628418 2067.167236,3398.180664 2074.741699,3401.118164 
	C2080.058838,3403.180176 2085.343018,3404.243408 2091.653320,3404.830322 
	C2094.271240,3406.449951 2095.275391,3408.218262 2094.896973,3411.300537 
	C2085.331543,3414.808594 2076.913818,3420.696045 2066.358887,3420.243408 
	C2070.616699,3422.053467 2075.004883,3422.910889 2079.138184,3422.000732 
	C2096.533447,3418.169678 2109.169678,3428.003906 2122.208008,3436.583984 
	C2125.893311,3439.009277 2129.014648,3442.545166 2126.905029,3447.534912 
	C2124.861816,3452.367676 2120.325439,3453.059082 2115.890625,3452.250000 
	C2108.115967,3450.832275 2100.626953,3451.767578 2093.174316,3453.866455 
	C2085.336182,3456.073975 2077.406006,3454.260254 2069.109131,3453.843506 
	C2069.251709,3459.332031 2072.834961,3460.805664 2076.357422,3460.998779 
	C2084.289795,3461.433350 2092.418457,3462.108887 2099.533691,3457.177490 
	C2102.323730,3455.243896 2105.354736,3454.253418 2108.809570,3453.906494 
	C2134.022949,3451.376465 2159.380127,3454.154297 2184.623047,3451.628662 
	C2192.516113,3450.838867 2200.474854,3453.431396 2207.955078,3456.422852 
	C2210.437256,3457.415283 2212.849854,3458.990479 2212.670166,3462.203857 
	C2212.516113,3464.965088 2210.076660,3465.756348 2208.065674,3466.936035 
	C2198.062012,3472.805420 2187.406982,3475.740967 2175.701416,3475.180176 
	C2168.058594,3474.813721 2160.370117,3474.901855 2152.731689,3475.785645 
	C2148.117432,3476.319824 2141.615723,3473.504883 2142.410156,3482.827881 
	C2142.652344,3485.673340 2138.562012,3485.810791 2135.853027,3485.741211 
	C2131.134766,3485.619873 2126.897461,3483.192871 2121.751709,3482.786133 
	C2128.375977,3485.064209 2134.103027,3488.216064 2140.016113,3490.951416 
	C2146.640137,3494.014893 2150.338379,3499.829834 2154.594727,3505.503662 
	C2159.380615,3511.883057 2167.447266,3512.033203 2174.830322,3511.031738 
	C2179.585938,3510.386719 2184.176514,3510.144531 2188.603027,3511.813965 
	C2200.190918,3516.183838 2212.050293,3519.366211 2224.339355,3520.967041 
	C2232.486328,3522.028076 2237.597168,3528.250000 2244.250488,3533.227051 
	C2245.924072,3539.109863 2242.153809,3540.186279 2238.468994,3540.692627 
	C2227.885742,3542.147705 2217.263184,3542.814941 2207.411133,3537.481934 
	C2203.235352,3535.221680 2200.382568,3536.361816 2195.938721,3538.038086 
	C2185.875977,3541.833740 2175.119873,3547.387939 2163.602051,3539.719727 
	C2162.899658,3533.028320 2168.714600,3534.458496 2172.297119,3531.673828 
	C2142.128906,3515.206299 2109.537109,3512.215332 2076.589355,3510.802734 
	C2096.682373,3519.618164 2115.497070,3533.303467 2139.759521,3524.735840 
	C2146.927979,3522.204102 2152.643311,3527.372559 2156.988770,3538.249023 
	C2153.075439,3544.317627 2147.950928,3542.965820 2142.211914,3541.509766 
	C2140.075928,3540.563965 2138.615967,3539.698486 2136.305176,3540.738281 
	C2132.508057,3542.509521 2129.208252,3541.766357 2125.917480,3541.379395 
	C2122.659668,3540.996338 2119.400635,3540.617432 2115.976807,3540.760986 
	C2123.554688,3543.463379 2129.958496,3548.263672 2136.958252,3551.826416 
	C2151.927734,3559.445312 2168.484863,3561.541748 2185.023682,3562.182861 
	C2192.352295,3562.467041 2199.417969,3560.671387 2199.616211,3571.546631 
	C2199.688965,3575.530273 2203.713623,3575.249756 2206.479248,3575.937988 
	C2211.999512,3577.311279 2217.796875,3576.419678 2223.301758,3577.740967 
	C2228.533203,3578.996094 2233.448242,3581.022949 2236.667969,3586.686523 
	C2237.282715,3592.416504 2234.364014,3595.709717 2230.291016,3599.070312 
	C2227.540039,3600.677490 2224.639404,3600.556885 2222.872803,3603.553223 
	C2222.433594,3604.460693 2222.257812,3604.822998 2221.814453,3605.725098 
	C2221.178955,3606.971924 2220.790527,3607.667725 2220.078613,3608.860840 
	C2218.907959,3610.681152 2217.829346,3611.799805 2216.185059,3613.153809 
	C2215.439941,3613.713379 2215.137695,3613.925293 2214.383789,3614.440430 
	C2213.640381,3614.947998 2213.348145,3615.152588 2212.643066,3615.685303 
	C2211.426758,3616.696777 2210.731201,3617.469971 2209.986328,3618.889648 
	C2209.234863,3620.357178 2208.670654,3621.310791 2207.865967,3622.787109 
	C2206.754883,3624.603760 2206.146973,3626.063721 2204.549805,3627.526367 
	C2203.717041,3627.853516 2203.363037,3627.942871 2203.056885,3627.858398 
	C2190.939941,3624.504883 2178.359375,3625.337646 2166.098633,3623.702393 
	C2155.085449,3622.233643 2144.071045,3619.229736 2133.660156,3615.303467 
	C2126.020996,3612.422607 2117.652344,3611.904785 2110.489990,3606.969238 
	C2106.318848,3604.095459 2099.928711,3605.277832 2094.890137,3606.287842 
	C2087.734619,3607.722412 2081.227783,3606.760986 2074.580078,3604.538574 
	C2073.049805,3604.026855 2071.306641,3604.152588 2068.842773,3603.977783 
	C2065.266357,3601.913086 2061.812500,3601.712891 2058.718994,3600.551270 
	C2053.142090,3598.457520 2050.085693,3598.665283 2049.196045,3606.545654 
	C2048.233154,3615.070801 2040.631104,3616.281494 2032.511475,3614.462891 
	C2031.108398,3614.008057 2030.221680,3614.006348 2028.669922,3614.007324 
z"
        />
        <path
          fill="#0B1926"
          opacity="1.000000"
          stroke="none"
          d="
M2296.625732,3663.269287 
	C2287.683105,3666.387207 2280.284912,3660.932373 2272.206299,3661.030762 
	C2270.593262,3661.050537 2268.957031,3660.049072 2267.357666,3660.119141 
	C2260.320557,3660.427002 2253.562256,3659.726318 2248.100830,3655.314453 
	C2247.132568,3656.201172 2246.336182,3656.572510 2246.279297,3657.034912 
	C2244.941162,3667.900879 2244.954834,3668.025635 2234.317871,3667.951416 
	C2228.383545,3667.910156 2222.385010,3668.753906 2216.545898,3666.565918 
	C2212.329346,3664.986084 2208.878174,3667.678711 2205.302979,3669.360596 
	C2201.590576,3671.106689 2198.568848,3674.997070 2193.520752,3672.455566 
	C2191.661377,3671.519531 2189.841309,3673.445312 2188.190430,3674.539062 
	C2185.768555,3676.143799 2183.169678,3676.388184 2180.642822,3674.998535 
	C2172.512451,3670.528564 2163.402832,3670.145508 2154.685547,3670.248779 
	C2140.795410,3670.413086 2130.394531,3662.447510 2118.799316,3657.161133 
	C2117.236328,3656.448730 2116.258545,3654.833252 2116.991699,3653.023193 
	C2117.878418,3650.833984 2119.791260,3650.098145 2121.879395,3651.062744 
	C2127.894287,3653.841309 2134.653564,3653.753662 2140.736572,3656.847412 
	C2149.466309,3661.286865 2159.579834,3659.482422 2168.984131,3661.180420 
	C2180.972656,3663.344727 2193.045654,3659.180420 2205.106689,3661.689697 
	C2209.662109,3662.637695 2213.659424,3658.888916 2213.752930,3654.213379 
	C2213.797607,3651.981934 2212.765137,3649.729004 2212.139648,3646.729248 
	C2222.863525,3645.283447 2233.482910,3647.528564 2244.208008,3648.155029 
	C2249.906738,3648.487793 2253.390869,3647.998291 2257.523438,3642.752197 
	C2264.667969,3633.681641 2274.536621,3627.839111 2287.583740,3631.187744 
	C2283.809570,3628.795898 2281.000977,3626.282471 2284.371094,3621.194336 
	C2291.824951,3617.838867 2298.220947,3619.068359 2303.438477,3623.976074 
	C2308.900146,3629.113525 2305.671143,3635.162109 2303.798340,3640.723389 
	C2301.361084,3647.960205 2299.676514,3655.411865 2296.625732,3663.269287 
z"
        />
        <path
          fill="#091825"
          opacity="1.000000"
          stroke="none"
          d="
M2015.303589,3666.146484 
	C2013.578979,3659.297852 2016.221069,3652.597412 2015.910156,3644.855957 
	C2025.981201,3644.468994 2036.022217,3645.207764 2045.663818,3646.677246 
	C2052.936768,3647.785889 2061.175537,3648.977539 2067.882080,3653.751709 
	C2071.368408,3656.233643 2075.755127,3657.228760 2080.043457,3657.913086 
	C2081.996826,3658.224854 2084.072510,3657.987793 2085.778076,3659.254395 
	C2087.661377,3660.653076 2089.774170,3662.143799 2088.989746,3664.864014 
	C2088.380127,3666.978271 2086.372314,3667.650146 2084.277344,3667.894043 
	C2079.379639,3668.464111 2074.338379,3667.102783 2069.637695,3668.805908 
	C2067.872803,3671.691895 2070.959961,3673.112305 2071.217529,3675.238770 
	C2071.745361,3679.592041 2068.957275,3681.385498 2064.695068,3679.814697 
	C2059.455566,3677.883301 2055.484863,3674.531982 2051.132812,3670.998535 
	C2043.671631,3664.941650 2034.361938,3660.383301 2023.789795,3664.343018 
	C2021.280884,3665.282715 2018.776123,3666.109131 2015.303589,3666.146484 
z"
        />
        <path
          fill="#2C2520"
          opacity="1.000000"
          stroke="none"
          d="
M2296.078613,3664.476074 
	C2296.586426,3654.678467 2298.809326,3645.793213 2302.050049,3637.210693 
	C2306.715820,3624.853027 2301.723877,3624.462891 2292.568604,3622.192383 
	C2290.395020,3621.653320 2287.994141,3622.031494 2284.312500,3621.408447 
	C2281.745117,3612.797363 2284.569580,3606.679688 2291.808838,3602.888184 
	C2295.362305,3601.026855 2298.457031,3598.709717 2301.285156,3595.868164 
	C2303.786133,3593.355469 2306.331543,3590.404541 2311.148438,3592.695312 
	C2315.070312,3610.788818 2311.119873,3627.613037 2307.183105,3645.305176 
	C2306.776367,3647.964355 2306.065430,3649.531494 2303.989746,3651.250000 
	C2300.526123,3656.353271 2300.020996,3661.731445 2297.989258,3667.369873 
	C2297.137451,3667.025635 2296.632568,3666.037842 2296.078613,3664.476074 
z"
        />
        <path
          fill="#2C2520"
          opacity="1.000000"
          stroke="none"
          d="
M2283.735840,3621.732422 
	C2283.409424,3626.202148 2285.980713,3627.623535 2289.416016,3628.927246 
	C2292.193359,3629.981689 2296.284180,3629.596924 2297.213623,3634.552734 
	C2293.135498,3635.553467 2289.430420,3633.888428 2285.770264,3633.489746 
	C2278.298584,3632.675781 2271.357422,3632.902344 2265.883789,3638.967285 
	C2263.183838,3641.958984 2257.014648,3642.152344 2258.831055,3648.906006 
	C2259.375977,3650.930908 2255.665527,3652.373291 2253.386719,3651.849365 
	C2240.270996,3648.833740 2226.205811,3652.526123 2212.823975,3646.071777 
	C2204.979736,3642.585205 2205.798828,3635.394531 2204.068848,3628.633301 
	C2204.114990,3626.125977 2203.945312,3624.358887 2206.160645,3622.747559 
	C2210.342285,3625.518311 2210.470947,3629.752686 2212.118896,3633.291992 
	C2214.398193,3638.187988 2218.297852,3640.820557 2224.162109,3641.926270 
	C2239.149902,3645.659180 2250.624512,3639.559814 2262.067139,3630.917480 
	C2269.409424,3627.982910 2273.634766,3620.724365 2282.256836,3620.680664 
	C2282.943115,3620.929932 2283.471680,3621.464844 2283.735840,3621.732422 
z"
        />
        <path
          fill="#040F1A"
          opacity="1.000000"
          stroke="none"
          d="
M2032.181641,3614.749756 
	C2035.062988,3614.254639 2037.819214,3613.069336 2040.610718,3612.093750 
	C2047.592041,3609.653320 2047.707886,3609.598877 2045.297119,3602.007080 
	C2043.949951,3597.764893 2046.365356,3596.402588 2049.611328,3596.187744 
	C2056.402344,3595.738281 2062.770508,3596.926758 2067.684082,3603.346680 
	C2062.570801,3603.809326 2065.322510,3612.338867 2059.535156,3611.932861 
	C2055.483887,3611.648682 2051.524658,3612.464844 2048.387451,3615.073730 
	C2044.405151,3618.385254 2039.939209,3618.213135 2035.312744,3617.778564 
	C2033.863892,3617.642578 2032.988892,3616.717285 2032.181641,3614.749756 
z"
        />
        <path
          fill="#294159"
          opacity="1.000000"
          stroke="none"
          d="
M2881.663086,3269.647949 
	C2886.346436,3276.112061 2893.837891,3275.711670 2899.673340,3277.494873 
	C2910.575928,3280.826660 2920.969482,3285.960693 2932.917725,3286.698242 
	C2939.306641,3287.093018 2945.538574,3291.814209 2952.545166,3292.794434 
	C2955.065674,3293.146973 2956.392578,3295.714355 2953.661133,3297.949707 
	C2950.446045,3300.581055 2948.963867,3303.474854 2951.661621,3307.992188 
	C2952.194092,3308.538818 2952.120117,3309.153320 2952.059082,3309.456543 
	C2950.905273,3311.033203 2949.785156,3312.285889 2948.634766,3314.325928 
	C2947.266602,3316.360596 2946.551758,3318.045410 2944.838867,3319.919434 
	C2936.273926,3323.173340 2927.871582,3323.337646 2919.882080,3321.505127 
	C2912.655762,3319.847656 2905.554199,3319.661621 2897.577148,3319.404785 
	C2892.533936,3316.298828 2893.390381,3311.771240 2893.035645,3306.871094 
	C2891.867188,3301.495605 2892.004150,3296.541992 2889.011475,3291.711182 
	C2886.767822,3285.413086 2883.843262,3280.353516 2879.109131,3275.579590 
	C2878.239014,3274.179932 2877.926514,3273.296143 2877.825684,3271.656250 
	C2878.470947,3269.292969 2879.349121,3268.227051 2881.663086,3269.647949 
z"
        />
        <path
          fill="#838F9A"
          opacity="1.000000"
          stroke="none"
          d="
M2877.250488,3270.578613 
	C2878.478027,3271.678711 2879.058350,3272.407471 2879.803223,3273.582520 
	C2877.010986,3279.951416 2871.379395,3279.275635 2865.427734,3279.454834 
	C2857.788330,3271.039551 2848.445801,3268.025879 2838.205078,3266.951416 
	C2833.063721,3266.411621 2829.226807,3268.087158 2827.088623,3273.895264 
	C2820.363281,3276.400146 2813.946289,3280.123291 2807.982422,3272.515137 
	C2808.143555,3267.267822 2811.524414,3266.514893 2815.867676,3266.191895 
	C2817.890137,3266.593262 2816.107422,3266.376221 2817.413574,3266.634033 
	C2818.282227,3266.804932 2819.124268,3267.034424 2820.006104,3266.770996 
	C2819.317871,3266.852295 2818.649170,3266.744629 2817.426025,3266.128418 
	C2816.600342,3265.302734 2816.337158,3264.909668 2815.787598,3263.870117 
	C2814.616455,3257.912354 2810.499756,3256.209473 2806.168945,3254.395508 
	C2802.562744,3252.884033 2797.377930,3252.958740 2797.555664,3246.586914 
	C2815.343750,3249.058838 2831.925049,3255.085449 2848.984863,3259.285645 
	C2858.572754,3261.646240 2868.423096,3263.992920 2877.250488,3270.578613 
z"
        />
        <path
          fill="#AAB7BE"
          opacity="1.000000"
          stroke="none"
          d="
M2797.375000,3245.716797 
	C2798.257568,3247.757324 2798.882080,3249.391602 2800.784424,3249.638184 
	C2804.804688,3250.160156 2808.129883,3252.938232 2811.950439,3253.429199 
	C2818.042725,3254.212646 2818.426025,3257.900146 2817.820068,3263.398926 
	C2810.034912,3268.487549 2803.074219,3263.958252 2795.865234,3262.407471 
	C2792.717285,3261.730225 2789.056641,3259.691162 2786.579834,3262.555176 
	C2784.404785,3265.070557 2787.629150,3268.061279 2787.886719,3271.688965 
	C2787.906250,3273.464600 2787.669922,3274.456055 2786.790039,3276.060547 
	C2784.494141,3278.133545 2782.374023,3278.501953 2779.318359,3278.322266 
	C2777.989746,3278.021973 2777.477051,3277.838379 2776.287598,3277.205566 
	C2774.525879,3275.549072 2773.934082,3274.103760 2773.475098,3271.811523 
	C2773.280518,3269.426758 2773.663086,3267.750977 2773.424072,3266.131836 
	C2771.685791,3254.360352 2771.659912,3254.364258 2783.511719,3249.824219 
	C2785.504883,3247.773926 2786.940918,3246.095703 2789.377930,3244.592285 
	C2792.250244,3244.703369 2794.506348,3245.050293 2797.375000,3245.716797 
z"
        />
        <path
          fill="#838F9A"
          opacity="1.000000"
          stroke="none"
          d="
M2789.606445,3243.724121 
	C2790.093506,3246.972168 2788.494629,3248.571533 2786.029297,3250.375000 
	C2781.578369,3253.103271 2778.624023,3249.911621 2775.228516,3249.193115 
	C2768.596680,3247.790283 2762.168457,3244.172607 2754.366211,3247.070801 
	C2745.382080,3247.658691 2739.887207,3242.205566 2733.489258,3237.093262 
	C2732.044678,3233.683350 2732.732178,3231.335449 2735.452148,3228.901611 
	C2743.313477,3228.681396 2749.662109,3232.277100 2757.317871,3233.697510 
	C2768.338623,3237.248291 2779.177734,3238.831055 2789.606445,3243.724121 
z"
        />
        <path
          fill="#838F9A"
          opacity="1.000000"
          stroke="none"
          d="
M2985.388672,1872.036987 
	C2981.710205,1870.959595 2979.675781,1868.780396 2984.881836,1865.576904 
	C2985.603271,1867.306885 2985.628906,1869.210693 2985.388672,1872.036987 
z"
        />
        <path
          fill="#495E72"
          opacity="1.000000"
          stroke="none"
          d="
M215.186920,2732.034180 
	C216.518372,2733.044922 217.047424,2734.039307 217.794937,2735.556641 
	C208.553146,2735.755127 198.983459,2736.995850 188.865250,2734.094238 
	C185.074768,2732.256348 181.793961,2731.939209 178.421448,2731.930664 
	C175.607483,2731.923340 174.140503,2730.447021 174.092255,2726.846191 
	C176.705078,2725.740234 179.370941,2725.643066 181.905182,2725.014893 
	C190.380081,2722.914795 198.460281,2723.372803 205.228958,2729.342529 
	C208.096573,2731.871582 211.105194,2731.931396 215.186920,2732.034180 
z"
        />
        <path
          fill="#556A7C"
          opacity="1.000000"
          stroke="none"
          d="
M214.486572,2726.257324 
	C214.303192,2724.808594 214.606812,2722.650146 215.742386,2722.920654 
	C221.696777,2724.338623 228.559387,2721.733643 233.788391,2727.653076 
	C229.194534,2728.297852 224.483551,2728.138672 218.886841,2727.989746 
	C217.002289,2727.490967 216.003448,2726.981689 214.486572,2726.257324 
z"
        />
        <path
          fill="#3C556B"
          opacity="1.000000"
          stroke="none"
          d="
M2738.638184,3004.632080 
	C2726.653076,3004.747314 2716.413818,2999.863281 2704.798096,2998.032959 
	C2703.110840,2995.808105 2699.445801,2996.632080 2699.193604,2993.020752 
	C2702.415771,2991.250977 2706.115723,2992.394531 2709.574219,2992.011719 
	C2712.392578,2991.699951 2715.692139,2992.973877 2717.824951,2988.864746 
	C2719.297119,2986.407959 2721.154297,2985.252441 2723.081787,2985.432861 
	C2736.368652,2986.677490 2750.074951,2984.948486 2763.307861,2991.549316 
	C2767.556396,2997.094238 2772.362793,2999.766357 2779.236328,2999.993164 
	C2781.203125,2999.892090 2782.247314,3000.215088 2783.507324,3001.479980 
	C2785.536865,3001.445557 2787.156494,3001.016113 2789.358887,3000.334473 
	C2793.000244,2999.679688 2795.692871,3001.053955 2799.233154,3001.936035 
	C2802.206055,3007.673340 2797.661865,3008.602051 2794.376953,3008.614258 
	C2775.972656,3008.683350 2757.438232,3010.231201 2738.638184,3004.632080 
z"
        />
        <path
          fill="#43596D"
          opacity="1.000000"
          stroke="none"
          d="
M2580.872559,3010.091309 
	C2576.386475,3009.965332 2572.606445,3010.375000 2571.459473,3005.481934 
	C2570.599609,3001.812256 2565.489258,3002.819092 2564.081543,2998.702148 
	C2574.458008,2998.452881 2584.946045,2997.705322 2595.551758,2999.450684 
	C2602.410889,3000.579590 2609.859863,2998.182617 2617.034912,2997.287354 
	C2618.656982,2997.084717 2620.372314,2995.952393 2621.817139,2996.273193 
	C2633.239014,2998.810791 2645.463623,2995.690918 2656.969482,3001.657471 
	C2651.537842,3007.789795 2644.661865,2999.746338 2638.621094,3003.561523 
	C2641.625488,3008.647949 2647.752197,3007.665527 2651.586182,3011.529541 
	C2643.615479,3012.991211 2635.459961,3010.706299 2627.316162,3009.534912 
	C2615.667480,3007.859619 2604.304443,3007.040527 2592.731445,3009.709961 
	C2589.239014,3010.516113 2585.435059,3009.973389 2580.872559,3010.091309 
z"
        />
        <path
          fill="#172B3E"
          opacity="1.000000"
          stroke="none"
          d="
M2764.724121,2992.187012 
	C2749.186523,2990.872314 2734.487793,2989.467529 2718.917236,2988.087646 
	C2715.167969,2986.463623 2712.888428,2984.290527 2710.858398,2980.574951 
	C2716.508789,2979.897461 2721.533447,2979.945557 2725.561768,2976.668213 
	C2726.229004,2976.125244 2727.515625,2976.003906 2728.435059,2976.161377 
	C2744.689697,2978.942871 2761.433838,2977.790771 2777.474609,2982.257568 
	C2778.112549,2982.435059 2778.790039,2982.550293 2779.366455,2982.852539 
	C2781.073486,2983.745605 2783.423096,2984.351074 2782.918457,2986.907227 
	C2782.579590,2988.625732 2780.951416,2989.506104 2779.207520,2989.764404 
	C2774.641846,2990.442139 2770.328613,2992.598145 2764.724121,2992.187012 
z"
        />
        <path
          fill="#3F5467"
          opacity="1.000000"
          stroke="none"
          d="
M2534.375977,2978.654541 
	C2540.432129,2977.341797 2546.553711,2978.868896 2552.544434,2980.557617 
	C2556.445068,2981.657715 2560.282959,2982.001709 2565.101074,2981.955811 
	C2571.874512,2979.176025 2578.678467,2980.817871 2585.312012,2978.123535 
	C2594.210693,2976.880371 2602.108887,2979.756592 2611.066406,2979.897461 
	C2616.997314,2985.635010 2617.100098,2987.339355 2611.170898,2988.309082 
	C2598.842041,2990.325928 2586.690430,2994.891113 2573.667480,2989.641113 
	C2569.103271,2987.801270 2562.925293,2988.596680 2556.686035,2988.100586 
	C2547.617920,2988.035889 2540.955566,2984.116699 2534.375977,2978.654541 
z"
        />
        <path
          fill="#243B51"
          opacity="1.000000"
          stroke="none"
          d="
M2783.926270,3001.236816 
	C2782.846436,3001.551270 2781.775635,3001.227051 2780.357422,3000.446777 
	C2780.201904,2998.746338 2780.414307,2997.124023 2781.820557,2997.096680 
	C2784.045898,2997.053467 2783.721680,2999.110840 2783.926270,3001.236816 
z"
        />
        <path
          fill="#2F465E"
          opacity="1.000000"
          stroke="none"
          d="
M2738.061279,3004.844238 
	C2741.181152,3003.832275 2744.520752,3004.371338 2747.682373,3003.120361 
	C2749.558838,3002.377686 2751.615723,3001.751953 2753.349609,3003.034912 
	C2760.182373,3008.091064 2768.013428,3006.002197 2775.329102,3005.673828 
	C2780.516846,3005.441162 2785.533203,3004.898193 2790.756592,3005.758301 
	C2793.681885,3006.239502 2797.453369,3007.295166 2799.784668,3002.771484 
	C2806.044922,3005.289551 2812.645264,3003.595703 2819.024902,3003.984131 
	C2822.503418,3004.196045 2826.277588,3003.281494 2829.264404,3007.435059 
	C2830.196533,3008.613281 2830.740234,3008.842041 2831.806641,3008.282227 
	C2832.141113,3007.939941 2833.009766,3007.923584 2833.444336,3007.931641 
	C2835.988770,3009.490723 2837.601318,3008.503418 2839.519043,3006.487793 
	C2842.330322,3006.121338 2844.795654,3005.592529 2847.507324,3007.490234 
	C2848.760254,3008.803711 2849.055664,3009.743652 2848.409180,3011.439453 
	C2845.589844,3013.594727 2841.642334,3013.962402 2842.546631,3018.079346 
	C2843.238281,3021.227783 2845.674561,3023.463135 2848.853760,3023.708008 
	C2858.939209,3024.484131 2868.516113,3029.148926 2879.171143,3026.526367 
	C2883.057373,3025.570068 2888.362793,3026.690430 2891.894531,3031.342285 
	C2885.370850,3032.103271 2878.663330,3032.137939 2871.057129,3032.230957 
	C2867.988037,3032.185303 2865.818115,3032.081787 2862.835693,3031.974365 
	C2855.666748,3031.608154 2849.104492,3032.550537 2842.624023,3028.500977 
	C2838.458984,3027.614990 2835.258789,3025.479980 2830.777832,3025.956055 
	C2828.411133,3023.952393 2826.156006,3024.031250 2823.928223,3023.977051 
	C2821.714844,3023.923340 2819.385986,3024.072998 2818.160156,3020.730713 
	C2814.545898,3013.764648 2808.523682,3016.870605 2803.667236,3016.323242 
	C2789.557373,3014.733154 2775.370605,3013.912842 2760.622314,3013.496094 
	C2754.989502,3011.442139 2749.258057,3011.712402 2743.957764,3010.101807 
	C2741.269287,3009.284424 2739.024414,3008.483398 2738.061279,3004.844238 
z"
        />
        <path
          fill="#3C556B"
          opacity="1.000000"
          stroke="none"
          d="
M2848.654785,3012.333496 
	C2847.951904,3011.151123 2847.972168,3010.253174 2847.996338,3008.682617 
	C2865.390381,3010.854736 2882.868896,3012.515869 2900.516846,3011.985840 
	C2903.178955,3011.905762 2905.846436,3011.994385 2908.511475,3012.009521 
	C2912.055664,3012.029785 2916.260986,3012.926514 2916.215576,3016.629395 
	C2916.158936,3021.226074 2911.377686,3019.879150 2908.346191,3019.995117 
	C2888.295166,3020.762695 2868.641357,3018.215332 2848.654785,3012.333496 
z"
        />
        <path
          fill="#3C556B"
          opacity="1.000000"
          stroke="none"
          d="
M2839.306152,3006.112549 
	C2839.802490,3007.842773 2840.481689,3010.032471 2838.349121,3010.994629 
	C2836.573242,3011.795654 2835.558105,3010.234131 2834.207520,3008.508301 
	C2835.476318,3007.348633 2837.073730,3006.757324 2839.306152,3006.112549 
z"
        />
        <path
          fill="#3C556B"
          opacity="1.000000"
          stroke="none"
          d="
M2831.565918,3007.934326 
	C2831.685791,3008.606445 2831.232910,3009.279053 2830.071533,3008.258301 
	C2830.224121,3007.934570 2830.607422,3007.931641 2831.565918,3007.934326 
z"
        />
        <path
          fill="#595B54"
          opacity="1.000000"
          stroke="none"
          d="
M2969.864258,3387.483398 
	C2964.305664,3389.859375 2965.280518,3395.715576 2962.618408,3399.206299 
	C2961.500732,3400.672119 2961.736084,3403.394287 2959.211914,3403.511963 
	C2955.994629,3403.662109 2956.059082,3400.672363 2955.271240,3398.656006 
	C2953.608154,3394.398682 2953.104980,3389.559814 2948.366211,3386.261475 
	C2945.801025,3383.857910 2945.772949,3381.535645 2947.600586,3378.431152 
	C2948.889160,3377.981689 2949.659424,3378.012695 2950.116699,3378.406982 
	C2956.498291,3383.908936 2957.585938,3384.135010 2964.370361,3380.343750 
	C2966.907227,3378.926514 2969.065918,3376.740479 2973.243652,3376.994629 
	C2972.059326,3380.382812 2969.236084,3383.133301 2969.864258,3387.483398 
z"
        />
        <path
          fill="#131C25"
          opacity="1.000000"
          stroke="none"
          d="
M2948.100586,3377.392578 
	C2948.126953,3379.967041 2947.989746,3382.152100 2947.779053,3385.154053 
	C2946.000488,3387.008545 2945.584961,3389.546631 2942.618896,3389.916016 
	C2941.627930,3389.647461 2941.243164,3389.285645 2940.513916,3388.207031 
	C2939.199951,3383.579590 2940.518066,3380.188477 2943.178955,3376.462158 
	C2944.627686,3376.012695 2945.460693,3376.063965 2946.913574,3376.188232 
	C2947.533447,3376.261230 2947.936768,3377.003418 2948.100586,3377.392578 
z"
        />
        <path
          fill="#374549"
          opacity="1.000000"
          stroke="none"
          d="
M2944.355469,3411.517578 
	C2943.991455,3412.722656 2943.962891,3413.572510 2943.927246,3415.059570 
	C2946.079346,3421.013184 2942.931641,3421.573730 2939.122314,3420.573730 
	C2919.887207,3415.522949 2900.885986,3409.659424 2881.174805,3403.401367 
	C2878.608887,3401.193115 2879.186523,3399.129150 2880.238770,3396.358887 
	C2888.862061,3389.038574 2888.288086,3377.917969 2894.333008,3369.484375 
	C2899.112549,3368.640381 2901.484375,3371.034668 2901.206787,3374.650391 
	C2900.606201,3382.472168 2905.517090,3383.814209 2912.120605,3384.620117 
	C2919.339355,3386.750488 2924.930908,3391.024170 2932.097412,3391.612305 
	C2937.978027,3392.094727 2939.689453,3397.092285 2940.762695,3402.866699 
	C2940.852539,3405.452148 2941.515869,3407.113037 2943.000488,3409.166016 
	C2943.409912,3409.693848 2943.841309,3409.967773 2944.034668,3410.134521 
	C2944.228271,3410.301270 2944.690918,3411.162354 2944.355469,3411.517578 
z"
        />
        <path
          fill="#131C25"
          opacity="1.000000"
          stroke="none"
          d="
M2879.039795,3401.520020 
	C2891.688477,3404.165527 2903.992676,3405.806641 2915.619873,3411.103271 
	C2924.063721,3414.949463 2934.241943,3413.846680 2942.721436,3418.504883 
	C2942.762695,3418.527100 2943.377930,3417.505371 2943.822510,3416.334229 
	C2948.272705,3416.464844 2950.276855,3421.443604 2955.581543,3421.938477 
	C2954.877441,3428.406982 2950.839844,3428.566406 2945.633301,3426.909180 
	C2935.887939,3423.807129 2926.233643,3418.990479 2916.018555,3419.366211 
	C2907.980713,3419.661865 2903.661377,3413.626221 2897.048340,3411.862793 
	C2890.470459,3410.108887 2883.621094,3408.404785 2878.169678,3402.843262 
	C2878.013916,3402.161133 2878.583496,3401.421875 2879.039795,3401.520020 
z"
        />
        <path
          fill="#DFD9CC"
          opacity="1.000000"
          stroke="none"
          d="
M2940.822266,3406.255859 
	C2939.024170,3398.377686 2936.176514,3393.524658 2927.079346,3393.309082 
	C2922.308838,3393.196289 2917.629395,3389.242676 2912.404297,3386.533203 
	C2912.096924,3381.720459 2915.465820,3383.055664 2917.589355,3383.250244 
	C2924.386963,3383.873047 2931.313721,3383.910645 2938.462891,3386.645508 
	C2939.548828,3386.925049 2939.966797,3387.095947 2940.862305,3387.747070 
	C2941.339844,3388.227783 2941.564453,3389.160645 2941.788574,3389.584961 
	C2942.153320,3394.740723 2942.293945,3399.472168 2942.502930,3405.092773 
	C2942.173828,3406.205811 2941.776367,3406.429932 2940.822266,3406.255859 
z"
        />
        <path
          fill="#9FA2A1"
          opacity="1.000000"
          stroke="none"
          d="
M2940.185059,3406.435059 
	C2940.650879,3405.861084 2941.036133,3405.864990 2941.996094,3405.925293 
	C2943.529785,3406.665039 2943.493164,3407.670898 2943.428223,3409.187012 
	C2941.788330,3409.440918 2940.476562,3408.806152 2940.185059,3406.435059 
z"
        />
        <path
          fill="#131C25"
          opacity="1.000000"
          stroke="none"
          d="
M2944.335449,3411.768066 
	C2944.019531,3411.873047 2944.096191,3411.080811 2944.162109,3410.690918 
	C2944.228271,3410.301270 2944.949707,3410.857666 2944.949707,3410.857666 
	C2944.949707,3410.857666 2944.651123,3411.663086 2944.335449,3411.768066 
z"
        />
        <path
          fill="#395166"
          opacity="1.000000"
          stroke="none"
          d="
M2942.824707,3079.867676 
	C2937.603271,3076.924805 2932.177246,3079.028076 2927.760986,3076.701660 
	C2925.712891,3075.622559 2923.091797,3074.458008 2927.481445,3071.952393 
	C2944.297363,3073.008301 2960.451904,3074.555420 2977.206543,3077.864258 
	C2979.256592,3079.314209 2979.446533,3080.868896 2978.358398,3083.209473 
	C2966.558594,3081.749512 2955.284668,3079.652344 2942.824707,3079.867676 
z"
        />
        <path
          fill="#23394D"
          opacity="1.000000"
          stroke="none"
          d="
M2978.651611,3083.908936 
	C2977.891846,3082.381348 2977.951904,3080.909668 2978.013184,3078.697998 
	C2980.673096,3074.843506 2983.295410,3076.747070 2985.911377,3078.027344 
	C2987.424072,3078.767822 2989.086426,3079.814453 2988.938232,3081.528809 
	C2988.709229,3084.179199 2986.271484,3083.877930 2984.376465,3083.974121 
	C2982.745850,3084.056885 2981.106689,3083.974121 2978.651611,3083.908936 
z"
        />
        <path
          fill="#23394D"
          opacity="1.000000"
          stroke="none"
          d="
M2995.602539,3093.642090 
	C2993.916992,3096.430420 2991.485596,3096.343750 2988.429932,3094.683105 
	C2989.993896,3091.507568 2992.469971,3091.589355 2995.602539,3093.642090 
z"
        />
        <path
          fill="#395166"
          opacity="1.000000"
          stroke="none"
          d="
M2897.647461,3318.522461 
	C2904.936279,3316.364990 2911.299072,3314.419922 2918.216797,3318.429688 
	C2921.492920,3320.328125 2926.604004,3320.469727 2930.463867,3319.582031 
	C2934.942871,3318.552246 2939.414307,3319.504150 2944.199707,3317.777832 
	C2950.976074,3321.266113 2946.924316,3326.278076 2945.729004,3331.714111 
	C2945.464600,3333.619141 2943.073975,3334.044678 2944.671143,3336.373047 
	C2944.964600,3337.976807 2944.804932,3338.888428 2944.246338,3340.443604 
	C2944.565186,3341.382080 2944.748291,3341.800049 2944.343994,3342.655273 
	C2943.911865,3343.413330 2943.717529,3343.888184 2942.790039,3344.604980 
	C2928.070312,3340.398682 2913.132568,3340.416748 2898.507812,3335.306641 
	C2897.450195,3334.487549 2897.082520,3334.084229 2896.326172,3332.950439 
	C2895.561523,3331.162354 2895.333496,3330.073730 2895.212891,3328.108887 
	C2895.219971,3324.416016 2895.087402,3321.529053 2897.647461,3318.522461 
z"
        />
        <path
          fill="#9FA2A1"
          opacity="1.000000"
          stroke="none"
          d="
M2941.756348,3387.550293 
	C2941.339844,3388.227783 2941.122314,3388.412842 2941.003906,3388.333008 
	C2939.123779,3388.020020 2938.439697,3386.686035 2937.633789,3384.566650 
	C2937.373047,3383.395752 2937.315918,3382.927002 2937.223633,3381.768066 
	C2936.904785,3377.739014 2938.000488,3374.191406 2935.234863,3370.735840 
	C2933.825928,3369.496826 2932.825195,3368.748291 2931.116699,3367.882324 
	C2928.628174,3366.383789 2926.910400,3364.930664 2924.568359,3363.128906 
	C2921.552734,3360.432861 2919.512939,3357.812500 2917.725830,3355.027588 
	C2915.985596,3352.315918 2913.240967,3349.400146 2915.971680,3346.115234 
	C2918.386963,3343.210205 2922.103760,3343.752441 2925.240479,3344.799805 
	C2929.248779,3346.137939 2933.690674,3346.814209 2936.861816,3351.031250 
	C2937.151123,3352.922119 2936.952148,3354.100586 2935.921631,3354.961670 
	C2935.719482,3355.130615 2935.396973,3355.155518 2935.129883,3355.246582 
	C2935.998047,3354.234619 2935.804443,3356.701660 2936.511475,3355.140137 
	C2937.721924,3354.666992 2938.470215,3354.643311 2939.793457,3354.996826 
	C2941.798828,3356.217041 2942.155029,3357.975830 2943.679688,3359.648682 
	C2943.922852,3364.780273 2943.700195,3369.496582 2943.635986,3375.087402 
	C2943.254150,3379.598633 2942.713379,3383.235840 2941.756348,3387.550293 
z"
        />
        <path
          fill="#BAB4A7"
          opacity="1.000000"
          stroke="none"
          d="
M2943.545166,3334.449951 
	C2944.082520,3328.771484 2947.054688,3323.906006 2945.014160,3317.897949 
	C2944.950928,3315.603760 2945.444336,3314.112061 2947.458496,3312.847656 
	C2948.385986,3312.547607 2948.878174,3312.674072 2949.879395,3313.368164 
	C2950.121826,3316.783691 2949.855469,3319.631348 2949.399902,3323.315430 
	C2949.829346,3325.854492 2950.032227,3327.531982 2948.496338,3329.631104 
	C2947.232422,3333.620361 2946.387695,3337.109619 2944.787598,3340.858398 
	C2943.825928,3340.038574 2943.619385,3338.959961 2943.445801,3337.052734 
	C2943.394287,3335.813232 2943.309570,3335.402588 2943.545166,3334.449951 
z"
        />
        <path
          fill="#D0D1C8"
          opacity="1.000000"
          stroke="none"
          d="
M2944.403564,3359.320801 
	C2941.738037,3360.316895 2940.181396,3359.255615 2939.127441,3356.261230 
	C2937.476074,3351.597168 2938.156738,3348.007568 2941.310059,3344.224609 
	C2942.290771,3343.516113 2942.618408,3343.304199 2943.525879,3343.015625 
	C2946.988770,3348.056152 2943.519287,3353.398926 2944.403564,3359.320801 
z"
        />
        <path
          fill="#7B7F84"
          opacity="1.000000"
          stroke="none"
          d="
M2948.704102,3329.938232 
	C2948.168701,3328.445068 2948.260498,3326.759521 2948.781738,3324.613037 
	C2952.515869,3325.946533 2952.364990,3327.815186 2948.704102,3329.938232 
z"
        />
        <path
          fill="#395166"
          opacity="1.000000"
          stroke="none"
          d="
M2950.811035,3313.537109 
	C2949.992920,3313.651123 2949.597412,3313.365967 2948.547852,3312.751221 
	C2948.398193,3310.968506 2949.411133,3310.126709 2951.464600,3309.826660 
	C2951.743408,3310.885742 2951.488770,3312.011963 2950.811035,3313.537109 
z"
        />
        <path
          fill="#556A7C"
          opacity="1.000000"
          stroke="none"
          d="
M221.997894,2673.245117 
	C219.864319,2673.989502 217.728027,2673.988770 214.790161,2673.974609 
	C212.876740,2672.297119 211.179031,2672.018311 208.704895,2672.003174 
	C208.032761,2671.080322 208.024139,2670.160400 208.011108,2668.548340 
	C210.740067,2667.851807 213.473404,2667.847900 217.026306,2667.815918 
	C218.710373,2667.146240 219.669144,2666.937500 221.352859,2667.595703 
	C221.987411,2669.519531 221.991302,2671.010010 221.997894,2673.245117 
z"
        />
        <path
          fill="#5D758A"
          opacity="1.000000"
          stroke="none"
          d="
M74.444504,2659.547852 
	C74.242691,2657.604248 75.206665,2656.419189 77.769417,2656.429688 
	C78.281372,2657.293213 78.163002,2658.050293 78.020935,2659.385254 
	C77.041748,2659.897461 76.086258,2659.832275 74.444504,2659.547852 
z"
        />
        <path
          fill="#3F5467"
          opacity="1.000000"
          stroke="none"
          d="
M38.496216,2662.502197 
	C40.796616,2658.257812 45.242062,2658.361816 49.610306,2656.664551 
	C52.294956,2656.288086 54.742439,2655.795654 55.954552,2659.208496 
	C50.644569,2662.398193 45.300426,2665.270996 38.496216,2662.502197 
z"
        />
        <path
          fill="#959693"
          opacity="1.000000"
          stroke="none"
          d="
M2758.817383,3367.906738 
	C2733.679688,3358.510742 2707.917480,3353.299316 2683.716309,3343.357422 
	C2676.382812,3340.344727 2668.974365,3337.481934 2661.949219,3332.324219 
	C2660.151367,3333.847900 2659.019287,3332.670654 2657.377930,3331.572754 
	C2654.885742,3328.041748 2652.691650,3325.151611 2649.624512,3322.069336 
	C2648.920410,3321.144775 2648.658203,3320.758545 2648.053223,3319.758789 
	C2645.724854,3313.937744 2641.872559,3309.312012 2643.050781,3302.582520 
	C2651.606445,3298.668701 2651.597900,3306.628906 2655.151123,3310.810303 
	C2655.867432,3311.618408 2656.165771,3311.926025 2656.905273,3312.697266 
	C2657.658203,3313.451416 2657.970215,3313.741943 2658.800293,3314.400391 
	C2672.041992,3315.453857 2682.689209,3323.017090 2695.574219,3325.532715 
	C2697.217041,3325.674561 2698.145264,3325.843506 2699.740234,3326.296387 
	C2704.051270,3328.735107 2708.196533,3329.914551 2712.394775,3332.550293 
	C2714.856934,3346.282959 2726.062988,3347.965088 2735.918701,3351.189941 
	C2739.353271,3352.313477 2742.925537,3352.963379 2746.148193,3355.964111 
	C2747.386963,3357.425537 2748.265869,3358.318604 2750.010254,3358.982422 
	C2751.375000,3358.517578 2750.957031,3360.716309 2751.404785,3359.470215 
	C2749.703369,3358.907715 2748.553467,3358.534668 2747.371582,3357.042480 
	C2747.762695,3352.154297 2750.729736,3353.397461 2754.043457,3354.129883 
	C2764.020996,3363.506592 2777.754395,3362.865479 2788.754639,3369.888428 
	C2788.224365,3367.548828 2787.737061,3365.583496 2788.734863,3363.030273 
	C2791.957764,3359.918213 2794.309326,3362.271729 2797.437988,3363.590576 
	C2799.283447,3364.531006 2800.462158,3365.244385 2802.327148,3366.115234 
	C2806.575684,3368.383301 2810.232910,3370.063965 2814.927734,3367.219971 
	C2816.032227,3367.033691 2816.480957,3367.025635 2817.561523,3367.232910 
	C2821.063965,3369.540771 2820.225586,3374.126953 2824.323730,3376.115234 
	C2825.597656,3376.965088 2826.324463,3377.464844 2827.619629,3378.341797 
	C2834.047607,3382.305908 2840.707031,3384.083496 2846.863037,3386.989014 
	C2850.910156,3388.899658 2855.484619,3390.307861 2856.535645,3396.174805 
	C2846.554688,3396.483887 2837.787598,3392.164795 2828.783447,3389.244873 
	C2805.811279,3381.794678 2782.215820,3376.389648 2758.817383,3367.906738 
z"
        />
        <path
          fill="#D1C9BA"
          opacity="1.000000"
          stroke="none"
          d="
M2856.986328,3397.252197 
	C2851.422607,3390.344238 2843.590576,3389.066895 2836.809570,3385.926270 
	C2833.516357,3384.400635 2830.172363,3382.984863 2826.424316,3380.984619 
	C2825.716553,3380.031006 2825.435303,3379.612061 2825.329590,3378.455566 
	C2827.803711,3375.396729 2830.835693,3375.191162 2834.533691,3374.715820 
	C2837.169678,3374.412598 2839.022217,3374.927002 2841.596191,3375.146973 
	C2851.952393,3372.480469 2859.084961,3378.879150 2867.603516,3382.617188 
	C2869.403809,3386.341064 2872.371826,3386.872314 2875.193604,3387.937744 
	C2878.905518,3389.339844 2882.737305,3390.997314 2882.664307,3396.684814 
	C2882.939941,3399.414795 2881.118164,3399.760254 2879.790527,3401.145264 
	C2879.496094,3401.618408 2878.682373,3401.691162 2878.348145,3401.926270 
	C2870.719238,3402.952393 2864.150879,3400.467041 2856.986328,3397.252197 
z"
        />
        <path
          fill="#243B51"
          opacity="1.000000"
          stroke="none"
          d="
M2869.444580,3051.479004 
	C2871.910400,3054.222900 2871.304443,3055.711182 2868.442627,3055.550049 
	C2865.515869,3055.384766 2862.773682,3053.513916 2859.725586,3054.046387 
	C2856.754639,3054.565674 2852.002197,3052.038086 2851.306396,3056.519287 
	C2850.763184,3060.020020 2855.465332,3060.219238 2858.125732,3060.928467 
	C2866.717285,3063.218506 2875.413818,3064.968994 2884.257568,3066.237305 
	C2889.674316,3067.014160 2896.613525,3065.582764 2899.888184,3073.257324 
	C2898.339600,3074.008301 2896.476807,3073.521484 2895.050293,3074.103271 
	C2883.007812,3079.018311 2872.007568,3074.369141 2861.354980,3069.927490 
	C2849.133789,3064.831787 2836.330322,3064.204346 2823.542969,3062.848389 
	C2821.743164,3062.657227 2820.101318,3062.902344 2819.127930,3064.390625 
	C2816.444580,3068.494873 2813.777832,3068.303467 2810.186279,3065.280518 
	C2806.186279,3061.913330 2806.475830,3058.874268 2809.651855,3055.344971 
	C2810.018555,3054.937012 2809.872070,3054.067139 2809.981934,3052.705811 
	C2815.880615,3046.828613 2821.678467,3052.605713 2828.254883,3052.187012 
	C2832.380615,3051.952881 2835.188965,3049.683838 2839.299072,3049.314209 
	C2846.548828,3049.619385 2852.546143,3045.351562 2859.210693,3046.972656 
	C2862.644287,3047.808350 2866.387451,3047.952637 2869.444580,3051.479004 
z"
        />
        <path
          fill="#172B3E"
          opacity="1.000000"
          stroke="none"
          d="
M2830.435303,3055.275391 
	C2824.052002,3053.673828 2817.844238,3052.815186 2810.812500,3052.004883 
	C2807.896729,3051.003418 2805.824219,3049.902344 2802.684082,3050.049561 
	C2793.517090,3054.021484 2785.671631,3048.900879 2776.750000,3048.172363 
	C2774.009766,3047.891846 2772.089844,3047.915283 2770.536377,3046.213135 
	C2765.482666,3040.675537 2759.206543,3037.819336 2750.818848,3038.008789 
	C2746.052246,3037.838623 2742.182373,3037.507324 2740.098145,3032.552246 
	C2750.387207,3025.067383 2760.379883,3030.329590 2771.232910,3033.443604 
	C2776.073486,3037.295654 2781.341797,3036.421143 2785.970703,3037.888428 
	C2799.385986,3042.140625 2812.970703,3045.857910 2827.241455,3049.876953 
	C2831.462646,3049.923828 2831.273682,3052.200684 2830.435303,3055.275391 
z"
        />
        <path
          fill="#172B3E"
          opacity="1.000000"
          stroke="none"
          d="
M2869.951416,3051.233398 
	C2860.520508,3048.827148 2851.079346,3049.912842 2840.833496,3051.977783 
	C2833.031738,3047.948242 2829.214600,3039.732178 2820.777832,3037.477783 
	C2819.292480,3037.080566 2818.866943,3034.953613 2819.582520,3032.605713 
	C2822.154785,3032.016602 2824.280518,3032.013428 2827.203125,3032.005127 
	C2833.052246,3031.725586 2837.270020,3032.856934 2841.717285,3036.035400 
	C2846.284912,3039.300293 2851.583496,3042.015625 2857.958496,3042.350586 
	C2862.838867,3042.606934 2868.722168,3043.740967 2869.951416,3051.233398 
z"
        />
        <path
          fill="#243B51"
          opacity="1.000000"
          stroke="none"
          d="
M2775.991699,3048.644531 
	C2778.322510,3045.355957 2781.298340,3045.839111 2784.187500,3046.425293 
	C2789.598389,3047.522705 2794.977539,3048.776855 2801.177246,3050.007324 
	C2801.044922,3055.374512 2796.778809,3053.599854 2792.748047,3054.013428 
	C2786.803711,3058.245361 2782.148682,3054.812012 2776.691406,3052.541016 
	C2775.998779,3051.118652 2775.997314,3050.219238 2775.991699,3048.644531 
z"
        />
        <path
          fill="#243B51"
          opacity="1.000000"
          stroke="none"
          d="
M2842.093262,3028.694824 
	C2848.494385,3027.856689 2854.998047,3027.663330 2861.499023,3031.436768 
	C2854.783936,3040.042480 2850.229004,3039.452148 2842.093262,3028.694824 
z"
        />
        <path
          fill="#172B3E"
          opacity="1.000000"
          stroke="none"
          d="
M2776.129883,3052.707764 
	C2780.983887,3051.218506 2785.427979,3054.304688 2791.151367,3054.014648 
	C2791.504883,3057.664307 2790.179688,3060.381104 2785.815430,3058.831787 
	C2782.337891,3057.597168 2778.073975,3057.604004 2776.129883,3052.707764 
z"
        />
        <path
          fill="#243B51"
          opacity="1.000000"
          stroke="none"
          d="
M2900.115967,3074.642578 
	C2900.325684,3074.007324 2900.657471,3073.977539 2900.976318,3074.027832 
	C2902.475830,3074.263184 2904.419189,3073.728760 2904.835205,3075.955078 
	C2904.913574,3076.373779 2904.350098,3077.225586 2903.912109,3077.373779 
	C2902.001709,3078.020264 2900.868896,3077.027344 2900.115967,3074.642578 
z"
        />
        <path
          fill="#B9A795"
          opacity="1.000000"
          stroke="none"
          d="
M901.696045,1776.791260 
	C897.044739,1778.263916 896.528687,1772.471069 891.936523,1772.008057 
	C890.768982,1772.219482 890.313782,1772.436035 889.171631,1772.336914 
	C892.060913,1769.241333 891.493652,1765.195679 891.838135,1760.624878 
	C901.745483,1760.486938 901.755737,1760.486816 901.880981,1769.963623 
	C901.907166,1771.950806 901.826355,1773.939453 901.696045,1776.791260 
z"
        />
        <path
          fill="#BAB4A7"
          opacity="1.000000"
          stroke="none"
          d="
M888.119019,1772.526733 
	C889.098267,1772.045776 889.712280,1772.055054 890.775269,1772.026123 
	C892.206909,1776.735352 888.419861,1780.938721 889.494019,1786.431396 
	C888.691101,1787.314819 888.089050,1787.400879 887.022095,1787.389893 
	C885.437378,1784.299438 885.677490,1781.190796 886.335571,1777.298828 
	C886.857117,1775.361938 887.305298,1774.197021 888.119019,1772.526733 
z"
        />
        <path
          fill="#646A6C"
          opacity="1.000000"
          stroke="none"
          d="
M863.520264,1775.993652 
	C865.290466,1776.623047 866.504272,1777.133423 867.492676,1777.925171 
	C868.156921,1778.457397 868.843567,1779.359863 868.907349,1780.146484 
	C868.995239,1781.230347 867.878723,1781.690308 866.918518,1781.795166 
	C863.494995,1782.168701 860.266296,1782.150513 860.272339,1776.594971 
	C861.139038,1775.889038 862.003418,1775.941772 863.520264,1775.993652 
z"
        />
        <path
          fill="#C4C8C1"
          opacity="1.000000"
          stroke="none"
          d="
M1207.457153,1737.487549 
	C1207.950073,1751.463989 1207.974487,1764.833374 1207.997070,1779.145142 
	C1202.875488,1780.078369 1203.795288,1775.858643 1203.262817,1772.939575 
	C1202.555908,1769.064331 1202.824463,1764.934937 1198.990845,1761.651123 
	C1195.221069,1767.444580 1198.765869,1774.837891 1194.099365,1780.725952 
	C1188.378784,1782.892456 1184.213745,1781.388184 1180.445435,1776.720337 
	C1179.415894,1770.823975 1177.094604,1764.847656 1186.415405,1763.733398 
	C1177.870117,1757.684814 1170.774170,1761.230591 1163.012451,1765.725586 
	C1158.926025,1767.093140 1155.633911,1766.988281 1151.767578,1764.764893 
	C1146.686035,1754.997070 1148.649658,1745.360229 1149.359497,1734.988159 
	C1150.161133,1732.582153 1151.044067,1731.224121 1153.061768,1729.871216 
	C1155.704102,1728.938354 1157.205933,1730.134277 1159.428223,1730.984863 
	C1161.279297,1731.058472 1162.522583,1731.079712 1164.342529,1731.581055 
	C1166.699219,1733.410767 1168.626953,1734.197021 1171.622803,1732.993164 
	C1172.758789,1732.889038 1173.218506,1732.902222 1174.350342,1733.072754 
	C1179.548584,1736.110352 1188.705200,1733.930542 1186.938721,1743.507446 
	C1188.081909,1740.381470 1186.613647,1735.074951 1193.338623,1734.357178 
	C1196.973633,1734.528198 1199.870117,1734.930542 1203.516357,1735.426514 
	C1205.251831,1735.828613 1206.160278,1736.272461 1207.457153,1737.487549 
z"
        />
        <path
          fill="#9DAAB4"
          opacity="1.000000"
          stroke="none"
          d="
M1194.516968,1735.609863 
	C1191.609497,1736.277100 1189.013916,1736.590942 1189.242554,1739.738892 
	C1189.391235,1741.784668 1189.143066,1743.634155 1188.604370,1745.545532 
	C1188.030518,1747.581543 1191.526611,1751.503784 1186.536743,1751.335571 
	C1183.599487,1751.236572 1183.698975,1747.695801 1184.317627,1745.689575 
	C1186.264404,1739.375366 1183.916992,1737.268921 1177.778076,1737.847290 
	C1176.037598,1738.011108 1174.727661,1736.823364 1173.649048,1734.489746 
	C1172.972168,1731.589722 1174.695801,1730.205444 1175.514282,1728.153076 
	C1168.611328,1724.707886 1159.776611,1730.107666 1153.848145,1722.748779 
	C1154.468994,1718.709106 1156.808960,1717.716797 1159.691650,1718.061523 
	C1174.639771,1719.849487 1189.803589,1719.217163 1204.651733,1722.122437 
	C1208.100220,1722.797119 1209.806519,1724.841431 1209.885132,1728.236450 
	C1209.952759,1731.165527 1210.518799,1734.168457 1208.480103,1737.525146 
	C1206.942993,1737.961060 1205.960449,1737.827393 1204.249512,1737.539429 
	C1200.870728,1735.999390 1200.464233,1725.876709 1194.516968,1735.609863 
z"
        />
        <path
          fill="#98999E"
          opacity="1.000000"
          stroke="none"
          d="
M1261.090088,1764.055664 
	C1263.174194,1766.808105 1264.560181,1769.567139 1266.073242,1772.974854 
	C1263.938354,1776.721436 1260.653076,1776.806152 1256.044434,1776.602661 
	C1264.774170,1779.963867 1262.595459,1786.800415 1263.513184,1793.227417 
	C1263.809692,1794.864990 1263.712891,1795.926636 1263.410522,1797.762085 
	C1260.969238,1802.943115 1262.069824,1807.596313 1262.329224,1812.148193 
	C1262.507202,1815.269531 1262.661499,1819.068237 1259.440796,1819.746948 
	C1255.956177,1820.481201 1255.207642,1816.283569 1253.899170,1813.751831 
	C1251.558716,1809.222900 1251.050049,1804.355225 1251.248413,1799.289795 
	C1251.357178,1796.511353 1253.001831,1793.320557 1249.327759,1790.681641 
	C1246.843628,1789.197388 1244.983154,1787.995728 1242.572021,1786.435791 
	C1238.339478,1780.214233 1239.765625,1773.800903 1240.803345,1767.757935 
	C1241.415039,1764.195923 1244.311157,1761.225708 1249.450073,1763.557129 
	C1251.481445,1765.609863 1253.109253,1766.804199 1255.702393,1764.491577 
	C1257.587036,1764.084229 1258.989624,1764.073120 1261.090088,1764.055664 
z"
        />
        <path
          fill="#C4BDAE"
          opacity="1.000000"
          stroke="none"
          d="
M1314.059814,1711.275269 
	C1299.436768,1714.833008 1284.842285,1717.815308 1268.983887,1718.000244 
	C1268.092896,1714.544922 1268.086670,1711.063843 1268.155518,1706.709473 
	C1280.953369,1704.162231 1293.674194,1702.473877 1306.400391,1700.826172 
	C1309.013550,1700.487793 1311.646240,1700.301392 1315.126709,1700.111572 
	C1315.343384,1703.622314 1314.703247,1707.066528 1314.059814,1711.275269 
z"
        />
        <path
          fill="#9DAAB4"
          opacity="1.000000"
          stroke="none"
          d="
M1194.275879,1780.495361 
	C1193.349243,1771.550537 1194.597290,1763.301514 1195.608276,1754.241943 
	C1202.919312,1759.131592 1207.538818,1764.348267 1205.997192,1773.157349 
	C1205.679077,1774.974976 1206.543945,1776.999512 1207.429443,1779.508179 
	C1207.979736,1782.680786 1207.933472,1785.274048 1207.955200,1787.866943 
	C1208.003296,1793.607056 1207.142944,1799.441406 1211.371338,1804.472900 
	C1212.402710,1805.700073 1211.893066,1808.222168 1212.150269,1810.969849 
	C1210.818481,1814.504272 1208.471069,1815.401001 1204.867920,1815.052734 
	C1200.052856,1811.441895 1201.975342,1804.656006 1196.333496,1801.388062 
	C1195.796753,1794.258423 1190.463623,1787.949341 1194.275879,1780.495361 
z"
        />
        <path
          fill="#C4BDAE"
          opacity="1.000000"
          stroke="none"
          d="
M1313.143311,1718.180664 
	C1313.989258,1720.909058 1314.003784,1723.619507 1314.023071,1727.146240 
	C1299.383301,1729.987671 1284.785645,1732.519043 1268.998657,1732.039062 
	C1268.069092,1729.883667 1268.050415,1727.719116 1268.076660,1724.742920 
	C1282.851685,1722.008301 1297.581787,1720.085571 1313.143311,1718.180664 
z"
        />
        <path
          fill="#A9ACA9"
          opacity="1.000000"
          stroke="none"
          d="
M1203.808472,1814.314697 
	C1206.653931,1813.355225 1208.766602,1812.700073 1211.543823,1811.917969 
	C1213.038940,1811.842285 1213.869507,1811.893555 1215.325439,1811.966309 
	C1219.127808,1816.118530 1224.969238,1814.079590 1228.989746,1818.031250 
	C1231.520386,1822.716431 1231.178711,1826.954102 1230.192261,1831.932129 
	C1226.466309,1837.317505 1222.690430,1839.606079 1218.079834,1833.966675 
	C1213.079834,1827.850708 1209.380371,1836.072144 1204.153076,1834.904785 
	C1197.699951,1828.099609 1198.734619,1821.389893 1203.808472,1814.314697 
z"
        />
        <path
          fill="#B6BCC1"
          opacity="1.000000"
          stroke="none"
          d="
M1242.009521,1786.902344 
	C1244.571655,1784.610107 1247.083862,1783.348022 1248.769531,1788.062744 
	C1252.930420,1798.466309 1251.019897,1808.663208 1250.792236,1818.632690 
	C1250.644165,1825.120972 1249.699219,1831.636963 1249.599609,1839.073975 
	C1248.434448,1842.626343 1246.433716,1843.609497 1242.862183,1843.132080 
	C1233.864014,1834.210449 1237.531372,1824.108765 1239.689209,1814.911743 
	C1241.052368,1809.101685 1241.985718,1803.498779 1242.002686,1796.761353 
	C1242.003784,1793.166870 1242.004028,1790.442017 1242.009521,1786.902344 
z"
        />
        <path
          fill="#98999E"
          opacity="1.000000"
          stroke="none"
          d="
M1241.469238,1796.455811 
	C1245.243164,1799.611572 1244.058716,1804.107178 1243.663940,1808.190308 
	C1243.036621,1814.678467 1241.801514,1821.133789 1240.412842,1827.513794 
	C1239.433350,1832.013550 1241.275146,1835.918091 1242.173950,1840.848389 
	C1241.963745,1843.192017 1240.922485,1844.060425 1238.808350,1844.644897 
	C1235.795288,1843.814941 1233.806885,1842.455566 1231.562500,1840.215576 
	C1230.687622,1838.065918 1230.859619,1836.527100 1230.990234,1834.189941 
	C1231.576172,1827.427856 1229.571289,1821.206909 1233.337402,1814.878174 
	C1241.602661,1810.702759 1239.116943,1802.980103 1241.469238,1796.455811 
z"
        />
        <path
          fill="#D7D5CF"
          opacity="1.000000"
          stroke="none"
          d="
M1153.150635,1722.058228 
	C1159.010132,1725.724609 1164.760986,1723.332397 1170.156494,1723.944336 
	C1174.541260,1724.441772 1179.416626,1723.051147 1183.175659,1727.870239 
	C1181.242310,1731.359497 1176.171997,1729.408936 1173.826172,1733.198730 
	C1173.354248,1733.760010 1173.007080,1733.808594 1172.832642,1733.825317 
	C1169.877808,1734.142090 1167.322510,1733.422363 1164.240723,1731.982910 
	C1162.869019,1732.325562 1162.150757,1732.813965 1161.095825,1733.578125 
	C1158.672485,1734.057983 1158.074341,1732.267822 1156.605225,1730.620605 
	C1155.326294,1730.537842 1154.474854,1730.906494 1153.291016,1731.608643 
	C1149.589111,1732.094238 1147.861572,1729.875488 1146.198730,1726.659424 
	C1146.006592,1725.091553 1145.956299,1724.223145 1145.795166,1722.710449 
	C1147.898193,1722.063721 1150.112305,1722.061157 1153.150635,1722.058228 
z"
        />
        <path
          fill="#4E585F"
          opacity="1.000000"
          stroke="none"
          d="
M1233.140991,1814.132202 
	C1233.871338,1820.836548 1233.861450,1827.427612 1233.873291,1834.920898 
	C1231.639282,1837.586060 1229.473877,1837.810059 1227.262817,1834.546631 
	C1227.228027,1829.114380 1227.390869,1824.580811 1227.511475,1819.196655 
	C1226.933350,1817.304199 1226.784424,1816.209839 1227.470459,1814.341919 
	C1229.412476,1813.753540 1230.906616,1813.886108 1233.140991,1814.132202 
z"
        />
        <path
          fill="#808489"
          opacity="1.000000"
          stroke="none"
          d="
M1227.356689,1813.124023 
	C1227.905029,1814.680542 1227.891846,1815.740234 1227.673828,1817.572876 
	C1223.508179,1817.165894 1218.005249,1819.548706 1216.060547,1812.721802 
	C1219.573486,1812.066162 1223.243042,1811.350586 1227.356689,1813.124023 
z"
        />
        <path
          fill="#C4BDAE"
          opacity="1.000000"
          stroke="none"
          d="
M1255.399414,1764.066650 
	C1255.783691,1766.265137 1256.375244,1769.206177 1253.171143,1769.548828 
	C1250.508057,1769.833496 1250.149170,1767.583374 1249.932617,1764.792969 
	C1251.476929,1764.054443 1253.045532,1764.046143 1255.399414,1764.066650 
z"
        />
        <path
          fill="#838F9A"
          opacity="1.000000"
          stroke="none"
          d="
M1031.942139,1752.636841 
	C1031.898193,1745.544189 1029.796265,1739.134033 1033.257080,1732.419678 
	C1039.342529,1728.460571 1044.912964,1727.657104 1051.639160,1729.591064 
	C1057.448242,1731.261353 1063.886353,1729.327271 1070.174561,1729.734253 
	C1085.235962,1730.708984 1100.285522,1732.096191 1115.425781,1732.269897 
	C1123.596924,1732.363647 1131.765869,1734.500977 1140.979492,1734.039185 
	C1143.608154,1735.292480 1144.725586,1737.088623 1144.817505,1739.064941 
	C1145.537720,1754.560547 1147.677124,1770.127930 1142.518555,1786.158447 
	C1137.294189,1791.860840 1132.167480,1789.155273 1127.279053,1787.350220 
	C1117.284912,1783.660034 1106.260254,1778.666260 1099.648560,1772.198120 
	C1082.317383,1755.243042 1062.477905,1762.237427 1043.674438,1762.224487 
	C1035.348877,1762.218628 1032.970703,1760.309570 1031.942139,1752.636841 
z"
        />
        <path
          fill="#5A6668"
          opacity="1.000000"
          stroke="none"
          d="
M1139.628540,1788.635742 
	C1143.074341,1770.846924 1141.783447,1753.354858 1141.948364,1734.987061 
	C1142.429199,1731.348145 1140.914673,1727.902344 1145.354492,1726.148926 
	C1148.450439,1727.149902 1150.779419,1728.422363 1152.595459,1731.376099 
	C1151.698120,1741.755005 1151.536255,1751.586792 1152.587891,1762.309570 
	C1157.284180,1771.966187 1155.223389,1781.317261 1154.922974,1791.298950 
	C1153.915527,1796.675293 1149.695679,1797.899902 1146.030518,1800.684448 
	C1144.240356,1801.907104 1142.933350,1802.454956 1140.788574,1802.554199 
	C1138.741821,1801.874146 1137.795410,1800.888306 1137.042236,1798.879395 
	C1136.814453,1796.359131 1137.194336,1794.562256 1137.600586,1792.086426 
	C1138.063721,1790.557495 1138.510986,1789.774048 1139.628540,1788.635742 
z"
        />
        <path
          fill="#5A605E"
          opacity="1.000000"
          stroke="none"
          d="
M979.428711,1829.265747 
	C981.373962,1827.815918 983.205872,1827.282471 983.795044,1824.578857 
	C987.314941,1820.746460 991.508728,1822.549072 995.328186,1822.017822 
	C994.047058,1821.678711 992.734436,1822.002319 991.436340,1821.922607 
	C988.766846,1821.758301 985.658142,1821.846191 985.342407,1818.323486 
	C984.995300,1814.450928 988.130432,1813.275635 991.170532,1812.940674 
	C995.179810,1812.499268 1000.513855,1814.450928 999.859924,1807.200195 
	C999.350098,1801.547485 1003.226685,1794.025146 993.426880,1792.228394 
	C991.129822,1791.807251 989.049866,1790.499390 989.822510,1787.042603 
	C999.603333,1785.766357 1008.562378,1789.567261 1017.868103,1788.318604 
	C1020.091370,1788.020264 1021.409790,1789.559204 1022.267944,1791.559204 
	C1023.437500,1794.284790 1025.767456,1794.116699 1028.902832,1793.466553 
	C1031.732178,1792.035278 1034.023071,1791.100830 1035.684814,1788.348389 
	C1039.270630,1784.010986 1041.288086,1786.236328 1042.371582,1789.453125 
	C1045.064697,1797.448608 1048.909912,1798.032593 1056.590332,1794.467529 
	C1063.121460,1791.435791 1071.150146,1789.892212 1079.413818,1793.385986 
	C1082.414062,1794.081787 1084.650879,1794.874634 1087.566162,1795.915161 
	C1092.054443,1798.256958 1095.612427,1800.809448 1100.354248,1802.798340 
	C1102.688843,1804.648438 1103.727783,1806.485229 1104.520142,1809.388184 
	C1104.788818,1811.306885 1104.859131,1812.414062 1104.916870,1814.360352 
	C1105.549072,1821.330811 1105.710327,1827.448364 1103.936646,1834.217773 
	C1091.655640,1839.426880 1079.283203,1842.651123 1067.176514,1846.701660 
	C1063.205566,1848.030151 1060.828125,1845.057617 1060.579590,1841.264526 
	C1059.690186,1827.681274 1047.475952,1828.889648 1039.369995,1825.644043 
	C1032.198364,1822.772461 1027.442017,1829.382690 1024.375854,1834.543457 
	C1020.011108,1841.889526 1015.898987,1843.316040 1008.338074,1838.949951 
	C1001.984192,1835.280884 994.344299,1835.329346 986.981445,1835.443237 
	C983.108521,1835.503174 978.374451,1836.365234 979.428711,1829.265747 
z"
        />
        <path
          fill="#6F7981"
          opacity="1.000000"
          stroke="none"
          d="
M1080.714966,1795.513306 
	C1076.785156,1796.382690 1073.097534,1793.775879 1070.940186,1794.321655 
	C1067.851196,1795.103027 1064.871704,1796.245239 1061.581421,1796.054199 
	C1057.673584,1795.827393 1054.291748,1796.159302 1053.728516,1801.196777 
	C1053.575562,1802.566406 1051.250854,1803.522461 1051.036133,1802.339111 
	C1050.169678,1797.560303 1044.592041,1802.447632 1043.122070,1797.840210 
	C1042.202271,1794.957031 1039.638428,1792.488647 1039.863159,1788.988525 
	C1039.880615,1788.715454 1039.725342,1788.279785 1039.518311,1788.171021 
	C1038.772095,1787.778809 1038.277344,1788.095337 1038.030518,1789.501587 
	C1035.422974,1791.378052 1033.225952,1790.682617 1030.684082,1788.416016 
	C1030.021973,1782.984741 1029.850098,1778.125122 1031.236328,1773.277588 
	C1033.161499,1766.545776 1030.820190,1759.769409 1031.421143,1752.426025 
	C1034.988403,1754.111938 1033.904419,1758.940063 1038.167847,1761.288940 
	C1040.015137,1757.666504 1042.641846,1756.179321 1047.294312,1757.595947 
	C1051.253174,1758.801392 1055.876709,1758.339478 1060.140259,1757.929443 
	C1071.153198,1756.870239 1081.828979,1760.196045 1092.747314,1760.063721 
	C1095.904297,1760.025513 1097.709839,1761.439209 1097.906006,1764.728516 
	C1098.022583,1766.683350 1097.707886,1768.738403 1099.137085,1770.748779 
	C1105.543579,1770.177124 1110.709839,1772.867432 1115.702515,1777.105225 
	C1122.263672,1782.673828 1130.270142,1785.789429 1139.377930,1788.037598 
	C1140.143799,1789.002808 1140.148193,1789.817749 1140.129639,1791.245605 
	C1137.730591,1793.771118 1136.408325,1796.536743 1133.832520,1799.245361 
	C1129.685669,1801.262451 1125.959839,1800.845581 1121.506836,1800.906494 
	C1115.864380,1801.280518 1111.712158,1799.422119 1107.819336,1796.781616 
	C1103.914429,1794.133057 1099.685913,1792.540039 1094.182251,1792.588623 
	C1088.856567,1790.853516 1084.703247,1791.335449 1080.714966,1795.513306 
z"
        />
        <path
          fill="#817A79"
          opacity="1.000000"
          stroke="none"
          d="
M989.572693,1786.412354 
	C991.033997,1791.062866 994.724426,1789.761963 997.418823,1790.110962 
	C1002.254333,1790.736938 1004.560791,1792.464844 1003.100769,1798.014282 
	C1002.146973,1801.639404 1002.734863,1805.683472 1002.722900,1809.540039 
	C1002.704285,1815.562012 1002.099854,1816.198853 996.102844,1815.949097 
	C993.001526,1815.819946 987.335388,1813.698364 987.665955,1817.370728 
	C988.123596,1822.455078 993.149292,1818.788208 996.286438,1818.332031 
	C997.259155,1818.190674 998.286560,1818.090942 999.244019,1818.247192 
	C1001.346558,1818.590332 1003.121948,1819.604248 1003.135376,1821.981445 
	C1003.148743,1824.355469 1001.424927,1825.551514 999.290527,1825.700562 
	C997.689331,1825.812622 995.929443,1825.605591 994.445190,1825.020264 
	C991.549194,1823.878662 988.585388,1824.026245 984.769653,1823.940186 
	C983.919617,1811.917603 983.909119,1799.911987 983.994629,1786.978882 
	C985.659729,1786.117676 987.228943,1786.183838 989.572693,1786.412354 
z"
        />
        <path
          fill="#808489"
          opacity="1.000000"
          stroke="none"
          d="
M1002.755005,1767.374268 
	C998.871765,1761.515869 998.851074,1754.974243 1002.742920,1748.079102 
	C1004.158081,1754.090210 1004.384888,1760.415405 1002.755005,1767.374268 
z"
        />
        <path
          fill="#838F9A"
          opacity="1.000000"
          stroke="none"
          d="
M1030.093750,1788.567383 
	C1032.464478,1787.927246 1034.739868,1788.043213 1037.332275,1789.731323 
	C1037.817383,1795.034790 1034.759521,1794.815552 1030.455811,1793.522217 
	C1029.782104,1791.921387 1029.899536,1790.582642 1030.093750,1788.567383 
z"
        />
        <path
          fill="#808489"
          opacity="1.000000"
          stroke="none"
          d="
M991.744934,1781.050049 
	C992.632690,1780.456055 993.729492,1780.373901 995.645752,1780.423584 
	C995.377441,1782.755493 994.256836,1784.807983 991.744934,1781.050049 
z"
        />
        <path
          fill="#F1E9D8"
          opacity="1.000000"
          stroke="none"
          d="
M939.105408,1781.119629 
	C932.744324,1787.810059 925.598999,1790.242432 916.419434,1786.325562 
	C916.271790,1776.667114 916.306763,1776.346680 924.864441,1777.281250 
	C928.274109,1777.653564 931.191956,1775.650757 935.238831,1776.385498 
	C936.910522,1776.246826 937.761475,1776.387329 939.096130,1777.163330 
	C939.446899,1778.507935 939.275635,1779.449463 939.105408,1781.119629 
z"
        />
        <path
          fill="#D8D1C0"
          opacity="1.000000"
          stroke="none"
          d="
M965.207214,1781.836670 
	C966.439636,1784.820435 966.798584,1787.679932 967.158203,1790.545288 
	C964.183960,1792.413208 964.121094,1789.171631 962.821655,1788.216919 
	C961.081482,1786.938110 959.287109,1785.922729 958.240967,1788.753662 
	C957.487061,1790.793457 957.953125,1794.104248 954.653381,1793.472900 
	C951.124084,1792.797729 952.384521,1789.288452 952.049133,1786.851440 
	C951.916077,1785.884888 952.064209,1784.879639 952.104370,1783.076660 
	C956.194092,1782.078369 960.263916,1781.895386 965.207214,1781.836670 
z"
        />
        <path
          fill="#B9A795"
          opacity="1.000000"
          stroke="none"
          d="
M939.915283,1777.098633 
	C938.751099,1777.447876 937.883972,1777.329468 936.519043,1776.774902 
	C936.378174,1774.749146 937.077759,1772.845337 938.706726,1772.953003 
	C940.616699,1773.079346 940.545288,1775.031738 939.915283,1777.098633 
z"
        />
        <path
          fill="#A2BBCE"
          opacity="1.000000"
          stroke="none"
          d="
M1098.093140,1686.483643 
	C1098.440918,1686.371460 1098.793091,1686.710083 1099.029297,1687.536865 
	C1098.703247,1687.580444 1098.431274,1687.216919 1098.093140,1686.483643 
z"
        />
        <path
          fill="#5A605E"
          opacity="1.000000"
          stroke="none"
          d="
M1807.330566,1917.419678 
	C1804.632202,1917.914917 1802.394043,1917.912720 1800.158569,1917.988281 
	C1792.602539,1918.243652 1792.602905,1918.254272 1789.931396,1927.113037 
	C1786.149414,1928.448730 1785.777588,1925.143433 1784.088379,1922.242432 
	C1782.917236,1915.629028 1780.831787,1909.887451 1782.687256,1903.147705 
	C1784.765747,1902.275269 1786.426636,1902.171875 1788.057373,1901.891724 
	C1790.509521,1901.470459 1791.006226,1900.636353 1789.983887,1897.892334 
	C1787.752441,1891.903198 1788.706421,1890.411011 1794.265991,1891.316895 
	C1798.651123,1892.031250 1802.403076,1890.718872 1805.549438,1888.730957 
	C1809.267212,1886.382324 1812.270630,1883.365112 1817.051758,1885.771973 
	C1817.565308,1886.030640 1818.754639,1886.001099 1818.949463,1885.685303 
	C1824.010742,1877.478516 1831.598145,1884.498901 1837.718262,1882.571899 
	C1840.698486,1881.633667 1841.435547,1884.083008 1842.169067,1886.520020 
	C1843.781738,1891.879517 1847.219604,1889.297119 1850.030762,1887.869629 
	C1853.455811,1886.130371 1855.100464,1883.522827 1852.395874,1879.868530 
	C1851.820435,1879.091064 1850.781128,1878.660522 1850.190430,1877.890015 
	C1847.448853,1874.314819 1847.875488,1872.151123 1852.088623,1870.376953 
	C1854.795654,1869.237183 1857.798462,1868.799927 1861.453979,1868.038452 
	C1866.235229,1872.535278 1864.666992,1877.359619 1862.867920,1882.070190 
	C1861.323608,1886.113281 1859.736572,1890.125977 1858.611816,1895.164062 
	C1854.997803,1903.918457 1854.986938,1913.142090 1848.667603,1920.721436 
	C1839.896118,1922.180420 1831.792358,1921.234497 1823.000000,1921.152710 
	C1817.416870,1916.472046 1819.488770,1910.497803 1818.537842,1904.572266 
	C1818.473267,1902.858765 1819.486938,1901.822021 1818.490479,1901.261963 
	C1817.994263,1902.398193 1817.412842,1902.811401 1816.162109,1903.159912 
	C1814.532593,1903.287598 1813.459229,1903.336060 1812.224854,1904.504883 
	C1810.179321,1908.849365 1810.160400,1913.306030 1807.330566,1917.419678 
z"
        />
        <path
          fill="#EEE9DC"
          opacity="1.000000"
          stroke="none"
          d="
M1855.927002,1896.795410 
	C1855.623291,1891.540161 1856.995117,1887.254028 1859.091553,1883.754272 
	C1861.802490,1879.228882 1862.317505,1874.597534 1862.382080,1868.857788 
	C1862.865234,1863.944580 1863.491455,1859.860107 1864.121338,1855.005371 
	C1864.879150,1853.330200 1865.831055,1853.007080 1867.431885,1853.935181 
	C1870.389893,1867.681885 1871.673950,1880.911255 1872.843262,1894.156006 
	C1873.193359,1898.121216 1873.630493,1902.078857 1874.278564,1906.907959 
	C1878.780029,1914.830566 1878.693726,1922.792358 1878.847534,1930.557007 
	C1879.065796,1941.568970 1877.592651,1943.052979 1866.004150,1946.054077 
	C1864.165649,1947.515869 1863.007812,1948.566895 1861.295898,1950.136841 
	C1859.196289,1951.231445 1857.755127,1951.206543 1855.943481,1949.723633 
	C1855.538086,1948.109497 1855.687012,1947.132690 1855.397583,1945.703613 
	C1854.516602,1944.786011 1854.232544,1944.346069 1853.685791,1943.149658 
	C1851.591431,1927.322754 1852.600098,1912.412109 1855.927002,1896.795410 
z"
        />
        <path
          fill="#6F7981"
          opacity="1.000000"
          stroke="none"
          d="
M1987.112061,1926.936035 
	C1982.958618,1921.774780 1977.996826,1921.543335 1972.941162,1922.071289 
	C1959.082764,1923.518311 1945.239746,1921.620728 1931.395020,1922.065308 
	C1928.271851,1922.165527 1924.578491,1920.754028 1922.156738,1925.286743 
	C1920.227539,1926.746460 1918.724121,1926.216187 1916.666748,1924.753174 
	C1912.829224,1919.816772 1912.474731,1915.056152 1914.641357,1909.341187 
	C1926.927002,1907.411255 1938.756470,1907.207764 1951.476562,1908.394287 
	C1953.986816,1910.090820 1953.130249,1912.807007 1955.036865,1914.296021 
	C1957.618286,1913.608032 1957.256104,1910.827271 1959.086182,1908.835693 
	C1972.403442,1907.169189 1985.383301,1909.525146 1998.195068,1906.864380 
	C1999.616089,1906.569336 2001.335693,1907.711792 2003.696533,1908.344727 
	C2005.936279,1922.055786 1999.613525,1929.246094 1987.112061,1926.936035 
z"
        />
        <path
          fill="#808489"
          opacity="1.000000"
          stroke="none"
          d="
M1797.720947,1815.041626 
	C1797.781128,1810.134644 1797.874268,1806.148193 1798.093018,1802.168579 
	C1798.350098,1797.490601 1801.240234,1795.984253 1805.512695,1796.151489 
	C1809.693481,1796.315186 1810.124634,1799.142700 1810.103638,1802.376831 
	C1810.086426,1805.036011 1810.123901,1807.695557 1810.237427,1811.256836 
	C1807.295166,1824.913818 1807.931885,1837.910645 1808.104980,1850.833984 
	C1808.182129,1856.588379 1806.697388,1861.992065 1806.002563,1867.564819 
	C1805.784424,1869.313721 1805.331543,1870.998535 1803.246338,1870.992798 
	C1801.553345,1870.988281 1799.968140,1870.069336 1799.051880,1868.644043 
	C1797.526489,1866.270630 1798.031494,1863.479980 1798.011597,1860.856079 
	C1797.898682,1845.892822 1797.868164,1830.928833 1797.720947,1815.041626 
z"
        />
        <path
          fill="#98999E"
          opacity="1.000000"
          stroke="none"
          d="
M2048.042969,1886.718628 
	C2053.994873,1884.929688 2059.851318,1883.760254 2066.134521,1885.545288 
	C2069.474121,1886.494141 2074.031982,1887.462891 2077.139648,1882.465332 
	C2078.501221,1881.276489 2079.426758,1880.804443 2081.243652,1880.507324 
	C2086.994385,1881.830933 2092.087891,1882.696411 2097.615723,1884.802734 
	C2099.327148,1886.429199 2099.779053,1887.862915 2099.832031,1890.173096 
	C2099.022461,1894.148926 2097.271973,1897.042236 2096.305664,1900.944336 
	C2095.684326,1902.434082 2095.181641,1903.193604 2093.988037,1904.234131 
	C2092.575684,1904.864258 2091.704834,1905.021362 2090.173096,1905.173340 
	C2084.933594,1908.991821 2080.551758,1910.372437 2075.500977,1905.550903 
	C2071.063477,1901.314819 2063.597168,1903.626343 2058.862793,1898.208008 
	C2057.710693,1891.496948 2051.494385,1890.893799 2048.042969,1886.718628 
z"
        />
        <path
          fill="#A4BED0"
          opacity="1.000000"
          stroke="none"
          d="
M1986.252808,1927.163940 
	C1996.543823,1926.059814 1996.588623,1926.082764 2001.143555,1917.183838 
	C2002.336548,1914.852905 2003.157104,1912.331299 2004.312500,1909.199585 
	C2006.926392,1903.821899 2009.369385,1899.138184 2011.853394,1894.476318 
	C2011.952148,1894.291016 2012.441406,1894.313843 2013.321899,1894.101440 
	C2015.586548,1898.778564 2014.096924,1904.505859 2017.311401,1909.615967 
	C2017.859253,1910.995361 2017.918457,1911.834229 2017.970337,1913.304199 
	C2016.899048,1918.744629 2022.141602,1926.373657 2011.682617,1927.008667 
	C2008.424927,1927.206421 2010.231445,1929.958130 2011.224365,1931.359253 
	C2013.121338,1934.035767 2018.712891,1935.176147 2016.067993,1939.645752 
	C2013.436768,1944.092407 2008.007690,1943.182983 2003.534668,1943.479126 
	C2001.546875,1943.610718 1999.801514,1942.423096 1998.152832,1940.273682 
	C1997.416626,1936.269531 1994.917480,1935.756958 1992.237793,1935.847900 
	C1986.458618,1936.044067 1984.945312,1933.061157 1986.252808,1927.163940 
z"
        />
        <path
          fill="#7B7F84"
          opacity="1.000000"
          stroke="none"
          d="
M1732.054688,1910.921875 
	C1730.849243,1907.811279 1729.401123,1906.017578 1726.585693,1906.849976 
	C1724.670654,1907.416382 1722.806030,1908.239868 1720.873779,1907.108276 
	C1719.008301,1906.015991 1717.896484,1904.448730 1718.658081,1902.200684 
	C1719.305542,1900.290161 1721.032715,1899.964600 1722.696289,1900.200928 
	C1723.951660,1900.379272 1725.277588,1900.893066 1726.313110,1901.624146 
	C1728.925903,1903.468506 1730.920044,1903.025635 1734.195557,1902.181396 
	C1747.625244,1898.720093 1761.434326,1895.248047 1775.357422,1903.484253 
	C1776.100952,1903.433105 1776.954346,1903.684570 1777.382324,1903.806152 
	C1778.659668,1904.755127 1779.083862,1905.764038 1778.889893,1907.786865 
	C1777.384644,1910.622070 1775.820923,1912.353516 1772.906982,1913.933960 
	C1761.463989,1915.562988 1750.824463,1914.759033 1740.201538,1914.821411 
	C1737.196045,1914.838989 1734.172852,1914.562500 1732.054688,1910.921875 
z"
        />
        <path
          fill="#797771"
          opacity="1.000000"
          stroke="none"
          d="
M2058.200684,1898.147705 
	C2063.044678,1898.230347 2067.228027,1900.476929 2071.966553,1900.117188 
	C2074.858398,1899.897583 2076.634766,1902.014893 2078.545654,1903.873779 
	C2082.773926,1907.987061 2082.869385,1907.943726 2086.154053,1902.496338 
	C2086.891113,1901.273804 2087.575928,1900.360596 2089.592285,1901.309692 
	C2091.182617,1902.978516 2090.079590,1904.813721 2091.375488,1906.689941 
	C2091.902100,1908.588745 2091.842285,1909.888184 2091.265869,1911.811035 
	C2087.523926,1917.110107 2082.072266,1919.906494 2078.524414,1925.444702 
	C2076.288574,1926.473877 2074.562256,1927.061890 2072.208496,1927.938110 
	C2067.793701,1929.058716 2065.633789,1927.214722 2064.284424,1923.891724 
	C2064.111572,1923.465576 2064.802490,1922.242554 2063.542725,1922.661987 
	C2063.150391,1922.792847 2062.953369,1923.709351 2061.904297,1924.589600 
	C2060.209717,1925.200684 2059.186279,1925.109619 2057.543945,1924.206909 
	C2053.676758,1917.159912 2054.235352,1910.328247 2055.581543,1902.666016 
	C2056.572266,1900.809692 2057.221436,1899.747192 2058.200684,1898.147705 
z"
        />
        <path
          fill="#79868C"
          opacity="1.000000"
          stroke="none"
          d="
M1862.650635,1946.694336 
	C1861.092773,1941.193604 1864.315796,1941.760254 1867.374756,1941.711548 
	C1876.118164,1941.572510 1875.884399,1941.491333 1875.859009,1932.999390 
	C1875.835327,1925.117920 1873.059448,1917.475220 1874.317383,1908.658081 
	C1876.499268,1907.888550 1878.469238,1908.001221 1881.177490,1908.023193 
	C1884.905762,1909.479492 1884.086548,1913.332520 1886.907227,1915.686279 
	C1889.259888,1917.391113 1891.218384,1918.391602 1894.150391,1918.634766 
	C1897.474976,1917.919067 1897.351685,1915.235474 1898.872803,1912.846924 
	C1900.366577,1911.226562 1901.659180,1910.810181 1903.802612,1911.311279 
	C1910.204102,1919.899780 1904.400391,1925.813843 1899.827637,1932.648315 
	C1896.112183,1933.453369 1892.799561,1933.937378 1889.502686,1933.846436 
	C1881.571777,1933.627563 1881.574829,1933.518921 1880.003906,1943.227051 
	C1875.874756,1952.147949 1869.361084,1948.543091 1862.650635,1946.694336 
z"
        />
        <path
          fill="#E7E3DA"
          opacity="1.000000"
          stroke="none"
          d="
M1899.500244,1933.288818 
	C1898.570312,1929.935913 1894.875366,1925.656616 1902.440674,1925.447998 
	C1904.548096,1925.389893 1904.378296,1923.161743 1904.215942,1921.590698 
	C1903.946289,1918.982544 1903.773438,1916.403442 1904.481689,1912.987793 
	C1900.611450,1910.254028 1899.194092,1907.357544 1901.372437,1902.518066 
	C1902.858765,1901.877808 1903.760742,1901.933838 1905.346680,1902.096191 
	C1906.440796,1901.581421 1906.934448,1901.034790 1908.040283,1900.421997 
	C1908.935913,1899.842163 1909.264893,1899.374268 1909.792603,1898.522461 
	C1910.376953,1897.877930 1910.736084,1897.598633 1911.396973,1897.931396 
	C1911.614258,1901.827515 1912.558472,1904.825562 1914.666870,1908.140869 
	C1915.639404,1913.316406 1914.739014,1918.141357 1917.096436,1923.323975 
	C1920.796753,1927.052368 1920.125244,1930.111206 1916.721436,1933.564697 
	C1914.393066,1934.063965 1912.855713,1934.182495 1910.559082,1934.434937 
	C1906.860840,1935.240845 1903.901978,1935.025269 1900.298096,1934.152222 
	C1899.653931,1933.536011 1899.582642,1933.347168 1899.500244,1933.288818 
z"
        />
        <path
          fill="#F3AB7B"
          opacity="1.000000"
          stroke="none"
          d="
M2055.476562,1901.882568 
	C2058.979248,1908.421265 2057.378906,1915.417603 2057.998047,1923.098511 
	C2061.278076,1927.174561 2059.404297,1930.748657 2058.563965,1935.056152 
	C2058.122559,1937.460815 2058.017090,1939.027710 2057.920898,1941.383789 
	C2056.268555,1943.746338 2054.918701,1943.606201 2053.797363,1940.921387 
	C2049.715820,1934.412354 2054.068848,1925.013794 2045.452637,1920.193359 
	C2045.636353,1917.090210 2045.993286,1914.675049 2046.368042,1911.551025 
	C2048.206055,1908.666626 2047.662720,1905.122070 2051.247559,1903.336304 
	C2052.330566,1903.026001 2052.753906,1902.965698 2053.666504,1902.490234 
	C2054.155762,1902.074829 2055.030273,1901.892822 2055.476562,1901.882568 
z"
        />
        <path
          fill="#6F7981"
          opacity="1.000000"
          stroke="none"
          d="
M1901.787842,1901.388428 
	C1901.914551,1904.932617 1901.542236,1908.123169 1904.278076,1911.374512 
	C1903.290283,1912.361450 1902.099243,1912.587646 1900.482056,1913.251953 
	C1898.122070,1915.855103 1898.430786,1919.014038 1896.125732,1921.667358 
	C1892.294189,1922.168457 1889.436279,1921.089600 1886.461304,1918.607788 
	C1883.867798,1915.186890 1883.616943,1912.107666 1886.080566,1908.340820 
	C1890.566772,1907.674927 1894.631836,1909.212646 1897.673340,1904.632324 
	C1899.033203,1903.085938 1898.981934,1901.779785 1899.694824,1900.174316 
	C1900.722778,1900.095215 1901.201538,1900.487549 1901.787842,1901.388428 
z"
        />
        <path
          fill="#EFF1EA"
          opacity="1.000000"
          stroke="none"
          d="
M1777.084961,1908.824463 
	C1777.676270,1907.224854 1777.873657,1906.287476 1777.940674,1904.638794 
	C1778.920654,1902.715454 1780.065552,1901.584229 1782.529541,1902.315308 
	C1784.366455,1908.348145 1785.614746,1914.238525 1787.038574,1920.947266 
	C1786.279541,1927.955078 1784.299438,1932.009644 1777.149902,1925.769165 
	C1775.934326,1923.574341 1775.538208,1921.972778 1775.262817,1919.518555 
	C1775.186646,1917.123901 1775.091309,1915.530884 1775.646240,1913.209717 
	C1776.019775,1911.437134 1776.295410,1910.445190 1777.084961,1908.824463 
z"
        />
        <path
          fill="#ECA777"
          opacity="1.000000"
          stroke="none"
          d="
M1959.098877,1908.003662 
	C1959.543457,1910.621704 1959.763550,1912.900024 1959.451172,1915.102783 
	C1959.036133,1918.027710 1957.163574,1919.853394 1954.098022,1919.302734 
	C1950.990356,1918.744385 1950.063232,1916.392944 1950.703491,1913.481567 
	C1950.976074,1912.242798 1951.620361,1911.085571 1952.224243,1909.215820 
	C1952.352783,1908.540527 1952.420288,1908.397705 1952.418945,1908.318848 
	C1952.536865,1907.551636 1952.892212,1907.021729 1953.925537,1906.438232 
	C1956.040283,1905.935547 1957.486450,1906.333618 1959.098877,1908.003662 
z"
        />
        <path
          fill="#A9AEB3"
          opacity="1.000000"
          stroke="none"
          d="
M1886.720947,1907.031372 
	C1886.635010,1910.665771 1886.357178,1913.517334 1886.059082,1917.235352 
	C1880.713135,1917.116577 1882.331055,1912.855713 1881.952881,1908.725830 
	C1882.668579,1905.223999 1883.421143,1902.515259 1884.270874,1899.107788 
	C1884.572021,1898.066528 1884.776123,1897.723999 1885.378662,1897.827393 
	C1886.027832,1900.931641 1886.278320,1903.590088 1886.720947,1907.031372 
z"
        />
        <path
          fill="#959693"
          opacity="1.000000"
          stroke="none"
          d="
M1886.185059,1854.617920 
	C1889.434570,1854.400146 1890.266846,1856.670044 1890.193237,1859.500000 
	C1890.074585,1864.063477 1889.244385,1868.393311 1885.030273,1871.933105 
	C1884.943726,1866.536743 1888.272217,1861.223267 1886.185059,1854.617920 
z"
        />
        <path
          fill="#79868C"
          opacity="1.000000"
          stroke="none"
          d="
M1900.061768,1899.261475 
	C1899.784302,1900.940186 1899.386108,1902.102539 1898.492676,1903.637207 
	C1898.040649,1902.208496 1897.845581,1900.356812 1898.211548,1898.623657 
	C1898.451416,1897.488281 1899.408691,1897.754761 1900.061768,1899.261475 
z"
        />
        <path
          fill="#5A605E"
          opacity="1.000000"
          stroke="none"
          d="
M1867.866943,1853.962891 
	C1867.052490,1854.476318 1866.221313,1854.347534 1864.757568,1854.226807 
	C1864.142456,1852.972534 1864.256104,1851.480957 1865.554321,1851.126831 
	C1867.017700,1850.727539 1867.590088,1852.040039 1867.866943,1853.962891 
z"
        />
        <path
          fill="#6F7981"
          opacity="1.000000"
          stroke="none"
          d="
M1885.704956,1897.880615 
	C1885.777222,1898.273193 1885.069824,1898.330811 1884.718994,1898.369873 
	C1884.482788,1897.680908 1884.326538,1896.580322 1885.704956,1897.880615 
z"
        />
        <path
          fill="#6F7981"
          opacity="1.000000"
          stroke="none"
          d="
M1911.709473,1898.090332 
	C1911.698608,1898.543457 1910.819092,1898.519775 1910.418457,1898.338623 
	C1910.357422,1897.237793 1910.699829,1896.328003 1911.709473,1898.090332 
z"
        />
        <path
          fill="#6F7981"
          opacity="1.000000"
          stroke="none"
          d="
M1908.020508,1900.401001 
	C1908.160278,1900.988403 1907.740479,1901.685547 1906.675659,1902.292603 
	C1906.507446,1901.638550 1906.984131,1901.074585 1908.020508,1900.401001 
z"
        />
        <path
          fill="#797771"
          opacity="1.000000"
          stroke="none"
          d="
M2053.736816,1902.138184 
	C2053.715332,1902.549072 2053.275146,1903.023315 2052.370850,1903.291870 
	C2052.292236,1902.655884 2052.762451,1902.360962 2053.736816,1902.138184 
z"
        />
        <path
          fill="#6F7981"
          opacity="1.000000"
          stroke="none"
          d="
M1953.887207,1906.390869 
	C1954.185791,1906.879639 1953.834839,1907.423950 1952.866089,1908.049438 
	C1952.747437,1907.678467 1953.077271,1907.116943 1953.887207,1906.390869 
z"
        />
        <path
          fill="#C4BDAE"
          opacity="1.000000"
          stroke="none"
          d="
M2514.069092,1927.213623 
	C2510.363281,1927.625366 2508.912598,1928.767456 2507.460205,1931.649048 
	C2504.664062,1937.196167 2498.485840,1937.507812 2493.041504,1932.517334 
	C2498.413330,1929.250488 2502.410645,1925.869019 2501.735840,1919.053467 
	C2497.822266,1921.115601 2497.788818,1925.814209 2494.041016,1928.321533 
	C2490.818115,1928.382690 2488.253418,1928.239990 2484.916016,1928.161377 
	C2480.336426,1925.499878 2480.797363,1921.135132 2479.857910,1916.526123 
	C2480.625244,1912.229492 2481.544434,1908.699707 2482.294434,1905.134277 
	C2482.812500,1902.672363 2483.763184,1900.380737 2486.495117,1900.295532 
	C2493.700684,1900.070557 2494.085938,1895.352783 2493.780273,1889.972900 
	C2493.705078,1888.648560 2493.801758,1887.314209 2493.835938,1885.146484 
	C2493.789551,1879.036377 2496.482910,1874.771362 2499.025146,1870.489502 
	C2499.914062,1868.992188 2501.359375,1868.141968 2503.644531,1869.617920 
	C2504.029297,1877.277832 2504.470947,1884.508057 2503.809570,1891.635986 
	C2503.219727,1897.993164 2507.921143,1902.958496 2507.334717,1909.321777 
	C2507.131592,1911.526245 2510.600342,1911.965454 2513.768066,1911.454834 
	C2518.220459,1916.329834 2518.161865,1921.402100 2514.069092,1927.213623 
z"
        />
        <path
          fill="#EEE9DC"
          opacity="1.000000"
          stroke="none"
          d="
M2465.603760,1892.569214 
	C2461.070801,1895.004517 2456.372070,1894.692871 2450.822266,1894.067627 
	C2449.859619,1888.934692 2452.721191,1886.653931 2457.064209,1886.504883 
	C2460.855225,1886.374512 2465.030273,1886.510742 2465.603760,1892.569214 
z"
        />
        <path
          fill="#8C8D89"
          opacity="1.000000"
          stroke="none"
          d="
M2375.573242,1894.715454 
	C2370.443359,1900.826294 2363.700195,1901.609619 2356.002686,1897.214600 
	C2362.033447,1895.583252 2368.591553,1897.055908 2375.573242,1894.715454 
z"
        />
        <path
          fill="#5A6668"
          opacity="1.000000"
          stroke="none"
          d="
M2470.059082,1923.865479 
	C2461.641357,1926.286499 2453.977539,1928.924805 2445.095703,1929.854980 
	C2439.388428,1927.372559 2442.084717,1920.649902 2437.143799,1918.072754 
	C2435.298584,1918.936157 2433.445312,1918.613159 2432.738037,1921.099731 
	C2432.250000,1923.444092 2431.697754,1925.086426 2429.912842,1926.791382 
	C2421.556152,1922.728271 2413.465820,1923.759155 2404.461670,1925.950928 
	C2398.367676,1918.822388 2397.108154,1911.231079 2401.833496,1902.538940 
	C2413.303223,1900.885742 2423.646729,1905.186279 2434.344238,1904.015625 
	C2436.981445,1903.727173 2439.818848,1904.224854 2442.263184,1903.432739 
	C2447.572998,1901.711914 2452.563965,1901.948486 2457.907959,1903.434082 
	C2461.334717,1904.386597 2465.196045,1904.177734 2468.822510,1903.921631 
	C2474.763428,1903.501953 2476.491699,1906.498535 2476.063965,1912.562012 
	C2475.333496,1914.411743 2474.582520,1915.458618 2474.069336,1917.357056 
	C2473.672607,1920.357544 2472.603027,1922.263306 2470.059082,1923.865479 
z"
        />
        <path
          fill="#46565D"
          opacity="1.000000"
          stroke="none"
          d="
M2231.482910,1912.864502 
	C2231.880127,1910.725220 2232.137207,1909.250366 2232.579590,1907.051270 
	C2238.362793,1905.936401 2243.958008,1905.326050 2249.419434,1907.410400 
	C2250.614258,1907.866455 2252.190674,1908.255127 2253.299072,1907.858154 
	C2262.559082,1904.540405 2270.569580,1908.452271 2279.444824,1911.960205 
	C2282.645752,1916.918335 2280.107910,1920.662354 2276.516846,1924.828735 
	C2260.662842,1929.606934 2254.073486,1927.958862 2246.788574,1917.667725 
	C2242.732422,1910.824341 2237.414062,1917.302734 2232.666504,1915.006592 
	C2231.832520,1914.290771 2231.587402,1913.909912 2231.482910,1912.864502 
z"
        />
        <path
          fill="#4E585F"
          opacity="1.000000"
          stroke="none"
          d="
M2320.379883,1916.561401 
	C2318.784180,1916.847168 2317.796387,1917.344727 2316.320557,1918.103760 
	C2311.121338,1915.537598 2310.791992,1911.661865 2313.931152,1906.634033 
	C2321.312256,1903.361572 2328.219238,1903.101562 2334.967041,1904.796631 
	C2339.549316,1905.947510 2344.056885,1905.818726 2348.624512,1905.995117 
	C2351.541992,1906.107910 2354.708740,1905.681274 2356.286133,1910.174438 
	C2358.077148,1915.884644 2358.800537,1920.688477 2354.430664,1925.479004 
	C2350.318359,1926.331909 2347.099365,1925.746704 2343.374512,1924.003906 
	C2339.966797,1923.391968 2338.877197,1921.057251 2338.533691,1919.005005 
	C2337.330322,1911.816895 2335.187012,1918.420654 2332.866455,1918.580322 
	C2328.740234,1916.357788 2325.297852,1913.942627 2320.379883,1916.561401 
z"
        />
        <path
          fill="#A6A29C"
          opacity="1.000000"
          stroke="none"
          d="
M2401.964844,1901.502441 
	C2400.865723,1909.568848 2402.952393,1916.899780 2403.748535,1925.133057 
	C2397.715332,1925.945435 2391.823975,1925.920898 2385.049316,1925.940918 
	C2386.511475,1923.605225 2388.155518,1921.041138 2387.999512,1917.404297 
	C2387.875000,1914.503296 2388.215820,1911.518188 2388.800537,1908.662720 
	C2390.729004,1899.243408 2390.807129,1899.259399 2401.964844,1901.502441 
z"
        />
        <path
          fill="#8C8D89"
          opacity="1.000000"
          stroke="none"
          d="
M2248.548828,1892.783691 
	C2250.227051,1889.431519 2253.821777,1888.344238 2256.808350,1888.425659 
	C2270.212646,1888.791382 2283.703125,1884.745728 2297.538086,1889.671387 
	C2297.220703,1891.981689 2296.015625,1893.298340 2293.637939,1893.981201 
	C2286.500977,1896.846436 2279.425537,1894.973633 2271.864502,1896.114258 
	C2263.599365,1896.305054 2256.214844,1895.884521 2248.548828,1892.783691 
z"
        />
        <path
          fill="#9DAAB4"
          opacity="1.000000"
          stroke="none"
          d="
M2314.423096,1905.332031 
	C2313.962891,1909.759766 2313.596191,1913.376343 2315.538574,1917.629395 
	C2315.646729,1920.820435 2315.449707,1923.269165 2311.681885,1924.094971 
	C2309.988770,1925.234497 2308.758789,1925.497314 2306.787354,1925.282959 
	C2304.988037,1924.689819 2303.847656,1924.152222 2302.053711,1923.565796 
	C2301.096924,1923.154785 2300.734619,1922.944336 2300.047363,1922.195923 
	C2301.073486,1917.590210 2301.952637,1913.480835 2302.185791,1909.140991 
	C2302.636719,1900.750366 2306.279297,1899.437256 2314.423096,1905.332031 
z"
        />
        <path
          fill="#E7E3DA"
          opacity="1.000000"
          stroke="none"
          d="
M2276.106445,1924.793579 
	C2277.590820,1920.581177 2278.147705,1916.741333 2280.200195,1912.675537 
	C2282.413574,1912.165039 2282.234375,1915.338623 2284.659668,1915.394043 
	C2286.609863,1914.650024 2286.904785,1912.487671 2288.008301,1910.963135 
	C2288.947021,1909.666626 2289.793945,1908.303711 2291.117432,1906.315063 
	C2295.401123,1909.812012 2293.519043,1914.366455 2294.427979,1918.883057 
	C2295.351318,1924.006592 2291.017822,1924.770996 2288.957520,1927.829346 
	C2288.479492,1928.697388 2288.283691,1929.039795 2287.770020,1929.872681 
	C2287.226807,1930.675293 2287.001709,1930.987427 2286.071045,1932.189575 
	C2284.889893,1933.659180 2284.393066,1934.220093 2282.951172,1935.369141 
	C2281.214600,1935.914185 2280.401855,1935.852661 2278.982422,1935.749512 
	C2278.375488,1935.708008 2278.114258,1935.722656 2278.064941,1935.843994 
	C2276.326172,1932.621704 2275.899658,1929.000488 2276.106445,1924.793579 
z"
        />
        <path
          fill="#B6BCC1"
          opacity="1.000000"
          stroke="none"
          d="
M2353.799805,1926.699219 
	C2354.616211,1921.424316 2355.509766,1916.940552 2356.479980,1911.728516 
	C2359.681152,1913.824219 2359.529053,1917.852539 2360.489746,1921.495117 
	C2361.277832,1924.483765 2361.557373,1928.014893 2366.481934,1928.614014 
	C2368.154541,1933.595703 2365.685059,1935.470337 2361.276855,1935.669800 
	C2355.514404,1935.930786 2353.893799,1932.433228 2353.799805,1926.699219 
z"
        />
        <path
          fill="#E7E3DA"
          opacity="1.000000"
          stroke="none"
          d="
M2271.378418,1896.919678 
	C2277.129395,1889.570312 2284.210205,1891.289062 2292.224609,1893.802612 
	C2292.446533,1895.497192 2292.064697,1898.109131 2291.130371,1898.324463 
	C2284.659668,1899.815918 2277.996338,1902.764771 2271.378418,1896.919678 
z"
        />
        <path
          fill="#9DAAB4"
          opacity="1.000000"
          stroke="none"
          d="
M2286.580566,1930.361572 
	C2285.141602,1924.218384 2290.429443,1923.376465 2294.034180,1920.214600 
	C2295.899658,1920.228638 2297.276855,1920.787354 2299.187988,1921.501953 
	C2299.722168,1921.657959 2300.266602,1922.222046 2300.545898,1922.497314 
	C2302.055908,1924.101562 2303.338135,1925.378540 2305.171875,1927.080811 
	C2307.205322,1929.984009 2310.006836,1932.310303 2306.918701,1935.319214 
	C2304.101074,1938.064575 2300.941406,1937.660522 2297.923096,1935.484619 
	C2294.675049,1933.143066 2291.307373,1931.104126 2286.580566,1930.361572 
z"
        />
        <path
          fill="#EFF1EA"
          opacity="1.000000"
          stroke="none"
          d="
M2479.391113,1916.168945 
	C2483.672852,1918.442383 2483.945068,1922.499878 2484.048340,1927.441895 
	C2484.142822,1928.225342 2483.959229,1928.988647 2483.812500,1929.352783 
	C2483.002686,1930.614380 2482.115234,1931.109497 2480.227051,1931.040283 
	C2478.636475,1930.776367 2477.822021,1930.674072 2476.419922,1930.388062 
	C2473.526855,1926.888428 2473.826416,1923.753418 2477.105957,1920.183594 
	C2478.151611,1918.577637 2478.577881,1917.570557 2479.391113,1916.168945 
z"
        />
        <path
          fill="#8C8D89"
          opacity="1.000000"
          stroke="none"
          d="
M2323.917480,1892.857666 
	C2327.217529,1891.764038 2328.979736,1894.317749 2331.834961,1896.357056 
	C2331.404541,1899.132935 2329.622314,1900.241455 2327.099121,1899.697754 
	C2323.705566,1898.966675 2324.098633,1896.102417 2323.917480,1892.857666 
z"
        />
        <path
          fill="#C4BDAE"
          opacity="1.000000"
          stroke="none"
          d="
M2477.236816,1919.141724 
	C2477.108154,1922.601318 2476.490967,1925.617920 2475.853027,1929.419434 
	C2475.832031,1930.204346 2474.975586,1930.274780 2474.551758,1930.204346 
	C2470.554688,1926.307617 2472.556152,1921.932983 2472.606445,1916.963745 
	C2472.358643,1914.602417 2473.349365,1913.896362 2475.447266,1913.498047 
	C2476.322021,1915.152344 2476.534912,1916.925537 2477.236816,1919.141724 
z"
        />
        <path
          fill="#5A6668"
          opacity="1.000000"
          stroke="none"
          d="
M2428.926270,1897.043823 
	C2428.880127,1896.958496 2428.972412,1897.129150 2428.926270,1897.043823 
z"
        />
        <path
          fill="#A9ACA9"
          opacity="1.000000"
          stroke="none"
          d="
M2609.516113,1830.966064 
	C2613.512451,1830.516235 2616.763428,1830.505859 2620.821777,1830.599121 
	C2626.537842,1834.362793 2624.345703,1840.082764 2625.892578,1845.506592 
	C2626.057617,1847.074829 2626.039795,1847.907837 2626.010986,1849.365479 
	C2618.193115,1853.540894 2616.072510,1859.776611 2617.399902,1867.797241 
	C2618.964111,1877.249146 2614.789307,1886.112549 2614.113281,1896.093262 
	C2613.588867,1897.250854 2613.325439,1897.686890 2612.518066,1898.654785 
	C2609.961914,1900.662476 2607.313477,1900.886475 2604.724609,1902.856934 
	C2603.118652,1903.452759 2602.157227,1903.588501 2600.465576,1903.483154 
	C2596.315430,1902.261841 2592.961426,1900.788208 2589.910400,1898.967529 
	C2582.789307,1894.718018 2575.471924,1893.427856 2566.851074,1895.850830 
	C2565.295898,1895.336182 2564.538574,1894.816162 2563.660156,1893.477783 
	C2563.444336,1892.156860 2563.827637,1891.597656 2564.004883,1891.002808 
	C2564.347412,1889.853271 2566.393066,1890.362915 2566.102539,1888.720215 
	C2565.274902,1889.390015 2564.332520,1889.405762 2562.717285,1888.889648 
	C2558.695557,1885.290161 2560.553711,1881.000366 2559.844238,1876.432373 
	C2570.022217,1876.022583 2570.655762,1876.738403 2568.058105,1885.485352 
	C2573.501709,1887.129150 2580.128418,1884.058350 2585.290771,1889.695801 
	C2588.881348,1885.019653 2587.685059,1879.446533 2588.018555,1874.063599 
	C2588.233643,1870.591675 2587.190674,1866.911377 2590.364258,1863.429565 
	C2598.148682,1864.009521 2597.831787,1869.616211 2597.750488,1874.401245 
	C2597.678467,1878.647827 2596.858154,1882.914795 2596.901367,1885.992065 
	C2597.763672,1878.499390 2597.544434,1869.795776 2597.060303,1861.057495 
	C2596.908203,1858.315552 2597.646729,1854.246948 2601.247314,1854.474731 
	C2604.470947,1854.678833 2603.858398,1858.554077 2603.938477,1861.088379 
	C2604.244873,1870.784424 2602.030273,1880.426880 2602.992676,1888.399170 
	C2603.238770,1874.081665 2604.405762,1858.016602 2602.867188,1841.938110 
	C2602.451660,1837.595215 2603.991211,1833.336548 2609.516113,1830.966064 
z"
        />
        <path
          fill="#CBC6BA"
          opacity="1.000000"
          stroke="none"
          d="
M2612.731201,1898.502197 
	C2612.374756,1890.893921 2611.423584,1883.971680 2614.588623,1876.789673 
	C2616.577881,1872.276001 2614.158203,1865.908813 2614.063477,1860.367310 
	C2613.890625,1850.243408 2613.998047,1850.241577 2625.125000,1849.987305 
	C2626.000000,1849.990234 2626.000000,1850.000000 2626.004883,1850.000000 
	C2626.731689,1850.609375 2626.955811,1851.429077 2626.993164,1852.327637 
	C2627.141357,1851.869507 2627.074463,1851.383423 2627.602051,1850.469360 
	C2633.369385,1850.509644 2634.895996,1854.096680 2633.761719,1858.473999 
	C2631.038574,1868.982178 2632.484863,1879.621826 2632.097656,1891.138916 
	C2632.128418,1892.079834 2631.894287,1892.873413 2631.784668,1893.270752 
	C2630.263916,1893.792480 2628.852295,1893.916748 2626.729492,1894.005859 
	C2622.277588,1890.378174 2623.365479,1885.779297 2623.444336,1881.079590 
	C2623.490967,1880.758545 2622.979980,1881.402344 2622.972656,1881.912598 
	C2622.892090,1886.058960 2623.429443,1889.801758 2620.702393,1893.647217 
	C2619.747314,1895.209229 2619.201172,1896.170044 2618.430176,1897.635254 
	C2616.744629,1899.189209 2615.164307,1899.581421 2612.731201,1898.502197 
z"
        />
        <path
          fill="#F0EBDD"
          opacity="1.000000"
          stroke="none"
          d="
M2675.656006,1856.723633 
	C2675.548096,1854.907471 2675.841064,1853.475098 2676.329102,1851.349609 
	C2679.743164,1850.428345 2682.962646,1850.200073 2686.976074,1849.775269 
	C2688.185059,1849.325073 2688.599609,1849.071655 2689.600098,1849.252197 
	C2697.001709,1850.437378 2703.816162,1851.169189 2711.071045,1847.715576 
	C2714.927246,1850.492188 2713.196289,1852.432129 2710.088623,1853.862793 
	C2707.769531,1854.930298 2705.349854,1855.780151 2702.532715,1857.215698 
	C2699.974365,1857.823364 2697.858398,1857.942383 2694.952637,1858.129150 
	C2689.299072,1860.179810 2684.418213,1860.576538 2678.754150,1858.239746 
	C2677.352051,1857.783936 2676.704590,1857.445801 2675.656006,1856.723633 
z"
        />
        <path
          fill="#CBC6BA"
          opacity="1.000000"
          stroke="none"
          d="
M2626.607910,1845.748169 
	C2623.053467,1845.693359 2621.919434,1843.766113 2621.962646,1840.832520 
	C2622.004639,1837.984375 2621.893311,1835.134155 2621.739014,1831.493652 
	C2625.048828,1830.443481 2628.469238,1830.184204 2632.716553,1829.850586 
	C2635.189209,1836.499390 2633.559570,1841.867554 2626.607910,1845.748169 
z"
        />
        <path
          fill="#A9ACA9"
          opacity="1.000000"
          stroke="none"
          d="
M2627.523682,1850.005859 
	C2627.685303,1850.816406 2627.342529,1851.613403 2626.999756,1852.410400 
	C2626.694092,1851.919922 2626.388428,1851.429443 2626.046387,1850.469482 
	C2626.346436,1849.997314 2626.682861,1849.994629 2627.523682,1850.005859 
z"
        />
        <path
          fill="#C7C0B1"
          opacity="1.000000"
          stroke="none"
          d="
M2690.091309,1849.251343 
	C2689.781738,1849.702148 2689.378174,1849.717896 2688.372559,1849.656006 
	C2688.190674,1848.870483 2688.611328,1848.162354 2689.031738,1847.454102 
	C2689.353516,1847.908203 2689.675293,1848.362305 2690.091309,1849.251343 
z"
        />
        <path
          fill="#CBC6BA"
          opacity="1.000000"
          stroke="none"
          d="
M2609.351562,1830.337158 
	C2606.653564,1834.778564 2605.477539,1839.643555 2605.958008,1845.192993 
	C2607.360596,1861.398315 2603.410156,1877.319214 2602.204590,1893.496704 
	C2602.204590,1881.215820 2602.204590,1868.934937 2602.204590,1856.654053 
	C2601.521973,1856.615845 2600.839355,1856.577759 2600.156982,1856.539551 
	C2600.377441,1867.780518 2599.486084,1878.872070 2596.933594,1890.990479 
	C2594.296143,1881.451416 2598.965820,1871.702148 2590.900879,1864.268799 
	C2586.887939,1861.057251 2588.065430,1857.386475 2589.108398,1853.109863 
	C2595.031006,1847.866943 2590.920410,1842.111206 2591.569092,1835.538696 
	C2591.848877,1833.234131 2591.901367,1832.299561 2591.960938,1830.661865 
	C2597.459229,1830.021851 2602.950684,1830.084717 2609.351562,1830.337158 
z"
        />
        <path
          fill="#F0EBDD"
          opacity="1.000000"
          stroke="none"
          d="
M2592.334229,1851.571533 
	C2589.768066,1855.508179 2589.854736,1859.038208 2590.097656,1863.349365 
	C2589.132324,1872.920044 2592.061035,1882.201416 2586.472168,1890.291992 
	C2585.992432,1876.066895 2585.998779,1862.069214 2586.108154,1848.072510 
	C2586.146973,1843.126587 2586.052490,1838.062378 2591.077148,1834.288574 
	C2591.796387,1834.168823 2591.934082,1834.130615 2591.974854,1834.072021 
	C2592.945557,1839.579956 2594.576660,1845.109009 2592.334229,1851.571533 
z"
        />
        <path
          fill="#959693"
          opacity="1.000000"
          stroke="none"
          d="
M2564.414062,1891.473145 
	C2564.457275,1892.720825 2564.973633,1893.327148 2566.017334,1894.228516 
	C2567.832764,1900.237061 2563.919678,1902.518433 2559.688232,1903.741211 
	C2555.582764,1904.927490 2550.877197,1905.763794 2549.616211,1898.849243 
	C2552.195801,1897.946899 2554.437256,1898.085083 2556.617920,1897.782837 
	C2559.485107,1897.385376 2560.244385,1895.606201 2558.563965,1892.507080 
	C2558.253906,1887.021729 2557.358887,1881.960205 2559.321777,1876.350342 
	C2563.590088,1878.664551 2560.835693,1883.443115 2563.356934,1887.456787 
	C2564.713135,1888.755981 2565.053467,1889.702881 2564.414062,1891.473145 
z"
        />
        <path
          fill="#626669"
          opacity="1.000000"
          stroke="none"
          d="
M2098.222412,1892.723145 
	C2098.157959,1890.610229 2098.215820,1889.187622 2098.269531,1887.052490 
	C2100.843750,1879.134644 2105.981934,1878.328247 2113.065674,1881.245605 
	C2119.151855,1884.187866 2120.851562,1889.906616 2125.228516,1893.947510 
	C2130.785156,1894.084473 2135.649170,1895.092773 2140.514160,1893.264404 
	C2145.408203,1891.425049 2149.455811,1893.589844 2153.517334,1897.436768 
	C2154.166992,1898.221069 2154.278320,1898.536011 2154.564941,1899.320312 
	C2155.339844,1902.050171 2153.318359,1903.319824 2152.626221,1905.407593 
	C2155.726807,1905.375488 2155.435059,1902.113770 2157.633057,1900.532227 
	C2163.544189,1899.481201 2168.960693,1898.855957 2174.382324,1898.278564 
	C2177.242188,1897.973877 2178.094482,1896.158203 2177.987305,1892.794922 
	C2178.130371,1888.859985 2178.657471,1885.527100 2182.784668,1886.359619 
	C2186.455322,1887.099976 2191.036133,1886.979248 2192.890869,1891.205444 
	C2193.735840,1893.130615 2191.561768,1893.739746 2189.920654,1894.075684 
	C2188.287354,1894.410034 2186.552246,1894.801392 2186.601074,1896.924316 
	C2186.661621,1899.572266 2188.751221,1899.873291 2190.749268,1899.991821 
	C2192.706787,1900.108032 2194.675293,1900.041260 2197.499023,1900.025269 
	C2200.782959,1908.670654 2196.587158,1915.594482 2191.772461,1923.017090 
	C2188.334229,1925.007812 2185.335205,1925.787598 2181.419922,1925.829102 
	C2177.705322,1925.103882 2176.519287,1922.900635 2175.635742,1920.245850 
	C2174.854248,1917.898438 2175.438965,1914.219604 2171.998779,1913.947266 
	C2168.709717,1913.687256 2168.521484,1917.363647 2167.143555,1919.450806 
	C2166.598389,1920.276001 2166.112549,1921.140747 2165.000732,1922.450806 
	C2162.364014,1924.471558 2160.033447,1925.160278 2156.729980,1924.461914 
	C2155.125244,1924.166016 2154.217285,1924.159790 2152.628906,1924.192993 
	C2141.432129,1923.320068 2129.863281,1928.437134 2120.785400,1919.951416 
	C2118.425049,1917.744751 2120.000000,1920.645996 2119.628906,1920.333496 
	C2118.439453,1922.934082 2116.844238,1925.175171 2113.585205,1923.907471 
	C2110.374756,1922.658691 2109.890137,1919.576782 2109.270020,1916.557007 
	C2108.311279,1911.887695 2111.277832,1906.411621 2105.829590,1902.218384 
	C2101.774902,1900.004150 2099.445312,1897.206909 2098.222412,1892.723145 
z"
        />
        <path
          fill="#626669"
          opacity="1.000000"
          stroke="none"
          d="
M2219.015137,1900.071045 
	C2220.480957,1901.083496 2221.044434,1902.080688 2221.861816,1903.591797 
	C2226.034668,1912.277710 2222.395020,1917.942871 2215.203613,1923.062988 
	C2212.819092,1924.018921 2211.151611,1924.113159 2208.657227,1923.557251 
	C2200.525146,1917.763550 2198.610596,1910.061401 2199.509277,1900.684570 
	C2205.979248,1900.000610 2212.046143,1900.028198 2219.015137,1900.071045 
z"
        />
        <path
          fill="#E7E3DA"
          opacity="1.000000"
          stroke="none"
          d="
M2199.525391,1900.070557 
	C2201.039795,1907.430054 2203.396729,1914.436768 2207.984375,1921.358521 
	C2208.747559,1929.320190 2205.550293,1932.802246 2197.872803,1932.741089 
	C2192.209473,1932.696045 2187.117920,1931.536499 2190.109863,1923.173828 
	C2193.327148,1915.566162 2195.734131,1908.532104 2198.250000,1900.747070 
	C2198.359131,1899.995850 2199.138428,1900.168335 2199.525391,1900.070557 
z"
        />
        <path
          fill="#83837F"
          opacity="1.000000"
          stroke="none"
          d="
M2740.464355,1860.158813 
	C2740.119629,1858.649536 2740.332764,1857.010498 2741.796631,1857.027832 
	C2744.022461,1857.053955 2743.614990,1859.210205 2744.017090,1861.319580 
	C2743.040527,1861.446289 2742.014648,1860.923584 2740.464355,1860.158813 
z"
        />
        <path
          fill="#83837F"
          opacity="1.000000"
          stroke="none"
          d="
M2785.421387,1874.288208 
	C2785.382324,1874.658691 2784.867676,1874.528320 2784.607666,1874.502686 
	C2784.547119,1873.949707 2784.373779,1872.680542 2785.421387,1874.288208 
z"
        />
        <path
          fill="#7B7F84"
          opacity="1.000000"
          stroke="none"
          d="
M2083.461182,1882.007080 
	C2081.567627,1882.270996 2080.459961,1882.170776 2078.517090,1882.013550 
	C2079.298096,1875.791748 2079.893066,1869.676392 2076.863037,1863.715820 
	C2075.700195,1861.428467 2076.209961,1859.230103 2077.615723,1856.395508 
	C2080.764648,1856.456421 2083.610107,1857.205688 2087.192139,1857.985352 
	C2088.048096,1858.851929 2088.167480,1859.688477 2088.462402,1861.133789 
	C2092.892334,1869.637817 2091.126709,1876.204590 2083.461182,1882.007080 
z"
        />
        <path
          fill="#5A6668"
          opacity="1.000000"
          stroke="none"
          d="
M2549.920410,1897.257446 
	C2550.136719,1900.265015 2551.224121,1901.681274 2553.590820,1901.779297 
	C2558.779297,1901.994385 2563.296631,1900.745850 2566.301270,1895.181763 
	C2568.384033,1893.875610 2570.490234,1893.599365 2572.011475,1892.508057 
	C2576.099854,1889.575317 2579.205322,1890.082275 2583.435303,1893.023560 
	C2588.462646,1896.519409 2594.576172,1898.452881 2600.783203,1901.581665 
	C2603.859863,1909.394165 2599.629150,1915.863892 2598.251953,1923.259644 
	C2595.023438,1923.808228 2592.364746,1923.849243 2588.907471,1923.894165 
	C2587.417969,1924.639771 2586.580078,1925.094116 2584.842773,1925.102539 
	C2571.292725,1916.000854 2557.313721,1911.810181 2540.413574,1914.185791 
	C2543.003174,1919.578735 2550.683594,1920.613403 2548.477539,1927.780762 
	C2547.042969,1929.209717 2545.889404,1930.067505 2544.538330,1929.662842 
	C2534.913818,1926.780884 2525.044922,1928.390625 2514.294922,1927.927246 
	C2513.706543,1923.008057 2514.112061,1918.132202 2514.516846,1912.296631 
	C2514.422607,1908.700684 2514.177979,1906.058472 2514.275391,1903.429077 
	C2514.388916,1900.363770 2516.302490,1897.973389 2519.208008,1898.325439 
	C2529.396729,1899.559448 2537.699707,1894.979736 2546.127686,1890.505615 
	C2546.654297,1890.226074 2547.425537,1890.406738 2548.773193,1890.479980 
	C2549.595703,1892.561157 2549.727783,1894.535645 2549.920410,1897.257446 
z"
        />
        <path
          fill="#959693"
          opacity="1.000000"
          stroke="none"
          d="
M2154.062988,1897.086670 
	C2152.436768,1897.689087 2150.486084,1897.971313 2149.262695,1897.158813 
	C2145.962891,1894.967407 2143.388184,1894.411987 2139.491699,1896.714722 
	C2135.531982,1899.054810 2130.611816,1899.057739 2126.307617,1894.690918 
	C2127.525146,1890.553711 2130.622803,1889.981445 2134.037354,1889.847046 
	C2137.981445,1889.691650 2139.896729,1887.468018 2139.957520,1882.807373 
	C2142.100830,1882.013184 2144.243164,1881.982788 2147.197754,1881.981079 
	C2153.942383,1890.428223 2153.942383,1890.428223 2154.062988,1897.086670 
z"
        />
        <path
          fill="#959693"
          opacity="1.000000"
          stroke="none"
          d="
M2157.557373,1900.031982 
	C2157.529541,1909.479858 2157.036377,1910.204224 2151.202393,1910.052490 
	C2149.603516,1910.010986 2147.759521,1910.240723 2147.129883,1908.204590 
	C2146.590088,1906.458984 2147.409668,1904.766113 2148.555908,1903.733276 
	C2149.970459,1902.459106 2151.900391,1901.757080 2154.173828,1900.298828 
	C2155.489746,1899.852295 2156.239502,1899.914795 2157.557373,1900.031982 
z"
        />
        <path
          fill="#959693"
          opacity="1.000000"
          stroke="none"
          d="
M2147.995117,1881.995117 
	C2148.134277,1881.367920 2148.135986,1880.660889 2148.447266,1880.137939 
	C2149.856934,1877.767334 2151.823242,1876.374634 2154.698486,1877.269409 
	C2155.085205,1877.389893 2155.476074,1878.365601 2155.420410,1878.898682 
	C2155.009277,1882.818359 2151.842773,1881.640381 2148.758301,1882.003174 
	C2148.009766,1882.009766 2148.000000,1882.000000 2147.995117,1881.995117 
z"
        />
        <path
          fill="#C4C8C1"
          opacity="1.000000"
          stroke="none"
          d="
M2083.122070,1882.868042 
	C2084.816406,1876.151978 2089.878418,1870.776611 2088.391602,1862.590332 
	C2093.175293,1861.871948 2097.442627,1862.099121 2101.657959,1859.059326 
	C2105.126465,1856.557739 2109.952148,1858.448730 2115.013672,1857.953369 
	C2114.597412,1865.250977 2113.810791,1872.612671 2113.820801,1880.927368 
	C2108.494629,1881.219482 2103.511719,1881.564941 2098.961914,1886.092163 
	C2093.328857,1885.535889 2087.899170,1887.165527 2083.122070,1882.868042 
z"
        />
        <path
          fill="#BAB4A7"
          opacity="1.000000"
          stroke="none"
          d="
M2214.693359,1922.636841 
	C2219.773193,1917.789673 2220.967773,1911.760376 2222.000977,1904.855713 
	C2225.527832,1903.856323 2227.904297,1904.876221 2228.103516,1908.792480 
	C2228.174561,1910.192383 2229.031494,1911.188354 2230.953613,1912.009766 
	C2231.623291,1912.199951 2231.880615,1913.070190 2232.039062,1913.495361 
	C2233.252930,1914.892090 2234.562500,1915.637573 2235.111572,1917.852051 
	C2228.019531,1930.103394 2223.467773,1931.171265 2214.693359,1922.636841 
z"
        />
        <path
          fill="#3D494C"
          opacity="1.000000"
          stroke="none"
          d="
M1399.376343,1869.917114 
	C1400.890747,1870.223755 1401.804199,1870.192139 1403.404419,1870.135010 
	C1408.218750,1869.529297 1410.963745,1871.822144 1413.583252,1874.544312 
	C1417.901489,1879.031982 1423.076660,1879.135132 1428.151367,1876.425293 
	C1431.241943,1874.775024 1434.054565,1873.318115 1437.484741,1874.803711 
	C1443.010132,1877.196289 1447.706055,1875.393677 1452.508545,1872.501465 
	C1458.658569,1868.797729 1460.931885,1869.346558 1465.141968,1874.652710 
	C1466.375000,1876.206787 1467.255249,1878.074585 1468.626953,1879.475952 
	C1470.164062,1881.046021 1471.557861,1883.858765 1474.186890,1882.807617 
	C1477.051147,1881.662354 1475.914795,1878.842041 1475.241577,1876.732422 
	C1473.972290,1872.754272 1476.187988,1872.107422 1479.285645,1872.022583 
	C1481.766724,1871.954590 1483.870728,1872.817871 1485.521240,1874.622437 
	C1489.246460,1878.695190 1492.794434,1878.464722 1496.535156,1874.555908 
	C1499.100220,1871.875732 1502.386597,1871.115479 1505.609741,1873.116211 
	C1511.450684,1876.741577 1518.749268,1876.764038 1524.272339,1881.537476 
	C1527.631592,1884.440796 1532.356201,1886.440918 1534.826904,1879.361694 
	C1535.446777,1877.585693 1538.223022,1875.883911 1540.729980,1877.294800 
	C1542.367676,1878.216309 1541.806152,1880.257446 1541.847656,1882.570557 
	C1542.541870,1888.364990 1546.584717,1886.789917 1549.575928,1886.953003 
	C1554.185425,1887.204102 1558.840210,1886.677734 1563.426758,1887.200439 
	C1567.870239,1887.706787 1572.277954,1888.701172 1570.638672,1895.737549 
	C1569.597412,1897.565186 1568.715942,1898.833008 1567.473999,1899.009155 
	C1555.314697,1900.732666 1543.725464,1905.182007 1530.745605,1906.288940 
	C1525.180176,1905.511841 1526.739380,1895.748413 1518.811279,1898.672363 
	C1517.191040,1898.003174 1516.568359,1897.085693 1515.868530,1895.688721 
	C1515.636963,1895.335449 1514.867432,1895.176514 1514.582031,1895.539551 
	C1512.281372,1900.056152 1511.417236,1904.940918 1506.266602,1907.784912 
	C1500.296021,1909.487793 1495.083984,1906.735107 1489.073975,1907.804688 
	C1482.127075,1909.156372 1478.097412,1903.791504 1471.996338,1902.472168 
	C1467.812988,1900.766968 1464.219604,1899.657715 1459.675171,1900.851562 
	C1458.599121,1900.786133 1458.171143,1900.685791 1457.187988,1900.228760 
	C1454.987183,1897.942993 1453.335449,1897.274902 1451.208130,1900.342041 
	C1449.722900,1901.746948 1448.556274,1902.426758 1446.630859,1903.137207 
	C1440.492310,1904.099487 1439.811768,1898.492310 1436.012939,1896.025635 
	C1433.407104,1895.512817 1431.433960,1895.899414 1428.869385,1896.479004 
	C1426.091431,1896.778687 1424.335205,1897.929077 1421.970825,1899.376099 
	C1420.508545,1900.032104 1419.623535,1900.295166 1418.008301,1900.574829 
	C1415.392578,1900.829712 1413.541138,1900.554321 1411.142334,1899.680908 
	C1409.576904,1898.742432 1408.859131,1897.858276 1408.144531,1896.452148 
	C1407.942627,1896.103760 1407.334473,1895.762085 1407.053223,1896.056763 
	C1405.596069,1898.757446 1404.903076,1901.403931 1402.684937,1903.965454 
	C1399.262329,1904.868774 1398.180786,1902.685547 1396.970825,1900.748535 
	C1395.221924,1897.948364 1393.007446,1897.604492 1389.672119,1899.643311 
	C1386.167114,1900.867310 1383.241211,1900.957886 1379.560059,1900.656250 
	C1375.077148,1899.841187 1371.717163,1897.974976 1367.463623,1896.644775 
	C1363.337036,1895.028687 1360.194092,1896.579834 1356.337646,1897.683105 
	C1347.100342,1897.389893 1350.291626,1888.630615 1346.298340,1884.162964 
	C1345.290161,1874.967285 1354.542969,1875.237915 1358.222290,1869.670776 
	C1360.564697,1867.494263 1362.601562,1866.147949 1365.465820,1864.768799 
	C1376.125488,1862.617432 1374.527344,1872.429932 1378.824585,1877.938721 
	C1376.120361,1869.237061 1378.435303,1865.667480 1386.147461,1865.348267 
	C1390.797119,1865.155884 1395.317139,1865.934326 1399.376343,1869.917114 
z"
        />
        <path
          fill="#7B7F84"
          opacity="1.000000"
          stroke="none"
          d="
M1506.740479,1863.842163 
	C1507.089233,1858.224365 1511.792480,1860.673096 1514.950317,1859.805176 
	C1516.928101,1859.261475 1519.040405,1858.693237 1518.805176,1856.148804 
	C1518.599609,1853.925781 1516.480713,1854.239502 1514.908447,1854.008545 
	C1513.272827,1853.768066 1511.556885,1853.780396 1510.027466,1853.247314 
	C1509.345215,1853.009644 1508.634277,1851.691772 1508.666016,1850.893188 
	C1508.697876,1850.093628 1509.468262,1848.974121 1510.213623,1848.615601 
	C1512.820435,1847.361450 1516.164307,1848.836914 1519.111450,1846.340576 
	C1517.061035,1842.928223 1513.655518,1844.457520 1511.120972,1843.714355 
	C1509.692261,1843.295410 1508.653442,1842.494873 1508.618164,1841.053955 
	C1508.583496,1839.631592 1509.470581,1838.607422 1510.972534,1838.326294 
	C1511.624268,1838.204468 1512.291748,1838.168091 1512.944824,1838.051758 
	C1515.361572,1837.621094 1518.838501,1838.937012 1519.375732,1835.102539 
	C1519.862915,1831.625244 1515.052124,1832.415649 1514.121826,1829.019531 
	C1514.252197,1824.921753 1514.755249,1821.563843 1517.810547,1818.445679 
	C1520.296143,1818.320679 1522.200806,1818.697266 1524.121216,1818.956909 
	C1529.701050,1819.711304 1535.174194,1820.276367 1532.426636,1828.544922 
	C1531.718384,1830.676147 1534.225952,1831.642578 1536.989990,1832.107666 
	C1542.172729,1838.812378 1541.250122,1845.743652 1538.507568,1853.417480 
	C1532.995239,1865.217651 1523.189331,1869.100220 1512.116699,1864.119385 
	C1510.402588,1863.348389 1508.951538,1863.704468 1506.740479,1863.842163 
z"
        />
        <path
          fill="#8C8D89"
          opacity="1.000000"
          stroke="none"
          d="
M1570.167236,1897.868896 
	C1570.281372,1892.282349 1568.442749,1889.306763 1562.611938,1889.683228 
	C1556.331177,1890.088623 1550.025757,1890.285767 1543.732300,1890.261353 
	C1538.745117,1890.242065 1538.272095,1889.116943 1541.109619,1883.566528 
	C1543.361938,1883.507812 1544.903320,1883.755737 1547.227051,1883.993286 
	C1553.588989,1884.015259 1559.170654,1883.961548 1564.747681,1884.099976 
	C1573.379150,1884.314087 1574.373535,1885.647095 1572.587524,1894.225830 
	C1572.387817,1895.185303 1572.016724,1896.109253 1571.448486,1897.819458 
	C1571.171875,1898.589233 1570.378296,1898.246216 1570.167236,1897.868896 
z"
        />
        <path
          fill="#D7D5CF"
          opacity="1.000000"
          stroke="none"
          d="
M1538.737305,1854.178467 
	C1538.165527,1847.303223 1536.339355,1840.539673 1537.803711,1832.990723 
	C1537.807251,1830.665649 1537.901733,1829.108398 1537.992920,1826.782227 
	C1539.480103,1826.015869 1540.970947,1826.018555 1543.206787,1826.024048 
	C1543.888306,1834.716187 1543.824585,1843.405640 1543.836548,1853.017822 
	C1542.433105,1854.053711 1540.954102,1854.166870 1538.737305,1854.178467 
z"
        />
        <path
          fill="#C4BDAE"
          opacity="1.000000"
          stroke="none"
          d="
M1578.311035,1873.205566 
	C1577.242920,1873.702637 1576.527222,1873.639404 1575.276978,1873.535400 
	C1574.042480,1870.657471 1571.228638,1868.724365 1571.277954,1865.011353 
	C1574.373657,1863.735840 1577.818604,1861.386841 1580.695557,1864.846436 
	C1582.931885,1867.535767 1580.561279,1870.329590 1578.311035,1873.205566 
z"
        />
        <path
          fill="#7B7F84"
          opacity="1.000000"
          stroke="none"
          d="
M1539.057373,1859.083008 
	C1538.952881,1859.144531 1539.161865,1859.021362 1539.057373,1859.083008 
z"
        />
        <path
          fill="#E7E3DA"
          opacity="1.000000"
          stroke="none"
          d="
M1651.182373,1890.367188 
	C1656.726685,1890.682495 1661.427368,1891.223267 1666.847168,1892.017578 
	C1668.406860,1892.766113 1669.015503,1893.456421 1669.435303,1895.078003 
	C1668.346191,1899.944824 1668.347046,1903.539551 1673.269165,1905.498901 
	C1675.846436,1906.524902 1677.571167,1908.558960 1677.394165,1912.330811 
	C1673.143066,1920.132568 1666.424072,1922.563110 1659.546631,1919.449097 
	C1650.681763,1915.434937 1638.937988,1917.973877 1632.742676,1907.865234 
	C1631.859741,1906.424561 1630.978394,1905.117554 1631.745728,1902.807129 
	C1632.271729,1902.256470 1632.022095,1902.055664 1632.123291,1902.179932 
	C1633.148193,1901.395508 1633.865967,1900.345703 1634.643188,1898.571289 
	C1633.545410,1892.987061 1635.409058,1890.573853 1640.634766,1890.679932 
	C1643.867432,1890.745728 1647.103882,1890.628174 1651.182373,1890.367188 
z"
        />
        <path
          fill="#EEE9DC"
          opacity="1.000000"
          stroke="none"
          d="
M1569.159424,1897.331543 
	C1569.768311,1898.034912 1569.944214,1898.406250 1570.827881,1898.597534 
	C1571.171875,1898.589233 1571.554443,1899.374878 1571.732422,1899.773926 
	C1575.477417,1906.302979 1572.343384,1909.399048 1566.649780,1910.273071 
	C1564.277100,1910.637329 1564.301392,1911.160889 1564.464722,1913.529541 
	C1557.145020,1921.040527 1548.228394,1917.891846 1540.101074,1918.217773 
	C1536.461060,1918.363770 1532.348999,1917.276489 1528.090576,1915.014404 
	C1525.585205,1911.884277 1525.440796,1909.149536 1527.427979,1905.676636 
	C1533.402100,1905.324219 1538.399292,1904.507568 1542.745483,1900.919434 
	C1547.139893,1897.291748 1552.626953,1897.518066 1557.770020,1897.879028 
	C1561.419678,1898.135010 1564.871094,1898.037842 1569.159424,1897.331543 
z"
        />
        <path
          fill="#73828A"
          opacity="1.000000"
          stroke="none"
          d="
M1631.497925,1902.109253 
	C1633.632324,1906.046387 1638.812622,1906.700317 1639.452393,1911.310791 
	C1639.690186,1913.024048 1641.956909,1913.642700 1643.412354,1912.170776 
	C1646.335571,1909.214355 1648.753662,1910.887207 1651.275757,1912.625366 
	C1660.699219,1919.119873 1666.005981,1919.144653 1675.813599,1912.280273 
	C1676.868042,1911.731079 1677.354858,1911.638306 1678.430420,1912.003418 
	C1681.196777,1915.400635 1680.581909,1919.188110 1682.202026,1923.092285 
	C1682.563110,1924.850464 1682.655273,1925.875854 1682.657104,1927.697388 
	C1682.560181,1930.101807 1682.165283,1931.632935 1680.833740,1933.649292 
	C1679.772461,1934.328369 1679.246338,1934.409180 1677.984375,1934.191650 
	C1668.622559,1923.233154 1655.633179,1924.114746 1644.022827,1921.459717 
	C1639.921875,1920.521851 1635.426270,1921.309082 1631.185791,1918.040649 
	C1629.706909,1912.519165 1621.334106,1912.359375 1623.449097,1905.113647 
	C1625.500244,1901.853149 1628.135864,1901.970459 1631.497925,1902.109253 
z"
        />
        <path
          fill="#BAB4A7"
          opacity="1.000000"
          stroke="none"
          d="
M1564.713623,1912.559814 
	C1563.386108,1911.461548 1562.469604,1911.036621 1561.814209,1910.359131 
	C1560.350708,1908.846436 1557.429565,1907.324585 1559.560913,1904.952148 
	C1561.920532,1902.325562 1563.094482,1905.381958 1564.722168,1906.816895 
	C1568.378906,1910.040894 1569.305176,1909.302979 1571.855347,1900.865967 
	C1581.878418,1901.684814 1591.845825,1902.194580 1601.652344,1899.305420 
	C1605.075684,1898.296753 1606.395996,1899.666504 1607.317993,1902.665894 
	C1608.756836,1907.346802 1611.563843,1905.288086 1614.859497,1903.708130 
	C1619.052612,1908.692383 1613.676392,1911.833618 1612.301025,1916.469971 
	C1610.473022,1917.684692 1609.075806,1917.865234 1606.935913,1917.382080 
	C1602.571289,1913.386108 1600.609131,1907.741821 1593.316772,1908.503540 
	C1587.807251,1909.078735 1583.880249,1910.325928 1581.925415,1916.510376 
	C1575.591431,1917.217773 1570.389160,1915.286743 1564.713623,1912.559814 
z"
        />
        <path
          fill="#EFF1EA"
          opacity="1.000000"
          stroke="none"
          d="
M1612.656738,1915.851074 
	C1607.564819,1909.690430 1614.090698,1908.128662 1615.598633,1904.256104 
	C1618.027344,1901.349609 1620.421753,1901.877808 1623.344727,1904.164307 
	C1623.914185,1907.299561 1624.681519,1909.550293 1627.910034,1909.836548 
	C1630.557007,1910.070923 1631.914673,1911.349976 1631.743286,1914.780029 
	C1628.643433,1919.532715 1624.210938,1919.232544 1618.951172,1919.094971 
	C1616.179321,1918.601685 1614.404907,1918.070923 1612.656738,1915.851074 
z"
        />
        <path
          fill="#73828A"
          opacity="1.000000"
          stroke="none"
          d="
M1634.253174,1898.254883 
	C1635.998779,1900.225220 1638.084229,1902.818115 1632.688599,1902.606934 
	C1631.996460,1900.794434 1632.459717,1899.535889 1634.253174,1898.254883 
z"
        />
        <path
          fill="#C7C0B1"
          opacity="1.000000"
          stroke="none"
          d="
M1712.654541,1880.614014 
	C1711.292603,1879.279663 1710.567505,1879.327393 1709.929443,1880.306763 
	C1708.600098,1882.347656 1707.311279,1884.414673 1705.808594,1887.061279 
	C1704.487427,1889.109009 1702.984497,1889.553833 1700.444824,1888.989502 
	C1699.855591,1881.605347 1699.837158,1881.685425 1692.353882,1879.966064 
	C1689.781250,1879.375000 1687.564697,1877.946289 1685.916504,1874.885620 
	C1684.698853,1872.084595 1682.406494,1872.109863 1680.124878,1870.994019 
	C1678.768555,1870.697998 1677.978027,1870.607056 1676.601929,1870.646729 
	C1675.723999,1870.295654 1675.431519,1869.814209 1675.106567,1868.487793 
	C1681.237305,1861.341064 1689.227173,1864.124268 1696.152710,1864.450073 
	C1701.866455,1864.718994 1707.692261,1865.211792 1714.110107,1866.061646 
	C1717.061890,1870.369141 1716.450317,1874.391113 1715.794434,1879.048340 
	C1715.175781,1880.536255 1714.356079,1880.959229 1712.654541,1880.614014 
z"
        />
        <path
          fill="#8C8D89"
          opacity="1.000000"
          stroke="none"
          d="
M1675.313599,1867.815674 
	C1675.906250,1868.792114 1675.926514,1869.187866 1675.981567,1870.180420 
	C1675.458862,1873.819824 1672.029053,1875.041992 1671.529297,1878.351440 
	C1671.159058,1880.803711 1668.897583,1882.143188 1666.200562,1881.693848 
	C1663.525757,1881.248413 1661.983154,1879.347656 1662.044922,1876.825684 
	C1662.241821,1868.774902 1657.584717,1864.043945 1650.644409,1860.122192 
	C1647.801147,1858.308228 1644.618164,1859.086304 1642.310059,1856.350098 
	C1641.985107,1855.033936 1642.005127,1854.296875 1642.390869,1852.416382 
	C1645.000000,1850.006714 1647.646484,1850.235962 1650.209595,1849.279785 
	C1651.292236,1849.058228 1649.444336,1847.747803 1650.074585,1848.922729 
	C1650.232056,1849.216309 1650.005371,1849.425537 1649.388428,1849.496582 
	C1646.638184,1849.814453 1643.875977,1851.075317 1640.796143,1848.744263 
	C1639.730957,1845.162598 1640.411499,1842.688477 1643.567383,1840.339355 
	C1649.132324,1838.977051 1653.900391,1838.289551 1659.104492,1841.559326 
	C1659.785889,1842.166870 1660.037598,1842.146851 1660.110596,1842.042969 
	C1660.130615,1846.806885 1661.594238,1851.693848 1660.002808,1857.268311 
	C1662.092407,1862.345093 1666.686768,1864.924805 1668.323120,1869.644287 
	C1668.393799,1869.848267 1670.079834,1869.492432 1671.571045,1868.988403 
	C1672.657715,1867.467041 1673.528564,1867.018921 1675.313599,1867.815674 
z"
        />
        <path
          fill="#E8E1D2"
          opacity="1.000000"
          stroke="none"
          d="
M1680.871338,1928.215332 
	C1680.317261,1926.830933 1680.230713,1926.016235 1680.065674,1924.591797 
	C1681.136963,1921.097778 1679.703003,1917.872070 1681.353027,1914.390625 
	C1681.683838,1913.334473 1681.832397,1912.917236 1682.232422,1911.886475 
	C1682.836670,1910.467529 1683.193359,1909.663574 1683.786133,1908.247314 
	C1685.738525,1904.228394 1685.408325,1901.031738 1682.811523,1897.380859 
	C1682.374634,1895.238403 1682.627930,1893.883301 1684.410767,1892.760620 
	C1689.923950,1892.945435 1694.576538,1892.670776 1698.975830,1888.716309 
	C1700.552979,1889.072388 1701.173706,1889.763550 1701.734375,1891.291382 
	C1701.741211,1893.979248 1701.660522,1895.965820 1701.502197,1898.694336 
	C1695.422729,1909.226196 1693.331421,1921.275146 1683.460693,1929.272705 
	C1682.262207,1929.121460 1681.800537,1928.953247 1680.871338,1928.215332 
z"
        />
        <path
          fill="#B6AFA3"
          opacity="1.000000"
          stroke="none"
          d="
M1641.080566,1855.820312 
	C1644.603882,1856.494751 1648.111694,1854.821899 1649.813721,1859.264893 
	C1641.857300,1863.669189 1633.511108,1862.269897 1624.489258,1860.637695 
	C1624.317017,1857.016113 1626.292114,1855.806396 1629.350464,1855.859619 
	C1632.964600,1855.922607 1636.580688,1855.871094 1641.080566,1855.820312 
z"
        />
        <path
          fill="#6F7981"
          opacity="1.000000"
          stroke="none"
          d="
M1684.157471,1892.566162 
	C1684.868042,1894.045898 1684.598633,1895.322510 1684.252441,1897.255981 
	C1679.264160,1901.770752 1674.564331,1899.592285 1669.287720,1896.783203 
	C1668.448486,1895.294678 1668.185913,1894.433960 1667.744873,1892.922119 
	C1672.770020,1892.301636 1677.973633,1892.332275 1684.157471,1892.566162 
z"
        />
        <path
          fill="#8C8D89"
          opacity="1.000000"
          stroke="none"
          d="
M1679.793213,1871.374146 
	C1681.912720,1870.463257 1684.581299,1869.168701 1685.649414,1873.510986 
	C1683.550171,1874.025879 1681.318115,1874.146606 1679.793213,1871.374146 
z"
        />
        <path
          fill="#7B7F84"
          opacity="1.000000"
          stroke="none"
          d="
M1702.494141,1891.906250 
	C1701.278198,1891.275513 1700.734497,1890.558350 1699.976318,1889.189453 
	C1699.761841,1888.537720 1699.775269,1888.649902 1699.723389,1888.666504 
	C1701.152710,1888.398682 1702.634033,1888.114258 1704.863403,1887.740723 
	C1705.862549,1889.666260 1705.303223,1891.205566 1702.494141,1891.906250 
z"
        />
        <path
          fill="#EBE4D0"
          opacity="1.000000"
          stroke="none"
          d="
M1644.206421,1841.164795 
	C1642.225708,1843.517944 1642.337891,1845.539307 1643.383789,1848.180786 
	C1643.542358,1849.364990 1643.432007,1849.982910 1642.649414,1851.260254 
	C1641.977295,1851.919678 1641.948975,1852.211792 1642.032715,1852.334473 
	C1633.563477,1851.300415 1633.505005,1851.300293 1633.524536,1843.218262 
	C1633.540283,1836.674927 1633.767334,1830.132080 1633.806885,1823.588501 
	C1633.829468,1819.851440 1635.018433,1817.190552 1639.984619,1816.620483 
	C1641.210449,1816.198975 1641.646362,1815.996582 1642.623779,1816.272705 
	C1644.993652,1819.689209 1645.069702,1823.148193 1646.184326,1827.055908 
	C1646.514526,1828.728638 1646.629150,1829.698853 1646.731689,1831.415527 
	C1646.871094,1833.653320 1646.820312,1835.145874 1646.469360,1837.376465 
	C1646.014404,1839.096924 1645.509644,1839.961182 1644.206421,1841.164795 
z"
        />
        <path
          fill="#98999E"
          opacity="1.000000"
          stroke="none"
          d="
M1641.590088,1816.434082 
	C1640.880127,1816.118530 1640.866699,1816.278931 1640.820557,1816.340088 
	C1640.635498,1816.043457 1640.496704,1815.685547 1640.265381,1814.761719 
	C1640.023926,1811.103271 1639.811646,1807.999878 1642.078125,1804.577148 
	C1643.224243,1803.906738 1644.008179,1803.921509 1645.382935,1803.962891 
	C1646.960327,1804.964722 1647.928467,1805.957520 1649.316772,1807.507690 
	C1652.580566,1807.994385 1655.382080,1807.964233 1659.020630,1807.959473 
	C1659.857788,1807.984741 1660.834229,1808.034668 1661.315430,1808.111694 
	C1661.675415,1809.710205 1661.554443,1811.231689 1661.205566,1813.483032 
	C1655.425415,1820.017578 1648.865356,1818.403076 1641.590088,1816.434082 
z"
        />
        <path
          fill="#6B6164"
          opacity="1.000000"
          stroke="none"
          d="
M1562.902588,1760.462646 
	C1564.359619,1774.984497 1563.237183,1789.599731 1562.149658,1805.102661 
	C1560.335693,1804.687378 1559.974243,1802.772705 1559.980591,1800.716553 
	C1560.017822,1788.748901 1560.055908,1776.781372 1560.108521,1764.813721 
	C1560.116089,1763.104004 1560.453125,1761.516479 1562.902588,1760.462646 
z"
        />
        <path
          fill="#6B6164"
          opacity="1.000000"
          stroke="none"
          d="
M1561.488281,1816.016724 
	C1562.077515,1816.835815 1562.074707,1817.754761 1562.064697,1819.363281 
	C1559.565430,1819.361572 1556.731201,1818.771606 1561.488281,1816.016724 
z"
        />
        <path
          fill="#E7E3DA"
          opacity="1.000000"
          stroke="none"
          d="
M1517.521973,1817.971069 
	C1517.862549,1821.394775 1521.734741,1826.064941 1514.744507,1828.083862 
	C1510.204956,1827.386719 1505.137085,1828.124878 1505.525635,1821.046387 
	C1508.683228,1816.928345 1512.816528,1818.130981 1517.521973,1817.971069 
z"
        />
        <path
          fill="#D7D5CF"
          opacity="1.000000"
          stroke="none"
          d="
M1538.006104,1825.995483 
	C1537.798340,1825.731079 1537.584595,1825.471313 1537.370850,1825.211426 
	C1537.521240,1825.307251 1537.671509,1825.402954 1537.905640,1825.755859 
	C1537.989380,1826.013184 1538.000000,1826.000000 1538.006104,1825.995483 
z"
        />
        <path
          fill="#E59F70"
          opacity="1.000000"
          stroke="none"
          d="
M1681.590210,1929.007568 
	C1684.825317,1927.960083 1685.598511,1925.873657 1686.598267,1923.665894 
	C1689.326050,1917.641724 1692.353882,1911.754028 1695.211548,1905.787720 
	C1696.362183,1903.385376 1697.382568,1900.922241 1700.766113,1899.636230 
	C1702.331055,1904.334229 1703.237671,1909.232300 1704.393188,1914.890381 
	C1708.083130,1920.130981 1709.612671,1925.025391 1707.489624,1931.422729 
	C1702.354736,1938.109619 1695.205933,1936.317993 1688.219238,1937.984375 
	C1681.468872,1938.157104 1680.418457,1934.626099 1681.590210,1929.007568 
z"
        />
        <path
          fill="#9FA2A1"
          opacity="1.000000"
          stroke="none"
          d="
M1777.047852,1911.362305 
	C1777.661987,1913.347412 1777.756104,1914.987061 1777.697144,1917.437012 
	C1771.394287,1922.194092 1764.751831,1919.221313 1758.364624,1919.454468 
	C1750.200684,1919.752319 1741.997314,1919.894043 1732.892334,1919.148438 
	C1727.845093,1918.300537 1722.591431,1919.876953 1721.550537,1912.826416 
	C1724.561401,1910.286621 1727.444458,1909.755005 1731.251953,1909.987061 
	C1734.382202,1912.480347 1737.510498,1911.957153 1740.441162,1911.967529 
	C1751.074463,1912.005127 1761.708130,1911.940674 1773.288574,1911.813721 
	C1774.905151,1911.169678 1775.669312,1910.937744 1777.047852,1911.362305 
z"
        />
        <path
          fill="#7B7F84"
          opacity="1.000000"
          stroke="none"
          d="
M1721.200684,1912.055664 
	C1722.847168,1917.215210 1727.157349,1915.818359 1731.286377,1916.401367 
	C1733.695435,1922.181152 1729.027222,1922.526489 1725.862427,1922.731689 
	C1719.351562,1923.153687 1715.814087,1926.952881 1712.288452,1932.520996 
	C1709.977173,1933.593506 1708.379150,1933.474854 1706.444946,1931.878418 
	C1705.488647,1926.498413 1704.926270,1921.865356 1704.502930,1916.441406 
	C1710.407349,1916.714600 1715.700562,1915.640625 1721.200684,1912.055664 
z"
        />
        <path
          fill="#EBE4D0"
          opacity="1.000000"
          stroke="none"
          d="
M1676.269043,1867.845459 
	C1675.050781,1868.431641 1674.215576,1868.466919 1672.754517,1868.540771 
	C1671.244507,1866.890381 1670.360352,1865.201538 1669.035645,1862.671387 
	C1680.203979,1862.671387 1690.744141,1862.295288 1701.232544,1862.868408 
	C1705.084106,1863.078979 1708.944336,1863.929077 1713.798828,1863.478271 
	C1714.857788,1863.880493 1714.989380,1864.425781 1714.932373,1865.792236 
	C1702.076660,1868.535767 1689.299805,1864.304932 1676.269043,1867.845459 
z"
        />
        <path
          fill="#626E74"
          opacity="1.000000"
          stroke="none"
          d="
M1711.985352,1880.940918 
	C1712.830444,1880.106812 1713.665405,1879.936157 1715.136475,1879.723633 
	C1715.824585,1881.535767 1716.649292,1884.003052 1714.392944,1884.732178 
	C1712.078613,1885.479858 1712.305908,1883.057861 1711.985352,1880.940918 
z"
        />
        <path
          fill="#C7C0B1"
          opacity="1.000000"
          stroke="none"
          d="
M1668.352905,1781.816284 
	C1676.680298,1781.369141 1684.315552,1780.567017 1692.002930,1782.279053 
	C1691.360352,1784.899780 1689.585938,1785.526611 1688.230713,1785.627075 
	C1681.325073,1786.139648 1674.397949,1786.376587 1667.475586,1786.634766 
	C1665.932739,1786.692261 1664.993652,1785.595581 1664.942993,1784.183594 
	C1664.883667,1782.533813 1666.328491,1782.178223 1668.352905,1781.816284 
z"
        />
        <path
          fill="#C4BDAE"
          opacity="1.000000"
          stroke="none"
          d="
M1707.479248,1791.294312 
	C1708.083618,1792.214111 1708.078003,1792.620361 1708.094116,1793.637695 
	C1701.193848,1797.052979 1694.468750,1797.087524 1687.175293,1792.996948 
	C1693.914673,1788.373901 1700.452026,1790.132812 1707.479248,1791.294312 
z"
        />
        <path
          fill="#C4BDAE"
          opacity="1.000000"
          stroke="none"
          d="
M1688.596191,1775.753174 
	C1686.289917,1776.420898 1684.376587,1776.416504 1682.537354,1776.783691 
	C1680.482422,1777.193848 1678.414185,1777.602783 1678.435669,1774.697510 
	C1678.455566,1772.038574 1680.810547,1771.840454 1682.690308,1772.110474 
	C1685.056885,1772.450439 1689.011475,1770.044556 1688.596191,1775.753174 
z"
        />
        <path
          fill="#C7C0B1"
          opacity="1.000000"
          stroke="none"
          d="
M1711.610352,1782.765625 
	C1713.028442,1783.102661 1712.300659,1781.607544 1712.507080,1782.911011 
	C1712.605591,1783.532715 1711.948364,1783.594727 1711.610352,1782.765625 
z"
        />
        <path
          fill="#C7C0B1"
          opacity="1.000000"
          stroke="none"
          d="
M1690.539917,1853.068359 
	C1688.468994,1850.657593 1685.521973,1851.767700 1683.892090,1849.963379 
	C1691.246704,1846.828735 1697.598755,1846.607544 1705.380371,1849.522827 
	C1701.043701,1853.636597 1696.403564,1854.543579 1690.539917,1853.068359 
z"
        />
        <path
          fill="#C7C0B1"
          opacity="1.000000"
          stroke="none"
          d="
M1677.844116,1851.157349 
	C1673.220215,1851.984741 1669.227905,1852.177246 1665.504517,1850.143799 
	C1669.947876,1848.693726 1674.460083,1845.405762 1677.844116,1851.157349 
z"
        />
        <path
          fill="#C7C0B1"
          opacity="1.000000"
          stroke="none"
          d="
M1699.119873,1840.131714 
	C1698.383911,1841.466064 1697.640503,1841.463501 1696.984619,1840.526123 
	C1696.846802,1840.329224 1696.852905,1839.862549 1696.995117,1839.667725 
	C1697.678711,1838.730103 1698.418213,1838.786621 1699.119873,1840.131714 
z"
        />
        <path
          fill="#C7C0B1"
          opacity="1.000000"
          stroke="none"
          d="
M1703.559570,1840.717285 
	C1704.015625,1840.747314 1704.263550,1840.996948 1704.511353,1841.246582 
	C1704.124756,1841.143311 1703.738037,1841.040039 1703.559570,1840.717285 
z"
        />
        <path
          fill="#C7C0B1"
          opacity="1.000000"
          stroke="none"
          d="
M1711.092773,1840.939453 
	C1711.153687,1841.043945 1711.031982,1840.835083 1711.092773,1840.939453 
z"
        />
        <path
          fill="#947C7A"
          opacity="1.000000"
          stroke="none"
          d="
M1641.006836,1816.895752 
	C1647.068237,1814.540894 1653.447388,1814.993408 1660.350342,1814.278442 
	C1661.190674,1815.081787 1661.403687,1815.950562 1661.705811,1817.486572 
	C1663.143311,1819.399414 1663.394165,1820.915039 1662.385254,1823.262085 
	C1661.640991,1824.751831 1661.402832,1825.595215 1660.888184,1827.036621 
	C1660.611694,1827.634521 1660.220459,1827.617798 1660.047485,1827.709595 
	C1655.417725,1831.737427 1650.544922,1831.502563 1644.695557,1828.659424 
	C1642.992188,1824.611328 1642.062866,1821.142212 1641.006836,1816.895752 
z"
        />
        <path
          fill="#C7C0B1"
          opacity="1.000000"
          stroke="none"
          d="
M1662.825195,1823.933838 
	C1661.816772,1822.468506 1661.754761,1821.028687 1661.743774,1818.871216 
	C1673.952637,1817.848633 1685.774780,1821.700439 1698.080078,1820.702637 
	C1693.211914,1825.994507 1687.096558,1823.385986 1681.610352,1823.846558 
	C1675.702637,1824.342651 1669.720947,1823.959106 1662.825195,1823.933838 
z"
        />
        <path
          fill="#C7C0B1"
          opacity="1.000000"
          stroke="none"
          d="
M1691.702637,1810.621094 
	C1691.482300,1815.658081 1687.272827,1815.146606 1684.207642,1815.621094 
	C1679.651123,1816.326416 1675.483032,1814.636108 1669.929810,1810.865723 
	C1678.298828,1809.530518 1684.660645,1809.689209 1691.702637,1810.621094 
z"
        />
        <path
          fill="#83837F"
          opacity="1.000000"
          stroke="none"
          d="
M1644.969116,1838.400635 
	C1644.064941,1836.335327 1643.876953,1834.859253 1643.798096,1832.643555 
	C1648.581055,1829.035034 1653.514648,1829.776123 1659.275024,1831.348389 
	C1660.012085,1833.249146 1659.946655,1834.656006 1659.719727,1836.751953 
	C1655.325073,1841.460205 1650.484863,1840.043213 1644.969116,1838.400635 
z"
        />
        <path
          fill="#B6AFA3"
          opacity="1.000000"
          stroke="none"
          d="
M1660.051270,1831.169678 
	C1655.286987,1831.854858 1650.496338,1831.867554 1644.806641,1831.892090 
	C1643.882202,1831.053833 1643.857056,1830.203613 1643.876587,1828.717041 
	C1648.656494,1828.052490 1653.391602,1828.024292 1659.000610,1827.898682 
	C1659.924683,1828.699951 1659.974976,1829.598633 1660.051270,1831.169678 
z"
        />
        <path
          fill="#B6AFA3"
          opacity="1.000000"
          stroke="none"
          d="
M1644.102539,1838.439819 
	C1648.800537,1837.785156 1653.348022,1837.758789 1658.726807,1837.586670 
	C1659.655151,1838.489868 1659.752319,1839.538940 1659.817627,1841.377441 
	C1655.001221,1842.059937 1650.216553,1841.952881 1644.516357,1841.731445 
	C1643.717896,1840.767456 1643.834961,1839.917847 1644.102539,1838.439819 
z"
        />
        <path
          fill="#E1D9C6"
          opacity="1.000000"
          stroke="none"
          d="
M1367.050049,1865.638184 
	C1365.018311,1867.559937 1363.794434,1869.509644 1360.689209,1869.630127 
	C1355.595947,1868.762451 1352.492065,1865.470459 1349.399414,1862.670898 
	C1345.760864,1859.376953 1342.151489,1858.375732 1336.955566,1859.515869 
	C1329.382935,1857.029541 1322.143921,1858.633057 1314.498047,1857.401855 
	C1313.299316,1856.823608 1312.419922,1856.410400 1312.420654,1855.925415 
	C1312.432983,1847.888916 1306.802856,1848.083252 1301.214111,1848.082520 
	C1299.250122,1848.082275 1297.305908,1847.756958 1295.183838,1846.036133 
	C1292.400391,1831.545776 1292.400391,1831.543579 1276.669556,1831.560425 
	C1275.342773,1831.561890 1274.071533,1831.328735 1272.273438,1830.343750 
	C1270.484375,1827.867920 1270.988403,1826.018433 1272.714966,1823.699951 
	C1276.436523,1820.982300 1280.137451,1821.201294 1283.759888,1820.973877 
	C1287.000854,1820.770386 1290.392822,1821.406128 1293.976807,1819.030884 
	C1291.373291,1815.905640 1287.082520,1817.297729 1283.945068,1814.650879 
	C1283.025024,1813.200195 1282.940918,1812.228149 1283.539795,1810.606079 
	C1290.405151,1806.389160 1297.455200,1809.013672 1305.044922,1807.938599 
	C1315.878540,1808.080566 1325.767822,1806.466064 1335.690552,1806.982544 
	C1340.554199,1807.235840 1346.165283,1807.238525 1345.707275,1815.278564 
	C1342.976440,1819.985962 1344.373291,1821.486694 1348.720703,1820.721680 
	C1352.531860,1820.050903 1356.569824,1817.960449 1359.488037,1823.541016 
	C1365.155151,1824.177124 1370.377197,1824.421021 1375.599976,1824.443604 
	C1380.093506,1824.463013 1382.298218,1826.569458 1382.097046,1831.029785 
	C1381.728638,1839.198242 1383.858154,1847.297729 1382.639404,1856.305176 
	C1381.525391,1862.580688 1377.485107,1863.996948 1373.112427,1862.681274 
	C1369.824341,1861.691895 1369.243530,1864.098877 1367.050049,1865.638184 
z"
        />
        <path
          fill="#808489"
          opacity="1.000000"
          stroke="none"
          d="
M1274.430176,1823.356445 
	C1273.152832,1825.399780 1272.372803,1826.874512 1272.813232,1829.232422 
	C1272.889893,1830.235352 1272.797852,1830.640381 1272.462158,1831.623169 
	C1271.283813,1833.940308 1271.831543,1835.478516 1273.270752,1837.480957 
	C1279.691040,1843.717285 1288.720459,1840.257568 1295.552246,1845.487793 
	C1300.341431,1847.195679 1305.074097,1841.076172 1309.137451,1846.703247 
	C1309.165649,1846.742188 1310.214966,1846.153564 1310.685547,1845.741699 
	C1311.916504,1844.663452 1312.410645,1841.466675 1314.896362,1844.039673 
	C1316.180542,1845.368896 1315.644897,1847.185791 1314.539795,1848.509888 
	C1312.929810,1850.438721 1312.096558,1852.358521 1313.837891,1855.291504 
	C1310.012939,1863.518311 1303.442261,1859.367554 1298.919922,1857.858032 
	C1292.693115,1855.779541 1287.183228,1855.188721 1280.793335,1856.574707 
	C1274.692261,1857.897705 1268.225098,1857.787964 1262.536011,1854.151001 
	C1256.685425,1850.410767 1251.501953,1850.283569 1247.050781,1856.442871 
	C1244.934692,1859.371094 1241.700806,1860.138062 1237.467041,1859.917480 
	C1235.766235,1859.831421 1234.827393,1859.608276 1233.236084,1859.075439 
	C1229.811523,1857.782227 1229.263306,1854.464844 1226.131836,1852.831055 
	C1220.941040,1851.308594 1215.969727,1852.950439 1210.874878,1850.219604 
	C1209.038940,1848.997925 1208.540894,1847.813232 1208.471191,1845.789307 
	C1209.208374,1842.104858 1206.433472,1842.232666 1204.344360,1840.754883 
	C1202.422607,1838.503784 1202.311401,1836.606201 1203.905273,1834.150146 
	C1208.576416,1832.356567 1209.907104,1826.136963 1215.882568,1827.867432 
	C1218.564697,1828.644287 1220.640503,1828.109619 1220.421753,1832.246948 
	C1220.164062,1837.120972 1223.605469,1834.786499 1226.497314,1833.850342 
	C1228.556030,1835.097778 1230.258057,1836.035889 1233.157959,1835.839844 
	C1233.416870,1836.822266 1232.938843,1837.821533 1232.178711,1839.303711 
	C1231.877197,1843.706177 1235.904663,1841.247314 1237.678345,1843.607178 
	C1239.195190,1843.465942 1240.207886,1842.852661 1241.740967,1841.938721 
	C1243.752075,1841.912964 1245.219238,1841.989014 1247.037354,1840.811035 
	C1248.213013,1839.323853 1249.250732,1838.180176 1250.569458,1838.331787 
	C1257.328369,1839.108521 1259.911743,1836.479980 1259.461548,1829.509521 
	C1259.035400,1822.911743 1262.307739,1817.456055 1267.684448,1812.609863 
	C1268.135498,1811.075562 1268.262939,1810.107300 1268.570190,1808.427734 
	C1269.436279,1807.187622 1270.206421,1806.996216 1271.517822,1807.567505 
	C1272.059692,1808.372070 1272.144165,1808.751709 1272.371582,1809.697266 
	C1273.158569,1811.031616 1273.756714,1811.834595 1274.641479,1813.351562 
	C1276.276733,1816.956665 1276.686890,1819.878052 1274.430176,1823.356445 
z"
        />
        <path
          fill="#626E74"
          opacity="1.000000"
          stroke="none"
          d="
M1267.203735,1811.958984 
	C1269.538818,1815.339478 1271.129761,1818.629395 1269.101685,1822.271240 
	C1266.180420,1822.427734 1266.636597,1819.520264 1265.495850,1818.435669 
	C1264.504150,1818.038086 1264.256226,1818.389404 1264.059570,1819.296265 
	C1262.796997,1825.124146 1260.876221,1830.925659 1262.668213,1836.948608 
	C1264.050293,1841.593384 1261.401123,1842.207275 1257.950073,1841.837524 
	C1255.004517,1841.521851 1252.101196,1840.812744 1248.339844,1840.387817 
	C1245.671021,1835.728149 1245.464966,1830.626831 1246.400757,1825.856812 
	C1248.708374,1814.095215 1248.288452,1802.241089 1248.845215,1789.548950 
	C1253.907227,1787.713013 1256.720337,1790.047241 1255.186646,1794.530884 
	C1252.209229,1803.235352 1255.211792,1810.370361 1259.356934,1817.889893 
	C1260.671997,1812.315308 1259.433838,1807.052979 1259.837646,1801.844116 
	C1259.960938,1800.252441 1258.856445,1797.548584 1262.734619,1798.147461 
	C1264.365356,1802.959229 1260.899414,1808.490356 1267.203735,1811.958984 
z"
        />
        <path
          fill="#D3CBBF"
          opacity="1.000000"
          stroke="none"
          d="
M1342.012817,1817.003906 
	C1343.806641,1813.834106 1346.860474,1810.798462 1341.072754,1810.273071 
	C1334.822998,1809.705933 1328.245728,1809.246338 1322.231445,1810.602539 
	C1316.585938,1811.875610 1311.844971,1811.439209 1306.443726,1808.444824 
	C1305.620483,1801.037720 1310.577515,1802.077026 1315.630371,1801.963135 
	C1324.192749,1801.948730 1331.897217,1801.949219 1340.486816,1801.936401 
	C1341.790161,1801.549316 1342.208374,1801.175537 1343.072021,1801.515381 
	C1346.208252,1803.967773 1345.137573,1807.544189 1347.614990,1810.156250 
	C1348.954468,1811.478027 1349.483154,1812.445801 1349.679688,1814.437500 
	C1348.207886,1819.375366 1345.778931,1820.583618 1342.012817,1817.003906 
z"
        />
        <path
          fill="#D3CBBF"
          opacity="1.000000"
          stroke="none"
          d="
M1316.913086,1790.145020 
	C1318.272949,1791.379883 1318.782349,1792.520264 1319.795532,1794.069336 
	C1319.919189,1796.021240 1319.232910,1797.334473 1317.126465,1798.159424 
	C1314.213379,1795.767578 1311.418701,1796.253418 1308.367310,1796.854980 
	C1297.282227,1799.041260 1286.077393,1797.920410 1274.912476,1797.683594 
	C1274.491943,1797.674561 1274.089478,1796.829102 1273.089355,1795.719238 
	C1280.149414,1792.101074 1287.796143,1795.140503 1294.746094,1793.356812 
	C1301.737427,1791.562500 1308.655151,1789.199219 1316.913086,1790.145020 
z"
        />
        <path
          fill="#626E74"
          opacity="1.000000"
          stroke="none"
          d="
M1263.927490,1792.993164 
	C1263.672119,1793.572144 1263.225586,1793.333252 1263.233643,1793.110718 
	C1263.545898,1784.528442 1258.063354,1779.795410 1250.072998,1775.354492 
	C1255.641113,1772.840698 1260.206177,1774.365845 1265.339844,1773.728271 
	C1266.405273,1775.211792 1266.610474,1776.799927 1266.928833,1779.177856 
	C1263.549316,1783.416016 1263.885376,1787.834106 1263.927490,1792.993164 
z"
        />
        <path
          fill="#D3CBBF"
          opacity="1.000000"
          stroke="none"
          d="
M1273.169189,1814.317017 
	C1272.392090,1812.941895 1272.343750,1812.171143 1272.404907,1810.831787 
	C1275.781372,1810.154541 1279.048218,1810.046265 1283.142090,1809.960205 
	C1283.999390,1810.791748 1284.029663,1811.600952 1284.084717,1813.016846 
	C1281.119385,1817.311768 1277.602295,1816.853760 1273.169189,1814.317017 
z"
        />
        <path
          fill="#626E74"
          opacity="1.000000"
          stroke="none"
          d="
M1272.057129,1807.254150 
	C1271.254761,1807.950317 1270.534546,1807.908325 1269.282227,1807.791260 
	C1268.712036,1807.403931 1268.612549,1807.084839 1268.645630,1806.780151 
	C1268.862427,1804.783691 1267.397461,1801.411133 1270.204102,1801.276978 
	C1273.238281,1801.131958 1271.752808,1804.592651 1272.057129,1807.254150 
z"
        />
        <path
          fill="#E1D9C6"
          opacity="1.000000"
          stroke="none"
          d="
M1349.016846,1815.452637 
	C1348.284668,1814.068604 1348.205078,1813.100952 1348.112793,1811.410889 
	C1351.541870,1810.391724 1354.983765,1810.095093 1359.280518,1809.662598 
	C1360.408936,1809.985718 1360.682617,1810.444702 1360.827148,1811.694702 
	C1360.393433,1813.146484 1360.088867,1813.807251 1359.544189,1814.958008 
	C1356.257812,1819.369385 1352.960327,1817.543091 1349.016846,1815.452637 
z"
        />
        <path
          fill="#9FA2A1"
          opacity="1.000000"
          stroke="none"
          d="
M1361.493164,1812.391602 
	C1360.554932,1812.002930 1360.411987,1811.520264 1360.202148,1810.282227 
	C1363.691650,1806.373535 1368.082153,1808.745728 1372.728516,1807.563599 
	C1373.494019,1807.385620 1373.726074,1807.684326 1373.846558,1807.830078 
	C1374.779419,1808.821899 1375.066772,1809.819092 1374.278442,1811.575195 
	C1369.914429,1812.221069 1366.101440,1812.259399 1361.493164,1812.391602 
z"
        />
        <path
          fill="#B6AFA3"
          opacity="1.000000"
          stroke="none"
          d="
M1343.577637,1801.632690 
	C1343.160889,1802.179810 1342.804199,1802.130737 1341.909790,1802.002441 
	C1341.473877,1801.049805 1341.575684,1800.176636 1341.844971,1798.666260 
	C1342.554199,1799.031494 1343.096069,1800.034058 1343.577637,1801.632690 
z"
        />
        <path
          fill="#947C7A"
          opacity="1.000000"
          stroke="none"
          d="
M1649.835327,1807.385986 
	C1647.985962,1807.853027 1646.545288,1807.214478 1646.039917,1804.619995 
	C1646.870972,1803.996826 1647.768066,1804.004028 1649.330933,1804.100708 
	C1649.961548,1805.042603 1649.926514,1805.894897 1649.835327,1807.385986 
z"
        />
        <path
          fill="#5A605E"
          opacity="1.000000"
          stroke="none"
          d="
M1367.308105,1866.160278 
	C1369.071655,1862.707764 1367.403442,1858.130127 1369.339966,1854.020142 
	C1374.150269,1861.512451 1374.150269,1861.512451 1381.970459,1857.311768 
	C1385.751709,1857.891479 1387.636108,1856.922607 1387.970215,1853.375000 
	C1388.225098,1850.668579 1387.234375,1847.567017 1390.223755,1844.546021 
	C1394.275879,1851.003784 1389.335815,1858.839966 1394.814697,1865.265747 
	C1397.324219,1858.115479 1394.685913,1851.344849 1397.904419,1845.439697 
	C1401.117798,1852.931152 1399.425903,1860.740967 1399.973755,1869.301880 
	C1398.406250,1871.703491 1397.148193,1871.093994 1395.712769,1869.862549 
	C1391.964478,1866.647461 1387.468506,1868.194336 1383.260986,1868.262329 
	C1381.240112,1868.294922 1379.142456,1869.206421 1379.177124,1871.686646 
	C1379.209351,1873.981567 1381.360596,1873.675293 1382.916870,1873.935791 
	C1384.484741,1874.198364 1386.601074,1873.866333 1386.875732,1876.028687 
	C1387.195557,1878.544678 1385.169800,1879.371948 1383.149048,1879.792480 
	C1380.588867,1880.325195 1377.994629,1880.695312 1375.425659,1881.133789 
	C1372.129883,1876.326904 1376.643921,1867.965942 1367.308105,1866.160278 
z"
        />
        <path
          fill="#9FA2A1"
          opacity="1.000000"
          stroke="none"
          d="
M1348.128540,1815.557861 
	C1351.638794,1813.746216 1354.710815,1816.006714 1358.619995,1815.514893 
	C1359.504639,1817.736450 1359.705444,1820.024902 1359.919556,1823.171387 
	C1357.087769,1823.756836 1354.677979,1820.506714 1351.223633,1823.523804 
	C1349.886597,1824.691772 1346.791504,1824.050171 1344.518921,1823.901978 
	C1339.838989,1823.596924 1339.207520,1820.909668 1341.424316,1816.629028 
	C1343.996338,1818.458740 1345.962646,1818.517334 1348.128540,1815.557861 
z"
        />
        <path
          fill="#E7E3DA"
          opacity="1.000000"
          stroke="none"
          d="
M1374.407593,1812.388916 
	C1373.768677,1811.247192 1373.809692,1810.311401 1373.908936,1808.675781 
	C1375.627563,1806.526245 1377.288086,1805.076538 1379.526123,1803.285522 
	C1384.055176,1804.996460 1387.580933,1808.189087 1393.274414,1807.564209 
	C1393.986450,1810.239746 1392.819702,1811.830688 1389.948242,1812.050781 
	C1387.669067,1812.225464 1385.408081,1812.637573 1382.574707,1813.436035 
	C1379.702881,1813.483521 1377.395264,1813.039307 1374.407593,1812.388916 
z"
        />
        <path
          fill="#5A605E"
          opacity="1.000000"
          stroke="none"
          d="
M1426.022949,1850.473389 
	C1426.270508,1849.350708 1426.502808,1848.796143 1426.735107,1848.241577 
	C1428.889893,1849.852051 1428.586304,1852.322998 1427.159790,1853.510132 
	C1426.205078,1854.304810 1426.278076,1852.010620 1426.022949,1850.473389 
z"
        />
        <path
          fill="#05121F"
          opacity="1.000000"
          stroke="none"
          d="
M905.573120,3404.740967 
	C911.016052,3402.558838 916.365234,3402.830322 921.466858,3400.472412 
	C924.750610,3398.954834 929.506897,3401.292236 932.442383,3403.878174 
	C936.230713,3407.215332 940.966064,3409.053711 944.647339,3412.114746 
	C952.060242,3418.278564 960.079285,3420.907959 969.492493,3420.007812 
	C974.913574,3419.489014 980.028015,3420.805420 985.074646,3422.627197 
	C989.393738,3424.186279 993.524475,3425.928955 998.506531,3424.442627 
	C1001.865173,3423.440674 1006.932129,3428.063721 1007.488220,3431.920410 
	C1008.131226,3436.380371 1005.089966,3437.560791 1001.461182,3438.094971 
	C998.193665,3438.576172 995.184082,3440.275391 991.737549,3440.037598 
	C989.749207,3439.900391 987.291504,3439.798096 986.115601,3441.445068 
	C982.119690,3447.041260 976.769897,3450.216309 970.296936,3452.241211 
	C967.585388,3453.089844 968.339600,3455.751465 969.342468,3458.176270 
	C972.745850,3466.404541 972.173950,3467.126709 963.844666,3466.301514 
	C962.526001,3466.170898 960.942871,3465.656982 959.909912,3466.174805 
	C951.078674,3470.597168 941.112793,3467.135254 932.133484,3470.339600 
	C926.010986,3472.524170 919.804749,3472.724365 913.551086,3470.836670 
	C909.603516,3469.645020 906.872314,3470.917480 906.308594,3475.121338 
	C905.741638,3479.348877 908.844299,3479.663086 912.028564,3480.145264 
	C915.614258,3480.688477 919.136230,3481.726318 922.635742,3482.725098 
	C923.914673,3483.090332 924.896729,3484.109131 924.900391,3485.627441 
	C924.904724,3487.433350 923.417358,3487.721436 922.164307,3487.903809 
	C916.390686,3488.745605 911.627014,3491.394287 907.049438,3495.812012 
	C904.401672,3495.146973 902.918762,3493.495117 902.730652,3491.599609 
	C900.892273,3473.073242 890.371338,3457.051270 887.690674,3437.985596 
	C887.893433,3436.634277 888.038574,3436.103516 888.581177,3434.850098 
	C889.383789,3433.759521 889.788940,3433.391357 890.950195,3432.708984 
	C892.767334,3432.185547 893.833130,3432.155273 895.656982,3432.507812 
	C906.854309,3437.557861 917.657104,3434.572998 929.078003,3434.083008 
	C930.793640,3434.000488 931.765320,3434.084229 933.418091,3434.465332 
	C944.285767,3439.899414 952.721558,3434.762695 962.356079,3430.301025 
	C956.504517,3427.189209 951.433289,3427.348389 945.528625,3428.589355 
	C932.854126,3428.627441 920.921509,3427.784424 908.966064,3428.131348 
	C906.220886,3428.211182 903.705566,3427.384033 901.463196,3424.484863 
	C899.625183,3417.047119 904.721924,3411.727295 905.573120,3404.740967 
z"
        />
        <path
          fill="#5E4237"
          opacity="1.000000"
          stroke="none"
          d="
M886.691406,3430.293945 
	C885.941162,3427.671143 886.463989,3425.137451 885.720947,3423.055664 
	C876.797302,3398.052490 876.445374,3370.926025 866.409851,3346.177734 
	C860.140808,3330.717773 858.587158,3314.123535 855.906250,3297.806396 
	C853.928162,3285.766846 853.258240,3273.466064 849.000732,3261.838623 
	C846.898682,3256.097900 848.467529,3250.155273 847.778809,3242.892334 
	C857.699707,3252.395996 856.274109,3263.773193 857.998901,3274.165039 
	C858.515930,3277.280029 856.700989,3280.916016 860.758423,3283.603516 
	C862.468811,3287.352783 861.442627,3291.234375 863.563293,3295.357422 
	C865.050293,3302.913574 866.225159,3309.760254 867.745850,3317.330566 
	C868.868469,3324.559570 870.083252,3330.945801 872.050842,3337.234131 
	C874.294373,3344.404297 876.542297,3351.646484 877.827759,3359.024658 
	C879.446594,3368.316895 885.580750,3375.550781 888.279663,3385.038574 
	C888.515930,3393.824219 891.777344,3401.161133 893.652954,3408.815430 
	C894.688110,3413.039551 895.170044,3417.262695 894.197388,3422.298096 
	C893.172913,3425.418701 891.746582,3427.440430 889.400146,3429.708252 
	C888.331604,3430.309326 887.853943,3430.451172 886.691406,3430.293945 
z"
        />
        <path
          fill="#6C5246"
          opacity="1.000000"
          stroke="none"
          d="
M892.480225,3425.131836 
	C893.636353,3413.693115 888.451965,3403.990479 886.277893,3393.737061 
	C885.762207,3391.305176 882.419312,3388.235352 887.643127,3385.950195 
	C890.419128,3387.681641 892.507141,3389.583496 895.133484,3391.770508 
	C896.250488,3392.433594 896.794312,3392.855957 897.615173,3393.736572 
	C897.927734,3394.148926 897.859436,3394.034180 897.841553,3394.098633 
	C899.731384,3395.130615 901.525024,3396.187256 901.753418,3399.250000 
	C903.049377,3401.007080 903.987671,3402.257812 905.439941,3403.809082 
	C909.036926,3410.497314 905.856201,3415.544922 902.074280,3421.131348 
	C900.236389,3425.974609 897.094543,3426.574951 892.480225,3425.131836 
z"
        />
        <path
          fill="#6C5246"
          opacity="1.000000"
          stroke="none"
          d="
M886.003174,3429.997559 
	C886.006409,3429.994873 886.744080,3430.078369 887.114624,3430.101074 
	C889.798828,3431.139404 889.223083,3433.142090 889.502075,3435.581055 
	C890.482239,3442.843506 892.214783,3449.318359 894.906921,3455.316162 
	C900.526489,3467.836670 903.401245,3481.047119 906.213623,3495.166016 
	C906.330872,3497.485352 906.273926,3499.006104 906.332581,3501.281006 
	C906.275757,3504.088867 906.103333,3506.143066 905.887817,3508.964355 
	C905.844666,3509.731934 904.944336,3509.918213 904.488281,3509.975342 
	C903.103516,3510.775146 902.047241,3511.092041 900.074280,3510.661377 
	C896.199646,3507.682861 895.920105,3504.097168 895.741821,3500.348877 
	C894.932190,3483.327148 892.630798,3466.600098 886.576843,3450.495605 
	C884.284180,3444.396729 881.167969,3438.064453 885.475464,3430.661621 
	C885.993835,3430.004639 886.000000,3430.000000 886.003174,3429.997559 
z"
        />
        <path
          fill="#05121F"
          opacity="1.000000"
          stroke="none"
          d="
M902.359314,3399.175781 
	C900.802612,3398.275146 899.494080,3396.793701 898.004639,3394.737793 
	C900.718018,3394.237549 904.592834,3393.254150 902.359314,3399.175781 
z"
        />
        <path
          fill="#172837"
          opacity="1.000000"
          stroke="none"
          d="
M932.086304,3320.883789 
	C933.454956,3321.632812 933.473083,3322.387207 932.494629,3323.053711 
	C932.289917,3323.193115 931.816467,3323.187256 931.616882,3323.041992 
	C930.663452,3322.348145 930.693909,3321.594971 932.086304,3320.883789 
z"
        />
        <path
          fill="#05121F"
          opacity="1.000000"
          stroke="none"
          d="
M897.683105,3393.674805 
	C897.334106,3393.776123 896.740601,3393.403320 895.909424,3392.543213 
	C896.260742,3392.437500 896.849609,3392.819336 897.683105,3393.674805 
z"
        />
        <path
          fill="#D99469"
          opacity="1.000000"
          stroke="none"
          d="
M718.074585,3105.315918 
	C722.577087,3107.117188 725.287537,3111.469238 730.003601,3112.689941 
	C732.521301,3113.341797 733.939087,3116.361328 733.135254,3119.281982 
	C731.666870,3124.616211 734.867798,3127.102783 739.032227,3127.702148 
	C745.402222,3128.618896 746.299194,3132.550781 745.975891,3137.654053 
	C745.601440,3143.564697 745.873657,3149.054688 752.693542,3151.523926 
	C754.256165,3152.089600 755.816467,3153.403320 755.663391,3155.190186 
	C754.786743,3165.423584 762.027344,3173.849609 761.931396,3183.831543 
	C761.893677,3187.752930 763.662354,3190.016357 766.996643,3191.534912 
	C769.516907,3192.682617 771.854004,3194.323486 771.780762,3197.354248 
	C771.507385,3208.676270 776.299622,3218.869385 780.578552,3228.605957 
	C784.118835,3236.661621 787.391663,3244.252930 789.364746,3252.981201 
	C790.844482,3259.526855 798.233276,3264.145996 803.664429,3269.026367 
	C808.801514,3273.642334 812.629272,3279.568115 811.838135,3286.466309 
	C809.994690,3302.541748 817.868164,3315.891357 822.630676,3330.171143 
	C826.092285,3340.551270 831.111023,3350.303467 834.935303,3360.551270 
	C838.728821,3370.716064 839.150513,3381.117188 842.004028,3391.168945 
	C843.594666,3396.771729 844.537842,3402.066162 838.582031,3405.384277 
	C839.418457,3407.154785 840.341919,3407.296387 841.363464,3407.136963 
	C842.606873,3406.942627 844.074097,3405.989502 844.912659,3407.579102 
	C845.641052,3408.959717 844.756592,3410.305420 843.577881,3410.852783 
	C840.453796,3412.303955 839.621155,3412.978271 840.295654,3417.600098 
	C840.926819,3421.925049 841.813232,3430.495605 832.326721,3432.600342 
	C839.855652,3439.384277 844.153076,3447.465088 853.431580,3451.195801 
	C860.897705,3454.197998 863.299500,3467.159668 858.926025,3474.091553 
	C855.509033,3479.507812 856.536682,3485.207520 857.363708,3490.936279 
	C857.826416,3494.141846 859.357483,3497.541992 854.644043,3499.893799 
	C853.504089,3500.277832 853.096619,3500.492188 852.061890,3500.417969 
	C849.089111,3497.472900 850.709351,3493.891357 848.982727,3490.420410 
	C846.364197,3482.031494 844.070557,3474.237305 841.333740,3465.874512 
	C833.978271,3459.170410 833.486145,3449.295410 827.203979,3441.966553 
	C825.995239,3440.175049 825.356323,3438.883545 824.396423,3436.941406 
	C823.347351,3434.466553 822.896973,3432.595947 823.291626,3429.926270 
	C832.770447,3407.005615 834.237915,3384.693848 823.913757,3362.371094 
	C819.810913,3353.500000 818.106140,3343.471680 810.259094,3336.362549 
	C807.452698,3333.820312 806.358459,3329.489502 806.341980,3325.320557 
	C806.315857,3318.719727 803.125427,3320.228271 798.908691,3322.946777 
	C786.441711,3320.742676 774.097046,3319.868408 766.474854,3308.142578 
	C763.837158,3304.085205 758.623169,3306.257324 756.687073,3312.579590 
	C754.994202,3318.108154 753.296448,3322.750488 745.524536,3321.433105 
	C739.845032,3317.025879 742.364136,3311.382568 742.218384,3306.461426 
	C742.036987,3300.331299 740.577332,3294.150146 742.548706,3287.350830 
	C758.947998,3281.695068 772.447510,3284.662354 782.257568,3299.240234 
	C784.953308,3303.246582 787.897461,3307.400391 793.495056,3304.326416 
	C799.945923,3300.784424 804.166748,3294.887451 804.855347,3287.751709 
	C805.332336,3282.808838 801.586121,3279.383545 796.298340,3278.256104 
	C787.407471,3276.360596 782.569946,3269.182129 780.303833,3261.862793 
	C775.233704,3245.487549 767.497742,3231.361084 753.819763,3220.786133 
	C749.648132,3217.561035 748.850586,3212.928955 749.165344,3208.199707 
	C750.160034,3193.252930 744.788757,3179.875977 738.031677,3167.293457 
	C734.155212,3160.075195 725.614197,3156.100586 727.798157,3144.978027 
	C728.756531,3140.097412 721.198364,3135.355957 714.770203,3134.629639 
	C704.499878,3133.468994 702.773132,3123.384766 696.874695,3117.735352 
	C694.653442,3115.608398 698.293274,3111.741699 699.647217,3108.746338 
	C703.478455,3100.269531 700.707275,3096.945312 691.631470,3096.593018 
	C679.236267,3096.111816 667.618896,3101.595947 655.195740,3100.451660 
	C651.694214,3100.129150 647.735229,3102.123779 645.922668,3096.528809 
	C645.969788,3095.510010 646.016724,3095.187744 646.106750,3094.877930 
	C647.601929,3089.734375 653.898743,3096.388428 655.617676,3090.857666 
	C656.870972,3086.824951 660.757263,3085.299561 665.290771,3084.086914 
	C666.382690,3082.377197 666.897400,3080.111816 668.927246,3080.956299 
	C674.702393,3083.358643 679.084717,3080.156250 683.919067,3078.124023 
	C690.791870,3075.235107 697.502502,3071.116699 705.542664,3074.023438 
	C706.738892,3074.455811 708.308838,3074.222168 708.813110,3075.691650 
	C709.517639,3077.744873 707.910706,3078.659668 706.489258,3079.472412 
	C704.873901,3080.395752 702.412659,3079.969482 701.646179,3083.014648 
	C703.871643,3084.628174 706.372498,3083.986816 708.771362,3083.418701 
	C710.721680,3082.956299 712.069946,3082.608643 713.919678,3084.511963 
	C719.164795,3089.910156 719.338928,3089.694824 714.280823,3096.068848 
	C713.765381,3096.718262 713.797852,3097.802490 713.411621,3099.338623 
	C717.788940,3098.815186 720.089172,3094.189209 724.944885,3095.088135 
	C725.659607,3100.391357 718.046448,3100.042969 718.074585,3105.315918 
z"
        />
        <path
          fill="#8C5B43"
          opacity="1.000000"
          stroke="none"
          d="
M810.886963,3423.294922 
	C809.613770,3429.106445 811.339661,3434.758789 804.075500,3437.963867 
	C800.079529,3439.727295 801.771912,3445.837402 802.383850,3449.977539 
	C803.926819,3460.416016 801.595154,3468.851562 790.208130,3473.362061 
	C787.124817,3468.902344 787.766296,3464.759766 789.263977,3461.157959 
	C793.340515,3451.354492 791.624146,3441.749756 790.108398,3431.754395 
	C785.766602,3403.122559 782.573669,3374.348145 781.648499,3345.355957 
	C781.318604,3335.016846 788.584534,3332.878174 797.042358,3330.912109 
	C806.608582,3339.774170 804.161987,3351.316895 805.475098,3362.669434 
	C801.567749,3367.147217 801.563110,3371.350586 803.027344,3376.048340 
	C806.342224,3386.683838 809.775635,3397.300049 811.871277,3408.269043 
	C812.793396,3413.094971 813.854980,3417.976318 810.886963,3423.294922 
z"
        />
        <path
          fill="#4A3831"
          opacity="1.000000"
          stroke="none"
          d="
M810.731689,3424.226074 
	C810.517212,3408.636230 805.302917,3394.546631 801.165283,3380.230225 
	C800.153564,3376.729736 798.947449,3373.264404 798.245300,3369.701416 
	C797.677124,3366.818359 798.436035,3364.321045 802.899658,3364.185059 
	C806.822449,3365.312744 808.026306,3368.078369 808.778809,3370.948730 
	C813.201599,3387.817627 818.257202,3404.478760 824.440186,3420.796875 
	C825.352600,3423.204834 825.992249,3425.849365 824.782654,3429.229492 
	C824.865540,3431.565430 825.334473,3433.194580 825.774292,3435.463867 
	C818.947510,3438.692871 817.199219,3433.413818 814.325195,3428.556152 
	C812.925964,3426.871826 812.032776,3425.731201 810.731689,3424.226074 
z"
        />
        <path
          fill="#8C5B43"
          opacity="1.000000"
          stroke="none"
          d="
M813.966064,3509.394043 
	C811.760315,3510.465576 807.894287,3513.687744 809.155212,3508.030762 
	C810.037720,3504.071289 809.695190,3500.972900 807.246155,3497.864014 
	C805.953369,3496.222656 806.115723,3494.176025 806.257996,3492.148193 
	C806.765564,3484.915283 806.722900,3484.912354 798.376770,3484.393555 
	C800.111450,3481.510986 803.253174,3481.156738 806.024780,3480.456055 
	C810.610046,3479.297119 815.118591,3481.983154 815.557373,3486.682129 
	C816.240906,3494.002441 818.766541,3501.629395 813.966064,3509.394043 
z"
        />
        <path
          fill="#281C18"
          opacity="1.000000"
          stroke="none"
          d="
M813.847290,3428.848633 
	C818.134277,3429.599365 819.973511,3434.517578 825.091309,3436.004395 
	C826.430420,3437.018311 827.115601,3437.933105 827.934814,3439.409668 
	C829.654297,3446.168945 836.687500,3452.020264 828.463745,3458.836914 
	C820.053833,3450.573242 813.276733,3441.870117 813.847290,3428.848633 
z"
        />
        <path
          fill="#4A3831"
          opacity="1.000000"
          stroke="none"
          d="
M827.831909,3459.446045 
	C831.074951,3454.870850 830.691101,3450.499268 828.716492,3446.083496 
	C827.956665,3444.384277 825.990540,3443.117676 827.585083,3440.412842 
	C834.672974,3446.656738 837.871765,3455.120605 840.717529,3464.582520 
	C838.256836,3467.065674 836.271484,3471.625732 832.082764,3466.672363 
	C829.742737,3464.600342 829.015259,3462.210938 827.831909,3459.446045 
z"
        />
        <path
          fill="#8C5B43"
          opacity="1.000000"
          stroke="none"
          d="
M791.906372,3483.328125 
	C786.045715,3486.870117 780.150574,3487.772461 774.198364,3482.814697 
	C777.019409,3476.133789 782.663513,3474.594971 789.207886,3473.667480 
	C789.898376,3473.927490 790.003723,3474.012207 790.056580,3474.057129 
	C789.631226,3477.145264 791.006836,3479.815674 791.906372,3483.328125 
z"
        />
        <path
          fill="#BA815C"
          opacity="1.000000"
          stroke="none"
          d="
M848.460327,3490.628418 
	C852.977112,3491.092285 851.766724,3494.758789 852.103333,3498.470703 
	C851.989624,3501.031250 852.087280,3502.935059 850.047852,3504.712891 
	C848.071899,3505.783936 846.735291,3506.575439 844.818604,3507.450195 
	C843.435913,3501.630371 850.016052,3497.571777 848.460327,3490.628418 
z"
        />
        <path
          fill="#A26847"
          opacity="1.000000"
          stroke="none"
          d="
M747.899536,3491.589844 
	C742.512756,3492.326660 737.751892,3490.021240 733.126221,3487.506104 
	C729.316772,3485.435303 727.746948,3487.598145 726.077209,3491.139160 
	C718.289917,3492.974854 712.011780,3489.760742 706.723633,3485.263184 
	C701.834412,3481.104980 696.996521,3477.743408 690.429504,3477.305908 
	C689.209167,3477.224609 688.049561,3476.233398 686.367920,3475.138428 
	C683.464111,3471.500244 682.763306,3467.898438 683.720276,3464.219727 
	C686.277039,3454.391113 681.341431,3448.640625 672.930664,3445.852783 
	C663.657349,3442.779053 663.255737,3437.415039 664.994324,3428.859131 
	C669.638550,3406.002930 671.798889,3383.002930 664.443298,3360.041992 
	C662.702332,3354.607666 659.979614,3348.708496 663.714172,3342.071289 
	C664.861694,3341.287598 665.397339,3341.092773 666.792542,3340.924072 
	C668.755798,3341.299072 669.737976,3341.860107 671.148560,3343.277100 
	C679.934692,3361.346924 682.434204,3380.165771 685.014832,3398.960693 
	C686.646484,3410.843750 688.985352,3422.577393 692.433960,3434.850586 
	C694.515198,3444.095703 696.490967,3452.564697 706.033569,3456.949219 
	C715.141968,3456.785400 717.028625,3448.525879 722.173706,3444.283691 
	C724.332703,3442.503418 724.975891,3438.854248 729.315674,3439.557129 
	C733.980225,3449.945312 733.234314,3452.352783 722.619873,3462.019775 
	C728.115601,3466.021484 734.842285,3467.438965 739.890625,3472.331055 
	C745.492126,3477.759521 745.681396,3484.460693 747.899536,3491.589844 
z"
        />
        <path
          fill="#ECA777"
          opacity="1.000000"
          stroke="none"
          d="
M754.848389,3460.389160 
	C745.553223,3435.723877 740.739136,3410.562256 735.743835,3385.444336 
	C735.356384,3383.496094 734.776855,3381.586182 733.979980,3378.959717 
	C727.213074,3372.710693 726.953918,3364.093018 723.289307,3357.151123 
	C721.438049,3353.644531 719.817871,3349.998047 717.742065,3345.728516 
	C716.636963,3343.035645 716.305115,3340.998779 717.138367,3338.158936 
	C722.612000,3334.317627 725.734131,3327.508057 733.960327,3329.189697 
	C738.334961,3342.677734 746.469788,3353.960205 749.934265,3367.147949 
	C751.051086,3371.399414 753.334290,3375.368164 755.999878,3378.473145 
	C760.273132,3383.450439 760.773621,3388.469482 761.210388,3394.760986 
	C761.859375,3404.109863 766.918701,3412.748291 766.672974,3422.744385 
	C766.427612,3432.728760 763.498108,3442.316650 763.692688,3452.357178 
	C763.771484,3456.424805 760.890686,3460.602051 754.848389,3460.389160 
z"
        />
        <path
          fill="#D99469"
          opacity="1.000000"
          stroke="none"
          d="
M754.230469,3460.651855 
	C761.006775,3456.845947 763.205566,3451.638184 761.184143,3444.599121 
	C760.631958,3442.675781 759.248962,3440.239502 760.398376,3438.878174 
	C766.863220,3431.218262 761.673340,3422.299561 763.371338,3414.129395 
	C764.243103,3409.934814 762.301636,3405.581055 759.236389,3402.356445 
	C756.329468,3399.298828 755.406738,3396.265869 757.756653,3392.580078 
	C760.125427,3388.864502 758.900696,3386.344727 756.085938,3383.061279 
	C751.076660,3377.217285 745.761719,3371.158691 745.962891,3362.581543 
	C746.020752,3360.113281 744.895508,3358.180420 743.333862,3356.337402 
	C737.305603,3349.222412 735.568054,3340.461914 734.045105,3330.833008 
	C733.816589,3328.594482 734.076050,3327.240723 735.162842,3325.390869 
	C736.695740,3323.862549 737.960876,3323.323242 740.074951,3323.675293 
	C746.138428,3329.792236 741.511658,3338.555664 747.110168,3345.014648 
	C747.742554,3346.011230 747.973389,3346.427734 748.470581,3347.511719 
	C755.299683,3363.818359 764.239258,3378.241455 772.094116,3394.073730 
	C772.423950,3401.126221 775.060974,3407.140381 774.520691,3414.301270 
	C772.048401,3422.003662 772.706177,3428.843506 775.196777,3436.368164 
	C775.479919,3438.601807 775.272766,3440.065918 774.696167,3442.206543 
	C771.486389,3450.361084 769.436584,3457.722900 774.970093,3465.102051 
	C776.316101,3466.896729 775.819580,3469.542725 776.228394,3472.518066 
	C776.389221,3475.229736 776.166016,3477.154541 774.509644,3479.400391 
	C773.103149,3479.964844 772.218628,3480.001465 770.670410,3480.052002 
	C769.118225,3480.036133 768.229614,3480.015381 766.674072,3480.002197 
	C758.769470,3475.885986 757.060486,3468.277832 754.230469,3460.651855 
z"
        />
        <path
          fill="#BA7C55"
          opacity="1.000000"
          stroke="none"
          d="
M729.903564,3439.238770 
	C728.578186,3440.271973 726.990784,3440.522217 726.696228,3441.911133 
	C725.848511,3445.907227 724.744141,3449.438721 720.067261,3450.580811 
	C718.347900,3451.000732 718.046631,3452.999023 717.589417,3454.550049 
	C716.049316,3459.775146 712.079407,3460.406250 706.694458,3459.902832 
	C701.317078,3450.176270 701.969543,3445.403076 710.181152,3439.410645 
	C714.425659,3436.312988 715.505432,3433.420166 713.837891,3428.617920 
	C710.595093,3419.279053 710.902161,3409.896729 713.444214,3400.299805 
	C716.207581,3389.867920 715.311829,3379.486084 711.670776,3369.180420 
	C708.803467,3361.064453 711.530640,3353.747314 717.693787,3347.235596 
	C723.834961,3352.633545 725.478210,3360.369141 726.078552,3366.894287 
	C727.217590,3379.273682 730.023560,3391.722900 728.316345,3404.292725 
	C727.021301,3413.827393 729.173889,3423.257568 729.372559,3433.416016 
	C729.898682,3435.554932 729.924194,3437.029297 729.903564,3439.238770 
z"
        />
        <path
          fill="#A26847"
          opacity="1.000000"
          stroke="none"
          d="
M730.066284,3433.255859 
	C725.954041,3433.424072 723.687988,3431.709961 725.264282,3427.269531 
	C726.428467,3423.989502 725.886047,3420.767578 724.919312,3417.477051 
	C724.275146,3415.284424 723.531006,3412.655029 724.156921,3410.631104 
	C728.152710,3397.709717 723.371338,3384.914062 723.970154,3372.071533 
	C724.126404,3368.721191 724.015686,3365.418701 721.749634,3362.575684 
	C718.560303,3358.573975 721.240967,3353.343750 718.491455,3348.561768 
	C716.798279,3346.489014 717.083252,3345.135254 719.217407,3343.944580 
	C725.178345,3354.627930 730.593567,3365.176025 733.583496,3377.509766 
	C727.737854,3380.017578 730.412964,3384.494141 730.794128,3388.040771 
	C730.952271,3389.512451 733.220337,3391.051514 732.969849,3392.227783 
	C730.139954,3405.517090 734.435852,3419.268799 730.066284,3433.255859 
z"
        />
        <path
          fill="#CC855B"
          opacity="1.000000"
          stroke="none"
          d="
M766.182251,3480.475586 
	C766.881836,3478.636475 767.946472,3478.075684 769.707764,3479.629883 
	C769.572571,3480.610352 769.138611,3481.155029 768.272827,3482.010742 
	C767.346313,3481.865234 766.851685,3481.408447 766.182251,3480.475586 
z"
        />
        <path
          fill="#A26847"
          opacity="1.000000"
          stroke="none"
          d="
M899.378235,3509.723145 
	C900.901245,3509.979248 901.795715,3510.003662 903.361145,3510.030273 
	C903.386536,3515.307129 901.183044,3519.832275 896.868530,3523.931885 
	C888.834961,3516.350342 879.369629,3515.064697 868.588745,3515.506104 
	C867.082336,3514.564453 866.475220,3513.776123 865.948120,3512.069336 
	C867.895081,3504.206787 865.512085,3496.366455 869.583130,3489.494141 
	C870.770996,3487.489014 869.039429,3485.022461 868.635315,3482.761719 
	C867.825317,3478.229736 866.737671,3473.568115 870.411377,3469.544434 
	C872.155579,3467.635010 872.383789,3465.165283 871.248840,3463.011475 
	C867.321716,3455.559326 867.510986,3447.026123 865.013123,3439.277100 
	C861.129944,3438.223877 858.664429,3441.433594 854.720703,3441.915039 
	C852.446045,3433.373047 852.446045,3433.373047 844.645813,3432.433594 
	C845.272522,3429.069580 848.185608,3426.428467 846.838806,3422.294922 
	C845.834534,3419.212158 849.412903,3418.271973 852.892151,3417.928711 
	C856.707397,3415.512939 860.280762,3413.297363 861.615784,3419.096436 
	C863.042908,3425.295410 867.446533,3428.170898 872.741455,3431.543945 
	C873.741211,3432.945557 874.132690,3433.855469 874.561768,3435.557373 
	C874.417603,3447.613281 880.481750,3458.185547 879.204895,3469.420410 
	C878.046875,3479.608887 883.080994,3489.227295 880.734741,3500.007812 
	C879.760071,3503.533447 880.081482,3505.171143 883.726501,3504.242920 
	C889.830627,3502.687988 894.548340,3505.428467 899.378235,3509.723145 
z"
        />
        <path
          fill="#BA815C"
          opacity="1.000000"
          stroke="none"
          d="
M868.355591,3513.964355 
	C870.963257,3514.022461 872.994324,3514.165527 874.937012,3513.816650 
	C888.427979,3511.393311 888.746704,3511.504395 895.902710,3523.597412 
	C885.199402,3537.206299 875.000000,3536.012695 863.043762,3519.737793 
	C863.081909,3518.849854 863.343872,3518.589111 864.008240,3517.947266 
	C865.594543,3516.384277 866.162964,3514.655762 868.355591,3513.964355 
z"
        />
        <path
          fill="#704A3A"
          opacity="1.000000"
          stroke="none"
          d="
M850.264221,3505.147461 
	C850.010498,3503.387695 850.612549,3501.783203 851.660889,3499.682129 
	C852.401855,3499.344971 852.696533,3499.503906 853.451416,3499.863281 
	C854.101685,3501.358643 854.291809,3502.653809 854.537354,3504.598145 
	C853.435120,3505.265869 852.277527,3505.284180 850.264221,3505.147461 
z"
        />
        <path
          fill="#B0714F"
          opacity="1.000000"
          stroke="none"
          d="
M773.984863,3480.463135 
	C773.983154,3477.811035 773.978699,3475.694092 773.969299,3472.781982 
	C782.386963,3467.374268 779.168457,3459.106445 780.502075,3451.571289 
	C783.238525,3440.025879 782.474426,3429.448730 776.783813,3418.772461 
	C776.230103,3417.690186 776.036011,3417.238037 775.630493,3416.070801 
	C773.342651,3407.463867 773.888123,3399.427002 773.873047,3390.657227 
	C773.304993,3388.344971 773.133789,3386.764648 773.102173,3384.351807 
	C772.443115,3376.238770 771.063354,3369.084717 767.961975,3362.420898 
	C764.554199,3355.098633 764.828247,3347.473877 766.282349,3339.976318 
	C768.689758,3327.563477 781.707092,3321.283203 797.859741,3325.081787 
	C799.530212,3327.173828 799.664307,3328.834473 798.589966,3331.277588 
	C794.001770,3332.962402 792.385742,3338.440918 786.703369,3337.356934 
	C784.356262,3336.909180 783.523560,3341.378906 784.457153,3343.987061 
	C786.788208,3350.497070 785.931519,3357.181641 785.904907,3363.788330 
	C785.859314,3375.121582 787.457092,3386.254395 789.501465,3397.329590 
	C790.533264,3402.919189 789.868164,3408.543213 790.860596,3414.189453 
	C792.634766,3424.283447 793.874390,3434.472168 795.781494,3444.568359 
	C796.622925,3449.022461 796.029053,3453.503418 793.606812,3458.019775 
	C791.348999,3462.229736 791.101379,3467.517822 789.933594,3473.129395 
	C785.670715,3477.711182 779.904419,3478.497803 774.622925,3481.758057 
	C773.992249,3481.711914 773.987244,3481.354980 773.984863,3480.463135 
z"
        />
        <path
          fill="#2E2D30"
          opacity="1.000000"
          stroke="none"
          d="
M794.215088,3510.845703 
	C790.935120,3512.041504 788.938416,3510.520996 788.181274,3506.469727 
	C790.927612,3506.203369 792.688354,3507.957275 794.215088,3510.845703 
z"
        />
        <path
          fill="#959693"
          opacity="1.000000"
          stroke="none"
          d="
M2811.366699,1889.916748 
	C2811.934082,1895.230713 2809.301514,1900.055176 2809.407471,1905.429077 
	C2806.960693,1901.013062 2808.440430,1896.302979 2807.989502,1890.915771 
	C2808.904541,1889.990112 2809.799561,1889.933472 2811.366699,1889.916748 
z"
        />
        <path
          fill="#B6AFA3"
          opacity="1.000000"
          stroke="none"
          d="
M2856.420410,1867.593018 
	C2853.506836,1872.747070 2849.325195,1871.922852 2844.668457,1871.914795 
	C2844.187744,1867.173218 2841.485840,1862.552368 2844.933105,1857.670532 
	C2847.454102,1854.100098 2849.727783,1851.587158 2855.000000,1851.891479 
	C2855.838135,1853.404297 2855.932373,1854.984009 2856.057861,1857.352051 
	C2858.196045,1860.859253 2858.285889,1863.779419 2856.420410,1867.593018 
z"
        />
        <path
          fill="#D1C9BA"
          opacity="1.000000"
          stroke="none"
          d="
M2802.761230,1873.720947 
	C2800.635254,1872.278931 2801.811279,1871.461426 2802.956055,1870.915649 
	C2803.850586,1870.489136 2804.882324,1870.846069 2805.034180,1871.954468 
	C2805.188721,1873.080933 2804.277100,1873.552002 2802.761230,1873.720947 
z"
        />
        <path
          fill="#959693"
          opacity="1.000000"
          stroke="none"
          d="
M2797.861328,1875.216309 
	C2797.257080,1875.647949 2796.759766,1875.338867 2796.219727,1874.377441 
	C2796.028320,1873.964966 2796.837646,1873.890015 2797.232910,1873.808838 
	C2797.692139,1874.056763 2797.755615,1874.385864 2797.861328,1875.216309 
z"
        />
        <path
          fill="#83837F"
          opacity="1.000000"
          stroke="none"
          d="
M2874.871582,1875.282593 
	C2875.293701,1876.788696 2874.765137,1877.334106 2873.596191,1877.102173 
	C2873.353760,1877.053955 2873.026855,1876.708984 2872.989990,1876.463257 
	C2872.813965,1875.281616 2873.388184,1874.786255 2874.871582,1875.282593 
z"
        />
        <path
          fill="#797771"
          opacity="1.000000"
          stroke="none"
          d="
M2856.617920,1868.155273 
	C2855.912354,1865.502808 2855.942627,1862.660889 2856.031250,1858.979736 
	C2861.157959,1860.966309 2861.361572,1862.548340 2856.617920,1868.155273 
z"
        />
        <path
          fill="#83837F"
          opacity="1.000000"
          stroke="none"
          d="
M2864.246094,1831.447266 
	C2864.500000,1830.626343 2864.839600,1830.224731 2865.179199,1829.823120 
	C2865.336426,1830.408203 2865.701172,1831.030029 2865.605225,1831.570190 
	C2865.440430,1832.498657 2864.879883,1832.573364 2864.246094,1831.447266 
z"
        />
        <path
          fill="#83837F"
          opacity="1.000000"
          stroke="none"
          d="
M2880.797363,1832.468750 
	C2880.706299,1831.974854 2880.891846,1831.443604 2880.927979,1831.453857 
	C2881.668945,1831.658447 2881.687500,1832.092896 2880.797363,1832.468750 
z"
        />
        <path
          fill="#5E4237"
          opacity="1.000000"
          stroke="none"
          d="
M2498.850098,3768.081543 
	C2498.576416,3767.046875 2499.091309,3765.988525 2499.853271,3764.391113 
	C2502.289307,3763.870361 2504.478516,3763.889404 2507.504150,3763.916016 
	C2507.229492,3769.068115 2503.079834,3767.817627 2498.850098,3768.081543 
z"
        />
        <path
          fill="#5E4237"
          opacity="1.000000"
          stroke="none"
          d="
M2515.525146,3760.136230 
	C2516.175293,3760.168701 2516.201172,3761.082520 2516.148193,3761.536377 
	C2515.093262,3762.015625 2514.091064,3762.041016 2512.330811,3762.025391 
	C2512.673340,3761.357666 2513.774170,3760.730713 2515.525146,3760.136230 
z"
        />
        <path
          fill="#1D1712"
          opacity="1.000000"
          stroke="none"
          d="
M2470.001465,3963.077148 
	C2466.706787,3953.531494 2470.218262,3946.553955 2477.358154,3939.999023 
	C2477.949219,3939.413818 2477.814697,3939.341064 2477.770020,3939.345703 
	C2480.526367,3937.652832 2480.782471,3940.444824 2481.546143,3941.736572 
	C2484.469238,3946.681641 2485.022217,3952.003906 2483.215088,3958.299316 
	C2482.224609,3959.858398 2481.498535,3960.657959 2480.465088,3961.902832 
	C2476.982666,3963.006592 2474.056641,3965.633545 2470.001465,3963.077148 
z"
        />
        <path
          fill="#0C0B0A"
          opacity="1.000000"
          stroke="none"
          d="
M2444.599121,3897.666992 
	C2443.671875,3898.093262 2443.152344,3898.144531 2441.859863,3898.322266 
	C2440.634033,3898.074707 2440.382324,3897.594971 2440.257812,3896.600586 
	C2441.157959,3895.020020 2442.409424,3894.410400 2444.517578,3895.266602 
	C2445.072998,3896.555908 2445.039795,3896.924072 2444.599121,3897.666992 
z"
        />
        <path
          fill="#D0D1C8"
          opacity="1.000000"
          stroke="none"
          d="
M2415.763184,3895.470215 
	C2416.187012,3901.605957 2415.884033,3907.246094 2413.233643,3912.433105 
	C2411.064941,3916.677490 2408.687012,3916.738281 2406.124268,3912.608887 
	C2404.914551,3910.659912 2404.132324,3908.445801 2403.152832,3906.344238 
	C2399.833984,3908.625732 2401.525879,3914.915527 2397.249756,3914.959473 
	C2393.228027,3915.000732 2393.169434,3909.494629 2390.925049,3906.892822 
	C2386.564697,3901.837158 2387.293701,3896.720215 2388.730713,3889.948242 
	C2388.496338,3888.275391 2388.707275,3887.889648 2389.950684,3886.676758 
	C2392.327881,3885.349121 2393.580566,3885.306152 2394.956055,3887.122803 
	C2396.607910,3890.699463 2397.106689,3893.844727 2396.857910,3897.105957 
	C2397.336670,3893.398682 2396.747803,3889.908936 2394.212402,3886.197998 
	C2393.630615,3885.202881 2393.545654,3884.722900 2393.854248,3883.612793 
	C2396.789307,3880.967529 2399.583496,3880.063965 2403.400391,3881.522949 
	C2404.090576,3882.551270 2404.247559,3883.074707 2404.731934,3883.893799 
	C2408.803711,3886.253906 2406.725342,3891.108887 2409.986816,3894.002930 
	C2411.352295,3894.693848 2412.077393,3896.006104 2413.555176,3894.414551 
	C2414.563721,3894.189941 2415.113037,3894.520752 2415.763184,3895.470215 
z"
        />
        <path
          fill="#50494B"
          opacity="1.000000"
          stroke="none"
          d="
M2437.941895,3917.268555 
	C2436.940918,3917.954102 2435.990723,3917.875977 2434.325195,3917.788086 
	C2433.099609,3916.063721 2435.379395,3914.125977 2431.640137,3912.585449 
	C2426.614258,3910.514404 2424.390381,3902.914307 2425.830078,3898.450928 
	C2426.486572,3896.415039 2427.949707,3896.249512 2429.641357,3896.283691 
	C2431.260742,3896.316162 2433.913818,3896.365479 2433.509277,3898.135986 
	C2431.907959,3905.148193 2438.712402,3909.885742 2437.941895,3917.268555 
z"
        />
        <path
          fill="#0C0B0A"
          opacity="1.000000"
          stroke="none"
          d="
M2412.422607,3895.844727 
	C2409.394775,3896.553955 2405.780273,3899.266357 2405.704102,3893.703369 
	C2405.668213,3891.078125 2405.092285,3888.460693 2404.878418,3885.023926 
	C2405.576660,3884.173340 2406.157227,3884.138184 2407.610352,3884.121094 
	C2407.891846,3887.527588 2409.587646,3890.033203 2411.936768,3892.988281 
	C2412.458252,3894.110352 2412.710205,3894.634766 2412.422607,3895.844727 
z"
        />
        <path
          fill="#2C2520"
          opacity="1.000000"
          stroke="none"
          d="
M2412.179688,3896.983154 
	C2411.917480,3896.037842 2411.951660,3895.544678 2412.096191,3894.318848 
	C2412.206543,3893.585938 2413.082031,3893.716309 2413.494629,3893.876953 
	C2413.801025,3895.326660 2414.666504,3897.024658 2412.179688,3896.983154 
z"
        />
        <path
          fill="#E6E1D0"
          opacity="1.000000"
          stroke="none"
          d="
M2388.053955,3890.027100 
	C2388.100342,3889.956055 2387.218262,3889.939941 2386.791992,3890.052979 
	C2385.937256,3890.422363 2385.509033,3890.678467 2384.369141,3890.705811 
	C2381.141113,3887.821045 2381.092285,3884.420654 2381.139893,3881.141357 
	C2381.285400,3871.162842 2377.346680,3863.184082 2369.210938,3856.371094 
	C2368.352051,3855.474121 2368.055664,3855.068848 2367.584229,3853.929443 
	C2367.649414,3852.174561 2368.200439,3851.365479 2369.791504,3850.573730 
	C2370.520752,3850.379150 2371.409912,3850.336914 2371.782715,3850.092773 
	C2377.054199,3851.516357 2380.406982,3855.053955 2383.615234,3859.710938 
	C2383.604004,3862.400635 2384.856445,3863.826660 2386.429199,3865.647461 
	C2393.313965,3869.527588 2393.157715,3875.521240 2392.393555,3882.468262 
	C2391.779297,3884.449951 2391.373291,3885.560059 2390.397949,3887.380859 
	C2389.920654,3888.125488 2390.114258,3888.077881 2390.105469,3887.978760 
	C2389.616699,3888.377930 2389.119141,3888.857910 2388.227539,3889.676270 
	C2387.851074,3890.032471 2388.007568,3890.098145 2388.053955,3890.027100 
z"
        />
        <path
          fill="#0C0B0A"
          opacity="1.000000"
          stroke="none"
          d="
M2390.687012,3884.435059 
	C2390.786133,3878.257568 2389.493164,3872.820068 2387.261719,3866.726562 
	C2393.782959,3864.523682 2393.782959,3864.523682 2397.553711,3873.486816 
	C2398.188965,3876.851562 2399.874023,3877.800781 2402.302246,3877.947754 
	C2404.163330,3878.060547 2405.097900,3878.921631 2404.205566,3881.453613 
	C2400.959229,3881.561279 2398.403809,3883.339111 2394.854980,3883.950684 
	C2393.332275,3884.805176 2392.390625,3885.075439 2390.687012,3884.435059 
z"
        />
        <path
          fill="#CC855B"
          opacity="1.000000"
          stroke="none"
          d="
M2435.623535,3754.404297 
	C2437.529785,3754.208252 2439.016357,3753.929688 2440.123047,3754.453613 
	C2447.629639,3758.007568 2448.231689,3766.755615 2453.154541,3772.463867 
	C2456.047363,3775.817627 2454.326416,3781.983887 2451.773193,3786.563232 
	C2450.488281,3788.868408 2449.520996,3791.362061 2448.124268,3793.591309 
	C2444.538086,3799.313721 2437.932861,3799.770508 2433.352051,3794.823242 
	C2431.362793,3792.674561 2430.460693,3789.569336 2426.880371,3788.102295 
	C2428.207031,3781.227783 2430.201416,3774.494873 2432.407227,3767.026855 
	C2433.098633,3765.261230 2433.730957,3764.291748 2433.893066,3762.559570 
	C2434.035889,3760.468506 2434.172363,3758.948486 2434.417969,3756.673340 
	C2434.693848,3755.509521 2434.860840,3755.101318 2435.623535,3754.404297 
z"
        />
        <path
          fill="#EEE7D5"
          opacity="1.000000"
          stroke="none"
          d="
M2432.531982,3755.240234 
	C2430.515137,3759.871582 2429.125732,3763.870850 2427.582764,3767.810303 
	C2426.995117,3769.310303 2426.116699,3770.946045 2424.031494,3770.141357 
	C2422.105713,3769.397949 2422.220215,3767.673340 2422.526611,3766.005615 
	C2423.949951,3758.257324 2425.384033,3750.510986 2426.743652,3741.969971 
	C2426.697021,3739.674072 2426.634277,3738.184326 2425.799316,3736.056152 
	C2425.000000,3732.319580 2425.226074,3729.291016 2425.621582,3725.506348 
	C2426.038330,3723.245117 2426.692139,3721.837646 2427.018311,3719.545166 
	C2427.192139,3716.630859 2427.301758,3714.494385 2427.886475,3711.569336 
	C2429.392822,3708.222168 2429.536377,3703.955566 2433.078857,3704.206055 
	C2436.686279,3704.461426 2436.318604,3708.555664 2436.999512,3711.405273 
	C2438.162598,3716.271240 2439.197510,3721.131348 2438.917725,3727.041016 
	C2439.933594,3737.444092 2436.619629,3746.036865 2432.531982,3755.240234 
z"
        />
        <path
          fill="#8C5B43"
          opacity="1.000000"
          stroke="none"
          d="
M2385.504395,3821.690918 
	C2386.018555,3828.295898 2381.725830,3832.918213 2379.514404,3838.333008 
	C2377.622803,3842.964844 2373.321289,3842.453369 2368.656494,3840.269531 
	C2363.287109,3834.983643 2362.455811,3829.886230 2368.125000,3824.101562 
	C2369.543457,3823.028564 2370.459473,3822.638428 2372.159668,3822.334229 
	C2376.957520,3822.896484 2380.873535,3821.229004 2385.504395,3821.690918 
z"
        />
        <path
          fill="#935F47"
          opacity="1.000000"
          stroke="none"
          d="
M2402.542969,3793.583496 
	C2393.635498,3792.403320 2395.074463,3784.927979 2391.638916,3778.679443 
	C2401.524658,3779.249268 2405.983643,3774.306885 2408.738037,3767.128906 
	C2409.360352,3765.507080 2410.837158,3764.656738 2413.187500,3764.041016 
	C2414.619141,3764.058350 2415.084473,3764.483887 2415.581055,3765.620117 
	C2415.873047,3766.045898 2415.848877,3766.975342 2415.846436,3767.440430 
	C2415.085693,3768.792236 2414.360352,3769.699707 2413.938965,3771.425293 
	C2413.803711,3775.415039 2411.692871,3777.535400 2408.624512,3779.720947 
	C2404.128174,3783.539551 2406.028564,3789.247314 2402.542969,3793.583496 
z"
        />
        <path
          fill="#935F47"
          opacity="1.000000"
          stroke="none"
          d="
M2416.628906,3750.435059 
	C2412.191162,3747.839355 2410.674561,3744.573242 2413.365723,3740.967773 
	C2416.724854,3736.468018 2418.053955,3731.617432 2418.112793,3726.190186 
	C2418.144775,3723.249756 2419.477295,3721.129639 2423.113770,3720.013184 
	C2427.985596,3725.279541 2423.946533,3730.925537 2424.091309,3737.208984 
	C2423.846680,3738.831543 2423.815186,3739.696777 2423.736816,3741.209473 
	C2420.811523,3744.338867 2423.145508,3750.545898 2416.628906,3750.435059 
z"
        />
        <path
          fill="#935F47"
          opacity="1.000000"
          stroke="none"
          d="
M2386.671875,3821.725586 
	C2382.341797,3822.983398 2379.466309,3827.199951 2374.444092,3824.526855 
	C2372.892822,3822.814453 2372.413818,3821.390137 2372.957031,3819.024414 
	C2373.695312,3818.005127 2374.120117,3817.702637 2375.267578,3817.699951 
	C2382.363037,3816.777344 2387.786621,3812.663818 2395.084473,3811.932617 
	C2395.147705,3817.019531 2389.977295,3818.117920 2386.671875,3821.725586 
z"
        />
        <path
          fill="#8C5B43"
          opacity="1.000000"
          stroke="none"
          d="
M2401.966797,3794.692383 
	C2402.228516,3789.015625 2400.924316,3783.480225 2407.323242,3780.158203 
	C2408.491211,3791.876953 2405.735840,3802.619141 2396.543457,3811.632080 
	C2395.961670,3809.812500 2396.015137,3807.709717 2396.096680,3804.816895 
	C2397.076416,3803.001465 2398.034180,3801.982178 2399.503906,3800.484131 
	C2401.621826,3798.854492 2401.938477,3797.169189 2401.966797,3794.692383 
z"
        />
        <path
          fill="#8C5B43"
          opacity="1.000000"
          stroke="none"
          d="
M2369.959961,3849.966309 
	C2369.807617,3851.247803 2369.094482,3852.116455 2368.231201,3853.530762 
	C2367.264160,3854.752197 2366.328369,3855.036377 2364.616699,3854.547852 
	C2363.959961,3854.167725 2363.077637,3854.448242 2362.632812,3854.321289 
	C2359.715820,3849.336914 2357.986572,3844.042725 2352.493408,3841.295654 
	C2350.174561,3840.135986 2348.514404,3837.836182 2349.447510,3834.137695 
	C2352.731689,3831.523438 2355.631592,3832.997559 2359.263184,3833.841553 
	C2361.893799,3835.074219 2363.483887,3836.348389 2364.135498,3839.185059 
	C2365.797852,3843.151855 2367.598633,3846.352783 2369.959961,3849.966309 
z"
        />
        <path
          fill="#805541"
          opacity="1.000000"
          stroke="none"
          d="
M2415.946045,3750.586182 
	C2418.214355,3749.711670 2419.709473,3748.676025 2419.973877,3746.297607 
	C2420.163818,3744.586914 2420.702637,3743.070068 2423.052734,3742.066162 
	C2424.035400,3750.382812 2420.052002,3757.468994 2416.003418,3765.311279 
	C2415.271484,3765.747070 2414.776611,3765.323242 2414.141602,3764.344971 
	C2413.900635,3763.064697 2413.906738,3762.213867 2413.913574,3760.724609 
	C2412.802979,3758.667969 2412.439941,3757.112793 2413.520020,3754.684570 
	C2414.620850,3753.051025 2415.251709,3752.111572 2415.946045,3750.586182 
z"
        />
        <path
          fill="#A36F51"
          opacity="1.000000"
          stroke="none"
          d="
M2432.605957,3755.918701 
	C2433.298828,3747.164307 2435.906738,3738.624512 2436.061768,3728.832520 
	C2436.733887,3727.710205 2437.250000,3727.447998 2438.625000,3727.335938 
	C2444.776367,3733.311768 2443.893555,3739.060303 2438.648926,3745.381348 
	C2436.971191,3748.111816 2435.892578,3750.217529 2436.163574,3753.405273 
	C2435.983398,3754.638916 2435.658447,3755.099121 2434.885742,3755.707275 
	C2434.100098,3755.925781 2433.673340,3755.933594 2432.605957,3755.918701 
z"
        />
        <path
          fill="#805541"
          opacity="1.000000"
          stroke="none"
          d="
M2424.468750,3738.377686 
	C2419.458496,3732.462646 2423.617432,3726.978516 2423.883789,3720.671387 
	C2423.864258,3718.997314 2423.877441,3718.137451 2423.894043,3716.631592 
	C2424.558350,3715.333984 2425.350830,3714.961914 2426.934570,3715.017090 
	C2428.880615,3716.019531 2428.956543,3717.380371 2429.392578,3719.316895 
	C2429.683105,3720.990234 2429.664307,3722.066895 2429.410156,3723.635254 
	C2424.966797,3726.884521 2427.822266,3731.072021 2427.323242,3735.204590 
	C2427.520264,3737.253174 2427.462646,3738.625977 2427.039062,3740.587402 
	C2426.135254,3740.380371 2425.597168,3739.584473 2424.468750,3738.377686 
z"
        />
        <path
          fill="#935F47"
          opacity="1.000000"
          stroke="none"
          d="
M2364.662842,3839.970215 
	C2362.884277,3838.966064 2361.771240,3837.980957 2360.329102,3836.537354 
	C2358.387695,3823.944580 2360.069580,3821.449463 2369.508789,3823.389648 
	C2366.899414,3828.517090 2365.908936,3833.277344 2367.977783,3839.220703 
	C2367.103027,3839.977051 2366.215576,3839.983398 2364.662842,3839.970215 
z"
        />
        <path
          fill="#805541"
          opacity="1.000000"
          stroke="none"
          d="
M2414.346191,3771.649902 
	C2412.022705,3770.209473 2412.449219,3768.942627 2415.299561,3768.009277 
	C2415.509766,3769.036621 2415.175781,3770.167725 2414.346191,3771.649902 
z"
        />
        <path
          fill="#A36F51"
          opacity="1.000000"
          stroke="none"
          d="
M2433.442871,3762.387207 
	C2435.831787,3763.457031 2435.673096,3764.772461 2433.114258,3766.119873 
	C2432.741455,3765.123535 2432.863525,3763.954834 2433.442871,3762.387207 
z"
        />
        <path
          fill="#7A7E89"
          opacity="1.000000"
          stroke="none"
          d="
M570.759155,1794.477661 
	C562.238647,1791.079102 554.085144,1790.290649 545.018433,1789.833740 
	C544.043579,1786.917725 544.522827,1783.383911 548.030945,1784.362671 
	C558.407898,1787.258301 570.067017,1785.596069 579.657959,1793.497559 
	C579.783752,1794.075562 579.448486,1794.089600 578.610107,1794.117920 
	C576.065735,1795.711060 573.877075,1796.159302 570.759155,1794.477661 
z"
        />
        <path
          fill="#687073"
          opacity="1.000000"
          stroke="none"
          d="
M596.654114,1797.887451 
	C595.958801,1796.792969 595.910339,1795.586060 595.821167,1793.787109 
	C596.284851,1793.096313 596.789246,1792.997437 598.574341,1793.331787 
	C599.893311,1795.668213 599.355652,1797.204712 596.654114,1797.887451 
z"
        />
        <path
          fill="#5A605E"
          opacity="1.000000"
          stroke="none"
          d="
M599.881958,1793.732422 
	C599.855042,1793.764771 599.125793,1793.443115 598.945557,1793.401123 
	C598.765381,1793.359009 599.465820,1793.258057 599.703003,1793.522339 
	C599.940125,1793.786621 599.908875,1793.700195 599.881958,1793.732422 
z"
        />
        <path
          fill="#687073"
          opacity="1.000000"
          stroke="none"
          d="
M838.213013,1894.497559 
	C837.261902,1895.268066 836.212219,1895.322998 834.387329,1895.219971 
	C834.073547,1891.901245 832.304382,1889.745605 830.590027,1887.332764 
	C827.000977,1882.281494 827.380249,1881.735962 835.056885,1879.709717 
	C838.532593,1881.586182 836.827820,1884.351562 837.553833,1887.377808 
	C838.042358,1889.926880 838.078430,1891.854370 838.213013,1894.497559 
z"
        />
        <path
          fill="#6F7981"
          opacity="1.000000"
          stroke="none"
          d="
M838.129456,1887.302979 
	C835.607361,1886.241577 836.096619,1883.600342 835.858765,1880.396729 
	C836.451599,1878.555054 837.191711,1877.290161 838.516724,1877.378174 
	C840.247375,1877.493042 840.135376,1879.192261 839.944214,1880.292480 
	C839.572754,1882.430664 838.837219,1884.505615 838.129456,1887.302979 
z"
        />
        <path
          fill="#49544F"
          opacity="1.000000"
          stroke="none"
          d="
M808.344421,1836.594727 
	C808.946960,1837.775757 809.049072,1838.338379 809.066895,1839.360107 
	C806.583008,1839.754150 803.739319,1840.665161 802.166199,1836.705078 
	C803.987061,1833.638794 805.970947,1833.814209 808.344421,1836.594727 
z"
        />
        <path
          fill="#49544F"
          opacity="1.000000"
          stroke="none"
          d="
M865.617676,1873.431641 
	C865.839783,1875.388428 866.437561,1878.031616 864.864685,1877.960449 
	C862.929871,1877.872559 862.657104,1875.126709 862.025391,1872.654907 
	C863.054016,1872.339600 864.155762,1872.656494 865.617676,1873.431641 
z"
        />
        <path
          fill="#595B54"
          opacity="1.000000"
          stroke="none"
          d="
M821.629333,1836.125366 
	C822.374146,1837.858032 823.121582,1839.923828 821.254517,1841.019043 
	C820.060730,1841.719238 819.113037,1840.427368 818.144531,1838.859009 
	C818.571472,1837.031006 819.596558,1836.330322 821.629333,1836.125366 
z"
        />
        <path
          fill="#49544F"
          opacity="1.000000"
          stroke="none"
          d="
M877.343018,1873.980469 
	C877.688843,1875.946045 878.765930,1878.172729 876.901489,1879.967041 
	C875.459595,1878.669800 876.143372,1876.960449 875.802124,1874.826660 
	C876.110046,1874.126587 876.461487,1874.077515 877.343018,1873.980469 
z"
        />
        <path
          fill="#49544F"
          opacity="1.000000"
          stroke="none"
          d="
M871.513550,1874.001709 
	C871.673340,1875.331299 872.757324,1877.135620 870.719910,1877.889526 
	C870.472595,1876.983398 870.225281,1876.077271 869.978210,1874.589111 
	C870.319519,1874.002441 870.660706,1873.997803 871.513550,1874.001709 
z"
        />
        <path
          fill="#434F51"
          opacity="1.000000"
          stroke="none"
          d="
M979.216064,1828.544678 
	C980.151855,1830.986450 980.261902,1834.210205 983.584778,1832.984253 
	C991.702576,1829.989136 999.264465,1834.530273 1007.138367,1834.085205 
	C1009.263428,1833.965088 1010.526123,1835.309326 1012.026062,1836.822388 
	C1016.356445,1841.190796 1017.637268,1840.568359 1020.042908,1835.484497 
	C1023.681946,1827.794067 1029.108154,1821.880005 1038.298218,1820.970459 
	C1039.247437,1820.876709 1040.838989,1820.688232 1041.092285,1821.134155 
	C1044.947144,1827.923584 1054.587646,1823.998413 1058.651978,1830.745728 
	C1059.555908,1832.246338 1062.763184,1832.359253 1064.700562,1833.037842 
	C1065.542236,1835.081909 1064.590454,1836.532593 1063.979980,1838.043579 
	C1063.132202,1840.141235 1061.429810,1842.407227 1063.959839,1844.310669 
	C1067.209473,1846.755371 1067.407104,1842.983765 1068.923096,1841.684570 
	C1072.310303,1838.781860 1076.513794,1840.324341 1080.361450,1840.036011 
	C1083.967041,1839.765869 1087.246338,1840.106934 1089.761230,1836.142456 
	C1094.317505,1828.960327 1094.659668,1829.177368 1101.126587,1835.940186 
	C1102.236328,1836.452881 1102.691772,1836.814209 1103.601562,1837.919922 
	C1106.076416,1845.420654 1105.397339,1852.260742 1104.510620,1859.919556 
	C1102.918823,1864.254761 1101.669312,1867.859131 1098.553711,1871.369385 
	C1098.024414,1871.975342 1097.964355,1872.035156 1097.932373,1872.066284 
	C1091.876831,1869.603271 1086.851685,1864.728638 1079.944458,1864.377930 
	C1076.622437,1864.209106 1072.102783,1862.127808 1073.057007,1869.092529 
	C1073.529419,1872.540894 1070.006348,1872.285889 1067.947266,1871.551270 
	C1064.251587,1870.232910 1062.402100,1870.836548 1061.653564,1874.967407 
	C1061.130371,1877.853760 1059.134033,1877.201294 1057.145996,1875.988770 
	C1048.379639,1870.641235 1039.351685,1867.331177 1029.376465,1872.979492 
	C1020.417603,1871.385010 1019.153320,1878.633789 1016.898010,1884.403687 
	C1014.513000,1883.577148 1013.886902,1881.807129 1014.172668,1880.252197 
	C1015.396973,1873.591309 1013.160522,1868.062866 1010.373108,1862.060547 
	C1008.273682,1857.539551 1006.098694,1855.794922 1000.817505,1856.055908 
	C1000.022522,1855.741089 1000.013611,1855.400024 1000.001099,1854.547241 
	C998.262024,1852.733276 998.113159,1850.472778 996.445862,1848.436890 
	C995.610962,1846.250488 995.654602,1843.979980 992.358826,1845.571533 
	C982.605713,1843.137329 973.215637,1847.063232 962.927185,1846.020020 
	C960.150635,1845.809326 959.485046,1844.282227 958.258667,1842.474731 
	C954.892578,1841.809937 951.842041,1842.585815 948.484619,1840.638062 
	C946.805542,1838.707886 946.451904,1837.127319 947.501953,1834.673340 
	C947.992676,1833.980469 948.000000,1834.000000 947.990845,1833.995972 
	C950.954712,1830.665527 955.281921,1829.479736 959.505005,1826.784424 
	C962.925781,1825.462280 965.712524,1825.286987 969.104858,1826.616699 
	C970.382507,1827.290039 971.071289,1827.694214 972.281006,1828.217773 
	C973.503357,1828.279907 974.205627,1828.231567 975.430786,1828.205566 
	C976.827271,1828.276245 977.701599,1828.335449 979.216064,1828.544678 
z"
        />
        <path
          fill="#3D494C"
          opacity="1.000000"
          stroke="none"
          d="
M960.281311,1827.428345 
	C958.508728,1833.556396 953.467957,1832.820312 948.737793,1833.979980 
	C947.872009,1827.592407 949.194458,1821.019653 944.570435,1815.301514 
	C943.563354,1814.056274 944.161560,1811.512939 943.993286,1808.724121 
	C949.812317,1806.831543 951.333679,1811.319702 952.222900,1815.251587 
	C952.990417,1818.645386 953.697632,1821.450806 957.605225,1823.356812 
	C958.994080,1824.788696 960.131653,1825.514038 960.281311,1827.428345 
z"
        />
        <path
          fill="#5A605E"
          opacity="1.000000"
          stroke="none"
          d="
M960.768066,1828.012939 
	C959.454651,1827.011597 958.911743,1826.003174 958.216187,1824.441162 
	C959.017883,1823.615845 960.295654,1823.645142 960.874512,1823.023682 
	C964.851318,1818.752319 968.178467,1819.632690 971.480469,1823.884399 
	C972.466736,1825.154175 974.041321,1825.967285 975.649597,1827.603394 
	C975.730225,1828.841675 975.343811,1829.338867 974.371338,1829.936768 
	C973.128174,1830.781860 972.209351,1831.018799 970.601074,1830.425293 
	C967.675842,1827.212402 964.463989,1828.225098 960.768066,1828.012939 
z"
        />
        <path
          fill="#F1E9D8"
          opacity="1.000000"
          stroke="none"
          d="
M916.484985,1793.844727 
	C914.349915,1792.418213 915.196228,1791.439331 916.553406,1790.908813 
	C917.539001,1790.523560 918.495728,1791.219849 918.751221,1792.226196 
	C919.086487,1793.545776 918.005737,1793.769043 916.484985,1793.844727 
z"
        />
        <path
          fill="#3D494C"
          opacity="1.000000"
          stroke="none"
          d="
M947.386902,1833.731567 
	C947.994080,1835.516724 947.995483,1837.053101 947.974731,1839.360596 
	C946.323059,1840.137329 944.669800,1839.964600 943.067810,1840.177612 
	C933.544739,1841.444092 932.216797,1840.177612 932.872498,1828.573975 
	C934.873291,1834.368896 937.188477,1837.714966 942.207153,1833.222778 
	C943.630371,1831.949097 945.285767,1832.774048 947.386902,1833.731567 
z"
        />
        <path
          fill="#3D494C"
          opacity="1.000000"
          stroke="none"
          d="
M957.815918,1842.714355 
	C959.415466,1842.568237 960.614441,1843.567627 961.767090,1845.493408 
	C961.613098,1848.099731 960.861572,1850.395142 958.294189,1849.374878 
	C954.810730,1847.990601 958.000732,1845.413208 957.815918,1842.714355 
z"
        />
        <path
          fill="#313E41"
          opacity="1.000000"
          stroke="none"
          d="
M937.358887,1860.071533 
	C937.814087,1861.778931 937.274902,1863.325317 934.609497,1863.897705 
	C934.157349,1862.193237 934.677185,1860.632690 937.358887,1860.071533 
z"
        />
        <path
          fill="#EFF1EA"
          opacity="1.000000"
          stroke="none"
          d="
M670.587769,1873.760010 
	C669.004211,1872.336182 667.801880,1872.342407 666.207764,1873.778809 
	C663.956360,1873.981689 661.951904,1873.712891 659.203369,1873.299561 
	C656.718018,1872.118530 656.387390,1870.484863 656.989868,1867.889893 
	C657.431396,1867.136841 658.246216,1866.910767 658.668945,1866.920654 
	C660.895630,1867.296875 661.893127,1865.825195 663.744629,1864.836914 
	C664.863220,1864.135254 665.462708,1863.713257 666.555115,1863.046753 
	C668.229431,1862.559204 669.392029,1862.628906 671.275757,1863.498535 
	C672.015564,1863.985840 672.000000,1864.000000 672.007568,1863.992432 
	C674.254578,1866.368652 676.616882,1868.711670 674.243164,1872.980103 
	C672.831238,1873.575439 672.014038,1873.628174 670.587769,1873.760010 
z"
        />
        <path
          fill="#A9AEB3"
          opacity="1.000000"
          stroke="none"
          d="
M674.445068,1873.805054 
	C674.339539,1870.566772 672.893311,1868.043091 672.011230,1864.695312 
	C676.180542,1866.040894 680.383362,1866.943115 685.441162,1864.392822 
	C686.007141,1868.353271 680.392212,1868.351929 680.289917,1873.046753 
	C678.573608,1873.820557 676.907715,1873.953979 674.445068,1873.805054 
z"
        />
        <path
          fill="#A9AEB3"
          opacity="1.000000"
          stroke="none"
          d="
M657.029907,1866.746826 
	C657.701782,1868.641113 657.972107,1870.145386 658.350830,1872.402344 
	C656.818176,1874.105469 655.048645,1874.102905 652.411255,1873.535400 
	C651.732422,1871.194336 651.888855,1869.175415 652.164612,1866.401855 
	C653.732117,1865.883789 655.180298,1866.120239 657.029907,1866.746826 
z"
        />
        <path
          fill="#7B7F84"
          opacity="1.000000"
          stroke="none"
          d="
M628.617676,1875.255615 
	C627.839783,1876.033936 626.836670,1876.128296 625.080933,1876.230469 
	C624.760010,1873.487793 622.734680,1869.765991 628.064026,1868.811523 
	C628.318726,1870.807861 627.627136,1872.636353 628.617676,1875.255615 
z"
        />
        <path
          fill="#A9AEB3"
          opacity="1.000000"
          stroke="none"
          d="
M650.573669,1862.786133 
	C649.112366,1863.605103 647.528137,1863.862671 645.142944,1864.167969 
	C645.750061,1861.624268 647.367249,1859.674683 650.573669,1862.786133 
z"
        />
        <path
          fill="#A9AEB3"
          opacity="1.000000"
          stroke="none"
          d="
M671.999634,1863.445557 
	C670.888611,1863.810669 669.761719,1863.635376 667.841431,1863.131226 
	C668.747375,1860.236328 670.386902,1860.545776 671.999634,1863.445557 
z"
        />
        <path
          fill="#A9AEB3"
          opacity="1.000000"
          stroke="none"
          d="
M663.650818,1864.440430 
	C663.825684,1866.693970 662.414185,1867.536865 659.718262,1867.218018 
	C659.831299,1865.190186 661.368958,1864.657471 663.650818,1864.440430 
z"
        />
        <path
          fill="#4A5448"
          opacity="1.000000"
          stroke="none"
          d="
M578.539062,1853.320801 
	C575.229980,1853.988770 572.452637,1853.996338 568.842529,1853.999512 
	C565.948669,1847.519653 565.733948,1847.387817 559.223145,1848.596924 
	C551.886292,1849.959595 529.387573,1845.615967 522.141418,1840.740234 
	C524.023987,1839.544312 526.123840,1839.024536 524.562195,1836.213623 
	C524.110718,1835.401001 523.225708,1834.646729 522.363098,1834.276001 
	C518.249939,1832.507935 518.566833,1828.511597 517.336426,1825.159424 
	C515.452576,1820.026855 519.192139,1819.423218 522.166992,1817.201050 
	C527.765930,1813.018555 536.797546,1807.584106 544.221558,1805.942749 
	C546.830322,1805.365723 547.947144,1803.374146 549.631592,1801.858276 
	C551.227905,1800.421875 553.921753,1799.345947 554.753967,1801.396729 
	C557.583679,1808.370361 562.367737,1804.180786 567.229126,1804.001099 
	C569.331665,1808.192261 573.701416,1811.483398 571.787109,1816.695679 
	C571.308899,1817.997803 570.793457,1819.187134 569.280518,1819.336304 
	C567.808167,1819.481445 566.807190,1818.684082 566.393005,1817.275391 
	C565.539673,1814.373535 566.550232,1809.635864 561.488037,1810.412598 
	C557.166748,1811.075928 555.250244,1815.123779 554.331421,1818.966187 
	C553.501221,1822.437988 553.481628,1826.067261 552.603210,1829.632202 
	C551.241882,1835.157471 553.202881,1837.581665 559.157043,1837.854370 
	C563.941772,1838.073242 568.464355,1838.045166 571.500977,1843.555908 
	C578.296204,1843.750732 579.899597,1847.224121 578.539062,1853.320801 
z"
        />
        <path
          fill="#595B54"
          opacity="1.000000"
          stroke="none"
          d="
M578.016235,1854.453857 
	C578.415405,1849.862915 576.573242,1846.886475 572.676636,1844.324951 
	C570.338806,1837.610229 574.850891,1832.230103 575.010071,1826.093750 
	C575.064636,1823.990356 577.833740,1823.746704 578.844177,1824.883301 
	C584.711731,1831.484863 589.204956,1828.750244 594.196594,1823.587402 
	C596.846008,1820.847290 599.241577,1822.479004 599.969543,1826.689575 
	C600.911804,1832.139893 597.808350,1837.014038 597.988037,1843.171021 
	C597.986023,1844.013672 598.002197,1843.997314 598.008118,1843.988770 
	C596.416687,1846.412354 594.750183,1848.791016 592.309692,1851.383789 
	C591.645996,1852.234253 591.363403,1852.566528 590.619507,1853.374512 
	C588.771301,1855.017212 587.522949,1856.358398 585.240479,1857.478394 
	C582.655884,1857.951050 580.761902,1858.014404 578.533203,1856.376953 
	C578.017090,1855.871704 578.025208,1854.926392 578.016235,1854.453857 
z"
        />
        <path
          fill="#313E41"
          opacity="1.000000"
          stroke="none"
          d="
M610.580078,1851.711914 
	C609.978455,1849.874634 609.969604,1847.734863 609.973999,1844.792603 
	C611.774658,1844.162354 614.238342,1843.496582 614.907410,1845.601196 
	C615.859375,1848.595947 612.890991,1849.748901 610.580078,1851.711914 
z"
        />
        <path
          fill="#B6BCC1"
          opacity="1.000000"
          stroke="none"
          d="
M781.713989,1888.969604 
	C781.562012,1889.699341 780.819946,1889.787964 780.455078,1889.866699 
	C778.538513,1887.506348 776.347473,1885.214722 778.490967,1881.287598 
	C779.403748,1880.650146 780.079651,1880.703857 781.255249,1880.853638 
	C782.261963,1883.372559 782.791321,1885.795166 781.713989,1888.969604 
z"
        />
        <path
          fill="#6F7981"
          opacity="1.000000"
          stroke="none"
          d="
M809.054321,1876.472290 
	C809.883423,1879.125854 810.884521,1881.682495 809.270447,1884.289429 
	C806.462158,1882.918701 806.373413,1880.354614 806.319336,1877.156494 
	C807.097717,1876.455933 807.812622,1876.403442 809.054321,1876.472290 
z"
        />
        <path
          fill="#46565D"
          opacity="1.000000"
          stroke="none"
          d="
M716.994324,1877.060059 
	C716.967773,1877.088135 717.020874,1877.031860 716.994324,1877.060059 
z"
        />
        <path
          fill="#595B54"
          opacity="1.000000"
          stroke="none"
          d="
M806.129639,1866.762207 
	C806.482849,1866.274414 807.103455,1866.433472 807.930176,1867.333374 
	C807.576416,1867.689209 806.996643,1867.467529 806.129639,1866.762207 
z"
        />
        <path
          fill="#49544F"
          opacity="1.000000"
          stroke="none"
          d="
M886.565002,1887.892090 
	C887.164795,1889.055542 887.185303,1890.002563 887.214050,1891.659912 
	C887.116760,1893.025513 886.984009,1893.675049 886.536987,1894.725586 
	C884.507629,1895.187500 882.765259,1895.242798 880.191345,1895.002197 
	C879.539856,1892.767822 879.692017,1890.826294 879.905273,1888.891479 
	C880.650879,1882.126221 883.501038,1885.796387 886.565002,1887.892090 
z"
        />
        <path
          fill="#595B54"
          opacity="1.000000"
          stroke="none"
          d="
M901.459839,1880.408203 
	C902.271545,1879.974121 902.611511,1879.971680 903.460876,1879.955933 
	C903.967896,1880.590332 904.012756,1881.242554 903.953613,1881.885010 
	C903.806519,1883.482788 903.584534,1885.630005 901.477417,1884.955322 
	C899.569824,1884.344482 900.190918,1882.356323 901.459839,1880.408203 
z"
        />
        <path
          fill="#595B54"
          opacity="1.000000"
          stroke="none"
          d="
M902.218445,1895.837891 
	C901.842102,1896.660767 901.418762,1896.750122 900.363159,1896.689941 
	C900.177307,1894.412964 899.498108,1892.075317 900.913330,1890.067993 
	C902.827576,1891.416870 901.778442,1893.441040 902.218445,1895.837891 
z"
        />
        <path
          fill="#5A605E"
          opacity="1.000000"
          stroke="none"
          d="
M996.025146,1848.705444 
	C998.844482,1848.253906 1000.017212,1849.920898 999.984741,1853.332764 
	C997.168335,1853.783447 995.993469,1852.115479 996.025146,1848.705444 
z"
        />
        <path
          fill="#49544F"
          opacity="1.000000"
          stroke="none"
          d="
M833.526733,1785.831543 
	C833.985901,1785.240723 834.362732,1785.166992 835.314575,1785.047363 
	C835.869141,1786.716064 836.141541,1788.550415 833.452759,1789.479980 
	C832.828247,1789.531738 832.600159,1788.853760 832.516785,1788.505859 
	C832.641907,1787.483154 832.971802,1786.876099 833.526733,1785.831543 
z"
        />
        <path
          fill="#646A6C"
          opacity="1.000000"
          stroke="none"
          d="
M833.228271,1785.233398 
	C833.437622,1785.990112 833.266235,1786.665771 832.764099,1787.749756 
	C832.338135,1787.638428 832.242798,1787.118896 832.115112,1785.802246 
	C832.082764,1785.005371 832.847473,1785.152222 833.228271,1785.233398 
z"
        />
        <path
          fill="#D0D1C8"
          opacity="1.000000"
          stroke="none"
          d="
M396.756927,1783.213501 
	C396.985870,1779.426270 397.150635,1776.506470 397.154205,1772.876221 
	C396.322296,1770.544434 396.157562,1768.911499 397.432190,1766.619507 
	C404.267975,1766.048584 410.556580,1765.994995 416.835571,1766.215210 
	C424.487640,1766.483765 424.227875,1766.600952 424.744629,1774.128540 
	C424.972015,1777.440552 422.678040,1781.450928 427.349670,1784.070312 
	C434.371918,1782.972046 439.597595,1787.001831 445.956543,1787.280029 
	C446.846588,1784.570557 447.802246,1782.726318 449.274078,1780.440430 
	C454.378113,1776.401245 459.970490,1776.585938 465.454834,1776.285645 
	C469.681458,1776.054321 473.906677,1775.796509 479.041626,1775.697021 
	C481.047394,1777.418091 480.019562,1778.387573 478.504364,1779.889893 
	C470.603973,1783.099121 468.043518,1786.092041 467.764526,1792.462280 
	C467.414246,1800.460449 467.782684,1800.728516 477.380280,1799.092773 
	C476.836243,1794.367920 476.271301,1789.500488 479.757202,1785.579590 
	C484.132690,1789.719727 480.675415,1795.010986 483.238281,1799.841064 
	C483.904541,1801.907349 484.209534,1803.292114 484.302856,1805.367920 
	C473.512115,1810.629883 462.464996,1810.182251 450.539001,1808.676514 
	C438.519928,1810.112793 427.326996,1809.606934 416.048187,1808.809814 
	C408.975189,1808.310059 401.794220,1809.248413 394.667633,1809.647583 
	C392.360443,1809.776733 390.034332,1810.514771 387.807861,1809.460693 
	C386.332458,1808.762085 384.717102,1807.964111 384.685455,1805.999878 
	C384.653778,1804.034424 386.131165,1802.708008 387.712677,1802.620605 
	C396.173187,1802.152100 396.865204,1796.598633 396.421875,1790.044556 
	C396.288452,1788.071655 396.590271,1786.069580 396.756927,1783.213501 
z"
        />
        <path
          fill="#A9ACA9"
          opacity="1.000000"
          stroke="none"
          d="
M449.688965,1808.790039 
	C460.333893,1804.311035 471.375946,1806.704468 483.151825,1806.054321 
	C484.112091,1810.353027 480.334473,1809.948242 477.865082,1810.792969 
	C473.421539,1812.312622 469.264160,1813.612427 469.296692,1819.587769 
	C469.314087,1822.781738 466.254913,1824.356812 464.024658,1827.122803 
	C462.952545,1829.087280 461.810516,1830.088135 460.292114,1831.488281 
	C459.832611,1831.818481 459.641235,1832.722656 459.550781,1833.174683 
	C456.848419,1834.442627 454.236481,1835.258423 450.938354,1836.150391 
	C450.918945,1829.926025 455.399200,1824.420410 453.964478,1817.635254 
	C453.494537,1815.412720 453.603577,1813.118164 450.287231,1811.794434 
	C449.554321,1810.800659 449.607025,1810.069946 449.688965,1808.790039 
z"
        />
        <path
          fill="#687073"
          opacity="1.000000"
          stroke="none"
          d="
M507.048950,1793.972656 
	C507.996887,1796.039673 508.167969,1798.124023 508.371399,1800.990967 
	C504.489075,1802.519287 499.942535,1800.629272 497.246002,1805.953003 
	C495.965027,1808.481934 491.019989,1806.488525 490.962158,1803.411011 
	C490.919830,1801.159180 491.725983,1798.902344 492.030609,1796.631226 
	C492.979492,1789.556763 493.033478,1789.422729 499.616119,1792.580200 
	C501.809448,1793.632446 503.946411,1794.026001 507.048950,1793.972656 
z"
        />
        <path
          fill="#7B7F84"
          opacity="1.000000"
          stroke="none"
          d="
M483.713989,1799.822144 
	C480.713226,1800.131592 479.792755,1798.304932 479.884338,1795.612061 
	C479.974854,1792.951294 479.931976,1790.286133 480.075623,1786.771484 
	C481.440704,1786.286499 482.798035,1786.440552 483.891693,1787.060059 
	C486.820038,1788.718994 490.245422,1790.327637 485.041473,1793.663330 
	C483.257751,1794.806885 484.190521,1797.271729 483.713989,1799.822144 
z"
        />
        <path
          fill="#EFF1EA"
          opacity="1.000000"
          stroke="none"
          d="
M478.787415,1780.649414 
	C478.536194,1779.289917 479.017181,1778.146484 479.724426,1776.423340 
	C483.672455,1775.291870 487.326721,1775.457275 491.569000,1777.223389 
	C492.133148,1778.372925 491.991974,1779.169434 491.836731,1780.570679 
	C487.702820,1781.819214 483.607239,1781.500732 478.787415,1780.649414 
z"
        />
        <path
          fill="#687073"
          opacity="1.000000"
          stroke="none"
          d="
M570.048462,1794.716309 
	C572.189575,1793.982178 574.339172,1794.006226 577.297852,1794.081299 
	C578.092529,1794.462402 578.104370,1794.796021 578.059692,1795.121948 
	C577.721802,1797.585938 578.752197,1801.158325 575.058167,1801.469971 
	C571.314453,1801.786011 570.187073,1798.778198 570.048462,1794.716309 
z"
        />
        <path
          fill="#83837F"
          opacity="1.000000"
          stroke="none"
          d="
M460.500427,1831.769897 
	C460.033356,1830.091919 460.799622,1828.762939 463.046417,1827.882446 
	C463.784943,1829.635376 463.424957,1831.256836 460.500427,1831.769897 
z"
        />
        <path
          fill="#AAB7BE"
          opacity="1.000000"
          stroke="none"
          d="
M444.572388,1762.312012 
	C443.908447,1755.600342 440.268860,1749.023193 445.132507,1742.918457 
	C446.110596,1741.690674 446.635956,1739.938965 449.056671,1739.972412 
	C451.179230,1748.605591 448.667267,1757.299683 449.176605,1766.603149 
	C445.697174,1767.723877 446.678680,1764.010010 444.572388,1762.312012 
z"
        />
        <path
          fill="#7A7E89"
          opacity="1.000000"
          stroke="none"
          d="
M448.964233,1780.116211 
	C450.421204,1782.441406 449.427673,1784.318970 446.965485,1786.257202 
	C446.119232,1784.255615 445.516144,1781.776123 448.964233,1780.116211 
z"
        />
        <path
          fill="#7A7E89"
          opacity="1.000000"
          stroke="none"
          d="
M484.953674,1758.914307 
	C485.040405,1758.867432 484.866974,1758.961304 484.953674,1758.914307 
z"
        />
        <path
          fill="#4A5448"
          opacity="1.000000"
          stroke="none"
          d="
M345.028870,1803.781860 
	C342.629700,1806.202515 340.461517,1808.424316 337.827606,1809.447998 
	C335.231903,1810.456909 331.803009,1811.115234 330.483032,1807.112061 
	C329.396088,1803.815674 331.505157,1800.609131 335.405701,1800.184448 
	C338.879608,1799.806274 342.763428,1799.043457 345.028870,1803.781860 
z"
        />
        <path
          fill="#4A5448"
          opacity="1.000000"
          stroke="none"
          d="
M325.778809,1810.749390 
	C324.458679,1812.308350 322.792969,1811.968628 321.168427,1811.794678 
	C320.150024,1811.685547 319.110199,1811.200562 319.199005,1810.059204 
	C319.334229,1808.321045 320.773163,1808.081299 322.076202,1808.458862 
	C323.312195,1808.817139 324.423309,1809.606201 325.778809,1810.749390 
z"
        />
        <path
          fill="#646A6C"
          opacity="1.000000"
          stroke="none"
          d="
M397.525085,1765.461182 
	C397.785522,1767.536499 397.587433,1769.092041 397.191162,1771.406738 
	C396.094269,1771.825195 395.116547,1771.613037 394.312714,1771.118286 
	C392.481903,1769.991089 389.575562,1769.194824 390.620514,1766.333618 
	C391.818085,1763.054688 394.575134,1763.899536 397.525085,1765.461182 
z"
        />
        <path
          fill="#6F7981"
          opacity="1.000000"
          stroke="none"
          d="
M448.610352,1717.645020 
	C449.072449,1714.584473 450.483612,1713.853394 452.646423,1715.199097 
	C453.541718,1715.755981 453.754395,1716.856689 453.303986,1717.767578 
	C452.109558,1720.183350 450.482025,1719.881104 448.610352,1717.645020 
z"
        />
        <path
          fill="#6F7981"
          opacity="1.000000"
          stroke="none"
          d="
M439.599792,1717.056152 
	C439.391357,1713.526489 441.566772,1714.136963 443.437195,1714.516113 
	C444.464050,1714.724243 445.145874,1715.845825 444.538727,1716.700195 
	C443.420654,1718.273682 441.675201,1717.936157 439.599792,1717.056152 
z"
        />
        <path
          fill="#797771"
          opacity="1.000000"
          stroke="none"
          d="
M230.967926,1700.098633 
	C230.518478,1698.706299 230.444275,1697.917969 230.312592,1696.537964 
	C233.093109,1693.201660 231.323242,1689.708252 231.774994,1686.576294 
	C232.332626,1682.710205 230.845261,1678.671021 233.452759,1674.444824 
	C234.023010,1681.911621 233.898483,1689.851685 233.691086,1698.689209 
	C233.126755,1700.513916 232.358185,1700.858398 230.967926,1700.098633 
z"
        />
        <path
          fill="#C4BDAE"
          opacity="1.000000"
          stroke="none"
          d="
M230.368774,1700.390381 
	C231.262695,1699.541504 231.932693,1699.588501 233.105438,1699.611084 
	C233.402054,1703.425781 233.195923,1707.264771 232.562256,1711.530518 
	C232.134735,1711.957153 231.262863,1712.100464 230.829056,1712.180176 
	C230.311783,1708.602051 230.228317,1704.944214 230.368774,1700.390381 
z"
        />
        <path
          fill="#0C0D0F"
          opacity="1.000000"
          stroke="none"
          d="
M1319.655273,2282.472656 
	C1325.948486,2279.587891 1329.140747,2274.738281 1331.970459,2268.754883 
	C1338.159424,2269.970947 1343.001465,2265.520264 1350.300049,2265.300293 
	C1345.321045,2260.865234 1344.041016,2256.006592 1342.196289,2250.597168 
	C1340.959106,2249.178955 1340.299805,2248.261475 1340.092163,2246.473145 
	C1340.071655,2245.893311 1340.062744,2245.081543 1340.037354,2244.676270 
	C1341.375488,2242.610596 1343.645508,2242.317627 1345.596069,2240.436523 
	C1346.043945,2240.005859 1345.992432,2239.992432 1345.998535,2240.018555 
	C1347.055420,2238.938232 1348.146851,2237.872070 1349.651855,2236.411133 
	C1350.072266,2236.022949 1349.994141,2235.997070 1350.009033,2236.035400 
	C1350.786743,2235.149170 1351.694336,2234.436523 1353.462402,2234.079590 
	C1357.369019,2234.012939 1360.701538,2233.805176 1364.006836,2234.019043 
	C1368.213501,2234.291016 1370.941895,2232.367432 1373.440674,2228.423828 
	C1376.456543,2229.763672 1376.895996,2232.499512 1376.484497,2235.072510 
	C1375.757690,2239.615723 1377.127319,2242.638428 1380.290649,2246.212891 
	C1385.437378,2252.028076 1380.205322,2264.104248 1372.497681,2265.933350 
	C1369.217407,2266.711670 1368.466309,2264.471436 1368.045898,2262.118652 
	C1367.523804,2259.195801 1369.121460,2256.325684 1368.501709,2253.236084 
	C1367.979980,2255.270264 1368.151367,2257.296387 1367.142212,2259.788330 
	C1360.695190,2266.744873 1351.847290,2270.438232 1351.785400,2281.979492 
	C1351.752808,2288.052002 1344.764771,2293.159180 1340.765625,2298.049072 
	C1337.729736,2301.761230 1331.225830,2295.454102 1326.199463,2293.326172 
	C1326.850098,2296.061035 1329.445190,2295.543457 1331.329834,2296.283936 
	C1335.677490,2297.993164 1336.634644,2300.781738 1332.190918,2303.162354 
	C1323.492432,2307.822266 1328.108276,2312.565918 1332.370117,2316.149170 
	C1336.754639,2319.835693 1335.906372,2322.752197 1335.207520,2327.904541 
	C1333.651001,2339.381104 1332.505249,2351.076904 1332.525879,2362.729980 
	C1332.532959,2366.748535 1335.731445,2370.035645 1338.819458,2372.577148 
	C1342.735840,2375.800781 1345.552734,2379.524414 1347.939819,2384.083496 
	C1350.690308,2389.337158 1354.960449,2393.067139 1361.030640,2387.022705 
	C1366.758057,2381.320068 1369.020264,2391.384766 1374.577148,2390.359375 
	C1386.279785,2397.980713 1383.783936,2408.903076 1382.782959,2420.161133 
	C1380.696533,2427.950928 1378.792358,2435.010010 1375.219727,2442.243164 
	C1369.641602,2448.835449 1365.893433,2446.868408 1363.051392,2440.597412 
	C1361.550781,2437.286133 1360.416626,2433.817139 1358.351074,2430.760498 
	C1355.212402,2426.116211 1352.561279,2420.562988 1346.002319,2427.162109 
	C1344.078003,2429.097900 1341.310303,2428.605225 1340.216797,2426.018066 
	C1337.662109,2419.973633 1331.777466,2414.790283 1332.259888,2408.191162 
	C1333.085815,2396.892334 1327.706543,2387.145264 1325.765747,2376.178955 
	C1323.155884,2373.299316 1323.267944,2370.033936 1322.006470,2366.520020 
	C1321.182617,2365.144043 1320.755981,2364.325439 1320.105957,2362.822754 
	C1319.258789,2360.336914 1319.005859,2358.419189 1318.090820,2355.925537 
	C1317.514282,2353.275146 1317.561890,2351.329102 1317.984375,2348.682617 
	C1320.823486,2336.062500 1321.877808,2324.619385 1309.070312,2316.517090 
	C1310.697754,2312.353760 1312.404297,2308.861328 1314.121460,2305.374268 
	C1316.900513,2299.730225 1318.555664,2293.946533 1316.600586,2286.962402 
	C1317.495117,2285.035889 1318.021851,2283.746338 1319.655273,2282.472656 
z"
        />
        <path
          fill="#61798F"
          opacity="1.000000"
          stroke="none"
          d="
M1175.340576,2265.387695 
	C1185.909912,2265.371826 1195.683594,2265.045654 1205.114746,2261.017578 
	C1210.927979,2258.535156 1217.637939,2259.584717 1223.875366,2260.441406 
	C1236.676147,2262.199463 1249.433716,2264.585693 1262.407959,2263.880127 
	C1277.083374,2263.082275 1291.474365,2267.300049 1307.070557,2265.993896 
	C1310.239136,2266.239990 1312.701782,2265.410645 1315.169312,2267.842773 
	C1311.733276,2271.557861 1307.350830,2271.798096 1302.819336,2272.012695 
	C1291.477539,2272.549805 1280.290039,2270.389404 1269.118652,2269.336182 
	C1259.817261,2268.459473 1250.577515,2268.425781 1241.312378,2267.996582 
	C1235.695923,2267.736572 1229.998779,2268.747070 1224.508301,2266.561768 
	C1222.269043,2265.670654 1219.820557,2265.988281 1217.744629,2267.236572 
	C1212.905518,2270.146484 1207.516479,2269.446533 1202.271118,2270.126709 
	C1184.220337,2272.466064 1165.963745,2271.593262 1147.883911,2275.111084 
	C1138.494507,2276.938232 1128.679932,2276.177490 1119.125854,2279.581299 
	C1113.511963,2281.581787 1106.587280,2280.157227 1100.273682,2279.841064 
	C1096.653564,2279.660156 1092.727783,2278.907959 1090.328491,2275.933350 
	C1086.597412,2271.307861 1082.819580,2271.095459 1078.269287,2274.446777 
	C1077.243164,2275.202393 1075.883911,2275.616455 1074.613770,2275.910889 
	C1070.025024,2276.975586 1068.582642,2278.927734 1073.128052,2281.950195 
	C1075.333130,2283.416748 1075.516602,2284.784180 1074.419067,2286.841309 
	C1073.561768,2288.447998 1072.382568,2289.475098 1070.459351,2289.081299 
	C1059.238281,2286.784180 1047.887451,2288.597168 1036.616821,2287.960938 
	C1034.781128,2287.857422 1032.522949,2288.625488 1031.140503,2285.625732 
	C1033.063843,2282.773926 1036.115479,2281.740723 1039.425659,2282.091797 
	C1044.392578,2282.619141 1049.115112,2279.582031 1054.181274,2281.474365 
	C1055.928711,2282.126953 1057.520630,2280.858154 1057.402710,2278.831543 
	C1057.287354,2276.853271 1055.625732,2276.285645 1053.852417,2276.110107 
	C1051.103760,2275.837402 1048.651245,2277.217285 1046.062256,2277.521729 
	C1039.901123,2278.246582 1033.679199,2278.571045 1025.246826,2276.732666 
	C1033.200928,2272.739258 1039.455566,2270.505615 1046.200684,2271.231445 
	C1054.315796,2272.104492 1061.967651,2270.691406 1069.291992,2267.645996 
	C1072.205322,2266.434570 1074.981079,2266.122559 1077.963989,2265.978271 
	C1081.586060,2265.803223 1085.302124,2266.689941 1089.405273,2264.551270 
	C1093.212769,2260.401367 1097.543213,2260.792480 1102.611328,2260.825928 
	C1108.040405,2261.189209 1112.606079,2261.022217 1117.992188,2261.210938 
	C1120.446777,2261.414551 1121.983276,2261.874023 1124.053955,2263.095703 
	C1126.470703,2265.141357 1127.999146,2267.209717 1131.589844,2266.854736 
	C1143.227905,2266.677246 1153.856445,2264.412598 1165.443848,2263.084473 
	C1169.261963,2262.785400 1172.117798,2263.040771 1175.340576,2265.387695 
z"
        />
        <path
          fill="#5B768D"
          opacity="1.000000"
          stroke="none"
          d="
M1025.181885,2231.523438 
	C1029.019287,2230.329102 1032.261719,2229.808105 1035.656738,2229.999023 
	C1049.232788,2230.763184 1062.867310,2228.554199 1076.447754,2231.510010 
	C1082.146973,2232.750488 1086.557861,2228.656006 1089.494751,2224.124268 
	C1091.107910,2221.635010 1092.429199,2219.972656 1095.521362,2219.994873 
	C1112.506470,2220.118164 1129.491943,2220.154053 1146.476807,2220.284180 
	C1147.373901,2220.291016 1148.471191,2220.666748 1148.377075,2222.499023 
	C1132.532959,2228.494141 1115.720459,2222.225342 1099.400635,2224.573486 
	C1100.057739,2228.511475 1102.429321,2227.956787 1104.172485,2227.885254 
	C1127.466309,2226.931396 1150.591187,2230.433838 1173.896729,2230.468018 
	C1185.233765,2230.484375 1197.081909,2230.775879 1208.443726,2233.618652 
	C1210.969116,2234.250488 1213.742065,2233.972168 1216.401245,2233.976318 
	C1232.724976,2234.002197 1249.048950,2233.943115 1265.372192,2234.030029 
	C1268.446777,2234.046387 1271.821533,2233.151123 1275.270996,2235.832275 
	C1272.703735,2238.505859 1269.989868,2237.950928 1267.565063,2237.953857 
	C1238.248901,2237.989990 1208.931030,2238.135010 1179.617554,2237.853760 
	C1171.698364,2237.777832 1163.728027,2236.756348 1155.893433,2235.479980 
	C1141.004150,2233.053955 1126.143677,2233.632324 1110.630981,2235.463867 
	C1105.515991,2238.736328 1100.514038,2237.853760 1094.817017,2238.034668 
	C1087.421753,2243.121582 1080.104614,2241.097168 1072.969849,2240.187744 
	C1060.847046,2238.643066 1048.751221,2236.771729 1035.603516,2239.235352 
	C1031.033203,2238.525146 1027.639404,2240.103271 1024.116211,2240.293701 
	C1019.974304,2240.517822 1015.322876,2239.445312 1015.490234,2234.679688 
	C1015.687866,2229.050049 1020.465271,2230.114990 1025.181885,2231.523438 
z"
        />
        <path
          fill="#61798F"
          opacity="1.000000"
          stroke="none"
          d="
M1137.999634,2321.286621 
	C1131.836304,2322.398682 1125.714966,2323.143799 1119.458374,2322.124756 
	C1115.294067,2321.446777 1111.718628,2321.840576 1112.757446,2328.384033 
	C1113.653442,2334.028320 1108.251465,2331.188477 1104.792603,2332.000488 
	C1105.717651,2323.123779 1105.711426,2323.168457 1096.795532,2321.939209 
	C1094.559204,2321.630859 1092.398560,2320.787109 1090.163940,2320.448975 
	C1086.220825,2319.853027 1082.234131,2318.794434 1082.943726,2325.604248 
	C1083.248535,2328.529297 1079.605835,2327.724854 1076.751709,2328.004395 
	C1073.518799,2327.209473 1070.890137,2326.750977 1068.371582,2324.542480 
	C1068.182373,2322.275635 1069.124023,2320.984375 1070.158081,2319.629395 
	C1072.293457,2316.830811 1070.391479,2315.195801 1067.957153,2314.541504 
	C1065.760986,2313.951172 1063.351929,2314.147461 1061.036133,2314.008301 
	C1056.599976,2313.741943 1051.629639,2315.359863 1048.989746,2309.597900 
	C1048.075073,2307.601318 1045.372314,2307.732666 1043.574829,2308.595215 
	C1030.395386,2314.919434 1016.903564,2315.267334 1002.553284,2310.541260 
	C1003.187683,2302.630615 1009.137634,2303.597168 1014.210571,2303.018066 
	C1022.353760,2302.088623 1030.795410,2304.993164 1039.354248,2300.560547 
	C1040.245972,2297.888184 1039.549927,2295.171875 1042.595581,2294.565430 
	C1045.110962,2294.064697 1045.519531,2296.376465 1046.475708,2297.979248 
	C1046.921753,2298.726807 1047.901733,2299.155762 1049.322266,2299.869385 
	C1052.616211,2303.369629 1056.273193,2303.192383 1059.930176,2302.887939 
	C1068.600708,2302.165771 1076.935547,2303.634277 1085.632935,2307.093750 
	C1088.033936,2310.654785 1089.323364,2313.658447 1094.355591,2312.519043 
	C1100.828491,2311.052979 1107.612915,2316.021973 1114.777466,2311.808594 
	C1116.142822,2310.912842 1117.017700,2310.607178 1118.634521,2310.416992 
	C1122.873657,2310.098145 1126.876953,2314.546387 1130.419800,2308.846680 
	C1132.026001,2307.367432 1133.312744,2306.913330 1135.405884,2307.598145 
	C1133.483398,2312.698486 1138.060425,2316.278809 1137.999634,2321.286621 
z"
        />
        <path
          fill="#597288"
          opacity="1.000000"
          stroke="none"
          d="
M1136.756836,2307.664551 
	C1134.903198,2308.351074 1133.806396,2308.702148 1132.354858,2309.521729 
	C1129.021606,2313.314697 1125.128052,2311.858398 1120.747314,2311.984131 
	C1119.080933,2311.977539 1118.206177,2311.979736 1116.675537,2311.986084 
	C1112.931152,2315.080078 1109.237915,2314.166748 1104.774170,2313.136230 
	C1102.600708,2311.179199 1102.402222,2309.427246 1103.480957,2306.772461 
	C1106.361328,2306.002686 1108.659790,2305.845703 1110.626709,2304.290039 
	C1111.934937,2303.255371 1113.430176,2301.967041 1115.090942,2302.415283 
	C1127.134277,2305.666504 1138.637085,2301.251465 1150.371216,2300.159424 
	C1153.677124,2299.852051 1156.960327,2300.075195 1160.244141,2301.029785 
	C1166.100952,2302.733154 1172.146729,2302.271973 1178.070190,2301.511719 
	C1200.577881,2298.622314 1223.123169,2300.068604 1245.663940,2300.651123 
	C1245.904419,2300.657227 1246.135254,2301.034912 1247.077271,2301.852051 
	C1243.354980,2304.990967 1238.905396,2305.915771 1234.688354,2305.825195 
	C1215.737915,2305.419434 1196.986084,2308.722168 1177.993774,2308.267822 
	C1167.992310,2308.028320 1158.249268,2305.718018 1148.279663,2306.005615 
	C1144.652344,2306.110107 1140.925293,2305.451660 1136.756836,2307.664551 
z"
        />
        <path
          fill="#597288"
          opacity="1.000000"
          stroke="none"
          d="
M1039.062744,2300.001953 
	C1039.549194,2304.677734 1036.548096,2306.028076 1032.364868,2305.996094 
	C1026.365479,2305.950195 1020.364197,2306.076416 1014.366150,2305.977295 
	C1010.289978,2305.910156 1006.359741,2306.152832 1002.501465,2309.414062 
	C995.712280,2310.145020 989.363770,2309.463623 983.240173,2311.692627 
	C982.042664,2312.128662 980.588135,2312.094727 979.283569,2311.964600 
	C964.108826,2310.446777 948.938110,2312.611572 933.767761,2311.965088 
	C932.110718,2311.894531 930.411133,2311.939209 928.820862,2311.557129 
	C928.023315,2311.365723 927.151611,2310.453613 926.802429,2309.654053 
	C926.204529,2308.284912 926.977356,2307.123535 928.095459,2306.263428 
	C931.234314,2303.848389 934.924927,2304.001709 938.597290,2304.001709 
	C954.471191,2304.001709 970.345215,2303.998291 986.218994,2303.944092 
	C989.820618,2303.931641 993.495605,2304.451172 996.583435,2301.580078 
	C998.986145,2299.346436 1002.245972,2300.013184 1005.194885,2299.999268 
	C1016.171509,2299.947266 1027.148682,2299.988037 1039.062744,2300.001953 
z"
        />
        <path
          fill="#70889D"
          opacity="1.000000"
          stroke="none"
          d="
M1033.543213,2236.581787 
	C1044.928833,2236.080322 1055.805786,2236.051758 1066.682617,2236.022461 
	C1075.231079,2235.999512 1083.527222,2239.189697 1093.070801,2238.036133 
	C1095.301025,2245.763184 1095.310791,2245.453125 1088.205933,2245.229248 
	C1082.323975,2245.043945 1076.416748,2245.738770 1070.517944,2245.977783 
	C1066.491821,2246.141113 1062.939331,2245.762207 1059.742188,2242.341797 
	C1056.694580,2239.081543 1048.074951,2238.639893 1044.985596,2241.405029 
	C1036.436279,2249.056885 1026.114868,2248.449219 1016.053650,2247.672852 
	C1010.525696,2247.246094 1005.010315,2245.862305 999.373779,2245.755859 
	C997.927490,2245.728760 997.017212,2244.726318 996.191772,2242.724121 
	C998.312439,2239.237793 1001.492676,2239.089844 1005.449402,2239.038086 
	C1011.138245,2238.697021 1015.991150,2238.162354 1020.948547,2239.811523 
	C1025.243164,2241.240234 1029.443726,2239.914307 1033.543213,2236.581787 
z"
        />
        <path
          fill="#3C556B"
          opacity="1.000000"
          stroke="none"
          d="
M1261.268921,2317.848145 
	C1261.490356,2321.251709 1259.151611,2322.016357 1256.228760,2321.910889 
	C1235.040161,2321.145752 1214.011108,2325.401367 1192.809326,2323.937256 
	C1189.553589,2323.712402 1185.020874,2325.617432 1184.287842,2319.128418 
	C1205.401733,2315.786621 1226.547974,2315.745361 1247.711670,2316.071533 
	C1252.034546,2316.138184 1256.315063,2316.241455 1261.268921,2317.848145 
z"
        />
        <path
          fill="#5B768D"
          opacity="1.000000"
          stroke="none"
          d="
M1008.450562,2241.085938 
	C1004.532166,2241.957764 1001.118469,2241.964111 996.851074,2241.965820 
	C995.042725,2241.355469 994.087952,2240.750000 992.541260,2240.125488 
	C967.366516,2240.061768 942.783508,2240.031006 918.200745,2239.954346 
	C915.232849,2239.945068 912.188110,2240.414551 909.332886,2239.228516 
	C908.100037,2238.716064 907.058777,2237.815430 907.195374,2236.269775 
	C907.346191,2234.562744 908.752930,2234.181885 910.050598,2234.155273 
	C915.680054,2234.039551 921.327759,2233.512939 926.923584,2234.662109 
	C929.184692,2235.126465 931.708618,2236.385010 933.685608,2235.810547 
	C943.598694,2232.931152 953.351501,2236.541504 963.194275,2236.061523 
	C972.805725,2235.592529 982.457153,2235.941162 993.025696,2235.919922 
	C1003.394287,2231.361816 1006.205872,2232.170166 1008.450562,2241.085938 
z"
        />
        <path
          fill="#597288"
          opacity="1.000000"
          stroke="none"
          d="
M1067.177246,2324.002930 
	C1070.735840,2324.354492 1074.064819,2322.966553 1075.854248,2327.305176 
	C1075.776611,2328.245850 1075.496460,2328.719482 1075.316772,2328.684326 
	C1064.353149,2326.519531 1053.552612,2331.887695 1042.313843,2328.779297 
	C1035.810913,2326.980469 1028.483887,2328.164795 1021.527893,2327.999268 
	C1018.877563,2327.936035 1016.223938,2327.915527 1013.579224,2327.754395 
	C1012.170105,2327.668701 1010.994629,2326.925537 1010.780273,2325.433105 
	C1010.480530,2323.346436 1011.880310,2322.541016 1013.650452,2322.202881 
	C1014.297791,2322.079590 1014.994385,2321.930176 1015.624390,2322.041748 
	C1027.253296,2324.100586 1039.292358,2320.733643 1050.698364,2324.267822 
	C1056.204590,2325.973877 1061.150635,2323.307861 1067.177246,2324.002930 
z"
        />
        <path
          fill="#597288"
          opacity="1.000000"
          stroke="none"
          d="
M1289.193604,2317.048584 
	C1295.172852,2315.646729 1300.312500,2319.118652 1306.718994,2317.894043 
	C1308.913208,2318.473145 1309.715698,2319.623535 1310.698730,2321.529297 
	C1311.384155,2323.034668 1311.779419,2323.894287 1312.554565,2325.368164 
	C1314.993896,2329.404541 1316.934448,2332.853516 1315.360474,2337.761230 
	C1312.628418,2341.452148 1309.613403,2344.215088 1305.949829,2341.055176 
	C1301.220947,2336.976562 1295.875244,2336.887451 1290.226929,2336.675293 
	C1285.596680,2336.501221 1279.433472,2335.873291 1279.514526,2330.301270 
	C1279.607788,2323.881104 1286.151855,2325.667725 1290.481445,2325.247559 
	C1291.749146,2325.124268 1293.027832,2325.688232 1294.373047,2325.274170 
	C1293.074951,2324.405762 1291.498291,2324.182617 1289.770508,2322.693359 
	C1288.464844,2320.744629 1288.341309,2319.252197 1289.193604,2317.048584 
z"
        />
        <path
          fill="#2E2D30"
          opacity="1.000000"
          stroke="none"
          d="
M1350.757812,2219.871094 
	C1355.447632,2223.962158 1352.223755,2226.286621 1348.822510,2227.655762 
	C1346.395386,2228.632568 1342.525757,2228.779785 1342.226440,2231.179443 
	C1341.362305,2238.108398 1333.911011,2240.993652 1333.024414,2247.856689 
	C1332.644409,2250.799072 1334.519897,2251.902832 1335.455811,2253.837402 
	C1339.029785,2261.223877 1337.989258,2262.638428 1329.636108,2262.064209 
	C1328.324097,2261.974121 1327.002441,2262.027588 1324.924561,2262.026367 
	C1323.991821,2257.923096 1328.280518,2255.587402 1328.346924,2250.841797 
	C1328.758423,2249.229736 1329.514282,2248.342773 1329.518799,2247.452393 
	C1329.591064,2233.187256 1339.089844,2226.040771 1350.757812,2219.871094 
z"
        />
        <path
          fill="#597288"
          opacity="1.000000"
          stroke="none"
          d="
M1086.708130,2310.494629 
	C1077.223511,2305.666992 1067.789917,2305.970459 1058.370605,2305.952148 
	C1055.005371,2305.945557 1050.719482,2307.149902 1050.046631,2300.828369 
	C1053.644897,2299.917725 1057.305054,2300.250732 1060.804077,2298.696777 
	C1066.208984,2296.296631 1071.040039,2295.498779 1076.177734,2300.421631 
	C1079.743530,2303.837891 1085.944458,2301.244385 1091.209229,2303.898438 
	C1093.199341,2305.426025 1093.629395,2307.038574 1092.792725,2309.628418 
	C1090.831055,2311.501221 1089.168701,2311.636963 1086.708130,2310.494629 
z"
        />
        <path
          fill="#46565D"
          opacity="1.000000"
          stroke="none"
          d="
M1308.318115,2324.117188 
	C1307.938965,2322.254150 1307.865723,2320.783447 1307.668701,2318.583008 
	C1307.780029,2317.345459 1308.055054,2316.860107 1308.678467,2316.120605 
	C1312.630371,2319.659424 1317.545410,2320.276367 1323.129272,2320.774170 
	C1321.746216,2328.256592 1321.433594,2335.349365 1322.905640,2342.394531 
	C1323.378052,2344.656006 1322.251343,2346.148682 1319.909668,2347.688721 
	C1307.870361,2350.495361 1315.638794,2342.201660 1314.231934,2338.436035 
	C1314.502319,2333.715332 1312.855103,2330.358643 1310.129761,2326.658691 
	C1309.456543,2325.540283 1309.009399,2325.052979 1308.318115,2324.117188 
z"
        />
        <path
          fill="#70889D"
          opacity="1.000000"
          stroke="none"
          d="
M1104.384277,2263.495605 
	C1099.877197,2263.923584 1095.810425,2263.961914 1090.871826,2264.000000 
	C1088.987549,2260.890625 1085.003662,2259.589600 1084.982056,2255.874756 
	C1086.527954,2253.314697 1089.050171,2254.109619 1091.223145,2254.031982 
	C1096.128662,2253.856934 1101.010864,2254.195801 1105.842041,2252.537842 
	C1108.497314,2251.626465 1111.764526,2251.035645 1113.814697,2255.250488 
	C1113.186768,2257.933350 1111.203369,2258.696777 1109.414551,2260.492676 
	C1107.717529,2261.867432 1106.059326,2262.092773 1104.384277,2263.495605 
z"
        />
        <path
          fill="#61798F"
          opacity="1.000000"
          stroke="none"
          d="
M1108.646729,2260.599365 
	C1108.219482,2257.176025 1109.889648,2255.998535 1113.294678,2256.008789 
	C1122.719727,2255.985840 1131.444214,2255.981445 1141.102051,2255.961182 
	C1145.453369,2255.963379 1148.871338,2255.981689 1153.144775,2256.000000 
	C1155.404785,2257.503906 1155.594238,2259.262451 1154.593506,2261.952148 
	C1143.282837,2268.013428 1132.681763,2262.455811 1121.066772,2262.051758 
	C1116.544800,2261.812012 1112.866699,2262.454102 1108.646729,2260.599365 
z"
        />
        <path
          fill="#61798F"
          opacity="1.000000"
          stroke="none"
          d="
M905.986511,2299.989746 
	C910.718811,2300.321289 914.721802,2299.207764 918.464905,2300.999756 
	C918.894165,2304.550049 916.147278,2304.941650 914.164612,2305.517090 
	C911.068115,2306.416748 907.715149,2306.021484 905.031006,2308.672363 
	C902.711548,2310.963379 900.065308,2308.800293 897.615784,2308.080566 
	C895.507629,2307.460693 894.171021,2306.206299 894.315613,2303.830322 
	C894.461670,2301.429932 895.944946,2300.301270 898.153870,2300.142334 
	C900.460266,2299.976318 902.782654,2300.031494 905.986511,2299.989746 
z"
        />
        <path
          fill="#61798F"
          opacity="1.000000"
          stroke="none"
          d="
M1043.971680,2265.549316 
	C1042.453125,2263.000488 1039.694824,2262.424805 1038.980469,2259.187256 
	C1048.560791,2256.981689 1057.699463,2257.320312 1066.699341,2263.052490 
	C1059.270874,2264.062012 1051.842529,2265.071777 1043.971680,2265.549316 
z"
        />
        <path
          fill="#3C556B"
          opacity="1.000000"
          stroke="none"
          d="
M1288.790894,2316.343506 
	C1289.698608,2317.729980 1289.763428,2319.064697 1289.786621,2321.073486 
	C1287.352783,2324.589111 1284.514282,2324.526611 1280.767334,2322.409912 
	C1276.993896,2318.411133 1272.348389,2321.199951 1268.433838,2318.477539 
	C1274.568481,2317.208740 1281.158691,2315.556396 1288.790894,2316.343506 
z"
        />
        <path
          fill="#61798F"
          opacity="1.000000"
          stroke="none"
          d="
M1092.511230,2310.631592 
	C1091.982666,2308.480225 1091.989746,2306.989990 1091.993530,2304.754639 
	C1095.573853,2304.132080 1099.261963,2303.449219 1103.309692,2305.701904 
	C1104.011719,2307.503906 1104.013672,2308.992676 1104.020020,2311.226074 
	C1100.138794,2315.409424 1096.636841,2312.638428 1092.511230,2310.631592 
z"
        />
        <path
          fill="#2E2D30"
          opacity="1.000000"
          stroke="none"
          d="
M1320.291382,2281.417480 
	C1319.923584,2283.703857 1319.348511,2285.077148 1317.373291,2286.067383 
	C1315.225342,2281.359863 1316.790649,2277.349609 1320.853149,2273.431641 
	C1322.325562,2275.734863 1320.660767,2278.090576 1320.291382,2281.417480 
z"
        />
        <path
          fill="#61798F"
          opacity="1.000000"
          stroke="none"
          d="
M1034.089111,2264.887451 
	C1035.444458,2265.630859 1035.463013,2266.378174 1034.492310,2267.038818 
	C1034.289185,2267.177002 1033.819824,2267.172119 1033.622314,2267.028076 
	C1032.676636,2266.339355 1032.711548,2265.592285 1034.089111,2264.887451 
z"
        />
        <path
          fill="#597288"
          opacity="1.000000"
          stroke="none"
          d="
M1256.921875,2303.043457 
	C1256.874390,2302.957031 1256.969482,2303.130127 1256.921875,2303.043457 
z"
        />
        <path
          fill="#698196"
          opacity="1.000000"
          stroke="none"
          d="
M1279.540649,2322.373047 
	C1282.769165,2321.799561 1285.458008,2321.800781 1288.945923,2321.774902 
	C1293.765259,2321.843262 1297.658936,2322.551514 1301.336792,2324.225830 
	C1302.239746,2324.636963 1303.118042,2325.168213 1303.009766,2326.390381 
	C1302.879272,2327.863770 1301.951904,2328.733887 1300.682495,2329.278076 
	C1298.657593,2330.145752 1296.837036,2329.099854 1294.911133,2328.701904 
	C1290.412476,2327.772949 1285.885742,2327.373779 1280.818604,2329.204590 
	C1282.541260,2333.331543 1285.732666,2333.792480 1288.987915,2333.925537 
	C1292.634766,2334.074463 1296.290771,2334.042969 1299.942261,2334.022461 
	C1302.772339,2334.006592 1305.082031,2334.473633 1306.145996,2337.698730 
	C1307.988037,2343.282959 1310.640869,2338.352783 1313.585815,2337.925781 
	C1315.467163,2339.456055 1321.095825,2339.376465 1316.106689,2343.500732 
	C1313.430786,2345.712402 1315.182373,2347.454834 1318.669922,2347.997070 
	C1319.747192,2350.145508 1320.234863,2352.297852 1320.976929,2355.238037 
	C1320.466309,2357.315430 1320.012573,2358.719727 1319.459961,2360.892578 
	C1318.954834,2368.159668 1316.188721,2369.962402 1309.149536,2369.965088 
	C1287.090210,2369.973633 1264.875977,2369.183838 1243.113892,2374.113770 
	C1238.163086,2375.234863 1233.201050,2375.215332 1228.270630,2375.143311 
	C1213.797974,2374.931885 1199.511719,2372.449219 1184.568481,2370.530762 
	C1182.771240,2362.209229 1182.771362,2362.212158 1189.937012,2361.993408 
	C1196.260010,2361.800537 1202.576416,2361.768799 1208.499756,2364.449951 
	C1212.277588,2366.159424 1216.273560,2366.349365 1220.221313,2365.944336 
	C1227.038452,2365.245361 1233.814453,2364.145752 1240.546753,2363.222656 
	C1240.409668,2359.531982 1237.973389,2360.073975 1236.171387,2360.050781 
	C1228.870117,2359.955811 1221.562988,2359.867920 1214.265625,2360.062256 
	C1208.850586,2360.206299 1203.701294,2359.508057 1198.342285,2356.389648 
	C1196.691895,2354.957520 1201.486816,2352.923828 1196.290771,2352.129883 
	C1173.460938,2348.640625 1150.579712,2348.897949 1127.640869,2349.291016 
	C1122.704102,2349.375488 1117.586060,2349.972412 1112.511963,2346.588379 
	C1110.231323,2343.649658 1105.840698,2341.145264 1112.957275,2339.749023 
	C1117.870117,2338.784912 1122.602295,2337.083008 1127.625488,2338.848633 
	C1129.704468,2339.579346 1131.732422,2339.599854 1132.758911,2337.381348 
	C1134.083740,2334.519043 1131.084229,2334.430420 1129.791016,2333.220703 
	C1129.432251,2332.885254 1129.516235,2332.076172 1129.233765,2330.672363 
	C1137.741577,2330.283691 1145.967651,2328.126221 1154.238770,2332.636230 
	C1159.385864,2335.442627 1165.382935,2334.499512 1170.873169,2333.259521 
	C1178.181030,2331.608887 1185.334717,2331.520996 1192.590332,2333.169678 
	C1199.505859,2334.740723 1206.755249,2334.925049 1213.454834,2333.666992 
	C1227.825195,2330.968750 1242.505127,2331.048584 1256.782349,2328.016113 
	C1264.212280,2326.438232 1271.596191,2324.644287 1279.540649,2322.373047 
z"
        />
        <path
          fill="#687D90"
          opacity="1.000000"
          stroke="none"
          d="
M1192.595215,2442.643066 
	C1190.374023,2436.569092 1187.514648,2435.743896 1182.328369,2438.294434 
	C1176.202637,2441.307373 1170.983887,2438.446289 1168.038330,2430.796875 
	C1170.722656,2425.219727 1175.551514,2424.722168 1180.119629,2424.864502 
	C1209.441650,2425.777588 1238.777466,2426.190674 1268.092651,2427.312500 
	C1270.731323,2427.413330 1273.330078,2428.040771 1277.739014,2429.434570 
	C1272.404053,2427.126709 1268.223755,2427.554932 1264.527344,2424.513184 
	C1263.283203,2422.314453 1262.433350,2420.733398 1263.797607,2418.480469 
	C1270.529053,2412.396484 1279.150635,2412.291016 1286.591919,2411.922607 
	C1299.688599,2411.273438 1312.991455,2409.728760 1326.923828,2412.538574 
	C1328.190796,2413.195312 1328.677002,2413.485840 1329.808228,2414.314941 
	C1332.617188,2417.361328 1332.937500,2420.847412 1335.272705,2424.182129 
	C1335.861938,2426.619873 1335.683228,2428.226562 1334.426514,2430.393066 
	C1322.194946,2434.326416 1310.269531,2435.543945 1298.163940,2435.101074 
	C1296.813599,2434.785400 1298.035767,2437.174805 1297.873047,2435.851318 
	C1297.818604,2435.408936 1298.016357,2435.181885 1298.463989,2435.208252 
	C1305.997559,2435.651611 1313.446899,2435.249756 1320.860596,2433.633301 
	C1326.103149,2432.490723 1331.369141,2432.296875 1336.243652,2437.024658 
	C1336.782837,2438.322266 1336.860718,2438.897705 1336.613770,2440.293945 
	C1333.199951,2444.887939 1328.963989,2445.365967 1324.561157,2444.976318 
	C1301.094116,2442.901123 1277.661011,2445.747070 1254.207031,2445.411865 
	C1251.554932,2445.374268 1248.915405,2445.338379 1245.400391,2445.000000 
	C1233.722290,2444.061523 1223.095581,2443.484619 1212.302612,2446.456543 
	C1206.044189,2448.179932 1199.249146,2446.308838 1192.595215,2442.643066 
z"
        />
        <path
          fill="#698196"
          opacity="1.000000"
          stroke="none"
          d="
M1326.017334,2385.382080 
	C1322.569702,2386.009521 1319.929077,2385.939453 1317.409302,2384.811279 
	C1315.826660,2384.102783 1313.723022,2383.738037 1312.645630,2384.930420 
	C1305.624512,2392.700928 1295.672363,2389.038330 1287.288696,2391.476562 
	C1278.892944,2393.918701 1269.955566,2390.821533 1261.725586,2389.354248 
	C1250.736938,2387.395020 1240.685791,2390.392822 1230.256958,2391.117920 
	C1226.315186,2391.391846 1222.179199,2392.675781 1218.419312,2391.744629 
	C1207.214111,2388.969238 1195.899292,2390.239502 1184.633789,2390.043945 
	C1175.255493,2389.880859 1165.959839,2389.118896 1156.749634,2393.124756 
	C1151.260376,2395.512451 1145.015625,2395.396484 1138.531494,2392.884033 
	C1131.572266,2390.187256 1123.712036,2389.431396 1115.981934,2389.830322 
	C1114.257812,2389.919434 1112.729614,2389.036377 1112.605957,2386.965576 
	C1112.515503,2385.452881 1113.532593,2384.547119 1114.698730,2383.843506 
	C1117.392700,2382.218506 1120.243652,2381.235596 1123.095093,2383.279785 
	C1130.389038,2388.509521 1138.545288,2385.363525 1146.307617,2385.662598 
	C1149.919556,2385.802002 1149.850464,2382.391602 1150.015869,2378.873291 
	C1155.076538,2373.157959 1161.531738,2374.699951 1167.136719,2375.342529 
	C1181.051270,2376.938965 1195.042725,2376.493896 1208.973022,2377.996338 
	C1225.957031,2379.828369 1242.534424,2373.705078 1259.494507,2373.535400 
	C1281.350342,2373.316895 1303.246582,2372.675781 1325.689697,2377.199707 
	C1328.044678,2380.010254 1328.030029,2382.390625 1326.017334,2385.382080 
z"
        />
        <path
          fill="#546D83"
          opacity="1.000000"
          stroke="none"
          d="
M1045.509033,2378.703125 
	C1049.514648,2378.249512 1052.701294,2380.443115 1057.134399,2379.896973 
	C1064.807373,2381.436035 1072.095337,2379.229980 1079.420288,2382.577148 
	C1081.978516,2387.278564 1085.981689,2386.425537 1089.416138,2386.708496 
	C1093.335815,2387.031250 1097.296997,2386.924561 1101.391479,2389.617920 
	C1103.520020,2392.647461 1107.136597,2391.204834 1109.170654,2394.096924 
	C1104.597900,2397.686768 1098.756836,2397.098633 1093.794800,2398.769531 
	C1092.116577,2403.507812 1099.209351,2400.612793 1098.331909,2405.075928 
	C1095.316162,2406.791504 1091.250366,2406.808105 1088.292847,2405.705811 
	C1078.313843,2401.987305 1068.735596,2406.840820 1058.970581,2406.222168 
	C1057.483521,2406.128174 1055.677246,2407.010254 1054.444824,2407.981445 
	C1052.909424,2409.191406 1054.572632,2410.103516 1055.688477,2411.457031 
	C1055.852539,2413.139893 1054.997559,2413.991943 1054.206055,2415.531494 
	C1053.526245,2418.055176 1052.772461,2420.197754 1050.391602,2418.198242 
	C1048.239868,2416.391357 1045.950928,2415.979980 1042.652832,2415.968750 
	C1039.227783,2413.076904 1036.213745,2410.493896 1033.644775,2406.511719 
	C1031.451660,2401.492920 1027.688843,2401.601807 1024.030273,2402.131348 
	C1017.458923,2403.082520 1010.833496,2403.087891 1004.241394,2403.626709 
	C1002.912598,2403.735352 1004.568054,2403.812744 1003.255920,2403.696289 
	C991.822632,2402.682861 980.806580,2397.983643 968.980713,2400.031982 
	C967.183167,2400.343750 965.464783,2398.408447 965.077332,2395.423828 
	C967.526001,2389.658691 975.723206,2392.364746 977.489563,2385.263184 
	C980.520630,2383.423096 983.099915,2383.268799 986.490967,2383.916748 
	C1003.131470,2388.603516 1019.397583,2389.816895 1035.679810,2387.639404 
	C1041.482422,2386.863525 1046.639771,2385.536377 1051.490845,2389.968750 
	C1049.581055,2388.102295 1047.371582,2386.884766 1045.219971,2384.451660 
	C1044.306885,2382.293457 1044.375977,2380.799316 1045.509033,2378.703125 
z"
        />
        <path
          fill="#4C6880"
          opacity="1.000000"
          stroke="none"
          d="
M1326.069946,2377.674805 
	C1314.338623,2377.333496 1303.081299,2377.420166 1291.874756,2376.950928 
	C1274.307983,2376.215820 1256.582153,2376.114990 1238.942749,2379.088135 
	C1229.506104,2380.678467 1219.833862,2384.227295 1210.129028,2381.828613 
	C1195.806274,2378.288818 1181.055054,2381.797363 1166.727783,2378.389404 
	C1162.008179,2377.267090 1156.825073,2378.093262 1150.943115,2378.033691 
	C1144.766724,2377.993408 1139.499878,2378.006836 1134.237061,2377.867432 
	C1132.585571,2377.823730 1130.755005,2377.362061 1131.207520,2375.088867 
	C1131.582153,2373.207520 1133.297852,2372.246094 1134.941284,2372.840820 
	C1139.570190,2374.517090 1143.977783,2374.016846 1149.157471,2372.171631 
	C1157.395630,2369.706543 1164.953613,2369.042236 1173.300781,2371.473389 
	C1176.980469,2371.808594 1180.090088,2372.586914 1183.485596,2370.512695 
	C1188.943237,2369.755859 1193.960693,2370.685303 1198.775391,2368.752441 
	C1201.379028,2367.707275 1203.995728,2368.120850 1206.516846,2369.202637 
	C1217.026001,2373.711182 1228.097656,2371.536621 1238.951782,2371.946289 
	C1253.137329,2364.501221 1269.050415,2370.558838 1283.686768,2366.265137 
	C1286.764771,2365.362549 1290.289062,2365.710938 1293.512573,2366.831543 
	C1297.224854,2368.122559 1300.329590,2368.576904 1302.643677,2364.044922 
	C1304.035522,2361.318848 1306.569214,2362.080078 1307.888428,2364.820068 
	C1309.831665,2368.854736 1312.928467,2368.052490 1315.664917,2367.522461 
	C1318.114624,2364.822510 1307.004883,2361.611572 1317.362793,2360.059570 
	C1318.818115,2360.156006 1319.518921,2360.459229 1320.414551,2361.498047 
	C1321.036987,2363.121338 1321.512939,2364.135010 1323.214844,2364.536865 
	C1326.076050,2367.432129 1324.964722,2371.126709 1325.929077,2375.201660 
	C1326.268433,2376.383789 1326.432251,2376.810059 1326.069946,2377.674805 
z"
        />
        <path
          fill="#4A667C"
          opacity="1.000000"
          stroke="none"
          d="
M1263.102539,2423.930176 
	C1272.359985,2423.659180 1280.298828,2426.239258 1288.614868,2427.352051 
	C1283.499756,2430.986084 1277.833130,2432.195312 1271.733887,2431.499268 
	C1269.444702,2431.238037 1267.220947,2430.295410 1264.934326,2430.134277 
	C1236.453491,2428.126953 1207.932983,2427.638184 1179.389526,2427.961182 
	C1175.992188,2427.999756 1172.807251,2428.603027 1168.847168,2429.898926 
	C1157.893799,2433.673096 1147.926025,2437.846191 1137.137817,2439.285400 
	C1135.827393,2439.459961 1134.528198,2439.898926 1133.222656,2439.902832 
	C1116.708252,2439.953369 1100.193848,2439.963379 1083.679321,2439.931396 
	C1081.763672,2439.927734 1079.272217,2440.141846 1079.149292,2437.408203 
	C1079.069946,2435.645020 1080.833618,2434.741455 1082.484131,2434.178955 
	C1086.264526,2432.890381 1090.037109,2431.839355 1094.135986,2431.961914 
	C1100.442139,2432.150635 1106.762817,2431.849854 1113.069092,2432.036377 
	C1123.121948,2432.333496 1132.909180,2431.500977 1142.161987,2427.061523 
	C1146.214355,2425.117188 1150.680420,2426.273926 1154.956787,2425.988037 
	C1156.849365,2425.861816 1158.977417,2426.555664 1160.486084,2424.665283 
	C1161.523438,2421.010010 1156.320312,2423.240234 1156.471436,2420.335205 
	C1170.747803,2419.140625 1184.831543,2420.459473 1198.830933,2423.223877 
	C1201.090088,2423.669922 1203.457886,2424.058838 1205.728027,2423.878662 
	C1224.546143,2422.381592 1243.351196,2424.976562 1263.102539,2423.930176 
z"
        />
        <path
          fill="#50677C"
          opacity="1.000000"
          stroke="none"
          d="
M1191.443359,2442.545898 
	C1195.624756,2442.208008 1199.439453,2441.770752 1202.831909,2442.768311 
	C1208.580322,2444.459229 1213.803101,2444.255615 1219.160278,2441.580078 
	C1224.132324,2439.096191 1229.482788,2439.791504 1234.740601,2440.604248 
	C1237.341919,2441.006348 1239.914062,2441.596436 1243.354736,2442.188477 
	C1246.116821,2443.871826 1247.817627,2445.629395 1248.953003,2448.689453 
	C1250.469116,2451.871094 1254.074341,2453.688965 1252.457031,2457.047119 
	C1250.947144,2460.182129 1247.607544,2459.458252 1244.647339,2459.715820 
	C1237.836304,2460.309326 1230.918335,2459.020020 1224.117920,2461.493652 
	C1224.028564,2464.426758 1222.401611,2465.675293 1220.380859,2467.391113 
	C1219.124023,2467.891113 1218.232056,2467.904297 1216.671387,2467.913086 
	C1215.113525,2467.945312 1214.224243,2467.969482 1212.667236,2467.982422 
	C1201.155518,2465.398682 1189.554565,2467.799805 1178.555176,2462.404053 
	C1176.694946,2458.483643 1181.007935,2456.986816 1180.653564,2453.934082 
	C1174.481567,2450.302002 1169.451172,2462.080566 1162.852905,2454.552734 
	C1162.542847,2452.928955 1162.713013,2452.010010 1163.542358,2450.568115 
	C1172.189819,2444.714111 1181.888184,2445.262939 1191.443359,2442.545898 
z"
        />
        <path
          fill="#4A667C"
          opacity="1.000000"
          stroke="none"
          d="
M1331.049072,2414.660156 
	C1308.849365,2412.529785 1287.211182,2413.965088 1264.834717,2417.975342 
	C1257.464722,2417.724365 1251.192383,2417.340332 1244.615356,2415.499756 
	C1239.326294,2414.019775 1232.253418,2415.454102 1226.071045,2416.435059 
	C1222.607422,2416.984619 1219.742065,2419.436768 1217.992065,2413.334229 
	C1217.081909,2410.160400 1211.301880,2409.992188 1207.267212,2409.996094 
	C1197.965942,2410.005127 1188.664795,2409.990967 1179.363525,2409.974121 
	C1175.475586,2409.967285 1171.537842,2410.049072 1168.349243,2405.081055 
	C1176.302002,2404.024414 1183.361450,2402.022705 1190.298340,2402.382324 
	C1205.641479,2403.177734 1220.923462,2403.067871 1236.218628,2402.043701 
	C1243.530640,2401.553955 1256.208496,2404.005859 1264.788330,2407.971191 
	C1263.309692,2411.403564 1259.012207,2408.763672 1257.239380,2411.564209 
	C1257.924927,2414.947998 1260.752075,2414.146973 1262.729248,2413.761230 
	C1268.561157,2412.623047 1274.381104,2411.299072 1280.068726,2409.591797 
	C1283.629395,2408.523193 1287.195435,2407.455811 1290.806030,2408.082031 
	C1302.990845,2410.194336 1315.124390,2407.470215 1328.266968,2407.996582 
	C1332.081909,2409.251465 1332.063721,2411.542969 1331.049072,2414.660156 
z"
        />
        <path
          fill="#2C2520"
          opacity="1.000000"
          stroke="none"
          d="
M1330.653809,2415.387695 
	C1330.368774,2413.207520 1330.077026,2411.146973 1329.520752,2408.534180 
	C1328.039795,2401.192627 1326.823364,2394.403809 1325.426025,2386.776855 
	C1325.395630,2383.852539 1325.546143,2381.766113 1325.620117,2378.896484 
	C1325.647339,2377.756592 1325.750854,2377.399658 1325.979492,2376.500244 
	C1327.409668,2375.989258 1328.678711,2371.508545 1329.885132,2375.725342 
	C1330.500732,2377.877197 1329.944214,2380.357910 1329.964355,2382.689453 
	C1329.975708,2384.019043 1329.616699,2386.233154 1330.213257,2386.546387 
	C1340.364624,2391.874512 1333.010620,2400.615234 1334.380371,2407.594482 
	C1334.694702,2409.196533 1334.296875,2411.088379 1335.603394,2412.239746 
	C1339.829102,2415.964111 1340.456787,2421.503418 1342.550537,2426.201904 
	C1346.898315,2425.157227 1348.789795,2418.908203 1352.328003,2419.963867 
	C1357.788086,2421.592529 1359.872559,2427.848877 1362.916870,2432.419189 
	C1364.692383,2435.084717 1365.068481,2438.667480 1366.156494,2441.809326 
	C1367.824829,2446.626709 1370.335815,2444.696777 1373.382812,2442.255615 
	C1374.349854,2441.718262 1374.773071,2441.506836 1375.832275,2441.611328 
	C1376.933472,2442.773682 1377.139893,2443.675293 1376.737061,2445.279297 
	C1370.559204,2446.986084 1367.753906,2453.637695 1360.727173,2454.004883 
	C1359.824463,2450.467041 1357.010986,2448.721924 1354.838989,2446.984131 
	C1351.453369,2444.275146 1348.389648,2441.420166 1346.505859,2437.622559 
	C1341.568237,2437.670898 1346.859131,2445.726562 1340.077637,2444.188965 
	C1337.860840,2444.227051 1336.895630,2443.408936 1336.591309,2441.356445 
	C1336.845337,2440.236084 1336.828491,2439.754150 1336.600098,2438.580078 
	C1334.263672,2436.214111 1333.687256,2433.981445 1334.239746,2430.703857 
	C1334.358032,2428.499023 1334.285889,2426.999756 1334.043701,2424.767334 
	C1331.803589,2422.136719 1332.041138,2419.266113 1330.727173,2416.354004 
	C1330.556274,2415.729980 1330.647217,2415.507812 1330.653809,2415.387695 
z"
        />
        <path
          fill="#4B6377"
          opacity="1.000000"
          stroke="none"
          d="
M1111.414307,2346.574219 
	C1144.914307,2345.877441 1177.880859,2345.001465 1211.771973,2351.613037 
	C1207.331299,2355.338867 1202.530518,2352.772705 1198.405762,2355.316895 
	C1195.151123,2355.831055 1192.491333,2355.847412 1189.028809,2355.861084 
	C1172.001221,2355.124512 1155.815674,2352.458496 1138.765747,2354.434814 
	C1129.929565,2354.021484 1121.854492,2354.041504 1112.848633,2354.100830 
	C1103.559204,2360.458496 1094.018921,2355.629395 1084.242920,2356.867188 
	C1077.322876,2354.823730 1070.801025,2354.531738 1064.602783,2350.636719 
	C1065.124878,2344.564209 1069.436279,2345.904297 1072.724121,2346.297363 
	C1076.595825,2346.760742 1080.308105,2348.466553 1085.197754,2347.962402 
	C1089.267822,2345.146240 1092.743530,2344.725098 1097.231689,2347.391602 
	C1102.265747,2347.734863 1106.642212,2348.739990 1111.414307,2346.574219 
z"
        />
        <path
          fill="#50677C"
          opacity="1.000000"
          stroke="none"
          d="
M1081.955444,2369.192383 
	C1076.487915,2369.432861 1071.425537,2371.023682 1066.381470,2372.788574 
	C1061.831787,2374.380127 1057.306396,2374.911621 1053.024414,2371.965576 
	C1051.628540,2371.005371 1050.129150,2370.065186 1048.420166,2370.436768 
	C1034.872192,2373.379639 1021.435608,2368.152588 1006.990234,2369.919434 
	C1006.057068,2368.448242 1006.047424,2367.004150 1006.052612,2364.836182 
	C1010.475830,2359.923340 1015.329956,2360.206299 1021.156128,2363.295410 
	C1028.651611,2360.556152 1028.651611,2360.556152 1039.229004,2364.001465 
	C1041.245361,2363.614258 1039.529175,2363.403320 1040.786621,2363.503662 
	C1054.127563,2364.570312 1067.388428,2362.011230 1081.354614,2363.357422 
	C1081.987061,2365.490723 1081.978271,2366.971924 1081.955444,2369.192383 
z"
        />
        <path
          fill="#546D83"
          opacity="1.000000"
          stroke="none"
          d="
M934.137390,2400.692383 
	C934.880798,2399.994629 935.766418,2399.989502 937.316284,2399.977539 
	C945.517822,2391.522705 954.699768,2396.086914 962.837952,2397.786621 
	C968.640625,2398.998779 974.152893,2400.578125 980.082886,2399.696289 
	C985.475952,2398.894287 989.037170,2400.777100 990.335938,2407.142822 
	C990.711182,2409.475098 990.954651,2411.029785 990.320679,2413.306396 
	C980.425903,2414.758057 971.147766,2415.311035 962.388000,2409.422607 
	C958.049988,2406.506592 952.399170,2404.459473 946.925598,2409.076660 
	C944.926147,2410.763672 941.452698,2410.606445 940.365967,2407.405029 
	C939.264954,2404.162109 935.211365,2404.445801 934.137390,2400.692383 
z"
        />
        <path
          fill="#698196"
          opacity="1.000000"
          stroke="none"
          d="
M1082.459595,2363.447266 
	C1076.588867,2363.227539 1071.583374,2365.617920 1066.318359,2365.992188 
	C1058.005981,2366.582764 1049.832764,2365.535400 1040.831909,2364.037354 
	C1040.754272,2359.186523 1044.770630,2359.260254 1047.651001,2360.229004 
	C1055.693604,2362.933838 1063.242798,2360.962402 1071.460083,2358.544922 
	C1074.129639,2355.040527 1077.596069,2355.082031 1081.315674,2354.073730 
	C1091.357300,2354.020508 1100.714478,2354.050781 1110.994873,2354.110596 
	C1111.900879,2356.487305 1112.407837,2358.903564 1110.485596,2361.548340 
	C1106.692505,2364.508545 1103.154053,2364.319824 1098.755127,2362.536133 
	C1092.745728,2358.348877 1087.778320,2359.646240 1082.459595,2363.447266 
z"
        />
        <path
          fill="#698196"
          opacity="1.000000"
          stroke="none"
          d="
M1173.810059,2371.256836 
	C1170.134033,2372.007324 1166.383179,2372.193115 1162.619629,2373.358154 
	C1158.941406,2374.497070 1155.037231,2374.449463 1150.654297,2372.368408 
	C1147.982056,2365.107422 1145.084351,2363.564209 1137.957397,2366.081787 
	C1134.486694,2367.307861 1130.909302,2369.840088 1130.039795,2362.760742 
	C1131.795898,2358.191406 1134.338257,2359.541260 1137.418335,2361.470703 
	C1145.813721,2358.434082 1154.086304,2360.457275 1162.192627,2360.239746 
	C1163.486816,2360.204834 1165.258911,2360.864258 1165.236572,2362.217773 
	C1165.140259,2368.082520 1171.685181,2366.966309 1173.810059,2371.256836 
z"
        />
        <path
          fill="#3F5A73"
          opacity="1.000000"
          stroke="none"
          d="
M990.926758,2414.013184 
	C989.484802,2413.032715 988.980713,2412.055908 988.261719,2410.572021 
	C1003.086304,2404.207520 1018.476868,2401.498047 1035.272583,2405.453125 
	C1038.250122,2408.679443 1042.541260,2409.905273 1041.938965,2415.244385 
	C1025.274536,2414.344727 1008.591553,2413.611084 990.926758,2414.013184 
z"
        />
        <path
          fill="#50677C"
          opacity="1.000000"
          stroke="none"
          d="
M1012.193970,2445.070801 
	C1003.785400,2447.395264 995.830627,2444.126709 986.775757,2443.836426 
	C986.780945,2439.706055 989.989624,2439.938477 992.964111,2440.052979 
	C997.388428,2440.222412 1001.458130,2439.671143 1005.061707,2436.671387 
	C1006.520386,2435.456787 1008.608643,2434.473633 1010.470520,2434.358643 
	C1019.056091,2433.827637 1027.691162,2433.364990 1036.235229,2434.583008 
	C1038.503662,2434.906250 1041.489624,2435.207764 1042.853516,2438.124268 
	C1040.540527,2440.868408 1038.071167,2439.538086 1035.633057,2438.896973 
	C1029.003662,2437.152588 1022.522156,2437.813721 1016.166809,2440.215332 
	C1014.198425,2440.958984 1012.759827,2442.208740 1012.193970,2445.070801 
z"
        />
        <path
          fill="#3F5A73"
          opacity="1.000000"
          stroke="none"
          d="
M1054.909424,2416.067139 
	C1051.999146,2414.267578 1052.401978,2412.972900 1055.456177,2412.024902 
	C1067.974243,2412.765625 1079.662109,2408.777344 1091.636108,2410.038818 
	C1093.605347,2410.245850 1095.617798,2410.015137 1097.598022,2410.156982 
	C1099.923584,2410.323486 1102.895996,2410.630371 1102.722900,2413.528320 
	C1102.530029,2416.753174 1099.229492,2416.089355 1097.162842,2415.901123 
	C1088.806763,2415.139648 1080.809692,2418.951172 1072.508911,2417.796387 
	C1066.947876,2417.022705 1061.476685,2415.595459 1054.909424,2416.067139 
z"
        />
        <path
          fill="#50677C"
          opacity="1.000000"
          stroke="none"
          d="
M1078.643555,2384.423828 
	C1071.684326,2382.643555 1064.919067,2384.338135 1058.417114,2380.441895 
	C1059.427612,2379.340088 1060.987793,2378.216553 1062.476685,2378.303711 
	C1073.772827,2378.967529 1085.017456,2375.542480 1097.126221,2377.917236 
	C1102.174683,2378.522461 1106.747559,2376.991699 1111.119751,2379.258545 
	C1109.191040,2383.131104 1105.380615,2381.443115 1102.671753,2383.694092 
	C1094.510620,2385.883057 1086.932007,2386.086426 1078.643555,2384.423828 
z"
        />
        <path
          fill="#445E74"
          opacity="1.000000"
          stroke="none"
          d="
M1163.058716,2450.005371 
	C1163.991089,2450.880371 1163.986938,2451.750732 1163.984009,2453.274414 
	C1159.646606,2459.017822 1153.755493,2459.047119 1147.775024,2459.033936 
	C1138.302490,2459.013184 1128.901123,2458.022217 1118.746704,2456.605469 
	C1117.501221,2455.022705 1116.992676,2454.055176 1116.279785,2452.583008 
	C1131.316895,2449.000000 1146.753052,2450.241943 1163.058716,2450.005371 
z"
        />
        <path
          fill="#698196"
          opacity="1.000000"
          stroke="none"
          d="
M1138.618164,2354.562744 
	C1154.387207,2350.789062 1170.703125,2351.383301 1187.597412,2355.358643 
	C1184.919312,2359.213379 1180.469849,2358.593506 1176.885864,2357.760742 
	C1165.991821,2355.229980 1155.026855,2356.340332 1144.091675,2355.964600 
	C1142.463989,2355.908691 1140.851318,2355.415039 1138.618164,2354.562744 
z"
        />
        <path
          fill="#698196"
          opacity="1.000000"
          stroke="none"
          d="
M1078.031860,2384.747803 
	C1085.459106,2382.029297 1092.943970,2380.764893 1101.272827,2383.669434 
	C1101.326660,2385.628906 1100.443604,2387.257080 1101.813232,2389.553223 
	C1096.119507,2390.022217 1090.233765,2389.961426 1084.348022,2389.959717 
	C1081.199829,2389.958984 1078.341309,2389.645264 1078.031860,2384.747803 
z"
        />
        <path
          fill="#50677C"
          opacity="1.000000"
          stroke="none"
          d="
M1137.798584,2361.230469 
	C1135.651611,2362.101074 1133.456421,2360.277832 1130.569824,2361.824219 
	C1126.809937,2361.857666 1123.498047,2361.921387 1121.248779,2358.110840 
	C1127.552734,2354.362305 1132.779175,2356.034668 1137.798584,2361.230469 
z"
        />
        <path
          fill="#50677C"
          opacity="1.000000"
          stroke="none"
          d="
M1098.175781,2362.727783 
	C1101.420654,2362.015869 1104.841309,2362.019043 1109.114990,2362.025879 
	C1106.473389,2367.028564 1104.118164,2367.315186 1098.175781,2362.727783 
z"
        />
        <path
          fill="#546D83"
          opacity="1.000000"
          stroke="none"
          d="
M913.964722,2411.799805 
	C911.979614,2411.516113 910.499695,2410.658691 911.447388,2409.360107 
	C912.759155,2407.562744 915.126221,2407.560059 916.454895,2409.333984 
	C917.416565,2410.617676 915.950439,2411.497803 913.964722,2411.799805 
z"
        />
        <path
          fill="#546D83"
          opacity="1.000000"
          stroke="none"
          d="
M932.238281,2408.724609 
	C930.301086,2410.783936 930.301086,2410.783936 926.148865,2408.638184 
	C927.988464,2407.535400 929.876099,2408.085205 932.238281,2408.724609 
z"
        />
        <path
          fill="#698196"
          opacity="1.000000"
          stroke="none"
          d="
M1116.863159,2375.279541 
	C1117.285767,2376.780762 1116.757324,2377.320068 1115.594727,2377.089355 
	C1115.352661,2377.041016 1115.026855,2376.698730 1114.990234,2376.454590 
	C1114.813354,2375.276367 1115.389160,2374.787842 1116.863159,2375.279541 
z"
        />
        <path
          fill="#50677C"
          opacity="1.000000"
          stroke="none"
          d="
M1031.717773,2444.290527 
	C1031.673950,2445.984863 1031.302368,2448.057617 1028.348145,2446.393555 
	C1028.754272,2444.895508 1029.776489,2444.301270 1031.717773,2444.290527 
z"
        />
        <path
          fill="#698196"
          opacity="1.000000"
          stroke="none"
          d="
M1320.079834,2390.747559 
	C1319.557861,2391.933350 1318.201172,2392.063232 1318.005493,2391.228271 
	C1317.702271,2389.935303 1319.027100,2390.311768 1320.079834,2390.747559 
z"
        />
        <path
          fill="#546D83"
          opacity="1.000000"
          stroke="none"
          d="
M922.294434,2400.416016 
	C922.883728,2399.985596 923.784424,2399.980469 925.361206,2399.974121 
	C925.150330,2401.276123 924.103638,2401.949707 922.294434,2400.416016 
z"
        />
        <path
          fill="#698196"
          opacity="1.000000"
          stroke="none"
          d="
M1021.937256,2363.058594 
	C1017.214478,2363.964111 1012.519531,2364.020996 1006.946045,2364.095215 
	C1003.339417,2363.789551 1000.870667,2364.855713 998.294617,2365.448975 
	C995.984009,2365.981201 994.423889,2364.813232 994.282349,2362.348877 
	C994.151367,2360.069092 995.219727,2358.444580 997.598206,2358.242676 
	C999.223206,2358.104736 1000.933777,2358.180420 1002.521057,2358.538086 
	C1007.872620,2359.743652 1012.384521,2359.355469 1014.655457,2353.338867 
	C1015.262268,2351.730957 1016.735291,2350.447266 1018.408325,2350.394775 
	C1027.098877,2350.122314 1035.854492,2349.034424 1044.485352,2351.946533 
	C1039.862061,2355.019775 1034.651855,2353.661621 1029.712036,2353.964111 
	C1022.979248,2354.376465 1022.973816,2354.287842 1021.937256,2363.058594 
z"
        />
        <path
          fill="#698196"
          opacity="1.000000"
          stroke="none"
          d="
M1098.075439,2347.262451 
	C1094.565063,2347.968506 1091.156982,2347.966553 1086.898804,2347.952637 
	C1080.792114,2338.049561 1080.845947,2337.967529 1092.517822,2338.018799 
	C1093.495483,2338.023193 1094.475464,2337.973877 1095.450562,2338.023682 
	C1101.774292,2338.347412 1101.825928,2338.463623 1098.849976,2344.729248 
	C1098.572998,2345.312500 1098.399414,2345.944824 1098.075439,2347.262451 
z"
        />
        <path
          fill="#5D758A"
          opacity="1.000000"
          stroke="none"
          d="
M1081.453857,2353.445312 
	C1080.311523,2357.770508 1077.016479,2358.138428 1072.745483,2358.004883 
	C1070.299438,2354.027100 1066.634888,2354.315674 1063.203735,2353.707520 
	C1060.500122,2353.228027 1060.224609,2351.704346 1063.423584,2350.102051 
	C1069.461426,2352.121338 1075.373535,2351.418701 1081.453857,2353.445312 
z"
        />
        <path
          fill="#61798F"
          opacity="1.000000"
          stroke="none"
          d="
M808.123840,2438.817383 
	C805.106567,2439.639648 802.367371,2439.773926 798.803345,2439.901855 
	C793.793335,2440.052979 789.607544,2440.017822 784.724976,2439.898926 
	C777.771790,2439.403564 771.832031,2441.667725 764.832642,2441.999023 
	C760.319336,2438.555664 755.749634,2440.582031 751.606567,2439.994141 
	C749.708984,2439.724609 747.075317,2440.111816 747.227722,2437.315186 
	C747.349365,2435.083984 749.578674,2434.375488 751.661560,2434.139404 
	C752.320679,2434.064697 752.986511,2434.019531 753.649658,2434.015137 
	C771.215332,2433.898438 788.655518,2430.786133 806.288574,2431.937256 
	C810.881287,2432.236816 815.592651,2432.560791 820.144836,2431.074219 
	C821.486633,2430.635986 822.905212,2430.815674 823.290100,2432.441406 
	C823.783508,2434.525391 822.429504,2435.545654 820.606323,2435.738037 
	C818.301208,2435.980469 815.966370,2435.944580 813.643982,2436.017822 
	C811.635925,2436.081299 809.609863,2436.086182 808.123840,2438.817383 
z"
        />
        <path
          fill="#627A90"
          opacity="1.000000"
          stroke="none"
          d="
M691.623230,2369.532715 
	C690.956116,2375.337402 686.774231,2373.581299 682.697266,2373.751953 
	C683.488037,2365.644531 684.005310,2365.385742 691.623230,2369.532715 
z"
        />
        <path
          fill="#627A90"
          opacity="1.000000"
          stroke="none"
          d="
M723.936035,2369.274414 
	C723.137329,2369.865234 722.231445,2369.901123 720.645874,2369.906250 
	C720.193665,2368.632812 720.353882,2367.238525 721.903320,2367.020020 
	C722.990845,2366.866943 723.519104,2367.768799 723.936035,2369.274414 
z"
        />
        <path
          fill="#50677C"
          opacity="1.000000"
          stroke="none"
          d="
M988.947021,2386.636719 
	C985.309570,2386.079590 982.463318,2386.051025 978.763306,2386.040527 
	C977.031799,2386.782715 976.027527,2387.208252 974.072266,2387.204346 
	C970.859802,2385.857666 970.339355,2383.911621 971.550903,2380.805664 
	C978.064575,2376.102539 984.402710,2375.242920 991.637939,2376.599365 
	C996.303528,2377.474365 1001.694153,2377.167725 1006.185852,2375.669434 
	C1013.283386,2373.302002 1019.940613,2374.701660 1026.542236,2376.327637 
	C1032.426514,2377.777344 1038.261353,2378.001221 1045.085205,2378.051758 
	C1045.981934,2379.550781 1045.988647,2381.029785 1045.995117,2383.249512 
	C1039.828613,2387.820068 1032.913208,2387.847656 1026.052734,2387.640137 
	C1013.948547,2387.273926 1001.816772,2389.142822 988.947021,2386.636719 
z"
        />
        <path
          fill="#546D83"
          opacity="1.000000"
          stroke="none"
          d="
M971.294128,2380.093994 
	C972.062988,2381.877930 971.888794,2383.664307 973.550049,2385.610840 
	C972.751892,2391.497559 969.155334,2390.423584 964.717529,2388.506836 
	C963.982483,2387.063232 963.987122,2386.174316 963.990845,2384.617432 
	C964.495178,2379.821533 967.576965,2380.033447 971.294128,2380.093994 
z"
        />
        <path
          fill="#4C6880"
          opacity="1.000000"
          stroke="none"
          d="
M780.557983,2351.756592 
	C781.066101,2347.458984 783.854187,2347.605713 787.551880,2349.541992 
	C786.224731,2352.331299 783.723083,2351.968262 780.557983,2351.756592 
z"
        />
        <path
          fill="#61798F"
          opacity="1.000000"
          stroke="none"
          d="
M678.447083,2349.541016 
	C674.775269,2348.094971 671.210205,2347.810059 666.772461,2347.992676 
	C664.676086,2340.745117 669.791443,2338.826172 675.532043,2336.507812 
	C674.843567,2334.636230 674.540283,2333.115723 675.507324,2330.749268 
	C679.664307,2331.964355 683.397156,2332.340088 687.524536,2331.293945 
	C692.366089,2330.066650 696.463074,2331.660400 699.825195,2335.575928 
	C703.201721,2339.507568 710.930115,2340.145020 714.480103,2336.976318 
	C716.365234,2335.293701 715.690979,2333.387207 714.195740,2331.779053 
	C712.715149,2330.186279 710.661743,2330.221191 708.672302,2329.992920 
	C706.218811,2329.711670 703.379883,2330.914795 701.083069,2328.005371 
	C702.671936,2325.581787 704.837463,2326.011230 707.014954,2326.651367 
	C708.889648,2327.202881 711.599609,2328.706543 712.504333,2326.814453 
	C715.712280,2320.105713 719.280701,2323.541016 723.683228,2325.232178 
	C727.227417,2326.593262 731.531616,2325.898193 735.473877,2326.337646 
	C737.154053,2326.524902 738.778870,2327.569824 738.834045,2329.424805 
	C738.899414,2331.622559 736.967285,2331.906494 735.305420,2331.892822 
	C732.661743,2331.871338 729.974670,2331.877197 727.390381,2331.407227 
	C724.928467,2330.958984 722.591187,2330.252197 722.180725,2333.728027 
	C721.858765,2336.453613 721.975342,2339.665283 725.605042,2339.707275 
	C730.716553,2339.766602 733.599365,2342.254639 735.702759,2347.283203 
	C731.539856,2350.752930 726.529602,2349.848389 720.834839,2350.001953 
	C714.997253,2347.762207 710.005127,2345.458252 704.301025,2349.437500 
	C701.861938,2349.993896 699.723083,2349.997559 696.782349,2349.983643 
	C692.941101,2347.014648 689.417664,2345.043945 684.475342,2344.599365 
	C683.004395,2344.661621 682.229553,2344.865723 681.166931,2345.742920 
	C680.262024,2347.252930 679.671631,2348.265869 678.447083,2349.541016 
z"
        />
        <path
          fill="#6A8297"
          opacity="1.000000"
          stroke="none"
          d="
M678.538086,2349.972168 
	C678.029907,2348.470459 678.015686,2346.980713 678.000732,2344.745605 
	C678.804443,2343.146973 679.694580,2342.423828 681.434937,2342.061523 
	C687.493347,2342.269531 694.121399,2340.081787 695.885681,2349.217773 
	C691.250549,2349.987061 686.520691,2350.008057 680.905151,2350.024170 
	C680.019531,2350.019531 679.032043,2349.984375 678.538086,2349.972168 
z"
        />
        <path
          fill="#6A8297"
          opacity="1.000000"
          stroke="none"
          d="
M704.744019,2350.098877 
	C703.438232,2348.398438 699.391113,2346.899902 703.842041,2345.168457 
	C709.859558,2342.828125 715.095459,2344.231689 719.842590,2349.280273 
	C715.178772,2351.384277 710.363708,2352.849121 704.744019,2350.098877 
z"
        />
        <path
          fill="#627A90"
          opacity="1.000000"
          stroke="none"
          d="
M1035.899048,2405.266113 
	C1024.765259,2405.899658 1013.720398,2407.558105 1002.664185,2409.311279 
	C998.454834,2409.978516 994.113037,2409.810547 988.939209,2410.044189 
	C986.929382,2409.180908 986.276489,2408.014160 986.734985,2406.636963 
	C988.050232,2402.687500 985.750000,2402.060791 982.686829,2402.042969 
	C982.356812,2402.041016 982.020691,2401.988770 981.697632,2402.033447 
	C970.882690,2403.527832 960.627991,2400.049072 950.185364,2398.371826 
	C946.384277,2397.760986 943.010315,2398.599854 938.773682,2399.874023 
	C937.328186,2397.139648 936.675903,2394.308838 936.009399,2390.743652 
	C944.720276,2388.237305 953.446411,2386.614746 963.023560,2389.835449 
	C965.334839,2390.214600 966.129944,2391.237793 966.240173,2393.309570 
	C966.367737,2396.083740 967.368042,2397.665283 969.731384,2397.839111 
	C973.358826,2398.105713 976.962402,2398.203125 980.469482,2396.850830 
	C983.311951,2395.754639 987.052002,2394.854492 989.116455,2396.523193 
	C999.905823,2405.244141 1011.473389,2399.361572 1022.569702,2398.563721 
	C1031.061768,2397.953613 1031.912231,2397.435791 1035.899048,2405.266113 
z"
        />
        <path
          fill="#698196"
          opacity="1.000000"
          stroke="none"
          d="
M966.800293,2393.969482 
	C965.443970,2392.933350 964.899231,2391.916016 964.097290,2390.374023 
	C963.840088,2389.849121 963.914246,2388.901611 963.946045,2388.427002 
	C966.994141,2387.817139 970.097412,2388.588867 973.516052,2386.563232 
	C974.907776,2386.042725 975.766235,2386.047607 977.267090,2386.055664 
	C976.565918,2393.556885 976.565918,2393.556885 966.800293,2393.969482 
z"
        />
        <path
          fill="#728BA1"
          opacity="1.000000"
          stroke="none"
          d="
M701.054565,2171.974609 
	C701.996277,2170.294922 703.167114,2170.092773 704.313354,2170.884277 
	C705.240173,2171.524414 705.229919,2172.543213 704.278748,2173.155518 
	C703.112610,2173.906250 701.944946,2173.691650 701.054565,2171.974609 
z"
        />
        <path
          fill="#7A91A4"
          opacity="1.000000"
          stroke="none"
          d="
M604.289001,2165.337891 
	C603.118164,2165.980469 602.231873,2165.980713 600.681396,2165.975830 
	C600.022888,2165.023438 599.970154,2164.071777 600.046265,2163.130127 
	C600.223999,2160.929688 600.277771,2157.970215 603.373535,2158.676514 
	C607.133301,2159.533936 605.596985,2162.374756 604.289001,2165.337891 
z"
        />
        <path
          fill="#728BA1"
          opacity="1.000000"
          stroke="none"
          d="
M718.805542,2223.734863 
	C715.831970,2223.848389 713.428284,2224.535645 710.951782,2222.286621 
	C713.887207,2219.040771 717.096313,2219.376709 720.326965,2221.264893 
	C721.051514,2221.687988 720.450989,2223.016357 718.805542,2223.734863 
z"
        />
        <path
          fill="#5D758A"
          opacity="1.000000"
          stroke="none"
          d="
M619.315186,2335.913818 
	C626.396790,2332.900635 632.488525,2336.117188 638.635925,2336.284424 
	C640.441101,2336.333252 641.725342,2337.568604 641.258118,2339.609619 
	C640.794434,2341.635254 638.955261,2342.114990 637.374756,2341.728271 
	C627.858276,2339.399658 618.464722,2343.193604 608.951355,2342.159424 
	C606.135620,2341.853271 603.168945,2343.319580 600.245178,2343.472656 
	C598.607300,2343.558350 597.107361,2342.270508 597.089661,2340.352783 
	C597.074646,2338.722656 598.495361,2338.297363 599.834290,2338.123047 
	C606.026855,2337.315674 612.063232,2335.159668 619.315186,2335.913818 
z"
        />
        <path
          fill="#5D758A"
          opacity="1.000000"
          stroke="none"
          d="
M675.208984,2329.951172 
	C675.978699,2331.548828 675.988831,2333.024902 675.999512,2335.239502 
	C666.500916,2334.921387 656.974609,2339.000244 647.477295,2335.822754 
	C646.517700,2335.501953 645.584351,2335.320801 645.363464,2332.901855 
	C654.746216,2330.307861 664.251526,2327.724121 675.208984,2329.951172 
z"
        />
        <path
          fill="#61798F"
          opacity="1.000000"
          stroke="none"
          d="
M769.208496,2324.015625 
	C770.356445,2325.114258 770.720154,2326.228271 771.554688,2327.715332 
	C771.818420,2328.713379 771.797363,2329.689209 771.376892,2329.912598 
	C764.331970,2333.650635 756.715332,2335.352783 748.822144,2335.890137 
	C747.186462,2336.001465 745.368042,2335.729248 744.783386,2333.781982 
	C744.034058,2331.286377 745.881104,2330.244873 747.673584,2329.324463 
	C748.548401,2328.875244 749.560974,2328.244873 750.445312,2328.335938 
	C756.966492,2329.006836 762.547791,2325.930664 769.208496,2324.015625 
z"
        />
        <path
          fill="#5D758A"
          opacity="1.000000"
          stroke="none"
          d="
M584.635620,2352.117188 
	C583.215759,2350.557373 582.555786,2348.928223 584.382385,2348.648926 
	C590.439575,2347.722412 596.546814,2347.350342 604.595398,2349.454102 
	C597.572327,2355.310547 591.539978,2354.579590 584.635620,2352.117188 
z"
        />
        <path
          fill="#CC855B"
          opacity="1.000000"
          stroke="none"
          d="
M2348.541016,3679.490234 
	C2345.903320,3680.173828 2344.465576,3681.326904 2343.346191,3683.098145 
	C2342.002441,3685.224609 2339.898682,3685.979980 2336.737793,3685.987061 
	C2331.462402,3677.480713 2336.055908,3669.203369 2337.209473,3661.019775 
	C2338.254883,3653.599854 2338.990723,3646.970703 2335.754883,3639.728516 
	C2332.477539,3632.393799 2335.698730,3624.141113 2334.883545,3616.264160 
	C2334.510010,3612.655029 2338.762695,3612.314941 2341.831543,3612.498535 
	C2346.871582,3612.799561 2347.486084,3610.310791 2348.982910,3605.689209 
	C2353.127930,3592.893555 2345.511963,3580.223877 2349.975586,3567.635986 
	C2351.336182,3563.798584 2352.060547,3559.871094 2355.773926,3557.654053 
	C2358.718018,3555.896240 2361.582764,3556.907959 2364.126221,3558.798096 
	C2367.579346,3561.364746 2368.107910,3564.881348 2366.604492,3568.605957 
	C2364.087402,3574.842041 2365.760986,3577.474609 2372.603027,3576.293457 
	C2374.239014,3576.010986 2376.238037,3575.467285 2377.520752,3576.310303 
	C2388.122070,3583.277344 2391.456543,3576.338135 2395.400879,3568.043457 
	C2399.052002,3560.365479 2403.264160,3552.561768 2412.887207,3550.128906 
	C2416.531006,3549.207520 2417.427002,3545.539551 2417.704834,3542.085205 
	C2418.098145,3537.192383 2419.848877,3533.524658 2425.522217,3533.923828 
	C2431.772705,3534.363037 2432.667236,3538.995361 2432.664062,3543.961670 
	C2432.659668,3550.569824 2429.995850,3556.989990 2431.211914,3564.605225 
	C2441.652588,3557.022949 2448.536621,3548.754883 2447.453125,3535.055908 
	C2446.874512,3527.739990 2447.567627,3519.432617 2458.086914,3517.542969 
	C2462.496582,3523.604248 2456.691650,3528.863281 2458.009766,3535.213867 
	C2458.041748,3541.273926 2456.729736,3546.117432 2455.219727,3551.249512 
	C2453.075928,3558.536865 2452.321533,3566.651123 2453.938232,3575.278564 
	C2454.040039,3576.911621 2454.031494,3577.793701 2454.022949,3579.337891 
	C2453.933350,3586.842285 2449.344971,3590.040771 2443.648926,3591.169434 
	C2436.719238,3592.542725 2432.920410,3596.503662 2430.504639,3603.442383 
	C2430.009766,3606.776367 2429.989990,3609.543213 2429.949219,3613.139404 
	C2426.460693,3615.378418 2424.209961,3613.082764 2421.328613,3610.731934 
	C2418.957275,3607.015137 2419.420898,3603.212891 2417.862305,3598.342529 
	C2416.085449,3605.923340 2418.516113,3611.767090 2418.722168,3618.375977 
	C2418.674561,3619.963379 2418.585938,3620.864990 2418.329102,3622.423828 
	C2417.846924,3625.079590 2416.871338,3627.240234 2420.285156,3628.617188 
	C2425.712158,3636.773438 2425.288330,3638.351074 2417.512695,3641.501465 
	C2425.849121,3648.596191 2425.120605,3657.959717 2414.774902,3667.561035 
	C2406.899170,3664.931885 2402.198486,3658.235840 2398.841797,3652.944824 
	C2392.510498,3642.965088 2384.670166,3638.188965 2373.222656,3636.178955 
	C2371.304199,3633.191650 2371.250244,3630.625732 2372.118408,3627.266602 
	C2382.628906,3619.443115 2380.750977,3608.871094 2379.891846,3598.860596 
	C2379.559082,3594.982910 2377.529785,3590.387695 2372.960693,3590.304443 
	C2368.355469,3590.220459 2366.383301,3594.765869 2365.832275,3598.653564 
	C2364.626465,3607.159668 2363.112061,3615.664795 2363.013916,3624.296631 
	C2362.954834,3629.471680 2361.647949,3634.100098 2357.911377,3637.862549 
	C2351.931885,3643.882812 2349.667725,3650.816650 2350.717285,3659.500488 
	C2351.490234,3665.894531 2352.387451,3672.739990 2348.541016,3679.490234 
z"
        />
        <path
          fill="#C2825D"
          opacity="1.000000"
          stroke="none"
          d="
M2350.243652,3833.459717 
	C2350.678711,3838.437012 2354.535156,3840.328613 2358.015381,3841.785889 
	C2363.701416,3844.166748 2364.450684,3847.682129 2362.167480,3853.455078 
	C2359.225342,3854.728027 2356.400146,3854.919678 2353.599365,3853.189941 
	C2343.690918,3847.070068 2337.169434,3829.131104 2341.309326,3818.264893 
	C2343.770508,3811.804443 2345.980713,3805.197266 2349.726318,3799.295166 
	C2353.953613,3792.634766 2352.678955,3784.478760 2355.982178,3776.758789 
	C2357.928223,3774.002197 2360.141357,3772.267578 2359.711914,3768.557617 
	C2359.758545,3766.412109 2359.894043,3764.922852 2360.262695,3762.722168 
	C2368.946289,3753.717773 2364.681641,3743.870361 2363.668945,3733.687012 
	C2362.624512,3727.364502 2362.251953,3721.808594 2364.136963,3715.498535 
	C2364.870605,3714.263916 2365.242920,3713.818115 2366.392822,3712.974854 
	C2368.312988,3712.297363 2369.459473,3712.280029 2371.391602,3712.889648 
	C2373.492432,3714.428223 2374.161133,3715.958008 2374.682129,3718.441406 
	C2374.999268,3720.277100 2375.055420,3721.319336 2374.953613,3723.108887 
	C2374.872070,3726.444336 2374.986328,3729.037354 2375.006104,3731.631348 
	C2375.114746,3745.831543 2376.864990,3746.813721 2390.528809,3739.647461 
	C2393.869141,3736.916260 2399.470215,3737.615723 2399.028320,3731.089844 
	C2398.879883,3727.682617 2399.294189,3725.119873 2401.109863,3722.086426 
	C2403.215088,3720.488525 2404.852539,3720.390137 2407.266113,3721.513672 
	C2409.724121,3727.107910 2410.132812,3732.074463 2408.242432,3737.422363 
	C2407.093262,3740.673584 2405.560059,3744.294922 2408.005371,3748.810791 
	C2403.055176,3748.726074 2399.077637,3747.638428 2396.826904,3753.781494 
	C2395.452637,3757.531982 2390.629883,3760.181641 2392.760986,3765.373535 
	C2394.909912,3770.608643 2388.531738,3770.148193 2387.355469,3773.560059 
	C2386.498535,3776.047119 2384.346680,3772.800537 2382.873535,3771.941162 
	C2379.543457,3769.999268 2375.739990,3768.770996 2373.123047,3765.402832 
	C2372.921143,3771.380127 2373.958984,3776.636963 2379.466064,3779.680908 
	C2384.317139,3782.362549 2385.806152,3787.209229 2387.453857,3791.797119 
	C2388.555664,3794.864746 2389.190430,3799.095459 2386.729736,3801.292969 
	C2381.866699,3805.635010 2380.769043,3812.262207 2376.115234,3817.315430 
	C2375.990234,3818.000000 2375.026367,3818.031250 2374.544434,3818.043457 
	C2371.963867,3820.272949 2369.512207,3821.591797 2366.334473,3821.406494 
	C2362.511963,3821.183594 2360.003174,3822.704834 2360.023926,3826.882080 
	C2360.058105,3833.737061 2355.622314,3833.657959 2350.243652,3833.459717 
z"
        />
        <path
          fill="#C2825D"
          opacity="1.000000"
          stroke="none"
          d="
M2415.694092,3624.556641 
	C2416.004883,3623.047607 2416.010010,3622.173096 2416.027100,3620.643066 
	C2419.444580,3617.864014 2418.660400,3613.494385 2421.520996,3610.397705 
	C2424.917725,3609.924072 2426.737061,3611.890625 2429.360596,3613.677246 
	C2429.913330,3620.491455 2430.277588,3626.755371 2426.707275,3633.066406 
	C2424.694824,3636.624023 2426.030762,3642.129639 2426.029053,3646.757812 
	C2426.026855,3653.088379 2424.806152,3658.750244 2421.863525,3664.717773 
	C2418.822510,3670.885254 2414.086670,3678.073242 2419.534180,3685.690430 
	C2417.142334,3695.399414 2417.753662,3706.194092 2408.690918,3713.872314 
	C2405.946777,3715.705078 2404.023926,3714.992188 2401.577637,3713.148438 
	C2397.160889,3704.150146 2397.853516,3696.121338 2403.492920,3687.875977 
	C2404.964111,3684.945801 2407.048096,3683.007812 2406.632324,3679.401123 
	C2407.844238,3674.258057 2408.671143,3669.614746 2413.381836,3666.303223 
	C2416.172363,3665.661377 2417.789062,3664.645508 2417.866211,3662.270508 
	C2417.964355,3659.245361 2418.629395,3656.407715 2420.101562,3653.769043 
	C2422.595459,3649.298096 2422.125244,3646.510986 2416.321289,3645.566406 
	C2412.844238,3645.000732 2411.564941,3642.238525 2412.811523,3638.791016 
	C2413.348633,3637.304932 2413.860840,3635.461182 2415.739258,3635.199951 
	C2417.965332,3634.890625 2417.687256,3637.480225 2418.918945,3638.358643 
	C2419.361328,3638.673828 2420.083008,3638.597412 2420.935303,3638.743896 
	C2422.268311,3635.607910 2423.170410,3632.621338 2418.745117,3630.154297 
	C2416.361816,3628.834229 2415.834717,3627.035156 2415.694092,3624.556641 
z"
        />
        <path
          fill="#805541"
          opacity="1.000000"
          stroke="none"
          d="
M2438.892090,3727.450928 
	C2437.935791,3728.043457 2437.592285,3728.025391 2436.733398,3727.989990 
	C2435.419434,3720.617920 2434.621094,3713.263428 2433.803711,3705.731934 
	C2430.766113,3706.588623 2432.586914,3709.566406 2430.952637,3711.645508 
	C2429.993164,3712.428711 2429.452148,3712.679443 2428.017578,3712.870361 
	C2422.236816,3709.343994 2423.801025,3704.472168 2424.866943,3700.133301 
	C2425.743164,3696.565674 2425.513428,3692.821777 2427.410645,3688.646973 
	C2427.348877,3685.738770 2426.794434,3683.573975 2426.184326,3680.737305 
	C2428.622803,3663.014893 2441.739746,3650.448242 2445.612305,3633.961670 
	C2446.132812,3631.746094 2446.967041,3629.190430 2449.985352,3629.323975 
	C2453.449219,3629.477051 2452.831055,3632.299561 2452.992920,3635.349609 
	C2453.039795,3636.716309 2453.079346,3637.267090 2453.369629,3638.619141 
	C2452.658203,3651.653809 2446.862549,3662.721680 2445.556641,3675.009277 
	C2444.028076,3689.393799 2440.323975,3703.546631 2441.129883,3718.185059 
	C2441.296143,3721.205078 2440.997314,3724.133789 2438.892090,3727.450928 
z"
        />
        <path
          fill="#BA7C55"
          opacity="1.000000"
          stroke="none"
          d="
M2487.382324,3581.406982 
	C2489.713623,3577.160645 2489.777832,3572.332031 2495.418457,3571.424805 
	C2499.575195,3570.756104 2496.525391,3565.211182 2500.468506,3562.901611 
	C2503.103027,3567.954102 2502.148438,3572.911621 2501.033691,3577.887451 
	C2499.893311,3582.977051 2498.799805,3587.822510 2499.590820,3593.367920 
	C2500.668701,3600.928711 2496.144531,3607.938477 2494.700928,3615.361328 
	C2494.216064,3617.855225 2491.108398,3617.923584 2489.227051,3615.463379 
	C2487.697510,3613.463867 2488.038086,3611.053955 2488.015137,3608.760986 
	C2487.926270,3599.876465 2488.318848,3590.976074 2487.382324,3581.406982 
z"
        />
        <path
          fill="#935F47"
          opacity="1.000000"
          stroke="none"
          d="
M2452.501953,3636.538086 
	C2451.800049,3634.405029 2452.421143,3632.237061 2450.713379,3631.483398 
	C2448.457031,3630.487549 2448.091309,3632.733154 2447.740234,3634.204590 
	C2443.818604,3650.629150 2432.487305,3663.753174 2426.823486,3679.884766 
	C2425.620605,3670.484863 2427.708984,3661.274902 2433.629395,3653.934326 
	C2438.827393,3647.489746 2441.592041,3640.471191 2443.598633,3632.752197 
	C2444.843506,3627.963867 2446.708252,3623.335938 2448.299561,3618.637939 
	C2448.788086,3617.195312 2449.945801,3616.452393 2451.318848,3616.641113 
	C2452.694580,3616.829834 2453.666504,3617.891602 2453.728516,3619.372559 
	C2453.880371,3623.007568 2453.934570,3626.646729 2454.061035,3631.167236 
	C2454.058350,3632.926758 2454.022461,3633.803467 2453.991943,3635.338379 
	C2453.665771,3636.356201 2453.333984,3636.715576 2452.501953,3636.538086 
z"
        />
        <path
          fill="#BA7C55"
          opacity="1.000000"
          stroke="none"
          d="
M2430.734375,3604.027588 
	C2430.022217,3601.707031 2430.145752,3599.394531 2429.980713,3597.103271 
	C2429.627197,3592.198730 2431.573242,3590.328613 2436.697510,3589.783936 
	C2443.043457,3589.109131 2449.759033,3587.896240 2453.499756,3580.462402 
	C2454.260254,3580.229248 2454.700684,3580.455566 2454.703857,3580.687744 
	C2454.760986,3584.877197 2446.883789,3601.101562 2444.427490,3603.856689 
	C2439.928467,3608.902588 2435.771729,3605.526123 2430.734375,3604.027588 
z"
        />
        <path
          fill="#CC855B"
          opacity="1.000000"
          stroke="none"
          d="
M2401.307129,3711.531250 
	C2403.601807,3712.628174 2405.164795,3713.256348 2407.358887,3713.942383 
	C2407.991455,3716.132324 2407.992676,3718.264648 2407.987305,3721.196533 
	C2406.478271,3721.998291 2404.975830,3722.000244 2402.722168,3722.006104 
	C2399.952393,3724.145508 2397.800537,3725.938232 2393.843262,3724.397461 
	C2391.313477,3721.301270 2391.268066,3718.489258 2392.496582,3714.773438 
	C2394.876221,3711.523193 2397.368408,3710.349121 2401.307129,3711.531250 
z"
        />
        <path
          fill="#935F47"
          opacity="1.000000"
          stroke="none"
          d="
M2427.172363,3688.126221 
	C2428.296875,3692.148438 2427.535645,3696.197998 2426.460693,3700.323486 
	C2425.578369,3703.708984 2425.488525,3707.368408 2427.301514,3711.438477 
	C2428.757568,3712.705322 2429.100098,3713.598389 2428.290283,3715.296875 
	C2426.899170,3715.903564 2426.042969,3715.940430 2424.541748,3715.981201 
	C2421.939209,3714.277100 2421.900635,3711.959473 2422.035400,3709.672607 
	C2422.467529,3702.356201 2422.699707,3694.985840 2427.172363,3688.126221 
z"
        />
        <path
          fill="#935F47"
          opacity="1.000000"
          stroke="none"
          d="
M2413.337891,3754.098145 
	C2413.988281,3755.513672 2413.986328,3757.036865 2413.949463,3759.323242 
	C2409.858643,3758.888184 2408.772217,3757.069580 2413.337891,3754.098145 
z"
        />
        <path
          fill="#935F47"
          opacity="1.000000"
          stroke="none"
          d="
M2399.363770,3800.085938 
	C2399.824463,3801.747314 2399.323242,3803.281006 2396.741211,3803.915527 
	C2396.195801,3802.204346 2396.738525,3800.702148 2399.363770,3800.085938 
z"
        />
        <path
          fill="#D89066"
          opacity="1.000000"
          stroke="none"
          d="
M2458.462891,3517.449707 
	C2449.643311,3523.153076 2448.827637,3531.367188 2450.453125,3539.629883 
	C2452.403320,3549.542236 2445.766113,3555.082031 2440.590088,3561.168213 
	C2438.884766,3563.173828 2435.640137,3564.109375 2434.514648,3567.147461 
	C2433.873047,3568.879883 2433.761230,3571.703125 2430.982666,3570.898193 
	C2428.327393,3570.128906 2425.934814,3567.889648 2426.928467,3564.940918 
	C2428.567139,3560.078613 2427.103027,3554.911133 2428.958252,3550.181641 
	C2430.088867,3547.299072 2429.970703,3544.312744 2429.970947,3541.314453 
	C2429.971191,3537.523438 2428.077393,3535.871826 2424.356445,3536.112793 
	C2420.738525,3536.347412 2420.499268,3538.264893 2420.685791,3541.539795 
	C2421.159180,3549.859863 2419.048340,3552.341553 2410.708496,3553.959473 
	C2408.160889,3554.453613 2406.483154,3555.435791 2405.215576,3557.621338 
	C2401.099121,3564.719727 2395.701416,3571.047119 2393.608887,3579.336426 
	C2392.446289,3583.943359 2386.347656,3586.163330 2383.431396,3582.701172 
	C2379.254639,3577.742188 2375.214355,3578.312500 2370.170410,3579.815674 
	C2368.336182,3580.362061 2366.191650,3580.032715 2364.206787,3579.874756 
	C2360.576904,3579.585938 2359.735840,3577.445312 2360.972168,3574.318604 
	C2361.704834,3572.465820 2362.775879,3570.748779 2363.540527,3568.906494 
	C2364.510742,3566.567871 2366.746094,3564.202148 2364.156494,3561.644043 
	C2362.491455,3559.999268 2360.275879,3558.214111 2358.035645,3558.988281 
	C2355.160889,3559.982178 2352.987061,3562.934326 2352.891602,3565.727783 
	C2352.770752,3569.254150 2354.247559,3572.573975 2351.516113,3576.036133 
	C2349.369629,3578.757568 2354.957764,3582.615723 2351.045410,3585.817871 
	C2350.811035,3586.009766 2351.035156,3587.343994 2351.424805,3587.628906 
	C2357.709473,3592.225098 2356.686523,3596.798828 2351.666504,3601.628662 
	C2349.899902,3603.328125 2351.200928,3605.094971 2351.910645,3607.038086 
	C2354.138184,3613.137451 2352.278564,3615.769775 2346.066650,3615.964355 
	C2345.071777,3615.995605 2344.069824,3615.919922 2343.075928,3615.837891 
	C2335.661621,3615.227783 2335.660156,3615.224121 2338.268555,3622.594482 
	C2338.710205,3623.841797 2339.944092,3625.670166 2339.511963,3626.235107 
	C2333.860107,3633.620361 2339.426270,3639.365967 2341.673584,3645.854980 
	C2342.903076,3649.405029 2342.597412,3653.812500 2341.083740,3657.650635 
	C2337.687012,3666.263916 2339.148438,3675.781006 2336.006592,3685.214600 
	C2335.486816,3686.988525 2334.963867,3687.976807 2334.215576,3689.477783 
	C2332.109375,3691.865967 2329.727783,3693.051758 2327.619385,3694.613037 
	C2316.150146,3703.107910 2310.270264,3702.350830 2300.537598,3690.609375 
	C2300.113037,3680.119385 2300.507324,3670.275391 2305.083008,3661.106934 
	C2306.464844,3658.337891 2306.525146,3655.331543 2303.886230,3652.152344 
	C2303.460449,3650.260254 2303.694092,3648.831543 2304.200684,3646.729492 
	C2311.916992,3632.932861 2309.286865,3617.756836 2313.040771,3603.867432 
	C2314.482178,3598.533936 2316.512939,3593.005371 2312.655762,3586.902832 
	C2311.922119,3583.492920 2312.593750,3581.010742 2314.010010,3577.914795 
	C2322.017822,3569.008789 2324.281006,3560.310303 2323.092285,3548.329346 
	C2321.930664,3536.620361 2322.895752,3523.807373 2326.460693,3511.878662 
	C2328.172852,3506.150391 2329.459717,3500.393311 2330.203857,3494.394775 
	C2331.091064,3487.243652 2334.632324,3481.327637 2342.073730,3478.493652 
	C2351.354004,3474.959961 2355.359863,3468.361572 2354.648926,3458.524658 
	C2354.529053,3456.863037 2354.270996,3455.155029 2353.647461,3453.648926 
	C2347.922852,3439.823975 2355.936279,3430.241211 2363.996094,3420.928711 
	C2368.773193,3415.409424 2373.830566,3421.557129 2379.477051,3421.805908 
	C2385.785400,3426.391846 2383.849365,3430.037598 2378.789795,3432.737061 
	C2373.459717,3435.581299 2371.960205,3439.270020 2373.855713,3445.049805 
	C2375.836426,3451.090820 2374.566895,3456.858154 2370.847900,3462.114990 
	C2366.991699,3467.565674 2366.109131,3472.281982 2371.982910,3477.764160 
	C2377.635498,3483.039795 2372.810547,3496.672852 2364.205078,3496.635986 
	C2348.128662,3496.567383 2347.466797,3509.654297 2342.453369,3518.510254 
	C2338.787598,3524.985107 2338.753418,3533.257812 2339.085938,3541.006836 
	C2339.367920,3547.581055 2342.876953,3548.879883 2348.500244,3546.974121 
	C2351.645996,3545.907471 2354.512207,3544.030273 2357.897461,3543.667725 
	C2364.675537,3542.942139 2369.624756,3545.383545 2372.068848,3552.010498 
	C2372.760742,3553.886719 2372.977783,3555.908691 2373.682373,3557.782959 
	C2374.926514,3561.093506 2376.877930,3564.162109 2380.768555,3563.645020 
	C2385.323486,3563.039551 2384.778320,3558.938477 2384.672119,3555.700684 
	C2384.469238,3549.531738 2381.982666,3544.115479 2378.755615,3538.938965 
	C2376.797119,3535.796875 2374.897217,3532.160889 2377.352783,3528.677734 
	C2380.204834,3524.631592 2383.889160,3526.719971 2387.113770,3528.544434 
	C2390.274414,3530.333252 2392.637695,3528.928955 2395.405762,3527.415527 
	C2402.312012,3523.639648 2405.339600,3516.003418 2411.875732,3511.657227 
	C2415.308838,3509.374512 2412.169189,3504.898438 2410.629150,3501.663330 
	C2405.977051,3491.889160 2406.645264,3492.910400 2415.306396,3486.845459 
	C2423.478271,3481.123047 2428.102539,3471.805420 2431.034668,3462.094727 
	C2432.781982,3456.308105 2434.799561,3451.061279 2443.192139,3452.747070 
	C2438.443359,3446.925049 2439.699951,3441.411865 2441.958984,3436.204102 
	C2444.858643,3429.520020 2445.385498,3423.793213 2439.518555,3418.111084 
	C2434.314209,3413.070312 2436.449707,3406.051758 2437.045166,3399.986816 
	C2437.509766,3395.251709 2445.021484,3393.791504 2449.274170,3398.058838 
	C2454.755859,3403.560303 2461.923584,3407.849365 2463.760742,3414.984619 
	C2455.463867,3400.080811 2448.612305,3383.011963 2436.661865,3368.876953 
	C2433.462646,3365.092773 2430.548096,3360.752930 2433.311279,3354.654785 
	C2444.217285,3353.046143 2452.461670,3356.276123 2458.006592,3365.748779 
	C2462.015381,3372.597656 2467.943359,3378.225342 2470.927246,3385.785400 
	C2471.649658,3387.615234 2472.781738,3389.843018 2474.436035,3390.531494 
	C2492.138184,3397.896973 2488.703125,3413.430664 2488.305176,3427.282715 
	C2487.864990,3442.593018 2486.121338,3457.862549 2482.513184,3473.466309 
	C2482.039307,3478.100342 2482.030029,3482.180908 2482.026855,3487.136230 
	C2480.681152,3490.140137 2478.306885,3490.634277 2475.619141,3491.919434 
	C2469.067871,3495.800781 2467.040039,3503.360840 2460.299805,3506.343506 
	C2458.294189,3507.230957 2458.908447,3510.489014 2459.532227,3513.494629 
	C2459.553467,3515.151611 2459.330811,3516.062988 2458.462891,3517.449707 
z"
        />
        <path
          fill="#C2825D"
          opacity="1.000000"
          stroke="none"
          d="
M2482.845215,3474.014648 
	C2482.287354,3466.447510 2481.025635,3458.789062 2483.697510,3451.354736 
	C2484.349365,3449.541260 2484.484375,3447.154785 2483.151123,3445.596436 
	C2480.521973,3442.522461 2480.391113,3440.708252 2484.724609,3439.314209 
	C2487.297119,3438.486572 2488.044922,3436.163574 2486.726807,3433.628174 
	C2484.668457,3429.668701 2483.022705,3425.925293 2486.698975,3421.786621 
	C2488.561035,3419.690674 2486.944580,3417.101074 2485.554443,3415.435791 
	C2482.627441,3411.928711 2482.489990,3408.827881 2484.434570,3404.622559 
	C2486.431396,3400.304443 2483.855225,3396.445312 2479.032959,3396.960693 
	C2474.691650,3397.425049 2473.538330,3395.905762 2471.701660,3392.562500 
	C2465.980713,3382.148193 2458.891357,3372.565918 2452.394287,3362.620850 
	C2450.339844,3359.476074 2448.040039,3357.713379 2444.360107,3358.017334 
	C2440.784668,3358.312256 2437.914062,3356.913574 2434.357910,3353.712891 
	C2431.155518,3346.781006 2418.015381,3337.000977 2412.295410,3337.603271 
	C2405.683105,3338.299316 2402.046631,3341.855225 2403.601318,3349.757812 
	C2403.679443,3351.463623 2403.547852,3352.435547 2403.036621,3354.097900 
	C2401.667236,3356.440918 2400.753174,3358.195068 2399.674805,3359.836182 
	C2393.377197,3369.419189 2391.286377,3369.336182 2384.533691,3358.649414 
	C2380.933594,3343.504395 2390.652344,3333.531250 2396.895508,3322.579346 
	C2399.310059,3318.343506 2404.862793,3319.745361 2408.870361,3321.331543 
	C2418.845703,3325.279785 2428.728516,3327.948975 2439.808594,3326.958740 
	C2445.868164,3326.416992 2452.063721,3329.649170 2457.017578,3333.871094 
	C2460.871826,3337.156006 2465.213135,3339.484863 2470.658691,3341.486328 
	C2474.197266,3343.363525 2476.487305,3345.431885 2477.843262,3349.266357 
	C2477.930664,3351.369385 2477.984131,3352.701172 2477.881348,3354.020752 
	C2477.577148,3357.923340 2474.759033,3361.639404 2476.517090,3365.644531 
	C2478.431885,3370.006348 2483.082764,3369.447754 2486.722656,3370.694580 
	C2487.622803,3371.002930 2488.460938,3371.677979 2489.155762,3372.359375 
	C2490.293701,3373.475098 2491.885498,3374.664551 2490.776367,3376.499023 
	C2489.910889,3377.930664 2488.439697,3376.996582 2487.196533,3376.792236 
	C2486.172363,3376.624023 2485.301758,3376.919434 2484.780273,3378.151123 
	C2485.457031,3381.782227 2488.503418,3383.196777 2491.482422,3384.631104 
	C2492.663330,3385.199463 2493.988281,3385.770020 2495.106689,3384.503418 
	C2496.443115,3382.989502 2496.694824,3379.271484 2499.915527,3381.445801 
	C2502.901123,3383.461670 2507.565918,3386.393555 2506.944092,3389.265625 
	C2505.152832,3397.535645 2512.364990,3399.832764 2515.705078,3405.405518 
	C2519.792969,3408.768066 2511.587158,3412.779053 2517.456055,3415.923096 
	C2522.260986,3424.402344 2520.914795,3432.604736 2518.536377,3441.415283 
	C2514.389160,3445.989990 2516.385498,3450.846680 2516.050537,3455.306396 
	C2515.457031,3463.215332 2514.787109,3463.832520 2507.325439,3461.691895 
	C2501.776855,3460.100098 2496.562988,3461.974854 2493.034424,3468.980713 
	C2490.699463,3473.617188 2487.768799,3474.205811 2482.845215,3474.014648 
z"
        />
        <path
          fill="#C2825D"
          opacity="1.000000"
          stroke="none"
          d="
M2473.501953,3490.514160 
	C2475.698730,3487.714111 2478.195312,3488.061035 2481.302002,3488.009766 
	C2486.783447,3492.913574 2487.513428,3498.624756 2484.262207,3504.354248 
	C2482.104004,3508.157227 2481.393066,3511.734863 2482.923096,3515.675293 
	C2484.307617,3519.240234 2483.659180,3522.448975 2480.864990,3524.664551 
	C2478.072754,3526.877930 2474.613037,3526.333984 2471.414551,3525.042480 
	C2462.834229,3521.578369 2462.832275,3521.562256 2460.305664,3530.204590 
	C2459.845459,3531.779541 2459.475586,3533.380859 2458.538574,3535.482910 
	C2455.161865,3533.396240 2455.757080,3530.157715 2456.674805,3527.091797 
	C2457.416748,3524.613281 2458.083984,3522.188721 2457.978271,3518.777100 
	C2457.969971,3517.086426 2457.969238,3516.211914 2457.965820,3514.681152 
	C2456.982178,3511.432861 2458.217041,3509.110840 2460.020264,3507.938232 
	C2466.405762,3503.787109 2469.168701,3497.031006 2473.501953,3490.514160 
z"
        />
        <path
          fill="#AB7453"
          opacity="1.000000"
          stroke="none"
          d="
M2518.498535,3442.442871 
	C2515.935547,3433.958008 2519.558838,3425.497314 2516.089355,3416.780273 
	C2514.065674,3411.344238 2516.662598,3410.679688 2521.185059,3411.503418 
	C2525.067871,3419.299805 2525.175293,3427.260498 2527.407959,3435.442139 
	C2527.936768,3436.891846 2527.940430,3437.777588 2527.931641,3439.327148 
	C2524.322266,3442.256348 2527.904053,3447.840332 2522.743652,3449.859375 
	C2519.301270,3448.385986 2520.625000,3445.002930 2518.498535,3442.442871 
z"
        />
        <path
          fill="#CC855B"
          opacity="1.000000"
          stroke="none"
          d="
M2504.744385,3704.148193 
	C2503.358154,3692.191650 2502.551025,3680.408447 2502.017578,3668.612549 
	C2501.630127,3660.041748 2502.301270,3651.545898 2505.655762,3642.696289 
	C2514.908691,3637.481934 2515.976562,3637.745605 2519.818848,3646.512451 
	C2520.770996,3648.685547 2521.495605,3651.100830 2523.479736,3652.258057 
	C2529.994141,3656.058838 2530.433838,3661.977295 2530.057617,3668.544189 
	C2529.624268,3676.109131 2530.571045,3683.696533 2528.321533,3691.221924 
	C2527.226318,3694.885986 2528.312256,3699.100342 2523.521973,3701.131104 
	C2521.587402,3701.951172 2522.265381,3704.669922 2521.890625,3706.578613 
	C2521.022949,3710.998047 2518.421387,3713.743164 2513.781738,3713.806396 
	C2509.540527,3713.864258 2508.284180,3710.830566 2507.802490,3707.250488 
	C2507.609375,3705.814209 2506.709229,3704.906982 2504.744385,3704.148193 
z"
        />
        <path
          fill="#935F47"
          opacity="1.000000"
          stroke="none"
          d="
M2497.880615,3693.988770 
	C2495.946533,3696.055664 2496.084717,3694.236084 2495.930664,3693.346436 
	C2495.850342,3692.882812 2496.276611,3691.942627 2496.620605,3691.864502 
	C2497.756348,3691.606689 2497.817871,3692.615967 2497.880615,3693.988770 
z"
        />
        <path
          fill="#935F47"
          opacity="1.000000"
          stroke="none"
          d="
M2495.496826,3699.092285 
	C2495.578125,3697.905029 2496.140625,3697.593262 2496.882568,3697.911621 
	C2497.102295,3698.005859 2497.316162,3698.518799 2497.258789,3698.778809 
	C2497.077637,3699.598389 2496.503662,3699.833984 2495.496826,3699.092285 
z"
        />
        <path
          fill="#CC855B"
          opacity="1.000000"
          stroke="none"
          d="
M2438.770996,3728.631348 
	C2437.924561,3701.834961 2440.699219,3675.997070 2447.986816,3650.735107 
	C2449.082520,3646.936279 2450.493896,3643.228271 2451.882324,3638.741211 
	C2452.337402,3637.699951 2452.668945,3637.396240 2453.500244,3637.545898 
	C2461.100586,3643.982178 2468.899658,3649.013184 2470.668701,3659.633301 
	C2471.440430,3664.264404 2475.445557,3668.229492 2471.001221,3673.008789 
	C2470.457520,3673.593018 2470.820312,3675.828613 2471.524414,3676.628906 
	C2475.282715,3680.900146 2474.640625,3686.104980 2475.510498,3691.556152 
	C2476.004883,3692.888916 2476.000000,3693.777832 2475.997559,3695.333496 
	C2473.127197,3696.268799 2470.279541,3697.607910 2470.433594,3700.491455 
	C2470.968018,3710.513428 2467.638916,3720.429688 2469.888916,3731.221680 
	C2467.879150,3732.004639 2465.772705,3732.019043 2462.876221,3732.037842 
	C2455.515137,3730.054932 2455.252686,3725.246826 2457.383789,3720.135742 
	C2462.540527,3707.766846 2461.635254,3694.704102 2462.304932,3681.818359 
	C2462.683105,3674.539551 2456.378906,3670.348145 2453.785156,3664.099365 
	C2446.470215,3684.312744 2444.833984,3704.639648 2449.849365,3725.374023 
	C2451.216553,3731.025146 2457.384521,3730.152100 2461.399902,3733.475098 
	C2461.892822,3737.502441 2462.851318,3742.634277 2458.469971,3743.174805 
	C2450.116455,3744.205811 2454.060791,3748.775635 2455.391846,3752.474854 
	C2448.023193,3754.332275 2444.294678,3748.924561 2438.733643,3746.101562 
	C2439.313721,3740.464600 2441.240479,3734.976074 2438.770996,3728.631348 
z"
        />
        <path
          fill="#E59F70"
          opacity="1.000000"
          stroke="none"
          d="
M2461.987793,3733.465088 
	C2459.296387,3733.928467 2456.620605,3734.066406 2453.969482,3733.871582 
	C2449.827637,3733.567139 2446.946533,3731.203613 2446.327881,3727.197021 
	C2444.045898,3712.417236 2442.307129,3697.603271 2445.416260,3682.678223 
	C2446.496338,3677.494141 2447.325928,3672.242676 2447.941895,3666.983398 
	C2448.269775,3664.184326 2449.051514,3661.799316 2451.145752,3659.926025 
	C2452.577637,3658.644775 2453.760254,3656.485352 2456.044189,3657.175537 
	C2458.545654,3657.931396 2457.989746,3660.420410 2457.933594,3662.268555 
	C2457.821533,3665.937012 2459.439453,3668.629883 2461.988037,3671.003906 
	C2464.226807,3673.088867 2466.133789,3675.402588 2465.932617,3678.731934 
	C2465.239014,3690.206543 2464.597168,3701.685303 2463.755371,3713.149414 
	C2463.559326,3715.821777 2462.235352,3718.335693 2460.442627,3720.315186 
	C2456.854248,3724.278320 2458.464844,3727.555420 2461.740234,3731.428223 
	C2462.085938,3732.042236 2462.014893,3732.990479 2461.987793,3733.465088 
z"
        />
        <path
          fill="#B0714F"
          opacity="1.000000"
          stroke="none"
          d="
M2453.999756,3637.499023 
	C2453.999756,3637.999756 2453.000000,3638.001221 2452.502930,3638.002441 
	C2452.003906,3637.669922 2452.001953,3637.335938 2452.000977,3636.501465 
	C2452.001709,3636.001221 2453.000977,3635.999756 2453.499023,3635.998047 
	C2453.998047,3636.330322 2453.999023,3636.664307 2453.999756,3637.499023 
z"
        />
        <path
          fill="#96664C"
          opacity="1.000000"
          stroke="none"
          d="
M2545.377930,3355.364014 
	C2545.555176,3357.546387 2545.646973,3359.012207 2545.662842,3361.208984 
	C2544.065918,3363.471924 2544.206055,3365.808105 2542.384033,3367.771973 
	C2536.479492,3368.509033 2533.478516,3364.971924 2530.785645,3361.202637 
	C2529.254395,3359.059570 2528.021973,3356.748047 2525.391113,3355.748291 
	C2523.927002,3355.191895 2522.099854,3353.750000 2520.974121,3355.515869 
	C2515.739502,3363.727539 2514.379883,3356.953125 2512.076172,3352.684082 
	C2512.385498,3351.615479 2512.771729,3351.231445 2513.156250,3350.845947 
	C2513.541016,3350.460205 2513.411377,3350.587158 2512.385254,3351.615234 
	C2508.973877,3352.545898 2504.437988,3350.233887 2503.239990,3354.055664 
	C2501.833496,3358.542236 2506.684082,3359.515625 2509.676025,3361.297607 
	C2510.387695,3361.721436 2510.704590,3362.807373 2511.260986,3363.679688 
	C2501.658691,3365.170654 2499.728760,3364.137695 2492.094238,3353.763916 
	C2477.477295,3332.857910 2458.617920,3321.561035 2434.497559,3318.900879 
	C2428.420166,3318.230469 2420.665771,3319.549805 2423.174316,3308.998535 
	C2423.418457,3307.970703 2412.065674,3304.341553 2410.621826,3305.167725 
	C2407.968994,3306.684814 2405.379395,3308.056641 2401.661621,3306.366211 
	C2399.653320,3303.647461 2399.712891,3301.302734 2401.086670,3298.315186 
	C2403.749512,3294.546143 2410.232666,3294.780518 2408.715820,3287.979004 
	C2408.473633,3286.428711 2408.498291,3285.529053 2408.837891,3283.968018 
	C2411.059814,3280.618652 2411.476074,3277.086426 2413.587402,3274.479492 
	C2416.598633,3270.761475 2420.523193,3268.348633 2424.708740,3271.914551 
	C2429.470947,3275.972656 2426.739990,3279.446777 2422.817383,3282.056885 
	C2427.043701,3280.644775 2428.553955,3277.411133 2429.541260,3272.804688 
	C2431.372070,3264.264648 2436.906738,3262.341797 2444.285400,3266.914062 
	C2451.623779,3271.460693 2458.679199,3275.097168 2457.246582,3286.796631 
	C2456.692139,3291.323486 2456.828125,3300.090820 2461.822266,3304.065918 
	C2470.613281,3311.063965 2479.411621,3321.408447 2490.064697,3321.933350 
	C2505.455078,3322.692139 2514.117432,3331.250000 2523.629639,3340.433105 
	C2528.595215,3345.227051 2533.322510,3350.472168 2541.067871,3352.454590 
	C2542.245850,3352.578125 2542.712891,3352.681152 2543.811035,3353.132812 
	C2544.725342,3353.870117 2545.009033,3354.258789 2545.377930,3355.364014 
z"
        />
        <path
          fill="#96664C"
          opacity="1.000000"
          stroke="none"
          d="
M2478.574707,3350.294189 
	C2475.631592,3348.953369 2473.946777,3347.156006 2472.175537,3344.585449 
	C2473.950684,3335.512207 2478.819580,3337.588379 2483.483398,3341.305420 
	C2487.133545,3344.213867 2488.680420,3348.845703 2491.561523,3353.376709 
	C2492.009766,3354.009766 2492.000000,3354.000000 2491.995117,3353.995117 
	C2491.873291,3355.541016 2492.348145,3357.230225 2490.623047,3358.774902 
	C2486.591309,3356.478027 2483.866211,3352.076172 2478.574707,3350.294189 
z"
        />
        <path
          fill="#BA7C55"
          opacity="1.000000"
          stroke="none"
          d="
M2521.922852,3411.396484 
	C2519.704834,3412.107666 2517.223389,3411.774902 2516.113770,3415.334717 
	C2514.659424,3415.886963 2513.291260,3415.942139 2512.033203,3415.596436 
	C2510.396729,3415.146484 2508.940674,3414.213623 2509.041260,3412.211426 
	C2509.127197,3410.494629 2510.623291,3410.273682 2511.901855,3410.042725 
	C2513.624512,3409.731445 2515.072510,3409.136475 2515.863281,3406.673828 
	C2517.007812,3406.517578 2518.006104,3407.035400 2519.528809,3407.759766 
	C2520.660400,3408.915283 2521.267578,3409.863770 2521.922852,3411.396484 
z"
        />
        <path
          fill="#805541"
          opacity="1.000000"
          stroke="none"
          d="
M2541.972412,3368.681641 
	C2542.151367,3365.928711 2541.679199,3363.439941 2544.947754,3362.099121 
	C2546.630615,3364.758057 2546.359131,3367.640137 2545.715820,3371.349609 
	C2543.688477,3371.925293 2542.541992,3370.999756 2541.972412,3368.681641 
z"
        />
        <path
          fill="#96664C"
          opacity="1.000000"
          stroke="none"
          d="
M2517.255371,3387.643555 
	C2513.600098,3387.182861 2511.595947,3384.719482 2513.169189,3383.766602 
	C2516.393311,3381.813721 2516.161621,3385.572998 2517.255371,3387.643555 
z"
        />
        <path
          fill="#102131"
          opacity="1.000000"
          stroke="none"
          d="
M2544.592285,3382.817139 
	C2545.747559,3383.196533 2546.003174,3384.189209 2544.895508,3385.567139 
	C2544.483643,3385.021973 2544.382080,3384.197021 2544.592285,3382.817139 
z"
        />
        <path
          fill="#96664C"
          opacity="1.000000"
          stroke="none"
          d="
M2522.924805,3365.042236 
	C2522.877930,3364.954834 2522.971680,3365.129639 2522.924805,3365.042236 
z"
        />
        <path
          fill="#4A3831"
          opacity="1.000000"
          stroke="none"
          d="
M2546.118652,3355.632324 
	C2544.889893,3355.957031 2544.432617,3355.656494 2543.890137,3354.809082 
	C2532.658936,3339.187988 2530.149658,3322.304688 2531.352539,3303.678955 
	C2532.455078,3286.606201 2530.359375,3269.354736 2526.413330,3252.493652 
	C2525.571289,3248.895264 2522.561768,3244.599854 2526.834717,3241.467285 
	C2530.313477,3238.917480 2533.773438,3241.734375 2537.134033,3242.960938 
	C2542.531738,3244.931396 2553.634521,3237.593750 2556.888672,3229.435059 
	C2561.740479,3217.269287 2564.419189,3204.582764 2563.377686,3191.444336 
	C2562.655273,3182.335693 2563.544922,3173.661621 2566.418945,3164.949951 
	C2568.863281,3157.540039 2573.158447,3150.298096 2570.614014,3141.902832 
	C2570.432373,3141.303467 2570.502930,3140.371094 2570.905029,3139.978027 
	C2580.067139,3131.011230 2571.971191,3124.629883 2567.316650,3118.142090 
	C2563.637695,3113.014160 2563.049316,3109.367432 2566.416260,3103.136475 
	C2570.869385,3094.894531 2572.362549,3085.684082 2569.173340,3075.405273 
	C2564.590820,3060.637695 2559.213867,3046.110352 2555.581787,3030.602051 
	C2561.541748,3029.534180 2564.486816,3032.782715 2566.611084,3037.262939 
	C2570.844971,3046.192871 2575.612793,3054.829346 2581.468262,3063.573730 
	C2583.076660,3070.320801 2586.453613,3075.978516 2584.754883,3083.193359 
	C2583.798584,3087.254150 2585.869141,3093.083252 2587.514404,3097.041016 
	C2589.750488,3102.419922 2588.939209,3106.433105 2587.377441,3111.088379 
	C2587.166748,3111.716553 2586.950195,3112.348877 2586.662109,3112.943359 
	C2583.947266,3118.546875 2594.242432,3121.032959 2590.397461,3125.917969 
	C2587.019043,3130.210449 2586.185059,3133.745117 2588.412842,3138.438477 
	C2590.641357,3143.133545 2590.763184,3148.301758 2589.642090,3153.072510 
	C2587.399902,3162.612061 2587.593994,3172.063965 2588.833252,3181.626709 
	C2588.997070,3182.890381 2589.396240,3184.538330 2588.806885,3185.420166 
	C2583.747070,3192.991211 2584.678223,3201.923584 2582.999023,3210.236572 
	C2581.514648,3217.585205 2584.449707,3223.313965 2591.206299,3225.965576 
	C2595.204834,3229.278320 2593.196533,3232.649902 2592.255615,3236.109375 
	C2589.491943,3246.271729 2585.610352,3256.087158 2582.953125,3267.068848 
	C2582.907227,3269.407471 2582.628174,3270.921387 2581.841553,3273.096191 
	C2576.633301,3279.509521 2575.024414,3286.648438 2572.975342,3294.364258 
	C2572.848877,3297.193115 2572.274902,3299.113525 2570.424316,3301.279785 
	C2567.088379,3302.371338 2566.468750,3304.339600 2566.008545,3306.844482 
	C2564.275391,3316.281494 2561.047852,3325.297119 2557.711182,3334.260986 
	C2557.024902,3336.105469 2556.379883,3338.759033 2554.967773,3339.406250 
	C2547.946777,3342.622070 2548.639648,3349.507568 2546.118652,3355.632324 
z"
        />
        <path
          fill="#040F19"
          opacity="1.000000"
          stroke="none"
          d="
M2569.876465,3302.558594 
	C2569.906250,3299.684326 2570.006592,3297.626221 2570.210938,3294.798096 
	C2574.594971,3288.249756 2574.977539,3280.709961 2579.370117,3274.212891 
	C2579.808105,3272.207031 2579.873779,3270.803223 2580.020996,3268.700928 
	C2586.663818,3259.230469 2585.940674,3247.791992 2590.928711,3238.400635 
	C2592.460449,3235.516846 2593.054199,3232.134033 2596.214355,3229.438965 
	C2601.104248,3230.461914 2605.430908,3232.769043 2607.358398,3225.804932 
	C2607.825928,3224.115479 2610.762207,3224.139893 2613.435059,3225.030762 
	C2614.338623,3225.753418 2614.618408,3226.136963 2615.092285,3227.206543 
	C2616.258301,3234.754395 2611.907959,3241.482666 2615.101807,3249.014160 
	C2618.255371,3263.108154 2617.179688,3265.959229 2605.717041,3272.378174 
	C2619.416992,3277.567383 2626.423096,3287.285400 2630.862549,3300.302246 
	C2633.709717,3308.650146 2643.276611,3312.760986 2650.254150,3319.056641 
	C2650.953857,3319.058105 2650.945557,3319.195557 2650.915039,3319.257324 
	C2650.828369,3324.264648 2654.468018,3326.644775 2658.466309,3329.413574 
	C2659.799072,3330.132324 2660.444824,3330.690186 2661.193848,3331.654785 
	C2658.833008,3336.714844 2655.241699,3335.845459 2651.039307,3334.704834 
	C2642.433350,3332.368896 2634.035889,3329.384277 2625.355469,3327.208496 
	C2615.543213,3324.748779 2605.825195,3323.677734 2594.913086,3323.982910 
	C2588.852539,3319.107422 2583.605957,3313.966309 2576.649170,3312.294189 
	C2571.235840,3310.992676 2569.807617,3308.131104 2569.876465,3302.558594 
z"
        />
        <path
          fill="#040F19"
          opacity="1.000000"
          stroke="none"
          d="
M2583.907959,3329.356201 
	C2580.564453,3330.047363 2577.138672,3330.026123 2572.856445,3330.002441 
	C2570.640381,3329.145752 2569.218262,3328.375488 2567.933838,3327.420166 
	C2564.919678,3325.178223 2566.675537,3322.802979 2567.940186,3320.561768 
	C2569.225830,3318.283203 2570.982422,3318.609863 2572.846436,3319.908936 
	C2576.691895,3322.589111 2582.593262,3322.678467 2583.907959,3329.356201 
z"
        />
        <path
          fill="#102131"
          opacity="1.000000"
          stroke="none"
          d="
M2619.575684,3443.432617 
	C2614.501953,3448.720459 2611.923584,3449.461182 2608.359863,3445.934326 
	C2603.722656,3441.343994 2598.902344,3437.829346 2592.079102,3437.557373 
	C2590.034668,3437.476074 2588.713867,3435.593506 2588.109375,3432.734863 
	C2599.253174,3433.146973 2609.919678,3435.970459 2619.575684,3443.432617 
z"
        />
        <path
          fill="#091A29"
          opacity="1.000000"
          stroke="none"
          d="
M2549.610596,3423.465332 
	C2547.981445,3424.558105 2545.632812,3427.164062 2544.111816,3422.550293 
	C2545.798584,3422.033447 2547.608643,3421.870850 2549.610596,3423.465332 
z"
        />
        <path
          fill="#0C0D0F"
          opacity="1.000000"
          stroke="none"
          d="
M1077.546997,3163.477539 
	C1078.022949,3164.314453 1078.111816,3164.636719 1078.179199,3165.459961 
	C1072.479980,3165.887451 1066.665894,3170.205811 1061.085327,3165.048096 
	C1059.538330,3163.618408 1057.202881,3165.243652 1055.432007,3165.953613 
	C1036.712280,3173.456787 1017.540344,3179.842041 999.256409,3188.411621 
	C994.717834,3190.538574 990.149414,3192.495361 985.316223,3193.722168 
	C983.553223,3194.169678 981.048096,3194.574463 981.821960,3190.567383 
	C982.245667,3189.640137 982.484070,3189.292480 983.222961,3188.557129 
	C985.665466,3187.289062 987.447449,3186.202637 989.115662,3183.861328 
	C1004.443420,3176.318604 1019.970398,3170.815674 1035.445312,3163.404785 
	C1038.920044,3162.572754 1041.375732,3161.076416 1044.713013,3159.937500 
	C1046.821167,3159.058838 1048.953613,3159.302246 1049.422363,3157.350830 
	C1049.805176,3155.757568 1048.302612,3155.261719 1047.062378,3154.805176 
	C1042.002441,3152.942627 1037.351440,3154.010742 1032.262329,3157.007080 
	C1030.641968,3157.416016 1029.691284,3157.398438 1028.078857,3156.926270 
	C1025.252441,3153.874756 1022.401123,3152.123535 1018.912781,3152.453613 
	C1010.754456,3153.225342 1004.285095,3149.870850 997.823730,3145.356689 
	C989.819763,3139.764404 981.299377,3135.341309 971.099731,3140.728027 
	C968.817749,3141.932861 965.919739,3142.039307 963.364990,3139.195801 
	C962.737610,3133.631592 966.781006,3132.037842 969.826050,3129.664795 
	C972.114258,3127.881592 975.678223,3126.229980 974.479492,3122.907959 
	C973.124207,3119.152344 969.067505,3117.760498 965.381775,3118.558350 
	C955.336792,3120.732666 944.967224,3119.439941 934.987244,3121.768311 
	C931.092957,3122.677246 927.087646,3121.495117 922.773804,3123.139404 
	C920.372498,3125.416748 918.969788,3127.673584 916.713806,3130.069824 
	C912.008728,3131.105469 910.634399,3127.608887 908.489319,3125.253662 
	C905.393616,3121.855225 902.642822,3117.910889 896.607178,3118.387207 
	C890.595764,3122.520508 882.930420,3120.082764 877.273438,3125.392090 
	C872.598633,3127.229980 868.629272,3127.339600 864.302979,3124.462891 
	C864.336426,3119.547852 862.708740,3114.363525 869.096252,3111.646973 
	C891.243530,3104.249023 913.042236,3102.987793 935.986938,3107.221191 
	C940.678894,3108.148193 944.588989,3108.703369 948.579712,3108.837158 
	C962.684875,3109.309326 976.724182,3105.151855 990.964600,3108.707031 
	C993.855652,3109.428467 998.284363,3109.416016 1002.001831,3106.232910 
	C1014.763550,3102.353516 1026.896606,3101.223389 1039.938354,3104.736816 
	C1044.001709,3106.380127 1047.092529,3108.219971 1051.111084,3109.808594 
	C1055.133057,3111.773926 1057.393188,3115.575195 1062.526855,3115.249512 
	C1063.775513,3115.403809 1064.277100,3115.497314 1065.513428,3115.845215 
	C1076.850708,3126.681396 1079.460449,3139.549072 1077.024658,3154.573242 
	C1077.055908,3157.849365 1075.215088,3160.444580 1077.546997,3163.477539 
z"
        />
        <path
          fill="#423C3E"
          opacity="1.000000"
          stroke="none"
          d="
M984.283630,3189.536133 
	C983.743103,3189.822510 982.871216,3189.864014 982.439270,3189.925781 
	C979.736633,3190.036377 977.374573,3189.859863 975.900330,3193.029297 
	C969.958130,3200.904785 961.494934,3200.854736 952.714600,3201.944824 
	C949.241821,3201.830078 946.614929,3202.408691 944.303467,3203.704346 
	C929.965454,3211.742432 914.038269,3216.040039 899.046509,3222.560791 
	C886.192444,3228.151611 872.871948,3233.193115 858.792175,3237.936523 
	C855.415161,3235.053223 854.658569,3231.333740 853.864197,3226.881836 
	C858.563843,3220.037109 864.053894,3215.692627 872.491577,3215.767822 
	C877.418640,3215.811768 881.346680,3211.950928 886.579346,3210.011719 
	C889.607239,3209.139893 891.998352,3208.723633 895.096680,3208.100830 
	C903.590820,3205.228760 910.697998,3201.051025 918.510437,3198.247803 
	C950.901672,3186.624756 983.360962,3175.140137 1015.083984,3161.745605 
	C1020.345459,3159.524170 1025.751343,3158.225098 1032.058838,3157.099121 
	C1034.363525,3156.194092 1035.927124,3156.273193 1038.136841,3157.425781 
	C1040.093140,3160.081543 1039.985107,3162.233398 1038.378906,3165.090332 
	C1031.932129,3167.597412 1026.328857,3170.007812 1020.934387,3173.255371 
	C1011.673401,3178.830811 1000.771729,3180.584717 990.548096,3185.642822 
	C988.556458,3187.591309 986.674072,3188.392578 984.283630,3189.536133 
z"
        />
        <path
          fill="#0C0D0F"
          opacity="1.000000"
          stroke="none"
          d="
M952.104126,3202.597656 
	C953.010803,3200.140869 954.479065,3198.424316 956.480042,3198.493896 
	C962.901917,3198.717285 968.401306,3195.810059 974.937439,3193.751953 
	C976.168701,3194.478271 977.258240,3195.681152 977.032959,3196.026367 
	C973.241943,3201.837158 959.457642,3206.387939 952.704834,3204.175537 
	C952.485657,3204.103760 952.405396,3203.607910 952.104126,3202.597656 
z"
        />
        <path
          fill="#0C0D0F"
          opacity="1.000000"
          stroke="none"
          d="
M943.563416,3208.742676 
	C944.979431,3209.071533 944.277588,3207.582764 944.475159,3208.885986 
	C944.575012,3209.544189 943.909851,3209.609375 943.563416,3208.742676 
z"
        />
        <path
          fill="#F4EEDC"
          opacity="1.000000"
          stroke="none"
          d="
M1164.554688,3694.427734 
	C1163.994507,3694.009766 1163.001953,3694.002686 1162.505859,3694.001465 
	C1163.908203,3687.263428 1163.609741,3679.652100 1170.324219,3674.868896 
	C1171.729126,3673.867920 1172.356689,3671.503418 1172.906128,3669.638428 
	C1173.722046,3666.869141 1171.959961,3666.083008 1169.653320,3666.527100 
	C1166.153564,3667.200928 1164.399780,3670.105713 1162.176758,3672.539307 
	C1158.607056,3676.447754 1157.006592,3676.277344 1153.466553,3672.196045 
	C1151.584717,3670.026123 1150.827881,3666.756104 1146.698364,3666.074219 
	C1144.576782,3665.596191 1143.779175,3664.617432 1143.720093,3662.607666 
	C1140.436279,3658.200195 1140.227051,3652.239258 1135.416016,3649.111572 
	C1133.549316,3647.897949 1134.140869,3645.465088 1135.466919,3643.814453 
	C1137.441284,3641.356445 1138.186157,3638.626221 1137.994141,3634.778809 
	C1138.371460,3633.610840 1138.760620,3633.235107 1139.162842,3632.873291 
	C1139.568359,3632.508057 1139.434326,3632.626465 1138.382446,3633.613770 
	C1132.131592,3634.385742 1128.673218,3630.885254 1126.518799,3626.153809 
	C1124.515137,3621.754150 1126.546631,3619.417480 1131.735596,3619.660400 
	C1133.752563,3619.754395 1136.526367,3620.329346 1137.104614,3618.334473 
	C1139.130493,3611.344971 1145.530640,3610.826660 1151.635986,3608.618896 
	C1148.876465,3604.839111 1146.888306,3601.618652 1143.283447,3599.583740 
	C1130.917725,3592.602539 1128.957031,3580.996094 1129.601685,3568.281738 
	C1129.668335,3566.968994 1130.229370,3565.181396 1129.594116,3564.410889 
	C1123.257935,3556.723145 1122.333252,3547.100830 1119.744629,3538.062744 
	C1119.531372,3537.318848 1118.246460,3536.881836 1116.732178,3536.145996 
	C1109.987061,3527.106445 1106.440430,3517.359131 1105.103760,3506.680176 
	C1104.763428,3503.961426 1103.886597,3501.492432 1105.878174,3499.193848 
	C1108.112305,3496.615479 1106.646851,3494.592529 1105.503052,3492.024170 
	C1102.655884,3485.630615 1103.221924,3484.695557 1109.499146,3481.757812 
	C1115.764038,3478.825195 1116.276611,3477.177002 1111.326538,3472.308105 
	C1108.010376,3469.045898 1107.935791,3465.332275 1108.024536,3461.305664 
	C1108.094604,3458.128662 1108.935669,3455.994873 1112.780762,3457.312744 
	C1114.440552,3457.881592 1116.289062,3457.978027 1117.169678,3456.246582 
	C1118.328613,3453.968506 1116.587402,3452.722656 1114.952148,3451.411865 
	C1112.169800,3449.182373 1109.555054,3446.743408 1106.276367,3443.873291 
	C1110.281494,3441.236572 1113.776367,3441.867432 1117.217773,3442.770996 
	C1119.151733,3443.278320 1121.039185,3444.235107 1122.965820,3443.103027 
	C1123.762329,3442.635254 1124.635254,3441.799072 1124.859253,3440.965576 
	C1125.254517,3439.495117 1124.379517,3438.256104 1122.835083,3438.287109 
	C1115.741333,3438.430908 1112.317261,3433.729736 1108.999146,3428.478516 
	C1107.292480,3425.777344 1105.559082,3423.523926 1108.244629,3420.561523 
	C1109.219360,3419.486084 1109.561890,3417.970459 1108.674561,3416.903076 
	C1102.495972,3409.472656 1108.702026,3402.503418 1108.737305,3393.482666 
	C1117.146118,3402.352051 1127.127075,3399.364014 1136.410645,3402.046631 
	C1135.519043,3397.085449 1131.676392,3397.679443 1129.267456,3396.880859 
	C1124.440430,3395.280029 1120.242432,3392.791016 1116.031128,3390.084961 
	C1113.301147,3388.331299 1111.747803,3385.940918 1110.746338,3383.086426 
	C1109.855835,3380.547852 1109.014282,3377.439209 1111.192627,3375.569092 
	C1113.605225,3373.498047 1115.127686,3376.688965 1116.957886,3377.817871 
	C1118.072632,3378.505615 1119.200317,3379.355713 1120.433472,3379.625732 
	C1122.391846,3380.054932 1124.594238,3380.134521 1125.314087,3377.610352 
	C1125.903076,3375.545410 1124.449219,3374.373291 1122.695068,3374.228271 
	C1119.500000,3373.964600 1117.305786,3370.997559 1114.582886,3370.756836 
	C1106.450928,3370.037842 1104.860229,3363.962158 1102.409058,3358.024170 
	C1104.683105,3355.610352 1111.094849,3359.440430 1109.834106,3352.740479 
	C1109.372070,3350.285645 1107.129883,3347.880371 1109.279419,3345.210938 
	C1111.061401,3342.998291 1114.607422,3345.328857 1116.198730,3342.613281 
	C1115.729126,3340.440918 1113.965332,3340.164307 1112.365845,3340.065674 
	C1108.231689,3339.810791 1105.763672,3337.113525 1103.546631,3334.176758 
	C1101.145874,3330.996582 1101.955933,3327.996338 1104.545166,3325.221924 
	C1105.593018,3324.099121 1105.318604,3322.598633 1104.613525,3321.493652 
	C1101.329590,3316.345947 1102.708618,3310.499512 1101.800537,3305.006592 
	C1100.856689,3299.296143 1099.915649,3294.091064 1104.221802,3289.244629 
	C1105.889893,3287.367432 1105.472778,3285.265137 1103.745117,3282.988525 
	C1100.375854,3278.549316 1100.600708,3278.378662 1107.236816,3276.026611 
	C1116.182861,3291.205078 1121.167358,3307.716064 1127.112671,3323.802734 
	C1147.596313,3379.226562 1164.313232,3435.968750 1185.427612,3491.178467 
	C1185.908447,3492.436035 1186.040161,3493.762451 1186.147461,3495.841309 
	C1186.090210,3499.444580 1183.895752,3502.465820 1186.177368,3505.121338 
	C1194.507080,3514.816162 1191.567139,3528.468018 1198.124878,3538.778809 
	C1198.782959,3539.813721 1198.421753,3541.351318 1198.186279,3543.356689 
	C1194.971069,3549.302246 1190.504028,3554.216309 1198.461060,3559.656494 
	C1201.884155,3561.996582 1201.022705,3566.049805 1197.902344,3569.181641 
	C1194.851562,3572.242676 1190.817749,3573.738037 1186.699951,3575.760498 
	C1190.221924,3578.731201 1193.646240,3577.457031 1196.811523,3577.753418 
	C1205.290405,3578.547119 1208.329834,3582.410400 1205.240723,3590.272461 
	C1202.823486,3596.424072 1203.643066,3600.266602 1209.617554,3603.173828 
	C1211.723633,3604.198486 1213.937378,3606.604736 1212.624390,3608.795166 
	C1208.145874,3616.265381 1215.444092,3617.574463 1218.941528,3621.698730 
	C1220.255981,3624.114990 1220.390503,3626.052002 1220.105713,3628.752930 
	C1219.737671,3631.356934 1218.246216,3632.877441 1218.932129,3635.099365 
	C1222.210571,3645.718262 1219.270386,3652.687988 1209.106323,3656.909668 
	C1204.303589,3658.904297 1200.221313,3660.744385 1198.909546,3666.431396 
	C1198.244995,3669.312500 1195.923584,3671.710938 1193.478882,3673.348877 
	C1186.193726,3678.230225 1178.720825,3682.477051 1175.486328,3691.719727 
	C1174.223511,3695.328125 1169.459961,3696.630615 1164.554688,3694.427734 
z"
        />
        <path
          fill="#D8D1C0"
          opacity="1.000000"
          stroke="none"
          d="
M1184.054688,3498.770508 
	C1182.989868,3488.966797 1178.848633,3480.775879 1175.439209,3472.755127 
	C1169.839600,3459.580811 1166.168091,3445.851074 1161.315186,3432.490967 
	C1148.328247,3396.738037 1138.038208,3360.054932 1124.405762,3324.514893 
	C1118.467529,3309.033936 1113.016113,3293.384521 1108.072021,3276.769531 
	C1106.135742,3272.749512 1105.771606,3269.204590 1105.987061,3264.769531 
	C1107.015381,3265.106934 1108.030762,3266.227295 1109.517090,3267.676758 
	C1112.002319,3269.784912 1111.982178,3272.185059 1111.999634,3275.287598 
	C1114.338257,3283.001709 1116.670776,3290.003418 1119.530273,3297.493652 
	C1131.070068,3312.295654 1134.549072,3329.437744 1138.111816,3347.246582 
	C1138.615234,3350.202148 1139.276855,3352.399414 1140.036255,3355.283203 
	C1149.284668,3364.965088 1152.024048,3376.967773 1155.717285,3389.335938 
	C1155.987915,3390.907959 1155.999756,3391.788818 1156.020508,3393.329834 
	C1160.036621,3397.516602 1160.418945,3402.757568 1162.399048,3407.257080 
	C1165.401733,3414.080566 1167.299927,3421.733643 1176.828979,3423.141113 
	C1181.598877,3423.845459 1183.943115,3429.397217 1181.015137,3432.267578 
	C1171.726318,3441.373291 1179.075073,3447.635254 1183.704590,3455.320312 
	C1182.669312,3468.326660 1186.648804,3479.376221 1192.759521,3490.604980 
	C1193.685181,3492.059570 1194.099731,3492.954346 1194.627197,3494.599121 
	C1201.515137,3510.499512 1203.825073,3527.373047 1213.062378,3542.352783 
	C1215.049805,3546.548828 1217.436646,3549.612305 1220.139648,3553.373047 
	C1223.061890,3558.652100 1224.533203,3563.818848 1228.070679,3567.952148 
	C1230.567871,3570.870117 1230.902222,3574.622314 1230.767334,3578.333496 
	C1230.646729,3581.651367 1230.732056,3585.453857 1226.761230,3586.564941 
	C1223.113403,3587.585449 1221.682373,3584.188232 1220.001221,3581.822266 
	C1207.408569,3564.100098 1199.158813,3544.229248 1193.212280,3523.578369 
	C1190.803955,3515.215332 1185.756104,3508.065430 1184.054688,3498.770508 
z"
        />
        <path
          fill="#D8D1C0"
          opacity="1.000000"
          stroke="none"
          d="
M1102.046997,3258.624512 
	C1101.836182,3255.226318 1100.570068,3252.918457 1098.567383,3250.280762 
	C1094.105957,3246.600586 1094.512573,3241.894043 1093.918213,3236.760498 
	C1093.540894,3229.883545 1091.190918,3223.962646 1091.945435,3216.848633 
	C1095.699463,3221.027588 1096.427612,3227.276367 1098.631714,3232.941895 
	C1100.390015,3237.461182 1102.134766,3241.986084 1104.010620,3247.239746 
	C1106.993286,3250.999756 1108.853394,3254.304932 1106.623535,3259.320801 
	C1106.134766,3260.910156 1106.103149,3261.793945 1106.042236,3263.340332 
	C1103.892578,3263.099854 1102.557983,3261.553711 1102.046997,3258.624512 
z"
        />
        <path
          fill="#EAE6D7"
          opacity="1.000000"
          stroke="none"
          d="
M1108.619141,3548.441406 
	C1106.922852,3547.115479 1106.166260,3545.836426 1107.466064,3544.924072 
	C1109.537354,3543.470459 1110.970825,3544.644043 1111.897461,3547.374512 
	C1112.004883,3548.000000 1112.000000,3548.000000 1112.000000,3548.000000 
	C1111.227173,3548.756592 1110.306641,3549.050781 1108.619141,3548.441406 
z"
        />
        <path
          fill="#F4EEDC"
          opacity="1.000000"
          stroke="none"
          d="
M1108.054199,3548.589355 
	C1108.888916,3548.000000 1109.777832,3548.000000 1111.333496,3548.000000 
	C1111.645996,3549.098633 1111.291748,3550.197266 1110.468750,3551.652832 
	C1109.369385,3551.065918 1108.738892,3550.122314 1108.054199,3548.589355 
z"
        />
        <path
          fill="#EBE4D0"
          opacity="1.000000"
          stroke="none"
          d="
M1192.507568,3496.488037 
	C1192.015259,3495.103516 1192.011353,3494.216797 1192.006104,3492.665039 
	C1191.569336,3487.655273 1194.637817,3485.612793 1197.511475,3483.293945 
	C1203.851562,3478.178711 1203.870850,3475.447266 1196.515015,3472.239258 
	C1189.355591,3469.116455 1184.143799,3465.041748 1182.460571,3456.505859 
	C1176.713623,3452.438477 1174.156372,3447.417725 1173.298828,3441.132080 
	C1172.745361,3437.076172 1169.219238,3432.349121 1177.642456,3432.989258 
	C1180.262451,3433.188232 1180.055542,3430.203125 1179.574829,3428.331543 
	C1178.940918,3425.864258 1177.273926,3423.669434 1174.626709,3425.666504 
	C1170.903442,3428.475342 1169.668823,3426.202881 1167.712891,3423.538574 
	C1161.537231,3415.125977 1158.178345,3405.614502 1156.037598,3394.773193 
	C1157.325684,3393.885742 1158.652588,3393.924316 1159.913574,3393.653809 
	C1170.027344,3391.486084 1172.000854,3392.907471 1172.106934,3402.956787 
	C1172.141479,3406.225342 1172.906250,3408.727295 1177.006470,3409.875000 
	C1182.059937,3410.862793 1182.828857,3414.810547 1184.285645,3417.991699 
	C1187.263306,3424.494385 1192.283325,3429.258057 1197.301147,3434.057129 
	C1198.498901,3435.202637 1200.194824,3437.001221 1201.448486,3436.396729 
	C1209.772339,3432.381104 1215.400757,3437.257812 1222.001831,3441.196045 
	C1228.709595,3445.197510 1240.325439,3438.224121 1242.745361,3429.470703 
	C1244.673950,3422.494141 1246.180054,3415.388428 1247.028320,3407.431396 
	C1246.774658,3405.030518 1246.677368,3403.346680 1246.911987,3401.706787 
	C1247.444824,3397.978271 1246.325684,3392.535156 1251.545410,3392.144287 
	C1255.972900,3391.812500 1258.004639,3396.561279 1259.139404,3400.347168 
	C1262.089722,3410.190674 1262.885010,3420.026367 1256.395020,3429.014160 
	C1251.594849,3435.662354 1248.876587,3443.038086 1253.521362,3450.354980 
	C1258.544434,3458.268311 1267.314575,3458.831787 1275.849854,3458.601807 
	C1284.582031,3458.366455 1289.153687,3452.653564 1291.326416,3445.239990 
	C1296.295288,3428.286377 1300.764526,3411.261475 1294.081665,3393.514404 
	C1290.920166,3385.118652 1289.224487,3376.214111 1286.842163,3367.546631 
	C1286.046509,3364.651855 1285.534424,3361.217285 1288.931274,3359.629883 
	C1292.276123,3358.066406 1294.693970,3360.527344 1296.458374,3362.979004 
	C1304.832764,3374.617188 1313.370117,3386.210693 1314.812744,3401.989014 
	C1314.845215,3404.890137 1315.531616,3406.848877 1316.475342,3409.550293 
	C1320.460571,3418.183594 1321.593750,3426.536865 1321.098389,3435.947754 
	C1320.925415,3438.792480 1321.031982,3440.822998 1322.340332,3443.413574 
	C1324.119629,3448.072998 1325.142090,3452.075439 1323.768555,3457.020996 
	C1315.100586,3464.394531 1323.867554,3471.032471 1324.507935,3477.367676 
	C1324.825073,3480.506348 1327.868408,3485.337402 1328.997681,3489.945312 
	C1337.720581,3480.844482 1337.738647,3480.830811 1344.816040,3490.140869 
	C1345.643188,3491.228760 1346.516479,3492.211670 1347.711670,3492.799805 
	C1351.506104,3494.667480 1355.078369,3496.918701 1353.423584,3501.864014 
	C1351.599731,3507.314453 1346.802124,3506.504150 1342.483887,3506.051025 
	C1338.528564,3505.636230 1334.618774,3504.600830 1330.145264,3505.477783 
	C1334.097778,3510.530518 1339.062744,3508.481934 1343.691162,3508.142090 
	C1356.606201,3507.193848 1360.082642,3510.579102 1358.607910,3523.208740 
	C1357.679810,3531.156982 1361.241089,3539.092285 1358.860596,3545.288086 
	C1359.018188,3540.080322 1363.739746,3535.424561 1368.174805,3531.104248 
	C1373.572021,3525.846680 1380.935791,3528.979492 1387.092407,3529.482422 
	C1393.132446,3529.975830 1396.491699,3529.820312 1397.863159,3523.220947 
	C1398.987915,3517.808350 1403.843506,3516.523438 1408.555176,3515.666016 
	C1412.307007,3514.983154 1416.159912,3515.509033 1419.966064,3515.098633 
	C1412.663086,3514.300537 1405.402100,3513.437012 1398.673340,3510.484863 
	C1394.960693,3508.856201 1389.202148,3507.646240 1391.798096,3501.701416 
	C1393.996094,3496.667480 1398.152100,3493.016602 1404.541748,3494.466064 
	C1415.700439,3496.997070 1415.698730,3497.025391 1414.746582,3484.529541 
	C1414.670532,3483.530762 1414.274292,3482.267822 1414.923462,3481.614990 
	C1422.656494,3473.840820 1424.216675,3461.345215 1434.617676,3455.694336 
	C1437.866333,3453.929443 1439.524170,3451.460938 1440.086182,3447.922607 
	C1441.569214,3438.589600 1447.094238,3430.738770 1449.788574,3421.867676 
	C1452.579102,3412.680176 1461.507446,3413.172119 1467.960938,3409.774902 
	C1469.673706,3408.873291 1471.777588,3408.757568 1473.721924,3407.468994 
	C1470.605713,3404.574219 1466.889648,3404.789795 1463.437134,3404.221191 
	C1460.286743,3403.701904 1456.263794,3404.615967 1455.191162,3400.304443 
	C1454.367798,3396.995117 1456.634399,3394.635742 1459.005127,3392.706787 
	C1462.124878,3390.167969 1465.667969,3389.273193 1469.060791,3391.934570 
	C1473.808105,3395.658447 1478.308716,3399.683350 1484.600220,3397.056152 
	C1482.095825,3397.547607 1479.181885,3398.004395 1476.379517,3396.427246 
	C1474.003784,3395.090332 1471.589111,3393.579590 1471.308228,3390.678467 
	C1470.964355,3387.127930 1474.006470,3386.576904 1476.548462,3385.796875 
	C1482.255615,3384.045410 1487.632446,3381.278320 1493.717773,3380.664551 
	C1497.385742,3380.294922 1500.647095,3379.100342 1502.974854,3375.620117 
	C1508.379761,3367.540039 1507.758301,3365.689209 1498.375610,3364.083252 
	C1487.345703,3362.195068 1487.276245,3362.062988 1492.157959,3352.180420 
	C1487.503174,3351.553467 1482.885864,3351.380859 1478.216187,3351.915283 
	C1472.975586,3352.514893 1466.945557,3352.309814 1466.842651,3345.696045 
	C1466.752686,3339.906982 1469.169189,3333.780273 1475.952637,3331.716309 
	C1479.472900,3330.645508 1483.248413,3330.781982 1486.859131,3331.475830 
	C1493.685669,3332.787598 1494.159790,3335.043701 1489.035156,3340.899414 
	C1494.621826,3341.339600 1498.511841,3341.239746 1500.014404,3333.516602 
	C1501.363647,3326.581787 1504.539062,3318.462402 1514.749878,3318.623291 
	C1517.668457,3318.669434 1519.749146,3316.293457 1520.150024,3313.492432 
	C1522.430664,3297.561279 1535.050903,3298.057373 1547.196167,3296.926270 
	C1548.234375,3297.556152 1548.589355,3297.905273 1549.258057,3298.938965 
	C1550.084473,3301.660645 1549.720337,3303.608154 1548.684082,3306.186035 
	C1544.372192,3311.206543 1545.196655,3317.357178 1542.367676,3322.852539 
	C1541.169922,3324.887939 1539.944458,3326.095459 1538.926025,3328.166260 
	C1538.523560,3329.558594 1538.267700,3330.349609 1537.776489,3331.729980 
	C1537.102051,3333.523193 1536.543091,3334.677734 1535.777466,3336.459473 
	C1534.947754,3338.272217 1534.377319,3339.480469 1533.500000,3341.304688 
	C1524.796387,3352.689209 1524.124268,3366.679932 1518.008301,3378.503174 
	C1515.169067,3383.992188 1513.160767,3390.079834 1510.159424,3395.636719 
	C1508.582397,3398.556396 1505.875244,3401.316406 1505.832031,3404.548828 
	C1505.565186,3424.418213 1491.808716,3438.113037 1483.387085,3454.198975 
	C1479.841553,3460.970459 1479.004395,3468.824463 1474.262329,3475.080811 
	C1473.190186,3476.495850 1473.240601,3479.123779 1475.357056,3480.393799 
	C1477.416138,3481.629639 1479.265625,3480.603271 1480.605835,3478.976807 
	C1487.656616,3470.417725 1497.963257,3468.454102 1507.760986,3465.458984 
	C1509.638306,3464.885254 1512.261597,3464.846680 1512.607300,3462.649902 
	C1513.105835,3459.482666 1509.989258,3459.243164 1507.909668,3458.575439 
	C1501.655273,3456.567383 1493.243774,3455.788818 1495.013062,3446.618164 
	C1497.010376,3436.265137 1505.889648,3442.703369 1511.675049,3441.617920 
	C1513.946655,3441.191406 1516.357544,3441.925293 1518.402344,3443.113525 
	C1522.831787,3445.687500 1525.849609,3444.757812 1527.826294,3439.932617 
	C1530.270386,3433.966797 1535.592285,3432.364746 1541.293457,3430.943115 
	C1547.566040,3429.379639 1554.509399,3428.787109 1557.281494,3420.952881 
	C1558.442749,3417.671143 1561.837158,3417.513672 1565.477783,3419.528564 
	C1566.004883,3419.990234 1566.999512,3420.000000 1567.496826,3420.003418 
	C1570.208130,3422.603271 1570.784668,3425.416992 1568.577881,3429.268799 
	C1568.142090,3435.947998 1562.274536,3435.246582 1558.443359,3437.805420 
	C1561.758789,3441.303223 1566.072266,3438.922607 1569.471191,3441.474121 
	C1569.958618,3443.193115 1569.596802,3444.267578 1568.412231,3445.595947 
	C1566.872803,3447.009766 1565.752319,3448.022461 1564.301270,3449.502930 
	C1558.616089,3452.758301 1552.925293,3453.849854 1547.004883,3452.694580 
	C1545.147827,3452.332275 1543.522705,3450.628174 1542.824097,3453.924805 
	C1542.105347,3457.315430 1535.988281,3458.828613 1538.512817,3462.860352 
	C1540.324463,3465.753906 1544.663574,3463.875000 1547.875610,3464.076416 
	C1550.194336,3464.221680 1552.417114,3463.187744 1555.374634,3463.694824 
	C1556.230469,3464.630371 1556.613159,3465.141602 1557.566162,3465.776611 
	C1558.073486,3475.205078 1553.893921,3479.224365 1545.769165,3479.647461 
	C1533.893433,3480.266357 1522.107056,3480.059814 1510.268677,3479.067139 
	C1504.232178,3478.560547 1498.914185,3480.001221 1498.226440,3487.686523 
	C1497.979248,3490.448730 1496.413696,3493.641846 1493.462280,3493.171143 
	C1485.381958,3491.882568 1477.681885,3502.239990 1469.231689,3493.509521 
	C1467.276001,3491.489258 1463.685669,3494.462402 1463.417847,3497.109863 
	C1462.113525,3510.002930 1453.496338,3519.292969 1447.971680,3530.108398 
	C1446.546509,3532.898682 1443.911133,3536.728516 1446.459229,3539.343262 
	C1448.798706,3541.743652 1452.709106,3539.952881 1456.843750,3538.375000 
	C1452.851929,3535.319580 1448.497070,3532.378174 1452.663086,3527.103760 
	C1456.096558,3522.757080 1460.450317,3520.467285 1465.757812,3522.897949 
	C1471.758667,3525.645752 1476.035767,3523.792725 1479.611328,3518.984863 
	C1482.439697,3515.181885 1490.278320,3514.008301 1485.619873,3506.068848 
	C1484.517334,3504.189697 1488.858276,3501.435547 1491.733154,3500.455811 
	C1496.454468,3498.846924 1500.914429,3496.788086 1505.158569,3493.936523 
	C1502.129150,3490.721680 1499.115356,3487.211182 1501.469482,3482.675781 
	C1503.643066,3478.488037 1508.593140,3478.112793 1512.115723,3479.094727 
	C1522.615234,3482.021729 1532.848022,3480.810303 1543.338867,3479.541016 
	C1547.254883,3479.067383 1552.611572,3480.164307 1550.455078,3487.463867 
	C1548.239136,3489.005859 1546.473022,3490.011963 1544.348877,3491.508789 
	C1542.848999,3503.076416 1536.333618,3511.731934 1530.464111,3521.432617 
	C1529.960205,3521.959961 1528.977539,3521.969238 1528.486328,3521.979736 
	C1525.971069,3524.389404 1523.201904,3522.976807 1520.660889,3523.402100 
	C1522.744995,3524.381348 1525.162720,3523.666016 1527.492798,3525.551270 
	C1530.536743,3526.247559 1534.616577,3525.187500 1535.064941,3527.974121 
	C1536.169922,3534.841064 1540.699951,3535.925293 1546.177368,3536.866455 
	C1548.023193,3537.183594 1547.805542,3539.400146 1546.504150,3541.516846 
	C1540.727051,3540.360840 1535.999512,3536.763428 1529.950439,3537.824707 
	C1528.240356,3538.124512 1526.856323,3536.865234 1526.122314,3535.186279 
	C1525.386597,3533.504150 1524.231567,3532.300293 1522.298950,3533.055908 
	C1519.094849,3534.308105 1516.509644,3533.321289 1513.979492,3531.368652 
	C1512.004395,3529.844727 1509.595337,3529.989258 1507.260498,3530.054443 
	C1504.767212,3530.124268 1502.522949,3530.671143 1502.229004,3533.699463 
	C1502.001465,3536.043945 1501.831055,3538.543457 1504.682495,3539.553467 
	C1509.863525,3541.388916 1514.349854,3545.294434 1520.559204,3544.133789 
	C1523.161255,3543.647217 1526.350830,3543.296387 1529.226318,3545.851074 
	C1526.404785,3548.867188 1523.268066,3547.656738 1520.554565,3548.035645 
	C1518.875610,3548.269775 1516.945312,3548.411865 1517.088623,3550.593262 
	C1517.210449,3552.448486 1518.954834,3553.874023 1520.601196,3553.150146 
	C1525.427490,3551.027344 1529.683594,3554.299072 1534.267700,3554.124023 
	C1536.751831,3554.028809 1538.638916,3555.512207 1540.159058,3557.411621 
	C1541.378906,3558.935547 1541.585205,3560.471680 1539.765869,3561.586670 
	C1533.130859,3565.652344 1525.728882,3567.621582 1518.105347,3567.685791 
	C1513.600830,3567.723877 1512.572876,3561.681152 1508.424561,3560.044189 
	C1506.831177,3559.415527 1506.073120,3556.922607 1503.916992,3556.423340 
	C1501.089111,3555.768311 1498.067993,3558.283691 1499.309570,3560.785400 
	C1502.253540,3566.717529 1499.910522,3572.777588 1499.951172,3579.956055 
	C1495.987427,3584.373291 1492.548706,3583.419678 1488.744385,3581.845459 
	C1486.095947,3580.749512 1482.834961,3578.415039 1480.137085,3583.132324 
	C1479.153564,3585.319092 1479.218750,3586.961182 1479.370239,3588.639404 
	C1479.738770,3592.720459 1480.354736,3597.422607 1474.395020,3597.412842 
	C1469.334473,3597.404541 1466.063721,3593.662842 1466.341187,3588.945312 
	C1466.764282,3581.752930 1463.624512,3576.029053 1460.607666,3570.137695 
	C1459.128296,3567.248535 1457.441040,3563.471436 1453.226196,3565.421387 
	C1449.828857,3566.992920 1450.833862,3570.198730 1451.613281,3573.306641 
	C1453.589966,3581.189941 1451.859497,3582.833740 1443.770752,3580.891602 
	C1440.217651,3580.038574 1436.330933,3579.613281 1433.262695,3577.762207 
	C1419.412964,3569.406982 1404.192993,3574.285156 1389.644897,3573.046875 
	C1386.496094,3572.778809 1379.726807,3572.253174 1380.899170,3577.798828 
	C1381.823242,3582.170898 1386.766113,3585.861816 1392.814819,3584.262695 
	C1394.102905,3583.922363 1392.816650,3584.687500 1393.711304,3583.882812 
	C1407.779175,3571.232666 1420.267700,3578.982666 1432.888428,3586.650879 
	C1436.135132,3588.623535 1439.357544,3590.442139 1439.434937,3594.707031 
	C1439.502319,3598.423828 1436.152954,3598.993164 1433.764282,3600.748779 
	C1428.258911,3604.794922 1423.704712,3610.204346 1419.598633,3615.469238 
	C1407.710938,3630.711670 1407.725830,3631.289062 1387.825073,3636.427490 
	C1389.101807,3637.109863 1390.249146,3636.009277 1391.437256,3636.242676 
	C1396.469849,3637.231445 1403.282593,3633.750000 1406.288086,3638.994629 
	C1409.398193,3644.422363 1404.233521,3649.715820 1401.834229,3654.602295 
	C1398.262085,3661.876709 1390.339478,3665.600830 1379.462158,3666.095947 
	C1380.094116,3669.423340 1382.996338,3670.696045 1384.959595,3672.431641 
	C1398.198486,3684.137207 1395.023804,3688.942627 1380.555908,3693.503174 
	C1369.870850,3696.871094 1367.506958,3704.841553 1364.446167,3710.943359 
	C1360.790405,3718.231201 1356.632690,3718.418701 1350.664917,3718.991211 
	C1346.438843,3719.396729 1339.420288,3715.601807 1338.595093,3721.739014 
	C1337.621948,3728.975586 1345.640137,3726.853271 1349.891968,3727.823486 
	C1362.163330,3730.625000 1364.181274,3732.628174 1362.511719,3743.542480 
	C1356.828125,3744.530518 1352.306274,3742.184326 1347.537964,3740.883301 
	C1339.803101,3738.773193 1338.220947,3740.182129 1338.987061,3748.088135 
	C1339.081177,3749.059326 1339.403320,3750.097656 1339.177979,3750.993652 
	C1338.578491,3753.377441 1338.175293,3755.627930 1340.790894,3757.062988 
	C1343.715698,3758.667480 1345.886719,3757.059082 1347.831909,3755.129395 
	C1351.686035,3751.305176 1355.989258,3751.248047 1361.311035,3753.695312 
	C1361.942993,3757.180908 1359.431885,3758.205078 1356.602539,3759.742676 
	C1354.947510,3761.083008 1353.863037,3762.129150 1352.414673,3763.610840 
	C1351.814087,3765.791748 1351.259155,3767.318115 1348.642822,3767.914551 
	C1345.785400,3771.777344 1342.739868,3774.998535 1340.420654,3779.486328 
	C1340.016968,3780.010010 1340.000000,3781.000000 1339.992676,3781.495117 
	C1331.537598,3790.977539 1326.620605,3802.563965 1317.812256,3812.173828 
	C1315.828491,3814.719238 1313.979980,3816.252441 1310.675903,3816.271484 
	C1307.786377,3816.610107 1305.582520,3817.229736 1302.691772,3817.924805 
	C1291.211304,3812.493652 1282.845215,3804.304688 1275.894409,3794.511963 
	C1274.135742,3792.034424 1274.261719,3789.570557 1275.338745,3786.969482 
	C1276.251709,3784.764893 1276.674805,3782.730469 1275.437622,3780.324951 
	C1270.618286,3770.954346 1270.698608,3770.913086 1281.687134,3766.958984 
	C1283.954956,3765.527832 1286.834961,3764.954590 1287.049438,3763.241211 
	C1288.069702,3755.094727 1290.528076,3749.524902 1300.317871,3748.549316 
	C1304.434692,3748.139160 1302.593384,3743.029297 1300.081909,3740.537109 
	C1282.400757,3722.992188 1273.838745,3699.200439 1258.534668,3680.019531 
	C1255.784790,3676.572998 1254.117920,3671.770264 1257.804810,3668.111572 
	C1261.854858,3664.092773 1265.956909,3667.443604 1269.376099,3669.977539 
	C1282.924072,3680.016113 1295.979492,3690.533936 1302.038086,3707.194580 
	C1302.731934,3709.102539 1304.476929,3710.312500 1307.153809,3712.090576 
	C1306.120483,3706.150391 1308.872070,3703.565674 1314.460449,3703.208008 
	C1303.374268,3700.658447 1299.930054,3689.724854 1291.890259,3683.966797 
	C1288.442505,3681.497803 1290.618652,3677.054199 1292.926392,3674.072998 
	C1295.730347,3670.449951 1294.614136,3668.075684 1291.189697,3665.975342 
	C1283.051636,3660.984131 1275.533447,3654.914795 1266.146851,3652.128418 
	C1259.640503,3650.196777 1257.777222,3645.693848 1262.614990,3641.274414 
	C1267.536377,3636.779541 1266.207642,3633.510254 1263.168213,3629.405273 
	C1260.985596,3626.457520 1259.610596,3622.740723 1257.102539,3620.290039 
	C1246.869385,3610.290771 1242.766479,3596.436768 1234.397949,3585.326172 
	C1229.319092,3578.583496 1230.411011,3569.380371 1225.023804,3562.694580 
	C1222.987915,3560.167725 1222.081787,3556.732666 1218.490601,3554.496826 
	C1215.730347,3551.336426 1211.443726,3550.062744 1212.020874,3544.708984 
	C1213.270752,3537.766602 1211.987793,3532.347168 1207.490845,3527.583984 
	C1203.329956,3523.176758 1200.882568,3518.193604 1200.599121,3511.641602 
	C1200.361450,3506.151367 1196.823608,3501.221191 1192.507568,3496.488037 
z"
        />
        <path
          fill="#83837F"
          opacity="1.000000"
          stroke="none"
          d="
M1131.204590,3254.261230 
	C1132.754639,3250.229980 1135.175537,3249.748535 1138.441284,3251.235596 
	C1142.961060,3253.293457 1147.109741,3255.750000 1150.568359,3259.435791 
	C1152.423096,3261.412354 1154.270874,3264.852295 1157.812134,3262.563721 
	C1160.805176,3260.629639 1158.567627,3257.833740 1158.197632,3255.394775 
	C1158.099365,3254.748291 1158.076782,3254.089844 1158.014648,3252.713623 
	C1159.809448,3250.245605 1162.189087,3250.522705 1163.357300,3252.019043 
	C1169.608154,3260.027344 1180.715210,3265.141113 1178.831055,3278.130859 
	C1178.280640,3281.926025 1181.397705,3284.818848 1183.056763,3288.039795 
	C1189.923950,3301.373779 1200.081177,3312.471191 1208.554321,3324.688477 
	C1213.817627,3332.277344 1223.867920,3336.169922 1228.307495,3345.166504 
	C1232.352539,3353.363281 1238.713867,3358.899658 1246.522583,3363.767090 
	C1254.642700,3368.829102 1253.318848,3375.590332 1242.919678,3379.459229 
	C1235.153564,3382.349121 1227.694580,3384.382324 1222.926025,3391.953613 
	C1221.302124,3394.531738 1217.684814,3394.480469 1213.941895,3393.129150 
	C1205.578735,3384.445312 1203.554932,3373.889160 1201.791748,3362.550537 
	C1201.922607,3359.173096 1205.680908,3356.442139 1201.257935,3353.401611 
	C1197.278442,3350.228516 1198.411011,3344.476562 1193.311768,3342.081787 
	C1183.714355,3337.363037 1173.118530,3337.051270 1164.226318,3330.490723 
	C1153.971802,3307.071777 1142.297241,3284.933838 1133.547852,3261.512207 
	C1132.734497,3259.334717 1132.011230,3257.123535 1131.204590,3254.261230 
z"
        />
        <path
          fill="#959693"
          opacity="1.000000"
          stroke="none"
          d="
M1249.026367,3407.506592 
	C1249.402344,3416.280273 1247.220093,3424.333740 1245.320190,3432.304199 
	C1242.482788,3444.207031 1224.760010,3449.106934 1215.924561,3440.999023 
	C1214.704346,3439.878906 1213.587524,3438.628662 1212.280640,3437.623535 
	C1210.249390,3436.062256 1207.933594,3435.216064 1206.516479,3438.178223 
	C1203.631592,3444.208984 1200.425049,3441.699707 1197.324707,3438.641846 
	C1189.292480,3430.719482 1181.232300,3422.782959 1178.087891,3410.606445 
	C1176.430908,3402.836182 1175.419067,3395.621338 1173.821045,3388.538818 
	C1172.424683,3382.349609 1171.501709,3376.165771 1171.601807,3368.947266 
	C1179.956787,3364.388428 1185.221558,3365.273682 1188.822998,3372.668945 
	C1192.279907,3379.767578 1197.699951,3384.670166 1203.894775,3390.113281 
	C1207.623413,3394.537354 1207.423706,3399.877441 1209.765015,3403.978271 
	C1212.001343,3407.895264 1211.463135,3402.699951 1213.077271,3401.947754 
	C1219.325684,3397.988037 1223.467163,3400.927002 1227.217041,3405.102783 
	C1229.413208,3407.548340 1230.960327,3410.511475 1233.179443,3412.936768 
	C1236.305054,3416.352295 1239.364868,3418.594238 1241.490356,3412.012695 
	C1242.562744,3408.692139 1244.124390,3405.819824 1249.026367,3407.506592 
z"
        />
        <path
          fill="#8C8D89"
          opacity="1.000000"
          stroke="none"
          d="
M1204.677246,3391.419678 
	C1196.876465,3388.313232 1189.650757,3384.916504 1187.488525,3375.760986 
	C1185.773071,3368.497559 1179.679565,3367.693604 1172.516602,3368.041016 
	C1168.602417,3356.040283 1165.154907,3344.103027 1163.612793,3330.916748 
	C1168.262207,3328.615967 1172.622070,3327.522217 1175.134521,3333.542236 
	C1175.938721,3335.469238 1178.267334,3336.266113 1180.271606,3335.511475 
	C1185.899048,3333.392822 1189.683350,3336.699463 1194.037598,3340.270508 
	C1196.333374,3342.353027 1197.609009,3344.121826 1197.925049,3346.621826 
	C1199.337280,3357.791992 1202.488403,3368.566162 1205.670532,3379.316895 
	C1206.842285,3383.275146 1207.316528,3387.049072 1204.677246,3391.419678 
z"
        />
        <path
          fill="#EBE4D0"
          opacity="1.000000"
          stroke="none"
          d="
M1138.751343,3348.024414 
	C1132.890991,3340.579834 1131.339478,3331.621826 1128.821899,3323.391113 
	C1126.321411,3315.217285 1123.174683,3307.386230 1120.130859,3298.711670 
	C1123.568359,3298.139160 1125.116577,3302.328613 1128.793823,3302.232910 
	C1129.353882,3302.218018 1130.442505,3303.048340 1130.426147,3303.083252 
	C1126.867798,3310.652100 1136.983887,3312.890869 1136.650757,3319.100342 
	C1136.529053,3321.370117 1137.602539,3323.179932 1139.099365,3324.270996 
	C1147.125244,3330.121826 1147.960327,3340.160889 1153.530762,3347.617188 
	C1148.689087,3352.661133 1144.053101,3347.886719 1138.751343,3348.024414 
z"
        />
        <path
          fill="#EBE4D0"
          opacity="1.000000"
          stroke="none"
          d="
M1156.316895,3389.420166 
	C1151.232666,3384.388672 1148.543579,3377.626709 1145.940186,3370.900391 
	C1144.186401,3366.368896 1143.085693,3361.583984 1140.390137,3356.640869 
	C1147.264648,3355.140869 1154.282471,3354.690186 1161.117432,3357.946289 
	C1165.167969,3359.876465 1168.038818,3361.186279 1164.443726,3366.384033 
	C1162.292969,3369.493652 1160.653198,3374.033691 1162.240479,3377.656250 
	C1165.121704,3384.231201 1160.384888,3386.060547 1156.316895,3389.420166 
z"
        />
        <path
          fill="#EBE4D0"
          opacity="1.000000"
          stroke="none"
          d="
M1106.829224,3259.922119 
	C1105.473755,3256.522217 1104.781006,3253.017578 1104.111938,3248.741943 
	C1108.054932,3248.013672 1111.622925,3249.086426 1115.560547,3251.883545 
	C1112.580933,3254.567383 1111.697021,3258.646484 1106.829224,3259.922119 
z"
        />
        <path
          fill="#EBE4D0"
          opacity="1.000000"
          stroke="none"
          d="
M1112.507812,3275.537109 
	C1109.722046,3274.315430 1110.089722,3271.816895 1109.992676,3268.732910 
	C1114.539062,3268.847168 1114.535645,3271.635254 1112.507812,3275.537109 
z"
        />
        <path
          fill="#020914"
          opacity="1.000000"
          stroke="none"
          d="
M1072.712158,3470.857666 
	C1071.224731,3471.274170 1070.685059,3470.753906 1070.915527,3469.598877 
	C1070.963379,3469.359131 1071.303833,3469.036865 1071.546265,3469.000732 
	C1072.712769,3468.826660 1073.201660,3469.394043 1072.712158,3470.857666 
z"
        />
        <path
          fill="#8C8D89"
          opacity="1.000000"
          stroke="none"
          d="
M1249.697266,3407.114258 
	C1245.096069,3407.769043 1243.813965,3410.298096 1243.980591,3414.272461 
	C1244.094482,3416.991699 1243.929810,3420.092041 1240.921265,3421.015137 
	C1237.779907,3421.979004 1234.974731,3419.960205 1233.015259,3417.752441 
	C1230.388184,3414.792480 1228.114258,3411.454834 1226.086182,3408.043457 
	C1223.644897,3403.937256 1220.487305,3401.538574 1214.785400,3402.001953 
	C1211.382446,3399.594727 1211.302734,3396.715088 1212.998291,3392.865234 
	C1220.598755,3393.261475 1223.529663,3388.406738 1226.302979,3383.322998 
	C1228.473145,3379.344727 1229.937378,3373.872803 1236.104614,3379.359619 
	C1237.102173,3380.247070 1248.713623,3373.508789 1249.332520,3372.001709 
	C1249.920166,3370.570801 1249.408569,3369.232178 1248.288696,3368.491211 
	C1244.744751,3366.146484 1242.171875,3362.408936 1237.577637,3361.509277 
	C1235.375488,3361.077881 1231.651733,3359.898438 1231.532715,3358.754639 
	C1230.843994,3352.127930 1224.562256,3348.697266 1222.875610,3343.622803 
	C1220.606567,3336.796387 1215.652344,3334.529785 1210.780151,3331.539551 
	C1207.263550,3329.381104 1204.002563,3327.635742 1203.739380,3322.836426 
	C1203.568970,3319.726562 1201.334839,3317.065918 1199.137329,3315.373535 
	C1187.731323,3306.588867 1184.738770,3291.931885 1175.270386,3281.850342 
	C1174.106934,3280.611084 1173.620728,3278.521484 1174.758057,3277.221436 
	C1179.420044,3271.892334 1175.216064,3269.017822 1171.870361,3265.896240 
	C1167.331787,3261.661377 1165.222900,3255.224121 1158.700439,3252.081543 
	C1157.368164,3250.432129 1156.726685,3248.874023 1156.013428,3246.685547 
	C1149.015747,3242.445068 1149.064575,3234.319580 1144.836304,3228.881104 
	C1137.380249,3219.290527 1128.328247,3210.730713 1124.538940,3198.608887 
	C1123.557983,3195.470703 1121.730225,3192.573975 1120.817993,3189.379395 
	C1118.541748,3181.406738 1120.647583,3178.489502 1128.918579,3177.267090 
	C1131.448730,3176.893311 1135.951416,3179.136475 1135.910156,3174.408936 
	C1135.872681,3170.119873 1132.461426,3170.112549 1128.565308,3170.554688 
	C1119.673584,3171.563477 1107.999268,3161.293213 1108.549438,3152.312500 
	C1109.043457,3144.245605 1101.968384,3144.128174 1098.001709,3139.778809 
	C1097.354126,3138.808105 1097.148560,3138.387695 1096.799805,3137.285156 
	C1099.210083,3127.324707 1097.086670,3119.153564 1090.454102,3111.367676 
	C1088.744385,3108.210938 1087.175781,3105.741455 1086.926636,3102.033203 
	C1087.416504,3100.379639 1088.005737,3099.612061 1089.493408,3098.751465 
	C1103.761108,3100.217773 1112.765747,3107.743164 1116.759277,3120.679688 
	C1119.897095,3130.843262 1124.267334,3139.849365 1133.671509,3145.642334 
	C1135.091064,3146.516846 1136.549927,3147.818359 1136.896851,3149.361084 
	C1140.315552,3164.562744 1153.545044,3170.236084 1164.656128,3177.746338 
	C1176.720703,3185.901367 1177.199585,3191.002441 1165.740845,3200.068115 
	C1164.465088,3201.077637 1162.785278,3201.692139 1162.324219,3203.614990 
	C1164.753052,3205.434326 1167.411865,3203.716064 1169.941528,3204.049805 
	C1176.025269,3204.852295 1180.267700,3207.200928 1182.180420,3213.812012 
	C1186.343628,3228.202881 1189.769897,3229.822754 1203.327515,3225.397949 
	C1217.487427,3220.776611 1219.813721,3221.921631 1222.389893,3237.153809 
	C1223.570801,3244.136719 1226.646484,3248.011963 1233.583374,3248.981445 
	C1238.763428,3249.705566 1241.722656,3252.041016 1242.200073,3257.986816 
	C1242.758423,3264.941162 1250.359741,3268.524902 1256.830200,3265.641846 
	C1258.039917,3265.103027 1259.064209,3264.186768 1260.259521,3263.601074 
	C1264.103516,3261.717529 1268.230225,3260.650879 1270.887207,3264.947021 
	C1273.367310,3268.957275 1271.314941,3272.457275 1268.118652,3275.509033 
	C1258.243286,3284.937012 1259.385498,3289.186035 1272.330811,3293.315918 
	C1277.520386,3294.971436 1281.819702,3297.836670 1288.190796,3296.872803 
	C1293.533447,3296.063965 1296.703491,3301.954102 1296.031372,3307.481201 
	C1295.192871,3314.375244 1298.032104,3318.902344 1303.135498,3322.821533 
	C1307.684326,3326.314941 1311.475586,3330.467041 1313.038452,3336.237305 
	C1313.971924,3339.683350 1316.422485,3341.859863 1319.423340,3343.549561 
	C1321.868164,3344.926514 1324.028076,3346.905029 1323.709229,3349.798584 
	C1322.785767,3358.177002 1327.855957,3363.581543 1332.915161,3368.852783 
	C1337.532104,3373.662354 1339.281738,3379.805420 1341.269653,3385.702881 
	C1345.267212,3397.562012 1346.215454,3410.163086 1349.173584,3422.339844 
	C1351.833130,3433.288330 1345.577515,3439.062012 1332.782715,3441.782959 
	C1347.533447,3446.891113 1351.844971,3457.440674 1349.718750,3471.944824 
	C1342.648071,3480.004883 1336.442139,3475.661377 1330.590210,3471.343262 
	C1326.639648,3468.428711 1323.395386,3464.664307 1322.718750,3458.631348 
	C1321.955566,3453.715332 1321.071655,3449.616943 1320.138428,3444.741211 
	C1319.995117,3442.319336 1320.034180,3440.657715 1319.197510,3438.513428 
	C1317.376953,3429.145264 1317.854980,3419.977051 1314.214844,3410.757568 
	C1315.599731,3407.819336 1318.137573,3408.193848 1320.290894,3407.294189 
	C1317.971680,3406.378174 1315.377075,3406.638916 1313.019775,3404.435059 
	C1312.059448,3402.339600 1311.407837,3400.783203 1311.099731,3399.161377 
	C1309.209473,3389.209229 1305.707642,3380.032471 1298.859741,3372.327393 
	C1295.637817,3368.702148 1294.735962,3363.302734 1289.944336,3360.149414 
	C1287.548340,3362.337402 1288.569824,3364.415039 1289.278198,3366.523193 
	C1294.041382,3380.697510 1298.077515,3395.063477 1301.017090,3409.742432 
	C1301.830322,3413.803467 1302.052490,3417.584229 1300.159790,3421.382568 
	C1298.652710,3424.406738 1298.194092,3427.685059 1298.094604,3431.061768 
	C1297.926025,3436.781006 1296.334106,3442.206299 1294.157715,3447.448975 
	C1291.141602,3454.715332 1287.536255,3461.496826 1278.375366,3462.083740 
	C1267.756348,3462.764160 1257.613647,3461.569824 1250.220215,3452.733154 
	C1245.787842,3447.435303 1246.465332,3435.463135 1251.999756,3429.567627 
	C1255.837769,3425.479248 1258.323608,3421.606934 1258.349854,3415.697021 
	C1258.386230,3407.530273 1257.498291,3399.951904 1251.406250,3392.542725 
	C1248.425537,3397.385742 1250.525635,3402.054199 1249.697266,3407.114258 
z"
        />
        <path
          fill="#83837F"
          opacity="1.000000"
          stroke="none"
          d="
M1097.513428,3138.602539 
	C1099.489502,3138.738525 1100.949585,3139.550781 1102.477539,3140.203857 
	C1111.851318,3144.211670 1111.944702,3144.210449 1110.647461,3154.845703 
	C1110.304932,3157.653320 1110.851807,3159.490234 1113.786743,3159.899170 
	C1116.390137,3160.261719 1117.646240,3162.135742 1119.222290,3163.877441 
	C1122.452881,3167.447510 1125.510620,3172.900635 1130.657959,3165.670654 
	C1131.857422,3163.985596 1133.657959,3165.069092 1135.000732,3166.180176 
	C1137.399536,3168.165771 1138.516968,3171.000488 1139.811646,3173.685547 
	C1140.786987,3175.708252 1142.417603,3177.825684 1140.344604,3180.087646 
	C1138.710693,3181.870605 1136.763428,3181.258789 1134.813965,3180.611572 
	C1133.879028,3180.301270 1132.869141,3180.157471 1131.881714,3180.073486 
	C1122.095703,3179.238037 1120.881348,3181.302490 1124.186646,3190.693359 
	C1126.606812,3197.569336 1132.003784,3203.094727 1132.779785,3210.702393 
	C1132.918579,3212.061279 1134.531372,3213.981689 1135.846313,3214.380615 
	C1140.550659,3215.807373 1141.527222,3220.482910 1144.472168,3223.273682 
	C1150.759033,3229.230957 1151.412109,3237.807373 1155.817383,3245.329102 
	C1151.509644,3245.267090 1148.270386,3243.137207 1145.464722,3239.317871 
	C1133.690308,3223.289062 1123.624634,3206.160156 1112.815308,3189.513428 
	C1111.173340,3186.984619 1109.769775,3184.382324 1106.646973,3182.294922 
	C1103.404907,3180.651855 1102.540161,3178.214111 1101.941772,3174.781738 
	C1101.481934,3162.233643 1096.500488,3151.102051 1097.513428,3138.602539 
z"
        />
        <path
          fill="#6B6164"
          opacity="1.000000"
          stroke="none"
          d="
M1103.851196,3173.293945 
	C1104.610718,3176.206543 1105.235474,3178.407471 1105.952759,3181.292480 
	C1101.778320,3185.769775 1099.745972,3182.729492 1097.715820,3179.327148 
	C1093.826172,3172.808350 1089.889771,3166.316895 1085.900757,3159.858398 
	C1084.687866,3157.894287 1083.535889,3155.732666 1081.836182,3154.261963 
	C1078.215210,3151.128906 1078.524292,3154.705811 1077.999512,3157.450928 
	C1077.627197,3158.326416 1077.321899,3158.531982 1076.644043,3158.330078 
	C1075.553711,3143.743896 1074.505127,3129.655029 1064.643188,3117.505371 
	C1063.592651,3112.126709 1067.977783,3109.310547 1070.119629,3104.641602 
	C1072.373291,3101.819580 1074.313477,3099.689697 1078.064941,3099.095703 
	C1082.482544,3108.493896 1087.797607,3117.660156 1084.338501,3128.190430 
	C1082.137207,3134.892090 1084.566040,3140.241455 1088.566040,3144.752197 
	C1095.820068,3152.932129 1100.283203,3162.428223 1103.851196,3173.293945 
z"
        />
        <path
          fill="#0C1A26"
          opacity="1.000000"
          stroke="none"
          d="
M1075.666748,3158.015137 
	C1076.271362,3157.922852 1077.108398,3157.969727 1077.520508,3158.045410 
	C1078.005615,3159.591553 1078.078491,3161.062256 1078.042847,3163.262695 
	C1072.407349,3161.007080 1072.407349,3161.007080 1075.666748,3158.015137 
z"
        />
        <path
          fill="#EAE5D5"
          opacity="1.000000"
          stroke="none"
          d="
M1093.357300,3236.608398 
	C1096.991211,3239.539062 1097.917847,3243.802490 1098.048584,3249.099121 
	C1096.982422,3251.608154 1095.282104,3251.982666 1092.730225,3251.954346 
	C1092.365479,3247.030518 1091.201904,3242.041260 1093.357300,3236.608398 
z"
        />
        <path
          fill="#AB7453"
          opacity="1.000000"
          stroke="none"
          d="
M564.320679,3160.511475 
	C563.864746,3159.057861 563.887573,3158.178711 563.912109,3156.639160 
	C568.825928,3139.999023 578.989258,3127.574707 591.133545,3116.674316 
	C595.713562,3112.563721 597.964355,3116.774414 598.347046,3120.514648 
	C599.194641,3128.799805 602.717285,3137.031006 602.077148,3145.000732 
	C601.092834,3157.255371 605.028442,3168.409912 606.556030,3180.067139 
	C607.394714,3186.467529 611.399536,3191.002441 616.336792,3194.452393 
	C619.678040,3196.787109 621.480347,3199.354736 621.876953,3203.866211 
	C622.184509,3205.980713 621.999878,3207.561279 621.457458,3209.861328 
	C614.628052,3209.613281 608.156433,3208.646240 600.830933,3207.592285 
	C599.024109,3207.016357 598.071228,3206.527344 596.561768,3205.995605 
	C595.981628,3205.134521 595.958008,3204.316650 595.933105,3202.884766 
	C597.234192,3189.637207 593.260620,3177.642334 591.365845,3165.405029 
	C590.724060,3161.259766 590.254761,3156.218506 584.583435,3155.768066 
	C578.645325,3155.296143 578.680908,3160.132812 577.157715,3164.288574 
	C574.928589,3170.369629 574.363159,3177.300293 568.763000,3182.121094 
	C564.809692,3178.882568 566.002686,3175.326172 567.073364,3171.805420 
	C568.291321,3167.800049 568.911682,3163.948242 564.320679,3160.511475 
z"
        />
        <path
          fill="#233444"
          opacity="1.000000"
          stroke="none"
          d="
M348.546814,3198.578125 
	C350.547638,3195.178223 353.526947,3193.575195 357.503937,3194.776123 
	C362.500519,3196.285156 367.763550,3191.868896 373.366974,3195.773926 
	C373.024658,3197.894775 371.421783,3200.135254 369.608093,3199.583252 
	C362.742920,3197.493652 355.844116,3201.690674 348.546814,3198.578125 
z"
        />
        <path
          fill="#233444"
          opacity="1.000000"
          stroke="none"
          d="
M376.388977,3196.484131 
	C377.412994,3194.045654 379.146362,3193.700684 381.596710,3195.447266 
	C380.597778,3197.944824 378.869019,3198.339111 376.388977,3196.484131 
z"
        />
        <path
          fill="#3A4B59"
          opacity="1.000000"
          stroke="none"
          d="
M439.613037,3285.617188 
	C437.639465,3285.530273 436.528564,3284.753662 437.053314,3283.354736 
	C437.532013,3282.078857 439.059784,3281.771484 440.191772,3282.471191 
	C441.459229,3283.255127 441.339691,3284.453857 439.613037,3285.617188 
z"
        />
        <path
          fill="#3A4B59"
          opacity="1.000000"
          stroke="none"
          d="
M500.606079,3265.945312 
	C495.357941,3260.030762 495.992889,3257.593018 502.702972,3255.934570 
	C505.915710,3255.140381 509.253815,3254.853516 513.309631,3254.242676 
	C514.583496,3254.845703 515.082886,3255.543457 515.828979,3256.597412 
	C516.541687,3260.827148 512.603210,3261.037109 510.452759,3263.666016 
	C507.526459,3266.834473 504.307129,3265.672852 500.606079,3265.945312 
z"
        />
        <path
          fill="#B6BCC1"
          opacity="1.000000"
          stroke="none"
          d="
M521.465576,3283.306152 
	C520.995911,3283.975342 520.223633,3284.010986 519.926086,3284.258301 
	C516.927673,3285.268066 514.036682,3285.604736 514.206787,3281.728271 
	C514.312012,3279.332275 515.557373,3276.352783 518.664978,3276.374512 
	C523.074402,3276.405273 522.454468,3279.689697 521.465576,3283.306152 
z"
        />
        <path
          fill="#A9AEB3"
          opacity="1.000000"
          stroke="none"
          d="
M510.677551,3264.240723 
	C509.773193,3260.708008 511.571350,3258.654297 515.403015,3257.266602 
	C518.783325,3257.273926 522.389771,3257.274414 518.368469,3261.092285 
	C516.503113,3262.863525 514.259583,3264.427002 510.677551,3264.240723 
z"
        />
        <path
          fill="#3A4B59"
          opacity="1.000000"
          stroke="none"
          d="
M555.759888,3250.571777 
	C558.235413,3252.348145 558.547913,3254.058350 556.324402,3255.387451 
	C555.179016,3256.071777 553.600708,3255.716553 552.911255,3254.481689 
	C551.646851,3252.217285 552.713440,3250.890381 555.759888,3250.571777 
z"
        />
        <path
          fill="#838F9A"
          opacity="1.000000"
          stroke="none"
          d="
M531.974670,3279.194824 
	C530.215271,3280.830322 528.630066,3281.809570 528.001099,3279.199951 
	C527.591980,3277.502686 528.278503,3275.665283 530.166382,3275.360352 
	C532.651794,3274.958984 532.484192,3276.901611 531.974670,3279.194824 
z"
        />
        <path
          fill="#152533"
          opacity="1.000000"
          stroke="none"
          d="
M501.915405,3291.596191 
	C500.688019,3292.117676 499.503082,3291.880859 498.019104,3291.101807 
	C497.222900,3289.508545 497.031921,3288.404297 497.551453,3286.496094 
	C498.861359,3285.810547 499.766113,3285.876221 501.330872,3286.113281 
	C501.979858,3287.802490 501.968842,3289.320068 501.915405,3291.596191 
z"
        />
        <path
          fill="#3A4B59"
          opacity="1.000000"
          stroke="none"
          d="
M527.129395,3266.089844 
	C526.394287,3267.420898 525.653076,3267.466797 524.992432,3266.505371 
	C524.857361,3266.308838 524.875732,3265.842529 525.020691,3265.645752 
	C525.692871,3264.734375 526.431274,3264.698730 527.129395,3266.089844 
z"
        />
        <path
          fill="#091825"
          opacity="1.000000"
          stroke="none"
          d="
M486.804138,3265.694824 
	C487.297821,3263.088135 488.837189,3262.872070 490.523865,3263.452637 
	C491.000000,3263.616699 491.684418,3264.646729 491.564331,3264.840820 
	C490.562531,3266.460449 488.825928,3266.374023 486.804138,3265.694824 
z"
        />
        <path
          fill="#3A4B59"
          opacity="1.000000"
          stroke="none"
          d="
M476.608307,3269.646484 
	C475.897461,3269.400635 475.978027,3268.514404 476.092590,3268.083008 
	C476.834534,3267.660889 477.465454,3267.630859 478.088531,3267.687256 
	C479.300323,3267.796875 481.414398,3267.838135 481.180542,3268.944580 
	C480.808807,3270.702881 478.759521,3270.001221 476.608307,3269.646484 
z"
        />
        <path
          fill="#1B2B3A"
          opacity="1.000000"
          stroke="none"
          d="
M497.182678,3285.770752 
	C497.928223,3286.816650 497.899811,3287.888428 497.795746,3289.760010 
	C496.315674,3289.238281 493.952271,3288.180908 497.182678,3285.770752 
z"
        />
        <path
          fill="#243748"
          opacity="1.000000"
          stroke="none"
          d="
M460.565796,3273.916748 
	C459.867249,3272.907227 460.414856,3272.199219 462.328491,3271.940430 
	C462.464630,3272.535400 461.835632,3273.143555 460.565796,3273.916748 
z"
        />
        <path
          fill="#ECA777"
          opacity="1.000000"
          stroke="none"
          d="
M623.073975,3294.149170 
	C622.482666,3290.469971 622.189819,3287.092041 621.701782,3282.887695 
	C622.598022,3277.065186 620.335938,3272.403564 620.162476,3266.745117 
	C624.167969,3260.045654 623.014587,3253.417969 623.498901,3246.466309 
	C625.865295,3243.471191 627.126953,3239.530518 631.393494,3241.564209 
	C634.423523,3243.008545 634.714661,3246.160156 634.463562,3249.215820 
	C634.252502,3251.784668 634.724976,3254.477783 632.779846,3257.279785 
	C632.120850,3258.277344 631.061890,3258.233154 631.620117,3259.021973 
	C632.447998,3258.875977 632.950867,3259.018799 633.724060,3259.656250 
	C633.404602,3262.210205 632.775391,3264.411621 632.082886,3267.301758 
	C633.955139,3278.268555 631.798279,3287.620117 624.906250,3296.009277 
	C623.782959,3295.506592 623.522888,3295.007080 623.073975,3294.149170 
z"
        />
        <path
          fill="#8C8D89"
          opacity="1.000000"
          stroke="none"
          d="
M601.056030,3222.016113 
	C602.401489,3219.636475 603.653137,3220.616943 604.770630,3221.788574 
	C605.446228,3222.497070 605.651245,3223.531494 604.464661,3223.863525 
	C603.205139,3224.215820 602.018250,3223.699951 601.056030,3222.016113 
z"
        />
        <path
          fill="#6E8AA1"
          opacity="1.000000"
          stroke="none"
          d="
M2055.880859,2194.655273 
	C2059.833008,2194.493652 2063.324463,2195.576904 2067.611816,2194.783447 
	C2078.256836,2192.814697 2089.137939,2195.552979 2100.012451,2194.694092 
	C2103.577637,2194.412354 2108.242432,2195.720459 2111.438965,2199.679932 
	C2114.110107,2202.989014 2127.296387,2200.026611 2129.113525,2196.126709 
	C2130.198975,2193.797119 2128.459717,2192.296143 2126.157471,2192.208984 
	C2120.839111,2192.007568 2115.488281,2192.335449 2110.194824,2191.904785 
	C2106.338379,2191.591064 2099.640625,2194.163330 2099.764648,2188.237549 
	C2099.850586,2184.126221 2106.481934,2186.745605 2110.120117,2186.023438 
	C2111.084961,2185.831787 2112.114258,2185.992920 2113.108887,2185.919922 
	C2115.166748,2185.769775 2117.437988,2185.414307 2117.232178,2182.786133 
	C2117.032227,2180.228516 2114.659912,2180.085205 2112.647461,2180.066162 
	C2105.989990,2180.002441 2099.326660,2179.849854 2092.675049,2180.048828 
	C2086.624023,2180.229736 2080.895020,2179.235107 2074.399658,2176.387939 
	C2078.082275,2172.849854 2082.107910,2171.449951 2086.213867,2172.238770 
	C2093.293945,2173.598389 2099.997559,2172.278320 2106.729736,2170.703857 
	C2109.711182,2170.006592 2112.812012,2169.443359 2115.619873,2170.187744 
	C2126.780518,2173.145508 2138.114014,2171.653564 2149.369873,2171.956543 
	C2153.425293,2172.065674 2157.360840,2171.989502 2160.418213,2175.317871 
	C2161.792969,2176.814453 2163.612061,2178.015137 2165.678467,2177.824463 
	C2175.227051,2176.943115 2184.675537,2178.808838 2194.248047,2178.693115 
	C2204.398193,2178.570068 2214.671875,2180.022705 2224.922363,2180.365234 
	C2226.349854,2180.413086 2227.328369,2181.579346 2227.299316,2183.042236 
	C2227.270264,2184.497070 2226.325439,2185.517334 2224.834473,2185.675537 
	C2222.855713,2185.884766 2220.860107,2185.981689 2218.869629,2186.025635 
	C2215.381836,2186.102539 2212.380615,2187.477783 2209.738281,2189.581543 
	C2208.249023,2190.767090 2206.280273,2192.092285 2207.357422,2194.454590 
	C2208.181396,2196.261963 2210.166748,2196.031006 2211.751465,2195.937744 
	C2216.856201,2195.637695 2221.420166,2195.814209 2226.594238,2192.451660 
	C2232.445557,2188.648926 2238.130371,2183.708984 2245.334473,2181.826416 
	C2248.686523,2180.950684 2251.865234,2179.834473 2254.558105,2182.768799 
	C2257.458252,2185.929199 2254.013428,2188.161133 2253.080811,2190.763916 
	C2252.876221,2191.334473 2253.050781,2192.040771 2253.050781,2193.057861 
	C2256.087646,2194.167480 2259.232178,2195.182617 2262.261719,2196.467529 
	C2264.561035,2197.442627 2266.289307,2199.134521 2265.202637,2201.902344 
	C2264.296387,2204.210449 2261.849609,2204.388916 2260.093506,2203.796143 
	C2252.952881,2201.387207 2245.486084,2202.933594 2238.369629,2201.704590 
	C2232.978271,2200.773438 2227.857666,2200.639648 2222.781982,2202.046631 
	C2211.470215,2205.182373 2199.539551,2202.383545 2188.291260,2205.916260 
	C2186.654053,2206.430420 2185.101318,2206.768555 2184.190674,2209.238770 
	C2171.390625,2210.134277 2158.777100,2210.177246 2146.261719,2207.963379 
	C2140.422119,2206.930176 2135.357178,2202.974365 2128.954590,2203.903076 
	C2125.367920,2204.423340 2121.630371,2203.771484 2118.484131,2206.300049 
	C2115.941650,2208.343262 2112.825684,2207.985352 2109.842041,2207.989990 
	C2100.510498,2208.004150 2091.179199,2207.983154 2080.909180,2207.963379 
	C2076.555420,2204.559326 2073.171631,2200.805664 2068.476074,2207.467773 
	C2060.946045,2209.713867 2058.822266,2201.932129 2053.446533,2200.140625 
	C2052.252930,2199.742920 2055.597412,2197.308105 2055.880859,2194.655273 
z"
        />
        <path
          fill="#1B1E22"
          opacity="1.000000"
          stroke="none"
          d="
M1589.479614,2098.123535 
	C1596.485352,2100.779053 1596.503418,2100.781250 1609.205933,2100.106934 
	C1614.768799,2098.879883 1617.191895,2102.882080 1621.133301,2104.896240 
	C1622.502441,2104.868408 1623.216064,2104.603271 1624.334473,2103.796387 
	C1625.127441,2103.189941 1625.448975,2102.945801 1626.263184,2102.342529 
	C1630.040649,2099.390137 1630.307129,2094.019531 1635.805176,2092.657959 
	C1638.828857,2091.965576 1641.113770,2092.581299 1644.124756,2093.006348 
	C1646.735229,2092.884521 1648.638794,2092.991455 1651.260742,2092.974121 
	C1654.381348,2093.179932 1656.784424,2093.172852 1659.915527,2093.011230 
	C1661.604004,2092.740967 1662.564575,2092.529541 1664.270264,2092.222656 
	C1666.682983,2092.078369 1668.580566,2091.926025 1669.880005,2092.804443 
	C1683.900024,2102.281006 1701.922363,2102.680664 1716.126587,2113.453369 
	C1720.346191,2120.045410 1728.777710,2121.621826 1731.400635,2129.650879 
	C1731.680908,2130.298096 1731.648804,2130.373047 1731.613037,2130.399414 
	C1730.984253,2133.549316 1732.573853,2136.029541 1734.040894,2138.507324 
	C1736.890381,2143.320557 1738.394897,2148.472656 1738.345947,2154.893555 
	C1739.062378,2163.827637 1740.974365,2172.123535 1734.733643,2179.895020 
	C1729.913574,2181.726318 1726.621338,2179.625488 1724.597900,2176.600586 
	C1716.682373,2164.768311 1703.555786,2158.673096 1694.188721,2148.459717 
	C1692.394531,2146.503418 1689.977539,2145.033447 1689.436279,2142.309082 
	C1688.408325,2137.136230 1685.660034,2135.546875 1680.938232,2137.862061 
	C1678.564209,2139.025879 1675.404785,2139.476318 1674.902466,2137.013672 
	C1672.638062,2125.910889 1660.826904,2127.021484 1655.460449,2120.288574 
	C1651.610229,2122.409424 1654.857422,2127.450439 1650.906982,2128.837158 
	C1647.106812,2130.170898 1643.723511,2128.402588 1640.316895,2127.187256 
	C1636.845825,2125.948486 1633.358887,2124.916260 1628.942627,2124.930664 
	C1623.555298,2124.475830 1619.202881,2127.457764 1613.812744,2126.007812 
	C1605.549927,2119.185059 1599.198486,2111.451416 1588.411865,2108.717773 
	C1581.229004,2106.897217 1585.987671,2101.831299 1589.479614,2098.123535 
z"
        />
        <path
          fill="#6E8AA1"
          opacity="1.000000"
          stroke="none"
          d="
M2043.229248,2151.875977 
	C2050.152832,2151.934570 2056.065430,2153.018311 2060.505615,2157.559570 
	C2063.980957,2161.114014 2068.950439,2158.735352 2073.237793,2161.677979 
	C2067.823975,2166.260010 2059.823975,2162.099609 2054.936279,2168.079834 
	C2057.781250,2171.440430 2062.446045,2169.473389 2065.694092,2171.530029 
	C2067.705566,2172.803467 2070.267822,2173.288574 2069.366699,2176.357910 
	C2068.621094,2178.896973 2066.491943,2179.861084 2063.927246,2179.822021 
	C2063.268311,2179.811768 2062.479736,2179.879639 2061.973877,2179.559082 
	C2053.928223,2174.462402 2044.791992,2177.266602 2036.222046,2176.251465 
	C2028.172241,2175.297852 2027.210327,2177.291992 2028.879150,2185.589355 
	C2029.074463,2186.559814 2029.473511,2187.489014 2029.879150,2189.228516 
	C2029.980469,2190.019531 2029.010132,2190.011230 2028.524902,2190.007080 
	C2027.609375,2190.477051 2027.140503,2190.909424 2026.311768,2191.654785 
	C2022.990723,2193.703125 2019.767334,2194.317383 2016.365112,2193.902588 
	C2014.717285,2193.701660 2012.352539,2193.222900 2013.528320,2191.114258 
	C2016.133301,2186.442627 2013.543213,2186.038574 2010.000000,2186.032959 
	C2003.009888,2186.021973 1996.019165,2186.000244 1989.029907,2186.083496 
	C1987.042480,2186.107178 1984.817627,2186.292969 1984.687500,2189.019287 
	C1984.584717,2191.166504 1986.388916,2191.537842 1988.005493,2192.018799 
	C1989.186279,2192.370117 1990.702148,2192.487793 1990.459961,2194.414307 
	C1989.192261,2196.636475 1986.981689,2195.856445 1985.136230,2195.959473 
	C1970.242798,2196.792725 1955.352661,2195.237793 1940.360107,2197.307861 
	C1933.970215,2198.190186 1926.654907,2195.982422 1919.682617,2196.000000 
	C1907.699097,2196.030762 1895.713867,2196.097900 1883.732788,2195.919678 
	C1881.187500,2195.881592 1876.937744,2197.411377 1877.182373,2192.495361 
	C1877.379639,2188.530273 1879.380005,2185.846680 1884.207397,2185.941650 
	C1895.520752,2186.164551 1906.841675,2185.992188 1918.159546,2186.007080 
	C1922.157715,2186.012207 1925.934082,2185.309326 1929.864014,2184.266602 
	C1940.001587,2181.577148 1950.328979,2178.799316 1960.968994,2181.402344 
	C1966.181274,2182.677979 1970.815796,2180.283447 1975.546631,2179.287842 
	C1978.845215,2178.593018 1975.776001,2176.416016 1974.383301,2174.523438 
	C1974.744629,2168.192871 1979.779785,2168.520508 1983.628174,2167.614746 
	C1989.443726,2166.246826 1995.635254,2166.162354 2001.347412,2167.036621 
	C2013.039673,2168.826904 2024.192383,2164.477051 2035.682373,2165.243164 
	C2039.509399,2165.498291 2041.114868,2163.871826 2040.723145,2159.981934 
	C2040.434448,2157.115967 2039.565674,2154.000000 2043.229248,2151.875977 
z"
        />
        <path
          fill="#6E8AA1"
          opacity="1.000000"
          stroke="none"
          d="
M1907.449219,2156.509766 
	C1908.627930,2156.126465 1909.345947,2156.090088 1909.916992,2156.362305 
	C1919.464966,2160.912598 1919.562988,2160.969238 1924.086792,2151.420166 
	C1925.072266,2149.340576 1926.494507,2147.499023 1928.332397,2148.249023 
	C1934.393677,2150.722656 1940.747925,2146.239258 1947.319946,2149.699219 
	C1948.000000,2150.000000 1948.000000,2151.000000 1948.000000,2151.500000 
	C1946.570312,2153.479004 1946.249756,2155.638672 1944.424438,2157.567627 
	C1937.457153,2158.702393 1937.587158,2158.673584 1938.873291,2164.466797 
	C1939.612549,2167.797119 1937.775513,2171.568604 1942.642334,2175.090088 
	C1934.887695,2175.692627 1929.397461,2178.096924 1923.415527,2176.331543 
	C1922.235718,2175.983398 1920.447266,2175.964600 1919.562378,2176.626465 
	C1912.874634,2181.629395 1905.932739,2179.186523 1898.653687,2178.431885 
	C1888.477173,2177.376953 1878.119751,2178.066162 1867.839722,2178.010742 
	C1865.849609,2178.000000 1863.853149,2178.096191 1861.870605,2177.969727 
	C1856.570557,2177.631836 1856.572876,2177.594482 1859.863281,2170.687012 
	C1863.409302,2170.012695 1866.838867,2170.005859 1871.125000,2169.996338 
	C1878.100342,2169.690186 1883.713867,2168.569092 1887.611084,2162.973145 
	C1889.858032,2159.746338 1893.702637,2159.219482 1897.449829,2159.046631 
	C1900.718262,2158.895752 1904.171509,2159.567627 1907.449219,2156.509766 
z"
        />
        <path
          fill="#6E8AA1"
          opacity="1.000000"
          stroke="none"
          d="
M2174.748047,2252.408447 
	C2164.471436,2248.616455 2155.078613,2245.899414 2144.630615,2245.729736 
	C2121.416260,2245.352539 2098.232910,2243.240723 2074.975830,2244.008057 
	C2070.722656,2244.148193 2066.270752,2243.170654 2062.529297,2246.302734 
	C2061.139893,2247.465820 2059.332031,2247.719482 2057.925781,2246.556152 
	C2053.569824,2242.952393 2048.436279,2244.252197 2043.544678,2244.004150 
	C2036.281982,2243.635498 2028.912964,2244.990723 2021.781372,2242.406982 
	C2019.495117,2241.578857 2017.196533,2242.515381 2014.998413,2243.354736 
	C2011.554443,2244.670166 2008.496582,2243.093262 2005.356323,2242.049805 
	C2003.134155,2241.311523 2001.914062,2239.890625 2002.640259,2237.578613 
	C2003.290771,2235.507812 2005.082764,2233.936768 2007.167847,2234.452881 
	C2018.463379,2237.247803 2030.218994,2235.312012 2041.499268,2237.955811 
	C2054.951904,2241.109375 2068.497803,2240.647461 2082.036621,2238.889404 
	C2084.432861,2238.578613 2086.539307,2238.679443 2088.848145,2239.199219 
	C2091.408691,2239.775635 2094.229004,2240.391113 2096.718994,2239.896240 
	C2112.574463,2236.744385 2128.356689,2241.067871 2144.189697,2240.069580 
	C2156.158936,2239.314941 2167.935547,2241.461914 2179.694092,2243.492920 
	C2182.608154,2243.996094 2185.813965,2244.418213 2188.607666,2243.737305 
	C2201.540527,2240.586914 2214.245117,2241.559814 2227.471191,2245.503906 
	C2216.323730,2252.038330 2203.296143,2251.026611 2190.836914,2252.711670 
	C2185.933350,2253.375000 2180.612549,2255.905029 2174.748047,2252.408447 
z"
        />
        <path
          fill="#5B768D"
          opacity="1.000000"
          stroke="none"
          d="
M2003.253174,2258.271484 
	C2012.637451,2261.883545 2021.888916,2258.400635 2032.366455,2261.497803 
	C2023.025513,2266.134521 2014.584839,2267.236816 2006.509521,2265.675781 
	C1993.575439,2263.175049 1980.670532,2264.216553 1967.762695,2264.125488 
	C1958.848999,2264.062256 1949.697510,2265.138916 1941.024048,2264.748047 
	C1930.292236,2264.264160 1920.506714,2267.629395 1910.250488,2268.561279 
	C1899.971069,2269.495361 1889.754883,2269.697998 1879.498413,2269.960449 
	C1877.186890,2270.019287 1874.837646,2269.857666 1872.565063,2269.459717 
	C1871.853638,2269.335205 1871.014404,2268.187012 1870.800659,2267.360596 
	C1870.419067,2265.885254 1871.385742,2264.636963 1872.764648,2264.474121 
	C1875.722534,2264.124268 1878.721191,2264.077393 1881.705566,2264.001221 
	C1891.300293,2263.756592 1900.958618,2264.893311 1910.418213,2262.098389 
	C1916.013672,2260.445068 1921.161499,2257.686035 1927.217651,2256.999756 
	C1931.845093,2256.475342 1936.513062,2255.583740 1941.050049,2256.142334 
	C1961.431519,2258.651611 1981.910278,2257.301758 2003.253174,2258.271484 
z"
        />
        <path
          fill="#5B768D"
          opacity="1.000000"
          stroke="none"
          d="
M1961.660034,2244.019531 
	C1973.715088,2245.920410 1985.089355,2245.155762 1996.137573,2247.603271 
	C2002.135376,2248.932373 2008.538574,2246.828613 2016.509644,2249.680908 
	C2008.888794,2253.260986 2002.785278,2252.413574 1996.995361,2251.173340 
	C1991.643188,2250.027100 1986.572144,2249.602539 1981.295654,2251.175537 
	C1976.097290,2252.725342 1970.668335,2252.267578 1965.416504,2251.869141 
	C1955.869751,2251.145752 1946.377441,2249.715820 1936.857666,2248.621094 
	C1934.891235,2248.394531 1932.709839,2247.748047 1930.959839,2248.317383 
	C1922.473267,2251.076904 1913.757324,2249.311768 1905.193970,2250.054688 
	C1886.984985,2251.634277 1868.761475,2252.547852 1850.492065,2251.906738 
	C1847.157959,2251.790039 1841.220947,2253.587158 1841.193848,2249.342285 
	C1841.157104,2243.579102 1847.356689,2246.359619 1850.771729,2246.151123 
	C1858.400024,2245.684570 1866.076294,2245.896729 1873.730835,2245.996826 
	C1878.441162,2246.058350 1882.924805,2245.682861 1887.282593,2243.491455 
	C1892.902588,2240.665283 1899.301636,2241.222412 1904.953857,2242.314941 
	C1918.208740,2244.876953 1931.471924,2243.585205 1944.723267,2243.983887 
	C1950.044556,2244.144043 1955.374512,2244.016113 1961.660034,2244.019531 
z"
        />
        <path
          fill="#6E8AA1"
          opacity="1.000000"
          stroke="none"
          d="
M2176.732422,2270.575928 
	C2175.077637,2260.510986 2174.586914,2259.303467 2166.284180,2260.235596 
	C2158.510498,2261.108398 2151.409180,2259.162109 2143.986816,2258.168701 
	C2134.375488,2256.882080 2124.764160,2258.467285 2115.312012,2260.570557 
	C2113.695312,2260.930420 2111.717773,2262.225586 2110.558594,2261.686768 
	C2100.111328,2256.831299 2089.040283,2261.920166 2078.507080,2259.402100 
	C2078.207275,2259.330322 2077.962158,2259.028320 2077.626465,2258.786865 
	C2077.662598,2254.928711 2080.736816,2256.214355 2082.661865,2256.068848 
	C2089.470947,2255.553955 2096.706055,2257.611328 2102.701904,2252.455566 
	C2103.130371,2252.087402 2104.049316,2251.976807 2104.626953,2252.142090 
	C2115.642578,2255.295410 2126.755371,2250.370605 2138.129150,2253.088379 
	C2144.309082,2254.565186 2151.648682,2253.081543 2158.550049,2253.779785 
	C2163.846680,2254.315918 2168.845459,2256.787354 2175.141113,2256.001953 
	C2183.606201,2251.755859 2191.719238,2252.743408 2200.708984,2254.077637 
	C2206.404541,2258.485596 2209.171143,2263.222656 2206.270020,2270.434082 
	C2196.238525,2274.554932 2186.844482,2273.384766 2176.732422,2270.575928 
z"
        />
        <path
          fill="#728DA3"
          opacity="1.000000"
          stroke="none"
          d="
M2043.527344,2151.527100 
	C2040.999756,2156.313721 2044.584473,2160.082275 2045.079102,2164.090088 
	C2045.678101,2168.942871 2044.577271,2170.752686 2040.101562,2169.490479 
	C2032.080200,2167.227295 2024.511719,2167.299072 2017.057739,2171.226562 
	C2015.536743,2172.027832 2013.873047,2171.391846 2012.228271,2171.139893 
	C1999.739136,2169.226074 1987.300781,2168.830322 1974.544678,2173.457764 
	C1971.866577,2174.000000 1969.733276,2174.000000 1966.799927,2174.000000 
	C1961.022583,2168.021729 1965.713989,2162.776855 1967.633545,2156.646973 
	C1970.695679,2153.123779 1974.076538,2153.583740 1977.105957,2154.771729 
	C1981.752197,2156.593506 1986.060913,2155.899414 1991.223145,2154.114746 
	C1994.117432,2158.081787 1996.778931,2160.417969 2002.036255,2159.502441 
	C2011.794556,2157.803223 2021.255737,2163.913086 2031.587158,2160.551025 
	C2032.868530,2152.453125 2032.868530,2152.453125 2043.527344,2151.527100 
z"
        />
        <path
          fill="#597288"
          opacity="1.000000"
          stroke="none"
          d="
M1752.534424,2263.886963 
	C1750.441406,2262.368896 1749.229736,2260.884277 1747.909668,2258.824463 
	C1754.676880,2257.888428 1761.479858,2256.572510 1768.462891,2257.886475 
	C1771.935425,2258.540039 1775.778931,2258.555908 1778.989136,2256.059326 
	C1780.739624,2254.697754 1782.758789,2254.134521 1783.852539,2256.732178 
	C1785.393799,2260.393311 1788.483398,2260.132324 1791.424194,2259.944824 
	C1806.400269,2258.990723 1821.105103,2262.985352 1836.112427,2262.218018 
	C1840.881226,2261.974121 1845.760620,2263.889160 1850.490479,2264.797119 
	C1850.541504,2268.509521 1848.029785,2267.826172 1846.265381,2267.927490 
	C1822.796753,2269.280518 1799.327148,2267.444336 1775.851440,2267.874512 
	C1768.217041,2268.014648 1761.215698,2263.488037 1752.534424,2263.886963 
z"
        />
        <path
          fill="#597288"
          opacity="1.000000"
          stroke="none"
          d="
M1747.878174,2257.598633 
	C1741.575684,2256.046143 1739.741333,2249.697266 1735.176147,2246.177246 
	C1740.323608,2238.718262 1740.323608,2238.718262 1736.166748,2231.046387 
	C1736.663086,2229.552979 1737.343018,2228.943115 1738.934570,2228.466797 
	C1749.362915,2227.114502 1753.891968,2221.932373 1753.824219,2211.204590 
	C1753.312988,2206.846436 1753.313721,2203.302979 1757.163574,2200.334961 
	C1760.076050,2200.236816 1761.228760,2201.449951 1762.129150,2203.505127 
	C1764.975220,2210.002441 1763.421387,2217.217041 1765.706787,2223.817139 
	C1766.421753,2225.881836 1764.424438,2227.267822 1762.870605,2228.595703 
	C1757.053345,2233.568115 1753.153687,2240.191650 1748.265503,2245.968262 
	C1746.954956,2247.517090 1745.005615,2250.021484 1748.331665,2251.702393 
	C1752.614868,2253.866943 1747.214233,2255.201904 1747.878174,2257.598633 
z"
        />
        <path
          fill="#5B768D"
          opacity="1.000000"
          stroke="none"
          d="
M2202.743164,2256.521484 
	C2193.944336,2256.020508 2185.898438,2256.011963 2176.926270,2256.001465 
	C2175.475830,2254.992432 2174.951660,2253.985107 2174.208984,2252.450684 
	C2185.468994,2250.451416 2197.047363,2249.496338 2208.384521,2247.296387 
	C2214.388184,2246.131348 2220.260254,2246.311035 2227.113770,2246.050049 
	C2230.127441,2246.979736 2232.205078,2248.171387 2234.584229,2247.946289 
	C2241.436768,2247.298828 2248.143799,2249.051025 2255.475342,2248.533691 
	C2256.884766,2248.013184 2257.769531,2248.016357 2259.317383,2248.019531 
	C2259.993896,2249.519043 2260.007324,2251.018799 2260.030029,2253.269043 
	C2254.510742,2254.130371 2248.895996,2253.439453 2242.810791,2255.907471 
	C2239.174805,2258.725830 2235.599365,2259.655029 2231.950928,2259.171875 
	C2222.497070,2257.920166 2212.942383,2258.206543 2202.743164,2256.521484 
z"
        />
        <path
          fill="#1B1E22"
          opacity="1.000000"
          stroke="none"
          d="
M1740.534912,2229.494873 
	C1739.135620,2230.027588 1738.291382,2230.145996 1736.811523,2230.325195 
	C1733.652100,2229.710205 1731.976807,2231.011963 1730.267578,2233.395508 
	C1729.086548,2233.968018 1728.154419,2233.959229 1726.532471,2233.832764 
	C1724.842285,2232.727783 1723.925293,2230.917969 1724.672363,2230.234619 
	C1730.799561,2224.628174 1730.281128,2214.596924 1738.054688,2210.209717 
	C1743.801636,2206.966553 1744.760254,2203.671387 1739.168579,2199.084961 
	C1734.730103,2195.444336 1731.412964,2190.896484 1733.509399,2183.924316 
	C1742.108765,2181.352295 1746.536865,2188.353516 1753.223633,2191.675781 
	C1755.008789,2194.430176 1756.291870,2196.613525 1757.725952,2199.412598 
	C1756.261963,2203.373047 1756.530151,2206.991211 1756.300049,2211.320312 
	C1755.173706,2221.307373 1746.614502,2223.839355 1740.534912,2229.494873 
z"
        />
        <path
          fill="#637E95"
          opacity="1.000000"
          stroke="none"
          d="
M1929.217896,2291.990234 
	C1930.374268,2293.119629 1930.724121,2294.219482 1931.543457,2295.679199 
	C1927.776978,2304.998779 1922.728882,2306.499512 1915.424683,2300.950195 
	C1914.902832,2300.553711 1914.553101,2299.925049 1914.021606,2299.547852 
	C1912.000244,2298.114014 1909.267212,2297.043213 1907.739624,2299.121826 
	C1905.544189,2302.108643 1910.955444,2301.587646 1910.744385,2304.725342 
	C1907.191406,2307.223633 1902.780762,2305.535400 1898.717041,2305.993164 
	C1894.198364,2306.502441 1891.146973,2304.537842 1889.001343,2300.597656 
	C1886.925171,2296.785156 1882.071899,2299.180420 1878.541382,2295.992676 
	C1882.575317,2293.091553 1886.242920,2290.834229 1890.961670,2293.583984 
	C1894.524536,2295.660156 1897.567017,2294.553955 1901.272583,2293.670654 
	C1910.123535,2291.561035 1919.149658,2288.844727 1929.217896,2291.990234 
z"
        />
        <path
          fill="#46565D"
          opacity="1.000000"
          stroke="none"
          d="
M1753.808594,2191.426270 
	C1749.036133,2192.060303 1744.661865,2191.177246 1741.347778,2187.375732 
	C1739.813110,2185.615234 1737.716553,2184.623047 1734.676758,2184.137207 
	C1731.901245,2184.297363 1731.328125,2183.148926 1732.072388,2180.898926 
	C1735.129761,2177.222412 1736.112671,2173.598877 1735.864624,2169.575439 
	C1735.603271,2165.339600 1736.062256,2161.199951 1738.190186,2156.505371 
	C1741.737305,2156.656006 1741.212036,2159.603760 1742.023560,2162.015625 
	C1743.192261,2165.487793 1742.361084,2170.802734 1748.775513,2170.359375 
	C1750.524536,2170.238281 1749.722778,2172.382812 1749.984863,2173.474854 
	C1751.351196,2179.169678 1752.600952,2184.892822 1753.808594,2191.426270 
z"
        />
        <path
          fill="#536F86"
          opacity="1.000000"
          stroke="none"
          d="
M2060.343994,2276.000000 
	C2055.734863,2275.938477 2052.181641,2276.250488 2048.605713,2277.349121 
	C2045.116943,2278.420898 2041.390381,2278.300049 2037.785156,2277.410156 
	C2036.401855,2277.068359 2035.307983,2276.065674 2035.166138,2274.724365 
	C2035.011230,2273.260254 2036.115845,2272.164551 2037.589844,2272.256836 
	C2053.512695,2273.254395 2069.255615,2268.643555 2085.115723,2270.486816 
	C2090.345215,2271.094482 2095.569580,2271.868896 2100.840820,2272.279785 
	C2102.847412,2272.436035 2104.938232,2273.271729 2104.738525,2275.800537 
	C2104.563965,2278.017334 2102.456299,2277.884766 2100.884033,2277.921143 
	C2097.236816,2278.005371 2093.383301,2278.659424 2089.974609,2277.741211 
	C2080.470459,2275.180420 2070.862305,2276.307373 2060.343994,2276.000000 
z"
        />
        <path
          fill="#516D85"
          opacity="1.000000"
          stroke="none"
          d="
M2175.387451,2270.092285 
	C2185.383545,2269.947021 2194.750244,2269.952881 2205.047852,2269.940918 
	C2207.981689,2268.298096 2210.156250,2267.854248 2213.283203,2269.470215 
	C2220.030762,2272.601807 2226.110596,2269.349365 2232.962402,2270.153564 
	C2235.131836,2271.400391 2235.431641,2272.930664 2234.490967,2275.331055 
	C2226.626465,2276.033203 2219.252930,2276.065674 2210.964844,2276.158447 
	C2198.552979,2275.566162 2186.901855,2277.157471 2175.586670,2273.847412 
	C2173.139893,2273.131592 2171.863037,2272.173828 2175.387451,2270.092285 
z"
        />
        <path
          fill="#637E95"
          opacity="1.000000"
          stroke="none"
          d="
M1967.170410,2308.045654 
	C1967.589233,2310.790039 1965.849243,2311.774658 1963.202393,2311.989990 
	C1959.999878,2312.250488 1956.581909,2311.119629 1952.373291,2314.072266 
	C1961.922241,2318.419922 1971.945679,2313.778564 1980.452271,2318.111572 
	C1966.566895,2321.980469 1952.902710,2318.603027 1938.599121,2316.599609 
	C1937.971191,2312.798584 1938.670166,2310.432373 1942.729004,2309.987061 
	C1950.559570,2309.128418 1958.286377,2307.194336 1967.170410,2308.045654 
z"
        />
        <path
          fill="#637E95"
          opacity="1.000000"
          stroke="none"
          d="
M2046.872314,2301.988281 
	C2051.270264,2295.740967 2059.840332,2294.355469 2067.830322,2299.221680 
	C2061.565186,2303.510010 2054.536377,2301.450684 2046.872314,2301.988281 
z"
        />
        <path
          fill="#6E8AA1"
          opacity="1.000000"
          stroke="none"
          d="
M1967.250000,2156.000000 
	C1968.621582,2157.774170 1970.229858,2159.723877 1968.471558,2161.398193 
	C1965.105347,2164.604248 1966.258911,2168.609375 1966.000000,2173.194824 
	C1963.008911,2172.923096 1960.017822,2171.846436 1957.111694,2170.800293 
	C1956.601562,2167.293945 1959.090942,2165.966309 1961.114624,2164.488037 
	C1963.175415,2162.982666 1964.524170,2161.456543 1962.480469,2158.424805 
	C1961.743652,2157.656250 1961.492065,2157.322266 1961.620117,2156.489258 
	C1963.500000,2155.993408 1965.000000,2155.996582 1967.250000,2156.000000 
z"
        />
        <path
          fill="#637E95"
          opacity="1.000000"
          stroke="none"
          d="
M1777.580322,2281.489502 
	C1774.629761,2286.093018 1769.953369,2286.105957 1764.419312,2286.048828 
	C1762.188477,2284.874756 1762.076782,2283.148682 1761.537842,2280.920898 
	C1765.222534,2280.734863 1767.699097,2278.030273 1771.291260,2276.131592 
	C1771.930542,2279.509033 1775.044922,2279.734863 1777.580322,2281.489502 
z"
        />
        <path
          fill="#738EA6"
          opacity="1.000000"
          stroke="none"
          d="
M2068.880859,2207.955566 
	C2068.661865,2205.427002 2064.342529,2202.166260 2068.944092,2200.322021 
	C2073.572510,2198.466797 2077.435303,2200.745850 2079.958984,2207.174072 
	C2076.558350,2207.956055 2073.145996,2207.960938 2068.880859,2207.955566 
z"
        />
        <path
          fill="#6E8AA1"
          opacity="1.000000"
          stroke="none"
          d="
M1873.460449,2203.468506 
	C1869.617676,2205.170410 1866.218262,2206.410645 1862.463013,2205.938721 
	C1860.746704,2205.723145 1858.912720,2205.408203 1859.091675,2203.313477 
	C1859.241089,2201.564209 1860.844360,2200.204590 1862.572754,2200.479736 
	C1866.384888,2201.086426 1870.124878,2202.146729 1873.460449,2203.468506 
z"
        />
        <path
          fill="#6E8AA1"
          opacity="1.000000"
          stroke="none"
          d="
M1865.547607,2187.989258 
	C1862.269165,2187.848877 1859.592285,2188.555664 1856.784912,2186.133545 
	C1860.299438,2182.707764 1864.311646,2184.379395 1867.847046,2184.179199 
	C1868.949951,2184.116455 1870.860352,2184.354980 1870.390747,2186.251953 
	C1869.885620,2188.291748 1867.848022,2187.744873 1865.547607,2187.989258 
z"
        />
        <path
          fill="#637E95"
          opacity="1.000000"
          stroke="none"
          d="
M1962.088135,2298.717285 
	C1966.285400,2298.320557 1970.679199,2297.167725 1975.453613,2299.421387 
	C1973.953369,2300.981934 1971.922852,2302.105957 1969.798096,2303.008545 
	C1965.985962,2304.628418 1963.413452,2303.385498 1962.088135,2298.717285 
z"
        />
        <path
          fill="#728DA3"
          opacity="1.000000"
          stroke="none"
          d="
M1767.138062,2179.734375 
	C1765.319946,2178.697510 1764.558350,2177.412598 1766.203491,2176.719482 
	C1768.882812,2175.591064 1771.742554,2175.510254 1774.753662,2177.296875 
	C1773.586792,2181.425781 1770.181641,2179.375244 1767.138062,2179.734375 
z"
        />
        <path
          fill="#637E95"
          opacity="1.000000"
          stroke="none"
          d="
M2088.706055,2299.982422 
	C2088.315674,2295.948730 2090.581543,2294.042480 2095.291260,2294.037109 
	C2095.680908,2298.080078 2093.388672,2299.951172 2088.706055,2299.982422 
z"
        />
        <path
          fill="#728DA3"
          opacity="1.000000"
          stroke="none"
          d="
M1961.869507,2155.323486 
	C1961.999512,2156.323486 1961.998901,2156.656982 1961.996582,2157.490234 
	C1960.494019,2157.988525 1958.992920,2157.986816 1956.740967,2157.973145 
	C1956.160767,2156.035400 1955.694824,2153.773926 1957.909424,2152.724365 
	C1959.800415,2151.828125 1961.009399,2152.919922 1961.869507,2155.323486 
z"
        />
        <path
          fill="#728DA3"
          opacity="1.000000"
          stroke="none"
          d="
M1802.513184,2144.997070 
	C1800.815796,2145.727783 1799.600342,2145.675537 1799.139526,2144.207764 
	C1799.013672,2143.806885 1799.477905,2142.851562 1799.861816,2142.724121 
	C1801.501953,2142.179443 1802.945801,2142.277100 1802.513184,2144.997070 
z"
        />
        <path
          fill="#728DA3"
          opacity="1.000000"
          stroke="none"
          d="
M1944.667480,2158.000000 
	C1944.063354,2155.725098 1944.907349,2153.864014 1947.432739,2152.296875 
	C1948.000488,2153.497803 1948.000854,2154.995361 1948.003052,2157.241699 
	C1947.114868,2157.993408 1946.224976,2157.996582 1944.667480,2158.000000 
z"
        />
        <path
          fill="#738EA6"
          opacity="1.000000"
          stroke="none"
          d="
M2026.422485,2191.776855 
	C2026.234375,2191.380615 2026.627441,2190.864990 2027.604614,2190.232910 
	C2027.790283,2190.628418 2027.395264,2191.142334 2026.422485,2191.776855 
z"
        />
        <path
          fill="#637E95"
          opacity="1.000000"
          stroke="none"
          d="
M2402.172852,2280.724365 
	C2419.428955,2280.036133 2436.867920,2279.551025 2455.092285,2282.007568 
	C2455.582275,2284.134766 2454.551514,2285.755127 2452.197754,2285.827393 
	C2436.164062,2286.319092 2420.149414,2286.259033 2404.280273,2283.494141 
	C2403.535400,2283.364258 2402.979736,2282.148682 2402.172852,2280.724365 
z"
        />
        <path
          fill="#637E95"
          opacity="1.000000"
          stroke="none"
          d="
M2463.411621,2295.510010 
	C2460.885986,2295.154053 2460.046143,2293.940430 2460.624512,2292.071045 
	C2460.934814,2291.068359 2462.048584,2290.606689 2462.952148,2291.031494 
	C2464.969238,2291.979248 2466.094238,2293.355957 2463.411621,2295.510010 
z"
        />
        <path
          fill="#6E8AA1"
          opacity="1.000000"
          stroke="none"
          d="
M2280.021973,2271.443848 
	C2279.365967,2270.721191 2279.752686,2270.273193 2280.378906,2270.766602 
	C2281.412354,2271.580322 2278.968018,2271.442383 2280.021973,2271.443848 
z"
        />
        <path
          fill="#040407"
          opacity="1.000000"
          stroke="none"
          d="
M1732.189453,2179.871094 
	C1732.868896,2181.412842 1733.051880,2182.386963 1733.594482,2183.729736 
	C1734.800171,2191.098877 1738.726807,2195.905518 1744.403809,2199.836670 
	C1749.146606,2203.120605 1749.002197,2206.102539 1744.409912,2209.641846 
	C1742.351196,2211.228516 1740.049561,2212.527832 1737.757324,2213.771729 
	C1736.631470,2214.382324 1734.502686,2214.610107 1734.679565,2215.794189 
	C1735.838623,2223.545898 1727.289062,2226.025391 1725.908936,2233.026855 
	C1725.292847,2234.126953 1724.742920,2234.538818 1723.930908,2235.398438 
	C1718.587891,2243.187012 1711.926270,2248.683105 1703.480347,2253.161621 
	C1689.216064,2259.909668 1682.621948,2256.447021 1676.176636,2239.554199 
	C1671.217529,2242.247314 1666.998657,2249.399170 1660.180908,2241.514648 
	C1658.659546,2232.244385 1661.908569,2223.506104 1658.517334,2214.795410 
	C1656.955811,2210.784424 1660.004272,2206.610352 1662.481079,2203.187744 
	C1654.981934,2203.719727 1646.131714,2200.004150 1642.759644,2211.729492 
	C1640.052002,2221.144043 1646.661011,2223.997559 1652.213013,2228.927734 
	C1653.101562,2230.350098 1653.329224,2231.274902 1653.240967,2232.965820 
	C1652.401611,2235.555176 1650.899414,2236.832031 1649.101562,2238.775635 
	C1647.023071,2240.426758 1645.282715,2241.193359 1642.778687,2241.952637 
	C1637.839478,2243.359863 1633.275391,2239.347656 1628.537842,2243.190918 
	C1626.213501,2244.261475 1624.390381,2244.571289 1621.861328,2244.866455 
	C1619.787964,2244.953369 1618.440674,2245.174805 1616.491455,2245.902832 
	C1610.327148,2247.845947 1604.617798,2246.401611 1599.006836,2247.219238 
	C1594.489136,2247.877441 1591.951538,2244.117920 1589.479736,2240.986084 
	C1586.635864,2237.382812 1584.877197,2232.813477 1579.575195,2230.597168 
	C1577.071289,2229.586182 1575.386108,2228.581543 1573.386108,2226.700684 
	C1572.088867,2224.785889 1571.690063,2223.319092 1571.458008,2221.025879 
	C1572.203369,2214.895264 1571.672974,2208.815918 1579.409546,2206.676758 
	C1583.536377,2205.916992 1585.124390,2209.376465 1588.373413,2210.224365 
	C1590.532959,2206.562988 1589.561035,2201.866455 1592.102661,2198.338867 
	C1594.735474,2194.684082 1597.666992,2191.374756 1602.624146,2192.775391 
	C1607.797607,2194.237549 1609.565918,2198.427490 1609.455688,2203.500977 
	C1609.427124,2204.810547 1608.864990,2206.072266 1608.700928,2207.375732 
	C1607.027832,2220.662109 1610.371948,2224.552979 1624.221191,2224.957520 
	C1625.383789,2225.549072 1625.880127,2225.958496 1626.419189,2226.300049 
	C1627.545532,2227.013184 1625.925293,2226.463135 1627.197632,2226.861816 
	C1627.014404,2226.716797 1626.800659,2226.620850 1626.115967,2226.120605 
	C1619.787476,2218.628418 1613.718628,2211.717041 1610.604248,2202.746094 
	C1609.331055,2199.078369 1608.210327,2195.088379 1604.229736,2192.141113 
	C1603.237671,2191.502197 1602.858765,2191.214600 1601.985840,2190.407715 
	C1601.209473,2189.503174 1600.927124,2189.117920 1600.291016,2188.110840 
	C1598.632690,2184.426270 1597.282959,2181.386719 1595.836304,2178.385498 
	C1593.727051,2174.009033 1594.466675,2170.628906 1599.489014,2168.947998 
	C1596.846802,2169.794922 1594.308350,2169.790039 1591.450195,2167.533203 
	C1589.939819,2165.913818 1589.352295,2164.606934 1589.293457,2162.410400 
	C1590.275024,2160.205566 1591.582275,2158.645752 1593.187866,2158.843750 
	C1601.377808,2159.853516 1607.954102,2157.219482 1611.933716,2150.429443 
	C1607.291382,2148.906982 1601.689941,2147.187988 1595.221191,2148.103516 
	C1589.058472,2148.975586 1588.263428,2143.765381 1589.008179,2138.745605 
	C1572.279297,2142.264648 1572.279297,2142.264648 1570.572388,2131.394043 
	C1567.195557,2131.185791 1564.564209,2134.020264 1560.482910,2133.701660 
	C1559.333740,2133.459229 1558.890747,2133.294189 1557.894775,2132.686768 
	C1556.875122,2125.376953 1565.545044,2115.174072 1573.996338,2114.065674 
	C1580.258789,2113.244385 1588.082031,2109.490723 1591.708862,2119.053711 
	C1599.699951,2118.691162 1603.951904,2127.610840 1612.647583,2127.252930 
	C1615.859009,2127.808838 1618.423584,2128.094482 1620.878906,2128.952637 
	C1626.233154,2130.823730 1626.906738,2127.439697 1627.152832,2122.764648 
	C1631.307007,2122.004150 1635.281250,2121.548340 1639.051758,2123.560547 
	C1642.545898,2125.425049 1646.255249,2126.802490 1650.145508,2127.127441 
	C1651.822632,2125.888672 1651.038940,2124.638184 1650.350220,2123.562256 
	C1649.093384,2121.597900 1647.092651,2119.405029 1649.186035,2117.360840 
	C1651.555054,2115.047119 1654.812866,2115.605469 1657.554688,2117.111572 
	C1658.951050,2117.878662 1660.190430,2119.197998 1661.095337,2120.541016 
	C1663.463623,2124.055664 1665.009399,2127.864258 1669.249756,2121.595947 
	C1671.031128,2118.962646 1673.427612,2122.348389 1673.686523,2124.575195 
	C1674.186768,2128.877930 1674.953613,2132.977539 1677.091675,2136.649658 
	C1680.484253,2136.503418 1679.488770,2133.730469 1680.438599,2132.265625 
	C1680.754761,2131.777832 1681.401367,2131.504395 1681.896973,2131.133057 
	C1682.791138,2135.952881 1688.892944,2132.903320 1690.377686,2136.701416 
	C1692.122314,2141.164307 1692.590942,2146.321289 1699.000610,2147.368164 
	C1700.781738,2147.659180 1702.751465,2150.320312 1703.568848,2152.327881 
	C1706.047485,2158.416748 1712.059937,2160.460205 1716.780396,2163.393311 
	C1720.056030,2165.428711 1723.054321,2166.763428 1723.893799,2170.658203 
	C1724.851196,2175.099609 1728.573853,2176.895264 1732.189453,2179.871094 
z"
        />
        <path
          fill="#765242"
          opacity="1.000000"
          stroke="none"
          d="
M1766.075195,2351.175781 
	C1764.318970,2355.369141 1761.303345,2357.005859 1757.944824,2355.572998 
	C1752.554565,2353.273193 1748.857544,2356.299561 1747.649048,2360.041504 
	C1745.383911,2367.055908 1739.721558,2369.045166 1734.305176,2371.875977 
	C1732.038452,2373.060791 1729.334229,2373.731201 1728.081299,2377.141357 
	C1722.945435,2381.598389 1717.404907,2378.713867 1711.337280,2379.046387 
	C1706.421265,2379.138672 1702.263428,2378.449463 1697.463135,2379.756348 
	C1694.802490,2380.471680 1692.840820,2380.502197 1690.180420,2380.094727 
	C1687.413086,2379.264404 1685.482422,2377.923340 1682.417358,2378.628906 
	C1675.159668,2379.278320 1668.318726,2381.135742 1663.079956,2374.086426 
	C1664.359131,2367.969482 1668.610840,2367.367676 1673.064087,2367.199707 
	C1678.683105,2366.987793 1684.297241,2367.112549 1690.678223,2367.618652 
	C1693.539307,2367.231934 1695.611816,2367.342041 1698.419189,2367.607178 
	C1709.616211,2369.506104 1717.654053,2365.443115 1723.420654,2355.428467 
	C1727.438232,2348.451172 1725.840942,2342.499268 1717.102539,2333.269287 
	C1703.285034,2317.224609 1695.825928,2298.653076 1689.243286,2279.005859 
	C1691.970093,2275.184326 1695.422852,2275.088379 1699.782471,2274.985596 
	C1706.160767,2274.294434 1711.619385,2273.973633 1717.405273,2277.257080 
	C1720.899780,2282.170898 1726.541504,2282.068848 1729.974121,2286.264404 
	C1733.653076,2290.760742 1738.770752,2293.890381 1739.414062,2300.460449 
	C1739.555420,2301.903809 1740.811401,2303.960205 1742.059204,2304.451660 
	C1748.602905,2307.028076 1751.762939,2313.189209 1756.503906,2317.619385 
	C1766.047852,2326.538086 1770.337646,2337.274170 1766.075195,2351.175781 
z"
        />
        <path
          fill="#4A3831"
          opacity="1.000000"
          stroke="none"
          d="
M1718.507080,2277.424316 
	C1712.627075,2277.906006 1707.218384,2277.885254 1700.910645,2277.828857 
	C1696.661133,2274.636719 1691.861206,2274.016113 1687.926270,2270.597656 
	C1688.744629,2262.587402 1694.006958,2259.243652 1700.708618,2257.914062 
	C1705.449951,2256.972900 1710.379150,2256.960205 1715.362427,2254.248535 
	C1719.459229,2251.824951 1721.839600,2247.078613 1727.638672,2249.568115 
	C1732.109497,2249.381836 1734.313965,2252.092285 1736.471558,2254.526367 
	C1741.350464,2260.030273 1740.545288,2262.832275 1733.429199,2263.929688 
	C1728.511597,2264.687988 1725.424438,2267.302734 1724.267578,2271.667480 
	C1723.432617,2274.817871 1721.311401,2275.758545 1718.507080,2277.424316 
z"
        />
        <path
          fill="#1B1E22"
          opacity="1.000000"
          stroke="none"
          d="
M1728.143066,2249.357178 
	C1725.623291,2250.067871 1723.040161,2250.047363 1721.982300,2253.132568 
	C1721.266724,2255.219971 1719.601074,2255.916992 1716.832153,2256.053223 
	C1711.304688,2257.146973 1707.160889,2255.289551 1702.592529,2252.598633 
	C1702.323120,2250.665527 1702.506592,2249.148438 1703.879395,2248.634766 
	C1709.805786,2246.416016 1715.747437,2244.409180 1717.625854,2237.166992 
	C1717.819824,2236.418457 1720.599854,2236.340576 1722.924316,2235.898926 
	C1724.306885,2236.895264 1724.944824,2237.944336 1725.847778,2239.543457 
	C1726.897461,2241.629150 1725.859619,2243.580566 1727.634521,2245.483398 
	C1728.058594,2246.884521 1728.077393,2247.784180 1728.143066,2249.357178 
z"
        />
        <path
          fill="#4A3831"
          opacity="1.000000"
          stroke="none"
          d="
M1728.010132,2245.989258 
	C1724.588135,2245.737305 1723.948853,2244.037842 1725.835327,2240.635742 
	C1726.706665,2241.008545 1727.300659,2241.922852 1727.935059,2243.409180 
	C1728.362793,2244.325439 1728.750000,2244.669678 1728.558228,2245.532715 
	C1727.979248,2246.051025 1727.980591,2245.993408 1728.010132,2245.989258 
z"
        />
        <path
          fill="#1B1E22"
          opacity="1.000000"
          stroke="none"
          d="
M1728.446045,2246.017090 
	C1727.985596,2245.706299 1727.991943,2245.361328 1727.987061,2244.498535 
	C1728.766357,2244.328613 1729.557129,2244.676514 1730.347900,2245.024414 
	C1729.869629,2245.343994 1729.391235,2245.663818 1728.446045,2246.017090 
z"
        />
        <path
          fill="#1B1E22"
          opacity="1.000000"
          stroke="none"
          d="
M1729.988892,2234.008057 
	C1730.203369,2234.289307 1730.428955,2234.562744 1730.654541,2234.835938 
	C1730.493042,2234.734131 1730.331421,2234.632080 1730.094238,2234.253418 
	C1730.018677,2233.976807 1730.000000,2234.000000 1729.988892,2234.008057 
z"
        />
        <path
          fill="#637E95"
          opacity="1.000000"
          stroke="none"
          d="
M1879.335938,2345.791016 
	C1876.779541,2344.331787 1876.704590,2342.963867 1879.014893,2342.384277 
	C1883.073120,2341.366211 1886.602539,2342.859375 1890.380005,2345.217041 
	C1886.594238,2346.538330 1883.295776,2345.813721 1879.335938,2345.791016 
z"
        />
        <path
          fill="#637E95"
          opacity="1.000000"
          stroke="none"
          d="
M1789.323730,2281.976807 
	C1790.132324,2282.596680 1790.374268,2283.207764 1790.405640,2283.829834 
	C1790.503296,2285.767822 1794.986938,2287.215576 1791.731201,2289.168945 
	C1788.585083,2291.056396 1786.351318,2289.119873 1786.080688,2285.408447 
	C1786.034424,2284.775391 1786.033813,2284.139404 1786.010742,2282.742676 
	C1786.893066,2281.978516 1787.776978,2281.975830 1789.323730,2281.976807 
z"
        />
        <path
          fill="#69849B"
          opacity="1.000000"
          stroke="none"
          d="
M2214.197266,2269.228271 
	C2211.868164,2269.978027 2209.726562,2269.983643 2206.781738,2269.956055 
	C2206.578857,2265.202148 2203.238281,2261.684326 2202.083496,2256.750000 
	C2209.725098,2253.700684 2217.388428,2253.343262 2225.396973,2255.269531 
	C2230.115967,2256.404541 2235.248779,2255.817627 2241.104492,2256.017090 
	C2243.195068,2263.746582 2243.194824,2263.738525 2236.043457,2264.001953 
	C2228.573242,2264.276855 2221.137939,2264.736572 2214.197266,2269.228271 
z"
        />
        <path
          fill="#69849B"
          opacity="1.000000"
          stroke="none"
          d="
M2234.445801,2276.497803 
	C2233.968018,2274.535889 2233.936279,2273.070801 2233.839600,2270.879639 
	C2237.064209,2270.273193 2239.947998,2271.574219 2243.271729,2273.400879 
	C2241.293701,2276.943848 2238.857422,2278.555420 2234.445801,2276.497803 
z"
        />
        <path
          fill="#2C2520"
          opacity="1.000000"
          stroke="none"
          d="
M1709.439819,2376.719971 
	C1715.504028,2375.711670 1721.062500,2375.359375 1727.149902,2377.719238 
	C1736.442017,2382.805908 1743.944092,2380.203613 1750.318848,2374.059326 
	C1753.208740,2371.273926 1755.851196,2369.701416 1759.809204,2369.975342 
	C1763.273682,2370.215088 1766.400269,2369.592529 1767.825806,2365.657959 
	C1771.319824,2356.014160 1778.661987,2352.102051 1789.335693,2352.163574 
	C1789.310913,2356.215088 1787.688721,2359.901611 1784.389526,2362.278076 
	C1778.018677,2366.867676 1774.234619,2373.925781 1768.030273,2379.447754 
	C1764.653687,2382.593506 1760.411255,2381.210449 1756.490479,2383.481689 
	C1753.765015,2386.882812 1750.195068,2386.557617 1746.393555,2387.810791 
	C1744.455688,2388.124756 1743.329712,2388.148682 1741.351318,2387.920654 
	C1734.015747,2386.174072 1727.410278,2387.223633 1720.866699,2386.899902 
	C1716.968872,2386.707031 1712.893066,2387.292236 1709.179688,2383.497803 
	C1708.027954,2380.952393 1708.109131,2379.194580 1709.439819,2376.719971 
z"
        />
        <path
          fill="#738EA6"
          opacity="1.000000"
          stroke="none"
          d="
M2315.687988,2219.828857 
	C2313.053711,2218.580078 2312.642578,2216.629395 2314.935547,2216.491455 
	C2323.762695,2215.960205 2332.351318,2212.965576 2341.313721,2213.994629 
	C2344.138916,2214.318848 2348.059814,2213.552246 2347.318848,2218.173340 
	C2346.795654,2221.435791 2344.057861,2221.358154 2341.070068,2220.749512 
	C2332.950928,2219.094238 2324.673584,2220.385498 2315.687988,2219.828857 
z"
        />
        <path
          fill="#69849B"
          opacity="1.000000"
          stroke="none"
          d="
M2237.405518,2216.331543 
	C2240.775391,2215.998291 2243.550537,2215.996582 2247.158203,2215.992676 
	C2253.829346,2215.714355 2258.893311,2217.336426 2263.076416,2221.685547 
	C2261.025146,2224.999023 2258.100098,2223.649414 2255.670410,2223.978271 
	C2249.268066,2224.844727 2243.294434,2222.104248 2237.022949,2221.559082 
	C2233.243896,2221.230225 2234.897949,2218.445312 2237.405518,2216.331543 
z"
        />
        <path
          fill="#738EA6"
          opacity="1.000000"
          stroke="none"
          d="
M2364.283691,2205.458496 
	C2360.633789,2205.693359 2357.596191,2206.125244 2354.796143,2205.497559 
	C2351.725342,2204.809082 2351.826660,2202.208252 2353.498779,2200.145020 
	C2356.537109,2196.396240 2364.819336,2197.613281 2366.891113,2202.102295 
	C2367.526367,2203.479492 2366.005127,2204.370850 2364.283691,2205.458496 
z"
        />
        <path
          fill="#69849B"
          opacity="1.000000"
          stroke="none"
          d="
M2260.062988,2212.622803 
	C2261.500000,2212.000732 2263.000000,2212.001465 2265.250000,2212.005859 
	C2265.782471,2213.847900 2265.690918,2215.986572 2263.569092,2216.527588 
	C2261.090820,2217.159668 2260.670410,2214.992432 2260.062988,2212.622803 
z"
        />
        <path
          fill="#738EA6"
          opacity="1.000000"
          stroke="none"
          d="
M2382.857422,2201.286621 
	C2383.274902,2202.775146 2382.752197,2203.311768 2381.598145,2203.081299 
	C2381.357910,2203.033447 2381.034912,2202.694092 2380.998779,2202.451904 
	C2380.824951,2201.284424 2381.393799,2200.797119 2382.857422,2201.286621 
z"
        />
        <path
          fill="#69849B"
          opacity="1.000000"
          stroke="none"
          d="
M2390.689453,2274.220947 
	C2387.865723,2276.680420 2384.913574,2276.099121 2382.078125,2275.552246 
	C2380.515137,2275.250977 2378.926758,2274.242188 2379.148682,2272.257080 
	C2379.397705,2270.028076 2381.374268,2269.994629 2382.875977,2270.264160 
	C2385.713135,2270.772949 2388.621338,2271.344482 2390.689453,2274.220947 
z"
        />
        <path
          fill="#778FA4"
          opacity="1.000000"
          stroke="none"
          d="
M1359.437500,2190.284912 
	C1353.004517,2188.839111 1350.404785,2191.629639 1348.413940,2196.723877 
	C1346.762573,2200.950195 1345.381836,2204.644043 1349.062500,2207.865479 
	C1347.748169,2209.805908 1346.419067,2210.052002 1345.125000,2209.896729 
	C1340.094971,2209.293457 1334.683350,2206.711426 1331.160034,2213.011475 
	C1330.917114,2213.445801 1329.972168,2213.655518 1329.343750,2213.677002 
	C1326.504272,2213.774170 1322.571777,2215.331055 1321.390991,2212.044434 
	C1320.344727,2209.131592 1321.456543,2205.364014 1325.426880,2204.138184 
	C1329.767456,2202.797852 1333.241821,2199.286865 1338.219238,2199.388672 
	C1339.697510,2199.418945 1341.239624,2197.930908 1340.724121,2195.860840 
	C1340.321289,2194.243652 1338.789673,2194.069580 1337.537109,2194.107910 
	C1327.610840,2194.410889 1317.612427,2192.828125 1307.763672,2195.102295 
	C1302.929932,2196.218750 1298.172852,2196.586426 1293.362061,2195.030518 
	C1293.442871,2191.338379 1296.071655,2190.921631 1298.130249,2190.437744 
	C1300.352783,2189.915039 1302.751953,2190.191162 1305.059814,2189.970459 
	C1307.511475,2189.736328 1311.130127,2190.786865 1311.213745,2186.898193 
	C1311.282959,2183.682617 1308.050659,2184.257324 1305.843872,2184.012939 
	C1304.253784,2183.836914 1302.516357,2184.100830 1301.455688,2182.343994 
	C1301.870728,2179.416504 1304.293701,2180.288574 1306.063843,2180.039795 
	C1312.796387,2179.093750 1319.044067,2182.662109 1325.761353,2182.183594 
	C1327.998413,2182.024414 1329.416138,2183.483154 1329.913330,2185.699951 
	C1330.376221,2187.764160 1331.283813,2189.255859 1333.600830,2189.830566 
	C1338.392456,2191.018311 1340.421143,2189.451172 1341.939819,2183.028076 
	C1349.577393,2187.893066 1355.577393,2187.081543 1363.372803,2180.117920 
	C1364.021362,2180.019043 1364.396362,2180.322998 1364.583862,2180.475098 
	C1366.127808,2185.215820 1364.117188,2188.197021 1359.437500,2190.284912 
z"
        />
        <path
          fill="#698196"
          opacity="1.000000"
          stroke="none"
          d="
M1154.425293,2262.502930 
	C1153.966553,2260.449463 1153.982910,2258.966553 1153.999512,2256.741699 
	C1156.198975,2251.446533 1160.381226,2252.004639 1164.335938,2252.001465 
	C1176.916870,2251.991699 1189.497803,2252.026123 1202.078491,2251.971191 
	C1206.975830,2251.949707 1212.033691,2252.811035 1216.339111,2249.226074 
	C1217.194092,2248.514404 1218.890869,2248.348877 1220.065308,2248.588379 
	C1222.165161,2249.016846 1223.526611,2250.515625 1223.452637,2252.812988 
	C1223.388672,2254.804443 1221.937500,2256.000977 1220.214233,2255.770752 
	C1209.393433,2254.326660 1198.803833,2259.229004 1187.842407,2256.524658 
	C1182.576660,2255.225586 1176.873779,2255.407959 1171.365479,2256.738037 
	C1171.300903,2260.789062 1175.793335,2261.337646 1175.934326,2265.241455 
	C1173.251221,2265.944580 1170.457397,2265.970459 1166.826416,2265.993164 
	C1162.961304,2262.477295 1158.253540,2265.350342 1154.425293,2262.502930 
z"
        />
        <path
          fill="#3D494C"
          opacity="1.000000"
          stroke="none"
          d="
M1358.545044,2190.999756 
	C1361.076904,2187.817383 1362.910400,2184.962891 1364.535156,2181.221191 
	C1367.532959,2178.640869 1369.164551,2175.326904 1373.137207,2173.871338 
	C1373.831177,2178.534424 1373.905151,2183.361084 1373.977051,2189.085449 
	C1374.919922,2192.261230 1371.741699,2194.073730 1373.156250,2197.052246 
	C1372.550049,2199.406006 1371.153809,2200.569580 1369.894653,2202.593750 
	C1368.825806,2203.716797 1368.077026,2204.166748 1366.631592,2204.133789 
	C1365.373901,2200.140625 1363.582031,2199.597900 1361.115601,2202.322266 
	C1359.700562,2203.885254 1360.111694,2206.650391 1357.269775,2208.048340 
	C1354.745728,2202.496582 1355.684814,2196.999756 1358.545044,2190.999756 
z"
        />
        <path
          fill="#698196"
          opacity="1.000000"
          stroke="none"
          d="
M1008.701660,2241.953125 
	C1008.198425,2238.026611 1006.628174,2235.847900 1002.424866,2236.007812 
	C1000.181702,2236.093018 997.931213,2235.987061 994.822205,2235.937988 
	C994.301086,2233.000000 995.615234,2230.611084 998.671814,2230.300537 
	C1006.518188,2229.503662 1014.390991,2228.921875 1022.266785,2228.517578 
	C1023.252441,2228.467041 1024.319580,2230.002441 1025.677246,2231.391846 
	C1024.364014,2232.001465 1022.715393,2231.935303 1021.082581,2232.066162 
	C1019.200867,2232.216553 1016.649048,2232.070557 1017.185547,2234.976074 
	C1017.588257,2237.156494 1019.746826,2237.812744 1021.827393,2237.823242 
	C1025.449951,2237.841553 1028.685669,2235.452637 1033.235107,2236.079102 
	C1031.931519,2242.556641 1029.241089,2244.405273 1022.828979,2242.368652 
	C1018.249084,2240.914307 1013.959839,2240.689697 1008.701660,2241.953125 
z"
        />
        <path
          fill="#698196"
          opacity="1.000000"
          stroke="none"
          d="
M894.014404,2245.168457 
	C886.710205,2246.250000 882.642334,2239.928467 876.726990,2237.281250 
	C873.251709,2235.726074 874.484863,2232.359863 877.186462,2229.983398 
	C879.704163,2227.768311 882.715820,2227.783447 885.669128,2228.130615 
	C888.606201,2228.476318 891.483765,2229.328125 895.195312,2229.983887 
	C896.817200,2234.997070 893.671082,2239.434814 894.014404,2245.168457 
z"
        />
        <path
          fill="#698196"
          opacity="1.000000"
          stroke="none"
          d="
M906.722778,2255.948730 
	C906.524658,2252.262695 904.074768,2249.139160 903.992615,2244.808594 
	C909.410583,2243.291260 914.305420,2246.296143 919.670044,2246.355225 
	C921.698425,2246.377686 923.182251,2248.146729 924.544495,2249.611572 
	C925.625854,2250.774414 927.235901,2251.867676 926.341675,2253.810059 
	C925.543945,2255.542725 923.807617,2255.895508 922.132446,2255.913818 
	C917.251526,2255.967773 912.367981,2253.739258 906.722778,2255.948730 
z"
        />
        <path
          fill="#61798F"
          opacity="1.000000"
          stroke="none"
          d="
M934.377930,2224.023926 
	C940.078064,2223.880859 944.807190,2224.281738 950.151978,2228.525879 
	C943.604675,2228.888672 938.269714,2227.282227 933.259766,2229.617676 
	C931.623535,2230.380371 928.983154,2229.955566 928.762878,2227.344482 
	C928.476013,2223.943848 931.364380,2224.306152 934.377930,2224.023926 
z"
        />
        <path
          fill="#6A8297"
          opacity="1.000000"
          stroke="none"
          d="
M911.284302,2173.959229 
	C913.953857,2171.508789 916.429077,2171.506592 919.552917,2174.029297 
	C916.365784,2176.479004 913.894958,2176.535645 911.284302,2173.959229 
z"
        />
        <path
          fill="#61798F"
          opacity="1.000000"
          stroke="none"
          d="
M922.712219,2226.039551 
	C920.047241,2228.477783 917.576294,2228.476807 914.450439,2225.969971 
	C917.639160,2223.538330 920.107056,2223.484863 922.712219,2226.039551 
z"
        />
        <path
          fill="#7B95AB"
          opacity="1.000000"
          stroke="none"
          d="
M989.580383,2162.478271 
	C991.376038,2163.695801 991.227905,2164.726562 989.823486,2165.425293 
	C988.940063,2165.865234 987.798462,2165.719482 987.354126,2164.780029 
	C986.635132,2163.260254 987.631165,2162.599854 989.580383,2162.478271 
z"
        />
        <path
          fill="#3D494C"
          opacity="1.000000"
          stroke="none"
          d="
M1352.048950,2216.028320 
	C1351.304443,2215.408203 1349.402222,2216.233154 1349.654785,2214.235352 
	C1349.684326,2214.001465 1350.019653,2213.686523 1350.269287,2213.620117 
	C1351.619263,2213.262695 1351.651367,2214.313477 1351.947510,2215.636230 
	C1352.050415,2216.099121 1352.000977,2216.000000 1352.048950,2216.028320 
z"
        />
        <path
          fill="#778FA4"
          opacity="1.000000"
          stroke="none"
          d="
M1214.932373,2191.042725 
	C1214.887695,2190.959717 1214.976929,2191.125732 1214.932373,2191.042725 
z"
        />
        <path
          fill="#70889D"
          opacity="1.000000"
          stroke="none"
          d="
M1059.125244,2098.575684 
	C1063.393066,2094.506836 1066.771729,2096.591553 1068.418701,2100.022461 
	C1070.600952,2104.568848 1073.302124,2106.597168 1078.351685,2106.068604 
	C1082.530396,2105.631348 1086.558105,2106.627686 1089.038818,2101.177002 
	C1090.928223,2097.025879 1098.092896,2096.210693 1102.169800,2099.764404 
	C1108.677612,2105.436768 1115.507568,2104.610840 1122.718506,2102.727783 
	C1126.666992,2101.696533 1130.633423,2102.100098 1134.601807,2102.052734 
	C1138.330933,2102.008301 1139.899170,2103.931885 1139.970581,2107.608154 
	C1140.047729,2111.580322 1138.757568,2114.020752 1134.377075,2113.975830 
	C1130.724243,2113.938232 1127.070068,2113.969482 1123.417236,2114.016113 
	C1119.172241,2114.070068 1114.400146,2113.114746 1113.453125,2119.409180 
	C1113.233887,2120.865967 1111.837524,2121.718750 1110.517944,2121.672119 
	C1103.181519,2121.413818 1096.102539,2123.471680 1088.855225,2123.892334 
	C1087.262085,2123.985107 1085.270508,2123.806152 1085.193848,2121.565918 
	C1085.127075,2119.615967 1086.766602,2118.632324 1088.395020,2118.373291 
	C1090.672852,2118.010986 1093.022583,2118.100342 1095.341797,2118.002197 
	C1097.330444,2117.917725 1099.390869,2118.143555 1101.291748,2117.692871 
	C1104.320801,2116.975098 1107.292114,2115.251709 1107.010132,2111.836670 
	C1106.681641,2107.859619 1103.052856,2108.077881 1100.046753,2108.114258 
	C1099.068848,2108.125977 1097.852051,2108.124023 1097.156006,2108.661377 
	C1089.977661,2114.203613 1081.740112,2111.064941 1073.963989,2111.596924 
	C1073.007690,2111.662109 1071.635010,2111.748779 1071.121338,2111.201172 
	C1067.187622,2107.007080 1060.784058,2105.334229 1059.125244,2098.575684 
z"
        />
        <path
          fill="#313E41"
          opacity="1.000000"
          stroke="none"
          d="
M1397.872559,2150.825684 
	C1398.982788,2150.136230 1399.802856,2150.059082 1401.236206,2149.912842 
	C1402.463501,2152.051270 1403.263550,2154.226318 1403.656250,2156.472168 
	C1404.825928,2163.160645 1403.931274,2164.680176 1397.550415,2167.771973 
	C1396.097412,2168.476074 1394.160645,2168.932617 1393.370239,2170.118896 
	C1390.489380,2174.441895 1387.770630,2171.554443 1384.355957,2170.052246 
	C1384.400757,2168.320801 1384.654541,2166.051514 1385.759888,2165.505371 
	C1391.836060,2162.502930 1393.706787,2156.156982 1397.872559,2150.825684 
z"
        />
        <path
          fill="#70889D"
          opacity="1.000000"
          stroke="none"
          d="
M1212.317871,2146.099121 
	C1214.287109,2146.142822 1216.030518,2145.995117 1216.459106,2147.259521 
	C1217.228516,2149.529053 1215.285889,2149.871094 1213.687012,2149.850342 
	C1211.935913,2149.827881 1209.506714,2150.536377 1209.432617,2147.566650 
	C1209.421265,2147.108154 1210.863525,2146.613525 1212.317871,2146.099121 
z"
        />
        <path
          fill="#70889D"
          opacity="1.000000"
          stroke="none"
          d="
M1258.862793,2179.371826 
	C1257.043091,2181.378906 1255.323853,2182.586182 1253.435547,2180.820068 
	C1252.272217,2179.732178 1252.889282,2178.286621 1254.063232,2177.484375 
	C1256.144897,2176.061768 1258.313232,2175.016846 1258.862793,2179.371826 
z"
        />
        <path
          fill="#70889D"
          opacity="1.000000"
          stroke="none"
          d="
M985.995117,2105.990234 
	C986.626465,2105.988770 987.276062,2105.917236 987.896362,2106.020752 
	C989.613525,2106.308105 991.892456,2105.372314 992.591675,2107.952148 
	C992.698303,2108.345703 992.167664,2109.251953 991.754028,2109.390625 
	C989.427856,2110.169678 987.345276,2110.010498 986.125488,2106.660156 
	C985.980469,2105.990234 986.000000,2106.000000 985.995117,2105.990234 
z"
        />
        <path
          fill="#8497A7"
          opacity="1.000000"
          stroke="none"
          d="
M1624.533203,2107.192871 
	C1620.911499,2108.718506 1617.455444,2111.560059 1615.905884,2105.529297 
	C1615.273682,2103.068848 1612.879272,2102.203369 1610.525391,2100.457031 
	C1610.135498,2099.407715 1610.357544,2098.185791 1610.397339,2098.191650 
	C1617.145020,2099.193115 1617.889526,2093.594971 1616.863647,2090.160156 
	C1614.072632,2080.814697 1616.370605,2070.527588 1611.072876,2061.708740 
	C1609.383789,2058.897217 1610.509277,2056.587646 1614.146240,2056.098145 
	C1616.375244,2055.798096 1618.863037,2056.577637 1620.765137,2054.600830 
	C1621.797852,2051.567383 1618.543823,2050.022461 1618.223755,2047.381470 
	C1617.632690,2042.505249 1620.391479,2039.734253 1625.225586,2040.751343 
	C1631.096436,2041.986694 1635.401855,2045.275757 1638.375854,2050.551514 
	C1640.024292,2053.475586 1642.172119,2055.957275 1646.238403,2055.575439 
	C1655.139404,2054.739746 1656.591187,2052.517334 1653.789551,2043.491699 
	C1652.448120,2039.170410 1653.280029,2037.828857 1657.472046,2038.114136 
	C1659.424561,2038.246948 1661.317993,2039.087646 1663.269165,2039.432861 
	C1668.939941,2040.436279 1674.577759,2037.767090 1675.652954,2033.657349 
	C1676.717529,2029.588135 1673.747681,2024.389160 1667.720825,2022.514526 
	C1663.343750,2021.153320 1661.406372,2019.414429 1662.209595,2014.956299 
	C1662.326172,2014.308838 1662.375122,2013.463867 1662.794189,2013.084595 
	C1667.847412,2008.510742 1668.860474,2003.460083 1664.158569,1998.187866 
	C1667.404419,1995.561401 1669.836914,1998.018799 1672.366577,1998.059814 
	C1676.839600,1998.132324 1680.838623,1999.099365 1685.648071,1996.897583 
	C1694.380981,1992.899536 1706.067993,1999.355713 1708.985718,2008.165649 
	C1709.982178,2011.174927 1708.126709,2013.255737 1707.211548,2015.666138 
	C1706.583740,2017.319336 1706.335815,2018.849243 1707.756104,2020.244141 
	C1709.194214,2021.656616 1710.688232,2021.446899 1712.265869,2020.520142 
	C1714.427124,2019.250610 1716.743774,2016.490967 1718.987793,2019.884644 
	C1720.338867,2021.928223 1716.826660,2026.848755 1713.611206,2027.906372 
	C1712.394287,2028.306763 1711.070679,2028.383057 1708.657227,2028.807373 
	C1714.105835,2035.756958 1710.071289,2044.403442 1714.992798,2051.325928 
	C1716.663940,2053.676514 1713.105591,2055.976562 1711.229858,2057.681885 
	C1704.288940,2063.991943 1706.534058,2064.713623 1711.506836,2069.223389 
	C1715.809448,2073.125488 1716.392456,2075.400635 1713.022705,2079.406738 
	C1711.984009,2080.641602 1710.455933,2081.465088 1709.151855,2082.476807 
	C1707.074463,2084.088623 1704.308472,2085.469482 1704.925903,2088.636719 
	C1705.454712,2091.350098 1708.165283,2091.378418 1710.384644,2092.137695 
	C1718.160767,2094.798096 1718.634521,2096.474365 1714.235229,2103.934814 
	C1712.450439,2106.961670 1711.437012,2109.625000 1717.731201,2110.502930 
	C1719.299805,2111.849365 1719.483398,2112.740723 1719.251831,2114.338135 
	C1718.538208,2115.781494 1717.854248,2116.359863 1716.330566,2116.469727 
	C1709.272827,2110.626465 1701.757812,2107.808838 1693.319580,2106.921875 
	C1687.738403,2106.335449 1682.584473,2104.863770 1678.267578,2100.495605 
	C1675.081787,2097.271973 1670.272827,2096.190186 1665.092651,2095.166260 
	C1656.343994,2095.842529 1653.590820,2087.565430 1647.368530,2084.961182 
	C1644.968994,2083.956787 1643.433105,2082.941406 1641.330322,2086.060547 
	C1632.943726,2089.171631 1629.738770,2095.980225 1626.245483,2103.371094 
	C1625.941406,2104.359375 1625.864502,2104.710449 1625.595459,2105.565186 
	C1625.266724,2106.375488 1625.130249,2106.682129 1624.533203,2107.192871 
z"
        />
        <path
          fill="#69849B"
          opacity="1.000000"
          stroke="none"
          d="
M1715.992920,2116.843262 
	C1716.303955,2115.832031 1716.923340,2115.425781 1717.850586,2114.554688 
	C1718.834961,2113.088623 1720.610352,2112.301025 1718.375977,2110.444824 
	C1717.958008,2110.073486 1717.966675,2110.044434 1717.944336,2110.044922 
	C1718.248901,2106.357178 1720.575439,2104.255615 1723.531372,2102.382080 
	C1728.914673,2098.968994 1728.866943,2098.831055 1724.461060,2095.150146 
	C1722.937500,2093.877197 1721.185547,2092.873535 1719.689819,2091.572266 
	C1717.456909,2089.629150 1718.312378,2087.423584 1719.722168,2084.636230 
	C1725.769897,2082.853027 1731.506958,2081.528809 1737.308228,2080.611572 
	C1742.358643,2079.812988 1744.717407,2082.935059 1742.849365,2087.681885 
	C1742.257812,2089.185059 1740.857056,2090.430664 1740.501831,2091.952148 
	C1739.894775,2094.551270 1739.530029,2097.843750 1743.838867,2096.882080 
	C1747.025024,2096.171143 1749.567505,2098.245850 1753.263672,2097.979492 
	C1758.453003,2097.403076 1762.499268,2099.239746 1766.660400,2100.224121 
	C1770.375488,2101.103027 1770.646973,2103.956055 1768.223389,2106.103027 
	C1765.078125,2108.888916 1761.799194,2112.055664 1757.054565,2111.951904 
	C1752.113403,2111.843994 1748.050415,2113.179443 1744.998535,2117.468994 
	C1741.196533,2122.812744 1737.434448,2117.150635 1732.386353,2116.493408 
	C1734.439453,2121.193848 1736.143066,2125.459229 1731.875977,2129.635742 
	C1726.635254,2125.917969 1719.999634,2123.441162 1715.992920,2116.843262 
z"
        />
        <path
          fill="#131416"
          opacity="1.000000"
          stroke="none"
          d="
M1614.491333,2129.304199 
	C1611.404663,2130.267090 1608.760864,2130.431885 1606.166382,2130.881348 
	C1604.321655,2131.201172 1603.265259,2130.926758 1602.234619,2129.091797 
	C1598.587402,2122.598633 1598.489258,2122.670898 1591.838867,2124.859619 
	C1591.258789,2125.050537 1590.561401,2124.885010 1589.696655,2124.885010 
	C1586.197510,2122.593506 1589.652344,2117.797363 1586.072754,2114.796143 
	C1582.866699,2115.404053 1579.476807,2119.056152 1575.430176,2116.281982 
	C1574.971924,2115.967773 1573.717163,2115.940674 1573.536255,2116.226318 
	C1569.619019,2122.412842 1560.109375,2122.525391 1557.900757,2131.351562 
	C1555.354370,2134.042480 1553.156006,2133.650879 1550.919067,2130.611328 
	C1547.546509,2120.901367 1549.474731,2112.081299 1551.863770,2102.596191 
	C1555.531616,2096.503662 1560.093018,2097.146240 1564.847290,2099.961670 
	C1566.645386,2101.026611 1568.640625,2102.391602 1570.006348,2100.275146 
	C1571.472656,2098.002441 1568.093994,2098.356689 1567.498901,2097.135498 
	C1565.903076,2093.860840 1569.129761,2094.544189 1570.922607,2093.393555 
	C1573.007935,2092.872559 1574.660522,2092.656006 1577.129639,2092.623291 
	C1581.280640,2095.157959 1585.227661,2095.961914 1589.572998,2097.540039 
	C1590.138184,2097.892822 1590.092896,2097.932617 1590.106079,2097.896240 
	C1590.355103,2101.085449 1587.144897,2102.918213 1586.787964,2105.741699 
	C1587.281982,2106.975342 1588.427612,2107.642578 1589.079468,2107.030029 
	C1593.274780,2103.087891 1596.485596,2105.488281 1598.820068,2108.896729 
	C1601.716187,2113.125244 1604.229736,2116.661133 1610.363281,2116.340088 
	C1615.596802,2116.065918 1611.378174,2121.141113 1613.569580,2123.590820 
	C1615.010132,2125.423340 1615.512573,2126.920898 1614.491333,2129.304199 
z"
        />
        <path
          fill="#859CAF"
          opacity="1.000000"
          stroke="none"
          d="
M1701.405640,1971.313599 
	C1704.201172,1971.339600 1706.381714,1970.775146 1709.234619,1970.139526 
	C1710.809692,1975.688110 1718.772461,1976.109985 1719.010498,1982.833496 
	C1714.972046,1984.566895 1710.181763,1985.036499 1706.645142,1988.181152 
	C1704.942261,1989.695190 1703.051147,1989.476196 1701.089844,1988.511353 
	C1694.423584,1985.231689 1687.827881,1984.485718 1681.308472,1989.016602 
	C1679.604980,1990.200195 1677.415039,1990.291138 1675.499390,1989.834473 
	C1668.246460,1988.104980 1660.464600,1989.461182 1652.765137,1986.007935 
	C1648.656006,1981.664062 1650.430786,1977.384399 1652.599365,1972.378174 
	C1654.727905,1969.920044 1656.839478,1968.994629 1659.903931,1968.058716 
	C1671.372925,1967.958984 1681.988647,1969.412354 1692.635254,1967.899414 
	C1695.675293,1967.467285 1698.614136,1968.219116 1701.405640,1971.313599 
z"
        />
        <path
          fill="#7B95AB"
          opacity="1.000000"
          stroke="none"
          d="
M1653.584961,1971.346680 
	C1654.680420,1976.373047 1652.457520,1980.251465 1652.025879,1985.204346 
	C1638.297119,1986.072388 1624.409912,1987.071899 1610.988647,1984.621704 
	C1606.206055,1983.748535 1599.882446,1979.055176 1600.010254,1970.759033 
	C1601.507080,1970.001465 1603.004395,1969.997925 1605.250488,1969.990356 
	C1611.219360,1966.660767 1616.830322,1969.006592 1622.140015,1969.320801 
	C1627.331543,1969.628052 1632.423096,1969.966797 1638.362183,1969.049194 
	C1643.963379,1968.400391 1648.869141,1966.942993 1653.584961,1971.346680 
z"
        />
        <path
          fill="#7B95AB"
          opacity="1.000000"
          stroke="none"
          d="
M1260.512939,1982.381592 
	C1260.229492,1979.549927 1261.751221,1976.006226 1263.452515,1976.957886 
	C1270.345703,1980.813599 1279.229614,1976.596191 1285.649414,1983.406738 
	C1281.588867,1991.167969 1273.862549,1986.948853 1267.899780,1989.030884 
	C1264.842041,1990.098633 1263.006104,1985.371338 1260.512939,1982.381592 
z"
        />
        <path
          fill="#7B95AB"
          opacity="1.000000"
          stroke="none"
          d="
M1300.130371,1986.692505 
	C1304.052979,1981.849121 1308.362061,1978.528198 1315.294922,1981.919678 
	C1312.821411,1988.193726 1307.674316,1989.940063 1300.130371,1986.692505 
z"
        />
        <path
          fill="#1B1E22"
          opacity="1.000000"
          stroke="none"
          d="
M1589.802612,2097.291260 
	C1585.723022,2098.149658 1581.418823,2098.062744 1578.159058,2093.447510 
	C1582.372192,2092.362793 1586.059937,2094.110107 1589.802612,2097.291260 
z"
        />
        <path
          fill="#7B95AB"
          opacity="1.000000"
          stroke="none"
          d="
M1392.952148,2010.022949 
	C1392.008057,2011.710449 1390.833740,2011.913452 1389.683838,2011.117920 
	C1388.755859,2010.475708 1388.763672,2009.453491 1389.718140,2008.838379 
	C1390.888428,2008.084229 1392.059326,2008.299805 1392.952148,2010.022949 
z"
        />
        <path
          fill="#728DA3"
          opacity="1.000000"
          stroke="none"
          d="
M1640.242676,1971.323242 
	C1629.053589,1974.069580 1618.331299,1972.385254 1606.823853,1970.015747 
	C1608.001221,1965.276855 1612.564941,1966.500854 1616.200928,1965.484741 
	C1617.758789,1965.049316 1619.260010,1963.998047 1618.833984,1961.938965 
	C1618.502686,1960.338745 1617.034546,1960.062012 1615.759521,1960.109619 
	C1610.868164,1960.291748 1607.136963,1957.023315 1602.702026,1955.870972 
	C1600.720825,1955.356201 1598.823608,1954.366333 1598.912476,1951.999756 
	C1599.022705,1949.059570 1601.377197,1948.340332 1603.770386,1948.125854 
	C1614.547485,1947.160034 1625.254150,1945.083618 1637.079834,1946.000122 
	C1641.947144,1943.197876 1646.172485,1943.703613 1651.225098,1945.464600 
	C1661.446411,1945.718384 1661.446411,1945.718384 1663.984863,1936.774536 
	C1665.975098,1931.421021 1669.729980,1931.252930 1674.597656,1932.332153 
	C1676.640259,1933.599365 1677.375244,1934.924194 1677.954956,1937.128662 
	C1678.101685,1940.416382 1677.870850,1943.116943 1681.442261,1944.800903 
	C1689.485718,1950.989990 1697.287598,1953.099609 1705.850342,1947.296143 
	C1707.281982,1946.325806 1709.009644,1946.389526 1711.191650,1947.452637 
	C1713.383179,1947.958374 1715.046021,1947.953491 1716.682495,1948.159424 
	C1719.173462,1948.473022 1721.110107,1949.634766 1721.553833,1952.319336 
	C1722.003418,1955.039917 1720.362427,1956.646484 1718.208008,1957.813354 
	C1714.183594,1959.993408 1710.192749,1962.252808 1706.060059,1964.209595 
	C1703.300415,1965.516235 1702.076660,1967.507812 1702.032593,1971.163818 
	C1697.838013,1971.095459 1693.868774,1970.805542 1689.400635,1971.439331 
	C1680.242065,1972.738403 1670.818481,1972.798462 1660.857666,1970.062744 
	C1654.797485,1965.925171 1650.643799,1959.692261 1642.175659,1963.273071 
	C1637.323608,1965.324951 1639.034180,1967.756714 1640.242676,1971.323242 
z"
        />
        <path
          fill="#708497"
          opacity="1.000000"
          stroke="none"
          d="
M1677.006104,1933.220215 
	C1672.443359,1934.143188 1668.751953,1933.434570 1664.704590,1935.688477 
	C1648.161011,1932.309937 1632.413574,1930.749146 1615.910278,1932.416748 
	C1606.796509,1933.337769 1599.739990,1939.317017 1590.521118,1938.989502 
	C1580.833862,1938.645386 1570.802979,1941.008789 1563.204224,1932.374146 
	C1561.762329,1930.735474 1559.843872,1931.054321 1557.772827,1930.977661 
	C1547.576294,1930.600464 1537.396484,1929.773804 1526.602661,1928.545532 
	C1525.371094,1921.284790 1529.182373,1917.420654 1535.132935,1917.614258 
	C1546.436035,1917.982300 1557.698120,1915.941895 1568.710327,1916.309937 
	C1580.521362,1916.704590 1592.442749,1916.731567 1604.268066,1917.658691 
	C1608.670898,1918.003906 1612.892090,1917.798462 1617.710693,1916.069702 
	C1622.198364,1916.051147 1626.087769,1916.973633 1630.879028,1915.662354 
	C1632.755615,1916.878174 1634.163208,1918.037842 1635.855713,1917.827271 
	C1646.342163,1916.523071 1655.902344,1921.445068 1666.105835,1922.197510 
	C1672.135864,1922.642334 1676.306519,1926.377441 1677.006104,1933.220215 
z"
        />
        <path
          fill="#73828A"
          opacity="1.000000"
          stroke="none"
          d="
M1617.564209,1915.227539 
	C1616.272217,1918.415161 1613.273315,1919.716553 1609.824951,1919.918335 
	C1596.641113,1920.690308 1583.436279,1920.868896 1570.348999,1918.960693 
	C1567.191528,1918.500244 1564.525024,1919.414307 1561.633057,1919.588745 
	C1554.344604,1920.028076 1546.994141,1920.333374 1539.721802,1919.864990 
	C1533.648682,1919.474121 1529.549316,1921.599854 1526.338501,1927.344971 
	C1523.862915,1927.985229 1521.731079,1927.985107 1518.799683,1927.979370 
	C1515.751709,1924.397949 1510.561279,1927.730347 1508.217285,1923.251221 
	C1511.501343,1916.823120 1517.986450,1920.591309 1523.006958,1917.572266 
	C1524.478882,1916.026855 1525.525879,1915.070923 1527.386475,1914.032227 
	C1537.675903,1915.701660 1547.464844,1915.081055 1557.179565,1915.605469 
	C1559.542236,1915.733154 1561.683472,1914.872314 1563.830566,1912.634888 
	C1570.008301,1911.839233 1576.007324,1910.844604 1581.573120,1915.488037 
	C1589.473267,1912.053589 1596.699341,1909.954834 1604.929688,1914.705200 
	C1607.258057,1915.301270 1608.959717,1915.357788 1611.489014,1915.199097 
	C1613.821045,1915.003052 1615.325195,1915.022217 1617.564209,1915.227539 
z"
        />
        <path
          fill="#708497"
          opacity="1.000000"
          stroke="none"
          d="
M1651.809814,1945.259399 
	C1647.911255,1945.996704 1643.832153,1945.998413 1638.879028,1946.000000 
	C1641.999023,1940.905151 1644.978638,1940.582275 1651.809814,1945.259399 
z"
        />
        <path
          fill="#070709"
          opacity="1.000000"
          stroke="none"
          d="
M1551.280762,2102.088623 
	C1555.185425,2104.978516 1557.097168,2108.199219 1553.008301,2111.997803 
	C1549.782227,2114.994873 1549.336914,2118.092773 1552.351074,2121.538818 
	C1553.763916,2123.154053 1554.212036,2125.024414 1552.537598,2127.492188 
	C1547.111816,2132.808594 1543.390259,2138.471680 1540.325928,2145.390869 
	C1539.474731,2146.166992 1539.097778,2146.439453 1538.067139,2146.966797 
	C1534.177124,2147.777344 1532.113892,2145.911621 1529.760010,2143.188965 
	C1529.038208,2142.274170 1528.760620,2141.899170 1528.096191,2140.939453 
	C1527.233032,2139.547607 1526.809448,2138.713867 1526.216797,2137.185547 
	C1525.673584,2135.231689 1525.552124,2133.929199 1525.744507,2132.607666 
	C1524.863403,2133.029053 1524.802734,2134.010742 1524.329346,2134.709717 
	C1520.224365,2140.771729 1515.535645,2146.827393 1509.283325,2150.157959 
	C1501.450317,2154.330566 1500.394409,2163.428955 1493.482544,2168.431641 
	C1490.362793,2170.689697 1494.565552,2177.463867 1499.028931,2180.092041 
	C1500.836304,2181.156494 1503.641968,2180.470459 1504.925293,2183.632568 
	C1504.921021,2184.595703 1504.821411,2184.949951 1504.233765,2185.916992 
	C1504.194214,2185.923340 1504.817993,2185.700684 1506.024902,2186.054688 
	C1508.313110,2192.705322 1508.356689,2198.802979 1504.785034,2203.068359 
	C1516.927246,2207.356445 1510.938232,2220.602295 1518.025513,2228.128906 
	C1517.522827,2221.040283 1517.459839,2215.938477 1513.072021,2210.153320 
	C1506.574707,2201.587158 1506.548828,2190.094971 1506.282715,2179.544678 
	C1506.149902,2174.271973 1509.338257,2168.325928 1515.103394,2167.491699 
	C1521.141968,2166.618164 1528.599731,2164.245117 1532.866089,2172.054688 
	C1533.629761,2173.452393 1534.256104,2175.106445 1536.401733,2175.398926 
	C1540.535278,2172.623535 1535.405640,2165.328857 1541.388062,2163.852051 
	C1544.724609,2163.028320 1547.614502,2165.609619 1550.300293,2168.750977 
	C1550.789429,2169.811279 1550.909668,2170.265625 1551.002563,2171.419434 
	C1548.588501,2177.666016 1551.222412,2183.708740 1549.198730,2189.368408 
	C1548.326172,2191.808838 1550.623657,2192.588379 1552.612183,2193.002930 
	C1555.749512,2193.656250 1559.155396,2193.691162 1561.408813,2197.483643 
	C1561.754883,2198.539062 1561.843750,2198.972656 1561.982300,2200.057129 
	C1561.961670,2202.807617 1562.005371,2204.862549 1564.635010,2206.515137 
	C1565.783325,2207.514648 1566.386353,2208.147461 1567.355225,2209.348633 
	C1569.803223,2212.765381 1570.449829,2216.455322 1573.503174,2219.496094 
	C1573.984985,2221.453369 1573.992920,2222.946533 1574.000732,2225.186523 
	C1574.870239,2230.789551 1568.601440,2227.679932 1567.765137,2231.622070 
	C1566.971436,2232.397705 1566.619385,2232.676514 1565.650024,2233.239746 
	C1559.377563,2235.678467 1556.549927,2229.507812 1551.204346,2229.285156 
	C1550.231934,2229.743652 1549.844971,2229.943359 1548.854126,2230.411133 
	C1543.863159,2233.401611 1540.369263,2232.901855 1538.361450,2227.084473 
	C1536.237427,2231.197510 1535.854980,2235.018799 1534.424316,2239.139648 
	C1533.965454,2240.429932 1533.738892,2241.150635 1533.741943,2242.405273 
	C1534.125488,2243.228271 1534.344971,2243.537354 1534.957764,2244.292236 
	C1537.485840,2247.458496 1540.292847,2249.956299 1537.969727,2254.517090 
	C1524.860229,2259.824951 1519.763428,2258.513916 1511.507446,2248.103760 
	C1509.275879,2251.490967 1510.033447,2255.043945 1510.222778,2258.544922 
	C1510.366943,2261.209229 1510.558838,2264.301514 1507.906006,2265.701904 
	C1504.591797,2267.451416 1502.338867,2264.568359 1500.263306,2262.614502 
	C1498.076416,2260.555420 1494.418579,2258.842773 1496.895142,2254.606445 
	C1492.862061,2253.990479 1489.840088,2258.432373 1485.021729,2256.566895 
	C1483.994873,2256.043213 1483.606934,2255.792969 1482.708984,2255.085449 
	C1481.909790,2254.282959 1481.620605,2253.937744 1480.973389,2253.028809 
	C1479.589355,2250.581055 1479.197876,2248.401367 1477.560425,2246.105957 
	C1477.012573,2245.196777 1476.807983,2244.823730 1476.338135,2243.870605 
	C1475.464722,2241.710449 1474.988892,2240.077148 1473.818237,2238.035645 
	C1471.818237,2235.078613 1469.919434,2232.865479 1467.958984,2230.450684 
	C1466.074585,2233.528809 1465.500610,2237.447510 1461.126221,2239.225098 
	C1452.546875,2240.118164 1452.546875,2240.118164 1458.606567,2250.725586 
	C1457.524780,2253.876465 1454.585083,2252.847412 1452.588135,2254.877441 
	C1449.254761,2258.512939 1446.121216,2260.606445 1441.379883,2257.130371 
	C1440.501953,2256.495850 1440.150635,2256.242432 1439.303467,2255.572266 
	C1438.176880,2254.576904 1437.551514,2253.993164 1436.437988,2253.006348 
	C1434.630737,2251.579102 1433.200195,2250.716309 1431.402466,2249.346680 
	C1430.064697,2248.537109 1429.880737,2246.767822 1427.950684,2247.915039 
	C1427.179688,2248.634521 1426.848267,2249.054199 1426.561523,2249.508545 
	C1427.684326,2249.702637 1426.931152,2248.356689 1428.152100,2247.891602 
	C1429.090576,2247.639648 1429.509277,2247.700684 1430.352295,2248.259766 
	C1433.846313,2251.508301 1435.595703,2255.080566 1436.792847,2258.904541 
	C1438.451904,2264.204346 1438.754395,2268.874023 1431.330200,2269.856689 
	C1428.344238,2270.251953 1424.528198,2270.937012 1428.058350,2275.313477 
	C1430.168579,2277.929443 1433.538452,2280.900879 1429.846191,2283.564209 
	C1426.514648,2285.967041 1421.253906,2286.642822 1417.800415,2283.639893 
	C1415.096436,2281.288818 1413.875488,2279.811035 1409.879883,2281.666748 
	C1406.431152,2283.268799 1405.793457,2278.980225 1405.968750,2276.580811 
	C1406.331787,2271.606689 1405.587158,2266.688232 1405.607056,2261.090088 
	C1404.835205,2258.255371 1404.242798,2256.079834 1403.763428,2252.683838 
	C1401.363770,2260.421631 1396.736572,2261.548828 1390.046143,2258.556641 
	C1385.380493,2253.501221 1385.909424,2248.666992 1389.084717,2243.720947 
	C1394.593262,2235.140381 1393.360962,2226.282471 1390.025635,2216.680176 
	C1391.450806,2213.859619 1394.008545,2213.875488 1396.084473,2212.971436 
	C1398.778564,2211.798584 1401.711548,2211.276367 1402.148804,2207.383057 
	C1402.484497,2204.394043 1404.602417,2203.646729 1407.213135,2205.237793 
	C1408.518188,2206.032959 1409.837280,2208.068115 1411.187744,2205.881836 
	C1412.510986,2203.739502 1411.994629,2201.217041 1409.994385,2199.610352 
	C1405.756226,2196.205566 1400.616211,2194.610107 1395.363159,2194.114258 
	C1390.867188,2193.689941 1389.814697,2191.346680 1389.953003,2186.731934 
	C1392.544189,2186.313232 1395.146973,2186.722656 1398.401978,2187.234863 
	C1398.167847,2183.961426 1399.378296,2180.949463 1400.373535,2177.880615 
	C1401.044922,2175.811035 1402.548096,2174.246826 1404.675537,2174.275635 
	C1409.786743,2174.344482 1411.865845,2172.083740 1413.656616,2167.154541 
	C1415.172363,2162.982422 1420.968262,2161.219971 1425.142578,2160.481201 
	C1430.209473,2159.584717 1434.748901,2158.836182 1438.526001,2155.054443 
	C1439.999756,2153.578857 1442.213623,2154.023193 1443.983887,2154.328369 
	C1444.729492,2156.229980 1443.659424,2157.099365 1442.901489,2158.142090 
	C1441.451782,2160.137207 1437.980957,2161.325684 1439.645630,2164.662598 
	C1441.903809,2169.189941 1443.954102,2164.023682 1446.152588,2164.325439 
	C1451.692627,2165.085449 1457.284790,2165.617188 1462.739136,2166.785156 
	C1465.485474,2167.373291 1465.722046,2169.946045 1464.952759,2172.496826 
	C1464.514893,2173.948730 1464.967773,2175.160400 1466.639160,2175.417725 
	C1468.158081,2175.651611 1469.542114,2174.847412 1469.539429,2173.426514 
	C1469.532959,2170.107422 1472.101929,2168.583008 1474.062378,2166.621826 
	C1469.293945,2162.312256 1463.530518,2164.946045 1457.789062,2163.429688 
	C1460.673584,2160.877686 1465.276245,2162.030762 1466.966675,2157.735840 
	C1464.160645,2154.598389 1459.684082,2155.729248 1456.177368,2154.715332 
	C1451.644409,2153.405029 1449.740234,2152.143066 1451.821411,2147.400635 
	C1453.543701,2143.475830 1454.774414,2139.370850 1453.187622,2135.289307 
	C1456.646240,2133.430176 1458.169434,2135.325928 1459.592896,2137.159912 
	C1461.010742,2138.986328 1462.551147,2140.067627 1464.423218,2137.999756 
	C1466.303833,2135.922852 1466.422485,2133.826172 1463.578247,2132.329346 
	C1462.700073,2131.867188 1461.787231,2131.471191 1460.451050,2130.531250 
	C1460.726807,2129.338867 1461.575562,2129.037354 1463.166382,2129.480713 
	C1466.332153,2130.060547 1468.368286,2128.951172 1469.790405,2126.992432 
	C1473.469116,2121.925293 1476.542725,2116.403809 1483.760010,2115.335449 
	C1486.134644,2114.984131 1485.361816,2112.353760 1485.139038,2110.316895 
	C1484.327881,2102.898682 1485.335571,2101.665039 1492.043823,2103.114990 
	C1496.822266,2104.147949 1499.406128,2104.512207 1499.114258,2098.059814 
	C1498.980835,2095.110840 1503.122070,2094.221924 1506.230347,2094.233887 
	C1508.102539,2094.240967 1509.926270,2095.382324 1509.646851,2097.080078 
	C1508.078857,2106.605225 1515.738037,2103.756592 1519.666870,2105.711914 
	C1521.523926,2103.891357 1520.704224,2102.981689 1519.703979,2101.960205 
	C1518.249390,2100.474854 1515.000000,2099.055664 1518.131958,2096.684082 
	C1519.802612,2095.419189 1521.928223,2096.951416 1523.132690,2098.716064 
	C1524.063477,2100.079834 1524.809448,2101.570801 1525.622437,2103.013672 
	C1526.473267,2104.524658 1528.092773,2106.459717 1529.349121,2105.676025 
	C1535.609253,2101.770264 1538.174316,2106.358643 1541.523071,2110.134033 
	C1543.508179,2112.372070 1545.703613,2111.378906 1547.173462,2108.956543 
	C1548.477173,2106.807861 1547.321655,2103.400391 1551.280762,2102.088623 
z"
        />
        <path
          fill="#1A1615"
          opacity="1.000000"
          stroke="none"
          d="
M1451.538818,2254.520752 
	C1451.603027,2252.574463 1449.688354,2251.138184 1451.078857,2249.729736 
	C1452.204102,2248.590088 1453.228271,2250.487793 1454.338989,2250.932617 
	C1455.234863,2251.291260 1456.167358,2251.558350 1457.720215,2251.903076 
	C1462.660645,2248.368652 1466.361328,2253.106689 1471.252563,2252.897705 
	C1467.125977,2247.717773 1452.490723,2252.176025 1459.318848,2238.557617 
	C1465.977417,2234.705322 1464.794067,2228.432861 1465.559448,2220.865479 
	C1469.717285,2227.090088 1472.844604,2231.771973 1475.997681,2237.194824 
	C1476.038940,2239.717773 1475.953369,2241.521484 1477.597412,2243.524658 
	C1478.076294,2243.991699 1477.992432,2244.011475 1478.003540,2243.969727 
	C1479.864990,2245.950439 1479.564941,2248.908691 1481.589478,2251.514160 
	C1482.060913,2251.968262 1482.023804,2252.011963 1482.018799,2251.984863 
	C1482.463379,2252.476807 1482.952271,2252.955566 1483.888184,2253.707520 
	C1485.530151,2254.435059 1487.724365,2255.412842 1487.859375,2255.182617 
	C1492.215698,2247.755859 1497.090576,2252.938477 1501.825073,2254.492920 
	C1501.590088,2256.785645 1499.202759,2255.463867 1497.762329,2257.272217 
	C1502.084351,2257.978760 1502.437622,2262.915039 1506.669922,2264.506836 
	C1509.961304,2260.374512 1506.518677,2256.497803 1506.141235,2252.641357 
	C1505.708252,2248.218262 1512.878662,2246.257324 1508.836670,2240.116455 
	C1514.730835,2253.473389 1523.783325,2257.497559 1537.730957,2254.501465 
	C1539.985840,2258.140869 1538.933350,2260.251465 1535.739258,2261.019531 
	C1528.957275,2262.651367 1530.489746,2265.604248 1534.537842,2268.750000 
	C1537.771118,2271.262451 1537.233032,2275.269531 1539.014771,2279.340576 
	C1545.776978,2275.669678 1545.244263,2269.791260 1544.683350,2263.316162 
	C1544.298218,2261.456055 1544.039551,2260.231934 1543.245605,2258.532227 
	C1542.742798,2257.628662 1542.529053,2257.270752 1541.995605,2256.360840 
	C1541.485962,2255.419678 1541.296265,2255.030029 1540.912109,2254.006836 
	C1540.679565,2252.926270 1540.641479,2252.479004 1540.689453,2251.356445 
	C1542.181641,2242.904541 1550.197876,2237.656006 1549.546631,2228.395996 
	C1550.963623,2226.198975 1551.523193,2224.227051 1554.052734,2222.834961 
	C1556.678833,2226.628662 1557.869263,2232.682373 1565.181152,2231.932617 
	C1568.511963,2236.525635 1566.404297,2241.223877 1565.303223,2245.394043 
	C1563.522705,2252.136963 1557.789795,2245.995850 1554.142212,2248.041260 
	C1555.137817,2250.861572 1558.575317,2251.252441 1560.600464,2253.268066 
	C1566.409180,2259.048340 1565.608521,2266.744385 1558.300537,2270.227051 
	C1554.293457,2272.136475 1554.547852,2275.181885 1554.331421,2278.473145 
	C1553.892090,2285.149170 1553.295654,2291.794189 1548.427734,2297.869141 
	C1547.673462,2298.769531 1547.358765,2299.120117 1546.536499,2299.963379 
	C1543.379150,2303.330322 1540.802856,2306.482666 1535.884277,2302.582520 
	C1536.907837,2292.724365 1532.847290,2288.339111 1523.067993,2289.070068 
	C1533.682739,2288.406250 1531.027222,2299.467041 1536.804199,2304.076172 
	C1539.335327,2314.105713 1534.273315,2319.430908 1525.745605,2323.107910 
	C1522.774536,2322.163330 1522.308350,2320.348389 1522.451904,2317.734863 
	C1519.703735,2319.848389 1520.183350,2324.640869 1516.136230,2324.840332 
	C1511.721924,2325.057861 1510.207520,2321.215088 1507.953491,2318.488770 
	C1506.900269,2317.214844 1505.863770,2315.927734 1504.329712,2314.245605 
	C1502.806641,2312.587402 1501.541992,2311.985352 1499.302979,2312.818359 
	C1494.025635,2309.420898 1491.208862,2304.566406 1488.574341,2298.817871 
	C1484.835938,2305.700439 1479.810059,2311.287354 1472.453125,2314.427490 
	C1477.043457,2314.482666 1478.943604,2310.432861 1482.244141,2308.610840 
	C1485.978027,2306.549072 1490.401367,2306.112061 1493.637329,2308.296875 
	C1496.751587,2310.399170 1495.223145,2314.313721 1493.597534,2317.328125 
	C1491.571655,2321.084961 1491.751343,2323.657959 1496.760132,2324.112061 
	C1498.770142,2324.294189 1500.612305,2325.096191 1502.411987,2325.982666 
	C1509.868774,2329.656250 1510.248413,2332.956299 1503.850342,2338.393555 
	C1502.061157,2339.914307 1499.754517,2340.600098 1497.395996,2342.510254 
	C1492.371338,2345.333496 1488.129883,2345.876465 1484.006470,2341.044434 
	C1482.450562,2339.515137 1481.527954,2338.206543 1479.432251,2337.636963 
	C1477.338501,2337.911133 1475.939087,2338.560059 1474.003174,2339.602295 
	C1470.058228,2341.243652 1466.654419,2342.442871 1462.361328,2342.458252 
	C1461.364136,2343.091309 1460.717041,2343.093262 1460.234375,2343.419678 
	C1460.791260,2343.537109 1461.322632,2343.432373 1462.430664,2343.232910 
	C1465.024658,2352.092041 1457.418579,2352.879395 1452.687378,2353.943604 
	C1446.573730,2355.318848 1449.189941,2348.473633 1447.407593,2345.569336 
	C1447.083252,2345.738770 1446.759644,2345.840576 1446.520630,2346.042725 
	C1443.030029,2348.993652 1438.798706,2353.719482 1434.678101,2350.127930 
	C1429.224976,2345.375000 1437.652100,2342.939453 1438.130127,2338.354736 
	C1437.625244,2337.122559 1437.704834,2336.505859 1437.838013,2335.887939 
	C1438.412842,2333.222412 1443.120483,2330.944336 1440.473511,2328.547607 
	C1437.442383,2325.803223 1433.974365,2328.580078 1431.549805,2331.651855 
	C1431.946655,2332.456299 1432.155151,2332.757568 1432.733887,2333.476562 
	C1434.424072,2335.357666 1436.348999,2336.074951 1437.974365,2338.114746 
	C1436.395508,2343.465820 1432.154053,2344.111084 1427.367188,2344.486328 
	C1426.943237,2344.433105 1426.752930,2345.235352 1427.231934,2345.330566 
	C1431.369507,2352.356201 1425.901733,2354.934570 1421.529907,2357.577637 
	C1418.456299,2359.435547 1414.167847,2361.142822 1411.546875,2357.625977 
	C1406.967285,2351.480957 1407.401855,2344.901611 1413.635620,2338.533447 
	C1407.952759,2339.505127 1403.399536,2339.469238 1401.160156,2344.914062 
	C1399.654419,2348.575195 1395.794067,2349.505859 1391.249268,2349.478516 
	C1389.609009,2349.009033 1388.789917,2348.488525 1387.614746,2347.229736 
	C1386.791260,2345.710449 1386.618408,2344.750488 1386.859619,2343.050293 
	C1389.753784,2340.994629 1390.418945,2339.660400 1386.679932,2337.713867 
	C1385.660034,2335.109375 1385.729370,2333.166748 1386.804321,2330.595703 
	C1387.943970,2329.318604 1388.801270,2328.881104 1390.493042,2328.672363 
	C1403.384521,2329.396240 1408.994995,2319.508057 1403.742798,2305.443115 
	C1402.435669,2301.942383 1403.138794,2293.909180 1394.178955,2298.332031 
	C1393.095825,2298.661621 1392.653931,2298.763916 1391.531982,2298.856689 
	C1388.557739,2298.210205 1387.167358,2296.701904 1386.534668,2293.940186 
	C1386.487793,2293.462646 1386.525269,2292.631592 1386.143311,2292.923828 
	C1384.132935,2294.552246 1382.282593,2295.315186 1379.505127,2295.306885 
	C1375.360718,2293.721680 1375.244751,2296.219482 1374.590576,2298.552979 
	C1373.936401,2300.887207 1373.932983,2304.578857 1370.143921,2303.734619 
	C1367.320679,2303.105713 1366.048828,2300.116455 1366.229614,2297.416016 
	C1366.758301,2289.520752 1365.449585,2281.477051 1367.975830,2273.101318 
	C1367.779419,2271.194336 1367.331543,2270.028076 1366.203857,2268.422852 
	C1364.534058,2265.763916 1363.912598,2263.581299 1365.475830,2260.627441 
	C1365.908813,2256.763184 1365.908813,2253.468018 1365.908813,2249.593750 
	C1369.235962,2251.154785 1371.824097,2252.369141 1375.416382,2254.054688 
	C1371.169678,2256.817383 1368.192627,2259.659180 1371.064087,2264.549316 
	C1374.690918,2262.983643 1374.214478,2258.735596 1377.545288,2257.009277 
	C1381.232056,2255.098877 1381.065308,2247.784424 1377.212646,2246.548340 
	C1372.167969,2244.929443 1372.027222,2242.800537 1373.341553,2238.555176 
	C1374.198364,2235.788086 1373.829834,2232.641602 1374.010376,2228.812988 
	C1375.889038,2226.440430 1377.683472,2223.335938 1380.096191,2227.206543 
	C1382.856689,2231.634521 1383.665039,2228.441406 1386.417847,2226.742188 
	C1391.457031,2223.631836 1385.090942,2218.963623 1389.493164,2216.248047 
	C1391.506348,2217.720459 1392.837402,2219.673828 1394.595093,2221.101562 
	C1396.713989,2222.822510 1397.981445,2224.237549 1395.440918,2226.576416 
	C1394.426392,2227.510010 1393.725586,2229.165283 1394.652466,2230.145752 
	C1400.476440,2236.308350 1394.252441,2240.983887 1391.688843,2244.566162 
	C1388.616943,2248.858887 1389.282104,2252.302490 1390.038086,2257.135010 
	C1390.804321,2263.865479 1395.598511,2268.943115 1393.070679,2275.829102 
	C1403.817017,2274.608643 1400.549927,2263.460205 1407.267822,2259.855957 
	C1409.920776,2266.553955 1408.726440,2273.202881 1408.023926,2279.864746 
	C1410.687500,2279.849121 1410.838379,2277.905762 1411.656738,2276.686768 
	C1412.565552,2275.333008 1413.088989,2273.257324 1415.025269,2273.460449 
	C1417.458984,2273.715332 1417.759033,2275.938965 1417.971313,2277.963379 
	C1418.139404,2279.565674 1417.818115,2281.299072 1419.328125,2282.726074 
	C1421.677612,2283.108398 1423.895508,2280.184814 1426.599609,2282.130371 
	C1427.613892,2282.860352 1429.303955,2282.961914 1429.486938,2281.068604 
	C1429.560425,2280.308594 1428.740356,2278.839355 1428.145508,2278.735596 
	C1423.331299,2277.898438 1420.563110,2275.354004 1422.565918,2270.524658 
	C1424.380981,2266.148438 1428.382690,2265.617188 1432.682983,2267.096191 
	C1433.228638,2267.283691 1433.941895,2266.983887 1435.079590,2266.851318 
	C1437.169678,2260.031006 1430.444214,2255.616943 1430.030273,2248.753662 
	C1432.737305,2248.286621 1435.943970,2247.010742 1437.823975,2251.072510 
	C1438.400635,2252.283936 1438.838135,2252.797607 1439.662476,2253.641113 
	C1440.038208,2253.980225 1439.993042,2253.992676 1440.008179,2253.972168 
	C1443.524780,2256.202148 1446.301636,2265.937744 1451.538818,2254.520752 
z"
        />
        <path
          fill="#070709"
          opacity="1.000000"
          stroke="none"
          d="
M1371.134277,2198.063232 
	C1371.679565,2195.156006 1369.691284,2192.199951 1373.621704,2190.412109 
	C1380.867920,2194.819336 1389.652954,2194.468506 1396.918091,2198.404297 
	C1401.626099,2200.954590 1402.102295,2203.167969 1396.638672,2206.020508 
	C1395.388062,2206.621582 1394.705811,2206.985107 1393.398193,2207.381592 
	C1392.100708,2207.700928 1391.350830,2207.827393 1390.099121,2208.193848 
	C1389.316895,2208.692383 1389.033691,2208.935547 1388.334839,2209.553955 
	C1385.164795,2209.951904 1382.410645,2209.974854 1378.830078,2209.993896 
	C1376.887939,2209.588623 1376.046753,2208.800781 1374.776123,2207.613281 
	C1373.745361,2206.688232 1373.161865,2206.141846 1372.154541,2205.135498 
	C1371.201294,2204.008057 1370.794189,2203.267578 1370.381348,2201.827393 
	C1370.320679,2200.308838 1370.529663,2199.471436 1371.134277,2198.063232 
z"
        />
        <path
          fill="#1A1615"
          opacity="1.000000"
          stroke="none"
          d="
M1375.834106,2205.627197 
	C1376.816772,2206.893555 1377.376587,2207.861328 1377.969971,2209.409668 
	C1379.015381,2212.056885 1369.875366,2211.302734 1376.608521,2215.066650 
	C1377.589844,2215.615234 1377.007324,2217.245605 1376.070801,2217.404541 
	C1371.810059,2218.126953 1372.689209,2222.543457 1370.664795,2224.850342 
	C1367.213379,2221.488037 1366.161743,2217.013428 1366.026245,2204.644043 
	C1366.647339,2203.636475 1367.147217,2203.288330 1367.798584,2202.401855 
	C1368.811035,2201.250488 1369.718750,2200.953857 1371.389893,2201.567871 
	C1372.236938,2202.589844 1372.601074,2203.074219 1373.480835,2203.707031 
	C1374.347778,2204.449951 1374.861694,2204.909180 1375.834106,2205.627197 
z"
        />
        <path
          fill="#313E41"
          opacity="1.000000"
          stroke="none"
          d="
M1377.923950,2173.854492 
	C1380.852417,2174.325684 1382.887085,2175.698975 1381.302124,2178.999512 
	C1380.368408,2180.943115 1378.764526,2182.564941 1376.945068,2185.033203 
	C1375.437378,2181.000732 1375.988403,2178.078125 1377.669189,2174.509033 
	C1377.877686,2173.820068 1377.974121,2173.924316 1377.923950,2173.854492 
z"
        />
        <path
          fill="#1A1615"
          opacity="1.000000"
          stroke="none"
          d="
M1391.415039,2205.673340 
	C1392.871704,2206.022705 1393.768188,2206.035645 1395.335693,2206.092285 
	C1394.625610,2208.895508 1392.348022,2210.086914 1388.617920,2209.944092 
	C1387.919067,2209.928955 1387.998047,2208.947266 1388.021240,2208.455322 
	C1388.826538,2206.922363 1389.580200,2205.850830 1391.415039,2205.673340 
z"
        />
        <path
          fill="#2E2D30"
          opacity="1.000000"
          stroke="none"
          d="
M1345.385986,2240.069824 
	C1345.759521,2242.790527 1344.142212,2244.087402 1340.715332,2244.210449 
	C1340.785034,2241.981445 1342.320068,2240.560059 1345.385986,2240.069824 
z"
        />
        <path
          fill="#313E41"
          opacity="1.000000"
          stroke="none"
          d="
M1387.805420,2182.499268 
	C1389.878174,2182.112305 1391.461060,2182.446289 1390.098389,2185.424072 
	C1389.941528,2185.903809 1389.998291,2185.989502 1389.952881,2185.966797 
	C1388.872681,2186.142578 1387.619263,2187.448242 1386.894531,2186.138184 
	C1386.505493,2185.435303 1387.342041,2184.054443 1387.805420,2182.499268 
z"
        />
        <path
          fill="#2E2D30"
          opacity="1.000000"
          stroke="none"
          d="
M1339.631592,2246.248535 
	C1340.667236,2246.849609 1341.262939,2247.806152 1341.871582,2249.347656 
	C1340.776978,2250.233398 1339.533081,2251.368408 1338.585815,2250.006836 
	C1337.806396,2248.886719 1338.344238,2247.629639 1339.631592,2246.248535 
z"
        />
        <path
          fill="#2E2D30"
          opacity="1.000000"
          stroke="none"
          d="
M1349.431396,2236.113281 
	C1349.865967,2237.827637 1349.182129,2239.274902 1346.628174,2239.916260 
	C1346.224609,2238.250244 1346.730103,2236.663086 1349.431396,2236.113281 
z"
        />
        <path
          fill="#2E2D30"
          opacity="1.000000"
          stroke="none"
          d="
M1353.685303,2233.605713 
	C1353.101440,2234.676025 1352.154175,2235.318359 1350.615356,2236.017334 
	C1350.406494,2234.292236 1350.352417,2232.013428 1353.685303,2233.605713 
z"
        />
        <path
          fill="#313E41"
          opacity="1.000000"
          stroke="none"
          d="
M1392.444580,2176.968750 
	C1392.463867,2178.197266 1391.767944,2179.182373 1390.965088,2178.335449 
	C1390.210449,2177.539307 1391.053223,2176.757324 1392.444580,2176.968750 
z"
        />
        <path
          fill="#313E41"
          opacity="1.000000"
          stroke="none"
          d="
M1463.493286,2129.447998 
	C1462.938965,2129.881104 1462.103394,2129.914551 1460.639893,2129.982910 
	C1460.130371,2127.787109 1460.150146,2127.781006 1463.493286,2129.447998 
z"
        />
        <path
          fill="#778FA4"
          opacity="1.000000"
          stroke="none"
          d="
M1410.707520,2134.502197 
	C1410.083618,2132.781494 1410.226196,2130.887695 1413.284912,2130.095459 
	C1414.032104,2131.996338 1412.853760,2133.232422 1410.707520,2134.502197 
z"
        />
        <path
          fill="#728DA3"
          opacity="1.000000"
          stroke="none"
          d="
M1519.968506,2085.985840 
	C1518.331421,2085.012207 1518.791626,2084.250732 1519.910156,2084.235840 
	C1520.950562,2084.221924 1521.591553,2084.945068 1519.968506,2085.985840 
z"
        />
        <path
          fill="#070709"
          opacity="1.000000"
          stroke="none"
          d="
M1396.932983,2178.894043 
	C1397.032593,2178.836426 1396.833374,2178.951660 1396.932983,2178.894043 
z"
        />
        <path
          fill="#313E41"
          opacity="1.000000"
          stroke="none"
          d="
M1388.009644,2181.992676 
	C1387.795776,2181.735352 1387.572144,2181.484863 1387.348633,2181.234619 
	C1387.497803,2181.327881 1387.646973,2181.421387 1387.890625,2181.767090 
	C1387.985229,2182.019043 1388.000000,2182.000000 1388.009644,2181.992676 
z"
        />
        <path
          fill="#2E2D30"
          opacity="1.000000"
          stroke="none"
          d="
M1325.082886,2268.931641 
	C1325.135132,2269.023926 1325.030640,2268.839355 1325.082886,2268.931641 
z"
        />
        <path
          fill="#2E2D30"
          opacity="1.000000"
          stroke="none"
          d="
M1332.026245,2268.040039 
	C1331.823975,2267.810059 1331.598145,2267.542236 1331.372314,2267.274414 
	C1331.525635,2267.367676 1331.679077,2267.460693 1331.955322,2267.801270 
	C1332.078125,2268.048828 1332.002686,2268.002441 1332.026245,2268.040039 
z"
        />
        <path
          fill="#313E41"
          opacity="1.000000"
          stroke="none"
          d="
M1453.830322,2107.861816 
	C1454.015625,2108.033936 1454.262817,2108.326416 1454.510010,2108.618896 
	C1454.343262,2108.519531 1454.176392,2108.420166 1453.837402,2108.073730 
	C1453.665405,2107.826416 1453.892212,2107.982422 1453.830322,2107.861816 
z"
        />
        <path
          fill="#7B95AB"
          opacity="1.000000"
          stroke="none"
          d="
M1286.788940,1958.052368 
	C1286.239014,1959.015991 1285.728882,1959.567871 1285.153564,1959.647095 
	C1283.594971,1959.861694 1281.396729,1960.238892 1281.429077,1957.995117 
	C1281.461060,1955.790161 1283.637451,1956.153809 1285.203003,1956.416260 
	C1285.775269,1956.512329 1286.269287,1957.075317 1286.788940,1958.052368 
z"
        />
        <path
          fill="#73828A"
          opacity="1.000000"
          stroke="none"
          d="
M1456.812866,1902.240967 
	C1463.663574,1896.025635 1463.663574,1896.025635 1469.799927,1900.364380 
	C1475.081909,1905.147827 1479.585571,1910.486694 1487.620850,1906.825439 
	C1490.620728,1903.361572 1493.888916,1903.793213 1497.071289,1904.760254 
	C1499.592651,1905.526367 1502.048706,1906.366089 1505.565674,1906.237305 
	C1508.722534,1910.336182 1512.201294,1914.536621 1504.145264,1917.057983 
	C1495.837769,1915.298706 1488.131348,1915.598633 1480.588989,1916.158447 
	C1468.401367,1917.062988 1456.375000,1913.392944 1444.200928,1915.376831 
	C1443.681396,1915.461670 1442.920776,1914.067505 1442.119507,1912.656860 
	C1440.961060,1908.106323 1443.813477,1906.765381 1447.022217,1904.973877 
	C1451.429443,1903.877563 1454.035400,1906.754272 1457.215454,1908.343140 
	C1457.375244,1905.989746 1456.462158,1904.520752 1456.812866,1902.240967 
z"
        />
        <path
          fill="#D0D1C8"
          opacity="1.000000"
          stroke="none"
          d="
M1503.188232,1917.456787 
	C1509.817749,1915.592407 1505.223145,1910.666626 1506.425049,1906.982422 
	C1508.728271,1901.965698 1511.050537,1897.635254 1513.733398,1892.748291 
	C1514.785400,1891.392578 1515.634766,1891.094971 1516.884033,1892.091064 
	C1517.272461,1894.266357 1517.444824,1895.731934 1517.848145,1897.900391 
	C1522.794678,1906.449829 1518.654297,1911.399658 1512.870850,1915.438599 
	C1516.934937,1913.162109 1521.289062,1912.203125 1525.656006,1917.166992 
	C1525.643311,1920.646729 1523.873169,1921.745850 1521.351440,1921.883301 
	C1517.493286,1922.093384 1513.631348,1922.235962 1508.891113,1922.507324 
	C1505.788452,1921.689575 1502.483032,1921.966309 1503.188232,1917.456787 
z"
        />
        <path
          fill="#9FA2A1"
          opacity="1.000000"
          stroke="none"
          d="
M1389.840088,1897.552490 
	C1391.393311,1891.921265 1394.188965,1894.858643 1396.408081,1896.000488 
	C1399.207153,1897.440674 1399.129150,1900.959229 1401.490479,1903.585938 
	C1408.084351,1906.234619 1406.914429,1899.810181 1410.095947,1897.653931 
	C1412.731567,1897.637817 1414.709717,1897.764038 1417.414062,1898.051758 
	C1420.400146,1900.472046 1421.885986,1903.078491 1421.711914,1907.133301 
	C1420.476562,1912.487427 1415.517212,1912.358154 1412.187622,1915.393433 
	C1406.313354,1915.319824 1399.818970,1918.949707 1396.332031,1910.678955 
	C1394.380127,1905.856567 1390.382935,1902.936401 1389.840088,1897.552490 
z"
        />
        <path
          fill="#D99469"
          opacity="1.000000"
          stroke="none"
          d="
M1355.600342,1897.108276 
	C1359.387329,1896.186646 1360.326782,1890.205322 1365.232178,1893.692017 
	C1368.706909,1896.552979 1368.842407,1899.868408 1368.428711,1904.144165 
	C1367.740234,1907.164062 1366.753662,1909.111450 1364.467041,1911.227417 
	C1360.941772,1914.188354 1357.877075,1914.074097 1354.286621,1911.652466 
	C1353.885498,1909.304199 1353.927612,1907.553955 1353.793457,1905.148926 
	C1352.124023,1901.806396 1352.609253,1899.505005 1355.600342,1897.108276 
z"
        />
        <path
          fill="#E7E3DA"
          opacity="1.000000"
          stroke="none"
          d="
M1389.055420,1896.712646 
	C1391.472290,1898.917725 1392.533325,1901.301758 1395.421387,1902.300781 
	C1398.967651,1903.527710 1398.245117,1906.227905 1396.343506,1909.415771 
	C1392.296875,1909.624023 1388.141113,1910.939575 1385.967407,1906.083130 
	C1384.961182,1903.835327 1383.309204,1905.232178 1382.608521,1906.850098 
	C1381.367798,1909.715210 1379.549805,1909.820312 1376.586670,1908.165039 
	C1373.558350,1904.716187 1374.071167,1901.843506 1378.084229,1899.113037 
	C1381.997070,1898.071777 1385.124512,1897.309937 1389.055420,1896.712646 
z"
        />
        <path
          fill="#9FA2A1"
          opacity="1.000000"
          stroke="none"
          d="
M1448.441040,1905.059937 
	C1446.214844,1907.405396 1444.378540,1909.106934 1442.254272,1911.379883 
	C1440.493774,1911.974609 1439.020996,1911.997803 1436.812622,1911.964355 
	C1433.378296,1910.809326 1432.419800,1908.149536 1434.592407,1906.822388 
	C1439.373779,1903.901733 1433.746826,1903.025024 1433.783691,1900.463867 
	C1433.400146,1897.786499 1433.638062,1895.880737 1435.049072,1893.534912 
	C1441.026001,1893.419067 1440.452271,1900.170166 1445.447998,1901.928223 
	C1448.246094,1901.453003 1448.649170,1902.809570 1448.441040,1905.059937 
z"
        />
        <path
          fill="#687073"
          opacity="1.000000"
          stroke="none"
          d="
M1378.191284,1898.408447 
	C1378.019897,1901.491333 1374.650024,1903.140503 1375.861694,1907.313110 
	C1375.249756,1907.973145 1374.543091,1907.905518 1373.303955,1907.859863 
	C1370.439941,1909.763550 1368.655762,1908.445435 1366.588379,1906.103271 
	C1366.058838,1902.148560 1365.854736,1898.971069 1365.626343,1894.997559 
	C1370.082397,1893.856079 1373.858398,1895.728394 1378.191284,1898.408447 
z"
        />
        <path
          fill="#73828A"
          opacity="1.000000"
          stroke="none"
          d="
M1412.425781,1915.852173 
	C1411.317871,1910.567383 1414.956177,1909.775635 1419.437744,1908.884766 
	C1421.623413,1906.340332 1423.774170,1905.826416 1427.062256,1906.852661 
	C1428.655884,1908.193481 1429.002441,1909.272461 1428.433350,1911.257935 
	C1423.652466,1915.471436 1418.596558,1916.201660 1412.425781,1915.852173 
z"
        />
        <path
          fill="#73828A"
          opacity="1.000000"
          stroke="none"
          d="
M1435.662598,1900.050049 
	C1437.082764,1903.344849 1439.400146,1905.982300 1435.429810,1908.247437 
	C1435.098755,1908.436401 1435.545288,1909.988403 1435.853760,1911.407471 
	C1434.652588,1913.906738 1432.918091,1914.380493 1430.410400,1912.507080 
	C1430.158447,1907.968628 1426.531860,1904.112671 1429.991211,1899.415527 
	C1432.245605,1898.370728 1433.802002,1898.440674 1435.662598,1900.050049 
z"
        />
        <path
          fill="#9FA2A1"
          opacity="1.000000"
          stroke="none"
          d="
M1430.188232,1899.849854 
	C1429.207031,1903.985962 1432.889404,1907.286743 1430.296021,1911.432007 
	C1429.679443,1912.033813 1429.340576,1912.034546 1428.493164,1912.024902 
	C1427.947510,1911.329224 1427.910156,1910.644165 1427.948608,1909.449951 
	C1426.125977,1905.148560 1420.492676,1903.816162 1421.495850,1897.684814 
	C1422.744873,1894.398682 1424.825684,1894.142822 1427.698853,1894.392090 
	C1429.677246,1895.826660 1430.384766,1897.335449 1430.188232,1899.849854 
z"
        />
        <path
          fill="#E7E3DA"
          opacity="1.000000"
          stroke="none"
          d="
M1365.754883,1905.847900 
	C1367.996948,1905.999023 1369.730957,1906.671753 1372.118164,1907.613281 
	C1372.182617,1912.431396 1369.034790,1912.617920 1364.693237,1911.976562 
	C1363.805786,1909.860718 1363.787476,1907.930176 1365.754883,1905.847900 
z"
        />
        <path
          fill="#6E8AA1"
          opacity="1.000000"
          stroke="none"
          d="
M1365.054321,1919.975098 
	C1365.996948,1918.294922 1367.168335,1918.090088 1368.315430,1918.882935 
	C1369.242065,1919.523438 1369.231567,1920.542969 1368.280151,1921.156250 
	C1367.113037,1921.908447 1365.945068,1921.693115 1365.054321,1919.975098 
z"
        />
        <path
          fill="#7B95AB"
          opacity="1.000000"
          stroke="none"
          d="
M1322.067017,1933.590210 
	C1321.527588,1933.865356 1321.042847,1933.620117 1320.355835,1932.947754 
	C1320.411621,1932.532715 1320.730469,1932.424316 1321.553223,1932.353027 
	C1322.057251,1932.390015 1322.060791,1933.190186 1322.067017,1933.590210 
z"
        />
        <path
          fill="#C4C8C1"
          opacity="1.000000"
          stroke="none"
          d="
M1151.868408,1941.018677 
	C1156.628540,1940.203491 1161.482300,1940.083862 1167.244995,1940.043701 
	C1169.949097,1942.950562 1175.783813,1943.597778 1171.869751,1949.402344 
	C1166.445923,1957.445679 1164.039795,1958.091919 1158.316650,1950.166504 
	C1156.251343,1947.306763 1151.944824,1946.083618 1151.868408,1941.018677 
z"
        />
        <path
          fill="#5A605E"
          opacity="1.000000"
          stroke="none"
          d="
M1335.269287,1857.864014 
	C1337.314453,1857.477051 1338.846313,1857.492554 1339.797241,1856.756958 
	C1343.957642,1853.539551 1346.860962,1853.198853 1349.212646,1858.962646 
	C1351.075928,1863.529907 1358.686646,1862.407715 1359.868286,1869.059082 
	C1359.701782,1874.344116 1356.987915,1877.016846 1352.672119,1877.824219 
	C1349.539429,1878.410034 1348.003418,1879.970703 1347.662231,1883.812134 
	C1346.169678,1885.480225 1344.734741,1885.531128 1342.689697,1884.384766 
	C1341.089966,1883.320679 1340.052734,1882.760742 1338.484131,1881.938965 
	C1336.576904,1880.533081 1336.013428,1882.395020 1334.404541,1882.164062 
	C1333.620728,1878.965454 1331.749634,1878.176880 1329.195801,1877.752930 
	C1322.431641,1876.629883 1315.967773,1874.387451 1308.775146,1872.023804 
	C1307.292358,1871.161255 1306.978760,1870.176147 1307.303955,1868.306519 
	C1314.002441,1857.267944 1325.063110,1859.882324 1335.269287,1857.864014 
z"
        />
        <path
          fill="#748B9F"
          opacity="1.000000"
          stroke="none"
          d="
M1333.631226,1882.159668 
	C1334.429932,1881.180664 1335.038574,1880.530640 1335.607300,1879.847290 
	C1336.211548,1879.121216 1335.639282,1876.973511 1337.587891,1877.996948 
	C1338.089111,1878.260132 1337.999634,1879.648315 1338.067139,1881.098145 
	C1338.440430,1889.284912 1336.486206,1896.617920 1334.690430,1904.543213 
	C1331.520508,1904.505249 1328.922485,1904.406738 1327.132690,1902.280640 
	C1325.425171,1900.252197 1323.397095,1900.293091 1320.613281,1901.708618 
	C1318.252563,1902.107666 1316.496826,1902.206543 1313.868652,1902.151611 
	C1313.422729,1899.598022 1313.440308,1897.061279 1315.565430,1894.456543 
	C1320.322388,1889.467529 1325.503418,1886.357422 1332.006104,1884.162476 
	C1332.721436,1883.198608 1333.027100,1882.801392 1333.631226,1882.159668 
z"
        />
        <path
          fill="#98999E"
          opacity="1.000000"
          stroke="none"
          d="
M1342.178101,1884.711670 
	C1343.487183,1884.030273 1344.846924,1884.179932 1346.877075,1884.461914 
	C1350.408936,1886.952515 1356.469360,1887.935547 1351.141724,1893.783325 
	C1350.533936,1894.450195 1353.292847,1895.813721 1355.573730,1896.425293 
	C1355.490845,1898.805054 1354.750000,1900.963257 1353.813110,1903.807861 
	C1344.096069,1901.338989 1342.427734,1898.562500 1342.178101,1884.711670 
z"
        />
        <path
          fill="#5A605E"
          opacity="1.000000"
          stroke="none"
          d="
M1204.184814,1833.092285 
	C1204.057495,1835.517334 1204.004761,1837.315796 1205.432983,1839.515015 
	C1207.038330,1841.293945 1208.763550,1841.937744 1209.710083,1844.103760 
	C1209.859253,1846.023315 1209.980835,1847.297363 1210.149902,1849.206665 
	C1206.958984,1849.227539 1203.725586,1848.154785 1200.480591,1848.118286 
	C1196.686401,1848.075439 1194.229126,1846.588867 1192.363770,1843.496216 
	C1191.511230,1842.082886 1190.459595,1839.751465 1189.381104,1839.682983 
	C1183.045532,1839.281250 1177.604858,1838.607300 1175.626953,1830.814087 
	C1175.208618,1829.165894 1170.625000,1830.257446 1170.184082,1833.830566 
	C1169.633911,1838.289795 1168.220703,1842.622681 1169.029907,1846.865967 
	C1167.968506,1847.278809 1167.675293,1847.483887 1167.374634,1847.495605 
	C1163.857422,1847.631104 1161.436279,1841.682739 1157.703979,1845.073486 
	C1154.666870,1847.832642 1155.287720,1852.553223 1154.958374,1856.313965 
	C1154.634644,1860.008789 1157.726196,1864.348877 1153.090698,1867.001465 
	C1149.714233,1868.933594 1146.194946,1870.234009 1142.235474,1866.816162 
	C1139.286011,1864.269897 1136.767456,1870.085449 1132.745728,1868.636475 
	C1131.232178,1864.900879 1136.920166,1858.445923 1128.519897,1856.198975 
	C1124.865234,1852.754028 1122.287109,1848.652344 1127.993408,1846.737061 
	C1137.105957,1843.678345 1141.000977,1835.134277 1148.390381,1829.398926 
	C1150.925171,1826.143066 1152.886230,1823.286621 1156.955322,1821.748047 
	C1159.549194,1821.176514 1161.472168,1821.194336 1164.081055,1821.044922 
	C1169.846313,1821.150146 1168.788452,1815.329468 1172.644287,1813.608887 
	C1174.192871,1814.098633 1174.595337,1814.770386 1174.778320,1815.837158 
	C1174.876343,1816.408813 1175.214966,1816.161499 1174.873047,1815.145386 
	C1172.857666,1809.155518 1170.550537,1802.032471 1176.381226,1797.983154 
	C1181.795288,1794.223022 1188.914429,1796.151001 1195.596191,1799.416992 
	C1203.682983,1801.396606 1203.682983,1801.396606 1204.266602,1813.188965 
	C1202.622803,1820.109497 1202.177856,1826.264282 1204.184814,1833.092285 
z"
        />
        <path
          fill="#797771"
          opacity="1.000000"
          stroke="none"
          d="
M1150.169189,1829.712402 
	C1149.499146,1836.057739 1145.365479,1837.915039 1140.924805,1839.642822 
	C1138.336670,1840.649658 1136.197998,1841.802490 1138.937378,1845.348877 
	C1140.868408,1847.848877 1139.190186,1849.739990 1136.032593,1849.506958 
	C1134.750977,1849.412476 1133.534302,1848.598999 1132.245117,1848.418091 
	C1127.095947,1847.695557 1126.946655,1847.934326 1127.819092,1855.195557 
	C1126.409668,1855.939697 1124.935181,1855.989014 1122.721802,1856.060669 
	C1119.187866,1852.834717 1119.802124,1849.460693 1121.508667,1845.973511 
	C1122.094360,1844.776855 1124.316284,1843.611084 1123.993408,1843.542603 
	C1121.650879,1843.045410 1120.405151,1840.219482 1117.911865,1840.586548 
	C1115.951660,1840.874878 1115.657593,1842.741699 1115.393311,1844.376099 
	C1114.859131,1847.678589 1115.949341,1850.972290 1115.541260,1854.265137 
	C1114.551392,1862.251099 1109.866455,1865.448486 1102.184082,1862.644043 
	C1101.956543,1854.573975 1101.987793,1847.146484 1102.127319,1838.794800 
	C1104.086182,1836.279419 1104.606323,1834.087524 1105.396606,1831.866333 
	C1108.478516,1823.203491 1115.369751,1823.595215 1122.547974,1825.334961 
	C1123.969849,1822.738281 1122.958252,1819.911011 1124.254150,1816.812500 
	C1125.571655,1814.428833 1127.009277,1813.181641 1129.086304,1812.935913 
	C1130.381592,1812.782593 1131.619995,1813.391113 1132.913574,1813.440552 
	C1131.736450,1812.882446 1130.309692,1812.859131 1129.308105,1811.966431 
	C1127.101807,1809.999390 1127.251953,1808.232666 1129.745972,1806.516724 
	C1132.182739,1804.840088 1135.617065,1805.776733 1138.884399,1802.634766 
	C1141.067627,1801.438843 1142.079346,1800.941895 1143.623047,1800.257080 
	C1149.423950,1803.475220 1149.044922,1808.994019 1149.198853,1814.164062 
	C1149.345337,1819.085571 1150.131470,1823.949707 1150.169189,1829.712402 
z"
        />
        <path
          fill="#5A605E"
          opacity="1.000000"
          stroke="none"
          d="
M1101.307251,1862.354004 
	C1109.772705,1863.525757 1113.822998,1859.502441 1112.555542,1851.124756 
	C1111.694092,1845.430542 1110.877075,1839.912354 1116.228027,1835.762573 
	C1118.452148,1834.037476 1121.015747,1833.600586 1121.516479,1836.801270 
	C1122.321777,1841.947632 1125.883179,1842.437256 1130.674805,1842.773804 
	C1122.369385,1843.805054 1123.056274,1849.815552 1122.047119,1855.425293 
	C1120.185913,1859.060913 1120.025024,1861.994873 1121.349487,1865.337769 
	C1122.364746,1867.900391 1120.187866,1870.993896 1118.210571,1869.317505 
	C1112.933594,1864.844360 1109.378540,1869.358032 1105.151001,1870.508789 
	C1103.287720,1871.015869 1101.411865,1871.476318 1098.783081,1871.966675 
	C1098.056030,1868.639526 1097.376465,1865.099121 1101.307251,1862.354004 
z"
        />
        <path
          fill="#5A605E"
          opacity="1.000000"
          stroke="none"
          d="
M1231.640869,1858.402832 
	C1232.903442,1857.896118 1233.784546,1857.908569 1235.326172,1857.929688 
	C1240.104248,1859.078491 1243.631592,1859.131592 1247.883423,1856.748169 
	C1258.746338,1850.658691 1270.260742,1855.289673 1273.180908,1866.846680 
	C1268.462158,1871.960327 1263.023438,1874.185669 1256.839233,1872.853027 
	C1252.566284,1871.932129 1249.141602,1874.163452 1244.634277,1874.329102 
	C1240.457397,1872.754272 1236.931152,1871.464600 1232.702393,1870.080200 
	C1231.188965,1866.834351 1234.138306,1864.064941 1232.459839,1860.426758 
	C1231.749878,1859.627808 1231.504517,1859.275024 1231.640869,1858.402832 
z"
        />
        <path
          fill="#748B9F"
          opacity="1.000000"
          stroke="none"
          d="
M1209.946289,1896.012817 
	C1210.267700,1900.015259 1207.833984,1900.081909 1205.152832,1900.490234 
	C1203.821533,1895.179443 1203.075806,1889.958862 1209.086670,1886.391846 
	C1209.814209,1889.296509 1209.829346,1892.214600 1209.946289,1896.012817 
z"
        />
        <path
          fill="#5A605E"
          opacity="1.000000"
          stroke="none"
          d="
M1167.925293,1860.471436 
	C1167.327393,1862.193848 1166.223633,1862.562744 1165.611938,1861.811279 
	C1163.348145,1859.029907 1163.382202,1856.115967 1165.700317,1852.567993 
	C1168.610229,1854.746216 1167.874878,1857.424316 1167.925293,1860.471436 
z"
        />
        <path
          fill="#687073"
          opacity="1.000000"
          stroke="none"
          d="
M1231.923828,1857.202393 
	C1232.015747,1858.233032 1232.008911,1858.582642 1231.998535,1859.456299 
	C1228.082886,1859.767822 1227.179565,1856.593994 1225.708252,1853.160767 
	C1228.845703,1851.920532 1230.905518,1853.319214 1231.923828,1857.202393 
z"
        />
        <path
          fill="#748B9F"
          opacity="1.000000"
          stroke="none"
          d="
M1175.897095,1868.169189 
	C1174.273804,1867.122559 1174.221436,1866.050537 1175.434814,1865.099609 
	C1175.633179,1864.943970 1176.142090,1864.935791 1176.350708,1865.082397 
	C1177.650024,1865.995972 1177.558350,1867.043213 1175.897095,1868.169189 
z"
        />
        <path
          fill="#687073"
          opacity="1.000000"
          stroke="none"
          d="
M1271.447021,1868.900635 
	C1269.018066,1858.789551 1262.907593,1855.135742 1253.387573,1857.333252 
	C1250.823608,1857.925171 1248.227539,1858.819946 1245.951050,1860.112427 
	C1242.473633,1862.086548 1239.628662,1861.271851 1236.399170,1858.476807 
	C1240.610596,1856.725708 1246.115112,1857.473145 1247.003418,1850.066162 
	C1247.377075,1846.949463 1260.169800,1846.663818 1263.268799,1849.919189 
	C1270.337280,1857.344238 1278.209106,1854.446899 1285.826416,1851.879517 
	C1288.423218,1851.004272 1291.366943,1849.023315 1293.074341,1851.152466 
	C1298.557129,1857.989136 1306.286011,1855.553711 1313.563965,1856.407471 
	C1319.753540,1854.231079 1325.819824,1856.223145 1331.532837,1854.070679 
	C1333.243408,1853.426147 1334.290405,1855.403076 1335.717041,1857.186523 
	C1334.718506,1860.872437 1332.225830,1862.372925 1329.065674,1861.929443 
	C1321.546021,1860.874146 1315.145142,1863.112549 1308.132080,1867.897461 
	C1305.698486,1867.925903 1304.561157,1867.978027 1302.828369,1868.144531 
	C1300.568481,1868.371948 1298.910278,1868.297974 1296.614746,1868.018066 
	C1292.165649,1867.116089 1288.320068,1865.980469 1284.519043,1869.631348 
	C1283.694336,1870.656372 1283.298218,1871.189453 1282.486816,1872.004028 
	C1276.999756,1877.309448 1273.112793,1877.338989 1271.447021,1868.900635 
z"
        />
        <path
          fill="#434F51"
          opacity="1.000000"
          stroke="none"
          d="
M1271.091919,1868.100586 
	C1272.943970,1873.303711 1275.754028,1875.141113 1281.414551,1872.490234 
	C1281.249756,1878.162109 1277.968628,1882.340942 1273.262817,1885.760986 
	C1270.592407,1887.701782 1268.199951,1887.968628 1266.736938,1884.991699 
	C1264.155884,1879.740234 1259.675659,1879.988647 1254.992188,1879.940552 
	C1250.914673,1879.898804 1246.118652,1881.187500 1244.111328,1874.806152 
	C1248.614136,1870.683472 1252.426880,1865.841431 1259.443359,1870.914429 
	C1262.041626,1872.792969 1265.966553,1866.770874 1271.091919,1868.100586 
z"
        />
        <path
          fill="#434F51"
          opacity="1.000000"
          stroke="none"
          d="
M1295.420410,1866.381348 
	C1298.175171,1866.135742 1300.291504,1866.093628 1303.201416,1866.046997 
	C1305.186279,1866.056396 1306.251587,1866.415161 1307.555786,1867.619385 
	C1307.949097,1868.041016 1307.975708,1868.022339 1307.965942,1868.038574 
	C1307.967773,1868.935669 1307.979370,1869.816650 1307.995483,1871.359131 
	C1306.377808,1877.190186 1301.413452,1878.262939 1297.369629,1879.357056 
	C1293.884155,1880.299927 1293.174683,1876.085571 1292.084595,1872.647461 
	C1290.943604,1869.114014 1293.005981,1867.924072 1295.420410,1866.381348 
z"
        />
        <path
          fill="#D0D1C8"
          opacity="1.000000"
          stroke="none"
          d="
M1331.594727,1883.647461 
	C1328.880371,1889.171753 1323.554321,1892.063477 1316.574219,1893.659180 
	C1315.833740,1891.721069 1315.830566,1889.668945 1315.900879,1886.849609 
	C1318.012817,1883.206909 1320.639893,1882.227417 1324.156616,1883.065552 
	C1326.257568,1883.566406 1328.502563,1883.461914 1331.594727,1883.647461 
z"
        />
        <path
          fill="#A9ACA9"
          opacity="1.000000"
          stroke="none"
          d="
M1267.481689,1902.839722 
	C1264.264771,1904.839844 1261.602905,1904.244995 1258.434204,1902.498291 
	C1257.821533,1902.281860 1257.669067,1901.545898 1257.780029,1901.187256 
	C1259.609009,1897.770142 1264.039429,1899.138794 1265.794189,1895.790894 
	C1266.998657,1893.492676 1269.426636,1892.731201 1271.341797,1895.046265 
	C1273.046143,1897.106445 1270.949219,1898.451782 1269.658569,1899.819458 
	C1269.000977,1900.516602 1268.438110,1901.302979 1267.481689,1902.839722 
z"
        />
        <path
          fill="#5A605E"
          opacity="1.000000"
          stroke="none"
          d="
M1295.570801,1865.623047 
	C1295.994751,1868.497925 1294.326782,1869.700195 1292.365723,1871.440186 
	C1289.927979,1870.938965 1287.896484,1869.804321 1284.778564,1870.096924 
	C1286.573730,1863.477295 1286.871704,1863.340210 1295.570801,1865.623047 
z"
        />
        <path
          fill="#434F51"
          opacity="1.000000"
          stroke="none"
          d="
M1223.899414,1896.558594 
	C1222.964355,1897.919434 1221.805908,1898.046387 1220.198120,1896.981934 
	C1220.941528,1894.575317 1222.180176,1893.794678 1223.899414,1896.558594 
z"
        />
        <path
          fill="#D7D5CF"
          opacity="1.000000"
          stroke="none"
          d="
M1300.004028,1898.005127 
	C1300.168579,1897.940308 1300.329224,1897.870483 1300.239746,1897.896484 
	C1299.989746,1897.992310 1300.000000,1898.000000 1300.004028,1898.005127 
z"
        />
        <path
          fill="#313E41"
          opacity="1.000000"
          stroke="none"
          d="
M578.029602,1856.726074 
	C580.106750,1855.883667 582.196472,1855.895630 585.069702,1855.877686 
	C587.053467,1854.931519 588.118835,1853.865723 589.619629,1852.439453 
	C590.032837,1852.054443 590.005371,1852.001343 590.030273,1852.018921 
	C592.002441,1850.347290 594.142395,1849.865356 597.255127,1851.510742 
	C599.125732,1854.440552 600.357056,1856.842163 599.891846,1860.479736 
	C593.228210,1861.260986 586.627686,1861.230225 579.135193,1861.094482 
	C578.176208,1859.853149 578.109131,1858.716675 578.029602,1856.726074 
z"
        />
        <path
          fill="#C4BDAE"
          opacity="1.000000"
          stroke="none"
          d="
M597.074219,1869.988159 
	C597.757568,1872.091797 597.773315,1874.328613 597.599976,1877.382324 
	C593.148865,1875.328491 593.000610,1874.135986 597.074219,1869.988159 
z"
        />
        <path
          fill="#3D494C"
          opacity="1.000000"
          stroke="none"
          d="
M597.995728,1851.215820 
	C595.858521,1852.019287 593.747253,1852.018921 590.845581,1852.027588 
	C589.973633,1847.455688 593.644897,1846.227905 597.394165,1844.248047 
	C598.016541,1846.124146 598.019104,1848.268066 597.995728,1851.215820 
z"
        />
        <path
          fill="#687073"
          opacity="1.000000"
          stroke="none"
          d="
M886.644165,1895.191650 
	C886.332214,1894.456177 886.414490,1893.780151 886.859558,1892.737305 
	C888.026306,1893.385742 887.947571,1894.344849 886.644165,1895.191650 
z"
        />
        <path
          fill="#7B7F84"
          opacity="1.000000"
          stroke="none"
          d="
M529.129272,1881.616455 
	C528.500183,1881.341675 528.525574,1880.763428 529.235107,1879.891113 
	C529.783020,1880.174438 529.737854,1880.746338 529.129272,1881.616455 
z"
        />
        <path
          fill="#B6AFA3"
          opacity="1.000000"
          stroke="none"
          d="
M1750.476562,3453.465820 
	C1749.754517,3454.611084 1749.378540,3455.134521 1748.457520,3455.825195 
	C1744.762451,3456.097900 1743.591797,3458.607666 1742.515991,3461.004395 
	C1741.206055,3463.922852 1738.992554,3465.735840 1736.154175,3466.892578 
	C1733.489380,3467.978027 1730.855713,3470.890625 1727.973755,3469.106934 
	C1725.024170,3467.281738 1728.253906,3464.341797 1727.979492,3461.854248 
	C1727.763794,3459.897217 1728.411011,3457.746826 1727.841553,3455.946045 
	C1726.294067,3451.053711 1727.047241,3447.368896 1731.058228,3443.692139 
	C1736.435791,3438.762207 1733.195801,3431.804688 1734.112671,3424.205566 
	C1737.421509,3408.179688 1747.669556,3396.795166 1751.805176,3381.984131 
	C1753.080933,3371.846191 1759.118286,3365.082520 1764.515991,3357.897217 
	C1777.492310,3340.622559 1789.369141,3322.634766 1799.490479,3302.714844 
	C1805.385010,3291.688477 1814.108154,3284.174561 1822.356567,3276.019531 
	C1829.105469,3269.346680 1836.705811,3261.698242 1831.472290,3250.147217 
	C1830.819458,3248.706543 1830.804565,3246.681885 1831.449463,3245.233887 
	C1837.215210,3232.286133 1834.710938,3216.956787 1843.335938,3204.777832 
	C1845.998413,3201.018311 1847.206543,3196.345703 1848.433960,3191.845703 
	C1849.782959,3186.899658 1851.969849,3182.300537 1842.840942,3182.759033 
	C1837.011230,3183.052002 1838.028687,3177.643066 1839.661499,3173.795898 
	C1843.468872,3164.824463 1845.885254,3155.612305 1845.392334,3145.063965 
	C1845.347168,3143.365234 1845.402588,3142.391357 1845.655640,3140.694824 
	C1845.958496,3139.508057 1846.119141,3139.046631 1846.623535,3137.944336 
	C1850.745728,3132.052246 1856.757080,3130.009766 1862.351196,3128.800781 
	C1875.050781,3126.056152 1874.250122,3113.961670 1879.035156,3105.992188 
	C1882.055908,3100.961182 1882.783447,3095.338135 1889.789185,3101.275635 
	C1891.544067,3102.763184 1892.817505,3101.741211 1894.072632,3100.584473 
	C1895.292358,3099.460449 1896.483276,3097.507080 1897.912354,3097.568359 
	C1914.073120,3098.260742 1915.114624,3086.520508 1916.689087,3075.131592 
	C1916.776978,3074.496338 1917.191284,3073.908936 1917.791626,3072.706299 
	C1919.800781,3070.039795 1921.310791,3067.877197 1922.158325,3064.594238 
	C1922.809814,3063.181885 1923.260742,3062.417969 1924.137695,3061.138428 
	C1927.254150,3056.280518 1932.273193,3053.510010 1933.109131,3047.214844 
	C1933.306885,3041.446777 1935.091187,3036.942383 1938.132080,3032.930908 
	C1944.283936,3024.815918 1949.320801,3016.054932 1953.910156,3006.230225 
	C1958.763428,3002.889404 1963.620850,3000.457764 1964.042725,2993.549072 
	C1964.467163,2992.486328 1964.675171,2992.072998 1965.299072,2991.097168 
	C1970.138428,2987.633057 1970.324463,2981.027100 1976.490845,2978.890381 
	C1982.369873,2979.489990 1981.838623,2983.275879 1981.797607,2987.005615 
	C1981.660889,2999.446045 1979.348022,3011.210205 1971.653320,3021.401611 
	C1970.010742,3023.577148 1969.303345,3026.091064 1968.787842,3029.453613 
	C1968.565918,3031.169189 1968.336548,3032.126953 1967.731689,3033.740723 
	C1964.687500,3038.586182 1961.445068,3042.156982 1957.480957,3045.384033 
	C1948.995239,3052.291992 1944.209595,3062.083496 1940.450439,3071.994873 
	C1937.521118,3079.718506 1933.148560,3087.221436 1932.620117,3095.588135 
	C1932.049683,3104.620361 1920.666016,3105.181152 1919.780029,3112.065186 
	C1918.813232,3119.577148 1918.529663,3125.593750 1911.430542,3130.737549 
	C1905.484375,3135.046387 1901.680054,3141.790527 1901.911133,3150.558838 
	C1902.156616,3159.879395 1908.637817,3171.409424 1895.238159,3177.695068 
	C1894.711792,3177.942139 1896.517212,3178.505371 1897.173340,3179.012451 
	C1900.276367,3181.410645 1900.715942,3184.572021 1899.605225,3187.966309 
	C1898.552856,3191.182129 1896.119141,3192.964844 1892.682983,3192.197998 
	C1887.358154,3191.009277 1887.642944,3195.670166 1886.611694,3198.215576 
	C1878.650879,3217.865234 1870.449463,3237.472656 1859.799438,3255.767578 
	C1851.357178,3270.270020 1839.724609,3282.749268 1832.335083,3298.121826 
	C1828.499146,3306.101807 1824.346802,3314.137695 1818.179443,3320.900635 
	C1814.836426,3324.566406 1815.231079,3329.543701 1816.129395,3334.269775 
	C1818.224609,3345.295166 1817.138672,3355.456299 1808.522461,3363.802002 
	C1805.294067,3366.929199 1804.851440,3371.549561 1803.239014,3375.538574 
	C1801.615845,3379.553711 1800.014160,3383.672119 1796.393799,3386.375244 
	C1794.100830,3388.087402 1791.432373,3388.361816 1788.912476,3386.740479 
	C1786.445068,3385.153320 1786.929443,3382.780029 1787.604004,3380.475586 
	C1788.258667,3378.238770 1788.661255,3375.810547 1789.854126,3373.872803 
	C1796.731445,3362.698242 1799.820557,3350.031738 1804.090332,3338.275146 
	C1794.927734,3352.883057 1791.423950,3370.511963 1782.428223,3385.711670 
	C1774.061768,3399.848389 1769.496094,3416.197510 1757.636108,3428.313965 
	C1755.547607,3430.447754 1755.896851,3433.214111 1755.509888,3435.811523 
	C1754.634155,3441.688721 1754.796265,3447.849121 1750.476562,3453.465820 
z"
        />
        <path
          fill="#D0C8B8"
          opacity="1.000000"
          stroke="none"
          d="
M1676.736328,3489.877686 
	C1675.563843,3483.118164 1675.760010,3476.466797 1683.308350,3472.123291 
	C1684.555908,3472.531006 1685.053101,3473.111084 1685.495483,3473.737549 
	C1684.281738,3474.289307 1685.702026,3474.017822 1684.449951,3472.476562 
	C1685.136475,3467.935059 1688.391113,3464.849609 1689.034912,3460.423828 
	C1689.398315,3457.926758 1690.971191,3455.486328 1693.721558,3454.384766 
	C1694.631592,3454.020508 1696.325195,3453.384033 1696.280151,3453.184814 
	C1694.408569,3444.933838 1704.068726,3444.002930 1705.845947,3437.950928 
	C1707.592163,3432.004639 1711.660645,3426.910645 1712.370117,3420.453369 
	C1712.607544,3418.292480 1713.782471,3414.910645 1715.280762,3414.429688 
	C1724.022583,3411.623779 1723.003540,3402.155273 1727.950317,3396.898682 
	C1728.873535,3395.917725 1729.726929,3394.621826 1729.250610,3393.327148 
	C1726.477295,3385.787109 1731.335938,3385.822266 1737.188965,3385.979492 
	C1744.468262,3390.559326 1745.947998,3396.009033 1741.705688,3403.124023 
	C1738.133911,3409.114258 1736.133911,3415.801514 1734.495850,3423.353027 
	C1730.865967,3423.829590 1727.669922,3424.331055 1726.116455,3427.175293 
	C1723.795410,3431.425293 1721.958862,3434.781738 1715.804688,3433.014648 
	C1713.001465,3432.209717 1711.864258,3435.858154 1712.606201,3438.814941 
	C1713.468994,3442.252197 1715.925415,3446.843506 1708.673218,3443.545166 
	C1708.352173,3443.399170 1707.283325,3444.055176 1707.121338,3444.529297 
	C1704.661743,3451.721191 1697.356567,3456.618896 1696.942749,3464.582031 
	C1696.606079,3471.055908 1691.965332,3476.056641 1691.826782,3482.435791 
	C1691.819824,3482.760498 1691.760498,3483.120361 1691.608032,3483.399658 
	C1690.338257,3485.724854 1688.872681,3488.353271 1686.108643,3488.481689 
	C1683.129150,3488.620117 1684.773071,3484.351807 1682.182251,3483.309570 
	C1678.728760,3483.898438 1681.006226,3489.154053 1676.736328,3489.877686 
z"
        />
        <path
          fill="#D1C9BA"
          opacity="1.000000"
          stroke="none"
          d="
M1725.465820,3674.419678 
	C1732.403442,3677.372559 1735.395142,3684.521240 1731.644409,3689.822754 
	C1729.184448,3693.299805 1728.422363,3695.821045 1730.605713,3699.485596 
	C1732.707153,3703.013184 1730.083374,3705.763428 1728.360596,3708.524902 
	C1723.270874,3716.683105 1721.472900,3725.286377 1725.019287,3734.500732 
	C1726.780518,3739.076904 1724.176025,3741.497803 1720.782959,3743.209473 
	C1717.721802,3744.753662 1713.021729,3743.849854 1712.339966,3748.719727 
	C1711.606079,3753.962158 1711.214111,3759.270020 1715.918579,3765.051270 
	C1710.201904,3763.357666 1707.457886,3767.320557 1702.025757,3770.666992 
	C1705.516846,3761.913330 1701.922119,3755.432861 1702.008789,3748.663818 
	C1702.056763,3744.911621 1699.766846,3745.342041 1696.665771,3745.932129 
	C1696.175171,3742.221191 1698.612305,3740.043457 1701.534424,3738.260498 
	C1709.479004,3733.412354 1713.116211,3727.272705 1708.701050,3717.877930 
	C1707.350586,3715.004150 1709.813721,3711.605713 1709.093872,3708.187500 
	C1708.700684,3706.320801 1711.249023,3706.148438 1712.883667,3706.053711 
	C1715.520142,3705.900635 1718.278931,3706.218994 1720.455811,3704.276123 
	C1721.903320,3702.984863 1723.994873,3701.560059 1722.808594,3699.401367 
	C1721.678589,3697.345215 1719.526001,3695.908447 1716.964111,3697.292236 
	C1715.087402,3698.305664 1712.887207,3701.554688 1711.362183,3698.486328 
	C1709.695068,3695.132080 1713.672363,3694.697021 1715.973022,3693.690430 
	C1718.800171,3692.453125 1722.254272,3690.401611 1721.092651,3687.332764 
	C1718.959839,3681.697754 1722.465698,3678.608154 1725.465820,3674.419678 
z"
        />
        <path
          fill="#B6AFA3"
          opacity="1.000000"
          stroke="none"
          d="
M1711.197876,3485.444580 
	C1709.006348,3473.082520 1710.245850,3471.047607 1721.009399,3469.267334 
	C1720.517334,3478.816650 1715.456665,3487.185059 1715.240967,3496.900391 
	C1707.801514,3492.176758 1707.801514,3492.176758 1711.197876,3485.444580 
z"
        />
        <path
          fill="#D8D1C0"
          opacity="1.000000"
          stroke="none"
          d="
M1646.002319,3605.298828 
	C1641.792236,3605.749512 1638.432861,3607.458008 1635.446045,3610.343262 
	C1634.005493,3611.734131 1632.356201,3611.162842 1631.260010,3609.650635 
	C1629.930542,3607.816895 1629.808838,3605.325928 1632.007446,3604.735596 
	C1635.297852,3603.852539 1637.785278,3601.849854 1641.295288,3600.153320 
	C1644.832642,3600.224121 1646.012817,3601.886475 1646.002319,3605.298828 
z"
        />
        <path
          fill="#D8D1C0"
          opacity="1.000000"
          stroke="none"
          d="
M1646.670288,3553.985107 
	C1645.725708,3553.389893 1645.318848,3552.815186 1645.197754,3552.185547 
	C1644.279297,3547.402588 1646.428711,3542.432373 1649.410645,3543.032959 
	C1653.699707,3543.896973 1651.330566,3547.875732 1652.001953,3551.234375 
	C1650.881226,3553.687256 1649.166992,3553.966309 1646.670288,3553.985107 
z"
        />
        <path
          fill="#D8D1C0"
          opacity="1.000000"
          stroke="none"
          d="
M1644.416382,3845.336426 
	C1640.604370,3841.122070 1642.718262,3838.111328 1646.188965,3834.090332 
	C1647.903931,3838.822998 1650.065552,3842.364014 1644.416382,3845.336426 
z"
        />
        <path
          fill="#D1C9BA"
          opacity="1.000000"
          stroke="none"
          d="
M1707.654541,3644.603516 
	C1709.925171,3646.501953 1710.212402,3648.142578 1707.758423,3649.332764 
	C1706.834473,3649.780273 1705.730957,3649.549072 1705.159424,3648.650635 
	C1703.743164,3646.424561 1704.605591,3645.064941 1707.654541,3644.603516 
z"
        />
        <path
          fill="#D0C8B8"
          opacity="1.000000"
          stroke="none"
          d="
M1727.516357,3438.438965 
	C1727.391479,3440.379395 1726.741577,3441.383789 1725.219727,3440.663574 
	C1724.280762,3440.218994 1724.130249,3439.085693 1724.571411,3438.201904 
	C1725.268188,3436.806396 1726.295166,3436.643799 1727.516357,3438.438965 
z"
        />
        <path
          fill="#D1C9BA"
          opacity="1.000000"
          stroke="none"
          d="
M1696.958618,3648.927246 
	C1697.044312,3648.881104 1696.872925,3648.973389 1696.958618,3648.927246 
z"
        />
        <path
          fill="#CBC6BA"
          opacity="1.000000"
          stroke="none"
          d="
M1965.837402,3034.691895 
	C1966.019897,3033.119385 1966.018188,3032.230713 1966.013184,3030.676025 
	C1975.412109,3024.880615 1978.445801,3016.565674 1978.803345,3006.322998 
	C1979.020996,3000.091309 1981.955322,2994.299072 1984.635864,2988.653320 
	C1987.332275,2982.973877 1984.830322,2974.849365 1978.452271,2970.551758 
	C1977.224365,2969.405029 1976.498901,2968.437744 1976.689819,2967.532227 
	C1978.378296,2959.526611 1974.246704,2957.807861 1967.434448,2958.632080 
	C1965.809082,2958.829102 1964.162842,2958.220703 1962.008057,2957.194580 
	C1957.221924,2952.331299 1960.292847,2948.393555 1963.118774,2945.453857 
	C1972.858032,2935.322266 1976.870239,2921.239746 1987.098145,2911.467773 
	C1989.504395,2909.168457 1989.440796,2905.336182 1990.630005,2902.252441 
	C1992.110840,2898.412598 1993.947754,2894.928223 1999.429688,2894.488770 
	C2003.872437,2895.596680 2003.659302,2898.968994 2004.564697,2901.724365 
	C2011.479492,2922.768799 2017.744507,2944.057373 2027.792603,2963.933838 
	C2017.690552,2943.516846 2010.856323,2921.880859 2004.364258,2900.154297 
	C2002.318115,2893.306396 1997.234985,2887.230225 1999.011719,2878.709961 
	C1999.742065,2877.663818 2000.145752,2877.330566 2001.298218,2876.760742 
	C2005.038818,2876.285400 2006.889282,2877.819824 2008.312134,2881.060059 
	C2009.186279,2883.622803 2008.541748,2886.343994 2011.281494,2886.907959 
	C2012.257324,2887.108887 2012.167114,2885.639160 2012.071289,2884.147949 
	C2011.877441,2882.245605 2011.955078,2880.972656 2012.364746,2879.083496 
	C2013.737915,2876.223877 2014.987305,2873.997559 2013.728027,2870.655029 
	C2013.095947,2867.059570 2013.160278,2864.151855 2014.010986,2860.528809 
	C2018.003174,2854.942871 2016.970093,2849.215820 2017.128784,2842.937988 
	C2017.090698,2840.639648 2017.176880,2839.116211 2017.548584,2836.864746 
	C2020.664307,2828.156982 2018.135864,2819.610107 2020.291748,2811.486084 
	C2021.075195,2808.533936 2020.696655,2805.277344 2024.697510,2804.888672 
	C2028.420288,2804.527344 2029.841553,2807.391602 2031.017578,2810.205322 
	C2033.723145,2816.678467 2035.890503,2823.251465 2035.392700,2830.464355 
	C2034.889404,2837.759033 2034.543945,2845.100098 2035.881714,2852.335449 
	C2036.658936,2856.538086 2034.066772,2863.162354 2043.259277,2861.806396 
	C2046.154053,2861.379150 2046.828491,2866.549805 2045.792236,2869.283936 
	C2043.081909,2876.435059 2044.068359,2883.667236 2044.331055,2890.922852 
	C2044.355103,2891.585693 2044.450195,2892.293213 2044.270508,2892.909668 
	C2039.413452,2909.582275 2035.693604,2926.392090 2039.139771,2943.025879 
	C2044.199707,2939.954834 2045.851074,2933.684326 2048.204102,2926.935791 
	C2053.624756,2911.390625 2057.210205,2894.948730 2067.460938,2881.323975 
	C2072.552734,2874.556396 2073.558350,2865.964600 2076.233887,2858.134521 
	C2077.622070,2854.071777 2078.059082,2849.652832 2081.492188,2845.664551 
	C2089.806885,2844.287598 2091.120117,2849.818359 2092.416992,2856.203613 
	C2092.247070,2860.166504 2091.288574,2863.160156 2090.350586,2866.914062 
	C2085.207520,2876.600098 2089.134766,2886.997559 2085.579102,2896.275635 
	C2084.837646,2898.210693 2085.617920,2900.848877 2084.455078,2902.985840 
	C2085.199707,2903.295898 2081.344727,2905.714600 2084.935303,2904.401123 
	C2092.344482,2901.690186 2092.411865,2906.516113 2091.268799,2910.860840 
	C2088.833740,2920.115967 2087.387939,2930.132324 2081.117676,2937.609863 
	C2076.307617,2943.346191 2075.367432,2948.853760 2078.499268,2954.823975 
	C2083.208252,2963.800537 2084.198730,2972.220947 2077.819824,2980.750732 
	C2077.086670,2981.731201 2077.125000,2983.281006 2077.335205,2984.802246 
	C2079.329590,2985.805420 2079.865479,2983.622803 2080.993408,2982.911865 
	C2083.317871,2981.446533 2085.675293,2979.835205 2088.143555,2982.210693 
	C2090.411133,2984.393311 2089.576172,2987.086914 2088.565430,2989.635986 
	C2087.054199,2993.446289 2081.114502,2997.491211 2090.130127,3000.380615 
	C2092.948242,3001.283936 2090.887207,3005.803955 2090.341797,3009.353760 
	C2089.718750,3010.855957 2089.231689,3011.638916 2088.188965,3012.857422 
	C2084.582275,3015.310791 2082.741455,3018.093262 2082.201660,3022.470703 
	C2080.510254,3027.171387 2077.932861,3030.542480 2074.715332,3034.317871 
	C2073.254883,3035.119629 2072.327881,3035.273926 2070.712402,3034.943359 
	C2066.311035,3030.440918 2070.605957,3026.060547 2069.812012,3021.292725 
	C2066.545898,3021.570312 2063.644043,3025.115234 2059.580566,3022.339355 
	C2057.459717,3018.957031 2058.130127,3016.116455 2059.518555,3012.607910 
	C2062.484131,3007.570068 2062.915527,3002.735596 2063.263916,2997.730225 
	C2063.675781,2991.817139 2062.937012,2987.476318 2055.958008,2986.104980 
	C2048.669678,2984.672852 2047.062744,2979.304199 2050.035645,2970.499512 
	C2042.170166,2976.630127 2039.651001,2981.474365 2044.897949,2987.782715 
	C2047.844727,2991.325439 2047.467529,2994.272461 2043.660156,2997.525879 
	C2041.676514,2998.405518 2040.249268,2998.787109 2039.291626,2996.728516 
	C2039.261597,2996.455322 2039.196777,2996.583252 2039.158447,2996.990479 
	C2039.781738,2999.457520 2039.299194,3001.208984 2037.108765,3002.984375 
	C2028.743164,3002.671631 2025.384644,3007.614014 2022.825928,3013.863770 
	C2019.699463,3021.500000 2017.146362,3029.576172 2008.809082,3034.138184 
	C2004.011841,3031.745605 2008.492432,3027.798828 2006.092773,3024.485596 
	C2000.794678,3028.730957 1994.084229,3031.442139 1995.922607,3040.279541 
	C1996.476196,3042.940674 1995.516846,3045.664795 1993.969238,3047.857422 
	C1986.141357,3058.947754 1983.394043,3072.208496 1980.116089,3084.777344 
	C1977.933105,3093.148438 1974.013916,3101.165039 1973.362061,3110.120850 
	C1973.024658,3114.756348 1971.343506,3119.837402 1966.633423,3122.961670 
	C1963.766113,3124.863525 1962.351440,3128.173584 1962.173584,3131.554688 
	C1961.886719,3137.006104 1959.765137,3141.898682 1957.970093,3146.854492 
	C1955.108398,3154.755371 1953.241821,3162.716064 1953.969849,3171.185791 
	C1954.496704,3177.313721 1953.308472,3182.504150 1946.217773,3184.246094 
	C1944.653076,3184.630615 1942.416992,3185.915771 1943.562744,3187.589111 
	C1946.562500,3191.970459 1944.294556,3195.792236 1943.140991,3199.808594 
	C1942.636353,3201.565186 1942.555298,3203.215332 1944.839844,3203.562012 
	C1946.624512,3203.832764 1947.900269,3202.857910 1948.963867,3201.386719 
	C1950.440430,3199.344727 1947.293091,3196.797119 1950.104126,3194.703613 
	C1954.513794,3196.421387 1955.474731,3201.149170 1952.744507,3203.135986 
	C1946.169434,3207.919922 1942.233398,3216.223877 1932.672363,3217.958252 
	C1930.846191,3216.899902 1930.405396,3215.588135 1930.586548,3213.402344 
	C1930.922485,3210.045898 1936.950195,3209.579346 1932.980469,3205.021484 
	C1928.603638,3198.941162 1930.357788,3193.445557 1933.870850,3188.887939 
	C1940.613281,3180.139893 1939.662231,3170.480225 1938.513672,3160.587402 
	C1936.189697,3163.839600 1935.626953,3167.539307 1935.638428,3171.384277 
	C1935.645264,3173.699463 1936.651123,3176.654785 1935.285522,3178.213379 
	C1927.806030,3186.750977 1931.144531,3199.090576 1924.481812,3207.879639 
	C1922.962036,3209.884521 1921.015137,3213.173584 1922.690674,3215.234863 
	C1928.998413,3222.993408 1923.046753,3227.882812 1919.297241,3233.550293 
	C1916.577026,3237.662109 1912.979980,3241.305420 1911.609375,3247.006836 
	C1910.433716,3249.720215 1908.698730,3251.386719 1908.190674,3254.420410 
	C1907.155518,3257.769287 1906.849609,3260.661377 1904.485107,3263.467773 
	C1900.115845,3267.508789 1896.201172,3270.891113 1894.421021,3276.202393 
	C1893.611572,3278.616699 1891.695923,3280.727051 1890.027466,3282.767090 
	C1888.566772,3284.553223 1886.560669,3286.715576 1884.168579,3285.286377 
	C1880.914917,3283.342529 1884.330444,3281.469971 1884.892212,3279.544678 
	C1886.977295,3272.398438 1886.155273,3265.136230 1885.439087,3257.927734 
	C1885.231445,3255.837646 1883.396606,3254.450439 1881.181641,3254.474609 
	C1879.262085,3254.495361 1878.386597,3255.950928 1878.095581,3257.743408 
	C1878.042358,3258.070312 1877.996460,3258.399170 1877.933594,3258.724121 
	C1875.713135,3270.218018 1870.063599,3280.065430 1862.623779,3289.680664 
	C1859.674805,3288.032715 1859.240723,3285.221924 1860.250366,3282.773193 
	C1865.252197,3270.641602 1869.123657,3257.967529 1877.503418,3246.820068 
	C1885.695435,3247.692383 1890.401001,3245.429688 1889.821045,3236.717285 
	C1886.470703,3239.164307 1885.586304,3244.900391 1879.464233,3243.919922 
	C1877.724121,3229.479736 1881.445923,3216.987061 1889.933472,3205.843994 
	C1898.335571,3194.812988 1905.078735,3182.817627 1908.033081,3169.214111 
	C1909.047363,3164.543945 1910.358521,3161.000977 1914.697266,3159.039795 
	C1923.291260,3155.155273 1924.642944,3148.203125 1923.005981,3139.911621 
	C1921.648071,3133.033691 1920.146362,3126.148682 1924.105957,3118.786133 
	C1924.816650,3117.747559 1925.141113,3117.365234 1926.042236,3116.513428 
	C1927.735718,3115.322754 1929.773804,3115.031250 1929.938843,3114.000000 
	C1931.856201,3102.007568 1942.608398,3093.621338 1943.269775,3081.185059 
	C1943.547363,3075.966553 1948.185303,3073.471680 1950.369507,3069.428955 
	C1956.304443,3058.443604 1959.767700,3046.368652 1965.837402,3034.691895 
z"
        />
        <path
          fill="#C4BDAE"
          opacity="1.000000"
          stroke="none"
          d="
M1880.359741,3247.356934 
	C1879.012695,3248.339600 1877.417847,3248.560303 1877.295776,3249.108887 
	C1875.550537,3256.949219 1870.295776,3263.421875 1868.389771,3271.008789 
	C1866.851440,3277.133057 1860.809448,3281.467041 1862.015625,3289.229004 
	C1861.728516,3292.998291 1864.652100,3293.429199 1866.194580,3295.210693 
	C1861.669189,3297.049561 1858.640015,3299.651855 1857.652466,3304.986084 
	C1856.712402,3310.064453 1854.044312,3314.738037 1851.191528,3319.292236 
	C1849.480347,3322.024170 1845.582275,3324.290527 1847.105347,3328.772949 
	C1847.953735,3331.270020 1845.411865,3331.940430 1843.884033,3333.096191 
	C1841.517456,3334.886230 1839.243164,3336.797607 1836.913086,3338.636475 
	C1834.815186,3340.291748 1834.516602,3341.923828 1835.613892,3344.630615 
	C1838.401001,3351.505127 1837.676025,3353.013916 1831.281494,3355.864502 
	C1828.614136,3357.053467 1825.529541,3357.426025 1823.717773,3360.144531 
	C1824.010498,3362.619385 1826.241089,3361.683838 1827.495117,3362.392578 
	C1830.446289,3364.060303 1830.404053,3366.626709 1827.544556,3367.387451 
	C1823.059326,3368.580811 1821.626953,3371.022461 1821.911621,3375.211426 
	C1821.955200,3375.853760 1821.895508,3376.980713 1821.583984,3377.099854 
	C1814.300415,3379.883789 1815.564331,3388.072021 1811.551025,3392.957031 
	C1809.661133,3395.257080 1807.063721,3395.595459 1804.398804,3397.440430 
	C1802.919800,3399.052002 1801.863525,3400.124512 1800.428589,3401.615967 
	C1800.041260,3402.026611 1800.000000,3402.000000 1800.014648,3402.019531 
	C1796.092407,3406.276123 1797.357056,3412.967041 1792.494385,3417.501953 
	C1792.014648,3418.009766 1792.000000,3418.000000 1792.004883,3418.009766 
	C1787.952026,3417.538330 1784.817871,3419.369873 1782.668091,3422.494385 
	C1778.920288,3427.941895 1772.145752,3431.869629 1773.773682,3440.165283 
	C1774.185791,3442.264893 1772.386841,3444.285156 1770.443970,3444.532471 
	C1763.796753,3445.378906 1759.379150,3449.875244 1754.376343,3453.495117 
	C1753.668335,3454.007568 1752.457642,3453.825684 1750.737427,3453.977783 
	C1750.844604,3447.763916 1751.385986,3441.472412 1752.677979,3435.339355 
	C1753.292725,3432.421631 1752.646362,3430.262695 1749.979858,3427.402344 
	C1759.367554,3425.653564 1763.557739,3420.377197 1765.309570,3412.057617 
	C1766.199219,3407.832520 1768.725708,3403.811523 1771.971436,3400.138672 
	C1775.557861,3396.081299 1777.810791,3390.585693 1779.353394,3385.121826 
	C1780.447021,3381.247559 1781.679443,3377.397949 1784.487793,3374.419922 
	C1788.564209,3370.096924 1790.940918,3365.328369 1790.114258,3359.172607 
	C1789.910034,3357.652832 1790.237061,3355.401611 1791.235596,3354.487061 
	C1798.193237,3348.117920 1799.387573,3338.802246 1803.619751,3331.067383 
	C1804.087524,3330.212646 1804.593872,3329.378662 1805.645630,3327.563232 
	C1809.047852,3332.917236 1808.337036,3336.922119 1806.495239,3340.833740 
	C1801.579346,3351.273926 1801.168579,3363.526123 1793.071411,3372.770508 
	C1790.380371,3375.843018 1793.362183,3381.462402 1787.943359,3383.844482 
	C1792.212891,3386.585938 1794.539062,3386.011475 1796.144409,3383.269287 
	C1797.823608,3380.401367 1800.321289,3377.430908 1800.509033,3374.385010 
	C1800.982666,3366.696045 1805.707275,3361.756592 1810.380371,3356.773193 
	C1816.344116,3350.413574 1815.027954,3342.524170 1813.231567,3335.979004 
	C1810.494507,3326.007080 1813.753540,3319.239258 1820.439575,3312.889160 
	C1821.395508,3311.981201 1822.935181,3311.267090 1823.260742,3310.180176 
	C1827.123047,3297.289307 1837.222900,3287.629639 1841.400635,3274.947021 
	C1842.278320,3272.282959 1843.456543,3269.989014 1846.025879,3268.791016 
	C1851.532104,3266.223145 1853.782349,3261.091553 1856.657349,3256.251709 
	C1861.122803,3248.734375 1863.238159,3239.976074 1869.013062,3233.174316 
	C1869.414307,3232.701904 1869.778809,3231.887695 1869.665039,3231.334473 
	C1868.109863,3223.775146 1873.788818,3219.331299 1877.042603,3213.816895 
	C1879.544800,3209.576416 1882.082642,3205.518799 1882.518677,3200.168945 
	C1882.975708,3194.561523 1887.325928,3190.346680 1889.722656,3185.704346 
	C1893.217773,3185.802734 1890.727661,3191.779297 1895.124268,3190.111084 
	C1897.277832,3189.294189 1897.923340,3187.458984 1897.932495,3185.301025 
	C1897.945435,3182.278809 1897.262451,3179.812500 1893.458130,3180.456055 
	C1889.457642,3181.133057 1889.865967,3178.270264 1890.108154,3176.104004 
	C1890.581421,3171.868408 1892.660034,3175.302002 1894.044922,3175.481445 
	C1897.986694,3175.991943 1899.618652,3173.647217 1899.813599,3170.303467 
	C1900.084473,3165.656494 1900.910522,3160.696289 1899.677368,3156.392334 
	C1898.053467,3150.724365 1900.099854,3145.279297 1899.076050,3139.811768 
	C1898.958496,3139.183838 1898.992432,3137.953857 1899.077393,3137.942627 
	C1906.213867,3137.022705 1904.952637,3127.269043 1911.778564,3125.966064 
	C1916.817627,3125.003906 1918.147095,3121.723145 1916.637329,3117.033447 
	C1915.169678,3112.473877 1916.288208,3108.515625 1919.483765,3105.061523 
	C1920.149658,3104.341309 1920.924072,3102.970947 1921.511719,3103.041260 
	C1930.769165,3104.151855 1929.140625,3096.882080 1929.873779,3091.893066 
	C1930.326904,3088.810059 1931.162842,3086.199463 1932.901611,3083.640869 
	C1934.182373,3081.756104 1935.161499,3079.525391 1935.711304,3077.308838 
	C1938.179810,3067.356445 1942.402466,3058.508789 1949.057739,3050.476074 
	C1952.357544,3046.493164 1954.155518,3041.688232 1959.988037,3040.284912 
	C1962.442871,3039.694824 1961.495361,3035.148682 1965.316284,3034.138916 
	C1968.730591,3036.298584 1967.975586,3038.678467 1965.989624,3040.849365 
	C1963.701538,3043.350830 1961.830200,3045.885010 1961.984253,3049.545166 
	C1962.213623,3054.995605 1961.802368,3060.169678 1955.748657,3062.824707 
	C1954.137817,3063.531494 1954.148926,3065.545166 1954.027222,3067.207520 
	C1953.906860,3068.848633 1954.218018,3071.614746 1953.471313,3071.949463 
	C1944.666504,3075.896484 1946.977661,3085.258301 1943.589600,3091.777100 
	C1941.383301,3096.022461 1941.243042,3101.338867 1935.889526,3103.732422 
	C1934.011108,3104.572510 1933.933716,3107.050781 1934.678101,3109.072754 
	C1936.589600,3114.266357 1933.294067,3116.227783 1928.643555,3117.881348 
	C1927.309692,3117.496094 1926.945312,3116.266357 1925.912109,3117.606689 
	C1923.429932,3126.201904 1924.742310,3134.299316 1926.439575,3142.436768 
	C1928.366211,3151.674805 1924.905151,3158.340088 1916.133911,3161.593506 
	C1912.106689,3163.087158 1909.639404,3164.513428 1911.425781,3169.409668 
	C1913.074097,3173.927002 1910.811401,3177.369141 1908.200195,3181.208252 
	C1904.434692,3186.744873 1898.718018,3191.643311 1899.847656,3199.636963 
	C1900.360840,3203.269775 1896.993896,3204.835205 1894.535889,3206.224609 
	C1888.874023,3209.424316 1885.538208,3213.368896 1885.669800,3220.455322 
	C1885.739380,3224.203613 1883.670898,3228.129883 1882.031860,3231.731934 
	C1880.456665,3235.193359 1879.811890,3238.661133 1880.021240,3243.198730 
	C1880.788330,3244.788330 1881.044800,3245.696533 1880.359741,3247.356934 
z"
        />
        <path
          fill="#E5DDC9"
          opacity="1.000000"
          stroke="none"
          d="
M1806.502197,3432.391357 
	C1806.237671,3428.243408 1808.604736,3426.067627 1811.461182,3424.345947 
	C1814.294678,3422.637695 1816.947266,3420.850342 1817.888916,3416.696289 
	C1820.229004,3410.819824 1817.888672,3405.779297 1817.134888,3400.793213 
	C1816.566650,3397.033936 1817.281250,3394.482178 1820.939941,3394.317139 
	C1825.502808,3394.112061 1828.589478,3389.115234 1834.041016,3391.328857 
	C1836.934570,3392.503418 1838.444092,3389.289307 1836.926147,3386.286377 
	C1835.103882,3382.681641 1829.822388,3382.468506 1828.864990,3378.355469 
	C1829.988281,3375.418213 1832.316772,3376.282227 1834.137207,3375.931396 
	C1835.497437,3375.668945 1837.448486,3374.696777 1836.735962,3373.753418 
	C1832.630493,3368.317383 1838.408936,3367.214355 1840.467407,3364.505615 
	C1840.867798,3363.978760 1841.329834,3363.484131 1841.828613,3363.048828 
	C1845.218872,3360.091309 1842.539062,3353.031982 1849.041016,3352.240967 
	C1853.242554,3351.729736 1857.283936,3351.110840 1861.454102,3349.872559 
	C1867.156860,3348.178955 1873.999634,3347.955811 1876.962769,3355.692871 
	C1871.810303,3359.094482 1871.810425,3359.094482 1871.970459,3366.895996 
	C1872.104736,3373.437012 1870.027222,3375.434326 1864.630981,3372.224609 
	C1858.414185,3368.527344 1856.251831,3377.223145 1850.815063,3374.345459 
	C1855.328857,3385.306396 1864.690796,3378.682861 1871.501953,3381.826660 
	C1869.989014,3385.719482 1869.620483,3390.455566 1863.220947,3388.924561 
	C1861.012573,3388.396240 1859.560181,3390.619141 1860.362915,3391.744385 
	C1864.173706,3397.087158 1861.030396,3403.227783 1862.838623,3408.693848 
	C1863.114380,3409.526611 1863.660156,3410.270264 1864.124146,3411.135498 
	C1860.625122,3413.169434 1856.428101,3413.700439 1855.738770,3418.878906 
	C1855.050781,3424.048096 1850.466553,3425.412354 1846.026367,3425.753662 
	C1841.839722,3426.075439 1840.396484,3423.073486 1839.815063,3419.420898 
	C1839.437500,3417.049316 1837.712158,3416.120605 1835.470581,3416.290527 
	C1832.699951,3416.500244 1831.629150,3418.653076 1830.786987,3420.873291 
	C1829.982788,3422.993896 1830.321167,3424.883301 1832.672852,3425.729248 
	C1834.924683,3426.539551 1836.800903,3427.794189 1838.464355,3429.484619 
	C1843.149414,3434.245361 1843.194946,3437.279053 1838.099731,3441.836914 
	C1836.066528,3443.655518 1834.093506,3444.647705 1835.296875,3448.226074 
	C1837.505859,3454.795654 1833.630615,3459.303467 1826.990845,3458.623779 
	C1825.675781,3458.489258 1824.371460,3458.102539 1823.061401,3458.098633 
	C1821.027222,3458.092773 1818.784668,3458.325195 1818.615356,3460.939941 
	C1818.487915,3462.907227 1820.095093,3463.644775 1821.911621,3463.887695 
	C1825.085693,3464.311279 1829.917236,3463.946045 1828.868408,3468.621826 
	C1827.942017,3472.750977 1823.245850,3473.966064 1819.065186,3473.752686 
	C1816.798706,3473.637451 1814.607178,3472.459717 1812.328491,3472.081055 
	C1808.393066,3471.427246 1808.044922,3469.631348 1810.215454,3466.666748 
	C1810.983276,3465.618652 1811.802734,3464.454346 1810.041504,3462.788330 
	C1805.816895,3463.876465 1804.125977,3466.913330 1803.941406,3471.261475 
	C1803.855225,3473.292969 1803.446777,3475.753906 1800.816528,3475.326172 
	C1797.423706,3474.774170 1798.276855,3472.098633 1799.070679,3469.856201 
	C1800.104492,3466.935791 1800.494263,3463.917480 1799.299805,3461.075439 
	C1797.827515,3457.572266 1798.443481,3454.772705 1800.765015,3451.877686 
	C1803.597168,3448.345459 1804.353027,3444.094727 1804.003418,3438.821533 
	C1804.616333,3437.752930 1805.121582,3437.362061 1805.756592,3436.413574 
	C1807.035522,3435.155273 1807.349243,3434.109863 1806.502197,3432.391357 
z"
        />
        <path
          fill="#DFD9CC"
          opacity="1.000000"
          stroke="none"
          d="
M1904.655762,3264.119141 
	C1904.223511,3261.006592 1903.413574,3257.905029 1905.493164,3254.533203 
	C1907.728516,3252.702148 1907.910767,3250.469238 1909.577515,3248.448486 
	C1909.011475,3244.172852 1908.431030,3241.258057 1912.533813,3237.780029 
	C1916.804199,3234.160156 1919.135254,3228.240967 1922.893188,3223.703613 
	C1924.062012,3222.292725 1924.506836,3218.819580 1921.883301,3219.028320 
	C1911.838867,3219.827881 1918.638184,3215.098389 1919.532227,3212.463379 
	C1920.999512,3208.140137 1921.635742,3203.440186 1925.958740,3200.495117 
	C1928.446045,3198.800781 1927.165527,3196.322754 1926.070312,3194.225830 
	C1923.803223,3189.885010 1922.980591,3186.187988 1928.877808,3183.844971 
	C1932.851318,3182.265869 1933.799805,3178.187500 1933.005005,3174.687012 
	C1931.177856,3166.639648 1934.615967,3160.056152 1937.119385,3153.076904 
	C1940.704224,3153.553711 1941.420288,3156.137451 1941.615723,3158.207031 
	C1942.683960,3169.520508 1945.192627,3180.985840 1935.776123,3190.722900 
	C1933.035645,3193.556396 1931.779663,3198.263184 1933.774414,3203.386719 
	C1936.040161,3206.571533 1935.174927,3208.760498 1932.690796,3211.370117 
	C1932.104736,3213.434814 1932.043579,3214.943359 1931.960815,3217.205566 
	C1928.726074,3220.864258 1930.454956,3224.892822 1929.609863,3228.342773 
	C1922.918823,3255.654785 1917.194458,3283.208252 1911.389771,3310.695068 
	C1908.558228,3324.103271 1902.756104,3337.185547 1903.765991,3351.461426 
	C1903.984741,3354.555664 1902.095703,3357.798828 1900.655029,3361.523438 
	C1895.881958,3361.672119 1891.673462,3362.245850 1886.793823,3363.647461 
	C1887.968018,3358.824951 1892.092529,3359.922363 1893.936523,3357.855957 
	C1894.857422,3356.823730 1895.836426,3355.946533 1895.439697,3354.465332 
	C1895.010742,3352.863525 1893.821655,3352.583252 1892.360596,3352.973877 
	C1890.489624,3353.473877 1888.539795,3354.521729 1886.578003,3351.545654 
	C1894.665649,3346.630859 1890.582031,3336.734619 1895.292603,3329.767334 
	C1897.469849,3326.547119 1890.201538,3319.826904 1884.359863,3319.235596 
	C1882.571045,3319.054443 1880.478149,3320.718262 1879.148193,3318.154785 
	C1879.755249,3315.366943 1882.345459,3316.347656 1884.009888,3315.625000 
	C1886.438599,3314.570557 1887.984253,3313.029053 1886.973022,3310.243164 
	C1886.124390,3307.905029 1886.777954,3305.761719 1887.028442,3303.443359 
	C1887.982544,3294.613525 1887.807983,3294.547852 1896.243408,3291.920166 
	C1897.953491,3291.387695 1900.043213,3290.606445 1899.459351,3288.900879 
	C1897.190308,3282.272949 1902.354126,3278.630615 1904.691406,3273.753662 
	C1906.021606,3270.977783 1909.589600,3267.645020 1904.655762,3264.119141 
z"
        />
        <path
          fill="#C4BDAE"
          opacity="1.000000"
          stroke="none"
          d="
M1781.973755,3509.257568 
	C1777.152222,3517.949951 1776.609253,3526.809326 1776.966553,3535.687256 
	C1777.247925,3542.674316 1776.889771,3548.963867 1772.296875,3555.001221 
	C1768.400635,3560.123047 1765.062378,3565.933105 1763.847656,3572.603516 
	C1763.195312,3576.185059 1761.506470,3579.673340 1765.881104,3582.259277 
	C1767.560425,3583.251709 1767.609985,3585.360352 1765.982910,3586.442139 
	C1759.782349,3590.563232 1762.639648,3595.501709 1765.550537,3599.215332 
	C1768.793091,3603.352539 1767.511597,3605.810303 1764.352173,3608.410889 
	C1758.876099,3612.918457 1756.534912,3619.496582 1756.411621,3625.719727 
	C1756.284912,3632.115967 1753.100220,3637.046387 1751.796753,3642.752930 
	C1751.451538,3644.264648 1750.487061,3645.182373 1749.036133,3644.856445 
	C1748.200073,3644.668457 1747.264404,3643.776123 1746.874146,3642.961426 
	C1745.526245,3640.147461 1745.635986,3637.008545 1746.178711,3634.126709 
	C1748.079346,3624.032715 1749.803101,3613.943359 1750.615723,3603.677490 
	C1750.848511,3600.735596 1752.017578,3597.974854 1750.682983,3594.983643 
	C1749.854736,3593.126709 1749.890625,3591.064941 1750.744629,3589.153076 
	C1759.011108,3570.648193 1758.896729,3550.524658 1761.995483,3531.022461 
	C1762.358154,3528.740479 1762.147461,3526.183350 1763.127197,3524.213379 
	C1770.728149,3508.931641 1771.744019,3493.094971 1767.868408,3476.686035 
	C1767.349243,3474.487793 1764.929932,3471.121338 1769.427734,3470.361084 
	C1773.478516,3469.677002 1775.383423,3472.188721 1775.963257,3476.100586 
	C1776.714478,3481.169189 1773.947266,3486.899170 1779.399170,3491.622559 
	C1780.062866,3492.900879 1780.048706,3493.786133 1780.033691,3495.335693 
	C1779.947998,3498.437988 1779.971313,3500.857666 1781.598999,3503.543945 
	C1781.980225,3505.512939 1781.980347,3507.010742 1781.973755,3509.257568 
z"
        />
        <path
          fill="#E5DDC9"
          opacity="1.000000"
          stroke="none"
          d="
M1861.886719,3328.632324 
	C1865.696045,3330.760254 1868.906128,3327.577393 1871.946167,3328.577637 
	C1873.222656,3330.889404 1869.734253,3332.520264 1871.007935,3334.084961 
	C1875.419800,3339.504395 1871.128296,3341.255127 1867.530518,3343.627930 
	C1865.552246,3344.932861 1863.587402,3346.270264 1861.209351,3345.940430 
	C1857.130127,3345.374512 1852.491211,3350.740723 1849.479370,3346.252930 
	C1847.778564,3343.718262 1852.061035,3339.719238 1851.055054,3335.348145 
	C1850.414795,3332.565430 1855.087524,3331.676514 1857.579224,3330.227783 
	C1858.707153,3329.572021 1859.984619,3329.173096 1861.886719,3328.632324 
z"
        />
        <path
          fill="#E6E1D0"
          opacity="1.000000"
          stroke="none"
          d="
M1861.446045,3461.343262 
	C1863.400513,3460.729980 1864.682251,3460.245361 1865.968262,3460.234131 
	C1866.861816,3460.226074 1868.340454,3460.655518 1868.562622,3461.257324 
	C1869.875854,3464.813477 1866.252686,3465.412109 1864.668213,3467.247070 
	C1862.774292,3469.440430 1860.329224,3470.901611 1857.726196,3469.086670 
	C1855.149902,3467.290283 1856.420776,3464.998291 1858.243652,3463.230469 
	C1858.937866,3462.557129 1859.922729,3462.183838 1861.446045,3461.343262 
z"
        />
        <path
          fill="#D1C9BA"
          opacity="1.000000"
          stroke="none"
          d="
M1792.805176,3417.983887 
	C1792.960083,3412.235596 1793.176147,3406.155029 1799.326904,3402.155762 
	C1799.995972,3405.325195 1800.046387,3408.614502 1799.907959,3411.896240 
	C1799.740845,3415.857422 1797.485352,3417.796387 1792.805176,3417.983887 
z"
        />
        <path
          fill="#D1C9BA"
          opacity="1.000000"
          stroke="none"
          d="
M1781.987915,3503.287109 
	C1779.135986,3502.371338 1777.033447,3500.426025 1779.539307,3496.402832 
	C1782.291382,3497.706299 1781.946045,3500.191895 1781.987915,3503.287109 
z"
        />
        <path
          fill="#D1C9BA"
          opacity="1.000000"
          stroke="none"
          d="
M1800.669678,3401.918945 
	C1800.181274,3400.231201 1800.715332,3398.707764 1803.319092,3398.065186 
	C1803.815674,3399.751709 1803.298828,3401.289062 1800.669678,3401.918945 
z"
        />
        <path
          fill="#E6E1D0"
          opacity="1.000000"
          stroke="none"
          d="
M1652.829346,3902.008789 
	C1647.630005,3901.683594 1643.059814,3901.527344 1642.139771,3895.862305 
	C1641.317505,3890.799561 1642.859619,3886.611572 1647.682495,3884.238281 
	C1651.439331,3882.389404 1653.793335,3880.252441 1654.381348,3875.451172 
	C1654.996704,3870.425293 1660.375854,3867.297852 1661.773315,3862.013916 
	C1662.378906,3859.723389 1665.553345,3859.608154 1667.539185,3861.759033 
	C1668.420898,3862.714111 1668.827026,3864.099121 1669.676758,3865.094482 
	C1672.646484,3868.572021 1674.932739,3868.636719 1677.391846,3864.787842 
	C1679.867310,3860.913086 1681.550049,3856.686035 1686.499512,3854.889648 
	C1688.371948,3854.209717 1688.402344,3851.388916 1686.976318,3849.692871 
	C1685.502563,3847.939453 1683.973145,3849.348633 1682.752686,3850.388428 
	C1680.726929,3852.114014 1678.848145,3853.851562 1675.875366,3853.876709 
	C1666.785889,3853.953125 1664.034302,3850.593262 1666.028564,3840.796875 
	C1665.915039,3835.536377 1667.570801,3831.858154 1671.505371,3828.492676 
	C1671.995117,3828.000000 1672.000000,3828.000000 1672.000000,3828.000000 
	C1676.474854,3826.340820 1679.744019,3827.050293 1682.576416,3831.536621 
	C1685.355103,3835.937744 1681.346313,3839.005615 1681.518066,3842.804688 
	C1684.313965,3845.269287 1687.090210,3841.543945 1689.984375,3842.867920 
	C1692.360352,3843.954590 1693.538574,3842.431396 1694.027588,3840.099854 
	C1695.351807,3833.784668 1699.320068,3837.031494 1702.455688,3837.955322 
	C1706.707275,3839.207764 1706.166382,3841.895020 1704.132446,3844.796631 
	C1703.167847,3846.172119 1702.455078,3847.501465 1702.762573,3849.341309 
	C1703.572632,3854.189209 1700.584351,3857.640137 1697.107422,3859.666748 
	C1692.107056,3862.581543 1689.365356,3866.720703 1688.091919,3871.910645 
	C1686.870483,3876.888916 1683.970825,3879.980957 1678.701294,3881.911865 
	C1677.995117,3882.000732 1677.415283,3881.407715 1677.710083,3881.703613 
	C1677.942993,3883.990723 1678.231812,3887.248535 1676.700073,3887.528076 
	C1668.441772,3889.034180 1667.962646,3898.572998 1661.557129,3901.530273 
	C1659.327515,3902.559326 1656.317261,3901.897461 1652.829346,3902.008789 
z"
        />
        <path
          fill="#E6E1D0"
          opacity="1.000000"
          stroke="none"
          d="
M1706.859375,3869.287598 
	C1707.275757,3870.776855 1706.754517,3871.316895 1705.598511,3871.086670 
	C1705.358398,3871.038818 1705.035156,3870.698242 1704.999146,3870.455322 
	C1704.825562,3869.287354 1705.395386,3868.798828 1706.859375,3869.287598 
z"
        />
        <path
          fill="#C7C0B1"
          opacity="1.000000"
          stroke="none"
          d="
M1671.994873,3814.004883 
	C1672.211060,3814.255371 1672.432129,3814.500977 1672.653320,3814.746582 
	C1672.504761,3814.657715 1672.356201,3814.568848 1672.107910,3814.235107 
	C1672.008057,3813.990234 1672.000000,3814.000000 1671.994873,3814.004883 
z"
        />
        <path
          fill="#DFD9CC"
          opacity="1.000000"
          stroke="none"
          d="
M2008.031494,3034.630371 
	C2013.369141,3026.586426 2018.783325,3019.427979 2020.604370,3010.035400 
	C2021.358032,3006.148438 2025.529419,3003.285889 2027.987793,2999.831543 
	C2030.295532,2996.588623 2032.219727,3001.425781 2035.243652,3001.898926 
	C2038.670288,3004.536621 2038.927368,3007.748779 2038.198975,3011.956299 
	C2037.173584,3015.210449 2035.195801,3017.034912 2033.834717,3020.050781 
	C2027.333130,3026.625244 2028.758789,3034.985107 2027.077637,3042.192383 
	C2021.950684,3064.172363 2013.026123,3084.906006 2006.988281,3106.559570 
	C2006.366821,3108.788574 2004.593018,3111.206055 2005.411255,3113.227295 
	C2009.200806,3122.590088 2003.176758,3128.109375 1997.870483,3133.992920 
	C1996.539307,3135.468506 1994.233276,3137.193604 1995.066772,3138.936768 
	C1999.321655,3147.836914 1994.153809,3156.421143 1994.444824,3165.198486 
	C1994.626221,3170.672363 2000.702759,3173.265625 1999.007202,3179.416016 
	C1998.008423,3180.732422 1997.201416,3181.211426 1995.598755,3181.653320 
	C1991.170166,3182.234375 1986.400146,3180.514648 1984.608521,3185.474854 
	C1983.190796,3189.399658 1985.238159,3192.286865 1988.669067,3194.585205 
	C1993.142578,3197.582031 1989.877930,3201.813477 1989.705688,3206.199219 
	C1989.291016,3207.297119 1989.066650,3207.714600 1988.330933,3208.629150 
	C1986.616089,3209.904785 1985.288086,3210.369385 1983.234131,3210.826416 
	C1977.588501,3213.561768 1978.222778,3217.042236 1981.304810,3220.554443 
	C1984.473022,3224.165039 1984.178955,3227.692871 1981.286987,3231.936035 
	C1980.030640,3232.969971 1979.187012,3233.348145 1977.581299,3233.632324 
	C1974.486084,3233.784912 1971.439209,3232.019775 1970.150879,3236.619141 
	C1969.130981,3239.523926 1968.462769,3241.827881 1966.804688,3244.521484 
	C1963.076294,3247.663818 1959.918457,3250.533447 1954.610840,3248.469238 
	C1953.211670,3240.950928 1955.187866,3234.034912 1956.531860,3227.401855 
	C1958.767090,3216.371338 1960.670044,3205.263916 1962.020752,3194.171387 
	C1964.375732,3174.831055 1972.941406,3156.909180 1974.129883,3137.395020 
	C1974.283325,3134.875977 1976.027222,3133.228027 1977.670898,3130.673828 
	C1982.603882,3125.498535 1987.050903,3120.978516 1984.918213,3113.564209 
	C1984.327881,3111.512451 1985.177856,3109.147949 1986.973877,3108.286377 
	C1996.878174,3103.532959 1991.786011,3098.459229 1987.740479,3092.654541 
	C1987.958984,3091.646729 1987.961182,3091.307861 1987.967041,3090.460449 
	C1992.333374,3085.878906 1994.284668,3080.974854 1993.554932,3074.494385 
	C1994.014282,3072.517822 1994.043213,3071.045166 1994.077393,3068.836426 
	C1995.716797,3066.718750 1995.906616,3064.505371 1997.503906,3062.414551 
	C1998.862427,3057.939941 2000.563843,3054.057617 2000.187866,3048.997070 
	C2002.300537,3046.238770 2001.579834,3043.153320 2003.565674,3040.489258 
	C2006.031006,3039.009277 2007.464722,3037.533203 2008.031494,3034.630371 
z"
        />
        <path
          fill="#DAD3C3"
          opacity="1.000000"
          stroke="none"
          d="
M1900.721924,3528.117676 
	C1899.083984,3528.307373 1897.501221,3528.490967 1897.385864,3529.004150 
	C1896.126465,3534.608398 1892.395508,3538.602295 1888.942383,3542.836914 
	C1888.220703,3543.722168 1888.340332,3545.293701 1888.072266,3547.289062 
	C1889.918823,3549.812988 1889.278076,3551.926025 1888.748047,3554.610840 
	C1888.279419,3556.832275 1888.493408,3558.404053 1889.140381,3560.584961 
	C1889.180298,3563.819092 1887.771973,3565.325928 1884.671143,3565.995605 
	C1879.172241,3565.978516 1874.301880,3565.572266 1869.024658,3567.997070 
	C1869.699463,3561.979980 1869.678711,3555.676514 1870.143066,3549.392822 
	C1871.498657,3531.048584 1870.081909,3512.623291 1872.027832,3494.308838 
	C1872.274536,3491.988281 1872.320923,3489.694092 1873.369385,3487.514648 
	C1874.625122,3484.903564 1876.627075,3482.846436 1879.397949,3482.968750 
	C1882.713013,3483.115234 1881.702026,3486.359131 1882.143311,3488.470215 
	C1882.346924,3489.444092 1882.394165,3490.513428 1882.847900,3491.359863 
	C1884.018311,3493.542969 1883.533081,3497.518311 1887.063232,3497.301758 
	C1890.834961,3497.070801 1889.881836,3493.404053 1889.976074,3490.939697 
	C1890.082520,3488.159668 1891.201538,3485.798096 1892.262939,3483.357666 
	C1892.789551,3482.147461 1893.900024,3480.469727 1893.478882,3479.677734 
	C1888.169067,3469.694336 1895.772217,3462.408936 1898.590454,3454.144775 
	C1898.695679,3453.835693 1899.077393,3453.546387 1899.049561,3453.277100 
	C1897.817383,3441.394287 1908.790649,3432.554443 1907.330200,3420.763184 
	C1906.375732,3413.057129 1912.341064,3408.128662 1913.964966,3400.770264 
	C1910.800415,3396.630127 1919.445190,3394.457275 1914.506592,3390.441895 
	C1914.088745,3389.077148 1914.072998,3388.207764 1914.052490,3386.686035 
	C1916.395630,3383.317139 1919.488525,3381.236084 1920.131714,3377.197021 
	C1920.867920,3372.573486 1924.188843,3371.580078 1929.032715,3372.639160 
	C1933.762817,3379.815430 1929.192505,3388.508545 1934.556641,3395.856445 
	C1934.877197,3398.650146 1934.585327,3400.630371 1933.534668,3403.210693 
	C1925.481079,3409.077881 1925.898926,3418.089600 1922.932739,3425.554932 
	C1919.574951,3434.006104 1921.439575,3443.057373 1919.325806,3451.729248 
	C1918.215454,3456.285156 1917.452515,3459.538818 1912.976562,3461.676025 
	C1908.590454,3463.769775 1906.252441,3468.265625 1908.379150,3472.508057 
	C1915.050415,3485.815430 1908.982544,3498.564941 1907.540771,3511.486328 
	C1907.030884,3516.056641 1905.694580,3520.582764 1903.985596,3525.683594 
	C1903.097290,3527.174561 1902.350098,3527.802734 1900.721924,3528.117676 
z"
        />
        <path
          fill="#EAE6D7"
          opacity="1.000000"
          stroke="none"
          d="
M1932.630371,3396.454102 
	C1932.022461,3395.712646 1932.093140,3395.275391 1931.907959,3395.075928 
	C1926.312744,3389.047119 1926.787964,3382.458252 1930.096680,3374.861084 
	C1930.274902,3373.679932 1930.419922,3373.236328 1930.956543,3372.216309 
	C1937.747070,3363.826904 1932.016602,3354.303467 1934.968750,3345.271484 
	C1934.170044,3337.662842 1937.331665,3331.613037 1939.101196,3325.299561 
	C1941.859009,3315.459961 1942.471313,3305.197021 1945.763428,3294.845215 
	C1949.957397,3291.280029 1953.412720,3296.248535 1957.152466,3294.597412 
	C1962.274414,3292.335693 1958.981689,3291.420654 1955.902100,3290.044922 
	C1952.908447,3288.749023 1951.219971,3286.963135 1949.350342,3284.302246 
	C1948.343262,3279.905273 1948.717041,3276.377930 1951.250977,3272.510254 
	C1951.702393,3271.877441 1951.820923,3270.927490 1951.882080,3270.452881 
	C1954.922607,3265.806396 1958.886597,3265.846680 1963.929443,3267.623535 
	C1968.329956,3270.053955 1971.604614,3272.573486 1973.056641,3277.593262 
	C1971.718506,3282.323975 1968.918579,3283.636230 1964.369873,3282.512207 
	C1963.098389,3281.853516 1962.813110,3280.780273 1962.020752,3281.306641 
	C1964.807129,3283.494629 1967.921387,3283.523682 1970.734619,3284.459229 
	C1979.411499,3287.343994 1980.551392,3290.568848 1974.555054,3297.107422 
	C1971.858765,3300.047852 1972.837646,3302.230469 1973.839844,3305.146729 
	C1977.411499,3315.538086 1976.742310,3317.514893 1967.063232,3322.244873 
	C1962.958008,3324.250732 1960.486816,3326.937500 1960.832153,3331.391846 
	C1962.013794,3346.633545 1962.103638,3346.626465 1949.534424,3352.911377 
	C1950.908325,3355.116943 1953.538208,3354.788086 1955.436523,3355.882324 
	C1960.547607,3358.828125 1960.207397,3363.051514 1955.680664,3364.898438 
	C1944.515991,3369.453613 1943.981201,3380.648682 1939.130859,3389.092773 
	C1937.451660,3392.016113 1938.485596,3396.751953 1932.630371,3396.454102 
z"
        />
        <path
          fill="#D1C9BA"
          opacity="1.000000"
          stroke="none"
          d="
M1948.784668,3293.548096 
	C1947.519287,3295.849121 1946.142334,3297.737061 1946.251709,3299.534912 
	C1947.023438,3312.218506 1942.405273,3323.684326 1938.765503,3335.414795 
	C1937.906738,3338.182373 1938.195679,3341.305908 1937.930176,3345.107422 
	C1932.360352,3349.546631 1934.554321,3355.366699 1933.919800,3360.305420 
	C1933.380981,3364.499023 1933.751221,3368.792969 1932.467041,3373.506348 
	C1932.024414,3374.030029 1931.075806,3374.078125 1930.602905,3374.101074 
	C1926.155640,3373.876709 1922.384277,3373.582764 1921.722534,3379.232422 
	C1921.322754,3382.646240 1918.800049,3384.793701 1914.763184,3385.927734 
	C1918.646973,3379.407227 1915.204346,3371.380371 1917.803467,3364.354980 
	C1918.573853,3362.272461 1917.908691,3359.620850 1920.186401,3358.136230 
	C1923.139771,3356.211914 1922.181641,3353.288818 1922.839478,3350.588867 
	C1924.401245,3344.179688 1924.386475,3336.882080 1929.012939,3331.640381 
	C1931.968750,3328.291016 1931.122925,3326.977539 1927.918091,3324.881104 
	C1922.518433,3321.348877 1922.572632,3321.102539 1927.377075,3315.552734 
	C1929.247314,3313.392334 1930.486450,3311.093506 1929.654541,3308.331055 
	C1927.770142,3302.073975 1930.359619,3296.983154 1932.790649,3291.422607 
	C1935.276367,3285.737305 1940.432861,3281.758057 1941.694946,3275.277344 
	C1942.494873,3271.169189 1946.921875,3272.143799 1950.922485,3271.907471 
	C1952.046265,3275.529541 1952.461670,3279.189209 1950.257568,3283.312012 
	C1951.054932,3285.095459 1951.683716,3286.378662 1951.711182,3288.645996 
	C1951.261719,3290.852783 1950.503784,3292.091309 1948.784668,3293.548096 
z"
        />
        <path
          fill="#CBC6BA"
          opacity="1.000000"
          stroke="none"
          d="
M1987.220459,3092.178223 
	C1991.017700,3095.476562 1995.374878,3097.114014 1999.432495,3099.652344 
	C1997.823608,3104.676025 1993.891724,3106.973389 1989.832886,3109.079102 
	C1986.962524,3110.568115 1985.704590,3112.436523 1988.163574,3115.205566 
	C1992.153320,3119.698975 1986.529785,3122.142822 1986.068115,3125.664062 
	C1985.542358,3129.672852 1982.715820,3130.025146 1978.791138,3130.001953 
	C1972.972778,3121.978027 1973.054565,3122.034668 1978.168823,3114.641846 
	C1981.554443,3109.748047 1984.473145,3104.626221 1984.046631,3098.281494 
	C1983.894409,3096.018799 1983.818848,3093.571533 1987.220459,3092.178223 
z"
        />
        <path
          fill="#EAE6D7"
          opacity="1.000000"
          stroke="none"
          d="
M1953.951904,3248.661377 
	C1958.446411,3249.185791 1960.441162,3243.843262 1965.255371,3244.012207 
	C1973.817017,3249.637695 1974.402588,3256.289307 1967.065674,3264.797363 
	C1962.653442,3262.741211 1958.651855,3261.088623 1954.390869,3258.547363 
	C1953.947876,3258.013672 1953.877563,3257.022217 1953.872314,3256.525391 
	C1953.919800,3255.127686 1953.943359,3254.226074 1953.899170,3252.647949 
	C1953.880371,3251.089111 1953.895996,3250.206055 1953.951904,3248.661377 
z"
        />
        <path
          fill="#DFD9CC"
          opacity="1.000000"
          stroke="none"
          d="
M1953.861084,3258.674805 
	C1955.952026,3258.742188 1957.917969,3260.302979 1959.980347,3258.045898 
	C1961.339355,3256.558594 1963.083496,3255.343994 1964.741577,3257.312012 
	C1966.175659,3259.015137 1964.257690,3260.844727 1964.086182,3263.333496 
	C1965.162476,3265.387207 1965.480957,3266.936279 1964.490234,3269.327148 
	C1960.451416,3269.430664 1956.921387,3267.589844 1952.637207,3269.911133 
	C1948.150757,3265.673096 1953.145630,3262.880127 1953.861084,3258.674805 
z"
        />
        <path
          fill="#CBC6BA"
          opacity="1.000000"
          stroke="none"
          d="
M1993.305176,3074.077637 
	C1993.618530,3077.889893 1997.691772,3079.071777 1999.420288,3082.727295 
	C1994.875977,3083.577148 1993.654053,3088.562988 1988.669189,3089.876953 
	C1987.715942,3085.870850 1990.101807,3082.065918 1988.831177,3077.742188 
	C1988.289551,3075.898926 1990.648682,3074.657715 1993.305176,3074.077637 
z"
        />
        <path
          fill="#D1C9BA"
          opacity="1.000000"
          stroke="none"
          d="
M1913.807373,3390.553223 
	C1917.109741,3390.420166 1920.114990,3390.893799 1924.293579,3391.552246 
	C1920.603516,3394.597900 1916.363525,3395.218506 1914.298096,3399.416748 
	C1911.105957,3399.657715 1909.875610,3398.346436 1911.334106,3395.467041 
	C1912.061279,3394.031982 1912.785034,3392.595459 1913.807373,3390.553223 
z"
        />
        <path
          fill="#CBC6BA"
          opacity="1.000000"
          stroke="none"
          d="
M2003.312012,3040.166992 
	C2003.731689,3042.766602 2004.985718,3046.008301 2000.892822,3047.987793 
	C2000.291260,3045.360596 1998.912842,3042.075928 2003.312012,3040.166992 
z"
        />
        <path
          fill="#CBC6BA"
          opacity="1.000000"
          stroke="none"
          d="
M1997.240601,3062.105713 
	C1997.930542,3064.265381 1998.275024,3066.708740 1994.751953,3067.942383 
	C1994.099365,3065.804932 1993.720581,3063.341553 1997.240601,3062.105713 
z"
        />
        <path
          fill="#DFD9CC"
          opacity="1.000000"
          stroke="none"
          d="
M1953.461914,3252.290771 
	C1955.266602,3252.834717 1955.957642,3253.884277 1954.320801,3255.728027 
	C1952.606689,3255.122803 1952.075684,3254.044922 1953.461914,3252.290771 
z"
        />
        <path
          fill="#C7C0B1"
          opacity="1.000000"
          stroke="none"
          d="
M1722.567139,3589.040039 
	C1722.911499,3591.771973 1721.464966,3591.843262 1719.831055,3591.250244 
	C1719.451782,3591.112793 1719.016724,3590.137207 1719.156372,3589.739990 
	C1719.665405,3588.292480 1720.880005,3588.250488 1722.567139,3589.040039 
z"
        />
        <path
          fill="#DAD3C3"
          opacity="1.000000"
          stroke="none"
          d="
M1806.070312,3432.613770 
	C1807.360352,3432.197754 1808.800171,3432.353516 1808.964111,3433.953369 
	C1809.077881,3435.063965 1808.114868,3435.546143 1806.565430,3435.903809 
	C1806.004028,3435.089600 1806.017822,3434.189209 1806.070312,3432.613770 
z"
        />
        <path
          fill="#E6E1D0"
          opacity="1.000000"
          stroke="none"
          d="
M1855.300293,3668.154053 
	C1856.659546,3670.888184 1857.477295,3673.750488 1857.935913,3676.669434 
	C1858.956543,3683.163818 1859.394287,3689.133789 1855.028809,3695.369385 
	C1852.635376,3698.787598 1848.277466,3703.900879 1852.734253,3709.724609 
	C1853.836792,3711.165039 1850.897217,3711.727783 1848.676880,3711.996582 
	C1846.084229,3706.539307 1845.902832,3701.666992 1849.362305,3696.195068 
	C1852.962891,3690.500244 1851.771118,3689.325439 1844.512939,3687.935791 
	C1843.235229,3687.690918 1841.876953,3687.506592 1840.759033,3686.912354 
	C1840.005127,3686.511475 1839.380493,3685.510986 1839.109009,3684.649414 
	C1838.771851,3683.578369 1839.620239,3682.544678 1840.553955,3682.473877 
	C1848.690674,3681.859375 1851.503784,3676.301025 1853.050293,3669.415283 
	C1853.153809,3668.954102 1854.061768,3668.673096 1855.300293,3668.154053 
z"
        />
        <path
          fill="#E6E1D0"
          opacity="1.000000"
          stroke="none"
          d="
M1833.969971,3743.976807 
	C1835.214722,3743.995605 1836.488159,3743.964111 1837.747803,3744.081299 
	C1841.507324,3744.429688 1842.054565,3746.272461 1839.858887,3749.078125 
	C1838.528442,3750.779053 1837.797852,3753.100586 1834.703735,3753.893799 
	C1833.177246,3752.130127 1831.548340,3751.014160 1830.014160,3749.733643 
	C1828.295044,3748.298584 1828.723145,3746.964355 1831.387939,3746.093262 
	C1832.394287,3745.523438 1832.860352,3745.087891 1833.674316,3744.328369 
	C1833.982178,3743.961670 1833.992310,3743.994385 1833.969971,3743.976807 
z"
        />
        <path
          fill="#E6E1D0"
          opacity="1.000000"
          stroke="none"
          d="
M1861.207031,3654.084717 
	C1862.004028,3655.553955 1861.582642,3657.365479 1862.191406,3658.710449 
	C1864.605591,3664.044189 1861.761963,3666.133301 1856.644287,3667.717285 
	C1856.897705,3663.212646 1853.458008,3657.024658 1861.207031,3654.084717 
z"
        />
        <path
          fill="#E6E1D0"
          opacity="1.000000"
          stroke="none"
          d="
M1843.438232,3720.291992 
	C1845.895386,3720.464111 1848.196289,3721.002197 1846.230225,3723.501953 
	C1844.550903,3725.637207 1842.813721,3728.160645 1838.770020,3728.026855 
	C1839.378784,3725.382568 1840.238525,3722.416504 1843.438232,3720.291992 
z"
        />
        <path
          fill="#E6E1D0"
          opacity="1.000000"
          stroke="none"
          d="
M1839.746460,3732.535645 
	C1840.626709,3732.027100 1841.307007,3731.922119 1841.944702,3732.042725 
	C1843.672363,3732.368652 1846.677734,3731.332764 1846.441162,3734.186768 
	C1846.233765,3736.689453 1843.315674,3735.691162 1841.510742,3735.963623 
	C1840.864258,3736.061035 1840.191406,3735.982666 1838.753418,3735.975586 
	C1838.493164,3734.991455 1839.010010,3734.016846 1839.746460,3732.535645 
z"
        />
        <path
          fill="#E6E1D0"
          opacity="1.000000"
          stroke="none"
          d="
M1847.375732,3712.104736 
	C1847.813965,3713.775635 1847.279053,3715.300781 1844.702148,3715.939453 
	C1844.180786,3714.229492 1844.709106,3712.696533 1847.375732,3712.104736 
z"
        />
        <path
          fill="#F2EBDD"
          opacity="1.000000"
          stroke="none"
          d="
M1833.542725,3744.186523 
	C1833.760254,3744.602539 1833.377686,3745.125977 1832.401367,3745.767090 
	C1832.190918,3745.360596 1832.569092,3744.830322 1833.542725,3744.186523 
z"
        />
        <path
          fill="#F2EBDD"
          opacity="1.000000"
          stroke="none"
          d="
M1886.068604,3556.585938 
	C1886.637573,3553.801270 1887.252930,3551.597900 1887.970215,3548.711914 
	C1892.954590,3548.564697 1897.047485,3546.431641 1901.272949,3544.410889 
	C1906.671265,3541.829102 1908.025513,3542.577393 1909.706543,3548.271484 
	C1909.985352,3549.215820 1909.864746,3550.287109 1910.188965,3551.207764 
	C1911.681274,3555.446777 1911.463745,3558.432129 1905.762329,3558.052246 
	C1904.495117,3557.967773 1903.194092,3558.393555 1901.974243,3558.574707 
	C1901.974243,3563.978027 1902.180908,3569.135986 1901.915771,3574.269531 
	C1901.653320,3579.353271 1900.068970,3580.039795 1891.955933,3581.048584 
	C1887.863770,3581.557617 1886.662842,3579.542480 1886.007324,3576.235107 
	C1885.430420,3573.324219 1884.700806,3570.444092 1884.014160,3566.765381 
	C1884.919800,3564.186035 1887.325195,3563.572998 1887.792725,3560.738525 
	C1887.109375,3559.255615 1886.403687,3558.335205 1886.068604,3556.585938 
z"
        />
        <path
          fill="#F5F1E5"
          opacity="1.000000"
          stroke="none"
          d="
M1909.384766,3529.496094 
	C1910.063110,3532.363770 1909.844238,3534.656738 1908.286621,3537.362793 
	C1906.192017,3538.506836 1904.406250,3539.172119 1902.560669,3539.487549 
	C1898.739502,3540.141113 1898.013184,3538.395996 1899.743408,3534.583008 
	C1902.341064,3531.303223 1903.870850,3527.185547 1909.384766,3529.496094 
z"
        />
        <path
          fill="#F2EBDD"
          opacity="1.000000"
          stroke="none"
          d="
M1909.997559,3530.004883 
	C1906.214844,3529.171387 1904.214233,3532.443848 1900.678223,3533.868896 
	C1899.996582,3532.485840 1899.998047,3530.981445 1899.992432,3528.723877 
	C1900.597046,3527.728027 1901.101074,3527.343994 1901.731689,3526.399658 
	C1903.184937,3524.903564 1904.591797,3524.343506 1906.994873,3524.624023 
	C1909.225952,3525.617920 1910.094360,3526.857178 1910.220703,3529.235840 
	C1910.004883,3529.990234 1910.000000,3530.000000 1909.997559,3530.004883 
z"
        />
        <path
          fill="#DAD3C3"
          opacity="1.000000"
          stroke="none"
          d="
M1671.229980,3828.018555 
	C1671.504150,3832.228271 1669.190552,3835.655762 1666.565674,3839.596924 
	C1662.652588,3832.219482 1663.180786,3830.786865 1671.229980,3828.018555 
z"
        />
        <path
          fill="#B0714F"
          opacity="1.000000"
          stroke="none"
          d="
M2348.496094,3680.447998 
	C2347.449219,3670.118164 2349.408936,3660.110596 2346.529541,3650.333740 
	C2345.911133,3648.234375 2346.587646,3646.092529 2348.278564,3644.974854 
	C2352.827148,3641.968750 2353.154541,3635.605469 2358.315430,3633.026123 
	C2360.677002,3631.845703 2360.157471,3628.866943 2359.948975,3626.647217 
	C2359.070312,3617.293213 2362.629150,3608.326904 2362.124268,3598.994385 
	C2361.800781,3593.014893 2367.078369,3589.897949 2370.023682,3585.676270 
	C2371.685059,3583.294189 2374.579102,3584.882568 2376.157959,3586.462646 
	C2381.482178,3591.789795 2385.353760,3597.841553 2383.652344,3605.940918 
	C2383.247803,3607.866699 2381.778320,3610.503418 2382.541260,3611.593018 
	C2388.932373,3620.722656 2380.956055,3622.567383 2375.008301,3625.948486 
	C2371.123779,3632.979492 2372.338135,3640.859131 2369.274902,3648.556152 
	C2363.060547,3660.886963 2361.718262,3673.778809 2359.000488,3686.281006 
	C2357.177490,3694.666016 2354.869141,3702.920898 2352.897949,3711.262939 
	C2352.064209,3714.791748 2350.128662,3718.240967 2346.410645,3717.456299 
	C2339.979248,3716.099365 2335.061523,3720.061279 2328.692871,3720.477051 
	C2328.023682,3718.398438 2328.292236,3716.676270 2327.923584,3715.104004 
	C2326.881104,3710.659424 2328.444824,3708.805908 2332.589600,3706.296143 
	C2341.815186,3700.709717 2347.429199,3691.896973 2348.496094,3680.447998 
z"
        />
        <path
          fill="#935F47"
          opacity="1.000000"
          stroke="none"
          d="
M2327.966797,3720.686768 
	C2328.696289,3719.919189 2329.492676,3719.982178 2329.973877,3719.623047 
	C2334.121826,3716.525879 2340.440674,3714.796875 2345.614990,3715.489014 
	C2348.734375,3715.906250 2349.474365,3713.998047 2350.083252,3711.452148 
	C2351.853760,3704.047119 2354.797119,3696.787109 2355.523682,3689.299561 
	C2356.623779,3677.966064 2361.211426,3667.487549 2362.427246,3656.230713 
	C2362.721680,3653.503174 2363.313965,3650.237061 2366.728027,3648.072998 
	C2371.227051,3650.852295 2369.593506,3655.822510 2371.637451,3660.247070 
	C2366.000000,3672.482422 2366.328369,3685.628174 2361.444824,3698.109131 
	C2358.745117,3702.062988 2358.906494,3705.999023 2358.141113,3710.438721 
	C2357.308350,3713.447510 2355.520020,3715.200928 2354.621338,3718.174316 
	C2354.239990,3719.294434 2354.064209,3719.734863 2353.577148,3720.814453 
	C2350.055176,3726.187744 2350.379639,3732.335205 2346.612793,3737.577637 
	C2345.755615,3741.846680 2344.241943,3745.197021 2342.011719,3748.053711 
	C2338.667480,3752.337158 2337.268066,3757.196533 2336.297363,3762.868652 
	C2335.436523,3763.385254 2334.972656,3763.463867 2333.822998,3763.381348 
	C2331.183594,3762.220947 2330.045654,3760.428467 2330.310791,3758.438965 
	C2331.332031,3750.776367 2330.128174,3744.494141 2322.875244,3739.826416 
	C2319.618896,3737.730469 2320.004150,3733.707031 2324.626953,3732.652832 
	C2329.055420,3731.642578 2327.585205,3729.671631 2326.011719,3726.684570 
	C2326.538330,3724.447266 2327.212891,3722.884521 2327.966797,3720.686768 
z"
        />
        <path
          fill="#B0714F"
          opacity="1.000000"
          stroke="none"
          d="
M2325.368652,3726.493652 
	C2327.232666,3726.919434 2328.710205,3727.702881 2329.944336,3728.768066 
	C2331.793457,3730.363770 2335.978027,3730.820068 2334.729492,3734.061035 
	C2333.354980,3737.628906 2330.263184,3734.508545 2327.915527,3734.352051 
	C2326.035645,3734.226074 2323.763916,3733.461914 2323.135010,3736.228027 
	C2322.797119,3737.714600 2324.035645,3738.559326 2325.191650,3739.238770 
	C2333.982178,3744.403320 2335.007812,3746.570312 2332.851074,3756.161865 
	C2332.481934,3757.803467 2332.001953,3759.332520 2333.307129,3761.453125 
	C2335.836670,3763.826172 2334.462158,3765.675537 2333.358154,3768.136230 
	C2327.778076,3773.551758 2323.489990,3779.216309 2320.685303,3785.490234 
	C2315.167480,3797.831787 2303.453613,3801.000244 2292.782471,3805.807373 
	C2291.123535,3806.554443 2288.822510,3806.115479 2286.484131,3804.545898 
	C2286.222656,3801.788086 2287.353027,3800.452637 2289.569092,3799.825928 
	C2296.254639,3797.934326 2297.633301,3792.902100 2297.288086,3786.742432 
	C2296.921631,3780.203125 2298.525146,3778.469971 2304.937256,3779.457520 
	C2309.570068,3780.171143 2312.820557,3779.563965 2314.124268,3774.589844 
	C2314.562256,3772.918701 2315.704102,3771.793213 2316.923340,3770.610352 
	C2324.905518,3762.868164 2325.313477,3757.347168 2319.229736,3747.006592 
	C2316.839600,3742.943604 2318.571533,3737.774902 2316.107666,3732.781738 
	C2320.040283,3732.172852 2323.817383,3731.992188 2325.368652,3726.493652 
z"
        />
        <path
          fill="#935F47"
          opacity="1.000000"
          stroke="none"
          d="
M2285.569824,3804.503662 
	C2292.049072,3804.998047 2296.993896,3802.530029 2301.633545,3799.028320 
	C2303.993652,3797.247070 2305.878906,3794.903809 2308.940186,3793.965576 
	C2313.712891,3792.502930 2319.001465,3791.183105 2316.737061,3783.988525 
	C2316.518311,3783.293213 2317.217041,3781.720947 2317.876465,3781.428711 
	C2323.949951,3778.738525 2325.133789,3771.505127 2331.112305,3768.030273 
	C2332.236816,3768.165771 2332.663086,3768.409912 2333.376953,3769.332764 
	C2333.578125,3770.810059 2333.491699,3771.608887 2333.228027,3772.983643 
	C2329.439697,3774.846436 2328.502930,3777.712891 2327.797119,3781.212158 
	C2326.752441,3786.390869 2324.808350,3791.395752 2321.993896,3795.897461 
	C2318.204346,3801.958984 2313.745605,3807.147705 2305.411133,3806.146973 
	C2303.895752,3805.964844 2302.256592,3806.812500 2300.071289,3807.753906 
	C2295.594482,3808.196777 2291.718994,3808.113525 2287.848389,3807.925537 
	C2286.090820,3807.840332 2284.702637,3807.218750 2285.569824,3804.503662 
z"
        />
        <path
          fill="#CC855B"
          opacity="1.000000"
          stroke="none"
          d="
M2300.016602,3690.812500 
	C2302.088379,3690.114258 2303.743652,3691.040771 2305.352539,3692.359863 
	C2313.535645,3699.070068 2315.767090,3699.333496 2324.600098,3694.199219 
	C2327.188232,3692.695068 2329.609619,3690.947998 2333.269531,3690.079102 
	C2332.644775,3694.888672 2327.219238,3697.437012 2327.181152,3703.503906 
	C2327.159424,3707.009521 2322.440918,3705.635986 2318.858398,3705.958008 
	C2318.034912,3705.947266 2317.417725,3705.520020 2317.680420,3705.788086 
	C2318.791992,3708.362793 2321.283691,3710.810059 2318.381104,3713.044434 
	C2315.999023,3714.877686 2313.472656,3713.674316 2311.172852,3712.129395 
	C2307.650391,3709.764404 2304.061768,3707.696777 2303.236328,3702.829834 
	C2302.588623,3699.010498 2301.131104,3695.328857 2300.016602,3690.812500 
z"
        />
        <path
          fill="#B0714F"
          opacity="1.000000"
          stroke="none"
          d="
M2315.968506,3732.031250 
	C2315.137939,3731.792236 2314.332031,3731.528076 2313.526367,3731.263916 
	C2314.147461,3729.937500 2314.843750,3730.692627 2315.783447,3731.570801 
	C2316.036133,3731.950195 2315.993408,3732.006104 2315.968506,3732.031250 
z"
        />
        <path
          fill="#B0714F"
          opacity="1.000000"
          stroke="none"
          d="
M2321.055664,3721.083008 
	C2320.962402,3721.135010 2321.149170,3721.031006 2321.055664,3721.083008 
z"
        />
        <path
          fill="#B0714F"
          opacity="1.000000"
          stroke="none"
          d="
M2308.954590,3726.924805 
	C2309.045898,3726.875488 2308.863281,3726.973877 2308.954590,3726.924805 
z"
        />
        <path
          fill="#E6E1D0"
          opacity="1.000000"
          stroke="none"
          d="
M2363.846680,3855.059082 
	C2364.875244,3854.107422 2365.790283,3854.047363 2367.393311,3854.031738 
	C2368.081055,3854.076416 2368.001221,3853.996826 2367.962646,3853.955566 
	C2373.171875,3860.403320 2372.840820,3868.741943 2375.204102,3876.181152 
	C2376.897949,3881.513672 2378.834473,3886.753662 2380.734863,3892.833740 
	C2380.873047,3894.130615 2380.858887,3894.655518 2380.572754,3895.918945 
	C2376.351074,3902.994385 2381.706787,3906.705811 2385.322021,3911.536377 
	C2380.120117,3912.564697 2375.963623,3914.286377 2370.769531,3916.733887 
	C2371.061279,3911.779541 2374.758545,3908.217041 2372.547852,3903.980469 
	C2371.393311,3901.768066 2371.968750,3898.590088 2368.506836,3897.867432 
	C2367.010742,3897.554932 2365.891113,3895.727783 2366.820312,3895.089111 
	C2373.091309,3890.778320 2367.177246,3886.041504 2366.452393,3882.518311 
	C2364.630127,3873.660889 2364.166504,3864.849854 2363.846680,3855.059082 
z"
        />
        <path
          fill="#BAB4A7"
          opacity="1.000000"
          stroke="none"
          d="
M2362.986572,3748.038574 
	C2354.736816,3745.907959 2353.686035,3739.620850 2352.320801,3732.676758 
	C2350.792480,3730.768311 2350.376953,3729.279297 2351.081055,3726.852051 
	C2358.175049,3724.557129 2361.289062,3729.300049 2364.966797,3734.407959 
	C2365.926514,3739.560547 2367.328125,3744.039551 2362.986572,3748.038574 
z"
        />
        <path
          fill="#B0714F"
          opacity="1.000000"
          stroke="none"
          d="
M2362.082031,3748.913086 
	C2364.981445,3744.357178 2363.722656,3739.952393 2364.039307,3734.975830 
	C2364.381348,3733.829102 2364.749023,3733.503418 2365.712646,3733.610840 
	C2370.173340,3741.488770 2367.078857,3749.565186 2368.320557,3757.302734 
	C2368.936768,3761.142334 2363.961670,3760.180176 2361.155273,3761.919922 
	C2359.759033,3757.753418 2362.373291,3753.911621 2362.082031,3748.913086 
z"
        />
        <path
          fill="#5E4237"
          opacity="1.000000"
          stroke="none"
          d="
M2352.673828,3725.527588 
	C2352.136475,3727.660156 2352.109863,3729.118896 2352.032959,3731.306152 
	C2349.517822,3733.213623 2350.142578,3736.849121 2346.788574,3737.969482 
	C2348.890869,3732.673340 2346.086182,3725.750977 2351.432373,3720.482422 
	C2353.394775,3721.503174 2353.604736,3723.105469 2352.673828,3725.527588 
z"
        />
        <path
          fill="#B0714F"
          opacity="1.000000"
          stroke="none"
          d="
M2359.159668,3768.340820 
	C2363.731201,3771.752441 2361.453125,3773.984375 2356.948242,3775.924805 
	C2356.150391,3773.301025 2357.578857,3771.092285 2359.159668,3768.340820 
z"
        />
        <path
          fill="#5E4237"
          opacity="1.000000"
          stroke="none"
          d="
M2334.239746,3769.550049 
	C2333.101562,3769.701660 2332.652344,3769.272461 2332.063477,3768.322998 
	C2332.461182,3766.363770 2333.111816,3764.805664 2333.781982,3762.610107 
	C2334.361328,3762.115967 2334.881836,3762.347412 2335.631836,3762.986816 
	C2335.946533,3765.310791 2336.073486,3767.329590 2334.239746,3769.550049 
z"
        />
        <path
          fill="#D0D1C8"
          opacity="1.000000"
          stroke="none"
          d="
M2383.155518,3892.328857 
	C2384.100098,3892.963379 2383.799072,3893.553711 2382.451172,3893.916016 
	C2381.572998,3893.220459 2381.741455,3892.752686 2383.155518,3892.328857 
z"
        />
        <path
          fill="#D0D1C8"
          opacity="1.000000"
          stroke="none"
          d="
M2380.857910,3896.560059 
	C2380.217529,3896.231445 2380.134521,3895.805176 2380.023193,3894.727539 
	C2379.994873,3894.076416 2380.379883,3893.345947 2380.773438,3893.221680 
	C2381.403320,3893.348145 2381.639160,3893.598389 2382.231934,3894.221436 
	C2382.197510,3895.217285 2381.806396,3895.839844 2380.857910,3896.560059 
z"
        />
        <path
          fill="#D0D1C8"
          opacity="1.000000"
          stroke="none"
          d="
M2383.597656,3890.842773 
	C2384.095947,3890.068115 2384.550049,3890.080811 2385.684814,3890.129639 
	C2386.043457,3890.778809 2385.721436,3891.391357 2384.900146,3892.188477 
	C2384.118408,3892.125488 2383.835693,3891.877686 2383.597656,3890.842773 
z"
        />
        <path
          fill="#0C0D0F"
          opacity="1.000000"
          stroke="none"
          d="
M2433.694824,3937.150391 
	C2433.691406,3938.342285 2433.284668,3938.889893 2432.444580,3939.716309 
	C2430.179932,3939.064941 2429.013672,3937.593506 2430.342773,3935.743408 
	C2431.243408,3934.489746 2432.328369,3936.055908 2433.694824,3937.150391 
z"
        />
        <path
          fill="#2C2520"
          opacity="1.000000"
          stroke="none"
          d="
M2453.448486,3930.588379 
	C2456.724609,3933.493408 2457.597656,3937.008789 2458.872803,3940.327148 
	C2459.356689,3941.585205 2459.761719,3943.025146 2458.323242,3943.698486 
	C2456.361328,3944.616699 2455.044434,3942.996582 2454.752930,3941.542480 
	C2454.009033,3937.833740 2451.165283,3934.468262 2453.448486,3930.588379 
z"
        />
        <path
          fill="#2C2520"
          opacity="1.000000"
          stroke="none"
          d="
M2396.033691,3927.931152 
	C2395.364502,3928.543701 2394.870850,3929.032471 2395.289307,3930.250244 
	C2396.358154,3933.360352 2394.771729,3935.798340 2391.806152,3935.789551 
	C2388.549561,3935.780029 2387.678467,3932.483887 2388.243896,3929.967041 
	C2389.000732,3926.598877 2391.825684,3928.573730 2393.855225,3928.747803 
	C2394.167236,3928.774414 2394.464111,3928.981445 2395.463379,3928.566406 
	C2396.158691,3928.027832 2396.057373,3927.981689 2396.033691,3927.931152 
z"
        />
        <path
          fill="#7793AA"
          opacity="1.000000"
          stroke="none"
          d="
M2072.754395,2124.000977 
	C2060.042480,2124.000000 2048.074219,2123.914551 2036.107910,2124.025635 
	C2025.473755,2124.124268 2014.901245,2124.279053 2004.620605,2120.658691 
	C2001.916748,2119.706543 1998.709961,2120.182617 1994.871948,2120.006348 
	C1991.356689,2119.883789 1988.670898,2119.965332 1986.062256,2119.555908 
	C1984.423706,2119.298340 1983.023560,2118.128662 1982.897217,2116.203369 
	C1982.768188,2114.235840 1984.083740,2112.724609 1985.702148,2112.638184 
	C1996.704224,2112.050781 2007.270752,2104.863770 2018.709229,2110.468506 
	C2020.449829,2111.321533 2022.515869,2111.683838 2024.472168,2111.894043 
	C2034.869751,2113.011230 2036.557129,2111.710693 2037.975342,2101.316650 
	C2038.392456,2098.259277 2038.403687,2095.105469 2042.488037,2094.791016 
	C2046.699341,2094.466553 2051.202148,2097.278320 2051.890137,2101.187012 
	C2052.460205,2104.427246 2053.394287,2106.901855 2056.719238,2107.991943 
	C2058.000488,2108.412109 2059.286621,2109.097412 2059.548340,2110.528320 
	C2060.960449,2118.248047 2066.861572,2117.990479 2072.631104,2117.996826 
	C2076.624023,2118.000977 2080.618652,2118.052002 2084.609375,2117.949463 
	C2087.702637,2117.870117 2090.281494,2117.314453 2087.571289,2113.343750 
	C2086.588379,2111.904053 2087.423096,2110.820557 2088.763672,2110.222900 
	C2096.286621,2106.870361 2104.025146,2106.114258 2111.921143,2108.684570 
	C2112.604248,2108.907227 2113.324951,2110.243164 2113.306641,2111.045654 
	C2113.288818,2111.844482 2112.492676,2113.137451 2111.808105,2113.312256 
	C2109.909668,2113.796387 2107.894287,2113.939209 2105.920166,2113.979980 
	C2103.195312,2114.036377 2100.114990,2114.068848 2098.473389,2116.168213 
	C2095.638916,2119.793213 2094.719238,2124.157959 2087.662598,2122.687256 
	C2083.772705,2121.876709 2078.445312,2124.800537 2072.754395,2124.000977 
z"
        />
        <path
          fill="#6E8AA1"
          opacity="1.000000"
          stroke="none"
          d="
M2095.993652,2154.290283 
	C2099.934326,2156.022705 2103.305176,2156.392090 2106.591064,2155.940674 
	C2120.516846,2154.026367 2134.777832,2156.712402 2148.581543,2152.418945 
	C2153.694824,2150.828613 2159.265625,2151.645264 2163.795166,2155.158447 
	C2166.602051,2157.335205 2169.601074,2158.365967 2173.060791,2157.892578 
	C2174.318848,2157.720215 2176.425049,2157.337402 2176.604004,2156.650146 
	C2179.121582,2146.968750 2185.139893,2151.566895 2190.417236,2152.840088 
	C2191.118652,2157.229492 2185.949707,2154.980713 2185.365234,2157.925537 
	C2185.567383,2160.069092 2187.621826,2159.682861 2189.036377,2160.251221 
	C2190.298340,2160.758057 2191.485840,2161.402100 2191.351318,2163.046875 
	C2191.180420,2165.141113 2189.631836,2165.776611 2187.907959,2165.861572 
	C2161.509521,2167.164307 2135.116455,2170.725586 2108.990723,2162.318848 
	C2101.852783,2160.021973 2093.630127,2159.248291 2085.840820,2161.119385 
	C2081.958740,2162.052490 2078.885742,2161.584473 2078.500732,2157.190674 
	C2078.168945,2153.401367 2081.187012,2152.369141 2084.474609,2152.037598 
	C2088.261475,2151.655273 2091.690674,2152.891357 2095.993652,2154.290283 
z"
        />
        <path
          fill="#7793AA"
          opacity="1.000000"
          stroke="none"
          d="
M2404.476318,2131.208740 
	C2407.802246,2132.129395 2410.399170,2132.838623 2412.470703,2134.585938 
	C2415.942871,2137.514404 2414.695068,2145.291016 2410.406494,2146.961914 
	C2408.326172,2147.772461 2405.872559,2146.528320 2405.263916,2144.928955 
	C2403.208984,2139.529297 2398.934570,2139.702393 2394.591309,2140.044922 
	C2390.151611,2140.395264 2386.109131,2138.843750 2381.916992,2137.959961 
	C2380.171875,2137.592041 2378.585693,2136.891357 2378.669189,2134.906982 
	C2378.751953,2132.938232 2380.394531,2132.229248 2382.146484,2132.123779 
	C2389.342041,2131.689209 2396.541260,2131.317871 2404.476318,2131.208740 
z"
        />
        <path
          fill="#6E8AA1"
          opacity="1.000000"
          stroke="none"
          d="
M2031.168823,2160.000000 
	C2033.931396,2162.364014 2032.828125,2163.770508 2030.329712,2163.825439 
	C2024.411987,2163.956543 2018.409546,2164.816895 2012.593872,2163.145508 
	C2007.690552,2161.736328 2002.942627,2161.791260 1997.973999,2162.945557 
	C1990.374023,2164.710938 1989.906250,2164.000488 1991.906982,2154.750244 
	C2002.502197,2153.925781 2012.821411,2154.891113 2022.588501,2159.156738 
	C2025.077271,2160.243652 2027.731445,2159.929199 2031.168823,2160.000000 
z"
        />
        <path
          fill="#6E8AA1"
          opacity="1.000000"
          stroke="none"
          d="
M2027.529541,2138.685547 
	C2041.596069,2136.750000 2054.968506,2139.926758 2068.496582,2140.061768 
	C2070.440430,2140.081055 2072.918701,2140.046387 2073.159424,2142.613525 
	C2073.482666,2146.060791 2070.531982,2145.998779 2068.330078,2145.913574 
	C2060.438477,2145.608643 2052.550781,2146.759033 2044.627319,2144.744629 
	C2039.932007,2143.550781 2034.771606,2144.186768 2028.912598,2144.000000 
	C2026.845093,2142.629639 2026.530273,2141.086670 2027.529541,2138.685547 
z"
        />
        <path
          fill="#7793AA"
          opacity="1.000000"
          stroke="none"
          d="
M2116.816895,2139.179199 
	C2116.346680,2134.815186 2117.668945,2132.767578 2122.460938,2132.381104 
	C2129.688477,2131.798584 2137.021973,2131.101318 2144.207031,2133.098633 
	C2144.845703,2133.276123 2145.475342,2133.486084 2146.112305,2133.669678 
	C2148.213135,2134.275146 2151.376221,2133.802002 2151.370117,2136.992676 
	C2151.364014,2140.282227 2148.236328,2140.891846 2145.735107,2141.648926 
	C2144.126221,2142.135498 2142.163574,2142.251221 2140.938232,2141.125488 
	C2133.590576,2134.374023 2125.474121,2137.910156 2116.816895,2139.179199 
z"
        />
        <path
          fill="#7793AA"
          opacity="1.000000"
          stroke="none"
          d="
M2431.947754,2178.000000 
	C2423.904785,2177.645752 2416.717041,2180.196289 2409.594482,2177.785400 
	C2408.767578,2177.505615 2408.060547,2176.871582 2407.147461,2176.309326 
	C2415.847656,2172.354004 2438.383789,2173.281494 2448.289307,2178.703613 
	C2442.035645,2178.418945 2437.435303,2178.209473 2431.947754,2178.000000 
z"
        />
        <path
          fill="#7793AA"
          opacity="1.000000"
          stroke="none"
          d="
M2249.489258,2132.276855 
	C2253.752441,2133.296143 2257.207520,2134.174561 2260.818604,2135.092773 
	C2254.942627,2141.648193 2246.005127,2141.977783 2234.100830,2135.601074 
	C2239.466309,2133.787354 2243.690430,2131.733398 2249.489258,2132.276855 
z"
        />
        <path
          fill="#7793AA"
          opacity="1.000000"
          stroke="none"
          d="
M2011.449585,2095.932861 
	C2013.862305,2099.197021 2011.776123,2099.903564 2009.739624,2099.850342 
	C2006.262817,2099.759277 2002.485718,2101.223877 1999.429565,2098.493164 
	C1999.058105,2098.161377 1999.305176,2096.405518 1999.829834,2095.923096 
	C2002.922485,2093.079590 2006.439819,2093.783936 2009.947021,2095.093750 
	C2010.252930,2095.208008 2010.534912,2095.385986 2011.449585,2095.932861 
z"
        />
        <path
          fill="#7793AA"
          opacity="1.000000"
          stroke="none"
          d="
M2442.499023,2114.434082 
	C2445.322266,2112.160889 2449.800049,2107.965820 2452.219971,2112.351807 
	C2454.821289,2117.066406 2448.240723,2117.694824 2444.681152,2119.909424 
	C2443.950195,2118.226562 2444.135742,2116.418945 2442.499023,2114.434082 
z"
        />
        <path
          fill="#728DA3"
          opacity="1.000000"
          stroke="none"
          d="
M2004.485596,2136.511230 
	C2006.146606,2132.563477 2008.564575,2129.427002 2013.337646,2128.083008 
	C2012.662964,2137.180176 2012.191162,2137.650879 2004.485596,2136.511230 
z"
        />
        <path
          fill="#728DA3"
          opacity="1.000000"
          stroke="none"
          d="
M2021.190674,2144.000000 
	C2021.735352,2146.870117 2020.586792,2149.122803 2017.503784,2149.666748 
	C2014.742798,2150.153809 2011.523682,2149.873779 2011.227295,2146.682373 
	C2010.916382,2143.336426 2014.345215,2144.266357 2016.440674,2144.036621 
	C2017.739990,2143.894531 2019.066895,2144.003662 2021.190674,2144.000000 
z"
        />
        <path
          fill="#7392AD"
          opacity="1.000000"
          stroke="none"
          d="
M2460.972168,2149.473145 
	C2461.919922,2152.588623 2461.220703,2154.578369 2458.301514,2155.037354 
	C2457.170898,2155.215088 2456.450684,2154.291504 2456.440430,2153.211182 
	C2456.415039,2150.527588 2457.452393,2148.739990 2460.972168,2149.473145 
z"
        />
        <path
          fill="#728DA3"
          opacity="1.000000"
          stroke="none"
          d="
M2027.325562,2138.082764 
	C2028.000366,2139.500000 2028.000732,2141.000000 2028.002930,2143.250000 
	C2026.503662,2144.000000 2025.002319,2144.000000 2022.750488,2144.000000 
	C2022.192139,2140.972412 2023.507568,2138.840332 2027.325562,2138.082764 
z"
        />
        <path
          fill="#7793AA"
          opacity="1.000000"
          stroke="none"
          d="
M2340.388184,2118.976074 
	C2340.587891,2122.469727 2338.440430,2121.913330 2336.560791,2121.517334 
	C2335.539551,2121.302246 2334.854492,2120.179443 2335.458252,2119.333008 
	C2336.573486,2117.769775 2338.317627,2118.109863 2340.388184,2118.976074 
z"
        />
        <path
          fill="#728DA3"
          opacity="1.000000"
          stroke="none"
          d="
M2018.307983,2128.582764 
	C2020.367432,2128.128906 2022.826660,2127.583984 2025.511719,2129.482910 
	C2023.142578,2133.185791 2020.810669,2131.776855 2018.307983,2128.582764 
z"
        />
        <path
          fill="#7793AA"
          opacity="1.000000"
          stroke="none"
          d="
M1958.512207,2068.995117 
	C1956.813965,2069.724854 1955.597534,2069.674072 1955.136719,2068.205322 
	C1955.010986,2067.804199 1955.475830,2066.848633 1955.859985,2066.720703 
	C1957.501831,2066.175293 1958.944702,2066.275635 1958.512207,2068.995117 
z"
        />
        <path
          fill="#728DA3"
          opacity="1.000000"
          stroke="none"
          d="
M1988.572754,2143.910400 
	C1987.996826,2143.111084 1987.998413,2142.222168 1988.000000,2140.666504 
	C1989.276245,2140.202393 1990.734253,2140.307617 1990.952881,2141.916748 
	C1991.105225,2143.039062 1990.133789,2143.539795 1988.572754,2143.910400 
z"
        />
        <path
          fill="#7793AA"
          opacity="1.000000"
          stroke="none"
          d="
M2421.072266,2146.956055 
	C2421.117188,2147.040039 2421.027100,2146.872070 2421.072266,2146.956055 
z"
        />
        <path
          fill="#7B98B0"
          opacity="1.000000"
          stroke="none"
          d="
M2652.151855,2027.881714 
	C2657.505371,2030.703491 2661.703369,2027.663086 2666.094238,2026.907349 
	C2668.490234,2026.494873 2670.947021,2025.674683 2671.436523,2028.979492 
	C2671.838623,2031.694214 2669.819336,2033.448853 2667.358643,2033.634155 
	C2663.105225,2033.954468 2658.796143,2033.919556 2654.536621,2033.639038 
	C2651.948730,2033.468628 2649.431641,2032.408325 2646.842041,2032.021362 
	C2644.352051,2031.649292 2641.812988,2031.074829 2642.617920,2027.998535 
	C2643.523438,2024.538696 2646.013428,2023.228149 2649.386719,2025.446045 
	C2650.196777,2025.978760 2650.811768,2026.807861 2652.151855,2027.881714 
z"
        />
        <path
          fill="#7793AA"
          opacity="1.000000"
          stroke="none"
          d="
M2504.000000,2075.250000 
	C2496.392578,2080.017090 2494.465332,2079.921631 2488.400879,2075.680908 
	C2487.187988,2074.833008 2486.375488,2073.898193 2486.632568,2072.549316 
	C2486.890625,2071.196777 2487.977539,2070.281494 2489.430420,2070.238770 
	C2493.689209,2070.113037 2497.950195,2070.073242 2503.105225,2070.000000 
	C2504.000000,2071.500000 2504.000000,2073.000000 2504.000000,2075.250000 
z"
        />
        <path
          fill="#738EA6"
          opacity="1.000000"
          stroke="none"
          d="
M2330.954590,2031.346191 
	C2326.440918,2032.370728 2323.395996,2031.308716 2320.832520,2028.273926 
	C2323.166260,2025.084839 2326.503906,2025.769043 2329.089600,2024.771484 
	C2331.617188,2023.796753 2335.493652,2022.560181 2336.667969,2026.113770 
	C2337.699707,2029.235840 2333.747314,2029.755981 2330.954590,2031.346191 
z"
        />
        <path
          fill="#738EA6"
          opacity="1.000000"
          stroke="none"
          d="
M2295.396484,2026.990967 
	C2291.944580,2027.358154 2290.273193,2025.876343 2290.779053,2023.162720 
	C2291.421875,2019.713867 2293.837646,2017.385620 2297.313477,2016.738892 
	C2299.914795,2016.255005 2301.503662,2018.251221 2302.684814,2020.199951 
	C2303.719727,2021.907349 2302.923096,2023.126709 2301.093750,2023.928833 
	C2299.332520,2024.701050 2297.700684,2025.768677 2295.396484,2026.990967 
z"
        />
        <path
          fill="#7793AA"
          opacity="1.000000"
          stroke="none"
          d="
M2716.000000,2049.187988 
	C2711.949463,2049.698242 2710.063232,2047.419800 2710.006592,2042.732300 
	C2713.041260,2039.905640 2711.121826,2035.416626 2714.156006,2033.316284 
	C2715.281738,2032.536987 2716.582520,2031.992554 2717.872559,2032.635254 
	C2719.229492,2033.311279 2719.553467,2034.602173 2718.857666,2035.962036 
	C2716.859619,2039.866699 2715.512939,2043.906250 2716.000000,2049.187988 
z"
        />
        <path
          fill="#7793AA"
          opacity="1.000000"
          stroke="none"
          d="
M2735.282715,2040.020508 
	C2735.856934,2044.036499 2736.327148,2046.797485 2741.251465,2048.713135 
	C2745.165283,2050.235840 2741.472656,2051.554443 2738.684570,2051.981689 
	C2735.879639,2050.138672 2733.413086,2048.544678 2731.719238,2046.353760 
	C2729.082520,2042.943237 2729.902832,2040.473145 2735.282715,2040.020508 
z"
        />
        <path
          fill="#7793AA"
          opacity="1.000000"
          stroke="none"
          d="
M2506.000000,2061.250000 
	C2501.790527,2060.931396 2500.792725,2059.137207 2505.348633,2056.117676 
	C2506.000000,2057.500000 2506.000000,2059.000000 2506.000000,2061.250000 
z"
        />
        <path
          fill="#7793AA"
          opacity="1.000000"
          stroke="none"
          d="
M1846.390869,2146.539307 
	C1844.663696,2139.077393 1849.194214,2137.580566 1856.112305,2137.298096 
	C1852.294189,2133.393555 1847.665527,2132.798340 1845.715576,2128.769531 
	C1843.242676,2123.659912 1844.821045,2119.986572 1850.570435,2120.194824 
	C1855.489380,2120.372803 1860.408691,2121.395752 1865.251343,2122.409912 
	C1867.202637,2122.818359 1867.416260,2124.425049 1866.251831,2126.263672 
	C1865.017578,2128.212158 1861.439087,2127.187012 1861.269897,2130.190186 
	C1861.927612,2131.891113 1863.336060,2131.938477 1864.611450,2131.953125 
	C1869.963623,2132.014893 1870.362305,2135.421875 1869.718628,2139.465576 
	C1869.231445,2142.526367 1866.880615,2145.520508 1870.012939,2149.873779 
	C1872.284790,2144.624756 1875.533081,2141.236084 1881.282471,2140.086914 
	C1880.151611,2145.318604 1876.690308,2150.242188 1877.993286,2157.157227 
	C1874.761841,2160.828857 1871.167603,2160.146240 1866.770264,2158.537109 
	C1861.351440,2158.401855 1857.111816,2156.953613 1852.484985,2154.521484 
	C1852.272095,2152.769775 1852.962158,2151.830566 1854.289307,2150.859619 
	C1854.617188,2150.617432 1854.470215,2149.863281 1854.006104,2149.798340 
	C1851.140869,2149.223877 1848.782837,2148.604980 1846.390869,2146.539307 
z"
        />
        <path
          fill="#69849B"
          opacity="1.000000"
          stroke="none"
          d="
M1866.176147,2158.720703 
	C1869.432251,2158.003662 1872.855835,2157.997314 1877.134766,2157.985840 
	C1887.419556,2157.883545 1896.641357,2154.814453 1907.069580,2156.015625 
	C1907.495117,2161.400635 1903.755371,2162.108154 1899.428467,2161.984863 
	C1893.974121,2161.829590 1888.528564,2161.516846 1887.567383,2169.095703 
	C1887.268677,2171.451416 1885.005493,2171.940430 1882.934448,2171.918213 
	C1879.657104,2171.883545 1876.301270,2172.608398 1872.563721,2170.541992 
	C1872.972900,2169.144531 1873.941162,2168.265869 1874.962280,2167.453857 
	C1876.015869,2166.615723 1877.225586,2165.663330 1877.014893,2164.297852 
	C1876.703491,2162.279785 1874.867920,2162.189453 1873.200317,2162.025391 
	C1870.692871,2161.778564 1867.740479,2162.637207 1866.176147,2158.720703 
z"
        />
        <path
          fill="#69849B"
          opacity="1.000000"
          stroke="none"
          d="
M1759.261475,2126.012207 
	C1764.166260,2125.946289 1767.615356,2127.457031 1772.598022,2129.973389 
	C1765.000366,2133.018799 1759.961792,2138.643311 1752.448975,2132.819092 
	C1750.176758,2131.057373 1746.080688,2131.765625 1742.907349,2130.986572 
	C1741.773438,2130.708252 1740.800049,2129.438477 1741.279541,2127.843262 
	C1741.615601,2126.725098 1742.531372,2126.271973 1743.500366,2126.245605 
	C1748.462036,2126.111084 1753.426636,2126.077148 1759.261475,2126.012207 
z"
        />
        <path
          fill="#69849B"
          opacity="1.000000"
          stroke="none"
          d="
M1845.097046,2146.005127 
	C1848.946411,2146.043945 1851.948242,2145.770508 1855.306274,2147.726318 
	C1856.991333,2148.807861 1858.106079,2149.496582 1858.248535,2151.395020 
	C1856.362671,2152.474121 1854.760986,2152.973389 1852.577148,2153.731445 
	C1845.123047,2154.124756 1838.606567,2151.066162 1831.634399,2151.801025 
	C1829.619995,2152.012695 1827.242798,2150.895752 1827.227783,2148.764648 
	C1827.203979,2145.392822 1830.312500,2146.116943 1832.424194,2146.043213 
	C1836.344849,2145.906006 1840.273560,2146.001709 1845.097046,2146.005127 
z"
        />
        <path
          fill="#69849B"
          opacity="1.000000"
          stroke="none"
          d="
M1815.736572,2159.953125 
	C1812.292480,2158.641602 1807.747925,2164.314209 1806.880005,2158.885498 
	C1806.239990,2154.882812 1811.413696,2156.647461 1813.920166,2155.671387 
	C1814.534790,2155.431885 1815.175659,2155.257812 1815.809814,2155.072266 
	C1818.228516,2154.364014 1821.745483,2152.803467 1822.365723,2156.042236 
	C1822.920776,2158.939697 1818.957886,2159.233398 1815.736572,2159.953125 
z"
        />
        <path
          fill="#7793AA"
          opacity="1.000000"
          stroke="none"
          d="
M1882.759033,2139.983398 
	C1885.371582,2133.562988 1885.371582,2133.562988 1893.565674,2135.989502 
	C1890.290771,2138.557129 1887.050049,2139.577637 1882.759033,2139.983398 
z"
        />
        <path
          fill="#7793AA"
          opacity="1.000000"
          stroke="none"
          d="
M1966.967041,2013.952881 
	C1965.499390,2013.989258 1964.823242,2014.076294 1964.172607,2013.984009 
	C1960.439087,2013.454590 1955.980957,2015.555908 1953.319458,2011.166504 
	C1952.833008,2010.364624 1953.110107,2009.290283 1954.173218,2008.688843 
	C1957.080322,2007.044678 1968.695435,2007.779175 1970.295044,2010.142822 
	C1971.982178,2012.635742 1969.971680,2013.547607 1966.967041,2013.952881 
z"
        />
        <path
          fill="#7793AA"
          opacity="1.000000"
          stroke="none"
          d="
M1986.990723,2019.073730 
	C1984.984985,2013.413696 1987.905640,2009.292114 1992.268555,2010.530640 
	C1993.944214,2011.006348 1995.101685,2012.197876 1995.486694,2013.935425 
	C1995.903931,2015.817993 1995.093262,2017.166138 1993.298828,2017.761108 
	C1991.439453,2018.377686 1989.521606,2018.817627 1986.990723,2019.073730 
z"
        />
        <path
          fill="#7793AA"
          opacity="1.000000"
          stroke="none"
          d="
M2030.458740,2010.326538 
	C2034.314453,2010.179932 2036.583374,2011.306885 2035.133789,2014.786011 
	C2033.794678,2017.999512 2030.398926,2019.050537 2027.907227,2017.097656 
	C2024.551880,2014.467896 2026.830444,2012.087524 2030.458740,2010.326538 
z"
        />
        <path
          fill="#7793AA"
          opacity="1.000000"
          stroke="none"
          d="
M1890.713623,2012.857910 
	C1889.225464,2013.275269 1888.687012,2012.754150 1888.916992,2011.599243 
	C1888.964966,2011.359375 1889.305054,2011.036987 1889.547729,2011.000732 
	C1890.715088,2010.826294 1891.202881,2011.394409 1890.713623,2012.857910 
z"
        />
        <path
          fill="#607B93"
          opacity="1.000000"
          stroke="none"
          d="
M1711.451050,1932.721680 
	C1712.916016,1921.617798 1714.019409,1920.553833 1724.362061,1919.966187 
	C1726.907837,1919.821533 1729.772583,1920.642700 1731.812744,1917.160645 
	C1746.725952,1916.173584 1761.429443,1914.261597 1776.741699,1918.116455 
	C1777.590454,1919.690430 1777.636841,1921.133301 1777.691162,1923.299805 
	C1777.728394,1926.772095 1776.932861,1929.188232 1774.367065,1931.525146 
	C1772.226929,1932.034790 1770.482910,1931.791260 1768.924316,1932.220337 
	C1750.887451,1937.186279 1732.449219,1938.165283 1712.472412,1937.500244 
	C1710.474487,1935.780151 1710.456055,1934.509888 1711.451050,1932.721680 
z"
        />
        <path
          fill="#FBF9EC"
          opacity="1.000000"
          stroke="none"
          d="
M1855.486816,1896.550293 
	C1856.464111,1896.761108 1857.615479,1897.686401 1857.480347,1898.349365 
	C1854.738525,1911.804565 1856.487305,1925.418823 1855.874512,1938.941162 
	C1855.770996,1941.225098 1855.126099,1943.484375 1854.203613,1946.670654 
	C1852.955811,1947.376343 1852.255493,1947.113525 1851.042847,1946.548340 
	C1848.911133,1945.021240 1846.847412,1944.444702 1845.037476,1942.406128 
	C1844.386230,1941.566162 1844.128174,1941.217285 1843.469971,1940.355225 
	C1842.297485,1933.230469 1842.434204,1926.799805 1847.307739,1920.582764 
	C1850.245605,1912.353760 1852.653687,1904.748291 1855.486816,1896.550293 
z"
        />
        <path
          fill="#5D758A"
          opacity="1.000000"
          stroke="none"
          d="
M1851.398682,1945.929443 
	C1851.975952,1947.202881 1852.337891,1947.546997 1853.303833,1947.616089 
	C1853.675781,1947.586304 1854.425049,1947.394165 1854.647827,1947.078125 
	C1855.473389,1947.047852 1856.076294,1947.333252 1855.810303,1948.115723 
	C1855.795898,1949.948486 1857.139160,1949.922607 1859.154907,1949.829834 
	C1867.328125,1946.347046 1875.293457,1948.650635 1883.911011,1948.051758 
	C1887.789673,1948.099731 1890.053955,1948.842896 1889.848877,1952.494385 
	C1889.703003,1955.088623 1888.383179,1956.951172 1885.917236,1957.513916 
	C1882.353882,1958.327148 1878.748779,1958.300659 1874.601562,1956.368042 
	C1865.048706,1961.792236 1856.416992,1959.100098 1847.125732,1954.974854 
	C1843.693848,1951.929932 1841.828247,1949.139526 1843.112305,1944.671753 
	C1846.246826,1942.469971 1848.536499,1944.276855 1851.398682,1945.929443 
z"
        />
        <path
          fill="#947C7A"
          opacity="1.000000"
          stroke="none"
          d="
M1711.789185,1947.048340 
	C1710.177124,1948.239502 1707.897949,1948.089478 1707.192627,1949.070801 
	C1704.125122,1953.338501 1700.061035,1954.540405 1695.214233,1953.867676 
	C1689.704468,1953.103027 1684.161377,1952.284180 1680.361084,1946.466309 
	C1681.639648,1938.896484 1687.985840,1942.244995 1692.641113,1940.042236 
	C1698.989014,1938.600220 1704.187866,1935.017578 1710.923584,1937.405029 
	C1711.542358,1937.904297 1711.786987,1937.903076 1711.906738,1937.928711 
	C1711.966553,1940.712158 1711.906372,1943.469971 1711.789185,1947.048340 
z"
        />
        <path
          fill="#5D758A"
          opacity="1.000000"
          stroke="none"
          d="
M1847.044434,1919.829102 
	C1847.437012,1926.326050 1845.409180,1932.324463 1843.368164,1939.114746 
	C1841.844482,1939.354980 1840.376709,1938.748657 1840.376831,1937.356934 
	C1840.377808,1929.288818 1835.214844,1927.815674 1828.706909,1927.996338 
	C1825.125610,1928.095825 1822.177612,1927.429077 1821.744629,1922.217773 
	C1821.553711,1921.031128 1821.518677,1920.666382 1821.988892,1920.085449 
	C1830.396973,1917.530518 1838.312622,1917.008179 1847.044434,1919.829102 
z"
        />
        <path
          fill="#E7E3DA"
          opacity="1.000000"
          stroke="none"
          d="
M1822.439697,1919.014526 
	C1822.494263,1919.869385 1821.857422,1920.525269 1821.723022,1920.960571 
	C1816.780273,1921.828735 1811.812012,1923.068237 1807.412476,1918.288086 
	C1806.405762,1913.054077 1807.930542,1908.652588 1809.996094,1903.681274 
	C1811.210938,1900.237183 1812.973999,1901.996948 1813.354858,1902.980957 
	C1815.090576,1907.464111 1814.964233,1902.734863 1816.396240,1902.643311 
	C1817.569946,1902.479614 1817.908569,1902.118286 1818.177612,1901.248413 
	C1818.425781,1900.422974 1818.596436,1899.968140 1819.393799,1899.885254 
	C1821.616821,1906.117798 1823.330078,1911.963257 1822.439697,1919.014526 
z"
        />
        <path
          fill="#C4C8C1"
          opacity="1.000000"
          stroke="none"
          d="
M1846.338135,1955.027832 
	C1854.997070,1954.861572 1863.545410,1955.359253 1872.974976,1955.995483 
	C1874.463379,1958.531372 1874.145996,1960.760010 1872.332275,1963.400269 
	C1867.640747,1965.073486 1863.377441,1966.927246 1859.175293,1962.983154 
	C1857.231934,1961.158936 1854.125122,1962.162842 1850.703125,1962.188477 
	C1849.883911,1962.254761 1848.937622,1962.073853 1848.455811,1962.088135 
	C1847.084961,1960.049072 1845.624756,1958.151489 1846.338135,1955.027832 
z"
        />
        <path
          fill="#9FA2A1"
          opacity="1.000000"
          stroke="none"
          d="
M1774.656006,1932.083252 
	C1773.729248,1929.161865 1774.021729,1926.521118 1777.116211,1924.355469 
	C1782.996216,1928.541870 1782.996216,1928.541870 1786.801758,1922.352051 
	C1787.824463,1923.564575 1788.434570,1925.363403 1789.481689,1927.533691 
	C1789.729492,1932.433838 1786.923706,1934.150635 1782.880737,1933.884888 
	C1780.370239,1933.719849 1777.909912,1932.788208 1774.656006,1932.083252 
z"
        />
        <path
          fill="#728DA3"
          opacity="1.000000"
          stroke="none"
          d="
M1758.796631,2012.750244 
	C1763.580078,2009.439331 1763.659668,2014.038208 1765.004639,2016.339355 
	C1766.255859,2018.479614 1766.072876,2020.857788 1763.909668,2022.422485 
	C1762.317383,2023.574341 1760.792969,2023.127319 1760.309570,2021.024048 
	C1759.732056,2018.511475 1759.316162,2015.961792 1758.796631,2012.750244 
z"
        />
        <path
          fill="#EEE9DC"
          opacity="1.000000"
          stroke="none"
          d="
M1851.828125,1945.873413 
	C1849.521851,1945.915283 1847.197388,1945.217285 1844.156738,1944.322144 
	C1843.653931,1943.763916 1843.867432,1943.402832 1844.362549,1942.478394 
	C1847.346313,1942.289795 1850.723145,1941.162476 1851.828125,1945.873413 
z"
        />
        <path
          fill="#7392AD"
          opacity="1.000000"
          stroke="none"
          d="
M2564.534180,2074.449219 
	C2566.885254,2074.110596 2568.551270,2074.163818 2569.791260,2075.423584 
	C2571.540771,2077.201660 2571.634766,2078.885254 2568.916992,2079.692383 
	C2564.854248,2080.899414 2563.298340,2079.299561 2564.534180,2074.449219 
z"
        />
        <path
          fill="#7392AD"
          opacity="1.000000"
          stroke="none"
          d="
M2575.867676,2067.601074 
	C2573.455566,2067.322266 2572.068604,2066.417480 2573.691895,2064.660889 
	C2574.862793,2063.393799 2576.942627,2061.642334 2578.512451,2063.349609 
	C2580.401855,2065.404541 2577.989258,2066.477539 2575.867676,2067.601074 
z"
        />
        <path
          fill="#7793AA"
          opacity="1.000000"
          stroke="none"
          d="
M2464.095947,2060.167480 
	C2466.810547,2060.717285 2468.698486,2061.454102 2470.653564,2061.761963 
	C2475.864746,2062.582275 2477.117188,2064.576904 2473.352783,2068.884521 
	C2472.357910,2070.023193 2472.369385,2071.991211 2471.729980,2073.498535 
	C2469.153320,2079.570801 2466.439697,2081.017822 2461.188477,2077.296875 
	C2455.384521,2073.184326 2450.583984,2075.857422 2445.470215,2077.762695 
	C2443.322266,2078.562988 2441.528076,2080.318604 2439.377686,2081.104736 
	C2435.632080,2082.474121 2431.152832,2080.478760 2430.420898,2077.213379 
	C2429.521729,2073.200928 2431.522949,2070.500488 2435.360107,2069.998779 
	C2445.406006,2068.685059 2453.573975,2062.240234 2464.095947,2060.167480 
z"
        />
        <path
          fill="#738EA6"
          opacity="1.000000"
          stroke="none"
          d="
M2239.142334,2160.713867 
	C2238.724365,2159.225098 2239.248047,2158.688965 2240.401611,2158.918213 
	C2240.641846,2158.966064 2240.965332,2159.305664 2241.001221,2159.547852 
	C2241.175049,2160.715332 2240.605957,2161.202881 2239.142334,2160.713867 
z"
        />
        <path
          fill="#7793AA"
          opacity="1.000000"
          stroke="none"
          d="
M2603.395752,2154.104492 
	C2604.517822,2154.987549 2605.035645,2155.975098 2605.781982,2157.471680 
	C2604.249023,2157.678223 2601.898438,2158.684326 2601.128662,2156.128174 
	C2600.827148,2155.126709 2601.843506,2154.521973 2603.395752,2154.104492 
z"
        />
        <path
          fill="#F5F1E5"
          opacity="1.000000"
          stroke="none"
          d="
M1997.363770,1939.718018 
	C2003.712280,1942.294067 2009.114624,1940.098389 2014.744751,1939.208740 
	C2014.101318,1935.386719 2011.564697,1936.308350 2009.960083,1935.546753 
	C2005.875610,1933.608765 2004.000854,1925.227539 2006.926514,1922.002075 
	C2007.951294,1920.872314 2009.124390,1920.850464 2010.208496,1922.143311 
	C2011.219360,1923.348999 2011.974365,1924.983643 2014.463379,1924.751099 
	C2016.962769,1922.045776 2014.366577,1917.750122 2017.360107,1914.390259 
	C2018.336060,1914.532837 2018.708984,1915.130493 2019.595459,1915.860596 
	C2021.596924,1917.657349 2023.366089,1919.154175 2023.376709,1922.306274 
	C2022.974854,1926.798462 2024.430420,1930.138550 2026.266479,1933.328857 
	C2028.195435,1936.680420 2028.515991,1940.225586 2027.812744,1944.737793 
	C2020.513428,1946.996094 2013.196045,1946.406982 2005.031250,1946.836426 
	C2003.764526,1947.072388 2003.388550,1947.129395 2002.449951,1947.092773 
	C1999.204834,1945.935059 1995.496338,1947.019653 1993.983154,1942.515869 
	C1994.247314,1941.509033 1994.621704,1941.262939 1995.395508,1940.476562 
	C1996.144165,1939.891235 1996.493286,1939.846313 1997.363770,1939.718018 
z"
        />
        <path
          fill="#A9AEB3"
          opacity="1.000000"
          stroke="none"
          d="
M1884.506714,1947.562012 
	C1877.348511,1951.995605 1869.385498,1949.482300 1860.745117,1949.905884 
	C1860.249512,1948.756226 1860.676025,1947.736816 1861.529785,1946.363281 
	C1867.561279,1945.475952 1873.264893,1946.746338 1879.401123,1944.536743 
	C1881.995239,1944.118530 1883.494141,1944.963379 1884.506714,1947.562012 
z"
        />
        <path
          fill="#79868C"
          opacity="1.000000"
          stroke="none"
          d="
M1916.350830,1934.392334 
	C1917.789062,1931.384644 1918.394531,1928.591309 1917.470703,1924.770264 
	C1918.572998,1924.523315 1919.698730,1925.024536 1921.376221,1925.794800 
	C1921.567383,1929.787109 1922.447510,1934.239502 1916.350830,1934.392334 
z"
        />
        <path
          fill="#79868C"
          opacity="1.000000"
          stroke="none"
          d="
M1900.124390,1934.099365 
	C1902.491211,1933.745117 1905.328369,1933.954102 1908.982666,1934.365967 
	C1906.752075,1936.627930 1903.682617,1936.541504 1900.124390,1934.099365 
z"
        />
        <path
          fill="#8C8D89"
          opacity="1.000000"
          stroke="none"
          d="
M2001.978027,1947.359375 
	C2002.262939,1946.907837 2002.638672,1946.816772 2003.577393,1946.870605 
	C2003.691528,1947.960815 2003.526978,1949.742676 2001.978027,1947.359375 
z"
        />
        <path
          fill="#A4BED0"
          opacity="1.000000"
          stroke="none"
          d="
M1995.330688,1940.057617 
	C1995.604370,1940.546021 1995.245117,1941.030884 1994.295166,1941.572876 
	C1993.944458,1941.065796 1994.275513,1940.540527 1995.330688,1940.057617 
z"
        />
        <path
          fill="#3A4F62"
          opacity="1.000000"
          stroke="none"
          d="
M2548.864746,1928.298584 
	C2547.594482,1923.297607 2544.414551,1920.960449 2539.866699,1919.620239 
	C2537.432129,1918.902588 2534.242920,1918.050903 2534.558594,1914.686768 
	C2534.841553,1911.667358 2538.035889,1912.314453 2540.098633,1911.568604 
	C2547.289551,1908.968262 2554.456055,1909.577759 2561.489502,1911.953857 
	C2569.127930,1914.534180 2577.949219,1914.530762 2583.754639,1922.335571 
	C2583.797852,1924.945068 2582.310059,1925.642334 2579.979004,1926.474365 
	C2578.663086,1926.714233 2578.134766,1926.766113 2576.820801,1926.853516 
	C2570.962402,1926.931396 2565.918701,1927.512329 2560.098633,1927.755127 
	C2556.118896,1927.953979 2552.895020,1928.105957 2548.864746,1928.298584 
z"
        />
        <path
          fill="#EFF1EA"
          opacity="1.000000"
          stroke="none"
          d="
M2285.965820,1929.997559 
	C2290.421387,1924.571655 2293.782715,1930.721436 2297.635986,1931.334351 
	C2298.874512,1931.531494 2299.649902,1933.814087 2300.941162,1934.698730 
	C2302.905029,1936.044800 2304.785889,1934.815308 2305.915039,1933.332764 
	C2306.208496,1932.947388 2304.438477,1930.990967 2303.469727,1929.191895 
	C2302.829102,1926.946777 2303.424072,1925.608276 2305.346680,1924.151611 
	C2307.288818,1923.988770 2308.517090,1924.137085 2310.351074,1924.246582 
	C2311.165283,1929.449951 2312.022217,1934.358032 2317.990967,1936.226196 
	C2315.288818,1940.944092 2311.158691,1939.461060 2307.027344,1940.372070 
	C2305.903076,1940.502441 2305.398682,1940.400269 2305.280029,1940.569214 
	C2301.036133,1946.604248 2296.526855,1950.091797 2290.246338,1942.742065 
	C2289.714355,1942.119629 2287.736572,1942.516724 2286.491943,1942.757935 
	C2281.662354,1943.693604 2282.060791,1940.436035 2282.057617,1936.703369 
	C2282.027100,1935.975708 2281.958252,1936.000122 2281.966797,1936.036621 
	C2282.911377,1935.204468 2282.983887,1933.971069 2283.699707,1932.385498 
	C2284.064941,1931.916016 2283.952881,1931.963745 2283.939941,1932.023315 
	C2284.401855,1931.601318 2284.889160,1931.132690 2285.733887,1930.305420 
	C2286.078857,1929.933594 2285.978760,1929.948486 2285.965820,1929.997559 
z"
        />
        <path
          fill="#E7E3DA"
          opacity="1.000000"
          stroke="none"
          d="
M2429.300537,1927.771973 
	C2429.802490,1925.521729 2430.239990,1923.873535 2430.647949,1921.583740 
	C2430.731445,1918.412842 2432.621826,1918.474976 2434.987793,1918.077637 
	C2436.663574,1915.843384 2437.739746,1913.888062 2439.249756,1911.144531 
	C2441.901611,1917.237793 2442.439209,1922.936279 2443.970703,1929.183716 
	C2443.555908,1934.415161 2441.627930,1937.943604 2436.984863,1933.917603 
	C2434.515381,1931.776245 2429.153809,1933.750488 2429.300537,1927.771973 
z"
        />
        <path
          fill="#E7E3DA"
          opacity="1.000000"
          stroke="none"
          d="
M2493.358398,1929.093506 
	C2493.310547,1923.605103 2497.278564,1920.297729 2498.452637,1915.861450 
	C2498.798096,1914.555420 2499.833252,1913.329102 2501.195557,1913.400513 
	C2502.817627,1913.485596 2504.416992,1914.303955 2505.193848,1915.906982 
	C2508.023438,1921.743652 2502.509766,1925.633301 2501.525391,1930.629517 
	C2500.781494,1934.404053 2497.094727,1931.257446 2494.109863,1931.952881 
	C2493.347900,1931.180054 2493.341064,1930.420898 2493.358398,1929.093506 
z"
        />
        <path
          fill="#D0D1C8"
          opacity="1.000000"
          stroke="none"
          d="
M2331.782227,1919.151123 
	C2333.903076,1916.536865 2335.358398,1914.106934 2337.144531,1911.124512 
	C2339.332764,1915.037842 2341.586670,1918.367554 2342.517578,1923.159668 
	C2341.950195,1924.943359 2341.455811,1926.385620 2340.450928,1927.025513 
	C2334.538086,1930.789917 2332.854248,1929.659058 2332.208008,1920.921143 
	C2331.859619,1919.879517 2331.488037,1919.607544 2331.782227,1919.151123 
z"
        />
        <path
          fill="#C4C8C1"
          opacity="1.000000"
          stroke="none"
          d="
M2559.652100,1928.514160 
	C2562.931885,1921.140015 2569.247070,1924.389648 2575.335938,1923.710327 
	C2577.165771,1925.201294 2577.464111,1926.720459 2576.323242,1928.962158 
	C2570.486328,1930.168945 2565.116455,1934.841675 2559.652100,1928.514160 
z"
        />
        <path
          fill="#646A6C"
          opacity="1.000000"
          stroke="none"
          d="
M2332.147705,1918.236084 
	C2332.447754,1918.966797 2332.500732,1919.588257 2332.365723,1919.869873 
	C2328.288330,1919.166748 2323.629395,1921.726929 2320.032959,1917.044434 
	C2320.452148,1915.624512 2321.134521,1914.901855 2321.811768,1914.174194 
	C2326.933838,1908.670654 2327.551514,1908.774170 2330.966309,1915.714478 
	C2331.259766,1916.311646 2331.553955,1916.908447 2332.147705,1918.236084 
z"
        />
        <path
          fill="#C4C8C1"
          opacity="1.000000"
          stroke="none"
          d="
M2577.551270,1924.722168 
	C2579.481445,1923.754150 2580.978516,1923.545654 2583.208984,1923.150391 
	C2584.867920,1923.171631 2585.793213,1923.379639 2587.413574,1923.742798 
	C2588.993408,1926.091675 2590.502930,1928.273315 2587.876465,1931.003662 
	C2585.081055,1931.576782 2582.645508,1931.673706 2579.478027,1931.668701 
	C2575.618164,1930.229980 2577.549805,1927.539795 2577.551270,1924.722168 
z"
        />
        <path
          fill="#EFF1EA"
          opacity="1.000000"
          stroke="none"
          d="
M2472.108887,1916.870972 
	C2475.373047,1919.933716 2473.588867,1924.369263 2474.112549,1929.302002 
	C2470.537354,1930.687256 2469.479980,1928.687622 2469.463379,1924.797607 
	C2470.235352,1921.860596 2471.073486,1919.697998 2472.108887,1916.870972 
z"
        />
        <path
          fill="#C4BDAE"
          opacity="1.000000"
          stroke="none"
          d="
M2479.711426,1931.375000 
	C2480.376953,1930.575806 2481.302979,1930.273071 2482.947510,1929.843506 
	C2483.691895,1932.590576 2483.167725,1934.521240 2479.711426,1931.375000 
z"
        />
        <path
          fill="#8C8D89"
          opacity="1.000000"
          stroke="none"
          d="
M2577.550781,1923.813721 
	C2578.120361,1926.002197 2578.256348,1928.041748 2578.569336,1930.823975 
	C2577.991943,1931.244507 2577.237549,1930.922241 2576.127441,1930.077881 
	C2575.859131,1928.112061 2575.947021,1926.668457 2576.121582,1924.501221 
	C2576.208496,1923.777710 2577.117676,1923.664673 2577.550781,1923.813721 
z"
        />
        <path
          fill="#6A8297"
          opacity="1.000000"
          stroke="none"
          d="
M2213.691895,1921.929932 
	C2214.772217,1921.936523 2215.233643,1921.790649 2215.394531,1921.949463 
	C2222.499756,1928.965698 2227.871826,1924.619507 2233.442871,1918.740479 
	C2237.048584,1915.515381 2240.154541,1912.514648 2245.603027,1912.978394 
	C2249.037109,1913.746094 2252.063721,1914.307617 2251.926514,1917.745239 
	C2251.692383,1923.608398 2255.242676,1924.102051 2259.645508,1924.027344 
	C2264.629639,1923.942993 2269.616699,1924.026611 2275.568359,1924.145020 
	C2279.008057,1927.407837 2280.972412,1930.655762 2278.232666,1935.344849 
	C2248.700928,1940.095337 2219.221924,1937.098877 2188.892090,1938.001953 
	C2175.684082,1935.030518 2175.303467,1934.532715 2178.855469,1924.795532 
	C2182.788086,1923.898193 2185.734375,1921.759766 2190.076172,1922.460815 
	C2189.965576,1926.307129 2190.095215,1929.453613 2195.106445,1929.309692 
	C2200.201660,1929.163452 2206.070312,1930.487183 2208.261719,1922.685547 
	C2209.931396,1921.924683 2211.432373,1921.922119 2213.691895,1921.929932 
z"
        />
        <path
          fill="#79868C"
          opacity="1.000000"
          stroke="none"
          d="
M2105.024658,1900.720581 
	C2107.340332,1899.568359 2109.288330,1898.261475 2110.586182,1901.007324 
	C2111.770752,1903.512695 2111.963623,1906.102783 2111.967041,1908.792114 
	C2111.971680,1912.122925 2112.062988,1915.455933 2112.220947,1918.783081 
	C2112.304688,1920.542236 2113.059814,1922.396362 2114.870117,1922.502319 
	C2116.732422,1922.611206 2117.537842,1920.852173 2117.717285,1919.054565 
	C2118.041016,1915.811157 2116.837891,1912.373047 2119.290771,1908.828125 
	C2122.757324,1910.258057 2124.241699,1912.471313 2122.924316,1916.301392 
	C2121.223633,1921.246338 2124.185303,1922.287598 2128.054932,1922.046753 
	C2132.071045,1921.796875 2137.093262,1923.918091 2139.059326,1915.567139 
	C2141.329346,1922.914795 2145.132568,1924.850830 2151.168945,1924.152588 
	C2145.949951,1933.693481 2144.711182,1934.135620 2134.022949,1932.375488 
	C2127.487061,1931.299194 2121.006348,1931.481934 2114.510742,1935.751465 
	C2112.234375,1937.376465 2110.473145,1937.549438 2107.829102,1936.405029 
	C2103.593750,1924.605835 2104.475098,1913.028931 2105.024658,1900.720581 
z"
        />
        <path
          fill="#50494B"
          opacity="1.000000"
          stroke="none"
          d="
M2104.971680,1899.587646 
	C2109.301758,1910.992432 2108.275146,1922.380127 2107.807129,1934.638916 
	C2106.931152,1937.084473 2105.460693,1937.518188 2103.027832,1937.489746 
	C2095.675537,1933.437012 2087.151855,1934.442993 2080.561035,1928.578613 
	C2079.714111,1927.459717 2079.324219,1926.927368 2078.415771,1926.183594 
	C2078.073730,1921.984009 2078.060059,1918.255615 2082.791748,1916.472900 
	C2085.063477,1915.616821 2086.880859,1913.554932 2089.613281,1911.903564 
	C2093.705078,1910.715942 2096.569092,1909.348999 2094.091309,1904.427124 
	C2093.389160,1899.884155 2094.046875,1896.160645 2097.480225,1892.547363 
	C2102.817627,1892.147827 2101.585205,1897.496704 2104.971680,1899.587646 
z"
        />
        <path
          fill="#D7D5CF"
          opacity="1.000000"
          stroke="none"
          d="
M2179.079102,1923.654907 
	C2177.919189,1932.224609 2177.932373,1932.184082 2185.434082,1934.633301 
	C2186.242188,1934.896973 2186.786621,1935.967896 2187.702637,1937.337402 
	C2178.364502,1941.330933 2176.618164,1940.988770 2170.960205,1934.321167 
	C2168.176758,1932.936890 2168.178467,1930.430542 2166.460449,1928.373169 
	C2166.043701,1927.900635 2165.517334,1927.631104 2165.223145,1927.661133 
	C2162.603516,1927.042603 2162.741699,1925.289429 2163.483887,1922.704102 
	C2164.895508,1919.137939 2165.698975,1916.265015 2166.871582,1913.551758 
	C2167.817383,1911.364014 2167.996338,1907.137207 2171.344971,1908.496094 
	C2173.850586,1909.513062 2177.617676,1910.945679 2177.744629,1915.161987 
	C2177.823730,1917.781616 2178.345947,1920.388062 2179.079102,1923.654907 
z"
        />
        <path
          fill="#E7E3DA"
          opacity="1.000000"
          stroke="none"
          d="
M2101.371826,1934.906738 
	C2103.633057,1935.459595 2105.040771,1935.664551 2107.144531,1935.707520 
	C2109.331543,1935.667725 2110.822754,1935.790161 2113.060059,1935.992432 
	C2113.763672,1938.866089 2115.279053,1942.837280 2110.750000,1943.098999 
	C2099.842285,1943.729370 2088.906494,1945.221802 2077.990234,1943.961548 
	C2073.795166,1943.477295 2074.729004,1940.773438 2077.576416,1938.013428 
	C2084.621094,1929.375244 2092.941650,1934.735962 2101.371826,1934.906738 
z"
        />
        <path
          fill="#797771"
          opacity="1.000000"
          stroke="none"
          d="
M2101.976074,1934.586426 
	C2094.860107,1937.650391 2087.104004,1935.491211 2078.900146,1937.378418 
	C2078.729492,1934.872925 2079.337646,1932.177856 2079.965820,1928.780396 
	C2087.466064,1929.157837 2094.669189,1931.266724 2101.976074,1934.586426 
z"
        />
        <path
          fill="#B6BCC1"
          opacity="1.000000"
          stroke="none"
          d="
M2061.735840,1924.530029 
	C2062.770996,1919.810425 2060.965088,1915.426514 2063.147217,1911.046997 
	C2067.034180,1911.778198 2065.989990,1914.875488 2066.180908,1917.165039 
	C2066.505371,1921.055908 2065.761475,1925.313721 2071.001221,1927.802002 
	C2070.134033,1934.155884 2065.448730,1931.591187 2061.088867,1931.854980 
	C2058.229492,1929.184326 2058.274170,1926.899048 2061.735840,1924.530029 
z"
        />
        <path
          fill="#79868C"
          opacity="1.000000"
          stroke="none"
          d="
M2163.238770,1921.982544 
	C2164.143555,1923.386475 2164.354492,1924.831055 2164.747314,1926.983521 
	C2161.939453,1927.604126 2158.541504,1929.192993 2156.218018,1924.893188 
	C2158.059570,1923.018188 2160.130371,1921.999390 2163.238770,1921.982544 
z"
        />
        <path
          fill="#8C8D89"
          opacity="1.000000"
          stroke="none"
          d="
M2061.498047,1924.015991 
	C2062.864746,1926.610840 2059.047363,1927.879028 2060.263184,1931.202148 
	C2059.925781,1932.908447 2059.543213,1934.035767 2058.694336,1935.528564 
	C2058.123047,1932.490845 2058.017822,1929.087524 2058.015625,1924.834473 
	C2059.019531,1923.997437 2059.920410,1924.010132 2061.498047,1924.015991 
z"
        />
        <path
          fill="#79868C"
          opacity="1.000000"
          stroke="none"
          d="
M2166.048340,1928.633545 
	C2169.253174,1927.760742 2169.842285,1929.987793 2170.290771,1933.156982 
	C2167.391846,1933.889038 2166.041748,1932.290039 2166.048340,1928.633545 
z"
        />
        <path
          fill="#E59F70"
          opacity="1.000000"
          stroke="none"
          d="
M2044.666992,1919.760498 
	C2055.517822,1923.937622 2055.506104,1923.936035 2054.041992,1934.771484 
	C2053.869385,1936.048950 2053.914062,1937.355835 2053.757812,1939.523193 
	C2048.809326,1941.557983 2043.945679,1942.733765 2038.918091,1941.807007 
	C2035.340088,1941.147339 2033.377930,1939.200073 2035.277100,1935.498657 
	C2037.984497,1930.221069 2039.805420,1924.422485 2044.666992,1919.760498 
z"
        />
        <path
          fill="#4C565C"
          opacity="1.000000"
          stroke="none"
          d="
M2024.134277,1922.760132 
	C2021.495605,1921.496582 2020.644775,1919.513794 2020.153564,1916.651978 
	C2023.103638,1916.004883 2026.101685,1915.934570 2029.091675,1916.056885 
	C2031.128296,1916.140381 2033.447754,1916.560059 2033.398315,1919.125732 
	C2033.348389,1921.709351 2030.962036,1921.838745 2028.958008,1922.063599 
	C2027.633911,1922.212280 2026.312500,1922.385864 2024.134277,1922.760132 
z"
        />
        <path
          fill="#BAB4A7"
          opacity="1.000000"
          stroke="none"
          d="
M2597.892578,1924.505981 
	C2595.153809,1916.533691 2599.495605,1910.235718 2600.895996,1902.787842 
	C2601.704346,1902.122925 2602.062012,1902.126831 2602.700439,1902.090698 
	C2603.729004,1901.172852 2604.637695,1900.957031 2606.340820,1901.868164 
	C2608.701416,1906.160522 2612.079834,1907.854126 2615.821777,1909.053711 
	C2620.452148,1910.538452 2622.491211,1913.769409 2622.138672,1919.282471 
	C2621.797852,1920.483276 2621.599365,1920.942261 2620.934082,1921.983032 
	C2619.641602,1923.105713 2618.747314,1923.482544 2617.073242,1923.741211 
	C2611.945801,1922.584351 2610.318359,1926.847412 2606.656250,1928.208984 
	C2603.107666,1927.823608 2599.634521,1929.311401 2597.892578,1924.505981 
z"
        />
        <path
          fill="#ECA777"
          opacity="1.000000"
          stroke="none"
          d="
M2628.679199,1927.264648 
	C2628.432617,1928.030518 2628.392090,1928.859619 2628.441895,1929.269409 
	C2626.682617,1939.601929 2624.905273,1940.673584 2616.903809,1935.943604 
	C2615.988770,1935.265015 2615.604004,1934.988892 2614.836670,1934.112793 
	C2613.974365,1932.138916 2613.958740,1930.744385 2614.493164,1928.680420 
	C2615.734863,1925.944702 2617.594482,1924.591797 2619.601074,1922.493652 
	C2620.090820,1922.044678 2620.016113,1921.986328 2620.029785,1922.031372 
	C2621.509521,1920.021729 2622.512451,1916.789185 2625.843994,1918.022461 
	C2629.821533,1919.494873 2628.936035,1923.278320 2628.679199,1927.264648 
z"
        />
        <path
          fill="#F5F1E5"
          opacity="1.000000"
          stroke="none"
          d="
M2617.394531,1927.698242 
	C2616.947754,1929.874512 2616.657715,1931.195679 2616.247559,1933.183350 
	C2614.188721,1935.333130 2612.646484,1933.819214 2610.400879,1932.780762 
	C2608.054199,1931.992432 2606.683838,1931.169434 2606.166992,1928.614258 
	C2607.302002,1925.386963 2608.397461,1922.635986 2609.959473,1920.181885 
	C2612.360107,1916.410645 2613.455078,1919.654419 2615.231201,1921.769775 
	C2617.131592,1923.435181 2617.754639,1924.972534 2617.394531,1927.698242 
z"
        />
        <path
          fill="#BAB4A7"
          opacity="1.000000"
          stroke="none"
          d="
M2609.996338,1933.049316 
	C2611.691406,1931.851562 2613.239014,1932.801636 2615.483887,1933.680176 
	C2616.126953,1933.849976 2616.251709,1934.697998 2616.312500,1935.119629 
	C2614.075684,1935.719238 2611.633057,1936.386475 2609.996338,1933.049316 
z"
        />
        <path
          fill="#FBC18E"
          opacity="1.000000"
          stroke="none"
          d="
M2223.686035,1990.719238 
	C2243.638672,1992.491577 2263.044922,1995.823120 2282.697510,1993.178345 
	C2286.119629,1992.717896 2289.291504,1993.800293 2293.341797,1993.252686 
	C2295.628906,1993.215454 2297.165283,1993.387329 2299.472656,1993.461426 
	C2301.880615,1997.036987 2300.323975,1999.119141 2296.730957,1999.757568 
	C2292.845947,2000.447998 2288.953857,2001.358765 2284.949951,2000.983521 
	C2265.896973,1999.198608 2246.765137,2000.937134 2226.819092,1999.465820 
	C2222.034180,1997.581543 2221.234619,1994.859131 2223.686035,1990.719238 
z"
        />
        <path
          fill="#959693"
          opacity="1.000000"
          stroke="none"
          d="
M2226.301270,1999.855225 
	C2226.532227,1998.954224 2227.139648,1998.399170 2227.718506,1998.427490 
	C2243.431885,1999.197998 2259.202881,1996.927979 2274.879150,1999.245239 
	C2282.155518,2000.320923 2289.317871,2000.141479 2296.406738,1998.058960 
	C2298.091309,1997.564209 2299.369873,1996.718140 2300.083252,1994.174194 
	C2301.621582,1992.090088 2303.256348,1991.725464 2304.670410,1992.968872 
	C2306.079834,1994.208008 2305.877686,1995.939453 2304.938721,1997.465454 
	C2303.281006,2000.159424 2300.928955,2001.949707 2297.679932,2001.937134 
	C2274.548828,2001.847656 2251.417969,2001.700928 2228.287109,2001.515015 
	C2227.743652,2001.510620 2227.205566,2000.835327 2226.301270,1999.855225 
z"
        />
        <path
          fill="#8C8D89"
          opacity="1.000000"
          stroke="none"
          d="
M2648.191162,1940.745605 
	C2648.303711,1940.493164 2648.634277,1940.445068 2649.457275,1940.485352 
	C2649.949707,1940.573608 2649.239990,1941.075195 2649.239990,1941.075195 
	C2649.239990,1941.075195 2648.409180,1940.949951 2648.191162,1940.745605 
z"
        />
        <path
          fill="#E7E3DA"
          opacity="1.000000"
          stroke="none"
          d="
M2677.143066,1875.960938 
	C2677.836670,1878.520264 2677.111084,1880.817749 2674.570557,1881.598511 
	C2671.447998,1882.558105 2668.309082,1884.496582 2664.987061,1883.539795 
	C2660.837891,1882.344604 2661.699219,1879.322388 2663.751709,1875.819824 
	C2668.295410,1875.442505 2672.290771,1875.651367 2677.143066,1875.960938 
z"
        />
        <path
          fill="#C7C0B1"
          opacity="1.000000"
          stroke="none"
          d="
M2678.366699,1858.703247 
	C2682.818359,1855.496582 2687.687012,1858.971802 2693.341064,1858.099854 
	C2694.162842,1860.440186 2694.162842,1862.683472 2694.162842,1866.581543 
	C2690.206299,1859.918091 2682.794189,1864.203979 2678.366699,1858.703247 
z"
        />
        <path
          fill="#9FA2A1"
          opacity="1.000000"
          stroke="none"
          d="
M2645.248535,1885.543213 
	C2645.344727,1886.013672 2645.164795,1886.527710 2645.119629,1886.516235 
	C2644.366943,1886.322754 2644.345703,1885.904785 2645.248535,1885.543213 
z"
        />
        <path
          fill="#CBC6BA"
          opacity="1.000000"
          stroke="none"
          d="
M2628.784180,1928.029663 
	C2628.184326,1926.458862 2627.937744,1924.887085 2627.687500,1923.315796 
	C2626.815918,1917.846680 2624.135254,1919.228271 2620.618164,1921.839111 
	C2621.193604,1915.362305 2618.586426,1911.634644 2611.629883,1910.743042 
	C2608.693604,1910.366699 2605.814209,1909.072266 2606.065674,1904.332031 
	C2606.724121,1902.796143 2607.506836,1902.210815 2609.132568,1901.601807 
	C2612.254150,1901.114502 2614.659912,1900.677856 2617.797607,1901.244629 
	C2619.385498,1902.096924 2620.281250,1902.726196 2621.674805,1903.586426 
	C2622.756104,1905.989258 2623.817383,1908.161133 2623.819092,1910.333862 
	C2623.822021,1914.273193 2625.988281,1916.183472 2629.890625,1917.832031 
	C2630.414551,1920.863770 2630.212891,1923.614624 2629.924805,1927.192749 
	C2629.838623,1928.020142 2629.135742,1928.028809 2628.784180,1928.029663 
z"
        />
        <path
          fill="#A9ACA9"
          opacity="1.000000"
          stroke="none"
          d="
M2621.005127,1894.186646 
	C2620.249756,1889.365723 2620.205566,1884.482910 2620.276855,1878.698242 
	C2621.267578,1877.121216 2621.986816,1876.285889 2623.149414,1875.117188 
	C2625.815430,1874.466309 2627.714600,1875.007446 2626.920410,1877.736938 
	C2625.522949,1882.538818 2626.212402,1887.395874 2626.017334,1893.099854 
	C2624.583984,1894.021973 2623.150146,1894.073364 2621.005127,1894.186646 
z"
        />
        <path
          fill="#BAB4A7"
          opacity="1.000000"
          stroke="none"
          d="
M2618.417480,1900.916504 
	C2616.121582,1902.902832 2613.715820,1904.207764 2609.989502,1903.561035 
	C2608.387451,1902.054932 2608.373291,1900.657837 2609.431152,1898.634033 
	C2610.220703,1898.031616 2610.587158,1898.027222 2611.503418,1898.007568 
	C2613.589111,1898.007690 2615.124756,1898.023193 2617.432861,1898.089111 
	C2618.251953,1898.881714 2618.298584,1899.623657 2618.417480,1900.916504 
z"
        />
        <path
          fill="#B9A795"
          opacity="1.000000"
          stroke="none"
          d="
M94.205490,1730.666748 
	C95.861794,1730.078369 97.148102,1729.721191 98.196632,1730.827515 
	C98.292984,1730.929321 98.202614,1731.424927 98.073700,1731.490234 
	C96.563744,1732.254272 95.064438,1732.668091 94.205490,1730.666748 
z"
        />
        <path
          fill="#313E41"
          opacity="1.000000"
          stroke="none"
          d="
M526.362793,1872.902344 
	C525.057678,1872.061279 523.274841,1871.091431 524.971558,1869.459595 
	C525.495972,1868.955322 526.109192,1870.348755 526.600586,1871.721313 
	C526.846191,1872.298462 526.571045,1872.743774 526.362793,1872.902344 
z"
        />
        <path
          fill="#233444"
          opacity="1.000000"
          stroke="none"
          d="
M163.063599,3042.942871 
	C163.108246,3043.025391 163.018936,3042.860107 163.063599,3042.942871 
z"
        />
        <path
          fill="#F2EBDD"
          opacity="1.000000"
          stroke="none"
          d="
M817.368652,2968.353516 
	C818.240845,2966.867432 819.055542,2966.074951 820.145386,2964.771973 
	C821.196167,2964.062500 821.971741,2963.863525 823.349670,2963.661377 
	C844.041199,2975.334229 864.584595,2986.226318 885.371887,2996.531738 
	C892.277405,2999.955078 900.386230,3001.650146 909.061890,3001.892334 
	C918.489319,3002.155518 927.729492,3008.003418 936.592468,3012.404785 
	C940.898438,3014.542725 944.582092,3017.131348 949.537659,3014.525391 
	C950.990784,3013.761475 953.152588,3013.489746 954.390625,3014.455566 
	C964.379761,3022.245850 978.247925,3017.237549 988.527710,3026.285400 
	C986.128418,3014.760986 988.799866,3011.258057 1000.647400,3008.672119 
	C999.080383,3005.469727 996.085632,3006.665039 993.707458,3006.523682 
	C985.580566,3006.038574 977.619202,3004.596924 971.081970,2999.625000 
	C963.628601,2993.955811 955.282104,2991.133057 946.249695,2990.196777 
	C929.811401,2988.492676 915.356567,2979.820312 899.185608,2977.139160 
	C895.294739,2976.494141 891.522339,2974.969971 887.477844,2974.891113 
	C873.749756,2974.622559 862.886047,2967.203857 851.829956,2960.404297 
	C848.377930,2958.281250 846.957275,2954.986084 849.513184,2950.411621 
	C850.989014,2948.288330 852.841125,2948.181396 854.967407,2947.328613 
	C879.026550,2963.069092 906.521729,2963.318848 932.993652,2966.490479 
	C951.402771,2968.696289 969.427063,2970.957520 986.326904,2979.131104 
	C992.942932,2982.330811 1000.583862,2982.339111 1008.264465,2984.768555 
	C1017.804321,2985.989014 1027.296753,2989.840820 1035.301636,2985.948730 
	C1041.039062,2983.158936 1045.840698,2983.519043 1051.337280,2983.556641 
	C1058.803467,2983.607910 1064.059570,2987.191650 1067.620728,2993.324463 
	C1071.030273,2999.196045 1075.864258,3003.381348 1081.354370,3007.222900 
	C1084.858521,3002.918213 1083.730957,2998.726074 1082.786743,2994.477783 
	C1081.601562,2989.145264 1082.929810,2985.204346 1089.752441,2984.368896 
	C1090.889526,2984.616943 1091.326050,2984.787842 1092.304688,2985.404297 
	C1094.907471,2990.331055 1096.021362,2995.372070 1100.760132,2999.084961 
	C1101.399658,3000.070068 1101.615112,3000.490967 1102.061401,3001.585693 
	C1103.240479,3007.438477 1102.322876,3012.926025 1105.093994,3017.826172 
	C1105.905396,3019.260742 1106.018188,3021.410156 1105.097046,3022.676270 
	C1098.652466,3031.535400 1099.810913,3043.848877 1091.672607,3051.909668 
	C1088.941528,3054.614990 1087.841553,3058.082520 1087.559570,3062.576660 
	C1087.192261,3064.711914 1086.622314,3066.016113 1085.376709,3067.768555 
	C1084.160400,3068.851318 1083.334961,3069.248779 1081.756836,3069.524658 
	C1078.948120,3069.543457 1076.848999,3068.719971 1074.027588,3069.250000 
	C1072.949951,3069.188721 1072.521729,3069.114990 1071.490967,3068.792236 
	C1067.847778,3065.799561 1064.694336,3063.836426 1060.133301,3067.255371 
	C1058.568726,3067.513428 1057.668457,3067.446045 1056.159912,3066.939941 
	C1047.248657,3057.645996 1046.030518,3057.627197 1037.916016,3066.707520 
	C1036.303345,3067.206543 1035.345337,3067.122070 1033.793213,3066.391602 
	C1031.354492,3063.274902 1030.157349,3060.254883 1025.873047,3060.188477 
	C1012.289246,3059.979004 998.745544,3057.100098 985.115662,3060.265137 
	C979.273438,3061.621826 973.208008,3061.351318 966.438477,3060.967529 
	C957.151672,3062.164307 949.009033,3057.170654 939.678162,3059.702881 
	C937.898682,3060.174316 936.862549,3060.294922 935.050049,3060.275146 
	C924.058960,3057.945068 914.014893,3054.366699 902.553650,3055.998047 
	C899.015991,3056.857178 896.113831,3056.753174 892.539062,3056.998291 
	C884.394836,3059.761963 880.791077,3056.113525 878.684814,3048.777832 
	C875.606140,3043.289795 871.105225,3037.802734 875.523987,3032.665527 
	C880.514221,3026.863770 887.674683,3025.120850 895.566101,3027.875732 
	C900.459839,3029.583740 904.536011,3032.147461 908.082214,3036.250000 
	C908.950745,3028.519531 905.871033,3024.610107 898.696533,3021.689453 
	C880.596619,3014.321289 862.655945,3006.595947 848.054138,2992.682861 
	C842.128723,2987.037354 834.358459,2982.438965 826.122192,2981.313232 
	C817.698975,2980.161621 816.569153,2975.767578 817.368652,2968.353516 
z"
        />
        <path
          fill="#FBF9EC"
          opacity="1.000000"
          stroke="none"
          d="
M1010.517090,2987.346191 
	C1002.340759,2987.699219 995.381409,2983.568604 987.602966,2983.862305 
	C980.639404,2976.817627 969.130981,2979.800537 962.330750,2972.409912 
	C961.084778,2971.055908 959.543701,2970.211426 957.997009,2971.431396 
	C953.928284,2974.639648 950.354553,2974.395996 946.426331,2970.994629 
	C944.349121,2969.195801 941.295837,2970.046875 938.665894,2970.020996 
	C931.370911,2969.948975 924.006104,2970.573242 917.077576,2967.439941 
	C915.181152,2966.582275 913.304199,2966.662109 911.265198,2967.198242 
	C909.381714,2967.693115 906.765381,2968.480957 905.468811,2967.626709 
	C897.228699,2962.197510 887.360596,2964.696533 878.725525,2961.705566 
	C870.408142,2958.824707 861.035645,2957.545166 855.909302,2947.902832 
	C859.850769,2943.538574 864.731384,2940.828125 869.699890,2938.291260 
	C873.598938,2936.300049 876.925598,2933.749268 879.438843,2929.252930 
	C884.346252,2917.065674 894.304321,2915.166992 905.719360,2914.837402 
	C916.863281,2922.535645 930.106323,2923.364014 940.940857,2929.540283 
	C953.838989,2936.892334 970.378235,2938.605957 979.040161,2953.891602 
	C981.683838,2960.719727 984.973450,2965.618408 992.360046,2966.585693 
	C998.221924,2967.353027 1001.011292,2973.651123 1007.247070,2975.500732 
	C1008.907349,2976.145996 1009.762695,2976.712891 1010.996826,2978.077881 
	C1012.628479,2981.462158 1012.657043,2984.117920 1010.517090,2987.346191 
z"
        />
        <path
          fill="#FBF9EC"
          opacity="1.000000"
          stroke="none"
          d="
M849.237061,2949.902100 
	C849.503113,2954.685791 850.915894,2957.968506 855.985657,2959.843994 
	C863.961243,2962.795166 870.210205,2969.535645 879.101440,2970.684570 
	C880.403076,2970.853027 881.638306,2971.655518 882.932556,2971.741943 
	C899.174500,2972.827637 914.333496,2977.634766 929.457397,2983.527344 
	C938.221008,2986.941895 948.105957,2987.785889 957.591309,2988.854492 
	C960.479614,2989.179932 962.466431,2989.751953 964.538452,2991.524902 
	C973.849854,2999.491943 984.042419,3005.043945 996.931824,3002.762695 
	C998.751465,3002.440674 1000.308167,3003.176758 1001.329224,3004.539062 
	C1003.619385,3007.594971 1005.716309,3010.795410 1008.050781,3014.166504 
	C1002.554260,3016.683838 1000.576050,3010.124023 997.550049,3011.453369 
	C994.503906,3012.791504 992.302246,3016.173584 988.888184,3017.137451 
	C988.814209,3021.209229 992.090149,3022.480469 993.736023,3024.834717 
	C994.485413,3025.906738 995.598816,3026.936768 994.861572,3028.377441 
	C993.936035,3030.186035 992.112854,3029.885742 990.492981,3029.891602 
	C989.837524,3029.894043 988.998413,3029.932861 988.553162,3029.572021 
	C981.922302,3024.200928 974.526794,3022.089600 965.910034,3022.758789 
	C962.598816,3023.015869 959.064697,3021.430176 956.445557,3018.157471 
	C954.176392,3015.322266 950.465210,3014.605225 948.263123,3018.695801 
	C946.715820,3021.570557 944.673828,3021.978516 942.735901,3020.125732 
	C935.730896,3013.428711 926.691650,3011.201660 917.950073,3008.304443 
	C915.663025,3007.546387 913.673523,3006.629639 912.208801,3004.625732 
	C910.511780,3002.303955 909.075928,3002.777832 907.906616,3005.218994 
	C906.249756,3008.678467 903.602478,3008.283691 901.470825,3006.148438 
	C898.121277,3002.794434 894.255310,3001.701660 889.714050,3001.853271 
	C888.451843,3001.895264 886.879700,3001.560059 885.934448,3000.797363 
	C878.444824,2994.752441 868.199158,2993.958252 861.061279,2986.847412 
	C853.858337,2979.671875 842.942627,2978.949463 834.420105,2973.817871 
	C830.530518,2971.475830 825.714966,2970.185059 824.046387,2964.378418 
	C832.459290,2959.656006 839.672119,2953.337402 849.237061,2949.902100 
z"
        />
        <path
          fill="#33495B"
          opacity="1.000000"
          stroke="none"
          d="
M756.192505,2913.308105 
	C765.561462,2913.270752 774.841553,2914.619873 785.147583,2914.050537 
	C795.940735,2916.279297 806.464783,2917.114990 812.424561,2927.437012 
	C801.426941,2927.665039 790.792908,2928.803223 780.217773,2926.352295 
	C775.529785,2925.265625 770.648499,2925.777344 765.808960,2928.494385 
	C761.396362,2930.971680 756.157227,2932.285156 750.503357,2930.723389 
	C745.389648,2929.310791 740.024170,2929.792480 734.751282,2931.359131 
	C725.860413,2934.000732 716.410522,2934.269287 707.634277,2937.590088 
	C705.856567,2938.262939 703.679443,2937.880615 700.851379,2938.006836 
	C695.975586,2935.962891 691.934875,2933.896729 687.894226,2931.830566 
	C708.265015,2931.456787 726.908264,2921.340332 747.310974,2923.764160 
	C754.653625,2924.636475 755.002747,2923.338867 756.192505,2913.308105 
z"
        />
        <path
          fill="#2B3B46"
          opacity="1.000000"
          stroke="none"
          d="
M906.673401,2916.683594 
	C895.878418,2916.671875 887.862915,2921.293213 880.258301,2928.304199 
	C876.863159,2928.148926 873.958191,2928.030518 870.601807,2926.197266 
	C861.225891,2920.561035 850.792603,2922.804443 841.797180,2919.017578 
	C840.040771,2918.278320 838.042053,2918.076416 836.062805,2916.144043 
	C835.996521,2912.968506 835.773804,2910.789795 832.891113,2909.890625 
	C831.473694,2909.448730 830.558594,2908.374268 831.055664,2906.945068 
	C831.637634,2905.271484 833.130859,2904.224121 834.922119,2904.439453 
	C836.863831,2904.673096 838.750244,2905.360596 840.663025,2905.841064 
	C850.405334,2908.288574 860.100769,2909.062988 870.058716,2906.553955 
	C873.785156,2905.614746 878.188538,2905.534180 881.856567,2906.571289 
	C889.542542,2908.744385 896.890930,2907.929932 905.180664,2906.073242 
	C907.263000,2907.763672 909.104675,2907.939697 911.696411,2908.027832 
	C913.669189,2909.967529 913.185120,2911.813965 911.759521,2914.240234 
	C910.215942,2915.909424 908.919434,2916.590332 906.673401,2916.683594 
z"
        />
        <path
          fill="#2B3B46"
          opacity="1.000000"
          stroke="none"
          d="
M812.499634,2928.599121 
	C811.875671,2924.707520 809.263916,2923.552979 806.665283,2923.379150 
	C799.057861,2922.870361 793.335938,2918.498535 786.692383,2914.359863 
	C786.524231,2909.894775 789.691345,2909.617920 792.605530,2910.176758 
	C798.097351,2911.230469 803.412903,2911.414062 808.928345,2910.150391 
	C812.512451,2909.329102 816.044922,2910.562500 819.524292,2913.430176 
	C821.479065,2916.740479 816.114563,2918.163574 818.333130,2921.628906 
	C817.246338,2925.121582 819.948608,2930.638672 812.499634,2928.599121 
z"
        />
        <path
          fill="#3C5163"
          opacity="1.000000"
          stroke="none"
          d="
M818.955933,2922.115723 
	C816.660583,2919.322754 812.486389,2920.840576 810.911804,2918.179199 
	C813.044800,2914.650391 816.778076,2916.918457 819.503540,2914.504395 
	C820.887024,2914.008301 821.776489,2914.013672 823.333008,2914.010986 
	C825.195068,2914.032959 826.305786,2914.340576 827.839600,2915.251953 
	C830.320129,2915.520996 832.268677,2915.544922 834.940674,2915.507812 
	C846.006775,2915.051514 855.215454,2921.408936 865.708862,2920.459961 
	C867.695740,2920.280518 869.166687,2922.190918 869.745728,2925.084961 
	C862.171326,2926.833740 854.699280,2924.781738 847.373047,2923.499512 
	C838.156494,2921.886230 829.056580,2922.187988 818.955933,2922.115723 
z"
        />
        <path
          fill="#3A4F62"
          opacity="1.000000"
          stroke="none"
          d="
M491.936554,2968.754883 
	C503.394562,2968.067139 514.756165,2968.085205 527.052368,2968.133301 
	C529.626648,2973.953369 534.374939,2972.281982 537.785522,2971.470215 
	C542.695923,2970.301270 547.544556,2969.983643 552.497070,2970.002686 
	C557.733154,2970.022949 562.748474,2969.496094 566.668213,2974.823730 
	C569.254700,2978.339600 574.327637,2978.077148 578.707397,2978.069580 
	C588.585449,2978.052002 598.512146,2977.466064 609.068176,2980.054688 
	C610.096313,2983.534180 609.068115,2986.014648 605.031006,2985.956055 
	C598.045898,2985.854736 590.852722,2986.734375 584.131287,2985.343994 
	C576.458374,2983.756592 568.907959,2984.368164 561.305420,2984.004639 
	C557.776428,2983.835693 555.036560,2984.405273 553.397034,2988.363770 
	C551.716248,2992.421631 547.541138,2994.090332 543.477905,2993.852539 
	C531.596313,2993.157959 519.801331,2995.867188 507.923492,2994.480713 
	C506.670776,2994.334473 505.102081,2994.462402 504.086365,2995.105225 
	C495.845001,3000.321289 488.013031,2996.093506 480.227386,2993.973633 
	C477.014893,2993.099365 473.884186,2991.383789 470.588715,2992.107422 
	C459.623383,2994.514404 448.962585,2992.294678 438.255463,2990.484375 
	C436.157745,2990.129639 434.369598,2991.113770 432.433441,2991.468750 
	C429.313965,2992.041016 426.080627,2992.689697 422.418762,2990.190430 
	C425.542694,2987.340332 428.706696,2985.944824 431.820038,2986.319336 
	C440.485382,2987.361328 448.900269,2986.171387 457.324432,2984.565430 
	C459.404053,2984.169189 461.226929,2985.008789 463.174103,2985.338135 
	C468.464203,2986.233154 473.978912,2987.510254 476.406036,2980.405762 
	C477.182404,2978.133057 479.546906,2978.363281 481.408997,2977.785889 
	C485.943756,2976.379639 490.209381,2974.545166 491.936554,2968.754883 
z"
        />
        <path
          fill="#2B3E51"
          opacity="1.000000"
          stroke="none"
          d="
M528.288818,2959.344971 
	C523.028687,2960.583984 518.361023,2959.011230 512.805908,2958.013672 
	C508.200500,2954.793945 503.335632,2956.742920 498.585144,2955.642090 
	C479.506317,2956.187500 460.819489,2954.502930 442.620361,2958.290527 
	C431.112732,2960.685303 419.508209,2960.171631 408.011688,2961.412842 
	C396.385590,2962.667480 384.595306,2958.634766 373.420105,2955.072754 
	C372.339661,2951.378662 377.398041,2952.590332 376.902557,2949.146973 
	C366.535126,2948.623291 355.988342,2948.090820 345.023376,2947.537109 
	C345.815552,2945.800781 346.041992,2944.794922 346.313660,2944.782471 
	C357.548462,2944.270996 368.599213,2941.548340 379.918304,2942.020996 
	C383.346344,2942.164062 385.917816,2942.570801 384.667450,2947.144531 
	C383.673950,2950.778809 386.103790,2952.145508 389.147614,2951.849854 
	C398.173828,2950.973877 407.802521,2954.944824 416.161713,2948.598145 
	C424.451538,2951.017578 429.203827,2945.339355 434.991333,2941.086914 
	C438.709839,2938.354736 444.391296,2939.071777 448.624084,2940.367676 
	C467.573883,2946.168945 486.930634,2943.345947 507.054688,2943.965332 
	C509.748352,2944.033447 511.537201,2943.967285 513.566040,2945.506348 
	C514.934814,2945.946045 515.807129,2945.948730 517.333374,2945.957031 
	C519.243774,2948.574463 521.307556,2949.915039 524.218384,2949.987061 
	C531.057739,2950.156250 530.606873,2954.018066 528.288818,2959.344971 
z"
        />
        <path
          fill="#233444"
          opacity="1.000000"
          stroke="none"
          d="
M344.809204,2991.992676 
	C343.965668,2989.879150 343.956146,2987.777588 343.911560,2984.892090 
	C351.838989,2984.332275 359.464233,2981.070801 367.505341,2981.832031 
	C368.639191,2981.939697 370.470947,2981.280029 370.961029,2980.404785 
	C372.792084,2977.135254 375.139679,2974.644043 378.819427,2976.831055 
	C383.529236,2979.630371 389.296021,2981.719971 390.986053,2987.709717 
	C391.564911,2989.761230 388.923279,2989.707031 386.675079,2989.996582 
	C376.348785,2996.198975 365.782135,2992.664551 354.776367,2992.453613 
	C351.198425,2991.994141 348.420837,2991.999756 344.809204,2991.992676 
z"
        />
        <path
          fill="#33495B"
          opacity="1.000000"
          stroke="none"
          d="
M354.371033,2992.650391 
	C364.053894,2991.612061 373.981781,2988.946045 385.071716,2990.015869 
	C386.251526,2991.523682 390.207184,2992.419434 386.916687,2994.437988 
	C384.668823,2995.816650 382.084747,2997.593018 379.664124,2997.573242 
	C371.188538,2997.504639 362.586823,2997.475586 354.371033,2992.650391 
z"
        />
        <path
          fill="#243748"
          opacity="1.000000"
          stroke="none"
          d="
M456.921936,2967.996582 
	C458.725189,2962.352051 462.600708,2960.418213 469.218323,2961.747070 
	C475.494263,2963.007324 482.281952,2961.815430 487.864624,2967.228027 
	C477.990387,2967.968506 467.923676,2967.985840 456.921936,2967.996582 
z"
        />
        <path
          fill="#243748"
          opacity="1.000000"
          stroke="none"
          d="
M446.600037,2969.863037 
	C448.324402,2963.534668 449.413666,2963.085693 453.885193,2967.330078 
	C451.777466,2968.600098 449.539001,2969.209717 446.600037,2969.863037 
z"
        />
        <path
          fill="#FBF9EC"
          opacity="1.000000"
          stroke="none"
          d="
M816.763855,2967.834473 
	C821.373535,2970.094971 819.222351,2974.380371 820.414612,2977.637939 
	C828.006409,2975.430664 833.213257,2981.090088 839.324036,2983.474121 
	C849.925232,2987.610596 856.108276,2998.471436 866.272522,3002.798096 
	C874.518555,3006.308105 881.116028,3012.540039 890.354187,3014.743164 
	C896.246216,3016.148438 902.205078,3019.872070 908.271179,3022.273438 
	C909.377380,3022.711426 911.084961,3024.394531 910.895996,3024.915771 
	C908.852112,3030.553223 912.643311,3034.913086 913.765198,3039.831787 
	C913.948608,3040.635986 913.488953,3041.928711 912.869446,3042.499512 
	C911.770264,3043.511963 910.423523,3043.101318 909.364197,3042.122070 
	C908.148987,3040.999268 906.551697,3040.028076 905.876404,3038.626709 
	C902.480103,3031.577881 895.762268,3030.907715 889.323486,3029.414307 
	C886.791687,3028.827148 884.651550,3028.321533 883.830383,3031.454346 
	C882.756897,3035.549805 875.688538,3029.845215 876.120789,3036.326172 
	C876.374329,3040.127686 879.088928,3042.958008 879.915161,3047.274902 
	C872.559387,3052.915527 863.282898,3053.366699 856.277283,3050.800293 
	C848.255859,3047.861816 843.212341,3049.075928 837.053223,3054.407471 
	C835.553284,3055.145752 834.624817,3055.320801 832.976135,3055.123535 
	C824.016418,3045.441162 813.272156,3042.156738 802.646973,3046.511230 
	C801.156433,3047.412109 800.189758,3047.675049 798.381409,3047.710693 
	C795.972595,3047.274170 794.607605,3046.438232 792.873718,3044.689209 
	C786.562439,3029.924561 782.166016,3015.437988 781.235962,2999.269531 
	C781.478516,2997.353271 781.834778,2996.321533 782.788025,2994.638916 
	C784.961914,2985.133057 789.954041,2979.277588 798.723267,2976.865967 
	C805.124756,2975.105713 810.341431,2971.025146 816.763855,2967.834473 
z"
        />
        <path
          fill="#A36F51"
          opacity="1.000000"
          stroke="none"
          d="
M738.750000,3038.040283 
	C737.352356,3032.727783 734.436218,3031.714355 729.590637,3032.941650 
	C726.018311,3033.846436 725.666809,3031.365723 726.868591,3028.814453 
	C728.361511,3025.645264 731.306335,3023.852783 734.468933,3022.888672 
	C740.043030,3021.189697 744.279541,3017.862549 747.837524,3012.654541 
	C751.976746,3010.298340 755.907471,3008.443115 759.670288,3005.080078 
	C765.382263,3002.331055 770.110168,2998.798340 776.594849,2997.509277 
	C777.758301,2997.623779 778.200562,2997.817139 779.020630,2998.625000 
	C780.751953,3004.907227 777.603271,3008.754883 773.985474,3012.334717 
	C771.628296,3014.666504 769.124512,3016.819336 767.045654,3019.452881 
	C759.121094,3029.491699 759.055908,3029.496582 769.729553,3036.128174 
	C772.304810,3037.727783 773.878723,3039.451416 771.538879,3042.988281 
	C762.675049,3043.973145 755.180725,3040.430176 746.589294,3038.684570 
	C743.781189,3038.180420 741.663391,3038.115479 738.750000,3038.040283 
z"
        />
        <path
          fill="#A36F51"
          opacity="1.000000"
          stroke="none"
          d="
M782.559448,2993.789062 
	C783.300171,2994.860352 783.403870,2995.797607 783.332520,2997.430908 
	C782.077698,2999.254883 780.779236,2999.413574 778.774292,2998.593750 
	C778.200073,2998.200684 777.769043,2997.809326 777.542542,2997.619629 
	C777.931763,2995.049561 779.482239,2993.809570 782.559448,2993.789062 
z"
        />
        <path
          fill="#18202C"
          opacity="1.000000"
          stroke="none"
          d="
M867.883057,3112.723633 
	C867.672302,3115.380615 864.423767,3116.322998 866.457886,3119.195068 
	C867.171692,3120.202881 865.358765,3121.211182 863.704102,3122.016602 
	C862.983765,3123.516113 856.986328,3122.656006 861.837341,3125.894531 
	C866.700562,3129.141357 872.816711,3125.769531 878.060303,3129.615723 
	C878.681030,3131.769775 877.766174,3132.778320 876.022888,3134.029297 
	C872.008789,3136.244629 871.895325,3139.683105 871.489197,3142.893555 
	C871.014038,3146.649658 870.893005,3150.553955 876.153687,3150.761230 
	C881.926819,3150.988525 881.056946,3146.894043 880.497070,3142.559570 
	C880.155701,3140.120605 879.880493,3138.359375 878.969971,3136.037842 
	C878.765930,3134.904785 878.751831,3134.437500 878.945557,3133.294434 
	C883.148132,3128.735840 887.964294,3130.030029 893.281860,3130.804932 
	C895.624390,3138.496826 899.479675,3143.340576 908.436157,3143.283447 
	C911.600525,3145.616943 912.339905,3148.323486 912.033325,3152.078613 
	C911.109375,3156.297363 906.964417,3158.955811 909.402100,3163.898682 
	C910.316956,3173.859375 903.249390,3182.310791 891.962585,3185.584961 
	C890.131348,3186.115967 887.946228,3185.515381 886.534668,3187.348389 
	C896.245361,3186.535645 896.570068,3187.151123 893.732422,3202.017090 
	C892.896851,3204.678223 891.603943,3206.139893 889.317871,3207.702881 
	C876.598572,3212.856445 863.299866,3213.224854 850.616455,3218.332520 
	C843.253113,3206.658691 839.544189,3193.826416 834.372742,3181.618896 
	C831.004761,3173.668701 828.345825,3165.412842 825.490967,3157.251465 
	C823.265076,3150.888916 823.348511,3150.859619 829.729614,3146.039551 
	C827.984009,3143.291748 824.828491,3144.788818 821.800781,3143.243896 
	C824.166321,3140.538086 824.598816,3137.252441 823.578613,3133.102051 
	C824.174316,3130.371094 825.986938,3129.640625 828.470337,3128.706055 
	C833.112976,3126.317139 837.252502,3133.162842 841.410278,3127.652832 
	C842.656067,3122.201904 846.097351,3118.961914 849.743164,3115.019531 
	C855.962341,3112.641113 861.327576,3108.882080 867.883057,3112.723633 
z"
        />
        <path
          fill="#A36F51"
          opacity="1.000000"
          stroke="none"
          d="
M723.938110,3066.746826 
	C728.753418,3067.843018 732.998596,3069.436279 736.580444,3074.268311 
	C739.326294,3077.972900 745.962280,3078.802979 746.443359,3085.325195 
	C746.554871,3086.836182 748.023071,3087.425537 749.476990,3086.999023 
	C755.983276,3085.089844 756.373413,3088.184326 754.779358,3093.222168 
	C754.527344,3094.018311 754.855652,3095.430420 755.449951,3095.994629 
	C760.992310,3101.253906 759.645020,3109.262695 762.944641,3115.086426 
	C769.708374,3127.024658 771.788757,3140.556396 776.818176,3153.067627 
	C779.881470,3160.687988 784.535889,3166.502686 788.359070,3173.222412 
	C792.853210,3181.121582 797.693298,3189.040771 800.377258,3197.924561 
	C801.038574,3200.113525 801.958740,3202.504150 803.489685,3204.103271 
	C811.025818,3211.973389 813.868591,3222.553955 819.602478,3231.464111 
	C822.455505,3235.897705 824.029114,3241.130615 824.031616,3246.621338 
	C824.032532,3248.654053 824.212891,3250.660889 827.137939,3251.893066 
	C827.875793,3253.581299 827.898010,3255.053223 827.922485,3257.262695 
	C828.385864,3263.889648 829.270264,3269.704102 830.246094,3275.541016 
	C831.596802,3283.620117 828.285583,3286.199219 818.738159,3284.568848 
	C815.270081,3278.908691 815.207642,3274.309814 819.319092,3269.416992 
	C825.354675,3262.234131 819.626892,3254.938477 818.335205,3247.796387 
	C817.959778,3245.720459 816.174133,3243.809570 815.870911,3241.406982 
	C815.501770,3238.481445 813.982422,3236.277100 810.738159,3236.422852 
	C807.784973,3236.555420 805.665283,3238.603516 804.858521,3241.171387 
	C803.444458,3245.672119 803.119568,3250.388672 805.826599,3254.628174 
	C807.917969,3257.903809 808.935852,3261.088867 806.177795,3265.937988 
	C802.748779,3258.536865 795.945984,3254.929688 793.727539,3247.111328 
	C791.565613,3239.492432 791.667175,3232.643799 795.449707,3226.004395 
	C797.188293,3222.952637 798.720886,3219.895508 797.898438,3216.631592 
	C794.967896,3204.999512 793.869141,3192.434082 782.338623,3184.895996 
	C778.679199,3182.503662 777.161804,3178.764893 775.617188,3174.427246 
	C772.782654,3166.467285 773.347107,3157.183594 766.965698,3150.502930 
	C766.361267,3149.869629 766.162537,3148.667236 766.114624,3147.712158 
	C765.487610,3135.229004 758.957153,3125.654785 751.102905,3116.652100 
	C749.394043,3114.693359 749.070557,3111.859375 747.998047,3109.483398 
	C744.666199,3102.102295 744.975525,3101.962646 739.401733,3098.586914 
	C740.018555,3097.822510 740.599548,3097.070557 741.215271,3096.348389 
	C741.829285,3095.628418 742.478088,3094.938477 743.122131,3094.223633 
	C735.534668,3087.754639 730.278992,3078.968262 721.027222,3075.088867 
	C721.085266,3071.813232 724.545898,3070.433838 723.938110,3066.746826 
z"
        />
        <path
          fill="#5E4237"
          opacity="1.000000"
          stroke="none"
          d="
M899.894409,3509.207031 
	C898.367615,3512.156494 896.557800,3511.621338 895.070984,3509.964355 
	C891.470459,3505.952148 887.369263,3505.377441 882.509888,3507.388184 
	C881.055237,3507.990234 879.180786,3508.143799 877.628845,3507.839355 
	C873.031738,3506.937256 874.629211,3504.754639 877.383362,3502.255127 
	C884.804260,3497.541260 887.538574,3492.440674 881.640015,3485.132568 
	C878.386292,3481.100342 881.173950,3476.174072 880.533997,3471.654785 
	C878.869446,3459.899902 875.700989,3448.506592 872.601196,3436.429199 
	C872.163208,3435.001953 872.153503,3434.136475 872.129883,3432.622070 
	C864.302368,3425.645020 866.612915,3416.085205 864.152893,3407.265137 
	C863.106506,3399.399658 858.188538,3392.780029 861.583130,3384.598633 
	C863.453857,3383.726074 864.769958,3383.492676 865.992188,3383.017822 
	C869.704224,3381.575195 871.631836,3382.686523 872.003113,3386.745117 
	C873.149292,3399.275635 880.723328,3410.235596 880.880371,3423.091309 
	C880.907837,3425.339111 881.556274,3428.482422 885.269775,3429.843994 
	C885.221497,3437.104980 886.914795,3443.842285 889.861206,3450.121338 
	C895.132812,3461.355225 895.252136,3473.681396 898.163330,3485.385498 
	C900.089172,3493.128174 897.395691,3500.924072 899.894409,3509.207031 
z"
        />
        <path
          fill="#8C5B43"
          opacity="1.000000"
          stroke="none"
          d="
M862.011292,3383.260498 
	C862.020508,3387.077881 861.911072,3390.098877 863.537903,3392.806641 
	C866.064026,3397.010742 866.385559,3401.588623 865.953430,3407.154785 
	C864.049500,3410.817871 861.146790,3411.369141 857.346558,3411.488770 
	C854.597351,3410.704834 853.173828,3409.385986 852.147827,3406.730957 
	C851.993652,3405.999268 852.017029,3405.011230 852.033569,3404.517578 
	C853.641663,3398.459717 855.692261,3392.893799 851.886902,3386.677734 
	C851.804138,3385.140869 851.812988,3384.279297 851.811157,3382.770996 
	C849.368774,3378.264648 848.040955,3374.068848 847.906494,3368.752441 
	C847.868286,3367.135986 847.877808,3366.261963 847.883606,3364.732910 
	C845.611694,3358.906250 844.126770,3353.462891 843.238281,3347.941162 
	C842.360718,3342.487305 840.494324,3337.407715 836.823608,3333.565674 
	C830.113586,3326.543213 826.522217,3319.300293 830.136353,3309.356201 
	C831.845947,3304.651855 829.767822,3300.177490 825.568909,3297.100342 
	C823.149475,3295.327148 820.684204,3293.623779 818.675537,3290.398926 
	C818.168274,3288.396973 818.113342,3286.951172 818.068848,3284.783691 
	C828.922913,3283.490967 830.600464,3281.277832 825.770325,3272.031006 
	C823.144470,3267.003906 826.510376,3263.332031 827.410034,3258.513916 
	C832.985168,3262.352295 832.866943,3268.760254 833.779358,3274.435303 
	C837.427246,3297.125000 843.888184,3318.970459 850.467468,3340.957031 
	C854.061584,3352.967529 862.804626,3363.987793 860.391296,3377.742920 
	C860.138611,3379.183350 861.323792,3380.875732 862.011292,3383.260498 
z"
        />
        <path
          fill="#1D1712"
          opacity="1.000000"
          stroke="none"
          d="
M830.545410,3129.454102 
	C828.817688,3131.751709 826.852661,3131.973389 824.109497,3132.255615 
	C817.335876,3133.267090 813.501953,3130.597656 810.746033,3125.321289 
	C807.548706,3119.199951 803.859985,3113.335205 800.260742,3106.678711 
	C808.389099,3095.898682 818.587891,3094.890625 830.717041,3099.858398 
	C832.304077,3100.557617 833.136597,3101.077148 834.438477,3102.170410 
	C838.409363,3108.346191 844.967041,3110.363770 849.690308,3115.501953 
	C849.815918,3117.964355 850.339783,3120.768555 848.385010,3121.151611 
	C844.165405,3121.978516 846.489441,3126.142334 843.796265,3128.143555 
	C839.339966,3132.122559 835.019531,3126.850342 830.545410,3129.454102 
z"
        />
        <path
          fill="#3A261C"
          opacity="1.000000"
          stroke="none"
          d="
M831.918274,3101.524902 
	C830.126160,3101.982666 828.551270,3102.406738 827.562378,3101.828613 
	C817.768738,3096.103516 809.864624,3102.173096 800.847839,3105.895020 
	C793.107422,3097.850098 788.700684,3087.971680 782.178833,3078.668701 
	C782.519104,3074.167969 785.464294,3072.992920 788.286377,3074.550049 
	C795.985718,3078.799561 804.696472,3077.554199 813.552917,3079.735840 
	C817.115417,3079.123779 818.887695,3080.759277 821.134277,3082.922852 
	C824.980286,3089.448975 831.242859,3093.671387 831.918274,3101.524902 
z"
        />
        <path
          fill="#2E2D30"
          opacity="1.000000"
          stroke="none"
          d="
M849.973816,3218.879395 
	C854.385437,3215.677246 858.825623,3213.015137 863.396606,3210.600830 
	C865.103760,3209.699219 867.517578,3208.385742 868.920471,3208.955566 
	C875.556091,3211.650635 880.764038,3207.311279 887.338745,3206.035400 
	C889.408936,3207.320068 889.540771,3208.869629 888.676514,3211.243652 
	C883.279785,3213.811523 879.439697,3218.596680 874.033447,3218.791748 
	C867.672302,3219.021240 862.122498,3219.887451 858.210632,3225.503174 
	C857.816833,3226.068604 856.321960,3225.866943 854.580444,3226.085938 
	C851.831116,3224.357666 850.836487,3221.998047 849.973816,3218.879395 
z"
        />
        <path
          fill="#ECA777"
          opacity="1.000000"
          stroke="none"
          d="
M645.253784,3095.882812 
	C647.984680,3098.557373 650.579529,3099.202148 653.719177,3097.801270 
	C657.506348,3096.111328 661.261841,3095.573242 665.280945,3097.366943 
	C667.857422,3098.516846 670.293091,3097.514893 672.843750,3096.246094 
	C677.795898,3093.783203 682.950989,3091.949707 688.691895,3094.265137 
	C692.617493,3095.848389 695.157471,3091.843506 698.413269,3090.954834 
	C699.942261,3093.020508 701.480896,3094.746826 702.620361,3096.705566 
	C705.921326,3102.379883 705.490173,3106.409668 701.266968,3111.741943 
	C697.144348,3116.947021 697.041870,3117.169678 703.714172,3120.245361 
	C707.053284,3121.784424 707.924805,3124.180176 708.061523,3127.338379 
	C708.206665,3130.688477 710.035400,3132.422119 713.241089,3131.692871 
	C722.054199,3129.687988 725.841614,3136.011475 730.160950,3141.529541 
	C730.554749,3142.032959 730.643982,3142.763428 730.941467,3143.354736 
	C734.665955,3150.754639 734.669250,3150.752930 727.863831,3157.475342 
	C736.065186,3155.248047 738.880188,3159.115234 740.832275,3165.192627 
	C742.919556,3171.690430 746.183594,3177.801514 748.731506,3184.162598 
	C751.488098,3191.045166 752.569214,3198.252197 751.982788,3205.653076 
	C751.458740,3212.266846 753.016846,3216.835693 758.962646,3221.609375 
	C766.656799,3227.787598 775.421326,3234.484863 776.438354,3246.002930 
	C781.354858,3250.230713 781.263306,3256.207275 782.808899,3261.864502 
	C784.092773,3266.563721 788.733643,3270.372803 792.004578,3274.483154 
	C792.846985,3275.541748 794.409790,3276.126953 795.640198,3275.593018 
	C803.374451,3272.235840 804.631409,3279.187500 807.200317,3283.182617 
	C810.003418,3287.541992 808.418762,3291.581543 805.412964,3295.929443 
	C801.422852,3301.701172 798.301514,3307.572510 790.421631,3309.013428 
	C786.870728,3309.663330 784.402344,3309.572266 783.162231,3306.135498 
	C780.468079,3298.669189 772.471008,3295.939941 768.594055,3289.438477 
	C767.224792,3287.142334 763.705017,3284.609131 760.845520,3287.012939 
	C755.161194,3291.791016 750.438293,3289.136719 744.204285,3285.656738 
	C741.419006,3283.315918 741.760986,3280.661133 738.534668,3277.175049 
	C740.498901,3289.049316 733.486084,3297.739502 733.906372,3308.468750 
	C725.281311,3308.007080 720.925781,3300.183594 723.735657,3291.029297 
	C724.882080,3287.294678 726.672180,3283.740723 728.026245,3280.055420 
	C729.796570,3275.237793 730.021240,3270.433350 726.705505,3265.228516 
	C726.227905,3263.561768 726.148682,3262.581299 726.381470,3260.877930 
	C733.412537,3252.542969 737.973877,3252.161621 744.069214,3259.863525 
	C748.131897,3264.996826 751.275879,3268.743408 758.578552,3264.754150 
	C764.852844,3261.326660 768.102539,3268.435303 773.449036,3272.506348 
	C773.892578,3263.161865 768.136108,3259.777344 763.526367,3256.104980 
	C759.410339,3252.825928 754.752625,3250.169922 750.316589,3247.265381 
	C747.183716,3245.214355 743.647644,3242.729004 744.029297,3238.890381 
	C744.965637,3229.472656 740.022522,3228.591309 732.332214,3228.190186 
	C725.687805,3227.843506 720.604126,3223.665771 721.454163,3215.652344 
	C721.625732,3214.034668 722.592285,3211.835938 720.443115,3211.450684 
	C709.828491,3209.546875 712.845337,3199.676025 709.498474,3192.786377 
	C709.302246,3185.526367 711.343689,3178.566162 702.108826,3175.053711 
	C700.641174,3174.495361 698.150818,3166.511475 695.595642,3162.199951 
	C695.099304,3161.362305 695.062134,3159.898926 694.304077,3159.585938 
	C675.381897,3151.778809 684.673950,3135.474854 683.540894,3122.598877 
	C682.995117,3116.397949 680.447754,3112.301025 674.909119,3115.690186 
	C659.421204,3125.168213 649.696960,3114.682129 638.415161,3106.580078 
	C637.997559,3106.019531 638.019165,3105.873535 638.036804,3105.797363 
	C639.317810,3102.974609 640.581238,3100.227539 641.926758,3096.835938 
	C642.874207,3096.107178 643.739563,3096.023193 645.253784,3095.882812 
z"
        />
        <path
          fill="#A36F51"
          opacity="1.000000"
          stroke="none"
          d="
M818.145630,3290.713379 
	C821.075806,3290.032471 822.953430,3292.147949 825.247559,3293.428223 
	C833.378479,3297.965820 836.155273,3302.905762 832.999146,3311.268555 
	C830.080750,3319.001221 831.620972,3323.792725 837.560120,3329.462158 
	C842.321472,3334.007568 846.606873,3340.156982 846.054504,3347.863037 
	C845.912354,3349.846191 845.956238,3352.012207 847.608093,3353.443359 
	C850.992188,3356.374512 849.036072,3359.487305 848.016846,3363.336426 
	C842.380371,3362.998779 842.212158,3358.022217 841.172241,3354.141602 
	C839.522400,3347.985107 837.532104,3342.158203 832.357178,3338.107910 
	C828.490295,3335.081055 829.682190,3330.268555 827.986206,3326.420410 
	C823.114014,3315.367188 817.299866,3304.332764 818.145630,3290.713379 
z"
        />
        <path
          fill="#805541"
          opacity="1.000000"
          stroke="none"
          d="
M855.660522,3410.387207 
	C858.929138,3409.376221 861.703552,3408.697021 865.194946,3408.008789 
	C867.536560,3408.303467 870.146790,3409.017090 869.409668,3410.536377 
	C865.587830,3418.414795 870.800415,3424.454102 872.541260,3431.545410 
	C862.868713,3430.247314 860.930176,3428.110107 859.601013,3419.076904 
	C859.109619,3415.737549 856.540710,3418.143066 854.265381,3417.895996 
	C853.723389,3417.060059 853.849731,3416.200684 854.037781,3414.692871 
	C854.915771,3413.083740 854.618469,3411.765869 855.660522,3410.387207 
z"
        />
        <path
          fill="#A36F51"
          opacity="1.000000"
          stroke="none"
          d="
M851.246338,3386.318359 
	C857.192932,3388.838867 859.785278,3392.401367 855.414001,3398.242920 
	C854.249756,3399.798340 856.625427,3402.951172 852.648865,3403.924316 
	C850.983887,3398.957031 849.844055,3393.903320 848.949646,3388.806396 
	C848.843689,3388.203125 850.082031,3387.363770 851.246338,3386.318359 
z"
        />
        <path
          fill="#A36F51"
          opacity="1.000000"
          stroke="none"
          d="
M847.360962,3368.532715 
	C851.038574,3371.597656 852.020081,3375.900146 851.868164,3381.336914 
	C845.518860,3379.519531 845.304382,3374.622314 847.360962,3368.532715 
z"
        />
        <path
          fill="#A36F51"
          opacity="1.000000"
          stroke="none"
          d="
M679.948608,3065.044678 
	C682.075317,3064.231689 684.232239,3064.104980 687.198486,3064.008789 
	C687.726379,3066.909668 686.682312,3069.273438 683.444275,3069.569092 
	C680.811401,3069.809326 680.239319,3067.824951 679.948608,3065.044678 
z"
        />
        <path
          fill="#A36F51"
          opacity="1.000000"
          stroke="none"
          d="
M855.864380,3409.531982 
	C855.774536,3411.143311 855.394226,3412.231445 854.556763,3413.681641 
	C852.692810,3411.795410 848.545288,3410.708252 851.513428,3406.404053 
	C853.187073,3407.002441 854.380493,3408.005371 855.864380,3409.531982 
z"
        />
        <path
          fill="#5E4237"
          opacity="1.000000"
          stroke="none"
          d="
M868.457764,3513.630859 
	C868.123047,3515.502197 867.510315,3517.136475 865.003784,3517.513916 
	C864.305664,3515.821289 864.096191,3514.063721 865.424805,3511.884277 
	C866.520264,3511.931885 867.231323,3512.543457 868.457764,3513.630859 
z"
        />
        <path
          fill="#A26847"
          opacity="1.000000"
          stroke="none"
          d="
M853.996277,3441.995117 
	C853.832703,3442.060059 853.672791,3442.129639 853.761536,3442.104492 
	C854.010132,3442.009766 854.000000,3442.000000 853.996277,3441.995117 
z"
        />
        <path
          fill="#A9AEB3"
          opacity="1.000000"
          stroke="none"
          d="
M820.056641,3084.791016 
	C817.950500,3083.010742 816.648804,3081.645508 814.871216,3079.965820 
	C812.345947,3074.017578 808.148376,3069.730713 804.548340,3064.312744 
	C801.390381,3061.601562 799.161865,3059.060059 798.641968,3054.832031 
	C798.600647,3053.281982 799.847107,3052.456055 798.535767,3051.042969 
	C797.360474,3049.850586 796.946533,3048.870361 797.190491,3047.196777 
	C798.432556,3046.553467 799.462219,3046.429688 801.264648,3046.218506 
	C812.396851,3045.011230 822.094727,3046.901611 831.650085,3052.920410 
	C833.118408,3053.488037 833.916565,3053.626221 835.322754,3053.776367 
	C851.452209,3049.834717 866.161621,3055.350342 881.083008,3057.892334 
	C885.435791,3058.633789 889.497925,3059.444336 894.399353,3057.391113 
	C905.516907,3059.906738 915.723633,3063.809082 927.583252,3063.052490 
	C929.932922,3062.858887 931.510559,3062.803223 933.903870,3062.994873 
	C940.244995,3062.815918 945.769653,3063.165527 952.126465,3063.035889 
	C956.266174,3062.666260 959.537720,3061.770996 963.721680,3062.509277 
	C968.310730,3064.792236 972.314331,3064.050049 976.263428,3063.544189 
	C993.559570,3061.328125 1010.887512,3064.360840 1028.191650,3063.207520 
	C1029.841553,3063.097656 1031.447144,3063.829834 1033.540771,3065.248047 
	C1035.023315,3065.739502 1035.826050,3065.766357 1037.231079,3065.783691 
	C1043.162354,3061.848633 1048.719238,3061.687256 1055.236206,3065.382812 
	C1056.888916,3066.018066 1057.777710,3066.035889 1059.333252,3066.038574 
	C1063.684448,3065.171875 1067.316406,3064.817627 1071.284302,3067.639648 
	C1072.253174,3068.159424 1072.601562,3068.191162 1073.474365,3068.225098 
	C1075.598022,3068.047119 1077.200195,3067.996826 1079.405029,3068.177734 
	C1080.902100,3068.237549 1081.800415,3068.236328 1083.372314,3068.243652 
	C1085.982544,3067.798096 1087.869995,3067.786865 1090.175781,3069.375244 
	C1090.912598,3070.333740 1091.109375,3070.789307 1091.390991,3071.988770 
	C1091.210083,3075.919434 1089.647949,3078.432129 1086.799072,3081.043457 
	C1079.508179,3085.609131 1070.990356,3085.590576 1064.175659,3091.309326 
	C1061.090454,3092.706299 1058.540283,3093.008301 1055.172363,3093.013428 
	C1045.962769,3093.972900 1038.664062,3098.863281 1029.695312,3098.852783 
	C1010.433289,3098.830322 991.228760,3098.228271 972.070251,3096.426758 
	C967.882385,3096.033203 964.447693,3093.840088 965.532227,3087.942871 
	C968.563293,3085.316650 971.571167,3085.477295 974.555359,3085.903320 
	C989.219299,3087.996582 1003.614624,3086.532959 1018.570129,3082.681152 
	C1016.887207,3079.660156 1014.184387,3079.563721 1011.806519,3079.788330 
	C990.968506,3081.756592 970.033203,3076.937012 949.184937,3081.003174 
	C940.590515,3082.679688 931.969116,3079.935059 923.493835,3078.621338 
	C908.258972,3076.259766 893.141541,3075.899170 877.982422,3078.559570 
	C873.429138,3079.358887 868.839417,3076.776123 863.502441,3079.312012 
	C866.359985,3078.197754 868.245728,3078.394287 870.474609,3080.245361 
	C871.199280,3086.862549 867.097473,3089.851074 862.455688,3089.783691 
	C848.491272,3089.582275 834.223511,3091.155029 820.056641,3084.791016 
z"
        />
        <path
          fill="#765242"
          opacity="1.000000"
          stroke="none"
          d="
M800.107422,3051.255615 
	C799.743286,3056.028320 802.729431,3058.903564 803.972473,3063.174805 
	C800.558655,3063.798096 797.924194,3061.780273 795.069580,3060.126709 
	C780.439331,3051.653564 765.506409,3043.909912 748.413330,3041.574951 
	C747.643555,3041.469727 747.017456,3040.312744 746.111694,3038.945312 
	C754.355713,3037.657715 762.113403,3041.834717 771.314819,3041.770996 
	C779.187134,3036.935791 785.951843,3038.918213 793.383911,3043.057129 
	C794.932007,3044.366699 795.746582,3045.143555 796.982056,3046.299072 
	C797.656372,3047.609375 797.909851,3048.541260 798.547241,3049.784668 
	C799.407349,3050.247314 799.883545,3050.398682 800.107422,3051.255615 
z"
        />
        <path
          fill="#F9B783"
          opacity="1.000000"
          stroke="none"
          d="
M728.004700,3259.304199 
	C727.978882,3260.885742 727.978516,3261.771484 727.979126,3263.321777 
	C726.153076,3271.684814 723.426331,3279.168213 724.210144,3287.382812 
	C724.549255,3290.936279 723.582947,3294.893555 719.805237,3296.767334 
	C716.082214,3298.613770 713.500427,3296.458496 711.354065,3293.580078 
	C708.424744,3289.651367 706.368652,3292.320312 704.244141,3294.530518 
	C699.789124,3299.166016 696.169373,3298.173340 693.196533,3292.875244 
	C692.382446,3291.424561 691.623230,3289.928955 690.454956,3288.719971 
	C688.772522,3286.979004 686.838135,3285.486572 684.316772,3286.584473 
	C682.295288,3287.464844 681.584229,3289.517822 681.680359,3291.589111 
	C681.924255,3296.846680 681.322449,3302.172119 684.301392,3307.097656 
	C687.073853,3311.681885 686.868347,3316.439453 681.470459,3318.873779 
	C676.372986,3321.172607 673.428162,3317.627197 671.363892,3313.420654 
	C669.887146,3310.411377 668.557190,3307.006592 665.701965,3305.330322 
	C655.833313,3299.536133 650.688538,3291.583252 653.049500,3279.167480 
	C647.896362,3281.936035 646.306091,3286.639404 643.587097,3290.471924 
	C639.200012,3296.656006 635.041809,3303.656006 624.920898,3300.455078 
	C624.219666,3299.080811 624.023682,3298.188721 623.990234,3296.622803 
	C625.619873,3289.141846 630.770569,3283.276123 628.876465,3275.499268 
	C628.463623,3273.803955 630.776550,3271.444824 631.926758,3268.690918 
	C634.362549,3267.992920 636.736450,3267.997559 638.574768,3266.236572 
	C647.538940,3257.647705 655.615601,3248.730713 656.193237,3235.161133 
	C656.442627,3229.302246 658.382080,3223.496582 659.722717,3217.705811 
	C662.525818,3205.598389 662.773560,3193.723633 659.860840,3181.388672 
	C657.817627,3172.736084 660.882080,3163.886719 664.930359,3155.908203 
	C666.924683,3151.978271 668.944580,3148.928711 662.763733,3146.927734 
	C659.464844,3145.859863 660.582031,3141.638184 658.960205,3139.217285 
	C655.276978,3133.720459 653.440491,3127.403564 650.920166,3121.384766 
	C647.526733,3120.061523 648.624146,3125.131592 645.973267,3124.869141 
	C645.588623,3124.432617 644.851196,3123.971191 644.880676,3123.565430 
	C645.116455,3120.312988 647.854431,3116.677002 640.680298,3116.746094 
	C636.137878,3116.790039 638.785156,3110.838623 637.994263,3106.828613 
	C644.763367,3107.627930 650.700439,3110.427979 655.828857,3115.495605 
	C660.564209,3120.174805 665.973389,3116.226562 670.351318,3114.155029 
	C674.238342,3112.315430 677.924622,3111.902344 681.873291,3112.074463 
	C687.026672,3112.299072 689.529541,3114.550293 687.596008,3118.732910 
	C683.193115,3128.257324 685.853271,3137.403320 687.137878,3146.762939 
	C687.351990,3148.322510 686.630066,3149.990967 686.498230,3151.621826 
	C686.181946,3155.533203 688.172180,3156.293945 691.422302,3154.998047 
	C693.172913,3154.299805 694.477844,3152.223145 696.786499,3153.338623 
	C699.135437,3164.700684 699.135437,3164.700684 706.832458,3165.237793 
	C708.116211,3169.256104 702.798035,3170.251221 702.922119,3173.794678 
	C703.781067,3176.199463 705.476379,3174.715820 706.963684,3174.670410 
	C712.786865,3174.492676 714.327698,3175.997314 713.058655,3181.490479 
	C712.373779,3184.454834 712.004150,3187.346191 711.987366,3191.161133 
	C708.666016,3194.490967 710.083984,3197.893555 710.192261,3201.095215 
	C710.496582,3210.090332 708.804810,3213.470703 701.033997,3219.615234 
	C706.975342,3220.104248 712.257812,3222.487549 717.780396,3223.979248 
	C720.442688,3224.698730 723.594849,3226.839111 722.768616,3229.311279 
	C719.124207,3240.214844 727.212830,3248.715088 728.004700,3259.304199 
z"
        />
        <path
          fill="#935F47"
          opacity="1.000000"
          stroke="none"
          d="
M622.560791,3203.594971 
	C619.694702,3201.818359 617.071350,3199.293213 614.033020,3197.026367 
	C608.557800,3192.941895 602.050964,3187.494873 603.574707,3178.624512 
	C604.223816,3174.845947 603.035217,3172.197998 600.878174,3168.811279 
	C598.309509,3164.778564 597.845886,3158.993896 599.137085,3153.908691 
	C600.333374,3149.197266 600.127563,3144.802979 598.467957,3140.304932 
	C595.752136,3132.944580 596.504456,3124.935791 595.062805,3117.214844 
	C591.017090,3117.969971 592.465942,3121.086670 591.433594,3122.855713 
	C590.616028,3124.256348 590.280518,3125.994141 588.688354,3126.818848 
	C581.561829,3130.509033 579.205811,3132.811768 575.197388,3139.552490 
	C572.004761,3144.921387 569.265076,3150.585938 564.530579,3155.674316 
	C561.225281,3153.505127 561.762024,3150.602539 563.142090,3147.856445 
	C568.305237,3137.581543 568.447266,3125.643799 573.967041,3114.732422 
	C577.128296,3111.795410 577.926636,3108.281738 579.605591,3104.545166 
	C581.422974,3104.233398 583.161987,3103.867432 583.978455,3104.571289 
	C587.453613,3107.569092 589.601746,3107.645508 592.587769,3103.413086 
	C595.932739,3098.671631 597.151917,3099.040283 599.946899,3104.112549 
	C601.039246,3106.094971 601.508545,3107.909912 604.023621,3105.869629 
	C607.147949,3103.334961 610.189270,3106.474854 613.946411,3105.112061 
	C612.745117,3110.793701 611.744446,3115.526611 610.755371,3120.204590 
	C613.832825,3122.552002 614.947266,3119.288574 617.419067,3118.276367 
	C619.221680,3125.389404 615.542358,3131.607910 612.652588,3137.781250 
	C610.165222,3143.095459 608.783813,3148.694092 605.367188,3155.390137 
	C615.558533,3152.264160 619.836975,3159.067383 625.939575,3163.960449 
	C626.570557,3177.113770 625.670837,3190.054443 622.560791,3203.594971 
z"
        />
        <path
          fill="#F9B783"
          opacity="1.000000"
          stroke="none"
          d="
M630.660767,3260.000000 
	C631.168762,3255.769531 633.497620,3251.938721 632.104736,3247.091553 
	C630.732483,3242.316162 631.158630,3242.193604 624.592773,3245.677246 
	C623.712708,3240.817627 623.808594,3236.438965 630.420410,3234.521973 
	C637.306030,3232.525635 636.993225,3232.003906 638.741089,3239.012695 
	C639.453125,3241.867920 638.804565,3244.186035 638.076965,3246.710938 
	C637.164673,3249.876221 635.623413,3252.912354 636.018860,3257.155273 
	C635.545654,3258.389404 635.084839,3258.841064 634.317017,3259.668945 
	C633.995605,3260.030762 633.996155,3259.999756 634.014954,3260.004150 
	C633.145142,3260.017822 632.256470,3260.027100 630.660767,3260.000000 
z"
        />
        <path
          fill="#F9B783"
          opacity="1.000000"
          stroke="none"
          d="
M630.715088,3226.860840 
	C629.220947,3227.280518 628.680420,3226.757324 628.911377,3225.597900 
	C628.959473,3225.356934 629.300781,3225.033203 629.544128,3224.996826 
	C630.715149,3224.822021 631.206787,3225.390869 630.715088,3226.860840 
z"
        />
        <path
          fill="#ECA777"
          opacity="1.000000"
          stroke="none"
          d="
M634.438477,3259.798828 
	C634.231873,3259.391846 634.620056,3258.864746 635.590454,3258.200928 
	C635.641113,3258.490967 635.261230,3259.029053 634.438477,3259.798828 
z"
        />
        <path
          fill="#935F47"
          opacity="1.000000"
          stroke="none"
          d="
M615.074707,3100.955078 
	C615.124390,3101.047119 615.025085,3100.863281 615.074707,3100.955078 
z"
        />
        <path
          fill="#F0EBDD"
          opacity="1.000000"
          stroke="none"
          d="
M1281.746094,3767.341797 
	C1282.323975,3769.205811 1283.274902,3770.656738 1281.836670,3771.500244 
	C1280.002808,3772.575439 1277.983765,3771.677246 1276.057495,3771.297363 
	C1274.525757,3770.995361 1273.423218,3771.663574 1273.000610,3773.076172 
	C1272.530762,3774.646973 1273.753418,3775.152100 1274.886475,3775.961914 
	C1281.562134,3780.735352 1281.523193,3780.791260 1277.275146,3788.240234 
	C1275.909546,3790.634521 1275.893799,3792.665527 1278.893799,3793.725342 
	C1280.423828,3794.265869 1283.139771,3794.347656 1283.070068,3795.803711 
	C1282.630249,3804.985840 1290.814697,3806.068115 1295.860229,3810.129639 
	C1298.143188,3811.967773 1300.506104,3813.839600 1301.878906,3817.330322 
	C1299.775635,3820.695312 1295.430054,3821.913330 1296.011963,3827.283936 
	C1295.621704,3829.620117 1295.318848,3831.475342 1293.037354,3831.140137 
	C1289.770752,3830.660400 1286.251343,3830.565430 1283.360840,3829.201660 
	C1279.846313,3827.543213 1277.560669,3825.699463 1274.372925,3830.207764 
	C1271.034668,3834.929199 1273.032959,3837.336914 1276.420044,3840.099609 
	C1276.812500,3840.419434 1276.755859,3841.290283 1276.906494,3841.905762 
	C1267.271362,3845.818848 1263.678467,3844.079346 1261.961792,3834.671143 
	C1260.520264,3826.770752 1257.762817,3825.645508 1250.918213,3829.913086 
	C1247.849609,3831.826172 1244.544067,3833.359375 1241.347534,3835.067627 
	C1240.100586,3835.734375 1238.794434,3835.902344 1237.630005,3835.042969 
	C1236.264160,3834.035156 1236.859375,3832.647461 1237.272339,3831.416260 
	C1238.197998,3828.656006 1237.999634,3825.559326 1240.620239,3823.321045 
	C1242.911743,3821.363525 1243.914062,3818.421387 1244.736694,3815.656006 
	C1244.053711,3815.010986 1243.548096,3814.162354 1243.146851,3814.209229 
	C1234.128296,3815.259521 1227.395630,3807.523926 1218.556885,3807.831299 
	C1217.384155,3807.871826 1215.203613,3807.027588 1215.137085,3806.414551 
	C1214.504395,3800.584229 1210.483276,3796.364502 1208.420898,3791.281006 
	C1206.837036,3787.377197 1209.898071,3783.854004 1214.248169,3784.607910 
	C1217.422363,3785.158203 1220.080078,3785.322754 1222.726074,3783.528320 
	C1224.122559,3782.580811 1225.462646,3781.514893 1225.129028,3779.551270 
	C1224.810547,3777.677246 1223.380005,3776.775635 1221.730347,3776.344482 
	C1220.456543,3776.011719 1219.072754,3776.118896 1217.779663,3775.838135 
	C1213.552734,3774.920410 1213.179443,3771.910400 1215.295288,3769.179932 
	C1217.304688,3766.586670 1220.256226,3765.001709 1218.911865,3760.808350 
	C1218.304321,3758.913330 1220.300659,3758.127441 1222.202881,3758.459229 
	C1224.135986,3758.796631 1225.888550,3760.106689 1227.989746,3759.542480 
	C1229.718018,3759.078125 1230.945190,3757.992188 1231.433228,3756.293213 
	C1231.946533,3754.506592 1231.380005,3752.852051 1229.681763,3752.097900 
	C1224.401978,3749.753418 1220.096191,3745.529541 1213.126709,3746.854736 
	C1207.816528,3747.864258 1201.865601,3746.807861 1196.444702,3745.584717 
	C1190.426514,3744.227051 1185.923706,3740.638184 1183.860840,3734.123535 
	C1181.961670,3728.125244 1178.534668,3727.205811 1173.263184,3730.465332 
	C1169.833130,3732.585938 1167.193481,3735.623779 1164.694946,3738.749023 
	C1163.234131,3740.576172 1161.761597,3743.121582 1159.235229,3740.690186 
	C1157.011963,3738.550049 1158.946533,3736.447021 1160.435547,3734.634033 
	C1165.041626,3729.025635 1170.723999,3724.557129 1176.238403,3719.929688 
	C1177.799805,3718.619385 1179.393799,3717.512939 1179.906006,3714.691895 
	C1184.633301,3711.811523 1185.436157,3715.412842 1186.760498,3719.146973 
	C1190.970703,3726.094971 1206.238403,3729.939697 1211.463623,3725.614258 
	C1214.765991,3722.880371 1217.876343,3720.825928 1222.143311,3722.254395 
	C1226.158203,3723.598145 1227.932495,3726.311523 1226.626465,3730.722412 
	C1225.953247,3732.995850 1222.273438,3735.453613 1225.445801,3737.601318 
	C1228.781494,3739.860107 1232.844116,3739.548584 1236.336670,3737.058838 
	C1235.734863,3732.825928 1231.703247,3732.105225 1229.952515,3729.392334 
	C1228.597900,3727.293213 1227.972656,3725.126465 1229.219604,3722.875244 
	C1230.536499,3720.497314 1233.148071,3719.690918 1235.224121,3720.755859 
	C1241.768677,3724.112305 1246.270142,3721.193604 1251.203491,3717.135254 
	C1254.705078,3714.254883 1259.282471,3711.752930 1263.175049,3718.118164 
	C1256.410278,3728.096924 1266.319458,3732.636719 1269.029907,3739.914062 
	C1268.515747,3742.078125 1267.494629,3743.032715 1265.444092,3743.904297 
	C1263.795532,3744.291260 1262.838257,3744.376221 1261.173584,3744.342529 
	C1257.286377,3743.936768 1254.102051,3743.576172 1251.215088,3745.788818 
	C1251.702393,3749.298828 1256.244263,3751.112793 1254.776611,3755.812500 
	C1251.546997,3759.787842 1245.914551,3757.341797 1242.717529,3762.403809 
	C1254.010742,3762.559570 1264.484863,3765.541260 1275.986084,3763.934570 
	C1278.596680,3764.344482 1280.311768,3765.036865 1281.746094,3767.341797 
z"
        />
        <path
          fill="#F4EEDC"
          opacity="1.000000"
          stroke="none"
          d="
M1184.541992,3722.447754 
	C1183.654663,3719.267090 1185.030029,3715.944336 1180.699707,3714.153564 
	C1177.379639,3712.245850 1176.987061,3708.959717 1174.504883,3706.382812 
	C1171.375488,3701.841797 1173.466919,3699.026123 1177.391846,3696.239990 
	C1186.536621,3695.067383 1195.069336,3699.036621 1203.954834,3695.293945 
	C1201.319580,3691.409424 1197.904785,3694.115967 1194.312500,3693.918701 
	C1188.790283,3692.709473 1183.848145,3690.512695 1185.213745,3684.874023 
	C1186.922119,3677.820801 1192.310669,3671.886719 1199.335571,3670.992920 
	C1209.067017,3669.754395 1215.117554,3666.476318 1217.693848,3656.434570 
	C1218.857300,3651.899658 1224.117065,3651.307129 1229.417969,3652.450195 
	C1231.424805,3653.488037 1232.405273,3654.582520 1233.478271,3656.539062 
	C1235.053345,3661.848145 1233.002930,3666.216553 1232.690796,3670.726074 
	C1232.480957,3673.758301 1230.417480,3677.204834 1234.653931,3680.097656 
	C1238.964355,3684.196289 1241.446045,3688.824463 1244.843262,3693.617920 
	C1246.379272,3699.718750 1239.665405,3699.235107 1238.500977,3702.817383 
	C1240.017822,3704.762695 1242.148560,3704.694092 1244.038940,3705.181885 
	C1247.622559,3706.106689 1251.839722,3706.726562 1250.787109,3711.876465 
	C1249.851685,3716.452881 1246.686646,3720.715332 1241.977661,3719.719238 
	C1234.961304,3718.235352 1228.271362,3719.330322 1221.409546,3719.419434 
	C1217.519165,3719.469727 1213.436890,3717.388672 1211.612183,3713.481201 
	C1209.905029,3709.825928 1213.162720,3708.249512 1216.034058,3706.350098 
	C1211.854248,3706.782227 1208.719482,3709.453369 1204.947876,3709.607422 
	C1200.503296,3709.789551 1197.698853,3711.617920 1195.883789,3715.918945 
	C1193.999878,3720.382812 1190.678467,3723.666260 1184.541992,3722.447754 
z"
        />
        <path
          fill="#F0EBDD"
          opacity="1.000000"
          stroke="none"
          d="
M1180.624023,3697.502441 
	C1175.962402,3698.299561 1174.058350,3700.570068 1174.013184,3705.274902 
	C1171.818970,3705.661377 1170.098267,3704.911133 1170.948242,3702.191162 
	C1172.001343,3698.821045 1170.027100,3698.142090 1166.677490,3698.014160 
	C1165.481445,3697.010986 1164.962891,3696.021973 1164.219482,3694.521484 
	C1174.194580,3692.199219 1174.583008,3692.152588 1175.916138,3683.072266 
	C1176.587646,3678.498535 1179.253784,3676.633789 1183.022217,3676.229248 
	C1189.449585,3675.538818 1192.235352,3670.301758 1196.219238,3666.550537 
	C1199.124756,3663.814453 1196.747192,3660.311523 1196.906372,3657.544189 
	C1199.276978,3655.544434 1201.925537,3656.700928 1203.900391,3655.821777 
	C1208.428223,3653.805908 1215.533569,3654.440674 1214.814453,3646.324463 
	C1221.616943,3642.448242 1219.030029,3638.774658 1214.902954,3634.627930 
	C1213.715698,3633.435303 1215.541992,3631.683838 1217.432861,3630.310547 
	C1228.266479,3634.005371 1232.995605,3642.213867 1230.586792,3653.428223 
	C1227.008301,3654.023193 1224.010254,3653.826660 1221.269775,3655.450439 
	C1219.353882,3656.585693 1217.909180,3657.944824 1219.490723,3660.162842 
	C1221.821045,3663.431396 1221.867676,3665.572266 1217.252563,3666.166748 
	C1214.993286,3666.457520 1213.872925,3668.206055 1213.026489,3670.015381 
	C1211.309082,3673.686523 1208.093628,3674.989258 1204.807007,3673.654785 
	C1199.675171,3671.571045 1196.630859,3673.854004 1193.645508,3677.332764 
	C1192.782715,3678.338135 1191.759521,3679.204102 1190.842529,3680.165283 
	C1184.909180,3686.384521 1184.911987,3686.387207 1193.394043,3691.725098 
	C1192.349854,3700.300049 1185.025391,3694.158447 1180.624023,3697.502441 
z"
        />
        <path
          fill="#EAE5D5"
          opacity="1.000000"
          stroke="none"
          d="
M1143.321533,3662.015625 
	C1144.523071,3662.986816 1145.041504,3663.973877 1145.786255,3665.470703 
	C1143.293335,3668.888428 1140.571777,3668.314209 1139.167969,3665.064697 
	C1137.995850,3662.351562 1140.802002,3662.324219 1143.321533,3662.015625 
z"
        />
        <path
          fill="#F4EEDC"
          opacity="1.000000"
          stroke="none"
          d="
M1119.978271,3625.023193 
	C1121.194580,3625.680664 1121.936157,3626.305664 1122.390625,3627.095947 
	C1123.166870,3628.445068 1124.052490,3630.236572 1122.000488,3630.978760 
	C1120.251831,3631.611084 1119.150146,3629.974365 1118.556396,3628.476074 
	C1118.075317,3627.261719 1118.285156,3626.006348 1119.978271,3625.023193 
z"
        />
        <path
          fill="#EAE5D5"
          opacity="1.000000"
          stroke="none"
          d="
M1146.889160,3687.910645 
	C1147.632568,3686.554932 1148.380249,3686.539795 1149.040649,3687.507568 
	C1149.178955,3687.710449 1149.173462,3688.180420 1149.029663,3688.377686 
	C1148.340698,3689.322510 1147.593506,3689.286865 1146.889160,3687.910645 
z"
        />
        <path
          fill="#C2825D"
          opacity="1.000000"
          stroke="none"
          d="
M568.073364,3182.641113 
	C569.618896,3173.891113 575.532166,3166.940186 574.257812,3158.081787 
	C574.006165,3156.332764 574.994507,3154.931396 576.753845,3154.230957 
	C580.157715,3152.876221 583.555542,3151.843750 587.350647,3152.031250 
	C592.881653,3152.304443 591.424072,3157.221191 593.090027,3160.049316 
	C595.013794,3163.315430 595.890503,3166.654541 594.370422,3170.435791 
	C593.927124,3171.538574 593.951599,3173.827881 594.587463,3174.214355 
	C602.788635,3179.197510 596.479553,3187.688477 599.481628,3193.942871 
	C600.807312,3196.705078 597.903931,3198.726074 596.248352,3201.600830 
	C594.450500,3202.161865 592.969116,3202.052734 590.744019,3201.928467 
	C587.280457,3201.774170 584.976013,3200.649170 582.441528,3198.489258 
	C581.960815,3198.048828 582.000000,3198.000000 581.976929,3198.020020 
	C577.825195,3194.111816 571.330444,3192.945312 568.551880,3186.424072 
	C568.100464,3185.042969 568.085083,3184.169434 568.073364,3182.641113 
z"
        />
        <path
          fill="#AB7453"
          opacity="1.000000"
          stroke="none"
          d="
M568.083130,3186.667969 
	C571.686340,3187.759766 579.621826,3193.963867 581.880493,3197.330322 
	C575.109741,3197.392822 569.142578,3195.597412 568.083130,3186.667969 
z"
        />
        <path
          fill="#AB7453"
          opacity="1.000000"
          stroke="none"
          d="
M582.050537,3198.725586 
	C584.806641,3197.869629 587.494446,3198.123535 589.660706,3201.324707 
	C585.937927,3205.592041 583.789734,3203.278076 582.050537,3198.725586 
z"
        />
        <path
          fill="#ECA777"
          opacity="1.000000"
          stroke="none"
          d="
M626.221802,3334.656250 
	C625.815002,3331.904785 625.705750,3329.827637 625.644409,3326.965088 
	C627.644165,3324.431396 625.649109,3322.030762 627.047791,3319.391602 
	C636.148438,3305.347900 639.946289,3304.237793 651.141968,3312.527832 
	C652.825623,3317.671631 651.208252,3320.835449 647.489136,3323.405518 
	C639.973511,3328.599854 639.005676,3338.314209 646.261230,3344.102051 
	C651.290771,3348.114014 652.308655,3352.893066 653.499329,3358.539307 
	C656.008789,3370.439209 661.734192,3381.635010 660.926331,3394.279053 
	C660.716797,3397.559082 661.050476,3400.890381 660.955444,3404.206299 
	C660.658142,3414.577148 657.157776,3423.390625 648.402466,3430.586914 
	C647.714233,3431.674805 647.387573,3432.077637 646.391724,3432.877441 
	C640.290466,3430.098389 638.997681,3424.729492 637.991821,3419.086914 
	C633.766174,3395.381836 630.813110,3371.504150 627.892822,3346.780273 
	C627.523132,3345.663574 627.376282,3345.356689 627.352905,3344.554688 
	C627.928040,3341.065430 627.390442,3338.179688 626.221802,3334.656250 
z"
        />
        <path
          fill="#BA7C55"
          opacity="1.000000"
          stroke="none"
          d="
M666.264343,3341.276123 
	C662.835022,3344.918945 664.488892,3348.058838 665.421204,3351.230469 
	C669.831299,3366.231445 673.361328,3381.442383 671.906250,3397.187744 
	C670.765259,3409.534668 671.811401,3422.198975 666.619873,3434.082520 
	C665.462952,3436.730713 663.886475,3440.927490 670.979675,3441.053955 
	C676.957092,3441.160156 682.316467,3446.246338 686.511536,3450.569580 
	C689.766357,3453.923828 688.322632,3459.426514 686.967346,3463.922607 
	C686.081726,3466.860840 686.072266,3469.811768 685.913696,3473.703125 
	C670.852844,3470.225098 663.576050,3457.539062 655.499023,3445.872559 
	C652.650696,3441.758789 649.980103,3437.629883 645.910461,3433.847656 
	C645.722534,3433.274414 645.733032,3432.495605 645.857178,3432.126465 
	C660.238525,3426.708984 661.199341,3414.015381 662.627808,3401.968750 
	C664.870789,3383.053223 658.351562,3365.264648 654.614868,3347.145264 
	C653.535950,3341.913574 652.394531,3336.739746 653.342102,3331.398193 
	C653.806458,3328.781006 654.414185,3325.844238 657.624207,3325.412109 
	C660.917908,3324.968750 662.116577,3327.792725 663.174805,3330.196045 
	C664.636597,3333.515869 666.103027,3336.866699 666.264343,3341.276123 
z"
        />
        <path
          fill="#131C25"
          opacity="1.000000"
          stroke="none"
          d="
M529.533447,3369.392578 
	C519.024902,3380.352539 505.449921,3384.845459 490.390625,3386.395508 
	C489.819214,3382.473389 491.000031,3380.507812 494.820892,3379.806885 
	C505.908569,3377.772217 516.581726,3374.429688 526.263855,3368.389160 
	C526.857361,3368.018555 528.114929,3368.712646 529.533447,3369.392578 
z"
        />
        <path
          fill="#131C25"
          opacity="1.000000"
          stroke="none"
          d="
M485.229675,3386.017090 
	C483.628540,3394.969727 476.296997,3393.784912 468.815247,3393.989502 
	C468.500702,3389.899658 471.690613,3388.951416 474.921967,3388.148926 
	C478.077911,3387.364746 481.268616,3386.719971 485.229675,3386.017090 
z"
        />
        <path
          fill="#131C25"
          opacity="1.000000"
          stroke="none"
          d="
M561.218506,3358.010986 
	C557.201904,3364.372803 550.308960,3366.226562 542.417358,3366.391602 
	C542.293945,3364.264404 542.853027,3362.799316 544.679871,3362.301270 
	C549.931213,3360.869873 555.184570,3359.446045 561.218506,3358.010986 
z"
        />
        <path
          fill="#423C3E"
          opacity="1.000000"
          stroke="none"
          d="
M583.908203,3341.108643 
	C582.556641,3340.366455 582.541748,3339.619873 583.508240,3338.960938 
	C583.710938,3338.822998 584.179749,3338.827148 584.376404,3338.970703 
	C585.320068,3339.658936 585.281250,3340.404785 583.908203,3341.108643 
z"
        />
        <path
          fill="#0C1A26"
          opacity="1.000000"
          stroke="none"
          d="
M618.737793,3342.010742 
	C618.121460,3341.219727 618.069458,3340.305420 618.022156,3338.705078 
	C619.327698,3338.159668 620.741333,3338.296143 621.005005,3339.882568 
	C621.189392,3340.991943 620.271606,3341.553223 618.737793,3342.010742 
z"
        />
        <path
          fill="#423C3E"
          opacity="1.000000"
          stroke="none"
          d="
M627.230408,3344.265137 
	C627.601562,3344.368896 627.726807,3344.677979 627.761047,3345.479004 
	C626.955078,3345.660645 626.726501,3345.160889 627.230408,3344.265137 
z"
        />
        <path
          fill="#F3AB7B"
          opacity="1.000000"
          stroke="none"
          d="
M650.586426,3314.484863 
	C647.819458,3311.368652 644.879089,3310.243408 641.510986,3310.047607 
	C637.725952,3309.827393 634.273315,3309.995850 633.808899,3315.061523 
	C633.581787,3317.538818 631.975403,3319.127197 628.802368,3319.896484 
	C627.590027,3320.229004 627.108826,3320.445801 625.993347,3320.189453 
	C624.590088,3315.014404 625.287659,3310.267578 624.627686,3304.823242 
	C624.252930,3303.171875 624.173462,3302.250244 624.254822,3300.650879 
	C632.557983,3300.604492 637.989624,3295.751709 640.830811,3289.393066 
	C643.197021,3284.096924 647.338196,3280.237305 649.679932,3275.080322 
	C651.386658,3271.321777 653.801392,3272.626709 655.564087,3275.382812 
	C657.030579,3277.675537 657.484802,3279.997314 656.782593,3282.916016 
	C655.067810,3290.044189 654.881592,3296.135986 663.897888,3299.747070 
	C668.579041,3301.622070 673.296021,3306.134033 674.084534,3312.479980 
	C674.451599,3315.434570 675.908569,3317.537842 679.126160,3317.620850 
	C680.882080,3317.666260 682.359070,3316.691162 683.087708,3315.057861 
	C684.272339,3312.403564 684.500732,3309.745361 681.386475,3308.255859 
	C678.923157,3307.077637 677.476318,3305.245361 678.336121,3302.754883 
	C679.615356,3299.049072 678.608459,3295.628174 678.171997,3292.033447 
	C677.715515,3288.273682 678.424866,3284.329346 682.252197,3282.640137 
	C686.379700,3280.818115 689.923218,3282.685303 692.919373,3285.891846 
	C694.835510,3287.942383 695.478638,3290.377930 696.342041,3292.918213 
	C697.530701,3296.416260 699.590027,3296.427979 701.780945,3293.244385 
	C702.522888,3292.166016 702.793274,3290.742676 703.600342,3289.733643 
	C707.568542,3284.772949 711.236572,3285.184082 713.777039,3291.046631 
	C714.688477,3293.150146 714.558594,3296.061035 717.879517,3295.408447 
	C721.636597,3294.669678 722.841309,3291.196045 721.760376,3288.471924 
	C718.705872,3280.773193 722.906311,3274.243896 724.167175,3267.243652 
	C724.426880,3265.801270 725.286377,3264.902832 727.270691,3264.151855 
	C729.796082,3265.351807 733.289978,3266.938477 733.144104,3268.046143 
	C732.428894,3273.478271 733.020081,3279.256348 729.791443,3284.231934 
	C725.588989,3290.707764 724.515137,3297.502930 727.934937,3304.781982 
	C728.933350,3306.907227 730.297546,3307.844482 733.227722,3307.974854 
	C734.425964,3307.788330 734.872498,3307.576904 735.977478,3307.703857 
	C737.957031,3309.645508 738.202087,3311.536133 738.000244,3314.197754 
	C736.428833,3318.060791 736.316650,3321.432373 736.278687,3325.431152 
	C735.674377,3327.112305 735.324463,3328.211426 734.510254,3329.670898 
	C731.335876,3329.895020 729.038330,3330.399414 727.421387,3332.999512 
	C725.674255,3335.808838 723.343933,3338.036621 718.919128,3337.953125 
	C715.705444,3336.272705 715.345703,3333.624268 715.017334,3330.221680 
	C715.200806,3325.839600 714.913879,3322.293457 713.707642,3318.780518 
	C710.956482,3310.768066 707.613525,3309.486816 698.481201,3313.963379 
	C689.722412,3333.887451 696.617737,3351.804199 703.529114,3369.515625 
	C705.737854,3375.175781 707.359741,3380.809814 708.241028,3386.739258 
	C708.535461,3388.720459 709.127930,3391.000488 708.169556,3392.654053 
	C701.494202,3404.173096 703.243042,3417.771973 698.783203,3429.833740 
	C697.346130,3433.720215 696.155884,3437.422607 690.773499,3438.197021 
	C684.907959,3423.128662 682.388245,3407.667480 680.582214,3392.211426 
	C678.715088,3376.231689 674.686035,3360.932129 669.590576,3345.009277 
	C670.005615,3342.040039 668.269592,3341.037354 666.730774,3339.135742 
	C664.798401,3335.369141 665.085266,3332.025635 665.529907,3327.971924 
	C667.463501,3322.275879 667.089722,3317.934082 662.541748,3314.338135 
	C659.899170,3312.248291 657.664917,3311.329834 654.580933,3314.657715 
	C653.014526,3315.199463 652.082214,3315.174072 650.586426,3314.484863 
z"
        />
        <path
          fill="#ECA777"
          opacity="1.000000"
          stroke="none"
          d="
M624.083862,3304.949707 
	C627.432068,3308.513184 625.388672,3313.541992 626.018127,3319.101562 
	C625.760620,3320.180176 625.493286,3320.413086 624.795044,3320.334473 
	C622.906372,3315.331787 623.696106,3310.556885 624.083862,3304.949707 
z"
        />
        <path
          fill="#F9B783"
          opacity="1.000000"
          stroke="none"
          d="
M624.277893,3320.662598 
	C624.364136,3320.022949 625.197266,3320.021729 625.612549,3319.984619 
	C626.368103,3319.958252 626.708374,3319.969238 627.559998,3319.996338 
	C627.956055,3321.856201 628.118774,3323.767578 626.240601,3325.749023 
	C625.192017,3324.553955 624.691833,3322.928223 624.277893,3320.662598 
z"
        />
        <path
          fill="#465F75"
          opacity="1.000000"
          stroke="none"
          d="
M2207.431885,2683.388184 
	C2207.708984,2685.328857 2207.309814,2686.600098 2207.075684,2687.901123 
	C2206.118652,2693.214844 2206.843018,2694.315918 2211.673828,2693.018311 
	C2217.304688,2691.505859 2222.910645,2692.261963 2228.525635,2692.052002 
	C2232.743652,2691.894531 2235.634521,2693.515137 2236.108398,2698.913330 
	C2227.219971,2699.801270 2218.239990,2699.899414 2209.260742,2700.055664 
	C2207.242188,2700.090820 2204.879395,2700.286621 2204.745850,2702.848877 
	C2204.609619,2705.461182 2206.913574,2705.787842 2208.954590,2705.906738 
	C2210.613281,2706.003906 2212.281494,2706.029053 2213.942627,2705.980225 
	C2227.582520,2705.581055 2241.010498,2706.690430 2254.466553,2709.404053 
	C2262.134521,2710.950439 2270.366211,2711.012451 2278.215332,2708.889893 
	C2282.439453,2707.747559 2286.489014,2707.898193 2290.000977,2708.408447 
	C2291.081299,2710.677002 2290.324707,2711.688965 2289.501221,2711.910156 
	C2274.273438,2716.001953 2259.187256,2721.885498 2243.191162,2714.768311 
	C2237.684570,2712.317871 2232.093750,2711.555420 2225.799561,2713.438477 
	C2217.988525,2715.775146 2210.011963,2712.936279 2202.189453,2711.616455 
	C2190.760254,2709.687988 2179.335693,2708.198486 2167.697754,2707.901367 
	C2151.271240,2707.481934 2134.567139,2708.278076 2118.627686,2703.826660 
	C2107.908447,2700.833252 2096.810303,2699.165039 2086.382080,2694.799316 
	C2096.424805,2694.634766 2106.408203,2695.115234 2115.993408,2698.127197 
	C2120.904785,2699.670654 2125.464600,2701.409668 2130.098389,2697.921875 
	C2133.359375,2695.467529 2136.464844,2698.353271 2139.696533,2698.066895 
	C2143.659180,2697.715820 2147.675293,2698.014404 2151.663818,2697.886230 
	C2152.916748,2697.846191 2154.452148,2697.698975 2154.743408,2696.007080 
	C2155.101318,2693.928467 2153.623047,2692.813721 2152.018555,2692.532959 
	C2148.116455,2691.850098 2144.022461,2692.001221 2140.236084,2690.970947 
	C2130.498291,2688.321777 2121.013428,2687.052979 2110.839600,2689.552246 
	C2102.717285,2691.547363 2094.453125,2688.097168 2086.300293,2686.936523 
	C2075.662354,2685.422607 2064.906250,2685.004150 2054.540771,2686.663574 
	C2044.440308,2688.280273 2034.808228,2688.047852 2024.097778,2686.186523 
	C2021.995117,2685.831787 2021.214966,2685.017334 2020.846924,2682.895508 
	C2022.120361,2678.038086 2025.366455,2677.874756 2028.815796,2677.616455 
	C2056.333008,2675.555908 2083.722168,2678.219727 2111.165771,2679.427979 
	C2122.655518,2679.933594 2134.055664,2676.677246 2145.687012,2677.110840 
	C2166.179932,2677.875244 2186.552979,2679.738037 2207.431885,2683.388184 
z"
        />
        <path
          fill="#21364A"
          opacity="1.000000"
          stroke="none"
          d="
M2084.094238,2721.456543 
	C2090.900391,2718.561523 2097.543213,2722.345947 2104.442871,2721.980713 
	C2123.569092,2720.969482 2142.502686,2725.116699 2161.650635,2724.119873 
	C2165.159424,2723.937744 2168.746338,2725.258301 2173.139404,2725.911133 
	C2179.246338,2724.509766 2184.494385,2724.541260 2189.667725,2726.302002 
	C2193.077148,2727.462646 2194.213623,2730.110840 2193.466797,2733.415039 
	C2192.552002,2737.462891 2189.754395,2737.391846 2186.503418,2736.263428 
	C2178.574951,2733.510498 2170.212891,2733.517578 2163.802979,2732.756348 
	C2174.534180,2731.886963 2186.171631,2738.154053 2199.325684,2737.673584 
	C2205.738770,2738.351318 2211.858154,2736.736328 2217.570068,2741.361328 
	C2219.208252,2743.438477 2219.621094,2745.110352 2218.773438,2747.752930 
	C2211.667236,2753.012207 2204.230225,2751.714355 2197.181641,2750.498047 
	C2177.534912,2747.108643 2157.602539,2749.508301 2137.930908,2747.061768 
	C2135.369141,2746.743164 2132.613770,2746.617188 2129.605957,2748.730957 
	C2126.101074,2752.134766 2124.191895,2749.636230 2121.694336,2747.397461 
	C2119.875488,2738.283447 2127.062744,2739.797607 2129.902100,2738.097656 
	C2126.954834,2738.338623 2122.801025,2739.516113 2118.722656,2742.939941 
	C2103.977051,2740.774170 2094.164062,2731.437744 2084.094238,2721.456543 
z"
        />
        <path
          fill="#AAB7BE"
          opacity="1.000000"
          stroke="none"
          d="
M2021.603760,2684.435791 
	C2021.929565,2685.568359 2022.179810,2685.895752 2022.902832,2686.163086 
	C2027.875977,2691.297607 2034.505493,2692.976318 2040.283325,2696.125488 
	C2043.771118,2698.026611 2047.246094,2699.951172 2051.309570,2702.278809 
	C2053.753174,2705.000000 2055.934814,2706.857910 2059.488770,2707.678223 
	C2062.785645,2708.207275 2064.957275,2709.718262 2067.511719,2711.758789 
	C2078.459229,2721.276123 2090.474121,2728.179932 2101.922363,2735.893555 
	C2106.012939,2738.649902 2110.876221,2740.529297 2111.669434,2747.036621 
	C2108.265625,2758.536377 2108.453369,2757.247070 2099.010010,2751.388916 
	C2084.979248,2742.684814 2072.431885,2731.873535 2058.046143,2723.847900 
	C2054.220215,2721.713623 2050.528320,2719.839844 2047.726318,2716.355957 
	C2045.168091,2713.175537 2041.874390,2710.794922 2037.648193,2710.455078 
	C2027.028564,2709.600830 2018.824585,2704.174561 2011.853027,2696.695312 
	C2008.014771,2692.577148 2004.121826,2690.891113 1998.609741,2693.170898 
	C1993.784912,2695.166504 1989.470947,2693.852783 1985.767212,2689.120605 
	C1985.786377,2681.418701 1990.315308,2675.946289 1993.432251,2669.297119 
	C1997.905518,2667.812500 2000.765259,2669.945801 2004.278320,2672.313477 
	C2008.634399,2679.025879 2017.187256,2678.106201 2021.603760,2684.435791 
z"
        />
        <path
          fill="#9DAAB4"
          opacity="1.000000"
          stroke="none"
          d="
M2109.571289,2748.606934 
	C2109.504150,2743.171387 2106.256836,2740.027832 2102.287354,2738.584229 
	C2096.271240,2736.395996 2091.509521,2732.963135 2086.749023,2728.756348 
	C2081.720459,2724.312256 2074.749023,2722.260010 2070.258789,2716.864990 
	C2069.438477,2715.879395 2068.842285,2714.707520 2068.019043,2712.954590 
	C2073.750000,2713.365967 2077.597412,2718.111816 2083.333496,2720.576172 
	C2094.768555,2728.046143 2105.644531,2735.369385 2117.153809,2743.055908 
	C2119.402344,2742.881592 2120.374512,2743.751221 2121.553711,2745.558594 
	C2123.574219,2747.299072 2125.227295,2748.452881 2127.741455,2750.005371 
	C2142.905518,2762.875977 2159.333496,2772.363525 2175.359863,2783.536865 
	C2175.967773,2783.805664 2176.316406,2784.232910 2176.583496,2784.164307 
	C2178.339111,2784.382812 2179.617188,2785.014404 2180.620850,2787.027344 
	C2180.017090,2790.273193 2179.004395,2792.733154 2177.653076,2794.960693 
	C2175.589844,2798.361084 2173.124023,2802.174072 2168.856689,2802.143066 
	C2164.479248,2802.111572 2163.423584,2797.707764 2162.534424,2794.276123 
	C2160.972900,2788.248535 2155.111328,2787.119141 2151.238037,2783.802979 
	C2142.602295,2776.410156 2135.479004,2767.093018 2124.342285,2762.774170 
	C2121.851074,2761.808105 2119.551514,2760.750977 2116.168213,2760.594727 
	C2111.999023,2757.431885 2109.560791,2753.890869 2109.571289,2748.606934 
z"
        />
        <path
          fill="#294159"
          opacity="1.000000"
          stroke="none"
          d="
M2200.359863,2737.519043 
	C2197.523682,2739.266357 2194.930420,2740.639404 2192.314453,2739.839111 
	C2179.003906,2735.766357 2164.909424,2736.278320 2151.565430,2733.172607 
	C2151.382324,2730.271729 2153.137451,2730.150635 2154.776123,2730.068604 
	C2165.442871,2729.532959 2175.887939,2730.832275 2186.080078,2734.035156 
	C2187.897705,2734.606201 2189.776855,2736.153320 2191.307373,2733.812256 
	C2191.776367,2733.094727 2191.765381,2731.853516 2191.560303,2730.945801 
	C2191.017334,2728.542236 2189.021240,2728.242920 2186.951904,2728.049561 
	C2183.019287,2727.682861 2178.962402,2728.782227 2174.563721,2726.497803 
	C2174.333252,2724.260498 2174.968018,2722.469727 2176.834717,2722.380371 
	C2188.480957,2721.824219 2198.976562,2725.697021 2209.842529,2730.250732 
	C2207.762451,2735.107178 2203.065674,2734.345703 2200.359863,2737.519043 
z"
        />
        <path
          fill="#294159"
          opacity="1.000000"
          stroke="none"
          d="
M2341.096191,2706.008789 
	C2341.325684,2707.617188 2340.417236,2709.197266 2341.830566,2711.424072 
	C2333.142822,2709.356689 2324.448975,2711.323486 2315.746338,2713.084473 
	C2314.451172,2713.346680 2313.104736,2714.008301 2311.877686,2713.829590 
	C2308.643066,2713.358398 2303.402832,2716.096191 2302.864990,2710.903564 
	C2302.475342,2707.142578 2307.211670,2705.414795 2310.593994,2704.385986 
	C2313.029785,2703.645020 2316.055176,2703.516602 2318.464111,2704.260010 
	C2325.630615,2706.472168 2332.909668,2705.908203 2341.096191,2706.008789 
z"
        />
        <path
          fill="#294159"
          opacity="1.000000"
          stroke="none"
          d="
M2217.779541,2748.660156 
	C2217.999268,2746.468262 2218.008545,2744.955811 2218.043213,2742.685547 
	C2225.975830,2743.463867 2233.767822,2745.383789 2241.560791,2749.396484 
	C2239.732666,2752.190674 2237.547119,2754.549561 2233.810303,2754.008301 
	C2228.913818,2753.299316 2224.316406,2755.088623 2218.781738,2755.944824 
	C2216.052490,2753.854980 2216.426270,2751.619873 2217.779541,2748.660156 
z"
        />
        <path
          fill="#294159"
          opacity="1.000000"
          stroke="none"
          d="
M2368.066406,2714.861328 
	C2371.136719,2712.040039 2374.769531,2711.803955 2379.291992,2712.058105 
	C2379.620117,2714.764648 2381.080322,2718.472412 2377.248047,2719.484619 
	C2373.519531,2720.469238 2370.306152,2719.021240 2368.066406,2714.861328 
z"
        />
        <path
          fill="#9DAAB4"
          opacity="1.000000"
          stroke="none"
          d="
M2059.756836,2707.191406 
	C2055.169189,2711.382080 2053.366699,2708.164307 2051.943848,2703.345215 
	C2054.732666,2703.393311 2057.067383,2705.042969 2059.756836,2707.191406 
z"
        />
        <path
          fill="#294159"
          opacity="1.000000"
          stroke="none"
          d="
M2348.092285,2712.540771 
	C2348.911377,2710.644775 2349.979980,2710.072266 2351.723145,2711.640137 
	C2351.806641,2713.351562 2351.663086,2714.792236 2350.044678,2714.959717 
	C2348.928711,2715.075195 2348.446289,2714.100342 2348.092285,2712.540771 
z"
        />
        <path
          fill="#243B51"
          opacity="1.000000"
          stroke="none"
          d="
M2543.142578,2757.976562 
	C2543.684814,2761.289551 2542.058838,2763.805908 2538.591797,2763.757812 
	C2527.566650,2763.604004 2516.438477,2765.503906 2504.698242,2762.302734 
	C2495.884277,2762.830078 2488.276123,2759.877930 2480.355713,2759.866455 
	C2478.269043,2759.863525 2476.193115,2759.061523 2476.812988,2756.405029 
	C2477.400635,2753.884277 2479.769043,2753.934082 2481.644531,2754.418213 
	C2492.374023,2757.188965 2503.308594,2755.410889 2515.061035,2756.070068 
	C2518.219971,2755.995605 2520.445068,2755.997070 2523.337891,2756.041016 
	C2524.872559,2756.062744 2525.739746,2756.070068 2527.257324,2756.085449 
	C2528.758545,2756.891113 2529.665283,2757.596436 2531.416504,2757.888184 
	C2535.425293,2757.939941 2538.855225,2757.965576 2543.142578,2757.976562 
z"
        />
        <path
          fill="#294159"
          opacity="1.000000"
          stroke="none"
          d="
M2361.260254,2743.862061 
	C2368.922607,2743.259766 2375.327881,2746.700928 2383.013184,2745.777832 
	C2387.750732,2745.829590 2391.662109,2745.747070 2395.533447,2746.101562 
	C2399.498291,2746.464355 2403.427002,2747.251221 2403.934082,2753.172363 
	C2389.401611,2753.299072 2374.995850,2751.326904 2360.646484,2749.068359 
	C2357.025635,2748.498047 2353.463623,2748.012939 2348.959473,2747.925537 
	C2348.416260,2745.178223 2350.084229,2744.057861 2352.688965,2744.030273 
	C2355.261475,2744.002930 2357.833984,2743.957764 2361.260254,2743.862061 
z"
        />
        <path
          fill="#3E5871"
          opacity="1.000000"
          stroke="none"
          d="
M2558.343994,2765.436523 
	C2553.905029,2765.034912 2552.778809,2763.217529 2556.336182,2760.405029 
	C2557.534912,2759.457275 2559.190918,2759.097656 2559.819336,2756.724365 
	C2564.059814,2756.376465 2563.975098,2759.412109 2564.021729,2763.101807 
	C2562.297607,2764.206055 2560.557129,2764.582031 2558.343994,2765.436523 
z"
        />
        <path
          fill="#344F68"
          opacity="1.000000"
          stroke="none"
          d="
M2523.569336,2755.547607 
	C2521.917480,2758.434814 2519.493652,2758.379395 2516.443848,2756.556152 
	C2518.052246,2753.508301 2520.495605,2753.604980 2523.569336,2755.547607 
z"
        />
        <path
          fill="#344F68"
          opacity="1.000000"
          stroke="none"
          d="
M2531.626465,2757.455322 
	C2530.277588,2760.015869 2529.022705,2759.576172 2528.012939,2756.651367 
	C2529.024170,2756.394287 2530.140869,2756.695068 2531.626465,2757.455322 
z"
        />
        <path
          fill="#2F465E"
          opacity="1.000000"
          stroke="none"
          d="
M2482.006836,2708.588379 
	C2483.504395,2707.915527 2484.996826,2707.921387 2487.234863,2707.937500 
	C2488.157715,2709.619629 2488.887939,2710.957520 2490.076172,2712.187744 
	C2491.517334,2713.680176 2491.247803,2715.018311 2488.610840,2715.883545 
	C2486.869629,2713.942871 2484.357910,2714.250732 2482.384277,2712.465332 
	C2481.997803,2711.076172 2481.999756,2710.171875 2482.006836,2708.588379 
z"
        />
        <path
          fill="#294159"
          opacity="1.000000"
          stroke="none"
          d="
M2408.950928,2744.927734 
	C2409.038330,2744.880371 2408.863525,2744.974854 2408.950928,2744.927734 
z"
        />
        <path
          fill="#2F465E"
          opacity="1.000000"
          stroke="none"
          d="
M2532.576172,2720.548828 
	C2535.755615,2712.584473 2539.028564,2711.495605 2547.593750,2715.491211 
	C2548.156494,2719.813965 2546.760742,2722.185791 2542.542969,2721.954346 
	C2539.402100,2721.782227 2536.172363,2722.571289 2532.576172,2720.548828 
z"
        />
        <path
          fill="#2F465E"
          opacity="1.000000"
          stroke="none"
          d="
M2503.720947,2719.624023 
	C2500.961914,2719.412842 2498.874268,2718.629395 2499.132568,2716.271729 
	C2499.421875,2713.630371 2501.996338,2714.056641 2503.840088,2714.096436 
	C2505.630371,2714.135010 2508.361084,2713.389648 2508.565918,2716.102295 
	C2508.736816,2718.367676 2506.103760,2718.717529 2503.720947,2719.624023 
z"
        />
        <path
          fill="#2F4457"
          opacity="1.000000"
          stroke="none"
          d="
M451.962524,2865.160156 
	C443.454285,2865.916260 434.783783,2865.631104 426.608124,2869.368164 
	C425.175720,2870.022705 423.312836,2870.096680 421.698914,2869.936035 
	C409.138580,2868.685059 397.951935,2874.385742 386.093750,2876.610596 
	C373.637817,2878.947510 361.403717,2885.226807 348.075531,2880.268311 
	C345.423981,2879.281982 342.129700,2879.938232 339.133942,2879.982178 
	C335.159882,2880.040771 333.947418,2877.728271 333.909668,2873.466797 
	C335.754333,2868.913086 338.887268,2868.217041 343.370300,2869.561035 
	C350.345093,2870.388428 356.656219,2870.594727 362.871033,2870.787109 
	C369.003265,2870.976562 376.332336,2871.728271 381.370819,2865.899414 
	C381.975189,2865.200439 383.124146,2864.454102 383.940582,2864.534912 
	C393.776031,2865.511475 402.570648,2860.217529 412.193970,2859.969727 
	C421.426208,2859.731934 430.673279,2860.690674 439.862640,2858.278809 
	C445.060516,2856.914795 449.780029,2858.613037 451.962524,2865.160156 
z"
        />
        <path
          fill="#0D1C2A"
          opacity="1.000000"
          stroke="none"
          d="
M343.935150,2869.271240 
	C341.171722,2870.784424 338.259155,2871.514893 334.614319,2872.460449 
	C330.433380,2873.144043 326.984680,2873.612305 322.771057,2874.104004 
	C320.822601,2874.073486 319.730896,2874.345703 318.274170,2875.353760 
	C315.069214,2875.770508 312.348877,2875.875732 308.812866,2876.029297 
	C305.771729,2876.715820 303.480591,2877.018066 300.524170,2877.526611 
	C297.767639,2877.684570 295.693420,2877.723633 292.844330,2877.808105 
	C286.224854,2874.663818 279.884033,2876.795410 272.914001,2876.149902 
	C270.453125,2870.128662 266.367859,2871.631348 262.744720,2873.430664 
	C260.674866,2874.458740 258.867065,2876.786377 256.881805,2874.141602 
	C255.334900,2872.081055 256.178864,2869.760010 258.097778,2867.932617 
	C258.794006,2867.269775 259.221649,2866.324463 259.949341,2864.830078 
	C264.130157,2864.056396 268.133301,2863.960938 272.996033,2863.820801 
	C275.255493,2862.612793 276.838684,2861.957031 279.343628,2862.025879 
	C284.730591,2861.973389 289.459686,2861.927246 295.070557,2861.836426 
	C298.490326,2864.835449 302.013062,2863.904053 305.206818,2863.938721 
	C315.123108,2864.045654 325.041229,2863.976318 334.958618,2863.989258 
	C338.653900,2863.993896 342.424225,2863.889160 343.935150,2869.271240 
z"
        />
        <path
          fill="#2B3E51"
          opacity="1.000000"
          stroke="none"
          d="
M489.443237,2842.145996 
	C491.046997,2843.853516 493.439697,2844.439941 493.991638,2847.273926 
	C491.295837,2847.684814 487.981964,2849.053955 486.145203,2844.642578 
	C486.949280,2843.344482 487.904724,2842.762695 489.443237,2842.145996 
z"
        />
        <path
          fill="#485D70"
          opacity="1.000000"
          stroke="none"
          d="
M615.104614,2793.938965 
	C626.841797,2795.962891 637.695190,2798.193604 648.630554,2799.898193 
	C651.858948,2800.401123 655.082642,2800.847412 658.287598,2801.522461 
	C659.559875,2801.790283 660.883240,2801.261963 661.330872,2799.713867 
	C661.760193,2798.229004 661.149109,2797.113037 659.869507,2796.491699 
	C658.397522,2795.776611 656.867126,2795.022949 655.280151,2794.730713 
	C648.454773,2793.474121 652.210449,2790.210693 653.898560,2786.692383 
	C657.664490,2780.087158 664.284485,2779.928467 669.774597,2779.520996 
	C682.180847,2778.600586 694.690918,2779.191895 706.865662,2775.794189 
	C708.753418,2775.267334 710.784851,2775.885254 713.276611,2777.455078 
	C714.425598,2781.877441 716.419250,2784.205322 720.494934,2783.637451 
	C724.769653,2783.041748 723.742798,2779.418457 723.979309,2776.618652 
	C724.062195,2775.636963 723.998230,2774.642822 723.998291,2772.817383 
	C729.219238,2767.198242 734.976990,2769.601318 741.497253,2770.832520 
	C759.355896,2771.518555 776.352722,2772.238770 794.158813,2773.340820 
	C800.421204,2774.545654 806.047058,2775.034912 811.068542,2777.662598 
	C816.948914,2780.739746 822.812927,2781.025146 829.057373,2778.633057 
	C834.908325,2776.391602 841.251953,2776.329590 848.179260,2775.302979 
	C850.038574,2775.293945 851.097290,2775.351807 852.933594,2775.580078 
	C857.242615,2777.162109 860.770996,2777.221191 865.103210,2775.507568 
	C871.663452,2774.598877 877.412842,2773.992676 883.723999,2776.814453 
	C886.915466,2780.358643 887.085999,2783.803223 885.921875,2788.219238 
	C884.712891,2790.527832 883.333252,2791.314453 880.900330,2791.709961 
	C877.824036,2791.528809 875.489807,2790.785156 872.513611,2792.214355 
	C863.708679,2794.913330 855.403320,2795.540527 846.217285,2795.061035 
	C844.427551,2794.904297 843.409119,2794.836426 841.644653,2794.699707 
	C838.034729,2794.255615 835.213745,2793.185303 831.532593,2794.270752 
	C829.774780,2794.632568 828.746033,2794.751709 826.904785,2794.858398 
	C814.958984,2796.325928 803.958191,2794.628418 792.143555,2793.299561 
	C783.834595,2794.140137 776.043701,2793.945557 768.689819,2798.829102 
	C752.844543,2796.735596 738.068909,2803.779541 722.888428,2801.114502 
	C721.911621,2800.943115 720.773560,2800.584229 719.940674,2800.992188 
	C698.782166,2811.353027 675.796753,2808.749023 653.421448,2810.480957 
	C651.798523,2810.606445 650.165405,2811.062744 647.822083,2811.793701 
	C639.651672,2811.981445 632.697571,2815.238037 624.471375,2814.927002 
	C620.001587,2815.011230 616.319092,2814.984619 611.845947,2815.012207 
	C609.450378,2815.124756 607.930542,2815.584961 605.639832,2816.313477 
	C598.907227,2817.448730 592.909790,2816.741455 586.137634,2817.157715 
	C580.699036,2818.738770 576.408020,2821.535645 570.402344,2820.980469 
	C563.520569,2821.323242 557.480042,2824.154785 550.739990,2820.613281 
	C545.907959,2819.534180 541.544861,2820.937744 536.002136,2818.238770 
	C547.346680,2811.595215 558.073730,2806.814697 570.921814,2806.941895 
	C567.504822,2802.639648 563.263062,2802.041016 558.756470,2801.993652 
	C553.517761,2801.938477 548.204956,2802.004639 544.198730,2796.765137 
	C553.292175,2795.866211 562.475464,2795.483398 571.350220,2799.251953 
	C574.942688,2800.777832 579.640381,2801.147949 582.912048,2798.960449 
	C587.785278,2795.701660 592.934570,2796.159668 599.001465,2795.812012 
	C600.783936,2795.074219 601.699341,2794.363037 603.425110,2793.998047 
	C607.417419,2793.935059 610.834778,2793.939941 615.104614,2793.938965 
z"
        />
        <path
          fill="#2B3E51"
          opacity="1.000000"
          stroke="none"
          d="
M550.054443,2820.832275 
	C556.254517,2820.807129 562.029358,2817.634033 569.029541,2818.214111 
	C572.960510,2821.235840 576.776062,2821.031982 580.792053,2821.410889 
	C593.532227,2822.613037 606.220642,2821.781494 619.743713,2821.670898 
	C625.740479,2824.249512 634.472473,2822.566162 632.486206,2833.344727 
	C627.128845,2833.949463 622.360779,2834.361816 618.013733,2830.925781 
	C616.303101,2829.573975 612.998230,2830.044678 610.416565,2830.024414 
	C601.590881,2829.954346 592.764099,2830.034912 583.010010,2830.072754 
	C577.864380,2829.636475 573.457031,2830.810791 568.719971,2828.307129 
	C562.968994,2824.211914 557.150940,2826.848389 550.884644,2825.997559 
	C550.017883,2824.516113 550.026917,2823.041992 550.054443,2820.832275 
z"
        />
        <path
          fill="#3C5163"
          opacity="1.000000"
          stroke="none"
          d="
M603.641113,2793.524170 
	C603.319275,2795.012207 602.359192,2795.657715 600.468384,2795.785156 
	C600.355835,2794.052490 600.569519,2791.970947 603.641113,2793.524170 
z"
        />
        <path
          fill="#2B3E51"
          opacity="1.000000"
          stroke="none"
          d="
M523.063538,2840.064941 
	C524.613342,2841.842773 525.289307,2843.588867 526.286743,2846.165039 
	C516.972046,2844.911133 508.146301,2844.140625 499.731384,2840.999023 
	C499.503448,2840.913818 499.649170,2839.828369 499.818787,2838.619873 
	C500.017029,2838.030273 501.008301,2838.000488 501.504150,2838.000244 
	C503.504517,2839.258789 505.527771,2837.917480 507.620514,2839.388916 
	C512.846985,2839.855957 517.518311,2839.944336 523.063538,2840.064941 
z"
        />
        <path
          fill="#374D60"
          opacity="1.000000"
          stroke="none"
          d="
M508.031860,2839.199463 
	C506.355042,2839.939941 504.530884,2840.137207 502.448120,2838.505615 
	C504.056091,2837.339844 506.292511,2834.998535 508.031860,2839.199463 
z"
        />
        <path
          fill="#2B3E51"
          opacity="1.000000"
          stroke="none"
          d="
M1004.404663,2714.595459 
	C1001.086853,2714.150391 998.333435,2714.124756 994.753662,2714.109375 
	C993.163940,2713.045898 992.934509,2711.868896 993.778687,2709.824951 
	C1005.685425,2704.930664 1017.743286,2706.768555 1029.431030,2705.145996 
	C1041.438721,2703.478516 1053.774658,2704.671143 1065.644165,2700.956787 
	C1069.364990,2699.792480 1073.515991,2700.412109 1078.135376,2701.974854 
	C1082.560913,2704.404053 1080.904785,2707.931396 1080.953247,2711.188965 
	C1095.514526,2707.942383 1109.501831,2709.548584 1123.339966,2713.649170 
	C1128.536743,2715.189209 1133.825928,2714.705322 1139.116211,2714.188232 
	C1143.356567,2713.773193 1147.783325,2712.714600 1151.391724,2717.559326 
	C1150.248169,2728.075439 1146.910767,2730.278320 1134.761719,2728.502441 
	C1119.804688,2728.355225 1105.520996,2726.871826 1091.529663,2731.411621 
	C1086.641479,2732.997314 1081.118042,2732.388916 1075.947754,2730.072998 
	C1069.875122,2727.352783 1063.192749,2725.809814 1056.632935,2726.179932 
	C1043.748901,2726.906738 1031.628174,2723.964355 1018.770752,2720.204102 
	C1013.357666,2719.182373 1009.173706,2717.072266 1004.404663,2714.595459 
z"
        />
        <path
          fill="#374D60"
          opacity="1.000000"
          stroke="none"
          d="
M994.439941,2709.339844 
	C993.989746,2710.904785 993.978394,2711.822021 993.947144,2713.429199 
	C985.517761,2717.355713 976.903870,2716.367676 968.379822,2714.995117 
	C965.410156,2714.516846 962.202087,2713.172852 959.561707,2714.254883 
	C946.915222,2719.437012 933.958435,2714.807861 921.225464,2715.977539 
	C914.585083,2716.587158 909.410950,2711.704590 902.530396,2710.751221 
	C897.932190,2705.568359 899.392395,2702.874512 905.002808,2701.320312 
	C910.029968,2699.927490 915.509399,2702.296875 921.037720,2698.989502 
	C932.252319,2698.685547 942.710083,2700.610352 953.215881,2698.834961 
	C955.795410,2698.398926 959.706299,2699.534424 960.810547,2697.601318 
	C966.550659,2687.552490 976.116394,2689.060303 985.149048,2688.587646 
	C986.483887,2688.517822 987.464111,2687.375488 989.308838,2686.366211 
	C1000.551025,2683.681641 1011.147095,2684.476562 1021.695496,2685.622070 
	C1034.218262,2686.982178 1046.656250,2689.198242 1059.331421,2688.770508 
	C1061.979980,2688.680908 1064.648438,2688.892822 1067.192627,2689.771729 
	C1069.475098,2690.559814 1071.645142,2691.757568 1071.890015,2694.367676 
	C1072.169312,2697.346436 1069.489746,2698.057861 1067.401245,2698.833740 
	C1063.010986,2700.464600 1058.290649,2701.717529 1053.745728,2701.061035 
	C1038.980835,2698.928955 1024.052612,2704.529785 1009.411011,2699.872314 
	C1008.707642,2699.648682 1007.810608,2699.366943 1007.816162,2699.404541 
	C1009.528870,2710.924805 998.308411,2704.399902 994.439941,2709.339844 
z"
        />
        <path
          fill="#3D494C"
          opacity="1.000000"
          stroke="none"
          d="
M1133.843018,2728.735352 
	C1137.978516,2728.366455 1141.445068,2726.252686 1145.250977,2725.752686 
	C1148.677246,2725.302246 1149.903198,2722.746338 1150.027100,2718.763428 
	C1152.705688,2715.039795 1155.986328,2715.144287 1160.163940,2716.468994 
	C1164.219116,2721.562744 1160.625610,2725.793945 1159.600586,2730.892090 
	C1155.496582,2735.357666 1149.865601,2732.655518 1145.182861,2735.759033 
	C1142.678833,2736.784424 1140.960815,2738.093994 1139.073730,2738.425781 
	C1117.415771,2742.235107 1102.309814,2754.170410 1093.426514,2774.298340 
	C1091.808228,2777.965088 1088.048218,2780.090576 1084.717163,2782.301025 
	C1047.374146,2807.078857 1010.721863,2832.791504 976.682434,2862.045654 
	C975.158813,2863.355225 973.448608,2864.372803 970.683105,2864.270020 
	C969.998474,2863.068848 969.992737,2862.125488 970.001831,2860.470703 
	C971.586243,2855.408203 975.278748,2852.374756 977.637146,2848.111816 
	C973.585388,2843.862793 967.772034,2844.922363 961.789185,2842.305908 
	C966.564148,2839.176758 970.360901,2837.055664 975.015625,2838.734131 
	C981.389648,2841.031738 987.241760,2839.846191 993.510498,2836.439941 
	C991.632874,2831.804688 983.570496,2831.002441 985.745483,2824.933105 
	C987.730164,2819.394775 994.305237,2821.484863 999.002197,2820.713867 
	C1001.604126,2820.287109 1004.605469,2820.345703 1006.022827,2817.744873 
	C1008.947815,2812.378418 1014.699402,2810.873291 1019.614624,2807.005615 
	C1022.057861,2804.557129 1024.490479,2803.305664 1027.090576,2803.387939 
	C1035.309692,2803.647461 1040.392212,2799.335938 1044.443604,2791.977051 
	C1056.208984,2783.419434 1068.271240,2776.774658 1081.399658,2770.610596 
	C1083.653320,2768.981201 1084.043701,2767.252441 1084.041382,2764.656738 
	C1088.214111,2758.323730 1095.405151,2754.435791 1093.373169,2745.313721 
	C1092.752686,2742.528076 1097.243530,2742.600830 1099.682861,2740.709961 
	C1102.334595,2740.329590 1104.696411,2740.182617 1106.891479,2740.718750 
	C1110.845581,2741.684082 1113.451660,2741.663818 1114.914551,2736.723633 
	C1116.207397,2732.357910 1121.107178,2732.851562 1124.816406,2732.113037 
	C1127.850342,2731.509277 1131.598267,2733.341309 1133.843018,2728.735352 
z"
        />
        <path
          fill="#2B3E51"
          opacity="1.000000"
          stroke="none"
          d="
M1099.226562,2740.118164 
	C1099.924194,2741.448730 1104.639038,2742.567627 1100.233765,2743.927002 
	C1098.722656,2744.393311 1096.935669,2743.819824 1095.044556,2745.352539 
	C1093.744141,2748.651123 1099.425049,2751.369629 1096.378296,2755.822754 
	C1093.469238,2760.074951 1089.604248,2761.760010 1084.682983,2763.682617 
	C1078.528809,2763.966309 1073.015625,2763.966309 1066.309326,2763.966309 
	C1068.463745,2758.681641 1074.645630,2761.036621 1074.939087,2755.454834 
	C1059.768311,2756.816406 1044.885498,2751.414551 1029.424438,2755.428711 
	C1022.211731,2757.301270 1013.931274,2754.931885 1006.626343,2756.616455 
	C999.563416,2758.245605 994.314453,2757.009521 988.572388,2752.277832 
	C984.696167,2746.807129 984.592468,2742.586426 992.185425,2740.318848 
	C1005.622803,2747.622559 1018.991455,2746.112549 1032.410278,2744.009033 
	C1039.233643,2742.939453 1046.075562,2741.268799 1053.349609,2745.452148 
	C1067.491699,2746.316650 1080.755981,2745.217773 1093.547363,2740.511230 
	C1095.021606,2739.968994 1096.779175,2740.196777 1099.226562,2740.118164 
z"
        />
        <path
          fill="#21364A"
          opacity="1.000000"
          stroke="none"
          d="
M921.267273,2698.751465 
	C921.547485,2703.246094 919.238647,2703.966553 916.336304,2703.952881 
	C912.394653,2703.934082 908.452576,2704.025146 904.510620,2704.036133 
	C900.720520,2704.046875 901.514404,2706.542725 901.934021,2709.536865 
	C899.859314,2710.195557 897.726868,2710.136475 894.794189,2710.089111 
	C885.081238,2711.618408 876.012512,2711.654053 866.456787,2713.374512 
	C863.146851,2713.812744 860.455078,2713.812256 856.961426,2713.761230 
	C852.937195,2709.127930 847.786316,2708.383789 843.011169,2708.253174 
	C836.380127,2708.071533 830.175110,2705.444092 823.494690,2705.715088 
	C820.581421,2705.833496 817.645752,2704.636475 816.140015,2700.716797 
	C826.785400,2693.172607 837.476685,2697.846191 848.939880,2700.808594 
	C854.231018,2701.997070 857.512634,2699.126465 861.598267,2696.742676 
	C866.247253,2691.893799 871.129639,2693.310059 876.732422,2694.832520 
	C884.160828,2698.320312 890.483521,2697.699463 897.090515,2692.895020 
	C903.960510,2690.093018 909.736511,2691.581299 916.020752,2694.850098 
	C918.065369,2695.776367 919.291687,2696.604492 921.267273,2698.751465 
z"
        />
        <path
          fill="#3C5163"
          opacity="1.000000"
          stroke="none"
          d="
M1054.054688,2745.164062 
	C1041.489136,2745.395996 1029.256470,2748.047119 1016.884888,2749.167480 
	C1009.191101,2749.864502 1001.113098,2749.296631 994.464233,2742.765137 
	C1001.587524,2735.557617 1010.724243,2741.104248 1016.919861,2738.995361 
	C1007.912598,2738.764160 997.060303,2738.682129 986.269287,2734.291504 
	C988.394470,2727.974854 992.930603,2726.936768 998.136536,2729.140137 
	C1005.126038,2732.098389 1012.357056,2732.054199 1019.683838,2732.019043 
	C1034.320190,2731.948975 1048.958130,2732.100586 1063.593018,2731.937256 
	C1068.043579,2731.887451 1072.152100,2732.907227 1076.190918,2734.466309 
	C1077.727051,2735.059082 1079.219971,2736.038330 1078.723389,2738.113281 
	C1078.328491,2739.763672 1076.795410,2739.832520 1075.534302,2739.865723 
	C1070.547119,2739.997803 1065.556641,2740.014160 1060.567139,2740.041260 
	C1057.447144,2740.058350 1054.386353,2740.107666 1054.054688,2745.164062 
z"
        />
        <path
          fill="#1C2E3E"
          opacity="1.000000"
          stroke="none"
          d="
M985.239441,2733.778320 
	C997.467651,2733.156250 1008.778870,2736.641846 1020.380798,2736.044678 
	C1023.695618,2735.874268 1026.852661,2736.546875 1029.371094,2740.773682 
	C1023.705017,2743.093262 1018.137268,2741.855469 1012.828003,2742.095215 
	C1007.218201,2742.348633 1001.586914,2742.124512 995.041809,2742.126953 
	C987.448120,2741.405762 988.009155,2746.088623 987.963623,2751.131348 
	C984.331055,2752.282227 980.906250,2751.395508 976.757202,2750.254395 
	C975.116638,2750.104492 974.226257,2750.062256 972.665833,2750.071777 
	C966.743896,2750.031738 961.490540,2749.930664 956.237122,2749.929199 
	C953.209778,2749.928467 950.303711,2749.511719 947.874451,2747.595215 
	C946.476318,2746.491943 945.580872,2744.354736 947.162964,2743.441650 
	C951.758606,2740.788330 954.533447,2735.352051 961.280640,2735.783936 
	C967.726746,2736.196533 974.385132,2736.947510 981.420593,2734.418945 
	C982.879211,2733.839111 983.738831,2733.843506 985.239441,2733.778320 
z"
        />
        <path
          fill="#3A4F62"
          opacity="1.000000"
          stroke="none"
          d="
M866.766846,2713.872559 
	C866.276855,2712.589844 866.656921,2710.800293 867.971252,2710.789795 
	C876.333130,2710.722656 884.459167,2707.476562 893.456543,2709.537598 
	C885.907471,2716.044434 876.569214,2713.395752 866.766846,2713.872559 
z"
        />
        <path
          fill="#374D60"
          opacity="1.000000"
          stroke="none"
          d="
M1004.016541,2714.833984 
	C1008.898865,2714.110352 1013.513672,2715.329834 1017.671387,2719.472900 
	C1011.759033,2723.481445 1008.030090,2719.324951 1004.016541,2714.833984 
z"
        />
        <path
          fill="#556A7C"
          opacity="1.000000"
          stroke="none"
          d="
M616.000000,2709.336426 
	C604.606995,2710.405273 593.296570,2709.169434 581.120972,2708.319336 
	C568.068542,2704.428711 555.215698,2704.341064 542.742065,2702.009521 
	C538.954590,2701.301514 534.950073,2701.059082 532.250977,2696.817139 
	C539.862061,2695.953613 547.768982,2697.139648 555.592773,2692.675781 
	C557.194519,2691.013672 558.074768,2689.715576 560.055725,2688.435547 
	C562.764893,2687.348877 564.844788,2687.093506 567.761353,2687.114258 
	C580.714661,2686.926758 592.407532,2682.332275 605.489014,2683.301270 
	C613.434875,2685.062500 620.007812,2681.918457 626.732422,2680.656738 
	C638.394165,2678.469482 650.312317,2677.543213 662.517822,2673.659180 
	C698.922974,2667.873779 734.667908,2664.430908 771.580994,2665.066895 
	C778.150696,2667.682129 784.292053,2666.305908 790.969482,2667.226807 
	C793.754272,2667.549561 795.729736,2668.189453 798.543640,2667.617432 
	C800.869080,2667.284180 802.445374,2667.213867 804.835327,2667.020996 
	C815.814819,2666.496582 825.983887,2665.890137 836.841553,2668.328857 
	C840.886169,2669.300781 844.197266,2669.015381 848.305481,2669.211426 
	C850.232605,2669.343018 851.307678,2669.569336 853.097900,2670.296387 
	C855.236328,2671.624023 856.199646,2672.947510 857.272095,2675.186035 
	C857.910889,2677.587402 857.768066,2679.172363 856.501953,2681.277344 
	C847.348450,2679.260010 838.357544,2679.318604 829.559509,2678.275391 
	C821.536072,2677.323975 813.470825,2675.409668 805.144287,2677.785645 
	C800.793213,2679.027344 796.040588,2677.418945 791.461243,2676.503174 
	C787.997009,2675.810303 783.167297,2674.167969 781.916504,2681.063721 
	C776.486389,2681.854736 771.144775,2681.868896 764.906982,2681.900879 
	C762.415344,2681.987061 760.817261,2681.987061 758.626892,2682.149414 
	C757.138367,2682.178955 756.245972,2682.150879 754.683960,2682.125488 
	C747.675293,2683.975830 741.003235,2677.983154 734.234253,2683.293457 
	C732.933777,2683.636230 732.195496,2683.629639 730.903442,2683.617432 
	C724.861328,2679.510498 718.662781,2682.038818 712.976013,2682.388184 
	C706.789368,2682.768555 701.084229,2682.666504 695.599854,2679.579590 
	C698.651550,2681.173340 701.586487,2682.734863 700.425293,2687.453369 
	C689.340454,2686.690430 679.050049,2682.837646 668.106506,2684.035889 
	C665.206238,2684.353271 662.213013,2683.909668 658.717407,2685.651611 
	C644.645325,2690.634521 631.327942,2695.872803 617.387390,2697.559082 
	C607.952393,2698.700195 598.437378,2701.041260 587.954224,2699.997070 
	C579.894653,2700.259766 572.632324,2700.181885 565.420105,2699.916260 
	C564.968506,2700.959229 565.099243,2700.400879 566.002502,2700.288818 
	C572.231384,2699.513428 578.512634,2700.861328 585.549683,2700.004639 
	C592.885376,2700.174805 599.790039,2698.901611 605.615051,2705.133789 
	C608.972351,2705.760254 611.688354,2705.854980 615.221802,2705.956787 
	C616.026184,2706.863281 616.013123,2707.762695 616.000000,2709.336426 
z"
        />
        <path
          fill="#43596D"
          opacity="1.000000"
          stroke="none"
          d="
M856.394531,2682.396973 
	C855.961426,2680.394043 855.978516,2678.930908 855.994995,2676.733887 
	C858.967773,2671.902100 864.428101,2674.257080 868.630493,2671.029785 
	C875.994751,2668.348877 882.598328,2667.598877 889.729370,2671.385254 
	C892.408569,2681.957031 900.725342,2679.871826 907.404053,2680.326904 
	C912.522522,2680.675537 917.710266,2678.127686 923.450745,2680.797852 
	C926.657959,2684.974121 923.525574,2686.903564 920.201477,2689.047363 
	C929.759460,2691.348877 938.083557,2691.654785 947.220947,2690.123291 
	C943.717163,2685.757324 938.068848,2687.673340 935.308472,2683.007568 
	C936.307373,2676.125732 942.559814,2677.094238 946.375427,2674.636719 
	C948.751343,2673.106201 950.795898,2671.934082 951.402588,2668.405762 
	C980.429565,2669.969238 1008.705994,2659.847412 1037.864502,2667.877930 
	C1041.155029,2670.000977 1040.633667,2672.009033 1038.677490,2674.746094 
	C1034.977051,2679.306885 1029.453491,2677.822510 1025.183350,2681.080078 
	C1016.156067,2685.542480 1007.758362,2683.570312 999.494507,2681.090088 
	C995.781006,2679.975342 992.723694,2679.814209 989.207336,2681.973389 
	C983.773315,2685.310303 991.751770,2684.731445 990.179565,2687.345703 
	C988.044983,2692.081299 984.348877,2692.052002 980.623413,2692.016846 
	C977.637146,2691.988770 974.650208,2692.026367 971.663635,2692.051758 
	C968.197449,2692.081299 965.590576,2693.026611 966.746460,2697.296387 
	C967.806396,2701.211670 965.568909,2701.879395 962.500610,2701.941895 
	C954.247314,2702.109619 945.993958,2701.506348 937.820801,2703.773438 
	C932.679810,2705.199707 928.708984,2700.099365 922.872559,2699.971680 
	C922.099731,2699.940186 922.048035,2699.937988 922.052368,2699.919434 
	C921.207825,2699.101562 920.310791,2698.375244 918.605042,2697.906982 
	C911.900940,2696.593018 906.323792,2692.691406 898.835571,2694.008301 
	C891.409546,2693.505859 886.080750,2698.889893 878.708252,2697.967041 
	C873.306274,2696.797852 868.601807,2695.653809 862.869263,2696.188477 
	C864.239929,2686.668945 864.239929,2686.668945 856.394531,2682.396973 
z"
        />
        <path
          fill="#4C6174"
          opacity="1.000000"
          stroke="none"
          d="
M849.787231,2700.575684 
	C838.973022,2703.093506 828.525513,2698.566895 816.907227,2699.977051 
	C810.519348,2697.837891 805.018982,2695.760498 799.587463,2693.516357 
	C798.090027,2692.897217 796.632874,2691.834229 797.230713,2689.777344 
	C797.696655,2688.174072 799.271179,2687.941650 800.458618,2688.113770 
	C810.782288,2689.611084 821.374878,2684.480713 831.574097,2689.445312 
	C833.380920,2690.324951 835.429199,2689.622314 837.407349,2689.247559 
	C848.806824,2687.087402 849.742798,2687.918945 849.787231,2700.575684 
z"
        />
        <path
          fill="#4C6174"
          opacity="1.000000"
          stroke="none"
          d="
M568.222046,2689.612793 
	C565.700684,2690.089355 563.728455,2690.090576 561.016785,2690.099121 
	C559.063232,2689.851318 558.118164,2689.092529 556.561646,2688.216309 
	C548.533997,2688.473145 540.911194,2686.881836 532.816406,2689.856689 
	C525.145996,2689.879150 518.194458,2691.705566 510.712646,2688.520508 
	C497.142822,2692.474121 484.374237,2695.872070 470.544159,2692.403564 
	C465.903595,2691.239502 460.541595,2690.601562 456.654297,2696.487305 
	C454.050140,2700.430420 449.402222,2695.746338 444.821381,2696.100342 
	C443.773773,2695.749023 443.516632,2695.408203 443.627899,2694.543457 
	C445.431671,2693.167236 447.109802,2692.334717 447.022766,2690.418213 
	C446.921844,2688.196045 444.992462,2688.242188 443.360260,2688.056396 
	C440.609711,2687.743408 437.525543,2688.733887 434.429047,2685.944580 
	C441.256439,2681.333008 447.808838,2681.048096 454.640808,2683.312744 
	C460.357513,2685.208008 465.861847,2686.986816 472.047363,2684.521240 
	C476.218781,2682.858643 480.319336,2686.505371 484.741547,2685.995361 
	C487.782776,2685.644775 490.273285,2685.330566 488.258514,2680.684570 
	C486.780426,2678.523193 486.386963,2676.817139 487.325989,2674.128174 
	C493.201416,2669.531006 499.272247,2670.745850 506.087952,2671.213135 
	C512.853760,2673.418213 518.819763,2673.886475 525.670898,2671.750000 
	C537.595764,2671.081055 548.360962,2667.912354 560.213501,2667.188965 
	C565.061890,2667.597412 569.071655,2668.580078 570.507935,2672.875732 
	C571.623413,2676.211914 569.052429,2677.465820 566.209839,2677.888184 
	C570.719849,2678.535889 576.844116,2674.835449 578.872131,2683.090820 
	C576.543030,2687.694092 571.341675,2685.966553 568.222046,2689.612793 
z"
        />
        <path
          fill="#495E72"
          opacity="1.000000"
          stroke="none"
          d="
M443.120178,2694.004150 
	C444.000031,2694.364258 444.003754,2694.708984 444.019196,2695.571777 
	C444.455780,2701.210938 442.953979,2704.139160 436.961304,2704.047119 
	C418.422150,2703.762451 399.992798,2707.312500 381.423248,2705.970947 
	C375.324524,2705.530273 369.744629,2707.946045 363.731293,2708.134277 
	C352.730713,2708.478271 342.060211,2706.163818 330.620972,2704.718750 
	C332.824066,2696.492188 340.576080,2696.719971 345.965332,2696.767822 
	C362.251129,2696.912598 377.832245,2691.386719 394.938721,2691.921631 
	C396.813110,2692.931885 397.990326,2693.047363 399.768036,2692.279785 
	C401.024567,2691.870361 401.787994,2691.852783 403.123901,2691.857422 
	C404.702820,2692.823242 405.858459,2693.075195 407.595703,2692.163574 
	C412.581543,2688.698242 417.352264,2689.887207 422.086395,2691.041260 
	C428.717010,2692.657959 435.297546,2694.556396 443.120178,2694.004150 
z"
        />
        <path
          fill="#3F5467"
          opacity="1.000000"
          stroke="none"
          d="
M510.325867,2688.708252 
	C516.898743,2688.269043 523.896606,2687.135498 531.394287,2689.425293 
	C524.760376,2694.237061 517.626404,2695.134033 510.325867,2688.708252 
z"
        />
        <path
          fill="#3F5467"
          opacity="1.000000"
          stroke="none"
          d="
M556.005493,2688.703613 
	C557.910217,2685.845703 559.142944,2686.589600 560.138062,2689.525635 
	C559.297180,2690.734863 558.317200,2691.363525 556.752808,2692.083252 
	C556.116638,2691.248535 556.064880,2690.322998 556.005493,2688.703613 
z"
        />
        <path
          fill="#3F5467"
          opacity="1.000000"
          stroke="none"
          d="
M585.407104,2698.934570 
	C595.617554,2697.392822 605.450500,2696.347412 615.320007,2695.600830 
	C624.325378,2694.919922 633.426392,2691.904785 641.840637,2688.379395 
	C646.753357,2686.321533 651.354248,2686.213379 657.092651,2685.959717 
	C661.336975,2690.057373 667.071289,2686.000977 670.623474,2689.491455 
	C670.447144,2691.957764 668.583801,2692.087891 667.059204,2691.932373 
	C658.587341,2691.069580 650.274597,2696.377197 641.693481,2692.515869 
	C640.649048,2692.046143 638.644104,2692.758789 638.937439,2694.427246 
	C640.151672,2701.332031 635.205566,2699.884521 631.564941,2699.954834 
	C624.963379,2700.082520 618.357666,2699.996338 611.753723,2700.001221 
	C608.901001,2700.003174 606.408630,2700.496582 606.235718,2704.881104 
	C599.862366,2705.447510 594.286865,2701.024414 586.831787,2702.026855 
	C585.321838,2701.327148 584.998901,2700.500977 585.407104,2698.934570 
z"
        />
        <path
          fill="#3A4F62"
          opacity="1.000000"
          stroke="none"
          d="
M700.662842,2688.285400 
	C699.790466,2684.454590 697.737549,2682.830566 694.532898,2681.796875 
	C691.924255,2680.955566 688.816589,2680.672852 686.922302,2678.158936 
	C689.167236,2674.993896 691.332520,2676.974121 693.327576,2677.524902 
	C703.348328,2680.291016 713.485962,2681.122314 723.702087,2679.050781 
	C726.977539,2678.386230 728.756836,2679.458984 730.135742,2682.948730 
	C726.395691,2683.749023 722.441040,2683.870117 718.487915,2684.029053 
	C716.210938,2684.120850 713.736511,2683.821289 712.420532,2686.360840 
	C709.653625,2691.700195 705.513428,2690.138672 700.662842,2688.285400 
z"
        />
        <path
          fill="#3A4F62"
          opacity="1.000000"
          stroke="none"
          d="
M734.561279,2683.756348 
	C734.784973,2678.752686 738.267151,2677.443604 742.625732,2678.207275 
	C746.143982,2678.823730 749.556152,2680.045898 753.514893,2681.563721 
	C753.591614,2684.988525 751.914001,2686.443848 749.185181,2685.355957 
	C744.692688,2683.564697 740.037415,2684.234863 734.561279,2683.756348 
z"
        />
        <path
          fill="#3A4F62"
          opacity="1.000000"
          stroke="none"
          d="
M758.434082,2682.649902 
	C759.335815,2680.009766 761.084961,2679.708496 763.608459,2681.457031 
	C762.656738,2683.942139 760.927490,2684.312744 758.434082,2682.649902 
z"
        />
        <path
          fill="#233444"
          opacity="1.000000"
          stroke="none"
          d="
M279.595825,2861.538574 
	C278.844116,2863.744873 277.099457,2863.995117 274.540649,2863.848633 
	C274.535858,2860.715576 276.081512,2859.353271 279.595825,2861.538574 
z"
        />
        <path
          fill="#243748"
          opacity="1.000000"
          stroke="none"
          d="
M1081.021484,2770.083984 
	C1082.793091,2775.189941 1079.077759,2776.200195 1075.500732,2777.184570 
	C1071.433594,2778.303955 1066.704590,2778.280518 1065.496094,2783.768799 
	C1065.374634,2784.320557 1064.609497,2785.206543 1064.245605,2785.158447 
	C1057.352539,2784.250732 1053.439209,2790.566162 1046.762451,2792.008301 
	C1032.594604,2796.964600 1018.587646,2800.676758 1004.516846,2799.155029 
	C982.273071,2796.749512 960.195496,2798.548584 938.044250,2798.874512 
	C935.725830,2798.908691 933.400085,2798.830078 930.341736,2798.166016 
	C923.855774,2792.470215 926.388611,2789.320312 932.644653,2787.544189 
	C936.488342,2786.452637 940.588013,2787.198975 944.717590,2786.564697 
	C944.206970,2784.323975 942.268921,2784.500732 941.059448,2784.477295 
	C924.938538,2784.166992 910.054565,2777.478760 894.260742,2775.473389 
	C890.831299,2775.037842 887.687134,2774.241699 884.573181,2777.744629 
	C877.930725,2778.072021 871.871216,2778.018555 864.903320,2777.961182 
	C861.083618,2777.169434 858.174561,2777.536377 854.633545,2777.987793 
	C853.126587,2777.976074 852.251709,2777.952148 850.720947,2777.902100 
	C846.793396,2773.721924 841.514648,2775.725098 837.510376,2773.786133 
	C834.657043,2772.404297 830.803711,2771.638672 831.400635,2767.458740 
	C831.957397,2763.560547 835.596313,2764.243652 839.204346,2764.000000 
	C842.946167,2763.913818 845.945435,2764.173096 849.326904,2762.314453 
	C853.389893,2761.930664 856.783691,2761.851318 861.023560,2761.720947 
	C862.813782,2761.039307 863.721130,2760.339600 865.426147,2760.014648 
	C867.479492,2759.943604 868.966125,2759.929443 871.195557,2759.887695 
	C874.432373,2757.588867 877.531494,2758.099121 881.247559,2757.992188 
	C890.550232,2755.158203 899.404968,2756.256592 909.070190,2755.938721 
	C914.304749,2759.810791 920.447510,2756.895996 925.072266,2759.966553 
	C926.695068,2761.044189 929.189758,2761.415283 928.774780,2763.844482 
	C928.327942,2766.459961 925.740295,2765.788818 923.866272,2765.981689 
	C919.986694,2766.381104 915.989746,2765.203613 911.347168,2767.032471 
	C918.292419,2771.651123 925.068237,2769.656250 931.837585,2769.107666 
	C941.861938,2768.295166 951.616516,2767.403809 962.039978,2768.955566 
	C974.975159,2770.881348 988.959167,2773.354248 1001.951599,2766.745605 
	C1003.847290,2765.781738 1006.655762,2765.730469 1008.788330,2766.266113 
	C1018.321472,2768.660889 1027.873169,2766.830811 1037.325317,2767.168213 
	C1046.504150,2767.496338 1056.190186,2763.289795 1065.373535,2769.176514 
	C1069.432739,2771.778320 1075.167725,2769.799561 1081.021484,2770.083984 
z"
        />
        <path
          fill="#2B3E51"
          opacity="1.000000"
          stroke="none"
          d="
M972.306091,2750.492676 
	C972.879456,2748.723389 973.937561,2748.093018 975.716553,2749.725098 
	C975.081665,2751.394287 974.005371,2751.888428 972.306091,2750.492676 
z"
        />
        <path
          fill="#3C5163"
          opacity="1.000000"
          stroke="none"
          d="
M881.500732,2757.479004 
	C879.662292,2760.795654 876.438843,2759.789795 872.714600,2759.913330 
	C873.514282,2754.358643 877.360718,2755.984131 881.500732,2757.479004 
z"
        />
        <path
          fill="#3C5163"
          opacity="1.000000"
          stroke="none"
          d="
M865.647705,2759.541992 
	C865.324280,2761.049316 864.357544,2761.662109 862.473694,2761.720947 
	C862.365540,2759.947021 862.646790,2757.934326 865.647705,2759.541992 
z"
        />
        <path
          fill="#2F4457"
          opacity="1.000000"
          stroke="none"
          d="
M839.417725,2763.448486 
	C838.432129,2766.410889 835.227722,2765.593506 832.572693,2767.456055 
	C835.695435,2771.372803 839.747681,2771.716064 843.729980,2771.964355 
	C846.802979,2772.156250 849.685059,2772.288818 850.009644,2777.103516 
	C841.959717,2780.359375 833.150635,2779.158203 825.232422,2783.459229 
	C821.723572,2785.365479 816.444702,2784.921143 812.835022,2782.187256 
	C808.055664,2778.567383 802.550598,2778.183594 796.544922,2776.702148 
	C778.940125,2766.911865 761.309509,2766.505615 742.693298,2773.288086 
	C736.566345,2774.028076 731.343567,2771.086182 724.844238,2771.983398 
	C721.190308,2768.748047 717.489197,2767.963379 713.429443,2768.003662 
	C708.219971,2768.055420 703.009521,2768.003906 696.875793,2767.978027 
	C695.630676,2765.740723 694.483154,2764.426514 692.151917,2764.086182 
	C689.286194,2763.667969 686.145630,2764.863281 683.548523,2762.734375 
	C683.504150,2759.468262 686.053528,2760.044922 687.746826,2760.120117 
	C698.964844,2760.618408 710.163574,2759.832764 721.457642,2760.924561 
	C727.450562,2761.503906 735.761841,2760.859131 739.962036,2752.751953 
	C739.796387,2746.997803 734.052795,2748.495361 731.468811,2743.962158 
	C738.179504,2743.962158 744.183350,2743.962158 751.087585,2743.949463 
	C755.280457,2750.122070 760.435486,2751.760498 767.347900,2752.298340 
	C777.809753,2753.112549 788.790344,2753.401367 797.863464,2761.235352 
	C806.222351,2765.424316 814.513794,2766.420654 823.488159,2762.481934 
	C828.938660,2762.308350 833.974487,2761.160400 839.417725,2763.448486 
z"
        />
        <path
          fill="#243748"
          opacity="1.000000"
          stroke="none"
          d="
M823.231995,2762.006348 
	C822.550598,2767.904053 817.787292,2768.201904 813.213135,2767.844971 
	C808.088562,2767.445068 803.011475,2766.326416 797.949646,2765.337158 
	C797.051941,2765.161865 796.781921,2763.947754 797.594238,2762.431396 
	C802.132141,2761.937012 806.232666,2761.931396 811.215698,2761.856934 
	C813.554382,2761.826904 815.010498,2761.865967 817.193970,2761.873047 
	C819.438904,2761.894775 820.956299,2761.948975 823.231995,2762.006348 
z"
        />
        <path
          fill="#2F4457"
          opacity="1.000000"
          stroke="none"
          d="
M713.964844,2777.187012 
	C711.395874,2778.301758 708.677246,2778.238770 706.208435,2778.993164 
	C697.908020,2781.529297 689.473389,2782.550537 680.834656,2782.014648 
	C672.144958,2781.475830 663.862854,2783.593506 654.803223,2785.935547 
	C653.254333,2785.429688 652.581970,2784.624512 651.721313,2784.229736 
	C649.873535,2783.382568 647.834717,2782.699951 648.784912,2780.067383 
	C649.459961,2778.197021 651.298706,2778.142822 652.945801,2778.088623 
	C663.473389,2777.744141 673.830811,2774.962402 684.463440,2775.998779 
	C688.801025,2776.421387 693.026428,2775.824951 696.927795,2773.605225 
	C700.900635,2771.344482 705.282349,2772.041748 709.554626,2772.084229 
	C712.346313,2772.112061 713.754944,2773.644043 713.964844,2777.187012 
z"
        />
        <path
          fill="#395166"
          opacity="1.000000"
          stroke="none"
          d="
M2361.286621,2549.405273 
	C2361.684326,2556.270752 2365.128906,2557.905029 2370.729004,2558.080322 
	C2383.215820,2558.470947 2395.511719,2560.569580 2407.703125,2563.893311 
	C2391.767334,2563.893311 2375.671631,2565.266357 2359.949707,2563.432373 
	C2348.610596,2562.109375 2337.265869,2558.998047 2325.862061,2556.987549 
	C2323.908691,2556.642822 2321.905518,2555.940674 2319.990723,2556.116699 
	C2306.257568,2557.377930 2292.563232,2554.893799 2278.821045,2555.893799 
	C2271.910156,2556.396729 2265.455566,2555.533203 2258.792236,2551.912842 
	C2251.640869,2548.027100 2242.624512,2549.450928 2234.478027,2548.106445 
	C2230.586914,2547.463867 2226.437988,2547.503906 2222.520996,2548.054443 
	C2208.964600,2549.959717 2195.350830,2548.673340 2181.960449,2547.624268 
	C2164.845459,2546.283447 2147.462891,2547.071289 2130.588379,2542.688721 
	C2126.814941,2541.708496 2122.661621,2542.125244 2118.679932,2542.032471 
	C2115.022949,2541.947266 2111.325195,2542.407471 2109.344971,2538.085449 
	C2108.401367,2536.025879 2106.065674,2535.987549 2104.073486,2536.062500 
	C2096.458008,2536.349854 2088.790039,2535.088135 2080.598145,2537.367432 
	C2078.995605,2539.111572 2077.687744,2539.584229 2075.492188,2539.558594 
	C2072.255371,2538.744629 2070.223877,2536.503418 2066.619629,2536.987793 
	C2062.845215,2537.722412 2059.740479,2538.431396 2055.828613,2538.431152 
	C2052.650391,2538.289551 2050.437744,2539.495850 2047.241943,2539.884521 
	C2010.638428,2539.122070 1974.881592,2537.811768 1939.260498,2533.902832 
	C1930.992920,2532.995605 1922.676392,2533.405518 1914.540649,2535.550537 
	C1907.076538,2537.518311 1899.444824,2537.587891 1891.796387,2537.577637 
	C1871.909790,2537.551514 1852.004639,2537.044434 1832.240845,2540.107666 
	C1829.288818,2540.565186 1826.284180,2539.927490 1822.475342,2539.059570 
	C1821.024048,2538.604004 1820.444946,2538.415283 1819.065186,2537.791504 
	C1817.824463,2536.942383 1817.384155,2536.528809 1816.476807,2535.344971 
	C1813.999634,2525.799316 1807.213013,2517.284424 1815.206787,2507.409668 
	C1825.647339,2503.072266 1836.167603,2504.173340 1847.070312,2502.029297 
	C1853.787231,2501.333252 1859.475464,2502.011719 1865.415405,2505.323242 
	C1870.304932,2511.085205 1876.308228,2510.428223 1881.776611,2509.830078 
	C1888.044189,2509.144775 1894.153320,2509.625977 1900.371338,2509.773926 
	C1910.275879,2510.009277 1920.337402,2511.376221 1930.147827,2508.449219 
	C1931.066650,2508.175293 1932.409058,2507.939941 1933.069824,2508.393555 
	C1941.811646,2514.393555 1952.774048,2510.205566 1961.869751,2514.810791 
	C1965.818726,2516.809814 1970.448975,2516.570801 1974.616943,2515.358887 
	C1984.931152,2512.359619 1994.727051,2514.505615 2006.258545,2517.444824 
	C1995.484619,2523.739014 1985.188965,2521.459473 1975.308105,2521.977539 
	C1965.225952,2522.506348 1955.506592,2518.545410 1944.337891,2518.344482 
	C1946.411987,2523.008301 1949.271851,2523.897705 1952.202881,2523.853027 
	C1965.176636,2523.655762 1977.907593,2526.570312 1990.950684,2526.303467 
	C1999.651489,2526.125488 2008.031860,2523.351562 2016.678589,2524.033447 
	C2037.824585,2525.701660 2059.092529,2525.128418 2080.195312,2527.777588 
	C2086.427734,2528.559814 2092.852539,2528.290039 2099.155029,2527.919678 
	C2107.569824,2527.424805 2115.552246,2530.426758 2123.888428,2530.450439 
	C2125.775146,2530.455811 2128.039795,2531.209961 2129.478027,2532.404785 
	C2135.619873,2537.506348 2142.494629,2536.188477 2149.385986,2535.197998 
	C2162.967773,2533.246094 2176.511230,2532.926025 2189.926270,2536.345215 
	C2192.486572,2536.997803 2195.890869,2537.397705 2197.250244,2539.178467 
	C2203.324707,2547.134766 2212.147217,2542.758545 2219.499756,2544.650635 
	C2221.536377,2545.174805 2223.854736,2543.291748 2225.670898,2540.736816 
	C2239.253662,2535.159668 2253.190430,2537.065430 2266.099609,2539.345703 
	C2293.997314,2544.272949 2322.092285,2544.375244 2350.122314,2546.278564 
	C2353.750000,2546.524902 2357.439209,2546.622803 2361.286621,2549.405273 
z"
        />
        <path
          fill="#61798F"
          opacity="1.000000"
          stroke="none"
          d="
M1866.506104,2505.384766 
	C1860.431396,2506.317871 1855.002563,2505.362061 1848.791626,2504.107178 
	C1840.991699,2502.387695 1833.963867,2501.606689 1826.768677,2502.650635 
	C1823.211182,2503.166748 1819.621338,2503.085938 1815.704834,2500.649902 
	C1813.448364,2497.359131 1813.374390,2494.589355 1815.447754,2491.150146 
	C1822.601929,2488.964111 1828.102295,2485.743652 1832.820557,2480.036133 
	C1837.456299,2480.239014 1841.601685,2480.823242 1845.232544,2483.882080 
	C1841.707397,2487.702881 1837.007202,2484.550537 1833.713867,2486.970947 
	C1834.777588,2490.716309 1838.071289,2490.537354 1840.221313,2489.748535 
	C1848.674927,2486.646240 1856.531982,2490.305664 1864.623901,2491.204590 
	C1866.927124,2491.460205 1869.272705,2492.151367 1871.524292,2491.919189 
	C1884.094482,2490.622559 1896.652588,2492.826416 1909.195435,2491.919189 
	C1915.793457,2491.441162 1922.356323,2490.462891 1928.928101,2489.653564 
	C1930.423706,2489.469482 1931.348267,2488.358154 1931.328491,2486.949951 
	C1931.308472,2485.530518 1930.268311,2484.300781 1928.836426,2484.355957 
	C1918.018799,2484.772461 1906.893433,2481.408203 1896.456177,2486.444092 
	C1890.692261,2489.225098 1885.455322,2487.765625 1880.190552,2485.438232 
	C1879.474243,2485.121582 1878.830444,2483.959473 1878.715332,2483.112549 
	C1878.510254,2481.602539 1879.576660,2480.661133 1880.990967,2480.388916 
	C1882.288086,2480.138672 1883.645020,2479.979980 1884.957397,2480.064453 
	C1893.038574,2480.584961 1900.857300,2479.274902 1908.519653,2476.825195 
	C1911.452881,2475.886963 1914.640747,2475.306641 1917.342163,2476.561035 
	C1929.122314,2482.031982 1941.619141,2482.031494 1955.073975,2481.981934 
	C1966.321289,2479.539795 1976.524902,2480.936768 1987.369385,2483.501953 
	C1988.033813,2483.980469 1989.019287,2483.977295 1989.512085,2483.983887 
	C1992.254028,2486.602783 1992.781372,2489.465332 1990.628906,2493.312500 
	C1981.395752,2494.007080 1972.791382,2493.982666 1964.187500,2494.037598 
	C1960.958496,2494.058350 1957.428467,2493.386963 1955.710571,2497.454346 
	C1955.405029,2498.177734 1954.091553,2499.124512 1953.487549,2498.975830 
	C1942.597656,2496.296143 1931.862549,2502.317871 1921.106079,2499.644775 
	C1915.545532,2498.262451 1909.722412,2496.811279 1904.474121,2498.224365 
	C1892.133911,2501.547119 1879.080566,2500.375000 1866.506104,2505.384766 
z"
        />
        <path
          fill="#5B768D"
          opacity="1.000000"
          stroke="none"
          d="
M2362.695068,2549.956055 
	C2340.443848,2549.273926 2319.020508,2547.428223 2297.476074,2547.750488 
	C2290.367920,2547.856934 2283.134277,2545.632568 2276.114258,2543.857178 
	C2269.264160,2542.125000 2262.353271,2541.518799 2255.441650,2542.024170 
	C2246.082764,2542.708740 2237.079590,2539.492920 2226.894531,2540.132324 
	C2226.326172,2537.987793 2227.292236,2536.457520 2229.679688,2536.143311 
	C2234.923584,2535.452881 2240.144775,2534.538818 2245.406250,2534.049561 
	C2248.929688,2533.722168 2251.678955,2533.845703 2251.689453,2528.696289 
	C2251.699219,2523.946289 2256.280273,2526.628906 2258.784424,2526.090332 
	C2260.830078,2525.650146 2261.430176,2526.054199 2261.572998,2529.092773 
	C2261.782715,2533.545166 2266.719727,2535.447754 2270.833496,2535.799316 
	C2279.076172,2536.503906 2287.167480,2538.887207 2295.558838,2538.055176 
	C2297.521240,2537.860596 2299.666748,2537.796875 2301.509033,2538.373047 
	C2317.405029,2543.344971 2333.786133,2542.425537 2349.979004,2541.739014 
	C2359.870361,2541.319336 2369.735352,2539.724365 2379.713623,2539.855713 
	C2381.601562,2539.880371 2383.880371,2539.292480 2385.338867,2538.161865 
	C2394.602051,2530.981445 2405.887695,2532.021973 2416.430176,2529.691406 
	C2434.420410,2525.714844 2451.313232,2529.760010 2469.198242,2533.974121 
	C2470.000000,2534.347656 2469.999756,2534.695312 2469.994629,2535.565430 
	C2467.629395,2535.953613 2465.345947,2536.211914 2462.605957,2537.688965 
	C2456.733887,2537.561035 2451.357422,2532.935303 2446.149902,2539.225830 
	C2434.706055,2536.191162 2422.857910,2538.810059 2411.326172,2536.914795 
	C2407.395752,2536.268799 2399.440918,2535.235596 2397.550537,2543.219727 
	C2396.995605,2545.563232 2394.801758,2545.873779 2392.739746,2545.919678 
	C2389.742920,2545.986328 2386.731201,2546.167969 2383.749512,2545.958984 
	C2376.626221,2545.459961 2369.827393,2546.409912 2362.695068,2549.956055 
z"
        />
        <path
          fill="#334D63"
          opacity="1.000000"
          stroke="none"
          d="
M2127.057861,2518.049316 
	C2136.533447,2518.235352 2145.184326,2517.380127 2153.704102,2519.709229 
	C2156.181641,2520.386963 2159.153809,2520.411865 2161.630127,2519.742188 
	C2173.452148,2516.543457 2184.630859,2519.535156 2195.752197,2522.919434 
	C2199.699707,2524.120605 2203.636963,2523.902588 2207.604248,2523.994141 
	C2211.594727,2524.086426 2215.590820,2523.964111 2219.578857,2524.102051 
	C2221.935791,2524.183350 2224.875244,2524.655029 2224.699463,2527.531006 
	C2224.497559,2530.830811 2221.197998,2529.875732 2219.096924,2529.888916 
	C2206.516602,2529.968750 2193.776367,2531.378418 2181.813965,2525.728271 
	C2180.342285,2525.033447 2178.502686,2524.191162 2177.063477,2524.497314 
	C2164.488525,2527.173828 2152.094971,2522.767822 2139.508057,2523.745605 
	C2133.552490,2524.208252 2127.400391,2522.136230 2121.292480,2521.206055 
	C2122.070557,2517.501221 2124.426270,2518.389160 2127.057861,2518.049316 
z"
        />
        <path
          fill="#61798F"
          opacity="1.000000"
          stroke="none"
          d="
M2025.618164,2491.400879 
	C2030.766724,2491.953857 2035.456177,2491.942871 2041.017944,2491.926758 
	C2044.074829,2491.941162 2046.259521,2491.960449 2049.261230,2491.965332 
	C2058.426514,2487.341309 2066.585938,2489.694580 2075.372070,2493.277344 
	C2075.639404,2495.065186 2075.264404,2496.196777 2074.404785,2497.701660 
	C2055.158447,2496.575439 2036.601074,2501.087402 2016.920410,2499.976318 
	C2014.791382,2499.948486 2013.710083,2499.596191 2012.339844,2498.452148 
	C2009.802734,2498.046875 2007.692627,2498.036377 2004.791260,2498.018066 
	C2000.376343,2495.195312 1999.465088,2491.597900 2001.457275,2486.655273 
	C2005.422974,2485.987549 2008.845825,2485.984131 2013.124268,2485.980957 
	C2019.019287,2484.576172 2022.260864,2487.311035 2025.618164,2491.400879 
z"
        />
        <path
          fill="#5B768D"
          opacity="1.000000"
          stroke="none"
          d="
M2001.301025,2485.680176 
	C2001.506470,2489.700195 2003.932007,2492.861816 2003.983643,2497.240723 
	C1998.416992,2502.366211 1995.124878,2496.502686 1990.420898,2494.530029 
	C1990.000366,2491.219727 1990.000610,2488.439453 1990.002930,2484.824707 
	C1993.582764,2484.100586 1997.261597,2483.436279 2001.301025,2485.680176 
z"
        />
        <path
          fill="#5B768D"
          opacity="1.000000"
          stroke="none"
          d="
M2269.474854,2510.584473 
	C2274.218994,2510.211670 2278.556152,2509.365479 2283.334473,2511.659180 
	C2283.421875,2513.039062 2282.940674,2514.924805 2282.106445,2515.096436 
	C2275.159912,2516.526123 2268.204346,2516.600342 2260.927979,2514.912354 
	C2262.909424,2510.545166 2266.819580,2513.020508 2269.474854,2510.584473 
z"
        />
        <path
          fill="#5B768D"
          opacity="1.000000"
          stroke="none"
          d="
M2012.011108,2498.618164 
	C2013.029175,2498.382812 2014.145508,2498.708008 2015.625122,2499.500488 
	C2014.223511,2502.024414 2012.976685,2501.534424 2012.011108,2498.618164 
z"
        />
        <path
          fill="#49647C"
          opacity="1.000000"
          stroke="none"
          d="
M2352.374023,2497.525635 
	C2343.708496,2498.562012 2335.584717,2501.023193 2327.215332,2499.964355 
	C2312.349854,2498.083984 2297.424805,2497.665771 2282.464844,2497.926270 
	C2281.803955,2497.937988 2281.051514,2497.974854 2280.493408,2497.696289 
	C2277.719238,2496.312256 2272.459961,2497.055908 2272.879395,2492.872070 
	C2273.332520,2488.348389 2278.209717,2490.166748 2281.225586,2490.104248 
	C2291.550781,2489.890869 2301.886230,2489.811768 2312.210693,2490.019775 
	C2327.523438,2490.327637 2342.662842,2488.689209 2357.788574,2486.637939 
	C2358.688721,2486.515869 2359.689941,2487.135010 2361.326904,2487.694824 
	C2361.010742,2493.114502 2355.881348,2494.018311 2352.374023,2497.525635 
z"
        />
        <path
          fill="#4A667C"
          opacity="1.000000"
          stroke="none"
          d="
M2076.702393,2493.630615 
	C2067.788818,2495.171143 2059.957520,2491.576416 2050.939941,2491.955078 
	C2050.311768,2490.214111 2050.908936,2488.688232 2052.779053,2488.171387 
	C2067.965576,2483.973633 2082.548096,2477.391357 2099.279053,2481.468262 
	C2106.729736,2483.283691 2115.144531,2483.211426 2122.984863,2480.676514 
	C2124.850098,2480.073730 2126.858398,2479.598877 2129.050049,2481.777100 
	C2126.061035,2487.100830 2119.706055,2486.794922 2114.292725,2489.930420 
	C2118.910156,2493.100098 2122.960205,2492.401123 2126.758057,2490.501221 
	C2133.107666,2487.324219 2139.584473,2487.228760 2146.153564,2489.137207 
	C2150.435791,2490.381348 2154.753418,2489.740723 2159.044434,2490.084229 
	C2160.660645,2490.213379 2162.647949,2490.172119 2162.770996,2492.398926 
	C2162.879639,2494.362061 2161.223389,2495.485596 2159.602295,2495.600342 
	C2152.645264,2496.093018 2145.603516,2496.847900 2138.758789,2495.109863 
	C2135.123779,2494.186768 2131.293701,2493.104980 2127.960449,2494.245361 
	C2119.500732,2497.139893 2111.700439,2494.843018 2103.572021,2492.965576 
	C2094.929443,2490.969238 2086.052734,2489.140869 2076.702393,2493.630615 
z"
        />
        <path
          fill="#4A667C"
          opacity="1.000000"
          stroke="none"
          d="
M2142.104492,2476.714355 
	C2149.168213,2474.203125 2151.019287,2474.922363 2153.992432,2481.246338 
	C2151.745605,2481.962646 2149.471924,2481.947266 2147.197998,2481.963867 
	C2144.173096,2481.985596 2142.179932,2480.852051 2142.104492,2476.714355 
z"
        />
        <path
          fill="#4C6880"
          opacity="1.000000"
          stroke="none"
          d="
M2255.183594,2456.092773 
	C2256.084717,2457.598389 2256.046631,2459.057617 2256.014160,2461.248779 
	C2247.018555,2462.261963 2238.687012,2459.388916 2230.341064,2456.046875 
	C2238.308838,2456.046875 2246.276611,2456.046875 2255.183594,2456.092773 
z"
        />
        <path
          fill="#765242"
          opacity="1.000000"
          stroke="none"
          d="
M1770.932373,2429.234375 
	C1779.367432,2444.592285 1791.691162,2456.364014 1801.284790,2470.203125 
	C1804.343994,2474.616211 1807.158569,2479.071777 1808.041260,2485.241699 
	C1802.459839,2496.087158 1797.835205,2506.684326 1792.311279,2517.626465 
	C1790.840088,2519.817383 1789.495850,2521.187256 1787.589844,2523.034668 
	C1785.875732,2524.449707 1784.582153,2525.150635 1782.479248,2525.876953 
	C1780.297729,2526.312988 1778.844360,2526.226074 1776.735352,2525.615479 
	C1770.926025,2522.194336 1773.467041,2517.035889 1772.877197,2512.021973 
	C1773.326538,2510.390137 1773.796997,2509.375732 1774.671021,2508.975586 
	C1794.268188,2499.998047 1793.905640,2486.980225 1787.456299,2470.350098 
	C1782.664307,2457.993408 1773.268921,2448.194092 1765.328247,2437.667725 
	C1763.607178,2435.385986 1761.741577,2434.524414 1760.200195,2438.816406 
	C1753.683105,2459.503418 1748.395386,2479.895996 1738.201050,2498.420166 
	C1735.013550,2504.211914 1735.698853,2510.724121 1733.113770,2517.263672 
	C1724.760742,2518.723145 1725.642578,2512.333740 1724.281494,2507.062256 
	C1724.293457,2505.044434 1724.517456,2503.735352 1724.137451,2501.820801 
	C1723.795044,2500.822021 1723.649292,2500.420410 1723.370605,2499.382324 
	C1723.201294,2493.307617 1727.828735,2490.333740 1730.318604,2485.465332 
	C1732.945312,2482.005371 1734.983521,2478.985352 1736.548950,2474.851562 
	C1742.831909,2461.418457 1746.490845,2448.046143 1747.233643,2433.148926 
	C1747.688843,2428.147949 1749.455566,2424.431152 1752.170898,2420.278076 
	C1754.143677,2418.129883 1755.981567,2417.227783 1758.791504,2416.630371 
	C1761.680176,2416.634277 1763.345337,2417.692627 1765.352295,2419.609375 
	C1767.629883,2422.896973 1770.252930,2425.140137 1770.932373,2429.234375 
z"
        />
        <path
          fill="#4A3831"
          opacity="1.000000"
          stroke="none"
          d="
M1790.439209,2518.489258 
	C1793.595215,2509.534668 1797.014282,2501.019043 1800.918823,2492.731934 
	C1802.116699,2490.189697 1803.553955,2487.287842 1807.385742,2486.083984 
	C1810.506836,2487.124268 1812.925537,2488.260254 1815.708740,2489.961426 
	C1816.017212,2493.150391 1815.961182,2495.773926 1815.967163,2499.189453 
	C1817.694580,2502.031982 1818.147583,2504.249756 1816.422852,2507.422852 
	C1814.074585,2514.783691 1812.120117,2521.352539 1817.323486,2527.578125 
	C1818.181274,2528.604492 1817.744507,2530.712402 1817.872192,2533.116211 
	C1817.459595,2534.195557 1817.080933,2534.479248 1815.993774,2534.770508 
	C1810.742432,2532.893066 1805.692383,2531.718994 1806.992065,2524.676270 
	C1807.679810,2520.950195 1803.828247,2521.135254 1801.520874,2520.517090 
	C1798.062744,2519.590576 1794.229980,2521.036377 1790.439209,2518.489258 
z"
        />
        <path
          fill="#3D494C"
          opacity="1.000000"
          stroke="none"
          d="
M1777.745850,2407.885986 
	C1778.900879,2415.752930 1780.433594,2422.928955 1779.775879,2430.216797 
	C1779.513428,2433.123779 1778.106689,2432.234863 1776.608398,2430.105469 
	C1775.449097,2428.875732 1774.701538,2428.052246 1773.790283,2426.313232 
	C1773.512817,2419.609131 1769.201538,2413.562256 1774.766602,2407.446289 
	C1776.094116,2407.156006 1776.651245,2407.199463 1777.745850,2407.885986 
z"
        />
        <path
          fill="#8C8D89"
          opacity="1.000000"
          stroke="none"
          d="
M1778.706299,2407.924316 
	C1777.866089,2408.447021 1777.448853,2408.364990 1776.404785,2408.163086 
	C1775.003174,2407.351074 1774.502808,2406.497314 1774.193848,2404.727539 
	C1774.163452,2402.429443 1774.541748,2400.958496 1775.241211,2398.772461 
	C1776.873291,2394.730469 1778.018433,2391.120605 1783.283203,2391.370117 
	C1783.970947,2391.714844 1783.920166,2391.988281 1784.057495,2392.020508 
	C1782.506226,2397.141602 1780.817627,2402.230469 1778.706299,2407.924316 
z"
        />
        <path
          fill="#2C2520"
          opacity="1.000000"
          stroke="none"
          d="
M1775.608398,2425.440186 
	C1776.157593,2426.871826 1776.220215,2427.645264 1776.313232,2428.992188 
	C1775.132812,2429.692383 1773.922241,2429.818848 1771.787109,2429.940918 
	C1768.820068,2427.616455 1766.777466,2425.296631 1764.586792,2422.388672 
	C1769.241455,2418.344727 1772.567139,2420.182129 1775.608398,2425.440186 
z"
        />
        <path
          fill="#5B768D"
          opacity="1.000000"
          stroke="none"
          d="
M1988.016479,2483.146973 
	C1977.674194,2486.406250 1967.466064,2483.532227 1956.587891,2482.539551 
	C1956.111694,2479.481689 1953.587769,2478.196777 1953.127197,2475.681885 
	C1955.696899,2472.813232 1959.317871,2473.577393 1962.144409,2474.277100 
	C1968.585205,2475.872070 1975.036499,2476.186768 1981.558838,2475.964600 
	C1986.113403,2475.809814 1988.292480,2477.720703 1988.016479,2483.146973 
z"
        />
        <path
          fill="#5B768D"
          opacity="1.000000"
          stroke="none"
          d="
M2026.084473,2491.207520 
	C2022.459351,2490.152100 2018.173706,2489.698242 2014.580933,2486.295654 
	C2014.342285,2484.281982 2015.025757,2482.497070 2016.876465,2482.369385 
	C2022.739136,2481.965088 2028.650391,2481.336426 2035.077759,2482.908203 
	C2033.565186,2487.894043 2025.474487,2484.132324 2026.084473,2491.207520 
z"
        />
        <path
          fill="#607B93"
          opacity="1.000000"
          stroke="none"
          d="
M1880.424561,2429.210938 
	C1870.620361,2429.844727 1861.303711,2429.859619 1851.068848,2429.874023 
	C1850.242432,2424.123047 1849.287842,2418.261230 1851.456177,2412.709229 
	C1853.061035,2408.599609 1848.646851,2405.530029 1849.878418,2400.771973 
	C1854.141113,2400.035156 1857.103394,2405.145752 1862.012207,2402.805908 
	C1863.227417,2402.226807 1864.768555,2403.638672 1865.102051,2404.822998 
	C1867.084595,2411.864502 1872.964844,2410.694092 1877.373657,2409.713379 
	C1890.255615,2406.847900 1903.219482,2407.892578 1916.112793,2408.101807 
	C1930.346313,2408.332520 1944.711792,2406.080322 1958.823730,2409.694336 
	C1974.822144,2405.858643 1991.317993,2410.029785 2007.378540,2406.658936 
	C2011.225708,2405.851562 2015.314941,2406.149414 2019.295654,2406.037109 
	C2024.928589,2405.878174 2030.546997,2406.240479 2036.101074,2404.486816 
	C2039.605713,2403.380371 2043.318970,2403.901367 2046.855835,2405.308350 
	C2053.176270,2407.822754 2059.465576,2408.678711 2066.292969,2406.527344 
	C2069.954834,2405.373535 2074.209473,2406.100586 2079.090332,2405.975098 
	C2088.752930,2403.305176 2097.999512,2405.285645 2107.478027,2402.823486 
	C2118.097412,2403.609375 2128.084473,2404.467041 2139.061035,2403.969482 
	C2142.623291,2406.856201 2146.137451,2405.920654 2149.349121,2405.937744 
	C2164.941406,2406.020752 2180.545898,2406.343750 2196.122314,2405.841553 
	C2204.975830,2405.556396 2213.797607,2403.953125 2222.606445,2402.745850 
	C2228.380615,2401.954834 2234.141113,2400.641602 2238.914551,2405.355225 
	C2237.562988,2408.462891 2235.373779,2407.913818 2233.523193,2407.956543 
	C2225.615723,2408.139404 2217.688477,2407.375000 2210.186768,2411.341309 
	C2207.799316,2412.603271 2204.334717,2411.951416 2201.362305,2411.962646 
	C2187.428467,2412.016602 2173.494385,2411.972656 2159.560547,2412.020508 
	C2156.309082,2412.031494 2152.581299,2411.439453 2152.020996,2417.142090 
	C2142.909912,2414.849121 2133.554932,2416.301758 2124.306885,2416.198242 
	C2123.019531,2416.183838 2121.278809,2416.281006 2120.506348,2417.069336 
	C2115.106445,2422.581055 2107.729248,2421.625488 2101.167969,2423.315918 
	C2097.872559,2424.165039 2094.583252,2424.215576 2091.308105,2423.437256 
	C2081.781982,2421.173096 2072.310791,2421.566895 2062.713623,2423.240234 
	C2057.855713,2424.087402 2052.793945,2423.764893 2046.917480,2423.975830 
	C2036.068848,2418.447754 2025.107910,2421.987549 2014.700928,2420.487061 
	C1992.361816,2417.266846 1969.812256,2417.623047 1947.330566,2416.672119 
	C1932.166992,2416.030518 1917.330688,2413.125977 1902.408813,2420.030029 
	C1895.112305,2423.406006 1886.035034,2422.082275 1877.846313,2420.116943 
	C1874.016235,2419.197510 1870.228149,2418.805908 1867.299194,2419.028809 
	C1872.597168,2420.124756 1881.515991,2417.017334 1880.424561,2429.210938 
z"
        />
        <path
          fill="#536F86"
          opacity="1.000000"
          stroke="none"
          d="
M1880.760498,2429.931152 
	C1880.305542,2424.303467 1877.618164,2421.823975 1872.195435,2422.015137 
	C1868.881958,2422.131592 1865.527832,2421.667969 1862.300903,2422.863525 
	C1859.419312,2423.931152 1857.133667,2422.956787 1854.785522,2420.213135 
	C1860.514038,2414.758789 1867.590942,2415.126465 1874.035156,2416.633057 
	C1882.683472,2418.654297 1891.267700,2417.531250 1899.865112,2417.979248 
	C1901.504272,2418.064697 1903.750366,2418.290283 1904.507568,2416.802734 
	C1907.689331,2410.551514 1913.353271,2412.069336 1918.465088,2412.024170 
	C1942.728149,2411.810547 1966.884766,2415.481689 1991.186523,2414.048828 
	C1993.871826,2413.890625 1996.519897,2414.154053 1998.791748,2415.875732 
	C2000.422241,2417.111816 2002.385254,2418.376465 2004.289185,2417.823486 
	C2013.139160,2415.253174 2022.091675,2419.605225 2030.817505,2417.316162 
	C2035.353516,2416.126221 2039.288452,2418.266846 2043.531250,2418.543945 
	C2045.401855,2418.666260 2045.752686,2420.737061 2045.971436,2423.209961 
	C2042.395874,2426.157715 2038.387207,2426.020508 2034.381714,2426.012695 
	C2021.400024,2425.986816 2008.417847,2426.075928 1995.436768,2425.982666 
	C1975.489746,2425.839355 1955.840820,2428.513916 1936.162964,2431.450195 
	C1924.794678,2433.146484 1913.216553,2433.768555 1900.834351,2432.004883 
	C1893.743286,2432.256104 1887.613037,2431.611084 1880.760498,2429.931152 
z"
        />
        <path
          fill="#607B93"
          opacity="1.000000"
          stroke="none"
          d="
M2172.860596,2420.021729 
	C2172.849365,2415.214111 2176.457275,2415.787598 2179.696045,2416.379150 
	C2197.477783,2419.627197 2215.105713,2418.491699 2232.655518,2414.763428 
	C2237.579590,2413.717529 2242.483887,2413.516113 2247.354980,2414.814697 
	C2254.314941,2416.670410 2254.444580,2417.418457 2248.580566,2423.709961 
	C2247.980469,2424.028809 2247.000000,2424.002441 2246.509766,2423.990967 
	C2243.897461,2421.802002 2241.527100,2421.564209 2238.449219,2423.511719 
	C2218.459473,2424.013184 2199.058594,2422.469971 2179.702637,2420.187744 
	C2177.736328,2419.956055 2175.729004,2420.071045 2172.860596,2420.021729 
z"
        />
        <path
          fill="#536F86"
          opacity="1.000000"
          stroke="none"
          d="
M2238.770996,2423.974121 
	C2240.711182,2419.073242 2241.421875,2418.952148 2245.873535,2423.304199 
	C2243.871826,2423.985352 2241.724121,2423.991211 2238.770996,2423.974121 
z"
        />
        <path
          fill="#536F86"
          opacity="1.000000"
          stroke="none"
          d="
M2107.219727,2402.309082 
	C2107.646240,2403.297363 2107.207520,2404.882080 2106.678955,2404.912598 
	C2098.259033,2405.399414 2090.111816,2410.161865 2080.710205,2406.299561 
	C2079.994873,2404.498291 2080.005371,2403.025146 2080.030762,2400.817627 
	C2088.860596,2400.041504 2097.435791,2403.000244 2107.219727,2402.309082 
z"
        />
        <path
          fill="#040407"
          opacity="1.000000"
          stroke="none"
          d="
M1783.980591,2391.052490 
	C1780.876099,2392.993896 1779.877563,2396.074219 1777.886353,2399.124023 
	C1771.057617,2402.292725 1763.979614,2400.596191 1756.465820,2401.866943 
	C1750.059692,2397.926514 1751.018555,2393.095215 1754.612549,2386.905273 
	C1765.932251,2386.224365 1775.142456,2381.673584 1784.993774,2380.930176 
	C1786.878296,2380.788086 1788.721680,2379.926514 1791.191528,2381.018555 
	C1789.307983,2384.745361 1785.342163,2386.653564 1783.980591,2391.052490 
z"
        />
        <path
          fill="#5A605E"
          opacity="1.000000"
          stroke="none"
          d="
M1747.309814,2386.952148 
	C1748.347900,2383.309570 1751.578003,2384.361084 1755.232788,2383.990723 
	C1756.000000,2383.990234 1755.955566,2384.885498 1755.924072,2385.332031 
	C1755.892578,2385.778564 1756.002808,2385.932617 1756.037354,2386.020996 
	C1753.884155,2387.987549 1751.417847,2388.689209 1747.309814,2386.952148 
z"
        />
        <path
          fill="#395166"
          opacity="1.000000"
          stroke="none"
          d="
M702.050293,2592.996094 
	C702.642090,2598.427979 706.265198,2598.052490 709.558167,2597.953857 
	C717.463135,2597.717285 725.160156,2598.605225 733.903503,2601.639404 
	C729.472534,2605.032227 724.893860,2605.144043 721.182617,2605.437988 
	C711.287537,2606.222412 700.996094,2607.275635 691.418945,2605.391846 
	C680.755066,2603.293701 670.369995,2605.634277 659.930176,2604.427490 
	C658.589783,2604.272461 657.160461,2604.637451 657.473938,2606.547852 
	C658.468811,2612.610596 654.148132,2611.639648 650.569885,2611.969971 
	C643.179565,2612.652344 637.758850,2606.221436 630.877747,2606.290771 
	C624.381592,2606.356201 617.945740,2609.440918 611.338013,2606.699219 
	C605.066467,2604.097656 598.497498,2603.585938 590.922119,2603.950195 
	C587.495422,2597.086670 582.742859,2593.952393 575.307312,2594.836670 
	C573.092896,2595.100098 570.815796,2594.424316 568.436035,2592.535156 
	C567.996155,2591.097900 567.999695,2590.204346 568.005432,2588.639648 
	C579.735474,2580.822021 592.694092,2580.434814 605.879578,2580.822266 
	C614.437195,2581.073975 622.725098,2578.930420 631.532043,2576.409424 
	C641.419006,2575.860840 650.771912,2575.887207 661.056274,2575.916016 
	C662.881470,2575.952637 663.775635,2575.965820 665.340576,2575.941650 
	C671.988281,2575.932129 677.965515,2575.958252 683.942627,2575.946533 
	C686.932495,2575.940430 689.866638,2575.741455 692.770996,2574.757324 
	C699.496887,2572.477783 702.190796,2572.676514 708.951355,2575.066650 
	C710.132935,2575.484619 711.576477,2575.549805 712.822815,2575.334961 
	C725.028931,2573.229492 737.153259,2573.427246 749.365112,2575.625732 
	C753.853149,2576.433838 758.558899,2576.099609 763.218994,2575.277100 
	C773.321533,2573.493652 783.511536,2571.847656 792.852600,2578.318359 
	C794.820129,2579.681152 797.201050,2580.227783 799.483704,2579.054443 
	C809.321045,2573.997559 820.278748,2575.884033 830.648743,2574.362305 
	C840.359436,2572.937256 850.450012,2573.779541 861.117432,2571.894531 
	C862.129761,2576.101318 862.541443,2579.211670 868.193604,2576.052979 
	C872.121643,2573.857178 875.884827,2571.723633 879.436157,2568.027344 
	C892.132263,2565.677734 903.218567,2569.959229 915.163574,2573.229736 
	C928.743225,2572.917480 941.402344,2576.915039 954.349609,2576.014160 
	C957.309021,2575.808105 960.482849,2576.583740 963.172180,2575.149414 
	C968.342712,2572.392334 973.796204,2571.751953 979.507874,2571.893555 
	C982.915344,2571.977783 985.770996,2570.200439 989.036194,2568.847900 
	C987.263611,2564.528320 983.791077,2566.527832 981.227539,2566.003418 
	C977.565796,2565.254150 973.155334,2567.687500 970.124390,2562.633301 
	C970.293884,2561.460693 970.671814,2561.058350 971.774658,2560.259277 
	C983.028931,2558.080078 994.166626,2560.642822 1004.936157,2555.012695 
	C1010.068665,2552.838379 1014.443359,2553.179688 1019.621033,2554.989258 
	C1021.566650,2555.307129 1022.653137,2555.593506 1024.475586,2556.355469 
	C1033.303101,2560.937744 1042.478760,2558.430420 1050.866333,2560.948486 
	C1054.268311,2561.969971 1058.153320,2561.680420 1058.468994,2567.154297 
	C1045.011353,2572.092529 1031.273193,2571.863525 1017.865601,2573.661621 
	C1010.283691,2574.678467 1003.614685,2579.127686 995.620605,2578.346191 
	C992.153625,2578.007080 991.856140,2581.516357 992.191589,2584.281982 
	C992.621704,2587.827881 995.010498,2587.344971 997.363892,2586.427979 
	C1011.228333,2581.024658 1026.019531,2581.876709 1040.386841,2579.822998 
	C1055.837891,2577.614014 1071.210938,2575.367432 1086.873169,2575.858643 
	C1088.786377,2575.918701 1090.961304,2575.461670 1092.615723,2574.533203 
	C1098.533325,2571.212891 1105.114868,2571.135986 1111.119629,2572.366211 
	C1121.803223,2574.554443 1132.222046,2574.283203 1142.642700,2571.902100 
	C1154.020142,2569.302246 1165.771729,2570.492920 1177.147583,2568.069092 
	C1179.347290,2567.600586 1182.072510,2568.441162 1183.367188,2565.909180 
	C1187.775513,2557.288818 1196.009644,2558.240234 1203.171631,2558.968994 
	C1214.302612,2560.102295 1225.120361,2563.677734 1236.573120,2560.298584 
	C1240.209351,2559.225830 1244.841553,2559.016602 1250.513550,2562.288330 
	C1239.816162,2564.991455 1230.981934,2566.413330 1221.801392,2566.118408 
	C1217.074097,2565.966797 1211.971924,2564.693115 1208.684082,2570.500977 
	C1207.162476,2573.188721 1203.134155,2571.944580 1200.213013,2571.963379 
	C1188.543213,2572.038086 1176.592773,2573.050537 1165.971191,2576.962646 
	C1156.266113,2580.537598 1147.125244,2580.134521 1137.732910,2579.278320 
	C1133.160889,2578.861816 1128.558594,2578.286865 1123.936157,2577.938232 
	C1112.118408,2577.045898 1101.060913,2581.473877 1089.541260,2582.720703 
	C1085.281494,2583.181885 1081.038696,2583.947998 1076.724976,2583.960693 
	C1073.048950,2583.971436 1069.474121,2583.992188 1066.279175,2586.399658 
	C1063.109619,2588.787598 1059.228149,2587.823730 1055.624756,2587.990967 
	C1052.716919,2588.126221 1050.266724,2588.627930 1050.184570,2593.054199 
	C1047.442749,2597.980469 1039.544922,2592.251953 1038.094727,2600.471436 
	C1026.853271,2608.005127 1015.599487,2603.483643 1004.732910,2602.130127 
	C977.766846,2598.770996 950.759155,2597.972168 923.665222,2599.162842 
	C912.032532,2599.674072 900.473755,2600.046143 888.567627,2595.565918 
	C877.302124,2591.355957 866.941284,2589.101318 856.985962,2598.475098 
	C855.894470,2599.097412 855.425476,2599.288086 854.210449,2599.622070 
	C847.703491,2599.561523 843.359009,2597.413330 838.625427,2593.500244 
	C832.943115,2588.803223 824.500061,2588.468018 817.036682,2589.164551 
	C807.521912,2590.052734 798.104919,2591.755615 788.484253,2590.730713 
	C786.392151,2590.508057 784.768982,2591.900879 782.634460,2593.632324 
	C775.685059,2594.851074 769.729431,2592.475342 762.726746,2591.858887 
	C762.112915,2588.481201 763.048706,2586.109863 757.387390,2585.551025 
	C740.883850,2583.920898 725.116882,2589.206543 708.883728,2589.377441 
	C707.568848,2589.391113 706.259094,2589.647949 704.263428,2589.038574 
	C701.208313,2584.111084 697.237183,2583.749023 692.616760,2584.169189 
	C681.344421,2585.194336 669.969849,2586.169678 659.468872,2590.474121 
	C644.220642,2596.725098 628.063538,2594.143066 611.464111,2596.298828 
	C616.087341,2598.190186 620.229492,2599.076416 623.845337,2598.020020 
	C639.707825,2593.385498 656.253906,2595.781006 672.250916,2592.825195 
	C675.249268,2592.271240 677.971497,2591.763916 680.241272,2589.394043 
	C685.642212,2583.755615 692.423279,2583.533203 700.122925,2585.550781 
	C702.005249,2587.924561 702.453796,2589.987305 702.050293,2592.996094 
z"
        />
        <path
          fill="#33495B"
          opacity="1.000000"
          stroke="none"
          d="
M1409.109741,2586.164551 
	C1416.529297,2588.307129 1419.744751,2592.994873 1420.300415,2600.080811 
	C1420.571655,2603.540771 1421.276978,2607.290039 1425.319946,2609.756104 
	C1430.801025,2609.758789 1430.138306,2614.354004 1432.362671,2617.509033 
	C1435.831421,2625.758789 1439.284302,2633.215088 1434.517822,2642.079346 
	C1414.643677,2644.444336 1395.320190,2643.963379 1376.111328,2643.040527 
	C1363.390747,2642.429443 1350.835205,2642.999268 1338.311401,2644.185791 
	C1326.460449,2645.308594 1315.078857,2645.546387 1304.698486,2638.767090 
	C1298.179199,2634.510010 1290.928467,2634.489990 1283.626953,2634.830322 
	C1268.677979,2635.527832 1253.786621,2636.135254 1238.898682,2633.385498 
	C1234.103149,2632.500000 1228.982788,2633.793457 1224.165405,2635.275146 
	C1220.952271,2636.263428 1217.650635,2636.857178 1214.330322,2635.937744 
	C1210.356201,2634.837158 1206.511475,2633.315186 1206.281982,2628.361572 
	C1206.045898,2623.261963 1209.823242,2621.644043 1213.842773,2620.538330 
	C1218.697632,2619.202393 1223.697754,2618.850586 1228.696655,2619.046387 
	C1233.521973,2619.235352 1237.947754,2618.324951 1242.631592,2614.987793 
	C1253.021484,2610.845947 1262.587036,2613.500000 1272.957275,2615.049805 
	C1278.468628,2616.084961 1283.040527,2618.925781 1288.745361,2616.047363 
	C1303.033081,2615.191650 1316.624268,2615.180664 1330.207397,2615.481201 
	C1336.676880,2615.624512 1342.662354,2618.592773 1349.100220,2612.495361 
	C1353.247559,2608.567383 1360.953857,2610.611328 1365.348145,2615.541260 
	C1368.077026,2618.602539 1371.088379,2619.261230 1374.202148,2618.368896 
	C1385.151245,2615.229736 1395.943726,2616.859863 1406.841431,2618.397949 
	C1409.957886,2618.837646 1413.417114,2619.639893 1416.585205,2617.271729 
	C1414.940430,2612.227295 1410.982666,2612.332520 1406.946533,2612.663086 
	C1389.584595,2614.084473 1372.352905,2613.398438 1355.219727,2610.000977 
	C1348.954956,2608.758301 1342.624146,2608.967529 1336.518799,2611.382812 
	C1332.095581,2613.132812 1327.404785,2613.484375 1322.737549,2613.211670 
	C1303.882324,2612.109619 1284.911743,2612.567871 1266.222900,2609.169678 
	C1262.726685,2608.533691 1257.549438,2608.843994 1257.605469,2604.262939 
	C1257.678833,2598.233398 1263.464111,2600.743896 1266.423096,2600.006836 
	C1262.658691,2599.974609 1258.635376,2600.686279 1254.395996,2602.133301 
	C1232.789307,2609.508301 1210.320435,2606.680908 1188.120239,2606.803955 
	C1185.462891,2606.818604 1182.859497,2605.817871 1181.286133,2602.375000 
	C1183.336304,2596.252686 1188.455078,2596.353027 1192.832031,2595.532959 
	C1200.674072,2594.063477 1208.666992,2594.478271 1216.594116,2594.642822 
	C1222.812988,2594.771484 1228.388428,2593.625488 1233.507935,2588.471680 
	C1234.918701,2587.843750 1235.718750,2587.798340 1237.119629,2587.776367 
	C1241.630127,2592.057617 1245.055542,2587.899902 1249.214111,2586.765625 
	C1257.352783,2585.940430 1264.829956,2586.083496 1272.447754,2587.458008 
	C1279.523315,2588.734375 1286.937256,2588.638672 1294.154907,2586.990479 
	C1296.390503,2586.480469 1298.907593,2585.668457 1300.976562,2586.211426 
	C1314.589600,2589.783203 1328.770630,2586.080078 1342.344360,2589.655518 
	C1346.449341,2590.736816 1350.136230,2588.074463 1354.026367,2588.191406 
	C1367.538086,2588.598389 1381.125977,2586.487061 1394.571655,2589.644775 
	C1399.529785,2590.808838 1404.448242,2590.198975 1409.109741,2586.164551 
z"
        />
        <path
          fill="#5D758A"
          opacity="1.000000"
          stroke="none"
          d="
M972.454346,2561.433105 
	C971.880310,2561.878418 970.899109,2561.871338 970.407593,2561.867188 
	C968.685059,2561.356201 967.460510,2560.833252 966.221863,2560.346436 
	C964.012451,2559.478271 960.005737,2560.604004 960.648315,2556.434814 
	C961.113770,2553.415039 964.013672,2552.639160 966.862976,2552.742432 
	C968.114380,2552.787598 969.378235,2553.771729 971.121948,2552.071777 
	C967.979431,2547.743408 962.552612,2545.506836 958.724915,2547.284180 
	C951.374084,2550.697998 943.392639,2551.505127 936.106201,2554.642822 
	C935.716736,2554.810547 935.645081,2555.715820 935.208618,2556.832764 
	C939.177856,2559.021729 943.549927,2557.567627 947.629272,2558.072021 
	C949.386719,2558.289062 951.076599,2558.740723 951.347717,2560.679688 
	C951.622131,2562.642578 950.137207,2563.620361 948.427063,2564.073730 
	C945.870544,2564.751709 943.315002,2565.642822 940.708618,2565.873291 
	C937.073975,2566.195312 933.632568,2566.199219 930.246521,2568.343750 
	C926.827209,2570.509277 926.522339,2570.027832 921.246948,2566.043945 
	C921.597229,2565.536377 921.837524,2564.844482 922.319763,2564.554932 
	C924.621643,2563.171875 929.564575,2565.249512 929.208801,2560.809570 
	C928.881470,2556.725586 924.532898,2558.122314 921.791016,2558.027832 
	C917.826843,2557.891846 913.859558,2557.764893 909.917419,2558.998047 
	C904.661926,2560.641846 899.504578,2561.255615 895.528198,2555.885742 
	C893.455811,2553.087402 890.020264,2554.175537 887.117676,2553.901123 
	C885.096252,2553.709961 882.880615,2553.625977 882.492126,2551.113525 
	C882.143982,2548.861572 883.649780,2547.467773 885.563293,2546.491455 
	C887.215332,2545.649170 889.698853,2546.628174 890.902588,2543.524170 
	C888.341125,2541.310791 885.725464,2542.181396 883.078918,2543.508301 
	C879.970642,2545.066650 877.339661,2546.554199 875.143982,2541.736816 
	C873.957336,2539.133057 870.233887,2539.182373 867.457642,2538.691650 
	C866.294434,2543.326660 874.434937,2540.919434 871.825806,2546.228027 
	C869.543823,2550.871338 865.734924,2554.391113 861.100525,2553.377197 
	C853.740967,2551.766846 845.654724,2553.368164 838.953186,2548.524414 
	C835.865662,2546.293213 832.423035,2546.680908 828.546448,2547.223877 
	C818.734985,2548.597656 808.699036,2547.990234 798.888550,2549.912842 
	C797.657349,2550.154053 796.250366,2549.889160 795.052429,2547.986084 
	C797.594666,2544.062012 803.638489,2545.457520 806.235718,2541.468750 
	C808.309875,2538.283203 812.232361,2536.664795 812.327209,2531.707764 
	C812.397949,2528.012695 816.163330,2527.241943 818.624939,2530.197998 
	C820.726990,2532.722412 822.497437,2535.630859 823.874573,2538.618408 
	C825.070618,2541.212891 826.831848,2541.906738 829.370850,2541.881104 
	C832.146606,2541.852783 833.187988,2540.190918 834.370483,2537.902344 
	C835.986572,2534.774414 834.654907,2529.873291 838.827332,2528.493896 
	C842.931702,2527.136963 847.418091,2528.342773 851.716675,2528.202148 
	C868.223083,2527.662354 884.764343,2529.009521 901.250732,2526.710449 
	C912.063477,2525.202881 922.969788,2524.378418 933.704163,2522.244629 
	C935.471313,2521.893066 936.920471,2522.775391 937.828247,2525.259277 
	C936.974609,2527.901611 935.237305,2528.777344 932.751099,2529.372559 
	C943.374329,2528.619141 953.392456,2524.336670 963.361694,2526.056152 
	C973.317566,2527.773438 983.200745,2530.569580 993.491089,2533.590332 
	C994.332153,2537.011719 993.185669,2540.142822 995.692383,2543.209229 
	C1001.330383,2541.484619 1006.994263,2541.782227 1012.460510,2545.025391 
	C1015.382080,2546.758301 1018.940247,2546.031494 1022.233704,2545.896484 
	C1033.661499,2545.428467 1045.017578,2543.692383 1056.493042,2543.998047 
	C1060.438965,2544.103271 1064.360718,2543.579346 1067.183350,2547.569336 
	C1069.386353,2550.683594 1073.147217,2550.110596 1076.392822,2549.926270 
	C1087.675781,2549.285889 1098.643799,2553.124268 1109.956665,2552.124268 
	C1112.663330,2551.885010 1115.048584,2553.366455 1117.673340,2555.638672 
	C1114.937622,2560.783691 1110.226196,2559.867432 1105.738403,2559.595459 
	C1101.806030,2559.357422 1097.989868,2560.103760 1093.280029,2560.804199 
	C1078.589966,2561.880615 1064.781128,2561.314697 1050.174561,2560.249756 
	C1042.072510,2550.212158 1031.880981,2553.671143 1021.585083,2554.053711 
	C1017.282898,2553.438477 1013.750427,2552.877441 1009.443726,2552.484375 
	C999.689331,2549.313477 991.897278,2550.979736 984.993713,2558.785156 
	C980.771484,2560.859863 976.599854,2559.026367 972.454346,2561.433105 
z"
        />
        <path
          fill="#546D83"
          opacity="1.000000"
          stroke="none"
          d="
M1191.507324,2490.554199 
	C1205.582886,2489.683594 1219.042847,2487.116455 1232.523315,2486.690430 
	C1244.988647,2486.296875 1257.215332,2483.384277 1270.097900,2485.405273 
	C1281.654297,2487.218506 1293.871826,2487.263916 1305.811035,2484.614990 
	C1311.206787,2483.417725 1317.260620,2483.356201 1322.674072,2484.458496 
	C1333.283447,2486.618652 1343.874023,2485.742188 1355.443848,2485.747803 
	C1358.771606,2485.840332 1360.832275,2486.299072 1361.980591,2488.926514 
	C1365.161255,2496.204834 1368.575562,2503.381348 1372.002930,2511.325684 
	C1369.728638,2514.872803 1366.358521,2515.589600 1363.070435,2515.385742 
	C1348.117310,2514.458008 1333.348389,2518.243408 1317.458008,2516.984375 
	C1310.849243,2517.577637 1305.167480,2518.321045 1299.061523,2514.950684 
	C1294.122803,2514.237061 1289.913330,2514.128174 1284.802002,2514.031250 
	C1281.850342,2515.476074 1279.532837,2514.856445 1276.539917,2514.619141 
	C1273.976929,2507.347412 1279.355347,2507.629395 1283.423950,2507.120361 
	C1290.703491,2506.209473 1298.036743,2507.064697 1305.346680,2506.789551 
	C1280.415649,2507.156250 1255.487305,2508.653076 1229.767090,2507.081299 
	C1227.379761,2506.878906 1225.771362,2506.873535 1223.373047,2506.538574 
	C1220.494995,2505.886475 1218.526367,2504.900391 1215.503906,2504.979004 
	C1204.876343,2505.040039 1195.108398,2505.827881 1184.679077,2506.446777 
	C1179.643311,2501.251221 1174.320923,2499.635498 1167.883301,2499.877686 
	C1161.802002,2500.106689 1155.533569,2500.838867 1149.438232,2499.055664 
	C1150.096558,2495.504883 1152.426758,2496.114990 1154.199463,2496.065186 
	C1158.861328,2495.933350 1163.551514,2495.707520 1168.189331,2496.059326 
	C1176.350342,2496.678467 1184.024292,2495.576416 1191.507324,2490.554199 
z"
        />
        <path
          fill="#395166"
          opacity="1.000000"
          stroke="none"
          d="
M1298.101562,2515.037598 
	C1303.659668,2514.271484 1308.986938,2514.196777 1315.213501,2514.177734 
	C1320.682739,2517.557861 1326.890015,2514.857178 1331.943604,2518.855957 
	C1332.986816,2523.567871 1333.016113,2527.108398 1339.439819,2523.879883 
	C1344.028809,2521.573730 1350.480591,2523.905518 1355.369385,2518.969238 
	C1357.373901,2518.162109 1358.779541,2518.078125 1360.875610,2518.562256 
	C1364.896606,2521.216553 1366.946899,2528.640869 1373.357666,2521.396973 
	C1374.432129,2521.039795 1374.892334,2521.000732 1375.988403,2521.237061 
	C1381.761353,2526.231934 1385.258423,2532.071289 1388.258301,2539.026367 
	C1370.804565,2539.261230 1353.333252,2543.763916 1335.792114,2541.788086 
	C1323.215332,2540.371582 1310.690552,2539.939697 1298.097778,2539.979248 
	C1291.807617,2539.999023 1285.504150,2539.575928 1279.326416,2541.605469 
	C1277.210571,2542.300537 1274.713989,2541.891357 1272.391479,2541.884766 
	C1270.623291,2541.879883 1268.999023,2541.273193 1268.601929,2539.376953 
	C1268.311523,2537.989746 1269.163086,2536.932617 1270.463257,2536.218994 
	C1271.524414,2535.637451 1273.104370,2535.307373 1272.332031,2532.767578 
	C1266.860962,2531.870117 1261.166138,2530.431885 1255.800293,2534.257080 
	C1252.386230,2536.690674 1248.966309,2536.246094 1244.742920,2534.380615 
	C1242.119263,2532.014648 1239.571777,2532.063232 1237.053345,2531.950684 
	C1234.079346,2531.817383 1231.294312,2531.371094 1231.547852,2526.802246 
	C1242.248291,2526.078857 1252.529053,2525.993408 1262.809326,2526.027100 
	C1265.440186,2526.035645 1268.408081,2525.700195 1270.643677,2526.751221 
	C1283.075073,2532.595947 1296.089844,2529.213379 1308.852905,2529.604248 
	C1309.351685,2529.619385 1309.871948,2528.936768 1311.157471,2528.036377 
	C1302.892700,2522.947021 1292.476685,2529.577148 1284.601807,2522.411621 
	C1288.202637,2517.871826 1296.092529,2522.411133 1298.101562,2515.037598 
z"
        />
        <path
          fill="#3C556B"
          opacity="1.000000"
          stroke="none"
          d="
M1275.526855,2513.424316 
	C1278.149902,2513.921143 1280.243530,2513.970215 1283.118652,2514.031250 
	C1275.658936,2515.996094 1267.467163,2519.293701 1259.165405,2519.599365 
	C1245.330444,2520.108398 1231.385620,2521.206543 1217.508301,2518.516846 
	C1211.730347,2517.396729 1205.352783,2519.118896 1199.681030,2517.751953 
	C1186.061157,2514.469482 1172.713135,2518.820801 1159.242188,2518.040771 
	C1151.901489,2517.615479 1145.838379,2522.636963 1138.247925,2522.807617 
	C1132.449219,2522.937744 1126.459717,2523.877441 1120.515137,2524.016846 
	C1115.268555,2524.139648 1109.938721,2523.316162 1105.243774,2526.958008 
	C1103.069214,2528.644775 1100.014771,2528.376465 1097.537598,2527.441162 
	C1092.362793,2525.486816 1087.042847,2526.256836 1080.892090,2526.006836 
	C1080.737061,2521.344727 1084.400757,2520.299561 1088.104736,2520.151855 
	C1098.935547,2519.718750 1109.386719,2515.834229 1120.561646,2517.718018 
	C1127.325439,2518.858398 1134.198486,2515.002686 1141.277466,2515.888916 
	C1141.909668,2515.967773 1142.883301,2515.944580 1143.196777,2515.560059 
	C1151.444702,2505.442383 1162.955444,2512.820801 1172.572754,2510.069824 
	C1176.184692,2509.036621 1180.655029,2511.635742 1183.792114,2506.749023 
	C1193.465576,2500.525391 1203.846069,2502.141846 1214.927734,2502.119141 
	C1217.192627,2503.434082 1218.828613,2503.992432 1221.344238,2504.014160 
	C1223.497070,2504.035400 1224.984375,2504.036621 1227.215332,2504.033691 
	C1231.625610,2510.343262 1227.273193,2511.304932 1224.598877,2511.766846 
	C1237.695801,2504.381348 1253.467529,2512.642334 1268.807373,2510.155762 
	C1271.055786,2509.791260 1273.275757,2511.265869 1275.526855,2513.424316 
z"
        />
        <path
          fill="#3E5871"
          opacity="1.000000"
          stroke="none"
          d="
M994.594788,2534.031982 
	C984.802002,2533.670898 975.882568,2534.306641 966.996887,2529.823975 
	C959.238647,2525.910156 950.313721,2530.341797 941.944092,2531.181396 
	C935.085571,2531.869385 928.377441,2532.521973 919.706848,2530.848389 
	C925.864014,2526.988037 931.533325,2527.829834 937.174683,2526.015625 
	C942.724487,2525.226562 948.371521,2526.138184 949.988647,2518.783691 
	C952.755981,2512.405273 958.135742,2513.310303 962.882751,2513.060059 
	C971.739807,2512.592773 980.635254,2513.587646 990.301636,2512.356445 
	C993.768555,2511.932617 996.429810,2512.852783 999.860413,2512.106934 
	C1004.269653,2512.225342 1007.945679,2512.212646 1011.427490,2515.449707 
	C1018.289246,2520.162598 1024.838257,2523.407715 1032.782837,2522.072998 
	C1034.004639,2521.867920 1041.201904,2529.598145 1040.682007,2530.640381 
	C1040.268921,2531.468506 1038.473999,2531.606934 1036.620972,2532.104492 
	C1032.950195,2536.920654 1028.078125,2536.141602 1023.687622,2535.902588 
	C1017.624207,2535.571777 1011.453430,2537.407227 1004.750244,2534.491211 
	C1001.100159,2530.416504 998.195129,2533.459229 994.594788,2534.031982 
z"
        />
        <path
          fill="#33495B"
          opacity="1.000000"
          stroke="none"
          d="
M1038.642822,2598.720703 
	C1037.828369,2597.065430 1037.611328,2596.103271 1037.488525,2595.129150 
	C1036.887451,2590.360840 1037.821167,2589.326416 1041.504639,2591.094727 
	C1043.841553,2592.216797 1045.783447,2594.189941 1049.421875,2593.883301 
	C1059.217285,2594.864502 1067.977539,2592.647461 1076.743896,2591.216553 
	C1081.028687,2590.517334 1085.244141,2590.130371 1089.539062,2590.018555 
	C1092.493530,2589.941650 1095.495850,2590.286377 1098.208862,2588.530029 
	C1107.018555,2582.826172 1115.528564,2584.260010 1124.059326,2589.431396 
	C1126.251587,2590.760498 1129.007202,2591.414062 1131.590454,2591.801758 
	C1145.681152,2593.916748 1159.863892,2592.825195 1173.884644,2591.581299 
	C1180.165405,2591.024170 1186.111694,2586.885986 1192.949585,2588.050049 
	C1194.243652,2588.270264 1195.529297,2588.540527 1197.440063,2589.294678 
	C1198.048706,2595.105225 1193.630005,2594.395996 1190.560913,2594.914551 
	C1185.408447,2595.784668 1180.466797,2597.428467 1175.370850,2598.471924 
	C1171.405518,2599.283691 1174.906982,2601.191895 1174.941528,2603.371094 
	C1171.184814,2611.571289 1164.248169,2608.363037 1158.493896,2608.858887 
	C1154.907837,2609.167969 1151.425903,2607.919678 1147.315674,2606.232666 
	C1139.944702,2598.837646 1130.384399,2602.371826 1121.884399,2599.143799 
	C1110.658447,2599.556396 1100.339233,2601.182861 1089.089355,2602.006348 
	C1084.178711,2602.177490 1080.191650,2601.377441 1075.265381,2601.104980 
	C1062.635376,2601.336182 1050.806641,2603.438721 1038.642822,2598.720703 
z"
        />
        <path
          fill="#33495B"
          opacity="1.000000"
          stroke="none"
          d="
M1405.134399,2574.334229 
	C1408.008179,2574.987061 1409.591309,2576.435059 1409.390625,2579.725098 
	C1398.494751,2578.825439 1388.176636,2582.735596 1377.462036,2582.159912 
	C1363.863403,2581.428711 1350.193359,2582.002686 1336.554321,2582.055176 
	C1328.936157,2582.084473 1321.407227,2582.218750 1314.474365,2577.975830 
	C1310.719849,2575.678223 1306.175781,2576.027588 1301.828979,2575.906738 
	C1288.254883,2575.530762 1274.628052,2577.232178 1261.107422,2574.165771 
	C1255.133789,2572.811035 1249.360107,2575.713379 1243.594116,2577.146484 
	C1232.290649,2579.956055 1232.307495,2580.024170 1218.564697,2576.510010 
	C1223.312500,2572.770996 1228.527710,2572.697510 1233.344727,2572.241699 
	C1241.237915,2571.494873 1249.175903,2572.377930 1257.126343,2570.544922 
	C1261.025879,2569.645508 1265.556885,2571.618896 1269.820435,2572.142578 
	C1294.288086,2575.148438 1318.719482,2574.656738 1343.252441,2572.371582 
	C1353.319580,2571.433838 1363.945068,2569.757568 1373.633057,2574.852295 
	C1377.961914,2577.128662 1381.889771,2574.120605 1386.267090,2574.725342 
	C1392.151611,2575.538086 1398.435425,2576.143311 1405.134399,2574.334229 
z"
        />
        <path
          fill="#3C5163"
          opacity="1.000000"
          stroke="none"
          d="
M1397.953613,2558.143555 
	C1400.336792,2559.657471 1401.057739,2562.246094 1401.878418,2565.491211 
	C1394.688477,2569.840332 1386.586792,2569.024658 1379.568359,2567.468018 
	C1362.125122,2563.599609 1344.627197,2564.050049 1327.062378,2563.979736 
	C1326.064697,2563.975830 1325.061279,2564.042480 1324.070435,2563.959229 
	C1320.891602,2563.692383 1315.825562,2564.936035 1315.545776,2561.039551 
	C1315.170898,2555.820068 1320.508057,2558.868164 1323.236206,2557.995850 
	C1334.144409,2554.507812 1344.655396,2557.725586 1355.522339,2558.982666 
	C1368.378174,2560.469971 1381.628906,2558.543701 1395.490601,2557.940918 
	C1396.272827,2557.785156 1396.181763,2557.913818 1396.110840,2557.879150 
	C1396.386841,2558.504150 1396.910278,2558.759033 1397.953613,2558.143555 
z"
        />
        <path
          fill="#1F3041"
          opacity="1.000000"
          stroke="none"
          d="
M1174.868530,2602.688965 
	C1170.991211,2601.352051 1167.735962,2600.733887 1163.758789,2597.428711 
	C1175.603394,2595.127197 1186.285645,2593.051514 1197.514648,2590.388672 
	C1209.526733,2589.641357 1221.052979,2590.597412 1233.250244,2587.910400 
	C1230.851807,2599.379395 1231.470215,2597.880615 1221.009521,2598.006348 
	C1208.298584,2598.159424 1195.483032,2596.928955 1182.558594,2601.601807 
	C1180.139648,2604.152588 1177.927734,2604.114258 1174.868530,2602.688965 
z"
        />
        <path
          fill="#33495B"
          opacity="1.000000"
          stroke="none"
          d="
M1231.141113,2526.062012 
	C1232.480957,2529.590332 1234.991943,2529.983643 1237.829224,2530.014893 
	C1240.120728,2530.040283 1242.579102,2529.849609 1243.863525,2533.340332 
	C1225.798706,2533.161133 1207.742065,2537.389404 1189.463379,2536.008545 
	C1184.504883,2535.634033 1179.529663,2535.879639 1175.358276,2532.689697 
	C1175.395752,2531.499512 1175.773315,2530.446777 1176.838013,2530.347900 
	C1187.244385,2529.382568 1197.780884,2530.716797 1207.946533,2526.242676 
	C1214.952637,2523.158936 1222.825439,2524.178955 1231.141113,2526.062012 
z"
        />
        <path
          fill="#243B51"
          opacity="1.000000"
          stroke="none"
          d="
M1047.423950,2557.038086 
	C1062.778564,2557.700195 1077.405396,2557.931885 1092.961548,2558.202637 
	C1095.933350,2560.103027 1096.067139,2562.338135 1094.603027,2565.463867 
	C1088.624756,2566.098145 1083.213745,2566.043701 1076.901367,2565.994629 
	C1072.290161,2566.349121 1068.430420,2565.127686 1064.384033,2567.459961 
	C1062.440186,2567.881104 1060.983887,2567.903320 1058.801270,2567.912598 
	C1057.577881,2565.130127 1055.767822,2564.093506 1053.122192,2564.078857 
	C1045.183594,2564.035156 1037.527710,2560.959473 1029.475952,2561.918945 
	C1027.493774,2562.155273 1025.463013,2561.818115 1024.261353,2558.843994 
	C1031.283325,2552.578857 1039.028564,2555.190430 1047.423950,2557.038086 
z"
        />
        <path
          fill="#395166"
          opacity="1.000000"
          stroke="none"
          d="
M1094.585693,2566.653320 
	C1094.002808,2564.036865 1093.969849,2561.921387 1093.913940,2559.023926 
	C1098.339478,2555.352295 1103.313354,2555.550781 1108.075928,2556.582275 
	C1111.101562,2557.237061 1113.808838,2557.210693 1117.402222,2556.305420 
	C1121.976074,2559.212402 1126.224243,2560.219971 1131.093872,2560.083008 
	C1136.196899,2559.939697 1141.481323,2559.097656 1146.479858,2560.977051 
	C1146.232544,2564.559326 1143.758911,2563.854980 1142.029297,2563.921143 
	C1137.738647,2564.085205 1133.436523,2564.060791 1129.141479,2563.982666 
	C1120.217529,2563.820068 1111.324585,2563.522949 1102.858887,2567.312744 
	C1100.464355,2568.385010 1097.687744,2568.344727 1094.585693,2566.653320 
z"
        />
        <path
          fill="#5D758A"
          opacity="1.000000"
          stroke="none"
          d="
M1004.095337,2534.639648 
	C1014.104187,2533.332520 1024.343994,2535.501221 1035.127563,2532.245605 
	C1036.348999,2533.585938 1040.030640,2534.406982 1036.873779,2536.462646 
	C1034.928955,2537.729004 1032.625000,2537.899902 1030.300537,2538.004150 
	C1023.176331,2538.323242 1015.996460,2537.140381 1008.917725,2539.145264 
	C1006.187317,2539.918457 1004.853149,2537.782959 1004.095337,2534.639648 
z"
        />
        <path
          fill="#3C556B"
          opacity="1.000000"
          stroke="none"
          d="
M1079.994751,2525.995117 
	C1079.616943,2528.120361 1078.608521,2529.770020 1076.255615,2529.823242 
	C1071.468506,2529.932129 1066.624512,2530.611572 1060.538208,2528.968018 
	C1066.747314,2524.172363 1072.782349,2526.752686 1079.124512,2526.001953 
	C1080.010864,2526.009766 1080.000000,2526.000000 1079.994751,2525.995117 
z"
        />
        <path
          fill="#5D758A"
          opacity="1.000000"
          stroke="none"
          d="
M1011.993042,2515.249268 
	C1008.447815,2515.664307 1005.250305,2513.522217 1000.783203,2514.001465 
	C999.219116,2513.241455 998.929260,2512.340576 999.606323,2510.685059 
	C1003.592773,2509.779297 1007.098389,2511.679932 1011.287109,2510.122559 
	C1011.962158,2511.551025 1011.980225,2513.029785 1011.993042,2515.249268 
z"
        />
        <path
          fill="#1F3041"
          opacity="1.000000"
          stroke="none"
          d="
M1249.005859,2586.312988 
	C1245.586670,2591.895020 1243.115723,2592.495605 1237.924316,2588.493408 
	C1241.234131,2587.288086 1244.747803,2586.776123 1249.005859,2586.312988 
z"
        />
        <path
          fill="#395166"
          opacity="1.000000"
          stroke="none"
          d="
M1064.710205,2567.907471 
	C1066.271851,2561.903320 1070.778198,2564.085449 1075.471924,2565.482910 
	C1072.979004,2569.174316 1069.064697,2567.564453 1064.710205,2567.907471 
z"
        />
        <path
          fill="#5D758A"
          opacity="1.000000"
          stroke="none"
          d="
M1075.114014,2544.097168 
	C1074.366699,2545.453613 1073.615479,2545.468506 1072.951782,2544.500977 
	C1072.812866,2544.298828 1072.818115,2543.826172 1072.962769,2543.629395 
	C1073.655029,2542.686768 1074.406250,2542.720459 1075.114014,2544.097168 
z"
        />
        <path
          fill="#395166"
          opacity="1.000000"
          stroke="none"
          d="
M1258.926270,2561.042480 
	C1258.882202,2560.961426 1258.970459,2561.123291 1258.926270,2561.042480 
z"
        />
        <path
          fill="#5D758A"
          opacity="1.000000"
          stroke="none"
          d="
M994.409119,2513.403320 
	C986.263977,2516.690674 978.245605,2515.862305 970.287720,2516.034668 
	C964.026428,2516.170166 957.630798,2514.755859 950.774231,2517.688477 
	C947.713745,2517.476807 943.788269,2519.182373 943.566345,2515.788086 
	C943.379333,2512.926514 947.166931,2512.667480 949.691467,2512.125977 
	C957.111145,2510.534668 964.581238,2509.472900 973.105225,2509.992432 
	C980.047058,2510.007080 986.084778,2510.036377 993.026245,2510.078613 
	C994.655823,2510.843506 994.950256,2511.737549 994.409119,2513.403320 
z"
        />
        <path
          fill="#5D758A"
          opacity="1.000000"
          stroke="none"
          d="
M915.827515,2573.008789 
	C904.360535,2572.293213 892.670166,2572.302490 880.904480,2568.000977 
	C880.395386,2565.442871 876.897766,2562.475586 880.175476,2560.791992 
	C883.363586,2559.154541 888.084351,2558.646729 890.529480,2561.229004 
	C896.189392,2567.206787 903.213989,2567.594971 910.453735,2567.966309 
	C913.357910,2568.115234 915.769165,2568.628906 915.827515,2573.008789 
z"
        />
        <path
          fill="#5D758A"
          opacity="1.000000"
          stroke="none"
          d="
M807.355713,2518.541992 
	C800.717285,2519.002197 795.036621,2518.162109 788.631042,2516.875244 
	C800.345398,2510.374756 819.066406,2510.695068 830.910278,2517.584717 
	C822.923340,2520.287109 815.391907,2516.562256 807.355713,2518.541992 
z"
        />
        <path
          fill="#5D758A"
          opacity="1.000000"
          stroke="none"
          d="
M909.672302,2516.070312 
	C910.675964,2516.242920 910.976562,2516.380859 911.277100,2516.518799 
	C904.124817,2518.329346 897.036194,2520.877686 887.247925,2518.578369 
	C895.607788,2514.387207 902.479370,2516.784424 909.672302,2516.070312 
z"
        />
        <path
          fill="#5D758A"
          opacity="1.000000"
          stroke="none"
          d="
M934.553345,2516.013184 
	C929.539246,2518.494873 924.636902,2518.839600 917.984436,2515.314209 
	C924.808716,2513.573242 929.655151,2513.250244 934.553345,2516.013184 
z"
        />
        <path
          fill="#395166"
          opacity="1.000000"
          stroke="none"
          d="
M957.040161,2568.186523 
	C958.497498,2568.101074 960.214905,2568.211182 959.971436,2568.967773 
	C959.498169,2570.437744 957.818420,2569.941650 956.557373,2569.671143 
	C956.297607,2569.615479 955.940613,2569.223633 955.938049,2568.983887 
	C955.935547,2568.751953 956.332581,2568.515625 957.040161,2568.186523 
z"
        />
        <path
          fill="#D89066"
          opacity="1.000000"
          stroke="none"
          d="
M1376.576538,2446.726562 
	C1376.539673,2445.066895 1376.653564,2444.215576 1376.615479,2442.731934 
	C1376.171143,2434.538818 1379.938354,2428.330566 1383.583740,2421.310547 
	C1387.038086,2417.137695 1391.119507,2415.048340 1395.785156,2412.483398 
	C1398.522705,2411.591553 1400.561768,2411.597900 1403.338013,2412.244141 
	C1409.837158,2416.292969 1416.103271,2415.433838 1423.130127,2414.220703 
	C1425.997803,2413.838379 1427.995605,2414.146973 1430.200073,2416.139648 
	C1430.957397,2417.817627 1431.015259,2418.864014 1430.667725,2420.698486 
	C1429.068848,2425.928711 1425.524048,2427.997314 1420.741455,2429.713135 
	C1416.352783,2430.870361 1412.839233,2429.799561 1409.226196,2429.315186 
	C1396.985718,2427.674316 1387.517700,2435.367920 1387.181030,2447.721436 
	C1386.718628,2464.693848 1393.174805,2479.242920 1404.837036,2492.237061 
	C1408.503296,2494.203369 1410.557861,2496.810059 1412.159424,2499.744385 
	C1415.262817,2505.429932 1420.047119,2508.072754 1426.363770,2509.375000 
	C1440.104858,2512.207764 1443.302856,2516.233887 1444.019897,2529.675293 
	C1444.143555,2531.993652 1443.838989,2534.829102 1446.263672,2535.842773 
	C1449.490112,2537.190918 1450.770020,2533.965820 1452.404297,2532.077637 
	C1453.695679,2530.585205 1454.773926,2528.883301 1455.717407,2526.327148 
	C1457.960083,2521.885986 1459.653076,2517.945312 1462.625610,2513.945801 
	C1465.029785,2510.182129 1470.975098,2513.791016 1471.389404,2507.823242 
	C1465.778931,2497.830078 1456.063354,2490.217041 1459.074463,2476.689941 
	C1458.854980,2473.306885 1458.913208,2470.704346 1459.443115,2467.339355 
	C1460.017456,2465.047363 1460.747192,2463.683350 1462.224365,2461.881592 
	C1465.609131,2459.446533 1468.508545,2457.611572 1471.861084,2455.123047 
	C1475.229004,2454.099121 1477.573364,2454.911377 1480.311401,2456.958496 
	C1486.776489,2468.904785 1493.774170,2479.798828 1498.021973,2492.863281 
	C1502.479614,2503.372803 1502.794800,2514.314453 1507.562988,2524.771973 
	C1508.057739,2525.994629 1508.223022,2526.501221 1508.537109,2527.799805 
	C1508.494263,2533.054199 1510.961182,2537.074219 1510.856934,2542.376953 
	C1510.552612,2548.946533 1511.789917,2555.655518 1502.016357,2555.717773 
	C1494.863403,2550.777832 1495.578369,2543.234863 1493.410400,2536.035400 
	C1489.727417,2531.105225 1488.575195,2525.821777 1486.425781,2520.571533 
	C1481.826782,2520.683594 1479.489014,2525.296631 1477.522095,2525.325439 
	C1484.659912,2521.586182 1488.662354,2527.611328 1492.621338,2535.095703 
	C1493.340088,2536.573730 1493.481201,2537.498047 1493.332886,2539.142334 
	C1491.890137,2542.300537 1490.576538,2544.762451 1490.060547,2548.302979 
	C1489.246582,2550.393066 1488.393555,2551.613525 1486.790039,2553.228516 
	C1479.185425,2557.095703 1471.756348,2559.148438 1463.363770,2560.567383 
	C1456.910278,2560.181396 1452.785034,2555.999756 1446.966919,2554.041504 
	C1442.949463,2551.338135 1440.122070,2548.211182 1435.716064,2546.085205 
	C1426.426880,2541.001465 1419.009033,2534.805176 1413.097656,2525.980957 
	C1404.166870,2513.256348 1395.068115,2501.514893 1388.048706,2488.244873 
	C1381.246948,2475.385742 1377.037842,2462.070068 1376.576538,2446.726562 
z"
        />
        <path
          fill="#704A3A"
          opacity="1.000000"
          stroke="none"
          d="
M1412.658691,2526.056885 
	C1420.940918,2530.386719 1426.526978,2538.622314 1435.716309,2543.318604 
	C1441.022583,2557.761963 1442.466309,2572.421387 1444.834106,2586.865234 
	C1446.460083,2596.784424 1446.734131,2606.843262 1443.078003,2617.081543 
	C1440.082520,2618.359863 1437.792969,2618.159668 1435.077393,2616.819336 
	C1434.606689,2616.520996 1433.905273,2615.866211 1433.466553,2615.862793 
	C1432.965820,2616.555664 1432.671265,2617.134766 1431.611328,2617.748291 
	C1427.464478,2616.892822 1428.610352,2613.127441 1426.528442,2610.434082 
	C1425.845337,2606.313477 1425.596069,2602.758057 1424.811035,2599.146973 
	C1423.405762,2592.682373 1422.852539,2586.048584 1426.134521,2579.806885 
	C1427.262451,2577.661865 1426.976562,2575.532227 1426.832031,2573.156006 
	C1425.818237,2556.489990 1419.756104,2541.462402 1412.658691,2526.056885 
z"
        />
        <path
          fill="#687D90"
          opacity="1.000000"
          stroke="none"
          d="
M1224.919434,2462.068848 
	C1222.659302,2461.238525 1221.279663,2460.401855 1219.900024,2459.564941 
	C1219.933472,2459.059326 1219.967041,2458.553467 1220.000488,2458.047852 
	C1230.174561,2458.047852 1240.348511,2458.047852 1250.841187,2458.047852 
	C1250.935181,2453.533447 1246.448242,2454.023926 1246.127686,2450.571289 
	C1251.603516,2445.037842 1258.601074,2446.742188 1265.020752,2445.945557 
	C1285.430908,2443.412598 1305.893188,2445.915039 1326.333496,2445.707764 
	C1330.499146,2445.665527 1334.972900,2446.091797 1339.482544,2449.179688 
	C1343.263550,2451.871094 1343.683594,2455.299805 1344.735352,2459.230957 
	C1323.938477,2459.990723 1302.931519,2460.043213 1280.996582,2460.109863 
	C1274.720459,2463.108154 1269.123657,2462.160889 1262.712280,2460.683594 
	C1249.829224,2460.088135 1237.906982,2462.862793 1224.919434,2462.068848 
z"
        />
        <path
          fill="#395166"
          opacity="1.000000"
          stroke="none"
          d="
M1340.166504,2448.996094 
	C1335.301880,2449.964844 1330.354126,2450.378174 1325.502319,2448.880127 
	C1324.565308,2448.590576 1323.502808,2448.183105 1322.606567,2448.364990 
	C1307.516479,2451.426270 1292.485840,2446.355469 1277.387329,2448.134033 
	C1267.589355,2449.287842 1257.770630,2450.633301 1246.925415,2449.895508 
	C1245.420532,2447.781006 1244.836060,2445.677979 1244.230835,2442.925293 
	C1254.755737,2441.975586 1265.268921,2441.196533 1275.859497,2441.700439 
	C1284.413086,2442.107422 1293.003296,2442.395020 1301.598755,2440.469482 
	C1306.661011,2439.334961 1312.175293,2439.551025 1317.408081,2440.855225 
	C1323.315918,2442.327881 1329.202759,2442.388672 1335.982178,2440.794434 
	C1337.479248,2441.682861 1338.096313,2442.647217 1339.093262,2443.833740 
	C1339.677856,2445.467041 1339.882690,2446.878418 1340.166504,2448.996094 
z"
        />
        <path
          fill="#2F4457"
          opacity="1.000000"
          stroke="none"
          d="
M1376.369629,2520.892334 
	C1376.182983,2521.618896 1375.741821,2521.725342 1374.627563,2521.935547 
	C1370.136475,2524.902588 1366.635132,2523.965088 1362.667236,2520.590088 
	C1360.580566,2519.993896 1359.117310,2519.957520 1356.922119,2519.915527 
	C1348.741699,2522.681396 1341.260864,2524.882812 1332.796387,2520.664062 
	C1326.890381,2518.673096 1320.973755,2519.529541 1316.262573,2514.937012 
	C1334.192993,2514.603516 1352.102417,2510.761230 1371.163818,2512.046387 
	C1374.710571,2514.175537 1374.378418,2517.728271 1376.369629,2520.892334 
z"
        />
        <path
          fill="#50677C"
          opacity="1.000000"
          stroke="none"
          d="
M1397.702881,2557.589600 
	C1397.802368,2558.342285 1397.451538,2559.208496 1396.239868,2558.184326 
	C1396.406006,2557.707764 1396.771973,2557.571045 1397.702881,2557.589600 
z"
        />
        <path
          fill="#2C2520"
          opacity="1.000000"
          stroke="none"
          d="
M1358.431519,2455.792480 
	C1358.195557,2455.352783 1358.572144,2454.840576 1359.500610,2454.181885 
	C1359.911133,2453.931641 1360.005005,2454.015625 1360.047119,2454.064941 
	C1359.838623,2454.729248 1359.443115,2455.229980 1358.431519,2455.792480 
z"
        />
        <path
          fill="#2C2520"
          opacity="1.000000"
          stroke="none"
          d="
M1358.018555,2456.025391 
	C1357.801147,2455.934570 1357.983521,2457.277832 1357.717529,2456.088135 
	C1357.960327,2455.968750 1358.001587,2456.003906 1358.018555,2456.025391 
z"
        />
        <path
          fill="#2C2520"
          opacity="1.000000"
          stroke="none"
          d="
M1355.055542,2460.946289 
	C1355.096558,2461.025879 1355.014526,2460.866699 1355.055542,2460.946289 
z"
        />
        <path
          fill="#43596D"
          opacity="1.000000"
          stroke="none"
          d="
M423.527039,2601.467773 
	C416.122711,2609.882812 406.755920,2608.951904 397.221069,2606.489990 
	C394.712067,2605.842041 392.500885,2603.911377 389.654053,2605.175537 
	C380.134277,2609.403320 370.049591,2607.813232 360.143890,2607.967041 
	C353.547729,2608.069336 346.948730,2607.986572 340.482758,2607.986572 
	C339.656036,2605.622803 341.809143,2604.701416 342.017395,2602.416016 
	C347.655884,2599.241211 353.725525,2598.489014 359.490021,2598.305420 
	C377.569885,2597.729004 395.678314,2598.061279 413.775543,2598.003174 
	C417.194885,2597.992432 420.315033,2598.777344 423.527039,2601.467773 
z"
        />
        <path
          fill="#465F75"
          opacity="1.000000"
          stroke="none"
          d="
M567.135315,2591.994873 
	C572.629761,2591.997803 577.267883,2591.942383 581.903687,2592.022705 
	C591.491028,2592.188721 591.735229,2592.531982 590.044495,2603.198486 
	C584.172791,2607.297363 577.750977,2605.865967 571.560425,2605.890625 
	C567.507935,2605.906982 562.729370,2605.653320 566.406921,2599.133301 
	C567.617065,2596.988037 565.628052,2596.364014 563.930603,2596.115234 
	C560.361389,2595.592529 556.641357,2596.696045 552.593750,2594.610352 
	C556.322205,2590.302734 561.529663,2592.715820 567.135315,2591.994873 
z"
        />
        <path
          fill="#465F75"
          opacity="1.000000"
          stroke="none"
          d="
M533.176514,2594.211426 
	C528.750488,2603.549316 519.898499,2603.369629 510.454224,2602.389648 
	C510.150757,2599.572510 516.097290,2601.270752 513.312134,2596.585938 
	C512.259460,2594.815430 514.820740,2594.129883 516.455444,2594.110352 
	C521.724243,2594.046875 526.994446,2594.098877 533.176514,2594.211426 
z"
        />
        <path
          fill="#465F75"
          opacity="1.000000"
          stroke="none"
          d="
M504.118713,2600.945557 
	C494.800995,2602.467529 485.288940,2605.053711 474.535645,2603.764160 
	C474.123383,2602.826660 474.565918,2601.356689 475.211121,2601.261963 
	C484.238556,2599.930908 493.284912,2598.658447 502.446289,2598.894043 
	C502.905365,2598.905762 503.342041,2599.788574 504.118713,2600.945557 
z"
        />
        <path
          fill="#465F75"
          opacity="1.000000"
          stroke="none"
          d="
M451.484863,2601.673828 
	C444.988556,2608.847412 444.451324,2608.892578 440.072632,2602.551270 
	C443.481781,2598.864746 447.226990,2599.694092 451.484863,2601.673828 
z"
        />
        <path
          fill="#465F75"
          opacity="1.000000"
          stroke="none"
          d="
M541.405334,2594.265137 
	C541.360535,2596.906494 540.431152,2598.725830 538.027771,2594.881592 
	C538.838013,2594.288574 539.773315,2594.223145 541.405334,2594.265137 
z"
        />
        <path
          fill="#465F75"
          opacity="1.000000"
          stroke="none"
          d="
M665.698975,2575.515381 
	C665.145020,2577.288330 664.087463,2577.906494 662.287781,2576.357666 
	C662.882446,2574.647705 663.955933,2574.127930 665.698975,2575.515381 
z"
        />
        <path
          fill="#50677C"
          opacity="1.000000"
          stroke="none"
          d="
M1262.141968,2460.925781 
	C1267.351196,2460.137207 1272.801880,2460.077881 1279.160645,2460.071045 
	C1275.518921,2464.956787 1270.243652,2465.413574 1262.141968,2460.925781 
z"
        />
        <path
          fill="#5D758A"
          opacity="1.000000"
          stroke="none"
          d="
M1119.194458,2479.854004 
	C1120.828735,2479.945068 1121.808350,2480.023193 1122.774902,2480.181152 
	C1123.768066,2480.343506 1124.631714,2480.829834 1124.772949,2481.894043 
	C1124.941284,2483.162842 1123.901245,2483.602295 1122.950928,2483.723389 
	C1120.347290,2484.055176 1117.631226,2484.496338 1116.068115,2480.614258 
	C1116.765625,2479.895020 1117.649780,2479.885742 1119.194458,2479.854004 
z"
        />
        <path
          fill="#5D758A"
          opacity="1.000000"
          stroke="none"
          d="
M1111.337158,2479.926025 
	C1110.600342,2481.902588 1108.815308,2482.361572 1106.275024,2480.402832 
	C1107.425171,2479.922607 1108.990112,2479.922852 1111.337158,2479.926025 
z"
        />
        <path
          fill="#5D758A"
          opacity="1.000000"
          stroke="none"
          d="
M508.848694,2460.062012 
	C507.732544,2458.956543 507.335602,2457.833496 506.466248,2456.333008 
	C507.082947,2454.659668 508.469574,2454.021973 510.163330,2454.142822 
	C512.494629,2454.309082 515.075562,2454.923096 514.878845,2457.639160 
	C514.653870,2460.745361 511.584167,2459.700684 508.848694,2460.062012 
z"
        />
        <path
          fill="#5D758A"
          opacity="1.000000"
          stroke="none"
          d="
M1117.082031,2455.997803 
	C1132.782715,2455.704102 1147.638306,2457.228760 1163.130493,2453.949463 
	C1165.419312,2455.714844 1167.627563,2456.589844 1168.968750,2454.739990 
	C1173.859985,2447.993164 1180.120605,2449.914551 1187.343140,2450.880371 
	C1185.172363,2455.535156 1182.505737,2458.551514 1178.479614,2461.321045 
	C1177.154541,2461.854004 1176.247437,2461.882568 1174.660645,2461.902344 
	C1173.093994,2461.939941 1172.207031,2461.981201 1170.654541,2462.043213 
	C1163.179443,2463.904053 1156.294312,2465.042236 1149.414307,2462.718262 
	C1145.239746,2461.307861 1141.609741,2463.709717 1136.891357,2463.732666 
	C1134.920532,2459.184814 1131.212402,2460.060059 1128.058105,2460.033447 
	C1118.853882,2459.956055 1109.648560,2460.035400 1100.444092,2459.981445 
	C1098.015015,2459.967041 1095.363892,2460.462891 1092.331665,2457.553955 
	C1100.881104,2454.692139 1108.629272,2456.598389 1117.082031,2455.997803 
z"
        />
        <path
          fill="#627A90"
          opacity="1.000000"
          stroke="none"
          d="
M947.836670,2452.935547 
	C935.111694,2453.576172 922.573730,2456.780518 908.879639,2455.855469 
	C901.805481,2452.511719 895.291443,2453.821289 888.982910,2454.837402 
	C885.972229,2455.322021 883.357666,2454.977783 880.447021,2454.611084 
	C874.810181,2453.900879 873.422241,2450.840576 874.114990,2445.119873 
	C879.504883,2444.207520 884.852356,2444.085938 891.095947,2443.970215 
	C892.768433,2445.472900 893.829102,2447.321289 895.390076,2447.299805 
	C903.500183,2447.189453 911.147339,2450.993896 919.679626,2448.771240 
	C927.584961,2446.712158 936.053894,2448.024414 944.275513,2448.227295 
	C946.636841,2448.285400 947.665161,2449.910156 947.836670,2452.935547 
z"
        />
        <path
          fill="#50677C"
          opacity="1.000000"
          stroke="none"
          d="
M784.535583,2440.428223 
	C788.109314,2436.823730 792.498718,2437.780029 797.438232,2439.379883 
	C793.928223,2443.334229 789.492981,2442.253418 784.535583,2440.428223 
z"
        />
        <path
          fill="#50677C"
          opacity="1.000000"
          stroke="none"
          d="
M994.361023,2514.468750 
	C993.994080,2513.156250 993.983276,2512.279541 993.951172,2510.747070 
	C995.464783,2510.067871 996.999756,2510.044189 999.302429,2510.056152 
	C1000.049683,2510.956055 1000.029175,2511.819824 1000.010254,2513.332031 
	C998.286072,2514.512451 996.770813,2516.251221 994.361023,2514.468750 
z"
        />
        <path
          fill="#3E5871"
          opacity="1.000000"
          stroke="none"
          d="
M1212.308228,2468.409668 
	C1212.866333,2466.702393 1213.904053,2466.075684 1215.688477,2467.502441 
	C1215.160522,2469.263916 1214.130249,2469.932373 1212.308228,2468.409668 
z"
        />
        <path
          fill="#50677C"
          opacity="1.000000"
          stroke="none"
          d="
M1170.310547,2462.473389 
	C1170.811401,2460.736572 1171.828735,2460.063232 1173.662842,2461.485840 
	C1173.161743,2463.232910 1172.132080,2463.855469 1170.310547,2462.473389 
z"
        />
        <path
          fill="#F1E9D8"
          opacity="1.000000"
          stroke="none"
          d="
M1361.992676,3744.004883 
	C1361.221802,3733.659912 1361.163086,3733.886719 1351.872070,3731.480957 
	C1347.937134,3730.462158 1344.029053,3730.278076 1340.076416,3729.829102 
	C1335.051392,3729.258301 1333.212402,3726.026611 1334.089966,3720.355957 
	C1334.800415,3715.763672 1337.690796,3714.153320 1341.928467,3715.072998 
	C1348.149658,3716.423096 1354.278564,3716.325195 1360.210571,3715.332520 
	C1361.591187,3712.123047 1357.752441,3711.058838 1358.521973,3708.500488 
	C1359.425171,3705.498047 1362.766724,3706.796875 1364.186890,3705.250000 
	C1366.884155,3702.311768 1371.317749,3700.007812 1367.860107,3694.441162 
	C1366.003662,3691.452393 1367.958618,3689.436035 1371.307373,3690.296631 
	C1372.860596,3690.695557 1374.301392,3691.654297 1375.696777,3692.515625 
	C1377.790039,3693.808350 1379.855591,3693.465820 1380.898804,3691.545410 
	C1383.155762,3687.390137 1387.894043,3686.888428 1391.841797,3684.108643 
	C1387.155640,3677.898682 1382.423340,3672.693359 1375.690796,3669.583252 
	C1374.143311,3668.867920 1372.702637,3667.869873 1372.851562,3665.918213 
	C1373.033936,3663.529541 1374.820312,3662.620850 1376.896118,3662.237793 
	C1377.867310,3662.058838 1379.132080,3661.716309 1379.841919,3662.145508 
	C1387.626587,3666.850830 1391.444336,3658.388672 1397.340332,3656.908691 
	C1398.122192,3656.712402 1399.176514,3655.524170 1399.209717,3654.756348 
	C1399.451660,3649.172852 1404.136230,3645.440186 1405.683105,3639.134766 
	C1399.154297,3640.679199 1393.337646,3637.895264 1387.294800,3639.686768 
	C1385.466553,3640.229248 1383.337524,3639.812256 1381.349854,3639.739502 
	C1380.298218,3639.701416 1379.480347,3639.136230 1379.196289,3638.058105 
	C1378.772583,3636.449463 1379.840698,3635.473877 1381.000610,3634.920654 
	C1382.161011,3634.367188 1383.537720,3634.144775 1384.841553,3634.034424 
	C1394.628906,3633.203125 1403.023926,3628.949951 1411.146729,3623.787109 
	C1413.133179,3622.524414 1413.629639,3620.992188 1414.141235,3619.038574 
	C1415.552734,3613.649902 1418.023682,3609.238281 1424.032837,3607.637207 
	C1430.157959,3606.005371 1424.705444,3601.684082 1425.415405,3598.990723 
	C1428.612183,3597.376709 1431.738892,3598.468994 1434.625732,3597.760986 
	C1437.802856,3596.982422 1438.536865,3594.184326 1436.236938,3591.771729 
	C1435.563843,3591.065918 1434.727539,3590.249023 1433.840820,3590.055176 
	C1426.586914,3588.468018 1420.665405,3585.029541 1415.641479,3579.353760 
	C1413.227661,3576.626709 1409.487427,3578.059814 1406.579712,3580.310303 
	C1402.076416,3583.795898 1396.955200,3586.154785 1391.654297,3588.159668 
	C1390.118896,3588.740723 1387.895996,3590.256592 1387.069214,3588.956055 
	C1383.558716,3583.434082 1374.523682,3582.167969 1374.957642,3573.148193 
	C1385.484131,3569.465332 1396.040527,3567.784424 1406.964478,3570.683105 
	C1409.241211,3571.287109 1411.567383,3572.465820 1413.643188,3570.853027 
	C1419.802490,3566.069092 1424.792725,3569.439697 1429.849243,3572.859131 
	C1433.394165,3575.256348 1446.309082,3578.734863 1450.851685,3578.524902 
	C1451.328491,3577.037354 1450.648315,3575.884766 1449.580811,3575.049805 
	C1447.702759,3573.580322 1445.559570,3572.155029 1446.587402,3569.395752 
	C1447.738647,3566.306396 1448.630859,3562.752441 1451.566162,3560.937500 
	C1455.019409,3558.802002 1458.922485,3560.455566 1460.148438,3563.476562 
	C1463.306885,3571.259766 1472.366089,3577.036133 1468.577759,3587.319580 
	C1467.499634,3590.246094 1469.265991,3592.443115 1471.450195,3594.203613 
	C1473.078247,3595.516113 1474.937866,3596.463623 1476.784668,3594.757324 
	C1478.245483,3593.407471 1477.816284,3591.628662 1476.801514,3590.232178 
	C1474.916748,3587.637207 1473.186646,3585.184082 1477.388184,3582.337158 
	C1483.341309,3578.810059 1488.233765,3581.509766 1493.784302,3583.787598 
	C1493.119019,3593.340820 1486.391602,3597.430420 1478.603394,3601.548340 
	C1477.105103,3601.990234 1476.219238,3602.000244 1474.669189,3602.014160 
	C1471.648926,3601.977295 1470.198364,3603.433594 1468.442017,3605.557617 
	C1465.177246,3608.677734 1462.972046,3612.273682 1458.303711,3612.098877 
	C1455.448853,3611.991943 1454.428223,3613.984375 1453.596313,3616.361572 
	C1452.946899,3618.217041 1452.005249,3620.044922 1450.848877,3621.634766 
	C1446.462891,3627.666016 1446.410522,3627.627686 1452.073608,3633.572754 
	C1453.956665,3635.549805 1454.638672,3638.295898 1452.119263,3639.456543 
	C1447.098755,3641.769775 1446.715088,3646.049805 1445.126953,3650.453613 
	C1443.455566,3655.087646 1440.988647,3659.888916 1434.086670,3660.301270 
	C1428.050171,3660.661865 1425.134644,3665.510742 1427.716309,3670.768066 
	C1430.518433,3676.473877 1427.554810,3678.480225 1423.753540,3680.900879 
	C1421.872803,3682.098633 1419.108887,3681.972900 1418.213623,3684.669922 
	C1413.399536,3699.173584 1398.545654,3705.749023 1391.481201,3718.735840 
	C1386.869141,3727.214111 1379.751831,3734.310303 1373.972778,3742.184326 
	C1372.244141,3744.539795 1370.394653,3746.001221 1366.734863,3745.985352 
	C1364.807983,3745.960693 1363.737915,3745.592773 1362.404297,3744.414551 
	C1361.990234,3744.009766 1362.000000,3744.000000 1361.992676,3744.004883 
z"
        />
        <path
          fill="#EBE4D0"
          opacity="1.000000"
          stroke="none"
          d="
M1478.654907,3602.110840 
	C1482.703857,3596.670898 1486.578491,3590.535156 1493.464355,3586.414551 
	C1495.576660,3584.143311 1497.797607,3582.941650 1499.574463,3580.434570 
	C1503.955322,3580.063965 1507.769653,3579.595703 1511.668457,3578.583740 
	C1516.885132,3577.229980 1520.897461,3579.647705 1521.849854,3583.689941 
	C1523.062256,3588.835449 1521.300903,3592.273193 1515.792725,3593.879395 
	C1512.721313,3594.775146 1510.479492,3596.285156 1508.337158,3599.369629 
	C1503.357300,3600.977783 1499.178589,3603.032227 1494.229370,3599.067383 
	C1490.406494,3596.005127 1487.912231,3598.389404 1484.846680,3606.670166 
	C1481.280518,3607.362305 1482.237915,3602.339111 1478.654907,3602.110840 
z"
        />
        <path
          fill="#F0EBDD"
          opacity="1.000000"
          stroke="none"
          d="
M1533.766479,3617.012207 
	C1535.398071,3622.276367 1535.933472,3626.737793 1532.255005,3630.683838 
	C1530.797241,3632.248047 1530.256714,3634.031006 1530.992310,3636.182861 
	C1531.831055,3638.636963 1530.308350,3640.021729 1528.309204,3640.707520 
	C1524.132446,3642.139648 1520.337769,3643.821289 1518.925049,3648.583008 
	C1518.632690,3649.568848 1517.319946,3649.609863 1516.307129,3649.341553 
	C1514.737183,3648.925537 1513.981445,3647.463623 1514.505981,3646.321289 
	C1516.182861,3642.669922 1514.705444,3637.680664 1520.095215,3635.500244 
	C1524.761597,3633.612793 1525.122559,3629.781982 1521.822876,3625.839355 
	C1519.486450,3623.048340 1520.024536,3620.332275 1523.549316,3620.099854 
	C1527.473389,3619.841064 1528.777954,3613.395508 1533.766479,3617.012207 
z"
        />
        <path
          fill="#EBE4D0"
          opacity="1.000000"
          stroke="none"
          d="
M1468.636963,3605.936035 
	C1468.290161,3602.888428 1467.757080,3598.930176 1473.558838,3601.523438 
	C1473.043579,3604.058350 1471.592407,3605.497559 1468.636963,3605.936035 
z"
        />
        <path
          fill="#EBE4D0"
          opacity="1.000000"
          stroke="none"
          d="
M1508.116577,3600.642578 
	C1508.325806,3600.007324 1508.657471,3599.977783 1508.976562,3600.028076 
	C1510.476562,3600.263184 1512.420776,3599.728516 1512.835083,3601.957275 
	C1512.913208,3602.376953 1512.350098,3603.228027 1511.911865,3603.377197 
	C1509.997437,3604.027100 1508.869995,3603.025879 1508.116577,3600.642578 
z"
        />
        <path
          fill="#EBE4D0"
          opacity="1.000000"
          stroke="none"
          d="
M1362.064575,3744.576660 
	C1363.089722,3744.360107 1364.189209,3744.710693 1365.644287,3745.530518 
	C1364.201660,3748.020020 1362.955933,3747.548584 1362.064575,3744.576660 
z"
        />
        <path
          fill="#E5DDC9"
          opacity="1.000000"
          stroke="none"
          d="
M1316.261475,3812.640869 
	C1316.555298,3808.266113 1317.709961,3804.637939 1321.067627,3802.692139 
	C1324.948120,3800.443359 1328.778687,3798.917236 1326.456299,3793.011963 
	C1325.563599,3790.741943 1328.204346,3789.064209 1330.321899,3787.853760 
	C1333.194824,3786.211182 1335.959351,3784.378906 1339.377808,3782.309082 
	C1340.330322,3786.531738 1339.642700,3790.413330 1334.947876,3792.805664 
	C1332.528320,3794.038574 1331.638062,3797.108154 1334.792114,3798.012207 
	C1342.583374,3800.245361 1342.765381,3806.641602 1343.973145,3812.657959 
	C1345.263184,3819.083984 1345.260742,3819.084473 1352.222412,3818.290527 
	C1363.274902,3817.030029 1366.829468,3821.901855 1362.510742,3833.545898 
	C1361.046997,3834.203857 1359.744385,3834.077637 1359.193115,3834.661133 
	C1351.020874,3843.314209 1342.659424,3839.466064 1334.435059,3834.981445 
	C1331.625000,3833.449463 1329.232910,3831.150635 1326.324951,3828.601074 
	C1328.614258,3822.660645 1334.651245,3818.045166 1329.187866,3810.649658 
	C1326.347290,3818.419922 1322.006104,3819.210205 1316.261475,3812.640869 
z"
        />
        <path
          fill="#E5DDC9"
          opacity="1.000000"
          stroke="none"
          d="
M1366.478271,3816.519531 
	C1367.103394,3814.190430 1368.149292,3812.963623 1369.682129,3812.267822 
	C1371.362549,3811.505371 1373.099609,3809.905273 1374.965942,3811.615234 
	C1376.278687,3812.817871 1375.963867,3814.610352 1375.809448,3816.219482 
	C1375.591187,3818.493896 1374.245117,3819.686768 1372.011353,3819.830322 
	C1369.714233,3819.978027 1367.360229,3820.143066 1366.478271,3816.519531 
z"
        />
        <path
          fill="#E5DDC9"
          opacity="1.000000"
          stroke="none"
          d="
M1340.631226,3779.685059 
	C1339.781128,3774.709961 1342.502319,3771.077637 1347.331787,3768.134277 
	C1349.359741,3773.949463 1345.143311,3776.559814 1340.631226,3779.685059 
z"
        />
        <path
          fill="#E5DDC9"
          opacity="1.000000"
          stroke="none"
          d="
M1352.642212,3763.906982 
	C1352.188354,3762.212402 1352.731445,3760.680176 1355.379150,3760.110840 
	C1355.810669,3761.795166 1355.262817,3763.322754 1352.642212,3763.906982 
z"
        />
        <path
          fill="#DED5C3"
          opacity="1.000000"
          stroke="none"
          d="
M1315.452637,3812.256836 
	C1316.952271,3812.198486 1317.983032,3812.244873 1318.854736,3812.641113 
	C1325.409668,3815.619629 1325.466919,3815.636963 1325.046021,3808.097168 
	C1324.969482,3806.726074 1324.671753,3805.368896 1325.977295,3804.680176 
	C1327.156006,3804.058105 1328.539551,3804.352539 1329.690063,3805.155518 
	C1331.920044,3806.712158 1333.643066,3808.640869 1333.855103,3811.495117 
	C1334.000732,3813.455078 1333.904907,3815.433105 1334.061646,3817.391602 
	C1334.683228,3825.156006 1334.704224,3825.154297 1326.509766,3827.480225 
	C1323.051270,3828.084717 1320.275879,3825.212891 1316.599487,3827.747070 
	C1312.749023,3825.215576 1309.027344,3822.689697 1309.995239,3816.738770 
	C1311.630249,3814.834717 1313.270264,3813.679199 1315.452637,3812.256836 
z"
        />
        <path
          fill="#F5F1E5"
          opacity="1.000000"
          stroke="none"
          d="
M1932.044678,3396.830078 
	C1936.252563,3395.379639 1935.984985,3392.163086 1936.001099,3389.144775 
	C1936.017212,3386.127930 1935.792358,3382.954346 1938.405396,3380.814941 
	C1941.833984,3378.007568 1944.597046,3375.547852 1942.985596,3370.119385 
	C1942.111816,3367.175537 1945.589844,3365.062500 1948.668213,3364.275635 
	C1950.583496,3363.785889 1952.622437,3363.787598 1954.546143,3363.321533 
	C1955.888062,3362.996582 1956.868530,3361.913574 1956.935791,3360.485352 
	C1957.015747,3358.789795 1955.618286,3358.316650 1954.288208,3358.146240 
	C1951.987305,3357.851562 1949.458618,3358.139648 1947.669312,3356.385498 
	C1945.783813,3354.537354 1942.599243,3354.739014 1941.228516,3352.181396 
	C1941.747925,3349.854004 1943.693115,3349.920166 1945.183105,3350.099121 
	C1951.751465,3350.887939 1955.600952,3346.385010 1959.785767,3342.704346 
	C1962.063354,3340.701172 1962.839233,3338.038574 1959.421753,3336.272705 
	C1957.008179,3335.025635 1954.952759,3332.740723 1956.553833,3330.929932 
	C1959.217163,3327.916992 1957.807983,3322.812256 1962.146851,3321.035889 
	C1963.989258,3320.281494 1965.928467,3319.767090 1967.790527,3319.056641 
	C1972.656738,3317.200195 1974.907593,3311.886719 1972.257446,3307.587891 
	C1970.546143,3304.811768 1967.557007,3302.786865 1968.210205,3298.902832 
	C1968.933105,3294.603516 1972.491211,3294.390625 1975.658691,3293.477539 
	C1976.675049,3293.184570 1977.140381,3292.072266 1976.902344,3291.086182 
	C1976.569824,3289.709473 1975.451782,3288.764893 1974.133789,3288.460938 
	C1968.373901,3287.132568 1963.158691,3284.645996 1958.152832,3280.631348 
	C1959.392578,3278.829590 1960.928101,3278.519043 1963.308716,3279.495850 
	C1966.897095,3283.267334 1968.936279,3280.060547 1971.630371,3278.495117 
	C1973.946167,3275.231934 1979.088989,3277.829834 1979.583252,3274.947754 
	C1980.402954,3270.168213 1981.523438,3265.698730 1983.065552,3261.108887 
	C1983.730835,3259.128418 1975.560181,3258.025635 1978.541016,3251.850098 
	C1979.265991,3250.939453 1979.600220,3250.610840 1980.504883,3249.873047 
	C1984.263794,3247.220703 1989.811523,3245.151123 1981.542969,3242.145020 
	C1978.365845,3240.990234 1975.349731,3239.577148 1974.538208,3235.070801 
	C1974.698608,3233.906006 1974.852173,3233.454834 1975.499390,3232.487305 
	C1976.884277,3231.978027 1977.775391,3231.985596 1979.334839,3232.006348 
	C1990.630615,3232.712646 1988.392944,3226.031738 1987.187134,3219.234375 
	C1987.356567,3217.614014 1989.811157,3218.570312 1988.468140,3216.353516 
	C1987.335693,3214.918945 1986.793701,3213.829102 1986.390015,3211.998291 
	C1986.388062,3210.490479 1986.660278,3209.685791 1987.543457,3208.458740 
	C1987.981934,3207.980469 1988.000000,3208.000000 1987.990234,3207.990234 
	C1989.711426,3206.131592 1991.975342,3205.481445 1994.347412,3205.681396 
	C2003.029663,3206.414062 2003.570435,3199.465332 2005.155396,3193.957275 
	C2006.880371,3187.963135 2001.396484,3188.065430 1997.986206,3186.607910 
	C1995.499268,3185.544678 1992.864868,3184.694824 1993.616821,3180.591797 
	C1994.877441,3179.993164 1995.751465,3179.974854 1997.281006,3179.953613 
	C2002.448242,3175.928223 2008.239380,3175.596436 2013.755859,3174.576416 
	C2018.278320,3173.740234 2021.537964,3175.513672 2023.542725,3180.680176 
	C2024.869141,3184.650146 2024.783813,3187.817871 2023.071289,3191.582275 
	C2021.306519,3194.112549 2018.912720,3195.206299 2018.189575,3198.425781 
	C2016.098389,3210.316650 2017.549316,3222.052490 2012.639282,3232.842529 
	C2011.700928,3234.904541 2011.897949,3237.576660 2010.691406,3239.520264 
	C2003.792114,3250.634277 2004.203125,3261.956787 2008.227173,3274.008057 
	C2010.213135,3279.956055 2006.869385,3286.510498 2008.023926,3293.555420 
	C2008.237061,3295.726074 2008.100830,3297.157959 2007.370483,3299.210449 
	C2004.526001,3303.122070 2000.771240,3301.782471 1997.475952,3302.363770 
	C2002.499023,3301.910645 2006.057983,3304.404297 2009.482910,3308.572754 
	C2010.677612,3311.890137 2010.572021,3314.445801 2008.570312,3317.439453 
	C2005.328613,3318.119629 2002.447754,3317.635498 2000.078369,3318.520752 
	C1996.456421,3319.873535 1994.161377,3322.777588 1995.310425,3327.070312 
	C1998.556030,3339.194092 1995.809326,3342.575928 1983.712891,3341.200195 
	C1985.997314,3344.580322 1985.997314,3344.580322 1998.746704,3345.530273 
	C1998.786621,3348.021973 1996.745239,3347.659912 1995.284058,3348.059814 
	C1991.726929,3349.033691 1989.286011,3350.366211 1993.440186,3353.798340 
	C1996.831787,3356.599854 1994.665649,3358.330078 1992.198975,3359.821777 
	C1989.276123,3361.588867 1986.169678,3362.644043 1982.714111,3361.288818 
	C1979.522583,3360.037354 1976.954712,3360.738037 1974.426025,3364.894043 
	C1981.546997,3363.875488 1984.218018,3372.798584 1991.590576,3370.613525 
	C1992.478027,3370.350586 1993.266724,3371.507568 1993.406250,3372.587646 
	C1993.540039,3373.623779 1993.347900,3374.728271 1992.349121,3375.135498 
	C1989.906616,3376.131104 1987.323730,3377.813232 1984.906494,3377.645996 
	C1977.690186,3377.145264 1971.442871,3380.191895 1964.806152,3381.919922 
	C1961.751343,3382.715332 1959.618774,3383.516113 1960.709351,3387.052002 
	C1962.290161,3392.178711 1959.582886,3393.678467 1955.216919,3393.971680 
	C1953.892090,3394.060547 1952.562988,3394.115967 1951.245361,3394.269287 
	C1949.769409,3394.440430 1948.747314,3395.349609 1948.580811,3396.770264 
	C1948.417847,3398.163574 1949.152832,3399.365967 1950.450806,3399.986572 
	C1955.281006,3402.295410 1960.411377,3403.513184 1965.734375,3403.837402 
	C1966.640381,3403.892578 1968.253052,3403.489990 1968.413574,3402.958496 
	C1970.612549,3395.678223 1979.063110,3395.631104 1982.963867,3390.240967 
	C1984.425537,3388.221436 1986.701782,3384.531982 1989.993652,3388.442139 
	C1992.863892,3391.851562 1992.477417,3396.632080 1989.252319,3399.095459 
	C1986.839844,3400.938232 1984.034302,3402.080811 1980.917236,3401.981689 
	C1977.230225,3401.864502 1974.770874,3404.201904 1972.132202,3406.187256 
	C1969.585449,3408.103027 1969.813843,3410.817627 1970.338989,3413.462158 
	C1970.951416,3416.545898 1973.277832,3417.417480 1976.131104,3417.983398 
	C1980.435181,3418.837158 1984.387695,3415.679688 1989.156738,3416.862061 
	C1988.756958,3421.491455 1987.384155,3424.800049 1982.816528,3426.035156 
	C1978.976807,3427.073730 1975.186157,3428.315918 1971.314209,3429.208496 
	C1963.772705,3430.947510 1961.354004,3429.411377 1960.761719,3421.859863 
	C1960.557251,3419.250732 1961.904419,3416.812500 1958.057251,3415.696777 
	C1954.919434,3414.786621 1952.287354,3414.128662 1950.221313,3417.391602 
	C1949.286133,3418.868408 1947.866943,3419.619141 1946.155518,3419.995850 
	C1944.946533,3420.261963 1943.367554,3420.243896 1943.294678,3422.229248 
	C1945.245483,3425.579346 1948.064453,3421.916016 1950.317017,3423.014160 
	C1950.579224,3423.541260 1951.226929,3424.241455 1951.075195,3424.627686 
	C1948.042725,3432.363281 1948.026367,3432.346191 1955.700562,3434.062256 
	C1956.636719,3434.271484 1957.551636,3434.614990 1958.435303,3434.992676 
	C1958.673950,3435.094482 1958.761353,3435.550537 1958.918091,3435.843750 
	C1952.967407,3438.330322 1953.116821,3438.269287 1955.690796,3444.562744 
	C1956.783203,3447.233643 1958.653931,3449.990234 1957.311890,3453.133057 
	C1956.019653,3456.158936 1957.330444,3458.003174 1959.528809,3459.993896 
	C1962.681274,3462.848877 1960.595581,3464.875244 1957.682739,3465.542725 
	C1954.821777,3466.198242 1951.740234,3465.879639 1948.756470,3466.014160 
	C1945.780273,3466.148438 1942.264282,3465.781738 1942.406372,3470.261475 
	C1942.525879,3474.030029 1945.617798,3473.904297 1948.279785,3473.974854 
	C1952.844238,3474.095703 1957.336182,3474.343750 1960.964355,3477.923340 
	C1958.841919,3480.938232 1955.974854,3479.594482 1952.775757,3479.982910 
	C1948.467163,3480.941406 1944.820679,3480.624023 1939.564575,3480.970215 
	C1945.064697,3487.395508 1945.124390,3493.640869 1940.735474,3499.928223 
	C1936.888550,3505.438965 1932.270264,3509.156250 1924.406494,3507.969238 
	C1915.059204,3498.986084 1914.728394,3495.137939 1921.928589,3484.863281 
	C1922.760986,3483.675049 1923.245483,3482.488770 1922.798218,3481.211426 
	C1919.394287,3471.488525 1924.983887,3461.729980 1922.635498,3452.005859 
	C1922.324951,3450.720459 1922.388550,3449.250732 1923.108643,3448.132080 
	C1931.623291,3434.904297 1928.509521,3419.483154 1931.613281,3404.567871 
	C1931.970825,3401.845215 1931.994507,3399.733154 1932.044678,3396.830078 
z"
        />
        <path
          fill="#D8D1C0"
          opacity="1.000000"
          stroke="none"
          d="
M2005.534668,3300.625000 
	C2005.989990,3298.490967 2005.994507,3296.991943 2005.992676,3294.742920 
	C2010.779541,3292.274902 2013.381348,3289.257812 2013.487671,3283.782715 
	C2013.645752,3275.647705 2012.373779,3267.319824 2013.507568,3259.468750 
	C2015.019531,3248.999268 2012.405151,3237.952393 2019.260010,3227.873535 
	C2022.758057,3222.729980 2026.140625,3214.774902 2018.293091,3208.433594 
	C2014.491577,3205.361816 2017.634766,3200.964600 2018.039185,3196.454834 
	C2018.252808,3194.800537 2018.573486,3193.914307 2019.532104,3192.544922 
	C2022.780518,3189.678711 2021.743042,3186.458008 2021.993896,3182.770264 
	C2024.025269,3168.011719 2026.303711,3154.067627 2028.768677,3140.146240 
	C2030.243774,3131.814697 2033.223633,3123.242188 2027.806030,3115.057129 
	C2026.884399,3113.665039 2026.359131,3111.670166 2027.511963,3110.353516 
	C2033.550659,3103.457275 2030.253296,3095.901855 2028.957520,3088.677246 
	C2028.079956,3083.784668 2029.272217,3080.260010 2033.623047,3076.917725 
	C2035.741211,3075.966064 2037.260498,3075.812012 2039.583740,3076.194092 
	C2044.561035,3078.673828 2044.638184,3082.432861 2043.772705,3086.388672 
	C2043.007202,3089.888184 2042.202637,3093.309326 2045.212891,3097.103027 
	C2046.593384,3100.272949 2046.760498,3102.822998 2046.010498,3106.142090 
	C2044.407837,3109.677979 2040.934692,3111.592773 2042.018311,3116.258789 
	C2040.247437,3134.361572 2037.767212,3151.663330 2039.630859,3169.923340 
	C2040.289062,3172.200439 2040.321045,3173.743896 2039.982666,3176.011230 
	C2038.611084,3179.972412 2035.587646,3182.854736 2038.030029,3187.464111 
	C2040.041382,3194.561768 2039.873291,3200.888672 2036.859253,3207.785645 
	C2034.027466,3216.477295 2034.342163,3224.855469 2033.104126,3233.024414 
	C2028.254883,3265.019287 2025.438721,3297.282227 2021.619507,3329.409180 
	C2019.905396,3343.828369 2015.910278,3358.166504 2017.278442,3372.980713 
	C2017.703735,3377.585938 2017.230591,3382.339111 2013.805664,3387.056641 
	C2012.681396,3387.966309 2012.159668,3388.235352 2010.795898,3388.246826 
	C2009.915649,3375.235107 2009.877319,3362.480713 2009.754639,3348.783203 
	C2009.142456,3347.073486 2008.926636,3346.227295 2009.373169,3344.721680 
	C2010.474609,3335.810059 2009.149536,3327.574463 2008.280762,3318.646973 
	C2007.988647,3315.218018 2007.996948,3312.445801 2007.995361,3308.841797 
	C2006.256836,3306.086914 2004.217651,3304.297607 2005.534668,3300.625000 
z"
        />
        <path
          fill="#DBD5C8"
          opacity="1.000000"
          stroke="none"
          d="
M2009.137939,3387.992188 
	C2010.295898,3387.992676 2010.637695,3387.995850 2011.492188,3387.999512 
	C2017.087402,3393.192139 2015.555786,3399.823486 2015.694458,3405.981934 
	C2015.865479,3413.578125 2015.897339,3421.227051 2013.018188,3429.460449 
	C2012.347656,3433.654297 2012.198853,3436.999756 2011.905273,3441.172119 
	C2008.820923,3447.099609 2008.566528,3453.650635 2002.707031,3457.736816 
	C1998.102905,3457.402832 1998.700195,3462.603516 1995.910889,3463.110352 
	C1993.702148,3462.839844 1992.677368,3461.461670 1991.884644,3460.059082 
	C1990.579834,3457.750244 1986.261719,3458.329590 1986.554321,3454.869385 
	C1986.845337,3451.423584 1990.563354,3451.988281 1992.663696,3450.655029 
	C1994.465332,3449.510498 1996.084229,3448.326172 1995.643433,3446.050537 
	C1995.211792,3443.821045 1995.853394,3442.651367 1997.609009,3441.100586 
	C2002.294556,3436.960205 2002.125977,3436.769043 1997.233154,3434.070068 
	C1988.839966,3429.440186 1988.716553,3427.993652 1996.299194,3423.109131 
	C2003.422852,3418.520264 2004.046387,3418.032715 1999.034180,3411.261719 
	C1993.846924,3404.253906 1995.938843,3397.938477 1998.734985,3391.372070 
	C2000.560913,3387.084473 2004.784546,3388.188477 2009.137939,3387.992188 
z"
        />
        <path
          fill="#F2EBDD"
          opacity="1.000000"
          stroke="none"
          d="
M1923.388672,3506.652344 
	C1932.385986,3507.154541 1936.822876,3502.098877 1940.154785,3495.522217 
	C1942.007935,3491.864258 1943.756348,3488.033691 1938.282593,3485.645996 
	C1936.163452,3484.721436 1936.030762,3482.521240 1935.355347,3480.708740 
	C1934.302612,3477.884277 1935.136353,3475.498535 1938.297729,3476.672607 
	C1942.453613,3478.216064 1946.903442,3477.161133 1951.448242,3479.454102 
	C1950.544189,3483.077148 1949.837402,3485.706787 1954.762085,3486.002441 
	C1958.643677,3486.235107 1962.666870,3485.134277 1967.276123,3487.418945 
	C1962.545410,3491.507568 1957.353027,3491.931152 1952.004395,3492.033203 
	C1948.865601,3492.093018 1945.356201,3491.954590 1948.548706,3496.852783 
	C1949.922607,3498.960938 1947.534302,3499.281494 1946.401367,3500.319824 
	C1943.546265,3502.937012 1940.954834,3505.842041 1938.136230,3509.306152 
	C1934.761963,3514.934326 1930.310913,3516.735840 1923.868530,3514.551758 
	C1921.820312,3511.841553 1921.776611,3509.599365 1923.388672,3506.652344 
z"
        />
        <path
          fill="#F5F1E5"
          opacity="1.000000"
          stroke="none"
          d="
M1923.301270,3513.676270 
	C1928.736694,3514.603760 1932.302124,3511.239502 1937.286377,3510.075684 
	C1941.176392,3509.954346 1943.971069,3510.406738 1943.966797,3515.266113 
	C1940.687378,3518.728760 1935.250854,3519.757324 1937.418945,3526.407471 
	C1938.444092,3529.551514 1934.879272,3530.814453 1932.354492,3531.433105 
	C1928.123291,3532.470215 1923.732788,3532.426758 1919.498779,3531.414795 
	C1916.908569,3530.796143 1914.406494,3529.889893 1910.850098,3529.997314 
	C1909.482788,3528.999512 1908.960693,3528.008789 1908.214355,3526.504150 
	C1910.912842,3519.512451 1915.606323,3515.093506 1923.301270,3513.676270 
z"
        />
        <path
          fill="#D8D1C0"
          opacity="1.000000"
          stroke="none"
          d="
M2002.041992,3458.641113 
	C2004.512695,3452.548584 2005.996216,3446.626465 2011.103516,3442.148438 
	C2012.983887,3451.711182 2011.357056,3461.358643 2010.698608,3471.541504 
	C2004.360840,3470.992432 1997.809448,3474.323486 1992.201172,3468.763184 
	C1993.868408,3467.460205 1995.744873,3466.438965 1997.603760,3466.470459 
	C2003.354248,3466.567139 2002.784302,3463.087646 2002.041992,3458.641113 
z"
        />
        <path
          fill="#F5F1E5"
          opacity="1.000000"
          stroke="none"
          d="
M2009.083496,3344.121582 
	C2009.747925,3344.964844 2009.772217,3345.788086 2009.733276,3347.225586 
	C2008.028320,3347.858154 2005.741821,3348.309570 2005.230103,3346.570801 
	C2004.556274,3344.281250 2006.830688,3344.278320 2009.083496,3344.121582 
z"
        />
        <path
          fill="#F5F1E5"
          opacity="1.000000"
          stroke="none"
          d="
M1966.952393,3460.928223 
	C1967.037354,3460.882568 1966.867310,3460.973633 1966.952393,3460.928223 
z"
        />
        <path
          fill="#061420"
          opacity="1.000000"
          stroke="none"
          d="
M2092.684570,3406.127930 
	C2085.805176,3408.498535 2080.427246,3407.753906 2074.402588,3404.178223 
	C2067.619141,3400.151611 2059.087402,3400.582764 2050.739746,3398.133789 
	C2048.957520,3399.020264 2047.782227,3399.642090 2045.726318,3399.844482 
	C2041.813477,3399.406738 2038.509155,3397.948242 2034.807983,3400.593750 
	C2033.784180,3400.830322 2033.357300,3400.837158 2032.453125,3400.416992 
	C2031.011475,3399.996338 2030.047241,3400.002441 2028.356689,3400.021484 
	C2026.676147,3380.435303 2029.476196,3361.001953 2030.322388,3341.482178 
	C2031.096802,3323.617676 2032.845093,3305.795654 2034.381104,3287.060059 
	C2041.803101,3282.590332 2052.428467,3284.255127 2057.009766,3289.703857 
	C2060.145752,3293.433105 2059.250977,3296.266113 2055.033691,3298.845215 
	C2075.714111,3295.415527 2096.595703,3298.425537 2118.092773,3296.282959 
	C2126.376709,3297.675293 2134.008301,3296.603027 2142.356445,3297.431641 
	C2148.178467,3299.221191 2152.811768,3301.884766 2158.693115,3303.525391 
	C2183.891357,3307.777100 2207.204834,3318.057617 2232.612549,3316.728760 
	C2239.398926,3316.373535 2246.258545,3317.093750 2246.378662,3306.971680 
	C2246.431396,3302.553955 2250.547607,3301.514160 2254.443848,3301.161865 
	C2259.375000,3300.715576 2264.451660,3301.906250 2269.925781,3299.483398 
	C2271.567383,3298.725830 2272.558594,3298.505615 2274.320068,3298.503174 
	C2280.127686,3299.972900 2285.269043,3303.770752 2290.687500,3297.715820 
	C2292.044922,3296.913086 2292.900146,3296.669922 2294.452637,3296.625977 
	C2297.127441,3297.197021 2299.068359,3298.204102 2301.936035,3297.599609 
	C2305.532715,3297.174805 2308.416260,3297.199707 2311.766846,3298.923340 
	C2313.360596,3300.577393 2313.730713,3301.993164 2313.607422,3304.249512 
	C2310.260010,3309.901367 2304.001709,3315.016846 2314.852539,3319.639648 
	C2317.597412,3323.479004 2318.542236,3327.135010 2317.975098,3331.785889 
	C2317.259277,3334.224121 2315.607178,3335.907471 2317.645752,3338.479736 
	C2319.226562,3341.709717 2320.003906,3344.431641 2318.621582,3347.999512 
	C2311.933594,3351.674805 2306.200928,3349.205811 2300.685303,3346.559082 
	C2297.123047,3344.849854 2293.727051,3343.177002 2288.934326,3343.900391 
	C2281.147217,3344.357422 2273.936523,3341.237061 2266.490234,3344.884766 
	C2269.181641,3348.654785 2275.532471,3346.415039 2276.693115,3351.430420 
	C2277.471680,3354.794189 2274.967529,3356.829102 2272.189453,3359.260010 
	C2268.176758,3361.117432 2264.674561,3361.132080 2260.409912,3360.336426 
	C2256.057129,3358.395264 2252.472900,3355.672852 2247.821777,3359.729492 
	C2244.781006,3360.678223 2242.372070,3360.691895 2239.237305,3360.222656 
	C2236.326172,3360.475830 2231.980713,3355.108154 2232.383789,3362.499268 
	C2232.898438,3371.929443 2228.402344,3377.177979 2219.196045,3378.060059 
	C2211.703613,3378.778320 2204.096191,3378.104248 2195.999512,3380.098633 
	C2187.454102,3381.797852 2179.431152,3380.386230 2171.569336,3381.644531 
	C2166.678467,3382.427490 2162.792480,3383.349121 2164.361084,3390.405762 
	C2166.020752,3397.871582 2162.348389,3400.690918 2154.805664,3398.875000 
	C2149.017822,3397.481201 2143.939209,3393.970703 2137.285156,3392.864502 
	C2133.831787,3392.887939 2129.016357,3389.995117 2130.602783,3396.607666 
	C2131.319336,3399.595215 2132.568604,3402.787109 2129.199463,3404.579834 
	C2125.498291,3406.549316 2122.107666,3404.673096 2119.197510,3402.217773 
	C2117.229980,3400.557617 2116.138428,3397.729248 2112.009766,3397.734131 
	C2115.042480,3400.847168 2119.658447,3403.315918 2116.472900,3407.981445 
	C2113.440430,3412.423096 2108.828369,3412.796387 2103.976318,3411.618164 
	C2100.030273,3410.659668 2096.727539,3408.395020 2092.684570,3406.127930 
z"
        />
        <path
          fill="#1B2B3A"
          opacity="1.000000"
          stroke="none"
          d="
M2117.771729,3297.337402 
	C2115.665283,3300.634766 2112.493896,3300.083496 2109.597656,3299.920410 
	C2101.144043,3299.443848 2092.737305,3300.796875 2084.251953,3300.123291 
	C2078.019043,3299.628418 2071.759521,3299.921143 2065.467285,3301.458984 
	C2060.744385,3302.613525 2055.552979,3301.924805 2050.570557,3301.911377 
	C2048.190186,3301.905029 2045.284668,3301.400146 2045.261108,3298.619385 
	C2045.232178,3295.214600 2048.524414,3296.280518 2050.635986,3296.019775 
	C2051.950684,3295.857422 2053.290771,3295.913086 2054.610352,3295.778564 
	C2055.627930,3295.675293 2056.519043,3295.245850 2056.831543,3294.154541 
	C2057.279541,3292.589600 2056.369629,3291.394043 2055.178467,3290.955322 
	C2049.021729,3288.688232 2043.260742,3284.765869 2035.389648,3286.132812 
	C2033.671387,3283.237305 2034.059204,3280.361328 2035.239502,3276.842773 
	C2045.402588,3267.985352 2056.578125,3269.346191 2067.619629,3272.383057 
	C2073.999268,3274.137939 2080.262695,3276.122314 2087.618408,3277.027588 
	C2084.331787,3273.679443 2079.008545,3271.563965 2081.697021,3266.621826 
	C2084.043457,3262.308350 2088.582520,3263.863281 2092.480957,3264.773682 
	C2082.635010,3261.569824 2072.980957,3257.990967 2062.092285,3259.850342 
	C2052.569092,3261.476318 2044.961182,3256.208984 2037.193481,3250.466553 
	C2036.810059,3249.884766 2036.051147,3249.929932 2035.679199,3250.008789 
	C2034.081421,3248.196533 2033.643188,3246.166748 2034.548218,3243.213867 
	C2035.218506,3242.062012 2035.569580,3241.653320 2036.603027,3240.814941 
	C2038.190796,3239.960938 2039.341309,3239.063965 2040.042114,3239.380615 
	C2053.186035,3245.320557 2067.833496,3243.120605 2081.438965,3247.609131 
	C2109.574463,3256.890869 2133.889648,3277.339111 2163.177734,3275.258057 
	C2159.141357,3273.363281 2151.940186,3273.874512 2145.406738,3271.683105 
	C2142.590576,3270.738525 2139.274414,3269.581543 2139.465088,3265.902100 
	C2139.664551,3262.061035 2143.205811,3261.660400 2146.125488,3261.164795 
	C2149.747314,3260.549805 2153.478516,3260.424072 2157.090088,3260.891357 
	C2171.700195,3262.781006 2186.242920,3263.599365 2200.798828,3260.323975 
	C2204.791748,3259.425537 2208.656250,3261.725830 2212.511719,3261.999268 
	C2232.685059,3263.430664 2251.662842,3271.688965 2271.790283,3272.519531 
	C2283.816406,3273.015869 2295.646729,3274.113525 2308.170898,3276.762207 
	C2314.310303,3283.608887 2315.899658,3290.797607 2312.528809,3299.453613 
	C2308.480957,3300.058838 2305.088623,3300.043945 2300.848145,3300.023193 
	C2298.811035,3299.970215 2297.729248,3299.619629 2296.343994,3298.503418 
	C2295.052979,3298.120850 2294.203613,3298.114746 2292.716797,3298.104248 
	C2287.641602,3302.079590 2282.324951,3299.820312 2276.683105,3300.112793 
	C2275.124268,3300.070801 2274.258545,3300.086182 2272.743164,3300.116699 
	C2255.223389,3302.122559 2238.481445,3305.943848 2221.343262,3304.296631 
	C2213.864990,3303.577881 2213.129639,3300.322266 2217.706299,3290.357666 
	C2197.499512,3282.808105 2171.269531,3289.509277 2158.642578,3305.615723 
	C2153.748779,3304.252441 2148.503174,3305.164062 2144.683105,3300.580811 
	C2141.355225,3297.014404 2137.329102,3297.817871 2133.877930,3297.305664 
	C2117.343262,3294.851318 2100.911377,3291.276123 2085.077637,3292.565918 
	C2091.173340,3293.536377 2098.429932,3292.795410 2105.636719,3293.011475 
	C2109.932861,3293.140381 2114.390137,3292.718750 2117.771729,3297.337402 
z"
        />
        <path
          fill="#A6A29C"
          opacity="1.000000"
          stroke="none"
          d="
M2035.804688,3241.177734 
	C2035.604858,3244.067383 2035.629639,3246.267578 2035.480957,3249.277832 
	C2033.982666,3250.453857 2032.677856,3250.997559 2032.484985,3252.513672 
	C2031.608643,3259.400391 2030.146118,3266.297363 2030.133179,3273.192871 
	C2030.098389,3291.816895 2026.177979,3310.026611 2024.852783,3328.500488 
	C2024.707764,3330.520996 2024.550781,3332.401123 2025.172241,3334.353271 
	C2026.516479,3338.575928 2026.478394,3343.045898 2025.745850,3347.217285 
	C2023.224609,3361.572754 2023.723022,3376.252441 2020.545044,3390.563477 
	C2019.272827,3396.293213 2019.801147,3402.499756 2020.041748,3408.467773 
	C2020.300659,3414.886963 2017.715698,3420.839600 2017.575439,3427.170654 
	C2017.533569,3429.059326 2015.672607,3429.687988 2013.242310,3430.186035 
	C2011.220215,3416.842529 2012.314453,3403.350830 2012.035400,3388.936768 
	C2013.475586,3383.194580 2014.324341,3378.328369 2014.088745,3373.253418 
	C2013.608276,3362.907959 2016.122803,3352.799072 2016.940186,3342.679688 
	C2018.024414,3329.258301 2024.837769,3316.431885 2021.059082,3302.409668 
	C2020.121338,3298.929688 2022.056030,3294.615967 2022.884399,3290.730957 
	C2025.587524,3278.053223 2026.865356,3265.201904 2028.240967,3252.329346 
	C2029.791016,3237.823975 2031.641357,3223.357910 2032.417358,3208.778564 
	C2032.462524,3207.928467 2033.413574,3207.126709 2034.640381,3205.958252 
	C2037.826416,3206.361572 2038.511353,3208.660400 2039.916504,3211.380859 
	C2041.282471,3216.493896 2041.339844,3220.841553 2040.284424,3225.959961 
	C2036.918823,3230.770752 2037.064453,3235.803223 2035.804688,3241.177734 
z"
        />
        <path
          fill="#0D1C2A"
          opacity="1.000000"
          stroke="none"
          d="
M2036.565063,3250.794922 
	C2042.028076,3251.813965 2047.693237,3252.450928 2052.352539,3256.013428 
	C2055.453369,3258.384277 2059.021973,3257.536133 2062.789062,3256.896484 
	C2077.150146,3254.457275 2089.718750,3258.927979 2101.303955,3267.148682 
	C2099.525635,3270.798096 2097.549316,3269.418213 2095.789307,3268.892822 
	C2093.252197,3268.135742 2090.788086,3267.104004 2088.221191,3266.487793 
	C2086.378418,3266.045166 2083.971924,3265.431885 2083.179443,3267.955811 
	C2082.462891,3270.237549 2084.572754,3271.427979 2086.085205,3272.647461 
	C2088.035645,3274.220703 2090.509521,3273.768799 2092.746826,3274.248535 
	C2094.066895,3274.531494 2095.292969,3275.161377 2095.337646,3276.781006 
	C2095.395752,3278.885498 2093.947021,3279.599365 2092.195068,3279.837402 
	C2084.785889,3280.843994 2077.145752,3279.760010 2070.848145,3276.754883 
	C2059.385254,3271.283936 2048.478271,3273.496826 2036.380737,3276.039062 
	C2035.129761,3268.000732 2035.627808,3259.849609 2036.565063,3250.794922 
z"
        />
        <path
          fill="#1B2B3A"
          opacity="1.000000"
          stroke="none"
          d="
M2023.281494,3464.602051 
	C2033.391479,3466.090088 2042.978638,3468.187744 2053.046631,3468.157715 
	C2065.780273,3468.119629 2077.619629,3476.634521 2083.279053,3489.120361 
	C2069.662354,3487.792725 2056.213379,3488.707520 2043.813110,3482.538818 
	C2042.930420,3482.099609 2041.914551,3480.818115 2041.349487,3481.019775 
	C2034.392456,3483.499023 2030.212891,3476.433105 2023.449585,3476.160156 
	C2021.966187,3472.515625 2022.195557,3468.866455 2023.281494,3464.602051 
z"
        />
        <path
          fill="#646A6C"
          opacity="1.000000"
          stroke="none"
          d="
M2020.572266,3502.085449 
	C2023.201904,3513.037598 2020.064941,3524.042480 2019.996460,3535.156738 
	C2019.970215,3539.420166 2019.672241,3543.681885 2019.541016,3548.858643 
	C2019.402954,3550.070312 2019.222046,3550.367676 2018.621948,3550.353516 
	C2015.641846,3548.779297 2013.748535,3547.104248 2015.098267,3543.933350 
	C2016.599121,3540.407471 2015.012695,3537.634766 2012.634766,3534.455566 
	C2012.106201,3532.501221 2012.104492,3530.966064 2012.182861,3528.663574 
	C2014.349121,3522.823975 2015.726929,3517.694580 2012.863647,3512.150879 
	C2012.187622,3511.155518 2012.161255,3510.287109 2012.187378,3508.770996 
	C2017.011963,3509.111328 2017.626099,3504.560791 2020.572266,3502.085449 
z"
        />
        <path
          fill="#646A6C"
          opacity="1.000000"
          stroke="none"
          d="
M2024.579590,3420.166748 
	C2026.068115,3425.499023 2026.050537,3431.110840 2024.407471,3437.339355 
	C2020.927734,3432.146973 2020.968140,3426.308105 2024.579590,3420.166748 
z"
        />
        <path
          fill="#040F1A"
          opacity="1.000000"
          stroke="none"
          d="
M2018.284668,3550.698242 
	C2018.202759,3550.042480 2018.901733,3549.942383 2019.242798,3549.857666 
	C2033.576782,3554.760742 2047.744629,3558.212646 2062.843506,3558.230225 
	C2071.967529,3558.240723 2081.489258,3558.666992 2089.965088,3563.012939 
	C2094.331055,3565.251953 2098.387207,3562.787842 2102.632568,3563.014160 
	C2117.123291,3563.787354 2130.574463,3568.185791 2143.316895,3574.345703 
	C2152.867676,3578.962891 2163.280518,3581.071777 2172.686768,3585.921387 
	C2174.736572,3586.978271 2176.823242,3587.941895 2178.718506,3589.262939 
	C2180.541260,3590.533447 2183.784424,3591.282227 2182.872314,3594.078369 
	C2181.948730,3596.910156 2178.592529,3599.720459 2176.186279,3599.064941 
	C2170.144775,3597.418945 2163.025391,3602.344238 2157.746582,3595.460693 
	C2155.460938,3592.479980 2150.783691,3594.062988 2147.229004,3593.532959 
	C2137.138184,3592.028076 2128.930176,3585.925537 2119.534180,3582.771973 
	C2114.124268,3580.956543 2108.743652,3579.558838 2102.963623,3579.944092 
	C2099.994873,3580.142334 2097.019775,3580.039307 2094.086914,3579.058838 
	C2088.711670,3577.261719 2083.306152,3576.252930 2077.550049,3577.827393 
	C2075.079590,3578.502686 2071.906006,3578.709473 2069.660645,3577.731201 
	C2060.945312,3573.933838 2051.970703,3577.393311 2043.137573,3576.549561 
	C2038.576416,3576.113770 2033.621094,3577.656006 2028.674438,3576.173096 
	C2025.072632,3575.093506 2023.458130,3580.164307 2019.113403,3579.933105 
	C2018.304932,3575.885498 2018.222412,3571.809082 2018.054443,3566.860107 
	C2017.491577,3562.428955 2016.583130,3558.874268 2018.018188,3554.641113 
	C2018.237305,3553.107422 2018.301880,3552.230713 2018.284668,3550.698242 
z"
        />
        <path
          fill="#646A6C"
          opacity="1.000000"
          stroke="none"
          d="
M2017.463867,3554.132080 
	C2020.073608,3557.514648 2019.593872,3561.185303 2018.392334,3565.430664 
	C2013.000854,3561.065186 2012.827148,3559.390381 2017.463867,3554.132080 
z"
        />
        <path
          fill="#2B3E51"
          opacity="1.000000"
          stroke="none"
          d="
M491.355682,2903.636719 
	C490.504059,2906.014404 488.244720,2905.949463 486.065674,2906.075684 
	C483.388580,2906.230713 482.143646,2907.681885 482.162170,2911.026855 
	C478.636841,2912.462891 475.368805,2914.486572 470.788208,2914.016113 
	C467.437988,2911.438232 465.000244,2909.030029 461.233490,2912.572266 
	C459.347778,2914.345703 457.817200,2912.578857 456.274536,2910.514648 
	C463.915253,2900.212891 480.084717,2897.104736 491.355682,2903.636719 
z"
        />
        <path
          fill="#233444"
          opacity="1.000000"
          stroke="none"
          d="
M280.481750,2946.618652 
	C286.706726,2943.857422 292.383759,2937.381104 301.185944,2941.462891 
	C302.186676,2945.759033 305.446716,2945.687500 308.225952,2946.523193 
	C310.194641,2947.114990 311.759094,2948.627686 310.988983,2950.938721 
	C310.297180,2953.015137 308.320984,2954.160400 306.303436,2953.564941 
	C297.933563,2951.094482 288.671051,2952.164062 280.481750,2946.618652 
z"
        />
        <path
          fill="#243748"
          opacity="1.000000"
          stroke="none"
          d="
M498.715027,2956.288818 
	C502.601410,2953.067871 507.053711,2952.759766 511.632874,2957.348145 
	C507.787628,2957.739990 503.434875,2958.772705 498.715027,2956.288818 
z"
        />
        <path
          fill="#243748"
          opacity="1.000000"
          stroke="none"
          d="
M513.937988,2945.316406 
	C512.265991,2945.957275 510.437164,2946.145508 508.425232,2944.467285 
	C510.260132,2941.601318 512.259583,2941.380859 513.937988,2945.316406 
z"
        />
        <path
          fill="#233444"
          opacity="1.000000"
          stroke="none"
          d="
M308.307373,2941.708008 
	C308.147797,2940.021484 308.833405,2938.535156 311.465942,2938.276367 
	C311.757751,2940.073975 311.030975,2941.514160 308.307373,2941.708008 
z"
        />
        <path
          fill="#465F75"
          opacity="1.000000"
          stroke="none"
          d="
M2003.541748,2674.416016 
	C1999.733032,2673.818115 1998.049316,2670.902588 1994.686523,2669.762695 
	C1993.181030,2668.997314 1992.463379,2668.266602 1991.652588,2666.605957 
	C1990.989014,2664.270996 1991.027588,2662.726562 1992.648926,2660.832520 
	C1997.324829,2658.520020 2001.596313,2658.784180 2006.634521,2658.971680 
	C2017.865234,2656.900146 2028.235596,2653.630859 2039.779785,2656.848633 
	C2054.723633,2656.056396 2068.946045,2658.720703 2082.820557,2657.315186 
	C2071.810303,2654.134277 2059.962158,2656.569336 2047.988525,2653.207520 
	C2044.913818,2649.350098 2042.125488,2648.136230 2037.407349,2648.526367 
	C2022.465088,2649.761475 2007.595947,2653.119385 1991.685059,2650.950195 
	C1989.860107,2650.923340 1988.816772,2650.912598 1986.989014,2650.898438 
	C1984.113892,2650.919922 1982.034790,2650.837891 1979.309082,2649.933105 
	C1977.260376,2648.935303 1976.292114,2647.861572 1975.468506,2645.843262 
	C1975.187012,2644.155273 1976.155396,2642.955322 1974.252930,2641.992676 
	C1972.820557,2641.769775 1971.978271,2641.612061 1970.449829,2641.247070 
	C1948.162720,2635.361816 1926.141724,2635.664062 1904.320923,2638.146729 
	C1892.331299,2639.510986 1881.151733,2636.747314 1869.617432,2635.688965 
	C1868.627319,2635.598145 1867.361328,2635.418945 1866.878540,2634.633301 
	C1859.950806,2623.359619 1851.549072,2629.210205 1843.452271,2632.635986 
	C1840.605347,2633.840332 1837.834839,2634.676514 1833.987915,2633.733398 
	C1831.079956,2632.177490 1830.248291,2630.202148 1830.271729,2626.977783 
	C1832.123413,2620.895264 1836.369263,2619.852295 1841.239868,2618.946289 
	C1859.416260,2615.565674 1877.666992,2621.419678 1895.805908,2618.221436 
	C1917.480347,2628.252197 1940.600098,2619.591797 1963.586548,2623.566406 
	C1988.486206,2629.875977 2012.244995,2635.431885 2037.710449,2629.800781 
	C2051.319580,2626.791504 2065.916748,2626.583984 2080.948730,2627.270996 
	C2082.813232,2625.869629 2088.544434,2633.010986 2086.308105,2624.680176 
	C2085.667480,2622.292969 2089.583984,2622.212891 2091.625488,2621.943359 
	C2085.691650,2620.385254 2079.231689,2621.633545 2073.062500,2620.151123 
	C2069.563232,2619.310547 2065.719238,2619.001953 2063.821777,2614.657471 
	C2062.580811,2610.994873 2061.756836,2608.182373 2066.621826,2608.051270 
	C2071.156250,2607.929443 2075.695801,2608.002197 2081.126953,2608.009521 
	C2090.359863,2613.808594 2099.826416,2614.451660 2109.393311,2613.393555 
	C2126.342529,2611.518066 2142.859131,2613.803955 2158.825684,2617.308594 
	C2156.028320,2615.492188 2152.229248,2616.602051 2148.807129,2615.803711 
	C2144.927490,2614.898682 2139.869873,2615.104980 2139.538086,2608.698975 
	C2149.298096,2609.663086 2158.645752,2607.677734 2168.012939,2607.950684 
	C2173.065186,2608.098389 2177.778320,2608.638916 2181.631836,2613.436035 
	C2183.218018,2617.118896 2180.414795,2617.681641 2178.486084,2619.520508 
	C2174.794922,2624.090088 2178.168701,2625.092285 2181.028076,2625.727783 
	C2184.775879,2626.560791 2188.845947,2625.270020 2193.182129,2627.583984 
	C2197.583740,2635.838135 2191.753174,2636.459961 2186.735840,2636.364746 
	C2177.827637,2636.195557 2168.980713,2637.718262 2161.887695,2637.034668 
	C2171.422607,2636.432373 2182.645996,2637.066650 2193.546875,2640.561035 
	C2196.613037,2641.543701 2201.025635,2642.033203 2200.489746,2646.271973 
	C2199.902588,2650.917969 2195.298096,2650.847900 2191.715576,2650.743408 
	C2182.913330,2650.486572 2174.188965,2647.727539 2165.260986,2650.500732 
	C2180.076416,2650.687012 2194.841064,2651.462402 2210.154297,2653.553223 
	C2211.598389,2655.479248 2211.772461,2657.016113 2211.125000,2659.356201 
	C2191.225586,2668.788818 2171.554932,2668.122803 2151.906250,2660.447998 
	C2143.777588,2657.273193 2135.734375,2654.104980 2126.457031,2656.705078 
	C2133.499756,2656.940430 2140.216797,2657.536133 2146.661865,2659.702148 
	C2148.576660,2660.345947 2150.447754,2661.371826 2150.598633,2663.703125 
	C2150.790527,2666.671631 2148.507568,2667.709229 2146.229004,2668.325195 
	C2140.989502,2669.741699 2135.567871,2669.695068 2130.368164,2668.595215 
	C2117.007812,2665.769043 2103.830811,2668.537354 2090.584229,2669.297852 
	C2089.251953,2669.374512 2087.494629,2669.238770 2086.849121,2670.322266 
	C2080.729004,2680.590820 2071.187500,2677.067627 2062.695801,2675.957764 
	C2051.487061,2674.493164 2040.178101,2673.734375 2029.054077,2671.531006 
	C2026.656738,2671.056152 2023.174927,2669.835449 2021.699951,2672.692871 
	C2016.725830,2682.329346 2010.487061,2677.863770 2003.541748,2674.416016 
z"
        />
        <path
          fill="#0C0D0F"
          opacity="1.000000"
          stroke="none"
          d="
M1831.460083,2625.470215 
	C1831.771118,2627.911377 1832.141968,2629.668701 1832.882690,2631.989258 
	C1835.289429,2633.445557 1836.699219,2635.322510 1836.331055,2637.203857 
	C1834.684570,2645.618408 1837.786743,2654.203125 1835.093018,2663.337158 
	C1832.007446,2674.164307 1833.424805,2685.358398 1826.438721,2695.040039 
	C1823.681885,2698.037598 1820.738647,2699.750977 1818.188965,2702.908691 
	C1817.283081,2703.626465 1816.901978,2703.885010 1815.909668,2704.448242 
	C1810.880127,2706.853027 1806.961426,2709.921875 1801.616577,2711.580566 
	C1797.532593,2711.531494 1794.304443,2712.165527 1790.875732,2714.510254 
	C1789.865356,2714.868408 1789.439331,2714.949463 1788.364868,2714.998535 
	C1782.539062,2713.357422 1780.621094,2719.287354 1775.699707,2720.008301 
	C1773.542603,2720.282715 1772.112793,2720.085205 1770.019287,2719.364258 
	C1764.099609,2715.154053 1758.382568,2713.087158 1751.698853,2713.792480 
	C1747.891602,2714.194580 1742.990479,2715.616455 1741.368042,2710.648926 
	C1739.840088,2705.969971 1744.490479,2703.912598 1747.390381,2701.364502 
	C1748.633423,2700.272217 1749.966553,2699.279785 1751.805786,2697.782715 
	C1760.652466,2697.384033 1759.651978,2689.712646 1762.865234,2684.753906 
	C1763.259277,2683.275635 1766.897339,2685.333740 1764.541016,2681.965820 
	C1768.207642,2674.115479 1766.339111,2666.396484 1766.179443,2658.118652 
	C1766.592896,2657.033936 1766.817505,2656.621582 1767.549194,2655.715332 
	C1776.598145,2648.295410 1787.404663,2654.352539 1796.412476,2650.003418 
	C1792.849731,2644.517090 1787.405273,2640.774414 1781.932007,2637.122559 
	C1775.786621,2633.022949 1774.980469,2627.883301 1778.281616,2621.593018 
	C1780.811768,2616.771729 1784.243896,2612.625244 1788.580078,2609.526611 
	C1796.171265,2604.102051 1802.510620,2606.710205 1804.312500,2615.755371 
	C1804.661377,2617.506592 1804.992798,2618.936523 1807.181396,2620.053955 
	C1808.492188,2620.574463 1808.791992,2621.741455 1809.616577,2621.560303 
	C1810.279541,2621.414795 1810.029541,2620.957275 1808.543823,2620.649170 
	C1803.094849,2613.962891 1803.263428,2606.777344 1805.563477,2599.780273 
	C1807.246704,2594.659668 1806.352783,2590.753174 1802.566895,2586.407959 
	C1801.712402,2584.420654 1801.438843,2583.015869 1801.287720,2580.863770 
	C1801.197388,2578.163086 1801.540405,2576.220947 1801.918945,2574.280762 
	C1804.833008,2559.344971 1804.831543,2559.344727 1791.061890,2552.429443 
	C1786.570190,2550.173340 1781.784790,2535.989746 1784.194336,2529.778076 
	C1785.096436,2527.452148 1784.856689,2525.408203 1785.661621,2522.739746 
	C1787.104492,2521.032959 1788.063354,2519.829834 1789.505737,2518.294434 
	C1795.098145,2518.874756 1800.121582,2514.623291 1805.323608,2517.847412 
	C1808.335571,2519.714111 1812.547852,2520.623535 1808.960083,2525.647949 
	C1807.918945,2527.105957 1808.700684,2528.874268 1810.614258,2529.541992 
	C1812.525513,2530.209229 1814.208496,2531.149902 1815.826172,2533.352539 
	C1816.177856,2534.663574 1816.406982,2535.306885 1817.365479,2536.072266 
	C1817.853882,2536.280273 1818.617432,2536.273193 1818.988281,2536.182617 
	C1824.956177,2540.738281 1823.118286,2547.142578 1823.238281,2553.919922 
	C1823.049805,2559.894043 1822.300293,2564.922852 1821.250854,2570.731934 
	C1820.967285,2576.252197 1821.282715,2580.957764 1821.847168,2586.403320 
	C1822.938354,2590.117188 1822.564087,2593.270020 1823.514404,2597.010254 
	C1823.657349,2598.817871 1823.440186,2599.812012 1822.542603,2601.383301 
	C1822.322266,2603.138916 1822.545288,2604.265625 1823.180786,2605.804688 
	C1826.655029,2607.952148 1828.512573,2610.634277 1829.860107,2614.562988 
	C1830.886353,2618.367188 1831.241821,2621.571045 1831.460083,2625.470215 
z"
        />
        <path
          fill="#334D63"
          opacity="1.000000"
          stroke="none"
          d="
M2210.693848,2654.758789 
	C2203.222412,2655.964844 2196.545166,2656.408447 2189.440674,2654.569336 
	C2184.758789,2653.357178 2179.173828,2653.155762 2174.615479,2654.544434 
	C2168.258301,2656.480713 2163.013428,2654.674561 2156.595947,2652.896484 
	C2160.631348,2646.971924 2164.043945,2643.419922 2171.916260,2645.569824 
	C2177.786377,2647.172852 2184.394531,2646.390869 2190.618896,2647.775391 
	C2193.205811,2648.350830 2196.382812,2648.663818 2199.481445,2645.968018 
	C2182.928711,2638.691406 2166.019775,2640.186035 2148.091309,2639.791016 
	C2151.312500,2634.170166 2155.176270,2633.659424 2159.012207,2634.098145 
	C2168.910645,2635.229980 2178.703857,2631.290527 2188.645752,2633.793457 
	C2191.591553,2634.535400 2193.837402,2632.986572 2193.891357,2628.697021 
	C2196.822266,2627.738281 2199.881592,2628.033447 2202.667480,2627.295898 
	C2208.206787,2625.829102 2213.484375,2626.256836 2219.110352,2627.082031 
	C2226.110840,2628.109375 2233.337158,2627.598633 2241.148682,2627.782715 
	C2250.182861,2623.844727 2257.075439,2617.584961 2265.566406,2612.561523 
	C2272.168701,2612.481445 2277.874756,2609.026367 2284.884766,2610.362793 
	C2287.583008,2611.863037 2288.065430,2613.844238 2287.780518,2616.812500 
	C2286.598389,2621.103027 2283.378418,2623.220947 2281.710938,2626.393799 
	C2296.233398,2631.039062 2311.812744,2628.760010 2326.757812,2634.312988 
	C2334.574219,2638.830078 2342.913574,2636.892822 2350.523438,2639.058838 
	C2355.939209,2640.600098 2363.741455,2641.321045 2363.258301,2647.377441 
	C2362.854736,2652.433594 2354.867676,2651.603271 2350.194092,2651.013428 
	C2327.482422,2648.147217 2304.458008,2651.938721 2281.839844,2647.549805 
	C2277.933350,2646.791748 2274.053955,2645.989502 2270.839355,2644.973877 
	C2277.508301,2648.063477 2285.759277,2647.595459 2293.437988,2651.578857 
	C2293.928223,2654.257080 2293.958984,2656.332031 2293.986572,2659.185547 
	C2292.843750,2661.281982 2291.316895,2662.347412 2289.681152,2662.149902 
	C2271.212158,2659.919434 2252.474121,2665.919922 2234.168945,2660.526611 
	C2226.643066,2658.309082 2217.998047,2661.243408 2210.693848,2654.758789 
z"
        />
        <path
          fill="#4B6377"
          opacity="1.000000"
          stroke="none"
          d="
M1822.725708,2601.947266 
	C1821.949463,2601.138916 1821.799805,2600.265381 1821.697144,2598.724365 
	C1821.038452,2593.041504 1820.303467,2588.013184 1827.746338,2586.129150 
	C1829.871948,2585.474365 1831.327881,2585.259277 1833.522217,2585.152832 
	C1846.761353,2585.396484 1859.563721,2582.975830 1869.362549,2588.218750 
	C1868.323120,2587.873291 1864.406494,2587.479004 1865.557617,2581.883057 
	C1868.076172,2579.539551 1870.601440,2579.338379 1873.947632,2579.437500 
	C1881.934204,2580.198730 1889.212158,2581.150635 1895.801270,2574.966797 
	C1900.781006,2572.280029 1904.931519,2574.829590 1909.981934,2575.010742 
	C1924.584961,2573.812500 1937.766357,2575.902832 1951.002808,2580.244873 
	C1964.104004,2584.542725 1978.004272,2581.267334 1991.542847,2581.671631 
	C1993.474854,2581.729248 1995.454712,2580.889160 1997.464966,2581.065918 
	C2001.838379,2581.450684 2007.512207,2581.883545 2007.515991,2586.985107 
	C2007.518921,2591.070312 2001.727417,2590.550049 1998.376465,2590.777588 
	C1993.409790,2591.114990 1988.455444,2591.592773 1983.473755,2592.409912 
	C2001.549194,2587.135986 2019.060547,2591.342529 2036.585571,2595.201660 
	C2039.542358,2595.852783 2042.550781,2596.591064 2042.463379,2601.189453 
	C2038.853638,2603.989990 2035.901978,2606.752441 2030.843262,2606.068848 
	C2008.738037,2601.516846 1987.126587,2600.105957 1964.667358,2601.507324 
	C1959.127197,2605.441162 1953.392578,2605.159912 1948.861938,2602.839111 
	C1940.149048,2598.375977 1931.372314,2599.587646 1922.645752,2600.928955 
	C1907.603516,2603.240723 1892.600464,2605.645020 1877.351685,2605.986572 
	C1875.360840,2606.031006 1872.548950,2606.725098 1871.504517,2605.701904 
	C1864.310059,2598.654785 1855.462280,2602.365234 1847.355347,2601.448486 
	C1846.720947,2601.376465 1846.124878,2600.965820 1844.808228,2600.390625 
	C1839.270874,2596.547119 1834.124878,2597.750000 1828.490479,2599.530762 
	C1826.457275,2600.620850 1824.904785,2601.251709 1822.725708,2601.947266 
z"
        />
        <path
          fill="#3E5871"
          opacity="1.000000"
          stroke="none"
          d="
M2251.087402,2565.968994 
	C2264.604492,2568.667480 2277.152100,2570.300049 2289.629395,2564.937256 
	C2293.684326,2563.194336 2298.317139,2563.182861 2302.198730,2565.330078 
	C2307.819092,2568.438721 2313.734863,2568.023926 2319.678223,2567.948486 
	C2328.183838,2567.840820 2336.620605,2568.010742 2344.575684,2572.344238 
	C2334.260986,2574.997803 2323.796387,2574.000488 2312.371338,2572.528076 
	C2315.102295,2577.861328 2318.970947,2577.972412 2322.893066,2577.978027 
	C2331.196045,2577.989746 2339.520264,2578.374268 2347.796143,2577.906982 
	C2355.222412,2577.487305 2362.185059,2580.030518 2369.471680,2580.347656 
	C2372.035889,2580.459229 2374.632080,2581.727295 2375.855225,2585.349609 
	C2361.762695,2585.464600 2347.456299,2587.271729 2333.273193,2584.274658 
	C2329.160400,2583.405762 2324.650879,2583.536865 2320.422363,2584.868164 
	C2314.232666,2586.816162 2307.709961,2586.740234 2301.636719,2585.671143 
	C2287.708984,2583.219238 2273.986816,2583.283203 2260.131104,2585.752930 
	C2256.908203,2586.327637 2253.496338,2585.917725 2250.174805,2585.830811 
	C2248.404785,2585.784668 2246.738525,2585.020752 2246.712891,2583.043457 
	C2246.687012,2581.061279 2248.348877,2580.250000 2250.105713,2580.201416 
	C2255.756104,2580.045410 2261.413818,2580.116943 2267.060791,2579.895264 
	C2269.567139,2579.797119 2272.371826,2580.855713 2275.061523,2578.007324 
	C2272.550293,2574.227783 2268.953125,2573.401855 2265.041748,2574.732422 
	C2260.461426,2576.290527 2255.818848,2576.218262 2251.225830,2575.898193 
	C2240.537354,2575.152832 2230.240234,2578.803955 2219.514160,2578.132568 
	C2210.920410,2577.594482 2202.278809,2577.924316 2192.963867,2575.895020 
	C2189.525879,2571.796143 2192.934082,2570.851807 2195.448730,2569.544922 
	C2198.381592,2568.020020 2201.834717,2568.356689 2205.465820,2566.609375 
	C2211.983887,2567.992676 2218.033936,2568.243408 2225.005859,2567.829590 
	C2228.407959,2565.607910 2231.514160,2566.097412 2235.247070,2565.975098 
	C2240.733887,2565.966064 2245.467773,2565.970215 2251.087402,2565.968994 
z"
        />
        <path
          fill="#395166"
          opacity="1.000000"
          stroke="none"
          d="
M2043.004761,2601.931641 
	C2041.469604,2598.509766 2039.012329,2597.917725 2036.125732,2597.996582 
	C2028.758057,2598.197754 2021.773315,2597.909912 2015.235474,2593.185547 
	C2011.490234,2590.479248 2006.293091,2591.939209 2001.658081,2593.041504 
	C1993.873657,2594.892090 1978.008789,2594.296143 1970.053589,2591.766846 
	C1976.615112,2588.631592 1982.808594,2591.071045 1988.158203,2588.993652 
	C1993.842896,2586.786133 2000.055176,2589.069092 2006.054688,2587.303223 
	C2003.513672,2583.378906 1999.645142,2583.901611 1996.129395,2584.024658 
	C1985.532593,2584.395508 1974.879150,2582.929688 1964.332397,2585.694824 
	C1960.601318,2586.672852 1956.104492,2586.984131 1952.536865,2585.023438 
	C1939.712646,2577.976807 1925.829834,2577.740723 1910.887695,2577.935547 
	C1901.529175,2570.684082 1892.475220,2574.384033 1883.856567,2576.987305 
	C1878.933472,2578.474365 1874.196411,2579.240723 1869.229248,2578.639893 
	C1866.183350,2578.271240 1863.200562,2577.154785 1862.844116,2573.470215 
	C1862.480713,2569.713135 1865.140747,2568.097168 1868.248413,2567.467285 
	C1879.883301,2565.108887 1891.220459,2561.318115 1903.554077,2563.337646 
	C1913.002930,2564.884766 1922.619385,2563.158447 1932.789795,2561.364746 
	C1939.694824,2559.257080 1945.101074,2564.158936 1951.845459,2563.508789 
	C1955.333374,2572.808838 1948.905273,2571.593994 1943.447266,2572.273438 
	C1953.572144,2574.120850 1964.217285,2572.846924 1973.582275,2579.434570 
	C1984.266968,2581.215332 1994.116943,2577.588379 2005.100342,2578.084229 
	C2012.884033,2580.096191 2019.896606,2578.299805 2026.848267,2578.715088 
	C2033.888306,2579.135742 2040.758179,2580.064453 2047.736084,2583.976562 
	C2054.936035,2591.632324 2064.637207,2589.957031 2072.638672,2593.050537 
	C2077.104736,2594.777100 2082.534180,2594.000244 2085.984375,2599.080322 
	C2083.365723,2602.861816 2079.845459,2601.890137 2076.656250,2601.920898 
	C2065.750000,2602.026123 2054.841797,2601.957275 2043.004761,2601.931641 
z"
        />
        <path
          fill="#334D63"
          opacity="1.000000"
          stroke="none"
          d="
M1832.091553,2625.724121 
	C1828.414062,2624.028564 1827.770996,2620.915527 1827.780762,2616.815674 
	C1833.432495,2611.535400 1839.848633,2611.741943 1847.151855,2613.602295 
	C1853.254883,2614.151367 1858.651245,2614.153809 1864.942383,2614.167480 
	C1867.787964,2616.142822 1870.313843,2615.994385 1873.395996,2616.108887 
	C1876.783691,2616.103027 1879.554688,2616.056152 1883.157227,2615.995605 
	C1895.381470,2610.553467 1907.211914,2612.975342 1918.942749,2614.153076 
	C1920.537964,2614.313477 1922.118164,2615.037109 1923.802002,2614.990479 
	C1921.406982,2614.970947 1919.321167,2614.538330 1919.579102,2610.740234 
	C1926.095093,2607.098877 1932.267700,2607.717285 1939.236084,2609.879150 
	C1943.753418,2613.957520 1948.063599,2615.203369 1953.527466,2612.294189 
	C1960.124023,2607.137451 1965.708618,2607.742920 1971.309814,2613.947754 
	C1973.831543,2614.652588 1968.571289,2616.662109 1972.756592,2617.121826 
	C1978.981812,2617.805420 1985.351685,2617.236328 1991.441650,2619.125000 
	C1993.387939,2619.728760 1995.774170,2620.590088 1995.871826,2622.604492 
	C1996.010132,2625.452637 1993.185669,2626.063477 1990.934937,2626.556885 
	C1982.329712,2628.442383 1973.783325,2625.991699 1964.453613,2626.136719 
	C1948.015625,2628.208984 1932.378052,2624.300537 1916.632935,2625.806641 
	C1911.666626,2626.281250 1906.948364,2628.069824 1901.956909,2627.910400 
	C1900.115723,2627.851562 1896.615112,2627.968506 1897.453003,2625.711914 
	C1900.859131,2616.539062 1894.877075,2621.568359 1891.891235,2621.613281 
	C1882.949829,2621.747559 1873.696045,2623.170410 1865.143921,2621.356934 
	C1855.252686,2619.259277 1846.167969,2623.175537 1836.660522,2622.455811 
	C1835.446533,2622.364014 1834.079102,2624.298584 1832.091553,2625.724121 
z"
        />
        <path
          fill="#395166"
          opacity="1.000000"
          stroke="none"
          d="
M2160.363770,2599.378906 
	C2140.585205,2598.907227 2121.303711,2601.751953 2101.934082,2601.932617 
	C2098.357422,2601.966064 2094.677246,2602.569580 2090.612793,2600.454834 
	C2087.666748,2593.000732 2092.877441,2592.229492 2097.263916,2591.421387 
	C2102.453125,2590.465088 2107.833984,2591.457520 2113.050781,2590.781982 
	C2117.285645,2590.233643 2113.774414,2588.317383 2112.996094,2586.327148 
	C2112.242920,2584.149414 2112.368408,2582.677734 2113.551025,2580.649414 
	C2137.427490,2578.738525 2160.663818,2582.998535 2184.981201,2582.112549 
	C2194.176758,2586.903564 2203.500488,2586.803955 2213.280762,2587.927246 
	C2216.665039,2589.387451 2219.478516,2590.208252 2223.302734,2589.925537 
	C2226.187500,2597.258545 2220.157471,2596.544189 2216.461182,2596.903564 
	C2208.201416,2597.706787 2199.841797,2597.780518 2191.593262,2596.553711 
	C2179.651855,2594.777588 2167.736572,2592.938477 2156.639648,2594.177734 
	C2158.494141,2594.358154 2160.865479,2595.185547 2160.363770,2599.378906 
z"
        />
        <path
          fill="#465F75"
          opacity="1.000000"
          stroke="none"
          d="
M2160.521240,2600.481689 
	C2159.490723,2596.569580 2157.054443,2596.156738 2154.182861,2595.969482 
	C2149.971191,2595.695068 2145.586670,2596.983887 2141.602539,2594.887451 
	C2141.461670,2593.672607 2141.753418,2592.472656 2142.787354,2592.420410 
	C2159.151611,2591.590332 2175.402344,2589.115479 2191.875488,2593.283936 
	C2201.080078,2595.613525 2211.002930,2594.084473 2220.603760,2593.757812 
	C2222.031982,2593.709473 2222.965088,2592.726562 2223.830566,2590.710938 
	C2228.336182,2587.402344 2233.228027,2587.212158 2237.640381,2588.251221 
	C2248.843262,2590.888916 2260.126953,2589.641602 2271.374756,2589.999023 
	C2275.552246,2590.131592 2279.956787,2589.541748 2283.547363,2594.658936 
	C2269.072510,2597.746582 2255.383301,2599.498535 2241.537842,2596.513184 
	C2240.455322,2600.709717 2247.371338,2600.137939 2245.044189,2604.035400 
	C2243.157227,2607.195557 2239.619873,2605.992432 2236.825195,2605.896973 
	C2228.897949,2605.626465 2220.859131,2607.226318 2213.054688,2604.667480 
	C2210.646973,2603.878418 2208.530029,2605.105225 2206.270752,2605.506836 
	C2202.346436,2606.204102 2197.894531,2607.198486 2194.584717,2604.834229 
	C2184.570312,2597.680664 2173.811279,2599.683105 2162.967041,2601.236816 
	C2162.350830,2601.325195 2161.685059,2601.069092 2160.521240,2600.481689 
z"
        />
        <path
          fill="#294159"
          opacity="1.000000"
          stroke="none"
          d="
M1964.294434,2626.679932 
	C1966.265381,2623.258057 1969.735718,2624.041992 1972.950317,2624.004639 
	C1979.840820,2623.925049 1986.732666,2623.980469 1995.389526,2623.980469 
	C1984.538818,2618.166992 1973.701416,2621.552979 1964.529541,2616.892578 
	C1965.959595,2614.911621 1969.140503,2616.780029 1971.502686,2614.599365 
	C1974.975464,2613.761719 1977.695801,2614.960205 1981.226929,2615.721680 
	C1983.604980,2615.945312 1985.217041,2616.344482 1987.406494,2616.448242 
	C1993.361450,2616.173584 1998.747437,2616.147949 2005.028442,2616.166016 
	C2009.119629,2618.711670 2012.884644,2617.926025 2016.472412,2617.954590 
	C2035.442383,2618.105469 2054.395996,2618.732178 2073.165039,2621.620605 
	C2077.648682,2622.310547 2083.604248,2621.807861 2084.410156,2629.288086 
	C2064.523193,2631.405518 2044.973755,2631.110596 2025.628540,2635.313965 
	C2013.272095,2637.999023 2000.447266,2635.776611 1988.052124,2633.018799 
	C1980.278687,2631.289551 1972.599854,2629.134521 1964.294434,2626.679932 
z"
        />
        <path
          fill="#465F75"
          opacity="1.000000"
          stroke="none"
          d="
M2048.800781,2586.660645 
	C2037.688232,2582.792969 2027.143433,2581.392090 2016.279175,2581.872070 
	C2012.966064,2582.018799 2008.981567,2583.470703 2006.205322,2578.863525 
	C2014.708130,2575.530273 2023.484009,2570.738037 2032.138062,2570.948486 
	C2043.304077,2571.220459 2054.661621,2571.076416 2065.792969,2572.537109 
	C2071.564453,2573.294434 2075.963623,2578.473877 2082.229248,2579.029785 
	C2084.169189,2579.201660 2083.860352,2582.332520 2084.056885,2585.077148 
	C2072.849609,2592.895996 2061.173096,2589.176758 2048.800781,2586.660645 
z"
        />
        <path
          fill="#395166"
          opacity="1.000000"
          stroke="none"
          d="
M2084.532959,2630.538574 
	C2083.635742,2627.233643 2081.858398,2626.372559 2079.240723,2625.980225 
	C2071.756348,2624.857666 2064.256836,2623.720215 2056.867676,2622.109619 
	C2050.271729,2620.672119 2043.772217,2626.632324 2039.261230,2624.888672 
	C2034.069214,2622.882080 2029.743042,2619.177002 2023.476196,2619.910645 
	C2019.556519,2620.369141 2015.535278,2619.970215 2011.559692,2619.934326 
	C2009.541138,2619.916016 2007.557129,2619.771729 2006.172729,2616.955078 
	C2012.048340,2615.593018 2017.947021,2614.837158 2024.075073,2613.049805 
	C2033.345947,2610.345947 2043.680908,2611.165283 2053.478027,2612.924561 
	C2056.412598,2613.451904 2059.288574,2614.008789 2063.132812,2613.993164 
	C2069.274658,2617.152832 2075.080078,2618.000732 2081.106934,2618.000977 
	C2088.332520,2618.000977 2095.208252,2619.840088 2103.788818,2621.787109 
	C2097.873779,2625.572266 2092.705811,2622.784424 2088.214355,2624.569336 
	C2089.211426,2628.886475 2093.901367,2626.820312 2096.555176,2629.456299 
	C2093.041992,2633.118896 2089.069336,2632.193115 2084.532959,2630.538574 
z"
        />
        <path
          fill="#49647C"
          opacity="1.000000"
          stroke="none"
          d="
M2205.323242,2566.006592 
	C2205.202148,2570.500488 2201.742676,2571.353271 2198.145020,2571.919189 
	C2195.979248,2572.260010 2193.489258,2571.736084 2192.256836,2575.153320 
	C2175.164062,2572.521729 2158.020264,2574.435303 2140.963135,2574.051025 
	C2136.215820,2573.943604 2131.669434,2573.994141 2128.366211,2568.768555 
	C2127.049805,2567.244385 2125.907227,2566.538086 2125.728516,2564.619141 
	C2138.727539,2564.680664 2151.465088,2565.341797 2165.111084,2566.016113 
	C2167.523682,2567.681641 2169.197754,2568.225342 2171.585449,2566.400146 
	C2178.072510,2564.265869 2184.418701,2564.122070 2190.557617,2562.481445 
	C2195.398438,2561.187500 2199.877197,2564.675537 2205.323242,2566.006592 
z"
        />
        <path
          fill="#294159"
          opacity="1.000000"
          stroke="none"
          d="
M2048.054199,2586.821289 
	C2059.418213,2586.020752 2070.807373,2586.012451 2083.139160,2585.934814 
	C2088.792725,2585.890869 2093.503418,2585.916260 2099.098145,2585.891357 
	C2104.203125,2583.963135 2108.429688,2582.181152 2113.504395,2585.554688 
	C2117.097656,2587.179688 2120.126953,2588.300781 2123.098145,2589.400635 
	C2122.461914,2594.029053 2119.210938,2593.896484 2116.262451,2593.952637 
	C2110.274170,2594.067139 2104.283203,2594.021484 2098.293213,2594.025146 
	C2094.832275,2594.027344 2091.538574,2594.307861 2090.139160,2599.113770 
	C2089.112793,2599.899170 2088.209229,2599.921387 2086.627441,2599.909912 
	C2083.734131,2597.209229 2080.485352,2597.978271 2077.702148,2597.657959 
	C2068.704834,2596.622070 2060.616699,2591.708252 2051.378418,2591.727783 
	C2049.010498,2591.732666 2048.269043,2589.724121 2048.054199,2586.821289 
z"
        />
        <path
          fill="#334D63"
          opacity="1.000000"
          stroke="none"
          d="
M2057.364746,2553.306885 
	C2058.740967,2556.143555 2058.944580,2557.865234 2055.804688,2557.975342 
	C2052.860107,2558.078125 2049.808350,2557.469971 2046.507324,2559.517822 
	C2027.294312,2560.063721 2008.594971,2560.084717 1988.959961,2560.104248 
	C1980.554321,2563.828369 1973.006836,2561.565430 1964.605225,2560.093994 
	C1960.855469,2558.197510 1958.503662,2556.281494 1959.874268,2551.709961 
	C1971.989868,2546.332764 1984.033081,2547.759766 1995.703857,2549.252930 
	C2008.284912,2550.862305 2020.686035,2551.352295 2033.286133,2550.272461 
	C2041.167114,2549.597168 2049.176025,2549.309570 2057.364746,2553.306885 
z"
        />
        <path
          fill="#465F75"
          opacity="1.000000"
          stroke="none"
          d="
M1974.120850,2579.115234 
	C1964.539551,2579.180420 1955.429443,2575.398926 1945.647949,2575.778320 
	C1941.458862,2575.941162 1939.059082,2571.591064 1933.730835,2571.197510 
	C1937.935913,2566.812988 1941.300171,2568.258545 1944.643921,2569.207520 
	C1948.138672,2570.199463 1951.425049,2570.611816 1951.947754,2564.787354 
	C1961.987305,2557.687500 1972.181519,2562.734375 1983.141357,2563.789795 
	C1989.283203,2565.927490 1994.961304,2565.813477 2001.438721,2566.761963 
	C1998.369629,2571.610352 1994.147827,2571.972900 1990.151855,2571.886963 
	C1985.707031,2571.791260 1981.702393,2573.384766 1977.489014,2574.141602 
	C1975.137817,2574.564209 1974.219238,2576.126709 1974.120850,2579.115234 
z"
        />
        <path
          fill="#395166"
          opacity="1.000000"
          stroke="none"
          d="
M2139.005859,2608.082275 
	C2141.101318,2613.324707 2145.913086,2613.305664 2149.445312,2613.663818 
	C2156.349609,2614.363770 2163.041992,2616.803955 2170.095459,2616.206299 
	C2171.062012,2616.124023 2171.983398,2616.577881 2172.725830,2618.589355 
	C2168.696289,2620.963135 2164.123047,2620.396484 2159.971436,2619.489258 
	C2144.966797,2616.209473 2129.721924,2614.718262 2114.587402,2616.353516 
	C2104.778809,2617.413330 2095.658936,2616.546143 2086.362305,2614.095215 
	C2083.762451,2613.409668 2082.139648,2612.377930 2082.044678,2608.800293 
	C2089.954590,2607.543945 2097.679688,2611.440430 2105.564453,2609.671875 
	C2115.704590,2607.397705 2125.900879,2608.128418 2136.098877,2608.020752 
	C2136.758789,2608.013916 2137.419189,2608.039795 2139.005859,2608.082275 
z"
        />
        <path
          fill="#395166"
          opacity="1.000000"
          stroke="none"
          d="
M1964.794678,2602.022217 
	C1964.422241,2599.900391 1965.408081,2598.208008 1967.790771,2598.161865 
	C1987.909302,2597.770752 2008.062378,2597.017090 2027.743164,2602.521240 
	C2028.531250,2602.741699 2029.060791,2603.886230 2029.906860,2605.336426 
	C2027.369873,2608.718262 2023.902954,2607.914551 2020.680664,2607.950439 
	C2011.757202,2608.050293 2002.832031,2607.988525 1992.973633,2607.973145 
	C1988.384155,2604.786621 1983.199219,2606.593750 1978.674072,2604.000977 
	C1973.862061,2603.307129 1969.725708,2602.668457 1964.794678,2602.022217 
z"
        />
        <path
          fill="#49647C"
          opacity="1.000000"
          stroke="none"
          d="
M1984.294067,2563.564453 
	C1980.706421,2567.226318 1976.446655,2566.573730 1972.984131,2565.605957 
	C1966.592041,2563.819336 1960.218506,2564.087646 1952.881836,2564.011475 
	C1945.914429,2568.334229 1940.835815,2563.840332 1934.708862,2562.112549 
	C1927.451294,2564.055176 1921.702759,2561.586670 1915.411743,2558.262207 
	C1912.627808,2549.987793 1915.616089,2546.230957 1924.222656,2546.243896 
	C1932.044189,2553.734619 1940.824829,2556.719482 1950.693359,2556.779053 
	C1954.672485,2556.802979 1958.551758,2557.363525 1963.224121,2557.985352 
	C1971.598999,2556.855469 1978.816650,2559.962402 1987.166870,2560.051758 
	C1986.887451,2561.097900 1985.750732,2562.093018 1984.294067,2563.564453 
z"
        />
        <path
          fill="#49647C"
          opacity="1.000000"
          stroke="none"
          d="
M2437.324951,2581.433350 
	C2439.570312,2582.574463 2442.927490,2583.783691 2439.661133,2585.266602 
	C2428.089600,2590.520020 2415.621582,2590.708496 2403.131592,2589.969238 
	C2400.572998,2589.817627 2398.866211,2588.962158 2397.861816,2586.405518 
	C2396.911377,2583.985840 2395.439209,2581.770996 2394.103027,2578.728027 
	C2394.009766,2577.990234 2394.000000,2578.000000 2393.995117,2578.004883 
	C2403.758301,2574.162598 2413.250732,2578.370117 2423.664062,2579.010742 
	C2428.644531,2579.033447 2432.801025,2579.204346 2437.324951,2581.433350 
z"
        />
        <path
          fill="#465F75"
          opacity="1.000000"
          stroke="none"
          d="
M2265.196777,2612.048828 
	C2265.981445,2613.067871 2266.237305,2614.622559 2265.731934,2614.931152 
	C2258.400391,2619.410156 2253.917480,2628.825928 2242.650146,2627.867188 
	C2241.014648,2622.909424 2237.113281,2624.236572 2234.055420,2624.049805 
	C2229.555664,2623.775146 2224.732910,2624.840576 2221.319580,2620.703369 
	C2221.393066,2619.059326 2222.272217,2618.104980 2223.752197,2618.217529 
	C2232.477051,2618.880615 2240.644043,2616.301270 2248.772705,2613.801025 
	C2253.900146,2612.224121 2259.036865,2611.766357 2265.196777,2612.048828 
z"
        />
        <path
          fill="#465F75"
          opacity="1.000000"
          stroke="none"
          d="
M2371.087891,2614.027344 
	C2372.519775,2614.998047 2373.039551,2615.967041 2373.779785,2617.440918 
	C2375.738281,2620.103760 2374.981445,2622.090332 2372.955566,2624.605469 
	C2370.853027,2626.098389 2369.219971,2626.546387 2366.666016,2626.815430 
	C2364.111084,2626.896729 2362.404785,2626.891602 2359.836426,2626.990723 
	C2353.089844,2627.591553 2347.226807,2627.747559 2340.628418,2625.746582 
	C2336.425293,2622.839600 2335.254395,2619.643066 2337.364258,2614.859863 
	C2341.516846,2611.936523 2345.586182,2611.217041 2349.432617,2612.414795 
	C2356.294922,2614.552002 2363.245850,2613.773926 2371.087891,2614.027344 
z"
        />
        <path
          fill="#465F75"
          opacity="1.000000"
          stroke="none"
          d="
M2286.427490,2618.689697 
	C2285.986084,2615.942383 2285.935303,2613.923584 2285.800781,2611.164062 
	C2291.451660,2611.321289 2296.950195,2612.040283 2302.957031,2610.506104 
	C2309.541260,2608.824707 2316.568359,2609.652832 2323.479004,2613.406982 
	C2325.276611,2619.544678 2322.006348,2621.740967 2317.298340,2622.563477 
	C2313.419189,2623.240479 2309.459717,2622.976562 2304.684326,2622.977051 
	C2297.702881,2624.000000 2291.643311,2624.649414 2286.427490,2618.689697 
z"
        />
        <path
          fill="#334D63"
          opacity="1.000000"
          stroke="none"
          d="
M2301.510986,2621.010010 
	C2306.460938,2620.120361 2311.050537,2619.857178 2315.624512,2620.026367 
	C2319.392578,2620.165771 2321.908203,2618.755127 2323.688965,2614.642822 
	C2328.066406,2613.989990 2332.123535,2614.032959 2337.044922,2614.092773 
	C2338.036621,2617.042480 2337.785400,2620.041260 2339.693848,2623.392578 
	C2340.208008,2626.632324 2338.396729,2627.933350 2336.464355,2627.988037 
	C2326.929443,2628.258301 2317.379883,2628.169189 2307.847656,2627.960449 
	C2304.507324,2627.887451 2301.579102,2625.835693 2301.510986,2621.010010 
z"
        />
        <path
          fill="#4B6377"
          opacity="1.000000"
          stroke="none"
          d="
M1919.338379,2609.690918 
	C1920.288208,2614.834473 1924.788818,2613.295166 1927.364380,2616.029785 
	C1913.531372,2616.029785 1899.698242,2616.029785 1884.927002,2616.005859 
	C1883.940186,2611.170898 1885.829590,2608.354004 1891.559204,2609.505859 
	C1894.838989,2611.956787 1897.981445,2610.768799 1901.513672,2610.364258 
	C1907.580688,2609.755371 1913.144653,2609.586426 1919.338379,2609.690918 
z"
        />
        <path
          fill="#4B6377"
          opacity="1.000000"
          stroke="none"
          d="
M1847.904053,2613.298340 
	C1841.921021,2616.288086 1835.747070,2616.137207 1828.686401,2616.068359 
	C1826.480713,2613.246338 1825.166992,2610.394043 1823.723145,2606.879639 
	C1829.897461,2606.164551 1836.202637,2606.152588 1842.505981,2606.045654 
	C1846.993286,2605.969482 1848.211914,2608.548828 1847.904053,2613.298340 
z"
        />
        <path
          fill="#465F75"
          opacity="1.000000"
          stroke="none"
          d="
M2213.500732,2587.374756 
	C2204.426758,2591.703613 2192.221680,2589.823486 2186.053467,2582.880859 
	C2195.361572,2581.276855 2204.283447,2583.449219 2213.500732,2587.374756 
z"
        />
        <path
          fill="#49647C"
          opacity="1.000000"
          stroke="none"
          d="
M2112.053711,2567.488281 
	C2107.869385,2575.956543 2105.438477,2576.854736 2098.580322,2572.465332 
	C2096.864990,2570.722412 2096.541260,2569.200684 2097.473633,2566.808105 
	C2101.615967,2564.942383 2104.500977,2560.900146 2109.556885,2563.453369 
	C2110.846924,2564.806152 2111.742432,2565.638184 2112.053711,2567.488281 
z"
        />
        <path
          fill="#465F75"
          opacity="1.000000"
          stroke="none"
          d="
M2114.048096,2585.305176 
	C2109.688721,2586.318359 2105.309326,2586.577881 2100.456055,2586.339355 
	C2101.305908,2577.938477 2107.574219,2580.510986 2113.205566,2580.013672 
	C2114.028809,2581.543213 2114.028320,2583.047363 2114.048096,2585.305176 
z"
        />
        <path
          fill="#395166"
          opacity="1.000000"
          stroke="none"
          d="
M2178.926270,2619.985352 
	C2177.965576,2617.604736 2179.568604,2616.318359 2181.609375,2614.586426 
	C2186.694824,2615.248291 2191.416260,2616.385986 2197.170166,2615.988525 
	C2198.754639,2616.791016 2199.032227,2617.724121 2198.424805,2619.448486 
	C2191.955078,2620.062012 2185.894043,2620.026367 2178.926270,2619.985352 
z"
        />
        <path
          fill="#3E5871"
          opacity="1.000000"
          stroke="none"
          d="
M2097.061523,2566.097656 
	C2097.956543,2567.599854 2097.989014,2569.079102 2098.039062,2571.302979 
	C2093.966797,2572.023438 2089.876709,2571.999512 2084.913574,2571.958984 
	C2082.849121,2570.615234 2082.583984,2569.093750 2083.580078,2566.755859 
	C2088.112305,2566.092285 2092.155518,2566.083496 2097.061523,2566.097656 
z"
        />
        <path
          fill="#294159"
          opacity="1.000000"
          stroke="none"
          d="
M1953.128418,2611.759277 
	C1954.081909,2612.695557 1954.717773,2614.185303 1954.352905,2614.494873 
	C1950.557007,2617.712646 1943.205322,2616.146240 1940.045654,2610.821045 
	C1944.124634,2610.611816 1948.235474,2611.188232 1953.128418,2611.759277 
z"
        />
        <path
          fill="#3E5871"
          opacity="1.000000"
          stroke="none"
          d="
M2112.944336,2568.079102 
	C2111.412109,2567.115967 2110.761719,2566.135742 2110.058594,2564.545898 
	C2114.742920,2563.956543 2119.479492,2563.976807 2125.103027,2564.008301 
	C2126.519531,2565.030029 2127.049072,2566.040527 2127.788574,2567.585449 
	C2123.274414,2568.100830 2118.550537,2568.081543 2112.944336,2568.079102 
z"
        />
        <path
          fill="#395166"
          opacity="1.000000"
          stroke="none"
          d="
M1828.899414,2600.004395 
	C1828.367188,2597.812500 1829.473511,2596.060303 1831.802368,2596.218750 
	C1835.835083,2596.493164 1840.464233,2594.106201 1843.891357,2599.318359 
	C1839.333374,2600.052002 1834.561279,2600.035400 1828.899414,2600.004395 
z"
        />
        <path
          fill="#465F75"
          opacity="1.000000"
          stroke="none"
          d="
M2198.505615,2620.555420 
	C2198.011963,2619.186768 2198.007568,2618.275879 2198.001465,2616.682373 
	C2202.275146,2616.322021 2206.824463,2615.024902 2212.352051,2618.056396 
	C2207.050781,2620.317871 2203.065674,2620.837646 2198.505615,2620.555420 
z"
        />
        <path
          fill="#4B6377"
          opacity="1.000000"
          stroke="none"
          d="
M1978.459473,2604.518066 
	C1983.038208,2602.348389 1987.676514,2602.853027 1991.871094,2607.259766 
	C1987.413696,2608.158936 1982.793091,2608.336914 1978.459473,2604.518066 
z"
        />
        <path
          fill="#607B93"
          opacity="1.000000"
          stroke="none"
          d="
M2437.958008,2581.250977 
	C2434.407227,2582.294922 2430.882812,2580.194824 2426.674805,2581.915283 
	C2424.845947,2580.636230 2424.556152,2579.114014 2425.572266,2576.768799 
	C2429.595215,2576.382568 2432.797363,2578.493896 2437.240234,2577.984863 
	C2437.997314,2578.860107 2437.984619,2579.729736 2437.958008,2581.250977 
z"
        />
        <path
          fill="#546D83"
          opacity="1.000000"
          stroke="none"
          d="
M2074.656738,2559.990967 
	C2074.679932,2556.175293 2069.398926,2556.748047 2069.058594,2553.154541 
	C2074.751465,2550.666504 2076.421631,2554.368164 2077.933350,2559.242676 
	C2077.136963,2559.971680 2076.235107,2559.982422 2074.656738,2559.990967 
z"
        />
        <path
          fill="#49647C"
          opacity="1.000000"
          stroke="none"
          d="
M2083.331787,2566.070557 
	C2084.052490,2567.561279 2084.036133,2569.021484 2084.029785,2571.212402 
	C2083.387451,2571.937256 2082.732422,2571.965576 2082.082520,2571.920410 
	C2079.693359,2571.755615 2076.950439,2571.185303 2077.124268,2568.416260 
	C2077.326904,2565.184326 2080.509766,2566.330322 2083.331787,2566.070557 
z"
        />
        <path
          fill="#49647C"
          opacity="1.000000"
          stroke="none"
          d="
M2235.502441,2565.456055 
	C2233.646240,2568.803711 2230.404053,2567.762451 2226.652100,2567.862793 
	C2227.536865,2562.447510 2231.335205,2563.905273 2235.502441,2565.456055 
z"
        />
        <path
          fill="#4B6377"
          opacity="1.000000"
          stroke="none"
          d="
M1873.541748,2615.586914 
	C1871.176270,2618.588135 1868.504028,2619.122314 1866.014526,2614.827148 
	C1868.293457,2614.075439 1870.799072,2613.549561 1873.541748,2615.586914 
z"
        />
        <path
          fill="#3E5871"
          opacity="1.000000"
          stroke="none"
          d="
M2171.252197,2565.985352 
	C2170.956543,2570.169434 2169.177490,2571.210938 2166.132812,2566.677490 
	C2167.514404,2566.016357 2169.009521,2566.003174 2171.252197,2565.985352 
z"
        />
        <path
          fill="#334D63"
          opacity="1.000000"
          stroke="none"
          d="
M1901.181030,2609.937988 
	C1899.299927,2612.847900 1896.171753,2612.319824 1892.470459,2610.486328 
	C1894.775879,2609.962158 1897.560547,2609.956787 1901.181030,2609.937988 
z"
        />
        <path
          fill="#334D63"
          opacity="1.000000"
          stroke="none"
          d="
M1987.466797,2615.917480 
	C1986.395264,2618.018066 1984.638550,2618.323486 1982.307617,2616.315918 
	C1983.548828,2613.873291 1985.250366,2614.669678 1987.466797,2615.917480 
z"
        />
        <path
          fill="#546D83"
          opacity="1.000000"
          stroke="none"
          d="
M1926.695312,2548.562256 
	C1917.376953,2548.539795 1917.376953,2548.539795 1915.942139,2556.914795 
	C1914.188965,2560.190186 1911.540527,2560.719727 1908.931885,2560.161621 
	C1897.525391,2557.720703 1886.025269,2560.220947 1873.780273,2559.176758 
	C1859.274536,2558.882812 1845.745972,2562.762939 1831.948730,2560.422852 
	C1828.422363,2559.824951 1824.645508,2560.589111 1821.790039,2556.438477 
	C1819.333984,2549.913574 1820.849365,2543.757812 1819.482666,2536.991699 
	C1847.501953,2536.316895 1875.533325,2532.462891 1903.720093,2533.815186 
	C1907.260132,2533.985107 1910.926758,2532.794189 1914.445679,2531.887939 
	C1925.267456,2529.101074 1936.091797,2529.231934 1947.007202,2531.239990 
	C1949.923706,2531.776611 1952.945801,2531.901611 1955.921753,2531.924561 
	C1969.537109,2532.030273 1982.984131,2533.893799 1996.448730,2535.611572 
	C1998.093872,2535.821533 1999.761230,2535.913818 2001.420654,2535.947021 
	C2017.001831,2536.260742 2032.634644,2535.297607 2048.979492,2537.854980 
	C2050.747803,2537.103027 2051.675537,2536.386963 2053.426270,2536.011475 
	C2057.607178,2536.267090 2061.032471,2535.446289 2065.168701,2534.322266 
	C2067.859619,2536.371338 2070.932617,2535.562988 2073.625244,2537.464355 
	C2075.596680,2537.886230 2077.055664,2537.901123 2079.243164,2537.914551 
	C2081.297363,2539.666504 2082.622803,2541.419434 2084.025879,2543.275146 
	C2078.788574,2545.415039 2074.232422,2542.070312 2069.464111,2542.129639 
	C2049.619629,2542.375488 2029.735229,2540.749512 2009.944458,2543.098389 
	C2007.166382,2543.427979 2004.794067,2541.830322 2002.147949,2542.469482 
	C2000.393799,2542.893311 1999.007935,2543.920166 1999.124390,2545.781494 
	C1999.216309,2547.248291 2000.620483,2547.815430 2002.040771,2547.854736 
	C2004.689331,2547.928223 2007.339355,2547.990234 2009.988770,2547.992676 
	C2023.244263,2548.004883 2036.500854,2548.084717 2049.754883,2547.942139 
	C2053.836914,2547.898193 2057.653809,2548.680664 2061.339600,2550.232910 
	C2062.574707,2550.752686 2063.665039,2551.872070 2062.156982,2553.168213 
	C2061.495117,2553.737061 2060.322021,2553.711426 2058.685547,2553.982910 
	C2054.336426,2553.936279 2050.715576,2553.989502 2047.129517,2552.813721 
	C2042.943359,2551.441650 2038.383179,2551.307129 2034.291382,2552.489502 
	C2026.379272,2554.775635 2018.230713,2554.892090 2010.513794,2553.690186 
	C1994.241577,2551.155762 1977.957153,2552.697266 1960.756592,2551.893066 
	C1948.566650,2556.024414 1938.067017,2551.509277 1926.695312,2548.562256 
z"
        />
        <path
          fill="#597288"
          opacity="1.000000"
          stroke="none"
          d="
M2425.177002,2576.074463 
	C2426.045166,2577.578613 2426.027588,2579.045166 2426.009766,2581.246094 
	C2420.280518,2582.533203 2414.930420,2580.328613 2409.485107,2579.271973 
	C2404.871582,2578.376221 2400.360352,2578.093506 2394.871582,2578.006592 
	C2400.171387,2574.584961 2406.458252,2573.126953 2413.576416,2575.354004 
	C2416.894531,2576.392090 2420.704346,2575.858154 2425.177002,2576.074463 
z"
        />
        <path
          fill="#3E5871"
          opacity="1.000000"
          stroke="none"
          d="
M2351.042480,2573.068359 
	C2350.959717,2573.111816 2351.125488,2573.024902 2351.042480,2573.068359 
z"
        />
        <path
          fill="#597288"
          opacity="1.000000"
          stroke="none"
          d="
M2462.151855,2584.852539 
	C2468.455322,2580.504150 2474.211182,2582.496094 2479.844238,2587.273438 
	C2474.141113,2587.126953 2467.738281,2590.228027 2462.151855,2584.852539 
z"
        />
        <path
          fill="#516D85"
          opacity="1.000000"
          stroke="none"
          d="
M2534.635498,2591.972900 
	C2534.496094,2588.856934 2535.894531,2587.281738 2539.583008,2589.513672 
	C2538.876221,2591.687744 2537.158691,2591.987305 2534.635498,2591.972900 
z"
        />
        <path
          fill="#344F68"
          opacity="1.000000"
          stroke="none"
          d="
M2371.916992,2625.048828 
	C2372.635742,2622.631592 2373.249268,2620.957520 2373.931396,2618.614746 
	C2388.973145,2620.816162 2404.082031,2622.088135 2419.326416,2621.997070 
	C2424.211182,2621.968018 2429.255615,2621.176025 2433.500732,2624.977539 
	C2435.277832,2626.569336 2437.954590,2625.929688 2440.257568,2625.965820 
	C2445.550537,2626.048828 2450.842285,2625.676758 2456.010010,2627.527588 
	C2458.894043,2628.560303 2461.838867,2627.295898 2464.787109,2626.885742 
	C2474.655762,2625.512451 2484.306641,2624.968262 2492.803711,2632.303223 
	C2497.083496,2635.997314 2503.050781,2633.481201 2509.124268,2633.994141 
	C2532.105225,2636.624756 2554.191406,2639.281738 2577.141602,2641.945801 
	C2578.253662,2642.285156 2578.501709,2642.617188 2578.389648,2643.456055 
	C2573.478027,2645.154297 2571.776367,2652.336670 2564.704834,2649.881348 
	C2560.534912,2646.346436 2556.932129,2645.781250 2552.538086,2649.453125 
	C2551.416504,2650.197754 2550.891846,2650.598389 2550.228516,2651.574951 
	C2543.799072,2647.630127 2536.719238,2644.991943 2529.845947,2642.060059 
	C2523.493408,2639.350830 2516.777344,2637.780762 2510.169922,2643.184570 
	C2502.143555,2647.667480 2494.246826,2645.338379 2486.616943,2643.670410 
	C2474.219238,2640.960449 2462.895996,2642.033936 2450.802979,2646.822266 
	C2438.857910,2651.551758 2425.550781,2652.916992 2412.290527,2646.611816 
	C2408.109619,2632.989990 2401.460449,2628.692871 2387.645996,2630.122803 
	C2381.906494,2630.717041 2375.553223,2632.816162 2371.916992,2625.048828 
z"
        />
        <path
          fill="#3F5A73"
          opacity="1.000000"
          stroke="none"
          d="
M2578.871094,2643.981934 
	C2578.023682,2643.628174 2578.018311,2643.293457 2578.009033,2642.455811 
	C2590.818115,2638.897461 2603.509033,2637.690918 2615.892578,2645.145264 
	C2603.865967,2647.413818 2591.760986,2646.268066 2578.871094,2643.981934 
z"
        />
        <path
          fill="#3F5A73"
          opacity="1.000000"
          stroke="none"
          d="
M2620.597168,2646.482422 
	C2628.149170,2642.553955 2636.480713,2643.904785 2645.400879,2645.519043 
	C2637.854492,2649.547119 2629.518066,2648.137207 2620.597168,2646.482422 
z"
        />
        <path
          fill="#3F5A73"
          opacity="1.000000"
          stroke="none"
          d="
M2552.895996,2649.936523 
	C2551.967529,2646.684570 2552.679443,2644.055176 2556.710938,2644.086182 
	C2559.914307,2644.110840 2563.546875,2643.427002 2563.899658,2649.078125 
	C2560.515381,2649.874268 2557.129395,2649.904541 2552.895996,2649.936523 
z"
        />
        <path
          fill="#445E74"
          opacity="1.000000"
          stroke="none"
          d="
M2748.277588,2550.017090 
	C2750.917236,2550.202637 2753.025635,2549.573975 2754.511230,2551.405029 
	C2749.215820,2554.760498 2749.215820,2554.760498 2742.216797,2551.108643 
	C2743.683594,2549.145508 2745.783936,2550.385010 2748.277588,2550.017090 
z"
        />
        <path
          fill="#E9E1D0"
          opacity="1.000000"
          stroke="none"
          d="
M1778.483521,3241.476074 
	C1774.439453,3241.834473 1771.137329,3241.538574 1770.798706,3246.672119 
	C1770.700195,3248.165527 1768.254395,3247.840088 1766.402588,3246.467041 
	C1765.712402,3245.673340 1765.419800,3245.346436 1764.933350,3244.394043 
	C1764.646362,3239.822998 1763.959839,3236.306396 1758.407959,3235.987549 
	C1754.353882,3236.663330 1751.019897,3237.375488 1749.425171,3241.932129 
	C1745.156250,3245.817383 1741.857422,3246.774414 1740.313354,3239.679688 
	C1740.831665,3236.287598 1742.704712,3234.439697 1744.509033,3231.731689 
	C1746.737061,3229.596680 1748.883789,3228.699707 1750.933228,3227.625732 
	C1763.113037,3221.241943 1763.108276,3221.232910 1757.605103,3207.849121 
	C1753.178711,3211.923828 1748.041382,3213.398193 1741.593384,3211.877930 
	C1740.568237,3211.438965 1740.183960,3211.219238 1739.319702,3210.557617 
	C1737.728516,3208.926758 1737.196411,3207.237061 1735.108643,3206.175781 
	C1732.601807,3205.182861 1730.625000,3205.223877 1728.010376,3206.060059 
	C1724.142456,3207.020264 1721.254395,3208.712158 1717.365967,3209.891602 
	C1707.521729,3208.249756 1707.354614,3207.878662 1712.009521,3198.223389 
	C1719.423218,3192.030762 1728.906372,3192.533936 1736.244751,3187.766846 
	C1738.603271,3186.234375 1741.381958,3186.169434 1741.460693,3181.790039 
	C1741.548462,3176.900879 1746.660645,3176.369141 1750.574097,3175.884521 
	C1754.644409,3175.380127 1758.744507,3176.120605 1761.613159,3179.297119 
	C1764.938721,3182.979248 1761.196289,3186.029053 1760.090576,3189.281982 
	C1759.567383,3190.821289 1758.260620,3191.922363 1757.357178,3193.723389 
	C1760.401978,3192.696045 1765.916138,3192.861328 1765.511475,3191.919434 
	C1762.926758,3185.903076 1770.661377,3182.462646 1768.539429,3176.871338 
	C1766.976196,3172.751953 1764.237427,3171.234863 1760.041748,3171.761230 
	C1757.078857,3172.133057 1753.978760,3173.065918 1751.143799,3171.961182 
	C1745.359009,3169.707764 1740.325073,3165.605713 1739.045166,3159.726807 
	C1737.693115,3153.516846 1745.083862,3154.031006 1748.231934,3151.156494 
	C1755.677124,3144.358398 1762.540649,3136.875977 1770.862183,3138.641602 
	C1763.302002,3143.279053 1748.542114,3136.500977 1739.284912,3148.505615 
	C1736.612549,3147.886230 1735.867432,3146.099854 1734.587524,3143.885498 
	C1731.209351,3138.118408 1726.667236,3140.870361 1722.676270,3141.197998 
	C1718.523193,3141.539551 1713.963013,3141.886230 1713.314331,3136.663574 
	C1712.707031,3131.772949 1717.501831,3131.547363 1720.904419,3130.702637 
	C1726.492188,3129.315918 1732.312744,3128.750488 1737.379395,3125.617676 
	C1729.244385,3129.136963 1720.288452,3129.941162 1711.425049,3133.461914 
	C1709.222168,3133.256592 1707.928467,3132.618652 1706.257812,3131.172852 
	C1705.373657,3129.742920 1705.244263,3128.818848 1705.704834,3127.225098 
	C1706.229004,3126.235107 1706.486572,3125.877197 1707.184570,3125.084961 
	C1709.108154,3123.486084 1709.040405,3122.453857 1706.830811,3121.168945 
	C1704.370728,3118.742432 1704.657959,3116.580078 1706.372437,3113.795898 
	C1710.836792,3110.859375 1714.748657,3108.636963 1712.560791,3102.630127 
	C1711.761963,3100.436768 1714.081055,3098.418945 1714.930542,3096.302246 
	C1717.712524,3089.370361 1721.110107,3082.390381 1715.971924,3074.357422 
	C1715.398438,3073.386475 1715.207520,3072.982422 1714.862183,3071.937988 
	C1714.396484,3069.399414 1715.941040,3067.376221 1713.887695,3065.103027 
	C1713.211914,3064.274170 1712.969604,3063.909180 1712.486938,3062.916260 
	C1711.640259,3050.429688 1708.556152,3038.683838 1710.677612,3026.692871 
	C1710.903320,3025.416748 1711.397461,3026.379639 1710.635986,3025.802734 
	C1700.352661,3018.004395 1708.572998,3009.562988 1709.860962,3001.564209 
	C1711.931274,2988.705078 1711.783936,2976.095215 1710.659668,2963.052979 
	C1709.801514,2953.097168 1712.044556,2942.754883 1709.369873,2931.941406 
	C1714.600830,2907.144531 1711.796753,2882.777100 1710.867065,2858.637695 
	C1710.587402,2851.377686 1703.908936,2844.138672 1703.463257,2835.699707 
	C1703.324341,2833.067139 1702.143677,2830.526611 1702.830200,2827.131348 
	C1709.116211,2824.308105 1714.622070,2829.546387 1721.040527,2827.451172 
	C1723.396606,2826.681885 1724.137939,2831.804688 1725.075073,2834.621338 
	C1730.170776,2849.937744 1732.132568,2865.819580 1733.369263,2881.780273 
	C1733.773315,2886.994629 1737.096436,2885.272949 1739.707275,2885.585938 
	C1742.364014,2885.903809 1745.357178,2885.811035 1746.457520,2888.809082 
	C1747.743774,2892.313965 1744.785400,2893.403809 1742.404785,2894.540283 
	C1737.678467,2896.796387 1739.541016,2899.677734 1741.536011,2902.603027 
	C1743.753174,2905.854736 1747.456665,2906.749756 1751.270386,2908.781250 
	C1753.551025,2911.945801 1751.794434,2914.628906 1749.842041,2914.817139 
	C1738.349731,2915.924805 1740.520996,2925.640137 1738.483398,2932.447021 
	C1736.556885,2938.883301 1739.642944,2945.710205 1737.807861,2952.282959 
	C1737.616699,2952.967773 1737.490845,2954.109131 1737.875000,2954.149170 
	C1751.351562,2955.552979 1742.253052,2964.509033 1742.999634,2969.949219 
	C1744.418091,2980.284668 1750.629639,2989.241699 1750.777344,3000.118652 
	C1752.347046,2994.057861 1755.794678,2988.364014 1754.146118,2982.589355 
	C1749.531128,2966.423584 1754.870728,2950.792236 1756.110718,2935.003174 
	C1756.763062,2926.696777 1753.302124,2919.375000 1753.196045,2910.703125 
	C1753.335083,2908.389893 1753.982300,2907.039795 1755.551025,2905.320312 
	C1758.079712,2903.786133 1760.104492,2903.629395 1762.899048,2904.416016 
	C1767.380615,2907.568115 1769.387207,2912.256592 1768.830933,2916.090088 
	C1767.508789,2925.200928 1774.408813,2933.047119 1771.829468,2942.130371 
	C1771.072876,2944.795166 1774.179565,2945.882324 1775.302979,2947.822754 
	C1777.694214,2951.951660 1782.371216,2956.094971 1775.013550,2960.091553 
	C1772.971313,2961.200439 1773.786621,2962.829834 1775.031006,2964.181641 
	C1775.936401,2965.165039 1777.231323,2965.794189 1778.021118,2966.812744 
	C1781.014404,2970.672852 1788.680664,2968.520996 1789.194702,2975.297119 
	C1789.587158,2980.468506 1785.294556,2983.505615 1783.015137,2987.479492 
	C1780.504150,2991.856934 1781.575195,2994.562256 1787.196167,2994.083008 
	C1787.859863,2994.026367 1788.517700,2993.898682 1789.181763,2993.847168 
	C1794.506348,2993.434570 1800.099243,2993.519531 1799.722412,3000.760742 
	C1799.391602,3007.119629 1795.308716,3010.151855 1788.261597,3008.717285 
	C1791.810791,3013.819092 1795.854858,3017.096680 1802.382202,3016.228760 
	C1805.871704,3015.764893 1809.297241,3016.571045 1810.636475,3020.316162 
	C1812.082764,3024.360840 1809.269531,3026.427246 1806.144043,3028.063721 
	C1804.965698,3028.680664 1803.698730,3029.150146 1802.682007,3030.031738 
	C1800.672119,3031.774902 1801.408447,3033.739746 1802.682129,3035.328125 
	C1804.315674,3037.365479 1805.604736,3035.603760 1806.661621,3034.437744 
	C1809.586914,3031.209961 1812.641479,3027.921631 1812.070190,3023.100586 
	C1810.952637,3013.670166 1810.860596,3003.884521 1803.616089,2996.372314 
	C1802.435669,2995.148682 1801.490723,2993.617676 1801.343140,2991.991455 
	C1800.516602,2982.881592 1802.163818,2973.766602 1801.436768,2964.630371 
	C1801.344482,2963.470215 1803.204712,2962.813721 1805.222900,2962.401367 
	C1814.634766,2965.967285 1819.869629,2972.058594 1819.627808,2981.904053 
	C1819.480469,2987.902100 1821.982300,2993.656738 1821.424438,2999.604980 
	C1820.928223,3004.895264 1825.612793,3004.961670 1828.184814,3006.934570 
	C1831.382202,3009.386719 1832.672485,3005.740723 1834.326660,3003.995117 
	C1836.732788,3001.455811 1839.311279,2999.642578 1843.673828,3000.968262 
	C1847.000366,3004.114014 1848.401001,3007.823730 1851.223877,3011.457520 
	C1852.367920,3014.004639 1852.560059,3015.979248 1852.272339,3018.727783 
	C1848.210938,3028.725098 1838.621948,3035.328857 1839.088623,3047.449219 
	C1838.200684,3049.665283 1837.188232,3051.090332 1835.691895,3051.760254 
	C1828.987183,3054.761719 1827.493164,3059.652588 1829.939575,3066.278320 
	C1830.507568,3067.816895 1830.104248,3069.539551 1829.394531,3071.794922 
	C1826.905518,3075.892578 1827.360229,3077.765381 1831.944702,3077.854492 
	C1834.837036,3077.910645 1838.314697,3077.972412 1838.683105,3081.950195 
	C1838.979736,3085.152344 1836.108032,3086.380371 1833.745117,3087.338623 
	C1828.511963,3089.460693 1825.258057,3094.664307 1818.971680,3096.259277 
	C1808.196777,3096.985840 1809.110229,3087.672852 1810.373779,3083.879883 
	C1814.536743,3071.382812 1809.671875,3058.475586 1813.343384,3046.031738 
	C1810.771240,3051.955811 1808.129150,3057.574707 1802.511963,3061.526123 
	C1798.787720,3064.145752 1799.058838,3068.841797 1799.444092,3073.439453 
	C1800.363037,3084.407227 1795.649902,3088.260742 1784.739868,3085.739258 
	C1782.638428,3085.253906 1781.701782,3085.112061 1781.179321,3087.182373 
	C1780.785767,3088.741699 1778.905029,3091.358887 1779.923584,3091.603516 
	C1788.942749,3093.770996 1784.078735,3101.344727 1785.519897,3106.845947 
	C1789.866089,3105.253174 1792.008057,3103.421143 1788.862061,3099.538330 
	C1786.553223,3096.688721 1786.211548,3093.696045 1789.378052,3091.880859 
	C1793.996948,3089.233154 1799.317261,3087.419922 1804.603516,3089.113525 
	C1808.630615,3090.403564 1808.206787,3094.438965 1807.139282,3097.655273 
	C1805.626221,3102.214600 1804.084473,3106.538086 1808.768555,3111.004395 
	C1808.921509,3112.652832 1808.644043,3113.539795 1807.625366,3114.839600 
	C1799.534058,3117.296387 1799.053223,3122.248779 1801.541260,3129.128418 
	C1801.138672,3130.564941 1800.649048,3131.237305 1800.086304,3131.878662 
	C1799.207886,3132.880859 1800.061890,3131.280029 1799.487915,3132.483154 
	C1799.369507,3132.731445 1799.503418,3132.502930 1800.539795,3131.426270 
	C1802.057007,3129.518799 1802.547241,3127.633789 1804.923828,3126.360352 
	C1813.380859,3123.879639 1818.634766,3127.129150 1822.658936,3134.538330 
	C1823.240601,3135.711182 1823.415894,3136.207764 1823.685059,3137.488281 
	C1820.522705,3146.923340 1813.071045,3150.223389 1804.395020,3152.965332 
	C1797.732910,3155.106201 1791.959961,3153.771484 1786.103516,3152.469238 
	C1780.279053,3151.173828 1776.833374,3152.766602 1778.890259,3159.662109 
	C1779.362183,3161.244141 1779.718384,3162.862793 1780.098022,3164.471924 
	C1783.305786,3178.071533 1783.303955,3178.072021 1799.760986,3176.641602 
	C1801.848511,3176.009766 1803.280762,3175.964844 1805.414917,3176.394531 
	C1809.247070,3178.308594 1811.717041,3180.784424 1810.754395,3184.507568 
	C1809.740234,3188.430176 1805.818604,3188.459961 1802.582153,3188.940430 
	C1798.321289,3189.573242 1794.088379,3190.504639 1789.726440,3190.310791 
	C1788.135864,3190.239990 1786.074463,3189.921875 1785.549561,3191.962646 
	C1784.967041,3194.227051 1786.525635,3195.561523 1788.454590,3196.534668 
	C1791.972656,3198.309570 1798.991943,3196.352295 1798.408813,3202.243408 
	C1797.812378,3208.268311 1791.008911,3205.785156 1786.892700,3207.103760 
	C1780.759766,3209.069092 1780.607788,3215.053223 1780.501099,3219.928467 
	C1780.378174,3225.543457 1780.067993,3231.200928 1780.289795,3237.564941 
	C1779.985718,3239.260986 1779.591919,3240.159912 1778.483521,3241.476074 
z"
        />
        <path
          fill="#E5DDC9"
          opacity="1.000000"
          stroke="none"
          d="
M1766.231689,3243.312012 
	C1766.004395,3244.009766 1766.005249,3245.004883 1766.005127,3245.502441 
	C1765.090576,3246.344482 1763.351929,3246.896484 1763.378906,3247.003906 
	C1765.817871,3256.690918 1753.792358,3257.031738 1752.355225,3264.134033 
	C1744.023560,3265.393066 1736.627319,3270.950684 1727.822632,3269.774658 
	C1723.465332,3269.192871 1723.115967,3266.047607 1727.006714,3262.100586 
	C1728.159790,3260.930908 1729.949341,3260.294189 1729.255615,3258.284668 
	C1728.407959,3255.828857 1726.152100,3255.814697 1724.291870,3256.397705 
	C1718.342041,3258.262695 1712.464478,3260.365479 1706.602173,3262.495605 
	C1705.782715,3262.793213 1704.601196,3263.760498 1704.606445,3264.409668 
	C1704.677124,3273.355957 1695.863037,3278.277588 1695.158447,3287.570312 
	C1691.967041,3285.700439 1692.367798,3281.059082 1687.785034,3281.819336 
	C1683.616821,3282.511475 1681.944336,3284.065674 1681.860962,3288.055664 
	C1681.812500,3290.369385 1680.266724,3292.179199 1678.083130,3291.711182 
	C1669.258423,3289.818848 1665.359131,3295.729248 1660.691772,3301.848633 
	C1659.527954,3301.558350 1659.101929,3301.077881 1658.721802,3300.558350 
	C1658.341919,3300.039307 1658.467529,3300.213379 1659.549561,3301.540039 
	C1659.770142,3304.863770 1660.798096,3307.113037 1663.002930,3309.008057 
	C1667.345093,3312.739502 1664.036987,3317.813721 1664.967407,3322.199219 
	C1665.334106,3323.927979 1662.421265,3325.056885 1660.746704,3325.989014 
	C1657.756836,3327.653564 1655.266602,3326.408691 1653.084595,3324.464111 
	C1653.939453,3321.061523 1657.660889,3322.810303 1658.667725,3320.394043 
	C1658.723022,3319.244873 1658.047241,3318.601562 1657.036987,3318.294189 
	C1654.813599,3317.618164 1652.572998,3316.998779 1650.342529,3316.345703 
	C1646.765991,3315.298584 1644.801514,3313.548584 1647.987671,3310.063965 
	C1650.261108,3307.578125 1650.166138,3305.359375 1647.672607,3303.229004 
	C1646.595825,3302.308838 1645.221924,3302.113037 1643.934937,3302.665771 
	C1642.922852,3303.100342 1642.742554,3304.160400 1642.838013,3305.187256 
	C1643.550781,3312.853760 1640.554932,3318.608643 1633.965698,3322.548096 
	C1632.737671,3323.282715 1632.101074,3324.481689 1632.141724,3325.879883 
	C1632.262695,3330.041016 1630.978638,3331.562256 1626.972534,3329.004639 
	C1626.170166,3328.491943 1624.933594,3328.996094 1624.624390,3330.128174 
	C1624.242554,3331.526367 1624.702515,3332.776855 1626.067017,3333.433594 
	C1628.136353,3334.429443 1630.774292,3333.480957 1632.648071,3335.206543 
	C1632.732422,3338.426514 1630.126831,3337.790039 1628.418457,3338.218018 
	C1623.898193,3339.350830 1621.519287,3341.960449 1621.992432,3346.798096 
	C1622.476929,3351.754150 1620.475586,3353.765381 1615.234619,3354.177246 
	C1606.996948,3354.824951 1601.950073,3361.337646 1600.401245,3368.074463 
	C1598.228882,3377.522461 1592.123535,3385.428711 1591.141968,3395.159180 
	C1590.993530,3396.629639 1589.788330,3398.428955 1588.532227,3399.269043 
	C1585.098511,3401.565430 1584.370972,3404.735840 1583.739746,3408.563721 
	C1582.604004,3415.450928 1580.425049,3416.747070 1573.267212,3415.342285 
	C1569.823608,3414.666504 1566.554077,3412.990234 1566.030884,3419.237793 
	C1562.077637,3420.107910 1557.257568,3419.135498 1559.491333,3426.057129 
	C1560.046509,3427.776855 1558.924683,3429.701904 1557.018066,3429.618652 
	C1550.821411,3429.347412 1546.387207,3434.600586 1539.950195,3434.325195 
	C1534.869507,3434.108154 1530.756958,3436.674805 1529.788696,3443.095703 
	C1528.851074,3449.311768 1523.368774,3450.905273 1518.407715,3446.541016 
	C1515.753418,3444.205811 1513.250488,3443.799805 1510.244141,3444.314453 
	C1508.016968,3444.696045 1505.862305,3446.218262 1503.620728,3444.410400 
	C1501.094849,3442.373047 1498.862915,3442.005615 1497.362671,3445.546387 
	C1495.741699,3449.371826 1497.274292,3452.057373 1500.732910,3453.619385 
	C1502.823730,3454.563477 1505.207642,3455.452637 1507.433228,3455.392090 
	C1513.830566,3455.218262 1516.935425,3458.747314 1518.661987,3464.269531 
	C1512.595215,3469.627197 1504.088257,3468.132324 1497.366211,3471.936279 
	C1492.169800,3474.877441 1485.367432,3474.685791 1482.836670,3482.238281 
	C1481.881104,3485.090576 1477.077271,3486.424072 1473.146118,3484.424316 
	C1469.292114,3482.463623 1470.039307,3478.940918 1470.105347,3475.680176 
	C1470.164673,3472.756104 1472.200562,3470.909424 1474.029541,3468.962402 
	C1475.376709,3467.528320 1477.875366,3465.914062 1477.140137,3464.175293 
	C1474.747070,3458.515869 1479.013428,3455.905762 1481.556396,3452.287842 
	C1485.923706,3446.074219 1489.297974,3439.281494 1492.487793,3432.385010 
	C1494.157959,3428.773926 1494.031006,3423.652100 1500.024414,3423.215088 
	C1501.638916,3423.097412 1501.902466,3420.681641 1501.320679,3418.966553 
	C1500.654541,3417.002686 1500.940552,3415.149658 1501.224609,3413.126221 
	C1502.091431,3406.947266 1503.353882,3401.352051 1506.697510,3395.573242 
	C1512.865479,3384.912354 1517.065430,3373.112793 1522.082275,3361.786133 
	C1522.486450,3360.873535 1523.406860,3359.779053 1523.177368,3359.059082 
	C1520.507202,3350.681885 1527.341797,3346.447510 1531.477661,3340.408203 
	C1536.684326,3336.165771 1543.000977,3334.605225 1546.066528,3328.374512 
	C1547.513428,3325.433838 1551.851074,3325.112305 1555.202637,3325.720215 
	C1563.385864,3327.204346 1570.978882,3324.679443 1578.654907,3322.703125 
	C1581.582886,3321.949463 1584.632080,3320.370850 1586.485962,3324.079834 
	C1588.323975,3327.757324 1586.201782,3330.689697 1583.489624,3332.729004 
	C1579.489502,3335.737549 1571.801636,3335.497314 1574.382568,3343.854004 
	C1566.390259,3345.621338 1562.543579,3355.783203 1553.411743,3354.658691 
	C1562.727905,3360.234375 1571.135498,3358.943604 1575.061890,3348.972168 
	C1578.956543,3339.081299 1586.095093,3332.631104 1593.233276,3325.757324 
	C1595.086304,3323.972900 1597.877808,3322.447998 1595.696045,3319.205322 
	C1593.572632,3316.049561 1590.963379,3317.879883 1588.586670,3318.789062 
	C1577.333984,3323.093994 1566.151978,3327.871338 1554.432373,3320.018066 
	C1551.586060,3318.110596 1546.103271,3317.907715 1547.554688,3313.083496 
	C1548.969849,3308.379883 1554.010254,3309.609863 1557.952881,3309.582764 
	C1573.464600,3309.475830 1588.212769,3303.319580 1603.900635,3304.404541 
	C1597.586304,3302.475830 1591.417603,3303.063965 1585.256836,3304.160889 
	C1582.296631,3304.687988 1579.307739,3305.022217 1576.308594,3304.671143 
	C1573.309082,3304.319824 1569.866577,3304.014404 1569.279053,3300.340088 
	C1568.723267,3296.863770 1571.582764,3294.942383 1574.232178,3293.537354 
	C1579.652344,3290.662842 1585.523315,3289.432129 1591.618286,3290.400879 
	C1595.664917,3291.043945 1599.539917,3291.262451 1604.277344,3288.894043 
	C1600.048340,3287.747803 1595.668579,3286.820312 1596.905884,3281.750000 
	C1597.887207,3277.728271 1601.655884,3277.445068 1604.969604,3277.248291 
	C1609.953491,3276.951660 1615.067871,3275.684082 1619.885864,3276.566406 
	C1625.265015,3277.551025 1629.395874,3276.082520 1633.984497,3273.729248 
	C1641.753540,3269.745605 1644.571411,3271.995850 1646.991211,3280.298828 
	C1649.046631,3287.351562 1656.940552,3285.320068 1662.157471,3287.425049 
	C1663.697388,3288.046387 1665.999756,3286.744873 1668.127686,3285.670166 
	C1664.997070,3285.964600 1662.401123,3288.108643 1659.552612,3287.127197 
	C1655.327271,3285.672607 1651.178101,3283.409912 1651.206055,3278.370605 
	C1651.232544,3273.602051 1655.765869,3272.833008 1659.266113,3272.603027 
	C1665.671753,3272.182129 1671.591675,3271.090576 1677.268555,3267.698975 
	C1682.278076,3264.705811 1686.697998,3265.775391 1688.097290,3273.346924 
	C1689.421997,3269.464355 1690.674805,3266.767334 1691.766235,3263.004395 
	C1687.010498,3264.274902 1682.910645,3266.308594 1678.468140,3266.526367 
	C1674.763794,3266.708252 1670.593872,3266.878174 1669.258423,3262.506592 
	C1668.147217,3258.869385 1670.632935,3255.322266 1673.619019,3254.340576 
	C1681.802612,3251.650146 1684.478394,3246.556396 1684.198120,3238.193848 
	C1684.044067,3233.599121 1687.659180,3229.693848 1691.871216,3226.265137 
	C1692.607422,3225.601807 1692.852295,3225.310791 1693.079956,3224.424316 
	C1692.184937,3220.696777 1693.526733,3218.077637 1694.937988,3215.443848 
	C1698.015015,3209.700928 1703.891479,3205.996094 1706.070923,3199.457275 
	C1706.864868,3197.074951 1710.080933,3196.289307 1713.473389,3197.567383 
	C1713.655396,3199.210449 1713.467041,3200.494141 1712.939331,3201.618652 
	C1710.463989,3206.893555 1710.430786,3206.877930 1717.269531,3207.989746 
	C1718.450195,3208.907471 1718.337280,3209.833008 1718.198608,3210.821289 
	C1717.664795,3214.624512 1710.008545,3215.675537 1713.367188,3220.086914 
	C1716.011963,3223.560547 1720.628052,3219.352051 1724.442871,3219.377686 
	C1725.721069,3219.386230 1727.041626,3219.241455 1728.578247,3218.601807 
	C1726.296387,3214.291992 1721.621826,3210.097168 1727.509277,3204.489258 
	C1730.080322,3201.558350 1732.502686,3201.591553 1735.558105,3203.496338 
	C1737.081665,3205.043213 1738.120972,3206.138428 1739.590820,3207.603760 
	C1739.996460,3208.333252 1739.997681,3208.666748 1739.999512,3209.500000 
	C1742.109375,3214.330566 1747.387085,3212.540039 1750.724365,3215.877930 
	C1751.751709,3217.747314 1751.857788,3219.116699 1751.310669,3221.160156 
	C1749.444702,3224.898926 1741.928345,3224.447021 1745.824829,3231.381348 
	C1745.935791,3234.278320 1745.092041,3236.140869 1742.558960,3237.704590 
	C1738.208008,3239.506348 1739.868652,3244.031982 1736.673218,3246.785889 
	C1742.393433,3245.756348 1747.499268,3247.631104 1752.434448,3246.253906 
	C1752.428345,3243.121582 1749.982056,3242.603760 1748.459229,3240.508789 
	C1748.434082,3234.618652 1752.133423,3233.854004 1757.239502,3234.001465 
	C1763.608521,3234.132812 1764.047363,3239.342041 1766.231689,3243.312012 
z"
        />
        <path
          fill="#D0C8B8"
          opacity="1.000000"
          stroke="none"
          d="
M1777.918945,3242.748535 
	C1777.934448,3241.080078 1777.963867,3240.208740 1778.008789,3238.683350 
	C1782.581299,3235.609619 1785.181152,3231.878174 1786.825928,3226.922119 
	C1790.465820,3215.954834 1796.125732,3206.664551 1809.689575,3205.041992 
	C1812.437378,3206.592041 1814.455200,3209.557617 1816.659790,3205.411377 
	C1817.593506,3203.655518 1818.512695,3201.874023 1819.986938,3200.484375 
	C1823.786255,3196.902344 1835.006226,3194.930664 1837.671875,3197.722900 
	C1841.993286,3202.248291 1838.086670,3206.404541 1836.391602,3210.454834 
	C1826.359741,3234.426270 1817.413452,3258.826904 1807.662598,3282.909424 
	C1805.082031,3289.282715 1802.803955,3295.799561 1802.416992,3303.417480 
	C1801.570557,3304.503418 1800.982666,3304.944336 1800.745361,3305.527832 
	C1796.019653,3317.148926 1788.832642,3327.316406 1782.135986,3337.814209 
	C1777.105591,3345.700439 1771.589478,3353.154785 1766.061401,3360.670166 
	C1761.542969,3366.812988 1755.107178,3372.290771 1754.497559,3381.484863 
	C1750.338257,3385.255127 1746.384766,3387.695557 1740.556641,3386.418457 
	C1739.736938,3382.779785 1740.714233,3379.578125 1736.170288,3377.845947 
	C1731.916382,3376.223877 1731.963135,3373.828857 1736.034668,3372.500488 
	C1740.965820,3370.891602 1743.908447,3368.332764 1745.083740,3363.183105 
	C1745.450439,3361.576416 1746.411743,3360.500977 1745.047363,3358.931641 
	C1744.123779,3357.868896 1744.145874,3355.532227 1745.118652,3355.394287 
	C1753.542969,3354.200928 1751.789673,3346.690674 1753.775879,3341.655762 
	C1755.283691,3337.833740 1758.802368,3336.520264 1761.499878,3334.181641 
	C1762.835938,3333.022949 1764.521240,3330.897461 1763.272095,3330.177734 
	C1756.450562,3326.247314 1761.616821,3323.218750 1763.580078,3319.813965 
	C1764.036499,3319.022461 1764.101196,3317.823975 1763.937012,3316.884033 
	C1762.735596,3310.006592 1766.637085,3307.495850 1772.370117,3307.275879 
	C1773.276123,3304.417236 1771.138062,3303.945801 1770.201172,3302.751953 
	C1768.722046,3300.866943 1768.286499,3299.083496 1771.156738,3298.275146 
	C1773.513428,3297.611816 1775.497681,3296.946289 1775.993408,3294.060791 
	C1776.889404,3288.846191 1778.138062,3283.690918 1778.999878,3278.471436 
	C1779.258911,3276.902588 1777.664062,3276.420410 1776.337769,3276.085693 
	C1772.259155,3275.056641 1771.700684,3273.645752 1775.799927,3271.584717 
	C1777.182007,3270.890137 1777.680786,3269.516602 1776.734253,3268.279297 
	C1771.609741,3261.579834 1774.756348,3255.005127 1776.832031,3248.375244 
	C1777.323853,3246.804688 1777.572266,3245.157471 1777.918945,3242.748535 
z"
        />
        <path
          fill="#E5DDC9"
          opacity="1.000000"
          stroke="none"
          d="
M1550.743896,3488.001221 
	C1549.923706,3485.238281 1549.739624,3482.346436 1546.116333,3482.178711 
	C1542.180298,3481.996582 1538.005859,3481.024902 1534.377563,3483.183350 
	C1528.168823,3486.876953 1522.560303,3486.542725 1517.187866,3481.636719 
	C1516.227051,3480.759521 1504.800293,3482.041260 1503.818970,3482.903320 
	C1501.755371,3484.715332 1503.947388,3490.583984 1507.236816,3491.689941 
	C1508.458374,3492.100342 1509.867065,3491.944092 1511.188232,3492.070312 
	C1512.471680,3492.192871 1513.819580,3492.205566 1514.844604,3494.466553 
	C1510.468140,3497.150879 1504.464600,3495.830811 1500.402466,3500.647217 
	C1497.382446,3504.227783 1489.176025,3501.294434 1486.767456,3507.991699 
	C1489.325928,3511.025879 1494.496094,3505.400146 1495.249634,3510.587402 
	C1495.879028,3514.919678 1491.407349,3515.378906 1488.247070,3515.896729 
	C1484.896118,3516.445801 1482.730835,3517.907959 1481.914307,3521.258057 
	C1480.529785,3526.937744 1474.290527,3525.886475 1470.991577,3528.997559 
	C1470.092896,3529.844727 1468.623779,3528.829346 1467.442749,3528.063477 
	C1459.105469,3522.658691 1458.689575,3522.802490 1452.810913,3532.415771 
	C1455.437744,3534.822266 1460.045410,3533.760498 1462.071533,3537.299561 
	C1463.280273,3539.411133 1463.561646,3541.215332 1460.728638,3541.712891 
	C1455.528564,3542.625977 1450.289673,3543.583984 1445.034302,3543.831543 
	C1440.718506,3544.034668 1439.622803,3542.083740 1441.194946,3538.222900 
	C1444.798706,3529.373047 1447.977539,3520.270264 1455.346191,3513.567627 
	C1457.467041,3511.637939 1458.211670,3509.089355 1457.977539,3506.324951 
	C1457.656372,3502.534668 1459.682495,3499.247803 1460.059448,3495.616455 
	C1460.521484,3491.164551 1464.004028,3489.613281 1467.524170,3488.577637 
	C1471.140503,3487.513672 1472.858643,3489.914062 1474.140869,3493.007324 
	C1475.629883,3496.600586 1478.052612,3496.314453 1480.072998,3493.546631 
	C1482.940796,3489.616943 1486.742432,3489.401611 1490.759155,3490.602295 
	C1495.178345,3491.923340 1496.735840,3489.879395 1495.554932,3486.220947 
	C1494.163940,3481.912354 1496.258911,3481.057861 1499.392334,3478.965332 
	C1507.328979,3473.665771 1515.601929,3477.146973 1523.581909,3477.207275 
	C1528.840088,3477.246826 1534.037109,3478.539795 1539.213623,3476.577637 
	C1539.824097,3476.346191 1540.638550,3476.029785 1541.147949,3476.246826 
	C1550.395630,3480.185791 1553.922729,3473.520996 1557.865723,3466.692139 
	C1563.345215,3463.833984 1569.682007,3462.923340 1570.733643,3455.417725 
	C1570.884888,3454.338623 1572.197998,3453.080566 1573.288452,3452.534424 
	C1577.617798,3450.364990 1578.160156,3446.645264 1578.028198,3442.425049 
	C1577.877319,3437.603760 1579.188721,3433.425537 1584.844604,3429.587646 
	C1584.200073,3436.062256 1585.241089,3440.857910 1587.343140,3445.566406 
	C1588.828735,3448.893555 1588.455811,3452.862061 1587.446411,3456.383789 
	C1586.515137,3459.632812 1586.168091,3462.850342 1585.930420,3466.184082 
	C1585.421143,3473.328125 1583.158203,3475.789062 1576.061890,3475.877930 
	C1569.651855,3475.958252 1564.578735,3476.031982 1563.179443,3484.463867 
	C1562.058350,3491.219482 1555.691772,3487.600586 1550.743896,3488.001221 
z"
        />
        <path
          fill="#E5DDC9"
          opacity="1.000000"
          stroke="none"
          d="
M1530.775146,3521.976074 
	C1528.893433,3517.307617 1532.239136,3514.130859 1533.937378,3510.453125 
	C1536.705444,3504.458984 1539.795532,3498.613525 1543.370361,3492.352783 
	C1545.864990,3492.627686 1548.012085,3494.179688 1549.565674,3493.721191 
	C1555.946533,3491.837158 1561.902100,3493.322266 1567.979248,3494.883301 
	C1571.414429,3495.765869 1572.057251,3494.210205 1571.005371,3491.079102 
	C1569.018677,3485.163818 1569.437500,3484.368896 1575.109497,3484.107910 
	C1578.254761,3483.962891 1581.666626,3483.105225 1584.651123,3485.312988 
	C1584.684570,3488.641357 1581.977417,3488.847412 1580.010864,3489.675293 
	C1578.219849,3490.429443 1575.605347,3490.467529 1575.514038,3492.868164 
	C1575.419922,3495.343262 1577.821289,3495.674561 1579.807861,3495.991211 
	C1580.462891,3496.095703 1581.142700,3496.131348 1581.765381,3496.337402 
	C1584.229248,3497.152344 1585.971924,3498.588867 1585.012207,3501.457520 
	C1584.265869,3503.688232 1583.163330,3506.118896 1580.217896,3505.556396 
	C1567.476807,3503.123047 1557.367676,3509.341797 1547.087280,3515.008789 
	C1546.958984,3518.700928 1553.789917,3518.417969 1551.149292,3523.020752 
	C1548.661499,3527.357178 1545.280396,3524.196777 1542.284912,3524.001221 
	C1538.649414,3523.763672 1535.369507,3521.539062 1530.775146,3521.976074 
z"
        />
        <path
          fill="#E5DDC9"
          opacity="1.000000"
          stroke="none"
          d="
M1555.997559,3463.509766 
	C1550.767334,3469.939453 1543.565796,3466.126709 1537.413574,3467.629395 
	C1534.639160,3468.307373 1534.219971,3464.913330 1533.357544,3462.817627 
	C1532.478394,3460.680664 1532.639160,3459.070312 1535.003906,3457.983154 
	C1540.435425,3455.484863 1542.942871,3451.416260 1541.079346,3444.424805 
	C1545.223022,3450.645264 1549.372192,3453.436768 1555.859009,3448.875244 
	C1557.172852,3447.951172 1560.249878,3449.534668 1563.242432,3449.966309 
	C1564.570557,3452.035400 1567.130859,3454.360352 1562.599609,3455.911621 
	C1559.254028,3456.602295 1556.963989,3457.800537 1555.985596,3461.339844 
	C1556.003662,3462.343018 1556.002441,3462.676514 1555.997559,3463.509766 
z"
        />
        <path
          fill="#C7C0B1"
          opacity="1.000000"
          stroke="none"
          d="
M1739.441528,3385.951660 
	C1744.479736,3385.828613 1747.486938,3380.806885 1753.213379,3381.991943 
	C1753.798462,3384.871582 1754.913330,3388.512695 1751.530396,3389.975342 
	C1746.890015,3391.981445 1754.015747,3396.866699 1748.796631,3398.842773 
	C1745.682251,3400.021973 1743.591064,3402.986084 1745.142212,3406.946045 
	C1745.250732,3407.222900 1745.241455,3407.822754 1745.119629,3407.874023 
	C1738.097046,3410.824219 1739.491821,3418.921387 1734.718872,3423.834961 
	C1733.629395,3417.011719 1734.508423,3409.825928 1738.265137,3404.236816 
	C1742.453003,3398.006104 1742.359131,3393.026611 1738.093140,3386.708496 
	C1737.990234,3385.980469 1738.957764,3385.961426 1739.441528,3385.951660 
z"
        />
        <path
          fill="#E5DDC9"
          opacity="1.000000"
          stroke="none"
          d="
M1527.992676,3525.339844 
	C1523.649902,3525.662842 1519.123901,3529.165039 1514.932861,3525.322998 
	C1513.953857,3524.425537 1512.910767,3523.588135 1513.068359,3522.169189 
	C1513.248047,3520.551025 1514.659302,3520.303223 1515.946777,3520.111328 
	C1519.614014,3519.563965 1523.047974,3520.562744 1527.218140,3521.885986 
	C1527.995239,3522.883545 1527.995239,3523.776611 1527.992676,3525.339844 
z"
        />
        <path
          fill="#E5DDC9"
          opacity="1.000000"
          stroke="none"
          d="
M1568.747192,3429.995117 
	C1568.019165,3427.231201 1568.008911,3424.452393 1567.996582,3420.840332 
	C1569.198608,3420.349121 1570.563965,3420.440430 1571.571899,3421.088135 
	C1573.413940,3422.271240 1575.509033,3423.667236 1575.180908,3426.215088 
	C1574.767822,3429.423828 1572.095947,3429.811768 1568.747192,3429.995117 
z"
        />
        <path
          fill="#E5DDC9"
          opacity="1.000000"
          stroke="none"
          d="
M1579.483154,3519.528564 
	C1576.306274,3519.813232 1573.899902,3519.344727 1574.711426,3516.158447 
	C1575.307739,3513.817383 1577.827759,3512.179688 1579.914062,3513.163574 
	C1582.853638,3514.549316 1582.260986,3517.146729 1579.483154,3519.528564 
z"
        />
        <path
          fill="#EBE4D0"
          opacity="1.000000"
          stroke="none"
          d="
M1556.557617,3461.732666 
	C1553.456299,3461.542480 1551.340454,3460.442627 1553.778931,3457.809326 
	C1555.588745,3455.854492 1557.945190,3453.881348 1561.590454,3455.543213 
	C1560.939087,3458.326172 1559.150269,3460.003662 1556.557617,3461.732666 
z"
        />
        <path
          fill="#E5DDC9"
          opacity="1.000000"
          stroke="none"
          d="
M1715.433350,3266.959229 
	C1715.088623,3264.228760 1716.534180,3264.158447 1718.166748,3264.751709 
	C1718.545654,3264.889648 1718.980225,3265.863770 1718.841064,3266.260986 
	C1718.333008,3267.709717 1717.118408,3267.747070 1715.433350,3266.959229 
z"
        />
        <path
          fill="#E5DDC9"
          opacity="1.000000"
          stroke="none"
          d="
M1568.561768,3445.924561 
	C1568.348022,3444.901123 1568.702515,3443.804932 1569.525391,3442.354492 
	C1572.022217,3443.803467 1571.544189,3445.044922 1568.561768,3445.924561 
z"
        />
        <path
          fill="#C7C0B1"
          opacity="1.000000"
          stroke="none"
          d="
M1654.114746,3537.306396 
	C1650.999390,3537.461182 1646.316650,3539.705078 1645.411865,3536.006348 
	C1644.390991,3531.833496 1650.016724,3531.498291 1652.414185,3529.139893 
	C1653.543213,3528.029053 1655.446899,3527.808594 1656.794678,3529.221924 
	C1658.175659,3530.670166 1657.560791,3532.137939 1656.487061,3533.521484 
	C1655.705811,3534.528320 1654.991455,3535.587158 1654.114746,3537.306396 
z"
        />
        <path
          fill="#C7C0B1"
          opacity="1.000000"
          stroke="none"
          d="
M1657.890869,3505.593262 
	C1660.195679,3504.406250 1662.374512,3503.280273 1662.976196,3506.287109 
	C1663.342651,3508.118896 1661.672119,3509.287842 1659.943359,3509.513428 
	C1657.257080,3509.863770 1656.281982,3508.645508 1657.890869,3505.593262 
z"
        />
        <path
          fill="#C7C0B1"
          opacity="1.000000"
          stroke="none"
          d="
M1671.440063,3507.803711 
	C1669.966064,3509.756348 1668.388550,3510.207275 1667.196289,3508.590820 
	C1666.340576,3507.430908 1667.150269,3506.002686 1668.331055,3505.352295 
	C1670.221680,3504.310791 1671.311890,3505.187012 1671.440063,3507.803711 
z"
        />
        <path
          fill="#D8D1C0"
          opacity="1.000000"
          stroke="none"
          d="
M1653.992676,3537.990234 
	C1654.614014,3538.143555 1655.272949,3538.234375 1655.865356,3538.483643 
	C1657.231445,3539.059326 1657.872314,3540.125488 1657.478149,3541.585205 
	C1657.199951,3542.615479 1656.328613,3543.346191 1655.348999,3542.790771 
	C1654.115112,3542.091309 1654.104858,3540.628906 1654.002686,3538.658691 
	C1653.982300,3537.990234 1654.000000,3538.000000 1653.992676,3537.990234 
z"
        />
        <path
          fill="#334D63"
          opacity="1.000000"
          stroke="none"
          d="
M2003.130615,2674.820312 
	C2006.610474,2673.515137 2009.979492,2675.424805 2013.579468,2675.818115 
	C2015.445679,2676.021973 2017.797729,2676.592773 2018.768555,2674.197021 
	C2019.549927,2672.269043 2017.787476,2671.270508 2016.582397,2670.204346 
	C2015.829712,2669.538330 2015.068970,2668.930664 2015.517578,2666.945068 
	C2037.736206,2668.595947 2059.781982,2674.079346 2081.933350,2673.961182 
	C2083.935303,2669.760986 2079.027344,2670.376465 2079.347168,2667.155273 
	C2086.563477,2664.216309 2094.689453,2667.361572 2102.270508,2665.043213 
	C2109.671387,2662.780273 2117.583008,2663.245850 2125.021729,2664.484131 
	C2132.925049,2665.799805 2140.566162,2666.480957 2148.493164,2664.434814 
	C2142.834717,2661.468994 2136.722900,2660.423828 2130.473145,2660.034912 
	C2126.829102,2659.808350 2123.166504,2659.896973 2119.516113,2659.747559 
	C2118.073730,2659.688721 2116.967773,2658.847900 2116.734131,2657.390381 
	C2116.498535,2655.924316 2117.286621,2654.575684 2118.715088,2654.479736 
	C2127.755615,2653.874023 2136.591309,2649.474854 2145.898926,2654.724365 
	C2157.058594,2661.017822 2169.902100,2661.809326 2182.282227,2663.854492 
	C2184.520508,2664.224365 2187.590576,2664.613281 2189.098389,2663.457031 
	C2195.012695,2658.921875 2201.673340,2660.187012 2209.091309,2660.012207 
	C2211.170654,2658.921631 2212.554688,2658.345947 2214.929688,2658.469727 
	C2218.430176,2659.862305 2221.057861,2661.525146 2225.096680,2660.988525 
	C2242.610352,2666.127441 2260.012695,2661.790283 2276.923828,2664.607178 
	C2282.212158,2665.488037 2287.537842,2665.626465 2291.424561,2671.342773 
	C2292.281738,2673.455566 2293.402832,2675.416016 2290.852295,2675.815430 
	C2285.878174,2676.594482 2281.134521,2675.416992 2276.381104,2673.894043 
	C2267.077393,2670.913086 2260.295898,2672.178223 2251.188965,2678.146729 
	C2253.554932,2682.467773 2261.422363,2679.652588 2261.908691,2687.106934 
	C2247.040771,2688.352539 2232.388672,2686.101318 2217.690674,2684.393311 
	C2215.075439,2684.089600 2212.418457,2684.144043 2208.906494,2684.038574 
	C2190.806152,2684.354004 2173.687744,2683.406982 2156.692627,2680.302246 
	C2153.185791,2679.661621 2149.277100,2679.731689 2145.837158,2680.598633 
	C2141.807861,2681.614014 2138.175049,2681.913086 2134.213867,2680.493652 
	C2130.206055,2679.057129 2126.346436,2680.751465 2122.632812,2682.135254 
	C2118.148438,2683.805664 2113.802490,2684.466553 2109.073730,2682.833740 
	C2106.648682,2681.996338 2103.852539,2682.109863 2101.217041,2682.026855 
	C2091.973145,2681.736328 2082.779785,2681.378174 2073.527100,2680.478027 
	C2060.831543,2679.243408 2047.888062,2680.236084 2035.074097,2680.800049 
	C2032.370483,2680.919189 2029.899536,2680.686523 2027.320190,2680.281982 
	C2024.736694,2679.876465 2022.736084,2680.663330 2021.947510,2684.301270 
	C2015.451538,2682.400879 2008.011108,2681.696533 2003.130615,2674.820312 
z"
        />
        <path
          fill="#4C6880"
          opacity="1.000000"
          stroke="none"
          d="
M2411.689697,2643.155029 
	C2418.820801,2646.333008 2425.552734,2648.510010 2432.989746,2646.811523 
	C2436.492676,2646.011475 2440.228760,2646.117676 2443.863525,2646.044678 
	C2446.944336,2645.982910 2450.749512,2645.937744 2452.086914,2643.433105 
	C2454.976807,2638.020020 2458.682861,2638.386230 2463.558350,2639.651367 
	C2465.553955,2640.169434 2468.011475,2638.564209 2470.291504,2638.412109 
	C2473.581543,2638.192383 2477.186768,2637.590088 2480.151855,2638.614746 
	C2487.485596,2641.149658 2494.866943,2642.370361 2502.588135,2642.249023 
	C2504.496094,2642.218994 2506.419678,2643.211426 2509.145264,2643.835449 
	C2512.187500,2644.217529 2514.610107,2643.497070 2517.191895,2645.685059 
	C2514.089600,2649.185303 2510.124023,2652.792236 2514.597412,2658.019775 
	C2507.341797,2658.019775 2501.523926,2658.406250 2495.774658,2657.942627 
	C2481.868408,2656.821289 2468.278564,2660.999512 2454.407227,2660.093994 
	C2452.687988,2659.981689 2451.216309,2660.758057 2450.213379,2663.216309 
	C2450.103271,2665.187744 2449.777100,2666.232910 2448.485107,2667.476318 
	C2430.981201,2666.457275 2414.791504,2660.515869 2397.397217,2659.350098 
	C2378.959473,2658.114258 2360.530273,2656.567139 2342.034424,2655.543945 
	C2330.814941,2654.923340 2319.594482,2654.260986 2308.350586,2653.894775 
	C2304.094971,2653.756348 2299.868896,2652.708740 2294.763428,2652.128662 
	C2281.176514,2653.087402 2270.195312,2647.885986 2259.266602,2642.180908 
	C2267.977539,2641.007568 2276.322510,2643.529785 2284.721191,2644.752686 
	C2292.227783,2645.845947 2299.718994,2645.938232 2307.182373,2646.498047 
	C2319.293213,2647.406250 2331.399902,2644.584961 2343.520752,2647.551514 
	C2349.149658,2648.929443 2355.395020,2647.787109 2362.531982,2647.787109 
	C2354.323975,2640.763428 2344.949707,2641.504395 2336.274414,2639.965332 
	C2332.887451,2639.364502 2328.812012,2641.475098 2326.074219,2636.662598 
	C2329.924316,2631.810547 2334.982666,2632.915771 2339.879395,2633.278809 
	C2353.926514,2634.319824 2367.644531,2637.420166 2382.144775,2635.403809 
	C2391.867432,2634.051514 2402.582520,2635.965088 2411.689697,2643.155029 
z"
        />
        <path
          fill="#4C6880"
          opacity="1.000000"
          stroke="none"
          d="
M2216.390137,2660.521973 
	C2214.408447,2660.083252 2212.935791,2660.061768 2210.726807,2660.025146 
	C2210.000488,2658.535889 2210.010742,2657.062256 2210.038574,2654.854980 
	C2223.998291,2656.521729 2238.196045,2657.018555 2252.090332,2659.863281 
	C2256.706787,2660.808350 2261.190186,2658.667725 2265.740967,2658.134766 
	C2274.642090,2657.092285 2283.506104,2658.239990 2293.129883,2659.965332 
	C2296.086914,2661.708496 2296.345215,2663.710449 2294.363525,2665.593506 
	C2292.883789,2666.999512 2292.056641,2668.535156 2292.014893,2671.250244 
	C2278.212646,2667.241699 2264.241455,2664.948730 2249.627930,2667.632080 
	C2244.511963,2668.571289 2239.065918,2669.079590 2234.323975,2665.579102 
	C2231.741455,2663.673340 2228.637939,2664.130615 2224.857910,2663.951660 
	C2222.215820,2661.587646 2219.061035,2662.448730 2216.390137,2660.521973 
z"
        />
        <path
          fill="#597288"
          opacity="1.000000"
          stroke="none"
          d="
M2448.867920,2667.920654 
	C2448.416748,2666.812012 2448.755127,2665.747070 2449.551758,2664.336426 
	C2450.893311,2663.997559 2451.776611,2664.004639 2453.322754,2664.022461 
	C2454.510010,2665.018799 2455.034668,2666.004395 2455.779541,2667.504883 
	C2453.885986,2668.001221 2451.771729,2667.982910 2448.867920,2667.920654 
z"
        />
        <path
          fill="#597288"
          opacity="1.000000"
          stroke="none"
          d="
M2357.206543,2685.650879 
	C2355.283447,2685.840576 2353.540527,2685.826416 2353.348633,2684.454834 
	C2353.012207,2682.052979 2355.172363,2682.096436 2356.675537,2682.324463 
	C2358.553955,2682.609131 2359.643555,2683.638916 2357.206543,2685.650879 
z"
        />
        <path
          fill="#172B3E"
          opacity="1.000000"
          stroke="none"
          d="
M2713.936035,3163.300049 
	C2712.252441,3163.819092 2710.727051,3163.298340 2710.071045,3160.688721 
	C2711.749756,3160.181396 2713.277832,3160.695801 2713.936035,3163.300049 
z"
        />
        <path
          fill="#172B3E"
          opacity="1.000000"
          stroke="none"
          d="
M2721.999512,3165.270996 
	C2720.979736,3165.513916 2719.847900,3165.210693 2718.357910,3164.423828 
	C2719.738770,3162.007568 2721.006104,3162.412109 2721.999512,3165.270996 
z"
        />
        <path
          fill="#0D171F"
          opacity="1.000000"
          stroke="none"
          d="
M2650.975342,3318.359619 
	C2648.687500,3319.936768 2645.835205,3320.663330 2644.371094,3318.722656 
	C2638.041504,3310.332031 2626.129395,3306.777832 2625.064941,3293.493408 
	C2624.489990,3286.318604 2617.680908,3281.235352 2610.797119,3277.955322 
	C2608.701416,3276.957275 2606.384766,3276.297852 2604.510254,3274.993652 
	C2602.428711,3273.544189 2599.659424,3271.898682 2601.197754,3268.634033 
	C2602.523682,3265.818848 2604.967285,3266.858643 2607.291260,3267.387207 
	C2611.588867,3268.364990 2613.556396,3265.948486 2613.872070,3262.056396 
	C2614.140137,3258.745850 2613.978027,3255.400635 2614.028809,3251.218018 
	C2610.584961,3246.455566 2614.360352,3243.068359 2614.778076,3239.424561 
	C2615.184570,3235.879395 2615.402100,3232.273438 2614.071289,3228.045410 
	C2613.920898,3227.312500 2613.981689,3226.952637 2614.041260,3226.780273 
	C2608.343506,3221.517822 2604.058594,3225.545654 2599.100098,3229.337891 
	C2594.041016,3237.182861 2589.626221,3244.552490 2587.803467,3253.337402 
	C2586.753906,3258.395264 2588.109375,3264.942627 2580.830322,3267.890137 
	C2582.049316,3255.958252 2587.187988,3244.858154 2590.403320,3233.188721 
	C2590.922363,3231.304932 2591.437256,3229.420166 2591.965576,3226.758789 
	C2595.835693,3215.492432 2596.870361,3204.318848 2599.955078,3192.833008 
	C2602.021729,3192.056396 2603.956543,3192.056885 2606.595215,3192.079590 
	C2610.267334,3195.301758 2613.250732,3198.475098 2618.877441,3198.302246 
	C2621.831299,3199.753418 2623.969971,3201.402100 2626.485840,3202.217529 
	C2633.938721,3204.633057 2637.054932,3209.470947 2635.695312,3217.312988 
	C2635.071533,3220.911133 2636.379150,3224.517578 2636.329102,3228.922607 
	C2635.998291,3231.751953 2635.161133,3233.607666 2633.810547,3236.038330 
	C2629.495850,3239.306641 2629.559814,3244.251465 2627.647461,3246.844482 
	C2627.796387,3246.628174 2628.228027,3245.360596 2628.943115,3244.271240 
	C2630.127441,3242.467041 2630.575928,3239.289062 2633.688477,3240.288330 
	C2636.221191,3241.101562 2636.405273,3243.864990 2636.674316,3246.160400 
	C2637.218262,3250.800049 2636.893066,3255.472900 2637.012695,3260.129883 
	C2637.195801,3267.242432 2638.293213,3274.059082 2643.618652,3280.308594 
	C2645.372559,3287.309082 2647.092773,3293.597168 2645.580566,3300.921875 
	C2644.549561,3307.932861 2648.906250,3312.388428 2650.975342,3318.359619 
z"
        />
        <path
          fill="#102131"
          opacity="1.000000"
          stroke="none"
          d="
M2617.980957,3106.711914 
	C2630.214111,3106.421631 2642.568604,3104.528564 2654.636719,3108.077881 
	C2655.932617,3108.458984 2657.161865,3108.768066 2658.926270,3111.149658 
	C2645.292480,3113.941406 2632.552734,3108.206055 2618.912109,3109.991943 
	C2617.987305,3109.108643 2617.984375,3108.237061 2617.980957,3106.711914 
z"
        />
        <path
          fill="#6F7981"
          opacity="1.000000"
          stroke="none"
          d="
M2735.464355,3228.073730 
	C2735.421387,3230.373047 2734.726807,3232.261230 2733.911377,3234.752197 
	C2730.957764,3245.721436 2727.747559,3247.443115 2718.368652,3243.303955 
	C2712.600586,3240.758789 2707.072510,3237.699707 2700.747314,3234.609375 
	C2699.182373,3233.934814 2698.301514,3233.532471 2696.728271,3232.953125 
	C2686.526367,3230.743408 2677.030762,3226.447266 2666.794189,3232.902344 
	C2665.132812,3233.400635 2664.145264,3233.445068 2662.446289,3233.034668 
	C2656.448486,3226.624268 2658.434814,3222.845947 2664.763184,3220.164062 
	C2662.132324,3218.605713 2657.036133,3218.635498 2655.873535,3212.796143 
	C2664.031982,3212.422607 2671.966309,3213.090820 2677.707275,3219.594971 
	C2680.364502,3222.604492 2683.423096,3222.807861 2686.648682,3221.552734 
	C2690.473145,3220.064453 2694.387695,3220.077148 2699.202637,3219.889160 
	C2712.365723,3219.254639 2723.516357,3223.781982 2735.464355,3228.073730 
z"
        />
        <path
          fill="#233A4F"
          opacity="1.000000"
          stroke="none"
          d="
M2655.889160,3211.359863 
	C2657.549072,3217.333008 2663.773682,3215.361572 2666.585693,3219.123291 
	C2666.491455,3223.426514 2656.804932,3224.305420 2662.024170,3231.464600 
	C2660.224121,3239.904297 2652.735596,3238.956787 2646.751953,3241.716309 
	C2643.355469,3242.299561 2640.888184,3241.882324 2638.102051,3239.846680 
	C2635.480713,3237.079834 2635.320068,3234.136719 2634.384033,3230.734131 
	C2633.952393,3224.297363 2630.418457,3218.674316 2633.401855,3212.603271 
	C2634.672852,3210.016602 2632.504150,3208.394287 2630.333496,3207.197021 
	C2626.580566,3205.126709 2622.739502,3203.161377 2619.836914,3198.980225 
	C2632.333496,3200.403320 2644.242188,3204.886963 2655.889160,3211.359863 
z"
        />
        <path
          fill="#102131"
          opacity="1.000000"
          stroke="none"
          d="
M2654.612793,3379.957520 
	C2654.488525,3376.836426 2655.917480,3375.298096 2659.584229,3377.512695 
	C2658.869141,3379.694336 2657.141602,3379.977295 2654.612793,3379.957520 
z"
        />
        <path
          fill="#E7E3DA"
          opacity="1.000000"
          stroke="none"
          d="
M2249.481934,2827.452148 
	C2258.898438,2832.009033 2265.842041,2838.721924 2273.624268,2845.429199 
	C2283.787109,2854.284180 2293.947021,2862.197998 2301.844727,2872.459961 
	C2302.521729,2871.797119 2302.774902,2871.505859 2303.395996,2870.756836 
	C2304.541260,2869.380859 2305.249512,2868.416504 2306.075684,2866.775879 
	C2307.071533,2865.130127 2307.939453,2864.211182 2309.639893,2863.263184 
	C2311.227783,2863.699951 2312.238770,2864.422363 2313.580811,2865.671875 
	C2319.239258,2875.488281 2325.254150,2884.359131 2330.203369,2894.252930 
	C2328.057373,2896.556885 2325.986572,2897.764404 2323.123535,2898.977051 
	C2315.495117,2897.395508 2313.871826,2889.742188 2307.827148,2886.103271 
	C2306.460449,2886.004395 2307.261719,2883.837891 2306.392578,2885.145020 
	C2313.319580,2888.511963 2314.602295,2896.572998 2321.523926,2899.841797 
	C2324.224609,2899.866943 2325.895752,2898.803955 2328.443848,2898.004150 
	C2331.296875,2897.521729 2333.343262,2897.728760 2335.949219,2899.091309 
	C2338.316406,2901.973877 2338.334717,2904.499512 2336.596191,2907.704590 
	C2333.895996,2909.987305 2331.284180,2909.363037 2328.110840,2909.640869 
	C2318.031982,2912.541992 2312.421387,2920.828613 2310.138672,2928.337891 
	C2307.405762,2937.328125 2303.915039,2945.953857 2299.752441,2954.013672 
	C2293.081543,2966.930176 2284.574951,2978.917480 2276.059326,2990.768555 
	C2273.968506,2993.678467 2271.077637,2996.194336 2266.427734,2996.608398 
	C2262.680176,2994.352539 2261.798096,2990.978027 2260.453369,2987.056885 
	C2258.978516,2981.522705 2261.986328,2977.171875 2261.799316,2971.759766 
	C2262.627441,2962.635254 2265.178467,2954.957764 2272.527832,2949.973145 
	C2278.748047,2945.754883 2274.766846,2940.637451 2274.313232,2935.086182 
	C2277.194580,2926.760010 2279.613281,2919.733643 2270.196777,2914.007324 
	C2265.591797,2911.206543 2266.357178,2905.636719 2268.285889,2900.425537 
	C2271.422119,2891.952637 2278.242188,2885.601318 2281.302734,2877.049561 
	C2277.992920,2874.021973 2275.818115,2876.587891 2273.572510,2878.286133 
	C2263.465576,2885.928223 2263.205566,2885.881592 2257.419189,2874.332764 
	C2256.816895,2873.130371 2255.942871,2872.139648 2254.854004,2870.412109 
	C2253.747559,2867.913818 2253.652832,2865.991455 2254.208984,2863.333496 
	C2259.840576,2854.485840 2258.584961,2849.109863 2248.375488,2838.103027 
	C2246.247803,2833.995605 2244.900635,2830.524414 2249.481934,2827.452148 
z"
        />
        <path
          fill="#374D60"
          opacity="1.000000"
          stroke="none"
          d="
M2405.475098,2887.430908 
	C2405.795898,2891.965088 2407.559814,2893.433350 2411.709961,2894.156738 
	C2420.605713,2895.707520 2429.349365,2898.150635 2438.122803,2900.358154 
	C2439.604980,2900.730713 2441.380371,2901.174561 2441.819824,2903.277588 
	C2437.241455,2905.289307 2432.050293,2902.588623 2427.092773,2905.309082 
	C2427.796875,2908.774170 2431.488037,2907.442627 2433.575439,2909.571289 
	C2433.653809,2915.120605 2436.633301,2916.222412 2440.843018,2916.010742 
	C2448.324463,2915.634277 2455.394043,2917.717041 2462.764648,2920.832764 
	C2457.614746,2921.885254 2452.494629,2923.137207 2447.303467,2923.912109 
	C2444.387207,2924.347168 2442.203857,2925.040527 2441.842773,2929.151855 
	C2436.104004,2934.085693 2431.750000,2929.899170 2427.339600,2927.441406 
	C2410.256348,2917.921875 2392.660889,2909.477783 2374.706299,2901.755371 
	C2371.924316,2900.558594 2368.941895,2900.281738 2365.322754,2899.508301 
	C2362.798828,2898.420654 2361.785400,2896.810791 2360.546875,2894.519043 
	C2359.419434,2891.404785 2358.259766,2889.017334 2357.727539,2885.776123 
	C2358.084473,2881.528564 2360.111328,2880.073730 2363.647705,2879.992188 
	C2371.969482,2879.799561 2380.228271,2881.040771 2387.527344,2884.632568 
	C2391.968262,2886.818115 2395.516357,2888.491943 2400.218262,2886.369141 
	C2401.711670,2885.694824 2403.452148,2886.058838 2405.475098,2887.430908 
z"
        />
        <path
          fill="#C4C8C1"
          opacity="1.000000"
          stroke="none"
          d="
M2357.040283,2884.906738 
	C2359.462646,2886.812988 2361.722412,2888.068359 2362.057861,2891.380859 
	C2360.761719,2893.576660 2358.913818,2894.561035 2357.676514,2896.102783 
	C2350.373291,2905.202637 2350.492432,2905.108398 2340.927246,2897.640869 
	C2339.521240,2896.542969 2339.281250,2900.392090 2336.642334,2900.166504 
	C2333.952148,2899.896729 2331.920654,2899.866943 2329.127930,2899.800293 
	C2326.791016,2898.598389 2326.617432,2897.051514 2327.691406,2894.673828 
	C2329.000977,2893.343506 2329.703369,2891.803223 2329.079590,2891.572998 
	C2318.336426,2887.609131 2317.029053,2877.729492 2314.446289,2868.635742 
	C2314.355957,2868.317139 2314.230225,2868.008789 2314.016602,2866.947510 
	C2324.199463,2867.034912 2332.334961,2873.012451 2341.058594,2877.576660 
	C2346.036377,2880.180664 2350.870117,2883.086670 2357.040283,2884.906738 
z"
        />
        <path
          fill="#9DAAB4"
          opacity="1.000000"
          stroke="none"
          d="
M2303.878906,2871.448730 
	C2304.035645,2873.662109 2304.248291,2875.359619 2303.953613,2876.963867 
	C2303.412842,2879.904053 2301.715820,2879.723877 2299.763672,2878.148682 
	C2299.509277,2877.943604 2299.201172,2877.705566 2299.104004,2877.419678 
	C2295.133301,2865.750732 2285.284424,2859.158936 2276.768799,2851.392090 
	C2275.607910,2850.333008 2275.010498,2848.656738 2274.135254,2846.569580 
	C2276.273926,2846.603027 2278.428955,2847.333496 2281.277832,2848.062012 
	C2288.399170,2852.504883 2295.831055,2854.872314 2303.121582,2858.708496 
	C2305.309082,2861.448242 2307.182129,2863.630859 2307.521973,2867.410156 
	C2307.476807,2869.186035 2307.232910,2870.274414 2306.501465,2871.665527 
	C2305.107178,2871.234619 2304.044434,2870.700195 2302.942627,2869.318848 
	C2302.271729,2868.364014 2301.912109,2867.904785 2301.543457,2867.452881 
	C2301.172119,2866.998291 2301.291260,2867.150391 2302.260498,2868.367676 
	C2302.971680,2869.520020 2303.333496,2870.208496 2303.878906,2871.448730 
z"
        />
        <path
          fill="#294159"
          opacity="1.000000"
          stroke="none"
          d="
M2649.936768,2922.064453 
	C2644.097900,2922.094727 2638.682861,2919.994385 2632.535156,2918.482910 
	C2635.323975,2916.367676 2634.454346,2912.514893 2636.412842,2909.976562 
	C2641.239258,2903.720703 2647.551025,2903.527832 2655.224609,2904.055664 
	C2656.078369,2904.954834 2656.042236,2905.823975 2656.002930,2907.346680 
	C2654.013184,2911.477539 2649.399170,2910.088135 2646.552979,2913.297363 
	C2642.318359,2918.343750 2647.954590,2917.437256 2649.604980,2919.576660 
	C2650.378662,2920.359863 2650.757080,2920.660156 2650.599121,2921.448242 
	C2650.062500,2921.936035 2649.978760,2922.021729 2649.936768,2922.064453 
z"
        />
        <path
          fill="#C4C8C1"
          opacity="1.000000"
          stroke="none"
          d="
M2308.054199,2867.464355 
	C2304.938965,2866.437744 2303.955566,2863.800293 2303.570801,2860.014648 
	C2305.822510,2859.241943 2307.697266,2860.437256 2309.838135,2862.435547 
	C2309.607422,2864.262207 2308.997803,2865.546875 2308.054199,2867.464355 
z"
        />
        <path
          fill="#1F3041"
          opacity="1.000000"
          stroke="none"
          d="
M2665.895020,2903.786133 
	C2665.743896,2904.103027 2665.383545,2904.100098 2664.482178,2904.082031 
	C2664.363037,2903.402344 2664.787842,2902.746582 2665.895020,2903.786133 
z"
        />
        <path
          fill="#233A4F"
          opacity="1.000000"
          stroke="none"
          d="
M2646.980957,2913.796875 
	C2645.631836,2907.170410 2650.348389,2908.071289 2655.198242,2908.002686 
	C2659.862549,2910.477295 2664.281982,2909.729736 2668.498535,2910.208252 
	C2670.242188,2910.406006 2671.722900,2911.317383 2671.331543,2913.406250 
	C2670.936523,2915.511475 2669.163330,2915.867676 2667.524414,2915.333496 
	C2661.077637,2913.232178 2654.430176,2914.262207 2646.980957,2913.796875 
z"
        />
        <path
          fill="#172B3E"
          opacity="1.000000"
          stroke="none"
          d="
M2650.712158,2921.935303 
	C2650.062500,2921.936035 2650.018555,2920.997559 2650.009277,2920.528320 
	C2651.468750,2920.056396 2652.937744,2920.053467 2655.137207,2920.068848 
	C2654.800293,2921.762451 2653.115967,2921.933350 2650.712158,2921.935303 
z"
        />
        <path
          fill="#233A4F"
          opacity="1.000000"
          stroke="none"
          d="
M2673.841797,2883.246582 
	C2667.803711,2884.896729 2662.056152,2887.587891 2656.133301,2881.096191 
	C2663.109619,2880.208496 2668.922363,2877.861328 2673.841797,2883.246582 
z"
        />
        <path
          fill="#B6BCC1"
          opacity="1.000000"
          stroke="none"
          d="
M2190.010742,2792.129639 
	C2193.281738,2797.690430 2199.254395,2797.716309 2203.512695,2801.511230 
	C2204.305176,2802.194580 2204.578369,2802.467041 2204.890137,2803.315674 
	C2204.746826,2804.629150 2204.488281,2805.337402 2204.649170,2806.282959 
	C2205.441650,2806.868164 2205.714111,2807.178223 2206.300781,2808.045654 
	C2211.197021,2818.562256 2218.600830,2827.081787 2221.821533,2838.512695 
	C2222.727051,2841.379639 2223.531250,2843.522705 2225.553711,2845.861816 
	C2228.336914,2851.829102 2233.107178,2856.436035 2230.836182,2863.764404 
	C2226.270752,2865.848389 2221.136230,2863.919922 2218.814209,2870.790283 
	C2217.223633,2875.496094 2212.324707,2874.134766 2210.086426,2869.317383 
	C2209.240723,2867.497314 2208.796143,2865.354004 2209.251709,2863.475098 
	C2213.504639,2845.928467 2202.892578,2833.133545 2195.258789,2819.427734 
	C2190.909180,2811.618652 2184.342285,2805.230957 2180.366943,2796.256836 
	C2179.919434,2793.840088 2180.126221,2792.242432 2181.319824,2790.038574 
	C2184.998291,2787.837158 2187.346191,2789.258545 2190.010742,2792.129639 
z"
        />
        <path
          fill="#9FA2A1"
          opacity="1.000000"
          stroke="none"
          d="
M2203.613525,2804.698730 
	C2203.902832,2803.838623 2203.919434,2803.454834 2203.983887,2802.496826 
	C2210.362549,2802.181396 2215.037109,2805.752686 2219.785889,2810.264893 
	C2221.147217,2813.134521 2222.556885,2815.100830 2225.819336,2815.760498 
	C2234.125732,2819.286621 2241.814941,2822.819824 2249.896484,2826.910645 
	C2246.421875,2830.326904 2249.009277,2833.441895 2250.160400,2837.176758 
	C2250.828369,2838.528320 2251.128174,2839.248779 2251.177979,2840.599365 
	C2250.918945,2841.480957 2250.740723,2841.799805 2250.124023,2842.387939 
	C2249.071045,2842.663574 2248.503174,2842.826660 2248.202148,2843.534180 
	C2249.147217,2844.667969 2249.891357,2845.396484 2250.824219,2846.747070 
	C2251.339355,2847.599854 2251.526855,2847.954346 2251.958008,2848.860352 
	C2252.782471,2851.347900 2253.706787,2853.118164 2255.084961,2855.356445 
	C2255.980469,2857.503418 2256.221680,2859.127930 2255.525879,2861.396240 
	C2255.601318,2864.085449 2255.895264,2866.186035 2256.320801,2869.071777 
	C2257.563965,2872.258057 2256.778564,2873.985840 2253.782959,2875.383301 
	C2252.494385,2875.528809 2251.976807,2875.493164 2250.743164,2875.178711 
	C2242.968994,2865.553955 2241.370361,2853.601074 2234.770264,2843.874023 
	C2227.296143,2832.859375 2218.979248,2822.729980 2209.482910,2813.495605 
	C2207.556152,2811.622070 2205.921387,2809.590332 2204.447266,2806.522217 
	C2204.037354,2805.927246 2203.997803,2806.000977 2204.039062,2805.993652 
	C2203.833740,2805.716064 2203.587158,2805.445557 2203.613525,2804.698730 
z"
        />
        <path
          fill="#233A4F"
          opacity="1.000000"
          stroke="none"
          d="
M2379.927734,2832.039062 
	C2373.843750,2831.735107 2368.567627,2832.798340 2363.545410,2831.043945 
	C2363.710938,2827.415283 2366.230469,2828.025391 2367.927002,2828.113281 
	C2379.438721,2828.709229 2391.074219,2826.449219 2402.479980,2829.626221 
	C2404.807617,2830.274658 2407.018555,2829.247070 2409.253418,2828.631104 
	C2413.469482,2827.470215 2417.807373,2828.053711 2422.092773,2828.037598 
	C2424.348389,2828.029053 2427.363770,2827.891602 2427.240479,2831.106934 
	C2427.119629,2834.264404 2424.082031,2833.938965 2421.840820,2833.946289 
	C2411.789551,2833.978760 2401.698730,2837.094971 2391.679932,2832.548584 
	C2388.627197,2831.163086 2384.501465,2832.142578 2379.927734,2832.039062 
z"
        />
        <path
          fill="#233A4F"
          opacity="1.000000"
          stroke="none"
          d="
M2321.279297,2827.364502 
	C2326.692383,2823.005615 2337.111816,2823.259033 2340.778564,2827.291992 
	C2341.301758,2827.867432 2341.468506,2829.261963 2341.141602,2829.976074 
	C2340.816162,2830.687012 2339.391846,2831.633057 2338.912109,2831.422363 
	C2333.376709,2828.993652 2326.866455,2831.663818 2321.279297,2827.364502 
z"
        />
        <path
          fill="#21364A"
          opacity="1.000000"
          stroke="none"
          d="
M2190.382568,2791.977539 
	C2187.728027,2793.268799 2185.762451,2791.292480 2182.692627,2790.238770 
	C2181.229736,2789.993652 2180.627930,2789.651611 2179.924316,2788.632080 
	C2178.971680,2787.156738 2178.221680,2786.174805 2177.160889,2784.644287 
	C2182.514648,2784.156982 2188.723877,2783.183594 2190.382568,2791.977539 
z"
        />
        <path
          fill="#F9F4E5"
          opacity="1.000000"
          stroke="none"
          d="
M2225.966309,2815.269287 
	C2221.499023,2819.977295 2221.155273,2815.251953 2219.948730,2811.616211 
	C2221.856201,2812.164551 2223.676270,2813.474854 2225.966309,2815.269287 
z"
        />
        <path
          fill="#4E6477"
          opacity="1.000000"
          stroke="none"
          d="
M2247.999756,2782.532471 
	C2249.380127,2781.859619 2250.917480,2781.855713 2253.220459,2781.824707 
	C2252.088379,2786.624756 2251.590576,2786.744629 2247.999756,2782.532471 
z"
        />
        <path
          fill="#395166"
          opacity="1.000000"
          stroke="none"
          d="
M2217.070312,2747.985840 
	C2220.973633,2750.162842 2218.293213,2752.450195 2218.034912,2755.323975 
	C2199.449707,2758.407959 2181.403809,2754.539551 2163.143799,2752.624268 
	C2152.393066,2751.496582 2141.554932,2749.507324 2129.606445,2750.292480 
	C2128.602783,2750.404053 2128.195068,2750.400391 2128.103271,2750.218750 
	C2127.938721,2746.727539 2128.670654,2744.056152 2132.759766,2744.041504 
	C2133.089844,2744.040283 2133.429199,2743.958740 2133.749023,2744.009766 
	C2148.670166,2746.386475 2163.710449,2743.770752 2178.750488,2745.409180 
	C2191.120117,2746.756592 2203.559326,2749.101562 2217.070312,2747.985840 
z"
        />
        <path
          fill="#233A4F"
          opacity="1.000000"
          stroke="none"
          d="
M2598.363281,2815.933105 
	C2604.550049,2815.786133 2609.477295,2818.785156 2614.903564,2817.954346 
	C2616.509277,2817.708496 2618.343018,2817.973145 2619.036133,2816.003906 
	C2619.620117,2814.344971 2617.615967,2810.946289 2620.236328,2811.127686 
	C2624.046875,2811.391113 2628.359375,2811.105713 2631.709473,2813.432129 
	C2634.338135,2815.257812 2634.566162,2817.916992 2631.646484,2819.932861 
	C2627.152100,2823.035156 2622.201660,2825.040527 2616.664551,2823.701416 
	C2605.813477,2821.077148 2595.023438,2818.200195 2583.379639,2815.215332 
	C2588.913574,2812.705078 2593.152100,2815.120605 2598.363281,2815.933105 
z"
        />
        <path
          fill="#445E74"
          opacity="1.000000"
          stroke="none"
          d="
M2407.316895,2791.721680 
	C2408.062988,2794.833740 2408.196289,2797.524414 2411.885010,2797.876465 
	C2412.876221,2797.971191 2413.882324,2797.986816 2414.855713,2798.172852 
	C2416.872070,2798.558105 2419.105957,2799.274414 2418.803955,2801.699707 
	C2418.455566,2804.499268 2415.829590,2804.040283 2414.063232,2803.525879 
	C2410.518799,2802.494141 2406.869141,2802.477051 2403.316895,2801.867188 
	C2391.908203,2799.908691 2380.224854,2800.454102 2368.910889,2797.827881 
	C2367.211670,2797.433594 2365.850342,2796.633057 2364.371338,2794.665039 
	C2365.194824,2793.510986 2366.384766,2792.625732 2367.621826,2792.553223 
	C2378.724365,2791.902588 2389.860596,2791.849609 2400.898926,2790.130371 
	C2402.682617,2789.852539 2404.677490,2790.932373 2407.316895,2791.721680 
z"
        />
        <path
          fill="#21364A"
          opacity="1.000000"
          stroke="none"
          d="
M2378.930176,2781.939453 
	C2378.450439,2780.603271 2378.625732,2778.849365 2380.046875,2778.730469 
	C2385.337158,2778.288330 2390.934326,2775.817871 2395.856445,2781.284668 
	C2390.606201,2785.824707 2385.160645,2782.625488 2378.930176,2781.939453 
z"
        />
        <path
          fill="#374D60"
          opacity="1.000000"
          stroke="none"
          d="
M613.641479,2614.757324 
	C628.139160,2612.940918 641.920288,2615.582031 655.796753,2615.951416 
	C659.921936,2616.061035 664.512512,2617.150879 666.878418,2611.738281 
	C667.737488,2609.772949 670.252747,2610.140869 672.286316,2610.475098 
	C692.403503,2613.781006 712.662170,2611.243408 732.844238,2611.913086 
	C744.071106,2606.025635 757.002502,2609.541260 768.532104,2605.442383 
	C771.744690,2604.300293 774.960754,2603.905273 779.149475,2604.045654 
	C781.292358,2603.985352 782.567688,2603.706299 784.534546,2603.666016 
	C785.653564,2603.738037 786.104126,2603.788086 787.233887,2603.946777 
	C790.240295,2604.458740 792.524719,2605.125732 795.672424,2605.231934 
	C804.604248,2608.604004 813.846680,2605.208252 822.584656,2609.784180 
	C825.617859,2613.943604 834.147888,2612.698730 832.261475,2618.789795 
	C830.312805,2625.081543 823.374084,2622.526123 818.382446,2622.895752 
	C811.375305,2623.414307 803.744690,2621.160645 797.586182,2628.233154 
	C784.250488,2635.243164 778.905029,2634.440918 769.231323,2624.494385 
	C768.312439,2623.549316 767.753967,2622.269287 766.178223,2622.488037 
	C755.093201,2624.028809 743.551453,2621.205811 732.008423,2626.269043 
	C724.016724,2629.888428 716.206787,2629.712402 708.803711,2628.916260 
	C687.108948,2626.583984 665.503723,2631.061523 643.012939,2628.966064 
	C639.484741,2628.270752 636.796936,2627.743896 633.325439,2627.128418 
	C629.401123,2626.970703 626.324402,2628.185303 622.395996,2627.273926 
	C617.794556,2623.864746 611.539490,2622.438721 613.641479,2614.757324 
z"
        />
        <path
          fill="#4E6477"
          opacity="1.000000"
          stroke="none"
          d="
M613.486572,2613.851074 
	C613.782654,2617.087891 614.588440,2619.311035 617.402405,2620.696533 
	C619.137817,2621.551270 621.041809,2622.354980 621.932007,2625.108154 
	C617.539673,2632.242676 609.177856,2633.074707 598.086853,2627.518066 
	C595.783936,2623.120117 594.049316,2621.125000 588.676514,2622.016602 
	C577.657104,2623.845947 566.494446,2625.345459 555.288757,2625.796143 
	C551.954590,2625.930176 555.096436,2628.000732 553.400635,2629.046143 
	C549.708679,2631.145264 546.422607,2630.984375 542.252441,2630.390625 
	C537.694824,2628.709229 533.746582,2629.092529 529.895081,2628.702881 
	C525.632141,2628.272217 522.273376,2626.803711 522.953613,2620.759766 
	C532.619446,2614.784912 542.901367,2615.724609 552.959412,2615.557373 
	C556.141663,2615.504395 559.364319,2617.370605 563.165039,2614.648438 
	C580.115662,2610.193848 596.387756,2606.711426 613.486572,2613.851074 
z"
        />
        <path
          fill="#465F75"
          opacity="1.000000"
          stroke="none"
          d="
M784.471924,2605.694092 
	C782.846313,2605.700195 781.764771,2605.345459 780.338989,2604.523438 
	C781.029541,2601.152100 785.276733,2599.155029 782.252686,2594.601562 
	C780.605164,2585.626465 780.586609,2585.729980 788.626587,2587.170410 
	C795.814392,2588.458008 803.105225,2588.823242 810.199646,2586.865479 
	C819.566223,2584.281250 828.481445,2587.777344 837.603088,2588.382812 
	C838.485840,2588.441162 839.930176,2589.127197 840.078613,2589.763428 
	C841.683167,2596.638184 847.968872,2595.571045 853.266235,2597.645020 
	C857.256775,2603.760254 853.666565,2605.805664 848.886414,2606.473877 
	C843.690125,2607.200439 838.476624,2606.383789 832.729980,2604.704590 
	C832.259094,2595.680176 825.781128,2595.944580 820.086731,2595.414551 
	C812.758423,2594.732422 811.210510,2599.549072 810.596802,2606.049316 
	C805.295044,2610.694824 800.129578,2604.454346 794.617371,2607.464355 
	C791.717529,2608.078613 789.430054,2607.925049 786.721680,2606.367676 
	C785.735474,2605.823486 785.375732,2605.578369 784.471924,2605.694092 
z"
        />
        <path
          fill="#33495B"
          opacity="1.000000"
          stroke="none"
          d="
M563.175293,2614.170654 
	C563.566162,2616.969971 561.732361,2618.050049 559.124023,2617.914551 
	C547.953125,2617.334473 537.074951,2621.188232 524.963623,2620.074951 
	C519.615540,2624.020752 514.192200,2623.335449 508.057098,2623.197754 
	C502.662842,2623.098389 498.221863,2623.988281 492.887421,2624.708008 
	C490.256805,2624.974609 488.493011,2625.038574 485.854248,2624.466309 
	C480.836578,2622.642090 479.393250,2619.801025 481.635040,2614.857422 
	C492.812347,2614.118408 503.411041,2614.120605 513.594116,2610.481689 
	C514.508728,2610.155029 515.613647,2609.887695 516.521606,2610.087158 
	C529.759033,2612.993164 543.623474,2609.941162 556.675110,2614.307129 
	C558.746704,2615.000488 560.566406,2614.581543 563.175293,2614.170654 
z"
        />
        <path
          fill="#4E6477"
          opacity="1.000000"
          stroke="none"
          d="
M481.346832,2614.092285 
	C482.180267,2616.960938 481.062195,2620.119141 485.351440,2621.958008 
	C489.282471,2626.886475 487.679535,2630.473877 482.903717,2632.443848 
	C473.999695,2636.116455 464.894043,2639.150146 454.514984,2634.590820 
	C453.999603,2632.591797 458.884644,2631.020264 453.723297,2630.046143 
	C451.587341,2629.642822 449.220032,2630.036865 448.054810,2626.742432 
	C452.883331,2625.975098 457.489166,2623.488281 462.596100,2623.773682 
	C464.620819,2623.886963 467.007324,2622.780029 466.780487,2620.498291 
	C466.444946,2617.123535 463.266205,2617.811523 461.251648,2618.278076 
	C456.698792,2619.332764 452.199188,2620.425049 447.490112,2619.951904 
	C445.963196,2619.798340 444.075104,2620.242432 443.292572,2617.518066 
	C455.405334,2612.882568 467.574890,2610.069092 481.346832,2614.092285 
z"
        />
        <path
          fill="#2B3E51"
          opacity="1.000000"
          stroke="none"
          d="
M702.656250,2593.725586 
	C700.279541,2592.654541 700.020752,2591.007324 699.998169,2588.612549 
	C700.757996,2587.269043 701.651367,2586.955811 703.312622,2587.499512 
	C719.636780,2585.495117 735.212158,2582.082275 751.204346,2581.992432 
	C757.823792,2581.955322 764.049805,2583.672607 770.935730,2585.712158 
	C768.455505,2589.408691 763.528076,2586.575439 762.073853,2591.125000 
	C752.780273,2592.544678 743.581238,2591.420166 734.513306,2593.730957 
	C730.807861,2594.675049 726.670349,2594.272217 722.820618,2593.201660 
	C716.318665,2591.393799 709.884705,2591.604980 702.656250,2593.725586 
z"
        />
        <path
          fill="#2B3E51"
          opacity="1.000000"
          stroke="none"
          d="
M288.893890,2611.960938 
	C291.819122,2607.058594 294.371490,2606.714844 301.560303,2610.838867 
	C297.724335,2611.666016 293.740204,2611.813477 288.893890,2611.960938 
z"
        />
        <path
          fill="#4E6477"
          opacity="1.000000"
          stroke="none"
          d="
M441.475891,2625.520264 
	C437.973846,2628.585449 434.011932,2629.466309 430.096436,2624.781982 
	C433.585114,2624.248535 437.367279,2623.252197 441.475891,2625.520264 
z"
        />
        <path
          fill="#374D60"
          opacity="1.000000"
          stroke="none"
          d="
M191.423798,2618.206299 
	C190.189514,2623.370117 185.419632,2622.973145 180.534592,2623.756348 
	C182.512009,2620.045166 186.223663,2618.333984 191.423798,2618.206299 
z"
        />
        <path
          fill="#4E6477"
          opacity="1.000000"
          stroke="none"
          d="
M472.307465,2658.667969 
	C470.279968,2658.206055 468.853394,2658.163574 466.713196,2658.116699 
	C465.753876,2657.777100 465.508209,2657.442139 465.603577,2656.585693 
	C467.122742,2655.198730 467.685730,2654.029785 467.251526,2652.623779 
	C465.993317,2648.549805 467.930023,2647.652100 471.298462,2648.771240 
	C474.553925,2649.853027 477.725830,2650.613281 480.978333,2649.112793 
	C482.197723,2648.550293 483.072632,2647.556885 482.813568,2645.982666 
	C482.608154,2644.734375 481.601318,2644.427490 480.659149,2644.269531 
	C479.355164,2644.050537 477.999512,2643.936523 476.683044,2644.026855 
	C459.362152,2645.214844 442.262756,2640.940186 424.944977,2641.932373 
	C423.112885,2642.037354 420.919861,2642.549316 419.195801,2639.791992 
	C422.078247,2636.906250 425.822174,2638.369141 429.086731,2638.002441 
	C433.236206,2637.536377 437.886749,2639.361084 441.646149,2634.622803 
	C442.056763,2633.945068 443.003113,2633.938477 443.476196,2633.949219 
	C463.215942,2638.197021 482.581360,2634.601807 501.899719,2634.628662 
	C504.182526,2634.631836 506.467316,2633.916016 509.492035,2635.000732 
	C511.364075,2636.684326 511.773315,2638.225586 511.591553,2640.690430 
	C509.089874,2646.354248 504.530518,2646.835938 499.995117,2646.872314 
	C495.958771,2646.904785 493.114960,2648.052246 492.080872,2653.082031 
	C490.332245,2656.433350 488.931519,2659.120850 486.626190,2662.141113 
	C484.304871,2663.941162 482.313965,2664.222656 479.469238,2663.793945 
	C476.319794,2662.711182 475.052429,2660.335449 472.307465,2658.667969 
z"
        />
        <path
          fill="#5D758A"
          opacity="1.000000"
          stroke="none"
          d="
M371.957031,2650.889893 
	C363.344818,2649.646729 354.598846,2650.094971 344.961914,2650.132080 
	C341.783813,2650.179199 339.543243,2649.275391 336.610901,2650.173340 
	C333.220428,2650.291992 330.484985,2650.213867 326.932861,2650.168945 
	C325.214966,2650.104004 324.313751,2650.006348 322.736084,2649.852051 
	C321.640900,2648.547607 321.341248,2647.242188 320.774597,2646.065186 
	C319.976715,2644.406982 317.688202,2642.980957 319.686371,2640.932129 
	C320.954773,2639.631836 322.785522,2640.309814 324.437836,2640.435547 
	C331.892761,2641.002930 339.353333,2641.500977 346.816071,2641.955322 
	C348.129211,2642.035400 349.656586,2642.179688 350.756317,2641.633057 
	C363.398956,2635.350586 377.326813,2638.365234 390.506409,2636.046631 
	C392.115570,2635.763672 393.832550,2635.967041 395.487091,2636.100098 
	C397.565552,2636.267090 399.777405,2636.810059 399.226959,2639.497559 
	C398.672455,2642.204590 396.281555,2641.943115 394.461700,2641.104248 
	C388.025635,2638.136230 382.099030,2642.208496 375.874451,2642.320557 
	C370.999603,2642.408447 372.338348,2646.977051 371.957031,2650.889893 
z"
        />
        <path
          fill="#43596D"
          opacity="1.000000"
          stroke="none"
          d="
M436.065002,2659.083008 
	C425.850342,2661.471191 416.373505,2658.565918 406.500824,2654.483887 
	C407.689484,2650.959717 409.881836,2647.983154 413.485138,2648.227539 
	C423.457001,2648.903320 433.052856,2645.550049 442.982758,2645.951416 
	C446.697601,2646.101807 450.123932,2646.843994 453.701965,2648.543945 
	C450.246613,2652.197754 445.852264,2652.044922 441.572876,2651.996826 
	C437.154297,2651.947021 435.791718,2654.337158 436.065002,2659.083008 
z"
        />
        <path
          fill="#43596D"
          opacity="1.000000"
          stroke="none"
          d="
M455.415802,2656.261963 
	C455.523743,2658.845703 453.805115,2659.971436 451.262115,2659.964111 
	C448.057373,2659.954834 444.852539,2659.968506 440.777832,2659.905518 
	C440.445892,2657.183594 442.158844,2656.040039 444.773315,2656.060791 
	C448.025391,2656.086182 451.277496,2656.114258 455.415802,2656.261963 
z"
        />
        <path
          fill="#43596D"
          opacity="1.000000"
          stroke="none"
          d="
M465.231812,2656.069336 
	C465.957062,2656.405029 465.969543,2656.745850 465.990753,2657.599365 
	C464.311096,2660.000977 463.013336,2659.586182 461.844238,2656.917236 
	C462.625366,2656.290771 463.572235,2656.182617 465.231812,2656.069336 
z"
        />
        <path
          fill="#5D758A"
          opacity="1.000000"
          stroke="none"
          d="
M380.026398,2649.694824 
	C379.242157,2650.312744 378.336792,2650.314453 376.754852,2650.317383 
	C376.152313,2649.086670 376.198730,2647.599854 377.429291,2647.145508 
	C378.835724,2646.626465 379.532196,2647.833740 380.026398,2649.694824 
z"
        />
        <path
          fill="#556A7C"
          opacity="1.000000"
          stroke="none"
          d="
M242.906982,2667.997803 
	C241.994217,2667.067383 241.989029,2666.154053 241.961945,2664.553711 
	C248.048248,2659.473145 254.164551,2664.449951 261.086975,2663.825195 
	C263.666046,2665.868408 263.324127,2667.645508 260.582428,2667.802246 
	C255.007309,2668.120850 249.405441,2667.971436 242.906982,2667.997803 
z"
        />
        <path
          fill="#3F5467"
          opacity="1.000000"
          stroke="none"
          d="
M222.608047,2667.920898 
	C221.063644,2667.999023 220.143784,2667.968994 218.534882,2667.863281 
	C218.825958,2664.041260 220.990662,2662.309326 225.495514,2663.865234 
	C225.812744,2665.882080 225.201279,2667.364014 222.608047,2667.920898 
z"
        />
        <path
          fill="#566C80"
          opacity="1.000000"
          stroke="none"
          d="
M510.752563,2636.054199 
	C505.631073,2634.795410 502.937592,2639.769531 498.884552,2639.191650 
	C481.022980,2636.645752 462.658447,2640.720703 444.497559,2634.509277 
	C446.709198,2633.943604 449.469177,2633.927246 453.050293,2633.904053 
	C463.844971,2635.713135 473.011505,2631.938232 482.425232,2629.818848 
	C485.919922,2629.032227 485.879669,2626.384033 486.030151,2622.939209 
	C487.529449,2622.117676 489.009857,2622.088623 491.231140,2622.084961 
	C496.018829,2625.065674 500.860199,2624.444580 506.148438,2625.084473 
	C509.917572,2624.825684 512.914062,2625.005371 516.520874,2626.316406 
	C519.961304,2629.100098 518.184509,2631.237549 516.219971,2633.937988 
	C514.443298,2635.426514 513.050598,2635.969238 510.752563,2636.054199 
z"
        />
        <path
          fill="#243748"
          opacity="1.000000"
          stroke="none"
          d="
M1021.428406,2807.322754 
	C1021.752197,2810.148438 1020.640930,2811.505371 1018.435791,2812.143799 
	C1013.939026,2813.445312 1009.950867,2815.013428 1009.069336,2820.758301 
	C1008.623535,2823.663574 1005.195801,2823.820312 1002.464722,2823.990723 
	C997.549988,2824.297363 992.553406,2823.256104 987.106995,2824.916504 
	C988.051880,2829.186523 995.136047,2829.086914 993.975952,2835.130371 
	C984.897766,2833.592041 975.617981,2833.841797 966.377319,2834.052979 
	C952.141724,2834.378662 937.976746,2833.584473 922.953064,2832.055420 
	C910.378540,2838.384766 898.029968,2835.322998 886.075439,2833.868164 
	C872.584839,2832.226807 859.004395,2830.479492 845.817627,2827.038330 
	C831.517212,2823.306885 819.080750,2827.380859 806.542969,2835.333984 
	C792.225891,2832.292969 778.244019,2834.603516 764.393494,2834.061523 
	C759.764771,2833.880615 755.026855,2834.481689 750.632812,2830.420898 
	C749.188538,2828.484863 749.768250,2827.151123 751.204346,2825.377930 
	C760.664307,2821.462891 770.691345,2823.918701 779.751465,2819.095703 
	C780.924622,2815.868164 782.484497,2813.655273 784.913940,2811.171387 
	C787.052795,2809.514893 788.845947,2808.705566 791.424316,2807.775879 
	C794.668274,2807.065674 797.248779,2806.909424 800.431091,2805.839111 
	C819.977051,2809.850098 840.168457,2806.010010 858.004028,2816.260498 
	C863.290222,2819.298828 870.151672,2818.916992 875.549255,2819.740723 
	C872.557922,2818.614502 867.871704,2818.620361 865.908875,2813.111816 
	C865.453735,2810.743652 865.633362,2809.181396 866.545837,2806.984619 
	C868.485840,2804.624512 870.724792,2804.141113 872.377380,2805.251953 
	C884.340149,2813.291016 898.791565,2810.840332 911.749207,2814.774170 
	C922.467163,2818.027832 932.731567,2813.924805 943.217102,2810.283936 
	C942.632385,2807.140381 936.892212,2805.630859 940.557617,2800.835693 
	C959.964294,2798.015381 978.713989,2796.310059 998.021851,2802.609863 
	C1000.486755,2803.436279 1002.009644,2804.293945 1004.581116,2804.632568 
	C1010.656555,2804.662598 1015.959351,2804.034912 1021.428406,2807.322754 
z"
        />
        <path
          fill="#2B3E51"
          opacity="1.000000"
          stroke="none"
          d="
M786.165466,2811.423340 
	C786.121460,2815.086426 782.851196,2816.149658 782.094116,2819.380859 
	C773.940063,2822.244873 765.524048,2821.375244 757.404602,2822.978516 
	C755.361450,2823.382080 752.692749,2822.137939 751.856201,2825.677734 
	C751.512390,2827.139893 750.960205,2828.091064 750.181580,2829.545410 
	C743.470764,2830.322266 736.681885,2828.523682 730.840271,2833.155029 
	C729.934998,2833.872559 728.204651,2834.138184 727.042419,2833.853027 
	C715.021057,2830.901855 703.130371,2835.319092 691.146790,2834.585693 
	C690.817444,2831.181641 693.045044,2830.418945 695.420410,2830.153076 
	C698.378967,2829.822021 701.451111,2830.024902 703.805725,2826.520996 
	C705.300781,2824.403076 708.646362,2823.055420 703.856689,2821.687744 
	C690.622192,2817.909424 677.545349,2810.914551 664.049988,2820.985352 
	C663.035278,2821.742432 661.457825,2821.327393 659.503174,2820.643799 
	C657.052124,2817.769287 654.514587,2817.060547 651.501282,2816.731445 
	C648.335266,2816.385986 645.087097,2815.446533 645.482544,2810.512939 
	C650.694153,2807.545898 655.169067,2805.818604 661.046204,2807.525146 
	C668.482727,2809.684082 676.202881,2806.541992 683.679871,2805.588623 
	C691.301331,2804.617188 698.835144,2804.176025 706.454895,2804.084229 
	C709.831848,2804.043457 713.135864,2804.204590 714.112549,2799.649414 
	C714.989685,2795.559082 718.439209,2795.377441 721.175049,2796.913330 
	C728.622925,2801.094238 736.965027,2799.252197 743.337952,2797.072998 
	C751.828735,2794.168945 758.781982,2796.190918 767.151794,2797.895996 
	C772.950928,2796.344971 777.570984,2799.569580 783.230164,2799.249756 
	C784.923157,2799.509033 785.852478,2799.789307 787.358154,2800.563477 
	C791.336060,2804.894287 788.794250,2807.824463 786.165466,2811.423340 
z"
        />
        <path
          fill="#2F4457"
          opacity="1.000000"
          stroke="none"
          d="
M806.776062,2835.979248 
	C805.576782,2830.903320 806.865723,2827.452881 812.942139,2827.888184 
	C814.480957,2827.998535 816.781189,2827.654541 817.555542,2826.630127 
	C823.115051,2819.275391 830.903259,2822.314941 837.821960,2822.266357 
	C842.701721,2822.231934 847.655334,2823.414062 852.466736,2824.525635 
	C874.753906,2829.675293 897.209900,2833.129150 921.125122,2832.032471 
	C922.826843,2836.707764 926.482788,2837.531494 930.195190,2837.896729 
	C934.131226,2838.283691 937.739380,2839.179688 941.273926,2841.847656 
	C929.201660,2846.249023 917.862122,2846.221924 908.643738,2841.715820 
	C904.026367,2839.458740 899.027161,2838.760986 894.218445,2839.157471 
	C883.261108,2840.060547 872.388428,2840.960205 861.550598,2838.345947 
	C857.780640,2837.436279 854.372131,2839.427734 850.780701,2839.711426 
	C845.814514,2840.104248 840.802979,2839.924072 834.897095,2839.980469 
	C830.979431,2838.293213 827.734863,2837.745117 824.344910,2837.998779 
	C818.637634,2838.426025 813.101379,2837.463135 806.776062,2835.979248 
z"
        />
        <path
          fill="#2F4457"
          opacity="1.000000"
          stroke="none"
          d="
M811.812500,2859.694336 
	C811.761047,2861.594971 811.410767,2863.019775 809.977600,2863.401855 
	C802.109070,2865.499512 794.264465,2867.716797 786.324707,2869.507812 
	C779.741394,2870.993408 773.456543,2867.846680 766.887573,2867.563232 
	C758.454712,2867.199219 750.090698,2865.239502 740.848389,2863.943359 
	C734.621826,2858.134277 727.914551,2854.854248 720.141479,2854.085205 
	C716.128784,2853.688232 712.482300,2852.819824 707.141479,2849.358398 
	C729.873291,2845.732910 750.195557,2849.863281 771.096436,2850.743164 
	C769.319397,2855.472168 764.865051,2852.638428 762.998230,2856.336914 
	C767.189819,2858.628174 772.289734,2859.888184 775.043091,2855.965332 
	C778.354126,2851.247803 782.269653,2852.302979 787.208984,2852.031738 
	C791.122803,2856.089111 795.645447,2854.624023 799.652527,2855.169189 
	C803.790894,2855.732910 807.962952,2856.288086 811.812500,2859.694336 
z"
        />
        <path
          fill="#1F3041"
          opacity="1.000000"
          stroke="none"
          d="
M659.255249,2819.353516 
	C663.972412,2820.051270 666.421082,2816.803711 669.575745,2815.125732 
	C676.887268,2811.236572 684.114868,2810.516357 691.392761,2814.736328 
	C698.617676,2818.925049 706.971802,2819.169189 715.534668,2821.235352 
	C712.827576,2825.677734 709.343018,2825.972168 704.940186,2825.902588 
	C702.336121,2823.838623 699.866150,2824.045166 696.730347,2824.017578 
	C694.820251,2824.031006 693.739502,2824.349121 692.329407,2825.413330 
	C688.477905,2825.829834 685.087891,2825.902344 680.850159,2825.995605 
	C678.168579,2826.223145 677.474487,2827.664795 676.329590,2829.484619 
	C671.427002,2829.967773 666.917603,2830.026123 662.408020,2830.047119 
	C658.421692,2830.065918 654.681885,2830.638184 651.924438,2834.960205 
	C645.579224,2835.406250 639.423462,2836.923340 632.721313,2834.360352 
	C633.009644,2825.320557 625.759033,2826.661865 620.460022,2824.472168 
	C622.523010,2816.417725 628.780090,2819.612793 634.541077,2819.324219 
	C638.055237,2820.586426 640.590515,2820.122070 643.620789,2818.013672 
	C649.179016,2816.816406 654.027100,2816.122559 659.255249,2819.353516 
z"
        />
        <path
          fill="#2F4457"
          opacity="1.000000"
          stroke="none"
          d="
M833.663879,2846.626953 
	C842.107666,2849.955322 850.876587,2851.294678 859.625305,2853.085449 
	C867.894348,2854.778320 876.517639,2852.604248 884.641479,2856.145508 
	C865.055969,2859.990967 845.768372,2865.529297 824.688416,2861.825195 
	C821.503113,2859.289551 818.323914,2857.308105 819.570190,2852.572510 
	C825.042725,2852.067139 830.216309,2852.568359 833.663879,2846.626953 
z"
        />
        <path
          fill="#1F3041"
          opacity="1.000000"
          stroke="none"
          d="
M819.084229,2851.984863 
	C819.856750,2855.457275 824.519165,2856.701172 823.850464,2861.064941 
	C820.437012,2861.256348 817.060425,2860.767090 812.952820,2860.322266 
	C805.612244,2859.930176 799.294861,2857.319336 792.531738,2857.863281 
	C789.792480,2858.083740 788.238403,2856.459473 788.071289,2852.851562 
	C798.089844,2852.028076 808.119202,2852.003906 819.084229,2851.984863 
z"
        />
        <path
          fill="#2B3E51"
          opacity="1.000000"
          stroke="none"
          d="
M676.478638,2829.706543 
	C676.495850,2827.967773 674.916016,2824.548828 679.604248,2825.578125 
	C679.008606,2827.173828 678.014648,2828.330811 676.478638,2829.706543 
z"
        />
        <path
          fill="#2B3E51"
          opacity="1.000000"
          stroke="none"
          d="
M662.233826,2840.789551 
	C663.757874,2840.607666 665.356140,2841.048096 667.551758,2841.712158 
	C665.987793,2844.149902 663.965637,2844.812744 662.233826,2840.789551 
z"
        />
        <path
          fill="#2F4457"
          opacity="1.000000"
          stroke="none"
          d="
M800.852905,2845.275146 
	C801.280884,2846.779053 800.749573,2847.312256 799.588684,2847.079102 
	C799.347107,2847.030518 799.024292,2846.687988 798.986389,2846.442871 
	C798.803467,2845.262207 799.377563,2844.777100 800.852905,2845.275146 
z"
        />
        <path
          fill="#2B3E51"
          opacity="1.000000"
          stroke="none"
          d="
M692.469604,2825.782715 
	C692.361023,2824.024658 692.595642,2821.948486 695.654907,2823.591797 
	C695.301270,2825.065674 694.336853,2825.682861 692.469604,2825.782715 
z"
        />
        <path
          fill="#2B3E51"
          opacity="1.000000"
          stroke="none"
          d="
M896.546509,2873.525391 
	C887.440247,2883.700439 873.774902,2882.483154 866.071533,2870.676758 
	C865.977661,2869.953125 865.907227,2869.942383 865.914001,2869.906250 
	C868.071899,2867.881348 870.808289,2866.721924 873.528809,2864.460205 
	C881.888672,2864.421631 889.779236,2863.177002 897.645874,2865.252441 
	C901.753906,2866.336182 905.928467,2863.229248 911.118042,2864.123535 
	C915.131287,2869.542236 911.957031,2871.246094 907.342651,2871.723389 
	C903.909729,2872.078613 900.158142,2869.677979 896.546509,2873.525391 
z"
        />
        <path
          fill="#2F4457"
          opacity="1.000000"
          stroke="none"
          d="
M896.718140,2874.041016 
	C896.012085,2870.246826 896.662964,2867.421631 901.721558,2868.827148 
	C905.604858,2869.906006 910.365967,2872.629883 911.928833,2864.911377 
	C917.472717,2863.830811 923.128113,2864.649170 929.203369,2862.120117 
	C932.086304,2864.637207 932.462402,2867.469482 930.536621,2871.256836 
	C919.139221,2872.667725 908.280823,2873.345459 896.718140,2874.041016 
z"
        />
        <path
          fill="#2B3E51"
          opacity="1.000000"
          stroke="none"
          d="
M930.760132,2872.001221 
	C930.000183,2869.220215 930.002808,2866.450195 930.005127,2862.849854 
	C932.233704,2861.405273 934.462585,2860.791016 937.391235,2860.165527 
	C934.512024,2867.080078 934.512024,2867.080078 939.873657,2871.328369 
	C937.062927,2872.549561 934.241333,2871.790039 930.760132,2872.001221 
z"
        />
        <path
          fill="#3D494C"
          opacity="1.000000"
          stroke="none"
          d="
M959.930664,2858.907959 
	C956.492615,2859.849365 952.784973,2860.456299 948.582336,2858.590820 
	C951.933350,2855.313477 955.832703,2854.741943 959.930664,2858.907959 
z"
        />
        <path
          fill="#2F4457"
          opacity="1.000000"
          stroke="none"
          d="
M873.470276,2863.839844 
	C874.354614,2868.836914 871.792480,2870.368896 866.698730,2869.908203 
	C866.781555,2866.136230 867.751709,2862.524658 873.470276,2863.839844 
z"
        />
        <path
          fill="#2B3B46"
          opacity="1.000000"
          stroke="none"
          d="
M828.187500,2914.987305 
	C827.107117,2915.801025 825.897827,2915.738770 824.398254,2914.480957 
	C825.585632,2912.042725 826.899475,2912.374756 828.187500,2914.987305 
z"
        />
        <path
          fill="#2F4457"
          opacity="1.000000"
          stroke="none"
          d="
M300.283600,2878.207764 
	C301.083862,2873.454834 303.881592,2873.625000 307.561584,2875.562500 
	C306.956390,2880.711670 304.164215,2880.614258 300.283600,2878.207764 
z"
        />
        <path
          fill="#233444"
          opacity="1.000000"
          stroke="none"
          d="
M318.393127,2875.737305 
	C318.338348,2873.915039 318.718536,2871.916992 321.681702,2873.681641 
	C321.274628,2875.174072 320.275177,2875.741211 318.393127,2875.737305 
z"
        />
        <path
          fill="#FBF9EC"
          opacity="1.000000"
          stroke="none"
          d="
M1157.434448,2732.301270 
	C1157.372681,2727.187012 1157.851318,2722.871338 1161.530396,2717.845703 
	C1167.064819,2710.426270 1173.660645,2708.112305 1182.072998,2708.577881 
	C1187.096924,2711.394287 1191.053711,2710.152100 1195.006958,2707.899658 
	C1208.685547,2700.106201 1222.837280,2703.664062 1236.622803,2707.047607 
	C1240.642334,2708.033936 1244.911621,2709.035889 1248.861450,2710.575928 
	C1253.828857,2712.511963 1256.742188,2716.581299 1258.318359,2721.530762 
	C1259.545898,2725.385254 1260.873169,2729.070801 1265.111694,2731.901367 
	C1268.170044,2738.666016 1267.387451,2744.974609 1266.648682,2752.130859 
	C1265.863770,2755.268311 1264.952026,2757.417480 1263.097412,2760.061523 
	C1260.468140,2762.086670 1258.244629,2762.336670 1255.120850,2761.471680 
	C1252.973145,2760.167969 1252.199585,2758.776123 1251.514160,2756.477783 
	C1251.053101,2751.969482 1249.969604,2748.398682 1248.303467,2744.290527 
	C1247.596802,2742.447021 1247.296509,2741.173584 1246.539917,2739.399658 
	C1245.749634,2737.727295 1245.225220,2736.619873 1244.093262,2735.161865 
	C1241.512085,2732.100830 1238.211060,2732.799072 1234.766113,2731.737793 
	C1221.154907,2725.229492 1207.161499,2728.364014 1192.944580,2726.826660 
	C1186.608765,2724.801514 1180.693359,2723.939697 1174.679932,2721.096680 
	C1172.402466,2720.576904 1171.002930,2719.691650 1169.454834,2719.198242 
	C1168.399902,2718.379395 1169.042480,2720.241943 1169.162842,2720.235352 
	C1172.752441,2720.045654 1173.111938,2723.696045 1175.448730,2726.100830 
	C1176.631470,2729.140137 1176.683716,2731.570312 1175.753784,2734.716309 
	C1171.487671,2740.572510 1165.502197,2744.133301 1163.198975,2751.378418 
	C1163.239380,2757.604004 1163.489136,2763.109863 1162.451782,2769.272949 
	C1161.690063,2772.932129 1158.271606,2775.041016 1159.852905,2779.249268 
	C1160.124268,2780.326660 1160.221924,2780.761230 1160.431396,2781.855469 
	C1161.004883,2785.973633 1160.240234,2789.686279 1162.941162,2793.368652 
	C1163.441895,2794.902832 1163.532959,2795.819824 1163.367432,2797.436279 
	C1160.651245,2804.682617 1160.989624,2810.974121 1164.972900,2816.927002 
	C1166.281372,2818.882568 1167.764893,2821.012695 1167.095703,2823.355957 
	C1164.557983,2832.244385 1167.028198,2841.642578 1164.194824,2851.208008 
	C1163.469482,2858.505615 1161.987915,2865.116943 1163.431519,2871.659180 
	C1166.017944,2883.380127 1156.504150,2891.717285 1155.178223,2902.423584 
	C1154.728149,2906.058105 1150.386353,2906.350830 1146.857544,2902.844727 
	C1150.393433,2877.046875 1150.734863,2851.674316 1152.087891,2830.686768 
	C1150.040161,2850.707764 1151.394287,2875.315674 1146.315063,2899.404541 
	C1145.635376,2902.628418 1146.015503,2905.989746 1147.373535,2909.695801 
	C1153.333252,2915.042480 1150.479736,2918.727783 1145.522461,2922.532227 
	C1138.509766,2926.799805 1141.863159,2934.277100 1138.666992,2940.132324 
	C1135.240601,2946.392578 1135.374390,2952.946777 1132.921631,2959.493896 
	C1132.192993,2961.012451 1131.726807,2961.850098 1130.989502,2963.342285 
	C1128.508911,2970.194824 1125.150146,2976.018311 1124.641602,2983.497559 
	C1123.646484,2988.529785 1120.451050,2991.812500 1119.022461,2996.693359 
	C1116.650024,3002.966064 1115.066040,3008.796631 1110.882080,3014.207764 
	C1101.206299,3015.765381 1103.519043,3007.476807 1100.439453,3002.628418 
	C1099.995117,3002.000244 1099.982422,3001.012207 1099.981445,3000.518066 
	C1097.238281,2995.905762 1094.037842,2992.039795 1092.423096,2986.534180 
	C1092.001831,2986.000000 1092.000000,2986.000000 1092.000000,2986.000000 
	C1089.822754,2976.021240 1086.306152,2966.472900 1082.844849,2956.892578 
	C1081.582153,2953.397705 1081.496338,2949.633545 1083.891235,2946.365479 
	C1085.755127,2943.821777 1088.173462,2942.069824 1091.521484,2942.619629 
	C1095.183228,2943.221436 1096.886475,2946.281494 1096.312988,2949.200195 
	C1093.799805,2961.988770 1105.561035,2972.023193 1102.443726,2985.043945 
	C1104.900269,2983.710449 1105.052612,2981.491211 1105.628418,2979.706299 
	C1112.182373,2959.395020 1117.783936,2938.800049 1123.605835,2918.273926 
	C1127.160034,2905.742920 1125.616333,2893.230225 1121.660767,2881.071289 
	C1120.129883,2876.365479 1119.656372,2871.992920 1121.636963,2867.511963 
	C1126.281250,2857.006104 1125.076050,2845.265869 1128.698975,2834.507080 
	C1129.699707,2831.534912 1129.440063,2827.942627 1127.583618,2823.522705 
	C1125.408691,2831.994141 1120.781006,2838.275879 1119.662598,2846.300049 
	C1118.693604,2853.250732 1113.952515,2853.839111 1103.310181,2849.438721 
	C1107.897095,2851.877686 1109.055420,2855.784668 1107.383423,2860.089111 
	C1104.414917,2867.731689 1106.869751,2873.694092 1112.230469,2879.060547 
	C1112.700317,2879.531006 1113.162354,2880.021240 1113.546753,2880.561035 
	C1115.622192,2883.477539 1119.192139,2886.706543 1115.667358,2890.101318 
	C1112.216187,2893.424316 1108.921387,2890.332275 1106.274292,2887.678955 
	C1098.661255,2880.048096 1092.482910,2871.517090 1089.704346,2860.882568 
	C1089.911255,2863.631348 1090.995239,2866.152588 1091.982178,2868.687500 
	C1094.376221,2874.836426 1093.747070,2879.752686 1086.808716,2882.428223 
	C1079.849731,2885.111816 1078.065308,2880.412354 1076.377563,2875.373535 
	C1075.961792,2874.132080 1075.431152,2872.842529 1072.997070,2872.218994 
	C1073.345093,2880.488770 1075.819946,2890.128174 1064.673340,2893.236328 
	C1056.338379,2895.560547 1047.780762,2897.731445 1038.186035,2891.178223 
	C1039.964722,2901.702637 1044.100830,2909.550293 1047.918335,2917.392822 
	C1050.854370,2923.425049 1052.947388,2929.699463 1055.099121,2935.991699 
	C1056.316284,2939.551025 1056.714111,2943.169189 1055.583740,2946.783203 
	C1054.198730,2951.210938 1053.174683,2956.429199 1047.655884,2957.261963 
	C1041.647949,2958.168213 1038.741699,2953.559570 1036.597290,2948.918701 
	C1033.941650,2943.171387 1031.431274,2937.411133 1027.497925,2932.344971 
	C1024.088013,2927.953125 1023.534180,2923.844238 1029.825439,2921.318604 
	C1032.741089,2920.148193 1034.295898,2918.162842 1032.453491,2915.126953 
	C1030.505981,2911.918457 1028.481689,2914.177734 1026.677368,2915.477295 
	C1021.981445,2918.861084 1018.150696,2917.237549 1014.447388,2913.164307 
	C1015.483887,2921.779541 1017.073059,2929.819336 1020.420105,2937.459717 
	C1022.122803,2941.346191 1022.769836,2945.421875 1018.692383,2948.239014 
	C1014.130798,2951.390381 1010.806091,2948.326660 1007.188477,2945.643311 
	C1002.344543,2942.050293 1002.196472,2935.525635 997.343262,2931.260742 
	C993.942383,2925.186035 990.938843,2919.635010 988.562073,2913.850586 
	C986.192200,2908.083008 984.089233,2902.102539 983.533020,2895.607666 
	C979.925964,2901.753418 981.292664,2908.623535 980.982910,2915.907227 
	C980.784668,2918.949951 981.553833,2920.846436 984.278198,2922.500977 
	C993.721252,2933.419434 994.258240,2937.804443 987.055298,2946.937988 
	C984.851807,2948.052002 983.253967,2948.312500 980.804932,2948.237549 
	C977.367371,2947.600586 974.914551,2946.482422 972.962219,2944.709717 
	C959.803406,2932.760986 943.321472,2927.971680 926.868408,2923.083496 
	C920.667664,2921.241211 913.937012,2920.536865 909.568481,2914.337402 
	C910.585205,2912.155029 911.412720,2910.726074 912.320007,2908.678955 
	C917.604675,2901.955078 923.892761,2897.400146 931.306152,2894.251221 
	C946.453491,2887.817627 958.544739,2877.303467 969.698730,2864.604004 
	C989.941589,2844.360107 1013.192993,2828.902344 1035.643311,2812.464111 
	C1051.433350,2800.902344 1067.026001,2788.999268 1084.002563,2779.148438 
	C1088.552612,2776.508301 1091.333618,2772.522949 1093.527222,2767.969482 
	C1101.321045,2751.792969 1112.889526,2740.585938 1131.538940,2737.980225 
	C1135.333740,2737.449951 1138.860718,2735.003906 1143.221069,2733.116699 
	C1148.466675,2737.961670 1142.413208,2746.019531 1149.326416,2751.131592 
	C1154.435791,2746.069580 1152.091919,2737.787354 1157.434448,2732.301270 
z"
        />
        <path
          fill="#395166"
          opacity="1.000000"
          stroke="none"
          d="
M2771.864014,3033.302490 
	C2761.818115,3034.924561 2751.987061,3030.776611 2740.884521,3031.874756 
	C2735.567383,3029.294189 2731.070068,3028.611816 2725.878418,3029.546387 
	C2717.666260,3031.024902 2709.902100,3029.303467 2701.949463,3023.826416 
	C2707.417480,3021.228516 2711.327881,3024.350342 2715.433105,3024.154297 
	C2725.372314,3023.680420 2735.348877,3023.995361 2745.310547,3023.983398 
	C2748.622559,3023.979492 2752.001953,3024.253906 2754.614502,3021.515381 
	C2755.051270,3021.057861 2755.739014,3020.579590 2756.324463,3020.562012 
	C2771.020020,3020.117920 2785.843994,3018.186279 2800.105713,3024.141357 
	C2789.464600,3027.541748 2778.350830,3024.759033 2767.594727,3026.590820 
	C2766.587402,3030.625977 2771.642090,3029.844727 2771.864014,3033.302490 
z"
        />
        <path
          fill="#3C556B"
          opacity="1.000000"
          stroke="none"
          d="
M2760.854980,3014.087158 
	C2760.652100,3009.739990 2763.762695,3009.770508 2766.804443,3010.224854 
	C2783.116455,3012.661377 2799.574219,3013.117920 2816.000977,3014.008789 
	C2819.700684,3014.209717 2823.386230,3014.396484 2818.592773,3019.672852 
	C2808.624023,3018.865234 2799.228271,3018.294189 2789.793457,3017.879639 
	C2780.354492,3017.465088 2770.888672,3016.579834 2760.854980,3014.087158 
z"
        />
        <path
          fill="#23394D"
          opacity="1.000000"
          stroke="none"
          d="
M2819.312012,3031.999023 
	C2820.113281,3034.721924 2821.854492,3036.330566 2824.111084,3036.980957 
	C2831.503662,3039.111084 2835.996582,3044.413330 2839.893799,3051.302734 
	C2836.243896,3051.190186 2834.212646,3054.451904 2830.639404,3055.857910 
	C2829.958984,3054.199951 2830.137695,3052.398438 2828.491699,3050.406738 
	C2828.666992,3044.833496 2826.689941,3042.351318 2821.317383,3041.264404 
	C2816.623779,3040.314697 2811.495850,3039.936279 2807.278320,3036.693115 
	C2808.746826,3029.517822 2813.842285,3031.150146 2819.312012,3031.999023 
z"
        />
        <path
          fill="#5E4237"
          opacity="1.000000"
          stroke="none"
          d="
M2556.012207,3029.507568 
	C2555.871094,3033.671143 2558.472656,3036.299072 2560.184326,3038.878906 
	C2566.555420,3048.480225 2567.236816,3059.683594 2570.075195,3070.278076 
	C2570.332275,3071.237305 2570.205322,3072.513672 2570.792725,3073.137451 
	C2579.743652,3082.635254 2571.371826,3092.548584 2571.448486,3102.228760 
	C2571.477051,3105.851562 2568.037109,3107.628418 2565.118652,3109.225342 
	C2568.249512,3115.799805 2575.656494,3119.533691 2576.439941,3127.444336 
	C2576.522705,3128.281494 2577.392090,3129.187256 2578.122070,3129.785889 
	C2580.253906,3131.533691 2582.028320,3133.032227 2580.783203,3136.423584 
	C2579.245850,3140.609863 2575.772705,3138.289062 2572.769287,3139.384766 
	C2573.368896,3142.525146 2573.676514,3145.789795 2574.661377,3148.835205 
	C2575.671875,3151.959473 2576.185303,3154.541260 2572.959229,3156.691895 
	C2571.926514,3157.379639 2570.750488,3158.644043 2570.614258,3159.762939 
	C2569.320801,3170.386963 2564.287598,3180.224365 2566.343506,3191.737549 
	C2567.417480,3197.751953 2567.414307,3204.745361 2565.120361,3211.711182 
	C2563.041992,3218.022217 2561.789062,3224.940186 2559.740723,3231.479736 
	C2558.648438,3234.966064 2557.687012,3239.172607 2553.402100,3239.601318 
	C2546.704102,3240.270996 2546.013672,3243.819824 2547.520264,3249.179443 
	C2547.643555,3249.619141 2546.959473,3250.285645 2546.359375,3251.375977 
	C2541.322998,3248.678711 2536.239014,3246.004395 2531.210938,3243.228271 
	C2529.964355,3242.539795 2528.787598,3242.072510 2527.604980,3243.023682 
	C2526.202393,3244.151123 2526.914795,3245.559082 2527.716553,3246.526855 
	C2530.053711,3249.347656 2530.059814,3252.633057 2529.999023,3255.982178 
	C2529.980713,3256.979492 2529.652100,3258.187500 2530.085205,3258.944092 
	C2537.210693,3271.388184 2530.727295,3285.728271 2535.613281,3298.505615 
	C2536.260254,3300.197510 2536.119141,3302.670410 2535.364746,3304.339355 
	C2531.395264,3313.119141 2534.294678,3321.299561 2536.334473,3329.910400 
	C2538.210938,3337.832764 2540.512939,3345.466553 2543.779297,3353.635986 
	C2543.688721,3354.439697 2542.846436,3354.362549 2542.424316,3354.337402 
	C2531.385742,3349.818115 2526.776611,3338.869629 2518.235352,3332.083008 
	C2515.281494,3329.735596 2512.993408,3326.297119 2510.782715,3322.190430 
	C2509.437988,3313.130615 2509.395752,3305.343750 2515.376953,3297.986084 
	C2519.848633,3292.485352 2517.357910,3285.827881 2512.003418,3282.195557 
	C2503.397705,3276.357910 2502.618652,3268.893555 2503.747314,3259.581543 
	C2505.030518,3248.996338 2503.187500,3238.906494 2493.499023,3232.052002 
	C2490.111328,3229.655029 2486.956543,3226.933105 2483.694336,3232.324707 
	C2481.465820,3236.007324 2478.126221,3235.439453 2474.942871,3233.694336 
	C2469.878906,3230.917969 2464.441406,3229.550049 2458.027344,3228.936523 
	C2455.268555,3229.034912 2453.341064,3228.623779 2450.860840,3227.423828 
	C2447.250488,3222.173096 2450.557373,3220.211426 2454.322021,3219.580566 
	C2461.394287,3218.395752 2464.394043,3214.296143 2464.860352,3206.857422 
	C2465.476807,3205.838379 2465.808838,3205.488281 2466.789551,3204.823242 
	C2473.774414,3203.616699 2478.798828,3207.831055 2484.631836,3208.926025 
	C2492.035400,3210.316162 2497.596191,3210.834473 2502.520752,3201.995850 
	C2508.608154,3191.071533 2515.788086,3179.227539 2531.723633,3179.496094 
	C2536.211670,3179.571777 2534.124512,3175.347168 2532.965820,3173.454346 
	C2529.353027,3167.554199 2528.611816,3161.405273 2528.297852,3154.526611 
	C2527.935303,3146.575439 2529.253662,3140.113770 2535.017578,3134.686279 
	C2536.002686,3133.758789 2536.785156,3132.793701 2537.167480,3131.445068 
	C2539.886475,3121.856934 2539.904541,3121.857910 2549.135498,3126.497314 
	C2550.027344,3126.945312 2550.877686,3127.486084 2551.810791,3127.839844 
	C2554.951172,3129.030029 2558.821533,3130.769043 2560.826660,3127.203369 
	C2563.068115,3123.217529 2559.446045,3120.402100 2556.522705,3118.288086 
	C2553.552246,3116.140137 2549.628174,3115.916748 2546.401123,3114.130615 
	C2540.989746,3111.135254 2538.863525,3107.690918 2544.960449,3103.022461 
	C2539.770264,3102.486328 2534.679443,3102.741943 2529.897461,3101.372314 
	C2524.333008,3099.778076 2523.307129,3095.257080 2527.894043,3092.024658 
	C2532.034912,3089.105957 2536.075195,3085.112061 2542.327393,3088.012695 
	C2548.866943,3091.046875 2556.438232,3085.489990 2555.903320,3078.215332 
	C2555.416016,3071.586914 2553.865234,3065.272461 2551.095215,3059.012207 
	C2542.517090,3039.626465 2530.958496,3022.523438 2514.628418,3007.829590 
	C2512.975342,3005.586182 2512.298584,3003.732910 2512.357666,3000.915527 
	C2514.769531,2995.071045 2518.514893,2996.552246 2521.477783,2998.842773 
	C2532.998291,3007.750000 2545.400879,3015.635742 2555.459961,3027.436035 
	C2555.978760,3028.312744 2555.987061,3028.654053 2556.012207,3029.507568 
z"
        />
        <path
          fill="#50494B"
          opacity="1.000000"
          stroke="none"
          d="
M2555.972900,3027.183838 
	C2555.003418,3027.760010 2553.634277,3027.886719 2553.129883,3027.286377 
	C2546.466309,3019.355225 2536.117676,3016.113037 2529.374023,3007.958252 
	C2525.977295,3003.851074 2520.905273,2999.994385 2514.408203,2999.494141 
	C2512.006836,3001.699219 2510.092529,3001.026855 2507.640137,2999.239258 
	C2504.126709,2993.513184 2505.278809,2986.898926 2500.789062,2981.541992 
	C2496.660400,2975.929443 2492.016113,2972.694580 2485.633301,2971.730469 
	C2481.350586,2971.083740 2477.343262,2969.378662 2474.824707,2965.296631 
	C2473.406494,2962.998779 2471.251221,2961.121094 2468.753662,2960.241211 
	C2455.857910,2955.700684 2445.759521,2945.553955 2432.307129,2942.168701 
	C2431.347412,2941.927246 2430.208008,2941.677002 2429.559082,2941.026123 
	C2417.187744,2928.631104 2399.722900,2925.791504 2385.274658,2917.322754 
	C2378.844971,2913.553955 2372.630615,2909.153320 2365.689209,2916.416260 
	C2364.735107,2917.414551 2363.168945,2916.904053 2361.405273,2915.788086 
	C2359.545654,2909.568359 2360.282715,2904.214111 2363.443604,2898.520508 
	C2367.936035,2896.907471 2371.431152,2896.571289 2375.472168,2898.548584 
	C2390.877686,2906.087158 2406.577881,2913.021240 2422.069092,2920.388184 
	C2425.006104,2921.784668 2427.365723,2924.352783 2430.226074,2925.964111 
	C2433.370850,2927.735596 2436.466797,2929.854248 2441.036621,2929.934082 
	C2442.788330,2930.751953 2443.695557,2931.870605 2444.863770,2932.341309 
	C2463.626465,2939.900879 2480.227539,2951.598633 2498.412842,2960.268555 
	C2502.971680,2962.441895 2507.644287,2963.446533 2512.538574,2964.470215 
	C2517.479248,2965.502930 2517.538086,2972.696289 2522.248535,2974.927246 
	C2524.339111,2975.917725 2526.432129,2976.902832 2529.225098,2978.020752 
	C2530.211182,2983.500977 2535.381104,2984.253418 2538.392334,2986.997314 
	C2540.254883,2988.694336 2541.908691,2989.795654 2539.644287,2992.484863 
	C2536.942627,2995.693359 2538.610352,2997.978027 2541.189453,3000.830322 
	C2547.886963,3008.237305 2556.085693,3014.982422 2555.972900,3027.183838 
z"
        />
        <path
          fill="#495E72"
          opacity="1.000000"
          stroke="none"
          d="
M336.151794,2684.806152 
	C337.232422,2683.872070 339.166473,2684.167480 339.723846,2683.427002 
	C343.422455,2678.511475 348.991058,2677.719727 355.026001,2676.207275 
	C355.838165,2676.945312 355.927368,2677.768555 356.015381,2679.210938 
	C356.071960,2684.678711 360.599579,2683.419922 363.529785,2685.491699 
	C364.394043,2686.331299 364.787628,2686.666260 364.591095,2687.503418 
	C363.765900,2688.232910 363.568024,2688.586182 363.290222,2688.668457 
	C357.963196,2690.240967 341.987640,2688.377197 336.151794,2684.806152 
z"
        />
        <path
          fill="#3F5467"
          opacity="1.000000"
          stroke="none"
          d="
M487.583588,2673.346680 
	C488.036285,2675.615234 488.013672,2677.092041 487.993896,2679.308838 
	C479.076508,2679.525146 470.084900,2681.007812 460.608124,2678.530273 
	C461.594818,2675.968018 463.854736,2676.062256 466.076080,2676.013428 
	C468.301300,2675.964600 470.596558,2676.050781 471.928009,2672.812012 
	C477.261383,2667.561523 482.223450,2668.765137 487.583588,2673.346680 
z"
        />
        <path
          fill="#3F5467"
          opacity="1.000000"
          stroke="none"
          d="
M422.625549,2677.723633 
	C421.953217,2672.977295 423.829010,2670.057617 428.937134,2669.954590 
	C434.100128,2669.850830 439.056549,2666.226562 444.773621,2669.172363 
	C443.421906,2676.342285 435.025513,2671.588379 432.346130,2677.039551 
	C429.302216,2677.765625 426.396210,2677.812256 422.625549,2677.723633 
z"
        />
        <path
          fill="#3F5467"
          opacity="1.000000"
          stroke="none"
          d="
M383.988190,2678.936035 
	C386.692932,2678.221924 389.626678,2678.174072 393.438934,2678.255615 
	C390.654510,2682.936035 389.358765,2683.092041 383.988190,2678.936035 
z"
        />
        <path
          fill="#495E72"
          opacity="1.000000"
          stroke="none"
          d="
M369.430237,2686.502930 
	C370.624695,2686.018311 371.254303,2685.995850 371.874695,2686.061035 
	C373.419403,2686.223389 375.460999,2686.455322 374.757202,2688.516846 
	C374.126923,2690.362793 372.250305,2689.787109 370.394287,2688.492676 
	C369.619110,2687.674561 369.240143,2687.339600 369.430237,2686.502930 
z"
        />
        <path
          fill="#3F5467"
          opacity="1.000000"
          stroke="none"
          d="
M369.249451,2686.000000 
	C369.999451,2686.334961 369.999603,2686.668945 369.998901,2687.506348 
	C368.498779,2688.007568 366.999512,2688.005371 364.750610,2688.004639 
	C364.000671,2687.671143 364.000336,2687.336670 364.000244,2686.499512 
	C365.500214,2685.997314 366.999908,2685.998047 369.249451,2686.000000 
z"
        />
        <path
          fill="#31475A"
          opacity="1.000000"
          stroke="none"
          d="
M399.522644,2691.898926 
	C399.251648,2693.141602 398.135101,2693.949951 396.187836,2692.350586 
	C396.816345,2691.915771 397.800293,2691.912842 399.522644,2691.898926 
z"
        />
        <path
          fill="#31475A"
          opacity="1.000000"
          stroke="none"
          d="
M407.308411,2691.762207 
	C407.184814,2693.245117 406.076599,2693.871094 404.076843,2692.306885 
	C404.661011,2691.861328 405.625732,2691.843018 407.308411,2691.762207 
z"
        />
        <path
          fill="#233444"
          opacity="1.000000"
          stroke="none"
          d="
M488.960693,2674.104492 
	C483.417480,2672.591064 478.696350,2671.596436 472.944946,2672.092529 
	C470.304962,2671.756836 467.474274,2672.544678 467.125153,2670.156738 
	C466.502228,2665.896973 471.099915,2666.700684 473.575806,2664.435547 
	C474.785431,2663.026367 475.610992,2662.197021 477.404297,2661.894531 
	C480.129913,2661.941406 482.265747,2661.986572 485.854492,2662.098633 
	C489.085632,2662.691895 490.817108,2662.311523 493.244202,2661.731689 
	C495.911713,2661.157227 497.906799,2661.063721 500.709900,2661.076172 
	C510.373322,2660.787598 519.235168,2660.368164 528.939453,2660.544434 
	C537.244019,2663.989502 545.237305,2662.422607 552.989014,2663.150391 
	C557.008484,2663.528076 561.959656,2662.481934 562.459106,2669.314941 
	C555.982971,2669.240234 550.214600,2671.237549 544.502502,2672.501709 
	C538.902649,2673.740723 533.415222,2673.876465 526.927368,2673.831543 
	C520.525696,2674.147949 515.008972,2673.944580 508.742554,2673.974121 
	C501.947815,2673.999512 495.905121,2674.035156 488.960693,2674.104492 
z"
        />
        <path
          fill="#3F5467"
          opacity="1.000000"
          stroke="none"
          d="
M381.772827,2659.651123 
	C383.182220,2659.285889 383.794495,2659.000977 384.438141,2658.871094 
	C387.581238,2658.235840 390.963623,2656.598389 393.282288,2660.334473 
	C393.615875,2660.871826 393.029510,2662.768799 392.617554,2662.856445 
	C388.887299,2663.650879 386.241882,2669.163330 382.113312,2666.504150 
	C378.576874,2664.226074 373.655914,2664.451416 370.704742,2659.742188 
	C374.361053,2657.930420 377.789215,2660.864502 381.772827,2659.651123 
z"
        />
        <path
          fill="#566C80"
          opacity="1.000000"
          stroke="none"
          d="
M336.436432,2650.802979 
	C337.264648,2643.583252 340.513763,2647.664307 343.664917,2649.596680 
	C341.976868,2652.482666 339.507843,2652.252686 336.436432,2650.802979 
z"
        />
        <path
          fill="#43596D"
          opacity="1.000000"
          stroke="none"
          d="
M477.856323,2661.216064 
	C477.056580,2662.531494 476.119110,2663.166748 474.593079,2663.859619 
	C473.329590,2662.425537 472.654663,2660.933350 471.843140,2658.844971 
	C474.052979,2658.110840 476.534637,2657.617676 477.856323,2661.216064 
z"
        />
        <path
          fill="#3F5467"
          opacity="1.000000"
          stroke="none"
          d="
M402.946838,2662.024414 
	C402.002472,2663.704102 400.829742,2663.893066 399.684662,2663.105713 
	C398.755646,2662.466797 398.761993,2661.451660 399.717438,2660.840088 
	C400.885559,2660.092773 402.053406,2660.312988 402.946838,2662.024414 
z"
        />
        <path
          fill="#2F465E"
          opacity="1.000000"
          stroke="none"
          d="
M2585.426758,2977.497559 
	C2580.882080,2984.170166 2574.565186,2984.916748 2566.715820,2982.069336 
	C2566.387695,2978.130127 2568.529297,2976.031982 2572.365967,2976.084717 
	C2576.536377,2976.141846 2580.791748,2975.240479 2585.426758,2977.497559 
z"
        />
        <path
          fill="#152533"
          opacity="1.000000"
          stroke="none"
          d="
M2244.666992,3534.556885 
	C2241.320801,3534.052979 2238.356445,3533.981934 2236.757568,3531.568359 
	C2231.159424,3523.118652 2221.926514,3523.283936 2213.666992,3522.310791 
	C2205.324707,3521.328369 2197.924316,3518.148682 2190.211670,3515.515381 
	C2185.385498,3513.867432 2181.051514,3512.832764 2175.204590,3514.537354 
	C2167.428467,3516.804443 2158.996826,3513.542725 2151.668457,3509.363770 
	C2150.944824,3508.951172 2150.282959,3507.698975 2150.293701,3506.844971 
	C2150.387207,3499.362305 2144.468750,3496.201660 2139.344971,3494.062500 
	C2132.358398,3491.145264 2126.225830,3486.139404 2118.433594,3485.109375 
	C2117.146484,3484.939453 2115.920654,3484.251465 2114.685059,3483.756836 
	C2112.875000,3483.031982 2112.027100,3481.672363 2112.849121,3479.854248 
	C2113.444824,3478.537109 2114.824707,3478.202881 2116.235352,3478.516846 
	C2122.705566,3479.956543 2129.179199,3481.383057 2135.632568,3482.895508 
	C2137.229248,3483.270020 2138.749756,3484.127197 2140.355469,3483.006348 
	C2140.593994,3482.418945 2141.098389,3481.798096 2141.024658,3481.256104 
	C2140.619141,3478.282715 2134.951172,3477.683838 2136.910889,3474.166992 
	C2139.115234,3470.210449 2143.401367,3472.633301 2146.876953,3472.550049 
	C2162.378174,3472.179443 2177.875488,3471.645752 2193.374268,3471.170898 
	C2198.327881,3466.689209 2204.383789,3464.423828 2210.701660,3463.105469 
	C2210.717041,3459.386230 2208.019287,3458.782715 2205.980225,3458.431885 
	C2196.004150,3456.714844 2186.539551,3453.418457 2175.709717,3455.473877 
	C2165.096924,3457.488281 2153.746094,3456.661865 2142.835693,3455.724121 
	C2134.292725,3454.989990 2126.176270,3457.354004 2117.785645,3457.247314 
	C2115.200684,3457.214111 2112.579346,3458.140625 2110.024658,3456.994629 
	C2106.765869,3455.532715 2104.035156,3456.539551 2102.018066,3459.084717 
	C2098.496582,3463.526855 2093.874756,3464.385010 2088.629639,3464.037109 
	C2085.647217,3463.839111 2082.712891,3464.476562 2079.745361,3464.901367 
	C2072.373535,3465.957031 2065.246582,3461.337891 2064.266846,3455.477539 
	C2063.424072,3450.434570 2066.334473,3447.705322 2071.094727,3449.721191 
	C2078.566406,3452.884766 2085.895020,3452.604004 2093.494629,3450.523682 
	C2101.207031,3448.412109 2108.980225,3446.456055 2116.949707,3449.733887 
	C2118.085205,3450.200684 2119.590332,3449.904541 2120.904541,3449.768799 
	C2123.041992,3449.548096 2124.690186,3448.462646 2125.395020,3446.371826 
	C2126.118652,3444.225586 2125.347656,3442.392334 2123.605713,3441.100098 
	C2114.517090,3434.356689 2104.760254,3428.714600 2094.236572,3424.593262 
	C2091.613770,3423.566162 2087.899902,3423.252197 2085.421143,3424.317627 
	C2077.993896,3427.509766 2071.006592,3423.936279 2063.838379,3423.896240 
	C2062.122070,3423.886475 2060.443604,3423.286621 2059.465332,3421.857666 
	C2057.931885,3419.617676 2057.735352,3417.245850 2060.922607,3416.446777 
	C2062.758057,3415.986328 2065.071533,3415.966064 2066.767090,3416.690674 
	C2071.796143,3418.840820 2075.764160,3417.749268 2079.320068,3413.837646 
	C2083.169434,3409.603516 2088.046875,3412.149170 2093.255371,3412.008545 
	C2095.669434,3411.687988 2097.332764,3411.625488 2099.565918,3412.376953 
	C2102.039551,3413.334961 2103.581299,3414.814209 2106.258301,3415.279785 
	C2107.681152,3415.555176 2108.491943,3415.745361 2109.917969,3416.104492 
	C2112.180908,3416.773926 2113.829102,3417.272217 2116.215820,3417.556641 
	C2118.196777,3418.076904 2119.391846,3418.704102 2120.939453,3420.062988 
	C2122.904785,3424.161377 2125.950684,3424.329102 2129.924316,3424.181641 
	C2138.475342,3428.618652 2146.763916,3430.034668 2155.674805,3429.091797 
	C2164.612549,3428.146484 2172.846680,3430.317383 2179.916260,3436.203613 
	C2183.031982,3438.797607 2186.764404,3440.232178 2190.576660,3441.513672 
	C2206.379150,3446.825195 2222.642334,3450.319092 2238.902832,3453.819092 
	C2243.159424,3454.734863 2247.521484,3455.112549 2251.805420,3454.262939 
	C2272.202881,3450.218506 2289.503662,3461.399658 2308.673096,3466.265137 
	C2309.488770,3477.788574 2302.260254,3484.479736 2294.949463,3491.843018 
	C2276.603271,3494.996094 2259.195801,3492.470703 2241.896729,3489.480713 
	C2237.006348,3488.635254 2232.925537,3487.670898 2229.286621,3492.002930 
	C2227.553711,3494.066162 2224.974609,3495.750488 2222.281982,3495.281250 
	C2206.702148,3492.569580 2191.251465,3494.225342 2176.472900,3496.674316 
	C2189.537354,3506.142334 2214.516602,3510.624756 2230.848145,3505.946777 
	C2238.298828,3503.812988 2245.699463,3503.135742 2253.493164,3504.196777 
	C2261.688965,3505.312744 2269.881836,3507.134277 2278.271973,3506.408203 
	C2281.863525,3506.097412 2284.860107,3507.115479 2286.773193,3511.356934 
	C2287.166504,3512.544678 2287.302002,3513.026123 2287.500488,3514.253418 
	C2287.486328,3516.973633 2286.813477,3518.843262 2286.364990,3521.480957 
	C2284.916504,3525.785156 2282.605225,3528.784424 2280.095703,3532.488770 
	C2278.083984,3534.857910 2276.607910,3536.753662 2274.343750,3538.932617 
	C2273.279297,3539.586426 2272.819824,3539.797363 2271.621094,3540.205811 
	C2264.459717,3541.426514 2259.003906,3537.401611 2252.076172,3536.931641 
	C2249.088623,3536.948242 2247.064209,3536.416504 2244.666992,3534.556885 
z"
        />
        <path
          fill="#091825"
          opacity="1.000000"
          stroke="none"
          d="
M2120.475830,3422.456055 
	C2119.065674,3421.366943 2118.121826,3420.733887 2116.588867,3420.040527 
	C2114.774658,3418.524170 2113.145752,3418.031494 2110.657959,3418.006836 
	C2109.111572,3417.986816 2108.223389,3417.978760 2106.668701,3417.960938 
	C2104.627930,3416.387695 2102.763184,3415.604980 2100.466064,3414.384277 
	C2098.234375,3413.951660 2096.422852,3414.140137 2094.432129,3412.508301 
	C2093.503174,3410.397461 2093.009277,3408.784912 2092.257568,3406.586426 
	C2096.466309,3402.475342 2099.267334,3407.141846 2102.574951,3408.430908 
	C2106.639648,3410.015381 2110.266357,3410.504150 2113.823975,3407.810791 
	C2116.175049,3406.031250 2116.413086,3404.043457 2113.594727,3402.368408 
	C2113.035156,3402.035400 2112.322998,3401.968018 2111.738281,3401.666748 
	C2109.164307,3400.339355 2105.796875,3399.912109 2105.079102,3396.404297 
	C2104.965332,3395.847656 2106.154785,3394.448486 2106.807129,3394.404297 
	C2110.988037,3394.121826 2115.869141,3392.370117 2118.757568,3396.667969 
	C2120.664307,3399.505371 2122.113037,3402.195068 2125.772217,3402.880615 
	C2127.152344,3403.139160 2128.518066,3403.409912 2129.281494,3402.101074 
	C2129.975830,3400.911133 2129.621582,3399.531006 2128.838379,3398.358887 
	C2128.656006,3398.085938 2128.420166,3397.846924 2128.197998,3397.602539 
	C2126.565186,3395.801514 2122.765625,3394.630615 2125.036377,3391.432129 
	C2127.342773,3388.183594 2130.899170,3387.255859 2135.367432,3389.720947 
	C2137.848389,3391.913086 2139.666748,3393.828613 2141.609619,3395.616943 
	C2144.758545,3398.514893 2148.768311,3401.229980 2147.133545,3406.273926 
	C2145.614746,3410.960205 2140.931396,3410.424072 2137.040283,3411.896240 
	C2155.211182,3409.851318 2173.331543,3408.306152 2190.906982,3417.468994 
	C2194.269531,3418.596191 2196.570557,3420.263916 2199.666504,3421.917969 
	C2205.319824,3425.458496 2209.107422,3432.319824 2217.487061,3427.702148 
	C2234.164307,3424.175049 2249.975830,3427.095703 2265.700928,3429.072021 
	C2285.412598,3431.549561 2305.625488,3432.426758 2324.009766,3443.506348 
	C2324.918945,3455.485352 2318.270020,3462.635010 2309.001465,3468.657959 
	C2305.550049,3464.844238 2300.903809,3464.665527 2297.419434,3464.545410 
	C2286.183594,3464.157959 2276.260254,3460.063477 2266.393066,3455.518066 
	C2264.314209,3454.560547 2262.575928,3454.712646 2260.709717,3455.523682 
	C2249.910645,3460.217041 2239.087646,3457.784668 2228.519531,3455.051025 
	C2214.813721,3451.505859 2200.908936,3448.669434 2187.485352,3444.020752 
	C2184.301514,3442.918213 2180.943359,3442.117920 2178.735596,3439.569580 
	C2172.007568,3431.802979 2163.029297,3431.059082 2154.031982,3432.265137 
	C2144.781982,3433.505127 2136.531494,3432.795166 2128.724121,3426.114258 
	C2125.683594,3424.893066 2122.827148,3425.009521 2120.475830,3422.456055 
z"
        />
        <path
          fill="#020D19"
          opacity="1.000000"
          stroke="none"
          d="
M2243.821289,3534.808594 
	C2246.144043,3534.059814 2248.248047,3534.067139 2251.141602,3534.084473 
	C2257.355225,3538.513916 2263.696289,3539.525879 2271.104492,3539.207031 
	C2272.096680,3539.673828 2272.416016,3540.009766 2272.875977,3541.034668 
	C2272.928223,3543.176270 2272.404297,3544.483887 2271.274902,3546.308105 
	C2262.277832,3558.267090 2250.941895,3567.581055 2244.895264,3581.718262 
	C2244.397705,3582.768555 2244.191650,3583.186523 2243.618164,3584.201172 
	C2242.963623,3585.167236 2242.676514,3585.536621 2241.326172,3586.818359 
	C2239.425537,3588.168213 2238.538086,3588.442139 2236.927734,3588.360352 
	C2230.117432,3582.736816 2223.370850,3578.890625 2214.755859,3580.004639 
	C2208.983643,3580.750732 2203.726318,3577.782715 2197.999268,3577.791504 
	C2194.395264,3577.797119 2193.988281,3575.411133 2195.137939,3572.165527 
	C2197.646729,3565.083984 2196.023682,3562.955811 2188.997803,3564.748535 
	C2181.560303,3566.646729 2174.734619,3566.324951 2167.663086,3563.135254 
	C2164.162598,3561.556396 2159.905518,3561.104980 2155.989502,3561.100342 
	C2144.683594,3561.086670 2135.701904,3556.132324 2127.517578,3548.962646 
	C2124.103760,3545.971924 2119.224609,3546.614746 2115.432373,3544.590088 
	C2112.718018,3543.141357 2107.646729,3542.683350 2108.864746,3538.511230 
	C2110.195557,3533.952881 2114.304688,3537.020996 2117.371826,3537.395752 
	C2123.215088,3538.109619 2129.094971,3538.526367 2135.479736,3538.547852 
	C2136.780029,3537.254150 2137.713135,3536.954346 2139.422852,3537.583008 
	C2144.857666,3540.507324 2149.821045,3540.763916 2155.459717,3538.532471 
	C2157.910889,3535.901123 2160.198486,3535.975098 2163.308105,3537.444336 
	C2168.701172,3540.367676 2173.268799,3542.816650 2178.583252,3539.103516 
	C2181.437744,3537.108887 2185.048584,3538.057617 2188.328125,3538.039795 
	C2192.191650,3538.019043 2195.000488,3537.730957 2196.405518,3532.865234 
	C2197.949707,3527.516357 2199.109375,3527.888184 2203.941895,3531.550781 
	C2210.586182,3536.586182 2217.898438,3539.719971 2226.641357,3538.312012 
	C2230.210693,3537.737305 2233.926025,3538.082275 2237.574707,3537.985840 
	C2239.875732,3537.925049 2242.322266,3538.185303 2243.821289,3534.808594 
z"
        />
        <path
          fill="#152533"
          opacity="1.000000"
          stroke="none"
          d="
M2164.552246,3537.486816 
	C2161.860107,3538.026611 2159.729248,3538.018311 2156.799316,3538.005615 
	C2154.331299,3534.620361 2151.182617,3532.538818 2148.802490,3529.783691 
	C2145.779541,3526.283691 2142.712646,3526.151123 2138.216797,3527.681641 
	C2127.530029,3531.319580 2116.382812,3533.420654 2105.726318,3527.566650 
	C2096.162109,3522.312012 2085.229248,3520.394043 2075.868652,3514.810791 
	C2073.288818,3513.272217 2068.067627,3513.242676 2069.372314,3508.843262 
	C2070.540527,3504.903564 2074.797363,3505.768799 2077.996338,3507.033203 
	C2084.223389,3509.494873 2090.421631,3506.671875 2096.637451,3507.201904 
	C2103.568604,3507.792725 2110.033203,3510.085449 2116.697266,3511.744873 
	C2125.952393,3514.049805 2135.636230,3514.596680 2145.104004,3516.102783 
	C2148.353027,3516.619873 2152.058105,3516.975586 2154.589111,3518.768311 
	C2160.865967,3523.213867 2167.420654,3526.764404 2174.861572,3528.697754 
	C2175.469238,3528.855713 2176.031006,3529.336670 2176.521240,3529.771973 
	C2177.753906,3530.864990 2179.219482,3532.003662 2178.893555,3533.838623 
	C2178.514648,3535.972412 2176.551270,3535.789795 2174.936768,3535.953125 
	C2171.662842,3536.284912 2168.260742,3535.335449 2164.552246,3537.486816 
z"
        />
        <path
          fill="#BA7C55"
          opacity="1.000000"
          stroke="none"
          d="
M2214.343750,3612.488037 
	C2216.016113,3611.643555 2217.074951,3610.849854 2218.407715,3609.638184 
	C2218.444092,3608.263184 2218.649902,3607.428467 2220.055664,3606.850098 
	C2220.744629,3606.965332 2221.049316,3607.064697 2221.192383,3606.992676 
	C2221.408447,3603.307129 2224.513672,3602.554443 2227.515625,3600.826172 
	C2240.548828,3603.201172 2238.268311,3593.863037 2239.593750,3586.575195 
	C2240.017578,3586.026855 2240.000000,3586.000000 2240.013184,3586.004883 
	C2240.493896,3585.541016 2240.949707,3585.061523 2241.497803,3584.085938 
	C2241.601318,3583.600586 2241.517822,3583.611572 2241.518555,3583.569336 
	C2249.196533,3579.956787 2255.741211,3575.300293 2258.663086,3566.777344 
	C2259.569336,3564.134033 2261.840088,3562.633545 2265.421387,3562.324219 
	C2266.579102,3562.486084 2267.031738,3562.618896 2268.084961,3563.127441 
	C2273.126221,3570.173584 2274.912842,3577.958496 2277.645264,3585.325195 
	C2278.686523,3588.132812 2278.233887,3591.513916 2274.832520,3592.881104 
	C2271.479004,3594.229004 2269.144531,3592.710205 2268.020508,3589.223877 
	C2268.620361,3598.753906 2259.843506,3601.176758 2254.411865,3606.984131 
	C2252.283691,3608.460693 2250.727783,3609.529785 2248.541992,3610.959473 
	C2246.997803,3611.659424 2246.059814,3611.895508 2244.370117,3612.056152 
	C2234.186523,3609.912598 2227.626221,3616.464844 2220.080566,3619.983398 
	C2216.312744,3621.739990 2213.042725,3625.119629 2207.804932,3622.528320 
	C2207.303955,3622.018555 2206.673828,3622.102295 2206.359863,3622.053711 
	C2206.613281,3621.125732 2207.180908,3620.246582 2207.988281,3618.898193 
	C2208.934082,3616.951660 2210.180176,3615.954102 2211.912598,3614.671143 
	C2212.462158,3614.441895 2212.392578,3614.243896 2212.349121,3614.339355 
	C2212.537598,3613.620361 2213.012695,3612.988525 2214.343750,3612.488037 
z"
        />
        <path
          fill="#96664C"
          opacity="1.000000"
          stroke="none"
          d="
M2239.500977,3586.205078 
	C2240.244385,3588.897461 2240.120850,3591.678467 2241.536377,3594.510010 
	C2244.487305,3600.412598 2242.554199,3603.598145 2236.175781,3603.730957 
	C2233.993652,3603.776367 2231.789795,3602.768066 2228.849365,3602.143555 
	C2227.371094,3601.264404 2227.087402,3600.344238 2227.810547,3598.777100 
	C2232.016357,3596.281494 2232.963623,3592.064209 2235.994629,3588.760742 
	C2237.165039,3587.572998 2238.074707,3586.978027 2239.500977,3586.205078 
z"
        />
        <path
          fill="#152533"
          opacity="1.000000"
          stroke="none"
          d="
M2043.253174,3398.022705 
	C2045.492554,3398.029297 2046.980347,3398.028809 2049.210693,3398.033691 
	C2048.616211,3404.832764 2044.836060,3409.583008 2040.223999,3409.229736 
	C2035.763916,3408.887939 2032.148071,3406.806152 2031.998535,3400.730469 
	C2032.311768,3399.988525 2032.647827,3399.987061 2033.487793,3399.982910 
	C2036.413818,3397.530029 2039.536743,3398.115234 2043.253174,3398.022705 
z"
        />
        <path
          fill="#2C2520"
          opacity="1.000000"
          stroke="none"
          d="
M2227.528320,3598.196777 
	C2228.264404,3599.105225 2228.159668,3599.942139 2228.079102,3601.412598 
	C2226.808594,3604.298340 2224.817627,3605.699463 2221.900391,3606.789062 
	C2219.582764,3599.256104 2219.582764,3599.256104 2227.528320,3598.196777 
z"
        />
        <path
          fill="#152533"
          opacity="1.000000"
          stroke="none"
          d="
M2049.324219,3443.041016 
	C2049.839355,3438.886963 2051.950684,3440.190674 2053.867432,3441.411133 
	C2054.983643,3442.122070 2055.756592,3443.539795 2054.672852,3444.546143 
	C2052.809570,3446.277100 2051.281982,3444.304199 2049.324219,3443.041016 
z"
        />
        <path
          fill="#2C2520"
          opacity="1.000000"
          stroke="none"
          d="
M2211.761475,3614.410156 
	C2212.095947,3616.197998 2210.922363,3617.280029 2208.823730,3618.257812 
	C2208.203857,3616.401611 2208.713379,3614.747314 2211.761475,3614.410156 
z"
        />
        <path
          fill="#152533"
          opacity="1.000000"
          stroke="none"
          d="
M2139.943848,3537.457520 
	C2139.147217,3538.035156 2138.247559,3538.032227 2136.674072,3538.029297 
	C2136.205566,3536.747314 2136.298828,3535.272705 2137.923828,3535.055908 
	C2139.052734,3534.905762 2139.552734,3535.890869 2139.943848,3537.457520 
z"
        />
        <path
          fill="#2C2520"
          opacity="1.000000"
          stroke="none"
          d="
M2220.133789,3607.007324 
	C2220.382080,3607.559082 2220.019531,3608.152832 2219.169189,3608.983398 
	C2218.961914,3608.496338 2219.242432,3607.772705 2220.133789,3607.007324 
z"
        />
        <path
          fill="#2C2520"
          opacity="1.000000"
          stroke="none"
          d="
M2214.352051,3612.513184 
	C2214.444580,3612.998535 2213.931885,3613.560059 2212.862793,3614.278320 
	C2212.786133,3613.819824 2213.266602,3613.204590 2214.352051,3612.513184 
z"
        />
        <path
          fill="#8C5B43"
          opacity="1.000000"
          stroke="none"
          d="
M2283.243652,3620.305420 
	C2276.025635,3622.862793 2272.032471,3629.419678 2264.805664,3632.052734 
	C2260.687012,3626.475342 2265.655273,3623.689941 2268.474854,3620.548340 
	C2273.028809,3615.474121 2279.068604,3611.603271 2280.141113,3604.028320 
	C2280.368408,3602.421387 2281.750732,3600.730713 2283.322998,3600.512939 
	C2292.804443,3599.198730 2297.322021,3591.430176 2303.285645,3585.539795 
	C2306.123535,3582.736572 2309.034912,3580.973877 2312.793701,3585.279053 
	C2313.785889,3588.217529 2313.757812,3590.241211 2311.021240,3591.922119 
	C2302.877930,3596.081055 2298.398682,3603.706787 2290.127686,3607.151123 
	C2286.775391,3608.547607 2285.671387,3615.342529 2283.243652,3620.305420 
z"
        />
        <path
          fill="#C2825D"
          opacity="1.000000"
          stroke="none"
          d="
M2310.228516,3592.902344 
	C2311.812256,3590.796387 2311.904297,3589.062256 2311.955078,3586.650635 
	C2312.318848,3585.641602 2312.666016,3585.289062 2313.540527,3585.458496 
	C2318.480225,3590.497070 2319.701904,3594.262695 2316.850586,3601.366211 
	C2313.591064,3609.486816 2310.656494,3618.546631 2311.705566,3628.234619 
	C2312.366211,3634.335205 2308.712402,3640.127197 2305.146484,3645.849609 
	C2307.793701,3628.802979 2310.230713,3611.452148 2310.228516,3592.902344 
z"
        />
        <path
          fill="#E59F70"
          opacity="1.000000"
          stroke="none"
          d="
M2207.109619,3622.742432 
	C2216.021973,3621.747803 2221.433838,3614.295410 2229.190674,3611.927734 
	C2229.804443,3611.740479 2230.490723,3611.456543 2230.904785,3611.000732 
	C2234.385010,3607.171143 2238.356201,3607.457520 2243.251221,3609.824219 
	C2244.850830,3611.052734 2245.899414,3611.375244 2247.569336,3610.469238 
	C2248.255859,3608.329102 2248.720459,3606.749756 2251.398682,3606.273926 
	C2257.933350,3605.311768 2263.210449,3605.904053 2266.301514,3612.966064 
	C2268.180420,3623.014893 2263.463867,3629.673340 2256.082764,3633.313477 
	C2246.373047,3638.102295 2236.255859,3642.407471 2224.722656,3644.850586 
	C2211.283203,3641.386963 2208.063232,3637.291748 2207.109619,3622.742432 
z"
        />
        <path
          fill="#BA7C55"
          opacity="1.000000"
          stroke="none"
          d="
M2224.678955,3645.537354 
	C2228.639160,3641.003662 2234.116699,3638.291504 2239.957520,3636.259521 
	C2244.042236,3634.838379 2248.241211,3633.718506 2252.252930,3632.121826 
	C2259.607422,3629.194824 2264.655029,3624.430420 2264.111328,3614.917969 
	C2266.501953,3606.384521 2273.713623,3602.249023 2279.158203,3598.099854 
	C2290.474365,3589.476562 2300.273438,3579.753662 2309.909180,3568.714111 
	C2315.521240,3569.038086 2316.567627,3572.198486 2316.039062,3577.195801 
	C2315.368652,3580.197266 2314.780029,3582.403564 2314.129883,3585.294922 
	C2313.718994,3585.984619 2313.369873,3585.989014 2312.496094,3585.993652 
	C2310.051025,3583.774658 2308.161865,3583.660156 2306.204102,3585.990234 
	C2303.862549,3588.778076 2301.041260,3591.261719 2299.183594,3594.334229 
	C2296.361084,3599.001953 2292.581055,3601.673584 2287.214355,3602.016602 
	C2284.114746,3602.214844 2282.260010,3603.387207 2282.103760,3606.750732 
	C2281.850586,3612.199219 2278.375977,3615.601807 2274.514160,3618.946533 
	C2270.555176,3622.375977 2266.189941,3625.571777 2264.133057,3631.442871 
	C2253.176514,3642.302490 2240.667725,3648.026611 2224.678955,3645.537354 
z"
        />
        <path
          fill="#D7D5CF"
          opacity="1.000000"
          stroke="none"
          d="
M2897.545898,3327.426758 
	C2898.184814,3328.896484 2898.322998,3329.780518 2898.287598,3331.320312 
	C2889.315674,3342.346191 2885.999023,3342.486328 2876.995850,3332.657959 
	C2869.849121,3324.856445 2861.229492,3318.562256 2854.479980,3310.344238 
	C2848.180420,3302.673584 2849.122559,3299.275879 2858.721191,3296.597168 
	C2862.220947,3295.620605 2865.789795,3294.892334 2870.053955,3293.746582 
	C2877.026855,3292.183838 2884.113037,3293.963623 2889.918945,3288.239746 
	C2893.412598,3292.487793 2894.469727,3297.581543 2895.729736,3303.350098 
	C2894.058105,3311.818848 2895.200439,3319.351318 2897.545898,3327.426758 
z"
        />
        <path
          fill="#B6BCC1"
          opacity="1.000000"
          stroke="none"
          d="
M2890.529785,3287.409912 
	C2890.437744,3293.469971 2888.277100,3296.694092 2882.122559,3296.034912 
	C2878.857422,3295.685303 2875.529053,3295.924561 2871.342773,3295.851318 
	C2864.641357,3292.828125 2864.601807,3286.796875 2862.914062,3280.851807 
	C2863.010254,3279.593506 2863.144531,3279.100342 2863.845215,3278.090576 
	C2869.147461,3276.592529 2873.883545,3275.610840 2879.293701,3274.329102 
	C2884.895020,3277.113037 2887.977295,3281.704590 2890.529785,3287.409912 
z"
        />
        <path
          fill="#EFF1EA"
          opacity="1.000000"
          stroke="none"
          d="
M2898.150879,3327.175293 
	C2895.237549,3327.666016 2893.974121,3325.937988 2894.057861,3323.252441 
	C2894.243408,3317.296387 2893.293457,3311.299805 2895.165283,3304.752197 
	C2896.411621,3308.233887 2897.157715,3312.348145 2898.085449,3317.197266 
	C2898.262939,3320.733887 2898.259033,3323.535889 2898.150879,3327.175293 
z"
        />
        <path
          fill="#D0D1C8"
          opacity="1.000000"
          stroke="none"
          d="
M2864.240234,3276.916016 
	C2864.342041,3277.946045 2864.272705,3278.318115 2864.142578,3279.254395 
	C2862.421387,3292.750244 2861.047119,3294.641357 2847.912354,3296.584717 
	C2839.281250,3297.861816 2831.288818,3301.301758 2822.760742,3302.894775 
	C2820.467773,3303.322998 2821.729736,3309.520996 2816.385498,3309.893311 
	C2806.937988,3308.503174 2801.056885,3312.525879 2796.471924,3319.525146 
	C2794.509766,3322.521240 2792.400635,3326.236572 2787.947998,3325.131348 
	C2783.103027,3323.928711 2782.993896,3319.299072 2782.388672,3315.456299 
	C2781.334717,3308.765869 2784.807861,3302.434570 2784.558105,3295.043457 
	C2788.815674,3289.690430 2794.940674,3288.658203 2800.415039,3285.086182 
	C2803.119873,3283.405273 2805.381836,3282.571777 2808.516113,3282.125732 
	C2815.706787,3282.025391 2821.966553,3281.424561 2826.413574,3274.808350 
	C2824.398682,3263.530273 2827.594482,3263.868896 2835.492676,3263.881592 
	C2841.445068,3263.891113 2847.654053,3263.064453 2852.076660,3268.545654 
	C2859.668701,3265.527344 2861.670654,3271.200928 2864.240234,3276.916016 
z"
        />
        <path
          fill="#D7D5CF"
          opacity="1.000000"
          stroke="none"
          d="
M2786.596680,3272.570557 
	C2785.680664,3271.176270 2785.129883,3270.341064 2784.779053,3269.429199 
	C2783.518799,3266.157471 2779.367432,3262.518066 2781.518066,3259.761963 
	C2784.549561,3255.876709 2790.355469,3257.341797 2794.314697,3258.803955 
	C2801.449951,3261.438965 2808.726318,3262.909668 2816.929688,3264.186035 
	C2817.729004,3264.233643 2817.922852,3265.078613 2817.974609,3265.508789 
	C2818.399170,3266.299072 2818.771484,3266.658936 2818.410400,3267.275391 
	C2814.674072,3268.632080 2811.671143,3269.732666 2807.865479,3271.984375 
	C2802.390625,3275.717041 2797.368652,3277.104004 2791.364746,3277.375488 
	C2788.640381,3276.568359 2788.095947,3274.613770 2786.596680,3272.570557 
z"
        />
        <path
          fill="#A9AEB3"
          opacity="1.000000"
          stroke="none"
          d="
M2825.957520,3274.142578 
	C2829.242676,3278.328857 2828.388916,3281.148193 2823.516113,3282.328857 
	C2819.360107,3283.335693 2815.187744,3284.276611 2810.467041,3284.869629 
	C2807.859131,3283.854492 2812.900391,3279.256836 2807.168945,3279.833252 
	C2805.780762,3277.215332 2805.870117,3275.226074 2807.461914,3272.634766 
	C2807.933838,3271.983154 2807.968018,3271.986328 2807.945801,3271.980469 
	C2813.280762,3275.926025 2819.467285,3273.084961 2825.957520,3274.142578 
z"
        />
        <path
          fill="#AAB7BE"
          opacity="1.000000"
          stroke="none"
          d="
M2818.168945,3267.602051 
	C2817.677246,3267.531738 2817.837158,3266.732910 2817.931885,3266.335938 
	C2818.952393,3266.034668 2819.967529,3265.944824 2820.389404,3267.082031 
	C2819.813232,3267.278809 2819.236816,3267.475342 2818.168945,3267.602051 
z"
        />
        <path
          fill="#FAFBBF"
          opacity="1.000000"
          stroke="none"
          d="
M2786.474121,3293.741211 
	C2785.744141,3299.370117 2790.035889,3304.435059 2784.999512,3310.057373 
	C2783.257812,3312.001465 2785.614502,3318.182617 2786.976807,3322.125488 
	C2787.770996,3324.425537 2790.616943,3324.041016 2791.387695,3321.738281 
	C2792.733398,3317.720459 2796.279541,3315.568604 2798.367920,3312.297119 
	C2802.489258,3305.841309 2807.820068,3304.665039 2815.247559,3307.781738 
	C2816.695801,3308.578613 2817.239258,3309.383057 2817.717773,3311.024902 
	C2815.164551,3321.966797 2812.582764,3332.103271 2815.049316,3342.908691 
	C2816.316406,3348.458496 2812.918457,3349.708252 2807.845215,3348.607178 
	C2796.299805,3346.101318 2787.635986,3339.043701 2779.660156,3331.000732 
	C2775.993408,3327.303467 2772.581299,3324.115967 2766.778809,3325.507568 
	C2763.211426,3326.363281 2760.176025,3324.620605 2757.996094,3321.938232 
	C2754.654053,3317.826416 2750.300049,3317.182861 2744.804932,3317.348145 
	C2743.262939,3316.701172 2742.489502,3316.146240 2741.325684,3314.949219 
	C2737.957275,3305.679688 2729.885254,3304.180420 2722.162109,3300.987305 
	C2719.202148,3294.807373 2723.348633,3292.676758 2727.319092,3291.129395 
	C2737.900635,3287.005371 2745.456543,3278.731934 2754.748535,3271.621338 
	C2757.935547,3268.622559 2761.358398,3267.669434 2765.426758,3266.301025 
	C2769.460205,3265.668701 2772.463867,3266.094971 2775.231934,3269.450684 
	C2775.867432,3271.602051 2775.977295,3273.171387 2776.138428,3275.525391 
	C2776.189941,3276.310547 2776.913330,3276.806641 2777.339355,3276.703125 
	C2779.852539,3276.225830 2781.939697,3275.852539 2784.797852,3275.285889 
	C2786.902588,3275.116699 2788.006348,3275.708252 2789.577637,3276.827393 
	C2790.881592,3277.569580 2791.590088,3278.050293 2792.618652,3279.144287 
	C2793.315430,3280.483398 2793.489990,3281.322266 2793.432617,3282.826660 
	C2793.086914,3284.262207 2792.736328,3285.021240 2791.947510,3286.237793 
	C2791.128906,3287.372559 2790.650391,3288.003418 2789.860596,3289.121094 
	C2788.794922,3290.949219 2787.871094,3292.165771 2786.474121,3293.741211 
z"
        />
        <path
          fill="#E6E1D0"
          opacity="1.000000"
          stroke="none"
          d="
M2775.822510,3269.339600 
	C2773.017090,3269.360107 2770.276367,3268.687256 2766.818604,3267.982910 
	C2761.775391,3267.912354 2760.147461,3265.326172 2759.968262,3260.710693 
	C2760.100098,3259.557617 2760.190430,3259.105469 2760.467773,3258.005859 
	C2762.029053,3253.638428 2758.695068,3253.184082 2756.196045,3251.352051 
	C2754.588867,3249.952393 2753.947266,3248.741943 2753.667969,3246.641602 
	C2753.777344,3242.026367 2756.367432,3241.933105 2759.206787,3242.478516 
	C2767.819336,3244.131592 2776.409180,3245.885986 2784.793945,3250.541016 
	C2785.709229,3254.010742 2783.237549,3254.673584 2780.997559,3254.713867 
	C2778.712646,3254.755371 2776.516357,3254.285645 2774.407715,3255.695557 
	C2772.856689,3256.732910 2771.466797,3258.477295 2773.334229,3259.357666 
	C2778.197266,3261.650391 2775.924805,3265.339111 2775.822510,3269.339600 
z"
        />
        <path
          fill="#E6E1D0"
          opacity="1.000000"
          stroke="none"
          d="
M2789.873291,3277.955566 
	C2788.226318,3277.449219 2787.189941,3276.842041 2785.861328,3275.663818 
	C2785.713867,3274.421143 2785.858643,3273.750000 2786.071777,3272.562500 
	C2788.162109,3272.244873 2789.646240,3273.106689 2790.228516,3275.936035 
	C2790.404053,3277.033203 2790.444092,3277.443848 2789.873291,3277.955566 
z"
        />
        <path
          fill="#E6E1D0"
          opacity="1.000000"
          stroke="none"
          d="
M2777.384766,3276.456543 
	C2777.765625,3276.599121 2777.135010,3277.080811 2777.135010,3277.080811 
	C2777.135010,3277.080811 2776.409912,3276.820312 2776.299805,3276.565430 
	C2776.189941,3276.310547 2777.003662,3276.313721 2777.384766,3276.456543 
z"
        />
        <path
          fill="#A9AEB3"
          opacity="1.000000"
          stroke="none"
          d="
M2752.997070,3245.817871 
	C2754.499023,3246.773193 2755.333252,3247.597412 2756.355469,3248.996582 
	C2748.755859,3256.082764 2740.723389,3250.025146 2732.083740,3250.058105 
	C2728.857422,3250.316895 2726.246826,3250.072510 2722.960938,3252.134521 
	C2729.116211,3255.672119 2735.601562,3256.920654 2741.207031,3261.268555 
	C2743.890869,3271.031006 2738.237549,3278.160645 2731.121338,3274.244629 
	C2723.484375,3270.041992 2715.696289,3271.229980 2707.312012,3269.722656 
	C2702.609131,3266.021240 2702.450684,3261.281006 2705.232910,3257.922363 
	C2711.314209,3250.579590 2706.904785,3245.979736 2701.708496,3240.539795 
	C2700.029053,3238.549316 2699.098877,3236.932617 2698.750977,3234.322998 
	C2698.930664,3233.175537 2699.100098,3232.736084 2699.861084,3231.909668 
	C2706.792236,3233.429443 2712.302734,3237.093994 2718.187988,3239.955566 
	C2726.785645,3244.136719 2726.733643,3244.243652 2733.249023,3235.813232 
	C2739.970215,3238.799072 2746.150146,3242.243164 2752.997070,3245.817871 
z"
        />
        <path
          fill="#3C556B"
          opacity="1.000000"
          stroke="none"
          d="
M2826.923584,3017.044189 
	C2826.876465,3016.958008 2826.970703,3017.130371 2826.923584,3017.044189 
z"
        />
        <path
          fill="#EFF1EA"
          opacity="1.000000"
          stroke="none"
          d="
M2816.133301,3313.172852 
	C2816.011475,3311.414062 2815.994873,3310.405029 2815.987305,3308.636719 
	C2817.156006,3307.258301 2818.518799,3306.850830 2819.433350,3305.975342 
	C2821.293457,3304.194580 2818.690674,3304.376953 2818.227783,3303.582031 
	C2817.111084,3301.665039 2819.030518,3301.383301 2819.944092,3301.172607 
	C2828.863037,3299.115234 2837.019043,3294.168213 2846.443848,3294.088379 
	C2849.167236,3294.065186 2851.533691,3292.871094 2854.070557,3292.234619 
	C2859.028076,3290.990967 2862.793945,3289.225586 2860.481445,3282.917969 
	C2860.010986,3281.634766 2861.406006,3280.617676 2863.400879,3279.967773 
	C2867.169189,3284.228760 2867.782715,3289.650635 2870.250732,3295.101318 
	C2866.266846,3298.825684 2861.286133,3299.262451 2856.425293,3300.048340 
	C2854.738037,3300.321045 2852.978271,3300.528564 2852.544922,3302.473389 
	C2852.125732,3304.354492 2853.778320,3305.233154 2854.825195,3306.503662 
	C2863.470703,3316.997070 2875.388916,3324.281738 2883.200928,3335.601074 
	C2884.956299,3338.144775 2887.568359,3338.437988 2890.185059,3336.524170 
	C2892.305176,3334.973877 2893.870605,3332.615234 2897.398926,3332.026855 
	C2898.114014,3331.975830 2898.434326,3332.832275 2898.563965,3333.270752 
	C2899.502930,3335.123047 2900.272705,3336.553711 2900.699219,3338.982178 
	C2900.823975,3340.334229 2900.851807,3340.877197 2900.902588,3342.235352 
	C2901.231934,3346.872314 2901.241943,3350.692383 2900.764160,3355.331787 
	C2899.114258,3360.255371 2899.916260,3365.040039 2896.419922,3369.330078 
	C2893.838867,3375.712158 2891.962402,3381.555664 2889.816895,3387.298828 
	C2888.465576,3390.916992 2887.178223,3394.668213 2883.156250,3397.275879 
	C2880.761963,3392.860596 2877.034912,3390.937500 2872.676514,3390.158691 
	C2869.578613,3389.604980 2866.892578,3388.878418 2866.022461,3384.616211 
	C2869.279053,3380.561768 2873.143311,3382.099365 2877.685547,3383.104492 
	C2867.538086,3376.593018 2858.757812,3369.757812 2850.003662,3362.916992 
	C2844.882568,3358.914795 2840.371826,3355.606445 2835.900879,3363.484863 
	C2835.272949,3364.590820 2833.752441,3364.919189 2831.705566,3365.125977 
	C2830.516846,3364.876465 2830.062744,3364.672852 2829.112793,3363.901855 
	C2827.073242,3360.626709 2826.767578,3357.611084 2826.927734,3354.636963 
	C2827.319824,3347.367920 2827.076660,3340.942871 2818.370117,3337.959717 
	C2814.260010,3336.551514 2813.815186,3331.898682 2814.000488,3327.662598 
	C2814.205322,3322.977051 2815.972412,3318.577881 2816.133301,3313.172852 
z"
        />
        <path
          fill="#4E585F"
          opacity="1.000000"
          stroke="none"
          d="
M2896.440430,3370.205078 
	C2895.540771,3365.650635 2896.481689,3361.551758 2897.740967,3356.735840 
	C2901.057861,3354.329834 2903.629395,3356.850098 2907.099121,3356.996338 
	C2909.014404,3357.049561 2910.261475,3356.755371 2912.180176,3356.484863 
	C2917.830322,3355.633545 2918.900391,3364.357422 2925.459961,3361.500977 
	C2928.274414,3362.148438 2929.763672,3363.628418 2931.778320,3365.448486 
	C2932.804688,3366.815674 2933.571045,3367.449707 2935.242920,3367.170898 
	C2940.562988,3369.297119 2939.924805,3373.720215 2939.893555,3378.740967 
	C2940.566162,3380.454834 2937.357666,3379.802002 2939.630371,3381.556152 
	C2940.052002,3383.477783 2940.101074,3385.122314 2940.518066,3387.510010 
	C2939.735840,3388.904541 2938.153076,3390.407959 2937.502197,3390.077393 
	C2929.715576,3386.119873 2920.925293,3387.454346 2912.378662,3385.603516 
	C2909.847900,3386.838623 2907.541992,3389.166260 2905.639893,3387.234619 
	C2903.171143,3384.727051 2899.555908,3384.844238 2897.253418,3382.665771 
	C2895.981445,3381.462158 2897.182373,3379.581543 2897.944824,3378.163086 
	C2899.444580,3375.373047 2900.399170,3372.700684 2896.440430,3370.205078 
z"
        />
        <path
          fill="#7B7F84"
          opacity="1.000000"
          stroke="none"
          d="
M2925.799316,3361.082520 
	C2925.341064,3362.261719 2924.593018,3362.914795 2923.780762,3363.474854 
	C2922.067627,3364.655762 2921.809082,3368.222412 2918.572021,3366.868652 
	C2915.691162,3365.664062 2916.819092,3363.088623 2916.684326,3360.880371 
	C2916.599121,3359.484375 2915.334473,3358.727295 2913.395752,3358.280273 
	C2911.275879,3357.854492 2909.877930,3358.200684 2908.278564,3359.653320 
	C2904.402832,3360.433594 2901.658936,3358.937256 2898.630127,3356.312988 
	C2898.087646,3352.028809 2898.159912,3348.044922 2898.229492,3343.208008 
	C2898.446533,3341.644287 2899.119873,3341.445068 2899.561768,3341.029053 
	C2900.342285,3340.294434 2900.045166,3339.891602 2898.656738,3339.918945 
	C2898.252930,3338.101562 2898.270752,3336.619629 2898.490967,3334.423340 
	C2903.583740,3334.126709 2908.224365,3334.723389 2912.995850,3336.762207 
	C2917.160645,3338.541748 2922.606445,3336.727051 2927.352539,3338.083740 
	C2932.027344,3339.419922 2937.242188,3339.072021 2941.612305,3343.104736 
	C2942.720215,3347.581299 2940.161377,3350.620850 2939.133789,3354.817871 
	C2938.416016,3355.563965 2937.981201,3355.604980 2937.223633,3355.734863 
	C2936.344971,3355.748535 2935.789307,3355.681152 2935.587891,3354.852539 
	C2930.950439,3347.883545 2924.196289,3346.863770 2915.896973,3346.829590 
	C2918.639893,3352.630859 2922.474854,3356.295166 2925.799316,3361.082520 
z"
        />
        <path
          fill="#7B7F84"
          opacity="1.000000"
          stroke="none"
          d="
M2943.581787,3334.120605 
	C2943.869385,3334.305420 2943.873779,3334.702393 2943.678711,3335.661865 
	C2943.310791,3335.801758 2943.093506,3335.390137 2942.998047,3334.951904 
	C2942.959961,3334.775635 2943.190186,3334.540771 2943.581787,3334.120605 
z"
        />
        <path
          fill="#D7D5CF"
          opacity="1.000000"
          stroke="none"
          d="
M2939.883789,3381.257812 
	C2939.081787,3381.875488 2938.147461,3381.831787 2937.242920,3381.976562 
	C2934.836914,3382.361084 2933.641113,3381.089111 2933.251221,3378.972656 
	C2933.020508,3377.720459 2933.181396,3376.130371 2934.748535,3375.959717 
	C2937.120850,3375.701660 2937.608887,3377.996582 2939.318359,3379.503418 
	C2939.913574,3379.913086 2939.839355,3380.297607 2939.883789,3381.257812 
z"
        />
        <path
          fill="#7B7F84"
          opacity="1.000000"
          stroke="none"
          d="
M2935.363770,3366.616699 
	C2935.671875,3368.409668 2936.253418,3370.538330 2934.090332,3370.681396 
	C2931.809570,3370.832031 2932.325195,3368.621582 2932.232666,3366.463867 
	C2933.217773,3365.954102 2934.118408,3366.139404 2935.363770,3366.616699 
z"
        />
        <path
          fill="#D0D1C8"
          opacity="1.000000"
          stroke="none"
          d="
M2935.548828,3354.338867 
	C2935.942139,3354.091064 2936.358887,3354.981689 2936.629883,3355.398926 
	C2936.190430,3356.236084 2935.527588,3357.370117 2934.670654,3356.202148 
	C2934.470215,3355.929199 2934.974854,3355.138916 2935.548828,3354.338867 
z"
        />
        <path
          fill="#D0D1C8"
          opacity="1.000000"
          stroke="none"
          d="
M2644.742188,3240.518555 
	C2650.614258,3237.844482 2656.120361,3236.104004 2661.625000,3232.649414 
	C2662.896484,3232.047119 2663.656982,3231.940430 2664.985840,3231.724609 
	C2666.493408,3231.264160 2667.805420,3230.860107 2668.357910,3231.533447 
	C2676.118164,3240.996338 2686.909180,3242.556396 2698.677246,3243.711182 
	C2702.479980,3251.499512 2704.496582,3258.946289 2703.081543,3267.687012 
	C2695.047363,3273.706299 2685.997803,3272.476807 2676.791504,3274.035400 
	C2673.406006,3274.534912 2671.157227,3275.725342 2668.956299,3277.227783 
	C2663.518555,3280.939453 2660.691895,3278.599365 2659.525879,3272.455811 
	C2656.541748,3278.994629 2656.088135,3284.644775 2662.982178,3289.575439 
	C2666.325439,3294.966797 2666.402100,3299.604492 2662.296875,3303.803711 
	C2659.640381,3306.521729 2659.201416,3310.410156 2656.388672,3313.803467 
	C2652.202881,3313.869629 2651.380371,3310.889648 2650.416260,3308.290527 
	C2649.520264,3305.874756 2648.753174,3303.503418 2645.843750,3301.841797 
	C2642.558350,3295.371826 2641.687988,3288.729980 2641.261475,3281.134766 
	C2640.314209,3278.409912 2639.811523,3276.468262 2640.291260,3273.689941 
	C2647.254883,3269.125488 2649.982422,3263.267090 2649.086182,3255.575439 
	C2647.004639,3255.892334 2646.237793,3259.646484 2642.860107,3257.274170 
	C2640.325928,3251.191895 2640.361328,3245.961670 2644.742188,3240.518555 
z"
        />
        <path
          fill="#CBC5B5"
          opacity="1.000000"
          stroke="none"
          d="
M2815.685547,3313.053467 
	C2819.094971,3315.548828 2817.824951,3319.288574 2817.214355,3322.747803 
	C2814.979492,3335.406738 2816.601807,3337.547119 2829.259521,3337.500977 
	C2830.652588,3345.678955 2829.318604,3353.992432 2830.154297,3363.141602 
	C2828.668945,3370.248047 2823.830566,3368.879150 2818.758545,3368.344482 
	C2818.007812,3367.981201 2817.165283,3368.118408 2816.738281,3368.114990 
	C2813.857178,3367.152832 2813.302246,3364.712646 2812.129639,3362.753662 
	C2805.346924,3351.425781 2799.323242,3350.833496 2790.581543,3361.078125 
	C2789.631836,3365.354004 2790.397217,3368.956787 2794.245850,3372.647217 
	C2787.636963,3372.103027 2783.179443,3370.035889 2778.620361,3368.320068 
	C2776.151123,3367.390869 2773.685059,3366.429932 2771.160156,3365.674805 
	C2765.653809,3364.027344 2758.227051,3360.298340 2754.258789,3356.103027 
	C2754.444336,3351.888428 2756.794678,3350.828857 2759.429199,3350.007080 
	C2764.407471,3348.454102 2765.234863,3345.151611 2763.115723,3340.044922 
	C2763.091309,3338.921143 2763.151367,3338.470947 2763.475342,3337.395020 
	C2766.684082,3331.426758 2763.066650,3329.809082 2759.001465,3328.543457 
	C2757.519287,3328.081787 2755.757812,3327.223389 2754.744141,3329.280273 
	C2754.084229,3330.619385 2754.906738,3331.753174 2755.739258,3332.780273 
	C2757.385010,3334.811035 2759.441895,3336.477783 2760.969727,3338.606445 
	C2762.311279,3340.475586 2763.767822,3342.480713 2762.671631,3344.884766 
	C2761.224854,3348.058838 2758.534668,3347.389160 2755.304688,3346.323242 
	C2754.287842,3345.769043 2753.902100,3345.511230 2753.004883,3344.784668 
	C2744.034424,3337.626221 2744.597656,3327.713379 2743.661621,3317.202148 
	C2751.320068,3313.037109 2757.092529,3314.281982 2761.133057,3321.552734 
	C2762.310547,3323.671631 2764.886475,3324.970947 2767.080566,3322.986328 
	C2772.926758,3317.698486 2777.259521,3322.834473 2779.266357,3326.201416 
	C2783.366211,3333.078857 2791.916016,3334.860107 2795.344482,3341.985107 
	C2801.795410,3341.223633 2806.649902,3345.932373 2813.710205,3347.828369 
	C2809.553711,3335.573486 2810.442139,3324.461182 2815.685547,3313.053467 
z"
        />
        <path
          fill="#CBC5B5"
          opacity="1.000000"
          stroke="none"
          d="
M2655.814453,3314.218750 
	C2656.352295,3309.079346 2654.515625,3303.330322 2661.708008,3300.392578 
	C2665.676758,3298.771729 2661.479736,3294.528809 2662.003418,3290.746094 
	C2660.586670,3285.620850 2662.481445,3282.345947 2666.611328,3282.071777 
	C2670.263184,3281.829346 2670.265625,3286.009521 2670.921143,3288.776123 
	C2671.375244,3290.693115 2671.438232,3292.687012 2671.638184,3295.393555 
	C2670.659424,3301.654541 2673.247559,3303.270264 2678.953613,3302.146484 
	C2688.203125,3299.824951 2694.658691,3302.122803 2698.638916,3311.166260 
	C2700.417969,3312.756104 2701.973389,3313.457520 2704.132324,3314.408203 
	C2709.669434,3317.259521 2713.478516,3322.011963 2720.221191,3322.559570 
	C2721.330322,3322.822510 2721.765381,3322.975342 2722.790039,3323.497070 
	C2727.981689,3328.607178 2732.973633,3332.629639 2739.881348,3333.452637 
	C2745.994629,3334.181641 2748.909424,3339.415283 2753.482178,3343.489990 
	C2753.977295,3343.978516 2754.000000,3344.000000 2754.012695,3344.010254 
	C2754.675049,3344.528809 2755.326904,3345.034424 2756.338135,3346.043213 
	C2756.737549,3346.958496 2756.780029,3347.367676 2756.649902,3348.365723 
	C2755.062012,3350.582275 2754.879150,3352.562012 2754.554199,3355.100098 
	C2754.239746,3355.609375 2754.003418,3355.952637 2753.878906,3356.122803 
	C2751.983887,3356.813232 2749.892578,3353.545410 2748.085693,3357.490479 
	C2736.720215,3354.590088 2725.598633,3351.010010 2714.528809,3347.276367 
	C2710.724121,3345.993408 2708.843018,3343.835449 2712.384766,3340.133789 
	C2713.677246,3338.782959 2713.716309,3337.221680 2712.221680,3335.154297 
	C2711.624756,3334.196533 2711.338135,3333.788574 2711.244629,3332.660889 
	C2712.257812,3331.365967 2713.161621,3331.066650 2714.831543,3331.384766 
	C2720.968750,3338.322510 2731.142578,3337.741699 2734.018311,3342.629639 
	C2731.279053,3338.970703 2723.936768,3336.974365 2716.880127,3333.273438 
	C2715.590576,3331.547119 2714.426514,3330.945312 2712.264893,3331.115723 
	C2707.289307,3331.976562 2705.247070,3328.210938 2701.571289,3326.203857 
	C2697.503418,3323.079834 2694.992676,3318.941895 2689.585693,3318.001465 
	C2693.038086,3320.590820 2699.058105,3320.769531 2696.431885,3327.602051 
	C2689.080322,3329.529297 2683.448730,3325.166016 2677.724121,3322.966309 
	C2671.188477,3320.454834 2665.331787,3317.531738 2658.173828,3319.308105 
	C2657.689941,3319.428223 2656.849854,3318.114502 2656.013672,3316.795410 
	C2656.552002,3315.832031 2656.810303,3315.318604 2656.235352,3314.324463 
	C2655.843994,3314.065674 2655.868408,3314.187744 2655.814453,3314.218750 
z"
        />
        <path
          fill="#FAFBBF"
          opacity="1.000000"
          stroke="none"
          d="
M2790.534668,3361.903320 
	C2792.732422,3349.397949 2800.422607,3346.766357 2809.636963,3354.806641 
	C2813.216064,3357.929688 2815.670898,3361.728027 2816.173340,3367.367920 
	C2812.291016,3374.150635 2807.487793,3372.384277 2802.469482,3368.287842 
	C2803.550537,3365.253662 2807.436768,3363.834717 2804.182129,3361.196777 
	C2802.047852,3359.467285 2801.213867,3362.319824 2799.943848,3364.408691 
	C2799.329590,3365.364258 2799.031494,3365.712158 2798.100342,3366.326660 
	C2794.430664,3366.526367 2792.868652,3364.233398 2790.534668,3361.903320 
z"
        />
        <path
          fill="#D0D1C8"
          opacity="1.000000"
          stroke="none"
          d="
M2696.560303,3328.183594 
	C2696.522461,3322.698975 2692.877441,3321.933105 2688.771240,3321.402344 
	C2685.976807,3321.040771 2681.880127,3320.838135 2683.267334,3316.669922 
	C2684.464600,3313.072021 2688.024170,3313.972168 2690.841553,3315.485840 
	C2694.613770,3317.512451 2698.259521,3319.735107 2701.449707,3323.640137 
	C2703.290039,3325.843994 2702.838379,3327.303467 2700.286865,3328.357422 
	C2698.659180,3328.206299 2697.894775,3328.232422 2696.560303,3328.183594 
z"
        />
        <path
          fill="#FAFBBF"
          opacity="1.000000"
          stroke="none"
          d="
M2817.963379,3368.744141 
	C2821.399658,3365.753418 2825.566895,3366.475342 2829.708984,3364.559082 
	C2830.249023,3364.070557 2831.139893,3364.108643 2831.584961,3364.133301 
	C2834.570557,3365.525391 2837.142822,3366.867432 2836.718750,3371.040771 
	C2833.883057,3374.776611 2829.342041,3372.828369 2826.140625,3376.004883 
	C2825.387939,3376.823242 2825.097900,3377.160645 2824.270020,3377.893066 
	C2818.268066,3377.695801 2817.777588,3373.810791 2817.963379,3368.744141 
z"
        />
        <path
          fill="#EBE4D0"
          opacity="1.000000"
          stroke="none"
          d="
M2699.877930,3328.737061 
	C2700.671143,3327.610840 2701.510010,3326.697266 2701.897949,3324.697754 
	C2706.553711,3323.398193 2707.348389,3328.672363 2711.510742,3329.835938 
	C2712.497070,3330.312988 2712.816406,3330.663086 2712.620605,3331.503418 
	C2712.062988,3332.425781 2712.020508,3332.858398 2711.944824,3333.947998 
	C2708.074219,3332.785156 2703.391602,3332.809082 2699.877930,3328.737061 
z"
        />
        <path
          fill="#0D171F"
          opacity="1.000000"
          stroke="none"
          d="
M2658.957520,3328.910645 
	C2651.107666,3328.609131 2650.733643,3328.243652 2650.608398,3320.139160 
	C2654.283447,3321.613037 2656.701904,3324.771484 2658.957520,3328.910645 
z"
        />
        <path
          fill="#FAFBBF"
          opacity="1.000000"
          stroke="none"
          d="
M2801.810059,3368.238770 
	C2800.602051,3368.048096 2799.441406,3367.786377 2797.873779,3367.059082 
	C2797.467041,3366.593506 2797.725830,3366.308594 2797.853027,3366.164062 
	C2799.495605,3364.777832 2800.847900,3365.092529 2802.032715,3367.246094 
	C2801.974121,3368.042480 2801.857422,3368.167480 2801.810059,3368.238770 
z"
        />
        <path
          fill="#CBC5B5"
          opacity="1.000000"
          stroke="none"
          d="
M2748.111328,3357.885254 
	C2749.290283,3358.161865 2750.369629,3358.551025 2751.675537,3359.529053 
	C2750.785889,3359.799072 2749.669678,3359.480225 2748.187500,3358.695801 
	C2747.821533,3358.230469 2748.011963,3357.998047 2748.111328,3357.885254 
z"
        />
        <path
          fill="#FAFBBF"
          opacity="1.000000"
          stroke="none"
          d="
M2825.323730,3377.818359 
	C2825.727051,3378.690674 2825.772949,3379.041992 2825.908203,3379.921631 
	C2825.392578,3380.055664 2824.787598,3379.661377 2823.957520,3378.777588 
	C2823.732422,3378.287842 2823.845215,3378.171387 2823.897461,3378.109863 
	C2824.288574,3377.797852 2824.627441,3377.547363 2825.323730,3377.818359 
z"
        />
        <path
          fill="#D0D1C8"
          opacity="1.000000"
          stroke="none"
          d="
M2655.774658,3314.580078 
	C2656.189453,3314.397461 2656.565186,3314.705566 2656.853516,3315.081299 
	C2656.909668,3315.153809 2656.623047,3315.489502 2656.173340,3315.913574 
	C2655.803223,3315.780273 2655.754395,3315.437500 2655.774658,3314.580078 
z"
        />
        <path
          fill="#CBC5B5"
          opacity="1.000000"
          stroke="none"
          d="
M2752.052734,3359.961914 
	C2752.334229,3360.241943 2752.562256,3360.571045 2752.790527,3360.899902 
	C2752.579346,3360.827637 2752.368164,3360.755371 2752.029297,3360.400391 
	C2751.901855,3360.118164 2751.999023,3360.010742 2752.052734,3359.961914 
z"
        />
        <path
          fill="#C4BDAE"
          opacity="1.000000"
          stroke="none"
          d="
M2836.860840,3373.047852 
	C2836.447266,3368.860596 2832.820801,3368.021240 2832.142822,3364.795898 
	C2836.613281,3361.883545 2836.983398,3356.672119 2840.014648,3352.239990 
	C2847.272705,3357.701172 2854.266113,3363.058350 2861.361816,3368.275879 
	C2868.466309,3373.500244 2875.673584,3378.585205 2883.534424,3384.233398 
	C2877.314697,3387.936035 2872.516846,3383.302979 2866.772949,3383.938477 
	C2861.343506,3380.796143 2854.818848,3382.528076 2850.861816,3376.996338 
	C2848.413330,3373.573975 2845.934814,3380.613525 2842.154297,3378.168945 
	C2840.452393,3376.423584 2839.174561,3375.143799 2836.860840,3373.047852 
z"
        />
        <path
          fill="#C4BDAE"
          opacity="1.000000"
          stroke="none"
          d="
M2826.514160,3378.270508 
	C2825.681152,3378.339600 2824.823486,3378.148682 2824.386719,3378.098633 
	C2824.946533,3369.533691 2825.614014,3369.143066 2835.274902,3371.936035 
	C2836.016113,3372.017578 2835.998535,3371.998779 2835.990479,3371.990723 
	C2836.671387,3372.655762 2837.013672,3373.473633 2836.695312,3375.058594 
	C2834.004883,3378.795166 2830.492188,3377.839844 2826.514160,3378.270508 
z"
        />
        <path
          fill="#FAFBBF"
          opacity="1.000000"
          stroke="none"
          d="
M2836.876953,3376.006836 
	C2836.249756,3374.863037 2836.117188,3374.045654 2835.983398,3372.605469 
	C2839.143311,3371.824463 2840.769287,3373.626221 2841.690430,3377.042725 
	C2840.093506,3377.872803 2838.677490,3377.281738 2836.876953,3376.006836 
z"
        />
        <path
          fill="#79868C"
          opacity="1.000000"
          stroke="none"
          d="
M1196.233643,1799.233154 
	C1189.907593,1800.149292 1183.679688,1799.832031 1177.232056,1798.837036 
	C1174.493896,1807.183594 1177.148438,1815.568237 1175.432983,1823.795532 
	C1172.702881,1821.100952 1175.246094,1817.109253 1172.214844,1814.088257 
	C1169.932739,1812.761719 1169.018799,1811.222656 1167.787476,1809.104248 
	C1164.345337,1802.694092 1156.885254,1802.696045 1152.811279,1797.202393 
	C1151.702759,1786.124390 1151.920776,1775.647461 1152.491577,1764.175537 
	C1155.967773,1763.699219 1159.208130,1764.216553 1163.283325,1764.755493 
	C1165.823853,1763.905640 1167.563843,1763.937378 1169.915527,1764.899048 
	C1171.538696,1766.057983 1172.372314,1767.091675 1173.734619,1768.522705 
	C1176.115601,1770.900146 1178.957397,1771.333374 1181.343018,1773.695068 
	C1183.719971,1779.133057 1188.513306,1778.859253 1193.232544,1780.020386 
	C1196.752930,1780.453735 1199.973877,1779.194946 1202.264404,1782.086060 
	C1201.698975,1784.876465 1199.068237,1783.421387 1197.618164,1784.325195 
	C1193.562012,1786.853516 1192.622925,1790.942749 1196.670654,1792.693970 
	C1204.100098,1795.908325 1195.591187,1796.717529 1196.233643,1799.233154 
z"
        />
        <path
          fill="#9DAAB4"
          opacity="1.000000"
          stroke="none"
          d="
M1181.806396,1773.473999 
	C1178.649658,1773.329346 1174.830200,1775.346069 1172.101807,1771.260376 
	C1170.970947,1769.140259 1171.899902,1767.060425 1169.620850,1766.038086 
	C1167.612549,1765.961060 1166.235718,1765.716064 1164.488525,1765.124023 
	C1163.879883,1760.604126 1164.248291,1757.141968 1170.026001,1758.985840 
	C1171.191284,1759.357910 1172.708008,1759.360107 1173.844116,1758.940796 
	C1176.286377,1758.039307 1178.914673,1755.486206 1180.849854,1755.987915 
	C1182.695068,1756.466675 1183.641235,1759.533936 1186.654785,1760.383179 
	C1190.328979,1761.418701 1191.016235,1767.261230 1188.273071,1770.153198 
	C1186.945557,1771.552734 1185.375854,1771.543457 1184.396851,1769.436035 
	C1183.874146,1768.310791 1184.398071,1766.666382 1182.724243,1766.068237 
	C1181.413818,1768.157593 1182.117676,1770.510620 1181.806396,1773.473999 
z"
        />
        <path
          fill="#79868C"
          opacity="1.000000"
          stroke="none"
          d="
M1194.476807,1736.534180 
	C1193.975708,1735.037476 1193.917114,1734.062866 1193.991577,1733.098511 
	C1194.206787,1730.315186 1193.400024,1726.715698 1197.573975,1726.315918 
	C1202.164307,1725.876099 1203.854980,1728.771362 1203.849365,1732.965942 
	C1203.848022,1733.934692 1203.777344,1734.903564 1203.629883,1736.628662 
	C1200.646973,1737.991943 1197.771729,1738.628784 1194.476807,1736.534180 
z"
        />
        <path
          fill="#9DAAB4"
          opacity="1.000000"
          stroke="none"
          d="
M1163.670044,1732.576782 
	C1166.670410,1728.980103 1169.311890,1730.189575 1172.233643,1733.285889 
	C1167.117676,1739.213379 1166.396484,1739.170776 1163.670044,1732.576782 
z"
        />
        <path
          fill="#9DAAB4"
          opacity="1.000000"
          stroke="none"
          d="
M1156.125610,1730.834595 
	C1157.989990,1730.250122 1159.472656,1730.867554 1160.496094,1733.257324 
	C1157.411743,1736.623413 1156.113403,1735.313965 1156.125610,1730.834595 
z"
        />
        <path
          fill="#9DAAB4"
          opacity="1.000000"
          stroke="none"
          d="
M1188.889771,1775.082031 
	C1188.832397,1774.979736 1188.947144,1775.184326 1188.889771,1775.082031 
z"
        />
        <path
          fill="#C4C8C1"
          opacity="1.000000"
          stroke="none"
          d="
M1238.064331,1843.366211 
	C1233.987061,1844.323608 1229.794678,1844.518555 1225.604370,1843.660645 
	C1225.675659,1839.899902 1228.357056,1840.312134 1231.187012,1839.858154 
	C1234.142456,1840.258057 1236.942749,1839.559204 1238.064331,1843.366211 
z"
        />
        <path
          fill="#B9A795"
          opacity="1.000000"
          stroke="none"
          d="
M1151.942627,1796.341797 
	C1158.556152,1799.517700 1166.090210,1799.877808 1169.645142,1807.583496 
	C1167.609009,1812.167114 1168.753296,1816.996338 1165.380127,1820.999512 
	C1162.835815,1822.243286 1160.484497,1821.749268 1157.929688,1823.112549 
	C1153.383423,1823.774536 1154.500732,1828.758301 1150.748291,1830.253906 
	C1145.431030,1827.598999 1145.902100,1822.794067 1145.804077,1818.225342 
	C1145.685425,1812.681274 1146.648560,1807.053711 1144.292969,1800.877441 
	C1145.931030,1797.641113 1146.346313,1792.761963 1151.942627,1796.341797 
z"
        />
        <path
          fill="#797771"
          opacity="1.000000"
          stroke="none"
          d="
M1119.178955,1799.159058 
	C1123.639038,1798.215454 1127.892334,1798.103882 1133.053955,1797.989014 
	C1134.799072,1797.228027 1135.767700,1796.974365 1137.504639,1797.694092 
	C1138.624512,1799.103882 1139.108276,1800.044189 1139.824097,1801.460205 
	C1140.056030,1801.935913 1139.811401,1802.187500 1139.688843,1802.312988 
	C1128.426514,1807.510254 1124.197144,1807.007568 1119.178955,1799.159058 
z"
        />
        <path
          fill="#838F9A"
          opacity="1.000000"
          stroke="none"
          d="
M1138.585693,1797.652466 
	C1137.211670,1798.132446 1136.282715,1798.101440 1134.657959,1798.028076 
	C1133.902100,1794.738647 1134.468994,1791.977051 1139.358398,1791.920166 
	C1139.748169,1793.619507 1139.389282,1795.380493 1138.585693,1797.652466 
z"
        />
        <path
          fill="#A6A29C"
          opacity="1.000000"
          stroke="none"
          d="
M1118.678345,1798.256348 
	C1121.102539,1802.169067 1123.922119,1804.023193 1128.141479,1802.819824 
	C1131.414551,1801.886230 1134.683594,1801.774780 1138.790405,1802.306396 
	C1139.984497,1804.822266 1140.327881,1807.140625 1137.111572,1807.939941 
	C1134.717163,1808.534912 1131.973389,1807.349243 1129.224365,1809.204468 
	C1132.034790,1813.197021 1134.875977,1809.782349 1137.375854,1810.055176 
	C1139.020508,1810.234741 1140.942383,1810.123779 1140.968750,1812.426880 
	C1140.989990,1814.294678 1139.617798,1815.267822 1137.919067,1815.819214 
	C1134.336670,1816.981567 1130.919312,1813.147095 1126.655396,1815.734009 
	C1125.300415,1815.563599 1124.695068,1815.045532 1123.862061,1813.924438 
	C1122.624756,1811.829956 1121.179199,1811.796753 1118.982178,1812.244751 
	C1117.439331,1812.569824 1116.523926,1812.698486 1114.853516,1812.890137 
	C1110.361084,1813.121582 1106.600952,1813.340820 1102.857910,1810.229614 
	C1101.859741,1808.027954 1101.386719,1806.443726 1100.727783,1804.266846 
	C1098.694580,1799.925537 1094.815063,1798.732300 1091.267578,1796.052124 
	C1090.345825,1794.095581 1090.365234,1792.708740 1091.359375,1790.760498 
	C1099.922852,1789.536987 1107.320679,1790.910034 1113.373291,1797.001953 
	C1114.331909,1797.966675 1116.410156,1797.818970 1118.678345,1798.256348 
z"
        />
        <path
          fill="#8C8D89"
          opacity="1.000000"
          stroke="none"
          d="
M1125.051270,1811.442383 
	C1125.725464,1812.717163 1125.818481,1813.646729 1125.955811,1815.275269 
	C1126.108032,1819.884399 1125.495972,1823.853394 1126.899414,1827.658569 
	C1127.391357,1828.992432 1127.463501,1830.455444 1126.264160,1831.185913 
	C1124.685791,1832.146973 1122.989502,1831.615479 1122.069458,1829.992188 
	C1119.490356,1825.441406 1116.070068,1826.139282 1111.975464,1827.715820 
	C1108.958130,1828.877563 1108.019897,1830.747314 1107.991333,1833.630005 
	C1107.964111,1836.381470 1106.545410,1837.854004 1102.987549,1837.877319 
	C1102.235596,1837.870605 1102.031982,1836.974854 1101.906494,1836.533203 
	C1101.913574,1829.402954 1102.046143,1822.714233 1102.227051,1815.124023 
	C1103.402710,1812.675415 1105.171997,1812.259033 1106.559814,1813.111084 
	C1113.131958,1817.145630 1118.984619,1816.069336 1125.051270,1811.442383 
z"
        />
        <path
          fill="#7B7F84"
          opacity="1.000000"
          stroke="none"
          d="
M1072.482910,1818.335083 
	C1071.494385,1816.974487 1071.402344,1815.554321 1070.762085,1815.235718 
	C1062.353516,1811.049072 1062.334106,1811.088135 1060.408813,1802.558594 
	C1062.351196,1800.420044 1065.260864,1801.245117 1067.618530,1800.689087 
	C1074.559448,1799.051758 1076.041138,1800.025269 1077.738037,1807.188477 
	C1078.463013,1810.249512 1079.488770,1810.872925 1082.341187,1810.364990 
	C1084.456299,1809.988281 1086.919434,1809.241333 1089.079956,1812.073730 
	C1087.223022,1815.153931 1084.490723,1816.164185 1080.989624,1815.209717 
	C1078.974243,1814.660400 1077.133301,1813.974121 1076.102539,1817.333008 
	C1075.179932,1818.758423 1074.221191,1819.022827 1072.482910,1818.335083 
z"
        />
        <path
          fill="#797771"
          opacity="1.000000"
          stroke="none"
          d="
M1125.634888,1811.164062 
	C1125.030151,1812.014648 1124.392944,1812.177002 1123.831421,1812.479858 
	C1121.075562,1813.966187 1124.606201,1819.667725 1120.333862,1819.126709 
	C1116.262695,1818.610962 1111.256836,1820.984497 1107.917847,1816.273315 
	C1107.124756,1815.154419 1105.084351,1814.919312 1102.946655,1814.250000 
	C1102.215820,1813.196777 1102.156128,1812.171265 1102.214600,1810.378906 
	C1106.261841,1809.778809 1110.191040,1809.945557 1114.964844,1810.223633 
	C1116.821289,1811.078247 1117.895020,1811.395020 1119.486816,1810.363159 
	C1122.047363,1807.808960 1124.068115,1807.105225 1125.634888,1811.164062 
z"
        />
        <path
          fill="#D7D5CF"
          opacity="1.000000"
          stroke="none"
          d="
M1091.308350,1789.683105 
	C1091.896484,1791.681152 1091.975586,1793.167603 1092.117920,1795.396729 
	C1092.164795,1798.185303 1090.979004,1798.893555 1088.568359,1798.225464 
	C1085.916016,1796.512939 1083.359131,1798.362671 1080.754150,1796.262207 
	C1081.870605,1787.609863 1082.352539,1787.304810 1091.308350,1789.683105 
z"
        />
        <path
          fill="#8C8D89"
          opacity="1.000000"
          stroke="none"
          d="
M1071.192139,1818.020508 
	C1072.765991,1817.911377 1073.690918,1817.939941 1075.308350,1817.981934 
	C1075.955444,1821.579102 1076.097534,1825.178833 1075.797363,1828.741333 
	C1075.599487,1831.088379 1073.871460,1832.127563 1071.596069,1831.287720 
	C1069.774902,1830.615479 1067.168579,1828.680420 1069.349243,1827.293823 
	C1073.335815,1824.758545 1071.305786,1824.366211 1069.041626,1822.512817 
	C1067.009033,1820.849121 1068.395508,1819.072876 1071.192139,1818.020508 
z"
        />
        <path
          fill="#797771"
          opacity="1.000000"
          stroke="none"
          d="
M1088.384277,1798.260132 
	C1089.144775,1797.410156 1090.264648,1797.191895 1091.782959,1796.556641 
	C1093.805664,1796.104370 1095.430054,1796.060181 1097.054688,1796.035889 
	C1101.891479,1795.963501 1102.458374,1798.568237 1100.791260,1803.042358 
	C1095.325439,1805.247192 1091.958008,1802.258301 1088.384277,1798.260132 
z"
        />
        <path
          fill="#7B7F84"
          opacity="1.000000"
          stroke="none"
          d="
M1087.162109,1805.727295 
	C1084.954224,1805.866333 1082.465698,1806.491089 1082.104736,1805.005981 
	C1081.472534,1802.405884 1084.064087,1802.154053 1085.899902,1802.198242 
	C1088.002075,1802.249023 1090.020020,1802.834106 1087.162109,1805.727295 
z"
        />
        <path
          fill="#434F51"
          opacity="1.000000"
          stroke="none"
          d="
M1038.107910,1812.882812 
	C1039.472778,1813.633667 1039.484253,1814.391235 1038.511597,1815.059204 
	C1038.308105,1815.198975 1037.833374,1815.191650 1037.633057,1815.046265 
	C1036.672241,1814.349487 1036.720337,1813.595703 1038.107910,1812.882812 
z"
        />
        <path
          fill="#434F51"
          opacity="1.000000"
          stroke="none"
          d="
M1038.110596,1804.881348 
	C1039.471313,1805.630615 1039.487427,1806.385986 1038.515747,1807.052856 
	C1038.312866,1807.192261 1037.838989,1807.184082 1037.639404,1807.038696 
	C1036.685547,1806.344482 1036.723755,1805.592285 1038.110596,1804.881348 
z"
        />
        <path
          fill="#5A605E"
          opacity="1.000000"
          stroke="none"
          d="
M994.794312,1806.229248 
	C996.427368,1806.770630 997.377930,1807.739502 996.799316,1808.485107 
	C995.025452,1810.771362 992.387756,1811.111572 989.719543,1810.542725 
	C988.471497,1810.276611 987.108948,1809.484863 987.190125,1807.986450 
	C987.281250,1806.305420 988.885803,1806.175659 990.162598,1806.062256 
	C991.468872,1805.946289 992.797363,1806.080078 994.794312,1806.229248 
z"
        />
        <path
          fill="#5A605E"
          opacity="1.000000"
          stroke="none"
          d="
M994.296753,1800.786133 
	C991.369873,1801.959717 989.470886,1801.323608 987.535889,1798.643921 
	C990.652405,1797.764282 993.288452,1797.004517 995.942932,1799.135986 
	C996.008423,1799.188477 995.785034,1799.709961 995.600830,1799.934326 
	C995.406067,1800.171997 995.096985,1800.315918 994.296753,1800.786133 
z"
        />
        <path
          fill="#6E8AA1"
          opacity="1.000000"
          stroke="none"
          d="
M1816.470947,1902.300537 
	C1816.975098,1904.337402 1816.921631,1906.563843 1815.409790,1906.972900 
	C1813.157349,1907.582397 1812.789917,1904.555298 1810.695557,1903.332520 
	C1810.145386,1901.723511 1809.853638,1900.089111 1811.152710,1899.422241 
	C1813.639038,1898.145752 1814.275024,1901.057373 1816.470947,1902.300537 
z"
        />
        <path
          fill="#6E8AA1"
          opacity="1.000000"
          stroke="none"
          d="
M1819.883545,1899.775879 
	C1820.020386,1900.257080 1819.186035,1900.695801 1818.720581,1900.786743 
	C1818.299805,1899.923584 1818.135864,1898.773071 1819.883545,1899.775879 
z"
        />
        <path
          fill="#FBF9EC"
          opacity="1.000000"
          stroke="none"
          d="
M1855.395020,1948.364746 
	C1854.782104,1948.302856 1854.622925,1947.993530 1854.667114,1947.223145 
	C1855.957642,1946.664673 1856.913696,1946.661865 1855.395020,1948.364746 
z"
        />
        <path
          fill="#D0D1C8"
          opacity="1.000000"
          stroke="none"
          d="
M2093.668701,1904.031250 
	C2099.593994,1903.140747 2099.218262,1907.121216 2098.336914,1910.620850 
	C2097.269287,1914.860840 2094.697998,1916.007935 2090.769531,1912.153320 
	C2090.358398,1910.463989 2090.394043,1909.160278 2090.457520,1907.203369 
	C2090.412842,1905.472046 2090.776367,1904.567993 2092.323242,1903.976318 
	C2093.075928,1904.116821 2093.616455,1904.168091 2093.668701,1904.031250 
z"
        />
        <path
          fill="#50494B"
          opacity="1.000000"
          stroke="none"
          d="
M2092.726562,1903.545898 
	C2092.533203,1904.694458 2091.990479,1905.271973 2090.966309,1906.199951 
	C2088.927734,1905.568481 2088.942871,1904.258911 2089.868652,1902.254395 
	C2090.815430,1902.134521 2091.596436,1902.554688 2092.726562,1903.545898 
z"
        />
        <path
          fill="#5A605E"
          opacity="1.000000"
          stroke="none"
          d="
M1777.647461,1911.115845 
	C1776.825562,1911.724243 1776.083496,1911.740723 1774.788330,1911.734863 
	C1774.277832,1910.165771 1774.657104,1908.838623 1776.938232,1908.281250 
	C1777.561768,1908.949585 1777.644531,1909.736694 1777.647461,1911.115845 
z"
        />
        <path
          fill="#A9AEB3"
          opacity="1.000000"
          stroke="none"
          d="
M1883.958130,1924.037109 
	C1887.360840,1924.907227 1888.691284,1926.516357 1886.525146,1928.910889 
	C1885.105713,1930.480103 1882.804932,1930.487305 1881.376953,1928.909912 
	C1879.185303,1926.489014 1880.588989,1924.919678 1883.958130,1924.037109 
z"
        />
        <path
          fill="#E7E3DA"
          opacity="1.000000"
          stroke="none"
          d="
M1896.340332,1922.025879 
	C1896.781494,1919.543457 1896.403442,1916.353271 1899.416504,1913.998047 
	C1900.051025,1916.798218 1900.900879,1920.208008 1896.340332,1922.025879 
z"
        />
        <path
          fill="#69849B"
          opacity="1.000000"
          stroke="none"
          d="
M2435.138672,1917.477051 
	C2435.252930,1919.859619 2433.983154,1920.876099 2431.288086,1920.995361 
	C2430.137939,1919.019775 2430.062744,1917.058472 2431.803223,1915.796631 
	C2433.204590,1914.780396 2433.878174,1916.349854 2435.138672,1917.477051 
z"
        />
        <path
          fill="#646A6C"
          opacity="1.000000"
          stroke="none"
          d="
M2246.074951,1912.668701 
	C2242.658203,1914.799438 2238.958740,1916.358398 2234.522949,1918.059326 
	C2233.286133,1917.158813 2232.785645,1916.116455 2232.241211,1914.497314 
	C2235.207520,1914.240356 2237.678467,1913.132935 2240.062012,1911.343018 
	C2242.026123,1909.868164 2244.161621,1909.734985 2246.074951,1912.668701 
z"
        />
        <path
          fill="#E7E3DA"
          opacity="1.000000"
          stroke="none"
          d="
M2305.984863,1923.327637 
	C2305.387939,1925.037598 2304.714844,1926.234619 2303.682617,1928.029541 
	C2301.303223,1927.708740 2300.078857,1926.317017 2300.678223,1923.350830 
	C2302.522217,1922.461914 2304.241943,1919.442261 2305.984863,1923.327637 
z"
        />
        <path
          fill="#9DAAB4"
          opacity="1.000000"
          stroke="none"
          d="
M2283.604492,1932.309570 
	C2283.723633,1933.084717 2283.382324,1934.253296 2282.508057,1935.747559 
	C2282.364746,1934.949707 2282.754395,1933.826538 2283.604492,1932.309570 
z"
        />
        <path
          fill="#9DAAB4"
          opacity="1.000000"
          stroke="none"
          d="
M2285.633301,1930.195801 
	C2285.697754,1930.525635 2285.316650,1931.117798 2284.431396,1931.896484 
	C2284.347412,1931.541260 2284.767578,1930.999634 2285.633301,1930.195801 
z"
        />
        <path
          fill="#5A6668"
          opacity="1.000000"
          stroke="none"
          d="
M2609.133789,1898.057739 
	C2609.736084,1899.389771 2609.618164,1900.743652 2609.347168,1902.757080 
	C2608.496338,1903.413208 2607.798828,1903.409546 2606.580078,1903.473633 
	C2605.420410,1903.126465 2604.782227,1902.711304 2603.562500,1902.182861 
	C2604.058350,1899.741089 2605.534424,1897.955200 2609.133789,1898.057739 
z"
        />
        <path
          fill="#D0D1C8"
          opacity="1.000000"
          stroke="none"
          d="
M2564.475342,1891.851074 
	C2563.934814,1891.197632 2563.928711,1890.280762 2563.894043,1888.677856 
	C2565.331055,1888.230469 2566.796631,1888.469238 2568.262207,1888.708008 
	C2567.905762,1890.489868 2566.161377,1890.703857 2564.475342,1891.851074 
z"
        />
        <path
          fill="#8C8D89"
          opacity="1.000000"
          stroke="none"
          d="
M2623.021484,1874.688477 
	C2623.464844,1876.347900 2625.345215,1879.220703 2620.835205,1878.031982 
	C2620.417725,1876.294312 2620.694092,1874.959106 2623.021484,1874.688477 
z"
        />
        <path
          fill="#A9ACA9"
          opacity="1.000000"
          stroke="none"
          d="
M2592.482910,1852.102051 
	C2591.808350,1846.825439 2591.866699,1841.322388 2591.970215,1834.916382 
	C2595.190918,1838.177124 2594.190430,1843.453979 2595.699219,1848.080933 
	C2596.235840,1849.726440 2594.907471,1851.161865 2592.482910,1852.102051 
z"
        />
        <path
          fill="#A9ACA9"
          opacity="1.000000"
          stroke="none"
          d="
M2592.959961,1858.936279 
	C2593.042725,1858.891968 2592.876953,1858.980469 2592.959961,1858.936279 
z"
        />
        <path
          fill="#ECA777"
          opacity="1.000000"
          stroke="none"
          d="
M1526.404541,1917.511230 
	C1521.845825,1915.390747 1517.094971,1916.562500 1512.137817,1915.653198 
	C1515.742920,1910.887695 1518.055786,1905.880371 1518.097900,1899.346191 
	C1521.175171,1897.970093 1521.662720,1893.762207 1525.091553,1893.430420 
	C1529.042236,1896.015137 1526.131714,1900.298462 1527.825684,1904.246826 
	C1527.985718,1907.473633 1527.934570,1909.897949 1527.966553,1913.049561 
	C1527.647705,1914.888428 1527.245850,1916.000122 1526.404541,1917.511230 
z"
        />
        <path
          fill="#E7E3DA"
          opacity="1.000000"
          stroke="none"
          d="
M1487.261230,1906.196899 
	C1490.405273,1910.116211 1489.822754,1912.851562 1484.842163,1912.322510 
	C1479.471802,1911.751953 1475.082153,1907.859497 1469.433350,1907.287598 
	C1467.275269,1907.069092 1468.983032,1904.020630 1469.969116,1901.671143 
	C1474.480225,1898.880005 1478.327759,1899.130249 1481.531494,1903.203491 
	C1482.769287,1904.777466 1484.406738,1905.908447 1487.261230,1906.196899 
z"
        />
        <path
          fill="#E7E3DA"
          opacity="1.000000"
          stroke="none"
          d="
M1410.672852,1896.819092 
	C1410.422729,1900.386719 1410.100342,1903.262573 1409.758545,1906.136230 
	C1409.500488,1908.305420 1408.449463,1909.751221 1406.105591,1909.811401 
	C1403.231201,1909.885254 1402.076782,1908.283691 1402.089355,1904.790771 
	C1403.290161,1900.517700 1404.566284,1897.021118 1406.149414,1892.907227 
	C1407.042603,1891.458984 1407.832886,1891.051270 1409.440674,1891.556641 
	C1410.212646,1893.363647 1410.402466,1894.745117 1410.672852,1896.819092 
z"
        />
        <path
          fill="#E7E3DA"
          opacity="1.000000"
          stroke="none"
          d="
M1448.744873,1905.882935 
	C1447.704224,1904.689941 1447.357056,1903.675781 1446.571777,1902.343018 
	C1446.920288,1901.144409 1447.706909,1900.264526 1448.992920,1899.041626 
	C1451.684204,1897.981323 1453.828003,1897.803223 1456.181396,1900.015747 
	C1456.537476,1900.766479 1456.687378,1901.214355 1456.842529,1901.394043 
	C1457.325928,1904.676514 1459.927124,1905.675171 1462.041504,1907.209351 
	C1463.110596,1907.984985 1463.703491,1909.389771 1462.866089,1910.696655 
	C1462.113647,1911.870728 1460.831177,1912.217163 1459.416382,1912.074829 
	C1455.134033,1911.643799 1452.918457,1907.807983 1448.744873,1905.882935 
z"
        />
        <path
          fill="#E7E3DA"
          opacity="1.000000"
          stroke="none"
          d="
M1421.274170,1897.143311 
	C1422.753174,1901.138916 1427.690430,1902.891846 1428.015015,1908.148926 
	C1425.879639,1908.759644 1423.734863,1908.578369 1420.780762,1908.415771 
	C1420.452759,1905.273560 1417.313477,1902.923462 1418.079346,1898.962891 
	C1418.970703,1897.911255 1419.801147,1897.609131 1421.274170,1897.143311 
z"
        />
        <path
          fill="#E7E3DA"
          opacity="1.000000"
          stroke="none"
          d="
M1430.889771,1900.491333 
	C1428.889648,1899.224487 1427.723633,1897.910278 1428.273193,1895.240479 
	C1430.320435,1893.962646 1432.318726,1893.341064 1434.928711,1892.850830 
	C1435.678467,1895.046387 1435.816406,1897.110840 1435.995728,1899.973145 
	C1434.566406,1900.675903 1433.095703,1900.580688 1430.889771,1900.491333 
z"
        />
        <path
          fill="#5A605E"
          opacity="1.000000"
          stroke="none"
          d="
M1384.657715,1885.618164 
	C1384.974854,1882.401489 1386.928589,1882.107910 1388.935181,1882.119141 
	C1390.488403,1882.127808 1392.422852,1881.895020 1392.850586,1884.020996 
	C1393.251831,1886.016602 1391.683960,1886.968872 1390.149048,1887.480713 
	C1388.244385,1888.116089 1386.287476,1888.114136 1384.657715,1885.618164 
z"
        />
        <path
          fill="#73828A"
          opacity="1.000000"
          stroke="none"
          d="
M1456.961914,1900.065186 
	C1454.652588,1900.252808 1452.767700,1899.739258 1450.187500,1898.962158 
	C1449.948975,1896.089722 1450.615356,1893.545410 1454.048706,1893.944336 
	C1457.271973,1894.318970 1458.168945,1896.388184 1456.961914,1900.065186 
z"
        />
        <path
          fill="#9FA2A1"
          opacity="1.000000"
          stroke="none"
          d="
M1410.123901,1891.370850 
	C1409.232788,1892.092651 1408.442871,1892.203247 1407.054688,1892.301880 
	C1406.211548,1890.991211 1406.484863,1889.721924 1407.659912,1889.152466 
	C1409.073730,1888.467407 1409.810547,1889.506226 1410.123901,1891.370850 
z"
        />
        <path
          fill="#73828A"
          opacity="1.000000"
          stroke="none"
          d="
M1517.336182,1892.197266 
	C1516.424561,1892.703125 1515.748901,1892.605469 1514.583618,1892.349854 
	C1513.973267,1890.855591 1514.252686,1889.405029 1515.601562,1889.156372 
	C1517.137329,1888.873047 1517.574463,1890.228882 1517.336182,1892.197266 
z"
        />
        <path
          fill="#5A605E"
          opacity="1.000000"
          stroke="none"
          d="
M1668.138672,1896.677856 
	C1673.566650,1895.044189 1677.952637,1897.420532 1683.376587,1897.967773 
	C1687.495361,1898.265625 1691.037109,1898.399292 1688.659058,1903.431152 
	C1687.843628,1905.156616 1687.222168,1906.973877 1686.177490,1909.371826 
	C1685.417236,1910.490112 1684.990967,1910.985229 1684.554199,1911.969482 
	C1684.544312,1912.458984 1684.536255,1912.546631 1684.560669,1912.583252 
	C1682.930542,1913.458130 1681.238525,1913.683960 1678.787109,1912.393066 
	C1678.087524,1911.854492 1677.234497,1911.742188 1676.807861,1911.782959 
	C1675.093140,1908.656982 1671.978394,1908.387207 1669.331299,1907.352905 
	C1661.813599,1904.415527 1661.648682,1903.429932 1668.138672,1896.677856 
z"
        />
        <path
          fill="#6F7981"
          opacity="1.000000"
          stroke="none"
          d="
M1661.980469,1908.045654 
	C1663.400635,1908.912476 1663.137451,1909.711060 1662.021240,1909.739746 
	C1661.035034,1909.765015 1660.441284,1909.060059 1661.980469,1908.045654 
z"
        />
        <path
          fill="#B6AFA3"
          opacity="1.000000"
          stroke="none"
          d="
M1694.130859,1941.164429 
	C1690.399780,1946.073853 1685.079102,1942.432129 1680.564941,1945.278320 
	C1676.598755,1945.682983 1673.735229,1945.020142 1675.686768,1939.997070 
	C1676.986450,1938.866577 1677.656738,1938.066406 1678.066406,1936.475708 
	C1678.315796,1935.623901 1678.439697,1935.290527 1678.782471,1934.470825 
	C1679.360229,1932.405518 1679.718750,1930.826782 1680.240601,1928.446777 
	C1680.403809,1927.645752 1681.254761,1927.850586 1681.648804,1928.043701 
	C1682.231934,1931.432617 1680.956421,1935.388184 1686.804199,1936.078613 
	C1690.646240,1936.228882 1693.067505,1937.414062 1694.130859,1941.164429 
z"
        />
        <path
          fill="#6F7981"
          opacity="1.000000"
          stroke="none"
          d="
M1678.058105,1912.649414 
	C1679.716919,1911.983276 1681.346436,1912.112061 1683.780518,1912.430420 
	C1684.150024,1916.365356 1682.458374,1919.688843 1680.524658,1923.546875 
	C1678.087036,1920.701294 1677.803833,1917.119995 1678.058105,1912.649414 
z"
        />
        <path
          fill="#708497"
          opacity="1.000000"
          stroke="none"
          d="
M1678.774170,1933.973633 
	C1679.001587,1933.984375 1679.190430,1934.815063 1679.162109,1935.239990 
	C1678.665161,1935.606934 1678.196777,1935.548828 1677.830078,1934.790283 
	C1677.931763,1934.089600 1678.009888,1933.999023 1678.052002,1933.954834 
	C1678.093994,1933.910645 1678.546631,1933.963013 1678.774170,1933.973633 
z"
        />
        <path
          fill="#F5F1E5"
          opacity="1.000000"
          stroke="none"
          d="
M1605.676514,1914.571777 
	C1598.331909,1914.140259 1591.203857,1916.987915 1583.151489,1915.625854 
	C1581.821167,1914.254639 1581.329834,1913.023804 1580.808105,1911.806152 
	C1579.169800,1907.982056 1580.671631,1906.257690 1584.577148,1905.840210 
	C1589.172607,1905.349121 1593.790405,1905.586670 1598.413940,1905.160889 
	C1603.589233,1904.684448 1604.902954,1909.601074 1605.676514,1914.571777 
z"
        />
        <path
          fill="#B6AFA3"
          opacity="1.000000"
          stroke="none"
          d="
M1642.751343,1851.955200 
	C1642.182495,1851.359009 1642.387817,1850.798218 1643.122925,1849.492188 
	C1647.088379,1847.345093 1650.523071,1847.709961 1655.220459,1848.363892 
	C1650.955811,1851.363281 1647.313965,1851.913208 1642.751343,1851.955200 
z"
        />
        <path
          fill="#6F7981"
          opacity="1.000000"
          stroke="none"
          d="
M1684.976685,1912.191895 
	C1684.707275,1911.834106 1684.870239,1911.209351 1685.438110,1910.289429 
	C1685.698364,1910.637939 1685.553711,1911.281372 1684.976685,1912.191895 
z"
        />
        <path
          fill="#E5DDC9"
          opacity="1.000000"
          stroke="none"
          d="
M1694.743896,1941.849121 
	C1692.077271,1940.291870 1690.184937,1938.722412 1687.909668,1936.643311 
	C1689.658813,1933.476685 1692.674683,1933.858032 1695.469604,1934.092529 
	C1698.825073,1934.374390 1701.743164,1933.435791 1705.309692,1931.490723 
	C1707.505371,1931.410522 1708.959961,1931.689575 1711.140137,1932.088379 
	C1711.860718,1933.637451 1711.855469,1935.066895 1711.696289,1937.200195 
	C1705.884155,1937.923950 1701.071411,1941.388306 1694.743896,1941.849121 
z"
        />
        <path
          fill="#B6AFA3"
          opacity="1.000000"
          stroke="none"
          d="
M1296.066162,1845.122314 
	C1288.239990,1846.648315 1280.832153,1843.186035 1272.956299,1843.691772 
	C1270.283203,1843.863403 1270.700684,1841.199463 1271.632568,1838.565186 
	C1270.288574,1835.950928 1269.918579,1833.832275 1271.129395,1830.766602 
	C1271.529053,1830.019287 1272.252686,1829.938721 1272.617432,1829.884521 
	C1278.613525,1827.638306 1284.519409,1827.874268 1290.366699,1828.092407 
	C1296.842773,1828.333862 1298.453735,1830.402710 1297.518799,1836.674316 
	C1297.136963,1839.234985 1296.554932,1841.765869 1296.066162,1845.122314 
z"
        />
        <path
          fill="#B6AFA3"
          opacity="1.000000"
          stroke="none"
          d="
M1272.570557,1814.444214 
	C1275.769653,1813.757935 1279.098755,1813.803223 1283.268555,1813.735840 
	C1287.102783,1813.690796 1290.097900,1813.718872 1293.089355,1813.837402 
	C1295.672852,1813.939697 1298.748413,1813.369873 1299.335815,1817.114990 
	C1299.891357,1820.657104 1297.020874,1823.865967 1292.746948,1824.046875 
	C1287.099609,1824.286011 1281.433350,1824.077271 1274.848877,1823.991699 
	C1273.824341,1820.963745 1273.922363,1817.977539 1272.570557,1814.444214 
z"
        />
        <path
          fill="#B6BCC1"
          opacity="1.000000"
          stroke="none"
          d="
M1210.474731,1844.534912 
	C1207.593506,1844.658936 1205.721680,1844.240112 1205.855957,1840.735962 
	C1207.838501,1839.991455 1209.765137,1839.908203 1211.682007,1840.009644 
	C1213.257080,1840.093018 1215.295288,1840.260010 1215.055542,1842.371582 
	C1214.835449,1844.309814 1212.709595,1844.045654 1210.474731,1844.534912 
z"
        />
        <path
          fill="#626E74"
          opacity="1.000000"
          stroke="none"
          d="
M1270.911865,1830.206787 
	C1271.666626,1832.115234 1271.804077,1834.211182 1271.874268,1837.091064 
	C1267.403320,1835.683960 1267.160645,1834.483154 1270.911865,1830.206787 
z"
        />
        <path
          fill="#18202C"
          opacity="1.000000"
          stroke="none"
          d="
M889.946777,3435.655518 
	C888.301086,3434.976318 888.076111,3433.194580 887.654602,3430.774170 
	C887.947937,3428.083984 888.640564,3426.191650 891.202637,3424.916504 
	C894.743958,3424.383057 897.798523,3424.252686 901.060303,3422.068359 
	C902.914917,3423.719971 904.548096,3426.404541 906.818604,3425.525635 
	C912.814636,3423.204834 918.796021,3426.198486 924.965637,3424.527832 
	C931.109619,3422.863525 937.609314,3427.154297 945.094238,3426.033691 
	C949.026062,3428.264893 950.604187,3431.314453 948.149597,3434.478516 
	C944.901489,3438.665039 940.105957,3438.066162 934.743103,3436.527344 
	C933.107117,3436.004150 932.220093,3435.999756 930.667847,3435.989746 
	C919.179321,3434.958740 908.258789,3437.404297 896.720276,3434.560059 
	C895.082581,3434.041016 894.196716,3434.033447 892.646484,3434.017822 
	C891.666443,3433.751221 891.350769,3433.492920 890.579346,3433.643066 
	C890.093079,3434.408936 890.062561,3434.766357 889.946777,3435.655518 
z"
        />
        <path
          fill="#0C1A26"
          opacity="1.000000"
          stroke="none"
          d="
M934.138916,3436.689941 
	C936.320618,3435.993896 938.663452,3436.151123 940.970520,3435.931885 
	C947.042358,3435.354980 947.315002,3434.833008 946.065979,3426.753418 
	C952.767029,3421.755127 959.299683,3426.476074 965.939636,3426.845459 
	C968.069519,3426.963867 968.225037,3430.334717 966.633057,3431.651123 
	C961.476562,3435.915771 954.708130,3437.465332 948.635925,3438.918457 
	C944.661072,3439.869385 938.452942,3442.638672 934.138916,3436.689941 
z"
        />
        <path
          fill="#0C1A26"
          opacity="1.000000"
          stroke="none"
          d="
M896.191162,3434.815918 
	C906.899292,3434.465088 917.878418,3432.946533 929.387268,3435.424805 
	C919.627319,3439.251709 909.023926,3438.002686 898.471558,3437.468994 
	C897.755798,3437.433105 897.098511,3436.238770 896.191162,3434.815918 
z"
        />
        <path
          fill="#0C1A26"
          opacity="1.000000"
          stroke="none"
          d="
M890.588135,3434.036621 
	C890.422241,3433.366455 890.749695,3432.748291 891.804321,3433.706055 
	C891.982117,3434.009766 891.052673,3434.022217 890.588135,3434.036621 
z"
        />
        <path
          fill="#F3AB7B"
          opacity="1.000000"
          stroke="none"
          d="
M798.378601,3332.721191 
	C798.063660,3330.489502 798.047729,3328.999023 797.990234,3326.763672 
	C797.214905,3325.210205 796.948181,3324.269287 797.663330,3322.625488 
	C798.598145,3321.545654 799.140137,3321.095215 799.416199,3320.517334 
	C800.632385,3317.973877 800.764709,3313.796143 804.954712,3314.989502 
	C808.360107,3315.959473 810.358887,3319.072754 809.801208,3322.832275 
	C808.602356,3330.912842 810.978577,3336.921143 818.928833,3340.061768 
	C820.219482,3340.572021 821.627747,3341.318604 821.497131,3342.751465 
	C820.576538,3352.852539 826.721497,3360.285645 830.852661,3368.534180 
	C833.585876,3373.992188 833.014954,3380.478760 833.156982,3386.096436 
	C833.424438,3396.681396 837.940430,3408.294922 829.315857,3417.990234 
	C827.539490,3419.987793 828.432800,3422.309570 829.107239,3424.615723 
	C829.934631,3427.445068 828.230469,3428.939941 825.050964,3429.827637 
	C816.061584,3408.995850 809.183411,3387.595459 803.868164,3364.895020 
	C801.992188,3353.926025 800.334900,3343.694092 798.378601,3332.721191 
z"
        />
        <path
          fill="#C2825D"
          opacity="1.000000"
          stroke="none"
          d="
M797.632629,3321.690186 
	C798.146301,3322.936523 798.114197,3323.818359 798.015381,3325.359375 
	C791.883423,3325.296387 786.091492,3326.861572 780.325806,3328.307129 
	C774.403625,3329.791748 769.783081,3333.274902 769.287903,3339.637451 
	C768.671875,3347.553223 766.583008,3355.534668 771.867004,3363.250732 
	C775.595459,3368.695068 774.630737,3375.944580 775.975342,3383.175293 
	C773.955322,3385.728271 771.839172,3385.042480 770.944580,3383.171631 
	C767.957825,3376.926025 762.487366,3371.893799 761.747437,3364.557861 
	C761.266785,3359.791748 759.387085,3356.292725 754.088440,3355.594727 
	C750.229797,3355.086182 749.044983,3351.585449 746.602051,3348.476074 
	C746.143860,3347.950439 746.092468,3346.957275 746.056274,3346.461426 
	C742.132080,3337.899170 742.062134,3329.640381 745.402893,3320.649902 
	C750.954834,3319.906006 754.351746,3318.895752 753.362854,3312.418945 
	C752.502319,3306.782715 761.492310,3300.554688 766.977844,3302.294434 
	C769.320679,3303.037354 769.915405,3304.818604 769.956055,3306.905762 
	C770.053894,3311.937988 772.979797,3313.157471 777.520508,3314.235596 
	C784.225830,3315.827393 790.582031,3318.889404 797.632629,3321.690186 
z"
        />
        <path
          fill="#CC855B"
          opacity="1.000000"
          stroke="none"
          d="
M745.516479,3319.562988 
	C746.125305,3328.148682 744.263611,3336.237793 746.016113,3345.147461 
	C740.044617,3344.277100 740.894531,3338.836914 741.082703,3334.909180 
	C741.244141,3331.540527 739.914307,3328.631836 739.978394,3324.713867 
	C737.818604,3322.250732 736.678772,3319.952148 735.921021,3316.716797 
	C735.841675,3313.931885 735.887512,3311.785400 735.932007,3308.833252 
	C734.682007,3299.411133 734.344238,3291.055908 743.252197,3285.557617 
	C744.008606,3285.980469 744.001099,3286.000000 744.011230,3285.995117 
	C743.301025,3292.569824 745.122620,3299.038086 745.455994,3305.559326 
	C745.692017,3310.176025 743.085449,3314.583252 745.516479,3319.562988 
z"
        />
        <path
          fill="#BA815C"
          opacity="1.000000"
          stroke="none"
          d="
M717.996948,3105.995117 
	C717.831238,3106.058838 717.668518,3106.127441 717.756836,3106.103027 
	C718.007812,3106.009766 718.000000,3106.000000 717.996948,3105.995117 
z"
        />
        <path
          fill="#BA815C"
          opacity="1.000000"
          stroke="none"
          d="
M666.102234,3084.251465 
	C666.223267,3084.257812 666.446533,3084.515381 666.669678,3084.773193 
	C666.514648,3084.683350 666.359558,3084.593262 666.102234,3084.251465 
z"
        />
        <path
          fill="#BA815C"
          opacity="1.000000"
          stroke="none"
          d="
M834.927368,3417.036377 
	C834.879333,3416.948975 834.975403,3417.123779 834.927368,3417.036377 
z"
        />
        <path
          fill="#281C18"
          opacity="1.000000"
          stroke="none"
          d="
M831.965271,3466.018555 
	C832.189331,3466.138428 831.914429,3464.949951 832.223267,3465.937500 
	C831.967712,3466.064209 831.995972,3466.003418 831.965271,3466.018555 
z"
        />
        <path
          fill="#D89066"
          opacity="1.000000"
          stroke="none"
          d="
M690.096680,3438.708740 
	C694.366699,3435.809326 695.015259,3431.937012 696.305908,3427.572754 
	C698.557129,3419.960938 698.248474,3411.963379 701.680054,3404.320068 
	C703.725891,3399.763672 707.858826,3393.974609 705.507080,3387.960449 
	C703.265686,3382.228516 705.711731,3374.958740 699.733765,3370.505127 
	C696.579956,3356.578125 688.792969,3343.574463 691.804260,3328.404785 
	C692.810181,3323.337646 690.832092,3317.624512 695.657532,3312.687988 
	C704.099487,3308.609619 708.685730,3313.666748 713.085938,3319.097656 
	C714.953491,3321.402832 714.975525,3324.645996 717.447693,3327.492188 
	C717.991089,3330.815186 718.028442,3333.565674 718.127075,3337.137939 
	C718.647217,3339.568359 719.106079,3341.176514 719.785645,3343.382324 
	C718.873657,3344.590820 718.234863,3345.545166 717.986694,3347.410645 
	C714.521484,3352.636963 717.013306,3359.205078 712.672241,3363.667236 
	C711.588867,3364.780518 712.638916,3366.900635 714.186951,3367.909668 
	C718.224854,3370.540771 717.180298,3374.521729 717.771667,3378.459961 
	C719.519592,3390.100830 716.299927,3400.915527 714.349976,3412.077637 
	C713.232910,3418.471924 715.329651,3425.132324 718.147705,3431.253418 
	C720.950500,3437.341553 721.010254,3437.832520 714.276917,3440.203857 
	C705.011841,3443.466553 705.831055,3450.841553 705.910034,3459.005371 
	C698.117004,3459.594238 696.139404,3452.958252 693.016602,3447.827637 
	C691.518433,3445.366211 691.080444,3442.259766 690.096680,3438.708740 
z"
        />
        <path
          fill="#D99469"
          opacity="1.000000"
          stroke="none"
          d="
M668.003296,3325.185059 
	C668.044312,3330.069580 668.061951,3334.148438 668.106323,3339.100586 
	C668.328979,3340.382812 668.524780,3340.791748 668.279419,3341.718262 
	C667.838257,3342.235840 666.900146,3342.165771 666.435059,3342.093994 
	C662.592712,3337.537109 660.119507,3332.577881 658.420471,3324.979980 
	C654.764709,3333.406250 656.232300,3339.513428 657.643677,3345.907471 
	C661.680542,3364.196777 667.892029,3382.166748 666.088501,3401.395508 
	C665.597839,3406.626465 666.515198,3411.951416 662.587463,3416.459473 
	C661.397217,3417.825439 661.342590,3420.166016 660.720459,3422.044678 
	C657.470276,3431.859375 657.465149,3431.857666 646.789734,3431.853271 
	C645.760620,3426.572266 645.485901,3421.464600 653.068787,3421.535645 
	C654.900757,3421.552734 655.684387,3419.708984 655.858276,3417.937256 
	C656.758850,3408.766113 658.450623,3399.814453 657.537048,3390.321045 
	C656.669861,3381.309814 655.528381,3372.890137 650.945496,3364.984131 
	C649.883057,3363.151611 650.013062,3360.466309 650.062378,3358.183594 
	C650.173218,3353.049561 650.546082,3348.355957 643.435181,3347.137451 
	C640.005005,3346.549561 637.997009,3342.333496 638.538879,3339.255371 
	C639.290588,3334.986084 638.359497,3331.772949 636.595398,3328.051270 
	C635.093689,3324.883545 636.720215,3322.163818 640.779480,3322.087402 
	C645.334839,3322.001465 649.695251,3321.748291 649.994629,3314.841797 
	C650.897827,3314.023438 651.778992,3313.988037 653.321289,3313.927490 
	C662.423157,3312.377930 665.346436,3314.565918 668.003296,3325.185059 
z"
        />
        <path
          fill="#ECA777"
          opacity="1.000000"
          stroke="none"
          d="
M668.045349,3342.625488 
	C667.889954,3341.859131 667.941589,3341.482666 668.063171,3340.540039 
	C670.039856,3340.441406 672.255188,3340.706543 669.999634,3343.910156 
	C668.981995,3343.935791 668.566406,3343.526855 668.045349,3342.625488 
z"
        />
        <path
          fill="#CC855B"
          opacity="1.000000"
          stroke="none"
          d="
M746.186646,3348.675293 
	C750.519775,3348.990234 752.500977,3354.555420 758.959351,3352.889648 
	C762.591370,3351.952881 765.499939,3359.177979 764.871338,3362.520752 
	C763.955200,3367.392822 765.655212,3370.326172 768.552856,3372.831543 
	C772.022827,3375.831543 770.500977,3381.271484 775.274292,3383.853516 
	C775.992554,3385.503418 776.017212,3386.999023 776.037964,3389.242432 
	C775.761841,3392.885498 774.727844,3395.042725 770.651489,3394.444580 
	C767.174255,3388.916260 762.130066,3385.070557 761.583252,3378.666016 
	C761.421814,3376.775146 759.503845,3376.288818 757.972229,3375.667969 
	C756.364014,3375.016113 754.528381,3373.856934 754.656311,3372.296387 
	C755.394714,3363.291016 749.089722,3356.997314 746.186646,3348.675293 
z"
        />
        <path
          fill="#CC855B"
          opacity="1.000000"
          stroke="none"
          d="
M782.500366,3451.255859 
	C780.661316,3454.361816 781.857300,3456.123047 782.764587,3458.350586 
	C785.757385,3465.699463 783.893921,3468.825684 774.729248,3471.982910 
	C771.412476,3469.445068 774.683472,3462.821045 767.830444,3463.214111 
	C767.558411,3463.230225 766.826965,3461.885254 766.979370,3461.685303 
	C771.174438,3456.187012 766.178711,3448.480713 771.715027,3442.633789 
	C780.841125,3439.639160 781.268677,3439.969482 782.500366,3451.255859 
z"
        />
        <path
          fill="#C2825D"
          opacity="1.000000"
          stroke="none"
          d="
M782.718018,3452.017578 
	C780.716125,3443.402588 780.716125,3443.402588 772.777344,3441.994629 
	C772.519958,3441.001953 773.032715,3440.013672 773.776367,3438.517578 
	C775.190979,3435.092773 774.728943,3432.447021 773.458923,3429.537598 
	C771.541565,3425.145264 772.670410,3421.195068 777.365967,3418.143066 
	C778.936035,3418.340088 780.593933,3418.609131 780.648804,3419.069092 
	C781.503906,3426.239502 788.466064,3432.246094 784.420593,3440.444092 
	C783.098816,3443.122070 784.504639,3445.982178 786.304504,3448.677979 
	C788.171204,3451.473877 785.244080,3451.595947 782.718018,3452.017578 
z"
        />
        <path
          fill="#C2825D"
          opacity="1.000000"
          stroke="none"
          d="
M770.002319,3394.870850 
	C772.413208,3394.037354 773.698547,3392.359375 775.520630,3390.353516 
	C777.358398,3391.121826 778.444824,3392.765625 777.783569,3394.260254 
	C774.781067,3401.044189 777.155396,3407.661621 778.002625,3415.163086 
	C776.631287,3417.198486 775.070312,3417.535156 772.648193,3416.493652 
	C772.903931,3409.082275 769.095215,3402.636963 770.002319,3394.870850 
z"
        />
        <path
          fill="#CC855B"
          opacity="1.000000"
          stroke="none"
          d="
M771.438721,3416.568115 
	C773.482666,3415.989990 774.990234,3415.990234 777.251343,3415.990479 
	C778.004883,3415.990234 778.017761,3416.986328 778.020813,3417.484375 
	C775.856567,3422.253418 772.964172,3426.245850 777.167603,3431.226318 
	C778.794128,3433.154053 778.556580,3436.248047 774.695984,3437.860596 
	C769.448608,3431.579834 769.089355,3424.524658 771.438721,3416.568115 
z"
        />
        <path
          fill="#ECA777"
          opacity="1.000000"
          stroke="none"
          d="
M735.261230,3316.368164 
	C740.080322,3316.327881 739.918030,3319.463379 739.944824,3323.222656 
	C738.973328,3324.501953 738.004517,3325.033447 736.530029,3325.789062 
	C734.957336,3322.982666 729.676819,3320.536865 735.261230,3316.368164 
z"
        />
        <path
          fill="#805541"
          opacity="1.000000"
          stroke="none"
          d="
M872.099915,3436.741211 
	C875.583435,3436.273193 876.116150,3439.094727 876.743958,3441.610840 
	C879.135498,3451.196533 881.406677,3460.812500 883.681030,3470.427002 
	C883.906372,3471.379639 883.922119,3472.400879 883.906616,3473.388184 
	C883.769592,3482.106201 883.760986,3482.105957 887.253601,3488.529541 
	C891.547302,3496.426758 890.818970,3498.261719 882.344666,3500.900635 
	C881.398438,3501.195312 880.455078,3501.499023 878.741699,3501.849609 
	C878.431396,3496.296631 881.872803,3490.225830 875.304626,3485.704346 
	C874.757019,3485.327393 874.571106,3483.752686 874.847839,3482.952148 
	C879.043640,3470.805908 875.673523,3459.114014 872.730042,3447.430664 
	C871.902832,3444.146973 872.042847,3440.900879 872.099915,3436.741211 
z"
        />
        <path
          fill="#805541"
          opacity="1.000000"
          stroke="none"
          d="
M855.072876,3427.086426 
	C854.972778,3427.142578 855.172913,3427.030029 855.072876,3427.086426 
z"
        />
        <path
          fill="#2C2520"
          opacity="1.000000"
          stroke="none"
          d="
M2394.515625,3886.781250 
	C2396.704102,3886.956055 2399.134033,3888.078369 2399.651367,3891.382568 
	C2400.457031,3896.529785 2400.393066,3901.536377 2398.020508,3908.383301 
	C2393.218506,3900.557617 2392.856934,3894.227539 2394.515625,3886.781250 
z"
        />
        <path
          fill="#2C2520"
          opacity="1.000000"
          stroke="none"
          d="
M2389.602783,3884.700684 
	C2390.967041,3883.978760 2391.874512,3883.968262 2393.460938,3884.012695 
	C2394.024658,3884.390869 2393.909912,3884.714111 2393.899414,3885.540283 
	C2394.004150,3886.043213 2394.093994,3885.934326 2394.151123,3885.889160 
	C2392.659180,3885.500000 2391.890137,3886.567627 2390.502930,3887.847656 
	C2388.712402,3887.492432 2388.452881,3886.588379 2389.602783,3884.700684 
z"
        />
        <path
          fill="#2C2520"
          opacity="1.000000"
          stroke="none"
          d="
M2388.527344,3890.320557 
	C2388.146240,3889.122314 2388.441162,3888.211914 2389.416260,3887.590820 
	C2389.798828,3888.789307 2389.501221,3889.698975 2388.527344,3890.320557 
z"
        />
        <path
          fill="#2E2D30"
          opacity="1.000000"
          stroke="none"
          d="
M2383.786133,3889.155273 
	C2383.641846,3890.055664 2383.000244,3890.281982 2382.850098,3890.587402 
	C2382.179199,3891.237549 2381.771729,3891.685547 2381.322266,3892.666992 
	C2381.167236,3893.097656 2380.354248,3893.315186 2380.174561,3893.695801 
	C2377.882324,3892.050781 2376.152344,3889.748047 2375.549316,3886.839111 
	C2373.373535,3876.339355 2367.774170,3866.676270 2367.924805,3854.763184 
	C2379.113037,3856.729004 2381.704102,3866.132080 2384.186768,3875.452637 
	C2385.304688,3879.649658 2384.753906,3883.999756 2383.786133,3889.155273 
z"
        />
        <path
          fill="#704A3A"
          opacity="1.000000"
          stroke="none"
          d="
M2427.800049,3735.063477 
	C2425.252197,3732.124512 2424.984375,3728.403564 2428.470703,3724.430664 
	C2428.790527,3727.500244 2428.406250,3730.873291 2427.800049,3735.063477 
z"
        />
        <path
          fill="#704A3A"
          opacity="1.000000"
          stroke="none"
          d="
M2427.861328,3716.442139 
	C2427.791260,3715.018066 2427.826904,3714.169434 2427.832031,3712.683594 
	C2428.257324,3712.056396 2428.713379,3712.066162 2429.852051,3712.126953 
	C2430.394043,3714.253418 2430.253662,3716.329346 2429.907715,3719.159180 
	C2429.123535,3718.948242 2428.545166,3717.982666 2427.861328,3716.442139 
z"
        />
        <path
          fill="#B0714F"
          opacity="1.000000"
          stroke="none"
          d="
M2350.488281,3834.027344 
	C2348.309570,3832.323242 2348.555908,3830.316162 2350.658203,3830.852051 
	C2355.543945,3832.097656 2358.341309,3831.520264 2357.996582,3825.740479 
	C2357.748291,3821.576416 2359.979492,3820.110107 2363.905273,3819.845215 
	C2366.770508,3819.652100 2369.583252,3818.674316 2373.240967,3818.050781 
	C2374.042969,3819.550537 2374.023438,3821.045410 2374.003418,3823.289795 
	C2373.116211,3824.033691 2372.229248,3824.028320 2370.677979,3824.040527 
	C2365.269775,3822.580322 2361.872070,3822.999512 2361.922119,3829.239990 
	C2361.936523,3831.061035 2361.060303,3832.889160 2360.296143,3835.396484 
	C2356.891113,3836.281982 2354.130371,3834.920410 2350.488281,3834.027344 
z"
        />
        <path
          fill="#5A605E"
          opacity="1.000000"
          stroke="none"
          d="
M1046.017334,1835.942139 
	C1043.017456,1835.670898 1040.144409,1835.784302 1040.846069,1832.408325 
	C1041.466919,1829.420776 1044.358398,1830.131226 1046.501953,1830.087402 
	C1048.387939,1830.048828 1050.775024,1829.921387 1051.204956,1832.392578 
	C1051.795776,1835.789795 1049.024292,1835.674194 1046.017334,1835.942139 
z"
        />
        <path
          fill="#5A605E"
          opacity="1.000000"
          stroke="none"
          d="
M1016.558838,1849.540649 
	C1013.830933,1847.361328 1014.991089,1845.977417 1017.026123,1845.017578 
	C1017.944092,1844.584473 1019.068848,1845.049927 1019.381470,1846.063110 
	C1019.965088,1847.954468 1019.117493,1849.184204 1016.558838,1849.540649 
z"
        />
        <path
          fill="#3D494C"
          opacity="1.000000"
          stroke="none"
          d="
M970.093506,1830.549927 
	C970.886597,1829.999634 971.763367,1830.023682 973.294556,1830.106079 
	C973.751160,1831.423950 973.612549,1832.843140 971.996094,1832.971436 
	C970.901794,1833.058105 970.437134,1832.089600 970.093506,1830.549927 
z"
        />
        <path
          fill="#3D494C"
          opacity="1.000000"
          stroke="none"
          d="
M589.403198,1852.129883 
	C589.802856,1853.859863 589.202026,1855.375732 586.498840,1855.801270 
	C586.174255,1854.110596 586.785583,1852.606079 589.403198,1852.129883 
z"
        />
        <path
          fill="#687073"
          opacity="1.000000"
          stroke="none"
          d="
M442.811401,1796.577759 
	C443.362305,1796.495972 443.790466,1796.844238 443.733124,1796.968872 
	C443.307220,1797.892578 442.841797,1797.851562 442.811401,1796.577759 
z"
        />
        <path
          fill="#040407"
          opacity="1.000000"
          stroke="none"
          d="
M1388.431152,2341.395508 
	C1387.971802,2342.913818 1387.965820,2343.798096 1387.958130,2345.346191 
	C1387.950562,2346.799072 1387.908691,2347.586670 1387.626709,2348.904785 
	C1387.236694,2349.744385 1387.039795,2350.051025 1386.506104,2350.743652 
	C1385.302002,2352.161133 1385.990356,2352.948486 1386.818237,2354.302734 
	C1387.265015,2356.164307 1386.917236,2357.339600 1385.741089,2358.916748 
	C1380.901855,2362.455078 1382.842896,2366.573486 1383.541138,2371.153320 
	C1383.717651,2372.790283 1383.639404,2373.722656 1383.150024,2375.279785 
	C1382.552002,2376.241455 1382.235352,2376.567627 1381.319946,2377.197754 
	C1378.666260,2378.171387 1376.513428,2377.727783 1373.935059,2378.370117 
	C1371.577148,2381.525635 1373.922363,2383.412109 1375.250244,2386.245361 
	C1375.778442,2388.344482 1375.627808,2389.722168 1374.396973,2391.507812 
	C1367.523804,2393.068115 1367.523804,2393.068115 1363.788330,2387.219482 
	C1360.406738,2389.143555 1364.188843,2392.101807 1362.683594,2394.415283 
	C1360.108032,2395.660156 1358.515259,2392.477051 1355.544678,2392.927490 
	C1350.364380,2393.713135 1345.161621,2389.632080 1344.292847,2384.453125 
	C1343.667969,2380.728760 1341.635620,2379.318115 1339.116455,2376.993896 
	C1333.619995,2371.923096 1326.680542,2367.285645 1329.342896,2357.240967 
	C1330.391846,2353.282471 1327.063354,2348.269043 1332.477417,2345.105957 
	C1333.791382,2344.338379 1332.810303,2342.769531 1332.389893,2341.502197 
	C1328.698242,2330.371826 1328.796631,2329.695557 1335.132446,2321.127686 
	C1330.396606,2320.408936 1327.782104,2317.190430 1325.667969,2313.537842 
	C1321.164307,2305.756104 1323.919067,2300.639893 1332.621216,2300.436523 
	C1332.823975,2299.301758 1332.256714,2298.531738 1331.253662,2298.308350 
	C1320.518555,2295.917725 1320.449219,2295.738037 1327.647827,2285.258301 
	C1327.652100,2290.497070 1328.923950,2294.277344 1334.140503,2295.166504 
	C1336.318481,2295.537842 1338.368286,2298.339600 1340.514526,2296.716309 
	C1343.400635,2294.533447 1340.060181,2292.851318 1339.328735,2290.904785 
	C1338.141235,2287.745605 1340.552490,2286.969482 1342.858032,2286.197021 
	C1350.663574,2283.581787 1351.087524,2283.378418 1349.295410,2275.314209 
	C1348.315063,2270.902588 1348.092041,2268.086670 1353.574341,2267.931885 
	C1356.394775,2267.852051 1357.618652,2266.161621 1358.117920,2263.556885 
	C1358.791748,2260.041992 1361.612671,2260.063965 1365.123535,2260.046631 
	C1365.948853,2262.386230 1366.144043,2264.678223 1367.728516,2267.348145 
	C1368.848145,2268.860840 1370.005127,2268.993408 1371.521973,2269.669434 
	C1368.467529,2278.267578 1370.703247,2287.281494 1368.466919,2295.698730 
	C1367.836304,2298.072266 1368.624756,2300.194580 1371.166992,2302.291016 
	C1373.021606,2298.152344 1370.724854,2292.911621 1374.919800,2289.813721 
	C1376.270996,2290.976807 1377.724365,2292.228027 1379.758057,2293.721680 
	C1381.565430,2294.881836 1382.096069,2296.206055 1382.593994,2298.316406 
	C1382.927246,2304.325684 1383.682983,2309.522949 1386.532593,2314.799316 
	C1389.199585,2317.170410 1390.185181,2319.628906 1390.195801,2323.168457 
	C1389.959717,2325.620361 1389.304932,2327.239746 1388.186157,2329.378906 
	C1387.976074,2332.113525 1387.966675,2334.233154 1387.959595,2337.147949 
	C1388.750732,2338.724854 1389.058350,2339.664551 1388.431152,2341.395508 
z"
        />
        <path
          fill="#2C2520"
          opacity="1.000000"
          stroke="none"
          d="
M1371.678955,2376.697510 
	C1374.181641,2372.958740 1376.424316,2375.698730 1379.277466,2375.992188 
	C1380.247192,2376.301758 1380.582153,2376.554932 1381.401611,2376.405762 
	C1381.895630,2375.099365 1381.905029,2374.195068 1381.908203,2372.612793 
	C1386.436279,2370.608643 1390.745605,2372.921875 1395.342529,2372.736084 
	C1400.593384,2372.523926 1402.337524,2376.844238 1401.342163,2381.817627 
	C1400.839478,2384.329834 1398.675781,2386.841797 1400.211426,2389.345459 
	C1405.895386,2398.613037 1403.172119,2406.531738 1396.525879,2414.680176 
	C1393.387939,2417.388184 1390.492310,2419.435059 1387.035889,2421.673828 
	C1385.029053,2423.088379 1383.385254,2423.406250 1380.880371,2422.397461 
	C1380.151123,2418.536621 1379.302979,2414.967285 1380.229004,2411.940186 
	C1382.586548,2404.235352 1379.495728,2398.424805 1374.448608,2392.397705 
	C1373.892700,2390.505371 1373.906372,2389.015381 1373.896729,2386.780762 
	C1371.824951,2383.507568 1371.665771,2380.430420 1371.678955,2376.697510 
z"
        />
        <path
          fill="#1D1712"
          opacity="1.000000"
          stroke="none"
          d="
M1379.861084,2422.528809 
	C1381.757690,2421.892334 1383.324219,2421.925293 1385.682861,2421.911621 
	C1382.751221,2427.911377 1379.009399,2433.948730 1376.849365,2441.519531 
	C1376.041504,2442.062500 1375.619507,2442.024902 1374.562012,2441.958740 
	C1374.586304,2435.324707 1377.447632,2429.378174 1379.861084,2422.528809 
z"
        />
        <path
          fill="#46565D"
          opacity="1.000000"
          stroke="none"
          d="
M1320.774658,2362.440430 
	C1319.316772,2361.724121 1318.840820,2361.352783 1318.243164,2360.457520 
	C1318.651733,2358.865234 1319.279053,2357.672363 1320.568848,2356.252930 
	C1322.372925,2357.102051 1322.523438,2358.534180 1322.513916,2360.507324 
	C1322.139038,2361.662842 1321.899536,2362.286377 1320.774658,2362.440430 
z"
        />
        <path
          fill="#698196"
          opacity="1.000000"
          stroke="none"
          d="
M1319.986816,2362.511230 
	C1320.316284,2361.847900 1320.742798,2361.724609 1321.774170,2361.320312 
	C1322.782837,2361.755127 1323.186768,2362.470703 1323.680664,2363.799805 
	C1321.753296,2366.093750 1320.694824,2365.178711 1319.986816,2362.511230 
z"
        />
        <path
          fill="#70889D"
          opacity="1.000000"
          stroke="none"
          d="
M1153.067139,2261.944336 
	C1157.848389,2262.777344 1162.539551,2260.034424 1165.811646,2265.252441 
	C1159.496460,2266.938232 1152.902710,2267.461182 1146.543335,2268.970947 
	C1141.591919,2270.146484 1136.712158,2269.767334 1130.899536,2269.994141 
	C1128.723755,2268.244385 1126.460449,2268.101807 1124.445923,2266.419678 
	C1123.074463,2265.356689 1122.134155,2264.723145 1120.606689,2264.030273 
	C1119.252319,2263.214844 1118.954468,2262.305176 1119.569092,2260.630859 
	C1130.849365,2258.782715 1141.406616,2262.198486 1153.067139,2261.944336 
z"
        />
        <path
          fill="#698196"
          opacity="1.000000"
          stroke="none"
          d="
M1119.152344,2260.011963 
	C1120.010498,2260.897705 1120.008911,2261.776123 1120.013428,2263.312500 
	C1115.257202,2263.964600 1110.494995,2263.958496 1104.838257,2263.918945 
	C1104.200439,2262.150391 1104.824219,2260.691895 1107.341797,2260.096191 
	C1111.404785,2260.001465 1114.848511,2260.002930 1119.152344,2260.011963 
z"
        />
        <path
          fill="#698196"
          opacity="1.000000"
          stroke="none"
          d="
M1124.137329,2266.668701 
	C1126.276245,2266.115234 1128.759766,2265.760498 1129.875244,2269.316406 
	C1127.728882,2269.893799 1125.239990,2270.260498 1124.137329,2266.668701 
z"
        />
        <path
          fill="#698196"
          opacity="1.000000"
          stroke="none"
          d="
M1091.229492,2309.975098 
	C1095.524170,2310.381104 1098.738525,2312.529053 1103.247192,2311.979492 
	C1107.450684,2311.977051 1110.877075,2311.983643 1115.161377,2311.990234 
	C1115.871826,2315.075195 1115.798706,2318.393066 1111.240845,2317.379883 
	C1103.899780,2315.748047 1096.618286,2314.458008 1089.021729,2315.845215 
	C1085.884521,2316.417725 1083.760132,2315.005127 1085.742920,2310.609375 
	C1087.497559,2309.980225 1088.990356,2309.979980 1091.229492,2309.975098 
z"
        />
        <path
          fill="#698196"
          opacity="1.000000"
          stroke="none"
          d="
M1120.407715,2312.481934 
	C1122.966553,2308.854248 1126.862793,2310.401611 1131.194458,2309.993652 
	C1129.432983,2316.189453 1129.432983,2316.189453 1120.407715,2312.481934 
z"
        />
        <path
          fill="#698196"
          opacity="1.000000"
          stroke="none"
          d="
M1308.213379,2324.199219 
	C1308.528320,2324.161377 1309.044189,2324.597900 1309.762695,2325.502686 
	C1309.448364,2325.538574 1308.931396,2325.105957 1308.213379,2324.199219 
z"
        />
        <path
          fill="#4B6377"
          opacity="1.000000"
          stroke="none"
          d="
M1213.768188,2345.988770 
	C1207.583008,2345.889648 1202.332397,2345.859619 1197.089111,2345.649414 
	C1195.678345,2345.592773 1194.696167,2344.456299 1194.703613,2343.027832 
	C1194.710815,2341.614014 1195.611938,2340.651611 1197.088257,2340.379395 
	C1197.410156,2340.319824 1197.738647,2340.170898 1198.050781,2340.200928 
	C1207.545532,2341.118408 1217.038696,2342.053223 1226.535889,2343.836670 
	C1222.912842,2347.038818 1218.643311,2345.561035 1213.768188,2345.988770 
z"
        />
        <path
          fill="#597288"
          opacity="1.000000"
          stroke="none"
          d="
M1294.638184,2339.843506 
	C1292.880859,2339.866455 1291.673706,2339.799072 1290.317627,2339.723633 
	C1291.700684,2336.998047 1293.662476,2338.096680 1295.432373,2338.470215 
	C1296.734497,2338.745117 1297.083374,2339.694580 1295.864136,2339.158691 
	C1295.748291,2339.107666 1295.418701,2339.543457 1294.638184,2339.843506 
z"
        />
        <path
          fill="#50677C"
          opacity="1.000000"
          stroke="none"
          d="
M1333.504150,2429.977051 
	C1336.209717,2431.828613 1335.836304,2434.280273 1336.345581,2437.188721 
	C1330.686035,2435.416748 1324.724854,2435.281250 1318.880859,2436.782959 
	C1310.582764,2438.915283 1302.599365,2438.320312 1292.590088,2434.641846 
	C1307.254639,2433.142090 1319.696411,2430.003418 1333.504150,2429.977051 
z"
        />
        <path
          fill="#4A667C"
          opacity="1.000000"
          stroke="none"
          d="
M1330.381104,2416.479736 
	C1333.316650,2417.235840 1333.939209,2419.801270 1333.930420,2423.342773 
	C1329.723633,2423.343750 1329.926880,2420.307129 1330.381104,2416.479736 
z"
        />
        <path
          fill="#698196"
          opacity="1.000000"
          stroke="none"
          d="
M988.320190,2386.877441 
	C1006.858582,2386.642334 1025.388916,2382.593994 1045.059814,2383.995605 
	C1051.058472,2385.228516 1055.248169,2388.915527 1061.368774,2388.551514 
	C1055.342529,2394.099121 1053.278809,2394.870605 1047.461548,2390.954346 
	C1043.788330,2388.481689 1041.557617,2388.486816 1037.803711,2390.530273 
	C1032.050659,2393.662354 1025.222900,2392.716309 1019.260193,2391.678467 
	C1010.661499,2390.181885 1002.142334,2390.087402 993.544556,2389.954346 
	C991.532410,2389.923340 989.518555,2389.845459 988.320190,2386.877441 
z"
        />
        <path
          fill="#0C0D0F"
          opacity="1.000000"
          stroke="none"
          d="
M1349.290039,2426.984375 
	C1351.061523,2426.223145 1352.609863,2426.168213 1352.854248,2427.717773 
	C1353.189087,2429.841553 1351.296143,2430.572998 1349.711548,2430.373047 
	C1347.992920,2430.155762 1348.371826,2428.614746 1349.290039,2426.984375 
z"
        />
        <path
          fill="#5D758A"
          opacity="1.000000"
          stroke="none"
          d="
M681.653076,2341.586426 
	C681.068176,2342.638428 680.123291,2343.266846 678.589233,2343.947754 
	C678.389893,2342.232666 678.367310,2339.986816 681.653076,2341.586426 
z"
        />
        <path
          fill="#D89066"
          opacity="1.000000"
          stroke="none"
          d="
M2402.030029,3711.169434 
	C2399.824463,3712.616455 2397.609863,3713.233154 2394.707520,3713.934570 
	C2388.611572,3705.637695 2393.266113,3697.105957 2394.681396,3689.152588 
	C2398.529541,3667.527100 2395.814941,3662.097900 2375.200928,3657.317627 
	C2374.225586,3657.091309 2373.489746,3656.338867 2372.370850,3654.903809 
	C2370.770264,3648.293213 2369.710449,3642.404053 2374.057617,3636.452148 
	C2376.205811,3636.030518 2377.571533,3635.802734 2378.852539,3636.037598 
	C2381.965820,3636.607422 2385.024902,3633.101562 2388.231445,3636.257080 
	C2391.721191,3639.691650 2396.598877,3641.832031 2398.028076,3647.114990 
	C2399.043701,3650.869141 2400.877441,3653.426758 2405.134766,3654.402832 
	C2410.588867,3655.653076 2408.743652,3662.661377 2413.389648,3665.653809 
	C2412.203857,3670.248291 2410.397949,3674.496582 2408.310791,3679.376465 
	C2407.964355,3681.753906 2408.032227,3683.528564 2406.483398,3685.530762 
	C2405.911865,3687.952637 2406.563232,3690.763184 2405.529053,3691.686035 
	C2399.425537,3697.135254 2402.264404,3703.979248 2402.030029,3711.169434 
z"
        />
        <path
          fill="#B0714F"
          opacity="1.000000"
          stroke="none"
          d="
M2422.045166,3609.144043 
	C2421.138916,3613.574463 2424.373779,3619.019531 2416.812012,3619.962646 
	C2415.776367,3612.497070 2412.940430,3605.244385 2414.007324,3597.625000 
	C2414.274658,3595.715820 2413.929688,3593.394775 2416.338379,3592.768799 
	C2419.372314,3591.980957 2420.269043,3594.576172 2421.113281,3596.609619 
	C2422.669189,3600.356201 2421.764648,3604.379883 2422.045166,3609.144043 
z"
        />
        <path
          fill="#603A24"
          opacity="1.000000"
          stroke="none"
          d="
M2374.628906,3635.245605 
	C2374.700928,3642.138428 2374.522949,3648.228760 2374.583008,3655.202393 
	C2375.292480,3657.040527 2375.394043,3658.033203 2374.732666,3659.723145 
	C2373.436768,3660.942871 2372.460693,3661.149902 2370.726562,3660.652344 
	C2366.102051,3657.306152 2366.016357,3653.463867 2367.510254,3648.541504 
	C2368.135010,3640.129395 2370.583740,3632.880615 2373.802246,3625.877441 
	C2374.347900,3628.853760 2374.363770,3631.648438 2374.628906,3635.245605 
z"
        />
        <path
          fill="#B0714F"
          opacity="1.000000"
          stroke="none"
          d="
M2415.543213,3624.310059 
	C2416.630371,3625.518311 2417.260742,3627.114746 2417.969971,3629.350586 
	C2414.310547,3630.265137 2413.785889,3629.328125 2415.543213,3624.310059 
z"
        />
        <path
          fill="#E59F70"
          opacity="1.000000"
          stroke="none"
          d="
M2373.489258,3661.695801 
	C2373.990967,3659.864258 2374.146240,3658.767578 2374.561523,3656.878418 
	C2388.677734,3653.119141 2388.176270,3653.832031 2397.086426,3666.805908 
	C2401.604492,3673.385010 2401.175293,3678.350586 2398.772949,3684.736572 
	C2395.432861,3693.616455 2395.708252,3703.200439 2394.009277,3713.213867 
	C2394.005371,3716.791504 2393.991211,3719.563721 2393.973877,3723.167969 
	C2392.731689,3729.854980 2390.054443,3734.984131 2383.574463,3735.423340 
	C2376.944336,3735.872314 2375.947021,3729.226562 2372.535400,3724.549072 
	C2372.022461,3723.101562 2372.025391,3722.220703 2372.038330,3720.679688 
	C2375.382324,3713.589355 2372.955566,3707.174561 2371.784668,3699.995850 
	C2369.702393,3687.943848 2374.591064,3676.952393 2373.052246,3664.864502 
	C2373.075195,3663.512939 2373.108887,3662.972168 2373.489258,3661.695801 
z"
        />
        <path
          fill="#D89066"
          opacity="1.000000"
          stroke="none"
          d="
M2372.001953,3724.902344 
	C2376.047363,3724.072021 2377.316406,3727.206299 2379.124023,3730.036621 
	C2382.091553,3734.683350 2384.675049,3734.626465 2388.287598,3730.580322 
	C2390.021240,3728.637939 2389.504639,3725.213867 2393.289062,3724.132080 
	C2396.180420,3723.387939 2398.389893,3722.775635 2401.285156,3722.086426 
	C2401.970215,3724.771240 2401.969482,3727.532471 2401.966309,3731.122559 
	C2399.547363,3735.081299 2395.631104,3736.314697 2392.497070,3739.560547 
	C2392.031494,3743.264404 2391.341064,3745.863525 2387.283936,3745.982178 
	C2384.701172,3746.057861 2382.115479,3745.845703 2379.543457,3746.802002 
	C2373.088379,3749.202881 2372.374268,3748.829346 2372.065674,3742.511475 
	C2371.794678,3736.960205 2371.993164,3731.386475 2372.001953,3724.902344 
z"
        />
        <path
          fill="#F2EBDA"
          opacity="1.000000"
          stroke="none"
          d="
M2373.255371,3660.611328 
	C2373.835449,3660.960693 2373.827881,3660.932373 2373.847656,3660.950195 
	C2374.693604,3678.530518 2372.207275,3695.763184 2367.908447,3713.364258 
	C2367.122803,3713.834961 2366.691406,3713.515137 2366.464844,3713.659668 
	C2357.211670,3712.737549 2358.219238,3705.386475 2357.785156,3698.791016 
	C2362.061768,3690.706055 2362.885986,3682.403564 2364.019287,3674.316895 
	C2364.735352,3669.206299 2366.726807,3665.341064 2370.044434,3661.050781 
	C2370.920898,3660.290039 2371.798340,3660.276123 2373.255371,3660.611328 
z"
        />
        <path
          fill="#50494B"
          opacity="1.000000"
          stroke="none"
          d="
M2357.784912,3698.708252 
	C2358.463379,3704.170654 2361.819092,3708.575684 2365.692871,3713.398438 
	C2366.211670,3719.940674 2366.185059,3726.077637 2366.233398,3733.129395 
	C2365.924805,3734.043945 2365.541016,3734.043457 2364.585693,3734.098877 
	C2359.990234,3732.279541 2356.559082,3729.584717 2352.790039,3726.345703 
	C2352.131348,3724.688232 2352.100098,3723.174561 2352.082031,3720.907227 
	C2352.135010,3719.806641 2352.174805,3719.459961 2352.297363,3718.595947 
	C2353.658447,3716.511230 2354.433105,3714.666016 2355.586670,3712.347656 
	C2356.504150,3707.633789 2355.195557,3703.234131 2357.784912,3698.708252 
z"
        />
        <path
          fill="#B0714F"
          opacity="1.000000"
          stroke="none"
          d="
M2367.896973,3713.856934 
	C2367.854492,3696.637939 2371.474609,3679.791260 2373.594238,3661.848633 
	C2377.945557,3667.487305 2376.109375,3674.667725 2375.304443,3681.369873 
	C2374.630859,3686.981201 2373.965088,3692.543457 2373.917969,3699.085449 
	C2373.731445,3704.260498 2374.762207,3708.625488 2372.380127,3713.312988 
	C2370.729736,3713.850098 2369.700195,3713.864502 2367.896973,3713.856934 
z"
        />
        <path
          fill="#D89066"
          opacity="1.000000"
          stroke="none"
          d="
M2371.835938,3714.607910 
	C2372.326172,3709.618164 2371.258301,3705.256836 2373.397949,3700.557129 
	C2377.132324,3706.494873 2377.452393,3712.922852 2372.704590,3719.620850 
	C2372.003174,3718.472656 2371.958008,3716.926270 2371.835938,3714.607910 
z"
        />
        <path
          fill="#CC855B"
          opacity="1.000000"
          stroke="none"
          d="
M2392.874756,3740.023682 
	C2391.598389,3733.935547 2395.672363,3732.597168 2401.214600,3731.981934 
	C2401.513184,3739.577148 2401.513184,3739.577148 2392.874756,3740.023682 
z"
        />
        <path
          fill="#CC855B"
          opacity="1.000000"
          stroke="none"
          d="
M2406.667969,3685.902832 
	C2406.051270,3684.239258 2405.865967,3682.426758 2407.522461,3680.437988 
	C2410.370117,3682.263184 2410.589111,3684.249756 2406.667969,3685.902832 
z"
        />
        <path
          fill="#C4BDAE"
          opacity="1.000000"
          stroke="none"
          d="
M2435.973145,3670.455811 
	C2437.715576,3669.734619 2439.567871,3665.572998 2439.900879,3670.272705 
	C2440.379395,3677.017334 2436.777344,3683.073242 2435.236816,3689.495850 
	C2435.093750,3690.093506 2434.419434,3687.964600 2433.911865,3686.479004 
	C2432.610352,3683.578369 2432.992432,3681.175781 2433.953857,3678.117188 
	C2435.878906,3676.283447 2435.788574,3674.658936 2435.954102,3672.539307 
	C2435.756348,3671.559570 2435.583984,3671.166016 2435.973145,3670.455811 
z"
        />
        <path
          fill="#704A3A"
          opacity="1.000000"
          stroke="none"
          d="
M2434.020264,3677.927734 
	C2434.384033,3679.712646 2434.189941,3681.967529 2433.894531,3685.050537 
	C2431.319580,3683.489746 2431.541748,3680.981201 2434.020264,3677.927734 
z"
        />
        <path
          fill="#704A3A"
          opacity="1.000000"
          stroke="none"
          d="
M2430.930176,3700.915039 
	C2431.022461,3700.859619 2430.838135,3700.970459 2430.930176,3700.915039 
z"
        />
        <path
          fill="#704A3A"
          opacity="1.000000"
          stroke="none"
          d="
M2436.166748,3670.229004 
	C2436.534668,3670.139160 2436.345215,3671.084229 2436.136963,3671.518799 
	C2435.689453,3671.557861 2435.426514,3671.172852 2435.240479,3670.753906 
	C2435.217285,3670.701904 2435.602783,3670.468018 2436.166748,3670.229004 
z"
        />
        <path
          fill="#E59F70"
          opacity="1.000000"
          stroke="none"
          d="
M2402.207031,3355.418457 
	C2402.022461,3353.105469 2402.011230,3352.218262 2401.995117,3350.665527 
	C2405.750977,3343.775879 2409.568359,3342.913574 2416.427734,3349.287354 
	C2421.720215,3354.205566 2426.668945,3355.161133 2433.478760,3353.536133 
	C2434.009766,3353.995605 2434.000000,3354.000000 2434.003662,3353.996094 
	C2433.062256,3360.701172 2437.166748,3365.280029 2440.951416,3369.852783 
	C2448.359131,3378.801758 2454.105713,3388.574219 2458.496582,3399.403320 
	C2460.665039,3404.750488 2463.748535,3409.869873 2466.281494,3415.176270 
	C2467.841553,3418.444336 2468.269775,3421.307129 2467.228027,3424.507324 
	C2463.589600,3424.943115 2464.451172,3422.205078 2463.790771,3420.603271 
	C2461.546387,3415.163818 2460.342285,3408.947510 2453.224121,3407.268555 
	C2451.568848,3406.878418 2450.624756,3405.239014 2450.189209,3403.560059 
	C2448.537354,3397.194092 2444.052734,3397.824463 2439.023682,3398.487549 
	C2438.463867,3403.062988 2442.802979,3406.923340 2438.963135,3411.241943 
	C2437.285645,3413.128662 2440.161865,3415.626221 2441.958252,3416.819580 
	C2449.479004,3421.815918 2450.023926,3427.833252 2445.453125,3435.101318 
	C2444.422119,3436.740479 2444.277344,3438.910156 2443.478760,3440.727783 
	C2441.475830,3445.286377 2441.143555,3448.989746 2447.054443,3450.901611 
	C2449.112305,3451.567139 2450.018311,3453.702393 2448.605713,3455.781250 
	C2447.368652,3457.602051 2445.548828,3457.934814 2443.348145,3457.175049 
	C2435.964600,3454.625977 2435.498535,3454.837158 2434.100098,3461.934570 
	C2432.796387,3468.549072 2430.216797,3474.469238 2425.587158,3479.431641 
	C2424.907471,3480.160645 2423.859131,3480.828857 2423.663086,3481.682129 
	C2421.842041,3489.604492 2418.163086,3494.575195 2407.809814,3489.212646 
	C2410.608154,3497.850830 2416.099365,3502.372559 2416.949463,3509.307861 
	C2417.450439,3513.395264 2417.666504,3515.832520 2412.839111,3516.002686 
	C2410.802002,3516.074463 2408.066406,3516.239990 2407.573242,3518.130127 
	C2405.929932,3524.427246 2400.625244,3526.909912 2396.426025,3530.842285 
	C2392.205322,3534.794434 2389.437500,3535.067627 2385.651367,3531.263916 
	C2384.726318,3530.334473 2383.790283,3529.305664 2382.652344,3528.736572 
	C2381.877930,3528.349854 2380.581299,3528.369629 2379.805420,3528.767578 
	C2378.546875,3529.413086 2377.987061,3530.851562 2378.519531,3532.176758 
	C2379.378418,3534.314697 2380.156738,3536.679932 2381.650391,3538.333740 
	C2387.494385,3544.804199 2388.272705,3552.634521 2387.908691,3560.687500 
	C2387.602539,3567.461670 2381.731201,3570.287109 2375.968750,3566.525391 
	C2372.209961,3564.071777 2370.250000,3560.665527 2369.832764,3555.791260 
	C2369.048340,3546.622314 2362.767090,3543.556641 2354.440918,3547.645264 
	C2353.564697,3548.075684 2352.918945,3549.106689 2352.026855,3549.353516 
	C2341.122314,3552.369385 2335.382080,3555.561523 2335.835938,3538.697754 
	C2336.025635,3531.643799 2338.240479,3524.998047 2338.485107,3518.008545 
	C2338.576660,3515.393311 2340.456543,3513.151855 2342.787109,3511.556885 
	C2344.489014,3510.392334 2345.496826,3508.757812 2345.864502,3506.645508 
	C2346.418945,3503.455811 2344.496338,3499.025391 2347.647949,3497.300293 
	C2351.553467,3495.162109 2356.322998,3494.260986 2360.992188,3494.707031 
	C2362.626953,3494.863037 2365.041504,3495.796387 2365.300781,3493.980713 
	C2366.021484,3488.930908 2372.585693,3488.623047 2373.045654,3484.201172 
	C2373.422119,3480.581299 2370.012695,3478.104248 2367.082520,3476.944336 
	C2361.846924,3474.872314 2361.309326,3472.470459 2364.509033,3468.080078 
	C2366.760986,3464.989990 2366.899414,3460.959473 2370.138428,3457.886963 
	C2373.674316,3454.533203 2372.583252,3449.118652 2371.009766,3444.676270 
	C2367.909180,3435.920654 2368.720215,3433.455811 2377.507080,3430.160400 
	C2380.352783,3429.093018 2382.735107,3428.618652 2380.478516,3424.448730 
	C2379.334473,3423.291260 2378.862305,3422.490234 2378.526855,3420.888184 
	C2382.726562,3413.967041 2382.529785,3404.440186 2392.262207,3401.510498 
	C2392.813965,3400.572998 2393.069824,3400.210449 2393.883545,3399.460938 
	C2396.579346,3398.203125 2401.476562,3400.324707 2399.145264,3394.631836 
	C2397.005859,3389.408203 2397.438232,3383.429443 2394.350098,3378.442383 
	C2393.127930,3376.468506 2392.044678,3374.096924 2394.115967,3372.035889 
	C2396.376709,3369.786621 2399.079834,3370.695557 2401.574463,3371.694580 
	C2405.893799,3373.423828 2408.259766,3377.248047 2410.233643,3381.021484 
	C2413.696533,3387.640625 2416.071777,3394.911865 2422.473145,3398.127686 
	C2417.771973,3389.923828 2412.178711,3380.820312 2410.840820,3369.981201 
	C2410.261475,3365.287598 2406.574707,3362.620361 2403.471436,3358.713379 
	C2402.832520,3357.697021 2402.606445,3357.270752 2402.207031,3355.418457 
z"
        />
        <path
          fill="#C2825D"
          opacity="1.000000"
          stroke="none"
          d="
M2394.255371,3399.230469 
	C2393.915283,3399.941406 2393.906982,3400.931641 2393.914551,3401.426758 
	C2387.004150,3406.130859 2384.967529,3399.566406 2381.280273,3395.996582 
	C2380.693359,3403.792236 2378.582031,3411.180176 2379.583984,3419.442139 
	C2379.944580,3420.921631 2379.957275,3421.798340 2379.975098,3423.332764 
	C2377.012451,3423.927490 2374.211426,3423.805420 2371.326660,3422.384277 
	C2366.322510,3419.918945 2364.808105,3420.697266 2364.171631,3425.992188 
	C2363.627441,3430.519287 2364.205078,3435.038574 2357.101562,3433.033203 
	C2354.476562,3432.291992 2353.553467,3435.250977 2354.343262,3436.986328 
	C2357.415283,3443.735107 2353.908936,3451.677490 2358.598633,3458.137451 
	C2360.921875,3461.337646 2356.178467,3463.474609 2356.433105,3466.179199 
	C2357.053711,3472.770020 2353.050049,3476.009033 2348.806152,3479.945068 
	C2346.486572,3482.096680 2345.712646,3483.687256 2342.858154,3481.269531 
	C2341.758301,3480.337891 2340.091309,3480.875244 2339.411133,3482.127441 
	C2336.547363,3487.396973 2330.156982,3491.399170 2333.278076,3498.887207 
	C2334.298096,3501.333984 2332.609375,3503.192871 2331.880615,3505.364258 
	C2328.975342,3514.023438 2325.180176,3522.620605 2327.634277,3532.162109 
	C2328.245605,3534.539062 2327.311279,3536.702881 2326.686035,3538.954346 
	C2325.181152,3544.373047 2323.922852,3549.676025 2327.222168,3555.175537 
	C2328.952148,3558.059326 2328.024902,3561.588135 2326.587158,3564.777100 
	C2324.370850,3569.693115 2321.747314,3574.242920 2316.568115,3577.682373 
	C2312.944092,3576.280273 2315.234375,3571.375732 2310.609375,3570.093018 
	C2305.983643,3562.573242 2310.499268,3554.332031 2307.546387,3546.761963 
	C2306.494141,3544.064209 2306.761230,3540.893311 2305.550537,3538.116943 
	C2301.021240,3527.729736 2296.250488,3527.183594 2288.688232,3535.525146 
	C2285.199219,3539.374268 2283.420898,3544.808594 2277.514160,3547.104004 
	C2274.741943,3546.595947 2273.464600,3545.197266 2272.287598,3542.705078 
	C2271.970703,3541.681396 2271.903809,3541.349121 2271.748779,3540.515625 
	C2271.583740,3539.516357 2271.506348,3539.017822 2272.169434,3538.364014 
	C2272.909912,3538.208740 2273.692139,3538.320068 2274.083252,3538.264404 
	C2275.520264,3536.709717 2276.331299,3535.072266 2277.812012,3533.111084 
	C2279.188477,3529.062256 2281.676025,3526.299072 2283.861816,3522.604980 
	C2285.151855,3520.581299 2285.718018,3518.978027 2286.421875,3516.741943 
	C2286.568359,3515.791260 2286.516602,3515.447998 2286.303467,3514.609375 
	C2285.011963,3512.431641 2286.114014,3511.343994 2287.766602,3509.791016 
	C2288.957764,3509.034424 2289.830322,3508.749268 2289.962891,3508.224854 
	C2295.198730,3487.552002 2313.174072,3476.889404 2326.057129,3462.237549 
	C2328.243652,3459.750732 2330.849365,3457.577637 2330.448975,3453.733643 
	C2328.990479,3439.723145 2337.538574,3429.175537 2343.892334,3418.335693 
	C2352.965088,3402.858154 2365.530518,3389.481445 2371.661133,3372.167969 
	C2373.914551,3365.802734 2378.110352,3362.174072 2386.098633,3364.163086 
	C2388.847168,3365.742676 2390.584961,3367.744141 2390.292725,3369.964355 
	C2388.978027,3379.947266 2393.318604,3388.988281 2394.255371,3399.230469 
z"
        />
        <path
          fill="#CC855B"
          opacity="1.000000"
          stroke="none"
          d="
M2473.289551,3490.095215 
	C2472.814209,3500.267334 2465.938721,3506.696289 2458.285156,3513.420898 
	C2454.984131,3505.855469 2455.007080,3505.371094 2461.714844,3501.864014 
	C2464.820801,3500.239990 2467.154053,3498.274658 2468.038818,3494.834961 
	C2468.663818,3492.404541 2470.063965,3490.759277 2473.289551,3490.095215 
z"
        />
        <path
          fill="#AB7453"
          opacity="1.000000"
          stroke="none"
          d="
M2386.442139,3365.281738 
	C2378.937988,3364.551025 2375.305664,3367.934570 2373.819092,3374.358154 
	C2371.963623,3382.375244 2364.917969,3388.099609 2364.028809,3396.551270 
	C2363.963623,3397.172119 2363.640381,3398.146484 2363.211182,3398.292480 
	C2352.099854,3402.077881 2353.219238,3415.665527 2344.520020,3421.608643 
	C2342.177490,3423.208984 2340.936035,3427.301025 2340.408936,3430.453613 
	C2339.766602,3434.294189 2337.872070,3437.255371 2335.823242,3440.219971 
	C2332.885498,3444.470947 2330.041992,3448.523926 2334.367920,3453.583984 
	C2336.323486,3455.871338 2334.997803,3457.885498 2333.262695,3459.680420 
	C2326.237061,3466.947754 2318.114746,3473.165527 2312.600830,3481.885254 
	C2310.593506,3485.060303 2305.829346,3483.491943 2303.304199,3486.983154 
	C2299.895752,3491.696289 2298.254883,3496.988037 2296.117920,3502.186279 
	C2294.730957,3505.559082 2295.514893,3510.537354 2289.403564,3511.274658 
	C2285.933594,3503.297363 2288.331055,3496.687988 2294.331543,3490.461182 
	C2299.410400,3483.417969 2303.692139,3476.666504 2308.087158,3469.268799 
	C2315.000488,3462.782959 2318.951172,3454.823975 2324.080811,3446.929688 
	C2324.911865,3443.050049 2324.278320,3439.571289 2326.548340,3435.971924 
	C2334.398193,3425.929932 2339.227539,3414.844971 2346.206299,3404.301758 
	C2349.027588,3401.190674 2350.643555,3398.115479 2352.475098,3394.412598 
	C2354.239014,3391.883545 2357.281982,3391.245605 2357.667725,3387.625488 
	C2358.444092,3385.314209 2359.197510,3383.697998 2360.276123,3381.468750 
	C2365.916748,3370.989746 2371.796143,3361.395508 2375.440918,3349.945312 
	C2377.806885,3345.657227 2379.469971,3341.866943 2379.999512,3336.935547 
	C2381.464355,3333.329834 2382.916504,3330.468750 2384.135254,3326.764648 
	C2384.829590,3325.318848 2385.321045,3324.549805 2386.292969,3323.290527 
	C2390.666016,3317.527344 2392.344238,3310.694824 2398.549316,3306.246094 
	C2399.512695,3305.670654 2399.940430,3305.515625 2401.009277,3305.594727 
	C2406.745850,3305.622314 2410.372070,3303.158203 2412.875488,3298.761719 
	C2413.505859,3297.655029 2414.591309,3296.542969 2416.139404,3297.096436 
	C2417.637451,3297.631836 2417.749512,3298.956299 2417.538086,3300.342773 
	C2417.266357,3302.125488 2415.532227,3304.407471 2418.410645,3305.347656 
	C2421.008057,3306.196289 2421.546143,3303.656494 2422.544434,3302.018799 
	C2423.304199,3300.773438 2424.440674,3300.220459 2425.895996,3300.752197 
	C2427.350586,3301.283936 2427.863770,3302.575195 2427.804199,3303.906250 
	C2427.732666,3305.518311 2427.528564,3307.205322 2426.963135,3308.701660 
	C2424.296631,3315.758057 2424.314697,3316.511230 2431.923828,3315.829346 
	C2446.188965,3314.550537 2457.712158,3319.632324 2468.127686,3328.729980 
	C2470.841309,3331.100586 2474.013184,3333.459961 2477.380615,3334.470459 
	C2486.563232,3337.225830 2490.971436,3343.348145 2491.989990,3353.245117 
	C2487.522461,3351.669678 2485.159180,3347.411621 2482.282471,3343.543457 
	C2479.534180,3339.848145 2476.242676,3338.666992 2472.449219,3343.640625 
	C2465.288574,3343.747314 2459.870361,3341.210449 2455.210449,3336.368652 
	C2450.723877,3331.706787 2442.049561,3330.560303 2435.176758,3330.789062 
	C2428.028076,3331.026611 2420.630371,3330.248047 2414.057861,3326.675781 
	C2412.013672,3325.564697 2410.087891,3324.493652 2407.758789,3323.907471 
	C2398.797852,3321.652100 2398.740967,3321.520020 2396.046875,3330.246094 
	C2395.443848,3332.199463 2394.947510,3334.123047 2393.411621,3335.474365 
	C2387.062988,3341.061523 2386.275635,3348.590088 2385.979004,3357.141113 
	C2387.361816,3360.019287 2388.079346,3362.168945 2386.442139,3365.281738 
z"
        />
        <path
          fill="#D89066"
          opacity="1.000000"
          stroke="none"
          d="
M2386.447021,3366.506836 
	C2385.912109,3363.836914 2385.933838,3361.705322 2385.967773,3358.772461 
	C2389.503906,3358.967285 2389.194824,3363.242676 2392.088379,3365.253906 
	C2395.615234,3362.000488 2396.763428,3357.001221 2401.346191,3354.122070 
	C2402.033691,3353.992676 2401.994141,3353.997559 2401.997070,3353.981445 
	C2402.416260,3354.516357 2402.757812,3355.109375 2403.510742,3355.874756 
	C2405.990967,3357.740723 2407.958740,3359.499756 2409.957520,3361.222656 
	C2411.481689,3362.535889 2413.422119,3363.901123 2413.471436,3365.893311 
	C2413.830078,3380.409180 2423.283691,3391.645752 2427.268311,3404.832275 
	C2427.652344,3406.102539 2427.986572,3407.412842 2426.816406,3408.509521 
	C2425.255615,3409.217285 2424.090576,3408.421387 2423.775391,3407.103027 
	C2422.298096,3400.923584 2419.850098,3395.968018 2412.427979,3395.467041 
	C2410.553711,3395.340820 2410.517334,3393.281250 2410.467285,3391.500977 
	C2410.212891,3382.461182 2404.236328,3375.105469 2394.806641,3372.899902 
	C2400.740967,3383.025879 2402.124756,3393.714355 2403.985596,3404.891602 
	C2399.192383,3404.558105 2398.608154,3400.167236 2394.605957,3400.045654 
	C2391.965820,3395.370850 2390.159424,3390.731934 2388.018311,3386.252930 
	C2386.504883,3383.086914 2386.026855,3380.184326 2387.315918,3376.734619 
	C2388.474854,3373.635010 2388.545654,3370.227783 2386.447021,3366.506836 
z"
        />
        <path
          fill="#D89066"
          opacity="1.000000"
          stroke="none"
          d="
M2434.426758,3353.454102 
	C2429.063232,3353.065674 2428.556396,3360.942871 2423.480713,3359.421387 
	C2419.384277,3358.193604 2418.687012,3353.343994 2415.522461,3351.172852 
	C2412.896973,3349.371338 2409.865234,3348.084473 2406.879150,3346.912109 
	C2404.977539,3346.165283 2404.171143,3348.205078 2402.510986,3349.625000 
	C2398.393799,3345.441162 2399.472168,3341.376221 2403.446045,3337.780518 
	C2407.806641,3333.834229 2413.416016,3332.911133 2418.162109,3335.506348 
	C2423.879639,3338.632324 2428.446777,3343.404297 2435.194092,3345.266357 
	C2439.362549,3346.416748 2438.193604,3350.434082 2434.426758,3353.454102 
z"
        />
        <path
          fill="#BA7C55"
          opacity="1.000000"
          stroke="none"
          d="
M2503.199707,3405.810547 
	C2500.809326,3408.332520 2499.764893,3406.591553 2498.917480,3404.766846 
	C2498.742920,3404.390625 2499.181641,3403.306396 2499.565430,3403.164795 
	C2501.487793,3402.453125 2502.490479,3403.676758 2503.199707,3405.810547 
z"
        />
        <path
          fill="#AB7453"
          opacity="1.000000"
          stroke="none"
          d="
M2501.115234,3390.086426 
	C2500.369629,3391.450684 2499.618408,3391.459473 2498.955078,3390.491455 
	C2498.816162,3390.288574 2498.822266,3389.817139 2498.966553,3389.618408 
	C2499.657715,3388.667725 2500.407471,3388.706299 2501.115234,3390.086426 
z"
        />
        <path
          fill="#805541"
          opacity="1.000000"
          stroke="none"
          d="
M2449.580566,3225.470459 
	C2452.130859,3226.009521 2454.251709,3226.018799 2457.167969,3226.033691 
	C2461.424316,3229.349854 2460.416992,3233.358887 2459.669189,3237.291992 
	C2458.925537,3241.203857 2458.430420,3244.669434 2460.574219,3248.764160 
	C2464.784912,3256.806641 2469.190918,3261.937744 2479.263672,3257.511230 
	C2482.723145,3255.991455 2487.203857,3256.755371 2491.112061,3257.807861 
	C2495.999023,3259.124023 2499.037109,3262.474365 2497.187256,3267.418945 
	C2495.044922,3273.144775 2496.041016,3278.455811 2496.896240,3284.063721 
	C2498.714844,3295.986084 2495.367676,3298.912842 2480.976074,3296.203369 
	C2494.007324,3302.878662 2498.703613,3316.293457 2511.496094,3321.508789 
	C2522.337158,3331.833984 2532.573486,3341.765625 2541.516602,3353.829102 
	C2539.979736,3356.354980 2537.708008,3356.257568 2535.781494,3354.473389 
	C2531.630615,3350.627930 2525.865967,3348.519775 2522.799316,3343.666504 
	C2516.345215,3333.452881 2504.217529,3330.774902 2496.772461,3322.597900 
	C2494.472656,3323.458496 2494.662354,3324.669434 2494.982666,3326.026611 
	C2495.627930,3328.759766 2494.591064,3330.130859 2491.981445,3328.579590 
	C2480.068359,3321.498535 2469.613770,3312.214111 2457.402588,3305.554932 
	C2456.327393,3304.968506 2454.750977,3303.000977 2454.915039,3302.776855 
	C2459.287842,3296.793457 2456.200195,3292.366455 2452.296631,3287.856201 
	C2451.820557,3287.305664 2452.501953,3285.094727 2453.283203,3284.224854 
	C2457.252441,3279.806641 2454.353271,3277.000977 2451.146729,3274.289795 
	C2447.761230,3271.427490 2442.507324,3271.712891 2440.000000,3266.855957 
	C2438.991943,3264.903564 2432.136475,3268.048096 2432.547119,3270.960449 
	C2433.914062,3280.660400 2424.935547,3282.403076 2420.748535,3287.759521 
	C2419.869873,3288.883301 2418.449463,3289.492188 2417.280273,3288.587646 
	C2415.792725,3287.437012 2415.898926,3285.579102 2416.599854,3284.008057 
	C2417.632324,3281.694824 2420.120361,3281.122803 2422.062256,3279.900391 
	C2424.441162,3278.401855 2426.876221,3276.676270 2424.119141,3273.693359 
	C2421.835449,3271.221924 2419.339355,3272.213379 2417.056641,3274.108643 
	C2414.836182,3275.952393 2414.165283,3278.477295 2413.728271,3281.151611 
	C2413.488525,3282.618164 2412.390625,3283.365723 2410.423340,3284.121338 
	C2408.879395,3283.482178 2408.288818,3282.510986 2407.796387,3280.551514 
	C2410.078125,3271.564941 2411.354736,3263.305664 2410.904297,3254.752441 
	C2410.677002,3250.437012 2411.817383,3246.300781 2415.500000,3242.462402 
	C2424.997803,3239.863281 2431.821533,3244.022949 2434.943604,3253.444824 
	C2442.170166,3248.869385 2442.176758,3248.863525 2437.010986,3243.167236 
	C2434.347656,3240.229980 2431.579346,3237.397705 2429.447510,3234.005371 
	C2425.647461,3227.958496 2424.767578,3222.459229 2431.469238,3217.628418 
	C2435.431396,3214.772461 2436.797363,3210.214355 2438.245605,3205.779541 
	C2439.649170,3201.481445 2441.680908,3197.776855 2447.801758,3198.094727 
	C2448.944824,3198.362061 2449.387695,3198.523682 2450.426758,3199.060547 
	C2452.843994,3201.297363 2454.554932,3203.261719 2456.248291,3205.234375 
	C2463.871582,3214.113525 2463.206543,3216.621826 2452.079102,3220.026611 
	C2449.292236,3220.879395 2448.258789,3222.185059 2449.580566,3225.470459 
z"
        />
        <path
          fill="#2C2520"
          opacity="1.000000"
          stroke="none"
          d="
M2401.620605,3305.106445 
	C2401.219727,3305.965576 2400.789307,3306.102783 2399.791504,3306.609375 
	C2397.061035,3307.932861 2394.980957,3307.146240 2392.247314,3306.530273 
	C2391.184082,3306.265869 2390.759766,3306.148193 2389.700439,3305.836914 
	C2388.209229,3305.371582 2387.354248,3305.095215 2385.835205,3304.625244 
	C2369.680420,3300.454590 2355.143311,3292.780273 2338.031982,3292.147949 
	C2334.368896,3289.052246 2332.987793,3285.686279 2335.681641,3282.241211 
	C2338.092773,3279.157715 2341.664551,3280.346680 2344.889160,3281.153076 
	C2358.332031,3284.514648 2371.566895,3288.593262 2384.802246,3292.675049 
	C2389.166992,3294.021729 2393.533936,3296.085693 2398.864258,3293.097168 
	C2400.648193,3292.583008 2401.696777,3292.584717 2403.453369,3293.191895 
	C2404.772949,3294.503662 2404.955811,3295.519043 2404.083496,3297.109863 
	C2401.556641,3299.515137 2401.696533,3301.983643 2401.620605,3305.106445 
z"
        />
        <path
          fill="#959693"
          opacity="1.000000"
          stroke="none"
          d="
M2404.360352,3294.990723 
	C2402.735352,3294.755859 2401.950684,3294.565674 2400.560791,3294.353027 
	C2397.751709,3294.144287 2395.981445,3293.218750 2394.486572,3290.576416 
	C2393.224609,3285.226807 2395.261719,3281.636475 2398.763428,3277.832764 
	C2401.385498,3276.272705 2403.447510,3276.304688 2406.268555,3277.316895 
	C2407.867432,3278.197266 2408.647949,3278.882080 2409.612305,3280.400391 
	C2409.812744,3281.828369 2409.735107,3282.529785 2409.682129,3283.770508 
	C2409.687500,3285.068604 2409.668213,3285.827637 2409.703125,3287.157227 
	C2411.518799,3292.208984 2408.328125,3293.602295 2404.360352,3294.990723 
z"
        />
        <path
          fill="#805541"
          opacity="1.000000"
          stroke="none"
          d="
M2403.485840,3295.389160 
	C2405.402588,3292.945801 2407.285400,3290.945312 2409.462646,3288.336426 
	C2412.100098,3288.352295 2414.585693,3289.180176 2412.441895,3292.173096 
	C2410.599609,3294.745117 2408.536621,3297.389893 2404.195068,3297.686523 
	C2403.466064,3297.016357 2403.464355,3296.424072 2403.485840,3295.389160 
z"
        />
        <path
          fill="#AB7453"
          opacity="1.000000"
          stroke="none"
          d="
M2512.000977,3352.001221 
	C2512.215332,3350.796631 2512.239990,3349.414795 2513.778320,3349.119141 
	C2514.098389,3349.057373 2514.886963,3349.731201 2514.899414,3350.086182 
	C2514.938477,3351.198730 2514.022705,3351.583252 2512.541748,3351.914062 
	C2511.997559,3351.997803 2512.000000,3352.000000 2512.000977,3352.001221 
z"
        />
        <path
          fill="#0D171F"
          opacity="1.000000"
          stroke="none"
          d="
M2579.082275,3273.855469 
	C2578.533203,3280.910645 2576.206543,3287.758301 2570.969971,3293.861328 
	C2572.215820,3287.059082 2572.865967,3279.581543 2579.082275,3273.855469 
z"
        />
        <path
          fill="#3D494C"
          opacity="1.000000"
          stroke="none"
          d="
M2598.976562,3230.248291 
	C2599.933350,3220.913330 2604.037109,3219.645996 2613.787842,3225.864746 
	C2606.770752,3226.861816 2606.721191,3226.878906 2608.914062,3233.233398 
	C2609.385742,3234.600342 2609.670654,3235.768799 2608.520752,3236.810547 
	C2607.113770,3238.084961 2605.398926,3238.051758 2603.914307,3237.193115 
	C2601.475342,3235.782471 2600.896729,3232.928467 2598.976562,3230.248291 
z"
        />
        <path
          fill="#3D494C"
          opacity="1.000000"
          stroke="none"
          d="
M2613.504395,3228.039062 
	C2620.755859,3230.304199 2617.866211,3235.921631 2617.233154,3240.658691 
	C2616.860596,3243.445801 2615.268066,3246.070068 2614.138184,3249.566406 
	C2611.349121,3249.793457 2609.611328,3248.008545 2610.486816,3245.415771 
	C2612.324707,3239.972656 2611.380371,3234.173584 2613.504395,3228.039062 
z"
        />
        <path
          fill="#0D171F"
          opacity="1.000000"
          stroke="none"
          d="
M894.470581,3132.469238 
	C889.310120,3132.692627 884.648193,3133.397461 879.615112,3134.715332 
	C877.060486,3135.948242 875.177490,3135.585938 873.703979,3132.886230 
	C874.827576,3131.800293 876.021423,3131.483643 877.654297,3130.721436 
	C876.875671,3129.012451 876.500122,3127.521240 877.355347,3125.160156 
	C876.755554,3121.292236 875.780090,3116.623535 880.928711,3118.504883 
	C886.578796,3120.569824 889.963074,3117.204346 895.040527,3116.027832 
	C900.310059,3115.404541 901.506104,3119.388428 903.096252,3122.016846 
	C906.014343,3126.840576 910.544006,3130.039062 914.045105,3134.229004 
	C914.142944,3134.345947 914.742065,3134.277344 914.820129,3134.136230 
	C915.133545,3133.569580 915.195679,3132.913330 915.385376,3131.654297 
	C915.745972,3127.836914 916.438416,3124.868164 919.746460,3122.245117 
	C929.154053,3121.530518 942.787842,3130.733398 945.538208,3139.336914 
	C945.732544,3140.767090 944.203308,3141.771973 946.380005,3142.546143 
	C950.289368,3144.011963 953.198242,3144.170410 955.326416,3140.471680 
	C956.857849,3137.810303 959.034668,3135.876953 963.117554,3137.013916 
	C965.724121,3140.787598 968.780396,3139.228027 970.611511,3137.949463 
	C981.331665,3130.463623 990.197510,3137.012939 998.580566,3142.085693 
	C1005.041748,3145.995117 1010.086121,3151.433838 1018.946167,3149.093750 
	C1021.231995,3148.490234 1027.490967,3148.300781 1027.979980,3155.088379 
	C1028.105103,3160.406250 1024.288452,3159.066162 1022.421021,3158.816162 
	C1008.213989,3156.913330 995.639465,3161.805176 982.549988,3167.703369 
	C977.586914,3167.023926 974.640686,3170.133789 970.463989,3171.692871 
	C965.098389,3172.091064 959.953308,3171.390137 957.301270,3177.831055 
	C950.167114,3184.187256 942.163147,3185.642822 932.940063,3185.134277 
	C930.974731,3183.800049 930.503113,3182.439941 930.741333,3180.160645 
	C931.335388,3177.377441 932.092590,3175.334961 931.936584,3173.038330 
	C930.388550,3174.031738 931.486023,3175.459717 931.089478,3177.276855 
	C930.498474,3180.749268 929.330261,3183.296143 927.692261,3186.417236 
	C925.405457,3189.331299 923.114197,3191.024414 919.439941,3191.769043 
	C911.687927,3192.415527 903.869568,3191.232422 900.112915,3200.603271 
	C899.464661,3201.601318 899.159119,3201.973877 898.255554,3202.763672 
	C896.252136,3203.766846 894.817322,3203.838867 892.736084,3203.028076 
	C889.707458,3198.932373 891.118164,3195.802002 893.358154,3192.611328 
	C894.096558,3191.559082 893.877747,3189.831543 892.530029,3189.140869 
	C891.852478,3188.793701 890.664917,3189.244873 889.786255,3189.555908 
	C889.197083,3189.764648 888.747864,3190.364746 888.231689,3190.784912 
	C886.221008,3192.420898 884.386780,3194.893555 881.384644,3192.866943 
	C880.762207,3192.446777 880.251892,3190.947998 880.519348,3190.276855 
	C881.856262,3186.923584 883.705322,3181.841797 887.175659,3182.681396 
	C896.869141,3185.025391 899.713135,3175.282715 906.624329,3172.983887 
	C908.775635,3172.268311 907.846008,3168.780762 908.037598,3165.770996 
	C908.573181,3158.764160 917.398804,3160.581055 917.827148,3154.297607 
	C915.809265,3153.720459 913.557861,3156.148682 910.550110,3154.552490 
	C910.036316,3150.949951 910.152283,3147.744141 907.959412,3144.177979 
	C902.288513,3141.292236 899.704346,3135.828125 894.470581,3132.469238 
z"
        />
        <path
          fill="#98999E"
          opacity="1.000000"
          stroke="none"
          d="
M965.349182,3087.554688 
	C966.348328,3091.503906 968.144897,3094.018311 971.607239,3093.798828 
	C977.491638,3093.425293 983.179993,3096.220703 989.197754,3094.311768 
	C991.917114,3093.448975 995.541138,3093.058350 997.892151,3094.771973 
	C1001.532410,3097.424805 1004.097168,3097.461182 1007.140137,3094.100098 
	C1009.830566,3091.128418 1012.973938,3092.947510 1015.087097,3094.816895 
	C1018.950989,3098.235107 1022.762939,3097.945312 1026.955811,3096.037842 
	C1027.252808,3095.902588 1027.603149,3095.750000 1027.907715,3095.785156 
	C1036.797241,3096.815430 1044.344116,3092.016113 1053.340088,3090.175537 
	C1056.952759,3097.422119 1049.979248,3099.197998 1046.573120,3103.706299 
	C1045.334717,3105.295654 1044.375366,3106.222656 1042.659790,3107.256348 
	C1031.901367,3104.182617 1021.717834,3106.883057 1011.551025,3107.315186 
	C1008.919495,3107.427002 1006.292358,3107.642578 1002.796387,3107.772461 
	C1000.363770,3108.119141 998.855591,3107.872070 996.683289,3106.969727 
	C982.088074,3102.572998 967.673157,3101.350586 952.684326,3098.902832 
	C950.270630,3098.938477 948.706299,3098.625732 946.416199,3097.871094 
	C932.830383,3091.067871 918.949341,3088.097656 904.881531,3086.068848 
	C898.755981,3085.185791 891.947205,3086.654053 888.940430,3092.043213 
	C884.772217,3099.514648 878.353516,3101.871338 871.150085,3102.812012 
	C859.234863,3104.367920 847.207886,3105.055420 834.535767,3104.517334 
	C833.361267,3103.592529 832.815430,3103.141113 831.849365,3102.356689 
	C827.504211,3096.583008 823.579224,3091.142334 819.453247,3085.039062 
	C832.043518,3085.223633 844.883545,3085.647217 857.604126,3087.110352 
	C863.372009,3087.773682 867.205017,3086.588623 870.034546,3080.885010 
	C877.031067,3073.857422 885.697266,3077.355225 893.436646,3076.262939 
	C911.031616,3073.779541 927.930054,3079.136719 945.622620,3083.280273 
	C949.385864,3084.894775 952.018311,3087.764893 956.585449,3085.809814 
	C959.966309,3085.326416 962.521851,3085.467041 965.349182,3087.554688 
z"
        />
        <path
          fill="#808489"
          opacity="1.000000"
          stroke="none"
          d="
M834.029297,3104.714111 
	C837.246033,3104.079346 840.515015,3103.931152 843.563049,3102.221191 
	C848.474182,3099.466309 853.835815,3099.182617 859.006165,3100.837402 
	C863.165222,3102.168213 866.771912,3101.866211 870.453613,3099.820068 
	C872.510803,3098.677002 874.728149,3097.924561 877.116028,3098.066650 
	C881.421265,3098.322510 884.237061,3097.013184 884.151917,3092.068359 
	C884.104797,3089.334229 886.105042,3087.974365 888.271301,3086.966309 
	C895.196899,3083.743652 901.750366,3081.153076 910.118103,3083.382568 
	C922.166748,3086.593018 934.476624,3088.710449 945.903259,3095.179199 
	C943.828430,3098.746094 940.843323,3097.572998 937.384338,3097.255127 
	C939.525024,3101.120605 941.532654,3104.890869 938.527954,3109.494141 
	C936.032959,3109.936035 933.999878,3110.252197 932.094055,3109.893066 
	C911.003967,3105.918945 890.260132,3108.169922 868.852478,3113.317383 
	C866.019226,3111.293457 863.887939,3112.838623 861.778076,3113.301270 
	C858.333435,3114.057129 855.195618,3116.051758 850.742004,3115.983398 
	C844.450012,3112.909912 837.702026,3111.501953 834.029297,3104.714111 
z"
        />
        <path
          fill="#2E2D30"
          opacity="1.000000"
          stroke="none"
          d="
M979.203735,3166.218994 
	C982.606262,3165.936768 985.192810,3164.917725 987.082153,3163.167969 
	C990.015076,3160.452393 992.487976,3157.302002 997.091248,3159.703369 
	C997.323120,3159.824219 997.833008,3159.726807 998.038269,3159.539307 
	C1006.956604,3151.397705 1017.283997,3157.429688 1027.467529,3156.242676 
	C1028.902954,3155.828369 1029.798706,3155.820312 1031.364746,3155.763672 
	C1032.258423,3156.066895 1032.481812,3156.418945 1032.366211,3157.296875 
	C1023.597595,3163.052002 1013.599426,3164.382324 1004.823975,3168.641357 
	C988.507629,3176.560303 970.673157,3180.755127 954.552979,3188.729492 
	C947.195007,3192.369385 939.213074,3191.480469 932.523743,3195.179443 
	C925.810181,3198.892090 918.997009,3202.337646 911.543823,3203.662842 
	C905.715637,3204.698975 902.941711,3210.937256 896.498169,3210.575684 
	C894.343933,3207.510742 895.284119,3205.236328 897.512207,3202.480713 
	C898.021912,3201.897217 897.905518,3201.708496 897.961365,3201.612793 
	C903.212830,3196.083496 909.875793,3193.129150 917.351074,3190.232422 
	C920.880615,3190.071045 922.751038,3188.109619 925.420898,3186.287109 
	C927.414795,3184.966064 928.957764,3184.271973 931.358459,3184.082275 
	C939.646179,3181.568604 948.158447,3181.966309 955.524231,3176.514893 
	C957.903320,3174.098633 960.239990,3173.408691 963.584961,3173.601562 
	C971.909241,3176.840576 974.103210,3169.405518 979.203735,3166.218994 
z"
        />
        <path
          fill="#5A605E"
          opacity="1.000000"
          stroke="none"
          d="
M964.163940,3136.886475 
	C956.763977,3138.065674 957.215515,3145.637695 952.803284,3149.060059 
	C949.092041,3149.511230 949.074646,3143.916504 944.751892,3143.958496 
	C943.787781,3143.563965 943.486084,3143.320312 943.291321,3142.504395 
	C941.662720,3130.653809 936.267334,3127.229980 922.603149,3128.736328 
	C920.979736,3126.447021 923.473938,3125.347900 923.945618,3123.670898 
	C924.425964,3121.963135 922.410889,3122.255615 920.779785,3121.741699 
	C920.519653,3120.309570 921.155884,3118.431396 922.403198,3118.751465 
	C934.102295,3121.752441 945.414673,3114.670898 957.094788,3117.529541 
	C958.215210,3117.803467 959.808533,3117.243896 960.847900,3116.561768 
	C965.430176,3113.555420 969.229004,3114.947266 973.606567,3117.533936 
	C981.094727,3121.958740 981.672241,3125.029785 974.688232,3130.159912 
	C971.512878,3132.492432 967.980164,3134.338867 964.163940,3136.886475 
z"
        />
        <path
          fill="#687073"
          opacity="1.000000"
          stroke="none"
          d="
M938.675476,3110.409668 
	C940.279419,3102.614990 933.809204,3099.159668 929.973267,3093.729736 
	C935.904785,3091.926270 939.922791,3096.208252 945.331299,3095.910400 
	C947.522766,3095.981201 948.946960,3096.085205 951.083740,3096.250000 
	C966.323364,3101.480225 981.561462,3102.971924 997.361511,3105.477539 
	C999.112793,3106.241455 1000.200134,3106.518799 1001.608521,3107.265381 
	C1001.671631,3109.785645 1001.277588,3111.885742 998.535889,3111.502930 
	C995.826599,3111.124756 992.895691,3108.804443 991.467285,3113.540283 
	C990.938354,3115.293945 989.248474,3114.189209 988.451416,3113.236816 
	C984.959595,3109.063721 980.335083,3109.496094 975.688354,3109.917725 
	C963.594055,3111.015137 951.509766,3112.475342 938.675476,3110.409668 
z"
        />
        <path
          fill="#808489"
          opacity="1.000000"
          stroke="none"
          d="
M1042.392334,3108.134766 
	C1042.344116,3106.498047 1042.644287,3105.485107 1043.388428,3104.147949 
	C1045.369019,3102.536133 1047.330811,3101.784424 1048.755127,3100.440674 
	C1054.432495,3095.084961 1059.767822,3095.183594 1065.144165,3100.869141 
	C1066.738892,3102.555420 1069.106934,3103.298340 1071.377197,3105.407715 
	C1070.412231,3110.062256 1067.828857,3113.353760 1064.293945,3116.717285 
	C1063.664062,3117.389404 1063.367920,3117.551025 1062.569336,3117.774902 
	C1059.336426,3115.684082 1056.567993,3113.598633 1052.447998,3112.959473 
	C1048.791626,3111.666504 1045.766235,3110.212402 1042.392334,3108.134766 
z"
        />
        <path
          fill="#4E585F"
          opacity="1.000000"
          stroke="none"
          d="
M915.093262,3130.520020 
	C916.212952,3134.508545 921.506653,3134.509033 921.228577,3139.393066 
	C916.859863,3138.917236 913.558411,3137.245117 910.050720,3134.527344 
	C903.891357,3129.754639 900.375610,3123.398193 896.366821,3116.403320 
	C903.134583,3114.704102 907.473022,3116.974609 911.676941,3124.876953 
	C912.599731,3126.611328 913.197449,3128.508057 915.093262,3130.520020 
z"
        />
        <path
          fill="#626669"
          opacity="1.000000"
          stroke="none"
          d="
M876.981445,3124.359375 
	C877.984619,3125.939697 878.146240,3127.381836 878.200562,3129.550049 
	C874.205994,3130.894287 870.209045,3128.750244 866.378235,3130.734131 
	C864.590698,3131.659424 863.337646,3130.921387 862.089478,3129.593018 
	C859.427246,3126.758789 855.508179,3125.170410 853.745056,3121.215820 
	C856.777649,3118.825684 859.283569,3121.214600 862.541504,3122.081055 
	C866.872131,3126.819092 872.026367,3122.179443 876.981445,3124.359375 
z"
        />
        <path
          fill="#0D171F"
          opacity="1.000000"
          stroke="none"
          d="
M1032.776123,3157.840820 
	C1032.032471,3157.472412 1032.037842,3157.122314 1032.039062,3156.243408 
	C1037.207275,3150.216797 1048.637695,3149.425293 1053.959473,3154.201904 
	C1055.446045,3155.536133 1055.437378,3156.969482 1054.255493,3158.513916 
	C1052.542725,3160.752930 1050.364380,3162.043945 1046.749146,3161.992188 
	C1044.085571,3160.161621 1041.448608,3159.575684 1038.643799,3158.003418 
	C1036.522705,3157.833740 1035.023804,3157.846191 1032.776123,3157.840820 
z"
        />
        <path
          fill="#626669"
          opacity="1.000000"
          stroke="none"
          d="
M998.307373,3125.879883 
	C1000.852600,3126.115234 1003.332397,3125.365723 1003.576416,3128.157715 
	C1003.778992,3130.474854 1002.138184,3131.540039 1000.033569,3131.913086 
	C999.401001,3132.025146 998.746033,3132.111816 998.108398,3132.077393 
	C995.304932,3131.927246 991.239136,3132.570312 991.137939,3128.914795 
	C991.058411,3126.038818 995.021301,3126.346680 998.307373,3125.879883 
z"
        />
        <path
          fill="#2E2D30"
          opacity="1.000000"
          stroke="none"
          d="
M1038.010986,3158.623535 
	C1043.577271,3155.116455 1043.917725,3155.232910 1045.999878,3161.271484 
	C1043.716675,3163.148926 1041.418701,3164.298096 1038.499512,3165.614746 
	C1037.918945,3163.663574 1037.959595,3161.544922 1038.010986,3158.623535 
z"
        />
        <path
          fill="#6B6164"
          opacity="1.000000"
          stroke="none"
          d="
M1052.188965,3113.637451 
	C1056.104126,3110.000488 1057.496094,3110.467529 1061.955322,3117.161377 
	C1057.922119,3119.129395 1055.340576,3116.385742 1052.188965,3113.637451 
z"
        />
        <path
          fill="#0C1A26"
          opacity="1.000000"
          stroke="none"
          d="
M984.460083,3189.869141 
	C984.905090,3187.893555 986.416260,3186.448975 989.431824,3186.184570 
	C989.682434,3189.045410 987.875061,3190.064697 984.460083,3189.869141 
z"
        />
        <path
          fill="#687073"
          opacity="1.000000"
          stroke="none"
          d="
M897.273682,3201.782471 
	C898.192932,3204.330322 897.928467,3206.676514 896.356384,3209.478027 
	C894.006592,3211.067627 892.043030,3212.353271 888.928101,3211.946777 
	C888.170837,3210.465088 888.091248,3208.987793 888.060120,3206.769043 
	C889.234009,3205.172119 890.359497,3204.316650 891.801086,3203.045166 
	C893.586670,3202.308594 895.056091,3201.988037 897.273682,3201.782471 
z"
        />
        <path
          fill="#E5DDC9"
          opacity="1.000000"
          stroke="none"
          d="
M1230.222412,3654.508301 
	C1231.021851,3648.017578 1228.618530,3643.107178 1225.131592,3638.553467 
	C1223.139893,3635.952637 1221.090088,3633.396484 1218.540649,3630.410156 
	C1218.020752,3627.866699 1218.026855,3625.733154 1218.026001,3622.799805 
	C1212.938354,3606.724854 1207.201294,3591.597656 1202.752686,3576.211670 
	C1199.900513,3566.346924 1196.668091,3556.041016 1196.263794,3544.702637 
	C1195.999146,3540.680664 1196.057129,3537.447998 1193.703735,3534.561768 
	C1190.655396,3530.822998 1187.884155,3527.596680 1190.338623,3521.461182 
	C1192.039429,3517.209717 1187.252441,3512.347656 1184.728027,3508.118652 
	C1182.951538,3505.142578 1181.048950,3502.527588 1183.426270,3498.486328 
	C1190.487061,3503.061523 1190.228027,3511.441895 1193.473022,3518.117432 
	C1196.841187,3525.046631 1201.243164,3531.938232 1200.509033,3540.285400 
	C1207.326904,3546.738037 1207.351929,3556.538818 1212.184204,3563.970703 
	C1216.731079,3570.963135 1221.272705,3577.958740 1226.895630,3586.614014 
	C1227.934570,3578.715088 1229.994629,3573.010254 1224.182373,3567.778564 
	C1220.733643,3564.674561 1220.008423,3559.677979 1218.076416,3554.749023 
	C1220.690430,3553.998291 1223.890259,3553.880859 1224.999512,3556.701660 
	C1227.456665,3562.949951 1232.215698,3568.290283 1232.587891,3575.337402 
	C1232.718506,3577.810791 1233.623535,3579.999756 1235.112305,3581.706787 
	C1241.071777,3588.540527 1245.461792,3596.215332 1248.164673,3604.845703 
	C1248.705688,3606.573730 1249.827393,3607.626953 1251.182373,3608.626465 
	C1260.045166,3615.163574 1263.168579,3625.803467 1269.440063,3634.192627 
	C1272.599243,3638.418457 1271.378540,3640.943359 1266.507202,3641.926758 
	C1263.348267,3642.564697 1261.533203,3644.020996 1262.545044,3647.368896 
	C1263.453979,3650.376709 1266.142578,3650.271973 1268.315796,3649.495605 
	C1272.186646,3648.113037 1274.774658,3648.737549 1276.054688,3652.887207 
	C1276.591675,3654.627441 1277.830933,3655.528076 1279.624146,3655.914062 
	C1288.470703,3657.820068 1293.264648,3665.989502 1301.518188,3668.824219 
	C1299.855713,3672.714600 1296.401855,3673.026367 1294.768921,3675.739746 
	C1291.565796,3681.061768 1291.101074,3681.116699 1297.017456,3684.116943 
	C1299.646362,3685.450195 1301.467529,3687.501465 1302.351440,3689.752686 
	C1304.849731,3696.114502 1309.770020,3698.714111 1315.896240,3699.992188 
	C1316.866211,3700.194580 1317.875854,3700.345703 1318.770752,3700.739746 
	C1320.419067,3701.465332 1321.553467,3702.806396 1321.116089,3704.634277 
	C1320.703735,3706.357422 1319.375244,3707.488037 1317.432495,3707.265381 
	C1314.647339,3706.945557 1311.813477,3704.671631 1309.128540,3707.532959 
	C1308.499268,3710.617676 1311.396118,3711.270996 1312.773804,3712.946289 
	C1313.731323,3714.110352 1313.799194,3715.385498 1312.974243,3716.572021 
	C1312.076416,3717.863281 1310.196899,3718.119385 1309.444824,3717.320801 
	C1305.308472,3712.929443 1296.187012,3712.691650 1297.902344,3703.310791 
	C1298.580200,3699.604004 1296.379639,3697.092041 1292.998901,3695.496826 
	C1290.316772,3694.231445 1287.409302,3693.098145 1286.077148,3690.091064 
	C1281.775879,3680.380859 1272.819580,3675.608887 1264.748535,3669.887207 
	C1263.051636,3668.684326 1261.227905,3667.568848 1259.364746,3669.135498 
	C1257.621826,3670.601074 1257.976196,3672.750488 1258.579590,3674.697754 
	C1259.876099,3678.882568 1262.739502,3682.773193 1265.427124,3685.397217 
	C1270.281128,3690.135498 1272.141846,3696.714355 1277.036011,3701.128662 
	C1278.632690,3702.569336 1278.910767,3704.092285 1279.044922,3706.293457 
	C1279.408325,3712.254395 1281.699951,3714.350342 1288.264160,3714.833252 
	C1290.991577,3715.034180 1291.974609,3715.697021 1291.365234,3718.217773 
	C1289.793213,3724.720215 1291.743652,3730.764648 1297.134277,3734.409180 
	C1299.878418,3736.264404 1302.986816,3737.488281 1304.498169,3740.812500 
	C1305.647827,3743.340576 1309.123413,3745.366211 1306.148193,3748.632324 
	C1303.739258,3751.275879 1301.593628,3754.656250 1297.089722,3753.406494 
	C1295.242432,3752.893799 1293.446777,3751.515381 1291.496582,3753.123047 
	C1292.266602,3766.294434 1292.266602,3766.294434 1282.788086,3768.003906 
	C1280.411255,3767.388916 1278.837158,3766.767822 1276.627686,3766.092773 
	C1275.520996,3759.281250 1273.432129,3753.869629 1266.255249,3751.199951 
	C1263.128540,3750.037109 1261.476440,3746.785645 1263.556396,3742.515137 
	C1265.103027,3741.654785 1266.201172,3741.299561 1267.656860,3740.477051 
	C1270.508789,3735.333984 1279.169678,3739.851074 1279.422119,3733.167969 
	C1279.595337,3728.585693 1274.371582,3725.807861 1271.922852,3721.968994 
	C1270.688843,3720.034424 1269.442261,3718.114258 1268.208496,3715.395508 
	C1267.000244,3711.437500 1266.857544,3707.584229 1261.863770,3706.235596 
	C1254.539307,3697.896240 1244.464722,3692.822510 1240.345215,3681.729004 
	C1243.736938,3671.704102 1239.298706,3665.002197 1232.497559,3658.448730 
	C1231.512573,3656.982178 1230.983521,3655.999268 1230.222412,3654.508301 
z"
        />
        <path
          fill="#F0EBDD"
          opacity="1.000000"
          stroke="none"
          d="
M1195.662842,3544.614502 
	C1202.380127,3551.250244 1201.602173,3560.781738 1203.640015,3569.298828 
	C1206.120239,3579.665283 1211.103394,3589.175781 1213.528564,3599.249756 
	C1215.269897,3606.482422 1222.547729,3612.525635 1218.529297,3621.494141 
	C1211.738770,3621.403809 1208.157837,3617.302979 1205.273804,3612.301758 
	C1205.791992,3609.062744 1209.517334,3610.763428 1210.676514,3608.601562 
	C1211.040161,3607.048340 1209.975830,3606.335205 1208.890747,3605.531494 
	C1205.775024,3603.222168 1199.991089,3603.413330 1199.293457,3599.709473 
	C1198.663940,3596.366455 1201.442261,3592.332031 1202.871826,3588.662354 
	C1205.117065,3582.898438 1203.147339,3579.314453 1197.259277,3580.399170 
	C1192.771484,3581.226318 1188.805054,3580.547363 1184.653198,3579.733398 
	C1182.600708,3579.331299 1180.795654,3578.278564 1180.905884,3575.864014 
	C1181.014282,3573.491211 1182.877319,3572.240479 1184.924194,3572.285156 
	C1189.648804,3572.388672 1192.883057,3569.927490 1196.310303,3567.135986 
	C1199.247314,3564.743164 1198.827515,3562.930908 1196.619873,3560.671143 
	C1196.391968,3560.437744 1196.088623,3560.280273 1195.831909,3560.072998 
	C1186.387695,3552.441650 1186.388062,3552.441162 1195.662842,3544.614502 
z"
        />
        <path
          fill="#EAE6D7"
          opacity="1.000000"
          stroke="none"
          d="
M1134.019043,3566.060547 
	C1138.552979,3562.791992 1143.296875,3563.959473 1147.777832,3564.196533 
	C1154.163574,3564.534424 1157.815918,3569.232178 1157.247925,3575.614746 
	C1156.876099,3579.792969 1153.292358,3580.291504 1149.545288,3581.609619 
	C1148.505249,3577.704102 1149.372803,3573.538086 1145.673340,3570.389160 
	C1141.930664,3567.203369 1137.216309,3569.435303 1134.019043,3566.060547 
z"
        />
        <path
          fill="#EAE6D7"
          opacity="1.000000"
          stroke="none"
          d="
M1155.575806,3650.735840 
	C1155.748535,3653.465820 1155.963013,3655.511230 1155.561646,3657.427979 
	C1155.109375,3659.587646 1153.144897,3660.704346 1151.167847,3661.016357 
	C1148.323120,3661.465088 1148.304688,3658.971191 1147.785767,3657.107178 
	C1147.609619,3656.475098 1147.311157,3655.872803 1147.033569,3655.273193 
	C1144.412231,3649.613281 1145.086670,3648.355713 1150.979370,3648.104004 
	C1152.697388,3648.030518 1154.270874,3648.486816 1155.575806,3650.735840 
z"
        />
        <path
          fill="#EAE6D7"
          opacity="1.000000"
          stroke="none"
          d="
M1153.549561,3636.887939 
	C1149.712524,3634.272705 1150.260986,3632.113770 1153.416870,3630.692871 
	C1158.667603,3628.328125 1161.539795,3630.435303 1164.888672,3638.772461 
	C1160.911011,3641.058105 1157.426514,3640.008789 1153.549561,3636.887939 
z"
        />
        <path
          fill="#F0EBDD"
          opacity="1.000000"
          stroke="none"
          d="
M1209.110840,3646.089355 
	C1208.367188,3647.444092 1207.619263,3647.461182 1206.958374,3646.492676 
	C1206.820312,3646.289795 1206.825439,3645.819824 1206.969360,3645.622559 
	C1207.658203,3644.677734 1208.405884,3644.712646 1209.110840,3646.089355 
z"
        />
        <path
          fill="#EAE6D7"
          opacity="1.000000"
          stroke="none"
          d="
M1138.002319,3634.000000 
	C1138.200439,3632.786133 1138.234985,3631.412109 1139.775269,3631.117920 
	C1140.095459,3631.056885 1140.882568,3631.731689 1140.895386,3632.087646 
	C1140.935059,3633.198486 1140.023315,3633.590576 1138.540405,3633.920410 
	C1137.995117,3634.000000 1138.000000,3634.000000 1138.002319,3634.000000 
z"
        />
        <path
          fill="#E5DDC9"
          opacity="1.000000"
          stroke="none"
          d="
M1181.972168,3456.844727 
	C1183.851196,3456.235107 1187.334961,3455.811279 1186.896484,3457.907715 
	C1185.270996,3465.676758 1191.114990,3467.542969 1195.810425,3468.542480 
	C1202.872559,3470.045898 1205.253784,3474.667236 1207.330688,3480.433838 
	C1202.993530,3484.819580 1195.853271,3485.151367 1192.537476,3491.584961 
	C1190.838623,3491.442383 1188.825684,3491.088867 1188.634521,3490.295898 
	C1186.185181,3480.132324 1176.902222,3471.432861 1181.901367,3459.642578 
	C1182.142578,3459.073975 1181.959351,3458.325195 1181.972168,3456.844727 
z"
        />
        <path
          fill="#E5DDC9"
          opacity="1.000000"
          stroke="none"
          d="
M1192.108643,3496.726562 
	C1198.092041,3500.533447 1205.169312,3504.545410 1202.982910,3514.006348 
	C1202.287354,3517.016602 1203.138672,3519.568115 1205.279541,3521.071777 
	C1208.690430,3523.467285 1210.476196,3527.000000 1212.837769,3530.119629 
	C1217.562500,3536.360596 1217.485962,3536.418457 1212.531006,3543.603516 
	C1207.002686,3541.036377 1205.720459,3535.708252 1204.018555,3530.813477 
	C1201.102173,3522.426025 1198.399170,3513.950195 1196.038574,3505.392090 
	C1195.215576,3502.408203 1192.736694,3500.446289 1192.108643,3496.726562 
z"
        />
        <path
          fill="#E5DDC9"
          opacity="1.000000"
          stroke="none"
          d="
M1322.084229,3457.916016 
	C1325.341187,3459.592285 1326.013550,3462.726074 1327.972534,3464.852295 
	C1333.080933,3470.395264 1338.222168,3476.105469 1347.550659,3472.628906 
	C1351.384277,3455.035156 1352.460693,3437.702637 1349.616211,3420.735596 
	C1347.036987,3405.352051 1344.473022,3389.983887 1342.188232,3374.545654 
	C1340.262329,3361.533691 1333.336914,3350.213867 1327.283325,3338.730225 
	C1320.449585,3325.766602 1312.939575,3313.155518 1307.234131,3299.611084 
	C1305.684448,3295.932129 1303.654907,3292.413086 1304.388916,3287.454102 
	C1312.150146,3277.544922 1322.494995,3278.671631 1332.158569,3280.511475 
	C1340.925415,3282.180664 1349.090698,3280.730957 1355.770630,3280.002197 
	C1341.382812,3283.810547 1325.620972,3278.410156 1309.703003,3277.703125 
	C1306.209229,3277.547852 1304.186890,3275.276123 1304.297852,3271.747314 
	C1304.460815,3266.558350 1305.513550,3261.509521 1311.185913,3259.595947 
	C1319.481812,3256.797363 1326.635376,3252.190186 1333.059692,3246.654785 
	C1325.298706,3245.645996 1318.679321,3246.265137 1314.296387,3255.103027 
	C1311.006714,3261.736816 1304.926636,3261.443848 1299.661255,3255.935791 
	C1298.749146,3254.981934 1298.080566,3253.795410 1297.152100,3252.835449 
	C1294.577148,3250.173584 1287.575073,3250.018799 1289.606079,3245.168701 
	C1292.128174,3239.146240 1293.716064,3231.654297 1301.635376,3229.057617 
	C1306.507935,3227.459717 1311.454712,3227.558105 1316.361938,3226.847412 
	C1319.944946,3226.328369 1327.730469,3231.054688 1325.536133,3220.808350 
	C1325.160889,3219.056396 1328.267700,3217.707275 1330.236450,3218.263428 
	C1334.507690,3219.469482 1337.075073,3216.753662 1339.377075,3215.212646 
	C1329.920532,3226.127686 1324.380737,3206.377930 1314.764771,3211.792236 
	C1317.440552,3214.618408 1319.431274,3218.245361 1317.392090,3222.451660 
	C1315.282959,3226.802734 1311.006592,3226.682129 1307.024902,3226.958496 
	C1305.359131,3227.073975 1303.327881,3226.768555 1302.237915,3227.977295 
	C1293.101440,3238.108154 1283.910889,3229.987549 1274.777710,3228.159180 
	C1272.037109,3227.610596 1269.740356,3226.005127 1269.208740,3223.063477 
	C1268.595947,3219.673340 1271.158447,3218.313477 1273.746826,3217.454834 
	C1281.389160,3214.920898 1279.965820,3207.211182 1282.983276,3202.571289 
	C1282.609619,3201.294434 1282.152588,3200.894287 1281.419800,3200.813477 
	C1276.239380,3200.244873 1268.649048,3206.365234 1266.757080,3196.615723 
	C1265.351685,3189.373779 1275.894409,3177.633545 1282.749390,3177.625732 
	C1285.746826,3177.622314 1288.749146,3177.450928 1291.739868,3177.602051 
	C1295.157959,3177.774658 1298.997925,3178.193848 1299.188599,3182.533691 
	C1299.433716,3188.111816 1295.136230,3187.531494 1291.377563,3187.498291 
	C1305.571167,3186.827637 1319.785645,3185.307373 1333.856079,3189.123291 
	C1327.398682,3187.349365 1319.176270,3190.738770 1315.866211,3181.379395 
	C1314.866333,3178.552246 1312.113281,3179.243408 1309.806274,3179.316650 
	C1306.436035,3179.424072 1303.697510,3178.219971 1303.279907,3174.606445 
	C1302.885620,3171.195312 1305.740601,3170.104736 1308.362305,3169.507324 
	C1312.632935,3168.534424 1315.382812,3166.697998 1316.210571,3161.854980 
	C1317.231934,3155.879150 1320.326782,3156.158691 1324.530640,3159.764893 
	C1331.501953,3165.745361 1339.146240,3165.605225 1346.434692,3160.400391 
	C1350.616943,3157.413086 1354.845703,3156.799805 1360.615356,3158.253906 
	C1355.908203,3156.675781 1351.828125,3157.332031 1348.216187,3155.666748 
	C1345.409546,3154.372559 1342.761108,3152.836914 1342.712158,3149.274170 
	C1342.658325,3145.363281 1345.564941,3144.408691 1348.659424,3143.811279 
	C1351.579834,3143.247559 1354.571045,3142.939453 1357.228638,3141.483154 
	C1353.926636,3131.312988 1354.608154,3130.284424 1364.890503,3128.796631 
	C1373.842285,3127.501465 1382.813354,3129.055908 1391.808228,3128.422119 
	C1397.137329,3128.046143 1398.432617,3132.162109 1394.743774,3136.433350 
	C1391.388062,3140.319580 1386.924316,3142.575928 1381.847534,3143.897705 
	C1387.940796,3156.675537 1382.639771,3167.161377 1375.116821,3177.156738 
	C1367.916626,3186.722900 1359.725830,3195.196777 1348.476807,3200.560303 
	C1355.029297,3201.330078 1360.891846,3198.471191 1368.278687,3198.826904 
	C1366.172729,3193.651855 1365.691040,3188.944824 1372.196045,3187.304199 
	C1374.187500,3186.801758 1373.981812,3184.963867 1374.372559,3183.317139 
	C1377.315186,3170.918213 1388.572876,3163.060547 1400.685669,3164.610840 
	C1405.014160,3165.164795 1405.931274,3168.015137 1406.121338,3171.645508 
	C1406.660889,3181.956055 1403.430420,3186.617676 1393.023804,3190.442627 
	C1402.727661,3190.762939 1406.555298,3185.148682 1406.739624,3176.379395 
	C1406.939941,3166.854736 1412.669189,3157.882568 1409.253784,3147.945557 
	C1408.475342,3145.680176 1411.061401,3143.857422 1412.571655,3142.104492 
	C1416.056763,3138.059814 1420.688843,3133.982666 1421.477173,3129.061035 
	C1422.675293,3121.581055 1427.000977,3119.183105 1433.672241,3117.335205 
	C1436.334106,3115.620605 1439.219604,3115.159180 1440.001709,3111.416504 
	C1440.566528,3110.449219 1440.831299,3110.082764 1441.613281,3109.270996 
	C1445.441284,3106.975342 1449.458130,3105.923584 1452.729370,3106.602295 
	C1461.816528,3108.488037 1460.616577,3100.068115 1464.076660,3096.394531 
	C1462.356689,3095.546631 1460.226440,3096.443848 1458.106323,3094.613281 
	C1457.165039,3092.598877 1457.624512,3091.266846 1458.929443,3089.471680 
	C1466.295776,3085.600098 1473.476685,3083.122559 1480.717407,3080.667725 
	C1487.275146,3078.444580 1497.122314,3077.984375 1492.904785,3066.215576 
	C1492.572754,3065.288330 1492.962646,3063.882324 1493.700317,3063.458496 
	C1499.314819,3060.230957 1503.778687,3056.899658 1505.924316,3049.711670 
	C1507.016602,3046.052246 1517.808960,3048.144531 1516.775757,3039.176514 
	C1516.694824,3038.473877 1519.016968,3037.550781 1520.253784,3037.725830 
	C1527.375000,3038.734131 1533.772339,3035.053955 1541.291870,3035.089355 
	C1542.915039,3036.344727 1544.005859,3037.596436 1543.689331,3038.812744 
	C1540.307373,3051.811768 1538.840454,3065.408691 1531.771118,3077.275146 
	C1529.493042,3081.099365 1525.852661,3082.341797 1521.825317,3083.025635 
	C1521.149536,3083.140381 1520.059570,3083.534424 1520.124023,3083.775391 
	C1520.617310,3085.617188 1522.097656,3084.106201 1523.794678,3084.534668 
	C1525.006104,3084.683594 1525.488281,3084.807129 1526.634033,3085.298340 
	C1533.003418,3093.514160 1532.854004,3094.217529 1523.579102,3099.887451 
	C1511.583496,3099.732178 1503.196411,3107.625977 1492.367432,3110.559570 
	C1496.507812,3111.425537 1499.883545,3110.121094 1503.310303,3110.113037 
	C1510.210693,3110.096436 1512.315796,3114.735107 1513.307251,3120.401123 
	C1514.194946,3125.474365 1512.233154,3129.502686 1507.122437,3129.973389 
	C1502.063599,3130.440186 1495.464844,3132.569092 1493.887817,3124.031250 
	C1492.857178,3118.450684 1488.932739,3122.439453 1486.516846,3122.499023 
	C1484.290527,3122.553711 1480.310059,3122.264893 1480.728394,3126.082764 
	C1481.051636,3129.030518 1484.072632,3129.918701 1486.987671,3129.778076 
	C1488.650513,3129.697998 1490.374146,3129.035889 1491.945801,3129.395508 
	C1496.036987,3130.331543 1502.195068,3127.963379 1502.870850,3134.947510 
	C1503.325928,3139.652100 1496.587158,3144.589844 1490.461304,3143.797119 
	C1484.108032,3142.974609 1477.948608,3142.245361 1471.882568,3145.119629 
	C1472.812866,3147.325439 1474.893677,3146.981201 1476.140991,3146.462402 
	C1487.372681,3141.790283 1495.728271,3147.065918 1503.995483,3153.797363 
	C1507.752075,3156.856201 1508.914062,3159.927002 1506.556030,3164.096924 
	C1502.545898,3171.187744 1500.651978,3178.579346 1501.508179,3186.867432 
	C1502.231445,3193.868896 1490.881104,3205.011475 1482.861450,3205.958984 
	C1467.709595,3207.749268 1467.668579,3207.752686 1468.927612,3222.370117 
	C1469.246948,3226.079590 1467.432129,3227.982666 1464.142822,3228.812500 
	C1461.028076,3229.598633 1459.014648,3228.253662 1457.987793,3225.283691 
	C1457.325562,3223.368652 1457.421021,3221.340088 1457.034790,3219.023438 
	C1454.468140,3220.518311 1448.972900,3219.543701 1453.906616,3225.491699 
	C1455.536621,3227.456543 1451.387329,3231.151611 1449.619629,3233.934570 
	C1448.175049,3236.208984 1444.062744,3237.454590 1446.448486,3241.317627 
	C1448.332397,3244.367676 1451.336182,3244.297607 1454.433594,3243.980225 
	C1458.928955,3243.519775 1458.426147,3240.535889 1458.020630,3237.387695 
	C1457.713135,3235.000488 1458.161621,3231.354492 1460.076294,3231.177490 
	C1470.658691,3230.200195 1468.807739,3223.274414 1469.434814,3215.880127 
	C1470.110596,3207.915039 1485.718262,3202.506104 1492.644409,3206.656250 
	C1494.550537,3207.798584 1495.484497,3209.444336 1495.373779,3211.596436 
	C1495.242188,3214.155518 1493.761963,3216.904297 1491.563110,3216.847168 
	C1484.369629,3216.661377 1485.310425,3221.011719 1484.494385,3225.979736 
	C1483.754395,3230.483887 1483.060791,3235.088379 1482.210571,3239.622070 
	C1481.554565,3243.121582 1483.127686,3245.493408 1486.220947,3246.771973 
	C1490.272339,3248.446777 1491.078857,3245.136230 1492.704102,3242.570557 
	C1497.321411,3235.282227 1505.560181,3230.660400 1507.749390,3221.422607 
	C1508.337036,3218.942627 1511.639038,3218.000732 1515.238892,3218.818604 
	C1517.520142,3219.515381 1517.089478,3222.213867 1519.190430,3222.023193 
	C1519.259155,3222.017090 1519.167969,3220.847412 1518.930908,3219.572754 
	C1512.185059,3209.321045 1514.150757,3195.100586 1523.149902,3187.725342 
	C1527.073608,3184.509766 1528.347290,3179.531006 1532.345947,3175.392090 
	C1527.719360,3173.232178 1521.174438,3173.714600 1522.396484,3165.902832 
	C1523.494873,3163.311768 1525.097778,3162.248535 1527.665894,3161.315918 
	C1533.493652,3159.014404 1538.819092,3159.902588 1545.012573,3160.268066 
	C1542.650024,3148.343750 1550.611694,3139.930176 1555.319458,3130.588867 
	C1558.520264,3124.237549 1566.045410,3123.460938 1573.675171,3123.795410 
	C1583.662354,3126.848633 1586.214355,3134.144775 1585.388550,3143.088135 
	C1583.397095,3164.657715 1569.058960,3183.029053 1570.252686,3205.304199 
	C1570.520386,3210.300049 1567.325928,3214.616943 1564.079712,3218.176514 
	C1565.393066,3220.641846 1567.702881,3220.499512 1569.326660,3221.535400 
	C1573.329468,3224.087402 1574.467163,3226.795654 1569.843994,3229.791504 
	C1567.574463,3231.262451 1564.429321,3232.860107 1565.014648,3235.584229 
	C1567.050537,3245.062012 1560.556519,3248.642822 1554.234375,3252.434570 
	C1550.179565,3254.866699 1546.135254,3257.059326 1549.401001,3262.863281 
	C1549.859985,3263.678711 1549.583252,3265.019775 1548.977295,3265.730225 
	C1543.418335,3272.246582 1548.308472,3275.568359 1553.378662,3279.610840 
	C1556.814209,3287.122559 1558.637451,3293.738037 1548.763062,3297.852539 
	C1542.211182,3299.593506 1536.454590,3301.192383 1530.680176,3302.724121 
	C1526.447754,3303.846924 1523.366211,3305.731934 1523.907959,3310.912842 
	C1524.072754,3312.489990 1523.431030,3314.175781 1523.037354,3315.782227 
	C1522.081787,3319.682373 1520.293335,3322.442383 1515.537109,3322.027832 
	C1510.224487,3321.564941 1505.277466,3321.825195 1505.670654,3329.405762 
	C1505.779053,3331.497314 1503.809082,3332.782471 1502.227661,3334.055664 
	C1501.125488,3334.943115 1500.921509,3335.895752 1501.746826,3337.412109 
	C1504.055054,3341.652588 1503.638306,3343.189209 1499.788452,3343.551025 
	C1494.507690,3344.047363 1489.154297,3343.867188 1483.834351,3343.797852 
	C1482.088989,3343.775146 1480.609863,3342.884766 1480.701416,3340.755859 
	C1480.770996,3339.137207 1482.028076,3338.644043 1483.329102,3338.218506 
	C1485.684204,3337.448730 1488.709351,3339.052979 1491.091064,3335.391602 
	C1485.912354,3332.938232 1480.702637,3334.238770 1475.708740,3334.192627 
	C1473.796021,3334.174805 1472.636475,3335.354004 1472.966553,3337.423584 
	C1473.236572,3339.116211 1473.559570,3340.829590 1471.604736,3341.689209 
	C1469.194580,3342.748535 1467.456665,3344.165039 1468.876099,3346.983887 
	C1470.298706,3349.809082 1473.237183,3350.740967 1475.699341,3349.551270 
	C1482.458252,3346.284668 1489.499634,3350.448242 1496.235474,3348.266846 
	C1497.403564,3347.888916 1498.831543,3348.670654 1499.331421,3350.176025 
	C1499.817505,3351.640381 1499.171753,3352.682617 1498.126709,3353.654297 
	C1495.818726,3355.801270 1491.599609,3355.763184 1490.774902,3360.622314 
	C1498.960327,3360.567383 1507.089111,3360.761963 1513.533325,3368.155273 
	C1509.579102,3370.560303 1505.132324,3372.576904 1505.746826,3378.271973 
	C1505.997925,3380.599121 1504.000122,3381.651855 1501.829224,3381.974365 
	C1491.932983,3383.445312 1482.562988,3386.820801 1472.378296,3390.133789 
	C1475.880859,3393.070801 1478.302124,3396.522217 1483.619995,3394.535645 
	C1487.716309,3393.005127 1491.262695,3395.392090 1495.098145,3397.259521 
	C1487.842407,3401.512695 1475.731689,3401.017090 1469.942871,3396.519043 
	C1462.702393,3390.893066 1462.076782,3391.079346 1456.522583,3401.309814 
	C1465.375610,3399.508057 1472.651978,3403.862305 1481.171143,3404.786621 
	C1480.015381,3408.961670 1476.947144,3410.906738 1474.008911,3411.415771 
	C1469.666260,3412.167725 1465.744141,3415.072754 1461.940552,3415.236328 
	C1454.649414,3415.550537 1453.802856,3420.699219 1451.370239,3425.223877 
	C1449.306396,3429.062500 1451.560669,3435.064453 1445.548340,3436.900879 
	C1449.227173,3441.794678 1442.507446,3444.493164 1443.021606,3449.094727 
	C1443.483276,3453.228271 1442.552856,3457.987549 1436.396118,3458.120605 
	C1435.453979,3458.140869 1433.791992,3458.529541 1433.697754,3458.997070 
	C1432.491211,3464.977295 1429.077026,3468.445312 1423.034668,3469.677490 
	C1422.155640,3469.856934 1422.450317,3472.119629 1423.590942,3473.097168 
	C1425.057617,3474.354004 1427.632080,3473.513916 1428.905762,3476.204346 
	C1426.941162,3479.714844 1423.269531,3479.629150 1419.743652,3480.186035 
	C1416.001099,3480.776855 1415.113770,3483.326904 1418.011597,3485.612793 
	C1422.781982,3489.375732 1422.555908,3492.183105 1417.850342,3495.714355 
	C1416.115601,3497.016113 1415.355347,3499.580566 1414.052124,3501.502197 
	C1412.432129,3503.890625 1410.642822,3504.450439 1408.465820,3501.992676 
	C1401.933228,3494.617432 1401.909668,3494.640869 1395.459717,3501.098145 
	C1394.565796,3501.992920 1393.106567,3502.475830 1393.383911,3504.909180 
	C1402.573853,3509.294189 1412.057007,3513.493164 1423.099854,3510.626221 
	C1424.747681,3510.198730 1426.502930,3510.105713 1427.287109,3512.104004 
	C1428.040405,3514.022949 1426.792236,3515.156738 1425.418091,3516.219971 
	C1422.747681,3518.286133 1420.250854,3520.190430 1416.414185,3518.570312 
	C1413.643188,3517.399902 1410.476318,3517.745361 1407.544678,3518.506104 
	C1403.180420,3519.639160 1399.815308,3521.177246 1399.811279,3527.034180 
	C1399.806763,3533.530762 1396.245850,3535.787598 1390.078125,3533.446777 
	C1385.965576,3531.885986 1382.579590,3529.074219 1379.141968,3534.361328 
	C1378.242188,3535.745117 1376.832275,3534.594238 1376.134644,3533.278809 
	C1373.410034,3528.141357 1371.133301,3530.287598 1369.155273,3533.811523 
	C1367.680176,3536.439209 1366.383057,3539.194580 1363.779907,3540.893311 
	C1361.834595,3542.163086 1361.123779,3543.245361 1362.386841,3545.691162 
	C1364.819580,3550.402832 1361.259399,3552.228271 1357.713379,3553.569824 
	C1356.023193,3554.208984 1354.448486,3553.225830 1353.365723,3551.838379 
	C1352.466675,3550.686035 1352.268188,3548.939697 1353.313721,3548.185059 
	C1359.383667,3543.804199 1356.385986,3537.882080 1355.803711,3532.665527 
	C1355.282837,3527.999756 1354.884888,3523.690674 1356.428589,3519.053955 
	C1358.186401,3513.773926 1355.800537,3510.701904 1350.334229,3511.097900 
	C1347.929321,3511.272217 1345.323730,3509.753662 1343.570679,3510.800049 
	C1336.627441,3514.942871 1331.325928,3511.092773 1325.729126,3507.947510 
	C1324.617554,3507.322998 1323.021973,3507.314209 1323.134033,3505.516113 
	C1323.259766,3503.500000 1324.822510,3502.532471 1326.438232,3502.358887 
	C1331.399414,3501.825684 1336.420776,3501.412842 1341.312134,3502.816406 
	C1343.577637,3503.466309 1345.793091,3504.106689 1348.166016,3503.822266 
	C1349.984985,3503.604492 1351.354248,3502.682373 1351.632568,3500.823730 
	C1351.959595,3498.639648 1351.262817,3496.406250 1348.848267,3496.360840 
	C1342.564087,3496.241699 1339.570435,3493.910889 1341.085205,3487.211182 
	C1341.351807,3486.031738 1339.694580,3484.992920 1338.310791,3484.809814 
	C1336.860352,3484.618164 1335.576538,3485.435547 1335.069580,3486.668701 
	C1334.321533,3488.488525 1334.177490,3490.554688 1333.443115,3492.382324 
	C1332.480957,3494.776123 1330.607178,3496.180908 1327.915527,3495.462158 
	C1325.331055,3494.772217 1324.126343,3492.870605 1324.428589,3490.190918 
	C1324.538574,3489.215576 1324.940918,3488.269287 1325.239990,3487.319580 
	C1326.621704,3482.929443 1325.522583,3480.714844 1320.493652,3479.775879 
	C1315.362305,3478.817627 1315.306885,3477.809082 1319.384399,3473.953369 
	C1320.685425,3472.723145 1322.102295,3471.751221 1321.281006,3469.986816 
	C1320.328979,3467.940918 1318.406616,3469.435547 1316.912354,3469.252197 
	C1312.549316,3468.716553 1311.898926,3467.054688 1314.613647,3463.538330 
	C1316.403564,3461.219971 1317.865356,3458.359131 1322.084229,3457.916016 
z"
        />
        <path
          fill="#E1D9C6"
          opacity="1.000000"
          stroke="none"
          d="
M1547.989258,3298.515137 
	C1548.043457,3297.708984 1548.006348,3297.216064 1548.194336,3297.105469 
	C1554.769653,3293.239502 1554.152100,3287.606445 1552.029419,3280.817383 
	C1555.440063,3276.013428 1560.503418,3273.443115 1562.998779,3267.628418 
	C1564.524780,3266.032227 1565.783936,3265.274414 1567.871582,3264.437012 
	C1571.778320,3263.552490 1574.681641,3264.369873 1578.084961,3266.260986 
	C1585.489990,3274.365479 1593.198975,3267.516602 1600.263794,3267.395996 
	C1606.910767,3267.282471 1613.202026,3265.853760 1619.443970,3269.380371 
	C1622.440918,3271.073486 1626.059204,3271.082520 1629.107056,3269.492432 
	C1634.812622,3266.516113 1640.881348,3265.390869 1647.256104,3265.554688 
	C1647.408813,3261.086182 1644.664673,3258.964844 1642.245483,3256.839844 
	C1636.573853,3251.858154 1638.083984,3247.780762 1643.520630,3244.007812 
	C1645.432617,3242.680664 1647.309937,3241.248535 1649.382690,3240.226318 
	C1657.041748,3236.449951 1662.858643,3232.986816 1657.270386,3222.312500 
	C1653.299927,3214.728760 1662.366577,3212.391846 1667.962524,3208.377686 
	C1660.462402,3206.187988 1656.805786,3206.899658 1656.075073,3215.113770 
	C1655.154907,3225.459229 1645.840454,3230.828857 1639.741211,3237.940186 
	C1637.452393,3240.608887 1635.646362,3242.489502 1635.690186,3246.240479 
	C1635.861084,3260.913818 1626.607788,3267.882080 1612.619019,3263.862793 
	C1610.068359,3263.129639 1607.428345,3262.670654 1604.393555,3261.178711 
	C1601.924194,3259.233887 1599.957275,3256.562012 1601.057861,3254.905029 
	C1603.868164,3250.674805 1601.769897,3249.787598 1598.400146,3247.980957 
	C1595.462036,3246.406006 1593.038818,3242.783447 1596.275269,3239.787109 
	C1603.996826,3232.638916 1603.604614,3222.546875 1605.327515,3213.788818 
	C1608.131592,3199.535645 1614.252930,3193.852295 1629.548096,3196.712646 
	C1632.518433,3199.431641 1632.329712,3202.441406 1632.441772,3205.443115 
	C1632.519897,3207.534912 1631.251099,3210.607422 1634.753174,3211.026123 
	C1637.351440,3211.336670 1639.258545,3209.296631 1639.984985,3207.113281 
	C1643.371094,3196.936279 1650.815308,3194.921143 1660.443359,3195.668457 
	C1662.505493,3195.828857 1669.974487,3198.041992 1665.946411,3189.733398 
	C1664.488159,3183.463623 1658.820312,3184.298584 1655.018555,3181.351074 
	C1654.920654,3172.218994 1654.820801,3163.574951 1665.725708,3159.998535 
	C1669.458862,3160.251709 1672.424438,3160.810059 1675.226562,3161.810303 
	C1680.592896,3163.726074 1682.890503,3167.057617 1679.266846,3172.464111 
	C1677.388550,3175.266357 1675.204346,3178.101318 1677.343506,3181.558350 
	C1678.514282,3183.450439 1680.715210,3183.480713 1682.738159,3182.746094 
	C1686.757812,3181.286133 1689.714478,3179.016113 1689.709351,3174.163818 
	C1689.702881,3167.994873 1690.927002,3162.224854 1696.115479,3158.101318 
	C1697.465698,3157.028320 1699.063232,3156.621826 1701.458008,3156.983154 
	C1707.729492,3166.761230 1706.835693,3177.011230 1704.798950,3187.428223 
	C1709.477417,3187.580811 1710.880249,3184.266602 1712.393188,3181.488525 
	C1717.772705,3171.611572 1727.058716,3164.330566 1730.838867,3153.359131 
	C1731.683350,3150.908447 1734.001343,3149.139404 1737.303589,3148.076416 
	C1738.802979,3147.417969 1740.232300,3146.912354 1740.314209,3146.242188 
	C1741.397705,3137.365234 1747.706787,3137.576416 1754.239502,3137.969727 
	C1756.557495,3138.109619 1758.891235,3137.999268 1761.217651,3137.984375 
	C1765.668701,3137.955566 1769.923706,3137.529297 1772.119507,3132.759766 
	C1773.403931,3129.969727 1775.571655,3129.646973 1777.763428,3131.367920 
	C1780.256470,3133.325195 1780.726440,3136.325195 1778.174438,3138.175781 
	C1774.925049,3140.531982 1772.230103,3144.599365 1766.901733,3142.709229 
	C1762.322021,3141.084717 1758.832886,3141.695801 1757.469116,3147.778564 
	C1756.320068,3152.903564 1750.541016,3153.390869 1746.525635,3155.489258 
	C1744.599976,3156.495361 1741.453247,3155.378906 1740.705688,3158.349854 
	C1739.853271,3161.738037 1743.189941,3161.588623 1744.932007,3162.789062 
	C1745.731567,3163.340088 1746.756470,3163.993896 1747.026733,3164.815430 
	C1749.051636,3170.974365 1753.260254,3170.205322 1758.221558,3169.013672 
	C1767.738647,3166.727539 1773.036865,3171.385742 1773.340698,3181.285400 
	C1773.480469,3185.837158 1768.355835,3184.984131 1767.227295,3187.706299 
	C1767.436035,3189.211426 1768.314697,3189.542236 1769.282837,3189.848145 
	C1770.759155,3190.314941 1772.956665,3190.126953 1772.837402,3192.447021 
	C1772.710938,3194.909424 1770.633789,3195.736084 1768.621216,3195.784912 
	C1762.417969,3195.935547 1756.208618,3195.839844 1749.938110,3195.839844 
	C1750.139526,3190.288086 1757.042114,3193.592773 1756.881104,3189.886719 
	C1756.715698,3186.081543 1757.434937,3183.635010 1762.148926,3182.522217 
	C1755.131470,3177.168945 1749.307373,3177.164795 1743.317505,3181.351074 
	C1743.382080,3185.114990 1748.348999,3182.934814 1748.806519,3186.887939 
	C1737.127808,3188.728271 1727.623657,3196.924072 1714.771362,3197.897461 
	C1709.784912,3199.030029 1705.009888,3199.543945 1706.894165,3206.010010 
	C1707.639038,3208.566650 1705.976074,3209.100342 1704.004028,3210.107422 
	C1698.522461,3212.906982 1695.522705,3218.507080 1695.736572,3225.389160 
	C1689.416382,3231.511963 1683.654907,3237.240967 1687.307495,3247.112061 
	C1688.993652,3251.668213 1686.900635,3254.178711 1681.992188,3253.965820 
	C1678.383301,3253.809570 1675.512207,3255.146973 1673.208496,3257.840332 
	C1672.159546,3259.067383 1670.604614,3260.152100 1671.140137,3262.053467 
	C1671.730225,3264.148926 1673.674927,3263.832520 1675.263428,3263.958008 
	C1679.326538,3264.278564 1683.238037,3263.824219 1686.773071,3261.544678 
	C1688.443481,3260.467773 1690.060791,3259.195557 1691.888672,3258.506348 
	C1693.768188,3257.797607 1695.995850,3257.763184 1697.188599,3259.843506 
	C1698.103027,3261.438721 1697.595459,3263.311279 1696.151978,3264.311768 
	C1692.763916,3266.659668 1692.056885,3269.875244 1691.735718,3273.747803 
	C1691.440063,3277.314453 1687.403687,3279.207764 1684.358398,3277.483887 
	C1680.585938,3275.348633 1683.258423,3273.145020 1684.633911,3270.977783 
	C1684.891846,3270.571533 1684.549927,3269.784424 1684.432983,3268.688477 
	C1680.072510,3267.841553 1676.965332,3270.298584 1674.091553,3272.975830 
	C1671.419312,3275.465332 1668.559937,3276.587891 1665.006348,3275.385254 
	C1660.550293,3273.877441 1656.843628,3275.861572 1652.735474,3277.317139 
	C1658.969971,3286.127930 1659.043213,3286.264648 1666.584106,3282.220459 
	C1668.826172,3281.018066 1670.630005,3280.146484 1672.982666,3281.602783 
	C1674.480835,3282.530029 1675.761597,3283.560547 1675.425171,3285.483643 
	C1675.169434,3286.946289 1673.948608,3287.538086 1672.580444,3287.868408 
	C1667.122803,3289.186768 1661.814453,3291.149658 1656.070557,3288.421387 
	C1653.192505,3287.053955 1649.975098,3285.853271 1646.466919,3287.473633 
	C1644.744141,3288.269287 1642.251343,3288.533691 1641.149414,3286.392822 
	C1640.107422,3284.368896 1641.722534,3282.575195 1642.953979,3281.055664 
	C1644.211182,3279.503906 1646.044922,3278.115723 1644.358032,3275.977783 
	C1642.728516,3273.912354 1640.308472,3273.749512 1637.965332,3274.441895 
	C1636.734131,3274.805664 1635.656128,3275.704346 1634.525635,3276.389160 
	C1630.203735,3279.007568 1626.934448,3281.430420 1620.410889,3279.531738 
	C1614.945679,3277.941162 1607.408813,3279.460205 1600.858521,3280.394287 
	C1599.458374,3280.594238 1598.603027,3281.673584 1598.671021,3283.228760 
	C1598.743530,3284.890381 1599.996094,3285.404297 1601.289429,3285.769775 
	C1604.496216,3286.676270 1607.655518,3284.645752 1610.877319,3284.908936 
	C1611.306152,3288.597656 1610.073486,3291.276367 1607.200073,3291.572266 
	C1601.702881,3292.137939 1597.383301,3296.374268 1590.323975,3293.281250 
	C1584.647339,3290.793945 1576.156738,3293.332520 1570.313110,3300.419189 
	C1576.053589,3302.896973 1581.069458,3302.249268 1586.147827,3300.959473 
	C1593.372070,3299.124512 1600.716064,3300.187500 1608.010498,3300.169678 
	C1609.748901,3300.165283 1611.258057,3300.955322 1611.361572,3303.052002 
	C1611.441528,3304.670410 1610.288208,3305.459717 1609.004883,3305.851074 
	C1604.035767,3307.366455 1599.539185,3310.107178 1593.689331,3308.455811 
	C1586.787598,3306.507324 1579.856934,3309.623535 1573.146606,3310.950684 
	C1566.935181,3312.178711 1561.138794,3314.091553 1554.771484,3312.126465 
	C1553.044800,3311.593506 1550.870972,3311.782227 1548.442505,3314.267822 
	C1553.893799,3316.661621 1559.005371,3318.472900 1563.617432,3321.266602 
	C1566.379517,3322.940186 1570.332397,3322.776611 1573.256226,3321.002441 
	C1578.308105,3317.936768 1584.561646,3318.938232 1589.543091,3315.385742 
	C1592.715454,3313.123047 1596.557861,3313.223145 1598.738037,3317.680664 
	C1600.624756,3321.537598 1600.294312,3324.703857 1596.282104,3327.006348 
	C1590.465454,3330.345459 1588.022705,3337.316406 1581.932007,3340.421631 
	C1581.182617,3340.803711 1580.312744,3342.055908 1580.397827,3342.802246 
	C1581.605103,3353.406982 1571.593506,3355.878906 1566.602051,3361.950684 
	C1564.917603,3363.999756 1562.524292,3364.358643 1560.317017,3362.855957 
	C1555.018799,3359.249023 1550.037964,3355.095703 1542.927734,3354.500244 
	C1547.134766,3350.979980 1551.484863,3352.413574 1555.542480,3351.989746 
	C1557.439941,3351.791748 1560.863770,3352.257812 1560.630249,3350.261719 
	C1559.771606,3342.923340 1564.626587,3344.120605 1568.819458,3343.954590 
	C1570.850586,3343.873779 1573.209961,3343.725342 1573.358765,3341.153076 
	C1573.511108,3338.517822 1571.187988,3338.281250 1569.151855,3338.001221 
	C1567.881104,3337.826172 1566.636841,3337.456543 1564.477051,3336.971436 
	C1567.593384,3332.763672 1571.898438,3332.457520 1575.185425,3333.056641 
	C1580.459595,3334.018311 1582.323364,3330.591797 1584.829834,3327.658691 
	C1585.790649,3326.533936 1585.540771,3325.127686 1584.489868,3324.068604 
	C1583.314453,3322.884277 1582.308716,3323.438721 1581.060669,3324.288086 
	C1576.648804,3327.290283 1562.635010,3330.035645 1557.608154,3328.807617 
	C1554.619629,3328.077393 1551.605835,3327.390137 1548.869751,3328.928467 
	C1546.670654,3330.164307 1543.537476,3331.967285 1545.439453,3335.036865 
	C1548.593628,3340.126953 1545.428589,3340.387207 1542.122070,3339.517578 
	C1539.020142,3338.702393 1536.292847,3338.732178 1532.715820,3339.913818 
	C1532.353027,3338.900879 1532.701172,3337.802002 1533.517334,3336.346680 
	C1534.847412,3335.196045 1535.581177,3334.314941 1535.959229,3332.577637 
	C1536.016846,3331.113281 1536.017822,3330.226562 1536.023682,3328.674805 
	C1537.090942,3326.943359 1538.138184,3325.861816 1539.580322,3324.376953 
	C1540.544434,3320.213135 1542.486572,3316.720215 1542.054443,3312.753174 
	C1541.781006,3310.243408 1543.252563,3308.565674 1545.567627,3306.585449 
	C1548.403198,3304.144043 1551.154419,3303.489990 1554.284058,3303.249512 
	C1552.448608,3302.636963 1550.528809,3302.204346 1548.535400,3300.461426 
	C1548.029297,3299.990234 1548.002930,3299.006836 1547.989258,3298.515137 
z"
        />
        <path
          fill="#E5DDC9"
          opacity="1.000000"
          stroke="none"
          d="
M1324.026123,3751.933350 
	C1328.610229,3752.051270 1330.634399,3754.010010 1329.601929,3757.667236 
	C1328.596069,3761.229736 1328.444580,3764.016357 1331.925171,3766.280273 
	C1334.229736,3767.780029 1334.251465,3770.261719 1333.326172,3772.531982 
	C1332.153687,3775.408936 1329.637573,3776.137939 1326.754517,3775.758301 
	C1325.777832,3775.629395 1324.843140,3775.200928 1323.867554,3775.044922 
	C1320.211670,3774.460205 1319.264282,3776.625000 1321.236084,3778.936279 
	C1324.679199,3782.971924 1322.561157,3784.726562 1319.129883,3786.381836 
	C1315.811279,3787.982910 1312.992920,3790.181152 1310.233887,3792.634521 
	C1304.900879,3797.377197 1301.697388,3796.958740 1296.738892,3791.732178 
	C1294.392334,3789.258789 1293.050171,3786.221680 1291.623535,3783.251465 
	C1289.834595,3779.526611 1285.603027,3778.667480 1282.276733,3775.882080 
	C1286.911499,3772.668701 1290.656494,3777.511719 1294.304810,3775.580078 
	C1297.993408,3773.627197 1301.210449,3774.323486 1304.415649,3776.813477 
	C1305.962891,3778.015625 1308.755859,3776.820312 1308.533203,3775.794434 
	C1307.434937,3770.732178 1312.028198,3767.057373 1311.108887,3761.978271 
	C1309.000854,3750.331055 1311.109619,3748.733398 1324.026123,3751.933350 
z"
        />
        <path
          fill="#E5DDC9"
          opacity="1.000000"
          stroke="none"
          d="
M1315.325317,3731.770020 
	C1316.907593,3731.155273 1317.827515,3730.751221 1318.623413,3730.175781 
	C1325.287964,3725.354980 1328.413696,3726.236328 1331.940674,3733.849121 
	C1333.094849,3736.340088 1334.185913,3738.658691 1331.797607,3741.094971 
	C1329.206421,3743.738525 1326.203491,3744.892334 1322.856445,3743.086426 
	C1319.668823,3741.366699 1316.825928,3739.034668 1313.237915,3738.034180 
	C1310.995972,3737.409180 1308.258057,3736.595703 1309.307739,3733.569092 
	C1310.043091,3731.448730 1312.718262,3732.180908 1315.325317,3731.770020 
z"
        />
        <path
          fill="#E5DDC9"
          opacity="1.000000"
          stroke="none"
          d="
M1499.302490,3580.080078 
	C1500.064453,3583.179199 1499.592041,3585.950439 1494.742920,3585.976562 
	C1489.852539,3582.152100 1483.880249,3585.261230 1478.739502,3582.118652 
	C1478.672241,3575.400391 1482.689087,3576.392334 1487.058594,3578.015137 
	C1490.774170,3579.395508 1494.066650,3583.175537 1499.302490,3580.080078 
z"
        />
        <path
          fill="#E5DDC9"
          opacity="1.000000"
          stroke="none"
          d="
M1547.962402,3300.627441 
	C1551.627319,3300.098389 1555.242920,3300.027832 1558.817383,3300.376953 
	C1561.215210,3300.610840 1561.926880,3302.593262 1561.637451,3304.733154 
	C1561.302490,3307.209717 1559.441772,3308.071289 1557.345703,3307.354980 
	C1554.170898,3306.270020 1550.981689,3305.888184 1546.850586,3306.007324 
	C1546.645264,3304.427979 1547.270386,3302.846436 1547.962402,3300.627441 
z"
        />
        <path
          fill="#959693"
          opacity="1.000000"
          stroke="none"
          d="
M1312.447510,3404.589355 
	C1316.115479,3404.028809 1319.703247,3403.914062 1323.233643,3404.310303 
	C1325.603760,3404.575928 1327.749512,3405.906250 1326.988525,3408.828369 
	C1326.415894,3411.028320 1324.586182,3411.575684 1322.364258,3411.098145 
	C1320.165527,3410.625000 1317.921631,3410.361328 1314.943359,3409.992188 
	C1312.523071,3408.813477 1312.367432,3407.058594 1312.447510,3404.589355 
z"
        />
        <path
          fill="#F0EBDD"
          opacity="1.000000"
          stroke="none"
          d="
M1294.594238,3802.911133 
	C1292.900513,3801.420898 1291.655396,3800.304199 1290.795288,3798.944336 
	C1290.088745,3797.827637 1289.963501,3796.345459 1291.174561,3795.370117 
	C1292.404419,3794.379150 1293.704834,3794.900391 1294.642578,3795.900635 
	C1296.734497,3798.132324 1300.213501,3800.358887 1294.594238,3802.911133 
z"
        />
        <path
          fill="#E5DDC9"
          opacity="1.000000"
          stroke="none"
          d="
M1518.782959,3508.749512 
	C1521.210205,3508.502441 1523.855591,3507.960449 1522.328369,3511.137207 
	C1521.770142,3512.298584 1519.782959,3512.899414 1518.334717,3513.467773 
	C1517.105225,3513.950195 1515.582153,3513.690674 1515.273804,3512.234131 
	C1514.832886,3510.150879 1516.957275,3509.878174 1518.782959,3508.749512 
z"
        />
        <path
          fill="#E5DDC9"
          opacity="1.000000"
          stroke="none"
          d="
M1532.088745,3310.323730 
	C1532.595215,3308.860352 1533.001099,3307.475098 1533.555176,3307.413086 
	C1535.934326,3307.147217 1535.843140,3309.150391 1535.882568,3310.675781 
	C1535.921631,3312.191895 1536.239014,3314.375488 1534.157471,3314.559814 
	C1531.835205,3314.764893 1532.344360,3312.468994 1532.088745,3310.323730 
z"
        />
        <path
          fill="#E5DDC9"
          opacity="1.000000"
          stroke="none"
          d="
M1516.246338,3352.906250 
	C1514.857666,3358.021240 1512.038208,3355.679932 1508.268066,3354.659180 
	C1510.835693,3351.512695 1513.289429,3351.983398 1516.246338,3352.906250 
z"
        />
        <path
          fill="#959693"
          opacity="1.000000"
          stroke="none"
          d="
M1318.728882,3438.585205 
	C1321.766479,3438.863037 1322.504395,3440.591064 1320.540039,3443.581299 
	C1318.432983,3442.757568 1318.339600,3441.033691 1318.728882,3438.585205 
z"
        />
        <path
          fill="#F1E9D8"
          opacity="1.000000"
          stroke="none"
          d="
M1365.288330,3681.142822 
	C1366.774902,3680.726562 1367.314697,3681.246338 1367.084229,3682.401123 
	C1367.036377,3682.641113 1366.696289,3682.963379 1366.453735,3682.999756 
	C1365.286011,3683.174805 1364.799805,3682.605225 1365.288330,3681.142822 
z"
        />
        <path
          fill="#E5DDC9"
          opacity="1.000000"
          stroke="none"
          d="
M1539.349487,3324.083984 
	C1539.812622,3325.765869 1539.286743,3327.302002 1536.656006,3327.904053 
	C1536.194702,3326.221191 1536.728638,3324.691895 1539.349487,3324.083984 
z"
        />
        <path
          fill="#E5DDC9"
          opacity="1.000000"
          stroke="none"
          d="
M1535.544922,3332.353027 
	C1538.026733,3333.818604 1537.538086,3335.059082 1534.554810,3335.921875 
	C1534.348267,3334.895508 1534.711182,3333.800781 1535.544922,3332.353027 
z"
        />
        <path
          fill="#D0D1C8"
          opacity="1.000000"
          stroke="none"
          d="
M1213.397583,3391.421387 
	C1213.626831,3394.869141 1213.716797,3397.613525 1213.913086,3401.178711 
	C1213.976318,3404.657959 1214.028564,3407.322998 1213.848877,3409.972656 
	C1213.763184,3411.237061 1213.550293,3412.749756 1211.832031,3412.963867 
	C1210.285889,3413.156494 1209.238892,3412.124512 1208.881592,3410.865723 
	C1207.252319,3405.125977 1205.786499,3399.340332 1204.166382,3392.793945 
	C1204.802002,3384.193359 1202.723022,3376.835205 1200.260864,3369.563721 
	C1197.258545,3360.696777 1196.418091,3351.409668 1194.599609,3341.591309 
	C1197.845703,3340.178955 1199.101318,3342.262207 1199.819092,3345.063477 
	C1200.303223,3346.952881 1200.814819,3348.835449 1201.603516,3351.370605 
	C1203.950562,3353.875977 1204.629272,3356.104248 1203.483154,3359.471680 
	C1204.304810,3371.107422 1209.344971,3380.728271 1213.397583,3391.421387 
z"
        />
        <path
          fill="#8C8D89"
          opacity="1.000000"
          stroke="none"
          d="
M1203.911621,3359.919434 
	C1201.851196,3358.129883 1201.933838,3355.760254 1201.845947,3352.729004 
	C1208.474854,3354.072510 1208.789307,3354.968750 1203.911621,3359.919434 
z"
        />
        <path
          fill="#959693"
          opacity="1.000000"
          stroke="none"
          d="
M1305.943359,3285.343750 
	C1307.642822,3296.008057 1312.553711,3304.823486 1317.142456,3313.489746 
	C1324.580933,3327.538330 1332.170044,3341.508057 1339.457031,3355.646973 
	C1345.576660,3367.520996 1346.237427,3380.474121 1347.776489,3393.232666 
	C1348.532837,3399.502686 1349.399170,3405.749756 1350.945068,3411.809814 
	C1355.721069,3430.531250 1354.412109,3449.221680 1351.377441,3467.948486 
	C1351.184692,3469.138428 1350.051270,3470.176025 1348.695190,3471.672852 
	C1348.011597,3467.205566 1347.011597,3462.457031 1347.063232,3457.498047 
	C1347.118652,3452.167480 1340.362549,3449.254883 1335.635010,3446.338867 
	C1334.866211,3445.864746 1333.685425,3446.011963 1332.690552,3445.959717 
	C1329.376221,3445.785889 1327.725464,3444.108154 1328.335327,3440.788086 
	C1329.001587,3437.160156 1331.358887,3435.841797 1334.916260,3436.633545 
	C1336.529175,3436.992676 1338.112793,3437.517822 1339.740723,3437.761719 
	C1341.125610,3437.969238 1342.342896,3437.337402 1343.263794,3436.287354 
	C1346.234863,3432.897949 1348.408325,3418.497314 1345.188843,3415.475586 
	C1338.862183,3409.536621 1342.292236,3402.017822 1340.872925,3395.402344 
	C1340.293823,3392.702881 1340.828369,3389.772705 1338.821045,3387.320068 
	C1338.201782,3386.563232 1337.522095,3385.596436 1337.458374,3384.685059 
	C1336.850342,3375.986084 1330.533691,3370.622803 1325.455444,3364.738037 
	C1321.757935,3360.453369 1318.565796,3356.801514 1321.319214,3350.693604 
	C1322.530273,3348.007080 1320.599121,3346.467529 1317.828735,3345.991699 
	C1313.408691,3345.233154 1310.251099,3342.708740 1310.194702,3338.125000 
	C1310.128296,3332.719238 1306.992188,3329.649170 1303.264893,3326.691895 
	C1300.927002,3324.837158 1298.702148,3322.837402 1296.336304,3321.020264 
	C1292.808472,3318.311279 1291.111694,3315.056396 1292.771973,3310.600342 
	C1293.119385,3309.667725 1293.458496,3308.719727 1293.667969,3307.750000 
	C1294.481812,3303.983154 1292.828125,3301.173096 1289.807983,3299.261719 
	C1286.324463,3297.057129 1283.393921,3298.877197 1280.921143,3301.310791 
	C1279.864746,3302.350098 1279.332520,3303.922852 1278.442749,3305.459717 
	C1277.559082,3304.340820 1276.794800,3303.742920 1276.897827,3303.449219 
	C1279.047363,3297.317139 1276.107788,3296.127441 1270.593262,3295.869873 
	C1261.474854,3295.443604 1257.411499,3290.363770 1257.973877,3281.706299 
	C1258.166138,3278.747803 1258.860596,3276.524414 1262.052368,3275.737061 
	C1263.006104,3275.501953 1263.883911,3274.953613 1264.792114,3274.538330 
	C1268.525513,3272.831787 1271.034790,3270.285645 1269.221680,3265.947998 
	C1268.051025,3263.147461 1264.131592,3263.766113 1259.842285,3267.187012 
	C1253.665161,3272.112793 1253.502563,3272.351074 1246.776489,3267.752441 
	C1243.792480,3265.712646 1240.195312,3266.883545 1237.582031,3265.058838 
	C1236.575684,3263.545410 1237.268066,3262.469727 1237.817017,3261.234863 
	C1241.111694,3253.823730 1240.775635,3253.251465 1232.804565,3251.963135 
	C1223.134277,3250.399902 1221.236206,3248.324951 1219.891846,3238.090820 
	C1219.677612,3236.459717 1219.118652,3234.873535 1218.714722,3233.267578 
	C1216.607422,3224.888672 1216.680908,3225.166504 1208.272949,3227.408447 
	C1204.129150,3228.512939 1199.867554,3228.609375 1196.549438,3232.031006 
	C1194.617554,3234.023438 1191.534180,3232.740479 1190.058838,3231.520020 
	C1184.991211,3227.328369 1180.087036,3222.645508 1178.779419,3215.830566 
	C1178.323486,3213.454346 1180.467529,3210.250732 1177.078613,3208.921875 
	C1173.504150,3207.520020 1169.924805,3204.909912 1165.714478,3207.193848 
	C1165.130493,3207.510986 1164.473145,3207.698975 1163.906616,3208.041260 
	C1160.598389,3210.038574 1158.329712,3209.262695 1156.915283,3205.630615 
	C1155.644653,3202.367432 1156.868408,3200.693359 1160.036865,3199.712158 
	C1163.932617,3198.505615 1167.276367,3196.299072 1169.796387,3193.052734 
	C1173.496582,3188.286133 1172.314209,3184.630371 1166.988403,3182.105469 
	C1156.335938,3177.055908 1145.234863,3171.456299 1139.181519,3161.409912 
	C1134.667969,3153.919434 1130.765625,3147.173340 1123.689209,3141.429688 
	C1119.474243,3138.008545 1116.019043,3131.551514 1114.820068,3124.527832 
	C1113.245728,3115.307129 1108.843506,3106.936768 1098.343994,3104.028320 
	C1095.876831,3103.344971 1093.732788,3101.495117 1090.730713,3100.090332 
	C1086.759399,3098.950195 1084.567139,3096.903564 1083.800781,3092.650391 
	C1083.613159,3088.614014 1084.890747,3085.743896 1086.680420,3082.280762 
	C1087.564087,3081.391602 1087.976929,3081.106934 1089.098877,3080.605957 
	C1090.476685,3080.320312 1091.388184,3080.089355 1091.759033,3080.466309 
	C1101.324951,3090.199951 1114.605591,3095.561523 1122.867065,3106.736084 
	C1124.505615,3108.952393 1125.728271,3109.846680 1128.462402,3109.380859 
	C1136.318481,3108.042480 1143.227173,3108.849365 1145.467041,3118.346191 
	C1145.900757,3120.184570 1147.393677,3120.907227 1148.992432,3121.510254 
	C1157.272583,3124.633545 1162.321899,3129.624023 1161.249390,3139.495850 
	C1160.728760,3144.288818 1165.549927,3144.544434 1169.068481,3145.479980 
	C1181.541870,3148.795898 1194.610474,3150.198730 1206.627686,3157.274902 
	C1207.563110,3157.959473 1207.910645,3158.266113 1208.688110,3159.114258 
	C1211.115112,3163.486572 1211.726562,3168.351318 1217.342896,3170.291504 
	C1218.249146,3170.996338 1218.580200,3171.321289 1219.285889,3172.236816 
	C1221.106323,3178.845703 1223.946411,3183.912598 1229.555908,3187.000732 
	C1234.531372,3189.739990 1235.918335,3195.301758 1239.354614,3199.186035 
	C1242.577148,3202.828125 1246.204346,3206.403320 1252.060791,3207.672363 
	C1261.840210,3211.797363 1265.766479,3220.693848 1271.631958,3227.663086 
	C1283.747192,3242.058838 1295.474609,3256.766602 1303.026855,3274.256592 
	C1304.462646,3277.582031 1306.149902,3280.841797 1305.943359,3285.343750 
z"
        />
        <path
          fill="#797771"
          opacity="1.000000"
          stroke="none"
          d="
M1087.610352,3081.530762 
	C1088.278687,3085.018555 1087.097290,3087.750244 1086.106934,3091.274658 
	C1083.634644,3095.340332 1086.664917,3097.945068 1087.583008,3101.459229 
	C1089.240234,3104.255859 1090.522461,3106.482910 1091.872314,3109.354736 
	C1093.787231,3118.306885 1096.576416,3126.364502 1098.236328,3135.328857 
	C1097.994995,3136.333252 1097.993286,3136.666748 1097.994751,3137.500000 
	C1101.742188,3149.396240 1103.045776,3161.277100 1104.468994,3173.521729 
	C1100.927612,3172.703369 1099.505859,3169.933594 1098.719238,3167.103027 
	C1095.894653,3156.939697 1088.259766,3149.878174 1082.527344,3141.623779 
	C1079.297363,3136.972656 1078.387573,3131.547363 1081.158447,3127.707275 
	C1084.744507,3122.737793 1083.059692,3118.730469 1081.093872,3114.878174 
	C1078.721191,3110.228760 1078.457764,3105.635254 1078.332520,3100.299316 
	C1076.799683,3098.752441 1076.894409,3097.543457 1077.935669,3095.819824 
	C1079.030396,3094.954102 1080.182739,3095.025635 1080.376343,3094.385254 
	C1080.755249,3093.131592 1080.796753,3094.587646 1079.198608,3093.625488 
	C1078.008179,3089.616943 1080.480591,3087.198975 1081.901123,3083.757324 
	C1082.828979,3082.438232 1083.536011,3081.865234 1085.033203,3081.255371 
	C1086.163940,3081.069336 1086.626831,3081.070312 1087.610352,3081.530762 
z"
        />
        <path
          fill="#83837F"
          opacity="1.000000"
          stroke="none"
          d="
M1098.525635,3135.478516 
	C1092.471802,3128.567627 1091.085083,3119.965332 1091.435791,3110.506836 
	C1095.238892,3112.604980 1095.964111,3117.316162 1099.957642,3119.476807 
	C1101.742798,3120.443115 1101.944458,3122.660400 1101.461182,3124.701904 
	C1100.652222,3128.118896 1099.856079,3131.538574 1098.525635,3135.478516 
z"
        />
        <path
          fill="#959693"
          opacity="1.000000"
          stroke="none"
          d="
M1324.294922,3423.521484 
	C1324.160767,3419.573242 1325.281738,3417.533936 1328.910278,3418.822998 
	C1330.087646,3419.241455 1331.140137,3420.217529 1332.053345,3421.134277 
	C1334.633545,3423.723877 1337.938599,3426.681152 1334.109985,3430.221191 
	C1329.695679,3434.302979 1328.065063,3429.332275 1325.906860,3426.793213 
	C1325.280640,3426.056641 1324.913330,3425.099854 1324.294922,3423.521484 
z"
        />
        <path
          fill="#959693"
          opacity="1.000000"
          stroke="none"
          d="
M1208.790527,3244.193359 
	C1204.673950,3247.003418 1201.190430,3243.064453 1197.201660,3243.883301 
	C1195.296265,3244.274658 1193.083130,3243.761963 1192.522461,3241.298096 
	C1192.224243,3239.987549 1192.729980,3238.700195 1193.776978,3237.728516 
	C1195.783569,3235.866211 1197.902100,3235.906250 1200.070923,3237.397705 
	C1203.023438,3239.428467 1205.956543,3241.487061 1208.790527,3244.193359 
z"
        />
        <path
          fill="#83837F"
          opacity="1.000000"
          stroke="none"
          d="
M1088.205566,3101.600586 
	C1082.295654,3100.562988 1083.900146,3096.686768 1085.476807,3092.495117 
	C1085.933716,3094.882568 1087.903320,3096.747559 1089.722656,3099.418457 
	C1089.386841,3100.240479 1088.864136,3100.631592 1088.205566,3101.600586 
z"
        />
        <path
          fill="#8C8D89"
          opacity="1.000000"
          stroke="none"
          d="
M1083.438599,3083.556885 
	C1084.194458,3087.561768 1080.767456,3089.465576 1080.081421,3093.327881 
	C1080.231445,3094.376709 1080.456543,3094.743408 1080.208252,3095.549316 
	C1079.151367,3096.904053 1078.567993,3097.819824 1077.914307,3099.291992 
	C1076.116699,3101.573486 1074.389648,3103.298340 1072.271729,3105.501953 
	C1067.326904,3105.978271 1062.732910,3106.733398 1060.771240,3100.567871 
	C1059.891968,3097.804199 1056.622925,3097.546143 1054.027344,3099.322510 
	C1051.300415,3101.188721 1048.632812,3103.104980 1044.571045,3103.800293 
	C1045.446899,3098.307617 1052.562500,3097.297363 1054.006226,3090.885254 
	C1056.835449,3090.128174 1059.549316,3090.031738 1063.079956,3089.910156 
	C1069.697998,3086.206299 1076.084106,3084.152344 1083.438599,3083.556885 
z"
        />
        <path
          fill="#F9B783"
          opacity="1.000000"
          stroke="none"
          d="
M630.017578,3260.024902 
	C629.796936,3259.902344 629.946777,3261.336670 629.708130,3260.076660 
	C629.953613,3259.963867 629.999817,3260.001221 630.017578,3260.024902 
z"
        />
        <path
          fill="#728DA3"
          opacity="1.000000"
          stroke="none"
          d="
M1640.307251,2087.280273 
	C1640.084961,2083.387695 1639.790161,2080.464600 1642.679321,2079.158691 
	C1645.866577,2077.717773 1647.556885,2080.389404 1648.963257,2082.417236 
	C1650.870605,2085.167969 1653.255737,2086.049072 1656.440430,2086.055908 
	C1658.540405,2086.060303 1661.062134,2085.161865 1662.686157,2087.686768 
	C1662.005127,2091.539551 1658.051514,2088.788574 1656.124756,2091.171387 
	C1658.440552,2092.782471 1661.958740,2091.198242 1664.041626,2094.502686 
	C1664.003296,2095.703125 1663.471191,2095.970703 1662.365234,2095.876465 
	C1659.021973,2094.289062 1655.971680,2095.141113 1652.474121,2095.592285 
	C1649.832397,2096.031494 1647.692017,2096.052734 1644.768066,2095.887695 
	C1641.706543,2093.870117 1641.338257,2091.138916 1640.307251,2087.280273 
z"
        />
        <path
          fill="#313E41"
          opacity="1.000000"
          stroke="none"
          d="
M1614.759766,2130.030762 
	C1614.033325,2128.489746 1614.039185,2126.999268 1614.044434,2124.763184 
	C1618.388794,2125.695801 1621.623779,2120.342041 1626.658203,2122.116699 
	C1628.777344,2124.635010 1630.960449,2126.809814 1631.232056,2129.876709 
	C1625.990845,2135.904053 1620.731201,2131.755859 1614.759766,2130.030762 
z"
        />
        <path
          fill="#728DA3"
          opacity="1.000000"
          stroke="none"
          d="
M1626.675415,2104.158691 
	C1624.492920,2103.668945 1622.359985,2103.560303 1623.317993,2101.245361 
	C1624.030029,2099.524902 1627.880859,2099.506592 1627.395264,2097.684326 
	C1624.650146,2087.380371 1632.456543,2087.745605 1639.226685,2086.087402 
	C1639.990234,2085.990234 1640.000000,2086.000000 1640.004883,2086.004883 
	C1642.778931,2090.271484 1638.787720,2091.639160 1636.476807,2094.602783 
	C1635.268311,2097.531738 1629.547729,2096.759033 1632.641113,2101.788818 
	C1633.241455,2102.764893 1629.758057,2104.590576 1626.675415,2104.158691 
z"
        />
        <path
          fill="#8497A7"
          opacity="1.000000"
          stroke="none"
          d="
M1636.917236,2095.460938 
	C1635.445068,2091.883545 1638.618896,2090.111816 1639.871338,2086.728027 
	C1645.229736,2086.838623 1643.613770,2090.989014 1643.981323,2094.939453 
	C1641.840210,2095.643066 1639.696045,2095.584473 1636.917236,2095.460938 
z"
        />
        <path
          fill="#8497A7"
          opacity="1.000000"
          stroke="none"
          d="
M1652.776611,2096.265625 
	C1654.625488,2093.153076 1657.729004,2093.707275 1661.412842,2095.298096 
	C1659.138916,2096.017822 1656.359741,2096.269287 1652.776611,2096.265625 
z"
        />
        <path
          fill="#040407"
          opacity="1.000000"
          stroke="none"
          d="
M1718.876709,2153.916504 
	C1719.634033,2152.519531 1720.395508,2152.511475 1721.068970,2153.501953 
	C1721.210327,2153.709717 1721.205444,2154.188477 1721.058838,2154.390625 
	C1720.357544,2155.357178 1719.595215,2155.328369 1718.876709,2153.916504 
z"
        />
        <path
          fill="#728DA3"
          opacity="1.000000"
          stroke="none"
          d="
M1609.808350,2110.694092 
	C1611.378784,2110.137207 1612.585449,2111.036133 1611.911987,2112.186523 
	C1610.943970,2113.839844 1610.169312,2112.365234 1609.808350,2110.694092 
z"
        />
        <path
          fill="#728DA3"
          opacity="1.000000"
          stroke="none"
          d="
M1624.522217,2107.453857 
	C1624.242310,2106.990234 1624.500610,2106.651611 1625.125610,2106.225098 
	C1625.403320,2106.068848 1625.667114,2106.933594 1625.667114,2106.933594 
	C1625.667114,2106.933594 1624.971802,2107.510986 1624.522217,2107.453857 
z"
        />
        <path
          fill="#728DA3"
          opacity="1.000000"
          stroke="none"
          d="
M1609.799194,2110.197998 
	C1609.324219,2109.941895 1608.576538,2109.544922 1608.617310,2109.261719 
	C1608.743774,2108.383057 1609.265747,2108.352295 1609.913574,2109.518555 
	C1610.059692,2109.928223 1609.887207,2110.109131 1609.799194,2110.197998 
z"
        />
        <path
          fill="#50494B"
          opacity="1.000000"
          stroke="none"
          d="
M1740.502930,2230.217773 
	C1739.423828,2226.511963 1741.130371,2224.455811 1744.016235,2223.097900 
	C1748.823730,2220.835938 1752.188477,2217.046143 1755.639771,2212.398193 
	C1758.409790,2213.989746 1757.512695,2216.067139 1756.888184,2218.341309 
	C1755.105103,2224.834473 1748.536377,2227.252197 1745.101196,2232.368408 
	C1744.363525,2233.467041 1742.375488,2231.315918 1740.502930,2230.217773 
z"
        />
        <path
          fill="#281C18"
          opacity="1.000000"
          stroke="none"
          d="
M1545.489990,2298.520996 
	C1549.514282,2291.194092 1552.180908,2284.141602 1551.400024,2275.788330 
	C1550.591553,2267.138184 1552.525024,2266.027344 1561.789551,2267.535889 
	C1563.751709,2265.080078 1559.788452,2263.637207 1560.643433,2261.388428 
	C1562.410278,2256.740723 1560.404541,2254.558350 1555.823486,2253.927246 
	C1551.232544,2253.294189 1551.839722,2248.618896 1548.914307,2246.377441 
	C1554.194092,2242.361816 1558.367554,2244.292969 1563.249146,2246.908936 
	C1563.476685,2242.023438 1561.333740,2236.878174 1565.823730,2232.631836 
	C1565.928467,2231.944092 1565.960815,2231.954834 1565.953735,2231.941406 
	C1569.439819,2227.529297 1574.663086,2230.231201 1579.431152,2228.376709 
	C1584.964355,2228.385254 1587.759888,2231.925049 1589.487793,2235.822998 
	C1590.769287,2238.713867 1593.043213,2240.566162 1594.626343,2243.053467 
	C1595.557617,2244.516602 1597.471191,2246.125244 1598.681274,2245.183350 
	C1603.882446,2241.135498 1609.427490,2245.535156 1615.302368,2244.063721 
	C1617.183838,2243.991455 1618.281372,2243.621338 1619.678101,2242.468994 
	C1622.223633,2242.071289 1624.326172,2242.064697 1627.216797,2242.032715 
	C1631.960938,2237.420166 1636.888062,2243.871582 1641.557129,2240.479736 
	C1643.671509,2239.430908 1645.212036,2238.767578 1647.378662,2238.024170 
	C1649.662964,2237.523682 1650.205078,2235.984619 1651.516357,2234.411133 
	C1651.905151,2233.108887 1651.906372,2232.223145 1651.896362,2230.673340 
	C1649.513184,2227.083008 1649.106323,2224.021729 1651.915527,2220.315430 
	C1653.687622,2219.936035 1654.604126,2220.395508 1655.728027,2221.893311 
	C1658.470215,2227.826172 1658.275146,2233.861084 1661.460449,2239.581543 
	C1662.312012,2241.741211 1662.823364,2243.893066 1664.865845,2243.122559 
	C1668.691284,2241.679688 1673.393677,2240.997559 1674.153320,2235.595703 
	C1674.395020,2233.876465 1675.224609,2232.260986 1677.220215,2232.524902 
	C1679.798828,2232.865967 1679.813965,2235.051270 1679.760742,2237.065674 
	C1679.718628,2238.659668 1679.335327,2240.145752 1679.885254,2241.850586 
	C1683.553467,2253.221924 1688.067383,2255.624756 1701.389404,2252.302734 
	C1706.788452,2251.472412 1710.871216,2253.106445 1715.428223,2255.758301 
	C1714.618530,2262.495850 1707.664307,2257.101562 1705.099731,2261.173340 
	C1699.220825,2257.998047 1696.039795,2262.273682 1692.499390,2265.681641 
	C1691.319824,2266.816895 1689.946411,2267.750977 1688.314087,2269.370361 
	C1685.862671,2274.769287 1680.748779,2275.807129 1676.427002,2278.963135 
	C1674.831543,2279.509521 1673.878296,2279.620361 1672.202759,2279.438965 
	C1665.043823,2276.286865 1665.957886,2271.253174 1667.577881,2265.797363 
	C1670.726318,2255.194580 1669.018311,2254.321533 1658.331787,2254.055420 
	C1643.750366,2253.693359 1628.756836,2259.386475 1614.513550,2251.559082 
	C1611.047485,2249.654541 1609.158447,2252.656738 1607.235107,2255.966309 
	C1605.919556,2257.008057 1605.040405,2257.375732 1603.383911,2257.646484 
	C1592.889160,2254.423584 1582.492554,2255.125244 1572.045898,2252.228271 
	C1572.157593,2259.753174 1578.558350,2261.846924 1581.669800,2266.130615 
	C1584.989990,2270.701904 1585.025635,2273.794434 1579.486328,2276.956787 
	C1577.990234,2277.477783 1577.106812,2277.607666 1575.564453,2277.517578 
	C1573.350586,2277.048828 1571.915649,2276.155518 1569.643799,2276.205078 
	C1567.850708,2276.423584 1566.653076,2276.605713 1564.843262,2276.903564 
	C1558.366699,2279.811768 1564.083984,2284.820312 1561.934448,2289.010010 
	C1561.020386,2292.236572 1559.742432,2294.618896 1558.831665,2297.854004 
	C1558.509399,2299.399414 1558.236816,2300.254395 1557.575684,2301.671875 
	C1555.602783,2304.514893 1553.361450,2305.395264 1549.656738,2305.094238 
	C1548.004395,2305.336670 1547.796997,2305.790039 1548.234131,2307.015869 
	C1552.224976,2320.183594 1543.631714,2324.928223 1534.109497,2329.871582 
	C1532.419434,2331.674316 1531.037109,2332.815674 1528.970947,2334.175293 
	C1525.450684,2334.951416 1524.463989,2332.789062 1523.074585,2330.171387 
	C1522.657104,2329.112305 1522.518188,2328.677246 1522.261230,2327.565430 
	C1522.055786,2325.486816 1522.306396,2324.156738 1523.497681,2322.459473 
	C1531.324463,2318.963867 1535.078735,2313.314453 1536.026123,2304.841309 
	C1535.768311,2303.725342 1535.506348,2303.372314 1535.695557,2302.538818 
	C1539.483887,2303.048096 1542.069824,2302.406982 1543.795776,2298.499512 
	C1544.314087,2298.225586 1545.097534,2298.814697 1545.489990,2298.520996 
z"
        />
        <path
          fill="#131416"
          opacity="1.000000"
          stroke="none"
          d="
M1560.674927,2200.266602 
	C1559.197754,2195.314453 1555.475098,2196.229004 1552.302856,2196.003174 
	C1543.394043,2195.369629 1543.118164,2195.017578 1546.712036,2187.249512 
	C1548.221313,2183.987305 1548.486572,2181.092285 1546.156250,2178.115234 
	C1543.282471,2174.443848 1547.078003,2173.918701 1549.552734,2172.257324 
	C1559.100952,2164.284424 1568.187622,2157.269287 1581.133789,2165.985107 
	C1582.596191,2166.744141 1583.361816,2167.281250 1584.565186,2168.376953 
	C1585.659302,2169.596436 1586.016113,2170.938232 1586.709106,2171.088867 
	C1588.202026,2171.413574 1587.382202,2170.020752 1587.441040,2169.408936 
	C1587.650757,2167.230713 1588.823730,2165.980713 1591.489258,2165.716064 
	C1594.788940,2166.942383 1597.478027,2167.903564 1600.834717,2169.103271 
	C1596.348877,2171.646973 1595.463501,2174.559814 1598.983032,2178.484131 
	C1600.879761,2180.598877 1601.980957,2183.457031 1601.995605,2187.248291 
	C1600.517334,2190.703369 1597.995605,2191.906982 1594.460815,2192.538086 
	C1589.675659,2185.031494 1585.469360,2184.732910 1578.366211,2189.880371 
	C1573.073242,2193.716309 1566.627563,2196.296631 1560.674927,2200.266602 
z"
        />
        <path
          fill="#2C2520"
          opacity="1.000000"
          stroke="none"
          d="
M1582.402100,2168.177002 
	C1580.223511,2167.997803 1578.055908,2168.515381 1577.037842,2167.661865 
	C1568.873535,2160.817139 1562.596924,2165.519775 1556.352783,2170.719482 
	C1554.840332,2171.978760 1553.541870,2173.595703 1550.707764,2172.223145 
	C1550.141357,2171.667725 1550.121826,2171.331299 1550.064209,2170.490723 
	C1547.555786,2165.566895 1537.873291,2159.833008 1552.195679,2158.495605 
	C1554.742798,2158.257812 1553.048950,2155.863770 1553.241699,2154.654297 
	C1553.480835,2153.152100 1552.492676,2154.722656 1552.207397,2154.834717 
	C1544.557251,2157.843018 1541.461304,2152.591064 1538.161865,2146.625732 
	C1538.104858,2142.126221 1542.614136,2140.302490 1541.849976,2135.303223 
	C1541.434326,2132.583740 1547.311890,2130.332764 1551.433228,2128.230957 
	C1552.189453,2130.767578 1553.776855,2132.012207 1557.157349,2132.094971 
	C1558.188477,2132.121094 1558.533447,2132.119141 1559.395874,2132.102539 
	C1560.405273,2132.535645 1561.024414,2132.986084 1561.174194,2133.587158 
	C1564.278931,2146.044189 1572.659912,2151.911133 1585.550659,2153.248291 
	C1586.495361,2154.418701 1586.794800,2155.204834 1586.960449,2156.649170 
	C1587.114380,2158.921875 1588.417480,2159.853271 1589.631104,2161.516602 
	C1590.458496,2163.009277 1591.019775,2163.990479 1591.840454,2165.476562 
	C1589.008301,2167.379150 1588.123291,2169.159180 1590.836182,2171.891846 
	C1592.140625,2173.205566 1594.127808,2174.789307 1591.733276,2176.730713 
	C1589.734497,2178.351562 1587.983398,2177.047852 1586.677124,2175.504883 
	C1585.648926,2174.290771 1584.976807,2172.775146 1584.051758,2170.725342 
	C1583.891113,2169.300293 1583.554199,2168.694092 1582.402100,2168.177002 
z"
        />
        <path
          fill="#131416"
          opacity="1.000000"
          stroke="none"
          d="
M1590.561035,2161.757812 
	C1587.272705,2161.789795 1584.097412,2162.628662 1583.733765,2158.269531 
	C1584.678101,2157.422852 1585.312012,2157.117920 1586.037354,2156.448242 
	C1583.697754,2153.378662 1580.444214,2153.545410 1577.703369,2154.139648 
	C1572.425293,2155.284668 1569.298950,2147.887939 1563.739868,2150.994629 
	C1563.606934,2151.068848 1562.468628,2149.915771 1562.561890,2149.740723 
	C1565.851807,2143.552490 1558.930908,2139.073242 1559.884399,2132.704102 
	C1565.597168,2131.014160 1569.907593,2125.544189 1576.240601,2126.734863 
	C1578.217651,2129.401367 1576.123291,2130.585449 1574.625977,2131.653320 
	C1572.041260,2133.497070 1572.571045,2135.425049 1573.971558,2137.605713 
	C1575.412842,2139.849854 1577.225830,2139.317627 1579.123291,2138.389648 
	C1583.878418,2136.064941 1588.965698,2135.183838 1594.005981,2134.382324 
	C1594.404419,2135.498535 1594.958984,2136.271729 1594.761719,2136.624268 
	C1593.365601,2139.117920 1589.584961,2141.298828 1591.299683,2144.214844 
	C1593.305298,2147.625488 1597.171753,2145.809326 1600.257812,2144.862305 
	C1602.262695,2144.247070 1604.349487,2143.883545 1605.914795,2145.322510 
	C1610.159180,2149.223877 1616.919434,2147.335938 1622.104370,2152.982910 
	C1615.717896,2152.175537 1611.259521,2152.246338 1608.647827,2158.444824 
	C1607.399414,2161.407227 1602.021362,2162.529785 1597.871704,2161.582031 
	C1595.602295,2161.064209 1593.470825,2160.043213 1590.561035,2161.757812 
z"
        />
        <path
          fill="#070709"
          opacity="1.000000"
          stroke="none"
          d="
M1593.245361,2191.555176 
	C1596.121094,2190.611572 1597.352417,2187.539795 1601.322998,2188.005371 
	C1602.010986,2187.990967 1602.000000,2188.000000 1602.001831,2187.996338 
	C1602.404053,2188.506836 1602.868042,2188.955566 1603.746582,2189.673828 
	C1607.043701,2190.270020 1608.612305,2191.841064 1609.877930,2194.608887 
	C1613.233154,2201.945801 1615.165894,2210.117432 1622.119873,2215.463135 
	C1624.930664,2217.623779 1623.473022,2221.870117 1625.703735,2225.334229 
	C1620.467041,2228.115234 1614.694580,2228.302002 1608.302002,2228.190674 
	C1609.480957,2221.907715 1603.820557,2217.044434 1604.958496,2210.461914 
	C1605.274414,2208.633789 1604.647095,2206.632324 1606.030273,2204.958984 
	C1609.937378,2200.232910 1606.082520,2197.405518 1602.995117,2195.334473 
	C1598.674316,2192.435791 1596.451904,2196.450195 1594.459106,2199.366211 
	C1592.571289,2202.128174 1591.217407,2205.320801 1592.575195,2208.729248 
	C1594.123535,2212.615967 1592.117188,2214.287354 1588.905029,2215.290039 
	C1585.207642,2216.444336 1584.312866,2213.988525 1583.808105,2211.122803 
	C1583.556641,2209.695312 1582.630371,2208.827393 1580.641846,2208.141602 
	C1573.824951,2203.467529 1574.199463,2199.425293 1581.250488,2196.708252 
	C1584.406494,2195.491943 1587.092041,2193.744629 1590.505859,2191.661377 
	C1591.651855,2191.302734 1592.134277,2191.244141 1593.245361,2191.555176 
z"
        />
        <path
          fill="#070709"
          opacity="1.000000"
          stroke="none"
          d="
M1662.023926,2239.423340 
	C1658.286377,2239.598633 1656.370972,2237.633301 1656.000977,2233.846680 
	C1655.726562,2231.038330 1654.857056,2228.288086 1654.181885,2224.748291 
	C1654.583252,2223.520752 1655.038452,2223.041016 1655.641479,2222.115234 
	C1655.806152,2221.685303 1655.575073,2220.810547 1654.797852,2220.402832 
	C1651.136475,2222.286865 1652.257812,2225.543945 1651.932617,2229.236572 
	C1644.916504,2229.752197 1642.345825,2223.588623 1638.092529,2219.695557 
	C1637.661133,2219.300537 1637.348755,2218.605225 1637.279297,2218.014404 
	C1636.945923,2215.179443 1644.884399,2200.265625 1647.259155,2199.166748 
	C1649.658691,2198.056152 1651.703491,2199.852539 1653.958252,2200.066650 
	C1657.704590,2200.422119 1661.619873,2202.970947 1664.419800,2197.936035 
	C1665.362549,2196.240723 1667.405029,2196.653809 1668.523438,2198.242920 
	C1669.727539,2199.953369 1668.783081,2201.282471 1667.250122,2202.315186 
	C1664.407593,2204.229980 1662.629761,2206.766357 1661.584106,2210.145020 
	C1660.543579,2213.506836 1659.787598,2216.183350 1662.931396,2219.031494 
	C1665.628906,2221.475098 1666.630127,2224.555664 1662.436157,2227.070068 
	C1660.987671,2227.938232 1661.414673,2229.324463 1662.454468,2230.397949 
	C1665.267700,2233.302490 1662.497681,2235.958252 1662.023926,2239.423340 
z"
        />
        <path
          fill="#1A1615"
          opacity="1.000000"
          stroke="none"
          d="
M1592.194824,2191.202148 
	C1589.779053,2195.449707 1587.441772,2199.742676 1582.901123,2199.572754 
	C1575.141357,2199.282471 1578.486206,2203.369141 1579.676147,2207.363525 
	C1574.762085,2209.777832 1575.451904,2214.772217 1574.047363,2219.310791 
	C1567.460938,2220.806396 1567.854370,2215.215332 1566.053467,2210.846680 
	C1569.275879,2201.266113 1573.473022,2193.024170 1582.489624,2188.599365 
	C1586.111206,2186.822021 1590.062134,2184.902100 1592.194824,2191.202148 
z"
        />
        <path
          fill="#1B1E22"
          opacity="1.000000"
          stroke="none"
          d="
M1648.551270,2143.983887 
	C1653.935913,2143.978027 1656.792114,2147.795898 1661.534912,2149.367676 
	C1658.864624,2152.902588 1655.980225,2155.402832 1652.538452,2152.257080 
	C1647.552002,2147.699707 1641.103394,2147.380615 1635.395386,2144.991211 
	C1638.376831,2137.947998 1639.472900,2137.788086 1644.047974,2143.188477 
	C1644.700562,2143.958984 1646.538574,2143.725098 1648.551270,2143.983887 
z"
        />
        <path
          fill="#1B1E22"
          opacity="1.000000"
          stroke="none"
          d="
M1649.179199,2131.380615 
	C1650.258545,2133.059570 1651.065308,2134.167969 1651.381348,2135.402344 
	C1651.941162,2137.588379 1652.702881,2140.326904 1649.805054,2141.245850 
	C1646.497192,2142.294434 1646.140869,2139.368408 1645.868652,2137.150879 
	C1645.536377,2134.445312 1641.826050,2133.549316 1642.499268,2130.330566 
	C1644.705688,2128.398193 1646.647705,2130.309570 1649.179199,2131.380615 
z"
        />
        <path
          fill="#1A1615"
          opacity="1.000000"
          stroke="none"
          d="
M1579.404541,2227.715088 
	C1579.564331,2230.492188 1580.568237,2235.054932 1575.665894,2232.268799 
	C1572.599609,2230.526123 1570.075439,2232.279541 1566.641235,2231.943848 
	C1565.399658,2226.176514 1568.861938,2225.900635 1573.551514,2226.310303 
	C1575.607178,2226.440186 1577.213989,2226.947510 1579.404541,2227.715088 
z"
        />
        <path
          fill="#070709"
          opacity="1.000000"
          stroke="none"
          d="
M1679.154541,2190.001709 
	C1679.698730,2192.122070 1680.448364,2195.584717 1677.059204,2194.963623 
	C1673.839233,2194.373291 1670.061768,2194.728271 1666.140503,2192.067871 
	C1670.643066,2188.977783 1674.639648,2190.422607 1679.154541,2190.001709 
z"
        />
        <path
          fill="#070709"
          opacity="1.000000"
          stroke="none"
          d="
M1641.346191,2240.045654 
	C1638.825562,2246.589600 1633.822388,2242.159912 1628.748291,2242.032715 
	C1628.389282,2238.813232 1630.398193,2237.759766 1633.293335,2238.262695 
	C1635.742554,2238.688477 1638.140747,2239.407715 1641.346191,2240.045654 
z"
        />
        <path
          fill="#070709"
          opacity="1.000000"
          stroke="none"
          d="
M1626.004883,2225.995117 
	C1627.297852,2225.993408 1628.598999,2225.882324 1629.870850,2226.027832 
	C1631.574829,2226.222656 1633.938599,2225.163086 1634.428833,2228.014893 
	C1634.521606,2228.553955 1634.177124,2229.368652 1633.758179,2229.761230 
	C1631.866455,2231.534180 1630.210693,2230.032715 1628.626099,2229.133301 
	C1627.823608,2228.677490 1627.255859,2227.808105 1626.287842,2226.567871 
	C1625.993774,2226.009766 1626.000000,2226.000000 1626.004883,2225.995117 
z"
        />
        <path
          fill="#1B1E22"
          opacity="1.000000"
          stroke="none"
          d="
M1634.648926,2134.951660 
	C1634.957520,2137.671631 1633.526001,2137.848389 1631.832275,2137.238770 
	C1631.449341,2137.101074 1631.009033,2136.103516 1631.152954,2135.701904 
	C1631.677124,2134.240234 1632.923584,2134.188232 1634.648926,2134.951660 
z"
        />
        <path
          fill="#070709"
          opacity="1.000000"
          stroke="none"
          d="
M1651.289551,2234.100098 
	C1651.489624,2236.017578 1653.223999,2239.417969 1648.394775,2238.402344 
	C1648.191284,2236.195312 1648.784546,2234.737305 1651.289551,2234.100098 
z"
        />
        <path
          fill="#070709"
          opacity="1.000000"
          stroke="none"
          d="
M1679.995117,2189.995117 
	C1680.193359,2188.774902 1680.227295,2187.393066 1681.776733,2187.106689 
	C1682.102051,2187.046631 1682.889893,2187.721191 1682.905884,2188.082031 
	C1682.955444,2189.198242 1682.041748,2189.594482 1680.555420,2189.928467 
	C1680.009766,2190.009766 1680.000000,2190.000000 1679.995117,2189.995117 
z"
        />
        <path
          fill="#070709"
          opacity="1.000000"
          stroke="none"
          d="
M1666.884766,2213.913574 
	C1667.629883,2212.556885 1668.379395,2212.536621 1669.041504,2213.508545 
	C1669.180054,2213.711914 1669.174194,2214.182617 1669.029785,2214.380371 
	C1668.339844,2215.325195 1667.590820,2215.293945 1666.884766,2213.913574 
z"
        />
        <path
          fill="#070709"
          opacity="1.000000"
          stroke="none"
          d="
M1619.510254,2242.104492 
	C1619.652588,2243.831543 1619.548096,2246.026855 1616.338501,2244.476807 
	C1616.662598,2242.980469 1617.623535,2242.317139 1619.510254,2242.104492 
z"
        />
        <path
          fill="#070709"
          opacity="1.000000"
          stroke="none"
          d="
M1593.113037,2222.086426 
	C1592.369385,2223.441895 1591.621338,2223.460693 1590.960083,2222.490723 
	C1590.821777,2222.287842 1590.827881,2221.818115 1590.971924,2221.620605 
	C1591.660522,2220.676270 1592.407959,2220.707031 1593.113037,2222.086426 
z"
        />
        <path
          fill="#131416"
          opacity="1.000000"
          stroke="none"
          d="
M1603.853149,2189.573242 
	C1603.553955,2189.633301 1603.010376,2189.257568 1602.235107,2188.437256 
	C1602.662964,2188.199707 1603.197998,2188.581299 1603.853149,2189.573242 
z"
        />
        <path
          fill="#B17752"
          opacity="1.000000"
          stroke="none"
          d="
M1700.691528,2369.749023 
	C1697.857666,2369.915527 1695.718872,2369.900879 1692.778564,2369.856934 
	C1682.762939,2366.893311 1673.495972,2367.529541 1663.486938,2369.237061 
	C1658.145142,2361.503418 1664.233398,2359.063965 1669.155151,2358.256592 
	C1678.500488,2356.723633 1686.891357,2352.716064 1696.909912,2349.570557 
	C1683.090942,2344.370117 1670.214478,2340.100586 1656.128052,2343.360352 
	C1651.880005,2344.343750 1647.524048,2344.202881 1643.257324,2344.939697 
	C1640.907715,2345.345459 1638.404541,2345.442383 1637.254272,2342.744629 
	C1631.305908,2328.793457 1627.878784,2314.805908 1636.584839,2300.567383 
	C1639.143677,2296.382324 1642.958374,2291.833008 1639.064453,2286.911133 
	C1635.380493,2282.254883 1629.401489,2282.482422 1624.197388,2283.273438 
	C1617.349854,2284.313965 1610.800537,2286.275879 1603.624390,2284.721680 
	C1599.260620,2283.776855 1597.531738,2289.407959 1593.236572,2291.233398 
	C1590.273804,2293.642090 1588.118652,2295.941406 1585.387695,2297.576660 
	C1588.255493,2296.408691 1589.872925,2293.781982 1592.930298,2291.813721 
	C1598.016724,2293.429443 1598.819946,2297.134766 1596.905884,2300.593262 
	C1593.556885,2306.644775 1592.359253,2313.776123 1586.830200,2319.461426 
	C1574.887207,2320.681152 1572.371460,2317.968750 1570.982666,2302.710938 
	C1570.875732,2293.941650 1571.979858,2286.468506 1579.541260,2280.808594 
	C1590.091309,2279.644775 1594.320923,2271.500000 1600.518799,2264.731445 
	C1611.330322,2256.355957 1620.675781,2262.134521 1629.763550,2266.662598 
	C1637.988525,2270.760986 1646.215942,2274.755127 1655.613770,2277.782227 
	C1657.870117,2277.681396 1659.345703,2277.899170 1661.454224,2278.690430 
	C1665.560547,2283.871338 1668.998047,2288.700928 1674.264893,2291.861816 
	C1675.954102,2292.875732 1678.623901,2294.338135 1678.465942,2295.856689 
	C1676.572388,2314.063721 1696.711060,2314.807861 1702.809692,2326.752686 
	C1704.985596,2331.013916 1709.095459,2334.388672 1711.526733,2338.772949 
	C1718.943237,2352.146484 1715.538574,2362.512939 1700.691528,2369.749023 
z"
        />
        <path
          fill="#9E6A4C"
          opacity="1.000000"
          stroke="none"
          d="
M1700.510498,2370.479492 
	C1703.662109,2364.737549 1709.510742,2361.467773 1712.094360,2355.161377 
	C1713.348877,2352.099121 1713.832153,2349.816650 1712.440063,2346.827637 
	C1709.059692,2339.569336 1705.270508,2332.617432 1698.932495,2327.489258 
	C1696.552979,2325.564209 1694.807739,2323.273926 1693.198608,2320.724365 
	C1690.499512,2316.447510 1687.928833,2312.681152 1681.342651,2314.896973 
	C1677.219849,2316.283936 1674.028076,2310.931641 1675.648193,2306.397705 
	C1678.309204,2298.950684 1678.161133,2299.123535 1672.130249,2293.957031 
	C1668.223511,2290.610107 1662.858643,2288.367432 1661.956543,2281.641602 
	C1662.250244,2278.992676 1663.765015,2278.652100 1666.130859,2278.493164 
	C1673.537231,2282.852783 1679.860718,2287.653076 1687.017578,2291.125488 
	C1689.810791,2292.480225 1691.866821,2294.595947 1692.935669,2297.525391 
	C1697.822510,2310.920898 1707.057373,2321.177246 1717.501465,2331.451172 
	C1732.180298,2337.175049 1731.692627,2355.996338 1720.962769,2362.110596 
	C1720.130127,2362.585205 1718.967285,2363.134521 1718.725586,2363.893311 
	C1715.676392,2373.471191 1707.717529,2370.652100 1700.510498,2370.479492 
z"
        />
        <path
          fill="#3A261C"
          opacity="1.000000"
          stroke="none"
          d="
M1663.561523,2373.363770 
	C1669.907959,2377.228760 1676.182617,2376.895508 1683.283325,2375.800781 
	C1684.731201,2376.429932 1685.230957,2377.207764 1685.510986,2378.832031 
	C1682.894775,2383.723877 1677.115723,2387.471191 1683.571167,2393.697998 
	C1683.221313,2395.885010 1682.458008,2397.106934 1680.766846,2398.583252 
	C1678.775391,2399.686279 1677.321045,2399.889648 1675.112305,2399.566162 
	C1668.812378,2395.257080 1663.416016,2393.809326 1658.057861,2401.020264 
	C1655.585815,2402.296387 1653.609009,2402.407959 1650.832520,2402.097656 
	C1643.824097,2398.678711 1635.750977,2400.374023 1630.045532,2394.105957 
	C1632.935303,2387.977051 1640.460449,2387.182617 1643.527344,2380.615234 
	C1644.274536,2379.845459 1644.594604,2379.563721 1645.436523,2378.927734 
	C1647.339478,2377.720703 1648.923218,2377.209717 1650.628174,2375.715088 
	C1651.458008,2375.122314 1651.806885,2374.905762 1652.720459,2374.435303 
	C1655.256348,2373.519287 1657.300049,2373.143311 1659.945679,2372.559570 
	C1661.439697,2372.462402 1662.270996,2372.624512 1663.561523,2373.363770 
z"
        />
        <path
          fill="#8C5B43"
          opacity="1.000000"
          stroke="none"
          d="
M1717.938599,2331.258301 
	C1715.955933,2331.910156 1714.183838,2331.161377 1712.721802,2329.685791 
	C1703.790894,2320.672607 1694.061035,2312.215088 1690.703735,2299.207520 
	C1689.872803,2295.989014 1687.685303,2294.742432 1684.960815,2293.171387 
	C1679.109131,2289.797607 1672.223145,2287.770996 1668.023315,2281.059082 
	C1668.332886,2278.999023 1669.392578,2278.354492 1671.389526,2277.994873 
	C1672.887207,2277.793701 1673.734863,2277.660889 1675.211426,2277.389648 
	C1680.253662,2277.088135 1684.752930,2276.127686 1689.440430,2279.275635 
	C1694.164673,2285.507080 1696.807495,2291.884521 1699.535278,2298.260742 
	C1704.427246,2309.696289 1709.913330,2320.834961 1717.938599,2331.258301 
z"
        />
        <path
          fill="#603A24"
          opacity="1.000000"
          stroke="none"
          d="
M1690.539551,2279.380615 
	C1685.595215,2279.577637 1681.012939,2281.078857 1676.322021,2277.946289 
	C1678.153931,2272.962646 1683.011963,2272.543945 1687.278931,2270.072266 
	C1692.953857,2270.209229 1697.530151,2271.206055 1699.901611,2277.113770 
	C1697.047363,2278.277832 1693.917847,2277.388672 1690.539551,2279.380615 
z"
        />
        <path
          fill="#9E6A4C"
          opacity="1.000000"
          stroke="none"
          d="
M1664.442627,2373.122070 
	C1663.155518,2373.828857 1662.259155,2373.878418 1660.690186,2373.917236 
	C1658.760742,2372.799316 1658.419922,2371.382080 1658.916504,2369.078125 
	C1659.614502,2368.062500 1660.015869,2367.750488 1661.150635,2367.647949 
	C1668.922241,2366.792969 1675.923462,2365.456787 1683.119141,2365.986572 
	C1686.049194,2366.202637 1689.032959,2365.895264 1691.562256,2369.181152 
	C1685.997681,2371.695068 1679.982056,2369.161133 1673.960571,2369.994385 
	C1670.773315,2370.435303 1667.315063,2369.521240 1664.442627,2373.122070 
z"
        />
        <path
          fill="#4A3831"
          opacity="1.000000"
          stroke="none"
          d="
M1709.128174,2375.951660 
	C1709.982666,2377.523438 1710.010376,2379.076416 1710.037842,2381.405762 
	C1705.782837,2385.552734 1701.657959,2383.499268 1696.855713,2381.383057 
	C1695.910889,2380.404297 1695.633057,2379.924316 1695.674561,2378.676758 
	C1699.666016,2374.569580 1704.184570,2376.503174 1709.128174,2375.951660 
z"
        />
        <path
          fill="#3A261C"
          opacity="1.000000"
          stroke="none"
          d="
M1695.203125,2377.887695 
	C1695.989746,2378.302490 1695.985596,2378.696045 1695.987915,2379.679443 
	C1694.324951,2385.197754 1691.225830,2384.060303 1687.552002,2381.395020 
	C1687.165161,2380.046631 1687.174683,2379.469482 1687.644287,2378.158203 
	C1690.207031,2377.572021 1692.309692,2377.719238 1695.203125,2377.887695 
z"
        />
        <path
          fill="#4A3831"
          opacity="1.000000"
          stroke="none"
          d="
M1688.006836,2376.912598 
	C1688.073853,2377.840088 1688.043335,2378.255615 1687.993408,2379.295654 
	C1687.239258,2380.746582 1686.326416,2381.069824 1684.628906,2380.416260 
	C1684.020142,2379.019287 1684.017944,2378.095215 1684.021729,2376.474121 
	C1685.637573,2374.018311 1686.922241,2374.283203 1688.006836,2376.912598 
z"
        />
        <path
          fill="#0C0D0F"
          opacity="1.000000"
          stroke="none"
          d="
M1683.568848,2380.476074 
	C1684.900757,2379.948975 1685.779053,2379.954346 1687.315674,2379.939941 
	C1689.965210,2382.427979 1692.411987,2382.338623 1695.521973,2380.715576 
	C1700.350708,2379.448242 1704.291626,2381.524414 1709.245850,2382.118652 
	C1712.706055,2384.810303 1716.092163,2383.842041 1719.241699,2383.989258 
	C1726.325439,2384.320312 1733.449951,2383.337646 1741.170410,2385.105225 
	C1739.730591,2389.152100 1735.755737,2390.277344 1732.453613,2390.272705 
	C1715.926147,2390.248779 1699.672852,2392.259033 1682.772217,2394.983887 
	C1681.883301,2392.425049 1681.183594,2390.780273 1678.987793,2390.091797 
	C1676.843506,2389.419434 1674.685547,2388.712891 1673.207031,2386.854004 
	C1673.048462,2386.654541 1673.150024,2386.248535 1673.113159,2385.652832 
	C1675.650879,2382.658203 1680.024902,2383.132812 1683.568848,2380.476074 
z"
        />
        <path
          fill="#5A605E"
          opacity="1.000000"
          stroke="none"
          d="
M1681.778687,2394.924805 
	C1696.084839,2389.274414 1710.516724,2386.893555 1725.584473,2387.779053 
	C1730.389893,2388.061523 1735.302612,2386.520508 1741.001465,2385.687988 
	C1742.765503,2385.681885 1743.694336,2385.801514 1745.324463,2385.973145 
	C1746.025635,2386.025146 1746.000977,2386.000000 1745.993164,2385.987305 
	C1744.078247,2393.696045 1736.725220,2391.221191 1731.166260,2393.710449 
	C1725.013794,2394.858643 1719.562622,2392.575195 1713.446777,2394.272949 
	C1709.008301,2394.977051 1705.193237,2394.853516 1700.862061,2396.072021 
	C1698.871460,2396.633789 1697.532349,2396.941650 1695.533203,2397.397461 
	C1690.450684,2397.772949 1686.388794,2400.468994 1680.790161,2398.603516 
	C1680.374634,2396.957764 1680.953857,2396.157471 1681.778687,2394.924805 
z"
        />
        <path
          fill="#1B1E22"
          opacity="1.000000"
          stroke="none"
          d="
M1371.966553,2201.243652 
	C1371.123413,2202.006836 1370.246704,2202.003662 1368.713501,2202.008789 
	C1368.189209,2200.331787 1368.645996,2198.825684 1370.827881,2197.711426 
	C1371.558350,2198.460938 1371.745605,2199.468994 1371.966553,2201.243652 
z"
        />
        <path
          fill="#313E41"
          opacity="1.000000"
          stroke="none"
          d="
M1370.526123,2186.040039 
	C1371.008057,2185.232178 1371.455078,2185.334961 1371.455933,2186.037109 
	C1371.456787,2186.742188 1371.011841,2186.847656 1370.526123,2186.040039 
z"
        />
        <path
          fill="#728DA3"
          opacity="1.000000"
          stroke="none"
          d="
M1631.796509,2076.778320 
	C1629.906616,2074.053955 1628.153564,2062.385010 1628.870850,2061.029053 
	C1629.898926,2059.085693 1631.622070,2058.219971 1633.756592,2058.250000 
	C1636.028442,2058.281982 1637.679199,2059.398682 1637.760498,2061.740723 
	C1637.909424,2066.032227 1638.038940,2070.355713 1637.675903,2074.623535 
	C1637.409790,2077.751465 1635.012329,2077.957031 1631.796509,2076.778320 
z"
        />
        <path
          fill="#728DA3"
          opacity="1.000000"
          stroke="none"
          d="
M1717.971680,2110.742676 
	C1718.579346,2110.069824 1719.206421,2110.010498 1719.821045,2110.072754 
	C1721.637817,2110.257324 1724.286377,2110.211670 1724.083008,2112.499023 
	C1723.872192,2114.869385 1721.126221,2113.680176 1718.822998,2114.066650 
	C1718.100586,2113.197021 1718.042969,2112.304443 1717.971680,2110.742676 
z"
        />
        <path
          fill="#1B1E22"
          opacity="1.000000"
          stroke="none"
          d="
M1565.365479,2104.275879 
	C1566.446167,2104.871582 1566.393555,2105.432373 1565.526123,2105.615234 
	C1565.013672,2105.723145 1564.403931,2105.370361 1563.838013,2105.224854 
	C1564.211914,2104.885010 1564.585815,2104.545166 1565.365479,2104.275879 
z"
        />
        <path
          fill="#1B1E22"
          opacity="1.000000"
          stroke="none"
          d="
M1593.364502,2112.267090 
	C1594.449219,2112.872314 1594.397461,2113.440918 1593.527466,2113.625977 
	C1593.013428,2113.735596 1592.399414,2113.376465 1591.830322,2113.228271 
	C1592.205566,2112.882812 1592.580811,2112.537598 1593.364502,2112.267090 
z"
        />
        <path
          fill="#6E8AA1"
          opacity="1.000000"
          stroke="none"
          d="
M1640.732422,1971.970947 
	C1638.617432,1967.727905 1633.896729,1968.777344 1630.562134,1966.103027 
	C1635.077759,1963.730225 1639.133545,1961.336426 1643.403076,1959.418579 
	C1649.400513,1956.724487 1656.907715,1960.986328 1659.995605,1969.287598 
	C1658.880005,1971.685547 1657.171143,1971.970825 1654.686646,1972.015869 
	C1649.843506,1970.401367 1645.654297,1971.032349 1640.732422,1971.970947 
z"
        />
        <path
          fill="#73828A"
          opacity="1.000000"
          stroke="none"
          d="
M1677.455200,1934.060791 
	C1678.100220,1934.515503 1678.334229,1934.901489 1678.883789,1935.456299 
	C1679.712036,1937.478149 1679.626099,1938.988892 1676.761230,1939.530151 
	C1676.101807,1938.161011 1676.061401,1936.777954 1676.022705,1934.697510 
	C1676.024414,1934.000000 1676.978638,1934.032104 1677.455200,1934.060791 
z"
        />
        <path
          fill="#131416"
          opacity="1.000000"
          stroke="none"
          d="
M1537.970581,2145.978027 
	C1539.245728,2146.144775 1541.598022,2146.102051 1541.693970,2146.546387 
	C1543.724121,2155.951172 1550.159546,2153.590332 1556.268066,2152.214355 
	C1556.565308,2152.147461 1556.913696,2152.307373 1557.379883,2152.385010 
	C1558.471802,2154.820068 1553.821289,2153.915283 1554.932129,2156.500244 
	C1555.639771,2158.146729 1559.691528,2157.668701 1557.866821,2160.420166 
	C1556.335083,2162.730225 1553.690186,2161.675537 1551.473145,2160.756348 
	C1550.212646,2160.233887 1548.931641,2160.036865 1547.327637,2160.988281 
	C1545.388916,2164.225586 1549.938232,2165.767822 1549.938965,2169.295166 
	C1547.108032,2168.488037 1544.189697,2166.989258 1541.280640,2165.495605 
	C1539.062500,2167.976318 1541.308960,2169.930420 1541.690796,2172.009766 
	C1542.487549,2176.349365 1540.549927,2178.968262 1536.681030,2180.590332 
	C1534.455933,2181.523438 1532.661133,2181.410645 1532.217163,2178.729004 
	C1531.773071,2176.045898 1531.012695,2173.517090 1529.493530,2171.305420 
	C1527.684204,2168.671387 1525.712524,2167.203369 1523.817627,2171.352295 
	C1522.848389,2173.474121 1521.336670,2173.668457 1520.321533,2171.567139 
	C1517.717529,2166.177246 1514.610229,2169.223877 1512.724609,2171.547363 
	C1509.089600,2176.025879 1506.973022,2181.389160 1508.290039,2187.351807 
	C1509.647949,2193.498535 1511.286499,2199.587402 1512.947998,2205.661377 
	C1513.575073,2207.953857 1513.926514,2209.829834 1517.612793,2210.287598 
	C1520.343384,2210.626465 1522.990112,2229.068359 1521.346313,2231.943359 
	C1520.340820,2233.701660 1518.663574,2233.978760 1517.155518,2232.825928 
	C1514.206543,2230.570557 1512.188232,2227.966797 1511.852417,2223.786377 
	C1511.360596,2217.663086 1509.859741,2211.620605 1508.809326,2205.659912 
	C1504.961792,2205.885254 1506.835815,2209.226318 1505.023682,2210.145996 
	C1501.082275,2208.261475 1499.364380,2205.510498 1502.305298,2201.601318 
	C1505.723877,2197.057129 1506.305420,2192.148193 1504.489746,2186.410400 
	C1503.406982,2182.730957 1501.975830,2181.008545 1498.682251,2183.362305 
	C1497.587158,2184.145020 1495.862915,2185.417725 1495.550171,2183.868164 
	C1494.358643,2177.966797 1488.744751,2174.177979 1488.373657,2167.977295 
	C1488.194824,2164.988770 1490.308105,2162.816650 1492.331909,2163.544922 
	C1498.004883,2165.586182 1497.436890,2163.127930 1496.921875,2159.011963 
	C1496.443604,2155.190674 1500.546387,2153.220459 1502.770142,2151.736328 
	C1512.800659,2145.042480 1520.742920,2136.676758 1528.016113,2126.147705 
	C1528.016113,2129.499756 1528.016113,2132.851562 1528.008057,2136.995605 
	C1528.419434,2138.306641 1528.851562,2138.814941 1529.668335,2139.646973 
	C1530.039551,2139.982422 1529.997192,2139.991943 1530.016357,2139.976807 
	C1531.886475,2142.741699 1530.395996,2148.951172 1537.524170,2146.371582 
	C1537.995605,2145.944092 1537.996826,2145.994873 1537.970581,2145.978027 
z"
        />
        <path
          fill="#131416"
          opacity="1.000000"
          stroke="none"
          d="
M1466.941284,2211.913086 
	C1467.285767,2214.021729 1466.315308,2215.725098 1465.259521,2214.570068 
	C1463.102295,2212.209717 1461.424805,2209.258301 1460.127808,2206.296875 
	C1457.524536,2200.353271 1452.735352,2197.324463 1446.932617,2195.411133 
	C1446.000488,2195.103516 1444.300903,2194.539551 1444.166016,2194.777832 
	C1441.223267,2199.984619 1434.162720,2195.844971 1430.982422,2200.702148 
	C1430.029907,2202.156738 1427.791016,2202.023438 1426.806030,2200.252686 
	C1423.702515,2194.672607 1422.796875,2188.316895 1421.893555,2182.205811 
	C1421.502197,2179.557129 1425.324097,2177.775391 1428.636719,2177.599854 
	C1431.592773,2180.027832 1428.554932,2184.210938 1431.482178,2186.751221 
	C1436.024170,2186.044922 1432.269165,2180.488281 1435.706055,2179.161133 
	C1436.005249,2179.238525 1436.342773,2179.250732 1436.595825,2179.403076 
	C1438.155396,2180.342285 1436.106201,2183.958740 1439.481323,2183.460938 
	C1441.476807,2183.166504 1442.213135,2181.362305 1441.874146,2179.712158 
	C1440.670288,2173.852051 1445.059082,2172.115723 1448.911011,2171.063721 
	C1452.850708,2169.987549 1455.697632,2173.332275 1457.552490,2176.323242 
	C1460.580322,2181.205566 1460.645386,2187.867188 1466.509766,2191.103760 
	C1466.814331,2191.271729 1466.944580,2192.461914 1466.679321,2192.850586 
	C1462.466675,2199.024414 1467.913208,2205.149658 1466.941284,2211.913086 
z"
        />
        <path
          fill="#2E2D30"
          opacity="1.000000"
          stroke="none"
          d="
M1407.860352,2259.269287 
	C1404.916992,2263.055664 1405.271118,2267.340576 1404.297852,2271.020996 
	C1402.993164,2275.955322 1399.956177,2278.155029 1395.523193,2278.869873 
	C1393.449829,2279.204102 1390.954224,2281.010742 1389.067871,2278.628906 
	C1387.177368,2276.242188 1389.153198,2274.355225 1390.370972,2272.462402 
	C1392.595703,2269.005371 1392.149170,2266.102539 1388.265503,2264.418213 
	C1381.826172,2261.625000 1386.245605,2260.180664 1389.474487,2258.153320 
	C1391.052856,2257.019531 1391.965576,2255.565918 1393.031250,2255.443359 
	C1399.418213,2254.710449 1399.411987,2254.802734 1402.861572,2248.867920 
	C1403.135742,2248.396484 1403.891846,2248.204590 1404.876465,2247.609131 
	C1405.866943,2251.325684 1406.826904,2254.927734 1407.860352,2259.269287 
z"
        />
        <path
          fill="#423C3E"
          opacity="1.000000"
          stroke="none"
          d="
M1592.469727,2191.911621 
	C1590.790405,2189.840576 1589.636841,2188.043213 1586.647461,2189.349854 
	C1579.541016,2192.456299 1574.740967,2197.491699 1571.368408,2206.215088 
	C1570.562622,2208.299561 1569.244629,2209.375977 1566.595093,2210.015137 
	C1565.430054,2209.705078 1564.912964,2209.286377 1564.103027,2208.461914 
	C1562.580566,2206.451172 1560.926880,2205.170166 1559.998413,2202.640625 
	C1559.888794,2201.707275 1559.908325,2201.369873 1559.971436,2200.527832 
	C1561.016235,2199.193848 1562.612427,2198.540039 1562.917603,2197.505371 
	C1564.398926,2192.484375 1567.054932,2189.454834 1572.769775,2189.954346 
	C1574.702393,2190.123047 1576.290161,2189.359131 1577.762451,2187.534180 
	C1584.478027,2179.208496 1589.916626,2180.292969 1593.885132,2191.209961 
	C1593.873779,2191.924561 1592.937256,2191.937744 1592.469727,2191.911621 
z"
        />
        <path
          fill="#2E2D30"
          opacity="1.000000"
          stroke="none"
          d="
M1459.204834,2237.778809 
	C1459.077881,2245.738770 1459.077881,2245.738770 1466.288086,2246.624023 
	C1475.106201,2247.706543 1476.367554,2250.179443 1472.094360,2259.647217 
	C1468.972046,2255.142334 1464.391357,2253.555420 1459.040039,2252.038330 
	C1457.024536,2249.644287 1455.158081,2247.822998 1452.960693,2246.419189 
	C1449.513184,2244.216553 1449.192627,2240.756836 1450.768677,2237.913086 
	C1452.557129,2234.686035 1455.835083,2236.274170 1459.204834,2237.778809 
z"
        />
        <path
          fill="#1A1615"
          opacity="1.000000"
          stroke="none"
          d="
M1531.635742,2242.263672 
	C1532.529907,2234.105957 1533.431274,2226.661133 1539.601196,2220.747070 
	C1542.848022,2222.441895 1540.099243,2224.877441 1540.565918,2226.737793 
	C1541.770142,2231.536621 1544.159668,2229.934814 1547.287842,2228.012451 
	C1549.232666,2238.581543 1544.808716,2243.778076 1532.698608,2244.466309 
	C1531.970581,2244.053467 1531.997070,2244.009766 1531.974243,2244.018555 
	C1531.729370,2243.633301 1531.507446,2243.239258 1531.635742,2242.263672 
z"
        />
        <path
          fill="#2E2D30"
          opacity="1.000000"
          stroke="none"
          d="
M1532.213379,2244.668701 
	C1540.511230,2242.682861 1545.417847,2237.362549 1547.880981,2228.659180 
	C1548.221680,2227.904297 1548.576294,2227.892090 1549.462891,2227.875488 
	C1548.506104,2232.635742 1554.351196,2232.652100 1556.245361,2236.463867 
	C1549.632324,2237.956299 1545.925293,2241.356934 1545.409546,2247.770508 
	C1545.272949,2249.468262 1543.359131,2251.022949 1542.091797,2253.348633 
	C1541.109253,2254.660645 1540.202515,2254.941406 1538.605713,2254.385742 
	C1538.025024,2253.928711 1538.003540,2253.977295 1538.036865,2253.970459 
	C1538.079956,2249.853760 1534.452026,2248.096680 1532.213379,2244.668701 
z"
        />
        <path
          fill="#2E2D30"
          opacity="1.000000"
          stroke="none"
          d="
M1451.376953,2254.120117 
	C1452.346924,2257.001221 1454.613770,2260.552246 1449.495483,2261.838135 
	C1443.839478,2263.258545 1442.054077,2259.513672 1440.289795,2254.631592 
	C1443.584839,2254.651611 1447.054565,2259.018066 1451.376953,2254.120117 
z"
        />
        <path
          fill="#131416"
          opacity="1.000000"
          stroke="none"
          d="
M1491.941528,2224.822754 
	C1488.992676,2221.036377 1491.220947,2217.403564 1492.977905,2214.869141 
	C1496.159546,2210.280273 1498.267456,2216.816162 1501.704224,2216.491699 
	C1497.486206,2218.440186 1493.529297,2220.045166 1491.941528,2224.822754 
z"
        />
        <path
          fill="#2C2520"
          opacity="1.000000"
          stroke="none"
          d="
M1537.346069,2145.853027 
	C1536.276489,2147.810059 1534.557251,2149.676025 1532.765869,2151.620117 
	C1528.911743,2148.559326 1529.317993,2144.926758 1529.998535,2140.597900 
	C1532.610596,2141.487549 1533.061768,2145.452637 1537.346069,2145.853027 
z"
        />
        <path
          fill="#131416"
          opacity="1.000000"
          stroke="none"
          d="
M1559.428833,2202.432617 
	C1562.230347,2202.734863 1563.408691,2204.401123 1563.782715,2207.404785 
	C1560.854858,2207.552490 1558.091675,2206.871826 1559.428833,2202.432617 
z"
        />
        <path
          fill="#2E2D30"
          opacity="1.000000"
          stroke="none"
          d="
M1482.119385,2251.269043 
	C1477.844238,2251.582275 1478.010132,2248.442627 1477.986816,2244.683838 
	C1480.795166,2245.269043 1482.233276,2247.451172 1482.119385,2251.269043 
z"
        />
        <path
          fill="#2E2D30"
          opacity="1.000000"
          stroke="none"
          d="
M1478.046753,2243.318359 
	C1474.852417,2243.548096 1473.216431,2242.179443 1475.534668,2238.363525 
	C1477.701416,2239.076660 1477.964478,2240.816406 1478.046753,2243.318359 
z"
        />
        <path
          fill="#1A1615"
          opacity="1.000000"
          stroke="none"
          d="
M1427.508301,2247.814697 
	C1427.679810,2249.387451 1428.801392,2251.380127 1426.754761,2251.928711 
	C1426.335449,2252.040771 1424.981445,2250.151855 1424.715820,2249.030029 
	C1424.218140,2246.927979 1425.641968,2247.128662 1427.508301,2247.814697 
z"
        />
        <path
          fill="#1A1615"
          opacity="1.000000"
          stroke="none"
          d="
M1429.983398,2248.019287 
	C1429.964722,2248.039062 1428.982544,2248.011719 1428.491333,2248.005859 
	C1428.325806,2247.179443 1428.651611,2246.358887 1428.977295,2245.538330 
	C1429.303589,2246.027588 1429.629761,2246.516846 1429.998169,2247.482910 
	C1430.040283,2247.959473 1430.002075,2247.999512 1429.983398,2248.019287 
z"
        />
        <path
          fill="#1B1E22"
          opacity="1.000000"
          stroke="none"
          d="
M1458.492188,2144.814453 
	C1458.381226,2145.186768 1457.979370,2145.493164 1457.861694,2145.407959 
	C1457.158203,2144.897949 1457.344482,2144.577393 1458.492188,2144.814453 
z"
        />
        <path
          fill="#131416"
          opacity="1.000000"
          stroke="none"
          d="
M1500.590576,2187.958008 
	C1500.899414,2187.336670 1501.853516,2186.709961 1503.401367,2186.041748 
	C1503.995117,2186.000000 1504.000000,2186.000000 1504.001221,2186.000000 
	C1503.080200,2186.650879 1502.157959,2187.301758 1500.590576,2187.958008 
z"
        />
        <path
          fill="#131416"
          opacity="1.000000"
          stroke="none"
          d="
M1531.263306,2185.535645 
	C1530.251465,2186.752686 1530.379272,2186.332275 1531.263306,2185.535645 
z"
        />
        <path
          fill="#131416"
          opacity="1.000000"
          stroke="none"
          d="
M1500.035645,2188.037109 
	C1499.801636,2188.857910 1499.539551,2189.651611 1499.277466,2190.445312 
	C1497.970825,2189.834961 1498.708252,2189.151855 1499.576416,2188.220215 
	C1499.945312,2187.963135 1500.007568,2188.009766 1500.035645,2188.037109 
z"
        />
        <path
          fill="#1B1E22"
          opacity="1.000000"
          stroke="none"
          d="
M1410.968872,2192.945801 
	C1411.042969,2192.907715 1410.894775,2192.984131 1410.968872,2192.945801 
z"
        />
        <path
          fill="#2C2520"
          opacity="1.000000"
          stroke="none"
          d="
M1529.811035,2139.524414 
	C1529.499390,2139.570068 1528.959229,2139.157715 1528.209595,2138.266602 
	C1528.527466,2138.214111 1529.054932,2138.640381 1529.811035,2139.524414 
z"
        />
        <path
          fill="#2E2D30"
          opacity="1.000000"
          stroke="none"
          d="
M1484.021973,2253.563477 
	C1483.702759,2253.645020 1483.109619,2253.269287 1482.265137,2252.425781 
	C1482.591919,2252.340576 1483.169922,2252.723389 1484.021973,2253.563477 
z"
        />
        <path
          fill="#2E2D30"
          opacity="1.000000"
          stroke="none"
          d="
M1439.852051,2253.487061 
	C1439.491577,2253.563477 1438.944946,2253.146729 1438.187134,2252.244629 
	C1438.539307,2252.170898 1439.102661,2252.582275 1439.852051,2253.487061 
z"
        />
        <path
          fill="#1A1615"
          opacity="1.000000"
          stroke="none"
          d="
M1422.926147,2235.049316 
	C1422.876587,2234.958740 1422.975708,2235.140137 1422.926147,2235.049316 
z"
        />
        <path
          fill="#2E2D30"
          opacity="1.000000"
          stroke="none"
          d="
M1531.634521,2241.918945 
	C1532.004639,2242.073486 1532.023438,2242.464600 1531.996826,2243.441895 
	C1531.586792,2243.643555 1531.181763,2243.287109 1530.888306,2242.854736 
	C1530.821411,2242.756348 1531.141113,2242.395508 1531.634521,2241.918945 
z"
        />
        <path
          fill="#2C2520"
          opacity="1.000000"
          stroke="none"
          d="
M1460.558105,2344.073486 
	C1462.011841,2337.631592 1468.342041,2340.117432 1473.175049,2337.953613 
	C1479.232300,2340.141357 1478.738892,2344.901123 1479.078613,2350.133789 
	C1479.462280,2352.582764 1480.048950,2354.141602 1480.539062,2355.727295 
	C1481.241089,2357.999512 1481.948608,2360.434082 1479.804932,2362.177246 
	C1476.857300,2364.573975 1474.259155,2363.221924 1471.571899,2360.195801 
	C1470.227783,2354.626465 1468.188232,2354.121582 1463.865112,2356.842041 
	C1459.039795,2359.878418 1455.934814,2366.167236 1448.378052,2365.722656 
	C1446.724854,2366.863037 1448.200073,2366.037109 1447.107056,2366.793701 
	C1446.018066,2367.547119 1444.809326,2368.026367 1443.637207,2368.595703 
	C1444.786987,2368.107422 1445.924561,2367.609619 1446.987671,2366.930176 
	C1448.109009,2366.213135 1446.606689,2366.995117 1448.288208,2365.860840 
	C1454.021606,2365.642822 1458.991699,2362.681396 1464.913208,2364.656250 
	C1468.122681,2368.855713 1466.200562,2371.419922 1462.617065,2372.390625 
	C1454.285522,2374.647949 1448.890869,2380.532715 1443.103027,2387.130371 
	C1441.468140,2388.428467 1440.995361,2390.100830 1439.321411,2390.295166 
	C1440.545654,2390.564453 1440.853882,2389.537842 1441.836670,2388.341553 
	C1442.764526,2385.838135 1444.051392,2391.001465 1445.092651,2387.322266 
	C1447.131348,2380.119141 1451.467773,2375.726562 1460.289429,2380.126465 
	C1461.510986,2381.251709 1462.071655,2382.012695 1462.772949,2383.514404 
	C1462.707886,2388.355713 1463.434937,2391.084229 1468.339722,2392.899414 
	C1472.540771,2394.454346 1478.893311,2396.245605 1476.633301,2404.152344 
	C1467.739990,2406.519775 1459.154053,2403.649902 1450.680908,2405.332520 
	C1448.684570,2405.729004 1446.391602,2403.975586 1444.818237,2407.205078 
	C1441.213867,2414.603271 1437.027222,2415.623291 1425.919067,2412.319824 
	C1418.722412,2410.179932 1415.683350,2404.319336 1416.508423,2397.739502 
	C1417.466309,2390.101074 1415.388672,2389.230957 1409.640381,2392.946045 
	C1406.062256,2395.258301 1404.852417,2393.345459 1403.993774,2390.471436 
	C1402.898315,2386.804688 1403.414429,2383.105469 1405.083862,2379.787109 
	C1408.044556,2373.902588 1408.488159,2367.866943 1406.949951,2362.187988 
	C1405.607056,2364.987793 1411.017822,2370.529785 1404.220337,2372.174316 
	C1395.339600,2374.322754 1391.478516,2371.863281 1389.188477,2362.765137 
	C1388.789551,2359.205811 1389.273804,2356.300781 1388.487061,2352.738770 
	C1388.397949,2351.004395 1388.612793,2350.042725 1389.525391,2348.575684 
	C1397.285278,2347.238770 1401.542236,2343.788574 1399.968262,2334.489014 
	C1405.402588,2337.575928 1409.536743,2338.892090 1413.091675,2333.389893 
	C1414.658691,2330.964844 1417.262939,2332.556641 1417.768311,2335.532715 
	C1418.180786,2337.962891 1417.502075,2340.884277 1415.699951,2341.842041 
	C1407.979126,2345.946289 1412.502808,2351.593994 1412.758057,2356.977539 
	C1419.015869,2357.017090 1423.426880,2354.618896 1426.619019,2349.983643 
	C1427.797363,2348.272705 1427.167114,2346.926270 1424.629639,2346.098633 
	C1424.250244,2343.887451 1425.146484,2342.931641 1427.538940,2342.477051 
	C1430.836792,2341.850098 1433.942993,2340.215332 1437.565674,2338.506104 
	C1442.120605,2342.769775 1435.583008,2344.497314 1435.062988,2348.708984 
	C1441.649048,2349.930420 1443.905396,2343.200928 1448.540283,2341.396240 
	C1453.676392,2344.024902 1450.025879,2348.301025 1450.542725,2353.036865 
	C1454.789429,2350.086426 1459.642334,2349.541748 1462.482056,2345.311768 
	C1462.558228,2345.198242 1462.239014,2344.720459 1462.008179,2344.526855 
	C1461.768433,2344.325439 1461.412109,2344.262939 1460.558105,2344.073486 
z"
        />
        <path
          fill="#442F21"
          opacity="1.000000"
          stroke="none"
          d="
M1523.994385,2321.267578 
	C1523.995605,2323.500000 1523.986328,2325.000244 1523.990967,2327.250000 
	C1521.274414,2329.740479 1521.037720,2332.654785 1520.637939,2335.487549 
	C1521.441406,2333.306641 1520.741089,2330.539062 1523.525146,2328.479004 
	C1526.142212,2329.127441 1525.701660,2331.830566 1527.773804,2333.680664 
	C1534.030029,2339.678711 1533.731323,2341.339600 1525.497681,2346.331543 
	C1524.450684,2346.705566 1524.041382,2346.863281 1522.504639,2347.648193 
	C1519.588135,2349.467773 1518.033936,2351.124512 1515.013184,2351.039551 
	C1513.986450,2350.690186 1513.608521,2350.463623 1512.783813,2349.758545 
	C1508.888672,2344.440918 1502.307373,2348.582275 1498.010498,2344.158691 
	C1497.077637,2343.537109 1496.734131,2343.244141 1496.076416,2342.351074 
	C1497.424438,2336.516846 1504.707886,2337.339355 1506.420776,2331.033447 
	C1501.657349,2329.953857 1498.338013,2324.335205 1491.851562,2327.406494 
	C1489.677246,2328.435791 1488.388062,2326.258545 1488.143066,2323.986328 
	C1487.844971,2321.220215 1488.255493,2318.717285 1490.352539,2316.633301 
	C1491.979126,2315.017090 1493.896851,2313.505127 1493.770508,2310.829346 
	C1493.596191,2309.926758 1493.134521,2309.655518 1492.382202,2310.013428 
	C1492.382202,2310.013428 1477.471069,2316.971191 1477.471069,2316.971191 
	C1476.866943,2317.242920 1476.270752,2317.534424 1475.656982,2317.782227 
	C1473.529785,2318.641602 1471.396484,2319.486084 1469.179810,2320.370605 
	C1467.207520,2317.467285 1468.182007,2314.729980 1469.146240,2311.891113 
	C1470.566528,2307.709717 1473.796631,2309.555908 1476.556885,2309.230469 
	C1478.946289,2308.948730 1480.691528,2306.846436 1481.535645,2304.688477 
	C1483.148682,2300.563965 1486.535767,2297.783936 1489.145508,2293.688965 
	C1491.766357,2300.133057 1494.411011,2306.238525 1499.671509,2311.696533 
	C1501.221802,2311.828125 1501.568481,2311.822266 1502.950317,2311.916504 
	C1510.645508,2312.751953 1510.772827,2320.351074 1516.173096,2323.816650 
	C1518.098389,2319.287842 1519.252808,2314.689453 1522.724243,2311.502441 
	C1523.815918,2310.500244 1525.058350,2310.025391 1526.267822,2310.773193 
	C1527.693115,2311.654297 1527.316895,2313.105713 1526.485474,2314.208252 
	C1525.052124,2316.108154 1523.844727,2318.039307 1523.994385,2321.267578 
z"
        />
        <path
          fill="#2C2520"
          opacity="1.000000"
          stroke="none"
          d="
M1380.648560,2300.402100 
	C1380.229492,2298.385010 1380.211060,2296.910889 1380.265503,2294.700439 
	C1382.502563,2292.799805 1384.666748,2291.635498 1387.432617,2290.248535 
	C1387.967651,2292.840088 1388.132080,2295.566650 1391.400146,2297.673828 
	C1394.637329,2294.466064 1399.042603,2293.654785 1402.629883,2289.794922 
	C1404.618286,2296.540039 1403.623779,2303.142090 1408.895386,2307.196289 
	C1405.771362,2314.314209 1407.291870,2321.796631 1407.110596,2329.150391 
	C1407.098389,2329.651123 1406.280518,2330.555664 1405.836060,2330.556396 
	C1401.246338,2330.563965 1396.765137,2333.791748 1391.968872,2330.486328 
	C1391.183228,2329.150391 1390.664673,2328.286133 1390.687256,2327.275146 
	C1390.706665,2326.402588 1390.609253,2325.414795 1391.506592,2324.800537 
	C1390.416260,2324.814941 1390.246094,2326.178955 1388.647461,2326.153809 
	C1387.897949,2323.039551 1388.402832,2320.002197 1386.308350,2316.674316 
	C1385.783813,2310.515625 1383.066895,2305.844971 1380.648560,2300.402100 
z"
        />
        <path
          fill="#070709"
          opacity="1.000000"
          stroke="none"
          d="
M1538.012329,2254.593994 
	C1538.891113,2253.998779 1539.757324,2254.068604 1541.272705,2254.096191 
	C1541.922119,2254.054199 1541.964966,2254.016113 1541.970947,2254.027100 
	C1542.407227,2254.490967 1542.856567,2254.924561 1543.643311,2255.681641 
	C1543.962158,2256.024902 1544.000000,2256.000000 1543.980469,2256.017334 
	C1544.860596,2256.856689 1544.955444,2258.024658 1545.629639,2259.539307 
	C1549.771851,2270.013672 1548.674683,2276.948730 1542.539551,2281.386963 
	C1540.308350,2283.000977 1537.958740,2284.224121 1535.205200,2282.458252 
	C1533.769531,2281.537598 1532.069824,2280.399170 1533.645020,2278.620605 
	C1537.740112,2273.996826 1535.047241,2271.602539 1531.006714,2269.433350 
	C1528.349854,2268.007080 1525.283813,2266.626465 1526.431274,2262.783936 
	C1527.415039,2259.489014 1530.317139,2258.182129 1533.434692,2258.355957 
	C1536.068726,2258.503174 1538.139038,2258.784668 1538.012329,2254.593994 
z"
        />
        <path
          fill="#2E2D30"
          opacity="1.000000"
          stroke="none"
          d="
M1459.717163,2304.151367 
	C1457.204224,2298.753174 1457.002563,2293.376465 1453.364502,2289.000000 
	C1449.784058,2284.693115 1453.249390,2280.236816 1454.570923,2275.898193 
	C1457.614502,2275.470947 1458.528442,2277.759033 1459.644531,2279.662598 
	C1463.093018,2285.543945 1463.017334,2285.869385 1470.848999,2287.217041 
	C1473.471680,2287.668213 1475.349731,2287.838379 1475.784790,2290.674805 
	C1476.610962,2296.061523 1472.507324,2298.989014 1469.781616,2302.645020 
	C1467.134766,2306.195068 1464.036865,2306.793945 1459.717163,2304.151367 
z"
        />
        <path
          fill="#423C3E"
          opacity="1.000000"
          stroke="none"
          d="
M1449.499878,2297.633789 
	C1449.974243,2300.612305 1449.974243,2303.240967 1449.974243,2306.275879 
	C1445.530640,2306.898438 1441.282959,2302.044434 1438.216064,2307.154053 
	C1435.080811,2304.735840 1436.600098,2301.675537 1435.576782,2299.317871 
	C1434.523315,2296.890869 1433.414551,2294.396240 1434.737061,2291.678711 
	C1438.236816,2289.313232 1438.243164,2295.407227 1441.354126,2294.111328 
	C1443.068481,2291.757812 1441.006470,2288.366943 1442.979248,2285.805176 
	C1449.217285,2287.434082 1449.307861,2292.260498 1449.499878,2297.633789 
z"
        />
        <path
          fill="#070709"
          opacity="1.000000"
          stroke="none"
          d="
M1536.113403,2301.427246 
	C1536.120117,2302.394043 1536.093506,2302.729980 1536.048584,2303.572021 
	C1531.103516,2304.401123 1529.874023,2301.572266 1529.969971,2297.283936 
	C1530.050293,2293.697266 1528.579590,2291.380127 1524.525757,2292.834229 
	C1521.951416,2293.757812 1520.054443,2293.058350 1518.345459,2291.196777 
	C1517.195557,2289.944336 1516.240967,2288.570557 1517.056396,2286.805420 
	C1517.806885,2285.181152 1519.329834,2283.999268 1520.986816,2284.399902 
	C1526.395874,2285.707520 1531.938721,2286.853271 1537.005371,2289.043457 
	C1540.200317,2290.424072 1541.926514,2293.910645 1538.115723,2296.798584 
	C1536.528442,2298.001221 1536.116455,2299.135010 1536.113403,2301.427246 
z"
        />
        <path
          fill="#2C2520"
          opacity="1.000000"
          stroke="none"
          d="
M1438.408691,2337.436035 
	C1436.094604,2336.567627 1431.659790,2340.772705 1431.811035,2334.562744 
	C1429.752075,2333.749023 1427.342285,2334.563721 1424.771240,2332.393555 
	C1428.399902,2328.120117 1432.796387,2325.181885 1438.219116,2324.162109 
	C1440.612061,2323.712402 1443.739624,2323.178467 1445.247070,2326.174561 
	C1446.603760,2328.870605 1445.591431,2331.367188 1443.522461,2333.309814 
	C1442.096313,2334.648682 1440.395508,2335.694824 1438.408691,2337.436035 
z"
        />
        <path
          fill="#442F21"
          opacity="1.000000"
          stroke="none"
          d="
M1476.713623,2352.515137 
	C1475.670776,2347.872803 1476.770996,2343.438232 1474.276001,2338.676514 
	C1474.956055,2336.042480 1476.229126,2334.438965 1479.137451,2333.938477 
	C1480.826050,2335.068115 1481.557739,2336.588135 1483.618652,2337.635742 
	C1487.146362,2340.437012 1489.725830,2344.091064 1495.134521,2341.841797 
	C1495.762451,2341.750732 1496.092896,2342.092285 1496.258911,2342.262207 
	C1496.937744,2343.285645 1497.133423,2344.206543 1496.706909,2345.852051 
	C1496.037109,2346.824463 1495.674561,2347.139160 1494.676514,2347.781738 
	C1488.034912,2348.255127 1482.903320,2351.450928 1476.713623,2352.515137 
z"
        />
        <path
          fill="#2E2D30"
          opacity="1.000000"
          stroke="none"
          d="
M1483.196289,2270.136475 
	C1482.172363,2270.786621 1481.415161,2271.101318 1480.935059,2270.861328 
	C1478.160278,2269.474854 1475.824707,2267.599121 1476.697144,2264.056396 
	C1477.191772,2262.048340 1479.204468,2261.989258 1480.753662,2262.361816 
	C1484.928955,2263.366211 1483.815552,2266.677490 1483.196289,2270.136475 
z"
        />
        <path
          fill="#0C0B0A"
          opacity="1.000000"
          stroke="none"
          d="
M1387.610352,2326.275146 
	C1390.587036,2324.651611 1389.349121,2320.729736 1392.921143,2319.494873 
	C1394.995728,2322.765137 1391.264526,2325.524414 1391.897461,2329.194580 
	C1390.990112,2329.933350 1390.132812,2329.960205 1388.630371,2329.990234 
	C1386.660522,2329.104980 1386.058716,2328.045654 1387.610352,2326.275146 
z"
        />
        <path
          fill="#2E2D30"
          opacity="1.000000"
          stroke="none"
          d="
M1454.492798,2271.110596 
	C1451.359497,2272.042236 1449.336060,2271.215088 1448.967041,2268.422119 
	C1448.700439,2266.403564 1450.600464,2265.392578 1452.147583,2265.577881 
	C1455.053955,2265.925537 1455.337891,2268.266602 1454.492798,2271.110596 
z"
        />
        <path
          fill="#2E2D30"
          opacity="1.000000"
          stroke="none"
          d="
M1461.693970,2315.499512 
	C1458.618286,2315.062256 1457.613647,2313.596436 1459.018066,2311.373779 
	C1459.885132,2310.001465 1461.659180,2308.685791 1463.080200,2310.114746 
	C1464.682373,2311.725830 1463.927124,2313.766357 1461.693970,2315.499512 
z"
        />
        <path
          fill="#442F21"
          opacity="1.000000"
          stroke="none"
          d="
M1499.907104,2311.913086 
	C1500.139526,2310.119873 1499.360596,2307.559814 1501.809814,2307.220459 
	C1504.002319,2306.916504 1503.577148,2309.250000 1503.924072,2311.346680 
	C1503.985352,2312.016602 1504.003906,2311.997070 1504.014648,2311.990234 
	C1503.278687,2312.689209 1502.394043,2312.994629 1500.745850,2312.507324 
	C1500.120483,2312.114746 1499.978638,2311.979980 1499.907104,2311.913086 
z"
        />
        <path
          fill="#0C0B0A"
          opacity="1.000000"
          stroke="none"
          d="
M1389.789062,2347.600098 
	C1390.043701,2348.916748 1390.048950,2349.804199 1390.067871,2351.356934 
	C1388.666016,2352.786865 1387.519165,2352.314941 1386.248779,2350.617432 
	C1386.340942,2348.899902 1386.686646,2347.809570 1387.494385,2346.364258 
	C1388.598145,2346.241211 1389.125977,2346.628418 1389.789062,2347.600098 
z"
        />
        <path
          fill="#2C2520"
          opacity="1.000000"
          stroke="none"
          d="
M1416.710205,2320.147461 
	C1418.287354,2321.120850 1418.240601,2322.473389 1417.297363,2323.844238 
	C1417.226440,2323.947510 1416.756958,2323.949219 1416.666748,2323.839111 
	C1415.814209,2322.798584 1416.031860,2321.624268 1416.710205,2320.147461 
z"
        />
        <path
          fill="#2C2520"
          opacity="1.000000"
          stroke="none"
          d="
M1389.125732,2284.101562 
	C1388.382812,2285.465576 1387.634399,2285.470703 1386.974487,2284.502930 
	C1386.835571,2284.299072 1386.838623,2283.829346 1386.982056,2283.630859 
	C1387.671753,2282.676270 1388.419067,2282.724121 1389.125732,2284.101562 
z"
        />
        <path
          fill="#2E2D30"
          opacity="1.000000"
          stroke="none"
          d="
M1479.006470,2288.944336 
	C1478.495972,2287.925049 1478.755737,2287.318359 1479.407837,2287.433350 
	C1479.853516,2287.511719 1480.456909,2288.131104 1480.524292,2288.578857 
	C1480.623169,2289.233643 1480.009888,2289.479980 1479.006470,2288.944336 
z"
        />
        <path
          fill="#564031"
          opacity="1.000000"
          stroke="none"
          d="
M1483.857178,2337.422363 
	C1482.218506,2337.842041 1480.631836,2337.257568 1479.927734,2334.527832 
	C1481.560303,2334.200928 1482.709351,2335.363037 1483.857178,2337.422363 
z"
        />
        <path
          fill="#2C2520"
          opacity="1.000000"
          stroke="none"
          d="
M1388.495728,2341.976562 
	C1387.970703,2341.121582 1387.963745,2340.213867 1387.959229,2338.624512 
	C1389.416260,2338.978027 1392.339478,2339.625244 1388.495728,2341.976562 
z"
        />
        <path
          fill="#2C2520"
          opacity="1.000000"
          stroke="none"
          d="
M1420.089844,2329.387695 
	C1418.912964,2329.910400 1418.065308,2329.958008 1417.650024,2328.939209 
	C1417.604614,2328.827637 1417.970459,2328.415283 1418.204956,2328.346924 
	C1419.071289,2328.094727 1419.912964,2328.072754 1420.089844,2329.387695 
z"
        />
        <path
          fill="#2E2D30"
          opacity="1.000000"
          stroke="none"
          d="
M1545.948730,2259.388184 
	C1545.305908,2259.063721 1544.630005,2258.144043 1543.957520,2256.629395 
	C1545.118164,2256.595703 1545.719482,2257.550537 1545.948730,2259.388184 
z"
        />
        <path
          fill="#2C2520"
          opacity="1.000000"
          stroke="none"
          d="
M1423.996338,2345.995117 
	C1423.336304,2346.461670 1422.679688,2346.932861 1422.023071,2347.404053 
	C1421.867554,2347.170410 1421.712036,2346.937012 1421.556519,2346.703369 
	C1422.073364,2346.486084 1422.590210,2346.268799 1423.559326,2346.030762 
	C1424.011719,2346.009766 1424.000000,2346.000000 1423.996338,2345.995117 
z"
        />
        <path
          fill="#0C0D0F"
          opacity="1.000000"
          stroke="none"
          d="
M1371.617920,2269.557617 
	C1370.872803,2269.666260 1369.786499,2269.298584 1368.356934,2268.455566 
	C1369.101440,2268.347412 1370.189087,2268.714355 1371.617920,2269.557617 
z"
        />
        <path
          fill="#2E2D30"
          opacity="1.000000"
          stroke="none"
          d="
M1448.855591,2277.518555 
	C1449.543823,2278.391113 1449.671509,2278.933594 1448.855591,2277.518555 
z"
        />
        <path
          fill="#2C2520"
          opacity="1.000000"
          stroke="none"
          d="
M1459.996826,2343.995117 
	C1459.837158,2344.064209 1459.680664,2344.138184 1459.766846,2344.109863 
	C1460.009521,2344.007568 1460.000000,2344.000000 1459.996826,2343.995117 
z"
        />
        <path
          fill="#2E2D30"
          opacity="1.000000"
          stroke="none"
          d="
M1543.765869,2255.575195 
	C1543.410522,2255.679199 1542.859009,2255.333252 1542.142090,2254.512695 
	C1542.644165,2254.200684 1543.175049,2254.563232 1543.765869,2255.575195 
z"
        />
        <path
          fill="#0C0D0F"
          opacity="1.000000"
          stroke="none"
          d="
M1378.923218,2241.054688 
	C1378.878540,2240.972656 1378.967896,2241.136475 1378.923218,2241.054688 
z"
        />
        <path
          fill="#442F21"
          opacity="1.000000"
          stroke="none"
          d="
M1545.490234,2298.213867 
	C1545.882324,2298.227051 1545.310181,2298.805664 1545.310181,2298.805664 
	C1545.310181,2298.805664 1544.566284,2298.706543 1544.440186,2298.466064 
	C1544.314087,2298.225586 1545.098145,2298.200928 1545.490234,2298.213867 
z"
        />
        <path
          fill="#423C3E"
          opacity="1.000000"
          stroke="none"
          d="
M1450.008789,2298.012695 
	C1450.017456,2298.025635 1450.442871,2297.239502 1450.442871,2297.239502 
	C1450.442871,2297.239502 1450.389282,2297.760986 1450.181763,2297.872314 
	C1449.974243,2297.983643 1450.000000,2298.000000 1450.008789,2298.012695 
z"
        />
        <path
          fill="#2C2520"
          opacity="1.000000"
          stroke="none"
          d="
M1387.988037,2289.985352 
	C1388.193115,2290.029541 1388.033203,2288.843262 1388.280273,2289.914307 
	C1388.034180,2290.026123 1388.002197,2290.000000 1387.988037,2289.985352 
z"
        />
        <path
          fill="#0C0D0F"
          opacity="1.000000"
          stroke="none"
          d="
M1377.044434,2271.073730 
	C1376.956177,2271.121582 1377.132690,2271.025635 1377.044434,2271.073730 
z"
        />
        <path
          fill="#1B1E22"
          opacity="1.000000"
          stroke="none"
          d="
M1391.632080,2205.576172 
	C1391.000610,2206.515381 1390.026001,2207.020996 1388.547974,2207.745117 
	C1388.394165,2206.181396 1388.377319,2203.977783 1391.632080,2205.576172 
z"
        />
        <path
          fill="#1A1615"
          opacity="1.000000"
          stroke="none"
          d="
M1389.055908,2201.071777 
	C1388.964966,2201.121826 1389.146973,2201.021484 1389.055908,2201.071777 
z"
        />
        <path
          fill="#1B1E22"
          opacity="1.000000"
          stroke="none"
          d="
M1375.963135,2205.481445 
	C1375.653564,2205.567871 1375.050293,2205.209717 1374.157959,2204.401367 
	C1374.469116,2204.312988 1375.069214,2204.675049 1375.963135,2205.481445 
z"
        />
        <path
          fill="#DFD9CC"
          opacity="1.000000"
          stroke="none"
          d="
M1163.872559,1820.502197 
	C1165.988281,1818.793457 1165.547119,1817.300415 1165.039062,1815.653809 
	C1164.003052,1812.297363 1165.458374,1809.991333 1169.034912,1808.376709 
	C1170.268188,1809.698975 1170.885376,1811.093140 1171.549438,1813.063843 
	C1170.413818,1815.425171 1170.981934,1817.367310 1171.015991,1819.287598 
	C1171.061890,1821.880981 1169.777344,1823.541748 1166.372314,1824.100952 
	C1164.869385,1823.152100 1163.682373,1822.402344 1163.872559,1820.502197 
z"
        />
        <path
          fill="#797771"
          opacity="1.000000"
          stroke="none"
          d="
M1163.586426,1820.045044 
	C1164.586182,1821.056030 1164.878418,1822.114136 1165.391235,1823.687256 
	C1163.390503,1824.036987 1161.169312,1823.871460 1158.121826,1823.483154 
	C1158.375610,1820.827637 1160.354004,1819.978271 1163.586426,1820.045044 
z"
        />
        <path
          fill="#5A605E"
          opacity="1.000000"
          stroke="none"
          d="
M1307.895752,1867.468140 
	C1306.866577,1867.682861 1305.783936,1867.324707 1304.348145,1866.504639 
	C1305.795654,1863.989624 1307.037964,1864.455322 1307.895752,1867.468140 
z"
        />
        <path
          fill="#CBC6BA"
          opacity="1.000000"
          stroke="none"
          d="
M1923.281494,3063.485107 
	C1923.628906,3066.714600 1925.022339,3069.979736 1920.677368,3071.822021 
	C1917.561157,3074.178711 1915.702393,3076.938721 1913.060791,3078.895264 
	C1906.158691,3084.006836 1900.459839,3094.777344 1890.089478,3083.358154 
	C1888.645874,3081.768555 1885.025269,3082.679688 1882.062378,3081.040039 
	C1876.963867,3072.348633 1879.671509,3063.794678 1880.782471,3055.383301 
	C1881.261108,3051.760010 1884.663940,3050.204834 1887.797363,3048.939453 
	C1894.357910,3046.290283 1897.972412,3042.079834 1896.975708,3034.420166 
	C1895.927856,3026.367676 1902.375244,3025.602051 1907.901245,3024.475830 
	C1912.562744,3023.525635 1915.497192,3023.134033 1913.268555,3016.399170 
	C1911.053345,3009.705322 1914.004150,3001.194092 1916.099609,2995.497803 
	C1919.696045,2985.721924 1919.612671,2974.907227 1925.475586,2965.654541 
	C1930.003418,2958.509033 1934.605713,2951.054443 1937.108643,2942.665771 
	C1938.147949,2939.182861 1940.354248,2936.148682 1943.348999,2933.844971 
	C1950.859375,2928.069336 1956.177246,2920.518555 1961.206787,2911.783203 
	C1963.804443,2909.563965 1966.192749,2908.871582 1969.565430,2909.349365 
	C1974.943359,2916.916504 1969.982422,2922.592529 1966.080078,2927.322754 
	C1958.395874,2936.637451 1955.321289,2947.444580 1953.046143,2958.793945 
	C1952.382202,2962.106445 1951.880981,2965.295166 1954.761230,2968.715088 
	C1958.635742,2982.873047 1952.026245,2994.385254 1945.961548,3005.040771 
	C1939.750122,3015.954102 1934.455688,3028.052246 1923.683716,3035.894043 
	C1922.557007,3036.714355 1921.614746,3038.274414 1922.777222,3039.313232 
	C1931.734009,3047.318359 1923.088623,3052.803467 1920.069214,3059.073975 
	C1919.493164,3060.270264 1918.546265,3061.255615 1917.615234,3062.488525 
	C1919.252075,3063.717041 1921.234863,3061.734131 1923.281494,3063.485107 
z"
        />
        <path
          fill="#C4BDAE"
          opacity="1.000000"
          stroke="none"
          d="
M1802.617676,3303.649658 
	C1800.105957,3297.398682 1801.326416,3291.341797 1803.695068,3284.883789 
	C1808.488159,3271.815918 1812.687622,3258.570557 1820.170166,3246.666748 
	C1822.865234,3242.379150 1824.204590,3237.580811 1823.995239,3232.447510 
	C1823.784058,3227.272949 1825.404175,3223.296875 1828.918091,3219.019043 
	C1832.932495,3214.131836 1834.970215,3207.543213 1837.451782,3201.522949 
	C1838.197998,3199.712158 1836.439819,3198.552490 1834.429321,3198.942871 
	C1827.556030,3200.276611 1819.296143,3199.364990 1818.061523,3209.627441 
	C1817.809082,3211.725098 1816.346436,3213.827148 1814.221069,3215.230957 
	C1811.193970,3213.201172 1811.338623,3209.939453 1810.061523,3206.670898 
	C1809.322998,3205.249023 1808.876709,3204.424561 1808.622803,3202.783691 
	C1810.006714,3194.650879 1816.602539,3191.391602 1821.377686,3185.848633 
	C1821.390991,3176.467529 1822.963989,3169.336426 1830.304199,3162.495361 
	C1834.855591,3158.253418 1835.763184,3149.356689 1839.875977,3142.500977 
	C1840.782104,3141.744141 1841.190063,3141.495117 1842.302979,3141.095703 
	C1844.529297,3141.015625 1845.772705,3141.657959 1847.295044,3143.285645 
	C1850.251343,3154.672607 1846.531738,3164.507080 1842.499390,3174.241455 
	C1841.654175,3176.281982 1839.039917,3178.082520 1841.086304,3180.373047 
	C1842.954224,3182.463867 1844.513428,3179.836426 1846.216064,3179.213623 
	C1852.395874,3176.953857 1854.763550,3178.712891 1853.348755,3184.841553 
	C1851.335449,3193.562988 1848.109375,3201.822510 1844.354980,3210.015137 
	C1841.267456,3216.752686 1835.511963,3223.366699 1839.514282,3231.996826 
	C1840.781616,3234.729492 1839.012573,3236.911377 1836.794556,3238.825928 
	C1832.269409,3242.730713 1834.376709,3248.069824 1834.227417,3252.886719 
	C1834.190918,3254.060059 1835.306396,3254.416016 1836.311890,3254.556885 
	C1841.355591,3255.263672 1839.191895,3258.372070 1837.604736,3260.000488 
	C1834.024048,3263.676270 1831.564575,3267.994629 1829.767578,3272.593262 
	C1827.395142,3278.662842 1821.303223,3280.689209 1817.142334,3284.692383 
	C1811.361206,3290.254150 1806.509399,3296.069580 1802.617676,3303.649658 
z"
        />
        <path
          fill="#D3CBBF"
          opacity="1.000000"
          stroke="none"
          d="
M1924.258301,3063.724121 
	C1920.903687,3063.854248 1918.687012,3064.686523 1917.568726,3067.744629 
	C1916.901611,3069.569336 1915.166382,3070.112793 1913.525757,3069.007324 
	C1911.841797,3067.873047 1912.086548,3066.036865 1913.017822,3064.566162 
	C1916.908203,3058.421143 1920.914551,3052.349365 1924.824097,3046.334473 
	C1925.031860,3045.022705 1924.208008,3044.415039 1923.368896,3043.873291 
	C1914.295776,3038.010986 1914.412231,3038.201172 1923.135376,3032.602051 
	C1926.503174,3030.440186 1931.146240,3028.683838 1930.105713,3022.959961 
	C1929.815674,3021.365479 1930.185791,3019.155273 1931.992798,3018.747314 
	C1937.695190,3017.459961 1939.149048,3013.821289 1938.454102,3008.586182 
	C1938.388550,3008.091797 1939.026978,3007.434570 1939.452637,3006.953857 
	C1947.706665,2997.635742 1953.189941,2984.391357 1954.018311,2970.884766 
	C1954.048706,2969.199219 1954.244507,2968.381592 1954.963623,2967.069824 
	C1956.396362,2965.217041 1957.236206,2963.784912 1957.543945,2961.386475 
	C1958.275757,2958.966309 1959.163574,2957.413818 1961.458374,2956.206543 
	C1968.265381,2955.878418 1974.429565,2956.482422 1980.813477,2954.553467 
	C1981.014038,2959.966553 1979.481323,2964.210449 1978.038208,2969.794678 
	C1977.814331,2972.216553 1977.779541,2973.343018 1977.692383,2975.060059 
	C1977.515137,2976.458984 1977.242676,2977.219971 1976.402832,2978.374023 
	C1970.953613,2981.443848 1970.157104,2987.165039 1966.471069,2991.560547 
	C1966.029053,2992.033691 1966.000000,2992.000000 1966.016846,2992.008789 
	C1963.472412,2996.706787 1960.253906,3000.939941 1956.515625,3005.622070 
	C1953.082275,3017.204102 1946.809570,3026.457275 1939.851807,3035.329834 
	C1937.307495,3038.574463 1935.746582,3042.166016 1935.945068,3047.156982 
	C1932.539551,3052.020752 1927.687500,3055.080322 1926.389160,3061.446777 
	C1925.539307,3062.477051 1925.124512,3062.990967 1924.258301,3063.724121 
z"
        />
        <path
          fill="#C4BDAE"
          opacity="1.000000"
          stroke="none"
          d="
M1881.264404,3079.804199 
	C1884.293335,3079.972168 1886.613159,3079.917236 1888.927124,3080.009033 
	C1893.636963,3080.195557 1900.174072,3078.092529 1896.077759,3089.120117 
	C1905.455688,3082.827637 1910.245117,3075.055176 1919.281494,3072.069824 
	C1918.923950,3076.477051 1917.990112,3080.921875 1917.867676,3085.634033 
	C1917.614136,3095.392090 1912.345093,3100.100830 1902.757080,3099.145752 
	C1899.920654,3098.863037 1898.563965,3099.536377 1897.029053,3101.567871 
	C1895.052734,3104.183838 1892.744507,3106.548828 1890.704102,3108.879395 
	C1889.321411,3108.785156 1888.650513,3108.179199 1888.286499,3107.188965 
	C1887.389404,3104.750244 1888.963867,3100.026123 1884.375732,3100.850586 
	C1880.439575,3101.557861 1883.193848,3105.150635 1883.286133,3107.499023 
	C1883.361938,3109.425781 1882.258545,3110.688477 1880.788696,3111.463135 
	C1877.412598,3113.242188 1877.330200,3115.707764 1876.934204,3119.396484 
	C1876.423828,3124.148438 1873.776855,3128.767822 1867.252319,3130.303467 
	C1861.160400,3131.737061 1854.746338,3133.604980 1848.840332,3137.898193 
	C1846.198486,3136.645996 1845.914185,3134.599121 1846.100220,3131.662598 
	C1849.323486,3108.232910 1867.298462,3095.520996 1881.264404,3079.804199 
z"
        />
        <path
          fill="#C4BDAE"
          opacity="1.000000"
          stroke="none"
          d="
M1975.593506,2978.500977 
	C1975.961670,2977.090332 1975.970947,2976.207275 1975.985962,2974.662109 
	C1976.036133,2972.808105 1976.406128,2971.738037 1977.585449,2970.404541 
	C1977.990234,2969.990234 1978.000000,2970.000000 1977.996826,2969.995117 
	C1981.207642,2970.366455 1982.984619,2966.880859 1986.529907,2966.895264 
	C1989.543457,2970.244873 1985.677002,2973.533447 1986.063354,2976.905762 
	C1986.583984,2981.447754 1991.422729,2981.258057 1994.043213,2984.843018 
	C1985.982300,2987.534668 1985.466675,2995.031250 1982.067017,3000.673340 
	C1979.244751,3005.357178 1980.939087,3010.415039 1980.735840,3014.895996 
	C1980.302002,3024.469238 1975.591431,3028.148926 1966.776733,3029.991943 
	C1965.720825,3025.243164 1967.015747,3021.738525 1970.549805,3017.745361 
	C1976.245239,3011.310303 1978.496582,3002.339111 1978.639160,2993.887939 
	C1978.690430,2990.849609 1978.389648,2988.102783 1979.565308,2985.146973 
	C1980.354614,2983.163086 1979.659790,2981.210938 1976.695557,2980.167480 
	C1975.763672,2979.695801 1975.499023,2979.362305 1975.593506,2978.500977 
z"
        />
        <path
          fill="#E1D9C6"
          opacity="1.000000"
          stroke="none"
          d="
M1848.126709,3129.225586 
	C1848.097900,3132.209717 1848.070923,3134.337402 1848.088013,3137.264648 
	C1848.132080,3138.063721 1847.894287,3139.021973 1847.783936,3139.502930 
	C1847.044189,3140.920654 1846.219604,3141.592529 1844.562744,3141.911133 
	C1844.016968,3141.946533 1843.048340,3142.008545 1842.563232,3142.023438 
	C1837.221924,3144.973145 1833.850952,3142.683105 1831.695557,3138.503174 
	C1829.351318,3133.957520 1832.565552,3131.242188 1835.938354,3128.944092 
	C1837.279053,3128.030273 1838.789673,3127.375732 1841.133911,3126.525635 
	C1844.353638,3125.980469 1846.550415,3126.263916 1848.126709,3129.225586 
z"
        />
        <path
          fill="#CBC6BA"
          opacity="1.000000"
          stroke="none"
          d="
M1956.838867,3006.076172 
	C1954.383179,2998.658203 1960.923218,2996.503174 1965.416992,2992.323486 
	C1966.138672,2998.444092 1966.340088,3004.929199 1956.838867,3006.076172 
z"
        />
        <path
          fill="#C4BDAE"
          opacity="1.000000"
          stroke="none"
          d="
M1926.565430,3061.653809 
	C1925.383545,3055.231201 1928.397217,3050.723633 1935.222900,3048.040527 
	C1936.306763,3054.553711 1929.656738,3056.590088 1926.565430,3061.653809 
z"
        />
        <path
          fill="#CBC6BA"
          opacity="1.000000"
          stroke="none"
          d="
M1975.261230,2978.075195 
	C1975.965210,2978.315674 1975.978149,2978.658447 1976.009521,2979.515137 
	C1971.895020,2983.043945 1973.358276,2990.146729 1966.717651,2991.933105 
	C1966.078369,2985.690430 1969.405396,2981.367188 1975.261230,2978.075195 
z"
        />
        <path
          fill="#C4BDAE"
          opacity="1.000000"
          stroke="none"
          d="
M1851.776367,3231.119141 
	C1851.848389,3234.078125 1852.351929,3236.719482 1849.336304,3237.488525 
	C1847.629028,3237.923828 1846.121582,3237.063965 1845.200073,3235.492676 
	C1844.184448,3233.761230 1844.938232,3232.251221 1846.144043,3231.071045 
	C1847.731812,3229.516846 1849.426880,3227.282471 1851.776367,3231.119141 
z"
        />
        <path
          fill="#C4BDAE"
          opacity="1.000000"
          stroke="none"
          d="
M1857.755127,3217.435791 
	C1861.146118,3220.035156 1858.803589,3221.246094 1856.882080,3222.416992 
	C1855.230713,3223.423340 1853.324097,3224.382568 1851.519043,3222.997314 
	C1849.988037,3221.822998 1849.972534,3219.919678 1851.596924,3218.994141 
	C1853.196655,3218.083252 1855.245605,3217.961182 1857.755127,3217.435791 
z"
        />
        <path
          fill="#C4BDAE"
          opacity="1.000000"
          stroke="none"
          d="
M1856.787598,3209.287598 
	C1857.371460,3211.636719 1856.345093,3212.767334 1854.578979,3213.111816 
	C1853.433716,3213.334961 1852.650024,3212.536865 1852.910034,3211.386963 
	C1853.306885,3209.632080 1854.452393,3208.628906 1856.787598,3209.287598 
z"
        />
        <path
          fill="#DBD5C8"
          opacity="1.000000"
          stroke="none"
          d="
M1844.341553,3142.408203 
	C1844.877563,3141.335449 1845.738281,3140.724365 1847.136230,3140.048828 
	C1847.736938,3140.831299 1847.800415,3141.678711 1847.835083,3143.159180 
	C1846.759644,3143.484619 1845.712891,3143.177246 1844.341553,3142.408203 
z"
        />
        <path
          fill="#E1D9C6"
          opacity="1.000000"
          stroke="none"
          d="
M1683.993652,3472.004883 
	C1684.616089,3472.165527 1685.276001,3472.246094 1685.867188,3472.491943 
	C1687.234985,3473.060791 1687.870728,3474.126221 1687.474976,3475.582764 
	C1687.196045,3476.608887 1686.327148,3477.342773 1685.350708,3476.785645 
	C1684.123291,3476.084717 1684.114380,3474.624756 1684.025146,3472.657715 
	C1684.012451,3471.990234 1684.000000,3472.000000 1683.993652,3472.004883 
z"
        />
        <path
          fill="#E8E1D2"
          opacity="1.000000"
          stroke="none"
          d="
M2082.505371,2845.540039 
	C2081.067139,2857.076660 2075.762939,2866.964844 2073.687256,2877.706299 
	C2073.564209,2878.342041 2073.360352,2879.110107 2072.917969,2879.515137 
	C2061.243408,2890.207520 2060.524414,2906.254883 2053.503174,2919.215820 
	C2052.721924,2920.657959 2052.504395,2922.404785 2051.727051,2923.850098 
	C2049.093018,2928.747070 2051.672852,2934.861084 2048.720703,2939.266602 
	C2046.175537,2943.064209 2039.713867,2943.284912 2038.819092,2949.383057 
	C2038.541870,2951.272461 2035.911011,2952.326416 2033.875977,2951.233154 
	C2031.647949,2950.035889 2032.329468,2947.890625 2033.079346,2945.970703 
	C2034.164185,2943.193604 2035.270386,2940.424316 2036.331665,2937.638184 
	C2036.916992,2936.101807 2037.990723,2934.347900 2037.015747,2932.884521 
	C2029.972168,2922.310059 2034.760864,2912.119629 2039.236450,2902.895996 
	C2042.265259,2896.654053 2042.178223,2890.084473 2040.987671,2885.014648 
	C2039.813232,2880.014160 2040.434570,2876.673096 2042.016724,2872.502441 
	C2043.108887,2869.623291 2045.402466,2866.542480 2042.649170,2863.294922 
	C2041.983032,2863.456299 2041.149048,2863.400879 2040.845337,2863.775146 
	C2039.306641,2865.671631 2039.305176,2869.858398 2035.868530,2868.841064 
	C2032.738647,2867.914551 2032.812378,2864.752197 2033.051758,2861.410889 
	C2033.686279,2852.552002 2030.502808,2843.665527 2032.090698,2834.843506 
	C2033.714600,2825.821533 2030.812378,2817.894287 2028.015381,2809.767090 
	C2027.449341,2808.122314 2027.012329,2806.439209 2024.946289,2806.566406 
	C2022.562134,2806.712646 2023.116333,2808.500244 2023.013306,2810.198975 
	C2022.515015,2818.407959 2021.781616,2826.602295 2020.586670,2835.410645 
	C2013.162598,2834.716064 2014.831299,2828.808350 2015.350342,2825.086670 
	C2017.319336,2810.963379 2010.380249,2796.885986 2015.465454,2782.854492 
	C2015.833496,2781.839111 2015.148926,2781.156006 2014.429077,2780.394287 
	C2004.556885,2769.949951 2004.570312,2769.937256 2013.297974,2760.558105 
	C2010.247559,2762.202881 2006.974243,2765.099854 2003.621460,2761.525635 
	C2001.255981,2759.003906 2002.150146,2755.998047 2004.948120,2753.043945 
	C2006.490601,2751.127197 2008.465576,2750.419189 2008.740967,2748.227783 
	C2008.080078,2749.719482 2007.077881,2750.794922 2005.561279,2752.378662 
	C1998.838257,2755.011719 1997.263428,2750.281006 1994.739136,2746.373047 
	C1991.103760,2740.745361 1992.926880,2733.384766 1987.993896,2727.578125 
	C1986.639771,2725.806396 1986.260986,2724.429443 1986.236328,2722.289551 
	C1986.447998,2719.739990 1988.233521,2717.972900 1986.320068,2715.478760 
	C1985.227783,2713.929688 1984.810303,2712.765869 1984.902466,2710.837891 
	C1988.220581,2707.527588 1987.987793,2703.385254 1989.927002,2699.530273 
	C1997.674805,2698.293457 1999.244263,2704.244141 2000.750122,2708.846436 
	C2002.552368,2714.354980 2004.181519,2718.157715 2011.104614,2716.691162 
	C2013.504883,2716.183105 2016.003662,2718.275635 2018.963623,2719.800781 
	C2019.953125,2720.441162 2020.328613,2720.729248 2021.189453,2721.536621 
	C2025.340088,2733.599121 2035.626587,2739.352539 2044.043823,2746.373535 
	C2049.030029,2750.532959 2050.990967,2753.889648 2047.905762,2759.915039 
	C2051.272705,2756.993896 2051.715088,2753.657715 2051.976074,2749.837891 
	C2052.468018,2742.632812 2054.727783,2741.632568 2060.089844,2746.254150 
	C2062.574463,2748.395996 2064.291260,2748.808350 2067.045410,2747.413574 
	C2070.125732,2745.853516 2075.119385,2742.387695 2076.218262,2747.109863 
	C2077.389648,2752.144287 2085.743408,2757.764160 2077.179688,2763.536377 
	C2076.353516,2764.093018 2075.670898,2764.836182 2075.274902,2765.983398 
	C2091.064941,2769.698242 2082.251465,2781.413330 2083.058105,2790.058594 
	C2082.769287,2791.358643 2084.608154,2790.247314 2083.280273,2790.368896 
	C2082.928467,2790.401367 2082.862793,2790.083252 2083.048828,2789.406006 
	C2084.639404,2783.623535 2087.148926,2778.191162 2089.537354,2772.723877 
	C2090.494629,2770.532471 2092.148682,2768.628418 2094.948486,2769.399170 
	C2097.109131,2769.994385 2098.319824,2772.023438 2098.167480,2773.991455 
	C2097.462646,2783.073975 2099.730957,2792.536865 2095.645752,2800.373291 
	C2099.361084,2797.323486 2097.877686,2788.021484 2106.689697,2788.305908 
	C2112.350830,2788.488037 2110.712402,2794.237305 2112.893311,2797.078125 
	C2118.727539,2795.487549 2118.322266,2786.484131 2126.225098,2787.223145 
	C2131.478271,2791.172363 2131.172852,2796.875488 2133.019287,2802.524902 
	C2135.992432,2815.827393 2139.752930,2828.173828 2139.881348,2841.865723 
	C2132.384033,2844.427246 2129.599609,2849.998779 2127.269775,2856.476074 
	C2125.968262,2860.094482 2123.245117,2863.386475 2117.876709,2863.443848 
	C2113.539795,2861.819092 2111.631592,2858.853760 2110.054199,2854.233643 
	C2111.371094,2860.623047 2111.063232,2865.873779 2108.215576,2870.601562 
	C2104.291016,2877.117188 2100.194824,2883.512451 2097.109619,2890.509521 
	C2095.893799,2893.267090 2093.706787,2895.500732 2090.735840,2896.461914 
	C2087.164551,2897.617432 2083.631348,2896.085205 2085.427979,2892.456299 
	C2089.190918,2884.856201 2086.343994,2876.950195 2088.069336,2868.698242 
	C2088.093994,2865.050781 2087.833496,2862.063721 2089.715820,2858.683594 
	C2095.154541,2850.053955 2094.021484,2842.143311 2089.711426,2834.343262 
	C2086.168945,2836.967529 2085.758301,2841.760742 2082.505371,2845.540039 
z"
        />
        <path
          fill="#DAD3C3"
          opacity="1.000000"
          stroke="none"
          d="
M2137.442871,2844.589600 
	C2138.565674,2838.032959 2136.557617,2832.229004 2135.411377,2826.544434 
	C2133.994873,2819.519043 2132.260986,2812.474365 2130.352539,2804.715576 
	C2131.482910,2802.632568 2132.730957,2801.139160 2133.680420,2799.393555 
	C2135.958496,2795.206299 2138.433838,2790.882324 2144.199463,2793.904541 
	C2149.603516,2796.737305 2156.167236,2799.223877 2152.492676,2808.307129 
	C2144.410400,2815.670654 2142.285156,2824.721680 2146.680908,2834.445312 
	C2148.641113,2838.781250 2150.430664,2842.832520 2148.372070,2848.268311 
	C2144.661377,2876.774414 2130.848145,2901.187500 2122.449463,2927.380127 
	C2118.924316,2938.374268 2117.445068,2949.983643 2111.824463,2960.370117 
	C2108.972656,2965.640381 2106.484375,2971.100342 2102.857178,2975.909180 
	C2099.290771,2980.636963 2096.808594,2984.703613 2101.610352,2990.874512 
	C2105.455078,2995.815430 2098.740723,3005.640869 2088.666504,3010.239746 
	C2087.983887,3009.639160 2088.082520,3009.275146 2087.982178,3008.978760 
	C2087.038574,3006.192627 2091.317383,3003.016846 2087.925293,3000.764160 
	C2084.903564,2998.757812 2082.901123,3003.489014 2079.628418,3003.104248 
	C2077.593506,3000.260498 2080.000244,2998.216797 2081.194824,2996.145996 
	C2083.014648,2992.990967 2085.276611,2990.094238 2087.191895,2986.989746 
	C2087.892090,2985.855225 2088.044434,2984.347168 2086.819824,2983.369629 
	C2085.589111,2982.386963 2084.450928,2983.123535 2083.285889,2983.880615 
	C2079.886963,2986.088379 2079.819336,2991.011719 2075.176758,2993.488770 
	C2073.327148,2986.463867 2072.380859,2979.711426 2077.234619,2974.543701 
	C2082.093994,2969.370117 2081.053711,2964.781494 2077.345947,2960.691162 
	C2073.495361,2956.443604 2076.123779,2950.507568 2072.353027,2946.370605 
	C2071.253418,2945.164307 2072.487305,2942.302734 2074.166992,2941.082031 
	C2082.701904,2934.878662 2084.087402,2925.047607 2086.862061,2916.016602 
	C2087.907715,2912.613281 2091.024902,2909.205322 2088.393799,2904.438965 
	C2085.191895,2906.729248 2085.375488,2910.983887 2082.252197,2912.414795 
	C2081.415771,2912.797852 2080.190674,2913.195312 2079.528320,2912.849609 
	C2078.881104,2912.511719 2078.458984,2911.242920 2078.426025,2910.364746 
	C2078.390869,2909.426025 2078.775146,2908.384277 2079.237549,2907.528564 
	C2081.730469,2902.914307 2082.378662,2898.020752 2083.026123,2892.816406 
	C2083.719482,2887.243652 2085.838135,2881.996094 2084.217041,2876.140137 
	C2083.506592,2873.574219 2085.337158,2871.051758 2087.533936,2868.440918 
	C2092.293457,2877.007568 2089.811523,2885.812988 2086.247314,2895.276123 
	C2091.879395,2894.604492 2094.725586,2891.505859 2095.457764,2887.856934 
	C2096.500244,2882.660889 2099.198730,2878.575928 2102.304443,2874.832520 
	C2107.870850,2868.122803 2108.765381,2860.581299 2107.753418,2852.443359 
	C2107.632324,2851.469482 2107.134033,2850.548828 2106.914551,2849.579834 
	C2106.401367,2847.317383 2107.128662,2845.322998 2109.465332,2845.085449 
	C2111.515137,2844.876709 2112.089844,2846.951904 2111.899658,2848.627686 
	C2111.315430,2853.777832 2114.171387,2857.434814 2117.550781,2861.578125 
	C2120.755859,2868.835938 2116.584473,2874.080078 2114.038818,2879.735596 
	C2107.127197,2895.090088 2098.813965,2909.811035 2093.175049,2925.737305 
	C2092.114746,2928.732178 2090.253662,2931.927734 2094.754883,2935.616699 
	C2105.754639,2903.666504 2125.519043,2876.393555 2137.442871,2844.589600 
z"
        />
        <path
          fill="#EEE7D5"
          opacity="1.000000"
          stroke="none"
          d="
M2087.749023,3010.398926 
	C2094.956055,3007.391357 2095.305664,2999.269287 2100.395020,2995.051758 
	C2101.548096,2994.096436 2100.514893,2992.701904 2099.542480,2991.666992 
	C2097.724365,2989.732666 2096.021729,2987.687012 2092.718506,2987.395264 
	C2095.124023,2981.990723 2097.854492,2977.763672 2100.905029,2973.536865 
	C2108.313232,2963.272949 2112.863525,2951.505371 2117.098877,2939.651611 
	C2117.431152,2938.721680 2117.942139,2937.664551 2117.775146,2936.778320 
	C2116.808838,2931.649658 2117.386475,2927.900391 2120.980713,2922.974854 
	C2123.945801,2918.911133 2125.296387,2912.147705 2126.880615,2906.423828 
	C2128.740967,2899.700439 2131.914062,2893.554688 2135.050049,2887.319336 
	C2140.605469,2876.273193 2141.834473,2863.929688 2144.085449,2851.929932 
	C2144.266602,2850.964111 2144.625732,2850.032227 2145.426758,2848.527588 
	C2148.904053,2845.856445 2150.713623,2847.735596 2152.835693,2850.513672 
	C2153.565918,2852.158203 2153.777832,2853.153564 2153.841553,2854.927002 
	C2153.575928,2858.076172 2153.093750,2860.448242 2152.821777,2863.566162 
	C2152.772217,2865.706543 2152.523682,2867.101807 2152.002930,2869.153809 
	C2151.055420,2872.754883 2147.643066,2875.930664 2150.564697,2878.589355 
	C2153.349609,2881.123779 2156.203613,2876.653809 2160.063721,2876.965576 
	C2165.688232,2882.236572 2165.155029,2888.412109 2161.457275,2893.198486 
	C2155.929932,2900.352783 2153.145996,2908.173340 2151.840332,2916.830078 
	C2150.955566,2922.695801 2149.156250,2927.166504 2142.112549,2928.828369 
	C2135.556885,2930.375000 2133.165283,2936.438477 2133.699707,2942.884277 
	C2134.064697,2947.285889 2132.872803,2951.460449 2132.836426,2955.656006 
	C2132.747314,2965.984375 2129.274414,2974.352783 2121.415527,2980.999023 
	C2120.424561,2981.837158 2119.481445,2983.071533 2119.252197,2984.298828 
	C2116.838623,2997.218262 2108.368408,3006.287598 2100.366699,3015.982910 
	C2093.228516,3024.632080 2083.848145,3031.276855 2078.222412,3041.249023 
	C2077.275391,3042.927490 2074.971191,3043.449463 2072.171387,3042.553711 
	C2069.994629,3039.428467 2070.838623,3037.168213 2073.483398,3034.595215 
	C2073.304688,3029.175049 2078.039307,3027.051270 2079.717285,3022.633301 
	C2082.493164,3019.434082 2083.181641,3015.847412 2085.553955,3012.479980 
	C2086.621338,3011.753174 2087.125000,3011.358643 2087.749023,3010.398926 
z"
        />
        <path
          fill="#F2EBDD"
          opacity="1.000000"
          stroke="none"
          d="
M1987.292969,2726.660156 
	C1991.992554,2725.789307 1994.588257,2727.494873 1993.912354,2731.790039 
	C1992.890259,2738.286133 1998.044922,2742.811523 1998.848022,2748.740479 
	C1999.192505,2751.283203 2001.998657,2751.872803 2005.229980,2751.980225 
	C2005.814697,2752.972656 2005.696899,2753.963867 2005.430542,2754.913818 
	C2004.841553,2757.015625 2002.882446,2759.470459 2005.805298,2760.964111 
	C2007.700684,2761.932861 2009.528687,2760.168213 2011.059204,2758.934326 
	C2013.518921,2756.951660 2015.025269,2753.682617 2018.590210,2753.134521 
	C2020.695557,2755.808105 2019.032715,2757.601074 2017.543823,2759.375488 
	C2015.424072,2761.901611 2013.181885,2764.327393 2011.113281,2766.893799 
	C2009.452881,2768.953613 2007.469238,2771.279785 2008.759277,2774.014893 
	C2010.144775,2776.952393 2013.071167,2777.956055 2016.275513,2777.553223 
	C2018.130371,2777.320068 2020.333496,2775.776367 2021.427612,2778.363037 
	C2022.447876,2780.775391 2021.555664,2782.860107 2018.999390,2784.034180 
	C2014.984253,2785.877686 2015.522949,2789.739014 2016.175415,2792.689697 
	C2018.987671,2805.408447 2018.191895,2818.230469 2018.075073,2831.052979 
	C2018.063599,2832.320068 2018.632202,2833.592773 2019.486572,2835.440918 
	C2020.009155,2837.505615 2019.976562,2838.992188 2019.914795,2841.221680 
	C2016.531860,2847.079590 2017.848755,2853.218750 2016.190186,2859.379395 
	C2016.116821,2863.466553 2016.065430,2866.884277 2015.981689,2871.155762 
	C2015.955444,2873.782227 2016.031860,2875.568359 2014.542236,2877.653809 
	C2014.124023,2879.636230 2014.110596,2881.091797 2014.085327,2883.275391 
	C2012.167114,2885.966309 2009.975342,2886.077393 2006.900391,2884.461914 
	C2005.830688,2881.799316 2006.189575,2879.344482 2002.958252,2878.124512 
	C1997.110840,2872.661865 1998.160034,2865.313721 1997.616577,2859.107666 
	C1994.022339,2818.055908 1987.001343,2777.341797 1985.448730,2736.096680 
	C1985.335205,2733.077881 1985.125854,2730.101562 1987.292969,2726.660156 
z"
        />
        <path
          fill="#B6AFA3"
          opacity="1.000000"
          stroke="none"
          d="
M1961.894043,2955.481689 
	C1961.452148,2957.574463 1960.775635,2959.118408 1960.021973,2961.291992 
	C1958.630615,2963.497070 1958.232056,2965.615234 1956.403564,2967.567871 
	C1955.710083,2968.595459 1955.333984,2969.111816 1954.426025,2969.797852 
	C1949.566040,2969.153564 1946.901611,2965.042725 1948.694702,2962.152344 
	C1952.898560,2955.375732 1951.836670,2947.560791 1954.492920,2940.421143 
	C1957.510010,2932.312012 1964.753906,2927.749268 1967.513550,2919.905762 
	C1968.486938,2917.139404 1970.138916,2914.651611 1970.042114,2910.788086 
	C1970.496094,2905.379883 1974.963745,2902.462402 1975.416382,2897.079590 
	C1975.817627,2895.448242 1976.152710,2894.537598 1976.892456,2892.978516 
	C1985.576538,2879.992920 1986.899536,2866.626221 1983.084106,2851.688721 
	C1980.928955,2835.907715 1978.111206,2820.993164 1980.733643,2805.068115 
	C1986.975586,2803.304443 1986.473022,2808.005127 1987.119263,2811.107178 
	C1990.490967,2827.289795 1991.664062,2843.861816 1995.950439,2860.749512 
	C1996.949097,2872.748535 2000.128418,2883.560059 2000.813599,2895.361328 
	C1993.783447,2896.145020 1993.341309,2901.757324 1992.630005,2905.851807 
	C1991.731689,2911.022217 1990.979248,2915.525635 1985.347656,2917.470947 
	C1984.478760,2917.770996 1983.150513,2918.640869 1983.146118,2919.255127 
	C1983.091797,2926.825439 1974.741211,2929.979004 1973.815430,2937.018066 
	C1973.426147,2939.978271 1970.879883,2941.175781 1969.051514,2943.012939 
	C1965.687256,2946.393555 1962.081299,2949.697998 1961.894043,2955.481689 
z"
        />
        <path
          fill="#8C8D89"
          opacity="1.000000"
          stroke="none"
          d="
M1987.756348,2725.271484 
	C1990.437866,2756.347656 1991.646729,2786.820068 1996.638550,2816.970703 
	C1998.471802,2828.043213 1999.277832,2839.375244 1999.442261,2850.605225 
	C1999.569458,2859.298340 2003.026489,2867.521973 2002.221680,2877.130371 
	C2001.965088,2877.955811 2001.626465,2877.885010 2000.770996,2877.765137 
	C1993.768066,2874.421631 1997.338623,2867.615723 1994.831909,2862.036133 
	C1990.190918,2830.838623 1981.212402,2801.136719 1981.024536,2770.123779 
	C1981.010254,2767.753906 1980.495117,2765.515625 1978.651855,2762.794678 
	C1972.837402,2744.015625 1973.916992,2725.143799 1971.094360,2706.774902 
	C1970.495239,2702.876953 1969.890625,2698.938477 1969.270630,2694.178223 
	C1969.208618,2691.717773 1969.318848,2690.107422 1970.012207,2687.834717 
	C1976.603149,2682.004639 1980.769653,2690.562500 1986.921143,2690.201660 
	C1988.093506,2692.177979 1988.681274,2693.628662 1988.771606,2696.055664 
	C1987.243896,2700.665039 1989.368042,2704.946289 1986.575562,2709.304932 
	C1986.102661,2711.087646 1986.151123,2712.177734 1986.493164,2713.735352 
	C1989.721191,2714.741455 1989.125122,2716.804688 1988.065430,2719.480713 
	C1987.444458,2721.647949 1987.516357,2723.103516 1987.756348,2725.271484 
z"
        />
        <path
          fill="#A6A29C"
          opacity="1.000000"
          stroke="none"
          d="
M2044.167725,3098.403809 
	C2038.421143,3096.595215 2040.160400,3092.542725 2040.304565,3089.310791 
	C2040.456909,3085.898193 2042.851929,3082.655518 2040.631348,3078.515381 
	C2038.959961,3076.657959 2037.859619,3075.300049 2037.323120,3072.670410 
	C2037.017578,3067.260010 2037.105591,3062.707031 2037.028809,3057.326416 
	C2037.094971,3052.721680 2037.091797,3048.946045 2037.215210,3044.400391 
	C2037.735352,3039.877197 2039.994507,3036.033936 2036.370117,3031.778076 
	C2034.687744,3025.933105 2034.622803,3020.746826 2035.782959,3014.765137 
	C2035.971558,3010.574219 2035.968384,3007.148682 2035.927612,3002.868408 
	C2038.219971,3000.339844 2037.766724,2997.811279 2037.957642,2994.721680 
	C2039.000000,2995.134277 2039.985474,2996.288086 2041.957275,2998.081543 
	C2043.439819,2999.332764 2043.865967,2999.988525 2044.419800,3001.220703 
	C2044.727295,3002.094727 2044.820801,3002.447510 2045.029053,3003.317383 
	C2046.616577,3005.003662 2045.796509,3002.467041 2047.367676,3002.643066 
	C2059.571533,3004.946533 2049.731689,3011.326904 2050.334473,3016.464355 
	C2050.231201,3017.563477 2050.180420,3018.000732 2050.041992,3019.085938 
	C2049.797363,3021.030518 2049.712646,3022.326172 2050.180664,3024.292480 
	C2050.474365,3025.439941 2050.582764,3025.913086 2050.806885,3027.125488 
	C2057.354004,3044.940430 2050.620117,3061.673828 2049.218506,3078.482422 
	C2048.659180,3085.191650 2052.271484,3093.274902 2044.167725,3098.403809 
z"
        />
        <path
          fill="#BAB4A7"
          opacity="1.000000"
          stroke="none"
          d="
M2048.773438,3020.030518 
	C2048.220459,3015.765381 2044.583008,3011.239990 2051.029541,3008.067627 
	C2053.174561,3007.011719 2050.307373,3004.331787 2046.958008,3004.050049 
	C2045.869385,3004.393311 2045.488037,3004.765137 2044.520752,3004.593506 
	C2043.934814,3004.050293 2043.971924,3004.009766 2043.964722,3004.026123 
	C2042.627563,3002.572021 2043.207642,3000.673584 2042.381836,2998.498535 
	C2041.938354,2998.006348 2041.992920,2998.019775 2041.966675,2998.045410 
	C2042.484741,2994.696289 2047.376221,2991.280273 2040.643066,2988.108154 
	C2037.613159,2986.680664 2037.110962,2981.919922 2039.169678,2978.210693 
	C2039.811279,2977.054443 2041.113892,2976.020752 2041.216553,2974.852051 
	C2041.645630,2969.966797 2043.959106,2968.011230 2048.875488,2968.783936 
	C2049.910889,2968.946533 2050.673096,2966.824219 2052.851318,2967.537109 
	C2054.804199,2970.010986 2053.410156,2972.371094 2052.021973,2975.127441 
	C2049.544189,2980.048340 2050.637939,2982.161621 2055.904297,2983.603760 
	C2057.480469,2984.035889 2059.221191,2983.834229 2060.835205,2984.169434 
	C2065.144775,2985.063721 2068.296143,2986.413086 2066.467773,2992.188477 
	C2065.409912,2995.530762 2066.053223,2999.420654 2065.983154,3003.064941 
	C2065.925781,3006.060791 2066.119141,3009.114746 2062.649414,3011.601807 
	C2058.176270,3015.326904 2053.739502,3017.688721 2048.773438,3020.030518 
z"
        />
        <path
          fill="#EEE9DC"
          opacity="1.000000"
          stroke="none"
          d="
M1995.579590,2861.556641 
	C1998.876343,2866.323242 1997.780151,2871.721191 2000.023926,2877.020508 
	C2003.294067,2893.627686 2009.139648,2908.714355 2014.078003,2924.054443 
	C2018.218628,2936.916748 2022.647705,2949.713623 2028.786133,2961.825439 
	C2029.833130,2963.891357 2030.644043,2966.114746 2028.522461,2969.273926 
	C2016.199707,2946.018066 2008.552979,2921.622314 2000.790771,2896.565674 
	C1999.239746,2885.080566 1993.803223,2874.954834 1994.088379,2862.876953 
	C1994.401245,2861.752441 1994.725464,2861.476807 1995.579590,2861.556641 
z"
        />
        <path
          fill="#DAD3C3"
          opacity="1.000000"
          stroke="none"
          d="
M2082.750000,2846.130615 
	C2080.453125,2838.830566 2085.984863,2834.576172 2089.109375,2829.363281 
	C2090.047363,2827.798340 2092.073486,2827.869385 2093.631348,2828.525635 
	C2095.599854,2829.354004 2095.105225,2831.002930 2094.709961,2832.703613 
	C2094.129639,2835.201172 2092.548828,2837.508789 2093.063477,2840.230713 
	C2095.631104,2842.119873 2096.288818,2838.549072 2098.103271,2838.595459 
	C2099.011230,2838.618896 2100.091797,2838.784424 2100.772461,2839.305664 
	C2101.995850,2840.242432 2102.009277,2842.374023 2101.148682,2842.806641 
	C2094.948730,2845.923340 2095.034912,2852.985840 2090.725586,2857.845215 
	C2089.760986,2853.000244 2088.874023,2848.345459 2082.750000,2846.130615 
z"
        />
        <path
          fill="#BAB4A7"
          opacity="1.000000"
          stroke="none"
          d="
M2059.554199,3021.565430 
	C2064.055908,3022.637939 2065.932373,3019.884521 2067.988281,3017.227295 
	C2068.952881,3015.980713 2070.233398,3014.468506 2072.086914,3015.133545 
	C2074.539795,3016.013428 2074.204590,3018.437500 2073.802979,3020.196777 
	C2072.852295,3024.361328 2071.450195,3028.422852 2070.147949,3033.285156 
	C2071.189209,3038.784668 2068.836914,3042.848633 2067.030029,3047.714355 
	C2066.069580,3048.539062 2065.602295,3048.763672 2064.357422,3049.025879 
	C2060.089111,3048.039551 2058.880859,3045.369629 2058.604248,3041.353027 
	C2060.923096,3036.700928 2058.448730,3034.040527 2056.594971,3030.806152 
	C2054.617188,3027.355469 2051.658936,3022.649414 2059.554199,3021.565430 
z"
        />
        <path
          fill="#A6A29C"
          opacity="1.000000"
          stroke="none"
          d="
M2060.065918,3021.260742 
	C2056.029785,3025.447266 2056.382324,3028.831543 2061.085449,3031.821533 
	C2065.869141,3034.862793 2065.868896,3034.863037 2060.222656,3039.988037 
	C2049.593994,3042.010986 2053.269043,3032.214111 2049.473877,3028.103516 
	C2049.419434,3026.701660 2048.196045,3025.392090 2049.766113,3024.842285 
	C2049.431885,3024.502197 2048.499512,3024.740234 2048.225342,3024.344971 
	C2047.951050,3023.949707 2048.002441,3024.006104 2048.023438,3024.037842 
	C2047.389282,3023.343262 2047.062622,3022.498047 2047.462158,3020.892334 
	C2047.804077,3020.270752 2047.962036,3020.047119 2048.038818,3019.930908 
	C2050.712402,3014.807617 2054.903809,3012.334473 2061.223145,3012.020996 
	C2061.342041,3014.841797 2060.705811,3017.683838 2060.065918,3021.260742 
z"
        />
        <path
          fill="#DAD3C3"
          opacity="1.000000"
          stroke="none"
          d="
M1880.494385,3248.509521 
	C1879.946289,3247.090088 1879.969849,3246.213867 1880.010376,3244.680176 
	C1883.049438,3242.731689 1884.086182,3239.844727 1885.377808,3237.216797 
	C1886.578979,3234.772705 1888.091675,3232.712402 1890.716309,3231.703369 
	C1892.168335,3231.145020 1893.716431,3229.893066 1895.087402,3231.717041 
	C1895.996460,3232.926270 1895.618530,3234.291504 1894.667480,3235.274414 
	C1892.990967,3237.007324 1892.260376,3238.946777 1891.952026,3241.376221 
	C1890.802490,3250.435059 1890.696045,3250.421631 1880.494385,3248.509521 
z"
        />
        <path
          fill="#E8E1D2"
          opacity="1.000000"
          stroke="none"
          d="
M2016.801636,2859.626465 
	C2011.685547,2853.455322 2015.353882,2847.924316 2019.182129,2842.143555 
	C2019.259399,2847.738281 2022.026245,2853.994141 2016.801636,2859.626465 
z"
        />
        <path
          fill="#DAD3C3"
          opacity="1.000000"
          stroke="none"
          d="
M2066.839355,3048.463379 
	C2065.412598,3043.361084 2067.034668,3039.253906 2069.741699,3034.695312 
	C2070.947998,3034.034180 2071.830566,3034.023926 2073.375977,3034.022705 
	C2073.429688,3036.250488 2072.820557,3038.469238 2072.170898,3041.380859 
	C2074.016846,3046.602783 2071.759277,3048.364990 2066.839355,3048.463379 
z"
        />
        <path
          fill="#E8E1D2"
          opacity="1.000000"
          stroke="none"
          d="
M2006.235352,2884.665527 
	C2008.296387,2883.950195 2010.392700,2883.976562 2013.281250,2884.002930 
	C2016.323486,2886.481689 2017.123779,2889.743896 2013.919067,2891.187988 
	C2008.926636,2893.437744 2007.413696,2889.254395 2006.235352,2884.665527 
z"
        />
        <path
          fill="#C4BDAE"
          opacity="1.000000"
          stroke="none"
          d="
M1932.868896,3211.927246 
	C1932.737427,3209.745605 1933.309326,3207.565430 1933.968994,3204.707031 
	C1936.084106,3204.301025 1939.182007,3203.402344 1939.067261,3206.663818 
	C1938.962158,3209.651367 1937.114868,3211.958252 1932.868896,3211.927246 
z"
        />
        <path
          fill="#B6AFA3"
          opacity="1.000000"
          stroke="none"
          d="
M1926.490234,3117.994629 
	C1924.168823,3117.746826 1921.725220,3118.527344 1921.038330,3116.006836 
	C1920.503540,3114.044189 1922.182373,3112.914062 1923.778198,3112.552734 
	C1926.899170,3111.846680 1927.808838,3113.869385 1927.936279,3117.237793 
	C1927.946289,3117.951172 1926.975586,3117.979736 1926.490234,3117.994629 
z"
        />
        <path
          fill="#DAD3C3"
          opacity="1.000000"
          stroke="none"
          d="
M2085.317383,3012.133789 
	C2085.884766,3015.929932 2085.457031,3019.706543 2080.699219,3021.884766 
	C2080.080078,3018.066650 2080.506104,3014.288574 2085.317383,3012.133789 
z"
        />
        <path
          fill="#DAD3C3"
          opacity="1.000000"
          stroke="none"
          d="
M1909.335327,3248.144043 
	C1909.894531,3250.286621 1910.250000,3252.766846 1906.697510,3253.883301 
	C1906.116455,3251.736572 1905.740967,3249.246338 1909.335327,3248.144043 
z"
        />
        <path
          fill="#EEE9DC"
          opacity="1.000000"
          stroke="none"
          d="
M2032.659180,2974.156250 
	C2033.677124,2975.698486 2034.190186,2976.977295 2033.300537,2978.066162 
	C2032.484619,2979.064697 2031.426270,2978.298096 2031.128052,2977.497559 
	C2030.607544,2976.100586 2028.606323,2973.971924 2032.659180,2974.156250 
z"
        />
        <path
          fill="#E8E1D2"
          opacity="1.000000"
          stroke="none"
          d="
M2014.751221,2878.020508 
	C2014.030273,2876.365967 2013.834351,2874.537598 2015.451660,2872.461426 
	C2018.402222,2874.224365 2018.498657,2876.203857 2014.751221,2878.020508 
z"
        />
        <path
          fill="#A6A29C"
          opacity="1.000000"
          stroke="none"
          d="
M2037.993042,2994.004883 
	C2037.142700,2993.601318 2036.486450,2993.033936 2036.599854,2992.004150 
	C2036.620850,2991.812256 2037.037354,2991.663818 2037.271362,2991.495361 
	C2037.503662,2992.017578 2037.736084,2992.539795 2037.991577,2993.521240 
	C2038.014648,2993.980469 2038.000732,2994.000000 2037.993042,2994.004883 
z"
        />
        <path
          fill="#BAB4A7"
          opacity="1.000000"
          stroke="none"
          d="
M2034.928833,2987.056396 
	C2034.884766,2986.971680 2034.972900,2987.141113 2034.928833,2987.056396 
z"
        />
        <path
          fill="#CBC6BA"
          opacity="1.000000"
          stroke="none"
          d="
M1908.560059,3158.020752 
	C1909.876099,3157.533691 1908.427979,3156.629395 1909.241333,3157.662842 
	C1909.737305,3158.292725 1909.287842,3158.680664 1908.560059,3158.020752 
z"
        />
        <path
          fill="#DAD3C3"
          opacity="1.000000"
          stroke="none"
          d="
M1678.002441,3881.999512 
	C1677.672607,3881.990967 1677.339600,3881.993164 1677.008179,3881.972168 
	C1675.002563,3881.845459 1672.887085,3881.815918 1672.462036,3879.197510 
	C1672.289551,3878.134277 1672.857300,3877.133545 1673.939819,3877.020508 
	C1676.516113,3876.752441 1677.205200,3878.721191 1677.911011,3881.331787 
	C1677.995117,3882.000732 1678.000000,3882.000000 1678.002441,3881.999512 
z"
        />
        <path
          fill="#EEE9DC"
          opacity="1.000000"
          stroke="none"
          d="
M2034.401367,3077.424316 
	C2032.875366,3081.927246 2028.640015,3084.656250 2032.989746,3090.183594 
	C2035.535767,3093.418701 2033.030151,3099.856689 2035.351440,3104.992920 
	C2037.031250,3108.709717 2031.279053,3109.233887 2028.618286,3111.986328 
	C2035.058350,3116.505859 2035.158936,3123.266846 2033.591309,3129.694580 
	C2031.474731,3138.373291 2032.532227,3147.694580 2028.181396,3155.881592 
	C2028.038818,3156.149658 2028.023560,3156.564941 2028.111084,3156.864014 
	C2030.735229,3165.830322 2025.438477,3173.125977 2022.514282,3181.514648 
	C2019.944824,3177.624023 2016.997314,3176.203125 2012.099731,3177.882812 
	C2008.150269,3179.237061 2004.000854,3180.635742 1998.778198,3179.970703 
	C1997.875610,3177.974854 1998.165039,3175.070557 1996.039673,3174.776367 
	C1989.843628,3173.918701 1991.001465,3170.320068 1991.807739,3166.169922 
	C1992.301270,3163.629150 1991.862305,3160.885742 1991.680786,3158.244385 
	C1991.591309,3156.944092 1990.490967,3154.865479 1990.942139,3154.503906 
	C1997.527466,3149.224121 1992.412720,3144.248291 1990.724365,3139.219727 
	C1989.820679,3136.528320 1990.641602,3134.228027 1993.197021,3133.615479 
	C1999.754028,3132.043701 1999.446899,3124.571289 2004.169067,3121.475830 
	C2005.566406,3120.560059 2004.983154,3118.921143 2003.622070,3118.348145 
	C1998.892456,3116.355469 2000.933472,3113.861084 2002.141846,3110.448730 
	C2004.037476,3105.095459 2008.336426,3100.271729 2006.709839,3093.873779 
	C2006.502808,3093.059570 2007.054810,3091.914307 2007.535278,3091.084473 
	C2014.189819,3079.588379 2017.270020,3066.840088 2020.374756,3054.130615 
	C2022.351807,3046.037598 2025.832764,3038.343506 2026.233398,3029.799561 
	C2026.393799,3026.377441 2027.019897,3022.492432 2031.303101,3020.137695 
	C2033.856445,3021.419678 2034.189697,3023.464600 2034.241333,3026.373047 
	C2032.305176,3031.535400 2034.005737,3035.804199 2034.725342,3040.833984 
	C2034.823242,3042.513428 2034.845703,3043.468994 2034.853760,3045.126465 
	C2034.771362,3056.147461 2031.851685,3066.448730 2034.401367,3077.424316 
z"
        />
        <path
          fill="#EEE9DC"
          opacity="1.000000"
          stroke="none"
          d="
M1979.801758,3251.452148 
	C1978.517334,3255.578857 1978.956665,3258.074219 1983.552124,3257.086426 
	C1986.160645,3256.525879 1988.242432,3256.478760 1989.092285,3259.263672 
	C1989.794556,3261.564209 1987.946167,3262.819824 1986.246460,3263.848877 
	C1982.226562,3266.281982 1981.185791,3269.156982 1983.506104,3273.664307 
	C1985.674072,3277.875244 1984.414429,3279.814697 1980.114624,3279.767090 
	C1977.902954,3279.742676 1975.702026,3278.750732 1972.749512,3278.098145 
	C1970.647217,3274.648193 1966.711182,3273.779541 1964.511597,3270.373779 
	C1964.003906,3268.502197 1964.007812,3267.004150 1964.015625,3264.757568 
	C1969.695068,3262.422363 1970.369629,3258.189697 1969.430908,3253.343262 
	C1968.884155,3250.520508 1967.857422,3247.790771 1966.518433,3244.504883 
	C1966.141235,3241.625488 1965.605347,3239.167969 1967.447021,3236.466309 
	C1969.815918,3234.150879 1971.872070,3232.834717 1975.322998,3233.676025 
	C1976.507202,3238.034912 1979.633301,3239.093262 1982.865112,3239.764404 
	C1985.917114,3240.397705 1989.483032,3239.638672 1989.965942,3244.470703 
	C1990.484009,3249.655273 1986.326050,3249.833984 1982.675293,3251.690186 
	C1980.729370,3251.253906 1980.507202,3249.286133 1978.695557,3249.193848 
	C1978.956543,3249.752441 1979.255981,3250.319580 1979.801758,3251.452148 
z"
        />
        <path
          fill="#EEE9DC"
          opacity="1.000000"
          stroke="none"
          d="
M1993.512451,3179.497314 
	C1994.349609,3182.827393 1996.107788,3183.732178 1998.762085,3184.054932 
	C2001.357056,3184.370361 2003.887817,3185.247803 2006.432739,3185.930176 
	C2008.139404,3186.387695 2009.325073,3187.399170 2009.754395,3189.217041 
	C2011.088379,3194.866211 2005.266113,3198.338867 2005.571533,3204.098389 
	C2005.805176,3208.503906 2001.325439,3209.538574 1992.547241,3208.119629 
	C1991.572388,3207.962402 1990.560547,3208.034912 1988.773193,3207.990723 
	C1987.795898,3205.719727 1986.727173,3203.006104 1987.606079,3201.289795 
	C1989.599121,3197.397705 1988.882080,3196.031738 1984.644043,3195.960449 
	C1981.990234,3195.915771 1980.134766,3194.954834 1981.141968,3191.737549 
	C1981.336060,3191.117920 1981.603638,3190.474121 1981.595093,3189.844727 
	C1981.451416,3179.282471 1981.442749,3179.282471 1993.512451,3179.497314 
z"
        />
        <path
          fill="#EAE6D7"
          opacity="1.000000"
          stroke="none"
          d="
M1987.557129,3211.580322 
	C1988.513672,3212.995605 1989.036987,3213.981445 1989.785034,3215.478760 
	C1990.243652,3216.326416 1990.477783,3216.662842 1990.353516,3217.498779 
	C1988.831055,3218.973389 1988.529175,3220.127930 1989.392456,3221.430420 
	C1990.115234,3222.520752 1991.328979,3223.458252 1991.613159,3224.635254 
	C1992.521851,3228.398438 1991.964233,3232.158936 1988.933350,3234.703369 
	C1985.982422,3237.180176 1983.400879,3234.552490 1980.439453,3232.562988 
	C1980.124023,3228.149658 1984.961060,3223.211182 1976.734985,3221.339111 
	C1973.720337,3220.652588 1976.369019,3216.710938 1976.082520,3214.272705 
	C1975.716553,3211.159424 1976.297852,3208.230225 1981.219849,3208.024902 
	C1983.265625,3209.756348 1985.538818,3209.895508 1987.557129,3211.580322 
z"
        />
        <path
          fill="#CBC6BA"
          opacity="1.000000"
          stroke="none"
          d="
M2032.019287,3028.798340 
	C2032.034546,3025.859375 2032.051270,3023.718506 2032.048706,3020.774414 
	C2032.133423,3017.716064 2031.732544,3015.232422 2035.300415,3014.121094 
	C2039.898926,3018.982910 2037.271240,3024.811035 2038.072998,3031.071777 
	C2039.223877,3033.458008 2038.977783,3034.873291 2037.042480,3036.529785 
	C2031.656006,3036.327148 2032.273804,3032.704590 2032.019287,3028.798340 
z"
        />
        <path
          fill="#EAE6D7"
          opacity="1.000000"
          stroke="none"
          d="
M1976.001953,3233.492676 
	C1973.886108,3234.986084 1971.820312,3236.133789 1968.661133,3235.972656 
	C1967.945435,3230.965576 1969.931274,3228.773438 1975.507568,3231.462402 
	C1975.996704,3232.308838 1976.000366,3232.647217 1976.001953,3233.492676 
z"
        />
        <path
          fill="#EEE9DC"
          opacity="1.000000"
          stroke="none"
          d="
M1987.992188,3211.338379 
	C1985.721191,3211.888184 1983.228882,3212.247803 1982.123291,3208.667480 
	C1983.492065,3207.986816 1984.989136,3207.983643 1987.234009,3207.980469 
	C1987.985962,3208.875977 1987.990112,3209.771484 1987.992188,3211.338379 
z"
        />
        <path
          fill="#EAE5D5"
          opacity="1.000000"
          stroke="none"
          d="
M1923.975708,3513.166016 
	C1918.208984,3516.708496 1914.587158,3522.199463 1908.602295,3525.715576 
	C1906.484741,3525.990967 1904.979126,3525.991699 1902.719727,3525.986328 
	C1903.865356,3515.298828 1905.613281,3504.586914 1907.720093,3493.946289 
	C1909.024170,3487.359863 1909.469238,3481.395020 1905.507446,3475.135254 
	C1901.356567,3468.576660 1906.132202,3459.957031 1914.033691,3457.915283 
	C1914.947266,3457.679199 1915.812622,3457.256836 1916.929688,3456.833740 
	C1916.062744,3453.821533 1908.435791,3450.770508 1916.806152,3447.969727 
	C1918.601807,3447.369141 1919.822266,3446.073486 1918.186035,3444.352783 
	C1915.000854,3441.003418 1916.098267,3437.697510 1917.882812,3434.332275 
	C1919.479248,3431.321533 1920.145020,3428.147217 1920.005737,3424.758301 
	C1919.880859,3421.717529 1920.222168,3418.830322 1922.542236,3416.479248 
	C1923.636353,3415.370605 1924.999878,3414.301025 1924.135742,3412.262207 
	C1921.434082,3405.886230 1924.776611,3404.012207 1931.205322,3403.972168 
	C1934.850830,3409.226562 1934.886475,3415.013184 1933.209473,3420.346924 
	C1931.942871,3424.375977 1928.517090,3427.651855 1933.194702,3431.682129 
	C1934.376465,3432.699951 1933.662354,3434.906982 1932.505127,3436.233643 
	C1929.424316,3439.764893 1929.723877,3445.093750 1925.844238,3448.261230 
	C1923.590698,3450.100830 1923.899292,3452.320068 1926.311523,3454.014160 
	C1929.881104,3456.520752 1930.384521,3459.116455 1926.555664,3461.765625 
	C1923.940552,3463.574707 1923.063721,3466.699951 1924.330688,3468.743408 
	C1927.176514,3473.333252 1921.768677,3480.181885 1928.743774,3483.489746 
	C1929.757568,3483.969971 1928.918701,3486.082520 1927.645874,3486.676270 
	C1926.195557,3487.353027 1924.559937,3487.855957 1922.971313,3487.970703 
	C1918.032471,3488.326904 1917.758789,3490.922607 1919.286743,3494.771729 
	C1920.625488,3498.144043 1921.733398,3501.607910 1923.466797,3505.520508 
	C1923.988770,3508.130371 1923.987427,3510.250977 1923.975708,3513.166016 
z"
        />
        <path
          fill="#EAE5D5"
          opacity="1.000000"
          stroke="none"
          d="
M1885.595459,3556.354980 
	C1886.634277,3556.978760 1887.246460,3557.953125 1887.868896,3559.532715 
	C1886.151611,3559.644287 1883.953979,3559.521729 1885.595459,3556.354980 
z"
        />
        <path
          fill="#DAD3C3"
          opacity="1.000000"
          stroke="none"
          d="
M1932.747070,3373.859375 
	C1931.263428,3367.482422 1930.257202,3361.193359 1931.732666,3354.306885 
	C1932.572144,3350.388428 1930.116577,3346.267334 1937.212158,3345.973877 
	C1937.259277,3350.060059 1936.214844,3353.880615 1937.449585,3358.379639 
	C1938.911255,3363.704102 1937.418091,3369.260986 1932.747070,3373.859375 
z"
        />
        <path
          fill="#F5F1E5"
          opacity="1.000000"
          stroke="none"
          d="
M1948.746704,3294.475830 
	C1948.512451,3292.919434 1948.816650,3291.835938 1949.561768,3290.385254 
	C1951.658691,3289.590332 1953.348145,3289.297363 1955.491821,3288.385010 
	C1959.585205,3288.424561 1964.556519,3286.656982 1965.057983,3292.481689 
	C1965.340210,3295.758545 1960.148071,3298.181641 1954.997314,3298.013184 
	C1952.473999,3297.930664 1950.660034,3296.957520 1948.746704,3294.475830 
z"
        />
        <path
          fill="#F5F1E5"
          opacity="1.000000"
          stroke="none"
          d="
M1947.290771,3321.152344 
	C1949.070190,3320.534912 1950.406860,3320.128906 1951.629272,3320.351318 
	C1953.227905,3320.642090 1954.891968,3321.410889 1954.391357,3323.564941 
	C1954.057739,3325.000732 1952.729126,3325.595459 1951.403320,3325.709717 
	C1948.533325,3325.957275 1945.884155,3325.763672 1947.290771,3321.152344 
z"
        />
        <path
          fill="#DFD9CC"
          opacity="1.000000"
          stroke="none"
          d="
M1955.496460,3287.635010 
	C1955.500000,3291.115234 1954.106201,3292.683105 1950.416138,3290.495605 
	C1949.988770,3288.519531 1949.974731,3287.021484 1949.964111,3284.774902 
	C1952.360840,3283.993652 1953.677979,3285.626221 1955.496460,3287.635010 
z"
        />
        <path
          fill="#DFD9CC"
          opacity="1.000000"
          stroke="none"
          d="
M1963.970093,3279.232178 
	C1962.497681,3279.953369 1961.000244,3279.955566 1958.754395,3279.939453 
	C1958.250854,3277.756592 1957.623169,3274.907227 1960.802246,3274.556641 
	C1963.405640,3274.269775 1963.758911,3276.493164 1963.970093,3279.232178 
z"
        />
        <path
          fill="#5E4237"
          opacity="1.000000"
          stroke="none"
          d="
M2355.365234,3712.177490 
	C2355.896484,3714.088867 2356.341553,3716.510010 2353.017090,3717.903076 
	C2352.073242,3715.732910 2353.033447,3713.941162 2355.365234,3712.177490 
z"
        />
        <path
          fill="#C2825D"
          opacity="1.000000"
          stroke="none"
          d="
M2317.968262,3706.030762 
	C2317.151855,3705.796875 2316.360352,3705.537842 2315.569092,3705.278809 
	C2316.177734,3703.970703 2316.854004,3704.727783 2317.778076,3705.581055 
	C2318.034912,3705.947266 2317.993652,3706.005127 2317.968262,3706.030762 
z"
        />
        <path
          fill="#728DA3"
          opacity="1.000000"
          stroke="none"
          d="
M1858.651001,2151.965820 
	C1857.455566,2150.994141 1856.946899,2150.013428 1856.208740,2148.528809 
	C1857.239014,2148.044922 1858.502686,2148.008301 1859.757080,2148.100586 
	C1860.980591,2148.190918 1862.570801,2148.220459 1862.676270,2149.771484 
	C1862.835327,2152.105713 1860.742310,2151.646729 1858.651001,2151.965820 
z"
        />
        <path
          fill="#8C8D89"
          opacity="1.000000"
          stroke="none"
          d="
M2102.286133,1924.429932 
	C2098.694092,1925.944336 2095.826660,1925.448364 2093.477295,1923.520996 
	C2091.807129,1922.150635 2091.552002,1919.953125 2093.615479,1918.665039 
	C2095.696533,1917.366333 2098.002441,1918.316528 2100.071289,1919.227173 
	C2102.102295,1920.121338 2104.609863,1920.872314 2102.286133,1924.429932 
z"
        />
        <path
          fill="#CBC6BA"
          opacity="1.000000"
          stroke="none"
          d="
M2617.773682,1928.234131 
	C2616.796143,1926.980591 2616.354980,1925.407959 2615.802246,1923.031494 
	C2616.667969,1922.164062 2617.645508,1922.100220 2619.356934,1922.040527 
	C2620.174072,1924.206787 2620.382080,1926.406738 2617.773682,1928.234131 
z"
        />
        <path
          fill="#FBF5E5"
          opacity="1.000000"
          stroke="none"
          d="
M997.566284,2930.514893 
	C1000.965942,2930.260742 1003.621155,2931.537598 1003.645630,2934.609863 
	C1003.690552,2940.243896 1008.718140,2942.112061 1011.291199,2945.788818 
	C1012.758484,2947.885254 1015.188110,2948.452637 1017.478516,2946.862549 
	C1020.139343,2945.015381 1020.195312,2942.579346 1018.640991,2939.885254 
	C1013.877625,2931.627930 1011.871033,2922.661865 1012.029053,2913.189941 
	C1012.061951,2911.215820 1012.239319,2909.189941 1012.753723,2907.297607 
	C1013.084900,2906.079102 1014.161255,2904.794922 1015.677002,2905.072021 
	C1017.353516,2905.378662 1017.392151,2906.792725 1016.869324,2908.124512 
	C1015.434082,2911.779785 1017.081665,2913.843994 1020.393494,2915.009033 
	C1023.683594,2916.165771 1025.159668,2913.657227 1026.927124,2911.697998 
	C1028.185425,2910.302979 1027.442505,2907.517822 1030.104858,2907.110107 
	C1032.386353,2907.602783 1033.055176,2909.734863 1034.119995,2911.272461 
	C1040.202881,2920.055664 1039.149048,2922.485840 1028.764893,2924.347900 
	C1028.212280,2924.447021 1027.757324,2925.091797 1026.625854,2925.975342 
	C1029.090332,2931.512695 1034.865112,2935.171631 1036.658691,2941.278320 
	C1037.589844,2944.448486 1038.848389,2947.575439 1040.381836,2950.500244 
	C1041.771484,2953.150635 1043.722778,2955.881836 1047.186035,2955.510742 
	C1050.345093,2955.172607 1050.848877,2952.046387 1051.831177,2949.618408 
	C1053.761475,2944.848633 1053.694458,2940.512451 1051.946167,2935.275146 
	C1046.526367,2919.039551 1036.769531,2904.573486 1033.271484,2887.378662 
	C1034.942749,2885.633301 1037.041504,2887.159424 1038.247925,2888.250000 
	C1045.516846,2894.818115 1054.030151,2892.166504 1062.074219,2891.447754 
	C1069.445923,2890.789062 1072.513672,2884.923828 1070.004517,2878.009033 
	C1069.440674,2876.455322 1068.495117,2874.919922 1068.409302,2873.339111 
	C1068.258545,2870.561035 1069.310913,2867.812256 1071.958740,2866.735107 
	C1075.100952,2865.457275 1076.646118,2867.966309 1078.145874,2870.326416 
	C1080.178589,2873.524902 1079.614014,2877.413330 1080.727295,2880.635254 
	C1084.512939,2881.733398 1086.807739,2879.426270 1089.381348,2878.110840 
	C1091.091064,2877.236816 1091.828735,2875.606445 1090.796509,2873.962891 
	C1086.927612,2867.803223 1089.742920,2859.010254 1082.168091,2854.118408 
	C1087.533081,2853.402344 1090.576416,2855.409668 1091.920288,2859.787109 
	C1095.325806,2870.879883 1102.408813,2879.550781 1110.067261,2887.886230 
	C1111.317017,2889.246094 1112.675049,2890.280518 1114.280273,2889.138916 
	C1116.243896,2887.742432 1114.864746,2886.129150 1113.797729,2884.948486 
	C1111.566650,2882.479980 1108.702271,2880.466309 1106.928223,2877.727783 
	C1103.543457,2872.502930 1100.039795,2867.367432 1104.179565,2860.406494 
	C1107.562134,2854.719238 1106.326172,2853.128418 1099.879761,2852.019531 
	C1098.308838,2851.749268 1096.531494,2852.211914 1095.403564,2850.587646 
	C1100.995850,2845.065674 1100.995728,2845.065918 1108.148804,2848.738525 
	C1113.928711,2851.706299 1116.941284,2849.933594 1116.718384,2843.298340 
	C1116.619873,2840.365479 1117.799438,2838.074951 1119.145996,2835.878418 
	C1122.133545,2831.006348 1124.948120,2826.209229 1124.147583,2820.124268 
	C1123.990479,2818.929443 1124.385742,2817.411865 1126.099365,2817.111816 
	C1127.602783,2816.848633 1128.554932,2817.814453 1129.518188,2818.714600 
	C1131.652344,2820.708740 1132.087524,2823.297852 1131.980103,2826.035400 
	C1131.723877,2832.575195 1133.234009,2839.331787 1128.850220,2845.327393 
	C1127.829102,2846.723633 1127.992920,2849.185303 1128.059570,2851.141357 
	C1128.265015,2857.186523 1127.787842,2862.857910 1124.414185,2868.312500 
	C1122.196533,2871.897949 1121.574829,2875.897217 1124.631348,2880.358887 
	C1127.558228,2884.631592 1129.139160,2890.004150 1127.251343,2895.584229 
	C1125.852417,2899.719238 1129.774292,2902.729980 1128.965942,2907.633057 
	C1127.002563,2919.543213 1122.481323,2930.568848 1119.531738,2942.090088 
	C1118.613525,2945.676270 1117.794189,2949.183350 1118.009155,2952.878418 
	C1118.270874,2957.377686 1118.079346,2961.564697 1113.013916,2963.695557 
	C1111.941162,2964.146484 1110.341919,2965.923828 1110.557495,2966.472412 
	C1113.806885,2974.741211 1105.238892,2981.545654 1107.781372,2989.677979 
	C1108.026978,2990.463623 1107.389404,2991.525391 1107.146118,2992.523682 
	C1098.915771,2987.734131 1096.859375,2984.697021 1098.750977,2977.257568 
	C1099.792847,2973.159424 1099.391602,2970.346680 1096.513550,2967.380371 
	C1092.791016,2963.543457 1091.043335,2959.066406 1093.663086,2953.764160 
	C1093.940796,2953.201904 1093.968140,2952.444580 1093.904907,2951.798096 
	C1093.661011,2949.301025 1094.958496,2946.286133 1091.791260,2944.711914 
	C1090.485352,2944.062988 1089.166138,2944.541260 1088.064697,2945.349609 
	C1085.520142,2947.217041 1083.972290,2949.376221 1084.533081,2952.937012 
	C1085.477905,2958.935303 1087.746216,2964.855713 1090.855591,2969.441162 
	C1094.574951,2974.926270 1092.887207,2979.376221 1092.020996,2985.216797 
	C1083.514648,2987.886719 1082.938721,2988.437744 1086.182617,2995.077393 
	C1089.313599,3001.485596 1085.829712,3006.610840 1083.622314,3011.877197 
	C1082.627319,3014.250000 1079.981689,3013.191406 1078.902466,3011.266846 
	C1074.833862,3004.011230 1066.482666,3000.529541 1063.247681,2992.182373 
	C1061.609863,2987.956543 1056.668945,2984.671387 1050.756348,2986.665283 
	C1049.567261,2987.066162 1048.154175,2987.253418 1046.933594,2987.037109 
	C1039.445801,2985.708740 1039.454346,2985.660645 1036.271484,2993.291748 
	C1033.070679,2991.373535 1030.447266,2985.896484 1025.706055,2990.753418 
	C1024.123901,2992.374268 1021.920410,2990.964600 1020.162659,2990.050049 
	C1017.456726,2988.642334 1014.647217,2987.774658 1010.785278,2988.009521 
	C1010.003540,2985.242676 1009.999817,2982.475586 1009.997925,2978.878662 
	C1006.900391,2974.442871 1008.192871,2971.094482 1011.103699,2968.407227 
	C1013.741394,2965.971924 1016.442566,2966.612793 1018.521057,2969.582031 
	C1013.940918,2962.531006 1009.897156,2955.198730 1003.608154,2949.102051 
	C998.931580,2944.568604 995.794617,2938.306152 997.566284,2930.514893 
z"
        />
        <path
          fill="#D0D1C8"
          opacity="1.000000"
          stroke="none"
          d="
M1034.269653,3065.028320 
	C1020.192078,3067.241455 1006.154114,3066.529785 992.104492,3066.048584 
	C985.869446,3065.835205 979.628296,3065.605225 973.430054,3067.521729 
	C970.516052,3068.422852 966.820190,3068.967285 963.979736,3065.093750 
	C963.550476,3062.465576 963.608154,3060.589844 965.536438,3058.552490 
	C974.621643,3057.962158 983.244202,3058.436279 991.676697,3056.007812 
	C999.856262,3053.651855 1008.054443,3056.515869 1016.276611,3056.348389 
	C1020.418762,3056.263672 1024.436401,3057.476807 1028.861084,3056.444824 
	C1033.991577,3055.248047 1035.232422,3057.503418 1034.269653,3065.028320 
z"
        />
        <path
          fill="#DFD9CC"
          opacity="1.000000"
          stroke="none"
          d="
M895.666382,3057.350586 
	C895.107361,3062.856934 891.223755,3062.057861 888.120361,3061.811523 
	C882.213745,3061.342529 876.074585,3061.230469 870.522644,3059.444824 
	C861.193359,3056.444092 851.825439,3055.508789 842.166382,3055.802979 
	C840.597778,3055.850830 839.002075,3055.009277 836.674805,3054.183838 
	C836.190186,3051.491943 836.050720,3048.747070 839.060486,3048.265381 
	C843.576904,3047.542480 847.307861,3041.536621 851.698730,3044.432617 
	C855.491272,3046.933838 857.389099,3050.703369 863.337891,3048.519531 
	C867.756592,3046.897461 873.205750,3048.082520 879.102539,3048.013672 
	C881.949158,3048.611328 884.796631,3047.840820 884.263184,3051.692871 
	C883.738708,3055.480469 884.009644,3055.518066 889.378662,3054.237305 
	C891.471985,3055.563965 894.193359,3054.718994 895.666382,3057.350586 
z"
        />
        <path
          fill="#FBF5E5"
          opacity="1.000000"
          stroke="none"
          d="
M1099.995117,3002.852539 
	C1105.396240,3004.054199 1104.186157,3010.972900 1109.297119,3013.861328 
	C1114.795532,3014.210205 1116.373291,3017.158447 1115.474487,3021.363525 
	C1114.818237,3024.434326 1112.765747,3025.960205 1109.694946,3023.583252 
	C1109.061523,3023.093018 1108.686523,3022.539795 1108.666260,3022.610107 
	C1108.482300,3023.247803 1108.619385,3023.934082 1108.520630,3024.572754 
	C1106.431641,3038.090820 1101.036133,3050.550537 1095.692627,3063.835693 
	C1092.697632,3067.173584 1089.955322,3068.380859 1086.361572,3064.774658 
	C1082.191772,3057.809570 1085.683960,3053.328125 1089.833130,3049.300293 
	C1092.863037,3046.359131 1096.816895,3044.371582 1094.293335,3038.853027 
	C1093.132690,3036.314941 1095.387817,3034.015137 1097.053711,3031.864014 
	C1101.289185,3026.394531 1105.684082,3021.128174 1100.493164,3013.437500 
	C1098.937012,3011.131592 1100.085693,3006.999756 1099.995117,3002.852539 
z"
        />
        <path
          fill="#D0D1C8"
          opacity="1.000000"
          stroke="none"
          d="
M1085.504150,3064.408203 
	C1088.210083,3065.278809 1090.433472,3066.462891 1093.337158,3064.018555 
	C1105.521240,3058.812012 1116.714844,3062.351562 1127.589355,3066.222900 
	C1131.452026,3067.598145 1132.907104,3071.907227 1129.995483,3076.730225 
	C1116.212891,3078.216797 1104.085571,3072.877441 1090.715332,3070.413086 
	C1088.324951,3069.765869 1086.631348,3069.471680 1084.491699,3068.714844 
	C1084.370239,3067.094482 1084.694702,3065.936768 1085.504150,3064.408203 
z"
        />
        <path
          fill="#D0D1C8"
          opacity="1.000000"
          stroke="none"
          d="
M896.478149,3058.041016 
	C894.136414,3056.954834 892.406555,3055.956787 890.325562,3054.469727 
	C893.412231,3053.989258 896.850037,3053.998047 901.146118,3054.021973 
	C909.329163,3053.143311 915.674927,3058.269531 923.272461,3057.203613 
	C926.257874,3056.784912 928.853577,3060.407227 928.476074,3064.938721 
	C919.245728,3067.410400 910.808411,3063.078369 902.071716,3063.889893 
	C897.394470,3064.324707 900.184082,3058.783203 896.478149,3058.041016 
z"
        />
        <path
          fill="#DFD9CC"
          opacity="1.000000"
          stroke="none"
          d="
M928.807007,3065.721680 
	C925.724915,3060.108398 922.460388,3057.544189 915.825745,3059.368408 
	C911.320251,3060.606934 907.424744,3056.141846 902.484253,3054.548340 
	C903.121765,3052.726074 904.686768,3051.712646 906.244995,3052.149414 
	C914.950928,3054.589355 924.557312,3052.541016 933.398071,3057.805176 
	C935.701904,3060.065918 936.092712,3062.195801 934.488342,3065.245117 
	C932.499268,3065.916992 931.018250,3065.887207 928.807007,3065.721680 
z"
        />
        <path
          fill="#DFD9CC"
          opacity="1.000000"
          stroke="none"
          d="
M965.149353,3058.025879 
	C965.937988,3059.911865 966.073303,3061.838135 964.221985,3063.906738 
	C960.947876,3064.901123 958.207031,3065.410645 954.750854,3065.891602 
	C949.049316,3062.532959 941.772949,3065.001709 937.846680,3058.719238 
	C942.658875,3054.438232 947.930054,3055.686035 952.808777,3056.786865 
	C956.653381,3057.654297 960.414001,3057.967285 965.149353,3058.025879 
z"
        />
        <path
          fill="#D0D1C8"
          opacity="1.000000"
          stroke="none"
          d="
M1055.982178,3065.261230 
	C1050.553833,3065.987549 1045.107544,3065.974854 1038.747437,3065.868164 
	C1037.992188,3060.639648 1040.096924,3056.418457 1045.538696,3056.356689 
	C1050.562500,3056.299805 1054.851318,3058.788574 1055.982178,3065.261230 
z"
        />
        <path
          fill="#D0D1C8"
          opacity="1.000000"
          stroke="none"
          d="
M937.351440,3058.031250 
	C942.785217,3061.426025 949.525208,3060.154297 953.925049,3065.279297 
	C947.957581,3065.910400 941.879578,3065.958008 934.890991,3065.976074 
	C933.994507,3063.857910 934.008667,3061.769043 934.039917,3058.899658 
	C934.935242,3058.089111 935.813416,3058.058838 937.351440,3058.031250 
z"
        />
        <path
          fill="#FBF5E5"
          opacity="1.000000"
          stroke="none"
          d="
M1092.000854,2986.792236 
	C1096.280396,2989.162598 1099.184082,2993.196533 1099.974976,2999.298584 
	C1093.734741,2998.177979 1091.455444,2993.786865 1092.000854,2986.792236 
z"
        />
        <path
          fill="#D0D1C8"
          opacity="1.000000"
          stroke="none"
          d="
M1071.659912,3067.472168 
	C1068.313477,3067.660156 1064.578247,3068.682861 1060.547852,3066.554199 
	C1065.715698,3060.528809 1066.651733,3060.593994 1071.659912,3067.472168 
z"
        />
        <path
          fill="#D0D1C8"
          opacity="1.000000"
          stroke="none"
          d="
M1079.649902,3067.710449 
	C1078.513062,3070.019043 1076.763550,3070.221436 1074.397217,3068.660156 
	C1075.397583,3066.020020 1077.155151,3065.630371 1079.649902,3067.710449 
z"
        />
        <path
          fill="#EEE9DC"
          opacity="1.000000"
          stroke="none"
          d="
M998.002136,2929.235107 
	C1000.175354,2939.126709 1002.970215,2947.829590 1011.840881,2952.985840 
	C1013.185913,2953.767578 1014.560730,2955.267334 1014.995483,2956.713379 
	C1016.919800,2963.113770 1020.346619,2968.596680 1024.431519,2973.771973 
	C1025.250488,2974.809570 1025.756714,2976.267578 1024.241821,2977.184570 
	C1023.027344,2977.919922 1021.673279,2977.682861 1020.709045,2976.541748 
	C1020.075623,2975.792236 1019.623901,2974.891602 1019.062073,2974.078857 
	C1017.643494,2972.027100 1017.168457,2968.232178 1013.809082,2969.174072 
	C1010.618103,2970.069092 1010.273010,2973.564453 1010.013062,2977.315430 
	C1009.120605,2978.027100 1008.241333,2978.005371 1006.702515,2977.977295 
	C1002.791199,2972.349609 998.719971,2967.771484 993.474976,2963.585205 
	C988.927612,2959.956055 985.819275,2953.882080 985.670166,2946.661865 
	C991.541931,2937.173340 991.356689,2935.742920 982.614746,2924.335449 
	C980.269470,2922.700684 980.096802,2920.452148 978.457642,2918.395020 
	C978.027710,2912.981201 978.191040,2908.002441 977.956116,2903.042480 
	C977.739929,2898.477051 979.465210,2894.641113 981.673584,2890.896240 
	C982.542725,2889.422607 983.632629,2888.145752 985.601135,2888.636719 
	C987.624573,2889.141357 987.790100,2890.873779 987.904053,2892.542969 
	C988.180786,2896.598633 987.540405,2900.408691 988.510803,2904.800049 
	C990.416443,2913.423828 997.017029,2919.793457 998.002136,2929.235107 
z"
        />
        <path
          fill="#E7E3DA"
          opacity="1.000000"
          stroke="none"
          d="
M909.198730,2913.826172 
	C915.202209,2916.627441 921.127319,2918.359131 927.038513,2920.232178 
	C932.632141,2922.004883 938.371521,2923.623535 943.924011,2925.210449 
	C957.473328,2929.083008 968.052917,2937.134521 979.347595,2945.715820 
	C982.565918,2949.348877 983.135559,2952.646240 978.911804,2955.960693 
	C975.651978,2953.237793 973.325317,2950.447266 970.971680,2947.679199 
	C970.121826,2946.679932 969.109497,2944.822266 968.338257,2944.905273 
	C959.730164,2945.833008 954.963440,2938.568604 948.168213,2935.725342 
	C936.389771,2930.797607 924.602844,2925.872559 912.379700,2922.149902 
	C909.717285,2921.338867 907.263000,2920.809570 906.075195,2917.225342 
	C906.844238,2915.710449 907.742004,2914.889404 909.198730,2913.826172 
z"
        />
        <path
          fill="#FBF5E5"
          opacity="1.000000"
          stroke="none"
          d="
M978.004456,2956.749268 
	C980.773743,2953.698730 979.778198,2950.493408 979.954346,2946.797363 
	C981.452271,2946.019043 982.963440,2946.008545 985.228760,2945.994141 
	C988.022583,2951.223389 992.415527,2955.167236 994.009338,2960.645508 
	C994.506348,2962.353760 995.547180,2963.722656 997.367920,2963.775879 
	C1006.034851,2964.030273 1005.962830,2970.197998 1006.031860,2977.146973 
	C1002.661621,2978.007324 1000.708984,2975.638428 998.556641,2973.568359 
	C995.766907,2970.884766 993.431458,2968.890869 988.535217,2968.884277 
	C982.929321,2968.876709 979.075562,2963.608398 978.004456,2956.749268 
z"
        />
        <path
          fill="#F2EBDD"
          opacity="1.000000"
          stroke="none"
          d="
M977.192383,3007.805664 
	C974.795837,3010.334961 973.762878,3008.572021 972.916382,3006.753906 
	C972.741089,3006.376953 973.178223,3005.291260 973.561157,3005.149902 
	C975.488220,3004.438477 976.482849,3005.674316 977.192383,3007.805664 
z"
        />
        <path
          fill="#F2EBDD"
          opacity="1.000000"
          stroke="none"
          d="
M979.287842,3013.141602 
	C980.774414,3012.725830 981.313843,3013.245605 981.083801,3014.400391 
	C981.035950,3014.640137 980.696045,3014.962646 980.453613,3014.998779 
	C979.286865,3015.173096 978.799561,3014.604004 979.287842,3013.141602 
z"
        />
        <path
          fill="#C2825D"
          opacity="1.000000"
          stroke="none"
          d="
M794.165039,3042.837402 
	C787.201721,3044.894287 780.756226,3040.786377 773.005737,3041.759766 
	C771.452820,3038.301270 768.825439,3038.116699 766.091187,3037.911133 
	C762.662170,3037.653564 759.703979,3036.398193 757.798584,3033.314941 
	C756.093750,3030.556152 755.223389,3027.367920 758.058044,3025.263184 
	C763.860168,3020.955322 765.739868,3013.199463 772.751953,3009.810791 
	C776.260010,3008.115234 777.620422,3003.755127 778.121582,2998.922119 
	C779.439575,2998.138916 780.679138,2998.076904 782.538025,2998.071045 
	C784.934814,3013.259521 789.027222,3027.809814 794.165039,3042.837402 
z"
        />
        <path
          fill="#DFD9CC"
          opacity="1.000000"
          stroke="none"
          d="
M832.195557,3052.639893 
	C822.583374,3051.286377 812.846497,3049.223145 802.573608,3046.645508 
	C802.754395,3039.357422 802.703613,3039.542725 809.309631,3041.353760 
	C813.727051,3042.564697 818.394470,3043.042969 822.888428,3043.590576 
	C828.537964,3044.279297 831.271973,3046.645020 832.195557,3052.639893 
z"
        />
        <path
          fill="#F2EBDD"
          opacity="1.000000"
          stroke="none"
          d="
M871.594421,3037.421875 
	C869.240356,3040.468506 867.430298,3039.736084 865.918762,3037.425781 
	C864.455872,3035.190186 863.444946,3032.753906 864.635681,3030.105713 
	C865.286377,3028.658691 866.757385,3028.650391 867.922607,3029.412842 
	C870.515076,3031.108887 872.161194,3033.413818 871.594421,3037.421875 
z"
        />
        <path
          fill="#DFD9CC"
          opacity="1.000000"
          stroke="none"
          d="
M834.931580,3045.052734 
	C834.891418,3044.975342 834.971741,3045.130127 834.931580,3045.052734 
z"
        />
        <path
          fill="#646A6C"
          opacity="1.000000"
          stroke="none"
          d="
M872.984619,3132.136719 
	C874.573608,3133.918457 876.470947,3134.067871 878.666565,3135.013672 
	C879.243835,3135.328857 879.261597,3135.608154 879.366455,3135.700684 
	C880.735901,3136.444824 881.403809,3137.532471 881.713501,3139.538574 
	C883.089233,3144.194580 884.473694,3148.187500 886.056213,3152.751465 
	C882.453796,3154.248779 879.175964,3153.513184 876.031738,3153.545898 
	C868.193115,3153.627441 866.923828,3152.209717 868.067200,3144.429199 
	C868.629089,3140.605469 870.132446,3136.923584 869.741760,3132.933350 
	C869.724854,3132.760742 871.431702,3132.419189 872.984619,3132.136719 
z"
        />
        <path
          fill="#646A6C"
          opacity="1.000000"
          stroke="none"
          d="
M909.481567,3154.624512 
	C912.395752,3154.084961 914.311951,3152.955322 916.290588,3151.635986 
	C919.059082,3149.789795 922.131653,3148.842529 925.481689,3150.785889 
	C920.764709,3156.295166 918.096619,3163.837402 908.766113,3164.714844 
	C902.245178,3160.838623 902.295288,3160.312500 909.481567,3154.624512 
z"
        />
        <path
          fill="#626669"
          opacity="1.000000"
          stroke="none"
          d="
M893.883301,3132.731934 
	C898.239380,3131.427979 901.979980,3132.048340 903.214844,3136.933105 
	C903.841187,3139.410645 906.560181,3140.013916 907.480347,3142.843262 
	C903.121155,3146.874023 898.597046,3144.107422 894.666809,3142.700195 
	C889.511230,3140.853760 893.366211,3136.684082 893.883301,3132.731934 
z"
        />
        <path
          fill="#4E585F"
          opacity="1.000000"
          stroke="none"
          d="
M830.768677,3129.990234 
	C830.222351,3126.138428 832.804077,3126.066895 835.461975,3126.504395 
	C837.637634,3126.862549 839.752930,3127.588135 842.582886,3128.322266 
	C843.420898,3133.616455 840.847473,3134.814453 836.490173,3133.010498 
	C834.719543,3132.277588 833.138855,3131.085693 830.768677,3129.990234 
z"
        />
        <path
          fill="#4E585F"
          opacity="1.000000"
          stroke="none"
          d="
M861.068115,3168.921631 
	C859.905823,3169.459473 859.208557,3169.750732 858.649170,3169.582764 
	C856.241333,3168.859375 853.325317,3168.483643 853.075134,3165.251221 
	C852.974976,3163.956543 854.576477,3162.676758 855.730896,3162.940186 
	C858.616638,3163.598877 862.699036,3163.422363 861.068115,3168.921631 
z"
        />
        <path
          fill="#0D171F"
          opacity="1.000000"
          stroke="none"
          d="
M882.183411,3139.819336 
	C880.516357,3139.497559 879.800415,3138.453613 879.479858,3136.456055 
	C881.229309,3136.399414 884.208496,3135.936768 882.183411,3139.819336 
z"
        />
        <path
          fill="#F3AB7B"
          opacity="1.000000"
          stroke="none"
          d="
M728.593567,3259.684570 
	C724.781677,3256.883789 722.913269,3252.960693 722.227722,3248.695801 
	C721.738953,3245.655273 721.114563,3243.100586 718.863892,3240.749512 
	C716.378540,3238.153320 715.476074,3235.208740 719.165222,3232.312012 
	C723.287170,3229.075439 720.482056,3226.485107 717.279419,3226.431396 
	C709.810425,3226.305908 703.322144,3219.130859 695.305908,3223.539551 
	C694.489319,3223.988525 693.455322,3223.147949 693.088867,3222.120361 
	C692.697083,3221.021484 693.220520,3220.044678 694.066284,3219.558594 
	C695.474731,3218.749023 697.057739,3218.249023 698.544434,3217.568848 
	C705.822144,3214.237061 708.868286,3208.970459 707.167725,3200.896484 
	C706.309937,3196.823486 705.778931,3193.710205 711.265259,3192.091797 
	C712.778870,3196.993164 718.026917,3200.992676 714.808777,3206.320312 
	C716.996765,3208.269287 719.057556,3207.837891 721.004822,3208.009766 
	C728.476929,3208.669189 728.715149,3209.201660 724.613647,3215.795166 
	C722.485657,3219.216064 726.406616,3225.053467 731.662415,3225.813477 
	C734.918762,3226.284424 738.209229,3225.905273 741.527283,3225.303711 
	C746.561768,3224.391113 748.710022,3226.331543 747.131531,3231.139160 
	C744.672546,3238.628174 747.052551,3242.401367 753.737732,3246.244141 
	C760.699951,3250.245850 768.198425,3253.967529 773.395081,3260.728271 
	C775.470154,3263.428223 777.446655,3266.001465 777.806396,3269.388428 
	C778.114624,3272.289795 776.972534,3274.649902 773.942810,3275.525635 
	C771.503723,3276.230469 769.342102,3275.742676 767.914185,3273.398682 
	C767.569641,3272.833252 767.281372,3272.233643 766.933350,3271.670410 
	C762.630859,3264.706787 762.554321,3264.689453 755.981873,3269.022461 
	C749.973206,3272.983887 749.112122,3272.782959 745.307800,3266.582031 
	C744.099365,3264.612793 743.064819,3262.490967 741.595764,3260.735840 
	C737.211670,3255.498535 735.932007,3255.425781 728.593567,3259.684570 
z"
        />
        <path
          fill="#D99469"
          opacity="1.000000"
          stroke="none"
          d="
M744.011536,3285.162842 
	C737.550537,3287.885742 739.918579,3294.108154 738.199219,3298.278320 
	C737.132690,3300.864746 738.524597,3304.140869 736.483276,3307.462891 
	C735.930664,3308.027832 734.955261,3308.016357 734.467346,3308.008301 
	C731.154175,3304.441406 729.622314,3300.862061 732.683228,3296.504883 
	C736.090393,3291.655273 736.814026,3286.030762 735.663025,3280.385986 
	C734.848572,3276.391602 735.835693,3273.281006 739.697144,3272.930908 
	C743.627930,3272.573975 743.829407,3276.355469 743.959290,3279.393311 
	C744.029785,3281.041748 743.999329,3282.694336 744.011536,3285.162842 
z"
        />
        <path
          fill="#D99469"
          opacity="1.000000"
          stroke="none"
          d="
M695.292847,3102.988037 
	C692.210571,3103.006836 692.429565,3101.550293 693.518860,3100.008789 
	C694.310974,3098.888184 695.665100,3098.161865 696.739563,3099.466797 
	C697.729431,3100.669678 696.795044,3101.829102 695.292847,3102.988037 
z"
        />
        <path
          fill="#A26847"
          opacity="1.000000"
          stroke="none"
          d="
M864.436523,3413.258789 
	C863.986511,3413.349854 863.496826,3413.178711 863.507568,3413.137451 
	C863.693665,3412.421387 864.092773,3412.400879 864.436523,3413.258789 
z"
        />
        <path
          fill="#9FA2A1"
          opacity="1.000000"
          stroke="none"
          d="
M1090.023438,3070.571533 
	C1096.286255,3066.960205 1102.259644,3070.257568 1108.250854,3071.169189 
	C1115.086548,3072.209229 1121.770874,3074.244873 1129.324341,3075.791260 
	C1136.507080,3073.475098 1141.018799,3077.096924 1145.307129,3080.817139 
	C1165.953735,3098.730957 1183.832520,3119.205322 1200.083008,3141.098633 
	C1203.884521,3146.220215 1210.275513,3150.172852 1208.968994,3158.622070 
	C1199.875977,3156.709961 1191.087646,3154.333740 1182.432983,3151.542236 
	C1177.052368,3149.806641 1171.559937,3148.833496 1166.020142,3148.029785 
	C1157.828125,3146.841064 1156.086182,3144.252441 1158.459351,3136.354248 
	C1159.579468,3132.626709 1155.358154,3126.406738 1150.370483,3124.555664 
	C1149.457031,3124.216797 1148.420898,3124.214844 1147.444946,3124.037354 
	C1139.891113,3122.663330 1139.891357,3122.661865 1142.763916,3115.102539 
	C1140.687378,3114.149902 1138.503418,3113.350830 1136.527588,3112.195801 
	C1133.233276,3110.270264 1130.706299,3110.569336 1127.593872,3113.136230 
	C1123.936646,3116.152588 1122.119507,3115.278564 1120.668091,3110.812988 
	C1120.163086,3109.259033 1120.045410,3107.557861 1119.906006,3105.910889 
	C1119.673584,3103.166260 1117.871460,3101.369385 1115.588745,3101.026367 
	C1107.022705,3099.739990 1101.733276,3094.424805 1097.045532,3087.705322 
	C1095.666504,3085.728760 1094.190552,3083.142090 1090.773438,3082.118896 
	C1087.033569,3078.922363 1090.783325,3076.232178 1090.313843,3072.715820 
	C1090.044800,3071.764160 1090.036621,3071.423340 1090.023438,3070.571533 
z"
        />
        <path
          fill="#B6BCC1"
          opacity="1.000000"
          stroke="none"
          d="
M966.720947,3088.107178 
	C963.117249,3088.114502 960.383789,3088.067139 956.826538,3088.017578 
	C953.646240,3087.889160 951.210266,3088.298340 948.470703,3086.535645 
	C939.601074,3085.882568 931.233521,3084.361328 923.831116,3081.281494 
	C913.777649,3077.097900 903.737610,3077.894287 893.644104,3078.293945 
	C887.481018,3078.538330 881.416748,3080.145508 875.206421,3078.378906 
	C874.163818,3078.082031 872.749512,3079.091553 870.839844,3079.838135 
	C865.996826,3080.270752 861.977417,3083.525146 857.646851,3080.733643 
	C855.976074,3079.656982 853.855530,3078.803955 854.705566,3076.299561 
	C855.451355,3074.102295 857.264282,3075.068848 858.869202,3075.285156 
	C866.796814,3076.354004 874.588074,3075.360107 882.536377,3074.647949 
	C895.328308,3073.502197 908.264954,3073.262451 921.212952,3075.223633 
	C934.527405,3077.240234 947.756531,3079.034180 961.622131,3076.607422 
	C972.458130,3074.710449 984.062622,3074.679199 995.258545,3077.341064 
	C999.741577,3078.406738 1004.480591,3079.014160 1008.746277,3076.160889 
	C1013.544617,3072.951660 1017.726501,3079.174805 1022.576172,3077.115479 
	C1023.085815,3076.899170 1023.990356,3079.177246 1023.807495,3080.541504 
	C1023.408264,3083.519775 1022.407776,3086.300049 1020.418213,3089.465576 
	C1015.451538,3086.166016 1010.727478,3087.329346 1005.030334,3088.850342 
	C995.182861,3091.479492 984.654602,3090.581543 974.422852,3089.125000 
	C972.140991,3088.800049 969.868103,3088.411377 966.720947,3088.107178 
z"
        />
        <path
          fill="#98999E"
          opacity="1.000000"
          stroke="none"
          d="
M1084.294189,3083.626221 
	C1077.417847,3084.631836 1072.015991,3089.137695 1064.667847,3089.903564 
	C1064.197998,3087.213379 1065.991699,3085.757812 1068.286621,3084.994385 
	C1073.598145,3083.226807 1078.966309,3081.628662 1085.071289,3079.906006 
	C1086.121216,3080.187256 1086.411255,3080.524170 1086.346680,3081.425781 
	C1085.414185,3082.287842 1084.927368,3082.694580 1084.294189,3083.626221 
z"
        />
        <path
          fill="#8C8D89"
          opacity="1.000000"
          stroke="none"
          d="
M1086.526855,3081.989746 
	C1085.973633,3081.633789 1085.955078,3081.277100 1085.883789,3080.385254 
	C1087.053833,3077.644287 1088.276489,3075.438232 1089.776001,3072.668457 
	C1093.647827,3074.872559 1090.546387,3077.769775 1090.161011,3081.298340 
	C1090.104736,3081.982910 1089.116211,3082.002197 1088.623535,3081.996094 
	C1087.774414,3081.989990 1087.417969,3081.989502 1086.526855,3081.989746 
z"
        />
        <path
          fill="#DFD9CC"
          opacity="1.000000"
          stroke="none"
          d="
M800.144165,3051.685059 
	C799.669922,3051.666016 799.484741,3051.371094 799.115417,3050.585938 
	C799.543762,3050.405762 800.722595,3050.067139 800.144165,3051.685059 
z"
        />
        <path
          fill="#ECA777"
          opacity="1.000000"
          stroke="none"
          d="
M682.400269,3143.231689 
	C682.827942,3148.753418 680.024353,3151.454834 675.318970,3154.199219 
	C674.361084,3148.465576 677.646057,3145.183105 679.146912,3141.487549 
	C679.630859,3140.295410 681.397949,3141.437744 682.400269,3143.231689 
z"
        />
        <path
          fill="#ECA777"
          opacity="1.000000"
          stroke="none"
          d="
M680.089722,3132.884277 
	C681.448547,3133.629395 681.461365,3134.380127 680.492981,3135.042725 
	C680.289795,3135.181641 679.818420,3135.176758 679.620483,3135.032715 
	C678.671692,3134.341309 678.711365,3133.591797 680.089722,3132.884277 
z"
        />
        <path
          fill="#ECA777"
          opacity="1.000000"
          stroke="none"
          d="
M673.753052,3118.104004 
	C673.775269,3117.896484 674.027222,3119.125488 673.753052,3118.104004 
z"
        />
        <path
          fill="#ECA777"
          opacity="1.000000"
          stroke="none"
          d="
M673.995605,3117.995117 
	C674.228455,3118.104980 673.973755,3116.884766 674.259155,3117.905273 
	C674.012207,3118.009766 674.000000,3118.000000 673.995605,3117.995117 
z"
        />
        <path
          fill="#ECA777"
          opacity="1.000000"
          stroke="none"
          d="
M665.754395,3126.102295 
	C665.836304,3126.068115 665.672546,3126.136475 665.754395,3126.102295 
z"
        />
        <path
          fill="#ECA777"
          opacity="1.000000"
          stroke="none"
          d="
M666.246948,3125.898438 
	C666.164612,3125.932129 666.329285,3125.864502 666.246948,3125.898438 
z"
        />
        <path
          fill="#EAE6D7"
          opacity="1.000000"
          stroke="none"
          d="
M1261.407593,3704.371582 
	C1269.602417,3704.515625 1269.602417,3704.515625 1269.993896,3713.178711 
	C1269.332031,3717.814697 1269.429565,3722.346436 1262.672729,3720.504395 
	C1261.637329,3717.159180 1260.150391,3715.663574 1257.251099,3716.609131 
	C1254.379761,3717.545898 1252.139160,3719.355957 1249.976685,3721.614502 
	C1243.889526,3727.970215 1243.735962,3727.824219 1236.322266,3723.565186 
	C1236.035034,3723.399902 1235.793823,3723.145752 1235.496094,3723.010010 
	C1233.912720,3722.287842 1232.194702,3721.795410 1231.017456,3723.473633 
	C1229.603638,3725.489990 1230.562134,3727.330078 1232.213745,3728.677734 
	C1234.271851,3730.356934 1237.302612,3730.214600 1239.221191,3732.327637 
	C1240.859009,3734.131348 1242.300293,3735.955078 1241.322266,3738.529541 
	C1240.544434,3740.576660 1238.842285,3741.700684 1236.710205,3741.788086 
	C1233.052734,3741.937744 1229.378906,3742.024658 1225.726196,3741.846436 
	C1223.023071,3741.714355 1220.188232,3740.910645 1218.908081,3738.368164 
	C1217.150269,3734.876953 1220.838013,3734.036865 1222.552734,3732.286133 
	C1224.441284,3730.358154 1226.751465,3728.427002 1224.031006,3725.616455 
	C1221.607544,3723.113281 1216.594482,3723.691895 1214.455078,3726.518799 
	C1209.947021,3732.475586 1207.870728,3733.789062 1202.880127,3730.878662 
	C1198.493286,3728.320312 1194.177246,3727.945801 1189.515869,3727.975342 
	C1186.501343,3727.994385 1184.265015,3727.093018 1184.040039,3722.810547 
	C1189.901855,3720.494141 1194.181396,3717.643311 1194.326538,3710.704834 
	C1194.380127,3708.139893 1196.505615,3705.841553 1198.908691,3706.461914 
	C1206.550293,3708.433594 1212.216675,3704.292969 1218.273315,3701.129150 
	C1219.817871,3700.322266 1221.604126,3699.689697 1222.733521,3701.325928 
	C1223.868042,3702.969727 1222.294312,3704.262207 1221.372681,3705.618652 
	C1219.300171,3708.668457 1215.533325,3709.503906 1212.023071,3712.127197 
	C1218.223145,3715.147217 1222.746826,3720.596191 1229.434937,3714.727539 
	C1231.157471,3713.215820 1233.773438,3714.288574 1236.032959,3715.266846 
	C1244.237549,3718.819824 1244.273071,3718.737793 1249.088257,3709.903809 
	C1246.968506,3706.916992 1243.715576,3708.183594 1240.963623,3708.029297 
	C1237.703613,3707.845947 1235.110352,3706.658447 1233.408203,3703.971924 
	C1231.627808,3701.161621 1232.040649,3698.912842 1235.729492,3698.138184 
	C1238.476807,3697.561279 1241.922607,3699.058350 1243.822510,3694.728516 
	C1249.870239,3690.292725 1252.358643,3694.258789 1254.309692,3698.759521 
	C1255.637207,3701.822266 1256.298462,3705.272705 1261.407593,3704.371582 
z"
        />
        <path
          fill="#EBE4D0"
          opacity="1.000000"
          stroke="none"
          d="
M1263.337158,3742.003906 
	C1263.841675,3745.927490 1264.820068,3749.065674 1269.222534,3750.079346 
	C1270.467651,3750.365967 1271.679565,3750.905029 1272.828247,3751.484131 
	C1280.291870,3755.247803 1280.855713,3757.475098 1276.116943,3765.354492 
	C1273.213745,3768.129639 1268.650879,3762.593262 1266.161011,3768.738770 
	C1265.543091,3770.263672 1259.310669,3768.418701 1256.325195,3766.706787 
	C1252.892578,3764.738281 1250.215210,3763.435547 1246.551025,3766.192139 
	C1243.140747,3768.757812 1239.792603,3767.047607 1238.722290,3763.619385 
	C1237.667603,3760.240723 1239.609009,3757.025879 1243.441406,3756.305908 
	C1246.305664,3755.768066 1249.328125,3756.071533 1253.137695,3756.000000 
	C1257.599976,3752.118408 1251.367554,3744.873047 1259.345581,3742.131836 
	C1260.889038,3741.999512 1261.779297,3741.998779 1263.337158,3742.003906 
z"
        />
        <path
          fill="#EBE4D0"
          opacity="1.000000"
          stroke="none"
          d="
M1261.401367,3720.316895 
	C1266.015503,3720.613037 1267.791748,3718.168457 1269.481201,3714.454102 
	C1273.183472,3718.907227 1276.298584,3723.864502 1280.743896,3727.810791 
	C1282.206299,3729.109131 1283.352051,3730.677246 1283.787598,3732.700439 
	C1284.732910,3737.091309 1283.109375,3739.541016 1278.579468,3739.919922 
	C1275.665405,3740.163574 1272.716187,3739.988770 1268.898560,3740.005859 
	C1266.593872,3736.285400 1262.301392,3735.662598 1260.140869,3732.587158 
	C1256.767456,3727.783936 1253.230591,3727.791992 1261.401367,3720.316895 
z"
        />
        <path
          fill="#EAE6D7"
          opacity="1.000000"
          stroke="none"
          d="
M1259.511963,3741.524902 
	C1258.055786,3745.128662 1255.268188,3747.928223 1257.535767,3752.239990 
	C1258.329468,3753.749023 1256.990479,3755.156006 1254.653320,3755.877441 
	C1254.082275,3750.895264 1249.046509,3750.226562 1246.530151,3747.375977 
	C1245.654785,3746.384277 1244.712646,3745.386475 1245.114136,3743.899414 
	C1245.554810,3742.267822 1247.001465,3742.225586 1248.294312,3742.087891 
	C1251.867554,3741.707764 1255.447632,3741.391357 1259.511963,3741.524902 
z"
        />
        <path
          fill="#E5DDC9"
          opacity="1.000000"
          stroke="none"
          d="
M1271.377441,3804.940430 
	C1266.978638,3804.409912 1268.061646,3802.070801 1269.356812,3800.060791 
	C1270.390747,3798.456543 1271.701904,3796.624512 1274.017456,3797.001709 
	C1275.594482,3797.259033 1275.843994,3798.680908 1275.535645,3800.070557 
	C1275.077026,3802.137451 1273.544434,3803.423340 1271.377441,3804.940430 
z"
        />
        <path
          fill="#EAE6D7"
          opacity="1.000000"
          stroke="none"
          d="
M1189.910645,3735.110352 
	C1188.555054,3734.367676 1188.538696,3733.620850 1189.507324,3732.960693 
	C1189.709961,3732.822266 1190.179199,3732.827881 1190.376831,3732.971436 
	C1191.322266,3733.659424 1191.287109,3734.406006 1189.910645,3735.110352 
z"
        />
        <path
          fill="#EAE6D7"
          opacity="1.000000"
          stroke="none"
          d="
M1232.005371,3658.772461 
	C1237.860596,3658.562012 1241.075928,3663.409912 1244.220337,3667.060791 
	C1246.667603,3669.901855 1244.047974,3674.977051 1242.506348,3679.484131 
	C1240.151733,3682.173096 1237.599731,3681.741211 1234.596436,3681.932617 
	C1229.657593,3681.445068 1227.601440,3679.318115 1228.695068,3674.712402 
	C1229.886963,3669.692139 1230.885986,3664.626221 1232.005371,3658.772461 
z"
        />
        <path
          fill="#EBE4D0"
          opacity="1.000000"
          stroke="none"
          d="
M1234.311035,3682.612305 
	C1234.691528,3676.560059 1238.093994,3679.488281 1241.327148,3679.922363 
	C1242.170166,3683.881836 1244.506226,3687.563477 1247.231812,3688.827148 
	C1253.968262,3691.950439 1256.580933,3698.277832 1261.405762,3703.689453 
	C1261.470093,3707.386475 1259.646973,3708.643555 1256.551636,3706.954102 
	C1253.841553,3705.474609 1252.256592,3703.078613 1251.971191,3699.892334 
	C1251.622681,3696.001953 1249.375244,3694.118896 1244.764160,3694.018066 
	C1238.331177,3692.616211 1237.897583,3686.682373 1234.311035,3682.612305 
z"
        />
        <path
          fill="#EAE6D7"
          opacity="1.000000"
          stroke="none"
          d="
M1180.833496,3698.016113 
	C1180.368042,3695.377686 1181.882324,3694.196289 1184.497437,3694.036621 
	C1187.312500,3693.864746 1190.244141,3694.554443 1193.457642,3692.533447 
	C1204.819702,3687.571045 1204.822388,3687.570068 1209.167969,3699.177490 
	C1209.273926,3699.460693 1209.150879,3699.829834 1209.085938,3701.152588 
	C1200.141357,3697.279053 1190.550293,3702.158447 1180.833496,3698.016113 
z"
        />
        <path
          fill="#ECA777"
          opacity="1.000000"
          stroke="none"
          d="
M717.967407,3327.233398 
	C715.518677,3327.820068 713.191589,3326.962158 712.556152,3324.487061 
	C710.525818,3316.580078 704.327026,3314.038086 696.742554,3312.111328 
	C698.882141,3304.644287 704.992371,3308.488037 709.640625,3308.499023 
	C713.531067,3308.508301 716.079041,3311.285645 716.100403,3315.767334 
	C716.117615,3319.382568 718.479492,3322.649902 717.967407,3327.233398 
z"
        />
        <path
          fill="#ECA777"
          opacity="1.000000"
          stroke="none"
          d="
M668.535889,3325.501953 
	C664.760559,3321.019043 662.189392,3315.317139 654.709900,3313.955078 
	C657.594971,3307.118896 657.916870,3307.015381 662.771973,3310.824951 
	C667.388672,3314.446777 671.441223,3318.299316 668.535889,3325.501953 
z"
        />
        <path
          fill="#395166"
          opacity="1.000000"
          stroke="none"
          d="
M2199.076660,2702.956055 
	C2199.123047,2703.041260 2199.030273,2702.870605 2199.076660,2702.956055 
z"
        />
        <path
          fill="#395166"
          opacity="1.000000"
          stroke="none"
          d="
M2122.085205,2745.405273 
	C2120.748535,2745.502686 2119.575684,2744.860352 2118.095215,2743.818359 
	C2123.556152,2735.036133 2132.781738,2733.251221 2142.493164,2738.994141 
	C2135.667236,2741.452393 2127.408203,2737.893066 2122.085205,2745.405273 
z"
        />
        <path
          fill="#D7D5CF"
          opacity="1.000000"
          stroke="none"
          d="
M1986.331543,2696.434326 
	C1985.674561,2694.464600 1985.479736,2693.011230 1985.078369,2690.854980 
	C1984.902222,2689.709473 1984.932739,2689.267090 1985.441162,2688.361328 
	C1990.809814,2690.212646 1995.168579,2693.815430 2001.099121,2688.931885 
	C2004.326172,2686.274170 2008.323608,2687.914062 2011.329468,2691.748779 
	C2016.267578,2698.049072 2022.265015,2703.283936 2030.082642,2705.948242 
	C2031.644531,2706.480469 2033.395996,2707.868652 2034.699829,2707.518555 
	C2043.658569,2705.114990 2047.594116,2711.551270 2052.149902,2716.988770 
	C2052.775146,2717.734863 2053.250488,2719.134521 2053.932129,2719.227539 
	C2067.508057,2721.079346 2074.020264,2734.815918 2086.480957,2738.501709 
	C2088.139160,2738.992432 2089.149170,2740.360107 2089.783936,2741.999268 
	C2090.134521,2742.904297 2090.756104,2744.505615 2091.136963,2744.471680 
	C2094.348633,2744.183594 2097.628906,2741.535400 2100.041504,2746.596191 
	C2101.370361,2749.383789 2102.702148,2752.775146 2106.196777,2754.356201 
	C2109.199463,2753.606445 2107.134033,2750.138672 2109.493408,2748.393311 
	C2110.666016,2752.251221 2115.472412,2754.219238 2115.918457,2759.323975 
	C2124.170898,2767.117920 2126.895996,2776.456055 2126.502441,2787.452637 
	C2120.730713,2788.594238 2121.097412,2793.899414 2118.942627,2796.887451 
	C2115.684570,2801.405518 2112.466064,2803.182861 2107.461914,2801.074707 
	C2106.164062,2798.505859 2108.213623,2797.200928 2108.980957,2795.484131 
	C2109.875977,2793.481934 2110.053955,2791.483154 2107.595703,2790.550049 
	C2105.593506,2789.790039 2103.303711,2789.991211 2102.543213,2792.267822 
	C2100.929199,2797.100098 2097.715088,2800.975098 2095.323975,2805.333008 
	C2094.688721,2806.490967 2094.003662,2807.654053 2093.151123,2808.652100 
	C2092.178955,2809.790283 2090.852295,2809.868652 2089.601562,2809.072998 
	C2088.592041,2808.430908 2088.626465,2807.355713 2089.025879,2806.422607 
	C2089.677979,2804.899902 2090.264648,2803.259521 2091.287842,2802.000977 
	C2094.858643,2797.609131 2096.281494,2793.248535 2094.560547,2787.308350 
	C2093.558350,2783.849609 2095.528320,2779.575928 2095.915771,2775.645020 
	C2096.064941,2774.133301 2096.289551,2772.259521 2093.595215,2771.057861 
	C2088.822021,2777.949463 2089.399414,2787.491699 2082.481201,2792.656250 
	C2080.224121,2789.519775 2078.855225,2785.513184 2081.602783,2780.110840 
	C2083.031738,2777.301270 2083.277344,2772.537354 2080.118652,2769.828369 
	C2076.096924,2766.378418 2075.534180,2773.897705 2071.163086,2772.667969 
	C2072.484375,2768.252441 2068.858398,2761.784424 2077.563965,2760.748535 
	C2078.722656,2760.610840 2079.691895,2758.835205 2078.864014,2757.540527 
	C2077.443604,2755.319580 2075.110840,2758.257812 2073.179932,2756.696045 
	C2071.547852,2753.385986 2075.976562,2750.998535 2074.885010,2747.099121 
	C2071.545410,2746.025146 2069.718262,2749.133789 2067.556885,2750.599121 
	C2061.331299,2754.819824 2060.757324,2754.794922 2057.963379,2748.016602 
	C2057.593994,2747.120117 2057.216064,2746.227539 2056.848633,2745.348877 
	C2053.518311,2745.499512 2053.750488,2748.011475 2054.414062,2749.625977 
	C2056.545898,2754.814209 2055.725830,2758.607666 2050.392090,2761.083008 
	C2047.592163,2762.382324 2047.191284,2768.929443 2043.486816,2766.400146 
	C2039.886108,2763.941895 2044.744751,2760.788086 2045.995361,2758.036621 
	C2048.451660,2752.632080 2048.556885,2752.395264 2042.440552,2749.806152 
	C2040.179321,2748.849121 2038.113281,2747.670410 2037.074585,2745.598877 
	C2034.902344,2741.266113 2032.351685,2737.952881 2027.051025,2737.061523 
	C2024.489258,2736.630615 2022.835205,2734.017578 2022.185425,2731.204834 
	C2021.588501,2728.621826 2020.864014,2726.067871 2020.095337,2722.747070 
	C2020.458984,2721.549072 2020.907959,2721.090576 2021.341797,2720.617432 
	C2020.192993,2719.940674 2021.685913,2720.245361 2020.392822,2721.617188 
	C2018.696289,2721.889160 2017.278320,2722.054443 2016.096802,2721.602051 
	C2008.322266,2718.626221 2008.338989,2718.582764 2001.235840,2723.328857 
	C1999.142456,2714.985596 2000.120117,2705.361572 1990.719482,2700.132080 
	C1987.072144,2700.938721 1987.274902,2698.590088 1986.331543,2696.434326 
z"
        />
        <path
          fill="#7A91A4"
          opacity="1.000000"
          stroke="none"
          d="
M1986.183105,2687.267090 
	C1985.780762,2688.288818 1985.642090,2688.678955 1985.187500,2689.610596 
	C1983.723755,2690.650635 1982.414185,2690.940918 1981.457153,2691.685059 
	C1978.056763,2694.327881 1976.371582,2693.391357 1975.890015,2689.317383 
	C1975.732178,2687.981201 1975.001465,2686.986084 1973.111816,2686.269531 
	C1970.159546,2685.339600 1968.976562,2683.480713 1967.729614,2680.639160 
	C1965.805664,2670.842773 1961.978516,2662.145752 1963.660889,2651.883789 
	C1965.489136,2647.131592 1967.541260,2643.458496 1971.914917,2640.687500 
	C1973.388306,2640.368652 1974.101074,2640.267090 1975.353027,2640.134277 
	C1976.601929,2640.729248 1977.016357,2641.512451 1976.929199,2643.107422 
	C1977.028198,2644.823242 1977.334839,2645.882568 1977.823730,2647.470947 
	C1976.333252,2655.667480 1978.505859,2660.546631 1987.128296,2661.349365 
	C1989.765137,2661.595215 1991.209717,2664.397949 1993.643799,2666.446777 
	C1993.862427,2667.623779 1993.907959,2668.170410 1994.089233,2669.116455 
	C1993.711182,2670.434570 1992.794312,2671.496338 1993.008057,2672.227539 
	C1994.983154,2678.987793 1989.695557,2682.331299 1986.183105,2687.267090 
z"
        />
        <path
          fill="#D0D1C8"
          opacity="1.000000"
          stroke="none"
          d="
M2126.275391,2788.730957 
	C2124.068359,2778.716064 2119.638672,2770.273193 2116.291260,2760.683594 
	C2118.919922,2756.990723 2121.809814,2753.838623 2125.096924,2759.684570 
	C2125.570312,2760.526123 2126.333740,2761.670410 2127.113281,2761.804932 
	C2140.263184,2764.075439 2144.515625,2778.384033 2154.983887,2782.977295 
	C2163.796143,2786.843994 2165.193115,2793.531982 2167.594238,2800.612793 
	C2173.622314,2798.143311 2175.208740,2792.607178 2179.060547,2788.295898 
	C2180.308838,2788.490967 2180.895752,2788.843262 2181.715332,2789.659180 
	C2181.986084,2791.527832 2182.023926,2792.933105 2182.083008,2795.041260 
	C2181.764404,2803.564697 2187.721436,2808.947754 2190.120605,2815.783447 
	C2194.051758,2826.983398 2190.353271,2837.535889 2190.076904,2848.331299 
	C2189.931396,2854.022705 2189.452148,2859.555908 2191.550293,2865.015869 
	C2192.756104,2868.153564 2193.157471,2871.530029 2192.556152,2874.842529 
	C2191.126709,2882.718018 2189.642578,2890.403320 2193.776123,2898.146240 
	C2195.544434,2901.458984 2193.578613,2905.294434 2192.023926,2909.494141 
	C2191.082520,2911.172363 2190.377441,2912.020508 2188.895996,2913.297363 
	C2177.183594,2916.936035 2173.981689,2914.515625 2174.504395,2903.439697 
	C2174.598633,2901.442383 2175.037109,2899.475098 2175.014404,2896.632324 
	C2174.597168,2892.483887 2174.596924,2889.168457 2174.983887,2885.037109 
	C2176.898926,2873.570801 2174.704346,2862.992188 2174.206543,2851.637695 
	C2173.646240,2849.916260 2173.433350,2848.916260 2173.255859,2847.162598 
	C2173.007080,2838.264648 2173.802246,2830.136963 2166.151855,2823.883789 
	C2161.831299,2820.352051 2163.248779,2814.070312 2162.953857,2809.517578 
	C2161.159912,2817.008789 2164.697266,2825.848877 2159.074463,2834.058105 
	C2155.149414,2836.191650 2152.567139,2834.721680 2149.728027,2831.806152 
	C2148.773926,2830.271484 2148.452637,2829.303711 2148.253418,2827.530273 
	C2148.552734,2824.362549 2148.789307,2821.949219 2147.717773,2818.850830 
	C2147.149170,2814.991211 2147.018066,2811.913818 2149.520020,2808.589355 
	C2152.560791,2805.478760 2153.089355,2802.148193 2149.866455,2800.732422 
	C2146.290771,2799.161377 2143.912842,2795.730957 2139.670898,2794.560547 
	C2137.114258,2797.980469 2138.071777,2804.490967 2130.805176,2803.974609 
	C2128.896484,2799.131104 2127.740479,2794.272217 2126.275391,2788.730957 
z"
        />
        <path
          fill="#31475A"
          opacity="1.000000"
          stroke="none"
          d="
M883.982849,2778.957031 
	C882.609497,2769.603271 883.141357,2768.909668 890.521973,2771.274170 
	C895.922119,2773.004639 901.268433,2774.506836 906.999878,2774.060059 
	C907.650635,2774.009033 908.449158,2773.928711 908.955566,2774.233643 
	C920.796265,2781.366699 934.552124,2779.254639 947.244202,2782.235840 
	C948.510803,2782.533691 949.742981,2783.074219 950.913635,2783.657715 
	C952.115479,2784.256836 953.177917,2785.138672 952.909485,2786.691895 
	C952.665161,2788.106445 951.635132,2789.054443 950.331421,2789.380615 
	C945.449280,2790.602051 940.458069,2789.737061 935.520813,2789.993164 
	C932.295715,2790.160645 928.548523,2789.391357 928.041016,2795.121094 
	C929.243896,2796.830078 930.527161,2797.700684 930.132446,2800.136719 
	C925.741211,2803.544922 921.635132,2802.962891 917.753357,2800.760254 
	C914.449890,2798.885254 911.064026,2798.349121 906.658081,2798.879639 
	C900.922546,2799.292480 895.900330,2801.372803 890.372192,2798.187988 
	C887.782654,2795.699463 886.487488,2793.081299 884.105225,2790.474609 
	C883.883118,2786.475098 883.929260,2783.131592 883.982849,2778.957031 
z"
        />
        <path
          fill="#243748"
          opacity="1.000000"
          stroke="none"
          d="
M883.126709,2789.836914 
	C886.899353,2790.053467 889.993408,2790.247314 889.953552,2795.267578 
	C889.381165,2798.899902 892.431580,2801.703369 889.632446,2805.057129 
	C879.228638,2808.935059 870.516968,2801.781494 860.895569,2802.397217 
	C858.067322,2802.578125 855.051758,2801.992920 852.797485,2801.705078 
	C850.797058,2801.635010 851.833191,2803.814697 851.587891,2802.504150 
	C851.525940,2802.173584 851.823364,2802.067139 852.476501,2802.049561 
	C855.454163,2801.969238 858.488098,2801.640869 861.203674,2804.847168 
	C862.242371,2807.012451 862.403503,2808.581543 862.049561,2810.983887 
	C859.791321,2815.992432 856.578796,2815.694580 853.034363,2813.993652 
	C849.880249,2812.479736 846.308655,2811.998779 842.607178,2809.730469 
	C837.872559,2804.302002 831.079407,2801.055908 831.551025,2792.636719 
	C834.083679,2789.413086 836.444824,2790.334717 839.411438,2791.733398 
	C840.834534,2793.003418 841.839844,2793.416260 843.552002,2792.495361 
	C853.489502,2792.641602 862.850586,2791.380371 873.056519,2789.927734 
	C874.747131,2789.202148 875.706177,2788.919678 877.465942,2789.458008 
	C879.565430,2789.851318 880.990967,2789.853516 883.126709,2789.836914 
z"
        />
        <path
          fill="#374D60"
          opacity="1.000000"
          stroke="none"
          d="
M659.892578,2819.100586 
	C654.551575,2819.933838 649.201294,2819.991943 642.956726,2820.061523 
	C641.187744,2820.857666 640.379639,2821.740234 638.582825,2822.044434 
	C632.386719,2822.489258 626.695862,2821.186035 620.546814,2823.415283 
	C614.659302,2824.134277 609.374329,2823.613525 604.174072,2825.484131 
	C601.063721,2826.603271 597.631104,2826.229736 594.369080,2825.164795 
	C589.183411,2823.471680 583.762634,2823.155273 578.698486,2824.914551 
	C573.133606,2826.847656 571.536377,2823.754150 570.007324,2818.936523 
	C574.555908,2818.368896 578.984070,2817.645752 583.340027,2814.204346 
	C586.250183,2817.409668 589.641418,2817.014160 592.942505,2817.031738 
	C603.100403,2817.085205 613.140381,2816.287598 623.381531,2812.644531 
	C628.458984,2812.250000 632.895691,2811.641357 637.210510,2810.000244 
	C639.567444,2809.103516 641.999268,2807.727539 645.259521,2809.614258 
	C646.654419,2814.576904 650.330139,2813.911377 653.555237,2813.989258 
	C656.635986,2814.063477 659.697205,2814.068848 659.892578,2819.100586 
z"
        />
        <path
          fill="#1C2E3E"
          opacity="1.000000"
          stroke="none"
          d="
M742.984009,2773.850342 
	C741.652954,2767.925781 745.708069,2766.310059 750.211182,2766.173340 
	C760.064026,2765.874268 769.976013,2765.555908 779.779541,2766.322754 
	C785.895752,2766.801514 791.743286,2769.458496 795.873535,2775.470947 
	C778.595825,2775.651123 761.165649,2775.815918 742.984009,2773.850342 
z"
        />
        <path
          fill="#243748"
          opacity="1.000000"
          stroke="none"
          d="
M789.513550,2790.698242 
	C802.136597,2790.725098 814.064209,2792.929199 827.073975,2792.064453 
	C830.642944,2802.576660 820.965515,2801.312744 815.394348,2804.897461 
	C809.810059,2806.435303 806.913452,2801.871094 802.005859,2801.022461 
	C796.428772,2800.438232 791.321350,2802.450195 786.129150,2799.010010 
	C783.707397,2794.709717 785.997803,2792.797852 789.513550,2790.698242 
z"
        />
        <path
          fill="#2B3E51"
          opacity="1.000000"
          stroke="none"
          d="
M623.026489,2812.124512 
	C623.939697,2814.965332 623.609314,2817.715332 620.032043,2817.823975 
	C609.250732,2818.152100 598.671448,2821.067871 587.766541,2820.080322 
	C584.384460,2819.774414 582.792358,2819.010742 583.987793,2814.768066 
	C590.764771,2814.032471 597.464172,2813.976807 605.078735,2813.910156 
	C607.188171,2813.930420 608.267761,2813.608887 609.625122,2812.455078 
	C614.084717,2812.066895 618.125427,2812.077881 623.026489,2812.124512 
z"
        />
        <path
          fill="#233444"
          opacity="1.000000"
          stroke="none"
          d="
M814.435120,2804.654785 
	C815.242554,2801.384277 817.340027,2800.217773 820.156555,2799.944824 
	C824.124084,2799.560303 826.572815,2797.216797 827.903259,2792.809082 
	C828.890869,2792.072021 829.780090,2792.030762 831.337158,2792.001465 
	C832.016724,2795.207764 834.209900,2797.108643 836.166748,2799.197021 
	C838.303589,2801.477051 841.119141,2803.265381 841.918579,2807.322266 
	C832.560059,2811.124023 823.393738,2811.458252 814.435120,2804.654785 
z"
        />
        <path
          fill="#31475A"
          opacity="1.000000"
          stroke="none"
          d="
M789.286499,2790.177734 
	C790.033081,2793.141113 786.829956,2794.181396 786.082275,2797.411621 
	C786.086975,2799.289795 785.698120,2800.333008 784.389526,2801.597900 
	C778.832153,2801.918701 773.423462,2802.982178 768.582458,2798.289307 
	C767.834900,2793.881836 769.616760,2791.648682 773.851013,2792.042236 
	C778.875610,2792.509277 783.607361,2790.943604 789.286499,2790.177734 
z"
        />
        <path
          fill="#2F4457"
          opacity="1.000000"
          stroke="none"
          d="
M854.479736,2778.515137 
	C856.867188,2775.462646 859.656921,2772.309326 863.694580,2777.399902 
	C861.282043,2780.836182 858.169678,2780.351318 854.479736,2778.515137 
z"
        />
        <path
          fill="#233444"
          opacity="1.000000"
          stroke="none"
          d="
M843.326172,2792.043945 
	C843.727905,2793.817871 844.587769,2796.359375 842.138855,2796.751221 
	C839.955872,2797.100586 840.324707,2794.771729 840.015747,2792.672363 
	C840.887390,2791.999268 841.774780,2791.998779 843.326172,2792.043945 
z"
        />
        <path
          fill="#374D60"
          opacity="1.000000"
          stroke="none"
          d="
M609.454102,2812.079590 
	C609.622559,2813.833008 609.559387,2816.051758 606.333618,2814.343750 
	C606.950623,2813.300293 607.907349,2812.701660 609.454102,2812.079590 
z"
        />
        <path
          fill="#31475A"
          opacity="1.000000"
          stroke="none"
          d="
M878.008667,2789.290527 
	C877.201355,2789.893311 876.262756,2789.937500 874.619629,2789.948486 
	C874.565857,2787.278320 875.388611,2785.216553 878.008667,2789.290527 
z"
        />
        <path
          fill="#4C6174"
          opacity="1.000000"
          stroke="none"
          d="
M990.938965,2688.001465 
	C986.654236,2683.950928 980.978149,2687.894043 976.612671,2683.843750 
	C981.468140,2681.204590 987.551453,2682.035645 990.741577,2676.680176 
	C991.786560,2674.926270 996.259888,2675.655029 999.043457,2677.229492 
	C1006.496094,2681.444824 1014.488342,2681.262451 1023.380005,2679.952393 
	C1047.116577,2674.692139 1070.358521,2674.540527 1093.325317,2678.447998 
	C1106.181152,2680.635254 1118.843262,2682.383789 1131.881714,2682.436279 
	C1152.821289,2682.520752 1173.807251,2681.143066 1194.694824,2684.014404 
	C1199.999512,2684.743652 1205.348877,2684.104980 1210.443481,2682.251465 
	C1222.671509,2677.802002 1235.170776,2677.563965 1247.863647,2679.597168 
	C1259.071899,2681.392334 1270.503906,2679.157959 1282.583008,2681.652832 
	C1286.087891,2684.899414 1293.178589,2684.678711 1291.542480,2690.268799 
	C1289.936157,2695.757080 1284.068848,2694.932373 1279.511597,2694.315918 
	C1264.561401,2692.294922 1249.755005,2694.314453 1234.902100,2695.414062 
	C1231.278076,2695.682373 1227.611816,2695.612061 1223.167969,2694.991699 
	C1216.663940,2694.649170 1210.528564,2690.219727 1204.912842,2697.085449 
	C1202.403687,2698.347900 1200.423340,2698.708740 1197.644287,2698.889160 
	C1192.788452,2698.182373 1190.620483,2701.335693 1187.190918,2703.747559 
	C1177.584473,2707.365967 1169.115112,2701.912842 1159.532471,2702.791992 
	C1144.666626,2697.490967 1130.462769,2697.369629 1116.084229,2701.378174 
	C1112.260132,2702.444336 1108.165894,2702.635498 1104.305176,2702.285645 
	C1096.359375,2701.565918 1088.725952,2702.361328 1080.548828,2704.569336 
	C1071.748535,2704.287109 1063.375366,2702.369873 1055.895752,2707.494873 
	C1054.939453,2708.150391 1053.319824,2708.041504 1052.025146,2707.969238 
	C1033.242920,2706.924072 1014.664856,2711.488770 994.935730,2709.989746 
	C993.037415,2708.684326 991.318726,2707.400391 991.284241,2706.072754 
	C991.230530,2704.004883 993.471497,2704.180664 995.079651,2704.057129 
	C998.921387,2703.761475 1002.922791,2704.734619 1006.798279,2702.982910 
	C1006.323242,2698.581543 1000.644287,2700.387939 1000.087036,2696.188232 
	C1005.749268,2696.213379 1011.051331,2698.527344 1016.737549,2698.158203 
	C1022.387573,2697.791504 1028.223267,2700.790039 1033.648804,2696.363770 
	C1034.732666,2695.479736 1037.509155,2696.361572 1039.404785,2696.832764 
	C1049.911133,2699.444824 1060.065674,2697.942871 1070.916992,2694.883545 
	C1065.302979,2692.120850 1014.481689,2687.909424 990.938965,2688.001465 
z"
        />
        <path
          fill="#3A4F62"
          opacity="1.000000"
          stroke="none"
          d="
M1079.871338,2704.932617 
	C1086.124390,2698.339844 1092.887085,2697.850342 1101.434570,2699.146484 
	C1106.622192,2699.932861 1113.333374,2698.451904 1119.351685,2697.284668 
	C1131.545654,2694.919678 1144.657471,2692.132080 1157.310181,2699.865234 
	C1160.445312,2702.497314 1163.575928,2701.918213 1166.523315,2702.010254 
	C1169.644775,2702.107422 1173.010132,2702.446533 1173.939331,2705.818604 
	C1175.024536,2709.757080 1171.431396,2710.955078 1168.664429,2711.668701 
	C1165.750854,2712.420166 1163.983643,2713.954590 1162.372803,2717.130371 
	C1161.893433,2717.718750 1161.896851,2717.963623 1161.774414,2717.963623 
	C1158.334595,2717.975098 1155.017090,2717.986572 1150.871582,2717.994141 
	C1147.921021,2715.606689 1145.366333,2716.185059 1142.661255,2716.689209 
	C1134.203125,2718.265137 1126.369507,2717.703857 1117.611084,2715.275391 
	C1108.944336,2712.872803 1099.020020,2710.256348 1089.217651,2713.137695 
	C1083.491333,2714.820801 1077.479736,2714.377686 1071.104492,2713.216064 
	C1072.163452,2707.933350 1080.570068,2712.010986 1079.871338,2704.932617 
z"
        />
        <path
          fill="#465869"
          opacity="1.000000"
          stroke="none"
          d="
M930.392944,2800.614258 
	C929.472290,2799.002930 928.949646,2797.995850 928.210571,2796.462402 
	C946.147705,2796.194824 964.186829,2793.536621 982.359009,2793.923828 
	C992.646118,2794.143066 1002.703918,2797.104004 1013.083130,2796.074707 
	C1016.705017,2795.715576 1020.145325,2795.722168 1023.596985,2793.809082 
	C1030.036499,2790.239502 1037.293579,2792.643555 1045.080078,2792.028809 
	C1042.710938,2804.237061 1040.998047,2805.720459 1029.952148,2805.980469 
	C1027.669678,2806.034180 1025.331177,2805.702637 1022.612488,2807.420898 
	C1016.653748,2807.920166 1011.278564,2807.877197 1005.011169,2807.805420 
	C1003.212097,2807.073975 1002.312988,2806.358154 1000.600586,2805.993164 
	C980.964539,2799.753418 961.276917,2802.708740 940.901733,2801.961914 
	C937.928162,2804.039551 935.603271,2803.409912 932.607788,2802.384521 
	C931.562256,2801.798828 931.176636,2801.508789 930.392944,2800.614258 
z"
        />
        <path
          fill="#D0D1C8"
          opacity="1.000000"
          stroke="none"
          d="
M1157.153809,2731.801270 
	C1157.348267,2738.973389 1155.843994,2746.003662 1153.237793,2752.743652 
	C1152.433350,2754.824463 1150.926758,2757.045654 1148.287598,2756.928467 
	C1145.690308,2756.813232 1146.244019,2753.950439 1145.217407,2752.425293 
	C1141.366455,2746.704590 1141.599609,2740.687744 1143.908203,2733.653809 
	C1147.844849,2728.963623 1152.166138,2730.585449 1157.153809,2731.801270 
z"
        />
        <path
          fill="#3A4F62"
          opacity="1.000000"
          stroke="none"
          d="
M878.581177,2698.484619 
	C883.579651,2696.554688 887.260925,2689.975098 894.099609,2692.099365 
	C895.032043,2692.388916 895.968750,2692.664551 897.452942,2693.476562 
	C896.404053,2698.127686 893.839050,2700.673096 888.972229,2700.030762 
	C885.713806,2699.600586 882.306580,2700.578857 878.581177,2698.484619 
z"
        />
        <path
          fill="#374D60"
          opacity="1.000000"
          stroke="none"
          d="
M918.361877,2698.312500 
	C919.887573,2695.927002 921.106018,2696.522705 921.975220,2699.347900 
	C920.934448,2699.541504 919.812134,2699.181885 918.361877,2698.312500 
z"
        />
        <path
          fill="#2B3E51"
          opacity="1.000000"
          stroke="none"
          d="
M890.609802,2674.607422 
	C883.960632,2670.532471 877.569275,2672.126709 870.616699,2673.200928 
	C865.028809,2675.416260 860.212036,2673.959473 854.679688,2672.550049 
	C853.120422,2672.015381 852.252991,2671.972656 850.734131,2671.928223 
	C846.551453,2669.070557 842.738647,2669.615967 838.304016,2671.362793 
	C827.287292,2668.736572 816.554016,2670.391357 805.020142,2669.900146 
	C794.696899,2666.710693 785.298767,2663.513916 774.684204,2667.947510 
	C771.020752,2668.022949 768.031555,2668.064941 765.046265,2667.976562 
	C749.786865,2667.525635 734.720642,2668.566895 719.630432,2671.383545 
	C711.273376,2672.943848 702.428589,2671.872070 693.804382,2672.028564 
	C688.883972,2672.117920 684.148254,2671.904297 679.439209,2674.730713 
	C674.810425,2677.508545 669.017883,2675.549805 662.873840,2676.160156 
	C648.395874,2664.481934 631.620056,2668.637451 616.202271,2667.872803 
	C596.063904,2666.874023 575.875977,2667.146973 555.874146,2663.985840 
	C551.363953,2663.272949 546.281311,2663.721191 545.225830,2656.750488 
	C547.050476,2653.997559 549.270020,2653.291504 551.612854,2653.020752 
	C583.380371,2649.344482 615.132019,2647.935303 646.719543,2654.516846 
	C652.756531,2655.774658 658.668640,2654.308105 664.614075,2654.717529 
	C669.360840,2655.043945 675.188843,2653.363525 677.765625,2659.107666 
	C678.766052,2661.337891 678.937988,2660.042969 679.752625,2659.530762 
	C697.640747,2648.291748 717.683411,2646.665527 738.032410,2646.622559 
	C756.024353,2646.584473 773.887268,2644.651123 791.789062,2643.281494 
	C806.593506,2642.148438 821.002991,2645.353516 835.323975,2647.971191 
	C849.507507,2650.563721 863.350037,2653.235352 877.697205,2649.430664 
	C882.842834,2648.065918 888.558289,2647.600098 893.551880,2649.225586 
	C910.779297,2654.833740 927.876465,2650.659912 945.007935,2649.357422 
	C950.684082,2648.926025 956.317444,2648.381592 961.885315,2649.770752 
	C985.804199,2655.737793 1009.817871,2656.841553 1034.024414,2651.591309 
	C1038.684448,2650.580322 1043.294434,2651.809814 1048.676025,2652.829102 
	C1051.563843,2653.373779 1053.585938,2654.136230 1056.557129,2654.104248 
	C1058.446777,2654.322998 1059.485596,2654.640625 1061.167480,2655.568115 
	C1064.461914,2659.231689 1068.342041,2658.807617 1071.819702,2659.595703 
	C1074.108032,2660.114258 1076.505981,2660.999756 1076.591675,2663.815186 
	C1076.695557,2667.222900 1073.968262,2668.174316 1071.299927,2668.519775 
	C1065.397827,2669.283447 1059.439453,2668.856445 1053.505981,2668.972656 
	C1049.551147,2669.050049 1045.589111,2668.878174 1040.878906,2669.881348 
	C1028.465576,2669.887695 1017.068787,2666.914062 1005.421570,2667.901123 
	C1001.471497,2668.236084 997.666443,2668.066406 994.025635,2670.458496 
	C989.604431,2673.363525 984.085571,2672.788086 979.496948,2671.633789 
	C970.264954,2669.311523 961.037964,2670.374756 950.881836,2670.038818 
	C946.296875,2674.970459 940.841064,2675.146973 935.545044,2675.370850 
	C926.914673,2675.736084 918.272156,2675.599609 908.760742,2675.355957 
	C905.050110,2674.876465 902.231689,2674.772949 898.658813,2676.002930 
	C895.528259,2676.538574 893.304810,2676.320068 890.609802,2674.607422 
z"
        />
        <path
          fill="#3C5163"
          opacity="1.000000"
          stroke="none"
          d="
M545.645813,2655.729492 
	C548.368896,2661.780518 553.400696,2661.702148 557.811157,2661.925537 
	C577.383362,2662.916748 596.932983,2664.130615 616.562134,2664.383545 
	C627.252991,2664.521240 638.107788,2663.641602 648.910461,2665.668701 
	C655.137939,2666.837158 659.541870,2668.784180 661.900513,2675.459717 
	C660.117737,2678.275635 657.620361,2679.952881 655.052979,2679.750977 
	C644.525757,2678.922119 634.939331,2684.257812 624.554810,2684.032715 
	C621.779663,2683.972656 619.382568,2685.249268 617.014160,2686.444824 
	C613.825867,2688.053711 610.678406,2688.344238 606.759644,2686.412598 
	C597.338379,2680.828125 588.137939,2686.732910 578.617310,2684.501953 
	C577.364624,2679.819580 574.201599,2680.029541 571.159790,2680.029785 
	C565.284424,2680.030029 559.409058,2680.029785 553.533691,2680.029785 
	C557.565674,2673.653809 563.917358,2677.855957 568.963867,2674.507812 
	C568.413818,2671.548828 565.820068,2671.001221 562.742249,2670.127441 
	C561.513489,2666.623779 559.112366,2665.953857 556.200989,2666.043701 
	C549.901917,2666.237793 543.471191,2664.953125 537.337646,2666.666992 
	C533.310120,2667.792236 531.858459,2665.953125 530.323120,2662.297119 
	C532.260559,2652.976318 538.552673,2653.761719 545.645813,2655.729492 
z"
        />
        <path
          fill="#31475A"
          opacity="1.000000"
          stroke="none"
          d="
M577.178223,2683.995117 
	C585.132935,2682.455811 592.333374,2681.549316 599.665833,2682.011475 
	C601.977356,2682.157227 604.411926,2681.897705 605.859802,2685.328613 
	C596.353394,2686.364746 586.661804,2686.700928 577.237976,2689.707275 
	C574.825439,2690.477051 572.015625,2690.001465 568.530579,2690.093262 
	C567.647644,2683.881348 571.902344,2683.788574 577.178223,2683.995117 
z"
        />
        <path
          fill="#43596D"
          opacity="1.000000"
          stroke="none"
          d="
M774.609802,2668.581055 
	C777.802185,2662.244873 783.325378,2660.790039 789.439453,2662.969482 
	C794.229614,2664.677246 799.790283,2664.565186 803.906128,2669.210205 
	C802.568359,2669.965332 801.042236,2670.016602 798.753906,2670.077637 
	C797.105957,2670.048828 796.219727,2670.026855 794.668274,2670.023926 
	C787.748718,2669.533936 781.403870,2670.905029 774.609802,2668.581055 
z"
        />
        <path
          fill="#2B3E51"
          opacity="1.000000"
          stroke="none"
          d="
M585.018860,2698.273682 
	C585.840698,2699.151611 585.910278,2699.965088 585.995728,2701.394287 
	C578.067017,2701.311523 569.738525,2703.792480 560.786011,2699.017578 
	C569.517090,2696.814941 576.969788,2699.347900 585.018860,2698.273682 
z"
        />
        <path
          fill="#4E6477"
          opacity="1.000000"
          stroke="none"
          d="
M854.045044,2672.638428 
	C858.875366,2671.797607 863.736206,2671.792969 869.199219,2673.623047 
	C866.494629,2677.717285 861.789062,2675.284424 856.834473,2675.999023 
	C855.363647,2675.072998 854.732971,2674.145996 854.045044,2672.638428 
z"
        />
        <path
          fill="#43596D"
          opacity="1.000000"
          stroke="none"
          d="
M838.633545,2671.825195 
	C841.818054,2666.987549 844.032532,2666.761475 849.887512,2671.218018 
	C846.560364,2671.924561 843.038025,2671.923096 838.633545,2671.825195 
z"
        />
        <path
          fill="#4E6477"
          opacity="1.000000"
          stroke="none"
          d="
M794.306580,2670.447266 
	C794.882263,2668.699707 795.929138,2668.065918 797.698242,2669.647949 
	C797.096497,2671.356201 796.044495,2671.942627 794.306580,2670.447266 
z"
        />
        <path
          fill="#263B4D"
          opacity="1.000000"
          stroke="none"
          d="
M1284.888794,2684.058350 
	C1267.152954,2684.478760 1250.277222,2683.792236 1233.427490,2682.045898 
	C1227.073853,2681.387207 1220.868286,2682.255859 1214.883789,2684.679932 
	C1205.685791,2688.406250 1196.284302,2689.034668 1186.594116,2686.628418 
	C1183.750000,2685.922363 1180.662598,2686.057617 1177.685791,2686.047607 
	C1160.409790,2685.988770 1143.128540,2685.749023 1125.859131,2686.086182 
	C1115.478271,2686.288818 1105.385620,2685.493896 1095.466553,2682.264160 
	C1089.127197,2680.200195 1082.536133,2679.742920 1075.834229,2679.826172 
	C1059.225098,2680.032715 1042.612061,2679.910889 1025.081543,2679.912354 
	C1024.330566,2677.181396 1025.939087,2676.131836 1028.499634,2676.009521 
	C1030.671875,2675.906250 1032.925415,2676.229492 1035.488037,2674.554199 
	C1045.840820,2668.233154 1056.773560,2668.089844 1067.645386,2668.063965 
	C1074.637451,2668.047363 1079.983398,2665.992432 1082.878418,2658.278320 
	C1087.668457,2654.194336 1092.347046,2655.622070 1097.741455,2656.878418 
	C1100.376709,2657.570557 1102.265625,2658.131592 1105.003906,2657.784424 
	C1112.124023,2657.905518 1118.439087,2659.409180 1125.603516,2658.697510 
	C1128.462891,2658.775879 1130.049072,2657.482422 1132.500488,2656.275391 
	C1136.872070,2654.823975 1140.540527,2654.804688 1144.954102,2656.125488 
	C1154.593750,2664.052002 1164.303833,2661.408936 1174.739990,2658.374512 
	C1176.930054,2657.767822 1178.429077,2657.689941 1180.673706,2657.799805 
	C1186.310059,2659.673584 1190.706909,2659.424316 1194.714844,2654.402344 
	C1203.195312,2651.535156 1210.692383,2653.638184 1218.956909,2655.765137 
	C1222.608154,2659.840820 1231.782959,2658.256348 1230.145996,2664.582764 
	C1228.437866,2671.183838 1220.808228,2666.621338 1217.093384,2667.896973 
	C1228.440674,2670.391357 1239.958252,2664.156494 1252.321533,2664.330811 
	C1265.978882,2664.523682 1279.588013,2663.048340 1292.473022,2669.229492 
	C1295.970825,2670.907471 1299.668091,2669.824951 1303.229980,2668.799561 
	C1307.746460,2667.499512 1312.277954,2666.312500 1317.526855,2668.961182 
	C1320.345825,2673.441895 1317.946289,2676.017334 1314.898682,2677.475830 
	C1305.708252,2681.874512 1296.605591,2687.095703 1284.888794,2684.058350 
z"
        />
        <path
          fill="#597288"
          opacity="1.000000"
          stroke="none"
          d="
M905.780518,2672.911865 
	C914.172302,2672.441406 922.012512,2672.074219 929.852234,2672.087158 
	C936.086914,2672.097656 942.230225,2671.693604 949.113770,2670.114746 
	C950.235901,2671.580811 953.850281,2671.894531 951.070557,2674.588135 
	C947.060669,2678.473633 942.331482,2680.218506 936.487061,2681.413086 
	C935.505310,2682.577637 934.895081,2683.048828 933.546387,2683.405273 
	C931.414062,2683.489746 929.867371,2683.311523 927.733032,2683.499023 
	C926.353516,2683.483643 925.608826,2683.264160 924.473755,2682.496826 
	C919.734070,2682.094482 915.462891,2681.756348 911.206360,2683.204102 
	C903.528503,2685.815430 896.137390,2682.004883 888.655762,2681.248779 
	C884.696594,2680.848633 888.420044,2677.284424 889.685913,2674.622559 
	C892.134277,2674.031738 894.267944,2674.048828 897.201782,2674.076172 
	C900.420715,2673.862793 902.930847,2674.366211 905.780518,2672.911865 
z"
        />
        <path
          fill="#374D60"
          opacity="1.000000"
          stroke="none"
          d="
M1084.122437,2657.100586 
	C1084.298828,2660.759766 1084.610474,2663.643799 1084.994629,2667.197998 
	C1069.129395,2669.487061 1053.420532,2671.753418 1036.869873,2674.033203 
	C1037.153687,2672.978516 1038.279175,2671.910645 1039.722656,2670.341309 
	C1042.093994,2665.780762 1045.855835,2665.995117 1049.559204,2666.004883 
	C1055.887695,2666.021484 1062.218018,2666.082031 1068.543823,2665.950684 
	C1070.633667,2665.907227 1073.230835,2666.818604 1074.807007,2663.568359 
	C1071.925049,2661.089844 1068.519775,2662.199951 1065.456909,2662.012939 
	C1062.441650,2661.828613 1060.236084,2661.002441 1060.091064,2656.720215 
	C1060.730103,2655.252197 1061.513428,2654.802002 1063.102783,2654.498535 
	C1067.439087,2655.309082 1070.904907,2655.105469 1074.675415,2652.418945 
	C1079.279053,2651.524902 1082.621948,2652.099854 1084.122437,2657.100586 
z"
        />
        <path
          fill="#374D60"
          opacity="1.000000"
          stroke="none"
          d="
M931.343628,2681.961670 
	C932.888794,2681.931641 933.767273,2681.923096 935.303711,2681.911133 
	C937.585327,2684.068115 939.957825,2684.003662 942.285217,2683.990234 
	C944.651367,2683.976562 946.884644,2684.402344 948.991333,2685.534180 
	C950.746643,2686.477051 952.970276,2687.302979 952.951843,2689.521973 
	C952.927551,2692.437744 950.159729,2693.236572 947.997742,2693.505127 
	C937.062439,2694.861084 926.215515,2694.063721 915.532593,2691.348145 
	C914.217651,2691.013916 913.199829,2689.982178 913.122192,2688.545166 
	C913.031189,2686.861328 914.396667,2686.460205 915.715576,2686.165039 
	C918.456604,2685.551025 921.897949,2687.041992 923.853455,2682.804199 
	C924.923035,2682.080322 925.804626,2682.063477 927.347961,2682.052246 
	C928.898743,2682.032227 929.787842,2682.007324 931.343628,2681.961670 
z"
        />
        <path
          fill="#3F5467"
          opacity="1.000000"
          stroke="none"
          d="
M508.563812,2674.510986 
	C513.406006,2670.622803 519.143433,2671.908691 525.445068,2673.309082 
	C520.643250,2677.523438 514.867981,2675.985596 508.563812,2674.510986 
z"
        />
        <path
          fill="#3C5163"
          opacity="1.000000"
          stroke="none"
          d="
M950.905151,2778.003906 
	C948.472168,2777.878418 946.244568,2778.233154 945.735718,2776.585205 
	C945.034851,2774.315430 947.282959,2774.097412 948.829956,2774.123535 
	C954.744995,2774.223145 960.810303,2773.094238 966.545593,2775.896729 
	C961.847717,2779.011963 956.674744,2777.614746 950.905151,2778.003906 
z"
        />
        <path
          fill="#4A667C"
          opacity="1.000000"
          stroke="none"
          d="
M1816.805786,2508.115234 
	C1815.893677,2505.969971 1815.897217,2503.789062 1815.965088,2500.794678 
	C1821.202026,2499.808350 1826.292358,2499.825439 1831.504883,2498.735840 
	C1837.048096,2497.577393 1842.843872,2498.948730 1847.824463,2503.346680 
	C1838.138550,2507.291504 1827.995117,2508.178467 1816.805786,2508.115234 
z"
        />
        <path
          fill="#49647C"
          opacity="1.000000"
          stroke="none"
          d="
M2073.939453,2537.182617 
	C2071.358643,2537.716064 2068.168457,2538.982910 2066.118164,2534.938477 
	C2068.731201,2534.284180 2071.902100,2533.050293 2073.939453,2537.182617 
z"
        />
        <path
          fill="#281C18"
          opacity="1.000000"
          stroke="none"
          d="
M1817.898193,2535.687744 
	C1817.065186,2537.273926 1816.101562,2537.841309 1815.061157,2536.775146 
	C1814.393677,2536.091309 1814.696899,2535.218262 1815.752930,2534.229004 
	C1816.189453,2533.934570 1817.015503,2533.921875 1817.427002,2533.916748 
	C1817.873169,2534.306152 1817.907837,2534.700684 1817.898193,2535.687744 
z"
        />
        <path
          fill="#49647C"
          opacity="1.000000"
          stroke="none"
          d="
M2053.648438,2535.531006 
	C2053.323242,2537.034180 2052.354980,2537.687012 2050.451416,2537.845703 
	C2050.345215,2536.125977 2050.501709,2533.959473 2053.648438,2535.531006 
z"
        />
        <path
          fill="#281C18"
          opacity="1.000000"
          stroke="none"
          d="
M1785.548584,2522.416748 
	C1785.885620,2526.961426 1791.771118,2524.782227 1792.869507,2528.009521 
	C1791.338379,2531.621826 1787.005615,2528.563965 1784.880493,2532.182617 
	C1788.854004,2534.869873 1789.710938,2538.736084 1788.268799,2543.614990 
	C1787.153198,2547.388428 1789.112915,2549.834229 1793.342163,2550.290039 
	C1795.604736,2550.533936 1797.787720,2551.449951 1800.030518,2551.956787 
	C1806.629272,2553.447754 1809.210938,2558.648682 1807.057739,2565.197754 
	C1805.632202,2569.533936 1804.914917,2574.102783 1803.814819,2579.377441 
	C1803.005493,2580.882812 1802.205566,2581.505859 1800.652222,2582.361572 
	C1796.608643,2583.874756 1793.196777,2584.777100 1788.867310,2584.892334 
	C1784.695557,2585.092041 1781.308594,2586.025635 1777.164185,2585.192383 
	C1773.499878,2582.951660 1776.043823,2580.132324 1775.572876,2578.201904 
	C1764.545044,2577.293213 1755.573120,2583.867920 1744.662598,2585.815918 
	C1742.330811,2585.630615 1740.752319,2585.197021 1739.132080,2584.961426 
	C1734.864014,2584.340820 1727.821533,2586.061768 1727.804077,2580.366211 
	C1727.786255,2574.547852 1733.636719,2569.557373 1739.668701,2569.432617 
	C1744.215820,2569.338135 1744.230347,2567.337646 1745.010986,2563.922852 
	C1745.278931,2560.452393 1745.616089,2557.722656 1748.456787,2555.434326 
	C1750.012573,2554.623535 1751.174805,2556.357910 1751.942749,2555.120117 
	C1752.628906,2554.013916 1752.175659,2555.597656 1751.135254,2554.085449 
	C1752.551880,2550.891357 1756.093872,2550.641846 1756.400513,2545.982910 
	C1756.651367,2542.174072 1761.738892,2538.480957 1767.703125,2538.413574 
	C1768.830688,2538.932861 1769.270508,2539.327393 1769.693481,2539.744873 
	C1770.279663,2540.323975 1770.232300,2540.227051 1769.187744,2539.026123 
	C1766.770752,2534.388672 1770.252563,2529.782715 1767.355225,2525.254883 
	C1764.567627,2523.587402 1762.171631,2523.361328 1759.004150,2524.329590 
	C1753.529663,2524.196533 1745.291626,2531.002441 1746.662720,2518.036621 
	C1749.787231,2514.539551 1753.436035,2513.508057 1757.696045,2511.916992 
	C1763.270630,2511.314697 1767.867676,2508.300781 1773.488525,2511.092285 
	C1776.151489,2513.939453 1779.137451,2516.025146 1774.885986,2519.161865 
	C1773.889771,2519.896729 1774.720703,2521.568115 1775.800659,2523.204590 
	C1777.249756,2525.358887 1778.645996,2526.311523 1781.263672,2525.030762 
	C1782.864380,2523.961426 1783.907959,2523.279297 1785.548584,2522.416748 
z"
        />
        <path
          fill="#E59F70"
          opacity="1.000000"
          stroke="none"
          d="
M1758.628662,2513.559082 
	C1755.221924,2515.347168 1752.385376,2516.608398 1748.899414,2518.060059 
	C1744.034424,2521.398438 1739.587402,2523.648682 1733.352539,2521.512207 
	C1732.221069,2520.701904 1731.845581,2520.278809 1731.462402,2519.007324 
	C1730.618774,2508.274658 1733.524536,2499.462646 1738.272461,2490.842041 
	C1747.242920,2474.554199 1751.425171,2456.465088 1757.262573,2438.252441 
	C1761.711792,2435.616211 1763.792358,2438.577637 1766.210815,2440.829590 
	C1777.344116,2451.194824 1781.876465,2465.316650 1786.970093,2478.853516 
	C1790.764893,2488.938232 1787.187744,2496.094238 1777.578735,2501.122070 
	C1771.047241,2504.540527 1766.086548,2510.253906 1758.628662,2513.559082 
z"
        />
        <path
          fill="#BA7C55"
          opacity="1.000000"
          stroke="none"
          d="
M1758.868896,2514.167480 
	C1761.469971,2506.827393 1768.382324,2503.326904 1774.485229,2499.037842 
	C1777.574097,2496.867188 1782.458862,2497.443359 1783.856689,2492.609131 
	C1785.259644,2487.757080 1785.868286,2483.076416 1784.087280,2478.078613 
	C1781.360107,2470.426270 1774.498901,2464.430664 1775.054932,2455.488281 
	C1768.405762,2450.908936 1765.766479,2442.649658 1758.406494,2437.877441 
	C1757.891724,2434.979492 1758.013306,2432.034180 1760.964966,2430.981201 
	C1764.162476,2429.841064 1765.698975,2432.611084 1767.202393,2434.681885 
	C1773.393188,2443.207764 1779.327271,2451.924072 1785.652954,2460.347168 
	C1790.381348,2466.643555 1792.206055,2473.942139 1793.891235,2481.339600 
	C1796.463135,2492.629395 1791.473022,2501.033691 1783.543335,2508.391113 
	C1781.027466,2510.725342 1777.771118,2509.342773 1774.627808,2511.171631 
	C1768.976685,2510.737793 1764.551514,2513.726807 1758.868896,2514.167480 
z"
        />
        <path
          fill="#281C18"
          opacity="1.000000"
          stroke="none"
          d="
M1730.867676,2517.801025 
	C1731.681519,2518.436523 1731.908325,2518.714355 1732.454834,2519.425293 
	C1732.498779,2520.827637 1732.013794,2521.677246 1730.706421,2522.827881 
	C1728.697021,2523.834229 1727.248169,2523.955078 1725.067627,2523.544922 
	C1722.624634,2522.556641 1721.291626,2521.216309 1719.408447,2519.470459 
	C1716.522461,2516.246094 1713.988403,2513.575439 1713.838623,2508.932617 
	C1713.864990,2507.799805 1713.901123,2507.347168 1714.017334,2506.220703 
	C1714.193481,2504.631592 1714.256836,2503.714355 1714.255859,2502.078613 
	C1713.744751,2486.214355 1719.468750,2472.750732 1725.671387,2459.432861 
	C1726.963013,2456.659424 1728.862183,2454.359375 1732.725830,2453.433594 
	C1735.896851,2454.820801 1736.277710,2457.314209 1735.349487,2459.366211 
	C1732.646729,2465.340820 1735.403198,2470.046387 1738.411377,2475.520264 
	C1738.376709,2480.196289 1736.127441,2483.133301 1732.993164,2486.343750 
	C1727.421631,2487.642822 1726.474121,2492.313721 1723.983154,2496.231934 
	C1723.688721,2497.079834 1723.570557,2497.402100 1723.315430,2498.177490 
	C1723.178345,2498.630615 1723.172607,2499.495361 1722.856445,2499.748535 
	C1722.540283,2500.001709 1722.710815,2500.776123 1723.350464,2500.405762 
	C1724.293701,2500.234375 1724.597412,2500.433105 1725.410278,2500.823730 
	C1726.609009,2501.548584 1726.941040,2502.250244 1726.555420,2503.625488 
	C1727.764282,2508.503906 1727.131592,2513.553955 1730.867676,2517.801025 
z"
        />
        <path
          fill="#1D1712"
          opacity="1.000000"
          stroke="none"
          d="
M1753.982422,2419.248047 
	C1754.348511,2424.106689 1750.526611,2426.748779 1749.935913,2431.275879 
	C1749.075073,2433.449707 1748.108276,2434.572754 1745.664062,2434.035645 
	C1742.347290,2430.285156 1743.904053,2425.810547 1741.940186,2422.117920 
	C1738.032349,2422.213867 1737.061523,2426.123291 1734.453003,2427.834229 
	C1730.086182,2430.698486 1725.735229,2431.139648 1721.506958,2428.045166 
	C1716.759155,2424.570801 1717.942871,2419.942627 1719.815918,2415.471680 
	C1721.917725,2410.454346 1726.100464,2407.966797 1731.153198,2406.670166 
	C1735.973999,2405.432861 1740.805664,2404.261230 1746.577393,2404.230469 
	C1748.861084,2404.622559 1750.232178,2405.287598 1752.067139,2406.698975 
	C1753.913208,2408.874023 1754.389160,2410.814697 1754.670898,2413.571777 
	C1754.819214,2415.758057 1754.650879,2417.180908 1753.982422,2419.248047 
z"
        />
        <path
          fill="#2C2520"
          opacity="1.000000"
          stroke="none"
          d="
M1745.357910,2434.219727 
	C1747.001953,2433.522705 1747.925537,2432.992920 1749.321533,2432.221191 
	C1750.284668,2447.336426 1746.328613,2461.651611 1739.113647,2475.758789 
	C1729.092529,2469.381592 1729.092529,2469.381592 1734.006592,2454.800781 
	C1732.938843,2444.996582 1739.380127,2440.011230 1745.357910,2434.219727 
z"
        />
        <path
          fill="#020914"
          opacity="1.000000"
          stroke="none"
          d="
M1776.069580,2425.271484 
	C1773.027954,2423.852051 1769.965088,2421.595947 1765.142578,2421.871826 
	C1763.368164,2420.546875 1762.297607,2419.292725 1760.756226,2417.638184 
	C1757.320312,2415.762695 1755.695068,2412.958740 1753.380859,2409.898682 
	C1752.994507,2408.246582 1752.939209,2407.253418 1753.240234,2405.532471 
	C1754.202148,2403.920654 1755.012085,2403.215332 1756.780396,2402.444580 
	C1763.220093,2401.883301 1768.953735,2399.237793 1775.006592,2403.582520 
	C1775.615112,2405.065918 1775.623657,2405.918945 1775.705200,2407.407715 
	C1773.905518,2409.781006 1773.739990,2411.956543 1774.522339,2414.155518 
	C1775.714111,2417.504883 1775.826294,2420.973389 1776.069580,2425.271484 
z"
        />
        <path
          fill="#2C2520"
          opacity="1.000000"
          stroke="none"
          d="
M1722.455322,2500.362793 
	C1721.748779,2494.768066 1724.558228,2490.756592 1726.922485,2486.638672 
	C1727.942993,2484.861816 1729.658203,2483.939209 1732.071289,2486.221680 
	C1730.876587,2491.546875 1726.258423,2494.218262 1724.156616,2499.380371 
	C1723.990112,2500.035645 1723.999878,2499.988770 1724.023071,2499.997314 
	C1723.680054,2500.287598 1723.313721,2500.569336 1722.455322,2500.362793 
z"
        />
        <path
          fill="#2C2520"
          opacity="1.000000"
          stroke="none"
          d="
M1753.945068,2410.205322 
	C1758.070923,2410.457275 1759.743652,2412.645752 1760.216309,2416.613037 
	C1758.600830,2418.104736 1756.916382,2418.972168 1754.518066,2419.887451 
	C1753.207275,2419.010010 1752.610352,2418.084961 1752.005859,2416.582764 
	C1753.557007,2414.438721 1752.741211,2412.316406 1753.945068,2410.205322 
z"
        />
        <path
          fill="#0C0D0F"
          opacity="1.000000"
          stroke="none"
          d="
M1781.107910,2524.508789 
	C1781.643921,2526.420166 1782.474365,2528.969482 1779.618042,2529.069824 
	C1777.343872,2529.149902 1776.585083,2527.101074 1776.132080,2524.439941 
	C1777.523438,2523.961914 1778.959229,2524.167969 1781.107910,2524.508789 
z"
        />
        <path
          fill="#2C2520"
          opacity="1.000000"
          stroke="none"
          d="
M1726.761353,2504.014404 
	C1726.121826,2503.432373 1726.049072,2502.734619 1725.947998,2501.526367 
	C1727.187866,2500.404053 1728.505615,2500.129639 1728.968384,2501.850098 
	C1729.254395,2502.913818 1728.306641,2503.550537 1726.761353,2504.014404 
z"
        />
        <path
          fill="#3D494C"
          opacity="1.000000"
          stroke="none"
          d="
M1776.001953,2403.720703 
	C1769.583862,2403.504150 1763.558472,2402.633057 1756.767334,2404.946777 
	C1755.212646,2404.371094 1754.965210,2403.246826 1755.761353,2401.304199 
	C1762.845581,2398.753906 1769.358643,2396.343018 1776.874756,2399.769531 
	C1777.227417,2400.900391 1776.812256,2402.064209 1776.001953,2403.720703 
z"
        />
        <path
          fill="#2C2520"
          opacity="1.000000"
          stroke="none"
          d="
M1755.758179,2400.025635 
	C1756.167358,2401.645264 1756.025146,2402.695557 1755.919800,2404.565918 
	C1755.956665,2405.385986 1755.486206,2405.916260 1755.295898,2405.936768 
	C1754.240112,2406.308105 1753.532104,2406.861084 1752.323364,2407.823730 
	C1750.615356,2407.620117 1749.563965,2407.206787 1748.167480,2406.332031 
	C1742.124268,2401.482178 1735.620239,2398.210938 1730.145874,2392.599854 
	C1734.538208,2388.901123 1740.148682,2389.086426 1745.524414,2386.538086 
	C1748.787964,2385.974121 1751.590454,2385.973389 1755.232422,2386.041016 
	C1755.185059,2390.519531 1752.268188,2394.798096 1755.758179,2400.025635 
z"
        />
        <path
          fill="#566C80"
          opacity="1.000000"
          stroke="none"
          d="
M794.891846,2607.961670 
	C794.492737,2604.354980 796.856506,2603.684082 799.785339,2604.373047 
	C802.662231,2605.049561 805.412781,2606.316895 809.284668,2605.904297 
	C811.616150,2604.530518 813.318848,2603.560547 816.094299,2603.215332 
	C819.387146,2603.309570 821.540894,2602.293945 824.239563,2600.547363 
	C826.999634,2599.734619 828.985962,2599.854248 831.432373,2601.458984 
	C837.581116,2602.631104 843.151367,2603.380615 848.743408,2603.893066 
	C851.734558,2604.166992 853.798279,2602.896484 853.935852,2598.749023 
	C854.293640,2597.960693 854.629272,2597.960205 855.468323,2597.960449 
	C865.547791,2595.802490 875.327454,2594.804199 885.515625,2592.520996 
	C896.172546,2593.904785 906.003235,2597.362305 916.527039,2596.067871 
	C920.788513,2595.543701 925.092590,2595.911377 929.366882,2594.683838 
	C934.199402,2593.295410 939.516052,2593.172119 944.233643,2594.324951 
	C954.484497,2596.830322 964.798645,2596.239258 975.030212,2595.821045 
	C987.827087,2595.298096 1000.234070,2597.379395 1012.497070,2600.136230 
	C1020.943542,2602.035156 1028.845215,2601.431152 1037.444092,2598.551025 
	C1050.010864,2598.070312 1062.019653,2598.107422 1074.957275,2598.175293 
	C1077.670288,2600.033691 1078.193970,2602.202881 1077.309570,2605.460449 
	C1075.067627,2608.795654 1072.373779,2610.386963 1069.356445,2610.422119 
	C1054.836792,2610.591309 1040.366699,2614.082520 1025.783813,2610.805420 
	C1015.242920,2608.437256 1004.685974,2608.710205 994.327759,2612.041748 
	C989.491699,2613.596924 984.645447,2614.137939 979.658020,2612.930176 
	C975.937317,2612.029541 972.379211,2611.786377 969.450439,2616.288086 
	C965.952026,2618.727783 962.726196,2618.909912 958.651733,2618.607910 
	C955.496765,2618.237061 953.196045,2616.954834 950.002563,2618.085938 
	C946.464172,2618.804688 943.579529,2618.883789 939.993042,2619.184082 
	C937.214111,2619.362549 935.671631,2620.691650 933.197998,2621.841309 
	C928.242554,2623.418945 923.946960,2623.219727 918.850220,2622.565186 
	C915.566040,2621.499756 912.990112,2621.030518 910.337219,2620.702393 
	C906.650085,2620.246094 902.092407,2619.149170 902.383667,2614.927002 
	C902.642029,2611.183350 907.274780,2611.148193 910.566528,2611.084229 
	C914.876343,2611.000488 919.177551,2610.775635 923.480713,2610.589844 
	C926.795715,2610.446777 930.108276,2610.161621 933.203003,2608.396973 
	C932.056213,2606.577881 930.083313,2607.198975 928.923462,2607.547119 
	C907.498962,2613.977539 885.415100,2606.568359 863.815063,2610.708252 
	C858.748840,2611.679443 853.471191,2612.801758 847.373535,2612.899414 
	C843.372314,2613.572998 840.015686,2612.869873 836.044312,2614.223633 
	C831.262085,2613.986328 827.197937,2614.500488 822.677612,2612.491211 
	C818.980408,2610.307129 815.719604,2609.874023 812.361450,2610.102295 
	C806.670288,2610.489746 801.171753,2609.460449 794.891846,2607.961670 
z"
        />
        <path
          fill="#465F75"
          opacity="1.000000"
          stroke="none"
          d="
M703.906250,2587.100586 
	C703.059631,2587.934326 702.182922,2587.956299 700.647400,2587.971191 
	C694.452087,2583.396484 689.376160,2589.140869 684.012817,2588.742188 
	C683.688232,2588.718018 682.909607,2589.761719 682.980530,2590.207031 
	C684.199707,2597.863281 678.502258,2595.652832 674.713257,2595.997314 
	C662.196655,2597.136475 649.709595,2598.773926 637.082397,2598.128662 
	C635.508972,2598.048096 633.578552,2598.369873 632.351135,2599.252930 
	C627.214661,2602.947510 621.712708,2602.520752 616.163818,2600.985596 
	C613.217773,2600.170654 610.279480,2600.123047 607.305664,2599.964111 
	C605.309753,2599.857666 602.948364,2599.820557 602.711182,2597.250732 
	C602.467468,2594.609619 604.733582,2594.386230 606.747375,2594.012695 
	C612.597778,2592.927002 618.398010,2591.273926 624.425171,2591.983643 
	C637.536865,2593.527832 649.981262,2590.488770 662.111450,2586.169189 
	C673.627930,2582.067627 685.409302,2581.548340 697.389038,2582.010254 
	C700.473572,2582.129395 703.572754,2582.038330 703.906250,2587.100586 
z"
        />
        <path
          fill="#465F75"
          opacity="1.000000"
          stroke="none"
          d="
M885.401367,2591.815918 
	C885.762268,2594.609863 884.548828,2597.756348 882.345581,2597.289062 
	C875.456055,2595.826416 869.498169,2599.146240 863.091553,2599.917725 
	C861.083984,2600.159424 859.151855,2599.598389 856.671997,2598.283203 
	C856.156372,2595.715332 856.040771,2593.592773 858.727661,2592.264404 
	C867.329651,2588.012451 875.881287,2586.276123 885.401367,2591.815918 
z"
        />
        <path
          fill="#50677C"
          opacity="1.000000"
          stroke="none"
          d="
M1048.107178,2556.684570 
	C1040.745728,2559.664795 1033.222534,2557.362549 1024.921631,2558.065918 
	C1023.178650,2558.020508 1022.291199,2557.960449 1020.737549,2557.874756 
	C1018.845032,2556.552490 1018.539673,2555.038330 1019.495239,2552.685547 
	C1027.208252,2549.411621 1034.722412,2550.084229 1042.184204,2549.999756 
	C1046.328613,2549.952881 1048.157227,2551.886475 1048.107178,2556.684570 
z"
        />
        <path
          fill="#465F75"
          opacity="1.000000"
          stroke="none"
          d="
M972.818909,2561.924805 
	C973.609070,2555.862549 978.670044,2558.774902 983.157349,2557.967773 
	C990.187256,2554.065430 996.159485,2548.925781 1005.075073,2554.997559 
	C1003.469849,2562.330566 997.381836,2561.215576 992.584839,2561.795898 
	C986.392639,2562.545166 980.040649,2561.973877 972.818909,2561.924805 
z"
        />
        <path
          fill="#465F75"
          opacity="1.000000"
          stroke="none"
          d="
M1019.437866,2551.490723 
	C1019.998108,2553.486084 1020.012817,2554.942871 1020.049438,2557.124512 
	C1015.905579,2557.329590 1011.962280,2555.309814 1006.812073,2556.006104 
	C1006.007324,2556.009766 1005.982056,2555.979492 1005.969666,2555.965332 
	C1004.318665,2553.944580 1004.984009,2552.214111 1007.300781,2550.293701 
	C1011.636597,2550.268311 1015.380249,2549.299805 1019.437866,2551.490723 
z"
        />
        <path
          fill="#495E72"
          opacity="1.000000"
          stroke="none"
          d="
M1075.625488,2606.675293 
	C1075.998413,2603.965088 1075.979736,2601.865479 1075.923584,2598.985840 
	C1079.466064,2598.053223 1083.086426,2597.480469 1087.170288,2599.544922 
	C1093.881958,2602.210449 1100.315674,2601.730713 1106.167725,2600.471436 
	C1113.192505,2598.960205 1119.670288,2599.122070 1126.334839,2601.433350 
	C1124.209595,2600.590332 1121.078125,2601.209473 1121.531006,2596.873047 
	C1127.679443,2597.649658 1133.518311,2598.360107 1139.485229,2598.058838 
	C1142.574463,2597.902832 1145.589844,2598.149170 1146.031494,2603.089355 
	C1145.032593,2607.263916 1142.255615,2608.184570 1137.035278,2609.136230 
	C1161.786499,2607.806641 1184.158813,2610.493896 1206.560547,2612.622803 
	C1215.172241,2613.441162 1223.766479,2613.459961 1232.392090,2612.828125 
	C1236.024048,2612.562256 1239.720215,2612.712891 1243.480713,2615.408691 
	C1241.873901,2622.122803 1236.365234,2621.771973 1231.627319,2621.852295 
	C1225.271729,2621.959717 1219.072021,2622.607422 1212.875366,2623.935547 
	C1210.373047,2624.471924 1208.337524,2624.997314 1208.285645,2628.050781 
	C1208.244141,2630.493408 1209.792725,2631.471191 1211.820068,2632.200928 
	C1215.295898,2633.452881 1218.773193,2634.989746 1222.271851,2632.479492 
	C1227.719849,2628.570801 1233.943848,2629.542725 1239.646240,2630.567383 
	C1253.909424,2633.130615 1268.217285,2632.547607 1282.376465,2631.226807 
	C1293.078979,2630.228271 1302.117065,2635.146729 1312.031006,2636.657715 
	C1318.101196,2647.151367 1327.721924,2640.535889 1335.424316,2640.759766 
	C1348.494263,2641.139160 1361.457886,2638.405273 1374.826172,2639.623047 
	C1390.347290,2641.037354 1406.095947,2639.930664 1421.745483,2639.981201 
	C1425.369873,2639.993164 1429.106812,2639.390869 1433.245605,2641.734863 
	C1436.410156,2644.631104 1436.178833,2647.312744 1433.841064,2650.670410 
	C1430.233521,2653.221924 1426.589355,2652.458984 1422.616943,2653.819824 
	C1414.850098,2653.965088 1407.861938,2652.550049 1400.142700,2654.611816 
	C1394.887939,2654.956299 1390.459229,2655.675781 1385.180542,2654.983398 
	C1380.342041,2655.398438 1376.412231,2654.696777 1372.453369,2654.163574 
	C1350.206055,2651.169678 1328.271851,2653.093262 1305.862549,2658.344727 
	C1304.034790,2658.227295 1304.105347,2655.984863 1302.624023,2656.840576 
	C1302.221802,2657.073242 1302.355225,2657.698730 1302.585693,2658.974609 
	C1301.310303,2661.045898 1299.667969,2662.125000 1298.157471,2661.645508 
	C1285.677002,2657.683105 1273.506714,2661.488770 1261.232910,2662.825928 
	C1258.263672,2663.149170 1255.346558,2662.717041 1251.964844,2660.891602 
	C1248.659180,2656.470215 1242.993652,2655.711426 1241.107666,2650.098633 
	C1242.254028,2645.713379 1245.205200,2644.570068 1249.251465,2643.816650 
	C1263.332886,2645.383789 1276.899414,2644.166260 1288.570923,2646.911621 
	C1289.454590,2647.054443 1288.710938,2647.114258 1288.164185,2646.814453 
	C1284.926758,2645.039062 1278.067017,2647.787598 1278.485962,2642.700684 
	C1278.880127,2637.919189 1285.290649,2641.138428 1289.339478,2639.373291 
	C1276.098755,2641.383301 1263.421875,2645.726807 1249.768921,2640.898926 
	C1247.037720,2640.745117 1244.854858,2639.713379 1243.106201,2640.626221 
	C1229.216675,2647.878662 1216.106079,2643.331787 1203.137817,2638.015381 
	C1199.676392,2636.596436 1196.308350,2635.277344 1192.601685,2635.552246 
	C1166.184937,2637.510742 1139.501221,2634.795166 1113.272827,2640.071289 
	C1111.959106,2640.335205 1110.630859,2639.955322 1108.636963,2639.160400 
	C1106.742798,2637.537109 1106.276978,2636.038330 1106.355103,2633.619385 
	C1112.605347,2624.053955 1122.185181,2625.740723 1130.856323,2625.165039 
	C1136.720459,2624.776123 1142.689819,2626.322021 1148.541992,2623.964844 
	C1138.716797,2624.471924 1128.890625,2625.114502 1118.690552,2621.732666 
	C1116.734619,2620.647705 1115.778076,2619.548096 1114.734131,2617.591309 
	C1114.060913,2613.041748 1112.004883,2610.915039 1108.012207,2611.139893 
	C1100.514282,2611.562500 1092.972046,2611.808838 1085.393311,2615.422363 
	C1079.458862,2619.603027 1072.865112,2619.420898 1066.890137,2621.217041 
	C1063.593872,2622.208008 1059.948730,2620.490234 1059.295288,2616.579590 
	C1058.622314,2612.551758 1061.425659,2610.655029 1065.173096,2610.132324 
	C1068.659668,2609.646484 1072.635498,2610.907959 1075.625488,2606.675293 
z"
        />
        <path
          fill="#AB7453"
          opacity="1.000000"
          stroke="none"
          d="
M1432.154785,2650.435059 
	C1434.532227,2648.191162 1434.178345,2645.806885 1434.079102,2642.801270 
	C1435.285034,2634.211670 1433.443970,2626.763672 1430.911865,2618.669434 
	C1431.416504,2616.947510 1431.754395,2615.995117 1432.038696,2614.532227 
	C1434.188110,2614.650879 1436.391113,2615.280029 1439.278931,2616.007812 
	C1441.653076,2617.345703 1444.281494,2620.587402 1445.172363,2616.742920 
	C1446.969971,2608.986816 1449.594971,2601.732910 1446.910400,2592.718506 
	C1443.563354,2581.479980 1441.759399,2569.437988 1440.620483,2557.545410 
	C1440.363770,2554.865234 1442.027710,2552.463135 1445.528687,2551.670898 
	C1451.945801,2551.515625 1455.742554,2555.901611 1461.248169,2557.930664 
	C1463.033447,2559.102051 1463.356445,2561.141113 1462.320801,2561.459717 
	C1451.876099,2564.673828 1457.221924,2571.579834 1460.112671,2575.563232 
	C1465.131836,2582.479492 1466.540405,2590.004639 1467.288330,2597.904053 
	C1468.675781,2612.556641 1472.599976,2627.129150 1468.031494,2641.983398 
	C1466.086914,2648.306641 1463.923340,2654.019043 1457.173828,2656.421143 
	C1453.124634,2657.862305 1449.169800,2660.076904 1449.787109,2664.635986 
	C1451.406860,2676.600098 1441.748779,2686.259277 1443.864624,2698.550049 
	C1444.819214,2704.095947 1439.156738,2708.200684 1433.472778,2708.796631 
	C1427.114380,2709.463379 1427.810547,2703.578369 1426.852051,2699.630615 
	C1426.135132,2696.677979 1425.499146,2693.723633 1422.924194,2691.788818 
	C1420.133911,2689.692383 1417.234253,2690.316406 1414.541504,2691.995361 
	C1411.691772,2693.771973 1412.170410,2696.447021 1412.990356,2699.111816 
	C1415.363037,2706.822510 1420.752563,2713.064453 1423.552490,2721.354980 
	C1423.601562,2723.135742 1423.324463,2724.108643 1422.252197,2725.563477 
	C1419.530273,2726.597656 1417.539429,2725.883789 1416.329834,2724.319336 
	C1404.024292,2708.405029 1394.705933,2691.279053 1395.294556,2670.336426 
	C1395.359619,2668.023682 1396.100708,2665.807129 1397.301270,2662.979248 
	C1399.243774,2659.733398 1401.013062,2657.077881 1404.537231,2657.747070 
	C1412.106812,2659.184814 1418.580078,2655.328857 1426.080566,2653.359619 
	C1428.194580,2652.036377 1430.093018,2651.872314 1432.154785,2650.435059 
z"
        />
        <path
          fill="#556A7C"
          opacity="1.000000"
          stroke="none"
          d="
M1244.903564,2615.988037 
	C1236.740723,2616.367188 1229.477661,2616.531250 1222.235840,2617.036133 
	C1215.229004,2617.524658 1207.711548,2616.789307 1200.593750,2615.748047 
	C1178.904419,2612.575195 1157.145264,2611.631348 1135.282959,2611.985352 
	C1134.618896,2611.996094 1133.948486,2612.044434 1133.291626,2611.974854 
	C1130.807617,2611.712158 1127.054932,2612.806396 1126.837158,2609.197754 
	C1126.606079,2605.369385 1130.391357,2606.217285 1132.867798,2606.035156 
	C1136.801514,2605.746094 1140.872314,2606.648682 1145.341797,2604.247559 
	C1152.978882,2604.041992 1159.585449,2606.857666 1166.590210,2605.982422 
	C1169.118774,2605.666504 1171.970337,2606.507568 1173.895630,2602.908203 
	C1176.256592,2602.162598 1178.352417,2602.152100 1181.238037,2602.156250 
	C1192.707886,2605.732178 1203.633423,2603.737305 1214.440186,2603.309570 
	C1217.356567,2603.194092 1220.088745,2602.596680 1223.154541,2603.188721 
	C1230.045654,2604.518311 1236.697632,2603.818359 1243.653320,2601.575928 
	C1252.561157,2598.703369 1268.908447,2595.528564 1281.593872,2599.922119 
	C1274.154419,2604.172119 1266.182495,2600.152588 1259.527466,2603.085205 
	C1260.000732,2606.469971 1262.378174,2605.930420 1264.117188,2605.886719 
	C1269.482544,2605.751465 1274.665405,2606.583496 1279.884155,2607.775879 
	C1286.970093,2609.395020 1294.093262,2611.174561 1301.400513,2608.234131 
	C1303.100342,2607.549805 1305.390259,2607.937012 1307.323364,2608.293457 
	C1318.102295,2610.281738 1328.506226,2611.578857 1339.297241,2606.727051 
	C1343.986938,2604.618408 1350.603394,2605.492188 1356.034058,2606.572998 
	C1374.089722,2610.167480 1392.104004,2611.661133 1410.328491,2608.299072 
	C1414.199829,2607.585205 1416.901611,2609.213623 1418.324463,2612.657471 
	C1419.607544,2615.763428 1425.713623,2615.759766 1424.166626,2619.635498 
	C1422.545288,2623.697510 1417.676514,2621.705078 1414.206909,2621.838135 
	C1408.585693,2622.053955 1403.046753,2621.575928 1397.405762,2620.636719 
	C1391.919800,2619.723389 1386.359741,2617.872070 1380.148071,2621.122070 
	C1375.145874,2623.739502 1368.233643,2622.978027 1362.550781,2621.356689 
	C1358.698730,2620.257812 1362.643555,2618.696533 1363.579346,2617.469482 
	C1357.224609,2614.011719 1357.224609,2614.011719 1348.395508,2614.464355 
	C1346.257690,2616.799561 1350.513916,2617.368164 1349.336060,2620.404053 
	C1329.800781,2617.843018 1309.798950,2619.496094 1288.972412,2618.105713 
	C1284.403198,2619.664795 1280.985718,2614.190918 1276.621826,2617.945312 
	C1265.900024,2618.110596 1256.012939,2614.929199 1244.903564,2615.988037 
z"
        />
        <path
          fill="#495E72"
          opacity="1.000000"
          stroke="none"
          d="
M1276.495361,2618.666016 
	C1277.610229,2611.711182 1278.235840,2611.199707 1282.775391,2613.559326 
	C1284.381836,2614.394287 1285.590332,2615.994873 1287.532959,2617.678955 
	C1287.628174,2620.138916 1286.882446,2621.752197 1284.283569,2621.038818 
	C1281.857666,2620.372559 1279.413330,2619.773438 1276.495361,2618.666016 
z"
        />
        <path
          fill="#50677C"
          opacity="1.000000"
          stroke="none"
          d="
M1007.433228,2549.451660 
	C1007.372253,2551.557617 1006.741272,2553.125000 1006.033813,2555.321777 
	C999.296204,2554.584961 992.581848,2552.785889 986.978149,2558.771240 
	C986.748657,2559.016602 985.762146,2558.553467 984.549072,2558.191406 
	C988.923950,2548.173828 997.025269,2546.346924 1007.433228,2549.451660 
z"
        />
        <path
          fill="#465F75"
          opacity="1.000000"
          stroke="none"
          d="
M849.996704,2540.001221 
	C851.808899,2540.026855 852.825256,2539.904297 853.786621,2540.081299 
	C855.782471,2540.448486 859.204468,2539.215576 858.681091,2542.980225 
	C858.294678,2545.760010 855.398071,2546.003174 853.069214,2545.853271 
	C848.456055,2545.556641 844.158630,2544.147705 838.964539,2541.018311 
	C843.051331,2539.417725 846.193665,2540.197266 849.996704,2540.001221 
z"
        />
        <path
          fill="#50677C"
          opacity="1.000000"
          stroke="none"
          d="
M1276.262207,2513.107910 
	C1270.541016,2513.292236 1265.085571,2511.802246 1259.475708,2513.783447 
	C1257.710449,2514.406982 1255.422119,2514.227539 1253.546021,2513.744385 
	C1244.017456,2511.289062 1234.614014,2511.345947 1225.015991,2513.656250 
	C1220.663696,2514.704102 1215.913818,2514.486084 1212.810425,2509.873291 
	C1216.172974,2506.777588 1218.811401,2509.970459 1221.611206,2509.924072 
	C1224.795654,2509.871338 1227.784302,2509.876953 1227.946777,2504.798340 
	C1241.112305,2504.710449 1254.258301,2503.121826 1267.493896,2505.478760 
	C1273.950928,2506.628662 1281.066772,2503.419434 1287.921631,2503.155518 
	C1297.547729,2502.784668 1307.241577,2503.111084 1316.729492,2506.727295 
	C1314.407349,2510.924072 1311.121338,2509.906494 1308.329956,2509.953613 
	C1300.391846,2510.087158 1292.448730,2509.923096 1284.510498,2510.055420 
	C1281.694824,2510.102295 1278.411621,2509.026123 1276.262207,2513.107910 
z"
        />
        <path
          fill="#50677C"
          opacity="1.000000"
          stroke="none"
          d="
M1221.563843,2503.524414 
	C1219.767578,2506.328369 1217.764282,2506.560547 1216.000732,2502.744629 
	C1217.662354,2502.059082 1219.502808,2501.862549 1221.563843,2503.524414 
z"
        />
        <path
          fill="#50677C"
          opacity="1.000000"
          stroke="none"
          d="
M1323.068359,2506.957520 
	C1323.112915,2507.040527 1323.023804,2506.874268 1323.068359,2506.957520 
z"
        />
        <path
          fill="#50677C"
          opacity="1.000000"
          stroke="none"
          d="
M1332.071411,2520.860840 
	C1339.778442,2519.734131 1347.411621,2519.335205 1355.617432,2519.422852 
	C1355.265015,2524.356689 1351.792236,2525.700684 1348.020752,2525.806152 
	C1342.339355,2525.965088 1336.877441,2526.108398 1331.828491,2529.436279 
	C1330.119751,2530.562500 1327.342407,2530.524658 1325.006470,2528.276123 
	C1326.100342,2524.860352 1332.651733,2526.604980 1332.071411,2520.860840 
z"
        />
        <path
          fill="#50677C"
          opacity="1.000000"
          stroke="none"
          d="
M1362.037476,2520.820312 
	C1365.506836,2520.687012 1368.969604,2521.343994 1373.193604,2522.020020 
	C1373.592407,2526.735596 1370.657349,2528.064209 1366.450562,2527.881836 
	C1362.195312,2527.697266 1362.028320,2524.739014 1362.037476,2520.820312 
z"
        />
        <path
          fill="#566C80"
          opacity="1.000000"
          stroke="none"
          d="
M1121.039795,2596.170898 
	C1122.419556,2600.925781 1126.965576,2599.180420 1129.454102,2601.457520 
	C1118.193115,2600.827148 1107.308960,2603.873779 1096.160278,2603.967285 
	C1093.354736,2603.990967 1090.088989,2604.860352 1088.092041,2600.644531 
	C1098.449707,2597.064209 1109.431152,2597.627930 1121.039795,2596.170898 
z"
        />
        <path
          fill="#395166"
          opacity="1.000000"
          stroke="none"
          d="
M1224.467041,2528.831787 
	C1224.359009,2529.188721 1223.973755,2529.480225 1223.864502,2529.399170 
	C1223.197998,2528.905273 1223.376709,2528.599365 1224.467041,2528.831787 
z"
        />
        <path
          fill="#C88655"
          opacity="1.000000"
          stroke="none"
          d="
M1422.732910,2722.606689 
	C1416.980835,2714.826416 1412.734131,2706.953125 1409.318115,2698.617676 
	C1407.988647,2695.373047 1407.019531,2692.326660 1410.659424,2689.781738 
	C1415.596924,2686.329834 1422.385132,2685.566406 1425.338257,2688.489502 
	C1428.280518,2691.401611 1429.826294,2694.988770 1430.043457,2699.198730 
	C1430.174561,2701.740967 1429.217285,2704.551270 1431.416992,2706.731934 
	C1442.508179,2702.360840 1442.685669,2702.165283 1440.606812,2691.171631 
	C1440.088623,2688.431152 1439.840576,2685.908203 1441.605591,2683.563721 
	C1445.740967,2678.070801 1447.733276,2672.427002 1446.274536,2665.124023 
	C1445.069946,2659.093506 1448.762451,2655.473877 1454.875488,2653.979248 
	C1461.171265,2652.439941 1466.064331,2645.874268 1465.403076,2639.461914 
	C1465.204956,2637.539795 1463.189331,2634.942871 1464.564575,2633.805176 
	C1471.361450,2628.182373 1466.379272,2622.047119 1466.092773,2616.009033 
	C1465.630981,2606.276611 1462.825073,2596.764404 1463.580811,2586.926758 
	C1463.669067,2585.777588 1462.957275,2584.032959 1462.043701,2583.425293 
	C1454.669922,2578.519531 1455.459717,2569.458252 1451.399170,2562.895264 
	C1450.750488,2561.846924 1450.198120,2560.450439 1451.322510,2559.265137 
	C1454.834595,2556.928467 1458.258057,2564.447021 1461.845093,2558.643799 
	C1469.573486,2556.079346 1477.195923,2554.137695 1485.610352,2552.156494 
	C1488.918701,2556.665771 1483.178955,2557.769775 1482.516968,2561.048828 
	C1485.511963,2563.305908 1489.160522,2564.096680 1492.604248,2566.666992 
	C1499.374023,2573.382812 1503.669800,2580.129639 1497.753662,2588.941162 
	C1496.455566,2590.874512 1497.348633,2592.473633 1497.584229,2594.424316 
	C1499.276978,2608.435547 1505.178101,2621.845947 1505.589111,2635.737305 
	C1505.989014,2649.255127 1512.196411,2655.588135 1524.368164,2659.477539 
	C1526.869629,2662.349854 1527.732300,2665.099854 1527.191162,2668.890137 
	C1524.709106,2672.899170 1521.162598,2670.917236 1517.688110,2670.971680 
	C1520.032227,2676.536621 1525.494751,2681.267578 1518.328125,2686.907227 
	C1517.632935,2687.782227 1517.318115,2688.096680 1516.443481,2688.751465 
	C1515.255737,2689.229004 1514.661011,2689.447266 1514.098511,2689.745605 
	C1513.524902,2690.049805 1513.752197,2690.080811 1515.436279,2689.846680 
	C1519.885376,2689.078857 1523.334229,2690.413330 1526.701172,2691.795166 
	C1529.132812,2692.792969 1532.615479,2694.247314 1531.853271,2697.023682 
	C1530.787842,2700.905029 1527.058960,2697.995850 1524.850952,2699.131348 
	C1531.382080,2698.119141 1538.116577,2698.359375 1543.660156,2704.546875 
	C1546.988159,2710.084717 1542.580322,2710.883301 1538.994263,2712.933105 
	C1542.892944,2715.047852 1545.188843,2718.798340 1550.325806,2719.542969 
	C1551.441406,2719.850098 1551.882324,2719.991943 1552.970459,2720.385254 
	C1560.411133,2724.750244 1568.768677,2724.667969 1575.849854,2728.863525 
	C1573.847046,2724.990723 1572.689453,2721.279785 1578.287842,2718.706055 
	C1580.390747,2718.645020 1581.707153,2719.065918 1583.606201,2719.867676 
	C1587.394287,2722.607422 1589.548340,2719.788086 1592.721558,2718.518311 
	C1594.406128,2718.463135 1595.349243,2718.698242 1596.910645,2719.479492 
	C1599.827759,2721.860840 1602.300537,2723.504395 1604.885254,2725.061768 
	C1613.323975,2730.144287 1613.914551,2735.493164 1606.943970,2742.509766 
	C1597.490356,2752.025879 1590.431396,2764.400391 1576.004883,2767.992676 
	C1573.258789,2768.676270 1572.110229,2771.422852 1571.641846,2774.222900 
	C1571.200439,2776.862793 1571.206177,2779.535645 1570.937622,2782.186523 
	C1569.484985,2796.521729 1567.265747,2798.586670 1553.070190,2798.887451 
	C1550.742676,2798.936768 1548.398193,2798.780273 1545.346436,2799.531982 
	C1536.865601,2799.509277 1529.682983,2796.874512 1521.637939,2794.724121 
	C1519.524414,2794.032715 1518.179810,2793.417725 1516.078369,2792.743164 
	C1514.890625,2792.441650 1514.417480,2792.309326 1513.243652,2791.943604 
	C1501.520386,2787.346680 1490.503906,2782.963379 1480.083130,2777.255127 
	C1476.657349,2775.378418 1472.778931,2773.930176 1471.827881,2768.809570 
	C1478.579224,2763.271729 1483.397461,2769.964600 1489.360229,2770.788086 
	C1485.608154,2767.949951 1483.049805,2764.482666 1483.674194,2759.842773 
	C1484.614380,2752.857178 1481.855835,2746.904541 1479.190308,2740.860840 
	C1474.042480,2729.189453 1466.558472,2725.963623 1453.767090,2728.017334 
	C1446.636841,2729.162354 1441.058594,2735.752197 1432.557251,2733.038574 
	C1429.244629,2729.303467 1428.135010,2724.467285 1422.732910,2722.606689 
z"
        />
        <path
          fill="#E59F70"
          opacity="1.000000"
          stroke="none"
          d="
M1457.807861,2525.426025 
	C1458.679321,2533.256592 1452.186768,2536.103760 1448.804443,2540.748291 
	C1447.141846,2543.031738 1444.133301,2541.597412 1443.083008,2539.326416 
	C1441.309937,2535.492432 1439.372070,2531.651367 1441.245361,2526.973633 
	C1443.318604,2521.796875 1441.478027,2518.204346 1435.965454,2516.100830 
	C1431.724854,2514.482422 1428.169067,2511.655273 1423.103882,2511.649414 
	C1417.935059,2511.643555 1413.867065,2508.597900 1411.120605,2503.912598 
	C1409.285400,2500.781738 1408.291382,2497.106201 1404.821045,2494.269043 
	C1396.001099,2484.794922 1392.342285,2473.892334 1391.802979,2460.997070 
	C1393.300781,2454.008545 1393.924683,2447.671631 1395.500244,2440.691895 
	C1397.792847,2434.555664 1401.270874,2430.791260 1408.032349,2430.007324 
	C1416.235352,2431.061035 1419.741455,2436.080322 1421.032837,2443.224609 
	C1425.060181,2441.447266 1426.188110,2437.690918 1429.629639,2435.334473 
	C1433.188721,2434.317627 1435.133179,2435.832764 1437.291870,2438.534180 
	C1439.260742,2442.003418 1440.282959,2444.966309 1440.185791,2449.002197 
	C1438.089355,2456.399902 1434.024048,2461.499512 1427.799805,2465.831787 
	C1417.545776,2471.910400 1414.667480,2478.047363 1417.687744,2487.264404 
	C1422.422852,2501.715088 1427.974487,2508.461914 1438.838379,2505.975586 
	C1444.478760,2504.684082 1450.006836,2506.109863 1455.836182,2508.852051 
	C1457.035278,2509.982178 1457.524780,2510.779541 1458.065063,2512.328125 
	C1459.208130,2516.988525 1456.087646,2520.766602 1457.807861,2525.426025 
z"
        />
        <path
          fill="#564031"
          opacity="1.000000"
          stroke="none"
          d="
M1509.027344,2542.044434 
	C1507.807983,2537.374756 1506.879272,2533.462158 1506.011475,2528.785645 
	C1513.798706,2526.335449 1514.163452,2533.564941 1517.522339,2537.282227 
	C1519.124756,2539.055664 1518.206665,2544.910156 1524.030762,2541.097656 
	C1527.703125,2537.969238 1529.065430,2534.571289 1529.408569,2530.444824 
	C1529.735596,2526.509766 1530.910522,2522.742188 1535.961792,2521.121094 
	C1538.841553,2521.791504 1540.302124,2523.539795 1540.236938,2525.452148 
	C1539.989624,2532.718750 1544.831055,2538.436523 1546.032349,2544.907959 
	C1547.381226,2552.175049 1549.592896,2558.086914 1555.441650,2563.434570 
	C1556.666138,2571.233643 1559.297607,2577.327637 1565.486450,2581.673828 
	C1568.159302,2583.550781 1569.633911,2582.636230 1571.861572,2580.257324 
	C1573.473633,2579.872803 1574.321655,2580.254639 1575.442505,2581.575195 
	C1576.690796,2589.651123 1580.316772,2596.220947 1582.597168,2603.955078 
	C1583.884033,2613.481445 1573.090332,2613.337891 1571.057495,2619.981934 
	C1570.752930,2620.977783 1567.537109,2619.261475 1565.921265,2618.087402 
	C1563.613525,2616.410156 1562.005005,2613.237793 1557.605713,2614.595703 
	C1549.295898,2611.896484 1548.255615,2604.846924 1547.592773,2598.472168 
	C1546.669556,2589.593750 1543.260864,2582.666748 1535.579346,2576.940186 
	C1534.003174,2574.575195 1533.610718,2572.620361 1533.661255,2569.842285 
	C1537.705811,2562.896973 1531.364136,2560.663574 1528.901123,2556.290527 
	C1528.352417,2554.942627 1530.174316,2554.210205 1528.451538,2553.789307 
	C1526.178589,2558.342041 1525.685547,2563.536621 1519.654541,2565.628906 
	C1517.416992,2566.058350 1515.924072,2565.885010 1513.786987,2564.925781 
	C1506.504028,2558.206055 1511.854004,2549.816895 1509.027344,2542.044434 
z"
        />
        <path
          fill="#0C0B0A"
          opacity="1.000000"
          stroke="none"
          d="
M1391.459717,2361.584229 
	C1392.180054,2364.363281 1392.331299,2366.687988 1392.796265,2368.947998 
	C1392.976562,2369.824707 1394.106201,2370.770264 1395.003296,2370.084961 
	C1396.612915,2368.854980 1396.881958,2365.399170 1400.171387,2367.177002 
	C1401.858398,2368.088867 1403.505615,2369.074707 1405.213623,2370.051758 
	C1407.463989,2364.415771 1403.910522,2358.121338 1408.440674,2353.085938 
	C1407.929321,2359.986816 1408.580933,2366.582764 1411.115723,2372.973389 
	C1411.780884,2374.650391 1409.781738,2375.817627 1409.049194,2377.241455 
	C1406.736084,2381.738037 1405.148193,2386.435547 1406.446289,2391.781250 
	C1408.959961,2390.599365 1408.759399,2386.999512 1411.582642,2386.232178 
	C1412.797729,2385.901611 1414.998535,2384.734131 1415.068115,2385.834473 
	C1415.461182,2392.050781 1419.980957,2387.778076 1422.293457,2388.653076 
	C1422.708008,2391.559326 1420.341675,2392.924561 1419.552002,2395.016602 
	C1417.032104,2401.693359 1421.538208,2409.907959 1428.581665,2409.761963 
	C1433.411499,2409.661621 1437.963257,2413.603027 1442.695801,2411.038574 
	C1444.133423,2407.049805 1439.084106,2406.122559 1439.811157,2402.322998 
	C1451.363525,2402.343750 1463.036499,2399.639404 1475.243408,2403.984131 
	C1476.299805,2403.708740 1476.598389,2403.437012 1477.495117,2403.191406 
	C1478.854126,2403.411133 1479.625000,2403.546143 1480.996460,2403.486816 
	C1481.998047,2403.292236 1482.409912,2403.230713 1483.454346,2403.259521 
	C1488.280396,2404.543701 1489.312378,2409.226807 1493.425659,2411.531006 
	C1494.485962,2412.110107 1494.898315,2412.382080 1495.848633,2413.189697 
	C1497.678345,2415.612549 1497.792480,2417.609375 1496.440308,2420.330566 
	C1492.072021,2423.623047 1487.702026,2422.678467 1482.715576,2422.633789 
	C1476.553589,2421.280518 1471.236084,2419.254395 1464.848755,2420.727051 
	C1462.847168,2421.429688 1461.764648,2422.364746 1459.883789,2423.395020 
	C1456.804932,2424.627197 1454.312500,2424.787598 1451.029053,2424.319336 
	C1449.824219,2423.959473 1449.362793,2423.760742 1448.316406,2423.107422 
	C1447.503052,2422.225098 1447.274536,2421.798096 1446.971436,2420.673340 
	C1449.100952,2411.950684 1441.567993,2417.656006 1440.962158,2415.020020 
	C1442.727051,2414.982666 1445.192383,2415.330078 1444.658203,2419.732422 
	C1443.647217,2421.107666 1442.799927,2421.621338 1441.086060,2422.135010 
	C1439.280029,2422.417969 1438.233521,2422.406250 1436.405762,2422.137207 
	C1433.643921,2421.250488 1431.586548,2420.676514 1429.343506,2418.764160 
	C1427.531250,2417.574707 1426.244141,2416.981934 1424.412842,2416.408203 
	C1417.770630,2415.303223 1411.516235,2416.829346 1404.686279,2414.965332 
	C1401.895264,2414.802002 1399.857422,2415.015137 1397.051636,2415.284668 
	C1395.631226,2413.483643 1394.744019,2411.670410 1394.382446,2409.757812 
	C1393.854248,2406.964355 1393.794434,2404.321533 1398.114380,2404.991699 
	C1402.498657,2405.671631 1402.157837,2402.462158 1401.441040,2399.971680 
	C1400.828979,2397.844727 1399.189575,2396.024658 1398.077759,2394.028076 
	C1396.045898,2390.379150 1395.674316,2387.020996 1397.902100,2382.942627 
	C1401.280884,2376.756104 1399.650269,2374.362793 1393.054932,2375.555420 
	C1388.924805,2376.302246 1386.056763,2374.992432 1382.545898,2372.271973 
	C1378.668457,2368.617432 1380.211670,2364.076172 1379.034912,2360.201416 
	C1378.502075,2358.446533 1381.015137,2358.250000 1383.278076,2358.020996 
	C1386.014282,2359.758545 1389.229858,2358.814209 1391.459717,2361.584229 
z"
        />
        <path
          fill="#B67247"
          opacity="1.000000"
          stroke="none"
          d="
M1435.277344,2420.130859 
	C1436.843262,2420.223633 1437.724976,2420.227295 1439.263184,2420.313232 
	C1446.968994,2423.018799 1450.362061,2428.438232 1450.933472,2435.606201 
	C1451.380371,2441.212891 1453.800415,2444.717285 1459.205933,2446.683350 
	C1466.008789,2449.157959 1468.448120,2453.904541 1464.521240,2461.458984 
	C1463.460083,2463.099609 1462.887817,2464.103516 1462.075195,2465.634277 
	C1458.714844,2468.850586 1455.770264,2471.556641 1461.482666,2475.486328 
	C1461.970947,2478.556885 1462.141846,2481.206543 1461.900146,2483.817871 
	C1461.439697,2488.792236 1462.847412,2492.853027 1466.878418,2495.970459 
	C1470.369263,2498.669922 1472.539185,2502.316895 1474.038818,2507.156250 
	C1473.087280,2513.818359 1469.061890,2511.616455 1464.969604,2510.535645 
	C1460.913818,2507.321045 1461.689331,2502.300781 1458.180298,2498.777588 
	C1455.351685,2501.608398 1457.502319,2504.730713 1457.262695,2508.200928 
	C1456.951538,2509.324219 1456.735962,2509.745605 1455.831787,2510.341797 
	C1448.419922,2509.150146 1442.014771,2507.551514 1434.752563,2509.284180 
	C1428.384766,2510.803711 1423.560913,2505.581543 1420.500366,2500.331543 
	C1417.523193,2495.224854 1415.199829,2489.628906 1413.360107,2483.996094 
	C1411.316528,2477.738525 1416.781860,2469.136230 1425.789795,2464.382568 
	C1429.384521,2468.752197 1427.067505,2472.719482 1425.363770,2476.473145 
	C1421.752563,2484.429932 1422.369141,2491.682373 1428.468750,2498.868896 
	C1429.298584,2499.577393 1429.617920,2499.868896 1430.297607,2500.665039 
	C1430.889648,2502.448730 1431.196289,2501.939941 1431.931152,2500.695557 
	C1435.326660,2497.538574 1437.727295,2494.344482 1440.623657,2491.605957 
	C1449.780396,2482.948242 1449.726074,2480.344482 1440.063354,2471.227051 
	C1437.581421,2464.440430 1441.165649,2457.871826 1438.375366,2450.954346 
	C1438.452881,2447.139648 1437.234131,2444.353516 1435.944946,2440.809814 
	C1436.634888,2435.368164 1433.628174,2434.316895 1429.970459,2434.108643 
	C1425.887695,2433.876465 1422.036743,2433.300049 1419.751709,2428.758789 
	C1421.984375,2425.190430 1427.346069,2426.144043 1427.942017,2420.653809 
	C1430.248535,2418.063232 1432.410034,2419.970215 1435.277344,2420.130859 
z"
        />
        <path
          fill="#564031"
          opacity="1.000000"
          stroke="none"
          d="
M1480.279053,2458.319824 
	C1477.569092,2457.845215 1475.500854,2457.105713 1472.796265,2456.541016 
	C1471.840942,2456.373047 1471.522095,2456.030273 1471.036987,2455.006104 
	C1476.195312,2444.021484 1476.111084,2443.809326 1466.472778,2434.485107 
	C1465.087646,2433.145508 1464.702271,2431.013184 1463.122559,2428.834961 
	C1462.501465,2427.212402 1462.368408,2426.223145 1462.505005,2424.440430 
	C1463.115845,2422.119629 1463.981812,2420.821533 1465.943115,2419.395996 
	C1470.928589,2419.156982 1475.106445,2420.156494 1480.157227,2420.786621 
	C1492.538574,2422.391846 1504.438232,2419.783203 1516.539795,2424.113281 
	C1523.350952,2430.010742 1517.393555,2435.703613 1516.561646,2440.775391 
	C1515.430908,2447.667480 1514.966553,2454.227295 1515.884277,2461.759766 
	C1514.919800,2466.084961 1514.595947,2470.205811 1508.714844,2470.180176 
	C1504.849121,2471.072510 1501.594971,2475.847168 1498.467285,2472.004150 
	C1494.383911,2466.986572 1499.334351,2461.524658 1499.818970,2455.051025 
	C1495.016357,2458.295898 1490.753296,2463.288086 1485.858765,2456.286133 
	C1484.479126,2454.312500 1482.496826,2457.099121 1480.279053,2458.319824 
z"
        />
        <path
          fill="#B0714F"
          opacity="1.000000"
          stroke="none"
          d="
M1508.495117,2542.156738 
	C1512.627441,2542.944824 1512.244141,2546.441406 1512.269165,2549.692139 
	C1512.302124,2553.968994 1512.289307,2558.235840 1513.956787,2563.119873 
	C1514.361938,2572.016846 1515.416016,2580.224609 1511.450562,2587.828857 
	C1509.105835,2592.325195 1508.457275,2596.422119 1512.459106,2600.508057 
	C1516.349731,2604.480225 1515.769409,2608.157959 1510.841675,2610.955322 
	C1506.887085,2613.200684 1507.951904,2615.902588 1509.467041,2619.335693 
	C1515.155762,2632.224854 1518.708618,2645.943848 1524.197266,2659.444824 
	C1517.159668,2660.816650 1504.533569,2654.111816 1503.009521,2648.805420 
	C1502.680054,2647.658447 1503.104370,2646.219238 1503.419312,2644.975098 
	C1505.294434,2637.568848 1502.028320,2630.878906 1500.384766,2624.020752 
	C1498.545288,2616.344971 1495.119385,2608.938232 1496.755615,2600.784424 
	C1497.207397,2598.533691 1496.617432,2597.486816 1494.995850,2595.827881 
	C1490.532715,2591.262695 1490.600464,2590.486328 1495.908325,2586.668457 
	C1499.649536,2583.977295 1497.787476,2580.774414 1497.514160,2577.779297 
	C1497.193726,2574.268799 1493.764160,2572.440430 1492.091553,2568.828369 
	C1494.460815,2563.619385 1496.807861,2559.011963 1499.836670,2554.095703 
	C1507.614624,2553.220947 1507.614624,2553.220947 1508.495117,2542.156738 
z"
        />
        <path
          fill="#442F21"
          opacity="1.000000"
          stroke="none"
          d="
M1479.660645,2459.341309 
	C1479.775024,2457.608154 1479.891968,2456.627930 1480.054688,2455.655518 
	C1480.450195,2453.291992 1481.111816,2450.894775 1483.973633,2450.780518 
	C1486.353882,2450.685547 1488.376709,2451.860840 1489.553955,2454.122803 
	C1490.255127,2455.469727 1489.521973,2457.438965 1491.409058,2458.331055 
	C1494.080322,2457.676514 1494.345581,2455.004395 1495.496216,2453.160156 
	C1497.192383,2450.441895 1499.270874,2449.178223 1501.862671,2451.457031 
	C1504.039795,2453.371582 1506.636353,2456.399658 1503.374268,2458.838867 
	C1499.743774,2461.553467 1500.528687,2465.186035 1499.649902,2468.522705 
	C1499.307617,2469.822021 1498.976562,2471.227051 1500.856079,2471.520752 
	C1502.341187,2471.753174 1503.149780,2470.814941 1503.776001,2469.487305 
	C1504.572754,2467.797607 1503.234375,2464.156250 1507.757690,2465.767578 
	C1508.305908,2468.915527 1509.909058,2471.207031 1510.101807,2474.532715 
	C1509.895996,2478.370850 1507.512329,2480.119385 1505.475708,2482.183105 
	C1504.271362,2482.308105 1505.434204,2482.903564 1505.680420,2482.636963 
	C1507.973145,2480.152832 1510.192505,2481.856689 1512.796143,2483.721924 
	C1514.160034,2486.030273 1514.290649,2487.885742 1513.678711,2490.462891 
	C1509.185791,2497.713867 1503.334106,2497.090820 1496.514526,2494.401611 
	C1492.993774,2490.027588 1491.687500,2485.235352 1489.854858,2480.687744 
	C1488.011230,2476.112549 1486.569946,2471.221436 1482.361084,2468.187256 
	C1479.335327,2466.006104 1480.400635,2462.839600 1479.660645,2459.341309 
z"
        />
        <path
          fill="#704A3A"
          opacity="1.000000"
          stroke="none"
          d="
M1419.048706,2428.059082 
	C1422.943359,2430.746094 1426.700806,2431.657471 1430.568237,2432.325195 
	C1433.505493,2432.832520 1436.333130,2433.752686 1438.341309,2436.061768 
	C1439.794312,2437.732666 1438.877075,2438.981689 1436.457275,2439.900146 
	C1434.396362,2438.673828 1433.523804,2436.631836 1430.704834,2436.042969 
	C1426.293335,2438.522217 1422.640503,2438.003174 1419.234009,2435.243652 
	C1416.874756,2433.332520 1414.366943,2431.927490 1410.597412,2431.668701 
	C1405.210449,2432.385498 1401.570923,2435.006348 1398.131470,2439.078125 
	C1395.264160,2445.750000 1393.390259,2451.971191 1393.899902,2459.387207 
	C1394.390015,2471.746826 1398.669189,2482.270020 1403.963379,2493.242188 
	C1400.989136,2495.865479 1399.983398,2492.758545 1399.072144,2491.110840 
	C1393.347656,2480.762451 1386.591309,2470.841064 1384.711548,2458.774902 
	C1383.101440,2448.439453 1382.036255,2438.303955 1391.775879,2430.776611 
	C1396.785522,2426.904541 1402.272949,2425.411377 1408.490356,2426.453369 
	C1411.732056,2426.996826 1414.978882,2427.509766 1419.048706,2428.059082 
z"
        />
        <path
          fill="#805541"
          opacity="1.000000"
          stroke="none"
          d="
M1464.104858,2423.087402 
	C1464.134521,2424.883301 1464.182617,2425.879639 1464.361816,2427.613037 
	C1465.011597,2437.073486 1473.057495,2443.746338 1471.560059,2453.811035 
	C1471.927979,2454.881348 1471.972168,2455.247559 1472.088135,2456.165039 
	C1471.474487,2460.444336 1469.306885,2462.347412 1464.739746,2462.131348 
	C1464.013794,2460.769287 1463.852051,2459.426025 1464.002441,2458.119141 
	C1464.583374,2453.068604 1462.856323,2450.590332 1457.442017,2449.269531 
	C1448.274414,2447.033691 1447.850098,2446.486572 1448.179321,2437.091064 
	C1448.422363,2430.153809 1444.048462,2426.270996 1440.265503,2420.978027 
	C1440.824219,2420.296387 1441.728882,2420.197021 1443.320190,2420.043213 
	C1444.772217,2419.297852 1445.662842,2419.040039 1447.176270,2419.759766 
	C1447.673828,2420.304688 1448.041504,2421.056152 1448.210449,2421.438965 
	C1448.613525,2422.142578 1448.847656,2422.463379 1449.520996,2422.381836 
	C1452.698975,2421.873535 1455.437500,2421.767334 1458.999756,2421.643555 
	C1461.422607,2420.769043 1462.856201,2420.986816 1464.104858,2423.087402 
z"
        />
        <path
          fill="#A26847"
          opacity="1.000000"
          stroke="none"
          d="
M1446.001587,2551.285156 
	C1443.449219,2554.216064 1441.894653,2556.846680 1443.216797,2560.571045 
	C1445.507690,2567.023438 1444.757812,2574.351562 1448.875977,2580.289307 
	C1449.672241,2581.437012 1450.210815,2583.402832 1449.352661,2583.947754 
	C1442.062622,2588.576416 1448.589600,2590.923340 1450.902710,2593.855957 
	C1452.349243,2595.690186 1454.310425,2597.366699 1452.900024,2599.920898 
	C1449.624268,2605.853271 1449.729858,2612.639404 1447.884033,2618.927490 
	C1447.179321,2621.328369 1446.171387,2623.499756 1443.453247,2623.481445 
	C1439.455933,2623.454346 1440.254639,2620.127441 1439.969360,2616.872559 
	C1443.313354,2615.399170 1444.659424,2613.515137 1442.798462,2610.407471 
	C1441.409302,2608.087646 1439.722900,2606.054688 1441.253174,2603.136230 
	C1445.334473,2595.352295 1441.937134,2587.632080 1440.891113,2579.923828 
	C1439.338501,2568.481201 1434.896484,2557.452637 1436.079102,2544.718750 
	C1440.429688,2544.611572 1443.354248,2547.305664 1446.001587,2551.285156 
z"
        />
        <path
          fill="#442F21"
          opacity="1.000000"
          stroke="none"
          d="
M1526.340820,2543.444336 
	C1524.582275,2544.142578 1523.317993,2544.406738 1523.291382,2546.121338 
	C1523.271729,2547.387695 1523.588257,2548.959229 1521.838013,2549.158203 
	C1520.464600,2549.314209 1519.139282,2548.273682 1518.849731,2547.068359 
	C1517.608276,2541.901367 1513.971069,2537.948730 1512.080566,2533.159180 
	C1511.130615,2530.752686 1509.252686,2529.750977 1506.695190,2528.311768 
	C1505.972534,2527.695312 1505.872437,2527.369141 1505.774902,2526.531738 
	C1506.441040,2522.251709 1504.093994,2519.200195 1503.274048,2515.786133 
	C1501.175537,2507.047852 1502.983276,2504.768799 1513.541504,2504.655273 
	C1517.636841,2509.881836 1520.671265,2514.695312 1526.350220,2518.066162 
	C1533.325928,2522.206543 1533.074463,2530.943604 1527.451904,2536.203125 
	C1525.518188,2538.011719 1528.289795,2540.774170 1526.340820,2543.444336 
z"
        />
        <path
          fill="#704A3A"
          opacity="1.000000"
          stroke="none"
          d="
M1500.364380,2552.992188 
	C1499.795776,2559.285400 1500.570435,2565.543945 1492.657715,2567.948730 
	C1486.950562,2566.077148 1481.958618,2564.018311 1476.875977,2561.922119 
	C1479.254395,2557.141602 1484.658691,2557.592285 1486.254639,2552.790039 
	C1486.948242,2551.317139 1487.494019,2550.517334 1488.264648,2549.283691 
	C1489.827759,2546.387939 1489.748291,2543.462158 1491.527100,2540.476318 
	C1491.970459,2539.098389 1491.947388,2538.235840 1491.805786,2536.735840 
	C1491.485718,2535.087891 1491.840454,2534.309326 1493.339478,2533.973145 
	C1497.246094,2539.817383 1499.068237,2545.910400 1500.364380,2552.992188 
z"
        />
        <path
          fill="#564031"
          opacity="1.000000"
          stroke="none"
          d="
M1514.408691,2505.519043 
	C1503.585449,2506.952393 1502.337891,2509.135742 1505.997437,2518.402100 
	C1506.873413,2520.619873 1510.965698,2522.855957 1506.377563,2525.934326 
	C1501.616455,2521.383789 1501.536865,2515.276611 1499.940674,2509.724365 
	C1498.594360,2505.040771 1497.407104,2500.311768 1496.039795,2494.839355 
	C1497.275635,2493.197510 1498.525879,2492.088135 1499.985718,2491.487305 
	C1503.393555,2490.084473 1503.895630,2495.975830 1505.762451,2494.737793 
	C1507.371338,2493.670898 1507.275391,2490.032959 1511.318115,2491.896484 
	C1516.327881,2495.405029 1517.180542,2499.785156 1514.408691,2505.519043 
z"
        />
        <path
          fill="#C88655"
          opacity="1.000000"
          stroke="none"
          d="
M1493.926025,2533.436523 
	C1493.461792,2534.595703 1492.917480,2534.974854 1492.030273,2535.726074 
	C1490.804443,2535.690674 1489.519775,2535.522461 1489.109253,2534.833496 
	C1487.448730,2532.046875 1486.840454,2528.374268 1483.372192,2527.117676 
	C1479.532227,2525.726807 1477.462158,2528.917969 1474.918335,2530.903564 
	C1473.065552,2532.349854 1471.120483,2531.796875 1469.643799,2530.144775 
	C1467.937744,2528.236328 1468.578491,2526.154053 1470.432495,2525.082520 
	C1475.768677,2521.997559 1481.908691,2520.408203 1485.873535,2514.902588 
	C1487.070923,2513.239746 1489.896362,2515.226318 1489.749268,2517.489014 
	C1489.385254,2523.092529 1492.151611,2527.698486 1493.926025,2533.436523 
z"
        />
        <path
          fill="#8C5B43"
          opacity="1.000000"
          stroke="none"
          d="
M1455.308350,2511.069336 
	C1455.333984,2509.893799 1455.524414,2509.270996 1455.919678,2507.692871 
	C1458.194336,2505.595947 1460.274414,2505.648682 1462.989502,2507.204590 
	C1466.647339,2509.404541 1469.670044,2511.148193 1473.674194,2508.412109 
	C1477.888428,2513.280518 1477.825562,2513.612793 1472.618774,2514.056641 
	C1470.396729,2514.246094 1468.150024,2514.146240 1465.107178,2514.228516 
	C1462.175415,2515.681152 1459.964233,2516.219482 1456.885010,2514.383789 
	C1455.956421,2513.082275 1455.714844,2512.352051 1455.308350,2511.069336 
z"
        />
        <path
          fill="#B67247"
          opacity="1.000000"
          stroke="none"
          d="
M1456.114258,2514.527344 
	C1458.354980,2513.906494 1460.511719,2514.000732 1463.484253,2514.187012 
	C1463.168945,2518.223633 1461.274048,2521.797119 1458.493652,2525.626221 
	C1453.603882,2525.127930 1450.902344,2523.582031 1454.969482,2518.946533 
	C1455.735474,2518.073486 1455.697388,2516.495117 1456.114258,2514.527344 
z"
        />
        <path
          fill="#8C5B43"
          opacity="1.000000"
          stroke="none"
          d="
M1462.063354,2475.110840 
	C1459.428833,2475.204590 1455.587402,2480.154053 1454.235474,2474.292969 
	C1453.145020,2469.566406 1454.695312,2467.612305 1461.055176,2466.112305 
	C1461.936768,2468.873047 1462.038574,2471.585205 1462.063354,2475.110840 
z"
        />
        <path
          fill="#B67247"
          opacity="1.000000"
          stroke="none"
          d="
M1404.173828,2415.301514 
	C1410.252808,2413.247314 1416.511230,2413.551514 1423.292480,2415.858398 
	C1417.329956,2418.839111 1410.829590,2419.436279 1404.173828,2415.301514 
z"
        />
        <path
          fill="#DED5C3"
          opacity="1.000000"
          stroke="none"
          d="
M1473.948608,2538.208008 
	C1477.941284,2538.660645 1478.373291,2540.632324 1476.278076,2542.697754 
	C1474.963013,2543.994141 1472.942139,2546.984863 1470.838989,2544.270996 
	C1468.965698,2541.853516 1470.192139,2539.413818 1473.948608,2538.208008 
z"
        />
        <path
          fill="#C88655"
          opacity="1.000000"
          stroke="none"
          d="
M1491.251953,2540.140625 
	C1492.271240,2542.964111 1492.838745,2546.043945 1489.153564,2548.511475 
	C1488.305542,2545.803467 1486.799438,2542.443848 1491.251953,2540.140625 
z"
        />
        <path
          fill="#805541"
          opacity="1.000000"
          stroke="none"
          d="
M1435.421143,2419.668457 
	C1433.681152,2422.976318 1431.514771,2420.231445 1428.668335,2420.022949 
	C1427.992554,2419.474609 1428.045288,2418.996826 1428.509277,2418.348877 
	C1430.933105,2417.884521 1432.985352,2417.999268 1435.421143,2419.668457 
z"
        />
        <path
          fill="#AB7453"
          opacity="1.000000"
          stroke="none"
          d="
M1432.011108,2613.992676 
	C1432.022217,2613.985596 1431.308472,2613.539551 1431.308472,2613.539551 
	C1431.308472,2613.539551 1431.785278,2613.623291 1431.885132,2613.822754 
	C1431.984985,2614.021973 1432.000000,2614.000000 1432.011108,2613.992676 
z"
        />
        <path
          fill="#E5DDC9"
          opacity="1.000000"
          stroke="none"
          d="
M1412.981567,3661.924805 
	C1414.132080,3657.805420 1413.833496,3652.354248 1420.199463,3655.230957 
	C1422.029663,3656.058105 1423.828125,3657.130371 1423.534058,3659.593262 
	C1423.267212,3661.826416 1421.803467,3663.174561 1419.714600,3663.627930 
	C1417.451660,3664.119141 1415.104126,3664.456299 1412.981567,3661.924805 
z"
        />
        <path
          fill="#EBE4D0"
          opacity="1.000000"
          stroke="none"
          d="
M1375.072998,3696.954590 
	C1375.119019,3697.039551 1375.026855,3696.869629 1375.072998,3696.954590 
z"
        />
        <path
          fill="#EAE5D5"
          opacity="1.000000"
          stroke="none"
          d="
M2019.420898,3195.722412 
	C2019.733521,3199.557861 2019.457153,3203.115479 2019.151367,3207.052002 
	C2020.442505,3207.373291 2021.661621,3207.853027 2022.911987,3207.956299 
	C2027.536133,3208.338867 2028.959473,3210.513672 2026.321899,3214.437500 
	C2024.550171,3217.073486 2023.358765,3219.877930 2024.265259,3222.804688 
	C2025.525146,3226.872559 2024.701904,3228.727783 2020.855591,3231.335449 
	C2016.329590,3234.403564 2016.087402,3240.882568 2018.065674,3245.509521 
	C2019.573486,3249.036133 2019.304199,3250.602295 2017.014038,3253.090820 
	C2014.556274,3255.761475 2013.127686,3258.728027 2018.158325,3260.623779 
	C2019.416870,3261.097656 2020.328735,3262.995850 2019.448486,3263.930176 
	C2012.847290,3270.935547 2019.657104,3279.799805 2016.068359,3287.194824 
	C2015.537354,3288.289307 2016.027222,3289.833740 2015.781372,3291.119385 
	C2014.699829,3296.778564 2013.123047,3297.333740 2006.636108,3294.112305 
	C2001.784302,3288.901611 2006.547241,3283.387695 2005.314453,3278.105713 
	C2005.100098,3277.187012 2007.285889,3275.474609 2006.067261,3274.686768 
	C1999.915039,3270.710205 2003.826172,3264.333252 2002.372925,3259.249023 
	C2000.910400,3254.132324 2001.262695,3248.364014 2005.759766,3243.533203 
	C2008.039917,3241.084229 2009.195435,3236.976562 2009.544922,3233.487305 
	C2010.049194,3228.452637 2011.144897,3223.893799 2013.679810,3219.477783 
	C2014.723633,3217.659424 2016.431152,3214.917480 2014.931274,3212.948242 
	C2011.228149,3208.086182 2013.732178,3203.606934 2015.484375,3198.484131 
	C2016.788086,3196.978760 2017.512329,3195.881104 2019.420898,3195.722412 
z"
        />
        <path
          fill="#EAE5D5"
          opacity="1.000000"
          stroke="none"
          d="
M1986.802979,3351.990723 
	C1981.562622,3358.870605 1974.706787,3355.572510 1968.443115,3355.281494 
	C1966.552124,3355.193359 1966.824829,3352.814453 1968.287842,3351.464111 
	C1969.894165,3349.981445 1971.968262,3349.950439 1973.936401,3350.071777 
	C1978.174927,3350.332764 1982.568726,3349.023682 1986.802979,3351.990723 
z"
        />
        <path
          fill="#EAE5D5"
          opacity="1.000000"
          stroke="none"
          d="
M2005.254272,3299.993652 
	C2006.615356,3302.202148 2007.245361,3304.414062 2007.930420,3307.317871 
	C2002.727905,3306.338867 1998.008911,3302.786133 1991.964966,3303.817627 
	C1990.367920,3304.090332 1988.480835,3303.163818 1989.188477,3300.884766 
	C1989.733276,3299.129395 1991.560181,3297.854004 1993.036133,3299.002930 
	C1996.791260,3301.926025 2000.715210,3299.673340 2005.254272,3299.993652 
z"
        />
        <path
          fill="#EAE6D7"
          opacity="1.000000"
          stroke="none"
          d="
M1980.506104,3252.004883 
	C1977.370239,3251.752197 1975.008423,3250.905273 1972.880249,3248.479736 
	C1973.937622,3245.270752 1976.900269,3242.803223 1979.640137,3243.148193 
	C1983.515259,3243.635986 1981.434692,3247.853516 1981.995972,3251.218750 
	C1981.995117,3251.990234 1981.002441,3252.000000 1980.506104,3252.004883 
z"
        />
        <path
          fill="#EEE9DC"
          opacity="1.000000"
          stroke="none"
          d="
M2020.020752,3195.333252 
	C2019.014771,3196.517334 2018.019775,3197.034668 2016.510010,3197.775879 
	C2016.115112,3195.731201 2015.742188,3193.235352 2019.322266,3192.124023 
	C2020.010498,3192.888916 2020.021240,3193.777588 2020.020752,3195.333252 
z"
        />
        <path
          fill="#EAE5D5"
          opacity="1.000000"
          stroke="none"
          d="
M1939.285645,3431.143066 
	C1940.776489,3430.726807 1941.316650,3431.249023 1941.086548,3432.405518 
	C1941.038818,3432.645508 1940.697266,3432.968750 1940.454346,3433.004639 
	C1939.285522,3433.177490 1938.796631,3432.607422 1939.285645,3431.143066 
z"
        />
        <path
          fill="#EEE9DC"
          opacity="1.000000"
          stroke="none"
          d="
M1990.302856,3217.812256 
	C1989.997070,3217.663574 1989.999023,3217.329102 1990.005249,3216.492188 
	C1990.322998,3216.326660 1990.672607,3216.638672 1990.923340,3217.016602 
	C1990.985107,3217.109863 1990.723633,3217.417236 1990.302856,3217.812256 
z"
        />
        <path
          fill="#797771"
          opacity="1.000000"
          stroke="none"
          d="
M2049.440430,3024.034180 
	C2052.163574,3029.610352 2052.427002,3036.176270 2058.852051,3039.987061 
	C2060.653564,3042.520508 2061.794922,3044.935303 2063.516602,3047.676758 
	C2065.537598,3049.742188 2067.687012,3050.767090 2069.234375,3053.336426 
	C2070.764648,3056.367188 2071.436523,3059.008301 2072.718994,3062.148438 
	C2074.648193,3066.651611 2077.472412,3069.817627 2079.867188,3074.105469 
	C2083.157227,3087.894775 2085.277588,3101.289551 2092.169434,3114.037598 
	C2092.803223,3116.800537 2093.227783,3118.827393 2092.576660,3121.623047 
	C2091.430420,3122.944336 2090.499756,3123.298828 2088.737305,3123.311035 
	C2075.067871,3114.057617 2060.595459,3106.496094 2053.561279,3093.876953 
	C2053.513184,3097.840576 2053.149170,3104.772705 2045.739380,3109.235352 
	C2044.570190,3109.166992 2044.110962,3109.040527 2043.119873,3108.437500 
	C2043.614868,3105.316406 2043.735352,3102.561523 2043.495117,3098.946533 
	C2045.733765,3096.675293 2045.799927,3094.329102 2045.813599,3091.985596 
	C2045.898315,3077.496338 2046.734497,3063.110352 2050.529053,3049.006104 
	C2052.028564,3043.432373 2049.589844,3037.973633 2049.090576,3032.449707 
	C2048.883789,3030.159912 2048.356689,3027.898926 2047.963135,3024.787354 
	C2047.951050,3023.949707 2048.941650,3023.999756 2049.440430,3024.034180 
z"
        />
        <path
          fill="#A6A29C"
          opacity="1.000000"
          stroke="none"
          d="
M2038.284180,3172.479492 
	C2037.178223,3171.493896 2036.546753,3170.967285 2036.504395,3170.396973 
	C2035.138428,3151.995605 2036.012817,3133.720459 2039.854980,3114.805664 
	C2040.997803,3113.267334 2042.129761,3113.019043 2044.116699,3113.743652 
	C2049.044434,3121.359375 2046.974854,3129.161133 2047.195312,3137.531738 
	C2046.900146,3139.964355 2046.788940,3141.575195 2046.301758,3143.889160 
	C2044.915894,3147.521729 2042.104492,3150.044434 2044.137695,3154.378906 
	C2044.668823,3156.578369 2044.753296,3158.098389 2044.895752,3160.423340 
	C2045.326660,3164.640869 2045.059570,3167.957031 2042.929443,3171.745361 
	C2041.324341,3172.974121 2040.208252,3173.141602 2038.284180,3172.479492 
z"
        />
        <path
          fill="#DFD9CC"
          opacity="1.000000"
          stroke="none"
          d="
M2034.887695,3078.045898 
	C2031.298218,3074.476807 2026.050171,3071.394531 2031.629028,3066.048828 
	C2029.337646,3059.830811 2032.188965,3053.728516 2032.085449,3046.726074 
	C2037.415405,3044.008301 2037.196533,3047.984131 2037.785522,3051.851807 
	C2037.023071,3058.789062 2037.305664,3064.960449 2039.552368,3071.508301 
	C2040.072266,3073.480713 2040.122803,3074.975830 2040.158447,3077.218018 
	C2038.640503,3077.986816 2037.137573,3078.008301 2034.887695,3078.045898 
z"
        />
        <path
          fill="#817A79"
          opacity="1.000000"
          stroke="none"
          d="
M2038.738525,3212.019043 
	C2036.088623,3210.727783 2036.070557,3208.794678 2035.558105,3206.292236 
	C2036.148804,3200.175537 2036.820923,3194.732666 2035.805176,3188.603516 
	C2036.905029,3187.230469 2038.119995,3187.004150 2040.212158,3187.846191 
	C2044.389282,3196.496094 2045.582520,3204.460449 2038.738525,3212.019043 
z"
        />
        <path
          fill="#BAB4A7"
          opacity="1.000000"
          stroke="none"
          d="
M2044.874390,3113.966309 
	C2043.116089,3113.997559 2042.217651,3113.993164 2040.644775,3113.991455 
	C2037.350098,3111.209473 2037.486328,3109.104248 2042.024536,3107.952637 
	C2043.066895,3108.007324 2043.545654,3108.053711 2044.746094,3108.110107 
	C2047.860352,3109.950928 2047.523560,3111.907471 2044.874390,3113.966309 
z"
        />
        <path
          fill="#A6A29C"
          opacity="1.000000"
          stroke="none"
          d="
M2040.159058,3187.386963 
	C2038.716431,3187.882568 2037.880493,3187.911377 2036.415283,3187.953613 
	C2033.505493,3184.571533 2033.166504,3181.284180 2036.975342,3177.950684 
	C2039.764771,3178.569336 2040.444092,3180.521240 2041.295898,3183.206055 
	C2041.462280,3185.003906 2041.238281,3185.990479 2040.159058,3187.386963 
z"
        />
        <path
          fill="#817A79"
          opacity="1.000000"
          stroke="none"
          d="
M2040.745117,3183.977783 
	C2038.025146,3182.753418 2038.138916,3180.853516 2037.786621,3178.385254 
	C2037.645020,3176.291748 2037.669800,3174.874023 2037.614746,3172.749023 
	C2038.297852,3171.973633 2039.060669,3171.906250 2040.394409,3171.874023 
	C2045.968872,3175.610107 2045.375122,3179.681885 2040.745117,3183.977783 
z"
        />
        <path
          fill="#091825"
          opacity="1.000000"
          stroke="none"
          d="
M2195.861328,3378.698730 
	C2203.009766,3374.315186 2210.739258,3376.047852 2218.115234,3375.242188 
	C2222.847412,3374.725098 2228.946045,3371.822998 2230.800537,3367.763916 
	C2231.831787,3365.506592 2230.905029,3363.904053 2229.129395,3362.676025 
	C2226.616455,3360.938232 2225.198975,3358.894043 2227.412598,3356.104736 
	C2229.440918,3353.548340 2232.031006,3353.979492 2234.583008,3355.366943 
	C2235.453613,3355.840332 2236.279785,3356.395264 2237.562988,3357.451660 
	C2244.295654,3365.732422 2253.257812,3365.319092 2261.675293,3364.839355 
	C2278.131104,3363.901367 2294.883545,3366.269531 2310.982910,3360.439941 
	C2315.053467,3358.965820 2318.630371,3361.720703 2321.885986,3365.558105 
	C2327.977539,3385.157715 2329.654541,3404.582764 2329.320068,3424.984375 
	C2325.060791,3429.500977 2320.873535,3427.584717 2316.854004,3426.189209 
	C2310.572510,3424.008057 2304.288086,3421.927979 2297.737305,3420.654053 
	C2292.020752,3419.542236 2286.881348,3419.021240 2282.230957,3424.079102 
	C2278.561523,3428.069824 2272.698486,3428.255127 2268.138428,3426.615967 
	C2254.104248,3421.571533 2239.538818,3421.395508 2225.009521,3420.434326 
	C2222.700195,3420.281494 2220.459961,3419.504395 2217.503906,3418.592285 
	C2211.669678,3415.113037 2205.989502,3413.539062 2200.148926,3412.188232 
	C2196.204346,3411.276367 2192.240234,3409.246094 2191.350830,3404.959961 
	C2190.361572,3400.192383 2194.079590,3398.801514 2198.011719,3398.216553 
	C2199.623535,3397.976562 2200.121094,3397.098389 2199.827637,3395.495605 
	C2198.036377,3385.719727 2204.624756,3387.773926 2209.542236,3389.223633 
	C2218.767578,3391.942871 2227.751709,3395.422852 2237.426514,3396.470215 
	C2244.533203,3397.239990 2251.142822,3396.648193 2258.337646,3390.407471 
	C2248.288574,3389.284668 2239.749512,3390.967773 2231.526367,3389.026611 
	C2219.491943,3386.186035 2207.265869,3384.062744 2195.861328,3378.698730 
z"
        />
        <path
          fill="#46565D"
          opacity="1.000000"
          stroke="none"
          d="
M2327.330566,3426.791016 
	C2325.365479,3406.528564 2324.407471,3386.880615 2320.277832,3366.827881 
	C2318.019043,3360.606201 2315.427979,3355.267578 2317.394531,3348.535645 
	C2317.282715,3345.052246 2316.776367,3342.184814 2316.226807,3338.584473 
	C2315.324707,3337.180664 2314.966064,3336.305176 2315.523926,3334.596680 
	C2316.574219,3329.660889 2314.807617,3325.688232 2314.108887,3320.775391 
	C2310.740967,3316.339600 2310.191406,3312.102295 2311.740234,3306.764893 
	C2312.097168,3304.537354 2312.063965,3303.046143 2311.951904,3300.814453 
	C2312.527588,3292.976562 2310.128662,3286.321777 2308.559570,3278.806885 
	C2304.994629,3275.333008 2304.774658,3271.326416 2303.758301,3266.756836 
	C2303.014893,3262.618408 2302.882324,3259.313477 2304.439453,3255.354980 
	C2307.915527,3251.685059 2311.176270,3253.357178 2313.970459,3254.802002 
	C2338.701660,3267.591797 2365.263184,3275.927002 2390.862549,3286.593506 
	C2392.398926,3287.233643 2393.849365,3287.994629 2395.488770,3289.829590 
	C2397.148193,3291.387695 2398.341553,3292.321777 2399.745117,3293.793457 
	C2398.708252,3298.460205 2396.703125,3300.226074 2391.926270,3298.531738 
	C2382.580811,3295.216064 2372.859375,3292.938721 2363.575684,3289.474854 
	C2356.730469,3286.920410 2349.245605,3286.858398 2342.691162,3283.456299 
	C2340.933105,3282.543457 2338.883057,3281.579346 2337.227539,3283.388672 
	C2335.466064,3285.313965 2337.831055,3287.003418 2338.002686,3288.853516 
	C2338.063721,3289.509766 2338.101807,3290.167969 2337.915283,3292.258301 
	C2337.410889,3294.077881 2337.141357,3294.464600 2336.368408,3295.354492 
	C2335.501465,3296.168457 2335.137695,3296.478516 2334.300781,3297.330566 
	C2331.830322,3308.060547 2336.330078,3317.394287 2337.898438,3327.080566 
	C2341.448730,3349.009521 2345.407959,3370.876953 2349.342041,3392.744873 
	C2349.985596,3396.321289 2350.790283,3399.984619 2348.499023,3404.184814 
	C2343.631348,3416.108398 2336.777344,3425.969238 2329.545654,3436.184326 
	C2325.163086,3434.216797 2325.500488,3431.025879 2327.330566,3426.791016 
z"
        />
        <path
          fill="#10212F"
          opacity="1.000000"
          stroke="none"
          d="
M2195.069092,3378.000000 
	C2196.804688,3378.562744 2197.689453,3379.700928 2198.401611,3379.601562 
	C2207.927246,3378.274170 2216.115723,3382.989258 2224.922119,3384.851807 
	C2233.305176,3386.624512 2242.266113,3389.413818 2251.176514,3386.182373 
	C2251.774902,3385.965576 2252.492432,3386.047607 2253.154785,3386.041992 
	C2256.840088,3386.010986 2261.584717,3385.806152 2262.632080,3389.634277 
	C2263.843018,3394.060059 2258.773682,3394.604492 2255.821533,3396.547363 
	C2249.099609,3400.970947 2242.095947,3400.100342 2234.790283,3399.335205 
	C2224.769775,3398.286377 2215.768799,3394.000977 2206.322998,3391.131592 
	C2204.484131,3390.572998 2202.355469,3389.331299 2201.178955,3391.780273 
	C2200.269287,3393.674316 2202.180908,3394.650635 2203.439453,3395.672119 
	C2204.460205,3396.500244 2205.786377,3397.125977 2206.455322,3398.174561 
	C2207.558838,3399.904053 2206.921387,3401.230469 2204.809082,3401.763916 
	C2201.730469,3402.541992 2198.956299,3400.938232 2196.019043,3400.772949 
	C2194.676025,3400.697266 2193.359863,3401.567139 2193.116455,3403.001465 
	C2192.977539,3403.820068 2193.315430,3405.135742 2193.918701,3405.592041 
	C2196.522217,3407.560791 2198.913574,3409.796143 2202.578857,3410.057129 
	C2207.182617,3410.384766 2211.763672,3411.271240 2215.645020,3415.379395 
	C2214.982910,3421.958984 2210.380615,3420.469727 2206.891846,3419.890381 
	C2202.300049,3419.127686 2197.819580,3418.205078 2192.581055,3419.641113 
	C2185.477539,3420.241943 2179.473145,3418.314453 2174.230469,3414.767090 
	C2170.270020,3412.087891 2166.594971,3411.857666 2162.108154,3412.847168 
	C2153.212158,3414.809326 2144.132080,3415.566162 2135.002197,3415.259521 
	C2133.734863,3415.217041 2132.416260,3414.901855 2131.240967,3414.420898 
	C2130.047119,3413.932129 2128.899658,3413.157715 2129.011963,3411.542236 
	C2129.118164,3410.017334 2130.177734,3408.936523 2131.401367,3408.738770 
	C2134.308350,3408.268799 2137.281738,3408.216553 2140.227051,3407.976318 
	C2142.154785,3407.819336 2144.398682,3408.139648 2145.263184,3405.788330 
	C2146.248535,3403.108398 2144.464111,3401.341797 2142.646240,3399.938232 
	C2141.111084,3398.753174 2139.075684,3398.222656 2137.519531,3397.057617 
	C2135.734375,3395.720947 2136.100342,3393.511719 2136.017578,3390.781738 
	C2140.098877,3389.655273 2143.800781,3390.741943 2147.400635,3392.640381 
	C2150.008057,3394.015625 2152.781006,3395.123047 2155.569336,3396.094971 
	C2157.932373,3396.918457 2160.621826,3398.727051 2162.683350,3395.688721 
	C2164.440918,3393.098145 2162.019043,3391.599365 2160.809570,3389.719238 
	C2157.418457,3384.448242 2158.563965,3382.060547 2164.684082,3379.847412 
	C2170.769531,3377.646240 2177.011963,3378.059814 2183.253174,3378.013916 
	C2186.881348,3377.987305 2190.509766,3378.003174 2195.069092,3378.000000 
z"
        />
        <path
          fill="#10212F"
          opacity="1.000000"
          stroke="none"
          d="
M2158.505859,3306.496094 
	C2158.251953,3295.191650 2166.052979,3292.174561 2174.544189,3290.107910 
	C2176.778320,3289.564209 2179.168945,3289.567627 2181.193848,3288.335449 
	C2194.380371,3280.311279 2208.173340,3284.919678 2221.792969,3286.444824 
	C2222.886230,3286.567383 2224.262695,3287.720459 2224.767822,3288.755615 
	C2225.676514,3290.618408 2224.133789,3291.639160 2222.700439,3292.594971 
	C2219.881104,3294.475830 2214.648438,3295.434570 2217.107666,3300.190918 
	C2218.871826,3303.603271 2223.032715,3301.939209 2226.139160,3300.912598 
	C2228.515137,3300.127197 2230.618164,3300.811279 2232.913330,3301.216797 
	C2239.457520,3302.372803 2245.909180,3302.699951 2252.375977,3299.952637 
	C2258.281006,3297.444580 2264.523438,3298.864258 2271.348389,3300.078857 
	C2271.517578,3302.825195 2269.771484,3303.963379 2267.104980,3303.954834 
	C2264.121338,3303.945312 2261.137939,3303.979492 2258.154297,3304.008057 
	C2249.465088,3304.091309 2249.465088,3304.093994 2249.715332,3312.635498 
	C2249.845215,3317.072510 2247.830811,3319.326904 2242.158447,3319.800049 
	C2236.888916,3320.239746 2231.558105,3320.016846 2226.255127,3319.977051 
	C2216.804688,3319.906250 2207.366455,3321.090332 2198.497070,3315.521240 
	C2194.556396,3313.046631 2189.012207,3312.452393 2184.148193,3312.268799 
	C2175.405273,3311.937988 2167.317627,3309.132080 2158.505859,3306.496094 
z"
        />
        <path
          fill="#152533"
          opacity="1.000000"
          stroke="none"
          d="
M2317.007812,3347.904541 
	C2319.625488,3353.470215 2320.628418,3359.177734 2320.576904,3365.517090 
	C2316.000732,3362.594482 2312.166260,3362.048340 2306.636475,3364.318848 
	C2296.120117,3368.636719 2284.744629,3368.955566 2273.458252,3367.412842 
	C2271.205078,3367.104736 2268.954834,3365.571289 2266.735840,3366.861572 
	C2257.442139,3372.266113 2248.790039,3365.957764 2239.845459,3365.380371 
	C2236.681641,3365.176270 2235.888184,3362.252686 2237.725342,3358.627441 
	C2240.777832,3357.999512 2243.555664,3358.008789 2247.166748,3358.018799 
	C2251.031006,3357.968262 2253.813477,3355.529541 2257.511719,3357.516602 
	C2262.086426,3357.949463 2266.160400,3357.947754 2271.107422,3357.931152 
	C2279.297363,3355.303955 2285.181641,3351.240967 2287.459961,3342.520020 
	C2292.521240,3339.118164 2296.695557,3338.762695 2300.833740,3343.185303 
	C2304.936035,3347.569336 2311.187744,3345.967529 2317.007812,3347.904541 
z"
        />
        <path
          fill="#2B3E51"
          opacity="1.000000"
          stroke="none"
          d="
M2118.789551,3298.326660 
	C2114.749512,3296.056885 2111.144287,3295.966064 2107.462158,3296.003906 
	C2100.180664,3296.078857 2092.897705,3296.016113 2085.615234,3295.993896 
	C2082.971680,3295.985596 2080.316650,3296.084961 2077.857178,3294.779785 
	C2076.356934,3293.983643 2074.715332,3293.136963 2075.090088,3291.197754 
	C2075.426025,3289.459229 2076.979980,3288.427734 2078.703613,3288.290283 
	C2080.994141,3288.108154 2083.512451,3287.689209 2085.580566,3288.398926 
	C2091.400879,3290.395996 2097.336914,3289.922119 2103.266113,3290.029541 
	C2109.476807,3290.141846 2115.469482,3289.770020 2121.511963,3293.033203 
	C2126.580811,3295.770264 2133.282715,3293.200684 2138.946777,3295.763184 
	C2140.703369,3296.558105 2142.887939,3296.405029 2143.953125,3299.389160 
	C2135.957275,3299.751221 2127.637451,3301.143555 2118.789551,3298.326660 
z"
        />
        <path
          fill="#091825"
          opacity="1.000000"
          stroke="none"
          d="
M2287.101318,3341.854980 
	C2287.924561,3342.624268 2287.956543,3343.281250 2287.954346,3343.937988 
	C2287.913330,3356.540039 2286.691895,3357.737549 2272.820312,3357.939453 
	C2272.898926,3355.895508 2273.817871,3353.875244 2274.952148,3351.381592 
	C2271.471436,3348.938721 2267.377197,3350.569336 2263.714844,3349.836426 
	C2260.060547,3349.104736 2259.668701,3346.830322 2261.394531,3344.061279 
	C2263.051758,3341.402344 2265.595459,3339.995605 2268.829590,3340.041992 
	C2274.698730,3340.125977 2280.660156,3339.247559 2287.101318,3341.854980 
z"
        />
        <path
          fill="#10212F"
          opacity="1.000000"
          stroke="none"
          d="
M2311.398926,3306.109863 
	C2314.268066,3309.910400 2314.063721,3314.166016 2314.055664,3319.190674 
	C2310.526611,3319.843262 2306.532715,3320.741943 2304.682373,3316.565918 
	C2303.375732,3313.617676 2304.150391,3310.766357 2306.579834,3308.634033 
	C2307.753418,3307.604004 2309.293701,3306.992188 2311.398926,3306.109863 
z"
        />
        <path
          fill="#10212F"
          opacity="1.000000"
          stroke="none"
          d="
M2276.176270,3300.789062 
	C2277.252930,3297.548340 2278.977539,3295.817627 2282.024170,3297.036621 
	C2284.782227,3298.139893 2287.644043,3297.910889 2291.284668,3298.061768 
	C2288.073730,3305.108398 2284.723389,3305.837891 2276.176270,3300.789062 
z"
        />
        <path
          fill="#091825"
          opacity="1.000000"
          stroke="none"
          d="
M2257.867188,3357.282471 
	C2254.916748,3357.803711 2252.216553,3361.091309 2248.448730,3358.509766 
	C2252.342285,3353.050049 2253.228271,3352.921875 2257.867188,3357.282471 
z"
        />
        <path
          fill="#0B1926"
          opacity="1.000000"
          stroke="none"
          d="
M2043.511719,3397.509277 
	C2041.671265,3400.794922 2038.459839,3399.781494 2034.763062,3399.989258 
	C2035.441772,3394.279297 2039.328857,3395.932129 2043.511719,3397.509277 
z"
        />
        <path
          fill="#1B2B3A"
          opacity="1.000000"
          stroke="none"
          d="
M2315.270264,3333.987305 
	C2316.026611,3334.831055 2316.111816,3335.693359 2316.190186,3337.203613 
	C2314.380859,3337.753662 2311.865234,3338.610352 2311.296143,3336.266357 
	C2310.748535,3334.010742 2313.176025,3334.352051 2315.270264,3333.987305 
z"
        />
        <path
          fill="#10212F"
          opacity="1.000000"
          stroke="none"
          d="
M2296.009766,3298.678955 
	C2297.027100,3298.440186 2298.151611,3298.753174 2299.638184,3299.541748 
	C2298.250732,3302.001221 2296.991943,3301.562744 2296.009766,3298.678955 
z"
        />
        <path
          fill="#10212F"
          opacity="1.000000"
          stroke="none"
          d="
M2305.703857,3265.388672 
	C2309.037842,3268.995117 2307.709717,3272.903809 2308.221191,3277.299316 
	C2298.965088,3280.557373 2290.386475,3275.265137 2281.166748,3275.845703 
	C2271.933594,3276.427246 2262.542236,3276.843750 2253.441162,3274.066162 
	C2251.870361,3273.586914 2249.482178,3273.858154 2248.755127,3272.853516 
	C2243.890381,3266.131836 2236.887939,3268.222168 2230.466553,3268.031738 
	C2223.235107,3267.817383 2215.925781,3268.926758 2209.348877,3264.238281 
	C2204.800049,3260.995361 2199.520752,3263.391602 2194.854736,3264.367676 
	C2184.589355,3266.514893 2174.565430,3266.487305 2164.259766,3264.600342 
	C2156.826660,3263.239258 2149.152344,3263.512451 2140.296875,3264.893799 
	C2143.869873,3269.401611 2147.978760,3270.061279 2152.038330,3269.896729 
	C2159.600098,3269.590332 2166.021484,3274.090576 2173.380615,3274.506348 
	C2174.807617,3274.586670 2175.639648,3276.005615 2175.242920,3277.437012 
	C2175.016846,3278.252686 2174.135254,3279.359619 2173.410400,3279.476562 
	C2171.132324,3279.843506 2168.748047,3280.140869 2166.476807,3279.867676 
	C2151.020264,3278.006104 2135.920654,3274.418945 2121.033936,3269.943359 
	C2118.527588,3269.189941 2115.709961,3268.258057 2113.933350,3266.501221 
	C2106.022217,3258.678711 2095.145508,3257.671875 2085.921143,3253.233154 
	C2075.792236,3248.358887 2066.152100,3248.029053 2055.800293,3247.294434 
	C2051.655518,3247.000488 2046.382935,3246.690186 2043.525269,3241.833984 
	C2042.023071,3239.281006 2040.156982,3242.990967 2037.988037,3241.801270 
	C2035.140381,3237.620850 2035.996338,3233.504150 2036.880859,3228.590820 
	C2037.442505,3223.096680 2037.674805,3218.426514 2037.840088,3212.878418 
	C2040.985474,3204.798340 2039.718018,3197.154785 2039.764526,3188.757812 
	C2039.652344,3186.998779 2039.752319,3186.144287 2039.789307,3184.645508 
	C2042.343628,3180.813721 2041.199097,3177.039795 2041.165771,3172.689697 
	C2041.304565,3168.487061 2041.643677,3165.064697 2042.244629,3160.822754 
	C2045.705566,3154.301514 2050.717285,3152.367188 2056.786133,3153.955811 
	C2083.231934,3160.877930 2110.385742,3164.797119 2137.315430,3172.965820 
	C2138.550781,3173.202393 2139.038574,3173.293701 2140.213379,3173.627441 
	C2142.951172,3174.895264 2144.937500,3176.128418 2148.117432,3175.859375 
	C2176.909424,3182.612061 2203.893311,3192.859131 2231.415283,3201.359863 
	C2234.251709,3202.236084 2236.925293,3203.687988 2239.020508,3206.986328 
	C2238.304688,3220.194580 2236.231689,3221.518799 2220.112305,3220.583984 
	C2231.863525,3222.090088 2242.856689,3226.522705 2254.430176,3228.688232 
	C2257.699951,3229.300049 2261.068359,3229.435547 2264.241211,3230.517822 
	C2267.755859,3231.716553 2271.680908,3232.929688 2271.081787,3237.708252 
	C2270.560791,3241.864502 2266.859863,3242.631348 2263.506836,3242.824951 
	C2252.922119,3243.437256 2242.410645,3246.263916 2231.692383,3243.671387 
	C2228.898438,3242.995605 2225.793457,3242.557129 2221.791504,3244.621582 
	C2234.266846,3250.424561 2247.261475,3251.565674 2259.249512,3255.842285 
	C2266.121582,3258.294189 2273.580811,3258.976562 2280.111084,3262.589600 
	C2283.282959,3264.344238 2286.577881,3263.552734 2289.851807,3262.756104 
	C2295.220459,3261.449951 2300.426758,3261.414551 2305.703857,3265.388672 
z"
        />
        <path
          fill="#817A79"
          opacity="1.000000"
          stroke="none"
          d="
M2036.566040,3228.051758 
	C2037.299927,3231.766357 2037.389771,3235.765869 2037.561890,3240.598877 
	C2037.308350,3241.542236 2036.972412,3241.652100 2036.108398,3241.814453 
	C2033.309326,3237.296631 2033.205566,3232.781006 2036.566040,3228.051758 
z"
        />
        <path
          fill="#33495B"
          opacity="1.000000"
          stroke="none"
          d="
M1972.189697,2639.788086 
	C1970.660156,2643.444336 1970.553345,2647.562500 1966.515259,2649.982666 
	C1964.912720,2650.487305 1963.797852,2650.854736 1962.963135,2650.331787 
	C1945.380371,2639.301758 1926.509033,2644.224609 1908.685425,2647.831787 
	C1894.796143,2650.643066 1881.883423,2650.793213 1867.798828,2647.112061 
	C1860.852661,2647.186523 1854.653320,2646.786621 1848.470093,2647.082031 
	C1845.903442,2647.204834 1842.078857,2645.856689 1842.308838,2651.386719 
	C1846.860107,2660.695557 1840.837036,2662.892822 1833.814697,2664.623047 
	C1833.273438,2654.290283 1833.407227,2644.221436 1833.396729,2633.353027 
	C1839.566162,2632.898682 1844.525513,2628.943115 1849.705688,2626.507324 
	C1854.772095,2624.124512 1858.906250,2624.703125 1863.944092,2625.415527 
	C1869.927734,2626.261475 1868.554565,2629.146484 1866.618896,2633.667480 
	C1871.342529,2632.165771 1875.106445,2632.440674 1878.932129,2633.746826 
	C1889.679810,2637.416504 1900.472046,2634.459229 1911.323364,2633.987061 
	C1926.444702,2633.329102 1941.735229,2632.712891 1956.753784,2635.425781 
	C1961.748779,2636.327393 1967.706177,2634.030273 1972.189697,2639.788086 
z"
        />
        <path
          fill="#33495B"
          opacity="1.000000"
          stroke="none"
          d="
M2040.448486,2659.541016 
	C2031.436279,2659.364014 2022.742065,2655.757080 2014.506958,2661.654541 
	C2013.396484,2662.449951 2011.272461,2661.830078 2008.798584,2661.820312 
	C2005.012939,2657.964111 2000.842407,2657.600098 1996.440430,2657.497803 
	C1991.330078,2657.379395 1988.411865,2654.943848 1989.543701,2648.847656 
	C1996.564697,2648.273682 2003.181152,2647.239014 2009.660522,2649.386719 
	C2010.831543,2649.774658 2012.664795,2649.953125 2013.490967,2649.316895 
	C2021.975952,2642.787598 2032.220947,2647.835205 2041.320679,2644.985352 
	C2044.582275,2643.964111 2047.663330,2646.289795 2048.085449,2651.178223 
	C2045.552734,2654.106689 2041.870728,2655.190918 2040.448486,2659.541016 
z"
        />
        <path
          fill="#2F465E"
          opacity="1.000000"
          stroke="none"
          d="
M2040.911865,2660.047852 
	C2039.662354,2657.202148 2039.334473,2654.359863 2039.005005,2651.503662 
	C2041.823608,2649.876465 2044.157715,2652.532959 2047.417480,2651.932373 
	C2060.430664,2651.959961 2072.724121,2651.642822 2084.898438,2653.966553 
	C2087.874023,2654.534424 2090.822998,2654.696533 2093.217285,2658.152588 
	C2087.422363,2660.968750 2081.072510,2661.421143 2075.394531,2661.145996 
	C2064.208496,2660.604248 2053.037354,2659.992920 2040.911865,2660.047852 
z"
        />
        <path
          fill="#33495B"
          opacity="1.000000"
          stroke="none"
          d="
M1993.785400,2666.379395 
	C1988.487183,2664.552002 1982.998047,2662.179932 1976.862793,2661.912109 
	C1972.858887,2661.737549 1971.286011,2659.999023 1973.362671,2656.137695 
	C1974.621582,2653.797119 1975.963745,2651.500977 1977.637207,2648.592529 
	C1980.145020,2648.049561 1982.284058,2648.098877 1985.223877,2648.202637 
	C1988.487305,2652.211182 1991.737305,2655.746826 1992.259399,2661.206055 
	C1992.509399,2663.126953 1993.070923,2664.409180 1993.785400,2666.379395 
z"
        />
        <path
          fill="#2F465E"
          opacity="1.000000"
          stroke="none"
          d="
M1992.840820,2661.887939 
	C1990.658447,2657.446533 1986.163574,2654.600342 1986.031738,2648.947754 
	C1986.906616,2648.224121 1987.788574,2648.191162 1989.330811,2648.224365 
	C1990.747070,2653.752441 1993.003296,2656.141602 1999.113037,2654.328125 
	C2003.496216,2653.027100 2007.606812,2654.426514 2007.962402,2661.024414 
	C2003.230835,2661.827148 1998.482300,2661.879395 1992.840820,2661.887939 
z"
        />
        <path
          fill="#33495B"
          opacity="1.000000"
          stroke="none"
          d="
M1977.323730,2643.801758 
	C1976.530640,2642.952393 1976.339600,2642.140625 1976.020264,2640.715820 
	C1977.580811,2638.008545 1979.514282,2638.260498 1980.662231,2640.309814 
	C1982.145508,2642.957520 1980.195190,2643.696045 1977.323730,2643.801758 
z"
        />
        <path
          fill="#1D1712"
          opacity="1.000000"
          stroke="none"
          d="
M1754.554077,2697.634766 
	C1753.477783,2702.396729 1749.335815,2703.270264 1746.714722,2705.573242 
	C1745.071045,2707.017578 1741.934448,2707.753174 1743.442505,2710.652588 
	C1744.453857,2712.596436 1747.127075,2712.219482 1748.838623,2711.357910 
	C1754.552490,2708.481201 1759.820312,2711.257568 1765.228271,2712.273926 
	C1768.238403,2712.839844 1769.585449,2714.990479 1770.233154,2718.642822 
	C1764.497192,2726.215576 1754.262573,2728.240723 1744.447998,2724.466309 
	C1731.845093,2719.619385 1718.978271,2717.955078 1704.949219,2720.997070 
	C1694.503296,2716.467285 1684.617554,2714.087158 1673.555420,2715.525635 
	C1667.394775,2716.326416 1661.463135,2711.219727 1656.865356,2708.122559 
	C1651.145142,2704.269043 1646.738770,2704.984863 1640.661133,2705.938721 
	C1638.535889,2706.240967 1637.118530,2706.169922 1635.028564,2705.696533 
	C1630.580444,2703.465576 1627.382446,2700.532471 1623.545288,2698.622803 
	C1619.126465,2696.423340 1615.288696,2696.339600 1612.700073,2702.328125 
	C1607.960571,2705.661377 1603.755249,2703.517822 1598.844482,2702.575684 
	C1597.717896,2702.263184 1597.282837,2702.103516 1596.236816,2701.636719 
	C1593.833008,2700.133789 1592.154175,2698.693359 1589.198975,2698.518799 
	C1588.144043,2698.316162 1587.723877,2698.222900 1586.681152,2697.960449 
	C1585.234497,2697.541016 1584.412842,2697.283447 1582.965332,2696.850342 
	C1581.494751,2696.449951 1580.655640,2696.205566 1579.180176,2695.765137 
	C1569.663818,2690.080322 1558.578247,2688.478271 1551.310791,2680.149414 
	C1550.753784,2679.510986 1550.346680,2679.135986 1550.278198,2679.336426 
	C1550.180908,2679.621094 1550.283325,2679.974121 1549.774658,2680.747559 
	C1546.287354,2682.478271 1543.578369,2681.613281 1540.307739,2679.815918 
	C1537.391846,2677.483887 1535.529663,2675.156494 1533.234863,2672.216797 
	C1531.467529,2667.752930 1532.483032,2664.591553 1536.108276,2661.635254 
	C1542.844482,2664.528320 1549.968506,2660.785645 1556.395142,2665.517334 
	C1556.510010,2652.930420 1548.105347,2651.193848 1539.452271,2648.677734 
	C1538.452637,2647.349609 1538.070068,2646.480225 1537.682739,2644.856689 
	C1537.455933,2639.625000 1540.131958,2635.136230 1537.560669,2630.535645 
	C1536.760864,2629.104736 1536.678589,2627.266602 1538.118408,2625.953125 
	C1539.866089,2624.359131 1541.828979,2624.859619 1543.638428,2625.810791 
	C1546.945190,2627.548828 1549.655396,2630.158936 1551.424561,2633.317871 
	C1557.018188,2643.305176 1566.450195,2647.802490 1577.049561,2651.846924 
	C1578.332397,2652.472900 1578.585938,2653.645264 1579.461426,2653.500000 
	C1580.119019,2653.390869 1579.925415,2652.802979 1578.699707,2652.019043 
	C1577.922363,2646.698975 1579.646851,2644.426270 1585.311890,2646.322754 
	C1588.081177,2648.358398 1589.144043,2650.914062 1591.213867,2653.510498 
	C1592.601440,2655.388428 1593.505249,2656.820801 1595.092651,2658.514160 
	C1596.759399,2660.729492 1598.616089,2661.692139 1600.949951,2663.096680 
	C1603.935913,2664.882324 1606.281006,2668.006348 1609.268555,2665.949951 
	C1611.643311,2664.315186 1609.865845,2661.036377 1610.152466,2658.510742 
	C1610.560791,2654.912109 1607.498169,2650.574463 1613.003662,2647.634521 
	C1616.815552,2646.341553 1619.636230,2644.365479 1622.771362,2643.415039 
	C1629.487183,2641.378906 1633.414307,2644.644775 1635.764404,2650.946533 
	C1634.647217,2645.762939 1637.328125,2641.246338 1638.487305,2636.387207 
	C1639.214600,2633.338379 1639.930298,2630.151611 1644.013306,2630.835938 
	C1647.888916,2631.485107 1649.236084,2634.601074 1649.641602,2638.138428 
	C1650.060669,2641.793213 1647.759888,2644.363770 1645.791748,2647.087402 
	C1655.466553,2646.860596 1664.603149,2654.453613 1663.747559,2662.962402 
	C1663.201172,2668.395996 1664.134033,2671.242432 1669.797852,2671.822510 
	C1671.779663,2672.025391 1673.761719,2672.827148 1675.289917,2674.092041 
	C1681.688354,2679.389160 1686.927246,2677.141113 1691.697021,2671.743408 
	C1695.399048,2667.553711 1700.361328,2665.709473 1705.450439,2664.042236 
	C1709.142700,2662.833252 1710.711304,2670.807617 1715.806641,2666.367676 
	C1718.136841,2666.994873 1719.058105,2668.293457 1720.042969,2670.228516 
	C1720.308350,2670.663574 1720.876099,2671.436035 1721.171875,2671.778320 
	C1721.467651,2672.120605 1722.031372,2672.735840 1722.280273,2672.997070 
	C1722.385010,2672.677002 1722.228271,2672.099121 1721.993164,2670.947021 
	C1722.112671,2667.408691 1723.389526,2665.252197 1727.059814,2664.233154 
	C1734.979736,2664.480713 1738.909058,2668.357178 1738.709106,2675.507324 
	C1738.585205,2679.932861 1741.375244,2683.625000 1741.193726,2688.372070 
	C1743.074585,2685.760254 1745.618042,2684.103027 1748.007080,2682.271729 
	C1751.213501,2679.815186 1754.141235,2674.986328 1758.856934,2679.718994 
	C1763.003174,2683.880127 1762.529663,2688.713379 1759.646851,2693.402344 
	C1758.599854,2695.105469 1756.734131,2696.075684 1754.554077,2697.634766 
z"
        />
        <path
          fill="#63707D"
          opacity="1.000000"
          stroke="none"
          d="
M1832.828003,2665.112549 
	C1835.995483,2662.202393 1838.982666,2660.194336 1841.659546,2657.834717 
	C1843.343262,2656.350342 1840.880249,2655.939209 1840.168945,2654.516846 
	C1848.477539,2648.489990 1858.051270,2648.674805 1867.653809,2649.460938 
	C1871.994385,2649.816650 1875.461182,2652.548340 1876.672607,2656.431885 
	C1879.990723,2667.070068 1885.970337,2677.184570 1883.841797,2689.793213 
	C1877.956665,2700.566162 1872.266479,2709.056152 1859.451660,2713.250732 
	C1851.330200,2715.908936 1841.305298,2716.913330 1834.292603,2724.093018 
	C1828.242310,2730.287354 1823.120117,2737.068359 1818.020996,2743.960205 
	C1815.465332,2747.414307 1816.740112,2750.698242 1817.546753,2754.328369 
	C1818.656372,2759.322021 1820.671021,2765.126221 1812.218750,2766.977539 
	C1804.159058,2761.772217 1805.156250,2752.910156 1801.693604,2745.243164 
	C1800.755249,2738.364014 1796.775879,2732.845947 1798.308105,2725.631348 
	C1803.910034,2718.982422 1810.235474,2713.898193 1814.968750,2706.454102 
	C1820.497192,2705.038574 1821.058472,2700.406006 1823.405029,2696.352295 
	C1828.850708,2686.433838 1828.860474,2675.619873 1832.828003,2665.112549 
z"
        />
        <path
          fill="#281C18"
          opacity="1.000000"
          stroke="none"
          d="
M1754.598877,2698.011230 
	C1761.561401,2687.984619 1761.561401,2687.984619 1755.076660,2678.966309 
	C1751.961914,2685.739258 1743.734009,2686.981201 1740.704468,2693.805176 
	C1739.889038,2686.173828 1734.302002,2679.878418 1735.670044,2671.669922 
	C1736.306763,2667.850098 1731.518677,2668.175781 1728.576538,2666.188477 
	C1727.642212,2665.485840 1727.401978,2665.114258 1727.148926,2664.083496 
	C1728.525757,2660.382080 1727.843384,2657.782715 1725.056274,2655.022949 
	C1723.460693,2652.593994 1722.515137,2650.557861 1721.448486,2647.846680 
	C1728.846436,2639.014893 1731.332031,2639.474854 1737.259277,2650.717285 
	C1737.571167,2652.287842 1735.464233,2653.084717 1736.904907,2653.887207 
	C1738.004517,2654.499512 1736.408813,2654.202881 1737.721313,2652.847900 
	C1744.191772,2652.399414 1747.721680,2657.853516 1753.482056,2658.960205 
	C1753.843872,2658.965088 1754.315186,2658.899414 1754.315186,2658.899414 
	C1754.315186,2658.899414 1754.059448,2658.428223 1753.633545,2658.366211 
	C1746.269287,2651.764893 1747.639771,2642.998535 1747.948730,2635.040771 
	C1748.110107,2630.885742 1752.478516,2628.959473 1757.556396,2628.416016 
	C1767.546265,2627.347412 1772.083862,2621.963379 1771.723999,2613.182129 
	C1771.753174,2611.527344 1771.918579,2610.598389 1772.508057,2609.053711 
	C1773.119507,2608.062744 1773.424927,2607.709473 1774.318604,2606.990234 
	C1782.510498,2604.328125 1786.917358,2597.879639 1793.185059,2592.802246 
	C1798.405884,2592.968506 1800.895264,2590.102783 1803.557861,2586.282715 
	C1810.429321,2591.652588 1811.540649,2594.160156 1808.667969,2599.888184 
	C1804.995361,2607.210693 1807.270630,2613.882568 1809.901123,2621.367188 
	C1804.935425,2622.449951 1801.993774,2621.174316 1801.814087,2615.103027 
	C1801.641968,2609.288574 1794.683838,2606.494629 1790.694946,2610.656738 
	C1786.666382,2614.859619 1781.936768,2618.592529 1779.614014,2624.437988 
	C1777.837402,2628.908691 1777.868408,2631.979248 1782.519775,2634.268066 
	C1789.963745,2637.930664 1795.539062,2643.800049 1800.698486,2650.109863 
	C1801.738525,2651.381836 1802.591675,2652.781006 1802.276367,2655.595215 
	C1797.593262,2652.025635 1792.963745,2651.957520 1788.602295,2655.494873 
	C1787.534668,2656.361084 1786.231445,2657.306396 1785.215942,2656.016357 
	C1780.945679,2650.591309 1777.474854,2653.070557 1773.820312,2656.770264 
	C1772.456543,2658.150879 1770.721680,2657.293213 1768.602173,2656.256348 
	C1767.982910,2658.976562 1768.388184,2661.833496 1769.158569,2664.749023 
	C1770.473877,2669.727295 1771.692871,2674.893311 1766.320190,2678.758789 
	C1765.654541,2679.238037 1766.104370,2681.267090 1766.003296,2683.308594 
	C1763.455688,2686.084961 1762.479248,2689.429199 1762.651245,2691.948486 
	C1763.082520,2698.268555 1758.776489,2697.381348 1754.598877,2698.011230 
z"
        />
        <path
          fill="#294159"
          opacity="1.000000"
          stroke="none"
          d="
M1871.551270,2556.672607 
	C1881.868408,2555.652344 1891.671143,2556.809326 1901.431763,2554.968018 
	C1905.818726,2554.140381 1909.412354,2559.438965 1915.124512,2557.759277 
	C1921.738037,2557.812256 1927.318481,2558.971924 1933.288940,2561.746826 
	C1934.317261,2563.578613 1935.217651,2566.076172 1932.730957,2565.766602 
	C1921.871094,2564.414551 1911.351318,2569.126953 1900.203369,2566.500000 
	C1893.506836,2564.922119 1885.784424,2564.451904 1879.015625,2569.008789 
	C1875.639282,2571.281738 1871.178101,2569.526123 1867.272339,2570.282227 
	C1865.804443,2570.566406 1864.794800,2571.404785 1864.698608,2572.847168 
	C1864.601685,2574.301270 1865.518555,2575.465820 1866.962036,2575.595459 
	C1871.897461,2576.037598 1877.130005,2577.215088 1881.640991,2574.740967 
	C1890.515381,2569.873291 1899.729736,2572.286621 1908.842651,2572.765137 
	C1910.609497,2572.857666 1914.257080,2573.785400 1910.306274,2577.439941 
	C1905.909058,2577.365479 1901.856201,2576.828857 1897.029785,2576.327148 
	C1890.989014,2581.469238 1883.757690,2580.066406 1876.766724,2582.045410 
	C1873.254761,2582.091309 1870.511719,2582.102051 1866.946045,2582.120605 
	C1862.407471,2585.651123 1857.725220,2584.697021 1853.263428,2585.048584 
	C1847.252930,2585.522217 1840.952881,2583.333496 1834.617188,2587.427734 
	C1832.480469,2587.971191 1830.987061,2587.973877 1828.746826,2587.971436 
	C1825.333252,2587.689697 1822.807129,2588.664551 1819.609619,2588.534180 
	C1817.043823,2583.438477 1818.035156,2578.493652 1818.377686,2572.751953 
	C1825.662476,2570.276367 1832.793091,2574.359863 1839.968018,2571.993896 
	C1841.144165,2571.605957 1843.509155,2572.340332 1843.553833,2571.479980 
	C1844.302856,2557.062500 1856.975342,2562.319336 1864.262573,2558.807129 
	C1866.353271,2557.799316 1868.896973,2558.253174 1871.551270,2556.672607 
z"
        />
        <path
          fill="#687073"
          opacity="1.000000"
          stroke="none"
          d="
M1798.904785,2723.608398 
	C1798.963013,2730.449707 1803.274658,2735.989746 1803.989624,2743.041016 
	C1803.709839,2744.153320 1803.399536,2744.490723 1802.420166,2744.992432 
	C1799.469238,2745.053955 1797.202515,2744.697510 1794.213013,2744.736816 
	C1792.093628,2744.691406 1790.700317,2744.456055 1788.624512,2743.954102 
	C1786.577393,2743.270996 1785.333008,2742.569092 1783.569336,2741.364990 
	C1775.840454,2732.518066 1767.818359,2727.508057 1756.327393,2734.852295 
	C1755.112549,2735.851562 1754.326172,2736.266113 1752.808472,2736.509033 
	C1750.273438,2736.065918 1752.367065,2730.646240 1747.483154,2733.169922 
	C1746.521118,2732.898926 1746.164429,2732.715332 1745.438965,2732.073975 
	C1744.527222,2730.649902 1744.003540,2729.668213 1742.511719,2728.836914 
	C1740.078735,2728.112061 1738.152588,2727.770996 1735.541138,2728.222656 
	C1729.404297,2729.164795 1726.674194,2733.514404 1723.367920,2737.233398 
	C1720.096680,2740.912598 1717.756714,2745.603516 1712.027832,2747.629395 
	C1709.156372,2747.788086 1707.362549,2746.965820 1705.365967,2744.881348 
	C1703.498779,2741.189209 1704.123657,2738.255127 1705.866943,2734.729980 
	C1706.665283,2731.632568 1712.820679,2730.715332 1707.506104,2726.698242 
	C1705.966309,2726.171631 1705.142578,2725.728516 1703.872803,2724.664795 
	C1703.036621,2723.195801 1702.999634,2722.242676 1703.789795,2720.805664 
	C1705.673828,2718.226318 1707.950806,2716.440430 1709.990356,2716.726074 
	C1718.023438,2717.851562 1726.145508,2715.076660 1734.198120,2718.228027 
	C1745.582153,2722.682861 1757.235107,2727.219727 1769.896606,2719.945557 
	C1771.581177,2719.179443 1772.804810,2718.882568 1774.659790,2718.532715 
	C1777.664673,2719.267090 1779.944702,2717.477051 1782.971680,2718.458252 
	C1787.608887,2722.264893 1790.777588,2728.234863 1798.904785,2723.608398 
z"
        />
        <path
          fill="#395166"
          opacity="1.000000"
          stroke="none"
          d="
M1871.254395,2556.170410 
	C1871.046265,2560.957520 1866.544189,2561.484619 1863.470459,2561.470947 
	C1856.515747,2561.440674 1850.993652,2564.433838 1845.315552,2567.430908 
	C1845.152466,2570.022461 1847.380371,2569.711914 1848.615234,2570.523438 
	C1850.006348,2571.437744 1851.678101,2572.419922 1850.773560,2574.375977 
	C1849.949341,2576.158447 1847.894897,2576.244873 1846.454346,2575.630127 
	C1842.493896,2573.940674 1838.796021,2574.857910 1834.851318,2575.710449 
	C1829.479248,2576.872070 1824.330566,2575.804932 1819.207520,2572.079590 
	C1817.123779,2566.703369 1820.275513,2562.165771 1821.079346,2556.453613 
	C1823.560669,2556.617188 1825.699585,2558.605957 1827.896484,2557.775146 
	C1833.834595,2555.529785 1839.670410,2557.166748 1845.312012,2558.156982 
	C1853.992554,2559.680664 1861.884888,2554.588379 1871.254395,2556.170410 
z"
        />
        <path
          fill="#434F51"
          opacity="1.000000"
          stroke="none"
          d="
M1816.017822,2705.151367 
	C1814.263672,2714.621094 1808.914062,2719.998535 1800.883667,2723.724365 
	C1800.207520,2723.781982 1800.162964,2723.841064 1800.141724,2723.835938 
	C1795.872559,2721.014404 1790.333252,2724.795410 1785.802246,2720.584717 
	C1784.495239,2718.020020 1784.954590,2716.345703 1787.433350,2714.867676 
	C1788.145264,2714.707031 1789.121460,2714.540039 1789.588379,2714.374756 
	C1792.282959,2711.355957 1796.025269,2712.052979 1799.494751,2710.485596 
	C1804.588745,2708.222412 1809.075439,2706.094727 1813.353271,2702.340820 
	C1814.588867,2702.620117 1815.187012,2703.246582 1816.017822,2705.151367 
z"
        />
        <path
          fill="#442F21"
          opacity="1.000000"
          stroke="none"
          d="
M1804.069580,2585.204834 
	C1803.403198,2595.752930 1803.086670,2596.060791 1794.603149,2594.224121 
	C1790.672363,2587.744141 1796.740356,2585.250488 1799.435547,2580.680176 
	C1800.780762,2580.111572 1801.631958,2580.095947 1803.116089,2580.134033 
	C1803.840698,2581.620605 1803.932251,2583.053711 1804.069580,2585.204834 
z"
        />
        <path
          fill="#434F51"
          opacity="1.000000"
          stroke="none"
          d="
M1823.129883,2695.896484 
	C1823.831055,2698.423340 1823.991577,2701.068848 1823.827637,2703.694580 
	C1823.550171,2708.140137 1820.945068,2708.258301 1816.954590,2706.582031 
	C1816.280151,2706.401855 1816.002075,2706.008789 1815.859375,2705.814941 
	C1815.242188,2704.835693 1815.033325,2703.984863 1815.446045,2702.498047 
	C1818.479126,2700.471436 1817.916260,2695.384766 1823.129883,2695.896484 
z"
        />
        <path
          fill="#1D1712"
          opacity="1.000000"
          stroke="none"
          d="
M1788.034424,2713.971680 
	C1787.381714,2715.873291 1786.618042,2717.039307 1785.698975,2718.716797 
	C1785.440796,2719.673096 1785.338013,2720.118408 1784.617676,2720.266602 
	C1781.467896,2719.836914 1778.877686,2720.772217 1775.847900,2719.018311 
	C1778.308350,2717.032959 1780.205200,2714.440918 1782.325928,2711.980713 
	C1784.582275,2709.363281 1786.769165,2709.634277 1788.034424,2713.971680 
z"
        />
        <path
          fill="#395166"
          opacity="1.000000"
          stroke="none"
          d="
M1819.192871,2588.963623 
	C1821.679688,2587.602783 1824.030640,2585.058594 1827.539307,2587.464844 
	C1823.862427,2589.749023 1822.275879,2592.960205 1822.251099,2597.692871 
	C1819.285400,2595.747070 1819.963989,2592.541504 1819.192871,2588.963623 
z"
        />
        <path
          fill="#1D1712"
          opacity="1.000000"
          stroke="none"
          d="
M1799.792847,2709.938477 
	C1798.121094,2713.661133 1794.951782,2714.254883 1790.780396,2714.204590 
	C1791.304688,2708.992676 1795.060181,2708.627686 1799.792847,2709.938477 
z"
        />
        <path
          fill="#281C18"
          opacity="1.000000"
          stroke="none"
          d="
M1803.909912,2533.350342 
	C1801.887451,2533.941650 1800.208984,2534.156250 1799.229004,2532.638184 
	C1798.689209,2531.801758 1799.324951,2530.775146 1800.292847,2530.546875 
	C1802.347778,2530.062500 1803.974121,2530.564941 1803.909912,2533.350342 
z"
        />
        <path
          fill="#1D1712"
          opacity="1.000000"
          stroke="none"
          d="
M1815.343018,2701.947754 
	C1815.801880,2702.750244 1815.799438,2703.573730 1815.756836,2705.009277 
	C1814.644409,2705.119385 1814.251221,2704.177490 1814.019531,2702.558105 
	C1813.960449,2702.022949 1814.881958,2701.968994 1815.343018,2701.947754 
z"
        />
        <path
          fill="#281C18"
          opacity="1.000000"
          stroke="none"
          d="
M1766.022095,2683.984375 
	C1766.264771,2684.251953 1766.487427,2684.535156 1766.709961,2684.818604 
	C1766.539307,2684.731445 1766.368652,2684.644043 1766.083740,2684.297852 
	C1765.969360,2684.039062 1766.001953,2684.000000 1766.022095,2683.984375 
z"
        />
        <path
          fill="#281C18"
          opacity="1.000000"
          stroke="none"
          d="
M1810.025269,2621.980469 
	C1810.261719,2622.238281 1810.474243,2622.515625 1810.686768,2622.793213 
	C1810.522217,2622.709717 1810.357788,2622.626465 1810.077881,2622.295898 
	C1809.962646,2622.048828 1810.001343,2622.000488 1810.025269,2621.980469 
z"
        />
        <path
          fill="#243B51"
          opacity="1.000000"
          stroke="none"
          d="
M2412.527832,2643.491943 
	C2403.232666,2640.889893 2393.958252,2639.963379 2384.883057,2638.194336 
	C2381.488525,2637.532715 2377.406738,2636.893799 2374.407227,2639.753418 
	C2371.659912,2642.372070 2369.031982,2641.016357 2366.095703,2640.408936 
	C2353.430664,2637.787109 2340.599609,2636.429199 2326.761719,2635.919678 
	C2315.697021,2632.941162 2305.054199,2634.100830 2294.750732,2632.123535 
	C2287.774170,2630.784912 2280.791748,2629.129883 2273.227539,2629.106934 
	C2277.062256,2625.119141 2281.856201,2622.723145 2285.346436,2618.111572 
	C2290.930420,2618.317627 2295.074707,2623.357910 2301.197754,2620.236816 
	C2303.142578,2624.982422 2307.146240,2626.597168 2310.970947,2625.568848 
	C2318.848389,2623.451660 2326.390625,2623.829346 2334.118164,2625.791992 
	C2335.549072,2626.155273 2337.272217,2625.368408 2339.421143,2624.591309 
	C2346.016357,2624.090088 2352.050537,2624.109863 2358.985840,2624.149902 
	C2361.487061,2625.600830 2363.196289,2626.271729 2365.666016,2624.575195 
	C2367.609375,2624.209473 2369.080322,2624.200195 2371.286865,2624.248535 
	C2376.202637,2630.186768 2382.237061,2628.750977 2387.310791,2627.050537 
	C2396.639893,2623.923828 2403.410645,2628.191895 2409.871338,2633.471436 
	C2412.552490,2635.662354 2414.840576,2638.910645 2412.527832,2643.491943 
z"
        />
        <path
          fill="#395166"
          opacity="1.000000"
          stroke="none"
          d="
M1834.909912,2587.990479 
	C1834.534912,2582.720703 1838.167236,2581.937744 1842.500122,2581.982178 
	C1849.743774,2582.056152 1856.988525,2582.020508 1865.177979,2582.079590 
	C1868.274536,2591.082520 1875.736328,2585.706543 1882.904541,2587.328613 
	C1873.378540,2592.285889 1866.008911,2588.528809 1858.668701,2588.133057 
	C1851.081055,2587.724121 1843.455078,2588.023193 1834.909912,2587.990479 
z"
        />
        <path
          fill="#395166"
          opacity="1.000000"
          stroke="none"
          d="
M1876.523193,2582.584961 
	C1881.646729,2578.288574 1888.533081,2578.509277 1895.466309,2576.340820 
	C1895.907593,2578.976562 1895.496582,2582.083740 1892.134399,2581.789795 
	C1886.968872,2581.338135 1882.197144,2584.266602 1876.523193,2582.584961 
z"
        />
        <path
          fill="#465F75"
          opacity="1.000000"
          stroke="none"
          d="
M1926.021973,2548.779785 
	C1937.008423,2547.084229 1947.608154,2549.542236 1959.016235,2551.873535 
	C1959.951172,2554.266602 1961.504272,2555.686279 1963.624878,2557.487793 
	C1956.572510,2561.866455 1948.736938,2560.108887 1941.139526,2559.157227 
	C1937.407959,2558.689697 1933.998291,2556.204590 1930.050415,2555.528076 
	C1927.085938,2555.019775 1926.103516,2552.408203 1926.021973,2548.779785 
z"
        />
        <path
          fill="#334D63"
          opacity="1.000000"
          stroke="none"
          d="
M2365.358398,2624.145020 
	C2364.868408,2628.118164 2363.045410,2629.295410 2360.041748,2624.798340 
	C2361.450928,2624.137695 2363.014893,2624.104736 2365.358398,2624.145020 
z"
        />
        <path
          fill="#3E5871"
          opacity="1.000000"
          stroke="none"
          d="
M2104.953125,2570.930176 
	C2105.035889,2570.886475 2104.870117,2570.973877 2104.953125,2570.930176 
z"
        />
        <path
          fill="#395166"
          opacity="1.000000"
          stroke="none"
          d="
M1858.558472,2542.068848 
	C1860.314697,2542.364502 1861.494385,2542.385254 1862.143677,2542.982910 
	C1862.891479,2543.670654 1862.665649,2545.136230 1861.674683,2545.377441 
	C1857.183472,2546.470947 1852.833740,2548.624756 1848.036377,2547.828857 
	C1843.183105,2547.023438 1838.361694,2546.025391 1832.546631,2544.927002 
	C1836.462891,2540.298584 1840.968384,2542.396240 1844.760498,2542.079834 
	C1849.060425,2541.720947 1853.416138,2542.030029 1858.558472,2542.068848 
z"
        />
        <path
          fill="#D3CBBF"
          opacity="1.000000"
          stroke="none"
          d="
M1848.793457,3129.916504 
	C1846.985352,3128.390137 1845.270508,3127.995361 1842.705811,3127.968994 
	C1837.906494,3127.414795 1833.757446,3128.797852 1829.603149,3127.415771 
	C1824.448608,3125.700928 1820.795166,3123.268311 1822.935303,3116.330566 
	C1826.566895,3112.995117 1830.103638,3113.702881 1834.344482,3114.916504 
	C1837.233765,3114.786865 1842.234497,3120.738525 1840.804199,3111.848633 
	C1840.602905,3110.274902 1840.730469,3109.379395 1841.394287,3107.921875 
	C1846.296753,3105.592529 1847.512695,3102.383789 1846.412964,3097.782959 
	C1845.269775,3093.000244 1846.075439,3089.092285 1850.156982,3085.282715 
	C1855.232544,3080.546143 1852.583740,3074.597168 1849.142334,3070.213623 
	C1845.853882,3066.024902 1841.692383,3062.871094 1847.846924,3058.029785 
	C1849.616577,3056.637695 1848.074097,3055.938232 1847.123169,3055.205566 
	C1845.349731,3053.839844 1844.012817,3051.368896 1845.495483,3050.094727 
	C1853.332886,3043.357910 1849.120605,3034.824219 1849.472900,3026.988525 
	C1849.563477,3024.973877 1850.860107,3023.241699 1850.384766,3020.583496 
	C1850.044434,3017.847412 1850.035767,3015.719238 1850.031982,3012.793457 
	C1850.279663,3002.733887 1851.766113,2992.459961 1855.505005,2985.331543 
	C1859.713013,2977.308594 1865.370117,2969.753906 1868.881470,2960.969971 
	C1870.051147,2958.043457 1873.759155,2955.591797 1877.248779,2957.593506 
	C1881.252197,2959.889648 1880.276245,2963.805664 1878.463379,2967.273926 
	C1877.241211,2969.611328 1875.193237,2971.750977 1876.371338,2974.629150 
	C1878.671997,2980.249268 1876.231445,2984.188477 1872.199829,2987.781738 
	C1868.174072,2991.369873 1866.987427,2996.200439 1866.913696,3001.333008 
	C1866.820679,3007.814453 1871.761230,3010.637939 1877.545044,3007.401367 
	C1879.268311,3006.437012 1881.485229,3005.484863 1882.312744,3003.875244 
	C1886.417725,2995.890625 1891.628784,2991.114258 1901.253418,2995.242432 
	C1902.782349,2995.898438 1905.453247,2994.289551 1904.576172,2992.839600 
	C1900.670532,2986.382080 1907.036255,2983.329102 1908.613647,2978.726318 
	C1910.953857,2971.897949 1913.698242,2965.473877 1912.737793,2957.555176 
	C1911.869507,2950.395996 1911.005981,2942.131104 1917.459717,2936.009277 
	C1918.354004,2935.161133 1918.666626,2936.011719 1918.146851,2935.491699 
	C1908.775391,2926.122803 1921.412598,2919.739258 1921.342651,2910.991455 
	C1921.552124,2909.832275 1921.666138,2909.374512 1922.024658,2908.253418 
	C1926.119629,2903.250000 1924.671387,2897.390869 1926.596924,2892.499756 
	C1927.762817,2889.538818 1927.756958,2885.199707 1932.163330,2885.321533 
	C1936.533081,2885.442383 1938.766235,2889.478271 1938.848267,2892.895996 
	C1939.077759,2902.469238 1943.115479,2911.664307 1941.350220,2921.841797 
	C1949.005981,2922.308838 1951.105103,2914.403809 1959.307617,2912.003906 
	C1962.010132,2922.685791 1953.506592,2928.485840 1947.174805,2934.552002 
	C1943.184082,2938.375244 1940.062866,2942.174316 1938.358643,2946.892334 
	C1935.127930,2955.836670 1930.295288,2963.828613 1925.198853,2971.695801 
	C1923.465942,2974.370850 1920.869995,2976.432617 1925.697266,2978.397705 
	C1926.633179,2978.778809 1927.402710,2980.480713 1926.672607,2980.667969 
	C1919.231445,2982.575928 1924.924316,2992.592041 1917.919800,2994.667969 
	C1917.508301,2994.789795 1917.048340,2996.201416 1917.102539,2996.235596 
	C1924.850464,3001.134766 1917.097534,3005.820068 1916.412109,3010.260254 
	C1915.765381,3014.450439 1913.908813,3017.234619 1919.060059,3019.887207 
	C1922.454346,3021.635498 1921.427368,3024.745117 1917.280396,3025.718262 
	C1913.099854,3026.698730 1908.822998,3027.267334 1904.589966,3028.026367 
	C1900.676270,3028.728027 1897.812866,3028.985840 1900.329712,3034.865967 
	C1903.069580,3041.267090 1898.166748,3049.430420 1890.153687,3051.282227 
	C1883.604980,3052.795166 1883.248779,3055.641357 1882.826660,3061.347900 
	C1882.411865,3066.955566 1882.339600,3072.580322 1881.982056,3079.074707 
	C1877.801880,3085.639648 1875.415039,3092.601318 1868.366577,3096.197754 
	C1865.834351,3097.489746 1864.277588,3101.331543 1863.027588,3104.324219 
	C1861.699585,3107.503906 1859.949463,3109.742432 1857.037720,3111.738037 
	C1852.273438,3115.002686 1848.548340,3119.184814 1851.502808,3125.842773 
	C1852.255127,3127.538086 1851.200439,3128.993896 1848.793457,3129.916504 
z"
        />
        <path
          fill="#DBD5C8"
          opacity="1.000000"
          stroke="none"
          d="
M1923.431396,2909.541016 
	C1924.443115,2914.849365 1922.924683,2919.032471 1920.004395,2922.982666 
	C1914.506226,2930.419434 1914.730225,2930.758789 1923.704590,2932.097168 
	C1924.656250,2932.239258 1925.566284,2932.660645 1926.381714,2932.916748 
	C1926.381714,2933.706055 1926.643921,2934.577148 1926.341064,2934.876709 
	C1923.527100,2937.659668 1917.265747,2936.412842 1916.662354,2940.778320 
	C1915.820312,2946.870117 1913.691650,2953.254883 1915.714966,2959.358154 
	C1917.324707,2964.214111 1917.375977,2968.185547 1914.031006,2972.263672 
	C1912.857544,2973.694336 1912.108643,2975.979004 1912.184326,2977.832275 
	C1912.396362,2983.015137 1909.591309,2985.704346 1905.483887,2986.876953 
	C1904.342773,2991.475830 1913.125977,2988.347900 1909.937256,2993.899658 
	C1906.657837,2999.609619 1903.651123,3000.615234 1897.888916,2997.918213 
	C1891.043945,2994.714844 1890.264160,2994.998779 1887.943115,3002.554688 
	C1887.206909,3004.951172 1886.351685,3007.364990 1883.765137,3007.527588 
	C1879.095703,3007.821289 1878.006104,3011.224854 1876.722778,3015.605957 
	C1872.729858,3012.378906 1869.511230,3008.618408 1864.039551,3010.019775 
	C1864.039551,3005.264648 1863.917114,3001.037598 1864.071411,2996.820557 
	C1864.253052,2991.862305 1866.263428,2987.389893 1870.550171,2985.063721 
	C1876.340698,2981.921387 1876.064331,2978.408936 1872.722656,2973.867188 
	C1872.151733,2973.091309 1871.828369,2972.133057 1871.388916,2971.255859 
	C1873.415405,2968.547852 1877.007568,2967.286865 1877.678467,2963.648193 
	C1877.989258,2961.962646 1878.086182,2960.159424 1876.464478,2959.187500 
	C1875.200073,2958.429199 1873.933716,2959.345215 1872.903198,2960.230957 
	C1870.043823,2962.688721 1867.891602,2965.403564 1867.948730,2969.552979 
	C1867.998901,2973.196289 1868.384644,2976.771240 1869.488159,2980.398438 
	C1868.132080,2980.923584 1867.213257,2981.641602 1866.397461,2981.538818 
	C1859.163208,2980.628174 1855.065552,2982.728516 1856.629272,2988.835938 
	C1858.760864,2997.161865 1854.044434,3002.641602 1851.901489,3009.262695 
	C1851.699341,3009.887451 1851.344116,3010.462646 1850.548584,3011.528320 
	C1846.134277,3010.471436 1843.277832,3008.209229 1843.979370,3002.689453 
	C1843.431030,3000.014648 1843.039307,2998.060547 1843.278564,2995.282959 
	C1847.246826,2983.687256 1846.175903,2973.453613 1839.703857,2963.806641 
	C1837.005737,2959.785156 1836.841675,2955.224121 1837.442871,2949.869141 
	C1840.589111,2936.410889 1839.153076,2923.799805 1836.305298,2910.524414 
	C1836.177124,2908.872803 1836.302612,2907.942139 1836.858643,2906.397949 
	C1838.803101,2903.851807 1841.481934,2902.292969 1839.501831,2899.112549 
	C1836.475342,2894.250977 1837.772339,2888.894531 1837.618652,2883.033203 
	C1837.184692,2877.283691 1833.811523,2873.364014 1831.849365,2868.203613 
	C1831.196289,2867.280273 1830.957520,2866.894043 1830.423584,2865.892334 
	C1829.107056,2861.798340 1827.550903,2858.515381 1826.277344,2854.404053 
	C1826.306519,2849.592773 1827.665649,2845.568115 1824.541992,2841.553223 
	C1821.014038,2837.018555 1823.816040,2832.226807 1826.157959,2827.263184 
	C1827.721558,2826.536377 1828.971313,2826.061035 1829.665161,2826.569092 
	C1841.850586,2835.489990 1853.468628,2845.024414 1861.382568,2858.207031 
	C1862.782593,2860.539062 1862.712036,2863.312744 1861.954834,2866.701172 
	C1854.067505,2877.686523 1858.699463,2888.811768 1860.281494,2899.357666 
	C1861.386963,2906.727295 1863.558105,2913.284180 1862.584839,2921.424072 
	C1861.577515,2929.848145 1863.703857,2939.599609 1867.999268,2947.963867 
	C1869.946167,2951.754639 1872.727905,2953.093994 1875.690308,2949.544922 
	C1885.313232,2938.017334 1897.898560,2928.018311 1897.303711,2910.071777 
	C1898.487671,2908.928467 1899.352173,2908.559326 1901.004150,2908.403320 
	C1908.564331,2914.851318 1915.793457,2910.184082 1923.431396,2909.541016 
z"
        />
        <path
          fill="#FBF5E5"
          opacity="1.000000"
          stroke="none"
          d="
M1845.497070,2995.511230 
	C1845.988770,2997.746338 1846.098877,2999.544189 1844.464600,3001.518555 
	C1838.635254,3001.361572 1836.643188,3004.986328 1834.404419,3008.925781 
	C1830.843750,3015.190674 1830.041992,3015.080078 1825.758667,3008.926270 
	C1825.194336,3008.115723 1824.797241,3006.803467 1823.823486,3007.029053 
	C1813.286865,3009.471924 1817.578979,3003.962402 1818.906006,2998.889893 
	C1819.662231,2995.999023 1818.050781,2992.468262 1817.417480,2989.242920 
	C1817.101685,2987.634521 1815.838867,2985.806641 1816.282837,2984.519043 
	C1819.610107,2974.869629 1812.532593,2970.173828 1806.582031,2964.306885 
	C1805.324097,2963.268311 1804.906494,2962.422852 1804.743652,2960.747803 
	C1805.972168,2955.409180 1805.436035,2950.153809 1809.637207,2946.618164 
	C1804.172485,2942.194336 1807.617920,2935.119385 1804.679932,2929.934326 
	C1804.026611,2928.781494 1802.802612,2927.080811 1803.270630,2926.322998 
	C1810.368774,2914.829346 1800.767578,2905.205322 1799.860229,2894.646973 
	C1799.411743,2889.426270 1797.437378,2884.181885 1801.708862,2879.494629 
	C1802.869629,2878.220947 1802.924805,2876.109131 1801.701050,2874.852539 
	C1792.152344,2865.049561 1796.303101,2852.359863 1794.666992,2840.958984 
	C1794.010498,2836.385986 1794.340942,2831.541260 1792.378296,2827.323730 
	C1786.142578,2813.922852 1783.715576,2799.537598 1781.224976,2785.199463 
	C1780.662598,2781.961670 1779.120117,2780.653320 1776.120361,2779.036133 
	C1775.009155,2777.788086 1774.613647,2776.922119 1774.291748,2775.271973 
	C1774.104492,2767.488770 1775.337524,2760.502686 1771.596069,2753.568604 
	C1768.926270,2748.621094 1773.600098,2744.479248 1776.742432,2739.942871 
	C1778.110718,2738.998047 1779.013184,2738.751953 1780.659912,2738.837402 
	C1784.620850,2741.535645 1785.822021,2745.977783 1790.123047,2748.548584 
	C1790.909912,2749.416748 1791.190918,2749.794922 1791.805542,2750.796875 
	C1799.905518,2777.616455 1809.361816,2803.273193 1817.333984,2829.376709 
	C1820.250122,2838.925537 1824.231934,2848.674805 1823.577393,2859.292480 
	C1823.322876,2863.421631 1823.928833,2867.938965 1827.213379,2871.393066 
	C1829.685547,2873.992676 1830.388428,2877.236328 1829.434082,2880.658203 
	C1828.354126,2884.530029 1829.531616,2887.709717 1831.568848,2890.969482 
	C1835.055542,2896.548340 1837.693726,2902.402588 1831.752808,2908.068115 
	C1829.537109,2910.181396 1830.747803,2911.252930 1832.298462,2912.829834 
	C1835.194458,2915.774902 1836.606201,2918.935303 1832.205078,2921.864990 
	C1831.593506,2922.271729 1831.035278,2922.486084 1830.879272,2923.031494 
	C1842.251831,2922.651123 1834.945435,2929.610840 1835.042969,2933.648438 
	C1835.163818,2938.653809 1838.225830,2943.136230 1837.454590,2948.954590 
	C1835.617798,2952.308350 1832.677490,2954.568115 1834.961548,2958.389648 
	C1835.609497,2959.473633 1835.774292,2961.049316 1835.505615,2962.293701 
	C1833.553101,2971.336670 1830.444824,2980.383545 1842.072998,2985.986572 
	C1844.771362,2987.286621 1839.695068,2993.267090 1845.497070,2995.511230 
z"
        />
        <path
          fill="#D1C9BA"
          opacity="1.000000"
          stroke="none"
          d="
M1711.381348,2931.563477 
	C1711.774292,2935.043457 1712.944702,2937.827393 1713.800781,2940.666016 
	C1715.456177,2946.154053 1716.274170,2951.573975 1713.436157,2956.983154 
	C1712.336426,2959.079102 1711.919922,2961.339111 1712.892578,2963.686035 
	C1716.668945,2972.796875 1715.219116,2982.308594 1714.725586,2991.675293 
	C1714.449341,2996.919678 1711.427979,3001.676758 1713.319702,3007.515381 
	C1714.467163,3011.057129 1708.764038,3011.668457 1707.114868,3014.461426 
	C1706.644775,3015.257568 1706.215820,3016.436523 1706.460938,3017.224365 
	C1707.034180,3019.065918 1708.325439,3018.302979 1709.487915,3017.657959 
	C1712.559082,3015.952637 1713.773682,3017.459961 1713.925049,3020.390137 
	C1714.044556,3022.702148 1714.214355,3025.065430 1713.885620,3027.336914 
	C1712.636963,3035.963867 1711.989746,3044.453125 1715.568359,3052.813965 
	C1716.964355,3056.075195 1713.832275,3059.100342 1714.007080,3063.222656 
	C1712.543701,3065.797363 1710.602539,3066.072754 1707.776855,3065.593750 
	C1703.549683,3063.977783 1703.210938,3058.686523 1698.029785,3058.258301 
	C1696.978516,3057.765381 1696.578613,3057.529541 1695.666748,3056.826660 
	C1693.116089,3053.801270 1693.365723,3050.223633 1691.390503,3047.681396 
	C1688.667114,3044.175781 1686.298706,3043.972168 1684.748291,3049.394775 
	C1684.492065,3050.866211 1684.268311,3051.679199 1683.726807,3053.020264 
	C1682.706055,3054.847656 1682.071289,3056.145996 1683.168945,3058.189941 
	C1683.800537,3059.933105 1683.852539,3061.156982 1683.472900,3063.009277 
	C1681.730591,3066.353516 1678.132446,3068.330566 1680.208252,3073.019775 
	C1681.386230,3079.292725 1679.862915,3084.246094 1676.088379,3089.297607 
	C1669.917725,3093.550293 1664.541870,3097.482422 1664.964111,3106.122070 
	C1663.353638,3108.394775 1661.698364,3109.315430 1658.833862,3109.142090 
	C1652.106323,3105.731934 1649.896240,3100.395996 1649.688843,3093.132324 
	C1649.655273,3084.882080 1652.161133,3077.877930 1655.399658,3071.478027 
	C1662.354370,3057.734131 1665.999512,3043.475098 1667.816406,3028.120850 
	C1669.864136,3010.814941 1673.106445,2993.401611 1681.500122,2977.616943 
	C1684.243408,2972.457764 1682.059692,2965.397461 1688.456421,2961.051758 
	C1696.462646,2959.595215 1698.015991,2964.948242 1699.556641,2970.153076 
	C1701.659668,2977.258545 1700.010376,2983.930664 1697.076782,2990.127686 
	C1702.258057,2983.154053 1705.643799,2968.477295 1703.427856,2957.610840 
	C1701.733398,2949.302002 1704.991577,2941.938965 1706.311646,2934.201416 
	C1706.658813,2932.166260 1708.448853,2930.908936 1711.381348,2931.563477 
z"
        />
        <path
          fill="#DAD3C3"
          opacity="1.000000"
          stroke="none"
          d="
M1806.434570,2959.419434 
	C1805.999390,2960.892334 1805.989014,2961.780273 1805.975342,2963.333984 
	C1804.321411,2964.359619 1802.229614,2964.786621 1802.890137,2966.944092 
	C1803.755859,2969.772217 1803.406128,2973.045410 1805.486206,2975.418457 
	C1807.128906,2977.291992 1806.576904,2978.526611 1805.449707,2980.573486 
	C1801.153442,2988.375488 1802.413574,2992.345459 1810.116455,2997.141357 
	C1811.523315,2998.017334 1813.860840,2999.066895 1813.041748,3000.600098 
	C1808.624756,3008.867188 1817.492920,3015.585449 1815.389160,3023.905273 
	C1814.614746,3026.967285 1815.099487,3030.268799 1812.372437,3032.570801 
	C1808.629639,3035.730469 1806.151978,3040.092285 1802.257935,3044.686279 
	C1800.293213,3040.591553 1801.656616,3036.846191 1797.551880,3034.733154 
	C1794.429199,3033.125732 1797.149292,3027.437012 1801.891602,3026.198242 
	C1804.992554,3025.387939 1809.957886,3026.012207 1808.903931,3021.403076 
	C1807.714111,3016.198975 1803.160645,3018.892578 1799.723022,3019.667725 
	C1796.249023,3020.450928 1793.355225,3019.881836 1791.053955,3016.533447 
	C1788.607788,3012.974121 1785.321777,3010.071777 1781.732178,3007.566162 
	C1780.595581,3006.772949 1779.926025,3005.391846 1780.630127,3004.070801 
	C1781.679688,3002.101074 1783.362183,3002.754883 1784.884033,3003.528809 
	C1786.067627,3004.130615 1787.176514,3004.878418 1788.356567,3005.488037 
	C1792.625488,3007.693359 1795.298218,3006.984131 1797.133301,3003.199219 
	C1799.114136,2999.113770 1797.038940,2995.919189 1792.393188,2996.124512 
	C1790.764282,2996.196777 1789.154175,2996.775391 1787.543579,2997.157227 
	C1784.328979,2997.918457 1780.493042,2999.421143 1778.733521,2995.535400 
	C1777.052490,2991.822510 1777.615356,2987.817627 1780.682983,2984.406250 
	C1785.840332,2978.670654 1785.743896,2978.583984 1787.289917,2972.986572 
	C1778.265625,2975.781738 1775.247192,2967.827393 1770.087402,2963.706543 
	C1768.518311,2962.453613 1767.296753,2959.661865 1769.152222,2957.386475 
	C1770.737793,2955.441650 1772.800415,2956.754883 1774.701904,2957.088867 
	C1775.256592,2957.186523 1775.899780,2956.780273 1777.437622,2956.333008 
	C1773.941040,2953.185303 1774.572388,2947.869873 1769.132690,2946.024414 
	C1763.863037,2944.236816 1763.812012,2943.391846 1768.817139,2940.158203 
	C1771.771484,2938.249023 1771.640747,2936.717773 1769.649292,2934.792480 
	C1766.136353,2931.396729 1765.135254,2927.455322 1766.672852,2922.955322 
	C1768.613159,2917.276855 1764.027100,2912.828125 1763.988281,2906.775879 
	C1762.135620,2903.436523 1762.161011,2901.150146 1765.714233,2899.162109 
	C1772.665405,2904.567139 1775.809326,2911.558838 1776.158691,2919.800293 
	C1776.432373,2926.252197 1779.358398,2930.700684 1785.741089,2932.962402 
	C1777.896606,2921.885498 1779.705811,2908.398193 1775.923462,2896.378662 
	C1775.007690,2893.468994 1774.383911,2890.598633 1775.492065,2887.621582 
	C1777.077393,2883.362793 1776.127319,2880.085938 1772.421631,2877.162354 
	C1767.818115,2873.530518 1767.429077,2868.844727 1770.890747,2863.295898 
	C1776.651123,2858.604980 1781.349731,2854.316406 1781.739380,2846.707275 
	C1781.943970,2842.711426 1784.026489,2838.637695 1789.037964,2838.726074 
	C1794.210449,2838.816895 1793.928833,2843.430908 1794.420410,2846.857178 
	C1795.025635,2851.074951 1795.291870,2855.330811 1795.378418,2859.640869 
	C1795.520996,2866.743652 1793.731689,2873.929688 1796.953735,2881.070801 
	C1798.678467,2884.893311 1797.383911,2889.729980 1795.111206,2893.508789 
	C1792.677979,2897.555176 1792.234619,2900.732666 1796.728394,2903.554688 
	C1802.452026,2907.149658 1802.979614,2912.427002 1801.297363,2918.427002 
	C1798.974487,2926.711670 1798.694946,2934.783691 1804.094849,2942.221191 
	C1807.753418,2947.260498 1802.878052,2953.799316 1806.434570,2959.419434 
z"
        />
        <path
          fill="#D8D1C0"
          opacity="1.000000"
          stroke="none"
          d="
M1704.744873,3063.907715 
	C1708.129761,3063.999023 1710.259399,3063.997803 1713.187256,3063.998535 
	C1713.985352,3064.000000 1714.013184,3063.985107 1714.027344,3063.979980 
	C1717.653687,3065.897217 1711.384033,3069.206299 1715.519775,3071.561035 
	C1715.995117,3072.000000 1716.000000,3072.000000 1715.997559,3072.000000 
	C1717.903442,3073.782959 1718.695923,3076.503418 1717.363892,3078.252930 
	C1711.479980,3085.981689 1713.421997,3095.943115 1709.636108,3104.327881 
	C1708.763550,3106.260254 1712.427368,3110.194580 1708.471680,3113.504395 
	C1707.427246,3115.714600 1705.710205,3117.248291 1707.553711,3119.653809 
	C1709.928833,3121.415527 1711.648560,3122.865234 1708.529541,3125.595703 
	C1707.994995,3125.990234 1708.000000,3126.000000 1707.996948,3125.995117 
	C1707.034790,3126.979980 1705.188965,3127.213623 1705.657959,3129.564941 
	C1707.095825,3130.353516 1708.191528,3130.707275 1709.646118,3131.525391 
	C1712.780640,3137.296875 1706.894043,3140.193115 1706.472290,3144.633301 
	C1706.283325,3146.623779 1701.488892,3149.589111 1707.974243,3149.974121 
	C1709.415771,3150.059570 1709.569214,3152.626465 1709.186035,3155.054443 
	C1707.624146,3157.681152 1705.705933,3158.258545 1702.732788,3158.104492 
	C1694.304321,3161.554199 1691.124756,3166.805176 1692.825317,3175.037109 
	C1693.627930,3178.922607 1693.638428,3180.974365 1689.501709,3182.611572 
	C1684.869629,3184.445068 1681.225586,3189.858643 1675.307861,3185.594238 
	C1672.113403,3183.292236 1671.658203,3174.497559 1674.988647,3172.184326 
	C1676.859375,3170.885498 1679.685913,3170.426758 1679.090088,3167.493652 
	C1678.431641,3164.252197 1675.470093,3164.209229 1672.794312,3163.957275 
	C1671.515747,3163.836670 1670.270996,3163.354004 1668.503052,3162.517578 
	C1666.077271,3158.497314 1667.935669,3155.484863 1669.534424,3151.763428 
	C1670.967896,3144.932129 1669.468872,3138.681641 1670.313965,3132.440186 
	C1670.770874,3129.065186 1670.689697,3125.783447 1674.072144,3123.792969 
	C1677.385620,3121.842285 1678.051880,3119.921631 1674.755249,3116.954346 
	C1671.617065,3114.130371 1673.074707,3111.045654 1676.506348,3110.125732 
	C1683.233643,3108.322754 1682.544067,3103.169434 1682.826416,3098.257568 
	C1683.486450,3086.776123 1687.726929,3076.216797 1691.453735,3065.551514 
	C1692.249146,3063.275391 1694.689697,3062.327637 1697.863037,3062.241211 
	C1700.262817,3062.548340 1701.785156,3063.475586 1704.744873,3063.907715 
z"
        />
        <path
          fill="#F6EEDE"
          opacity="1.000000"
          stroke="none"
          d="
M1752.515991,2910.449951 
	C1745.385498,2910.950684 1740.842529,2908.599121 1737.454346,2902.510254 
	C1732.910522,2894.345459 1733.011963,2893.547363 1742.615234,2891.764160 
	C1743.610352,2891.579590 1744.503662,2891.237549 1744.582031,2889.291016 
	C1741.923218,2887.097168 1738.859985,2887.981934 1735.834961,2889.093994 
	C1733.457886,2889.967285 1731.224609,2890.045898 1729.494873,2887.976807 
	C1727.694092,2885.823486 1728.149170,2883.739258 1730.120728,2881.860596 
	C1732.171753,2879.905762 1731.609741,2877.940186 1730.526123,2875.590332 
	C1727.660889,2869.377197 1725.158447,2863.048340 1729.283569,2856.286377 
	C1729.966431,2855.166992 1729.653809,2853.737549 1728.896362,2852.519043 
	C1724.239258,2845.027832 1723.024902,2841.988037 1722.057495,2835.280029 
	C1721.869141,2833.974121 1721.900757,2832.637939 1721.744751,2831.325684 
	C1721.572998,2829.879883 1720.740479,2828.788330 1719.305298,2828.628662 
	C1717.906006,2828.473145 1716.780884,2829.187256 1716.361328,2830.670166 
	C1716.091431,2831.624023 1716.030762,2832.690186 1715.555908,2833.523193 
	C1713.962524,2836.318359 1712.000977,2836.480957 1710.430298,2833.619385 
	C1709.024658,2831.058838 1708.675293,2827.869141 1705.094482,2826.074463 
	C1700.473511,2824.278564 1699.228516,2820.909180 1698.782959,2816.302734 
	C1701.034424,2811.255615 1704.610840,2811.972656 1708.946777,2813.013672 
	C1712.478638,2813.428223 1713.984253,2815.647949 1715.808960,2817.403564 
	C1713.839355,2811.905273 1713.839722,2805.952393 1711.164917,2800.000732 
	C1710.892334,2798.877197 1710.823486,2798.418701 1710.795898,2797.267090 
	C1711.162109,2795.209961 1711.865479,2794.036133 1713.079590,2792.377441 
	C1713.770264,2791.494385 1714.055908,2791.149658 1714.784668,2790.301758 
	C1716.891846,2788.303467 1718.013550,2786.242188 1720.741211,2784.991699 
	C1726.357910,2785.042236 1726.235107,2789.925049 1728.841431,2793.203125 
	C1729.374634,2794.747314 1729.473877,2795.671387 1729.332764,2797.293701 
	C1733.507812,2810.331543 1734.486206,2823.697266 1742.083252,2835.092285 
	C1746.337036,2841.472656 1748.177979,2849.457275 1750.458496,2856.742188 
	C1754.481934,2869.594482 1758.901489,2882.454102 1760.946655,2895.914551 
	C1761.584839,2900.115479 1760.920532,2903.061523 1756.586792,2905.513184 
	C1755.717163,2907.099609 1755.345581,2908.199463 1754.501587,2909.654297 
	C1753.691895,2910.306396 1753.354736,2910.603271 1752.515991,2910.449951 
z"
        />
        <path
          fill="#C4BDAE"
          opacity="1.000000"
          stroke="none"
          d="
M1699.472290,2815.529785 
	C1701.485352,2818.718018 1702.769409,2821.633545 1704.244141,2825.209717 
	C1705.376221,2833.349609 1709.180542,2840.325195 1708.229614,2848.165283 
	C1708.020142,2849.891357 1709.079224,2851.523682 1711.207520,2851.097900 
	C1715.908325,2850.157227 1716.379883,2852.815918 1715.543091,2856.214600 
	C1712.573486,2868.276611 1714.814575,2880.244629 1716.123413,2892.207275 
	C1716.512207,2895.759521 1716.605591,2898.735596 1715.474609,2902.475586 
	C1713.761353,2908.141846 1715.143188,2914.602783 1714.140869,2920.737061 
	C1713.609375,2923.990479 1713.281982,2927.250732 1712.085938,2931.184082 
	C1710.278320,2932.348633 1708.674316,2932.975342 1708.346680,2934.858643 
	C1707.405640,2940.270508 1709.234985,2946.250244 1704.944580,2950.991455 
	C1704.614380,2951.356201 1704.583984,2952.478271 1704.893311,2952.864502 
	C1711.618896,2961.258789 1705.312744,2970.113525 1705.495850,2978.688721 
	C1705.674072,2987.029785 1700.294922,2993.686768 1693.404541,2998.859863 
	C1690.500366,2995.061768 1693.117920,2991.905029 1694.599243,2988.843018 
	C1698.089111,2981.628662 1698.716797,2974.412598 1695.871826,2966.828857 
	C1694.976318,2964.441162 1693.807983,2962.766846 1690.536987,2962.100098 
	C1685.117798,2956.413086 1688.573853,2950.512695 1689.276978,2943.952637 
	C1690.348145,2939.825195 1692.288086,2936.846191 1693.157227,2932.715820 
	C1693.407227,2931.061768 1693.625977,2930.139648 1694.181763,2928.593262 
	C1696.969604,2925.145264 1697.092651,2921.504395 1698.053711,2917.468018 
	C1699.093750,2914.926514 1700.115723,2913.052246 1699.875244,2910.161133 
	C1703.944702,2898.336670 1702.461060,2886.997314 1701.099121,2874.943359 
	C1701.243286,2870.509521 1704.111328,2867.005127 1701.769531,2862.432373 
	C1697.055298,2848.224854 1697.192505,2834.050049 1695.690552,2819.366211 
	C1696.013672,2818.121094 1696.203003,2817.638672 1696.862061,2816.535400 
	C1697.802246,2815.695312 1698.272705,2815.476074 1699.472290,2815.529785 
z"
        />
        <path
          fill="#E1D9C6"
          opacity="1.000000"
          stroke="none"
          d="
M1836.644287,3117.896729 
	C1832.458984,3117.687500 1829.281860,3115.478516 1824.809448,3116.028564 
	C1822.019165,3117.694580 1819.661987,3117.075684 1819.321411,3115.029541 
	C1817.852661,3106.204834 1813.640015,3109.417969 1808.654541,3112.196289 
	C1806.895752,3110.585693 1805.330444,3110.192627 1803.656128,3109.881348 
	C1799.870361,3109.176514 1799.494385,3106.693604 1801.364136,3104.051270 
	C1803.551758,3100.960449 1804.630371,3097.508545 1805.485474,3093.989258 
	C1806.064819,3091.604492 1804.378174,3089.640137 1802.166382,3090.593506 
	C1798.531372,3092.161133 1795.176025,3093.958008 1791.010986,3093.007324 
	C1790.539307,3092.899658 1789.401978,3094.193848 1789.137451,3095.019043 
	C1788.649902,3096.539795 1789.815918,3097.101074 1791.060425,3097.851318 
	C1796.875366,3101.355713 1797.009155,3101.908203 1792.161133,3106.708740 
	C1789.772949,3109.074463 1787.592285,3113.605957 1783.253540,3110.826660 
	C1779.455688,3108.393555 1780.439331,3104.587891 1782.390259,3101.078125 
	C1783.345825,3099.358643 1784.532959,3097.339844 1783.013672,3095.525146 
	C1780.189941,3092.152344 1777.753052,3096.693604 1774.946411,3096.217285 
	C1773.449097,3093.811768 1774.068481,3091.831299 1776.124756,3090.127441 
	C1777.644043,3088.868164 1779.521362,3087.760498 1778.849609,3085.333740 
	C1778.396118,3083.696045 1775.543823,3081.975342 1778.577026,3080.526611 
	C1780.640991,3079.540527 1783.822510,3079.312256 1784.950073,3081.552246 
	C1787.166626,3085.955078 1790.008667,3086.871094 1793.551147,3083.888428 
	C1796.674194,3081.259521 1798.410767,3077.501221 1796.959595,3073.328125 
	C1796.261963,3071.321777 1792.868286,3069.467041 1795.068970,3067.416504 
	C1798.296631,3064.408691 1796.959106,3058.386963 1802.076416,3057.106689 
	C1806.839966,3055.915527 1807.927368,3052.821045 1808.440430,3048.395264 
	C1808.849487,3044.867920 1810.425415,3041.208008 1814.743896,3040.291504 
	C1816.028076,3040.018799 1817.497192,3039.937500 1818.473877,3041.059814 
	C1819.494751,3042.233154 1819.012329,3043.448486 1818.222046,3044.628906 
	C1815.838379,3048.188965 1812.693481,3051.562012 1816.665649,3056.141113 
	C1817.986084,3057.663330 1816.927490,3059.069580 1815.145630,3059.984863 
	C1811.322266,3061.948730 1812.998169,3063.080566 1815.801147,3064.248535 
	C1819.326294,3065.717529 1820.936401,3067.984375 1816.849487,3070.558838 
	C1812.250244,3073.455566 1817.875488,3079.670654 1812.360962,3082.758789 
	C1810.741333,3083.665771 1818.160645,3082.695801 1816.567871,3086.838867 
	C1815.852539,3088.699219 1811.774048,3088.294189 1812.609375,3091.683838 
	C1813.109741,3093.713867 1814.923218,3093.767578 1817.286133,3094.027832 
	C1820.430786,3097.641602 1824.088989,3097.345459 1827.806396,3097.519775 
	C1834.369263,3097.827393 1840.419434,3099.365234 1842.339966,3107.431641 
	C1842.052246,3108.924072 1842.052368,3109.792969 1842.057129,3111.313965 
	C1841.727295,3114.933594 1839.861816,3116.646729 1836.644287,3117.896729 
z"
        />
        <path
          fill="#DBD5C8"
          opacity="1.000000"
          stroke="none"
          d="
M1807.765503,3112.423340 
	C1810.361938,3110.666992 1809.861938,3107.248047 1812.795898,3106.052246 
	C1818.158447,3103.866699 1821.348755,3105.829346 1820.997803,3111.890137 
	C1820.889526,3113.758789 1820.856934,3115.066650 1823.401001,3115.905273 
	C1824.837769,3123.160400 1827.918091,3125.705322 1836.031006,3125.581543 
	C1838.074463,3125.550537 1840.363525,3123.406006 1841.888184,3127.306641 
	C1841.057251,3128.767090 1840.123779,3129.620850 1839.116211,3130.375977 
	C1836.927979,3132.017334 1832.662598,3132.315674 1833.196045,3135.734863 
	C1833.736206,3139.196045 1836.570923,3141.766602 1841.354858,3142.005615 
	C1839.502563,3146.484863 1840.098877,3152.384521 1835.477173,3155.961182 
	C1834.109131,3157.019775 1834.709106,3158.943115 1835.115967,3160.575439 
	C1835.844604,3163.497803 1834.641113,3166.529541 1832.191162,3167.158203 
	C1823.143555,3169.479736 1823.164307,3175.144531 1825.635010,3182.234375 
	C1825.901001,3182.998047 1825.253662,3184.080322 1824.510132,3185.497803 
	C1821.033203,3187.897461 1817.872925,3188.098145 1813.991211,3186.732666 
	C1811.753052,3183.448730 1811.310669,3179.496582 1806.484375,3178.431152 
	C1804.476318,3178.035400 1802.991821,3178.028320 1800.765137,3178.020264 
	C1799.169189,3178.562012 1797.979492,3179.160645 1797.268433,3178.689209 
	C1793.022583,3175.873535 1784.052734,3177.057129 1785.598877,3169.453125 
	C1786.895020,3163.078857 1793.743042,3166.315430 1798.393311,3166.617432 
	C1801.034546,3166.788818 1803.661621,3166.692871 1806.253784,3166.227051 
	C1808.691284,3165.789062 1810.793823,3164.561523 1810.849854,3161.863770 
	C1810.903076,3159.294922 1808.412964,3159.168213 1806.616333,3158.619629 
	C1802.894653,3157.482910 1799.585327,3156.122803 1801.587158,3150.592041 
	C1806.067139,3150.265869 1810.113892,3149.969727 1812.583130,3146.284180 
	C1814.894897,3142.833984 1819.727783,3143.014404 1821.865479,3138.678223 
	C1822.792725,3137.795898 1823.044312,3137.311279 1822.377441,3136.272949 
	C1821.101440,3135.693848 1819.564697,3135.584473 1819.400635,3135.051758 
	C1817.205811,3127.931641 1811.692017,3127.522217 1804.838379,3128.010742 
	C1800.167847,3123.527832 1800.995972,3119.312012 1805.365723,3114.630859 
	C1806.618774,3113.759766 1807.129028,3113.375244 1807.765503,3112.423340 
z"
        />
        <path
          fill="#F4EEDC"
          opacity="1.000000"
          stroke="none"
          d="
M1751.618530,2910.665527 
	C1752.350220,2910.000732 1752.685913,2910.001221 1753.525391,2910.005859 
	C1759.112671,2915.898682 1756.357300,2924.385742 1760.797729,2930.509033 
	C1761.126221,2930.962158 1761.228516,2931.932373 1760.968506,2932.404541 
	C1758.169434,2937.485107 1754.902344,2941.617188 1757.682739,2948.779785 
	C1759.252808,2952.824951 1758.164062,2959.841064 1756.681885,2964.545654 
	C1754.885742,2970.247559 1754.248413,2974.323242 1757.418823,2979.566406 
	C1758.499512,2981.353760 1755.647095,2985.146484 1760.010376,2986.641602 
	C1761.515503,2987.157715 1760.967285,2989.299316 1759.509155,2989.510742 
	C1752.481323,2990.528320 1755.335693,2994.646240 1756.601929,2998.248779 
	C1757.120483,2999.724365 1759.172363,3000.468018 1758.450439,3003.391113 
	C1753.555664,3000.105957 1748.651367,3005.210938 1743.409424,3003.185547 
	C1746.883789,2995.505371 1747.064575,2988.265137 1742.689697,2981.062012 
	C1737.957275,2973.270264 1739.530273,2965.356201 1743.408325,2957.115723 
	C1738.829468,2956.359131 1735.387939,2959.479248 1730.828857,2959.003662 
	C1731.563110,2953.933350 1738.524414,2951.352051 1735.862915,2945.810303 
	C1733.519531,2940.931396 1735.770752,2936.062744 1735.291382,2931.260986 
	C1735.036133,2928.705078 1734.948364,2927.033203 1737.132324,2925.242676 
	C1741.139771,2921.957520 1739.246216,2919.682861 1735.396484,2918.009521 
	C1734.542969,2917.638672 1733.834717,2916.933838 1732.952393,2916.306396 
	C1735.016235,2912.658936 1738.362061,2914.363525 1741.070557,2914.056641 
	C1744.616211,2913.654785 1748.526489,2914.842285 1751.618530,2910.665527 
z"
        />
        <path
          fill="#DBD5C8"
          opacity="1.000000"
          stroke="none"
          d="
M1842.769775,3108.050781 
	C1837.339233,3100.448975 1830.580688,3098.805420 1822.243408,3100.854492 
	C1817.010864,3102.140625 1816.943481,3101.866211 1817.922852,3094.757568 
	C1819.253296,3093.667236 1821.020264,3093.661133 1821.655029,3092.810303 
	C1825.535156,3087.610107 1831.107300,3085.047119 1837.551025,3082.756592 
	C1834.909424,3078.940186 1832.112549,3080.329102 1829.479492,3081.198242 
	C1826.594238,3082.150391 1824.019531,3081.736084 1822.803955,3078.838135 
	C1821.338745,3075.344971 1823.205688,3073.225342 1827.269165,3072.088135 
	C1829.157104,3070.454590 1830.062744,3068.433838 1832.168701,3068.130127 
	C1837.000732,3067.434082 1837.873535,3065.056396 1836.250122,3060.677002 
	C1834.795532,3056.752930 1836.944214,3053.073730 1838.029053,3048.689941 
	C1836.425903,3044.986328 1834.862305,3041.661133 1836.908936,3038.577881 
	C1840.851074,3032.638184 1843.178833,3025.534912 1849.620361,3020.510010 
	C1852.291016,3020.398193 1853.650024,3021.315430 1853.003296,3024.005371 
	C1851.755981,3029.194336 1850.900269,3034.143799 1854.729980,3039.075928 
	C1857.516235,3042.664551 1853.894531,3045.019775 1851.381714,3047.155029 
	C1849.716797,3048.569580 1846.487549,3049.064453 1847.255859,3051.997070 
	C1848.047974,3055.020996 1851.145752,3053.632812 1853.264404,3054.039062 
	C1854.537476,3054.282959 1855.898682,3054.213135 1856.951050,3056.321777 
	C1855.491333,3058.811279 1852.798218,3059.629639 1849.868408,3060.059814 
	C1848.079224,3060.322510 1846.622803,3061.043945 1846.526367,3063.036133 
	C1846.456909,3064.468750 1847.357666,3065.699219 1848.656006,3066.106445 
	C1854.146851,3067.829102 1857.409790,3070.520508 1854.482300,3076.880371 
	C1854.161743,3077.576904 1855.492188,3079.167236 1856.249634,3080.202148 
	C1859.112549,3084.114990 1857.373657,3086.828369 1853.501709,3087.603027 
	C1847.857788,3088.732422 1847.532837,3091.679688 1849.014404,3096.251709 
	C1849.714844,3098.413574 1849.612305,3100.823486 1850.005127,3103.098633 
	C1851.024536,3109.003418 1847.519287,3108.849609 1842.769775,3108.050781 
z"
        />
        <path
          fill="#E1D9C6"
          opacity="1.000000"
          stroke="none"
          d="
M1806.290039,3178.641602 
	C1810.661377,3177.674805 1814.565430,3178.301758 1814.033081,3185.287109 
	C1816.282471,3188.816895 1814.597900,3191.007324 1812.792603,3192.829834 
	C1810.571899,3195.071533 1809.571655,3197.544922 1809.960449,3201.316406 
	C1809.961060,3202.883301 1809.968384,3203.764404 1809.972290,3205.305664 
	C1804.994873,3208.868652 1797.772339,3210.641357 1795.604492,3214.954346 
	C1792.764282,3220.605469 1788.607910,3226.092773 1787.863403,3232.984375 
	C1787.175415,3239.352783 1786.673218,3239.298584 1778.736206,3238.107178 
	C1777.232422,3234.498535 1776.309204,3231.128906 1777.624146,3227.336182 
	C1778.569214,3224.610840 1778.797729,3221.164062 1776.951172,3218.616943 
	C1774.435913,3215.147217 1776.406494,3214.325439 1779.169556,3213.062500 
	C1781.111084,3212.174805 1782.377930,3210.217041 1779.908325,3208.478271 
	C1774.708862,3204.817383 1779.403564,3204.485840 1781.509644,3204.214844 
	C1785.121216,3203.750000 1788.825684,3204.005127 1792.491089,3203.962158 
	C1794.134155,3203.942871 1795.685547,3203.732422 1797.264404,3202.153320 
	C1794.464966,3199.127686 1791.586182,3200.433350 1788.789917,3200.509766 
	C1784.202759,3200.635254 1778.895996,3192.359863 1780.723145,3188.145020 
	C1781.729858,3185.822510 1784.103760,3185.617920 1785.820190,3186.446045 
	C1792.862305,3189.842773 1799.591187,3185.896973 1806.384277,3185.507080 
	C1812.007568,3185.184326 1807.685303,3181.482666 1806.290039,3178.641602 
z"
        />
        <path
          fill="#EEE7D5"
          opacity="1.000000"
          stroke="none"
          d="
M1702.043213,3158.651855 
	C1703.080078,3156.311768 1704.790283,3155.994873 1707.293701,3155.945557 
	C1715.770630,3148.895508 1724.761719,3144.003418 1735.416870,3141.532715 
	C1737.681519,3143.118164 1737.976074,3144.827393 1737.992432,3147.329102 
	C1734.690674,3152.883301 1729.980347,3156.974609 1729.627686,3163.522461 
	C1729.565918,3164.668945 1728.584717,3166.024902 1727.638916,3166.822998 
	C1721.197754,3172.257324 1716.728149,3179.130615 1712.881958,3186.492432 
	C1711.280518,3189.557861 1709.385010,3192.282715 1705.324585,3191.839355 
	C1702.950562,3191.580322 1700.512451,3190.897217 1699.322144,3188.772461 
	C1698.049683,3186.500488 1700.138672,3185.172363 1701.703003,3184.008789 
	C1703.882446,3182.388184 1704.270264,3180.070312 1703.931396,3177.754883 
	C1703.036011,3171.635986 1701.722534,3165.570801 1702.043213,3158.651855 
z"
        />
        <path
          fill="#E6E1D0"
          opacity="1.000000"
          stroke="none"
          d="
M1756.874512,2906.000000 
	C1759.150024,2901.596191 1757.287842,2897.247314 1757.017334,2892.475586 
	C1756.399536,2881.583984 1750.296021,2872.530029 1749.058472,2862.094482 
	C1748.247437,2855.254150 1746.428955,2849.158691 1739.157593,2847.160400 
	C1738.775391,2843.657471 1743.266968,2843.974365 1742.707886,2842.619385 
	C1741.124634,2838.781250 1740.365967,2833.684814 1736.409058,2831.901855 
	C1731.338013,2829.616943 1732.196289,2826.932373 1732.772705,2822.527588 
	C1733.243164,2818.932617 1735.743408,2812.708252 1728.186523,2811.586914 
	C1726.771851,2811.376953 1726.256226,2809.515137 1727.166870,2808.371094 
	C1730.493652,2804.191406 1726.209473,2803.778564 1724.341309,2801.893311 
	C1721.105713,2798.627686 1724.191406,2798.406250 1727.182129,2797.965332 
	C1731.387207,2797.680420 1733.419312,2799.885498 1734.748901,2802.658447 
	C1747.151245,2828.519531 1762.449707,2853.314453 1764.602173,2883.699219 
	C1763.378052,2888.826172 1765.624023,2891.991699 1768.701172,2895.580566 
	C1769.177490,2897.111816 1769.112305,2898.013916 1768.385132,2899.425293 
	C1765.108154,2900.226562 1763.989746,2901.939209 1763.981201,2905.305176 
	C1761.857422,2906.001953 1759.758789,2906.000977 1756.874512,2906.000000 
z"
        />
        <path
          fill="#E1D9C6"
          opacity="1.000000"
          stroke="none"
          d="
M1801.324219,3150.326172 
	C1801.657349,3155.029785 1804.530029,3156.186279 1808.837769,3156.045166 
	C1812.122192,3155.937256 1815.932251,3155.632324 1815.757446,3160.699951 
	C1815.626465,3164.495605 1809.469849,3171.252930 1806.588257,3170.242920 
	C1802.192383,3168.702148 1797.090576,3172.941895 1793.208374,3168.289307 
	C1792.780884,3167.776367 1791.303711,3168.059570 1790.320312,3168.109863 
	C1789.011597,3168.176270 1787.624756,3168.292480 1787.136597,3169.839111 
	C1786.644653,3171.396973 1787.619995,3172.470459 1788.787231,3173.033447 
	C1792.049316,3174.607178 1795.410278,3175.976074 1799.377686,3177.722656 
	C1799.624756,3180.795410 1797.792969,3182.886719 1795.391968,3181.256592 
	C1791.006592,3178.279053 1787.408447,3177.690674 1782.927124,3181.076172 
	C1780.746460,3182.724121 1777.885986,3181.393555 1778.523560,3178.246826 
	C1780.198486,3169.980713 1776.148804,3162.838135 1774.641602,3155.214600 
	C1773.776489,3150.839111 1773.714111,3148.479980 1779.688965,3149.082764 
	C1783.188721,3149.435791 1786.814331,3149.028809 1790.202271,3150.360352 
	C1793.687134,3151.729736 1797.102417,3152.751953 1801.324219,3150.326172 
z"
        />
        <path
          fill="#E1D9C6"
          opacity="1.000000"
          stroke="none"
          d="
M1736.191406,3141.438965 
	C1733.927612,3142.122559 1732.159058,3142.896240 1730.673340,3144.369873 
	C1724.723389,3150.272217 1716.608032,3152.220703 1708.716187,3155.940186 
	C1707.801392,3154.650635 1708.089966,3153.006592 1707.400391,3152.152344 
	C1703.378906,3147.171143 1704.142090,3147.143799 1703.753906,3143.512939 
	C1703.187622,3138.215820 1707.230713,3136.459961 1709.850220,3132.702637 
	C1717.615845,3126.851562 1728.088257,3128.544434 1734.952759,3121.542725 
	C1735.613892,3120.867920 1736.774170,3121.124756 1737.643311,3121.788330 
	C1740.178711,3123.723877 1742.731445,3125.636230 1747.317017,3129.097168 
	C1735.688477,3128.180908 1727.532837,3132.962891 1718.523804,3133.969238 
	C1716.865967,3134.154297 1714.800659,3134.495117 1715.051270,3136.562012 
	C1715.328003,3138.843262 1717.217651,3139.671143 1719.517944,3139.107910 
	C1722.398193,3138.402344 1725.245605,3137.316162 1728.163452,3137.075439 
	C1731.408813,3136.807861 1735.363159,3135.944336 1736.191406,3141.438965 
z"
        />
        <path
          fill="#E1D9C6"
          opacity="1.000000"
          stroke="none"
          d="
M1746.725952,3231.905762 
	C1742.083252,3232.145508 1739.667969,3230.756592 1740.126709,3226.345947 
	C1740.705078,3220.785645 1745.032593,3222.364014 1749.174927,3222.000488 
	C1751.533203,3220.535889 1753.261353,3219.108887 1750.484375,3216.484619 
	C1747.393311,3216.002441 1744.755859,3216.053711 1742.128418,3215.913330 
	C1738.966553,3215.744385 1738.172852,3214.069824 1739.737061,3210.635986 
	C1750.803955,3209.937500 1750.803955,3209.937500 1760.865234,3199.897705 
	C1759.269775,3208.994385 1764.270264,3215.242188 1766.897461,3222.956299 
	C1759.532837,3224.035156 1754.978638,3230.927734 1746.725952,3231.905762 
z"
        />
        <path
          fill="#E1D9C6"
          opacity="1.000000"
          stroke="none"
          d="
M1708.714722,3113.902832 
	C1709.133057,3106.953125 1709.133057,3106.953125 1699.071167,3104.654053 
	C1701.375366,3099.307373 1706.389038,3104.124512 1709.276611,3101.790283 
	C1711.625610,3096.818604 1706.734741,3092.895264 1707.142334,3088.642578 
	C1707.514893,3084.753174 1714.551514,3084.651367 1714.561157,3079.283936 
	C1714.564575,3077.368652 1715.466553,3075.455078 1715.976807,3072.770508 
	C1717.663086,3072.326172 1719.323853,3072.823730 1719.650513,3074.752197 
	C1720.192993,3077.955078 1720.076172,3081.203613 1724.087769,3082.750977 
	C1726.136719,3083.541016 1725.114746,3085.782471 1723.729004,3086.703125 
	C1721.207642,3088.377930 1717.262329,3088.624512 1720.608276,3093.507812 
	C1722.034668,3095.589600 1718.057129,3096.730469 1716.672241,3098.408691 
	C1714.328735,3101.248535 1712.570190,3103.782471 1717.267090,3106.183350 
	C1720.648926,3107.911621 1719.219971,3109.979736 1716.862793,3111.196777 
	C1714.553101,3112.389160 1711.926514,3112.967529 1708.714722,3113.902832 
z"
        />
        <path
          fill="#E1D9C6"
          opacity="1.000000"
          stroke="none"
          d="
M1727.289307,3204.001465 
	C1726.185547,3209.939453 1725.743408,3215.216309 1733.842773,3216.646240 
	C1734.758667,3216.807617 1735.424438,3217.776855 1735.378174,3218.920166 
	C1735.316528,3220.443604 1734.447754,3221.600342 1733.053101,3221.623291 
	C1726.389160,3221.733887 1719.942993,3222.564453 1713.807007,3225.472412 
	C1712.131104,3226.266602 1710.532837,3224.810791 1710.157837,3223.044189 
	C1709.600464,3220.419678 1706.844482,3218.827393 1706.981445,3215.350098 
	C1710.232666,3213.343262 1715.266968,3213.568359 1717.668701,3208.612793 
	C1720.235474,3205.326172 1723.173462,3204.165283 1727.289307,3204.001465 
z"
        />
        <path
          fill="#E1D9C6"
          opacity="1.000000"
          stroke="none"
          d="
M1837.348511,3048.295898 
	C1839.597046,3048.361328 1842.198486,3049.203613 1840.972046,3050.783936 
	C1835.823608,3057.417725 1838.531982,3062.956787 1843.696411,3068.305908 
	C1838.532349,3071.241943 1833.428467,3068.743164 1828.701660,3071.679688 
	C1827.910889,3069.362305 1827.989624,3066.802979 1826.908813,3064.215820 
	C1823.710327,3056.559814 1824.709473,3054.330322 1832.257690,3050.811768 
	C1833.758057,3050.112305 1835.221436,3049.333740 1837.348511,3048.295898 
z"
        />
        <path
          fill="#E1D9C6"
          opacity="1.000000"
          stroke="none"
          d="
M1747.694702,3240.569336 
	C1750.267944,3240.137695 1752.713379,3239.540527 1754.683228,3241.072510 
	C1756.588623,3242.554688 1757.823120,3244.695801 1757.450806,3247.083008 
	C1756.976685,3250.122314 1754.261841,3250.009766 1751.980713,3249.943848 
	C1745.527710,3249.758301 1739.006470,3247.896484 1732.723511,3251.106934 
	C1731.765991,3251.596191 1730.915649,3250.625000 1730.604980,3249.615479 
	C1730.294067,3248.604980 1730.433716,3247.411865 1731.400757,3247.020264 
	C1735.868774,3245.212158 1739.207642,3243.218262 1735.066040,3237.847412 
	C1734.358521,3236.929688 1735.706665,3235.331299 1737.057129,3234.952393 
	C1739.036621,3234.396729 1739.869263,3236.162598 1741.490234,3237.636963 
	C1742.163940,3239.785889 1741.513550,3241.975098 1743.569214,3242.986328 
	C1745.341675,3243.858398 1746.367920,3242.299561 1747.694702,3240.569336 
z"
        />
        <path
          fill="#E1D9C6"
          opacity="1.000000"
          stroke="none"
          d="
M1805.241943,3114.015625 
	C1805.212158,3118.113525 1803.370605,3122.057129 1804.003906,3127.204590 
	C1803.810425,3129.795654 1803.262817,3131.325928 1800.630127,3131.901855 
	C1799.679321,3130.401611 1799.695801,3128.631104 1798.982300,3127.233154 
	C1794.775024,3118.989746 1795.323975,3117.552979 1805.241943,3114.015625 
z"
        />
        <path
          fill="#E1D9C6"
          opacity="1.000000"
          stroke="none"
          d="
M1760.039673,3124.094727 
	C1762.800049,3124.050781 1764.795654,3124.182617 1766.188477,3125.791992 
	C1767.056396,3126.794678 1767.655640,3128.358643 1766.152100,3129.086182 
	C1762.706909,3130.753418 1759.203735,3132.596191 1755.514648,3133.373291 
	C1752.459106,3134.016602 1751.813354,3131.217529 1752.456055,3128.790039 
	C1753.339722,3125.453369 1756.392578,3124.824219 1760.039673,3124.094727 
z"
        />
        <path
          fill="#E1D9C6"
          opacity="1.000000"
          stroke="none"
          d="
M1752.546875,3116.808594 
	C1747.937134,3120.935059 1744.515381,3118.770752 1740.737305,3116.431396 
	C1747.152710,3110.178955 1749.439087,3109.463623 1754.066406,3112.692383 
	C1756.179077,3114.166504 1754.323853,3115.393799 1752.546875,3116.808594 
z"
        />
        <path
          fill="#E1D9C6"
          opacity="1.000000"
          stroke="none"
          d="
M1765.956055,3155.049316 
	C1768.086060,3154.690674 1769.595459,3155.281006 1769.042236,3156.799316 
	C1767.760132,3160.318848 1758.995117,3164.405029 1755.766113,3162.990723 
	C1754.002441,3162.218018 1754.000732,3160.349121 1755.326172,3158.907959 
	C1758.003296,3155.996826 1761.911865,3156.312988 1765.956055,3155.049316 
z"
        />
        <path
          fill="#E1D9C6"
          opacity="1.000000"
          stroke="none"
          d="
M1767.875366,3107.621338 
	C1768.813232,3105.313965 1769.791260,3104.073242 1771.830566,3104.701904 
	C1773.317627,3105.160156 1773.759766,3106.424316 1773.774536,3107.788330 
	C1773.785034,3108.756104 1773.676392,3109.795410 1773.322876,3110.684326 
	C1771.876221,3114.323730 1767.500244,3116.945801 1765.772339,3115.085693 
	C1763.095947,3112.204346 1767.272827,3110.659424 1767.875366,3107.621338 
z"
        />
        <path
          fill="#E1D9C6"
          opacity="1.000000"
          stroke="none"
          d="
M1716.121948,3071.338867 
	C1714.377808,3072.183838 1712.749390,3072.301270 1711.146973,3072.572998 
	C1709.919067,3072.781250 1708.749146,3073.737061 1707.057983,3072.369629 
	C1707.530762,3068.502441 1713.099976,3068.982666 1713.912720,3064.640625 
	C1715.025635,3063.991455 1716.035034,3063.875000 1716.987793,3064.056641 
	C1718.233643,3064.294189 1720.139893,3064.425293 1720.519043,3065.205811 
	C1721.554443,3067.337646 1719.278076,3067.593018 1718.036621,3068.464355 
	C1717.290527,3068.988281 1716.834961,3069.925781 1716.121948,3071.338867 
z"
        />
        <path
          fill="#E1D9C6"
          opacity="1.000000"
          stroke="none"
          d="
M1766.501709,3243.559570 
	C1762.871460,3241.548096 1761.081543,3238.070068 1758.513184,3234.396240 
	C1759.116577,3232.665283 1760.905396,3231.435059 1762.110596,3232.714844 
	C1764.791260,3235.561279 1769.080811,3237.933838 1766.501709,3243.559570 
z"
        />
        <path
          fill="#E1D9C6"
          opacity="1.000000"
          stroke="none"
          d="
M1708.725098,3125.980469 
	C1708.669189,3124.368652 1709.561890,3122.764404 1708.190186,3120.503906 
	C1708.320312,3120.009521 1708.653442,3119.962646 1708.969482,3120.016357 
	C1711.051025,3120.368896 1714.294800,3119.033203 1714.771362,3121.972656 
	C1715.346802,3125.520752 1712.086792,3125.724609 1708.725098,3125.980469 
z"
        />
        <path
          fill="#E1D9C6"
          opacity="1.000000"
          stroke="none"
          d="
M1792.843994,3055.189941 
	C1793.755737,3057.377686 1793.701294,3059.025879 1791.434082,3059.055176 
	C1790.098999,3059.072510 1788.913452,3057.891602 1788.953979,3056.554688 
	C1789.021729,3054.310059 1790.659912,3054.240479 1792.843994,3055.189941 
z"
        />
        <path
          fill="#E1D9C6"
          opacity="1.000000"
          stroke="none"
          d="
M1763.746216,3093.686768 
	C1765.416504,3092.579590 1766.820923,3092.408447 1767.063110,3094.095947 
	C1767.327515,3095.937744 1766.024658,3097.251221 1764.178467,3096.999512 
	C1762.481567,3096.768311 1762.651733,3095.364502 1763.746216,3093.686768 
z"
        />
        <path
          fill="#E1D9C6"
          opacity="1.000000"
          stroke="none"
          d="
M1706.223999,3129.486328 
	C1704.258911,3129.780762 1701.375854,3130.423828 1701.393921,3128.213867 
	C1701.419312,3125.083740 1704.627197,3126.390625 1707.274780,3125.993408 
	C1707.478638,3126.984375 1706.963257,3127.978271 1706.223999,3129.486328 
z"
        />
        <path
          fill="#E1D9C6"
          opacity="1.000000"
          stroke="none"
          d="
M1822.000000,3136.500000 
	C1822.798462,3134.997559 1823.918701,3134.006104 1824.814087,3135.447754 
	C1825.657227,3136.805664 1824.321045,3137.488281 1822.548584,3137.923096 
	C1822.000000,3138.000000 1822.000000,3137.000000 1822.000000,3136.500000 
z"
        />
        <path
          fill="#E1D9C6"
          opacity="1.000000"
          stroke="none"
          d="
M1739.896973,3207.369141 
	C1738.208252,3207.804443 1736.673950,3207.260010 1736.099854,3204.621094 
	C1737.786987,3204.186523 1739.324219,3204.727783 1739.896973,3207.369141 
z"
        />
        <path
          fill="#DAD3C3"
          opacity="1.000000"
          stroke="none"
          d="
M1809.046143,2989.080566 
	C1808.958130,2989.129150 1809.134033,2989.031982 1809.046143,2989.080566 
z"
        />
        <path
          fill="#E1D9C6"
          opacity="1.000000"
          stroke="none"
          d="
M1771.073730,3230.959961 
	C1771.119263,3231.044678 1771.028076,3230.875000 1771.073730,3230.959961 
z"
        />
        <path
          fill="#E1D9C6"
          opacity="1.000000"
          stroke="none"
          d="
M1799.757568,3132.102051 
	C1799.838379,3132.068115 1799.676880,3132.135986 1799.757568,3132.102051 
z"
        />
        <path
          fill="#E1D9C6"
          opacity="1.000000"
          stroke="none"
          d="
M1719.992798,3237.594482 
	C1722.881348,3236.633301 1725.077271,3235.608643 1727.446655,3236.439209 
	C1728.842529,3236.928955 1729.506958,3238.105713 1728.706543,3239.566895 
	C1726.399048,3243.779053 1719.556274,3245.382324 1715.611084,3242.622803 
	C1714.536865,3241.870850 1713.643066,3240.849365 1711.899658,3240.914307 
	C1709.181274,3241.766357 1710.133179,3245.655273 1707.549316,3246.922363 
	C1706.272095,3247.548096 1704.981201,3248.020264 1703.856201,3247.138916 
	C1702.370239,3245.975586 1703.298096,3244.890381 1704.378174,3243.956299 
	C1706.375244,3242.229492 1709.108398,3240.454102 1705.118164,3238.119141 
	C1703.005859,3236.883057 1701.725586,3235.447510 1703.916016,3233.669189 
	C1706.045898,3231.940186 1708.580688,3228.952881 1711.628784,3231.939941 
	C1713.965332,3234.229980 1715.418945,3237.491943 1719.992798,3237.594482 
z"
        />
        <path
          fill="#E9E1D0"
          opacity="1.000000"
          stroke="none"
          d="
M1750.017090,3216.781250 
	C1750.673096,3216.034912 1751.323975,3215.986816 1751.965942,3216.041016 
	C1754.133667,3216.224121 1757.194458,3215.650391 1757.307251,3218.736328 
	C1757.449585,3222.638184 1753.928467,3221.655273 1750.777344,3221.994141 
	C1750.005859,3220.508789 1750.006836,3219.017578 1750.017090,3216.781250 
z"
        />
        <path
          fill="#E1D9C6"
          opacity="1.000000"
          stroke="none"
          d="
M1659.985107,3301.333496 
	C1659.372925,3301.840576 1658.714233,3301.756348 1658.125000,3301.507324 
	C1656.764282,3300.932373 1656.129883,3299.866455 1656.526123,3298.412842 
	C1656.805664,3297.387207 1657.675537,3296.660645 1658.650879,3297.214600 
	C1659.880249,3297.912354 1659.892212,3299.369141 1659.985107,3301.333496 
z"
        />
        <path
          fill="#E1D9C6"
          opacity="1.000000"
          stroke="none"
          d="
M1617.970947,3288.949463 
	C1616.286499,3288.007080 1616.082397,3286.834961 1616.876343,3285.686279 
	C1617.517822,3284.758545 1618.538208,3284.768555 1619.152466,3285.720703 
	C1619.906250,3286.888672 1619.692139,3288.057861 1617.970947,3288.949463 
z"
        />
        <path
          fill="#E1D9C6"
          opacity="1.000000"
          stroke="none"
          d="
M1696.080200,3226.544434 
	C1697.212280,3226.200195 1698.587524,3226.234131 1698.882446,3227.772949 
	C1698.943970,3228.093994 1698.270996,3228.882080 1697.915161,3228.895508 
	C1696.804077,3228.938232 1696.411133,3228.026611 1696.080200,3226.544434 
z"
        />
        <path
          fill="#C4BDAE"
          opacity="1.000000"
          stroke="none"
          d="
M1811.283447,3237.258057 
	C1808.963501,3239.938965 1808.806885,3242.108643 1809.185913,3245.040771 
	C1809.862061,3250.271973 1805.586426,3252.797607 1801.059692,3250.395020 
	C1799.006714,3249.305664 1797.857300,3247.588867 1799.677979,3245.701416 
	C1802.569946,3242.704590 1804.369507,3239.315674 1804.526489,3235.205566 
	C1804.830811,3227.244141 1810.971680,3224.592773 1816.670532,3221.462646 
	C1817.989624,3220.738037 1819.124512,3220.762451 1820.227417,3221.789795 
	C1821.705688,3223.167236 1821.648926,3224.795898 1820.520508,3226.195068 
	C1817.652832,3229.750732 1814.627930,3233.179443 1811.283447,3237.258057 
z"
        />
        <path
          fill="#E1D9C6"
          opacity="1.000000"
          stroke="none"
          d="
M1786.534668,3264.999756 
	C1787.233032,3267.804688 1786.293335,3269.262695 1783.759521,3268.958496 
	C1782.750244,3268.837402 1782.270996,3267.681641 1782.444458,3266.695557 
	C1782.801392,3264.666260 1784.221313,3264.185303 1786.534668,3264.999756 
z"
        />
        <path
          fill="#465F75"
          opacity="1.000000"
          stroke="none"
          d="
M2216.069580,2660.798096 
	C2218.670898,2660.310547 2221.941895,2658.968262 2223.858887,2663.229004 
	C2221.254395,2663.704834 2217.996582,2665.022461 2216.069580,2660.798096 
z"
        />
        <path
          fill="#465F75"
          opacity="1.000000"
          stroke="none"
          d="
M2033.114014,2674.085449 
	C2032.369141,2675.439209 2031.618652,2675.457520 2030.955444,2674.489746 
	C2030.816650,2674.287598 2030.822876,2673.816406 2030.967041,2673.618652 
	C2031.657471,2672.672607 2032.406494,2672.708252 2033.114014,2674.085449 
z"
        />
        <path
          fill="#49544F"
          opacity="1.000000"
          stroke="none"
          d="
M2642.777344,3271.490723 
	C2642.071289,3274.376709 2641.883301,3276.558838 2641.422363,3279.515625 
	C2634.803711,3276.157227 2634.491455,3269.428955 2634.125488,3262.983398 
	C2633.734619,3256.104004 2634.037598,3249.185303 2634.037598,3240.493896 
	C2630.023438,3246.434570 2628.418213,3251.595215 2626.895020,3257.213135 
	C2622.744141,3250.836182 2623.385742,3247.456055 2631.097900,3236.054688 
	C2633.819092,3234.640625 2635.382812,3235.608398 2637.383789,3237.477539 
	C2639.886475,3238.669189 2641.633057,3240.148438 2644.572266,3239.836182 
	C2645.353027,3245.340332 2642.065674,3250.524414 2643.917480,3256.990234 
	C2647.536865,3262.379883 2645.826660,3266.610596 2642.777344,3271.490723 
z"
        />
        <path
          fill="#434F51"
          opacity="1.000000"
          stroke="none"
          d="
M2614.854492,3209.311523 
	C2613.542480,3211.819336 2614.772949,3214.954590 2611.530518,3215.525146 
	C2609.138184,3215.946045 2608.068848,3214.034424 2607.121094,3212.246582 
	C2606.008301,3210.148193 2606.392334,3208.277100 2608.490723,3207.183838 
	C2610.536377,3206.117920 2612.737793,3205.310059 2614.936523,3204.590332 
	C2617.257568,3203.830078 2620.201416,3203.499512 2620.227783,3206.718262 
	C2620.245850,3208.905762 2616.842529,3207.633545 2614.854492,3209.311523 
z"
        />
        <path
          fill="#D0D1C8"
          opacity="1.000000"
          stroke="none"
          d="
M2638.102539,3237.276855 
	C2636.305176,3237.335938 2634.713623,3236.694092 2632.465820,3236.034424 
	C2632.372314,3234.437012 2632.934326,3232.857666 2633.763428,3230.702637 
	C2637.470459,3230.940186 2638.613037,3233.277588 2638.102539,3237.276855 
z"
        />
        <path
          fill="#B6BCC1"
          opacity="1.000000"
          stroke="none"
          d="
M2697.819580,3244.047852 
	C2695.846680,3244.164062 2694.404297,3243.793945 2693.277344,3244.232910 
	C2681.995117,3248.624268 2674.766113,3243.297852 2668.930176,3234.351807 
	C2668.417480,3233.566162 2667.524170,3233.029053 2666.181152,3231.995605 
	C2668.323975,3225.408203 2674.182129,3226.395752 2679.053711,3226.548096 
	C2684.893799,3226.730225 2690.853271,3227.507568 2697.114014,3230.176270 
	C2698.192871,3230.598877 2698.479004,3230.918701 2698.636719,3231.869629 
	C2697.509521,3235.195312 2699.077393,3237.567627 2699.476074,3240.762207 
	C2699.347412,3242.344482 2699.013428,3243.177002 2697.819580,3244.047852 
z"
        />
        <path
          fill="#A9AEB3"
          opacity="1.000000"
          stroke="none"
          d="
M2698.166992,3232.037598 
	C2697.909180,3231.508301 2697.890625,3230.890625 2697.898438,3230.584717 
	C2698.609619,3230.160645 2699.232666,3230.332520 2700.114014,3231.158936 
	C2700.452637,3231.523193 2700.022949,3231.962158 2699.806641,3232.180176 
	C2699.201904,3232.454590 2698.813232,3232.510742 2698.166992,3232.037598 
z"
        />
        <path
          fill="#FAF7E7"
          opacity="1.000000"
          stroke="none"
          d="
M2265.256836,2996.034424 
	C2272.674316,2990.941650 2278.594482,2985.484863 2281.687256,2977.219727 
	C2283.268555,2972.993896 2286.838379,2969.541748 2289.295654,2965.606445 
	C2292.723877,2960.116699 2298.663818,2955.981689 2298.367432,2948.428955 
	C2298.287354,2946.380371 2300.173340,2944.956055 2301.493408,2943.465820 
	C2304.737061,2939.803711 2306.208252,2935.578369 2306.055420,2930.646973 
	C2305.904053,2925.771973 2306.837646,2921.637695 2312.318359,2919.456055 
	C2317.062744,2917.567383 2317.737305,2912.133545 2319.860596,2908.104980 
	C2322.087158,2903.879639 2324.270020,2905.187012 2327.353027,2907.757324 
	C2328.230469,2908.357422 2328.560791,2908.671387 2329.133301,2909.622314 
	C2327.959717,2915.664062 2325.558594,2920.835693 2325.211914,2927.153320 
	C2324.091797,2929.510010 2322.397705,2930.812500 2322.085449,2933.678467 
	C2322.558350,2931.223633 2323.745850,2930.029785 2325.026855,2928.089844 
	C2326.106445,2926.220215 2327.223145,2925.432373 2329.297363,2924.898926 
	C2335.429688,2929.426025 2340.779785,2932.176270 2347.906006,2927.318604 
	C2351.130859,2925.120117 2354.868164,2926.946289 2357.268555,2931.280273 
	C2358.708008,2935.354492 2357.586914,2938.552246 2356.331787,2942.425049 
	C2355.713135,2943.932617 2355.292480,2944.755859 2354.517578,2946.155762 
	C2353.251953,2948.253906 2352.482178,2949.845459 2351.565918,2952.108398 
	C2349.368408,2955.713867 2347.503418,2958.743408 2345.752441,2962.627441 
	C2332.945557,2987.114746 2318.826904,3009.966064 2302.162109,3032.047852 
	C2293.333984,3044.258301 2284.862061,3055.859619 2276.365723,3068.284912 
	C2272.613525,3073.016357 2269.269531,3077.198486 2265.874023,3082.184082 
	C2263.610596,3085.242676 2261.523682,3087.587402 2259.260986,3090.667480 
	C2251.944580,3098.697021 2245.962646,3106.879639 2240.100098,3116.006348 
	C2237.081055,3120.187988 2234.901855,3124.095703 2232.154297,3128.426758 
	C2229.955322,3130.893799 2228.725098,3133.189209 2227.246338,3136.103027 
	C2223.384033,3139.851318 2219.919678,3143.080078 2217.936768,3148.294434 
	C2215.434082,3152.837891 2212.954590,3156.604492 2209.658691,3160.627686 
	C2204.090332,3165.551758 2200.972168,3171.401611 2197.397461,3177.768311 
	C2195.931152,3179.868164 2194.658936,3181.245850 2192.940430,3183.198730 
	C2183.370361,3189.500977 2177.814941,3180.753174 2170.059082,3178.121826 
	C2164.598145,3172.125977 2156.847412,3169.997314 2152.371338,3162.977539 
	C2151.260010,3158.987305 2151.839111,3155.660889 2152.166260,3152.363281 
	C2152.474609,3149.259277 2152.627441,3146.773438 2149.129395,3144.864014 
	C2142.187256,3141.073975 2143.447266,3136.891602 2149.262207,3132.848389 
	C2153.498535,3129.902832 2156.650635,3125.086182 2163.165771,3126.816406 
	C2165.753662,3127.504150 2166.552490,3124.153076 2168.262695,3121.749268 
	C2172.368896,3114.770264 2178.330322,3110.298340 2182.820801,3104.608643 
	C2193.208008,3091.447998 2203.010986,3077.855713 2212.642822,3064.134033 
	C2227.161133,3043.450684 2239.635010,3021.081543 2256.905029,3002.318848 
	C2259.147949,2999.882080 2261.316406,2997.350098 2265.256836,2996.034424 
z"
        />
        <path
          fill="#E1D9C6"
          opacity="1.000000"
          stroke="none"
          d="
M2265.895996,2995.257324 
	C2261.586182,3001.314941 2257.320557,3006.787354 2252.783936,3012.024414 
	C2245.671875,3020.234375 2240.334717,3029.670898 2234.247559,3038.578857 
	C2230.042480,3044.732910 2227.644287,3051.705078 2220.715088,3056.374756 
	C2216.347900,3059.317627 2213.054932,3065.174072 2211.384521,3071.053223 
	C2209.439453,3077.900635 2205.741699,3083.806641 2200.392334,3088.458496 
	C2195.064941,3093.092041 2191.287598,3098.990479 2186.984863,3104.408447 
	C2182.117920,3110.537109 2175.158936,3114.727539 2170.725098,3121.939941 
	C2154.001953,3132.443359 2138.003662,3127.242432 2122.064453,3121.826904 
	C2119.707520,3121.025879 2118.413818,3118.940186 2118.882812,3116.236572 
	C2119.375244,3113.398926 2121.548340,3112.552002 2123.991943,3112.367676 
	C2128.339844,3112.040039 2132.563721,3110.047607 2136.740234,3110.152100 
	C2151.456055,3110.520264 2158.413818,3100.176270 2165.301025,3090.037598 
	C2168.311035,3085.606201 2170.916504,3080.628662 2175.071533,3077.524414 
	C2184.165283,3070.730225 2186.397705,3057.647949 2198.162598,3053.562988 
	C2191.591064,3055.086914 2186.408691,3057.851562 2184.176514,3064.862061 
	C2182.484131,3070.177979 2178.356445,3070.178711 2174.927490,3066.497803 
	C2171.121826,3062.411865 2167.398193,3059.817627 2161.287842,3059.844482 
	C2154.594238,3059.873779 2152.957275,3055.992676 2157.333740,3050.764160 
	C2164.475342,3042.231934 2170.299072,3031.789551 2183.130371,3030.183838 
	C2185.420898,3029.897461 2188.065430,3028.660889 2189.091797,3026.772705 
	C2195.235596,3015.470215 2208.499023,3012.218994 2216.008789,3002.542236 
	C2217.944580,3000.047607 2220.512939,2997.990479 2223.734375,2999.325684 
	C2230.570557,3002.158691 2235.235840,2998.850342 2240.228027,2994.009766 
	C2245.452881,2990.820068 2252.509277,2994.574707 2256.346680,2987.604980 
	C2257.943115,2986.187500 2259.202148,2985.596680 2261.318848,2985.679932 
	C2264.554932,2988.238770 2264.584229,2991.669678 2265.895996,2995.257324 
z"
        />
        <path
          fill="#F9F4E5"
          opacity="1.000000"
          stroke="none"
          d="
M2275.855469,2933.417236 
	C2276.079102,2936.238770 2277.401123,2937.777344 2278.286377,2939.548340 
	C2281.493652,2945.963867 2280.718262,2948.914307 2274.303223,2952.119141 
	C2266.547852,2955.993896 2266.689209,2963.933838 2264.006104,2971.221680 
	C2256.161621,2975.579834 2251.108398,2982.352539 2244.661377,2988.612305 
	C2243.530273,2989.035156 2243.045410,2989.114258 2241.831543,2989.067383 
	C2236.961914,2986.274902 2237.655762,2982.443359 2239.761963,2979.368896 
	C2244.033691,2973.133057 2248.825928,2967.226807 2254.983398,2962.646484 
	C2255.092041,2962.565430 2255.540283,2962.940430 2255.830078,2963.102783 
	C2255.711426,2962.961670 2255.593018,2962.820801 2254.997559,2962.112549 
	C2248.171875,2968.973145 2238.616943,2971.170166 2230.665283,2976.305664 
	C2228.168945,2977.917725 2225.198486,2978.869873 2223.565918,2981.576660 
	C2221.216553,2985.471680 2218.638428,2989.291260 2213.370117,2986.586914 
	C2207.802246,2983.728271 2205.266113,2978.698486 2205.983398,2972.532715 
	C2206.556396,2967.604736 2209.044189,2963.054443 2209.673828,2957.308594 
	C2210.673584,2953.633301 2211.838623,2950.835205 2213.927490,2947.631836 
	C2216.108643,2945.132568 2218.046631,2943.614746 2221.403564,2943.789795 
	C2223.925537,2946.122070 2225.665039,2947.953125 2228.699707,2945.571045 
	C2237.186523,2938.908203 2247.454834,2936.032227 2258.113770,2932.598145 
	C2256.123291,2927.518311 2252.531738,2925.377686 2248.011719,2926.321777 
	C2241.273193,2927.729004 2235.785400,2931.711670 2233.604980,2938.416016 
	C2231.631348,2944.485107 2228.206299,2946.221191 2221.896484,2944.015137 
	C2218.147461,2936.809570 2222.988525,2930.837158 2223.162109,2923.662109 
	C2223.274658,2922.381836 2223.337646,2921.869629 2223.591553,2920.605957 
	C2223.993652,2919.385254 2224.205078,2918.915771 2224.859375,2917.816895 
	C2232.327393,2913.391602 2239.028076,2909.367432 2242.778076,2901.705566 
	C2244.120605,2898.962158 2247.256348,2897.855225 2251.223145,2897.927002 
	C2264.903076,2906.128662 2270.679932,2919.171631 2275.855469,2933.417236 
z"
        />
        <path
          fill="#D0D1C8"
          opacity="1.000000"
          stroke="none"
          d="
M2276.458496,2933.667236 
	C2272.760010,2932.511963 2271.720703,2929.365479 2270.381104,2926.397217 
	C2266.083252,2916.873779 2259.990234,2908.522461 2252.768555,2900.201660 
	C2246.799805,2896.533447 2245.962402,2890.926025 2244.781738,2884.723145 
	C2244.471924,2883.112305 2244.475342,2882.181396 2244.815430,2880.585449 
	C2246.420410,2877.332520 2249.265625,2876.503662 2251.792480,2874.228027 
	C2254.069092,2873.626465 2255.593506,2873.121826 2256.234375,2870.462402 
	C2260.334229,2870.875732 2260.911377,2874.222168 2261.993164,2877.380371 
	C2263.807617,2882.677979 2265.461426,2883.150635 2269.069092,2878.612793 
	C2272.519531,2874.272461 2275.734375,2870.644287 2281.949951,2872.018066 
	C2283.963623,2872.463135 2286.034180,2872.781738 2286.837891,2874.829346 
	C2287.571045,2876.696045 2285.556152,2877.520020 2284.735352,2878.840088 
	C2279.923096,2886.579590 2273.621826,2893.376221 2271.013672,2902.522705 
	C2269.335449,2908.407227 2269.588135,2911.612793 2274.217041,2914.827148 
	C2282.851074,2920.822266 2282.848389,2920.821289 2278.182373,2930.471680 
	C2277.753174,2931.359131 2277.384277,2932.275635 2276.458496,2933.667236 
z"
        />
        <path
          fill="#EEE9DC"
          opacity="1.000000"
          stroke="none"
          d="
M2357.112793,2932.037109 
	C2352.595703,2927.332764 2348.266357,2928.784912 2344.270264,2931.277832 
	C2336.568115,2936.083252 2336.656250,2936.218994 2332.143799,2928.402344 
	C2331.818359,2927.838867 2331.416992,2927.319336 2330.511719,2926.351074 
	C2326.263916,2921.363037 2329.520020,2917.319336 2331.121826,2912.404785 
	C2331.649658,2910.696045 2332.143066,2909.814453 2333.373291,2908.519287 
	C2336.299072,2906.331543 2335.955322,2903.808838 2335.993652,2900.667480 
	C2340.440918,2897.435059 2337.030273,2892.048584 2339.757812,2888.469238 
	C2344.743896,2899.575195 2344.743896,2899.575195 2351.981689,2901.381348 
	C2354.793701,2899.296143 2350.773438,2895.597656 2353.890625,2893.432617 
	C2355.983643,2891.978760 2358.191162,2892.065186 2361.242676,2892.020752 
	C2363.280518,2893.335693 2363.333740,2895.094727 2363.863770,2897.366943 
	C2363.404053,2903.461182 2362.730225,2908.951416 2362.099609,2915.248535 
	C2364.786377,2922.441895 2362.208984,2927.447021 2357.112793,2932.037109 
z"
        />
        <path
          fill="#F2EBDA"
          opacity="1.000000"
          stroke="none"
          d="
M2244.470703,2988.527832 
	C2246.040771,2982.731445 2250.360107,2979.015381 2253.288818,2974.331055 
	C2255.324707,2971.074951 2259.179199,2972.187744 2263.181885,2971.986816 
	C2265.989502,2973.548828 2265.358643,2975.286377 2264.424072,2977.151367 
	C2263.263916,2979.467529 2261.928711,2981.752686 2262.005371,2985.210449 
	C2260.997070,2986.470703 2260.003906,2987.010010 2258.490723,2987.770264 
	C2253.804199,2990.300537 2249.515625,2991.158203 2244.470703,2988.527832 
z"
        />
        <path
          fill="#D0D1C8"
          opacity="1.000000"
          stroke="none"
          d="
M2250.380615,2850.382324 
	C2249.904053,2849.917480 2249.766602,2849.346436 2249.820312,2849.057617 
	C2249.038574,2847.117432 2248.580566,2845.405518 2249.510010,2842.777344 
	C2250.023682,2841.081787 2250.066650,2840.156006 2250.250244,2838.546631 
	C2258.726807,2841.854004 2260.233154,2849.794434 2261.428955,2857.668945 
	C2261.803711,2860.136475 2259.302246,2861.401367 2256.101562,2861.924316 
	C2254.024902,2860.863037 2253.811523,2859.317871 2253.622803,2857.161621 
	C2254.039307,2854.010254 2252.404053,2852.450195 2250.380615,2850.382324 
z"
        />
        <path
          fill="#C4C8C1"
          opacity="1.000000"
          stroke="none"
          d="
M2305.762695,2883.629150 
	C2312.996094,2886.385498 2316.858154,2891.822754 2321.251221,2897.647949 
	C2322.664795,2898.762451 2323.022217,2899.661865 2322.282959,2901.375732 
	C2318.226074,2901.930420 2314.590088,2902.497803 2313.873779,2897.248779 
	C2313.106934,2891.628418 2307.352051,2889.505859 2304.289062,2884.613770 
	C2304.388428,2883.760498 2304.779053,2883.520752 2305.762695,2883.629150 
z"
        />
        <path
          fill="#FAF7E7"
          opacity="1.000000"
          stroke="none"
          d="
M2322.496582,2901.971191 
	C2321.774658,2901.100586 2321.786377,2900.239990 2321.764160,2898.735840 
	C2323.735596,2897.156494 2324.430664,2894.492188 2327.566162,2894.104736 
	C2328.235840,2895.470947 2328.241943,2896.905029 2328.307617,2899.051270 
	C2327.086182,2901.501465 2325.282471,2902.029053 2322.496582,2901.971191 
z"
        />
        <path
          fill="#C4C8C1"
          opacity="1.000000"
          stroke="none"
          d="
M2333.405273,2907.788574 
	C2333.944336,2908.883301 2333.944580,2909.753418 2333.955566,2911.276123 
	C2332.000244,2913.616943 2330.329102,2913.228516 2328.505371,2910.550293 
	C2328.120850,2909.894287 2328.049805,2908.964111 2327.975098,2908.503906 
	C2329.362549,2905.880859 2331.099609,2906.569336 2333.405273,2907.788574 
z"
        />
        <path
          fill="#C4C8C1"
          opacity="1.000000"
          stroke="none"
          d="
M2304.542480,2872.023682 
	C2302.908203,2871.439697 2302.256836,2870.502930 2302.032227,2868.593018 
	C2303.225098,2868.911865 2304.381592,2869.910156 2305.775879,2871.438477 
	C2306.013672,2871.968018 2305.033691,2872.040771 2304.542480,2872.023682 
z"
        />
        <path
          fill="#9DAAB4"
          opacity="1.000000"
          stroke="none"
          d="
M2306.100586,2883.619141 
	C2305.962402,2883.973389 2305.569336,2883.969482 2304.587158,2883.982910 
	C2304.357422,2883.113281 2304.786865,2882.400635 2306.100586,2883.619141 
z"
        />
        <path
          fill="#C4C8C1"
          opacity="1.000000"
          stroke="none"
          d="
M2301.945801,2868.041016 
	C2301.687744,2867.770020 2301.473389,2867.459717 2301.259277,2867.149170 
	C2301.443848,2867.230225 2301.628418,2867.311279 2301.940918,2867.652832 
	C2302.068848,2867.913330 2301.989990,2868.001709 2301.945801,2868.041016 
z"
        />
        <path
          fill="#F9F4E5"
          opacity="1.000000"
          stroke="none"
          d="
M2246.506836,2879.572266 
	C2246.041016,2880.944824 2246.030518,2881.820557 2246.000488,2883.352539 
	C2244.625732,2890.862549 2240.033691,2894.859375 2234.019043,2897.638672 
	C2228.588135,2900.148193 2223.174072,2902.700928 2218.945068,2908.117676 
	C2217.706299,2909.652832 2216.833496,2910.628906 2215.637207,2912.173584 
	C2214.181641,2914.011719 2212.933838,2915.198975 2211.823242,2917.295654 
	C2211.178955,2918.153564 2210.893799,2918.475098 2210.109375,2919.201172 
	C2206.776611,2920.755127 2204.725586,2922.705078 2203.140625,2926.033691 
	C2202.419189,2926.796631 2202.094971,2927.064697 2201.234863,2927.655762 
	C2198.831787,2928.766113 2196.885742,2929.474609 2196.560059,2932.593262 
	C2195.432617,2936.845947 2193.334961,2939.832275 2190.604248,2942.229736 
	C2181.387451,2950.322754 2174.758301,2960.613037 2166.353271,2970.284180 
	C2164.526855,2971.948730 2162.976074,2972.898438 2161.458984,2974.877686 
	C2159.486328,2977.083008 2157.708496,2978.479248 2155.185547,2980.024902 
	C2145.036133,2986.404541 2140.518555,2998.008789 2129.281250,3003.179688 
	C2124.362061,3005.046875 2124.144043,3009.579834 2121.663574,3013.300781 
	C2116.072266,3025.025635 2108.855225,3034.609863 2099.809082,3043.029541 
	C2093.979736,3048.455566 2075.536865,3055.933838 2067.258789,3054.891113 
	C2065.775146,3053.039062 2065.001465,3051.137695 2064.162109,3048.619873 
	C2064.096436,3048.003662 2065.082764,3048.141357 2065.580078,3048.104980 
	C2069.107666,3047.874512 2071.150879,3046.452148 2072.007812,3042.737549 
	C2076.154053,3041.859619 2077.472656,3038.732422 2079.154785,3035.805420 
	C2082.460938,3030.052734 2085.715820,3024.212646 2092.405273,3021.510010 
	C2093.552002,3021.046631 2095.043457,3019.978271 2095.276367,3018.942139 
	C2097.200439,3010.386719 2104.772217,3006.584717 2110.086670,3000.896240 
	C2111.650146,2999.222656 2113.833496,2997.746582 2113.271973,2995.043945 
	C2111.355469,2985.820068 2117.889404,2980.585938 2122.925781,2974.761963 
	C2124.372803,2973.088379 2126.566650,2972.184326 2127.774170,2969.970215 
	C2130.387939,2965.178467 2127.596191,2959.933594 2128.927979,2955.586426 
	C2130.400879,2950.779541 2133.659180,2946.816162 2129.373047,2941.965820 
	C2128.150391,2940.582275 2128.514893,2938.751221 2129.805908,2937.441406 
	C2132.196777,2935.014893 2133.527100,2932.073975 2134.868896,2928.988281 
	C2136.066162,2926.234863 2138.245117,2923.773682 2141.924561,2925.040039 
	C2146.631592,2926.660156 2147.383789,2924.179443 2148.174805,2920.222412 
	C2149.647949,2912.849365 2149.696777,2905.022217 2154.126221,2898.426270 
	C2154.669678,2897.617188 2154.623535,2896.167969 2155.306641,2895.734375 
	C2161.806396,2891.611816 2160.902100,2885.102539 2160.494629,2878.427246 
	C2158.190186,2876.448975 2157.550293,2874.364258 2157.302490,2871.271484 
	C2157.347168,2869.355957 2157.483398,2868.277588 2157.932617,2866.442383 
	C2161.843750,2860.162354 2161.029541,2853.628418 2162.171387,2847.551758 
	C2162.925537,2843.536865 2161.363037,2838.188965 2167.231201,2837.227539 
	C2172.603271,2836.347412 2172.563477,2842.066895 2175.386719,2845.485840 
	C2175.886719,2846.949707 2175.890625,2847.823730 2175.890137,2849.353516 
	C2174.564941,2861.068604 2176.153320,2872.028564 2177.446289,2883.541748 
	C2177.856934,2887.437744 2177.837891,2890.805176 2177.770020,2895.012939 
	C2166.789795,2905.921387 2172.301758,2917.954834 2173.717041,2929.492188 
	C2173.891602,2930.913574 2174.605225,2932.102783 2176.214844,2931.904541 
	C2178.777832,2931.588623 2180.197266,2929.696045 2181.355225,2927.611816 
	C2183.913330,2923.008057 2182.382324,2916.814941 2187.516113,2912.521484 
	C2188.263428,2911.416260 2188.646729,2910.903076 2189.576172,2910.237061 
	C2194.506348,2903.091797 2199.697998,2897.983154 2208.248291,2895.028320 
	C2216.234375,2892.268311 2220.354736,2883.423340 2224.473877,2875.911377 
	C2226.359131,2872.472900 2227.702637,2868.742188 2229.577393,2864.536621 
	C2230.632080,2857.455811 2224.726074,2853.619873 2223.641113,2847.224609 
	C2222.649902,2844.642578 2221.602295,2842.844238 2220.350586,2840.477051 
	C2215.967529,2828.456787 2206.955566,2819.395508 2203.921387,2806.680420 
	C2222.156006,2818.125977 2234.708496,2835.014893 2243.823242,2854.474365 
	C2246.453857,2860.089844 2249.511475,2865.754639 2250.650391,2872.827393 
	C2250.090332,2875.833984 2248.535889,2877.461182 2246.506836,2879.572266 
z"
        />
        <path
          fill="#E7E3DA"
          opacity="1.000000"
          stroke="none"
          d="
M2229.041504,2863.763672 
	C2231.166260,2864.894531 2232.236084,2866.328613 2231.501465,2868.046387 
	C2226.810791,2879.011230 2220.801514,2889.112305 2211.776855,2897.152588 
	C2211.284668,2897.591309 2210.731445,2898.398926 2210.261719,2898.360596 
	C2201.187744,2897.620117 2197.461670,2905.603027 2190.712891,2909.897217 
	C2189.900879,2907.625488 2190.354736,2905.382812 2191.259521,2903.192871 
	C2192.042236,2901.297607 2192.676514,2898.562988 2191.254639,2897.503418 
	C2184.258789,2892.291260 2187.259766,2885.552490 2188.698486,2879.625244 
	C2190.195557,2873.458496 2190.411865,2867.916992 2187.285400,2862.352783 
	C2186.108643,2860.258545 2185.727539,2857.871582 2186.114746,2855.582031 
	C2186.987793,2850.417725 2186.050781,2844.799316 2187.386230,2840.120850 
	C2191.265869,2826.530762 2187.836426,2814.831543 2180.997070,2803.406250 
	C2179.729492,2801.288330 2179.696777,2798.987305 2181.582031,2796.270508 
	C2188.380615,2804.174561 2195.703857,2812.042725 2200.625244,2821.201172 
	C2205.137207,2829.597168 2209.836182,2838.057861 2213.353027,2847.007812 
	C2214.484375,2849.886963 2214.250977,2852.881104 2213.199219,2855.795166 
	C2211.686768,2859.986328 2212.003418,2864.301025 2212.379883,2868.610840 
	C2212.510498,2870.104492 2213.466553,2871.159912 2214.874512,2871.249512 
	C2216.288330,2871.339111 2217.298340,2870.405518 2217.591064,2868.909424 
	C2217.654785,2868.584229 2217.692627,2868.252197 2217.782471,2867.934326 
	C2218.629395,2864.932373 2216.066406,2860.427490 2220.062988,2859.073242 
	C2223.435547,2857.930176 2224.906738,2863.223877 2229.041504,2863.763672 
z"
        />
        <path
          fill="#E7E3DA"
          opacity="1.000000"
          stroke="none"
          d="
M2219.930664,2840.709473 
	C2222.184570,2840.985352 2223.617432,2842.564209 2223.641846,2845.818848 
	C2221.038574,2845.739258 2219.912354,2843.977539 2219.930664,2840.709473 
z"
        />
        <path
          fill="#F9F4E5"
          opacity="1.000000"
          stroke="none"
          d="
M2203.543457,2804.390137 
	C2203.886475,2804.222412 2204.118652,2805.089600 2204.099609,2805.538086 
	C2203.272705,2805.826904 2202.497803,2805.647217 2203.543457,2804.390137 
z"
        />
        <path
          fill="#E7E3DA"
          opacity="1.000000"
          stroke="none"
          d="
M2249.990234,2850.565674 
	C2252.947754,2850.607910 2256.481201,2850.897705 2254.095215,2856.098877 
	C2251.923340,2855.117676 2250.482910,2853.507080 2249.990234,2850.565674 
z"
        />
        <path
          fill="#F2EBDA"
          opacity="1.000000"
          stroke="none"
          d="
M2246.736328,2879.968994 
	C2246.039551,2877.471191 2247.186523,2875.543701 2250.081543,2873.915527 
	C2250.741455,2873.584717 2251.507080,2873.595947 2251.885742,2873.666260 
	C2251.792480,2876.682861 2250.704102,2879.142090 2246.736328,2879.968994 
z"
        />
        <path
          fill="#E7E3DA"
          opacity="1.000000"
          stroke="none"
          d="
M2249.578125,2841.874023 
	C2249.972656,2843.689941 2249.964600,2845.372803 2249.915283,2847.912109 
	C2248.128418,2847.310303 2246.604248,2845.702637 2246.150391,2842.675049 
	C2246.430176,2841.763428 2246.771484,2841.509521 2247.726074,2841.431152 
	C2248.339355,2841.607178 2249.176025,2841.740723 2249.578125,2841.874023 
z"
        />
        <path
          fill="#D0D1C8"
          opacity="1.000000"
          stroke="none"
          d="
M2248.058594,2841.288330 
	C2247.967773,2841.696533 2247.596191,2841.785889 2246.656738,2841.946289 
	C2246.276123,2841.062500 2246.646973,2840.403564 2248.058594,2841.288330 
z"
        />
        <path
          fill="#4E6477"
          opacity="1.000000"
          stroke="none"
          d="
M1241.398682,2649.419922 
	C1244.149170,2653.889648 1249.906616,2652.504150 1251.882568,2657.443359 
	C1247.645142,2662.461914 1242.262329,2661.254150 1237.188843,2660.509033 
	C1232.024414,2659.750488 1226.865234,2659.022949 1220.826904,2658.320801 
	C1211.842773,2658.389893 1204.020142,2654.992188 1194.927246,2656.043945 
	C1191.234253,2659.368164 1186.991699,2658.657959 1182.716431,2659.943848 
	C1180.504395,2660.034424 1179.002930,2660.026611 1176.750732,2660.018555 
	C1166.442139,2660.040527 1156.807861,2663.156006 1146.584961,2658.812012 
	C1141.855713,2658.317627 1137.887207,2658.320068 1133.070557,2658.382324 
	C1131.059570,2659.192871 1129.967896,2660.040039 1128.331665,2661.313477 
	C1120.342041,2661.972656 1112.897461,2662.425049 1104.830200,2660.050781 
	C1103.108887,2660.006836 1102.218018,2659.981689 1100.660156,2659.909180 
	C1095.088257,2660.091553 1090.609375,2657.186279 1084.811279,2657.895752 
	C1082.125000,2654.155029 1078.834106,2653.858643 1074.519531,2654.319336 
	C1071.228149,2656.427979 1068.369751,2657.345947 1064.486694,2656.375000 
	C1063.019165,2655.907959 1062.175415,2655.899902 1060.700317,2655.903564 
	C1059.167114,2655.910156 1058.265137,2655.905029 1056.687622,2655.893066 
	C1055.118164,2655.928711 1054.223877,2655.949463 1052.658569,2655.918945 
	C1048.333984,2655.890137 1044.701294,2655.998535 1041.142700,2654.734619 
	C1038.257812,2653.710449 1035.058716,2653.448242 1032.391602,2655.012451 
	C1026.234253,2658.623779 1019.564392,2658.046631 1012.945435,2657.923340 
	C1000.028137,2657.682373 987.070557,2658.936523 974.214417,2656.134277 
	C970.660583,2655.359375 967.011536,2654.846924 963.624939,2653.561768 
	C960.093689,2652.221680 956.521973,2651.703125 952.863708,2652.040039 
	C942.329773,2653.010986 931.804871,2654.089844 921.288635,2655.239014 
	C913.675537,2656.070801 906.287842,2656.594727 898.765503,2653.653564 
	C891.192200,2650.692383 883.066895,2651.188965 875.268982,2653.381104 
	C862.026367,2657.103760 849.138428,2655.114258 836.417114,2651.216064 
	C829.978577,2649.242676 823.302185,2649.614990 816.774536,2648.566162 
	C806.469910,2646.910645 796.112305,2646.385742 785.294922,2647.394531 
	C771.217102,2648.707764 756.971008,2650.701904 742.650940,2650.037598 
	C735.726196,2649.716309 728.687317,2649.235840 721.861206,2651.477295 
	C717.463867,2652.921143 713.413086,2650.084473 709.121887,2650.111572 
	C705.960144,2650.131592 701.892151,2649.159912 703.013733,2654.915771 
	C703.432068,2657.062500 701.720032,2657.770996 699.951843,2657.872559 
	C697.297668,2658.024658 694.632812,2657.986816 691.972717,2658.041016 
	C687.713135,2658.127930 683.484985,2657.529541 680.378601,2661.974365 
	C678.456482,2664.724854 674.561035,2664.957275 671.327942,2665.565430 
	C669.706360,2665.870850 667.758789,2665.295410 667.459229,2663.302734 
	C667.148621,2661.236572 668.915039,2660.581299 670.580139,2660.251465 
	C672.158691,2659.938721 673.772034,2659.802002 676.105347,2659.489014 
	C672.419067,2657.015137 668.824524,2657.641357 665.750916,2658.122070 
	C653.726318,2660.002441 642.326904,2657.066406 630.771423,2654.672119 
	C626.599365,2653.807617 622.184326,2654.065674 617.876587,2653.916992 
	C605.294922,2653.482910 592.609314,2655.518555 580.137207,2652.251953 
	C578.919922,2651.933105 577.210327,2651.893555 576.231323,2652.515381 
	C567.367004,2658.143799 557.432312,2655.144287 547.117676,2656.303223 
	C540.754883,2656.499023 535.469788,2657.278564 530.454407,2661.041992 
	C524.668091,2665.139893 518.575867,2663.315918 512.779846,2663.727783 
	C509.188721,2663.982666 505.562103,2663.738281 501.034912,2663.650391 
	C497.157440,2659.850830 499.425232,2657.458740 502.373077,2655.466797 
	C506.574585,2652.628174 511.201080,2650.933838 516.368286,2650.990723 
	C521.945984,2651.052490 526.691101,2649.039062 531.377625,2644.714600 
	C543.581482,2643.136719 555.050476,2640.364502 566.516174,2640.882568 
	C585.509094,2641.740723 604.211792,2638.391602 623.101013,2638.437256 
	C627.496521,2638.447754 631.641968,2637.408447 636.175659,2634.767578 
	C637.350708,2634.079346 637.795349,2633.766602 638.978394,2633.119629 
	C640.742310,2632.517090 641.782715,2632.424072 643.572266,2632.675781 
	C649.221375,2635.822998 654.948730,2635.717285 660.322815,2636.894531 
	C663.657654,2637.625244 666.920959,2637.058350 668.701294,2632.466309 
	C675.723877,2627.956055 682.948547,2626.572754 690.221436,2628.238281 
	C700.864441,2630.675537 711.450439,2628.788330 722.053833,2629.020264 
	C725.722656,2629.100586 728.370056,2627.306152 731.431274,2624.442871 
	C733.828369,2620.379395 737.048828,2620.177490 740.490967,2620.002930 
	C750.041260,2619.519775 759.700378,2621.109131 769.152039,2618.348877 
	C771.077271,2617.786377 773.807556,2618.597900 772.925842,2621.273438 
	C770.693237,2628.048828 775.930847,2628.232178 779.753418,2629.498291 
	C784.762695,2631.157227 789.530212,2628.836914 795.201660,2628.032227 
	C797.924316,2626.103760 800.259338,2625.430420 803.591919,2625.597168 
	C808.603638,2627.433350 812.213806,2626.120605 816.126099,2622.906738 
	C820.267212,2622.078125 823.615051,2623.259277 827.794434,2623.548828 
	C849.388916,2628.827637 870.493347,2631.001221 892.765259,2629.174805 
	C912.723938,2625.835938 932.032166,2626.476562 952.144714,2626.417480 
	C955.170532,2626.573242 957.224365,2626.198486 959.763916,2624.544678 
	C966.536560,2622.075439 973.029541,2623.352539 979.927002,2621.631348 
	C982.758667,2621.155029 984.880554,2621.145020 987.597656,2620.040771 
	C990.484680,2619.393311 992.625549,2619.356689 995.580322,2619.492188 
	C1010.577454,2619.430176 1024.790039,2619.796631 1038.860107,2617.304443 
	C1041.793091,2616.784668 1045.004883,2616.164307 1047.723633,2617.093994 
	C1061.594360,2621.836182 1075.359009,2620.239746 1090.019409,2617.085449 
	C1096.111450,2617.354492 1101.451050,2616.366943 1107.305664,2618.780518 
	C1108.754639,2620.284424 1109.089111,2621.386719 1108.753784,2623.426758 
	C1097.463257,2630.263672 1085.097046,2629.677979 1073.428833,2629.592285 
	C1048.308472,2629.407959 1023.666687,2632.959717 999.009949,2636.563477 
	C990.081116,2637.868164 981.285278,2639.484619 972.217834,2638.718994 
	C997.137756,2641.217773 1021.784058,2636.968018 1046.137573,2633.652832 
	C1061.846436,2631.514404 1077.472534,2629.614746 1093.268921,2628.766357 
	C1097.917236,2628.516602 1102.629272,2628.245117 1107.447266,2631.393555 
	C1108.001343,2633.514648 1108.002686,2635.019531 1108.006958,2637.276855 
	C1105.355347,2641.200439 1101.596924,2641.358398 1097.982910,2641.343506 
	C1088.313965,2641.302979 1078.803101,2642.446289 1071.537964,2644.207764 
	C1113.262451,2640.570801 1157.119629,2642.479736 1201.799316,2645.156738 
	C1207.396606,2646.953125 1212.216553,2647.854736 1218.033203,2646.030762 
	C1226.220825,2646.555420 1233.793457,2645.536377 1241.398682,2649.419922 
z"
        />
        <path
          fill="#263B4D"
          opacity="1.000000"
          stroke="none"
          d="
M828.817627,2625.922852 
	C824.465332,2626.260498 821.068604,2625.570312 816.993774,2624.418457 
	C813.581055,2626.259277 810.276794,2626.518066 806.671875,2627.912354 
	C803.235535,2628.067627 800.458313,2628.034912 796.848511,2627.991211 
	C795.977417,2625.191650 796.454895,2622.962402 799.641357,2621.788330 
	C803.777344,2620.264160 807.949646,2619.910889 812.268616,2619.936768 
	C818.361206,2619.973389 824.454773,2619.843262 831.499695,2619.775391 
	C829.278870,2614.303955 823.423035,2617.681885 822.150513,2612.692383 
	C826.076782,2611.924316 830.170715,2611.868408 835.140930,2611.788086 
	C839.893433,2613.231201 843.078369,2616.912354 848.588379,2615.371826 
	C863.701904,2617.066162 878.093750,2614.412109 893.255554,2615.386719 
	C901.297974,2618.126953 908.339600,2621.933350 917.258911,2619.895264 
	C922.651489,2619.772705 927.316345,2619.696045 932.848389,2619.548340 
	C934.911621,2618.787598 936.023132,2617.972900 937.713623,2616.784668 
	C941.637512,2616.318359 944.982117,2616.224854 949.164124,2616.139648 
	C951.598145,2616.027344 953.197571,2616.004639 955.397705,2616.001953 
	C959.392761,2615.958496 962.787964,2615.916992 967.031128,2615.856445 
	C975.775024,2609.812500 984.370239,2614.250732 992.622253,2614.431641 
	C995.183838,2614.487793 996.887085,2617.295654 996.443298,2621.225342 
	C993.893311,2622.099365 991.745972,2622.075195 988.793823,2622.081543 
	C986.230347,2622.048584 984.465942,2621.954834 982.459229,2623.537109 
	C975.563721,2627.236084 968.614258,2625.514648 961.031738,2626.148193 
	C959.054443,2627.026855 958.019958,2627.964844 956.378296,2629.262695 
	C935.168579,2630.592773 914.417542,2628.766846 892.951660,2631.905273 
	C890.308716,2630.924805 888.505005,2630.026367 886.467041,2630.045898 
	C867.406067,2630.228516 848.673645,2625.937256 828.817627,2625.922852 
z"
        />
        <path
          fill="#597288"
          opacity="1.000000"
          stroke="none"
          d="
M731.140137,2623.916016 
	C732.425598,2629.533447 728.801392,2631.708252 724.125793,2631.806641 
	C711.828674,2632.064941 699.464417,2634.889648 687.234558,2630.786621 
	C681.590332,2628.892822 676.415771,2632.302734 670.478149,2633.644043 
	C662.073303,2639.845459 653.803833,2635.426514 644.887573,2634.596680 
	C643.199036,2634.017578 642.313599,2633.975586 640.763550,2633.918945 
	C638.035522,2632.071777 637.955139,2629.860596 639.444031,2626.789551 
	C649.348999,2626.095459 658.678772,2626.126221 668.007019,2626.025635 
	C673.314331,2625.968262 678.607178,2626.160889 683.844727,2624.528076 
	C687.329407,2623.441895 691.097473,2623.833984 694.737976,2624.580566 
	C706.633179,2627.019043 718.489014,2626.771484 731.140137,2623.916016 
z"
        />
        <path
          fill="#597288"
          opacity="1.000000"
          stroke="none"
          d="
M541.584106,2628.765625 
	C545.457825,2628.014648 548.886108,2628.009521 553.171265,2627.988525 
	C567.848816,2623.232422 582.172974,2622.763184 597.283142,2623.533691 
	C599.347229,2626.664062 601.485657,2628.079834 604.360168,2627.951416 
	C609.886292,2627.704590 615.506958,2628.962402 621.508301,2626.373291 
	C624.885742,2625.215088 627.683655,2624.656494 631.205566,2624.106934 
	C634.838989,2627.896729 633.536804,2631.261719 630.136719,2635.062988 
	C624.092529,2638.181641 618.414124,2637.623535 612.848633,2637.631348 
	C594.069275,2637.657715 575.289368,2637.793457 555.599243,2637.463379 
	C554.093811,2637.275146 553.499329,2637.175537 552.010742,2637.001709 
	C547.499390,2636.716309 544.007568,2636.216309 541.427490,2632.055176 
	C541.112549,2630.669189 541.125610,2630.090088 541.584106,2628.765625 
z"
        />
        <path
          fill="#4E6477"
          opacity="1.000000"
          stroke="none"
          d="
M630.532471,2634.415039 
	C630.567932,2631.006592 631.251465,2628.268555 631.932251,2624.823242 
	C634.352844,2624.066162 636.831482,2623.581787 639.542236,2625.572266 
	C640.023376,2628.196533 640.026855,2630.273193 640.064636,2633.127197 
	C640.098938,2633.904297 640.018250,2633.981689 639.977722,2634.020020 
	C637.218018,2636.104004 634.300232,2636.455322 630.532471,2634.415039 
z"
        />
        <path
          fill="#566C80"
          opacity="1.000000"
          stroke="none"
          d="
M784.469666,2606.060547 
	C784.352112,2605.406494 784.770386,2604.741455 785.883057,2605.752930 
	C785.733887,2606.067383 785.372681,2606.066650 784.469666,2606.060547 
z"
        />
        <path
          fill="#43596D"
          opacity="1.000000"
          stroke="none"
          d="
M541.440918,2627.472168 
	C542.029541,2628.019531 541.980103,2628.990234 541.954346,2629.475586 
	C541.289612,2630.682129 540.561584,2631.298096 539.078125,2632.063965 
	C537.055908,2632.647705 535.674805,2632.797852 533.665039,2632.906250 
	C532.407532,2632.947998 531.588562,2632.898926 531.244690,2633.322021 
	C530.795471,2633.874756 531.259705,2634.392334 532.723267,2634.665283 
	C533.701355,2635.119873 534.080139,2635.366455 534.859009,2636.196777 
	C536.422363,2639.998291 535.574280,2642.673340 532.533691,2645.445557 
	C529.998901,2652.220703 525.854187,2654.616943 519.265930,2654.100098 
	C511.693939,2653.505859 504.573853,2654.879150 500.118958,2662.859375 
	C497.956604,2663.705322 495.794739,2663.827637 492.822083,2663.951416 
	C490.279449,2663.956787 488.533661,2664.038818 486.444855,2662.685059 
	C485.912964,2662.312012 485.913452,2662.138672 485.958557,2662.064453 
	C486.329437,2659.239746 484.989227,2655.923340 489.320801,2654.132568 
	C490.993988,2653.026367 492.013916,2652.152344 493.919800,2651.410156 
	C496.441956,2650.992188 497.715027,2649.733154 499.583252,2648.147461 
	C506.570862,2644.848633 513.282776,2644.774902 520.216797,2645.024658 
	C517.034729,2644.895264 513.517395,2645.436279 510.451538,2642.519531 
	C510.027557,2640.463379 510.017761,2638.975586 510.006409,2636.743896 
	C510.946228,2635.377930 511.887604,2634.755615 513.414795,2634.078613 
	C517.116028,2633.859131 518.345093,2631.924072 518.296021,2628.562988 
	C514.558105,2628.009521 511.148895,2627.990723 506.887634,2627.966309 
	C504.383545,2626.043213 503.907684,2623.920654 505.421997,2620.834229 
	C511.363831,2620.091309 516.799927,2620.053467 523.144287,2620.049561 
	C523.819885,2623.966309 525.380005,2625.981445 529.536560,2626.049316 
	C533.309814,2626.110840 537.191406,2625.274902 541.440918,2627.472168 
z"
        />
        <path
          fill="#374D60"
          opacity="1.000000"
          stroke="none"
          d="
M598.253418,2623.459473 
	C584.001770,2625.987061 570.088379,2628.685059 554.945435,2627.987549 
	C554.063965,2626.625000 549.512695,2624.981934 553.690491,2624.119629 
	C562.425354,2622.317871 571.294067,2620.835205 580.177368,2620.210938 
	C585.199341,2619.858154 589.966858,2618.429688 594.917053,2618.114990 
	C598.169617,2617.907715 600.282410,2618.973145 598.253418,2623.459473 
z"
        />
        <path
          fill="#395166"
          opacity="1.000000"
          stroke="none"
          d="
M832.419189,2601.448730 
	C829.868591,2601.990967 827.735413,2602.001221 824.802368,2602.010742 
	C821.890625,2602.373535 820.282959,2603.592773 818.347046,2605.447754 
	C815.795898,2605.855713 813.720337,2605.877930 810.869385,2605.884277 
	C810.565063,2602.894775 804.976807,2601.368164 808.575012,2597.288086 
	C811.638184,2593.814697 814.564575,2591.481201 819.367432,2592.039307 
	C823.579651,2592.528564 827.519897,2594.504639 831.915283,2594.073242 
	C833.764038,2593.891846 836.379211,2593.588135 836.894470,2595.986816 
	C837.496765,2598.790283 834.435913,2599.440186 832.419189,2601.448730 
z"
        />
        <path
          fill="#4E6477"
          opacity="1.000000"
          stroke="none"
          d="
M505.131592,2620.117188 
	C505.956512,2622.217041 505.985260,2624.304932 506.024811,2627.176758 
	C501.018127,2627.827881 495.580688,2628.993896 492.131653,2622.813477 
	C496.048615,2621.166992 500.016144,2619.551270 505.131592,2620.117188 
z"
        />
        <path
          fill="#566C80"
          opacity="1.000000"
          stroke="none"
          d="
M496.371521,2653.550293 
	C494.475739,2653.948242 492.991455,2653.959961 490.765717,2653.971191 
	C490.416718,2644.281250 490.416168,2644.261963 499.838409,2643.998535 
	C502.804718,2643.915527 505.808228,2644.181152 509.287048,2642.291504 
	C512.296753,2642.157227 514.643433,2642.056152 516.788452,2642.665283 
	C518.564941,2643.169434 520.681519,2643.317871 521.981079,2645.031494 
	C515.883728,2650.124756 507.934357,2646.201416 500.726624,2649.666260 
	C498.957794,2651.084717 497.860168,2652.114014 496.371521,2653.550293 
z"
        />
        <path
          fill="#4E6477"
          opacity="1.000000"
          stroke="none"
          d="
M517.708435,2628.571289 
	C521.851624,2628.315186 520.540405,2630.754395 519.946228,2632.858887 
	C519.148438,2635.684082 517.432251,2636.390869 514.426758,2634.500000 
	C515.150330,2632.387207 516.299988,2630.750488 517.708435,2628.571289 
z"
        />
        <path
          fill="#31475A"
          opacity="1.000000"
          stroke="none"
          d="
M931.270386,2800.550049 
	C934.166626,2800.627197 936.380554,2801.206787 939.278259,2801.873535 
	C940.410950,2806.770264 944.833801,2805.466309 947.498840,2806.875000 
	C949.198669,2807.773682 951.505127,2808.433838 950.915833,2810.981201 
	C950.524536,2812.673096 948.795349,2813.977783 947.180115,2813.685791 
	C939.277161,2812.256836 933.177612,2818.201904 925.447754,2818.639404 
	C920.108826,2818.941406 915.016235,2818.812256 909.993713,2817.801025 
	C900.964966,2815.983154 892.143188,2813.066162 882.730530,2813.926514 
	C879.886414,2814.186768 877.720703,2812.661865 875.750244,2810.751221 
	C873.874146,2808.931885 872.229858,2806.742432 868.708191,2806.113770 
	C866.003052,2807.660889 863.843140,2808.125244 860.747070,2806.437988 
	C856.877136,2802.678711 851.651184,2804.574219 848.714355,2801.230957 
	C856.825195,2799.505615 865.380554,2799.697510 872.883057,2801.273438 
	C877.986694,2802.345947 882.911255,2804.582764 889.159180,2803.980957 
	C893.932251,2800.383789 898.777588,2800.676758 904.440247,2800.844727 
	C913.742249,2799.333740 922.146545,2804.053467 931.270386,2800.550049 
z"
        />
        <path
          fill="#31475A"
          opacity="1.000000"
          stroke="none"
          d="
M813.320679,2803.854736 
	C822.611328,2806.449951 831.331421,2808.169434 841.170227,2808.005371 
	C847.708984,2809.665039 853.417908,2811.320068 859.578247,2812.522461 
	C861.981689,2810.351074 864.137207,2809.885254 867.268799,2811.523926 
	C868.301697,2814.252930 869.417847,2815.704834 871.769592,2815.913574 
	C875.909363,2816.281250 878.416199,2819.494141 883.605896,2821.282471 
	C869.770020,2823.249756 858.638794,2821.424316 848.288574,2815.188721 
	C845.465820,2813.488525 842.448608,2811.807373 838.980774,2812.077637 
	C826.278137,2813.067871 814.020081,2810.133301 800.830322,2808.030762 
	C798.543701,2807.685547 797.643677,2806.720215 796.951294,2804.724121 
	C796.957153,2801.371826 799.025574,2800.274658 801.395020,2798.566162 
	C807.170654,2796.732178 809.124207,2801.720459 813.320679,2803.854736 
z"
        />
        <path
          fill="#3C5163"
          opacity="1.000000"
          stroke="none"
          d="
M752.963867,2826.144531 
	C749.197998,2825.797119 746.147461,2826.515625 742.984802,2824.815430 
	C744.783264,2820.617676 748.739502,2821.811035 751.372253,2821.426270 
	C760.956238,2820.025146 770.505554,2816.243164 781.172302,2820.006836 
	C782.843994,2820.443115 784.112488,2820.634033 784.481018,2821.313232 
	C785.460510,2823.118408 783.778809,2823.801758 782.589844,2823.785156 
	C772.934753,2823.650635 763.572083,2827.056641 752.963867,2826.144531 
z"
        />
        <path
          fill="#31475A"
          opacity="1.000000"
          stroke="none"
          d="
M786.683228,2812.003418 
	C784.321350,2811.726074 781.694946,2812.612793 781.344421,2810.094971 
	C781.041870,2807.922363 783.476624,2808.221680 784.895691,2807.850586 
	C787.191345,2807.250000 787.816650,2805.596924 787.950256,2802.793457 
	C789.540161,2800.618408 791.034851,2801.043945 792.957520,2802.955811 
	C793.995056,2805.385010 793.868286,2807.035645 792.510864,2809.317871 
	C790.445740,2810.645996 788.879272,2811.282471 786.683228,2812.003418 
z"
        />
        <path
          fill="#233444"
          opacity="1.000000"
          stroke="none"
          d="
M867.991699,2811.284668 
	C865.863708,2812.037598 863.740784,2812.037598 860.823792,2812.053711 
	C860.021790,2810.530029 860.013916,2808.990723 860.025085,2806.679199 
	C862.163879,2805.937012 864.283569,2805.966797 867.198914,2806.011719 
	C867.995300,2807.528564 867.996033,2809.030273 867.991699,2811.284668 
z"
        />
        <path
          fill="#2B3E51"
          opacity="1.000000"
          stroke="none"
          d="
M792.737183,2810.009766 
	C792.009521,2808.482666 792.006836,2806.955322 792.002686,2804.665039 
	C793.334106,2802.515869 794.930542,2802.469238 797.319397,2803.476318 
	C798.517578,2804.972656 799.034851,2805.981445 799.773926,2807.513672 
	C798.300598,2810.293701 795.821472,2809.953125 792.737183,2810.009766 
z"
        />
        <path
          fill="#3D494C"
          opacity="1.000000"
          stroke="none"
          d="
M1000.376343,2806.417969 
	C1001.761047,2803.966064 1003.006775,2804.437988 1004.007446,2807.237305 
	C1002.986694,2807.487549 1001.854553,2807.198486 1000.376343,2806.417969 
z"
        />
        <path
          fill="#485D70"
          opacity="1.000000"
          stroke="none"
          d="
M798.371094,2803.472168 
	C796.500610,2803.952637 795.000854,2803.941650 792.751221,2803.916260 
	C791.050720,2803.301758 790.100220,2802.701904 788.561646,2802.081299 
	C787.085999,2802.046387 786.198486,2802.032227 784.644531,2802.013916 
	C784.335022,2800.927246 784.691956,2799.844727 785.509583,2798.410156 
	C790.770264,2799.571045 795.571106,2797.630371 801.127747,2798.084229 
	C802.036316,2800.504883 800.404297,2801.752686 798.371094,2803.472168 
z"
        />
        <path
          fill="#2B3E51"
          opacity="1.000000"
          stroke="none"
          d="
M638.096680,2822.648193 
	C638.650757,2820.968018 639.596436,2820.312012 641.459351,2820.098145 
	C641.692688,2821.803955 642.654602,2824.160156 640.121704,2824.887451 
	C639.126953,2825.173096 638.520752,2824.185059 638.096680,2822.648193 
z"
        />
        <path
          fill="#EEE7D5"
          opacity="1.000000"
          stroke="none"
          d="
M1208.584351,3159.354004 
	C1205.950806,3149.593262 1197.836914,3143.632324 1192.502319,3135.783447 
	C1182.075806,3120.443359 1168.220703,3107.952881 1156.052246,3094.064697 
	C1149.503784,3086.590576 1141.534302,3079.583008 1130.745483,3076.091797 
	C1129.667725,3070.237305 1128.324097,3066.283936 1121.145996,3066.948730 
	C1117.604736,3067.276855 1113.831055,3062.288086 1110.126587,3067.295898 
	C1109.830688,3067.695801 1107.644531,3067.481201 1107.468628,3067.069092 
	C1104.578857,3060.298340 1099.235107,3064.691650 1094.432861,3063.952148 
	C1095.536499,3052.859863 1100.996094,3043.246826 1103.950928,3032.792969 
	C1105.152954,3028.539551 1105.128418,3023.524170 1108.564209,3019.848389 
	C1109.719482,3020.875732 1111.003540,3022.847900 1111.940063,3022.694580 
	C1114.751953,3022.234619 1113.963989,3019.699463 1113.394165,3017.959717 
	C1113.026001,3016.835938 1111.687988,3016.029785 1110.375244,3014.535645 
	C1110.975586,3008.152832 1113.803711,3002.961426 1115.918701,2996.761719 
	C1122.847778,2994.900879 1121.634888,2988.902344 1122.486572,2984.271973 
	C1121.452393,2976.521973 1125.403320,2970.232422 1127.921265,2962.773438 
	C1128.645386,2961.731201 1129.260376,2961.398438 1129.920654,2960.525879 
	C1133.301758,2954.224365 1130.788086,2946.748779 1135.701050,2940.662109 
	C1141.426270,2939.783936 1145.756226,2943.597656 1151.513794,2944.466064 
	C1156.987549,2950.771973 1163.976318,2954.137207 1170.716187,2959.632324 
	C1171.265869,2944.922852 1182.103027,2947.326904 1191.702393,2947.358398 
	C1192.194946,2943.996094 1188.291016,2942.595459 1188.671997,2939.440186 
	C1189.053833,2936.276855 1190.187012,2933.664551 1192.915283,2931.967773 
	C1194.736572,2930.834961 1196.432739,2931.026367 1198.404907,2932.260254 
	C1209.768188,2939.370361 1215.306763,2938.952148 1226.235352,2929.348877 
	C1232.375244,2923.953369 1238.857300,2921.033447 1247.599976,2923.353760 
	C1250.184204,2927.039551 1248.851318,2930.127197 1246.219360,2931.541260 
	C1238.646240,2935.610352 1233.163452,2942.704590 1224.602051,2945.416260 
	C1214.523193,2948.608887 1206.604126,2955.326904 1197.976196,2961.297852 
	C1185.888672,2969.662842 1177.164062,2982.934814 1162.003418,2987.528320 
	C1169.013672,2989.550537 1177.029297,2984.706055 1183.614990,2991.363281 
	C1191.500366,3000.742920 1190.987427,3003.569336 1179.977173,3005.072021 
	C1171.142700,3006.278076 1165.491699,3012.164795 1160.073242,3017.305908 
	C1152.731812,3024.270752 1145.430664,3025.584717 1139.804321,3024.054443 
	C1143.547241,3024.917969 1151.018677,3025.671631 1154.897583,3033.471436 
	C1157.171753,3038.044678 1161.039673,3036.449951 1164.289551,3034.482178 
	C1175.596313,3027.635986 1175.561523,3027.584229 1185.753540,3035.828857 
	C1186.523560,3036.451660 1187.308350,3037.076172 1188.510376,3037.280518 
	C1190.518433,3036.053711 1188.754395,3034.463379 1188.644897,3033.052490 
	C1187.910889,3023.594971 1192.352539,3018.927734 1201.674561,3020.896973 
	C1209.599976,3022.571289 1217.153564,3026.119141 1224.784302,3023.441650 
	C1222.265503,3021.445068 1222.121094,3018.468994 1225.065430,3015.432129 
	C1227.389771,3013.034424 1229.994629,3009.939697 1232.974609,3009.568359 
	C1244.134277,3008.177002 1252.276978,2999.781982 1262.994263,2997.488281 
	C1270.026733,2995.983154 1274.378906,2989.596924 1279.563232,2985.534668 
	C1288.438843,2978.579834 1297.682861,2975.134521 1308.968872,2973.419678 
	C1313.905762,2971.588867 1315.434448,2967.104736 1318.833740,2964.569580 
	C1323.106812,2961.382812 1327.672241,2960.619141 1333.379761,2962.409668 
	C1347.597656,2973.834961 1356.592651,2964.685303 1365.079590,2955.146484 
	C1362.561768,2953.623047 1356.005127,2954.909180 1357.489380,2948.649658 
	C1358.740356,2943.374268 1363.791016,2943.128662 1368.114014,2944.267334 
	C1374.633667,2945.984619 1383.287354,2944.130127 1385.239746,2954.301270 
	C1385.884155,2957.657959 1390.152954,2957.029785 1393.406616,2958.519775 
	C1400.902100,2966.227051 1400.838013,2969.864990 1392.188477,2973.781494 
	C1386.100708,2976.538330 1384.659912,2979.621582 1385.900391,2985.999756 
	C1387.283325,2993.111816 1384.278320,2999.905518 1380.605347,3005.887939 
	C1375.992188,3013.401367 1368.483032,3013.509277 1361.754517,3006.593994 
	C1361.290649,3008.624268 1362.444946,3009.775391 1363.515747,3011.063965 
	C1369.123047,3017.812744 1368.842529,3019.199951 1361.044189,3023.568115 
	C1359.592773,3024.381348 1357.585083,3024.730957 1356.719482,3025.986084 
	C1349.831055,3035.976074 1340.477051,3039.334473 1328.418579,3036.438721 
	C1334.873169,3041.070557 1341.714966,3043.012207 1348.952271,3043.863525 
	C1352.037720,3044.226074 1355.093384,3044.888916 1355.257446,3048.870361 
	C1355.392700,3052.151855 1353.097534,3053.743164 1350.366577,3054.291748 
	C1345.613892,3055.246338 1342.691040,3057.679443 1341.519287,3062.518555 
	C1340.851929,3065.274902 1338.624878,3067.418213 1335.763550,3067.356201 
	C1322.367798,3067.065430 1317.739502,3079.657959 1308.694824,3085.710938 
	C1306.203735,3087.377686 1304.954590,3091.765381 1306.925293,3094.868652 
	C1309.345581,3098.679443 1313.548462,3099.437744 1318.576416,3098.765625 
	C1315.228882,3098.599121 1312.593140,3098.129639 1310.297852,3096.645264 
	C1305.277344,3093.399170 1305.046997,3087.532227 1310.680664,3086.013428 
	C1327.167969,3081.567627 1337.773804,3064.973389 1356.078857,3064.663574 
	C1359.046265,3064.613281 1360.521118,3062.283936 1361.793945,3059.731934 
	C1370.108032,3043.060303 1370.863281,3042.699219 1388.962036,3044.730469 
	C1390.913208,3044.949219 1392.927490,3045.262451 1395.475586,3044.030273 
	C1393.217285,3040.229248 1389.842285,3035.943848 1393.941406,3031.823975 
	C1397.580078,3028.166748 1402.252686,3029.838867 1406.142456,3031.657715 
	C1415.047852,3035.822021 1425.527100,3032.850098 1434.606445,3038.640625 
	C1435.937012,3040.197998 1436.309692,3041.501709 1436.946777,3042.650391 
	C1444.366577,3056.031982 1443.140625,3058.263184 1427.633789,3058.878418 
	C1429.627319,3059.214844 1431.356689,3058.961426 1433.071167,3059.070801 
	C1436.996216,3059.321533 1441.729004,3058.325684 1444.095093,3062.181396 
	C1447.088989,3067.060547 1440.403198,3068.581299 1439.623657,3071.886719 
	C1437.065308,3082.737305 1428.205688,3082.748779 1420.234253,3083.763428 
	C1414.894409,3084.443359 1408.049438,3083.367920 1406.104126,3078.326172 
	C1401.924438,3067.495361 1394.017944,3070.947998 1388.582520,3070.829102 
	C1389.350464,3070.622803 1391.574341,3072.084473 1393.039673,3073.851074 
	C1401.610962,3084.184326 1412.985718,3084.420410 1424.867676,3083.138184 
	C1433.360962,3082.221924 1435.839111,3085.234619 1432.651978,3093.387207 
	C1430.864990,3097.958496 1430.272949,3102.323975 1431.722900,3107.664062 
	C1431.552490,3111.249756 1432.594849,3113.414551 1435.864014,3115.075439 
	C1436.500000,3115.997803 1436.648193,3116.433838 1436.405640,3117.439941 
	C1435.048218,3119.846924 1433.749390,3121.502441 1431.607056,3121.703125 
	C1427.470093,3122.091064 1423.033203,3121.958740 1426.927612,3128.140625 
	C1427.159668,3128.509033 1426.834961,3129.677246 1426.429932,3129.930664 
	C1419.505249,3134.258545 1418.855225,3144.012939 1409.330322,3147.671387 
	C1415.639160,3150.633545 1416.286377,3153.455078 1413.287354,3158.838623 
	C1410.729614,3163.429932 1410.539062,3169.594482 1409.883179,3175.227051 
	C1407.932617,3191.978516 1407.213623,3190.812988 1392.198242,3194.027832 
	C1390.901855,3194.305664 1389.611450,3194.626465 1388.302856,3194.827393 
	C1387.385376,3194.968018 1386.441772,3194.938721 1384.718750,3195.025146 
	C1386.489624,3188.730957 1390.205200,3185.938721 1396.098389,3186.012939 
	C1400.357422,3186.066406 1401.922607,3184.057373 1402.078857,3179.936768 
	C1402.190186,3177.001221 1403.358398,3174.121094 1403.719116,3171.175293 
	C1404.142944,3167.713867 1402.778564,3166.148438 1399.052246,3167.382812 
	C1394.960938,3168.738281 1390.820923,3169.798096 1386.489380,3170.397217 
	C1381.717163,3171.057129 1377.498901,3176.904541 1376.377686,3181.621582 
	C1375.393799,3185.761719 1380.722534,3185.313721 1380.718384,3188.527588 
	C1377.845947,3191.184326 1374.280762,3189.394775 1371.190674,3190.214600 
	C1369.785278,3190.587402 1368.663330,3191.254883 1368.560425,3192.739258 
	C1368.394775,3195.127930 1369.992554,3195.399414 1371.823120,3195.011963 
	C1373.177612,3194.725098 1374.395386,3194.558350 1375.121338,3196.184326 
	C1374.917969,3198.822021 1372.865601,3201.060303 1370.901489,3201.079346 
	C1362.349487,3201.163086 1354.459473,3205.018799 1345.939941,3205.027832 
	C1344.542847,3205.029297 1343.149658,3205.220459 1342.560547,3203.809570 
	C1341.904907,3202.239014 1341.887207,3200.404297 1343.198120,3199.144775 
	C1344.370728,3198.017822 1345.799683,3197.010986 1347.298096,3196.395508 
	C1353.046509,3194.033447 1358.248901,3191.508301 1362.689819,3186.484131 
	C1370.399536,3177.762207 1379.623901,3170.584229 1381.265381,3157.743652 
	C1382.469360,3148.325928 1382.716064,3147.698242 1371.979858,3144.081055 
	C1380.831299,3140.774414 1390.221924,3140.266846 1395.309937,3130.990723 
	C1389.311646,3130.386475 1383.866699,3133.410645 1378.613892,3131.846680 
	C1372.578857,3130.049561 1366.798828,3132.320312 1360.899536,3132.073242 
	C1359.229736,3132.003418 1357.340332,3132.234375 1357.044189,3134.301758 
	C1356.850708,3135.651855 1357.977295,3136.674805 1359.131226,3137.443604 
	C1361.040894,3138.715576 1363.463135,3139.256104 1365.331665,3142.194824 
	C1359.861206,3143.846436 1355.198486,3147.169434 1349.326050,3146.693115 
	C1347.268799,3146.526123 1345.155640,3145.888428 1344.605835,3148.616943 
	C1344.100342,3151.125488 1346.174683,3152.338379 1348.025757,3152.805664 
	C1352.775146,3154.004883 1357.531616,3155.639404 1362.562744,3154.718018 
	C1364.489502,3154.364990 1366.397217,3153.402100 1368.448486,3155.238037 
	C1367.051880,3159.474854 1363.605835,3159.838379 1359.848267,3160.037354 
	C1354.941284,3160.296875 1349.444946,3158.071777 1346.945557,3165.797607 
	C1345.875366,3169.105713 1339.537598,3167.004639 1336.078003,3167.054199 
	C1330.314575,3167.136963 1322.194824,3168.441895 1320.924805,3159.354492 
	C1318.952271,3159.343018 1318.317871,3160.092285 1318.438232,3161.172607 
	C1319.469971,3170.436035 1319.324707,3170.682861 1310.784668,3171.969727 
	C1309.473145,3172.167236 1308.061646,3171.973877 1306.837891,3172.383545 
	C1306.065918,3172.642334 1305.067627,3173.663330 1305.034180,3174.382324 
	C1304.969238,3175.776123 1306.034668,3177.016113 1307.405640,3177.146729 
	C1308.634888,3177.263428 1309.957764,3176.717529 1311.199097,3176.333984 
	C1317.608032,3174.354736 1317.657837,3174.332275 1318.705566,3181.624268 
	C1319.115967,3184.480713 1320.927979,3185.616943 1323.437012,3185.822266 
	C1327.083008,3186.120605 1330.729248,3186.275146 1334.282837,3184.910156 
	C1337.812134,3183.554932 1340.642212,3184.669922 1343.493286,3188.447510 
	C1337.589722,3190.585938 1332.732788,3192.464111 1326.344604,3190.650879 
	C1315.040161,3187.441650 1303.304443,3190.932129 1291.774170,3189.984131 
	C1288.323486,3189.700439 1284.617432,3190.866699 1280.091797,3188.071289 
	C1285.551636,3185.496826 1289.998779,3184.570557 1294.840332,3185.138916 
	C1296.275879,3185.307129 1297.489624,3184.752441 1297.461914,3183.014160 
	C1297.437744,3181.501221 1296.528931,3180.343506 1295.088135,3180.346436 
	C1289.203979,3180.359131 1283.328369,3181.203369 1277.430664,3180.234863 
	C1275.982178,3179.997070 1274.536987,3181.298584 1274.896973,3182.600586 
	C1276.328491,3187.778564 1271.708130,3190.197266 1269.987671,3193.889404 
	C1269.287109,3195.393311 1267.871094,3196.677246 1268.899902,3198.308350 
	C1270.166382,3200.316650 1272.395874,3200.123291 1274.298462,3199.522705 
	C1276.506714,3198.825928 1278.559814,3197.650635 1280.711792,3196.759033 
	C1283.637207,3195.546387 1285.916138,3196.725830 1287.752808,3198.949219 
	C1290.117310,3201.812500 1287.420044,3202.895508 1285.783936,3204.433350 
	C1283.478760,3206.600586 1282.193848,3209.878662 1282.490723,3212.501953 
	C1283.122681,3218.089844 1280.400513,3219.518555 1275.848877,3220.000488 
	C1274.028809,3220.193359 1271.242432,3219.557861 1271.203369,3222.369873 
	C1271.161377,3225.395020 1273.928345,3225.788574 1276.279297,3225.890869 
	C1280.900146,3226.091797 1285.522583,3225.561523 1288.705933,3230.372070 
	C1290.291748,3232.768311 1293.232422,3231.957764 1294.642456,3229.819824 
	C1298.333496,3224.223145 1303.747559,3223.753662 1309.596680,3223.960693 
	C1312.788086,3224.073730 1315.658936,3223.632812 1315.886719,3219.416504 
	C1316.097656,3215.510742 1314.061401,3214.105469 1310.492310,3213.909912 
	C1308.431152,3213.796875 1306.246216,3213.189209 1306.804443,3210.468018 
	C1307.318115,3207.963623 1309.700317,3208.115479 1311.658813,3208.048340 
	C1314.320679,3207.957275 1316.993652,3208.122559 1319.650635,3207.975342 
	C1323.915161,3207.739502 1327.242676,3208.275879 1328.995117,3213.213135 
	C1330.321289,3216.949707 1333.248047,3217.972656 1336.681519,3214.509033 
	C1338.257324,3212.918945 1340.521973,3211.936035 1342.601562,3210.944580 
	C1344.356323,3210.107910 1345.948486,3210.906494 1346.759766,3212.551758 
	C1347.811401,3214.684326 1346.124023,3215.318115 1344.560791,3216.085205 
	C1339.173340,3218.729736 1333.541748,3220.475586 1327.395142,3220.539062 
	C1327.274902,3226.153809 1333.262207,3224.578125 1334.990601,3228.346680 
	C1330.943359,3231.494141 1326.166626,3229.604004 1321.829712,3229.939941 
	C1314.296509,3230.523438 1306.419067,3228.534912 1299.496948,3233.261963 
	C1298.678101,3233.821289 1297.680054,3234.123291 1296.877441,3234.700684 
	C1295.703979,3235.545410 1294.928223,3236.826172 1296.544189,3237.743408 
	C1301.429688,3240.516113 1298.263062,3241.278809 1295.564331,3242.429932 
	C1293.824341,3243.172363 1291.677246,3243.583984 1291.060425,3246.843018 
	C1295.964478,3248.165771 1302.208496,3248.301758 1302.734863,3255.776855 
	C1302.888672,3257.961182 1305.715820,3258.085449 1307.747192,3257.591064 
	C1310.532593,3256.913330 1313.251587,3254.955566 1313.260986,3252.418945 
	C1313.291016,3244.380859 1318.416504,3244.081055 1324.390015,3243.393555 
	C1330.725708,3242.664307 1336.535400,3244.341797 1342.804932,3245.672363 
	C1330.697754,3248.417969 1324.189087,3260.748291 1311.367676,3262.506348 
	C1306.090942,3263.229736 1307.822144,3270.004883 1306.097778,3274.342041 
	C1311.919067,3275.074463 1317.050415,3275.697266 1322.173218,3276.383545 
	C1323.149536,3276.514404 1324.089111,3276.908203 1325.051147,3277.159668 
	C1328.355957,3278.023193 1327.591187,3271.616211 1330.813965,3273.574219 
	C1332.839966,3274.804932 1333.425049,3277.907227 1336.557983,3278.531250 
	C1338.904053,3278.998291 1340.979370,3279.029785 1343.350342,3278.653564 
	C1352.135376,3277.260986 1360.171631,3277.787598 1368.594238,3280.287109 
	C1356.018066,3282.035889 1345.439331,3286.236816 1334.630859,3283.479980 
	C1324.977783,3281.017578 1316.248657,3282.376953 1306.622803,3285.951172 
	C1302.235840,3282.345459 1301.447876,3277.201172 1299.239746,3272.778809 
	C1287.554321,3249.375977 1270.612793,3229.886719 1252.950439,3210.144287 
	C1239.929565,3198.710449 1229.056641,3186.199219 1218.816650,3172.205566 
	C1218.465454,3171.606689 1218.143921,3171.761475 1218.278809,3171.644531 
	C1213.889771,3169.161377 1211.844360,3164.649658 1208.772461,3160.179688 
	C1208.457520,3159.605469 1208.560913,3159.446045 1208.584351,3159.354004 
z"
        />
        <path
          fill="#EEE7D5"
          opacity="1.000000"
          stroke="none"
          d="
M1261.737793,2760.635254 
	C1262.006470,2758.217041 1261.835449,2756.406250 1263.470581,2754.425781 
	C1267.331665,2751.701172 1269.319458,2753.996094 1269.811768,2756.603516 
	C1271.554199,2765.833252 1275.831543,2775.345459 1269.101318,2784.261719 
	C1265.733398,2788.723633 1267.876099,2789.968018 1272.010132,2790.491943 
	C1276.952393,2791.118164 1282.176880,2791.884766 1286.709839,2789.791504 
	C1292.929688,2786.919434 1298.901489,2785.026611 1305.746216,2787.154297 
	C1307.585083,2787.725830 1310.212036,2787.220215 1310.705200,2784.993408 
	C1311.350464,2782.080322 1308.849365,2781.040039 1306.489380,2780.603760 
	C1301.074463,2779.602783 1296.602539,2777.987061 1299.165527,2770.383301 
	C1303.032715,2766.790283 1307.033936,2767.781982 1310.894409,2768.479492 
	C1319.200928,2769.979980 1327.165894,2773.914062 1335.998047,2771.601074 
	C1337.489868,2771.210205 1339.291260,2771.962158 1340.731323,2772.790527 
	C1345.054565,2775.276855 1350.827026,2778.462891 1349.534302,2783.384521 
	C1348.251709,2788.267334 1341.969727,2786.902100 1337.718628,2786.279297 
	C1341.585938,2787.626709 1345.954834,2786.611328 1350.868652,2787.450195 
	C1352.018921,2787.715576 1352.469849,2787.856689 1353.560669,2788.299316 
	C1364.410522,2794.657227 1374.238037,2790.559082 1384.721924,2787.098389 
	C1385.787842,2786.523682 1386.235840,2786.323975 1387.394775,2785.903564 
	C1389.588623,2785.355713 1391.096069,2785.291992 1393.374268,2785.260498 
	C1405.684937,2787.325928 1412.965942,2795.230469 1420.051758,2804.597656 
	C1423.949707,2813.354736 1430.011353,2821.152588 1422.676025,2831.033691 
	C1418.883423,2836.170166 1415.140503,2840.009277 1408.111084,2838.747559 
	C1403.092407,2830.900879 1396.795044,2832.143555 1390.362671,2834.684326 
	C1381.692139,2838.108887 1372.949707,2840.526367 1362.918701,2837.609619 
	C1359.619629,2833.925537 1356.617310,2831.755615 1352.062012,2833.797119 
	C1350.553101,2834.473145 1348.820435,2834.523438 1347.176636,2834.798828 
	C1332.428833,2837.269287 1330.432129,2835.332764 1332.558472,2820.636719 
	C1332.743652,2819.356445 1333.252930,2818.086914 1332.823975,2816.762207 
	C1327.156128,2820.338623 1320.076904,2822.708496 1320.623901,2831.491943 
	C1320.678833,2832.374512 1319.744507,2833.479492 1318.844482,2833.777588 
	C1307.347778,2837.587158 1304.347778,2848.729492 1298.422729,2857.332031 
	C1296.322510,2860.380859 1294.317261,2863.608398 1290.381836,2864.379883 
	C1285.174072,2865.401123 1282.461548,2868.802490 1280.671631,2873.433105 
	C1279.693237,2875.964600 1278.152222,2878.614258 1275.494629,2879.218018 
	C1268.957520,2880.702393 1263.972046,2883.301025 1262.281860,2890.571777 
	C1261.764160,2892.798584 1258.809692,2893.643799 1256.300781,2892.687988 
	C1253.327881,2891.554932 1251.312134,2889.347412 1250.534058,2886.239990 
	C1249.522705,2882.202148 1250.769775,2878.303467 1251.582520,2874.404053 
	C1254.393066,2860.919678 1261.623901,2848.227051 1258.547852,2833.663818 
	C1258.003662,2831.087646 1259.742554,2828.485107 1261.369019,2826.347656 
	C1264.015259,2822.870361 1263.072998,2820.200928 1259.786011,2817.956787 
	C1257.467163,2816.373047 1255.267456,2812.919189 1252.130371,2815.921387 
	C1249.401978,2818.532471 1249.274536,2822.063232 1252.140869,2824.822754 
	C1257.576416,2830.055664 1256.520264,2835.633057 1253.150635,2841.155273 
	C1248.662720,2848.510010 1248.290283,2857.607910 1242.775513,2864.546387 
	C1240.257935,2867.713867 1242.192017,2871.280029 1242.642456,2874.902100 
	C1244.552612,2890.260498 1245.068970,2892.249023 1230.942871,2894.474609 
	C1223.699341,2895.615723 1221.426270,2901.441650 1218.895874,2906.629883 
	C1211.190674,2922.429932 1196.791260,2928.236572 1180.052979,2921.052979 
	C1179.517700,2914.538086 1182.551758,2907.808105 1175.449463,2903.394043 
	C1174.788086,2902.982910 1174.653320,2901.339355 1175.208618,2900.570068 
	C1182.384277,2890.629639 1177.860596,2878.639160 1180.986938,2867.985840 
	C1182.594971,2862.506592 1178.728760,2857.851318 1177.996094,2852.609375 
	C1175.358521,2833.742676 1185.624023,2819.345459 1193.992065,2804.477295 
	C1197.470703,2798.296387 1198.996582,2790.723633 1205.506836,2786.158203 
	C1207.731567,2784.598145 1206.607666,2781.066162 1205.646973,2778.614990 
	C1203.046143,2771.979980 1204.253906,2766.259766 1209.576904,2760.638672 
	C1210.303711,2759.713867 1210.626953,2759.371582 1211.504395,2758.595215 
	C1221.863403,2755.235352 1223.172363,2756.013916 1224.512329,2765.414307 
	C1226.017212,2775.969482 1231.793823,2783.460693 1241.689697,2788.746582 
	C1244.968262,2791.743164 1244.742065,2795.885742 1248.895508,2800.589355 
	C1246.530640,2794.432129 1245.010864,2790.328613 1245.644043,2785.275391 
	C1246.390625,2781.491699 1245.561646,2777.933105 1249.104370,2775.269043 
	C1255.166382,2777.258057 1258.488281,2775.988037 1259.137817,2769.704346 
	C1259.438477,2766.795654 1259.768677,2763.818848 1261.737793,2760.635254 
z"
        />
        <path
          fill="#E9E1D0"
          opacity="1.000000"
          stroke="none"
          d="
M1211.670654,2759.352051 
	C1211.622437,2761.263916 1211.623901,2762.930908 1210.793579,2763.703613 
	C1206.288208,2767.896484 1205.177734,2772.305420 1208.708740,2777.730957 
	C1210.607910,2780.648926 1210.009399,2784.162354 1209.895508,2787.464600 
	C1209.862915,2788.409180 1209.274658,2790.174805 1209.125122,2790.155273 
	C1200.314087,2789.004395 1203.106567,2797.583496 1199.899292,2801.057617 
	C1194.627930,2806.767334 1192.645386,2814.386963 1188.462402,2820.652100 
	C1185.092285,2825.699951 1184.416382,2831.789795 1180.978027,2836.694580 
	C1179.781250,2838.401611 1179.810913,2840.579590 1180.511475,2842.467773 
	C1181.702148,2845.676758 1182.389648,2848.790039 1181.036011,2852.126709 
	C1179.908691,2854.905762 1181.071777,2857.740234 1182.527954,2859.658203 
	C1186.009033,2864.243164 1187.603149,2868.210205 1182.111206,2872.345215 
	C1181.528198,2872.784424 1181.102173,2874.577148 1181.440796,2874.923340 
	C1186.872314,2880.479980 1181.744629,2886.304688 1181.966675,2891.957764 
	C1182.017456,2893.252441 1181.749023,2894.597168 1181.414795,2895.862549 
	C1180.699585,2898.570801 1175.310547,2899.186523 1177.554688,2902.858154 
	C1178.776733,2904.857666 1181.807251,2905.818604 1184.118530,2907.070312 
	C1187.778198,2909.052490 1188.273926,2911.225586 1184.607788,2913.596680 
	C1182.614868,2914.885742 1180.662109,2916.077148 1180.087891,2919.316406 
	C1181.263916,2923.837646 1178.552246,2925.533691 1175.382568,2927.661133 
	C1164.786743,2930.169434 1158.878174,2936.799316 1154.544067,2946.241699 
	C1153.070435,2947.051270 1152.122559,2947.091064 1150.580200,2946.434570 
	C1148.385620,2943.882568 1146.508301,2944.570312 1144.775269,2945.665039 
	C1138.812256,2949.431396 1138.633911,2945.688965 1138.802612,2941.227783 
	C1138.814819,2940.904785 1137.789429,2940.542725 1136.630615,2940.094971 
	C1138.734985,2933.881104 1136.559448,2926.526123 1141.475586,2920.367188 
	C1143.696045,2921.548828 1143.629883,2923.435791 1142.868896,2925.424561 
	C1143.755371,2923.602051 1145.266479,2922.585449 1147.013550,2921.576416 
	C1150.354492,2919.647217 1150.645630,2915.900635 1150.458130,2912.743896 
	C1150.193604,2908.287354 1151.917480,2905.593018 1155.862671,2904.441650 
	C1159.870728,2903.272461 1162.840820,2904.973877 1164.753784,2909.181641 
	C1159.365234,2899.709473 1160.725220,2890.892822 1163.212158,2880.899414 
	C1165.554443,2871.488037 1166.744263,2860.907715 1162.521973,2850.481445 
	C1158.697754,2845.499756 1158.697754,2845.499756 1164.507446,2842.818359 
	C1165.414185,2840.105469 1162.801636,2839.674072 1161.554688,2838.433594 
	C1158.811646,2835.704834 1156.920166,2833.494141 1162.353149,2831.446045 
	C1164.525635,2830.627197 1164.460083,2821.033203 1162.413330,2818.448730 
	C1157.362671,2812.070801 1157.277832,2805.342529 1161.206543,2798.106445 
	C1166.338257,2795.618896 1166.060181,2790.773438 1167.662231,2786.931396 
	C1170.800293,2779.406494 1172.274048,2771.036377 1179.482910,2765.148438 
	C1187.442871,2769.842285 1183.208984,2775.991455 1181.132690,2780.848389 
	C1177.657715,2788.977783 1175.453735,2797.035400 1176.452881,2805.129150 
	C1179.770752,2797.719238 1181.610962,2789.128174 1187.463135,2781.778320 
	C1192.456543,2775.506836 1190.605835,2766.553223 1191.531006,2758.768311 
	C1191.993164,2754.880859 1190.724487,2750.725586 1194.793823,2747.253662 
	C1203.777222,2746.763672 1206.385498,2754.313232 1211.670654,2759.352051 
z"
        />
        <path
          fill="#F6EEDE"
          opacity="1.000000"
          stroke="none"
          d="
M1352.602783,2789.450195 
	C1344.949463,2790.789307 1338.178101,2788.901123 1331.322144,2787.846924 
	C1329.285034,2787.533936 1326.991333,2786.821533 1327.175049,2784.468994 
	C1327.413696,2781.411377 1329.966675,2782.282471 1331.729614,2782.927002 
	C1336.942139,2784.833008 1342.240112,2783.520020 1347.887817,2783.535645 
	C1343.977051,2782.080811 1344.486328,2777.308838 1341.057373,2775.657959 
	C1336.954468,2773.681885 1332.783447,2773.038574 1328.742065,2775.472168 
	C1327.043945,2776.494629 1325.004150,2778.756836 1323.618774,2777.039062 
	C1317.750000,2769.761719 1308.973145,2772.396240 1300.331665,2769.809814 
	C1297.879150,2769.058594 1296.947510,2768.310791 1295.612061,2767.072754 
	C1294.906372,2766.303711 1294.643921,2765.971680 1294.039551,2765.095215 
	C1292.959961,2761.573730 1290.386841,2760.688965 1287.430664,2759.225586 
	C1278.759277,2754.590088 1275.229736,2746.583008 1270.807861,2739.291260 
	C1269.194702,2736.630615 1269.251099,2732.650391 1264.465576,2731.993896 
	C1258.380615,2732.283691 1255.906982,2729.653564 1255.915039,2724.250244 
	C1255.920410,2720.739990 1253.961548,2718.130859 1251.619141,2715.574219 
	C1249.048950,2712.768555 1246.459839,2711.620117 1242.858154,2713.233643 
	C1242.340454,2713.465576 1241.270386,2713.396729 1240.967285,2713.036865 
	C1235.470825,2706.509033 1227.444702,2708.938721 1220.617188,2707.146973 
	C1211.379272,2704.722900 1203.059326,2706.425293 1195.180664,2711.643799 
	C1190.849976,2714.512207 1186.284058,2716.218262 1182.178101,2710.112305 
	C1184.376221,2703.229736 1191.085693,2703.545654 1196.036621,2699.950684 
	C1213.534912,2695.259766 1229.640381,2699.565186 1246.472900,2703.787109 
	C1251.157715,2706.616455 1255.023682,2709.464844 1260.831299,2709.422852 
	C1262.042603,2709.613037 1262.525757,2709.718994 1263.719971,2710.059326 
	C1265.828369,2710.886230 1267.121460,2711.661865 1268.945312,2713.018066 
	C1283.206421,2725.144043 1299.672974,2731.287598 1316.300171,2737.013184 
	C1329.194702,2741.453613 1342.277710,2745.508057 1354.951660,2750.392822 
	C1364.298218,2753.994629 1371.247559,2762.582031 1380.954834,2767.751953 
	C1382.491333,2778.598145 1380.016602,2781.954346 1371.146606,2785.239746 
	C1365.259521,2787.420410 1358.643188,2785.156494 1352.602783,2789.450195 
z"
        />
        <path
          fill="#DBD5C8"
          opacity="1.000000"
          stroke="none"
          d="
M1263.848633,2732.975342 
	C1264.250000,2730.270264 1263.466675,2727.681885 1265.939575,2727.093994 
	C1268.325806,2726.527100 1269.356445,2728.705811 1270.145752,2730.482178 
	C1272.540405,2735.871826 1275.295776,2741.016846 1278.229614,2746.146240 
	C1280.797607,2750.635742 1284.219849,2753.462158 1287.873047,2757.317383 
	C1289.883667,2760.865479 1293.496094,2761.985352 1295.548096,2765.556641 
	C1295.995117,2766.008789 1296.000000,2766.000000 1295.997559,2766.000000 
	C1297.059814,2767.061279 1298.120361,2768.126953 1299.614014,2769.555176 
	C1300.033813,2769.931641 1300.022461,2769.963623 1300.046631,2769.962402 
	C1300.070801,2774.410889 1299.391846,2779.463379 1306.753418,2776.841797 
	C1308.862793,2776.090332 1310.680786,2777.260254 1312.265991,2778.464600 
	C1315.286621,2780.759521 1316.788940,2785.990723 1315.230835,2788.260254 
	C1314.048462,2789.982422 1305.152954,2792.435791 1303.264771,2790.791260 
	C1298.613403,2786.740723 1295.488647,2788.949951 1291.010986,2791.516357 
	C1281.693970,2796.856934 1271.712891,2793.981689 1262.145264,2791.315186 
	C1259.819092,2790.666992 1259.748657,2787.615234 1261.777222,2786.592285 
	C1271.533203,2781.672852 1271.073608,2773.940918 1268.252319,2765.333008 
	C1267.077393,2761.748291 1269.779907,2757.181152 1264.733398,2754.187256 
	C1263.948242,2747.294678 1263.918945,2740.589600 1263.848633,2732.975342 
z"
        />
        <path
          fill="#D7D5CF"
          opacity="1.000000"
          stroke="none"
          d="
M1212.002441,2760.004883 
	C1209.250610,2760.083740 1206.674194,2759.706055 1205.467285,2756.764648 
	C1203.858765,2752.844482 1200.213135,2751.054932 1196.563721,2748.319824 
	C1192.848022,2744.542236 1190.073608,2740.788330 1186.673706,2736.642090 
	C1185.820801,2731.493164 1187.525269,2727.896973 1191.253906,2724.427002 
	C1195.755615,2724.586914 1199.408691,2724.897217 1203.434692,2724.368164 
	C1214.235229,2722.949951 1225.004150,2723.867188 1235.541504,2729.407471 
	C1238.663208,2731.992920 1242.264160,2731.252197 1245.454834,2733.552246 
	C1246.581421,2734.966553 1247.212891,2735.903809 1247.892090,2737.428711 
	C1248.006348,2739.195312 1248.368530,2740.264404 1249.508301,2741.614258 
	C1251.602173,2745.387939 1253.849731,2748.531250 1253.850830,2753.238037 
	C1252.037354,2755.664307 1252.025024,2757.971680 1251.565674,2760.864746 
	C1251.066528,2761.903809 1250.799683,2762.286133 1249.988525,2763.109619 
	C1236.444946,2767.893799 1233.402344,2766.762207 1226.965576,2755.189209 
	C1223.232544,2748.477783 1219.911987,2748.359375 1215.712769,2754.852051 
	C1214.809448,2756.249023 1213.995972,2757.701904 1212.479370,2759.506104 
	C1212.009766,2760.009766 1212.000000,2760.000000 1212.002441,2760.004883 
z"
        />
        <path
          fill="#D3CBBF"
          opacity="1.000000"
          stroke="none"
          d="
M1180.534058,2765.411133 
	C1174.447388,2776.063477 1169.718628,2786.424072 1167.936401,2797.927490 
	C1167.151733,2802.991943 1165.017578,2800.912598 1162.423584,2798.334473 
	C1161.840698,2797.130371 1161.806641,2796.297363 1161.744385,2794.839355 
	C1159.340210,2790.896729 1160.051636,2786.786621 1158.454346,2782.576416 
	C1157.980957,2781.661377 1157.931763,2781.330322 1157.832520,2780.500000 
	C1158.133667,2777.659180 1157.719971,2775.192383 1159.329346,2772.395752 
	C1159.860840,2765.180420 1159.993408,2758.560547 1160.179688,2751.038818 
	C1165.362061,2747.536621 1168.118164,2742.257324 1173.458740,2739.032715 
	C1178.041260,2740.187988 1177.822388,2743.424805 1177.435425,2746.302979 
	C1176.724487,2751.591553 1178.830078,2755.941406 1181.452515,2761.024414 
	C1181.662231,2762.833984 1181.463135,2763.841553 1180.534058,2765.411133 
z"
        />
        <path
          fill="#EEE7D5"
          opacity="1.000000"
          stroke="none"
          d="
M1161.989502,2850.729004 
	C1169.028809,2850.836670 1168.663208,2850.813721 1168.276611,2856.976074 
	C1167.944702,2862.269287 1164.669922,2867.472168 1168.742432,2873.130615 
	C1170.333252,2875.341064 1165.795044,2878.409180 1165.539062,2881.926514 
	C1165.256836,2885.805908 1160.580200,2889.265625 1164.966797,2893.660156 
	C1166.074341,2894.769531 1164.105835,2896.586426 1163.366333,2898.022705 
	C1162.735840,2899.247070 1162.427368,2900.653320 1163.953369,2901.447266 
	C1171.020020,2905.122314 1167.197266,2910.281494 1165.851929,2915.084717 
	C1165.454468,2916.503662 1164.484741,2917.367920 1162.920410,2916.868652 
	C1161.541504,2916.428467 1160.766113,2915.320068 1160.768433,2913.896973 
	C1160.770142,2912.931885 1161.108887,2911.962158 1161.334473,2911.003418 
	C1161.843140,2908.841064 1161.604492,2906.992432 1159.084473,2906.385742 
	C1156.587158,2905.784424 1154.249268,2906.469482 1153.122925,2908.758545 
	C1152.290894,2910.449707 1152.127930,2912.562500 1152.037598,2914.504639 
	C1151.894287,2917.586914 1151.617920,2921.255371 1148.989136,2922.511719 
	C1144.402344,2924.704834 1143.657715,2928.610107 1142.568726,2933.066895 
	C1138.909912,2929.076660 1143.211670,2925.158691 1142.040527,2920.705811 
	C1148.263062,2918.974365 1149.130737,2917.650146 1146.561523,2912.033691 
	C1143.975830,2908.572266 1144.489990,2905.282471 1147.090332,2902.011719 
	C1149.953613,2904.026855 1152.194580,2904.561279 1153.389526,2901.603271 
	C1154.208618,2899.575684 1153.950195,2897.073486 1153.991821,2894.779785 
	C1154.063965,2890.817627 1153.634521,2886.986572 1158.561890,2884.941406 
	C1160.964722,2883.944092 1161.961304,2880.927979 1160.948608,2878.060791 
	C1159.470093,2873.875000 1160.816162,2869.405518 1159.819214,2865.336914 
	C1158.560669,2860.199951 1160.019043,2855.896240 1161.989502,2850.729004 
z"
        />
        <path
          fill="#A9ACA9"
          opacity="1.000000"
          stroke="none"
          d="
M1180.774292,2761.708252 
	C1171.386475,2756.341553 1174.560303,2748.307617 1174.396729,2740.504395 
	C1173.162964,2739.238770 1172.781006,2738.307617 1173.004272,2736.497559 
	C1173.344971,2733.135010 1173.433960,2730.506836 1173.497559,2727.085693 
	C1172.337524,2724.135010 1171.819336,2721.945801 1173.779785,2719.074219 
	C1179.896851,2720.036621 1185.369019,2721.588379 1191.389160,2723.596191 
	C1191.813599,2727.974854 1188.885742,2730.857666 1188.014648,2735.223145 
	C1186.793335,2737.192627 1186.628540,2738.889160 1185.863770,2741.026611 
	C1185.126465,2742.899658 1184.952759,2744.217773 1185.129028,2746.318848 
	C1184.817017,2752.241455 1186.450684,2757.919922 1180.774292,2761.708252 
z"
        />
        <path
          fill="#E9E1D0"
          opacity="1.000000"
          stroke="none"
          d="
M1147.995483,2901.145996 
	C1145.214355,2904.305664 1146.234985,2907.503662 1145.977539,2911.161133 
	C1145.229126,2912.585449 1144.587402,2913.593262 1143.724487,2913.845703 
	C1141.498535,2914.496826 1141.914185,2912.596924 1142.117554,2911.577393 
	C1143.604736,2904.113525 1145.322388,2896.720703 1146.268921,2889.126953 
	C1148.319336,2872.674561 1149.590698,2856.165039 1149.870972,2839.619873 
	C1150.006226,2831.636719 1151.558594,2824.038574 1153.211670,2816.340576 
	C1153.709839,2825.233887 1155.068237,2834.114502 1152.733643,2842.933594 
	C1152.479614,2843.893066 1152.148804,2844.893311 1152.189209,2845.860840 
	C1152.955566,2864.171631 1151.120483,2882.278564 1147.995483,2901.145996 
z"
        />
        <path
          fill="#3D494C"
          opacity="1.000000"
          stroke="none"
          d="
M1194.987061,2701.282715 
	C1191.828735,2704.314941 1187.440430,2706.397949 1182.558105,2708.909668 
	C1175.329224,2710.884277 1168.763306,2712.840088 1162.524902,2717.421875 
	C1161.774048,2713.433105 1162.292358,2709.813965 1167.940552,2709.581299 
	C1169.631226,2709.511719 1171.863403,2708.816895 1172.307373,2706.215332 
	C1168.856201,2701.239502 1161.670898,2706.919434 1158.212891,2700.759277 
	C1167.498047,2699.020264 1176.624878,2702.934082 1186.967285,2701.881348 
	C1189.593018,2700.474365 1191.507935,2699.759033 1194.987061,2701.282715 
z"
        />
        <path
          fill="#F6EEDE"
          opacity="1.000000"
          stroke="none"
          d="
M1249.425049,2762.782715 
	C1250.034180,2762.029297 1250.056641,2761.044189 1250.058594,2760.551270 
	C1250.810547,2759.269287 1251.723877,2758.955811 1253.422852,2759.559570 
	C1256.154419,2759.996826 1258.263916,2759.993408 1261.162598,2759.986816 
	C1262.917358,2766.438477 1263.199219,2772.774902 1258.941284,2778.382812 
	C1255.505737,2782.907959 1254.903198,2782.803711 1250.513184,2776.452637 
	C1246.175903,2772.172852 1246.511230,2767.948486 1249.425049,2762.782715 
z"
        />
        <path
          fill="#E9E1D0"
          opacity="1.000000"
          stroke="none"
          d="
M1172.600342,2735.841309 
	C1173.462402,2736.701904 1173.668823,2737.640625 1173.886719,2739.302002 
	C1172.057861,2740.996094 1170.373413,2742.379150 1170.284180,2744.466553 
	C1170.028564,2750.444580 1165.831543,2750.044922 1161.016113,2750.105713 
	C1161.441040,2743.176758 1167.435059,2740.159424 1172.600342,2735.841309 
z"
        />
        <path
          fill="#FBF5E5"
          opacity="1.000000"
          stroke="none"
          d="
M1121.852051,2984.739990 
	C1122.971680,2983.329834 1123.970215,2982.575928 1125.941772,2981.087891 
	C1124.688354,2988.356201 1122.290283,2994.156250 1120.006958,2999.937256 
	C1117.444702,3000.032471 1118.111694,2997.574219 1116.471313,2996.389648 
	C1117.610840,2992.326904 1118.968384,2988.483887 1121.852051,2984.739990 
z"
        />
        <path
          fill="#E9E1D0"
          opacity="1.000000"
          stroke="none"
          d="
M1158.014160,2782.806396 
	C1161.385132,2784.805420 1162.132690,2788.561279 1161.804810,2793.444092 
	C1155.858887,2792.303711 1158.709595,2787.336914 1158.014160,2782.806396 
z"
        />
        <path
          fill="#D7D5CF"
          opacity="1.000000"
          stroke="none"
          d="
M1173.738525,2718.305664 
	C1174.257446,2720.572754 1174.090332,2722.660400 1173.697754,2725.520508 
	C1171.401001,2725.796143 1170.761841,2724.094238 1169.686646,2722.470947 
	C1166.159424,2717.145996 1169.805664,2717.905518 1173.738525,2718.305664 
z"
        />
        <path
          fill="#E9E1D0"
          opacity="1.000000"
          stroke="none"
          d="
M1245.804688,2733.299561 
	C1242.531494,2734.221680 1239.521240,2733.170410 1236.457520,2730.488770 
	C1239.303223,2730.633545 1243.370728,2728.365479 1245.804688,2733.299561 
z"
        />
        <path
          fill="#E9E1D0"
          opacity="1.000000"
          stroke="none"
          d="
M1159.092407,2771.988770 
	C1159.910522,2774.271973 1160.461426,2776.784180 1158.357178,2779.534668 
	C1155.452515,2777.172852 1155.005127,2774.507080 1159.092407,2771.988770 
z"
        />
        <path
          fill="#E9E1D0"
          opacity="1.000000"
          stroke="none"
          d="
M1254.041260,2759.243652 
	C1253.158813,2760.008789 1252.272583,2760.017578 1250.723389,2760.042480 
	C1250.080566,2757.779785 1249.709717,2755.334717 1253.195557,2754.100586 
	C1253.917969,2755.464355 1253.977783,2756.976074 1254.041260,2759.243652 
z"
        />
        <path
          fill="#FBF5E5"
          opacity="1.000000"
          stroke="none"
          d="
M978.040222,2918.656982 
	C980.841003,2918.239990 982.008301,2919.912598 981.990967,2923.301025 
	C979.152954,2923.764404 977.991699,2922.081299 978.040222,2918.656982 
z"
        />
        <path
          fill="#FBF5E5"
          opacity="1.000000"
          stroke="none"
          d="
M1129.555176,2960.244629 
	C1131.255127,2960.203369 1133.179932,2960.346436 1132.876709,2961.866455 
	C1132.360229,2964.455078 1130.491943,2962.918945 1128.556396,2962.252441 
	C1128.233398,2961.408447 1128.604980,2960.888672 1129.555176,2960.244629 
z"
        />
        <path
          fill="#E9E1D0"
          opacity="1.000000"
          stroke="none"
          d="
M1249.867676,2741.445068 
	C1248.133301,2741.637695 1245.999146,2741.546875 1247.526123,2738.372070 
	C1249.016968,2738.663086 1249.654663,2739.604492 1249.867676,2741.445068 
z"
        />
        <path
          fill="#704A3A"
          opacity="1.000000"
          stroke="none"
          d="
M2515.369141,3005.577637 
	C2519.155273,3008.618652 2524.265869,3009.280273 2524.597656,3014.677002 
	C2534.746826,3021.299805 2539.321777,3031.984863 2545.283203,3041.881348 
	C2550.116943,3049.905762 2557.014893,3063.895264 2558.094238,3073.362549 
	C2558.317871,3075.322754 2558.985596,3077.229736 2559.407959,3079.169922 
	C2560.427979,3083.853027 2560.649658,3087.959473 2553.985840,3088.144775 
	C2551.541992,3088.212646 2550.418701,3089.413574 2551.994385,3092.054199 
	C2554.276855,3095.880127 2551.290283,3095.879883 2548.887451,3095.708740 
	C2547.662842,3095.621582 2545.973877,3095.026367 2545.387939,3094.097412 
	C2539.745117,3085.154541 2534.321289,3092.471436 2528.837158,3094.182861 
	C2527.361816,3094.643311 2526.616699,3095.651367 2527.133545,3097.168945 
	C2527.599609,3098.537842 2528.821533,3099.541260 2530.167480,3099.243408 
	C2536.177002,3097.912598 2541.924561,3101.021973 2547.921387,3100.101074 
	C2549.715332,3099.825684 2552.394775,3099.565430 2552.783691,3102.199219 
	C2553.140625,3104.617920 2550.945801,3105.392090 2548.923340,3105.830322 
	C2547.043213,3106.237549 2544.881104,3105.575684 2542.923828,3107.798096 
	C2544.567627,3111.280029 2548.261719,3113.020752 2551.402588,3113.229492 
	C2557.878174,3113.660156 2561.780762,3116.359131 2563.855469,3122.372803 
	C2564.343018,3123.785889 2566.761230,3124.072266 2566.781006,3126.089600 
	C2559.247559,3134.521973 2557.320068,3134.730469 2547.954102,3129.294678 
	C2545.263184,3127.733154 2543.156250,3124.679932 2539.392822,3124.994141 
	C2537.838867,3128.328857 2541.583496,3129.702148 2541.689209,3132.254150 
	C2541.784424,3134.545654 2542.385986,3137.756104 2538.750732,3137.019775 
	C2531.906006,3135.632812 2534.816162,3142.727051 2531.611084,3144.706787 
	C2529.207520,3146.191162 2529.634033,3149.533936 2531.327393,3151.927734 
	C2533.039062,3154.347900 2533.532959,3156.115234 2531.385254,3158.905029 
	C2528.544922,3162.593750 2531.836670,3165.814697 2533.843994,3168.684326 
	C2537.246338,3173.548096 2539.345703,3178.485596 2536.767578,3185.153320 
	C2527.254395,3178.059570 2520.278076,3184.021484 2514.373291,3190.269287 
	C2510.622314,3194.237305 2507.973877,3199.378906 2503.921143,3203.306885 
	C2503.268311,3203.939453 2502.904541,3205.004639 2502.691162,3205.935059 
	C2501.127441,3212.748291 2501.041016,3211.779785 2493.662598,3212.607666 
	C2484.414062,3213.645264 2477.169189,3209.443604 2468.672607,3206.041016 
	C2468.265381,3197.445801 2461.491211,3192.830566 2457.404785,3186.008301 
	C2457.710205,3183.815674 2458.734131,3182.915283 2460.794434,3182.166992 
	C2466.645020,3181.232178 2469.314941,3186.135254 2474.031982,3187.583984 
	C2475.313965,3183.855469 2473.588135,3179.615234 2476.493408,3175.731445 
	C2477.858154,3174.036133 2479.021973,3173.204346 2481.006836,3172.333252 
	C2486.159668,3171.355469 2489.397217,3172.888916 2491.466797,3177.586914 
	C2492.861572,3170.938232 2498.349121,3167.881104 2502.697266,3163.808350 
	C2512.142334,3154.960693 2512.675781,3153.000732 2507.760498,3141.103760 
	C2506.074951,3137.024414 2507.954834,3134.227051 2509.648926,3130.972900 
	C2511.822021,3126.798828 2516.511475,3123.480225 2514.190430,3117.171875 
	C2507.149658,3105.658691 2507.879150,3097.813965 2516.550537,3088.465088 
	C2513.876953,3083.723877 2504.041992,3083.339111 2507.244385,3075.267090 
	C2509.409180,3069.810791 2514.264160,3074.783447 2517.880371,3074.303711 
	C2517.533203,3072.945312 2515.223389,3072.276367 2515.678711,3071.450439 
	C2520.923340,3061.938721 2512.281738,3057.145752 2508.762939,3050.667236 
	C2507.267090,3047.912842 2503.169189,3045.523926 2506.145020,3042.091309 
	C2509.242188,3038.518311 2512.839600,3041.062988 2515.982178,3043.017090 
	C2518.523193,3044.596436 2520.566650,3046.806641 2524.371826,3049.800049 
	C2519.629150,3031.970215 2510.035645,3018.876709 2499.047363,3006.630859 
	C2496.632080,3003.939453 2493.115479,3002.299805 2491.049805,2998.266113 
	C2492.510498,2989.613770 2497.491455,2992.678955 2501.119141,2995.103760 
	C2505.782715,2998.220947 2509.952881,3002.110352 2515.369141,3005.577637 
z"
        />
        <path
          fill="#704A3A"
          opacity="1.000000"
          stroke="none"
          d="
M2512.089844,3321.263428 
	C2508.509277,3320.946533 2505.047607,3319.818848 2501.542236,3318.848389 
	C2497.499512,3317.729004 2494.215576,3316.256104 2493.792236,3311.180176 
	C2493.414551,3306.647949 2489.568604,3304.700439 2485.911377,3302.977539 
	C2482.211182,3301.233887 2479.184326,3298.751221 2476.952637,3295.328369 
	C2476.168701,3294.126221 2476.078613,3292.691162 2476.883545,3291.586914 
	C2478.026611,3290.018311 2479.771973,3289.981934 2481.294434,3290.970703 
	C2482.952637,3292.047119 2484.382324,3293.468994 2485.987549,3294.635742 
	C2488.557861,3296.503418 2490.987305,3295.608643 2493.372314,3294.125000 
	C2495.516357,3292.791016 2495.121582,3291.020508 2494.675537,3288.933594 
	C2493.987793,3285.717041 2494.607910,3281.902100 2493.083496,3279.257080 
	C2490.749268,3275.206787 2490.528809,3272.651855 2493.755615,3268.816406 
	C2497.513916,3264.350098 2495.589111,3260.945801 2489.767334,3260.064697 
	C2482.232178,3258.924316 2475.196777,3260.396729 2465.674316,3265.239502 
	C2463.347168,3262.832031 2462.711182,3259.594727 2462.095703,3256.443604 
	C2461.633057,3254.075928 2461.243896,3251.523438 2458.765625,3250.751953 
	C2453.861084,3249.225098 2454.311523,3246.433350 2455.402832,3242.303223 
	C2456.654297,3237.568359 2458.676514,3232.834961 2457.972656,3226.873535 
	C2466.789551,3226.597168 2471.013916,3227.912842 2480.089844,3232.931396 
	C2484.718506,3221.563477 2484.535645,3221.883789 2495.359375,3229.523438 
	C2506.331055,3237.266846 2507.958008,3248.161621 2506.828613,3260.059326 
	C2506.516846,3263.344727 2506.083008,3266.621094 2506.016846,3269.933838 
	C2505.960205,3272.774902 2506.526123,3275.000488 2509.683838,3276.188477 
	C2513.920410,3277.782471 2516.421631,3281.360352 2518.693115,3285.137695 
	C2522.168213,3290.917480 2521.395752,3297.616943 2516.129639,3302.314941 
	C2513.104736,3305.012939 2511.284912,3307.930176 2513.024414,3311.864502 
	C2514.429199,3315.041992 2512.946777,3317.689697 2512.089844,3321.263428 
z"
        />
        <path
          fill="#765242"
          opacity="1.000000"
          stroke="none"
          d="
M2515.749512,3005.419434 
	C2509.550293,3006.104492 2505.687500,3001.357178 2500.928467,2998.152832 
	C2498.470947,2996.498535 2495.920166,2992.085205 2492.117188,2997.357910 
	C2489.492188,3000.613770 2486.657227,3000.524170 2484.148438,2998.312256 
	C2481.477051,2995.957031 2482.517334,2992.987793 2483.504639,2990.090576 
	C2484.731201,2986.492188 2488.573730,2983.863037 2486.944824,2979.082031 
	C2485.632812,2978.400635 2484.857910,2978.028320 2483.497559,2977.314453 
	C2479.996582,2974.660645 2476.633789,2973.490479 2472.981934,2972.953613 
	C2466.913818,2972.061279 2461.615723,2970.034668 2458.298828,2964.238281 
	C2455.884521,2960.019287 2451.924805,2958.782715 2446.507812,2960.275879 
	C2444.320801,2960.442627 2442.891846,2960.226807 2440.860352,2959.406738 
	C2435.255127,2950.595947 2425.062012,2950.619385 2417.912598,2945.458740 
	C2409.414062,2939.324707 2400.167236,2933.807373 2388.967773,2933.248535 
	C2385.607422,2933.080811 2383.330078,2929.976562 2380.694092,2927.982910 
	C2372.982910,2922.149902 2370.896973,2922.507080 2364.442871,2931.138672 
	C2362.200439,2935.499756 2360.509033,2939.367920 2357.693848,2943.435059 
	C2356.796631,2944.245361 2356.383057,2944.510742 2355.232910,2944.888916 
	C2354.012939,2944.868896 2353.529541,2944.736572 2353.060547,2943.852539 
	C2354.103760,2939.906006 2355.132568,2936.711426 2356.232422,2932.795654 
	C2359.734619,2927.771484 2359.789551,2922.275879 2361.820801,2916.709717 
	C2363.049805,2915.675293 2364.300049,2915.554199 2364.810547,2914.876221 
	C2370.996582,2906.658936 2370.987549,2906.598877 2379.677734,2910.834717 
	C2382.656494,2912.286621 2385.909912,2913.536621 2388.340820,2915.672363 
	C2392.812744,2919.601807 2398.198730,2920.521240 2403.561035,2922.267090 
	C2410.761719,2924.611572 2418.698486,2925.715332 2423.417236,2933.677490 
	C2426.895020,2939.545898 2434.710938,2939.665527 2440.782471,2941.869141 
	C2442.586426,2942.524170 2445.063965,2942.023926 2446.337891,2943.622314 
	C2453.342285,2952.411377 2464.831299,2953.400635 2473.405762,2959.416748 
	C2474.470215,2960.163330 2476.557617,2960.470703 2476.739258,2961.296875 
	C2478.435059,2969.012939 2484.958008,2968.945068 2490.579102,2969.872559 
	C2496.070068,2970.778809 2499.237549,2974.156494 2501.943359,2979.234863 
	C2505.251953,2985.103027 2505.182129,2991.257568 2507.597900,2997.385254 
	C2509.613281,2998.595703 2511.172363,2999.219238 2513.360840,2999.896973 
	C2514.489746,3001.577148 2514.989258,3003.203125 2515.749512,3005.419434 
z"
        />
        <path
          fill="#704A3A"
          opacity="1.000000"
          stroke="none"
          d="
M2450.186279,3225.272949 
	C2447.830566,3224.865967 2445.972168,3223.362061 2446.502930,3220.635986 
	C2446.987305,3218.146484 2449.236816,3218.165039 2451.234619,3218.007812 
	C2452.878418,3217.878662 2454.627441,3217.997803 2456.135010,3217.460205 
	C2459.205566,3216.366211 2460.287109,3214.309814 2458.141357,3211.345703 
	C2455.645264,3207.897705 2451.380859,3205.866455 2450.088379,3200.700684 
	C2458.470459,3192.395020 2460.174072,3192.876221 2465.842529,3205.193604 
	C2468.863281,3217.026367 2466.854736,3220.081787 2455.216553,3221.999023 
	C2453.247070,3222.323730 2451.259766,3222.311035 2450.186279,3225.272949 
z"
        />
        <path
          fill="#805541"
          opacity="1.000000"
          stroke="none"
          d="
M2466.451172,3205.938965 
	C2462.124512,3204.135986 2460.188232,3200.731445 2458.997559,3197.027588 
	C2455.768066,3197.172363 2454.380615,3200.546875 2450.708252,3199.987793 
	C2450.009766,3200.009766 2449.925781,3199.925781 2449.883789,3199.883789 
	C2447.086426,3196.824219 2446.907471,3193.319092 2448.266357,3188.750488 
	C2450.591309,3185.125732 2453.141113,3183.902588 2457.273926,3185.462891 
	C2461.286621,3188.685303 2464.478516,3191.483643 2467.861572,3194.027832 
	C2472.487793,3197.507324 2471.397705,3200.994629 2468.072266,3205.278320 
	C2467.941895,3205.922119 2466.948242,3205.941406 2466.451172,3205.938965 
z"
        />
        <path
          fill="#704A3A"
          opacity="1.000000"
          stroke="none"
          d="
M2508.045654,2997.145752 
	C2502.391846,2993.842041 2503.022217,2987.397949 2502.028320,2980.748535 
	C2508.740234,2983.679199 2507.177246,2990.442871 2508.045654,2997.145752 
z"
        />
        <path
          fill="#2B3E51"
          opacity="1.000000"
          stroke="none"
          d="
M486.061371,2662.911133 
	C487.704285,2662.069580 489.485382,2661.906982 491.560486,2663.463867 
	C489.885345,2666.322266 487.916321,2666.635254 486.061371,2662.911133 
z"
        />
        <path
          fill="#091825"
          opacity="1.000000"
          stroke="none"
          d="
M2294.221436,3490.141602 
	C2293.031738,3496.646973 2290.934814,3503.124023 2288.738281,3510.366699 
	C2288.027832,3511.887207 2287.417480,3512.641846 2286.474365,3513.755371 
	C2283.909180,3511.042236 2282.556152,3508.122559 2277.028564,3509.511719 
	C2268.620117,3511.624512 2259.919922,3508.391602 2251.534424,3507.088623 
	C2243.494629,3505.839355 2236.496582,3507.332275 2229.414551,3509.899170 
	C2226.916992,3510.804443 2224.622314,3512.405273 2221.914307,3511.831543 
	C2210.561523,3509.425781 2198.662598,3510.579590 2187.596191,3507.513916 
	C2180.942383,3505.670898 2175.145996,3500.582031 2169.133301,3496.690674 
	C2167.712891,3495.771240 2167.820312,3493.546387 2168.661377,3492.080078 
	C2169.708008,3490.255371 2171.565186,3490.671387 2172.993896,3491.609375 
	C2177.698486,3494.697754 2182.375244,3493.661133 2187.302979,3492.154297 
	C2193.332031,3490.311279 2199.273682,3489.832275 2205.827637,3491.145020 
	C2210.925537,3492.165771 2216.491943,3489.434326 2221.846191,3493.032227 
	C2223.769775,3494.324951 2226.345215,3491.632568 2227.972168,3489.186035 
	C2231.860352,3483.339600 2234.081787,3482.760742 2240.096680,3485.397949 
	C2248.422363,3489.047607 2257.206055,3489.250977 2266.036133,3488.906250 
	C2267.642334,3488.843506 2269.304688,3487.979492 2270.889404,3488.664795 
	C2278.207031,3491.828613 2285.801025,3490.259033 2294.221436,3490.141602 
z"
        />
        <path
          fill="#0B1926"
          opacity="1.000000"
          stroke="none"
          d="
M2272.036133,3538.156250 
	C2272.774170,3538.560059 2272.638672,3538.911133 2272.082031,3539.638672 
	C2264.591797,3541.053955 2257.367188,3542.769287 2252.065430,3534.816895 
	C2258.688232,3533.768311 2264.371826,3538.590576 2272.036133,3538.156250 
z"
        />
        <path
          fill="#0B1926"
          opacity="1.000000"
          stroke="none"
          d="
M2120.082275,3422.651611 
	C2122.835449,3421.936768 2125.571045,3422.109131 2127.671143,3425.405273 
	C2124.390137,3427.991943 2121.397705,3428.185791 2120.082275,3422.651611 
z"
        />
        <path
          fill="#0B1926"
          opacity="1.000000"
          stroke="none"
          d="
M2110.433105,3418.496094 
	C2112.253174,3415.638428 2114.242676,3415.394043 2115.893066,3419.349121 
	C2114.231201,3419.952393 2112.421875,3420.136963 2110.433105,3418.496094 
z"
        />
        <path
          fill="#96664C"
          opacity="1.000000"
          stroke="none"
          d="
M2286.070557,3516.526367 
	C2288.729004,3518.601562 2286.836670,3520.023926 2284.718018,3521.772705 
	C2283.994873,3520.156982 2284.374268,3518.439697 2286.070557,3516.526367 
z"
        />
        <path
          fill="#0B1926"
          opacity="1.000000"
          stroke="none"
          d="
M2100.295898,3414.566895 
	C2102.257080,3414.114014 2104.734619,3413.743408 2105.870605,3417.281250 
	C2103.713623,3417.944336 2101.872314,3417.077881 2100.295898,3414.566895 
z"
        />
        <path
          fill="#96664C"
          opacity="1.000000"
          stroke="none"
          d="
M2277.779541,3532.880615 
	C2278.159180,3534.808838 2277.995605,3536.920410 2275.080078,3538.064453 
	C2274.242432,3535.890625 2275.340088,3534.268311 2277.779541,3532.880615 
z"
        />
        <path
          fill="#172837"
          opacity="1.000000"
          stroke="none"
          d="
M2192.518555,3420.416504 
	C2193.411865,3414.494141 2196.931885,3414.078613 2201.756836,3415.593994 
	C2205.993408,3416.924072 2210.239502,3419.465332 2215.472656,3416.534180 
	C2226.729492,3418.278564 2237.614746,3418.019531 2248.487061,3418.055420 
	C2252.880615,3418.069824 2256.973145,3419.617432 2260.778564,3421.534424 
	C2265.112061,3423.717285 2269.572754,3424.200195 2274.240967,3423.985107 
	C2277.701416,3423.825928 2281.793945,3424.999268 2281.034668,3418.679199 
	C2280.658691,3415.547119 2284.228027,3416.028076 2286.388672,3416.049072 
	C2297.774170,3416.159912 2308.455078,3419.460938 2318.939941,3423.531494 
	C2321.386963,3424.481445 2323.614014,3426.067627 2327.162109,3425.966064 
	C2328.075684,3428.885986 2328.229004,3431.801758 2328.648926,3435.555176 
	C2328.481445,3439.691650 2327.217285,3442.662354 2324.833252,3445.842285 
	C2309.984375,3441.032715 2296.307373,3433.177002 2280.147705,3435.305664 
	C2268.992920,3429.211182 2255.556152,3435.413574 2244.449707,3428.706299 
	C2243.232910,3427.971436 2241.065430,3428.071045 2239.619629,3428.580566 
	C2232.459473,3431.105957 2225.090576,3429.560547 2216.917480,3430.024414 
	C2209.989746,3430.911377 2206.079834,3426.493408 2200.661377,3424.096680 
	C2197.076660,3424.261719 2194.692139,3423.362793 2192.518555,3420.416504 
z"
        />
        <path
          fill="#10212F"
          opacity="1.000000"
          stroke="none"
          d="
M2200.308105,3424.580566 
	C2201.986572,3421.443848 2203.988037,3423.019531 2205.916260,3424.022949 
	C2209.008545,3425.631836 2212.050293,3427.337891 2215.556641,3429.515869 
	C2212.914307,3433.422363 2209.954590,3434.563232 2206.273682,3430.482910 
	C2204.579346,3428.605713 2202.104492,3427.392334 2200.308105,3424.580566 
z"
        />
        <path
          fill="#96664C"
          opacity="1.000000"
          stroke="none"
          d="
M2266.510742,3563.391113 
	C2263.574219,3564.170410 2260.574707,3565.091553 2260.736084,3567.402344 
	C2261.141113,3573.203369 2256.313477,3575.380371 2253.931641,3579.236816 
	C2251.288574,3583.516602 2247.720459,3584.567627 2242.341797,3583.673340 
	C2245.875732,3574.235596 2250.240967,3564.906738 2258.625488,3558.410400 
	C2262.983398,3555.033936 2263.850098,3549.104004 2268.905273,3545.584473 
	C2274.281494,3550.550293 2267.174316,3554.976074 2268.213867,3560.737793 
	C2267.632080,3561.916260 2267.326904,3562.349365 2266.510742,3563.391113 
z"
        />
        <path
          fill="#BA7C55"
          opacity="1.000000"
          stroke="none"
          d="
M2266.520020,3562.425781 
	C2264.899414,3556.389893 2267.815430,3551.762451 2269.610107,3546.087891 
	C2270.020752,3544.337646 2270.520996,3543.382324 2271.529541,3542.220215 
	C2273.858643,3542.165039 2275.350830,3542.766357 2275.918945,3545.404785 
	C2276.352295,3547.716309 2276.377197,3549.387695 2275.705078,3551.655518 
	C2274.566162,3554.638428 2273.342773,3556.880371 2271.812988,3559.699463 
	C2270.921631,3560.958740 2270.291504,3561.574707 2268.977295,3562.401611 
	C2267.929443,3562.747803 2267.484619,3562.799561 2266.520020,3562.425781 
z"
        />
        <path
          fill="#96664C"
          opacity="1.000000"
          stroke="none"
          d="
M2241.123535,3583.826660 
	C2241.377930,3584.233643 2241.154785,3584.866699 2240.479004,3585.754883 
	C2240.233154,3585.357422 2240.439697,3584.705078 2241.123535,3583.826660 
z"
        />
        <path
          fill="#D89066"
          opacity="1.000000"
          stroke="none"
          d="
M2274.038574,3552.661621 
	C2274.124756,3550.238281 2273.915527,3548.443604 2275.527100,3546.468262 
	C2280.465332,3540.635742 2285.084229,3535.366455 2289.313477,3529.800781 
	C2294.605957,3522.835205 2298.290527,3522.635010 2303.845703,3529.827148 
	C2312.854492,3541.491211 2313.012207,3554.671143 2309.977051,3569.133789 
	C2306.620117,3576.906982 2303.964844,3584.416748 2295.484131,3586.982422 
	C2292.895752,3593.842285 2287.255371,3596.893066 2280.767334,3599.065674 
	C2279.743408,3599.408691 2278.810791,3600.903076 2278.408691,3602.063477 
	C2276.972412,3606.209961 2276.050781,3610.321289 2269.817627,3609.077881 
	C2267.772461,3608.669678 2268.115967,3613.255371 2264.781738,3613.973633 
	C2262.937012,3607.802979 2257.081787,3608.909912 2252.521240,3606.680176 
	C2256.646973,3603.117188 2258.542969,3597.172607 2264.308350,3595.078125 
	C2266.754883,3594.188965 2266.030273,3591.082520 2265.330566,3588.819580 
	C2264.360107,3585.679688 2263.066895,3582.572021 2265.667236,3578.852783 
	C2269.014404,3581.326660 2268.267334,3585.430664 2269.807129,3588.379639 
	C2270.800049,3590.280762 2271.642578,3592.238770 2274.076416,3591.154297 
	C2276.285400,3590.169678 2276.390381,3587.635254 2275.468018,3585.977051 
	C2272.870361,3581.307129 2270.870117,3576.454590 2269.529785,3571.305908 
	C2269.031494,3569.391602 2268.558105,3567.470947 2268.037109,3564.776367 
	C2267.684326,3563.666260 2267.367432,3563.333252 2267.525879,3562.500488 
	C2268.266113,3561.416016 2268.656738,3560.933350 2269.587402,3560.329834 
	C2272.159424,3558.338623 2273.861084,3556.300293 2274.038574,3552.661621 
z"
        />
        <path
          fill="#D89066"
          opacity="1.000000"
          stroke="none"
          d="
M2247.326660,3610.044922 
	C2247.834473,3611.352539 2247.690918,3612.765625 2246.108887,3613.006104 
	C2245.001465,3613.174316 2244.460938,3612.252197 2244.039062,3610.718994 
	C2244.836182,3610.111084 2245.741699,3610.066406 2247.326660,3610.044922 
z"
        />
        <path
          fill="#96664C"
          opacity="1.000000"
          stroke="none"
          d="
M2253.076172,3586.945312 
	C2253.123291,3587.032959 2253.028809,3586.857910 2253.076172,3586.945312 
z"
        />
        <path
          fill="#C2825D"
          opacity="1.000000"
          stroke="none"
          d="
M2267.500977,3562.000488 
	C2268.001221,3562.000732 2268.000977,3563.000488 2268.000977,3563.500000 
	C2267.667480,3563.999512 2267.333984,3563.999512 2266.500244,3563.999756 
	C2266.000000,3563.999756 2265.998535,3563.000000 2265.999268,3562.500000 
	C2266.333496,3562.000000 2266.666992,3562.000000 2267.500977,3562.000488 
z"
        />
        <path
          fill="#8C8D89"
          opacity="1.000000"
          stroke="none"
          d="
M2789.484863,3278.781494 
	C2789.262695,3278.056396 2789.893555,3277.399902 2790.128906,3277.011230 
	C2795.670654,3274.955566 2800.706787,3272.364502 2807.155518,3271.963379 
	C2807.981689,3274.096680 2808.029297,3276.209961 2808.108398,3279.115723 
	C2806.259033,3281.731934 2803.852295,3283.067139 2802.578857,3286.287109 
	C2797.896973,3290.271484 2792.626465,3292.007080 2786.865723,3294.225586 
	C2785.998291,3292.335938 2785.755371,3290.471436 2787.429688,3288.444092 
	C2788.313477,3287.782959 2788.598633,3287.459717 2789.202637,3286.574951 
	C2789.873779,3285.034912 2790.225830,3284.056641 2791.207275,3282.608398 
	C2791.836914,3282.138672 2792.456055,3281.857666 2792.160400,3281.689941 
	C2791.145508,3280.850342 2790.426514,3280.178467 2789.484863,3278.781494 
z"
        />
        <path
          fill="#838F9A"
          opacity="1.000000"
          stroke="none"
          d="
M2803.008057,3286.720703 
	C2802.273682,3285.277344 2802.048584,3283.631348 2802.130615,3282.000977 
	C2802.295410,3278.724121 2804.124268,3278.225098 2807.478516,3279.680176 
	C2809.482422,3280.345947 2811.475586,3279.238525 2812.057861,3281.248291 
	C2812.210449,3281.775391 2810.995117,3282.698730 2810.159668,3283.966309 
	C2807.521240,3284.230957 2805.749268,3285.872314 2803.008057,3286.720703 
z"
        />
        <path
          fill="#D0D1C8"
          opacity="1.000000"
          stroke="none"
          d="
M2722.811768,3324.102295 
	C2721.736328,3323.980957 2721.392090,3323.978516 2720.531982,3323.986328 
	C2706.393066,3318.017578 2706.071289,3316.624268 2714.650391,3299.305664 
	C2712.242920,3291.239258 2706.112305,3285.649658 2704.213867,3277.821045 
	C2703.526123,3274.984619 2701.624756,3272.425537 2703.048096,3268.686523 
	C2703.790527,3267.829590 2704.178223,3267.572266 2705.222900,3267.551758 
	C2708.167236,3267.855469 2710.598145,3268.457764 2712.716309,3267.891113 
	C2719.785645,3265.999756 2726.184326,3266.046387 2731.393311,3272.135254 
	C2732.556885,3273.494873 2734.571289,3273.980713 2735.755371,3272.656006 
	C2738.088623,3270.044922 2743.378906,3268.717529 2740.133057,3262.840820 
	C2740.171143,3261.921875 2740.526611,3261.559082 2741.605713,3260.957764 
	C2744.904541,3260.430176 2747.455078,3261.033691 2750.039795,3260.868164 
	C2758.093262,3260.351807 2759.760986,3264.232666 2756.450684,3271.675537 
	C2755.604004,3273.816406 2755.616699,3276.496338 2754.809814,3276.770996 
	C2745.094238,3280.081787 2740.634521,3291.231934 2729.973389,3293.156494 
	C2726.987549,3293.695312 2723.176025,3293.981689 2722.228516,3298.906738 
	C2724.805176,3302.220703 2728.785400,3303.432617 2730.613281,3307.923828 
	C2729.809814,3314.473145 2728.374268,3320.142090 2722.811768,3324.102295 
z"
        />
        <path
          fill="#9FA2A1"
          opacity="1.000000"
          stroke="none"
          d="
M2756.669922,3272.144043 
	C2755.965820,3270.615479 2755.951172,3268.977051 2755.954834,3267.338623 
	C2755.963623,3263.554443 2754.937256,3261.122314 2750.906006,3264.195068 
	C2747.520996,3266.775146 2745.526855,3264.322510 2742.630127,3262.297119 
	C2737.010010,3258.676270 2731.047607,3256.288086 2729.728516,3248.803711 
	C2735.079590,3245.430176 2740.043213,3248.325195 2745.002197,3248.871826 
	C2748.239258,3249.228760 2751.430664,3250.116211 2755.647949,3249.713867 
	C2759.564697,3249.640869 2762.352539,3250.524658 2764.449707,3252.695557 
	C2766.425049,3254.739502 2766.276123,3256.709229 2762.711914,3257.938232 
	C2761.666748,3261.414551 2761.021240,3264.959229 2765.606445,3267.418457 
	C2763.189941,3269.312500 2760.278809,3270.673340 2756.669922,3272.144043 
z"
        />
        <path
          fill="#CBC5B5"
          opacity="1.000000"
          stroke="none"
          d="
M2730.291016,3308.645752 
	C2725.664551,3307.586182 2724.863770,3303.462646 2722.339355,3300.262207 
	C2728.831055,3298.719482 2733.822510,3302.612061 2739.138916,3305.662354 
	C2741.841309,3307.212891 2742.073242,3309.718994 2741.597900,3313.605469 
	C2736.177979,3315.628174 2733.115234,3312.961670 2730.291016,3308.645752 
z"
        />
        <path
          fill="#E7E3DA"
          opacity="1.000000"
          stroke="none"
          d="
M2791.858887,3281.628906 
	C2792.055908,3281.271729 2792.207764,3280.973633 2792.444580,3280.779053 
	C2793.865234,3279.611084 2795.311523,3278.078613 2797.254883,3279.575195 
	C2797.765381,3279.968506 2797.994629,3281.395020 2797.697266,3282.048096 
	C2796.706299,3284.223389 2794.773438,3283.969727 2792.352051,3282.797607 
	C2791.836914,3282.138672 2791.853027,3281.736084 2791.858887,3281.628906 
z"
        />
        <path
          fill="#E6E1D0"
          opacity="1.000000"
          stroke="none"
          d="
M2789.178711,3286.287354 
	C2789.372314,3286.662109 2789.222900,3287.311279 2788.551025,3288.033203 
	C2788.150391,3287.514404 2788.419189,3286.999512 2789.178711,3286.287354 
z"
        />
        <path
          fill="#CBC5B5"
          opacity="1.000000"
          stroke="none"
          d="
M2742.058350,3313.959229 
	C2742.773438,3314.089844 2743.296143,3314.464111 2743.874756,3315.472656 
	C2744.060791,3315.925049 2743.991211,3316.005127 2743.954834,3316.043945 
	C2743.042236,3316.360107 2742.321289,3316.136719 2741.817871,3314.782471 
	C2741.880859,3314.152100 2741.996338,3314.020996 2742.058350,3313.959229 
z"
        />
        <path
          fill="#98999E"
          opacity="1.000000"
          stroke="none"
          d="
M2705.908936,3267.006836 
	C2705.540283,3267.814941 2705.200928,3267.841309 2703.665283,3267.925781 
	C2702.110107,3260.300293 2697.524658,3253.545166 2697.365723,3244.963623 
	C2697.311768,3243.338379 2697.480713,3242.518066 2697.689697,3241.066650 
	C2698.179443,3238.947998 2699.320557,3238.321289 2701.411621,3237.995361 
	C2705.567871,3240.038574 2707.947021,3243.104492 2710.235840,3246.383301 
	C2712.910889,3250.215576 2715.320312,3253.514648 2709.252686,3256.550537 
	C2705.266113,3258.545410 2706.131836,3262.675781 2705.908936,3267.006836 
z"
        />
        <path
          fill="#EFF1EA"
          opacity="1.000000"
          stroke="none"
          d="
M2729.374023,3247.884766 
	C2733.796631,3252.352295 2737.644531,3256.572510 2741.764893,3261.396484 
	C2741.665283,3262.031250 2741.293213,3262.062500 2740.368652,3262.188965 
	C2732.122314,3258.906006 2723.635010,3257.151367 2715.731934,3250.742188 
	C2721.067383,3250.059082 2724.061523,3246.026123 2729.374023,3247.884766 
z"
        />
        <path
          fill="#6F7981"
          opacity="1.000000"
          stroke="none"
          d="
M2701.791504,3237.422119 
	C2701.052002,3238.655518 2700.009766,3239.348145 2698.348633,3240.238281 
	C2695.639404,3237.978027 2695.219971,3235.317627 2697.333496,3231.964111 
	C2697.909180,3231.508301 2698.708008,3232.037598 2699.149170,3232.217773 
	C2700.223389,3233.892578 2700.856201,3235.386963 2701.791504,3237.422119 
z"
        />
        <path
          fill="#D7D5CF"
          opacity="1.000000"
          stroke="none"
          d="
M2898.162109,3340.276123 
	C2898.548340,3339.504150 2898.887207,3339.477295 2899.170166,3339.337158 
	C2902.012451,3337.929932 2904.736328,3337.530518 2906.936768,3340.412598 
	C2907.193115,3340.748047 2907.109863,3341.740234 2906.803467,3342.043945 
	C2904.494141,3344.331787 2901.978760,3342.738770 2898.876465,3342.324219 
	C2898.180664,3341.892578 2898.134766,3341.430664 2898.162109,3340.276123 
z"
        />
        <path
          fill="#D7D5CF"
          opacity="1.000000"
          stroke="none"
          d="
M2908.669922,3360.420898 
	C2908.135010,3357.662354 2908.384277,3355.272217 2912.302734,3357.740723 
	C2912.301270,3359.777588 2911.900146,3361.595703 2908.669922,3360.420898 
z"
        />
        <path
          fill="#A9AEB3"
          opacity="1.000000"
          stroke="none"
          d="
M2701.563477,3268.019043 
	C2702.468994,3267.984131 2703.155029,3267.912354 2703.498047,3267.906006 
	C2706.956543,3277.192627 2709.033447,3287.000488 2716.937500,3293.897705 
	C2717.892578,3294.731689 2718.977539,3295.676758 2718.267090,3297.677734 
	C2711.490723,3301.429443 2706.609131,3297.428223 2701.923340,3294.229492 
	C2697.936523,3291.507812 2693.975098,3289.954102 2688.482910,3290.503662 
	C2686.816650,3290.297119 2685.904541,3290.020264 2684.434082,3289.241699 
	C2682.446777,3287.611816 2682.292725,3284.746094 2680.020752,3284.866211 
	C2669.890869,3285.401123 2670.661621,3279.747559 2673.506836,3272.531250 
	C2682.607178,3268.965088 2691.797852,3269.621094 2701.563477,3268.019043 
z"
        />
        <path
          fill="#FAFBBF"
          opacity="1.000000"
          stroke="none"
          d="
M2673.240479,3272.020020 
	C2674.019531,3274.605713 2673.993652,3277.190918 2674.140381,3279.766113 
	C2674.301025,3282.589355 2675.226807,3284.328613 2678.338867,3282.192871 
	C2680.190674,3280.922607 2680.907715,3276.927734 2684.368164,3279.459961 
	C2687.421631,3281.694092 2684.266357,3284.163086 2684.431641,3287.221191 
	C2682.061279,3293.993896 2678.479248,3298.634521 2670.621094,3298.429443 
	C2666.793457,3293.776855 2668.477783,3288.666992 2667.631836,3283.921387 
	C2663.886475,3283.685303 2664.004639,3287.069092 2662.257080,3289.400879 
	C2654.711670,3288.322510 2653.929443,3287.520264 2654.110596,3281.542969 
	C2654.262207,3276.533691 2656.501465,3272.064941 2657.935059,3267.390625 
	C2658.439453,3265.746094 2659.222168,3264.245361 2661.361084,3265.180908 
	C2662.636719,3265.738281 2663.306641,3266.840332 2663.332520,3268.285889 
	C2663.385498,3271.223145 2659.450684,3274.945068 2663.752441,3276.878418 
	C2666.921875,3278.302734 2667.561523,3272.859131 2670.572754,3272.270996 
	C2671.211182,3272.145996 2671.864746,3272.098633 2673.240479,3272.020020 
z"
        />
        <path
          fill="#959693"
          opacity="1.000000"
          stroke="none"
          d="
M2642.962891,3272.252441 
	C2640.379639,3267.606445 2643.522217,3263.705322 2644.080566,3258.625732 
	C2646.724609,3255.726074 2645.620850,3252.153564 2647.407715,3249.768311 
	C2648.474609,3248.343994 2649.912354,3247.671631 2651.688477,3248.301025 
	C2653.222412,3248.844727 2653.482666,3250.212402 2653.097412,3251.489014 
	C2651.381104,3257.176514 2651.988770,3263.154785 2650.557861,3268.964355 
	C2649.320801,3273.988525 2647.121338,3273.593018 2642.962891,3272.252441 
z"
        />
        <path
          fill="#EBE4D0"
          opacity="1.000000"
          stroke="none"
          d="
M2741.215820,3314.035645 
	C2742.137939,3314.757812 2742.534424,3315.251465 2743.494629,3315.857666 
	C2746.265137,3320.641113 2749.397217,3324.986084 2746.818848,3330.603027 
	C2745.871094,3332.667725 2747.315186,3334.267822 2748.712402,3335.684814 
	C2750.743896,3337.744873 2753.061035,3339.594238 2753.901367,3343.285645 
	C2751.415527,3344.109619 2749.523438,3342.778564 2747.998291,3340.971680 
	C2745.029541,3337.453369 2741.321045,3336.367432 2736.762451,3335.798584 
	C2730.405762,3335.005127 2724.989746,3331.789062 2722.177734,3324.682373 
	C2725.735352,3319.674316 2725.069336,3313.244385 2729.656982,3308.618896 
	C2733.511963,3309.979492 2737.031250,3311.949219 2741.215820,3314.035645 
z"
        />
        <path
          fill="#EBE4D0"
          opacity="1.000000"
          stroke="none"
          d="
M2755.395996,3348.115234 
	C2755.490479,3347.000244 2754.925049,3346.041748 2754.192383,3344.551758 
	C2756.204590,3344.433594 2758.383301,3344.846924 2760.522705,3345.252930 
	C2762.283447,3341.976318 2759.559570,3340.875488 2758.193604,3339.312988 
	C2756.028564,3336.836182 2753.314453,3334.761963 2751.516113,3332.062012 
	C2749.332764,3328.783936 2752.121826,3324.306396 2755.898438,3324.416016 
	C2761.150391,3324.568359 2766.976318,3328.778809 2768.869873,3333.792969 
	C2770.121826,3337.107178 2768.325684,3337.906494 2764.813477,3337.858887 
	C2764.118408,3337.856934 2763.430420,3337.330811 2763.139893,3337.069580 
	C2762.849365,3336.808350 2762.153564,3336.459229 2762.544678,3336.583740 
	C2762.935791,3336.708252 2763.330078,3337.456055 2763.603027,3337.778809 
	C2765.688477,3342.092285 2769.805664,3345.704346 2764.922607,3350.518311 
	C2763.127686,3352.288086 2762.035400,3353.893311 2759.223145,3352.779541 
	C2757.316162,3352.024414 2756.296143,3353.744873 2754.736816,3355.297852 
	C2754.646484,3353.179688 2750.184082,3350.420898 2755.395996,3348.115234 
z"
        />
        <path
          fill="#FAFBBF"
          opacity="1.000000"
          stroke="none"
          d="
M2774.988525,3352.242432 
	C2776.731445,3346.521973 2778.210449,3341.466064 2771.014648,3336.979736 
	C2781.275391,3335.772705 2786.040771,3341.166748 2792.965332,3346.725342 
	C2786.103760,3347.041260 2782.512451,3349.381836 2780.480957,3354.618896 
	C2778.935303,3358.603516 2777.645264,3358.150146 2774.988525,3352.242432 
z"
        />
        <path
          fill="#F1E9D8"
          opacity="1.000000"
          stroke="none"
          d="
M2687.526855,3288.714844 
	C2693.645752,3286.963867 2698.624268,3285.918945 2703.010498,3291.759766 
	C2705.978516,3295.711426 2711.895752,3295.927246 2717.203613,3297.966797 
	C2717.785156,3301.578613 2715.833496,3304.228516 2714.046387,3307.012939 
	C2709.018799,3314.847412 2709.078613,3314.866455 2716.462891,3320.201416 
	C2717.515381,3320.961670 2718.422119,3321.923828 2719.706055,3323.394531 
	C2718.397217,3326.001709 2716.502930,3325.370605 2714.818848,3324.296631 
	C2711.271973,3322.034668 2707.838379,3319.594971 2703.980957,3316.652344 
	C2701.598633,3313.445801 2702.019775,3310.666504 2703.096680,3307.843750 
	C2701.796143,3310.008301 2702.334229,3313.191650 2698.744141,3314.336670 
	C2696.311279,3313.254150 2694.870117,3311.990723 2694.290771,3310.015869 
	C2692.620605,3304.321289 2689.581055,3301.865234 2683.730713,3305.206055 
	C2683.152344,3305.536133 2681.944824,3304.763916 2680.465332,3304.286621 
	C2675.838379,3296.088867 2682.238770,3293.051270 2687.526855,3288.714844 
z"
        />
        <path
          fill="#D0D1C8"
          opacity="1.000000"
          stroke="none"
          d="
M2687.518066,3288.111328 
	C2687.908936,3291.348389 2685.764160,3293.283203 2683.753418,3295.294434 
	C2681.715088,3297.333496 2679.808838,3299.434570 2679.910156,3303.334473 
	C2677.821777,3304.974121 2675.802246,3305.976318 2673.418213,3305.946045 
	C2668.324951,3305.881592 2667.177002,3303.407959 2669.486328,3298.488037 
	C2676.287842,3297.442871 2678.318115,3291.027588 2683.791504,3288.184570 
	C2685.269531,3287.943359 2686.089111,3287.970215 2687.518066,3288.111328 
z"
        />
        <path
          fill="#EBE4D0"
          opacity="1.000000"
          stroke="none"
          d="
M2716.116211,3332.737793 
	C2724.773193,3333.945557 2732.094482,3338.542480 2739.501953,3343.198486 
	C2741.812012,3344.649902 2742.192139,3345.701172 2741.471436,3347.651367 
	C2740.666992,3349.827637 2738.886230,3349.377441 2737.387939,3348.498535 
	C2735.396973,3347.330811 2733.480957,3346.014404 2731.632812,3344.628906 
	C2728.035889,3341.932373 2725.101074,3338.347656 2719.796875,3338.513672 
	C2717.307129,3338.591309 2715.970215,3336.159668 2716.116211,3332.737793 
z"
        />
        <path
          fill="#D0D1C8"
          opacity="1.000000"
          stroke="none"
          d="
M2698.475342,3314.656250 
	C2700.992920,3310.704834 2700.607666,3306.118652 2701.953857,3302.094727 
	C2702.626221,3300.085449 2703.111084,3297.938232 2705.729004,3298.740234 
	C2707.865967,3299.395020 2707.030029,3301.303467 2706.625732,3302.906738 
	C2705.654785,3306.757812 2704.778076,3310.632812 2703.733398,3315.287109 
	C2702.032715,3315.751709 2700.461426,3315.427002 2698.475342,3314.656250 
z"
        />
        <path
          fill="#D0D1C8"
          opacity="1.000000"
          stroke="none"
          d="
M2679.211914,3315.035156 
	C2676.098389,3314.715332 2675.780273,3313.191650 2677.109863,3311.268066 
	C2677.921631,3310.093262 2679.328613,3309.997803 2680.357910,3310.886475 
	C2682.021973,3312.322998 2680.849121,3313.600098 2679.211914,3315.035156 
z"
        />
        <path
          fill="#D0D1C8"
          opacity="1.000000"
          stroke="none"
          d="
M2712.761475,3331.981201 
	C2712.104492,3331.654541 2712.103516,3331.315674 2712.140137,3330.469971 
	C2713.243652,3330.348145 2714.309814,3330.733398 2715.737793,3331.574219 
	C2716.100098,3332.030029 2716.025635,3332.004883 2716.034668,3332.041016 
	C2715.168457,3332.041016 2714.293213,3332.005127 2712.761475,3331.981201 
z"
        />
        <path
          fill="#C7C0B1"
          opacity="1.000000"
          stroke="none"
          d="
M2802.767578,3368.063721 
	C2801.035400,3367.398193 2800.096680,3366.753662 2798.569092,3366.064453 
	C2797.938477,3365.042969 2797.876953,3364.066895 2797.858398,3363.089844 
	C2797.794922,3359.728760 2797.620361,3356.049072 2802.460693,3356.268066 
	C2806.517578,3356.451416 2809.096191,3359.053467 2809.736572,3362.975586 
	C2810.525635,3367.807617 2807.084717,3368.138184 2802.767578,3368.063721 
z"
        />
        <path
          fill="#C4C8C1"
          opacity="1.000000"
          stroke="none"
          d="
M1169.208984,1766.843994 
	C1169.971802,1765.351440 1171.126831,1764.118774 1172.369019,1764.929810 
	C1174.276733,1766.175293 1172.830200,1767.835083 1172.106445,1769.947754 
	C1171.063232,1769.550903 1170.246094,1768.516357 1169.208984,1766.843994 
z"
        />
        <path
          fill="#8C8D89"
          opacity="1.000000"
          stroke="none"
          d="
M1119.195068,1809.952026 
	C1119.830322,1811.229492 1119.788208,1812.658569 1118.216797,1813.031860 
	C1117.110352,1813.294678 1116.494629,1812.400269 1115.963379,1810.884766 
	C1116.711914,1810.220581 1117.614014,1810.106323 1119.195068,1809.952026 
z"
        />
        <path
          fill="#0C0D0F"
          opacity="1.000000"
          stroke="none"
          d="
M1338.885254,2309.329590 
	C1341.611328,2308.269775 1343.871094,2310.284180 1346.142090,2308.657471 
	C1347.619263,2307.599121 1349.379761,2305.531494 1351.177124,2307.897705 
	C1352.490356,2309.626465 1351.886475,2311.673584 1350.547241,2313.387695 
	C1349.348755,2314.921387 1348.172241,2316.472412 1346.950928,2318.061768 
	C1345.375366,2316.677002 1345.035400,2313.472900 1341.754272,2314.615723 
	C1340.128906,2315.181885 1338.895630,2318.510986 1336.917358,2316.061279 
	C1335.180542,2313.911377 1337.294434,2311.746582 1338.885254,2309.329590 
z"
        />
        <path
          fill="#1A1615"
          opacity="1.000000"
          stroke="none"
          d="
M1380.165039,2300.608398 
	C1386.357178,2304.527832 1388.397461,2309.658203 1386.094238,2315.415039 
	C1384.344116,2315.927246 1382.688965,2315.923584 1381.055298,2315.733643 
	C1380.066406,2315.618408 1378.665161,2314.363525 1379.116577,2314.063721 
	C1384.994263,2310.158936 1378.580200,2305.458496 1380.165039,2300.608398 
z"
        />
        <path
          fill="#1A1615"
          opacity="1.000000"
          stroke="none"
          d="
M1391.932739,2361.290527 
	C1389.209351,2361.637451 1385.887207,2362.998047 1384.151245,2358.697754 
	C1384.587280,2357.065918 1385.181152,2356.131836 1385.826660,2354.623047 
	C1385.184570,2353.231934 1384.929688,2352.295166 1385.551514,2350.619629 
	C1385.990234,2350.000000 1386.000000,2350.000000 1385.997559,2349.995117 
	C1387.005615,2350.518799 1388.016113,2351.047119 1389.554077,2351.799316 
	C1392.779297,2354.395508 1391.785522,2357.585693 1391.932739,2361.290527 
z"
        />
        <path
          fill="#0C0D0F"
          opacity="1.000000"
          stroke="none"
          d="
M1371.360596,2376.116211 
	C1373.421143,2378.670410 1374.235229,2381.481689 1373.917480,2385.289551 
	C1368.895142,2382.767822 1368.227295,2380.687744 1371.360596,2376.116211 
z"
        />
        <path
          fill="#0C0B0A"
          opacity="1.000000"
          stroke="none"
          d="
M1385.267578,2349.999268 
	C1385.984497,2350.899902 1385.978638,2351.799805 1385.925659,2353.374512 
	C1385.218994,2354.031494 1384.538330,2354.113525 1383.904297,2353.977539 
	C1382.194580,2353.610107 1379.141724,2354.632324 1379.520630,2351.704102 
	C1379.820312,2349.388916 1382.702637,2350.228760 1385.267578,2349.999268 
z"
        />
        <path
          fill="#0C0B0A"
          opacity="1.000000"
          stroke="none"
          d="
M1374.092041,2342.889893 
	C1375.451660,2343.637207 1375.475220,2344.389160 1374.499878,2345.054688 
	C1374.296631,2345.193359 1373.824463,2345.185791 1373.625366,2345.040771 
	C1372.676392,2344.349365 1372.706787,2343.598145 1374.092041,2342.889893 
z"
        />
        <path
          fill="#0C0B0A"
          opacity="1.000000"
          stroke="none"
          d="
M1381.392456,2376.004883 
	C1382.960205,2376.748047 1384.013428,2377.833496 1382.587891,2378.802490 
	C1381.244751,2379.715576 1380.503906,2378.377197 1380.006348,2376.598145 
	C1379.912231,2376.048828 1380.898682,2376.006592 1381.392456,2376.004883 
z"
        />
        <path
          fill="#D89066"
          opacity="1.000000"
          stroke="none"
          d="
M2380.382324,3419.494141 
	C2374.582031,3418.873535 2375.737793,3414.477783 2376.187500,3411.213379 
	C2377.043945,3404.997070 2378.435791,3398.870605 2378.105469,3392.526855 
	C2378.016602,3390.817139 2378.571289,3389.004639 2380.503906,3388.699707 
	C2383.176025,3388.278564 2383.521484,3390.430420 2384.060791,3392.414795 
	C2385.282471,3396.911621 2386.281982,3401.709473 2393.172852,3401.920410 
	C2393.720703,3404.181396 2395.908447,3406.108154 2394.918457,3408.420898 
	C2393.902344,3409.184570 2393.154785,3408.849365 2392.324219,3408.188232 
	C2387.970459,3404.722168 2386.662598,3404.823730 2386.264404,3409.295410 
	C2385.881348,3413.597168 2384.818115,3416.938965 2380.382324,3419.494141 
z"
        />
        <path
          fill="#D89066"
          opacity="1.000000"
          stroke="none"
          d="
M2394.042480,3418.905029 
	C2396.286133,3419.501953 2397.611328,3420.544678 2396.900146,3422.458740 
	C2396.687256,3423.031982 2395.164062,3423.639893 2394.493652,3423.428711 
	C2391.944336,3422.625977 2391.870361,3420.945312 2394.042480,3418.905029 
z"
        />
        <path
          fill="#D89066"
          opacity="1.000000"
          stroke="none"
          d="
M2426.316406,3379.743408 
	C2427.421143,3381.413574 2427.591064,3382.817627 2425.904785,3383.060059 
	C2424.062988,3383.324463 2422.749756,3382.024170 2423.001953,3380.177490 
	C2423.233643,3378.482178 2424.637451,3378.651123 2426.316406,3379.743408 
z"
        />
        <path
          fill="#D89066"
          opacity="1.000000"
          stroke="none"
          d="
M2383.281494,3433.141113 
	C2384.772949,3432.724365 2385.314941,3433.246338 2385.085449,3434.405273 
	C2385.037842,3434.645752 2384.697021,3434.969727 2384.453857,3435.006104 
	C2383.282715,3435.180176 2382.793945,3434.608398 2383.281494,3433.141113 
z"
        />
        <path
          fill="#C2825D"
          opacity="1.000000"
          stroke="none"
          d="
M2403.772949,3355.570312 
	C2402.344482,3357.346191 2402.258789,3355.988525 2402.022461,3354.408203 
	C2402.621582,3354.215332 2403.133545,3354.607910 2403.772949,3355.570312 
z"
        />
        <path
          fill="#D89066"
          opacity="1.000000"
          stroke="none"
          d="
M2312.502197,3515.598145 
	C2308.300537,3509.515381 2314.780029,3507.205566 2315.201904,3501.040771 
	C2319.249268,3507.386230 2318.571289,3512.560547 2317.464600,3516.911133 
	C2316.329590,3521.371582 2314.340332,3517.552979 2312.502197,3515.598145 
z"
        />
        <path
          fill="#EBE4D0"
          opacity="1.000000"
          stroke="none"
          d="
M2333.867188,3294.691895 
	C2334.506836,3294.112305 2335.350830,3294.298096 2335.716309,3294.067871 
	C2336.429688,3293.527832 2336.777344,3293.218262 2337.650635,3292.448486 
	C2338.176270,3291.988525 2338.001465,3292.150635 2338.066650,3292.250244 
	C2340.873047,3287.452148 2340.894531,3287.382812 2345.302734,3288.743896 
	C2359.103516,3293.003418 2372.886719,3297.320557 2387.356445,3301.965820 
	C2388.648682,3302.765381 2389.276855,3303.191895 2390.489746,3303.607910 
	C2391.059082,3303.620605 2391.673340,3303.531250 2391.834473,3303.796631 
	C2395.072510,3315.925293 2386.521729,3310.812500 2380.958740,3310.954346 
	C2377.973145,3310.220459 2375.895264,3309.086426 2372.965088,3308.555176 
	C2371.949219,3308.326172 2371.554688,3308.223633 2370.592529,3307.955811 
	C2368.963379,3307.495361 2367.912598,3307.137939 2366.287598,3307.433350 
	C2365.475342,3307.798584 2365.158691,3307.971436 2364.380859,3308.453613 
	C2363.613525,3308.988037 2363.307373,3309.213623 2362.531250,3309.783203 
	C2361.068115,3310.826172 2360.021973,3311.441650 2358.421387,3312.392578 
	C2353.475586,3314.839600 2355.135254,3318.819336 2355.094238,3322.862793 
	C2355.371094,3323.905029 2355.492432,3324.314209 2355.786377,3325.328613 
	C2356.300537,3327.109863 2356.620605,3328.292969 2357.495605,3329.905762 
	C2358.134277,3330.722900 2358.401611,3331.049072 2359.100830,3331.854492 
	C2362.034668,3335.556396 2365.839111,3335.652588 2369.320312,3336.052979 
	C2381.902344,3337.500000 2381.913330,3337.404053 2377.175781,3349.859619 
	C2374.805176,3361.984375 2368.477539,3371.712891 2362.381348,3382.219971 
	C2351.685791,3383.500977 2347.344238,3380.233398 2345.303223,3369.087646 
	C2342.457764,3353.548340 2339.845703,3337.965332 2337.073975,3322.410889 
	C2335.472656,3313.424072 2331.696045,3304.709961 2333.867188,3294.691895 
z"
        />
        <path
          fill="#2E2D30"
          opacity="1.000000"
          stroke="none"
          d="
M2333.744141,3294.074707 
	C2339.098877,3318.748291 2344.025391,3343.329102 2348.088379,3368.052002 
	C2349.383789,3375.936035 2352.384521,3380.778564 2361.046387,3382.359863 
	C2361.223145,3384.229980 2360.687988,3385.853027 2360.031982,3388.106934 
	C2358.468750,3389.764893 2357.100830,3390.870850 2355.773438,3392.935303 
	C2354.005615,3397.361328 2353.035400,3401.477783 2348.793213,3404.505127 
	C2345.879395,3392.242676 2343.322998,3379.649658 2341.478516,3366.953369 
	C2338.828369,3348.714600 2335.447754,3330.616455 2331.978271,3312.527832 
	C2331.047852,3307.676514 2330.136230,3302.819824 2329.080811,3297.994873 
	C2328.320068,3294.515381 2330.396973,3294.087402 2333.744141,3294.074707 
z"
        />
        <path
          fill="#E1D9C6"
          opacity="1.000000"
          stroke="none"
          d="
M2378.287354,3307.698975 
	C2382.071289,3307.555176 2384.834961,3308.623291 2387.703125,3309.391846 
	C2390.759033,3310.210449 2391.773682,3308.177246 2391.955078,3304.782227 
	C2394.276123,3304.115479 2396.359131,3304.762207 2398.713135,3306.553955 
	C2395.009521,3312.037842 2392.167725,3317.897705 2388.777344,3323.899658 
	C2385.007324,3325.975586 2381.551758,3326.868652 2377.232666,3327.130371 
	C2376.185303,3327.413818 2375.770752,3327.528564 2374.734375,3327.785400 
	C2371.702881,3328.368652 2369.227295,3328.354492 2366.304932,3329.233887 
	C2365.411133,3329.673828 2365.056152,3329.857422 2364.175781,3330.334717 
	C2361.526611,3331.726807 2359.689453,3333.730469 2356.426270,3332.379639 
	C2355.940674,3332.000488 2356.011963,3331.989502 2356.008301,3332.025391 
	C2354.610107,3330.607666 2355.219727,3328.680908 2354.382324,3326.481934 
	C2353.956299,3325.968506 2354.023438,3325.988281 2354.011719,3326.020996 
	C2351.487549,3320.092773 2353.929688,3315.264404 2358.260254,3310.322021 
	C2359.472900,3309.428467 2359.936035,3308.909180 2360.679688,3308.181641 
	C2361.768555,3307.788818 2362.485596,3307.420898 2363.535156,3306.438965 
	C2363.947021,3305.984375 2363.983398,3305.977539 2363.992676,3305.961426 
	C2365.408203,3304.834229 2367.045166,3305.030273 2369.354980,3305.553711 
	C2370.046387,3305.985596 2369.994141,3306.011963 2369.988281,3305.983398 
	C2372.209961,3307.956543 2375.128418,3306.396729 2378.287354,3307.698975 
z"
        />
        <path
          fill="#EBE4D0"
          opacity="1.000000"
          stroke="none"
          d="
M2384.955566,3325.698730 
	C2387.478027,3329.927002 2386.048340,3332.859619 2382.958984,3336.027344 
	C2378.272461,3338.178711 2373.887207,3337.145264 2369.669922,3337.222412 
	C2365.431641,3337.300293 2361.895020,3335.072998 2363.543213,3328.811035 
	C2364.055420,3328.001953 2363.989014,3327.977539 2363.979980,3328.011963 
	C2366.693359,3326.046875 2369.871338,3327.512207 2373.402344,3326.465820 
	C2374.030518,3326.043457 2374.000000,3326.000000 2374.008057,3326.021973 
	C2377.290039,3324.096436 2380.835693,3325.301025 2384.955566,3325.698730 
z"
        />
        <path
          fill="#A6A29C"
          opacity="1.000000"
          stroke="none"
          d="
M2363.373779,3328.024658 
	C2365.218750,3335.660156 2365.404053,3335.878418 2371.890869,3336.110107 
	C2374.794434,3336.213623 2377.702393,3336.189941 2381.518066,3336.373779 
	C2381.953613,3341.058838 2382.143311,3345.815674 2377.783203,3349.860352 
	C2373.890625,3349.280518 2374.170410,3347.155273 2375.614014,3344.351807 
	C2376.409180,3342.807617 2379.590820,3342.159668 2377.939941,3339.740234 
	C2376.366211,3337.433594 2374.012207,3338.872559 2371.804199,3338.883301 
	C2366.184326,3338.909912 2359.951416,3339.653809 2356.205078,3332.684082 
	C2359.241455,3332.476562 2359.960205,3328.542725 2363.373779,3328.024658 
z"
        />
        <path
          fill="#C4BDAE"
          opacity="1.000000"
          stroke="none"
          d="
M2356.027832,3393.650879 
	C2354.177490,3390.466797 2352.203125,3387.035400 2359.169922,3388.421387 
	C2359.556396,3390.911865 2360.313477,3393.816162 2356.027832,3393.650879 
z"
        />
        <path
          fill="#A6A29C"
          opacity="1.000000"
          stroke="none"
          d="
M2386.131348,3325.948730 
	C2382.319824,3326.254639 2378.993896,3326.270996 2374.842041,3326.165527 
	C2377.582275,3322.166260 2382.350098,3324.712158 2387.302734,3323.856201 
	C2387.638672,3324.550293 2387.127686,3325.104492 2386.131348,3325.948730 
z"
        />
        <path
          fill="#9E6A4C"
          opacity="1.000000"
          stroke="none"
          d="
M2450.071289,3187.305664 
	C2449.982178,3191.389160 2449.954590,3194.778564 2449.884521,3199.004883 
	C2444.636475,3199.216064 2441.639160,3201.659424 2440.785645,3206.668213 
	C2439.670654,3213.210693 2437.033936,3218.598633 2430.796143,3221.765381 
	C2427.692627,3223.341309 2427.952637,3225.690674 2429.539062,3228.443604 
	C2433.009033,3234.464844 2437.020996,3240.011719 2442.572754,3244.275391 
	C2444.433350,3245.704590 2446.288330,3247.038330 2447.416016,3250.060303 
	C2442.843750,3252.331787 2438.487061,3255.015381 2434.858398,3258.744629 
	C2433.422119,3260.220947 2430.903809,3260.422119 2429.493896,3258.649902 
	C2428.258545,3257.097168 2429.679443,3255.583008 2431.038330,3254.532471 
	C2432.811768,3253.161377 2434.161865,3250.318848 2431.983643,3249.896484 
	C2426.548340,3248.842773 2424.356201,3242.927246 2418.225586,3242.383301 
	C2416.489746,3241.394043 2416.096924,3240.087158 2415.988281,3237.989990 
	C2416.159668,3235.142578 2417.206787,3233.027100 2416.224609,3230.145508 
	C2415.886719,3228.129150 2415.899658,3226.769775 2416.161865,3224.730469 
	C2416.617432,3222.717285 2417.121338,3221.437744 2417.804199,3219.492188 
	C2419.627930,3212.453369 2423.784668,3205.706299 2415.018555,3200.129883 
	C2412.895264,3194.195801 2411.994385,3188.685791 2411.137207,3182.403320 
	C2411.031738,3179.138916 2410.048584,3176.812500 2409.502930,3173.650391 
	C2409.168701,3170.993652 2408.751709,3169.099854 2408.095703,3166.504639 
	C2407.571045,3164.341797 2407.403809,3162.866455 2407.250000,3160.631348 
	C2407.178711,3158.873047 2407.177979,3157.866455 2407.230469,3156.101562 
	C2407.340576,3153.815430 2407.742432,3152.373291 2408.856934,3150.415283 
	C2416.224609,3147.163574 2417.504150,3152.871826 2418.534668,3156.971924 
	C2420.636230,3165.333740 2424.824707,3172.553467 2428.981201,3179.890625 
	C2430.017090,3181.718506 2430.884521,3183.444580 2433.224121,3183.452393 
	C2435.996582,3183.461670 2437.293945,3181.550537 2438.175781,3179.265869 
	C2439.406006,3176.078857 2439.041748,3172.732178 2439.179688,3169.419189 
	C2439.693359,3157.077881 2440.080566,3145.096924 2452.149658,3136.768799 
	C2457.075195,3133.370605 2458.088623,3125.885498 2459.158203,3119.662842 
	C2459.828125,3115.765625 2457.023438,3113.672363 2453.949951,3112.010742 
	C2450.385254,3110.083496 2446.993408,3107.937744 2446.078125,3103.576416 
	C2445.513916,3100.888672 2445.545898,3098.144043 2447.791260,3096.188477 
	C2450.461426,3093.861816 2452.689209,3095.753906 2455.182129,3097.702393 
	C2454.442139,3093.538574 2451.106201,3089.493164 2455.838135,3086.085693 
	C2456.906494,3085.316650 2457.926758,3084.065918 2456.562012,3082.854004 
	C2450.009521,3077.035400 2454.070312,3074.763672 2459.747559,3072.866455 
	C2467.490723,3070.278809 2462.427490,3064.636719 2462.786377,3059.560059 
	C2463.430420,3056.969971 2464.818848,3055.618164 2466.326660,3053.509766 
	C2469.993652,3049.939941 2469.706787,3045.052734 2473.157715,3041.369141 
	C2477.091553,3038.915771 2480.635010,3037.294678 2485.269531,3036.630615 
	C2498.253418,3045.295654 2500.441895,3053.302246 2492.975830,3061.638428 
	C2490.600342,3064.290771 2488.218262,3066.087646 2485.813477,3064.974121 
	C2489.738037,3067.099609 2492.962158,3072.179932 2498.273193,3075.204834 
	C2502.861572,3077.818604 2499.956787,3081.191162 2495.388428,3082.342529 
	C2483.487549,3085.340332 2484.088379,3085.678711 2489.958496,3096.681885 
	C2493.494873,3103.310547 2496.204102,3111.281006 2492.998535,3119.538086 
	C2491.328613,3123.839111 2488.700684,3126.156494 2484.074707,3125.791992 
	C2480.626221,3125.520508 2477.661865,3125.124023 2478.590576,3130.430420 
	C2478.964600,3132.566895 2476.394287,3133.572754 2474.318604,3133.678955 
	C2472.216553,3133.786621 2471.173828,3133.102051 2473.882568,3135.142090 
	C2481.447754,3140.839355 2480.343018,3149.997314 2479.026367,3157.128662 
	C2477.972656,3162.835938 2470.776367,3167.376221 2464.720703,3168.941162 
	C2452.445557,3172.113525 2450.882812,3173.148193 2450.071289,3187.305664 
z"
        />
        <path
          fill="#313E41"
          opacity="1.000000"
          stroke="none"
          d="
M2417.204102,3223.490967 
	C2417.836914,3225.464111 2417.906006,3226.974854 2417.986328,3229.242920 
	C2417.990479,3231.610596 2418.005859,3233.221924 2417.820801,3235.430664 
	C2417.680664,3237.635254 2417.603760,3239.239258 2417.493164,3241.660156 
	C2414.672119,3246.731934 2413.688721,3251.390137 2414.063965,3256.447998 
	C2414.654785,3264.414307 2413.509033,3272.114014 2409.974121,3280.259766 
	C2408.974854,3280.945801 2408.059570,3280.765137 2406.475098,3280.378418 
	C2406.297119,3263.258057 2395.072998,3258.406250 2381.212402,3254.971924 
	C2368.803955,3251.897461 2357.203369,3245.244385 2343.699219,3247.854736 
	C2341.754883,3248.230469 2339.581055,3247.476562 2338.165771,3246.072266 
	C2330.885254,3238.847168 2321.969971,3240.238770 2312.385742,3241.255371 
	C2305.337646,3241.411377 2301.923828,3237.924561 2300.482666,3231.341309 
	C2303.300781,3222.233154 2309.586182,3222.727783 2315.952881,3225.010986 
	C2328.774902,3229.608887 2342.427734,3231.461670 2352.191650,3236.047852 
	C2335.385254,3232.308350 2317.973633,3222.077881 2299.203857,3215.332031 
	C2295.080566,3213.849854 2291.790771,3210.917236 2289.152344,3206.378174 
	C2288.574219,3205.261475 2288.368164,3204.795654 2287.941895,3203.586670 
	C2287.250244,3200.214844 2287.290283,3197.629150 2288.794434,3194.491211 
	C2295.842041,3190.497314 2301.651123,3193.548584 2306.924316,3196.844482 
	C2318.427979,3204.034424 2331.570557,3206.207275 2344.036865,3210.544922 
	C2358.396484,3215.541016 2374.612793,3213.800049 2387.645020,3224.881836 
	C2393.429443,3229.800293 2404.175049,3231.554199 2411.953369,3223.681885 
	C2413.207520,3222.412598 2414.969482,3222.487793 2417.204102,3223.490967 
z"
        />
        <path
          fill="#E1D9C6"
          opacity="1.000000"
          stroke="none"
          d="
M2390.692871,3303.154297 
	C2390.322021,3303.457764 2389.584961,3303.294922 2388.442139,3302.723877 
	C2388.799805,3302.439941 2389.562988,3302.563965 2390.692871,3303.154297 
z"
        />
        <path
          fill="#C4C8C1"
          opacity="1.000000"
          stroke="none"
          d="
M2301.050293,3229.816895 
	C2302.385010,3235.715820 2306.731201,3238.014404 2311.244629,3241.074951 
	C2314.647949,3240.425049 2317.115479,3241.448486 2319.552246,3242.502930 
	C2343.237061,3252.747559 2367.218994,3262.250244 2391.349365,3271.388428 
	C2394.414795,3272.549316 2397.609863,3273.679688 2399.681152,3277.455078 
	C2400.245117,3282.369141 2395.245850,3284.624023 2395.989746,3289.697998 
	C2390.766113,3290.421387 2386.346924,3287.853271 2381.744141,3286.001953 
	C2361.673096,3277.927734 2341.050537,3271.215576 2321.537354,3261.768311 
	C2316.747803,3259.449463 2312.123535,3256.789062 2306.756104,3254.071045 
	C2295.485352,3250.385254 2296.204346,3241.174072 2295.512695,3231.951172 
	C2295.822510,3230.661377 2296.005859,3230.162354 2296.640137,3229.010254 
	C2298.665771,3227.098145 2299.919922,3227.692139 2301.050293,3229.816895 
z"
        />
        <path
          fill="#4E585F"
          opacity="1.000000"
          stroke="none"
          d="
M2400.504883,3278.167969 
	C2375.107178,3267.961670 2349.728760,3259.278564 2325.288330,3248.262695 
	C2321.075684,3246.364258 2316.952637,3244.266846 2312.345947,3241.818848 
	C2312.050537,3238.413086 2312.843262,3236.022461 2316.558594,3236.610107 
	C2318.835205,3236.970215 2321.155518,3238.111328 2323.330811,3237.851318 
	C2329.616211,3237.100342 2335.508789,3236.258301 2338.922607,3243.603760 
	C2339.908203,3245.724854 2342.746338,3246.245605 2345.151367,3245.339111 
	C2353.604736,3242.152832 2361.467285,3245.031494 2369.247314,3247.768066 
	C2376.103027,3250.179688 2382.812500,3252.799561 2390.030518,3254.238281 
	C2395.824951,3255.393066 2401.146973,3258.734375 2405.425049,3262.766113 
	C2410.336426,3267.394043 2407.820068,3273.199951 2405.957275,3279.376465 
	C2404.252686,3279.496338 2402.699219,3278.820068 2400.504883,3278.167969 
z"
        />
        <path
          fill="#18202C"
          opacity="1.000000"
          stroke="none"
          d="
M917.509277,3189.526855 
	C913.273987,3196.227051 906.001343,3198.385498 898.725952,3201.592529 
	C900.118164,3191.234863 900.109985,3191.164795 910.353638,3189.967041 
	C912.586853,3189.706055 914.608215,3187.885986 917.509277,3189.526855 
z"
        />
        <path
          fill="#687073"
          opacity="1.000000"
          stroke="none"
          d="
M979.570557,3165.537598 
	C977.740417,3169.533691 976.394714,3173.562500 972.792419,3176.004150 
	C970.942627,3177.257812 969.158142,3178.745605 966.387024,3176.451416 
	C964.756653,3172.898193 967.029663,3171.931885 969.663818,3170.488770 
	C973.594666,3169.012939 974.774597,3164.097656 979.570557,3165.537598 
z"
        />
        <path
          fill="#646A6C"
          opacity="1.000000"
          stroke="none"
          d="
M930.504944,3178.316650 
	C929.959656,3177.018066 929.732483,3176.352295 929.295532,3175.881836 
	C926.090698,3172.430908 928.634949,3169.823486 930.617920,3167.229248 
	C931.279785,3166.363281 932.516663,3166.594971 933.301575,3167.202393 
	C936.060852,3169.338135 936.506958,3172.062012 934.938477,3175.111572 
	C934.490479,3175.982666 933.824036,3176.741455 932.648804,3177.921143 
	C931.601990,3178.540283 931.164001,3178.790283 930.504944,3178.316650 
z"
        />
        <path
          fill="#18202C"
          opacity="1.000000"
          stroke="none"
          d="
M969.558167,3170.041016 
	C969.033020,3171.747070 967.796387,3173.333496 966.247375,3175.439941 
	C963.169861,3175.969482 960.404724,3175.979004 956.807983,3175.974609 
	C959.391663,3168.645264 960.659546,3168.050293 969.558167,3170.041016 
z"
        />
        <path
          fill="#18202C"
          opacity="1.000000"
          stroke="none"
          d="
M929.955811,3178.207520 
	C930.283936,3177.593262 931.198181,3177.848633 931.619080,3178.069580 
	C932.028992,3179.725098 932.018005,3181.159912 932.002808,3183.314697 
	C931.700256,3187.492676 929.932495,3188.025879 926.562378,3186.237305 
	C925.906860,3182.941406 928.135437,3181.067627 929.955811,3178.207520 
z"
        />
        <path
          fill="#18202C"
          opacity="1.000000"
          stroke="none"
          d="
M942.898865,3142.064941 
	C943.547241,3142.232910 943.696106,3142.533203 943.967163,3143.320557 
	C943.397888,3144.829834 942.421021,3145.895264 941.326660,3144.793945 
	C940.190308,3143.650146 941.226196,3142.743896 942.898865,3142.064941 
z"
        />
        <path
          fill="#646A6C"
          opacity="1.000000"
          stroke="none"
          d="
M930.309326,3147.377930 
	C931.623108,3148.105713 931.480835,3148.894531 930.432251,3149.309570 
	C930.025574,3149.470703 929.384644,3149.040283 928.850342,3148.879395 
	C929.191772,3148.413574 929.533264,3147.947998 930.309326,3147.377930 
z"
        />
        <path
          fill="#808489"
          opacity="1.000000"
          stroke="none"
          d="
M997.819031,3105.219727 
	C982.141113,3106.711426 966.798645,3104.467285 952.037964,3096.994141 
	C962.641602,3096.793945 973.391296,3098.372070 983.796204,3101.225830 
	C988.384705,3102.484131 993.961182,3099.628662 997.819031,3105.219727 
z"
        />
        <path
          fill="#A9AEB3"
          opacity="1.000000"
          stroke="none"
          d="
M948.071960,3086.748291 
	C950.317688,3086.089600 952.785339,3085.593994 955.513184,3087.489746 
	C953.193420,3090.612549 950.548889,3091.106689 948.071960,3086.748291 
z"
        />
        <path
          fill="#EBE4D0"
          opacity="1.000000"
          stroke="none"
          d="
M1278.994873,3665.490723 
	C1279.726318,3667.186279 1279.670288,3668.398438 1278.205688,3668.858398 
	C1277.805054,3668.984619 1276.850342,3668.521729 1276.723267,3668.138916 
	C1276.179443,3666.500000 1276.280151,3665.059570 1278.994873,3665.490723 
z"
        />
        <path
          fill="#EEE7D5"
          opacity="1.000000"
          stroke="none"
          d="
M1691.703003,2941.481201 
	C1692.866455,2948.257568 1689.603760,2954.049316 1689.823730,2961.157471 
	C1688.736938,2963.673584 1686.925415,2965.273682 1686.770874,2967.020264 
	C1685.847046,2977.474365 1678.836426,2986.122070 1677.474854,2996.017578 
	C1675.430542,3010.874756 1667.122314,3024.893311 1671.108765,3040.572754 
	C1671.449097,3041.911621 1671.394775,3043.461182 1670.307983,3044.277588 
	C1665.382324,3047.977783 1666.262085,3053.840820 1664.841187,3058.786621 
	C1663.735474,3062.635010 1662.531006,3066.094238 1659.974243,3069.420898 
	C1654.862305,3076.072754 1654.615601,3084.708252 1651.997803,3093.202148 
	C1651.464111,3099.014404 1649.599487,3103.592529 1647.452271,3108.089111 
	C1646.453613,3110.180908 1645.699585,3112.426025 1644.464111,3114.362549 
	C1641.241089,3119.414795 1650.990234,3123.250244 1645.953857,3127.788330 
	C1642.816040,3130.615967 1636.532837,3128.799805 1634.376709,3135.051758 
	C1626.827026,3150.332275 1616.916382,3163.811523 1614.164795,3181.206543 
	C1609.678833,3187.663818 1607.012817,3194.194580 1604.479980,3201.577637 
	C1603.416870,3203.964355 1602.580811,3205.671631 1601.310059,3207.956055 
	C1599.585571,3209.963135 1597.916626,3210.831787 1595.555420,3211.841309 
	C1592.895752,3212.638184 1590.826294,3213.159912 1591.840088,3216.740967 
	C1592.157715,3223.400391 1589.393555,3228.532471 1586.804199,3234.424316 
	C1581.866577,3238.719238 1580.782349,3244.380615 1578.004150,3249.866455 
	C1574.682739,3254.335693 1571.828979,3258.293213 1569.534668,3263.387207 
	C1567.776245,3265.026611 1566.165161,3265.802979 1564.461670,3267.540527 
	C1564.001587,3275.072998 1560.001953,3278.633789 1552.747437,3280.012207 
	C1550.162964,3279.302979 1548.272827,3278.660400 1546.477051,3277.817139 
	C1539.759766,3274.663818 1539.443115,3272.691895 1544.739014,3267.570557 
	C1548.718872,3263.721924 1548.720215,3263.207520 1543.950806,3259.748535 
	C1542.605713,3258.772705 1540.845947,3258.139160 1541.069946,3256.190674 
	C1541.326416,3253.959229 1543.434814,3254.442383 1544.934326,3253.968506 
	C1551.325439,3251.948975 1556.374268,3247.637695 1561.734253,3243.878174 
	C1562.924561,3243.043213 1562.784302,3241.946533 1562.404297,3240.450684 
	C1560.170288,3231.658203 1561.873169,3228.938721 1571.354736,3226.032227 
	C1567.464478,3221.622559 1561.442139,3223.107178 1555.717163,3220.078857 
	C1565.854614,3215.218750 1568.813843,3207.469971 1568.018677,3197.900391 
	C1567.738647,3194.531250 1569.346802,3191.302979 1569.405762,3188.145508 
	C1569.555786,3180.134277 1575.153809,3173.557129 1574.744995,3165.521973 
	C1574.716553,3164.965088 1575.197021,3164.021240 1575.657104,3163.854492 
	C1582.457397,3161.391602 1579.819580,3155.455322 1580.919067,3150.721680 
	C1581.828369,3146.806396 1577.453979,3141.062500 1584.438965,3138.971191 
	C1584.628906,3138.914307 1584.692871,3138.435791 1584.815552,3138.154053 
	C1578.380981,3136.675293 1582.853516,3126.894775 1574.831055,3126.002441 
	C1572.090576,3119.422363 1577.481201,3114.689209 1578.288574,3108.775879 
	C1579.330200,3101.146484 1583.609863,3094.368652 1584.668457,3085.978760 
	C1584.842896,3084.825439 1584.978882,3084.374756 1585.497803,3083.331299 
	C1588.717163,3081.024902 1590.245728,3077.914062 1589.620483,3075.249268 
	C1587.239014,3065.099854 1595.192871,3057.954590 1597.173096,3048.613770 
	C1601.188843,3046.798584 1604.945557,3047.324219 1608.449341,3046.403809 
	C1616.276367,3044.347900 1620.672119,3038.484131 1620.240723,3030.571777 
	C1620.129272,3028.526611 1620.045288,3026.115967 1621.546997,3024.979980 
	C1628.442627,3019.763428 1633.202393,3013.682861 1635.460083,3004.853760 
	C1636.599731,3000.396973 1642.469360,3004.313721 1646.877197,3004.222656 
	C1652.741821,3004.375488 1657.688843,3000.224609 1663.071167,3004.229248 
	C1662.640137,2996.118164 1667.672485,2989.445801 1665.447388,2981.783447 
	C1665.002441,2980.251709 1667.253540,2978.260986 1669.074341,2977.386963 
	C1672.119141,2975.926025 1673.553101,2975.525391 1670.040161,2971.976318 
	C1665.901855,2967.794922 1668.027832,2961.396729 1672.177002,2958.884277 
	C1678.011475,2955.351074 1680.878418,2951.016113 1682.004028,2944.709473 
	C1682.277344,2943.178223 1683.983276,2942.115234 1686.033203,2940.850586 
	C1688.227417,2940.174316 1689.695679,2940.300049 1691.703003,2941.481201 
z"
        />
        <path
          fill="#D8D1C0"
          opacity="1.000000"
          stroke="none"
          d="
M1585.372925,3083.553467 
	C1587.835449,3088.427979 1592.444580,3093.228027 1584.274902,3096.379395 
	C1582.876709,3096.918457 1582.396851,3098.247559 1582.983765,3099.602295 
	C1584.848267,3103.906250 1582.534180,3107.155029 1580.412354,3110.476562 
	C1580.060913,3111.026611 1579.749512,3111.623291 1579.304443,3112.088379 
	C1576.738525,3114.770752 1583.658447,3117.184082 1579.655151,3119.547607 
	C1577.379761,3120.890869 1574.812500,3121.560791 1574.147705,3125.276855 
	C1569.539551,3128.120361 1563.667480,3125.185547 1559.746338,3129.801758 
	C1558.942017,3130.748779 1556.808838,3131.247070 1556.756714,3132.048096 
	C1556.207397,3140.466309 1549.458740,3145.943359 1546.910034,3153.356445 
	C1544.987671,3158.948242 1551.126709,3157.004639 1553.193970,3160.130859 
	C1549.529053,3163.002197 1545.654907,3164.817139 1540.928711,3163.258301 
	C1537.637695,3162.173096 1534.544189,3161.576172 1530.683838,3163.684082 
	C1524.096680,3158.613281 1518.969849,3161.415283 1512.932007,3164.881836 
	C1517.087524,3168.156494 1519.894775,3163.585938 1523.487061,3165.540283 
	C1523.848267,3170.914307 1525.784668,3172.800781 1530.672119,3170.769531 
	C1532.512085,3170.004639 1534.765137,3169.413330 1536.272095,3170.994873 
	C1538.635986,3173.476562 1535.089722,3174.930664 1534.786499,3176.965088 
	C1534.110352,3181.502441 1526.346436,3182.828613 1529.457764,3189.166504 
	C1530.061890,3190.396729 1528.206421,3190.999268 1526.785034,3191.175049 
	C1519.860596,3192.030029 1517.956909,3194.107422 1518.548340,3200.497070 
	C1518.724609,3202.401123 1520.685059,3204.851807 1519.236328,3206.120361 
	C1513.096191,3211.497803 1519.341675,3213.512451 1521.855957,3217.283691 
	C1520.696777,3219.324707 1519.405518,3220.706299 1516.657471,3220.334961 
	C1507.083496,3222.521973 1505.875732,3224.975098 1508.762939,3234.781006 
	C1507.136597,3236.332275 1504.912109,3235.543701 1503.109497,3236.201904 
	C1498.627563,3237.839600 1494.290771,3240.139893 1495.055420,3245.825684 
	C1495.574097,3249.683105 1493.255981,3250.648438 1490.760376,3251.316162 
	C1484.803833,3252.911133 1477.281128,3245.925049 1479.119507,3240.124512 
	C1480.703003,3235.127930 1478.189209,3229.254883 1482.687256,3224.922852 
	C1483.631104,3224.013672 1483.055298,3222.176025 1481.881104,3221.406738 
	C1478.848145,3219.420410 1477.650391,3217.103760 1480.267944,3214.071777 
	C1483.086792,3210.806641 1484.840576,3214.751709 1487.115601,3215.258789 
	C1488.058960,3215.469238 1489.114258,3215.689941 1490.015747,3215.474854 
	C1492.155640,3214.964844 1493.487427,3213.522217 1493.488525,3211.237305 
	C1493.489624,3209.196045 1492.060669,3208.337402 1490.269043,3208.166016 
	C1483.804199,3207.547363 1477.630005,3207.602295 1473.196167,3213.690430 
	C1471.543823,3215.959473 1470.141846,3217.766113 1471.236206,3220.824707 
	C1473.053345,3225.903076 1471.328125,3230.483887 1468.945312,3234.578857 
	C1465.426270,3235.810303 1464.854004,3228.618408 1461.327515,3232.397461 
	C1458.991455,3234.900635 1459.874634,3237.996338 1461.758667,3240.664307 
	C1462.921265,3242.310547 1464.176025,3243.914307 1463.101318,3246.550537 
	C1460.046387,3248.119141 1456.463623,3245.585693 1452.880249,3247.189453 
	C1448.392944,3249.197510 1444.515015,3248.263916 1443.379272,3242.225830 
	C1443.099609,3240.738281 1440.681030,3239.653320 1438.241211,3237.494385 
	C1446.230835,3236.592041 1448.316284,3230.060791 1452.465942,3225.398438 
	C1449.098145,3222.228271 1444.312744,3225.850098 1441.259766,3222.403564 
	C1441.716064,3219.185059 1444.860352,3219.062256 1446.562012,3219.080078 
	C1450.836670,3219.124756 1454.350708,3219.204346 1454.957397,3213.666260 
	C1455.095703,3212.404297 1457.041992,3212.113037 1458.384277,3212.286377 
	C1460.669189,3212.581543 1460.475708,3214.203857 1460.316406,3215.982178 
	C1460.079224,3218.627930 1460.000732,3221.306641 1460.117432,3223.959717 
	C1460.220703,3226.303223 1461.374512,3227.850098 1463.986572,3226.771973 
	C1465.829346,3226.011719 1468.110474,3224.766602 1466.195435,3222.464355 
	C1463.765137,3219.541748 1463.972900,3216.306152 1464.028564,3213.012207 
	C1464.153076,3205.629395 1467.914551,3202.269043 1475.381592,3202.887939 
	C1477.682373,3203.078613 1479.944458,3203.758057 1482.244995,3203.923096 
	C1484.218994,3204.064453 1486.672241,3204.079346 1487.383057,3201.829102 
	C1488.666504,3197.766846 1491.948120,3196.655273 1495.272217,3194.981934 
	C1498.670288,3193.270996 1501.608887,3190.075195 1498.614868,3186.118408 
	C1495.470093,3181.962402 1497.132080,3178.491211 1499.147949,3175.126221 
	C1500.401978,3173.033203 1502.025757,3171.560303 1500.084595,3169.066650 
	C1497.497925,3165.743652 1499.333008,3164.413330 1502.634521,3163.799561 
	C1505.035645,3163.353516 1505.892822,3161.656250 1505.678467,3159.412354 
	C1505.499146,3157.535156 1504.286011,3156.394287 1502.449585,3156.172607 
	C1499.251221,3155.786133 1496.429077,3154.400879 1495.108276,3151.592285 
	C1492.703979,3146.479492 1489.236816,3146.426758 1484.489868,3147.842285 
	C1480.087891,3149.155273 1475.599365,3150.626709 1470.784302,3149.980713 
	C1468.130615,3149.624756 1466.193726,3148.758789 1466.255737,3145.821533 
	C1466.318115,3142.863281 1467.547119,3140.098389 1470.939087,3140.909180 
	C1474.187256,3141.685547 1476.884399,3140.709961 1479.645996,3139.766846 
	C1482.657593,3138.738281 1485.350098,3138.202881 1487.972900,3140.632568 
	C1489.244629,3141.811035 1490.996338,3141.990234 1492.690430,3141.847412 
	C1495.935303,3141.573730 1501.766846,3136.428223 1500.909058,3134.267822 
	C1499.416992,3130.509521 1495.760620,3132.455566 1493.027344,3132.044678 
	C1491.734009,3131.850342 1490.367798,3132.207764 1489.041992,3132.148438 
	C1486.382080,3132.029297 1484.494263,3136.114014 1481.838867,3134.025146 
	C1479.389160,3132.097656 1478.771484,3128.573730 1475.565186,3126.742920 
	C1473.501221,3125.564941 1475.826050,3120.962158 1479.217407,3120.324463 
	C1484.741455,3119.285400 1490.350830,3118.653564 1495.947510,3118.073975 
	C1497.816895,3117.880371 1500.457031,3117.615234 1500.882202,3120.152588 
	C1501.273438,3122.486572 1498.989014,3123.304688 1497.105713,3124.079834 
	C1496.554565,3124.306641 1496.160889,3124.916016 1495.669678,3125.373779 
	C1496.659668,3128.285645 1499.200073,3127.893311 1501.425415,3127.972656 
	C1511.157837,3128.318604 1512.827271,3126.001221 1509.868774,3116.932373 
	C1508.567505,3112.943115 1506.250732,3111.607178 1502.314209,3112.878662 
	C1496.856201,3114.641846 1491.386597,3114.170654 1485.924683,3113.578125 
	C1485.635254,3110.408936 1487.565308,3108.975098 1489.253540,3108.571045 
	C1500.307739,3105.926758 1509.074585,3096.993652 1521.537964,3097.541992 
	C1523.706055,3099.631348 1526.285156,3101.795898 1525.179932,3103.785400 
	C1520.614502,3112.003174 1523.806885,3121.568359 1520.254272,3129.736328 
	C1517.614746,3135.804932 1518.511230,3141.191406 1521.125854,3147.395996 
	C1529.646240,3142.372070 1530.351318,3134.403564 1530.499146,3126.539307 
	C1530.610474,3120.618164 1532.093628,3115.309326 1535.495972,3110.678223 
	C1540.456055,3103.926514 1546.610962,3104.273682 1553.192749,3112.524902 
	C1551.915283,3100.442383 1556.922852,3094.441650 1567.982788,3093.789551 
	C1571.631226,3093.574463 1573.819092,3091.019775 1575.913574,3088.324707 
	C1578.150391,3085.446777 1580.390625,3082.369873 1585.372925,3083.553467 
z"
        />
        <path
          fill="#D0C8B8"
          opacity="1.000000"
          stroke="none"
          d="
M1585.961670,3083.968750 
	C1581.321655,3084.378174 1579.187866,3087.680176 1577.338501,3091.332520 
	C1575.217529,3095.521484 1572.495728,3097.423584 1567.126709,3097.021240 
	C1558.771118,3096.395020 1556.627197,3099.057617 1556.055054,3108.115479 
	C1555.861328,3111.181152 1554.912598,3113.932861 1554.001465,3116.762207 
	C1553.552979,3118.155273 1552.750122,3119.159668 1551.236328,3118.958496 
	C1549.773926,3118.765137 1549.099121,3117.549316 1548.739014,3116.214111 
	C1548.656006,3115.907471 1548.709106,3115.549805 1548.758301,3115.224121 
	C1549.125366,3112.790283 1551.187256,3109.802490 1547.409424,3108.473633 
	C1544.070923,3107.299561 1540.054321,3107.685059 1538.524292,3110.904053 
	C1536.192383,3115.810303 1532.713623,3119.871094 1533.923584,3126.934814 
	C1534.864014,3132.424561 1533.840332,3140.398926 1528.579346,3145.603516 
	C1527.885498,3146.290283 1526.982788,3146.784180 1526.130127,3147.288574 
	C1523.638550,3148.761719 1524.633301,3154.119385 1520.603027,3153.246094 
	C1516.531494,3152.364502 1517.984253,3147.671631 1516.753540,3144.801758 
	C1515.483276,3141.838867 1515.362305,3138.388428 1514.691895,3135.160889 
	C1514.417603,3133.840576 1513.974487,3132.137207 1515.062744,3131.419189 
	C1521.146851,3127.405029 1519.881836,3122.153076 1518.222046,3116.482666 
	C1517.353149,3113.514648 1518.251953,3110.439941 1520.757080,3108.343994 
	C1523.923096,3105.695312 1523.472778,3102.665771 1522.081543,3098.713379 
	C1530.308472,3094.520752 1530.412964,3094.254150 1526.099365,3086.628906 
	C1526.387817,3085.597412 1526.789307,3085.206543 1527.192017,3084.816895 
	C1527.596313,3084.426270 1527.463379,3084.556396 1526.402100,3085.608887 
	C1523.517944,3086.021240 1521.730835,3087.563965 1519.757935,3088.782959 
	C1517.899780,3089.930664 1515.714844,3090.179932 1514.691040,3087.891602 
	C1513.580933,3085.410156 1513.480835,3082.684082 1516.029053,3080.728271 
	C1516.514404,3080.355957 1517.316895,3080.098633 1517.902344,3080.199219 
	C1529.748779,3082.236084 1531.051147,3072.845947 1533.638794,3065.311768 
	C1534.617920,3062.461182 1535.553223,3059.888672 1537.378174,3057.410156 
	C1541.028687,3052.452637 1540.573730,3051.711914 1534.281494,3049.912354 
	C1533.355103,3049.647217 1532.454102,3049.267822 1531.584595,3048.851318 
	C1531.408936,3048.767090 1531.428101,3048.276123 1531.204102,3047.315186 
	C1535.254517,3044.866455 1543.034912,3045.755127 1542.174927,3036.258301 
	C1542.963623,3033.959473 1543.824219,3033.292969 1545.378906,3032.848633 
	C1545.838745,3032.764160 1546.689697,3032.576172 1546.689697,3032.576172 
	C1546.689697,3032.576172 1546.665039,3031.664551 1546.692993,3031.076660 
	C1551.927002,3020.878418 1554.323120,3009.376953 1566.241943,3004.468018 
	C1569.600952,3003.084473 1572.165894,2999.134033 1573.355591,2995.163574 
	C1578.888550,2976.699219 1587.828613,2959.680176 1595.728027,2942.219971 
	C1596.287720,2940.982910 1597.278809,2940.078857 1599.139648,2939.230713 
	C1606.103149,2941.447266 1603.534424,2946.376953 1602.271362,2949.975098 
	C1596.630493,2966.045410 1594.159546,2983.170654 1586.136841,2998.443848 
	C1584.569702,3001.427490 1584.013184,3004.724854 1583.964966,3008.097412 
	C1583.836426,3017.079834 1587.556641,3026.213379 1582.450928,3035.061035 
	C1585.551880,3026.994873 1589.395142,3019.233643 1588.203979,3010.378418 
	C1588.026978,3009.062500 1587.861328,3007.572754 1588.483643,3006.436279 
	C1600.942017,2983.684814 1600.805664,2956.953857 1611.512451,2933.655762 
	C1612.356812,2931.818359 1613.107910,2929.984375 1615.942139,2929.194336 
	C1620.933838,2931.105713 1621.011597,2934.923584 1620.099609,2938.432373 
	C1616.165771,2953.566650 1614.011963,2969.156006 1608.580566,2983.902344 
	C1606.966675,2988.283691 1605.603149,2992.635010 1607.392334,2998.074219 
	C1607.477295,2999.345215 1607.453735,2999.851562 1607.218506,3001.077881 
	C1606.171875,3004.011230 1602.484619,3004.553467 1602.853882,3006.953613 
	C1604.248535,3016.017578 1598.753418,3023.257080 1596.918457,3032.174805 
	C1593.929199,3043.727539 1595.605347,3055.647217 1588.468262,3065.537109 
	C1584.774658,3070.655273 1589.853149,3077.189209 1586.452393,3083.346680 
	C1585.964478,3083.941406 1585.983398,3083.969482 1585.961670,3083.968750 
z"
        />
        <path
          fill="#F2EBDA"
          opacity="1.000000"
          stroke="none"
          d="
M1542.026611,3036.026367 
	C1534.341553,3040.200684 1526.029175,3041.572266 1517.734985,3040.143066 
	C1516.860962,3043.383789 1518.468384,3043.574219 1519.575806,3044.106201 
	C1521.380737,3044.973145 1523.530640,3045.750488 1522.696411,3048.301514 
	C1522.062622,3050.239258 1520.005005,3049.874023 1518.409546,3049.952637 
	C1515.087036,3050.116943 1511.741699,3050.014160 1508.442017,3050.362305 
	C1506.821045,3050.533691 1505.281860,3051.523682 1505.126587,3053.509521 
	C1504.985352,3055.315674 1506.493530,3055.589355 1507.730591,3055.927002 
	C1509.708862,3056.466797 1511.927246,3056.938477 1511.253540,3059.638428 
	C1510.639404,3062.099365 1508.261963,3061.820312 1506.309814,3061.968018 
	C1505.646851,3062.018066 1504.976196,3061.960205 1504.312378,3062.003174 
	C1501.084839,3062.212158 1496.956787,3060.957764 1495.297241,3064.608887 
	C1493.183228,3069.260254 1498.834106,3070.194336 1500.758179,3073.596191 
	C1489.266357,3082.784180 1475.247681,3085.958496 1460.803101,3089.994141 
	C1458.039917,3090.876221 1456.127563,3090.629639 1453.652588,3089.278076 
	C1447.738525,3082.642090 1452.829712,3078.494141 1456.095947,3074.086426 
	C1464.102661,3063.281250 1472.835327,3053.420654 1485.586670,3047.717041 
	C1494.379883,3043.783447 1497.274780,3034.673340 1501.243042,3026.785156 
	C1496.832764,3025.715820 1489.888428,3027.692383 1490.968506,3018.393799 
	C1499.763062,3013.607178 1508.267700,3009.476074 1508.507202,2998.083984 
	C1508.520996,2997.426758 1508.860840,2996.718506 1509.375610,2996.315674 
	C1525.373779,2983.789551 1530.662964,2962.575928 1547.013550,2950.052979 
	C1550.231445,2947.588135 1553.140869,2941.485840 1553.032959,2936.167480 
	C1552.899780,2929.602539 1556.419556,2925.119141 1561.098267,2922.122314 
	C1569.279907,2916.882324 1573.998291,2909.973633 1575.492310,2900.480225 
	C1576.295410,2895.376709 1578.798462,2891.702393 1585.523682,2892.727539 
	C1592.979980,2898.836914 1596.933350,2905.092041 1588.914917,2912.978516 
	C1590.519043,2928.620117 1571.196411,2933.263916 1570.598877,2947.772949 
	C1570.574463,2948.365723 1569.999268,2949.081055 1569.428467,2949.333008 
	C1555.474731,2955.483887 1558.003906,2968.896240 1556.092285,2980.181152 
	C1555.406250,2984.230957 1554.546265,2986.729248 1550.185181,2988.060303 
	C1540.994751,2990.864990 1540.588989,2991.738281 1538.033447,3002.648438 
	C1544.392334,2999.590820 1554.518188,2987.622803 1556.247559,2981.120850 
	C1558.788208,2971.568115 1569.402466,2961.425781 1578.055664,2961.230469 
	C1582.677124,2968.434326 1579.932739,2974.198486 1576.106445,2980.041504 
	C1569.841919,2989.608643 1561.209473,2997.715088 1557.154053,3008.594482 
	C1553.606201,3018.112305 1544.235107,3024.309570 1542.432373,3035.453857 
	C1542.041382,3036.027832 1542.012329,3036.009766 1542.026611,3036.026367 
z"
        />
        <path
          fill="#F2EBDA"
          opacity="1.000000"
          stroke="none"
          d="
M1457.378906,3093.589844 
	C1462.876221,3094.282227 1467.827515,3093.358154 1473.852661,3095.062256 
	C1470.220947,3098.509277 1465.194580,3095.882568 1463.066895,3100.166260 
	C1462.910767,3100.853027 1463.697388,3101.483154 1464.465942,3102.119385 
	C1466.279053,3103.620361 1468.355103,3105.114502 1466.547241,3107.825928 
	C1465.296265,3109.701904 1463.186401,3110.233887 1461.260010,3109.391602 
	C1455.261108,3106.769043 1449.632202,3111.471680 1442.902710,3110.056641 
	C1440.105469,3110.604492 1439.341797,3109.425049 1438.853394,3107.078125 
	C1439.095703,3105.302002 1439.485352,3104.356689 1440.455933,3102.840576 
	C1444.311401,3095.947266 1448.885742,3091.711670 1457.378906,3093.589844 
z"
        />
        <path
          fill="#EEE7D5"
          opacity="1.000000"
          stroke="none"
          d="
M1548.987183,3205.538574 
	C1549.302979,3207.331299 1549.610840,3208.927734 1548.959351,3209.836182 
	C1546.463257,3213.316895 1552.202148,3219.145264 1547.386719,3220.451172 
	C1542.340332,3221.819092 1536.449219,3220.161133 1530.944702,3219.642090 
	C1530.232300,3219.575195 1529.002319,3218.305908 1529.092529,3217.777832 
	C1529.295898,3216.585205 1530.049316,3215.414307 1530.802612,3214.400635 
	C1533.475952,3210.802734 1537.089355,3214.616455 1539.958862,3213.358154 
	C1543.093506,3211.983643 1538.355591,3206.338867 1543.777466,3205.791748 
	C1545.364624,3205.631348 1546.813721,3204.470459 1548.987183,3205.538574 
z"
        />
        <path
          fill="#D0C8B8"
          opacity="1.000000"
          stroke="none"
          d="
M1524.441162,3165.504883 
	C1521.928833,3166.160889 1520.353638,3167.103027 1518.794067,3168.547363 
	C1515.111694,3171.958984 1509.919678,3170.530762 1508.647217,3165.918213 
	C1508.483398,3165.324219 1508.419556,3164.289551 1508.751465,3164.035400 
	C1512.127686,3161.445801 1515.591919,3158.710205 1519.899780,3158.180420 
	C1523.792236,3157.701904 1528.157959,3157.240234 1529.873779,3163.235352 
	C1528.228882,3164.052246 1526.421265,3163.876465 1524.441162,3165.504883 
z"
        />
        <path
          fill="#EEE7D5"
          opacity="1.000000"
          stroke="none"
          d="
M1458.252075,3093.460938 
	C1457.449829,3094.236328 1456.876709,3094.689453 1456.270874,3094.738525 
	C1450.234619,3095.227295 1445.786255,3097.951416 1442.695557,3103.833008 
	C1437.455688,3102.263916 1436.190552,3098.514648 1436.860107,3094.312744 
	C1437.592651,3089.716553 1441.859863,3089.598877 1445.406616,3088.850830 
	C1447.698242,3088.367676 1450.036621,3088.323975 1453.185425,3088.107422 
	C1455.617676,3088.498291 1457.230469,3088.996826 1459.429199,3089.757324 
	C1459.498901,3090.996826 1458.982422,3091.974121 1458.252075,3093.460938 
z"
        />
        <path
          fill="#EEE7D5"
          opacity="1.000000"
          stroke="none"
          d="
M1361.059692,3265.840576 
	C1356.275879,3262.796143 1351.573608,3266.162354 1347.142822,3265.077148 
	C1347.758911,3260.933838 1351.678101,3262.538330 1353.317017,3260.582764 
	C1354.907349,3258.685303 1356.293335,3255.344727 1359.508301,3257.321533 
	C1362.273804,3259.022461 1362.928955,3262.396973 1363.528809,3265.489014 
	C1363.533081,3265.511230 1362.351318,3265.763428 1361.059692,3265.840576 
z"
        />
        <path
          fill="#EEE7D5"
          opacity="1.000000"
          stroke="none"
          d="
M1545.997314,3233.211426 
	C1540.162109,3234.637939 1537.293945,3230.198242 1533.553467,3226.965088 
	C1542.786499,3222.359131 1544.219849,3223.015381 1545.997314,3233.211426 
z"
        />
        <path
          fill="#EEE7D5"
          opacity="1.000000"
          stroke="none"
          d="
M1545.997559,3234.004883 
	C1547.302246,3233.908447 1548.612671,3233.837402 1549.915649,3233.698242 
	C1551.967163,3233.479248 1554.611694,3231.325684 1555.604858,3234.880859 
	C1556.302246,3237.377197 1556.623291,3240.419922 1553.297363,3241.481445 
	C1550.820068,3242.272217 1547.941528,3242.641357 1546.560303,3239.464111 
	C1546.061401,3238.316162 1546.178711,3236.899902 1546.009888,3234.798340 
	C1546.004883,3233.990234 1546.000000,3234.000000 1545.997559,3234.004883 
z"
        />
        <path
          fill="#EEE7D5"
          opacity="1.000000"
          stroke="none"
          d="
M1516.021973,3220.825684 
	C1517.592773,3219.389160 1519.161255,3218.749023 1521.363892,3218.035156 
	C1522.292847,3219.910889 1522.400269,3221.912598 1522.923706,3223.799072 
	C1523.823853,3227.044434 1522.805786,3229.188721 1519.477661,3229.450928 
	C1516.140747,3229.713867 1516.195679,3226.925049 1516.048340,3224.601807 
	C1515.985474,3223.611816 1516.026123,3222.615479 1516.021973,3220.825684 
z"
        />
        <path
          fill="#EEE7D5"
          opacity="1.000000"
          stroke="none"
          d="
M1533.776978,3257.306641 
	C1532.140991,3257.269287 1530.996826,3257.236328 1530.314209,3256.658203 
	C1528.019897,3254.715088 1527.432983,3252.182861 1529.063965,3249.606934 
	C1530.039673,3248.066406 1531.865845,3247.947998 1533.520752,3248.586182 
	C1536.372437,3249.685547 1535.964600,3252.216553 1535.764282,3254.476562 
	C1535.685059,3255.368896 1534.928345,3256.201172 1533.776978,3257.306641 
z"
        />
        <path
          fill="#EEE7D5"
          opacity="1.000000"
          stroke="none"
          d="
M1323.797607,3203.850098 
	C1320.334961,3203.158203 1317.626465,3202.002930 1319.264526,3198.556152 
	C1320.571533,3195.805908 1323.079712,3196.268066 1325.006958,3198.213135 
	C1326.942261,3200.166016 1329.056519,3202.402100 1323.797607,3203.850098 
z"
        />
        <path
          fill="#EEE7D5"
          opacity="1.000000"
          stroke="none"
          d="
M1309.513184,3202.017090 
	C1307.460449,3203.158203 1305.992676,3204.020264 1304.250366,3203.390137 
	C1303.275757,3203.037598 1302.559326,3202.378662 1302.555664,3201.231934 
	C1302.549072,3199.153320 1303.916504,3198.132812 1305.673340,3198.231201 
	C1307.724609,3198.346436 1311.765625,3196.485596 1309.513184,3202.017090 
z"
        />
        <path
          fill="#E9E1D0"
          opacity="1.000000"
          stroke="none"
          d="
M1436.747314,3117.984863 
	C1436.011597,3117.673096 1436.008301,3117.336670 1436.002441,3116.495117 
	C1435.234619,3115.111084 1434.936890,3114.094727 1435.497803,3112.211914 
	C1437.401123,3110.528076 1438.981323,3110.452881 1441.314575,3111.558594 
	C1442.057617,3115.179199 1441.594727,3117.959717 1436.747314,3117.984863 
z"
        />
        <path
          fill="#EEE7D5"
          opacity="1.000000"
          stroke="none"
          d="
M1442.018921,3111.523682 
	C1440.508423,3112.006348 1439.007202,3112.003174 1436.755371,3112.000000 
	C1435.667969,3111.584229 1435.331055,3111.168701 1434.809570,3109.971191 
	C1434.804321,3106.731201 1435.866577,3105.152344 1439.282959,3105.604980 
	C1440.357300,3107.104980 1440.729248,3108.219971 1441.579590,3109.700684 
	C1442.058105,3110.066406 1442.028076,3111.037842 1442.018921,3111.523682 
z"
        />
        <path
          fill="#EEE7D5"
          opacity="1.000000"
          stroke="none"
          d="
M1334.162598,3198.838867 
	C1336.025879,3200.445557 1336.115601,3201.924805 1334.393188,3203.036621 
	C1333.470825,3203.631836 1332.359619,3203.136230 1331.832153,3202.243164 
	C1330.672485,3200.279785 1331.831909,3199.300049 1334.162598,3198.838867 
z"
        />
        <path
          fill="#EEE7D5"
          opacity="1.000000"
          stroke="none"
          d="
M1293.484985,3201.000977 
	C1295.187622,3200.269287 1296.407471,3200.319580 1296.870972,3201.791260 
	C1296.997437,3202.192871 1296.531250,3203.151611 1296.146484,3203.279541 
	C1294.501831,3203.824951 1293.052490,3203.728271 1293.484985,3201.000977 
z"
        />
        <path
          fill="#F2EBDA"
          opacity="1.000000"
          stroke="none"
          d="
M1519.072754,3058.952637 
	C1519.120483,3059.039795 1519.025024,3058.865479 1519.072754,3058.952637 
z"
        />
        <path
          fill="#EBE4D0"
          opacity="1.000000"
          stroke="none"
          d="
M1458.930298,3411.045898 
	C1458.885132,3410.961182 1458.975464,3411.130859 1458.930298,3411.045898 
z"
        />
        <path
          fill="#D8D1C0"
          opacity="1.000000"
          stroke="none"
          d="
M1593.582520,3210.455566 
	C1595.553955,3209.366455 1597.092285,3208.703613 1599.257324,3207.969238 
	C1601.485474,3206.778076 1601.947144,3205.145996 1601.943848,3202.648682 
	C1604.531616,3195.386963 1604.405151,3187.544922 1611.361328,3182.316650 
	C1618.798462,3172.266846 1625.814087,3171.295898 1641.682617,3178.520020 
	C1643.139771,3180.529053 1643.239258,3182.033936 1642.200195,3184.208008 
	C1636.888428,3186.504395 1636.951538,3191.109375 1635.444824,3195.629883 
	C1634.157593,3197.480225 1632.922729,3198.211914 1630.705322,3198.239502 
	C1623.096680,3198.033691 1615.790771,3196.776611 1611.107666,3203.911865 
	C1610.205444,3205.286621 1608.180908,3207.141113 1608.538696,3207.941895 
	C1613.172241,3218.322021 1599.985352,3226.458252 1605.080444,3236.937012 
	C1606.248657,3239.339844 1604.219971,3241.358643 1601.292725,3241.292480 
	C1599.759399,3241.258057 1598.121216,3239.989990 1596.784790,3242.026855 
	C1598.274170,3247.892334 1603.003540,3247.965820 1607.754883,3248.111084 
	C1609.961182,3248.178467 1611.951050,3249.083496 1611.323608,3251.650146 
	C1610.799561,3253.793457 1608.865845,3253.033203 1607.216553,3252.705566 
	C1600.377808,3251.347168 1603.364014,3255.723389 1603.579834,3259.895508 
	C1602.033203,3262.447021 1600.499634,3263.410889 1598.463135,3264.809082 
	C1596.994019,3265.340088 1596.117310,3265.440674 1594.594727,3265.250732 
	C1592.479126,3264.620117 1591.157715,3263.427490 1588.854248,3264.290039 
	C1585.267700,3265.982910 1582.902222,3268.912354 1578.549805,3268.322266 
	C1575.152100,3267.334473 1572.313843,3266.678955 1568.740234,3266.006836 
	C1567.741821,3265.658691 1567.478882,3265.327148 1567.573486,3264.471680 
	C1568.036377,3258.391113 1570.273438,3253.930908 1575.384277,3250.317383 
	C1582.413452,3246.969727 1585.345093,3242.259277 1583.594971,3234.556152 
	C1586.630005,3228.747559 1588.557251,3223.221924 1590.074341,3216.770508 
	C1590.345337,3213.837891 1591.611084,3212.278809 1593.582520,3210.455566 
z"
        />
        <path
          fill="#E5DDC9"
          opacity="1.000000"
          stroke="none"
          d="
M1593.545654,3263.522461 
	C1594.905884,3263.966797 1595.780029,3263.965576 1597.309570,3263.962891 
	C1599.348389,3262.391113 1601.268921,3261.681396 1603.528564,3260.382812 
	C1603.989990,3259.990234 1604.000000,3260.000000 1603.995117,3259.995117 
	C1608.153687,3260.455078 1612.179077,3259.695312 1616.458496,3262.010010 
	C1621.630127,3264.806885 1626.386719,3260.232178 1630.774780,3257.806641 
	C1634.401489,3255.802002 1634.363037,3251.895752 1633.095337,3248.023682 
	C1632.388428,3245.864258 1632.254150,3243.487061 1632.085327,3241.191650 
	C1631.962280,3239.519775 1632.338013,3237.005859 1633.860352,3236.919434 
	C1643.951782,3236.347168 1643.749634,3224.596191 1651.384766,3221.165771 
	C1654.651611,3219.697998 1653.903198,3215.665771 1653.179565,3212.161865 
	C1651.894165,3205.938232 1653.149170,3204.501465 1659.417969,3204.067139 
	C1662.725098,3203.837891 1666.063965,3204.017334 1669.387085,3204.082764 
	C1671.103882,3204.116699 1672.815063,3204.517090 1673.343994,3206.407715 
	C1673.971924,3208.653076 1672.711304,3210.246582 1670.918213,3211.346436 
	C1669.510742,3212.209961 1667.998535,3212.947021 1666.455444,3213.538086 
	C1657.431274,3216.995850 1656.824463,3218.236816 1661.812256,3225.761475 
	C1663.189575,3227.839600 1663.975220,3229.743896 1663.964233,3232.141357 
	C1663.951050,3235.021729 1663.404053,3237.222168 1659.969238,3237.990723 
	C1653.296875,3239.483154 1648.760376,3244.903320 1642.808228,3247.820312 
	C1639.739258,3249.324463 1639.119629,3253.019043 1642.743774,3253.378906 
	C1649.057861,3254.005859 1646.921997,3261.412354 1651.871948,3262.828613 
	C1653.587158,3263.319092 1652.161865,3265.822510 1651.716553,3267.301758 
	C1651.008423,3269.653809 1648.753174,3270.499756 1646.938110,3269.696045 
	C1641.015747,3267.073730 1636.310913,3268.980713 1631.200684,3272.158691 
	C1626.452148,3275.111816 1621.152100,3275.067139 1616.256226,3271.456299 
	C1612.307983,3268.543945 1608.196411,3266.671875 1603.712891,3270.817139 
	C1602.468018,3271.967773 1600.549194,3272.082520 1598.982300,3271.312744 
	C1595.512817,3269.608398 1592.881348,3271.024170 1589.742188,3272.650146 
	C1585.377441,3274.911377 1580.681519,3275.463867 1578.102783,3268.729492 
	C1580.895874,3266.062500 1583.801514,3264.135010 1587.353271,3262.098633 
	C1589.753540,3262.038818 1591.533325,3261.967285 1593.545654,3263.522461 
z"
        />
        <path
          fill="#E5DDC9"
          opacity="1.000000"
          stroke="none"
          d="
M1629.999878,3198.824707 
	C1630.987061,3197.479248 1631.974243,3196.958496 1633.473389,3196.213867 
	C1636.825073,3192.861328 1637.394287,3188.677490 1639.539551,3184.505371 
	C1640.626709,3183.056396 1641.258057,3182.115967 1641.944824,3180.587891 
	C1639.101440,3176.948975 1640.347778,3173.636230 1641.677124,3169.665039 
	C1642.212646,3168.580566 1642.460449,3168.166748 1643.171143,3167.204346 
	C1646.059814,3162.899170 1654.137695,3163.531494 1651.603516,3155.391846 
	C1649.578613,3142.278564 1653.042725,3137.289551 1665.091797,3136.289551 
	C1671.086304,3139.767822 1670.762207,3145.671631 1672.312256,3151.270020 
	C1670.859253,3154.930664 1669.703979,3157.848877 1668.271973,3161.383545 
	C1663.397827,3161.673828 1660.617676,3163.571045 1659.598633,3168.259277 
	C1658.701538,3172.385498 1657.354858,3176.414062 1656.108276,3181.247559 
	C1657.198853,3186.097412 1652.363525,3185.890381 1651.679443,3188.833984 
	C1652.661255,3189.292969 1653.281372,3189.049561 1654.364014,3188.265625 
	C1658.116089,3185.881348 1661.513184,3186.189453 1665.476807,3187.602051 
	C1668.540771,3190.117676 1674.117554,3189.032471 1673.609619,3194.231689 
	C1673.065796,3199.797119 1668.047485,3197.340820 1664.921753,3198.178711 
	C1661.806274,3199.013672 1658.895630,3199.817139 1655.345215,3198.952881 
	C1648.382935,3197.257812 1646.206299,3198.648438 1644.139282,3206.107178 
	C1642.815796,3210.883545 1639.849731,3213.382324 1635.751831,3214.981934 
	C1631.009277,3216.833008 1627.633423,3214.282471 1628.676147,3209.353271 
	C1629.361328,3206.114990 1630.112915,3202.964844 1629.999878,3198.824707 
z"
        />
        <path
          fill="#E5DDC9"
          opacity="1.000000"
          stroke="none"
          d="
M1689.297852,3215.646729 
	C1686.922485,3215.765381 1684.909546,3215.689697 1684.720337,3213.682129 
	C1684.323975,3209.475098 1691.836426,3197.990479 1695.624023,3196.755615 
	C1699.175415,3195.597412 1701.122437,3197.635742 1702.752686,3200.924805 
	C1696.968018,3204.424561 1695.042969,3211.260742 1689.297852,3215.646729 
z"
        />
        <path
          fill="#D8D1C0"
          opacity="1.000000"
          stroke="none"
          d="
M1650.645508,3191.974121 
	C1645.931641,3191.186035 1644.867920,3189.067139 1648.181274,3186.276367 
	C1650.059326,3184.694580 1652.754883,3184.083496 1655.546021,3182.522461 
	C1657.866577,3181.489258 1659.699585,3180.651855 1661.584839,3180.510986 
	C1666.103271,3180.173828 1668.578979,3181.776855 1666.257080,3187.389160 
	C1663.183350,3190.655029 1659.741577,3187.897461 1656.370117,3189.525635 
	C1654.651489,3191.186768 1653.005005,3191.643555 1650.645508,3191.974121 
z"
        />
        <path
          fill="#E5DDC9"
          opacity="1.000000"
          stroke="none"
          d="
M1591.579834,3320.480957 
	C1593.374023,3321.696289 1593.224365,3322.725098 1591.822510,3323.422852 
	C1590.939941,3323.862305 1589.800293,3323.716064 1589.356689,3322.778564 
	C1588.638672,3321.260986 1589.632568,3320.601562 1591.579834,3320.480957 
z"
        />
        <path
          fill="#E5DDC9"
          opacity="1.000000"
          stroke="none"
          d="
M1574.480225,3286.420166 
	C1575.696167,3284.625732 1576.725342,3284.775146 1577.423706,3286.177490 
	C1577.863159,3287.060303 1577.716431,3288.200195 1576.778564,3288.644043 
	C1575.260254,3289.362061 1574.600708,3288.367920 1574.480225,3286.420166 
z"
        />
        <path
          fill="#E5DDC9"
          opacity="1.000000"
          stroke="none"
          d="
M1589.910645,3287.114746 
	C1588.550781,3286.368164 1588.535645,3285.617676 1589.506958,3284.955322 
	C1589.710327,3284.816650 1590.181641,3284.822754 1590.379639,3284.967285 
	C1591.327148,3285.658447 1591.292236,3286.407959 1589.910645,3287.114746 
z"
        />
        <path
          fill="#E5DDC9"
          opacity="1.000000"
          stroke="none"
          d="
M1567.307983,3264.063477 
	C1567.947998,3264.287842 1567.964844,3264.628174 1567.993408,3265.479492 
	C1567.061523,3266.623779 1566.118286,3267.257324 1564.584961,3267.945312 
	C1564.173462,3266.220703 1564.705322,3264.690430 1567.307983,3264.063477 
z"
        />
        <path
          fill="#9FA2A1"
          opacity="1.000000"
          stroke="none"
          d="
M1218.316895,3172.434082 
	C1220.574585,3171.854492 1222.145752,3173.101074 1223.405518,3174.625732 
	C1232.818848,3186.018555 1243.598145,3196.255127 1252.219360,3209.128418 
	C1244.242554,3209.773193 1238.225220,3204.653809 1235.159790,3198.599365 
	C1232.566284,3193.477051 1229.484009,3190.186035 1225.002930,3187.383301 
	C1219.818115,3184.140625 1216.949097,3179.660400 1218.316895,3172.434082 
z"
        />
        <path
          fill="#9FA2A1"
          opacity="1.000000"
          stroke="none"
          d="
M1208.313599,3160.411621 
	C1213.910767,3161.108398 1215.939697,3165.777100 1218.328491,3170.859863 
	C1209.845581,3170.804932 1209.211792,3170.167236 1208.313599,3160.411621 
z"
        />
        <path
          fill="#8C8D89"
          opacity="1.000000"
          stroke="none"
          d="
M1252.060791,3260.495361 
	C1250.150513,3261.634033 1248.072266,3262.447754 1247.446167,3260.754639 
	C1246.298340,3257.651123 1249.368774,3257.080078 1251.265381,3256.902588 
	C1253.639893,3256.680176 1253.345825,3258.490479 1252.060791,3260.495361 
z"
        />
        <path
          fill="#6B6164"
          opacity="1.000000"
          stroke="none"
          d="
M1080.080566,3095.841064 
	C1079.734619,3095.988525 1079.897827,3095.002686 1079.952026,3094.506348 
	C1080.297485,3094.377686 1080.622803,3094.726318 1080.851318,3095.129639 
	C1080.897583,3095.211182 1080.577637,3095.500244 1080.080566,3095.841064 
z"
        />
        <path
          fill="#704A3A"
          opacity="1.000000"
          stroke="none"
          d="
M1547.828979,2304.718018 
	C1548.544434,2303.223389 1547.956787,2300.782715 1549.881958,2302.167969 
	C1551.747437,2303.510010 1553.131836,2303.586914 1555.392578,2302.208496 
	C1563.189697,2299.797852 1561.403931,2305.510986 1561.485474,2308.817383 
	C1561.665161,2316.084229 1557.293823,2322.712402 1558.244385,2330.848877 
	C1559.971680,2348.523193 1549.739624,2358.289795 1536.778076,2365.939209 
	C1533.716064,2367.746338 1533.288574,2369.927490 1532.062622,2372.593506 
	C1528.333496,2380.703369 1525.462646,2390.058350 1513.533081,2390.233887 
	C1511.844360,2388.063721 1511.594116,2386.151367 1511.705566,2383.448242 
	C1514.023315,2379.015869 1511.536499,2372.124512 1519.595947,2371.976807 
	C1520.883545,2371.953125 1521.878418,2366.148926 1522.843140,2362.955322 
	C1526.076660,2352.251465 1529.608643,2341.666504 1536.295654,2331.680176 
	C1544.597290,2324.334961 1551.350220,2317.079834 1547.828979,2304.718018 
z"
        />
        <path
          fill="#603A24"
          opacity="1.000000"
          stroke="none"
          d="
M1671.660889,2277.433594 
	C1671.056030,2278.630371 1670.072632,2279.334229 1668.485107,2280.213867 
	C1666.379395,2280.428955 1664.877808,2280.468018 1662.656494,2280.733398 
	C1660.410645,2280.804932 1658.884644,2280.649658 1656.588135,2280.548584 
	C1652.323242,2278.341553 1650.278076,2274.734619 1649.046021,2271.146729 
	C1646.082520,2262.516357 1639.870972,2260.887939 1631.963379,2261.865723 
	C1622.409302,2263.047363 1613.751343,2259.696533 1605.458862,2255.923584 
	C1608.362305,2247.561035 1611.048340,2246.219971 1617.619751,2249.627197 
	C1623.568481,2252.711914 1629.581909,2254.267822 1636.071777,2251.253174 
	C1637.222778,2250.718506 1638.774780,2250.028564 1639.794556,2250.389893 
	C1648.318115,2253.410400 1656.686768,2251.613281 1665.149658,2250.135986 
	C1669.421265,2249.390381 1672.527832,2251.151367 1671.882690,2256.057617 
	C1671.386597,2259.830322 1674.894165,2264.281494 1669.662231,2267.634277 
	C1668.863770,2268.145752 1666.484131,2274.517090 1671.660889,2277.433594 
z"
        />
        <path
          fill="#603A24"
          opacity="1.000000"
          stroke="none"
          d="
M1602.020020,2265.333496 
	C1599.812744,2270.944092 1595.339966,2274.208740 1592.513184,2278.676270 
	C1590.312622,2282.154297 1587.210205,2282.193359 1583.017090,2281.662109 
	C1580.102417,2280.564941 1577.751953,2279.750977 1577.588257,2276.295410 
	C1579.450073,2274.461670 1580.997559,2273.271240 1582.784912,2271.896240 
	C1578.159790,2267.762695 1575.649048,2262.115723 1569.795898,2259.280273 
	C1566.745972,2257.802979 1567.738647,2250.126221 1570.633423,2247.683594 
	C1572.306519,2246.271729 1573.797119,2247.030029 1574.857422,2248.402344 
	C1576.572144,2250.622070 1578.601562,2252.706299 1581.447510,2251.863770 
	C1588.545288,2249.761719 1594.679443,2253.239014 1601.735840,2255.126953 
	C1601.415771,2258.593750 1602.215820,2261.650146 1602.020020,2265.333496 
z"
        />
        <path
          fill="#8C5B43"
          opacity="1.000000"
          stroke="none"
          d="
M1602.662964,2265.703613 
	C1599.222290,2263.785400 1598.880493,2260.014893 1601.708008,2255.775146 
	C1603.093628,2255.607910 1603.890381,2255.625732 1605.269653,2255.493652 
	C1606.258667,2255.841797 1606.974854,2256.806396 1607.027222,2256.771729 
	C1611.652466,2253.698486 1615.524292,2255.262451 1619.742554,2258.048096 
	C1622.384644,2259.792725 1625.965820,2261.041748 1629.179199,2259.198730 
	C1634.267212,2256.280273 1639.544189,2260.824951 1644.633789,2258.444824 
	C1645.786621,2257.905518 1647.307495,2259.049561 1647.494141,2260.502441 
	C1648.400024,2267.549561 1654.696655,2272.111328 1655.861328,2279.797363 
	C1652.287109,2283.507812 1649.586548,2280.600586 1646.838379,2279.123047 
	C1645.455444,2278.379639 1644.642090,2275.930420 1643.358887,2275.747803 
	C1631.681885,2274.089355 1623.471558,2264.074707 1611.626587,2262.823486 
	C1608.265381,2262.468262 1605.962158,2264.219482 1602.662964,2265.703613 
z"
        />
        <path
          fill="#8C5B43"
          opacity="1.000000"
          stroke="none"
          d="
M1577.323975,2275.598389 
	C1579.230103,2277.243408 1580.557495,2278.834473 1582.065918,2281.012207 
	C1575.112183,2285.945068 1574.064819,2292.969727 1573.986816,2301.231934 
	C1571.171631,2308.922363 1567.533569,2315.441406 1565.189331,2322.532227 
	C1563.842285,2326.606445 1562.407715,2330.733398 1557.103516,2332.359375 
	C1552.977173,2326.786377 1556.031616,2321.493408 1557.302979,2316.302490 
	C1558.485596,2311.473877 1560.562134,2306.892334 1556.569824,2302.083984 
	C1555.993896,2301.123779 1556.067993,2300.268311 1556.278076,2298.782715 
	C1564.310303,2294.679199 1562.583862,2287.002686 1563.827515,2280.052734 
	C1563.284546,2278.033447 1563.156982,2276.662842 1563.733887,2274.666260 
	C1565.550781,2273.964355 1567.034546,2273.868408 1569.261597,2273.755859 
	C1571.168457,2273.925293 1572.290039,2274.242676 1573.890381,2274.986084 
	C1575.212891,2275.294434 1575.979126,2275.419678 1577.323975,2275.598389 
z"
        />
        <path
          fill="#765242"
          opacity="1.000000"
          stroke="none"
          d="
M1538.026733,2331.229004 
	C1537.955566,2332.744873 1538.169678,2333.621582 1537.826172,2334.034668 
	C1529.973145,2343.488281 1528.517334,2355.365479 1525.396118,2366.609375 
	C1524.486206,2369.887207 1521.872070,2372.237305 1520.551880,2375.187744 
	C1518.765869,2379.180176 1517.232056,2371.252197 1516.122803,2375.325195 
	C1515.615112,2377.189453 1516.233765,2379.313232 1514.537476,2381.524902 
	C1512.116943,2383.354004 1510.506592,2386.783447 1507.637085,2383.767822 
	C1504.812378,2380.799072 1504.429565,2374.741943 1506.896973,2374.097412 
	C1513.527466,2372.364990 1508.575684,2367.132812 1511.146240,2363.909180 
	C1511.538940,2356.689453 1518.147095,2353.327148 1519.647705,2346.598633 
	C1520.113770,2345.998779 1519.995850,2345.988281 1520.006592,2346.046387 
	C1520.401733,2345.609619 1520.829468,2345.155273 1521.592529,2344.375000 
	C1522.482300,2343.744141 1523.058105,2343.329834 1523.681519,2343.238281 
	C1530.062134,2342.300781 1530.064331,2342.315674 1528.762939,2334.531006 
	C1528.432373,2332.305420 1528.965210,2330.862549 1531.453125,2330.213623 
	C1534.141724,2328.391602 1536.151855,2328.327637 1538.026733,2331.229004 
z"
        />
        <path
          fill="#442F21"
          opacity="1.000000"
          stroke="none"
          d="
M1538.683350,2331.954834 
	C1536.922607,2330.347412 1535.203491,2330.103271 1532.714355,2330.081543 
	C1532.176270,2327.123291 1533.556641,2325.348389 1536.405884,2324.208740 
	C1546.923950,2320.001221 1546.883545,2319.917236 1545.957642,2308.510986 
	C1545.877930,2307.529785 1545.961670,2306.535400 1545.980713,2304.778320 
	C1546.342163,2304.014160 1546.692261,2304.018555 1547.568359,2304.035400 
	C1553.614868,2315.956299 1548.114624,2324.401367 1538.683350,2331.954834 
z"
        />
        <path
          fill="#704A3A"
          opacity="1.000000"
          stroke="none"
          d="
M1565.240967,2277.864014 
	C1567.333862,2280.692139 1568.851807,2283.482422 1566.448486,2286.300293 
	C1563.127319,2290.194580 1563.543579,2294.006104 1566.904419,2297.643799 
	C1563.499634,2299.983398 1560.579834,2297.189453 1557.082031,2298.135010 
	C1555.746216,2295.187988 1557.615723,2293.099365 1559.456299,2290.409912 
	C1563.024536,2286.484863 1560.020142,2280.735596 1565.240967,2277.864014 
z"
        />
        <path
          fill="#603A24"
          opacity="1.000000"
          stroke="none"
          d="
M1565.809570,2277.463135 
	C1562.795410,2281.026611 1564.377686,2286.167725 1560.525635,2289.583008 
	C1559.912231,2287.118164 1559.989136,2284.273926 1558.861084,2281.478516 
	C1557.371460,2277.787354 1558.814819,2275.290039 1563.329346,2274.089844 
	C1564.587646,2275.022217 1565.108154,2275.983887 1565.809570,2277.463135 
z"
        />
        <path
          fill="#040407"
          opacity="1.000000"
          stroke="none"
          d="
M1657.389282,2219.562256 
	C1659.250122,2220.208008 1661.064575,2220.606201 1660.836426,2221.817627 
	C1660.265747,2224.848877 1657.460815,2223.649414 1654.799805,2223.984863 
	C1654.092041,2223.098145 1654.072754,2222.211426 1654.037109,2220.659912 
	C1654.020752,2219.994873 1653.995605,2220.000977 1653.989746,2220.010742 
	C1654.765137,2219.271240 1655.691772,2218.972656 1657.389282,2219.562256 
z"
        />
        <path
          fill="#603A24"
          opacity="1.000000"
          stroke="none"
          d="
M1574.229736,2274.699219 
	C1573.153687,2275.674316 1571.904663,2275.699219 1570.371338,2274.284180 
	C1571.583008,2271.920654 1572.879761,2272.404053 1574.229736,2274.699219 
z"
        />
        <path
          fill="#040407"
          opacity="1.000000"
          stroke="none"
          d="
M1585.098389,2240.957031 
	C1585.150146,2241.047119 1585.046631,2240.866943 1585.098389,2240.957031 
z"
        />
        <path
          fill="#442F21"
          opacity="1.000000"
          stroke="none"
          d="
M1546.005127,2303.995117 
	C1545.789917,2303.744629 1545.569580,2303.498779 1545.349243,2303.252930 
	C1545.496826,2303.342285 1545.644287,2303.431885 1545.891846,2303.765625 
	C1545.991943,2304.009766 1546.000000,2304.000000 1546.005127,2303.995117 
z"
        />
        <path
          fill="#2E2D30"
          opacity="1.000000"
          stroke="none"
          d="
M1582.128052,2168.519775 
	C1582.589966,2167.120850 1583.461060,2166.191650 1584.737061,2167.065674 
	C1585.583862,2167.645996 1585.377808,2168.598145 1584.370361,2169.724365 
	C1583.339966,2169.813477 1582.820190,2169.435547 1582.128052,2168.519775 
z"
        />
        <path
          fill="#2E2D30"
          opacity="1.000000"
          stroke="none"
          d="
M1585.643555,2156.152832 
	C1586.736572,2156.306152 1588.039551,2156.951660 1588.005493,2157.031982 
	C1587.451538,2158.342285 1586.205444,2157.802734 1584.644409,2157.889160 
	C1584.327271,2157.245850 1584.704224,2156.756104 1585.643555,2156.152832 
z"
        />
        <path
          fill="#131416"
          opacity="1.000000"
          stroke="none"
          d="
M1574.959717,2156.913574 
	C1575.044556,2156.868164 1574.875000,2156.958984 1574.959717,2156.913574 
z"
        />
        <path
          fill="#040407"
          opacity="1.000000"
          stroke="none"
          d="
M1596.677246,2154.674805 
	C1594.263428,2152.059082 1590.734009,2155.430908 1588.879395,2151.607422 
	C1593.770996,2148.653076 1598.884521,2150.158203 1603.853516,2150.763184 
	C1604.448608,2150.835693 1605.273560,2152.215820 1605.283569,2152.998779 
	C1605.293579,2153.785889 1604.601074,2154.979736 1603.897827,2155.303711 
	C1601.714844,2156.309082 1599.408325,2156.223877 1596.677246,2154.674805 
z"
        />
        <path
          fill="#281C18"
          opacity="1.000000"
          stroke="none"
          d="
M1657.709229,2219.572998 
	C1657.119263,2220.013184 1656.223511,2220.026367 1654.655762,2220.030029 
	C1654.857666,2218.688477 1655.917358,2218.083252 1657.709229,2219.572998 
z"
        />
        <path
          fill="#CC855B"
          opacity="1.000000"
          stroke="none"
          d="
M1662.053467,2367.188965 
	C1661.543335,2367.910889 1661.202759,2367.964600 1660.354736,2368.119141 
	C1657.763428,2370.695557 1655.839478,2373.334229 1652.419434,2375.436035 
	C1651.893799,2375.889648 1652.000000,2376.000000 1651.946777,2375.946777 
	C1650.940308,2377.088623 1649.820312,2378.118408 1648.210083,2379.448242 
	C1647.732178,2379.760254 1646.989868,2380.027832 1646.635864,2380.202148 
	C1644.976318,2381.176758 1643.601440,2381.729736 1641.325684,2381.349609 
	C1638.256836,2368.634277 1625.996704,2365.875977 1618.123291,2359.004883 
	C1611.478394,2353.205811 1610.386597,2347.787842 1612.707886,2339.879883 
	C1616.056519,2328.472656 1615.657349,2328.377197 1604.314209,2328.390137 
	C1597.799561,2328.397461 1592.013672,2325.833008 1585.795410,2322.577637 
	C1584.961426,2321.103760 1584.911499,2320.148926 1585.549316,2318.583496 
	C1589.343994,2311.672852 1589.877930,2304.086426 1595.283813,2298.590332 
	C1596.996582,2296.849121 1595.266357,2294.208984 1592.604736,2292.328613 
	C1591.976929,2288.633057 1595.055908,2287.035400 1596.425293,2284.426514 
	C1598.513794,2280.447266 1602.247314,2279.190918 1606.560059,2281.322998 
	C1610.208618,2283.126709 1612.995483,2283.447510 1617.416992,2281.376221 
	C1624.694336,2277.967041 1633.077759,2279.200928 1640.523193,2282.683350 
	C1644.936768,2284.747070 1645.709473,2293.865234 1641.865845,2298.627930 
	C1635.603638,2306.387939 1634.850464,2315.902100 1632.824707,2325.028564 
	C1632.311890,2327.338867 1633.820312,2328.835449 1635.681152,2329.741943 
	C1641.764160,2332.705322 1642.788696,2336.910156 1636.946899,2343.844971 
	C1649.967041,2339.501709 1661.495728,2341.511475 1672.394165,2338.349121 
	C1673.013550,2338.169189 1673.876587,2337.984375 1674.348633,2338.266602 
	C1682.449341,2343.106445 1691.770264,2344.071777 1700.577026,2346.665283 
	C1702.267822,2347.163330 1703.436768,2348.590820 1703.139404,2350.437988 
	C1702.858032,2352.185303 1701.213989,2354.045898 1699.793091,2353.452148 
	C1693.204834,2350.698975 1689.179688,2359.438477 1682.826782,2356.928711 
	C1679.114746,2361.006836 1674.765381,2361.324951 1669.446167,2360.473145 
	C1666.220215,2359.956787 1664.005005,2363.680908 1662.053467,2367.188965 
z"
        />
        <path
          fill="#BA815C"
          opacity="1.000000"
          stroke="none"
          d="
M1585.167480,2317.989502 
	C1585.993652,2318.885742 1586.001953,2319.781006 1586.016113,2321.348145 
	C1586.442993,2322.901367 1586.385132,2323.764893 1585.485840,2325.151611 
	C1584.369141,2326.308105 1583.638794,2326.861084 1582.286865,2327.709961 
	C1575.716919,2331.870117 1570.183960,2336.169434 1566.032471,2342.917969 
	C1552.911987,2356.391357 1545.365967,2373.089600 1533.480103,2387.359863 
	C1532.027100,2388.396729 1531.180786,2389.123779 1531.242676,2391.114258 
	C1525.489380,2401.620117 1528.285034,2413.591064 1522.661865,2424.178223 
	C1519.314453,2425.960205 1516.674316,2425.096924 1513.393677,2423.639404 
	C1512.098755,2422.113770 1511.638306,2421.072510 1511.214600,2419.104492 
	C1508.838745,2409.128662 1509.533447,2400.225098 1513.911743,2391.132324 
	C1521.647705,2383.752197 1531.644897,2378.670654 1529.595581,2366.132324 
	C1529.228638,2363.886475 1530.304688,2362.296387 1533.136230,2363.288086 
	C1537.450684,2364.798828 1540.269043,2362.035889 1543.001343,2359.476807 
	C1544.183838,2358.368896 1544.849609,2356.729736 1545.869507,2355.425293 
	C1553.307129,2345.911377 1553.373901,2345.798828 1556.330322,2333.013184 
	C1559.970581,2329.966797 1562.061401,2326.124512 1562.474731,2322.459961 
	C1563.396729,2314.285889 1569.577393,2309.149170 1573.317383,2302.170654 
	C1571.855957,2307.160645 1576.119995,2311.038574 1576.824097,2315.649902 
	C1577.360718,2319.165771 1581.638062,2317.617432 1585.167480,2317.989502 
z"
        />
        <path
          fill="#CC855B"
          opacity="1.000000"
          stroke="none"
          d="
M1592.004883,2292.004883 
	C1591.417725,2297.535889 1586.961182,2299.799561 1583.331909,2303.029785 
	C1579.886475,2296.152344 1579.903076,2296.208008 1586.487549,2294.245850 
	C1587.993164,2293.797363 1589.341675,2292.821289 1591.375854,2292.039307 
	C1591.989136,2291.990234 1592.000000,2292.000000 1592.004883,2292.004883 
z"
        />
        <path
          fill="#9E6A4C"
          opacity="1.000000"
          stroke="none"
          d="
M1649.630249,2399.204102 
	C1652.336304,2399.395020 1654.358643,2399.530029 1657.142090,2399.680176 
	C1662.641357,2399.927490 1663.980225,2403.192139 1664.586670,2407.915039 
	C1664.566772,2411.825195 1663.313965,2414.515869 1661.078735,2417.588135 
	C1659.802490,2419.347656 1658.834473,2420.419922 1657.372559,2422.018311 
	C1656.454956,2422.794922 1656.050903,2423.061523 1654.967773,2423.589844 
	C1652.343750,2424.322998 1650.448364,2424.136719 1647.975586,2423.063721 
	C1643.898315,2417.268311 1640.811768,2415.136475 1634.427124,2420.354004 
	C1627.927612,2425.665283 1620.123047,2429.390869 1614.363770,2436.807129 
	C1611.975952,2438.190918 1610.074707,2438.321045 1607.437866,2437.578613 
	C1605.927368,2436.740234 1605.038452,2436.013428 1604.942383,2435.068848 
	C1604.498047,2430.698730 1597.073364,2429.089600 1599.606201,2424.311523 
	C1602.603882,2418.656006 1606.611938,2413.264404 1613.439209,2411.198730 
	C1615.265259,2410.646729 1617.446655,2410.814209 1619.138306,2408.428223 
	C1621.700928,2404.350586 1625.279419,2404.148193 1628.903442,2403.501221 
	C1635.627808,2402.300049 1642.725708,2402.680664 1649.630249,2399.204102 
z"
        />
        <path
          fill="#765242"
          opacity="1.000000"
          stroke="none"
          d="
M1649.745605,2398.830078 
	C1648.741943,2401.419922 1648.330078,2404.320312 1644.332764,2404.364746 
	C1638.224487,2404.432373 1632.374634,2407.595459 1626.012451,2406.230225 
	C1624.319214,2405.866943 1622.894409,2406.783447 1622.139160,2409.262695 
	C1617.329346,2411.369873 1612.737549,2411.702881 1607.908936,2408.364258 
	C1605.955811,2404.543701 1607.672363,2402.215820 1610.300049,2399.533691 
	C1616.616821,2397.194336 1621.242065,2393.112549 1627.492554,2390.807129 
	C1628.633179,2390.954346 1629.073120,2391.111084 1629.940430,2391.822754 
	C1633.039307,2394.982178 1636.276367,2396.103271 1639.965210,2396.014404 
	C1643.264893,2395.935059 1646.290039,2396.896484 1649.745605,2398.830078 
z"
        />
        <path
          fill="#765242"
          opacity="1.000000"
          stroke="none"
          d="
M1663.133423,2411.900879 
	C1661.799194,2408.021973 1662.883423,2403.663818 1658.395508,2400.366211 
	C1660.915649,2390.547607 1666.185059,2389.585938 1673.911133,2397.526367 
	C1674.270996,2398.580078 1674.567627,2398.892578 1674.994873,2399.834229 
	C1675.048584,2400.881104 1674.971436,2401.298584 1674.564941,2402.256348 
	C1673.367432,2403.681885 1672.351807,2404.366699 1670.845459,2405.360840 
	C1670.115479,2405.987793 1669.817871,2406.226074 1669.041504,2406.750488 
	C1667.892334,2407.165283 1667.655273,2407.361572 1667.836548,2407.635986 
	C1668.033691,2407.934570 1668.218750,2408.088623 1668.465210,2408.660156 
	C1668.238892,2409.975342 1667.775635,2410.590332 1666.638916,2411.389160 
	C1665.333374,2411.924805 1664.539551,2412.052979 1663.133423,2411.900879 
z"
        />
        <path
          fill="#765242"
          opacity="1.000000"
          stroke="none"
          d="
M1639.764160,2379.829102 
	C1641.700439,2380.300781 1643.229004,2380.247559 1645.519775,2380.285156 
	C1642.974609,2386.143066 1640.322144,2392.709229 1631.115723,2392.282715 
	C1630.367676,2392.377686 1630.084473,2392.104004 1629.936523,2391.973877 
	C1628.654907,2391.086914 1627.479492,2390.365479 1627.371094,2388.162354 
	C1628.123413,2386.240723 1629.094238,2385.295654 1630.781128,2384.124268 
	C1634.368286,2382.905762 1636.038330,2379.625000 1639.764160,2379.829102 
z"
        />
        <path
          fill="#765242"
          opacity="1.000000"
          stroke="none"
          d="
M1652.633301,2375.947754 
	C1652.452515,2371.915283 1654.709473,2369.418945 1659.184082,2368.247803 
	C1659.906982,2369.640625 1659.966553,2371.061035 1660.021973,2373.193848 
	C1658.326660,2376.264404 1655.791992,2375.952637 1652.633301,2375.947754 
z"
        />
        <path
          fill="#5A605E"
          opacity="1.000000"
          stroke="none"
          d="
M1674.755371,2402.507324 
	C1674.058105,2401.265381 1674.036133,2400.405518 1673.994263,2398.906738 
	C1675.432495,2398.131348 1676.890625,2397.994873 1679.072021,2397.808350 
	C1679.795532,2397.758057 1680.026611,2398.046631 1680.140625,2398.192383 
	C1680.318604,2401.626953 1678.554565,2402.978760 1674.755371,2402.507324 
z"
        />
        <path
          fill="#765242"
          opacity="1.000000"
          stroke="none"
          d="
M1648.404541,2379.787109 
	C1648.048096,2378.006836 1648.925293,2376.695801 1651.273438,2375.974609 
	C1651.884155,2377.868164 1651.212524,2379.366699 1648.404541,2379.787109 
z"
        />
        <path
          fill="#040407"
          opacity="1.000000"
          stroke="none"
          d="
M1729.176270,2391.896484 
	C1736.936035,2394.625488 1743.653687,2397.666016 1747.708252,2405.226074 
	C1742.054199,2407.221191 1736.260254,2408.471680 1730.524780,2409.949219 
	C1724.347046,2411.540527 1721.026367,2415.711182 1720.443848,2421.756104 
	C1720.158691,2424.716064 1721.926758,2425.928467 1724.093384,2426.943848 
	C1727.393311,2428.490479 1730.643311,2428.439697 1733.192139,2425.617432 
	C1735.613525,2422.936279 1737.619507,2419.884033 1739.959351,2417.123535 
	C1740.986816,2415.911377 1742.290771,2414.548340 1744.140015,2415.156738 
	C1746.134155,2415.812988 1745.905884,2417.750732 1745.938477,2419.340820 
	C1746.026855,2423.642578 1745.995239,2427.947021 1746.044922,2433.151367 
	C1742.239624,2440.295654 1738.400879,2446.538818 1734.294189,2453.414062 
	C1728.724487,2456.194580 1728.127686,2461.519043 1726.096558,2465.854980 
	C1721.658813,2475.328613 1718.400146,2485.137207 1717.043213,2495.589111 
	C1716.726074,2498.031738 1717.257812,2500.066650 1717.833130,2502.976074 
	C1717.559448,2504.259277 1717.145752,2504.791016 1716.310547,2505.624512 
	C1715.928467,2505.956543 1715.988770,2505.920898 1715.953125,2505.912598 
	C1706.575928,2518.279053 1693.643188,2518.156250 1679.383423,2515.820801 
	C1678.138672,2515.106201 1677.686523,2514.744873 1676.759766,2513.656738 
	C1675.571411,2511.337158 1675.279785,2509.655273 1675.277588,2507.089600 
	C1675.414429,2505.179443 1675.636963,2504.130615 1676.331421,2502.426025 
	C1684.989868,2497.319824 1688.528809,2491.348145 1683.647949,2482.069336 
	C1682.474121,2479.837646 1682.733276,2476.829346 1683.204224,2474.256104 
	C1684.386230,2467.797852 1682.614746,2463.514648 1675.840210,2461.482422 
	C1670.753418,2459.956299 1669.896729,2455.080811 1668.443726,2450.606201 
	C1666.939941,2445.974854 1666.598145,2439.577881 1658.190430,2440.565430 
	C1655.997192,2437.768311 1656.674683,2435.400879 1658.163208,2432.420166 
	C1661.506592,2428.514160 1665.617920,2427.090820 1669.359009,2425.076904 
	C1673.530273,2422.830811 1678.991333,2421.720459 1675.410034,2414.542969 
	C1674.019043,2411.755371 1677.503662,2409.067871 1679.826416,2409.249512 
	C1689.840210,2410.032227 1691.064331,2402.759277 1693.439087,2395.386230 
	C1694.916748,2394.572510 1695.967896,2394.306152 1697.557129,2393.967529 
	C1701.487061,2396.109619 1700.640503,2402.908447 1704.685059,2402.716064 
	C1709.541260,2402.484863 1707.868164,2395.593262 1711.142334,2392.073486 
	C1717.117920,2389.331543 1722.758179,2390.414551 1729.176270,2391.896484 
z"
        />
        <path
          fill="#1A1615"
          opacity="1.000000"
          stroke="none"
          d="
M1693.294189,2394.753662 
	C1694.109131,2401.026855 1694.673828,2407.275391 1690.138184,2413.382324 
	C1685.863037,2408.441650 1681.563354,2410.913086 1677.330811,2412.931152 
	C1676.989014,2415.094482 1678.197632,2415.646240 1679.503662,2415.895020 
	C1684.339600,2416.816650 1684.012207,2419.459717 1680.932251,2421.737061 
	C1674.847290,2426.236328 1667.981689,2429.412842 1660.034790,2431.865723 
	C1654.196655,2423.204834 1661.566284,2417.661133 1665.174683,2410.504639 
	C1666.458984,2409.703613 1667.094604,2409.404541 1668.015869,2408.644775 
	C1667.961670,2407.857666 1667.621948,2407.531006 1667.782349,2406.705078 
	C1668.282349,2406.205811 1667.993652,2406.054443 1668.135986,2406.140137 
	C1669.320557,2404.271729 1671.353271,2403.698975 1673.614990,2402.402832 
	C1674.109619,2402.050049 1674.033936,2402.047607 1674.057129,2402.086426 
	C1676.286743,2401.963135 1677.933960,2400.711670 1679.885742,2398.835938 
	C1684.410645,2397.114746 1688.566650,2395.891602 1693.294189,2394.753662 
z"
        />
        <path
          fill="#2C2520"
          opacity="1.000000"
          stroke="none"
          d="
M1710.654907,2391.504150 
	C1712.913574,2395.896973 1710.593750,2399.852539 1709.860229,2404.015625 
	C1709.361694,2406.844971 1707.633667,2407.803467 1705.007690,2407.848877 
	C1702.356567,2407.895020 1700.766479,2406.771729 1699.888550,2404.179932 
	C1698.929443,2401.349121 1697.890503,2398.585205 1698.102051,2394.713867 
	C1701.751709,2391.700928 1705.947388,2392.221680 1710.654907,2391.504150 
z"
        />
        <path
          fill="#070709"
          opacity="1.000000"
          stroke="none"
          d="
M1540.019531,2159.457764 
	C1539.922729,2158.265869 1540.107788,2158.260742 1540.019531,2159.457764 
z"
        />
        <path
          fill="#070709"
          opacity="1.000000"
          stroke="none"
          d="
M1501.047852,2163.066406 
	C1500.959106,2163.114014 1501.136475,2163.018799 1501.047852,2163.066406 
z"
        />
        <path
          fill="#1A1615"
          opacity="1.000000"
          stroke="none"
          d="
M1394.875610,2265.923340 
	C1395.622681,2264.570068 1396.376221,2264.533447 1397.043457,2265.514160 
	C1397.182129,2265.717773 1397.173706,2266.189209 1397.028564,2266.389160 
	C1396.337524,2267.341797 1395.586182,2267.309326 1394.875610,2265.923340 
z"
        />
        <path
          fill="#1A1615"
          opacity="1.000000"
          stroke="none"
          d="
M1455.511963,2239.689453 
	C1455.639038,2240.683594 1455.269043,2241.344482 1454.199463,2240.312012 
	C1454.365112,2239.779785 1454.694580,2239.579346 1455.511963,2239.689453 
z"
        />
        <path
          fill="#070709"
          opacity="1.000000"
          stroke="none"
          d="
M1455.826782,2239.683350 
	C1456.000000,2240.000000 1455.017456,2239.977783 1454.526489,2239.979004 
	C1454.415039,2239.354492 1454.774658,2238.676270 1455.826782,2239.683350 
z"
        />
        <path
          fill="#564031"
          opacity="1.000000"
          stroke="none"
          d="
M1514.484863,2389.843750 
	C1514.454956,2399.352783 1511.858154,2407.919922 1513.720581,2417.397461 
	C1511.941895,2419.719971 1510.558350,2421.559570 1507.859375,2422.826660 
	C1506.265137,2422.978271 1505.348022,2422.917236 1503.833984,2422.435547 
	C1499.072021,2416.103027 1502.657715,2409.076416 1500.751221,2401.884277 
	C1495.810181,2404.629639 1497.822266,2410.170898 1494.565308,2413.613281 
	C1493.749023,2414.100098 1493.410400,2414.084473 1492.569092,2413.992432 
	C1488.040283,2405.779053 1483.739014,2397.704590 1486.987671,2387.910156 
	C1486.009277,2386.771240 1484.922974,2388.827393 1486.072876,2388.152588 
	C1488.286621,2393.700195 1485.106323,2398.120361 1482.450317,2403.264648 
	C1481.882080,2403.760254 1481.870728,2403.837402 1481.835693,2403.854248 
	C1481.109741,2404.693848 1480.229858,2405.050537 1478.501953,2404.583008 
	C1477.842896,2404.223145 1476.915527,2404.151611 1476.458252,2404.065918 
	C1475.906738,2402.388672 1476.034546,2399.458252 1475.049561,2399.566895 
	C1470.457153,2400.072510 1470.188965,2394.137207 1465.795410,2394.186768 
	C1457.522095,2394.280518 1457.518799,2393.990967 1461.783447,2384.634033 
	C1462.226929,2383.069336 1463.774658,2382.450439 1462.811157,2381.317871 
	C1462.585693,2381.052979 1461.751221,2381.423828 1460.619873,2381.857422 
	C1451.889526,2379.460449 1451.204956,2379.650391 1447.795532,2385.254150 
	C1447.134521,2386.340820 1445.831299,2387.881104 1447.225586,2388.548584 
	C1449.514526,2389.645020 1450.807251,2391.027344 1448.668335,2392.696289 
	C1446.565430,2394.336914 1445.336670,2391.847656 1444.186401,2390.326660 
	C1443.789917,2389.802490 1443.405273,2389.269287 1442.516479,2388.376465 
	C1442.728516,2382.199707 1448.114258,2380.073242 1451.055054,2376.136230 
	C1454.588501,2371.406006 1457.898193,2369.204346 1463.592041,2370.019043 
	C1464.140869,2370.097900 1465.000366,2368.005615 1465.952148,2366.387207 
	C1465.051880,2364.892334 1465.494019,2363.784424 1466.176758,2362.165039 
	C1466.694336,2361.336426 1466.928467,2361.022949 1468.009033,2359.901855 
	C1469.184692,2358.884033 1469.513794,2358.674316 1470.976074,2358.264893 
	C1472.792603,2358.777100 1473.534546,2359.442383 1474.146240,2360.211182 
	C1475.333740,2361.704346 1476.903442,2362.301758 1478.409668,2361.186035 
	C1480.283203,2359.798096 1478.818848,2358.333984 1478.008789,2356.943115 
	C1477.355469,2355.821289 1476.838867,2354.619629 1476.206665,2352.784424 
	C1476.798340,2351.970459 1477.592041,2352.017578 1478.068848,2351.651855 
	C1484.485840,2346.729736 1485.386353,2346.372314 1493.492310,2346.181641 
	C1497.132446,2350.150635 1496.234741,2355.403564 1492.986206,2356.687012 
	C1485.136108,2359.789307 1485.201050,2365.890137 1484.975586,2371.579346 
	C1484.118286,2366.561523 1488.293213,2366.287842 1492.161377,2365.634521 
	C1495.936646,2364.996826 1497.794312,2366.589355 1497.689941,2371.053223 
	C1497.190674,2372.486572 1496.462646,2373.055664 1496.086792,2373.770752 
	C1495.474854,2374.934326 1495.897461,2373.222900 1496.585815,2374.364502 
	C1496.744385,2374.627686 1496.698120,2374.328857 1496.665771,2373.098633 
	C1498.595093,2366.026611 1497.090332,2358.311035 1504.862061,2353.594971 
	C1513.610352,2352.482666 1509.008789,2360.503662 1512.853027,2363.269775 
	C1512.770996,2367.584229 1512.337769,2371.734863 1511.346436,2375.747803 
	C1510.948364,2377.359375 1508.734131,2370.790771 1508.031738,2376.009033 
	C1507.817871,2377.597900 1507.860962,2379.321777 1508.243164,2380.871094 
	C1509.296021,2385.139648 1511.443481,2382.048584 1513.630615,2381.719727 
	C1514.141113,2384.305664 1514.225586,2386.640625 1514.484863,2389.843750 
z"
        />
        <path
          fill="#442F21"
          opacity="1.000000"
          stroke="none"
          d="
M1468.345215,2362.011963 
	C1467.603394,2363.310547 1467.099121,2364.145508 1466.391846,2365.416992 
	C1460.631226,2365.838135 1455.102417,2368.353271 1448.759277,2366.021484 
	C1451.005371,2363.324219 1454.396240,2361.506348 1457.124634,2357.968750 
	C1460.503418,2353.588135 1467.083862,2351.676758 1472.368652,2348.648682 
	C1474.168701,2351.792725 1471.445068,2354.108398 1472.074951,2357.354980 
	C1472.109497,2358.064941 1472.056885,2358.028320 1472.040527,2358.045166 
	C1471.679810,2358.372314 1471.335449,2358.682861 1470.525391,2359.510254 
	C1470.059937,2360.027100 1470.012085,2360.009766 1470.019287,2360.033691 
	C1469.487549,2360.498779 1469.006104,2360.995605 1468.345215,2362.011963 
z"
        />
        <path
          fill="#442F21"
          opacity="1.000000"
          stroke="none"
          d="
M1460.255127,2382.485596 
	C1460.298706,2380.270264 1459.450317,2377.215576 1461.797852,2377.282227 
	C1463.790527,2377.338867 1467.040039,2378.413330 1467.490234,2381.067871 
	C1467.917603,2383.587891 1465.347778,2383.779541 1462.732910,2383.997314 
	C1461.438599,2383.797607 1460.912354,2383.429688 1460.255127,2382.485596 
z"
        />
        <path
          fill="#442F21"
          opacity="1.000000"
          stroke="none"
          d="
M1447.285645,2366.015137 
	C1444.607788,2370.874512 1444.607788,2370.874512 1437.634033,2371.348389 
	C1440.105713,2368.185791 1442.676758,2366.054199 1447.285645,2366.015137 
z"
        />
        <path
          fill="#564031"
          opacity="1.000000"
          stroke="none"
          d="
M1441.990479,2387.990234 
	C1441.921997,2388.940430 1441.988647,2389.929443 1441.775391,2390.854004 
	C1441.383545,2392.552979 1440.429565,2393.906494 1438.462524,2393.436279 
	C1436.021240,2392.853027 1435.618530,2390.603027 1436.293091,2388.867920 
	C1437.245239,2386.418945 1439.562744,2390.147705 1441.514404,2388.179443 
	C1442.017700,2388.012451 1441.999146,2388.000000 1441.990479,2387.990234 
z"
        />
        <path
          fill="#0C0B0A"
          opacity="1.000000"
          stroke="none"
          d="
M1426.937012,2386.902588 
	C1427.038452,2386.843750 1426.835571,2386.961426 1426.937012,2386.902588 
z"
        />
        <path
          fill="#1A1615"
          opacity="1.000000"
          stroke="none"
          d="
M1513.674072,2362.828125 
	C1511.857788,2364.463379 1510.287354,2365.437988 1508.798340,2366.362061 
	C1506.161011,2362.638916 1512.319702,2357.911377 1506.390381,2354.544922 
	C1500.179932,2355.123535 1497.339111,2351.887451 1495.757812,2346.576172 
	C1496.132935,2345.112305 1496.190063,2344.337402 1496.336060,2342.997314 
	C1498.692017,2342.272461 1501.096069,2341.582764 1503.074219,2343.166016 
	C1504.730957,2344.491943 1505.914307,2345.195312 1508.163696,2343.802979 
	C1513.806641,2340.310303 1513.847534,2344.527832 1513.914917,2349.161377 
	C1513.922607,2349.916260 1513.854004,2350.437988 1513.959961,2350.253418 
	C1516.487183,2350.077393 1517.784912,2348.402344 1519.596680,2346.360840 
	C1521.437988,2352.686523 1516.299683,2356.898438 1513.674072,2362.828125 
z"
        />
        <path
          fill="#281C18"
          opacity="1.000000"
          stroke="none"
          d="
M1523.997559,2328.000000 
	C1523.166870,2331.139404 1522.338745,2334.278809 1521.510620,2337.418213 
	C1521.890137,2334.281738 1514.486938,2330.224609 1523.300537,2328.006348 
	C1524.004883,2328.000000 1524.000000,2328.000000 1523.997559,2328.000000 
z"
        />
        <path
          fill="#1A1615"
          opacity="1.000000"
          stroke="none"
          d="
M1500.140869,2312.625488 
	C1500.987061,2312.052490 1501.853760,2311.989990 1503.372803,2311.955566 
	C1502.059570,2315.213623 1501.777222,2315.297852 1500.140869,2312.625488 
z"
        />
        <path
          fill="#1A1615"
          opacity="1.000000"
          stroke="none"
          d="
M1521.467407,2344.251953 
	C1521.587524,2344.610352 1521.290527,2345.212158 1520.505493,2345.959473 
	C1520.203613,2345.466797 1520.547974,2344.930176 1521.467407,2344.251953 
z"
        />
        <path
          fill="#1A1615"
          opacity="1.000000"
          stroke="none"
          d="
M1391.827148,2298.460449 
	C1393.165161,2298.776367 1393.968140,2299.675537 1392.860474,2301.061523 
	C1392.701294,2301.260986 1392.203735,2301.409424 1391.989868,2301.309326 
	C1390.707764,2300.711182 1391.380493,2299.776855 1391.827148,2298.460449 
z"
        />
        <path
          fill="#1A1615"
          opacity="1.000000"
          stroke="none"
          d="
M1395.508301,2307.178223 
	C1395.620605,2306.800049 1396.026245,2306.489746 1396.140259,2306.573975 
	C1396.843018,2307.093994 1396.656006,2307.418213 1395.508301,2307.178223 
z"
        />
        <path
          fill="#1A1615"
          opacity="1.000000"
          stroke="none"
          d="
M1390.967041,2304.918457 
	C1391.059082,2304.867676 1390.874878,2304.969238 1390.967041,2304.918457 
z"
        />
        <path
          fill="#1A1615"
          opacity="1.000000"
          stroke="none"
          d="
M1431.992676,2333.990234 
	C1432.214478,2334.077393 1431.983521,2332.872314 1432.262451,2333.904297 
	C1432.019531,2334.012207 1432.000000,2334.000000 1431.992676,2333.990234 
z"
        />
        <path
          fill="#6C5246"
          opacity="1.000000"
          stroke="none"
          d="
M1495.600830,2345.975098 
	C1498.973999,2348.415527 1501.871948,2350.944092 1505.276733,2353.883301 
	C1502.432861,2358.900635 1499.323730,2363.573975 1499.861816,2369.708984 
	C1500.015747,2371.463379 1499.194946,2372.913574 1496.723389,2373.827637 
	C1495.995117,2372.061523 1495.995117,2370.122803 1495.995117,2368.072998 
	C1486.733276,2370.039551 1486.733276,2370.039551 1484.865601,2376.976562 
	C1483.762817,2370.792480 1482.662354,2365.975098 1482.134277,2361.095703 
	C1481.865723,2358.613037 1482.313965,2354.367188 1485.662354,2355.495605 
	C1494.336548,2358.418701 1492.443359,2351.446777 1494.103271,2346.926025 
	C1494.172852,2346.225586 1495.125610,2346.063232 1495.600830,2345.975098 
z"
        />
        <path
          fill="#D3CBBF"
          opacity="1.000000"
          stroke="none"
          d="
M1977.303955,2897.481934 
	C1977.473755,2902.785889 1975.082031,2906.507812 1970.654297,2909.702148 
	C1967.926636,2910.946045 1965.868652,2912.132324 1962.715332,2911.999023 
	C1962.014648,2912.009766 1962.000000,2912.000000 1961.997437,2911.995117 
	C1961.830688,2905.265381 1969.445312,2903.520752 1971.615723,2897.443604 
	C1973.710938,2896.382568 1975.197266,2896.382080 1977.303955,2897.481934 
z"
        />
        <path
          fill="#C4BDAE"
          opacity="1.000000"
          stroke="none"
          d="
M1894.870972,3068.335938 
	C1893.347046,3063.681641 1895.615601,3061.770020 1899.158813,3060.829346 
	C1900.162720,3060.562744 1901.322266,3061.261230 1901.372070,3062.173096 
	C1901.600098,3066.346924 1899.638550,3068.740723 1894.870972,3068.335938 
z"
        />
        <path
          fill="#D3CBBF"
          opacity="1.000000"
          stroke="none"
          d="
M1931.649170,3011.289062 
	C1929.647949,3013.730225 1928.594116,3012.680664 1928.307129,3010.772705 
	C1928.082031,3009.275879 1927.989136,3007.086670 1930.426147,3007.421875 
	C1931.809326,3007.612305 1931.826416,3009.358643 1931.649170,3011.289062 
z"
        />
        <path
          fill="#D3CBBF"
          opacity="1.000000"
          stroke="none"
          d="
M1908.572021,3055.034668 
	C1908.913574,3057.762939 1907.474365,3057.851318 1905.833008,3057.250977 
	C1905.452881,3057.112061 1905.018311,3056.136230 1905.157837,3055.737793 
	C1905.666016,3054.286865 1906.883179,3054.246338 1908.572021,3055.034668 
z"
        />
        <path
          fill="#D3CBBF"
          opacity="1.000000"
          stroke="none"
          d="
M1935.141602,2994.716797 
	C1934.724731,2993.225098 1935.245850,2992.682617 1936.404785,2992.912598 
	C1936.645264,2992.960693 1936.968262,2993.302490 1937.004517,2993.545898 
	C1937.178223,2994.715576 1936.609863,2995.207031 1935.141602,2994.716797 
z"
        />
        <path
          fill="#D0C8B8"
          opacity="1.000000"
          stroke="none"
          d="
M1810.500732,3201.590820 
	C1805.595093,3195.220703 1805.632324,3195.270996 1811.818848,3190.679443 
	C1812.762817,3189.979004 1813.180054,3188.569092 1813.946167,3186.784668 
	C1816.976685,3186.029785 1819.709106,3183.243652 1823.392456,3185.723389 
	C1823.894165,3187.914795 1824.196899,3190.805420 1822.220459,3191.143799 
	C1816.178711,3192.178223 1814.150757,3197.173340 1810.500732,3201.590820 
z"
        />
        <path
          fill="#CBC6BA"
          opacity="1.000000"
          stroke="none"
          d="
M1956.645264,2967.863037 
	C1956.050781,2965.718262 1956.927979,2963.863037 1959.385254,2962.233398 
	C1959.883301,2964.224609 1960.284668,2966.736084 1956.645264,2967.863037 
z"
        />
        <path
          fill="#CBC6BA"
          opacity="1.000000"
          stroke="none"
          d="
M1977.424072,2970.066895 
	C1977.639282,2971.091309 1977.288208,2972.192383 1976.464355,2973.646729 
	C1973.974487,2972.196533 1974.458984,2970.954834 1977.424072,2970.066895 
z"
        />
        <path
          fill="#F6EEDE"
          opacity="1.000000"
          stroke="none"
          d="
M2137.231689,2843.956543 
	C2136.563965,2855.999023 2132.268311,2866.502686 2125.568848,2876.701416 
	C2121.953857,2882.204590 2119.587646,2889.120605 2116.259033,2895.136230 
	C2108.318359,2909.488037 2102.613525,2924.819824 2096.686768,2938.495117 
	C2095.366211,2940.437744 2094.560059,2940.579102 2094.237061,2939.794678 
	C2092.848145,2936.422363 2087.613281,2937.137939 2087.057129,2933.720459 
	C2086.523682,2930.442139 2089.876221,2927.806152 2090.569092,2924.612549 
	C2092.609375,2915.208252 2096.670410,2906.865479 2102.823242,2899.504150 
	C2103.029785,2899.257080 2103.265381,2898.919922 2103.261230,2898.627930 
	C2103.116943,2888.320801 2110.795166,2881.403320 2114.416016,2872.734375 
	C2115.681396,2869.705078 2116.466553,2866.475586 2117.796387,2862.669922 
	C2124.163574,2858.498779 2128.010498,2853.877197 2127.205566,2846.263916 
	C2126.633545,2840.851562 2128.185791,2839.912598 2133.058350,2842.066406 
	C2134.253174,2842.594238 2135.391113,2843.250244 2137.231689,2843.956543 
z"
        />
        <path
          fill="#F2EBDD"
          opacity="1.000000"
          stroke="none"
          d="
M2006.751221,2752.000000 
	C2005.991089,2748.038574 2005.739990,2743.999756 2009.406372,2741.175781 
	C2014.791138,2749.238037 2014.591919,2750.349854 2006.751221,2752.000000 
z"
        />
        <path
          fill="#F2EBDD"
          opacity="1.000000"
          stroke="none"
          d="
M1985.760498,2696.796631 
	C1986.927612,2697.062256 1987.985840,2698.206543 1989.521729,2699.696777 
	C1990.161255,2703.578613 1994.026855,2708.102539 1986.721924,2709.910889 
	C1985.920044,2705.889893 1985.785889,2701.782471 1985.760498,2696.796631 
z"
        />
        <path
          fill="#D7D5CF"
          opacity="1.000000"
          stroke="none"
          d="
M2031.524780,2747.437500 
	C2029.359497,2750.144775 2027.981079,2749.002930 2027.027344,2746.973877 
	C2026.598267,2746.061035 2027.059692,2744.941162 2028.066284,2744.630859 
	C2029.945557,2744.052002 2031.169312,2744.895020 2031.524780,2747.437500 
z"
        />
        <path
          fill="#F2EBDD"
          opacity="1.000000"
          stroke="none"
          d="
M1988.118652,2720.043701 
	C1987.184082,2718.697510 1986.995728,2717.202393 1986.796997,2714.955078 
	C1988.673218,2714.291260 1990.816284,2714.105469 1991.380737,2716.527100 
	C1991.821533,2718.418701 1990.499023,2719.360596 1988.118652,2720.043701 
z"
        />
        <path
          fill="#E7E3DA"
          opacity="1.000000"
          stroke="none"
          d="
M2175.931641,2845.164795 
	C2172.956543,2845.767090 2170.961914,2844.341797 2170.063477,2841.436279 
	C2169.555908,2839.793945 2168.790283,2838.166260 2166.595947,2839.213623 
	C2164.858643,2840.043213 2164.398682,2841.657471 2164.869629,2843.412842 
	C2166.574463,2849.764404 2163.125732,2855.727783 2163.482910,2862.000000 
	C2163.541748,2863.030273 2162.114014,2864.144775 2160.724121,2865.614746 
	C2156.919678,2867.646240 2153.999756,2867.099609 2150.635254,2864.556641 
	C2150.132080,2861.672119 2149.583496,2859.201660 2151.481445,2856.492676 
	C2152.550537,2855.016602 2155.030518,2854.590576 2152.476074,2852.423340 
	C2150.188232,2851.028564 2149.527100,2848.551514 2146.577637,2848.067383 
	C2147.395264,2843.754150 2146.764160,2840.255615 2144.348145,2836.146484 
	C2138.777344,2826.671875 2140.985596,2814.935791 2149.296143,2808.100098 
	C2149.995605,2811.417480 2149.971680,2814.843018 2149.950684,2819.124512 
	C2149.988037,2821.593018 2149.999023,2823.205811 2149.964600,2825.423584 
	C2149.950439,2826.906494 2149.947510,2827.784912 2149.949707,2829.321777 
	C2150.740967,2833.919922 2154.356689,2835.994873 2156.032715,2839.471191 
	C2157.071533,2838.027832 2156.351807,2836.351318 2157.575684,2834.514648 
	C2160.101074,2824.297607 2160.032471,2814.494141 2159.248047,2804.676758 
	C2159.117920,2803.046387 2158.216797,2801.289307 2160.516846,2800.657227 
	C2162.493164,2800.114014 2163.454590,2801.600830 2164.347168,2803.063477 
	C2166.390381,2806.410889 2166.064941,2810.147949 2165.943115,2813.768311 
	C2165.760986,2819.167236 2166.727539,2823.832520 2172.452148,2825.886475 
	C2175.652100,2827.034912 2175.965088,2829.481201 2175.954102,2832.268799 
	C2175.938232,2836.263916 2175.969238,2840.258789 2175.931641,2845.164795 
z"
        />
        <path
          fill="#E7E3DA"
          opacity="1.000000"
          stroke="none"
          d="
M2159.221191,2869.428223 
	C2159.984375,2872.105469 2160.007568,2874.239746 2160.033691,2877.173340 
	C2158.260986,2879.353516 2156.469482,2880.714111 2154.715088,2882.120605 
	C2152.829590,2883.632080 2150.761230,2884.344727 2148.423340,2883.438965 
	C2145.670898,2882.372314 2146.156006,2879.844971 2146.039795,2877.656494 
	C2145.897461,2874.982666 2146.342529,2872.490723 2149.355225,2870.382568 
	C2152.588135,2867.790039 2155.386719,2867.212402 2159.221191,2869.428223 
z"
        />
        <path
          fill="#DAD3C3"
          opacity="1.000000"
          stroke="none"
          d="
M2159.971680,2869.310547 
	C2157.195801,2869.981689 2154.430664,2869.992432 2150.834961,2870.001465 
	C2150.013184,2868.513428 2150.022217,2867.027100 2150.030273,2864.798584 
	C2153.006592,2863.973145 2156.000977,2863.780762 2159.391113,2865.714844 
	C2160.044189,2866.889160 2160.013184,2867.769531 2159.971680,2869.310547 
z"
        />
        <path
          fill="#DAD3C3"
          opacity="1.000000"
          stroke="none"
          d="
M2152.034180,2852.674805 
	C2152.690430,2851.907715 2153.341309,2851.801025 2153.993408,2851.704346 
	C2155.798584,2851.436279 2157.841064,2849.620117 2159.281738,2851.967529 
	C2159.866943,2852.920410 2159.947510,2854.721680 2159.434814,2855.709961 
	C2157.816406,2858.829834 2155.443604,2856.224854 2152.692139,2856.080566 
	C2152.009766,2855.113281 2152.019531,2854.226562 2152.034180,2852.674805 
z"
        />
        <path
          fill="#E8E1D2"
          opacity="1.000000"
          stroke="none"
          d="
M2004.236694,2806.600586 
	C1998.052979,2800.435059 2001.100708,2792.147217 1998.603027,2785.088135 
	C1997.940796,2783.216309 2000.199219,2782.447998 2001.915039,2783.454590 
	C2004.301147,2784.854248 2005.924194,2787.031250 2005.849243,2789.886230 
	C2005.704468,2795.411621 2006.985840,2801.025391 2004.236694,2806.600586 
z"
        />
        <path
          fill="#E8E1D2"
          opacity="1.000000"
          stroke="none"
          d="
M1996.877808,2767.754883 
	C1999.219604,2766.653564 2000.478027,2767.532227 2001.215576,2769.166748 
	C2002.056519,2771.030029 2002.248291,2772.959229 2000.797119,2774.655029 
	C2000.116943,2775.449951 1999.125732,2775.668701 1998.152588,2775.249756 
	C1995.861938,2774.262939 1996.144653,2772.162109 1996.129639,2770.238525 
	C1996.124756,2769.596680 1996.331665,2768.953125 1996.877808,2767.754883 
z"
        />
        <path
          fill="#F1E9D8"
          opacity="1.000000"
          stroke="none"
          d="
M1977.966064,2897.294922 
	C1976.461426,2897.970947 1974.991699,2898.007080 1972.786377,2898.068848 
	C1963.854736,2906.852783 1959.052979,2907.386719 1951.958008,2899.583496 
	C1948.812134,2893.095215 1947.768555,2886.928711 1947.570679,2879.836914 
	C1949.491089,2872.191406 1946.768433,2864.833740 1943.930176,2860.155029 
	C1935.932495,2846.971191 1935.983154,2831.384277 1928.976929,2818.095459 
	C1926.347046,2813.107422 1924.884521,2808.425781 1917.828369,2809.148438 
	C1913.197021,2809.623047 1911.868042,2806.425781 1913.533203,2802.600342 
	C1916.102539,2796.697754 1914.185303,2792.563721 1909.762573,2788.490723 
	C1904.172241,2783.342529 1902.896851,2775.056885 1896.293823,2770.227295 
	C1892.866089,2767.719971 1896.507568,2764.396729 1898.505981,2761.755371 
	C1901.680176,2757.559570 1907.864868,2758.107666 1911.438721,2753.289062 
	C1913.405518,2752.281250 1914.826660,2752.136719 1916.959961,2752.635742 
	C1922.779419,2756.093750 1918.041504,2762.385498 1923.389282,2767.976074 
	C1919.462891,2761.138916 1922.376465,2757.033203 1924.092773,2752.035156 
	C1932.201294,2741.239258 1942.666626,2734.966309 1955.254028,2731.061523 
	C1956.666504,2732.025391 1957.559814,2732.985107 1957.341919,2733.843506 
	C1954.076416,2746.714600 1960.109253,2758.629883 1961.172241,2771.049805 
	C1961.613403,2776.205078 1960.501099,2779.507568 1955.275879,2780.790039 
	C1950.946045,2781.852783 1947.472778,2784.273926 1944.226929,2787.270508 
	C1941.369629,2789.908936 1942.492554,2793.277588 1941.801880,2795.914307 
	C1938.764526,2807.509277 1948.770264,2816.369629 1946.612793,2827.409912 
	C1945.585083,2832.668457 1948.706909,2837.854980 1950.302979,2842.716064 
	C1954.478882,2855.433594 1959.115112,2868.163574 1967.323242,2881.154541 
	C1969.124146,2871.857666 1968.916870,2865.411865 1964.492676,2858.629395 
	C1958.945190,2850.124512 1962.340820,2829.877197 1969.392944,2822.546387 
	C1971.661621,2820.187988 1973.421143,2818.066162 1971.265259,2814.790527 
	C1965.836182,2806.541992 1971.528564,2804.372559 1978.660278,2802.610352 
	C1979.840576,2802.758057 1980.301025,2802.897705 1981.305664,2803.511719 
	C1983.157715,2819.373291 1984.465576,2834.760254 1985.789062,2851.001221 
	C1982.557617,2858.233154 1985.391357,2865.190674 1983.713867,2872.009033 
	C1982.016113,2878.909912 1981.211304,2886.143799 1978.540527,2893.555420 
	C1978.073486,2894.946289 1978.037231,2895.800537 1977.966064,2897.294922 
z"
        />
        <path
          fill="#CBC6BA"
          opacity="1.000000"
          stroke="none"
          d="
M1982.426270,2804.510742 
	C1981.849854,2803.986084 1980.908691,2803.997803 1980.437988,2804.003906 
	C1977.512573,2802.029541 1977.177490,2799.211914 1976.986572,2795.526855 
	C1976.466309,2785.024170 1975.900146,2775.349121 1977.882812,2764.950684 
	C1978.484741,2763.734131 1978.796997,2763.284424 1979.824463,2762.431641 
	C1985.306274,2763.166748 1983.818359,2767.197510 1984.046387,2770.167236 
	C1984.703125,2778.721191 1984.773438,2787.281494 1985.724121,2795.860840 
	C1988.125732,2817.530762 1992.741577,2838.804199 1996.146118,2861.097412 
	C1995.770630,2861.932861 1995.431763,2861.952881 1994.584961,2862.000488 
	C1990.081299,2854.254150 1989.540649,2845.567627 1988.215454,2837.225342 
	C1986.510620,2826.492920 1985.440796,2815.656738 1982.426270,2804.510742 
z"
        />
        <path
          fill="#D3CBBF"
          opacity="1.000000"
          stroke="none"
          d="
M1978.805176,2893.926758 
	C1980.204224,2884.268066 1978.310913,2873.856689 1982.469727,2864.348389 
	C1983.035034,2863.055908 1983.575684,2861.584473 1982.358765,2860.807129 
	C1974.196167,2855.594238 1983.532837,2854.953125 1985.175537,2852.010254 
	C1990.116333,2866.788574 1989.286011,2880.948730 1978.805176,2893.926758 
z"
        />
        <path
          fill="#FBF5E5"
          opacity="1.000000"
          stroke="none"
          d="
M1979.292603,2793.526367 
	C1979.813721,2796.832520 1979.916382,2799.592041 1979.993164,2803.180664 
	C1978.753906,2804.559326 1977.548584,2805.127930 1976.325562,2805.655273 
	C1969.363403,2808.656494 1969.352173,2808.700928 1974.730469,2813.440918 
	C1978.367310,2816.646240 1979.011597,2820.640137 1973.293701,2823.529297 
	C1962.307129,2829.080811 1967.070923,2838.599609 1965.237183,2846.429688 
	C1963.678589,2853.084473 1966.370728,2858.453613 1970.715942,2863.250488 
	C1973.166748,2865.956055 1974.273560,2868.619873 1971.399048,2871.969971 
	C1968.907837,2874.873291 1970.929321,2877.499756 1972.671509,2880.050781 
	C1973.723022,2881.590576 1973.493774,2883.254639 1971.956787,2884.461914 
	C1970.416382,2885.671875 1968.840576,2885.452148 1967.531128,2884.069092 
	C1963.787598,2880.115723 1959.520752,2877.040771 1957.791016,2870.992920 
	C1955.673218,2863.588867 1953.533325,2855.833008 1948.470337,2849.516846 
	C1947.472046,2848.271729 1946.053223,2846.275635 1946.435913,2845.195068 
	C1948.379395,2839.707275 1946.115845,2835.589355 1943.035767,2831.448486 
	C1941.731689,2829.695312 1942.163330,2827.617920 1943.171387,2825.766846 
	C1945.276245,2821.901367 1946.873169,2818.289062 1940.511108,2816.881592 
	C1939.843506,2816.733887 1938.807861,2814.925537 1939.023193,2814.598389 
	C1944.171387,2806.772705 1936.241089,2808.537354 1933.365723,2807.163574 
	C1932.902954,2804.521240 1934.571411,2803.880615 1935.995239,2803.107422 
	C1942.383179,2799.637451 1942.465698,2799.526855 1936.504517,2795.657715 
	C1933.769043,2793.882324 1933.535278,2791.292725 1935.681396,2790.063477 
	C1943.204224,2785.754883 1947.736816,2776.150391 1958.480835,2777.717041 
	C1959.803101,2773.812012 1958.550415,2770.360840 1957.302246,2767.732910 
	C1955.476318,2763.889160 1954.700439,2761.302734 1956.927734,2756.936768 
	C1960.069092,2750.779053 1953.298462,2750.812012 1949.483643,2748.955566 
	C1955.521484,2744.767334 1953.825928,2738.081055 1955.358643,2731.726562 
	C1953.066040,2727.714111 1954.151123,2724.587891 1955.734375,2720.851562 
	C1956.521484,2719.481445 1957.036377,2718.752930 1957.985107,2717.555176 
	C1959.075317,2716.042969 1959.710938,2715.005127 1959.863281,2713.111328 
	C1959.946289,2709.283691 1961.179565,2706.576416 1963.754150,2703.774170 
	C1965.286377,2702.083984 1966.695801,2701.087891 1967.078857,2698.668457 
	C1967.390747,2696.207275 1967.788818,2694.438232 1969.110962,2692.252930 
	C1970.013306,2691.531494 1970.461060,2691.363037 1971.522949,2691.565430 
	C1975.036133,2706.994873 1976.634644,2722.230469 1978.280640,2737.456055 
	C1979.102295,2745.055664 1979.663208,2752.683594 1980.440918,2761.163574 
	C1980.539795,2762.028320 1980.001221,2761.838135 1979.715698,2761.840576 
	C1974.104004,2772.129883 1975.966797,2782.545166 1979.292603,2793.526367 
z"
        />
        <path
          fill="#F1E9D8"
          opacity="1.000000"
          stroke="none"
          d="
M1972.221436,2691.210449 
	C1971.696167,2691.958008 1971.255005,2691.979004 1970.151611,2692.016113 
	C1963.517944,2688.141602 1960.632690,2684.014648 1969.318115,2679.389648 
	C1970.766846,2681.417969 1971.392578,2683.120605 1972.248535,2685.470215 
	C1972.421021,2687.572754 1972.363281,2689.028564 1972.221436,2691.210449 
z"
        />
        <path
          fill="#D8D1C0"
          opacity="1.000000"
          stroke="none"
          d="
M2040.072510,3071.099609 
	C2033.460693,3067.008545 2033.964233,3060.416016 2035.897095,3052.769287 
	C2038.817993,3051.085938 2038.746338,3053.272461 2039.572754,3055.473633 
	C2040.037842,3060.738281 2040.080444,3065.476074 2040.072510,3071.099609 
z"
        />
        <path
          fill="#CBC6BA"
          opacity="1.000000"
          stroke="none"
          d="
M2040.030396,3055.119141 
	C2038.977417,3054.887207 2037.959717,3053.773682 2036.468628,3052.339844 
	C2033.879028,3050.603271 2037.121338,3046.664551 2032.666260,3045.994141 
	C2032.144531,3045.070068 2032.162842,3044.247070 2032.202637,3042.806152 
	C2032.878540,3041.399658 2033.673584,3040.795898 2035.355225,3040.234619 
	C2037.135498,3040.109863 2038.109619,3040.356934 2039.578613,3041.358643 
	C2040.109863,3046.000488 2040.087769,3050.119141 2040.030396,3055.119141 
z"
        />
        <path
          fill="#BAB4A7"
          opacity="1.000000"
          stroke="none"
          d="
M2040.411377,3041.245850 
	C2039.271484,3041.904785 2038.411133,3041.927734 2036.906616,3041.964844 
	C2034.816284,3040.711670 2034.530762,3039.144287 2035.596436,3036.697021 
	C2036.722046,3035.060547 2037.320190,3034.090820 2037.999756,3032.533203 
	C2043.721191,3033.396729 2041.923096,3037.088379 2040.411377,3041.245850 
z"
        />
        <path
          fill="#CBC6BA"
          opacity="1.000000"
          stroke="none"
          d="
M2041.930664,2998.683105 
	C2043.710815,2999.087402 2043.970093,3000.834717 2043.977539,3003.363281 
	C2042.180786,3002.958740 2042.026367,3001.170410 2041.930664,2998.683105 
z"
        />
        <path
          fill="#797771"
          opacity="1.000000"
          stroke="none"
          d="
M2047.388672,3020.536133 
	C2047.863281,3021.113281 2047.922485,3021.955811 2048.013184,3023.434082 
	C2046.771606,3023.280518 2046.096313,3022.295410 2047.388672,3020.536133 
z"
        />
        <path
          fill="#CBC6BA"
          opacity="1.000000"
          stroke="none"
          d="
M2043.970215,3004.555176 
	C2044.319824,3004.031006 2044.704834,3004.011963 2045.670410,3004.007080 
	C2045.855957,3004.885498 2045.460938,3005.749512 2045.065918,3006.613525 
	C2044.712402,3006.095703 2044.359009,3005.578125 2043.970215,3004.555176 
z"
        />
        <path
          fill="#BAB4A7"
          opacity="1.000000"
          stroke="none"
          d="
M2049.965820,3024.029785 
	C2050.099121,3023.983643 2050.266357,3023.907715 2050.186523,3023.950195 
	C2049.939209,3024.068848 2050.000000,3024.000000 2049.965820,3024.029785 
z"
        />
        <path
          fill="#DFD9CC"
          opacity="1.000000"
          stroke="none"
          d="
M2035.598633,3035.605957 
	C2036.123291,3037.518799 2036.122803,3039.007324 2036.192383,3041.237305 
	C2035.361694,3042.015137 2034.460693,3042.051270 2032.892090,3042.137695 
	C2032.763672,3037.697754 2028.338379,3033.684082 2031.487061,3028.497070 
	C2034.705200,3029.683838 2033.343628,3033.090088 2035.598633,3035.605957 
z"
        />
        <path
          fill="#F2EBDD"
          opacity="1.000000"
          stroke="none"
          d="
M1079.631104,2976.311523 
	C1077.785889,2972.004639 1074.133545,2969.686035 1072.954468,2965.681152 
	C1072.408081,2963.825439 1072.759521,2962.252441 1074.341431,2961.370850 
	C1076.263550,2960.299805 1077.652588,2961.398193 1077.791504,2963.352783 
	C1078.081787,2967.441650 1080.889038,2969.828857 1083.399048,2972.472900 
	C1084.803101,2973.952148 1086.112793,2975.600830 1085.579712,2977.763916 
	C1084.949829,2980.319580 1084.169434,2983.834473 1081.030029,2983.368652 
	C1076.558105,2982.704834 1080.127686,2979.203613 1079.631104,2976.311523 
z"
        />
        <path
          fill="#ECA777"
          opacity="1.000000"
          stroke="none"
          d="
M733.269043,3236.389160 
	C738.761353,3243.376221 738.926880,3246.763916 734.699707,3250.060791 
	C733.301025,3251.151855 731.809937,3251.749023 730.237610,3250.549805 
	C727.429504,3248.408691 728.086609,3245.247559 728.035583,3242.343750 
	C727.980774,3239.219482 728.218506,3236.207031 733.269043,3236.389160 
z"
        />
        <path
          fill="#E8E1D2"
          opacity="1.000000"
          stroke="none"
          d="
M2020.002441,2722.004883 
	C2020.197876,2720.794678 2020.229126,2719.420410 2021.767456,2719.119141 
	C2022.087769,2719.056641 2022.879395,2719.728027 2022.893188,2720.083496 
	C2022.936401,2721.195801 2022.025146,2721.589355 2020.540527,2721.915527 
	C2019.995117,2721.992676 2020.000000,2722.000000 2020.002441,2722.004883 
z"
        />
        <path
          fill="#9DAAB4"
          opacity="1.000000"
          stroke="none"
          d="
M1970.137207,2678.912109 
	C1966.882812,2679.942871 1965.718872,2681.694580 1966.588379,2684.770508 
	C1967.182495,2686.871826 1967.932495,2688.929199 1969.050903,2691.519043 
	C1969.446533,2693.924805 1969.403931,2695.817139 1969.414551,2698.418213 
	C1969.274048,2700.844971 1967.939087,2701.850830 1966.613770,2703.562988 
	C1964.399414,2706.675049 1961.688110,2708.875977 1961.905029,2713.267578 
	C1961.943237,2715.127441 1961.674561,2716.195557 1960.665039,2717.600098 
	C1959.823975,2718.536133 1959.304810,2719.010986 1958.295654,2719.683594 
	C1951.787231,2719.081787 1952.319946,2714.414307 1952.240112,2710.248535 
	C1952.070679,2701.398926 1950.920288,2692.630127 1949.975342,2683.064453 
	C1948.383057,2675.777832 1947.261597,2669.211182 1945.817017,2662.703369 
	C1944.361328,2656.144775 1940.609619,2652.829346 1933.784302,2653.540283 
	C1916.603516,2655.329346 1899.407959,2654.476562 1882.229614,2653.706055 
	C1875.716553,2653.414062 1869.334473,2652.045898 1865.870850,2645.282715 
	C1883.269043,2648.479492 1899.804321,2647.106689 1916.548828,2642.797119 
	C1925.117554,2640.591553 1934.405273,2641.158936 1943.371948,2640.527344 
	C1951.977051,2639.921387 1958.879761,2643.495850 1965.340820,2649.840820 
	C1964.788086,2659.929932 1968.879272,2668.803223 1970.137207,2678.912109 
z"
        />
        <path
          fill="#E7E3DA"
          opacity="1.000000"
          stroke="none"
          d="
M2187.310547,2912.092773 
	C2187.343018,2915.915039 2186.200928,2919.662109 2185.789795,2923.661377 
	C2184.753906,2933.731201 2180.046387,2936.722900 2169.421875,2934.835693 
	C2172.074951,2928.720459 2170.306885,2922.833496 2168.554688,2916.922607 
	C2165.723145,2907.371582 2167.516113,2902.685059 2177.057129,2895.992676 
	C2177.764893,2898.808105 2178.051758,2901.783936 2177.806152,2904.714600 
	C2177.083008,2913.335938 2177.584229,2913.824951 2187.310547,2912.092773 
z"
        />
        <path
          fill="#E7E3DA"
          opacity="1.000000"
          stroke="none"
          d="
M2177.938477,2883.269043 
	C2175.093994,2883.645996 2173.871338,2881.946045 2174.188477,2879.241211 
	C2174.415039,2877.306152 2175.893799,2874.347656 2175.140137,2873.647461 
	C2166.977783,2866.064209 2174.649414,2858.542969 2175.383789,2850.535156 
	C2176.314453,2850.499023 2177.104736,2850.979248 2177.118164,2851.480469 
	C2177.396484,2861.810059 2180.421143,2872.066406 2177.938477,2883.269043 
z"
        />
        <path
          fill="#DAD3C3"
          opacity="1.000000"
          stroke="none"
          d="
M2157.384277,2833.934570 
	C2158.759521,2836.175293 2159.405518,2838.375000 2160.224609,2840.508057 
	C2160.865234,2842.177002 2161.154297,2843.808838 2159.468750,2844.864014 
	C2157.821289,2845.895996 2155.972900,2845.275635 2155.226807,2843.716797 
	C2153.172119,2839.422363 2149.230713,2836.017334 2149.504150,2830.407471 
	C2152.406494,2830.937744 2153.936035,2833.503906 2157.384277,2833.934570 
z"
        />
        <path
          fill="#DAD3C3"
          opacity="1.000000"
          stroke="none"
          d="
M2150.434814,2825.641113 
	C2148.054199,2824.554932 2147.628418,2822.820557 2149.487061,2820.372314 
	C2151.957031,2821.430908 2152.359375,2823.173828 2150.434814,2825.641113 
z"
        />
        <path
          fill="#243748"
          opacity="1.000000"
          stroke="none"
          d="
M931.463318,2800.036865 
	C931.484741,2803.391846 929.146912,2803.997803 926.305176,2803.978516 
	C920.147278,2803.936768 913.989014,2803.945801 906.905151,2803.908691 
	C904.329529,2801.940918 903.918457,2799.808838 905.492920,2796.739258 
	C909.798645,2797.136963 914.465454,2791.687744 917.069458,2795.926270 
	C920.166626,2800.967529 924.231506,2799.707031 929.158447,2800.019775 
	C929.994995,2800.009766 930.973938,2800.026123 931.463318,2800.036865 
z"
        />
        <path
          fill="#233444"
          opacity="1.000000"
          stroke="none"
          d="
M905.215698,2796.022949 
	C905.992493,2798.125488 905.992249,2800.221680 905.985718,2803.100586 
	C901.246277,2803.916504 896.513184,2803.949707 890.892700,2803.976807 
	C889.735901,2802.271240 889.047852,2801.029785 887.388794,2800.090088 
	C882.138306,2797.116943 885.807556,2796.656250 889.305420,2796.023682 
	C894.792725,2799.718994 899.616882,2795.411865 905.215698,2796.022949 
z"
        />
        <path
          fill="#3D494C"
          opacity="1.000000"
          stroke="none"
          d="
M1248.654907,2706.604492 
	C1235.644775,2702.656738 1222.839844,2702.056641 1210.268799,2700.090576 
	C1206.063354,2699.432861 1201.780151,2700.162598 1197.021729,2702.141602 
	C1196.216919,2702.231934 1196.049561,2702.054443 1195.964355,2701.967285 
	C1194.812988,2700.538330 1194.549438,2699.054688 1195.583252,2696.805176 
	C1198.206665,2696.137451 1200.336304,2696.092773 1203.265015,2696.038574 
	C1208.253784,2691.201416 1214.581909,2696.458252 1219.493286,2692.379883 
	C1234.970825,2693.524170 1249.747559,2689.413818 1264.725952,2690.039307 
	C1270.687866,2690.288086 1276.667603,2689.720459 1282.536865,2691.670654 
	C1284.934937,2692.467529 1287.830200,2692.595459 1290.515625,2689.810791 
	C1289.743530,2686.779297 1284.386597,2688.858887 1284.185059,2684.477051 
	C1286.789062,2681.098389 1290.294434,2682.524414 1293.413940,2681.940674 
	C1302.354004,2680.268066 1311.252563,2678.377441 1318.010986,2670.618408 
	C1324.018066,2661.770020 1332.023315,2664.933594 1340.403320,2666.285156 
	C1343.611084,2668.600830 1342.748535,2670.828369 1341.347534,2673.745117 
	C1337.870361,2676.992676 1334.092896,2676.927979 1330.521240,2677.447998 
	C1323.202881,2678.513184 1315.592163,2679.944824 1310.146362,2684.798828 
	C1298.426025,2695.246094 1283.830200,2699.315918 1269.904297,2705.015869 
	C1266.759888,2706.302734 1263.567017,2706.895752 1259.432617,2706.885010 
	C1255.560425,2707.212891 1252.468018,2707.463379 1248.654907,2706.604492 
z"
        />
        <path
          fill="#3A4F62"
          opacity="1.000000"
          stroke="none"
          d="
M1219.263550,2691.612793 
	C1220.926880,2695.990967 1220.843872,2699.348389 1215.161133,2697.364014 
	C1212.035400,2696.272705 1208.915894,2695.946045 1204.873291,2696.029297 
	C1208.626587,2689.149902 1208.626587,2689.149902 1219.263550,2691.612793 
z"
        />
        <path
          fill="#3A4F62"
          opacity="1.000000"
          stroke="none"
          d="
M1195.350098,2696.137695 
	C1196.030762,2697.605469 1195.984619,2699.028809 1195.908813,2701.166016 
	C1193.741089,2701.902100 1191.603027,2701.924316 1188.658936,2701.905029 
	C1188.438721,2697.979736 1190.607422,2695.953125 1195.350098,2696.137695 
z"
        />
        <path
          fill="#4E6477"
          opacity="1.000000"
          stroke="none"
          d="
M802.850464,2652.187012 
	C804.649597,2653.199463 805.588745,2654.566895 803.868591,2655.182129 
	C800.538635,2656.373535 797.052368,2656.579590 793.158508,2653.682861 
	C796.179504,2651.162842 799.310913,2652.190674 802.850464,2652.187012 
z"
        />
        <path
          fill="#4E6477"
          opacity="1.000000"
          stroke="none"
          d="
M887.968933,2661.751953 
	C886.032043,2661.477051 884.498779,2660.680664 885.459717,2659.394531 
	C886.751892,2657.665039 889.141907,2657.671875 890.446838,2659.379150 
	C891.422119,2660.655273 889.915466,2661.475586 887.968933,2661.751953 
z"
        />
        <path
          fill="#43596D"
          opacity="1.000000"
          stroke="none"
          d="
M905.587280,2672.470703 
	C904.807312,2676.374023 902.118286,2676.497559 898.459961,2674.562500 
	C899.818909,2671.720459 902.359985,2672.120850 905.587280,2672.470703 
z"
        />
        <path
          fill="#374D60"
          opacity="1.000000"
          stroke="none"
          d="
M1052.288940,2656.336426 
	C1052.883911,2654.640625 1053.947632,2654.090332 1055.696045,2655.485352 
	C1055.151978,2657.260010 1054.107544,2657.927002 1052.288940,2656.336426 
z"
        />
        <path
          fill="#4C6174"
          opacity="1.000000"
          stroke="none"
          d="
M582.405579,2670.967285 
	C582.148987,2675.050049 579.908203,2674.057373 577.778320,2672.973877 
	C577.449219,2672.806396 577.209045,2671.614258 577.448181,2671.322510 
	C578.671204,2669.831787 580.328064,2670.103516 582.405579,2670.967285 
z"
        />
        <path
          fill="#374D60"
          opacity="1.000000"
          stroke="none"
          d="
M1342.607422,2668.633301 
	C1334.538940,2668.557373 1326.958740,2666.753906 1318.880493,2669.589355 
	C1312.826538,2669.939453 1307.647949,2669.927246 1302.591675,2672.490967 
	C1297.611938,2675.015625 1292.346069,2675.098877 1287.760986,2670.849365 
	C1286.580322,2669.755371 1285.029297,2668.532715 1283.543335,2668.377930 
	C1268.048096,2666.763916 1252.145752,2665.775391 1237.143677,2668.878174 
	C1225.446655,2671.297607 1214.756714,2668.151855 1203.534424,2668.812012 
	C1207.114624,2664.763916 1211.765747,2666.441162 1215.959106,2666.034424 
	C1220.125366,2665.630371 1224.466431,2666.833252 1229.188721,2664.758789 
	C1227.347778,2660.371094 1221.528687,2663.595703 1220.155273,2658.815430 
	C1224.244629,2654.437744 1229.387695,2655.688232 1233.848999,2656.660889 
	C1239.326904,2657.855469 1244.731567,2657.888428 1251.099365,2658.093750 
	C1256.161743,2661.354736 1260.741577,2660.028809 1265.231323,2659.241455 
	C1270.719971,2658.278564 1276.160400,2657.017578 1281.666748,2656.183594 
	C1286.313843,2655.479492 1290.825806,2656.087158 1295.073730,2658.443604 
	C1297.385864,2659.725830 1300.022217,2660.914062 1301.856812,2656.694092 
	C1303.636230,2656.123291 1306.417969,2656.853516 1306.741455,2656.255127 
	C1309.849731,2650.505127 1315.168823,2652.315430 1319.875000,2651.948975 
	C1325.997681,2651.472656 1332.453247,2653.569580 1338.043579,2648.789795 
	C1339.450439,2647.586670 1342.672729,2647.743408 1344.807495,2648.280762 
	C1357.146851,2651.386719 1370.019775,2648.859375 1383.192871,2652.067871 
	C1390.640015,2659.618652 1388.344116,2665.881836 1377.617188,2668.342773 
	C1367.952759,2670.560059 1358.083130,2671.517822 1347.264893,2670.741699 
	C1345.260376,2670.357666 1344.200928,2669.900635 1342.607422,2668.633301 
z"
        />
        <path
          fill="#374D60"
          opacity="1.000000"
          stroke="none"
          d="
M1146.012207,2659.050781 
	C1155.483154,2658.012451 1165.151855,2657.231201 1175.385254,2659.462402 
	C1168.087524,2665.792725 1159.076782,2663.874756 1150.337280,2663.554199 
	C1148.267212,2663.478271 1146.896729,2661.799805 1146.012207,2659.050781 
z"
        />
        <path
          fill="#374D60"
          opacity="1.000000"
          stroke="none"
          d="
M1182.549194,2660.562744 
	C1184.170898,2655.268066 1188.474243,2656.016602 1193.258301,2656.021484 
	C1192.185547,2662.603271 1192.185547,2662.603271 1182.549194,2660.562744 
z"
        />
        <path
          fill="#374D60"
          opacity="1.000000"
          stroke="none"
          d="
M1128.404785,2661.731445 
	C1128.209961,2660.010498 1128.891602,2658.531006 1131.584717,2658.392822 
	C1131.776855,2660.136963 1130.975830,2661.488770 1128.404785,2661.731445 
z"
        />
        <path
          fill="#374D60"
          opacity="1.000000"
          stroke="none"
          d="
M1100.280273,2660.315918 
	C1100.916138,2658.596436 1101.992676,2658.068115 1103.702026,2659.596680 
	C1103.085449,2661.336426 1102.013062,2661.916016 1100.280273,2660.315918 
z"
        />
        <path
          fill="#43596D"
          opacity="1.000000"
          stroke="none"
          d="
M931.693481,2681.528564 
	C931.171387,2683.274414 930.144897,2683.943604 928.321411,2682.475586 
	C928.841675,2680.705811 929.883484,2680.104004 931.693481,2681.528564 
z"
        />
        <path
          fill="#2B3E51"
          opacity="1.000000"
          stroke="none"
          d="
M1064.365845,2656.434082 
	C1066.698120,2655.415283 1069.533203,2654.914795 1073.072510,2654.409180 
	C1072.165527,2660.001465 1068.336914,2657.846924 1064.365845,2656.434082 
z"
        />
        <path
          fill="#935F47"
          opacity="1.000000"
          stroke="none"
          d="
M1729.559692,2522.805664 
	C1730.502563,2521.593994 1731.020264,2520.923584 1732.156250,2520.055664 
	C1737.636963,2520.948975 1742.059692,2518.818848 1747.453125,2518.106934 
	C1747.301514,2521.431396 1745.506836,2525.150146 1751.818604,2523.094727 
	C1753.957153,2522.398193 1756.244629,2522.159424 1759.226807,2521.675293 
	C1763.494507,2525.887451 1761.420654,2529.577393 1757.863770,2531.742432 
	C1751.341064,2535.712646 1750.057251,2542.078857 1748.405884,2549.245850 
	C1747.402954,2552.193359 1745.998169,2554.916016 1743.857666,2554.985107 
	C1732.843506,2555.342285 1722.963501,2563.420410 1711.427368,2559.697266 
	C1707.078003,2558.293457 1704.705322,2560.483398 1702.602295,2563.947021 
	C1697.595825,2572.192871 1694.921753,2581.419189 1691.132324,2590.896973 
	C1690.985840,2592.040771 1690.920410,2592.497314 1690.752319,2593.639648 
	C1690.438965,2595.211182 1690.156006,2596.073730 1689.480957,2597.492432 
	C1687.071411,2600.217529 1685.288940,2602.489746 1685.964355,2606.453857 
	C1685.797241,2608.033447 1685.516235,2608.901123 1684.694092,2610.297852 
	C1681.719849,2612.606934 1679.098022,2613.530762 1676.063354,2611.975830 
	C1675.770874,2611.825684 1676.165039,2608.232178 1674.465454,2611.672852 
	C1671.513428,2617.648682 1666.751221,2616.345459 1661.613037,2612.970215 
	C1660.069336,2610.848389 1659.783936,2609.050293 1659.883301,2606.546387 
	C1660.197876,2604.413574 1660.362549,2602.930176 1658.393799,2601.492188 
	C1657.561890,2600.823242 1657.237183,2600.523926 1656.496460,2599.676514 
	C1653.530518,2595.074463 1653.115112,2589.850586 1648.313354,2586.955078 
	C1645.622437,2585.332275 1645.501465,2581.935303 1647.148438,2578.269531 
	C1659.254517,2577.890381 1662.546021,2570.427734 1664.987793,2560.558594 
	C1668.647339,2545.768311 1672.073364,2531.132324 1670.567139,2515.110107 
	C1672.723145,2512.569336 1674.611084,2513.182373 1677.172852,2514.580322 
	C1683.453247,2516.924561 1689.649536,2516.655762 1695.735352,2519.688965 
	C1702.524170,2526.368408 1701.187256,2538.696289 1692.199829,2545.496338 
	C1686.257324,2549.992432 1684.135620,2555.275391 1683.280151,2562.796631 
	C1691.341187,2551.180908 1701.113525,2542.015869 1708.745972,2531.101562 
	C1710.273682,2528.916992 1711.822388,2526.635742 1715.684692,2527.315186 
	C1719.778198,2533.915527 1713.544556,2541.493896 1719.362427,2547.413574 
	C1722.325562,2544.571777 1725.805298,2541.887695 1730.626099,2541.537598 
	C1733.343994,2541.339600 1737.087646,2542.600830 1738.032104,2538.541748 
	C1738.874390,2534.922852 1736.676758,2532.336182 1733.974854,2530.167480 
	C1731.727417,2528.363525 1728.780396,2527.013428 1729.559692,2522.805664 
z"
        />
        <path
          fill="#3A261C"
          opacity="1.000000"
          stroke="none"
          d="
M1688.403809,2598.679199 
	C1688.604248,2597.186279 1688.549316,2596.401855 1688.405518,2595.034180 
	C1688.654053,2593.877441 1688.776123,2593.261475 1688.380859,2592.231934 
	C1688.286011,2588.993408 1687.402954,2585.935059 1690.257324,2583.633789 
	C1691.469971,2582.656006 1692.970703,2580.901367 1692.870483,2579.614746 
	C1692.244995,2571.591064 1699.622803,2567.145752 1701.246338,2560.138428 
	C1701.795044,2557.770264 1710.813232,2554.203125 1711.993652,2555.740967 
	C1714.823120,2559.427246 1717.076294,2559.863037 1720.867065,2556.980469 
	C1723.079590,2555.298340 1727.175293,2558.540527 1729.305664,2554.267578 
	C1730.534424,2551.802979 1733.265259,2552.904053 1735.569946,2553.506104 
	C1739.209839,2554.456787 1742.829590,2554.713623 1745.515869,2550.187012 
	C1749.337280,2547.221436 1750.722534,2543.315674 1753.213867,2539.409668 
	C1753.715088,2538.390869 1753.920898,2537.983643 1754.454346,2536.969971 
	C1758.413086,2531.570068 1762.132812,2526.854248 1767.240234,2522.463867 
	C1771.362427,2522.342773 1771.812378,2524.729248 1772.030029,2527.661133 
	C1772.312500,2531.465576 1770.149048,2534.715332 1770.017334,2539.191895 
	C1766.338135,2540.340088 1761.109009,2538.607666 1762.600586,2545.719238 
	C1762.751831,2546.440430 1760.674683,2545.854248 1757.960449,2543.330322 
	C1757.960449,2547.001953 1758.273682,2549.850586 1757.882690,2552.599121 
	C1757.369507,2556.206787 1752.621094,2550.217041 1752.092041,2555.428223 
	C1747.884399,2556.461426 1748.001343,2559.508545 1747.993896,2563.219482 
	C1747.647705,2564.233398 1747.300415,2564.506104 1746.325195,2564.597656 
	C1743.244873,2561.049316 1740.768799,2563.112305 1737.691284,2564.585449 
	C1736.266113,2564.916748 1735.453857,2564.892334 1734.177612,2564.345215 
	C1732.679810,2563.851318 1734.552734,2562.360596 1732.960938,2562.976074 
	C1732.806763,2563.868652 1732.671753,2564.218506 1732.143433,2564.989502 
	C1730.811523,2566.148193 1729.761230,2566.683594 1728.073486,2567.285645 
	C1726.692139,2567.558838 1725.891968,2567.567383 1724.510254,2567.393311 
	C1722.791382,2567.080566 1721.652588,2566.986816 1719.941650,2567.494385 
	C1717.337402,2568.330811 1715.262939,2568.489502 1712.546021,2568.122559 
	C1711.568115,2567.886475 1711.196289,2567.762451 1710.323975,2567.380615 
	C1709.271362,2566.750488 1708.767822,2566.321289 1708.014893,2565.632568 
	C1707.293701,2565.859863 1707.116333,2566.378662 1707.133789,2567.546387 
	C1703.020874,2577.036133 1698.652100,2585.363525 1699.488159,2596.224121 
	C1700.087769,2604.011719 1702.472290,2608.590088 1708.870728,2612.757568 
	C1709.636108,2613.932373 1709.190186,2614.980957 1710.169067,2615.372314 
	C1710.234253,2614.791260 1710.406372,2614.238281 1711.076904,2613.165527 
	C1712.892090,2611.183594 1714.613281,2610.316650 1717.361938,2609.873779 
	C1719.179321,2609.878418 1720.180054,2610.121338 1721.776123,2610.980713 
	C1723.039307,2612.235840 1723.442383,2613.148193 1723.609375,2614.891113 
	C1717.394531,2625.823730 1719.499268,2636.692871 1721.555908,2647.753174 
	C1724.876709,2648.218018 1726.010742,2649.902344 1726.000610,2653.275879 
	C1726.685669,2657.009277 1730.972778,2658.884277 1728.428955,2663.381348 
	C1728.000000,2663.945068 1727.947876,2664.903076 1727.915283,2665.380127 
	C1723.854736,2666.478516 1724.000244,2669.555420 1723.951416,2673.269531 
	C1723.617310,2674.368164 1723.291870,2674.720215 1722.473511,2674.548584 
	C1721.980469,2674.025146 1722.000000,2674.000000 1721.990723,2674.009521 
	C1721.659058,2673.756836 1721.336914,2673.494873 1720.513062,2673.602051 
	C1715.853760,2673.798096 1717.785034,2671.055908 1717.974976,2668.470215 
	C1715.798218,2666.365723 1717.076050,2662.413086 1713.860107,2661.703125 
	C1706.469727,2660.072510 1708.025269,2655.413330 1709.917847,2650.775146 
	C1703.075195,2654.875977 1701.104980,2651.112061 1700.305908,2644.373779 
	C1699.706787,2643.539551 1699.423096,2643.233887 1698.671143,2642.496826 
	C1696.971436,2640.806641 1695.689941,2639.590820 1694.465820,2637.438232 
	C1693.272583,2626.601807 1695.562012,2615.845947 1688.658447,2605.946045 
	C1687.677124,2603.332764 1687.505737,2601.374023 1688.403809,2598.679199 
z"
        />
        <path
          fill="#1D1712"
          opacity="1.000000"
          stroke="none"
          d="
M1733.487793,2561.695312 
	C1734.210205,2562.622559 1734.589966,2563.120117 1735.540771,2563.719727 
	C1739.009033,2563.904053 1741.878540,2561.999023 1745.536865,2563.523926 
	C1746.045898,2563.960938 1747.020508,2563.971680 1747.507568,2563.966309 
	C1747.295288,2566.819336 1746.559326,2569.669434 1745.912476,2572.540039 
	C1745.609497,2573.885742 1745.137695,2575.185303 1743.726196,2575.487793 
	C1741.137085,2576.042725 1741.748291,2573.915039 1741.239746,2572.482178 
	C1740.038574,2569.097412 1738.186157,2571.284424 1736.819092,2572.356445 
	C1733.874146,2574.665771 1733.628052,2579.584473 1727.562866,2579.607422 
	C1733.285767,2583.725098 1738.710571,2580.427490 1743.567749,2583.610352 
	C1744.811401,2585.003662 1745.106323,2585.960938 1744.712402,2587.761230 
	C1741.740356,2591.205811 1738.295410,2591.892822 1733.968262,2592.105957 
	C1729.369263,2591.377686 1725.517578,2590.810303 1722.550049,2595.304932 
	C1721.286743,2597.798096 1720.964355,2599.814209 1721.406982,2602.605469 
	C1721.213745,2606.010254 1720.871948,2608.714600 1718.570557,2611.468750 
	C1716.479248,2612.500488 1714.824585,2613.001221 1712.572754,2613.775391 
	C1711.199219,2614.781738 1710.284180,2615.062012 1708.612793,2614.461426 
	C1704.104492,2613.520996 1701.790649,2611.805664 1699.052856,2608.373535 
	C1696.178833,2604.770752 1696.055786,2602.518799 1697.333008,2599.040527 
	C1697.446045,2598.732422 1697.638428,2598.354004 1697.552002,2598.086426 
	C1693.651367,2586.008789 1699.208130,2576.298096 1705.472900,2566.031006 
	C1705.188110,2564.557373 1705.052979,2563.745605 1705.627930,2562.450195 
	C1707.842896,2562.212402 1709.359009,2562.742432 1709.878296,2565.415039 
	C1712.777344,2566.072510 1715.561157,2566.075684 1719.180420,2566.083008 
	C1720.902222,2565.898682 1721.797852,2565.778076 1723.378662,2565.787842 
	C1724.931274,2565.869141 1725.807983,2565.889404 1727.342163,2565.918945 
	C1729.243652,2566.104736 1730.301392,2565.751221 1731.565918,2564.395508 
	C1731.972534,2563.636475 1731.969360,2563.302490 1731.976807,2562.466797 
	C1732.323853,2561.769775 1732.660522,2561.573975 1733.487793,2561.695312 
z"
        />
        <path
          fill="#8C5B43"
          opacity="1.000000"
          stroke="none"
          d="
M1731.197754,2590.071289 
	C1735.433716,2589.349854 1738.853760,2588.641357 1743.016846,2587.825195 
	C1753.697510,2583.707031 1763.869995,2581.172852 1775.485352,2583.215332 
	C1779.611328,2583.427734 1782.766235,2581.714355 1786.948608,2582.431152 
	C1792.672241,2587.667480 1790.744141,2592.602295 1786.823730,2597.135498 
	C1783.856323,2600.566162 1780.279419,2603.453369 1776.643188,2607.320557 
	C1775.948975,2607.703125 1775.010620,2607.863281 1774.548096,2607.975586 
	C1768.056763,2613.675049 1760.213867,2617.135742 1754.716553,2624.395020 
	C1750.904175,2624.923584 1747.734253,2623.949707 1744.562988,2625.955566 
	C1741.600098,2627.829590 1740.109985,2632.888672 1736.102905,2631.777100 
	C1731.655518,2630.543457 1729.401855,2625.711182 1725.831909,2622.837891 
	C1723.939819,2621.315186 1723.495361,2618.955566 1722.739258,2616.056152 
	C1722.499512,2614.655762 1722.491943,2613.907959 1722.447021,2612.600586 
	C1723.676147,2604.217041 1718.718628,2594.133301 1731.197754,2590.071289 
z"
        />
        <path
          fill="#564031"
          opacity="1.000000"
          stroke="none"
          d="
M1776.329102,2582.989014 
	C1766.261597,2586.747803 1755.869873,2587.108887 1744.652710,2587.833984 
	C1743.834229,2586.928467 1743.908691,2586.139404 1744.050171,2584.759521 
	C1748.557739,2583.019287 1753.063232,2582.061768 1756.516479,2578.640137 
	C1758.198608,2576.973633 1760.125000,2576.008057 1762.723389,2576.478516 
	C1767.032471,2577.258789 1771.197876,2576.509521 1774.915527,2574.149658 
	C1777.413696,2572.563721 1779.547485,2573.321045 1780.906372,2575.318848 
	C1782.831543,2578.149170 1780.573853,2579.269775 1778.272461,2580.277100 
	C1777.493042,2580.618164 1777.009888,2581.635254 1776.329102,2582.989014 
z"
        />
        <path
          fill="#564031"
          opacity="1.000000"
          stroke="none"
          d="
M1776.310547,2608.206055 
	C1776.727173,2603.376465 1778.516846,2599.271973 1782.350830,2597.244873 
	C1788.152344,2594.177246 1788.450195,2589.431152 1787.775879,2583.253418 
	C1791.254883,2581.753906 1794.833374,2581.027100 1799.170654,2580.213867 
	C1801.209839,2585.754150 1795.372192,2588.071045 1794.051147,2593.227539 
	C1789.326660,2600.178711 1784.760132,2606.500000 1776.310547,2608.206055 
z"
        />
        <path
          fill="#765242"
          opacity="1.000000"
          stroke="none"
          d="
M1767.260010,2521.844727 
	C1766.936768,2529.023193 1759.526611,2531.312744 1756.502197,2537.606934 
	C1754.934326,2543.363770 1753.270386,2548.155029 1746.526367,2549.612061 
	C1744.949097,2543.629150 1749.443115,2539.198975 1750.017334,2533.717041 
	C1750.245239,2531.540283 1751.767700,2531.679443 1753.450439,2531.387451 
	C1757.905029,2530.614258 1759.982788,2527.627686 1760.005127,2522.401611 
	C1762.215820,2519.753906 1764.455322,2520.534180 1767.260010,2521.844727 
z"
        />
        <path
          fill="#3A261C"
          opacity="1.000000"
          stroke="none"
          d="
M1770.241211,2540.381348 
	C1770.806030,2540.231201 1771.617065,2540.462402 1772.427979,2540.693604 
	C1771.829224,2542.069336 1771.152710,2541.259277 1770.241211,2540.381348 
z"
        />
        <path
          fill="#3A261C"
          opacity="1.000000"
          stroke="none"
          d="
M1752.009766,2555.990234 
	C1752.233521,2556.243896 1752.447388,2556.507080 1752.661255,2556.770508 
	C1752.506470,2556.683594 1752.351685,2556.596680 1752.090210,2556.264648 
	C1751.983643,2556.019531 1752.000000,2556.000000 1752.009766,2555.990234 
z"
        />
        <path
          fill="#F9B783"
          opacity="1.000000"
          stroke="none"
          d="
M1757.034790,2497.968750 
	C1755.128662,2501.621094 1753.120117,2504.640381 1751.006470,2507.584717 
	C1749.424561,2509.788330 1747.495117,2511.612305 1744.601685,2511.859375 
	C1742.623169,2512.028320 1740.423340,2512.318115 1739.063354,2510.542480 
	C1737.653198,2508.701416 1738.762085,2506.778076 1739.579468,2504.952148 
	C1743.053833,2497.190674 1749.733643,2496.718750 1757.034790,2497.968750 
z"
        />
        <path
          fill="#704A3A"
          opacity="1.000000"
          stroke="none"
          d="
M1716.563843,2527.816650 
	C1711.096069,2528.447510 1711.482544,2533.278809 1709.298706,2535.947266 
	C1701.154175,2545.899170 1691.778931,2554.856445 1684.864380,2565.816895 
	C1684.181641,2566.899170 1683.109985,2567.858154 1682.003296,2568.507324 
	C1681.281738,2568.930664 1679.919067,2569.062012 1679.302368,2568.645264 
	C1678.641968,2568.199463 1677.993774,2566.758545 1678.244629,2566.093018 
	C1680.437988,2560.274902 1682.451538,2554.462891 1682.698608,2548.136230 
	C1682.745972,2546.921143 1684.472900,2546.345703 1685.835083,2546.107910 
	C1692.041260,2545.023926 1694.509521,2540.383301 1695.748047,2535.025879 
	C1696.361450,2532.372559 1697.251587,2530.041260 1698.709351,2527.784424 
	C1700.272827,2525.363281 1697.874390,2524.327637 1696.350830,2522.342285 
	C1701.974731,2513.282715 1710.546265,2516.548340 1719.210571,2517.222656 
	C1721.630737,2518.079102 1723.211060,2518.585693 1723.988159,2521.004883 
	C1724.547729,2526.662598 1720.413818,2526.638916 1716.563843,2527.816650 
z"
        />
        <path
          fill="#BA815C"
          opacity="1.000000"
          stroke="none"
          d="
M1715.794556,2528.634521 
	C1716.460205,2523.486816 1720.959229,2524.735840 1723.676636,2522.199707 
	C1725.563110,2521.753418 1727.041748,2521.884277 1729.252441,2522.139648 
	C1730.121216,2525.089355 1732.170654,2526.552979 1734.401001,2527.072021 
	C1740.170166,2528.414795 1741.166260,2533.017578 1741.853638,2537.605713 
	C1742.701416,2543.263428 1740.514038,2545.465088 1734.700562,2544.409424 
	C1727.353638,2543.075684 1722.786011,2546.642090 1718.882080,2552.013916 
	C1712.463135,2545.213135 1712.700195,2537.500488 1715.794556,2528.634521 
z"
        />
        <path
          fill="#2C2520"
          opacity="1.000000"
          stroke="none"
          d="
M1719.829834,2517.002930 
	C1712.601196,2520.233643 1704.774658,2519.480225 1696.622314,2521.254883 
	C1690.243530,2520.514404 1684.333252,2519.689697 1678.552734,2516.244873 
	C1677.831055,2515.384521 1677.594971,2515.053467 1677.730957,2514.238770 
	C1691.046875,2515.094727 1703.555054,2514.353760 1715.207153,2506.018555 
	C1715.505615,2509.981201 1716.828369,2513.433105 1719.829834,2517.002930 
z"
        />
        <path
          fill="#2C2520"
          opacity="1.000000"
          stroke="none"
          d="
M1716.390991,2505.708984 
	C1716.312378,2505.398926 1716.696289,2504.841064 1717.503540,2503.987549 
	C1717.569092,2504.281738 1717.211182,2504.871826 1716.390991,2505.708984 
z"
        />
        <path
          fill="#765242"
          opacity="1.000000"
          stroke="none"
          d="
M1721.912964,2500.387695 
	C1722.311523,2499.880371 1722.659668,2499.886719 1723.526978,2499.949219 
	C1723.738281,2500.614746 1723.430298,2501.223633 1722.516846,2501.882080 
	C1721.895142,2501.588135 1721.878906,2501.244629 1721.912964,2500.387695 
z"
        />
        <path
          fill="#765242"
          opacity="1.000000"
          stroke="none"
          d="
M1722.045410,2502.058105 
	C1721.842773,2502.088379 1721.768188,2503.771240 1721.639038,2502.053711 
	C1721.911255,2501.931396 1722.004028,2502.012451 1722.045410,2502.058105 
z"
        />
        <path
          fill="#98999E"
          opacity="1.000000"
          stroke="none"
          d="
M1751.761963,2408.831055 
	C1752.332520,2407.375000 1752.998047,2406.716309 1754.384521,2406.007324 
	C1754.937256,2406.827637 1754.769043,2407.697998 1754.603638,2409.233398 
	C1753.987671,2411.569092 1753.368652,2413.239502 1752.374023,2415.458008 
	C1751.951172,2413.879883 1751.904175,2411.754150 1751.761963,2408.831055 
z"
        />
        <path
          fill="#3C556B"
          opacity="1.000000"
          stroke="none"
          d="
M996.550171,2622.620605 
	C995.654419,2617.549561 992.826050,2615.963379 988.614868,2615.982910 
	C982.326904,2616.012207 976.038696,2615.993164 968.814819,2615.916016 
	C971.427490,2607.258057 971.483459,2607.083008 978.970337,2609.475342 
	C983.527771,2610.931641 987.445679,2611.110596 992.234314,2609.229248 
	C1002.559143,2605.172852 1013.666931,2604.196777 1024.365479,2607.343994 
	C1034.916504,2610.448242 1045.260254,2607.970703 1055.686401,2608.011963 
	C1061.941284,2608.036865 1068.311768,2608.716309 1075.188110,2606.169434 
	C1079.597656,2611.111084 1079.521484,2611.602539 1074.818237,2611.935547 
	C1071.499146,2612.170410 1068.144409,2611.961182 1064.836792,2612.277588 
	C1062.775879,2612.474609 1060.587769,2613.686768 1061.087158,2616.021973 
	C1061.627075,2618.546631 1064.884521,2620.312500 1066.435669,2619.061523 
	C1071.329102,2615.115723 1077.513184,2617.138428 1083.264404,2614.333252 
	C1085.177856,2612.805664 1086.548340,2612.857910 1088.570068,2613.987793 
	C1090.106689,2615.665039 1090.621826,2617.029053 1090.322266,2619.262207 
	C1086.120850,2620.179932 1082.352173,2619.915527 1078.543457,2622.105469 
	C1073.540649,2624.981445 1067.509766,2623.802002 1062.483521,2622.262451 
	C1048.248535,2617.901123 1034.202148,2621.288086 1020.227478,2622.635498 
	C1012.427856,2623.387939 1004.709839,2620.984375 996.550171,2622.620605 
z"
        />
        <path
          fill="#3C556B"
          opacity="1.000000"
          stroke="none"
          d="
M917.433533,2619.358887 
	C910.808533,2624.743164 903.812073,2623.568115 896.454773,2618.698730 
	C893.025757,2615.234375 889.215698,2615.975830 885.676270,2616.047119 
	C873.838257,2616.285400 862.004700,2616.565186 849.276489,2615.754883 
	C846.482483,2614.993896 845.281006,2613.795654 845.579346,2610.876465 
	C861.454773,2608.315186 876.797852,2605.113281 892.510925,2605.955322 
	C896.817505,2606.186035 901.053894,2606.118652 905.351746,2607.274658 
	C909.764221,2608.462158 914.022156,2607.919678 918.716187,2605.802246 
	C926.646423,2602.224609 935.138611,2602.781494 943.355530,2607.614258 
	C930.973755,2616.461426 917.221985,2613.350342 903.658447,2614.168945 
	C907.391418,2619.763184 912.937195,2616.758789 917.433533,2619.358887 
z"
        />
        <path
          fill="#374D60"
          opacity="1.000000"
          stroke="none"
          d="
M845.215942,2610.034424 
	C846.342407,2611.137451 846.707153,2612.221680 847.541260,2613.652832 
	C848.942200,2615.371826 848.201294,2616.293701 846.620361,2617.455078 
	C840.550781,2620.971680 838.754456,2616.244873 836.108459,2612.447266 
	C838.422852,2609.218506 841.563354,2610.219238 845.215942,2610.034424 
z"
        />
        <path
          fill="#465F75"
          opacity="1.000000"
          stroke="none"
          d="
M818.582397,2605.871094 
	C818.284363,2602.772705 817.957703,2598.904541 823.560547,2601.518066 
	C823.742737,2604.891602 822.033203,2606.022461 818.582397,2605.871094 
z"
        />
        <path
          fill="#3C556B"
          opacity="1.000000"
          stroke="none"
          d="
M955.606323,2615.532715 
	C954.574707,2617.895996 952.840210,2618.230225 950.401245,2616.592285 
	C951.387939,2614.094482 953.131958,2613.763672 955.606323,2615.532715 
z"
        />
        <path
          fill="#3C556B"
          opacity="1.000000"
          stroke="none"
          d="
M937.661987,2616.357422 
	C937.799988,2618.110596 937.044312,2619.540527 934.352173,2619.558594 
	C934.246094,2617.819336 935.082031,2616.479980 937.661987,2616.357422 
z"
        />
        <path
          fill="#374D60"
          opacity="1.000000"
          stroke="none"
          d="
M1242.394043,2649.513672 
	C1234.537231,2649.540771 1226.904785,2651.129639 1218.821777,2648.101562 
	C1213.804443,2648.009766 1209.603882,2647.988037 1204.703369,2648.006348 
	C1170.090576,2647.383057 1136.223389,2645.268555 1102.264648,2645.743652 
	C1089.918579,2645.916260 1077.807861,2648.441406 1065.503418,2647.940186 
	C1062.456909,2647.816162 1057.390015,2649.655518 1056.992432,2645.387207 
	C1056.529175,2640.413818 1061.780518,2642.640137 1064.646606,2641.966797 
	C1078.299683,2638.759277 1092.100952,2637.112793 1107.077637,2638.034912 
	C1109.667114,2637.964600 1111.720825,2638.511475 1112.917847,2637.735840 
	C1121.086304,2632.444336 1130.161499,2634.269531 1138.978149,2634.002197 
	C1146.235352,2633.782471 1153.542725,2632.944336 1160.683228,2632.724365 
	C1173.428955,2632.331299 1186.244873,2632.469971 1199.030884,2632.090820 
	C1200.711304,2632.041016 1202.474731,2632.345947 1203.483521,2633.833496 
	C1206.944336,2638.935791 1212.733643,2638.837646 1217.723145,2639.656738 
	C1222.895752,2640.506348 1228.212769,2640.435547 1233.434204,2641.625732 
	C1235.411865,2642.076416 1240.460449,2643.841797 1238.192505,2637.662598 
	C1237.419922,2635.557861 1239.880615,2635.990234 1240.837524,2636.123047 
	C1243.436523,2636.483398 1245.981079,2637.237061 1249.280884,2637.946045 
	C1251.696167,2640.027100 1252.129761,2642.217773 1250.593506,2645.316650 
	C1247.254150,2646.155029 1244.940430,2647.377930 1242.394043,2649.513672 
z"
        />
        <path
          fill="#33495B"
          opacity="1.000000"
          stroke="none"
          d="
M1108.718872,2631.687988 
	C1096.699219,2632.011963 1085.398315,2632.041260 1074.097656,2632.006592 
	C1067.472046,2631.986084 1060.821777,2631.335938 1055.187134,2636.234131 
	C1052.862305,2638.255127 1049.555786,2638.191650 1046.559814,2637.929199 
	C1039.322021,2637.294678 1032.139771,2640.250488 1024.885620,2638.195557 
	C1024.285889,2638.025635 1023.447327,2638.049316 1022.926331,2638.345947 
	C1011.760315,2644.700684 999.567871,2641.192383 987.840271,2641.968994 
	C978.367615,2642.596436 968.814392,2640.855469 959.190918,2643.068359 
	C962.959045,2639.078125 966.870605,2635.572021 972.366272,2636.101074 
	C984.539246,2637.272217 996.041382,2633.424072 1007.701782,2631.362793 
	C1016.548462,2629.798584 1025.539062,2629.215088 1034.299438,2628.591797 
	C1047.004639,2627.687988 1059.673584,2623.681641 1072.827881,2627.321289 
	C1077.833740,2628.706543 1084.021484,2626.864746 1089.419556,2625.500732 
	C1095.022339,2624.085205 1100.575806,2624.062500 1107.103760,2624.009277 
	C1110.718018,2622.332275 1112.795532,2619.311523 1117.301758,2619.928223 
	C1121.619385,2620.166260 1125.388428,2619.818359 1128.809692,2620.749023 
	C1133.151978,2621.930420 1137.084106,2621.915527 1141.387939,2620.624512 
	C1146.312500,2619.146973 1151.243286,2619.848389 1155.491699,2623.081787 
	C1156.203247,2623.623291 1156.918823,2624.702393 1156.911499,2625.526611 
	C1156.898560,2626.991699 1155.697998,2627.947021 1154.258057,2627.798340 
	C1148.045410,2627.155273 1142.043213,2629.666260 1135.684082,2628.413818 
	C1126.757080,2626.656006 1118.048218,2629.090088 1108.718872,2631.687988 
z"
        />
        <path
          fill="#33495B"
          opacity="1.000000"
          stroke="none"
          d="
M1250.767090,2646.322266 
	C1250.005371,2643.866943 1250.005859,2641.753174 1250.010498,2638.848633 
	C1260.509888,2640.116211 1270.847168,2640.597412 1281.583618,2638.481445 
	C1287.443726,2637.326660 1294.446411,2636.061279 1301.567993,2640.918457 
	C1293.773804,2643.036621 1287.106323,2641.212402 1278.574585,2642.831787 
	C1287.053223,2644.270996 1293.517700,2645.368164 1300.642700,2646.577393 
	C1298.536377,2651.030029 1294.797363,2650.378174 1292.096680,2649.814209 
	C1280.233276,2647.337646 1268.249390,2648.316895 1256.315918,2647.884277 
	C1254.707764,2647.826172 1253.124390,2647.087402 1250.767090,2646.322266 
z"
        />
        <path
          fill="#3D494C"
          opacity="1.000000"
          stroke="none"
          d="
M1345.480225,2668.531738 
	C1355.628540,2668.137695 1365.210693,2668.106934 1374.696289,2666.079590 
	C1376.985474,2665.590576 1379.108887,2665.205078 1381.173340,2663.927734 
	C1387.133545,2660.239258 1387.194458,2660.337646 1384.118896,2652.743652 
	C1388.478882,2652.061523 1392.894165,2652.020264 1398.127686,2652.009766 
	C1399.451660,2652.143799 1399.957764,2652.246826 1400.614014,2653.110352 
	C1395.555908,2662.938232 1388.683960,2670.168457 1377.884766,2674.016113 
	C1365.635742,2670.416748 1355.342651,2677.454590 1343.573975,2677.540283 
	C1342.433960,2677.003906 1342.023315,2676.703857 1341.310303,2675.707520 
	C1340.811890,2672.029297 1343.051880,2670.653809 1345.480225,2668.531738 
z"
        />
        <path
          fill="#8C8D89"
          opacity="1.000000"
          stroke="none"
          d="
M1399.593262,2653.814941 
	C1399.947510,2652.861572 1399.234009,2652.711670 1399.089844,2652.376221 
	C1406.071899,2648.271729 1413.266724,2649.797363 1421.282959,2651.821289 
	C1424.353394,2650.375000 1426.078613,2651.379639 1427.853271,2653.829102 
	C1427.526001,2656.827393 1426.012085,2657.868164 1423.842773,2657.868164 
	C1420.222168,2657.868164 1416.941162,2658.457764 1413.753052,2660.577881 
	C1411.020264,2662.395752 1407.609009,2662.790771 1404.690552,2660.443848 
	C1402.655762,2658.807861 1401.978271,2660.627197 1400.459595,2662.014160 
	C1397.834229,2660.033936 1398.298340,2657.424805 1399.593262,2653.814941 
z"
        />
        <path
          fill="#566C80"
          opacity="1.000000"
          stroke="none"
          d="
M1088.656250,2616.345215 
	C1087.046997,2615.272217 1086.090454,2614.688232 1084.549805,2614.062012 
	C1084.308350,2612.760986 1084.525879,2610.986816 1085.871948,2610.824463 
	C1095.232788,2609.693848 1104.462646,2606.943115 1114.025635,2608.151367 
	C1118.541870,2608.721924 1118.040771,2611.552490 1116.300903,2615.319336 
	C1114.088501,2618.351562 1111.542603,2619.031250 1107.834106,2618.776611 
	C1101.239014,2616.963135 1095.019287,2619.249023 1088.656250,2616.345215 
z"
        />
        <path
          fill="#3C556B"
          opacity="1.000000"
          stroke="none"
          d="
M1107.442993,2618.301270 
	C1109.779663,2615.694824 1112.228516,2616.063965 1115.285645,2616.009766 
	C1116.520752,2616.985596 1117.038086,2617.961670 1117.780029,2619.441895 
	C1114.984497,2621.673584 1112.668091,2624.718262 1108.401367,2624.436768 
	C1108.000000,2623.118652 1108.000000,2622.227539 1108.000000,2620.668213 
	C1107.645508,2619.627686 1107.291016,2619.255127 1107.442993,2618.301270 
z"
        />
        <path
          fill="#33495B"
          opacity="1.000000"
          stroke="none"
          d="
M1428.708740,2654.385254 
	C1426.477417,2653.823975 1424.910889,2653.044189 1422.721436,2652.072754 
	C1424.616699,2648.972412 1427.998901,2650.328857 1431.900513,2650.093262 
	C1432.272217,2652.010010 1431.528564,2653.676758 1428.708740,2654.385254 
z"
        />
        <path
          fill="#33495B"
          opacity="1.000000"
          stroke="none"
          d="
M1200.937378,2625.034668 
	C1200.894897,2624.955078 1200.979858,2625.114258 1200.937378,2625.034668 
z"
        />
        <path
          fill="#374D60"
          opacity="1.000000"
          stroke="none"
          d="
M1302.002441,2655.999023 
	C1301.786743,2655.744141 1301.568726,2655.490479 1301.350586,2655.236816 
	C1301.500977,2655.328125 1301.651245,2655.419434 1301.898438,2655.760254 
	C1301.995117,2656.009766 1302.000000,2656.000000 1302.002441,2655.999023 
z"
        />
        <path
          fill="#FBF9EC"
          opacity="1.000000"
          stroke="none"
          d="
M1399.332031,2653.225586 
	C1401.106323,2655.369141 1400.223267,2657.909912 1400.031372,2661.411377 
	C1395.253662,2675.563965 1399.199463,2687.856201 1404.494873,2699.689941 
	C1408.661499,2709.001465 1414.122314,2717.703613 1421.728271,2726.247803 
	C1425.462402,2728.228027 1427.217651,2730.698730 1430.252197,2732.510742 
	C1431.030518,2733.140625 1431.340210,2733.389160 1432.116455,2733.995605 
	C1432.865112,2734.615723 1433.147095,2734.877930 1434.202881,2735.957031 
	C1435.216919,2737.050049 1435.457153,2737.325928 1436.035889,2738.020996 
	C1436.582520,2738.724854 1436.790649,2739.009521 1437.286621,2739.724609 
	C1437.759399,2740.437988 1437.944458,2740.720459 1438.416260,2741.408691 
	C1438.703125,2741.814697 1439.333862,2742.564209 1439.690918,2742.896973 
	C1440.047974,2743.229736 1440.830811,2743.821289 1441.241211,2744.098145 
	C1441.651611,2744.374756 1442.479980,2744.932861 1442.885376,2745.229980 
	C1443.551880,2745.738281 1443.813110,2745.949707 1444.828125,2746.829834 
	C1445.828979,2747.724854 1446.075806,2747.950928 1447.065430,2748.845947 
	C1447.808105,2749.514893 1448.568359,2750.158936 1448.959106,2750.466797 
	C1449.884521,2751.169678 1450.422729,2751.559814 1451.382812,2752.225586 
	C1452.067383,2752.710449 1452.333984,2752.914795 1452.975342,2753.454102 
	C1453.829346,2754.250000 1454.303223,2754.716309 1455.143555,2755.514648 
	C1455.515259,2755.841797 1456.261230,2756.490723 1456.633789,2756.813965 
	C1457.503662,2757.574219 1458.007446,2758.002930 1458.933350,2758.709961 
	C1459.935425,2759.364258 1460.530151,2759.719238 1462.599854,2760.887695 
	C1464.707764,2762.129883 1465.336060,2762.563965 1466.450562,2763.303223 
	C1467.269653,2763.798584 1467.592041,2764.003418 1468.386108,2764.530518 
	C1469.469971,2765.303711 1470.048950,2765.793457 1471.091797,2767.312012 
	C1479.388062,2775.214844 1489.255493,2778.393799 1498.321045,2783.000488 
	C1503.024658,2785.390625 1507.993164,2787.259033 1513.398560,2789.787354 
	C1514.258545,2790.399170 1514.562012,2790.586182 1515.398560,2790.731445 
	C1516.982178,2790.934814 1518.032715,2791.179932 1519.513428,2791.763428 
	C1519.384399,2799.858154 1513.685059,2797.902100 1509.763794,2796.648926 
	C1501.868774,2794.125244 1493.633301,2792.907471 1485.491455,2789.804199 
	C1487.832153,2792.012207 1490.537720,2792.738525 1493.043945,2793.081299 
	C1511.622559,2795.624512 1522.651855,2809.217529 1532.392578,2822.538574 
	C1542.341064,2836.143555 1555.669434,2838.681152 1569.802490,2838.311035 
	C1578.755615,2838.076416 1588.028442,2835.730225 1597.795044,2833.619141 
	C1605.165039,2831.232666 1611.572021,2827.441406 1619.662231,2830.785400 
	C1624.179077,2836.213867 1622.669312,2842.946777 1626.140015,2848.900635 
	C1627.092896,2853.367676 1627.178467,2857.068115 1626.365845,2861.505859 
	C1624.771606,2865.920654 1620.852661,2868.975342 1623.490479,2874.332031 
	C1624.127197,2877.588623 1623.559570,2879.901855 1621.481201,2882.507324 
	C1613.039307,2886.963623 1614.335815,2895.898926 1610.715210,2902.179199 
	C1608.621948,2905.810303 1608.153931,2910.322266 1604.604492,2914.023926 
	C1602.613525,2916.057129 1600.750366,2917.331543 1600.073730,2920.289551 
	C1599.023926,2923.008789 1598.648438,2925.257568 1597.370361,2927.922607 
	C1592.530884,2933.245605 1590.141968,2939.767578 1584.218384,2943.314453 
	C1582.235474,2944.501953 1582.482666,2946.614990 1583.137085,2948.632812 
	C1584.556519,2953.010254 1583.242798,2957.055420 1581.049805,2961.584229 
	C1580.153198,2962.329102 1579.714966,2962.522949 1578.648071,2962.368164 
	C1572.968506,2963.542480 1566.680176,2963.632568 1567.191406,2971.528809 
	C1567.288086,2973.021729 1566.002075,2973.778809 1565.122437,2974.749023 
	C1562.140137,2978.038330 1555.802124,2979.617676 1559.219116,2986.251221 
	C1559.407959,2986.617432 1558.914185,2987.770996 1558.479248,2987.959229 
	C1549.573364,2991.810303 1548.330811,3003.036377 1539.786987,3007.300293 
	C1537.729492,3008.327393 1536.147949,3010.522949 1533.781616,3008.220459 
	C1531.941772,3006.430420 1533.008545,3004.746582 1534.008545,3002.896729 
	C1536.730957,2997.861084 1536.569336,2991.650879 1541.555054,2987.340332 
	C1543.806274,2985.394287 1544.986450,2984.383301 1548.057129,2984.865479 
	C1553.692993,2985.750488 1554.573486,2984.891846 1553.296631,2979.189209 
	C1551.767456,2972.359375 1555.963501,2966.496338 1555.642822,2959.699219 
	C1555.439087,2955.382812 1558.865723,2950.457520 1564.850220,2949.320801 
	C1568.000610,2948.722168 1569.641357,2946.833252 1568.767700,2942.769775 
	C1567.781372,2938.183594 1571.782104,2935.107666 1574.468018,2932.024902 
	C1576.601807,2929.575684 1579.496826,2927.721924 1582.249756,2925.899658 
	C1584.815308,2924.201660 1585.822266,2922.384277 1584.762939,2919.260742 
	C1583.205688,2914.668945 1584.335449,2910.564209 1588.741455,2908.217529 
	C1592.622070,2906.150635 1592.160034,2903.861572 1589.937744,2901.089600 
	C1588.495361,2899.290283 1586.605225,2897.805908 1586.054077,2894.647461 
	C1586.495361,2892.372314 1588.909546,2891.238525 1588.307739,2889.777832 
	C1585.009888,2881.776123 1589.960938,2875.306152 1594.490845,2871.407959 
	C1601.359497,2865.496826 1602.262207,2859.021240 1601.789062,2851.037598 
	C1600.104736,2853.441650 1598.204346,2858.004639 1596.630615,2857.269775 
	C1590.529785,2854.421875 1592.038208,2860.231689 1590.877930,2862.015869 
	C1588.137695,2866.229248 1586.082764,2870.014648 1581.722168,2862.754883 
	C1578.138916,2860.722168 1586.875732,2852.807373 1577.073975,2854.270752 
	C1574.561768,2854.645752 1574.702881,2854.928711 1573.977661,2856.691895 
	C1571.017456,2863.887939 1568.243408,2860.862061 1565.678711,2856.285156 
	C1567.029297,2859.280273 1568.831665,2862.205811 1566.600830,2866.210449 
	C1560.408325,2869.894775 1555.801880,2863.613281 1549.555176,2864.876221 
	C1547.330200,2864.827881 1545.855957,2864.723389 1543.674316,2864.432861 
	C1541.577271,2864.045410 1540.207397,2863.698975 1538.132324,2863.382812 
	C1526.594727,2869.179688 1520.541748,2860.505859 1512.684692,2855.096924 
	C1494.772705,2849.242676 1494.437134,2849.008057 1484.486206,2863.241699 
	C1480.599487,2868.801025 1473.215576,2872.910400 1474.863037,2881.506348 
	C1464.678345,2890.724609 1456.561157,2902.693359 1442.724731,2907.111328 
	C1436.000854,2909.258301 1433.709106,2905.947510 1433.562256,2894.437500 
	C1434.797729,2889.645508 1436.900635,2886.297119 1441.306030,2883.826904 
	C1452.438232,2883.597656 1454.521484,2874.197266 1459.812500,2867.435059 
	C1460.434082,2866.466309 1460.711548,2866.099121 1461.503174,2865.276367 
	C1471.114990,2863.589111 1467.015747,2855.015381 1470.413818,2849.824219 
	C1471.400635,2848.541748 1472.100586,2847.940674 1473.515747,2847.151367 
	C1476.542114,2845.841309 1478.956543,2848.833496 1481.512573,2846.782471 
	C1480.124878,2843.053223 1476.166992,2843.924561 1473.081787,2842.021973 
	C1470.942505,2840.957275 1469.707031,2839.691406 1467.485352,2838.857910 
	C1466.031738,2838.488037 1465.208618,2838.238770 1463.780151,2837.734863 
	C1459.214355,2835.648682 1455.284546,2833.738525 1450.691406,2831.715820 
	C1448.030151,2829.936279 1446.509155,2827.827881 1443.916260,2825.989990 
	C1441.749146,2824.383789 1440.109009,2823.116699 1437.793213,2821.720215 
	C1435.512817,2820.224854 1433.883789,2818.875977 1431.368774,2817.713379 
	C1425.800171,2815.098389 1422.795898,2810.541260 1418.503906,2806.438965 
	C1411.898438,2798.254639 1405.150146,2791.303711 1394.780762,2787.955811 
	C1392.606201,2786.321533 1390.902100,2786.359863 1388.548950,2787.586426 
	C1388.018433,2788.019531 1387.028564,2788.052246 1386.533447,2788.061035 
	C1376.180786,2792.968994 1365.647949,2790.726562 1354.666138,2790.499023 
	C1353.981934,2790.027100 1352.989990,2790.017090 1352.493896,2790.008545 
	C1351.790771,2785.885986 1353.573364,2783.857178 1357.864258,2783.973877 
	C1361.494629,2784.072754 1365.060059,2783.858154 1368.681763,2782.837158 
	C1378.139648,2780.171143 1378.545410,2779.938721 1379.910645,2768.606934 
	C1372.092163,2755.139404 1362.939941,2745.578613 1346.477417,2743.577881 
	C1337.051636,2742.432617 1328.326538,2735.750244 1317.949463,2732.888916 
	C1303.941284,2726.408691 1289.381714,2722.763184 1277.296387,2712.682617 
	C1276.584595,2704.018799 1282.222778,2702.892578 1287.535156,2704.594727 
	C1297.877441,2707.908203 1307.477295,2714.308838 1318.469238,2714.704102 
	C1329.414307,2715.097900 1339.225220,2718.349121 1349.137085,2722.163330 
	C1342.087402,2718.969482 1334.783325,2716.465820 1326.847046,2713.654785 
	C1324.917236,2712.263672 1324.010986,2710.899414 1323.562866,2709.330811 
	C1321.262695,2701.282471 1317.549194,2697.214355 1309.448364,2703.817383 
	C1308.265015,2704.781738 1306.322754,2704.455322 1303.980347,2704.370605 
	C1292.148071,2703.251465 1280.947388,2701.744629 1271.104004,2710.872070 
	C1268.539551,2712.146484 1266.483154,2711.899170 1263.701538,2711.983398 
	C1262.936646,2711.982910 1262.993652,2712.012695 1262.984497,2711.981934 
	C1260.462891,2710.333740 1256.938232,2709.635742 1257.614990,2704.915771 
	C1263.566284,2703.370117 1269.314941,2703.378174 1273.876465,2699.346191 
	C1274.802368,2698.527588 1276.272827,2697.914551 1277.496338,2697.907715 
	C1287.847168,2697.849365 1294.943237,2691.377930 1302.357910,2685.516846 
	C1312.895386,2677.187012 1324.907715,2673.483398 1339.148438,2673.946777 
	C1340.416260,2673.584229 1340.812744,2673.270752 1341.535400,2673.641602 
	C1341.861450,2674.325928 1342.051270,2674.944092 1342.157227,2675.249512 
	C1343.695801,2679.428955 1339.812622,2679.928955 1337.695557,2682.341309 
	C1336.755127,2683.038086 1336.357666,2683.288574 1335.324097,2683.843018 
	C1332.240967,2685.283936 1329.488770,2685.178711 1326.763062,2686.103516 
	C1333.442627,2687.171143 1338.776611,2690.252930 1343.000244,2695.815674 
	C1346.029541,2693.533203 1345.359497,2689.511963 1347.795776,2687.286865 
	C1350.701782,2684.633057 1353.929199,2683.317139 1357.822510,2684.405518 
	C1361.407104,2685.407471 1361.831909,2688.296143 1362.011719,2691.473633 
	C1362.744995,2704.433105 1368.210205,2715.667725 1375.346924,2726.980469 
	C1377.373901,2731.064453 1380.353760,2733.770996 1381.875732,2737.362549 
	C1380.436890,2733.743652 1377.401489,2731.092773 1375.358887,2726.996582 
	C1368.892578,2717.192383 1365.095459,2707.025879 1362.744507,2696.352539 
	C1359.120117,2679.897705 1361.522095,2676.128174 1377.419922,2672.625000 
	C1386.662842,2667.900879 1391.649658,2659.584717 1399.332031,2653.225586 
z"
        />
        <path
          fill="#D89066"
          opacity="1.000000"
          stroke="none"
          d="
M1422.189575,2722.882812 
	C1430.770264,2722.533936 1430.770264,2722.533936 1431.343750,2731.494629 
	C1427.238037,2731.912598 1425.329712,2729.078125 1422.328369,2726.582275 
	C1421.773193,2726.164307 1421.821655,2726.351074 1421.750610,2726.416748 
	C1421.837769,2725.524658 1421.996094,2724.567139 1422.189575,2722.882812 
z"
        />
        <path
          fill="#495E72"
          opacity="1.000000"
          stroke="none"
          d="
M1213.040283,2613.072510 
	C1212.957275,2613.115967 1213.123169,2613.029053 1213.040283,2613.072510 
z"
        />
        <path
          fill="#F4EEDC"
          opacity="1.000000"
          stroke="none"
          d="
M1598.434814,2835.420898 
	C1591.848511,2840.020508 1584.308105,2836.898682 1577.867432,2839.967285 
	C1568.102051,2844.620361 1558.257690,2838.997314 1548.426147,2839.715332 
	C1542.783325,2834.595215 1533.216064,2834.259033 1530.976807,2824.791748 
	C1530.351929,2822.149658 1528.087036,2819.599609 1525.528442,2817.838867 
	C1520.611938,2814.456055 1516.252075,2810.914551 1513.156982,2805.293945 
	C1510.695190,2800.823242 1504.794556,2799.103027 1499.574097,2798.105469 
	C1492.695312,2796.790527 1486.393311,2793.817383 1479.784790,2791.742432 
	C1478.274292,2791.268311 1476.752686,2789.998779 1477.171997,2788.031006 
	C1477.631714,2785.874268 1479.635376,2786.183594 1481.223877,2786.067871 
	C1482.547363,2785.971191 1484.226685,2785.552490 1485.161621,2786.167969 
	C1491.389526,2790.267578 1499.012329,2789.416016 1505.676392,2792.205078 
	C1509.742676,2793.906738 1514.404785,2797.988037 1519.615723,2792.694336 
	C1527.523193,2793.898926 1535.102783,2795.695801 1543.442505,2797.509033 
	C1559.954712,2799.023926 1575.356079,2795.897949 1591.636597,2793.844727 
	C1613.958374,2790.003662 1634.036377,2782.383545 1653.425903,2770.870361 
	C1660.210815,2768.000488 1664.729980,2763.177979 1670.462402,2758.879395 
	C1682.515625,2751.811279 1688.166626,2740.049072 1696.314209,2729.514160 
	C1697.904297,2727.979248 1699.174316,2727.263184 1701.361206,2726.765625 
	C1702.608398,2726.802490 1703.102661,2726.912842 1704.224121,2727.456299 
	C1705.419312,2728.759766 1705.679810,2729.712891 1705.499023,2731.483887 
	C1695.752686,2750.558838 1682.259399,2765.774170 1666.451172,2779.889404 
	C1664.194458,2780.758057 1662.376587,2780.727539 1660.360718,2782.051758 
	C1659.542236,2782.711426 1659.210449,2782.970703 1658.375244,2783.613037 
	C1656.474854,2784.949219 1655.096802,2785.924805 1653.545776,2787.704102 
	C1651.686646,2789.313477 1650.044678,2789.918945 1647.671143,2790.482422 
	C1644.772217,2790.742432 1642.680420,2791.290527 1640.368652,2793.259766 
	C1639.317993,2793.860596 1638.871704,2794.073486 1637.699707,2794.503418 
	C1619.786133,2801.039062 1601.787109,2804.150879 1584.012817,2808.162598 
	C1577.756836,2809.574951 1571.456055,2808.823486 1564.437256,2808.960449 
	C1560.567627,2808.901367 1557.428467,2809.001465 1554.302734,2808.642578 
	C1556.508789,2808.959473 1558.748169,2808.988770 1561.591187,2809.685547 
	C1562.663330,2810.107422 1563.075195,2810.320801 1564.016602,2810.989502 
	C1565.980103,2813.613037 1565.433960,2815.659180 1564.646729,2817.993408 
	C1561.748413,2826.586914 1565.320557,2831.535645 1573.832031,2830.305908 
	C1580.838989,2829.293701 1587.649170,2831.171631 1594.576416,2830.796143 
	C1597.158813,2830.656250 1599.097046,2831.777344 1598.434814,2835.420898 
z"
        />
        <path
          fill="#B0714F"
          opacity="1.000000"
          stroke="none"
          d="
M1594.367676,2796.402344 
	C1578.034912,2799.999512 1561.972900,2801.538818 1545.007812,2797.769043 
	C1548.118408,2794.792236 1552.560181,2796.236084 1556.760620,2796.060791 
	C1565.088867,2795.712646 1564.975830,2795.812012 1567.190918,2787.552490 
	C1568.248657,2783.608398 1567.859497,2779.664795 1567.989258,2775.714111 
	C1568.264404,2767.343750 1569.114990,2766.666260 1576.868408,2763.798584 
	C1585.812866,2760.490479 1594.956909,2757.041016 1597.681152,2746.227051 
	C1598.318481,2743.697510 1599.735962,2742.188232 1602.411621,2741.916016 
	C1605.075928,2741.645264 1606.191284,2739.441406 1607.509644,2737.574707 
	C1611.111816,2732.473389 1610.427612,2729.907227 1604.577393,2728.157715 
	C1600.757446,2727.015137 1596.510620,2726.851562 1596.017334,2720.803711 
	C1593.395020,2713.965576 1597.724121,2712.630127 1602.015015,2711.690430 
	C1605.853760,2710.849854 1609.805176,2711.774414 1614.489258,2711.806152 
	C1615.761108,2712.059082 1616.252075,2712.209473 1617.401245,2712.734863 
	C1628.937744,2721.602295 1621.071777,2731.691650 1620.306763,2741.877930 
	C1617.825195,2755.661133 1607.621338,2764.920898 1603.438110,2777.113770 
	C1602.044556,2781.175781 1599.880981,2785.007080 1599.845703,2789.437744 
	C1599.817383,2792.999268 1597.815430,2794.802734 1594.367676,2796.402344 
z"
        />
        <path
          fill="#96664C"
          opacity="1.000000"
          stroke="none"
          d="
M1616.389648,2713.558594 
	C1612.558350,2714.656006 1609.339355,2715.594727 1605.613647,2714.464844 
	C1601.870483,2713.329346 1597.732666,2713.538818 1596.107666,2719.315186 
	C1595.111816,2720.010742 1594.228638,2719.967529 1592.683594,2719.877686 
	C1589.420532,2718.813965 1586.962891,2718.567871 1584.514648,2721.562012 
	C1582.901733,2721.634033 1581.803467,2721.277832 1580.352173,2720.451172 
	C1578.090576,2717.550293 1573.717041,2718.273682 1572.874268,2713.782959 
	C1572.953369,2712.723389 1573.065308,2712.318115 1573.489990,2711.422852 
	C1569.836426,2706.412109 1565.611206,2709.710938 1560.795410,2710.747314 
	C1558.198120,2711.348633 1556.312866,2711.027832 1553.719727,2710.508545 
	C1550.374634,2709.201416 1547.308350,2709.061035 1544.495483,2706.536621 
	C1538.398193,2707.261719 1536.629272,2701.039795 1532.189087,2700.086182 
	C1527.915771,2699.168701 1523.151855,2701.280518 1517.934082,2697.816650 
	C1522.744873,2696.135010 1526.678345,2699.909180 1530.459839,2696.839600 
	C1525.939941,2692.945801 1520.038330,2693.067383 1514.500977,2690.515625 
	C1513.754150,2689.673584 1513.521240,2689.339600 1513.625488,2688.495605 
	C1513.962524,2687.986084 1514.921387,2687.903076 1515.402344,2687.886230 
	C1519.333984,2683.976807 1524.715698,2686.169189 1529.114746,2683.319092 
	C1530.801880,2681.460938 1532.247192,2680.360352 1534.325195,2678.958740 
	C1536.109741,2677.924072 1537.385010,2677.451660 1539.433838,2677.229248 
	C1542.926392,2677.993896 1545.738770,2678.775391 1549.270386,2679.696289 
	C1558.107788,2687.464844 1568.698364,2690.187256 1579.286255,2694.416992 
	C1580.802856,2694.567627 1581.643066,2694.558594 1583.112305,2694.503906 
	C1584.163940,2694.643555 1584.586548,2694.828613 1585.608154,2695.360352 
	C1586.207275,2695.707031 1586.929565,2696.088135 1587.284912,2696.289062 
	C1589.776611,2698.235840 1592.778564,2697.582031 1595.417847,2699.634521 
	C1595.853027,2700.129395 1595.822754,2700.286133 1595.801025,2700.363281 
	C1604.509888,2704.067139 1613.326782,2706.906250 1623.588379,2703.312988 
	C1626.900513,2702.932129 1629.410645,2703.088867 1632.446045,2704.480713 
	C1635.604004,2709.456787 1632.277710,2708.976562 1628.889893,2708.989502 
	C1627.697632,2709.598389 1626.979126,2709.077148 1626.379639,2709.285400 
	C1626.315308,2709.307617 1626.541504,2709.709473 1626.799927,2709.747559 
	C1628.119019,2709.941650 1626.273193,2709.644775 1628.122070,2709.072754 
	C1636.461914,2710.905762 1634.490601,2714.763672 1630.223999,2718.207275 
	C1628.541504,2719.564941 1626.825439,2719.292236 1630.862915,2720.080811 
	C1632.659668,2722.899170 1632.675293,2725.374756 1632.239746,2728.652832 
	C1631.031616,2732.886963 1629.375732,2736.101318 1627.243774,2739.943848 
	C1624.629639,2742.402832 1622.170410,2743.118896 1618.686279,2742.359375 
	C1618.197266,2735.705322 1616.321533,2728.870361 1623.610107,2724.071289 
	C1620.554932,2721.573730 1614.085571,2721.340088 1617.919678,2714.763184 
	C1617.028809,2713.306885 1616.947021,2712.381592 1617.521729,2711.340332 
	C1617.062744,2711.835693 1616.821655,2712.431152 1616.389648,2713.558594 
z"
        />
        <path
          fill="#D89066"
          opacity="1.000000"
          stroke="none"
          d="
M1460.654541,2761.513184 
	C1459.439453,2761.713379 1458.891113,2761.487061 1458.200195,2760.634277 
	C1457.563599,2759.693359 1457.132935,2759.167480 1456.259155,2758.366455 
	C1455.846802,2758.066162 1455.934570,2758.280273 1455.829224,2758.328125 
	C1455.036621,2756.910400 1453.801880,2755.932129 1452.080688,2754.685059 
	C1451.533813,2754.470215 1451.726562,2754.315430 1451.701904,2754.436523 
	C1451.243652,2753.260498 1450.011108,2752.745605 1448.586914,2751.550781 
	C1447.688232,2751.027344 1447.298218,2750.873535 1446.337036,2750.456299 
	C1445.765991,2750.192871 1445.841919,2750.070312 1445.896240,2750.117676 
	C1445.569824,2749.614014 1445.136230,2749.108643 1444.245117,2748.352783 
	C1443.841309,2748.056396 1443.975464,2748.022705 1443.960693,2748.090332 
	C1443.589600,2747.589355 1443.157104,2747.086426 1442.244995,2746.354492 
	C1441.841919,2746.060303 1441.978638,2746.031250 1441.971191,2746.100830 
	C1441.189087,2745.116455 1439.961182,2745.011475 1438.335693,2744.366211 
	C1437.829712,2744.052734 1437.969360,2744.030762 1437.956787,2744.100342 
	C1437.203613,2743.190674 1437.040527,2741.989746 1436.246948,2740.454102 
	C1435.818359,2740.041992 1435.959839,2740.025146 1435.944336,2740.094727 
	C1435.586792,2739.587646 1435.159790,2739.083008 1434.241455,2738.362305 
	C1433.835083,2738.074951 1433.973145,2738.027344 1433.949219,2738.096436 
	C1433.586182,2737.587646 1433.160400,2737.082520 1432.240967,2736.365234 
	C1431.833618,2736.080078 1431.948853,2736.160889 1431.885132,2736.190918 
	C1431.203857,2735.357422 1430.955200,2734.398438 1431.493896,2732.703613 
	C1431.911255,2732.063477 1431.949585,2732.036621 1431.967529,2732.023193 
	C1434.268555,2731.770752 1437.586914,2732.409424 1438.659424,2731.144287 
	C1443.945679,2724.907959 1452.184937,2726.932861 1458.474976,2723.402344 
	C1462.947876,2720.892090 1477.326660,2728.807373 1479.698364,2734.150146 
	C1482.098755,2739.557617 1483.582153,2745.361816 1486.897461,2750.378906 
	C1488.347046,2752.572266 1487.369019,2754.785156 1486.675903,2757.026611 
	C1484.974854,2762.529297 1486.260986,2766.781250 1492.027954,2769.044434 
	C1493.246094,2769.522217 1494.425171,2770.205078 1495.467285,2770.997314 
	C1496.511475,2771.791260 1497.556030,2772.895752 1496.883667,2774.336670 
	C1496.249146,2775.695801 1494.836548,2775.968994 1493.526489,2775.465088 
	C1489.219727,2773.807373 1484.937866,2772.081787 1480.683105,2770.294678 
	C1478.255615,2769.274658 1475.982910,2767.797363 1472.381958,2768.215088 
	C1471.589722,2768.300049 1471.739868,2768.126953 1471.843018,2768.176758 
	C1471.234619,2767.075195 1469.981812,2767.021973 1468.335693,2766.415039 
	C1467.812134,2766.125732 1467.965210,2766.107666 1467.948242,2766.182617 
	C1466.518677,2764.686035 1464.631958,2763.792969 1462.309082,2762.451416 
	C1461.750000,2762.178955 1461.913086,2762.184082 1461.885986,2762.261230 
	C1461.646729,2762.000977 1461.434570,2761.663574 1460.654541,2761.513184 
z"
        />
        <path
          fill="#8C5B43"
          opacity="1.000000"
          stroke="none"
          d="
M1523.933594,2660.803955 
	C1520.832153,2652.934814 1512.158813,2648.274170 1514.451904,2638.754395 
	C1508.153687,2634.107422 1512.134277,2624.976318 1506.304932,2620.061279 
	C1505.355713,2619.260986 1504.939697,2617.817139 1504.308472,2616.653564 
	C1502.500122,2613.320557 1501.683228,2610.466064 1506.847778,2609.399902 
	C1509.011475,2608.953613 1511.011230,2607.712891 1512.926025,2606.901123 
	C1512.824463,2605.760498 1513.020142,2604.857666 1512.675049,2604.536865 
	C1503.542847,2596.045410 1503.288574,2595.918945 1509.313599,2585.132080 
	C1511.054810,2582.014893 1512.681152,2579.423096 1510.995605,2576.067139 
	C1508.750122,2571.595947 1511.585571,2568.596680 1513.918457,2564.632568 
	C1515.540283,2563.969727 1516.988892,2564.021973 1519.163086,2564.088867 
	C1520.887939,2564.227051 1521.783081,2564.593262 1522.983398,2565.842041 
	C1525.313232,2575.354492 1520.505737,2584.204590 1523.381104,2593.797363 
	C1523.717773,2594.927002 1523.820312,2595.387451 1523.994385,2596.550293 
	C1520.906860,2608.586670 1526.073364,2619.285889 1526.541748,2630.321533 
	C1527.070923,2642.794189 1532.488770,2654.552490 1531.121094,2668.532715 
	C1530.353394,2670.186768 1529.988525,2670.493408 1528.926270,2670.963867 
	C1527.758423,2671.044189 1527.287964,2670.960693 1526.258301,2670.429688 
	C1525.121216,2667.164307 1524.543579,2664.346680 1523.933594,2660.803955 
z"
        />
        <path
          fill="#B0714F"
          opacity="1.000000"
          stroke="none"
          d="
M1559.518799,2710.578613 
	C1560.332275,2708.904541 1560.479492,2707.183105 1561.819702,2706.937500 
	C1568.048096,2705.795410 1574.351807,2704.655762 1580.273193,2708.474121 
	C1579.109009,2711.038818 1577.102173,2711.293457 1574.642334,2711.932129 
	C1573.560059,2711.543701 1573.108398,2711.104736 1572.633667,2710.689697 
	C1571.971191,2711.846680 1572.246826,2710.340576 1573.621094,2711.616699 
	C1573.677002,2716.165039 1578.960205,2715.472168 1579.882812,2719.317871 
	C1574.350098,2724.730713 1574.480713,2725.327393 1581.824585,2729.651611 
	C1571.408569,2731.288086 1562.140015,2727.229004 1552.436157,2722.545410 
	C1556.684448,2719.325195 1561.338867,2722.575439 1566.694580,2722.205566 
	C1567.966553,2722.559326 1568.505249,2722.890625 1569.012085,2723.276855 
	C1570.072876,2724.084473 1568.613281,2723.190674 1569.704346,2723.942139 
	C1570.462402,2724.464355 1570.313477,2724.500244 1569.086792,2723.243652 
	C1564.077148,2721.807373 1559.486450,2721.106445 1554.243896,2720.970215 
	C1552.977173,2720.741211 1552.428345,2720.274414 1552.145020,2720.230713 
	C1552.458252,2720.572021 1552.790527,2721.462891 1552.401855,2721.736328 
	C1548.803467,2721.875488 1544.986572,2722.758545 1543.064087,2719.454834 
	C1540.912720,2715.758057 1536.706665,2716.582764 1533.312622,2714.030273 
	C1534.929932,2710.820068 1536.191772,2707.800293 1540.932495,2709.222168 
	C1542.349609,2709.646973 1543.359741,2708.732422 1543.857178,2706.682617 
	C1547.000977,2705.942871 1550.068359,2705.780029 1553.480957,2707.916016 
	C1554.047852,2712.240479 1554.691284,2714.890625 1559.518799,2710.578613 
z"
        />
        <path
          fill="#B0714F"
          opacity="1.000000"
          stroke="none"
          d="
M1525.002930,2669.946777 
	C1526.068115,2670.010498 1526.437256,2670.039551 1527.360596,2670.105957 
	C1528.685425,2670.584473 1529.476685,2670.985352 1530.622437,2671.895752 
	C1531.643433,2674.606445 1530.162231,2676.208984 1529.487549,2678.562988 
	C1529.489502,2679.464600 1529.515381,2679.829834 1529.659424,2680.733643 
	C1530.146240,2681.956055 1530.611328,2682.568604 1531.559448,2683.597168 
	C1531.586792,2686.756104 1529.951172,2687.935059 1527.320312,2687.937988 
	C1524.091431,2687.941406 1520.862671,2687.961670 1516.758545,2687.922363 
	C1515.939209,2687.212402 1515.733398,2686.270020 1516.091187,2685.941406 
	C1520.496338,2681.895996 1519.141846,2678.180420 1515.681152,2674.412842 
	C1514.275391,2672.882812 1514.046387,2670.868408 1514.093384,2668.842529 
	C1514.124390,2667.512451 1514.180664,2666.065674 1515.372681,2665.317627 
	C1516.687134,2664.491943 1517.979980,2665.249756 1518.807983,2666.304688 
	C1520.258911,2668.153076 1521.604492,2670.025879 1525.002930,2669.946777 
z"
        />
        <path
          fill="#B0714F"
          opacity="1.000000"
          stroke="none"
          d="
M1584.307129,2722.559082 
	C1583.912231,2721.360596 1583.796143,2720.733398 1583.741943,2720.100830 
	C1583.590942,2718.343750 1581.567383,2716.442139 1583.631104,2714.875488 
	C1585.454224,2713.491211 1587.257202,2714.626221 1588.739136,2715.919434 
	C1589.691162,2716.750000 1590.491699,2717.754395 1591.691040,2719.255859 
	C1590.484375,2726.978027 1590.243774,2727.085205 1584.307129,2722.559082 
z"
        />
        <path
          fill="#B0714F"
          opacity="1.000000"
          stroke="none"
          d="
M1513.644287,2688.171387 
	C1513.969116,2688.323242 1513.975586,2688.660156 1513.984497,2689.502441 
	C1513.315918,2689.637695 1512.664062,2689.260010 1513.644287,2688.171387 
z"
        />
        <path
          fill="#F4EEDC"
          opacity="1.000000"
          stroke="none"
          d="
M1515.460693,2790.474609 
	C1515.931641,2790.689941 1515.110352,2791.122070 1515.110352,2791.122070 
	C1515.110352,2791.122070 1514.276123,2790.773682 1514.115479,2790.492676 
	C1514.299805,2790.228027 1514.644775,2790.243896 1515.460693,2790.474609 
z"
        />
        <path
          fill="#603A24"
          opacity="1.000000"
          stroke="none"
          d="
M1430.531494,2501.413086 
	C1420.661377,2497.458008 1416.966064,2487.209961 1421.853516,2477.718262 
	C1423.818848,2473.901367 1425.327026,2470.042969 1426.427979,2465.187500 
	C1430.793213,2460.623291 1434.445801,2456.267578 1437.843018,2450.860352 
	C1445.331787,2455.638672 1443.427124,2461.870117 1439.770020,2468.944336 
	C1439.887329,2474.937744 1436.090820,2478.667236 1434.041016,2483.783203 
	C1432.724609,2486.242676 1431.077271,2487.813721 1431.003906,2490.790039 
	C1431.140991,2494.589844 1432.130127,2497.735596 1430.531494,2501.413086 
z"
        />
        <path
          fill="#B67247"
          opacity="1.000000"
          stroke="none"
          d="
M1410.381348,2432.116699 
	C1414.328491,2428.081787 1418.284302,2431.164795 1421.241333,2433.413086 
	C1424.009399,2435.517578 1426.393311,2435.339600 1429.648682,2435.564697 
	C1429.661133,2440.893066 1424.497925,2443.145752 1423.400269,2447.760986 
	C1423.067017,2449.161377 1421.695435,2449.621094 1420.250000,2449.216797 
	C1418.714600,2448.787354 1418.265381,2447.416260 1418.260620,2446.152344 
	C1418.238403,2440.275146 1417.404297,2434.929932 1410.381348,2432.116699 
z"
        />
        <path
          fill="#D89066"
          opacity="1.000000"
          stroke="none"
          d="
M1394.424438,2459.541016 
	C1390.223267,2452.920166 1392.502197,2446.567627 1397.189575,2440.063965 
	C1398.251221,2446.309570 1395.759521,2452.525635 1394.424438,2459.541016 
z"
        />
        <path
          fill="#8C5B43"
          opacity="1.000000"
          stroke="none"
          d="
M1554.634644,2566.361328 
	C1550.856323,2564.492432 1552.428101,2557.118164 1546.167358,2559.398682 
	C1546.552490,2555.013184 1544.256104,2551.085205 1544.014648,2546.803711 
	C1543.977905,2546.151611 1544.036133,2545.253906 1543.652832,2544.897461 
	C1537.130737,2538.834229 1538.556641,2530.436768 1536.584229,2522.264893 
	C1535.389771,2520.893066 1535.054565,2520.026855 1535.433838,2518.365723 
	C1544.088501,2516.052490 1542.320312,2523.276611 1544.103638,2526.946289 
	C1543.957153,2520.892334 1538.063232,2514.464844 1544.839600,2507.917969 
	C1553.760498,2516.387939 1556.371582,2527.805176 1560.874756,2538.887695 
	C1561.027588,2540.204834 1561.009033,2541.253418 1561.770874,2541.327881 
	C1563.078247,2541.456055 1561.602051,2541.825684 1562.179321,2540.025879 
	C1567.463989,2537.906494 1569.174316,2542.507568 1572.656494,2545.048340 
	C1573.337769,2545.959473 1573.577026,2546.351074 1574.103027,2547.371094 
	C1578.407959,2555.403564 1584.327637,2557.233887 1591.699829,2551.702393 
	C1596.039062,2548.446777 1598.796631,2548.809326 1601.774292,2552.161133 
	C1605.108765,2555.914551 1608.705688,2556.655518 1613.282715,2554.914307 
	C1616.063721,2553.856445 1619.770996,2552.661133 1621.931763,2554.465576 
	C1630.283325,2561.438721 1639.932007,2566.876465 1646.991943,2576.071289 
	C1644.279663,2584.132080 1638.373291,2582.070312 1632.297852,2581.466309 
	C1626.833740,2579.035889 1623.072632,2574.644775 1617.720703,2573.857666 
	C1615.111938,2573.473877 1616.951050,2568.339111 1612.948486,2570.377441 
	C1609.244263,2572.264160 1611.998413,2575.054688 1611.657349,2577.364502 
	C1610.777100,2583.329590 1610.744141,2589.288330 1613.762939,2595.552734 
	C1615.011597,2601.691650 1615.674805,2607.220459 1617.649780,2612.541504 
	C1618.675293,2615.304443 1618.571411,2618.892334 1615.223022,2620.291016 
	C1611.429077,2621.876221 1610.033203,2618.438232 1608.678345,2615.888672 
	C1605.859375,2610.584229 1604.484009,2604.772217 1603.313721,2598.930664 
	C1601.996094,2592.354004 1599.380371,2586.499756 1593.810181,2580.783447 
	C1596.087524,2587.149658 1599.839844,2591.753418 1599.331055,2598.375977 
	C1592.484863,2605.363770 1590.708130,2605.462158 1586.184692,2597.884277 
	C1582.959473,2592.481689 1578.926025,2587.806641 1574.739380,2582.319336 
	C1574.014526,2581.156250 1573.579590,2580.663818 1571.851440,2580.032715 
	C1570.266724,2579.576172 1569.843506,2579.373779 1568.863037,2578.741211 
	C1566.910767,2576.980713 1565.675781,2575.505859 1564.509277,2573.978271 
	C1562.884521,2571.850586 1561.645264,2569.485596 1563.250000,2566.923096 
	C1565.503052,2563.325684 1568.262451,2565.713867 1571.260498,2566.731445 
	C1571.674927,2566.857178 1572.071411,2567.273193 1572.071411,2567.273193 
	C1572.071411,2567.273193 1572.234985,2566.763428 1571.803955,2566.649902 
	C1559.441040,2564.325439 1557.463135,2553.688965 1554.546143,2546.773438 
	C1557.122192,2549.592773 1557.652588,2555.714355 1560.248657,2560.937744 
	C1562.460327,2565.387939 1560.426636,2567.978516 1554.634644,2566.361328 
z"
        />
        <path
          fill="#3A261C"
          opacity="1.000000"
          stroke="none"
          d="
M1586.687012,2647.573486 
	C1581.963623,2646.886963 1579.051147,2647.259521 1579.999756,2653.319580 
	C1569.038574,2653.868896 1558.052612,2647.887207 1551.757690,2638.671143 
	C1548.538208,2633.957520 1545.779785,2628.738037 1539.221680,2626.474121 
	C1543.182007,2635.160645 1543.182007,2635.160645 1540.059326,2643.242188 
	C1533.848389,2647.024170 1533.183960,2640.527832 1529.655151,2637.629395 
	C1528.973267,2636.601807 1528.740845,2636.163330 1528.322998,2635.004150 
	C1526.890625,2629.477539 1526.331299,2624.511475 1524.713135,2619.790039 
	C1523.454590,2616.117432 1524.146484,2612.601318 1525.704712,2609.172363 
	C1526.802979,2606.755371 1527.702881,2603.819092 1530.903198,2603.878662 
	C1534.063599,2603.937500 1535.415161,2606.642578 1535.713745,2609.322021 
	C1536.047729,2612.321533 1538.000732,2614.883301 1537.558716,2618.658447 
	C1537.103394,2620.201172 1536.445068,2620.881348 1536.107544,2621.667725 
	C1535.589355,2622.875244 1535.916992,2621.173096 1536.604370,2622.315186 
	C1536.773315,2622.595459 1536.697754,2622.302002 1536.583008,2620.984863 
	C1539.258057,2615.380371 1535.554688,2611.103027 1535.103760,2606.394287 
	C1534.780029,2603.014404 1533.806885,2599.809326 1539.212891,2599.073242 
	C1548.735474,2609.847900 1560.290771,2618.308105 1565.582153,2631.828125 
	C1563.861328,2625.771973 1558.651733,2621.618164 1557.515381,2614.781738 
	C1557.842407,2612.799316 1557.998779,2611.497070 1558.204956,2609.779053 
	C1564.805420,2609.475342 1566.067017,2616.948486 1570.933960,2618.802246 
	C1573.292114,2615.441650 1568.847168,2612.923584 1570.775269,2609.827148 
	C1573.118408,2608.444336 1574.843018,2611.068604 1577.015015,2611.156738 
	C1580.037476,2611.279053 1579.677002,2608.919678 1579.983398,2606.338379 
	C1586.596802,2608.678955 1586.105103,2615.330811 1587.332031,2620.862549 
	C1587.933716,2623.574951 1587.676514,2626.583740 1588.625244,2630.099121 
	C1589.223511,2636.347900 1592.136963,2642.083984 1586.687012,2647.573486 
z"
        />
        <path
          fill="#805541"
          opacity="1.000000"
          stroke="none"
          d="
M1536.480469,2517.436768 
	C1536.038940,2518.780029 1536.040283,2519.600342 1536.054199,2521.029297 
	C1533.649414,2523.947510 1531.920166,2526.801025 1532.117188,2530.095459 
	C1532.431274,2535.344482 1530.082764,2539.328369 1526.498413,2543.583496 
	C1526.615112,2539.350342 1522.625244,2535.252686 1524.461060,2530.387451 
	C1526.969727,2530.560303 1525.057495,2534.301025 1527.193848,2534.275391 
	C1529.777100,2534.244385 1529.789185,2531.878662 1530.013916,2529.975342 
	C1530.710938,2524.071045 1526.725220,2519.608398 1522.600464,2518.283691 
	C1516.561646,2516.343750 1515.385498,2512.281494 1513.878540,2506.840332 
	C1514.842285,2501.707764 1512.464355,2497.740967 1511.956787,2492.716064 
	C1511.892578,2489.815918 1511.926270,2487.711182 1511.942261,2484.815430 
	C1509.869751,2481.340088 1510.478394,2477.798340 1508.586182,2474.378906 
	C1508.192871,2471.621338 1506.397339,2469.550049 1507.917480,2466.748047 
	C1511.617310,2470.605957 1512.023560,2465.958252 1514.004639,2464.185547 
	C1515.342773,2463.229736 1516.291016,2462.950928 1518.060791,2463.416992 
	C1520.737305,2465.557373 1521.610474,2467.798096 1521.092163,2471.105957 
	C1522.521484,2479.232178 1522.248047,2487.123779 1527.888062,2493.625488 
	C1533.531250,2500.130859 1537.778931,2507.658203 1536.480469,2517.436768 
z"
        />
        <path
          fill="#704A3A"
          opacity="1.000000"
          stroke="none"
          d="
M1586.527588,2630.481689 
	C1585.984985,2626.708984 1580.399902,2624.187256 1585.272095,2620.531006 
	C1585.715332,2620.198486 1585.584106,2618.025146 1585.251953,2617.894043 
	C1579.643066,2615.685303 1583.610352,2609.967773 1580.531250,2606.173340 
	C1580.114014,2603.259766 1580.021606,2600.628174 1578.292236,2599.080322 
	C1573.404785,2594.705322 1573.796143,2589.156250 1574.220947,2582.641357 
	C1581.642090,2582.659912 1583.610962,2589.420654 1587.595581,2593.958740 
	C1587.968628,2594.383301 1588.042725,2595.261963 1587.942505,2595.879150 
	C1587.376099,2599.365234 1591.529541,2599.010742 1592.606079,2601.646973 
	C1593.353027,2603.475830 1594.163574,2597.159180 1597.694946,2599.842285 
	C1599.671143,2599.918213 1601.350952,2600.677246 1601.097046,2601.890625 
	C1599.253418,2610.697754 1600.474487,2620.155518 1596.121094,2628.365967 
	C1594.324707,2631.754150 1591.470947,2635.212891 1586.527588,2630.481689 
z"
        />
        <path
          fill="#6C5246"
          opacity="1.000000"
          stroke="none"
          d="
M1557.117920,2613.706299 
	C1562.450195,2618.666016 1564.255615,2625.539795 1569.941895,2629.477539 
	C1571.910645,2630.840332 1572.656250,2633.902344 1571.220825,2635.826904 
	C1569.078979,2638.698975 1566.391479,2635.955811 1564.077026,2635.262207 
	C1563.022705,2634.946045 1561.822388,2633.521973 1561.562256,2632.398193 
	C1558.929810,2621.022949 1550.060303,2614.399902 1542.346558,2606.912109 
	C1540.783325,2605.394531 1540.117188,2603.673096 1539.924561,2600.878418 
	C1537.055054,2596.677734 1532.631470,2594.317383 1532.394897,2588.473145 
	C1532.615845,2587.406494 1532.768188,2586.999268 1533.310791,2586.093018 
	C1537.611938,2583.652100 1534.098145,2580.638184 1535.373535,2577.122559 
	C1545.004517,2579.208984 1549.728638,2585.552734 1550.042480,2595.149658 
	C1550.263184,2601.897461 1552.276001,2608.011230 1557.117920,2613.706299 
z"
        />
        <path
          fill="#704A3A"
          opacity="1.000000"
          stroke="none"
          d="
M1554.112793,2566.685547 
	C1557.191040,2565.835693 1560.908691,2566.345459 1558.200928,2561.283936 
	C1555.437378,2556.117920 1554.829834,2550.068604 1551.043335,2545.241211 
	C1548.950073,2542.572266 1549.953857,2538.994385 1550.846436,2533.545410 
	C1554.090576,2540.892578 1556.897217,2546.200928 1558.795410,2551.816406 
	C1561.109253,2558.661377 1564.480957,2563.516846 1572.522827,2563.477539 
	C1573.893066,2563.470703 1573.937012,2565.407959 1573.992310,2567.368652 
	C1572.474854,2568.535889 1571.104980,2569.624023 1569.367432,2569.083740 
	C1567.809937,2568.600098 1566.508057,2565.306885 1564.727417,2568.067627 
	C1563.438232,2570.066650 1565.445923,2571.528809 1567.109253,2572.639648 
	C1568.505981,2573.572021 1569.470093,2574.828125 1569.919312,2577.181152 
	C1570.444092,2578.340820 1570.872925,2578.831299 1571.660400,2579.648438 
	C1572.038330,2579.958252 1572.037720,2579.969482 1572.062378,2579.947754 
	C1571.644897,2582.761719 1572.679321,2585.802002 1570.949585,2588.475830 
	C1558.754761,2582.169434 1554.546509,2576.929199 1554.112793,2566.685547 
z"
        />
        <path
          fill="#442F21"
          opacity="1.000000"
          stroke="none"
          d="
M1522.279541,2566.613281 
	C1521.142456,2565.906494 1520.675781,2565.490479 1520.107666,2564.523438 
	C1521.798584,2561.925537 1524.365723,2560.214355 1524.076050,2556.677002 
	C1523.859863,2554.038330 1525.078369,2552.221680 1528.774658,2552.177734 
	C1529.967285,2552.440674 1530.438354,2552.710693 1530.402588,2553.618408 
	C1530.304688,2558.626465 1534.908325,2557.863281 1537.119995,2560.114746 
	C1539.840332,2562.883545 1539.962402,2565.060791 1536.305176,2567.785400 
	C1535.564087,2568.532715 1535.178833,2568.729980 1534.153076,2568.959473 
	C1529.406738,2571.319824 1524.487671,2578.089111 1522.279541,2566.613281 
z"
        />
        <path
          fill="#6C5246"
          opacity="1.000000"
          stroke="none"
          d="
M1533.111572,2567.993652 
	C1533.995483,2568.009766 1534.975708,2568.151611 1535.462402,2568.243408 
	C1535.958618,2570.380127 1535.967896,2572.424805 1535.979980,2575.243652 
	C1535.982666,2576.017578 1536.001587,2575.997559 1536.009521,2575.989502 
	C1533.889893,2577.581543 1531.325928,2578.002930 1529.908569,2575.715088 
	C1528.064209,2572.738281 1529.829834,2570.086426 1533.111572,2567.993652 
z"
        />
        <path
          fill="#805541"
          opacity="1.000000"
          stroke="none"
          d="
M1530.294189,2553.981445 
	C1529.847168,2553.905273 1529.798340,2553.554688 1529.622803,2552.687012 
	C1530.016235,2551.893555 1530.859253,2551.264648 1531.003906,2551.397217 
	C1531.891235,2552.210449 1531.389404,2553.006348 1530.294189,2553.981445 
z"
        />
        <path
          fill="#2C2520"
          opacity="1.000000"
          stroke="none"
          d="
M1494.537842,2414.590820 
	C1493.513184,2408.394043 1493.803711,2402.813721 1496.929199,2397.427002 
	C1500.498779,2395.793457 1501.859985,2401.800049 1507.174316,2398.119873 
	C1503.254028,2406.695801 1504.134277,2413.348389 1503.975220,2420.798096 
	C1501.532349,2424.067139 1499.179443,2422.959961 1496.430176,2420.875488 
	C1495.682129,2418.482422 1495.335083,2416.774170 1494.537842,2414.590820 
z"
        />
        <path
          fill="#2C2520"
          opacity="1.000000"
          stroke="none"
          d="
M1482.476318,2404.138916 
	C1480.808594,2397.171143 1487.151611,2392.771484 1487.284302,2386.225586 
	C1485.744629,2395.641846 1492.381226,2403.269287 1492.087646,2413.091064 
	C1489.861938,2413.802734 1488.399902,2412.821777 1487.856689,2410.559570 
	C1487.201294,2407.831055 1485.158447,2406.142822 1482.476318,2404.138916 
z"
        />
        <path
          fill="#C4BDAE"
          opacity="1.000000"
          stroke="none"
          d="
M1495.141479,2420.186035 
	C1497.914551,2421.945068 1500.341919,2421.405762 1503.251709,2421.702148 
	C1504.848877,2421.797852 1505.758667,2421.925537 1507.355225,2421.947266 
	C1509.703247,2420.687012 1511.452759,2420.455078 1513.668457,2422.196289 
	C1516.079712,2424.367920 1518.356567,2424.044189 1521.277344,2423.903320 
	C1522.867676,2423.161133 1523.884155,2422.930420 1525.686157,2423.597656 
	C1526.411987,2424.593506 1526.454956,2425.139893 1525.975342,2426.327393 
	C1523.387817,2428.217529 1521.277466,2428.286133 1518.411987,2426.779785 
	C1505.719727,2424.852051 1493.738647,2423.356445 1480.918213,2421.682617 
	C1479.852051,2421.187988 1479.625488,2420.872070 1479.740234,2420.082520 
	C1484.805664,2419.800049 1489.529785,2419.990723 1495.141479,2420.186035 
z"
        />
        <path
          fill="#2C2520"
          opacity="1.000000"
          stroke="none"
          d="
M1448.177734,2419.723633 
	C1446.857666,2420.251709 1446.041504,2420.198975 1444.616211,2420.067383 
	C1443.151367,2413.889404 1437.424194,2417.423340 1434.508301,2415.246826 
	C1443.120728,2413.399658 1449.100220,2413.490967 1457.381470,2415.770996 
	C1455.095947,2419.232178 1450.895508,2416.980225 1448.177734,2419.723633 
z"
        />
        <path
          fill="#B6AFA3"
          opacity="1.000000"
          stroke="none"
          d="
M1479.546265,2419.111816 
	C1480.081543,2419.609619 1480.115234,2420.556885 1480.096924,2421.030518 
	C1475.762939,2422.320557 1471.772827,2420.001709 1466.737061,2420.283203 
	C1464.288330,2421.155029 1462.811646,2420.954102 1461.457397,2418.718750 
	C1467.286743,2416.866211 1473.142090,2417.907959 1479.546265,2419.111816 
z"
        />
        <path
          fill="#040407"
          opacity="1.000000"
          stroke="none"
          d="
M1388.995605,2368.708008 
	C1389.010132,2371.761230 1387.563599,2371.565430 1386.025146,2370.482910 
	C1384.905273,2369.694824 1384.200317,2368.341553 1385.491455,2367.274902 
	C1386.687622,2366.286377 1387.840454,2367.216064 1388.995605,2368.708008 
z"
        />
        <path
          fill="#2C2520"
          opacity="1.000000"
          stroke="none"
          d="
M1477.887695,2404.881836 
	C1478.722046,2404.110352 1479.601074,2403.997314 1481.140381,2403.877930 
	C1481.809082,2405.685303 1482.622925,2408.132324 1480.303467,2408.754883 
	C1478.072876,2409.353760 1478.307129,2406.960693 1477.887695,2404.881836 
z"
        />
        <path
          fill="#2C2520"
          opacity="1.000000"
          stroke="none"
          d="
M1403.984009,2378.716553 
	C1403.832520,2380.874756 1404.384399,2382.581543 1402.861328,2383.911621 
	C1401.683594,2381.400635 1401.599976,2378.934082 1402.645508,2376.435547 
	C1402.688721,2376.332520 1403.226196,2376.288330 1403.255737,2376.349854 
	C1403.526855,2376.914307 1403.736328,2377.507812 1403.984009,2378.716553 
z"
        />
        <path
          fill="#2C2520"
          opacity="1.000000"
          stroke="none"
          d="
M1460.954102,2418.251953 
	C1461.981323,2419.307861 1463.148560,2419.898193 1465.280273,2420.205566 
	C1465.638550,2421.362061 1465.324097,2422.357422 1464.547974,2423.620117 
	C1463.385132,2422.741455 1462.293579,2422.225342 1460.425537,2421.808105 
	C1460.069092,2420.617188 1460.314575,2419.608154 1460.954102,2418.251953 
z"
        />
        <path
          fill="#2C2520"
          opacity="1.000000"
          stroke="none"
          d="
M1468.882690,2407.916748 
	C1469.633667,2406.552246 1470.388428,2406.529541 1471.055176,2407.510254 
	C1471.194702,2407.715576 1471.190308,2408.189453 1471.045044,2408.388428 
	C1470.348755,2409.341797 1469.593750,2409.302002 1468.882690,2407.916748 
z"
        />
        <path
          fill="#2C2520"
          opacity="1.000000"
          stroke="none"
          d="
M1449.566406,2421.926758 
	C1449.657959,2422.686035 1449.446899,2423.615479 1448.409790,2422.211914 
	C1448.379517,2421.821533 1449.172485,2421.874268 1449.566406,2421.926758 
z"
        />
        <path
          fill="#E59F70"
          opacity="1.000000"
          stroke="none"
          d="
M1430.066162,2501.982422 
	C1430.372681,2498.424805 1427.337891,2495.481445 1428.412476,2491.083496 
	C1429.319092,2488.465088 1431.546509,2487.451904 1431.635742,2484.544922 
	C1433.368896,2479.153809 1434.670410,2474.145264 1439.099854,2470.053223 
	C1452.012695,2473.365234 1455.028442,2483.190918 1445.515991,2491.030518 
	C1441.371704,2494.446045 1439.162354,2499.919434 1433.005615,2501.803711 
	C1431.922241,2502.129395 1431.547729,2502.420410 1430.588745,2502.305420 
	C1430.004395,2501.899658 1430.018311,2502.006348 1430.066162,2501.982422 
z"
        />
        <path
          fill="#ECA777"
          opacity="1.000000"
          stroke="none"
          d="
M1463.751099,2506.976074 
	C1461.611328,2507.534424 1459.606567,2507.343750 1456.863037,2506.945312 
	C1451.555542,2501.231445 1451.555542,2501.231445 1458.014893,2492.334473 
	C1461.884766,2496.325195 1464.494263,2500.509766 1463.751099,2506.976074 
z"
        />
        <path
          fill="#603A24"
          opacity="1.000000"
          stroke="none"
          d="
M1430.191162,2502.254883 
	C1430.383911,2501.810303 1430.763306,2501.720947 1431.719849,2501.734863 
	C1431.743042,2502.309082 1431.407837,2503.323730 1430.191162,2502.254883 
z"
        />
        <path
          fill="#B17752"
          opacity="1.000000"
          stroke="none"
          d="
M1517.755981,2427.062744 
	C1519.932617,2426.324951 1522.164307,2426.302246 1525.226074,2426.398926 
	C1527.034912,2426.181396 1528.013916,2425.844971 1529.773682,2425.407715 
	C1534.197632,2425.490967 1538.208740,2424.739990 1539.865601,2429.816895 
	C1536.931030,2432.858643 1533.716919,2432.972900 1529.750610,2433.067139 
	C1521.878052,2434.964844 1518.764526,2444.989502 1522.711182,2453.587646 
	C1523.936890,2456.257812 1526.165283,2458.423096 1526.957642,2461.327148 
	C1527.909424,2464.815674 1527.462769,2468.369141 1523.915894,2469.721924 
	C1520.203247,2471.138184 1520.963745,2466.245605 1518.384033,2464.501465 
	C1517.108643,2463.967773 1516.363281,2463.930420 1515.068115,2463.912598 
	C1509.511841,2451.324463 1512.528076,2439.447998 1517.755981,2427.062744 
z"
        />
        <path
          fill="#2C2520"
          opacity="1.000000"
          stroke="none"
          d="
M1472.291260,2453.905762 
	C1468.739868,2446.524170 1461.573486,2439.788574 1464.360840,2429.168945 
	C1467.793213,2428.291016 1467.327271,2430.975830 1468.239624,2432.975342 
	C1469.670532,2436.110840 1470.273438,2430.098389 1471.937012,2432.298340 
	C1473.351685,2434.169678 1473.768555,2436.436279 1474.112671,2438.712158 
	C1474.206787,2439.333496 1474.449829,2439.999268 1474.820557,2440.499756 
	C1476.359863,2442.579590 1480.782959,2442.343262 1479.468506,2446.775635 
	C1478.404175,2450.363525 1475.414307,2451.607910 1472.291260,2453.905762 
z"
        />
        <path
          fill="#442F21"
          opacity="1.000000"
          stroke="none"
          d="
M1500.916626,2447.069336 
	C1500.857788,2446.965088 1500.975464,2447.173828 1500.916626,2447.069336 
z"
        />
        <path
          fill="#564031"
          opacity="1.000000"
          stroke="none"
          d="
M1508.034668,2474.544434 
	C1512.799438,2475.301758 1514.485718,2478.185791 1512.211060,2483.429688 
	C1509.124146,2483.360596 1506.353027,2482.811768 1504.556763,2486.002686 
	C1502.436035,2481.336426 1507.179565,2478.939697 1508.034668,2474.544434 
z"
        />
        <path
          fill="#F2EBDA"
          opacity="1.000000"
          stroke="none"
          d="
M2120.041260,3012.701172 
	C2120.688721,3009.931396 2121.619141,3007.917480 2122.027100,3005.802734 
	C2122.539795,3003.143555 2124.064697,3002.119629 2127.293457,3002.047852 
	C2129.348877,3002.967773 2130.946289,3004.641113 2132.374512,3003.361328 
	C2139.709961,2996.788574 2149.843994,2993.747803 2155.893555,2984.529053 
	C2156.647217,2983.711670 2156.968506,2983.407471 2157.816406,2982.712402 
	C2159.789551,2981.440674 2161.332764,2980.682129 2162.681152,2978.669189 
	C2167.806885,2971.607910 2172.664062,2965.183594 2180.528809,2960.847900 
	C2183.388916,2959.339844 2186.245117,2959.116943 2188.136230,2956.051270 
	C2188.907471,2955.228027 2189.238525,2954.916504 2190.120117,2954.195312 
	C2197.819092,2948.128174 2204.472168,2950.828125 2211.519287,2955.496582 
	C2211.805176,2960.900391 2212.470459,2965.723389 2209.289795,2970.345947 
	C2206.118408,2974.954834 2209.134521,2979.325439 2212.164062,2982.944580 
	C2215.604980,2987.055420 2218.281738,2986.310303 2219.945068,2980.824219 
	C2220.537842,2978.868896 2220.627441,2976.682861 2222.953125,2976.177979 
	C2230.865479,2974.459473 2236.403564,2967.586670 2244.376221,2966.250977 
	C2249.343018,2965.419189 2251.466797,2958.668701 2258.408203,2960.626953 
	C2252.854736,2965.954102 2250.676514,2973.244629 2244.145508,2977.549316 
	C2241.337158,2979.400391 2239.119873,2982.978271 2241.531982,2987.581543 
	C2243.166992,2989.406250 2243.465088,2990.936523 2242.470215,2993.302734 
	C2241.056152,3002.151123 2233.201172,3001.911621 2228.248047,3005.186768 
	C2227.875488,3005.433594 2226.980469,3005.028564 2226.389160,3004.780762 
	C2224.194092,3003.860596 2223.722412,2999.354004 2220.156738,3001.479004 
	C2217.784668,3002.892578 2217.230713,3005.833984 2215.897949,3008.111328 
	C2214.154297,3011.091064 2212.005859,3014.020264 2208.312988,3013.799072 
	C2202.439697,3013.447021 2199.987793,3017.373779 2197.787842,3021.458984 
	C2193.740479,3028.974365 2187.867432,3033.392822 2179.227051,3034.200439 
	C2175.871582,3034.514160 2172.729980,3035.969727 2170.736816,3038.895264 
	C2167.167236,3044.134033 2162.882568,3048.717773 2158.362305,3053.116455 
	C2157.402344,3054.050293 2156.442627,3055.247314 2157.353027,3056.568604 
	C2157.819092,3057.245361 2159.280029,3058.005371 2159.823730,3057.746582 
	C2169.372314,3053.198975 2173.680664,3059.439697 2177.808105,3066.245117 
	C2178.090088,3066.710449 2178.940186,3066.831299 2179.527100,3067.112793 
	C2182.272949,3066.532959 2181.817627,3064.260254 2182.038086,3062.370117 
	C2182.648193,3057.140381 2185.814941,3053.256348 2190.546875,3052.538330 
	C2195.278320,3051.820557 2199.020020,3049.924805 2202.526855,3046.584229 
	C2204.764160,3050.998047 2203.866211,3053.751709 2200.281494,3055.582764 
	C2194.335449,3058.619873 2188.737793,3061.806396 2186.952148,3069.119873 
	C2186.559814,3070.727295 2183.953369,3071.670654 2182.788574,3073.234375 
	C2177.543701,3080.275635 2168.854980,3084.809814 2167.283203,3094.582520 
	C2167.108154,3095.671631 2165.801758,3096.839600 2164.727295,3097.479248 
	C2159.462646,3100.612793 2156.238770,3105.778320 2152.070557,3109.989746 
	C2150.681152,3111.393799 2149.628418,3113.888916 2147.492676,3113.121338 
	C2140.902588,3110.752686 2135.082275,3113.174805 2129.138184,3115.304199 
	C2127.498779,3115.892090 2125.884766,3115.648682 2124.251953,3115.161621 
	C2122.561523,3114.657227 2120.781738,3114.477295 2120.577148,3116.882568 
	C2120.399170,3118.978516 2122.032959,3119.729248 2123.762451,3119.917725 
	C2129.237549,3120.513916 2134.104248,3125.023682 2140.088135,3122.444824 
	C2140.640381,3122.206787 2141.648682,3122.157227 2142.017334,3122.491943 
	C2150.974609,3130.619629 2159.632080,3121.915771 2169.237305,3122.083496 
	C2169.557617,3126.167725 2167.157959,3129.324707 2165.597168,3132.366943 
	C2162.655273,3133.527588 2162.435791,3131.498291 2161.596924,3130.223877 
	C2159.816162,3127.518799 2157.910400,3127.949951 2156.114990,3130.211670 
	C2154.526123,3132.212891 2153.944824,3135.126221 2150.919678,3135.803467 
	C2148.699707,3136.300537 2145.822021,3136.480713 2146.675537,3139.814697 
	C2147.304932,3142.273926 2149.700684,3143.104980 2152.087646,3143.347412 
	C2153.643555,3143.505615 2155.193115,3142.117188 2157.474121,3143.781250 
	C2155.725830,3149.020996 2154.738770,3154.785156 2153.973633,3161.328613 
	C2148.755615,3164.051270 2145.143311,3159.799805 2140.054199,3158.507324 
	C2136.364258,3156.955078 2133.503662,3155.295410 2129.838867,3153.636719 
	C2127.203857,3151.728271 2125.751221,3149.845459 2125.710693,3146.531982 
	C2130.956055,3141.091797 2127.772949,3138.014404 2123.888672,3134.737305 
	C2114.111328,3126.488525 2101.560791,3123.469971 2090.336426,3116.535400 
	C2082.717285,3103.540039 2078.995850,3089.971436 2077.739502,3074.819336 
	C2086.886719,3067.332520 2098.622314,3067.278076 2109.300537,3062.333740 
	C2114.545166,3062.056885 2119.151855,3062.231934 2123.591309,3058.798340 
	C2126.756836,3057.314941 2129.556885,3057.042236 2132.881836,3056.064453 
	C2144.686279,3055.097900 2149.653320,3046.535645 2153.965820,3040.003906 
	C2148.330811,3044.818848 2140.771240,3048.019043 2131.484375,3044.635498 
	C2124.626953,3042.136963 2120.216309,3046.799316 2114.874268,3050.772705 
	C2113.818604,3051.320557 2113.367920,3051.484375 2112.203125,3051.746582 
	C2103.816162,3048.281738 2102.255615,3044.780029 2106.356934,3038.574707 
	C2109.242676,3034.208984 2112.393555,3029.536133 2116.781494,3026.841553 
	C2122.903076,3023.082520 2122.441650,3018.729248 2120.041260,3012.701172 
z"
        />
        <path
          fill="#1B1E22"
          opacity="1.000000"
          stroke="none"
          d="
M2140.761230,3174.843262 
	C2133.084961,3175.031250 2125.568359,3174.073975 2118.256592,3171.817139 
	C2102.730469,3167.024170 2087.176270,3162.320312 2070.731445,3161.972168 
	C2067.597168,3161.905762 2064.830322,3160.692139 2062.327393,3159.104492 
	C2055.963379,3155.068115 2049.895508,3155.134033 2043.205444,3159.869629 
	C2042.350464,3158.497803 2042.194458,3156.992432 2041.960938,3154.732422 
	C2042.043823,3151.677002 2041.931519,3149.364014 2042.292725,3146.383057 
	C2042.729736,3144.209229 2043.065918,3142.719727 2043.513184,3140.473389 
	C2049.493164,3138.214844 2055.797852,3137.916748 2054.241455,3128.454102 
	C2053.425049,3123.489746 2057.512207,3122.071777 2061.234375,3125.942627 
	C2064.239746,3129.068359 2066.480713,3131.192383 2071.308838,3128.568359 
	C2075.461670,3126.311523 2075.979248,3130.865967 2076.730469,3133.516602 
	C2078.239014,3138.838867 2078.508789,3144.720703 2084.719971,3148.804199 
	C2088.056396,3140.091064 2082.141357,3131.160156 2087.554688,3123.059082 
	C2088.865234,3122.656006 2089.613281,3122.675537 2090.902832,3122.544189 
	C2095.758545,3119.685791 2096.864502,3123.749512 2099.212158,3126.603516 
	C2101.676270,3133.254150 2098.716064,3139.095703 2099.144531,3145.020020 
	C2099.479248,3149.651123 2100.846436,3155.126221 2106.252441,3153.317627 
	C2115.757324,3150.137695 2121.347656,3154.507324 2126.893799,3160.710938 
	C2127.997559,3161.945801 2129.497070,3162.744385 2131.908203,3163.252686 
	C2137.707275,3165.167480 2139.848145,3169.172852 2140.761230,3174.843262 
z"
        />
        <path
          fill="#50494B"
          opacity="1.000000"
          stroke="none"
          d="
M2087.855225,3121.955566 
	C2088.177979,3129.926514 2087.380615,3137.228271 2089.556885,3144.442383 
	C2090.356445,3147.093018 2091.454834,3150.468750 2088.632812,3152.775635 
	C2086.885498,3154.204346 2080.038330,3151.323975 2078.288086,3148.413086 
	C2077.107422,3146.449951 2076.569092,3144.111572 2075.614502,3141.999268 
	C2073.928467,3138.269287 2074.176514,3134.209473 2073.609863,3130.286377 
	C2071.303223,3130.137939 2071.287354,3132.239014 2070.284912,3133.312500 
	C2066.983643,3136.848877 2065.349121,3136.788818 2062.551514,3133.064453 
	C2060.796143,3130.727783 2060.452393,3127.450928 2057.136475,3125.323242 
	C2055.177490,3128.226562 2056.815430,3130.802246 2057.340820,3133.448486 
	C2058.936279,3141.480225 2057.216309,3143.028564 2049.049561,3141.118164 
	C2047.763550,3140.817139 2046.534424,3140.273682 2044.451660,3139.779297 
	C2043.825806,3131.758301 2044.027222,3123.800537 2044.121582,3114.922363 
	C2045.334839,3112.725586 2045.464722,3111.066650 2045.484619,3108.748779 
	C2051.593506,3100.301514 2052.226074,3090.829102 2052.354004,3081.317383 
	C2056.459473,3081.669189 2056.296387,3084.142090 2055.846680,3085.717285 
	C2053.718994,3093.168213 2057.740234,3097.513184 2063.083496,3101.470947 
	C2070.226074,3106.762207 2077.288574,3112.163574 2084.312012,3117.612305 
	C2085.586182,3118.600342 2086.508545,3120.041748 2087.855225,3121.955566 
z"
        />
        <path
          fill="#E9E1D0"
          opacity="1.000000"
          stroke="none"
          d="
M2119.383301,3012.332520 
	C2123.736816,3014.029297 2126.255127,3016.819092 2125.955811,3021.439453 
	C2125.797852,3023.877197 2125.322998,3026.687988 2122.809570,3027.100586 
	C2115.240967,3028.342773 2113.145020,3035.493408 2108.581787,3039.855225 
	C2104.774170,3043.494141 2105.691650,3045.590576 2111.454590,3050.008057 
	C2114.050293,3053.465332 2111.836426,3055.284180 2109.339844,3056.237793 
	C2098.266357,3060.468750 2086.600098,3062.719238 2075.247070,3066.031006 
	C2074.032959,3066.385254 2072.724365,3065.422119 2070.910645,3064.362793 
	C2069.114746,3061.344971 2067.939209,3058.819336 2066.656250,3055.617188 
	C2072.710938,3053.035156 2079.873535,3053.095947 2083.466797,3046.136963 
	C2083.802979,3045.486084 2085.472656,3044.775635 2085.956055,3045.059326 
	C2094.737549,3050.216064 2096.264404,3041.851807 2099.788818,3037.560303 
	C2100.798828,3036.330322 2101.185059,3034.520508 2102.979248,3034.113525 
	C2109.918213,3032.541016 2111.271729,3026.222656 2113.864502,3021.106689 
	C2115.347412,3018.180908 2116.014160,3014.842529 2119.383301,3012.332520 
z"
        />
        <path
          fill="#CBC5B5"
          opacity="1.000000"
          stroke="none"
          d="
M2070.417969,3064.505371 
	C2074.900879,3064.018555 2079.589355,3064.640625 2082.551025,3059.658447 
	C2083.437744,3058.166504 2085.344727,3058.595947 2087.033447,3058.549805 
	C2095.834717,3058.308594 2103.988525,3055.567139 2111.955078,3050.826660 
	C2112.167725,3050.129150 2113.079346,3050.056641 2113.534668,3050.018555 
	C2118.567383,3046.824951 2123.594727,3045.829102 2128.983887,3047.113281 
	C2133.759766,3048.250977 2136.709473,3050.759521 2134.440430,3057.085938 
	C2131.570312,3060.518555 2128.415527,3059.705566 2124.785156,3060.047119 
	C2120.924072,3062.548096 2116.991211,3062.732666 2112.712646,3063.972656 
	C2101.477295,3068.587402 2090.685791,3072.281250 2078.457520,3074.021973 
	C2074.109375,3071.962158 2073.336914,3067.746094 2070.417969,3064.505371 
z"
        />
        <path
          fill="#D1C9BA"
          opacity="1.000000"
          stroke="none"
          d="
M2098.393066,3128.617920 
	C2096.072021,3126.453613 2094.207764,3124.848145 2091.894043,3122.818115 
	C2091.892090,3120.452393 2090.439941,3119.089355 2089.864258,3116.779297 
	C2095.561035,3114.893066 2099.489990,3119.148193 2104.291260,3120.711914 
	C2113.048584,3123.562988 2120.092041,3128.801514 2127.496826,3133.726562 
	C2129.840820,3135.285400 2131.195801,3137.155273 2132.222412,3139.789062 
	C2134.383545,3145.334229 2131.500488,3146.147705 2125.751465,3145.823486 
	C2120.503418,3141.986572 2116.744385,3138.080322 2110.015625,3138.478271 
	C2104.362549,3136.980225 2100.903564,3133.878662 2098.393066,3128.617920 
z"
        />
        <path
          fill="#817A79"
          opacity="1.000000"
          stroke="none"
          d="
M2041.915039,3146.047852 
	C2043.710938,3148.009277 2043.951416,3150.386719 2042.359375,3153.412842 
	C2039.332397,3151.592529 2039.629639,3149.039307 2041.915039,3146.047852 
z"
        />
        <path
          fill="#1B2B3A"
          opacity="1.000000"
          stroke="none"
          d="
M2296.800293,3229.431396 
	C2298.048096,3234.845215 2300.511475,3239.262451 2300.574463,3244.300781 
	C2300.619873,3247.921631 2303.042969,3250.285400 2305.708984,3253.270508 
	C2306.131104,3257.278320 2306.173096,3260.700195 2306.259766,3264.967773 
	C2299.219971,3265.723145 2291.993164,3263.218506 2285.183350,3267.645264 
	C2283.612793,3268.666260 2281.211914,3267.741455 2279.510498,3266.605469 
	C2268.210938,3259.061523 2254.598633,3258.416016 2242.134521,3254.427734 
	C2235.989014,3252.461182 2229.667725,3250.062256 2222.913574,3249.638428 
	C2218.409424,3249.355713 2217.327148,3244.858643 2214.189697,3241.053955 
	C2222.268311,3239.468750 2229.072998,3239.404053 2235.808594,3241.003418 
	C2240.790527,3242.186523 2245.625244,3242.448975 2250.513672,3240.836182 
	C2254.728271,3239.445801 2259.092285,3240.246338 2263.383545,3239.973389 
	C2265.599121,3239.832520 2268.591553,3240.551758 2269.185059,3237.521240 
	C2269.853760,3234.106689 2266.687500,3234.360596 2264.568359,3233.789062 
	C2261.695068,3233.014893 2258.914795,3231.704590 2255.839355,3232.034668 
	C2251.733398,3232.475342 2247.796875,3231.341064 2244.179443,3229.903076 
	C2237.668457,3227.313721 2231.252441,3225.571045 2224.091064,3225.748535 
	C2218.637207,3225.883789 2214.538086,3222.182617 2210.283691,3217.652344 
	C2214.726562,3215.166504 2218.649170,3215.832764 2222.396973,3216.501465 
	C2234.095703,3218.588135 2234.080566,3218.672607 2238.369873,3207.527344 
	C2239.938721,3206.180176 2241.207764,3206.093750 2243.127930,3206.464355 
	C2244.805420,3206.910400 2245.664795,3208.318115 2247.630371,3207.084473 
	C2263.023193,3208.841309 2276.196533,3216.052490 2291.203613,3219.532715 
	C2292.312500,3219.928955 2292.740479,3220.123535 2293.739746,3220.725098 
	C2296.492432,3223.271729 2296.182617,3226.138916 2296.800293,3229.431396 
z"
        />
        <path
          fill="#2C2520"
          opacity="1.000000"
          stroke="none"
          d="
M2337.794678,3292.259766 
	C2337.883545,3292.652588 2337.590576,3293.316406 2336.689941,3293.908936 
	C2336.455811,3293.331055 2336.899414,3292.895508 2337.794678,3292.259766 
z"
        />
        <path
          fill="#718A41"
          opacity="1.000000"
          stroke="none"
          d="
M2244.266846,3208.235840 
	C2242.467285,3207.496338 2241.211182,3207.263916 2239.342041,3206.893555 
	C2213.216064,3197.298340 2187.019531,3189.957275 2161.121094,3181.692139 
	C2156.708252,3180.284424 2152.276367,3178.935303 2147.089355,3177.528320 
	C2145.052002,3177.764160 2143.861328,3177.598877 2142.366211,3176.359619 
	C2141.943604,3175.901123 2141.327393,3175.649414 2140.999023,3175.639893 
	C2138.148926,3172.724854 2135.627441,3169.819336 2132.572754,3166.591309 
	C2129.030762,3163.006348 2127.711670,3159.295898 2129.520996,3154.297363 
	C2133.465088,3151.553223 2135.721436,3154.220215 2138.876221,3155.997070 
	C2147.368408,3165.395508 2157.449951,3171.093994 2169.385986,3175.121582 
	C2174.298096,3177.730469 2178.601562,3180.067627 2182.708008,3182.710938 
	C2185.530762,3184.528564 2188.097900,3184.801758 2191.458740,3182.403809 
	C2192.749023,3181.390869 2193.496826,3180.871338 2195.030762,3180.421387 
	C2200.296143,3179.943848 2203.502441,3186.558350 2209.260498,3182.481934 
	C2213.682861,3179.791504 2215.755371,3174.486084 2221.994629,3175.411621 
	C2224.883789,3178.326416 2224.855957,3181.786621 2226.096191,3184.755615 
	C2227.227295,3187.463379 2228.359375,3190.856445 2231.654053,3190.870605 
	C2236.049316,3190.889160 2234.537598,3186.681396 2235.177246,3184.151123 
	C2235.572754,3182.587158 2235.692627,3180.951660 2236.751465,3178.883545 
	C2241.537354,3176.336670 2242.003174,3180.243896 2242.850830,3182.581055 
	C2244.405518,3186.865967 2246.900146,3189.812988 2251.970947,3191.276367 
	C2256.581543,3195.341553 2256.945801,3206.582275 2267.238770,3198.060303 
	C2270.673340,3197.067627 2271.859131,3199.220459 2273.271240,3201.057617 
	C2280.040039,3209.862549 2280.045654,3209.858398 2286.556396,3199.755371 
	C2289.018066,3199.981689 2288.907227,3201.527100 2289.517090,3203.484863 
	C2289.899414,3204.089111 2289.975830,3203.981689 2289.916748,3204.010742 
	C2291.186768,3209.738281 2296.968018,3214.052490 2294.574219,3221.427734 
	C2288.250244,3222.364746 2283.157227,3219.726318 2277.817871,3217.975586 
	C2267.750000,3214.675049 2257.629395,3211.535645 2246.814453,3208.164551 
	C2245.668457,3208.248047 2245.239258,3208.495850 2244.266846,3208.235840 
z"
        />
        <path
          fill="#1B2B3A"
          opacity="1.000000"
          stroke="none"
          d="
M2096.757812,3239.807617 
	C2092.424072,3237.854736 2091.183105,3235.803467 2095.500244,3233.390381 
	C2098.910645,3231.483887 2102.736816,3231.423096 2106.196289,3232.564941 
	C2111.111328,3234.187256 2116.049072,3233.914551 2121.024414,3234.006348 
	C2139.624268,3234.349121 2158.142334,3236.259521 2176.784180,3236.429199 
	C2185.766113,3236.510742 2194.798584,3240.484131 2203.622559,3243.281006 
	C2206.635010,3244.235840 2209.170654,3246.868408 2211.745850,3248.954590 
	C2213.041260,3250.003906 2213.871826,3251.616455 2212.865234,3253.378906 
	C2211.658447,3255.491211 2209.814209,3255.723145 2207.739014,3254.784180 
	C2198.685059,3250.687256 2188.854980,3248.634766 2179.965088,3244.053223 
	C2174.742432,3241.361816 2169.636230,3241.452148 2163.786621,3243.797119 
	C2158.075195,3246.086914 2151.522949,3243.369629 2145.358643,3243.536865 
	C2135.713623,3243.798584 2125.861572,3239.553467 2116.424805,3244.718750 
	C2114.031494,3246.028564 2111.943359,3244.881104 2109.802490,3243.917236 
	C2105.851074,3242.138184 2101.800049,3240.643799 2096.757812,3239.807617 
z"
        />
        <path
          fill="#1B1E22"
          opacity="1.000000"
          stroke="none"
          d="
M2142.037354,3176.508301 
	C2143.119141,3176.140869 2144.294678,3176.380371 2145.897949,3177.058838 
	C2144.714600,3179.707520 2143.266602,3180.127686 2142.037354,3176.508301 
z"
        />
        <path
          fill="#71889C"
          opacity="1.000000"
          stroke="none"
          d="
M1865.427734,2644.487305 
	C1870.746094,2648.541260 1878.052612,2651.383545 1882.750000,2650.524658 
	C1887.047241,2649.739014 1891.478394,2649.206787 1895.531738,2650.338379 
	C1901.256958,2651.936768 1906.650391,2653.941895 1912.179443,2650.052734 
	C1912.629883,2649.736084 1913.547485,2649.778076 1914.123169,2649.991455 
	C1921.600586,2652.762451 1929.043823,2650.011963 1936.499756,2649.954590 
	C1944.473389,2649.893066 1946.123901,2651.235352 1948.148071,2659.001465 
	C1949.973877,2666.006592 1951.820679,2673.020752 1952.068848,2681.172852 
	C1952.252563,2689.210693 1949.097900,2693.746582 1941.585205,2693.542236 
	C1934.249390,2693.342529 1929.787598,2697.587891 1925.915405,2702.670898 
	C1919.477905,2711.120850 1910.665039,2713.510986 1900.046875,2713.161621 
	C1897.086548,2710.533691 1895.010254,2707.763428 1891.101685,2709.986328 
	C1887.203735,2712.203369 1882.832397,2713.622803 1879.505737,2709.711426 
	C1876.119873,2705.730225 1877.447754,2701.130615 1879.988770,2696.974609 
	C1880.677734,2695.847656 1881.123901,2694.633545 1881.782959,2692.704346 
	C1881.524536,2688.155273 1878.943237,2684.406494 1881.517456,2680.440430 
	C1881.680664,2680.188965 1881.778564,2679.677979 1881.637451,2679.477051 
	C1876.610229,2672.331299 1877.021118,2663.207764 1872.967041,2655.706299 
	C1871.058594,2652.174805 1867.860352,2652.143799 1864.426514,2651.933594 
	C1856.719727,2651.462158 1849.421143,2655.050293 1840.795166,2654.026367 
	C1837.021118,2651.580078 1838.402954,2648.008789 1836.460205,2644.293701 
	C1846.532959,2644.293701 1855.507935,2644.293701 1865.427734,2644.487305 
z"
        />
        <path
          fill="#465F75"
          opacity="1.000000"
          stroke="none"
          d="
M1846.987549,2632.291992 
	C1849.474243,2632.250244 1852.072266,2631.372070 1852.562378,2633.805664 
	C1853.022827,2636.091553 1850.536865,2635.928955 1849.085815,2635.873047 
	C1847.123535,2635.797607 1843.903931,2636.251221 1846.987549,2632.291992 
z"
        />
        <path
          fill="#442F21"
          opacity="1.000000"
          stroke="none"
          d="
M1586.118530,2630.583252 
	C1587.889160,2630.445801 1589.721924,2632.452393 1591.442993,2630.310547 
	C1593.472778,2627.783936 1595.747437,2625.314453 1595.946289,2621.700928 
	C1596.287354,2615.509521 1594.347900,2608.802979 1599.839111,2603.648438 
	C1600.165161,2603.342285 1599.380127,2601.852295 1598.661743,2600.462402 
	C1597.511230,2591.588867 1590.936157,2585.398438 1589.046631,2576.736572 
	C1593.166748,2575.814453 1595.824707,2577.474365 1597.830078,2580.178223 
	C1601.562622,2585.210938 1605.046265,2590.305664 1605.936035,2596.875732 
	C1606.988647,2604.649170 1609.294556,2612.114258 1614.030151,2619.468018 
	C1616.442505,2616.733887 1616.232300,2614.219238 1614.815063,2612.009277 
	C1611.960815,2607.558594 1611.870361,2602.651123 1611.724487,2596.839844 
	C1617.088745,2590.796387 1618.791870,2595.232910 1620.309692,2599.466064 
	C1625.421875,2613.724609 1635.945923,2620.915771 1650.591309,2619.273682 
	C1655.026001,2618.776611 1657.305054,2620.199951 1659.376831,2623.755127 
	C1661.659058,2627.671631 1664.160645,2631.505615 1667.030884,2635.392090 
	C1669.053955,2632.681641 1667.270752,2630.473389 1667.515381,2627.589355 
	C1668.074341,2623.777832 1668.884399,2620.934814 1673.595093,2621.582031 
	C1676.298828,2623.284912 1675.703369,2627.292725 1680.053345,2627.143555 
	C1684.322266,2628.214844 1686.291870,2630.772217 1687.592285,2634.823975 
	C1687.930908,2639.439697 1688.963501,2643.270752 1688.992798,2647.224854 
	C1689.068726,2657.483643 1685.663940,2659.930908 1675.870605,2656.541260 
	C1675.389648,2658.666016 1677.354858,2660.125977 1677.347412,2662.737793 
	C1677.195068,2664.011719 1676.877686,2664.516113 1676.481079,2664.992676 
	C1676.293213,2665.218262 1676.362183,2665.492676 1676.423828,2665.595459 
	C1677.110352,2666.738525 1678.182251,2665.416504 1676.915283,2665.832031 
	C1676.897461,2665.837646 1676.790649,2665.546143 1676.949707,2664.446777 
	C1679.224243,2660.340332 1682.426025,2660.680176 1685.817627,2660.865234 
	C1691.154053,2661.156738 1693.071045,2659.132812 1690.508789,2653.713867 
	C1687.543457,2647.442383 1688.049316,2641.673828 1695.276367,2637.818848 
	C1697.746704,2638.177002 1699.309692,2638.699463 1699.903076,2641.363281 
	C1706.113892,2641.625488 1706.314575,2641.730957 1704.256104,2645.652100 
	C1703.471069,2647.147461 1702.104736,2648.297363 1703.567383,2649.845703 
	C1705.457153,2651.845947 1707.229248,2650.326416 1708.719360,2649.130859 
	C1709.650269,2648.384277 1710.196777,2647.158447 1711.046509,2645.963623 
	C1713.901978,2650.315430 1709.524536,2654.178711 1710.772583,2658.474609 
	C1713.751709,2660.674072 1717.099854,2656.302734 1720.682617,2659.466309 
	C1722.202271,2662.044434 1716.893921,2663.431152 1718.025391,2667.180420 
	C1713.448853,2667.057373 1710.877686,2670.240967 1708.406250,2673.412598 
	C1706.580811,2670.763428 1709.286377,2668.312500 1707.180298,2666.198242 
	C1706.117432,2665.926025 1706.084351,2667.676270 1705.629150,2667.691406 
	C1696.902222,2667.983887 1692.785522,2675.217529 1687.027954,2679.936523 
	C1684.682983,2681.858643 1681.802002,2682.707764 1679.791748,2680.621582 
	C1674.088867,2674.702637 1666.777466,2673.523926 1659.456177,2673.210205 
	C1659.181519,2672.229736 1658.915405,2671.817383 1659.027466,2671.602539 
	C1663.826294,2662.404785 1660.301025,2656.429443 1652.560547,2650.531982 
	C1648.303345,2647.288330 1647.156860,2649.117188 1644.460938,2652.507568 
	C1644.106567,2648.322754 1643.432007,2645.170166 1645.999512,2641.916016 
	C1649.817505,2637.077148 1646.501587,2634.514648 1642.502563,2631.882812 
	C1639.670166,2641.185059 1639.228638,2650.593750 1633.854126,2658.591309 
	C1631.305420,2656.426758 1632.024780,2654.399902 1632.781250,2652.673828 
	C1634.342163,2649.111816 1631.556274,2648.084473 1629.603516,2646.395020 
	C1627.231812,2644.342773 1625.159912,2644.211914 1622.526245,2646.167725 
	C1620.446289,2647.712402 1617.839966,2648.548340 1614.768799,2649.753906 
	C1612.596436,2648.389404 1611.221436,2646.901367 1610.443726,2644.147949 
	C1609.353149,2638.623535 1613.522949,2634.681885 1613.041016,2630.541016 
	C1612.125244,2636.671875 1608.094360,2642.688721 1608.134644,2650.366943 
	C1604.945679,2658.477539 1604.959106,2658.453369 1598.427124,2654.811523 
	C1598.099976,2654.629395 1598.312378,2655.902344 1597.734863,2656.400635 
	C1595.698730,2658.157471 1593.581299,2657.722412 1590.724121,2656.415039 
	C1588.489258,2653.895020 1585.451904,2652.725342 1585.996826,2648.670654 
	C1588.985474,2642.463623 1586.225342,2636.924561 1586.118530,2630.583252 
z"
        />
        <path
          fill="#3A261C"
          opacity="1.000000"
          stroke="none"
          d="
M1702.238770,2727.354004 
	C1700.820679,2728.292480 1700.152954,2729.031250 1699.066162,2730.053711 
	C1684.332397,2733.154053 1669.725220,2733.870850 1655.296997,2735.858643 
	C1645.330322,2737.231689 1643.340454,2733.561523 1643.725464,2723.055420 
	C1643.914307,2717.903320 1638.920166,2715.160889 1638.683594,2709.584961 
	C1638.391724,2707.733154 1638.549072,2706.688477 1639.433838,2705.035156 
	C1644.457275,2700.076904 1650.664673,2702.859619 1655.573242,2699.993652 
	C1657.456421,2709.582520 1658.534302,2709.898682 1670.422729,2704.877441 
	C1673.066650,2707.969238 1667.305908,2709.888672 1669.638062,2713.340576 
	C1674.778442,2713.359863 1680.196899,2710.981934 1685.723389,2712.050537 
	C1692.028320,2713.269531 1699.264771,2712.385010 1703.761230,2719.853760 
	C1704.191650,2721.043701 1704.026367,2721.751221 1703.778564,2723.003662 
	C1705.359619,2725.123047 1704.329468,2726.168701 1702.238770,2727.354004 
z"
        />
        <path
          fill="#564031"
          opacity="1.000000"
          stroke="none"
          d="
M1533.716797,2567.410645 
	C1529.483643,2573.843018 1529.496216,2573.991699 1535.341064,2575.979492 
	C1536.284424,2580.096436 1538.957275,2583.218994 1540.781006,2586.569580 
	C1538.593750,2588.860107 1536.896118,2587.268311 1534.580322,2586.261230 
	C1533.860107,2589.094727 1534.366333,2591.930908 1537.305908,2593.687012 
	C1539.206665,2594.822510 1539.893311,2596.546387 1539.895264,2599.391602 
	C1537.625488,2600.383789 1536.300293,2601.262451 1536.918823,2603.876709 
	C1537.729126,2607.302002 1538.153076,2610.833252 1539.167236,2614.191162 
	C1540.017090,2617.005371 1539.757202,2619.223389 1536.790527,2621.402344 
	C1536.324951,2615.650879 1534.235840,2610.358643 1530.436157,2603.305664 
	C1528.215576,2611.715576 1523.067261,2617.387451 1529.314941,2624.620850 
	C1531.001709,2626.573730 1529.838745,2630.988037 1529.989502,2635.136230 
	C1535.168945,2636.046631 1534.424316,2642.701172 1539.355957,2643.860840 
	C1540.065063,2644.842529 1540.095337,2645.739746 1540.132324,2647.309326 
	C1540.307495,2648.259277 1540.447144,2648.559570 1540.648682,2648.810547 
	C1543.158325,2651.934814 1550.693481,2649.712158 1549.703735,2655.533691 
	C1548.981689,2659.781250 1543.237793,2660.201660 1538.632935,2661.711426 
	C1534.876709,2663.893311 1534.489746,2667.112061 1533.638550,2671.423340 
	C1532.135132,2672.336426 1531.342285,2671.917480 1530.320068,2670.634277 
	C1528.873413,2662.091309 1529.496338,2653.780029 1524.646240,2646.620117 
	C1523.516846,2644.953125 1523.931274,2642.712891 1524.726196,2640.863770 
	C1526.655151,2636.376709 1526.025269,2632.557129 1522.292725,2629.261963 
	C1521.319458,2628.402832 1520.112671,2626.764404 1520.935791,2625.833252 
	C1525.251465,2620.949707 1521.851074,2616.480957 1520.228638,2612.164307 
	C1518.210327,2606.795166 1519.678833,2602.156006 1521.954102,2596.736328 
	C1518.929688,2590.393555 1519.241699,2584.395020 1519.999023,2578.280029 
	C1520.438110,2574.735596 1522.477417,2571.480469 1521.770020,2566.976074 
	C1525.997314,2565.664795 1525.339722,2569.852295 1526.987183,2571.894775 
	C1528.707153,2570.268799 1527.787476,2568.273438 1528.056519,2566.512451 
	C1528.294434,2564.954590 1528.192505,2562.952881 1530.446167,2563.249268 
	C1532.190063,2563.478516 1532.544922,2565.514404 1533.716797,2567.410645 
z"
        />
        <path
          fill="#3A261C"
          opacity="1.000000"
          stroke="none"
          d="
M1538.286621,2662.369141 
	C1538.968018,2656.224609 1545.007080,2658.065430 1547.998657,2655.066162 
	C1546.576050,2652.490967 1543.992554,2652.378906 1541.504272,2651.992432 
	C1539.735107,2651.717285 1538.360107,2651.014160 1539.669189,2648.386963 
	C1541.118408,2648.151123 1542.169067,2648.149170 1543.064331,2648.520752 
	C1546.225342,2649.832275 1550.144165,2646.206055 1552.545776,2649.168457 
	C1555.819702,2653.206543 1558.254272,2657.942627 1560.898438,2662.467041 
	C1562.154419,2664.616211 1560.692627,2666.213379 1559.245605,2667.648926 
	C1557.713257,2669.169189 1556.002930,2669.842773 1553.864502,2668.840576 
	C1551.747314,2667.848145 1549.652222,2666.894531 1548.373291,2664.757812 
	C1547.572144,2663.419189 1546.221191,2662.821045 1545.235352,2664.236572 
	C1541.814209,2669.148682 1540.789429,2663.379150 1538.286621,2662.369141 
z"
        />
        <path
          fill="#3A261C"
          opacity="1.000000"
          stroke="none"
          d="
M1590.276123,2656.511230 
	C1593.694336,2658.227539 1597.520752,2651.594727 1601.806763,2656.611328 
	C1603.144409,2658.177002 1603.780151,2652.858154 1605.737793,2650.591309 
	C1608.059082,2648.018311 1605.585938,2642.911133 1611.438477,2643.565674 
	C1613.694458,2645.106201 1613.979126,2646.764893 1614.044922,2649.167725 
	C1613.018921,2650.208252 1611.150757,2650.058350 1612.121948,2652.036865 
	C1614.618286,2657.121582 1612.279785,2662.667236 1613.770264,2667.907471 
	C1614.357788,2669.973145 1612.683838,2671.721924 1610.153320,2670.859619 
	C1606.560547,2669.635498 1602.227905,2669.638428 1600.126709,2664.719727 
	C1599.120728,2661.627197 1596.380249,2662.263672 1594.445557,2660.438721 
	C1592.834106,2659.016357 1591.668091,2658.032715 1590.276123,2656.511230 
z"
        />
        <path
          fill="#3A261C"
          opacity="1.000000"
          stroke="none"
          d="
M1634.620728,2704.984375 
	C1631.336304,2705.501709 1628.656128,2705.523438 1625.169434,2705.546387 
	C1620.821167,2702.300781 1615.390259,2708.251221 1612.079468,2702.414551 
	C1612.012451,2699.502441 1611.899170,2697.198730 1612.273560,2694.977295 
	C1612.676392,2692.587402 1614.330933,2692.029541 1616.617188,2692.761963 
	C1622.944092,2694.788574 1628.999390,2697.269775 1633.846436,2702.872803 
	C1634.406982,2703.843262 1634.815918,2704.166016 1634.620728,2704.984375 
z"
        />
        <path
          fill="#564031"
          opacity="1.000000"
          stroke="none"
          d="
M1579.577637,2693.942383 
	C1575.530762,2697.444336 1571.731079,2695.161377 1568.223267,2692.787598 
	C1564.365967,2690.177734 1560.582520,2687.910156 1555.690796,2687.918213 
	C1551.574829,2687.924805 1550.058594,2685.239014 1550.048218,2680.631836 
	C1550.342407,2678.737549 1550.420288,2676.556396 1552.166870,2678.549805 
	C1555.361450,2682.196045 1559.402344,2684.214355 1563.968140,2685.059082 
	C1569.999390,2686.174561 1573.139282,2692.391846 1579.577637,2693.942383 
z"
        />
        <path
          fill="#704A3A"
          opacity="1.000000"
          stroke="none"
          d="
M1633.648804,2706.077148 
	C1634.016602,2705.479980 1633.924438,2704.503906 1633.961182,2704.012207 
	C1635.474365,2703.765137 1636.950562,2704.009766 1639.170654,2704.322266 
	C1639.942017,2705.189453 1639.969482,2705.989014 1640.035278,2707.391113 
	C1638.924927,2713.927246 1643.065674,2720.331055 1637.859131,2726.378906 
	C1636.762939,2726.900391 1636.285034,2727.002441 1635.094971,2726.981689 
	C1631.887207,2725.720459 1632.324219,2722.837891 1630.435547,2720.572754 
	C1628.722168,2719.967041 1627.499634,2719.834961 1625.970581,2719.669922 
	C1633.319824,2714.142090 1633.407593,2713.424316 1626.724121,2710.026367 
	C1627.665405,2707.228027 1631.284790,2708.729980 1633.648804,2706.077148 
z"
        />
        <path
          fill="#704A3A"
          opacity="1.000000"
          stroke="none"
          d="
M1611.103760,2701.834717 
	C1614.177490,2703.136230 1616.425415,2703.758545 1619.082275,2702.562744 
	C1621.012939,2701.693848 1622.788086,2702.381836 1624.118164,2704.950684 
	C1614.200562,2710.041260 1604.966919,2707.592285 1595.981567,2701.108398 
	C1600.872681,2698.397705 1605.362183,2702.361328 1611.103760,2701.834717 
z"
        />
        <path
          fill="#564031"
          opacity="1.000000"
          stroke="none"
          d="
M1539.992676,2676.625488 
	C1539.568115,2678.495605 1538.619141,2679.291748 1536.606934,2679.666748 
	C1534.710938,2681.200684 1533.240601,2681.241699 1532.014160,2679.938477 
	C1529.916016,2677.708740 1530.784058,2675.419434 1532.959106,2672.716797 
	C1533.649536,2672.199707 1533.964844,2671.992676 1534.120117,2671.881836 
	C1536.783936,2672.349854 1538.875610,2673.475830 1539.992676,2676.625488 
z"
        />
        <path
          fill="#442F21"
          opacity="1.000000"
          stroke="none"
          d="
M1594.123779,2660.676758 
	C1596.386108,2660.819824 1600.472900,2657.934570 1600.413574,2663.584961 
	C1597.705811,2663.918701 1595.232666,2664.231934 1594.123779,2660.676758 
z"
        />
        <path
          fill="#564031"
          opacity="1.000000"
          stroke="none"
          d="
M1595.760376,2699.398926 
	C1593.134155,2699.821289 1590.026489,2700.800537 1587.881348,2697.142334 
	C1590.522339,2696.456299 1593.767822,2695.083496 1595.760376,2699.398926 
z"
        />
        <path
          fill="#3A261C"
          opacity="1.000000"
          stroke="none"
          d="
M1619.140137,2670.713135 
	C1618.724609,2669.223145 1619.237183,2668.672363 1620.401367,2668.907227 
	C1620.642090,2668.956055 1620.964478,2669.297607 1621.001099,2669.541504 
	C1621.176392,2670.711670 1620.608398,2671.204102 1619.140137,2670.713135 
z"
        />
        <path
          fill="#3A261C"
          opacity="1.000000"
          stroke="none"
          d="
M1604.854492,2692.497314 
	C1605.916016,2690.404297 1606.976562,2691.698242 1607.916626,2692.291260 
	C1608.635010,2692.744873 1608.412354,2693.619873 1607.564331,2693.649658 
	C1606.710571,2693.679688 1605.838989,2693.208252 1604.854492,2692.497314 
z"
        />
        <path
          fill="#442F21"
          opacity="1.000000"
          stroke="none"
          d="
M1722.020996,2674.506348 
	C1721.980469,2674.025146 1722.962280,2674.016846 1723.452515,2674.016602 
	C1723.936401,2674.952148 1724.046143,2675.964355 1722.992065,2676.417725 
	C1722.903320,2676.455566 1722.381958,2675.487793 1722.020996,2674.506348 
z"
        />
        <path
          fill="#442F21"
          opacity="1.000000"
          stroke="none"
          d="
M1642.542969,2658.023926 
	C1642.798340,2657.685791 1643.263062,2657.572754 1643.276733,2657.612793 
	C1643.569336,2658.475586 1643.304565,2658.718018 1642.542969,2658.023926 
z"
        />
        <path
          fill="#442F21"
          opacity="1.000000"
          stroke="none"
          d="
M1720.503662,2673.991211 
	C1720.395508,2673.345459 1720.785034,2672.734619 1721.802734,2673.712402 
	C1721.981323,2674.019043 1720.995972,2674.010986 1720.503662,2673.991211 
z"
        />
        <path
          fill="#281C18"
          opacity="1.000000"
          stroke="none"
          d="
M1739.041504,2699.069336 
	C1738.957153,2699.114746 1739.125977,2699.024170 1739.041504,2699.069336 
z"
        />
        <path
          fill="#564031"
          opacity="1.000000"
          stroke="none"
          d="
M1585.925293,2695.288330 
	C1585.542236,2695.725342 1584.947998,2695.529297 1584.083008,2694.788574 
	C1584.375244,2694.595459 1585.009277,2694.732422 1585.925293,2695.288330 
z"
        />
        <path
          fill="#3A261C"
          opacity="1.000000"
          stroke="none"
          d="
M1580.007324,2653.995117 
	C1580.230225,2654.254150 1580.445801,2654.518311 1580.661377,2654.782227 
	C1580.505249,2654.692139 1580.349121,2654.602051 1580.091553,2654.260742 
	C1579.990234,2654.009766 1580.000000,2654.000000 1580.007324,2653.995117 
z"
        />
        <path
          fill="#7B7F84"
          opacity="1.000000"
          stroke="none"
          d="
M1881.291992,2692.112793 
	C1883.786621,2693.378906 1884.172485,2695.170410 1883.202271,2697.156738 
	C1882.350708,2698.900391 1880.949585,2700.443604 1880.419434,2702.258789 
	C1879.794067,2704.400146 1879.452881,2706.914795 1881.334961,2708.701172 
	C1883.271851,2710.540039 1885.605225,2709.991943 1887.671753,2708.828613 
	C1889.646973,2707.716553 1891.372803,2706.190918 1893.821533,2706.079590 
	C1897.064697,2705.932373 1899.769775,2706.393555 1899.986328,2711.235840 
	C1899.445679,2712.789307 1898.739624,2713.415771 1897.213989,2714.109863 
	C1894.357544,2714.973633 1891.938599,2714.566406 1889.307129,2716.186523 
	C1887.621948,2717.262207 1885.809326,2717.937012 1885.750732,2718.995117 
	C1884.725708,2737.502197 1865.634888,2736.495605 1856.437012,2746.138672 
	C1854.890503,2747.760010 1852.044189,2747.642578 1849.859131,2746.500244 
	C1845.410889,2744.174805 1841.609497,2745.586914 1837.343018,2748.584961 
	C1835.908936,2749.291992 1835.039307,2749.537354 1833.479736,2749.686035 
	C1830.398926,2749.531738 1827.906372,2748.468994 1825.135498,2750.662598 
	C1823.274780,2762.424316 1829.510254,2771.877686 1832.507935,2782.139404 
	C1833.969360,2787.141846 1837.166382,2791.795410 1834.709595,2798.000488 
	C1831.231689,2800.342773 1828.230957,2799.852051 1826.190063,2797.378418 
	C1818.871338,2788.508789 1812.155518,2779.254150 1811.584595,2766.589844 
	C1818.412476,2763.970947 1816.092041,2759.100342 1815.162109,2755.284912 
	C1814.417114,2752.228760 1810.429932,2749.206787 1812.628784,2746.265869 
	C1815.669067,2742.199951 1816.871094,2736.623047 1821.560425,2733.998535 
	C1824.144531,2732.552490 1825.530273,2730.767090 1826.293701,2728.066650 
	C1827.252563,2724.674316 1829.049683,2722.016113 1833.055542,2721.839600 
	C1833.381958,2721.825195 1833.703003,2721.699463 1834.027466,2721.630127 
	C1839.540894,2720.449219 1835.036377,2714.464111 1837.897949,2712.874268 
	C1842.098145,2710.541504 1842.577271,2718.461182 1846.727661,2717.121826 
	C1848.490356,2716.553223 1849.987793,2714.922363 1851.402466,2713.562500 
	C1854.877197,2710.222900 1858.768799,2707.870361 1863.746826,2708.022461 
	C1869.108032,2708.186035 1872.416260,2705.822754 1874.166138,2700.686523 
	C1875.332397,2697.263428 1877.242920,2694.120361 1881.291992,2692.112793 
z"
        />
        <path
          fill="#A6A29C"
          opacity="1.000000"
          stroke="none"
          d="
M1811.761475,2765.310059 
	C1815.157104,2777.121826 1821.391357,2786.586426 1828.076904,2795.815918 
	C1829.381470,2797.616699 1831.204102,2797.965088 1833.983887,2798.004395 
	C1841.735718,2799.482422 1842.372803,2805.370361 1843.553955,2811.550537 
	C1841.573853,2816.590820 1837.352051,2817.209961 1832.912720,2818.736816 
	C1826.160522,2817.370605 1824.981445,2811.963623 1822.598389,2806.707275 
	C1821.664551,2804.508789 1820.994263,2802.921387 1819.054932,2801.405029 
	C1817.202759,2799.749023 1816.000244,2798.394531 1814.646729,2796.253662 
	C1813.936890,2794.755127 1813.638550,2793.854004 1813.310059,2792.219238 
	C1811.116577,2780.302979 1806.214355,2770.272461 1800.609375,2760.464355 
	C1798.670166,2757.070801 1797.117676,2753.325439 1796.568726,2748.670410 
	C1796.953613,2745.465820 1799.343506,2745.258301 1801.545898,2743.995117 
	C1802.103516,2743.797119 1803.061890,2743.803223 1803.541016,2743.809570 
	C1806.983521,2750.589600 1807.732422,2758.150879 1811.761475,2765.310059 
z"
        />
        <path
          fill="#33495B"
          opacity="1.000000"
          stroke="none"
          d="
M1833.391602,2675.152588 
	C1833.767456,2673.942871 1833.816162,2673.242432 1834.138306,2672.706299 
	C1834.718384,2671.740723 1835.672607,2671.499756 1835.870972,2672.829834 
	C1836.154419,2674.730225 1836.285034,2676.767334 1834.988892,2678.430176 
	C1834.779419,2678.698975 1833.439697,2678.552246 1833.410400,2678.421143 
	C1833.216309,2677.552979 1833.237061,2676.636475 1833.391602,2675.152588 
z"
        />
        <path
          fill="#442F21"
          opacity="1.000000"
          stroke="none"
          d="
M1722.051514,2615.975098 
	C1727.287231,2620.148926 1732.453125,2624.519775 1737.653564,2632.109131 
	C1736.420410,2622.085693 1747.003418,2625.469971 1747.167114,2618.583496 
	C1750.478516,2619.648682 1750.939209,2622.390625 1753.410767,2624.065918 
	C1762.959229,2624.188965 1765.972534,2615.258545 1773.287354,2611.783203 
	C1774.057373,2615.204590 1776.875610,2618.099854 1775.144287,2621.558350 
	C1773.818359,2624.206787 1774.145264,2628.666016 1768.827515,2626.735596 
	C1766.713013,2625.968018 1765.236572,2627.973389 1763.775146,2629.267090 
	C1761.987061,2630.850098 1759.915283,2632.593750 1757.598145,2631.471436 
	C1752.083252,2628.800049 1751.745239,2634.221680 1749.776855,2636.447998 
	C1747.085327,2639.492188 1749.957520,2641.781250 1752.052002,2643.860352 
	C1753.307617,2645.106934 1756.143921,2646.000977 1753.276978,2648.221436 
	C1747.961060,2652.338379 1751.628662,2654.794434 1755.410645,2657.706787 
	C1755.428711,2662.216797 1753.065918,2661.912354 1749.764648,2660.947998 
	C1745.066528,2659.576172 1742.556641,2654.538574 1736.733032,2654.022461 
	C1735.828857,2653.056152 1735.863525,2652.010498 1735.454590,2651.184326 
	C1731.526611,2643.251221 1730.990112,2643.093018 1722.429932,2647.502686 
	C1720.689453,2647.996826 1718.901855,2652.173828 1718.221069,2648.379639 
	C1717.265747,2643.055176 1718.264526,2637.593262 1715.021484,2632.432861 
	C1713.392456,2629.841064 1719.123901,2627.085205 1717.498413,2623.613037 
	C1715.298828,2618.913818 1719.139404,2618.251221 1722.051514,2615.975098 
z"
        />
        <path
          fill="#564031"
          opacity="1.000000"
          stroke="none"
          d="
M1774.005859,2611.325928 
	C1771.319092,2615.290039 1768.803711,2618.712891 1766.015259,2621.896484 
	C1762.902344,2625.450439 1759.228638,2627.769043 1754.106201,2625.006836 
	C1756.044189,2614.308594 1764.439209,2611.305908 1773.331787,2608.050781 
	C1774.075806,2608.951660 1774.066162,2609.815186 1774.005859,2611.325928 
z"
        />
        <path
          fill="#442F21"
          opacity="1.000000"
          stroke="none"
          d="
M1728.601562,2663.661621 
	C1729.228516,2660.218018 1724.587036,2658.327393 1725.578125,2654.391602 
	C1732.815308,2657.908691 1732.815308,2657.908691 1728.601562,2663.661621 
z"
        />
        <path
          fill="#442F21"
          opacity="1.000000"
          stroke="none"
          d="
M1755.995117,2657.995117 
	C1756.142944,2657.358154 1756.208008,2656.687256 1756.466187,2656.101562 
	C1757.051270,2654.776367 1758.118164,2654.070068 1759.557495,2654.437744 
	C1759.913452,2654.528809 1760.400391,2655.496338 1760.268799,2655.858398 
	C1759.781616,2657.200195 1758.539551,2657.659424 1756.617676,2657.958008 
	C1756.009766,2658.009766 1756.000000,2658.000000 1755.995117,2657.995117 
z"
        />
        <path
          fill="#0C0D0F"
          opacity="1.000000"
          stroke="none"
          d="
M1767.993042,2656.004883 
	C1767.517700,2655.343994 1767.050659,2654.677734 1766.583740,2654.011719 
	C1766.817017,2653.850586 1767.050415,2653.689697 1767.283691,2653.528809 
	C1767.513550,2654.043457 1767.743530,2654.558105 1767.996582,2655.526855 
	C1768.019531,2655.980469 1768.001465,2655.999756 1767.993042,2656.004883 
z"
        />
        <path
          fill="#442F21"
          opacity="1.000000"
          stroke="none"
          d="
M1797.052002,2599.067139 
	C1796.965088,2599.114014 1797.139038,2599.020264 1797.052002,2599.067139 
z"
        />
        <path
          fill="#442F21"
          opacity="1.000000"
          stroke="none"
          d="
M1735.749390,2654.102051 
	C1735.757935,2653.884277 1736.053467,2655.086914 1735.749390,2654.102051 
z"
        />
        <path
          fill="#0C0D0F"
          opacity="1.000000"
          stroke="none"
          d="
M1765.052124,2647.079102 
	C1764.968994,2647.125000 1765.135254,2647.032959 1765.052124,2647.079102 
z"
        />
        <path
          fill="#CBC5B5"
          opacity="1.000000"
          stroke="none"
          d="
M1767.904297,2900.587402 
	C1767.935303,2899.078857 1767.941406,2898.211426 1767.944458,2896.692383 
	C1765.883179,2893.232910 1764.239258,2890.191406 1762.494629,2886.459961 
	C1760.240845,2874.604004 1760.222046,2862.800049 1753.448730,2852.765137 
	C1750.575928,2833.879883 1736.972900,2819.623291 1731.429810,2801.876465 
	C1731.079468,2800.754883 1729.662964,2799.966309 1728.288330,2798.483154 
	C1727.830444,2797.090332 1727.826782,2796.237549 1727.774292,2794.745605 
	C1725.809082,2790.971924 1725.399414,2787.989258 1729.336426,2785.137939 
	C1738.028687,2786.902832 1740.169434,2794.035889 1742.903564,2800.229736 
	C1746.517090,2808.416992 1750.327759,2816.506836 1753.824341,2825.320557 
	C1755.873535,2821.613770 1754.718872,2818.156738 1755.546753,2814.303955 
	C1755.893677,2811.892578 1756.311523,2810.164551 1755.862305,2807.724365 
	C1755.571777,2806.163086 1755.442139,2805.258057 1755.282959,2803.647217 
	C1754.319458,2794.457520 1754.183105,2785.878174 1751.895630,2776.850586 
	C1748.668823,2770.027344 1749.139282,2763.674805 1750.237183,2757.197754 
	C1750.822388,2753.745361 1751.631592,2750.173584 1749.719971,2746.026611 
	C1748.472778,2742.682373 1749.060181,2740.311768 1751.427490,2737.737305 
	C1752.574951,2736.628418 1753.085571,2735.947998 1753.889648,2734.699707 
	C1759.862305,2730.916016 1762.743530,2739.536865 1768.702393,2737.912598 
	C1771.332153,2742.549805 1773.407349,2746.710205 1768.642578,2751.204102 
	C1763.069824,2751.560303 1759.498291,2752.756348 1762.825195,2758.578613 
	C1763.777466,2760.244873 1764.779175,2758.457031 1766.224731,2757.396484 
	C1776.138550,2758.509033 1772.823242,2767.403076 1776.022949,2773.233398 
	C1776.141968,2774.831543 1776.207886,2775.673096 1776.371582,2777.140137 
	C1778.546265,2780.108643 1780.333374,2782.571045 1779.700195,2786.676758 
	C1776.111694,2791.879639 1771.553589,2795.998535 1774.559204,2803.284912 
	C1774.829224,2805.795654 1774.664551,2807.616211 1774.333008,2810.103027 
	C1773.848755,2812.900635 1772.825806,2814.959473 1773.613281,2817.866211 
	C1773.713257,2819.415527 1773.629395,2820.295166 1773.253418,2821.795166 
	C1770.890503,2826.129150 1769.038818,2829.917725 1770.056152,2835.031738 
	C1770.721558,2836.900879 1771.129272,2838.167969 1771.761475,2840.085938 
	C1773.618042,2845.568848 1769.423950,2850.898438 1773.500488,2856.229004 
	C1774.250977,2858.976807 1774.157349,2860.951904 1772.491577,2863.385254 
	C1770.350708,2872.099121 1770.316406,2872.154785 1776.939575,2876.237305 
	C1781.974243,2879.340088 1780.324097,2883.666748 1778.782959,2887.556641 
	C1777.680542,2890.338867 1774.989624,2892.703613 1779.802002,2894.426025 
	C1780.274536,2894.594971 1780.937500,2895.599854 1780.803589,2895.894287 
	C1776.856567,2904.575439 1786.147461,2912.327637 1782.497314,2921.150146 
	C1781.591919,2923.338867 1784.263062,2925.204346 1786.636475,2925.956543 
	C1789.938599,2927.002686 1789.976318,2929.668945 1789.960327,2932.382568 
	C1789.944336,2935.087891 1789.879028,2937.993164 1786.803955,2939.002197 
	C1784.109009,2939.886719 1782.465088,2937.670654 1781.205200,2935.797119 
	C1779.525879,2933.299805 1777.744385,2931.048096 1775.324829,2929.176025 
	C1773.476196,2927.745361 1772.274048,2925.520508 1772.760376,2923.034180 
	C1774.108154,2916.143555 1773.001343,2910.091064 1767.682495,2905.039551 
	C1767.082397,2904.469727 1767.782227,2902.531738 1767.904297,2900.587402 
z"
        />
        <path
          fill="#E6E1D0"
          opacity="1.000000"
          stroke="none"
          d="
M1753.490479,2775.607422 
	C1755.780518,2777.187500 1757.440063,2779.308350 1757.015381,2780.864502 
	C1755.016357,2788.191406 1758.658691,2795.128418 1757.990234,2803.145752 
	C1750.670288,2806.569336 1750.341675,2800.683594 1748.759277,2796.341064 
	C1745.757446,2788.103027 1740.732788,2784.608154 1732.678467,2785.791992 
	C1727.838257,2786.421875 1728.085693,2789.641602 1727.813965,2793.351074 
	C1725.634521,2793.678467 1724.244141,2792.588867 1724.030151,2790.311279 
	C1723.875488,2788.664795 1723.433228,2787.156494 1721.068359,2786.273438 
	C1717.964355,2786.832275 1717.780884,2789.244873 1716.383667,2791.555664 
	C1716.004883,2792.075684 1715.986572,2791.988281 1716.030884,2791.993164 
	C1714.767578,2792.950684 1713.269897,2793.706299 1712.304932,2795.864258 
	C1711.994019,2796.392578 1712.087524,2797.142090 1712.103516,2797.519287 
	C1712.368652,2803.490967 1714.559814,2809.205322 1710.542725,2815.229004 
	C1706.942627,2816.224854 1704.300293,2812.958008 1700.634888,2815.337158 
	C1699.859253,2815.803467 1699.516968,2815.804443 1698.661499,2815.812988 
	C1698.148193,2815.820801 1697.312378,2815.333008 1697.553955,2815.752441 
	C1699.993042,2830.946533 1699.928711,2846.035156 1703.970215,2861.278320 
	C1703.813477,2864.845947 1702.691040,2866.858154 1698.812378,2866.896729 
	C1693.906982,2860.913330 1693.036377,2853.748535 1692.849487,2847.276367 
	C1692.596924,2838.525391 1688.434082,2832.974365 1682.038086,2827.954346 
	C1678.746826,2829.500244 1676.007324,2830.450684 1672.233398,2829.737549 
	C1669.449951,2828.483643 1668.491455,2826.789062 1668.606689,2823.775879 
	C1669.176880,2822.114258 1669.802246,2821.378174 1671.330566,2820.610352 
	C1674.120972,2819.848633 1676.121582,2819.177002 1676.701904,2815.949707 
	C1679.182983,2811.417480 1675.339600,2804.937988 1682.718506,2802.986816 
	C1684.539307,2803.103271 1684.257568,2805.110840 1685.261353,2804.812744 
	C1686.494141,2804.447021 1685.193726,2805.428467 1684.798218,2803.571533 
	C1683.939087,2799.655273 1686.228760,2797.218506 1687.589478,2793.783203 
	C1688.987915,2791.089844 1690.636475,2789.554688 1693.240479,2788.093994 
	C1695.354248,2787.146240 1696.966919,2786.615723 1698.207886,2784.504883 
	C1698.885742,2783.710449 1699.177979,2783.409668 1699.968506,2782.723145 
	C1702.673950,2781.192871 1704.960815,2780.133545 1706.026367,2776.864258 
	C1706.597656,2775.942871 1706.853027,2775.588379 1707.560669,2774.754395 
	C1713.190918,2769.652100 1718.400146,2765.051758 1719.852905,2757.072754 
	C1721.462769,2744.827637 1729.146362,2736.318848 1735.047119,2726.096191 
	C1738.531738,2722.467285 1739.683350,2727.009766 1742.607666,2727.430664 
	C1744.155029,2727.573730 1744.867798,2727.929932 1745.434692,2729.374756 
	C1745.565186,2730.356689 1745.623291,2730.687744 1745.851318,2731.493164 
	C1749.953491,2734.197998 1750.092773,2737.161133 1748.520752,2741.165771 
	C1744.081543,2752.475098 1738.819458,2763.275879 1730.594360,2773.225098 
	C1729.403076,2774.176025 1728.797729,2774.866699 1728.024536,2775.070312 
	C1727.126831,2775.306641 1727.134155,2775.240967 1728.579590,2775.000244 
	C1734.125732,2772.051514 1734.185791,2764.031982 1741.727783,2763.540039 
	C1744.173706,2769.155518 1744.475708,2775.928711 1753.490479,2775.607422 
z"
        />
        <path
          fill="#B6AFA3"
          opacity="1.000000"
          stroke="none"
          d="
M1663.491333,2778.291992 
	C1668.000610,2777.678711 1668.491333,2773.555176 1670.812866,2771.639648 
	C1684.092651,2760.683105 1693.178711,2746.413086 1703.497437,2732.306641 
	C1705.683594,2730.793213 1707.060303,2731.115234 1708.322754,2733.355957 
	C1707.548462,2736.962646 1706.748901,2739.754639 1705.857300,2743.271484 
	C1700.887817,2756.676758 1689.222290,2764.100830 1681.836304,2774.849365 
	C1679.830200,2777.768799 1676.438110,2779.760742 1675.952637,2784.436035 
	C1674.970825,2791.304932 1669.215088,2793.041504 1664.821045,2797.016357 
	C1663.748291,2797.682129 1663.295166,2797.916504 1662.115601,2798.411621 
	C1651.556396,2801.688721 1641.892334,2805.216553 1632.099121,2808.354248 
	C1629.543213,2809.173096 1626.986938,2810.012207 1624.291138,2809.240234 
	C1621.602173,2808.469482 1619.253052,2807.113281 1619.056885,2803.975830 
	C1618.894409,2801.377930 1620.407593,2799.466064 1622.732910,2798.574707 
	C1627.990967,2796.559814 1633.250732,2794.531006 1639.197998,2792.342773 
	C1639.745972,2791.780762 1639.923706,2791.918213 1639.863770,2791.823242 
	C1641.349609,2790.339844 1643.487671,2790.073242 1645.605103,2788.502441 
	C1647.697876,2787.649902 1649.335449,2787.200684 1651.416870,2786.287598 
	C1653.574951,2784.783691 1655.587769,2784.253174 1657.385986,2782.280762 
	C1657.722290,2781.778320 1657.876221,2781.886475 1657.836426,2781.801270 
	C1659.412231,2780.466064 1661.267212,2779.640625 1663.491333,2778.291992 
z"
        />
        <path
          fill="#8C8D89"
          opacity="1.000000"
          stroke="none"
          d="
M1674.826538,2784.826416 
	C1672.256226,2782.914062 1672.098145,2781.227783 1673.732300,2779.384766 
	C1684.019409,2767.782471 1694.309937,2756.183350 1705.182373,2744.289551 
	C1707.434448,2744.620850 1709.103516,2745.245850 1711.430054,2745.977539 
	C1713.986084,2747.544922 1714.273804,2749.625977 1714.180664,2752.590088 
	C1713.778198,2754.363770 1713.330322,2755.281006 1712.207397,2756.654297 
	C1704.977661,2760.820557 1699.951660,2766.267090 1695.000977,2772.844727 
	C1688.071533,2777.188232 1684.902832,2786.280273 1674.826538,2784.826416 
z"
        />
        <path
          fill="#B6AFA3"
          opacity="1.000000"
          stroke="none"
          d="
M1712.174316,2754.189941 
	C1711.831665,2751.613037 1711.942261,2749.601562 1712.070190,2746.836914 
	C1716.166382,2741.107666 1720.402222,2736.250000 1724.268188,2731.113770 
	C1726.783325,2727.772217 1729.677124,2725.621826 1734.662720,2725.537109 
	C1735.062622,2731.587402 1732.413086,2736.932617 1729.042969,2741.793945 
	C1725.987061,2746.202148 1723.317993,2750.700195 1721.623413,2756.510254 
	C1721.307007,2758.234375 1720.650879,2758.846191 1718.919312,2759.124268 
	C1715.626099,2758.572021 1713.899170,2756.952637 1712.174316,2754.189941 
z"
        />
        <path
          fill="#CBC6BA"
          opacity="1.000000"
          stroke="none"
          d="
M1797.613770,2745.473145 
	C1799.807495,2753.392090 1803.244141,2760.215820 1807.058960,2766.679443 
	C1811.503418,2774.209961 1813.883057,2782.279541 1815.816040,2791.382568 
	C1806.837891,2793.897705 1805.606567,2786.589844 1803.494385,2781.141602 
	C1799.569702,2771.018066 1797.882202,2759.996582 1790.583008,2750.489258 
	C1790.072998,2749.929199 1790.000000,2750.000000 1790.034912,2749.964844 
	C1788.103394,2747.920410 1786.059570,2745.942627 1787.756958,2742.093262 
	C1789.807007,2741.491943 1791.284302,2741.496826 1793.498047,2741.561035 
	C1794.792358,2743.202393 1796.457520,2743.721924 1797.613770,2745.473145 
z"
        />
        <path
          fill="#A6A29C"
          opacity="1.000000"
          stroke="none"
          d="
M1742.421387,2763.135010 
	C1736.393188,2766.552490 1735.327393,2773.778564 1728.709961,2776.533691 
	C1727.749023,2776.350830 1727.435547,2775.894287 1727.427979,2774.665039 
	C1736.070312,2762.935791 1741.456421,2750.367676 1747.263428,2738.034424 
	C1748.038940,2736.387451 1747.212158,2734.807617 1746.203979,2732.653076 
	C1749.267212,2731.259766 1752.845337,2730.020752 1752.064697,2736.528809 
	C1752.284912,2739.391357 1751.332275,2741.151123 1750.339844,2743.545166 
	C1752.848511,2752.268066 1746.981934,2757.160400 1742.421387,2763.135010 
z"
        />
        <path
          fill="#A6A29C"
          opacity="1.000000"
          stroke="none"
          d="
M1768.632324,2738.569824 
	C1764.141235,2737.127441 1764.697144,2742.218994 1761.472412,2743.013428 
	C1759.155518,2740.421143 1760.489502,2735.028809 1754.877197,2734.210449 
	C1754.402954,2733.199707 1754.350342,2731.669678 1754.884277,2731.426270 
	C1760.753784,2728.750977 1767.237671,2728.350586 1773.388306,2726.670166 
	C1774.515137,2726.362549 1775.637573,2727.238770 1775.584839,2728.020508 
	C1775.175537,2734.098145 1782.375977,2733.430664 1784.092407,2738.336426 
	C1784.005371,2739.511475 1783.781738,2740.010254 1782.780884,2740.247559 
	C1781.128052,2739.919922 1780.252563,2739.853516 1778.718506,2739.758301 
	C1775.032837,2740.112549 1771.985718,2740.734375 1768.632324,2738.569824 
z"
        />
        <path
          fill="#63707D"
          opacity="1.000000"
          stroke="none"
          d="
M1784.083740,2720.674316 
	C1784.000000,2719.969727 1784.735107,2719.523682 1785.139404,2719.375977 
	C1789.312256,2720.062012 1792.835815,2721.530273 1796.874634,2719.093994 
	C1799.402954,2717.568848 1799.773560,2720.575928 1800.107910,2723.163330 
	C1799.578003,2724.165771 1798.992676,2724.447754 1798.499512,2724.844482 
	C1790.363647,2731.395996 1788.978760,2731.064453 1784.083740,2720.674316 
z"
        />
        <path
          fill="#8C8D89"
          opacity="1.000000"
          stroke="none"
          d="
M1709.044312,2734.035156 
	C1707.346558,2733.479736 1706.345215,2732.788818 1704.728027,2732.055176 
	C1704.424561,2731.104492 1704.737061,2730.196289 1705.334106,2728.625732 
	C1705.807251,2726.765381 1706.322876,2725.729492 1707.572876,2724.431641 
	C1710.598755,2723.897949 1713.310791,2723.809814 1713.788086,2727.268066 
	C1714.229004,2730.462402 1712.918823,2732.805664 1709.044312,2734.035156 
z"
        />
        <path
          fill="#EBE4D0"
          opacity="1.000000"
          stroke="none"
          d="
M1782.011963,2740.717529 
	C1782.383911,2739.845459 1782.764282,2739.704346 1783.686768,2739.287842 
	C1785.336792,2739.524658 1786.444580,2740.036865 1787.941040,2741.018066 
	C1787.805664,2744.052002 1789.338989,2746.166748 1789.968018,2749.215576 
	C1784.950684,2749.414551 1782.405518,2746.456787 1782.011963,2740.717529 
z"
        />
        <path
          fill="#A9ACA9"
          opacity="1.000000"
          stroke="none"
          d="
M1798.341064,2746.179443 
	C1795.998169,2745.723145 1794.660278,2744.885010 1794.283936,2742.266113 
	C1796.595947,2741.655273 1799.001099,2741.423828 1801.627441,2743.273926 
	C1801.878174,2745.733887 1801.475586,2747.444824 1798.341064,2746.179443 
z"
        />
        <path
          fill="#B6AFA3"
          opacity="1.000000"
          stroke="none"
          d="
M1707.267944,2723.965088 
	C1707.835449,2725.349365 1707.640137,2726.669678 1706.057373,2727.779297 
	C1704.929810,2727.898682 1704.240967,2727.834473 1702.520264,2727.662109 
	C1701.963989,2726.509033 1702.439819,2725.464111 1703.305664,2723.984131 
	C1704.647217,2723.672363 1705.598633,2723.796143 1707.267944,2723.965088 
z"
        />
        <path
          fill="#A6A29C"
          opacity="1.000000"
          stroke="none"
          d="
M1746.099365,2729.716797 
	C1744.880005,2729.475830 1744.253052,2728.925537 1743.424683,2728.015625 
	C1744.241577,2726.770752 1745.413574,2726.004150 1746.670410,2726.906982 
	C1747.515625,2727.514404 1747.281738,2728.561279 1746.099365,2729.716797 
z"
        />
        <path
          fill="#294159"
          opacity="1.000000"
          stroke="none"
          d="
M1837.363403,2566.297852 
	C1838.442993,2566.889160 1838.390015,2567.445312 1837.523193,2567.626465 
	C1837.011108,2567.733643 1836.403442,2567.383057 1835.838623,2567.238525 
	C1836.212036,2566.901611 1836.585571,2566.564697 1837.363403,2566.297852 
z"
        />
        <path
          fill="#EAE5D5"
          opacity="1.000000"
          stroke="none"
          d="
M1842.570557,2812.287598 
	C1840.164917,2807.337891 1837.950439,2803.063232 1835.245850,2798.406250 
	C1830.105957,2784.026855 1825.337769,2770.066650 1820.915161,2755.998291 
	C1820.116333,2753.457275 1816.890991,2749.722168 1823.184082,2748.001221 
	C1826.200073,2749.189697 1828.408691,2750.170898 1831.393311,2748.201660 
	C1832.897461,2748.029297 1833.768433,2747.988281 1835.266113,2747.757080 
	C1844.879150,2745.653076 1852.845459,2748.449219 1860.921631,2753.527832 
	C1861.608765,2754.635498 1861.830933,2755.100342 1862.267334,2756.290527 
	C1861.614014,2771.214844 1870.074463,2783.483154 1871.309570,2796.827393 
	C1872.399048,2808.599854 1879.914795,2817.848877 1879.770142,2830.351562 
	C1879.652588,2840.522461 1884.444946,2852.202148 1897.432129,2856.724854 
	C1906.198364,2859.777588 1907.876221,2869.019043 1910.092407,2876.851074 
	C1912.063843,2883.817139 1914.321899,2890.157227 1921.929688,2893.925049 
	C1927.513794,2897.699463 1924.641846,2902.358398 1924.239746,2907.293457 
	C1923.980591,2907.994629 1923.964111,2908.978027 1923.962524,2909.469238 
	C1919.782715,2911.455322 1916.579224,2914.866211 1912.021484,2915.726807 
	C1907.584717,2916.564697 1904.459839,2915.563721 1902.541138,2910.484619 
	C1898.885132,2907.014404 1898.592041,2903.028076 1898.136475,2898.385986 
	C1898.103149,2895.047607 1899.706299,2892.478027 1898.182373,2889.186523 
	C1897.334229,2886.942871 1896.756836,2885.297607 1895.852783,2883.076172 
	C1894.654297,2879.660645 1894.150757,2876.593750 1890.645508,2874.641113 
	C1886.407593,2872.819092 1884.418457,2869.929199 1883.130615,2866.309814 
	C1879.255859,2855.419922 1872.255249,2846.622314 1863.701904,2838.077148 
	C1856.770630,2829.066650 1847.494507,2822.892822 1842.570557,2812.287598 
z"
        />
        <path
          fill="#DBD5C8"
          opacity="1.000000"
          stroke="none"
          d="
M1924.636597,2907.617188 
	C1920.793579,2904.540771 1923.454712,2900.565430 1922.540039,2896.434570 
	C1919.130493,2892.459717 1922.133667,2889.496338 1923.178955,2885.599609 
	C1925.255737,2879.341064 1921.849487,2870.510498 1933.076782,2870.485840 
	C1940.823608,2877.564453 1942.083130,2888.325195 1949.938599,2894.697510 
	C1950.918701,2895.492920 1951.733765,2896.531982 1953.474243,2897.575439 
	C1956.143799,2902.054932 1958.514648,2904.782227 1963.501953,2901.396729 
	C1965.581543,2899.985352 1968.292603,2899.504395 1971.383545,2898.347168 
	C1972.936768,2905.289062 1967.294434,2907.658936 1962.333740,2911.386475 
	C1961.994873,2911.990234 1960.997437,2911.995850 1960.498779,2911.998047 
	C1957.649658,2914.768311 1953.368530,2915.895996 1952.327393,2919.595215 
	C1950.204712,2927.136719 1942.816040,2923.711670 1938.434937,2926.575439 
	C1936.932617,2927.557373 1935.552002,2924.364502 1936.432495,2923.286133 
	C1941.547974,2917.021729 1938.925171,2910.951660 1936.583740,2904.746582 
	C1935.864502,2902.840820 1936.023438,2900.837158 1936.660522,2898.873779 
	C1938.352051,2893.660645 1935.280151,2890.398682 1931.557983,2885.811523 
	C1929.671021,2891.299072 1928.013428,2895.466797 1928.018188,2900.120361 
	C1928.020996,2902.854736 1927.766479,2905.465332 1924.636597,2907.617188 
z"
        />
        <path
          fill="#DBD5C8"
          opacity="1.000000"
          stroke="none"
          d="
M1867.451660,3018.988037 
	C1870.914429,3020.194580 1873.735718,3021.123535 1876.427856,3022.338623 
	C1877.912476,3023.008789 1879.504272,3024.182129 1879.025635,3026.083496 
	C1878.484253,3028.233398 1876.379150,3029.363281 1874.491699,3029.089844 
	C1871.844971,3028.706299 1869.535889,3025.637695 1866.533203,3028.600586 
	C1865.985596,3029.141357 1864.436401,3028.336914 1864.415039,3027.262695 
	C1864.355225,3024.256836 1862.210327,3020.575928 1867.451660,3018.988037 
z"
        />
        <path
          fill="#DBD5C8"
          opacity="1.000000"
          stroke="none"
          d="
M1836.485107,3118.519531 
	C1837.067017,3115.661621 1838.868896,3113.971436 1841.500244,3112.244141 
	C1847.053345,3113.344727 1846.744629,3116.037109 1843.462280,3118.864990 
	C1841.620850,3120.451904 1839.175903,3120.284424 1836.485107,3118.519531 
z"
        />
        <path
          fill="#EAE5D5"
          opacity="1.000000"
          stroke="none"
          d="
M1898.265625,2909.245850 
	C1901.437134,2918.253418 1899.307373,2924.840820 1893.760010,2931.893311 
	C1888.458374,2938.633545 1883.241821,2945.488525 1877.824829,2952.162842 
	C1873.087769,2957.999268 1868.495850,2957.289795 1865.943970,2950.465576 
	C1864.759277,2947.297607 1864.759155,2947.297607 1858.651978,2943.897705 
	C1863.072021,2940.749512 1864.146729,2937.279297 1860.765991,2932.496338 
	C1858.810303,2929.729248 1860.353394,2925.302734 1857.599976,2922.281982 
	C1856.345825,2920.905762 1856.553711,2919.111084 1858.518799,2918.229248 
	C1863.402344,2916.038818 1862.325317,2912.365967 1860.385986,2909.125000 
	C1858.153198,2905.393555 1858.754517,2900.639648 1855.470093,2897.314941 
	C1853.181030,2894.997803 1855.548828,2892.313965 1855.561157,2889.809814 
	C1855.588745,2884.229004 1859.040771,2877.493408 1850.742065,2874.746582 
	C1852.439941,2870.529053 1856.269897,2870.404297 1859.442871,2868.213867 
	C1860.840332,2867.290527 1861.711670,2866.940674 1863.395020,2867.115234 
	C1868.684814,2873.384766 1875.252197,2878.249023 1875.675293,2886.890625 
	C1875.765991,2888.741943 1877.876587,2889.019043 1880.269531,2889.204346 
	C1893.602905,2889.494629 1895.422363,2899.579590 1898.265625,2909.245850 
z"
        />
        <path
          fill="#EBE4D0"
          opacity="1.000000"
          stroke="none"
          d="
M1790.051270,2750.744141 
	C1794.988892,2750.324951 1797.591309,2753.164551 1798.963989,2757.759033 
	C1801.126099,2764.996582 1803.781616,2772.088867 1805.855835,2779.349365 
	C1807.346802,2784.567871 1809.446411,2789.140381 1815.069580,2792.035156 
	C1815.873413,2792.978271 1816.000366,2793.785645 1816.199097,2795.203125 
	C1820.566650,2805.854004 1827.309570,2815.050537 1826.470703,2827.347168 
	C1826.406982,2829.919189 1827.779053,2832.460449 1827.022705,2833.665039 
	C1824.536743,2837.625000 1825.531616,2839.848389 1829.104858,2842.338135 
	C1834.401733,2846.029297 1828.681885,2849.195068 1828.059692,2853.319336 
	C1825.936279,2858.537354 1828.892578,2861.674316 1831.531250,2865.511963 
	C1832.009766,2865.990234 1832.000000,2866.000000 1832.002441,2865.995117 
	C1834.772949,2870.787354 1834.783691,2876.873535 1839.504883,2881.493408 
	C1841.977539,2883.560547 1841.040649,2885.342529 1840.606323,2887.265625 
	C1839.580322,2891.806885 1839.539917,2895.779785 1843.754272,2899.515381 
	C1847.515625,2902.849609 1846.355225,2904.392578 1838.781494,2905.994141 
	C1836.982422,2906.851807 1836.670288,2907.899170 1837.575562,2909.580811 
	C1838.903687,2910.943604 1840.709717,2912.071289 1840.561279,2912.802246 
	C1839.066406,2920.168701 1843.372925,2926.728516 1843.374146,2933.547852 
	C1843.374878,2937.775146 1841.058105,2942.397949 1840.520752,2947.553711 
	C1839.224121,2948.983643 1838.536987,2950.089355 1836.599243,2950.218262 
	C1834.468994,2945.334229 1834.435669,2940.210938 1831.327393,2936.017090 
	C1827.609009,2931.000000 1834.964478,2929.860352 1835.153442,2926.025391 
	C1832.311401,2922.650635 1828.790161,2928.297119 1825.863525,2924.660645 
	C1825.589600,2920.345703 1831.033081,2921.113037 1832.894531,2918.229980 
	C1831.868286,2914.407471 1828.347412,2912.962646 1825.600952,2910.809326 
	C1825.918945,2910.185059 1826.066650,2909.436035 1826.522217,2909.069092 
	C1834.552979,2902.604736 1835.364136,2897.877930 1827.823853,2890.755127 
	C1824.727783,2887.830566 1823.778442,2886.112549 1825.692749,2882.666260 
	C1828.279419,2878.008789 1829.165405,2873.812500 1822.587036,2871.730469 
	C1821.169312,2871.281494 1819.882935,2869.673096 1820.499756,2868.833740 
	C1823.825928,2864.309082 1817.749146,2859.465576 1821.193970,2855.069336 
	C1821.556152,2854.607178 1821.833496,2853.513428 1821.572632,2853.210205 
	C1814.959595,2845.527100 1817.123413,2835.146729 1813.217651,2826.394531 
	C1809.135376,2817.247559 1805.569214,2807.469238 1804.062378,2797.338135 
	C1803.132568,2791.086670 1799.276611,2785.678955 1799.273071,2779.227539 
	C1799.272949,2778.934326 1798.964844,2778.650635 1798.840088,2778.346191 
	C1797.239380,2774.442383 1788.969727,2774.948242 1792.697510,2766.860352 
	C1794.525391,2762.894531 1790.059692,2756.936523 1790.051270,2750.744141 
z"
        />
        <path
          fill="#CBC6BA"
          opacity="1.000000"
          stroke="none"
          d="
M1826.766846,2828.020264 
	C1823.495972,2817.545166 1817.397949,2808.260498 1816.191895,2796.615234 
	C1817.419678,2796.848145 1818.568481,2797.883545 1820.013916,2799.427246 
	C1821.178101,2801.011963 1822.078125,2802.060059 1823.500488,2803.569092 
	C1826.286621,2808.462402 1828.604370,2812.847656 1831.428345,2817.700439 
	C1836.358398,2820.929443 1840.041504,2824.832275 1845.465210,2826.053223 
	C1849.089111,2826.868652 1850.146362,2830.368652 1851.509399,2833.409668 
	C1854.178589,2839.363770 1857.905762,2844.592285 1862.100220,2850.044434 
	C1865.914429,2845.080322 1860.635376,2840.842041 1863.601318,2836.371338 
	C1874.770264,2843.077393 1881.154297,2853.514404 1885.769897,2865.110596 
	C1886.877686,2867.894043 1887.023804,2870.894043 1891.236084,2871.923828 
	C1894.848755,2874.386719 1893.368286,2879.168213 1897.445312,2881.594238 
	C1898.530518,2883.643799 1899.173828,2885.191650 1899.870117,2887.369873 
	C1900.012207,2890.221436 1899.980835,2892.445068 1900.057495,2895.333496 
	C1900.843750,2900.113037 1902.674805,2904.057617 1902.023438,2909.195801 
	C1901.118652,2909.996094 1900.227539,2909.992188 1898.668213,2909.994141 
	C1895.858521,2906.071777 1892.031006,2903.380859 1891.363403,2898.237549 
	C1890.738525,2893.423096 1886.421021,2891.708496 1880.878052,2891.886230 
	C1875.642944,2883.861572 1872.989868,2874.661377 1864.522095,2868.470215 
	C1863.151001,2867.962158 1862.270386,2867.953613 1860.730957,2867.929932 
	C1860.544189,2862.020752 1859.745117,2856.742432 1853.786499,2853.598389 
	C1852.720215,2853.035889 1851.825439,2851.766602 1851.303467,2850.617920 
	C1848.191040,2843.771973 1843.861816,2838.604980 1835.790649,2837.479736 
	C1833.320068,2837.135010 1832.293213,2834.681641 1832.031738,2832.229980 
	C1831.740356,2829.497314 1830.258057,2828.115234 1826.766846,2828.020264 
z"
        />
        <path
          fill="#E9E1D0"
          opacity="1.000000"
          stroke="none"
          d="
M1835.359863,2950.285645 
	C1836.995850,2949.481934 1837.991699,2948.963867 1839.505981,2948.227783 
	C1840.108398,2954.420898 1840.718140,2959.886475 1844.779297,2966.090332 
	C1850.028442,2974.109863 1852.179443,2984.717041 1846.039551,2995.215576 
	C1842.488281,2995.658203 1838.779663,2996.907471 1835.025391,2994.613770 
	C1835.455933,2991.098877 1839.531738,2991.098389 1840.892090,2988.677246 
	C1841.061157,2987.599121 1840.674316,2986.671631 1839.782471,2986.163086 
	C1836.620239,2984.358643 1833.227661,2982.899902 1830.263794,2980.823242 
	C1827.505737,2978.889893 1824.738281,2976.605713 1829.227295,2973.183350 
	C1832.979004,2970.323730 1833.337891,2966.563477 1832.652466,2961.724609 
	C1832.074829,2957.647217 1831.494141,2955.059082 1826.006592,2955.312256 
	C1828.816406,2951.097412 1832.591431,2952.501709 1835.359863,2950.285645 
z"
        />
        <path
          fill="#E9E1D0"
          opacity="1.000000"
          stroke="none"
          d="
M1839.979736,2881.243652 
	C1831.931274,2880.197021 1832.126465,2873.809570 1832.003174,2866.794922 
	C1835.504761,2868.895020 1836.663330,2871.005127 1839.979736,2881.243652 
z"
        />
        <path
          fill="#E9E1D0"
          opacity="1.000000"
          stroke="none"
          d="
M1832.008301,2865.249512 
	C1823.582275,2862.570068 1822.977295,2861.258545 1827.378174,2854.334961 
	C1830.032471,2857.231445 1832.800049,2860.186035 1832.008301,2865.249512 
z"
        />
        <path
          fill="#E9E1D0"
          opacity="1.000000"
          stroke="none"
          d="
M1838.006226,2909.333496 
	C1836.727417,2909.797852 1835.266602,2909.695068 1835.047363,2908.083496 
	C1834.894653,2906.959717 1835.867065,2906.458252 1837.431152,2906.089111 
	C1838.009033,2906.888916 1838.008301,2907.777832 1838.006226,2909.333496 
z"
        />
        <path
          fill="#EBE4D0"
          opacity="1.000000"
          stroke="none"
          d="
M1777.944092,2788.676270 
	C1778.438110,2784.903320 1776.841431,2782.191650 1776.409790,2778.517578 
	C1778.464600,2777.890625 1780.470947,2777.922852 1782.451294,2778.166504 
	C1784.324707,2778.397217 1785.742554,2779.430664 1784.947510,2781.482910 
	C1782.335815,2788.225342 1786.584351,2794.095215 1787.260254,2800.412109 
	C1787.920166,2806.580078 1789.614136,2812.388916 1792.284668,2818.334717 
	C1795.502075,2825.498535 1800.100342,2833.371338 1796.985107,2842.161133 
	C1796.107666,2844.636963 1797.824585,2846.690430 1797.831299,2848.957031 
	C1797.841553,2852.424316 1800.835327,2855.666016 1797.963989,2859.447510 
	C1796.124268,2861.870361 1803.829712,2863.144287 1799.457520,2867.150635 
	C1797.784180,2868.684082 1800.683472,2869.387207 1801.918701,2870.302490 
	C1808.490967,2875.174561 1808.522217,2876.568848 1802.636108,2882.896973 
	C1801.969727,2883.613525 1800.809937,2885.029541 1800.911621,2885.136475 
	C1806.335327,2890.825684 1799.524902,2899.179932 1805.805664,2905.144287 
	C1811.897949,2910.928955 1811.731567,2915.084473 1806.647705,2921.507568 
	C1804.746948,2923.908936 1804.538574,2925.392090 1806.459106,2927.389404 
	C1808.605713,2929.621826 1810.775391,2932.123535 1809.654297,2935.300537 
	C1807.719971,2940.781982 1807.961060,2944.402344 1815.030884,2944.363037 
	C1815.975830,2944.357666 1817.077148,2945.075684 1816.894409,2946.258301 
	C1816.757812,2947.142822 1816.260986,2948.409180 1815.583618,2948.693848 
	C1813.178101,2949.705078 1810.626709,2950.368896 1807.456665,2951.379150 
	C1809.042725,2954.219238 1813.768555,2957.573730 1806.585205,2959.748535 
	C1805.679932,2959.083496 1805.622925,2957.711914 1804.973511,2957.318604 
	C1801.990479,2955.510986 1798.994995,2954.121582 1802.599121,2950.017822 
	C1804.686401,2947.641357 1804.000488,2944.919678 1801.549805,2943.052979 
	C1793.715820,2937.085693 1797.315308,2928.879883 1797.331421,2921.453369 
	C1797.336182,2919.249268 1798.774902,2917.074707 1799.383423,2914.839600 
	C1800.471436,2910.845459 1799.826660,2907.510986 1795.321533,2906.148682 
	C1793.006836,2905.448730 1791.022583,2904.327148 1789.670654,2902.340332 
	C1787.838013,2899.646729 1787.442993,2897.211426 1790.066040,2894.396729 
	C1795.532837,2888.530518 1795.835693,2884.718018 1791.911987,2877.581299 
	C1790.362793,2874.763184 1790.323120,2872.458984 1791.603516,2869.258301 
	C1795.145874,2860.403076 1791.776367,2851.291992 1791.288818,2842.307617 
	C1791.167969,2840.080811 1788.267212,2839.954102 1786.603149,2841.715576 
	C1784.989380,2843.423828 1783.828003,2845.692139 1784.150391,2848.052490 
	C1785.320801,2856.622314 1779.859253,2860.501465 1772.722656,2863.898438 
	C1771.997559,2861.872070 1771.985352,2859.753662 1771.954712,2856.841064 
	C1769.619751,2853.125732 1771.874756,2851.203369 1773.588013,2849.391357 
	C1777.832764,2844.901611 1775.200684,2842.716553 1770.465088,2840.496338 
	C1769.346069,2839.095947 1768.737671,2838.139893 1768.078979,2836.596924 
	C1769.520020,2831.790771 1771.189941,2827.613525 1771.469482,2822.469238 
	C1771.898560,2821.051514 1771.910156,2820.179932 1771.923096,2818.654297 
	C1771.994629,2816.401123 1772.000977,2814.800537 1772.035156,2812.600098 
	C1772.036621,2809.872803 1772.026855,2807.745361 1772.020752,2804.820801 
	C1776.403076,2800.101562 1776.979492,2794.663086 1777.944092,2788.676270 
z"
        />
        <path
          fill="#EBE4D0"
          opacity="1.000000"
          stroke="none"
          d="
M1768.107178,2738.891113 
	C1771.359741,2738.823975 1774.723877,2735.904053 1777.971924,2739.122070 
	C1777.889038,2742.800537 1776.540039,2745.477051 1774.815308,2747.857422 
	C1772.783813,2750.661133 1771.612915,2752.855713 1775.935913,2754.596680 
	C1778.125244,2755.478760 1778.412109,2757.955811 1777.804321,2759.694336 
	C1776.248901,2764.142822 1777.809204,2768.636230 1776.576172,2773.518066 
	C1770.841431,2770.002441 1772.598267,2762.291260 1766.965942,2757.974121 
	C1764.073120,2756.022705 1764.025513,2753.815674 1765.558838,2750.710449 
	C1768.279785,2749.686768 1769.937012,2748.833740 1769.004883,2746.264648 
	C1768.215088,2744.087891 1767.969604,2741.898193 1768.107178,2738.891113 
z"
        />
        <path
          fill="#E1D9C6"
          opacity="1.000000"
          stroke="none"
          d="
M1672.656494,3151.889404 
	C1670.200439,3147.761719 1668.386353,3143.510986 1666.286133,3138.629883 
	C1663.860840,3135.356689 1660.337280,3136.624512 1657.834595,3135.137451 
	C1654.167847,3132.958740 1650.645020,3129.227051 1653.842529,3125.655762 
	C1658.589355,3120.352783 1657.139648,3114.808105 1657.558594,3108.538574 
	C1659.608765,3107.483398 1661.217529,3106.995605 1663.410645,3106.249023 
	C1669.568970,3102.548340 1670.543823,3096.323975 1673.509766,3090.502930 
	C1673.879639,3084.187744 1680.234009,3079.980957 1677.986084,3072.831055 
	C1676.721313,3068.678223 1679.360718,3067.038330 1681.503662,3064.482910 
	C1681.985229,3062.497559 1681.985107,3061.004883 1681.980225,3058.765869 
	C1681.228516,3057.235596 1680.940308,3056.309082 1681.548096,3054.614990 
	C1681.993652,3053.107910 1682.001831,3052.225342 1682.014893,3050.681152 
	C1688.692261,3045.251709 1691.610229,3050.520264 1695.457520,3055.396973 
	C1696.029297,3055.960938 1697.017090,3055.978027 1697.510986,3055.989014 
	C1699.251099,3057.289062 1700.682129,3058.443848 1701.190308,3060.987793 
	C1701.130493,3062.104492 1701.014771,3062.543213 1700.447021,3063.491211 
	C1692.229370,3065.720215 1691.930054,3065.827393 1694.153320,3072.042725 
	C1695.100342,3074.690186 1694.270020,3075.543701 1692.421631,3076.712402 
	C1690.771973,3077.755127 1688.500122,3077.641113 1687.335571,3079.438721 
	C1687.130859,3080.704346 1687.867676,3081.217773 1688.815063,3081.687988 
	C1694.093628,3084.309326 1694.437134,3085.198486 1690.333862,3087.634521 
	C1686.625732,3089.835938 1684.167358,3091.145508 1686.786743,3096.372314 
	C1687.811523,3098.417236 1683.773560,3102.166992 1686.882690,3105.968750 
	C1688.251587,3107.642578 1685.110107,3109.788330 1683.223145,3111.143555 
	C1680.863525,3112.838867 1677.193359,3110.808105 1675.143799,3113.911865 
	C1676.780640,3117.370117 1680.929688,3114.835205 1682.903564,3117.439941 
	C1683.619263,3120.458740 1681.135376,3122.212402 1679.528931,3124.091553 
	C1677.401855,3126.579834 1674.332642,3132.139160 1673.774658,3134.778564 
	C1672.825562,3139.268066 1670.629761,3143.644287 1674.542480,3147.914307 
	C1675.930664,3149.429199 1675.211060,3150.958740 1672.656494,3151.889404 
z"
        />
        <path
          fill="#D8D1C0"
          opacity="1.000000"
          stroke="none"
          d="
M1657.916260,3107.303711 
	C1658.094727,3110.031494 1659.269287,3111.612305 1660.170532,3113.375244 
	C1663.145386,3119.194824 1662.785645,3120.326172 1657.550537,3124.467529 
	C1654.034058,3127.249268 1654.187256,3129.333984 1657.331421,3132.249268 
	C1659.801880,3134.540283 1664.199463,3132.771973 1665.851685,3137.310059 
	C1655.229980,3138.851074 1651.896484,3143.432373 1653.989014,3155.264404 
	C1654.167603,3162.291992 1646.686157,3162.296631 1644.463745,3167.500977 
	C1643.986084,3167.982422 1644.000000,3168.000000 1643.992676,3167.995117 
	C1634.190552,3172.357910 1629.233276,3169.494873 1629.149292,3159.452881 
	C1629.112427,3155.035645 1630.557373,3150.989014 1632.188354,3146.995605 
	C1633.759277,3143.149658 1634.201172,3139.340088 1632.082031,3134.745605 
	C1632.856323,3132.247070 1633.640869,3130.402100 1634.749756,3128.779053 
	C1636.475464,3126.252686 1638.337158,3123.763916 1641.871704,3126.533691 
	C1642.956299,3127.383545 1644.379883,3127.715332 1645.214722,3126.167236 
	C1645.900757,3124.895020 1645.683472,3123.098389 1644.294922,3122.854492 
	C1636.387939,3121.465332 1639.082886,3117.735596 1641.642456,3113.286133 
	C1645.062500,3107.340576 1647.873291,3101.044434 1651.468018,3094.449707 
	C1652.587769,3098.836182 1655.028687,3102.820068 1657.916260,3107.303711 
z"
        />
        <path
          fill="#D8D1C0"
          opacity="1.000000"
          stroke="none"
          d="
M1673.233398,3089.999023 
	C1673.933105,3092.874512 1674.147949,3095.799072 1673.801025,3098.655518 
	C1673.230225,3103.356201 1670.929443,3106.470459 1664.789062,3105.991211 
	C1661.426270,3095.609375 1662.381104,3093.815186 1673.233398,3089.999023 
z"
        />
        <path
          fill="#D8D1C0"
          opacity="1.000000"
          stroke="none"
          d="
M1696.019653,3055.166260 
	C1693.438354,3055.993896 1691.002441,3055.818359 1689.887939,3052.838867 
	C1688.760010,3049.822998 1686.061768,3050.089600 1682.791504,3050.018555 
	C1682.272461,3046.582520 1680.953491,3042.340576 1685.596680,3040.857666 
	C1689.187012,3039.711182 1693.617310,3043.419434 1695.158691,3048.586914 
	C1695.709961,3050.435303 1695.741333,3052.438965 1696.019653,3055.166260 
z"
        />
        <path
          fill="#D8D1C0"
          opacity="1.000000"
          stroke="none"
          d="
M1687.006592,3035.812988 
	C1684.804565,3033.280518 1683.577393,3031.141846 1687.302368,3030.270996 
	C1691.305298,3029.335205 1695.188965,3030.050049 1698.312134,3032.878662 
	C1700.417358,3034.786377 1705.450562,3030.249023 1705.145264,3035.173584 
	C1704.868408,3039.638428 1699.973877,3037.729492 1697.027344,3037.930176 
	C1693.826050,3038.147949 1690.436768,3038.545410 1687.006592,3035.812988 
z"
        />
        <path
          fill="#D8D1C0"
          opacity="1.000000"
          stroke="none"
          d="
M1700.508301,3062.491943 
	C1699.383423,3060.431396 1698.752319,3058.853271 1698.062988,3056.637451 
	C1702.572388,3055.823975 1705.884277,3057.186279 1705.984863,3063.250488 
	C1704.077026,3064.909912 1702.444458,3064.404541 1700.508301,3062.491943 
z"
        />
        <path
          fill="#D8D1C0"
          opacity="1.000000"
          stroke="none"
          d="
M1681.307373,3064.078125 
	C1682.098999,3066.893555 1680.089478,3068.742920 1678.284668,3071.423340 
	C1674.505371,3067.920410 1676.668457,3065.740234 1681.307373,3064.078125 
z"
        />
        <path
          fill="#D8D1C0"
          opacity="1.000000"
          stroke="none"
          d="
M1681.312134,3054.007324 
	C1681.987183,3054.885498 1681.989014,3055.780762 1681.983154,3057.347656 
	C1680.232056,3057.748291 1677.677856,3058.595703 1677.247925,3056.173828 
	C1676.855469,3053.962158 1679.207764,3054.327881 1681.312134,3054.007324 
z"
        />
        <path
          fill="#C4BDAE"
          opacity="1.000000"
          stroke="none"
          d="
M1691.175049,3004.472168 
	C1690.403076,3003.429932 1690.468994,3003.178955 1691.175049,3004.472168 
z"
        />
        <path
          fill="#D1C9BA"
          opacity="1.000000"
          stroke="none"
          d="
M1700.009766,3062.507324 
	C1701.619995,3062.502441 1703.225220,3062.995361 1705.415283,3063.744141 
	C1704.373901,3065.935059 1703.188965,3070.031006 1700.087158,3064.594238 
	C1699.995117,3064.000000 1700.004883,3063.004883 1700.009766,3062.507324 
z"
        />
        <path
          fill="#E9E1D0"
          opacity="1.000000"
          stroke="none"
          d="
M1710.665894,2816.056641 
	C1710.452881,2810.383545 1708.851318,2804.701904 1711.768311,2798.637695 
	C1716.282837,2801.385742 1715.898193,2806.739014 1717.321655,2811.276367 
	C1718.324341,2814.472412 1716.744995,2818.231689 1719.228149,2821.404785 
	C1720.004883,2822.397217 1718.958984,2823.936523 1717.659424,2824.481445 
	C1716.080566,2825.143555 1715.274170,2823.913086 1714.650513,2822.778809 
	C1713.471313,2820.634521 1714.615723,2817.318604 1710.665894,2816.056641 
z"
        />
        <path
          fill="#CBC5B5"
          opacity="1.000000"
          stroke="none"
          d="
M1716.576904,2791.783203 
	C1716.293701,2789.399658 1715.115356,2785.964355 1719.945557,2785.753174 
	C1719.858521,2788.227539 1719.021362,2790.174561 1716.576904,2791.783203 
z"
        />
        <path
          fill="#CBC5B5"
          opacity="1.000000"
          stroke="none"
          d="
M1712.517822,2796.066895 
	C1712.114868,2794.476807 1712.530273,2792.752197 1715.401367,2792.091797 
	C1715.063965,2793.245605 1714.052856,2794.493164 1712.517822,2796.066895 
z"
        />
        <path
          fill="#E9E1D0"
          opacity="1.000000"
          stroke="none"
          d="
M1703.539062,2873.402344 
	C1703.830688,2876.329346 1702.861816,2879.001465 1703.697510,2880.864502 
	C1707.978760,2890.408447 1705.976807,2899.426025 1702.295166,2908.459717 
	C1702.055542,2909.047852 1702.122070,2909.760986 1702.018066,2911.208008 
	C1701.963257,2913.172607 1701.641602,2914.246582 1700.575439,2915.641602 
	C1699.301392,2919.944580 1701.721558,2924.591553 1696.744629,2927.830078 
	C1688.759277,2926.589111 1692.219360,2920.221680 1690.364258,2915.453857 
	C1692.472290,2900.046387 1693.923706,2885.245361 1698.472656,2870.413086 
	C1701.816162,2868.894531 1702.734619,2870.447754 1703.539062,2873.402344 
z"
        />
        <path
          fill="#EEE7D5"
          opacity="1.000000"
          stroke="none"
          d="
M1691.213989,2913.567871 
	C1695.973267,2917.604004 1692.498291,2922.955566 1695.478882,2927.473145 
	C1696.006714,2928.892578 1695.997314,2929.775635 1695.943604,2931.319336 
	C1692.160767,2935.127441 1687.503540,2937.028809 1683.351440,2940.847656 
	C1678.247070,2940.464844 1676.650269,2936.764648 1677.950562,2933.940430 
	C1681.327271,2926.605957 1683.023682,2918.064697 1691.213989,2913.567871 
z"
        />
        <path
          fill="#E9E1D0"
          opacity="1.000000"
          stroke="none"
          d="
M1684.140625,2940.509766 
	C1685.452271,2932.475098 1685.452271,2932.475098 1695.099243,2931.984619 
	C1696.095825,2935.298828 1696.207642,2938.590332 1692.702393,2941.413330 
	C1690.740234,2941.963135 1689.177490,2941.968994 1686.838379,2941.972656 
	C1685.353760,2941.867432 1684.757568,2941.557861 1684.140625,2940.509766 
z"
        />
        <path
          fill="#D1C9BA"
          opacity="1.000000"
          stroke="none"
          d="
M1704.075684,2873.304688 
	C1702.999390,2872.872559 1702.031494,2871.714844 1700.603271,2870.214844 
	C1697.923462,2870.590576 1697.449219,2869.263672 1697.813721,2866.846680 
	C1700.642334,2866.113525 1701.741699,2864.309570 1703.490479,2862.355225 
	C1707.887207,2865.455566 1704.928467,2869.037842 1704.075684,2873.304688 
z"
        />
        <path
          fill="#D1C9BA"
          opacity="1.000000"
          stroke="none"
          d="
M1700.768311,2915.989258 
	C1700.497925,2914.969482 1700.770264,2913.844482 1701.516357,2912.359863 
	C1703.959595,2913.678711 1703.614258,2914.952881 1700.768311,2915.989258 
z"
        />
        <path
          fill="#E1D9C6"
          opacity="1.000000"
          stroke="none"
          d="
M1722.882446,3155.908691 
	C1723.629761,3154.547607 1724.381592,3154.529297 1725.046021,3155.502930 
	C1725.185181,3155.706787 1725.179688,3156.178955 1725.034912,3156.377441 
	C1724.342529,3157.325684 1723.591064,3157.292236 1722.882446,3155.908691 
z"
        />
        <path
          fill="#DAD3C3"
          opacity="1.000000"
          stroke="none"
          d="
M1761.863525,2886.499512 
	C1766.819580,2886.954834 1767.506958,2890.686768 1767.951538,2895.308105 
	C1763.830811,2894.467773 1759.953003,2892.729492 1761.863525,2886.499512 
z"
        />
        <path
          fill="#374549"
          opacity="1.000000"
          stroke="none"
          d="
M2263.984375,3082.404297 
	C2265.556885,3076.695312 2269.582764,3072.957031 2273.050293,3068.119629 
	C2280.612061,3064.072510 2286.372803,3068.466553 2293.193359,3070.813477 
	C2295.369141,3071.050049 2296.784180,3071.333740 2298.810791,3072.052246 
	C2301.471436,3073.162598 2302.992920,3075.054443 2306.039551,3075.174805 
	C2309.812988,3075.454102 2312.750732,3076.389648 2316.222656,3077.757812 
	C2317.117432,3078.274414 2317.439209,3078.511719 2318.113281,3079.195068 
	C2318.696777,3080.284180 2318.278320,3081.207520 2318.778564,3081.584717 
	C2319.832520,3082.381348 2318.277832,3081.863770 2319.904785,3080.835938 
	C2323.243164,3080.430908 2325.750244,3081.388428 2328.916016,3082.639648 
	C2340.958252,3089.842773 2354.331299,3091.601807 2366.247070,3097.141113 
	C2370.352539,3099.049561 2375.252441,3099.771484 2376.966797,3105.691650 
	C2374.285400,3112.779785 2367.596436,3109.572021 2363.210449,3112.067627 
	C2364.312988,3115.063965 2367.273193,3115.316650 2369.388184,3116.678223 
	C2371.295410,3117.906494 2374.396484,3118.797363 2373.833984,3121.322021 
	C2373.186523,3124.226074 2369.799805,3125.001709 2367.257080,3124.497559 
	C2349.220215,3120.919189 2330.714355,3119.210449 2313.194824,3110.903809 
	C2311.949463,3108.910645 2311.579590,3107.411377 2311.444824,3105.086914 
	C2315.349854,3095.437744 2316.315430,3094.953125 2331.208496,3095.322754 
	C2326.267822,3093.866943 2322.911621,3092.952881 2319.495361,3092.204346 
	C2315.554932,3091.340332 2311.605225,3092.061279 2307.670410,3091.961426 
	C2292.729004,3091.581787 2278.377441,3088.089355 2263.984375,3082.404297 
z"
        />
        <path
          fill="#172837"
          opacity="1.000000"
          stroke="none"
          d="
M2263.036133,3082.089844 
	C2276.522705,3082.529297 2288.739746,3087.117676 2301.488281,3088.899414 
	C2303.821533,3089.225830 2305.909424,3089.508789 2308.269287,3088.917725 
	C2318.260986,3086.416504 2327.107666,3090.635010 2336.024414,3094.099121 
	C2337.853271,3094.809326 2339.867432,3096.266602 2338.692627,3098.572754 
	C2337.852783,3100.220947 2335.787354,3099.726074 2334.214844,3099.106445 
	C2330.039307,3097.460693 2325.688965,3098.194092 2321.404053,3098.095703 
	C2317.733643,3098.011719 2314.874512,3099.210205 2313.949707,3104.081299 
	C2311.859375,3106.220459 2309.627930,3107.250000 2307.321045,3106.764404 
	C2291.244873,3103.381836 2275.288818,3099.516113 2259.809570,3093.924072 
	C2258.562744,3093.473389 2257.790039,3092.278320 2257.278320,3090.209473 
	C2259.067139,3087.024414 2260.776123,3084.698486 2263.036133,3082.089844 
z"
        />
        <path
          fill="#564031"
          opacity="1.000000"
          stroke="none"
          d="
M2229.422119,3128.343994 
	C2232.105957,3123.931885 2234.459717,3120.333008 2237.312744,3116.330566 
	C2238.885254,3115.154053 2239.958740,3113.833496 2241.212646,3114.003418 
	C2251.020996,3115.334229 2257.338135,3111.240723 2261.628662,3102.430908 
	C2263.434082,3098.722900 2267.697998,3097.376221 2272.046387,3098.305908 
	C2279.138184,3099.822021 2286.449951,3100.489990 2293.645508,3104.222168 
	C2294.353516,3105.286865 2294.547607,3105.764404 2294.670898,3107.006836 
	C2292.867432,3110.262695 2290.101318,3110.987305 2287.569092,3110.464844 
	C2277.285889,3108.343262 2271.058594,3114.440674 2264.301025,3120.605957 
	C2256.792480,3127.456543 2252.541504,3137.609375 2241.799072,3142.085449 
	C2236.906494,3142.606201 2233.200439,3141.556396 2229.374512,3138.368652 
	C2227.692139,3134.806396 2228.037842,3131.970947 2229.422119,3128.343994 
z"
        />
        <path
          fill="#646A6C"
          opacity="1.000000"
          stroke="none"
          d="
M2294.281738,3106.406250 
	C2286.927246,3103.434814 2279.675537,3102.673584 2272.702148,3100.843018 
	C2267.423584,3099.457031 2264.436523,3101.395020 2262.161865,3105.604980 
	C2261.224854,3107.339355 2260.651855,3109.266113 2259.824219,3111.064209 
	C2256.922363,3117.366699 2254.900146,3118.537598 2248.297119,3116.909180 
	C2245.360840,3116.185059 2242.510498,3115.611816 2238.658936,3115.915527 
	C2242.775391,3106.434570 2249.923828,3098.474854 2256.736328,3089.596680 
	C2261.073975,3093.228516 2266.373291,3093.125977 2270.997070,3094.416992 
	C2284.609131,3098.217773 2297.926758,3103.324219 2313.012451,3104.700195 
	C2313.851318,3106.077393 2313.864746,3107.246582 2313.798340,3109.010010 
	C2312.448242,3112.275146 2310.016846,3112.810791 2306.682617,3112.928223 
	C2302.587646,3110.181152 2298.380615,3109.121094 2294.281738,3106.406250 
z"
        />
        <path
          fill="#BA815C"
          opacity="1.000000"
          stroke="none"
          d="
M2300.443359,3032.346680 
	C2300.452881,3030.520752 2300.693115,3029.117432 2301.475830,3028.156250 
	C2317.883789,3008.013916 2330.648682,2985.572510 2343.960938,2962.787109 
	C2349.754150,2971.907715 2342.174561,2978.513916 2339.926758,2986.961914 
	C2328.384033,3001.564209 2319.550537,3017.114258 2308.855713,3032.296875 
	C2306.963379,3033.685059 2305.475098,3034.029053 2303.111816,3033.854004 
	C2301.864990,3033.462402 2301.395752,3033.218750 2300.443359,3032.346680 
z"
        />
        <path
          fill="#564031"
          opacity="1.000000"
          stroke="none"
          d="
M2221.857178,3175.467773 
	C2216.731689,3177.504639 2216.363281,3183.887695 2210.224609,3184.100586 
	C2208.901611,3184.146973 2208.281494,3184.123047 2207.039062,3183.403809 
	C2203.396729,3175.422119 2204.110596,3168.194580 2207.171387,3160.170654 
	C2210.034180,3155.830566 2212.484375,3152.317383 2215.400635,3148.363037 
	C2221.526611,3144.982422 2223.548340,3148.423096 2224.890137,3153.063965 
	C2225.923584,3156.637939 2224.912598,3160.227051 2224.986328,3164.786377 
	C2225.261963,3169.106934 2225.120361,3172.350098 2221.857178,3175.467773 
z"
        />
        <path
          fill="#5E4237"
          opacity="1.000000"
          stroke="none"
          d="
M2299.463379,3032.187744 
	C2300.450928,3031.773438 2300.941650,3031.828613 2302.173340,3031.888428 
	C2304.740967,3036.605225 2301.150391,3040.252441 2300.227539,3045.161621 
	C2298.820312,3048.988525 2297.300049,3051.998291 2296.345215,3055.980469 
	C2294.283447,3061.834473 2296.204834,3067.221191 2294.813721,3073.038086 
	C2287.592285,3073.113770 2282.068848,3068.254395 2274.402832,3067.831543 
	C2282.060791,3056.062744 2290.513672,3044.360107 2299.463379,3032.187744 
z"
        />
        <path
          fill="#8C8D89"
          opacity="1.000000"
          stroke="none"
          d="
M2223.961182,3166.860596 
	C2221.020508,3162.808838 2222.083252,3158.486572 2222.019775,3154.299072 
	C2221.970947,3151.087158 2221.541016,3148.354004 2216.695312,3148.021973 
	C2216.881836,3142.840332 2220.200439,3139.243408 2224.259766,3135.429688 
	C2226.355713,3135.293457 2227.750977,3135.828369 2229.532715,3137.315430 
	C2233.544189,3138.545166 2236.969727,3139.295410 2241.148682,3140.091309 
	C2242.201172,3140.505859 2242.500732,3140.875000 2242.826660,3141.957031 
	C2238.518311,3152.054688 2233.172852,3160.682129 2223.961182,3166.860596 
z"
        />
        <path
          fill="#DAD3C3"
          opacity="1.000000"
          stroke="none"
          d="
M2169.733887,3174.730957 
	C2158.068115,3176.849854 2146.128418,3169.949219 2139.574707,3156.969727 
	C2144.615234,3156.152100 2148.398438,3159.451416 2153.318115,3161.761963 
	C2157.965820,3167.546631 2165.433838,3168.636963 2169.733887,3174.730957 
z"
        />
        <path
          fill="#797771"
          opacity="1.000000"
          stroke="none"
          d="
M2206.963867,3159.629395 
	C2207.792236,3166.816162 2208.000732,3174.288574 2208.182129,3182.662598 
	C2202.989502,3187.663330 2201.134033,3181.760254 2196.978760,3179.111328 
	C2196.393555,3170.254150 2202.793945,3165.888672 2206.963867,3159.629395 
z"
        />
        <path
          fill="#E7E3DA"
          opacity="1.000000"
          stroke="none"
          d="
M2327.942383,2910.591309 
	C2329.653320,2910.538086 2331.186035,2911.181641 2333.342773,2911.876953 
	C2333.888428,2916.493164 2329.913330,2919.828125 2329.994629,2925.186035 
	C2328.995605,2926.468506 2328.018311,2927.014648 2326.528076,2927.785156 
	C2325.673096,2928.263184 2325.331299,2928.516602 2324.479980,2928.385010 
	C2323.923096,2926.378418 2324.409912,2924.478271 2323.735352,2923.183350 
	C2320.854980,2917.655762 2323.563232,2914.277588 2327.942383,2910.591309 
z"
        />
        <path
          fill="#EEE9DC"
          opacity="1.000000"
          stroke="none"
          d="
M2323.268555,2928.159668 
	C2324.311279,2928.000244 2324.652100,2928.000732 2325.503906,2928.005371 
	C2324.927002,2932.629639 2323.878174,2937.267090 2320.163818,2940.815674 
	C2319.555908,2936.324951 2318.719727,2931.885986 2323.268555,2928.159668 
z"
        />
        <path
          fill="#BA815C"
          opacity="1.000000"
          stroke="none"
          d="
M2343.925049,2961.928223 
	C2345.286865,2958.658936 2346.760254,2955.500000 2348.734131,2951.919434 
	C2350.411865,2951.655518 2351.452881,2952.120117 2352.873291,2953.596436 
	C2354.384277,2956.590332 2354.360107,2958.874512 2352.888184,2961.885498 
	C2350.278320,2964.130859 2348.032227,2964.277100 2344.964600,2962.789307 
	C2344.259521,2962.259033 2344.036621,2962.038574 2343.925049,2961.928223 
z"
        />
        <path
          fill="#646A6C"
          opacity="1.000000"
          stroke="none"
          d="
M2230.037109,3136.947266 
	C2228.492188,3137.878174 2227.046631,3137.455811 2225.344727,3135.686279 
	C2226.083252,3132.800049 2227.348633,3130.504395 2229.183105,3127.869629 
	C2229.820068,3130.386963 2229.887939,3133.243164 2230.037109,3136.947266 
z"
        />
        <path
          fill="#8C5B43"
          opacity="1.000000"
          stroke="none"
          d="
M2352.687988,2953.868896 
	C2351.361328,2953.737793 2350.701172,2953.150879 2349.637695,2952.030762 
	C2349.781250,2949.911865 2350.327881,2948.325928 2351.367676,2946.353271 
	C2352.317383,2945.682617 2352.773926,2945.398438 2353.920410,2945.533691 
	C2356.034424,2948.750000 2355.184326,2951.164062 2352.687988,2953.868896 
z"
        />
        <path
          fill="#797771"
          opacity="1.000000"
          stroke="none"
          d="
M2196.211914,3181.512207 
	C2194.846924,3182.100342 2194.064941,3182.107178 2192.697021,3182.091553 
	C2192.104248,3180.134277 2193.224121,3179.025635 2195.486816,3178.185303 
	C2196.101807,3178.066406 2196.013672,3177.998047 2195.973389,3177.959229 
	C2196.737549,3178.775879 2197.024902,3179.779541 2196.211914,3181.512207 
z"
        />
        <path
          fill="#EEE9DC"
          opacity="1.000000"
          stroke="none"
          d="
M2336.889893,2943.909424 
	C2337.629639,2942.559570 2338.373779,2942.541260 2339.031738,2943.506836 
	C2339.169189,2943.708984 2339.163818,2944.176270 2339.020752,2944.373291 
	C2338.335449,2945.314941 2337.591553,2945.280518 2336.889893,2943.909424 
z"
        />
        <path
          fill="#765242"
          opacity="1.000000"
          stroke="none"
          d="
M2354.373047,2945.556152 
	C2353.647461,2946.022705 2353.290527,2946.006592 2352.397217,2945.978516 
	C2352.007080,2945.266113 2352.153076,2944.565430 2352.687012,2943.482910 
	C2353.074951,2943.100830 2353.575195,2943.576172 2353.817871,2943.821289 
	C2354.287598,2944.402100 2354.514648,2944.737549 2354.373047,2945.556152 
z"
        />
        <path
          fill="#E7E3DA"
          opacity="1.000000"
          stroke="none"
          d="
M2242.694824,2993.997314 
	C2242.006348,2992.492188 2242.012939,2991.007324 2242.029297,2988.780762 
	C2242.039062,2988.039062 2243.000488,2988.006836 2243.480713,2987.988770 
	C2248.262207,2989.175537 2252.349609,2985.293945 2257.330566,2987.713379 
	C2253.763672,2996.815674 2252.218018,2997.454590 2242.694824,2993.997314 
z"
        />
        <path
          fill="#D3CBBF"
          opacity="1.000000"
          stroke="none"
          d="
M2182.498535,2961.611328 
	C2175.597900,2967.016602 2169.986572,2972.802002 2164.602051,2979.704590 
	C2162.940674,2981.094971 2161.844238,2982.133545 2160.308105,2983.530273 
	C2159.875000,2983.895508 2158.936523,2983.927246 2158.468262,2983.962402 
	C2154.945312,2983.908203 2153.031982,2982.636230 2153.556641,2978.638184 
	C2155.612305,2976.818115 2157.235352,2975.648193 2159.387207,2974.187988 
	C2161.061768,2972.928467 2162.110352,2971.861084 2163.445312,2970.294678 
	C2172.444092,2956.610840 2183.994629,2945.786133 2194.106445,2932.985352 
	C2195.542969,2930.192627 2197.145020,2928.336914 2199.660156,2926.484863 
	C2200.127197,2926.081055 2201.049805,2926.023926 2201.508789,2925.967773 
	C2203.863770,2923.120605 2205.308594,2919.885254 2209.414551,2918.371094 
	C2209.877197,2917.929443 2209.969238,2917.975586 2209.950195,2917.927734 
	C2211.492432,2916.519043 2211.743164,2914.324707 2213.546387,2912.420654 
	C2214.348389,2910.916992 2214.730957,2909.843750 2215.558594,2908.401855 
	C2220.208740,2906.124756 2223.658203,2902.875488 2227.926514,2901.135986 
	C2231.690186,2899.602539 2236.108643,2898.371582 2238.455322,2902.804199 
	C2240.501221,2906.668945 2237.196777,2909.494873 2234.493164,2911.979980 
	C2232.313721,2913.983154 2228.877197,2914.095459 2226.634033,2917.424072 
	C2226.103271,2918.031738 2226.003906,2918.979980 2225.965088,2919.455078 
	C2217.296143,2934.630859 2202.265625,2942.946533 2190.450684,2955.454590 
	C2189.939941,2955.929932 2190.000000,2956.000000 2189.965088,2955.969971 
	C2188.008789,2958.215820 2185.760010,2960.060791 2182.498535,2961.611328 
z"
        />
        <path
          fill="#F2EBDA"
          opacity="1.000000"
          stroke="none"
          d="
M2222.005371,2944.765137 
	C2224.534668,2943.228271 2227.106445,2942.557129 2229.589844,2941.645508 
	C2230.973389,2941.137451 2231.804443,2939.792236 2230.829590,2938.494629 
	C2227.515625,2934.083008 2228.870605,2932.207275 2233.991699,2931.550293 
	C2236.378662,2931.244385 2238.190674,2929.308594 2239.195312,2927.204590 
	C2241.089844,2923.237061 2243.719727,2922.205811 2248.023438,2923.211426 
	C2250.593506,2923.812256 2254.030518,2920.612793 2255.992432,2924.421875 
	C2257.845215,2928.019531 2263.933594,2929.148682 2263.180176,2933.244141 
	C2262.286133,2938.104492 2256.388428,2935.630127 2252.912109,2937.230469 
	C2244.967773,2940.887695 2235.906738,2941.977295 2229.555664,2949.131836 
	C2225.516602,2953.681885 2223.675537,2952.619629 2222.005371,2944.765137 
z"
        />
        <path
          fill="#E9E1D0"
          opacity="1.000000"
          stroke="none"
          d="
M2190.675781,2955.867676 
	C2190.086670,2953.882080 2190.754883,2951.968750 2192.370605,2950.719727 
	C2199.993164,2944.824951 2203.715576,2934.507324 2214.162842,2931.753174 
	C2216.957520,2931.016357 2217.598633,2928.117920 2218.449951,2925.564941 
	C2219.396484,2922.727051 2220.739258,2919.998535 2225.191406,2919.970703 
	C2225.949219,2920.276855 2225.972168,2920.623535 2226.004395,2921.491211 
	C2222.799805,2930.212891 2220.385986,2938.709473 2216.420898,2947.394043 
	C2214.986328,2950.386475 2213.982178,2952.776367 2212.489258,2955.583008 
	C2208.788818,2956.239746 2204.952881,2959.321533 2203.138672,2953.575439 
	C2202.634521,2951.979248 2200.391846,2952.087646 2198.728760,2952.770020 
	C2196.285645,2953.772461 2193.850342,2954.792725 2190.675781,2955.867676 
z"
        />
        <path
          fill="#F2EBDA"
          opacity="1.000000"
          stroke="none"
          d="
M2226.815430,2917.930908 
	C2225.907959,2915.188965 2226.312500,2912.913574 2229.598877,2911.765137 
	C2233.133545,2910.530029 2235.747803,2907.997314 2237.332764,2903.840088 
	C2233.879639,2900.647461 2230.596680,2902.582520 2227.609619,2904.353027 
	C2224.449951,2906.226318 2221.564697,2908.556641 2216.785645,2908.030273 
	C2219.230225,2903.395508 2222.786133,2899.435791 2228.487305,2897.389404 
	C2235.236328,2894.967285 2241.045898,2890.858398 2245.296631,2884.137207 
	C2247.304932,2884.188965 2249.436279,2884.926270 2249.052734,2885.794678 
	C2246.791016,2890.916748 2251.095215,2894.249512 2252.114258,2899.158447 
	C2248.271729,2899.783936 2245.132324,2900.924316 2243.559814,2904.912109 
	C2240.671875,2912.235107 2235.061035,2916.240723 2226.815430,2917.930908 
z"
        />
        <path
          fill="#F2EBDA"
          opacity="1.000000"
          stroke="none"
          d="
M2216.535645,2947.734863 
	C2215.239014,2938.293945 2221.126709,2930.953613 2225.471191,2922.441650 
	C2226.311768,2929.126221 2222.973877,2935.532715 2222.022217,2943.204102 
	C2220.360352,2945.157715 2218.720947,2946.315674 2216.535645,2947.734863 
z"
        />
        <path
          fill="#E9E1D0"
          opacity="1.000000"
          stroke="none"
          d="
M2153.292969,2978.061035 
	C2153.979980,2980.376709 2155.611084,2981.688232 2157.634766,2983.489746 
	C2157.619629,2988.269287 2155.125488,2992.002686 2151.867920,2993.596680 
	C2144.377686,2997.261475 2138.541260,3003.067627 2131.676270,3007.474121 
	C2130.365967,3008.314941 2129.023682,3010.109863 2127.457031,3008.755859 
	C2125.512939,3007.075928 2127.864258,3005.183838 2127.951172,3002.685059 
	C2128.819824,3001.018555 2129.416016,2999.711182 2130.432373,2999.045410 
	C2136.429932,2995.116699 2141.896484,2990.839600 2145.300537,2984.182861 
	C2146.710938,2981.424316 2149.164551,2978.940918 2153.292969,2978.061035 
z"
        />
        <path
          fill="#F2EBDA"
          opacity="1.000000"
          stroke="none"
          d="
M2209.142090,2917.961670 
	C2208.449463,2921.161133 2205.766602,2923.257568 2202.546875,2925.652344 
	C2201.832275,2921.416992 2203.225098,2918.165039 2209.142090,2917.961670 
z"
        />
        <path
          fill="#F2EBDA"
          opacity="1.000000"
          stroke="none"
          d="
M2199.367676,2926.075928 
	C2199.843262,2929.023682 2198.475342,2931.122314 2194.881348,2932.154053 
	C2193.871582,2928.960693 2194.254883,2926.126709 2199.367676,2926.075928 
z"
        />
        <path
          fill="#F2EBDA"
          opacity="1.000000"
          stroke="none"
          d="
M2213.296875,2912.113281 
	C2214.000977,2914.308838 2213.116211,2916.142090 2210.524170,2917.633789 
	C2210.097900,2915.649170 2209.791016,2913.191650 2213.296875,2912.113281 
z"
        />
        <path
          fill="#E9E1D0"
          opacity="1.000000"
          stroke="none"
          d="
M2163.175781,2969.985352 
	C2163.810547,2971.729492 2163.326416,2973.280029 2160.566406,2973.834473 
	C2160.132324,2972.168945 2160.752441,2970.725098 2163.175781,2969.985352 
z"
        />
        <path
          fill="#33495B"
          opacity="1.000000"
          stroke="none"
          d="
M532.920898,2646.001221 
	C532.676941,2643.171631 533.353882,2640.353027 534.035339,2636.802246 
	C534.678406,2635.383789 535.470703,2634.999268 537.067017,2634.967041 
	C538.141296,2635.268066 538.542297,2635.473145 539.450073,2636.112793 
	C544.364685,2641.108643 547.582031,2636.886719 551.619873,2634.620605 
	C577.425293,2634.098145 602.731384,2633.989990 628.960999,2633.813477 
	C632.679688,2633.850098 635.474854,2633.955811 639.103638,2634.060059 
	C637.164734,2639.700684 631.859070,2641.531250 626.358887,2641.834229 
	C614.529968,2642.485840 602.788330,2642.114502 590.925720,2644.644775 
	C584.339111,2646.049561 576.580688,2644.227051 569.340515,2643.937988 
	C557.423096,2643.462402 545.796204,2647.490479 532.920898,2646.001221 
z"
        />
        <path
          fill="#374D60"
          opacity="1.000000"
          stroke="none"
          d="
M828.390137,2626.425293 
	C830.031372,2623.659912 832.764954,2624.016357 835.385010,2624.010254 
	C842.990540,2623.992432 850.596130,2624.001709 858.201660,2624.001465 
	C858.862976,2624.001465 859.592346,2623.827393 860.175476,2624.043457 
	C868.550842,2627.146240 876.928711,2628.952148 885.907104,2626.248291 
	C888.573608,2625.445068 890.912415,2627.473633 891.921387,2631.156250 
	C890.535339,2632.510986 888.852844,2633.907471 887.517212,2633.630859 
	C875.662109,2631.174072 863.508423,2634.025146 851.594482,2631.057373 
	C844.146851,2629.202148 836.231018,2629.189209 828.390137,2626.425293 
z"
        />
        <path
          fill="#33495B"
          opacity="1.000000"
          stroke="none"
          d="
M644.246765,2634.800781 
	C652.095764,2634.096191 660.107178,2634.133057 669.040161,2634.191895 
	C669.923035,2637.021240 669.526245,2639.822021 665.951477,2639.827881 
	C658.511658,2639.839844 651.147034,2639.187500 644.246765,2634.800781 
z"
        />
        <path
          fill="#33495B"
          opacity="1.000000"
          stroke="none"
          d="
M1107.202271,2617.430176 
	C1107.960693,2618.099854 1107.971924,2618.479736 1107.991577,2619.429932 
	C1102.607422,2619.994385 1097.214722,2619.988770 1090.925049,2619.964355 
	C1089.493774,2618.938721 1088.959473,2617.931641 1088.214355,2616.390625 
	C1094.149902,2616.343262 1100.438721,2614.784912 1107.202271,2617.430176 
z"
        />
        <path
          fill="#495E72"
          opacity="1.000000"
          stroke="none"
          d="
M1204.541504,2648.539551 
	C1208.066772,2644.733398 1212.492432,2645.763184 1217.466919,2647.453369 
	C1213.939941,2651.245850 1209.519287,2650.269531 1204.541504,2648.539551 
z"
        />
        <path
          fill="#374D60"
          opacity="1.000000"
          stroke="none"
          d="
M806.467041,2628.572021 
	C806.946899,2622.562988 811.249329,2624.335693 815.449219,2624.236816 
	C814.557373,2628.717041 811.539307,2630.470947 806.467041,2628.572021 
z"
        />
        <path
          fill="#3C556B"
          opacity="1.000000"
          stroke="none"
          d="
M982.651062,2624.003418 
	C982.493591,2620.803467 983.942505,2619.310059 987.598389,2621.604980 
	C986.853271,2623.778076 985.135803,2624.017578 982.651062,2624.003418 
z"
        />
        <path
          fill="#374D60"
          opacity="1.000000"
          stroke="none"
          d="
M763.562622,2627.525635 
	C761.636047,2627.399902 760.583496,2626.789062 761.321350,2625.249268 
	C761.768311,2624.316650 762.916260,2624.181641 763.799438,2624.610352 
	C765.206482,2625.293701 765.387451,2626.311035 763.562622,2627.525635 
z"
        />
        <path
          fill="#374D60"
          opacity="1.000000"
          stroke="none"
          d="
M956.464172,2629.695312 
	C956.206360,2627.966553 956.890015,2626.513184 959.512817,2626.183105 
	C959.809631,2627.920898 959.185181,2629.437256 956.464172,2629.695312 
z"
        />
        <path
          fill="#374D60"
          opacity="1.000000"
          stroke="none"
          d="
M754.941284,2625.054443 
	C754.896790,2624.972168 754.985840,2625.136719 754.941284,2625.054443 
z"
        />
        <path
          fill="#566C80"
          opacity="1.000000"
          stroke="none"
          d="
M846.964966,2617.848877 
	C846.573975,2616.840332 847.074158,2615.906738 847.792480,2614.486572 
	C860.924011,2613.978516 873.838074,2613.873535 886.750000,2613.997070 
	C889.841736,2614.026611 893.550476,2612.713135 895.811646,2617.374023 
	C879.952515,2617.883301 863.893372,2619.970947 846.964966,2617.848877 
z"
        />
        <path
          fill="#43596D"
          opacity="1.000000"
          stroke="none"
          d="
M551.592834,2634.134277 
	C552.429077,2640.416504 547.344910,2640.554688 543.411743,2641.634766 
	C540.386047,2642.465576 538.223145,2641.148438 537.947998,2636.921875 
	C541.075317,2632.197998 545.132141,2635.878662 549.325317,2634.462402 
	C550.359070,2634.028564 550.712769,2634.045654 551.592834,2634.134277 
z"
        />
        <path
          fill="#4E6477"
          opacity="1.000000"
          stroke="none"
          d="
M549.308105,2633.993164 
	C547.006287,2637.196777 543.063232,2635.549805 538.727356,2636.112305 
	C537.913940,2636.168945 537.925537,2636.072021 537.961792,2636.040039 
	C536.843323,2634.633301 536.536316,2633.086914 537.529053,2630.686035 
	C538.872925,2629.990234 539.746826,2629.980713 541.274658,2629.966064 
	C543.280151,2632.756836 545.526428,2634.063477 549.308105,2633.993164 
z"
        />
        <path
          fill="#4E6477"
          opacity="1.000000"
          stroke="none"
          d="
M532.462524,2635.323730 
	C530.827209,2636.519287 529.701843,2637.158447 528.492737,2637.506348 
	C527.262634,2637.860596 525.828613,2637.915771 525.108093,2636.611572 
	C524.425720,2635.376221 525.075378,2634.203369 525.950684,2633.208740 
	C527.231140,2631.753906 528.655151,2630.524170 531.324097,2630.081543 
	C533.159424,2631.379883 533.462463,2632.926514 532.462524,2635.323730 
z"
        />
        <path
          fill="#597288"
          opacity="1.000000"
          stroke="none"
          d="
M532.502319,2636.009521 
	C531.993225,2634.506348 531.997192,2633.004639 532.003296,2630.753418 
	C533.503662,2630.003418 535.001892,2630.002686 537.249634,2630.000977 
	C537.997375,2631.501465 537.995789,2633.002930 537.996094,2635.256348 
	C537.116272,2636.015625 536.234436,2636.023438 534.696289,2636.050781 
	C533.698425,2636.050537 533.356934,2636.031006 532.502319,2636.009521 
z"
        />
        <path
          fill="#4E6477"
          opacity="1.000000"
          stroke="none"
          d="
M496.596680,2653.859375 
	C496.196289,2652.175537 496.757568,2650.656006 499.365295,2650.090332 
	C499.808167,2651.789795 499.271088,2653.331543 496.596680,2653.859375 
z"
        />
        <path
          fill="#F6EEDE"
          opacity="1.000000"
          stroke="none"
          d="
M1435.353394,2893.708740 
	C1436.649170,2897.742676 1433.974609,2902.949219 1438.128906,2904.949219 
	C1442.305664,2906.960205 1446.962158,2903.524658 1449.521851,2900.614990 
	C1453.220947,2896.409912 1460.692383,2896.085938 1461.301270,2888.547607 
	C1461.512573,2885.930908 1465.705811,2886.126709 1467.939087,2884.762695 
	C1474.499268,2880.757080 1474.636597,2880.011230 1469.636108,2874.051514 
	C1469.252441,2873.594482 1469.194092,2872.864258 1468.709106,2871.467773 
	C1474.316040,2869.765625 1481.238770,2868.946777 1481.002075,2860.695801 
	C1487.599609,2857.974854 1488.691528,2851.583740 1490.617432,2845.200439 
	C1497.783691,2849.065918 1505.479004,2849.173096 1513.340698,2851.730957 
	C1516.674805,2861.268311 1517.433838,2869.466797 1513.603394,2879.593994 
	C1508.581909,2892.870117 1502.123901,2906.390625 1502.963501,2921.643555 
	C1503.616821,2933.508301 1489.350342,2948.162842 1479.443359,2946.792236 
	C1484.160034,2950.276123 1485.673584,2954.743164 1483.631836,2959.928223 
	C1481.673096,2964.902344 1476.895630,2964.558105 1472.609131,2965.007080 
	C1469.513428,2965.331299 1466.166260,2964.455078 1465.462524,2969.666992 
	C1464.124634,2979.576172 1459.571289,2982.336426 1450.035645,2980.452637 
	C1447.398193,2979.931641 1445.321411,2978.027588 1442.627319,2977.741211 
	C1441.840820,2987.306396 1443.169922,2988.477539 1451.594116,2985.452637 
	C1453.788696,2984.664795 1455.906616,2983.644531 1458.269165,2983.548584 
	C1461.301392,2983.425537 1464.468872,2983.417236 1466.262817,2986.403809 
	C1467.940186,2989.196289 1465.843384,2991.426758 1464.575806,2993.709229 
	C1456.133301,3008.913086 1445.987549,3011.288574 1431.401978,3001.426270 
	C1432.409546,3006.311035 1436.586060,3006.790283 1440.253540,3008.097900 
	C1442.894287,3009.039062 1444.906128,3010.866211 1444.974731,3013.905273 
	C1445.043457,3016.939453 1443.011230,3018.767822 1440.574585,3020.048584 
	C1435.377930,3022.780518 1429.816040,3023.288330 1423.393311,3022.273438 
	C1417.183228,3020.074463 1411.674561,3017.734863 1403.786865,3022.374756 
	C1412.226807,3021.532227 1417.065063,3028.037109 1424.854492,3025.643555 
	C1432.510010,3024.362549 1439.178223,3025.628906 1446.476807,3028.048828 
	C1450.976440,3031.605957 1449.799194,3034.913818 1446.893555,3038.848633 
	C1446.006592,3039.814697 1445.629639,3040.179443 1444.591797,3040.967773 
	C1442.978394,3041.820557 1441.986816,3042.119629 1440.189209,3042.347656 
	C1437.865601,3042.344971 1436.426025,3041.928711 1434.585205,3040.503906 
	C1431.603516,3038.825684 1429.153320,3037.858643 1426.377197,3038.000000 
	C1417.913940,3038.430908 1409.888062,3036.720947 1402.179321,3033.206055 
	C1399.437866,3031.955811 1396.020264,3030.793213 1394.605957,3034.687256 
	C1393.211426,3038.526611 1395.363770,3040.993896 1399.284790,3041.915527 
	C1400.808228,3042.273926 1402.975342,3042.173096 1402.812744,3044.511719 
	C1402.642822,3046.954590 1400.553101,3047.839600 1398.541260,3047.781982 
	C1392.664795,3047.614014 1386.500977,3049.628418 1381.027588,3045.973389 
	C1378.716309,3044.429932 1377.099487,3044.768799 1374.855347,3046.451904 
	C1369.500366,3050.468262 1364.740723,3054.671143 1364.346069,3061.958984 
	C1364.097900,3066.537109 1361.643799,3068.484619 1357.143066,3067.991455 
	C1352.724487,3067.507080 1348.572266,3068.806396 1344.408081,3069.952637 
	C1342.830688,3070.386963 1340.672241,3070.549805 1339.860962,3071.641113 
	C1332.639893,3081.355713 1322.057739,3085.056641 1311.123657,3088.213623 
	C1309.745117,3088.611572 1308.666992,3089.340576 1308.565430,3090.843018 
	C1308.468384,3092.278320 1309.332642,3093.250488 1310.495605,3094.054443 
	C1313.755981,3096.308105 1317.470337,3095.864014 1321.114624,3096.049316 
	C1323.582642,3096.174805 1327.389160,3095.314697 1327.160645,3099.194824 
	C1326.944336,3102.870361 1323.169434,3101.795410 1320.689697,3101.954590 
	C1318.369873,3102.103760 1315.961548,3102.271729 1313.708740,3101.833496 
	C1303.399658,3099.825439 1303.817139,3097.497070 1304.477051,3090.260254 
	C1305.146973,3082.916016 1309.172974,3079.365479 1314.339722,3076.408936 
	C1317.068237,3074.847900 1319.533813,3073.548096 1320.674316,3070.579834 
	C1323.044312,3064.410645 1327.400391,3062.833984 1333.460327,3064.757568 
	C1337.895264,3066.165283 1340.491577,3063.967285 1339.402954,3059.859131 
	C1337.805420,3053.831055 1341.386963,3052.968262 1345.503662,3052.140137 
	C1347.119141,3051.815186 1348.835938,3052.024170 1350.477295,3051.787354 
	C1352.258667,3051.530518 1353.698120,3050.646484 1353.351929,3048.542969 
	C1352.992188,3046.356689 1351.248291,3046.334961 1349.522095,3046.483154 
	C1341.732300,3047.151855 1334.760986,3044.704590 1328.475464,3040.419189 
	C1325.643311,3038.488770 1322.100708,3038.669189 1319.321533,3036.680908 
	C1324.401245,3033.270264 1335.657593,3031.638184 1340.971313,3034.125000 
	C1344.045044,3035.563965 1346.745972,3035.630371 1347.291016,3032.405029 
	C1348.688965,3024.133057 1356.012451,3023.281250 1361.476440,3020.116699 
	C1362.821899,3019.337402 1364.937988,3019.646484 1365.313110,3017.602539 
	C1365.821289,3014.834717 1363.702271,3014.363281 1361.699707,3013.952881 
	C1358.783691,3013.356201 1357.778198,3011.593018 1358.741211,3008.857422 
	C1359.455078,3006.829102 1359.465332,3005.051025 1358.191895,3003.209229 
	C1357.115967,3001.653320 1355.389160,2999.489990 1358.576172,2998.629883 
	C1361.194702,2997.923096 1361.659302,3000.363770 1362.041260,3002.384766 
	C1362.793335,3006.363525 1364.927002,3009.212891 1369.124634,3009.641602 
	C1373.184082,3010.056152 1378.215820,3006.568848 1379.634888,3002.051758 
	C1381.963379,2994.640137 1386.623169,2987.332031 1380.914673,2979.275635 
	C1379.311035,2977.012695 1380.625000,2974.803467 1383.423584,2973.954590 
	C1386.283325,2973.087646 1389.288818,2972.555420 1391.988281,2971.338867 
	C1396.650146,2969.237549 1397.059448,2966.797852 1394.298950,2959.958740 
	C1397.590576,2955.102051 1402.000244,2953.762207 1406.938721,2952.118164 
	C1408.772217,2952.020020 1409.942627,2951.984375 1411.113892,2952.028809 
	C1409.959229,2951.964600 1408.803711,2951.996338 1406.943848,2951.999268 
	C1396.467651,2949.981934 1396.141357,2948.206543 1404.019287,2936.939941 
	C1405.145752,2935.598145 1405.923096,2934.989746 1407.459229,2934.222656 
	C1414.442627,2930.779541 1419.192261,2924.336670 1428.014160,2925.267822 
	C1422.025513,2915.780518 1425.036743,2907.241699 1428.929932,2898.110840 
	C1429.495728,2897.083984 1429.736938,2896.678711 1430.434570,2895.726562 
	C1431.972534,2894.184814 1433.221680,2893.551270 1435.353394,2893.708740 
z"
        />
        <path
          fill="#F9F4E5"
          opacity="1.000000"
          stroke="none"
          d="
M1407.275879,2837.973633 
	C1412.751953,2837.333740 1416.552246,2835.072998 1419.705078,2830.609375 
	C1420.492432,2829.668457 1420.758057,2829.466797 1420.825684,2829.427002 
	C1425.605103,2826.637207 1429.766846,2819.903809 1436.165771,2826.224121 
	C1439.100342,2829.123047 1435.675537,2839.837402 1430.055176,2843.818848 
	C1412.680176,2856.127686 1397.814331,2871.322754 1382.167725,2885.540527 
	C1365.359863,2900.812988 1346.773926,2913.412598 1328.160645,2926.117920 
	C1323.241089,2929.476074 1320.012329,2935.056396 1313.624023,2936.985596 
	C1319.639038,2936.793701 1326.139160,2935.491211 1325.055664,2945.414307 
	C1317.618652,2951.023926 1311.614258,2960.466064 1301.158569,2951.974365 
	C1294.898438,2962.284912 1295.516357,2964.462646 1306.217896,2969.515137 
	C1308.644897,2970.660889 1311.242310,2971.452637 1310.424072,2975.455078 
	C1306.712036,2978.011719 1303.032349,2977.748535 1299.390747,2978.097900 
	C1293.695801,2978.644287 1289.666016,2981.509033 1285.275146,2985.747559 
	C1279.657593,2991.170410 1271.596191,2994.094482 1266.403442,3000.591309 
	C1265.823853,3001.316162 1263.681641,3001.531738 1262.697144,3001.066650 
	C1258.207397,2998.946289 1255.834351,3002.025879 1253.360229,3004.628418 
	C1250.765259,3007.358398 1247.854492,3010.433594 1244.119019,3009.742188 
	C1236.375977,3008.308105 1232.177368,3014.276855 1226.566162,3017.160645 
	C1223.801880,3018.581543 1224.629517,3020.683350 1227.082031,3022.528320 
	C1229.414062,3024.282471 1233.109253,3022.890137 1235.203247,3026.677979 
	C1227.659790,3029.572266 1218.161255,3020.885254 1211.992188,3032.696777 
	C1209.912354,3022.414062 1202.372437,3023.907959 1196.406982,3022.928223 
	C1192.788208,3022.333496 1189.381470,3029.029297 1190.994751,3032.707275 
	C1191.247925,3033.283936 1191.881592,3033.682617 1192.278076,3034.209717 
	C1193.802246,3036.238037 1196.391113,3038.189697 1194.271118,3041.010498 
	C1192.736450,3043.052002 1186.113037,3042.385254 1184.574585,3039.634521 
	C1182.657104,3036.205811 1179.432617,3034.508057 1176.806763,3032.065430 
	C1174.205811,3029.646484 1171.607788,3029.322998 1169.861206,3033.433838 
	C1167.567871,3038.831543 1162.806885,3039.971191 1157.547363,3039.955078 
	C1155.095703,3039.947754 1152.484009,3039.560791 1152.288574,3036.605469 
	C1151.703491,3027.756836 1143.843872,3028.668213 1138.580688,3026.251465 
	C1136.907837,3025.483398 1134.642578,3026.023926 1132.650146,3025.928467 
	C1129.977661,3025.800049 1127.867798,3024.794434 1128.647461,3021.717041 
	C1129.287842,3019.189453 1131.348145,3017.829590 1134.011108,3018.554199 
	C1135.556152,3018.974609 1136.852295,3020.233398 1138.367798,3020.852539 
	C1144.724609,3023.449707 1151.425903,3021.835449 1156.179565,3016.572510 
	C1159.974854,3012.370605 1165.282715,3010.103027 1168.666504,3004.907959 
	C1170.413940,3002.225586 1177.267212,3002.835693 1181.826904,3002.034424 
	C1183.700073,3001.705078 1186.336548,3002.459473 1186.869507,2999.990479 
	C1187.489136,2997.120361 1184.303223,2996.544678 1182.382812,2994.410645 
	C1182.838745,2992.689941 1183.761475,2991.597656 1185.663330,2990.546387 
	C1189.822388,2989.030273 1193.420776,2989.775635 1196.993896,2989.783691 
	C1199.305542,2989.789062 1202.462036,2989.686523 1202.369385,2986.872803 
	C1202.100952,2978.723633 1209.406616,2977.418945 1214.206787,2972.915527 
	C1215.937134,2971.643799 1217.226074,2971.033936 1219.284180,2970.399902 
	C1231.346313,2969.071289 1237.859619,2958.892578 1247.565918,2955.124023 
	C1267.384888,2947.429199 1281.545898,2931.487549 1299.229980,2920.632812 
	C1309.022095,2914.622314 1322.724976,2910.442383 1326.715942,2896.570312 
	C1327.432495,2894.079590 1330.296509,2892.680908 1332.638550,2891.422363 
	C1342.767944,2885.979492 1352.420776,2879.671387 1362.835815,2874.752197 
	C1368.628906,2872.016357 1373.019043,2868.373291 1372.291626,2860.400879 
	C1372.564941,2859.225342 1372.740234,2858.769775 1373.370972,2857.735352 
	C1374.615112,2856.577881 1375.464355,2856.109131 1377.066162,2855.560791 
	C1382.376709,2855.147461 1386.001709,2852.584717 1389.036987,2849.614258 
	C1394.620361,2844.149902 1400.116211,2842.895996 1406.416992,2848.959473 
	C1405.052368,2846.160156 1402.528198,2844.684570 1402.931519,2841.019043 
	C1403.855835,2838.963379 1405.080566,2838.275635 1407.275879,2837.973633 
z"
        />
        <path
          fill="#F5F1E5"
          opacity="1.000000"
          stroke="none"
          d="
M1173.527832,2926.496582 
	C1176.328003,2924.926514 1177.998901,2923.137695 1179.723755,2920.551514 
	C1182.719849,2919.885742 1185.199097,2921.100586 1187.786011,2921.472656 
	C1192.977173,2922.219971 1198.454956,2923.431396 1202.371216,2918.031006 
	C1203.055908,2917.087158 1204.685181,2916.104492 1205.718628,2916.252930 
	C1213.365601,2917.348145 1214.923706,2911.722656 1215.293945,2906.590088 
	C1215.691406,2901.078857 1223.300293,2899.348145 1221.353027,2893.183105 
	C1222.583130,2892.801025 1223.792725,2892.198730 1225.047607,2892.080811 
	C1228.013672,2891.802490 1230.965942,2891.916016 1233.931274,2891.089355 
	C1241.058472,2889.102295 1241.096313,2889.238281 1239.347046,2881.956299 
	C1239.194946,2881.322998 1238.930664,2880.691162 1238.915771,2880.054199 
	C1238.768066,2873.710938 1238.647217,2873.376221 1234.657715,2870.139893 
	C1236.948242,2867.717773 1240.812988,2866.258545 1240.171143,2861.809326 
	C1239.818604,2859.365234 1241.328125,2857.415283 1243.767822,2856.382080 
	C1244.598389,2856.030273 1245.988037,2854.956299 1245.962769,2854.914307 
	C1241.095581,2846.875488 1250.408081,2843.550537 1251.975098,2837.591309 
	C1253.185425,2832.988525 1255.250000,2830.306152 1249.530762,2827.242920 
	C1244.437866,2824.514893 1246.258545,2812.660645 1251.605347,2810.561279 
	C1253.528320,2809.806396 1256.058472,2809.580322 1257.284668,2811.110352 
	C1260.234497,2814.789795 1263.544678,2817.677002 1267.916992,2819.572510 
	C1269.682617,2820.338135 1269.510254,2822.319580 1268.026001,2823.705566 
	C1267.056885,2824.610840 1265.915283,2825.328369 1264.912109,2826.200684 
	C1259.786377,2830.657715 1260.160889,2836.275146 1262.556030,2841.615479 
	C1263.987427,2844.806641 1265.954956,2846.704834 1261.506958,2849.253906 
	C1259.381104,2850.472656 1260.569824,2854.387451 1259.453125,2857.223877 
	C1256.227661,2865.417236 1254.838989,2874.327393 1252.621216,2882.928223 
	C1251.916382,2885.661377 1253.229370,2887.324463 1255.061279,2888.951660 
	C1256.288086,2890.041260 1257.310913,2891.586914 1259.254150,2890.794434 
	C1260.977661,2890.091553 1261.546631,2888.543945 1261.700195,2886.791016 
	C1262.408081,2878.714355 1262.402832,2878.312256 1269.885620,2878.125488 
	C1275.310181,2877.989990 1277.684937,2875.493164 1278.774170,2870.654297 
	C1279.952637,2865.417725 1282.075317,2861.146973 1288.727417,2861.934814 
	C1291.237671,2862.232178 1292.984863,2861.107422 1294.126343,2858.537354 
	C1296.396606,2853.425781 1300.528320,2849.497803 1302.608887,2844.019775 
	C1304.507202,2839.020996 1307.972656,2834.291748 1314.573853,2833.343750 
	C1318.030518,2832.847168 1321.072632,2830.752197 1317.638672,2826.427490 
	C1315.972656,2824.328857 1317.086914,2822.891113 1318.921143,2821.515625 
	C1321.340332,2819.700928 1323.875732,2818.304932 1326.791504,2817.365723 
	C1329.219971,2816.583496 1332.142334,2815.625977 1330.970703,2811.856201 
	C1330.525513,2810.424072 1330.731812,2809.141357 1332.452026,2808.987549 
	C1333.998047,2808.849854 1334.975342,2810.142578 1335.304565,2811.367188 
	C1336.543335,2815.973877 1335.878784,2820.459717 1334.731934,2825.096680 
	C1332.877197,2832.595459 1334.093628,2833.414307 1341.851440,2832.453857 
	C1346.808228,2831.839844 1351.777466,2831.488525 1356.449219,2829.593506 
	C1361.647217,2827.484619 1362.064941,2830.642090 1361.957764,2835.208008 
	C1361.419312,2836.723389 1360.757324,2837.341553 1359.307617,2838.016602 
	C1356.371826,2838.829834 1353.533203,2838.607666 1352.867432,2842.605469 
	C1346.814697,2851.806152 1338.809326,2857.814209 1330.830078,2864.381836 
	C1320.227295,2873.109131 1306.907227,2878.606689 1298.264160,2890.802734 
	C1286.233276,2896.174805 1279.972168,2909.010742 1266.399658,2912.061035 
	C1259.320312,2913.878418 1255.532593,2919.315430 1249.209595,2923.404297 
	C1236.499878,2923.510498 1228.914917,2931.117432 1220.624878,2937.282471 
	C1213.739624,2942.402588 1205.966309,2939.800537 1198.582397,2939.430908 
	C1194.743896,2939.238525 1199.029785,2934.433838 1196.148804,2932.993652 
	C1189.102661,2938.533691 1188.778809,2941.023193 1194.878540,2943.787354 
	C1196.556885,2944.547852 1199.510254,2944.084473 1198.887817,2946.901855 
	C1198.296021,2949.581543 1196.137573,2951.743164 1193.041016,2951.119629 
	C1190.101318,2950.527832 1187.223511,2949.952148 1184.205933,2949.980469 
	C1175.998413,2950.057861 1175.803467,2950.179443 1173.907349,2958.437500 
	C1173.094727,2961.976807 1172.119629,2965.415771 1167.133545,2968.102295 
	C1168.807617,2960.625488 1165.523560,2957.719238 1159.774170,2955.508057 
	C1156.102173,2954.095947 1153.400513,2950.161133 1150.131836,2946.673828 
	C1150.885620,2945.990234 1151.771362,2945.990234 1153.321167,2945.985352 
	C1157.012451,2936.315430 1166.217529,2932.846191 1173.527832,2926.496582 
z"
        />
        <path
          fill="#E9E1D0"
          opacity="1.000000"
          stroke="none"
          d="
M1410.486572,2935.529297 
	C1409.118408,2936.026367 1408.233032,2936.024414 1406.683594,2936.020996 
	C1402.324707,2939.439209 1398.391846,2938.918701 1394.121826,2937.090332 
	C1390.568848,2935.569336 1386.790894,2934.406982 1383.397583,2936.604248 
	C1383.009155,2938.016113 1383.333618,2938.816895 1383.974243,2938.683350 
	C1396.110229,2936.156982 1396.518433,2936.497314 1396.205078,2949.220703 
	C1396.124390,2952.495850 1396.575073,2955.841797 1394.411987,2959.433838 
	C1393.995117,2959.990234 1394.000000,2960.000000 1393.995117,2959.995117 
	C1389.752197,2959.990234 1385.514160,2959.990234 1381.126343,2959.990234 
	C1381.126343,2957.828613 1380.915405,2956.812500 1381.161743,2955.922852 
	C1382.427734,2951.350098 1382.761719,2948.002441 1375.995850,2949.175537 
	C1373.650635,2949.582520 1370.910767,2947.991455 1368.411133,2947.137939 
	C1366.499878,2946.485352 1364.633911,2945.900146 1362.593384,2946.306641 
	C1360.836914,2946.657227 1359.239624,2947.574707 1359.131958,2949.354004 
	C1359.004639,2951.457031 1360.865723,2951.858154 1362.592285,2951.924316 
	C1364.913696,2952.012939 1367.240967,2951.971191 1369.560181,2952.090332 
	C1371.706665,2952.200684 1373.609985,2952.888184 1373.470947,2955.493164 
	C1373.351440,2957.731201 1371.802490,2959.781494 1369.722046,2959.123535 
	C1363.933472,2957.292725 1362.671265,2959.695068 1361.385742,2964.978760 
	C1360.239380,2969.690186 1354.498413,2970.342773 1350.508301,2969.743164 
	C1344.992798,2968.914307 1337.637939,2974.294434 1334.047363,2964.826172 
	C1334.745972,2963.001221 1335.775146,2962.525879 1337.424316,2962.384521 
	C1337.865479,2962.392578 1338.465698,2962.679688 1338.465698,2962.679688 
	C1338.465698,2962.679688 1338.779541,2962.113770 1338.414062,2961.926514 
	C1337.538818,2961.309326 1337.118896,2960.804443 1336.538574,2959.671143 
	C1335.276489,2955.136963 1336.269287,2951.801270 1339.973267,2949.853516 
	C1343.945923,2947.764648 1346.174683,2944.106445 1349.193848,2940.322754 
	C1349.688354,2939.428467 1349.895996,2939.086182 1350.421143,2938.280518 
	C1351.154541,2937.221924 1351.208984,2936.585205 1350.475464,2935.591797 
	C1346.582886,2933.789062 1342.554077,2932.224854 1343.287720,2927.779053 
	C1344.051025,2923.154297 1348.631714,2923.632080 1352.176025,2923.122314 
	C1356.101807,2922.557861 1360.164917,2923.513672 1364.106812,2922.107178 
	C1365.190308,2922.677734 1364.278076,2920.041992 1364.288208,2921.375244 
	C1364.291626,2921.833252 1364.015991,2921.697510 1363.288696,2921.418945 
	C1356.077271,2918.656982 1358.667114,2913.249268 1363.020142,2912.954346 
	C1373.080688,2912.271973 1379.688599,2904.446533 1389.400024,2902.726318 
	C1395.999268,2901.557617 1402.065918,2893.487793 1405.132446,2887.496338 
	C1412.130127,2873.823975 1422.139160,2864.050781 1435.007446,2856.789551 
	C1439.365112,2854.330566 1441.288452,2851.904297 1440.708862,2846.790527 
	C1440.122070,2841.612549 1443.992188,2837.514404 1445.900635,2832.172607 
	C1446.867065,2830.740479 1447.636475,2830.141846 1449.270508,2829.832031 
	C1455.084351,2829.779541 1459.095581,2832.447266 1463.631592,2835.423828 
	C1466.610474,2839.599854 1463.239746,2840.916260 1461.163940,2842.861816 
	C1464.980347,2842.575684 1465.524536,2839.319824 1467.521973,2836.512207 
	C1469.769165,2836.199707 1471.286377,2836.763184 1471.949219,2839.330322 
	C1473.930054,2841.825928 1475.802734,2843.693115 1474.433472,2847.345947 
	C1473.737183,2848.592773 1473.365723,2849.113037 1472.435791,2849.778809 
	C1466.612305,2853.590576 1471.022949,2863.083252 1462.464844,2865.505371 
	C1461.975586,2865.970703 1462.000000,2866.000000 1461.984863,2865.985352 
	C1456.096680,2871.002686 1451.578857,2877.378418 1445.138428,2882.795166 
	C1437.462524,2883.888672 1436.511353,2890.785156 1432.409546,2895.461182 
	C1432.009277,2896.004395 1432.000000,2896.000000 1432.003052,2896.004883 
	C1429.082275,2897.958740 1427.902222,2901.022461 1426.898804,2904.156494 
	C1424.678589,2911.090088 1421.797729,2917.585938 1416.103149,2922.502441 
	C1412.324097,2925.765381 1412.346924,2930.713867 1410.486572,2935.529297 
z"
        />
        <path
          fill="#DED5C3"
          opacity="1.000000"
          stroke="none"
          d="
M1263.553955,2910.499023 
	C1272.141357,2908.420654 1278.516479,2904.036621 1283.163086,2897.363525 
	C1286.152710,2893.070068 1291.200195,2893.309082 1295.534180,2890.519287 
	C1305.670898,2884.150391 1314.182129,2876.647949 1323.651978,2870.528320 
	C1332.377319,2864.889404 1337.892456,2866.927490 1340.232788,2877.093262 
	C1339.952515,2878.327881 1339.786743,2878.802246 1339.238037,2879.911621 
	C1329.463867,2889.130127 1316.911133,2894.196533 1310.190186,2906.711914 
	C1305.575806,2917.089844 1296.067749,2921.345459 1288.224365,2927.131348 
	C1282.384399,2931.439453 1276.797852,2935.968262 1271.157349,2940.500244 
	C1266.342285,2944.369385 1261.365967,2948.560547 1255.126831,2950.056152 
	C1241.426025,2953.340576 1230.757324,2961.327637 1220.622803,2971.614258 
	C1219.049072,2972.565186 1218.045776,2973.072021 1216.527344,2973.806885 
	C1206.829834,2976.009033 1199.764404,2983.128174 1190.298706,2984.522461 
	C1186.716553,2985.050293 1187.527710,2988.540771 1186.447632,2991.487793 
	C1185.061646,2992.605713 1184.108765,2993.201660 1182.572021,2993.826416 
	C1179.218872,2988.989746 1174.858276,2989.455811 1170.599243,2990.852295 
	C1166.329346,2992.252686 1162.057251,2992.161133 1157.755981,2991.778809 
	C1155.214844,2991.552734 1153.954834,2989.747559 1155.965942,2987.785645 
	C1158.267822,2985.539795 1160.269287,2982.354980 1163.906860,2982.164551 
	C1170.645020,2981.811523 1174.388916,2977.361816 1178.191772,2972.656738 
	C1181.687012,2968.332275 1185.096680,2963.916260 1191.383667,2963.060059 
	C1193.732422,2962.739990 1197.117188,2962.278809 1197.673096,2958.991943 
	C1199.137207,2950.336182 1207.120972,2949.125244 1213.002808,2946.524414 
	C1217.610596,2944.486572 1222.123047,2940.692871 1227.874390,2941.642578 
	C1233.041504,2934.418213 1242.915649,2932.989990 1247.919678,2924.731445 
	C1248.018677,2924.019531 1248.001953,2924.003418 1248.011230,2924.011475 
	C1252.829468,2919.429932 1259.868530,2917.414307 1263.553955,2910.499023 
z"
        />
        <path
          fill="#E9E1D0"
          opacity="1.000000"
          stroke="none"
          d="
M1434.021729,3040.769287 
	C1435.502808,3040.018066 1436.995972,3040.017334 1439.235718,3040.022949 
	C1440.847534,3041.046631 1441.899658,3041.338623 1443.578491,3040.423340 
	C1444.003296,3040.000000 1444.000000,3040.000000 1444.002441,3040.000000 
	C1450.319702,3038.906738 1453.414062,3032.055420 1460.508301,3031.072266 
	C1461.835205,3030.884521 1462.796143,3030.883301 1463.029785,3030.443115 
	C1469.031006,3019.141602 1480.759766,3020.082275 1491.398926,3017.701904 
	C1491.949829,3021.362793 1492.753784,3024.037109 1496.849609,3023.911621 
	C1498.820923,3023.851074 1500.798706,3023.676514 1502.750610,3023.395752 
	C1504.843628,3023.094482 1507.535278,3020.755615 1508.736938,3023.845947 
	C1509.821167,3026.634033 1506.651489,3027.434814 1504.766846,3028.593750 
	C1503.091431,3029.624268 1500.274902,3030.524414 1500.560913,3032.401123 
	C1501.813232,3040.620361 1495.700195,3043.989990 1490.691772,3047.761475 
	C1483.481812,3053.190186 1476.212036,3058.711914 1466.779541,3061.399414 
	C1468.041382,3066.355713 1467.907349,3069.963379 1461.788696,3072.953613 
	C1457.505859,3075.046631 1451.590942,3079.432861 1453.742065,3087.394287 
	C1453.545288,3091.291016 1451.252686,3091.772705 1448.428223,3092.028076 
	C1438.092041,3092.963135 1438.098877,3093.038330 1441.697510,3103.331543 
	C1441.749634,3104.610840 1441.365356,3105.122070 1440.410400,3105.757080 
	C1438.197266,3106.189941 1436.667358,3106.744873 1436.088379,3109.379395 
	C1434.616699,3111.205322 1433.062622,3111.523193 1430.662354,3110.491699 
	C1424.780762,3101.755859 1428.023682,3094.401855 1432.913452,3087.241455 
	C1429.518433,3085.492188 1426.754517,3084.115234 1423.153687,3085.227539 
	C1417.988403,3086.823486 1412.585449,3085.898193 1407.289185,3085.917725 
	C1402.417725,3085.935791 1397.264893,3084.303223 1394.999878,3080.416748 
	C1391.027710,3073.600098 1386.534912,3071.132324 1379.355469,3075.318359 
	C1379.195312,3073.929443 1378.843628,3072.939453 1379.093750,3072.751709 
	C1387.062500,3066.772705 1395.969727,3066.076416 1405.304565,3068.418701 
	C1407.828979,3069.052246 1407.981567,3071.198486 1407.238525,3073.223877 
	C1405.963379,3076.700684 1407.949341,3078.390381 1410.675049,3079.149414 
	C1415.859619,3080.593018 1421.077393,3084.640625 1426.491943,3079.591064 
	C1427.948853,3078.232178 1429.589600,3079.213623 1431.195679,3079.293213 
	C1434.074585,3079.435547 1436.014160,3077.620850 1435.136475,3075.005859 
	C1433.532715,3070.228271 1434.968018,3068.158203 1439.752563,3067.602051 
	C1441.473389,3067.401855 1443.000244,3066.145508 1442.831543,3064.302734 
	C1442.642212,3062.233154 1440.673096,3062.083008 1439.003540,3062.062012 
	C1434.016846,3061.999512 1429.027466,3062.071777 1424.042603,3061.949951 
	C1422.183228,3061.904785 1419.453979,3062.390381 1419.306152,3059.698975 
	C1419.139282,3056.658691 1421.930420,3056.257080 1424.259277,3056.113281 
	C1427.241821,3055.929199 1430.244507,3056.095215 1433.232666,3055.971680 
	C1435.462891,3055.879639 1438.187378,3056.550049 1439.280884,3053.749023 
	C1440.310791,3051.110840 1438.286621,3049.414307 1436.724854,3047.740723 
	C1435.066284,3045.963379 1433.976562,3044.007080 1434.021729,3040.769287 
z"
        />
        <path
          fill="#F6EEDE"
          opacity="1.000000"
          stroke="none"
          d="
M1340.385132,2963.506836 
	C1338.502319,2964.008057 1337.004639,2964.006348 1334.758301,2964.007324 
	C1325.683594,2963.260742 1318.538818,2964.645996 1315.492554,2973.891113 
	C1314.914795,2975.645020 1313.101562,2975.829346 1310.727295,2975.970947 
	C1309.644165,2973.202881 1307.903198,2971.722656 1305.273315,2972.113281 
	C1299.503174,2972.970459 1297.326050,2968.478027 1294.296509,2965.205322 
	C1293.150146,2963.966553 1291.922241,2962.263428 1293.029175,2960.834229 
	C1295.619751,2957.489502 1295.553345,2953.501709 1296.602051,2949.756104 
	C1297.704590,2945.818115 1301.206787,2945.505371 1303.805176,2948.738037 
	C1308.308716,2954.341064 1308.337158,2954.377930 1314.451294,2949.629150 
	C1316.871094,2947.749756 1319.792480,2947.201416 1323.924561,2946.198730 
	C1327.397583,2947.070557 1329.639038,2947.421875 1332.395996,2948.454590 
	C1334.310669,2949.363770 1335.400391,2950.230469 1336.457764,2952.056641 
	C1334.253174,2958.285645 1328.784058,2956.055908 1324.552734,2957.353027 
	C1328.921631,2957.156738 1333.173218,2956.427002 1337.492920,2959.523926 
	C1338.621582,2960.240967 1339.131836,2960.623779 1339.772705,2961.569336 
	C1340.263306,2962.328125 1340.516724,2962.666016 1340.385132,2963.506836 
z"
        />
        <path
          fill="#F6EEDE"
          opacity="1.000000"
          stroke="none"
          d="
M1315.258301,3026.435059 
	C1321.276855,3020.578369 1329.445068,3022.418457 1335.747925,3018.810547 
	C1336.888184,3018.157471 1338.160889,3019.457031 1338.855957,3020.645020 
	C1339.639282,3021.984131 1339.176147,3023.254883 1338.233765,3024.223389 
	C1335.038452,3027.505371 1319.572754,3029.324951 1315.258301,3026.435059 
z"
        />
        <path
          fill="#E5DDC9"
          opacity="1.000000"
          stroke="none"
          d="
M1429.499756,3110.459229 
	C1431.492920,3109.994629 1432.990723,3109.999023 1435.236938,3110.006592 
	C1435.985352,3110.009766 1435.995117,3111.004883 1436.000000,3111.502441 
	C1436.003296,3112.886719 1436.001709,3113.773682 1436.000000,3115.325195 
	C1433.060791,3115.142334 1427.323975,3118.160400 1429.499756,3110.459229 
z"
        />
        <path
          fill="#E5DDC9"
          opacity="1.000000"
          stroke="none"
          d="
M1284.468994,3236.833252 
	C1284.360962,3237.190918 1283.975098,3237.483154 1283.864868,3237.401855 
	C1283.196045,3236.909180 1283.375244,3236.602051 1284.468994,3236.833252 
z"
        />
        <path
          fill="#FBF5E5"
          opacity="1.000000"
          stroke="none"
          d="
M1109.073242,3030.950195 
	C1109.118896,3031.036865 1109.027466,3030.863281 1109.073242,3030.950195 
z"
        />
        <path
          fill="#F6EEDE"
          opacity="1.000000"
          stroke="none"
          d="
M1447.480225,2831.592285 
	C1448.263916,2835.164551 1446.646851,2837.581543 1445.269653,2840.281982 
	C1443.588989,2843.577637 1441.152588,2847.073242 1444.789917,2851.162598 
	C1447.057373,2853.711914 1445.222412,2856.594482 1442.344360,2857.298096 
	C1438.120850,2858.330566 1434.141724,2858.606689 1432.979980,2864.180908 
	C1432.494751,2866.508301 1428.974365,2865.692383 1426.735718,2866.053955 
	C1418.503906,2867.383057 1418.125977,2868.089844 1421.045532,2876.038574 
	C1420.089722,2878.500000 1417.793579,2877.874512 1415.952881,2878.056152 
	C1411.883423,2878.457764 1410.194946,2880.803955 1410.018433,2884.657715 
	C1409.785278,2889.751221 1408.092163,2893.240479 1402.284180,2894.497803 
	C1397.215942,2895.595215 1396.696289,2901.410156 1394.313232,2905.154541 
	C1391.202148,2910.042969 1391.579346,2910.258301 1387.043945,2905.563721 
	C1386.648682,2905.154785 1385.869873,2905.116455 1384.740479,2904.720215 
	C1380.548462,2911.705811 1374.244507,2914.993896 1365.805054,2914.190186 
	C1363.473267,2913.968262 1361.192993,2914.988770 1360.954468,2918.151611 
	C1363.682739,2920.789795 1366.490479,2917.036133 1369.305542,2918.196289 
	C1370.908813,2918.857666 1372.617065,2919.399170 1372.865601,2921.198975 
	C1373.170532,2923.407227 1371.294312,2923.644531 1369.632690,2923.959717 
	C1363.133179,2925.191162 1356.661987,2926.531738 1349.965088,2926.056641 
	C1348.068481,2925.921875 1345.436768,2925.700684 1345.093628,2928.227539 
	C1344.784424,2930.504639 1346.986572,2931.395020 1348.988281,2931.995361 
	C1350.251343,2932.373779 1351.458618,2932.938477 1353.345459,2933.709961 
	C1354.914307,2935.442139 1354.051758,2936.348877 1352.523926,2937.609131 
	C1352.014648,2938.009766 1352.000000,2938.000000 1352.004883,2938.009766 
	C1345.888428,2939.965576 1345.091064,2949.259277 1336.467529,2948.755859 
	C1334.753906,2948.670166 1333.780640,2948.503174 1332.062744,2948.162109 
	C1329.251465,2947.905273 1327.133667,2948.103027 1324.631836,2946.493164 
	C1324.009766,2946.009766 1324.000000,2946.000000 1323.995117,2945.995117 
	C1322.337158,2938.349121 1322.298218,2938.252197 1315.916626,2940.815918 
	C1313.986450,2941.591309 1312.166992,2942.127441 1310.169189,2941.614258 
	C1308.482300,2941.181152 1307.109497,2940.187256 1306.879028,2938.350342 
	C1306.638550,2936.432129 1307.870605,2934.793213 1309.502808,2934.683105 
	C1316.180664,2934.232666 1320.172241,2930.026611 1323.872314,2925.203613 
	C1324.464600,2924.431641 1325.254395,2923.635498 1326.127563,2923.281250 
	C1341.952759,2916.864502 1352.826172,2903.136719 1367.767212,2895.178711 
	C1373.723267,2892.006104 1377.343994,2884.570068 1383.464111,2880.445557 
	C1394.290405,2873.149902 1401.042725,2861.048828 1413.081787,2855.096680 
	C1414.148804,2854.569092 1415.308472,2853.284668 1415.536743,2852.169678 
	C1416.479980,2847.561523 1419.785034,2845.305908 1423.749756,2844.452148 
	C1432.093506,2842.655029 1432.368408,2835.152344 1434.886475,2829.355713 
	C1436.023804,2826.737549 1433.400146,2826.433594 1431.371460,2826.039795 
	C1427.189453,2825.227783 1425.432373,2830.247070 1420.773193,2829.982178 
	C1427.661133,2821.818848 1421.583374,2814.725342 1418.126709,2806.686035 
	C1424.013428,2806.644531 1426.256714,2812.770020 1431.598145,2815.478516 
	C1433.364014,2817.584229 1435.219604,2818.397705 1437.531982,2819.614258 
	C1440.268311,2820.071777 1442.132324,2820.905029 1443.692993,2823.432617 
	C1443.772461,2826.898682 1443.369873,2829.875244 1447.480225,2831.592285 
z"
        />
        <path
          fill="#E9E1D0"
          opacity="1.000000"
          stroke="none"
          d="
M1338.557861,2880.455566 
	C1337.993042,2878.368896 1337.962158,2876.728027 1337.962402,2875.087158 
	C1337.962769,2870.458740 1334.920776,2869.418945 1331.342407,2870.230225 
	C1325.865356,2871.471924 1321.335083,2874.432373 1316.892334,2878.088135 
	C1311.078369,2882.872070 1306.443848,2890.061523 1296.776978,2889.998047 
	C1297.741821,2881.742676 1305.516479,2879.672607 1311.161987,2875.571289 
	C1315.565796,2872.371826 1322.041016,2871.592773 1322.612793,2864.420898 
	C1322.668945,2863.716797 1323.853882,2862.620117 1324.615112,2862.527344 
	C1336.452026,2861.082764 1342.148560,2851.537598 1349.346680,2843.971436 
	C1349.802124,2843.492676 1350.333862,2843.086670 1351.430664,2842.328857 
	C1353.320435,2839.881348 1355.409912,2838.548340 1357.574951,2836.433838 
	C1358.877930,2835.992676 1359.755859,2835.985107 1361.291260,2835.967773 
	C1371.763794,2836.041016 1381.582764,2836.733154 1390.408691,2830.666504 
	C1392.577148,2829.176025 1396.154907,2829.803223 1399.057495,2829.299561 
	C1406.414551,2828.023193 1406.408325,2827.987305 1408.000000,2837.215820 
	C1407.065063,2838.639160 1406.124878,2839.268311 1404.598145,2839.958496 
	C1397.268188,2845.551758 1388.652100,2848.420166 1382.045776,2855.323486 
	C1379.298218,2856.223877 1376.900757,2855.642090 1374.485962,2857.547363 
	C1374.009399,2858.009766 1374.000000,2858.000000 1374.004883,2858.004883 
	C1368.321289,2866.003418 1347.035889,2879.963135 1338.557861,2880.455566 
z"
        />
        <path
          fill="#E9E1D0"
          opacity="1.000000"
          stroke="none"
          d="
M1249.233887,2762.022949 
	C1250.024414,2766.103516 1250.014771,2770.177734 1250.005005,2775.125977 
	C1246.590576,2777.950195 1250.754761,2781.082031 1248.425781,2783.640137 
	C1247.476929,2784.538086 1246.953369,2785.045654 1246.003662,2785.879883 
	C1245.061646,2786.681885 1244.571777,2787.181396 1243.823242,2788.150879 
	C1243.303223,2788.852295 1243.086670,2789.127686 1242.437500,2789.701660 
	C1239.370117,2789.974121 1236.730591,2790.032227 1234.103394,2789.877441 
	C1233.177734,2789.822998 1231.479004,2788.973389 1231.487305,2788.949463 
	C1234.189087,2781.039307 1223.998291,2780.301514 1223.174805,2774.018799 
	C1222.839355,2771.458252 1220.898315,2769.370605 1221.469604,2765.968994 
	C1222.729614,2758.464600 1221.698975,2757.959717 1212.749512,2759.911133 
	C1211.726318,2756.962402 1213.323975,2754.281982 1213.714966,2751.404053 
	C1214.269653,2747.321533 1216.355713,2745.260254 1220.269897,2746.203369 
	C1224.119263,2747.130371 1228.798096,2746.840820 1230.005249,2752.583740 
	C1232.134155,2762.710205 1234.773804,2764.242676 1244.518311,2762.274902 
	C1245.789062,2762.018311 1247.126831,2762.094727 1249.233887,2762.022949 
z"
        />
        <path
          fill="#F6EEDE"
          opacity="1.000000"
          stroke="none"
          d="
M1354.086670,2790.738770 
	C1359.773438,2788.392578 1365.562378,2786.940918 1371.612915,2788.812988 
	C1375.946411,2790.153564 1380.050293,2787.098877 1385.210693,2788.046875 
	C1385.621948,2790.810059 1383.740112,2791.390381 1381.290527,2792.077881 
	C1373.991455,2794.128174 1366.363037,2793.414551 1359.045898,2795.163330 
	C1356.240845,2795.833496 1354.915405,2793.866211 1354.086670,2790.738770 
z"
        />
        <path
          fill="#F5F1E5"
          opacity="1.000000"
          stroke="none"
          d="
M1243.624512,2784.435059 
	C1244.921509,2784.032959 1245.795776,2784.026367 1247.325195,2784.014648 
	C1247.992065,2788.290039 1247.611206,2792.598877 1250.769165,2796.239258 
	C1252.109009,2797.783691 1252.027588,2799.943115 1251.875854,2801.947998 
	C1251.773926,2803.295654 1251.468750,2804.608887 1250.065186,2805.178223 
	C1248.130127,2805.963379 1247.085693,2804.866943 1246.140015,2803.304199 
	C1243.949341,2799.683838 1241.010986,2796.359619 1242.005493,2790.811035 
	C1242.004883,2790.000000 1242.026123,2789.004883 1242.029785,2788.507324 
	C1242.053101,2786.814209 1242.416382,2785.745117 1243.624512,2784.435059 
z"
        />
        <path
          fill="#F6EEDE"
          opacity="1.000000"
          stroke="none"
          d="
M1388.771973,2788.014648 
	C1389.618164,2786.027832 1390.781128,2782.019775 1393.945312,2787.345947 
	C1392.542725,2787.958740 1391.034058,2787.984131 1388.771973,2788.014648 
z"
        />
        <path
          fill="#F5F1E5"
          opacity="1.000000"
          stroke="none"
          d="
M1183.996704,2740.568359 
	C1184.282593,2738.267334 1184.720215,2736.672363 1187.353149,2736.060547 
	C1191.701660,2738.638184 1197.332031,2739.909424 1195.958740,2747.248535 
	C1195.399170,2748.824219 1194.227905,2749.782959 1194.383057,2750.417236 
	C1196.565430,2759.340332 1191.956909,2768.070068 1193.766724,2776.989502 
	C1194.522095,2780.712402 1192.293457,2783.452393 1189.290405,2785.161377 
	C1185.789185,2787.153564 1184.656128,2789.918213 1183.733154,2793.796387 
	C1182.305664,2799.794434 1179.487793,2805.482178 1177.034424,2811.204834 
	C1176.191895,2813.170166 1174.320312,2814.019531 1171.167847,2813.139160 
	C1173.222900,2808.229492 1173.658081,2803.225342 1173.145020,2797.755615 
	C1172.735962,2793.394287 1173.479614,2788.108887 1176.726318,2783.705811 
	C1180.243896,2778.935547 1181.787231,2773.130127 1180.546387,2766.507324 
	C1180.039795,2765.002930 1180.038208,2763.986328 1180.022705,2762.197266 
	C1183.433350,2756.682373 1181.134521,2751.049561 1182.548096,2745.132324 
	C1183.146362,2743.255371 1183.759155,2742.280029 1183.996704,2740.568359 
z"
        />
        <path
          fill="#EEE7D5"
          opacity="1.000000"
          stroke="none"
          d="
M1173.251221,2926.007812 
	C1174.311279,2929.370117 1172.973633,2931.668945 1169.834717,2933.105713 
	C1163.783936,2935.875488 1159.074463,2940.255371 1154.670044,2945.857422 
	C1154.767700,2936.279053 1162.774536,2927.633057 1173.251221,2926.007812 
z"
        />
        <path
          fill="#D3CBBF"
          opacity="1.000000"
          stroke="none"
          d="
M1317.252686,2730.301758 
	C1325.018921,2731.735352 1331.885498,2734.065186 1338.431641,2737.255615 
	C1346.178223,2741.031006 1353.835083,2744.954834 1362.888306,2744.182373 
	C1365.674316,2743.944824 1368.128296,2744.939209 1369.640869,2747.492920 
	C1373.288574,2753.651123 1377.305176,2759.612061 1379.859253,2767.084473 
	C1372.155884,2765.287598 1363.714233,2764.091797 1359.429443,2755.638672 
	C1358.783081,2754.364014 1356.825928,2753.033936 1355.425781,2752.979004 
	C1346.599487,2752.631592 1339.447021,2746.939209 1330.908569,2745.652588 
	C1325.004883,2744.762451 1319.706177,2741.537109 1314.307983,2739.291748 
	C1302.819336,2734.513184 1289.945068,2732.566895 1280.261841,2723.608398 
	C1276.762817,2720.371094 1270.420044,2721.175049 1267.879883,2715.171387 
	C1270.775513,2710.776123 1273.659790,2713.668945 1276.434570,2714.914307 
	C1286.628662,2719.490723 1297.012085,2723.624756 1307.207642,2728.194824 
	C1310.187744,2729.530762 1313.059814,2731.327393 1317.252686,2730.301758 
z"
        />
        <path
          fill="#A9ACA9"
          opacity="1.000000"
          stroke="none"
          d="
M1317.284912,2729.760498 
	C1316.081421,2734.295898 1313.323975,2733.533203 1309.849243,2731.988281 
	C1296.347534,2725.985840 1282.733521,2720.236084 1268.427734,2714.450684 
	C1266.461304,2713.951416 1265.228638,2713.393799 1263.466309,2712.409668 
	C1263.941528,2709.804443 1265.900635,2709.360352 1268.774170,2709.163574 
	C1272.253540,2708.468262 1274.661255,2708.955811 1277.165771,2711.606445 
	C1290.671753,2717.937988 1303.655518,2723.697998 1317.284912,2729.760498 
z"
        />
        <path
          fill="#D3CBBF"
          opacity="1.000000"
          stroke="none"
          d="
M1257.329346,2704.129395 
	C1257.721069,2707.700439 1261.688354,2708.074951 1262.755859,2711.295410 
	C1257.684570,2711.938477 1252.090332,2712.982666 1248.115967,2707.037109 
	C1250.490723,2704.365967 1253.430664,2703.785156 1257.329346,2704.129395 
z"
        />
        <path
          fill="#EEE7D5"
          opacity="1.000000"
          stroke="none"
          d="
M1295.983154,2765.258789 
	C1292.028809,2764.846436 1287.747559,2764.069824 1287.584473,2758.393066 
	C1292.603882,2757.812012 1295.883789,2759.211426 1295.983154,2765.258789 
z"
        />
        <path
          fill="#EEE7D5"
          opacity="1.000000"
          stroke="none"
          d="
M1299.924561,2769.307617 
	C1298.216675,2769.815918 1296.689087,2769.282959 1296.093628,2766.632568 
	C1297.773560,2766.175781 1299.308350,2766.698242 1299.924561,2769.307617 
z"
        />
        <path
          fill="#D7D5CF"
          opacity="1.000000"
          stroke="none"
          d="
M1183.551758,2740.375000 
	C1185.908691,2741.504150 1185.667969,2742.805176 1183.172363,2744.113770 
	C1182.331665,2743.059570 1182.344482,2741.866699 1183.551758,2740.375000 
z"
        />
        <path
          fill="#8C5B43"
          opacity="1.000000"
          stroke="none"
          d="
M2450.647461,3187.718262 
	C2447.086914,3185.625000 2448.159424,3181.785889 2446.677734,3178.833496 
	C2445.678955,3176.843994 2446.725098,3175.073730 2448.587646,3173.747803 
	C2450.202637,3172.597656 2451.875488,3171.370605 2453.079102,3169.831055 
	C2456.791260,3165.082275 2460.878174,3162.783203 2465.808105,3167.330811 
	C2468.477783,3165.513916 2467.742676,3163.303467 2468.158203,3161.448242 
	C2468.575195,3159.584229 2469.587158,3158.477051 2471.645264,3159.025146 
	C2478.494385,3160.849121 2477.937256,3157.341064 2476.654053,3152.577637 
	C2475.894043,3149.756592 2476.224121,3146.650391 2475.983643,3143.678955 
	C2475.475830,3137.407715 2475.086182,3136.966797 2468.092529,3136.970947 
	C2466.249512,3136.972168 2464.670166,3137.186523 2464.471436,3134.984619 
	C2464.267090,3132.720459 2465.703857,3131.311035 2467.707520,3130.514404 
	C2470.648438,3129.345215 2473.312256,3132.884766 2476.306885,3131.109619 
	C2477.958740,3128.291504 2474.979736,3127.864990 2473.927490,3126.530518 
	C2472.748047,3125.034668 2472.368652,3123.624023 2473.598145,3122.084961 
	C2474.926025,3120.422607 2476.595947,3120.569580 2477.860352,3121.960693 
	C2479.942383,3124.251221 2482.325928,3122.129639 2484.385254,3122.697266 
	C2488.213135,3123.753174 2490.278564,3122.861572 2490.007324,3118.638672 
	C2489.822266,3115.761475 2492.430908,3114.060791 2493.093750,3111.908936 
	C2491.683105,3109.608398 2489.812744,3108.917969 2487.912354,3108.311279 
	C2486.371582,3107.819580 2484.550781,3108.117920 2483.334961,3106.636475 
	C2483.169434,3104.268311 2484.934082,3104.232422 2486.551025,3104.031006 
	C2488.147461,3103.831787 2489.906494,3104.051270 2491.302979,3101.535889 
	C2487.291992,3098.069580 2483.343262,3094.232422 2482.139648,3088.299316 
	C2481.161621,3083.477295 2484.331055,3078.982178 2489.331787,3079.477295 
	C2491.308350,3079.673096 2493.288818,3079.891113 2495.270996,3079.933838 
	C2496.222412,3079.954590 2497.181885,3079.591309 2498.573975,3079.315674 
	C2497.465576,3075.906982 2495.841064,3074.401855 2492.048828,3074.868896 
	C2488.114502,3075.353760 2483.171387,3075.944092 2486.519043,3068.944092 
	C2487.602051,3066.679932 2485.344727,3066.247314 2483.730469,3066.092773 
	C2480.431396,3065.777100 2477.145752,3065.580078 2475.122803,3062.126953 
	C2476.603516,3059.186035 2479.362305,3059.584473 2480.943848,3060.905273 
	C2486.631348,3065.655762 2489.611084,3063.135254 2491.930176,3057.545410 
	C2492.155518,3057.002197 2492.884521,3056.641846 2493.424072,3056.259033 
	C2496.830811,3053.842285 2496.094727,3051.134277 2493.621338,3048.759033 
	C2490.653076,3045.908691 2489.382324,3042.032227 2486.480957,3038.433105 
	C2484.848145,3037.145752 2483.596680,3036.457275 2482.282715,3034.779053 
	C2481.283203,3033.021729 2480.287109,3031.277100 2480.984619,3030.525635 
	C2488.926025,3021.964111 2481.024658,3020.012451 2475.503418,3017.092773 
	C2472.437744,3015.471191 2469.308350,3013.275635 2470.353516,3009.643311 
	C2471.532471,3005.547852 2470.571533,3003.063477 2467.635742,3000.349365 
	C2465.595459,2998.462891 2465.697021,2995.599365 2467.092773,2992.368652 
	C2475.450684,2988.731445 2478.397705,2979.039062 2487.583008,2976.429688 
	C2490.988525,2976.243408 2494.992432,2975.672852 2492.145264,2980.740234 
	C2490.193359,2984.214111 2487.985840,2987.544434 2486.036865,2991.020020 
	C2483.257812,2995.974854 2483.534668,2996.351562 2491.245850,2997.992920 
	C2500.973633,3003.817627 2509.261230,3009.943604 2513.104004,3020.885010 
	C2514.348145,3024.426758 2517.594482,3028.059814 2520.422363,3031.122070 
	C2526.115234,3037.287109 2525.336670,3045.330322 2527.540527,3052.484375 
	C2527.905273,3053.667480 2527.169189,3055.128418 2525.526367,3055.400146 
	C2524.046143,3055.644775 2522.913330,3055.023926 2522.401123,3053.635986 
	C2520.260498,3047.835938 2514.598145,3045.881592 2510.229980,3042.541992 
	C2509.740967,3042.167969 2508.225830,3042.574951 2507.584961,3043.103271 
	C2506.788330,3043.759277 2506.897217,3045.119629 2507.846191,3045.590820 
	C2512.476562,3047.888428 2513.151123,3052.974121 2515.914795,3056.527344 
	C2518.477539,3059.822021 2522.136475,3062.263916 2525.156494,3064.942871 
	C2524.245117,3069.989258 2518.753906,3066.245850 2517.021729,3070.369385 
	C2519.098877,3073.427979 2524.232422,3070.538574 2526.652588,3074.990479 
	C2521.518555,3075.635254 2517.163086,3079.152588 2512.074463,3075.411377 
	C2510.961182,3074.593262 2509.469482,3074.457031 2508.682617,3075.931396 
	C2508.030273,3077.154785 2508.332520,3078.504150 2509.172363,3079.652100 
	C2510.212646,3081.073975 2511.855713,3081.437500 2513.342285,3082.157959 
	C2517.109375,3083.984375 2521.061523,3085.681885 2523.215332,3089.370605 
	C2522.024902,3092.855469 2519.097412,3091.695312 2517.129150,3092.549561 
	C2513.623779,3094.070557 2511.041748,3095.666504 2514.424072,3099.760986 
	C2514.962158,3100.412109 2515.223145,3102.311768 2515.004639,3102.421631 
	C2505.432617,3107.216553 2516.017578,3110.547852 2515.947021,3115.290039 
	C2508.558594,3124.158203 2504.493164,3135.119385 2493.360596,3140.476074 
	C2486.727051,3143.668213 2485.948975,3151.715820 2485.948486,3158.798584 
	C2485.948242,3163.778076 2486.342529,3168.731201 2482.478516,3173.469238 
	C2481.018066,3174.521240 2480.035889,3175.052246 2478.541504,3175.806152 
	C2476.068604,3178.185547 2473.430176,3178.727051 2470.962646,3177.823730 
	C2465.717285,3175.903564 2464.150391,3179.048584 2462.451660,3183.488525 
	C2460.895508,3184.331787 2459.801025,3184.692871 2458.357910,3185.526855 
	C2455.651123,3185.929932 2453.355957,3186.157715 2450.647461,3187.718262 
z"
        />
        <path
          fill="#805541"
          opacity="1.000000"
          stroke="none"
          d="
M2482.719971,3174.005859 
	C2481.423828,3168.208008 2483.865967,3162.780762 2482.618408,3156.569824 
	C2481.465088,3150.829346 2485.874023,3144.905029 2488.102783,3139.159668 
	C2488.418457,3138.346436 2489.525879,3137.277832 2490.266357,3137.278076 
	C2497.962891,3137.280029 2501.095215,3131.555664 2504.626221,3126.223877 
	C2505.121826,3125.475830 2506.947998,3124.903076 2506.865479,3124.581787 
	C2504.887207,3116.891113 2511.439453,3118.546143 2515.542480,3116.491699 
	C2518.557617,3117.409180 2520.545166,3119.054688 2518.544922,3122.271729 
	C2517.495605,3123.958740 2516.483398,3125.673584 2515.351318,3127.304199 
	C2511.052979,3133.496338 2506.142090,3139.165283 2514.613525,3146.425293 
	C2517.980469,3149.310303 2514.488770,3153.969727 2512.461670,3157.230469 
	C2511.312012,3159.080078 2509.221191,3160.281738 2508.026367,3162.422119 
	C2505.393311,3167.138428 2500.047852,3169.029053 2496.350098,3172.714844 
	C2492.730713,3176.322021 2494.139893,3183.381104 2486.930176,3185.652100 
	C2486.975342,3181.111572 2493.186279,3174.423340 2482.719971,3174.005859 
z"
        />
        <path
          fill="#805541"
          opacity="1.000000"
          stroke="none"
          d="
M2462.700684,3184.075195 
	C2462.367432,3180.422607 2461.357666,3176.961182 2460.579590,3173.188477 
	C2466.659180,3172.568359 2471.136719,3176.696045 2477.230957,3176.014648 
	C2477.864746,3179.142578 2475.518066,3182.046631 2477.624512,3185.428955 
	C2478.676758,3187.118652 2480.746826,3189.746094 2477.839600,3191.295898 
	C2475.210693,3192.697021 2472.125244,3192.514893 2469.996826,3189.656006 
	C2468.242432,3187.299561 2466.532715,3184.891357 2462.700684,3184.075195 
z"
        />
        <path
          fill="#5E4237"
          opacity="1.000000"
          stroke="none"
          d="
M2524.859863,3147.288086 
	C2525.277344,3148.777832 2524.756348,3149.317627 2523.599609,3149.086182 
	C2523.359375,3149.038086 2523.036133,3148.697998 2523.000000,3148.455322 
	C2522.825928,3147.287109 2523.394287,3146.797852 2524.859863,3147.288086 
z"
        />
        <path
          fill="#5E4237"
          opacity="1.000000"
          stroke="none"
          d="
M2530.927734,3117.047363 
	C2530.882080,3116.961914 2530.973389,3117.133057 2530.927734,3117.047363 
z"
        />
        <path
          fill="#B67247"
          opacity="1.000000"
          stroke="none"
          d="
M2353.944580,2946.744385 
	C2354.004639,2946.039062 2354.069580,2945.053223 2354.065186,2944.559814 
	C2354.395752,2944.112793 2354.730713,2944.158936 2355.571777,2944.233398 
	C2369.390625,2944.707764 2381.096680,2950.867432 2392.590576,2956.239990 
	C2405.874268,2962.449463 2420.657715,2963.653564 2433.554443,2971.071045 
	C2445.879150,2978.159912 2446.772461,2978.333496 2441.125488,2991.619873 
	C2438.079834,3001.282471 2435.270508,3010.306885 2430.966309,3019.572754 
	C2421.673340,3040.173340 2409.561523,3057.781494 2393.049805,3072.291504 
	C2389.064941,3075.793213 2385.164795,3077.193359 2380.512939,3074.332520 
	C2372.281738,3069.270508 2364.370117,3066.923584 2354.321533,3070.757324 
	C2348.630615,3072.928711 2341.117188,3071.690918 2334.672852,3070.576660 
	C2320.738770,3068.167480 2306.852783,3065.166748 2294.265625,3056.678711 
	C2293.834717,3052.688477 2294.863281,3049.677246 2297.042969,3046.386230 
	C2298.882324,3044.908447 2300.531006,3043.552734 2302.030029,3044.244385 
	C2312.750977,3049.191162 2324.975342,3046.217285 2335.763672,3050.926025 
	C2343.784912,3054.427002 2354.697754,3051.054199 2358.970459,3043.362305 
	C2364.062744,3034.194336 2370.484131,3028.842773 2381.478271,3032.218262 
	C2384.991943,3033.297119 2386.849609,3031.847412 2388.679443,3028.855225 
	C2395.397217,3017.869141 2404.342041,3008.323730 2409.688965,2996.428955 
	C2410.225830,2995.234619 2411.410645,2994.424316 2412.851318,2993.167969 
	C2394.699707,2995.672852 2379.198486,2991.910645 2367.864502,2976.917236 
	C2365.366211,2973.612305 2361.747314,2971.964844 2358.017822,2970.415527 
	C2354.722412,2969.046143 2350.858643,2967.983398 2351.525146,2962.582520 
	C2351.955811,2959.961426 2351.970215,2957.903320 2352.002930,2955.084961 
	C2352.642334,2952.032959 2353.263428,2949.741211 2353.944580,2946.744385 
z"
        />
        <path
          fill="#A26847"
          opacity="1.000000"
          stroke="none"
          d="
M2440.699707,2992.361572 
	C2435.708252,2991.085938 2435.056396,2989.113281 2438.295898,2986.032715 
	C2444.292969,2980.329834 2444.127197,2979.161865 2436.811035,2975.955566 
	C2430.785889,2973.315674 2424.644531,2970.941406 2419.073730,2967.329590 
	C2417.780518,2966.491211 2415.985840,2966.147949 2414.402588,2966.087402 
	C2409.787109,2965.910400 2405.463623,2965.825439 2401.176514,2963.052734 
	C2392.718750,2957.582031 2382.449707,2956.087891 2373.510254,2951.244141 
	C2368.550049,2948.556641 2361.926270,2949.004150 2356.536377,2944.767334 
	C2356.458740,2939.920898 2358.824707,2936.425781 2361.469238,2932.396240 
	C2362.742432,2931.290527 2363.823975,2930.376709 2364.568604,2930.702393 
	C2375.902832,2935.658936 2389.437988,2934.625488 2399.270752,2943.736328 
	C2410.318359,2953.972656 2424.260986,2956.482666 2439.276367,2957.949219 
	C2441.471191,2958.084473 2442.954590,2958.089600 2445.179688,2958.110352 
	C2454.152832,2963.917969 2459.596924,2972.487061 2466.917725,2979.944824 
	C2460.228760,2988.568359 2451.320312,2991.745361 2440.699707,2992.361572 
z"
        />
        <path
          fill="#8C5B43"
          opacity="1.000000"
          stroke="none"
          d="
M2440.074707,2957.350098 
	C2436.304443,2962.155518 2432.291992,2962.373047 2427.462891,2959.860352 
	C2423.058105,2957.568604 2418.299561,2956.175049 2413.167480,2955.896729 
	C2405.624512,2955.488525 2401.446533,2949.084961 2396.247070,2944.928223 
	C2392.188232,2941.683350 2387.774170,2938.589600 2383.272705,2939.073730 
	C2378.681885,2939.567383 2375.922852,2938.507324 2372.556885,2935.956055 
	C2370.020508,2934.032959 2366.519531,2933.381836 2362.719238,2932.064941 
	C2364.625000,2919.517090 2372.178467,2916.540283 2381.400146,2924.553711 
	C2385.564697,2928.172607 2388.804688,2932.853760 2395.615967,2931.081543 
	C2397.483398,2930.595703 2400.038330,2932.171631 2402.110840,2933.153564 
	C2410.658691,2937.204590 2419.046631,2941.617188 2426.461670,2947.522705 
	C2429.693604,2950.097168 2433.142090,2950.049805 2436.737793,2950.008789 
	C2442.421143,2949.944336 2441.192871,2953.296631 2440.074707,2957.350098 
z"
        />
        <path
          fill="#8C5B43"
          opacity="1.000000"
          stroke="none"
          d="
M2466.571289,2980.462158 
	C2457.000977,2975.449951 2451.387207,2967.659180 2446.069824,2958.782715 
	C2454.378662,2955.202393 2458.078125,2955.671143 2460.742188,2962.198486 
	C2463.080811,2967.927734 2467.250000,2969.116211 2472.188965,2969.925293 
	C2476.385498,2970.612793 2480.753906,2970.720215 2483.897461,2975.180176 
	C2481.790039,2978.433105 2478.085693,2977.983398 2474.895020,2980.291504 
	C2471.927490,2981.692383 2469.566650,2982.205566 2466.571289,2980.462158 
z"
        />
        <path
          fill="#9E6A4C"
          opacity="1.000000"
          stroke="none"
          d="
M2473.607666,2978.512695 
	C2476.307373,2974.772461 2479.197754,2973.958740 2483.412598,2975.862305 
	C2484.965088,2975.908203 2485.856445,2975.925537 2487.418945,2975.947266 
	C2484.449951,2981.112305 2479.265137,2984.946777 2475.917969,2990.416992 
	C2474.665283,2992.464111 2471.807129,2991.926270 2468.284180,2991.528809 
	C2466.628662,2990.213135 2466.409912,2989.333496 2466.458008,2987.736328 
	C2467.309082,2983.176514 2471.833252,2982.412842 2473.607666,2978.512695 
z"
        />
        <path
          fill="#C2825D"
          opacity="1.000000"
          stroke="none"
          d="
M2273.540527,3552.378906 
	C2277.830811,3556.678467 2274.767090,3558.867676 2270.428955,3560.426270 
	C2270.113525,3557.298584 2271.362305,3554.955566 2273.540527,3552.378906 
z"
        />
        <path
          fill="#E6E1D0"
          opacity="1.000000"
          stroke="none"
          d="
M2762.034668,3257.976562 
	C2762.060547,3257.955811 2761.219482,3257.656494 2761.219482,3257.656494 
	C2761.219482,3257.656494 2761.728271,3257.655518 2761.850342,3257.853516 
	C2761.972412,3258.051758 2762.009033,3257.997559 2762.034668,3257.976562 
z"
        />
        <path
          fill="#CBC5B5"
          opacity="1.000000"
          stroke="none"
          d="
M2762.032471,3335.319824 
	C2761.283203,3335.842285 2760.657227,3335.567383 2760.176025,3335.132568 
	C2758.982910,3334.054443 2757.370117,3333.182861 2757.537598,3331.255371 
	C2757.569336,3330.893555 2758.441406,3330.315674 2758.911377,3330.329346 
	C2761.547119,3330.405029 2761.895264,3332.443359 2762.032471,3335.319824 
z"
        />
        <path
          fill="#CBC5B5"
          opacity="1.000000"
          stroke="none"
          d="
M2763.940186,3338.043457 
	C2763.247070,3337.899658 2762.730469,3337.537598 2762.084717,3336.602539 
	C2761.843262,3336.190186 2762.032959,3335.982422 2762.140625,3335.884277 
	C2762.866455,3336.028809 2763.357910,3336.427734 2763.920410,3337.419922 
	C2764.118408,3337.856934 2764.004150,3337.985840 2763.940186,3338.043457 
z"
        />
        <path
          fill="#CBC5B5"
          opacity="1.000000"
          stroke="none"
          d="
M2756.027832,3347.982178 
	C2756.264648,3348.247314 2756.473145,3348.535645 2756.681885,3348.823975 
	C2756.514160,3348.738525 2756.346680,3348.653076 2756.065918,3348.314941 
	C2755.952881,3348.062500 2755.999512,3348.005615 2756.027832,3347.982178 
z"
        />
        <path
          fill="#A6A29C"
          opacity="1.000000"
          stroke="none"
          d="
M2358.171387,3309.766602 
	C2358.633545,3315.218750 2352.967529,3318.697021 2354.043457,3325.264160 
	C2350.054932,3317.331055 2350.524902,3315.009033 2358.171387,3309.766602 
z"
        />
        <path
          fill="#A6A29C"
          opacity="1.000000"
          stroke="none"
          d="
M2378.429199,3307.430420 
	C2376.303467,3308.143555 2373.528076,3308.865723 2370.472168,3306.604004 
	C2372.706543,3305.347656 2375.333252,3305.563477 2378.429199,3307.430420 
z"
        />
        <path
          fill="#A6A29C"
          opacity="1.000000"
          stroke="none"
          d="
M2353.887695,3326.654785 
	C2355.803223,3327.025146 2355.998779,3328.840088 2356.063721,3331.374512 
	C2354.198486,3330.986572 2353.872803,3329.226562 2353.887695,3326.654785 
z"
        />
        <path
          fill="#A6A29C"
          opacity="1.000000"
          stroke="none"
          d="
M2369.723633,3305.441406 
	C2368.527588,3306.078613 2367.008789,3306.171631 2364.746094,3306.104980 
	C2365.397461,3303.514648 2367.331543,3303.857178 2369.723633,3305.441406 
z"
        />
        <path
          fill="#A6A29C"
          opacity="1.000000"
          stroke="none"
          d="
M2363.299316,3306.061523 
	C2363.421875,3306.678223 2362.896484,3307.371826 2361.665771,3308.019531 
	C2361.523926,3307.361816 2362.087891,3306.750000 2363.299316,3306.061523 
z"
        />
        <path
          fill="#A6A29C"
          opacity="1.000000"
          stroke="none"
          d="
M2373.261230,3325.902100 
	C2372.207275,3330.628906 2368.353760,3327.280273 2364.782227,3328.124512 
	C2366.256836,3325.217285 2369.524902,3326.051025 2373.261230,3325.902100 
z"
        />
        <path
          fill="#B17752"
          opacity="1.000000"
          stroke="none"
          d="
M2463.880859,3056.719238 
	C2464.089111,3060.072998 2465.337646,3061.645508 2466.215820,3063.427979 
	C2470.120605,3071.352295 2468.169922,3074.804443 2459.368408,3076.038574 
	C2458.090576,3076.217285 2456.828613,3076.509033 2455.461670,3076.766846 
	C2455.517334,3081.206055 2459.477783,3079.424561 2461.232422,3080.896484 
	C2462.587891,3082.033203 2463.707764,3083.242188 2463.559326,3085.009521 
	C2463.396729,3086.944580 2461.820312,3087.380127 2460.136963,3087.954346 
	C2454.885010,3089.745117 2454.382812,3091.685547 2457.991943,3095.485596 
	C2458.637939,3096.166016 2459.869141,3096.280518 2460.541992,3096.947266 
	C2462.307617,3098.696533 2461.817871,3100.553711 2460.295410,3102.174805 
	C2458.968994,3103.586914 2457.293457,3103.663086 2455.865967,3102.455078 
	C2453.885986,3100.779297 2452.094727,3098.880371 2450.197754,3097.053223 
	C2447.174561,3104.128418 2447.551758,3105.630127 2453.343018,3108.572266 
	C2455.103760,3109.466797 2457.163086,3109.791504 2458.883545,3110.743408 
	C2461.954590,3112.442139 2463.342041,3114.552979 2462.963379,3118.740967 
	C2462.355225,3125.468750 2457.131836,3130.530029 2457.364258,3137.480469 
	C2457.450439,3140.048584 2453.919922,3140.221191 2451.875000,3141.059570 
	C2447.800781,3142.730225 2445.216064,3145.768799 2444.513184,3149.843506 
	C2443.224854,3157.313965 2440.981201,3164.731689 2442.105713,3172.464600 
	C2442.484619,3175.070557 2442.620117,3177.351807 2441.416016,3180.075684 
	C2438.377441,3186.950928 2435.345703,3188.957520 2428.601074,3187.189453 
	C2425.353027,3186.337891 2426.360840,3183.672607 2425.980957,3181.533447 
	C2425.466064,3178.633545 2427.948730,3172.869385 2420.572021,3176.569580 
	C2418.183350,3177.767578 2418.650635,3174.404541 2418.762207,3172.473389 
	C2419.246582,3164.092773 2417.115967,3156.532471 2410.507080,3150.186523 
	C2407.825928,3150.166260 2407.158691,3148.910156 2406.935791,3146.506592 
	C2413.566650,3136.501221 2409.189697,3126.807129 2407.981445,3116.518555 
	C2407.403809,3113.769775 2407.433838,3111.747314 2407.820801,3108.988525 
	C2413.056885,3088.537598 2425.200928,3071.769043 2431.335449,3052.466553 
	C2432.643311,3048.351074 2434.464844,3044.067871 2440.164551,3044.723389 
	C2441.621826,3044.890625 2442.641357,3045.113525 2442.612793,3043.109863 
	C2442.531494,3037.379639 2447.399658,3033.831787 2448.984619,3028.701172 
	C2452.305664,3017.950439 2456.125977,3017.273193 2465.366211,3025.708984 
	C2466.906738,3031.277832 2472.091797,3032.839111 2474.846191,3037.364258 
	C2475.297852,3038.984619 2475.221680,3039.929199 2474.465332,3041.426270 
	C2472.431152,3045.881348 2473.247803,3050.718018 2468.202637,3053.395020 
	C2464.675781,3049.777344 2469.447266,3046.432373 2466.559570,3042.627441 
	C2464.923828,3040.918457 2463.711182,3039.697754 2462.039062,3038.037354 
	C2461.203857,3037.199219 2460.825439,3036.804199 2460.452637,3036.403076 
	C2460.584961,3036.638184 2460.812500,3036.787354 2461.398438,3037.403809 
	C2463.012451,3039.015381 2464.203613,3040.218262 2465.868896,3041.866699 
	C2470.028809,3047.585693 2463.563721,3050.865967 2463.880859,3056.719238 
z"
        />
        <path
          fill="#33495B"
          opacity="1.000000"
          stroke="none"
          d="
M2409.618408,3165.498535 
	C2411.709473,3167.123291 2411.820801,3168.833740 2411.727539,3171.253906 
	C2406.984375,3178.799316 2400.767334,3178.422363 2394.079102,3175.538818 
	C2386.200439,3172.142334 2378.241943,3170.264160 2369.514893,3169.140381 
	C2361.005859,3168.044922 2351.501221,3165.374756 2343.969971,3159.735107 
	C2340.602539,3157.213379 2337.042236,3155.836914 2332.933350,3155.974609 
	C2325.590332,3156.220947 2318.469727,3155.827148 2311.601074,3152.560303 
	C2306.399170,3150.086182 2301.999756,3152.998047 2297.286133,3156.488770 
	C2295.691650,3157.278320 2294.696289,3157.463623 2292.894043,3157.301025 
	C2291.150391,3156.729492 2290.309082,3156.121338 2289.186523,3154.661133 
	C2288.017822,3151.923828 2288.200195,3149.907227 2289.502441,3147.276367 
	C2292.779541,3144.268799 2293.182129,3140.570801 2295.294189,3136.927002 
	C2310.881348,3132.944092 2325.460205,3125.801514 2341.514893,3134.835205 
	C2348.745117,3138.903320 2358.027344,3139.659424 2366.555664,3140.447021 
	C2375.007080,3141.227295 2382.757080,3143.593018 2390.923340,3147.590332 
	C2393.951416,3149.404541 2394.626953,3153.070068 2398.873291,3152.995850 
	C2402.656738,3152.925049 2405.655029,3153.110840 2408.852051,3155.411621 
	C2409.493164,3156.873291 2409.598877,3157.791992 2409.597900,3159.398926 
	C2408.202881,3161.652100 2408.417969,3163.309326 2409.618408,3165.498535 
z"
        />
        <path
          fill="#434F51"
          opacity="1.000000"
          stroke="none"
          d="
M2418.264160,3223.643799 
	C2414.273193,3223.844238 2412.387695,3225.228027 2411.762939,3228.975098 
	C2411.055664,3233.215576 2408.482422,3234.606934 2404.660889,3231.764893 
	C2401.725342,3229.581543 2398.125244,3229.254150 2395.144531,3230.330078 
	C2390.178955,3232.122559 2387.200928,3230.315186 2383.820801,3226.985840 
	C2378.173096,3221.421631 2372.407959,3215.610840 2363.063477,3219.703857 
	C2360.476807,3220.836670 2358.158203,3218.584229 2355.843262,3217.222656 
	C2351.299072,3214.550293 2346.704834,3212.085449 2340.940918,3213.190918 
	C2336.852295,3213.975342 2333.399170,3210.987549 2329.734375,3209.736816 
	C2321.432373,3206.903809 2312.264648,3205.949707 2305.543213,3199.091797 
	C2301.934814,3195.409912 2296.139893,3196.250488 2290.913330,3194.000244 
	C2289.372559,3193.238770 2288.816406,3192.435303 2288.320068,3190.742432 
	C2288.283447,3186.499023 2287.210693,3182.851562 2290.037598,3179.092529 
	C2294.187744,3176.732910 2297.915283,3176.902100 2302.466309,3177.806641 
	C2304.230225,3178.419189 2305.162842,3178.913330 2306.657471,3179.965088 
	C2316.573975,3186.118408 2326.556885,3190.225830 2337.046143,3193.129150 
	C2338.309082,3193.478516 2339.776123,3193.295654 2340.927734,3193.859375 
	C2360.878906,3203.625977 2383.225098,3204.558105 2404.744385,3211.227539 
	C2405.864990,3211.577393 2406.302979,3211.736572 2407.361328,3212.196777 
	C2409.845459,3213.635254 2411.455811,3215.281494 2414.480957,3215.551758 
	C2416.925049,3216.249756 2418.497070,3217.108643 2419.696533,3219.413818 
	C2419.496338,3221.165527 2419.128662,3222.250000 2418.264160,3223.643799 
z"
        />
        <path
          fill="#2B3B46"
          opacity="1.000000"
          stroke="none"
          d="
M2392.459961,3150.214355 
	C2376.200195,3144.717041 2360.006348,3142.110840 2343.733643,3139.841797 
	C2342.160889,3139.622559 2340.338135,3139.049805 2339.227051,3138.007080 
	C2330.820801,3130.114502 2321.574463,3133.151611 2312.444580,3135.886230 
	C2309.864258,3136.659180 2307.610107,3137.770752 2304.829346,3136.607422 
	C2302.218750,3135.514893 2299.602051,3136.079834 2296.550293,3137.946289 
	C2294.682373,3137.322998 2294.159668,3136.023682 2293.986084,3133.850098 
	C2294.189697,3132.247314 2294.506592,3131.397949 2295.451660,3130.159180 
	C2296.038330,3128.562988 2297.993164,3130.833008 2297.283203,3128.261230 
	C2296.660645,3126.919678 2296.546875,3126.077881 2296.727539,3124.567383 
	C2297.083252,3123.520020 2297.291260,3123.120605 2298.452881,3121.726562 
	C2299.795166,3120.464111 2300.183838,3120.196777 2301.197266,3119.572754 
	C2302.262451,3119.003662 2302.702393,3118.791748 2303.837158,3118.306641 
	C2305.018311,3117.887451 2305.504150,3117.741943 2306.745605,3117.438965 
	C2320.399902,3115.631592 2332.468506,3120.730469 2345.032715,3121.953369 
	C2361.206055,3123.527588 2376.206299,3130.950195 2392.539795,3132.125244 
	C2398.443115,3132.549561 2401.307373,3137.730957 2406.022949,3141.479492 
	C2407.567871,3142.586914 2408.224365,3143.431641 2408.750488,3145.241211 
	C2408.891602,3147.127197 2408.965820,3148.201660 2409.410645,3149.684570 
	C2409.738037,3151.562744 2409.694580,3153.032715 2409.519287,3155.228516 
	C2405.888428,3155.908447 2402.388916,3155.862305 2398.001465,3155.739502 
	C2395.664307,3154.004639 2394.944824,3151.782959 2392.459961,3150.214355 
z"
        />
        <path
          fill="#4E585F"
          opacity="1.000000"
          stroke="none"
          d="
M2295.840088,3156.739258 
	C2300.945312,3148.480225 2305.223633,3145.650391 2311.018311,3149.012939 
	C2318.692383,3153.466064 2326.715576,3153.367188 2334.648926,3153.040283 
	C2339.626221,3152.835205 2342.922607,3153.962402 2345.838379,3157.518555 
	C2346.048828,3157.774902 2346.232910,3158.052979 2346.436035,3158.315674 
	C2350.439209,3163.499268 2356.979248,3159.491943 2360.817627,3161.792969 
	C2365.240234,3164.445068 2367.694336,3169.436279 2374.345947,3166.491699 
	C2377.867676,3164.932861 2382.156494,3165.594238 2385.610840,3168.449707 
	C2389.543945,3171.700439 2394.833252,3172.096680 2399.178955,3174.536621 
	C2403.577393,3177.005859 2406.588623,3172.857666 2411.000000,3172.028076 
	C2412.328125,3174.131592 2412.979980,3176.357178 2413.641602,3179.274902 
	C2411.666504,3183.444824 2410.978516,3188.120117 2404.959473,3188.665283 
	C2393.793457,3188.784424 2384.109863,3184.997803 2374.188965,3182.672119 
	C2357.876221,3178.848145 2342.379150,3172.277832 2326.473877,3167.060791 
	C2323.119873,3165.960693 2319.958252,3166.554688 2316.687256,3166.490723 
	C2308.489014,3166.331055 2301.096436,3164.144043 2295.840088,3156.739258 
z"
        />
        <path
          fill="#805541"
          opacity="1.000000"
          stroke="none"
          d="
M2420.432129,3219.562012 
	C2418.368408,3219.366455 2416.872803,3218.651855 2414.764648,3217.815674 
	C2413.449219,3217.205322 2412.958008,3216.561035 2412.651855,3215.115479 
	C2412.642090,3213.668457 2412.753662,3212.851562 2412.987061,3211.437012 
	C2413.092041,3209.602783 2413.016846,3208.385498 2412.566162,3206.569824 
	C2412.359863,3205.083252 2412.387207,3204.223633 2412.706543,3202.735840 
	C2413.365967,3201.332764 2413.987305,3200.718750 2415.395020,3200.095215 
	C2423.729736,3201.017334 2424.854980,3202.119141 2423.784424,3208.692139 
	C2423.213379,3212.200439 2421.950684,3215.596436 2420.432129,3219.562012 
z"
        />
        <path
          fill="#626669"
          opacity="1.000000"
          stroke="none"
          d="
M2416.019531,3199.100586 
	C2415.789307,3200.555908 2415.413086,3201.073242 2414.501465,3201.749512 
	C2410.363525,3197.104736 2403.867920,3194.261475 2403.584961,3186.582031 
	C2408.963867,3187.246826 2409.572510,3182.170654 2413.013672,3180.138184 
	C2413.945068,3186.123779 2416.671143,3191.968994 2416.019531,3199.100586 
z"
        />
        <path
          fill="#A26847"
          opacity="1.000000"
          stroke="none"
          d="
M2474.736572,3041.986328 
	C2474.002197,3041.112061 2473.985107,3040.226807 2473.960205,3038.677246 
	C2476.028320,3034.932861 2479.044678,3033.674561 2483.322998,3033.765625 
	C2484.628418,3034.948242 2485.247070,3035.896729 2485.903809,3037.436035 
	C2482.496094,3039.477051 2479.455078,3042.001953 2474.736572,3041.986328 
z"
        />
        <path
          fill="#805541"
          opacity="1.000000"
          stroke="none"
          d="
M2418.276855,3235.677246 
	C2416.017578,3234.467773 2415.800049,3232.699219 2417.520996,3230.366943 
	C2420.022217,3231.510254 2420.343750,3233.276367 2418.276855,3235.677246 
z"
        />
        <path
          fill="#2B3B46"
          opacity="1.000000"
          stroke="none"
          d="
M2410.072021,3165.250977 
	C2408.186279,3164.470215 2404.280029,3163.531982 2408.948975,3160.186523 
	C2409.692871,3161.560059 2409.894775,3163.032715 2410.072021,3165.250977 
z"
        />
        <path
          fill="#B17752"
          opacity="1.000000"
          stroke="none"
          d="
M2467.995117,3053.995117 
	C2467.810303,3055.784912 2467.270508,3057.321289 2464.622314,3057.893066 
	C2463.990234,3057.990234 2464.000000,3058.000000 2464.004883,3058.004883 
	C2462.283936,3055.105469 2463.438721,3053.659424 2467.237305,3053.585205 
	C2468.009766,3054.009766 2468.000000,3054.000000 2467.995117,3053.995117 
z"
        />
        <path
          fill="#8C5B43"
          opacity="1.000000"
          stroke="none"
          d="
M2474.958984,3100.937744 
	C2475.041016,3100.894287 2474.877197,3100.981201 2474.958984,3100.937744 
z"
        />
        <path
          fill="#B17752"
          opacity="1.000000"
          stroke="none"
          d="
M2427.042480,3193.068848 
	C2426.957275,3193.114990 2427.127441,3193.022705 2427.042480,3193.068848 
z"
        />
        <path
          fill="#1B2B3A"
          opacity="1.000000"
          stroke="none"
          d="
M2294.067871,3222.072266 
	C2292.567627,3216.617188 2289.383057,3211.587402 2289.741699,3204.833984 
	C2295.388672,3211.028320 2302.343750,3215.350830 2311.426025,3216.139404 
	C2316.514648,3216.581055 2320.063232,3220.646240 2324.407959,3222.814209 
	C2335.719727,3228.458252 2347.830322,3231.573975 2359.560303,3235.845947 
	C2362.067383,3236.759033 2364.738281,3236.860352 2365.240723,3240.969238 
	C2358.329346,3240.810059 2351.885498,3239.187256 2346.047607,3236.727051 
	C2335.019531,3232.079834 2322.709473,3232.116943 2311.966797,3226.525146 
	C2308.494385,3224.717773 2304.863770,3226.425537 2301.734863,3230.157227 
	C2300.354736,3230.125732 2299.652100,3229.659180 2298.665039,3228.856445 
	C2295.764404,3227.668701 2295.968994,3224.978271 2294.459961,3222.606934 
	C2294.048828,3222.076660 2294.041992,3222.047852 2294.067871,3222.072266 
z"
        />
        <path
          fill="#1B2B3A"
          opacity="1.000000"
          stroke="none"
          d="
M2289.368896,3189.723633 
	C2290.086670,3190.856934 2290.098633,3191.717773 2290.144043,3193.221436 
	C2290.104736,3196.706543 2290.032471,3199.549072 2289.929688,3203.240234 
	C2289.227051,3203.067383 2288.554443,3202.045898 2287.577148,3200.464111 
	C2285.795898,3198.282715 2285.559814,3196.300293 2285.734619,3193.479492 
	C2286.028076,3192.254395 2286.207520,3191.780762 2286.839844,3190.699707 
	C2287.749268,3189.878418 2288.206055,3189.665039 2289.368896,3189.723633 
z"
        />
        <path
          fill="#313E41"
          opacity="1.000000"
          stroke="none"
          d="
M2294.016357,3222.775391 
	C2296.344727,3223.022461 2297.587646,3224.807373 2298.244141,3227.844482 
	C2298.275879,3228.875732 2298.171143,3229.230713 2297.557861,3229.820312 
	C2293.498047,3229.039551 2294.151611,3226.065674 2294.016357,3222.775391 
z"
        />
        <path
          fill="#E5DDC9"
          opacity="1.000000"
          stroke="none"
          d="
M1594.506348,3032.485352 
	C1594.137451,3030.012451 1593.513184,3027.468018 1594.510620,3026.174316 
	C1599.205688,3020.084229 1601.194580,3013.869385 1598.951660,3006.092041 
	C1598.087646,3003.096191 1601.710815,3002.193604 1605.245117,3002.002686 
	C1608.138794,3003.075195 1609.027710,3005.584473 1611.113159,3006.656982 
	C1614.498047,3008.397461 1617.063843,3008.380371 1616.519409,3003.416016 
	C1616.298950,3001.407227 1615.851807,2999.001221 1617.286743,2997.643311 
	C1627.537109,2987.947021 1627.354248,2975.485352 1628.015503,2962.710205 
	C1628.340210,2956.437256 1629.729248,2948.349609 1639.478027,2947.114990 
	C1645.832886,2954.196777 1637.646973,2960.521484 1639.886719,2969.712891 
	C1642.643433,2959.140625 1646.844482,2951.440430 1649.076050,2942.987305 
	C1651.258667,2934.719971 1651.688965,2925.192139 1664.668213,2927.370850 
	C1667.514526,2927.848389 1670.281372,2923.687012 1674.532715,2925.810059 
	C1677.713745,2930.791260 1676.692749,2935.250732 1675.033936,2939.729004 
	C1672.191284,2947.403320 1663.784180,2953.394531 1667.921875,2963.184814 
	C1658.212402,2975.711426 1658.069946,2992.414551 1648.733887,3005.709961 
	C1644.260010,3009.158691 1640.722046,3006.918457 1637.774780,3005.023926 
	C1636.100342,3006.618896 1635.952637,3007.959961 1636.044434,3009.261230 
	C1636.523193,3016.038330 1636.170166,3021.974609 1627.185303,3022.876221 
	C1624.637329,3023.131592 1622.852783,3025.831543 1622.235107,3028.646240 
	C1621.552856,3031.755127 1623.200806,3032.682617 1625.994507,3032.960938 
	C1627.074219,3033.068848 1627.090942,3034.438232 1626.357910,3035.228271 
	C1619.092285,3043.055420 1613.009766,3053.052979 1598.855347,3050.003906 
	C1594.935303,3047.155273 1595.196533,3043.617188 1596.212402,3040.114502 
	C1596.989136,3037.436768 1597.218262,3035.060303 1594.506348,3032.485352 
z"
        />
        <path
          fill="#F9F4E5"
          opacity="1.000000"
          stroke="none"
          d="
M1648.099609,3006.591797 
	C1649.002808,3003.897217 1650.110352,3001.822021 1649.965332,2999.393066 
	C1649.738037,2995.587646 1650.455811,2992.163818 1653.424072,2989.437500 
	C1655.527100,2987.506104 1656.275513,2984.891113 1655.968872,2982.167725 
	C1655.281372,2976.059082 1656.927856,2971.521240 1662.406982,2967.846924 
	C1665.698242,2965.639893 1667.331909,2960.676514 1665.554932,2957.041992 
	C1663.656738,2953.159912 1665.925415,2951.701660 1667.211670,2948.942627 
	C1670.360718,2942.187500 1675.329102,2935.854980 1673.964600,2926.793457 
	C1672.932007,2924.837402 1672.418945,2923.535889 1672.595825,2921.343262 
	C1675.794434,2917.368408 1678.169312,2913.860840 1676.299927,2908.372559 
	C1676.149170,2906.778809 1676.209961,2905.864258 1676.550293,2904.283203 
	C1682.776733,2886.317871 1681.919189,2868.846924 1677.833496,2850.563477 
	C1676.687988,2842.031494 1674.770508,2834.089844 1681.376221,2826.659180 
	C1682.210815,2824.447021 1681.568604,2822.275146 1683.232300,2821.482178 
	C1685.479248,2820.411621 1685.640991,2822.719238 1686.288818,2824.104492 
	C1688.526001,2828.888184 1690.153931,2834.052490 1694.718750,2837.383789 
	C1695.471802,2837.933350 1696.642700,2839.171143 1696.465332,2839.549805 
	C1692.423706,2848.184814 1699.908081,2856.029297 1698.180786,2865.330078 
	C1698.587769,2867.221680 1698.902222,2868.242432 1699.679810,2869.567627 
	C1696.716064,2874.580322 1698.872925,2879.885742 1698.287842,2884.864502 
	C1697.194214,2894.168701 1694.314941,2903.262939 1692.061035,2913.217773 
	C1688.527588,2914.474365 1687.946045,2916.899170 1688.032471,2919.747559 
	C1688.156738,2923.849365 1687.574341,2926.847900 1683.014526,2929.186768 
	C1678.806763,2931.345459 1678.261719,2935.951172 1683.269043,2939.840820 
	C1684.607666,2940.253418 1685.126465,2940.632080 1685.804321,2941.558105 
	C1684.982056,2945.857666 1679.973022,2949.022461 1685.086426,2954.013672 
	C1687.074585,2955.954590 1683.865234,2957.820312 1681.306763,2957.909180 
	C1677.239502,2958.050537 1673.577148,2958.174805 1671.851929,2963.161377 
	C1669.519043,2969.905273 1669.654541,2970.642822 1676.788330,2972.042969 
	C1678.332397,2972.345703 1680.443848,2971.889404 1680.771973,2974.031982 
	C1681.160278,2976.567871 1679.115479,2977.349609 1677.116821,2977.750244 
	C1675.503906,2978.073730 1673.752930,2977.863770 1672.210571,2978.354004 
	C1667.219971,2979.939697 1666.480347,2982.584961 1669.978516,2987.259033 
	C1672.263794,2990.312500 1672.281616,2992.544434 1668.840332,2994.517822 
	C1667.436035,2995.322998 1665.856567,2995.968750 1664.983154,2997.966064 
	C1665.810303,2999.438965 1667.085327,3001.017822 1667.607056,3002.815430 
	C1668.240967,3004.998535 1668.605103,3007.657471 1666.224243,3009.079102 
	C1664.026489,3010.391113 1662.682617,3008.555664 1661.395996,3006.958008 
	C1658.390747,3003.227051 1655.356445,3003.398682 1652.457642,3007.112793 
	C1651.042480,3008.925781 1649.684204,3010.111572 1648.099609,3006.591797 
z"
        />
        <path
          fill="#E1D9C6"
          opacity="1.000000"
          stroke="none"
          d="
M1631.426270,3134.456055 
	C1634.296387,3135.055664 1636.666504,3136.118896 1639.530762,3137.403564 
	C1637.276611,3142.633301 1635.081665,3147.618164 1632.993774,3152.647217 
	C1632.499023,3153.839111 1632.139771,3155.172363 1632.097534,3156.453125 
	C1631.749512,3167.013428 1631.903809,3168.998535 1643.123169,3167.990234 
	C1644.502930,3171.698975 1642.069946,3174.858643 1642.017944,3179.232422 
	C1635.627563,3177.194824 1628.524292,3178.699951 1621.985229,3176.511963 
	C1617.569824,3175.034668 1616.697998,3180.685303 1612.673706,3181.859375 
	C1612.113159,3176.748535 1614.756836,3171.866211 1614.084473,3166.490967 
	C1613.881348,3164.866943 1614.420532,3163.231201 1615.590332,3161.946533 
	C1622.740601,3154.093018 1625.420898,3143.704834 1631.426270,3134.456055 
z"
        />
        <path
          fill="#D8D1C0"
          opacity="1.000000"
          stroke="none"
          d="
M1594.002930,3032.798828 
	C1598.785034,3031.817139 1600.911011,3034.065430 1599.395752,3038.665771 
	C1598.342407,3041.864014 1597.909790,3045.028320 1597.998291,3049.165039 
	C1597.042358,3055.777344 1598.457397,3062.443359 1591.622803,3065.987061 
	C1590.532471,3066.552490 1590.506592,3068.340820 1591.790283,3069.368408 
	C1593.143433,3070.451416 1596.706421,3071.312500 1594.265381,3073.263184 
	C1590.591675,3076.197998 1594.594849,3083.610107 1586.725830,3083.928711 
	C1586.106323,3081.258545 1585.108398,3078.816895 1584.493774,3076.271484 
	C1583.556274,3072.387451 1583.382568,3068.285889 1585.689941,3064.821533 
	C1592.015625,3055.324219 1591.151489,3043.992920 1594.002930,3032.798828 
z"
        />
        <path
          fill="#E1D9C6"
          opacity="1.000000"
          stroke="none"
          d="
M1583.399658,3234.342773 
	C1584.128418,3235.986572 1583.962646,3237.997070 1585.893921,3239.383301 
	C1591.250000,3243.228516 1591.086182,3243.040771 1585.547241,3246.805908 
	C1583.008057,3248.532227 1580.786987,3250.364014 1576.762207,3249.994629 
	C1577.591553,3244.592773 1578.231201,3238.761719 1583.399658,3234.342773 
z"
        />
        <path
          fill="#E1D9C6"
          opacity="1.000000"
          stroke="none"
          d="
M1593.303955,3210.020508 
	C1593.886841,3212.264648 1594.251953,3214.723633 1590.768799,3215.873779 
	C1588.451294,3215.124512 1586.407837,3214.105713 1587.023804,3211.988770 
	C1587.819824,3209.253174 1590.567139,3210.268311 1593.303955,3210.020508 
z"
        />
        <path
          fill="#E1D9C6"
          opacity="1.000000"
          stroke="none"
          d="
M1601.451416,3202.424072 
	C1604.387207,3204.265625 1604.647095,3206.256348 1600.535645,3207.830566 
	C1600.244385,3206.217529 1600.604736,3204.537842 1601.451416,3202.424072 
z"
        />
        <path
          fill="#E5DDC9"
          opacity="1.000000"
          stroke="none"
          d="
M1484.043945,3238.500244 
	C1483.274292,3236.094727 1484.630493,3236.204590 1485.842651,3236.506348 
	C1486.792969,3236.743164 1487.336304,3237.639160 1486.807129,3238.551758 
	C1486.265625,3239.485352 1485.290527,3239.350830 1484.043945,3238.500244 
z"
        />
        <path
          fill="#E5DDC9"
          opacity="1.000000"
          stroke="none"
          d="
M1487.139893,3230.714355 
	C1486.722412,3229.222656 1487.244263,3228.681396 1488.402710,3228.912109 
	C1488.643433,3228.959961 1488.967163,3229.301270 1489.003174,3229.544434 
	C1489.176758,3230.714355 1488.607910,3231.205078 1487.139893,3230.714355 
z"
        />
        <path
          fill="#F2EBDD"
          opacity="1.000000"
          stroke="none"
          d="
M1640.472900,2947.505127 
	C1631.337646,2950.353271 1632.645752,2958.676758 1630.166992,2964.668457 
	C1629.464233,2966.367188 1629.653320,2968.683350 1630.089600,2970.564209 
	C1632.655884,2981.628418 1628.862183,2990.210693 1620.307129,2997.172607 
	C1617.877563,2999.149902 1618.170532,3001.202148 1619.809082,3004.055664 
	C1622.624634,3008.959229 1620.228638,3012.612061 1614.976440,3011.677490 
	C1610.615356,3010.901123 1605.291626,3010.330811 1606.007080,3002.776855 
	C1605.995117,3001.990234 1606.051880,3001.020508 1606.072021,3000.535156 
	C1609.685547,2992.158203 1610.636841,2983.754639 1612.270996,2975.315674 
	C1615.528809,2958.493652 1618.825195,2941.584473 1622.923218,2924.883301 
	C1624.861206,2916.984619 1626.290161,2908.941162 1628.812744,2900.433838 
	C1634.270386,2897.359375 1635.064819,2892.834961 1635.754395,2887.800293 
	C1636.410400,2883.010742 1637.900513,2878.296387 1639.156128,2872.835449 
	C1639.352295,2871.146973 1639.571777,2870.208984 1640.166504,2868.641357 
	C1644.940308,2860.239502 1650.318237,2852.890625 1651.654541,2843.045654 
	C1652.106445,2841.376953 1652.456299,2840.303711 1651.687988,2838.685547 
	C1651.202026,2837.826904 1651.016724,2837.466553 1650.641846,2836.508789 
	C1647.945190,2827.082275 1650.744141,2819.672363 1656.936279,2812.482910 
	C1658.041748,2811.278564 1658.885498,2810.908691 1660.529907,2810.794922 
	C1662.118042,2811.156982 1662.910034,2811.616211 1663.998291,2811.699707 
	C1664.870361,2810.932129 1665.324951,2810.738525 1666.542725,2810.500244 
	C1672.878540,2812.309570 1673.148315,2816.075928 1670.502930,2821.356445 
	C1670.040894,2823.818115 1669.831543,2825.690674 1671.689941,2827.639648 
	C1675.454468,2838.603271 1670.867065,2848.642822 1669.810059,2858.864990 
	C1669.186279,2864.899170 1667.090332,2871.013428 1664.955322,2877.688477 
	C1663.316040,2883.271973 1656.406982,2885.275146 1657.944092,2892.311035 
	C1658.121582,2894.472168 1657.934814,2895.888916 1657.173340,2897.914551 
	C1648.593628,2908.394775 1647.305176,2920.695801 1644.770386,2933.270020 
	C1643.110962,2938.219238 1642.427490,2942.737793 1640.472900,2947.505127 
z"
        />
        <path
          fill="#BAB4A7"
          opacity="1.000000"
          stroke="none"
          d="
M1642.674316,2867.589844 
	C1642.117065,2869.025391 1642.022949,2869.804443 1641.873779,2871.169434 
	C1640.632324,2873.132324 1640.006104,2874.817383 1638.827148,2876.961426 
	C1631.146851,2887.488770 1626.323486,2898.568115 1623.749512,2911.295410 
	C1623.848389,2912.889893 1623.772949,2913.795654 1623.416992,2915.342041 
	C1621.871338,2918.247559 1620.508911,2920.500977 1619.930542,2923.817871 
	C1619.139038,2926.181152 1618.379761,2927.810547 1616.522217,2929.547363 
	C1612.335083,2941.804199 1605.917236,2952.662598 1605.526367,2965.645508 
	C1605.381226,2970.468750 1602.214966,2975.306152 1601.196289,2980.518311 
	C1600.395386,2984.614746 1598.538696,2988.511230 1598.423462,2992.902344 
	C1598.332397,2996.378906 1595.852051,2998.832031 1593.793945,3001.497070 
	C1591.168823,3004.896240 1588.416992,3008.594238 1592.005737,3013.144287 
	C1592.575317,3013.866699 1593.275635,3015.352539 1592.960571,3015.778076 
	C1586.989136,3023.838135 1590.057129,3035.465088 1581.626709,3044.065430 
	C1579.750122,3037.135254 1574.731812,3031.549316 1582.491699,3025.933350 
	C1583.915771,3024.902832 1583.122925,3022.969482 1582.639160,3021.301025 
	C1579.718750,3011.227295 1578.916382,3001.364990 1586.228882,2992.542480 
	C1587.388550,2991.143311 1587.537598,2989.588623 1586.940430,2987.881104 
	C1585.542725,2983.883301 1585.654175,2980.675293 1590.877197,2979.751953 
	C1593.293457,2979.324463 1593.747925,2977.466553 1593.426758,2975.188721 
	C1592.421875,2968.063721 1594.115967,2961.455078 1597.423950,2955.161133 
	C1599.756470,2950.723145 1600.890015,2945.951904 1600.602783,2940.454102 
	C1599.388306,2939.380371 1598.790405,2938.661133 1598.180664,2937.112549 
	C1597.954102,2935.458740 1598.037964,2934.511475 1598.521484,2932.916260 
	C1599.056030,2931.873047 1599.326782,2931.489746 1600.115479,2930.641113 
	C1607.374634,2927.184082 1605.123779,2920.248779 1607.269043,2914.545898 
	C1607.500610,2913.009277 1607.726074,2912.154785 1608.286377,2910.719238 
	C1609.511841,2908.270264 1611.219360,2906.777832 1611.370361,2903.847900 
	C1611.701782,2902.375977 1611.977783,2901.558350 1612.600342,2900.177246 
	C1615.011841,2896.906738 1617.191650,2894.251221 1617.908447,2890.073486 
	C1618.739746,2887.338867 1619.253662,2885.178467 1620.828857,2882.736572 
	C1624.436646,2878.710449 1627.949707,2875.438721 1630.032959,2870.272949 
	C1631.618164,2866.383057 1634.815063,2864.113037 1635.473633,2859.691650 
	C1636.114136,2857.581543 1636.854004,2856.320557 1638.514648,2854.773926 
	C1640.590942,2853.567139 1642.102539,2853.285889 1644.315308,2854.284180 
	C1647.329590,2859.291504 1644.867798,2863.058594 1642.674316,2867.589844 
z"
        />
        <path
          fill="#E5DDC9"
          opacity="1.000000"
          stroke="none"
          d="
M1599.270996,2935.499512 
	C1599.944336,2936.841797 1600.006104,2937.733398 1600.109497,2939.293457 
	C1595.252686,2951.741211 1587.627686,2962.303467 1584.589600,2974.917236 
	C1583.731323,2978.480469 1582.647095,2982.060547 1580.048096,2984.738037 
	C1577.034790,2987.842285 1575.245605,2990.893799 1577.396851,2995.324219 
	C1578.385620,2997.360107 1577.164062,2999.100342 1575.247437,3000.270508 
	C1573.026123,3001.626709 1570.388184,3002.582031 1570.008789,3005.759766 
	C1569.599121,3009.189697 1567.618286,3010.323486 1564.430542,3009.167480 
	C1561.483887,3008.098877 1559.665405,3009.645020 1559.165527,3012.064941 
	C1557.517212,3020.043213 1550.418701,3025.003418 1548.090088,3033.282715 
	C1546.414673,3034.492676 1544.812744,3035.004150 1542.626099,3035.771729 
	C1541.424683,3026.653809 1541.311157,3017.529541 1552.785889,3015.295654 
	C1553.107788,3011.496094 1549.291382,3012.542725 1549.278687,3009.476562 
	C1550.447266,3006.813232 1554.416504,3008.429932 1556.530273,3006.095947 
	C1559.555664,3002.754639 1554.924805,2998.937256 1557.768677,2995.754639 
	C1560.091675,2993.155029 1565.672119,2995.181885 1565.979004,2989.828857 
	C1566.212769,2985.752686 1569.349609,2983.290527 1571.806030,2980.778320 
	C1576.575806,2975.900391 1578.891724,2970.460205 1578.012939,2962.854492 
	C1578.019531,2962.019531 1578.988647,2961.980469 1579.473389,2961.968750 
	C1583.890381,2955.028564 1587.657593,2948.011963 1590.948975,2940.754883 
	C1592.484741,2937.368652 1593.609375,2933.607178 1599.270996,2935.499512 
z"
        />
        <path
          fill="#E5DDC9"
          opacity="1.000000"
          stroke="none"
          d="
M1621.408203,2916.387695 
	C1621.833862,2915.001709 1621.869507,2914.144531 1621.922363,2912.643555 
	C1622.356934,2907.097900 1623.357910,2902.442383 1628.730957,2899.565918 
	C1629.821533,2904.963867 1630.946289,2910.146240 1627.142334,2914.328857 
	C1626.927246,2914.565186 1626.653564,2914.969238 1626.719604,2915.209229 
	C1629.178711,2924.139648 1621.392944,2931.222900 1622.109253,2939.617432 
	C1623.081543,2951.009521 1617.143188,2960.659424 1615.180786,2971.277588 
	C1614.759521,2973.557617 1613.412109,2975.911865 1614.453613,2978.060303 
	C1618.335815,2986.068359 1611.868408,2991.730713 1610.058960,2998.422363 
	C1609.884644,2999.067139 1608.402100,2999.358154 1606.809326,2999.931641 
	C1602.538574,2995.657471 1601.253662,2991.780518 1604.353394,2985.778564 
	C1607.244263,2980.180908 1608.266724,2973.213379 1610.465088,2967.230225 
	C1613.789917,2958.180908 1612.551514,2948.307861 1616.973022,2939.693359 
	C1618.375244,2936.961182 1618.455200,2933.961914 1616.545044,2930.538574 
	C1616.348022,2928.293945 1616.676636,2926.582275 1617.487061,2924.420166 
	C1620.447876,2922.250732 1619.550415,2919.072266 1621.408203,2916.387695 
z"
        />
        <path
          fill="#D8D1C0"
          opacity="1.000000"
          stroke="none"
          d="
M1567.900879,3072.306641 
	C1568.439453,3070.370605 1569.581909,3068.406250 1570.415283,3069.471191 
	C1573.074463,3072.868164 1572.577026,3076.789551 1570.354248,3080.363525 
	C1568.657471,3083.091797 1566.161011,3083.114014 1563.408936,3082.030273 
	C1562.148315,3081.533936 1560.875244,3081.098145 1560.599609,3079.626953 
	C1560.218140,3077.590332 1561.483276,3076.597168 1563.239136,3076.179199 
	C1565.197876,3075.712646 1567.159546,3075.346191 1567.900879,3072.306641 
z"
        />
        <path
          fill="#D8D1C0"
          opacity="1.000000"
          stroke="none"
          d="
M1561.309570,3093.041992 
	C1557.359619,3092.561523 1554.208862,3091.318115 1558.944702,3088.302734 
	C1560.963257,3087.017334 1563.881714,3084.795166 1566.901733,3087.458252 
	C1566.688354,3090.604248 1563.527344,3090.977783 1561.309570,3093.041992 
z"
        />
        <path
          fill="#E5DDC9"
          opacity="1.000000"
          stroke="none"
          d="
M1526.005615,3086.004883 
	C1526.192749,3084.787842 1526.225586,3083.414307 1527.770630,3083.114990 
	C1528.091553,3083.052734 1528.882935,3083.728271 1528.896118,3084.084717 
	C1528.937622,3085.199463 1528.024536,3085.593018 1526.535156,3085.915527 
	C1525.988037,3085.990234 1526.000000,3086.000000 1526.005615,3086.004883 
z"
        />
        <path
          fill="#E5DDC9"
          opacity="1.000000"
          stroke="none"
          d="
M1547.991211,3034.008057 
	C1549.202515,3034.204346 1550.584595,3034.225586 1550.886230,3035.775146 
	C1550.948486,3036.095215 1550.269653,3036.888672 1549.912720,3036.901367 
	C1548.794678,3036.940674 1548.405151,3036.023438 1548.089111,3034.530029 
	C1548.016724,3033.981445 1548.000000,3034.000000 1547.991211,3034.008057 
z"
        />
        <path
          fill="#D8D1C0"
          opacity="1.000000"
          stroke="none"
          d="
M1569.045410,3063.074219 
	C1568.960449,3063.120117 1569.130371,3063.028564 1569.045410,3063.074219 
z"
        />
        <path
          fill="#DED5C3"
          opacity="1.000000"
          stroke="none"
          d="
M1492.822632,3018.012695 
	C1486.393066,3019.987061 1482.130981,3026.257568 1474.748047,3022.866455 
	C1472.276733,3021.731689 1470.873779,3024.598633 1469.692139,3026.647461 
	C1468.079712,3029.443359 1467.306030,3033.015137 1462.703613,3033.906250 
	C1460.963501,3032.859131 1460.287109,3031.545166 1460.097412,3029.263184 
	C1462.690063,3017.844238 1465.445801,3007.204590 1474.113525,2999.492920 
	C1475.804077,2997.988770 1476.294678,2997.882812 1474.386963,2995.528320 
	C1467.991943,2987.636719 1468.596069,2984.776855 1476.965942,2980.124756 
	C1483.302368,2976.603027 1486.213135,2970.648926 1490.435913,2965.351074 
	C1496.804199,2957.361572 1503.518188,2949.709717 1509.041748,2940.986816 
	C1510.656372,2938.436768 1514.370361,2936.160645 1517.543213,2936.264893 
	C1533.611938,2936.791748 1534.739380,2922.586426 1540.811035,2913.274658 
	C1541.034180,2912.932373 1540.323853,2912.115723 1539.517822,2911.010742 
	C1538.826538,2909.540771 1538.670044,2908.636719 1538.858398,2907.035400 
	C1545.766846,2902.062988 1551.875610,2897.241943 1553.649658,2888.075195 
	C1554.046997,2887.035400 1554.230103,2886.629150 1554.748047,2885.646973 
	C1557.481323,2882.392090 1558.570679,2879.051025 1560.074951,2875.156738 
	C1561.331665,2872.908203 1562.670410,2871.684570 1565.030151,2870.648926 
	C1569.390015,2870.193848 1572.301636,2868.763184 1574.370361,2865.450928 
	C1576.237061,2862.462158 1578.376709,2859.437988 1583.225586,2861.654785 
	C1584.520752,2864.828369 1585.678833,2866.886475 1587.694702,2862.976318 
	C1590.535034,2857.466553 1590.587646,2851.294922 1590.744507,2844.232178 
	C1595.855347,2847.667480 1592.966309,2852.244141 1594.457520,2855.498779 
	C1600.203979,2852.487793 1599.871704,2846.269043 1601.634277,2840.941162 
	C1606.006592,2848.785156 1604.104492,2856.987061 1605.048218,2864.802246 
	C1605.164917,2865.768311 1603.884644,2867.347900 1602.850220,2867.933594 
	C1597.275757,2871.089600 1594.334961,2875.975830 1591.732178,2881.713623 
	C1589.443115,2886.759766 1588.656006,2886.402832 1594.261597,2889.026367 
	C1592.645996,2891.645752 1590.795410,2893.900879 1586.644287,2893.908691 
	C1580.679199,2893.772705 1578.246094,2896.094971 1577.893799,2901.596436 
	C1577.279297,2911.192139 1573.558716,2919.202881 1563.953247,2923.060303 
	C1559.453491,2924.867432 1558.915771,2929.894775 1555.616577,2932.638184 
	C1554.790161,2933.325439 1554.697632,2934.614990 1555.696045,2935.181885 
	C1562.600342,2939.102051 1557.848389,2941.007812 1554.302979,2942.777344 
	C1548.297485,2945.774170 1551.556519,2947.883301 1558.018311,2950.612549 
	C1545.433228,2951.896729 1541.326782,2959.562012 1536.797119,2966.573486 
	C1535.121704,2969.166504 1533.214966,2971.134521 1530.705444,2972.881348 
	C1525.836426,2976.270752 1523.159790,2981.912109 1523.174683,2987.096191 
	C1523.188965,2992.044678 1521.278320,2993.977539 1517.705933,2994.952148 
	C1512.878662,2996.269531 1509.389282,2996.974609 1511.012695,3003.891357 
	C1512.271606,3009.254639 1505.073975,3011.163330 1502.732056,3015.538086 
	C1500.908691,3018.944092 1496.863525,3017.812500 1492.822632,3018.012695 
z"
        />
        <path
          fill="#E5DDC9"
          opacity="1.000000"
          stroke="none"
          d="
M1535.671143,3026.866211 
	C1535.737671,3029.630371 1535.378174,3031.664307 1532.659668,3031.424072 
	C1530.522095,3031.235352 1529.997437,3029.396729 1530.597656,3027.789551 
	C1531.444214,3025.521729 1532.810913,3023.384521 1535.671143,3026.866211 
z"
        />
        <path
          fill="#E1D9C6"
          opacity="1.000000"
          stroke="none"
          d="
M1639.208252,3184.032715 
	C1640.033447,3184.950439 1639.775146,3186.048828 1640.157959,3186.834717 
	C1642.759888,3192.177734 1639.629883,3194.135498 1634.621216,3195.713135 
	C1634.002075,3193.729492 1634.043457,3191.468506 1634.029053,3189.208008 
	C1634.010132,3186.209717 1635.127930,3184.218750 1639.208252,3184.032715 
z"
        />
        <path
          fill="#E1D9C6"
          opacity="1.000000"
          stroke="none"
          d="
M1593.914795,3263.337891 
	C1592.254150,3263.949219 1590.437134,3264.138916 1588.435425,3262.492676 
	C1590.253662,3259.632324 1592.244019,3259.407471 1593.914795,3263.337891 
z"
        />
        <path
          fill="#E1D9C6"
          opacity="1.000000"
          stroke="none"
          d="
M1603.292725,3260.007812 
	C1603.047974,3262.061523 1601.591797,3263.503174 1598.607422,3263.910400 
	C1598.243774,3261.161133 1599.909790,3259.993408 1603.292725,3260.007812 
z"
        />
        <path
          fill="#E1D9C6"
          opacity="1.000000"
          stroke="none"
          d="
M1653.912354,3233.109131 
	C1652.557739,3232.366699 1652.542847,3231.619629 1653.509644,3230.959961 
	C1653.711914,3230.821533 1654.180908,3230.827393 1654.378418,3230.971191 
	C1655.322510,3231.658936 1655.288086,3232.405029 1653.912354,3233.109131 
z"
        />
        <path
          fill="#E1D9C6"
          opacity="1.000000"
          stroke="none"
          d="
M1644.703125,3167.893555 
	C1643.814331,3164.035156 1644.897705,3161.040039 1649.214844,3159.793213 
	C1650.616211,3159.388672 1651.690063,3157.849854 1653.459229,3156.414795 
	C1656.865723,3159.479492 1656.968506,3162.269531 1652.238647,3164.219238 
	C1649.874878,3165.193848 1647.687500,3166.595703 1644.703125,3167.893555 
z"
        />
        <path
          fill="#E1D9C6"
          opacity="1.000000"
          stroke="none"
          d="
M1650.360107,3192.453125 
	C1651.107910,3190.321777 1652.816528,3190.025391 1655.313477,3189.990723 
	C1654.795898,3191.971924 1653.842896,3194.388672 1650.360107,3192.453125 
z"
        />
        <path
          fill="#D99469"
          opacity="1.000000"
          stroke="none"
          d="
M1640.192139,2379.599121 
	C1637.813599,2382.018799 1636.996948,2385.803711 1632.520264,2385.342285 
	C1628.792236,2382.880127 1628.911499,2378.617920 1626.363892,2375.955322 
	C1621.354858,2370.720459 1616.258545,2365.900879 1608.421875,2371.281982 
	C1605.644775,2373.189209 1601.916260,2373.460693 1600.437866,2370.592041 
	C1597.926392,2365.718750 1594.378662,2364.169189 1589.382568,2362.473389 
	C1584.068115,2360.669189 1588.431763,2356.498291 1590.156006,2353.663086 
	C1592.471558,2349.855225 1596.610107,2346.881836 1593.307617,2341.357422 
	C1590.221924,2336.195557 1587.262695,2337.343994 1583.917603,2340.615723 
	C1583.245728,2341.272705 1583.395874,2342.524414 1582.982056,2343.399902 
	C1577.265625,2355.495605 1574.833008,2355.786377 1566.609131,2345.445801 
	C1565.992920,2344.671143 1565.258301,2343.989258 1564.316406,2342.591797 
	C1567.204102,2334.253418 1573.143555,2329.384033 1581.280029,2325.991699 
	C1582.358398,2325.643799 1582.687500,2325.319336 1583.499268,2324.497559 
	C1584.605957,2323.765869 1585.122925,2323.385742 1585.777344,2322.439697 
	C1589.353271,2321.898438 1592.616455,2322.080322 1595.623169,2323.791016 
	C1599.546997,2326.023193 1603.519897,2326.185547 1608.001099,2325.130615 
	C1614.830933,2323.522949 1618.806885,2327.706787 1617.536377,2334.446777 
	C1617.415894,2335.085938 1617.018433,2335.669678 1616.868530,2336.307373 
	C1615.703979,2341.263428 1612.578857,2346.927246 1614.006592,2350.982422 
	C1615.323486,2354.722412 1621.786377,2356.471436 1624.085327,2361.131592 
	C1629.926270,2359.964111 1631.702026,2366.302979 1636.353271,2367.747070 
	C1641.797241,2369.437256 1642.593994,2373.855225 1640.192139,2379.599121 
z"
        />
        <path
          fill="#B17752"
          opacity="1.000000"
          stroke="none"
          d="
M1659.240723,2345.572266 
	C1659.329468,2346.015625 1659.158203,2346.499512 1659.115601,2346.488525 
	C1658.407715,2346.304688 1658.389404,2345.910645 1659.240723,2345.572266 
z"
        />
        <path
          fill="#ECA777"
          opacity="1.000000"
          stroke="none"
          d="
M1563.471191,2342.378418 
	C1568.006226,2341.851074 1571.308594,2342.731201 1571.969360,2347.470459 
	C1572.196777,2349.101562 1572.388184,2351.084717 1574.533203,2351.017334 
	C1576.310791,2350.961670 1577.616333,2349.424561 1577.736450,2347.727539 
	C1577.943481,2344.807861 1583.427246,2343.204102 1579.633301,2339.186523 
	C1578.712769,2338.211670 1580.432983,2337.108887 1581.902466,2337.093506 
	C1582.867432,2337.083496 1584.065674,2337.400879 1584.754150,2336.951172 
	C1587.064575,2335.441650 1587.914185,2331.364014 1591.771606,2332.657959 
	C1594.955444,2333.726318 1593.441040,2337.409424 1595.021606,2339.446777 
	C1596.238525,2341.014893 1597.618652,2342.468262 1598.721313,2344.110352 
	C1599.933105,2345.915039 1600.333008,2348.280518 1598.189697,2349.309814 
	C1593.357422,2351.630859 1593.111450,2357.379639 1588.666748,2359.717285 
	C1590.799316,2361.909668 1592.297729,2360.528564 1593.810181,2360.372070 
	C1600.141602,2359.716064 1600.257812,2359.724854 1600.807861,2366.540039 
	C1600.962646,2368.459229 1601.565063,2369.623779 1603.241943,2370.337646 
	C1605.079468,2371.119873 1606.345581,2370.344238 1607.495728,2368.915771 
	C1612.757935,2362.380615 1615.036377,2364.155518 1621.094116,2367.685791 
	C1627.143433,2371.211182 1633.072021,2375.226562 1631.905029,2384.507324 
	C1630.850830,2385.892334 1629.829712,2386.546875 1628.271240,2387.479492 
	C1623.825806,2392.707764 1618.511353,2395.882080 1612.886841,2399.702148 
	C1610.818726,2402.293457 1608.421875,2403.822998 1607.535400,2407.856934 
	C1606.770874,2409.703369 1606.163818,2410.248047 1604.970337,2411.036865 
	C1604.089722,2411.536865 1603.729858,2411.719482 1602.825684,2412.166992 
	C1601.923584,2412.620605 1601.565918,2412.809570 1600.739502,2413.388672 
	C1597.399780,2416.966064 1595.975342,2421.373535 1591.420044,2423.982422 
	C1586.380859,2426.024414 1584.405640,2432.240723 1578.434814,2431.203613 
	C1578.112793,2431.147949 1577.799194,2431.043701 1577.481567,2430.962158 
	C1577.280518,2431.785889 1578.267822,2431.479736 1578.541992,2432.650879 
	C1578.448975,2433.782715 1578.243164,2434.222656 1577.426270,2435.096680 
	C1570.475220,2437.600830 1564.425293,2433.844238 1557.359985,2434.491455 
	C1552.111816,2432.320557 1547.131470,2433.383545 1541.310791,2431.057129 
	C1537.095947,2426.874756 1533.157837,2428.498779 1528.840576,2427.954590 
	C1527.089478,2422.923340 1532.909546,2426.791992 1533.512695,2423.757812 
	C1534.748535,2423.386475 1532.307617,2423.258545 1533.286011,2424.164307 
	C1531.396729,2425.818604 1529.185791,2424.770020 1526.514893,2424.458984 
	C1525.032959,2424.009521 1524.151123,2423.999512 1522.611816,2423.916992 
	C1521.798950,2415.676514 1524.696655,2407.791748 1524.069458,2399.547852 
	C1523.737793,2395.187500 1528.730347,2393.391846 1529.861328,2388.715820 
	C1537.384399,2379.306396 1542.369019,2369.207764 1548.304565,2359.697998 
	C1552.202637,2353.452148 1557.993408,2348.387939 1563.471191,2342.378418 
z"
        />
        <path
          fill="#2C2520"
          opacity="1.000000"
          stroke="none"
          d="
M1514.013428,2422.235107 
	C1512.528931,2422.766357 1511.029785,2422.476318 1508.786377,2422.013672 
	C1508.174438,2419.141846 1509.655518,2417.984131 1512.930420,2418.149658 
	C1513.720215,2419.248535 1513.859619,2420.331299 1514.013428,2422.235107 
z"
        />
        <path
          fill="#ECA777"
          opacity="1.000000"
          stroke="none"
          d="
M1529.964844,2388.034424 
	C1528.729248,2387.955811 1527.462280,2387.836914 1526.932373,2386.569336 
	C1526.774536,2386.191895 1527.128906,2385.204102 1527.459229,2385.085938 
	C1528.929565,2384.560303 1529.541748,2385.568359 1529.996094,2387.357910 
	C1530.069336,2387.929443 1530.000122,2387.999756 1529.964844,2388.034424 
z"
        />
        <path
          fill="#B17752"
          opacity="1.000000"
          stroke="none"
          d="
M1583.568604,2324.232666 
	C1583.771606,2324.624268 1583.403442,2325.134766 1582.453491,2325.750000 
	C1582.268677,2325.365723 1582.644043,2324.864746 1583.568604,2324.232666 
z"
        />
        <path
          fill="#BA815C"
          opacity="1.000000"
          stroke="none"
          d="
M1614.448120,2436.962891 
	C1614.653076,2427.543213 1622.212036,2425.051025 1627.961060,2421.208740 
	C1630.641357,2419.417236 1633.417847,2418.098389 1635.756958,2415.594482 
	C1641.002686,2409.979492 1643.895386,2410.759277 1646.978394,2417.914062 
	C1647.367432,2418.817383 1647.614746,2419.781738 1648.058472,2421.468994 
	C1648.665283,2424.284180 1648.067139,2426.134033 1646.604370,2428.496826 
	C1638.918945,2434.617188 1630.911987,2438.306152 1621.050781,2439.508301 
	C1618.167236,2439.812012 1616.258057,2439.328613 1614.448120,2436.962891 
z"
        />
        <path
          fill="#BA815C"
          opacity="1.000000"
          stroke="none"
          d="
M1601.669434,2410.352783 
	C1602.054688,2409.950928 1602.665649,2409.827881 1602.974121,2409.871338 
	C1604.473267,2409.381104 1605.716797,2409.027344 1607.472168,2408.373047 
	C1607.940430,2407.924805 1607.918213,2408.017578 1607.874023,2407.995117 
	C1612.149536,2408.512451 1616.469238,2409.052246 1621.396484,2409.774902 
	C1621.203735,2410.957275 1620.340698,2412.867676 1619.612793,2412.817383 
	C1611.493652,2412.258301 1607.566406,2418.492676 1602.555054,2422.975586 
	C1600.288574,2425.002930 1599.875488,2427.078125 1603.340454,2427.772217 
	C1607.957520,2428.697021 1608.545410,2430.973633 1606.307373,2435.363281 
	C1600.817993,2439.870605 1595.900879,2436.699463 1590.320312,2434.876953 
	C1587.118042,2430.762207 1589.210693,2427.008057 1589.760010,2422.675781 
	C1593.278198,2420.200195 1595.870972,2417.928955 1596.072388,2413.865479 
	C1596.160767,2412.080078 1597.080688,2410.867920 1599.569092,2410.356445 
	C1600.606323,2410.452881 1600.945312,2410.603760 1601.669434,2410.352783 
z"
        />
        <path
          fill="#B9A795"
          opacity="1.000000"
          stroke="none"
          d="
M1589.140869,2431.620361 
	C1594.295898,2435.093506 1599.659668,2434.480225 1605.250488,2436.021484 
	C1608.191162,2436.063965 1610.342773,2436.108154 1613.304443,2436.212646 
	C1615.898438,2436.742676 1617.682495,2437.212158 1620.162354,2437.579590 
	C1630.994995,2438.008789 1641.010986,2433.761475 1651.853271,2437.106445 
	C1652.812988,2437.913574 1653.128418,2438.323730 1653.755249,2439.461670 
	C1654.491455,2441.785400 1654.508789,2443.395996 1653.989258,2445.811768 
	C1653.453369,2447.081787 1653.165039,2447.556396 1652.222900,2448.547852 
	C1641.934692,2451.836426 1633.697632,2445.747314 1624.607056,2444.707764 
	C1611.224487,2443.177490 1598.085693,2439.724365 1584.600098,2438.621582 
	C1582.206543,2438.425781 1580.126831,2437.500488 1578.400635,2434.697754 
	C1578.133179,2433.921143 1578.055176,2432.971191 1578.005737,2432.497070 
	C1581.348755,2430.617676 1584.725098,2429.044189 1589.140869,2431.620361 
z"
        />
        <path
          fill="#D0D1C8"
          opacity="1.000000"
          stroke="none"
          d="
M1652.288086,2439.641113 
	C1652.013550,2438.885742 1651.991821,2439.012207 1652.055908,2439.015625 
	C1651.595337,2438.163330 1651.072998,2437.305908 1650.365234,2435.717041 
	C1650.010864,2432.935059 1650.619507,2431.037598 1651.593384,2428.471436 
	C1652.614380,2425.966064 1653.664185,2424.349121 1655.742676,2422.619873 
	C1656.322754,2422.193359 1656.233032,2422.044678 1656.157837,2422.087891 
	C1656.908447,2421.131592 1656.994751,2419.848633 1657.816284,2418.226074 
	C1659.569458,2416.088867 1660.906372,2414.413330 1662.392700,2412.247559 
	C1663.282593,2411.386719 1664.023193,2411.016357 1665.293457,2410.324219 
	C1662.428711,2416.396973 1659.803711,2423.055420 1659.066895,2431.086914 
	C1658.507202,2433.980225 1657.745239,2436.078369 1656.864014,2438.838379 
	C1655.623901,2441.072021 1654.183716,2441.155762 1652.288086,2439.641113 
z"
        />
        <path
          fill="#704A3A"
          opacity="1.000000"
          stroke="none"
          d="
M1655.507324,2422.086914 
	C1655.871094,2423.876221 1655.419434,2425.558838 1654.565186,2427.770752 
	C1652.654663,2429.440186 1651.032837,2430.224854 1648.394409,2429.768311 
	C1647.324097,2429.368896 1646.925659,2429.135986 1646.144775,2428.326416 
	C1646.242676,2426.161621 1646.723022,2424.573486 1647.696289,2422.603027 
	C1650.356812,2422.140381 1652.524414,2422.060303 1655.507324,2422.086914 
z"
        />
        <path
          fill="#704A3A"
          opacity="1.000000"
          stroke="none"
          d="
M1657.728638,2418.165039 
	C1657.886108,2419.003418 1657.539673,2420.242676 1656.637939,2421.806641 
	C1656.463257,2420.942871 1656.843994,2419.754150 1657.728638,2418.165039 
z"
        />
        <path
          fill="#9E6A4C"
          opacity="1.000000"
          stroke="none"
          d="
M1613.174316,2400.177490 
	C1615.306763,2394.085449 1620.598511,2390.744141 1627.059570,2387.861328 
	C1628.293823,2388.763184 1628.853760,2389.769043 1629.601074,2391.309326 
	C1623.685303,2393.075439 1620.551025,2400.005127 1613.174316,2400.177490 
z"
        />
        <path
          fill="#5A605E"
          opacity="1.000000"
          stroke="none"
          d="
M1673.392578,2402.050293 
	C1673.061523,2403.964844 1671.461548,2405.274170 1668.905518,2406.224609 
	C1668.147217,2403.152832 1669.916626,2402.080566 1673.392578,2402.050293 
z"
        />
        <path
          fill="#5A605E"
          opacity="1.000000"
          stroke="none"
          d="
M1667.912598,2406.342041 
	C1668.378662,2406.534180 1668.474854,2406.862305 1668.436279,2407.687500 
	C1667.659302,2407.788574 1666.746216,2407.513672 1667.912598,2406.342041 
z"
        />
        <path
          fill="#1A1615"
          opacity="1.000000"
          stroke="none"
          d="
M1651.544678,2439.713379 
	C1653.188354,2439.098389 1654.363037,2439.311279 1656.141357,2439.511963 
	C1659.927368,2440.792725 1662.230957,2437.747314 1665.571533,2437.650635 
	C1668.059814,2442.862061 1672.591553,2447.069092 1672.195190,2453.773438 
	C1671.949341,2457.931396 1675.675415,2458.883057 1678.942261,2459.881592 
	C1687.356445,2462.452637 1687.022217,2462.458252 1686.300293,2471.030029 
	C1685.840820,2476.485840 1685.337891,2481.800537 1688.646729,2487.322754 
	C1693.129517,2494.804199 1690.100830,2498.659180 1678.667114,2501.666016 
	C1672.416992,2501.621338 1674.198853,2497.432861 1674.509033,2494.631348 
	C1675.067871,2489.586182 1673.501099,2485.902100 1669.900269,2482.326660 
	C1663.712158,2476.181641 1662.971436,2466.466553 1656.168945,2459.846436 
	C1657.313599,2454.495117 1651.341064,2451.690186 1652.465332,2446.422852 
	C1650.892822,2444.264648 1650.330200,2442.518311 1651.544678,2439.713379 
z"
        />
        <path
          fill="#281C18"
          opacity="1.000000"
          stroke="none"
          d="
M1654.122437,2454.833252 
	C1657.944946,2459.345947 1659.967651,2465.846436 1667.670288,2466.556641 
	C1669.304688,2466.707275 1669.536133,2468.735840 1668.695435,2469.715332 
	C1664.397217,2474.723877 1667.899658,2478.075684 1671.561890,2480.171631 
	C1678.480713,2484.131104 1679.223267,2489.500732 1676.870117,2496.186768 
	C1676.298584,2497.810791 1676.038574,2499.379883 1677.434814,2501.470703 
	C1677.942139,2502.866455 1677.938110,2503.754639 1677.907471,2505.308594 
	C1676.317139,2508.215332 1673.642212,2508.880615 1670.993408,2510.726562 
	C1669.557007,2511.330566 1668.681763,2511.479248 1667.116211,2511.416992 
	C1664.676880,2510.803711 1663.307129,2509.644287 1661.444580,2508.038574 
	C1659.686890,2506.356689 1658.565918,2504.991699 1657.020264,2503.109131 
	C1655.808838,2501.478271 1655.091431,2500.319824 1653.906494,2498.619629 
	C1652.986816,2497.205078 1652.534180,2496.341553 1651.858398,2494.739746 
	C1649.899902,2487.413330 1648.423462,2480.774658 1649.534668,2473.087891 
	C1650.774292,2469.313721 1651.292603,2466.226807 1651.200195,2462.231445 
	C1651.486328,2459.222412 1652.283203,2457.243164 1654.122437,2454.833252 
z"
        />
        <path
          fill="#1A1615"
          opacity="1.000000"
          stroke="none"
          d="
M1669.912109,2511.390625 
	C1670.120972,2506.640869 1672.883789,2505.843994 1677.100098,2505.982910 
	C1677.907593,2507.482178 1677.934570,2508.990723 1677.957031,2511.252930 
	C1676.882935,2513.083008 1675.622314,2513.588135 1673.392334,2513.178711 
	C1671.708496,2513.061523 1670.850586,2512.746826 1669.912109,2511.390625 
z"
        />
        <path
          fill="#281C18"
          opacity="1.000000"
          stroke="none"
          d="
M1671.445801,2512.689209 
	C1673.177612,2512.114990 1674.769531,2512.059326 1677.156982,2512.005371 
	C1677.952515,2512.007080 1678.038818,2512.882324 1678.070923,2513.318848 
	C1678.103027,2513.755127 1678.051514,2514.735596 1678.059326,2515.225586 
	C1676.454712,2515.258057 1674.842163,2514.800537 1672.608643,2514.190430 
	C1671.760376,2513.761230 1671.533081,2513.484375 1671.445801,2512.689209 
z"
        />
        <path
          fill="#2C2520"
          opacity="1.000000"
          stroke="none"
          d="
M1470.518066,2359.851318 
	C1470.246704,2359.384033 1470.609741,2358.864258 1471.586670,2358.265137 
	C1471.891235,2358.740234 1471.541992,2359.278076 1470.518066,2359.851318 
z"
        />
        <path
          fill="#6C5246"
          opacity="1.000000"
          stroke="none"
          d="
M1486.914673,2351.080078 
	C1486.851562,2350.973633 1486.977783,2351.186279 1486.914673,2351.080078 
z"
        />
        <path
          fill="#6C5246"
          opacity="1.000000"
          stroke="none"
          d="
M1496.000977,2374.000000 
	C1495.760620,2373.883057 1496.045288,2375.115479 1495.743652,2374.099365 
	C1495.995117,2374.000000 1496.000000,2374.000000 1496.000977,2374.000000 
z"
        />
        <path
          fill="#2C2520"
          opacity="1.000000"
          stroke="none"
          d="
M1468.535889,2362.161133 
	C1468.432739,2361.843750 1468.757935,2361.211914 1469.554932,2360.318848 
	C1469.672607,2360.654053 1469.318481,2361.250488 1468.535889,2362.161133 
z"
        />
        <path
          fill="#442F21"
          opacity="1.000000"
          stroke="none"
          d="
M1514.030029,2350.031006 
	C1513.880493,2351.337646 1513.678223,2352.774170 1512.239502,2353.077148 
	C1510.366699,2353.471191 1508.722656,2352.231201 1508.988159,2350.591309 
	C1509.380737,2348.166260 1511.491577,2350.113037 1513.361572,2349.871094 
	C1513.922607,2349.916260 1513.994019,2349.992920 1514.030029,2350.031006 
z"
        />
        <path
          fill="#959693"
          opacity="1.000000"
          stroke="none"
          d="
M1860.573730,2755.834473 
	C1859.039062,2753.718262 1857.259155,2752.177246 1855.382324,2752.348389 
	C1848.810669,2752.947021 1843.409790,2749.397949 1836.676270,2747.709473 
	C1837.581909,2742.823730 1842.728760,2742.999268 1846.035522,2740.542236 
	C1847.131104,2739.728027 1848.787720,2740.581787 1849.380981,2741.841309 
	C1852.446045,2748.347412 1856.602661,2744.510010 1858.407104,2741.804688 
	C1861.760132,2736.777344 1866.466431,2735.407715 1871.620728,2734.019287 
	C1879.414673,2731.920166 1881.343140,2729.301514 1880.447632,2720.767822 
	C1879.892822,2715.481689 1884.379517,2716.385254 1887.349854,2714.311035 
	C1890.414307,2713.847656 1892.954224,2714.471436 1895.587036,2712.456543 
	C1896.917236,2712.029785 1897.803345,2712.028320 1899.354492,2712.018555 
	C1903.710815,2712.126953 1907.311401,2712.064941 1910.073486,2708.968750 
	C1911.690552,2707.156250 1913.596191,2706.060059 1916.229614,2707.033447 
	C1918.984985,2708.052002 1920.955566,2706.638672 1921.577393,2704.149170 
	C1922.641357,2699.889160 1926.101074,2698.019287 1928.838989,2695.204346 
	C1932.975586,2690.950928 1936.698608,2689.396484 1942.746826,2690.951172 
	C1948.299194,2692.378174 1949.128540,2686.545410 1951.554565,2682.456543 
	C1954.768311,2691.427979 1956.152710,2701.056152 1955.207275,2710.765137 
	C1954.896240,2713.959473 1954.948853,2716.501221 1957.273560,2719.456543 
	C1957.022095,2723.111084 1956.238647,2726.340820 1955.335693,2730.240723 
	C1945.758789,2737.526611 1936.301147,2744.141846 1926.337891,2751.182129 
	C1924.360718,2754.028320 1922.119263,2754.684326 1918.733765,2754.253906 
	C1916.534302,2754.215576 1915.062866,2754.310303 1912.883789,2754.624268 
	C1904.714600,2759.365479 1895.844971,2759.695312 1887.937500,2762.975830 
	C1883.925903,2764.639648 1881.545898,2760.217529 1877.491943,2759.068848 
	C1875.450806,2758.858643 1874.098145,2758.760010 1872.131836,2758.381348 
	C1869.491211,2757.756104 1867.475464,2757.338623 1864.761597,2757.765625 
	C1863.714844,2757.764648 1863.295410,2757.725098 1862.276611,2757.481934 
	C1861.360229,2757.001709 1861.042969,2756.724854 1860.573730,2755.834473 
z"
        />
        <path
          fill="#DFD9CC"
          opacity="1.000000"
          stroke="none"
          d="
M1875.460938,2756.816406 
	C1877.549438,2755.835449 1879.835693,2755.335938 1880.667603,2756.560059 
	C1884.802979,2762.645752 1889.185669,2759.435791 1894.082886,2757.825684 
	C1899.384766,2756.082520 1905.078247,2755.530762 1911.388672,2754.651367 
	C1911.858032,2756.794922 1911.762329,2759.551270 1909.408081,2759.522461 
	C1903.093262,2759.445312 1900.481079,2764.022217 1896.856934,2767.937744 
	C1902.712769,2771.249023 1907.499146,2774.885010 1908.055542,2781.794434 
	C1908.237671,2784.054199 1909.731323,2784.939941 1911.440674,2786.176025 
	C1919.557861,2792.045410 1920.278198,2796.411133 1915.068115,2804.620605 
	C1914.786987,2805.063477 1915.031982,2805.840088 1915.031982,2807.620361 
	C1920.706543,2803.617920 1925.428467,2804.741943 1929.280518,2809.715332 
	C1929.866943,2810.472168 1930.965332,2811.105225 1931.087769,2811.907959 
	C1932.855103,2823.501465 1939.780518,2833.724854 1940.046875,2845.710449 
	C1940.068604,2846.679932 1940.101929,2848.124512 1940.693726,2848.534424 
	C1949.763672,2854.818115 1945.998901,2866.513672 1951.570312,2874.260498 
	C1951.949219,2874.787354 1951.235352,2876.099854 1950.520508,2877.528320 
	C1944.734497,2878.958496 1943.120239,2874.756348 1941.813110,2871.353027 
	C1938.872070,2863.696777 1936.306763,2855.989746 1930.398438,2849.882080 
	C1928.578491,2848.000977 1928.336670,2845.137207 1928.268066,2842.451660 
	C1928.074707,2834.877197 1924.701172,2837.163086 1920.507324,2840.562988 
	C1910.803467,2841.885010 1908.220459,2834.662354 1905.349976,2828.612305 
	C1902.771118,2823.177002 1899.706543,2819.023682 1892.937500,2817.510986 
	C1890.384277,2807.113281 1885.579956,2797.855957 1884.380371,2787.032715 
	C1884.584717,2785.642334 1885.805054,2785.406006 1885.591797,2784.648438 
	C1885.442505,2784.117432 1884.728394,2784.342529 1883.761719,2785.289551 
	C1879.203613,2785.590820 1876.747437,2783.311279 1876.293457,2779.753174 
	C1875.348511,2772.345703 1872.072510,2765.017578 1875.460938,2756.816406 
z"
        />
        <path
          fill="#D1C9BA"
          opacity="1.000000"
          stroke="none"
          d="
M1919.509155,2840.615967 
	C1919.334106,2834.410645 1923.470703,2833.299805 1927.456665,2832.863037 
	C1930.866699,2832.489258 1932.490356,2835.557617 1931.692871,2838.189697 
	C1929.485229,2845.475586 1932.372681,2850.195801 1937.973755,2854.345215 
	C1940.776001,2856.421387 1941.179443,2860.095947 1941.842651,2863.282715 
	C1942.953247,2868.619873 1945.098755,2873.330322 1949.396973,2877.675293 
	C1950.034668,2884.405273 1951.862061,2890.468506 1953.748535,2897.284668 
	C1943.648560,2896.395020 1940.300049,2887.806885 1936.372559,2879.904541 
	C1935.369019,2877.885498 1935.124390,2875.489258 1934.220215,2872.592773 
	C1930.364868,2868.594482 1926.353149,2865.610107 1925.369873,2859.636719 
	C1924.975830,2853.748779 1923.776978,2848.933838 1919.398804,2844.638184 
	C1918.812500,2843.073242 1918.835815,2842.133057 1919.509155,2840.615967 
z"
        />
        <path
          fill="#DFD9CC"
          opacity="1.000000"
          stroke="none"
          d="
M1917.986694,2754.985352 
	C1919.439575,2751.506348 1922.051758,2751.941650 1925.108765,2751.631836 
	C1925.921509,2753.890625 1926.560181,2756.398438 1925.164673,2758.333008 
	C1922.997803,2761.337402 1922.157959,2763.125000 1926.906982,2764.393799 
	C1930.552612,2765.367920 1929.279785,2767.830566 1926.861816,2769.135742 
	C1924.555298,2770.380615 1921.847046,2770.452637 1918.757935,2770.248535 
	C1917.265747,2765.559814 1918.363525,2760.640381 1917.986694,2754.985352 
z"
        />
        <path
          fill="#F1E9D8"
          opacity="1.000000"
          stroke="none"
          d="
M1979.804932,2793.225098 
	C1971.868896,2788.806396 1971.868896,2788.806396 1974.770386,2780.354004 
	C1976.913330,2774.111084 1976.524292,2773.568604 1968.131714,2772.192383 
	C1971.782227,2768.655762 1973.137085,2763.740479 1978.767700,2761.920654 
	C1981.045044,2771.998779 1978.278320,2782.221680 1979.804932,2793.225098 
z"
        />
        <path
          fill="#F1E9D8"
          opacity="1.000000"
          stroke="none"
          d="
M1966.940186,2814.028809 
	C1965.998657,2815.700439 1964.830566,2815.908203 1963.686157,2815.120117 
	C1962.760010,2814.482422 1962.773071,2813.466553 1963.722412,2812.856689 
	C1964.884888,2812.110352 1966.051025,2812.317383 1966.940186,2814.028809 
z"
        />
        <path
          fill="#F1E9D8"
          opacity="1.000000"
          stroke="none"
          d="
M1966.899780,2703.878174 
	C1966.057129,2702.167725 1966.618774,2700.664795 1968.894165,2699.419434 
	C1969.812134,2701.066406 1969.719727,2702.825439 1966.899780,2703.878174 
z"
        />
        <path
          fill="#F1E9D8"
          opacity="1.000000"
          stroke="none"
          d="
M1960.882812,2717.964600 
	C1960.558838,2716.936279 1960.774658,2715.811035 1961.459595,2714.330566 
	C1963.932739,2715.555176 1963.745850,2716.850342 1960.882812,2717.964600 
z"
        />
        <path
          fill="#263B4D"
          opacity="1.000000"
          stroke="none"
          d="
M1342.243408,2673.796875 
	C1341.861450,2674.325928 1340.895752,2674.304443 1340.457886,2674.101074 
	C1340.503662,2672.319336 1340.987305,2670.741211 1341.729736,2668.591309 
	C1342.884155,2668.016113 1343.779785,2668.012939 1345.347534,2668.012939 
	C1346.038696,2670.510498 1344.276367,2671.853027 1342.243408,2673.796875 
z"
        />
        <path
          fill="#B17752"
          opacity="1.000000"
          stroke="none"
          d="
M1671.183594,2512.060059 
	C1671.585693,2512.170654 1671.701782,2513.122314 1671.844727,2513.580078 
	C1672.105225,2518.659912 1672.513428,2523.184326 1673.390259,2527.795898 
	C1674.536987,2533.828613 1674.046631,2539.783447 1671.739868,2546.129150 
	C1668.591919,2554.788818 1666.013184,2563.995605 1665.610962,2573.411133 
	C1665.485107,2576.360840 1664.344849,2577.436523 1661.917847,2578.335693 
	C1657.760376,2579.876465 1653.689575,2581.012207 1648.821533,2578.204102 
	C1647.463257,2577.604736 1647.035156,2577.141113 1646.452393,2576.212891 
	C1643.646484,2571.621582 1638.666504,2571.114258 1635.034180,2568.621094 
	C1632.626709,2566.968506 1629.898438,2566.127686 1627.986938,2563.413818 
	C1622.140137,2555.111572 1621.683228,2554.567383 1612.686646,2558.191162 
	C1607.033691,2560.468262 1603.787109,2560.075195 1600.777588,2554.917480 
	C1599.839233,2553.309326 1597.950317,2552.256104 1596.604004,2551.045410 
	C1593.060303,2552.175049 1594.468140,2555.050537 1593.823364,2557.052490 
	C1593.369019,2558.463135 1591.876709,2560.018066 1591.186646,2558.977539 
	C1587.771851,2553.829590 1584.670532,2558.276611 1581.380859,2558.789062 
	C1576.888184,2559.488770 1574.232910,2556.682129 1571.984131,2548.763184 
	C1574.500122,2544.312988 1575.660400,2539.715088 1580.175659,2537.338623 
	C1584.902710,2534.850586 1589.807739,2533.104736 1593.623901,2537.670410 
	C1600.490601,2545.885498 1608.836182,2546.914062 1618.411621,2544.596436 
	C1620.000610,2544.211914 1621.677979,2544.513672 1623.721680,2545.501709 
	C1624.476440,2546.079102 1624.737671,2546.330811 1625.410522,2546.837891 
	C1629.546265,2540.429688 1637.715820,2542.590576 1642.809204,2538.558594 
	C1644.342285,2537.344971 1646.300903,2536.487061 1648.221924,2536.065674 
	C1659.573486,2533.576416 1667.717285,2528.936523 1663.508301,2515.068359 
	C1663.057129,2513.581299 1664.067261,2511.840576 1665.623779,2510.039062 
	C1666.969482,2509.891602 1667.738770,2510.148438 1669.099365,2510.559082 
	C1669.924316,2511.239502 1670.288086,2511.651855 1671.183594,2512.060059 
z"
        />
        <path
          fill="#5E4237"
          opacity="1.000000"
          stroke="none"
          d="
M1669.414307,2627.476562 
	C1670.530518,2630.176758 1671.390747,2632.371338 1671.722656,2634.643311 
	C1672.040527,2636.818115 1672.942017,2639.824463 1670.184937,2640.756104 
	C1667.337524,2641.718750 1665.419922,2639.672119 1664.021118,2637.061768 
	C1661.528931,2632.410645 1658.609131,2627.987061 1656.153931,2623.318115 
	C1654.951294,2621.030762 1654.060425,2621.628662 1651.785034,2621.869385 
	C1646.758667,2622.400879 1640.379395,2627.195801 1636.776001,2619.154785 
	C1632.268188,2620.531250 1629.686035,2616.911133 1626.290894,2615.253662 
	C1620.556152,2612.453125 1618.010742,2608.033691 1617.894653,2601.764893 
	C1617.837769,2598.700684 1618.647949,2594.400879 1612.314697,2595.794434 
	C1608.410767,2592.649170 1607.894043,2588.492188 1608.066528,2584.097656 
	C1608.252197,2579.361572 1610.547241,2574.510010 1606.666748,2570.002686 
	C1605.562866,2568.720459 1607.984131,2565.932129 1608.460938,2566.544434 
	C1610.642578,2569.345459 1615.440430,2563.890869 1616.360352,2569.353027 
	C1616.680176,2571.252441 1618.352295,2571.882324 1620.118286,2571.808838 
	C1625.585205,2571.582520 1628.384888,2575.140625 1631.416992,2579.629883 
	C1638.372559,2580.040039 1635.189575,2588.021484 1640.341431,2589.216797 
	C1641.590698,2588.038818 1642.288208,2587.069092 1643.628906,2585.825439 
	C1652.927368,2586.022461 1652.016357,2595.286865 1657.386963,2599.699219 
	C1658.010742,2599.941406 1658.987671,2599.967285 1659.475220,2599.998535 
	C1660.825195,2600.865967 1661.744995,2601.656982 1662.226807,2603.431152 
	C1662.270142,2606.119629 1662.271362,2608.206543 1662.327515,2611.074219 
	C1666.509888,2616.064209 1662.463013,2623.805176 1669.414307,2627.476562 
z"
        />
        <path
          fill="#5E4237"
          opacity="1.000000"
          stroke="none"
          d="
M1695.953857,2637.151123 
	C1694.535889,2640.141357 1693.204224,2642.346191 1691.698853,2644.425293 
	C1689.651367,2647.253174 1689.289185,2649.957031 1692.686157,2651.774902 
	C1697.020386,2654.093994 1696.790405,2657.829346 1694.978760,2660.949707 
	C1692.860718,2664.597656 1688.746826,2666.057129 1684.916382,2663.694092 
	C1681.278809,2661.450439 1679.532837,2663.835449 1676.549316,2665.693115 
	C1676.011841,2663.071045 1675.268433,2661.165039 1672.430054,2659.584229 
	C1668.539551,2657.417725 1671.576416,2654.057373 1672.761963,2651.995605 
	C1674.352905,2649.228760 1676.798828,2651.486816 1678.581177,2652.704834 
	C1680.338745,2653.905762 1680.361938,2658.666016 1684.088379,2655.598877 
	C1687.330933,2652.930420 1689.360352,2649.204590 1686.818115,2645.275391 
	C1685.095337,2642.612305 1683.396729,2640.529053 1685.624512,2636.786133 
	C1687.659912,2634.060059 1687.922241,2631.975830 1685.728149,2630.051270 
	C1679.369141,2624.474121 1680.270630,2617.890137 1683.466064,2610.494141 
	C1683.932983,2609.076172 1683.951782,2608.194092 1683.982300,2606.650391 
	C1686.137695,2606.240723 1687.444336,2604.797852 1689.444946,2603.805664 
	C1696.250000,2611.091064 1698.454590,2619.323242 1696.681030,2628.486816 
	C1696.172485,2631.114014 1696.036133,2633.687744 1695.953857,2637.151123 
z"
        />
        <path
          fill="#805541"
          opacity="1.000000"
          stroke="none"
          d="
M1683.264404,2609.969727 
	C1683.953125,2613.866943 1684.044189,2617.776123 1684.014648,2621.684082 
	C1683.990479,2624.876465 1684.844116,2626.797607 1688.319214,2628.236816 
	C1693.114624,2630.222900 1692.829834,2631.152588 1686.647705,2636.002197 
	C1684.701782,2633.393799 1683.203003,2630.839844 1680.391724,2628.453613 
	C1678.950928,2625.881104 1676.909668,2625.466797 1674.395996,2624.588379 
	C1669.715942,2620.437988 1670.523193,2624.619385 1669.943848,2627.292969 
	C1662.013428,2626.103516 1662.002319,2626.103516 1661.977051,2618.365723 
	C1661.971802,2616.758789 1662.082153,2615.151123 1662.260742,2612.699463 
	C1666.768799,2612.852539 1671.651611,2616.541260 1674.122314,2608.899170 
	C1674.905884,2606.475342 1675.766479,2608.957520 1676.353027,2609.652100 
	C1678.345703,2612.011963 1680.560791,2609.333008 1683.264404,2609.969727 
z"
        />
        <path
          fill="#805541"
          opacity="1.000000"
          stroke="none"
          d="
M1657.996704,2599.172363 
	C1652.101318,2603.441650 1650.353271,2597.984863 1650.494873,2594.954590 
	C1650.721680,2590.096191 1646.995728,2589.206299 1644.467041,2586.313721 
	C1642.374390,2587.361328 1643.144409,2589.154785 1643.503418,2591.348633 
	C1636.533081,2594.712402 1635.200806,2593.839111 1635.253906,2586.097412 
	C1635.268921,2583.894287 1633.533813,2582.751709 1632.280273,2580.588867 
	C1633.555298,2577.959961 1635.384888,2578.823975 1637.316772,2579.444336 
	C1640.403687,2580.435791 1643.223877,2580.046875 1645.652832,2576.622070 
	C1647.524780,2574.583252 1647.741211,2575.861572 1648.082275,2577.429443 
	C1647.976440,2581.489502 1647.646973,2584.690918 1652.308350,2586.584473 
	C1657.147461,2588.550049 1656.912964,2594.031982 1657.996704,2599.172363 
z"
        />
        <path
          fill="#805541"
          opacity="1.000000"
          stroke="none"
          d="
M1690.049072,2603.373779 
	C1689.441528,2604.723145 1688.535278,2605.334961 1688.329346,2606.128662 
	C1687.479736,2609.401367 1686.253296,2608.253662 1684.437012,2606.274658 
	C1681.500366,2599.641602 1681.500366,2599.641602 1687.880371,2597.971680 
	C1690.083618,2599.270020 1690.061279,2600.999756 1690.049072,2603.373779 
z"
        />
        <path
          fill="#805541"
          opacity="1.000000"
          stroke="none"
          d="
M1662.856934,2603.912598 
	C1661.114990,2603.440186 1660.366333,2602.531250 1660.028809,2600.639404 
	C1661.743896,2600.290283 1664.048096,2599.275391 1664.947998,2601.740234 
	C1665.311035,2602.735107 1664.389771,2603.421875 1662.856934,2603.912598 
z"
        />
        <path
          fill="#B17752"
          opacity="1.000000"
          stroke="none"
          d="
M1659.528687,2583.155762 
	C1659.638916,2582.791016 1660.032227,2582.492676 1660.144531,2582.575439 
	C1660.825684,2583.078369 1660.642944,2583.391113 1659.528687,2583.155762 
z"
        />
        <path
          fill="#805541"
          opacity="1.000000"
          stroke="none"
          d="
M1688.093140,2592.512451 
	C1688.960083,2592.272949 1689.437134,2592.859619 1688.630371,2594.157959 
	C1688.246826,2594.021729 1688.177002,2593.592773 1688.093140,2592.512451 
z"
        />
        <path
          fill="#805541"
          opacity="1.000000"
          stroke="none"
          d="
M1666.948486,2594.929199 
	C1667.034058,2594.881836 1666.862793,2594.976807 1666.948486,2594.929199 
z"
        />
        <path
          fill="#442F21"
          opacity="1.000000"
          stroke="none"
          d="
M1731.548828,2589.544922 
	C1728.309814,2592.193115 1725.009277,2594.457520 1725.809570,2599.653076 
	C1726.423218,2603.637207 1724.637085,2607.263428 1722.874756,2611.479980 
	C1721.457275,2612.024658 1720.504639,2612.008789 1718.843018,2611.996338 
	C1718.003662,2609.016846 1717.822266,2606.025635 1719.805420,2602.652344 
	C1720.019409,2600.381104 1720.006104,2598.782227 1719.964844,2596.583984 
	C1721.742310,2589.480957 1722.455566,2589.035645 1731.548828,2589.544922 
z"
        />
        <path
          fill="#281C18"
          opacity="1.000000"
          stroke="none"
          d="
M1746.035156,2563.294678 
	C1743.075073,2563.923340 1740.273071,2566.779053 1736.567139,2564.206543 
	C1737.724609,2562.525635 1739.574951,2561.214355 1741.228760,2559.689697 
	C1746.411743,2554.912354 1745.898071,2559.254883 1746.035156,2563.294678 
z"
        />
        <path
          fill="#442F21"
          opacity="1.000000"
          stroke="none"
          d="
M1707.910156,2614.726807 
	C1708.880005,2614.029785 1709.764771,2614.026855 1711.312622,2614.036621 
	C1711.329956,2616.255615 1710.755249,2618.487305 1710.002808,2620.656982 
	C1709.712524,2621.494141 1709.018799,2622.191650 1708.177612,2623.447021 
	C1704.852905,2620.570557 1707.558716,2617.915771 1707.910156,2614.726807 
z"
        />
        <path
          fill="#281C18"
          opacity="1.000000"
          stroke="none"
          d="
M1723.942871,2565.284912 
	C1723.228516,2567.176758 1722.228516,2567.948242 1720.339355,2566.497803 
	C1720.195801,2564.814209 1720.248291,2563.350830 1721.820435,2563.042480 
	C1722.927124,2562.825439 1723.505249,2563.739746 1723.942871,2565.284912 
z"
        />
        <path
          fill="#281C18"
          opacity="1.000000"
          stroke="none"
          d="
M1731.395508,2564.032227 
	C1731.691406,2565.745361 1732.729492,2568.117676 1730.115723,2568.866455 
	C1729.108154,2569.154785 1728.512939,2568.109375 1728.103027,2566.540771 
	C1728.938232,2565.316406 1729.876831,2564.705078 1731.395508,2564.032227 
z"
        />
        <path
          fill="#935F47"
          opacity="1.000000"
          stroke="none"
          d="
M1705.730347,2561.754883 
	C1705.959595,2562.746582 1705.836670,2563.484131 1705.646606,2564.773926 
	C1704.106201,2564.125244 1702.740601,2562.917969 1705.730347,2561.754883 
z"
        />
        <path
          fill="#281C18"
          opacity="1.000000"
          stroke="none"
          d="
M1733.800171,2561.699219 
	C1733.978271,2562.012451 1732.982788,2561.990479 1732.485107,2561.978027 
	C1732.369263,2561.312988 1732.769287,2560.710938 1733.800171,2561.699219 
z"
        />
        <path
          fill="#3A261C"
          opacity="1.000000"
          stroke="none"
          d="
M1719.480469,2596.379395 
	C1721.918945,2597.387451 1722.327026,2599.111816 1720.566284,2601.583496 
	C1718.019409,2600.608398 1717.656372,2598.873047 1719.480469,2596.379395 
z"
        />
        <path
          fill="#281C18"
          opacity="1.000000"
          stroke="none"
          d="
M1716.928467,2571.042480 
	C1716.882080,2570.956055 1716.974976,2571.129150 1716.928467,2571.042480 
z"
        />
        <path
          fill="#281C18"
          opacity="1.000000"
          stroke="none"
          d="
M1709.993774,2566.035645 
	C1709.765747,2565.948486 1709.936523,2567.280518 1709.688843,2566.113037 
	C1709.926758,2566.007812 1709.994141,2566.001953 1709.993774,2566.035645 
z"
        />
        <path
          fill="#BA815C"
          opacity="1.000000"
          stroke="none"
          d="
M1752.909912,2592.629883 
	C1759.355103,2592.180664 1764.632935,2589.522461 1770.381592,2589.968262 
	C1773.065674,2590.176514 1776.087891,2590.042969 1777.038696,2593.119385 
	C1777.990356,2596.198242 1775.437134,2598.610107 1773.300293,2599.473633 
	C1766.963135,2602.033691 1760.338989,2603.904053 1753.768188,2605.845703 
	C1752.564331,2606.201416 1750.988159,2605.500000 1750.502808,2604.108398 
	C1749.199951,2600.371826 1747.485229,2601.615723 1745.579590,2603.519775 
	C1744.627075,2604.471436 1743.528442,2605.060547 1742.103271,2605.187012 
	C1740.229614,2605.353271 1737.621948,2607.710205 1737.699463,2603.277100 
	C1737.741577,2600.878418 1735.365967,2600.368164 1733.527466,2599.018311 
	C1739.061523,2594.144775 1745.975220,2594.669434 1752.909912,2592.629883 
z"
        />
        <path
          fill="#442F21"
          opacity="1.000000"
          stroke="none"
          d="
M1733.170654,2616.709961 
	C1732.756348,2615.237549 1733.279053,2614.713867 1734.415894,2614.940430 
	C1734.653687,2614.987793 1734.974365,2615.322021 1735.009888,2615.561035 
	C1735.181274,2616.716309 1734.615601,2617.193359 1733.170654,2616.709961 
z"
        />
        <path
          fill="#442F21"
          opacity="1.000000"
          stroke="none"
          d="
M1739.022949,2612.983398 
	C1739.066406,2613.072266 1738.979614,2612.894775 1739.022949,2612.983398 
z"
        />
        <path
          fill="#3A261C"
          opacity="1.000000"
          stroke="none"
          d="
M1755.952637,2538.039062 
	C1755.680420,2537.772705 1755.442383,2537.476807 1755.204224,2537.181152 
	C1755.400024,2537.254639 1755.595825,2537.328125 1755.918945,2537.671631 
	C1756.046509,2537.941406 1755.986938,2538.009277 1755.952637,2538.039062 
z"
        />
        <path
          fill="#E7E3DA"
          opacity="1.000000"
          stroke="none"
          d="
M1335.222290,2682.001465 
	C1335.927246,2678.225098 1339.552612,2678.327637 1341.868042,2676.115479 
	C1346.646484,2676.218262 1350.639893,2674.627197 1354.482300,2673.012939 
	C1361.722778,2669.970703 1368.983398,2669.667969 1377.232422,2671.966797 
	C1377.593750,2675.506348 1376.101562,2677.500488 1372.312988,2677.716309 
	C1365.183472,2678.122314 1364.566772,2678.679199 1364.118530,2685.062988 
	C1363.276978,2697.047119 1366.902100,2708.010010 1372.322388,2718.477539 
	C1373.676270,2721.092285 1376.159058,2723.163574 1375.981812,2727.263672 
	C1369.559937,2724.349854 1366.794678,2718.620605 1365.268188,2711.513184 
	C1364.520874,2708.033203 1365.599609,2702.132812 1358.991699,2701.800781 
	C1357.406128,2701.721191 1357.992188,2700.311523 1358.772461,2699.803467 
	C1362.342529,2697.479980 1359.938477,2694.406982 1359.886719,2691.663086 
	C1359.842773,2689.334717 1359.789185,2686.635010 1356.720825,2686.294922 
	C1353.232300,2685.908203 1349.972168,2686.937744 1348.487061,2690.429199 
	C1347.603149,2692.507080 1347.327637,2694.918457 1347.167725,2697.207031 
	C1347.032471,2699.144043 1347.823730,2701.455322 1344.820435,2701.582520 
	C1342.580811,2701.677246 1340.963501,2700.180908 1340.454346,2698.170166 
	C1339.540283,2694.559814 1336.943970,2693.283203 1334.017334,2691.409424 
	C1329.412842,2688.461182 1325.093384,2687.954590 1319.368896,2690.343262 
	C1324.230225,2684.051514 1325.897583,2683.199951 1335.222290,2682.001465 
z"
        />
        <path
          fill="#E9E1D0"
          opacity="1.000000"
          stroke="none"
          d="
M1539.286255,2909.800781 
	C1541.526611,2910.485107 1543.199707,2910.755615 1544.642578,2911.482910 
	C1548.027100,2913.188477 1548.257812,2916.125244 1544.819580,2917.100830 
	C1539.194946,2918.696777 1538.348145,2922.704102 1537.126587,2927.329102 
	C1534.948853,2935.574219 1532.442505,2938.125488 1523.918335,2937.742920 
	C1516.952881,2937.430664 1513.278076,2942.504639 1507.977173,2944.769043 
	C1505.052612,2946.018555 1505.600586,2949.534668 1506.424194,2951.905273 
	C1508.496460,2957.869873 1505.923950,2959.754639 1500.445312,2960.087402 
	C1495.179688,2960.406982 1491.569214,2962.619141 1491.979004,2968.722168 
	C1492.256104,2972.847412 1489.978882,2975.566650 1486.687134,2977.617920 
	C1483.881226,2979.366699 1481.077637,2981.120605 1478.240967,2982.818848 
	C1475.689697,2984.346191 1470.950562,2984.064209 1472.802490,2989.208740 
	C1474.217285,2993.138184 1475.991333,2996.756348 1481.381348,2994.834961 
	C1481.755127,2994.701660 1482.423950,2995.396484 1483.736816,2996.161377 
	C1470.009399,3002.136719 1467.069092,3014.917236 1462.539795,3027.512695 
	C1458.314087,3031.158691 1454.225952,3032.665771 1448.684570,3030.505127 
	C1440.460205,3030.643555 1433.326416,3026.900146 1424.879028,3028.023438 
	C1420.837036,3026.770020 1420.057495,3024.433350 1421.486816,3020.687500 
	C1428.322998,3020.086670 1434.674438,3020.374756 1440.558228,3017.424561 
	C1442.106079,3016.648193 1443.349609,3015.567383 1443.103271,3013.600586 
	C1442.921753,3012.152832 1441.781738,3010.638184 1440.696899,3010.808350 
	C1431.516968,3012.247070 1429.820679,3002.399170 1421.890015,3000.173584 
	C1426.765137,2996.968994 1431.094360,2998.708740 1434.646484,2996.950928 
	C1435.952881,2996.304443 1437.519775,2997.418945 1437.142700,2998.934814 
	C1436.014160,3003.470215 1438.596558,3003.771973 1442.008911,3004.041260 
	C1450.547852,3004.715088 1458.130859,3003.986328 1460.405518,2993.693359 
	C1460.988281,2991.056152 1464.835571,2990.619873 1464.658203,2986.975586 
	C1460.409302,2985.465576 1456.524902,2985.525391 1452.604370,2988.221436 
	C1450.457275,2989.697754 1447.976685,2990.855957 1445.481079,2991.611572 
	C1443.025146,2992.354736 1440.923340,2991.513184 1440.146606,2988.631836 
	C1439.197510,2985.110107 1438.197021,2981.600098 1437.104736,2978.120850 
	C1436.417969,2975.932373 1436.779541,2974.098145 1438.800049,2972.997559 
	C1440.971558,2971.814941 1443.850342,2971.325439 1445.050171,2973.687256 
	C1447.121094,2977.763428 1450.301758,2978.069824 1454.129028,2978.035645 
	C1463.385620,2977.953857 1464.513428,2976.550293 1461.182129,2967.873535 
	C1459.621826,2963.810059 1461.088867,2962.227295 1464.977539,2962.094971 
	C1468.631348,2961.970703 1472.290405,2962.010010 1475.947144,2961.968750 
	C1478.753906,2961.936768 1481.413330,2961.589111 1481.780273,2958.031494 
	C1482.127075,2954.668945 1482.222168,2951.537354 1478.613892,2949.353271 
	C1475.701904,2947.591064 1471.928589,2949.091064 1468.906372,2946.046387 
	C1472.291504,2942.626953 1476.734131,2943.352051 1479.830200,2944.550781 
	C1486.246826,2947.034424 1488.426514,2944.143555 1491.347290,2939.133301 
	C1493.870728,2934.804443 1494.073120,2928.617188 1499.939697,2926.408691 
	C1500.524902,2926.188477 1501.195679,2924.281738 1500.892700,2923.900635 
	C1495.865723,2917.579102 1503.105103,2911.235596 1500.800903,2904.927979 
	C1500.522339,2904.165283 1500.842041,2902.642578 1501.434326,2902.186768 
	C1507.824951,2897.270996 1507.054810,2889.858398 1507.856445,2883.189941 
	C1508.347168,2879.107178 1510.411499,2876.486816 1513.053955,2873.940918 
	C1515.208008,2871.865967 1516.282349,2869.503418 1514.359131,2866.896729 
	C1511.084717,2862.458984 1513.129272,2858.031982 1514.015137,2852.808105 
	C1517.450928,2856.746826 1524.120728,2857.348633 1527.167114,2862.634766 
	C1528.131104,2864.306885 1530.174927,2863.715088 1531.398560,2862.479736 
	C1532.852905,2861.011719 1534.370972,2859.978027 1537.229736,2860.023438 
	C1539.599609,2862.306152 1538.732788,2865.200928 1539.979614,2868.280273 
	C1540.412720,2870.296631 1540.509521,2871.668945 1540.457153,2873.749512 
	C1540.107300,2876.103516 1539.738770,2878.456787 1538.399536,2878.815186 
	C1522.139893,2883.164551 1529.161621,2899.184570 1523.341553,2908.734619 
	C1521.992676,2910.947998 1522.348267,2914.142822 1525.119019,2915.756836 
	C1527.743652,2917.285400 1530.234741,2916.242188 1532.415649,2914.414062 
	C1534.423096,2912.731689 1535.880249,2910.341797 1539.286255,2909.800781 
z"
        />
        <path
          fill="#E5DDC9"
          opacity="1.000000"
          stroke="none"
          d="
M1662.505371,2812.355469 
	C1661.086304,2812.022461 1660.177490,2812.044678 1658.588379,2812.111572 
	C1657.252808,2812.719971 1656.591553,2813.276611 1655.364380,2814.141602 
	C1652.658569,2815.024658 1651.068237,2816.254639 1649.827637,2818.870117 
	C1645.140747,2823.861572 1638.419312,2824.673828 1633.765137,2829.812500 
	C1632.877686,2830.428711 1632.500366,2830.637207 1631.505127,2831.011230 
	C1629.253418,2831.380615 1627.596313,2831.102783 1625.379150,2831.538086 
	C1623.609131,2831.921143 1622.419189,2832.081299 1620.612793,2831.877930 
	C1612.737305,2830.857422 1606.312012,2834.169189 1598.788086,2835.940186 
	C1597.818481,2834.639648 1596.765625,2832.501953 1596.206421,2832.966064 
	C1591.378906,2836.969727 1586.515991,2832.878906 1581.733276,2833.069092 
	C1578.201538,2833.209229 1574.924683,2832.625488 1571.349854,2834.128418 
	C1566.333374,2836.237061 1561.297607,2832.150146 1561.639160,2826.371582 
	C1561.745605,2824.569580 1561.196411,2824.613770 1560.088989,2823.874268 
	C1555.395264,2820.740479 1555.376099,2820.448486 1560.640137,2817.956543 
	C1562.745728,2816.959961 1563.783447,2815.797363 1563.891357,2812.861084 
	C1577.480591,2806.466553 1591.020386,2809.264160 1604.257446,2813.027588 
	C1609.003052,2814.376709 1612.849609,2814.754150 1617.507202,2813.085449 
	C1632.003662,2807.892090 1647.175415,2804.530518 1661.348877,2796.395752 
	C1661.952148,2795.929688 1662.883301,2795.806885 1663.350952,2795.763916 
	C1667.406372,2795.050049 1669.939209,2792.443604 1672.992554,2790.798340 
	C1676.380493,2788.972656 1679.797607,2789.353027 1683.093018,2792.834473 
	C1683.286255,2805.463867 1673.951294,2807.968506 1664.596924,2811.552490 
	C1663.954102,2811.986328 1664.006348,2812.009766 1664.003662,2811.980957 
	C1663.672485,2812.204590 1663.344116,2812.457520 1662.505371,2812.355469 
z"
        />
        <path
          fill="#F6EEDE"
          opacity="1.000000"
          stroke="none"
          d="
M1539.977661,2909.351074 
	C1538.136475,2912.340820 1536.209717,2914.600586 1534.515991,2917.023438 
	C1531.346680,2921.557617 1526.578003,2920.690430 1522.837036,2919.364258 
	C1518.539185,2917.841064 1519.243896,2912.928955 1519.331421,2909.154541 
	C1519.362793,2907.805908 1522.095459,2906.400146 1521.090576,2904.926270 
	C1517.946777,2900.316162 1527.462646,2900.357178 1524.150269,2896.316895 
	C1521.160400,2892.669922 1524.565308,2889.396240 1524.100098,2885.915283 
	C1523.546021,2881.769043 1525.813721,2879.876221 1529.969238,2879.998535 
	C1533.443604,2880.101318 1536.661743,2879.555908 1537.906982,2874.730469 
	C1539.887085,2872.007324 1542.143921,2873.058350 1544.298462,2873.069580 
	C1549.039062,2873.094238 1549.781738,2870.794434 1547.326660,2866.372803 
	C1546.822754,2864.864258 1546.863525,2863.970459 1547.546387,2862.549805 
	C1553.993042,2861.070557 1559.293701,2863.127441 1565.188354,2865.949219 
	C1567.326782,2867.299561 1567.515869,2868.889160 1566.503174,2871.327148 
	C1564.851807,2873.002197 1563.689209,2873.986816 1562.265747,2875.490479 
	C1558.773560,2878.151123 1558.222046,2881.806641 1556.446533,2885.510742 
	C1555.985718,2885.990234 1556.000000,2886.000000 1555.992676,2885.995117 
	C1552.933228,2888.430664 1552.484131,2892.274414 1551.363281,2895.558838 
	C1549.572266,2900.808105 1545.914307,2903.414307 1540.593994,2905.571289 
	C1540.061401,2906.945312 1540.029419,2907.820068 1539.977661,2909.351074 
z"
        />
        <path
          fill="#D3CBBF"
          opacity="1.000000"
          stroke="none"
          d="
M1277.832153,2711.426270 
	C1275.176147,2712.133545 1272.802002,2711.635986 1270.145142,2709.602783 
	C1269.865601,2708.568359 1270.051880,2707.733154 1270.543457,2707.471924 
	C1280.534912,2702.161865 1290.867310,2698.572266 1303.111084,2701.873047 
	C1306.143799,2703.874756 1308.360474,2705.686523 1311.163086,2707.748291 
	C1313.204102,2709.052002 1314.902588,2709.136230 1317.317139,2709.091309 
	C1320.459229,2709.167480 1322.851929,2709.463379 1325.433838,2711.525879 
	C1335.630859,2711.302002 1343.359009,2717.905518 1352.602051,2718.989990 
	C1355.450684,2719.324219 1356.558960,2722.694336 1355.489380,2725.430908 
	C1354.056030,2729.097168 1351.172241,2727.583984 1348.913940,2726.299561 
	C1339.312988,2720.838867 1329.159424,2718.368164 1318.024658,2717.523926 
	C1308.678223,2716.815430 1299.341431,2713.916992 1291.022461,2708.606201 
	C1284.437744,2704.402344 1282.930054,2704.969482 1277.832153,2711.426270 
z"
        />
        <path
          fill="#E9E1D0"
          opacity="1.000000"
          stroke="none"
          d="
M1566.651855,2872.296875 
	C1566.006226,2870.506104 1565.998047,2868.994385 1565.974609,2866.726562 
	C1565.957520,2863.008057 1565.840698,2860.300537 1563.885742,2857.572998 
	C1561.599121,2854.383057 1562.024292,2854.078369 1563.331665,2846.916504 
	C1568.078979,2849.623047 1566.635132,2855.279541 1569.876099,2859.125732 
	C1574.113403,2855.204346 1570.239746,2849.414307 1573.238647,2845.294678 
	C1577.883301,2845.901367 1574.431152,2851.407471 1578.065674,2852.876953 
	C1581.406616,2851.690674 1578.670288,2847.258789 1581.970093,2845.306641 
	C1582.403687,2845.953369 1583.199463,2846.682617 1583.429077,2847.560303 
	C1584.534302,2851.786377 1583.963623,2856.103027 1583.786987,2861.266113 
	C1578.335449,2861.291260 1577.218384,2865.556885 1575.548584,2868.986816 
	C1573.772461,2872.634766 1571.713257,2875.213379 1566.651855,2872.296875 
z"
        />
        <path
          fill="#F2EBDA"
          opacity="1.000000"
          stroke="none"
          d="
M1599.957764,2935.299805 
	C1597.112915,2936.027344 1594.796143,2936.302002 1594.028564,2939.875488 
	C1592.191528,2948.427979 1587.641968,2955.500977 1580.580078,2961.667480 
	C1579.954956,2958.955566 1580.065796,2955.994873 1581.272705,2953.146729 
	C1581.952393,2951.542480 1581.976196,2949.583984 1580.264038,2948.732910 
	C1573.892456,2945.565186 1577.342773,2943.384277 1581.010986,2941.596680 
	C1585.687012,2939.317871 1589.553833,2936.580811 1590.787598,2931.093018 
	C1591.164673,2929.415283 1592.881104,2928.615723 1595.265869,2928.079102 
	C1597.661743,2928.367920 1598.778198,2929.494385 1599.877930,2931.455078 
	C1600.050903,2932.905762 1600.041748,2933.777832 1599.957764,2935.299805 
z"
        />
        <path
          fill="#E5DDC9"
          opacity="1.000000"
          stroke="none"
          d="
M1600.104858,2932.097168 
	C1598.229004,2931.864258 1596.683228,2931.297607 1596.082764,2928.619629 
	C1596.210571,2925.626953 1595.645020,2923.161377 1597.451172,2920.454102 
	C1599.429688,2918.510254 1599.757812,2916.359619 1601.514526,2914.392090 
	C1606.889404,2906.110840 1609.958862,2897.476562 1611.907104,2888.508789 
	C1612.725464,2884.742432 1614.008301,2882.321533 1618.785645,2881.959473 
	C1619.965454,2881.658203 1620.383423,2881.380859 1621.405518,2881.551758 
	C1621.761108,2884.342773 1622.273438,2886.797119 1620.488159,2889.488770 
	C1617.102173,2892.346924 1616.549927,2896.000977 1614.531982,2899.581055 
	C1614.076416,2900.950195 1614.040039,2901.812500 1613.977539,2903.321289 
	C1612.600708,2905.506592 1612.229980,2907.615967 1610.534424,2909.645996 
	C1610.108154,2910.966064 1610.063721,2911.816650 1609.974365,2913.303955 
	C1605.863159,2919.060547 1605.702515,2926.178955 1600.737061,2931.603027 
	C1600.304688,2932.145752 1600.149780,2932.160889 1600.104858,2932.097168 
z"
        />
        <path
          fill="#E7E3DA"
          opacity="1.000000"
          stroke="none"
          d="
M1325.991943,2711.231445 
	C1323.187622,2711.998047 1320.381592,2711.986328 1316.735352,2711.919922 
	C1314.622681,2710.631348 1313.306885,2709.490234 1310.696777,2709.837646 
	C1307.259399,2708.523438 1305.037354,2706.576660 1303.944580,2702.838623 
	C1307.849976,2702.064209 1311.639893,2702.056885 1313.052612,2696.786865 
	C1313.755981,2694.163574 1316.483398,2694.016602 1318.722168,2695.931396 
	C1323.184570,2699.747803 1326.300659,2704.244629 1325.991943,2711.231445 
z"
        />
        <path
          fill="#D0C8B8"
          opacity="1.000000"
          stroke="none"
          d="
M1622.706055,2881.927734 
	C1621.600830,2881.996338 1621.191895,2881.992920 1620.165283,2881.962646 
	C1620.799805,2879.981201 1621.932861,2877.984619 1621.820068,2874.855957 
	C1619.499512,2870.852539 1621.500122,2868.012207 1623.284058,2864.427246 
	C1623.906006,2859.824219 1624.070435,2855.891602 1624.249634,2851.115234 
	C1625.478271,2848.736084 1627.095703,2848.461426 1629.677002,2849.161621 
	C1632.899170,2852.299316 1633.103149,2855.650879 1632.935059,2859.910156 
	C1633.076538,2863.413818 1632.093750,2865.977783 1632.065918,2869.432617 
	C1632.152710,2876.312012 1628.506836,2879.627441 1622.706055,2881.927734 
z"
        />
        <path
          fill="#F6EEDE"
          opacity="1.000000"
          stroke="none"
          d="
M1443.564697,2882.630371 
	C1446.573975,2876.333008 1452.700684,2873.401123 1455.077271,2867.464844 
	C1455.822144,2865.604492 1458.512573,2866.088623 1461.204102,2865.975586 
	C1463.577271,2872.155273 1458.760620,2874.575684 1455.247559,2876.981934 
	C1454.557861,2880.552002 1459.656128,2879.428467 1458.508179,2883.103516 
	C1454.568359,2885.184326 1449.484253,2882.766113 1444.730957,2885.701416 
	C1443.250000,2885.247559 1442.951782,2884.324707 1443.564697,2882.630371 
z"
        />
        <path
          fill="#F2EBDD"
          opacity="1.000000"
          stroke="none"
          d="
M1630.394775,2850.472656 
	C1628.372803,2850.059082 1626.994995,2850.061768 1624.940918,2850.168213 
	C1619.686035,2845.488770 1619.632080,2839.556396 1620.014282,2832.648438 
	C1620.943604,2831.201660 1621.867676,2830.621094 1623.358887,2829.985596 
	C1630.346436,2834.309326 1629.791626,2842.543945 1633.940430,2848.248047 
	C1634.965942,2849.657959 1632.841919,2850.847656 1630.394775,2850.472656 
z"
        />
        <path
          fill="#F6EEDE"
          opacity="1.000000"
          stroke="none"
          d="
M1474.629395,2848.097656 
	C1473.366211,2845.759521 1472.766479,2843.548584 1472.118164,2840.645264 
	C1476.870361,2840.862305 1481.670898,2841.771240 1487.015991,2842.783447 
	C1486.540283,2847.460205 1484.547729,2851.320312 1480.653809,2852.921387 
	C1477.745483,2854.117188 1478.311523,2848.531494 1474.629395,2848.097656 
z"
        />
        <path
          fill="#F6EEDE"
          opacity="1.000000"
          stroke="none"
          d="
M1462.756836,2865.982910 
	C1462.862671,2860.958008 1468.760986,2857.937500 1467.567627,2851.986572 
	C1467.491333,2851.606445 1469.583008,2850.791992 1471.343018,2850.103271 
	C1472.127075,2852.593750 1470.496826,2854.758301 1470.758423,2857.508789 
	C1471.437012,2864.644775 1471.231201,2864.664307 1462.756836,2865.982910 
z"
        />
        <path
          fill="#F6EEDE"
          opacity="1.000000"
          stroke="none"
          d="
M1467.341064,2835.990723 
	C1466.025635,2846.588867 1464.895874,2847.262451 1453.000488,2844.583740 
	C1455.039429,2839.658203 1461.931763,2842.080322 1463.911133,2836.655762 
	C1464.944458,2835.963623 1465.815674,2835.972412 1467.341064,2835.990723 
z"
        />
        <path
          fill="#F9F4E5"
          opacity="1.000000"
          stroke="none"
          d="
M1448.226929,2831.573730 
	C1444.807861,2833.995117 1441.832642,2833.971436 1439.826904,2830.511230 
	C1437.940552,2827.257080 1441.208740,2826.216553 1443.436890,2824.276855 
	C1447.005005,2824.188721 1449.140991,2825.492188 1449.920776,2829.291016 
	C1449.390869,2830.222656 1448.878052,2830.618164 1448.226929,2831.573730 
z"
        />
        <path
          fill="#E7E3DA"
          opacity="1.000000"
          stroke="none"
          d="
M1376.030884,2727.977051 
	C1381.642944,2731.209961 1384.569580,2736.541016 1387.742310,2742.215576 
	C1381.302734,2741.414551 1379.623901,2739.512207 1375.971802,2728.787598 
	C1375.936890,2728.062012 1375.997803,2728.003174 1376.030884,2727.977051 
z"
        />
        <path
          fill="#EEE7D5"
          opacity="1.000000"
          stroke="none"
          d="
M1443.258789,2882.024170 
	C1444.008911,2882.900635 1444.008423,2883.791260 1444.011230,2885.350830 
	C1439.535767,2886.072021 1438.247681,2889.647949 1436.279419,2893.387939 
	C1435.015137,2894.519531 1434.025391,2895.039307 1432.522461,2895.781738 
	C1433.837402,2890.092529 1434.766357,2883.504639 1443.258789,2882.024170 
z"
        />
        <path
          fill="#AB7453"
          opacity="1.000000"
          stroke="none"
          d="
M1431.324219,2731.747559 
	C1431.925171,2732.986572 1431.939087,2733.909668 1431.887207,2735.526855 
	C1426.293091,2735.849854 1425.143433,2730.562256 1421.896240,2726.827881 
	C1426.199829,2725.471924 1427.765503,2729.648193 1431.324219,2731.747559 
z"
        />
        <path
          fill="#F6EEDE"
          opacity="1.000000"
          stroke="none"
          d="
M1538.685547,2868.433105 
	C1535.260498,2865.943604 1536.186890,2863.589600 1537.709839,2860.613281 
	C1539.074097,2860.349365 1540.177246,2860.680176 1541.641357,2861.473633 
	C1542.732544,2862.768799 1543.182861,2863.732178 1543.256836,2865.632324 
	C1542.426514,2868.074463 1541.189087,2868.923340 1538.685547,2868.433105 
z"
        />
        <path
          fill="#E5DDC9"
          opacity="1.000000"
          stroke="none"
          d="
M1623.102783,2863.948242 
	C1623.158936,2866.718994 1622.576294,2869.681152 1621.912109,2873.405762 
	C1617.931641,2871.082764 1617.999023,2870.014648 1623.102783,2863.948242 
z"
        />
        <path
          fill="#E7E3DA"
          opacity="1.000000"
          stroke="none"
          d="
M1396.094604,2756.622559 
	C1397.291260,2756.362549 1398.625122,2756.611572 1399.707153,2757.272705 
	C1400.847534,2757.969482 1401.707275,2759.328125 1400.703613,2760.534912 
	C1399.592773,2761.870850 1398.294678,2760.981445 1397.446777,2759.910645 
	C1396.855225,2759.163086 1396.552612,2758.187012 1396.094604,2756.622559 
z"
        />
        <path
          fill="#E7E3DA"
          opacity="1.000000"
          stroke="none"
          d="
M1393.918701,2751.363037 
	C1392.737183,2751.609863 1391.380859,2751.390869 1390.281616,2750.738525 
	C1389.120728,2750.049805 1388.250366,2748.675049 1389.259766,2747.451416 
	C1390.378174,2746.095459 1391.697021,2746.987793 1392.551392,2748.070801 
	C1393.145508,2748.824219 1393.432617,2749.819336 1393.918701,2751.363037 
z"
        />
        <path
          fill="#E9E1D0"
          opacity="1.000000"
          stroke="none"
          d="
M1542.677002,2866.507324 
	C1541.997192,2865.117676 1541.998657,2864.209717 1542.001221,2862.619141 
	C1543.496582,2861.952637 1544.990967,2861.968994 1547.231689,2862.000732 
	C1547.980225,2862.897705 1547.982300,2863.779541 1547.982422,2865.322754 
	C1546.654541,2867.307373 1545.081299,2867.492920 1542.677002,2866.507324 
z"
        />
        <path
          fill="#F2EBDA"
          opacity="1.000000"
          stroke="none"
          d="
M1601.254883,2914.089355 
	C1601.960083,2916.262451 1601.093018,2918.113281 1598.533813,2919.671875 
	C1598.087769,2917.703369 1597.776978,2915.249268 1601.254883,2914.089355 
z"
        />
        <path
          fill="#F9F4E5"
          opacity="1.000000"
          stroke="none"
          d="
M1437.703003,2819.433105 
	C1435.730591,2819.886963 1433.241455,2820.250732 1432.127441,2816.676514 
	C1434.275269,2816.062500 1436.132935,2816.912842 1437.703003,2819.433105 
z"
        />
        <path
          fill="#C88655"
          opacity="1.000000"
          stroke="none"
          d="
M1462.017334,2762.770508 
	C1464.196167,2762.009277 1466.150757,2762.830566 1467.683838,2765.628906 
	C1465.568115,2766.230713 1463.953247,2764.744141 1462.017334,2762.770508 
z"
        />
        <path
          fill="#C88655"
          opacity="1.000000"
          stroke="none"
          d="
M1451.532593,2755.111084 
	C1453.617188,2754.154541 1455.233032,2754.625977 1455.730957,2757.671875 
	C1453.813843,2758.320312 1452.556519,2757.221680 1451.532593,2755.111084 
z"
        />
        <path
          fill="#E7E3DA"
          opacity="1.000000"
          stroke="none"
          d="
M1352.609863,2690.313477 
	C1353.395386,2690.560059 1353.778442,2690.883301 1354.161621,2691.206543 
	C1353.601807,2691.359131 1353.006348,2691.711914 1352.490234,2691.620117 
	C1351.611328,2691.463379 1351.540527,2690.921387 1352.609863,2690.313477 
z"
        />
        <path
          fill="#C88655"
          opacity="1.000000"
          stroke="none"
          d="
M1447.610352,2750.665527 
	C1449.562622,2750.672852 1450.804321,2751.696289 1451.587646,2753.929688 
	C1450.373779,2755.221680 1449.446167,2754.399658 1448.140747,2753.250000 
	C1447.613770,2752.278320 1447.421631,2751.804688 1447.610352,2750.665527 
z"
        />
        <path
          fill="#E7E3DA"
          opacity="1.000000"
          stroke="none"
          d="
M1395.974609,2756.016113 
	C1395.316040,2755.094238 1394.690674,2754.146729 1394.022949,2752.609375 
	C1393.980469,2752.019531 1393.998413,2752.000488 1394.006714,2751.995117 
	C1394.660400,2752.914795 1395.305786,2753.840088 1396.009766,2755.348633 
	C1396.068237,2755.932129 1396.007690,2755.990479 1395.974609,2756.016113 
z"
        />
        <path
          fill="#AB7453"
          opacity="1.000000"
          stroke="none"
          d="
M1435.475098,2740.650391 
	C1437.047119,2740.610840 1437.728149,2741.581543 1437.933105,2743.539795 
	C1437.006836,2743.199463 1436.069336,2742.229248 1435.475098,2740.650391 
z"
        />
        <path
          fill="#AB7453"
          opacity="1.000000"
          stroke="none"
          d="
M1438.188843,2744.592529 
	C1438.992676,2744.404053 1440.155762,2744.755371 1441.641357,2745.638428 
	C1440.516968,2746.838867 1439.538696,2745.965088 1438.188843,2744.592529 
z"
        />
        <path
          fill="#E7E3DA"
          opacity="1.000000"
          stroke="none"
          d="
M1406.526123,2768.020996 
	C1406.781128,2767.681885 1407.252808,2767.566406 1407.269531,2767.613281 
	C1407.579224,2768.481201 1407.311279,2768.724854 1406.526123,2768.020996 
z"
        />
        <path
          fill="#C88655"
          opacity="1.000000"
          stroke="none"
          d="
M1468.141724,2766.654785 
	C1468.966797,2766.479004 1470.121460,2766.832031 1471.611206,2767.705811 
	C1470.787842,2767.878906 1469.629639,2767.531006 1468.141724,2766.654785 
z"
        />
        <path
          fill="#AB7453"
          opacity="1.000000"
          stroke="none"
          d="
M1447.432373,2750.020996 
	C1447.962769,2750.458496 1447.934448,2750.916748 1447.856079,2752.063477 
	C1447.182495,2752.286621 1446.559204,2751.821289 1445.850952,2750.774414 
	C1446.135254,2750.142578 1446.504395,2750.092285 1447.432373,2750.020996 
z"
        />
        <path
          fill="#AB7453"
          opacity="1.000000"
          stroke="none"
          d="
M1443.821045,2748.681396 
	C1444.406128,2748.439941 1444.970947,2748.823486 1445.743164,2749.686035 
	C1445.234009,2749.878662 1444.517334,2749.592529 1443.821045,2748.681396 
z"
        />
        <path
          fill="#AB7453"
          opacity="1.000000"
          stroke="none"
          d="
M1441.782715,2746.698242 
	C1442.403931,2746.442627 1442.965820,2746.824951 1443.736816,2747.682617 
	C1443.204956,2747.884033 1442.464233,2747.610352 1441.782715,2746.698242 
z"
        />
        <path
          fill="#AB7453"
          opacity="1.000000"
          stroke="none"
          d="
M1433.658203,2738.782715 
	C1434.399048,2738.452393 1434.963135,2738.829834 1435.728027,2739.685547 
	C1434.932373,2740.596191 1434.125610,2740.338135 1433.658203,2738.782715 
z"
        />
        <path
          fill="#AB7453"
          opacity="1.000000"
          stroke="none"
          d="
M1431.652222,2736.789551 
	C1432.401123,2736.453369 1432.968750,2736.826660 1433.730957,2737.682617 
	C1432.927612,2738.604492 1432.118164,2738.350586 1431.652222,2736.789551 
z"
        />
        <path
          fill="#F6EEDE"
          opacity="1.000000"
          stroke="none"
          d="
M1557.073608,2856.957764 
	C1557.120117,2857.044189 1557.027100,2856.871338 1557.073608,2856.957764 
z"
        />
        <path
          fill="#E7E3DA"
          opacity="1.000000"
          stroke="none"
          d="
M1411.037720,2771.102295 
	C1410.937378,2771.159424 1411.138062,2771.045166 1411.037720,2771.102295 
z"
        />
        <path
          fill="#C88655"
          opacity="1.000000"
          stroke="none"
          d="
M1460.489502,2761.972168 
	C1460.601929,2761.148193 1461.120728,2760.709229 1461.772217,2761.992676 
	C1461.859009,2762.338135 1460.891968,2762.243896 1460.489502,2761.972168 
z"
        />
        <path
          fill="#C88655"
          opacity="1.000000"
          stroke="none"
          d="
M1455.938721,2758.622314 
	C1456.402466,2758.475830 1456.958130,2758.885742 1457.736328,2759.771973 
	C1457.316040,2759.891602 1456.673218,2759.534912 1455.938721,2758.622314 
z"
        />
        <path
          fill="#E7E3DA"
          opacity="1.000000"
          stroke="none"
          d="
M1426.098633,2786.254395 
	C1426.225708,2786.256104 1426.445312,2786.521729 1426.664917,2786.787598 
	C1426.506958,2786.697754 1426.348999,2786.608154 1426.098633,2786.254395 
z"
        />
        <path
          fill="#E7E3DA"
          opacity="1.000000"
          stroke="none"
          d="
M1425.995117,2786.004883 
	C1425.353271,2785.794678 1424.834473,2785.417236 1424.219360,2784.433838 
	C1424.632568,2784.218750 1425.147095,2784.599854 1425.777344,2785.562012 
	C1426.006104,2785.990234 1426.000000,2786.000000 1425.995117,2786.004883 
z"
        />
        <path
          fill="#E7E3DA"
          opacity="1.000000"
          stroke="none"
          d="
M1423.995117,2784.004883 
	C1423.765015,2783.749512 1423.539673,2783.489014 1423.314331,2783.228760 
	C1423.473267,2783.315674 1423.632080,2783.402344 1423.897949,2783.739746 
	C1424.004883,2783.990234 1424.000000,2784.000000 1423.995117,2784.004883 
z"
        />
        <path
          fill="#704A3A"
          opacity="1.000000"
          stroke="none"
          d="
M1625.270996,2740.435547 
	C1625.727173,2736.491943 1626.948486,2733.532227 1629.439697,2730.386719 
	C1631.400269,2728.818359 1630.670410,2726.345459 1633.323242,2725.778076 
	C1633.966675,2725.978027 1634.036499,2726.033203 1634.069580,2726.062988 
	C1636.991577,2729.089600 1636.254517,2732.750732 1636.193970,2736.396484 
	C1636.052368,2744.913330 1639.094849,2746.921631 1646.164062,2741.975830 
	C1654.844238,2735.903320 1662.582764,2736.636963 1670.830444,2742.344727 
	C1679.299438,2748.205322 1679.716309,2750.916260 1672.476318,2760.396240 
	C1668.338379,2766.420654 1662.150146,2768.715088 1656.445923,2772.833496 
	C1650.984619,2765.838623 1645.908203,2768.732422 1640.833740,2772.739502 
	C1636.009766,2776.548828 1631.506592,2782.708496 1624.508057,2778.751709 
	C1617.355103,2774.707520 1617.594604,2767.257324 1618.966675,2760.180908 
	C1620.241577,2753.605225 1618.083984,2745.932617 1625.270996,2740.435547 
z"
        />
        <path
          fill="#8C5B43"
          opacity="1.000000"
          stroke="none"
          d="
M1625.096436,2739.921387 
	C1621.538574,2748.275879 1622.985229,2757.691895 1621.023560,2766.511719 
	C1620.031738,2770.971680 1622.370483,2775.248779 1626.300415,2777.337158 
	C1630.225830,2779.422852 1632.979126,2775.954102 1635.594727,2773.406250 
	C1639.127197,2769.965088 1642.311523,2766.267822 1647.603394,2765.067383 
	C1654.505737,2763.501709 1654.676758,2763.383789 1655.823975,2772.254883 
	C1650.033325,2778.248291 1642.939331,2781.294189 1636.022095,2783.877930 
	C1622.825195,2788.807373 1609.767944,2794.433594 1594.895020,2796.941406 
	C1594.917603,2794.179932 1598.627075,2792.248291 1596.489502,2788.262207 
	C1595.340942,2786.120361 1597.086670,2783.848877 1598.098389,2781.805908 
	C1599.990845,2777.983887 1602.039307,2774.241211 1602.384888,2769.846436 
	C1602.458130,2768.915283 1602.840576,2767.686279 1603.518677,2767.173828 
	C1611.511230,2761.132324 1613.007324,2751.391113 1617.642700,2742.620850 
	C1620.053955,2741.068359 1622.033203,2739.865723 1625.096436,2739.921387 
z"
        />
        <path
          fill="#564031"
          opacity="1.000000"
          stroke="none"
          d="
M1672.864746,2760.771729 
	C1672.249756,2760.435547 1672.273315,2759.776367 1672.283325,2759.117432 
	C1672.340942,2755.320801 1678.712036,2751.482178 1674.556519,2748.605225 
	C1669.693848,2745.238525 1665.273804,2740.100830 1658.465942,2739.985596 
	C1655.389648,2739.933838 1652.603271,2740.390381 1650.291992,2742.698975 
	C1648.668579,2744.320801 1647.045166,2746.312500 1645.021118,2747.074219 
	C1642.154907,2748.152588 1638.957031,2750.969727 1635.861572,2748.218018 
	C1633.086792,2745.752197 1631.463501,2742.234131 1632.363403,2738.408447 
	C1633.194946,2734.873535 1634.339600,2731.437012 1634.079834,2726.917480 
	C1637.275024,2725.511963 1637.905640,2723.282715 1638.039429,2720.463379 
	C1638.219238,2716.679443 1637.223511,2712.786621 1639.509277,2708.559570 
	C1642.842407,2710.727783 1641.886353,2716.593750 1647.964844,2716.763428 
	C1649.731323,2716.812744 1649.058960,2719.361328 1648.466553,2720.954102 
	C1647.335205,2723.996094 1644.893921,2726.539307 1644.915649,2730.802490 
	C1653.251099,2732.989746 1661.420654,2736.617676 1669.657959,2729.667725 
	C1672.961182,2726.881104 1677.430176,2727.390137 1681.119141,2728.381836 
	C1686.328247,2729.782227 1691.545410,2729.864502 1697.721069,2730.285645 
	C1692.274536,2742.054199 1683.694580,2751.927246 1672.864746,2760.771729 
z"
        />
        <path
          fill="#D0C8B8"
          opacity="1.000000"
          stroke="none"
          d="
M1661.182129,2795.950195 
	C1657.169800,2800.895264 1651.712891,2804.043457 1645.041260,2806.735596 
	C1634.934937,2810.813721 1623.852661,2812.101807 1614.393433,2817.789062 
	C1611.006958,2819.825195 1607.743042,2820.807861 1604.995483,2816.576660 
	C1603.359009,2814.056152 1600.457031,2814.439453 1597.856079,2813.935303 
	C1587.163330,2811.862793 1576.302612,2813.138916 1564.742676,2812.070312 
	C1563.910278,2812.115723 1562.942505,2812.066406 1562.458496,2812.045410 
	C1560.842163,2810.658203 1560.562012,2809.131592 1561.634277,2806.814453 
	C1584.093994,2806.567871 1604.886841,2800.369141 1625.827881,2795.054688 
	C1629.950195,2794.008301 1633.796875,2791.895508 1638.965210,2791.823242 
	C1639.758789,2794.063965 1638.711060,2795.296387 1636.432739,2795.976807 
	C1632.337158,2797.199463 1628.339966,2798.754150 1624.311768,2800.197998 
	C1622.347046,2800.902344 1620.171265,2802.181152 1621.076904,2804.337646 
	C1622.163208,2806.923828 1625.022705,2808.360352 1627.675171,2806.898438 
	C1632.116211,2804.450684 1637.114014,2803.998291 1641.726196,2802.204834 
	C1647.838501,2799.827393 1654.173096,2798.021973 1661.182129,2795.950195 
z"
        />
        <path
          fill="#E5DDC9"
          opacity="1.000000"
          stroke="none"
          d="
M1561.252075,2806.117676 
	C1562.103760,2807.639893 1562.052612,2809.098877 1561.988037,2811.291260 
	C1556.057007,2812.349365 1550.877686,2809.674072 1545.220825,2808.259521 
	C1550.134521,2805.063232 1555.342529,2806.343994 1561.252075,2806.117676 
z"
        />
        <path
          fill="#D0C8B8"
          opacity="1.000000"
          stroke="none"
          d="
M1645.360352,2788.041016 
	C1645.744507,2791.008545 1643.974731,2792.069092 1640.518799,2791.811768 
	C1640.224976,2788.926758 1642.039795,2787.932129 1645.360352,2788.041016 
z"
        />
        <path
          fill="#D0C8B8"
          opacity="1.000000"
          stroke="none"
          d="
M1657.105225,2781.855957 
	C1657.882690,2784.903809 1656.085083,2785.930908 1652.581543,2785.862793 
	C1652.837158,2783.853516 1654.327515,2782.495117 1657.105225,2781.855957 
z"
        />
        <path
          fill="#D0C8B8"
          opacity="1.000000"
          stroke="none"
          d="
M1663.212280,2777.862305 
	C1663.110840,2780.128662 1661.623169,2781.577393 1658.463867,2781.768066 
	C1658.146851,2778.929443 1659.936890,2777.915527 1663.212280,2777.862305 
z"
        />
        <path
          fill="#704A3A"
          opacity="1.000000"
          stroke="none"
          d="
M1616.486084,2714.021729 
	C1616.075562,2713.066406 1615.985474,2712.023438 1617.068481,2711.630859 
	C1617.171753,2711.593750 1617.648071,2712.585693 1617.977783,2713.586914 
	C1617.666870,2714.067139 1617.329224,2714.060303 1616.486084,2714.021729 
z"
        />
        <path
          fill="#C88655"
          opacity="1.000000"
          stroke="none"
          d="
M1559.430542,2709.904785 
	C1561.904419,2713.895752 1559.000732,2714.657959 1556.548584,2715.458252 
	C1553.440186,2716.472168 1550.968384,2714.348145 1547.936890,2713.446533 
	C1549.327515,2711.019287 1553.185791,2712.529297 1553.967773,2708.861328 
	C1555.725220,2708.721924 1557.289673,2709.191162 1559.430542,2709.904785 
z"
        />
        <path
          fill="#8C5B43"
          opacity="1.000000"
          stroke="none"
          d="
M1533.205322,2671.901611 
	C1533.057007,2674.780273 1530.243408,2677.689941 1535.348145,2679.634766 
	C1534.851685,2680.859131 1533.740601,2681.966553 1532.300537,2683.569580 
	C1530.440918,2683.668213 1528.434692,2684.909668 1527.664795,2682.323242 
	C1528.194824,2681.600098 1528.478394,2681.296875 1528.388306,2680.495117 
	C1522.820679,2676.386719 1529.809570,2675.691650 1529.687744,2672.706055 
	C1529.570312,2671.804199 1529.386963,2671.475586 1529.504639,2670.669189 
	C1529.805664,2670.191650 1529.874390,2670.121826 1529.907959,2670.087158 
	C1530.881348,2670.569580 1531.821167,2671.086426 1533.205322,2671.901611 
z"
        />
        <path
          fill="#8C5B43"
          opacity="1.000000"
          stroke="none"
          d="
M1633.957397,2725.279297 
	C1632.954712,2727.096924 1631.942871,2728.215820 1630.447021,2729.638184 
	C1629.965576,2727.206299 1629.968140,2724.470459 1629.957764,2720.916992 
	C1632.846558,2720.192871 1633.971924,2721.873535 1633.957397,2725.279297 
z"
        />
        <path
          fill="#B0714F"
          opacity="1.000000"
          stroke="none"
          d="
M1566.101196,2703.266357 
	C1564.423462,2703.721436 1563.194702,2703.629395 1563.045166,2702.015381 
	C1563.012329,2701.661133 1563.694214,2700.940186 1564.096802,2700.900879 
	C1565.486206,2700.765137 1566.399292,2701.428955 1566.101196,2703.266357 
z"
        />
        <path
          fill="#704A3A"
          opacity="1.000000"
          stroke="none"
          d="
M1626.002441,2710.002441 
	C1625.764648,2709.887939 1626.040161,2711.131836 1625.743164,2710.099121 
	C1625.995117,2709.998291 1626.000000,2710.000000 1626.002441,2710.002441 
z"
        />
        <path
          fill="#B0714F"
          opacity="1.000000"
          stroke="none"
          d="
M1554.105225,2704.244141 
	C1554.221924,2704.250000 1554.443726,2704.500000 1554.665527,2704.750000 
	C1554.513916,2704.662598 1554.362183,2704.575439 1554.105225,2704.244141 
z"
        />
        <path
          fill="#B0714F"
          opacity="1.000000"
          stroke="none"
          d="
M1553.900024,2703.759277 
	C1553.787720,2703.750000 1553.575439,2703.500000 1553.363281,2703.250000 
	C1553.508789,2703.339355 1553.654419,2703.428711 1553.900024,2703.759277 
z"
        />
        <path
          fill="#96664C"
          opacity="1.000000"
          stroke="none"
          d="
M1529.333496,2670.152588 
	C1529.805664,2670.191650 1529.851929,2671.163086 1529.802856,2671.647949 
	C1529.227173,2671.811523 1528.775757,2671.406250 1528.157227,2670.529785 
	C1527.914673,2670.142822 1528.861450,2670.113525 1529.333496,2670.152588 
z"
        />
        <path
          fill="#C88655"
          opacity="1.000000"
          stroke="none"
          d="
M1552.006592,2722.004883 
	C1552.010742,2721.674805 1552.008301,2721.339844 1552.002930,2720.502441 
	C1557.855469,2720.902588 1564.285156,2717.732422 1569.799316,2723.240234 
	C1564.350586,2724.941162 1559.177246,2721.980225 1552.813232,2721.997070 
	C1551.986816,2721.990234 1552.000000,2722.000000 1552.006592,2722.004883 
z"
        />
        <path
          fill="#C88655"
          opacity="1.000000"
          stroke="none"
          d="
M1551.639893,2719.507324 
	C1549.551025,2719.895508 1546.904053,2720.286865 1545.121948,2717.184082 
	C1547.988770,2716.061279 1549.503052,2718.011230 1551.639893,2719.507324 
z"
        />
        <path
          fill="#96664C"
          opacity="1.000000"
          stroke="none"
          d="
M1574.002441,2712.000000 
	C1572.776978,2711.792969 1571.393677,2711.737549 1571.095337,2710.188477 
	C1571.033569,2709.868408 1571.718262,2709.074951 1572.075195,2709.064453 
	C1573.194702,2709.032227 1573.596558,2709.947754 1573.920288,2711.449463 
	C1573.995117,2712.000000 1574.000000,2712.000000 1574.002441,2712.000000 
z"
        />
        <path
          fill="#C88655"
          opacity="1.000000"
          stroke="none"
          d="
M1570.099121,2724.245605 
	C1570.215454,2724.253174 1570.430908,2724.506348 1570.646362,2724.759521 
	C1570.496948,2724.670166 1570.347534,2724.580566 1570.099121,2724.245605 
z"
        />
        <path
          fill="#96664C"
          opacity="1.000000"
          stroke="none"
          d="
M1527.991333,2680.473633 
	C1528.323730,2680.333008 1528.666138,2680.646729 1528.916016,2681.022461 
	C1528.969971,2681.103760 1528.690918,2681.406250 1528.238403,2681.754883 
	C1527.911377,2681.903320 1527.968140,2680.950439 1527.991333,2680.473633 
z"
        />
        <path
          fill="#B67247"
          opacity="1.000000"
          stroke="none"
          d="
M1431.047363,2484.242188 
	C1435.217896,2487.211914 1433.752441,2489.041260 1429.176514,2490.254883 
	C1427.613281,2487.887451 1428.845947,2486.153809 1431.047363,2484.242188 
z"
        />
        <path
          fill="#CC855B"
          opacity="1.000000"
          stroke="none"
          d="
M1623.560303,2550.406494 
	C1623.732422,2548.706787 1623.477417,2547.012939 1622.013306,2546.968262 
	C1620.105103,2546.909668 1618.141724,2547.438721 1616.259888,2547.934570 
	C1607.101685,2550.347412 1599.540649,2549.439941 1593.083008,2540.972168 
	C1588.778076,2535.327148 1583.026978,2536.659668 1577.793213,2542.751709 
	C1576.336670,2544.447266 1575.912964,2547.041748 1572.663818,2547.894043 
	C1571.974365,2548.019531 1571.823730,2547.135498 1571.783325,2546.688965 
	C1570.190552,2542.065430 1568.396851,2538.408447 1564.801270,2535.120117 
	C1559.664917,2530.422363 1554.484131,2525.038330 1554.417969,2516.456543 
	C1560.562622,2512.684570 1566.842041,2510.604248 1573.693481,2511.023193 
	C1575.672852,2511.144287 1578.573975,2511.461914 1579.140259,2513.023438 
	C1582.660156,2522.727783 1591.452637,2522.872314 1599.331055,2525.864258 
	C1611.608276,2530.527588 1622.982178,2529.917480 1635.327148,2524.835449 
	C1636.492920,2524.722656 1636.963013,2524.749023 1638.103027,2524.983398 
	C1644.196167,2528.583496 1647.856323,2528.246582 1649.481567,2521.031250 
	C1650.959473,2514.469971 1653.960693,2508.614258 1661.265137,2505.822021 
	C1663.641846,2506.188477 1664.579468,2507.485596 1665.916992,2509.124512 
	C1666.522827,2515.742920 1664.402710,2522.035645 1669.073242,2527.745361 
	C1671.050537,2530.162598 1668.724365,2533.127686 1665.772339,2533.588867 
	C1655.413818,2535.206299 1646.157227,2539.375244 1637.510620,2545.062744 
	C1634.873779,2546.797119 1632.449829,2544.439697 1629.782349,2545.060059 
	C1629.192749,2547.756104 1630.834351,2551.235352 1627.456055,2552.965576 
	C1625.246948,2554.097168 1622.895630,2554.878662 1623.560303,2550.406494 
z"
        />
        <path
          fill="#B17752"
          opacity="1.000000"
          stroke="none"
          d="
M1536.729004,2518.132812 
	C1532.536865,2509.297852 1532.264404,2498.940186 1522.990479,2493.312012 
	C1520.781738,2491.971680 1520.139160,2489.735840 1520.111084,2487.283691 
	C1520.080444,2484.618408 1519.751709,2481.910156 1520.110718,2479.297363 
	C1520.723877,2474.836182 1516.748169,2478.037109 1516.338745,2476.961426 
	C1515.617065,2475.064209 1516.317993,2473.019043 1519.311768,2472.094727 
	C1528.737183,2479.356445 1538.796265,2485.669678 1541.755127,2498.584717 
	C1542.158325,2499.774414 1540.452026,2501.363770 1543.277222,2500.961426 
	C1546.639648,2501.411865 1547.520386,2503.239746 1546.636963,2506.943848 
	C1542.690674,2511.567139 1544.113770,2515.497070 1544.762695,2519.377441 
	C1545.615234,2524.476318 1546.384644,2529.589355 1547.435303,2536.253174 
	C1540.982666,2530.634277 1543.001953,2522.384033 1536.729004,2518.132812 
z"
        />
        <path
          fill="#B17752"
          opacity="1.000000"
          stroke="none"
          d="
M1555.691895,2515.232178 
	C1556.257568,2523.829590 1563.836060,2527.607422 1566.622437,2534.148438 
	C1566.826294,2534.627197 1567.656006,2535.159912 1568.172241,2535.137939 
	C1578.470947,2534.699463 1572.343872,2540.909180 1571.913696,2545.487061 
	C1569.446167,2545.940918 1567.658447,2544.840332 1566.417480,2542.868164 
	C1565.571899,2541.524170 1564.704102,2540.206055 1562.390625,2541.562012 
	C1561.088013,2541.668457 1559.597290,2541.594971 1559.332520,2540.987305 
	C1554.788330,2530.560303 1550.395752,2520.066650 1545.938843,2508.839355 
	C1545.907837,2508.090820 1546.129028,2507.867432 1546.239502,2507.755859 
	C1554.254761,2503.234375 1555.303345,2504.051270 1555.691895,2515.232178 
z"
        />
        <path
          fill="#704A3A"
          opacity="1.000000"
          stroke="none"
          d="
M1574.011230,2567.990723 
	C1574.846924,2568.407959 1575.523193,2568.965088 1575.409180,2569.996826 
	C1575.387939,2570.188477 1574.971802,2570.336426 1574.737793,2570.504395 
	C1574.500366,2569.985107 1574.263062,2569.466064 1574.000732,2568.488037 
	C1573.975586,2568.029297 1573.998047,2568.001465 1574.011230,2567.990723 
z"
        />
        <path
          fill="#564031"
          opacity="1.000000"
          stroke="none"
          d="
M1571.834473,2579.494629 
	C1571.507446,2579.563232 1570.976685,2579.168457 1570.220947,2578.320312 
	C1570.540894,2578.254883 1571.085693,2578.642822 1571.834473,2579.494629 
z"
        />
        <path
          fill="#B17752"
          opacity="1.000000"
          stroke="none"
          d="
M1561.995117,2542.004883 
	C1562.209961,2542.266357 1562.429688,2542.522949 1562.649292,2542.779541 
	C1562.496582,2542.686523 1562.343994,2542.593750 1562.099854,2542.245605 
	C1562.008301,2541.990234 1562.000000,2542.000000 1561.995117,2542.004883 
z"
        />
        <path
          fill="#1D1712"
          opacity="1.000000"
          stroke="none"
          d="
M1536.204468,2621.866455 
	C1536.013916,2621.765137 1536.128784,2623.164307 1535.865723,2621.875488 
	C1536.136597,2621.710205 1536.124756,2621.869385 1536.204468,2621.866455 
z"
        />
        <path
          fill="#564031"
          opacity="1.000000"
          stroke="none"
          d="
M1540.937744,2623.039062 
	C1540.887329,2622.945557 1540.988037,2623.132812 1540.937744,2623.039062 
z"
        />
        <path
          fill="#D99469"
          opacity="1.000000"
          stroke="none"
          d="
M1556.472778,2515.426270 
	C1553.233521,2515.657471 1551.587280,2514.261475 1552.536621,2511.417725 
	C1554.729980,2504.847656 1550.581543,2507.152588 1546.975586,2507.683105 
	C1545.942993,2505.480225 1546.115723,2502.971436 1542.498901,2502.264160 
	C1541.482788,2502.365723 1541.145630,2502.516602 1540.472412,2502.227295 
	C1538.765869,2494.177734 1535.697754,2487.523926 1528.564453,2483.761719 
	C1523.985962,2481.346924 1522.698730,2477.061035 1520.493286,2472.411621 
	C1519.314209,2469.778564 1518.685181,2467.576660 1517.955078,2464.689941 
	C1520.804199,2463.741455 1521.587524,2466.183838 1523.081543,2468.399902 
	C1527.129028,2463.900146 1524.589478,2460.962402 1521.453979,2457.643555 
	C1516.496338,2452.395996 1516.684692,2435.676758 1521.705322,2430.845459 
	C1522.662109,2429.925049 1524.850220,2430.284424 1527.285034,2430.130127 
	C1530.865967,2434.656738 1528.923706,2439.684570 1529.727051,2444.963379 
	C1529.968628,2446.341553 1526.350464,2447.743408 1530.532715,2448.326660 
	C1535.366821,2453.415527 1539.034302,2458.425293 1539.238647,2465.273438 
	C1539.450806,2472.384277 1544.993164,2476.207031 1550.219604,2478.359375 
	C1556.508423,2480.949951 1562.884155,2484.765625 1569.459473,2485.299805 
	C1577.220459,2485.930176 1580.735718,2489.565430 1583.270020,2495.933838 
	C1586.812378,2504.835449 1592.854614,2510.014404 1603.130127,2509.327393 
	C1607.181152,2509.056152 1611.474731,2509.971436 1613.075806,2504.963867 
	C1614.502319,2500.501953 1613.478760,2496.608398 1609.850464,2493.220215 
	C1602.783203,2486.620361 1594.291992,2481.943115 1586.949707,2475.598145 
	C1580.731201,2470.224365 1576.647827,2464.480957 1574.849121,2456.904785 
	C1573.174316,2449.849854 1570.758911,2443.641602 1563.660889,2440.216553 
	C1561.504517,2439.176270 1560.395508,2436.910645 1561.886475,2433.752441 
	C1567.491333,2433.407959 1572.269043,2435.124023 1577.872070,2436.340332 
	C1584.726562,2440.014893 1588.110596,2444.865479 1589.759888,2452.019043 
	C1592.615479,2464.405273 1601.780029,2471.151367 1614.010254,2472.323975 
	C1626.361694,2473.508057 1634.604126,2479.333984 1638.862061,2490.598633 
	C1642.152466,2499.303467 1648.687378,2501.442871 1657.600830,2501.479492 
	C1659.349854,2502.853271 1660.424072,2503.914307 1661.704102,2505.507324 
	C1657.159424,2510.539551 1653.015137,2515.546631 1652.088745,2522.218018 
	C1650.641113,2532.644531 1647.618530,2533.876221 1638.132324,2526.691650 
	C1635.306763,2526.107422 1634.393921,2528.652344 1632.577637,2529.879395 
	C1629.145264,2532.198486 1625.677368,2534.771973 1621.014282,2531.318848 
	C1617.412598,2528.651367 1613.331421,2529.672607 1610.217773,2532.893066 
	C1606.951660,2536.270996 1604.889771,2535.218018 1602.440308,2531.782959 
	C1599.672607,2527.901855 1596.491943,2525.666260 1590.663330,2527.002930 
	C1584.581299,2528.397949 1580.921021,2525.138184 1580.084961,2519.093018 
	C1579.443115,2514.453125 1576.643066,2514.196533 1573.185547,2513.824951 
	C1570.100098,2513.493408 1566.595825,2511.185791 1565.749756,2516.785889 
	C1565.322998,2519.610596 1563.344604,2519.497559 1561.393677,2517.977539 
	C1559.972290,2516.870117 1559.634644,2514.133301 1556.472778,2515.426270 
z"
        />
        <path
          fill="#D99469"
          opacity="1.000000"
          stroke="none"
          d="
M1525.939941,2424.645020 
	C1528.402344,2424.019531 1530.889893,2424.019531 1533.456177,2424.019531 
	C1533.002197,2427.171143 1529.938477,2425.356934 1528.441895,2427.437744 
	C1527.448608,2427.854736 1526.943115,2427.618652 1526.286987,2426.864746 
	C1526.025757,2426.102295 1525.995483,2425.686279 1525.939941,2424.645020 
z"
        />
        <path
          fill="#F3AB7B"
          opacity="1.000000"
          stroke="none"
          d="
M1528.508301,2448.519287 
	C1527.965576,2442.542480 1524.165405,2437.144531 1527.951294,2430.938477 
	C1531.517456,2430.183594 1534.934937,2430.160400 1539.209839,2430.107178 
	C1540.067139,2430.077393 1540.040894,2430.031982 1540.058594,2430.044434 
	C1544.343872,2431.652832 1548.913452,2431.328125 1554.054321,2432.292236 
	C1556.765503,2433.251465 1558.880005,2432.856934 1561.385864,2433.829102 
	C1562.600586,2438.009766 1565.738770,2438.649414 1568.430054,2439.877197 
	C1572.936646,2441.932617 1576.186890,2444.892090 1576.058716,2450.387939 
	C1575.923340,2456.179443 1581.250366,2460.435303 1580.692627,2466.405273 
	C1580.643921,2466.926270 1581.230225,2467.871582 1581.709839,2468.022949 
	C1590.608643,2470.832275 1593.867554,2480.979736 1602.709717,2483.971924 
	C1608.164673,2485.817627 1610.963989,2491.468750 1615.924927,2494.213379 
	C1617.014404,2494.816162 1617.450195,2507.272461 1616.370850,2508.095215 
	C1612.941772,2510.708984 1610.407837,2515.963379 1604.600952,2512.426514 
	C1603.577026,2511.802490 1601.822998,2511.321289 1600.888184,2511.772461 
	C1592.822144,2515.668213 1589.236206,2508.817627 1584.679810,2504.903320 
	C1582.806274,2503.293701 1582.020752,2500.341553 1580.927856,2497.915527 
	C1578.380737,2492.261719 1576.968384,2485.363281 1567.824341,2489.434814 
	C1564.991577,2490.695801 1563.422241,2487.032227 1561.382202,2485.500000 
	C1559.603271,2484.163818 1558.007446,2481.006592 1555.681396,2482.897217 
	C1551.532349,2486.269043 1549.349243,2485.571045 1547.730225,2480.764648 
	C1547.440674,2479.905029 1546.661011,2478.773438 1545.899170,2478.577393 
	C1537.276855,2476.357666 1535.981934,2469.738525 1535.959839,2462.334473 
	C1535.951538,2459.540771 1535.659790,2457.027832 1532.531250,2455.846436 
	C1529.398682,2454.663330 1530.483154,2451.186768 1528.508301,2448.519287 
z"
        />
        <path
          fill="#797771"
          opacity="1.000000"
          stroke="none"
          d="
M2129.977539,3152.912109 
	C2130.926025,3157.331299 2132.860840,3160.758789 2132.083740,3165.482910 
	C2127.002197,3165.958740 2123.523926,3163.278320 2120.590332,3159.317139 
	C2116.068604,3153.211426 2115.940918,3153.305908 2109.372070,3156.891602 
	C2104.079590,3159.780518 2097.195801,3155.910645 2096.285400,3149.473145 
	C2095.340576,3142.793701 2097.383057,3136.320068 2097.847168,3128.894287 
	C2102.152588,3129.479004 2103.779053,3134.138184 2108.341797,3135.987061 
	C2114.988281,3137.834717 2120.344727,3140.792969 2125.428223,3145.616211 
	C2125.899414,3146.156006 2125.729248,3146.102295 2125.668457,3146.165771 
	C2127.049561,3148.191895 2128.491455,3150.154541 2129.977539,3152.912109 
z"
        />
        <path
          fill="#E9E1D0"
          opacity="1.000000"
          stroke="none"
          d="
M2134.658203,3057.930664 
	C2133.049316,3050.346436 2133.051025,3050.296631 2126.410156,3050.061279 
	C2122.879150,3049.936035 2119.340332,3050.018311 2114.897705,3049.993652 
	C2120.125732,3040.816406 2125.017090,3039.213623 2134.038086,3042.628906 
	C2141.851318,3045.586670 2147.941650,3041.230225 2153.929443,3037.041260 
	C2156.313232,3035.374023 2157.139404,3032.062500 2162.023193,3030.280518 
	C2158.741699,3039.551025 2152.771484,3045.581055 2148.694824,3052.604736 
	C2145.762695,3057.656738 2139.929932,3056.294434 2134.658203,3057.930664 
z"
        />
        <path
          fill="#E9E1D0"
          opacity="1.000000"
          stroke="none"
          d="
M2112.457520,3064.482422 
	C2115.024414,3061.392334 2118.725586,3060.558594 2123.285645,3060.057129 
	C2122.088867,3065.730957 2117.112061,3064.448486 2112.457520,3064.482422 
z"
        />
        <path
          fill="#E9E1D0"
          opacity="1.000000"
          stroke="none"
          d="
M2182.737061,2961.995361 
	C2183.440430,2959.168213 2185.426025,2956.951172 2189.280762,2956.020020 
	C2189.753418,2960.072998 2187.432129,2961.909912 2182.737061,2961.995361 
z"
        />
        <path
          fill="#E9E1D0"
          opacity="1.000000"
          stroke="none"
          d="
M2160.519531,2983.836914 
	C2160.162354,2982.138184 2160.757568,2980.620605 2163.389160,2980.118652 
	C2163.811523,2981.853516 2163.218262,2983.374268 2160.519531,2983.836914 
z"
        />
        <path
          fill="#F2EBDA"
          opacity="1.000000"
          stroke="none"
          d="
M2125.899414,3145.421387 
	C2119.200684,3145.607910 2115.250000,3140.196533 2109.458008,3136.617188 
	C2116.644531,3134.928223 2122.396484,3137.592529 2125.899414,3145.421387 
z"
        />
        <path
          fill="#10212F"
          opacity="1.000000"
          stroke="none"
          d="
M2243.954102,3208.066895 
	C2244.124756,3207.724365 2244.525635,3207.720459 2245.512207,3207.858154 
	C2245.576904,3208.685791 2245.029053,3209.245361 2243.954102,3208.066895 
z"
        />
        <path
          fill="#A7CD43"
          opacity="1.000000"
          stroke="none"
          d="
M2286.817383,3191.284912 
	C2286.868408,3194.094482 2287.088623,3196.201660 2287.290527,3199.106445 
	C2286.304932,3201.332764 2286.178467,3202.941650 2286.147949,3204.611816 
	C2286.094971,3207.514160 2286.663330,3210.855469 2282.534912,3211.601807 
	C2278.559814,3212.320557 2275.362793,3210.894043 2273.236572,3207.491211 
	C2271.517822,3204.740723 2271.462646,3201.101562 2268.096436,3198.544922 
	C2262.548096,3197.656982 2259.019043,3195.283447 2257.599609,3189.773926 
	C2262.082031,3178.876953 2261.833984,3167.752197 2264.308105,3157.177734 
	C2265.003662,3154.206299 2264.143555,3150.824463 2260.267578,3150.438232 
	C2256.114258,3150.024414 2255.565186,3153.486084 2255.380371,3156.467041 
	C2254.724609,3167.037354 2254.771484,3177.594727 2256.827637,3188.769775 
	C2256.065186,3191.079590 2254.785889,3191.825195 2252.464355,3192.257080 
	C2246.761230,3194.709961 2243.587158,3192.957520 2241.784424,3188.083496 
	C2240.665771,3185.058838 2239.641846,3181.999023 2237.979004,3178.536621 
	C2231.004639,3170.951172 2231.561279,3159.316650 2239.298096,3152.714600 
	C2242.820557,3149.708984 2244.424805,3147.072021 2242.276367,3142.117676 
	C2242.134277,3141.445557 2242.094238,3140.777344 2241.998047,3140.457031 
	C2246.192383,3137.497803 2249.062988,3133.493896 2252.056641,3129.602051 
	C2257.292969,3122.794678 2263.574707,3117.018311 2270.143311,3111.554443 
	C2274.569580,3107.873291 2279.225342,3105.261963 2285.355469,3108.067383 
	C2287.322998,3108.967773 2289.878418,3108.583740 2292.968750,3108.977539 
	C2301.401611,3109.750977 2303.662109,3113.765869 2300.779785,3121.529785 
	C2300.354492,3122.263672 2300.211182,3122.141602 2300.236328,3122.232422 
	C2299.577881,3122.520020 2299.063477,3122.928955 2298.916016,3124.026367 
	C2298.845215,3125.297607 2298.577148,3126.093750 2298.101807,3127.485352 
	C2298.168945,3128.389648 2298.443115,3128.698730 2298.103516,3129.097168 
	C2297.196533,3129.843262 2296.902832,3130.500244 2296.420410,3131.662842 
	C2293.815918,3136.690674 2295.224121,3142.116211 2292.307129,3147.210693 
	C2291.339111,3149.409912 2290.876953,3151.037842 2290.360352,3153.306396 
	C2290.695801,3154.779297 2291.020752,3155.636230 2291.547119,3157.158203 
	C2291.902588,3158.253906 2291.981445,3158.712402 2292.054443,3159.869629 
	C2291.982666,3161.028076 2291.917236,3161.488037 2291.681152,3162.620605 
	C2290.264160,3167.182129 2288.520020,3170.958496 2288.789307,3175.893555 
	C2288.312744,3178.899658 2287.862305,3181.208496 2288.033203,3184.296875 
	C2288.424561,3187.008301 2287.710205,3188.796387 2286.817383,3191.284912 
z"
        />
        <path
          fill="#F5F1E5"
          opacity="1.000000"
          stroke="none"
          d="
M2241.655029,3141.971191 
	C2246.990723,3141.834717 2248.121582,3144.949951 2247.234863,3149.009521 
	C2246.593750,3151.944092 2246.807129,3156.148193 2241.678955,3155.164307 
	C2238.312744,3154.518311 2237.923340,3157.050293 2237.385010,3159.202637 
	C2235.940674,3164.976562 2236.139648,3170.846680 2237.023438,3177.417725 
	C2237.703613,3182.721191 2237.980957,3187.327393 2238.357910,3191.925537 
	C2238.710449,3196.228027 2236.528320,3197.103516 2232.893066,3196.113525 
	C2228.522705,3194.923584 2224.916016,3192.660889 2223.569824,3188.116943 
	C2222.545898,3184.661133 2222.053711,3181.047852 2221.144775,3176.692871 
	C2221.592041,3173.351074 2222.219238,3170.821533 2223.053711,3167.538574 
	C2229.232666,3158.689209 2235.204102,3150.592773 2241.655029,3141.971191 
z"
        />
        <path
          fill="#F2EBDA"
          opacity="1.000000"
          stroke="none"
          d="
M2251.747559,3193.023438 
	C2253.013916,3191.550049 2254.344971,3190.828613 2256.376465,3189.971191 
	C2260.078857,3192.500244 2264.514404,3193.359375 2266.977295,3197.771484 
	C2267.572998,3200.750000 2269.445312,3204.485352 2266.671631,3205.437500 
	C2262.493896,3206.871094 2258.279297,3204.592285 2254.901855,3201.859375 
	C2252.477539,3199.898682 2252.021484,3196.791016 2251.747559,3193.023438 
z"
        />
        <path
          fill="#FAF7E7"
          opacity="1.000000"
          stroke="none"
          d="
M2196.160156,3182.580566 
	C2195.633057,3181.159912 2195.637207,3180.226807 2195.787354,3178.606934 
	C2200.577881,3177.855225 2202.666016,3182.663330 2207.467773,3183.587402 
	C2208.155273,3183.564697 2208.795654,3183.967773 2209.158691,3184.069336 
	C2209.938965,3186.268555 2210.045166,3188.487305 2207.080322,3188.161865 
	C2203.092041,3187.724121 2199.472412,3186.155518 2196.160156,3182.580566 
z"
        />
        <path
          fill="#1D1712"
          opacity="1.000000"
          stroke="none"
          d="
M1612.175659,2643.216553 
	C1608.600220,2643.933594 1608.278809,2647.006836 1606.514404,2649.567383 
	C1604.883667,2641.635254 1608.957642,2635.002686 1613.387451,2628.605469 
	C1614.769043,2633.196533 1613.849121,2637.835938 1612.175659,2643.216553 
z"
        />
        <path
          fill="#5E4237"
          opacity="1.000000"
          stroke="none"
          d="
M1673.876709,2624.971191 
	C1675.085327,2623.860596 1676.327026,2623.542969 1677.526245,2623.107910 
	C1678.845581,2622.629639 1680.074829,2620.336670 1681.485840,2622.303223 
	C1682.447388,2623.643066 1681.277832,2625.363770 1680.290771,2627.362305 
	C1679.421387,2628.687988 1678.666138,2629.375244 1678.219849,2630.224854 
	C1677.631348,2631.344482 1677.450562,2632.964111 1675.808838,2632.800293 
	C1674.063110,2632.626221 1674.210815,2630.946045 1674.066895,2629.691895 
	C1673.916016,2628.375977 1673.942383,2627.040039 1673.876709,2624.971191 
z"
        />
        <path
          fill="#704A3A"
          opacity="1.000000"
          stroke="none"
          d="
M1597.300293,2645.771240 
	C1596.727051,2645.823730 1596.379883,2645.592285 1596.343262,2645.319580 
	C1596.178589,2644.089355 1595.664429,2642.081055 1597.106567,2642.075195 
	C1598.246460,2642.070557 1597.939087,2644.194824 1597.300293,2645.771240 
z"
        />
        <path
          fill="#5E4237"
          opacity="1.000000"
          stroke="none"
          d="
M1676.048096,2666.067383 
	C1675.858765,2666.150146 1675.660645,2667.617920 1675.602051,2666.020020 
	C1675.848389,2665.877197 1675.985962,2665.999512 1676.048096,2666.067383 
z"
        />
        <path
          fill="#704A3A"
          opacity="1.000000"
          stroke="none"
          d="
M1592.983521,2640.926025 
	C1593.057129,2640.885010 1592.909912,2640.967041 1592.983521,2640.926025 
z"
        />
        <path
          fill="#1D1712"
          opacity="1.000000"
          stroke="none"
          d="
M1618.936279,2642.915039 
	C1619.052002,2642.845703 1618.820435,2642.984131 1618.936279,2642.915039 
z"
        />
        <path
          fill="#3A261C"
          opacity="1.000000"
          stroke="none"
          d="
M1699.999268,2642.000000 
	C1699.762207,2641.895996 1700.041870,2643.049316 1699.753174,2642.102051 
	C1700.004883,2642.000000 1700.000000,2642.000000 1699.999268,2642.000000 
z"
        />
        <path
          fill="#564031"
          opacity="1.000000"
          stroke="none"
          d="
M1696.947266,2724.934570 
	C1697.036011,2724.885986 1696.858643,2724.983398 1696.947266,2724.934570 
z"
        />
        <path
          fill="#6C5246"
          opacity="1.000000"
          stroke="none"
          d="
M1534.003906,2585.997559 
	C1533.537109,2585.354492 1533.066162,2584.713867 1532.595337,2584.072998 
	C1532.820190,2583.912354 1533.045044,2583.751953 1533.270020,2583.591553 
	C1533.487793,2584.101074 1533.705566,2584.610596 1533.957520,2585.564941 
	C1533.991821,2586.009766 1534.000000,2586.000000 1534.003906,2585.997559 
z"
        />
        <path
          fill="#8C5B43"
          opacity="1.000000"
          stroke="none"
          d="
M1522.019653,2596.021240 
	C1522.246094,2595.229004 1522.457397,2594.420898 1522.668701,2593.613037 
	C1524.033081,2594.206299 1523.207153,2594.858643 1522.345093,2595.742676 
	C1521.970703,2595.983643 1522.004639,2596.005615 1522.019653,2596.021240 
z"
        />
        <path
          fill="#3A261C"
          opacity="1.000000"
          stroke="none"
          d="
M1529.997559,2636.000000 
	C1529.760010,2635.890869 1530.056763,2637.055176 1529.757935,2636.101074 
	C1530.004883,2636.000000 1530.000000,2636.000000 1529.997559,2636.000000 
z"
        />
        <path
          fill="#959693"
          opacity="1.000000"
          stroke="none"
          d="
M1831.595215,2747.556152 
	C1830.912964,2750.391846 1829.799316,2752.713867 1828.658325,2755.092529 
	C1825.370117,2754.428223 1824.888794,2751.750732 1824.025635,2748.675781 
	C1826.087524,2745.393311 1828.554199,2745.677490 1831.595215,2747.556152 
z"
        />
        <path
          fill="#71889C"
          opacity="1.000000"
          stroke="none"
          d="
M1895.300415,2712.026855 
	C1895.128784,2716.515625 1892.358887,2716.552734 1888.463623,2714.515137 
	C1889.698364,2711.730469 1892.193604,2712.072998 1895.300415,2712.026855 
z"
        />
        <path
          fill="#A9ACA9"
          opacity="1.000000"
          stroke="none"
          d="
M1863.790161,2835.304688 
	C1864.367798,2837.492432 1864.042847,2839.679443 1864.972412,2840.601318 
	C1870.213257,2845.797852 1867.027344,2849.984131 1864.101685,2855.152344 
	C1855.776855,2849.591309 1852.142700,2841.464600 1848.518311,2833.677490 
	C1846.962280,2830.334717 1846.148315,2828.006348 1842.026367,2827.855957 
	C1836.813721,2827.665283 1833.539551,2824.628174 1832.044922,2818.880859 
	C1833.449097,2813.498047 1838.354492,2814.719971 1841.891846,2812.165039 
	C1850.193604,2818.598633 1857.453735,2826.090088 1863.790161,2835.304688 
z"
        />
        <path
          fill="#A9ACA9"
          opacity="1.000000"
          stroke="none"
          d="
M1823.892822,2803.457031 
	C1822.188354,2803.840332 1820.681519,2803.257812 1820.323975,2800.570312 
	C1822.036255,2800.229736 1823.397827,2800.963623 1823.892822,2803.457031 
z"
        />
        <path
          fill="#E9E1D0"
          opacity="1.000000"
          stroke="none"
          d="
M1732.292725,2786.589844 
	C1735.909546,2778.608887 1739.946167,2777.652100 1743.414429,2784.217285 
	C1745.492065,2788.150146 1750.671387,2786.694092 1751.354492,2790.490723 
	C1751.916260,2793.612793 1751.014038,2796.973877 1752.328003,2800.115723 
	C1753.151245,2802.083984 1754.516968,2803.337402 1757.295410,2803.906250 
	C1758.029541,2804.877930 1758.063843,2805.755615 1758.095337,2807.292480 
	C1758.054688,2808.859131 1758.036255,2809.767578 1758.060303,2811.357910 
	C1758.052002,2816.695801 1758.105835,2821.354980 1757.958862,2826.007812 
	C1757.889160,2828.216309 1758.320679,2831.158936 1754.998657,2831.346191 
	C1752.065552,2831.510986 1751.087891,2828.793457 1750.668579,2826.641357 
	C1749.378174,2820.018799 1745.386230,2814.564209 1743.222412,2808.345703 
	C1740.608643,2800.834229 1736.395264,2794.139160 1732.292725,2786.589844 
z"
        />
        <path
          fill="#E9E1D0"
          opacity="1.000000"
          stroke="none"
          d="
M1741.815918,2764.255859 
	C1742.255859,2756.507812 1748.484863,2752.111084 1749.976318,2744.824219 
	C1752.433716,2744.415039 1753.859375,2745.582764 1753.922974,2747.958740 
	C1754.029663,2751.934814 1754.518677,2755.955811 1753.063721,2759.812988 
	C1751.186890,2764.788818 1751.922241,2769.605957 1753.956787,2775.165039 
	C1748.926758,2779.558105 1744.331787,2776.161133 1739.028931,2774.978760 
	C1739.933716,2771.466064 1742.999146,2768.751465 1741.815918,2764.255859 
z"
        />
        <path
          fill="#DAD3C3"
          opacity="1.000000"
          stroke="none"
          d="
M1769.955811,2840.850830 
	C1772.867432,2839.703125 1775.780518,2839.354248 1778.834473,2838.988770 
	C1782.298584,2846.812988 1777.122681,2850.664307 1772.094482,2855.345703 
	C1768.500610,2855.541504 1767.651367,2853.285645 1768.334106,2850.313232 
	C1768.993774,2847.442139 1770.250122,2844.697266 1769.955811,2840.850830 
z"
        />
        <path
          fill="#DAD3C3"
          opacity="1.000000"
          stroke="none"
          d="
M1777.338379,2788.298828 
	C1781.647827,2797.793945 1781.149658,2799.548340 1772.773193,2803.917236 
	C1771.603638,2802.458008 1771.123657,2800.906250 1770.772339,2799.325684 
	C1769.225098,2792.363770 1769.366333,2792.128418 1777.338379,2788.298828 
z"
        />
        <path
          fill="#A6A29C"
          opacity="1.000000"
          stroke="none"
          d="
M1765.525269,2749.749512 
	C1766.137451,2752.095459 1766.159424,2754.172363 1766.235352,2757.020996 
	C1764.949097,2760.246826 1769.770508,2764.023926 1765.402344,2765.287354 
	C1761.682983,2766.363037 1761.387451,2761.880371 1759.941040,2759.572998 
	C1758.531250,2757.324707 1757.878906,2754.799805 1758.074585,2752.106689 
	C1758.218506,2750.126465 1758.413086,2748.014648 1760.697998,2747.419189 
	C1762.473633,2746.956055 1763.536133,2748.736328 1765.525269,2749.749512 
z"
        />
        <path
          fill="#DAD3C3"
          opacity="1.000000"
          stroke="none"
          d="
M1771.203857,2822.102539 
	C1773.493652,2826.893555 1775.388794,2831.920410 1768.633789,2835.686035 
	C1764.822266,2830.700928 1767.439453,2826.449463 1771.203857,2822.102539 
z"
        />
        <path
          fill="#DAD3C3"
          opacity="1.000000"
          stroke="none"
          d="
M1771.564575,2812.374512 
	C1773.974243,2813.484863 1774.344604,2815.222168 1772.411621,2817.627197 
	C1770.020874,2816.510254 1769.661255,2814.772217 1771.564575,2812.374512 
z"
        />
        <path
          fill="#E6E1D0"
          opacity="1.000000"
          stroke="none"
          d="
M1758.488525,2811.720215 
	C1756.726807,2811.150879 1756.045410,2810.093506 1757.650635,2808.261719 
	C1759.354736,2808.869629 1759.894165,2809.956543 1758.488525,2811.720215 
z"
        />
        <path
          fill="#D1C9BA"
          opacity="1.000000"
          stroke="none"
          d="
M1655.463379,2898.541992 
	C1656.022339,2896.464600 1656.111328,2894.978516 1656.231323,2892.748535 
	C1655.259766,2887.184814 1660.064087,2884.968994 1661.563599,2880.538574 
	C1665.802734,2863.296631 1667.567993,2846.189697 1672.162720,2828.842773 
	C1675.226929,2828.433105 1677.785522,2826.891846 1681.246582,2826.113281 
	C1679.375122,2833.925049 1680.127441,2842.077393 1680.026245,2851.071777 
	C1680.215820,2865.565430 1682.112671,2879.243408 1678.930054,2892.656250 
	C1678.131836,2896.020508 1679.350464,2899.288086 1677.980469,2903.217285 
	C1677.824585,2904.874512 1677.798340,2905.756836 1677.729736,2907.300293 
	C1676.867798,2911.696289 1676.371216,2915.510498 1674.566650,2919.613770 
	C1674.102295,2921.584961 1674.025269,2923.041748 1673.940918,2925.226807 
	C1672.247314,2926.124023 1670.345825,2926.352539 1669.434814,2927.780762 
	C1666.106689,2932.998535 1662.489746,2934.077637 1658.250488,2928.192627 
	C1654.508911,2934.592529 1650.842285,2939.988525 1651.856201,2947.092285 
	C1652.150757,2949.157471 1651.120850,2950.938232 1649.373169,2952.239990 
	C1647.734985,2953.460205 1646.032715,2955.015869 1646.272095,2957.096436 
	C1647.052124,2963.879395 1642.164917,2968.725586 1640.822754,2975.422363 
	C1634.676270,2970.940918 1633.907715,2965.969971 1637.092529,2959.982666 
	C1638.818237,2956.738037 1640.398560,2953.416016 1639.986816,2948.766602 
	C1640.236938,2943.724365 1637.254639,2938.997070 1641.957764,2934.731445 
	C1647.118530,2922.697754 1652.557983,2911.470459 1655.463379,2898.541992 
z"
        />
        <path
          fill="#D0C8B8"
          opacity="1.000000"
          stroke="none"
          d="
M1682.865112,2794.968994 
	C1678.467285,2790.596680 1674.872437,2790.937744 1671.624268,2794.856934 
	C1669.705688,2797.172363 1667.760498,2798.075928 1664.438721,2796.045898 
	C1664.140991,2793.047363 1666.126343,2791.893066 1668.249634,2790.855225 
	C1670.631592,2789.690918 1673.080688,2788.593018 1674.054443,2785.062988 
	C1677.965942,2784.033936 1680.835571,2782.197754 1683.393677,2779.446777 
	C1686.035400,2776.605957 1688.800049,2773.809814 1692.946411,2771.728516 
	C1699.535278,2767.486572 1703.988647,2761.888672 1709.395996,2756.341797 
	C1710.408325,2755.518799 1710.915283,2755.099365 1711.515137,2754.083984 
	C1714.088989,2754.117432 1716.272827,2754.909180 1717.806274,2757.845215 
	C1719.223389,2766.295898 1712.771973,2769.903076 1708.436768,2775.464355 
	C1707.909302,2775.956543 1708.001831,2775.999268 1707.976196,2775.955078 
	C1705.795654,2778.505859 1704.774902,2782.185059 1700.446533,2783.582031 
	C1699.917969,2783.956055 1700.000000,2784.000000 1699.978271,2783.959229 
	C1698.596680,2785.646484 1698.098755,2787.952637 1695.690552,2789.656250 
	C1693.113525,2789.942627 1691.727051,2791.022949 1690.231689,2792.894043 
	C1688.407471,2795.765381 1686.191650,2796.114990 1682.865112,2794.968994 
z"
        />
        <path
          fill="#FBF9EC"
          opacity="1.000000"
          stroke="none"
          d="
M1682.099121,2795.176514 
	C1684.195679,2794.256836 1686.296753,2794.014648 1689.167725,2793.560303 
	C1690.586182,2797.185791 1687.077026,2799.190186 1685.861694,2802.950195 
	C1685.497803,2804.094971 1685.276855,2804.558350 1684.527466,2804.461426 
	C1680.087769,2804.429932 1679.700073,2807.058838 1680.337646,2810.191895 
	C1680.751831,2812.227783 1682.476685,2814.438477 1678.635620,2815.896484 
	C1675.181030,2816.617188 1676.438599,2819.721680 1674.301025,2821.466064 
	C1672.910034,2821.899902 1672.079102,2821.940918 1670.624023,2821.990723 
	C1669.230347,2818.359619 1672.313965,2812.980469 1664.734619,2812.023438 
	C1667.384277,2806.543457 1674.378052,2805.843750 1678.419067,2801.086426 
	C1679.835571,2799.418701 1682.113037,2798.499756 1682.099121,2795.176514 
z"
        />
        <path
          fill="#FBF9EC"
          opacity="1.000000"
          stroke="none"
          d="
M1708.609375,2775.898438 
	C1709.283569,2769.350586 1715.085815,2765.488037 1718.002319,2759.139160 
	C1718.854858,2758.213379 1719.630127,2757.938232 1720.982300,2757.439941 
	C1722.452637,2758.142334 1723.985840,2759.524414 1723.206421,2760.411133 
	C1718.638306,2765.607666 1716.173950,2772.730957 1708.609375,2775.898438 
z"
        />
        <path
          fill="#D1C9BA"
          opacity="1.000000"
          stroke="none"
          d="
M1674.159302,2822.230225 
	C1673.646484,2819.631348 1673.647217,2817.394287 1673.425049,2815.179443 
	C1673.263916,2813.572021 1672.758789,2811.487793 1674.812012,2811.345947 
	C1676.388916,2811.237061 1676.781616,2813.552246 1677.770264,2815.459473 
	C1680.116699,2819.907715 1679.953369,2822.604004 1674.159302,2822.230225 
z"
        />
        <path
          fill="#FBF9EC"
          opacity="1.000000"
          stroke="none"
          d="
M1700.678955,2783.975586 
	C1701.811890,2781.031982 1703.430054,2777.871826 1707.301270,2775.997070 
	C1708.248779,2780.593262 1706.845825,2783.906006 1700.678955,2783.975586 
z"
        />
        <path
          fill="#CBC5B5"
          opacity="1.000000"
          stroke="none"
          d="
M1727.251343,2774.267578 
	C1727.802612,2774.374023 1727.871216,2774.855469 1728.001221,2776.072021 
	C1727.747070,2777.728760 1727.598633,2778.761719 1727.075684,2779.544678 
	C1726.331665,2780.658203 1725.021606,2781.708984 1723.786133,2780.931396 
	C1722.131714,2779.890137 1723.439941,2778.717529 1724.271484,2777.718506 
	C1725.116333,2776.703613 1725.937256,2775.668457 1727.251343,2774.267578 
z"
        />
        <path
          fill="#FBF9EC"
          opacity="1.000000"
          stroke="none"
          d="
M1695.875610,2789.883789 
	C1695.992065,2787.896729 1696.743408,2785.759766 1699.358887,2784.197021 
	C1699.986206,2786.518555 1700.150635,2789.193604 1695.875610,2789.883789 
z"
        />
        <path
          fill="#E9E1D0"
          opacity="1.000000"
          stroke="none"
          d="
M1684.060059,2804.539062 
	C1683.999023,2803.901611 1684.869995,2803.825684 1685.294434,2803.728516 
	C1686.416016,2805.630859 1688.438110,2807.264893 1687.148071,2810.436279 
	C1685.237793,2808.989502 1684.523682,2807.194824 1684.060059,2804.539062 
z"
        />
        <path
          fill="#E9E1D0"
          opacity="1.000000"
          stroke="none"
          d="
M1693.287476,2803.142090 
	C1694.775391,2802.726074 1695.314575,2803.247314 1695.084106,2804.402100 
	C1695.036377,2804.641846 1694.695679,2804.964355 1694.453125,2805.000488 
	C1693.286011,2805.174072 1692.797729,2804.605713 1693.287476,2803.142090 
z"
        />
        <path
          fill="#C4BDAE"
          opacity="1.000000"
          stroke="none"
          d="
M1697.887451,2816.087891 
	C1697.609253,2815.820557 1697.423218,2815.469482 1697.237061,2815.118408 
	C1697.435913,2815.187744 1697.634644,2815.256836 1697.990723,2815.573242 
	C1698.148193,2815.820801 1697.979614,2816.003906 1697.887451,2816.087891 
z"
        />
        <path
          fill="#B6AFA3"
          opacity="1.000000"
          stroke="none"
          d="
M1709.116943,2755.904785 
	C1709.817139,2756.454834 1710.120728,2757.281738 1709.833740,2757.735596 
	C1706.101685,2763.638428 1701.997559,2769.184814 1694.346436,2771.646484 
	C1697.038940,2764.905762 1702.275269,2760.009521 1709.116943,2755.904785 
z"
        />
        <path
          fill="#FBF5E5"
          opacity="1.000000"
          stroke="none"
          d="
M1875.149414,2756.191406 
	C1876.745605,2763.459717 1877.896729,2770.649902 1878.323120,2777.882812 
	C1878.581299,2782.260254 1880.703979,2783.711426 1885.251709,2783.968018 
	C1887.685547,2791.639160 1889.129639,2799.571533 1893.080078,2806.251465 
	C1895.400757,2810.175049 1893.501099,2812.017822 1892.130615,2815.345215 
	C1904.638916,2828.683594 1911.905762,2844.904541 1920.008911,2860.384766 
	C1921.832520,2863.868652 1923.646240,2869.565186 1922.749634,2874.446533 
	C1922.008057,2878.484375 1923.849731,2881.534424 1925.516846,2885.481934 
	C1926.132812,2889.560791 1922.744263,2891.491455 1922.151611,2895.323730 
	C1918.411621,2896.026855 1915.101440,2896.276367 1913.343994,2891.509521 
	C1911.282593,2885.918213 1904.701172,2881.926025 1907.633423,2874.608643 
	C1904.097656,2870.370850 1902.111328,2865.319092 1899.872925,2860.363281 
	C1899.270996,2859.030273 1897.006348,2858.150391 1895.429321,2858.753906 
	C1890.685303,2860.567871 1889.331787,2857.149414 1886.814819,2854.348633 
	C1881.361206,2848.280029 1880.802856,2839.641846 1875.225952,2833.739258 
	C1874.050415,2832.495361 1874.423584,2830.816650 1874.989380,2829.078613 
	C1877.653931,2820.893311 1877.130737,2819.125732 1870.424438,2812.903320 
	C1868.173950,2810.814941 1867.224609,2808.118408 1868.601440,2805.734375 
	C1870.836548,2801.863770 1871.737549,2799.679443 1867.229980,2796.106201 
	C1864.678955,2794.083740 1864.171753,2788.258301 1865.154175,2783.923340 
	C1865.727417,2781.394287 1865.701172,2779.306152 1863.528931,2777.680420 
	C1858.607544,2773.997314 1857.719360,2769.354736 1859.880127,2763.770996 
	C1860.332886,2762.600830 1860.008057,2761.129395 1860.034668,2758.984863 
	C1860.407227,2757.597168 1860.835571,2757.060059 1861.611084,2756.140137 
	C1862.274536,2755.653809 1862.633179,2755.583984 1863.539429,2755.494385 
	C1865.301880,2757.515137 1867.140503,2755.817139 1869.257568,2756.327881 
	C1871.391968,2756.326416 1872.895508,2756.229004 1875.149414,2756.191406 
z"
        />
        <path
          fill="#DBD5C8"
          opacity="1.000000"
          stroke="none"
          d="
M1897.840332,2881.372070 
	C1891.480835,2882.388916 1891.906494,2877.890137 1891.958496,2872.849365 
	C1897.211182,2872.666748 1896.133423,2877.587158 1897.840332,2881.372070 
z"
        />
        <path
          fill="#DBD5C8"
          opacity="1.000000"
          stroke="none"
          d="
M1900.568237,2895.550781 
	C1897.513916,2893.982422 1897.595947,2891.543945 1899.438721,2888.451416 
	C1902.473999,2890.020752 1902.407471,2892.458740 1900.568237,2895.550781 
z"
        />
        <path
          fill="#DFD9CC"
          opacity="1.000000"
          stroke="none"
          d="
M1861.548340,2755.580566 
	C1861.583252,2756.340820 1861.250488,2756.958008 1860.472900,2757.874268 
	C1860.071289,2757.680176 1860.114746,2757.187012 1860.290039,2755.957764 
	C1860.421875,2755.221436 1861.180664,2755.437500 1861.548340,2755.580566 
z"
        />
        <path
          fill="#EAE5D5"
          opacity="1.000000"
          stroke="none"
          d="
M1926.199951,2885.431152 
	C1920.561523,2885.336426 1917.740479,2880.834229 1918.832886,2877.034668 
	C1920.128662,2872.526611 1921.570190,2870.170654 1915.660278,2868.993164 
	C1913.965576,2868.655518 1914.162842,2866.291504 1915.494751,2864.879395 
	C1916.540405,2863.770996 1918.809448,2863.963623 1918.884644,2861.124512 
	C1915.181274,2858.609619 1912.176758,2855.736328 1911.240967,2850.077393 
	C1910.223877,2843.926758 1903.455200,2839.541748 1903.424927,2832.314941 
	C1903.419678,2831.083496 1901.688843,2830.601074 1900.203247,2831.033936 
	C1898.002075,2831.675293 1895.770386,2832.569092 1892.914185,2830.589111 
	C1894.521851,2828.000732 1899.442993,2824.771240 1892.859375,2822.149658 
	C1892.081665,2821.839844 1892.266846,2819.111572 1892.015137,2816.742432 
	C1899.817261,2814.972900 1905.046997,2816.966309 1907.203125,2825.739990 
	C1908.659668,2831.666748 1911.992188,2837.394775 1919.247925,2839.941650 
	C1919.965698,2840.905518 1919.995361,2841.774658 1920.035156,2843.295898 
	C1924.828369,2847.005615 1923.184204,2853.460938 1927.435547,2857.553223 
	C1927.920410,2861.068359 1928.401978,2863.888184 1931.249268,2865.625244 
	C1933.088989,2866.748047 1933.806763,2868.427246 1933.892212,2871.200684 
	C1929.295410,2872.192627 1925.058960,2872.761963 1927.473633,2879.250488 
	C1928.209106,2881.226807 1927.465454,2883.052490 1926.199951,2885.431152 
z"
        />
        <path
          fill="#D3CBBF"
          opacity="1.000000"
          stroke="none"
          d="
M1959.897949,2911.757324 
	C1959.784180,2911.749268 1959.568359,2911.498535 1959.352539,2911.247803 
	C1959.500366,2911.336914 1959.648071,2911.425781 1959.897949,2911.757324 
z"
        />
        <path
          fill="#DBD5C8"
          opacity="1.000000"
          stroke="none"
          d="
M1864.025146,2868.762695 
	C1873.067871,2870.557617 1879.342896,2879.299805 1880.077515,2891.079102 
	C1872.400024,2893.285156 1872.229004,2893.288574 1872.088989,2886.437988 
	C1871.992065,2881.687744 1872.048584,2877.301514 1866.281738,2875.483398 
	C1863.620117,2874.644287 1864.149048,2871.788574 1864.025146,2868.762695 
z"
        />
        <path
          fill="#935F47"
          opacity="1.000000"
          stroke="none"
          d="
M2410.057617,3107.263184 
	C2410.059082,3110.145508 2410.057129,3112.271484 2410.062988,3115.194824 
	C2408.248779,3122.178467 2413.248047,3128.570312 2408.752441,3135.037109 
	C2406.882812,3135.569824 2405.788330,3135.492920 2404.005859,3134.697266 
	C2399.973633,3129.462891 2400.720947,3123.672363 2399.305664,3117.626465 
	C2398.577393,3108.206543 2398.719971,3099.600342 2400.920898,3090.187256 
	C2397.909424,3095.766846 2394.384766,3099.540283 2388.429443,3097.765137 
	C2369.014404,3091.978027 2348.610596,3090.591064 2328.606445,3084.270508 
	C2325.110107,3083.539795 2322.330078,3082.837158 2318.815430,3082.003906 
	C2318.081299,3081.873291 2317.922119,3081.096191 2317.850098,3080.706299 
	C2314.254150,3076.815674 2309.630859,3076.529053 2304.398926,3075.941406 
	C2302.764648,3075.793213 2301.873291,3075.530518 2300.431641,3074.739258 
	C2298.475342,3074.191895 2297.113525,3074.022705 2295.081055,3073.750000 
	C2292.249268,3068.293945 2290.719971,3062.880859 2293.359863,3056.567139 
	C2297.526123,3056.343994 2300.802734,3058.430664 2304.605225,3058.093750 
	C2307.121094,3057.870850 2309.119629,3058.830566 2310.207031,3061.338379 
	C2310.750000,3062.590576 2311.758545,3063.832764 2313.167236,3063.677979 
	C2324.449463,3062.440186 2334.705078,3071.424561 2346.295654,3066.961182 
	C2348.005859,3066.302734 2349.572021,3066.931152 2350.935791,3067.998535 
	C2353.321045,3069.864258 2355.045410,3069.224365 2356.735596,3066.893799 
	C2359.066895,3063.678955 2362.232422,3063.904785 2365.719971,3064.873291 
	C2371.484375,3066.474365 2377.321777,3067.672119 2382.001465,3071.885010 
	C2385.500000,3075.035400 2388.511475,3074.896729 2390.409180,3069.945068 
	C2390.849365,3068.796631 2391.920166,3067.207031 2392.893799,3067.054688 
	C2400.312012,3065.894531 2401.354980,3057.827881 2406.758057,3054.500977 
	C2408.028320,3053.718506 2409.386719,3051.777344 2409.354980,3050.400391 
	C2409.188965,3043.207031 2414.310791,3039.852051 2419.225586,3036.612305 
	C2422.333984,3034.562988 2423.765381,3032.166504 2424.309570,3028.613525 
	C2424.807129,3025.367188 2426.026123,3022.231689 2427.453857,3018.541016 
	C2430.726807,3020.438477 2430.829102,3023.687744 2430.830566,3027.748535 
	C2432.722412,3036.639160 2430.663574,3044.047363 2428.014404,3051.800049 
	C2421.810303,3069.956543 2412.938477,3087.262939 2410.057617,3107.263184 
z"
        />
        <path
          fill="#1B2B3A"
          opacity="1.000000"
          stroke="none"
          d="
M2401.364014,3115.598145 
	C2402.714355,3121.486328 2403.549072,3126.778564 2404.608887,3132.867676 
	C2405.457031,3134.950195 2406.482178,3135.967285 2407.444824,3137.869629 
	C2408.001221,3139.995605 2407.913330,3141.418701 2406.941650,3143.377686 
	C2403.037109,3144.024902 2401.209717,3141.920166 2399.463379,3139.240967 
	C2397.357422,3136.010498 2393.619629,3133.233154 2390.260498,3134.631592 
	C2382.086182,3138.034912 2376.507080,3131.673340 2369.658936,3130.407227 
	C2368.428223,3130.179688 2367.364990,3128.630615 2366.189209,3128.584717 
	C2345.677979,3127.783691 2326.259277,3119.350098 2304.645508,3119.875488 
	C2302.005127,3117.098145 2303.046631,3114.915039 2305.762207,3112.617676 
	C2308.245361,3110.975830 2310.033936,3109.679199 2313.062256,3109.655029 
	C2325.276367,3113.791016 2337.470947,3115.314697 2349.402832,3117.865479 
	C2356.734375,3119.432861 2363.844971,3123.022705 2371.955322,3121.143555 
	C2364.745850,3119.758057 2359.627441,3115.395264 2354.538330,3109.854248 
	C2361.132080,3105.539551 2368.612793,3109.993652 2375.390625,3106.277588 
	C2386.558838,3102.563477 2393.780762,3108.576660 2401.364014,3115.598145 
z"
        />
        <path
          fill="#704A3A"
          opacity="1.000000"
          stroke="none"
          d="
M2401.948486,3115.263672 
	C2393.449707,3113.433594 2386.398193,3107.418701 2376.692871,3105.754150 
	C2369.512451,3099.492676 2360.556641,3099.001709 2352.989990,3095.400146 
	C2345.062988,3091.626953 2335.718994,3091.273682 2328.263428,3084.824463 
	C2329.209961,3082.281250 2331.036621,3082.061035 2333.147949,3082.649414 
	C2351.607422,3087.794189 2371.091797,3088.411377 2389.152832,3095.364990 
	C2392.302734,3096.577637 2394.828125,3095.947266 2396.287354,3092.242920 
	C2397.783447,3088.444092 2399.901855,3084.890381 2402.791504,3079.160156 
	C2405.878174,3092.454346 2400.144531,3103.373779 2401.948486,3115.263672 
z"
        />
        <path
          fill="#704A3A"
          opacity="1.000000"
          stroke="none"
          d="
M2303.755859,3074.782227 
	C2308.850098,3074.802002 2314.033447,3074.492676 2317.712646,3079.571777 
	C2314.347168,3079.592041 2310.916016,3078.867920 2306.726074,3078.098633 
	C2305.088867,3077.217529 2303.957764,3076.609131 2303.755859,3074.782227 
z"
        />
        <path
          fill="#5E4237"
          opacity="1.000000"
          stroke="none"
          d="
M2303.282715,3074.156738 
	C2304.500000,3075.151367 2305.026611,3076.097900 2305.760254,3077.548828 
	C2303.755615,3077.935547 2301.376709,3078.214111 2300.024658,3074.990723 
	C2300.755615,3074.277100 2301.674072,3074.192871 2303.282715,3074.156738 
z"
        />
        <path
          fill="#5E4237"
          opacity="1.000000"
          stroke="none"
          d="
M2318.068359,3081.955078 
	C2317.885742,3081.819092 2318.089844,3083.090820 2317.820312,3081.988281 
	C2318.081299,3081.873291 2318.009521,3081.991943 2318.068359,3081.955078 
z"
        />
        <path
          fill="#718A41"
          opacity="1.000000"
          stroke="none"
          d="
M2306.251953,3111.560059 
	C2305.633057,3114.378174 2304.787842,3116.436035 2303.835449,3119.151855 
	C2303.728271,3119.809814 2303.804932,3119.927979 2303.873047,3119.911621 
	C2303.644531,3120.236084 2303.347656,3120.577393 2302.636475,3121.456055 
	C2302.222412,3121.993652 2301.293213,3122.170654 2300.823730,3122.217285 
	C2300.692139,3117.050049 2299.313965,3112.735107 2293.958008,3109.786865 
	C2293.784912,3108.628662 2293.797607,3108.090088 2293.784668,3106.743408 
	C2298.316406,3106.375732 2303.872070,3104.298584 2306.251953,3111.560059 
z"
        />
        <path
          fill="#C88655"
          opacity="1.000000"
          stroke="none"
          d="
M2351.170410,2962.023438 
	C2352.237061,2965.207520 2354.515381,2966.868896 2357.164307,2967.317627 
	C2363.854492,2968.451660 2368.025879,2972.271729 2372.202881,2977.549805 
	C2379.458252,2986.718506 2389.344727,2991.009277 2401.580566,2990.765869 
	C2407.286621,2990.652344 2412.616943,2990.263428 2417.964355,2988.502441 
	C2419.230225,2988.085693 2420.802979,2988.396729 2420.913818,2990.142822 
	C2420.965576,2990.961182 2420.309082,2992.470215 2419.750244,2992.585938 
	C2408.593262,2994.894531 2409.599365,3007.103027 2403.115234,3013.434082 
	C2400.519775,3015.968506 2399.008057,3020.226562 2395.442627,3022.889160 
	C2393.249268,3024.527588 2392.047607,3027.757080 2390.901611,3030.483154 
	C2387.971191,3037.454834 2388.053955,3037.575439 2381.441895,3034.937744 
	C2378.194336,3033.641846 2374.893555,3033.974609 2371.589355,3034.122314 
	C2368.674805,3034.252441 2365.449463,3036.057373 2365.391602,3038.336426 
	C2365.243896,3044.144531 2357.809326,3045.974609 2358.866699,3052.682373 
	C2359.352295,3055.763184 2354.957520,3056.527100 2351.856445,3054.554443 
	C2349.881348,3053.298828 2347.580566,3051.246582 2345.761719,3053.823975 
	C2341.977783,3059.185547 2338.551514,3056.613525 2334.977295,3053.944336 
	C2331.050293,3051.011475 2327.135254,3049.353027 2322.052246,3051.503418 
	C2318.924561,3052.826904 2315.549561,3051.735352 2312.420898,3050.216309 
	C2308.229980,3048.181641 2303.886475,3046.490723 2298.361328,3045.946289 
	C2297.372559,3041.070801 2300.047363,3037.272217 2302.552734,3032.585449 
	C2304.180176,3031.931641 2305.446045,3031.970703 2307.360840,3032.004883 
	C2313.115479,3027.541016 2318.799805,3028.876221 2323.988281,3031.192139 
	C2337.027588,3037.011475 2348.709961,3032.482178 2360.472412,3027.346191 
	C2363.345215,3026.091797 2364.355225,3023.944824 2364.355957,3021.026611 
	C2364.356445,3018.304443 2364.988037,3015.871094 2366.767822,3013.611572 
	C2368.652344,3011.219238 2373.886963,3011.004150 2372.296387,3006.431396 
	C2370.754883,3001.999512 2368.168213,2997.999023 2363.764893,2995.720947 
	C2357.547852,2992.504639 2351.396484,2988.956787 2343.886475,2990.995605 
	C2341.825684,2991.555176 2339.935791,2990.954590 2338.230957,2988.626465 
	C2337.735596,2983.949707 2338.183350,2979.906250 2340.852783,2976.830566 
	C2344.233643,2972.935059 2344.093018,2968.450195 2344.199219,2963.065918 
	C2346.306152,2962.181885 2348.353027,2962.104492 2351.170410,2962.023438 
z"
        />
        <path
          fill="#CC855B"
          opacity="1.000000"
          stroke="none"
          d="
M2337.277832,2988.077393 
	C2339.506836,2988.478516 2341.405029,2990.179443 2342.585693,2988.896973 
	C2346.129639,2985.047363 2349.970215,2986.698242 2353.544189,2988.031250 
	C2362.115479,2991.227295 2371.646729,2993.442627 2374.346924,3004.207520 
	C2375.020996,3006.894043 2378.726074,3007.342285 2378.781982,3010.326416 
	C2377.157959,3012.724609 2374.451660,3011.869873 2372.406494,3012.819824 
	C2368.464355,3014.650635 2365.592285,3016.448486 2367.464600,3021.812744 
	C2369.200439,3026.786133 2367.479492,3029.930908 2361.369385,3030.272705 
	C2357.162842,3030.508301 2353.470459,3033.333252 2349.453125,3034.781006 
	C2339.998779,3038.187988 2331.272949,3038.409912 2322.468018,3033.350098 
	C2318.495117,3031.066895 2313.995605,3032.254639 2308.865479,3032.000000 
	C2308.035889,3030.010254 2307.977539,3027.986328 2309.149902,3026.242676 
	C2317.400146,3013.971191 2325.647217,3001.697266 2333.982178,2989.483398 
	C2334.461182,2988.781494 2335.700195,2988.598389 2337.277832,2988.077393 
z"
        />
        <path
          fill="#EEE7D5"
          opacity="1.000000"
          stroke="none"
          d="
M1410.431641,2936.461426 
	C1407.613403,2930.991943 1408.462036,2925.731201 1412.135254,2922.408203 
	C1416.442627,2918.511230 1418.469604,2913.201660 1422.391113,2909.204102 
	C1424.761719,2906.787598 1425.376343,2903.428223 1425.951538,2900.232178 
	C1426.437012,2897.534668 1427.760132,2896.142334 1431.242432,2896.037109 
	C1430.738159,2901.789062 1428.228027,2907.124023 1427.409790,2913.196289 
	C1426.676636,2918.635254 1427.718750,2921.307129 1432.347534,2923.275635 
	C1433.528076,2923.778076 1434.676270,2924.523438 1434.743286,2926.372559 
	C1432.967896,2928.976074 1430.056274,2927.878662 1427.610718,2927.926514 
	C1421.253540,2928.050293 1415.647949,2929.126221 1413.370117,2936.228271 
	C1413.060181,2937.195068 1411.893555,2937.386963 1410.431641,2936.461426 
z"
        />
        <path
          fill="#EEE7D5"
          opacity="1.000000"
          stroke="none"
          d="
M1394.651855,2959.707031 
	C1394.213501,2954.507812 1391.860718,2949.078613 1393.853027,2943.515137 
	C1394.353149,2942.117920 1394.170532,2940.089844 1392.425415,2939.217285 
	C1390.577393,2938.292969 1389.403687,2939.863525 1388.174072,2940.868652 
	C1386.405273,2942.314941 1384.785522,2944.308350 1382.165283,2943.386475 
	C1379.921509,2942.596924 1378.110107,2940.739746 1377.368530,2938.705811 
	C1376.424927,2936.118164 1380.139893,2936.383057 1380.810547,2934.803223 
	C1382.757080,2930.217773 1385.589600,2930.199463 1389.242554,2932.602783 
	C1393.838135,2935.626709 1398.949707,2936.452148 1405.178711,2936.013672 
	C1405.730957,2938.862305 1406.288818,2941.548340 1403.368286,2944.021484 
	C1398.837036,2947.858643 1399.192871,2948.278809 1407.244019,2950.013672 
	C1408.763794,2950.794922 1409.052002,2951.710449 1408.439575,2953.385498 
	C1403.841797,2955.976807 1399.881470,2958.416992 1394.651855,2959.707031 
z"
        />
        <path
          fill="#EEE7D5"
          opacity="1.000000"
          stroke="none"
          d="
M1421.541016,3019.470215 
	C1421.930054,3022.396729 1422.177246,3024.680176 1423.720825,3027.385742 
	C1420.577515,3031.431396 1417.546753,3029.677490 1414.307861,3027.528809 
	C1411.665894,3025.775635 1408.988037,3023.203857 1405.369629,3025.714111 
	C1403.735718,3026.847656 1401.586182,3028.327637 1400.563599,3025.578369 
	C1399.538452,3022.822266 1399.210571,3019.392822 1403.189087,3018.280762 
	C1409.168823,3016.609375 1415.281494,3013.945557 1421.541016,3019.470215 
z"
        />
        <path
          fill="#EEE7D5"
          opacity="1.000000"
          stroke="none"
          d="
M1447.973267,3030.717773 
	C1452.128052,3029.341797 1456.270874,3028.673828 1461.211670,3028.002930 
	C1462.010498,3029.500000 1462.011230,3031.000000 1462.008423,3033.250000 
	C1459.044922,3034.648438 1453.970337,3032.068359 1455.102783,3038.851562 
	C1455.584717,3041.738037 1453.291870,3041.838379 1451.202515,3041.428223 
	C1449.286865,3041.052002 1447.404419,3040.506592 1444.756104,3040.018066 
	C1443.938354,3036.502686 1448.501587,3035.141602 1447.973267,3030.717773 
z"
        />
        <path
          fill="#E9E1D0"
          opacity="1.000000"
          stroke="none"
          d="
M1408.703003,2953.990234 
	C1408.000977,2953.116211 1408.001953,2952.232666 1408.009033,2950.686523 
	C1410.839966,2950.024170 1413.664795,2950.024170 1417.079590,2950.024170 
	C1415.328979,2953.174316 1412.505005,2953.808838 1408.703003,2953.990234 
z"
        />
        <path
          fill="#EEE7D5"
          opacity="1.000000"
          stroke="none"
          d="
M1443.333252,3040.000488 
	C1443.801758,3041.275635 1443.694336,3042.729492 1442.093506,3042.959473 
	C1440.973145,3043.120605 1440.462036,3042.159424 1440.078003,3040.603516 
	C1440.875977,3040.019775 1441.769531,3040.010498 1443.333252,3040.000488 
z"
        />
        <path
          fill="#EEE7D5"
          opacity="1.000000"
          stroke="none"
          d="
M1338.004883,2880.004883 
	C1344.457886,2878.288818 1350.322266,2875.870605 1352.443848,2868.609375 
	C1352.673340,2867.824219 1353.942017,2866.574219 1354.290649,2866.705566 
	C1363.165161,2870.041016 1366.275513,2860.459473 1373.385132,2858.284668 
	C1373.954102,2860.394287 1374.263062,2862.595459 1376.213989,2864.344971 
	C1377.306763,2865.324707 1377.536743,2867.377441 1376.445190,2867.831299 
	C1371.166138,2870.024658 1370.247192,2877.729980 1363.591797,2877.925537 
	C1358.470947,2878.076172 1354.680542,2880.123291 1352.453003,2884.853271 
	C1351.317871,2887.263672 1349.188599,2888.158936 1346.706543,2887.934082 
	C1342.287476,2887.533447 1339.551392,2890.520752 1336.081543,2892.376465 
	C1330.701172,2895.253906 1327.436890,2899.150879 1325.535522,2904.753174 
	C1324.813843,2906.879639 1323.271362,2908.773926 1321.902344,2910.625977 
	C1318.488525,2915.244141 1310.823120,2911.450928 1308.208862,2917.058350 
	C1305.659180,2922.527100 1300.658081,2923.671875 1296.153198,2926.375488 
	C1290.976929,2929.481934 1285.568848,2932.372559 1280.936035,2937.211670 
	C1275.664673,2942.718506 1269.630371,2949.623779 1261.243530,2951.058838 
	C1252.847656,2952.495361 1247.361206,2958.544678 1241.200928,2962.962891 
	C1236.522583,2966.318359 1230.414429,2967.912354 1226.876343,2973.129639 
	C1225.773560,2974.755371 1223.153687,2973.546875 1220.693604,2972.342285 
	C1220.057617,2970.070068 1219.627930,2967.593506 1221.247314,2966.393799 
	C1230.302124,2959.686768 1237.398071,2950.063721 1250.345581,2949.305908 
	C1257.998291,2948.858154 1264.424561,2942.993164 1269.574341,2938.257812 
	C1278.142578,2930.378174 1288.383667,2925.047363 1296.952148,2917.418945 
	C1300.581299,2914.188232 1305.427002,2912.084229 1307.501953,2906.440918 
	C1316.064209,2895.788330 1327.929688,2889.998047 1337.522217,2880.508301 
	C1338.004883,2880.009766 1338.000000,2880.000000 1338.004883,2880.004883 
z"
        />
        <path
          fill="#EEE7D5"
          opacity="1.000000"
          stroke="none"
          d="
M1186.830200,2992.016113 
	C1185.793335,2990.313721 1184.949219,2988.997314 1183.820923,2987.707031 
	C1181.375732,2984.910156 1182.064453,2982.554688 1185.714478,2982.217041 
	C1194.180298,2981.434570 1201.533081,2978.259766 1208.226562,2973.177246 
	C1210.331787,2971.578613 1212.606567,2972.239990 1215.372803,2973.737793 
	C1214.994629,2976.177002 1213.536743,2978.210205 1211.412231,2979.006348 
	C1206.736450,2980.759277 1202.244141,2981.894287 1207.260010,2988.231445 
	C1208.635010,2989.968750 1206.076904,2991.154053 1204.111328,2992.028809 
	C1198.536865,2994.509766 1193.118530,2991.471191 1186.830200,2992.016113 
z"
        />
        <path
          fill="#F6EEDE"
          opacity="1.000000"
          stroke="none"
          d="
M1379.500732,2854.410156 
	C1380.876465,2853.561768 1382.152954,2853.333984 1382.542847,2852.621338 
	C1386.911865,2844.632568 1395.346436,2843.557373 1403.340210,2840.305420 
	C1403.725708,2845.877197 1410.451172,2845.724365 1412.917969,2849.931885 
	C1408.945923,2852.567383 1405.090820,2853.405518 1402.208496,2849.731201 
	C1398.413086,2844.892578 1395.946777,2847.229248 1392.714233,2850.169922 
	C1388.885254,2853.653076 1385.034302,2857.345703 1378.725220,2857.979492 
	C1377.098999,2856.544434 1377.951172,2855.645752 1379.500732,2854.410156 
z"
        />
        <path
          fill="#EEE7D5"
          opacity="1.000000"
          stroke="none"
          d="
M1160.825439,3028.001465 
	C1160.004639,3026.692627 1160.048340,3025.373535 1159.991089,3024.058838 
	C1159.838989,3020.558594 1160.462036,3016.599121 1163.953491,3015.861084 
	C1167.684814,3015.072754 1171.438843,3017.647705 1174.337280,3020.060303 
	C1176.681396,3022.011475 1174.685181,3024.205322 1172.947266,3025.840576 
	C1169.645020,3028.947754 1165.498291,3027.725830 1160.825439,3028.001465 
z"
        />
        <path
          fill="#EEE7D5"
          opacity="1.000000"
          stroke="none"
          d="
M1379.310547,2854.025879 
	C1379.488159,2854.999268 1378.964600,2855.983887 1378.215698,2857.479492 
	C1377.105591,2857.996826 1376.220947,2858.003174 1374.672852,2858.009766 
	C1374.227661,2855.162598 1375.904297,2854.004395 1379.310547,2854.025879 
z"
        />
        <path
          fill="#EEE7D5"
          opacity="1.000000"
          stroke="none"
          d="
M1159.996826,3027.995117 
	C1159.794922,3029.201904 1159.765259,3030.577148 1158.228638,3030.877686 
	C1157.909424,3030.939941 1157.119019,3030.267334 1157.106567,3029.913330 
	C1157.067505,3028.802979 1157.978027,3028.411133 1159.461914,3028.086670 
	C1160.007080,3028.009766 1160.000000,3028.000000 1159.996826,3027.995117 
z"
        />
        <path
          fill="#EEE7D5"
          opacity="1.000000"
          stroke="none"
          d="
M1193.040527,2999.071045 
	C1192.953857,2999.117432 1193.127319,2999.024658 1193.040527,2999.071045 
z"
        />
        <path
          fill="#EEE7D5"
          opacity="1.000000"
          stroke="none"
          d="
M1263.336670,2910.094727 
	C1263.902222,2919.609863 1256.545288,2921.579346 1248.743408,2923.930664 
	C1250.332764,2916.698975 1257.260010,2914.249023 1263.336670,2910.094727 
z"
        />
        <path
          fill="#EEE7D5"
          opacity="1.000000"
          stroke="none"
          d="
M1357.247559,2835.996338 
	C1358.063843,2839.171387 1357.603638,2841.946777 1352.762207,2841.987305 
	C1351.382446,2840.317871 1348.142090,2839.643066 1349.400391,2837.475098 
	C1350.948120,2834.809082 1354.079102,2836.398926 1357.247559,2835.996338 
z"
        />
        <path
          fill="#EEE7D5"
          opacity="1.000000"
          stroke="none"
          d="
M1164.292236,2951.432617 
	C1161.514282,2948.763184 1162.247437,2947.014648 1165.199585,2946.334229 
	C1166.698853,2945.989014 1168.830566,2946.040039 1168.894409,2948.300537 
	C1168.962402,2950.705566 1167.036743,2951.435547 1164.292236,2951.432617 
z"
        />
        <path
          fill="#EEE7D5"
          opacity="1.000000"
          stroke="none"
          d="
M1181.158203,2930.810303 
	C1180.242065,2928.615967 1180.305664,2926.967041 1182.571289,2926.936035 
	C1183.907837,2926.917725 1185.090332,2928.106689 1185.050293,2929.446533 
	C1184.983398,2931.697021 1183.347046,2931.769043 1181.158203,2930.810303 
z"
        />
        <path
          fill="#EEE7D5"
          opacity="1.000000"
          stroke="none"
          d="
M1335.521973,2946.700195 
	C1338.851929,2945.245361 1345.308838,2948.070312 1341.379272,2940.475830 
	C1340.750732,2939.261230 1342.248291,2938.257080 1343.678467,2938.188477 
	C1345.925415,2938.081055 1348.176636,2938.063721 1351.217896,2938.013672 
	C1352.842773,2945.512695 1347.091675,2948.835205 1342.127930,2951.697510 
	C1338.936401,2953.537842 1337.958496,2955.295898 1338.008301,2959.212402 
	C1332.084961,2960.334473 1326.088745,2959.187988 1320.242065,2960.880615 
	C1318.877319,2961.275879 1317.451660,2961.357178 1316.812866,2960.012695 
	C1315.975952,2958.251465 1316.989746,2956.917236 1318.516968,2955.923584 
	C1320.830078,2954.418213 1323.384277,2954.048096 1326.086304,2953.981934 
	C1329.024902,2953.910156 1332.061157,2954.546875 1335.430420,2952.520752 
	C1334.842041,2950.614258 1334.525513,2949.084473 1335.521973,2946.700195 
z"
        />
        <path
          fill="#EEE7D5"
          opacity="1.000000"
          stroke="none"
          d="
M1340.408325,2964.476807 
	C1340.001587,2963.673096 1340.003296,2963.336670 1340.007324,2962.495117 
	C1340.630737,2962.014160 1341.255737,2962.002197 1341.872070,2962.069092 
	C1343.405029,2962.235596 1345.439331,2962.468018 1344.750000,2964.505859 
	C1344.126953,2966.347168 1342.260864,2965.754883 1340.408325,2964.476807 
z"
        />
        <path
          fill="#EEE7D5"
          opacity="1.000000"
          stroke="none"
          d="
M1352.665527,2937.995117 
	C1352.529053,2937.016357 1353.043457,2936.022705 1353.778931,2934.514648 
	C1355.257568,2934.195557 1356.894287,2934.416016 1356.899536,2935.850342 
	C1356.907715,2938.091553 1354.804443,2937.724121 1352.665527,2937.995117 
z"
        />
        <path
          fill="#E9E1D0"
          opacity="1.000000"
          stroke="none"
          d="
M1337.271973,2880.098633 
	C1337.958740,2882.690186 1338.053955,2885.551270 1335.271606,2887.026855 
	C1326.845337,2891.497070 1319.031738,2896.684326 1313.024536,2904.293213 
	C1312.314087,2905.192871 1310.645264,2905.336426 1308.712769,2905.915039 
	C1308.172485,2901.089844 1311.944092,2898.005615 1315.163452,2895.929932 
	C1322.643311,2891.107178 1327.250000,2882.425781 1337.271973,2880.098633 
z"
        />
        <path
          fill="#EEE7D5"
          opacity="1.000000"
          stroke="none"
          d="
M1324.160645,2946.712402 
	C1326.145508,2946.010986 1328.281250,2946.012451 1331.218140,2946.021484 
	C1332.710938,2946.811523 1332.958374,2947.724609 1332.410156,2949.375488 
	C1329.264648,2949.651367 1325.947388,2951.033936 1324.160645,2946.712402 
z"
        />
        <path
          fill="#E9E1D0"
          opacity="1.000000"
          stroke="none"
          d="
M1332.356079,2950.466553 
	C1332.002075,2949.117432 1332.004028,2948.234619 1332.012695,2946.690674 
	C1332.901489,2946.024902 1333.783813,2946.020264 1335.327515,2946.017578 
	C1335.991699,2947.507568 1335.994385,2948.995605 1335.998535,2951.227295 
	C1334.904053,2951.625000 1333.808105,2951.279053 1332.356079,2950.466553 
z"
        />
        <path
          fill="#EEE7D5"
          opacity="1.000000"
          stroke="none"
          d="
M1243.473022,2784.098877 
	C1243.683716,2785.125244 1243.320068,2786.211182 1242.494873,2787.653320 
	C1239.975586,2786.198730 1240.443604,2784.955322 1243.473022,2784.098877 
z"
        />
        <path
          fill="#9E6A4C"
          opacity="1.000000"
          stroke="none"
          d="
M2483.767578,3033.426758 
	C2481.146729,3035.369141 2479.044434,3038.388672 2474.658203,3037.999268 
	C2471.320068,3034.378418 2464.539795,3035.530029 2464.027832,3028.713867 
	C2464.536133,3027.333740 2464.947998,3026.606934 2465.199707,3025.233887 
	C2465.053223,3023.960938 2464.677490,3023.361572 2463.646240,3022.528320 
	C2462.052246,3021.447754 2461.332520,3019.808594 2460.188965,3019.646973 
	C2449.217285,3018.096680 2450.054443,3010.536133 2451.439697,3001.973877 
	C2452.078125,2998.741211 2453.385742,2996.693848 2456.002930,2994.643555 
	C2459.885742,2992.994873 2462.929932,2991.014893 2467.323486,2991.688721 
	C2466.383545,2998.840576 2472.128906,3001.041260 2476.081055,3004.426514 
	C2475.077881,3006.777832 2471.363281,3007.127930 2472.171875,3010.572021 
	C2472.736328,3012.975830 2474.441406,3013.498047 2476.281250,3014.339355 
	C2479.600586,3015.857422 2482.933838,3017.428223 2486.005371,3019.379395 
	C2488.620850,3021.041016 2490.401855,3023.546143 2489.722900,3026.957520 
	C2488.944824,3030.866211 2485.970947,3028.137939 2484.122559,3028.780518 
	C2481.833496,3029.576172 2482.908203,3031.359619 2483.767578,3033.426758 
z"
        />
        <path
          fill="#704A3A"
          opacity="1.000000"
          stroke="none"
          d="
M2503.126465,3096.733398 
	C2502.697998,3095.209473 2503.229004,3094.655029 2504.413818,3094.891113 
	C2504.659912,3094.939697 2504.990234,3095.288330 2505.027344,3095.537109 
	C2505.206299,3096.731445 2504.627441,3097.234863 2503.126465,3096.733398 
z"
        />
        <path
          fill="#704A3A"
          opacity="1.000000"
          stroke="none"
          d="
M2509.074707,3098.955322 
	C2509.127197,3099.049561 2509.022461,3098.861084 2509.074707,3098.955322 
z"
        />
        <path
          fill="#B17752"
          opacity="1.000000"
          stroke="none"
          d="
M2458.416016,2995.533203 
	C2455.926270,2996.939209 2454.517578,2998.401367 2454.040527,3001.313232 
	C2447.620850,3009.471924 2441.372559,3017.046631 2436.900635,3025.940918 
	C2435.419678,3028.885742 2433.209229,3031.521240 2428.577637,3030.423096 
	C2427.980469,3026.577637 2427.980469,3023.158447 2427.980469,3018.887207 
	C2430.712646,3015.770996 2431.426025,3012.632812 2432.159912,3009.332520 
	C2433.453613,3003.515869 2435.227295,2997.819580 2439.692383,2992.612061 
	C2448.380127,2988.331543 2456.740723,2984.671631 2465.560059,2980.483643 
	C2468.375488,2980.052246 2470.649170,2979.757568 2473.367188,2978.260254 
	C2474.960693,2982.590576 2470.949707,2984.160889 2468.318115,2987.372314 
	C2465.337158,2990.833252 2461.344727,2991.980957 2458.416016,2995.533203 
z"
        />
        <path
          fill="#C88655"
          opacity="1.000000"
          stroke="none"
          d="
M2405.064697,3029.993652 
	C2403.016113,3032.106445 2401.347168,3032.026611 2400.580078,3029.490723 
	C2400.379883,3028.829102 2401.006104,3027.331543 2401.576172,3027.129150 
	C2403.471436,3026.456543 2404.506836,3027.755127 2405.064697,3029.993652 
z"
        />
        <path
          fill="#A26847"
          opacity="1.000000"
          stroke="none"
          d="
M2458.632568,2995.914795 
	C2458.737793,2991.088135 2461.527100,2988.354492 2467.263672,2987.992432 
	C2467.993652,2988.866211 2467.996826,2989.761719 2468.000000,2991.328613 
	C2465.104492,2993.282959 2462.209229,2994.565918 2458.632568,2995.914795 
z"
        />
        <path
          fill="#A26847"
          opacity="1.000000"
          stroke="none"
          d="
M2427.546143,3030.425781 
	C2431.983887,3029.920654 2433.436523,3026.959229 2435.035889,3023.980957 
	C2437.056885,3020.218018 2439.719727,3016.755371 2441.383545,3012.855225 
	C2443.650391,3007.542480 2447.770264,3004.690430 2453.344482,3002.244629 
	C2453.980225,3005.918457 2454.380371,3009.932129 2451.740234,3014.821777 
	C2458.331543,3013.771973 2462.582520,3015.936035 2465.467529,3021.593506 
	C2466.706543,3022.100342 2467.285645,3022.443359 2467.873779,3023.501709 
	C2467.807373,3025.791504 2467.267334,3027.322754 2464.637939,3027.899902 
	C2462.732910,3026.496582 2461.716553,3024.589355 2460.130127,3023.565918 
	C2455.566162,3020.622559 2452.282959,3022.037354 2452.183350,3027.406738 
	C2452.095459,3032.137451 2450.962158,3035.666016 2446.919189,3038.322754 
	C2444.823242,3039.700195 2444.231201,3041.898193 2445.168457,3044.484375 
	C2446.173096,3047.257568 2446.580566,3050.108398 2444.874512,3052.553223 
	C2442.364258,3052.777588 2442.316650,3051.199463 2442.025146,3050.008301 
	C2441.613525,3048.325684 2441.130859,3046.646240 2439.121826,3046.538086 
	C2436.674805,3046.406250 2435.300537,3048.436523 2435.090576,3050.246582 
	C2434.162598,3058.250977 2427.247559,3063.978027 2427.273193,3072.501221 
	C2427.285645,3076.732178 2423.458496,3080.172852 2420.912598,3083.570557 
	C2415.615479,3090.640381 2414.943604,3099.476318 2410.611816,3107.549072 
	C2404.950928,3102.333008 2409.338623,3096.353516 2410.559570,3091.153564 
	C2412.176025,3084.269043 2414.013916,3076.720459 2417.046387,3070.831543 
	C2421.176758,3062.809570 2422.459473,3053.970947 2426.748779,3046.199219 
	C2429.434570,3041.333008 2430.578125,3036.050049 2427.546143,3030.425781 
z"
        />
        <path
          fill="#9E6A4C"
          opacity="1.000000"
          stroke="none"
          d="
M2406.927002,3144.233643 
	C2406.427490,3142.449219 2406.364014,3140.954102 2406.304688,3138.710938 
	C2406.165527,3136.779785 2406.451904,3135.718506 2407.626465,3134.400879 
	C2410.279053,3132.181396 2409.770996,3130.037109 2408.947266,3127.701904 
	C2407.706055,3124.183350 2407.402588,3120.595459 2409.525391,3116.521484 
	C2412.089111,3125.857178 2417.324951,3135.722656 2409.444580,3145.820312 
	C2408.332520,3145.542725 2407.847656,3145.032715 2406.927002,3144.233643 
z"
        />
        <path
          fill="#9E6A4C"
          opacity="1.000000"
          stroke="none"
          d="
M2468.022949,3053.258301 
	C2466.216064,3054.191162 2464.678711,3054.729492 2464.106934,3057.377686 
	C2459.848633,3057.268066 2459.347412,3054.907959 2461.531250,3051.793457 
	C2463.361816,3049.182861 2466.216309,3047.181885 2465.985840,3042.740234 
	C2468.834229,3042.360107 2470.153564,3043.826416 2469.450928,3046.750000 
	C2468.989014,3048.671143 2468.508545,3050.588135 2468.022949,3053.258301 
z"
        />
        <path
          fill="#9E6A4C"
          opacity="1.000000"
          stroke="none"
          d="
M2461.912842,3037.294922 
	C2456.310547,3035.014648 2456.310547,3035.014648 2457.936523,3028.816650 
	C2460.681396,3030.883789 2460.705078,3034.038574 2461.912842,3037.294922 
z"
        />
        <path
          fill="#9E6A4C"
          opacity="1.000000"
          stroke="none"
          d="
M2462.098633,3038.630615 
	C2463.787842,3038.190186 2465.324219,3038.729736 2465.901367,3041.369141 
	C2464.211914,3041.810059 2462.677002,3041.268311 2462.098633,3038.630615 
z"
        />
        <path
          fill="#9E6A4C"
          opacity="1.000000"
          stroke="none"
          d="
M2452.716309,3128.857910 
	C2451.224365,3129.278809 2450.692139,3128.750488 2450.919189,3127.598389 
	C2450.966553,3127.358154 2451.307129,3127.034424 2451.549316,3126.998047 
	C2452.718018,3126.822754 2453.203857,3127.394043 2452.716309,3128.857910 
z"
        />
        <path
          fill="#6F7981"
          opacity="1.000000"
          stroke="none"
          d="
M2289.329834,3162.311768 
	C2289.533447,3161.552002 2289.546143,3160.847656 2289.678955,3160.521484 
	C2290.059570,3159.924561 2290.307617,3159.653564 2290.754150,3158.868408 
	C2291.022461,3157.992676 2291.091797,3157.631348 2291.598633,3156.933350 
	C2292.914062,3156.438477 2293.791992,3156.280273 2295.337891,3156.072510 
	C2304.019531,3160.134521 2311.363525,3166.697266 2321.565430,3162.605225 
	C2322.963379,3162.044678 2325.379639,3161.906250 2326.343506,3162.725586 
	C2332.296143,3167.787598 2339.421631,3168.142090 2346.626221,3168.470703 
	C2347.261719,3168.499756 2348.318359,3168.645752 2348.449951,3169.005127 
	C2351.704102,3177.873047 2360.551514,3173.354736 2366.339111,3176.375732 
	C2372.404297,3179.541748 2378.883057,3182.612305 2386.243164,3182.050049 
	C2392.001953,3181.609863 2396.273682,3187.599365 2403.206055,3185.990234 
	C2404.156006,3188.516602 2405.078857,3190.730713 2407.580078,3191.469727 
	C2412.206543,3192.836914 2413.545898,3196.288818 2414.033936,3201.247070 
	C2414.060303,3202.874512 2414.033691,3203.753174 2413.961914,3205.289551 
	C2413.555908,3209.457764 2415.188721,3213.958740 2408.846191,3214.352295 
	C2408.186768,3213.879395 2408.097656,3213.895508 2408.094482,3213.850586 
	C2399.975586,3205.236328 2391.288818,3199.520508 2378.205322,3199.955322 
	C2368.861572,3200.265869 2360.900879,3192.356934 2351.796631,3189.213867 
	C2348.071045,3187.927734 2344.968506,3185.400391 2340.695068,3186.774414 
	C2338.348877,3187.529053 2335.966064,3187.142822 2334.138428,3185.465576 
	C2330.633545,3182.249268 2326.991943,3181.626465 2322.282959,3182.895752 
	C2317.193604,3184.267578 2315.279053,3179.142578 2312.951172,3176.013428 
	C2309.835938,3177.743896 2309.769775,3181.078125 2306.597656,3182.281738 
	C2305.283203,3181.896240 2304.773682,3181.494873 2304.148193,3180.515137 
	C2300.805664,3173.632080 2293.708984,3176.308838 2288.673340,3172.895752 
	C2287.896484,3169.118408 2289.352295,3166.164795 2289.329834,3162.311768 
z"
        />
        <path
          fill="#718A41"
          opacity="1.000000"
          stroke="none"
          d="
M2292.403809,3147.449707 
	C2291.599609,3142.599365 2293.557617,3137.909424 2295.685791,3132.650879 
	C2296.123047,3133.689941 2296.014160,3135.211670 2295.891113,3137.505859 
	C2295.435303,3141.392090 2296.178711,3144.890625 2292.403809,3147.449707 
z"
        />
        <path
          fill="#C4C8C1"
          opacity="1.000000"
          stroke="none"
          d="
M2391.800781,3150.683594 
	C2397.017578,3148.794678 2397.706299,3149.322998 2397.371582,3155.045410 
	C2393.861328,3156.071533 2391.623047,3155.213379 2391.800781,3150.683594 
z"
        />
        <path
          fill="#718A41"
          opacity="1.000000"
          stroke="none"
          d="
M2291.849365,3156.088867 
	C2292.036133,3156.596924 2291.997559,3157.214844 2291.985840,3157.524414 
	C2291.588867,3158.129639 2291.203613,3158.425049 2290.322510,3158.187012 
	C2289.856445,3156.816406 2289.886475,3155.979248 2290.111084,3154.544434 
	C2290.758057,3154.491455 2291.210449,3155.035889 2291.849365,3156.088867 
z"
        />
        <path
          fill="#626669"
          opacity="1.000000"
          stroke="none"
          d="
M2306.076660,3182.846191 
	C2307.813965,3176.873047 2307.912354,3169.821533 2316.665283,3171.200684 
	C2318.533936,3172.966064 2316.832764,3174.887207 2316.931885,3176.723877 
	C2317.143311,3180.637451 2321.653320,3182.821777 2323.352051,3179.877686 
	C2325.744385,3175.731445 2327.412842,3176.722900 2330.150146,3178.831787 
	C2331.973633,3180.236572 2334.238037,3181.062256 2335.580078,3183.097656 
	C2336.594971,3184.636475 2337.771484,3185.386475 2339.657227,3184.246094 
	C2344.591309,3181.262939 2348.787842,3181.996826 2352.312012,3186.744873 
	C2353.037842,3187.723145 2354.561035,3188.737305 2355.678223,3188.696045 
	C2363.044678,3188.424561 2367.981934,3193.500488 2373.881592,3196.501953 
	C2380.061768,3199.645996 2384.824951,3198.512207 2391.312744,3192.203613 
	C2389.750244,3199.571777 2395.965332,3201.964111 2399.162109,3202.402100 
	C2406.058105,3203.347900 2406.218506,3208.099121 2407.987793,3213.125000 
	C2404.122314,3213.994629 2400.377441,3212.793701 2396.574219,3212.168213 
	C2387.580811,3210.688232 2379.383545,3205.196045 2369.326904,3207.767578 
	C2365.438477,3208.761963 2360.612061,3204.330566 2356.161621,3202.504395 
	C2348.462646,3199.345947 2341.055908,3195.830811 2332.498291,3195.826660 
	C2330.936035,3195.825928 2328.947754,3195.395996 2327.885986,3194.392334 
	C2323.277832,3190.036865 2317.540527,3188.754150 2311.654541,3187.523438 
	C2309.421143,3187.056396 2307.240967,3185.959229 2306.076660,3182.846191 
z"
        />
        <path
          fill="#626669"
          opacity="1.000000"
          stroke="none"
          d="
M2289.593750,3174.195312 
	C2299.035645,3169.620605 2300.759521,3170.231201 2303.939941,3179.340332 
	C2299.914062,3180.023682 2295.908936,3179.967041 2291.045410,3179.863281 
	C2289.392334,3179.225586 2288.858643,3178.453857 2288.576660,3176.777588 
	C2288.733643,3175.598389 2288.898682,3175.144287 2289.593750,3174.195312 
z"
        />
        <path
          fill="#313E41"
          opacity="1.000000"
          stroke="none"
          d="
M2289.359863,3175.333984 
	C2290.002930,3176.555420 2290.044434,3177.489258 2290.136719,3179.119629 
	C2290.451660,3182.904541 2290.716553,3185.992920 2290.528076,3189.538574 
	C2289.721436,3189.988037 2289.367920,3189.980225 2288.484619,3189.954346 
	C2286.898438,3189.158936 2287.068359,3187.923340 2286.584473,3186.321289 
	C2286.528564,3183.244873 2286.690430,3180.697510 2286.963867,3177.394287 
	C2287.229736,3175.677490 2287.771240,3175.126465 2289.359863,3175.333984 
z"
        />
        <path
          fill="#626669"
          opacity="1.000000"
          stroke="none"
          d="
M2408.249512,3214.580566 
	C2412.846924,3213.630859 2411.263428,3209.155273 2413.421875,3206.412598 
	C2415.639404,3207.046387 2415.944580,3208.758545 2415.927734,3211.281006 
	C2415.469971,3212.427002 2415.035645,3212.916016 2414.320312,3213.810303 
	C2414.050049,3214.979004 2414.078613,3215.758301 2414.129395,3217.116211 
	C2411.784668,3217.908203 2409.309814,3218.383301 2408.249512,3214.580566 
z"
        />
        <path
          fill="#718A41"
          opacity="1.000000"
          stroke="none"
          d="
M2297.772949,3129.235352 
	C2297.490234,3129.186279 2297.571533,3128.588867 2297.732910,3128.334717 
	C2297.894531,3128.080566 2298.434082,3128.649414 2298.434082,3128.649414 
	C2298.434082,3128.649414 2298.055420,3129.284668 2297.772949,3129.235352 
z"
        />
        <path
          fill="#718A41"
          opacity="1.000000"
          stroke="none"
          d="
M2299.416016,3124.205078 
	C2298.722656,3123.836914 2298.804688,3123.237305 2299.810059,3122.512695 
	C2300.250732,3122.909912 2300.069824,3123.438477 2299.416016,3124.205078 
z"
        />
        <path
          fill="#1B2B3A"
          opacity="1.000000"
          stroke="none"
          d="
M2302.604004,3121.729980 
	C2302.290771,3121.320801 2302.564209,3120.745605 2303.492188,3120.081543 
	C2303.768555,3120.507568 2303.449951,3121.031494 2302.604004,3121.729980 
z"
        />
        <path
          fill="#434F51"
          opacity="1.000000"
          stroke="none"
          d="
M2414.440918,3213.918945 
	C2414.371582,3213.631348 2414.721680,3213.063477 2415.497070,3212.224609 
	C2415.568359,3212.515625 2415.214600,3213.076904 2414.440918,3213.918945 
z"
        />
        <path
          fill="#718A41"
          opacity="1.000000"
          stroke="none"
          d="
M2286.197754,3186.411621 
	C2286.816650,3186.806152 2287.266602,3187.820068 2287.835449,3189.385254 
	C2287.789307,3190.311035 2287.623535,3190.685547 2287.052734,3191.523438 
	C2286.441650,3190.335205 2286.235352,3188.683105 2286.197754,3186.411621 
z"
        />
        <path
          fill="#EEE7D5"
          opacity="1.000000"
          stroke="none"
          d="
M1608.025024,3036.725586 
	C1610.379272,3037.774902 1612.366455,3038.924072 1610.255737,3041.092529 
	C1609.064453,3042.316162 1606.986084,3042.309082 1605.802124,3041.092041 
	C1603.711304,3038.941650 1605.644043,3037.764893 1608.025024,3036.725586 
z"
        />
        <path
          fill="#E9E1D0"
          opacity="1.000000"
          stroke="none"
          d="
M1678.418213,2903.755859 
	C1673.400879,2896.850098 1677.919434,2889.593750 1677.795166,2882.392578 
	C1677.731201,2878.676758 1679.341553,2875.111572 1678.267334,2870.994141 
	C1676.762451,2865.225342 1678.448608,2859.350098 1679.961914,2852.809570 
	C1680.910034,2852.356934 1682.597900,2852.981445 1682.569702,2853.071533 
	C1680.709717,2859.046875 1682.906616,2864.979980 1683.939087,2870.493896 
	C1685.849365,2880.696045 1680.485962,2890.111328 1682.242920,2900.090820 
	C1682.405518,2901.013916 1680.129395,2902.366699 1678.418213,2903.755859 
z"
        />
        <path
          fill="#E9E1D0"
          opacity="1.000000"
          stroke="none"
          d="
M1674.936646,2920.069336 
	C1674.847168,2916.241455 1669.587158,2911.147461 1677.113281,2908.250488 
	C1681.311035,2915.041016 1681.054077,2916.593750 1674.936646,2920.069336 
z"
        />
        <path
          fill="#FBF9EC"
          opacity="1.000000"
          stroke="none"
          d="
M1631.907837,2830.667480 
	C1631.920044,2826.411621 1634.299316,2824.757812 1637.140137,2823.470215 
	C1640.440063,2821.974365 1643.739868,2820.478271 1647.506348,2818.477539 
	C1649.737305,2816.321533 1651.400024,2814.565674 1653.625732,2812.523682 
	C1654.935059,2812.123779 1655.785522,2812.117188 1657.271973,2812.133301 
	C1658.144043,2815.285645 1661.666260,2819.347168 1655.015381,2820.350830 
	C1651.506958,2820.880371 1651.699463,2824.275146 1652.222168,2826.837158 
	C1652.892090,2830.121826 1653.716797,2833.278076 1651.465332,2837.510742 
	C1650.397217,2838.242676 1650.039185,2837.963379 1649.277954,2837.149170 
	C1648.228149,2835.523926 1648.114502,2834.292236 1647.935547,2833.077881 
	C1647.987915,2834.059326 1648.132202,2835.024902 1648.779907,2836.513672 
	C1649.423584,2837.381348 1649.724121,2837.697754 1650.563721,2838.394531 
	C1651.468750,2839.024170 1651.834961,2839.273682 1652.461182,2840.138184 
	C1652.202271,2842.939209 1651.934692,2845.231689 1649.584717,2847.299072 
	C1647.827026,2848.454834 1646.515381,2848.876953 1644.407715,2848.880615 
	C1642.851318,2848.424561 1642.059082,2847.945312 1640.833496,2846.880371 
	C1636.878540,2841.734863 1631.969116,2837.889404 1631.907837,2830.667480 
z"
        />
        <path
          fill="#E5DDC9"
          opacity="1.000000"
          stroke="none"
          d="
M1655.177002,2898.067871 
	C1657.402466,2907.535889 1655.208984,2916.451904 1649.330322,2923.896973 
	C1646.853394,2927.033936 1647.235229,2931.610352 1642.769531,2933.904541 
	C1641.751831,2929.304688 1645.256470,2924.640137 1642.485596,2919.790039 
	C1642.346313,2919.545898 1642.354370,2918.925293 1642.480591,2918.864014 
	C1650.069336,2915.166748 1646.808716,2907.500732 1648.854980,2901.785400 
	C1649.656982,2899.545166 1652.209473,2898.857422 1655.177002,2898.067871 
z"
        />
        <path
          fill="#E5DDC9"
          opacity="1.000000"
          stroke="none"
          d="
M1637.319092,2876.468750 
	C1638.098145,2874.116699 1638.784912,2872.669189 1641.216797,2871.917480 
	C1641.731445,2880.003906 1637.626099,2887.510742 1637.484009,2895.814209 
	C1637.434204,2898.731445 1634.495239,2899.846924 1630.839600,2900.015137 
	C1630.058228,2900.058350 1629.998047,2899.998779 1629.967407,2899.970215 
	C1626.725830,2890.655518 1634.606445,2884.726074 1637.319092,2876.468750 
z"
        />
        <path
          fill="#D0C8B8"
          opacity="1.000000"
          stroke="none"
          d="
M1647.516602,2846.519043 
	C1649.819702,2844.898438 1650.401245,2842.833496 1652.704346,2840.537109 
	C1655.182251,2851.026855 1648.772461,2859.204834 1642.869873,2868.033691 
	C1642.445923,2864.043945 1644.681274,2860.113281 1644.016846,2854.330566 
	C1644.965576,2850.857178 1646.402710,2849.141846 1647.516602,2846.519043 
z"
        />
        <path
          fill="#E5DDC9"
          opacity="1.000000"
          stroke="none"
          d="
M1661.372559,2880.294434 
	C1662.752197,2884.699707 1659.242798,2887.588623 1656.752197,2891.561523 
	C1653.269897,2889.976074 1653.904175,2887.605469 1655.841309,2885.366943 
	C1657.331909,2883.644775 1659.119873,2882.180176 1661.372559,2880.294434 
z"
        />
        <path
          fill="#FBF9EC"
          opacity="1.000000"
          stroke="none"
          d="
M1662.188599,2812.307129 
	C1662.329346,2811.992188 1662.663574,2811.984375 1663.499268,2811.964355 
	C1663.639771,2812.609131 1663.279541,2813.268066 1662.188599,2812.307129 
z"
        />
        <path
          fill="#D0C8B8"
          opacity="1.000000"
          stroke="none"
          d="
M1653.270020,2838.522217 
	C1654.305176,2838.267334 1655.161865,2838.457275 1654.314941,2839.773438 
	C1653.995117,2839.991699 1653.946899,2839.947754 1653.923096,2839.925293 
	C1653.579590,2839.623779 1653.260010,2839.344971 1653.270020,2838.522217 
z"
        />
        <path
          fill="#FBF9EC"
          opacity="1.000000"
          stroke="none"
          d="
M1653.207397,2837.997314 
	C1653.599609,2837.978027 1653.788940,2838.936035 1653.843994,2839.419434 
	C1653.899170,2839.902588 1652.928589,2839.895508 1652.444580,2839.933350 
	C1651.960571,2839.971191 1652.004761,2840.012939 1652.022583,2840.037109 
	C1651.751221,2839.713135 1651.461914,2839.365234 1651.602051,2838.492188 
	C1652.031250,2837.966553 1652.008789,2837.990723 1652.020020,2837.979736 
	C1652.031250,2837.968750 1652.815186,2838.016602 1653.207397,2837.997314 
z"
        />
        <path
          fill="#FBF9EC"
          opacity="1.000000"
          stroke="none"
          d="
M1629.800781,2850.903809 
	C1630.679932,2849.905273 1632.387939,2849.250000 1632.040527,2848.937012 
	C1626.802979,2844.222900 1626.711914,2837.271240 1624.042969,2830.674805 
	C1625.443848,2828.092773 1627.193604,2827.591797 1629.647217,2829.491699 
	C1632.847656,2835.584473 1634.214111,2842.176758 1640.830322,2846.164551 
	C1642.501709,2849.521973 1642.288818,2852.097900 1640.301758,2855.255615 
	C1639.201538,2856.866699 1638.618042,2857.837646 1637.912354,2859.378662 
	C1636.079346,2861.919189 1634.304077,2863.748047 1630.707642,2862.419922 
	C1630.020996,2858.551025 1629.936035,2855.151123 1629.800781,2850.903809 
z"
        />
        <path
          fill="#D0C8B8"
          opacity="1.000000"
          stroke="none"
          d="
M1637.128540,2876.021484 
	C1636.455811,2883.803711 1633.100098,2891.078369 1630.043213,2899.215332 
	C1624.116577,2901.480225 1625.641846,2907.581299 1622.473389,2911.549316 
	C1620.868286,2911.112793 1619.785889,2909.818848 1620.347168,2908.614746 
	C1625.446045,2897.674316 1627.576050,2885.244141 1637.128540,2876.021484 
z"
        />
        <path
          fill="#D0C8B8"
          opacity="1.000000"
          stroke="none"
          d="
M1601.055176,2932.106445 
	C1601.078979,2925.267822 1603.313843,2919.063721 1609.245605,2914.095215 
	C1609.232178,2918.170898 1608.491211,2922.394287 1607.851196,2926.632812 
	C1607.320190,2930.150146 1605.227905,2931.821289 1601.055176,2932.106445 
z"
        />
        <path
          fill="#F2EBDD"
          opacity="1.000000"
          stroke="none"
          d="
M1630.049194,2862.696045 
	C1632.229370,2861.343750 1634.352783,2860.736328 1637.133179,2860.038574 
	C1639.159058,2864.528320 1635.099365,2866.406250 1632.600464,2869.771484 
	C1629.850342,2868.431641 1629.918457,2865.937256 1630.049194,2862.696045 
z"
        />
        <path
          fill="#D0C8B8"
          opacity="1.000000"
          stroke="none"
          d="
M1614.790161,2899.931641 
	C1614.015991,2896.090576 1613.281982,2891.807617 1619.266602,2890.101074 
	C1619.942017,2893.926025 1619.428955,2897.647949 1614.790161,2899.931641 
z"
        />
        <path
          fill="#D0C8B8"
          opacity="1.000000"
          stroke="none"
          d="
M1640.322998,2855.743164 
	C1639.718262,2853.095215 1639.651489,2850.294678 1639.468262,2846.659668 
	C1639.802734,2845.612793 1640.253784,2845.399902 1641.383789,2845.501465 
	C1642.612183,2846.187744 1643.080200,2846.643555 1643.709229,2847.610840 
	C1645.206421,2849.283691 1645.499512,2850.766602 1644.614990,2853.116943 
	C1644.189331,2853.798096 1644.024658,2853.983154 1643.944580,2854.080811 
	C1642.863159,2854.648926 1641.861938,2855.119629 1640.322998,2855.743164 
z"
        />
        <path
          fill="#D0C8B8"
          opacity="1.000000"
          stroke="none"
          d="
M1621.117554,2916.059082 
	C1621.702759,2918.685059 1623.066650,2921.957764 1618.683960,2923.829346 
	C1618.243774,2921.223877 1617.009399,2917.995361 1621.117554,2916.059082 
z"
        />
        <path
          fill="#D0C8B8"
          opacity="1.000000"
          stroke="none"
          d="
M1610.813843,2909.950195 
	C1609.959229,2907.750488 1610.876953,2905.919434 1613.383057,2904.273438 
	C1613.942017,2906.254395 1614.258057,2908.679199 1610.813843,2909.950195 
z"
        />
        <path
          fill="#E9E1D0"
          opacity="1.000000"
          stroke="none"
          d="
M1540.839355,2906.039551 
	C1540.283569,2904.781006 1540.361938,2903.159424 1541.704224,2902.739258 
	C1547.108521,2901.046143 1549.290527,2897.007080 1550.003906,2891.851562 
	C1550.397339,2889.007080 1551.603760,2886.946045 1555.261719,2886.098145 
	C1555.101440,2898.040283 1551.481201,2903.073242 1540.839355,2906.039551 
z"
        />
        <path
          fill="#E9E1D0"
          opacity="1.000000"
          stroke="none"
          d="
M1556.666870,2885.866455 
	C1556.128906,2882.085205 1555.222412,2877.691162 1561.276123,2876.105225 
	C1560.981323,2879.507080 1561.039795,2883.521973 1556.666870,2885.866455 
z"
        />
        <path
          fill="#E9E1D0"
          opacity="1.000000"
          stroke="none"
          d="
M1534.954102,2938.923340 
	C1535.037354,2938.878418 1534.870972,2938.968506 1534.954102,2938.923340 
z"
        />
        <path
          fill="#CC855B"
          opacity="1.000000"
          stroke="none"
          d="
M1609.782837,2333.441895 
	C1607.155762,2333.313721 1606.276855,2332.225342 1607.332397,2330.327393 
	C1607.989746,2329.145752 1609.421875,2328.332275 1610.583130,2329.189941 
	C1612.205078,2330.388184 1611.748047,2331.968750 1609.782837,2333.441895 
z"
        />
        <path
          fill="#805541"
          opacity="1.000000"
          stroke="none"
          d="
M1562.635498,2434.325684 
	C1559.953979,2434.200439 1558.033691,2433.966064 1555.388184,2433.682617 
	C1554.104126,2433.160400 1553.916138,2432.684570 1554.097412,2432.205322 
	C1554.279297,2431.725342 1554.462036,2431.485107 1555.306519,2431.652344 
	C1562.785278,2432.570801 1569.604858,2433.322021 1577.278809,2433.997070 
	C1585.967041,2437.230469 1594.491455,2436.180664 1602.620850,2437.787598 
	C1616.787842,2440.587891 1631.099731,2442.652832 1645.283569,2445.375732 
	C1646.960571,2445.697754 1648.536377,2447.495605 1651.250977,2447.862793 
	C1654.512451,2451.829590 1654.194824,2455.811768 1653.262207,2460.624023 
	C1653.938721,2464.570312 1654.328369,2467.763916 1652.501221,2471.447754 
	C1650.220825,2472.541748 1649.082886,2471.585205 1647.573975,2469.854736 
	C1636.381470,2458.550781 1625.003174,2449.106689 1609.009033,2447.301758 
	C1602.848267,2446.606445 1596.984009,2444.065674 1590.087280,2442.878418 
	C1585.675903,2442.225342 1582.548950,2440.264404 1578.623413,2438.354980 
	C1573.468262,2435.392822 1568.177002,2435.847656 1562.635498,2434.325684 
z"
        />
        <path
          fill="#D99469"
          opacity="1.000000"
          stroke="none"
          d="
M1589.954346,2431.584473 
	C1586.450195,2432.161865 1583.058105,2432.109131 1578.811279,2432.039795 
	C1576.738525,2431.876709 1575.520874,2431.730225 1574.303223,2431.583984 
	C1581.354004,2431.763428 1583.483887,2424.575195 1589.364990,2422.123291 
	C1594.463501,2425.023926 1590.753662,2427.986572 1589.954346,2431.584473 
z"
        />
        <path
          fill="#D99469"
          opacity="1.000000"
          stroke="none"
          d="
M1555.384521,2431.290527 
	C1555.595947,2432.170410 1555.246704,2432.540283 1554.790405,2433.281738 
	C1549.838501,2434.354980 1545.520630,2432.239014 1540.543945,2430.625732 
	C1544.992065,2430.153076 1549.960571,2429.144043 1555.384521,2431.290527 
z"
        />
        <path
          fill="#D99469"
          opacity="1.000000"
          stroke="none"
          d="
M1564.476685,2429.937988 
	C1563.331299,2429.667480 1562.745117,2429.409668 1562.158936,2429.151855 
	C1563.288208,2427.401611 1565.019409,2428.112793 1566.518677,2428.203125 
	C1567.313232,2428.250488 1568.606812,2428.746094 1566.995728,2429.708740 
	C1566.478638,2430.017578 1565.696655,2429.883057 1564.476685,2429.937988 
z"
        />
        <path
          fill="#9E6A4C"
          opacity="1.000000"
          stroke="none"
          d="
M1607.272461,2407.830078 
	C1607.250122,2409.242920 1606.111084,2409.823730 1603.977783,2409.913818 
	C1604.390015,2409.188232 1605.497314,2408.461670 1607.272461,2407.830078 
z"
        />
        <path
          fill="#D99469"
          opacity="1.000000"
          stroke="none"
          d="
M1597.076904,2370.959473 
	C1597.121704,2371.041504 1597.032227,2370.877441 1597.076904,2370.959473 
z"
        />
        <path
          fill="#9E6A4C"
          opacity="1.000000"
          stroke="none"
          d="
M1601.602539,2410.012207 
	C1601.786987,2410.691406 1601.318481,2410.969238 1600.458252,2410.542969 
	C1600.267212,2410.301758 1601.150513,2410.073486 1601.602539,2410.012207 
z"
        />
        <path
          fill="#704A3A"
          opacity="1.000000"
          stroke="none"
          d="
M1652.096436,2432.785156 
	C1652.645874,2434.504639 1652.666016,2435.818359 1652.403076,2438.075684 
	C1647.625244,2439.429932 1643.024170,2436.495605 1638.529785,2440.001953 
	C1636.825439,2441.331543 1634.103516,2440.217041 1631.872803,2439.714844 
	C1628.712158,2439.002441 1625.547241,2438.308350 1621.621094,2437.541992 
	C1629.497925,2436.342773 1636.640503,2431.466064 1645.068359,2427.908447 
	C1646.010620,2428.014160 1646.258911,2428.278809 1646.879639,2428.940430 
	C1647.504517,2432.033203 1649.990356,2431.561768 1652.096436,2432.785156 
z"
        />
        <path
          fill="#B6AFA3"
          opacity="1.000000"
          stroke="none"
          d="
M1653.576660,2460.668945 
	C1650.692749,2457.771484 1652.426880,2453.611328 1651.943359,2448.932617 
	C1651.913818,2447.773438 1651.943970,2447.401367 1651.999756,2446.469727 
	C1655.026001,2445.361816 1656.757446,2446.222168 1655.103027,2449.418945 
	C1654.298462,2450.973389 1653.846191,2452.280518 1654.501221,2454.038086 
	C1654.294922,2456.102539 1653.998047,2458.033936 1653.576660,2460.668945 
z"
        />
        <path
          fill="#B9A795"
          opacity="1.000000"
          stroke="none"
          d="
M1652.898438,2432.747559 
	C1650.747314,2433.300049 1648.869507,2433.474854 1646.990356,2433.487305 
	C1646.157349,2433.492920 1645.322021,2433.170654 1644.487915,2432.997559 
	C1645.158691,2432.045654 1645.829346,2431.093750 1646.875977,2429.739502 
	C1649.079956,2428.940430 1650.907959,2428.543701 1653.449219,2428.223633 
	C1653.832153,2429.634521 1653.501709,2430.968994 1652.898438,2432.747559 
z"
        />
        <path
          fill="#CC855B"
          opacity="1.000000"
          stroke="none"
          d="
M1649.215210,2467.778320 
	C1650.226196,2469.163330 1650.610962,2470.249023 1651.471069,2471.670654 
	C1651.186523,2479.346680 1653.761230,2486.208008 1654.835938,2494.042969 
	C1654.409546,2495.714844 1654.590088,2496.503418 1655.792236,2497.508301 
	C1656.980103,2498.767334 1657.593384,2499.708008 1658.241211,2501.220703 
	C1655.336304,2503.516113 1652.172974,2504.028076 1648.772217,2504.015381 
	C1644.455933,2503.999268 1641.208862,2503.237305 1639.687012,2498.336426 
	C1638.337036,2493.988770 1635.608765,2490.068604 1633.417480,2486.098145 
	C1630.036621,2479.971924 1624.647217,2476.088623 1616.682861,2475.810547 
	C1604.460938,2475.384033 1594.502563,2470.130615 1588.557983,2458.824219 
	C1587.643433,2457.084961 1586.158325,2455.060791 1586.412964,2453.393799 
	C1587.491577,2446.332520 1583.021484,2442.800781 1578.180420,2438.535156 
	C1581.749878,2437.405762 1584.932129,2439.543213 1589.233154,2439.958984 
	C1592.885742,2442.846436 1596.250732,2444.823975 1600.153442,2445.978516 
	C1605.158569,2447.458984 1608.735596,2450.496338 1611.157471,2455.301270 
	C1614.954102,2462.833252 1621.288574,2466.351318 1629.836670,2465.781494 
	C1636.162964,2465.359619 1642.233765,2468.041992 1649.215210,2467.778320 
z"
        />
        <path
          fill="#B17752"
          opacity="1.000000"
          stroke="none"
          d="
M1656.263672,2497.234863 
	C1655.803467,2497.249023 1655.240112,2496.671387 1654.676880,2495.458984 
	C1655.171509,2495.430420 1655.666138,2496.036865 1656.263672,2497.234863 
z"
        />
        <path
          fill="#DFD9CC"
          opacity="1.000000"
          stroke="none"
          d="
M1869.672119,2755.876221 
	C1868.201660,2758.308105 1866.445557,2758.308350 1864.357178,2756.045898 
	C1865.855469,2754.648926 1867.618042,2753.605469 1869.672119,2755.876221 
z"
        />
        <path
          fill="#FBF5E5"
          opacity="1.000000"
          stroke="none"
          d="
M1886.005371,2784.006104 
	C1886.168335,2783.938232 1886.325928,2783.864502 1886.235107,2783.890381 
	C1885.986694,2783.990234 1886.000000,2784.000000 1886.005371,2784.006104 
z"
        />
        <path
          fill="#DFD9CC"
          opacity="1.000000"
          stroke="none"
          d="
M1927.912842,2857.294922 
	C1924.570068,2858.078369 1922.186523,2857.217041 1921.934692,2853.175049 
	C1921.773804,2850.591309 1920.814087,2848.057129 1920.128906,2844.724609 
	C1927.481201,2845.129639 1926.341064,2851.682129 1927.912842,2857.294922 
z"
        />
        <path
          fill="#442F21"
          opacity="1.000000"
          stroke="none"
          d="
M1645.994385,2606.361816 
	C1645.776367,2608.545898 1646.252686,2610.656250 1644.010986,2610.800537 
	C1642.385132,2610.904785 1642.018799,2609.288330 1642.441284,2608.030273 
	C1643.131714,2605.973633 1643.107178,2603.531982 1645.546509,2601.793701 
	C1645.717041,2603.275879 1645.855835,2604.481445 1645.994385,2606.361816 
z"
        />
        <path
          fill="#8C5B43"
          opacity="1.000000"
          stroke="none"
          d="
M1643.911499,2591.152832 
	C1641.937500,2589.976562 1641.211182,2587.926270 1643.566650,2585.780029 
	C1643.975952,2587.466797 1643.962769,2588.942383 1643.911499,2591.152832 
z"
        />
        <path
          fill="#442F21"
          opacity="1.000000"
          stroke="none"
          d="
M1643.071777,2617.084229 
	C1642.971680,2617.138916 1643.171997,2617.029541 1643.071777,2617.084229 
z"
        />
        <path
          fill="#442F21"
          opacity="1.000000"
          stroke="none"
          d="
M1646.906372,2619.049316 
	C1646.851318,2618.948486 1646.961426,2619.150391 1646.906372,2619.049316 
z"
        />
        <path
          fill="#FBF9EC"
          opacity="1.000000"
          stroke="none"
          d="
M1336.016846,2681.981445 
	C1337.261719,2682.176514 1338.644409,2682.245117 1338.920288,2683.801025 
	C1338.978394,2684.128906 1338.296997,2684.911865 1337.927979,2684.930664 
	C1336.810791,2684.988037 1336.387451,2684.081055 1336.039795,2682.587646 
	C1335.963013,2682.039062 1335.998901,2682.000488 1336.016846,2681.981445 
z"
        />
        <path
          fill="#FBF9EC"
          opacity="1.000000"
          stroke="none"
          d="
M1541.640625,2866.491455 
	C1543.492432,2866.020508 1544.988892,2866.015381 1547.232910,2865.997070 
	C1550.940063,2866.187500 1554.284546,2865.789062 1552.713135,2870.747314 
	C1551.122314,2875.767090 1548.500488,2878.065430 1544.544312,2876.884766 
	C1542.725098,2876.341797 1541.116821,2875.092041 1538.723022,2874.068604 
	C1538.020020,2872.480957 1538.006958,2870.987549 1537.996826,2868.747070 
	C1539.095093,2867.652344 1540.190308,2867.304932 1541.640625,2866.491455 
z"
        />
        <path
          fill="#F2EBDD"
          opacity="1.000000"
          stroke="none"
          d="
M1641.949219,2845.139893 
	C1641.613281,2845.829102 1641.163818,2845.842773 1640.033081,2845.840820 
	C1631.650513,2844.123291 1628.559082,2839.372070 1629.995239,2830.811523 
	C1630.019531,2829.980469 1630.994873,2829.991455 1631.482300,2829.991211 
	C1635.818481,2834.433838 1637.397827,2840.423340 1641.949219,2845.139893 
z"
        />
        <path
          fill="#F2EBDD"
          opacity="1.000000"
          stroke="none"
          d="
M1653.400391,2812.163086 
	C1653.798218,2815.118164 1652.433350,2817.206055 1648.648193,2817.906006 
	C1648.217773,2814.950195 1649.511108,2812.788086 1653.400391,2812.163086 
z"
        />
        <path
          fill="#FBF9EC"
          opacity="1.000000"
          stroke="none"
          d="
M1310.489014,2710.416748 
	C1310.206787,2708.615723 1310.452759,2707.060059 1311.959839,2706.900879 
	C1314.454224,2706.637451 1315.239868,2708.598877 1315.845703,2711.229004 
	C1314.173340,2711.874023 1312.431641,2711.985596 1310.489014,2710.416748 
z"
        />
        <path
          fill="#B17752"
          opacity="1.000000"
          stroke="none"
          d="
M1624.103882,2550.240723 
	C1624.216553,2550.248779 1624.432983,2550.497803 1624.649414,2550.746582 
	C1624.502197,2550.658203 1624.354980,2550.569824 1624.103882,2550.240723 
z"
        />
        <path
          fill="#8C5B43"
          opacity="1.000000"
          stroke="none"
          d="
M1539.734131,2502.139160 
	C1540.136108,2501.786865 1540.982178,2501.989014 1541.401123,2502.101807 
	C1541.706909,2503.405762 1541.650635,2504.957275 1540.187012,2504.955811 
	C1538.444458,2504.954346 1538.595947,2503.683594 1539.734131,2502.139160 
z"
        />
        <path
          fill="#805541"
          opacity="1.000000"
          stroke="none"
          d="
M1531.799316,2493.512451 
	C1532.616333,2494.464111 1533.278442,2495.260986 1533.285156,2496.063477 
	C1533.290894,2496.770996 1532.378662,2496.942139 1531.798828,2496.370605 
	C1530.928223,2495.512207 1529.790527,2494.608887 1531.799316,2493.512451 
z"
        />
        <path
          fill="#CC855B"
          opacity="1.000000"
          stroke="none"
          d="
M1638.000732,2526.000000 
	C1638.653564,2525.534424 1639.305542,2525.068848 1639.957520,2524.603271 
	C1640.113281,2524.832764 1640.269043,2525.062256 1640.424805,2525.291504 
	C1639.912964,2525.506836 1639.401123,2525.721924 1638.442139,2525.968506 
	C1637.995117,2526.000000 1638.000000,2526.000000 1638.000732,2526.000000 
z"
        />
        <path
          fill="#F3AB7B"
          opacity="1.000000"
          stroke="none"
          d="
M1528.011108,2448.014648 
	C1527.798340,2447.949951 1527.960449,2449.216553 1527.717529,2448.084473 
	C1527.970337,2447.979492 1528.000000,2448.000000 1528.011108,2448.014648 
z"
        />
        <path
          fill="#FBF9EC"
          opacity="1.000000"
          stroke="none"
          d="
M2257.963379,3189.895996 
	C2257.076660,3189.835693 2257.128174,3189.894043 2257.166504,3189.896973 
	C2255.280518,3188.054443 2253.041260,3186.299561 2252.644287,3183.513184 
	C2251.087891,3172.588135 2250.852051,3161.611084 2252.140381,3150.646240 
	C2252.529297,3147.337158 2254.416260,3145.256592 2258.158691,3145.317871 
	C2263.219238,3145.400879 2267.803711,3147.903564 2268.319336,3151.626953 
	C2268.676270,3154.204834 2268.509766,3157.006592 2267.891113,3159.540527 
	C2265.913330,3167.644043 2264.009033,3175.729980 2263.930908,3184.134521 
	C2263.899902,3187.467529 2262.928467,3190.098633 2257.963379,3189.895996 
z"
        />
        <path
          fill="#718A41"
          opacity="1.000000"
          stroke="none"
          d="
M2289.967285,3175.138428 
	C2289.317627,3175.846680 2288.673828,3176.071777 2287.552979,3176.467529 
	C2285.318359,3171.760010 2287.582031,3167.350098 2289.110840,3162.178711 
	C2289.694580,3165.036621 2289.855713,3168.521240 2290.070312,3172.853027 
	C2290.123535,3173.700439 2289.973389,3174.655273 2289.967285,3175.138428 
z"
        />
        <path
          fill="#626669"
          opacity="1.000000"
          stroke="none"
          d="
M2289.783691,3158.288574 
	C2290.182373,3157.710693 2290.538330,3157.767578 2291.434082,3157.829102 
	C2291.573975,3158.436768 2291.173584,3159.039551 2290.292480,3159.918945 
	C2289.788086,3159.771484 2289.764648,3159.347656 2289.783691,3158.288574 
z"
        />
        <path
          fill="#704A3A"
          opacity="1.000000"
          stroke="none"
          d="
M2407.498291,3133.979980 
	C2407.744629,3135.082764 2407.450439,3136.156738 2406.732422,3137.597168 
	C2405.061768,3137.327393 2404.441162,3136.312500 2404.647949,3134.287109 
	C2405.542236,3133.760010 2406.249756,3133.855713 2407.498291,3133.979980 
z"
        />
        <path
          fill="#B17752"
          opacity="1.000000"
          stroke="none"
          d="
M2468.408203,3023.690430 
	C2467.383789,3023.760742 2466.873047,3023.372803 2466.229004,3022.422852 
	C2466.709961,3021.003906 2467.595459,3020.195068 2468.801758,3021.094971 
	C2469.594971,3021.686768 2469.395996,3022.603516 2468.408203,3023.690430 
z"
        />
        <path
          fill="#626669"
          opacity="1.000000"
          stroke="none"
          d="
M2297.909668,3165.116943 
	C2296.555908,3164.371094 2296.538086,3163.620361 2297.506104,3162.957275 
	C2297.708496,3162.818604 2298.179688,3162.824951 2298.377441,3162.969482 
	C2299.322021,3163.660156 2299.288086,3164.409668 2297.909668,3165.116943 
z"
        />
        <path
          fill="#6F7981"
          opacity="1.000000"
          stroke="none"
          d="
M2347.885010,3187.749268 
	C2347.737061,3187.765137 2347.511719,3187.481445 2347.286377,3187.197510 
	C2347.459961,3187.281738 2347.633789,3187.365723 2347.885010,3187.749268 
z"
        />
        <path
          fill="#6F7981"
          opacity="1.000000"
          stroke="none"
          d="
M2348.021484,3187.984863 
	C2348.250732,3188.243164 2348.457764,3188.519775 2348.665039,3188.796631 
	C2348.504639,3188.711182 2348.344238,3188.625732 2348.073242,3188.294434 
	C2347.962646,3188.048828 2347.999512,3188.003662 2348.021484,3187.984863 
z"
        />
        <path
          fill="#F2EBDD"
          opacity="1.000000"
          stroke="none"
          d="
M1650.058228,2837.217529 
	C1644.375977,2836.432129 1644.844727,2831.512939 1644.321655,2827.181152 
	C1644.200806,2826.180908 1644.947876,2825.388672 1645.954590,2825.122803 
	C1647.504883,2824.713135 1648.478271,2825.770020 1649.049438,2826.876221 
	C1650.603638,2829.888184 1649.846313,2833.211670 1650.058228,2837.217529 
z"
        />
        <path
          fill="#F2EBDD"
          opacity="1.000000"
          stroke="none"
          d="
M1644.877686,2853.690918 
	C1644.118164,2852.359863 1644.046997,2850.921631 1643.963623,2848.761230 
	C1644.922485,2847.503418 1645.893433,2846.968018 1647.363281,2846.197266 
	C1647.634155,2848.664062 1649.107910,2851.879395 1644.877686,2853.690918 
z"
        />
        <path
          fill="#D0C8B8"
          opacity="1.000000"
          stroke="none"
          d="
M1651.531982,2838.025146 
	C1652.032593,2838.315430 1652.034058,2838.664551 1652.037964,2839.537598 
	C1651.418823,2839.820068 1650.915405,2839.427246 1650.307129,2838.454346 
	C1650.083862,2838.025879 1650.005981,2837.990723 1650.016602,2838.030762 
	C1650.362305,2838.075195 1650.697510,2838.079590 1651.531982,2838.025146 
z"
        />
        <path
          fill="#B67247"
          opacity="1.000000"
          stroke="none"
          d="
M1649.767090,2467.362305 
	C1647.741577,2470.244385 1645.894165,2471.636230 1642.213623,2470.552979 
	C1637.021484,2469.024658 1631.668945,2468.686035 1625.908936,2469.013672 
	C1618.448975,2469.437744 1611.171143,2464.558838 1608.595093,2456.902344 
	C1606.918457,2451.919189 1604.083008,2450.108398 1599.831665,2448.887451 
	C1595.635620,2447.683105 1591.546265,2446.327393 1590.098511,2440.723877 
	C1602.912476,2442.858154 1615.833740,2445.687256 1628.742432,2448.572754 
	C1629.686523,2448.783691 1631.092163,2449.189941 1631.350586,2449.863281 
	C1634.683105,2458.547363 1645.204834,2459.306152 1649.767090,2467.362305 
z"
        />
      </svg>
    );
  }
}

export default FeelingProud;
