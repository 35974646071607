import React, { Component } from "react";

export default class SalesforceImg extends Component {
  render() {
    // eslint-disable-next-line
    const theme = this.props.theme;
    return (
      <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        width="820.000000pt"
        height="481.000000pt"
        viewBox="0 0 820.000000 481.000000"
        preserveAspectRatio="xMidYMid meet"
      >
        <g
          transform="translate(0.000000,481.000000) scale(0.100000,-0.100000)"
          fill="#000000"
          stroke="none"
        >
          <path
            d="M5600 3488 c0 -42 -36 -129 -70 -170 -44 -53 -143 -99 -214 -100 -48
       -1 -58 -4 -72 -26 -32 -48 -99 -115 -154 -152 -182 -123 -406 -138 -599 -40
       l-55 27 -61 -61 c-152 -154 -354 -188 -546 -90 -67 33 -142 104 -181 170 l-31
       52 -44 -19 c-104 -45 -254 -56 -368 -29 -84 21 -190 78 -259 141 l-65 60 -46
       -12 c-100 -25 -238 11 -321 84 l-47 42 7 -30 c45 -189 241 -319 433 -287 l66
       11 64 -59 c67 -63 170 -118 258 -140 107 -25 293 -8 378 37 16 8 30 13 31 11
       1 -2 14 -23 28 -48 153 -263 524 -304 731 -80 l56 60 58 -30 c33 -16 85 -36
       118 -45 69 -18 202 -20 277 -4 122 26 263 116 333 212 l40 54 70 5 c86 6 139
       31 193 93 91 104 95 258 8 370 -15 19 -15 19 -16 -7z"
          />
          <path
            d="M5272 2544 c-47 -33 -18 -114 41 -114 35 0 57 25 57 65 0 35 -28 65
       -60 65 -9 0 -26 -7 -38 -16z"
          />
          <path
            d="M3539 2499 c-45 -45 0 -122 63 -106 33 9 54 56 39 86 -23 43 -70 52
       -102 20z"
          />
          <path
            d="M4547 2472 c-23 -25 -21 -68 3 -92 27 -27 56 -25 86 6 20 19 24 31
       19 52 -12 51 -75 71 -108 34z"
          />
          <path
            d="M5866 2278 c-32 -45 -6 -98 48 -98 39 0 66 26 66 62 0 55 -83 81
       -114 36z"
          />
          <path
            d="M3297 2038 c-9 -7 -19 -25 -22 -40 -10 -39 34 -83 73 -73 34 8 52 31
       52 65 0 50 -62 78 -103 48z"
          />
          <path
            d="M4836 1878 c-9 -12 -16 -33 -16 -45 0 -29 33 -63 60 -63 27 0 70 43
       70 70 0 53 -84 81 -114 38z"
          />
          <path
            d="M6240 1830 c-39 -39 -11 -110 44 -110 27 0 66 42 66 71 0 27 -36 59
       -65 59 -14 0 -34 -9 -45 -20z"
          />
          <path
            d="M2921 888 c-5 -13 -20 -56 -35 -95 l-26 -73 411 0 c325 0 410 3 407
       13 -3 6 -18 49 -34 95 l-29 82 -343 0 -342 0 -9 -22z"
          />
          <path
            d="M3162 658 l-8 -33 114 -3 c63 -1 117 -1 119 2 3 3 1 17 -4 33 -10 28
       -10 28 -111 31 l-101 3 -9 -33z"
          />
          <path
            d="M2780 580 c0 -20 7 -20 490 -20 483 0 490 0 490 20 0 20 -7 20 -490
       20 -483 0 -490 0 -490 -20z"
          />
        </g>
      </svg>
    );
  }
}
